import React from "react";
import classes from "./SearchCompo.module.css";

function SearchCompo({style, setSearch=()=>{},onSearch=()=>{},placeholder='Search'}){

    const changeHandler=(event)=>{
        event.preventDefault();
        setTimeout(()=>{
        setSearch(event.target.value);
        },600)
        onSearch(event.target.value);

    }
    return (
            <input  onChange={changeHandler} className={classes.inputSearch} style={style} type="text" placeholder={placeholder}></input>
    )
}

export default SearchCompo;
