import React from 'react';
import {Col, Row, Input } from 'antd';
import { Color } from 'services';
import { docButton } from './documentForm';

export const bankDetails = (kycDetails, handleUpdateDetails, handleIfscUpdate, compareAcNo,
     handleUploadChange, beforeUpload, handleDocClick, handleEditCommentClick
    ) => (
    <div style={{width: "100%"}}>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                <div className='text-xs color-black m-b-5 bold-600' >Beneficiary Name <span style={{color:Color.red}}>*</span></div>
                <Input onChange={(e) => handleUpdateDetails(e.target.value, 'beneficiaryName')}
                disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                value={kycDetails.beneficiaryName} placeholder="Beneficiary of the bank account" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >Branch IFSC Code <span style={{color:Color.red}}>*</span></div>
                <Input onChange={(e) => handleUpdateDetails(e.target.value.toUpperCase().trim(), 'ifsc')} 
                onBlur={e => handleIfscUpdate(e)} disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                value={kycDetails.ifsc} placeholder="IFSC Code of the account" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
                {kycDetails.ifscValid  === false ? <div className="text-xs red bold-600">Enter valid ifsc code</div> : null}
            </Col>
        </Row>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                <div className='text-xs color-black m-b-5 bold-600' >Bank Name <span style={{color:Color.red}}>*</span></div>
                <Input disabled
                value={kycDetails.bankName} 
                placeholder="Bank Name" className='radius-5 kyc-input-fields' 
                style={{background:Color.lightGrey, border:'none', color: 'black'}}/>
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >Branch <span style={{color:Color.red}}>*</span></div>
                <Input disabled
                value={kycDetails.branch} placeholder="Branch Name" className='radius-5 kyc-input-fields' 
                style={{background:Color.lightGrey, border:'none', color: 'black'}}/>
            </Col>
        </Row>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                <div className='text-xs color-black m-b-5 bold-600' >Account Number <span style={{color:Color.red}}>*</span></div>
                <Input onChange={(e) => handleUpdateDetails(e.target.value, 'accNoHidden')}  onContextMenu={e => e.preventDefault()}
                disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                type="password" onBlur={() => compareAcNo()}
                value={kycDetails.accNoHidden} placeholder="Account Number" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >Re-enter Account Number <span style={{color:Color.red}}>*</span></div>
                <Input onChange={(e) => handleUpdateDetails(e.target.value, 'accNo')} onContextMenu={e => e.preventDefault()}
                disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                type="number" onBlur={() => compareAcNo()}
                value={kycDetails.accNo} placeholder="Verify Account Number" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
                {kycDetails.accNoMatch  === false ? <div className="text-xs red bold-600">Account numbers do not match</div> : null}
            </Col>
        </Row>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton("Upload Cancelled Cheque", true, kycDetails, handleUploadChange, beforeUpload, 
                'cancelled_cheque', handleUpdateDetails, handleDocClick, handleEditCommentClick)}
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
               
            </Col>
        </Row>

    </div>
)