import { Modal } from 'antd'
import React from 'react'

function FooterSocialMediaModal({ isSocialMedia, setIsSocialMedia, footerData }) {
    console.log("footerDataModal", footerData?.name)
    const handleSocialMediaClick = (item) => {

        window.open(item, '_blank')

    }
    const SocialMediaIcon = ({ name }) => {
        console.log('fgasd',name);
        
        const iconMap = {
            WhatsApp: require("../../Assets/aulasMarket/whatsappColored.svg").default,
            Instagram: require("../../Assets/aulasMarket/instagram.svg").default,
            Facebook: require("../../Assets/aulasMarket/facebook.svg").default,
            Twitter: require("../../Assets/aulasMarket/TwiterX-colored-IC.svg").default,
            LinkedIn: require("../../Assets/aulasMarket/LinkedInColor.svg").default,
            YouTube: require("../../Assets/Footer/yt1.svg").default
        };

        return <img className="m-r-10" style={{ height: "40px", width: "40px" }} src={iconMap[name]} alt={name} />;
    };
    return (
        <div>
            <Modal open={isSocialMedia} centered 
                className="modal-round-corner" footer={null} onCancel={() => { setIsSocialMedia(false) }}>
                <center style={{ fontWeight: "bold", fontSize: "18px" }}>We're Social</center>

                {footerData && footerData?.urlDetails && (
                    <div>
                        {footerData?.urlDetails?.map((url, index) => (
                            <div key={index} className='r-c-fs p-10' onClick={() => handleSocialMediaClick(url?.url)}>
                                <SocialMediaIcon name={footerData?.name} />
                                <div typeof='link' style={{ cursor: "pointer", fontWeight: "600", fontSize: "14px", color:'#1E90FF' }}>{url?.name ? url?.name : url?.url}</div>
                            </div>
                        ))}
                    </div>
                )}

            </Modal>
        </div>
    )
}

export default FooterSocialMediaModal
