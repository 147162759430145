import React from 'react'
import { RadialBar, Pie } from '@ant-design/charts';
import { useEffect, useRef } from 'react'

const StaffLeaveChart = ({ storeAttendanceLeaveOverview }) => {
    {
        const data = [
            {
                type: 'Teachers',
                value: storeAttendanceLeaveOverview?.staffLeaveSummary?.Teacher ? storeAttendanceLeaveOverview?.staffLeaveSummary?.Teacher : 0
            },
            {
                type: 'Data Operators',
                value: storeAttendanceLeaveOverview?.staffLeaveSummary?.['Data Operator'] ? storeAttendanceLeaveOverview?.staffLeaveSummary?.['Data Operator'] : 0

            },
            {
                type: 'Operations',
                value: storeAttendanceLeaveOverview?.staffLeaveSummary?.Operation ? storeAttendanceLeaveOverview?.staffLeaveSummary?.Operation : 0

            }
        ];
        // const actualvals = [4, 2, 10]
        // const sum = actualvals[0]+actualvals[1]+actualvals[2]
        // const vals = actualvals.map((val)=>(Math.floor(val*100/sum)))
        // console.log("cals vals", vals)
        // const data = [
        //   {
        //     name: 'Chapter test',
        //     star: vals[0],
        //     bgcolor:'#9464F9'
        //   },
        //   {
        //     name: 'Part test',
        //     star: vals[1],
        //     bgcolor:'#99DED9'

        //   },
        //   {
        //     name: 'Full test',
        //     star: vals[2],
        //     bgcolor:'#589DFF'

        //   }
        // ];
        // const config = {
        //   data,
        //   xField: 'name',
        //   yField: 'star',
        //   maxAngle: 350,
        //   //最大旋转角度,
        //   radius: 0.8,
        //   innerRadius: 0.2,
        //   maxBarWidth: 5,
        //   tooltip: {
        //     formatter: (datum) => {
        //       return {
        //         name: datum.name,
        //         value: datum.star,
        //       };
        //     },
        //   },
        //   colorField: 'bgcolor',
        //   color: ['#9464F9', '#99DED9', '#589DFF'],
        //   barBackground: ['#9464F9', '#99DED9', '#589DFF'],
        //   barStyle: {
        //     lineCap: 'round',
        //   },
        //   // annotations: [
        //   //   {
        //   //     type: 'html',
        //   //     position: ['50%', '50%'],
        //   //     html: (container, view) => {
        //   //       const coord = view.getCoordinate();
        //   //       const w = coord.polarRadius * coord.innerRadius * 1.15;
        //   //       return `<div></div>`;
        //   //     },
        //   //   },
        //   // ],
        // };
        const config = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            color: ['#9876E6', '#99DED9', '#589DFF'],

            radius: 0.8,
            autoFit: true,
            legend: false,
            innerRadius: 0.68,
            label: {
                type: 'inner',
                offset: '-50%',
                content: "",
                style: {
                    fill: '#fff',
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 8
                    },
                    customHtml: function customHtml() {
                        return (
                            <div>
                                <div style={{ fontSize: "20px", fontWeight: "700" }}>{/* {attendanceDataContainer?.monthlyAttendance?.averagePercentage} */}{storeAttendanceLeaveOverview?.staffLeaveSummary?.totalLeaves}</div>
                                <div style={{ fontSize: "10px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto", color: "grey" }}>Total staff on leave</div>
                            </div>
                        )
                    }
                },
            },
            annotations: [
            ],
        };

        // const tags = ["Present", "Absent", "equal"]
        // const tagval = [pieData?.upcomingLiveTestCount??0, pieData?.onGoingLiveTestCount??0, pieData?.submittedCount??0, pieData?.cancelledCount??0]
        // const tagcolor = ['#28DF99', '#FF414D', '#28DF69']

        return (
            <>
                <div
                    style={{
                        //  width:"20vw",
                        width: "280px",
                        // position: "absolute",
                        // top: -50,
                        // marginLeft: 50
                    }}>
                    {/* <RadialBar {...config} />; */}
                    <Pie {...config} />
                </div>
                {/* <div className="r-jsb absolute" style={{ bottom: 10, marginLeft: 120 }}>
                    {tags?.map((tag, index) =>
                        <div className="r-c-c m-r-15" key={index}>
                            <div style={{
                                backgroundColor: tagcolor[index],
                                width: "15px",
                                height: "15px",
                                borderRadius: "3px",
                                marginRight: "5px"
                            }}>

                            </div>
                            <div style={{ fontSize: 12 }}>
                                {tag}
                            </div>
                        </div>
                    )
                    }
                </div> */}
            </>

        )
    }




}

export default StaffLeaveChart