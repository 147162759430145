import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import TextArea from "antd/lib/input/TextArea";
import { Button } from 'components/Button';
import { connect } from 'react-redux';
import { setDiscountDetails } from 'actions/courseManagement';


function CourseAddDiscountModal({ isCourseDiscountModalVisible, setCourseDiscountModalVisible, setDiscountDetails, courseDiscountDetails }) {
  const handleCancel = () => {
    setCourseDiscountModalVisible(false)
  }
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [desc, setDesc] = useState(null);
  const [amount, setAmount] = useState(null);
  return (
    <Modal
      centered
      className="modal-round-corner"
      visible={isCourseDiscountModalVisible}
      onCancel={handleCancel}
      onOk={handleCancel}
      footer={null}
    >
      <div style={{ fontSize: "22px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>
        Discount details
      </div>
      <p className="dropDownLabelBasicStyle m-t-10">Discount title<span style={{ color: "red" }}> *</span></p>
      <Input
        placeholder={`Enter discount title`}
        defaultValue={null}
        style={{ width: "100%" }}
        value={name}
        onChange={(e) => { setName(e.target.value) }}
      ></Input>
      <p className="dropDownLabelBasicStyle m-t-20">Discount code<span style={{ color: "red" }}> *</span></p>
      <Input
        placeholder={`Enter discount code`}
        defaultValue={null}
        style={{ width: "100%" }}
        value={code}
        onChange={(e) => { setCode(e.target.value) }}
      ></Input>
      <p className="dropDownLabelBasicStyle m-t-20">Discount details</p>
      <TextArea
        placeholder={`Enter discount details`}
        defaultValue={null}
        style={{ width: "100%", height: "100px" }}
        value={desc}
        onChange={(e) => { setDesc(e.target.value); }}
      ></TextArea>
      <p className="dropDownLabelBasicStyle m-t-20">Amount<span style={{ color: "red" }}> *</span></p>
      <Input
        type="number"
        placeholder="Enter amount"
        prefix="₹"
        defaultValue={null}
        style={{ width: "100%" }}
        value={amount}
        onChange={(e) => { setAmount(e.target.value) }}
      ></Input>
      <div className='r-c-c'>
        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px", width: "70%" }}>
          <Button onClick={handleCancel}>CANCEL</Button>
          <Button
            type="primary"
            disabled={!name || !code || !amount}
            onClick={() => {
              handleCancel();
              let temp = courseDiscountDetails
                ? [...courseDiscountDetails]
                : [];
              temp.push({
                name: name,
                code: code,
                details: desc,
                amount: amount
              });
              setDiscountDetails(temp);
            }}
            style={{ backgroundColor: "#594099", color: "white" }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </Modal>
  )
}
const mapStateToProps = (state) => {
  const { courseDiscountDetails } = state.courseManagement;
  return {
    courseDiscountDetails
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDiscountDetails: (val) => dispatch(setDiscountDetails(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseAddDiscountModal);