import React from "react";
import { Api, Notification } from "../../../services";

import { connect } from "react-redux";
import Typography from "antd/lib/typography";
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import DatePicker from "antd/lib/date-picker";
import Modal from "antd/lib/modal";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import "antd/lib/typography/style/index";
import "antd/lib/upload/style/index";
import "antd/lib/message/style/index";
import "antd/lib/date-picker/style/index";
import "antd/lib/modal/style/index";
import "antd/lib/select/style/index";
import "antd/lib/button/style/index";
import "antd/lib/input/style/index";
import { Color } from "../../../services";
import {
  CameraOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  userModalVisible,
  saveUser,
  updateAllUserInput,
  editUsers,
  togglePermanentAddressCheckbox
} from "../../../actions/user";
import "./styles.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Avatar from "antd/lib/avatar/avatar";
import { Divider, Row, Switch } from "antd";
import {IsJsonString} from './studentModal';
import user from "../user";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}


class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      imgLoading: false,
      role: "Admin",
      permission: "None",
      imageAdd: "",
      sex: "",
      dob: null
    };
  }

  uploadImage = info => {
    if (info.file.status === "uploading") {
      this.setState({ imgLoading: true });
      return;
    }
    if (info.file) {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          imgLoading: false,
          imageAdd: info.file.originFileObj,
          allowEdit: true
        })
      );
    }
  };
  componentDidMount() {
    const {userSingle} = this.props;
    const currentAddressRaw = userSingle.currentAddressRaw && userSingle.currentAddressRaw !="" ? userSingle.currentAddressRaw : userSingle.currentAddress && IsJsonString(userSingle.currentAddress) ?  JSON.parse(userSingle.currentAddress) : userSingle.currentAddress;
    const permanentAddressRaw = userSingle.permanentAddressRaw && userSingle.permanentAddressRaw !="" ? userSingle.permanentAddressRaw : userSingle.permanentAddress && IsJsonString(userSingle.permanentAddress) ?  JSON.parse(userSingle.permanentAddress) : userSingle.permanentAddress;
    // const permanentAddressRaw = JSON.parse(userSingle.permanentAddress);

    this.handleUserInput(currentAddressRaw, "currentAddressRaw")
    this.handleUserInput(permanentAddressRaw, "permanentAddressRaw")
    this.setState({
      // dob: this.props.userSingle.dob ? moment(this.props.userSingle.dob, 'YYYY/MM/DD') : null
      dob: ""
    });
    this.actionsList(userSingle);
  }

  componentWillUnmount(){
    this.props.togglePermanentAddressCheckbox(false)
  }

  actionsList(childData){
    const {user} = this.props;
    let allowEdit =  true;
    if(childData.role){
      if(childData.role.includes("SuperAdmin") && user.role.includes("SuperAdmin")){
        allowEdit = false;
      }else if(childData.role.includes("Admin") && user.role.includes("SuperAdmin")){
        allowEdit = true;
      }else if(childData.role.includes("SuperAdmin") && user.role.includes("Admin")){
        allowEdit = false;
      }else if(childData.role.includes("Admin") && user.role.includes("Admin")){
        allowEdit = false;
      }
    }
    this.setState({allowEdit})
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  updatePermanentAddress(bool){
    this.props.togglePermanentAddressCheckbox(bool)
    if(bool){
      this.handleUserInput({...this.props.userSingle.currentAddressRaw}, "permanentAddressRaw")
    }else{
      this.handleUserInput({locality: null, city: null, state: null, pincode: null}, "permanentAddressRaw")
    }
  }

  pageAdminAdd() {
    const { userSingle, positionCurrent } = this.props;
    if(
      !userSingle.currentAddressRaw ||
      !userSingle.currentAddressRaw.city || userSingle.currentAddressRaw.city.trim() === "" ||
      !userSingle.currentAddressRaw.state || userSingle.currentAddressRaw.state.trim() === "" ||
      !userSingle.name || userSingle.name.trim() === "" ||
      !userSingle.phone || userSingle.phone.trim() === ""  
      // !userSingle.permission || userSingle.permission.trim() === ""

      ){
       return Notification.error('error', "All mandatory fields must be filled!")
      }
    if(!userSingle.bloodGroup){
      userSingle.bloodGroup = "NA"
    }

    if (userSingle.role === "None" || !userSingle.role) {
      Object.assign(userSingle, { role: positionCurrent });
    } else {
      Object.assign(userSingle, {
        role: `${userSingle.role},${positionCurrent}`
      });
    }
    if(userSingle.feeAccess){
      Object.assign(userSingle, {
        role: `${userSingle.role},Fee Manager`
      });
    }else if (!userSingle.feeAccess && userSingle.role.split(',').includes("Fee Manager")){
      const roles = userSingle.role.split(',');

      const userRole = ""
      roles.forEach(role => 
          {
          if(role !== "Fee Manager"){
            if(userRole === ""){
              userRole = role;
            }else{
              userRole = `${userRole},${role}`
            }
          }
        }
        )
        Object.assign(userSingle, { role: userRole});
    }

    if(userSingle.supportAccess){
      Object.assign(userSingle, {
        role: `${userSingle.role},Institute Support`
      });
    }else if (!userSingle.supportAccess && userSingle.role.split(',').includes("Institute Support")){
      const roles = userSingle.role.split(',');
      let userRole = ""
      roles.forEach(role => 
          {
          if(role !== "Institute Support"){
            if(userRole === ""){
              userRole = role;
            }else{
              userRole = `${userRole},${role}`
            }
          }
        }
        )
        Object.assign(userSingle, { role: userRole});
    }

    userSingle.permanentAddress = userSingle.permanentAddressRaw ? JSON.stringify(userSingle.permanentAddressRaw) : "";
    userSingle.currentAddress = userSingle.currentAddressRaw ? JSON.stringify(userSingle.currentAddressRaw) : "";

    userSingle.permission = "Edit";
    if (!userSingle.email || userSingle.email === "") {
      delete userSingle.email;
    }
    let newUser = {
      file: this.state.imageAdd,
      data: userSingle
    };
    this.props.saveUser(newUser, positionCurrent);
    this.setState({
      imageAdd: "",
      imageUrl: ""
    });
  }

  pageUserEdit(userSingle) {
    const { positionCurrent } = this.props;


    if(
      !userSingle.currentAddressRaw ||
      !userSingle.currentAddressRaw.city || userSingle.currentAddressRaw.city.trim() === "" ||
      !userSingle.currentAddressRaw.state || userSingle.currentAddressRaw.state.trim() === "" ||
      !userSingle.name || userSingle.name.trim() === "" ||
      !userSingle.phone || userSingle.phone.trim() === ""  
      // !userSingle.permission || userSingle.permission === ""
      ){
        return Notification.error('error', "All mandatory fields must be filled!")
      }
    if(!userSingle.bloodGroup){
      userSingle.bloodGroup = "NA"
    }

    userSingle.permanentAddress = userSingle.permanentAddressRaw ? JSON.stringify(userSingle.permanentAddressRaw) : "";
    userSingle.currentAddress = userSingle.currentAddressRaw ? JSON.stringify(userSingle.currentAddressRaw) : "";


    delete userSingle.secondaryPhone;
    delete userSingle.guardianPhone;
    delete userSingle.guardianEmail;
    delete userSingle.permission;
    if (!userSingle.email || userSingle.email === "") {
      delete userSingle.email;
    }
    if (userSingle.role === "None") {
      Object.assign(userSingle, { role: positionCurrent });
    } else {
      Object.assign(userSingle, {
        role: `${userSingle.role},${positionCurrent}`
      });
    }
    if(userSingle.feeAccess){
      Object.assign(userSingle, {
        role: `${userSingle.role},Fee Manager`
      });
    }else if (!userSingle.feeAccess && userSingle.role.split(',').includes("Fee Manager")){
      const roles = userSingle.role.split(',');
      let userRole = ""
      roles.forEach(role => 
          {
          if(role !== "Fee Manager"){
            if(userRole === ""){
              userRole = role;
            }else{
              userRole = `${userRole},${role}`
            }
          }
        }
        )
        Object.assign(userSingle, { role: userRole});
    }
    if(userSingle.supportAccess){
      Object.assign(userSingle, {
        role: `${userSingle.role},Institute Support`
      });
    }else if (!userSingle.supportAccess && userSingle.role.split(',').includes("Institute Support")){
      const roles = userSingle.role.split(',');
      let userRole = ""
      roles.forEach(role => 
          {
          if(role !== "Institute Support"){
            if(userRole === ""){
              userRole = role;
            }else{
              userRole = `${userRole},${role}`
            }
          }
        }
        )
        Object.assign(userSingle, { role: userRole});
    }
    let oldUser = {
      file: this.state.imageAdd,
      data: userSingle
    };
    this.props.editUsers(oldUser, this.props.filterParams.role);
    this.setState({
      imageAdd: "",
      imageUrl: ""
    });
  }

  handleUserInput(val, key) {
    // const { positionCurrent } = this.props;
    this.props.updateUserInput(val, key);
    // if (positionCurrent !== val) {
    //   this.props.updateUserInput(val, key);
    // }
  }

  handleCancelClick() {
    this.props.userModalVisible({}, false);
    this.setState({
      imageAdd: "",
      imageUrl: "",
      dob: null
    });
  }


  handleInputChange(telNumber, selectedCountry) {
    // console.log(
    //   "input changed. number: ",
    //   telNumber,
    //   "selected country: ",
    //   selectedCountry
    // );
  }

  handleDobChange(e){
    const date = e ? moment(e).format("YYYY-MM-DD") :  null;
    this.handleUserInput(
      date,
      "dob"
    )
  }


  render() {
    const {
      userSingle,
      userAddLoader,
      positionCurrent,
      editable,
      user,
      permanentAddressChkboxBool
    } = this.props;
    const {allowEdit} = this.state;

    let role = "None";
    if(userSingle.role){
      if(typeof userSingle.role === "string"){
        if(userSingle.role === "Admin" || userSingle.role === "SuperAdmin" || userSingle.role === "Attendance Marker" || userSingle.role === "Institute Support" ){
          role = userSingle.role
        }
      }else{
        if(userSingle.role.length > 1) {
          role = userSingle.role.filter(item => !["Fee Manager", "Institute Support", "Teacher", "Data Operator", "Operation", "Planner", "Student"].includes(item))[0] || "None"
        }
      }
    }
      // userSingle.role &&
      // (typeof userSingle.role === "string"
      //   ? userSingle.role === "Admin" || userSingle.role === "SuperAdmin" || userSingle.role === "Attendance Marker" || userSingle.role === "Institute Support" 
      //     ? userSingle.role
      //     : "None"
      //   : userSingle.role.length > 1 && (userSingle.role[0] !== "Fee Manager" || userSingle.role !== "Institute Support" )
      //   ? userSingle.role[0]
      //   : "None");
    const uploadButton = (
      <div>
        {this.state.imgLoading ? (
          <LoadingOutlined />
        ) : userSingle.name ? (
          <Avatar
            src={`${Api._s3_url}${userSingle.image}`}
            alt=""
            style={{ width: "100%" }}
          />
        ) : (
          <CameraOutlined style={{ color: Color.primary, fontSize: "2em" }} />
        )}
      </div>
    );
    const { imageUrl } = this.state;

    console.log('See userSingle.dob', userSingle.dob)
    return (
      <div>
        <Modal
          visible={this.props.userModalBool}
          centered
          width={900}
          closable={true}
          maskClosable={false}
          onCancel={() => this.props.userModalVisible({}, false)}
          footer={[
            <div className="r-c-c">
              {allowEdit ? userSingle.id ? (
                <Button
                  loading={userAddLoader}
                  className="mod-assign-btn"
                  key="back"
                  onClick={() => this.pageUserEdit(userSingle)}
                >
                  Save {positionCurrent}
                </Button>
              ) : (
                <Button
                  loading={userAddLoader}
                  className="mod-assign-btn"
                  key="back"
                  onClick={() => this.pageAdminAdd()}
                >
                  Add {positionCurrent}
                </Button>
              ) : null}
              <Button
                className="mod-cancel-btn"
                key="submit"
                loading={false}
                onClick={() => this.handleCancelClick()}
              >
                Cancel
              </Button>
            </div>
          ]}
        >
          <div>
            <div className="r-c-sb">
              <Title style={{ color: Color.primary, margin: 0 }} level={4}>
                {allowEdit ? userSingle.id ? "Edit" : "Add" : "View"} {positionCurrent}
              </Title>
              <div className="r-ac">
               { <Upload
                  name="avatar"
                  listType="picture-card"
                  className="administratorUploader cursor-pointer"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={this.beforeUpload}
                  onChange={this.uploadImage}
                  disabled={!allowEdit}
                >
                  {imageUrl ? (
                    <Avatar
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>}
                <div
                  className="bold-600 text-sm m-l-10"
                  style={{ color: Color.darkGrey }}
                >
                  {userSingle.image && userSingle.image != "default.png" ? "Change Image" : "Upload Image"}
                  <span style={{ color: Color.red }}>
                    {/* {imageUrl ? "" : "*"} */}
                  </span>
                </div>
              </div>
            </div>

            <div className="r-ac m-b-20 m-t-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  Name <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      //value = value.replace(/[^A-Za-z ]/ig, '')
                      this.handleUserInput(value, "name")
                    }}
                    //onChange={e => {}} //this.handleUserInput(e.target.value, "name")
                    value={userSingle.name}
                    placeholder="Enter name"
                    disabled={!allowEdit}
                    className="userInput"
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                  />
                </div>
              </div>
              <div className="r-ac flex-1">
                <div className="m-r-20">
                  <div className="dark-grey">
                    Gender 
                  </div>
                  <div
                    className="r-c-sb"
                    style={{
                      backgroundColor: "#F9F9F9",
                      padding: 5,
                      borderRadius: 5
                    }}
                  >
                    <Select
                      onChange={e => this.handleUserInput(e, "gender")}
                      className="borderLessSelect bold-600"
                      value={userSingle.gender}
                      placeholder="Select gender"
                      style={{
                        color: Color.primary,
                        width: "100px",
                        fontSize: 16
                      }}
                      disabled={!allowEdit}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </div>
                </div>
                <div className="m-r-20">
                  <div className="dark-grey">
                    Blood Group 
                  </div>
                  <div
                    className="r-c-sb"
                    style={{
                      backgroundColor: "#F9F9F9",
                      padding: 5,
                      borderRadius: 5
                    }}
                  >
                    {/* <Input onChange={(e) => this.handleUserInput(e.target.value, 'bloodGroup')}  value={userSingle.bloodGroup} placeholder="B +ve"  style={{border:'none', width:'100px',  color:Color.primary, fontSize:16, fontWeight:600}}/> */}
                    <Select
                      onChange={e => this.handleUserInput(e, "bloodGroup")}
                      className="borderLessSelect bold-600"
                      value={userSingle.bloodGroup}
                      placeholder="Select blood group"
                      style={{
                        color: Color.primary,
                        width: "100px",
                        fontSize: 16
                      }}
                      disabled={!allowEdit}
                    >
                      <Option value="NA">NA</Option>
                      <Option value="A-">A-</Option>
                      <Option value="A+">A+</Option>
                      <Option value="B-">B-</Option>
                      <Option value="B+">B+</Option>
                      <Option value="O-">O-</Option>
                      <Option value="O+">O+</Option>
                      <Option value="AB+">AB+</Option>
                      <Option value="AB-">AB-</Option>
                    </Select>
                  </div>
                </div>
                <div className="">
                  <div className="dark-grey">
                    Date Of Birth 
                  </div>
                  <div
                    className="r-c-sb"
                    style={{
                      backgroundColor: "#F9F9F9",
                      padding: 5,
                      borderRadius: 5
                    }}
                  >
                    <DatePicker
                      onChange={e => this.handleDobChange(e)}
                      format="DD-MM-YYYY"
                      disabledDate={disabledDate}
                      disabled={!allowEdit}
                      value={
                        userSingle.dob !== '0000-00-00' ?
                        userSingle.dob
                          ? moment(userSingle.dob, "YYYY/MM/DD")
                          : null :
                        ''
                      }
                      style={{
                        border: "none",
                        width: "150px",
                        color: Color.primary,
                        fontSize: 16,
                        fontWeight: 600
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Email ID</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input
                    onChange={e =>
                      this.handleUserInput(e.target.value, "email")
                    }
                    value={userSingle.email}
                    placeholder="Enter email"
                    style={{
                      border: "none",
                      width: "100%",
                      color: (!userSingle.id || allowEdit) ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={userSingle.id || !allowEdit}
                  />
                </div>
                <div style={{ color: "red", fontSize: 10, padding: 2 }}>
                  Email ID is not editable
                </div>
              </div>
              <div className="flex-1">
                <div className="dark-grey">
                  Phone Number <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5,
                    marginBottom: 10
                  }}
                >
                  <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    disabled={userSingle.id || !allowEdit}
                    value={userSingle.phone}
                    onCountryChange={e => console.log("code", e)}
                    onChange={e => this.handleUserInput(e, "phone")}
                    maxLength={(userSingle.phone?.startsWith('+91') || userSingle.phone?.startsWith('91')) && userSingle.phone?.length == 13 ? 11 : ""}
                  />
                  {/* <Input onChange={(e) => this.handleUserInput(e.target.value, 'phone')}  value={userSingle.phone} placeholder="Enter Phone"  style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}/>*/}
                </div>
                <div style={{ color: "red", fontSize: 10, padding: 2 }}>
                  Phone no is not editable
                </div>
              </div>
            </div>
            <div className="dark-grey" style={{fontSize: 16}}>
              Current Address <span style={{ color: Color.red }}>*</span>
            </div>
            <Divider />
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  House No./ Locality/ Area 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="text"
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      this.handleUserInput({...userSingle.currentAddressRaw, locality: value}, "currentAddressRaw")
                    }}
                    value={userSingle?.currentAddressRaw?.locality}
                    placeholder="Enter House No./ Locality/ Area"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  City <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="text"
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      value = value.replace(/[^A-Za-z ]/ig, '')
                      this.handleUserInput({...userSingle.currentAddressRaw, city: value}, "currentAddressRaw")
                    }}
                    value={userSingle?.currentAddressRaw?.city}
                    placeholder="Enter City"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  State <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="text"
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      value = value.replace(/[^A-Za-z ]/ig, '')
                      this.handleUserInput({...userSingle.currentAddressRaw, state: value}, "currentAddressRaw")
                    }}
                    value={userSingle?.currentAddressRaw?.state}
                    placeholder="Enter State"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  PIN / ZIP Code 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="number"
                    onChange={e =>
                      this.handleUserInput({...userSingle.currentAddressRaw, pincode: e.target.value}, "currentAddressRaw")
                    }
                    value={userSingle?.currentAddressRaw?.pincode}
                    placeholder="Enter PIN / ZIP code"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
            </div>
            <div className="dark-grey" style={{fontSize: 16}}>
              Permanent Address 
            </div>
            <Divider />
            <Row className='m-t-20 m-b-20'>
              <span className="m-r-5"><Checkbox checked={permanentAddressChkboxBool} onClick={e => this.updatePermanentAddress(e.target.checked)}/></span> Same as Current Address
            </Row>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  House No./ Locality/ Area 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input
                    type="text"
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      this.handleUserInput({...userSingle.permanentAddressRaw, locality: value}, "permanentAddressRaw")
                    }}
                    value={userSingle?.permanentAddressRaw?.locality}
                    placeholder="Enter House No./ Locality/ Area"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  City 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="text"
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      value = value.replace(/[^A-Za-z ]/ig, '')
                      this.handleUserInput({...userSingle.permanentAddressRaw, city: value}, "permanentAddressRaw")
                    }}
                    value={userSingle?.permanentAddressRaw?.city}
                    placeholder="Enter City"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  State 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="text"
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      value = value.replace(/[^A-Za-z ]/ig, '')
                      this.handleUserInput({...userSingle.permanentAddressRaw, state: value}, "permanentAddressRaw")
                    }}
                    value={userSingle?.permanentAddressRaw?.state}
                    placeholder="Enter State"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  PIN / ZIP Code 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input 
                    type="number"
                    onChange={e =>
                      this.handleUserInput({...userSingle.permanentAddressRaw, pincode: e.target.value}, "permanentAddressRaw")
                    }
                    value={userSingle?.permanentAddressRaw?.pincode}
                    placeholder="Enter PIN / ZIP code"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
            </div>
            <div className="m-b-10 bold-600" style={{ color: Color.primary }}>
              Emergency Contact
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  Name 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Input
                    onChange={(em) => {
                      let value = em.target.value
                      value = value.replace(/\s\s+/g, '');
                      //value = value.replace(/[^A-Za-z ]/ig, '')
                      this.handleUserInput(
                        value,
                        "emergencyContactName"
                      )
                    }}
                    value={userSingle.emergencyContactName}
                    placeholder="Enter name"
                    style={{
                      border: "none",
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600
                    }}
                    disabled={!allowEdit}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="dark-grey">
                  Phone Number 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={userSingle.emergencyPhone}
                    onCountryChange={e => console.log("code", e)}
                    onChange={e => this.handleUserInput(e, "emergencyPhone")}
                    disabled={!allowEdit}
                    maxLength={(userSingle.emergencyPhone?.startsWith('+91') || userSingle.emergencyPhone?.startsWith('91')) && userSingle.emergencyPhone?.length == 13 ? 11 : ""}
                  />
                </div>
              </div>
            </div>

            <div className="r-ac">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  Select Permission 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "200px",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  <Select
                    className="borderLessSelect bold-600"
                    value={role}
                    placeholder="Select permission"
                    style={{
                      width: "100%",
                      color: allowEdit ? Color.primary  : Color.aeGrey,
                      fontSize: 16
                    }}
                    onChange={e => this.handleUserInput(e, "role")}
                    disabled={!allowEdit}
                  >
                     <Option key="None" value="None">
                      None
                    </Option>
                    {user.role.includes("SuperAdmin") && 
                      <>
                        <Option key="Admin" value="Admin">
                          Admin
                        </Option>
                      </>
                    }
                    {positionCurrent !== "Teacher" &&
                    <Option key="Attendance Marker" value="Attendance Marker">
                      Attendance Marker
                    </Option>}
                  </Select>
                </div>
              </div>
              {user.role.includes("SuperAdmin") &&  user.services.includes("fee") ? 
              <div className="flex-1">
                <div className="dark-grey">
                  Financial Privilege 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  Allow Fee Access? <Switch onChange={e => this.handleUserInput(e, "feeAccess")} checked={userSingle.feeAccess} />
                  {/* <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={userSingle.emergencyPhone}
                    onCountryChange={e => console.log("code", e)}
                    onChange={e => this.handleUserInput(e, "emergencyPhone")}
                    disabled={!allowEdit}
                  /> */}
                  {/*<Input onChange={(e) => this.handleUserInput(e.target.value, 'emergencyPhone')}  value={userSingle.emergencyPhone} placeholder="Enter Phone"  style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}/> */}
                </div>
              </div> : null
              }
            </div>
            <div className="r-ac m-t-30">
              {user.role.includes("SuperAdmin") &&  user.services.includes("care") ? 
              <div className="flex-1">
                <div className="dark-grey">
                  Support Privilege 
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "300px",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  Allow Support Privilege? <Switch onChange={e => this.handleUserInput(e, "supportAccess")} checked={userSingle.supportAccess} />
                  {/* <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={userSingle.emergencyPhone}
                    onCountryChange={e => console.log("code", e)}
                    onChange={e => this.handleUserInput(e, "emergencyPhone")}
                    disabled={!allowEdit}
                  /> */}
                  {/*<Input onChange={(e) => this.handleUserInput(e.target.value, 'emergencyPhone')}  value={userSingle.emergencyPhone} placeholder="Enter Phone"  style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}/> */}
                </div>
              </div> : null
              }
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    userModalBool,
    userSingle,
    userAddLoader,
    positionCurrent,
    studentStandards,
    filterParams,
    allowSuperAdminCreation,
    permanentAddressChkboxBool
  } = state.user;
  const {user} = state.session;
  const { orgId } = state.session.user;
  return {
    userModalBool,
    userSingle,
    userAddLoader,
    positionCurrent,
    studentStandards,
    orgId,
    filterParams,
    allowSuperAdminCreation,
    user,
    permanentAddressChkboxBool
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserInput: (value, key) => dispatch(updateAllUserInput(value, key)),
  saveUser: (value, positionCurrent) =>
    dispatch(saveUser(value, positionCurrent)),
  editUsers: (value, positionCurrent) =>
    dispatch(editUsers(value, positionCurrent)),
  userModalVisible: (data, value) => dispatch(userModalVisible(data, value)),
  togglePermanentAddressCheckbox: bool => dispatch(togglePermanentAddressCheckbox(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
