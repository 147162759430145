import React, { useEffect, useState, useRef, useCallback } from 'react'
import emptyImage from '../../Assets/schedule/Group 10647.svg'
import CreateFormModal from './modals/createForm';
import PieChartCard from './pieChartCard';
import BarGraphCard from './BarGraph';
import OngoingClassCard from './onGoingClassCard';
import ScheduleClassCard from './scheduledClass';
import CompletedClassCard from './completedClassCard';
import CancelledClassCard from './cancelledClassCard';
import Timetable from './timetable';
import PageDataLoader from 'components/pageDataLoader';
import ScheduleCalender from './sceduleCalender'
import moment from 'moment';
import RecordingsModal from 'pages/broadcast/localComponent/recordingsModal'
import Date_picker from './datePicker'
import { Tabs, Image, Typography, Select, Row, Col, DatePicker, Input, Table, Checkbox, Alert } from 'antd';
import { getRecordings, setCompletedSubName } from 'actions/broadcast';
import { fetchSchedule, fetchTimetableData, fetchScheduleFilter, setTabType, setStdFilterTimeTable, setRoomIds, getJoinUsers, setCurrentDate, setTeacherfilter, setClassFilter, setSubFilter, setMonthOrWeek, setPageToggle, subscriptionAlert, setJoinLoader, setCardData } from '../../actions/schedule'
import { NodataCard } from 'pages/Test';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ArrowLeftOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './styles.css'
import PageHeader from 'components/pageHeader';
import { Heading } from 'components/Typography';
import { Button } from 'components/Button';
import { InputSearch } from 'components/customInputs';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { EventRegister } from 'react-native-event-listeners';
import { setQuickAccessItem } from 'actions/userManagementV2';

const { TabPane } = Tabs
const { Text } = Typography
const { Option } = Select
const { Search } = Input

let intervalId = null;

function useInterval(callback, delay) {
    const savedCallback = useRef(callback);
    const intervalRef = useRef(null);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            intervalRef.current = id;
            return () => clearInterval(id);
        }
    }, [delay]);

    useEffect(() => {
        // clear interval on when component gets removed to avoid memory leaks
        return () => clearInterval(intervalRef.current);
    }, [])

    const reset = useCallback(() => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(tick, delay)
        }
    });

    const stop = useCallback(() => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
    })

    return {
        reset,
        stop
    };
}
const ScheduleAdmin = ({
    fetchSchedule, scheduleList, summaryDataList, timetableDataList, fetchTimetableData, stdListFilter, fetchScheduleFilter, todayActivities,
    subListFilter, isMonthOrWeek, setTabType, setStdFilterTimeTable, stdTimetable, roomIds, setRoomIds, getJoinUsers, access, setCurrentDate, currentDate, setTeacherfilter,
    setClassFilter, setSubFilter, setMonthOrWeek, getRecordings, setCompletedSubName, classFilter, subFilter, pageToggle, setPageToggle, subscriptionAlert, subsAlertData, user,
    setJoinLoader, setCardData, setQuickAccessItem, quickAccessItem
}) => {
    const [key, SetKey] = useState("1")
    const [isModalVisible, setModalVisible] = useState(false)
    const [std, setStd] = useState([])
    const [sub, setSub] = useState([])
    const [date, setDate] = useState('')
    const [topic, setTopic] = useState('')
    const [stdTimetableFilter, setStdTimetableFilter] = useState(null)
    const [pageLoader, togglePageLoader] = useState(false)
    let [listToShow, setListToShow] = useState([])
    let [noOfitemToShow, setNoOfItemToShow] = useState(6)
    const [visibleButton, setVisibleButton] = useState(true)
    const [isRecordingsModalVisible, setRecordingsModalVisibility] = useState(false);
    const [futureDate, setFutureDate] = useState(moment().format('YYYY/MM/DD'))
    const [teacherName, setTeacherName] = useState('')
    const [location, setLocation] = useState(0)
    const location1 = useLocation()



    useEffect(() => {
        if(quickAccessItem == 'addSchedule'){
            setModalVisible(true)  
    }
    }, []);
    

    let roomId = []
    const handleButton = () => {
        SetKey('2');
    }
    const handleTab = (key) => {
        SetKey(key)
        setStdTimetableFilter(null)

    }


    useEffect(() => {
        setJoinLoader(false);
        if (key == "1") {
            setTabType('schedule')
            if (pageToggle == '1') {
                console.log('page1', pageToggle)
                togglePageLoader(true)
                fetchSchedule(() => togglePageLoader(false), date)
                setCurrentDate(moment().format('DD/MM/YYYY'))
                setTeacherName('')
                setMonthOrWeek('week')
                setDate(moment().format('DD/MM/YYYY'))
                setClassFilter([])
                setSubFilter([])
            }
            else {
                setPageToggle('1')
                togglePageLoader(true)
                fetchScheduleFilter(moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), topic, std, sub, () => togglePageLoader(false), date)
                setTeacherName(teacherName)
                setMonthOrWeek('week')
                setDate(moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                setClassFilter([])
                setSubFilter([])
            }
        }
        else if (key == "2") {
            setTabType('timetable')
            togglePageLoader(true)
            fetchTimetableData('', () => togglePageLoader(false))
        }



    }, [key])

    useEffect(() => {
        if (scheduleList?.scheduled?.length != 0) {
            if (noOfitemToShow < scheduleList?.scheduled?.length) {
                setVisibleButton(false)
            }
            if (noOfitemToShow <= scheduleList?.scheduled?.length) {
                listToShow = scheduleList?.scheduled?.slice(0, noOfitemToShow)
            }
            else {
                listToShow = scheduleList?.scheduled?.slice(0, scheduleList?.scheduled?.length)
            }
            setListToShow(listToShow)
        }
    }, [scheduleList?.scheduled, noOfitemToShow])

    useEffect(() => {
        if (scheduleList?.ongoing?.length != 0) {
            scheduleList?.ongoing?.map((item) => {
                roomId.push(item.roomName)
            })
            setRoomIds(roomId)
        }

    }, [scheduleList?.ongoing])

    useEffect(() => {
        setVisibleButton(true)
    }, [currentDate])

    useEffect(() => {
        subscriptionAlert();
    }, []);

    const { reset, stop } = useInterval(() => {
        if (subsAlertData?.status == undefined || subsAlertData?.status === "Fetching" || subsAlertData?.status === "Invalid") {
            subscriptionAlert();
        } else {
            stop();
        }
    }, 5000);

    useEffect(() => {
        setTabType('timetable')
        setStdTimetableFilter(location1?.state?.classroom?.classId)
        setStdFilterTimeTable(location1?.state?.classroom?.classId)
        togglePageLoader(true);
        fetchTimetableData(location1?.state?.classroom?.classId, () => togglePageLoader(false), null);
    }, [])
    const apilistener = useRef(null);
    useEffect(()=>{
        apilistener.current = EventRegister.addEventListener('apicall:scheduleHome', ()=>{
            togglePageLoader(true)
            fetchSchedule(() => togglePageLoader(false), date)
        })
        return () => {
            EventRegister.removeEventListener(apilistener.current)
        }
    }, [date])

    const onSelectStdFilter = (value, obj) => {
        setStdTimetableFilter(value)
        setStdFilterTimeTable(value)
        togglePageLoader(true)
        fetchTimetableData(value, () => togglePageLoader(false), futureDate)
    }

    const onChangeDateFilter = (date, dateString) => {
        console.log("dateString", dateString);
        setDate(dateString)
        setCurrentDate(dateString)
        fetchScheduleFilter(dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '', topic, std, sub)
    }

    const onSearchTeacher = (value) => {
        setTopic(value)
        setTeacherfilter(value)
        fetchScheduleFilter(date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '', value, std, sub)
        setTeacherName(value)
    }
    const onChangeStdSchedule = (value) => {
        setStd(value)
        setClassFilter(value)
        fetchScheduleFilter(date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '', topic, value, sub)
    }

    const onChangeSubSchedule = (value) => {
        setSub(value)
        setSubFilter(value)
        fetchScheduleFilter(date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '', topic, std, value)
    }


    const handleViewMore = () => {
        setNoOfItemToShow(noOfitemToShow + 3)
        setVisibleButton(true)
    }

    const handleReplay = (streamId, subject, completedItem) => {
        setCardData(completedItem)
        setCompletedSubName(subject.name);
        getRecordings(streamId, () => {
            setRecordingsModalVisibility(true);
        });
    }

    const history = useHistory();

    function numberWithCommas(x) {
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
    }

    const actions = [
        (user?.privilage?.includes('Planner') || user?.privilage?.includes('Admin') || user?.privilage?.includes('SuperAdmin')) ?
            <Button type="primary" onClick={() => { setModalVisible(true) }} style={{}} icon={<PlusCircleOutlined />}>CREATE SCHEDULE</Button>
            : null
    ]

    return (
        <div style={{ width: '100%' }}>
            {location1?.state?.classroom?.classId ?
                <div style={{ display: "flex", justifyContent: "space-between", height: 50, borderBottom: "1px solid #E6E6E6" }}>
                    <div style={{ fontSize: "2em", fontWeight: 700, display: "flex", flexDirection: "row" }}>
                        <ArrowLeftOutlined
                            style={{ marginTop: "15px", fontSize: "20px" }}
                            onClick={() => {
                                history.goBack();
                            }} />
                        <div style={{ marginLeft: "15px" }}>{"Schedule"}</div>
                    </div>
                    {(user?.privilage?.includes('Planner') || user?.privilage?.includes('Admin') || user?.privilage?.includes('SuperAdmin')) ?
                        <Button type="primary" onClick={() => { setModalVisible(true) }} style={{}} icon={<PlusCircleOutlined />}>CREATE SCHEDULE</Button>
                        : null}
                </div>
                :
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ marginTop: -10, height: 50 }}>
                        <PageHeader title='Schedule' actions={actions} />
                    </div>
                    {/* {key == '1' && access == true ?
                        <div>
                            <Button className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118' onClick={() => setModalVisible(true)}><PlusCircleOutlined/> CREATE</Button>
                        </div>
                        : null} */}
                    {(isModalVisible && <CreateFormModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setFutureDate={setFutureDate} futureDate={futureDate} setQuickAccessItem={setQuickAccessItem} />)}
                </div>
            }
            {user?.privilage?.includes("SuperAdmin") ?
                // <div className='display-flex' style={{ height: 35, width: '100%', backgroundColor: "#F6F4FE", borderRadius: 3, borderColor: "#E6E6E6", border: "2px solid #E6E6E6" }}>
                // <div className='m-r-10'>
                //     <img src={infoImg} style={{ height: 17, width: 17 }} className="m-l-10 m-t-5" />
                // </div>
                <div className='m-t-30'>
                    {subsAlertData?.freeHours === true ?
                        subsAlertData?.userHoursUsed >= subsAlertData?.totalUserHours ?
                            <div className='text-xmd m-t-5' style={{ color: '#636363', fontWeight: 400 }}>
                                <Alert showIcon icon={<InfoCircleOutlined />} message={<div>You have used up all your free Broadcast hours. To keep using this feature, <span onClick={() => { history.push("/broadcast-packages") }} className='text-xmd bold-600 blue cursor-pointer'>BUY A PLAN.</span></div>}></Alert>
                            </div>
                            :
                            <div className='text-xmd m-t-5' style={{ color: '#636363', fontWeight: 400 }}>
                                <Alert showIcon icon={<InfoCircleOutlined />} message={<div>You have used up {(subsAlertData?.userHoursUsed?.toFixed(2))} hours of your free Broadcast. Your remaining balance is {numberWithCommas(Math.floor(subsAlertData?.totalUserHours - subsAlertData?.userHoursUsed))} free hours. To keep using this feature <span onClick={() => { history.push("/broadcast-packages") }} className='text-xmd bold-600 blue cursor-pointer'>BUY A PLAN.</span></div>}></Alert>
                            </div>
                        :
                        subsAlertData?.userHoursUsed >= subsAlertData?.totalUserHours ?
                            <div className='text-xmd m-t-5' style={{ color: '#636363', fontWeight: 400 }}>
                                <Alert showIcon icon={<InfoCircleOutlined />} message={<div> You have used up all your user hours.To keep using this feature <span onClick={() => {
                                    history.push({

                                        pathname: "/broadcast-packages",
                                        state: { key: (location + 1) }
                                    })
                                }} className='text-xmd bold-600 blue cursor-pointer'>, RECHARGE.</span></div>}></Alert>
                            </div>
                            :
                            <div className='text-xmd m-t-5' style={{ color: '#636363', fontWeight: 400 }}>
                                <Alert showIcon icon={<InfoCircleOutlined />} message={<div>You have used up {(subsAlertData?.userHoursUsed?.toFixed(2))} hours of your user hours Broadcast. Your remaining balance is {numberWithCommas(Math.floor(subsAlertData?.totalUserHours - subsAlertData?.userHoursUsed))} user hours. To keep using this feature, <span onClick={() => {
                                    history.push({
                                        pathname: "/broadcast-packages",
                                        state: { key: (location + 1) }
                                    })
                                }} className='text-xmd bold-600 blue cursor-pointer'>RECHARGE.</span></div>}></Alert>
                            </div>
                    }
                </div>
                : null}
            <div>
                <Tabs /* activeKey={key} */ onTabClick={handleTab} defaultActiveKey={location1?.state?.classroom?.classId ? '2' : '1'}>
                    <TabPane tab="Schedule" key="1">
                        {/* {    true||todayActivities?.scheduled == 0 && todayActivities?.ongoing == 0 && todayActivities?.completed == 0 && todayActivities?.cancelled == 0 ? */}
                        {/* {todayActivities?.scheduled == 0 && todayActivities?.ongoing == 0 && todayActivities?.completed == 0 && todayActivities?.cancelled == 0 &&
                         scheduleList?.ongoing?.length == 0 && scheduleList?.scheduled?.length == 0 && scheduleList?.completed?.length == 0 && scheduleList?.cancelled?.length == 0?
                            <div className="schedule-main" style={{height:"70vh"}}>
                                <div className="schedule-empty">
                                    <Image
                                        src={emptyImage}
                                        preview={false}
                                    />
                                    <Text style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'roboto',marginTop:"10px" }}>No live classes scheduled!</Text>
                                    <Text style={{ color: "#636363", fontSize: '14px', fontFamily: 'roboto', textAlign: 'center', marginBottom: '8px' }}>There are no live classes scheduled.</Text>
                                    <Button type="primary" size='large' style={{ borderRadius: "20px", width: "120px" }} onClick={handleButton}>Timetable</Button>
                                </div>
                            </div> : */}
                        <div style={{ padding: '10px' }}>
                            <Row style={{ width: "100%" }}>
                                <Col sm={{ span: 18 }} md={{ span: 18 }} lg={{ span: 16 }} xl={{ span: 10 }}>
                                    <div
                                        className="pieChartCard m-r-10 m-t-10"
                                    >
                                        <Text style={{ fontWeight: 'bold', fontSize: '14px', fontFamily: 'roboto' }}>Today's activities</Text>
                                        {todayActivities?.scheduled !== 0 || todayActivities?.ongoing !== 0 || todayActivities?.completed !== 0 || todayActivities?.cancelled !== 0 ?
                                            <div style={{ height: '240px' }}>
                                                <PieChartCard activitiesData={todayActivities} />
                                            </div>
                                            :
                                            <NodataCard />
                                        }
                                    </div>
                                </Col>
                                <Col sm={{ span: 30 }} md={{ span: 18 }} lg={{ span: 16 }} xl={{ span: 14 }}>
                                    <div
                                        className="barChartCard m-r-10 m-t-10"
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Text style={{ fontWeight: 'bold', fontSize: '14px', fontFamily: 'roboto' }}>Summary</Text>
                                                {isMonthOrWeek == 'week' ?
                                                    <Text style={{ fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(summaryDataList[0]?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}-${moment(summaryDataList[summaryDataList?.length - 1]?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`}</Text> :
                                                    <Text style={{ fontSize: '10px', fontFamily: 'roboto' }}>{summaryDataList[0]?.month}-{summaryDataList[summaryDataList?.length - 1]?.month} {moment().year()}</Text>}
                                            </div>
                                            <div style={{ width: '180px' }}>
                                                <ScheduleCalender togglePageLoader={togglePageLoader} />
                                            </div>
                                        </div>
                                        <div style={{ height: '240px' }}>
                                            <BarGraphCard barGraphData={summaryDataList} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <div style={{ marginRight: '10px', marginBottom: '4px' }}>
                                        <DatePicker showToday={true} onChange={onChangeDateFilter} value={currentDate ? moment(currentDate, 'DD/MM/YYYY') : moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')} format={'DD/MM/YYYY'} />
                                    </div>
                                    <div>
                                        <InputSearch placeholder="Search by teacher"
                                            style={{ fontSize: "1em"}}
                                            allowClear
                                            onEnter={onSearchTeacher}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                                    <div style={{ width: '220px' }}>
                                        <Select
                                            mode='multiple'
                                            showSearch
                                            allowClear={true}
                                            style={{ width: '100%', borderRadius: '8px', marginRight: '4px', minHeight: '32px', marginBottom: '4px' }}
                                            placeholder="Select standard"
                                            optionFilterProp="children"
                                            onChange={onChangeStdSchedule}
                                            filterOption={true}
                                            value={classFilter}
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}

                                        >
                                            {stdListFilter?.length != 0 ? stdListFilter.map((item, index) => (
                                                <Option key={index} value={item.id}>{item.std + '-' + item.section}</Option>
                                            ))
                                                : null}
                                        </Select>
                                    </div>
                                    <div style={{ width: '220px', marginBottom: '4px' }}>
                                        <Select
                                            mode='multiple'
                                            showSearch
                                            allowClear={true}
                                            style={{ width: '100%', borderRadius: '8px', marginRight: '4px', minHeight: '32px' }}
                                            placeholder="Select subject"
                                            optionFilterProp="children"
                                            onChange={onChangeSubSchedule}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={subFilter}
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}
                                        >
                                            {subListFilter?.length != 0 ? subListFilter.map((item, index) => (
                                                <Option key={index} value={item?.id}>{item?.name}</Option>
                                            )) : null}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            {scheduleList?.ongoing?.length != 0 || scheduleList?.scheduled?.length != 0 || scheduleList?.completed?.length != 0 || scheduleList?.cancelled?.length != 0 ?
                                // { false ?
                                <div  >
                                    {scheduleList?.ongoing?.length != 0 ?
                                        <div style={{ marginTop: '8px' }}>
                                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Ongoing live classes</Text>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                {scheduleList?.ongoing?.map((item, index) => (
                                                    <OngoingClassCard onGoingItem={item} togglePageLoader={togglePageLoader} />
                                                )
                                                )}
                                            </div>
                                        </div>

                                        : null}

                                    {scheduleList?.scheduled?.length != 0 ?
                                        <div style={{ marginTop: '16px' }}>
                                            <Heading >Scheduled classes</Heading>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                {listToShow?.map((item, index) => (<ScheduleClassCard scheduleItem={item} index={index} togglePageLoader={togglePageLoader} />))}
                                            </div>
                                            <div style={{ marginTop: '10px', width: '100%', display: visibleButton ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button style={{ borderRadius: "16px", borderColor: 'lightskyblue' }} onClick={() => handleViewMore()} >View More</Button>
                                            </div>
                                        </div>
                                        : null}
                                    {scheduleList?.completed?.length != 0 ?
                                        <div style={{ marginTop: '16px' }}>
                                            <Heading >Completed classes</Heading>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                {scheduleList?.completed?.map((item, index) => (<CompletedClassCard completedItem={item} handleReplay={handleReplay} />))}
                                            </div>
                                        </div>
                                        : null}
                                    {scheduleList?.cancelled?.length != 0 ?
                                        <div style={{ marginTop: '16px' }}>
                                            <Heading >Cancelled classes</Heading>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                {scheduleList?.cancelled?.map((item, index) => (<CancelledClassCard cancelledItem={item} />))}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                :
                                <div className="schedule-main" >
                                    <div className="schedule-empty" >
                                        <Image
                                            src={emptyImage}
                                            preview={false}
                                        />
                                        <Text style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'roboto' }}>No live classes scheduled for the selected date</Text>
                                        <Text style={{ color: "#636363", fontSize: '14px', fontFamily: 'roboto', textAlign: 'center', marginBottom: '8px' }}>There are no live classes scheduled.</Text>
                                        {(user?.privilage?.includes('Admin') || user?.privilage?.includes('Planner') || user?.privilage?.includes('SuperAdmin')) ?
                                            <Button type='primary' className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118' icon={<PlusCircleOutlined />} onClick={() => setModalVisible(true)}>CREATE</Button>
                                            : null
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        {/* // } */}
                    </TabPane>
                    <TabPane tab="Timetable" key="2" >

                        <div >
                            <div className="timetable-fiter-button">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "220px" }}>
                                        <Select
                                            allowClear={stdTimetableFilter ? true : false}
                                            style={{ width: "220px" }}
                                            onChange={onSelectStdFilter}
                                            value={stdTimetableFilter}
                                            placeholder="Select standard"
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}
                                        >
                                            {stdListFilter?.length != 0 ? stdListFilter.map((item, index) => (
                                                <Option value={item.id}>{item.std + '-' + item.section}</Option>
                                            ))
                                                : null}
                                        </Select>
                                    </div>
                                    <div style={{ width: "200px", marginLeft: "20px" }}>
                                        <Date_picker id={stdTimetableFilter} setDate={setFutureDate} value={futureDate} />
                                    </div>
                                </div>
                                {/* {access == true ?
                                    <div>
                                        <Button className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118' onClick={() => setModalVisible(true)}><PlusCircleOutlined/> Create</Button>
                                    </div>
                                    : null} */}
                                {(isModalVisible && <CreateFormModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setFutureDate={setFutureDate} futureDate={futureDate} setQuickAccessItem={setQuickAccessItem}/>)}
                            </div>
                            {stdTimetableFilter == null ?
                                <div style={{ marginTop: '14px' }}>
                                    <div className="schedule-main">
                                        <div className="schedule-empty">
                                            <Image
                                                src={emptyImage}
                                                preview={false}
                                            />
                                            <Text style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'roboto' }}>No classes selected yet!</Text>
                                            <Text style={{ color: "#636363", fontSize: '14px', fontFamily: 'roboto', textAlign: 'center', marginBottom: '8px' }}>Please select the class to check the timetable.</Text>
                                        </div>
                                    </div>
                                </div> :

                                <div>
                                    <Timetable timetableDataSource={timetableDataList} togglePageLoader={togglePageLoader} />
                                </div>
                            }
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <PageDataLoader visible={pageLoader} />
            <RecordingsModal isRecordingsModalVisible={isRecordingsModalVisible}
                setRecordingsModalVisibility={setRecordingsModalVisibility} />
        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        scheduleList,
        summaryDataList,
        timetableDataList,
        stdListFilter,
        todayActivities,
        subListFilter,
        isMonthOrWeek,
        stdTimetable,
        roomIds,
        access,
        currentDate,
        classFilter,
        subFilter,
        pageToggle,
        subsAlertData,
    } = state.schedule;
    const { user } = state.session;
    const {quickAccessItem} = state.userManagementV2

    return {
        scheduleList,
        summaryDataList,
        timetableDataList,
        stdListFilter,
        todayActivities,
        subListFilter,
        isMonthOrWeek,
        stdTimetable,
        roomIds,
        access,
        currentDate,
        classFilter,
        subFilter,
        pageToggle,
        subsAlertData,
        user,
        quickAccessItem
    };
};


const mapDispatchToProps = (dispatch) => ({
    fetchSchedule: (callback, date) => dispatch(fetchSchedule(callback, date)),
    fetchTimetableData: (value, callback, date) => dispatch(fetchTimetableData(value, callback, date)),
    fetchScheduleFilter: (date, topic, std, sub, callback) => dispatch(fetchScheduleFilter(date, topic, std, sub, callback)),
    setTabType: (tab) => dispatch(setTabType(tab)),
    setStdFilterTimeTable: (std) => dispatch(setStdFilterTimeTable(std)),
    setRoomIds: (roomIds) => dispatch(setRoomIds(roomIds)),
    getJoinUsers: () => dispatch(getJoinUsers()),
    setCurrentDate: (date) => dispatch(setCurrentDate(date)),
    setTeacherfilter: (teacher) => dispatch(setTeacherfilter(teacher)),
    setClassFilter: (clasess) => dispatch(setClassFilter(clasess)),
    setSubFilter: (sub) => dispatch(setSubFilter(sub)),
    setMonthOrWeek: (isMonthOrWeek) => dispatch(setMonthOrWeek(isMonthOrWeek)),
    getRecordings: (streamId, callback) => dispatch(getRecordings(streamId, callback)),
    setCompletedSubName: (name) => dispatch(setCompletedSubName(name)),
    setPageToggle: (value) => dispatch(setPageToggle(value)),
    subscriptionAlert: () => dispatch(subscriptionAlert()),
    setJoinLoader: (bool) => dispatch(setJoinLoader(bool)),
    setCardData: (val) => dispatch(setCardData(val)),
    setQuickAccessItem: (res) => dispatch(setQuickAccessItem(res)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ScheduleAdmin);
