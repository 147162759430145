import '../styles.css';
import React, { Component } from 'react';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import 'antd/lib/typography/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import { MdClose } from "react-icons/md";
import { Color } from 'services';
import {toggleCreateSubjectModal, postSubjects} from 'actions/classRoom';
import {connect} from 'react-redux';

const { Title } = Typography;

class AddSubjectModal extends Component {
    constructor(){
      super();
      this.state = {
        subjectNames: [
          ''
        ],
        buttonLoader: false
      }
    }

    componentWillUnmount(){
      // this.props.changeDetail({name: "", section: "", stream: ""})
    }

    handleSubjectNameChange(e, index){
      const subjectNames = this.state.subjectNames;
      subjectNames[index] = e.target.value;
      this.setState({subjectNames});
    }

    handleSubjectAdd(){
      const subjectNames = this.state.subjectNames;
      subjectNames.push('');
      this.setState({subjectNames});
    }

    handleCreateClick(){
      this.setState({buttonLoader: true})
      const subjects = this.state.subjectNames.filter(subject => subject !== "");
      this.props.postSubjects(subjects, () => this.setState({buttonLoader: false}))
    }

    render () {
      const { showCreateSubModal, toggleCreateSubjectModal, addSubLoader } = this.props;

      return (
        <Modal
          visible={showCreateSubModal}
          className='modal-border-15'
          width={600}
          closable={false}
          onCancel={() => {}}
          footer={null}
          centered
        >
          <div>
            <div className='r-c-sb'>
              <Title style={{color: Color.primary, margin:0}} level={4}>Create Subject(s)</Title>
              <div className='cursor-pointer' onClick={() => toggleCreateSubjectModal(false)}><MdClose className='text-md dark-grey'/></div>
            </div>
            <div className='m-b-20 m-t-30'>
              <div className='flex-1'>
                <div className='color-black text-xs m-b-5 bold-600'>Subject Name</div>
                <div style={{maxHeight: 200, overflowY: 'auto'}}>
                  {this.state.subjectNames.map((subject, index) => 
                  <Input className=' m-t-10' placeholder="What’s the name of the subject?" 
                  value={subject} onChange={(e) => this.handleSubjectNameChange(e, index)}/>)}
                </div>
              </div>
              {this.state.subjectNames[this.state.subjectNames.length - 1].trim() !== "" ? 
              <div className='text-xmd bold-600 text-right blue cursor-pointer m-t-15'
                onClick={() => this.handleSubjectAdd()}
              >+ Add Another Subject</div> : null}
            </div>
            <div className='r-c-c m-t-40'>
              {console.log(this.state.subjectNames.filter(subject => subject.trim() !== "").length)}
              <Button type='primary' 
                className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118'  
                style={{width:'120px'}}
                disabled={this.state.subjectNames.filter(subject => subject.trim() == "").length > 0}
                loading={this.state.buttonLoader}
                onClick={() => this.handleCreateClick()}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
      )
    }
}


const mapStateToProps = state => {
  const {
    showCreateSubModal, addSubLoader
  } = state.classRoom;
  return {
    showCreateSubModal, addSubLoader
  };
};

const mapDispatchToProps = dispatch => ({
  toggleCreateSubjectModal: (bool) => dispatch(toggleCreateSubjectModal(bool)),
  postSubjects: (subjects, callback) => dispatch(postSubjects(subjects, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubjectModal);
