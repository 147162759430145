import React, { useEffect, useState } from 'react'
import { Modal, Select, Input, Row, Col } from 'antd'
import { Button } from 'components/Button'
import { connect } from 'react-redux'
import { Heading } from 'components/Typography'
import { fetchStandards, setCourseDetails } from 'actions/userManagement'
import { Notification } from 'services'

const EditClass = ({ open, onCancel, selectedClass, UpdateRollNumber, setCourseDetails, storeStudentRollNo, studentRollNo , getStudentsDetailsById, userData}) => {

        console.log({ selectedClass });

        const [rollNo, setRollNo] = useState(selectedClass?.rollNo || '');

        const handleRollNoChange = (e) => {
                const newValue = e.target.value.trim();
                setRollNo(newValue);
                storeStudentRollNo(newValue);
        };
        console.log({ rollNo })

        return (
                <Modal
                        centered
                        closable
                        open={open}
                        footer={null}
                        className='modal-round-corner'
                        onCancel={onCancel}
                >
                        <center>
                                <Heading>Edit roll number</Heading>
                                <Col style={{ padding: 20 }}>
                                        <div className='simGrey bold-600' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 43 }}>Class</div>
                                        <Select
                                                style={{ width: '80%' }}
                                                value={selectedClass?.std}
                                                disabled
                                        >
                                        </Select>

                                        <div className='simGrey bold-600' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 43, marginTop: 20 }}>Section</div>
                                        <Select
                                                style={{ width: '80%' }}
                                                value={selectedClass?.section}
                                                disabled
                                        >
                                        </Select>

                                        <div className='simGrey bold-600' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 43, marginTop: 20 }}>Roll number</div>
                                        <Row style={{ justifyContent: 'space-between', width: '80%' }}>
                                                <div>
                                                        <Input
                                                                onChange={(e) => {
                                                                        if (/[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/.test(e?.target?.value)) {
                                                                                Notification.error('Error', "Please enter roll no without special characters")
                                                                        } else {
                                                                                handleRollNoChange(e)
                                                                        }
                                                                }}
                                                                style={{ width: '100%' }}
                                                                value={rollNo}
                                                        ></Input>
                                                </div>
                                                <div>
                                                        <Button onClick={(e) =>  {UpdateRollNumber(selectedClass?.standardId); getStudentsDetailsById(()=>{}, userData?.id); onCancel()}} type='primary' style={{ backgroundColor: '#1089FF', border: 'none' }}>UPDATE</Button>
                                                </div>
                                        </Row>
                                </Col>
                        </center>
                </Modal>
        )
}

export default EditClass