import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import "./modal.css"

const ImageModal = ( ) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
  };

  const handleOk = () => {
    setIsModalVisible(false);

  };

  const handleCancel = () => {
    setIsModalVisible(false);

  };


  return (
    <div className="imgModal" >
      {/* <Button type="primary" onClick={showModal}>
        image Modal
      </Button> */}
      <Modal className="imgModal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div style={{display:"flex",height:"100%",width:"100%",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <div><img style={{width:"100%" ,height:"100%"}} src={require('Assets/images/imageModal.png').default} alt="" /></div>
        </div>

      </Modal>

    </div>

  );

};

export default ImageModal;
