import React, { useState } from "react";
import { Modal } from "antd";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import { Title, SubHeading, Heading } from "components/Typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {
  CloseCircleOutlined,
  MoreOutlined,
  DownloadOutlined,
  RedoOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  refundPayment,
  toggleUserDetailModal,
  cancelPaymentRequest,
} from "actions/fees";
import { connect } from "react-redux";

import Card from "../../components/card";
import { Api } from "services";

const renderStatus = (status) => {
  let color = "#FFA931";
  if (status == "Link Sent") {
    status = "Pending";
  }
  if (status == "Paid") {
    color = "#28DF99";
  } else if (status == "Refunded") {
    color = "#594099";
  } else if (status == "Overdue") {
    color = "#FF414D";
  } else if (status == "Settled") {
    color = "#1089FF";
  } else if (status == "Cancelled") {
    color = "#bcbcbc";
  } else {
    // Do nothing
  }
  return (
    <SubHeading bold color={color}>
      {status.toUpperCase()}
    </SubHeading>
  );
};

const UserDetail = ({
  visible,
  toggleUserDetailModal,
  userTransactions,
  refundPayment,
  transactionUser,
  cancelPaymentRequest,
  tableName,
}) => {
  const [currentTransaction, setCurrentTransaction] = useState();
  const [cancelLoader, toggleCancelLoader] = useState();
  const menu = (
    <Menu style={{ padding: 10 }}>
      <Menu.Item
        onClick={() => {
          if (currentTransaction?.invoice) {
            window.open(`${Api._s3_url}${currentTransaction?.invoice}`);
          }
        }}
      >
        <>
          <DownloadOutlined />
          <span>Download Receipt</span>
        </>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          refundPayment(currentTransaction);
        }}
      >
        <>
          <RedoOutlined />
          <span>Refund</span>
        </>
      </Menu.Item>
    </Menu>
  );

  const cancelMenu = (id) => (
    <Menu style={{ padding: 10 }}>
      <Menu.Item
        onClick={() => {
          toggleCancelLoader(true);
          cancelPaymentRequest(id, () => toggleCancelLoader(false));
        }}
      >
        <>
          {cancelLoader ? (
            <LoadingOutlined className="m-r-5" />
          ) : (
            <CloseCircleOutlined className="m-r-5" />
          )}
          <span>Cancel Request</span>
        </>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      className="userTransactionDetailModal"
      onCancel={() => toggleUserDetailModal(false)}
      closeIcon={<CloseCircleOutlined style={{ fontSize: 22 }} />}
      title={null}
      visible={visible}
      width={800}
      footer={null}
      style={{
        content: {
          height: "500px",
          overflow: "scroll",
        },
      }}
    >
      <>
        <Row>
          <Title bold color="#594099" style={{ marginBottom: 0 }}>
            {`${tableName} (${userTransactions?.length})`}
          </Title>
        </Row>
        <Row>
          <Heading color="#8C8C8C">{`for`}</Heading>
          <Heading className="m-l-5">{` ${transactionUser?.name}`}</Heading>
        </Row>
        <Row>
          {userTransactions?.map((transaction) => (
            <Col xs={24} md={12} key={transaction.id}>
              <Card>
                <Row justify="space-between" align="middle">
                  <SubHeading bold>{transaction.note}</SubHeading>
                  {transaction.status == "Paid" ? (
                    <Dropdown
                      trigger={["click"]}
                      overlay={menu}
                      placement="bottomRight"
                      onClick={() => setCurrentTransaction(transaction)}
                    >
                      <MoreOutlined
                        className="cursor-pointer"
                        style={{ fontSize: 20 }}
                      />
                    </Dropdown>
                  ) : transaction.status == "Link Sent" ||
                    transaction.status == "Overdue" ? (
                    <Dropdown
                      trigger={["click"]}
                      overlay={cancelMenu(transaction.id)}
                      placement="bottomRight"
                      onClick={() => setCurrentTransaction(transaction)}
                    >
                      <MoreOutlined
                        className="cursor-pointer"
                        style={{ fontSize: 20 }}
                      />
                    </Dropdown>
                  ) : null}
                </Row>
                <Row className="m-t-20 m-b-5">
                  <SubHeading bold color="#8C8C8C">
                    DETAILS
                  </SubHeading>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <SubHeading>Amount</SubHeading>
                    <SubHeading>Status</SubHeading>
                    <SubHeading>Link generated on</SubHeading>
                    <SubHeading>Due date</SubHeading>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    <SubHeading>{`₹ ${transaction?.amount?.toLocaleString(
                      "en-IN"
                    )}`}</SubHeading>
                    {renderStatus(transaction.status)}

                    <SubHeading>{transaction.date}</SubHeading>
                    <SubHeading>{transaction.dueDate}</SubHeading>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { userTransactions, transactionUser, tableName } = state.fees;
  return {
    tableName,
    transactionUser,
    userTransactions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refundPayment: (transaction) => dispatch(refundPayment(transaction)),
  toggleUserDetailModal: (value) => dispatch(toggleUserDetailModal(value)),
  cancelPaymentRequest: (transactionId, callback) =>
    dispatch(cancelPaymentRequest(transactionId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
