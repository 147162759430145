import React, { useCallback, useEffect, useRef, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Select, Input, Tooltip } from "antd";
import {
  createTestModal,
  fetchSubjectChapters,
  getTestChapterList,
  getTestClassList,
  postReorderChaptersTest,
  setOpenTab,
  setSubjectSectionAction,
  updateFilter,
  updateListViewType,
} from "actions/testAdmin";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchBox from "./localComponent/searchBox";
import IconAndVal from "./localComponent/iconAndVal";
import Loader from "./localComponent/loader";
import { NodataCard } from ".";
import CreateTestModal from "./modals/createTestModal";
import CreateTestType from "./modals/createTestType";
import { Button } from "components/Button";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import "./styles.css"

const bookRed = require("../../Assets/test/bookRed.svg").default;
const bookPurple = require("../../Assets/test/bookPurple.svg").default;
const chapterTest = require("../../Assets/test/chapterTest.svg").default;
const fullTest = require("../../Assets/test/fullTest.svg").default;
const MathTest = require("../../Assets/test/sbj-mathematics.svg").default;
const GeoTest = require("../../Assets/test/sbj-geography.svg").default;
const ChemTest = require("../../Assets/test/sbj-chemistry.svg").default;
const MockTest = require("../../Assets/test/question-bank.svg").default;
const ICChapterTest = require("../../Assets/test/ic-chapter-test.svg").default;

const ItemType = "CHAPTER";

const useAutoScroll = (containerRef, isDragging, itemHeight) => {
  const scrollSpeed = 0.2; // Adjust the scroll speed as needed
  const scrollInterval = useRef(null);

  useEffect(() => {
    if (!isDragging) {
      clearInterval(scrollInterval.current);
      return;
    }

    const scrollContainer = containerRef.current;

    const handleScroll = () => {
      const scrollTop = scrollContainer.scrollTop;
      const containerHeight = scrollContainer.clientHeight;
      const maxScroll = scrollContainer.scrollHeight - containerHeight;

      const currentPos = scrollTop + containerHeight / 2;

      if (currentPos < itemHeight) {
        scrollContainer.scrollTop = Math.max(0, scrollTop - scrollSpeed);
      } else if (currentPos + itemHeight > maxScroll - itemHeight) {
        scrollContainer.scrollTop = Math.min(maxScroll, scrollTop + scrollSpeed);
      } else if (currentPos + itemHeight > maxScroll - itemHeight * 2) {
        scrollContainer.scrollTop = Math.min(maxScroll, scrollTop + scrollSpeed * 2);
      } else if (currentPos < itemHeight * 2) {
        scrollContainer.scrollTop = Math.max(0, scrollTop - scrollSpeed * 2);
      }
    };
    scrollInterval.current = setInterval(handleScroll, 10);

    return () => clearInterval(scrollInterval.current);
  }, [isDragging, containerRef, itemHeight]);
};

const PageHeader = ({ goBack, onSearch, searchVal, syllabus, classname, subjectname, onCreateClick, isEditOrderClicked, setIsEditOrderClicked, user, reorderSaveDisable, setChaptersData, chapterList, chaptersData, fetchSubjectChapters, postReorderChaptersTest }) => {
  const [reorderSaveLoader, setReorderSaveLoader] = useState(false)
  return (
    <div className="resultPageHeader full-width pageHeaderWrapper">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer" />
        <div className="text-md bold-700 m-l-20">
          <div>
            {syllabus}
          </div>
          <div style={{ color: "#AEAEAE", fontSize: 12 }}>
            {classname} / {subjectname}
          </div>
        </div>
      </div>
      <div className="testfiltersgroup">
        {isEditOrderClicked ?
          <>
            <Button style={{ marginRight: 20 }} onClick={() => { setIsEditOrderClicked(false); setChaptersData([...chapterList]) }}>CANCEL</Button>
            <Button disabled={reorderSaveDisable} type="primary" loading={reorderSaveLoader} style={{ marginRight: 20 }} onClick={() => {
              setReorderSaveLoader(true);
              postReorderChaptersTest(chaptersData, () => { setReorderSaveLoader(false) }, () => { fetchSubjectChapters((response) => { setChaptersData(response) }); setIsEditOrderClicked(false); })
            }}>SAVE</Button>
          </> :
          <>
            <SearchBox loading={false} onChange={onSearch} value={searchVal} className="m-r-10" placeholder="Search by chapter name" style={{ width: 230 }} />
            {(user.access.includes('test-superadmin') || user.access.includes('test-admin') || user.access.includes('dataoperator-test')) && chaptersData?.length > 1 && !isEditOrderClicked && <Button type="primary" style={{ marginRight: 10, backgroundColor: "#FFFFFF", color: "#594099" }} onClick={() => { setIsEditOrderClicked(true) }}>REORDER</Button>}
            <Button onClick={onCreateClick} type='primary' icon={"+"} style={{ marginRight: 20 }}>
              CREATE TEST
            </Button>
          </>
        }
      </div>
    </div>
  );
};

const ChapterCard = ({
  chapnumber,
  onClick,
  chapterItem,
  index,
  isEditOrderClicked,
  moveChapter,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { chapterItem, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (!item || item.index === index) {
        return;
      }
      moveChapter(item.index, index);
      item.index = index;
    },
  });
  return (
    <>
      <div
        ref={(node) => (isEditOrderClicked ? drag(drop(node)) : null)}
        className="cursor-pointer shadow-box hover-shadow customCardShadow"
        style={{
          height: "125px",
          width: "280px",
          borderRadius: "10px",
          border: "1px solid #E6E6E6",
          margin: "10px 10px 10px 0",
          padding: "15px",
          opacity: isDragging ? 0.5 : 1,
          cursor: isEditOrderClicked ? "move" : "pointer",
        }}
        onClick={onClick(chapterItem)}
      >
        <div className="display-flex" style={{ fontSize: "12px" }}>
          <div
            style={{
              color: "#636363",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Chapter {chapnumber}
          </div>
        </div>
        <div
          className="bold-600 "
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "50px",
            whiteSpace: "nowrap",
            fontSize: "14px",
            paddingTop: "10px",
          }}
        >
          {chapterItem.chapterName}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0px",
          }}
        >
          <div style={{ width: "50%", alignSelf: "start" }}>
            <IconAndVal val={chapterItem.subjectiveCount} refer={0} />
          </div>
          <div style={{ width: "50%", alignSelf: "start" }}>
            <IconAndVal val={chapterItem.objectiveCount} refer={1} />
          </div>
        </div>
      </div>
    </>
  );
};

let timer = false;

const InsideChapter = ({ fetchSubjectChapters, chapterList, updateFilter, filter,
  selectedClassroom, selectedSubject, setOpenTab, loaders, createTestModalBool, user,
  createTestModal, updateListViewType, getTestClassList, setSubjectSectionAction, getTestChapterList, postReorderChaptersTest }) => {

  const [testModalOpen, setTestModalOpen] = useState(false);
  const [isEditOrderClicked, setIsEditOrderClicked] = useState(false);
  const [chaptersData, setChaptersData] = useState([]);
  const scrollContainerRef = useRef(null);

  const history = useHistory();
  useEffect(() => {
    fetchSubjectChapters((response) => { setChaptersData(response) });
  }, []);
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchSubjectChapters((response) => { setChaptersData(response) });
    }, 1000);
  }, [filter?.chapterSearch]);
  const goBack = () => {
    history.goBack();
  }
  const onSearch = (e) => {
    updateFilter('chapterSearch', e.target.value);
  }
  const onChapterClick = (selectedChapter) => {
    return () => {
      if (!isEditOrderClicked) {
        updateFilter('selectedChapter', selectedChapter);
        updateFilter('isVA', false);
        updateFilter('isTabPage', true);
        updateFilter('tabSearch', null);
        updateFilter('isTAdatefiltered', false);
        updateFilter('taDate', null);
        updateFilter('testTypeSelector', null);
        setOpenTab('draft');
        history.push('test-subject');
      }
    }
  }
  const showModal = () => {
    setTestModalOpen(true)
  }
  const closeModal = () => {
    setTestModalOpen(false);
  }
  const onCreateClick = () => {
    // console.log("AA-selectedSubject",selectedSubject);
    setSubjectSectionAction([{ name: selectedSubject?.subjectName, id: selectedSubject?.subjectId }])
    showModal()
    // getTestClassList([selectedSubject.subjectId], []);
    updateListViewType('create');
    // createTestModal('test-create', user, 'subject');
    createTestModal('test-create', user, filter.selectedSyllabus === 'Chapter Test' ? 'chapter' : 'subject');
    if (filter?.selectedSyllabus === 'Chapter Test' || selectedSubject?.isCombined === false) {
      getTestChapterList(null, null, true, null);
    }
  }

  const reorderSaveDisable = chapterList?.every((chapter, index) => chapter?.chapterName === chaptersData[index]?.chapterName);

  const [dragIndex, setDragIndex] = useState(null);

  const moveChapter = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedChapter = chaptersData[dragIndex];
      const updatedChapters = [...chaptersData];
      updatedChapters.splice(dragIndex, 1);
      updatedChapters.splice(hoverIndex, 0, draggedChapter);
      setChaptersData(updatedChapters);
      setDragIndex(hoverIndex);
    },
    [chaptersData]
  );

  const containerRef = useRef(null);
  const isDragging = dragIndex !== null;

  useAutoScroll(containerRef, isDragging, 125);

  return (
    <div className="subjectSection" ref={containerRef}>
      <PageHeader goBack={goBack} onSearch={onSearch} searchVal={filter?.chapterSearch}
        classname={`${selectedClassroom.classname} - ${selectedClassroom.section}`} subjectname={selectedSubject.subjectName}
        syllabus={filter?.selectedSyllabus} onCreateClick={onCreateClick} setIsEditOrderClicked={setIsEditOrderClicked}
        isEditOrderClicked={isEditOrderClicked} user={user} reorderSaveDisable={reorderSaveDisable} chapterList={chapterList}
        setChaptersData={setChaptersData} chaptersData={chaptersData} fetchSubjectChapters={fetchSubjectChapters} postReorderChaptersTest={postReorderChaptersTest} /> 
      {loaders.chapterPageLoader ? (
        <Loader style={{ fontSize: 40 }} />
      ) : (
        <>
          <div className="contentWrapper">
            <DndProvider backend={HTML5Backend}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {chaptersData?.length ? (
                  chaptersData.map((chapter, index) => (
                    <ChapterCard
                      key={chapter.chapterId}
                      chapnumber={index + 1}
                      onClick={onChapterClick}
                      chapterItem={chapter}
                      index={index}
                      isEditOrderClicked={isEditOrderClicked}
                      moveChapter={moveChapter}
                    />
                  ))
                ) : (
                  <div className="full-width full-height r-c-c">
                    <NodataCard imgheight="50%" imgwidth="50%" />
                  </div>
                )}
              </div>
            </DndProvider>
          </div>
        </>)}
      {/* {createTestModalBool && <CreateTestModal history={history} />} */}
      {testModalOpen && <CreateTestType isModalOpen={testModalOpen} closeModal={closeModal} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    selectedClassroom,
    selectedSubject,
    filter,
    chapterList,
    loaders,
    createTestModalBool
  } = state.testAdmin;
  const { user } = state.session;
  return {
    selectedClassroom,
    selectedSubject,
    filter,
    chapterList,
    loaders,
    createTestModalBool,
    user
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchSubjectChapters: (succesCallback) => dispatch(fetchSubjectChapters(succesCallback)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  setOpenTab: (val) => dispatch(setOpenTab(val)),
  updateListViewType: (type) => dispatch(updateListViewType(type)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
  getTestClassList: (subIds, chapIds) => dispatch(getTestClassList(subIds, chapIds)),
  setSubjectSectionAction: (val) => dispatch(setSubjectSectionAction(val)),
  getTestChapterList: (search, subjectId, iscreate, stdid) => dispatch(getTestChapterList(search, subjectId, iscreate, stdid)),
  postReorderChaptersTest: (chapters, callBack, successCallBack) => dispatch(postReorderChaptersTest(chapters, callBack, successCallBack))
})

export default connect(mapStateToProps, mapDispatchToProps)(InsideChapter);