import { FilterFilled, PlusCircleOutlined } from '@ant-design/icons';
import { getSubjStudentsData, removeStudents, setAllStudentsPageParam } from 'actions/classRoom';
import { Avatar, Button, Checkbox, Divider, Input, Pagination, Popover, Radio, Select, Typography } from 'antd';
import { InputSearch } from 'components/customInputs';
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PageDataLoader from "components/pageDataLoader";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from 'services';
import AddStudentModal from './modals/addStudent';
import TransferStudentModal from './modals/transferStudent';
import ConfirmDeleteModal from './modals/confirmDeleteModal';
import { IMG_URL } from "../../env.json";
import { getCourseStudentsData } from 'actions/courseManagement';

const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
const { Search } = Input;
const { Option } = Select;

const bodyStyle = {
	width: "calc(100% + 30px)",
	height: "calc(100% + 40px)",
	margin: -20,
	padding: 20,
	overflow: "auto",
};
const tableDataStyle = {
	color: "#191919", textAlign: "center", fontSize: 14, fontFamily: "roboto"
}
function ClassRoomAllStudents({ getSubjStudentsData, selectedSection, setAllStudentsPageParam, allStudentsParamsObj, removeStudents, user, getCourseStudentsData }) {
	const [pageLoader, togglePageLoader] = useState(false);
	const [selectedStudents, setSelectedStudents] = useState([])
	const [isTransferStudentVisible, setTransferStudentVisible] = useState(false)
	const [isSortSelected, setSortSelected] = useState(false);
	const [isAddStudentModalVisible, setAddStudentModalVisible] = useState(false)
	const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const history = useHistory();
	const location = useLocation()
	useEffect(() => {
		setAllStudentsPageParam("search", null);
		setAllStudentsPageParam("page", 1);
		setAllStudentsPageParam("limit", 10);
		setAllStudentsPageParam("sort", "ASC");
		togglePageLoader(true)
		console.log("abcdefLocation", location);
		if (location?.state?.key == "fromCourseStudents") {
			getCourseStudentsData( () => { togglePageLoader(false) })
		} else {
			getSubjStudentsData(() => { togglePageLoader(false) })
		}
	}, [])
	useEffect(() => {
		if (allStudentsParamsObj?.sort) {
			setSortSelected(true)
		} else {
			setSortSelected(false)
		}
	}, [allStudentsParamsObj?.sort])
	const onSearch = (e) => {
		setAllStudentsPageParam("page", 1);
		setAllStudentsPageParam("search", e);
		togglePageLoader(true)
		if (location?.state?.key == "fromCourseStudents") {
			getCourseStudentsData( () => { togglePageLoader(false) })
		} else {
			getSubjStudentsData(() => { togglePageLoader(false) })
		}
		setSelectedStudents([])
	}
	const handlePageination = (value) => {
		setAllStudentsPageParam("page", value);
		setSelectedStudents([])
		togglePageLoader(true);
		if (location?.state?.key == "fromCourseStudents") {
			getCourseStudentsData( () => { togglePageLoader(false) })
		} else {
			getSubjStudentsData(() => { togglePageLoader(false) })
		}
	};
	const content = () => {
		const [value, setValue] = useState("ASC");
		return (
			<div
				id='checkbox'
				className="flex-column organistationcardfilter"
				style={{
					marginLeft: -16,
					marginRight: -16,
					marginTop: -12,
					width: 160,
					height: 120
				}}>
				<Radio.Group
					className="flex-column"
					value={value}
					onChange={(e) => {
						setValue(e.target.value)
						setAllStudentsPageParam("sort", e.target.value);
					}}
				>
					<Radio value={"ASC"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Ascending</Radio>
					<Radio value={"DESC"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Descending</Radio>
				</Radio.Group>
				<div style={{ borderBottom: "1px solid #E6E6E6" }}></div>
				<div className='r-jsb' style={{ padding: 10 }}>
					<div
						style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", cursor: "pointer" }}
						onClick={() => {
							setValue(null)
							setAllStudentsPageParam("sort", null);
							setSelectedStudents([])
							togglePageLoader(true);
							getSubjStudentsData(() => togglePageLoader(false));
						}}
					> RESET</div>
					<Button
						type="primary"
						style={{ width: 50, borderRadius: 16, height: 25, display: "flex", alignItems: "center", fontWeight: "bold" }}
						onClick={() => {
							setSelectedStudents([])
							togglePageLoader(true);
							getSubjStudentsData(() => togglePageLoader(false));
						}}
					>OK</Button>
				</div>
			</div>
		)
	}
	const handleCheckbox = (value) => {
		setSelectedStudents((prevSelectedOptions) => {
			const isChecked = prevSelectedOptions.includes(value);
			if (isChecked) {
				const updatedOptions = prevSelectedOptions.filter((option) => option !== value);
				setSelectedStudents(updatedOptions)
			} else {
				const updatedOptions = [...prevSelectedOptions, value];
				setSelectedStudents(updatedOptions)
			}
		});
	};
	function onSelectAll(e) {
		let temp = [];
		if (e.target.checked) {
			for (let i = 0; i < selectedSection?.students?.items?.length; i++) {
				temp.push(selectedSection?.students?.items?.[i]?.id)
			}
		}
		setSelectedStudents([...temp])
	}
	const pageHeaderTitle = (
		<div style={{ fontSize: "1em" }}>
			{location?.state?.key == "fromCourseStudents" ? "Students enrolled" : selectedSection?.students?.items?.length > 1 ? "Students " : "Student "}
		</div>
	);
	const pageHeaderSubtitle = selectedSection
		? `${selectedSection?.std} : ${selectedSection?.section}`
		: ""`${selectedSection?.courseName}` !== "NA"
			? ` - ${selectedSection?.courseName}`
			: "";
	const actions = [
		selectedStudents?.length <= 1 && (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) && location?.state?.key != "fromCourseStudents" ? (<Button type="primary" style={{ marginRight: 20, borderRadius: 16 }} onClick={() => { setAddStudentModalVisible(true) }}><PlusCircleOutlined style={{ marginRight: 5 }} />ADD</Button>) : null,
		selectedStudents?.length > 1 && (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) && location?.state?.key != "fromCourseStudents" ? (<Button style={{ marginRight: 20, backgroundColor: "#FFA931", color: "#FFFFFF", borderRadius: 16, fontWeight: "bold" }} onClick={() => { setTransferStudentVisible(true) }}>TRANSFER</Button>) : null,
		selectedStudents?.length > 1 && (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) && location?.state?.key != "fromCourseStudents" ? (<Button style={{ marginRight: 20, backgroundColor: "#FF414D", color: "#FFFFFF", borderRadius: 16, fontWeight: "bold" }} onClick={() => { setConfirmDeleteModal(true) }}>UNASSIGN</Button>) : null,
		<Search
			allowClear={true}
			placeholder="Search by name/mobile"
			style={{ width: 300 }}
			onSearch={onSearch}
		/>,
	];
	return (
		<div style={bodyStyle}>
			<div style={{ marginBottom: 90 }}>
				<PageHeader
					title={pageHeaderTitle}
					subtitle={location?.state?.key == "fromCourseStudents" ? null : pageHeaderSubtitle}
					actions={actions}
					onBackPress={() => history.goBack()}
				/>
			</div>
			<div style={{ width: "100%", paddingBottom: "20px",/*  height: "78vh", overflowY: "scroll" */ }}>
				<table style={{ width: "100%", overflowY: "scroll" }}>
					<thead>
						<tr style={{ backgroundColor: "#F6F4FE", padding: "20px", height: "50px", fontFamily: "roboto" }}>
							{location?.state?.key == "fromCourseStudents"
								?
								<th></th>
								:
								<th style={{ color: "#636363", width: 200 }}>
									{(user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin')) ? <Checkbox className='m-r-10' checked={selectedStudents?.length === selectedSection?.students?.items?.length && selectedStudents?.length !== 0} onChange={onSelectAll} /> : <></>}
									Roll No.
									<Popover
										placement="bottomLeft"
										content={content}
										trigger="click"
									>
										<FilterFilled
											style={{
												fontSize: "18px",
												color: isSortSelected ? "#1089FF" : "#AEAEAE",
												marginLeft: "20px",
											}}
										/>
									</Popover>
								</th>
							}
							<th style={{ color: "#636363", textAlign: location?.state?.key == "fromCourseStudents" ? "left" : "" }}>Name</th>
							<th style={{ color: "#636363", textAlign: location?.state?.key == "fromCourseStudents" ? "left" : "" }}>Mobile No.</th>
							{(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) && location?.state?.key != "fromCourseStudents" ? <th style={{ color: "#636363" }}>Actions</th> : <th><div style={{ width: 100 }}></div></th>}
						</tr>
					</thead>
					{selectedSection?.students?.items?.map((item, index) => (
						<tr style={{ backgroundColor: "#ffffff", height: "60px", border: "1px solid #e6e6e6", borderBottom: "1px solid #E6E6E6" }}>
							{location?.state?.key == "fromCourseStudents" ?
								<td></td>
								:
								<td style={{ width: 200 }}>
									<div style={{ display: "flex", alignItems: "center" }}>
										{((user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin'))) ?
											<Checkbox style={{ padding: "0px 10px 0px 40px" }} checked={selectedStudents?.includes(selectedSection?.students?.items?.[index]?.id)} onChange={(e) => { handleCheckbox(item?.id) }} />
											: <div style={{ width: 70 }}></div>}
										<div style={{ ...tableDataStyle, }}>{item?.rollNo ? item?.rollNo : "-"}</div>
									</div>
								</td>
							}
							<td>
								<div style={{ display: "flex", alignItems: "center", marginLeft: location?.state?.key == "fromCourseStudents" ? "2%" : ((user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin'))) ? '30%' : '35%', }}>
									<img
										src={`${IMG_URL}/${item?.image || "default.png"}`}
										className="radius-100 batch-detial__student-img"
									/>
									<Typography.Text ellipsis={{ tooltip: true }} className='m-l-20' style={{ ...tableDataStyle, maxWidth: 120 }}>{item?.name}</Typography.Text>
								</div>
							</td>
							<td><div style={{ ...tableDataStyle, textAlign: location?.state?.key == "fromCourseStudents" ? "left" : "" }}>{item?.phoneNo}</div></td>
							{location?.state?.key == "fromCourseStudents" ?
								<td style={{ width: 100 }}></td>
								:
								<>
									{(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) ?
										<td>
											<div style={{ display: "flex", justifyContent: "center" }}>
												<div style={{ ...tableDataStyle, color: "#FFA931", fontWeight: "bold", marginRight: 20, cursor: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? "not-allowed" : "pointer", opacity: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? 0.5 : 1 }}
													onClick={() => {
														if (!(selectedStudents?.length > 1 && selectedStudents?.includes(item?.id))) {
															setTransferStudentVisible(true); setSelectedStudents([item?.id])
														}
													}}>TRANSFER</div>
												<div style={{ ...tableDataStyle, color: "#FF414D", fontWeight: "bold", cursor: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? "not-allowed" : "pointer", opacity: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? 0.5 : 1 }}
													onClick={() => {
														if (!(selectedStudents?.length > 1 && selectedStudents?.includes(item?.id))) {
															setConfirmDeleteModal(true); setSelectedStudents([item?.id])
														}
													}}>UNASSIGN</div>
											</div>
										</td> : <td>
											<div style={{ width: 100 }}></div>
										</td>
									}
								</>
							}
						</tr>
					))}
				</table>
			</div>
			{selectedSection?.students?.items?.length == 0 ?
				<div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
					<div><img src={illNoData} /></div>
					<div><h4><b>No data!</b></h4></div>
					<div><h5>There are no data to show yet. Please check back later</h5></div>
				</div>
				: null
			}
			{selectedSection?.students?.items?.length > 0 ?
				<div className='r-jsb'>
					<div style={{ color: "#636363", }}>
						<Select
							style={{ width: 100, marginRight: 10 }}
							defaultValue={"10/ page"}
							value={allStudentsParamsObj?.limit}
							onChange={(value) => {
								setAllStudentsPageParam("limit", value);
								togglePageLoader(true);
								if (location?.state?.key == "fromCourseStudents") {
									getCourseStudentsData(() => { togglePageLoader(false) })
								} else {
									getSubjStudentsData(() => togglePageLoader(false))
								}
							}}>
							<Option value={10}>10/ page</Option>
							<Option value={12}>12/ page</Option>
							<Option value={16}>16/ page</Option>
							<Option value={18}>18/ page</Option>
						</Select>
						{selectedSection?.students?.meta?.itemsPerPage *
							(selectedSection?.students?.meta?.currentPage - 1) +
							1}{" "}
						-{" "}
						{selectedSection?.students?.meta?.itemsPerPage *
							selectedSection?.students?.meta?.currentPage <
							selectedSection?.students?.meta?.totalItems
							? selectedSection?.students?.meta?.itemsPerPage *
							selectedSection?.students?.meta?.currentPage
							: selectedSection?.students?.meta?.totalItems}{" "}
						of {selectedSection?.students?.meta?.totalItems} items
					</div>
					<div>
						<Pagination
							size="small"
							current={selectedSection?.students?.meta?.currentPage}
							defaultCurrent={1}
							total={selectedSection?.students?.meta?.totalPages * 10}
							onChange={handlePageination}
							showSizeChanger={false}
						/>
					</div>
				</div>
				:
				<></>
			}
			<PageDataLoader visible={pageLoader} />
			{isTransferStudentVisible &&
				<TransferStudentModal
					isTransferStudentVisible={isTransferStudentVisible}
					setTransferStudentVisible={setTransferStudentVisible}
					togglePageLoader={togglePageLoader}
					getSubjStudentsData={getSubjStudentsData}
					selectedStudents={selectedStudents}
					setSelectedStudents={setSelectedStudents}
				/>}
			{isAddStudentModalVisible &&
				<AddStudentModal
					isAddStudentModalVisible={isAddStudentModalVisible}
					setAddStudentModalVisible={setAddStudentModalVisible}
					standardId={selectedSection?.id}
					togglePageLoader={togglePageLoader}
					getSubjStudentsData={getSubjStudentsData}
				/>}
			{showConfirmDeleteModal &&
				<ConfirmDeleteModal
					showConfirmDeleteModal={showConfirmDeleteModal}
					setConfirmDeleteModal={setConfirmDeleteModal}
					togglePageLoader={togglePageLoader}
					getSubjStudentsData={getSubjStudentsData}
					selectedStudents={selectedStudents}
					removeStudents={removeStudents}
					selectedSection={selectedSection}
					fromAllStudents={true}
					setSelectedStudents={setSelectedStudents}
				/>
			}
		</div>
	)
}
const mapStateToProps = (state) => {
	const { selectedSection, allStudentsParamsObj } = state.classRoom;
	const { user } = state.session
	return {
		selectedSection, allStudentsParamsObj, user
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSubjStudentsData: (callBack) => dispatch(getSubjStudentsData(callBack)),
	setAllStudentsPageParam: (key, val) => dispatch(setAllStudentsPageParam(key, val)),
	removeStudents: (params, callBack, successCallBack) => dispatch(removeStudents(params, callBack, successCallBack)),
	getCourseStudentsData: ( callBack) => dispatch(getCourseStudentsData( callBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassRoomAllStudents);
// export default ClassRoomAllStudents