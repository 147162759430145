import { autocompleteAssignStudents, postAssignStudentsToCourse } from 'actions/courseManagement';
import { AutoComplete, Input, Modal, Tag } from 'antd';
import { Button } from 'components/Button';
import { ModalSubTitle } from 'components/Typography';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Color } from 'services';

function AddStudentsToCourse({ isAddStudentsToCourseModalVisible, setAddStudentsToCourseModalVisible, togglePageLoader, getSpecificCourseData, autocompleteAssignStudents, postAssignStudentsToCourse, getStudentsListLoader }) {
    const [searchName, setSearchName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [unassignedStudentList, setUnassignedStudentList] = useState([]);
    const [selectedStudents, setSelectedStudent] = useState([]);
    const [addStudentLoader, setAddStudentLoader] = useState(false)
    const handleCancel = () => {
        setAddStudentsToCourseModalVisible(false)
    }
    useEffect(() => {
        autocompleteAssignStudents('', (students) => updateStudentList(students))
    }, [])

    useEffect(() => {
        const updatedUnassignedList = unassignedStudentList?.filter(item =>
            !selectedStudents?.some(student => student?.id === item?.id)
        );
        setUnassignedStudentList(updatedUnassignedList);
    }, [selectedStudents, studentList]);

    const handleStudentSearch = (query) => {
        if (query?.trim().length > 0 || query?.trim().length === 0) {
            setLoading(true)
            autocompleteAssignStudents(query, (students) => updateStudentList(students))
        }
    }
    const updateStudentList = (students) => {
        // setUnassignedStudentList(students?.filter((item) => !item?.disabledBool))
        setStudentList(students?.map(item => Object.assign(item, { key: item.id, label: item.name, value: item.id })))
        setLoading(false)
    }
    const handleStudentSelect = (value, obj) => {
        if (!selectedStudents?.map(student => student.id).includes(obj.id)) {
            selectedStudents.push(obj);
            setSelectedStudent([...selectedStudents]);
        }
        setSearchName('');
        autocompleteAssignStudents("", (students) => updateStudentList(students))
    }
    const removeSelectedStudents = (currStudent) => {
        for (let i = 0; i < selectedStudents?.length; i++) {
            if (currStudent.id == selectedStudents[i].id) {
                return false
            }
        }
        return true
    }
    const removeStudent = (index) => {
        selectedStudents.splice(index, 1);
        setSelectedStudent([...selectedStudents])
        unassignedStudentList?.splice(index, 1)
        setUnassignedStudentList([...unassignedStudentList])
        autocompleteAssignStudents('', (students) => updateStudentList(students))
    }
    const handleAddClick = () => {
        setAddStudentLoader(true)
        const students = selectedStudents?.map(student => student.id)
        postAssignStudentsToCourse(
            students,
            () => setAddStudentLoader(false),
            () => {
                setAddStudentsToCourseModalVisible(false);
                togglePageLoader(true);
                getSpecificCourseData(() => togglePageLoader(false))
            });
    }
    return (
        <div>
            <Modal
                centered
                className="modal-round-corner"
                visible={isAddStudentsToCourseModalVisible}
                onCancel={handleCancel}
                onOk={handleCancel}
                footer={null}>
                <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Add Students"}</div>
                <div style={{ padding: 20 }}>
                    <ModalSubTitle>Student’s name</ModalSubTitle>
                    <AutoComplete
                        notFoundContent={'No Students Found'}
                        value={searchName}
                        onChange={e => setSearchName(e)}
                        options={studentList ? studentList?.filter(removeSelectedStudents) : []}
                        style={{ border: 'none', width: '100%', color: Color.primary, fontSize: 16, fontWeight: 600 }}
                        onSelect={(value, obj) => handleStudentSelect(value, obj)}
                        onSearch={(query) => handleStudentSearch(query)}
                    >
                        <Input.Search style={{ border: 'none', borderRadius: 5, height: 32 }} placeholder="Search by name"
                            loading={getStudentsListLoader} allowClear />
                    </AutoComplete>
                    <div className="flex-wrap m-t-10" style={{ height: 100, overflowY: "scroll" }}>
                        {selectedStudents?.map((student, index) =>
                            <Tag
                                key={student.id}
                                style={{
                                    backgroundColor: '#F6F4FE',
                                    color: '#594099',
                                    padding: 5,
                                    borderRadius: 5,
                                    marginBottom: 20
                                }}
                                closable
                                onClose={() => removeStudent(index)}
                            >
                                {student.label}
                            </Tag>
                        )}
                    </div>
                </div>
                <div className='r-c-c m-t-40'>
                    <Button
                        type="primary"
                        disabled={!selectedStudents.length}
                        onClick={handleAddClick}
                        loading={addStudentLoader}
                    >ADD</Button>
                </div>
            </Modal>
        </div>
    )
}
const mapStateToProps = state => {
    const { getStudentsListLoader } = state.classRoom;
    return {
        getStudentsListLoader
    };
};

const mapDispatchToProps = dispatch => ({
    autocompleteAssignStudents: (query, callback) => dispatch(autocompleteAssignStudents(query, callback)),
    postAssignStudentsToCourse: (students, callback, successCb) => dispatch(postAssignStudentsToCourse(students, callback, successCb))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStudentsToCourse)
// export default AddStudentsToCourse