import React, { useEffect } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from 'pages/Test/localComponent/searchBox';
import { SubIcon } from 'components/subIcon';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { NodataCard } from './homepage'

const practiceTestImg = require("../../Assets/studentPracticeTest/ic-practice-test-g.svg").default;
import {
  fetchChapterWiseTest, UpdateStudentTestData, fetchSubjectInSyllabusData, setFilter, updateChapterInSyllabusData
} from "actions/studentPracticeTest";
import Loader from 'pages/Test/localComponent/loader';
import { Tooltip } from 'antd';



const SubjectCard = ({ description, count, icon, type, setFilter, data, filter }) => {
  const history = useHistory();

  return (
    <>
      <div className='cursor-pointer m-l-10 r-afs flex-wrap full-height m-t-20' style={{ overflow: "auto" }}>

        <div style={{
          width: 240,
          backgroundColor: "#FFFFFF",
          border: "1px solid #E6E6E6",
          borderRadius: 10,
          height: 200
        }}
          onClick={(e) => {
            setFilter('chapterSearch', null);
            setFilter('tabSearch', null);
            setFilter('selectedSubject', data);
            setFilter('selectedTab', 'Available');
            if (data.isCombined === false && filter.selectedSyllabus === 'Chapter Test') {
              history.push("student-practice-chapters")

            } else {
              history.push('student-practice-tabs')
            }
          }}
        >

          <div className='r-c-c-c m-t-40 p-10' >
            <div>
              <SubIcon icon={icon} name={description} />
            </div>
            <div className='r-jc ' style={{ font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight: "500" }}>{description}</div>
          </div>

          <div className='r-jc m-t-30'>
            <Tooltip title='Test Count'>
              <img src={practiceTestImg} />
            </Tooltip>
            <div>{count}</div>
          </div>


        </div>
      </div>
    </>
  )
}

let timer = false;
const PracticeSubjectPage = ({ studentTestData, fetchSubjectInSyllabusData, SubjectInSyllabus,
  setFilter, updateChapterInSyllabusData, filter, loaders }) => {
  const history = useHistory();

  useEffect(() => {
    fetchSubjectInSyllabusData(() => { });
  }, []);
  const onSearch = (e) => {
    setFilter('subjectSearch', e.target.value);
  }
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchSubjectInSyllabusData(() => { });
    }, 1000);
  }, [filter?.subjectSearch]);
  return (
    <>

      <div style={{ display: "flex", justifyContent: "space-between" }} >
        <div style={{ display: "flex" }} >
          <div style={{ fontWeight: "800", fontSize: "20px", color: "#191919" }} className="m-b-20"><ArrowLeftOutlined className="cursor-pointer m-r-20" onClick={() => {
            history.push(`/student-practice-home`)
          }} /> </div>
          <div style={{ fontStyle: "roboto", fontSize: "21px" }}><b />{studentTestData.selectedTestCard.type}</div>
        </div>
        <div >
          <SearchBox loading={false}
            onChange={onSearch}
            placeholder="Search by subject name"
            style={{ width: 350, marginRight: 75 }}
            value={filter?.subjectSearch} />
        </div>
      </div>
      {
        loaders?.subjectPageLoader ?
          <Loader style={{ fontSize: 30 }} />
          :
          <div className='m-l-10 flex-wrap' style={{ display: "flex" }}>
            {SubjectInSyllabus?.map((item) => (
              <SubjectCard
                description={item.subjectName}
                count={item.testCount}
                icon={item.icon}
                type={studentTestData.selectedTestCard.type}
                data={item}
                setFilter={setFilter}
                filter={filter}
              />
            ))}
          </div>
      }
      {SubjectInSyllabus && SubjectInSyllabus.length ? null : <NodataCard />}


    </>
  )
}
const mapStateToProps = (state) => {
  const { studentTestData, SubjectInSyllabus, filter, loaders } = state.studentPracticeTest;
  const { user } = state.session;
  return {
    studentTestData,
    SubjectInSyllabus,
    filter,
    user,
    loaders
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchChapterWiseTest: (callback) => dispatch(fetchChapterWiseTest(callback)),
  UpdateStudentTestData: (key, val) => dispatch(UpdateStudentTestData(key, val)),
  fetchSubjectInSyllabusData: (callback) => dispatch(fetchSubjectInSyllabusData(callback)),
  setFilter: (key, val) => dispatch(setFilter(key, val)),
  updateChapterInSyllabusData: (value) => dispatch(updateChapterInSyllabusData(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeSubjectPage);