import {
    SET_COURSE_TYPE_NAME,
    SET_CREATE_COURSE_OBJ,
    STORE_COURSE_CATEGORY,
    STORE_COURSE_CATEGORY_FOR_FEE,
    STORE_COURSE_SUB_CATEGORY,
    STORE_SPECIFIC_COURSE_DATA,
    STORE_SPECIFIC_COURSE_ID,
    SET_DISCOUNT_DETAILS,
    STORE_COURSE_STATS,
    STORE_TOTAL_COURSES_LIST,
    SET_ALL_COURSES_PAGE_PARAMS_OBJ,
    STORE_CRM_TABLE_DATA,
    SET_CRM_PAGE_PARAMS_OBJ,
    STORE_SRM_COURSE_LIST_DATA,
    STORE_TAGS_DATA,
    STORE_AUTOCOMPLETE_SEARCH_DATA,
    STORE_AUTOCOMPLETE_COURSES_DATA,
    STORE_COURSE_LANGUAGES_DATA,
    STORE_SEARCH_RESULT_DATA,
    SET_SEARCH_RESULT_PAGE_PARAMS_OBJ,
    FILE_KEY_UPDATE,
    STORE_QUIZ_DATA,
    PDF_UPLOADED,
    SET_QUIZ_DATA,
    STORE_ONLINE_TEST_SERIES,
    STORE_SUBCATEGORY_DATA,
    STORE_THUMBNAILS_DATA,
    SET_COURSE_DASHBOARD_DATA,
    SET_COURSE_ACTIVITY_DATA,
    APPEND_COURSE_ACTIVITY_DATA,
    SET_STORE_COURSE_GRAPH_DATA,
    SET_STORE_PIE_CHART_DATA,
    SET_FROM_COURSE_DETAILS_PAGE,
    STORE_ALL_STUDENTS_DATA,
    SET_ALL_STUDENTS_PAGE_PARAMS,
    SET_PAYMENT_MODAL_PARAMS_OBJ,
    SET_VIDEO_RESOURCES_QUESTION_ANSWER,
    SET_IS_FROM_ADMIN_PREVIEW,
    SET_TAB,
    APPEND_CRM_TABLE_DATA,
    APPEND_ALL_STUDENTS_DATA,
    SET_COURSE_STUDENT_ROLL_NO
} from "actions/courseManagement";

const initialState = {
    courseTypeName: null,
    courseCreateObj: {},
    courseCategoryData: [],
    courseCategoryDataForFee:[],
    courseSubCategoriesData: [],
    specificCourseDataContainer: [],
    newCourseId: null,
    courseDiscountDetails: [],
    courseStatsData: {},
    totalCoursesDataContainer: [],
    allCoursesPageParamsObj: { subcategoryId: null, search: null, startDate: null, endDate: null, mode: null, sort: null, durationFilter: null },
    crmTableData: [],
    crmParamsObj: { page: 1, limit: 10, search: null, filter: null, newFirst: false, startDate: null, endDate: null, filterByCourseType: ['All'], status: 'All',filterByCourseName:null },
    crmCourseListData: [],
    totalTagsData: [],
    autocompleteSearchData: [],
    autocompleteCoursesSearchData: [],
    courseLanguagesData:[],
    searchResultData: [],
    searchPageParamsObj: { search: null, startDate: null, endDate: null, sort: null, mode: null, type: null, status: null, durationFilter: null, feeType: null },
    quizDataContainer: [],
    fileKey: '',
    Pdftoupload: null,
    storeQuizData: [],
    storeOnlineTestData: [],
    storeCategoryData: [],
    storeThumbnails: [],
    courseDashboardData: [],
    courseActivityData: [],
    storeGraphData: [],
    storePieChartData: {},
    isFromCourseDetailsReducer: false,
    allStudentsEnrolledData: [],
    allStudentsParamsObj: { page: 1, search: null, standardIds: [], limit: 10, rollNo: null },
    paymentModalParamsObj: { amount: null, paymentDate: null, paymentMethod: null, transactionId: null, additionDetails: null, discountDetails: null },
    storeVideoResourcesQandA: [],
    isFromAdminPreview: false,
    tab: 1,
    courseStudentRollNo:{rollNo:null}

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COURSE_TYPE_NAME:
            return {
                ...state,
                courseTypeName: action.val,
            }
        case SET_CREATE_COURSE_OBJ: {
            let newCourseCreateObj = { ...state.courseCreateObj };
            newCourseCreateObj[action.key] = action.val;
            return {
                ...state,
                courseCreateObj: newCourseCreateObj,
            }
        }
        case STORE_COURSE_CATEGORY_FOR_FEE:
            return {
                ...state,
                courseCategoryDataForFee: action.val
            }
        case STORE_COURSE_CATEGORY:
            return {
                ...state,
                courseCategoryData: action.val
            }
        case STORE_COURSE_SUB_CATEGORY:
            return {
                ...state,
                courseSubCategoriesData: action.val
            }
        case STORE_SPECIFIC_COURSE_DATA:
            return {
                ...state,
                specificCourseDataContainer: action.val
            }
        case STORE_SPECIFIC_COURSE_ID: {
            return {
                ...state,
                newCourseId: action.val
            }
        }
        case SET_DISCOUNT_DETAILS: {
            return {
                ...state,
                courseDiscountDetails: action.val
            }
        }
        case STORE_COURSE_STATS: {
            return {
                ...state,
                courseStatsData: action.val
            }
        }
        case STORE_TOTAL_COURSES_LIST: {
            return {
                ...state,
                totalCoursesDataContainer: action.val
            }
        }
        case SET_ALL_COURSES_PAGE_PARAMS_OBJ: {
            let newParamsObj = { ...state.allCoursesPageParamsObj }
            newParamsObj[action.key] = action.val;
            return {
                ...state,
                allCoursesPageParamsObj: newParamsObj,
            }
        }
        case STORE_ONLINE_TEST_SERIES: {
            return {
                ...state,
                storeOnlineTestData: action.val
            }
        }
        case STORE_SUBCATEGORY_DATA: {
            return {
                ...state,
                storeCategoryData: action.val
            }
        }
        case STORE_CRM_TABLE_DATA: {
            return {
                ...state,
                crmTableData: action.val
            }
        }
        case SET_CRM_PAGE_PARAMS_OBJ: {
            let newCRMParamsObj = { ...state.crmParamsObj };
            newCRMParamsObj[action.key] = action.val;
            return {
                ...state,
                crmParamsObj: newCRMParamsObj,
            }
        }
        case STORE_SRM_COURSE_LIST_DATA: {
            return {
                ...state,
                crmCourseListData: action.val
            }
        }
        case STORE_TAGS_DATA: {
            return {
                ...state,
                totalTagsData: action.val
            }
        }
        case STORE_AUTOCOMPLETE_SEARCH_DATA: {
            return {
                ...state,
                autocompleteSearchData: action.val
            }
        }case STORE_AUTOCOMPLETE_COURSES_DATA: {
            return {
                ...state,
                autocompleteCoursesSearchData: action.val
            }
        }
        case STORE_COURSE_LANGUAGES_DATA : {
            return {
                ...state,
                courseLanguagesData: action.val
            }
        }
        case STORE_SEARCH_RESULT_DATA: {
            return {
                ...state,
                searchResultData: action.val
            }
        }
        case SET_SEARCH_RESULT_PAGE_PARAMS_OBJ: {
            let newSearchParamsObj = { ...state.searchPageParamsObj };
            newSearchParamsObj[action.key] = action.val;
            return {
                ...state,
                searchPageParamsObj: newSearchParamsObj,
            }
        }
        case STORE_QUIZ_DATA: {
            return {
                ...state,
                quizDataContainer: action.val
            }
        }
        case FILE_KEY_UPDATE:
            return {
                ...state,
                fileKey: action.data
            }
        case PDF_UPLOADED:
            return {
                ...state,
                Pdftoupload: action.data
            }
        case SET_QUIZ_DATA:
            return {
                ...state,
                storeQuizData: action.data
            }
        case STORE_THUMBNAILS_DATA:
            return {
                ...state,
                storeThumbnails: action.val
            }
        case SET_COURSE_DASHBOARD_DATA:
            return {
                ...state,
                courseDashboardData: action.val,
            }
        case SET_STORE_COURSE_GRAPH_DATA:
            return {
                ...state,
                storeGraphData: action.data
            }
        case SET_STORE_PIE_CHART_DATA:
            return {
                ...state,
                storePieChartData: action.data
            }
        case SET_COURSE_ACTIVITY_DATA:
            return {
                ...state,
                courseActivityData: action.val,
            }
        case APPEND_COURSE_ACTIVITY_DATA:
            let newTempData = state.courseActivityData
            newTempData.meta = action?.res?.meta
            newTempData?.items.push(...action.res?.items)
            return {
                ...state,
                courseActivityData: newTempData
            }
        case SET_FROM_COURSE_DETAILS_PAGE:
            return {
                ...state,
                isFromCourseDetailsReducer: action.val
            }
        case STORE_ALL_STUDENTS_DATA:
            return {
                ...state,
                allStudentsEnrolledData: action.val
            }
        case SET_ALL_STUDENTS_PAGE_PARAMS:
            let newParamsObj = { ...state.allStudentsParamsObj }
            newParamsObj[action.key] = action.val;
            return {
                ...state,
                allStudentsParamsObj: newParamsObj,
            }
        case SET_PAYMENT_MODAL_PARAMS_OBJ: {
            let newPaymentModalParamsObj = { ...state.paymentModalParamsObj };
            newPaymentModalParamsObj[action.key] = action.val;
            return {
                ...state,
                paymentModalParamsObj: newPaymentModalParamsObj,
            }
        }
        case SET_VIDEO_RESOURCES_QUESTION_ANSWER: {
            return {
                ...state,
                storeVideoResourcesQandA: action.val
            }
        }
        case SET_IS_FROM_ADMIN_PREVIEW: {
            return {
                ...state,
                isFromAdminPreview:action.val
            }
        }
        case SET_TAB: {
            return {
                ...state,
                tab:action.res
            }
        }
        case APPEND_ALL_STUDENTS_DATA:
            let newtempData = state.allStudentsEnrolledData
            newtempData.students.meta = action?.res?.students?.meta
            newtempData?.students?.items.push(...action.res?.students?.items)
            return {
                ...state,
                allStudentsEnrolledData: newtempData
            }
        case APPEND_CRM_TABLE_DATA:
            let temp = state.crmTableData
            temp.meta = action?.res?.meta
            temp?.items.push(...action.res?.items)
            return {
                ...state,
                crmTableData: temp
            }
        case SET_COURSE_STUDENT_ROLL_NO: {
                let newStudentRollNo = { ...state.courseStudentRollNo };
                newStudentRollNo[action.key] = action.val;
                return {
                    ...state,
                    courseStudentRollNo: newStudentRollNo,
                }
            }
        default:
            return {
                ...state
            };
    }
};

export default reducer;