import { DeleteFilled, EllipsisOutlined, UploadOutlined } from '@ant-design/icons';
import { AutoComplete, Col, Divider, Dropdown, Input, Menu, Row, Select, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { Color, Notification } from 'services';
import {toggleVideoForm, clearPostContentParams, updatePostContentParams, searchChapter, 
    updateContent, updateContentStatus, clearChaptersList} from 'actions/digitalLibrary_old';
import { fetchClasslist } from 'actions/calendar';
import {searchSubject} from 'actions/classRoom';
import { Button } from 'components/Button';


const {Option} = Select;

function validateYouTubeUrl(urlToParse){
    if (urlToParse) {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (urlToParse.match(regExp)) {
            return true;
        }
    }
    return false;
}

function VideoForm({
    showVideoForm, toggleVideoForm,
    clearPostContentParams,
    updatePostContentParams, fetchClasslist,
    subjectsFound, postContentParams,
    searchSubject, searchedChaptersList,
    searchChapter, subClasses,
    updateContent, updateContentLoader,
    updateContentStatus, clearChaptersList
}) {

    useEffect(() => {
        updatePostContentParams('video','type');
        return () => {
            clearPostContentParams();
            clearChaptersList();
        }
    }, []);

    // const menuOptions = () => (
    //     <Menu>
    //         {postContentParams.status === "Draft" ?
    //         <Menu.Item key="0" onClick={() => updateContentStatus("Published")}>
    //             <UploadOutlined style={{color: Color.green, marginRight: 5}}/> Publish
    //         </Menu.Item> : null}
    //         <Menu.Item key="1" onClick={() => updateContentStatus("Deleted")}>
    //             <DeleteFilled style={{color: Color.red, marginRight: 5}} /> Delete
    //         </Menu.Item>
    //     </Menu>
    //   );


    const handleSubjectSelect = (subject, obj) => {
        if(!obj || obj.id === -1) {
          return console.log('return');
        }
        updatePostContentParams(subject,'subjectName');
        updatePostContentParams(obj.id,'subjectId');
        updatePostContentParams([], 'standardIds');
        fetchClasslist(obj.id);
        clearChaptersList();
    }
    
    const handleSubjectSearch = async (query) =>  {
        const response = await searchSubject(query);
        updatePostContentParams(null,'subjectId');
        return response;
    }

    const handleChapterSelect = (chapter, obj) => {
        if(!obj || obj.id === -1) {
          return console.log('return');
        };
        updatePostContentParams(obj.id,'chapterId');
        updatePostContentParams(chapter,'chapterName');
      }

    const onAddClick = () => {
        if(!validateYouTubeUrl(postContentParams.link)){
            return Notification.error('error', "You are allowed to add youtube links only!")
        }
        updateContent()
    }

    const handleSubjectChange = val => {
        if (val === "") {
            updatePostContentParams(val, 'subjectName')
            updatePostContentParams(null, 'subjectId')
            updatePostContentParams(val, 'chapterName')
            updatePostContentParams(null, 'chapterId')
            updatePostContentParams([], 'standardIds')
            clearChaptersList();

        }else{
            updatePostContentParams(val, 'subjectName')
        }
    }

    const handleChaptertChange = val => {
        if (val === "") {
            updatePostContentParams(val, 'chapterName')
            updatePostContentParams(null, 'chapterId')

        }else{
            updatePostContentParams(val, 'chapterName')
        }
    }

    const handleChangeStandards = val => {
        if (!val.length) {
            updatePostContentParams(val, 'standardIds')
            updatePostContentParams("", 'chapterName')
            updatePostContentParams(null, 'chapterId')
            clearChaptersList();

        }else{
            updatePostContentParams(val, 'standardIds')
        }
    }

    return (
        <Modal
            className='rounded-border'
            visible={showVideoForm}
            title={<div className="r-jsb">
                        <div>{postContentParams.id? "Update Info": "Add Video"} </div> 
                        {/* <div>
                        {postContentParams.id && postContentParams.status === "Draft" ?  
                       
                        <Tooltip title="Publish Resource">
                            <UploadOutlined style={{color: Color.green, marginRight: 5}}  onClick={() => updateContentStatus("Published")}/>
                        </Tooltip>
                        : null }
                        </div> */}
                    </div>}
            closable={false}
            
            footer={
                <div>
                    <Button style={{width: 150}} onClick={() => toggleVideoForm(false)}>
                        Close
                    </Button>
                    {postContentParams.status !== "Published" ? 
                    <Button type="primary" style={{width: 150}} 
                        onClick={() => onAddClick()} loading={updateContentLoader} 
                        disabled={
                            !postContentParams ||
                            !postContentParams.subjectId ||
                            !postContentParams.chapterId ||
                            !postContentParams.standardIds.length ||
                            !postContentParams.link || postContentParams.link === "" ||
                            !postContentParams.title || postContentParams.title === ""
                    }
                    >
                        {postContentParams.id? "Update": "Add"}
                    </Button> : null}
                </div>
            }
        >
            <div>
                <Row>
                    <Col span={24}>
                        <div style={{fontWeight: 500}}>Subject<span style={{ color: Color.red }}>*</span></div>
                        <div className="m-t-10">
                            <AutoComplete
                                value={postContentParams.subjectName}
                                onChange={(value) =>
                                    handleSubjectChange(value)
                                }
                                onSelect={(value, obj) => handleSubjectSelect(value, obj)}
                                onSearch={(query) => handleSubjectSearch(query)}
                                options={subjectsFound}
                                style={{
                                    color:Color.primary,
                                    fontSize:16,
                                    fontWeight:600,
                                    backgroundColor: '#EEEEEE',
                                    width: "100%"
                                }}
                                disabled={postContentParams.status === "Published"}
                                >
                                <Input.Search
                                style={{ borderRadius:5, height: 32}}
                                placeholder="Search subject..."
                                loading={false}
                                allowClear
                                />
                            </AutoComplete>
                        </div>
                    </Col>
                </Row>
                <Row className="m-t-20"> 
                    <Col span={24}>
                        <div style={{fontWeight: 500}}>Class<span style={{ color: Color.red }}>*</span></div>
                        <div className="m-t-10">
                            <Select
                                className='bold-600'
                                placeholder="Select class(es)..."
                                disabled = {!postContentParams?.subjectId || !subClasses || !subClasses.length || postContentParams.status === "Published"}
                                value={postContentParams.standardIds[0]}
                                style={{width: "100%"}}
                                onChange={(value) => handleChangeStandards([value])}
                                loading={false}
                                >
                                    {subClasses && subClasses.map((item) =>
                                        <Option value={item.id}>
                                            {item.std}-{item.section}
                                        </Option>
                                    )}
                                
                            </Select>
                        </div>

                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col span={24}>
                        <div style={{fontWeight: 500}}>Chapter<span style={{ color: Color.red }}>*</span></div>
                        <div className="m-t-10">
                            <AutoComplete
                                options={searchedChaptersList}
                                style={{width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                                onSelect={(value, obj) => handleChapterSelect(value, obj)}
                                onSearch={(query) => searchChapter(query)}
                                disabled={!postContentParams?.subjectId || !postContentParams.standardIds.length || postContentParams.status === "Published"}
                                value={postContentParams.chapterName}
                                onChange={(value) => handleChaptertChange(value)}
                                >
                                <Input.Search 
                                style={{borderRadius:5, height: 32}} 
                                placeholder="Search chapter..."  allowClear
                                />
                            </AutoComplete>
                        </div>
                    </Col>
                </Row>
                <Row className="m-t-20"> 
                    <Col span={24}>
                        <div style={{fontWeight: 500}}>Topic<span style={{ color: Color.red }}>*</span></div>
                        <div className="m-t-10">
                            <Input type="text" placeholder="Enter Topic..." style={{width: "100%"}} 
                                value={postContentParams.title}
                                disabled={postContentParams.status === "Published"}
                                onChange={e => updatePostContentParams(e.target.value, 'title')} />
                        </div>

                    </Col>
                </Row>
                <Divider />
                <Row className="m-t-20"> 
                    <Col span={24}>
                        <div style={{fontWeight: 500}}>Link<span style={{ color: Color.red }}>*</span></div>
                        <div className="m-t-10">
                            {postContentParams.id ?
                                <a href={postContentParams.link} target="_blank">View Video</a>
                            
                            :<Input type="text" placeholder="Enter Youtube Link..." style={{width: "100%"}} 
                                value={postContentParams.link}
                                onChange={e => updatePostContentParams(e.target.value, 'link')} />}
                        </div>

                    </Col>
                </Row>

            </div>
            
        </Modal>
    )
}


const mapStateToProps = state => {
    const { subClasses } = state.calendar;
    const {
        showVideoForm, postContentParams, searchedChaptersList, updateContentLoader
    } = state.digitalLibrary;  
    const { subjectsFound } = state.classRoom;  
    return {
        showVideoForm, subjectsFound, postContentParams, searchedChaptersList, subClasses, updateContentLoader
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    toggleVideoForm: bool => dispatch(toggleVideoForm(bool)),
    clearPostContentParams: () => dispatch(clearPostContentParams()),
    updatePostContentParams: (val, key) => dispatch(updatePostContentParams(val, key)),
    fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
    searchSubject: (query) => dispatch(searchSubject(query)),
    searchChapter: (query) => dispatch(searchChapter(query)),
    updateContent: () => dispatch(updateContent()),
    updateContentStatus: (status) => dispatch(updateContentStatus(status)),
    clearChaptersList: () => dispatch(clearChaptersList())
  });


export default connect(mapStateToProps, mapDispatchToProps)(VideoForm);