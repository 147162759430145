// import { enableEslintTypescript } from 'customize-cra';
import moment from 'moment';
import { Api } from '../services';
import { UPDATE_PROFILE } from './profile';
import Notification from "services/Notification";
import { fetchMarkingSchemeDataTS } from './marketTest';


export const POST_TEST_CREATE = 'POST_TEST_CREATE';
export const UPDATE_PIECHART_DATA = 'UPDATE_PIECHART_DATA'
export const UPDATE_CLASSCARD_DATA = 'UPDATE_CLASSCARD_DATA'
export const SET_SELECTED_CLASSROOM = 'SET_SELECTED_CLASSROOM'
export const UPDATE_RESULT_TO_PUBLISH = 'UPDATE_RESULT_TO_PUBLISH'
export const UPDATE_SUBMITTED_TESTS = 'UPDATE_SUBMITTED_TESTS'
export const UPDATE_SUBMITTED_TESTS_DETAILS = 'UPDATE_SUBMITTED_TESTS_DETAILS'
export const UPDATE_EVALUATED_MARK = 'UPDATE_EVALUATED_MARK'
export const UPDATE_EVALUATED_COMMENT = 'UPDATE_EVALUATED_COMMENT'
export const UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID = 'UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID'
export const UPDATE_EVALUATION_QUESTION_DATA = 'UPDATE_EVALUATION_QUESTION_DATA'
export const UPDATE_QUESTION_GRIDS_ARRAY = 'UPDATE_QUESTION_GRIDS_ARRAY'
export const UPDATE_EVALUATION_QUESTION_ID = 'UPDATE_EVALUATION_QUESTION_ID'
export const UPDATE_CURRENT_QUESTION_INDEX = 'UPDATE_CURRENT_QUESTION_INDEX'
export const UPDATE_SUBMITTED_TESTS_ID = 'UPDATE_SUBMITTED_TESTS_ID'
export const UPDATE_SUBMITTED_TESTS_SUBMITTED_ID = 'UPDATE_SUBMITTED_TESTS_SUBMITTED_ID'
export const UPDATE_POST_EVALUATION_OBJECT = 'UPDATE_POST_EVALUATION_OBJECT'
export const UPDATE_SUBMITTED_TESTS_USERS = 'UPDATE_SUBMITTED_TESTS_USERS'
export const UPDATE_PUBLISHED_RESULT = 'UPDATE_PUBLISHED_RESULT'
export const SET_TEXT_FALSE = "SET_TEXT_FALSE"
export const UPDATE_TEST_TO_PUBLISH = 'UPDATE_TEST_TO_PUBLISH'
export const UPDATE_ONGOING_TEST = 'UPDATE_ONGOING_TEST'
export const UPDATE_DRAFTS = 'UPDATE_DRAFTS'
export const UPDATE_BARCHART_DATA = 'UPDATE_BARCHART_DATA'
export const SET_SELECTED_CLASSROOM_ID = 'SET_SELECTED_CLASSROOM_ID'
export const UPDATE_SUBJECTS_BY_CLASS = 'UPDATE_SUBJECTS_BY_CLASS'
export const SET_SELECTED_SUBJECT = 'SET_SELECTED_SUBJECT'
export const UPDATE_STANDARDWISE_BARCHART_DATA = 'UPDATE_STANDARDWISE_BARCHART_DATA'
export const UPDATE_TODAY_ACTIVITY = 'UPDATE_TODAY_ACTIVITY'
export const UPDATE_UPCOMING_TESTS = 'UPDATE_UPCOMING_TESTS'
export const UPDATE_NO_DATA = 'UPDATE_NO_DATA'
export const UPDATE_STANDARDS_LIST = 'UPDATE_STANDARDS_LIST'
export const GET_TEST_NAME_ARR = 'GET_TEST_NAME_ARR'
export const UPDATE_STUDENT_RESULT = 'UPDATE_STUDENT_RESULT'
export const UPDATE_SELECTED_TEST = 'UPDATE_SELECTED_TEST'
export const UPDATE_SELECTED_STUDENT = 'UPDATE_SELECTED_STUDENT'
export const GET_RESULTS_LOADER = 'GET_RESULTS_LOADER'
export const UPDATE_CANCELLED_TESTS = 'UPDATE_CANCELLED_TESTS'
export const UPDATE_SELECTED_TEST_ID = 'UPDATE_SELECTED_TEST_ID'
export const SET_EDIT_MODAL_VISIBLE = 'SET_EDIT_MODAL_VISIBLE'
export const SET_C_MODAL_VISIBLE = 'SET_C_MODAL_VISIBLE'
export const SET_C_MODAL_TYPE = 'SET_C_MODAL_TYPE'
export const TOGGLE_RELOADER_FLAG = 'TOGGLE_RELOADER_FLAG'
export const GET_INDEX_OF_TEST = 'GET_INDEX_OF_TEST'
export const CLEAR_TEST_CREATE_OBJ = 'CLEAR_TEST_CREATE_OBJ'
export const STORE_GENERATE_QUESTIONS_OBJ = 'STORE_GENERATE_QUESTIONS_OBJ'
export const SHOW_TEXT_EDITOR = 'SHOW_TEXT_EDITOR'
export const UPDATE_SUB_WITH_CHAPTERS = 'UPDATE_SUB_WITH_CHAPTERS'
export const ADD_MARKS_FOR_QUES = 'ADD_MARKS_FOR_QUES'
export const RENDER_EDITOR_CONTENT = 'RENDER_EDITOR_CONTENT'
export const CHOOSE_CORRECT_ANSWER_ADMIN = 'CHOOSE_CORRECT_ANSWER_ADMIN'
export const STORE_TEST_QUES_TYPE = 'STORE_TEST_QUES_TYPE'
export const POST_QUESTION_CREATE = 'POST_QUESTION_CREATE'
export const UPDATE_LIST_OF_QUESTIONS = 'UPDATE_LIST_OF_QUESTIONS'
export const STORE_EDITOR_REMOVED_IMGS = 'STORE_EDITOR_REMOVED_IMGS'
export const STORE_EDITOR_ADDED_IMGS = 'STORE_EDITOR_ADDED_IMGS'
export const DELETE_QUILL_IMGS = 'DELETE_QUILL_IMGS'
export const CLEAR_GENERATE_QUES_FIELDS = 'CLEAR_GENERATE_QUES_FIELDS'
export const RESET_GENERATE_QUES_FIELDS = 'RESET_GENERATE_QUES_FIELDS'
export const STORE_EDIT_GENERATE_QUE_ID = 'STORE_EDIT_GENERATE_QUE_ID'
export const GET_EACT_QUES_DATA_LOADER = 'GET_EACT_QUES_DATA_LOADER'
export const GENERATE_QUES_LIST_VIEW_LOADER = 'GENERATE_QUES_LIST_VIEW_LOADER'
export const STORE_GENERATE_QUES_LIST_VIEW = 'STORE_GENERATE_QUES_LIST_VIEW'
export const CHANGE_TEST_STATUS_LOADER = 'CHANGE_TEST_STATUS_LOADER'
export const CHANGE_TEST_STATUS_MODAL = 'CHANGE_TEST_STATUS_MODAL'
export const CREATE_TEST_MODAL = 'CREATE_TEST_MODAL'
export const UNSET_CREATE_TEST_MODAL_BOOL = 'UNSET_CREATE_TEST_MODAL_BOOL'
export const UPDATE_LISTVIEW_TYPE = 'UPDATE_LISTVIEW_TYPE'
export const UPDATE_FEEDBACK_DATA = 'UPDATE_FEEDBACK_DATA'
export const UPDATE_READY_TO_PUBLISH = 'UPDATE_READY_TO_PUBLISH'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_FEEDBACK_FROM_TEACHER = 'UPDATE_FEEDBACK_FROM_TEACHER'
export const UPDATE_DATA_OPERATOR_STATS = 'UPDATE_DATA_OPERATOR_STATS'
export const TESTING = 'TESTING'
export const UPDATE_TESTS_TO_REVIEW = 'UPDATE_TESTS_TO_REVIEW'
export const EDIT_DETAILS_LOADER = 'EDIT_DETAILS_LOADER'
export const CLONE_LOADER = 'CLONE_LOADER'
export const UPDATE_RESULTS_DATA = 'UPDATE_RESULTS_DATA'
export const UPDATE_SELECTED_CARD_TYPE = 'UPDATE_SELECTED_CARD_TYPE'
export const UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS = 'UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS'
export const UPDATE_TOTAL_MARKS_OBTAINED = 'UPDATE_TOTAL_MARKS_OBTAINED'
export const UPDATE_PALETTE_DATA = 'UPDATE_PALETTE_DATA'
export const UPDATE_NUMERIC_ANSWER = 'UPDATE_NUMERIC_ANSWER'
export const UPDATE_POST_PARAMS2 = 'UPDATE_POST_PARAMS2'
export const SET_OPEN_TAB = 'SET_OPEN_TAB'
export const UPDATE_STD_FOR_CREATING_TEST = 'UPDATE_STD_FOR_CREATING_TEST'
export const SET_DASHBOARD_META = 'SET_DASHBOARD_META'
export const UPDATE_CHAPTER_FOR_CREATING_TEST = 'UPDATE_CHAPTER_FOR_CREATING_TEST'
export const REMOVE_SELECTED_CHAPTER = 'REMOVE_SELECTED_CHAPTER'
export const UPDATE_SYLLABUS = 'UPDATE_SYLLABUS'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const SET_CHAPTERS_LIST = 'SET_CHAPTERS_LIST'
export const SET_LOADER = 'SET_LOADER'
export const UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST = 'UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST'
export const UPDATE_TODAY_RESULTS = 'UPDATE_TODAY_RESULTS'
export const SET_SELECTED_SECTION_ID = 'SET_SELECTED_SECTION_ID'
export const CHOOSE_BOOL_ANSWER = 'CHOOSE_BOOL_ANSWER'
export const ADD_NEW_QUESTIONS = 'ADD_NEW_QUESTIONS'
export const SET_SUBJECT_SECTION = 'SET_SUBJECT_SECTION'
export const STORE_MARKING_SCHEME_DATA = 'STORE_MARKING_SCHEME_DATA'
export const UPDATE_QUESTION_TYPE = 'UPDATE_QUESTION_TYPE'
export const STORE_QUESTION_INDEX = 'STORE_QUESTION_INDEX'
export const STORE_SECTION_INDEX = 'STORE_SECTION_INDEX'
export const STORE_MARKING_SCHEME_CARD_DATA = "STORE_MARKING_SCHEME_CARD_DATA";
export const SEARCH_FILTER = "SEARCH_FILTER";
export const STORE_MARKING_SCHEME_TITLE = "STORE_MARKING_SCHEME_TITLE";
export const STORE_MARKING_SCHEME_DESC = "STORE_MARKING_SCHEME_DESC";
export const STORE_MARKING_SCHEME_SUBJECTIVE = "STORE_MARKING_SCHEME_SUBJECTIVE";
export const STORE_MARKING_SCHEME_OBJECTIVE = "STORE_MARKING_SCHEME_OBJECTIVE";
export const STORE_MARKED_SCHEME_CARD_ID = "STORE_MARKED_SCHEME_CARD_ID";
export const MARKED_SCHEME_CARD_DETAILS = "MARKED_SCHEME_CARD_DETAILS";
export const CREATE_ADDITIONAL_QUESTION = 'CREATE_ADDITIONAL_QUESTION';
export const STORE_SCHEME_ID = 'STORE_SCHEME_ID'
export const FROM_CREATE_NEW_BUTTON = "FROM_CREATE_NEW_BUTTON";
export const SET_FROM_SECTION_MARKING_SCHEME = "SET_FROM_SECTION_MARKING_SCHEME"
export const ADD_MARKING_SCHEME_MARKS = 'ADD_MARKING_SCHEME_MARKS';
export const TOTAL_QUES_IN_CHAPTER = "TOTAL_QUES_IN_CHAPTER";
export const STORE_DELETING_CHAPTER_ID = "STORE_DELETING_CHAPTER_ID";
export const STORE_IMPORT_TEST_ID = "STORE_IMPORT_TEST_ID";
export const STORE_SUBJECT_NAME = "STORE_SUBJECT_NAME";
export const STORE_LIST_VIEW_DATA = "STORE_LIST_VIEW_DATA";
export const STORE_PAGE_NUMBER = "STORE_PAGE_NUMBER";
export const STORE_SUBJECT_ID = "STORE_SUBJECT_ID";
export const STORE_QUESTION_PALETTE_DATA = "STORE_QUESTION_PALETTE_DATA";
export const MODIFY_OPTIONS = 'MODIFY_OPTIONS'
export const STORE_CHAPTERS = 'STORE_CHAPTERS'
export const SET_FROM_IMPORT_LIST_VIEW = 'SET_FROM_IMPORT_LIST_VIEW'
export const SAVE_START_TIME = 'SAVE_START_TIME'
export const ONLINE_TEST_SERIES_COMBINEDATA = 'ONLINE_TEST_SERIES_COMBINEDATA '



const updateTestToPublish = data => (
    {
        type: UPDATE_TEST_TO_PUBLISH,
        data
    }
)

const updateResultToPublish = data => (
    {
        type: UPDATE_RESULT_TO_PUBLISH,
        data
    }
)
const updateSubmittedTests = data => (
    {
        type: UPDATE_SUBMITTED_TESTS,
        data
    }
)
const updatePublishedResult = data => (
    {
        type: UPDATE_PUBLISHED_RESULT,
        data
    }
)
export const updateSubmittedTestsDetails = value => (
    {
        type: UPDATE_SUBMITTED_TESTS_DETAILS,
        value
    }
)
const updateSubmittedTestsByStudentId = value => (
    {
        type: UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID,
        value
    }
)
export const updateEvaluationQuestionData = value => (
    {
        type: UPDATE_EVALUATION_QUESTION_DATA,
        value
    }
)
const updateQuestionGridsArray = value => (
    {
        type: UPDATE_QUESTION_GRIDS_ARRAY,
        value
    }
)
export const updateEvaluationQuestionId = value => (
    {
        type: UPDATE_EVALUATION_QUESTION_ID,
        value
    }
)
const updateSubmittedTestsUsers = data => (
    {
        type: UPDATE_SUBMITTED_TESTS_USERS,
        data
    }
)
//Mark given by Admin/Teacher
export const updateEvaluatedMark = value => (
    {
        type: UPDATE_EVALUATED_MARK,
        value
    }
)
//comment given by Admin/Teacher
export const updateEvaluatedComment = value => (
    {
        type: UPDATE_EVALUATED_COMMENT,
        value
    }
)



export const STORE_CREATE_TEST_MODAL_DATA = 'STORE_CREATE_TEST_MODAL_DATA';
export const GET_EXAMINER_NAME = 'GET_EXAMINER_NAME';
export const GET_EXAMINER_LOADER = 'GET_EXAMINER_LOADER';
export const GET_SUBJECTS_LOADER = 'GET_SUBJECTS_LOADER';
export const GET_EVALUATION_PAGE_LOADER = 'GET_EVALUATION_PAGE_LOADER';
export const GET_SUBJECTS_NAME = 'GET_SUBJECTS_NAME';
export const REMOVE_SELECTED_SEARCHED_SUBJS = 'REMOVE_SELECTED_SEARCHED_SUBJS';
export const UPDATE_ONE_STUDENT_RESULT = 'UPDATE_ONE_STUDENT_RESULT'
export const SET_IMPORT_META = 'SET_IMPORT_META'

export const storeCreateTestModalData = (key, value) => ({
    type: STORE_CREATE_TEST_MODAL_DATA,
    key,
    value,
});

export const universalLoader = (name, varname, bool) => ({
    type: name,
    varname,
    bool,
});

export const updateCurrentQuestionIndex = value => ({
    type: UPDATE_CURRENT_QUESTION_INDEX,
    value
})

export const updateSubmittedTestsId = value => ({
    type: UPDATE_SUBMITTED_TESTS_ID,
    value
})

export const updateSubmittedTestsStudentId = value => ({
    type: UPDATE_SUBMITTED_TESTS_SUBMITTED_ID,
    value
})

export const updatePostEvaluationObject = () => ({
    type: UPDATE_POST_EVALUATION_OBJECT,
})

export const setSelectedClassroom = newSelection => ({
    type: SET_SELECTED_CLASSROOM,
    newSelection
})

export const setSelectedClassroomId = data => ({
    type: SET_SELECTED_CLASSROOM_ID,
    data
})
const updatePiechartData = pieData => ({
    type: UPDATE_PIECHART_DATA,
    pieData
})

const updateClassCardData = classTestInfo => ({
    type: UPDATE_CLASSCARD_DATA,
    classTestInfo
})


const handleClassCardData = (dispatch, subjects) => {
    const subjectLength = subjects?.length ?? 0;
    const classdata = {}
    let currentStandard = "";
    let newData = {}
    for (let i = 0; i < subjectLength; i++) {
        currentStandard = subjects[i].standard_std;
        newData = {
            id: subjects[i].standard_id,
            section: subjects[i].standard_section,
            stream: subjects[i].standard_stream,
            chapterTestCount: subjects[i].chapterTestCount,
            partTestCount: subjects[i].partTestCount,
            fullTestCount: subjects[i].fullTestCount
        }
        if (currentStandard in classdata) {
            classdata[currentStandard].push(newData)
        }
        else {
            classdata[currentStandard] = [newData];
        }
    }
    dispatch(updateClassCardData(classdata))
}

const updateStandards = (data) => ({
    type: UPDATE_STANDARDS_LIST,
    data
})

export const testing = (data) => ({
    type: TESTING,
    data
});

export const setChaptersList = (res) => ({
    type: SET_CHAPTERS_LIST,
    res
})


export const fetchDashBoardData = (callback, filter = "", startDate = "", endDate = "") => {
    return (dispatch, getState) => {
        const { userType } = getState().onlineTestReducer;
        dispatch(setLoader('indexLoader', true));
        return Api.get(`/testv2`).send((response) => {
            if (response !== undefined) {
                handleClassCardData(dispatch, response.standards);
                if (response.standards) {
                    const stds = response.standards?.map((item) => ({ id: item.standard_id, std: item.standard_std, section: item.standard_section }))
                    dispatch(updateStdForCreatingTest(stds));
                }
                dispatch(updateStandards(response.standards));
                const { userType } = getState().onlineTestReducer;
                if (userType == "admin") {
                    dispatch(setDashboardMeta({
                        feedbackCount: response.feedbackCount,
                        ongoingCount: response.ongoingCount,
                        readyToPublishCount: response.readyToPublishCount,
                        resultCount: response.resultCount,
                        resultsPublishedCount: response.resultsPublishedCount,
                        reviewCount: response.reviewCount
                    }));
                    dispatch(updateTestsToReview(response.review));
                    dispatch(updatePiechartData(response.pieChart));
                    dispatch(updateResultToPublish(response.results));
                    dispatch(updateSubmittedTests(response.submittedTests));
                    dispatch(updateOngoingTest(response.ongoing));
                    dispatch(updateFeedbackFromTeacher(response.feedback));
                    dispatch(updateTestToPublish(response.readyToPublish));
                    dispatch(updatePublishedResult(response.resultsPublished));
                    // dispatch(updateResultsData(response))
                }
                else if (userType == "dataOperator") {
                    dispatch(setDashboardMeta({
                        feedbackCount: response.feedbackCount,
                    }));
                    dispatch(updateFeedbackFromTeacher(response.feedback));
                    dispatch(updateOperatorStats(response.overAllStats));

                }
                else if (userType == "teacher") {
                    // dispatch(updateFeedbackFromTeacher(response.feedback));
                    dispatch(setDashboardMeta({
                        reviewCount: response.reviewCount,
                        ongoingCount: response.ongoingCount
                    }));
                    dispatch(updateOngoingTest(response.ongoing));
                    dispatch(updateTestsToReview(response.review));
                    // dispatch(updateResultToPublish(response.results));
                    dispatch(updatePiechartData(response.pieChart));
                }
                dispatch(setLoader('indexLoader', false));
            }
            callback(false);
        })
    }
}


export const fetchStandards = (callback = null) => {
    return (dispatch, getState) => {
        const { filter } = getState().onlineTestReducer;
        const params = {
            search: filter.indexSearch
        }
        dispatch(setLoader('indexSearchLoader', true));
        return Api.get(`/testv2/standards`).params(params).send((response) => {
            if (response !== undefined) {
                handleClassCardData(dispatch, response);
            }
            dispatch(setLoader('indexSearchLoader', false));
            // callback(false);
        })
    }
}


export const fetchTests = (type, callback, startDate = "", endDate = "", searchText = "", standardId = '', subjectId = '') => {
    return (dispatch, getState) => {
        // console.log("from fetch test to publish", startDate, endDate, searchText);
        dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', true));
        const { selectedClassroom } = getState().onlineTestReducer;
        let declared = '';
        if (type == "test-result-published") {
            declared = true;
        }
        return Api.get(`/testv2/${type}?startDate=${startDate}&endDate=${endDate}&name=${searchText}&declared=${declared}&standardId=${standardId}&subjectId=${subjectId}`).send((response) => {
            if (response !== undefined) {
                if (type == "test-publish") {
                    dispatch(updateTestToPublish(response.testToPublish));
                }
                else if (type == "test-result-published") {
                    dispatch(updatePublishedResult(response.tests))
                }
                else if (type == "test-result-publish") {
                    dispatch(updateResultsData(response))
                }
                dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', false));
                callback(false);
            }
        })
    }
}


export const fetchTestsByStage = (type, callback) => {
    return (dispatch, getState) => {
        // console.log("from fetch test to publish", startDate, endDate, searchText);
        dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', true));
        const { selectedClassroom, filter, selectedSubject } = getState().onlineTestReducer;
        const params = {

        }
        if (filter.isTabPage) {
            dispatch(setLoader('tabPageLoader', true));
        } else if (filter.isVA) {
            dispatch(setLoader('vaPageLoader', true));
        }
        if (filter.isVA) {
            params.name = filter.VAsearch,
                params.syllabus = filter.VAsyllabus,
                params.standardIds = filter.VAclasses,
                params.page = -1
            params.testDate = filter.VAtype === 'results' ? null : filter.isVAdatefiltered ? filter.vaDate : null
            params.resultDate = filter.VAtype === 'results' ? filter.isVAdatefiltered ? filter.vaDate : null : null
        } else if (filter.isTabPage) {
            params.page = -1,
                params.name = filter.tabSearch,
                params.standardIds = [selectedClassroom?.id];
            params.syllabus = [filter.selectedSyllabus];
            params.subjectId = selectedSubject.isCombined ? null : selectedSubject.subjectId;
            params.chapterId = filter.selectedSyllabus === 'Chapter Test' ? filter.selectedChapter.chapterId : null;
            params.combined = selectedSubject.isCombined ? true : null;
            params.testDate = (type === "results-published" || type === "results-to-publish") ? null : filter.isTAdatefiltered ? filter.taDate : null
            params.resultDate = (type === "results-published" || type === "results-to-publish") ? filter.isTAdatefiltered ? filter.taDate : null : null
            params.testType = filter.testTypeSelector
        }
        return Api.get(`/testv2/tests-by-stage?stage=${type}`).params(params).send((response) => {
            if (response !== undefined) {
                if (type == "ready") {
                    dispatch(updateTestToPublish(response.tests));
                }
                else if (type == "results-published") {
                    dispatch(updatePublishedResult(response.tests))
                }
                else if (type == "results-to-publish") {
                    dispatch(updateResultsData(response.tests))
                }
                else if (type === 'draft') {
                    dispatch(updateDrafts(response.tests))
                }
                else if (type === 'ongoing') {
                    dispatch(updateOngoingTest(response.tests))
                }
                else if (type === 'upcoming') {
                    dispatch(updateUpcomingTests(response.tests))
                }
                else if (type === "submitted") {
                    dispatch(updateSubmittedTests(response.tests))
                }
                else if (type === "feedback") {
                    dispatch(updateFeedbackFromTeacher(response.tests))
                } else if (type === 'review') {
                    dispatch(updateTestsToReview(response.tests))
                } else if (type == 'cancelled') {
                    dispatch(updateCancelledTest(response.tests))
                }
                dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', false));
                dispatch(setLoader('tabPageLoader', false));
                dispatch(setLoader('vaPageLoader', false));
                callback(false);
            }
        })
    }
}

const updateBarchartData = (data) => ({
    type: UPDATE_BARCHART_DATA,
    data
})

export const fetchPublishedResult = () => {
    return (dispatch, getState) => {
        const params = {

        }
        return Api.get(`/testv2/test-result-published`).send((response, error) => {
            // console.log("fetched Publised Result")
            if (response !== undefined) {
                // console.log("fetched Publised Result", response.tests)
                dispatch(updatePublishedResult(response.tests))
            }
        })
    }
}

const updateStandardWiseBarchartData = (data) => ({
    type: UPDATE_STANDARDWISE_BARCHART_DATA,
    data
})

const monthToVal = {
    "Jan": 1, "Feb": 2, "Mar": 3, "Apr": 4, "May": 5, "Jun": 6, "Jul": 7, "Aug": 8,
    "Sep": 9, "Oct": 10, "Nov": 11, "Dec": 12
}

const updateNodata = (data) => ({
    type: UPDATE_NO_DATA,
    data
})

const compare = (a, b) => {
    if (monthToVal[a.date] < monthToVal[b.date]) {
        return -1;
    }
    if (monthToVal[a.date] > monthToVal[b.date]) {
        return 1;
    }
    return 0;
}

const keynames = { cancelledCount: "Cancelled count", scheduledCount: "Scheduled count", heldCount: "Held count", draftCount: "Draft count", publishedCount: "Published count", chapterTests: "Chapter test", fullTests: "Full test", partTests: "Part test" }
const handleBarchartData = (dispatch, data, filter, userType) => {
    const reformatted_data = []
    let max_val = 0
    if (userType != "dataOperator") {
        for (let i = 0; i < data?.length; i++) {
            for (const key in data[i]) {
                let temp = {}
                if ((key == "chapterTests") || (key == "fullTests") || (key == "partTests")) {
                    temp['date'] = filter == "month" ? data[i]['unit'].slice(0, 3) : data[i]['unit']
                    temp['name'] = key === 'publishedCount' ? keynames["scheduledCount"] : keynames[key]
                    temp['totalCount'] = parseInt(data[i][key])
                    max_val = Math.max(parseInt(data[i][key]), max_val)
                    reformatted_data.push(temp)
                }
            }
        }
    }
    else {
        for (let i = 0; i < data?.length; i++) {
            for (const key in data[i]) {
                let temp = {}
                if ((key == "draftCount") || (key == "publishedCount")) {
                    temp['date'] = filter == "month" ? data[i]['unit'].slice(0, 3) : data[i]['unit']
                    temp['name'] = keynames[key];
                    temp['totalCount'] = parseInt(data[i][key])
                    max_val = Math.max(parseInt(data[i][key]), max_val)
                    reformatted_data.push(temp)
                }
            }
        }
    }
    if (max_val == 0) {
        dispatch(updateNodata(true))
    }
    else if (max_val > 0) {
        dispatch(updateNodata(false))
    }
    filter == "month" && reformatted_data.sort(compare);
    return reformatted_data;
}


export const fetchBarChartData = (type, callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('graphLoader', true));
        const { userType, filter, selectedClassroom } = getState().onlineTestReducer;
        const { isFromClassRoomDetails, selectedSection } = getState().classRoom
        let filter1 = 'week'
        const params = {};
        if (type === 'overall') {

        } else {
            params.standardId = isFromClassRoomDetails ? selectedSection?.id : selectedClassroom?.id
        }
        if (filter.selectedGraphFilter === 'week') {
            params.startDate = filter.graphStartDate;
            params.endDate = filter.graphEndDate
        } else {
            filter1 = 'month'
            params.year = filter.graphYear
        }
        return Api.get(`/testv2/homepage-graph`).params(params).send((response) => {
            if (response !== undefined) {
                let reformatted_data = handleBarchartData(dispatch, response, filter1, userType);
                if (type === 'overall') {
                    dispatch(updateBarchartData(reformatted_data));

                } else {
                    dispatch(updateStandardWiseBarchartData(reformatted_data))
                }
                callback(false);
                dispatch(setLoader('graphLoader', false));
            }
        })
    }
}


export const clearTestCreateObj = () => ({
    type: CLEAR_TEST_CREATE_OBJ,
})
export const storeGenerateQuestionsObj = (res) => ({
    type: STORE_GENERATE_QUESTIONS_OBJ,
    res,
})

export const postTestCreate = (callback, success, sectionValue) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', true));
        const { testCreateObj, createTestModalName, generateQuesObj, listViewType, storeSubjectSection } = getState().onlineTestReducer;
        let isClassScreen = window.location.pathname == '/test-std-to-subjects-screen' ? true : false;
        const params = {
            // chapterIds: [1274, 1272],
            // chapterNames: ["Fractions", "Understanding Elementary Shapes"],
            // cloneTestId: null,
            // id: 21,
            // status: "Draft",
            // sub: undefined,
            // subjectId: -1,
            index: testCreateObj.tIndex,
            instructions: testCreateObj.instruction,
            selectedTeacher: testCreateObj.teacherId,
            standardIds: typeof testCreateObj.standardId == "string" ? [testCreateObj.standardId] : testCreateObj.standardId,
            testDate: moment(testCreateObj.heldDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            startTime: testCreateObj.heldStartTime,
            endTime: testCreateObj.heldEndTime,
            // subjectIds: testCreateObj.subjectId,
            // subjectNames: testCreateObj.subjectName,
            teacherId: testCreateObj.teacherId,
            teacherName: testCreateObj.teacherName,
            title: testCreateObj.title,
            testType: testCreateObj.testType,
            syllabus: testCreateObj.syllabus === 'Chapter' ? 'Chapter Test' : testCreateObj.syllabus === 'Full' ? 'Full Test' : testCreateObj.syllabus === 'Part' ? 'Part Test' : '',
            chosenChapterIds: testCreateObj.chapterList?.map((item) => item.id),
            subjectsSection: storeSubjectSection,
            sectionValue: sectionValue

        }
        if (testCreateObj.testType === 'Objective') {
            params.resultDate = moment(testCreateObj.resultDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            params.resultTime = testCreateObj.resultTime;
        }
        if (createTestModalName === 'test-edit') {
            params.id = generateQuesObj.id
        }
        else if (createTestModalName == 'test-clone') {
            params.cloneTestId = generateQuesObj.id
        }
        if (params.id) {
            delete params.id
        }
        return Api.post(`/test`).params(params).send(response => {
            success()
            dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', false));
            if (response != undefined && response.show.type !== 'error') {
                if (createTestModalName === 'test-create') {
                    dispatch(clearGenerateQuesFields());
                }
                dispatch(clearTestCreateObj());
                dispatch(storeGenerateQuestionsObj(response));
                dispatch(updateListOfQuestions(injectQuestionIndex(response)));

                if ((response.testType === 'Subjective')) {
                    setTimeout(() => {
                        dispatch(getMarkingSchemeMarks(response.testType))
                    }, 3000)
                }
                callback(response);
            }
        });
    }
}
export const postTestEdit = (sectionValue, callback, success) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', true));
        const { testCreateObj, createTestModalName, generateQuesObj, listViewType, storeSubjectSection, schemeId } = getState().onlineTestReducer;
        let isClassScreen = window.location.pathname == '/test-std-to-subjects-screen' ? true : false;
        const params = {
            // chapterIds: [1274, 1272],
            // chapterNames: ["Fractions", "Understanding Elementary Shapes"],
            // cloneTestId: null,
            // id: 21,
            // status: "Draft",
            // sub: undefined,
            // subjectId: -1,
            index: testCreateObj.tIndex,
            instructions: testCreateObj.instruction,
            selectedTeacher: testCreateObj.teacherId,
            standardIds: typeof testCreateObj.standardId === "object" ? testCreateObj.standardId : [testCreateObj.standardId],
            testDate: moment(testCreateObj.heldDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            startTime: testCreateObj.heldStartTime,
            endTime: testCreateObj.heldEndTime,
            // subjectIds: testCreateObj.subjectId,
            // subjectNames: testCreateObj.subjectName,
            teacherId: testCreateObj.teacherId,
            teacherName: testCreateObj.teacherName,
            title: testCreateObj.title,
            testType: testCreateObj.testType,
            syllabus: testCreateObj.syllabus === 'Chapter' ? 'Chapter Test' : testCreateObj.syllabus === 'Full' ? 'Full Test' : testCreateObj.syllabus === 'Part' ? 'Part Test' : '',
            chosenChapterIds: testCreateObj.chapterList?.map((item) => item.id),
            subjectsSection: storeSubjectSection,
            sectionValue

        }
        if (testCreateObj.testType === 'Objective') {
            params.resultDate = moment(testCreateObj.resultDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            params.resultTime = testCreateObj.resultTime;
        }
        if (createTestModalName === 'test-edit') {
            params.id = generateQuesObj.id
        }
        else if (createTestModalName == 'test-clone') {
            params.cloneTestId = generateQuesObj.id
        }
        return Api.post(`/test`).params(params).send(response => {
            callback();
            dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', false));
            if (response != undefined && response.show.type !== 'error') {
                if (success) {
                    success();
                }
                // if(createTestModalName === 'test-create'){
                //   dispatch(clearGenerateQuesFields());
                // }
                dispatch(storeGenerateQuestionsObj(response));
                dispatch(updateListOfQuestions(injectQuestionIndex(response)));
                if (response.testType === 'Subjective') {
                    setTimeout(() => {
                        dispatch(getMarkingSchemeMarks(response.testType))
                    }, 3000)
                }

            }
        });
    }
}

export const getSubjectsName = (res) => ({
    type: GET_SUBJECTS_NAME,
    res,
});
// export const getSubjects = (text, standardId) => {
//     return (dispatch, getState) => {
//         dispatch(setLoader('modalSubjectLoader', true));
//         const { testCreateObj } = getState().onlineTestReducer;
//         return Api.get(`/testv2/subject/autocomplete?name=${text}&standardId=${standardId}`).send(response => {
//             if (response != undefined) {
//                 dispatch(getSubjectsName(response));
//                 dispatch(setLoader('modalSubjectLoader', false));
//             }
//         });
//     }
// }

export const getSubjects = (testSeriesId) => {
    console.log("yyyyyyyyyyyyyyyyyyyyyyy", testSeriesId)
    return (dispatch, getState) => {
        dispatch(setLoader('modalSubjectLoader', true));
        const { testCreateObj } = getState().onlineTestReducer;
        return Api.get(`/testSeries/autocomplete?testSeriesId=${testSeriesId}`).send(response => {
            if (response != undefined) {
                console.log("yyyyyyyyyyyyyyyyyyyyyyy1111111", response)
                dispatch(getSubjectsName(response));
                dispatch(setLoader('modalSubjectLoader', false));
            }
        });
    }
}

export const removeSelectedSearchSubjs = (index, text) => ({
    type: REMOVE_SELECTED_SEARCHED_SUBJS,
    index,
    text
});
export const getExaminerName = (res) => ({
    type: GET_EXAMINER_NAME,
    res,
});
export const getExaminer = (text) => {
    return (dispatch) => {
        dispatch(universalLoader('GET_EXAMINER_LOADER', 'getExaminerBool', true));
        return Api.get(`/user/autocomplete?role=Teacher&name=${text}&includeSuperAdmin=1`).send(response => {
            dispatch(universalLoader('GET_EXAMINER_LOADER', 'getExaminerBool', false));
            // console.log('response getExaminer+++', response);
            if (response != undefined) {
                dispatch(getExaminerName(response));
            }
        });
    }
}

const updateOngoingTest = ongoingTests => ({
    type: UPDATE_ONGOING_TEST,
    ongoingTests
})


const updateDrafts = drafts => ({
    type: UPDATE_DRAFTS,
    drafts
})


const updateSubjectsByClass = data => ({
    type: UPDATE_SUBJECTS_BY_CLASS,
    data
})

export const fetchClassSubjects = (standardId, callback) => {
    return (dispatch) => {
        return new Promise((resolve) => {
            Api.get(`/testv2/test-standard?standardId=${standardId}`).send((response) => {
                if (response !== undefined) {
                    resolve();
                    dispatch(updateSyllabus(response.syllabuses));
                    dispatch(updateSubjectsByClass(response.subjects ? response.subjects : []));
                    dispatch(updateTodayActivities(response));
                    callback(false);
                    dispatch(setLoader('syllabusPageLoader', false));
                }
            })
        })
    }
}

export const setSelectedSubject = data => ({
    type: SET_SELECTED_SUBJECT,
    data
})

export const updateTodayActivities = data => ({
    type: UPDATE_TODAY_ACTIVITY,
    data
})


export const fetchTestSubmitDetails = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('studentSearchLoader', true));
        const { submittedTestsId, filter } = getState().onlineTestReducer;
        return Api.get(`/testv2/test-submit-details?testId=${submittedTestsId}&search=${filter?.studentSearch}`).send((response, error) => {
            // console.log("Response ",response )
            if (response !== undefined) {
                dispatch(updateSubmittedTestsDetails(response.test))
                dispatch(updateSubmittedTestsUsers(response.users))
                dispatch(updateSubmittedTestsAbsentStudents(response.absentStudents))
            }
            callback(false)
            dispatch(setLoader('studentSearchLoader', false));
        })
    }
}

const reformatted_questions = (subjects) => {
    let c = 0
    for (let subject of subjects) {
        if (subject?.sections && subject?.sections?.length > 0) {
            for (let section of subject?.sections) {
                for (let question of section.questions) {
                    c += 1
                    question.index = c
                }
            }
        } else {
            for (let question of subject.questions) {
                c += 1
                question.index = c
            }
        }
    }
    return [c, subjects]
}
//Fetching questions array for Question grid
export const fetchSubmittedTestQuestionsForQueGrid = (callback, type) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
        const { submittedTestsId, submittedTestsStudentsId } = getState().onlineTestReducer
        return Api.get(`/test/student/submit-test?testId=${submittedTestsId}&studentId=${submittedTestsStudentsId}&question=0`).send((response, error) => {
            //dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
            if (response !== undefined) {
                let [questionCount, subjects] = reformatted_questions(response.subjects);
                if (type === 'fromResult') {
                    dispatch(updateEvaluationQuestionId(response.subjects?.length && response.subjects[0].questions?.length ? response.subjects[0].questions[0] : 0))
                }
                dispatch(updateSubmittedTestsByStudentId({ ...response, questionCount: questionCount }))
                dispatch(updateTotalMarksObtained(parseFloat(response.totalMarksObatined != null ? response.totalMarksObatined : '0')));
                dispatch(updateQuestionGridsArray(subjects));
                callback(false)
            }
        })
    }
}

//Save entire evaluated test
export const saveEntireEvaluatedTest = (callback) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
        const { submittedTestsStudentsId,
            submittedTestsId,
            submittedTestsByStudentId,
            totalMarksObtained
        } = getState().onlineTestReducer


        const params = {
            testId: submittedTestsId,
            totalMarks: totalMarksObtained,
            studentId: submittedTestsStudentsId,
            // totalMarksObatined: 50
        }



        return Api.post(`/test/review-test`).params(params).send(response => {
            dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
            if (response != undefined) {
                callback(false)
            }
        });
    }
}

//Save evaluation of Admin/Teacher
export const saveEvaluation = (callback, answerstatus) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
        const { evaluationQuestionId,
            evaluatedMark,
            evaluatedComment,
            submittedTestsStudentsId,
            submittedTestsId,
        } = getState().onlineTestReducer


        const params = {
            testQuestionId: evaluationQuestionId.id,
            score: (evaluatedMark == null || evaluatedMark == "None") ? 0 : evaluatedMark,
            comment: evaluatedComment,
            status: answerstatus,
            studentId: submittedTestsStudentsId,
            testId: submittedTestsId,
        }

        return Api.post(`/test/review-test`).params(params).send(response => {
            // console.log('response postQuestionCreate', response);
            if (response != undefined) {
                callback(false)
            }
        });
    }
}

const getObj = (index, subjects) => {
    console.log('subjectGetObj', subjects)
    for (let subject of subjects) {
        if (subject?.sections && subject?.sections?.length > 0) {
            for (let section of subject?.sections) {
                for (let question of section?.questions) {
                    if (index === question.index) {
                        return {
                            "id": question.id,
                            "answerStatus": question.answerStatus,
                            "submitStatus": question.submitStatus
                        }
                    }
                }
            }
        } else {
            for (let question of subject.questions) {
                if (index === question.index) {
                    return {
                        "id": question.id,
                        "answerStatus": question.answerStatus,
                        "submitStatus": question.submitStatus
                    }
                }
            }
        }
    }
}

export const increaseQuestionId = (callback) => {
    return (dispatch, getState) => {
        const { evaluationQuestionId, evaluationPageQuestionGrid, currentQuestionIndex } = getState().onlineTestReducer;
        const obj = getObj(currentQuestionIndex + 1, evaluationPageQuestionGrid)

        dispatch(updateEvaluationQuestionId(obj))
        // dispatch(updatePostEvaluationObject())
        callback(true)
    }
}

//Fetching question by it's id for evaluation page
export const fetchSubmittedTestQuestionUsingQuestionId = (callback) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
        const { submittedTestsId, submittedTestsStudentsId, evaluationQuestionId, currentQuestionIndex } = getState().onlineTestReducer
        // console.log("See currentQuestionIndex", currentQuestionIndex)
        return Api.get(`/testv2/get-student-answer-by-id?testId=${submittedTestsId}&studentId=${submittedTestsStudentsId}&index=${currentQuestionIndex + 1}`).send((response, error) => {
            dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
            if (response !== undefined) {
                dispatch(updateEvaluationQuestionId(response));
                dispatch(updateEvaluationQuestionData(response))
                dispatch(updateEvaluatedMark(parseFloat(response.marksObtained != null ? response.marksObtained : 0)))
                dispatch(updateEvaluatedComment(response.comment == null ? '' : response.comment))
                callback(false)
            }
        })
    }
}


//Fetching questions array for list view
export const fetchSubmittedTestByStudentId = (callback) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
        const { submittedTestsId, submittedTestsStudentsId, } = getState().onlineTestReducer
        return Api.get(`/test/student/submit-test?testId=${submittedTestsId}&studentId=${submittedTestsStudentsId}&question=${1}`).send((response, error) => {
            dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
            if (response !== undefined) {
                console.log('listViewRes', response)
                let [questionCount, subjects] = reformatted_questions(response.subjects);
                dispatch(updateSubmittedTestsByStudentId({ ...response, subjects: subjects, questionCount: questionCount }));
                callback(false)
            }
        })
    }
}

export const updateTodayResults = (res) => ({
    type: UPDATE_TODAY_RESULTS,
    res
})

export const fetchTodayTest = (type, callback, searchText) => {
    return (dispatch, getState) => {
        const { filter } = getState().onlineTestReducer;
        const params = {
            name: filter.TAsearch,
            syllabus: filter.TAsyllabus,
            standardIds: filter.TAclasses,
            page: filter.TApage
        }
        dispatch(setLoader('taPageLoader', true));
        dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', true));
        return Api.get(`/testv2/test-filter-today?filter=${type}`).params(params).send((response) => {
            if (response !== undefined) {
                // console.log("fetched today test", type, response.tests);
                if (type == 'Cancelled') {
                    dispatch(updateCancelledTest(response.tests))
                }
                else if (type == 'Ongoing') {
                    dispatch(updateOngoingTest(response.tests))
                }
                else if (type == 'Upcoming') {
                    dispatch(updateUpcomingTests(response.tests))
                }
                else if (type == "Submitted") {
                    dispatch(updateSubmittedTests(response.tests))
                } else if (type == "Results") {
                    dispatch(updateTodayResults(response.tests))
                }
                // console.log("exiting from something");
                callback(false);
                dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', false));
                dispatch(setLoader('taPageLoader', false));
            }
            callback(false);
        })
    }
}


const updateUpcomingTests = data => ({
    type: UPDATE_UPCOMING_TESTS,
    data
})
// const updateSubmittedTests = data=>({
//   type: UPDATE_SUBMITTED_TESTS,
//   data
// })

const getTestNames = (data) => ({
    type: GET_TEST_NAME_ARR,
    data,
});

const updateStudentResults = (data) => ({
    type: UPDATE_STUDENT_RESULT,
    data
})

export const fetchStudentResults = (testId = 1, callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('studentSearchLoader', true));
        const { filter } = getState().onlineTestReducer;
        return Api.get(`/testv2/test-submit-details?testId=${testId}&search=${filter?.studentSearch}`).send((response) => {
            if (response !== undefined) {
                dispatch(updateStudentResults(response))
                callback(false);
                dispatch(setLoader('studentSearchLoader', false));
            }
        })
    }
}

const storeMarkingSchemeData = (res) => ({
    type: STORE_MARKING_SCHEME_DATA,
    res

})

export const fetchMarkingSchemeData = () => {
    return (dispatch, getState) => {
        // dispatch(setLoader('studentSearchLoader', true));
        const { testCreateObj } = getState().onlineTestReducer;
        const params = {
            orgurl: Api?._headers?.orgurl,
            testType: testCreateObj?.testType
        }
        return Api.get(`/marking-scheme/autocomplete`).params(params).send((response) => {
            if (response !== undefined) {
                dispatch(storeMarkingSchemeData(response))
                // callback(false);
                // dispatch(setLoader('studentSearchLoader', false));
            }
        })
    }
}
const updateOneStudentResult = (data) => ({
    type: UPDATE_ONE_STUDENT_RESULT,
    data
})
export const fetchOneStudentResult = (testId = null, studentId = null, callback) => {
    return (dispatch) => {
        return Api.get(`/testv2/get-each-student-result?testId=${testId}&studentId=${studentId}`).send((response) => {
            if (response !== undefined) {
                // console.log("fetched one student result", response)
                dispatch(updateOneStudentResult(response))
                callback(false);
            }
        })
    }
}


export const updateSelectedTest = (data) => ({
    type: UPDATE_SELECTED_TEST,
    data
})

export const updateSelectedTestId = (data) => ({
    type: UPDATE_SELECTED_TEST_ID,
    data
})

export const updateSelectedStudent = (data) => ({
    type: UPDATE_SELECTED_STUDENT,
    data
})

export const updateCancelledTest = (data) => ({
    type: UPDATE_CANCELLED_TESTS,
    data
})



export const actOnSelectedTest = (testid, type, callback) => {
    return () => {
        let status = "";
        if (type == "Delete") {
            status = "Deleted"
        }
        else if (type == "Publish") {
            status = "Published"
        } else if (type == "Cancel") {
            status = "Cancelled"
        }
        else if ((type == "Unpublish") || type == "Draft") {
            status = "Draft"
        }
        const params = {
            status: status
        }
        // console.log("the test id is", testid, "the status is", status)
        return Api.post(`/test/editstatus/${testid}`).params(params).send((response, error) => {
            if (response !== undefined && response?.show?.type !== "error") {
                if (callback) {
                    callback()
                }
            }
        })
    }
}
export const getIndexOfTest = () => {
    return (dispatch, getState) => {
        dispatch(setLoader('indexLoader', true));
        dispatch(universalLoader('GET_INDEX_OF_TEST', 'getIndexOfTestBool', true));
        const { testCreateObj } = getState().onlineTestReducer;
        const standardId = testCreateObj.standardId;
        const subIds = testCreateObj.subjectId;
        let syllabus = testCreateObj.syllabus === 'Chapter' ? 'Chapter Test' : testCreateObj.syllabus === 'Part' ? 'Part Test' : testCreateObj.syllabus === 'Full' ? 'Full Test' : '';
        return Api.get(`/testv2/get-index?subjectIds=[${subIds}]&standardId=${standardId}&syllabus=${syllabus}`).send(response => {
            dispatch(universalLoader('GET_INDEX_OF_TEST', 'getIndexOfTestBool', false));
            // console.log('response getExaminer+++', response);
            if (response != undefined) {
                dispatch(storeCreateTestModalData('tIndex', response.index));
                dispatch(setLoader('indexLoader', false));
            }
        });
    }
}

export const showTextEditor = (name, index, val) => ({
    type: SHOW_TEXT_EDITOR,
    name,
    index,
    val
});
export const updateSubWithChapters = (name) => ({
    type: UPDATE_SUB_WITH_CHAPTERS,
    name,
});
export const addMarksForQues = (operation, name, index) => ({
    type: ADD_MARKS_FOR_QUES,
    operation,
    name,
    index
});
export const renderEditorContent = (name, value) => ({
    type: RENDER_EDITOR_CONTENT,
    name,
    value,
});
export const chooseCorrectAnswer = (val, index, identifier) => ({
    type: CHOOSE_CORRECT_ANSWER_ADMIN,
    val,
    index,
    identifier
});

export const chooseBoolAnswer = (val, bool, index) => ({
    type: CHOOSE_BOOL_ANSWER,
    val,
    bool,
    index
});

export const storeTestQuesType = (obj, id, name) => ({
    type: STORE_TEST_QUES_TYPE,
    obj,
    id,
    name,
});
export const updateListOfQuestions = (res) => ({
    type: UPDATE_LIST_OF_QUESTIONS,
    res,
});
export const storeEditorRemovedImgs = (arr) => ({
    type: STORE_EDITOR_REMOVED_IMGS,
    arr,
});
export const storeEditoraddedImgs = (arr) => ({
    type: STORE_EDITOR_ADDED_IMGS,
    arr,
});

export const clearGenerateQuesFields = (val) => ({
    type: CLEAR_GENERATE_QUES_FIELDS,
    val
});

const reformat_response = (response) => {
    for (let i = 0; i < response.subjects?.length; i++) {
        response.subjects[i].comments = [];
        for (let j = 0; j < response.subjects[i].mix?.length; j++) {
            response.subjects[i].comments.push(response.subjects[i].mix[j].remark)
        }
        // response.subjects[i].comments.reverse();
    }
    return response;
}
export const postQuestionCreate = (params, callback) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('POST_QUESTION_CREATE', 'postQuestionCreateBool', true));
        const { questionContent, answerContent, generateQuesObj, generateQuesSelectedTestType, generateSolutionText, quesPositiveMark, quesNegativeMark, generateQuesSelectedChapter, selectedSectionId } = getState().onlineTestReducer;
        // const params = {
        //   questionContent: questionContent,
        //   answerContent: answerContent,
        //   type:generateQuesSelectedTestType.children,
        //   positiveMarks:quesPositiveMark,
        //   negativeMarks:quesNegativeMark,
        //   testId:generateQuesObj.id,
        //   chapterId:generateQuesSelectedChapter.key,
        //   solution:generateSolutionText,
        //   sectionId:selectedSectionId,
        // }
        return Api.post(`/test/test-question`).params(params).send(response => {
            dispatch(universalLoader('POST_QUESTION_CREATE', 'postQuestionCreateBool', false));
            // console.log('response postQuestionCreate', response);
            if (response != undefined) {
                let reformated_response = reformat_response({ ...response });
                dispatch(clearGenerateQuesFields('Post'));
                dispatch(updateListOfQuestions(reformat_response(injectQuestionIndex(reformated_response))));
                dispatch(updateNumericAnswer(''));
            }
        });
    }
}

export const deleteQuillImgs = (callback) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('DELETE_QUILL_IMGS', 'deleteQuillImgsBool', true));
        const { generateQuesRemovedImgs } = getState().onlineTestReducer;

        return Api.delete(`/test/delete-image`).params({ deletedFiles: generateQuesRemovedImgs }).send(response => {
            dispatch(universalLoader('DELETE_QUILL_IMGS', 'deleteQuillImgsBool', false));
            // console.log('response deleteQuillImgs', response);
            if (response != undefined) {
                callback();
            }
        });
    }
}

export const deleteQuesFromServer = (callback) => {
    return (dispatch, getState) => {
        const { editGenerateQueId } = getState().onlineTestReducer;
        return Api.delete(`/test/test-question/${editGenerateQueId}`).send(response => {
            // console.log('response deleteQuesFromServer', response);
            if (response != undefined) {
                if (callback) {
                    callback()
                }
                // console.log('DeleteRes',response)
                dispatch(updateListOfQuestions(injectQuestionIndex(response)));
                dispatch(clearGenerateQuesFields());
            }
        });
    }
}

export const resetGenerateQuesFields = (res) => ({
    type: RESET_GENERATE_QUES_FIELDS,
    res,
});
export const storeEditGenerateQueId = (id) => ({
    type: STORE_EDIT_GENERATE_QUE_ID,
    id,
});

export const getEachQuesData = (id) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GET_EACT_QUES_DATA_LOADER', 'getEachQuesDataBool', true));
        dispatch(storeEditGenerateQueId(id));
        return Api.get(`/test/get-question-by-id/${id}`).send(response => {
            dispatch(universalLoader('GET_EACT_QUES_DATA_LOADER', 'getEachQuesDataBool', false));
            // console.log('response getEachQuesData', response);
            if (response != undefined) {
                dispatch(resetGenerateQuesFields(response))
            }
        });
    }
}

export const publishResult = (testid, callback) => {
    return () => {
        return Api.patch(`/test/status/${testid}`).params({ status: 'Published' }).send((response) => {
            if (response != undefined) {
                // console.log("the response is", response);
                // actOnSelectedTest1(type, callback);
                callback(false);
            }
        })
    }
}

export const PublishTestResult = (testid, callback) => {
    return new Promise((resolve) => {
        Api.patch(`/testv2/declare-result/${testid}`).send((response) => {
            if (typeof (response) !== "undefined") {
                resolve();
                callback(response);
            }
        })
    })
}

export const storeGenerateQuesListView = (res) => ({
    type: STORE_GENERATE_QUES_LIST_VIEW,
    res,
});

export const getTestClassList = (subIds, chapterIds = []) => {
    return (dispatch, getState) => {
        dispatch(setLoader('modalClassLoader', true));
        // const params = {
        //     subjectIdList: subIds,
        //     chapterIdList: chapterIds
        // }
        return Api.get(`/testSeries/autocomplete?testSeriesId=1`).send(response => {
            // dispatch(universalLoader('POST_QUESTION_CREATE', 'postQuestionCreateBool', false));
            if (response != undefined) {
                dispatch(updateStdForCreatingTest(response));
                dispatch(setLoader('modalClassLoader', false));
            }
        });
    }
}

const groupChapters = (chapters) => {
    let groupedChapters = {};
    for (let chapter of chapters) {
        if (chapter.subjectId in groupedChapters) {
            groupedChapters[chapter.subjectId].chapters.push({ ...chapter });
        } else {
            groupedChapters[chapter.subjectId] = {
                subjectId: chapter.subjectId,
                subjectName: chapter.subjectName, chapters: [{ ...chapter }]
            }
        }
    }
    return groupedChapters
}

export const getTestChapterList = (search = null, subjectId = null, iscreate = true, stdid) => {
    return (dispatch, getState) => {
        dispatch(setLoader('modalChapterLoader', true));
        const { testCreateObj } = getState().onlineTestReducer;
        const params = {
            standardId: iscreate == undefined || iscreate === true ? testCreateObj.standardId : stdid,
            subjectIds: subjectId ? subjectId : testCreateObj.subjectId,
            name: search
        };
        if (params.standardId?.length === 0) {
            return
        }
        return Api.get(`/testv2/chapter/autocomplete`).params(params).send(response => {
            if (response != undefined) {
                dispatch(updateChapterForCreatingTest(response));
                dispatch(updateGroupedChapterForCreatingTest(response, subjectId != undefined && iscreate ? true : false, subjectId))
            }
            dispatch(setLoader('modalChapterLoader', false));
        });
    }
}

// const injectQuestionIndex = (response) => {
//   let index = 1
//   for (const subject of response?.subjects) {
//     if (subject?.includeSectionFlag && subject?.includeSectionFlag !== 0) {
//       for (let section of subject?.sections) {
//         const newquestions = [];
//         for (let question of section?.questions) {
//           newquestions.push({ id: question, index: index })
//           index += 1
//         }
//         section.questions = newquestions
//       }
//     } else {
//       const newquestions = [];
//       for (let question of subject?.questions) {
//         newquestions.push({ id: question, index: index })
//         index += 1
//       }
//       subject.questions = newquestions

//     }

//   }
//   return response
// }

const injectQuestionIndex = (response, listview) => {
    let index = 1
    if (listview === 'list') {
        for (const subject of response?.subjects) {
            if (subject?.includeSectionFlag && subject?.includeSectionFlag !== 0) {
                for (let section of subject?.sections) {
                    const newquestions = [];
                    for (let question of section?.questions) {
                        newquestions.push({ ...question, index: index })
                        index += 1
                    }
                    section.questions = newquestions
                }
            } else {
                const newquestions = [];
                for (let question of subject?.questions) {
                    newquestions.push({ ...question, index: index })
                    index += 1
                }
                subject.questions = newquestions

            }

        }

    } else {
        for (const subject of response?.subjects) {
            if (subject?.includeSectionFlag && subject?.includeSectionFlag !== 0) {
                for (let section of subject?.sections) {
                    const newquestions = [];
                    for (let question of section?.questions) {
                        newquestions.push({ id: question, index: index })
                        index += 1
                    }
                    section.questions = newquestions
                }
            } else {
                const newquestions = [];
                for (let question of subject?.questions) {
                    newquestions.push({ id: question, index: index })
                    index += 1
                }
                subject.questions = newquestions

            }

        }
    }
    return response
}

export const showGenerateQuesListView = (callback, page_source,) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('GENERATE_QUES_LIST_VIEW_LOADER', 'generateQuesListViewBool', true));
        const { generateQuesObj, selectedTest, importTestIdContainer, schemeId } = getState().onlineTestReducer;
        let id = "";
        let ques = "";
        if (page_source == "listview") {
            id = generateQuesObj.id;
            ques = '';
        } else if (page_source === "navigation") {
            id = generateQuesObj.id;
            ques = 0;
        } else if (page_source === 'import') {
            id = generateQuesObj.id;
            ques = 0;
        } else if (page_source === "importListView") {
            id = importTestIdContainer;
            ques = '';
        } else if (page_source === 'editImportListView') {
            id = importTestIdContainer;
            ques = 0;
        }
        else {
            id = selectedTest.id ? selectedTest.id : selectedTest.testId ? selectedTest.testId : generateQuesObj.id;
            ques = 0;
        }
        return Api.get(`/testv2/test-by-id-admin/${id}?questions=${ques}`).send(response => {
            dispatch(universalLoader('GENERATE_QUES_LIST_VIEW_LOADER', 'generateQuesListViewBool', false));
            if (response != undefined) {
                if (page_source === 'listview' || page_source === 'importListView') {
                    dispatch(storeGenerateQuesListView(injectQuestionIndex(response, 'list')));
                } else {
                    dispatch(storeGenerateQuesListView(response));
                }
                dispatch(setSubjectSectionAction(response?.subjects))
                console.log('response', response)
                if (page_source != "listview") {
                    dispatch(clearGenerateQuesFields())
                    if (page_source !== "importListView") {
                        dispatch(getTestChapterList(null, response.subjects?.map(item => '' + item.subjId), false, response.standardIds));
                    }
                    dispatch(storeGenerateQuestionsObj(response));
                    dispatch(updateListOfQuestions(injectQuestionIndex(response)));
                    if (response.testType === 'Subjective' && page_source !== 'tabview' && schemeId !== null && schemeId > 0) {
                        setTimeout(() => {
                            dispatch(getMarkingSchemeMarks(response.testType))
                        }, 3000)
                    }
                }
                if (page_source === 'tabviewlive') {
                    callback(response);
                }
                else {
                    callback(response.subjectIds?.split(','), response.chapters);
                }
            }
        });
    }
}

export const sendForReview = (testid, callback) => {
    return () => {
        const params = {
            "markedForReview": 1
        }
        return Api.patch(`/test/test-markforreview/${testid}`).params(params).send((response, error) => {
            if (response !== undefined) {
                // actOnSelectedTest1(type, callback);
            }
            callback(false);
        });
    }
}

export const setIsEditModalVisible = (bool) => ({
    type: SET_EDIT_MODAL_VISIBLE,
    data: bool
})

export const setIsCModalVisible = (bool) => ({
    type: SET_C_MODAL_VISIBLE,
    data: bool
})

export const setCModalType = (data) => ({
    type: SET_C_MODAL_TYPE,
    data
})

export const toggleReloaderFlag = () => ({
    type: TOGGLE_RELOADER_FLAG
})
export const createTestModal = (name, user, source) => ({
    type: CREATE_TEST_MODAL,
    name,
    user,
    source
});

export const changeTestStatusModal = (name) => ({
    type: CHANGE_TEST_STATUS_MODAL,
    name,
});

export const setSubjectSectionAction = (val) => ({
    type: SET_SUBJECT_SECTION,
    val
})
export const changeTestStatus = (history, path) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('CHANGE_TEST_STATUS_LOADER', 'changeTestStatusBool', true));
        const { generateQuesObj, modalActiveName, selectedTestId, importTestIdContainer } = getState().onlineTestReducer;
        return Api.patch(`/test/status/${(modalActiveName == "MarkReady" || modalActiveName == "SendReview") ? selectedTestId : (modalActiveName == "Published" && path == '2') ? importTestIdContainer : (modalActiveName == "Review" && path == '2') ? generateQuesObj?.test?.id : generateQuesObj.id}`).params({ status: modalActiveName == "MarkReady" ? "Ready" : modalActiveName == "SendReview" ? "Review" : modalActiveName }).send(response => {
            dispatch(universalLoader('CHANGE_TEST_STATUS_LOADER', 'changeTestStatusBool', false));
            if (response != undefined && response.show.type !== 'error') {
                if ((modalActiveName == "MarkReady") || (modalActiveName == "SendReview")) {
                    dispatch(fetchDashBoardData(() => { }));
                    dispatch(fetchTestsByStage("draft", () => { }))
                } else if (path == "fromClassroom") {
                    history.push({ pathname: '/test-std-to-subjects-screen', state: { key: "fromClassroom" } });
                }
                else {
                    history.push('test');
                }
                dispatch(changeTestStatusModal(''));
                dispatch(clearGenerateQuesFields(''));
            }
        });
    }
}

export const unsetCreateTestModalBool = () => ({
    type: UNSET_CREATE_TEST_MODAL_BOOL
})

export const updateListViewType = (data) => ({
    type: UPDATE_LISTVIEW_TYPE,
    data
})

export const updateFeedbackData = (res) => ({
    type: UPDATE_FEEDBACK_DATA,
    res
})

export const updateReadyToPublish = (data) => ({
    type: UPDATE_READY_TO_PUBLISH,
    data
})

export const updateRole = () => {
    return (dispatch, getState) => {
        const { user } = getState().session;
        dispatch(updateRoleHelper(user));
    }
}

export const updateRoleHelper = (user) => ({
    type: UPDATE_ROLE,
    user
})

export const updateFeedbackFromTeacher = (data) => ({
    type: UPDATE_FEEDBACK_FROM_TEACHER,
    data
})

export const updateOperatorStats = (data) => ({
    type: UPDATE_DATA_OPERATOR_STATS,
    data
})

export const updateTestsToReview = (data) => ({
    type: UPDATE_TESTS_TO_REVIEW,
    data
})

export const updateResultsData = (data) => ({
    type: UPDATE_RESULTS_DATA,
    data
})

export const updateSelectedCardType = (data) => ({
    type: UPDATE_SELECTED_CARD_TYPE,
    data
})

export const updateSubmittedTestsAbsentStudents = (data) => ({
    type: UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS,
    data
})

export const updateTotalMarksObtained = (marks) => ({
    type: UPDATE_TOTAL_MARKS_OBTAINED,
    marks
})

export const updatePalette = (qIndex, newType) => ({
    type: UPDATE_PALETTE_DATA,
    qIndex,
    newType
})

export const updateNumericAnswer = (newAnswer, index) => ({
    type: UPDATE_NUMERIC_ANSWER,
    newAnswer,
    index
})

export const updatePostParams = (newParams) => ({
    type: UPDATE_POST_PARAMS2,
    newParams
})

export const setOpenTab = (tabName) => ({
    type: SET_OPEN_TAB,
    tabName
})

export const updateStdForCreatingTest = (classes) => ({
    type: UPDATE_STD_FOR_CREATING_TEST,
    classes
})

export const updateChapterForCreatingTest = (chapters) => ({
    type: UPDATE_CHAPTER_FOR_CREATING_TEST,
    chapters
})

export const updateGroupedChapterForCreatingTest = (chapterList, isSearch, subjectId) => ({
    type: UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST,
    chapterList,
    isSearch,
    subjectId
})

export const postComment = (params, callback) => {
    return (dispatch, getState) => {
        return Api.post(`/test/question/comment`).params(params).send(response => {
            // dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
            if (response != undefined) {
                console.log("prime res", response);
                let reformated_response = reformat_response({ ...response });
                dispatch(updateListOfQuestions(reformat_response(injectQuestionIndex(reformated_response))));
                callback(response);
            }
        });
    }
}

export const setDashboardMeta = (val) => ({
    type: SET_DASHBOARD_META,
    val
})

export const removeSelectedChapter = (id) => ({
    type: REMOVE_SELECTED_CHAPTER,
    id
});

export const updateSyllabus = (res) => ({
    type: UPDATE_SYLLABUS,
    res
})

export const updateFilter = (key, val) => ({
    type: UPDATE_FILTER,
    key,
    val
})

const handleSubjects = (response, syllabus) => {
    let temp = [];
    for (const sub of response.subjects) {
        temp.push({ ...sub, isCombined: false });
    }
    if (syllabus !== 'Chapter Test') {
        temp.push({
            subjectName: "Combined Test(s)",
            objectiveCount: response.combinedObjective,
            subjectiveCount: response.combinedSubjective,
            icon: response.combinedIcon,
            isCombined: true
        })
    }
    return temp;
}

export const fetchSyllabusSubjects = () => {
    return (dispatch, getState) => {
        dispatch(setLoader('subjectPageLoader', true));
        const { selectedClassroom, filter } = getState().onlineTestReducer;
        const { isFromClassRoomDetails, selectedSection } = getState().classRoom
        const params = {
            standardId: isFromClassRoomDetails ? selectedSection?.id : selectedClassroom?.id,
            syllabus: filter.selectedSyllabus,
            search: filter.subjectSearch
        }
        return Api.get(`/testv2/test-standard-syllabus`).params(params).send(response => {
            if (response != undefined) {
                const subjects = handleSubjects(response, filter.selectedSyllabus);
                dispatch(updateSubjectsByClass(subjects));
                dispatch(setLoader('subjectPageLoader', false));
                console.log("response", response);
            }
        });
    }
}

export const fetchSubjectChapters = () => {
    return (dispatch, getState) => {
        dispatch(setLoader('chapterPageLoader', true));
        const { selectedClassroom, filter, selectedSubject } = getState().onlineTestReducer;
        const params = {
            standardId: selectedClassroom.id,
            subjectId: selectedSubject.subjectId,
            search: filter.chapterSearch
        }
        return Api.get(`/testv2/chapter-test-chapters`).params(params).send(response => {
            if (response != undefined) {
                // const subjects = handleSubjects(response, filter.selectedSyllabus);
                // dispatch(updateSubjectsByClass(subjects));
                dispatch(setChaptersList(response));
                dispatch(setLoader('chapterPageLoader', false));
                console.log("response", response);
            }
        });
    }
}

export const setLoader = (key, val) => ({
    type: SET_LOADER,
    key,
    val
})

export const setImportMeta = (key, value) => ({
    type: SET_IMPORT_META,
    key,
    value
})


export const fetchClassByCurrData = (callback = null) => {
    return (dispatch, getState) => {
        // dispatch(setLoader('importClassLoader', true));
        return Api.get('/question-bankv2').send((response, error) => {
            if (callback) {
                callback()
            }
            if (response != undefined) {
                console.log("response class data", response);
                dispatch(setImportMeta('setImportClassroom', response));
                // dispatch(setLoader('importClassLoader', false));
                // callback(false);
            }
        })
    };
}


export const fetchSampleQuestionPaper = (callback = null) => {
    return (dispatch, getState) => {
        // dispatch(setLoader('sampleGroupLoader', true));
        const { importMeta } = getState().onlineTestReducer;
        const params = {
            curriculumId: importMeta.selectedStandardObj?.curriculumId,
            standardId: importMeta.selectedStandardObj?.id,
            questionPaperType: 'sample question paper',
            questionPaperName: ''
        }
        return Api.get(`/question-bankv2/question-paper`).params(params).send((response, error) => {
            if (callback) {
                callback()
            }
            if (response != undefined) {
                console.log("response sample question papers", response)
                dispatch(setImportMeta('setImportSampleQuestionPapers', response));
                // dispatch(setLoader('sampleGroupLoader', false));
            }
        })
    }
}

export const fetchSampleQuestionPaperQuestions = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('sampleQuesLoader', true));
        const { importMeta } = getState().onlineTestReducer;
        // const params = {
        // standardId: importMeta.selectedClassData.standardId,
        // questionPaperType: 'sample question paper',
        // questionPaperName: ''
        // }


        const params = {
            questionPaperId: importMeta.sampleQuestionPaperObj.questionPaperId,
            testImport: true
        }
        return Api.get(`/question-bankv2/questions`).params(params).send((response, error) => {
            if (response != undefined) {
                console.log("response sample question papers", response)
                // dispatch(updateTopicCardData(response))
                dispatch(setImportMeta('setSampleQuestionTree', response));
                callback();
                dispatch(setLoader('sampleQuesLoader', false));
            }
        })
    }
}

const reformat_chapters = (chapters) => {
    let reformatted_chapters = [];
    for (let subject_index of chapters) {
        if (subject_index?.chapters?.length !== 0) {
            for (let chapter of subject_index.chapters) {
                reformatted_chapters.push({ ...chapter, subjectId: subject_index.id, subjectName: subject_index.name });
            }
        }
    }
    return reformatted_chapters;
}

export const getTestSampleQuestionPaperData = (callback = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { importMeta } = getState().onlineTestReducer;
        const params = {
            questionPaperId: importMeta?.selectedPaperObj?.id
        }
        return Api.get(`/question-bankv2/import/test/sample-question-paper`).params(params).send((response, error) => {
            if (callback) {
                callback()
            }
            if (response != undefined && response?.show?.type !== 'error') {
                dispatch(clearGenerateQuesFields())
                dispatch(storeChapters(reformat_chapters(response.chapters)))
                // dispatch(getTestChapterList(null, response.subjects?.map(item=>''+item.subjId), false, response.standardIds));
                dispatch(storeGenerateQuestionsObj(response));
                // dispatch(updateListOfQuestions(injectQuestionIndex(response))); 
                dispatch(setSubjectSectionAction(response?.subjects))
                if (successCallBack) {
                    successCallBack()
                }
            }
        })
    }
}

export const storeChapters = (val) => ({
    type: STORE_CHAPTERS,
    val
})

export const storeOnlineTestSeriesData = (val) => ({
    type: ONLINE_TEST_SERIES_COMBINEDATA,
    val
})

export const postImportSampleQuestionPaper = (callback, success) => {
    return (dispatch, getState) => {
        dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', true));
        const { testCreateObj, createTestModalName, generateQuesObj, listViewType, storeSubjectSection, importMeta } = getState().onlineTestReducer;
        let isClassScreen = window.location.pathname == '/test-std-to-subjects-screen' ? true : false;
        const params = {
            // chapterIds: [1274, 1272],
            // chapterNames: ["Fractions", "Understanding Elementary Shapes"],
            // cloneTestId: null,
            // id: 21,
            // status: "Draft",
            // sub: undefined,
            // subjectId: -1,
            questionPaperId: importMeta?.selectedPaperObj?.id,
            index: testCreateObj.tIndex,
            instructions: testCreateObj.instruction,
            selectedTeacher: testCreateObj.teacherId,
            standardIds: typeof testCreateObj.standardId === "object" ? testCreateObj.standardId : [testCreateObj.standardId],
            testDate: moment(testCreateObj.heldDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            startTime: testCreateObj.heldStartTime,
            endTime: testCreateObj.heldEndTime,
            // subjectIds: testCreateObj.subjectId,
            // subjectNames: testCreateObj.subjectName,
            teacherId: testCreateObj.teacherId,
            teacherName: testCreateObj.teacherName,
            title: testCreateObj.title,
            testType: testCreateObj.testType,
            syllabus: testCreateObj.syllabus === 'Chapter' ? 'Chapter Test' : testCreateObj.syllabus === 'Full' ? 'Full Test' : testCreateObj.syllabus === 'Part' ? 'Part Test' : '',
            chosenChapterIds: testCreateObj.chapterList?.map((item) => item.id),
            subjectsSection: storeSubjectSection

        }
        if (testCreateObj.testType === 'Objective') {
            params.resultDate = moment(testCreateObj.resultDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            params.resultTime = testCreateObj.resultTime;
        }
        if (createTestModalName === 'test-edit') {
            params.id = generateQuesObj.id
        }
        else if (createTestModalName == 'test-clone') {
            params.cloneTestId = generateQuesObj.id
        }
        return Api.post(`/question-bankv2/import/test/sample-question-paper`).params(params).send(response => {
            callback();
            dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', false));
            if (response != undefined && response?.show?.type !== 'error') {
                dispatch(storeImportTestId(response?.testId))
                if (success) {
                    success();
                }
                // if(createTestModalName === 'test-create'){
                //   dispatch(clearGenerateQuesFields());
                // }
                // dispatch(storeGenerateQuestionsObj(response));
                // dispatch(updateListOfQuestions(injectQuestionIndex(response)));

            }
        });
    }
}

export const storeImportTestId = (val) => ({
    type: STORE_IMPORT_TEST_ID,
    val
})

export const storeListViewData = (val) => ({
    type: STORE_LIST_VIEW_DATA,
    val
})

export const storePageNumber = (val) => ({
    type: STORE_PAGE_NUMBER,
    val
})

export const storeSubjectId = (val) => ({
    type: STORE_SUBJECT_ID,
    val
})

export const storeQuestionPaletteData = (val) => ({
    type: STORE_QUESTION_PALETTE_DATA,
    val
})

export const fetchQuestionPaletteData = (callback, toFirstSubject = true) => {
    return (dispatch, getState) => {
        const { importMeta } = getState().onlineTestReducer;
        const params = {
        }
        // if (qbFullData.qbType === 'sample') {
        //   params.questionPaperId = qbFullData.selectedQuestionPaper?.questionPaperId
        // } else {
        //   params.topicId = qbFullData.selectedTopic?.topicId
        // }
        params.questionPaperId = importMeta?.selectedPaperObj?.id
        return Api.get(`/question-bankv2/question-navigation`).params(params).send((response, error) => {
            if (callback) {
                callback()
            }
            if (response != undefined) {
                dispatch(storeQuestionPaletteData(response));
                dispatch(storeSubjectId(response[0]?.id))
                dispatch(getListViewData(() => { }))
            }
        })
    }
}

export const getListViewData = (callBack = null) => {
    return (dispatch, getState) => {
        const { importMeta, questionPaletteDataConatiner, pageNumber, subjectIdContainer, listViewDataContainer } = getState().onlineTestReducer;
        let data = questionPaletteDataConatiner?.map((item) => {
            return {
                name: item?.name,
                id: item.id
            }
        })
        dispatch(storeSubjectName(data))
        const params = {
        }
        // if (qbFullData.qbType === 'sample') {
        //   params.questionPaperId = qbFullData.selectedQuestionPaper?.questionPaperId
        // } else {
        //   params.topicId = qbFullData.selectedTopic?.topicId
        // }
        params.questionPaperId = importMeta?.selectedPaperObj?.id
        params.limit = 10
        params.page = pageNumber
        params.subjectId = subjectIdContainer
        return Api.get(`/question-bankv2/questionsListView`).params(params).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (response != undefined) {
                if (listViewDataContainer?.items?.length > 0) {
                    let temp = [...response?.items]
                    let temp1 = [...listViewDataContainer?.items]
                    let temp3 = [...temp1, ...temp]
                    let arr = {
                        items: temp3,
                        meta: response?.meta
                    }
                    dispatch(storeListViewData(arr))
                } else {
                    dispatch(storeListViewData(response))
                }
            }
        })
    }
}

export const storeSubjectName = (val) => ({
    type: STORE_SUBJECT_NAME,
    val
})

export const importSampleQuestionPaper = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('importingSampleQuesLoader', true));
        const { importMeta, generateQuesObj } = getState().onlineTestReducer;
        const params = {
            questionIds: importMeta.sampleIndividualBool ? importMeta.selectedSampleQuestions : [],
            questionPaperIds: importMeta.sampleGroupBool ? importMeta.selectedSampleQuestionPapers : [],
            testId: generateQuesObj?.id
        }
        return Api.post(`/question-bankv2/import/test/question-paper`).params(params).send((response, error) => {
            if (response != undefined) {
                callback(response);
                dispatch(setLoader('importingSampleQuesLoader', false));
            }
        })
    }
}

export const fetchSubjectCardData = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('importSubjectList', true));
        const { importMeta } = getState().onlineTestReducer;
        const params = {
            curriculumId: importMeta.selectedStandardObj?.curriculumId,
            standardId: importMeta.selectedStandardObj?.id,
            questionPaperType: 'subject-wise question paper'
        }
        return Api.get(`/question-bankv2/question-paper`).params(params).send((response, error) => {
            if (response != undefined) {
                console.log("response subjects", response);
                dispatch(setImportMeta('subjectCardData', response));
                callback(false);
                dispatch(setLoader('importSubjectList', false));
            }
        })
    }
}


export const fetchChapterCardData = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('importChapter', true));
        const { importMeta } = getState().onlineTestReducer;
        const params = {
            curriculumId: importMeta.selectedStandardObj?.curriculumId,
            standardId: importMeta.selectedStandardObj?.id,
            subjectId: importMeta.selectedSubjectObj.subId
        }
        return Api.get(`/question-bankv2/chapters`).params(params).send((response, error) => {
            if (response != undefined) {
                console.log("response chapters", response)
                dispatch(setImportMeta('chapterCardData', response));
                dispatch(setLoader('importChapter', false));
                // callback(false);
            }
        })
    }
}


export const fetchTopicList = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('importTopics', true));
        const { importMeta } = getState().onlineTestReducer;
        const params = {
            curriculumId: importMeta?.selectedStandardObj?.curriculumId,
            standardId: importMeta?.selectedStandardObj?.id,
            subjectId: importMeta?.selectedSubjectObj?.subId,
            chapterId: importMeta?.selectedChapterObj?.chapterId
        }
        return Api.get(`/question-bankv2/topics`).params(params).send((response, error) => {
            if (response != undefined) {
                console.log("response chapters", response);
                dispatch(setImportMeta('topicsList', response));
                dispatch(setImportMeta('setSelectionTree', response))
                callback(response);
                dispatch(setLoader('importTopics', false));
                dispatch(setLoader('importSubjectWiseQuestions', false));
            }
        })
    }
}


export const fetchSubjectWiseQuestions = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('importSubjectWiseQuestions', true));
        const { importMeta, generateQuesObj } = getState().onlineTestReducer;
        const params = {
            topicId: importMeta.selectedTopic?.topicId,
            testImport: true
        }
        return Api.get(`/question-bankv2/questions`).params(params).send((response, error) => {
            if (response != undefined) {
                console.log("response subjective question papers", response);
                dispatch(setImportMeta('topicwiseQuestionsList', response));
                dispatch(setLoader('importSubjectWiseQuestions', false));
            }
        })
    }
}

export const setTextFalse = (res) => ({
    type: SET_TEXT_FALSE,
    res
})

export const importSubjectWiseQuestionPaper = (callback) => {
    return (dispatch, getState) => {
        dispatch(setLoader('importingSubQuestions', true));
        const { importMeta, generateQuesObj, selectedSectionId } = getState().onlineTestReducer;
        let questions = [];
        for (let topic of importMeta.selectionTree) {
            for (let questionId of topic.selectedQuestions) {
                questions.push({
                    questionId: questionId,
                    positiveMarks: importMeta.marksList[questionId]?.positiveMark,
                    negativeMarks: importMeta.marksList[questionId]?.negativeMark,
                    subQuestionMarks: importMeta.marksList[questionId]?.subQuestionMarks
                })
            }
        }
        const params = {
            questions: questions,
            testId: generateQuesObj.id,
            sectionId: selectedSectionId
        }
        return Api.post(`/question-bankv2/import/test/subjectwise`).params(params).send((response, error) => {
            if (response != undefined) {
                console.log("response sample question papers", response);

                // dispatch(updateTopicCardData(response))
                // dispatch(setImportMeta('setSampleQuestionTree', response));
                // dispatch(setLoader('topicPageLoader', false));
                callback(response);
            }
        })
    }
}


export const getMarkingSchemeData = (search, callBack = null) => {
    console.log(Api._headers, "abcd")
    return (dispatch, getState) => {
        return Api.get("/marking-scheme/allScheme")
            .params({
                orgurl: Api._headers?.orgurl,
                search: search ? search : ''
            })
            .send((response) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    dispatch(storeMarkingSchemeCardData(response));
                    console.log("response", response);
                }
            })
    }
}

export const storeMarkingSchemeCardData = (val) => ({
    type: STORE_MARKING_SCHEME_CARD_DATA,
    val
})

export const searchFilter = (val) => ({
    type: SEARCH_FILTER,
    val
})

export const storeMarkingSchemeTitle = (val) => ({
    type: STORE_MARKING_SCHEME_TITLE,
    val
})

export const storeMarkingSchemeDesc = (val) => ({
    type: STORE_MARKING_SCHEME_DESC,
    val
})

export const storeMarkingSchemeSubjective = (val) => ({
    type: STORE_MARKING_SCHEME_SUBJECTIVE,
    val
})

export const storeMarkingSchemeObjective = (val) => ({
    type: STORE_MARKING_SCHEME_OBJECTIVE,
    val
})

export const postCreateMarkingScheme = (callBack = null, successCallBack) => {
    return (dispatch, getState) => {
        const { markingSchemeTitle, markingSchemeDescription, subjectiveTypeContainer, objectiveTypeContainer, markedSchemeCardIdContainer } = getState().onlineTestReducer;
        const updatedSubjectiveTypeContainer = subjectiveTypeContainer.map((item) => item.positiveMarks ? item : null).filter(Boolean);
        return Api.post("/marking-scheme/addScheme")
            .params({
                title: markingSchemeTitle,
                description: markingSchemeDescription,
                orgurl: Api._headers?.orgurl,
                subjectiveQuestion: updatedSubjectiveTypeContainer,
                objectiveQuestion: objectiveTypeContainer,
                schemeId: markedSchemeCardIdContainer
            })
            .send((response) => {
                console.log("jj", response);
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    if (successCallBack) {
                        successCallBack(response)
                    }
                    dispatch(fetchMarkingSchemeDataTS());

                }
            })
    }
}

export const fromCreateNewButton = (bool) => ({
    type: FROM_CREATE_NEW_BUTTON,
    bool
});

export const setFromSectionMarkingScheme = (val) => ({
    type: SET_FROM_SECTION_MARKING_SCHEME,
    val
})

export const getMarkingSchemeCardDetails = (callBack = null) => {
    return (dispatch, getState) => {
        const { markedSchemeCardIdContainer } = getState().onlineTestReducer
        return Api.get("/marking-scheme/fetchDetails")
            .params({ schemeId: markedSchemeCardIdContainer })
            .send((response) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    dispatch(storeMarkingSchemeTitle(response?.title))
                    dispatch(storeMarkingSchemeDesc(response?.description))
                    dispatch(storeMarkingSchemeObjective(response?.objectiveQuestion))
                    dispatch(storeMarkingSchemeSubjective(response?.subjectiveQuestion))
                    dispatch(markedSchemeCardDetails(response))
                }
            })
    }
}

export const deleteMarkingScheme = (callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { markedSchemeCardIdContainer } = getState().onlineTestReducer;
        return Api.delete("/marking-scheme/deleteScheme")
            .params({ schemeId: markedSchemeCardIdContainer })
            .send((response) => {
                if (callBack) {
                    callBack();
                }
                if (response != undefined) {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            })
    }
}

export const markedSchemeCardDetails = (val) => ({
    type: MARKED_SCHEME_CARD_DETAILS,
    val
})

export const storeMarkedSchemeCardId = (val) => ({
    type: STORE_MARKED_SCHEME_CARD_ID,
    val
})

export const setSelectedSectionId = (val) => ({
    type: SET_SELECTED_SECTION_ID,
    val
})

export const addNewQuestion = (value, name, index) => ({
    type: ADD_NEW_QUESTIONS,
    value,
    name,
    index
});

export const updateQuestionType = (value, options) => ({
    type: UPDATE_QUESTION_TYPE,
    value,
    options
});

export const storeQuestionIndex = (value) => ({
    type: STORE_QUESTION_INDEX,
    value
})

export const storeSectionIndex = (value) => ({
    type: STORE_SECTION_INDEX,
    value
})

export const createAdditionalQuestion = (identifier, value) => ({
    type: CREATE_ADDITIONAL_QUESTION,
    identifier,
    value
})

export const storeSchemeId = (value) => ({
    type: STORE_SCHEME_ID,
    value
})

export const getMarkingSchemeMarks = (type, callBack = null) => {
    return (dispatch, getState) => {
        const { schemeId } = getState().onlineTestReducer
        if ((type !== null && type !== undefined) && (schemeId !== null && schemeId !== undefined)) {
            return Api.get("/marking-scheme/question-mark")
                .params({ typeOfQuestion: type, schemeId: schemeId })
                .send((response) => {
                    if (callBack) {
                        callBack(response)
                    }
                    if (response != undefined) {
                        dispatch(addMarkingSchemeMarks(response))
                    }
                })
        } else {
            return null
        }
    }
}

export const addMarkingSchemeMarks = (data) => ({
    type: ADD_MARKING_SCHEME_MARKS,
    data
})

export const getQuestionNumbers = (callBack = null) => {
    return (dispatch, getState) => {
        const { generateQuesListViewData, deletingChapterId } = getState().onlineTestReducer;
        return Api.get("/testv2/question-by-chapter")
            .params({
                testId: generateQuesListViewData?.id,
                chapterId: deletingChapterId
            })
            .send((response) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    dispatch(totalQuesInChapter(response))
                }
            })
    }
}

export const DeleteChapterInTest = (callBack = null) => {
    return (dispatch, getState) => {
        const { generateQuesListViewData, deletingChapterId } = getState().onlineTestReducer;
        return Api.delete("/testv2/delete-chapter-in-test")
            .params({
                testId: generateQuesListViewData?.id,
                chapterId: deletingChapterId
            })
            .send((response) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    dispatch(totalQuesInChapter(response))
                }
            })
    }
}

export const totalQuesInChapter = (val) => ({
    type: TOTAL_QUES_IN_CHAPTER,
    val
})

export const storeDeletingChapterId = (val) => ({
    type: STORE_DELETING_CHAPTER_ID,
    val
})

export const modifyOptions = (index, operation, qIndexType, qType) => ({
    type: MODIFY_OPTIONS,
    index,
    operation,
    qIndexType,
    qType
})

export const postPublishAllReady = (callBack = null) => {
    return (dispatch, getState) => {
        return Api.post(`/test/publish-all-ready`).params().send(response => {
            callBack()
            if (response != undefined) {
            }
        });
    }
}

export const setFromImportListView = (val) => ({
    type: SET_FROM_IMPORT_LIST_VIEW,
    val
})

export const setStartTimeTest = (val) => ({
    type: SAVE_START_TIME,
    val
})