import { Checkbox, Dropdown, Menu, Pagination, Popover, Select, Typography } from 'antd'
import { InputSearch } from 'components/customInputs'
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import filterIcon from "../../Assets/aulasMarket/filterIC.svg"
import UserAvatar from 'components/userAvatar'
import { connect } from 'react-redux'
import { getCourseStudentsData, getStandardsFromStudentsIds, postCancelTransactionRequest, postNewTransferStudents, postSendReminder, postUnassignStudents, postUnassignStudentsFromCourse, revokeCancelledInstallment, setAllStudentsPageParam, setPaymentModalParamsObj } from 'actions/courseManagement'
import PageDataLoader from 'components/pageDataLoader'
import { Api } from 'services'
import "./styles.css"
import moment from 'moment'
import { postSendInvoice } from 'actions/feeManagement'
import Axios from 'axios'
import fileDownload from 'js-file-download'
import { Button } from 'components/Button';
import PermanentDeleteModal from '../userManagementV2/modals/parmanentDeleteModal'
import NewTransferStudentsModal from './newTransferStudentsModal'
import NewUnassignStudentsModal from './newUnassignStudentsModal'
import ConfirmationModal from './confirmationModal'
import { getAllClasses } from 'actions/classRoom'
import { CloseOutlined } from '@ant-design/icons'
import AssignStudentsToBatchModal from './assignStudentsToBatchModal'
import { setSpecificUserId, setUserDetails, storeStudentRollNo } from 'actions/userManagementV2'
import SendInvoiceEmailModal from './sendInvoiceEmailModal'
import RemainderHistoryModal from './remainderHistoryModal'
import EditOfflinePaymentModal from './editOfflinePaymentModal'
import AddDiscountModal from './addDiscountModal'
import { setOthersSelectedTab } from 'actions/profileNew'

const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
const { Option } = Select;

export const bodyStyle = {
    backgroundColor: "#FAFAFA",
    width: " calc(100% + 30px)",
    height: "calc(100% + 40px)",
    margin: "-20px",
    padding: "20px",
    overflow: "auto"
}

function StudentsViewAllPage({ getCourseStudentsData, setAllStudentsPageParam, allStudentsEnrolledData, allStudentsParamsObj, postSendInvoice, postSendReminder, user, getStandardsFromStudentsIds, specificCourseDataContainer, postUnassignStudentsFromCourse, classes, getAllClasses, postNewTransferStudents, postUnassignStudents, storeStudentRollNo, postCancelTransactionRequest, revokeCancelledInstallment, setPaymentModalParamsObj, paymentModalParamsObj, setUserDetails, setSpecificUserId, setOthersSelectedTab }) {
    const history = useHistory()
    const [isFilterClicked, setIsFilterClicked] = useState();
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [parmanentDeleteModal, setPermanentDeletModal] = useState(false);
    const [isTransferStudentsModalVisible, setIsTransferStudentsModalVisible] = useState(false);
    const [standardsFromStudentIdsData, setStandardsFromStudentIdsData] = useState([]);
    const [isUnassignStudentsModalVisible, setIsUnassignStudentsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isAssignBatchModalVisible, setIsAssignBatchModalVisible] = useState(false);
    const [sendInvoiceEmailModalVisible, setSendInvoiceEmailModalVisible] = useState(false);
    const [sendInvoiceBtnLoader, setSendInvoiceBtnLoader] = useState(false);
    const [singleFeeItem, setSingleFeeItem] = useState(null);
    const [isRemainderHistoryModalVisible, setRemainderHistoryModalVisible] = useState(false);
    const [feeRemindersData, setFeeRemindersData] = useState([])
    const [isSendReminderClicked, setSendReminderClicked] = useState(false);
    const [isCancelRequestClicked, setCancelRequestClicked] = useState(false);
    const [isRevokeCancelClicked, setRevokeCancelClicked] = useState(false);
    const [unassignStudents, setUnassignStudents] = useState(false);
    const [isEditOfflinePaymentModalVisible, setEditOfflinePaymentModalVisible] = useState(false);
    const [isAddDiscountModalVisible, setIsAddDiscountModalVisible] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [transactionData, setTransactionData] = useState(null);
    const [isEditOfflinePaymentClicked, setIsEditOfflinePaymentClicked] = useState(false)
    const [downloadHoveredItemIndex, setDownloadHoveredItemIndex] = useState(null);
    const [sendInvoiceHoveredItemIndex, setSendInvoiceHoveredItemIndex] = useState(null);
    const [sendReminderHoveredItemIndex, setSendReminderHoveredItemIndex] = useState(null);
    const [remainderHistoryHoveredItemIndex, setRemainderHistoryHoveredItemIndex] = useState(null);
    const [cancelRequestHoveredItemIndex, setCancelRequestHoveredItemIndex] = useState(null);
    const [revokeCancelHoveredItemIndex, setRevokeCancelHoveredItemIndex] = useState(null);
    const [editOfflinePaymentHoveredItemIndex, setEditOfflinePaymentHoveredItemIndex] = useState(null);
    const [markAsPaidOfflineHoveredItemIndex, setMarkAsPaidOfflineHoveredItemIndex] = useState(null);
    const [emailId, setEmailId] = useState(null)
    const [tab, setTab] = useState(1);


    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');

    const handleDownloadHover = (index) => {
        setDownloadHoveredItemIndex(index);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(null)
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(null);
    };

    const handleSendInvoiceHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(index);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(null);
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(null);
    };

    const handleEditOfflinePaymentHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(index);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(null);
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(null)
    }

    const handleMarkAsPaidOfflineHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(index);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(null);
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(null)
    }

    const handleSendReminderHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(index);
        setRemainderHistoryHoveredItemIndex(null);
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(null);
    };

    const handleRemainderHistoryHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(index);
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(null)
    }

    const handleCancelRequestHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(null);
        setCancelRequestHoveredItemIndex(index);
        setRevokeCancelHoveredItemIndex(null)
    }

    const handleRevokeCancelHover = (index) => {
        setDownloadHoveredItemIndex(null);
        setSendInvoiceHoveredItemIndex(null);
        setEditOfflinePaymentHoveredItemIndex(null);
        setMarkAsPaidOfflineHoveredItemIndex(null);
        setSendReminderHoveredItemIndex(null);
        setRemainderHistoryHoveredItemIndex(null);
        setCancelRequestHoveredItemIndex(null);
        setRevokeCancelHoveredItemIndex(index)
    }

    let selectedStudentsName = allStudentsEnrolledData?.students?.items
        ?.filter((item) => selectedStudents?.includes(item?.id))
        ?.map((item) => item?.name)?.join(", ")
        ?.replace(/, ([^,]*)$/, ' and $1')

    let selectedStandardName = allStudentsEnrolledData?.standards
        ?.filter((item) => allStudentsParamsObj?.standardIds?.includes(item?.id))
        ?.map((item) => item?.name)?.join(", ")
        ?.replace(/, ([^,]*)$/, ' and $1')

    const tableDataStyle = {
        color: "#191919", fontFamily: "roboto", textAlign: "left",
    }

    const tableRowStyle = {
        paddingLeft: 10, paddingTop: 10, paddingBottom: 10
    }

    useEffect(() => {
        setAllStudentsPageParam("search", null);
        setAllStudentsPageParam("page", 1);
        setAllStudentsPageParam("limit", 10);
        setAllStudentsPageParam("standardIds", []);
        storeStudentRollNo(null)
        togglePageLoader(true)
        getCourseStudentsData(() => togglePageLoader(false))
    }, [])

    useEffect(() => {
        setSelectedStudents([])
        togglePageLoader(true)
        getCourseStudentsData(() => togglePageLoader(false))
    }, [allStudentsParamsObj?.search, allStudentsParamsObj?.page, allStudentsParamsObj?.limit, allStudentsParamsObj?.standardIds])

    const menu = (
        <Menu className='different-bgc-dropdown' style={{ borderRadius: "16px 16px 8px 8px", padding: "10px 10px", width: 300, marginTop: -35, boxShadow: "-4px 4px 16px #00000029" }}>
            <div className='r-ac'>
                <img style={{ width: 15, height: 15, cursor: "pointer", marginRight: 10, marginLeft: 2 }} src={filterIcon} onClick={() => { setIsFilterClicked(!isFilterClicked) }} />
                <div style={{ color: "#AEAEAE", fontSize: 13 }}>Filter by batch</div>
            </div>
            <div className='r-c-c-c m-t-5 m-b-5' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            <div className='scroll-bar-universal' style={{ height: allStudentsEnrolledData?.standards?.length > 8 ? 300 : "fit-content", backgroundColor: "#FFFFFF" }}>
                {allStudentsEnrolledData?.standards?.map(option => (
                    <Menu.Item key={option.name} style={{ cursor: "pointer", backgroundColor: allStudentsParamsObj?.standardIds?.includes(option?.id) ? "#D6C8F5" : "", borderRadius: 8, fontWeight: allStudentsParamsObj?.standardIds?.includes(option?.id) ? 600 : "normal", color: allStudentsParamsObj?.standardIds?.includes(option?.id) ? "#191919" : "#AEAEAE", paddingLeft: 5 }}
                        onClick={() => {
                            setAllStudentsPageParam("standardIds", [option?.id])
                        }}>
                        {option?.name}
                    </Menu.Item>
                ))}
            </div>
        </Menu>
    )

    const handleSearch = (e) => {
        setAllStudentsPageParam("search", e);
    }

    const actions = [
        selectedStudents?.length && allStudentsEnrolledData?.students?.items?.filter(student => selectedStudents.includes(student.id)).every(student => student.studentStandards.length > 0) && (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) ? (<Button style={{ marginRight: 20, backgroundColor: "#636363", color: "#FFFFFF", borderRadius: 16, fontWeight: 600, border: "1px solid #636363" }} onClick={() => { getStandardsFromStudentsIds(selectedStudents, (res) => { setStandardsFromStudentIdsData(res) }); setIsUnassignStudentsModalVisible(true); setUnassignStudents(true); setSendReminderClicked(false); setCancelRequestClicked(false); setRevokeCancelClicked(false) }}>UNASSIGN</Button>) : null,
        selectedStudents?.length ? (<Button style={{ marginRight: 20, backgroundColor: "#1089FF", color: "#FFFFFF", borderRadius: 16, fontWeight: 600, border: "1px solid #1089FF" }} onClick={() => { getAllClasses(); storeStudentRollNo(null); setAllStudentsPageParam("rollNo", null); setIsAssignBatchModalVisible(true) }}>ASSIGN</Button>) : null,
        selectedStudents?.length && allStudentsEnrolledData?.students?.items?.filter(student => selectedStudents.includes(student.id)).every(student => student.studentStandards.length > 0) && (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) ? (<Button style={{ marginRight: 20, backgroundColor: "#FFA931", color: "#FFFFFF", borderRadius: 16, fontWeight: 600, border: "1px solid #FFA931" }} onClick={() => { getStandardsFromStudentsIds(selectedStudents, (res) => { setStandardsFromStudentIdsData(res) }); getAllClasses(); setIsTransferStudentsModalVisible(true) }}>TRANSFER</Button>) : null,
        selectedStudents?.length && (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) ? (<Button style={{ marginRight: 5, backgroundColor: "#FF414D", color: "#FFFFFF", borderRadius: 16, fontWeight: 600, border: "1px solid #FF414D" }} onClick={() => { setPermanentDeletModal(true) }}>DELETE</Button>) : null,
        !selectedStudents?.length && <InputSearch
            allowClear
            placeholder="Search by name / mobile number"
            value={allStudentsParamsObj?.search}
            onEnter={(e) => { handleSearch(e) }}
            onClear={() => { handleSearch("") }}
            style={{ width: 300, marginRight: 20 }} />,
        (!selectedStudents?.length && allStudentsEnrolledData?.standards?.length > 1) && <Dropdown overlay={isFilterClicked ? menu : <></>} trigger={['click']} open={isFilterClicked} >
            <div className='r-c-sb' style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", width: 300, padding: "2px 10px", color: "#AEAEAE", display: "flex", alignItems: "center", borderRadius: 32, cursor: "pointer", marginRight: 10 }} onClick={(e) => { e.stopPropagation(); setIsFilterClicked(!isFilterClicked) }}>
                <div className='r-ac'>
                    <img style={{ width: 15, height: 15, marginRight: 10, marginLeft: 2 }} src={filterIcon} />
                    <div style={{ color: "#AEAEAE", fontSize: 13 }}>{selectedStandardName ? selectedStandardName : "Filter by batch : All"}</div>
                </div>
                {allStudentsParamsObj?.standardIds?.length ? <div><CloseOutlined style={{ fontSize: 16, color: "#AEAEAE" }} onClick={(e) => { e.stopPropagation(); setAllStudentsPageParam("standardIds", []) }} /></div> : <></>}
            </div>
        </Dropdown>
    ]

    const handlePageination = (value) => {
        setAllStudentsPageParam("page", value);
    };

    const [expandedDetailsItemId, setExpandedDetailsItemId] = useState(null);
    const [expandedMoreItemId, setExpandedMoreItemId] = useState(null);

    const toggleExpansion = (itemId, source) => {
        if (source === "DETAILS") {
            setExpandedDetailsItemId(prevId => (prevId === itemId ? null : itemId));
            setExpandedMoreItemId(null);
        } else if (source === "MORE") {
            setExpandedMoreItemId(prevId => (prevId === itemId ? null : itemId));
            setExpandedDetailsItemId(null);
        }
    };

    function onSelectAll(e) {
        let temp = [];
        if (e.target.checked) {
            for (let i = 0; i < allStudentsEnrolledData?.students?.items?.length; i++) {
                temp.push(allStudentsEnrolledData?.students?.items?.[i]?.id)
            }
        }
        setSelectedStudents([...temp])
    }

    const handleCheckbox = (value) => {
        setSelectedStudents((prevSelectedOptions) => {
            const isChecked = prevSelectedOptions.includes(value);
            if (isChecked) {
                const updatedOptions = prevSelectedOptions.filter((option) => option !== value);
                setSelectedStudents(updatedOptions)
            } else {
                const updatedOptions = [...prevSelectedOptions, value];
                setSelectedStudents(updatedOptions)
            }
        });
    };

    const onPermanentDeleteModalCancel = () => {
        setPermanentDeletModal(false)
    };

    const handleSendInvoiceOnClick = (transactionId) => {
        setSendInvoiceBtnLoader(true)
        postSendInvoice(transactionId, emailId, () => { setSendInvoiceBtnLoader(false) }, () => { setSendInvoiceEmailModalVisible(false) })
    }

    const TableDataRow = ({ item, index }) => {
        const itemId = item.id;
        const isDetailsExpanded = expandedDetailsItemId === itemId;
        const isMoreExpanded = expandedMoreItemId === itemId;

        const detailsBarArray = [{
            label: "Fee payment term",
            value: item?.studentFee?.feePlan
        },
        {
            label: "Total fee amount",
            value: "₹ " + item?.studentFee?.totalPayable
        },
        {
            label: "Total fee balance",
            value: "₹ " + item?.studentFee?.totalBalance
        }
        ]

        const hoverIconTextStyle = {
            padding: "2px 8px", borderRadius: 20, width: "fit-content", color: "#FFFFFF", fontSize: 10
        }

        const handleDownload = (invoiceFile) => {
            Axios
                .get(`${Api._s3_url}${invoiceFile}`, {
                    responseType: "blob",
                })
                .then((res) => {
                    if (res.data) {
                        fileDownload(res.data, "Transaction-Invoice.pdf");
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        return (
            <>
                <tr style={{ backgroundColor: "#ffffff", height: "fit-content", borderBottom: isDetailsExpanded ? "" : "1px solid #e6e6e6", borderTop: "1px solid #e6e6e6", borderLeft: "1px solid #E6E6E6", borderRight: "1px solid #E6E6E6", textAlign: "left", cursor: "pointer" }}
                    onClick={(e) => {
                        if (!hasMarketingPrivilege) {
                            e.stopPropagation()
                            setUserDetails(item)
                            setSpecificUserId(item?.id);
                            setOthersSelectedTab('Basic Profile');
                            history.push({
                                pathname: '/user-management/profile',
                                state: {
                                    key: true
                                }
                            })
                        }

                    }}>
                    <td style={{ width: "25%", ...tableRowStyle }}>
                        <div className='r-ac' style={{ ...tableDataStyle, paddingLeft: 10 }}>
                            {hasMarketingPrivilege ? <></> :
                                <label onClick={(e) => e.stopPropagation()}><Checkbox checked={selectedStudents?.includes(allStudentsEnrolledData?.students?.items?.[index]?.id)} onChange={() => { handleCheckbox(item?.id) }} /></label>
                            }
                            <div className='r-ac m-l-10'>
                                <UserAvatar size="4vmin" shape="circle" name={item?.name} img={item?.image == "default.png" ? "" : `${Api._s3_url}${item?.image}`} />
                                <div className='m-l-10' style={{ textAlign: "left", width: "100%" }}>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ ...tableDataStyle, width: 200 }}>{item?.name} </Typography.Text>
                                    {item?.email ? <Typography.Text ellipsis={{ tooltip: true }} style={{ ...tableDataStyle, color: "#AEAEAE", fontSize: 12, width: 200 }}>{item?.email}</Typography.Text> : <></>}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style={{ width: "15%", ...tableRowStyle }}><div style={{ ...tableDataStyle }}>{item?.phone || "-"}</div></td>
                    <td style={{ width: "12%", ...tableRowStyle }}><div style={tableDataStyle}>{item?.attendancePercentage}%</div></td>
                    <td style={{ width: "30%", ...tableRowStyle }}>
                        {isMoreExpanded ?
                            <div style={{ textAlign: "left" }}>
                                {item?.studentStandards?.map((batch, batchIndex) => (
                                    <>
                                        <Typography.Text key={batchIndex} ellipsis={{ tooltip: true }} style={{ ...tableDataStyle, width: 300 }}>{batch?.name}</Typography.Text>
                                        <div className='display-flex' style={{ width: "100%", textAlign: "left" }}>
                                            <div style={{ color: "#AEAEAE", fontSize: 12, width: "50%" }}>Roll No. <span style={{ color: "#191919" }}> {batch?.rollNo || "-"}</span></div>
                                            <div style={{ color: "#AEAEAE", fontSize: 12, width: "50%" }}>Attendance <span style={{ color: "#191919" }}>{batch?.attendancePercentage}%</span></div>
                                        </div>
                                        {batchIndex !== item.studentStandards.length - 1 && <div className='m-t-5 m-b-5 border-dashed-element' style={{ width: "90%" }}></div>}
                                    </>
                                ))}
                            </div>
                            :
                            <>
                                {item?.studentStandards?.length ?
                                    <>
                                        {item?.studentStandards?.length == 1 ?
                                            <>
                                                <div style={{ textAlign: "left", width: "100%" }}>
                                                    {item?.studentStandards?.map((batch, batchIndex) => (
                                                        <>
                                                            <Typography.Text key={batchIndex} ellipsis={{ tooltip: true }} style={{ ...tableDataStyle, width: 300 }}>{batch?.name}</Typography.Text>
                                                            <div className='display-flex' style={{ width: "100%", textAlign: "left" }}>
                                                                <div style={{ color: "#AEAEAE", fontSize: 12, width: "50%" }}>Roll No. <span style={{ color: "#191919" }}> {batch?.rollNo || "-"}</span></div>
                                                                <div style={{ color: "#AEAEAE", fontSize: 12, width: "50%" }}>Attendance <span style={{ color: "#191919" }}>{batch?.attendancePercentage}%</span></div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </>
                                            :
                                            <div className='r-c-sb'>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ ...tableDataStyle, width: 220 }}>{item?.studentStandards[0]?.name}</Typography.Text>
                                                {item?.studentStandards?.length > 1 ? <div className='m-r-20 cursor-pointer' style={{ color: "#1089FF", fontWeight: 600 }} onClick={(e) => { e.stopPropagation(); toggleExpansion(itemId, "MORE") }}>+ {item?.studentStandards?.length - 1} MORE</div> : <></>}
                                            </div>
                                        }
                                    </>
                                    :
                                    <div style={{ textAlign: "left" }}>-</div>}
                            </>
                        }
                    </td>
                    <td style={{ width: "18%", ...tableRowStyle }}>
                        <div className='r-c-sb' style={tableDataStyle}>
                            <div >₹ {item?.studentFee?.totalFee || "0"}</div>
                            {item?.studentFee?.totalFee > 0 ? <div className='m-r-20 cursor-pointer' style={{ color: "#1089FF", fontWeight: 600 }} onClick={(e) => { e.stopPropagation(); toggleExpansion(itemId, "DETAILS"); setEmailId(item?.email) }}>DETAILS</div> : <></>}
                        </div>
                    </td>
                </tr>
                {isDetailsExpanded &&
                    <tr style={{ backgroundColor: "#ffffff", borderLeft: "1px solid #E6E6E6", borderRight: "1px solid #E6E6E6", borderBottom: "1px solid #E6E6E6", width: "100%" }}>
                        <td colSpan={5} style={{ textAlign: "left", padding: "0px 20px 10px 20px" }}>
                            <div className='r-c-sa' style={{ backgroundColor: "#F6F4FE", border: "1px solid #E6E6E6", width: "100%", borderRadius: 16, padding: "15px 0px" }}>
                                {detailsBarArray?.map((item) => (
                                    <div className='display-flex'>
                                        <div style={{ color: "#636363" }}>{item?.label}</div>
                                        <div className='m-l-10' style={{ coloe: "#191919", fontWeight: 600 }}>{item?.value || "-"}</div>
                                    </div>
                                ))}
                            </div>
                            {item?.studentFee?.installments?.length ?
                                <>
                                    <div className='m-t-10' style={{ coloe: "#191919", fontWeight: 600, marginLeft: 2 }}>Installments</div>
                                    <div className='display-flex' style={{ flexWrap: "wrap", width: "100%", marginLeft: 2 }}>
                                        {item?.studentFee?.installments?.map((feeItem, index) => (
                                            <div className='display-flex m-t-10 installment-parent' style={{ width: 220 }}>
                                                <div style={{ fontSize: 24, color: "#636363", marginTop: -10, color: feeItem?.status === "Paid" ? "#28DF99" : feeItem?.status === "Pending" ? "#FFA931" : feeItem?.status === "Overdue" ? "#FF414D" : feeItem?.status === "Cancelled" ? "#636363" : "#594099" }}>•</div>
                                                <div className='m-l-10' style={{ fontSize: 12 }}>
                                                    <div style={{ color: "#191919", fontWeight: 600 }}>Installment {index + 1}</div>
                                                    <div style={{ color: "#191919", fontWeight: 600 }}>₹ {feeItem?.amountPayable}</div>
                                                    <div style={{ color: "#636363", fontWeight: 600 }}>
                                                        {feeItem?.status === "Paid" && feeItem?.transaction?.paymentDate ? `Paid on ${feeItem?.transaction?.paymentDate}` :
                                                            feeItem?.status === "Pending" && feeItem?.transaction?.dueDate ? `Due on ${feeItem?.transaction?.dueDate}` :
                                                                feeItem?.status === "Overdue" && feeItem?.transaction?.dueDate ? `Overdue on ${feeItem?.transaction?.dueDate}` :
                                                                    feeItem?.status === "Cancelled" && feeItem?.transaction?.cancelledDate ? `Cancelled on ${feeItem?.transaction?.cancelledDate}` : ""}
                                                    </div>
                                                    <div className='show-action-icons' style={{ marginTop: 8, marginBottom: 20 }}>
                                                        {!hasMarketingPrivilege && (feeItem?.status === "Paid" ?
                                                            <>
                                                                <Dropdown open={downloadHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#1089FF", ...hoverIconTextStyle }}>Download invoice</div>} showArrow={false} placement='bottomLeft'>
                                                                    <img className='cursor-pointer' src={require("../../Assets/courseManagementV4/download-icon.svg").default} onClick={() => { handleDownload(feeItem?.transaction?.invoice) }} onMouseEnter={() => handleDownloadHover(index)} onMouseLeave={() => setDownloadHoveredItemIndex(null)} />
                                                                </Dropdown>
                                                                <Dropdown open={sendInvoiceHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#FFA931", ...hoverIconTextStyle }}>Send invoice</div>} showArrow={false} placement='bottomLeft'>
                                                                    <img className='m-l-15 cursor-pointer' src={require("../../Assets/courseManagementV4/sendInvoice-icon.svg").default} onClick={() => { setSendInvoiceEmailModalVisible(true); setSendInvoiceHoveredItemIndex(null); setSingleFeeItem(feeItem) }} onMouseEnter={() => handleSendInvoiceHover(index)} onMouseLeave={() => setSendInvoiceHoveredItemIndex(null)} />
                                                                </Dropdown>
                                                                {feeItem?.transaction?.payment_type == "online" ? <></> :
                                                                    <Dropdown open={editOfflinePaymentHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#DAAB00", ...hoverIconTextStyle }}>Edit offlie payment</div>} showArrow={false} placement='bottomLeft'>
                                                                        <img className='m-l-15 cursor-pointer' src={require("../../Assets/courseManagementV4/edit-offline-payment-icon.svg").default} onClick={() => { setEditOfflinePaymentHoveredItemIndex(null); setEditOfflinePaymentModalVisible(true); setTransactionData(feeItem?.transaction); setIsEditOfflinePaymentClicked(true) }} onMouseEnter={() => handleEditOfflinePaymentHover(index)} onMouseLeave={() => setEditOfflinePaymentHoveredItemIndex(null)} />
                                                                    </Dropdown>
                                                                }
                                                            </>
                                                            :
                                                            feeItem?.status === "Pending" || feeItem?.status === "Overdue" ?
                                                                <>
                                                                    <Dropdown open={markAsPaidOfflineHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#594099", ...hoverIconTextStyle }}>Mark as paid offline</div>} showArrow={false} placement='bottomLeft'>
                                                                        <img className='cursor-pointer' src={require("../../Assets/courseManagementV4/mark-as-paid-offline-icon.svg").default} onClick={() => { setMarkAsPaidOfflineHoveredItemIndex(null); setEditOfflinePaymentModalVisible(true); setTransactionData(feeItem?.transaction); setIsEditOfflinePaymentClicked(false) }} onMouseEnter={() => handleMarkAsPaidOfflineHover(index)} onMouseLeave={() => setMarkAsPaidOfflineHoveredItemIndex(null)} />
                                                                    </Dropdown>
                                                                    <Dropdown open={sendReminderHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#14B789", ...hoverIconTextStyle }}>Send reminder</div>} showArrow={false} placement='bottomLeft'>
                                                                        <img className='m-l-15 cursor-pointer' src={require("../../Assets/courseManagementV4/remainder-icon.svg").default} onClick={() => { setIsConfirmModalVisible(true); setSendReminderHoveredItemIndex(null); setSendReminderClicked(true); setSingleFeeItem(feeItem); setUnassignStudents(false); setCancelRequestClicked(false); setRevokeCancelClicked(false) }} onMouseEnter={() => handleSendReminderHover(index)} onMouseLeave={() => setSendReminderHoveredItemIndex(null)} />
                                                                    </Dropdown>
                                                                    {feeItem?.transaction?.feeReminders?.length ?
                                                                        <Dropdown open={remainderHistoryHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#09ACE0", ...hoverIconTextStyle }}>Reminder history</div>} showArrow={false} placement='bottomLeft'>
                                                                            <img className='m-l-15 cursor-pointer' src={require("../../Assets/courseManagementV4/remainder-history-icon.svg").default} onClick={() => { setRemainderHistoryModalVisible(true); setRemainderHistoryHoveredItemIndex(null); setFeeRemindersData(feeItem?.transaction?.feeReminders) }} onMouseEnter={() => handleRemainderHistoryHover(index)} onMouseLeave={() => setRemainderHistoryHoveredItemIndex(null)} />
                                                                        </Dropdown>
                                                                        :
                                                                        <></>}
                                                                    <Dropdown open={cancelRequestHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#191919", ...hoverIconTextStyle }}>Cancel request</div>} showArrow={false} placement='bottomLeft'>
                                                                        <img className='m-l-15 cursor-pointer' src={require("../../Assets/courseManagementV4/cancel-request-icon.svg").default} onClick={() => { setIsConfirmModalVisible(true); setCancelRequestHoveredItemIndex(null); setUnassignStudents(false); setCancelRequestClicked(true); setRevokeCancelClicked(false); setSendReminderClicked(false); setSingleFeeItem(feeItem) }} onMouseEnter={() => handleCancelRequestHover(index)} onMouseLeave={() => setCancelRequestHoveredItemIndex(null)} />
                                                                    </Dropdown>
                                                                </>
                                                                :
                                                                feeItem?.status === "Cancelled" ?
                                                                    <Dropdown open={revokeCancelHoveredItemIndex === index} overlay={<div style={{ backgroundColor: "#E50087", ...hoverIconTextStyle }}>Revoke cancel</div>} showArrow={false} placement='bottomLeft'>
                                                                        <img className='cursor-pointer' src={require("../../Assets/courseManagementV4/revoke-cancelled-icon.svg").default} onClick={() => { setIsConfirmModalVisible(true); setRevokeCancelHoveredItemIndex(null); setUnassignStudents(false); setRevokeCancelClicked(true); setCancelRequestClicked(false); setSendReminderClicked(false); setSingleFeeItem(feeItem) }} onMouseEnter={() => handleRevokeCancelHover(index)} onMouseLeave={() => setRevokeCancelHoveredItemIndex(null)} />
                                                                    </Dropdown>
                                                                    :
                                                                    <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                : <></>}
                        </td>
                    </tr>
                }
            </>
        )
    }

    return (
        <div style={bodyStyle} onClick={(e) => { e.stopPropagation(); setIsFilterClicked(false); }}>
            <div style={{ marginBottom: "60px" }}>
                <PageHeader title='Students enrolled' onBackPress={() => { history.push({ pathname: "/course-details", state: { key: "openStudents" } }) }} actions={actions} />
            </div>
            <div>
                <table>
                    <thead>
                        <tr style={{ backgroundColor: "#F6F4FE", height: "50px", color: "#636363", fontFamily: "roboto", width: "100%", textAlign: "left" }}>
                            <th style={{ width: "25%", paddingLeft: 20 }}>
                                {!hasMarketingPrivilege && (allStudentsEnrolledData?.students?.items?.length ?
                                    <Checkbox className='m-r-10' checked={selectedStudents?.length === allStudentsEnrolledData?.students?.items?.length && selectedStudents?.length !== 0} onChange={onSelectAll} />
                                    : <></>
                                )}
                                NAME
                            </th>
                            <th style={{ width: "15%", paddingLeft: 10 }}>MOBILE NUMBER</th>
                            <th style={{ width: "12%", paddingLeft: 10 }}>ATTENDANCE</th>
                            <th style={{ width: "30%", paddingLeft: 10 }}>BATCH</th>
                            <th style={{ width: "18%", paddingLeft: 10 }}>FEE</th>
                        </tr>
                    </thead>
                    {allStudentsEnrolledData?.students?.items?.map((item, index) => (
                        <TableDataRow
                            item={item}
                            index={index} />
                    ))}
                </table>
            </div>
            {allStudentsEnrolledData?.students?.items?.length == 0 ?
                <div className="r-c-c-c" style={{ width: "100%", height: "70vh" }}>
                    <div><img src={illNoData} /></div>
                    <div><h4><b>No data!</b></h4></div>
                    <div><h5>There are no data to show yet. Please check back later</h5></div>
                </div>
                : null
            }
            {allStudentsEnrolledData?.students?.items?.length ?
                <div className='r-jsb m-t-10'>
                    <div style={{ color: "#636363", }}>
                        <Select
                            style={{ width: 100, marginRight: 10 }}
                            defaultValue={"10/ page"}
                            value={allStudentsParamsObj?.limit}
                            onChange={(value) => {
                                setAllStudentsPageParam("limit", value);
                            }}>
                            <Option value={10}>10/ page</Option>
                            <Option value={12}>12/ page</Option>
                            <Option value={16}>16/ page</Option>
                            <Option value={18}>18/ page</Option>
                        </Select>
                        {allStudentsEnrolledData?.students?.meta?.itemsPerPage * (allStudentsEnrolledData?.students?.meta?.currentPage - 1) + 1}{" "} -{" "}
                        {allStudentsEnrolledData?.students?.meta?.itemsPerPage * allStudentsEnrolledData?.students?.meta?.currentPage < allStudentsEnrolledData?.students?.meta?.totalItems ? allStudentsEnrolledData?.students?.meta?.itemsPerPage * allStudentsEnrolledData?.students?.meta?.currentPage : allStudentsEnrolledData?.students?.meta?.totalItems}{" "}of {allStudentsEnrolledData?.students?.meta?.totalItems} items
                    </div>
                    <div>
                        <Pagination
                            size="small"
                            current={allStudentsEnrolledData?.students?.meta?.currentPage}
                            defaultCurrent={1}
                            total={allStudentsEnrolledData?.students?.meta?.totalPages * 10}
                            onChange={handlePageination}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
                :
                <></>}
            {parmanentDeleteModal &&
                <PermanentDeleteModal
                    isFromCourse={true}
                    getCourseStudentsData={getCourseStudentsData}
                    togglePageLoader={togglePageLoader}
                    setSelectedRowKeys={setSelectedStudents}
                    selectedRowKeys={selectedStudents}
                    open={parmanentDeleteModal}
                    onCancel={() => onPermanentDeleteModalCancel()} />}
            {isTransferStudentsModalVisible &&
                <NewTransferStudentsModal
                    isTransferStudentsModalVisible={isTransferStudentsModalVisible}
                    setIsTransferStudentsModalVisible={setIsTransferStudentsModalVisible}
                    standardsFromStudentIdsData={standardsFromStudentIdsData}
                    classes={classes}
                    getCourseStudentsData={getCourseStudentsData}
                    togglePageLoader={togglePageLoader}
                    setSelectedStudents={setSelectedStudents}
                    selectedStudents={selectedStudents}
                    postNewTransferStudents={postNewTransferStudents}
                />}
            {isUnassignStudentsModalVisible &&
                <NewUnassignStudentsModal
                    isUnassignStudentsModalVisible={isUnassignStudentsModalVisible}
                    setIsUnassignStudentsModalVisible={setIsUnassignStudentsModalVisible}
                    standardsFromStudentIdsData={standardsFromStudentIdsData}
                    selectedStudents={selectedStudents}
                    tab={tab}
                    setTab={setTab}
                    setIsConfirmModalVisible={setIsConfirmModalVisible}
                    courseName={specificCourseDataContainer?.course?.courseName}
                    postUnassignStudents={postUnassignStudents}
                    getCourseStudentsData={getCourseStudentsData}
                    togglePageLoader={togglePageLoader}
                    setSelectedStudents={setSelectedStudents}
                />}
            {isConfirmModalVisible &&
                <ConfirmationModal
                    isConfirmModalVisible={isConfirmModalVisible}
                    setIsConfirmModalVisible={setIsConfirmModalVisible}
                    unassignStudents={unassignStudents}
                    selectedStudentsName={selectedStudentsName}
                    tab={tab}
                    courseName={specificCourseDataContainer?.course?.courseName}
                    setIsUnassignStudentsModalVisible={setIsUnassignStudentsModalVisible}
                    selectedStudents={selectedStudents}
                    postUnassignStudentsFromCourse={postUnassignStudentsFromCourse}
                    getCourseStudentsData={getCourseStudentsData}
                    togglePageLoader={togglePageLoader}
                    setSelectedStudents={setSelectedStudents}
                    isSendReminderClicked={isSendReminderClicked}
                    postSendReminder={postSendReminder}
                    singleFeeItem={singleFeeItem}
                    isRevokeCancelClicked={isRevokeCancelClicked}
                    isCancelRequestClicked={isCancelRequestClicked}
                    postCancelTransactionRequest={postCancelTransactionRequest}
                    revokeCancelledInstallment={revokeCancelledInstallment}
                />}
            {isAssignBatchModalVisible &&
                <AssignStudentsToBatchModal
                    isAssignBatchModalVisible={isAssignBatchModalVisible}
                    setIsAssignBatchModalVisible={setIsAssignBatchModalVisible}
                    selectedStudents={selectedStudents}
                    classes={classes}
                    getCourseStudentsData={getCourseStudentsData}
                    togglePageLoader={togglePageLoader}
                    setSelectedStudents={setSelectedStudents}
                    setAllStudentsPageParam={setAllStudentsPageParam}
                />}
            {sendInvoiceEmailModalVisible &&
                <SendInvoiceEmailModal
                    sendInvoiceEmailModalVisible={sendInvoiceEmailModalVisible}
                    setSendInvoiceEmailModalVisible={setSendInvoiceEmailModalVisible}
                    emailId={emailId}
                    setEmailId={setEmailId}
                    handleSendInvoiceOnClick={handleSendInvoiceOnClick}
                    sendInvoiceBtnLoader={sendInvoiceBtnLoader}
                    singleFeeItem={singleFeeItem} />}
            {isRemainderHistoryModalVisible &&
                <RemainderHistoryModal
                    isRemainderHistoryModalVisible={isRemainderHistoryModalVisible}
                    setRemainderHistoryModalVisible={setRemainderHistoryModalVisible}
                    feeRemindersData={feeRemindersData}
                />}
            {isEditOfflinePaymentModalVisible &&
                <EditOfflinePaymentModal
                    isEditOfflinePaymentModalVisible={isEditOfflinePaymentModalVisible}
                    setEditOfflinePaymentModalVisible={setEditOfflinePaymentModalVisible}
                    transactionData={transactionData}
                    getCourseStudentsData={getCourseStudentsData}
                    togglePageLoader={togglePageLoader}
                    isAddDiscountModalVisible={isAddDiscountModalVisible}
                    setIsAddDiscountModalVisible={setIsAddDiscountModalVisible}
                    isDiscount={isDiscount}
                    setIsDiscount={setIsDiscount}
                    setPaymentModalParamsObj={setPaymentModalParamsObj}
                    isEditOfflinePaymentClicked={isEditOfflinePaymentClicked}
                />}
            {isAddDiscountModalVisible &&
                <AddDiscountModal
                    isAddDiscountModalVisible={isAddDiscountModalVisible}
                    setIsAddDiscountModalVisible={setIsAddDiscountModalVisible}
                    setEditOfflinePaymentModalVisible={setEditOfflinePaymentModalVisible}
                    setPaymentModalParamsObj={setPaymentModalParamsObj}
                    paymentModalParamsObj={paymentModalParamsObj}
                    isDiscount={isDiscount}
                    transactionData={transactionData} />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { allStudentsEnrolledData, allStudentsParamsObj, specificCourseDataContainer, paymentModalParamsObj } = state.courseManagement;
    const { classes } = state.classRoom;
    const { user } = state.session
    return {
        allStudentsEnrolledData, allStudentsParamsObj, user, specificCourseDataContainer, classes, paymentModalParamsObj
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAllStudentsPageParam: (key, val) => dispatch(setAllStudentsPageParam(key, val)),
    getCourseStudentsData: (callBack) => dispatch(getCourseStudentsData(callBack)),
    postSendInvoice: (id, emailId, callBack, succesCallback) => dispatch(postSendInvoice(id, emailId, callBack, succesCallback)),
    postSendReminder: (params, callBack, succesCallback) => dispatch(postSendReminder(params, callBack, succesCallback)),
    getStandardsFromStudentsIds: (ids, callback) => dispatch(getStandardsFromStudentsIds(ids, callback)),
    getAllClasses: () => dispatch(getAllClasses()),
    postUnassignStudentsFromCourse: (ids, callback, succesCallback) => dispatch(postUnassignStudentsFromCourse(ids, callback, succesCallback)),
    postNewTransferStudents: (params, callBack, successCallBack) => dispatch(postNewTransferStudents(params, callBack, successCallBack)),
    postUnassignStudents: (params, callBack, successCallBack) => dispatch(postUnassignStudents(params, callBack, successCallBack)),
    storeStudentRollNo: (rollNo) => dispatch(storeStudentRollNo(rollNo)),
    postCancelTransactionRequest: (id, callback, successCallBack) => dispatch(postCancelTransactionRequest(id, callback, successCallBack)),
    revokeCancelledInstallment: (id, callback, successCallBack) => dispatch(revokeCancelledInstallment(id, callback, successCallBack)),
    setPaymentModalParamsObj: (key, val) => dispatch(setPaymentModalParamsObj(key, val)),
    setUserDetails: (res) => dispatch(setUserDetails(res)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsViewAllPage);