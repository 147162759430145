import React, { useEffect, useState } from 'react'
import "./relatedCourse.css"
import RatingStars from '../ratingStars'
import dummyImg from "../../../Assets/aulasMarket/courseImage.png"
import saveFill from "../../../Assets/aulasMarket/saveFilledIcon.svg"
import saveOutlined from "../../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import { Tooltip } from 'antd'
import "../saveForLater.css"
import starIcon from "../../../Assets/aulasMarket/ic-star-fill.svg"
import { Api } from 'services'
import { connect } from 'react-redux'
import { postAddSaveItem, removeSaveItem, storeCourseIdAndType } from 'actions/studentMarketPlace'
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const defaultCourseImage = require('Assets/default_course-img.svg').default;

import HtmlParser from 'react-html-parser';



function RelatedCourse({ singleCourseDetailsDataContainer, postAddSaveItem, removeSaveItem, fromMyCourcesPage,storeCourseIdAndType, isDownloadBarVisible, showContactBar }) {
    const [itemsToShow, setItemsToShow] = useState(false);

    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState();
 

    const totalItemsToShow = itemsToShow ? singleCourseDetailsDataContainer?.length : 6;
    const toggleShowMore = () => {
        setItemsToShow(!itemsToShow);
    };
    console.log("dataaaaa", singleCourseDetailsDataContainer)

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;
    
        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }
        return false;
    }

    const SaveRelatedCourseCompo = ({ item }) => {
        const [isSavedClicked, setIsSavedClicked] = useState(false)
        const [saved, setSaved] = useState({});
        const history = useHistory()
        useEffect(() => {
            setSaved((prevSavedItems) => ({
                ...prevSavedItems,
                [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
            }));
        }, [])
        const handleSaveForLater = (id, itemType) => {
            setIsSavedClicked(!isSavedClicked)
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        };
        return (
            <div className='related-subchild' style={{ cursor: "pointer" }}
                onClick={() => {
                    storeCourseIdAndType("id",item?.id);
                    storeCourseIdAndType("type",item?.resourceType)
                    history.push({
                        pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                        state: {
                            id: item?.id,
                            resourceType: item?.resourceType
                        }
                    })
                }}>
                <div className='r-c-fs' style={{ width: "100%" }}>
                    <div className='rel-img-parent'>
                        <img className='rel-img-child' src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} />
                    </div>
                    <div className='rel-info-container' style={{}}>
                        <div className='r-c-sb' >
                            <div className='related-title'>{item?.resourceType === "course" || item?.resourceType === "videoResource" ? item?.courseName : item?.name}</div>
                            <div className='related-cost-save-rate'>
                                {item?.rating ?
                                    <div className='r-c-c m-r-20'><RatingStars star={item?.rating} />{item?.ratingCount ? <span className='rel-rating-count'>({item?.ratingCount})</span> : null}  </div>
                                    : null}
                                <div className='rel-costs m-r-20'>₹{item?.amount}</div>
                                {item?.owned == 1 ? <></> :
                                <Tooltip title="Save for Later">
                                    <div onClick={(e) => { e.stopPropagation(); handleSaveForLater(item?.id, item?.resourceType) }}><img src={saved[item?.id] || isSavedClicked ? saveFill : saveOutlined} style={{ height: 20, width: 20 }} /></div>
                                    {/* <div onClick={(e) => { e.stopPropagation(); handleSaveForLater(item?.id, item?.resourceType) }}><img src={saved[item?.id] ? saveFill : saveOutlined} style={{ height: 20, width: 20 }} /></div> */}
                                </Tooltip>
                                }
                            </div>
                        </div>
                        <div className='rel-breif'><Tooltip title={IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}>{IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}</Tooltip></div>
                        <div className='rel-seller-parent'>
                            <div className='r-c-fs' style={{ width: "50%" }}>
                                {item?.bestseller ?
                                    <div className='rel-bestseller'>Bestseller</div>
                                    : null}
                                {item?.rating ?
                                    <div className='relate-mobile-rating'><img className='relate-star' src={starIcon} /><span style={{ color: "#191919", fontWeight: "550" }}>{item?.rating}</span>
                                        <span style={{ marginLeft: 5 }}>(12)</span>
                                    </div>
                                    : null}
                            </div>
                            <div className='relate-mobile-rating'>
                                <img onClick={() => {
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                                }} style={{ width: 20, height: 20, cursor: "pointer" }} src={require("../../../Assets/aulasMarket/shareIcon.svg").default} />
                                {item?.owned == 1 ? <></> :
                                <div onClick={(e) => { e.stopPropagation(); handleSaveForLater(item?.id, item?.resourceType) }}><img src={saved[item?.id] || isSavedClicked ? saveFill : saveOutlined} style={{ height: 20, width: 20 }} /></div>
                            }
                                {/* <img onClick={(e) => { e.stopPropagation(); handleSaveForLater(item?.id, item?.resourceType) }} style={{ width: 20, height: 20, cursor: "pointer", marginLeft: 10 }} src={saved[item?.id] || item?.inSaved == 1 ? saveFill : saveOutlined} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const CalculateMarginTop = () => {
        if (isDownloadBarVisible && showContactBar) {
          return 160
        } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
          return 110
        } else if (!isDownloadBarVisible && !showContactBar) {
          return 60
        }
      }
    return (
        <div className='rel-parent' style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div className='rel-header'>Related Courses</div>
            <div className='rel-child'>
                {singleCourseDetailsDataContainer?.slice(0, totalItemsToShow).map((item, index) => (
                    <SaveRelatedCourseCompo item={item} />
                ))}
                {singleCourseDetailsDataContainer?.length > 6 ?
                    <div className='rel-show-more'>
                        <div onClick={toggleShowMore}>{itemsToShow ? "Show Less" : "Show more"}</div>
                    </div>
                    : null}
            </div>
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl}
                />}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { showContactBar, isDownloadBarVisible } = state.studentMarketPlace;
    const { user } = state.session
    return { showContactBar, user, isDownloadBarVisible };
};

const mapDispatchToProps = (dispatch) => ({
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    storeCourseIdAndType:(key,val) => dispatch(storeCourseIdAndType(key,val))
});
export default connect(mapStateToProps, mapDispatchToProps)(RelatedCourse)
