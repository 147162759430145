import moment from "moment";

export const validateEmail = (emailId) => {
    if (!emailId || emailId === "") {
        return false
    }
    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailId?.match(email)) {
        return false
    } else {
        return true
    }
}
const style = {
    marginLeft: "50%",
}
export const studentBasicKycDetails = (userKycDetails, handleInputChange, handlePhoneNumber, user) => {
    function disabledDate(current) {
        // Can not select days before today and today
        return !(
            current &&
            current < moment().subtract(user?.role?.includes('Student') ? 6 : 14, "years")
        );
    }
    return [
        {
            fields: [
                {
                    label: "Name", type: "text",
                    value: userKycDetails?.name ? userKycDetails?.name : '', required: true, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "name"),
                    placeholder: "Enter name"
                },
                {
                    label: "Phone Number", type: "phoneInputWithSendOtp", value: userKycDetails?.phone, required: true,
                    placeholder: "Enter mobile no",
                    disabled: false,
                    onChange: e => {
                        handleInputChange(e, "phone");
                        handlePhoneNumber()
                    },
                },
                {
                    label: "Email ID", type: "text", value: userKycDetails?.email ? userKycDetails?.email : '', required: true,
                    disabled: false,
                    onChange: e => handleInputChange(e.target.value, "email"),
                    placeholder: "Enter email id",
                    error: validateEmail(userKycDetails?.email) ? "Enter valid email id" : null
                },
                {
                    label: "Gender", type: "select", value: userKycDetails?.gender, required: true, disabled: false,
                    options: ["Male", "Female", "Others"],
                    placeholder: "Select gender",
                    onChange: e => handleInputChange(e, "gender"),
                },
                {
                    label: "Date of birth", type: "date", value: userKycDetails?.dob, required: true, disabled: false,
                    onChange: e => handleInputChange(e, "dob"),
                    placeholder: "Enter date of birth",
                    disabledDate: disabledDate
                },
                {
                    label: "Relationship", type: "select", value: userKycDetails?.relation, required: true, disabled: false,
                    onChange: (value) => handleInputChange(value, "relation"),
                    options: ["Father", "Mother", "Others"],
                    placeholder: "Select relationship"
                },
                userKycDetails?.relation === "Others" ?
                    {
                        type: "text",
                        value: userKycDetails?.relationType ? userKycDetails?.relationType : '',
                        disabled: false,
                        onChange: e => handleInputChange(e.target.value, "relationType"),
                        placeholder: "Enter relation",
                        style: style
                    }
                    :
                    ""
            ]
        },
    ]
}
export const studentContactKycDetails = (userKycDetails, handleInputChange, validateData) => {
    return [
        {
            fields: [
                {
                    label: "House No./Locality/Area", type: "text", value: userKycDetails?.h_no || "", required: true, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, 'h_no')
                    },
                    placeholder: "Enter House No./Locality/Area"
                },
                {
                    label: "PIN/ZIP Code", type: "number", value: userKycDetails?.pincode || "", required: true, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, 'pincode');
                        validateData(e.target.value, 'pincode')
                    },
                    placeholder: "Enter Pincode",
                    // onFocusChange: (e) => validateData(e.target.value, 'pincode'),
                    error: userKycDetails?.pincodeValid ? "Enter valid pincode" : ""
                },
                {
                    label: "State", type: "text", value: userKycDetails?.state || "", required: true, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, 'state')
                    },
                    placeholder: "Enter State"
                },
                {
                    label: "District", type: "text", value: userKycDetails?.city || "", required: true, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, 'city')
                    },
                    placeholder: "Enter District/City"
                },
                {
                    label: "Country", type: "text", value: userKycDetails?.country || "", required: true, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, 'country')
                    },
                    placeholder: "Enter Country"
                },
            ]
        },
    ]
}