import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './dateField.css';

function AnimatedDatePicker({ label, value, handleDateChange, isMandatory, inputId, isDisabled, disabled, placeholder, style, width, inputstyle , inputReadOnly, disabledDate, allowClear}) {
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.add('shrink');
        }
    }, [value, inputId]);

    const handleInputFocus = () => {
        setIsFocused(true);
        document.getElementById(`customInputLabel_${inputId}`).classList.add('shrink');
    };

    const handleInputBlur = () => {
        setIsFocused(false);
        if (!value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('shrink');
        }
    };

    return (
        <div className="custom-input-container m-t-20" style={style}>
            <label htmlFor={`customInput_${inputId}`} id={`customInputLabel_${inputId}`} className={`custom-input-label ${value ? 'shrink' : ''}`} >
                {label}{" "} {isMandatory ? <span style={{ color: 'red' }}>*</span> : <></>}
            </label>
            <DatePicker
            bordered={null}
                id={`customInput_${inputId}`}
                style={{ 
                    color: "#636363", 
                    fontSize: 14, 
                    backgroundColor: isFocused ? "#F6F4FE" : "#FFFFFF", 
                    width: width ? width : "100%", 
                    marginLeft:-12,
                    ...inputstyle, 
                }}
                format="DD/MM/YYYY"
                placeholder=""
                onChange={handleDateChange}
                value={value ? moment(value, 'DD/MM/YYYY') : null}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                disabled={isDisabled || disabled}
                inputReadOnly = {inputReadOnly}
                allowClear={allowClear || false}
                disabledDate={disabledDate}
            />
            <div style={{borderBottom :'1px solid #8C8C8C'}}></div>

        </div>
    );
}

export default AnimatedDatePicker;
