import { CheckCircleOutlined, DragOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Input, Modal, Tooltip } from 'antd'
import AnimatedTextField from 'components/AnimatedTextField';
import { Button } from 'components/Button';
import NewlyCreatedSubjectCard from 'pages/adminMarketPlaceCourse/newlyCreatedSubjectCard';
import React, { useEffect, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'CHAPTER';

const DraggableChapters = ({ chapterItem, chapterIndex, subjectIndex, subjects, setSubjects, subjectItem }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: ItemType,
        hover(draggedItem, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = draggedItem.chapterIndex;
            const hoverIndex = chapterIndex;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            const updatedSubjects = [...subjects];
            const dragChapter = updatedSubjects[subjectIndex].courseChapter[dragIndex];
            updatedSubjects[subjectIndex].courseChapter.splice(dragIndex, 1);
            updatedSubjects[subjectIndex].courseChapter.splice(hoverIndex, 0, dragChapter);

            setSubjects(updatedSubjects);
            draggedItem.chapterIndex = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { type: ItemType, chapterIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0 : 1;

    return (
        <div ref={ref} className='m-t-15' style={{ display: "flex", opacity }}>
            <div className='m-t-5 m-r-5' style={{ fontSize: 14, color: "#636363", fontWeight: 600, width: 30 }}>{chapterIndex + 1}.</div>
            <div style={{ width: "100%", marginTop: -25 }}>
                <AnimatedTextField
                    label={""}
                    handleInputChange={(e) => {
                        const updatedSubjects = [...subjects];
                        updatedSubjects[subjectIndex].courseChapter[chapterIndex] = { name: e.target.value };
                        setSubjects(updatedSubjects);
                    }}
                    isMandatory={false}
                    inputId={chapterItem?.id}
                    value={chapterItem?.name}
                    placeholder={"Enter chapter name"}
                />
            </div>
            {subjectItem?.courseChapter?.length > 1 ?
                <div><DragOutlined style={{ fontSize: "18px", color: "#FFA931", marginTop: "5px", cursor: "pointer", marginLeft: 10 }} /></div>
                : <></>}
            {chapterIndex === subjects[subjectIndex]?.courseChapter?.length - 1 ?
                <PlusCircleOutlined
                    className='m-t-5 m-l-10'
                    style={{ color: "#1089FF", cursor: chapterItem?.name ? "pointer" : "not-allowed", fontSize: 18 }}
                    onClick={() => {
                        if (chapterItem?.name) {
                            const updatedSubjects = [...subjects];
                            updatedSubjects[subjectIndex].courseChapter.push({ name: '' });
                            setSubjects(updatedSubjects);
                        }
                    }} />
                :
                <MinusCircleOutlined
                    className='m-t-5 m-l-10'
                    style={{ color: "red", cursor: "pointer", fontSize: 18, }}
                    onClick={() => {
                        const updatedSubjects = [...subjects];
                        updatedSubjects[subjectIndex].courseChapter.splice(chapterIndex, 1);
                        setSubjects(updatedSubjects);
                    }}
                />
            }
        </div>
    );
};

function CreateCurriculumModal({ isCreateCurriculumModalVisible, setIsCreateCurriculumModalVisible, togglePageLoader, getSpecificCourseData, postCreateCurriculum, fromClassroom, getSubjStudentsData, fromCurriculum, getCurriculum }) {
    const [subjects, setSubjects] = useState([
        {
            "name": null,
            "courseChapter": [
                {
                    "name": null
                }
            ]
        }
    ]);
    const [addBtnLoader, setAddBtnLoader] = useState(false);
    const [createdCards, setCreatedCards] = useState([]);
    const [isSavedClicked, setIsSaveClicked] = useState(false);
    const location = useLocation()

    const handleCancel = () => {
        setIsCreateCurriculumModalVisible(false);
    }

    const handleSubjectNameChange = (index, newName) => {
        const updatedSubjects = [...createdCards];
        updatedSubjects[index].name = newName;
        setCreatedCards(updatedSubjects);
    };

    const handleChapterNameChange = (subjectIndex, chapterIndex, newName) => {
        const updatedCards = [...createdCards];
        if (!updatedCards[subjectIndex]) {
            updatedCards[subjectIndex] = { courseChapter: [] };
        } else if (!updatedCards[subjectIndex].courseChapter) {
            updatedCards[subjectIndex].courseChapter = [];
        }
        const updatedChapter = { ...updatedCards[subjectIndex].courseChapter[chapterIndex] };
        updatedChapter.name = newName;
        updatedCards[subjectIndex].courseChapter[chapterIndex] = updatedChapter;
        setCreatedCards(updatedCards);
    };


    const addChapter = (subjectIndex) => {
        const updatedCards = [...createdCards];
        updatedCards[subjectIndex].courseChapter.push({ name: '' });
        setCreatedCards(updatedCards);
    };

    const removeChapter = (subjectIndex, chapterIndex) => {
        const updatedCards = [...createdCards];
        updatedCards[subjectIndex].courseChapter.splice(chapterIndex, 1);
        setCreatedCards(updatedCards);
    };

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isCreateCurriculumModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>
                {"Create curriculum"}
            </div>
            <div className='scroll-bar-universal' style={{ padding: "0px 10px 10px 10px ", height: 450, marginBottom: 10 }}>
                {createdCards?.length > 0 ?
                    <NewlyCreatedSubjectCard
                        createdCards={createdCards}
                        onUpdateSubjectName={handleSubjectNameChange}
                        onUpdateChapterName={handleChapterNameChange}
                        onAddChapter={addChapter}
                        onRemoveChapter={removeChapter}
                    />
                    :
                    <></>
                }
                {isSavedClicked ?
                    <div className='m-t-20 r-c-c'>
                        <div style={{ cursor: createdCards?.some(subject => !subject.name) ? "not-allowed" : "pointer", color: "#1089FF", fontWeight: "600", fontFamily: "roboto", opacity: createdCards?.some(subject => !subject.name) ? 0.5 : 1, width: 120 }}
                            onClick={() => {
                                if (createdCards?.some(subject => !subject.name)) { }
                                else {
                                    setIsSaveClicked(false);
                                    setSubjects([
                                        {
                                            "name": null,
                                            "courseChapter": [
                                                {
                                                    "name": null
                                                }
                                            ]
                                        }
                                    ])
                                }
                            }}
                        >
                            <PlusCircleOutlined /><span className='m-l-5'>ADD SUBJECT</span>
                        </div>
                    </div>
                    :
                    <></>
                }
                {isSavedClicked ? <></> :
                    <div style={{ marginTop: 10 }}>
                        {subjects?.map((item, index) => (
                            <React.Fragment key={index}>
                                <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 600 }}>{"Subject name"}{" "} </label>
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder='Enter subject name'
                                    value={item?.name}
                                    onChange={(e) => {
                                        const updatedSubjects = [...subjects];
                                        updatedSubjects[index].name = e.target.value;
                                        setSubjects(updatedSubjects);
                                    }}
                                    style={{ color: "#636363", fontSize: 14, }}
                                />
                                <h6 style={{ fontSize: "14px", color: "#8C8C8C", fontWeight: 600, marginTop: 15 }}>Chapter(s)</h6>
                                <DndProvider backend={HTML5Backend}>
                                    {item?.courseChapter?.map((chapterItem, chapterIndex) => (
                                        <DraggableChapters
                                            key={chapterIndex}
                                            chapterItem={chapterItem}
                                            chapterIndex={chapterIndex}
                                            subjectIndex={index}
                                            subjects={subjects}
                                            setSubjects={setSubjects}
                                            subjectItem={item}
                                        />
                                    ))}
                                </DndProvider>
                            </React.Fragment>
                        ))}
                        <div className='m-t-20 r-c-c'>
                            <div
                                style={{
                                    cursor: subjects.some(subject => !subject.name) ? "not-allowed" : "pointer",
                                    color: "#1089FF",
                                    fontWeight: "600",
                                    fontFamily: "roboto",
                                    opacity: subjects.some(subject => !subject.name) ? 0.5 : 1,
                                    width: 120,
                                }}
                                onClick={() => {
                                    if (subjects.some(subject => !subject.name)) { }
                                    else {
                                        setIsSaveClicked(true);
                                        const newCard = subjects.map(subject => ({
                                            name: subject.name,
                                            courseChapter: subject.courseChapter.map(chapter => ({ name: chapter.name }))
                                        }));
                                        setCreatedCards(prevCards => [...prevCards, ...newCard]);
                                        setSubjects([]);
                                    }
                                }}
                            >
                                <CheckCircleOutlined /><span className='m-l-5'>SAVE</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <center>
                <div className="r-c-sa" style={{ width: "70%" }}>
                    <div style={{ color: "#636363", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setIsCreateCurriculumModalVisible(false) }}>CANCEL</div>
                    <Button
                        loading={addBtnLoader}
                        type="primary"
                        disabled={!subjects.concat(createdCards).some(item => item.name)}
                        onClick={() => {
                            const concatenatedArray = [...subjects, ...createdCards].flat();
                            const filteredArray = concatenatedArray
                                ?.filter(item => item?.name && item?.name?.trim() !== '')
                                ?.map(({ courseChapter, ...rest }) => {
                                    const filteredChapters = courseChapter?.filter(chapter => chapter?.name);
                                    return { ...rest, chapters: filteredChapters };
                                });
                            setAddBtnLoader(true)
                            postCreateCurriculum(
                                filteredArray,
                                fromClassroom || fromCurriculum ? true : false,
                                () => { setAddBtnLoader(false) },
                                () => {
                                    setIsCreateCurriculumModalVisible(false);
                                    togglePageLoader(true)
                                    if (fromCurriculum) {
                                        getCurriculum(null, location.pathname, () => { togglePageLoader(false) }, null)
                                    } else if (fromClassroom) {
                                        getSubjStudentsData(() => togglePageLoader(false))
                                    } else {
                                        getSpecificCourseData(() => togglePageLoader(false))
                                    }
                                }
                            )
                        }}
                    >CREATE</Button>
                </div>
            </center>
        </Modal>
    )
}

export default CreateCurriculumModal