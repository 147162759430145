import React, { useEffect, useState } from "react";
import './courseContent.css'
import { Collapse } from "antd";
import video from '../../../Assets/aulasMarket/previewDarkColor.png'
import quiz from '../../../Assets/aulasMarket/ic-practice-quiz.svg'
import { CaretRightOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { getVideoResourceDetails } from "actions/studentMarketPlace";
import PreviewCourse from '../coursePageCompo/previewCourse';


const { Panel } = Collapse;

const CourseContentPage = ({ getVideoResourceDetails, singleCourseDetailsDataContainer }) => {
    const location = useLocation()
    const [expanded, setExpanded] = useState(false);
    const [activeKey, setActiveKey] = useState([]);
    const [isVideoPreview, setVideoPreview] =useState(false)
    const [previewLink,setPreviewLink]=useState('')
    const [videoTypeLink,setVideoTypeLink] = useState('')
    const [isPreviewClicked, setIsPreviewClicked] = useState(false);
    const [courseContentPreviewName, setCourseContentPreviewName] = useState(null)

    // useEffect(() => {
    //     getVideoResourceDetails(location?.state?.id, () => { })
    // }, [])
    const handleExpandAll = () => {
        setExpanded(!expanded);
        setActiveKey(expanded ? [] : singleCourseDetailsDataContainer?.videoResource?.sections.map((_, index) => index.toString()));
    };
    const handlePanelChange = (key) => {
        if (expanded) {
            setActiveKey(activeKey.includes(key) ? [] : [key]);
        } else {
            setActiveKey([key]);
        }
    };

    return (
        <div className="courses-content-container m-t-10">
            {singleCourseDetailsDataContainer?.videoResource?.sections?.length == 0 ? 
            (<></>):
            (<>
            <div className="m-t-20" style={{ fontSize: "21px", fontWeight: "bold", fontFamily: 'Roboto' }}>Course content</div>
            <div className="m-t-20 course-alignment">
                <div style={{ fontSize: "17px", color: "#AEAEAE", fontFamily: 'Roboto' }}>Course content • {singleCourseDetailsDataContainer?.videoResource?.topicCount} lectures • {singleCourseDetailsDataContainer?.videoResource?.duration} total length</div>
                <div style={{ fontSize: "17px", color: "#1089FF", fontFamily: 'Roboto', fontWeight: "bold", cursor: "pointer" }} onClick={handleExpandAll}>{expanded ? 'Collapse all sections' : 'Expand all sections'}</div>
            </div>
            <div className="m-t-10">
                <Collapse accordion={!expanded} size='small' activeKey={activeKey}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ marginTop: "-10px" }} />}
                    onChange={handlePanelChange}>
                    {singleCourseDetailsDataContainer?.videoResource?.sections?.map((topics, index) => {
                        return (
                            <Panel
                                key={index.toString()}
                                header={
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                        <div style={{ fontSize: "16px", fontFamily: "Roboto", fontWeight: "bold" }}>{topics?.name}</div>
                                        <div style={{ display: "flex",justifyContent:"space-between",gap:20  }}>
                                            <div style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "Roboto", }}>{topics?.topicsCount}{" "}{" "}<h style={{ marginRight: "15px" }}>lectures</h></div>
                                            <div style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "Roboto", }}>{topics?.tDuration}• mins </div>
                                        </div>
                                    </div>}
                            >
                                {topics?.topics?.map((item) => {
                                    return (
                                        <div className="p-10">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", gap: 20 ,cursor:"not-allowed"}} >
                                                    {item?.type == 'notes' ?
                                                        <FilePdfOutlined style={{ color: "#FF414D", marginLeft: 10, fontSize: 25, marginTop: "5px" ,marginRight:"2px"}} />
                                                        :
                                                        item?.type == 'video'
                                                            ?
                                                            <img style={{ width: "28px", height: "28px", marginLeft: "10px", marginTop: "5px" }} src={video} alt="Video" />
                                                            :
                                                            <img src={quiz} style={{ width: "28px", height: "28px", marginLeft: "10px", marginTop: "5px" }} />
                                                    }
                                                    <div style={{ fontSize: "16px", fontWeight: 450, marginTop: "5px", fontFamily: "Roboto", }}>{item?.name}</div>
                                                </div>
                                                <div style={{ display: "flex",justifyContent:"space-between",gap:60 }}>
                                                    {item?.preview == '1'?
                                                        <div style={{ fontSize: "16px", fontWeight: 700, fontFamily: "Roboto",cursor:'pointer' }} onClick={()=>{setVideoPreview(true),setPreviewLink(item?.fileUrl);setIsPreviewClicked(true); setCourseContentPreviewName(item?.name)}}><u>Preview</u></div> : <div></div>}
                                                       
                                                       {item?.type == 'quiz'  || item?.videoType == 'link' ? (<></>):
                                                    <div style={{ fontSize: '16px', color: "#AEAEAE", fontWeight: 700, fontFamily: "Roboto", }}>{item?.type === 'notes' ? (
                                                        item?.pageCount > 0 ? (
                                                          `${item?.pageCount} Pages`
                                                        ) : (
                                                          '1 Page'
                                                        )
                                                      ) : (
                                                        item?.duration
                                                      )}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Panel>)})}
                </Collapse>
            </div></>)
            }
             {isVideoPreview &&
                <PreviewCourse
                    isVideoPreview={isVideoPreview}
                    setVideoPreview={setVideoPreview}
                    previewLink={previewLink}
                    videoType ={videoTypeLink}
                    isPreviewClicked={isPreviewClicked}
                    courseContentPreviewName={courseContentPreviewName}
                />}
        </div>
    )

}

const mapStateToProps = (state) => {
    const { singleCourseDetailsDataContainer } = state.studentMarketPlace
    return { singleCourseDetailsDataContainer }
};

const mapDispatchToProps = (dispatch) => ({
    getVideoResourceDetails: (id, callback) => dispatch(getVideoResourceDetails(id, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseContentPage)