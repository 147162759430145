import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getCoursesCategories, getCoursesSubCategories, getSearchResultsData, setSearchResultPageParamsObj } from 'actions/courseManagement';
import { fetchCourseVA, fetchStudyMaterialsVA, fetchTestSeriesVA, fetchVideoResourcesVA, setWhichViewAllClicked, storeResourceData } from 'actions/feeManagement';
import { AutoComplete, Dropdown, Menu, Pagination, Radio, Row, Switch, Typography } from 'antd';
import { Button } from 'components/Button';
import { SubHeading } from 'components/Typography';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { Api } from 'services';
import testSeriesIcon from '../../Assets/ic-course.svg'
import { InputSearch } from 'components/customInputs';
import './styles.css'
import { setUserids } from 'actions/userManagementV2';

const defaultCourseImage = require('Assets/default_course-img.svg').default;

const Card = ({ imageSrc, title, testSeriesIconSrc, description, count, cardClick, studentCount, selectedCourse }) => (
    <div style={{ cursor: 'pointer', width: 260 }} onClick={cardClick}>
        
            <div className='image-container'>
                {imageSrc && <img className='image-container' src={imageSrc} />}
                <div className="hover-text" style={{ display: 'flex' }}>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>{studentCount}</div>
                    <div style={{ fontSize: "12px", margin: '5px 5px' }}> {studentCount > 1 || studentCount == 0 ? 'Students' : 'Student'}</div>
                </div>
            </div> 
        {title && <SubHeading bold style={{ width: 300 }}>{title}</SubHeading>}

        
        {/* {testSeriesIconSrc && (
            <div className='p-15 study-container'>
                <Row>
                    <img src={testSeriesIconSrc} style={{ width: 50 }} />
                    <div className='m-l-10 m-t-15'>{count}</div>
                </Row>
                <div className="hover-text-study">
                    <div style={{ fontSize: "21px", fontWeight: "bold", marginTop: "10px" }}>{studentCount}</div>
                    <div style={{ color: '#AEAEAE', fontSize: "17px" }}>Students</div>
                </div>
            </div>
        )} */}
        {
            description && (
                <div style={{ width: 255, marginLeft: 5, marginTop: "5px", overflow: "hidden" }}>
                    <Typography.Text
                        className='text-ellipsis--3'
                        ellipsis={{ tooltip: true }}
                        style={{ width: '100%', fontWeight: 500 }}
                    >
                        {description}
                    </Typography.Text>
                </div>
            )
        }
    </div>
);

const InsideLiveCoursesStudents = ({ history, courseSubCategoriesData, courseCategoryData, searchPageParamsObj, setSearchResultPageParamsObj, getCoursesSubCategories, selectedCourse, fetchCourseVA, fetchTestSeriesVA, fetchVideoResourcesVA, fetchStudyMaterialsVA, viewAllDATA, setWhichViewAllClicked, storeResourceData, setUserids }) => {

    console.log({ searchPageParamsObj });
    let paginationComponent;
    const [statusArray, setStatusArray] = useState([
        { label: "All", value: null, },
        { label: "Published", value: true, },
        { label: "Unpublished", value: false, },
    ]);
    const modeArray = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Hybrid", value: "hybrid" }, { label: "Offline", value: "offline" }];

    const bodyStyle = { backgroundColor: "#FAFAFA", width: " calc(100% + 30px)", height: "calc(100% + 40px)", margin: "-20px", padding: "20px", overflow: "auto" }

    const [search, setSearch] = useState('');
    const [mode, setMode] = useState('');
    const [isMultipleFilterClicked, setMultipleFilterClicked] = useState(false);
    const [isFilterCategoryClicked, setFilterCategoryClicked] = useState(false);
    const [isFilterSubCategoryClicked, setFilterSubCategoryClicked] = useState(false);
    const [selectedSubCategory, setSeletedSubCategory] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [pageLoader, togglePageLoader] = useState(false);
    const [modeLabel, setModeLabel] = useState('All');
    const [statusLabel, setStatusLabel] = useState('Active');

    console.log({ searchPageParamsObj })
    let params
    let published = null;
    let modes = null

    if (searchPageParamsObj?.status?.length > 0) {
        if (searchPageParamsObj?.status?.includes(true) && searchPageParamsObj?.status?.includes(false)) {
            published = null;
        } else if (searchPageParamsObj?.status?.includes(true)) {
            published = true;
        } else if (searchPageParamsObj?.status?.includes(false)) {
            published = false;
        }
    }

    if (searchPageParamsObj?.mode?.length > 0) {
        if (searchPageParamsObj?.mode?.includes(null)) {
            modes = null
        } else {
            modes = searchPageParamsObj?.mode
        }
    }
    if (searchPageParamsObj?.status == 'inactive') {
        console.log("callin")
        params = {
            // disablePagination: false,
            modes: modes,
            activeStatus: searchPageParamsObj?.deleted ? "inactive" : null,
            search,
            subcategoryId: searchPageParamsObj?.subcategoryId
        }
    } else {
        console.log("callinOut")
        params = {
            // disablePagination: false,

            modes: modes,
            published: published,
            search,
            subcategoryId: searchPageParamsObj?.subcategoryId,
            activeStatus: searchPageParamsObj?.deleted ? "inactive" : null,
        }
    }

    const handlePageChange = (value) => {
        let params = {
            page: value,
            disablePagination: false
        }

        togglePageLoader(true);
        if (selectedCourse == 'Live Courses') {
            fetchCourseVA(params, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Video Courses') {
            fetchVideoResourcesVA(params, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Online Test Series') {
            fetchTestSeriesVA(params, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Study Materials') {
            fetchStudyMaterialsVA(params, () => { togglePageLoader(false) })
        }
    }

    useEffect(() => {
        setSearchResultPageParamsObj('subcategoryId', null)
        setSearchResultPageParamsObj('mode', [])
        setSearchResultPageParamsObj('status', [])
        setSearchResultPageParamsObj('deleted', false)
    }, [])

    console.log("naveen", searchPageParamsObj);
    useEffect(() => {
        togglePageLoader(true)
        if (!searchPageParamsObj?.subcategoryId) {
            switch (selectedCourse) {
                case 'Live Courses':
                    fetchCourseVA(params, () => togglePageLoader(false));
                    break;

                case 'Online Test Series':
                    fetchTestSeriesVA(params, () => togglePageLoader(false));
                    break;

                case 'Video Courses':
                    fetchVideoResourcesVA(params, () => togglePageLoader(false));
                    break;

                case 'Study Materials':
                    fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                    break;

                default:
                    break;
            }
        } else {
            switch (selectedCourse) {
                case 'Live Courses':
                    fetchCourseVA(params, () => togglePageLoader(false));
                    break;

                case 'Online Test Series':
                    fetchTestSeriesVA(params, () => togglePageLoader(false));
                    break;

                case 'Video Courses':
                    fetchVideoResourcesVA(params, () => togglePageLoader(false));
                    break;

                case 'Study Materials':
                    fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                    break;

                default:
                    break;
            }
        }

    }, [search, searchPageParamsObj?.subcategoryId])

    switch (selectedCourse) {
        case 'Live Courses':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.liveCourses?.courses?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.liveCourses?.courses?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        case 'Online Test Series':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.onlineTestSeries?.testSeries?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.onlineTestSeries?.testSeries?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        case 'Video Courses':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.videoResources?.videoResources?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.videoResources?.videoResources?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        case 'Study Materials':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.studyMaterials?.studyMaterials?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.studyMaterials?.studyMaterials?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        default:
            break;
    }

    const FilterTitle = ({ titleName }) => {
        return (
            <div className='m-b-5' style={{ color: "#191919", fontWeight: 500 }}>{titleName}</div>
        )
    }


    // const toggleMode = (mode, label) => {
    //     if (searchPageParamsObj.mode.includes(mode)) {
    //         setSearchResultPageParamsObj("mode", searchPageParamsObj?.mode?.filter((i) => i !== mode))
    //     } else {
    //         setSearchResultPageParamsObj("mode", [...searchPageParamsObj.mode, mode])
    //     }
    //     setModeLabel(label)
    // };
    const toggleMode = (mode, label) => {
        let updatedModes;
        if (Array.isArray(searchPageParamsObj?.mode) && searchPageParamsObj?.mode?.includes(mode)) {
            updatedModes = searchPageParamsObj?.mode?.filter((i) => i !== mode);
        } else {
            updatedModes = [...(searchPageParamsObj?.mode || []), mode];
        }
        // If all modes are selected, replace with an empty array to signify "All"
        if (updatedModes.length === modeArray.length - 1) {
            updatedModes = [];
        }
        setSearchResultPageParamsObj("mode", updatedModes);
        setModeLabel(label);
    };


    const toggleStatus = (value, label) => {
        setStatusLabel(label);
        let updatedStatus;
        if (Array.isArray(searchPageParamsObj?.status) && searchPageParamsObj?.status?.includes(value)) {
            updatedStatus = searchPageParamsObj?.status?.filter((i) => i !== value);
        } else {
            updatedStatus = [...(searchPageParamsObj?.status || []), value];
        }
        // If all statuses are selected, replace with an empty array to signify "All"
        if (updatedStatus.length === statusArray.length - 1) {
            updatedStatus = [];
        }
        setSearchResultPageParamsObj("status", updatedStatus);
    };

    console.log("statusArry", statusArray, params)

    const toggleDelete = (value) => {
        console.log("deletedState", value)
        setSearchResultPageParamsObj('deleted', value)
    };

    const StatusFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course status"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {statusArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div
                                style={{
                                    height: "fit-content",
                                    width: "fit-content",
                                    backgroundColor: searchPageParamsObj?.status?.includes(item?.value) || (searchPageParamsObj?.status?.length === 0 && item.value === null) ? "#1089FF" : "#FFFFFF",
                                    border: searchPageParamsObj?.status?.includes(item?.value) || (searchPageParamsObj?.status?.length === 0 && item.value === null) ? "1px solid #1089FF" : "1px solid #E6E6E6",
                                    color: searchPageParamsObj?.status?.includes(item?.value) || (searchPageParamsObj?.status?.length === 0 && item.value === null) ? "#FFFFFF" : "#636363",
                                    borderRadius: 32,
                                    padding: "5px 10px 5px 10px",
                                    cursor: "pointer",
                                    fontSize: 12
                                }}
                                onClick={() => toggleStatus(item?.value, item?.label)}
                            >
                                {item?.label}
                            </div>
                        </div>
                    ))}
                </div>

            </div >
        )
    }
    const DeletedFilter = () => {

        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Deleted"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Switch checked={searchPageParamsObj?.deleted} onChange={toggleDelete} />
                </div>
            </div>
        )
    }

    const ModeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Mode of class"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {modeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{
                                height: "fit-content", width: "fit-content",
                                borderRadius: "16px",
                                backgroundColor: searchPageParamsObj?.mode?.includes(item.value) || (searchPageParamsObj?.mode?.length === 0 && item.value === null) ? "#1089FF" : "#FFFFFF",
                                border: searchPageParamsObj?.mode?.includes(item.value) || (searchPageParamsObj?.mode?.length === 0 && item.value === null) ? "1px solid #1089FF" : "1px solid #E6E6E6",
                                color: searchPageParamsObj?.mode?.includes(item.value) || (searchPageParamsObj?.mode?.length === 0 && item.value === null) ? "#FFFFFF" : "#636363",
                                padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12
                            }}
                                onClick={() => toggleMode(item?.value, item?.label)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const handleCategoryDropdownVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);
        setFilterSubCategoryClicked(false);
    };

    const handleSubCategoryDropdownVisibleChange = (visible) => {
        setFilterSubCategoryClicked(visible);
    };

    const handleApply = () => {
        togglePageLoader(true)
        if (selectedCourse == 'Live Courses') {
            fetchCourseVA(params, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Video Courses') {
            fetchVideoResourcesVA(params, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Online Test Series') {
            fetchTestSeriesVA(params, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Study Materials') {
            fetchStudyMaterialsVA(params, () => { togglePageLoader(false) })
        }
        setMultipleFilterClicked(false)
    }
    const handleReset = () => {

        togglePageLoader(true)
        setSearchResultPageParamsObj('mode', [])
        setSearchResultPageParamsObj('status', [])
        setSearchResultPageParamsObj('deleted', false)
        setStatusLabel("All")
        setModeLabel('All');
        let resetParams = {
            disablePagination: false,
            published: null
        }
        if (selectedCourse == 'Live Courses') {
            fetchCourseVA(resetParams, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Video Courses') {
            fetchVideoResourcesVA(resetParams, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Online Test Series') {
            fetchTestSeriesVA(resetParams, () => { togglePageLoader(false) })
        } else if (selectedCourse == 'Study Materials') {
            fetchStudyMaterialsVA(resetParams, () => { togglePageLoader(false) })
        }
        setMultipleFilterClicked(false)
    }

    console.log(searchPageParamsObj?.status, 'anurag')

    const filterSubCategoryMenu = (
        <Menu className='scroll-bar-universal' style={{ maxHeight: courseCategoryData?.length > 3 ? 150 : 'fit-content', padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
            <Radio.Group onChange={(e) => {
                e.stopPropagation();
                setFilterCategoryClicked(true)
                const selectedSubcategory = e.target.value;
                setSearchResultPageParamsObj("subcategoryId", selectedSubcategory?.id);
                togglePageLoader(true);
                switch (selectedCourse) {
                    case 'Live Courses':
                        fetchCourseVA(params, () => togglePageLoader(false));
                        break;

                    case 'Online Test Series':
                        fetchTestSeriesVA(params, () => togglePageLoader(false));
                        break;

                    case 'Video Courses':
                        fetchVideoResourcesVA(params, () => togglePageLoader(false));
                        break;

                    case 'Study Materials':
                        fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                        break;

                    default:
                        break;
                }
                setFilterCategoryClicked(false);
                setFilterSubCategoryClicked(false);
            }}
            >

                {courseSubCategoriesData?.map((subcategory) => (
                    <Radio key={subcategory?.name} value={subcategory} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                        <Typography.Text className="radio-hover-effect" ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{subcategory?.name}</Typography.Text>
                    </Radio>
                ))}
            </Radio.Group>
        </Menu >

    )

    const filterCategoryMenu = (
        <Dropdown
            visible={isFilterCategoryClicked}
            onVisibleChange={handleSubCategoryDropdownVisibleChange}
            overlay={isFilterSubCategoryClicked ? filterSubCategoryMenu : <></>} placement='right' open={isFilterSubCategoryClicked} trigger={['click']}>
            <Menu className='scroll-bar-universal ' style={{ maxHeight: courseCategoryData?.length > 6 ? 300 : 'fit-content', padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
                <Radio.Group onChange={(e) => {
                    const selectedCat = e?.target?.value;
                    getCoursesSubCategories(selectedCat?.id, null); setFilterSubCategoryClicked(true); setSelectedCategory({ id: selectedCat?.id, name: selectedCat?.name });
                }}>
                    {courseCategoryData && courseCategoryData?.map((category) => (
                        <Radio key={category?.id} value={category} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                            <Typography.Text className="radio-hover-effect" ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{category?.name}</Typography.Text>
                        </Radio>
                    ))}
                </Radio.Group>
            </Menu>
        </Dropdown>
    );

    const multipleFilterMenu = (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#FFF', padding: 20, borderRadius: 8 }}>
            {selectedCourse == 'Live Courses' && <ModeFilter />}
            <StatusFilter />
            <DeletedFilter />
            <div style={{ borderBottom: '1px solid #AEAEAE', width: '100%' }}></div>
            <div className='r-jsb m-t-10'>
                <Button type='link' style={{ fontSize: 14 }} onClick={() => { handleReset() }}>Reset</Button>
                <Button type='primary' style={{ backgroundColor: '#1089FF', color: 'white', borderRadius: 4, width: '20%', border: 'none' }}
                    onClick={() => { handleApply() }}>Apply</Button>
            </div>
        </div>
    )



    // This function will be called when the dropdown visibility changes
    const handleVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);

    };
    const handleVisible = (visible) => {
        setMultipleFilterClicked(visible);

    }

    const actions = [
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='r-jsb' style={{ width: 650, display: 'flex', alignItems: 'center' }}>
                <Dropdown overlay={isFilterCategoryClicked ? filterCategoryMenu : <></>} trigger={['click']} open={isFilterCategoryClicked} onVisibleChange={handleVisibleChange}>
                    <div

                        style={{
                            border: "1px solid #E6E6E6",
                            backgroundColor: "#FFFFFF",
                            width: 300,
                            padding: "5px 10px",
                            marginRight: 20,
                            color: "#AEAEAE",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 32,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setFilterCategoryClicked(!isFilterCategoryClicked);
                            setMultipleFilterClicked(false);
                            setFilterSubCategoryClicked(false);
                        }}
                    >
                        <div>Filter by category</div>
                        {!isFilterCategoryClicked ? (
                            <CaretDownOutlined />
                        ) : (
                            <CaretUpOutlined />
                        )}
                    </div>
                </Dropdown>

                <Dropdown overlay={isMultipleFilterClicked ? multipleFilterMenu : <></>} trigger={['click']} open={isMultipleFilterClicked} onVisibleChange={handleVisible}>
                    <div
                        style={{
                            border: "1px solid #E6E6E6",
                            backgroundColor: "#FFFFFF",
                            width: "30%",
                            padding: "5px 10px",
                            color: "#AEAEAE",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 43,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setMultipleFilterClicked(!isMultipleFilterClicked);
                            setFilterCategoryClicked(false);
                            setFilterSubCategoryClicked(false);
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <img src={require("../../Assets/aulasMarket/filterIC.svg").default} style={{ height: 18, width: 18, marginRight: 10, marginTop: 3 }} />
                            <div style={{ color: "#191919" }}>Filter</div>
                        </div>
                        {!isMultipleFilterClicked ? (
                            <CaretDownOutlined onClick={() => { setMultipleFilterClicked(!isMultipleFilterClicked) }} />
                        ) : (
                            <CaretUpOutlined onClick={() => { setMultipleFilterClicked(!isMultipleFilterClicked) }} />
                        )}
                    </div>
                </Dropdown>

                <InputSearch allowClear val={search} onEnter={(e) => setSearch(e)} placeholder={selectedCourse == 'Video Courses' ? 'Search course / teacher' : 'Search course'} style={{ marginLeft: "10px", borderRadius: 32, backgroundColor: 'white', padding: 5 }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                <div style={{ color: '#AEAEAE' }}>Mode: <span style={{ color: '#636363' }}>{modeLabel}</span></div> &nbsp; |  &nbsp; <div style={{ color: '#AEAEAE' }}>Status: <span style={{ color: '#636363' }}>{statusLabel}</span></div>
            </div>
        </div>

    ];

    return (
        <div style={bodyStyle}>
            <PageHeader title={
                selectedCourse == 'Live Courses' ? `Live Courses (${viewAllDATA?.liveCourses?.courses?.meta?.totalItems})` :
                    selectedCourse == 'Video Courses' ? `Video Courses (${viewAllDATA?.videoResources?.videoResources?.meta?.totalItems})` :
                        selectedCourse == 'Study Materials' ? `Study Materials (${viewAllDATA?.studyMaterials?.studyMaterials?.meta?.totalItems})` :
                            `Online Test Series (${viewAllDATA?.onlineTestSeries?.testSeries?.meta?.totalItems})`
            }
                actions={actions}
                onBackPress={() => history.goBack()}
            />
            <div className='m-t-70' style={{marginTop:"100px"}}>
                {pageLoader ? <></> :
                    <div className='m-t-60' >
                        {selectedCourse === 'Live Courses' &&
                            <div className='display-flex flex-wrap' style={{ gap: 23 }}>
                                {
                                    viewAllDATA?.liveCourses?.courses?.items?.length > 0 ?
                                        viewAllDATA?.liveCourses?.courses?.items?.map((item) => {
                                            return <Card selectedCourse={selectedCourse} studentCount={item?.studentCount} description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} cardClick={() => { setUserids(null); storeResourceData(item); setWhichViewAllClicked('course'); history.push('/user-management/course-standards'); }} />
                                        })
                                        :
                                        <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <div className='r-c-c-c m-t-80'>
                                                <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                                <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                            </div>
                                        </center>
                                }
                            </div>
                        }

                        {selectedCourse === 'Video Courses' &&
                            <div className='display-flex flex-wrap' style={{ gap: 23 }}>
                                {viewAllDATA?.videoResources?.videoResources?.items?.length > 0 ?
                                    viewAllDATA?.videoResources?.videoResources?.items?.map((item) => (
                                        <Card selectedCourse={selectedCourse} studentCount={item?.studentCount} description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} cardClick={() => { setUserids(null); storeResourceData(item); setWhichViewAllClicked('videoResource'); history.push('/user/student'); }} />
                                    ))
                                    :
                                    <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <div className='r-c-c-c m-t-80'>
                                            <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                            <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                        </div>
                                    </center>
                                }
                            </div>
                        }

                        {selectedCourse === 'Study Materials' &&
                            <div className='display-flex flex-wrap' style={{ gap: 23 }}>
                                {viewAllDATA?.studyMaterials?.studyMaterials?.items?.length > 0 ?
                                    viewAllDATA?.studyMaterials?.studyMaterials?.items?.map((item) => (
                                        <Card selectedCourse={selectedCourse} studentCount={item?.studentCount} description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} cardClick={() => { setUserids(null); storeResourceData(item); setWhichViewAllClicked('studyMaterial'); history.push('/user/student'); }} />
                                    ))
                                    :
                                    <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <div className='r-c-c-c m-t-80'>
                                            <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                            <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                        </div>
                                    </center>
                                }
                            </div>
                        }

                        {selectedCourse === 'Online Test Series' &&
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 23 }}>
                                {viewAllDATA?.onlineTestSeries?.testSeries?.items?.length > 0 ?
                                    viewAllDATA?.onlineTestSeries?.testSeries?.items?.map((item) => (
                                        <Card selectedCourse={selectedCourse} studentCount={item?.studentCount}  description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage}  cardClick={() => { setUserids(null); storeResourceData(item); setWhichViewAllClicked('testSeries'); history.push('/user/student'); }} />
                                    )
                                    )
                                    :
                                    <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <div className='r-c-c-c m-t-80'>
                                            <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                            <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                        </div>
                                    </center>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
                {paginationComponent}
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { studentByCourseData, viewAllIs, viewAllDATA } = state.feeManagement;
    const { selectedCourse } = state.userManagementV2;

    const { courseSubCategoriesData, courseCategoryData, searchPageParamsObj } = state.courseManagement;
    return { studentByCourseData, courseSubCategoriesData, courseCategoryData, searchPageParamsObj, viewAllIs, selectedCourse, viewAllDATA };
};
const mapDispatchToProps = (dispatch) => ({
    getCoursesCategories: callback => dispatch(getCoursesCategories(callback)),
    getCoursesSubCategories: (id, callback) => dispatch(getCoursesSubCategories(id, callback)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    getSearchResultsData: callback => dispatch(getSearchResultsData(callback)),
    fetchCourseVA: (params, callback) => dispatch(fetchCourseVA(params, callback)),
    fetchVideoResourcesVA: (params, callback) => dispatch(fetchVideoResourcesVA(params, callback)),
    fetchTestSeriesVA: (params, callback) => dispatch(fetchTestSeriesVA(params, callback)),
    fetchStudyMaterialsVA: (params, callback) => dispatch(fetchStudyMaterialsVA(params, callback)),
    setWhichViewAllClicked: val => dispatch(setWhichViewAllClicked(val)),
    storeResourceData: res => dispatch(storeResourceData(res)),
    setUserids: userIds => dispatch(setUserids(userIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsideLiveCoursesStudents)