import {
  UPDATE_SCHEDULE,
  UPDATE_START_LOADER,
  UPDATE_CONF_STATUS,
  UPDATE_ACTIVE_CLASS,
  TOGGLE_FETCH_SCHEDULE_LOADER,
  UPDATE_LIVE_FILTER_PARAMS,
  CLEAR_LIVE_FILTER_PARAMS
} from "actions/today";
import moment from "moment";

const initialState = {
  schedules: [],
  startLoader: false,
  inConference: false,
  fetchScheduleLoader: false,
  standards: [],
  liveFilterParams: {
    date: moment().format("YYYY-MM-DD"),
    standardId: "-1",
    teacherId: null,
    teacherName: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LIVE_FILTER_PARAMS:
      return {
        ...state,
        liveFilterParams: {...initialState.liveFilterParams}
      };
    case UPDATE_LIVE_FILTER_PARAMS:
      return {
        ...state,
        liveFilterParams: {...state.liveFilterParams, ...action.filterParams}
      };
    case UPDATE_ACTIVE_CLASS:
      return {
        ...state,
        activeClass: action.value
      };
    case TOGGLE_FETCH_SCHEDULE_LOADER:
      return {
        ...state,
        fetchScheduleLoader: action.bool
      };
    case UPDATE_SCHEDULE:
      return {
        ...state,
        schedules: action.response.schedules,
        standards: action.response.standards
      };
    case UPDATE_START_LOADER:
      return {
        ...state,
        startLoader: action.value
      };
    case UPDATE_CONF_STATUS:
      return {
        ...state,
        inConference: action.status
      };
    default:
      return state;
  }
};

export default reducer;
