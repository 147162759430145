import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import { Api } from 'services';
import ReactPlayerHoc from 'components/ReactPlayerHoc';

const VideoPreview = ({ isVideoPreview, setVideoPreview,previewLink }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    console.log("fileUrl: ",previewLink);
    const handleCancel =()=>{
        setVideoPreview(false)
    }
    return (
        <Modal
            visible={isVideoPreview}
            onCancel={handleCancel}
            footer={null}
            width={1200}
            bodyStyle={{ height: "600px", backgroundColor: "#000000", marginTop: "-80px" }}
        >
            <div className='r-c-fs'>
                <div onClick={handleCancel} style={{cursor:"pointer"}}>
                    <img src={require("../../Assets/aulasMarket/ic-back-arrow.svg").default} />
                </div>
                <div className=' text-sm bold-600 m-l-10' style={{ color: "#FFFFFF" }}>
                    {/* Mathematics */}
                    <div className=' text-xs bold-400' style={{ color: "#FFFFFF", opacity: "50%" }}>
                        {/* XII - A: Science XI - A: Science */}
                        </div>
                </div>
            </div>
            <div className="" style={{ width: "100%", height: "100%" }}>

                <ReactPlayerHoc
                    ref={videoRef}
                    url={`${Api.dlS3Url}${previewLink}`}
                    playing={isPlaying}
                    keyboardControls={true}
                    controls
                    width="100%"
                    height="100%"
                />

                {/* <div className=" r-c-sa controls  m-l-30 m-t-10" style={{ width: "15vw" }}>

                    <div onClick={handlePlayPause} style={{ cursor: "pointer" }}>
                        <img style={{ width: 25 }} src={isPlaying ? pauseIcon : playIcon} />
                    </div>
                    <div onClick={handleRewind} style={{ cursor: "pointer" }}><img style={{ width: 25 }} src={require("../../Assets/aulasMarket/rewindIc.svg").default} /></div>
                    <div onClick={handleForward} style={{ cursor: "pointer" }}><img style={{ width: 25 }} src={require("../../Assets/aulasMarket/forwardIc.svg").default} /></div>
                </div> */}
            </div>
        </Modal>
    );
};

export default VideoPreview;
