import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Timeline, Popover } from "antd";
import moment from "moment";
import { CheckCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "components/Button";
import { getUserActivity, updateUserActivity } from "actions/profileNew";
import { setSelectedTest } from "actions/studentTest";
import { useHistory } from "react-router";
import { IMG_URL } from "../../env.json";
import "./styles.css";
import "../../design/layout.css";
import ReactHtmlParser from "react-html-parser";
import PageDataLoader from "components/pageDataLoader";
const NoData = require("../../Assets/ill-no-data.svg").default

const NoDataCard = () => {
  return (
    <div className='r-c-c-c'>
      <img src={NoData} />
      <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "18px" }}>No activities yet! try again later</div>
    </div>
  )
}

const Activities = ({
  getUserActivity,
  activity,
  meta,
  setPageLoader,
  setSelectedTest,
  specificUserId
}) => {

  const [page, setPage] = useState(1)
  const [isLoading, setisLoading] = useState(false)

  const params = {
    page,
    limit: 100,
    userId: specificUserId
  };


  useEffect(() => {
    setisLoading(true);
    if (page == 1) setPageLoader(true)

    getUserActivity(params, (page == 1 ? true : false), () => setisLoading(false), () => setPageLoader(false));
  }, [page]);


  //https://youtu.be/NZKUirTtxcg
  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (isLoading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && meta?.currentPage != meta?.totalPages)
        setPage(prevPage => prevPage + 1)
    })
    if (node) observer.current.observe(node)
  })


  const history = useHistory();

  const viewResult = (id) => {
    setSelectedTest({ id }); //only adding id, instead of whole test data
    history.push("/student-test/result");
  };

  console.log({ activity })


  return (
    <div>
      {activity?.length == 0 && !isLoading ? <h1>No activities found</h1> :
        activity?.map((activity_item, index) => (
          <div
            ref={
              (index == activity?.length - 1) ? lastElementRef : null
            }
          >
            <div
              style={{
                fontWeight: 800,
                fontSize: 16,
                marginBottom: 20,
                marginLeft: -10,
              }}
            >
              {activity_item.monthYear}
            </div>
            <Col>
              <Timeline>
                {activity_item?.items?.map((item, idx) => (
                  <Timeline.Item
                    dot={<CheckCircleFilled style={{ fontSize: 20 }} />}
                  >
                    <div className="r-afs" style={{ marginBottom: -15 }}>
                      {/* description */}
                      {ReactHtmlParser(item?.description, {
                        transform: (node) => {
                          if (
                            node.type === "tag" &&
                            node.name === "span" &&
                            node.attribs["data-value"]
                          ) {
                            return <TeX math={`${node.attribs["data-value"]}`} />;
                          }
                        },
                      })}

                      {/* test popover*/}
                      {item?.type == "test" && (
                        <div>
                          &nbsp;&nbsp;
                          <Popover
                            placement="right"
                            // title={text}
                            content={
                              <div>
                                <div className="r-jsb">
                                  {item?.meta?.info?.subjectNames?.map(
                                    (subject, idx) => (
                                      <div className="r-jsb">
                                        <strong>{subject}</strong>
                                        {idx !=
                                          item?.meta?.info?.subjectNames.length -
                                          1 && (
                                            <div>
                                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                                            </div>
                                          )}
                                      </div>
                                    )
                                  )}
                                </div>
                                <div
                                  className="r-jsb"
                                  style={{
                                    color: "#636363",
                                    fontSize: 12,
                                    // textAlign: "left",
                                    fontWeight: 500,
                                  }}
                                >
                                  {item?.meta?.info?.totalQuestions}Qs
                                  &nbsp;&#8226;&nbsp;
                                  {item?.meta?.info?.duration} mins
                                  &nbsp;&#8226;&nbsp;
                                  {item?.meta?.info?.totalMarks} marks
                                </div>
                              </div>
                            }
                            arrow={{ pointAtCenter: true }}
                          >
                            <InfoCircleOutlined style={{ color: "#1089FF" }} />
                          </Popover>
                        </div>
                      )}
                    </div>

                    {item?.meta?.resultPublished == 1 && (
                      <Button
                        type="link"
                        style={{
                          fontSize: 14,
                          marginLeft: -3,
                          marginBottom: -7,
                          marginTop: -20,
                        }}
                        onClick={() => viewResult(item?.meta?.testId)}
                      >
                        VIEW RESULT
                      </Button>
                    )}

                    {item?.type == "feePayment" && (
                      <a
                        style={{ fontSize: 14, fontWeight: 600 }}
                        href={IMG_URL + "/" + item?.meta?.invoice}
                        target="_blank"
                      >
                        VIEW INVOICE
                      </a>
                    )}

                    {/* timestamp */}
                    <div
                      style={{
                        color: "#AEAEAE",
                        fontSize: 12,
                        // textAlign: "left",
                        fontWeight: 500,
                      }}
                    >
                      {(item?.type == "feePayment" &&
                        item?.meta?.status == 'Paid' &&
                        item?.meta?.type == 'offline') ?
                        moment(item?.date).format("DD MMM YYYY") :
                        moment(item?.date).format("DD MMM YYYY, HH:mm")}

                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
          </div>
        ))
      }

      {isLoading && <h1>Loading...</h1>}

    </div>
  );
};

const mapStateToProps = (state) => {
  const { userActivity } = state.profileNew;
  const { specificUserId } = state.userManagementV2;
  return {
    activity: userActivity?.activity,
    meta: userActivity?.meta,
    specificUserId
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserActivity: (params, loadmore, callback, loader) =>
    dispatch(getUserActivity(params, loadmore, callback, loader)),
  setSelectedTest: (data) => dispatch(setSelectedTest(data)),
  updateUserActivity: (data) => dispatch(updateUserActivity(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
