import { Col, Modal, Row, TimePicker } from 'antd';
import { Input } from 'components/customInputs';
import { Heading } from 'components/Typography';
import React from 'react';
import {Button} from 'components/Button';


const EditAttendanceModal = ({
    visible, onClose
}) => {
    return (
        <Modal
            visible={visible}
            footer={false}
            className="modal-round-corner"
            onClose={onClose}
        >
            <div className='r-c-c m-t-20'>
                <Heading>Edit attendance</Heading>
            </div>
            <div className='p-20'>
                <div>Date</div>
                <Input disabled style={{width: "100%"}} />
            </div>
            <Row className='p-20' style={{paddingTop: 0}}>
                <Col span={12}>
                    <div>Clock in</div>
                    <TimePicker style={{width: "90%"}}  format="HH:mm"/>
                </Col>
                <Col span={12}>
                    <div>Clock out</div>
                    <TimePicker style={{width: "90%"}}  format="HH:mm"/>
                </Col>
            </Row>
            <div className='p-20' style={{paddingTop: 0}}>
                <div>Total hours</div>
                <Input disabled style={{width: "100%"}} />
            </div>

            <div className='r-c-c m-t-40'>
                <Button type="primary">UPDATE</Button>
            </div>

        </Modal>
    );
}

export default EditAttendanceModal;
