import {
  UPDATE_ASSIGNMENT_LIST,
  UPDATE_SUB_FILTER,
  TOGGLE_ASSIGNMENT_LOADER,
  SET_CURRENT_ASSIGNMENT,
  SET_CURRENT_ASSIGNMENT_TASK,
  TOGGLE_ASSIGNMENT_TASK_LOADER,
  UPDATE_FILTER_PARAMS,
  TOGGLE_ASSIGNMENT_MODAL,
  TOGGLE_QUESTION_MODAL,
  TOGGLE_INSTRUCTION_MODAL,
  UPDATE_NEW_STUDENT_ASSIGNMENT_LIST,
  UPDATE_POST_PARAMS,
  SET_LOCAL_STATUS_STUDENT,
  SET_CURRENT_QUESTION,
  REMOVE_CURRENT_QUESTION,
  SET_STUDENT_ASSIGNMENT,
  SET_CURRENT_STATEMENT,
  BOOKMARK_QUESTION,
  SET_ANSWER,
  SET_LOCAL_IMAGE,
  SET_REMOTE_IMAGE,
  UPDATE_ASSIGNMENT_SEARCH,
  SET_CURRENT_ANSWER,
  SET_CURRENT_IMAGE,
  SET_CURRENT_MARKS,
  SET_ANSWER_TYPE,
  SET_CURRENT_OPTIONS,
  SET_FINISH_MODE,
  UPDATE_STUDENT_LIST,
  UPDATE_TEACHER_PAGE,
  TOGGLE_ANS_LOADER,
  SET_STUDENT_ANSWERS,
  UPDATE_REVIEW_PARAMS,
  UPDATE_TOTAL_MARKS,
  TOGGLE_CORRECT_OPT,
  SET_OPTION_IMAGE,
  UPDATE_QUESTION_FEEDBACK,
  SET_QUESTIONS_FEEDBACK,
  TOGGLE_RESULT_LOADER,
  TOGGLE_REMINDER_LOADER,
  TOGGLE_REVIEW_LOADER,
  SET_CURRENT_ASSIGNMENT_PARAMS,
  TOGGLE_STATUS_LOADER,
  UPDATE_STUDENT_ASSIGNMENT_STATUS,
  UPDATE_CSS,
  UPLOAD_IMG_ARR,
  REMOVE_UPLOADED_IMG,
  GET_REMOVE_RESPONSE,
  SET_CKEDITOR_STATEMENT,
  SET_CKEDITOR_OPTION,
  CLEAR_CKEDITOR_STATEMENT,
  UPDATE_IMAGE_LIST,
  READ_QUILL_EDITOR_TEXT,
  DISPLAY_OPTION_MODAL,
  DISPLAY_OPTION_INDEX_READ,
  SET_OPTION_VALUE,
  UPDATE_STUDENT_ASSIGNMENT_LIST,
  SET_STUDENT_ASSIGNMENT_TASKS,
  REPLACE_ANSWER_IMAGE,
  DELETE_ANSWER_IMAGE,
  UPDATE_DONUT_DATA,
  UPDATE_SUBMIT_DETAILS,
  ANSWERING_LOADER_TOGGLE,
  SUBMIT_MODAL_TOGGLE,
  UPDATE_Q_TIME,
  SET_STUDENT_QUESTION_INDEX,
  SET_STUDENT_ID,
  UPDATE_SEARCHED_CHAPTERS,
  UPDATE_SELECTED_TAB,
  TOGGLE_VIEW_Q_MODAL,
  RESET_DELETED_ASSIGNMENT_IMAGES,
  UPDATE_REVIEW_TIME,
  TOGGLE_TEACHER_ASSIGNMENT_REMINDER_LOADER
} from "../actions/assignment";

import { constAssignList } from "../pages/Assignment/const";

const initialState = {
  testState: "yolo",
  studentId: 0,
  studentAssignmentList: [],
  answeringLoader: false,
  submittedModal: false,
  studentNewAssignmentList: [],
  studentCurrentAssignment: {},
  studentCurrentSubmitData: {},
  donutData: {
    submitted: 0,
    graded: 0,
    inProgress: 0,
    total: 0,
    new: 0,
    pending: 0
  },
  studentCurrentAssignmentTasks: [],
  imageRemoteList: [],
  subjectListStudent: [],
  searchValue: "",
  statusLoader: false,
  answerLoader: false,
  studentResponse: {},
  assignmentLoader: false,
  assignmentTaskLoader: false,
  assignmentList: [],
  currentAssignment: {},
  showQuestionModal: false,
  showInstructionModal: false,
  showConfirmModal: false,
  confirmAction: false,
  currentQuestionData: false,
  finishMode: false,
  assignmentTaskParams: {
    statement: "",
    image: false,
    type: "Short",
    answer: "",
    positiveMarks: 0,
    negativeMarks: 0,
    details: "",
    remarks: ""
  },

  assignmentPageTaskDetails: false,

  answerTypeId: 1,

  assignmentTaskOptions: [],

  aer2: [],
  currentAssignmentTasks: [],
  filterStudentParams: {
    standard: -1,
    subject: null,
    subjectName: "",
    teacher: null,
    teacherName: "",
    publishDate: null,
    dueDate: null,
    sections: [],
    selectedStd: -1,
    currentPage: 1,
    status: "Draft"
  },
  filterParams: {
    standard: -1,
    subject: null,
    subjectName: "",
    teacher: null,
    teacherName: "",
    publishDate: null,
    dueDate: null,
    sections: [],
    selectedStd: -1,
    currentPage: 1,
    status: "Draft"
  },
  postParams: {
    standardIds: [],
    selectedSubject: "",
    subjectId: null,
    selectedTeacher: "",
    teacherId: null,
    gradingType: "Ungraded",
    deadlineDate: null,
    resultDueDate: null,
    title: "",
    instructions: "",
    chapterIds: [],
    chapterNames: [],
    cloneAssignmentId: null
  },
  standards: [],
  pageMeta: {},
  showAssignmentModal: false,
  studentList: [],
  graded: 0,
  submitted: 0,
  studentQIndex: 0,
  teacherCurrentPage: 1,
  studentReview: [],
  saveButton: false,
  imageUrl: null,
  optionsImage: [],
  questionsFeedback: {},
  resultLoader: false,
  reminderLoader: false,
  reviewLoader: false,
  uploadedImgArrData: [],
  getRemoveResponseBool: false,
  updateCssBool: null,
  ckeditorStatement: "",
  ckeditorOption: "",
  questionImagesList: [],
  optionsImagesList: [[], [], [], []],
  deletedImages: [],
  oldImageList: [],
  quillEditorText: {},
  displayOptionModalBool: false,
  displayOptionIndex: "",
  searchedChaptersList: [],
  selectedTab: "Question",
  viewQModal: false,
  deletedAssignmentImages: [],
  assignmentTeacherReminderLoader: false
};

const reducer = (state = initialState, action) => {
  let reviews, review;
  switch (action.type) {
    case TOGGLE_TEACHER_ASSIGNMENT_REMINDER_LOADER:
        return {
          ...state,
          assignmentTeacherReminderLoader: action.bool
        };
    case RESET_DELETED_ASSIGNMENT_IMAGES:
      return {
        ...state,
        deletedAssignmentImages: []
      };
    case TOGGLE_VIEW_Q_MODAL:
      return {
        ...state,
        viewQModal: action.bool
      };
    case UPDATE_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.key
      };
    case UPDATE_ASSIGNMENT_LIST:
      return {
        ...state,
        assignmentList:
          action.payload && action.payload.assignments
            ? action.payload.assignments
            : constAssignList.assignments,
        standards: action.payload ? action.payload.standards : [],
        pageMeta: action.payload ? action.payload.pageMeta : {}
      };
    case UPDATE_SEARCHED_CHAPTERS:
      return {
        ...state,
        searchedChaptersList: [...action.chapters]
      };
    case SET_OPTION_VALUE:
      const options = state.assignmentTaskOptions;
      if (options && options[action.index]) {
        options[action.index].value = action.data;
      }
      return {
        ...state,
        assignmentTaskOptions: [...options]
      };
    case UPLOAD_IMG_ARR:
      // let arr = state.uploadedImgArrData.slice(0);

      // arr.push(action.obj);
      // console.log('arr ++++++',arr);
      return {
        ...state,
        uploadedImgArrData: action.obj
      };

    case UPDATE_IMAGE_LIST:
      let newList = [];
      if (action.index !== null) {
        newList = state.imagesList;
        newList.splice(action.index, 1);
      }
      return {
        ...state,
        imagesList: newList
      };
    case REMOVE_UPLOADED_IMG:
      let deleteArrIndex = state.uploadedImgArrData;
      deleteArrIndex.splice(action.index, 1);
      return {
        ...state,
        uploadedImgArrData: deleteArrIndex
      };
    case CLEAR_CKEDITOR_STATEMENT:
      return {
        ...state,
        ckeditorStatement: ""
      };
    case SET_CKEDITOR_STATEMENT:
      return {
        ...state,
        ckeditorStatement: action.entities
      };
    case SET_CKEDITOR_OPTION:
      return {
        ...state,
        ckeditorOption: action.entities
      };
    case GET_REMOVE_RESPONSE:
      return {
        ...state,
        getRemoveResponseBool: action.bool
      };
    case UPDATE_CSS:
      return {
        ...state,
        updateCssBool: action.bool
      };
    case UPDATE_SUB_FILTER:
      return {
        ...state,
        subjectListStudent: action.value
      };
    case SET_STUDENT_QUESTION_INDEX:
      return {
        ...state,
        studentQIndex: action.value
      };
    case UPDATE_DONUT_DATA:
      return {
        ...state,
        donutData: { ...action.data }
      };
    case TOGGLE_ASSIGNMENT_LOADER:
      return {
        ...state,
        assignmentLoader: action.value
      };
    case ANSWERING_LOADER_TOGGLE:
      return {
        ...state,
        answeringLoader: action.value
      };
    case SUBMIT_MODAL_TOGGLE:
      return {
        ...state,
        submitModal: action.value
      };
    case SET_FINISH_MODE:
      return {
        ...state,
        finishMode: action.value
      };
    case SET_STUDENT_ID:
      return {
        ...state,
        studentId: action.value
      };
    case SET_ANSWER:
      state.studentCurrentAssignmentTasks[action.index].selectedKey =
        action.answer;
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case UPDATE_REVIEW_TIME:
      state.studentCurrentAssignment.reviewDuration = action.value;
      return {
        ...state,
        studentCurrentAssignment: state.studentCurrentAssignment
      };
    case UPDATE_Q_TIME:
      state.studentCurrentAssignmentTasks[action.index].duration = action.value;
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case BOOKMARK_QUESTION:
      state.studentCurrentAssignmentTasks[action.index].markedForReview = !state
        .studentCurrentAssignmentTasks[action.index].markedForReview;
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case SET_LOCAL_IMAGE:
      if (!state.studentCurrentAssignmentTasks[action.index].files) {
        state.studentCurrentAssignmentTasks[action.index].files = [];
      }
      state.studentCurrentAssignmentTasks[action.index].files.push({
        local: action.file,
        localShowImg: action.url
      });
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case SET_REMOTE_IMAGE:
      state.studentCurrentAssignmentTasks[action.index].files = [];
      state.studentCurrentAssignmentTasks[action.index].selectedKey = [
        ...action.files
      ];
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case DELETE_ANSWER_IMAGE:
      if (action.kind === "new") {
        // state.deletedAssignmentImages.push(
        //   state.studentCurrentAssignmentTasks[action.index].files[
        //     action.imageIndex
        //   ].remote.value
        // );
        state.studentCurrentAssignmentTasks[action.index].files.splice(
          action.imageIndex,
          1
        );
      }
      if (action.kind === "old") {
        state.deletedAssignmentImages.push(
          state.studentCurrentAssignmentTasks[action.index].selectedKey[
            action.imageIndex
          ].value
        );
        state.studentCurrentAssignmentTasks[action.index].selectedKey.splice(
          action.imageIndex,
          1
        );
        if (
          !state.studentCurrentAssignmentTasks[action.index].selectedKey.length
        ) {
          state.studentCurrentAssignmentTasks[action.index].selectedKey = null;
        }
      }
      // if (!state.studentCurrentAssignmentTasks[action.index].delFCount) {
      //   state.studentCurrentAssignmentTasks[action.index].delFCount = 1;
      // } else {
      //   state.studentCurrentAssignmentTasks[action.index].delFCount += 1;
      // }
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case REPLACE_ANSWER_IMAGE:
      state.studentCurrentAssignmentTasks[action.index].files.splice(
        action.imageIndex,
        1
      );
      return {
        ...state,
        studentCurrentAssignmentTasks: state.studentCurrentAssignmentTasks.slice(
          0
        )
      };
    case SET_STUDENT_ASSIGNMENT:
      return {
        ...state,
        studentCurrentSubmitData: {},
        studentCurrentAssignment: {
          ...action.value
        }
      };
    case SET_LOCAL_STATUS_STUDENT:
      state.studentCurrentAssignment.assignmentStatus = action.value;
      return {
        ...state,
        studentCurrentAssignment: state.studentCurrentAssignment
      };
    case UPDATE_SUBMIT_DETAILS:
      return {
        ...state,
        studentCurrentSubmitData: Object.assign({}, action.data)
      };
    case SET_STUDENT_ASSIGNMENT_TASKS:
      return {
        ...state,
        studentCurrentAssignmentTasks: action.value
      };
    case UPDATE_STUDENT_ASSIGNMENT_LIST:
      return {
        ...state,
        studentAssignmentList: action.value
      };
    case UPDATE_NEW_STUDENT_ASSIGNMENT_LIST:
      return {
        ...state,
        studentNewAssignmentList: action.value
      };
    case TOGGLE_REMINDER_LOADER:
      return {
        ...state,
        reminderLoader: action.value
      };
    case TOGGLE_REVIEW_LOADER:
      return {
        ...state,
        reviewLoader: action.value
      };
    case TOGGLE_STATUS_LOADER:
      return {
        ...state,
        statusLoader: action.value
      };
    case TOGGLE_RESULT_LOADER:
      return {
        ...state,
        resultLoader: action.value
      };
    case SET_CURRENT_ASSIGNMENT_PARAMS:
      return {
        ...state,
        currentAssignment: {
          ...state.currentAssignment,
          ...action.currentAssignment
        }
      };
    case SET_QUESTIONS_FEEDBACK:
      return {
        ...state,
        questionsFeedback: action.value
      };
    case UPDATE_REVIEW_PARAMS:
      review = state.studentReview[action.index];
      review = { ...review, ...action.param };
      state.studentReview[action.index] = review;
      let allChecked = true;
      state.studentReview.forEach((item, index) => {
        if (
          item.correct === null &&
          !(
            state.studentResponse.questions[index].answer === null &&
            state.studentResponse.questions[index].filename === null
          )
        ) {
          allChecked = false;
          return;
        }
      });
      return {
        ...state,
        studentReview: [...state.studentReview],
        saveButton: allChecked
        // studentResponse: {...studentResponse, }
      };
    case UPDATE_QUESTION_FEEDBACK:
      review = state.questionsFeedback[action.index];
      review = { ...review, ...action.param };
      state.questionsFeedback[action.index] = review;
      return {
        ...state,
        questionsFeedback: { ...state.questionsFeedback }
      };
    case TOGGLE_ANS_LOADER:
      return {
        ...state,
        answerLoader: action.value
      };
    case SET_STUDENT_ANSWERS:
      reviews = action.response.questions
        ? action.response.questions.map(item => ({
            assignmentTaskId: item.id,
            score: item.marksObtained,
            comment: item.comment,
            correct: item.correct,
            sent: false
          }))
        : [];
      const answered = action.response.questions
        ? action.response.questions.filter(
            (item, index) => !(item.answer === null && item.filename === null)
          )
        : false;
      return {
        ...state,
        studentResponse: action.response,
        studentReview: [...reviews],
        saveButton: !answered.length ? true : false
      };
    case UPDATE_TOTAL_MARKS:
      return {
        ...state,
        studentResponse: {
          ...state.studentResponse,
          totalMarksObtained: action.totalMarksObtained
        }
      };
    case UPDATE_TEACHER_PAGE:
      return {
        ...state,
        teacherCurrentPage: action.page
      };
    case UPDATE_STUDENT_LIST:
      return {
        ...state,
        studentList: action.studentList,
        graded: action.graded,
        submitted: action.submitted,
        totalStudents: action.totalStudents
      };
    case UPDATE_STUDENT_ASSIGNMENT_STATUS:
      const index = state.studentList.map(item => item.id).indexOf(action.index);
      const student = {
        ...state.studentList[index],
        score: action.marks,
        status: action.status
      };
      state.studentList[index] = student;
      const gradedCount = state.studentList.filter(
        item => item.status === "Graded"
      ).length;
      return {
        ...state,
        studentList: [...state.studentList],
        graded: gradedCount
      };
    case TOGGLE_ASSIGNMENT_MODAL:
      return {
        ...state,
        showAssignmentModal: action.state
      };
    case TOGGLE_QUESTION_MODAL:
      return {
        ...state,
        showQuestionModal: action.state
      };
    case UPDATE_ASSIGNMENT_SEARCH:
      return {
        ...state,
        searchValue: action.value
      };
    case TOGGLE_INSTRUCTION_MODAL:
      return {
        ...state,
        showInstructionModal: action.state
      };
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: { ...state.filterParams, ...action.filterParams }
      };
    case UPDATE_POST_PARAMS:
      return {
        ...state,
        postParams: { ...state.postParams, ...action.postParams }
      };
    case TOGGLE_ASSIGNMENT_TASK_LOADER:
      return {
        ...state,
        assignmentTaskLoader: action.value
      };
    case SET_CURRENT_ASSIGNMENT:
      return {
        ...state,
        currentAssignment: {...action.data }

      };
    case SET_CURRENT_ASSIGNMENT_TASK:
      const feedback = {};
      const currentFeedback = state.questionsFeedback;
      if (action.assignmentTasks) {
        action.assignmentTasks.forEach(item => {
          if (item.markedForReview || item.remark) {
            feedback[item.id] = {
              markedForReview:
                currentFeedback[item.id] &&
                currentFeedback[item.id].markedForReview !== undefined
                  ? currentFeedback[item.id].markedForReview
                  : item.markedForReview,
              comment:
                currentFeedback[item.id] &&
                currentFeedback[item.id].comment &&
                currentFeedback[item.id].comment !== ""
                  ? currentFeedback[item.id].comment
                  : item.remark
            };
          }
        });
      }
      return {
        ...state,
        questionsFeedback: { ...currentFeedback, ...feedback },
        currentAssignmentTasks: action.assignmentTasks
          ? [...action.assignmentTasks]
          : [],
        assignmentPageTaskDetails: action.pageMeta
        // ckeditorStatement:''
      };
    case SET_OPTION_IMAGE:
      state.optionsImage[action.index] = action.image;
      state.assignmentTaskParams[`fileoption${action.index + 1}`] =
        action.value;
      return {
        ...state,
        assignmentTaskParams: { ...state.assignmentTaskParams },
        optionsImage: { ...state.optionsImage }
      };
    case SET_CURRENT_QUESTION:
      return {
        ...state,
        assignmentTaskOptions: action.data.options || [],
        assignmentTaskParams: {
          ...state.assignmentTaskParams,
          ...action.data,
          oldMarks: action.data.positiveMarks
        },
        currentQuestionData: action.data,
        answerTypeId:
          action.data.type === "MCQ"
            ? 1
            : action.data.type === "Subjective"
            ? 0
            : 2
      };
    case REMOVE_CURRENT_QUESTION:
      delete state.assignmentTaskParams.id;
      return {
        ...state,
        assignmentTaskOptions: [],
        assignmentTaskParams: {
          ...state.assignmentTaskParams,
          statement: "",
          image: "",
          // type: 'Short',
          answer: "",
          positiveMarks: 0,
          negativeMarks: 0,
          details: "",
          remarks: "",
          fileoption1: null,
          fileoption2: null,
          fileoption3: null,
          fileoption4: null
        },
        currentQuestionData: false,
        imageUrl: null,
        optionsImage: []
      };
    case SET_CURRENT_STATEMENT:
      state.assignmentTaskParams.statement = action.value;
      return {
        ...state,
        assignmentTaskParams: {
          ...state.assignmentTaskParams
        }
      };
    case SET_CURRENT_ANSWER:
      state.assignmentTaskParams.answer = action.value;
      return {
        ...state,
        assignmentTaskParams: {
          ...state.assignmentTaskParams
        }
      };
    case TOGGLE_CORRECT_OPT:
      state.assignmentTaskOptions = state.assignmentTaskOptions.map(item => {
        return { ...item, correct: false };
      });
      state.assignmentTaskOptions[action.index].correct = true;
      return {
        ...state,
        assignmentTaskOptions: state.assignmentTaskOptions.slice(0)
      };
    case SET_CURRENT_IMAGE:
      state.assignmentTaskParams.image = action.value;
      return {
        ...state,
        assignmentTaskParams: {
          ...state.assignmentTaskParams
        },
        imageUrl: action.image
      };
    case SET_CURRENT_MARKS:
      state.assignmentTaskParams.positiveMarks = action.value;
      return {
        ...state,
        assignmentTaskParams: {
          ...state.assignmentTaskParams
        }
      };
    case SET_ANSWER_TYPE:
      state.assignmentTaskParams.type = action.value;
      return {
        ...state,
        answerTypeId: action.id,
        assignmentTaskParams: {
          ...state.assignmentTaskParams
        }
      };
    case SET_CURRENT_OPTIONS:
      state.assignmentTaskParams["options"] = action.value;
      return {
        ...state,
        assignmentTaskParams: {
          ...state.assignmentTaskParams
        },
        assignmentTaskOptions: action.value
      };
    case READ_QUILL_EDITOR_TEXT:
      let quillEditorText = state.quillEditorText;
      if (action.id === "statement") {
        quillEditorText.statement = action.data;
        console.log("1111");
      } else if (action.id === "option1") {
        quillEditorText.option1 = action.data;
        console.log("22222");
      } else if (action.id === "option2") {
        quillEditorText.option2 = action.data;
        console.log("33333");
      } else if (action.id === "option3") {
        quillEditorText.option3 = action.data;
        console.log("44444");
      } else if (action.id === "option4") {
        quillEditorText.option4 = action.data;
        console.log("55555");
      }
      // console.log('action.data!!',action.data);
      // console.log('action.id!!',action.id);
      // console.log('quillEditorText!!',quillEditorText);
      return {
        ...state,
        quillEditorText: { ...quillEditorText }
      };
    case DISPLAY_OPTION_MODAL:
      return {
        ...state,
        displayOptionModalBool: action.bool
        // displayOptionIndex: action.index,
      };
    case DISPLAY_OPTION_INDEX_READ:
      return {
        ...state,
        // displayOptionModalBool: action.bool,
        displayOptionIndex: action.index
      };
    default:
      return state;
  }
};

export default reducer;
