import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";

const TeacherBarChart = ({data}) => {
 let temp=[];
 for(let i=0;i<=data?.length-1;i++){
  let obj1={
    name:"Present %",
    range:parseInt(`${data[i]?.presentPercentage}`),
    unit:`${data[i]?.unit}`,
    count:`${data[i]?.presentCount}`,
  }
   let obj2={
     name:"Absent %",
     range:parseInt(`${data[i]?.absentPercentage}`),
     unit:`${data[i]?.unit}`,
    count:`${data[i]?.absentCount}`,
   }
   
  let obj3={
    name:"Leave %",
    range:parseInt(`${data[i]?.leavePercentage}`),
    unit:`${data[i]?.unit}`,
    count:`${data[i]?.leaveCount}`,

  }
  temp.push(obj1,obj2,obj3) 
 }
  var config = {
    data:temp,
    isGroup: true,
    xField: "unit",
    yField: "range",
    seriesField: "name",
    tooltip:
    {customContent:(val,item)=>{
      return(
        <div style={{width:"140px",height:"80px",background:" #FFFFFF",boxShadow:" 4px 4px 18px #302A5F14",borderRadius:"8px",margin:"-12px",padding:"10px"}}>
        <div style={{display:"flex",justifyContent:"space-around",}}>
        <div style={{color:" #000000",fontSize:"14px"}}>Present</div>
        <div style={{color:" #000000",fontSize:"14px",fontWeight:"bold"}}>{item[0]?.data?.count}</div>
        </div>
        <div style={{display:"flex",justifyContent:"space-around",marginTop:"10px"}}>
        <div style={{color:" #000000",fontSize:"14px"}}>Absent</div>
        <div style={{color:" #000000",fontSize:"14px",fontWeight:"bold"}}>{item[1]?.data?.count}</div>
        </div>
        <div style={{display:"flex",justifyContent:"space-around",marginTop:"10px"}}>
        <div style={{color:" #000000",fontSize:"14px"}}>Leave</div>
        <div style={{color:" #000000",fontSize:"14px",fontWeight:"bold"}}>{item[2]?.data?.count}</div>
        </div>
        </div>
      )
    }
    },
    columnStyle: {
      radius: [3, 3, 0, 0],
      
    },
    

    color: ["#28DF99", "#FF414D","#1089FF"],
    legend: false,
  };
  return <Column {...config} />;
};
export default TeacherBarChart

