const Styles = {
  toolbarContainer: {
    top: '45%',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '1px 3px 10px #d4d3d3',
    position:"absolute",
    marginLeft: '20px',
    zIndex:100,
    paddingTop: 10,
    paddingBottom: 10
  },
  toolbarBtn: {
    height: 30,
    width:30,
    margin: 10,
    marginBottom: 2,
    cursor: 'pointer'
  },
  colorPickerWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom:10
  },
  colorPickerBtn: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: 'green',
    marginRight:10,
    border:"none"
  },
  shadow: {
    borderRadius: 10,
    position:"absolute",
    zIndex:15,
  }
};

export default Styles;
