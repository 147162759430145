import { DatePicker, Popover, Select, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import { CalendarOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import image1 from "Assets/care/ic-sort.svg";
import { connect } from 'react-redux';
import moment from 'moment';
import { Column } from '@ant-design/plots';
import { selectMonth, storeSummaryType, storeSummaryYear, storeUserSummaryEndDate, storeUserSummaryStartDate } from 'actions/attendanceNew';
import { setSingleBarData, storeSummaryMonth } from 'actions/aulasWallet';
import { NodataCard } from 'pages/classRoom';

const { Option } = Select;

function FeeSummary({ feeOverviewData, togglePageLoader, getWalletFeeOverViewData, storeSummaryType, summaryTypeContainer, storeUserSummaryStartDate, summaryStartDateContainer, storeUserSummaryEndDate, summaryEndDateContainer, storeSummaryMonth, summaryMonth, storeSummaryYear, summaryYearContainer, setSingleBarData, singleBarData, user }) {
    useEffect(() => {
        setPeriod("week")
        storeSummaryType("week");
        storeUserSummaryStartDate(moment().startOf("week").format("DD/MM/YYYY"));
        storeUserSummaryEndDate(moment().format("DD/MM/YYYY"));
        storeSummaryMonth(null);
        storeSummaryYear(null)
    }, [])
    function numberWithCommas(x) {
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
    }
    const paymentDetailsArray = [
        {
            color: "#FFA931",
            totalCount: feeOverviewData?.pendingTransactionsDetails?.count,
            text: "pending",
            amount: feeOverviewData?.pendingTransactionsDetails?.amount,
            todayCount: feeOverviewData?.pendingTransactionsDetails?.requestedToday
        },
        {
            color: "#28DF99",
            totalCount: feeOverviewData?.paidTransactionsDetails?.count,
            text: " paid",
            amount: feeOverviewData?.paidTransactionsDetails?.amount,
            todayCount: feeOverviewData?.paidTransactionsDetails?.requestedToday
        },
        {
            color: "#FF414D",
            totalCount: feeOverviewData?.overdueTransactionsDetails?.count,
            text: " overdue",
            amount: feeOverviewData?.overdueTransactionsDetails?.amount,
            todayCount: feeOverviewData?.overdueTransactionsDetails?.requestedToday
        },
        {
            color: "#636363",
            totalCount: feeOverviewData?.cancelledTransactionsDetails?.count,
            text: " cancelled",
            amount: feeOverviewData?.cancelledTransactionsDetails?.amount,
            todayCount: feeOverviewData?.cancelledTransactionsDetails?.requestedToday
        },
        {
            color: "#1089FF",
            totalCount: feeOverviewData?.refundedTransactionsDetails?.count,
            text: " refunded",
            amount: feeOverviewData?.refundedTransactionsDetails?.amount,
            todayCount: feeOverviewData?.refundedTransactionsDetails?.requestedToday
        },
    ];
    let [graphData, setGraphData] = useState([]);
    let [sum, setSum] = useState(0);
    useEffect(() => {
        graphData = [];
        sum = 0;
        if (feeOverviewData?.summaryGraph?.length != 0) {
            feeOverviewData?.summaryGraph?.map(
                (item) => (
                    (sum = sum + parseFloat(item?.amount)),
                    graphData.push({
                        type: "Payment Requested",
                        short_date: item?.short_date,
                        Payment_Requested: parseFloat(item?.amount),
                        totalCount: item?.count,
                        sameStartEndDate: item?.date,
                        weekStartDate: item?.weekStartDate,
                        weekEndDate: item?.weekEndDate,
                        month: item?.month,
                        year: item?.year
                    })
                )

            );
        }
        setSum(sum);
        setGraphData([...graphData]);
    }, [feeOverviewData?.summaryGraph]);

    const config = {
        data: graphData,
        isGroup: true,
        xField: "short_date",
        yField: "Payment_Requested",
        seriesField: "type",
        tooltip: {
            customContent: (val, item) => {
                function numberWithCommas(x) {
                    return (x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString());
                }
                return (
                    <div>
                        <div style={{ marginLeft: "-20px", marginRight: "-20px", alignItems: "center", height: "50px", width: "190px" }}>
                            <div style={{ fontWeight: "bold", color: "#000000", fontSize: "14px", fontFamily: "Roboto", marginTop: "15px", marginLeft: "20px" }}>{item[0]?.data?.totalCount ? item[0]?.data?.totalCount : 0}{""}{" "}{" "}payment requested</div>
                            <div style={{ fontWeight: "bold", color: "#000000", fontSize: "14px", fontFamily: "Roboto", marginTop: "15px", marginLeft: "20px" }}>₹  {numberWithCommas(item[0]?.data?.Payment_Requested ? item[0]?.data?.Payment_Requested : 0)}</div>
                        </div>
                    </div>
                )
            }
        },
        columnStyle: {
            radius: [3, 3, 0, 0],
        },
        meta: {
            Percentage: { alias: "Percentage(%)" },
        },
        color: ["#9876E6", "#FEA5DD"],
        legend: false,
        xAxis: {
            label: {
                formatter: (text, data, index) => {
                    return `${text}`
                },
            },
        },
    };
    const [period, setPeriod] = useState("")
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.chart;
            chartInstance.on('element:click', (evt) => {
                const { data } = evt;
                if (period === 'week') {
                    setSingleBarData({
                        period: "week",
                        startDate: data?.data?.sameStartEndDate,
                        endDate: data?.data?.sameStartEndDate,
                        userId: user?.id
                    })
                } else if (period === 'month') {
                    setSingleBarData({
                        period: "week",
                        startDate: data?.data?.weekStartDate,
                        endDate: data?.data?.weekEndDate,
                        userId: user?.id
                    })
                } else if (period === 'year') {
                    setSingleBarData({
                        period: "month",
                        month: data?.data?.month,
                        year: data?.data?.year,
                        userId: user?.id
                    })
                }
                togglePageLoader(true);
                getWalletFeeOverViewData(true, () => {
                    togglePageLoader(false)
                })
            });
        }
    }, [period]);

    const handleChange = (value) => {
        setPeriod(value)
        storeSummaryType(value);
        if (value === 'week') {
            storeUserSummaryStartDate(moment().startOf(value).format("DD/MM/YYYY"))
            storeUserSummaryEndDate(moment().format("DD/MM/YYYY"));
        } else if (value === 'month') {
            storeSummaryMonth(moment().format('MMMM'));
            storeSummaryYear(moment().format('YYYY'));
            storeUserSummaryStartDate(null);
            storeUserSummaryEndDate(null)
        } else if (value === 'year') {
            storeSummaryYear(moment().format('YYYY'));
            storeSummaryMonth(null);
            storeUserSummaryStartDate(null);
            storeUserSummaryEndDate(null)
        } else {
            storeUserSummaryStartDate(null);
            storeUserSummaryEndDate(null);
            storeSummaryMonth(null);
            storeSummaryYear(null);
        }
        togglePageLoader(true)
        getWalletFeeOverViewData(false, () => {
            togglePageLoader(false)
        })
    }
    const disableFutureDate = (current) => {
        const yesterday = moment()
        return !(yesterday.isAfter(current));
    }
    const disabledMonth = (current) => {
        return current && current > moment().endOf('month');
    };
    const disabledYear = (current) => {
        return current.year() > moment().year();
    };
    return (
        <div>
            <div className='r-c-sb'>
                <div>
                    <div className='font' style={{ fontSize: "16px", color: "#191919" }}>Overview</div>
                    <div className="font_roboto" style={{ fontSize: "12px", color: "#636363", fontWeight: 500 }}>
                        {summaryTypeContainer === "week" ? (
                            `${summaryStartDateContainer} - ${summaryEndDateContainer}`
                        ) : summaryTypeContainer === "month" ? (
                            `${summaryMonth}, ${summaryYearContainer}`
                        ) : summaryTypeContainer === "year" ? (
                            `${summaryYearContainer}`
                        ) : null}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", maxWidth: "250px", marginRight: 20 }}>
                        {summaryTypeContainer === 'week' ?
                            <DatePicker.RangePicker
                                format={'DD/MM/YYYY'}
                                getPopupContainer={(trigger) => trigger.parentElement}
                                allowClear={false}
                                value={[summaryStartDateContainer ? moment(summaryStartDateContainer, "DD/MM/YYYY") : null, summaryEndDateContainer ? moment(summaryEndDateContainer, "DD/MM/YYYY") : null]}
                                onChange={(arg, value) => {
                                    if (value[0] !== "" && value[1] !== "") {
                                        storeUserSummaryStartDate(value[0]);
                                        storeUserSummaryEndDate(value[1]);
                                        togglePageLoader(true)
                                        getWalletFeeOverViewData(false, () => {
                                            togglePageLoader(false)
                                        })
                                    } else {
                                        storeUserSummaryStartDate(moment().startOf('week').format("DD/MM/YYYY"));
                                        storeUserSummaryEndDate(moment().format("DD/MM/YYYY"));
                                    }
                                }}
                                disabledDate={disableFutureDate}
                            />
                            :
                            summaryTypeContainer === 'month' ?
                                <DatePicker
                                    allowClear={false}
                                    value={summaryMonth ? (moment(`${summaryMonth} ${summaryYearContainer}`, 'MMMM YYYY')) : null}
                                    defaultValue={summaryMonth ? (moment(summaryMonth, "MMMM")) : null}
                                    onChange={(date, value) => {
                                        if (date) {
                                            storeSummaryMonth(value);
                                            storeSummaryYear(date.year())
                                            togglePageLoader(true)
                                            getWalletFeeOverViewData(false, () => {
                                                togglePageLoader(false)
                                            })
                                        }
                                    }}
                                    picker="month"
                                    format={"MMMM"}
                                    disabledDate={disabledMonth}
                                />
                                :
                                summaryTypeContainer === "year" ?
                                    <DatePicker
                                        allowClear={false}
                                        value={summaryYearContainer ? (moment(summaryYearContainer, "YYYY")) : null}
                                        format={"YYYY"}
                                        onChange={(args, value) => {
                                            storeSummaryYear(value)
                                            togglePageLoader(true)
                                            getWalletFeeOverViewData(false, () => {
                                                togglePageLoader(false)
                                            })
                                        }}
                                        picker="year"
                                        disabledDate={disabledYear}
                                    />
                                    :
                                    <></>
                        }
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "start",
                        }}
                    >
                        <div style={{ width: "150px" }} >
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
                                <div><img style={{ width: "50px", height: "15px" }} src={image1} alt="" /></div>
                                <Select
                                    className="inputSelecter"
                                    defaultValue={"week"}
                                    style={{ width: "80%", border: "none" }}
                                    onChange={handleChange}
                                    bordered={false}
                                    removeIcon={true}
                                    dropdownMatchSelectWidth={true}
                                >
                                    <Option value="week">Week</Option>
                                    <Option value="month">Month</Option>
                                    <Option value="year">Year</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "30%" }}>
                    <div style={{ marginTop: "100px" }}>
                        <div className="r-ac">
                            <div className="dot" style={{ marginRight: "3px" }}></div>
                            <div style={{ color: "#594099" }}>
                                <span className="bold-700">
                                    {" "}
                                    {
                                        feeOverviewData?.allTransactionsDetails?.count
                                    }
                                </span>{" "}
                                {feeOverviewData?.allTransactionsDetails?.count <= 1
                                    ? "Payment"
                                    : "Payments"}{" "}
                                requested
                            </div>
                        </div>
                        <div className="bold-700" style={{ fontSize: 24 }}>
                            ₹{" "}
                            {
                                feeOverviewData?.allTransactionsDetails?.amount ?
                                    feeOverviewData?.allTransactionsDetails?.amount &&
                                    numberWithCommas(feeOverviewData?.allTransactionsDetails?.amount) : "0"
                            }
                        </div>
                        <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                            {
                                feeOverviewData?.allTransactionsDetails?.requestedToday
                            }{" "}
                            {feeOverviewData?.allTransactionsDetails?.requestedToday <= 1
                                ? "payment"
                                : "payments"}{" "}
                            requested today
                        </div>
                    </div>
                </div>
                <div className='m-l-10 m-t-10' style={{ width: "100%", height: 240 }}>
                    {sum == 0 ?
                        <div className='m-t-30'>
                            <NodataCard />
                        </div>
                        :
                        <Column {...config} onReady={(chart) => (chartRef.current = chart)} />
                    }
                </div>
            </div>
            <div
                className="r-c-sb"
                style={{
                    borderTop: "1px solid #E6E6E6",
                    backgroundColor: "white",
                    width: '100%',
                    marginTop: 20
                }}>
                {paymentDetailsArray?.map((item, index) => (
                    <div
                        style={{
                            borderRight: index === 4 ? " " : "1px solid #E6E6E6",
                            padding: "0px 10px 0px 10px",
                            marginTop: "10px",
                        }}
                    >
                        <div className="r-ac">
                            <div
                                className="dot"
                                style={{ marginRight: "3px", backgroundColor: item?.color }}
                            ></div>
                            <div
                                style={{
                                    color: item?.color,
                                    fontSize: "10px",
                                    fontWeight: "550",
                                }}
                            >
                                <span className="bold-700" style={{ fontSize: "14px" }}>{" "}{item?.totalCount}</span>{" "}
                                {item?.totalCount <= 1
                                    ? "payment"
                                    : "payments"}{" "}
                                {item?.text}
                            </div>
                        </div>
                        <div className="bold-700" style={{ fontSize: "18px" }}>
                            ₹{" "}{item?.amount ? item?.amount && numberWithCommas(item?.amount) : "0"}
                        </div>
                        <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                            {item?.todayCount}{" "}
                            {item?.todayCount <= 1
                                ? "payment"
                                : "payments"}{" "}
                            {item?.text} today
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    const { walletDashboardData, summaryMonth, feeOverviewData, singleBarData } = state.aulasWallet;
    const { summaryTypeContainer, summaryYearContainer, summaryStartDateContainer, summaryEndDateContainer } = state.attendanceNew;
    const { user } = state.session
    return {
        summaryTypeContainer,
        summaryYearContainer,
        summaryStartDateContainer,
        summaryEndDateContainer,
        summaryMonth,
        walletDashboardData,
        feeOverviewData,
        singleBarData,
        user
    };
};

const mapDispatchToProps = dispatch => ({
    storeSummaryType: (val) => dispatch(storeSummaryType(val)),
    storeUserSummaryStartDate: (val) => dispatch(storeUserSummaryStartDate(val)),
    storeUserSummaryEndDate: (val) => dispatch(storeUserSummaryEndDate(val)),
    storeSummaryYear: (val) => dispatch(storeSummaryYear(val)),
    storeSummaryMonth: (val) => dispatch(storeSummaryMonth(val)),
    setSingleBarData: (val) => dispatch(setSingleBarData(val))
});


export default connect(mapStateToProps, mapDispatchToProps)(FeeSummary);

// export default FeeSummary