import { deleteOffer, deleteOffers, getOffers, getOffersOverview, patchPublishOffers, publishOffers, updateOfferBanner } from 'actions/offers';
import { Modal } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { connect } from 'react-redux';
function OffersConfirmationModal({
    isModalVisible,
    setModalVisible,
    togglePageLoader,
    getOffers,
    actionType,
    selectedOfferId,
    isPublished,
    isBanner,
    updateOfferBanner,
    patchPublishOffers,
    publishOffers,
    deleteOffer,
    deleteOffers,
    selectedOfferIds,
    offerFilterParams,
    getOffersOverviewData,
    setSelectedItems,
    setPage,
    setOfferFilterParams,
    setOffersTypeIndex,
    setMainSectionType,
    previousMainSectionType,
    setSelectedOffer,
}) {
    const handleCancel = () => {
        setModalVisible(false);
    }
    const [btnLoader, setBtnLoader] = useState(false);
    let actionMessage = `delete ${selectedOfferId || selectedOfferIds?.length === 1 ? 'this promotional offer' : 'these promotional offers'}`;
    if (actionType == 'publish') {
        actionMessage = `${isPublished ? 'unpublish' : 'publish'} ${selectedOfferId || selectedOfferIds?.length === 1 ? 'this promotional offer' : 'these promotional offers'}`;
    } else if (actionType === 'banner') {
        actionMessage = `${isBanner ? 'remove' : 'show'} ${selectedOfferId || selectedOfferIds?.length === 1 ? 'this promotional offer' : 'promotional offers'} ${isBanner ? 'from' : 'in'} marketplace display`;
    } else if (actionType == 'createPublish') {
        actionMessage = "Promotional offer saved, do you want to publish this offer?";
    }
    const handleRedirectOverview = (publish = false) => {
        return function () {
            setSelectedItems([]);
            setSelectedOffer({});
            togglePageLoader(true);
            setModalVisible(false);
            setOffersTypeIndex(publish ? 1 : 2);
            setMainSectionType(previousMainSectionType);
            setModalVisible(false);
            setOfferFilterParams({ page: 1, available: !!publish });
            getOffersOverviewData(() => togglePageLoader(false));
            getOffers({ page: 1, limit: 10 });
        }
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            open={isModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}
            onClose={handleCancel}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>Confirm</div>
            <center style={{ color: "#636363", fontSize: 16, marginTop: 5, padding: "0px 20px" }}> {actionType == 'createPublish' ? actionMessage : `Are you sure you want to ${actionMessage}?`} </center>
            <center>
                <div style={{ display: "flex", justifyContent: "space-around", width: "75%", marginTop: 60 }}>
                    <Button onClick={() => { actionType === "createPublish" ? handleRedirectOverview(false)() : handleCancel() }}>{actionType === "createPublish" ? "DO IT LATER" : "NO"}</Button>
                    <Button
                        type="primary"
                        loading={btnLoader}
                        onClick={() => {
                            setBtnLoader(true);
                            if (actionType == 'delete') {
                                const deleteFunction = selectedOfferId ? deleteOffer : deleteOffers;
                                deleteFunction(
                                    selectedOfferId || selectedOfferIds || null,
                                    null,
                                    () => {
                                        setBtnLoader(false);
                                        setSelectedItems([]);
                                        togglePageLoader(true);
                                        setModalVisible(false);
                                        getOffersOverviewData(() => togglePageLoader(false));
                                        getOffers({ ...offerFilterParams, page: 1, limit: 10 }, () => setPage(1));
                                    }
                                )
                            } else if (actionType == 'banner') {
                                updateOfferBanner(
                                    selectedOfferId,
                                    !isBanner,
                                    null,
                                    () => {
                                        setBtnLoader(false);
                                        setSelectedItems([]);
                                        togglePageLoader(true);
                                        setModalVisible(false);
                                        getOffersOverviewData(() => togglePageLoader(false));
                                        getOffers({ ...offerFilterParams, page: 1, limit: 10 }, () => setPage(1));
                                    }
                                )
                            } else if (actionType == 'publish') {
                                const publishFunction = selectedOfferId ? patchPublishOffers : publishOffers
                                publishFunction(
                                    selectedOfferId || selectedOfferIds || null,
                                    !isPublished,
                                    null,
                                    () => {
                                        setBtnLoader(false);
                                        setSelectedItems([]);
                                        togglePageLoader(true);
                                        setModalVisible(false);
                                        getOffersOverviewData(() => togglePageLoader(false));
                                        getOffers({ ...offerFilterParams, page: 1, limit: 10 }, () => setPage(1));
                                    }
                                )
                            } else if (actionType == 'createPublish') {
                                patchPublishOffers(
                                    selectedOfferId,
                                    true,
                                    null,
                                    handleRedirectOverview(true),
                                )
                            }
                        }}>{actionType === "createPublish" ? "PUBLISH" : "YES"}</Button>
                </div>
            </center>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => ({
    deleteOffer: (id, callBack, successCallBack) => dispatch(deleteOffer(id, callBack, successCallBack)),
    deleteOffers: (ids, callBack, successCallBack) => dispatch(deleteOffers(ids, callBack, successCallBack)),
    updateOfferBanner: (id, banner, callBack, successCallBack) => dispatch(updateOfferBanner(id, banner, callBack, successCallBack)),
    getOffers: (params, callback) => dispatch(getOffers(params, callback)),
    patchPublishOffers: (id, publish, callBack, successCallBack) => dispatch(patchPublishOffers(id, publish, callBack, successCallBack)),
    publishOffers: (ids, publish, callBack, successCallBack) => dispatch(publishOffers(ids, publish, callBack, successCallBack)),
    getOffersOverviewData: (callBack) => dispatch(getOffersOverview(callBack)),
});
export default connect(null, mapDispatchToProps)(OffersConfirmationModal);