import { DownCircleOutlined, DragOutlined, MinusCircleOutlined, PlusCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import React, { useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Tooltip from 'rc-tooltip';


const ChapterItemType = 'CHAPTER';

const DraggableChapter = ({ chapterItem, chapterIndex, subjectIndex, moveChapter, handleChapterNameChange, handleAddChapter, handleRemoveChapter, subjectItem }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: ChapterItemType,
        hover(draggedItem, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = draggedItem.chapterIndex;
            const hoverIndex = chapterIndex;
            const dragSubjectIndex = draggedItem.subjectIndex;

            if (dragIndex === hoverIndex && dragSubjectIndex === subjectIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveChapter(subjectIndex, dragIndex, hoverIndex);
            draggedItem.chapterIndex = hoverIndex;
            draggedItem.subjectIndex = subjectIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ChapterItemType,
        item: { type: ChapterItemType, chapterIndex, subjectIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={ref} className='m-t-15 m-b-5' style={{ display: 'flex', opacity, alignItems: 'center' }}>
            <div className='m-t-5 m-r-5' style={{ fontSize: 14, color: '#636363', fontWeight: 600, width: 30 }}>{chapterIndex + 1}.</div>
            <div style={{ width: '100%', marginTop: -5 }}>
                <input
                    type="text"
                    className="custom-input"
                    value={chapterItem?.name}
                    onChange={(e) => handleChapterNameChange(subjectIndex, chapterIndex, e.target.value)}
                    style={{ color: '#636363', fontSize: 14, backgroundColor: '#F6F4FE' }}
                    placeholder='Enter chapter name'
                />
            </div>
            {subjectItem?.courseChapter?.length > 1 ?
                <Tooltip placement="top" overlay={'Drag & Drop'}>
                    <DragOutlined style={{ fontSize: "18px", color: "#FFA931", marginTop: "5px", cursor: "pointer", marginLeft: 10 }} />
                </Tooltip> : <></>}
            {chapterIndex === chapterItem.length - 1 ? (
                <PlusCircleOutlined
                    className='m-t-5 m-l-10'
                    style={{ color: '#1089FF', cursor: chapterItem?.name ? 'pointer' : 'not-allowed', fontSize: 18 }}
                    onClick={() => {
                        if (chapterItem?.name) {
                            handleAddChapter(subjectIndex);
                        }
                    }}
                />
            ) : (
                <MinusCircleOutlined
                    className='m-t-5 m-l-10'
                    style={{ color: 'red', cursor: 'pointer', fontSize: 18 }}
                    onClick={() => handleRemoveChapter(subjectIndex, chapterIndex)}
                />
            )}
        </div>
    );
};

function NewlyCreatedSubjectCard({ createdCards, onUpdateSubjectName, onUpdateChapterName, onAddChapter, onRemoveChapter }) {
    const [expandedItemId, setExpandedItemId] = useState(null);

    const toggleExpansion = (itemId) => {
        setExpandedItemId((prevItemId) => (prevItemId === itemId ? null : itemId));
    };

    const handleSubjectNameChange = (index, value) => {
        onUpdateSubjectName(index, value);
    };

    const handleChapterNameChange = (index, chapterIndex, value) => {
        onUpdateChapterName(index, chapterIndex, value);
    };

    const handleAddChapter = (index) => {
        onAddChapter(index);
    };

    const handleRemoveChapter = (index, chapterIndex) => {
        onRemoveChapter(index, chapterIndex);
    };

    const moveChapter = (subjectIndex, dragIndex, hoverIndex) => {
        const updatedCards = [...createdCards];
        const draggedChapter = updatedCards[subjectIndex].courseChapter[dragIndex];
        updatedCards[subjectIndex].courseChapter.splice(dragIndex, 1);
        updatedCards[subjectIndex].courseChapter.splice(hoverIndex, 0, draggedChapter);
        onUpdateChapterName(updatedCards);
    };

    return (
        <>
            {createdCards?.map((item, index) => (
                <div key={index} style={{ backgroundColor: "#F6F4FE", width: "100%", padding: "5px 10px", border: "1px solid #E4E4E4 ", borderRadius: 8, marginTop: 10 }}>
                    {expandedItemId === index ? (
                        <div style={{ marginTop: 5 }}>
                            <div className='r-c-sb'>
                                <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 600 }}>{"Subject name"}{" "} </label>
                                <div>
                                    <UpCircleOutlined
                                        style={{ color: "#594099", fontSize: 16, cursor: "pointer" }}
                                        onClick={() => toggleExpansion(index)}
                                    />
                                </div>
                            </div>
                            <input
                                type="text"
                                className="custom-input"
                                value={item?.name}
                                onChange={(e) => handleSubjectNameChange(index, e.target.value)}
                                placeholder='Enter subject name'
                                style={{ color: "#636363", fontSize: 14, backgroundColor: "#F6F4FE" }}
                            />
                            <h6 style={{ fontSize: "14px", color: "#8C8C8C", fontWeight: 600, marginTop: 15 }}>Chapter(s)</h6>
                            <DndProvider backend={HTML5Backend}>
                                {item?.courseChapter?.map((chapterItem, chapterIndex) => (
                                    <DraggableChapter
                                        key={chapterIndex}
                                        chapterItem={chapterItem}
                                        chapterIndex={chapterIndex}
                                        subjectIndex={index}
                                        moveChapter={moveChapter}
                                        handleChapterNameChange={handleChapterNameChange}
                                        handleAddChapter={handleAddChapter}
                                        handleRemoveChapter={handleRemoveChapter}
                                        subjectItem={item}
                                    />
                                ))}
                            </DndProvider>
                        </div>
                    ) : (
                        <div className='r-c-sb'>
                            <div className='r-ac'>
                                <div className='text-xlg r-c-c-c color-purple bold-600 font-pt-serif text-square-bg__grey' style={{ height: 38, width: 38, fontSize: 22 }}>
                                    {item?.name?.charAt(0)}
                                </div>
                                <div className='m-l-10'>
                                    <div style={{ color: "#191919", fontWeight: 600 }}>{item?.name}</div>
                                    <div style={{ color: "#636363", fontSize: 12 }}>{item?.courseChapter[0]?.name ? item?.courseChapter?.length : 0} {item?.courseChapter?.length > 1 ? "Chapters" : "Chapter"}</div>
                                </div>
                            </div>
                            <div>
                                <DownCircleOutlined
                                    style={{ color: "#594099", fontSize: 16, cursor: "pointer" }}
                                    onClick={() => toggleExpansion(index)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default NewlyCreatedSubjectCard;