import { deleteOffer, getOnlineTestSeriesCourseDetails, getSpecificCourseData, getSpecificSMData, getSpecificVideoCourseData, patchPublishOffers } from 'actions/courseManagement';
import { Typography, Tooltip } from 'antd';
import { Information } from 'components/Typography';
import moment from "moment";
import { OfferTagText, OfferTagTextColor, OfferTypeGraphColor } from 'pages/offers/constants/offers';
import React from 'react';
import { connect } from 'react-redux';
import "./styles.css";
import unPublish from 'Assets/offers/colored-offer-unpublish-icon.svg';
import publish from 'Assets/offers/colored-offer-publish-icon.svg';
import deleteIcon from 'Assets/offers/colored-offer-delete-icon.svg'
const offerIconPublished = require('Assets/offers/offer-icon-published.svg').default;
const offerIconUnpublished = require('Assets/offers/offer-icon-unpublished.svg').default;
const offerIconPrivate = require('Assets/offers/offer-icon-private.svg').default;
const offerIconExpired = require('Assets/offers/offer-icon-expired.svg').default;
const offerIconBanner = require('Assets/offers/offer-icon-banner.svg').default;

function DashboardOffersCard({ item, setIsOfferDrawerVisible, setSelectedOfferId, hasMarketingPrivilege, courseTypeName, setIsOfferPublish, setIsOfferDeleteModalVisible, setIsOfferPublishModalVisible }) {
    const getOfferIcon = (offer) => {
        let offerIcon = offerIconPublished;
        if (offer.expired) {
            offerIcon = offerIconExpired;
        } else if (offer.privateOffer) {
            offerIcon = offerIconPrivate;
        } else if (!offer.published) {
            offerIcon = offerIconUnpublished;
        } else if (offer.banner) {
            offerIcon = offerIconBanner;
        }
        return offerIcon;
    };
    const handleOnClick = (e) => {
        e.stopPropagation();
        if (setSelectedOfferId) {
            setSelectedOfferId(item.id);
        }
        if (setIsOfferDrawerVisible) {
            setIsOfferDrawerVisible(true);
        }
    }

    const getOfferTag = (offer) => {
        let offerTagBackground = OfferTypeGraphColor.published;
        let offerTagText = OfferTagText.published;
        let offerTagTextColor = OfferTagTextColor.published;
        if (offer.expired) {
            offerTagText = OfferTagText.expired;
            offerTagTextColor = OfferTagTextColor.expired;
            offerTagBackground = OfferTypeGraphColor.expired;
        } else if (offer.privateOffer) {
            offerTagText = OfferTagText.privateOffer;
            offerTagTextColor = OfferTagTextColor.privateOffer;
            offerTagBackground = OfferTypeGraphColor.privateOffer;
        } else if (!offer.published) {
            offerTagText = OfferTagText.unpublished;
            offerTagTextColor = OfferTagTextColor.unpublished;
            offerTagBackground = OfferTypeGraphColor.unpublished;
        } else if (offer.banner) {
            offerTagText = OfferTagText.banner;
            offerTagTextColor = OfferTagTextColor.banner;
            offerTagBackground = OfferTypeGraphColor.banner;
        }
        return {
            offerTagText,
            offerTagTextColor,
            offerTagBackground,
        }
    }
    const offerTag = getOfferTag(item);
    return (
        <div className='r-c-fs' style={{ borderBottom: "1px solid #E6E6E6", marginTop: "5px", cursor: 'pointer', paddingBottom: '0.3rem' }} onClick={handleOnClick}>
            <img src={getOfferIcon(item)} style={{ width: "14%", marginBottom: 10 }} />
            <div style={{ display: "flex", justifyContent: "space-between", width: "86%", position: "relative" }}>
                <div style={{ width: '100%' }}>
                    <Typography.Text ellipsis={{ tooltip: true, color: "#636363" }} style={{maxWidth:"80%"}}><Information bold={true}>{item.name}</Information></Typography.Text>
                    <div className='r-c-fs'>
                        <div className='r-c-fs'>
                            <Typography.Text ellipsis={{ tooltip: true }} >
                                <Information style={{ fontWeight: 700, color: "#636363" }}>{item.discountType === 'amount' ? `₹${item.discount}` : `${item.discount}%`}</Information>
                            </Typography.Text>
                            <Information>&nbsp;off</Information>
                        </div>
                        {item.code
                            ? <>
                                <Information style={{ margin: "0 0.5rem" }}>|</Information>
                                <Typography.Paragraph style={{ marginBottom: '0', color: "#636363", maxWidth: item.startDate || item.endDate ? "100%" : "48%" }} ellipsis={{ rows: 1, tooltip: true }}><Information>Promo code:</Information><Information style={{ fontWeight: 700, color: "#636363", marginRight: 5 }}>{` ${item.code}`}</Information></Typography.Paragraph>
                            </>
                            : null
                        }
                    </div>

                    {item.startDate || item.endDate ? <div className='r-c-fs' style={{ width: '100%' }}>
                        <Typography.Paragraph ellipsis={{ tooltip: true, rows: "2" }} style={{ marginBottom: 0 }}>
                            {!(item.startDate && item.endDate) ? <Information>Offer</Information> : null}
                            {item.startDate
                                ? <>{!item.endDate ? <Information>&nbsp;starting from&nbsp;</Information> : null}<Information style={{ fontWeight: 700 }}>{moment(item.startDate).format('DD/MM/YYYY')}</Information></>
                                : null
                            }
                            {item.endDate
                                ? <><Information>{!item.startDate ? ' ends on ' : ' - '}</Information><Information style={{ fontWeight: 700 }} >{moment(item.endDate).format('DD/MM/YYYY')}</Information></>
                                : null
                            }
                        </Typography.Paragraph>
                    </div> : null}
                    <div className='course-offer-type-tag' style={{ background: offerTag.offerTagBackground, color: offerTag.offerTagTextColor }}>
                        <span>{offerTag.offerTagText}</span>
                    </div>
                    {courseTypeName === 'videoResources' || courseTypeName === 'studyMaterials' || courseTypeName === 'onlineTestSeries' ? (
                        !hasMarketingPrivilege && (
                            <div style={{ display: "flex", justifyContent:'space-between',gap:"10px",position: 'absolute', top: 0, right: 0, alignItems:'flex-start' }}>
                                <Tooltip placement="top" overlay="Delete">
                                    <div
                                        style={{ color: "#FF414D", fontSize: "18px", cursor: "pointer"}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedOfferId(item?.id);
                                            setIsOfferDeleteModalVisible(true);
                                        }}
                                    >
                                        <img
                                            src={deleteIcon}
                                            style={{ marginTop:"-3px"}}
                                            alt='Delete'
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip placement="top" overlay={!item?.published ? 'Publish' : 'Unpublish'}>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (setIsOfferPublish) {
                                                setIsOfferPublish(item?.published);
                                            }
                                            setIsOfferPublishModalVisible(true);
                                            setSelectedOfferId(item?.id);
                                        }}
                                    >
                                        <img
                                            src={!item?.published ? publish : unPublish}
                                            style={{ marginTop:"-3px"}}
                                            alt={!item?.published ? 'Publish' : 'Unpublish'}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        )
                ) : null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { specificCourseDataContainer, newCourseId, courseTypeName } = state.courseManagement;
    const { user } = state.session
    return { specificCourseDataContainer, newCourseId, courseTypeName, user };
};

const mapDispatchToProps = (dispatch) => ({
    getSpecificCourseData: (callback) => dispatch(getSpecificCourseData(callback)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
    patchPublishOffers: (id, publish, callBack, successCallBack) => dispatch(patchPublishOffers(id, publish, callBack, successCallBack)),
    deleteOffer: (id, callBack, successCallBack) => dispatch(deleteOffer(id, callBack, successCallBack)),
    getOnlineTestSeriesCourseDetails: (callBack) => dispatch(getOnlineTestSeriesCourseDetails(callBack)),

});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOffersCard)