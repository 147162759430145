import { changeCourseName, setCourseDetails } from "actions/userManagement";
import { Button, Checkbox, Input, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
// import Date_Picker from "./datePickerForCourse";
import StartDate_Picker from "./courseStartDatePicker";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function CourseEditModal({
  isModalVisible,
  handleOk,
  handleCancel,
  isAdditionModal,
  setCourseDetails,
  studentCourseDetails,
  setViewEditCourseModal,
  setViewCourseDeleteConfirmModal,
  userDetailsById,
  changeCourseName,
  setViewCourseDetails,
}) {
  const [startDate, setStartDate] = useState(null);
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [renderEndDate, setRenderEndDate] = useState(false);

  // useEffect(() => {
  //   const returnaDurationType = (val) => {
  //     if (val == "1") {
  //       return "days";
  //     } else if (val == "30") {
  //       return "months";
  //     } else if (val == "360") {
  //       return "years";
  //     }
  //   };
  //   // console.log("future datePicker", returnaDurationType(courseRef));
  //   let temp = moment(courseStartDate)
  //     .add(courseDuration, returnaDurationType(courseRef))
  //     .format("DD-MM-YYYY");
  //   setCourseEndDate(temp !== NaN && temp != "Invalid date" ? temp : "");
  //   // setCourseStartDate(specificCourseDetails?startDate)
  //   // console.log("course end date", courseEndDate);
  // }, [
  //   courseStartDate,
  //   courseDuration,
  //   courseRef,
  //   studentCourseDetails?.selectedCourse,
  // ]);
  useEffect(() => {
    // setCourseDetails("scholarships", []);
    setCourseDetails(
      "course",
      studentCourseDetails?.selectedCourse?.courseName
    );
    setStartDate(studentCourseDetails?.selectedCourse?.startDate);
    //   {
    //     name: "Top 10",
    //     percentage: "200",
    //     details: "none",
    //   },
    //   {
    //     name: "Top 20",
    //     percentage: "100",
    //     details: "none",
    //   },
  }, []);

  return (
    <div>
      <Modal
        maskClosable={false}
        className="editCourseModalB"
        title="Basic Modal"
        visible={isModalVisible}
        onOk={() => {
          setViewEditCourseModal(false);
        }}
        onCancel={() => {
          setViewEditCourseModal(false);
        }}
      >
        <div
          className="flex_column_center"
          style={{
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "roboto",
            justifyContent: "center",
            marginBottom: "35px",
          }}
        >
          Course details
        </div>
        <div style={{ width: "100%" }}>
          <p className="dropDownLabelBasicStyle">Course name</p>
          <Select
            size="middle"
            placeholder="Select Course name"
            defaultValue={null}
            style={{ width: "97%", marginBottom: "20px" }}
            onChange={(e) => {
              setCourseDetails("courseId", e);
            }}
            value={studentCourseDetails?.course}
            onChange={(e) => setCourseDetails("course", e)}
          >
            {userDetailsById?.student?.courses?.map((item) => (
              <Option value={item?.id}>{item?.courseName}</Option>
            ))}
          </Select>
        </div>
        <div style={{ width: "97%" }}>
          <p className="dropDownLabelBasicStyle">Start date</p>

          <StartDate_Picker
            value={startDate}
            setCourseStartDate={setStartDate}
            setRenderEndDate={setRenderEndDate}
          />
        </div>

        <div
          className="flex_row_center"
          style={{ justifyContent: "space-between", marginTop: "150px" }}
        >
          <div style={{ color: "#AEAEAE" }}>
            <Button
              style={{ color: "#AEAEAE", fontWeight: "500" }}
              onClick={() => {
                setViewEditCourseModal(false);
                setViewCourseDeleteConfirmModal(true);
              }}
            >
              DELETE
            </Button>
          </div>
          <div className="felx_row_center">
            <Button
              style={{ color: "#AEAEAE", fontWeight: "500" }}
              onClick={() => {
                setViewEditCourseModal(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              loading={saveBtnLoader}
              onClick={() => {
                setSaveBtnLoader(true);
                changeCourseName(
                  () => {
                    setSaveBtnLoader(false);
                  },
                  () => {
                    setViewEditCourseModal(false);
                  },
                  startDate
                );
                // let temp = studentCourseDetails?.scholarships;
                // temp.push({
                //   name: "Top 10",
                //   percentage: "200",
                //   details: "none",
                // });
                // setCourseDetails(temp);
              }}
              style={{ backgroundColor: "#594099", color: "white" }}
            >
              SAVE
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// export default ScholarshipAndAdditionModal;
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    userDetailsById,
    classList,
    studentCourseDetails,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  changeCourseName: (callback, successCallback, date) =>
    dispatch(changeCourseName(callback, successCallback, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseEditModal);
