import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import OverViewCard from './subComponent/overviewCard'
import graphImage from '../../Assets/dashBoard/Icon-open-graph.svg';
import icExclamation from '../../Assets/dashBoard/ic-exclamation-outlined.svg'
import rightIcon from '../../Assets/dashBoard/ic-check-outlined.svg'
import icWarning from '../../Assets/dashBoard/ic-warning.svg'
import attendancePresent from '../../Assets/dashBoard/more-digital-library.svg'
import { Row, Col, Tooltip, Select } from 'antd';
import './dashboard.css'
import InformationBoardCard from './subComponent/informationBoardCard';
import RecentlyAddedCard from './subComponent/recentlyAddedCard';
import ActivityBoardCard from './subComponent/activityBoard';
import { connect } from 'react-redux';
import { fetchDashBoardData, fetchInfoBoardData, fetchOverviewData } from 'actions/dashBoard';
import moment from 'moment';
import MyCalendar from './component/calenderComponent';
import chatIcon from '../../Assets/dashBoard/Group_18923.png'
import { useHistory } from 'react-router';
import { setAulasCareChat } from 'actions/adminCare';
import { NodataCard } from 'pages/attendanceNew/studentAttendanceGraph';
import { setSelectedTest } from 'actions/studentTest';
import { storePaymentReqModalData } from 'actions/aulasWallet';
import ChatPopup from './component/chatBot';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import { CloseOutlined } from '@ant-design/icons';
import { getLocation, setLocation } from 'actions/attendanceNew';
import { fetchStudentStandards } from 'actions/schedule';
import { showMenu } from 'actions/layout';



export const overAllStatsDetail = (overviewData) => {
  const overallPercentage = overviewData?.overallPercentage;
  const isBelow50Percent = overallPercentage !== undefined && overallPercentage < 50;
  const scheduleInfo = overviewData?.ScheduleInfo;
  const displayValue = scheduleInfo !== null ? scheduleInfo : null;


  // { console.log("AA-scheduleInfoData", displayValue) }

  return [
    {
      rank: "#" + " " + `${overviewData?.classRank != undefined ? overviewData?.classRank : 'NA'}`,
      label: "Class rank",
      numberOfStudent: "Out of " + " " + `${overviewData?.rankOutOf != undefined ? overviewData?.rankOutOf : 'NA'}` + " " + "students",
      color: "#28DF99",
      image: graphImage,

    },
    {
      rank: overviewData?.overallPercentage + "%",
      label: "Attendance",
      numberOfStudent: isBelow50Percent ? "Your attendance is below 50%" : null,
      color: isBelow50Percent ? "#FF414D" : "#28DF99",
      image: isBelow50Percent ? icWarning : null,
    },
    {
      rank: overviewData?.percentBySchedule + "%",
      label: "Class Attendance",
      numberOfStudent: displayValue ? displayValue : null,
      color: displayValue ? "#28DF99" : null,
      image: displayValue ? rightIcon : null
    },
    {
      rank: overviewData?.myTestScore + "%",
      label: "Average test score",
      numberOfStudent: "Class average is" + " " + `${overviewData?.avgTestScore != undefined ? overviewData?.avgTestScore : 'NA'}` + "%",
      color: "#28DF99",
      image: icExclamation
    },
  ]
}


const DashBoardStudent = ({ fetchDashBoardData, dashBoardOverviewData, fetchInfoBoardData, storeInfoBoardData, setAulasCareChat, publishedTests, setSelectedTest, storePaymentReqModalData, paymentReqModalData, setLocation, getLocation, fetchOverviewData, overviewData, fetchStudentStandards, studentStandardFilters,showMenu }) => {
  const data = useSelector(state => state?.dashBoard?.dashBoardOverviewData?.Chat);

  const initialSelectedStandard = studentStandardFilters?.studentStandards?.length > 0 ? studentStandardFilters?.studentStandards[0]?.standardId : null;

  const history = useHistory();
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationPop, setShowNotificationPop] = useState(false);
  const [pageLoader, tooglePageLoader] = useState(false);
  const [selectedTime, setSelectedTime] = useState(moment());
  const [clockIn, setClockIn] = useState(true)
  const [selectedStandards, setSelectedStandards] = useState(initialSelectedStandard);


  const [isClose, setIsClose] = useState(true)
  // useEffect(() => {
  //   const myTimer = () => {
  //     var diff = moment().diff(showTime ? selectedTime : moment(), 's');
  //     setTs(diff)
  //     let mins = diff / 60;

  //     setTimeM(mins % 60 ? mins % 60 : '00');
  //     setTimeH((mins / 60 ? mins / 60 : '00'))
  //     setToggler(!toggler)
  //     if (moment().format('HH:mm:ss') == '00:00:00') {
  //       setSelectedTime(moment());
  //       setShowTime(false)
  //     }
  //   }
  //   setTimeout(myTimer, 1000);
  // }, [toggler])

  function Location(callback, successCallBack) {
    navigator.geolocation.getCurrentPosition(function (position) {
      getLocation(`${position.coords.latitude}`, `${position.coords.longitude}`);
      setLocation(() => { callback() }, () => { successCallBack() });
    }, function error(err) {
      Notification.error('error', 'Location access denied');
      return true;
    })
  }

  const clockinHandler = () => {
    Location(() => {
      if (clockIn) {
        setSelectedTime(moment());
        setShowTime(true)
      }
      setClockIn(false);
    }, () => {

    });

  }

  const handleChatClick = () => {
    /*  if (notificationCount > 0) {
     
       setAulasCareChat(false)
       history.push('/care/chat');
       // setShowNotificationPop(false);
       // setShowChatPopup(true);
     } else {
       setAulasCareChat(false)
       history.push('/care/chat');
     } */
    setAulasCareChat(false)
    history.push('/care/chat');
  };

  const handleNotificationClick = () => {
    setShowNotificationPop(false)
  };

  const handleNotificationHover = () => {
    if (notificationCount > 0) {
      setShowNotificationPop(true);
    }
  };

  const handleNotificationLeave = () => {
    setShowNotificationPop(false);
  };

  const handleCloseChatPopup = () => {
    setShowChatPopup(false);
  };

  useEffect(() => {
    showMenu(true)
    tooglePageLoader(true);
    fetchDashBoardData(() => { tooglePageLoader(false); })
    fetchInfoBoardData();
    // tooglePageLoader(false)
    fetchStudentStandards();
    fetchOverviewData(null, () => { });
  }, [])

  console.log({ overviewData })


  const DropdownCheckbox = ({ selectedStandards, setSelectedStandards, studentStandardFilters, fetchOverviewData }) => {
    const handleStandardChange = (value) => {
      fetchOverviewData({ standardId: value }, () => { })
      setSelectedStandards(value);
    };

    console.log({ selectedStandards })

    return (
      <div style={{ width: '100%' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="Select standards"
          onChange={handleStandardChange}
          value={selectedStandards}
        >
          {studentStandardFilters?.studentStandards?.map((standard) => (
            <Option key={standard.standardId} value={standard.standardId}>
              {`${standard.std} - ${standard?.section}`}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  // useEffect(()=>{
  //   const interval = setInterval(()=> {
  //     fetchDashBoardData((r)=>{
  //       setNotificationCount(r?.Chat.unreadMessage ? r?.Chat.unreadMessage :0)
  //       console.log("AA-notifcationcount",r?.Chat.unreadMessage);
  //     })
  //   } ,10000)

  //   return ()=>{
  //     clearInterval(interval)
  //   }
  // })
  const currentYear = moment().format('YYYY');
  const nextYear = moment().subtract(1, 'year').format('YYYY');
  const yearRange = `${nextYear}-${currentYear}`;
  return (
    <div style={{ marginTop: '55px', height: "100%" }}>
      <PageHeader title={'Dashboard'} />
      {isClose && dashBoardOverviewData?.AttendanceToday?.isHolidayOffday && dashBoardOverviewData?.AttendanceToday?.startTime == null ?
        <div style={{ height: "40px", boxShadow: '1px 5px 8px 2px #302A5F14', width: "100%", marginLeft: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", }}>
            <div style={{ fontSize: "14px", marginTop: "-10px" }}>It’s time to mark your attendance for the day!</div>
            <div style={{ display: "flex" }}>
              <div style={{ color: "#1089FF", fontSize: "14px", marginTop: "-10px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }} onClick={clockinHandler}>{clockIn ? 'CLOCK ME IN' : 'CLOCK ME OUT'} </div>
              <div style={{ marginTop: "-10px", cursor: "pointer" }} onClick={() => { setIsClose(false) }}><CloseOutlined style={{ fontSize: "14px", color: "black", }} /></div>
            </div>
          </div>
        </div> : null}

      <Row gutter={[16, 16]}>

        <Col span={18}>
          <div style={{ width: '100%', padding: "12px", borderRadius: '5px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '10px', height: "185px", marginTop: "30px", border: "1px solid #E6E6E6" }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '10px', marginTop: "4px" }}>
              <Row style={{ justifyContent: 'space-between', width: '100%' }}>
                <div className='text-heading-library' style={{ fontSize: "18px" }}>
                  Overview
                </div>
                <div>
                  <DropdownCheckbox selectedStandards={selectedStandards} setSelectedStandards={setSelectedStandards} studentStandardFilters={studentStandardFilters} fetchOverviewData={fetchOverviewData} />
                </div>
              </Row>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ color: "#636363", fontSize: "13px", fontWeight: 500 }}>{yearRange}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around", marginTop: "10px" }}>
                {overAllStatsDetail(overviewData)?.map((item, section, array) => (
                  <>
                    <OverViewCard
                      rank={item?.rank}
                      label={item?.label}
                      noOfStudent={item?.numberOfStudent}
                      color={item?.color}
                      icon={item?.image}
                    />
                    {section !== array.length - 1 && (
                      <div style={{ borderLeft: "1px solid #E6E6E6", marginLeft: "10px", marginRight: "10px" }}></div>
                    )}
                  </>
                ))
                }
              </div>
            </div>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className='scroll-bar-universal' style={{ width: '100%', border: "1px solid #E6E6E6", padding: "15px", borderRadius: '5px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '10px', height: "450px", marginTop: "15px" }}>
                <div style={{ fontSize: "15px", fontWeight: "bold" }}>Information Board ({dashBoardOverviewData?.InfoBoard?.length})</div>
                <div>

                  {storeInfoBoardData?.length != 0 ? (<>
                    {storeInfoBoardData?.map((item) => (
                      <>
                        <InformationBoardCard
                          allData={item}
                          title={item?.meta?.title}
                          detail={item?.detail}
                          icon={item?.icon}
                          clockin={item?.meta?.clockIn}
                          date={item?.meta?.date}
                          clockOut={item?.meta?.clockOut}
                          type={item?.type}
                          CourseStartDate={item?.meta?.startDate}
                          CourseDurationType={item?.meta?.durationType}
                          CourseDuration={item?.meta?.duration}
                          courseName={item?.meta?.courseName}
                          createdAt={item?.createdAt}
                          scheduleTitle={item?.meta?.title}
                          scheduleDesc={item?.meta?.description}
                          scheduleStartDate={item?.meta?.details?.startDate}
                          scheduleStartTime={item?.meta?.details?.startTime}
                          AnnouncementTitle={item?.meta?.title}
                          AnnouncementText={item?.meta?.text}
                          publishedTests={publishedTests}
                          setSelectedTest={setSelectedTest}
                        />
                      </>
                    ))}

                  </>) : <div style={{ marginTop: "50px" }}><NodataCard /></div>
                  }

                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className='scroll-bar-universal' style={{ width: '100%', border: "1px solid #E6E6E6", padding: "15px", borderRadius: '5px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '10px', height: "450px", marginTop: "15px" }}>
                <div style={{ fontSize: "15px", fontWeight: "bold" }}>Activity Board ({dashBoardOverviewData?.Activities?.length})</div>
                <div>
                  <>
                    <ActivityBoardCard
                      data={dashBoardOverviewData}
                      storePaymentReqModalData={storePaymentReqModalData}
                    />
                  </>

                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6} className="month-calendar">
          <div style={{ width: '100%', border: "1px solid #E6E6E6", padding: "20px", borderRadius: '5px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '10px', height: "260px", marginTop: "30px" }}>
            <div >
              <div style={{ display: "flex" }}>
                <div style={{ fontSize: "15px", fontWeight: "bold", }}>Event calendar</div>
                <div style={{ fontSize: "15px", fontWeight: 400, color: "#AEAEAE", marginLeft: "5px" }}>| {moment().format('MMMM YYYY')}</div>
              </div>
              <div style={{ marginLeft: '-20px' }}>
                <MyCalendar
                  dashBoardOverviewData={dashBoardOverviewData}
                  storePaymentReqModalData={storePaymentReqModalData}
                  paymentReqModalData={paymentReqModalData}

                />

              </div>
            </div>


          </div>
          <Col span={24}>
            <div className='scroll-bar-universal' style={{ width: '106%', border: "1px solid #E6E6E6", padding: "10px", borderRadius: '5px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '2px', height: "370px", marginTop: "13px", }}>
              <div style={{ fontSize: "15px", fontWeight: "bold" }}>Recently Added ({dashBoardOverviewData?.['Recently Added']?.length})</div>
              <div>
                {dashBoardOverviewData?.['Recently Added']?.length != 0 ? (<>
                  {dashBoardOverviewData?.['Recently Added']?.map((item) => (
                    <>

                      <RecentlyAddedCard
                        item={item}
                        title={item?.title}
                        subjectName={item?.subject?.name}
                        type={item?.type}
                        createdAt={item?.createdAt}
                        id={item?.id}
                        url={item?.link}
                        file={item?.file}
                      />
                      <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "15px" }}></div>
                    </>

                  ))}

                </>) : <div style={{ marginTop: "40px" }}><NodataCard /></div>}

                <div style={{ display: "flex", justifyContent: "flex-end", width: "18%", paddingRight: 10, position: "fixed", bottom: 10, cursor: "pointer" }} >
                  {showChatPopup && <ChatPopup onClose={handleCloseChatPopup} setAulasCareChat={setAulasCareChat} notificationCount={notificationCount} setNotificationCount={setNotificationCount} studentChat={true} />}
                  <img
                    src={chatIcon}
                    style={{ width: 60, height: 60, cursor: 'pointer' }}
                    onClick={() => { handleChatClick(); }}
                    onMouseEnter={handleNotificationHover}
                    onMouseLeave={handleNotificationLeave}
                    alt="Chat Icon"
                  />
                  {notificationCount > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        top: -5,
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: 12,
                        fontWeight: 'bold',
                      }}
                      onMouseEnter={handleNotificationHover}
                      onMouseLeave={handleNotificationLeave}
                      onClick={handleNotificationClick}
                    >
                      {notificationCount}
                    </div>
                  )}
                  {/* {showNotificationPop && (
                    <div
                      style={{
                        position: 'absolute',
                        top: -10,
                        right: 70,
                        backgroundColor: 'white',
                        borderRadius: 4,
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                        padding: '8px 12px',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                      onClick={handleChatClick}
                    >
                      {data?.lastUnreadMessage}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { dashBoardOverviewData, storeInfoBoardData, overviewData } = state.dashBoard
  const { publishedTests, } = state.studentTest;
  const { paymentReqModalData } = state.aulasWallet;
  const { studentStandardFilters } = state.schedule;

  return { dashBoardOverviewData, storeInfoBoardData, publishedTests, paymentReqModalData, overviewData, studentStandardFilters };

};

const mapDispatchToProps = (dispatch) => ({
  fetchDashBoardData: (callback) => dispatch(fetchDashBoardData(callback)),
  fetchInfoBoardData: (callback) => dispatch(fetchInfoBoardData(callback)),
  setAulasCareChat: (m) => dispatch(setAulasCareChat(m)),
  setSelectedTest: (data) => dispatch(setSelectedTest(data)),
  storePaymentReqModalData: (val) => dispatch(storePaymentReqModalData(val)),
  setLocation: (callback, successCallBack) => (dispatch(setLocation(callback, successCallBack))),
  getLocation: (val1, val2) => (dispatch(getLocation(val1, val2))),
  fetchOverviewData: (params, callback) => dispatch(fetchOverviewData(params, callback)),
  fetchStudentStandards: () => dispatch(fetchStudentStandards()),
  showMenu: (bool) => dispatch(showMenu(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardStudent);
