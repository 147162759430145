/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {
  Title,
  Heading,
  SubHeading,
  SmallHeading,
  GraphHeading,
} from "components/Typography";
import { Button, Input, Table, Dropdown, Menu, Tag } from "antd";
import { ArrowLeftOutlined, CaretDownOutlined } from "@ant-design/icons";
import Card from "../../components/card";
import {
  getStandardData,
  toggleUserDetailModal,
  toggleGenerateLinkModal,
  getUserTransactionDetails,
  updateSelectedFeeStudents,
  toggleFeeSearchLoader,
} from "actions/fees";
import { connect } from "react-redux";
import LoadingModal from "components/LoadingModal";
import UserDetailModal from "./userDetailModal";
import GenerateLinkModal from "./generateLinkModal";

//Assets
import linkSvg from "Assets/fee/link.svg";
import checkSvg from "Assets/fee/check.svg";
import pendingSvg from "Assets/fee/pending.svg";
import overdueSvg from "Assets/fee/overdue.svg";
import settlementSvg from "Assets/fee/double_check.svg";
import refundSvg from "Assets/fee/refund.svg";
import cancelSvg from "Assets/fee/cancel.svg";
import KycPending from "pages/kyc/kycPending";
import ApprovalPending from "pages/kyc/approvalPending";

const AllFeeStudents = ({
  standards,
  getStandardData,
  history,
  feeLoader,
  feeStudents,
  totalLinksRequested,
  totalLinksSettled,
  totalLinksRefunded,
  totalLinksCancelled,
  totalLinksPending,
  totalLinksPaid,
  totalLinksOverdue,
  getUserTransactionDetails,
  toggleUserDetailModal,
  showUserDetailModal,
  toggleGenerateLinkModal,
  showGenerateLinkModal,
  updateSelectedFeeStudents,
  selectedFeeStudents,
  feeSearchLoader,
  toggleFeeSearchLoader,
}) => {
  const [standardName, setStandardName] = useState("All");
  const [query, setQuery] = useState("");
  useEffect(() => {
    updateSelectedFeeStudents([], []);
    getStandardData(history);
    return () => {
      updateSelectedFeeStudents([], []);
    };
  }, []);
  const columns = [
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"STUDENT'S NAME"}
        </SmallHeading>
      ),
      dataIndex: "name",
      key: "name",
      render: (data, record) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              getUserTransactionDetails(record);
              toggleUserDetailModal(true);
            }}
          >
            <SubHeading bold>{data}</SubHeading>
          </div>
        );
      },
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"NO. OF TRANSACTIONS"}
        </SmallHeading>
      ),
      dataIndex: "transactions",
      key: "transactions",
      render: (data, record) => (
        <div>
          <SubHeading>{record?.transaction?.length}</SubHeading>
        </div>
      ),
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"LAST TRANSACTION"}
        </SmallHeading>
      ),
      dataIndex: "lastTransaction",
      key: "lastTransaction",
      render: (data, record) => {
        let status = record?.transaction[0]?.status;
        if (status == "Link Sent") {
          status = "Pending";
        }
        let color = "gold";
        if (status == "Paid") {
          color = "green";
        } else if (status == "Refunded") {
          color = "purple";
        } else if (status == "Settled") {
          color = "blue";
        } else if (status == "Overdue") {
          color = "red";
        } else if (status == "Cancelled") {
          color = "default";
        }
        return (
          <>
            {record?.transaction.length ? (
              <>
                <SubHeading>{`₹ ${record?.transaction[0]?.amount.toLocaleString(
                  "en-IN"
                )}`}</SubHeading>
                <Tag color={color} key={status}>
                  {status?.toUpperCase()}
                </Tag>
              </>
            ) : (
              <SubHeading>---</SubHeading>
            )}
          </>
        );
      },
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"TOTAL PAID"}
        </SmallHeading>
      ),
      dataIndex: "total_paid",
      key: "total_paid",
      render: (data, record) => (
        <div>
          <SubHeading>{`₹ ${record?.totalPaid?.toLocaleString(
            "en-IN"
          )}`}</SubHeading>
        </div>
      ),
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"PAYMENT HISTORY"}
        </SmallHeading>
      ),
      dataIndex: "history",
      key: "history",
      render: (data, record) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            getUserTransactionDetails(record);
            toggleUserDetailModal(true);
          }}
        >
          <SmallHeading bold color="#1089FF">
            View Details
          </SmallHeading>
        </div>
      ),
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"ACTIONS"}
        </SmallHeading>
      ),
      dataIndex: "actions",
      key: "actions",
      render: (data, record) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            toggleGenerateLinkModal(true);
            updateSelectedFeeStudents([record.id], [record]);
          }}
        >
          <img src={linkSvg} style={{ width: 25, maxWidth: "100%" }} alt="" />
        </div>
      ),
    },
  ];

  const menu = (
    <Menu style={{ padding: 5, maxHeight: 240, overflow: "auto" }}>
      <Menu.Item
        key={-2}
        onClick={() => {
          setStandardName(`All`);
          getStandardData(history);
        }}
      >
        <SubHeading>{`All`}</SubHeading>
      </Menu.Item>
      {standards.map((std) => (
        <Menu.Item
          key={std.id}
          onClick={() => {
            setStandardName(`${std.std}-${std.section}`);
            getStandardData(history, std.id);
          }}
        >
          <SubHeading>{`${std.std}-${std.section}`}</SubHeading>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col flex="auto">
          <Row align="middle">
            <Button
              className="m-r-10"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.goBack()}
            ></Button>
            <Card className="p-5 radius-0">
              <Dropdown overlay={menu} trigger={["click"]}>
                <div className="cursor-pointer r-ac">
                  <Title bold style={{ margin: 0 }}>
                    {`${standardName} Students`}
                  </Title>
                  <CaretDownOutlined style={{ fontSize: 25, marginLeft: 5 }} />
                </div>
              </Dropdown>
            </Card>
          </Row>
        </Col>
        <Col xs={6}>
          <Input.Search
            placeholder="Student Name / Phone No...."
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value == "") {
                getStandardData(history, null, "");
                toggleFeeSearchLoader(true);
              }
            }}
            value={query}
            onSearch={() => {
              getStandardData(history, null, query);
              toggleFeeSearchLoader(true);
            }}
            allowClear
            loading={feeSearchLoader}
          />
        </Col>
      </Row>
      {!feeSearchLoader && query ? (
        <Row>
          <Heading color="#8C8C8C">showing search results for</Heading>
          <Heading>{` "${query}"`}</Heading>
        </Row>
      ) : null}
      <Row className="m-t-20">
        <GraphHeading >Overview</GraphHeading>
      </Row>
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          backgroundColor: "#f9f9f9",
          padding: 15,
          borderRadius: 7,
        }}
      >
        <Card>
          <Row justify="end">
            <img src={linkSvg} style={{ width: 30, maxWidth: "100%" }} alt="" />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksRequested.amount?.toLocaleString(
            "en-IN"
          )}`}</Heading>
          <SubHeading>{`${totalLinksRequested.count} requested`}</SubHeading>
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={checkSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksPaid.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksPaid.count} paid`}</SubHeading>
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={pendingSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksPending.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksPending.count} pending`}</SubHeading>
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={overdueSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksOverdue.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksOverdue.count} overdue`}</SubHeading>
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={settlementSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksSettled.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksSettled.count} settled`}</SubHeading>
        </Card>
        <Card>
          <Row justify="end">
            <img
              src={refundSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksRefunded.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksRefunded.count} refunded`}</SubHeading>
        </Card>
        <Card>
          <Row justify="end">
            <img
              src={cancelSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksCancelled.amount?.toLocaleString(
            "en-IN"
          )}`}</Heading>
          <SubHeading>{`${totalLinksCancelled.count} cancelled`}</SubHeading>
        </Card>
      </div>
      <Row className="m-t-20" justify="space-between" align="middle">
        <Heading bold style={{ margin: 0 }}>
          {`${standardName} Students (${feeStudents.length})`}
        </Heading>
        <Button
          disabled={!selectedFeeStudents.ids.length}
          loading={false}
          type="primary"
          size="middle"
          shape="round"
          onClick={() => {
            toggleGenerateLinkModal(true);
          }}
        >
          Generate Requests
        </Button>
      </Row>
      <Row className="m-t-20">
        <Col flex="auto">
          <Table
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedFeeStudents.ids,
              onChange: (selectedRowKeys, selectedRows) => {
                updateSelectedFeeStudents(selectedRowKeys, selectedRows);
              },
            }}
            columns={columns}
            dataSource={feeStudents}
            pagination={false}
          />
        </Col>
      </Row>
      <UserDetailModal visible={showUserDetailModal} />
      <GenerateLinkModal visible={showGenerateLinkModal} kind="students" />
      <KycPending />
      <ApprovalPending />
      <LoadingModal visible={feeLoader} />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    feeSearchLoader,
    standards,
    showGenerateLinkModal,
    showUserDetailModal,
    feeStudents,
    totalLinksRequested,
    totalLinksPending,
    totalLinksPaid,
    totalLinksSettled,
    totalLinksRefunded,
    totalLinksCancelled,
    totalLinksOverdue,
    feeLoader,
    selectedFeeStudents,
  } = state.fees;
  return {
    feeSearchLoader,
    standards,
    selectedFeeStudents,
    showGenerateLinkModal,
    showUserDetailModal,
    feeStudents,
    feeLoader,
    totalLinksRequested,
    totalLinksSettled,
    totalLinksRefunded,
    totalLinksCancelled,
    totalLinksPending,
    totalLinksPaid,
    totalLinksOverdue,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSelectedFeeStudents: (ids, rows) =>
    dispatch(updateSelectedFeeStudents(ids, rows)),
  getStandardData: (history, standardId, query) =>
    dispatch(getStandardData(history, standardId, query)),
  getUserTransactionDetails: (user) =>
    dispatch(getUserTransactionDetails(user)),
  toggleUserDetailModal: (value) => dispatch(toggleUserDetailModal(value)),
  toggleGenerateLinkModal: (value) => dispatch(toggleGenerateLinkModal(value)),
  toggleFeeSearchLoader: (value) => dispatch(toggleFeeSearchLoader(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllFeeStudents);
