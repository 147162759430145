import { Api, Utils } from "../services";
export const TOGGLE_CHAT_LOADER = "TOGGLE_CHAT_LOADER";
export const UPDATE_CLASS_DATA = "UPDATE_CLASS_DATA";
export const UPDATE_CLASS_RESOURCE = "UPDATE_CLASS_RESOURCE";
export const UPDATE_CHATS = "UPDATE_CHATS";
export const SET_PAGE = "SET_PAGE";
export const GET_ROOM_ID_FOR_SIDEMENU = "GET_ROOM_ID_FOR_SIDEMENU";

export const getClassResources = (roomId, history, extraData, orgId) => {
  return dispatch => {
    dispatch(updateClassResourceLoader(true));
    return Api.get(`/resource/${roomId}`).send((response, error) => {
      dispatch(updateClassResourceLoader(false));
      const messages = [];
      if (typeof response !== "undefined") {
        response.chats.items.map(item => {
          if (item.type === "image") {
            messages.push(
              Utils.sendMessage(
                "",
                item.user,
                {
                  image: `${Api._base_url}/api/${orgId}/uploads/chat/${item.message}`
                },
                item.createdAt
              )
            );
          } else {
            messages.push(
              Utils.sendMessage(item.message, item.user, {}, item.createdAt)
            );
          }
          return 1;
        });
        dispatch(getRoomIdForSideMenu(roomId));
        dispatch(
          updateClassData(
            messages,
            response.chats.meta.currentPage,
            response.resources,
            extraData
          )
        );
        history.push({
          pathname: `/classroom-resources/${roomId}`
        });
      }
    });
  };
};

export const getRoomIdForSideMenu = roomId => ({
  type: GET_ROOM_ID_FOR_SIDEMENU,
  roomId
});

export const updateClassData = (
  messages,
  currentPage,
  resources,
  extraData
) => ({
  type: UPDATE_CLASS_DATA,
  messages,
  currentPage,
  resources,
  extraData
});

export const updateClassResourceLoader = value => ({
  type: UPDATE_CLASS_RESOURCE,
  value
});

export const getPreviousChat = (confId, page, orgId) => {
  return dispatch => {
    return Api.get(`/resource/fetch-chats/${confId}/${page}`).send(
      (response, error) => {
        console.log("getPreviousChat", response, page);
        dispatch(toggleChatLoader(false));
        if (typeof response != "undefined") {
          if (response.items.length) {
            const messages = [];
            response.items.forEach(item => {
              if (item.type === "image") {
                messages.push(
                  Utils.sendMessage(
                    "",
                    item.user,
                    {
                      image: `${Api._base_url}/api/${orgId}/uploads/chat/${item.message}`
                    },
                    item.createdAt
                  )
                );
              } else {
                messages.push(
                  Utils.sendMessage(item.message, item.user, {}, item.createdAt)
                );
              }
            });
            dispatch(updateChats(messages, response.meta.currentPage));
            dispatch(setPage(response.meta.currentPage));
          }
        }
      }
    );
  };
};

export const toggleChatLoader = value => ({
  type: TOGGLE_CHAT_LOADER,
  value
});

export const setPage = value => ({
  type: SET_PAGE,
  value
});

export const updateChats = (chats, currentPage) => ({
  type: UPDATE_CHATS,
  chats,
  currentPage
});
