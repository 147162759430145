import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import './modal.css';
import moment from 'moment';
import {connect} from 'react-redux';


const SubmittedSuccessModal = ({isModalVisible, handleOk, close, history, selectedTest}) => {

  const handleHomeClick = () => {
    close();
    history.push('/student-test')
  }

  return (
    <div className="confirm">
      <Modal className="confirm"  visible={isModalVisible} onOk={handleOk} closable={false}>

        <div className="message-area" style={{display:"flex", flexDirection:"column",justifyContent:"space-between",alignItems:"center"}}>
            <div className="imgconfirm" style={{display:"flex",height:"50%",width:"80%",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <div><img style={{width:"100%" ,height:"100%"}} src={require('Assets/images/ic-confirmation.PNG').default} alt="" /></div>
            </div>
            <div className="test1">
                <h2 style={{fontWeight:"700px", fontSize:"23px"}}>Successfully submitted</h2>
                <p style={{color:"grey"}}>Your test is successfully submitted</p>
            </div>
            {/* {item==='beforeResults'
            ?
            <div className="text2">
                <p  style={{color:"grey" ,marginBottom:"0px"}}>We will notify ones the</p>
                <p  style={{color:"grey"}}>results are published</p>

            </div >
            : */}
            <div className="text2">
                <p  style={{color:"grey" ,marginBottom:"0px"}}>Your results will be published</p>
                <p  style={{color:"black",fontWeight:"700"}}>{selectedTest.testType=="Objective"?"on "+moment(selectedTest.esultDate).format("DD MMMM YYYY"):"After evaluation"}</p>
            </div >

            <div style={{ borderRadius:"5px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <Button type="primary" shape="round" style={{width: 150}}  onClick={() => handleHomeClick()} style={{backgroundColor:"#594099", width:"150px",height:"10%",color:"white"}}>HOME</Button>

            </div>
        </div>




      </Modal>
    </div>
  );
};


const mapStateToProps = state => {
  const {
     selectedTest
  } = state.studentTest;
  return {
     selectedTest
  };
};

const mapDispatchToProps = dispatch => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(SubmittedSuccessModal);
