import React, { useState } from "react";
import { Modal, Button } from "antd";
import "../style.css";
import PageDataLoader from "components/pageDataLoader";
import { useHistory } from "react-router";

const ConfirmRejectModal = ({
    isRejectModalVisible, 
    setIsRejectModalVisible,
    setCardId,
    id,
    setActionData,
    requestedActionData,
    functionProp,
    inDifferentPage,
    getLeaveRequestData,
    setIsButtonDivVisible,
    getLeavesTabData,
    togglePageLoader,
    postUserStatus,
    fromNotification,
    fromStudentProfile,
    getStudentOverview,
    Title,}) => {

    const [isRejectLoading,setIsRejectLoading]=useState(false);
    const history= useHistory();

   const handleOk = () => {
    setIsRejectModalVisible(false);
  };
  const handleCancel = () => {
    setIsRejectModalVisible(false);

  };
  console.log("togglePageLoader",togglePageLoader)
  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={isRejectModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
          
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <div
            style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}
          >
            Confirm
          </div>
          <div
            style={{
              fontSize: "16px",
              color: "#636363",
              fontWeight: "bold",
              marginTop: "10px",
              textAlign:"center"
            }}
          >
            {fromNotification ?  "Are you sure you want to reject " + Title + " ?"  : "Are you sure you want to reject ?"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "30px",
          }}
        >
          <div>
            <Button
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid  #636363",
                backgroundColor: "#FFFFFF",
                color: "#594099",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
                onClick={handleOk}
              >
                NO
              </div>
            </Button>
          </div>
          <div>
            <Button
            loading={isRejectLoading}
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid #594099 ",
                backgroundColor: "#594099",
                fontSize: "16px",
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
              onClick={(e)=>{
                e.stopPropagation()
                setIsRejectLoading(true);
                if(fromNotification){
                  postUserStatus(()=>{
                    setIsRejectLoading(false);
                  },
                  ()=>{
                    setIsRejectModalVisible(false)
                    history.push("/notifications-home")
                  })
                }else if(fromStudentProfile){
                  setCardId(id);
                  setActionData("Rejected");
                  requestedActionData(
                  ()=>{
                  setIsRejectLoading(false)
                  },
                  ()=>{
                  setIsRejectModalVisible(false);
                  getStudentOverview()
                  }                
                  )
                }
                
                else{
                  setCardId(id);
                  setActionData("Rejected");
                  requestedActionData(
                  ()=>{
                  setIsRejectLoading(false)
                  },
                  ()=>{
                  setIsRejectModalVisible(false);
                  if(setIsButtonDivVisible){
                  setIsButtonDivVisible(false)}
                  togglePageLoader(true);
                  functionProp(()=>{
                      togglePageLoader(false)
                  })
                  }                
                  )
                }
                
              }}
            >
                YES
            </Button>
          </div>
        </div>

      </Modal>
    </div>
    
  );
};

export default ConfirmRejectModal;


