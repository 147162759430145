import { Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { editTeacherLeaves, getLeavesTabData } from 'actions/attendanceNew';
import { connect } from 'react-redux';

const TeacherLeaveModal = ({ isTeacherLeave, setIsTeacherLeave, leaveType, balanceData, editTeacherLeaves, getLeavesTabData, togglePageLoader, user, leavesTabDataContainer, leaveCardIndex, specificUserId }) => {

    const [leaveBalance, setLeaveBalance] = useState(balanceData);
    const [saveLoader, setSaveLoader] = useState(false);

    console.log({specificUserId});

    const handleCancel = () => {
        setIsTeacherLeave(false);
    };

    const callback = () => {
        setSaveLoader(false);
        setIsTeacherLeave(false);
    };

    const successCallBack = () => {
        togglePageLoader(true);
        getLeavesTabData(() => {togglePageLoader(false)});
    };

    const handleSaveClick = () => {
        let params = {
            userId: specificUserId,
            leaveTypes: [{
                availed: leavesTabDataContainer?.leaveTab?.leaveSummary[leaveCardIndex]?.availed,
                leaveType: leaveType,
                noOfLeaves: leaveBalance,
            }]
        };

        setSaveLoader(true);
        editTeacherLeaves(params, () => callback(), () => successCallBack());
    };

    return (
        <>
            <Modal
                visible={isTeacherLeave}
                onCancel={handleCancel}
                footer={null}
                className='modal-round-corner'
            >
                <div style={{
                    marginTop: "20px",
                    fontSize: "22px",
                    color: "#191919",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                }}>Leave Balance</div>
                <div style={{ marginTop: "20px" }}>
                    <h6
                        style={{
                            fontSize: "14px",
                            color: "#636363",
                            fontWeight: "bold",
                        }}
                    >
                        Leave name
                    </h6>
                    <Input
                        readOnly
                        value={leaveType}
                        style={{
                            fontSize: "16px",
                            color: "#191919",
                        }}
                    />
                </div>
                <div style={{ marginTop: "20px" }}>
                    <h6
                        style={{
                            fontSize: "14px",
                            color: "#636363",
                            fontWeight: "bold",
                        }}
                    >
                        Number of Leaves
                    </h6>
                    <Input
                    type='number'
                        style={{
                            fontSize: "16px",
                            color: "#191919",
                        }}
                        value={leaveBalance}
                        onChange={(e) => {
                            setLeaveBalance(e.target.value)

                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "50px",
                    }}
                >
                    <div>
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "150px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #636363 ",
                                color: "#636363",
                            }}
                            onClick={handleCancel}
                        >
                            CANCEL
                        </Button>
                    </div>
                    <div>
                        <Button
                            loading={saveLoader}
                            style={{
                                borderRadius: "19px",
                                width: "150px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#594099",
                                color: "#FFFFFF",
                                opacity:leaveBalance < 1 ? 0.5:1
                            }}
                            disabled={leaveBalance < 1}
                            onClick={handleSaveClick}
                        >
                            SAVE
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    const { leavesTabDataContainer, tabDataContainer } = state.attendanceNew;
    const { editRole, specificUserId } = state.userManagement;
    const { user } = state.session;
    return {leavesTabDataContainer, tabDataContainer, editRole, user, specificUserId};
};

const mapDispatchToProps = (dispatch) => ({
    getLeavesTabData: (callback) => (dispatch(getLeavesTabData(callback))),
    editTeacherLeaves: (params, callback, successCallBack) => dispatch(editTeacherLeaves(params, callback, successCallBack))


});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherLeaveModal);



