import React, { useEffect, useState } from "react";
import { Modal, Menu, Checkbox } from "antd";
import "./styles.css";
import dotIcon from "../Assets/layout/newFilledIcons/dotIcon.svg";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from "react-redux";
import { setMenuSettingsData } from "actions/userManagementV2";
import addUserEnabled from "../Assets/layout/newFilledIcons/add-user-enabled.svg";
import addUserDisabled from "../Assets/layout/newFilledIcons/add-user-disabled.svg";
import addFeeEnabled from "../Assets/layout/newFilledIcons/add-fee-enabled.svg";
import addFeeDisabled from "../Assets/layout/newFilledIcons/add-fee-disabled.svg";
import addScheduleEnabled from "../Assets/layout/newFilledIcons/add-schedule-enabled.svg";
import addScheduleDisabled from "../Assets/layout/newFilledIcons/add-schedulle-disabled.svg";
import addAnnounceEnabled from "../Assets/layout/newFilledIcons/add-announcement-enabled.svg";
import addAnnounceDisabled from "../Assets/layout/newFilledIcons/add-announcement-disabled.svg";
import markAttendanceEnabled from "../Assets/layout/newFilledIcons/mark-attendance-enabled.svg";
import markAttendanceDisabled from "../Assets/layout/newFilledIcons/mark-attendance-disabled.svg";
import applyLeaveEnabled from "../Assets/layout/newFilledIcons/apply-leave-enabled.svg";
import applyLeaveDisabled from "../Assets/layout/newFilledIcons/apply-leave-disabled.svg";
import createCourseEnabled from "../Assets/layout/newFilledIcons/cretae-course-enabled.svg";
import createCourseDisabled from "../Assets/layout/newFilledIcons/create-course-disabled.svg";
import addResourceEnabled from "../Assets/layout/newFilledIcons/add-resource-enabled.svg";
import addResourceDisabled from "../Assets/layout/newFilledIcons/add-resource-disabled.svg";
import addTestEnabled from "../Assets/layout/newFilledIcons/add-test-enabled.svg";
import addTestDisabled from "../Assets/layout/newFilledIcons/add-test-disabled.svg";


const ItemType = 'MENUITEM';

const QuickAccessModal = ({ isVisible, selectedItems, allItems, onClose, setSelectedItems, setMenuSettingsData, userMenusettingsData, setEditClick, editClick }) => {

    const iconMap = {
        "Add user": { enabled: addUserEnabled, disabled: addUserDisabled },
        "Add fee request": { enabled: addFeeEnabled, disabled: addFeeDisabled },
        "Add schedule": { enabled: addScheduleEnabled, disabled: addScheduleDisabled },
        "Add announcement": { enabled: addAnnounceEnabled, disabled: addAnnounceDisabled },
        "Mark attendance": { enabled: markAttendanceEnabled, disabled: markAttendanceDisabled },
        "Apply leave": { enabled: applyLeaveEnabled, disabled: applyLeaveDisabled },
        "Create course": { enabled: createCourseEnabled, disabled: createCourseDisabled },
        "Add resource": { enabled: addResourceEnabled, disabled: addResourceDisabled },
        "Add test": { enabled: addTestEnabled, disabled: addTestDisabled },
    };


    const [localSelected, setLocalSelected] = useState(selectedItems);


    useEffect(() => {
        setLocalSelected(userMenusettingsData?.menuSettings)
    }, [])


    useEffect(() => {
        userMenusettingsData?.menuSettings?.filter(item => item !== null);
        localSelected?.filter(item => item !== null);

    }, [userMenusettingsData?.menuSettings, localSelected])




    const handleSelect = (item) => {
        const filteredData = localSelected.filter(item => item !== null || item !== undefined);

        let newSelected = [...filteredData];

        if (isSelected(item) && (newSelected.length === 1)) {
            return; // Do nothing
        }


        if (isSelected(item)) {
            newSelected = newSelected.filter((i) => i.label !== item.label);
        } else if (newSelected.length < 5) {
            newSelected = [...newSelected, item];
        }

        setLocalSelected(newSelected);
        setMenuSettingsData(newSelected)
    };

    const sortedItems = [...allItems].sort((a, b) => {

        // Filter out null or undefined entries from localSelected
        const validLocalSelected = localSelected?.filter((i) => i != null) || [];

        const aSelected = validLocalSelected.some((i) => i?.label === a?.label);
        const bSelected = validLocalSelected.some((i) => i?.label === b?.label);

        // Prioritize selected items by moving them to the top
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;

        // If both are selected or both are not selected, maintain their order based on `localSelected`
        const aIndex = validLocalSelected.findIndex((i) => i?.label === a?.label);
        const bIndex = validLocalSelected.findIndex((i) => i?.label === b?.label);

        // Handle case when both items are selected or unselected
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex; // Maintain drag-and-drop order for selected items
        }

        // For unselected items, preserve their original order from `allItems`
        return allItems.indexOf(a) - allItems.indexOf(b);
    });


    const isSelected = (item) => localSelected?.some((i) => i?.label === item?.label);

    const isDisabled = (item) => !isSelected(item) && localSelected?.length >= 5;

    const DragItem = React.memo(({ item, index }) => {
        const ref = React.useRef(null);
        const moveItem = (dragIndex, hoverIndex) => {


            const dragItem = localSelected[dragIndex];
            const newItems = [...localSelected];
            newItems?.splice(dragIndex, 1);
            newItems?.splice(hoverIndex, 0, dragItem);
            setLocalSelected(newItems);
            // setSelectedItems(newItems)
            setMenuSettingsData(newItems)

        };

        const [, drop] = useDrop({
            accept: ItemType,
            hover(draggedItem, monitor) {
                if (!ref.current) {
                    return;
                }

                const dragIndex = draggedItem?.index;
                const hoverIndex = index;

                if (!isSelected(localSelected[dragIndex]) || !isSelected(localSelected[hoverIndex])) {
                    return;
                }

                if (dragIndex === hoverIndex) {
                    return;
                }

                if (localSelected.length == 0) {
                    return
                }

                const hoverBoundingRect = ref?.current?.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect?.bottom - hoverBoundingRect?.top) / 2;
                const clientOffset = monitor?.getClientOffset();
                const hoverClientY = clientOffset?.y - hoverBoundingRect?.top;

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }


                moveItem(dragIndex, hoverIndex);
                draggedItem.index = hoverIndex;
            },
        });

        const [{ isDragging }, drag] = useDrag({
            type: ItemType,
            item: { type: ItemType, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        const opacity = isDragging ? 0 : 1;




        return (
            <div
                ref={ref}
                style={{ opacity }}
                key={index}
                className={`menu-item-1 ${isSelected(item) ? "selected" : ""} ${isDisabled(item) ? "disabled" : ""}`}
                onClick={() => {
                    if (!isDisabled(item)) handleSelect(item);
                }}
            >
                <div className="menu-item-content" style={{ cursor: 'pointer' }}>
                    <Checkbox
                        checked={isSelected(item)}
                        disabled={isDisabled(item)}
                        className={`menu-item-checkbox ${localSelected?.some(sortedItem => sortedItem?.index !== item?.index) && localSelected?.length < 5
                            ? 'active-border'
                            : 'inactive-border'
                            }`}
                        style={{ cursor: 'pointer' }}
                    />
                    <img
                        src={
                            isSelected(item)
                                ? iconMap[item.label]?.enabled // Selected icon
                                : localSelected?.some((sortedItem) => sortedItem?.index !== item?.index) &&
                                    localSelected?.length < 5
                                    ? iconMap[item.label]?.enabled // Enabled based on condition
                                    : iconMap[item.label]?.disabled // Disabled icon
                        }
                        alt={`${item?.label} icon`}
                        className="menu-item-icon"
                        style={{ marginLeft: 10 }}
                    />
                    <span className="menu-item-label" style={{ color: isSelected(item) ? '#636363' : (localSelected?.some(sortedItem => sortedItem?.index !== item?.index) && localSelected?.length < 5) ? '#636363' : '#AEAEAE', }}>
                        {item?.label}
                    </span>
                    <span>
                        {isSelected(item) ? <img src={dotIcon} style={{ height: 20, width: 20, cursor: 'pointer' }} /> : <></>}
                    </span>
                </div>
            </div>
        );
    });



    return (
        <div className="quick-access-menu" style={{ marginLeft: editClick ? 0 : 40, }}>
            <DndProvider backend={HTML5Backend}>
                {sortedItems?.map((item, index) => (
                    <DragItem key={index} item={item} index={index} />
                ))}
            </DndProvider>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { userMenusettingsData, menuSettingsData } = state.userManagementV2
    return {
        userMenusettingsData, menuSettingsData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMenuSettingsData: (res) => dispatch(setMenuSettingsData(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccessModal)