import { Button, Dropdown, Menu, Popover, Rate, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import TopBar from './topBar'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"

import { connect } from 'react-redux';
import { Api } from 'services';
import noImage from "../../Assets/aulasMarket/no-image.svg"
import { InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { getAllStudyMaterials, getCourseList, getVideoResourcesList } from 'actions/aulasMarket';
import RatingStars from './ratingStars';
import dropDownIcon from "../../Assets/aulasMarket/ic-arrow-head-down.svg"
import dropUpIcon from "../../Assets/aulasMarket/ic-arrow-head-up.svg"
import PageDataLoader from 'components/pageDataLoader';
import { getAllStudyMaterials, getAllTestSeries, getCartCount, getCheckOutItem, getCourseList, getVideoResourcesList, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, storeCourseIdAndType } from 'actions/studentMarketPlace';
import Footer from './footer';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import HtmlParser from 'react-html-parser';
import NewFooter from './newFooter'

const defaultCourseImage = require('Assets/default_course-img.svg').default;

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
        if (c[i].nodeType === 1) return true;
    }
    return false;
}

const LiveCourseCards = ({ item, history, getCheckOutItem, user, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, getCartCount, storeCourseIdAndType }) => {
    console.log("item121212", item?.resourceType);
    const [shareUrl, setShareUrl] = useState(null);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false)
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)


    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.inSaved, item?.owned])

    const handleSaveForLater = (id, itemType) => {
        setIsSavedClicked(!isSavedClicked)

        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {
        if (user?.accessToken) {
            postAddCartItem(id, itemType, (res) => {
                setAddToCartClicked(true)

                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        } else {
            history.push('/login');
        }
    };

    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
                history.push('/courses-list')
            });
        } else {
            history.push('/login');
        }
    };

    return (

        <div className='live-course-cards ' style={{}} >
            <div
                className='live-course-not-hover-card'
            >
                <div style={{ width: "100%", height: "140px", position: "relative" }}>
                    <img className='main-image' src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{
                        borderRadius: 8, width: "100%", height: "140px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    }} />
                    {item?.feeType == "free" ? <img src={require("../../Assets/userMarketPlace/freeTag.svg").default} style={{ position: "absolute", left: 0, top: -5, height: 45, width: 45 }} /> : <></>}
                    {item?.mode ? <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : null}
                    {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <></>}
                </div>
                <Typography.Text className='course-name-div' ellipsis={{ tooltip: true }} style={{ color: "#191919", fontWeight: "bold", fontSize: 16, width: "100%" }}>{item?.courseName}</Typography.Text>
                {/* <div className='mobile-disable' style={{ color: "#636363" }}>20 hours videos | 6 quizzes | 14 notes</div> */}
                <div style={{ display: "flex" }}>
                    {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                    <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                </div>
            </div>
            {/* <div className='live-hover-card'> */}
            <div className='live-hover-card zoom123'
                onClick={() => {
                    storeCourseIdAndType("id",item?.id);
                    storeCourseIdAndType("type",item?.resourceType)
                    if (item?.resourceType == 'course') {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            }
                        })
                    } else {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            },
                        })

                    }

                }}
            >
                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 14, width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}>{item?.resourceType == "studyMaterial" ? item?.name : item?.courseName}</div>
                <div style={{ display: "flex" }}>
                    {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                    <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                </div>
                <div className={`m-t-5 ${item?.bestseller == 1 ? 'r-c-sb' : ""}`}>
                    {item?.bestseller == 1 ? <div style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12 }}>Bestseller</div> : <></>}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        {user?.role?.includes("Student") ? <>
                            {item?.owned == 1 ? <></> : <div onClick={(e) => {
                                e.stopPropagation();
                                if (user?.accessToken) {
                                    handleSaveForLater(item?.id, item?.resourceType)
                                }
                                else {
                                    history.push("/login")
                                }
                            }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || isSavedClicked ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div>}
                        </> : <></>}
                        <img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ marginLeft: 20, height: 20, width: 20 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                            }} />
                    </div>
                </div>
                <div className='ellipsis-text m-t-10' style={{ color: "#191919", fontSize: 12 }}>
                    {IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}</div>
                {item?.mode ? <div className='m-t-10' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", fontWeight: "bold", width: "fit-content" }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
                {item?.mode ? <div className='m-t-5' style={{ fontSize: 11, color: "#AEAEAE" }}>{item?.mode == "hybrid" ? "Classes to be held in physical classroom and also over the internet." : item?.mode == "online" ? "Classes to be conducted over the internet." : "Classes to be held in physical classroom."}</div> : <></>}
                {user?.role?.includes("Student") ? <>
                    <center>
                        {item?.owned == 1 || enroll[item?.id] ?
                            <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "fit-content", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, marginTop: 5, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}>VIEW COURSE</Button>
                            :
                            <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                {item?.resourceType == 'course' ? null :
                                    <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (!user?.accessToken) {
                                                history.push("/login")
                                            } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                history.push("/cart")
                                            } else {
                                                setAddCartLoader(true)
                                                handleAddToCart(item?.id, item?.resourceType)
                                                getCartCount()
                                            }
                                        }}>
                                        {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}
                                    </Button>
                                }
                                <Button loading={enrollLoader} type="primary" style={{ fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, width: "47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (user?.accessToken) {
                                            if (item?.feeType == "free") {
                                                setEnrollLoader(true)
                                                handleEnroll(item?.id, item?.resourceType)
                                            } else {
                                                history.push({
                                                    pathname: '/user-checkout',
                                                    state: { itemId: item?.id, itemType: item?.resourceType }
                                                });
                                            }
                                        } else {
                                            history.push("/login")
                                        }
                                    }}
                                >
                                    {enroll[item?.id] ? "ENROLLED" : item?.feeType == "free" || item?.resourceType == "course" ? "ENROLL NOW" : "BUY NOW"}
                                </Button>
                            </div>
                        }
                    </center>
                </> : <></>}
            </div>
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
        </div>


        // </div>

    );
};
const StudyMaterialsCards = ({ item, history, user, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, getCartCount, type }) => {
    const [shareUrl, setShareUrl] = useState(null);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false)
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)


    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.inSaved, item?.owned])

    const handleSaveForLater = (id, itemType) => {
        setIsSavedClicked(!isSavedClicked)
        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {
        if (user?.accessToken) {
            postAddCartItem(id, itemType, (res) => {
                setAddToCartClicked(true)
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        } else {
            history.push('/login');
        }
    };

    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
                history.push('/courses-list')
            });
        } else {
            history.push('/login');
        }
    };
    { console.log('item', item) }
    return (
        <div >
            <div className='study-material-parent-card zoom123'>
                <div className='study-course-not-hover-card'>
                    <div className='study-material-card'
                        onClick={() => {
                            storeCourseIdAndType("id",item?.id);
                            storeCourseIdAndType("type",item?.resourceType)
                            if (item?.resourceType == 'course') {
                                history.push({
                                    pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                    state: {
                                        id: item?.id,
                                        resourceType: item?.resourceType,
                                        selectedSuggestion: location?.state?.selectedSuggestion
                                    }
                                })
                            } else {
                                history.push({
                                    pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                    state: {
                                        id: item?.id,
                                        resourceType: item?.resourceType,
                                        selectedSuggestion: location?.state?.selectedSuggestion
                                    }
                                })

                            }

                        }}>
                        {item?.feeType == 'free' ?
                            <div className='study-material-free' >
                                <div style={{ textAlign: "center", fontSize: "10px", color: '#FFFFFF', fontWeight: "700px", marginTop: 2 }}>FREE</div></div> : <div style={{ width: '60px', height: 20 }} ></div>}
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />
                            {type == 'test'
                                ?
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>  {item?.tCount} {item?.tCount <= 1 ? "Test" : "Tests"}
                                </div>
                                :
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>  {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                            }

                        </div>
                        <div style={{ marginRight: "10px", marginTop: "-10px" }}>
                            {item?.feeType == 'free' ? (<></>) :
                                <div className='study-material-amount'>
                                    <div style={{ textAlign: "center", fontSize: "10px", color: '#594099', fontWeight: "700px", marginTop: 2 }}>₹ {item?.amount}</div>
                                </div>}
                        </div>



                    </div>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200, marginTop: "5px" }}>{item?.name}</Typography.Text>
                    <div style={{ display: "flex" }}>
                        {item?.rating > 0 ?
                            <RatingStars star={item?.rating} /> : null}
                        {item?.ratingCount > 0 ?
                            <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.ratingCount})</h> : null}
                    </div>
                </div>
                <div className='study-course-hover-card' onClick={() => {
                    storeCourseIdAndType("id", item?.id);
                    storeCourseIdAndType("type", item?.resourceType)
                    if (item?.resourceType == 'course') {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            }
                        })
                    } else {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            },
                        })

                    }

                }} >
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 14, width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}>{item?.resourceType == "studyMaterial" ? item?.name : item?.resourceType == "testSeries" ? item?.name : item?.courseName}</div>
                    <div style={{ display: "flex" }}>
                        {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                        <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                    </div>
                    <div className={`m-t-5 ${item?.bestseller == 1 ? 'r-c-sb' : ""}`}>
                        {item?.bestseller == 1 ? <div style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12 }}>Bestseller</div> : <></>}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {user?.role?.includes("Student") ? <>
                                {item?.owned == 1 ? <></> : <div onClick={(e) => {
                                    e.stopPropagation();
                                    if (user?.accessToken) {
                                        handleSaveForLater(item?.id, item?.resourceType)
                                    }
                                    else {
                                        history.push("/login")
                                    }
                                }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || isSavedClicked ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div>}
                            </> : <></>}

                            <img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ marginLeft: 20, height: 20, width: 20 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                                }} />
                        </div>
                    </div>
                    <div className='ellipsis-text m-t-10' style={{ color: "#191919", fontSize: 12 }}>{IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}</div>
                    {item?.mode ? <div className='m-t-10' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", fontWeight: "bold", width: "fit-content" }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
                    {item?.mode ? <div className='m-t-5' style={{ fontSize: 11, color: "#AEAEAE" }}>{item?.mode == "hybrid" ? "Classes to be held in physical classroom and also over the internet." : item?.mode == "online" ? "Classes to be conducted over the internet." : "Classes to be held in physical classroom."}</div> : <></>}
                    {user?.role?.includes("Student") ? <>
                        <center>
                            {item?.owned == 1 || enroll[item?.id] ?
                                <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "fit-content", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, marginTop: 5, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}>VIEW COURSE</Button>
                                :
                                <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                    <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (!user?.accessToken) {
                                                history.push("/login")
                                            } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                history.push("/cart")
                                            } else {
                                                setAddCartLoader(true)
                                                handleAddToCart(item?.id, item?.resourceType)
                                                getCartCount()
                                            }
                                        }}>
                                        {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}
                                    </Button>
                                    <Button loading={enrollLoader} type="primary" style={{ fontSize: `clamp(0.5em, 1.5vmin, 0.5500em)`, width: "47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (user?.accessToken) {
                                                if (item?.feeType == "free") {
                                                    setEnrollLoader(true)
                                                    handleEnroll(item?.id, item?.resourceType)
                                                } else {
                                                    history.push({
                                                        pathname: '/user-checkout',
                                                        state: { itemId: item?.id, itemType: item?.resourceType }
                                                    });
                                                }
                                            } else {
                                                history.push("/login")
                                            }
                                        }}
                                    >
                                        {enroll[item?.id] ? "ENROLLED" : item?.feeType == "free" || item?.resourceType == "course" ? "ENROLL NOW" : "BUY NOW"}
                                    </Button>
                                </div>
                            }
                        </center>
                    </> : <></>}

                </div>


            </div>
        </div>
    )
}

const AllCourseData = ({ exploreCourseData, storeResourceType, getCourseList, getVideoResourcesList, parentLink, getAllStudyMaterials, getAllTestSeries, getCheckOutItem, user, postAddSaveItem, postAddCartItem, postCourseEnrollNow, removeSaveItem, getCartCount,storeCourseIdAndType, isDownloadBarVisible, showContactBar }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [filterParam, setFilterParam] = useState({
        period: null,
        mode: null,
        free: null,
    })
    const history = useHistory()
    const [hasMore, setHasMore] = useState(true)
    const [filtersData, setFiltersData] = useState('All')
    const classArr = [{ label: "All", value: null }, { label: "Ongoing", value: "ongoing" }, { label: "Upcoming", value: "upcoming" }, { label: "Completed", value: "completed" }]
    const typeArr = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Offline", value: "offline" }, { label: "Hybrid", value: "hybrid" }]
    const modeArr = [{ label: "All", value: null }, { label: "Free", value: "free" }]
    const items = exploreCourseData?.items;

    // const fetchQAdata = () => {
    //     const page = Math.ceil(items.length / exploreCourseData.meta.itemsPerPage) + 1;
    //     getCourseList('live', page, exploreCourseData.meta.itemsPerPage, () => { })
    //     if (items.length < exploreCourseData.meta.itemsPerPage) {
    //         console.log("abcdef",items.length < exploreCourseData.meta.itemsPerPage);
    //         setHasMore(false);
    //     }

    // };


    const location = useLocation()
    console.log("abcdef", location);
    useEffect(() => {
        if (location?.state?.value == 'live') {
            if (location?.state?.categoryId == undefined) {
                togglePageLoader(true)
                getCourseList('', '', '', exploreCourseData?.meta?.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            } else if (location?.state?.type == 'pid') {
                togglePageLoader(true)
                getCourseList('', location?.state?.type, location?.state?.categoryId, exploreCourseData?.meta?.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            }
            else {
                togglePageLoader(true)
                getCourseList('', '', location?.state?.categoryId, exploreCourseData?.meta?.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            }

        } else if (location.state.value == 'recorded') {
            if (location?.state == undefined) {
                togglePageLoader(true)
                getVideoResourcesList('', '', '', exploreCourseData?.meta?.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)

                })
            }
            else if (location?.state?.type == 'pid') {
                togglePageLoader(true)
                getVideoResourcesList('', location?.state?.type, location?.state?.categoryId, exploreCourseData?.meta?.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            }
            else {
                togglePageLoader(true)
                getVideoResourcesList('', '', location?.state?.categoryId, exploreCourseData?.meta?.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            }
        }
        else if (location.state.value == 'study') {
            if (location?.state?.categoryId == undefined) {
                togglePageLoader(true)
                getAllStudyMaterials('', '', '', location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })

            } else if (location?.state?.type == 'pid') {
                togglePageLoader(true)
                getAllStudyMaterials('', location?.state?.type, location?.state?.categoryId, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })

            }
            else {
                togglePageLoader(true)
                getAllStudyMaterials('', '', location?.state?.categoryId, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            }
        }
        else {
            if (location?.state?.categoryId == undefined) {
                togglePageLoader(true)
                getAllTestSeries('', '', '', location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })

            } else if (location?.state?.type == 'pid') {
                togglePageLoader(true)
                getAllTestSeries('', location?.state?.type, location?.state?.categoryId, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })

            }
            else {
                togglePageLoader(true)
                getAllTestSeries('', '', location?.state?.categoryId, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                    togglePageLoader(false)
                })
            }
        }
    }, [])
    console.log("abcd", exploreCourseData, location, storeResourceType);
    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '#191919', fontSize: 14, fontWeight: 600, marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }
    const ClassFilter = () => {
        const handleClassToggle = (val) => {
            setFilterParam({
                ...filterParam,
                period: val,
            })

        }
        return (
            <div className='r-c-c-fs' >
                <FilterTitleName filterName="Classes" />
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {classArr?.map((item) => (
                        <div style={{
                            borderRadius: "18px",
                            backgroundColor: filterParam?.period === item?.value ? '#1089FF' : '#FFFFFF',
                            color: filterParam?.period === item?.value ? '#FFFFFF' : '#191919',
                            border: filterParam?.period === item?.value ? 'none' : '1px solid #E6E6E6',
                            padding: "5px 10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            fontSize: 12,
                            cursor: "pointer",

                        }}
                            onClick={() => {
                                handleClassToggle(item?.value);
                            }}
                        >{item?.label}</div>
                    ))}
                </div>
            </div>
        )
    }
    const TypeFilter = () => {
        const handleTypeToggle = (val) => {
            setFilterParam({
                ...filterParam,
                mode: val
            })
        }
        return (
            <div className=''>
                <FilterTitleName filterName="Type" />
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {typeArr?.map((item) => (
                        <div style={{
                            borderRadius: "18px",
                            backgroundColor: filterParam?.mode === item?.value ? '#1089FF' : '#FFFFFF',
                            color: filterParam?.mode === item?.value ? '#FFFFFF' : '#191919',
                            border: filterParam?.mode === item?.value ? 'none' : '1px solid #E6E6E6',
                            padding: "5px 10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            fontSize: 12,
                            cursor: "pointer",
                        }}
                            onClick={() => {
                                handleTypeToggle(item?.value);
                            }}
                        >{item?.label}</div>
                    ))}
                </div>
            </div>
        )
    }
    const ModeFilter = () => {
        const handleModeToggle = (val) => {

            setFilterParam({
                ...filterParam,
                free: val,
            })
        }
        return (
            <div className=''>
                <FilterTitleName filterName="Mode" />
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {modeArr?.map((item) => (
                        <div style={{
                            borderRadius: "18px",
                            backgroundColor: filterParam?.free === item?.value ? '#1089FF' : '#FFFFFF',
                            color: filterParam?.free === item?.value ? '#FFFFFF' : '#191919',
                            border: filterParam?.free === item?.value ? 'none' : '1px solid #E6E6E6',
                            padding: "5px 10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            fontSize: 12,
                            cursor: "pointer",
                        }}
                            onClick={() => {
                                handleModeToggle(item?.value)
                            }}
                        >{item?.label}</div>
                    ))}
                </div>
            </div>
        )
    }
    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible);
    };
    const filterDropDownMenu = (
        <Menu visible={dropdownVisible} className='filterParent-Live' style={{ width: "100%", padding: "20px" }}>
            {/* <ClassFilter /> */}
            <TypeFilter />
            <ModeFilter />
            <div style={{ borderBottom: '2px solid #E6E6E6', marginTop: "5px" }}></div>
            <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
                <div style={{ color: "#1089FF", fontWeight: 600, fontSize: "16px", marginTop: "5px", cursor: "pointer" }}
                    onClick={() => {
                        setFilterParam(
                            {
                                period: null,
                                mode: null,
                                free: null,
                            }
                        )
                        togglePageLoader(true)
                        getCourseList('', '', location?.state?.categoryId, exploreCourseData.meta.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => { togglePageLoader(false) })
                    }}
                >Reset</div>
                <Button style={{ color: "#fff", backgroundColor: '#1089FF', borderRadius: "5px", border: "#1089FF" }} onClick={() => {
                    togglePageLoader(true)
                    getCourseList(filterParam, '', location?.state?.categoryId, exploreCourseData.meta.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => { togglePageLoader(false) })
                }}>Apply</Button>
            </div>
        </Menu>
    );
    const [isHomeLinkHovered, setHomeLinkHovered] = useState(false);

    const handleHomeLinkHover = () => {
        setHomeLinkHovered(true);
    };

    const handleHomeLinkLeave = () => {
        setHomeLinkHovered(false);
    };
    const live = (
        <div>
            {storeResourceType == 'live' ?
                <div onClick={(e) => { e.stopPropagation() }}>
                    <div style={{ width: "200px" }}>User who enrolls to any live course can attend classes in real time and can also interact with the instructor. Live courses can be held in various modes such as online, offline as well as hybrid</div>
                </div> :
                <div onClick={(e) => { e.stopPropagation() }}>
                    <div style={{ width: "300px" }}>User who enrolls to this course will be provided with a series of series of recorded videos which they can refer to at any time of their convenience. This course comes with lecture notes and quizzes for the self evaluation. There is an option to clarify their doubts in the dedicated Q&A section</div>
                </div>}
        </div>
    );
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const CalculateMarginTop = () => {
        if (isDownloadBarVisible && showContactBar) {
            return 160
        } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
            return 110
        } else if (!isDownloadBarVisible && !showContactBar) {
            return 60
        }
    }

    return (
        <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div >
                <TopBar selectedSuggestionTemp={location?.state?.selectedSuggestion} isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked} />
            </div>
            <div className='bg-color'>

                <div className='popular-breadcrumbs m-t-10' onMouseEnter={handleHomeLinkHover} onMouseLeave={handleHomeLinkLeave}>
                    {location?.state?.categoryId == undefined ?
                        <div >
                            <Link to="/" style={{ color: isHomeLinkHovered ? '#000' : '#AEAEAE', fontFamily: "Roboto" }}>Home</Link> /<span style={{ color: "#AEAEAE", fontFamily: "Roboto" }}><h onClick={() => { history.push('/all-popular-courses') }} style={{ color: isHomeLinkHovered ? '#000' : '#AEAEAE', fontFamily: "Roboto", cursor: "pointer" }}>Popular Courses </h></span> {storeResourceType == 'live' ? '/All Live Courses' : storeResourceType == 'recorded' ? '/All Video Courses' : storeResourceType == 'test' ? '/All Online tests' : '/All Study materials'}</div>
                        // <div className='headings-font' >{storeResourceType == 'live' ? 'All Courses' : storeResourceType == 'recorded' ? 'All Video Resources' : storeResourceType == 'test' ? 'All Online tests' : 'All Study materials'}({exploreCourseData?.items?.length})<h className='m-l-10' style={{ color: "#594099" }}><InfoCircleOutlined /></h></div>
                        :
                        (<>
                            <Link to="/" style={{ color: isHomeLinkHovered ? '#000' : '#AEAEAE', fontFamily: "Roboto" }}>Home</Link>{" "}/ <span style={{ color: "#AEAEAE", fontFamily: "Roboto" }}>{parentLink?.parentName}</span>  <span style={{ color: "#191919", fontFamily: "Roboto", fontWeight: 900 }}>{`{/}${parentLink?.childName}`}</span>

                        </>)
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20 }} >{storeResourceType == 'live' ? 'All Courses' : storeResourceType == 'recorded' ? 'All Video Courses' : storeResourceType == 'test' ? 'All Online tests' : 'All Study materials'}{" "}({exploreCourseData?.items?.length})<h className='m-l-10' style={{ color: "#594099" }}>
                        <Popover content={live}>
                            <InfoCircleOutlined />
                        </Popover>
                    </h></div>
                    {storeResourceType == 'live' ?
                        (<>
                            <div className='filterDesign-Live' style={{ width: "150px", height: "35px", }}>
                                <Dropdown
                                    overlay={filterDropDownMenu}
                                    visible={dropdownVisible}
                                    onVisibleChange={handleDropdownVisibleChange}
                                    trigger={['click']}
                                    placement='bottomRight'
                                >
                                    <div className='r-c-sa' style={{ width: "100%", cursor: "pointer" }}>
                                        <div className='r-c-fs'>
                                            <img style={{ width: "15px" }} src={require("../../Assets/aulasMarket/filterIC.svg").default} />
                                            <div className='mobile-hidden'>&nbsp; Filter</div>
                                        </div>
                                        <img style={{ width: "12px" }} className='mobile-hidden' src={dropdownVisible ? dropUpIcon : dropDownIcon} /> </div>
                                </Dropdown>
                            </div>
                        </>) : (<>
                            <div style={{ display: "flex", gap: 30, marginTop: "30px" }}>
                                <Button type="primary"
                                    style={{
                                        backgroundColor: filtersData == 'All' ? "#1089FF" : "#fff",
                                        color: filtersData == 'All' ? "#FAFAFA" : '#636363',
                                        border: filtersData == 'All' ? "#FAFAFA" : '#636363',
                                        borderRadius: "20px",
                                        width: "80px",
                                        height: "35px",
                                    }}
                                    onClick={() => {
                                        setFiltersData('All')
                                        if (storeResourceType == 'study') {
                                            togglePageLoader(true)
                                            getAllStudyMaterials('all', location?.state?.type, '', location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                                                togglePageLoader(false)

                                            })

                                        } else if (storeResourceType == 'test') {
                                            togglePageLoader(true)
                                            getAllTestSeries('all', location?.state?.type, '', location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                                                togglePageLoader(false)
                                            })
                                        }
                                        else {
                                            togglePageLoader(true)
                                            getVideoResourcesList('all', location?.state?.type, location?.state?.categoryId, exploreCourseData.meta.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                                                togglePageLoader(false)
                                            })
                                        }
                                    }}>All</Button>
                                <Button
                                    type="primary"
                                    style={{
                                        backgroundColor: filtersData == 'Free' ? "#1089FF" : '#fff',
                                        color: filtersData == 'Free' ? "#FAFAFA" : '#636363',

                                        border: filtersData == 'Free' ? "#FAFAFA" : '#636363',
                                        borderRadius: "20px",
                                        width: "80px",
                                        height: "35px",
                                    }}
                                    onClick={() => {
                                        setFiltersData('Free')
                                        if (storeResourceType == 'study') {
                                            togglePageLoader(true)
                                            getAllStudyMaterials('free', location?.state?.type, '', location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                                                togglePageLoader(false)
                                            })
                                        } else if (storeResourceType == 'test') {
                                            togglePageLoader(true)
                                            getAllTestSeries('free', location?.state?.type, '', location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                                                togglePageLoader(false)
                                            })
                                        } else {
                                            togglePageLoader(true)
                                            getVideoResourcesList('free', location?.state?.type, location?.state?.categoryId, exploreCourseData.meta.itemsPerPage, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null, () => {
                                                togglePageLoader(false)
                                            })
                                        }

                                    }}
                                >Free</Button>
                            </div>
                        </>)}

                </div>

                {storeResourceType == 'study' || storeResourceType == 'test' ?
                    <div className='m-t-30 course-card' >
                        {exploreCourseData?.items?.map((item) => {
                            return (
                                <StudyMaterialsCards item={item} history={history} getCheckOutItem={getCheckOutItem} user={user} postAddSaveItem={postAddSaveItem} postAddCartItem={postAddCartItem} postCourseEnrollNow={postCourseEnrollNow} removeSaveItem={removeSaveItem} getCartCount={getCartCount} type={location?.state?.value} storeCourseIdAndType={storeCourseIdAndType}/>
                            )
                        })}

                    </div> : null}


                {(storeResourceType != 'study' && storeResourceType != 'test') ?
                    // <InfiniteScroll
                    //     dataLength={exploreCourseData?.items?.length}
                    //     next={() => fetchQAdata()}
                    //     hasMore={hasMore}
                    //     loader={<h4>Loading...</h4>}
                    //     height={500}
                    // >
                    <div className='m-t-30 course-card' >

                        {exploreCourseData?.items?.map((item) => {
                            return (
                                <LiveCourseCards item={item} history={history} getCheckOutItem={getCheckOutItem} user={user} postAddSaveItem={postAddSaveItem} postAddCartItem={postAddCartItem} postCourseEnrollNow={postCourseEnrollNow} removeSaveItem={removeSaveItem} getCartCount={getCartCount} storeCourseIdAndType={storeCourseIdAndType}/>

                            )
                        })}
                    </div>
                    // </InfiniteScroll>
                    :
                    null}
            </div>
            <NewFooter/>
            <PageDataLoader visible={pageLoader} />

        </div>
    )
}


const mapStateToProps = (state) => {
    const { exploreCourseData, storeResourceType, parentLink, isDownloadBarVisible, showContactBar } = state.studentMarketPlace
    const { user } = state.session

    return {
        exploreCourseData, storeResourceType, parentLink, user, isDownloadBarVisible, showContactBar
    }
};

const mapDispatchToProps = (dispatch) => ({
    getCourseList: (filter, type, page, totalPage, search, callback) => dispatch(getCourseList(filter, type, page, totalPage, search, callback)),
    getVideoResourcesList: (filtersData, type, categoryId, totalPage, search, callback) => dispatch(getVideoResourcesList(filtersData, type, categoryId, totalPage, search, callback)),
    getAllStudyMaterials: (filtersData, type, categoryId, search, callback) => dispatch(getAllStudyMaterials(filtersData, type, categoryId, search, callback)),
    getAllTestSeries: (filtersData, type, categoryId, search, callback) => dispatch(getAllTestSeries(filtersData, type, categoryId, search, callback)),
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCartCount: () => dispatch(getCartCount()),
    storeCourseIdAndType:(key,val) => dispatch(storeCourseIdAndType(key,val))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllCourseData)