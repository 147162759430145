import React, {useState, useEffect} from 'react';
import {Modal, Button, Input} from 'antd';
import '../styles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditCommentModal({
    visible, closeModal,
    comment
}) {
    const modules = {
        toolbar: false,
      }

    return (
        <Modal
            className="modal-border-15"
            visible={visible}
            footer={false}
            onCancel={() => {closeModal()}}
        >
            <div className="text-md bold-600 color-purple m-t-20 m-b-10">
                Edit Comment
            </div>
            <div className='r-ac m-b-20 m-t-20'>
                <div className='m-r-20 flex-1'>
                <ReactQuill
                    theme={"snow"}
                    modules={modules}
                    style={{height: "100%"}}
                    readOnly={true}
                    // value={announcementSingle.details ? JSON.parse(announcementSingle.details).web : null}
                    value={comment}
                    placeholder={'Enter Reason'}
                    />
                </div>
            </div>
            {/* <Input.TextArea style={{height: 100}} value={comment} disabled style={{color: Color.black}} /> */}
            <div className="r-c-c m-t-30">
                    <Button
                        shape="round"
                        style={{width: 150, marginRight: 10}}
                        onClick={() => { closeModal()}}
                    >
                        Close
                    </Button>
                </div>
        </Modal>
    )
}



export default EditCommentModal;