
import React, { useState, useEffect, useRef } from 'react';
import { Bar } from '@ant-design/plots';
import { connect } from 'react-redux';
import loader from '../Assets/gifs/section-loader.gif'


const GroupedBarPlot = ({ data, value1, formCrmOverview, isGraphLoader, source }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.scrollTop = chartRef.current.scrollHeight;
        }
    }, []);

    // const handleScroll = () => {
    //     const element = chartRef.current;
    //     if (!element) return;

    //     const { scrollTop, scrollHeight, clientHeight } = element;
    //     const atBottom = scrollHeight - scrollTop === clientHeight;

    //     if (atBottom && visibleRange.start > 0) {
    //         console.log("Scrolled to bottom");
    //         setVisibleRange(prevRange => ({
    //             start: Math.max(0, prevRange.start - 10),
    //             end: Math.max(10, prevRange.end - 10)
    //         }));
    //     } else if (scrollTop === 0) {
    //         setVisibleRange(prevRange => ({
    //             start: prevRange.start + 10,
    //             end: prevRange.end + 10
    //         }));
    //     }
    // };


    const config = {
        data,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        seriesField: 'type',
        marginRatio: 0,
        legend: false,
        barStyle: {
            radius: [4, 4, 0, 0],
        },
        xAxis: {
            line: {
                lineDash: [4, 4],
                style: {
                    stroke: '#AEAEAE',
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: '#AEAEAE',
                        lineDash: [5, 5],
                    },
                },
            },
        },
        yAxis: {
            label: {
                formatter: (value) => {
                    return value.length > 10 ? `${value.slice(0, 10)}...` : value;
                },
            },
        },
        color: ["#594099"],
        minBarWidth: 10,
        maxBarWidth: 10,
        scrollbar: {
            type: 'vertical',
            ratio: 1.5,
            style: {
                trackColor: '#E6E6E6',
                thumbColor: '#AEAEAE',
            },
        },
        tooltip: {
            customContent: (val, item) => {
                return (
                    <div style={{ padding: 10, width: "150px" }}>
                        <div style={{ fontSize: "14px", color: "#636363", fontWeight: 400, marginTop: "5px" }}>
                            {item[0]?.data?.label}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <div style={{ display: "flex", gap: 10 }}>
                                <div style={{ height: "12px", width: "12px", backgroundColor: "#594099", borderRadius: 2 }}></div>
                                <div>{item[0]?.data?.type} :</div>
                            </div>
                            <div>{formCrmOverview ? null : '₹'} {item[0]?.data?.value}</div>
                        </div>
                    </div>
                );
            },
        },
    };
    
    return (
        <div style={{ position: "relative", width: "100%" }}>
            <div className={source === "courseHome" ? "course-bar-graph-size" : value1 == 'modal' ? "course-modal-graph-size" : formCrmOverview ? 'crm-bar-graph-size' : "default-bar-graph-size"} style={{ width: "100%", paddingLeft: "30px" }}>
                {isGraphLoader ?
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 40, }}>
                        <img src={loader} width={120} height={70} />
                    </div> :
                    <Bar {...config} />}
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    const { } =
        state.userManagement;
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedBarPlot);