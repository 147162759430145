import React, { useState } from 'react';
import { Modal, Button } from 'antd';

function PreviewModal(props) {
    return (

        <Modal wrapClassName="imagePreview" centered={true} okText={"Send"} visible={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel}>
            <div style={{ width: "fit-content", height: "fit-content",margin:"auto" }}><img style={{ width: "100%", height: "100%" }} src={`${props.image}`} alt="" /></div>
        </Modal>
    );
}

export default PreviewModal
