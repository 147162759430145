import { Api, Notification } from "../services";
import { getStats } from "./userManagement";
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const GET_INFO_DATA = 'GET_INFO_DATA'
export const GET_OVERVIEW_DATA = 'GET_OVERVIEW_DATA'

export const getDashBoardData = (res) => ({
  type: GET_DASHBOARD_DATA,
  res,
});

export const getInfoData = (res) => ({
  type: GET_INFO_DATA,
  res
});

export const getOverviewData = res => ({
  type: GET_OVERVIEW_DATA,
  res
});

export const fetchDashBoardData = (callback) => {
  return (dispatch, getState) => {
    return Api.get(`/dashboard`)
      .send((response) => {
        if (callback) {
          callback(response)
        }
        if (response !== undefined) {
          dispatch(getDashBoardData(response))
        }
      });
  };
};

export const fetchInfoBoardData = (callback) => {
  return (dispatch, getState) => {
    return Api.get(`/dashboard/getInfoBoard`).send((response) => {
      if (response !== undefined) {
        dispatch(getInfoData(response))

      }
    })
  }
}

export const fetchOverviewData = (params, callback) => {
  return (dispatch, getState) => {
    const { } = getState().dashBoard
    const url = `/dashboard/overview`
    return Api.get(url).params(params).send(response => {
      if (response !== undefined) {
        dispatch(getOverviewData(response))
      };
      callback();
    });
  };
};