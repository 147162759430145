import { Table } from 'antd'
import React, { useState } from 'react'
import TimetableCard from './timetableCard';
import EditFormModal from './modals/editForm';
import { connect } from 'react-redux';
import DeleteConfirmModal from './modals/deleteConfirmModal';
import moment from 'moment';

const Timetable = ({ timetableDataSource, timetableItem, scheduleId, togglePageLoader, delOrCancel, access }) => {
    const [isVisibleEditSchedule, setVisibleEditScheduleTime] = useState(false)
    const [isDeleteModal, toogleDeleteModal] = useState(false)
    return (
        <div style={{ overflowX: 'scroll' }}>
            <table style={{ marginTop: '18px', width: '100%' }}>
                <thead className="tablehead">
                    <tr>
                        <th style={{ color: '#636363' }}>Time/Day</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>SUNDAY</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>MONDAY</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>TUESDAY</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>WEDNESDAY</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>THURSDAY</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>FRIDAY</th>
                        <th style={{ color: '#636363', textAlign: 'center' }}>SATURDAY</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.sunday).format("DD-MM-YYYY")}</td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.monday).format("DD-MM-YYYY")}</td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.tuesday).format("DD-MM-YYYY")}</td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.wednesday).format("DD-MM-YYYY")}</td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.thursday).format("DD-MM-YYYY")}</td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.friday).format("DD-MM-YYYY")}</td>
                        <td style={{ color: '#636363', textAlign: 'center' }}>{moment(timetableDataSource?.dates?.saturday).format("DD-MM-YYYY")}</td>
                    </tr>
                </thead>
                <tbody className="tableBody">
                    {timetableDataSource ? timetableDataSource?.timetable?.map((item) => (
                        <tr>
                            <td className="tableBodyTime"><div style={{ marginTop: "75px" }}>{item?.time}</div></td>
                            <td className="tableBodyCell">{Object.keys(item.sunday).length != 0 ? <TimetableCard items={item.sunday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                            <td className="tableBodyCell">{Object.keys(item.monday).length != 0 ? <TimetableCard items={item.monday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                            <td className="tableBodyCell">{Object.keys(item.tuesday).length != 0 ? <TimetableCard items={item.tuesday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                            <td className="tableBodyCell">{Object.keys(item.wednesday).length != 0 ? <TimetableCard items={item.wednesday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                            <td className="tableBodyCell">{Object.keys(item.thursday).length != 0 ? <TimetableCard items={item.thursday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                            <td className="tableBodyCell">{Object.keys(item.friday).length != 0 ? <TimetableCard items={item.friday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                            <td className="tableBodyCell">{Object.keys(item.saturday).length != 0 ? <TimetableCard items={item.saturday} setVisibleEditScheduleTime={setVisibleEditScheduleTime} /> : null}</td>
                        </tr>

                    )) : null}


                </tbody>



            </table>
            {access === true ?
                <div>
                    {(isVisibleEditSchedule && <EditFormModal isModalVisible={isVisibleEditSchedule} setModalVisible={setVisibleEditScheduleTime} toogleDeleteModal={toogleDeleteModal} editData={timetableItem} togglePageLoader={togglePageLoader} />)}
                    {(isDeleteModal && <DeleteConfirmModal isModalVisible={isDeleteModal} setModalVisible={toogleDeleteModal} id={scheduleId} delOrCancel={delOrCancel} />)}
                </div>
                : null}
        </div>

    )
}
const mapStateToProps = (state) => {
    const {
        timetableItem,
        scheduleId,
        delOrCancel,
        access

    } = state.schedule;
    return {
        timetableItem,
        scheduleId,
        delOrCancel,
        access
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Timetable);