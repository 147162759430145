import React from 'react';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import 'antd/lib/typography/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/card/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/collapse/style/index';
import 'antd/lib/radio/style/index';
import { Color } from '../../../services';
import "react-quill/dist/quill.snow.css";
import '../styles.css';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import { toggleViewQuestionModal, toggleQuestionModal, removeCurrentQuestion } from '../../../actions/assignment';


function getFilename(url){
    return url ? url.split('/').pop().split('#').shift().split('?').shift() : null;
}

const answerType = {
  Subjective: "Numeric",
  Image: "Subjective",
  MCQ: "Objective"
}

class ViewQuestionModal extends React.Component {
  

    handleClose(){
        this.props.toggleViewQuestionModal(false);
        this.props.removeCurrentQuestion();
    }

    handleEditQuestionModal = () => {
        const { questionImagesList, optionsImagesList, oldImageList, toggleQuestionModal, assignmentTaskParams, toggleViewQuestionModal} =  this.props;
        toggleViewQuestionModal(false);
        toggleQuestionModal(true);
        Array.from( new DOMParser().parseFromString( assignmentTaskParams.statement, 'text/html' )
        .querySelectorAll( 'img' ) )
        .map( img => {
          questionImagesList.push(getFilename(img.getAttribute( 'src' )))
          oldImageList.push(getFilename(img.getAttribute( 'src' )))
          return 1
        })
        if(assignmentTaskParams.options){
          assignmentTaskParams.options.forEach((option, index) => {
            Array.from( new DOMParser().parseFromString( option.value, 'text/html' )
            .querySelectorAll( 'img' ) )
            .map( (img) => {
              optionsImagesList[index].push(getFilename(img.getAttribute( 'src' )))
              oldImageList.push(getFilename(img.getAttribute( 'src' )))
              return 1
            } )
          })
        }
    
      }


  render() {
    const { viewQModal, assignmentTaskParams, currentAssignment } = this.props;

    // const uploadButton = (
    //   <div>
    //     {this.state.imgLoading ? <LoadingOutlined /> :
    //       <MdImage style={{color:Color.primary, fontSize:'1.6em'}} />
    //     }
    //   </div>
    // );
    

    return (
      <Modal
        centered
        width={'50%'}
        // style={{}}
        footer={
            <div>
                 <Button type="ghost" style={{borderRadius: 10}} onClick={() => this.handleClose()}>
                    Close
                </Button>
                {currentAssignment.status !== "Published" &&
                <Button type="primary" style={{borderRadius: 10}} onClick={() => this.handleEditQuestionModal()}>
                    Edit
                </Button>}
            </div>
            
        }
        onCancel={() => {
            this.handleClose()
        }}
        closable={true}
        // closable={assignmentTaskParams.id ? false : true}
        visible={viewQModal}
        style={{
          height:'100%',
          overflowY: 'auto',
        }}
        className='radius-10 qmodal'
        title={<div className='color-purple'>
            View Question
        </div>}
      >
        <div>
          <div className='r-jsb'>
            <div style={{flex: 1}} className='color-greenblack'>
              <div className='p-5 questionLightGreyBox'>
                <div style={{display: 'flex'}}>
                  <div className='color-purple text-xmd'>
                    Statement <span style={{color: Color.red}}>*</span>
                  </div>
                </div>

                <div className="quest">{ ReactHtmlParser(assignmentTaskParams.statement, { transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }

                    if (node.type === 'tag' && node.name === 'img') {
                      return <img style={{maxHeight: '40vh', maxWidth: '30vw'}} src={node.attribs.src} alt=""/>;
                    }
                  }}) }</div>
              </div>
              {(assignmentTaskParams.type !== 'MCQ') ?
                <div className='p-5 questionLightGreyBox'>
                  <div className='color-purple text-xmd'>
                    Answer {assignmentTaskParams.type === "Image" ?  '(optional)' : <span style={{color: Color.red}}>*</span>}
                  </div>
                  <div>{assignmentTaskParams.answer}</div>
                </div>
                : null
              }
            </div>
            <div>
            {currentAssignment.gradingType === "Graded" && 
            <>
              <div style={{margin: '0px 0px 0px 10px'}} className='p-5 questionLightGreyBox'>
                <div  className='color-purple text-xmd'>
                  Marks
                </div>
              </div>
              <div className="display-flex" style={{justifyContent: "space-around"}}>
                  <div>{assignmentTaskParams.positiveMarks}</div> 
              </div> 
            </>}
            <div style={{margin: '0px 0px 10px 10px'}} className='p-5 questionLightGreyBox'>
              <div className='color-purple text-xmd'>
                Answer Type
              </div>
            </div>
            <div className='r-c-c'>{answerType[assignmentTaskParams.type]}</div>
            </div>
          </div>
         
          <div>
            {assignmentTaskParams.type === "MCQ" && assignmentTaskParams.options && assignmentTaskParams.options.length ?
              assignmentTaskParams.options.map((data, index) => //assignmentTaskParams.options
               { 
                 const objAns = typeof(assignmentTaskParams.answer) === "string" ? parseInt(assignmentTaskParams.answer) : assignmentTaskParams.answer;
                 return (<div key={index.toString()} style={{padding: 0}} className='assignmentItem m-t-10'>
                  <div
                    className='r-jsb'
                  >
                    <div
                      className='color-purple text-xmd p-5'>
                      {String.fromCharCode(65 + index)} :
                    </div>
                    <div className='flex-1 color-greenblack'>
                      <div className='color-purple text-xmd m-t-5'>
                        Option: 
                        { assignmentTaskParams.options[index] ?
                          ReactHtmlParser(assignmentTaskParams.options[index].value, { transform: (node) => {
                              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                return <TeX math={`${node.attribs['data-value']}`} />;
                              }
                              // if (node.type === 'tag' && node.name === 'math') {
                              //   return <MathJax math={String.raw`${assignmentTaskParams.options[index].value}`} />;
                              // }
                              if (node.type === 'tag' && node.name === 'img') {
                                return <img style={{maxWidth: '40vw',  maxHeight: '40vh'}} src={node.attribs.src} alt="" />;
                              }
                            }}) : '' }
                      </div>
                    </div>
                    <Radio
                      style={{padding: 5,}}
                      key={index.toString()}
                      disabled
                      value={index}
                      checked={data ? data.correct : false}
                    />
                  </div>
                </div>)}
              )
            : null}
          </div>
        </div>
      </Modal>

      /*<Modal
      visible={displayOptionModalBool}
      width={"70%"}
      title="Editor"
      closable={false}
      footer={
        <div>
          <Button type="primary" onClick={() => this.props.displayOptionModal(false)}>Done</Button>
        </div>
      }
      >

      <FormulaEditor
          id={`option${displayOptionIndex}`}
          optText={quillEditorText['option'+displayOptionIndex]}
          // data={info.data}
          onChange={(value) => {
            // this.setState({info: {...this.state.info, data: value}})
            // setCurrentQuestion(value)
          }}
          // imagesList={optionsImagesList[index]}
          user={user}
          deletedImages={deletedImages}
          imagesList={questionImagesList }
          // deleteImages={(imgList, index) => deleteImages(imgList, index)}
       />
      </Modal>*/
    )
  }
}

const mapStateToProps = state => {
  const { viewQModal, assignmentTaskParams, questionImagesList, optionsImagesList, oldImageList, currentAssignment } = state.assignment;
  return { viewQModal, assignmentTaskParams, questionImagesList, optionsImagesList, oldImageList, currentAssignment };
};

const mapDispatchToProps = dispatch => ({ 
  toggleViewQuestionModal: (bool) => dispatch(toggleViewQuestionModal(bool)),
  toggleQuestionModal: bool => dispatch(toggleQuestionModal(bool)),
  removeCurrentQuestion: () => dispatch(removeCurrentQuestion()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestionModal);
