export const locations = [
  {
    path: "/dashboard-student",
    key: "28",
  },
  {
    path: "/user-management",
    key: "1",
  },
  {
    path: "/user-management",
    key: "1",
  },
  {
    path: "/user-management/students",
    key: "1",
  },
  {
    path: "/user-management/teacher",
    key: "1",
  },

  {
    path: "/user-management/data-operator",
    key: "1",
  },
  {
    path: "/user-management/operations",
    key: "1",
  },
  {
    path: "/user-management/students/details",
    key: "1",
  },
  {
    path: "/user-management/teacher/details",
    key: "1",
  },
  {
    path: "/user-management/standard",
    key: "1",
  },
  {
    path: "/user/details",
    key: "1",
  },
  {
    path: "/user-management/data-operator/details",
    key: "1",
  },
  {
    path: "/user-management/operation/details",
    key: "1",
  },
  {
    path: "/user-management/all-requests",
    key: "1",
  },
  {
    path: "/user-management/all-requests/approval",
    key: "1",
  },
  {
    path: "/user-management/students/unassigned",
    key: "1",
  },

  {
    path: "/",
    key: "28",
  },
  {
    path: "/live-class",
    key: "3",
  },
  {
    path: "/schedule",
    key: "4",
  },
  {
    path: "/schedule-attendance",
    key: "4",
  },
  {
    path: "/broadcast",
    key: "4",
  },
  {
    path: "/today/physics124",
    key: "3",
  },
  {
    path: "/classroom-resources",
    key: "3",
  },
  {
    path: "/users",
    key: "5",
  },
  {
    path: "/classroom",
    key: "18",// batches key was 6
  },
  {
    path: "/classroom/classroom-details",
    key: "18",
  },
  {
    path: "/classroom/allstudents",
    key: "18",
  },
  {
    path: "/students/enrolled",
    key: "18",
  },
  {
    path: "/classroom/editchapters",
    key: "18",
  },
  {
    path: "/classroom/resources",
    key: "18",
  },
  {
    path: "/classroom/curriculum",
    key: "18",
  },
  {
    path: "/syllabus",
    key: "7",
  },
  {
    path: "/announcement",
    key: "8",
  },
  {
    path: "/all-announcement",
    key: "8",
  },
  {
    path: "/attendance",
    key: "9",
  },
  {
    path: "/attendance-geofence",
    key: "21",
  },
  // {
  //   path: '/attendance/teacher',
  //   key: '9',
  // },
  // {
  //   path: '/attendance/standard',
  //   key: '9',
  // },
  // {
  //   path: '/my-attendance',
  //   key: '9',
  // },
  {
    path: "/digital-library/division-card",
    key: "13",
  },
  {
    path: "/digital-library/access-management",
    key: "13",
  },
  {
    path: "/digital-library/dashbord-subject",
    key: "13",
  },
  {
    path: "/digital-library/sub-details-empty",
    key: "13",
  },
  {
    path: "/digital-library/sub-details",
    key: "13",
  },
  {
    path: "/digital-library/view-content",
    key: "13",
  },
  {
    path: "/digital-library/subject-details",
    key: "13",
  },
  {
    path: "/digital-library/chapter-details",
    key: "13",
  },
  {
    path: "/digital-library/content",
    key: "13",
  },
  {
    path: "/digital-library/buy-storage",
    key: "13",
  },
  {
    path: "/digital-library/broadcast-recordings",
    key: "13",
  },
  {
    path: "/digital-library/broadcast-recordings-details",
    key: "13",
  },
  {
    path: "/assignment",
    key: "10",
  },
  {
    path: "/test",
    key: "11",
  },
  {
    path: "/insideChapter",
    key: "11",
  },
  {
    path: "/student-test",
    key: "11",
  },
  {
    path: "/profile",
    key: "-1",
  },
  {
    path: "/student-fees",
    key: "14",
  },
  {
    path: "/kyc-form-rejected",
    key: "14",
  },
  {
    path: "/kyc-form-submitted",
    key: "14",
  },
  {
    path: "/kyc-form",
    key: "14",
  },
  {
    path: "/fee-management",
    key: "14",
  },
  {
    path: "/fee-management/all-activities",
    key: "14",
  },
  {
    path: "/fee-management/all-transactions",
    key: "14",
  },
  {
    path: "/fee-management/class-details",
    key: "14",
  },
  {
    path: "/fee-management/class-details/student-profile",
    key: "14",
  },
  {
    path: "/fee-management/class-details/all-activities",
    key: "14",
  },
  {
    path: "/fee-management/all-students",
    key: "14",
  },
  {
    path: "/fee-management/all-students/courses",
    key: "14",
  },
  {
    path: "/fee-management/all-students/courses/view-all",
    key: "14",
  },
  {
    path: "/fee-management/invoice",
    key: "14",
  },
  {
    path: "/fee-management/course-details",
    key: "14",
  },
  {
    path: "/notification",
    key: "-1",
  },
  {
    path: "/care/admin",
    key: "16",
  },
  {
    path: "/care/admin/conversations",
    key: "16",
  },
  {
    path: "/care/admin/conversations/user",
    key: "16",
  },
  {
    path: "/care/submit-success",
    key: "16",
  },
  {
    path: "/care",
    key: "16",
  },
  {
    path: "/care/chat",
    key: "16",
  },
  {
    path: "/care/faqs",
    key: "16",
  },
  {
    path: "/care/covid-resources",
    key: "16",
  },
  {
    path: "/testlist",
    key: "11",
  },
  {
    path: "/test-subject",
    key: "11",
  },
  {
    path: "/admin-test",
    key: "11",
  },
  {
    path: "/admin-test-view",
    key: "11",
  },
  {
    path: "/test-generate-questions-list",
    key: "11",
  },
  {
    path: "/test-evaluation-questions-list",
    key: "11",
  },
  {
    path: "/test-result-screen",
    key: "11",
  },
  {
    path: "/test-publish-result-screen",
    key: "11",
  },
  {
    path: "/test-std-to-subjects-screen",
    key: "11",
  },
  {
    path: "/test-submit-detail-screen",
    key: "11",
  },
  {
    path: "/test-stud-submit-ques-screen",
    key: "11",
  },
  {
    path: "/review-list",
    key: "11",
  },
  {
    path: "/feedback-list",
    key: "11",
  },
  {
    path: "/marking-scheme-dashboard",
    key: "11",
  },
  {
    path: "/create-test",
    key: "11",
  },
  {
    path: "/edit-test",
    key: "11",
  },
  {
    path: "/test-import-classes",
    key: "11"
  },
  {
    path: "/sample-question-paper",
    key: "11"
  },
  {
    path: "/sample-list-view",
    key: "11"
  },
  {
    path: "/test-import-subjects",
    key: "11"
  },
  {
    path: "/test-import-subjectwiseChapters",
    key: "11"
  },
  {
    path: "/sub-ques-import-page",
    key: "11"
  },
  {
    path: "/result-list",
    key: "11"
  },
  {
    path: "/publish-list",
    key: "11"
  },
  {
    path: "/syllabus-subjects",
    key: "11",
  },
  {
    path: "/notifications-home",
    key: "17",
  },
  {
    path: "/practise-test",
    key: "19"
  },
  {
    path: "/practiseTest-questionScreen",
    key: "19"
  },
  {
    path: "/practise-test-import-classes",
    key: "19"
  },
  {
    path: "/practise-test-import-subwise",
    key: "19"
  },
  {
    path: "/practise-test-import-sub-chapters",
    key: "19"
  },
  {
    path: "/practise-test-SubQues-import-page",
    key: "19"
  },
  {
    path: "/practise-test-noneditable-questions",
    key: "19"
  },
  {
    path: "/practise-test-create-list-view",
    key: "19"
  },
  {
    path: "/practise-test-syllabus-page",
    key: "19"
  },
  {
    path: "/practise-test-subject-page",
    key: "19"
  },
  {
    path: "/practise-test-chapter-page",
    key: "19"
  },
  {
    path: "/practise-test-tabs-page",
    key: "19"
  },
  {
    path: "/practise-test-view-all",
    key: "19"
  },
  {
    path: "/student-practice-home",
    key: "19"
  },
  {
    path: "/practice-result-screen",
    key: "20"
  },
  {
    path: "/student-practice-tabs",
    key: "20"
  },
  {
    path: "/student-practise-subjects",
    key: "20"
  },
  {
    path: "/student-practice-chapters",
    key: "20"
  },
  {
    path: "/attendance-dashBoard",
    key: "21",
  },
  {
    path: "/staff-leave/summary",
    key: "21",
  },
  {
    path: "/user-profile/others",
    key: "21",
  },
  {
    path: "/attendance/students",
    key: "21",
  },
  {
    path: "/attendance/staff",
    key: "21",
  },
  {
    path: "/attendance/requests",
    key: "21",
  },
  {
    path: '/student-analytic-graph',
    key: "21"
  },
  {
    path: "/attendance-leaves",
    key: "21",
  },
  {
    path: "/student-profile-detail",
    key: "21",
  },
  {
    path: "/attendance-requirements",
    key: "21",
  },
  {
    path: "/holiday-page",
    key: "21",
  },
  {
    path: "/Students-attendance",
    key: "21",
  },
  {
    path: "/Class-page",
    key: "21",
  },
  {
    path: "/student-detail-page",
    key: "21",
  },
  {
    path: "/leave-graph",
    key: "21",
  },
  {
    path: "/operations-view-all",
    key: "21",
  },
  {
    path: "/attendance-details-view",
    key: "21",
  },
  {
    path: "/users-attendance",
    key: "21",
  },
  {
    path: "/users-geoFence",
    key: "21",
  },
  {
    path: "/leaves-dashboard",
    key: "21",
  },
  {
    path: "/attendancetab-details",
    key: "21",
  },
  {
    path: "/request-viewall",
    key: "21",
  },
  {
    path: "/my-attendance-student-dashBoard",
    key: "21",
  },

  // old attendance keys
  {
    path: "/attendance-student",
    key: "21",
  },
  {
    path: "/attendance-teacher",
    key: "21",
  },
  {
    path: "/attendance-data-operator",
    key: "21",
  },
  {
    path: "/attendance-operation",
    key: "21",
  },

  {
    path: "/allusers-view-all",
    key: "21"
  },
  {
    path: "/leaves-overview",
    key: "21"
  },
  {
    path: "/student-fees/transactions",
    key: "14"
  },
  {
    path: "/student-fees/all",
    key: "14"
  },
  {
    path: "/courses",
    key: "18"
  },
  {
    path: "/add-live-courses",
    key: "18"
  },
  {
    path: "/add-video-resources",
    key: "18"
  },
  {
    path: "/add-online-test-series",
    key: "18"
  },
  {
    path: "/add-study-materials",
    key: "18"
  },
  {
    path: "/live-courses-data",
    key: "18"
  },
  {
    path: "/video-resources-data",
    key: "18"
  },
  {
    path: "/online-test-series-details",
    key: "18"
  },
  {
    path: "/study-materials-data",
    key: "18"
  },
  {
    path: "/course-details",
    key: "18"
  },
  {
    path: "/testSeries/createTest",
    key: "18"
  },
  {
    path: "/testSeries/addQuestions",
    key: "18"
  },
  {
    path: "/offers",
    key: "18"
  },
  {
    path: "/create-offers",
    key: "18"
  },
  {
    path: "/add-video-resources-content",
    key: "18"
  },
  {
    path: "/add-study-materials-content",
    key: "18"
  },
  {
    path: "/course/add-quiz",
    key: "18"
  },
  {
    path: "/search-result",
    key: "18"
  },
  {
    path: "/search-results",
    key: "18"
  },
  {
    path:'/students-enrolled',
    key:'18'
  },
  {
    path: "/students-view-all",
    key: "18"
  },
  {
    path: '/fee-details',
    key: '18'
  },
  {
    path: "/market-place-crm",
    key: "30"
  },
  {
    path: "/overview-crm",
    key: "30"
  },
  {
    path: "/student-ChapterTest",
    key: "11"
  },
  {
    path: "/student-practise-subjects",
    key: "19"
  },
  {
    path: "/student-practice-tabs",
    key: "19"
  },
  {
    path: "/student-InsideChapTest",
    key: "11"
  },
  {
    path: "/student-test/subject",
    key: "11"
  },
  {
    path: "/student-test/result",
    key: "11"
  },
  {
    path: "/student-test/solutions",
    key: "11"
  },
  {
    path: "/aulas-wallet",
    key: "12"
  },
  {
    path: "/complete-kyc",
    key: "12"
  },
  {
    path: "/payment-history",
    key: "12"
  },
  {
    path: "/payment-request",
    key: "12"
  },
  {
    path: "/kyc-details",
    key: "12",
  },
  {
    path: '/user-management',
    key: '2'
  },
  {
    path: '/user/student',
    key: '2'
  },
  {
    path: '/user/staff',
    key: '2'
  },
  {
    path: '/user-management/deleted-users',
    key: '2'
  },
  {
    path: "/user-management/courses",
    key: "2",
  },
  {
    path: "/user-management/students-by-courses",
    key: "2",
  },
  {
    path: "/user-management/course-standards",
    key: "2",
  },
  {
    path: '/user-management/transfer-data',
    key: '2'
  },
  {
    path: '/user/registration/requests',
    key: '2'
  },
  {
    path: '/user-management/profile',
    key: '2'
  },
  {
    path: '/courses-list',
    key: '31'
  },
  {
    path: '/online-test-series',
    key: '31'
  },
  {
    path: '/testSeries/result',
    key: '31'
  },
  {
    path: '/course-details-page',
    key: '31'
  },
  {
    path: '/course-chapters',
    key: '31'
  },
  {
    path: '/video-resources',
    key: '31'
  },
  {
    path: '/study-material',
    key: '31'
  },
  {
    path: '/pdf-view',
    key: '31'
  },
  {
    path: '/Q&A',
    key: '31'
  },
  {
    path: '/market-place',
    key: '34'
  },
  {
    path: '/liveplayer',
    key: '4'
  }
];