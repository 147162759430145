import React, { useState } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import {
  deleteCourseWithId,
  getStudentsDetailsById,
} from "actions/userManagement";
import {
  cancelTransaction,
  deleteTransaction,
  fetchTransaction,
  getFeeOverView,
} from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";

function ConfirmModal({
  userApiCall,
  isModalVisible,
  setViewCourseDeleteConfirmModal,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,
  type,
  name,
  role,
  deleteCourseWithId,
  getStudentsDetailsById,
  setViewCourseDetails,
  setShowPayRequestModal,
  setShowConfirmationModal,
  fetchTransaction,
  cancelTransaction,
  deleteApiCall,
  deleteTransaction,
  getFeeOverView,
  setPageLoader,
  id,
  transactionPage,
  getTransactionData,
  startDate, 
  endDate, 
  week, 
  isYear
}) {
  console.log("AA-REQUEST DETAILS",deleteApiCall,userApiCall);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  // const [pageLoader, setPageLoader] = useState(true);
  return (
    <div>
      <Modal
        maskClosable={false}
        className="deleteUserModal"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{ loading: deleteBtnLoader }}
        onOk={() => {
          setDeleteBtnLoader(true);
          //   deleteCourseWithId(
          //     () => {
          //       setDeleteBtnLoader(false);
          //       getStudentsDetailsById();
          //     },
          //     () => {
          //       setViewCourseDetails(true);
          //       setViewCourseDeleteConfirmModal(false);
          //     }
          //   );
          // setCancelBtnLdr(true);
          if (deleteApiCall) {
            deleteTransaction(
              transactionPage,
              id,
              () => {
                setDeleteBtnLoader(false);
              },
              () => {
                if(transactionPage) {getTransactionData(startDate, endDate, week, isYear ,()=>{},()=>{}), setShowConfirmationModal(false);} else {
                setShowConfirmationModal(false);
                // setShowPayRequestModal(false);
                // fetchTransaction();
                setPageLoader(true);
                if (userApiCall) {
                  getStudentsDetailsById(() => {
                    setPageLoader(false);
                  });
                } else {
                  getFeeOverView(() => {
                    setPageLoader(false);
                  });
                }}
              }
            );
          } else {
            cancelTransaction(
              () => {
                setDeleteBtnLoader(false);
                //   setCancelBtnLdr(false);
              },
              () => {
                setShowConfirmationModal(false);
                setShowPayRequestModal(false);
                fetchTransaction();
              }
            );
          }
        }}
        onCancel={() => {
          if (deleteApiCall) {
          } else {
            setShowPayRequestModal(true);
          }
          setShowConfirmationModal(false);
          //   deleteTransaction();
          //   setViewCourseDeleteConfirmModal(false);
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Confirm
        </h2>
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#636363",
          }}
        >
          Are you sure you want to {deleteApiCall ? "delete" : "cancel"} the
          payment request?
        </p>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCourseWithId: (callback, successCallBack) =>
    dispatch(deleteCourseWithId(callback, successCallBack)),
  getStudentsDetailsById: (callback) =>
    dispatch(getStudentsDetailsById(callback)),
  cancelTransaction: (callback, successCallback) =>
    dispatch(cancelTransaction(callback, successCallback)),
  fetchTransaction: () => dispatch(fetchTransaction()),
  deleteTransaction: (transactionPage,id,callback, successCallBack) =>
    dispatch(deleteTransaction(transactionPage,id,callback, successCallBack)),
  getFeeOverView: (callback, successCallBack) =>
    dispatch(getFeeOverView(callback, successCallBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
