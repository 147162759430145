import { CloseOutlined, PlusCircleTwoTone, ZoomOutOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Checkbox, DatePicker, Input, Modal, Select, Space, Tag } from 'antd'
import Search from 'antd/lib/input/Search';
import SearchBox from 'pages/Test/localComponent/searchBox';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { postSaveEditedGeoFence, storeEditedGeoFenceData, storeStartDate } from 'actions/attendanceNew';

function EditGeoFenceModal({
    isEditModalVisible,
    setIsEditModalVisible,
    teacherLocationTag,
    togglePageLoader,
    teachersGeofenceLocDataContainer,
    editedGeoFenceData,
    storeEditedGeoFenceData,
    getTeachersGeofenceData,
    getTeachersGeofenceLocations,
    postSaveEditedGeoFence
}) {
    const [options, setOptions] = useState([teachersGeofenceLocDataContainer]);
    const [searchedLocId, setSearchedLocId] = useState();
    const [locationName, setLocationName] = useState("");
    const [saveLoader, setSaveLoader] = useState(false);
    const [checkD,setCheckD] = useState(false);
    const [btnDisabled,setBtnDisabled] =useState(false);
    const [isDeleted, setIsDeleted] = useState(false)

    useEffect(() => {
        getTeachersGeofenceLocations()
    }, [])

    useEffect(() => {
        let temp = [];
        for (let i = 0; i < teacherLocationTag?.length; i++) {
            temp.push(
                {
                    title: teacherLocationTag[i]?.title,
                    geoFenceId: teacherLocationTag[i]?.geoFenceId.toString(),
                    type: teacherLocationTag[i]?.type,
                    startDate: teacherLocationTag[i]?.geoFenceAllocationStartDate !== null ? moment(teacherLocationTag[i]?.geoFenceAllocationStartDate).format("YYYY-MM-DD") : null,
                    endDate: teacherLocationTag[i]?.geoFenceAllocationEndDate !== null ? moment(teacherLocationTag[i]?.geoFenceAllocationEndDate).format("YYYY-MM-DD") : null,
                }
            )
        }
        storeEditedGeoFenceData(temp)
        setCheckD(!checkD)
    }, [])

    useEffect(() => {
        let temp = [];
        let tempobj = editedGeoFenceData.reduce(
            (obj, item) =>
                Object.assign(obj, { [item?.geoFenceId]: item?.title }), {});
        for (let i = 0; i < teachersGeofenceLocDataContainer.length; i++) {
            if (tempobj[`${teachersGeofenceLocDataContainer[i]?.id}`] == undefined) {
                temp.push(
                    {
                        value: teachersGeofenceLocDataContainer?.[i]?.title,
                        key: teachersGeofenceLocDataContainer?.[i]?.id,
                    }
                )
            }
        }
        setOptions(temp)
    }, [teachersGeofenceLocDataContainer, isDeleted])


    function onFill(index, key, val) {
        console.log("inside", index, key, val)
        if (editedGeoFenceData[index]) {
            editedGeoFenceData[index][`${key}`] = val;
        } else {
            editedGeoFenceData.push({
                index,
                [`${key}`]: val
            })
        }
        setCheckD(!checkD)
        console.log("editedGeoFenceData", editedGeoFenceData)
        return ;
    }
    const onSelect = (value, obj, index) => {
        setLocationName(value);
        setSearchedLocId("" + obj?.key);
        onFill(index, "geoFenceId", obj?.key?.toString())
        onFill(index, "title", value)
    }

    const deleteLocation = (id) => {
        let temp = editedGeoFenceData;
        temp?.splice(id, 1)
        storeEditedGeoFenceData([...temp]);
        setIsDeleted(true)
    };

    const EditableGeoFenceCard = ({ index, index1, title, _type, startDate, endDate, isNew }) => {
        const [type, setType] = useState(_type);
        const [startDateValue, setStartDateValue] = useState(startDate ? moment(startDate) : null);
        const [endDateValue, setEndDateValue] = useState(endDate ? moment(endDate) : null);

        console.log("inside", editedGeoFenceData, index)

        const OnChangePermanent = (id) => {
            if (type == '') {
                id == 0 ? setType("Permanent") : setType("Temporary")
            } else if (type === "Permanent") {
                setType('Temporary')
            } else if (type !== "Permanent") {
                setType("Permanent")
            }
        }

        const handleSearchLocation = (value) => {
            setLocationName(value)
        }

        function disabledDate(current) {
            return current < moment().startOf('day') 
        }

        function disabledEndDate(current) {
            return current < moment(startDateValue);
        }
        console.log("editedGeoFenceData", editedGeoFenceData)

        return (
            <div>
                <div style={{ display: "flex", fontSize: "13px", fontWeight: "bold", justifyContent: "space-between", margin: "20px 0px 10px 10px", width: "95%" }}>
                    <div style={{ color: "#636363", }}>Location {index1}</div>
                        <div style={{ color: "#FF414D", cursor: "pointer" }} onClick={() => { deleteLocation(index) }}>DELETE</div>
                </div>
                <div style={{ height: "fit-content", width: "440px", border: "1px solid #E6E6E6", borderRadius: "4px", margin: "10px 10px 10px 10px", padding: 10 }}>
                    {isNew ? (
                        <>
                            <div style={{ fontSize: "13px", color: "#636363", fontWeight: "bold" }}>Locations</div>
                            <div style={{ marginTop: "10px" }}>
                                <Select
                                    dropdownMatchSelectWidth={252}
                                    className="autocompletetoupload"
                                    value={title}
                                    // value={title?title:locationName}
                                    style={{ width: 420 }}
                                    options={options}
                                    onSelect={(val, obj) => onSelect(val, obj, index)}
                                    placeholder="Select a location"
                                    filterOption={options.filter((inputValue, option) => {
                                    option?.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) > -1
                                })}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ color: "#191919", fontSize: "14px", fontWeight: "bold" }}>{title}</div>
                        </>
                    )}

                    <div style={{ fontSize: "13px", color: "#636363", fontWeight: "bold", marginTop: 10 }}>Duration</div>
                    <div style={{ display: "flex", marginTop: 10 }}>
                        <div style={{ display: "flex" }}>
                            <Checkbox onChange={() => { onFill(index, "type", "Permanent"); OnChangePermanent(0) }} checked={type === "Permanent" ? true : false} />
                            <div style={{ fontSize: "14px", marginLeft: "20px", color: "#191919", }}>Permanent</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "110px" }}>
                            <Checkbox onChange={() => { onFill(index, "type", "Temporary"); OnChangePermanent(1) }} checked={type === "Temporary" ? true : false} />
                            <div style={{ fontSize: "14px", marginLeft: "20px", color: "#191919" }}>Temporary</div>
                        </div>
                    </div>
                    {type === "Temporary" ?
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", }}>
                            <div>
                                <div
                                    style={{ fontSize: "13px", color: "#636363", fontWeight: "bold" }}
                                >
                                    Start Date
                                </div>
                                <Space>
                                    <DatePicker
                                        allowClear={false}
                                        value={startDateValue}
                                        onChange={(e) => { onFill(index, "startDate", e.format("YYYY-MM-DD")); setStartDateValue(e) }}
                                        style={{
                                            fontSize: "16px",
                                            color: "#AEAEAE",
                                            width: "13vw",
                                            marginTop: "10px"
                                        }}
                                        disabledDate={disabledDate}
                                        placeholder="Select start date"
                                    />
                                </Space>
                            </div>
                            <div>
                                <div
                                    style={{ fontSize: "13px", color: "#636363", fontWeight: "bold" }}
                                >
                                    End Date
                                </div>
                                <Space>
                                    <DatePicker
                                        allowClear={false}
                                        value={endDateValue}
                                        disabledDate={disabledEndDate}
                                        onChange={(e) => { onFill(index, "endDate", e.format("YYYY-MM-DD")); setEndDateValue(e) }}
                                        style={{
                                            fontSize: "16px",
                                            color: "#AEAEAE",
                                            width: "13vw",
                                            marginTop: "10px"
                                        }}
                                        placeholder="Select end date"
                                    //   disabled={startDateValue === null ? true : false}
                                    />
                                </Space>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
    const handleCancel = () => {
        setIsEditModalVisible(false);
    };

    const [reload, setReload] = useState(1);


    useEffect(()=>{
        function checkDisable() {
            let disabled = false;
            if (editedGeoFenceData?.length > 0) {
                for (let i = 0; i < editedGeoFenceData?.length; i++) {
                    if (editedGeoFenceData[i].geoFenceId == null || editedGeoFenceData[i].type == '') {
                        disabled = true;
                    }
                    if ((editedGeoFenceData[i].type == 'Temporary') && (editedGeoFenceData[i].startDate == null || editedGeoFenceData[i].endDate == null)) {
                        disabled = true;
                    }
                }
            } else if(isDeleted) {
                disabled = false;
            }else{
                disabled = true
            }

            setBtnDisabled(disabled);
            return;
        }
        checkDisable();
    },[checkD,reload,editedGeoFenceData])

    const disableAddNew = editedGeoFenceData.some(item => item.title === "Global")
    return (
        <div>
            <Modal className='modal-round-corner'
                centered={true}
                footer={null}
                header={null}
                visible={isEditModalVisible}
                // onOk={handleOk}
                onCancel={handleCancel}>
                <div style={{ height: "450px" }}>
                    <div style={{
                        marginTop: "20px",
                        fontSize: "24px",
                        color: "#191919",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                    }}>Edit Geo fence </div>
                    <div style={{ display: "flex", flexWrap: "wrap", height: "400px", overflowY: "scroll", justifyContent: "center" }}>
                        {reload && editedGeoFenceData?.map((item, index) => (
                            <EditableGeoFenceCard
                                index={index}
                                index1={index + 1}
                                title={item?.title}
                                _type={item?.type}
                                startDate={item?.startDate}
                                endDate={item?.endDate}
                                isNew={item?.isNew}
                            />
                        ))}
                        {/* {(editedGeoFenceData?.length + 1) < 5 ? */}
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <Button 
                                disabled={disableAddNew}
                                style={{
                                    color: "#1089FF",
                                    fontWeight: "bold",
                                    justifyContent: "center",
                                    display: "flex",
                                    cursor: disableAddNew ? "not-allowed" : "pointer",
                                    border: "none",
                                    fontSize: 13,
                                    opacity: disableAddNew ? 0.5:1
                                }}
                                    onClick={() => {
                                        let tmp = {
                                            geoFenceId: null,
                                            type: '',
                                            startDate: null,
                                            endDate: null,
                                            isNew: true
                                        }
                                        console.log("AA", editedGeoFenceData)
                                        editedGeoFenceData.push(tmp);
                                        setReload(reload + 1);
                                        setLocationName(null)
                                        setCheckD(!checkD)
                                    }} >+ ADD NEW</Button>
                            </div>
                            {/* : null
                        } */}
                    </div>

                </div>
                <div style={{ display: "flex", marginLeft: "130px", marginBottom: "20px" }}>
                    <Button style={{
                        borderRadius: "19px",
                        width: "100px",
                        height: "40px",
                        fontWeight: "500",
                        border: "1px solid #594099 ",
                        color: "#594099"
                    }} onClick={() => { setIsEditModalVisible(false) }}>CANCEL</Button>
                    <Button
                        loading={saveLoader}
                        style={{
                            borderRadius: "19px",
                            width: "100px",
                            height: "40px",
                            fontWeight: "500",
                            border: "1px solid #E6E6E6 ",
                            backgroundColor: "#594099",
                            color: "#FFFFFF",
                            marginLeft: "30px",
                            opacity: btnDisabled? 0.5:1
                        }} onClick={() => {
                            setSaveLoader(true);
                            postSaveEditedGeoFence(
                                () => { setSaveLoader(false) },
                                () => {
                                    setIsEditModalVisible(false);
                                    togglePageLoader(true);
                                    getTeachersGeofenceData(
                                        () => { togglePageLoader(false) })
                                }
                            )
                        }}
                        disabled={btnDisabled}
                        >SAVE</Button>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
  const {startDateContainer,editedGeoFenceData} =
    state.attendanceNew;
  return {startDateContainer,editedGeoFenceData
  };
};

const mapDispatchToProps = (dispatch) => ({
  storeStartDate:(val)=>dispatch(storeStartDate(val)),
  storeEditedGeoFenceData:(val)=>dispatch(storeEditedGeoFenceData(val)),
  postSaveEditedGeoFence:(callBack,successCallBack)=>dispatch(postSaveEditedGeoFence(callBack,successCallBack))

  });

export default connect(mapStateToProps, mapDispatchToProps)(EditGeoFenceModal);

// export default AssignLocModal