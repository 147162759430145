import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Color } from "services";
import { orangecircle } from '../TestScreen'

const Up = require("../../../Assets/test-new/ic-chevron-up.svg").default;
const Down = require("../../../Assets/test-new/ic-chevron-down.svg").default;


const circle = (item, currentQIndex, onQuestionSelect) => {
	return <Col
			key={item.index}
			className="questionSelector r-c-c cursor-pointer m-t-5"
			style={{
				borderRadius: "50%",
				border: `${(currentQIndex === item.index ? '2px': '1px')} solid ${Color.midGrey}`,
				backgroundColor:
					currentQIndex === item.index
						? Color.midGrey
						: item.status === "Attempted"
							? Color.purple
							: item.status === "AttemptedAndBookmarked"
								? Color.purple
								: item.status === "Unanswered"
									? Color.wrong
									: item.status === "Bookmarked"
										? Color.wrong
										: "transparent",
				color:
					currentQIndex === item.index || item.status === "NotVisited"
						? Color.white
						: item.status == "None" || !item.status
							? Color.darkGrey
							: "white",
				position: "relative",
			}}
			onClick={() => onQuestionSelect(item)}
		>
			<span> {item.index}</span>
			{item.status == "Bookmarked" || item.status == "AttemptedAndBookmarked"
				? orangecircle(0)
				: null}
		</Col>
};

function QuestionPalette(props) {
	const { currentQIndex, onQuestionSelect, testSubjects, palateData } = props;

	const [closed, setClosed] = useState({});

	useEffect(() => {
		const closed_obj = {};
		testSubjects.map((item, index) => {
			closed_obj[item.name] = false;
		});
		setClosed(closed_obj);
	}, []);

	const toggleViewHandler = (name) => {
		setClosed((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};


	return (
		<div className="questionPalette">
			<div className='bold-600'
				style={{ color: '#AEAEAE', marginBottom: 10 }}>
					ALL QUESTIONS 
			</div>

			{palateData?.map((subject, index) => (
				<div key={index} style={{}}>
					<div className="r-jsb" 
						style={{cursor:'pointer'}}
						onClick={() => toggleViewHandler(subject?.subjectName)}
					>
						<div className='text-sm bold-600 m-b-10'>
							{subject?.subjectName}
						</div>
						<img src={closed[subject?.subjectName] ? Down : Up} />
					</div>


					{!closed[subject?.subjectName] &&
						subject?.sections?.map((section, index) => (
							<div key={index} className='text-xmd bold-600 m-b-10 m-l-10'>

								{(section.name != '_default_')
								&& <div>
									{section.name}
									<span style={{color: 'grey'}} className='bold-400'>
										&ensp; | &ensp; 
										{`${section?.questions[0]?.index} - 
										${section?.questions[section.questionCount - 1]?.index}`}
									</span>
								</div>}

								<Row>
									{section?.questions.map((item) =>
										circle(item, currentQIndex, onQuestionSelect)
									)}
								</Row>
							</div>)
					)}
				</div>
			))}
		</div>
	);
}

export default QuestionPalette;
