import React, {Component} from 'react';
import {connect} from 'react-redux';
import {BrigoshaMedia, Color} from 'services';
import {
  updateInHandRaiseQ,
  acceptHandRaiseQ,
  removeHandRaiseQ,
  toggleDoublePip,
} from 'actions/liveClass';
import styles from './style';
import ConfirmationModal from 'pages/broadcast/localComponent/confirmationModal';
import { Avatar } from 'antd';
import { FiCheck } from 'react-icons/fi';
import { MdCallEnd } from 'react-icons/md';
import { Button } from 'components/Button';
import { NodataCard } from './rightColumn';


const brigoshaMedia = new BrigoshaMedia();

class RaiseHandList extends Component {
    constructor(props){
        super(props);
        this.state = {
            confirmModalVisilbe: false
        }

    }
  componentDidMount() {
    const {toggleDoublePip, deviceType} = this.props;
    if (deviceType === 'phone') {
      toggleDoublePip(true);
    }
  }

  componentWillUnmount() {
    const {toggleDoublePip, deviceType, showTeacherCanvas} = this.props;
    if (deviceType === 'phone' && !showTeacherCanvas) {
      toggleDoublePip(false);
    }
  }

  handleDoubtPressAlert = () => {
    this.setState({
        confirmModalVisilbe: true
    })
  };

  handleDoubtPress = () => {
    const {updateInHandRaiseQ} = this.props;
    brigoshaMedia.raiseHandRequest();
    updateInHandRaiseQ(true);

    this.setState({
        confirmModalVisilbe: false
    })
    // .then(() => {
    //   updateInHandRaiseQ(true);
    // })
    // .catch((e) => {
    //   const message = {
    //     type: 'error',
    //     text: e,
    //   };
    // });
  };

  handleHandRaiseAccept = user => {
    const {acceptHandRaiseQ, isHandRaiseActive} = this.props;
    if (isHandRaiseActive) {
      return;
    }
    acceptHandRaiseQ(user);
    brigoshaMedia.acceptHandRequest(user.id);
  };

  handleEndCall = user => {
    const {removeHandRaiseQ} = this.props;
    removeHandRaiseQ(user);
    brigoshaMedia.rejectHandRequest(user.id);
  };

  removeUserFromQ = user => {
    const {removeHandRaiseQ} = this.props;
    removeHandRaiseQ(user);
    brigoshaMedia.rejectHandRequest(user.id);
  };

  handleDoubtCancelPress = () => {
    const {updateInHandRaiseQ, user} = this.props;
    brigoshaMedia.rejectHandRequest(user.id);
    updateInHandRaiseQ(false);
  };

  renderDoubtButton = () => {
    const {isTeacherPresent} = this.props;
    if (!isTeacherPresent) {
      return (
        <Button style={{height:50, boxShadow:'none'}} type="primary" className='m-r-20' disabled={true}><div className='r-c-c-c'><div style={{fontSize:14}}>Ask a Doubt</div><div style={{fontSize:10}}>Teacher is offline</div></div></Button>
      );
    }
    return (
      <Button style={{height:40, boxShadow:'none'}} onClick={this.handleDoubtPressAlert} type="primary" className='m-r-20' disabled={false}><div style={{fontSize:14}}>Ask a Doubt</div></Button>
    );
  };

  renderHandRaiseList = data => {
    const {isTeacher, user, isHandRaiseActive} = this.props;
    return (
      <div style={{...styles.onLineCardBox}}>
        <div className='r-c-fs'>
          <Avatar style={{color: '#fde3cf', backgroundColor: '#f56a00'}}>{data.name[0]}</Avatar>
          <div style={{marginLeft:10}}>
            <div className="white rfbc1 bold">
              {data.name}
              {user.id === data.id ? ' (Me)' : ''}
            </div>
            {data.student ? (
              <div
                className="white rfbc0p8 bold"
                style={{marginTop: 3}}>
                Roll No: {data.student.rollNo || ''}
              </div>
            ) : null}
          </div>
        </div>
        <div
        className='r-c-c'>
          {isTeacher ? (
            <div style={{marginRight: 5}}>
              {data.active ? (
                <div className="white rfbc0p8 bold m-r-5">
                  Ongoing
                </div>
              ) : (
                <div
                  onClick={() => this.handleHandRaiseAccept(data)}
                  style={{
                    padding:10,
                    borderRadius: 30,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <FiCheck color="white" size={20} style={{
                    color: isHandRaiseActive
                        ? Color.darkGrey
                        : Color.brightGreen,
                    }}/>
                </div>
              )}
            </div>
          ) : null}

          {data.active ? (
            <div>
              {isTeacher ? (
                <div
                  onClick={() => this.handleEndCall(data)}
                  style={{
                    display:"flex",
                    borderRadius: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <MdCallEnd color={Color.pinkRed} size={25}/>
                </div>
              ) : (
                <div className="rfbc0p8 r-c-c white">
                  Ongoing
                </div>
              )}
            </div>
          ) : (
            <div>
              {isTeacher ? (
                <div
                  onClick={() => this.removeUserFromQ(data)}
                  style={{
                    display:"flex",
                    borderRadius: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {/* <MIcon
                    name="close"
                    style={[styles.listIcons, {color: Color.pinkRed}]}
                  /> */}
                </div>
              ) : (
                <div className="rfbc0p8 r-c-c white">
                  Queued
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      handRaiseQArray,
      isTeacher,
      isInHandRaiseQ,
      isMyHandRaise,
    } = this.props;

    return (
        <>
            {
                handRaiseQArray.map(item=>this.renderHandRaiseList(item))
            }
            {
              isTeacher && handRaiseQArray?.length==0?
              <NodataCard type="HandRaise" />
              :null
            }
                <div style={{...styles.doubtBt}}>
                  
                </div>
          {!isTeacher && (
            <div className='r-c-c' style={{margin: 20, position:"absolute", bottom:40, left:0, right:0}}>
              {!isInHandRaiseQ ? (
                this.renderDoubtButton()
              ) : isMyHandRaise ? (
                <Button style={{height:40, boxShadow:'none'}} disabled={true} onClick={this.handleDoubtCancelPress} type="link" className='m-r-20'><div style={{fontSize:14}}>Ongoing <span style={{fontSize:20}} className="recRed bold-900 liveBlink m-r-10"> • </span></div></Button>
              ) : (
                <Button style={{height:40, boxShadow:'none'}} onClick={this.handleDoubtCancelPress} type="delete" className='m-r-20' disabled={false}><div style={{fontSize:14}}>Cancel Request</div></Button>
              )}
            </div>
          )}
                      {this.state.confirmModalVisilbe?  <ConfirmationModal visible={this.state.confirmModalVisilbe} 
                                title={'Confirm'} onCancel={()=>{
                                    this.setState({
                                        confirmModalVisilbe: false
                                    })
                                }} onConfirm={this.handleDoubtPress} content={'Are you sure you want to ask the teacher a doubt ?'}/>:null}
        </>
    );
  }
}

const mapStateToProps = state => {
  const {user} = state.session;
  const {deviceType} = state.common;
  const {
    handRaiseQArray,
    roomName,
    isInHandRaiseQ,
    isMyHandRaise,
    showTeacherCanvas,
    isTeacherPresent,
    isHandRaiseActive,
    isTeacher
  } = state.liveClass;
  return {
    user,
    roomName,
    isTeacher,
    deviceType,
    isMyHandRaise,
    isInHandRaiseQ,
    handRaiseQArray,
    isTeacherPresent,
    isHandRaiseActive,
    showTeacherCanvas,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleDoublePip: value => dispatch(toggleDoublePip(value)),
  removeHandRaiseQ: user => dispatch(removeHandRaiseQ(user)),
  acceptHandRaiseQ: user => dispatch(acceptHandRaiseQ(user)),
  updateInHandRaiseQ: value => dispatch(updateInHandRaiseQ(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RaiseHandList);
