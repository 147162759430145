import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getCareChat, setAulasCareChat } from 'actions/adminCare';
import ChatScreen from './functionalGiftedWebChat'
import { PageHeader } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

function ChatWithAulas({ getCareChat, setAulasCareChat }) {
    const [pageLoader, togglePageLoader] = useState(false)

    const history = useHistory();
    useEffect(() => {
        togglePageLoader(true)
        getCareChat();
    })
    function goBack() {
        history.goBack()
        setAulasCareChat(false)
    }

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <PageHeader
                title={<div className="text-md">Chat</div>}
                onBack={goBack}
                backIcon={<ArrowLeftOutlined />}
            />
            <div style={{ width: "100%", height: "85%" }}>


                <ChatScreen studentChat={true} togglePageLoader={togglePageLoader} isAulasChat={true} />


            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({

    getCareChat: () => dispatch(getCareChat()),
    setAulasCareChat: (m) => dispatch(setAulasCareChat(m))


});

export default connect(null, mapDispatchToProps)(ChatWithAulas);