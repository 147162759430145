import { ArrowLeftOutlined, SettingFilled } from '@ant-design/icons';
import { fetchChapterWiseList, getChapterWiseData, postReorderChapters, updateCurrentSub } from 'actions/digitalLibrary';
import { Input, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChapterCard from '../components/chapterCard';
import emptyimage from "../assets/emty-tabs-subject-details/ill-es-sbj.png"
import PageDataLoader from 'components/pageDataLoader';
import AddTeacherFormView from '../modals/addTeacherFormView';
import UploadModal from "../modals/uploadModel";
import Uploadfiles from "../modals/uploadfiles";
import Videouploadmodel from "../modals/Videouploadmodel";
import Succesfulmodel from "../modals/Succesfulmodel";
import AddTeacherModal from "../modals/addTeacher"
import { InputSearch } from 'components/customInputs';
import PageHeader from 'components/pageHeader'
import {Button} from 'components/Button'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ChapterWiseViewPage = ({ selectedSubject, fetchChapterWiseList, chapterWiseDataList, updateCurrentSub, arrStoreData, addedTeacher, teacherMangementList, user, getChapterWiseData, postReorderChapters }) => {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [pageLoader, tooglePageLoader] = useState(false)
    const [modalUpload, setModalUpload] = useState(false);
    const [visibleForm, setVisibleForm] = useState(false);
    const [uploadFileModal, toggleUploadFileModel] = useState(false);
    const [modal, setmodel] = useState(false);
    const [type, setType] = useState("");
    const [search, setSearch] = useState('');
    const [isEditOrderClicked, setIsEditOrderClicked] = useState(false);
    const [chaptersData, setChaptersData] = useState([]);
    const [reorderSaveLoader, setReorderSaveLoader] = useState(false)
    const handleHistory = () => {
        updateCurrentSub({})
        history.goBack()
    }

    const onSearchChapter = (value) => {
        setSearch(value)
        tooglePageLoader(true)
        fetchChapterWiseList(value, () => tooglePageLoader(false), (response) => { setChaptersData(response) })
    }

    const showTeacher = () => {
        setVisibleForm(true);
    };

    useEffect(() => {
        getChapterWiseData([])
        setSearch('')
        tooglePageLoader(true)
        fetchChapterWiseList('', () => tooglePageLoader(false), (response) => { setChaptersData(response) })
    }, [])

    const reorderSaveDisable = chapterWiseDataList?.every((chapter, index) => chapter?.chapterName === chaptersData[index]?.chapterName);

    const actions = [
        (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) && chaptersData?.length > 1 && !isEditOrderClicked && <Button type="primary" style={{ marginRight: 10, backgroundColor: "#FFFFFF", color: "#594099" }} onClick={() => { setIsEditOrderClicked(true) }}>REORDER</Button>,
        isEditOrderClicked && <Button style={{ marginRight: 20 }} onClick={() => { setIsEditOrderClicked(false); setChaptersData([...chapterWiseDataList]) }}>CANCEL</Button>,
        isEditOrderClicked && <Button disabled={reorderSaveDisable} type="primary" loading={reorderSaveLoader} onClick={() => {
            setReorderSaveLoader(true);
            postReorderChapters(chaptersData, () => { setReorderSaveLoader(false) }, () => { tooglePageLoader(true); fetchChapterWiseList('', () => tooglePageLoader(false)); setIsEditOrderClicked(false); })
        }}>SAVE</Button>,
        addedTeacher?.length != 0 && (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin')) && !isEditOrderClicked?
            <InputSearch allowClear placeholder="Search By Chapter" onInputChange={onSearchChapter} onClear={() => onSearchChapter('')} />
            :
            (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin')) && !isEditOrderClicked ?
                <Button className='m-l-20 text-xmd' style={{ marginRight: 10 }} onClick={() => setIsModalVisible(true)} icon={"+"}>ADD A TEACHER</Button> : null
        ,
        addedTeacher?.length != 0 && !isEditOrderClicked ? <SettingFilled style={{ fontSize: "20px", color: "#594099", marginTop: 4, marginLeft: 10 }} onClick={() => showTeacher()} /> : null
    ]

    const handleBackPress = () => {
        history.goBack();
    }

    let subjectName = null;

    if(user.role == 'Student'){
        subjectName = selectedSubject?.subjectName
    }else {
        subjectName = selectedSubject?.subjects_name
    }

    return (
        <div>
            <div className="r-c-sb" style={{ height: "50px" }}>
                    <PageHeader title={subjectName} actions={actions} onBackPress={()=>handleBackPress()}/>
                <div
                    style={{
                        width: "35%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {(isModalVisible && <AddTeacherModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} setVisibleForm={setVisibleForm}
                        teacherMangementList={teacherMangementList}
                    />)}
                    {visibleForm && (
                        <AddTeacherFormView
                            visibleForm={visibleForm}
                            setVisibleForm={setVisibleForm}
                        />
                    )}
                </div>
            </div>
            <DndProvider backend={HTML5Backend}>
            <Row style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '22px', marginLeft: -10, marginRight: -10}}>
                {(chaptersData?.length != 0) ? chaptersData?.map((item, index) => (
                    <ChapterCard key={item.id} chapterItem={item} index={index} isEditOrderClicked={isEditOrderClicked}
                    moveChapter={(dragIndex, hoverIndex) => {
                        const updatedChapter = [...chaptersData];
                        const draggedChapter = updatedChapter[dragIndex];
                        updatedChapter.splice(dragIndex, 1);
                        updatedChapter.splice(hoverIndex, 0, draggedChapter);
                        setChaptersData(updatedChapter);
                    }} />
                )) :
                    <div style={{ width: '100%' }}>
                        <div className="all-cont">
                            <div className="img-tex-btn">
                                <img className="img-emp-cl" src={emptyimage} />
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                                    <div>
                                        <p className="p-emp">No Chapter(s) available!</p>
                                        <p className="p-c-emp">There are no chapter(s) created yet.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}
            </Row>
            </DndProvider>
            <PageDataLoader visible={pageLoader} />

        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        selectedSubject,
        chapterWiseDataList,
        arrStoreData,
        addedTeacher,
        teacherMangementList,
    } = state.digitalLibrary;
    const { user } = state.session
    return {
        selectedSubject,
        user,
        chapterWiseDataList,
        arrStoreData,
        addedTeacher,
        teacherMangementList

    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchChapterWiseList: (search, callback, successCallBack) => dispatch(fetchChapterWiseList(search, callback, successCallBack)),
    updateCurrentSub: (object) => dispatch(updateCurrentSub(object)),
    getChapterWiseData: (data) => dispatch(getChapterWiseData(data)),
    postReorderChapters: (chapters, callBack, successCallBack) => dispatch(postReorderChapters(chapters, callBack, successCallBack))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ChapterWiseViewPage);

