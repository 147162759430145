import styled, { css } from "styled-components";
import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";
const CustomInput = styled.input`
    border-radius: 2px;
    border: 1px solid lightgray;
    height: 30px;
    outline: none !important;
    padding: 10px;
    &:hover,
    &:focus,
    &:active {
        border: 1px solid gray !important;
        box-shadow: none;
    }
`;
const CustomSearch = styled.div`
    border-radius: 2px;
    border: 1px solid lightgray;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    &:hover,
    &:focus,
    &:active {
        box-shadow: 1px 5px 8px 2px #302A5F14;
    }
`;
const StyledInput = styled.input`
    border: none !important;
    height: 100%;
    border-radius: 2px;
    width: 100%;
    outline: none !important;
    &:hover,
    &:focus,
    &:active {
        box-shadow: none !important;
        border: none !important;
        
    }
`;
const Input = (props) => {
    return <CustomInput {...props}/>
}

const InputSearch = (props) => {
    
    const [val, setVal] = useState('');

    useEffect(() => {
        setVal('')
    }, [])
    

    useEffect(() => {
        setVal(props.value)
    }, [props.value])

    const _handleKeyDown = (e) => {
        if (props.onEnter){
            if (e.key === 'Enter') {
                props.onEnter(val)
            }
        }
    }

    const _onClear = (e) => {
        setVal('')

        if (props?.onClear){
            props.onClear('');
        }
        if (props.onEnter){
            props.onEnter('');
        }
    }

    const onChange = (e) => {
        setVal(e.target.value);
        if(props.onInputChange){
            props.onInputChange(e.target.value)
        }
    }


    return <CustomSearch {...props}>
        <AiOutlineSearch style={{marginLeft: 5, fontSize: 18, fontWeight: 600, color: 'lightgray'}}/>
        <StyledInput type="text" id="customSearch" {...props} onChange={onChange} onKeyDown={_handleKeyDown} value={val}/>
        {props.allowClear && val && val !== '' ? 
            <div onClick={_onClear} style={{cursor: 'pointer'}} className='r-c-c-c'>
                <AiOutlineCloseCircle style={{marginRight: 5, fontSize: 14, fontWeight: 600, color: 'grey'}}/>
            </div>
            : null}
    </CustomSearch>
}
export { Input, InputSearch }