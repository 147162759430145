import {
  ArrowLeftOutlined,
  CameraFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Input, PageHeader, Progress, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import image from "Assets/user-management/Group 19.png";
import PhoneInput from "react-phone-number-input";
import Date_Picker from "./datePicker";
import DeleteModal from "./deleteModal";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import { IMG_URL } from "../../env.json";
import userAvatar from "Assets/user-management/Icon awesome-user-alt.svg";
import { postApproveReject, setPhone, setStatus } from "actions/userManagement";
import { postUserStatus, setActionData, setCardId, setFromNotification } from "actions/notification";
import ConfirmRejectModal from "pages/attendanceNew/Modals/confirmRejectModal";
import ConfirmApproveModal from "pages/attendanceNew/Modals/confirmApproveModal";
const { Option } = Select;

const goBack = (history) => {
  history.goBack();
};
function onChange(e) {
  // console.log(`checked = ${e.target.checked}`);
}

function parseData(data) {
  // console.log("parsedata", data);
  if (!data) return {};
  if (typeof data === "object") return data;
  if (data[0] === "{") {
    if (typeof data === "string") return JSON.parse(data);
  }

  return {};
}

function approvalForm({
  tab,
  setStatus,
  userRequestStatus,
  userDetails,
  postApproveReject,
  fromNotification,
  setFromNotification,
  setCardId,
  setActionData,
  postUserStatus,
}) {
  const [buttonLoaderApproval, setButtonLoaderApproval] = useState(false);
  const [buttonLoaderReject, setButtonLoaderReject] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [approved, setApproved] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);

  const [keys, setKeys] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (userRequestStatus === "approved") {
      setButtonLoaderApproval(true);
      postApproveReject(
        () => successApproveCallback(),
        () => chndleChangeButton()
      );
    }
    if (userRequestStatus === "rejected") {
      setButtonLoaderReject(true);
      postApproveReject(
        () => successCallbackReject(),
        () => handleRejectCallback()
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const history = useHistory();
  const successApproveCallback = () => {
    setButtonLoaderApproval(false);

    console.log(approved);
  };

  const chndleChangeButton = () => {
    setApproved(true);
    history.goBack();
  };
  const handleAprove = () => {
    setStatus("approved");
    setIsModalVisible(true);
  };
  const successCallbackReject = () => {
    setButtonLoaderReject(false);
  };
  const handleRejectCallback = () => {
    setRejected(true);
    history.goBack();
  };
  const handleReject = () => {
    setStatus("rejected");
    setIsModalVisible(true);
  };
  useEffect(() => {
    setStatus(null);
  }, []);
  
  return (
    <div style={{ width: "100%", height: "100%", marginTop: "-20px" }}>
      <div
        style={{
          padding: "20px",
          width: "97%",
          height: "10px",

          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "space-between",
          // alignItems: "baseline",
        }}
      >
        <div style={{ marginLeft: "-38px" }}>
          <PageHeader
            title={<div></div>}
            onBack={() => goBack(history)}
            backIcon={<ArrowLeftOutlined onClick={()=>setFromNotification(false)}/>}
          />
          {/* <p>hi tyhtrdyguhii cfyuy gygiu</p> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "25%",
            // marginRight: "-20px",
            position: "absolute",
            top: "20px",

            right: "40px",
          }}
        >
          {
            fromNotification ?
              userDetails?.userStatus === "Rejected" ?
                <>
                  <Button
                    loading={buttonLoaderApproval}
                    style={{
                      border: "#28DF99 solid 1px",
                      borderRadius: "20px",
                      width: "140px",
                      margin: "2px",
                      height: "40px",
                      fontFamily: "Roboto",
                      textAlign: "center",
                      color: "#28DF99",
                      backgroundColor: "#E9FCF5",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsApproveModalVisible(true);
                      setCardId(userDetails?.refId);
                      setActionData("Approved")
                    }}
                  >
                    APPROVE
                  </Button>
                  <ConfirmApproveModal
                    isApproveModalVisible={isApproveModalVisible}
                    setIsApproveModalVisible={setIsApproveModalVisible}
                    postUserStatus={postUserStatus}
                    fromNotification={fromNotification}
                    Title={userDetails?.name}
                  />
                  <div style={{ fontSize: '15px', color: '#FF414D', display: "flex", fontWeight: "500", marginLeft: "8%" }}>
                    <CloseCircleOutlined style={{ marginTop: 5 }} />
                    <div style={{ marginLeft: 5 }}>REJECTED</div>
                  </div>

                </>
                :
                userDetails?.userStatus === "Pending" ?
                  <>
                    <Button
                      loading={buttonLoaderReject}
                      style={{
                        border: "#FF414D solid 1px",
                        borderRadius: "20px",
                        width: "140px",
                        margin: "2px",
                        marginRight: "10px",
                        height: "40px",
                        fontFamily: "Roboto",
                        textAlign: "center",
                        color: "#FF414D",
                        backgroundColor: "#FFE4D9",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsRejectModalVisible(true);
                        setCardId(userDetails?.refId);
                        setActionData("Rejected")
                      }}
                    >
                      REJECT
                    </Button>
                    <ConfirmRejectModal
                    isRejectModalVisible={isRejectModalVisible}
                    setIsRejectModalVisible={setIsRejectModalVisible}
                    postUserStatus={postUserStatus}
                    fromNotification={fromNotification}
                    Title={userDetails?.name}
                  />
                    <Button
                      loading={buttonLoaderApproval}
                      style={{
                        border: "#28DF99 solid 1px",
                        borderRadius: "20px",
                        width: "140px",
                        margin: "2px",
                        height: "40px",
                        fontFamily: "Roboto",
                        textAlign: "center",
                        color: "#28DF99",
                        backgroundColor: "#E9FCF5",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsApproveModalVisible(true);
                        setCardId(userDetails?.refId);
                        setActionData("Approved")
                      }}
                    >
                      APPROVE
                    </Button>
                    <ConfirmApproveModal
                    isApproveModalVisible={isApproveModalVisible}
                    setIsApproveModalVisible={setIsApproveModalVisible}
                    postUserStatus={postUserStatus}
                    fromNotification={fromNotification}
                    Title={userDetails?.name}
                  />
                  </>
                  :
                  userDetails?.pendingUser === "Approved"?
                  <>
                  <div style={{ fontSize: '15px', color: '#28DF99', display: "flex", fontWeight: "500", marginLeft: "8%" }}>
                    <CheckCircleOutlined style={{ marginTop: 5 }} />
                    <div style={{ marginLeft: 5 }}>APPROVED</div>
                  </div>
                  </> :
                  <></>: 
                  <>
                  {
            tab == "pending" ? (
              <>
                {rejected ? (
                  <Button
                    style={{
                      border: "#FF414D solid 1px",
                      borderRadius: "20px",
                      width: "140px",
                      margin: "2px",
                      marginRight: "10px",
                      height: "40px",
                      fontFamily: "Roboto",
                      textAlign: "center",
                      color: "#FF414D",
                      backgroundColor: "#FFE4D9",
                    }}
                  >
                    <CloseCircleOutlined />
                    REJECT
                  </Button>
                ) : (
                  <Button
                    disabled={approved}
                    onClick={() => handleReject()}
                    loading={buttonLoaderReject}
                    style={{
                      border: "#FF414D solid 1px",
                      borderRadius: "20px",
                      width: "140px",
                      margin: "2px",
                      marginRight: "10px",
                      height: "40px",
                      fontFamily: "Roboto",
                      textAlign: "center",
                      color: "#FF414D",
                      backgroundColor: "#FFE4D9",
                    }}
                  >
                    REJECT
                  </Button>
                )}
                {/* {console.log("approved--", approved)} */}
                {approved === true ? (
                  <div>
                    <Button
                      onClick={() => handleAprove()}
                      style={{
                        border: "#28DF99 solid 1px",
                        borderRadius: "20px",
                        width: "140px",
                        margin: "2px",
                        height: "40px",
                        fontFamily: "Roboto",
                        textAlign: "center",
                        color: "#28DF99",
                        backgroundColor: "#E9FCF5",
                      }}
                    >
                      <CheckCircleOutlined />
                      APPROVE
                    </Button>
                  </div>
                ) : (
                  <Button
                    disabled={rejected}
                    onClick={() => handleAprove()}
                    loading={buttonLoaderApproval}
                    style={{
                      border: "#28DF99 solid 1px",
                      borderRadius: "20px",
                      width: "140px",
                      margin: "2px",
                      height: "40px",
                      fontFamily: "Roboto",
                      textAlign: "center",
                      color: "#28DF99",
                      backgroundColor: "#E9FCF5",
                    }}
                  >
                    APPROVE
                  </Button>
                )}
              </>
            ) : (
              <>
                {approved === true ? (
                  <div>
                    <Button
                      //   onClick={() => handleAprove()}
                      style={{
                        border: "#28DF99 solid 1px",
                        borderRadius: "20px",
                        position: "absolute",
                        right: "30px",

                        width: "140px",
                        // top: "100px",
                        height: "40px",
                        fontFamily: "Roboto",
                        textAlign: "center",
                        color: "#28DF99",
                        backgroundColor: "#E9FCF5",
                        marginLeft: "110%",
                      }}
                    >
                      <CheckCircleOutlined />
                      APPROVE
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      disabled={rejected}
                      loading={buttonLoaderApproval}
                      onClick={() => handleAprove()}
                      style={{
                        border: "#28DF99 solid 1px",
                        borderRadius: "20px",
                        position: "absolute",
                        right: "30px",

                        width: "140px",
                        // top: "100px",
                        height: "40px",
                        fontFamily: "Roboto",
                        textAlign: "center",
                        color: "#28DF99",
                        backgroundColor: "#E9FCF5",
                        marginLeft: "110%",
                      }}
                    >
                      APPROVE
                    </Button>
                  </div>
                )}
              </>
            )
            // <Button onClick={()=>handleAprove()} style={{ border: "#28DF99 solid 1px", borderRadius: "20px", width: "140px", margin: "2px", height: "40px", fontFamily: "Roboto", textAlign: "center", color: "#28DF99", backgroundColor: "#E9FCF5",marginLeft:"60%" }}>APPROVE</Button>
          }
                  </>
          }
          
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "40px",
          height: "95%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "600px",
            boxShadow: " 1px 1px 16px #E6E6E6",
            borderRadius: "10px",
          }}
        >
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <h3
              style={{
                cursor: "pointer",
                borderRadius: "3px",
                padding: "10px",
                marginRight: "20px",
                color: "#594099",
                backgroundColor: "#F6F4FE",
              }}
            >
              Form
            </h3>
          </div>
        </div>
        <div
          style={{
            width: "65%",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingRight: "20px",
            padding: "8px",
          }}
        >
          {/* {window.scrollTo(0, 2000)} */}
          <div
            style={{
              boxShadow: " 1px 1px 16px  #E6E6E6",
              borderRadius: "10px",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              {/* <img
                width="120px"
                height="120px"
                style={{ borderRadius: "50%" }}
                src={`${IMG_URL}/${userDetails?.details?.image}`}
                alt=""
              ></img> */}
              {userDetails?.details?.image !== "default.png" || fromNotification ? (
                <img
                  width="120px"
                  height="120px"
                  style={{ borderRadius: "50%" }}
                  src={fromNotification ?
                    `${IMG_URL}/${userDetails?.image ? userDetails?.image : "default.png"}`
                    :
                    `${IMG_URL}/${userDetails?.details?.image ? userDetails?.details?.image : "default.png"}`
                  }
                ></img>
              ) : (
                <img
                  width="120px"
                  height="120px"
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#F6F4FE",
                    padding: "15px",
                  }}
                  src={userAvatar}
                ></img>
              )}
            </div>
            {/* <h2 style={{ fontWeight: "bold", paddingLeft: "30px", fontFamily: "Roboto" }}>Basic information</h2> */}

            <div
              id="basicInfo"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </p>
                  <Input
                    readOnly
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      border: "#E6E6E6 solid 1px",
                      borderRadius: "5px",
                    }}
                    //placeholder="Enter name"
                    type="text"
                    onChange={() => {}}
                    value={userDetails?.name}
                  />
                  <div
                    className="supportPhone"
                    style={{ marginBottom: "20px" }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      Phone Number
                    </p>

                    <PhoneInput
                      style={{
                        width: "100%",
                        border: "1px solid #d9d9d9",
                        paddingLeft: 5,
                        backgroundColor: "transparent",
                      }}
                      defaultCountry="IN"
                      //placeholder="Enter phone number"
                      value={userDetails?.phone}
                      disabled={true}
                      height={"40px"}
                      onCountryChange={(e) => console.log("code", e)}
                      onChange={() => {}}
                      maxLength={(userDetails?.phone?.startsWith('+91') || userDetails?.phone?.startsWith('91')) && userDetails?.phone?.length == 13 ? 11 : ""}
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "50%" }}>
                <div
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    width: "100%",
                  }}
                >
                  {userDetails?.role[0] === "Student" || userDetails?.role === "Student"? (
                    <div>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                        }}
                      >
                        Preferred Course
                      </p>
                      <Input
                        readOnly
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          border: "#E6E6E6 solid 1px",
                          borderRadius: "5px",
                        }}
                        //placeholder="Enter Class/Course"
                        type="text"
                        onChange={() => {}}
                        value={fromNotification ? userDetails?.preferredCourse ? userDetails?.preferredCourse : "NA" : userDetails?.details?.preferredCourse}
                      />
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                        }}
                      >
                        User type
                      </p>
                      <Input
                        readOnly
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          border: "#E6E6E6 solid 1px",
                          borderRadius: "5px",
                        }}
                        //placeholder="Enter Role"
                        type="text"
                        onChange={() => {}}
                        value={fromNotification ? userDetails?.role : userDetails?.role}
                      />
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        type="aproveOrReject"
        name={userDetails?.name}
        role={userDetails?.role}
      />
    </div>
  );
}

// export default approvalForm

const mapStateToProps = (state) => {
  const { tab, userDetails, userRequestStatus } = state.userManagement;
  const {fromNotification} = state.notification
  return {
    tab,
    userDetails,
    userRequestStatus,
    fromNotification
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setTab: (m) => dispatch(setTab(m))
  setStatus: (m) => dispatch(setStatus(m)),
  postApproveReject: (callback, callbackButton) =>
    dispatch(postApproveReject(callback, callbackButton)),
  setFromNotification:(val) => dispatch(setFromNotification(val)),
  setCardId:(id) => dispatch(setCardId(id)),
  setActionData:(val)=> dispatch(setActionData(val)),
  postUserStatus:(callBack,successCallBack) => dispatch(postUserStatus(callBack,successCallBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(approvalForm);
