import React from 'react';
import { Popover,   } from 'antd';
import { Select, DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

const {RangePicker} = DatePicker;

function CustomCalender({isdisabled, issubjectpage, handleDateFilterSelect, selectedDateFilter}){
  const Footer = ({}) => (
    <div style={{color:"#1089FF"}} className="cursor-pointer text-center bold-700 full-width">
      RESET TO ALL
    </div>
  )
  const text = <span></span>;
  const content =(isdisabled, handleDateFilterSelect) => {
    if (isdisabled){
      return <span></span>
    }
    return (
      <div style={{display:'flex'}}>
      <RangePicker placeholder={['Start Date','End Date']} disabled={isdisabled}
          style={{marginLeft:-16, marginRight:-16, marginTop:-20, marginBottom:-20, borderRadius:10}} 
          onChange={(val, datsring) => handleDateFilterSelect(null, datsring)}
          showTime format={'YYYY-MM-DD'}
          dropdownClassName="rangepickeradmintest"
          // dateRender={(currentDate, today)=><div style={{backgroundColor:"yellow"}}>jh</div>}
          renderExtraFooter={() => <Footer/>}/>
      </div>
    )
};

// const buttonWidth = 70;

  const { Option } = Select;

function handleChange(value) {
  handleDateFilterSelect(value)
}
  const selectArr = ['All', 'Week', 'Month']
  return(

    <div className="r-c-sb m-r-20 m-l-20" 
          style={{border:"#E6E6E6 solid 1px", borderRadius:"5px", height: issubjectpage?32:30, backgroundColor:"#FFF"}} 
          id="testCareFilter">
        {issubjectpage
        ?
        null
        :
        <div className="m-l-10"><img style={{width:"20px" ,height:"17px", marginRight:0}}  src={require('../../../Assets/care/ic-sort.svg').default} alt="" />
        </div>
        }

        <Select
          className="fs-11 bold-700"
          labelInValue
          defaultValue={issubjectpage?{value:"All"}:{ value: 'Week' }}
          style={{ width: 100 ,border:"none", marginLeft:0, paddingLeft:"0px"}}
          onChange={handleChange}
          bordered={false}
          removeIcon={true}
          showArrow={false}
          dropdownMatchSelectWidth={100}
          disabled={isdisabled}
          getPopupContainer={() => document.getElementById('testCareFilter')}
        >
          <Option value="All">All</Option>
          <Option value="Week">Week</Option>
          <Option value="Month">Month</Option>
        </Select>

        <Popover placement="bottom" 
                 title={null} 
                 content={content(isdisabled, handleDateFilterSelect)} 
                 trigger="click" 
                 disabled={isdisabled}
                 overlayInnerStyle={{borderRadius:5, marginLeft:7, marginRight:7, padding:0, marginTop:10}}>
                    <CalendarOutlined className="m-r-10" style={issubjectpage?{fontSize:16}:{fontSize:12}}/>
        </Popover>
  </div>

)
  }
export default CustomCalender;
