import React from 'react'
import HtmlParser from 'react-html-parser';

const ExamPattern = ({ singleCourseDetailsDataContainer, fromTestSeriesPage }) => {
    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }
    return (
        <div style={{ width: '100%', height: 'fit-content', padding: fromTestSeriesPage ? 0 : 120, paddingTop: fromTestSeriesPage ? 40 : 50 }}>
            <div className='course-content-header' style={{marginBottom:20}}>Exam Pattern</div>
            <div style={{ backgroundColor: 'white', width: '100%', height: 'fit-content', border: singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern ? '1px solid #d9d9d9' : 'none', display: 'flex', justifyContent: 'flex-start', padding: 10 }}>
                <div style={{ width: '80%', height: '80%' }}>
                    {IsJsonString(singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern) ? !IsJsonString(JSON.parse(singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern)?.mobile) && isHTML(JSON.parse(singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern)?.mobile) ? HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern)?.mobile) : HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern)?.mobile) : singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern}
                </div>
            </div>
        </div>
    )
}

export default ExamPattern