import React from "react";
import './styles.css'

function ClassCard({ total_students, stream, section }) {
  return (
    <div
    className="classCard"
      // style={{
      //   boxShadow: "2px 3px 6px #c7cece",
      //   margin: "10px",
      //   minWidth: "150px",
      //   maxWidth: "fit-content",
      //   height: "90%",
      //   paddingLeft: "20px",
      //   paddingRight: "20px",
      //   borderRadius: "10px",
      // }}
    >
      <div style={{ width: "100%", margin: "auto" }}>
        <div
          style={{
            textAlign: "center",
            borderBottom: "#E6E6E6 solid 1px",
            padding: "10px",
          }}
        >
          <h1
            style={{
              color: "#594099",
              fontSize: "40px",
              marginBottom: "-10px",
              marginTop: "-10px",
              fontFamily: "auto",
              width: "100%",
              textAlign: "center",
              fontWeight: "600",
              fontFamily: "PT Serif",
            }}
          >
            {section && section.length <= 3
              ? section
              : section?.substring(0, 3)}
          </h1>
          <p
            style={{
              color: "#ACACACD9",
              marginBottom: "0",
              whiteSpace: "nowrap",
              width: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {stream && stream != undefined ? stream : "NA"}
          </p>
        </div>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <p
            style={{ color: "#28DF99", marginBottom: "0", fontWeight: "bold" }}
          >
            {total_students}
          </p>
          <p style={{ color: "#ACACACD9", marginBottom: "0" }}>
            {total_students == 1 ? "Student" : "Students"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClassCard;
