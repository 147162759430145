import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  MoreOutlined,
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Menu, Popover } from "antd";
import ConfirmDeleteModal from "./Modals/confirmDeleteModal";
import LeaveDonutGraph from "./localComponents/leaveDonutGraph";
import { useHistory } from "react-router";
import EditModal from "./Modals/editModal";
import createLeaveModal from "./Modals/createLeaveModal";
import { getAllLeave, storeEditLeaveModalData, deleteLeaves, setBackToPrivilegedView } from "actions/attendanceNew";
import { connect } from "react-redux";
import { showModal } from "actions/calendar";
import CreateLeaveModal from "./Modals/createLeaveModal";
import LeavesCard from '../attendanceNew/leaves'
import PageDataLoader from "components/pageDataLoader";
import DeleteEditLeave from "./Modals/deleteEditLeave";
import PageHeader from "components/pageHeader";
import { Button } from 'components/Button'



const image1 = require("../../Assets/settingss/PlusCircle.svg").default;
const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 40px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  // overflow: "auto",
};


function LeaveCard(props) {
  const menu = (
    <div onClick={(e) => { e.stopPropagation() }}>
      <Menu style={{ width: "100%", }}>
        <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => {
          props.setIsModalVisible(true)
          props.deleteLeaves(props.id)
        }}>
          <DeleteFilled />
          Delete
        </Menu.Item>
      </Menu>
    </div>
  );
  return (
    <div
      // className="border-16 boxShadow118"
      style={{
        margin: 5,
        borderRadius: "6px",
        borderColor: "#E6E6E6",
        backgroundColor: "#FFFFFF",
        alignItems: "center",
        border: "#E6E6E6 solid 1px",
        width: 300,
        height: 125,
        cursor: "pointer",
        borderLeft: "6px solid" +
          `${props.leaveName === "Sick" ? "#FFA931" :
            props.leaveName === "Marriage" ? "#99DED9" :
              props.leaveName === "Paternity" ? "#28DF99" :
                props.leaveName === "Maternity" ? "#B2DFFF" :
                  props.leaveName === "Casual" ? "#FEA5DD" :
                    props.leaveName === "Privilege" ? "#9876E6" : null}`
      }}
      onClick={(e) => {
        e.stopPropagation()
        console.log("XYZ", props?.carryForward, props?.halfDayApplicable, props?.backDays)
        props.setIsEditModalVisible(true);
        props.storeEditLeaveModalData("leaveName", props?.leaveName)
        props.storeEditLeaveModalData("noOfDays", props?.num)
        props.storeEditLeaveModalData("startDate", props?.startDate)
        props.storeEditLeaveModalData("endDate", props?.endDate)
        props.storeEditLeaveModalData("id", props?.id)
        props.storeEditLeaveModalData("carryForward", props?.carryForward)
        props.storeEditLeaveModalData("halfDayApplicable", props?.halfDayApplicable)
        props.storeEditLeaveModalData("totalBackDays", props?.backDays)
      }}
    >
      {console.log("ABCD", props?.isLocked)}
      <div style={{ paddingLeft: "90%", paddingTop: `${props?.isLocked ? "35px" : "10px"}`/* "10px" */ }} onClick={(e) => e.preventDefault()}>
        {!props?.isLocked ?
          <Popover placement="bottomRight" content={menu}>
            <MoreOutlined style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation() }} />
          </Popover>
          :
          null
        }
      </div>

      <div style={{ marginLeft: 20, marginTop: "-20px" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{ fontSize: "29px", fontWeight: "bold", color: "#191919" }}
          >
            {props.num}
          </div>
          <div
            style={{
              fontSize: "11px",
              fontWeight: 500,
              color: "#636363",
              marginTop: "20px",
              marginLeft: 5,
            }}
          >
            {props.days}
          </div>
        </div>
        <div style={{ fontSize: "14px", fontWeight: "bold", color: "#636363", }}>
          {props.leaveName} Leave
        </div>
        <div style={{ fontSize: "12px", color: "#AEAEAE", fontWeight: 500, }}>{props.date}</div>
      </div>
    </div>
  );
}

function LeaveGraph({ getAllLeave, setAllLeaves, editLeaveObj, storeEditLeaveModalData, deleteLeaves, leavePageLoader, clearCreateLeaveObj, setBackToPrivilegedView }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    storeEditLeaveModalData("carryForward", false)
    storeEditLeaveModalData("halfDayApplicable", false)
    storeEditLeaveModalData("backDays", false)
    getAllLeave(null, 1)
  }, [])

  // const buttons = [
  //   {label: '', onClick: ()=>{setIsCreateModalVisible(true)}}
  // ]

  const actions = [
    <Button type="primary" onClick={() => { setIsCreateModalVisible(true) }} style={{}} icon={<PlusCircleOutlined />}>CREATE LEAVE</Button>
  ]

  const handleBackPress = () => {
    history.push('/attendance-dashboard');
    setBackToPrivilegedView(true);
  }

  const easySwitch = [
    { label: 'Leaves', action: () => { history.push('/leave-graph') } },
    { label: 'Geo Fence', action: () => { history.push('/attendance-geofence') } },
    { label: 'Holidays', action: () => { history.push('/holiday-page') } },
    { label: 'Attendance Requirement', action: () => { history.push('/attendance-requirements') } }
  ]

  if (setAllLeaves?.leaves?.length == 0) {
    return (
      <div> <LeavesCard setBackToPrivilegedView={setBackToPrivilegedView} /></div>
    )
  }
  else {

    return (
      <>
        <div style={bodyStyle}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ height: 40, width: "100%" }}>
              <PageHeader title='Leave Policies' actions={actions} onBackPress={() => handleBackPress()} easySwitch={easySwitch} />
            </div>
          </div>
          <div
            // className="border-16 boxShadow118"
            style={{
              width: "99%",
              display: "flex",
              justifyContent: "center",
              border: "#E6E6E6 solid 1px",
              borderRadius: "6px",
              padding: "10px",
              paddingTop: "10px",
              height: "400px",
              marginLeft: "10px",
              borderColor: "#E6E6E6",
              backgroundColor: "#FFFFFF",
              marginTop: "40px",
            }}
          >
            {setAllLeaves && setAllLeaves?.leaves && setAllLeaves?.leaves?.length !== 0 ?
              <div style={{ justifyContent: "center", cursor: "pointer", marginTop: "-12px", width: "500px" }}>
                <LeaveDonutGraph leaves={setAllLeaves?.leaves} />
              </div>
              : null}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "8px", marginTop: "30px" }}>
            {setAllLeaves?.leaves?.map((item) => (
              <LeaveCard
                storeEditLeaveModalData={storeEditLeaveModalData}
                num={item.numberOfDays}
                days={'days'}
                leaveName={item?.leaveType}
                startDate={item?.startDate}
                endDate={item?.endDate}
                date={`${item?.startDate}- ${item?.endDate}`}
                id={item?.id}
                isLocked={item?.isLocked}
                carryForward={item?.carryForward}
                halfDayApplicable={item?.halfDayApplicable}
                deleteLeaves={deleteLeaves}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setIsEditModalVisible={setIsEditModalVisible}
                isEditModalVisible={isEditModalVisible}
                backDays={item?.backDays}
              />
            ))}
          </div>
          {isModalVisible &&
            <ConfirmDeleteModal
              deleteVar={true}
              deleteEditVar={false}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
            />
          }
        </div>
        <div>

        </div>
        <div>
          {isEditModalVisible && <EditModal
            setAllLeaves={setAllLeaves}
            isEditModalVisible={isEditModalVisible}
            setIsEditModalVisible={setIsEditModalVisible}
            editLeaveObj={editLeaveObj}
          />
          }
        </div>
        {isCreateModalVisible && <CreateLeaveModal
          isCreateModalVisible={isCreateModalVisible}
          setIsCreateModalVisible={setIsCreateModalVisible}
        />}
        <PageDataLoader visible={leavePageLoader} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { setAllLeaves, editLeaveObj, leavePageLoader } = state.attendanceNew;
  return { setAllLeaves, editLeaveObj, leavePageLoader };
};

const mapDispatchToProps = (dispatch) => ({
  getAllLeave: (callBack, homepage) => dispatch(getAllLeave(callBack, homepage)),
  storeEditLeaveModalData: (key, value) => dispatch(storeEditLeaveModalData(key, value)),
  deleteLeaves: (id) => dispatch(deleteLeaves(id)),
  toggleFetchClassesLoader: () => dispatch(toggleFetchClassesLoader()),
  setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveGraph);