import React from "react";
import {
  Canvas,
  VideoControlBox,
  LiveModals,
  UtilityBox,
  Chatting
} from "components";
import { SyncOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { BrigoshaMedia, Api, Color } from "services";
// import {
//   endCall,
//   initCanvas,
//   updateChat,
//   onResetChatBadge,
//   postChat,
//   postChatImage,
//   toggleChatLoader,
//   toggleSideMenu,
//   toggleVideo,
//   toggleAudio,
//   updateInHandRaiseQ
// } from "actions/liveClass";
import { getPreviousChat } from "actions/classResources";
import { connectMedia } from "actions/today";
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import message from 'antd/lib/message';
import 'antd/lib/typography/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/message/style/index';
import ParticipantList from "./ParticipantList";
import HandRaiseList from "./HandRaiseList";
import VideoFeed from "./VideoFeed";
import "./styles.css";
const { Text } = Typography;

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  flex: 1
};

class LiveClass extends React.Component {
  componentDidMount() {
    const { connectMedia, roomName } = this.props;
    connectMedia(roomName);
    this.loadEarlierChats(1);
  }

  componentWillUnmount() {
    this.props.endCall();
  }

  handleOnSendMsg(message) {
    const { user, roomName, updateChat, postChat } = this.props;
    const params = {
      userId: user.id,
      message: message[0].text,
      type: "chat",
      roomName
    };
    if (message[0].text.length) {
      updateChat(message);
      postChat(params, message);
    }
  }

  loadEarlierChats(pageNumber) {
    const {
      confId,
      currentPage,
      toggleChatLoader,
      getPreviousChat,
      user
    } = this.props;
    const pageNo = !pageNumber ? parseInt(currentPage) + 1 : pageNumber;
    toggleChatLoader(true);
    getPreviousChat(confId, pageNo, user.orgId);
  }

  handleImageUpload(file) {
    const { user, roomName } = this.props;

    const params = {
      file,
      userId: user.id,
      roomName,
      type: "image"
    };
    this.props.postChatImage(params, user);
  }

  enableAudio() {
    this.props.brigoshaMedia.resumeProducer(BrigoshaMedia.MEDIA_TYPE.audio);
  }

  disableAudio() {
    this.props.brigoshaMedia.pauseProducer(BrigoshaMedia.MEDIA_TYPE.audio);
  }

  onPressVideo() {
    const { brigoshaMedia, toggleVideo, isVideo } = this.props;
    if (!isVideo) {
      brigoshaMedia.resumeProducer(BrigoshaMedia.MEDIA_TYPE.video);
      toggleVideo(true);
    } else {
      brigoshaMedia.pauseProducer(BrigoshaMedia.MEDIA_TYPE.video);
      toggleVideo(false);
    }
  }

  handleDoubtPress = () => {
    const { updateInHandRaiseQ, brigoshaMedia } = this.props;
    brigoshaMedia
      .raiseHandRequest()
      .then(() => {
        // to disable doubt double press
        updateInHandRaiseQ(true);
      })
      .catch(e => {
        const showerr = {
          type: "error",
          text: e
        };
        message.error(showerr.text);
      });
  };

  cancelHandRaise = () => {
    const { brigoshaMedia, updateInHandRaiseQ, user } = this.props;
    brigoshaMedia.rejectHandRequest(user.id);
    updateInHandRaiseQ(false);
    message.success("Request cancelled successfully");
  };

  onPressEnd = () => {
    const { endCall, history } = this.props;
    endCall();
    history.goBack();
  };

  render() {
    const {
      pages,
      onlineUsers,
      imageLoader,
      switchStudentCanvas,
      pathData,
      sideMenu,
      chats,
      user,
      chatCount,
      chatLoader,
      activeClass,
      isVideo,
      activeSpeaker,
      isAudio,
      isMyHandRaise,
      handRaiseQArray,
      initCanvas,
      isInHandRaiseQ,
      isClassConnected
    } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <Canvas
          onCanvasRef={ref => initCanvas(ref)}
          pathData={pathData}
          style={styles}
          height="calc(100vh - 186px)"
          showToolBar={false}
          prevPages={pages}
          switchCanvas={switchStudentCanvas}
        />
        <VideoControlBox
          onCancelHandRaise={() => this.cancelHandRaise()}
          activeClass={activeClass}
          activeSpeaker={activeSpeaker}
          inQ={isInHandRaiseQ}
          isMyHandRaise={isMyHandRaise}
          isAudio={isAudio}
          isVideo={isVideo}
          onPressVideo={() => this.onPressVideo()}
          onPressEnd={() => this.onPressEnd()}
          onPressHand={() => this.handleDoubtPress()}
          onPressAudio={() => this.enableAudio()}
          outPressAudio={() => this.disableAudio()}
        />
        <div className="utilityContainer">
          <UtilityBox
            chatCount={chatCount}
            handCount={handRaiseQArray.length}
            onlineCount={onlineUsers.length}
            pageNumber={switchStudentCanvas + 1}
            onPressChat={() => {
              this.props.onResetChatBadge();
              this.props.toggleSideMenu("chat");
            }}
            onPressHand={() => {
              this.props.toggleSideMenu("hand");
            }}
            onPressPart={() => {
              this.props.toggleSideMenu("part");
            }}
          />
        </div>

        <LiveModals
          hideModal={() => {
            this.props.toggleSideMenu(false);
          }}
          showModal={sideMenu}
          teacherName={activeClass.teacher.name}
          subject={activeClass.subject.name}
        >
          {sideMenu === "part" && <ParticipantList />}
          {sideMenu === "hand" && <HandRaiseList />}
          {sideMenu === "chat" && (
            <Chatting
              imgUpLoading={imageLoader}
              messages={chats}
              onSend={message => this.handleOnSendMsg(message)}
              onImgUploaded={(url, imageFile) =>
                this.handleImageUpload(url, imageFile)
              }
              user={{
                id: user.id,
                _id: user.id,
                name: user.name,
                avatar: `${Api._base_url}/api/${user.orgId}/uploads/users/${user.image}`
              }}
              onLoadEarlier={() => this.loadEarlierChats()}
              loadIndicator={chatLoader}
            />
          )}
        </LiveModals>
        <VideoFeed />
        <Modal
          visible={!isClassConnected}
          footer={null}
          centered
          closable={false}
        >
          <div className="r-c-c-c">
            <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
            <Text className="text-xmd m-t-10">Joining Class...</Text>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    pages,
    chatLoader,
    chatCount,
    switchStudentCanvas,
    roomName,
    isVideo,
    isAudio,
    isMyHandRaise,
    pathData,
    sideMenu,
    isInHandRaiseQ,
    onlineUsers,
    chats,
    isClassConnected,
    confId,
    imageLoader,
    handRaiseQArray
  } = state.liveClass;
  const { user } = state.session;
  const { activeClass } = state.today;
  const { currentPage } = state.classResources;
  const { remoteMedia, brigoshaMedia, activeSpeaker } = state.brigoshaMedia;

  return {
    currentPage,
    confId,
    pages,
    chatCount,
    isClassConnected,
    activeSpeaker,
    pathData,
    imageLoader,
    switchStudentCanvas,
    sideMenu,
    handRaiseQArray,
    isInHandRaiseQ,
    isVideo,
    isAudio,
    isMyHandRaise,
    chatLoader,
    chats,
    onlineUsers,
    roomName,
    user,
    activeClass,
    remoteMedia,
    brigoshaMedia
  };
};

const mapDispatchToProps = dispatch => ({
  connectMedia: roomName => dispatch(connectMedia(roomName)),
  endCall: () => dispatch(endCall()),
  onResetChatBadge: () => dispatch(onResetChatBadge()),
  initCanvas: canvas => dispatch(initCanvas(canvas)),
  toggleSideMenu: value => dispatch(toggleSideMenu(value)),
  toggleAudio: value => dispatch(toggleAudio(value)),
  toggleVideo: value => dispatch(toggleVideo(value)),
  updateChat: message => dispatch(updateChat(message)),
  postChat: (payload, message) => dispatch(postChat(payload, message)),
  getPreviousChat: (roomId, page, orgId) =>
    dispatch(getPreviousChat(roomId, page, orgId)),
  postChatImage: (params, user) => dispatch(postChatImage(params, user)),
  toggleChatLoader: value => dispatch(toggleChatLoader(value)),
  updateInHandRaiseQ: value => dispatch(updateInHandRaiseQ(value))
});
export default connect(mapStateToProps, mapDispatchToProps)(LiveClass);
