import React from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import { ArrowRightOutlined} from '@ant-design/icons';
import { submitModalFun } from "actions/mobileTest";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Button from "antd/lib/button";
import "antd/lib/button/style/index";
import "antd/lib/modal/style/index";

class SubmitModal extends React.Component{
  render(){
    const {history, studentTestResultPublishDate} = this.props;
    return(
      <Modal
        className="autoSaveModal NutinFont"
        centered
        visible={this.props.submitModalBool}
        bodyStyle={{
          padding: 0,
          borderRadius: 15,
          textAlign: "center",
          margin:'-10px'
        }}
        closable={false}
        width={1000}
        style={{ maxWidth: "calc(100vw - 16px)" }}
        footer={null}
      >
        <div className='p-20'>
          {/*<div className='text-right higrey'>
            <CloseOutlined onClick={() => this.props.submitModalFun(false)} />
          </div>*/}
          <IoIosCheckmarkCircleOutline className="seaGreen" style={{fontSize:'5em'}}/>
          <div className="text-md color-black bold-700">Successfully Submitted</div>
          <div className="higrey text-xmd m-b-20">
            Your test has been successfully submitted.
          </div>
          <Button
            onClick={() => {
              this.props.submitModalFun(false);
              history.push(`/web-test`);
            }}
            className='mobileTestGreenBtnWrap m-b-20'
          >
            HOME <ArrowRightOutlined />
          </Button>
        </div>
        <div onClick={() => {}} className='p-10' style={{background:'#EAFCF5', borderRadius:'0px 0px 10px 10px'}}>
          <div className="text-sm color-black bold-700">Your result will be published on</div>
          <div className="text-xmd higrey">{studentTestResultPublishDate}</div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { submitModalBool, studentTestResultPublishDate } = state.mobileTest;
  return {
    submitModalBool, studentTestResultPublishDate
  };
};

const mapDispatchToProps = dispatch => ({
  submitModalFun: (bool) => dispatch(submitModalFun(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitModal);
