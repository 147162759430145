import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';

import { MdKeyboardBackspace } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { Avatar} from 'antd';
import './styles.css';
import { fetchStudentResults,setCModalType,setIsCModalVisible,updateFilter,updateSelectedStudent, updateSelectedTestId, updateSubmittedTestsStudentId } from 'actions/testAdmin';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import {NodataCard} from './index'
import ConfirmationModal from './modals/confirmationModal';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from './localComponent/searchBox';
import Loader from './localComponent/loader';


const StudentRow = ({declared, index, name, rollno, obtain, total, user, handleStudentCardClick, id, rank}) => {
  return(
    <div onClick={()=>handleStudentCardClick(user, id)} className='r-c-sb p-20 hover-shadow cursor-pointer m-b-15' style={{backgroundColor:"#fff", borderRadius:5, width:"100%", minWidth:300, maxWidth:400}}>
      <div className='r-ac'>
        <div className='text-sm bold-600 color-black'>{declared == 0 ? index : rank}</div>
        <div className='r-ac m-l-20'>
          <Avatar size={50} icon={<FaUserAlt />} className='color-purple' style={{background:'#F6F4FE'}} />
          <div className='m-l-15'>
            <div className='text-xmd bold-600 color-black'>{name}</div>
            <div className='text-xs a2grey'>Roll No. {rollno}</div>
          </div>
        </div>
      </div>
      <div className='text-sm bold-600 color-black'>{obtain}/{total}</div>
    </div>
  )
}

const PageHeader = ({goBack, title, onSearch, searchVal}) => {
  return (
    <div className="full-width m-b-20 r-c-sb">
      <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{fontSize:18}}/>
          <div className="text-md bold-700 m-l-20"> {title} </div>
      </div>
      <div className="">
          <SearchBox  loading={false} 
                      onChange={onSearch}
                      placeholder="Search by student name"
                      style={{width:220, marginLeft:10}}
                      value={searchVal}/>
      </div>
    </div>
  )
}

let timer = false;
function PublishResultScreen ({history, testStudentResult, fetchStudentResults, 
                                selectedTest, updateSelectedStudent, isCModalVisible, 
                                cModalType, setCModalType, setIsCModalVisible, updateSelectedTestId,
                                reloadToggler, updateSubmittedTestsStudentId, updateFilter, filter, loaders}) {
                                  
                                  
    const [pageLoader, togglePageLoader] = useState(false);
    const handleStudentCardClick = (user, id) => {
      updateSubmittedTestsStudentId(id)
      updateSelectedStudent(user);
      history.push('test-result-screen')
    } 
    const handleButtonClick = (id, type)=>{
      updateSelectedTestId(id);
      if (type=="Publish"){
          setCModalType("Publish");
          setIsCModalVisible(true);
      }
  }
    useEffect(() => {
        togglePageLoader(true);
        fetchStudentResults(selectedTest.id?selectedTest.id:selectedTest.testId?selectedTest.testId:null, (bool)=>{togglePageLoader(bool)});
    }, [reloadToggler]);

    useEffect(()=>{
      clearTimeout(timer);
      timer = setTimeout(()=>{
        fetchStudentResults(selectedTest.id?selectedTest.id:selectedTest.testId?selectedTest.testId:null, (bool)=>{togglePageLoader(bool)});
      }, 1000);
    }, [filter?.studentSearch]);

    const goBack = () => {
      history.goBack();
    }
    const onSearch = (e) =>{
      updateFilter('studentSearch', e.target.value);
    }
    return (
      <div style={{margin:'-20px', padding:30, backgroundColor:"#FAFAFA", overflow:"hidden", height:"calc(100% + 40px)", width:"calc(100% + 40px)", overflow:"hidden"}}>
        <PageHeader title={testStudentResult.test?.title} goBack={goBack} onSearch={onSearch} searchVal={filter?.studentSearch}/>

        <div className='display-flex' style={{height:'100%'}}>
          <div className='flex-1' style={{height:"90%", overflowY:"auto"}}>
            {testStudentResult?.test?.declared == 0 ? <></> : <div className='text-xs bold-600 a2grey p-l-20'>RESULT BY RANK</div>}
            <div className='p-20' style={{height:"90%"}}>
              {
                loaders?.studentSearchLoader
                ?
                  <Loader style={{fontSize:30}}/>
                :
                  testStudentResult?.users?.length>0
                  ?
                  testStudentResult?.users?.map((user, index) => (
                  <StudentRow history={history} key={index} name={user.username} rollno={user.rollNo} total={user?.totalMarks/* testStudentResult.test?.totalPoints */} 
                              obtain={user.gradeObtained} index={index+1} handleStudentCardClick={handleStudentCardClick} user={user} id={user.userId} declared={testStudentResult?.test?.declared} rank={user?.rank}/>))
                  :
                  <NodataCard/>
              }
            </div>
          </div>
          <div className='vertical-divider'></div>
          <div className='position-relative'>
            <div style={{width:340, height:'95%', overflowY:"auto"}}>
              <div className='text-sm m-l-20 color-black bold-600'>Test details</div>
              <div className='p-20'>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>TITLE</div>
                  <div className='text-xmd bold-600 color-black '>{testStudentResult?.test?.title}</div>
                </div>
                <div className='r-ac m-b-20'>
                  <div className='m-r-50'>
                    <div className='text-xs bold-600 a2grey'>INDEX</div>
                    <div className='text-xmd bold-600 color-black '>{testStudentResult?.test?.index}</div>
                  </div>
                  <div className=''>
                    <div className='text-xs bold-600 a2grey'>CLASS</div>
                    <div className='text-xmd bold-600 color-black '>{testStudentResult?.test?.standardNames}</div>
                  </div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>SUBJECT(S)</div>
                  <div className='display-flex flex-wrap'>
                    {testStudentResult?.test?.subjectNames?.split(',')?.join(' • ')}
                  </div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>EXAMINER</div>
                  <div className='text-xmd bold-600 color-black '>{testStudentResult?.test?.teacherName}</div>
                </div>
                <div className='r-ac m-b-20'>
                  <div className='m-r-50'>
                    <div className='text-xs bold-600 a2grey'>TEST HELD ON</div>
                    <div className='r-ac'>
                      <div className='text-xmd bold-600 color-black '>{moment(testStudentResult.test?.testDate, "DD,MMM,YYYY").format("DD/MMM/YYYY")}</div>
                      <div className='p-3 radius-100 m-l-10 m-r-10' style={{background: '#191919'}}></div>
                      <div className='text-xmd bold-600 color-black '>{testStudentResult.test?.startTime}</div>
                    </div>
                  </div>
                  <div className=''>
                    <div className='text-xs bold-600 a2grey'>SUBMISSIONS</div>
                    <div className='text-xmd bold-600 color-black '>{testStudentResult.test?.submissionCount}/{testStudentResult.test?.studentCount}
                    </div>
                  </div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>RESULT PUBLISHED ON</div>
                  <div className='r-ac'>
                    <div className='text-xmd bold-600 color-black '>{moment(testStudentResult.test?.resultDate, "DD MMM, YYYY").format("DD/MMM/YYYY")}</div>
                    {/* <div className='p-3 radius-100 m-l-10 m-r-10' style={{background: '#191919'}}></div> */}
                    {/* <div className='text-xmd bold-600 color-black '>{testStudentResult.test?.resultTime}</div> */}
                  </div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>TEST TYPE</div>
                  <div className='text-xmd bold-600 color-black '>{testStudentResult.test?.testType}</div>
                </div>

              </div>
            </div>
            {selectedTest.declared=="Draft"
            ?
            <div className='r-c-c m-t-30 test-save-sticky-footer p-20'>
              <Button className='purpleBtnWrap radius-100' onClick={()=>handleButtonClick(test.id?test.id:test.testId?test.testId:null, "Publish")}>PUBLISH RESULT</Button>
            </div>
            :
            null
            }

          </div>
        </div>
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
        {/* <PageDataLoader visible={pageLoader}/> */}
      </div>
  );
}


const mapStateToProps = state => {
  const {testStudentResult, selectedTest, isCModalVisible, cModalType, reloadToggler, filter, loaders} = state.testAdmin;
  return {
    testStudentResult, selectedTest, isCModalVisible, cModalType, reloadToggler, filter, loaders
  }
};

const mapDispatchToProps = dispatch => ({
  fetchStudentResults: (testId, callback)=>dispatch(fetchStudentResults(testId, callback)),
  updateSelectedStudent: (student)=>dispatch(updateSelectedStudent(student)),
  updateSelectedTestId: (testId)=> dispatch(updateSelectedTestId(testId)),
  setCModalType: (type)=>dispatch(setCModalType(type)),
  setIsCModalVisible: (bool)=>dispatch(setIsCModalVisible(bool)),
  updateSubmittedTestsStudentId: (id) => dispatch(updateSubmittedTestsStudentId(id)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val))
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishResultScreen);