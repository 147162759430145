import { Button, Modal } from "antd";
import React from "react";
import image from "../../Assets/ProfileAssets/Group 1.svg";

function SuccessModal({ isModalVisible, handleOk, handleCancel }) {
  return (
    <div>
      <Modal
        className="SuccessModalB"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={image} alt="" />
          <h2
            style={{
              fontFamily: "Roboto",
              textAlign: "center",
              color: "#594099",
              marginBottom: "0px",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            Successfully saved!
          </h2>
          <p style={{ color: "#636363", fontSize: "15px" }}>
            Your details were successfully saved.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "30px",
            marginBottom: "50px",
          }}
        >
          <Button
            onClick={handleOk}
            style={{
              borderRadius: "20px",
              backgroundColor: "#594099",
              width: "150px",
              height: "40px",
            }}
          >
            {/* <SearchOutlined style={{ color: "white" }} /> */}
            <span
              style={{ color: "white", fontSize: "12px", fontWeight: "600" }}
            >
              OKAY
            </span>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default SuccessModal;
