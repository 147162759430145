import {
  addUserByRole,
  getUserDashBoardData,
  setClass,
  setEmail,
  setPhone,
  setPrivilage,
  setRollNumber,
  setSortType,
  setTab,
  setName,
  setUserName,
  setSpecificUserId,
  setEndDate,
  setStartDate,
  setPage,
  storeAdduserFields,
  clearAddUserFields,
  setDashBoardRole,
} from "actions/userManagement";
import { Col, Input, Pagination, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import AddStdTchrModal from "./addStdTchrModal";
import AddUsers from "./addUsers";
import GenderRatio from "./genderRatio";
import GrowthSummary from "./growthSummary";
import UserCard from "./userCard";
import { IMG_URL } from "../../env.json";
import PageDataLoader from "components/pageDataLoader";
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
import moment from "moment";
import PageHeader from "components/pageHeader";
import { Heading } from "components/Typography";
import {Button} from 'components/Button'
import { ConsoleSqlOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InputSearch } from "components/customInputs";

// import { setSartDate } from "actions/schedule";
// import { setSpecificStudentUseId } from "actions/adminCare";

const goBack = (history) => {
  history.goBack();
};

function DashboardByRole({
  dashBoardRole,
  getUserDashBoardData,
  dashBoardDataByRole,
  setSortType,
  setName,
  addUserByRole,
  setPrivilage,
  setEmail,
  setPhone,
  setClass,
  setRollNumber,
  setUserName,
  setSpecificUserId,
  role,
  sort,
  setEndDate,
  setStartDate,
  user,
  setPage,
  storeAdduserFields,
  clearAddUserFields,
  setDashBoardRole
}) {
  const [fieldDisabledForFee, setFieldDisabledForFee] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  useEffect(() => {
    let temp = false;
    temp =
      user.privilage.includes("SuperAdmin") || user.privilage.includes("Admin");
    console.log("temp", temp);
    setFieldDisabledForFee(!temp);
  }, []);
  useEffect(() => {
    setEndDate(moment().format("YYYY-MM-DD"));
    setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
  }, []);
  useEffect(() => {
    setName(null);
    setPage(1);
    setSortType("New");
    setPrivilage([]);
    setEmail(null);
    setPhone(null);
    setUserName(null);
    setClass(null);
    setRollNumber(null);
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false));
  }, []);
  const showMoreUsers = (e) => {
    setPage(e)
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false), e);
  };
  const history = useHistory();
  const handleViewDetails = (id) => {
    setSpecificUserId(id);
    if (dashBoardRole == "Student") {
      history.push("/user-management/students");
    } else if (dashBoardRole != "Data operator") {
      // console.log(dashBoardRole);
      history.push(`/user-management/${dashBoardRole?.toLowerCase()}/details`);
    } else {
      history.push("/user-management/data-operator/details");
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleViewMore = () => {
    history.push("/user-management/students/unassigned");
  };

  const { Option } = Select;

  const handleChangeFilter = (value) => {
    console.log(value);
    setSortType(value);
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false));
  };
  const handleSearch = (e, value) => {
    console.log("On clear", e);
    setPage(1);
    setName(e);
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false));
  };

  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const successCallback = () => {
    clearAddUserFields('name', '');
    clearAddUserFields('email', '');
    clearAddUserFields('privilage', []);
    clearAddUserFields('phoneNo', null);
    clearAddUserFields('course', '');

    setClass(null);
    setRollNumber(null);
    setIsRoleModalVisible(false);
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false));
  };

  const toggleButtonLoader = () => {
    setButtonLoader(false);
  };
  
  const handleRoleOk = () => {
    if(dashBoardRole == 'Student'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Teacher'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Data operator'){
      storeAdduserFields('role', "Data Operator")
    }else if(dashBoardRole == 'Operation'){
      storeAdduserFields('role', dashBoardRole)
    }
    setButtonLoader(true);
    addUserByRole(
      () => successCallback(false),
      () => toggleButtonLoader()
    );
  };

  const showRoleModal = () => {
    setIsRoleModalVisible(true);
  };

  const handleRoleCancel = () => {
    clearAddUserFields('name', '');
    clearAddUserFields('email', '');
    clearAddUserFields('privilage', []);
    clearAddUserFields('phoneNo', null);
    clearAddUserFields('course', '');

    setClass(null);
    setRollNumber(null);
    setButtonLoader(false);
    setIsRoleModalVisible(false);
  };

  const actions = [
    user?.privilage?.includes('FeeManager') || user?.privilage?.includes('LeaveManager') ? <></> : <Button type="primary" onClick={()=>{showRoleModal()}}  style={{}} icon={<PlusCircleOutlined/>}>ADD A USER</Button>
  ]

  const handleBackPress = () => {
    history.push('/user-management');
  }

  const easySwitch = [
    {label: 'Students', action: () => {setDashBoardRole('Student'); history.push('/user-management/students'); setPageLoader(true); getUserDashBoardData(()=>{setPageLoader(false)})}},
    {label: 'Teachers', action: () => {setDashBoardRole('Teacher'); history.push('/user-management/Teacher'); setPageLoader(true); getUserDashBoardData(()=>{setPageLoader(false)})}},
    {label: 'Data Operators', action: () => {setDashBoardRole('Data operator'); history.push('/user-management/data-operator'); setPageLoader(true); getUserDashBoardData(()=>{setPageLoader(false)})}},
    {label: 'Operations', action: () => {setDashBoardRole('Operation'); history.push('/user-management/operations'); setPageLoader(true); getUserDashBoardData(()=>{setPageLoader(false)})}}
  ]

  return (
    <div>

      <div style={{ marginTop: -10, height: 50}}>
        <PageHeader title={`${dashBoardRole}s`} actions={actions} onBackPress={()=>handleBackPress()} easySwitch={easySwitch}/>
      </div>

      <div className="m-t-20">
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 16 }}>
            <GrowthSummary setPageLoader={setPageLoader}/>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            {/* <ApprovalPanel /> */}
            <GenderRatio />
          </Col>
        </Row>
      </div>
      <div
        style={{}}
      >
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Heading>
            All {dashBoardRole}s
            <span style={{ color: "grey" }}>
              {" "}
              ({dashBoardDataByRole?.totalUsers})
            </span>
          </Heading>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                height: "40px",
                display: "flex",
                flexDirection: "row",
                marginRight: "10%",
                justifyContent: "space-between",
                alignItems: "center",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
              }}
            >
              <Select
                className="inputSelecter"
                style={{ width: 200, border: "none" }}
                onChange={handleChangeFilter}
                bordered={false}
                removeIcon={true}
                dropdownMatchSelectWidth={true}
                value={sort}
              >
                <Option value="Old">
                  <h3>Oldest first</h3>
                </Option>
                <Option value="New">
                  <h3>Newest first</h3>
                </Option>
                <Option value="Alphabetical orders">
                  <h3>Alphabetical orders</h3>
                </Option>
              </Select>
            </div>
              <InputSearch
                allowClear
                style={{ fontSize: "1em" }}
                onEnter={(e)=>handleSearch(e)}
                placeholder="Search By Name / Phone No..."
              />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
         <Row style={{flexWrap: "wrap", justifyContent: "space-between"}}>
            {dashBoardDataByRole &&
              dashBoardDataByRole?.response?.items?.length !== 0 &&
              dashBoardDataByRole?.response?.items?.map((item) => (
                <Col 
                  style={{ cursor: "pointer", width: "22%", minWidth: 250}}
                  onClick={() => handleViewDetails(item?.id)}
                >
                  <UserCard
                    privilage={item?.privilage}
                    phoneNo={true}
                    name={item?.name}
                    cell={item?.phone}
                    avatar={`${IMG_URL}/${
                      item?.image ? item?.image : "default.png"
                    }`}
                    loc={'dashByRole'}
                  />
                </Col>
              ))}
            {dashBoardDataByRole &&
            dashBoardDataByRole?.response?.items?.length == 0 ? (
              <div
                className="r-c-c-c"
                style={{ width: "100%", height: "60vh", marginTop: "-50px" }}
              >
                <div>
                  <img src={illNoData} />
                </div>
                <div>
                  <h4>
                    <b>No data!</b>
                  </h4>
                </div>
                <div>
                  <h5>
                    There are no data to show yet. Please check back later
                  </h5>
                </div>
              </div>
            ) : null}
          </Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <Pagination
              size="small"
              defaultCurrent={1}
              current={dashBoardDataByRole?.response?.meta?.currentPage}
              total={dashBoardDataByRole?.response?.meta?.totalPages * 10}
              onChange={showMoreUsers}
              showSizeChanger={false}
            />
          </div>
        </div>

        <PageDataLoader visible={pageLoader} />
      </div>

      {/* <AddUsers isModalVisible={isModalVisible} showModal={showModal} handleOk={handleOk} handleCancel={handleCancel} showRoleModal={showRoleModal} /> */}
      {isRoleModalVisible && (
        <AddStdTchrModal
          isRoleModalVisible={isRoleModalVisible}
          showRoleModal={showRoleModal}
          handleRoleOk={handleRoleOk}
          handleRoleCancel={handleRoleCancel}
          buttonLoader={buttonLoader}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, dashBoardDataByRole, sort } =
    state.userManagement;
  const { user } = state.session;
  return {
    tab,
    dashBoardRole,
    dashBoardDataByRole,
    sort,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTab: (m) => dispatch(setTab(m)),
  getUserDashBoardData: (callback, page) =>
    dispatch(getUserDashBoardData(callback, page)),
  addUserByRole: (callback, callbackLoader) =>
    dispatch(addUserByRole(callback, callbackLoader)),
  setSortType: (sort) => dispatch(setSortType(sort)),
  setName: (name) => dispatch(setName(name)),
  setPrivilage: (value) => dispatch(setPrivilage(value)),
  setEmail: (value) => dispatch(setEmail(value)),
  setPhone: (value) => dispatch(setPhone(value)),
  setClass: (value) => dispatch(setClass(value)),
  setRollNumber: (value) => dispatch(setRollNumber(value)),
  setUserName: (name) => dispatch(setUserName(name)),
  setSpecificUserId: (id) => dispatch(setSpecificUserId(id)),
  setStartDate: (date) => dispatch(setStartDate(date)),
  setEndDate: (date) => dispatch(setEndDate(date)),
  setPage:(val) => dispatch(setPage(val)),
  storeAdduserFields:(key, val) => dispatch(storeAdduserFields(key, val)),
  clearAddUserFields: (key,val) => dispatch(clearAddUserFields(key,val)),
  setDashBoardRole:(m) => dispatch(setDashBoardRole(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardByRole);