import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Utils } from 'services';
import ConsumptionTracker from 'services/consumptionTracker';
import {recordConsumption} from 'actions/broadcast';
import ReactHlsPlayer from 'pages/broadcast/hls-player';

let consumptionTracker = null;
const withLogger = (WrappedComponent) => {
  return (props) => {
    const playerRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8);
    const LOGGER_ENABLED = props.contentId!=null && props.enableLogger;
    const KEYBOARD_CONTROLS_ENABLED = props.keyboardControls ? props.keyboardControls : false;
    const baseProgress = props.onProgress;
    const baseProgressInterval = props.progressInterval || 1000;
    const baseResolution = props.currentResolution || 720;
    const baseModuleType = props.moduleType || 'DIGITALLIBRARY';
    const baseProps = Utils.__rest(props, ["onProgress", "progressInterval", "keyboardControls"]);
    const handleKeyDown = (event) => {
        if (!playerRef.current) return;
        switch (event.key) {
          case ' ': // Space to toggle play/pause
            event.preventDefault(); // Prevent spacebar from scrolling
            setPlaying((playing)=>!playing);
            break;
          case 'ArrowRight': // Right arrow to seek forward
            playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
            break;
          case 'ArrowLeft': // Left arrow to seek backward
            playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
            break;
          case 'ArrowUp': // Up arrow to increase volume
            setVolume(volume => Math.min(volume + 0.1, 1));
            break;
          case 'ArrowDown': // Down arrow to decrease volume
            setVolume(volume => Math.max(volume - 0.1, 0));
            break;
          default:
            break;
        }
      };
    const wrappedProgress = () => {
        console.log("32")
        if (LOGGER_ENABLED){
            consumptionTracker?.incrementIntervalProgress({id: props.contentId, module:baseModuleType, resolution: baseResolution});
        }
        if (baseProgress){
            baseProgress()
        }
    }
    const handleReady = (player) => {
      const video = player.getInternalPlayer();
      console.log("29999 is", video, video.videoWidth, video.videoHeight)
      // setResolution({ width: video.videoWidth, height: video.videoHeight });
    };
    const cleanUp = () => {
        if (LOGGER_ENABLED){
            recordConsumption().then(()=>{
                console.log("118")
                consumptionTracker?.reset();
                consumptionTracker?.recordStorage();
                console.log("120 success", consumptionTracker)
              });
        }
        if (KEYBOARD_CONTROLS_ENABLED){
            window.removeEventListener('keydown', handleKeyDown);
        }
    }
    useEffect(()=>{
        if (LOGGER_ENABLED){
            consumptionTracker = new ConsumptionTracker();
            consumptionTracker?.setVideoDurationIncrement(baseProgressInterval);
        }
        if (KEYBOARD_CONTROLS_ENABLED){
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            cleanUp();
        }
    }, []);

    return (
      <WrappedComponent
        ref={playerRef}
        progressInterval={baseProgressInterval}
        onProgress={wrappedProgress}
        playing={playing}
        volume={volume}
        onReady={handleReady}
        {...baseProps}
      />
    );
  };
};

const ReactPlayerHoc = withLogger(ReactPlayer);
export const ReactHlsPlayerHoc = withLogger(ReactHlsPlayer)

export default ReactPlayerHoc;