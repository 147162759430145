import React from "react";
import { Api } from '../../services';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import ReactQuill from "react-quill";
import { removeImage } from './imageHandler';
import QuillToolbar, {  formats, modules, uploadedImageName } from "./toolbar";
import "react-quill/dist/quill.snow.css";
// import katex from 'katex';
// import 'katex/dist/katex.min.css';
// window.katex = katex;
// import "./styles.css";
import './styles.css'




class FormulaEditor extends React.Component{
  static quillRef = null;
  static uploadUrl = "";
  constructor(props){
    super(props);
    this.state = {
      data: ""
    }
  }

  componentDidMount(){
    FormulaEditor.quillRef.getEditor().focus()
  }


  onChange(){
    const editorData =  FormulaEditor.quillRef ? FormulaEditor.quillRef.getEditorContents() : '';
    const { imagesList, deletedImages, imgPath } = this.props;
    const editorImgs = Array.from( new DOMParser().parseFromString( editorData, 'text/html' )
    .querySelectorAll( 'img' ) )
    .map( img => img.getAttribute( 'src' ) );
    if(editorImgs.length){
      if(!editorImgs.includes(null)){
        if(editorImgs.length > imagesList.length){
          imagesList.push(uploadedImageName)
        }else if(editorImgs.length < imagesList.length){
          imagesList.forEach((item, index) => {
            const deleteIndex  = editorImgs.indexOf(`${Api._base_url}/${this.props.user.orgId}${imgPath}${item}`);
            if(deleteIndex === -1){
              const deletedImage = imagesList[index];
              removeImage(deletedImage, deletedImages, imagesList, index);
              return
            }
          })
        }
      }
    }else{
      const count = imagesList.length;
      if(count){
        imagesList.forEach((img, index) =>
          removeImage(img, deletedImages, imagesList, index)
        )
      }
    }
  }

  onSave(){
    const editorData = FormulaEditor.quillRef.getEditorContents();
    this.props.onChange(editorData)
  }

  onCancel(){
      const editorData = FormulaEditor.quillRef.getEditorContents();
      const { imagesList, deletedImages } = this.props;
      const editorImgs = Array.from( new DOMParser().parseFromString( editorData, 'text/html' )
      .querySelectorAll( 'img' ) )
      .map( img => img.getAttribute( 'src' ).split('/').pop().split('#').shift().split('?').shift() );
      const count = editorImgs.length;
      if(count){
        editorImgs.forEach((img, index) =>
          removeImage(img, deletedImages, imagesList, index)
        )
      }
  }

  render(){
    const {  data, id, resetEditor, addOption, disableOptAdd } = this.props;
    return (
      <div className="text-editor">
        <QuillToolbar/>
        <ReactQuill
          selection={{start:0, end:0}}
          ref={(el) => FormulaEditor.quillRef = el}
          theme="snow"
          value={data} // data || ""
          onChange={(value) => this.onChange(value)}
          placeholder={"Type here..."}
          modules={modules}
          formats={formats}
          style={{height: '30vh'}}
        />
      <div style={{marginTop: 20, display: 'flex', justifyContent: 'flex-end'}}>
        <Button style={{borderRadius: 10, marginRight:10}} onClick={() => {
          resetEditor()
          // this.onCancel()
         }}>Close</Button>
        { id !== "statement" && 
        <Button disabled={disableOptAdd}  style={{borderRadius: 10, marginRight:10}} type="primary" onClick={async () => {
          this.onSave()
          await resetEditor()
          addOption()
          // setTimeout(() =>  addOption(), 500)
         
          }}>Save & Add More</Button>}
        <Button style={{borderRadius: 10}} type="primary" onClick={() => {
          this.onSave()
          resetEditor()}}>Save & Close</Button>
      </div>
      </div>
    );
  }
};


export default FormulaEditor;
