import React, {useEffect, useState} from 'react';
import { ArrowUpOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { Button } from './Button';

const wrapperStyle = {
  display: 'flex',
  left: '45vw',
  justifyContent: 'center',
  position:"fixed",
	bottom:"20px",
  right: "40px",
  zIndex: 0,
  width: '20%'
}

const buttonStyle = {
  opacity: 0.7,
  backgroundColor: '#1089FF',
  border: 'none',
  width: 150,
  color:"#FFFFFF"
}

const BackToTop = () => {

  const [scrollTop, setScrollY] = useState(0);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setScrollY(document.getElementById("mainBody").scrollTop);
    }
    document.getElementById("mainBody").addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById("mainBody").removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollTop > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [scrollTop]);

  const  handleBackToTop = () => {
    document.getElementById("mainBody").scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  

  return (
    <div style={{...wrapperStyle, visibility: showButton ? 'visible': 'hidden',}} className="headerStyle" id="backToTop" >
        <Button icon={<ArrowUpOutlined />} shape="round" type="primary" style={buttonStyle} onClick={handleBackToTop}>Back to Top</Button>
    </div>
  )
}

export default BackToTop