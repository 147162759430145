import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SearchBox from './localComponents/searchBox';
import ReadyToPublishCard from './localComponents/readyToPublishCard';
import {Button, Dropdown, Tabs} from 'antd'
import { MdMoreVert } from 'react-icons/md';
import CustomMenu from 'pages/practiseTest/localComponents/customMenu';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTestsByStage, setOpenTab, toggleReloaderFlag, updateFilter, getTestChapterList, updateListViewType, createTestModal } from 'actions/practiseTest';
import TestCard from "./localComponents/practiseTestCards"
import PageDataLoader from 'components/pageDataLoader';
import Loader from './localComponents/loader';
const chapterTestImg = require("../../Assets/Practise Test/ic-chapter-test.svg").default
import CreateTestModal from 'pages/practiseTest/modals/createTestModal';
import ConfirmationModal from './modals/confirmationModal';
import TestPublishModal from './localComponents/testPublishModal';


const bodyStyle = {background: "#FAFAFA",
        width: "105%",
        height: "94vh",
        margin:-20,
        padding:20,
        overflow:"auto"
    }

    const {TabPane} = Tabs;

const PageHeader = ({ goBack, onSearch, filter, classname, subjectname, syllabus, chaptername, chapterSelected, onCreateClick}) => {
      return (
        <div className="resultPageHeader full-width">
          <div className="r-c-fs m-r-30">
            <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
            <div className="text-md bold-700 m-l-20"> 
              <div>
                {chapterSelected?chaptername:subjectname}
              </div>
              <div style={{color:"#AEAEAE", fontSize:12}}>
                {classname} / {syllabus} {chapterSelected?"/ "+subjectname:""}
              </div>
            </div>
          </div>
          <div className="testfiltersgroup m-r-50">
             <SearchBox loading={false} onChange={onSearch}  value={filter?.tabSearch} className="m-r-10" placeholder="Search by test name" style={{width:230}}/>
             <Button onClick={onCreateClick} className="purpleBtnWrap radius-100 purpleButtonHoverEffect m-r-50">
             <PlusCircleOutlined />CREATE TEST
             </Button>
          </div>
        </div>
      );
};

function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, /* marginBottom: "20px" */ }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}


let timer = false

const TestTabsPage = ({openTab, reloadToggler, filter,fetchTestsByStage, updateFilter, toggleReloaderFlag, 
  setOpenTab, publishedResults, feedbackFromTeacher, sentForFeedback, drafts, testsToPublish, selectedChapter, 
  selectedClassroom, loaders, selectedSubject, createTestModalBool, updateListViewType, createTestModal, user, 
  getTestChapterList, isCModalVisible, cModalType, userType}) => {

  const [pageLoader, togglePageLoader] = useState(false);


  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }

  useEffect(() => {
    togglePageLoader(false);
    if(openTab==='feedback'){
        fetchTestsByStage('feedback', ()=>{});
        fetchTestsByStage('review', ()=>{
            togglePageLoader(false);
        });
    }
    else if(openTab === 'draft'){
      fetchTestsByStage('draft', ()=>{});
      fetchTestsByStage('ready', ()=>{
          togglePageLoader(false);
      })
    }
    else{
        fetchTestsByStage(openTab, ()=>{
            togglePageLoader(false);
        });
    }

}, [openTab, reloadToggler]);
useEffect(() => {

        clearTimeout(timer);
        timer = setTimeout(() => {
            if(openTab==='feedback'){
                fetchTestsByStage('feedback', ()=>{});
                fetchTestsByStage('review', ()=>{
                    togglePageLoader(false);
                });
            }
            else if(openTab === 'draft'){
              fetchTestsByStage('draft', ()=>{});
              fetchTestsByStage('ready', ()=>{
                  togglePageLoader(false);
              })
            }
            else{
                fetchTestsByStage(openTab, ()=>{});
            }
        },1000);
    }
, [filter?.tabSearch]);

    const onSearch =(e)=>{
        updateFilter('tabSearch', e.target.value);
    }
    const onCreateClick = () => {
      updateListViewType('create');
      createTestModal('test-create', user, filter.selectedSyllabus==='Chapter Test'?'chapter':'subject');
      if (filter?.selectedSyllabus==='Chapter Test'||selectedSubject?.isCombined===false){
          getTestChapterList(null, null, true, null);
      }
    }

  return (
    <div style={bodyStyle}>
        <PageHeader
                  filter={filter}
                  goBack={goBack}
                  onSearch={onSearch}
                  classname={`${selectedClassroom?.name}`} 
                  subjectname={selectedSubject?.subjectName} 
                  syllabus={filter?.selectedSyllabus} 
                  chaptername = {filter?.selectedChapter?.chapterName}
                  chapterSelected={filter?.selectedSyllabus==='Chapter Test'?true:false}
                  onCreateClick={onCreateClick}
        />
 
    <div className='m-t-20'>
      <Tabs className="full-height" defaultActiveKey={openTab}
                    style={{overflowY:'scroll'}}
                    onChange={(activeKey)=>{
                      // changeTestTypeFilter()(null);
                      setOpenTab(activeKey)
                      toggleReloaderFlag();
                  }}>
                <TabPane tab="DRAFT" key="draft" > 
                      {
                        testsToPublish?.length?
                        <div className="bold-800 text-xmd m-b-10">Test(s) ready to be published</div>
                        :null
                      }
                      <div className='display-flex flex-wrap'>
                      {testsToPublish?.map((item)=>(
                        <TestCard type={'ready'} page={'index'} test={item} imgval={chapterTestImg}/>
                      ))}
                  </div>
                      {/* {drafts && Array.isArray(drafts) && drafts?.length? */}
                        <>
                      <div className='m-t-30 bold-800 text-xmd m-b-10'>All draft(s)</div>
                      <div className='display-flex flex-wrap'>
                      {drafts?.map((item)=>(
                        <TestCard type={'draft'} page={"tab"} test={item}/>
                      ))}
                      </div>
                      </> 
                      {(drafts && Array.isArray(drafts) && drafts.length) || (testsToPublish && Array.isArray(testsToPublish) && testsToPublish.length) ?  null : <NodataCard/>}
                </TabPane>
                <TabPane tab="FEEDBACK" key="feedback" >
                  <>
                        {(feedbackFromTeacher?.length>0)
                          ?
                        <div className='m-t-30 bold-800 text-xmd m-b-10'>{userType==='teacher'?'Review sent':'Feedback from teacher(s)'}</div>
                        :null}
                        <div className='display-flex flex-wrap'>
                        {feedbackFromTeacher?.map((item)=>(
                        <TestCard type={"feedback"} page={"tab"} test={item}/>))
                          }
                        </div>
                        {sentForFeedback?.length?
                        <div className='m-t-30 bold-800 text-xmd m-b-10'>{userType==='teacher'?'Test(s) to be reviewed':'Sent for feedback'}</div>
                        :null
                        }
                        <div className='display-flex flex-wrap'>
                        {sentForFeedback?.map((item)=>(
                        <TestCard type={'review'} page={"tab"} test={item}/>))}
                        </div>
                        {feedbackFromTeacher?.length === 0 && sentForFeedback?.length === 0 ? <NodataCard/>:null}
                        </>
                </TabPane>
                <TabPane tab="PUBLISHED" key="published">  
                <div className='display-flex flex-wrap'>
                        {publishedResults.map((item)=>(
                        <TestCard type={'published'} page={"tab"} test={item}/>))}
                        </div>
                        {publishedResults && publishedResults?.length === 0 ? <NodataCard/> : null}
                </TabPane>
                
            </Tabs>
    </div>
           <PageDataLoader visible={loaders?.tabPageLoader}/>
        {createTestModalBool && <CreateTestModal history={history} />}
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
        <TestPublishModal history={history}/>
    </div>
  )
}

const mapStateToProps = state =>{
  const {reloadToggler, openTab, filter, publishedResults, feedbackFromTeacher, sentForFeedback, 
    drafts, testsToPublish,selectedChapter, selectedClassroom, loaders, selectedSubject, 
    createTestModalBool, isCModalVisible, cModalType, userType}=state.practiseTest
  const {user} = state.session;
  return {reloadToggler, openTab, filter, publishedResults, feedbackFromTeacher, sentForFeedback, 
    drafts, testsToPublish, selectedChapter, selectedClassroom, loaders, selectedSubject, 
    user, createTestModalBool, isCModalVisible, cModalType, userType}
}

const mapDispatchToProps = (dispatch) => ({
 fetchTestsByStage: (type, callback) => dispatch(fetchTestsByStage(type, callback)), 
 setOpenTab: (tab) => dispatch(setOpenTab(tab)),
 toggleReloaderFlag: ()=>dispatch(toggleReloaderFlag()),
 updateFilter: (type, callback) => dispatch(updateFilter(type, callback)),
 updateListViewType: (data)=>dispatch(updateListViewType(data)),
 createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
 getTestChapterList: (search, subjectId, iscreate, stdid) => dispatch(getTestChapterList(search, subjectId, iscreate, stdid))
})


export default connect(mapStateToProps, mapDispatchToProps)(TestTabsPage)