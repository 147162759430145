import moment from "moment";
import { store } from "App";

export class TestTimer {
  timer = null;

  static startTimer = (
    ongoingTest,
    setDuration,
    updateCurrentDuration = null,
    handleAutoSubmit = null
  ) => {
    if (ongoingTest) {
      this.timer = setInterval(
        () =>
          this.decrementSeconds(
            ongoingTest,
            setDuration,
            updateCurrentDuration,
            handleAutoSubmit
          ),
        1000
      );
    }
  };

  static decrementSeconds = (
    ongoingTest,
    setDuration,
    updateCurrentDuration,
    handleAutoSubmit
  ) => {
      let durationInMinutes = ongoingTest.durationInMinutes;
      let createdTimeMoment = moment.utc(ongoingTest.attemptCreatedAt).local();
      let endTimeMoment = createdTimeMoment.add(0, 'seconds').add(durationInMinutes, 'minutes')
      let currentTimeMoment = moment();
      let durationSeconds = moment.duration(endTimeMoment.diff(currentTimeMoment)).asSeconds();
    if (Math.round(durationSeconds) <= 0) {
      this.clearTimer(setDuration);
      if (handleAutoSubmit) {
        handleAutoSubmit();
        clearInterval(this.timer);
      }
    }

    // durationSeconds = durationSeconds - 1
    setDuration(durationSeconds);
    const seconds = store.getState().studentPracticeTest.currentDuration;
    if (updateCurrentDuration) {
      updateCurrentDuration(seconds + 1);
    }
  };

  static clearTimer = (setDuration) => {
    setDuration(null);
    clearInterval(this.timer);
  };
}
