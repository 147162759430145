import { Row, Col } from 'antd'
import React, { useRef, useCallback, forwardRef } from 'react'
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import './broadcastStyles.css'
import ActionButtons from './localComponent/actionButtons';
import RightColumn from './localComponent/rightColumn.js';
import { setRightViewType, setMuteState, setCameraState, clearGroupChat, updateUserList, 
    updateUserCount, addMessageToGroup, setUserType, setRightView, addMessageToPrivate, 
    setStreamId, postChat, triggerHeadlessRecording, stopHeadlessRecording, clearPrivateChat } from 'actions/broadcast'
import Canvas from './localComponent/sketchCanvas.js'
import ConfirmationModal from '../broadcast/localComponent/confirmationModal';

import { handleJoinClass } from 'pages/schedule/handleJoinClass';
import { fake_ongoingConferenceData } from './fakedata';
import { EventRegister } from 'react-native-event-listeners';
import { BrigoshaMedia, Utils } from 'services';
import { endCall, toggleCallModal } from 'actions/liveClass';
import { toggleAudio, toggleSpeaker, toggleVideo } from 'actions/common';
import { store } from 'App';
import { VideoView } from 'components';


const brigoshaMedia =  new BrigoshaMedia();
const SubjectInfo = ({subjectname, standardNames})=>{
    return (
        <div>
            <div className="white rfbc1p5">
                {subjectname}
            </div>
            <div className="white opacity50 rfbc1">
                {standardNames&&standardNames.length>0
                ?
                standardNames?.map((standardName, index)=>(
                    <>
                        <> { index != 0 ? <>•</> : null} </>
                        <> { standardName} </>
                    </>
                ))
                :
                null
                }
            </div>
        </div>
    )
}



const ParticipantInfo = ({setRightViewType, userCount, setRightView, rightView, rightViewType})=>{
    return (
        <div style={{padding:10}} className="r-ac topRowCards p-l-15 p-r-15 cursor-pointer" onClick={()=>{
            if ((rightView==false)||(rightViewType=='')){
                setRightView(true);
                setRightViewType('participantsView');

            }
            else{
                if (rightViewType=='participantsView'){
                    setRightView(false); 
                }
                else{
                setRightViewType('participantsView');}
            }
        }}>
            <div className="r-c-c m-r-10"> 
                <img style={{height:'1.5vw', width:'1.5vw'}}  src={require('./assets/Group 11815.svg').default} alt="" />
            </div>
            <div className="white rfbc1p2"> {userCount} </div>
        </div>
    )
}
const TopRow = ({setRightViewType, userCount, setRightView, 
        rightView, rightViewType, broadcastSelectedClass, isRecording, onRecord, isPublishing
        })=>{
    const RecordInfo = ({timeleft})=>{
        return (
            <div className="r-ac topRowCards p-l-15 p-r-15 m-r-20 cursor-pointer recBtn" onClick={onRecord}>
                {isRecording? <div className="recRed bold-900 liveBlink m-r-10 rfbc1p2"> REC • </div>:null}
                <div className="white bold rfbc1p2"> {isRecording?"Stop": "Start"} Recording </div>
            </div>
        )
    }
    return(
        <>
            <Row className="r-jsb m-r-20 m-t-20 m-l-20">
                <Col>
                    <SubjectInfo subjectname={broadcastSelectedClass && broadcastSelectedClass.subject?.name} 
                                 standardNames={ (broadcastSelectedClass&&broadcastSelectedClass.standards?.length>0) && [...broadcastSelectedClass.standards?.map((standard, index)=>{return `${standard.std} ${standard.section}`})]}
                                    />
                </Col>
                <Col className="display-flex">
                    {
                        isPublishing?
                        <RecordInfo timeleft="01 : 48 : 25"/>
                        :null
                    }
                    <ParticipantInfo setRightViewType={setRightViewType} 
                                        userCount={userCount} 
                                        setRightView={setRightView} 
                                        rightView={rightView}
                                        rightViewType={rightViewType}
                                        />
                </Col>
            </Row>
        </>
    )
}


const ActionBoxRow = ({adapter, chat, sessionuser, isPublishing, onShareClick, chatClient, onMicClick, onCameraClick, streamId, onLeave, onRaiseHandClick, isTeacher}) =>{
    return(
        <div className="r-jc">
            <Row className="r-c-sa" style={{height:"15%", width:"75%"}}>
            {isTeacher
                ?
                <>
                <ActionButtons type={'partiButton'}  chat={chat} chatClient={chatClient} onMicClick={null}/>
                {/* <ActionButtons type={'privateButton'}  chat={chat} chatClient={chatClient} onMicClick={null}/> */}
                <ActionButtons type={'raiseHandButton'} chat={chat} chatClient={chatClient} onMicClick={null} onRaiseHandClick={onRaiseHandClick}/>
                <ActionButtons type={'groupButton'} chat={chat} chatClient={chatClient} onMicClick={null}/>
                <ActionButtons type={'leaveButton'} adapter={adapter} chat={chat} chatClient={chatClient} onMicClick={null} onLeave={onLeave}/>
                <ActionButtons type={'camOnButton'} chat={chat} isPublishing={isPublishing} chatClient={chatClient} onMicClick={null} onCameraClick={onCameraClick}/>
                <ActionButtons type={'speakOnButton'} chat={chat} isPublishing={isPublishing} chatClient={chatClient} onMicClick={onMicClick}/>
                {/* <ActionButtons type={'shareScreenButton'} chat={chat} isPublishing={isPublishing} onShareClick={onShareClick} chatClient={chatClient} onMicClick={null}/> */}
                </>
                :
                <>
                <ActionButtons type={'groupButton'} chat={chat} chatClient={chatClient} onMicClick={null}/>
                <ActionButtons type={'leaveButton'} chat={chat} chatClient={chatClient} onMicClick={null} streamId={streamId} onLeave={onLeave}/>
                <ActionButtons type={'raiseHandButton'} chat={chat} chatClient={chatClient} onMicClick={null} onRaiseHandClick={onRaiseHandClick}/>
                {/* <ActionButtons type={'privateButton'} chat={chat} chatClient={chatClient} onMicClick={null}/> */}
                </>
                }
            </Row>
        </div>
    )
}


let intervalId=null;
let t1 = null



function Conference({rightView, setRightViewType, setMuteState, 
                    setCameraState, clearGroupChat, isCamOn, 
                    userCount, updateUserCount, updateUserList, 
                    addMessageToGroup, sessionuser, setUserType, userType, setRightView, 
                    rightViewType,streamId, addMessageToPrivate, broadcastSelectedClass, 
                    history, broadcastStudentsList, postChat, setStreamId,
                    pollAttendance, triggerHeadlessRecording, stopHeadlessRecording, micIsMuted, 
                    clearPrivateChat, isTeacher, isTeacherPresent, pages, switchStudentCanvas,
                    isAudio,
                    isVideo,
                    isSpeaker,
                    orientation,
                    audioJack,
                    bluetoothHeadset,
                    toggleAudio,
                    toggleCallModal,
                    toggleSpeaker,
                    toggleVideo,
                    endCall,
                    onlineUsers,
                    offlineUsers,
                    remoteMedia,
                    activeClass
                }){
    const [inc, setInc] = useState(0);
    const [isRecordModalVisible, setRecordModalVisible] = useState(false);
    const [isRecording, setRecording] = useState(false);
    const [isPublishing, setPublishing] = useState(0);

    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef([]);
    const teacherId = useRef(null);
    const [remoteVideoLength, setRemoteVideoLength] = useState(0);
    const [requester, setRequester] = useState(null)
    const [toggler, setToggler] = useState(false);

    const onLocalStreamRcv = useCallback((data) => {
        setTimeout(()=>{
            if (localVideoRef.current){
                localVideoRef.current.srcObject = data.stream
            }
        }, 2000);
    }, []);
    console.log("teacherid",teacherId.current, remoteVideoRef.current)
    const onRemoteStreamRcv = (data)=> {
        console.log("datarcv", data)
        let temp = 0
        data.forEach((item, index)=>{
            if (item.video){
                temp += 1
            }
        })
        setRemoteVideoLength(temp)
        setTimeout(()=>{
                data.forEach((item, index)=>{
                    if (item.video && item.audio){
                        const mediaStream = new MediaStream([
                            item.video?.stream.getVideoTracks()[0],
                            item.audio?.stream.getAudioTracks()[0],
                          ]);
                        //   console.log("sdf", item.video?.stream.getVideoTracks()[0])

                        // item.video.stream.getVideoTracks()[0].onended = () => {
                        //     console.log("trackended")
                        // }
                        if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[index]){
                            remoteVideoRef.current[index].srcObject = mediaStream
                        }
                    }else if(item.video){
                        if (remoteVideoRef.current && remoteVideoRef.current.length && remoteVideoRef.current[index]){
                            remoteVideoRef.current[index].srcObject = item.video.stream
                        }
                    }
                })
                setTimeout(()=>{
                    setToggler((prev)=>!prev)
                }, 500)
            // 
            // if (isTeacher){
            //     if (data && data[0] && data[0].video){
            //         if (remoteVideoRef.current){
            //             remoteVideoRef.current.srcObject = data[0].video?.stream
            //         }
            //     }
            // }else{
            //     // if (data && data[0] && data[0].video){
            //     //     remoteVideoRef.current.srcObject = data[0].video?.stream
            //     // }
            //     data.forEach((item)=>{
            //         if (item.userId==teacherId.current && item.video){
            //             console.log("209 assigned", item.video, remoteVideoRef.current)
            //             if (remoteVideoRef.current){
            //                 remoteVideoRef.current.srcObject = item.video?.stream
            //             }
            //         }else{

            //         }
            //     })
            // }
        }, 1000);
    };
    console.log("230", remoteVideoRef.current, remoteVideoLength)
    const onRaiseHandRequest = user => {
        console.log("user", user)
        if (!isTeacher){
            return
        }
        const {handRaiseQArray} = store.getState().liveClass;
        if (handRaiseQArray.length) {
          return;
        }
        setRequester(user);
        setRecordModalVisible(true);
      }
    const setUpListeners = useCallback(() => {
         window.addEventListener("beforeunload", onUnmount);
         EventRegister.addEventListener('localMedia', onLocalStreamRcv);
         EventRegister.addEventListener('remoteMedia', onRemoteStreamRcv);
         EventRegister.addEventListener('raiseHandRequestRcv', onRaiseHandRequest);
    }, [])
    const removeListeners = useCallback(() => {
         window.removeEventListener("beforeunload", onUnmount)
         EventRegister.removeEventListener('localMedia', onLocalStreamRcv);
         EventRegister.removeEventListener('remoteMedia', onRemoteStreamRcv);
         EventRegister.removeEventListener('raiseHandRequestRcv', onRaiseHandRequest);
    }, []);
    const onMount = useCallback(() => {
        setUpListeners();
        // handleJoinClass(fake_ongoingConferenceData)

    }, []);
    const onUnmount = useCallback(() => {
        brigoshaMedia.closeCanvas();
        endCall();
        removeListeners();
    }, []);
    useEffect(() => {
          setTimeout(()=>{
            brigoshaMedia.openCanvas();
          }, 4000);
          onMount();
          return () => {
                onUnmount();
                }
    }, []);

    useEffect(()=>{
        onlineUsers.forEach((item)=>{
            if (item.isTeacher){
                teacherId.current = item.id
            }
        })
    }, [onlineUsers])

    const onShareClick = ()=>{

    }
    const onMicClick = () => {
        if (isAudio) {
            brigoshaMedia.pauseProducer('audioType');
          } else {
            brigoshaMedia.resumeProducer('audioType');
          }
          toggleAudio(!isAudio);
    }
    const onCameraClick = () => {
        if (isVideo) {
            brigoshaMedia.pauseProducer('videoType');
        } else {
            brigoshaMedia.resumeProducer('videoType');
        }
        toggleVideo(!isVideo);
    }
    const onRecord = () => {
        setRecordModalVisible(true);
    }
    const onCancel = () => {
        brigoshaMedia.rejectHandRequest(requester.id);
        setRecordModalVisible(false);
    }
    const onClose = () => {
        setRecordModalVisible(false);
    }
    const onConfirm = () => {
        brigoshaMedia.acceptHandRequest(requester.id);
        setRightView(true);
        setRightViewType('raiseHandList');
        setRecordModalVisible(false);
    }
    const onLeave = () => {
        history.goBack();
    }

    const onRaiseHandClick = () => {
        setRightView(!rightView);
        setRightViewType('raiseHandList');
    }



    let roomClient = null
    return (
        <div className="backgroundBlack display-flex full-height full-width" style={{overflow:"hidden", overflowX:"hidden", overflowY:"hidden"}}>
            <div className="full-height r-jsb-fc" 
                 style={{width:rightView==false?"100%":"70%", 
                        }}>
   
                <TopRow setRightViewType={setRightViewType} 
                        userCount={onlineUsers.length+offlineUsers.length}
                        setRightView={setRightView} 
                        rightView={rightView}
                        rightViewType={rightViewType}
                        broadcastSelectedClass={activeClass}
                        isRecording={isRecording}
                        isPublishing={isPublishing}
                        onRecord={onRecord}
                        />
                <VideoView key="localvideo" ref={localVideoRef} style={{top:-200, left:100}} visible={isVideo}/>
                {
                    Array(remoteVideoLength).fill(1).map((_, index)=>(
                        <VideoView key={`remotevideo:${index}`} ref={(el) => (remoteVideoRef.current[index] = el)} style={{top:100+(index+1)*100, left:100}} visible={true}/>
                    ))
                }
                {/* <VideoView key="remotevideo" ref={remoteVideoRef} style={{top:200, left:100}} visible={true}/> */}
                {
                    <div className="r-jc full-width m-t-15 m-b-15" style={{height:"95%"}}>
                            <div className="videoboxcard full-height relative" 
                                style={{width:rightView==false?"62%":"90%"}}>
                                {
                                    isTeacherPresent?
                                    <Canvas chat={null} streamId={streamId} showToolBar={isTeacher} switchStudentCanvas={switchStudentCanvas} pages={pages}/>
                                    :
                                    <div style={{height:"100%", width:"100%"}} className="r-c-c">
                                        <div style={{fontSize:30, textAlign:"center", width:300, fontFamily:"sans-serif"}}>Teacher will be joining the class shortly.</div>
                                    </div>
                                }
                            </div>
                    </div>
                }

                <ActionBoxRow adapter={roomClient} 
                              chat={null}
                              isTeacher={isTeacher}
                              onCameraClick={onCameraClick}
                              streamId={streamId}
                              sessionuser={sessionuser}
                              chatClient={null}
                              isPublishing={isPublishing}
                              onShareClick={onShareClick}
                              onMicClick={onMicClick}
                              onLeave={onLeave}
                              onRaiseHandClick={onRaiseHandClick}
                              />
            </div>
            {rightView==true
            ?
            <div className="p-20" style={{width:"30%"}}>
                <RightColumn inc={inc} 
                            setInc={setInc} 
                            chat={null}
                            chatClient={null}
                            />
            </div>
            :
            null}
            {isRecordModalVisible?  <ConfirmationModal visible={isRecordModalVisible} 
                                title={'Hand Raise Request'} onClose={onClose} onCancel={onCancel} onConfirm={onConfirm} confirmTitle={'Accept'} rejectTitle={'Reject'} content={`${requester.name} has a doubt!`}/>:null}
        </div>
    )
}

const mapStateToProps = state=>{
    const {rightView, isCamOn,streamId, userType, rightViewType, micIsMuted} = state.broadcast;
    const {user} = state.session;
    const {isTeacher, isTeacherPresent, pages, switchStudentCanvas, onlineUsers, offlineUsers } = state.liveClass;
    const {broadcastSelectedClass, broadcastStudentsList, activeClass} = state.schedule;
    const {
        isAudio,
        isVideo,
        isSpeaker,
        orientation,
        audioJack,
        bluetoothHeadset,
      } = state.common;
    const {
        remoteMedia
    } = state.brigoshaMedia;
    const sessionuser = user;
    return {
        rightView, isCamOn,
        sessionuser, userType, rightViewType,streamId,
        broadcastSelectedClass, broadcastStudentsList,
        micIsMuted,
        isTeacher,
        isTeacherPresent,
        pages,
        switchStudentCanvas,
        isAudio,
        isVideo,
        isSpeaker,
        orientation,
        audioJack,
        bluetoothHeadset,
        onlineUsers,
        offlineUsers,
        remoteMedia,
        activeClass
    }
}

const mapDispatchToProps = (dispatch)=>({
    setRightViewType: (newView) => dispatch(setRightViewType(newView)),
    setMuteState: (bool)=>dispatch(setMuteState(bool)),
    setCameraState: (bool)=>dispatch(setCameraState(bool)),
    clearGroupChat: ()=>dispatch(clearGroupChat()),
    updateUserList: (usersList, broadcastList) => dispatch(updateUserList(usersList, broadcastList)),
    updateUserCount: (usercount)=>dispatch(updateUserCount(usercount)),
    addMessageToGroup: (newMessage) => dispatch(addMessageToGroup(newMessage)),
    setUserType: ()=>dispatch(setUserType()),
    setRightView: (bool)=>dispatch(setRightView(bool)),
    addMessageToPrivate: (reciever, newMessage)=>dispatch(addMessageToPrivate(reciever, newMessage)),
    setStreamId: (newId)=>dispatch(setStreamId(newId)),
    postChat: (message, chatType)=>dispatch(postChat(message, chatType)),
    pollAttendance: () => dispatch(pollAttendance()),
    triggerHeadlessRecording: (successCallback) => dispatch(triggerHeadlessRecording(successCallback)),
    stopHeadlessRecording: (successCallback) => dispatch(stopHeadlessRecording(successCallback)),
    clearPrivateChat: ()=>dispatch(clearPrivateChat()),
    endCall: () => dispatch(endCall()),
    toggleAudio: value => dispatch(toggleAudio(value)),
    toggleVideo: value => dispatch(toggleVideo(value)),
    toggleSpeaker: value => dispatch(toggleSpeaker(value)),
    toggleCallModal: value => dispatch(toggleCallModal(value)),

})

export default connect(mapStateToProps, mapDispatchToProps)(Conference)