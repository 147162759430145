import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Dropdown } from 'antd';
import { Button } from 'components/Button';
import React from 'react';

const SearchAndFilters = ({ filterDropDownMenu, handleDropdownVisibleChange, dropdownVisible, 
    searchPlaceholder, onSearch, fromCrmOverview,fromCrmConversions,setIsScrolled, setIsFilterClicked, 
    fromMarketPlaceTable,setFilterModal, tab, setIsFilterModal, search }) => {
    const serachImage = require('../../Assets/crmOverview/Search.png').default
    console.log("autoSearch", search)
    return (

        <div className='display-flex' style={{ width: fromMarketPlaceTable ? '' :'100%', position:'relative'  }}>
            {search == null || search == '' ?<img style={{
                position: 'absolute',
                marginLeft: fromCrmOverview ? 20 : fromCrmConversions ? 10 : 10,
                zIndex:10,
                marginTop:9,
                width:15,
                height:15
                }} src={serachImage}/>
                :
                <></>
            }
            <AutoComplete className="searc-tag serach-card"
                style={{  marginLeft: fromCrmOverview ? 10 : 0 , 
                    width: fromCrmOverview ? '97%' : fromCrmConversions ? 170 : 350, 
                    marginRight: fromCrmOverview ? -25: fromCrmConversions ? -15 :0,
                }}
                 onSearch={onSearch} suffixIcon={<FilterFilled />} placeholder={<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft:20, marginTop:47 , }}>{searchPlaceholder}</div>}
                />
            <div className='sort-filter-btn'>
                <Dropdown
                    overlay={filterDropDownMenu}
                    visible={!(fromMarketPlaceTable && (tab == 3 || tab == 4) )? dropdownVisible:false}
                    onVisibleChange={handleDropdownVisibleChange}
                    trigger={['click']}
                    placement='bottomRight'
                    overlayStyle={{ width: "80px", marginLeft: 10 }}
                >
                    <div style={{ width: "100%", margin: fromMarketPlaceTable ? '3px -10px 0 0' : '5px -10px 0 0',position:'relative' }} onClick={()=>{
                        if(fromCrmOverview){
                            setIsScrolled(true)
                            setFilterModal(true)

                        } 

                        if(fromMarketPlaceTable && (tab == 3 || tab == 4)){
                            setIsFilterModal(true)
                        }
                    
                    }}>
                        <img src={require('../../Assets/common/misc/filter.svg').default} style={{ width: 30 ,marginTop:fromCrmConversions || fromCrmOverview ? -1:0 ,position:fromCrmOverview || fromCrmConversions ? "absolute": "",marginLeft:fromCrmOverview  ? 20  : fromCrmConversions ? 10 : 0,}} />
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

export default SearchAndFilters;