import {
    SET_SUBJECT,
    SET_CLASS,
    SET_TEACHER,
    SET_START_TIME,
    SET_END_TIME,
    SET_START_DATE,
    SET_END_DATE,
    SET_MODE,
    SET_WEEKDAYS,
    GET_SUB_LIST,
    GET_CLASS_LIST,
    GET_TEACHER_LIST,
    GET_SCHEDULE_LIST,
    GET_SUMMARY_DATA,
    GET_TIMETABLE_DATA,
    GET_STD_LIST_FILTER,
    SET_STD_FILTER,
    GET_TODAY_ACTIVITIES_DATA,
    GET_SUB_LIST_FILTER,
    SET_SCHEDULE_ID,
    SET_SCHEDULE_TIMETABLE,
    SET_MONTH_OR_WEEK,
    SET_START_DATE_SCHEDULE,
    SET_END_DATE_SCHEDULE,
    SET_TAB_TYPE,
    SET_STD_FILTER_TIMETABLE,
    UPDATE_ERROR,
    SET_BROADCAST_SELECTED_CLASSROOM,
    SET_ROOM_IDS,
    GET_ONLINE_USER,
    SET_ACCESS,
    SET_DEL_OR_CANCEL,
    UPDATE_BROADCAST_STUDENT_LIST,
    SET_CURRENT_DATE,
    GET_MODES,
    SET_TEACHER_FILTER,
    SET_CLASS_FILTER,
    SET_SUB_FILTER,
    SET_JOIN_LOADER,
    SET_STUDENT_DATA,
    SET_SUBJECT_DATA,
    SET_SELECTED_STUDENTS,
    SET_SELECT_ALL,
    SET_CHECKBOX_STATE,
    SET_SELECTED_ID,
    SET_PRESENT_STUDENTS,
    SET_CARD_DATA,
    SET_ALL_FALSE,
    SET_INITIAL_SUMMARY,
    SET_TEACHER_DATE,
    SET_PAGE_TOGGLE,
    FETCH_SUBSALERT_DATA,
    UPDATE_ACTIVE_CLASS,
    SET_CONFERENCE_JOIN_LOADER,
    STUDENT_STANDARD_FILTER,
    SET_RECORD_COUNT,
    SET_LIVE_STREAM_URL
} from '../actions/schedule';

const initialState = {
    subId: null,
    classId: [],
    teacherId: null,
    startTime: '',
    endTime: '',
    startDate: '',
    endDate: '',
    mode: '',
    weekdays: [],
    subList: [],
    classList: [],
    teacherList: [],
    scheduleList: {},
    summaryDataList: [],
    timetableDataList: [],
    stdListFilter: [],
    stdIdFilter: null,
    todayActivities: {},
    subListFilter: [],
    scheduleId: null,
    timetableItem: {},
    isMonthOrWeek: 'week',
    startDateSummary: '',
    endDateSummary: '',
    tabType: 'schedule',
    stdTimetable: null,
    errorMessage: {},
    broadcastSelectedClass: [],
    roomIds: [],
    onlineUsers: {},
    access: false,
    delOrCancel: '',
    broadcastStudentsList: [],
    currentDate: '',
    currentDate: '',
    getModes: {},
    teacherSearch: '',
    classFilter: [],
    subFilter: [],
    isJoinLoader: false,
    studentAttendanceData: [],
    subjectAttendanceData: {},
    selectedStudents: {},
    checkboxState: false,
    selectedId: null,
    presentStudents: [],
    cardData: {},
    initialSummary: {},
    teacherDate: {},
    pageToggle: '1',
    activeClass: {},
    conferenceJoinLoader: false,
    studentStandardFilters: [],
    recordCount: 0,
    liveStreamUrl: null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_SUBJECT:
            return {
                ...state,
                subId: action.subId
            }
        case GET_SUB_LIST:
            return {
                ...state,
                subList: [...action.subList]
            }
        case GET_CLASS_LIST:
            return {
                ...state,
                classList: [...action.classList]
            }
        case GET_TEACHER_LIST:
            return {
                ...state,
                teacherList: [...action.teacherList]
            }
        case SET_TEACHER:
            return {
                ...state,
                teacherId: action.teacherId
            }
        case SET_START_DATE:
            return {
                ...state,
                startDate: action.date
            }
        case SET_END_DATE:
            return {
                ...state,
                endDate: action.enddate
            }
        case SET_START_TIME:
            return {
                ...state,
                startTime: action.time
            }
        case SET_END_TIME:
            return {
                ...state,
                endTime: action.time
            }
        case SET_WEEKDAYS:
            return {
                ...state,
                weekdays: [...action.week]
            }
        case SET_MODE:
            return {
                ...state,
                mode: action.mode
            }
        case SET_CLASS:
            return {
                ...state,
                classId: [...action.classId]
            }
        case GET_SCHEDULE_LIST:
            return {
                ...state,
                scheduleList: action.schedule
            }
        case GET_SUMMARY_DATA:
            return {
                ...state,
                summaryDataList: [...action.summaryData]
            }
        case GET_TIMETABLE_DATA:
            return {
                ...state,
                timetableDataList: action.timetableData
            }
        case GET_STD_LIST_FILTER:
            return {
                ...state,
                stdListFilter: [...action.stdListFilter]
            }
        case SET_STD_FILTER:
            return {
                ...state,
                stdIdFilter: action.std
            }
        case GET_TODAY_ACTIVITIES_DATA:
            return {
                ...state,
                todayActivities: action.data
            }
        case GET_SUB_LIST_FILTER:
            return {
                ...state,
                subListFilter: [...action.sub]
            }
        case SET_SCHEDULE_ID:
            return {
                ...state,
                scheduleId: action.id
            }
        case SET_SCHEDULE_TIMETABLE:
            return {
                ...state,
                timetableItem: action.item
            }
        case SET_MONTH_OR_WEEK:
            return {
                ...state,
                isMonthOrWeek: action.isMonthOrWeek
            }
        case SET_START_DATE_SCHEDULE:
            return {
                ...state,
                startDateSummary: action.date
            }
        case SET_END_DATE_SCHEDULE:
            return {
                ...state,
                endDateSummary: action.date
            }
        case SET_TAB_TYPE:
            return {
                ...state,
                tabType: action.tab
            }
        case SET_STD_FILTER_TIMETABLE:
            return {
                ...state,
                stdTimetable: action.std
            }
        case UPDATE_ERROR:
            return {
                ...state,
                errorMessage: action.error
            }
        case SET_BROADCAST_SELECTED_CLASSROOM:
            return {
                ...state,
                broadcastSelectedClass: action.newClass
            }
        case SET_ROOM_IDS:
            return {
                ...state,
                roomIds: action.roomIds
            }
        case GET_ONLINE_USER:
            return {
                ...state,
                onlineUsers: action.users
            }
        case SET_ACCESS:
            return {
                ...state,
                access: action.bool
            }
        case SET_DEL_OR_CANCEL:
            return {
                ...state,
                delOrCancel: action.delOrCancel
            }

        case UPDATE_BROADCAST_STUDENT_LIST:
            let newList = [];
            for (let i = 0; i < action.data.length; i++) {
                newList.push({
                    ...action.data[i],
                    isStudent: true
                })
            }
            return {
                ...state,
                broadcastStudentsList: newList
            }

        case SET_CURRENT_DATE:
            return {
                ...state,
                currentDate: action.currentDate
            }
        case GET_MODES:
            return {
                ...state,
                getModes: action.modes
            }
        case SET_TEACHER_FILTER:
            return {
                ...state,
                teacherSearch: action.teacher
            }
        case SET_CLASS_FILTER:
            return {
                ...state,
                classFilter: [...action.classes]
            }
        case SET_SUB_FILTER:
            return {
                ...state,
                subFilter: [...action.sub]
            }

        case SET_JOIN_LOADER:
            return {
                ...state,
                isJoinLoader: action.bool
            }

        // schedule attendance

        case SET_STUDENT_DATA:
            return {
                ...state,
                studentAttendanceData: action.value
            }

        case SET_SUBJECT_DATA:
            return {
                ...state,
                subjectAttendanceData: action.value
            }


        case SET_SELECTED_STUDENTS:
            let tem = { ...state.selectedStudents }
            tem[action.id] = tem[action.id] === undefined ? true : tem[action.id] === true ? false : true
            // if (Object.values(tem).every((item) => (item === true))) {
            //     state.checkboxState = true;
            // } else {
            //     state.checkboxState = false;
            // }
            return {
                ...state,
                selectedStudents: tem
            }

        case SET_CHECKBOX_STATE:
            // let tbool = false;
            // if (!state.checkboxState) {
            //     tbool = true;
            // }
            return {
                ...state,
                checkboxState: action.bool
            }

        case SET_SELECT_ALL:
            let temp = {};
            state.studentAttendanceData.forEach(item => {
                if (state.checkboxState) {
                    temp[item.studentId] = true
                } else {
                    temp[item.studentId] = false
                }
            });
            return {
                ...state,
                selectedStudents: temp
            }

        case SET_ALL_FALSE:
            let temp1 = {};
            state.studentAttendanceData.forEach(item => {
                temp1[item.studentId] = item.status === 'Present'
            });
            return {
                ...state,
                selectedStudents: temp1
            }

        case SET_SELECTED_ID:
            return {
                ...state,
                selectedId: action.id
            }

        case SET_PRESENT_STUDENTS:
            const output = [];
            Object.keys(state.selectedStudents).forEach(key => {
                if (state.selectedStudents[key]) {
                    output.push(key);
                }
            })
            return {
                ...state,
                presentStudents: action.id === null ? [] : output
            }

        case SET_CARD_DATA:
            return {
                ...state,
                cardData: action.value
            }

        case SET_INITIAL_SUMMARY:
            return {
                ...state,
                initialSummary: action.value
            }

        case SET_TEACHER_DATE:

            let teacherDateObj = { ...state.teacherDate };
            if (action.key === 'momentDate') {
                teacherDateObj.moment = action.value;
            }
            else if (action.key === 'stringDate') {
                teacherDateObj.date = action.value;
            } else {
                null
            }

            return {
                ...state,
                teacherDate: { ...teacherDateObj }
            }

        case SET_PAGE_TOGGLE:
            return {
                ...state,
                pageToggle: action.value
            }

        case FETCH_SUBSALERT_DATA:
            return {
                ...state,
                subsAlertData: action.res
            }
        case UPDATE_ACTIVE_CLASS:
            return {
                ...state,
                activeClass: action.value,
            };
        case SET_CONFERENCE_JOIN_LOADER:
            return {
                ...state,
                conferenceJoinLoader: action.bool
            }

        case STUDENT_STANDARD_FILTER:
            return{
                ...state,
                studentStandardFilters: action.res
            }
        case SET_RECORD_COUNT:
            return{
                ...state,
                recordCount: action.val
            }
        case SET_LIVE_STREAM_URL:
            return {
                ...state,
                liveStreamUrl: action.val
            }
        default:
            return state

    }
}

export default reducer