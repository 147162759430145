import React, { useState } from "react";
import { Modal, Button, Radio } from "antd";
import { connect } from "react-redux";
import { getFeeOverView, updateSettings } from "actions/feeManagement";

function SettingsModal({
  isModalVisible,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,
  type,
  name,
  role,
  setIsModalVisible,
  updateSettings,
  feeMangementData,
  getFeeOverView,
}) {
  const [value, setValue] = React.useState(
    feeMangementData?.dashBoardDetails?.feepayer == "institute" ? 2 : 1
  );
  const [buttonLoader, setButtonLoader] = useState(false);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const [invoiceType, setInvoiceType] = useState(feeMangementData?.dashBoardDetails?.invoiceType)
  const onChangeValue = (e) => {
    setInvoiceType(e.target.value);
  };


  return (
    <div style={{ fontFamily: "roboto" }}>
      <Modal
        maskClosable={false}
        className="settingFeeModalB"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{ loading: buttonLoader }}
        onOk={() => {
          setButtonLoader(true);
          updateSettings(
            value,
            invoiceType,

            () => {
              setButtonLoader(false);
            },
            () => {
              getFeeOverView();
              setIsModalVisible(false);
            },

          );
        }}
        onCancel={() => setIsModalVisible(false)}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Settings
        </h2>
        <p
          style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px" }}
        >
          Processing fee
        </p>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={1}>Processing fee payable by student</Radio>
          <Radio style={{ marginLeft: "50px" }} value={2}>
            Processing fee payable by institute
          </Radio>
        </Radio.Group>

        <p
          style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px", marginTop: "12px" }}
        >
          Invoice Mode
        </p>
        <Radio.Group onChange={onChangeValue} value={invoiceType} >
          <Radio value={"automatic"}>Automatic</Radio>
          <Radio style={{ marginLeft: "197px" }} value={"manual"}>
            Manual
          </Radio>
        </Radio.Group>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F6F4FE",
            padding: "20px",
            borderRadius: "5px",
            marginTop: "40px",
            color: "#636363",
          }}
        >
          <div>
            <div>UPI</div>
            {/* <div>UPI and Rupay</div> */}
            <div>Debit and Credit Card</div>
          </div>
          <div>
            <div style={{ textAlign: "end" }}>Free</div>
            <div>1.8%</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}

// export default connect(mapStateToProps, mapDispatchToProps)(FeeManagement);
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  const { feeMangementData } = state.feeManagement;

  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    feeMangementData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (val, invoiceType, callback, successCallBack) =>
    dispatch(updateSettings(val, invoiceType, callback, successCallBack)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
