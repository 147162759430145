import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/charts';

const BarGraphCard = ({ barGraphData }) => {
  var data = []
  if (barGraphData.length != 0) {
    barGraphData.map((item, index) => (
      data.push({
        type: 'Live classes scheduled',
        short_date: item?.short_date ? item?.short_date : item?.month,
        count: parseInt(item.total)
      })
    ))
  }

  if (barGraphData.length != 0) {
    barGraphData.map((item, index) => (
      data.push({
        type: 'Completed',
        short_date: item?.short_date ? item?.short_date : item?.month,
        count: item.completed
      })
    ))
  }

  if (barGraphData.length != 0) {
    barGraphData.map((item, index) => (
      data.push({
        type: 'Cancelled',
        short_date: item?.short_date ? item?.short_date : item?.month,
        count: parseInt(item.cancelled)
      })
    ))
  }

  // console.log(data)
  var config = {
    data: data,
    isGroup: true,
    xField: 'short_date',
    yField: 'count',
    seriesField: 'type',
  };
  return <Column {...config} />;
};

export default BarGraphCard;