import React from 'react';
import {  DatePicker } from 'antd';
import moment from 'moment';


function CustomDatePicker({
    style,
    className,
    onDateChange,
    defaultDate,
    placeholder
}){
  
    // const Footer = ({}) => (
    // <div style={{color:"#1089FF"}} className="cursor-pointer text-center bold-700 full-width">
    //   RESET TO ALL
    // </div>
    // )


  return(
    <div style={{display:'flex'}}>
    <DatePicker placeholder={[placeholder]}
        style={style} 
        className={className}
        format={'DD-MM-YYYY'}
        onChange={onDateChange}
        defaultValue={defaultDate==undefined?null:moment(defaultDate, 'YYYY-MM-DD')}
        // dropdownClassName="datepickeradmintest"
        // renderExtraFooter={() => <Footer/>}
        />
    </div>
    )
  }
export default CustomDatePicker;
