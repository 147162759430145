import React from 'react';
import './styles.css'
import { Information, Title } from 'components/Typography';
import { LockOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';

function PromotionalOffersCard({ lessPadding, count, hasMarketingPrivilege, history, setOffersTypeIndex }) {
    return (
        <div className='recently-added-card r-c-fs' style={{ width: "100%", alignItems: "center", padding: lessPadding ? "15px 20px" : 20 }}>
            <img src={require("../../../../Assets/courseManagementV4/new-promo-offers-icon.svg").default} className='overview-single-card-img' style={{ marginLeft: -8 }} />
            <div className='m-l-10 r-c-sb' style={{ width: "100%" }}>
                <div className='m-l-8'>
                    <Title>Promotional offers</Title>
                    <div>
                        <Information>
                            {count}&nbsp; active promotional offers
                        </Information>
                    </div>
                </div>
                <LockOutlined style={{ fontSize: 24, color: "#AEAEAE" }} />
            </div>
        </div>
    )
}

export default PromotionalOffersCard