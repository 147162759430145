import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Menu, Modal, Radio } from 'antd';
import chevronDown from '../../../Assets/aulasMarket/ic-chevron-down.svg'
import { getOnlineTestSeriesCourseDetails, getSpecificSMData, getSpecificVideoCourseData, postAddFeeStudyMaterial } from 'actions/courseManagement';
import { connect } from 'react-redux';


const CourseEditFeeModal = ({ chooseModal, setChooseModal, specificCourseDataContainer, courseTypeName, postAddFeeStudyMaterial, getSpecificSMData, getSpecificVideoCourseData, getOnlineTestSeriesCourseDetails }) => {
    const [loader, setLoader] = useState(false)
    const [totalFee, setTotalFee] = useState(null)
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [isDiscountValue, setIsDiscountValue] = useState(null)
    const [isDropDownOpen, setIsDropDown] = useState(false)
    const [discountType, setDiscountType] = useState(null)
    const [minRegisterFee, setMinRegisterFee] = useState(null)



    const menuItems = [
        { value: "percent", label: "Percentage" },
        { value: "amount", label: "Amount" },
    ]

    const menu = (
        <Menu className='different-bgc-dropdown' style={{ width: "120px" }}>
            {menuItems?.map((item) => (
                <div style={{ fontSize: 15, cursor: "pointer", borderRadius: 4, marginTop: 5, padding: "2px 10px 2px 10px", backgroundColor: discountType === item.value ? '#F6F4FE' : '#ffff' }} onClick={() => { setDiscountType(item?.value), setIsDropDown(false), setIsDiscountValue(null),setDiscountedPrice(null) }}>{item?.label}</div>
            ))}
        </Menu>
    )
    console.log("specificCourseDataContainer?.discount", specificCourseDataContainer?.testSeriesDetails);
    useEffect(() => {
        if (courseTypeName == 'studyMaterials' || courseTypeName == 'videoResources') {
            setTotalFee(specificCourseDataContainer?.amount)
            setDiscountType(specificCourseDataContainer?.discountType);
            setIsDiscountValue(specificCourseDataContainer?.discount)
            if (specificCourseDataContainer?.discountType == "percent") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.amount - specificCourseDataContainer?.discount)
            }
        } else {
            setTotalFee(specificCourseDataContainer?.testSeriesDetails?.amount)
            setDiscountType(specificCourseDataContainer?.testSeriesDetails?.discountType);
            setIsDiscountValue(specificCourseDataContainer?.testSeriesDetails?.discount)
            if (specificCourseDataContainer?.testSeriesDetails?.discountType == "percentage") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.testSeriesDetails?.amount - (specificCourseDataContainer?.testSeriesDetails?.amount * specificCourseDataContainer?.testSeriesDetails?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.testSeriesDetails?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.testSeriesDetails?.amount - specificCourseDataContainer?.testSeriesDetails?.discount)
            }
        }

    }, [])
    const calcDiscount = (e) => {

        if (discountType == "percent" || discountType == 'percentage') {
            setDiscountedPrice(Math.round((totalFee - (totalFee * e.target.value) / 100) * 100) / 100)
        } else if (discountType == "amount") {
            setDiscountedPrice(totalFee - e?.target?.value)
        }




        setIsDiscountValue(e.target.value)
        // setCreateCourseObj("discount", parseInt(e.target.value))
    }
    const handleOK = () => {
        if (courseTypeName == 'studyMaterials') {
            setLoader(true)
            postAddFeeStudyMaterial(
                discountType, isDiscountValue, totalFee,
                () => { setLoader(false) },
                () => { setChooseModal(false), getSpecificSMData(() => { }) }
            )
        } else if (courseTypeName == 'videoResources') {
            setLoader(true)
            postAddFeeStudyMaterial(
                discountType, isDiscountValue, totalFee,
                () => { setLoader(false) },
                () => { setChooseModal(false), getSpecificVideoCourseData(() => { }) }
            )

        } else {
            setLoader(true)
            postAddFeeStudyMaterial(
                discountType, isDiscountValue, totalFee,
                () => { setLoader(false) },
                () => { setChooseModal(false), getOnlineTestSeriesCourseDetails(() => { }) }
            )
        }

    };

    const handleCancel = () => {
        setChooseModal(false);
    };


    return (
        <>

            <Modal
                className="modal-round-corner"
                open={chooseModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={false}
                bodyStyle={{ borderRadius: "10px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{ padding: 24 }}>
                    <div style={{ fontSize: "18px", fontWeight: 500, color: '#191919', display: "flex", justifyContent: "center" }}>Edit Fee</div>
                    <div style={{ fontSize: "15px", color: "#8C8C8C", fontWeight: 400, marginTop: 10 }}>Total fee</div>
                    <div style={{ display: "flex" }}> <div className='font-text' > ₹ </div><div><Input type="number" style={{ width: "430px", }} bordered={false} placeholder='Enter amount' value={totalFee} onChange={(e) => { setTotalFee(e.target.value); setIsDiscountValue(null), setDiscountedPrice(null) }} /></div></div>
                    <div className='save-border-line'></div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <div>
                            <div style={{ fontSize: "15px", color: "#8C8C8C", fontWeight: 400 }}>Discount</div>
                            <div style={{ display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    <Dropdown bordered={false} overlay={isDropDownOpen ? menu : <></>} trigger={['click']} open={isDropDownOpen} style={{ backgroundColor: "red" }}>
                                        <div style={{ display: "flex" }} onClick={(e) => { e.stopPropagation(); setIsDropDown(!isDropDownOpen) }}>
                                            <img style={{ width: 15, height: 15, marginTop: "5px", cursor: "pointer" }} src={chevronDown} />
                                            <div style={{ backgroundColor: "#FFFFFF", width: 85, padding: "2px 10px", color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 32, cursor: "pointer" }}>
                                                <div style={{ color: "#636363", fontWeight: 400 }}>{discountType == "percent" || discountType == 'percentage'  ? "Percentage" : "Amount"}</div>
                                            </div><div style={{ color: "#636363", fontWeight: 400, marginTop: "3px" }}>|</div>
                                        </div>
                                    </Dropdown>
                                    <Input type="number" bordered={null} placeholder={`${discountType == 'percent' ? 'Enter %' : 'Enter Amount'}`} value={isDiscountValue} onChange={(e) => { calcDiscount(e) }} />

                                </div>

                            </div>
                            <div className='save-border-line' style={{ marginTop: "5px", width: "200px" }}></div>


                        </div>
                        <div>
                            <div style={{ fontSize: "15px", color: "#8C8C8C", fontWeight: 400 }}>Fee after discount</div>
                            <div style={{ display: "flex" }}>
                                <div className='font-text' style={{}}> ₹ </div><div><Input readOnly bordered={null} placeholder='Enter amount' value={discountedPrice} /></div></div>
                            <div className='save-border-line' style={{ marginTop: "5px" }}></div>

                        </div>

                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: "30px",
                        }}
                    >
                        <div>
                            <Button
                                style={{
                                    borderRadius: "19px",
                                    width: "120px",
                                    height: "35px",
                                    border: "1px solid  #636363",
                                    backgroundColor: "#FFFFFF",
                                    color: "#594099",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "16px",
                                        color: "#636363",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => { handleCancel() }}
                                >
                                    CANCEL
                                </div>
                            </Button>
                        </div>
                        <div>
                            {console.log("totalFee",totalFee)}
                            <Button
                                disabled={totalFee == null || totalFee == ''}
                                loading={loader}
                                style={{
                                    borderRadius: "19px",
                                    width: "120px",
                                    height: "35px",
                                    border: "1px solid #594099 ",
                                    backgroundColor: "#594099",
                                    fontSize: "16px",
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                    opacity: totalFee == null || totalFee == '' ? 0.5 : 1
                                }}
                                onClick={() => { handleOK() }}

                            >
                                SAVE
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
};

const mapStateToProps = (state) => {
    const { specificCourseDataContainer, newCourseId, courseTypeName } = state.courseManagement;
    return { specificCourseDataContainer, newCourseId, courseTypeName };
};

const mapDispatchToProps = (dispatch) => ({
    postAddFeeStudyMaterial: (discountType, discount, totalFee, callback, successCallBack) => dispatch(postAddFeeStudyMaterial(discountType, discount, totalFee, callback, successCallBack)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
    getOnlineTestSeriesCourseDetails: (callBack) => dispatch(getOnlineTestSeriesCourseDetails(callBack)),





});

export default connect(mapStateToProps, mapDispatchToProps)(CourseEditFeeModal);