import React, { useEffect, useState } from 'react';
import { NoData, newCardStyle } from "./courseInformationPage";
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SquareSubIcon } from 'components/subIcon';
import { Divider, Skeleton, Typography } from 'antd';

const SingleSubjectCard = ({ item, subject, setCreateSubjectModalVisible, setEditSubjectVisible, setShowEditChapters, courseTypeName, specificCourseDataContainer, index, hasMarketingPrivilege }) => {
    return (
        <div className="box-sequare__subject" style={{ height: specificCourseDataContainer?.course?.subjects?.length <= 2 ? 230 : 280, marginTop: index == 0 || index == 1 ? 0 : 20,/*  marginRight: 10 */ }}>
            {courseTypeName == 'onlineTestSeries' ?
                <div className="r-ac">
                    <SquareSubIcon name={subject?.name} size={24} />
                    <div className="m-l-10">
                        <div className='text-xmd dark-black bold-600 text-overflow' style={{ width: 150, textTransform: 'uppercase' }}>{subject?.name}</div>
                        <div style={{ color: "#636363", fontSize: 12 }}>
                            {subject?.testSeriesChapter ? subject?.testSeriesChapter?.length : 0} Chapters
                        </div>
                    </div>
                </div> :
                <div className="r-ac">
                    <SquareSubIcon name={item?.name} size={24} icon={item?.icon?.iconPath ? item?.icon?.iconPath : ""} />
                    <div className="m-l-10">
                        <Typography.Text ellipsis={{ tooltip: true }} className='text-xmd dark-black bold-600' style={{ width: 240, textTransform: 'uppercase' }}>{item?.name}</Typography.Text>
                        <div style={{ color: "#636363", fontSize: 12 }}>
                            {item.courseChapter ? item.courseChapter?.length : 0} {item.courseChapter?.length > 1 ? "Chapters" : "Chapter"}
                        </div>
                    </div>
                </div>}
            <Divider style={{ margin: "10px 0px" }} />
            {courseTypeName == 'onlineTestSeries' ?
                (<>
                    <div className="box-sequare__subject-chapter-wrap">
                        <div>
                            {subject?.testSeriesChapter && subject?.testSeriesChapter?.length
                                ? subject.testSeriesChapter
                                    .filter((chap, i) => i < 6)
                                    .map((chapter, chapIndex) => (
                                        <div className="r-ac m-b-5">
                                            <div className=" m-r-15" style={{ color: "#636363", fontSize: 13 }}>
                                                Chapter {chapIndex + 1}
                                            </div>
                                            <div className="dark-black bold-600 text-overflow" style={{ fontSize: 13 }}>
                                                {chapter.name}
                                            </div>
                                        </div>
                                    ))
                                : null}
                        </div>
                        {subject?.testSeriesChapter?.length > 4 ? (
                            <div
                                className=" bold-600 text-center blue cursor-pointer"
                                onClick={() => {
                                    setEditSubjectVisible(true)
                                    setCreateSubjectModalVisible(true);
                                    setShowEditChapters(item)
                                }}
                                style={{ fontSize: 13 }}
                            >
                                VIEW ALL
                            </div>
                        ) : (
                            hasMarketingPrivilege ? <></> :
                                <div
                                    className="bold-600 text-center blue cursor-pointer"
                                    style={{ fontSize: 13 }}
                                    onClick={() => {
                                        setEditSubjectVisible(true)
                                        setCreateSubjectModalVisible(true)
                                        setShowEditChapters(item)
                                    }}
                                >
                                    <PlusCircleOutlined /> ADD CHAPTERS
                                </div>
                        )}
                    </div>
                </>) :
                (<>
                    <div className="box-sequare__subject-chapter-wrap" >
                        <div style={{ height: specificCourseDataContainer?.course?.subjects?.length <= 2 ? 100 : 150 }}>
                            {item?.courseChapter && item?.courseChapter?.length
                                ? item.courseChapter
                                    .filter((chap, i) => i < (specificCourseDataContainer?.course?.subjects?.length <= 2 ? 4 : 6))
                                    .map((chapter, chapIndex) => (
                                        <div className="display-flex m-b-5" style={{ width: "100%" }}>
                                            <div className=" m-r-15" style={{ color: "#636363", fontSize: 13 }}>
                                                Chapter {chapIndex + 1}
                                            </div>
                                            <Typography.Text ellipsis={{ tooltip: true }} className="dark-black bold-600 chapterName-text-width" style={{ fontSize: 13 }}>
                                                {chapter.name}
                                            </Typography.Text>
                                        </div>
                                    ))
                                : null}
                        </div>
                        {item.courseChapter?.length > 4 ? (
                            <div
                                className="text-center blue cursor-pointer m-t-5"
                                onClick={() => {
                                    setEditSubjectVisible(true)
                                    setCreateSubjectModalVisible(true);
                                    setShowEditChapters(item)
                                }}
                                style={{ fontSize: 12, fontWeight: "bold" }}
                            >
                                VIEW ALL
                            </div>
                        ) : (
                            hasMarketingPrivilege ? <></> :
                                <div
                                    className=" text-center blue cursor-pointer m-t-5"
                                    style={{ fontSize: 12, fontWeight: "bold" }}
                                    onClick={() => {
                                        setEditSubjectVisible(true)
                                        setCreateSubjectModalVisible(true)
                                        setShowEditChapters(item)
                                    }}
                                >
                                    <PlusCircleOutlined /> ADD CHAPTERS
                                </div>
                        )}
                    </div>
                </>)
            }

        </div>
    )
}

function LiveCourseCurriculum({ specificCourseDataContainer, isCoursePlanningEditClicked, courseTypeName, setIsCreateCurriculumModalVisible, setEditSubjectVisible, setCreateSubjectModalVisible, setShowEditChapters, pageLoader, showSkeletonOnCancel, isScrolled, scrollContainerRef, handleScroll, hasMarketingPrivilege }) {
    const history = useHistory();
    return (
        <div>
            <div className={isScrolled ? "scrolled-curriculum-height" : isCoursePlanningEditClicked ? "planning-clicked-curriculum-height" : "curriculum-card-height"} style={{ ...newCardStyle, marginTop: 20,/*  height: isScrolled ? 515 : isCoursePlanningEditClicked ? 264 : 325 */ }}>
                {pageLoader && showSkeletonOnCancel ? <Skeleton active /> :
                    <>
                        <div className='r-c-sb' style={{ width: "100%" }}>
                            <div className='r-ac' style={{ width: "50%" }}>
                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Curriculum</div>
                                <div style={{ color: "#636363", marginLeft: 20 }}>
                                    {specificCourseDataContainer?.course?.totalSubjectCount > 0 && (
                                        <span>{specificCourseDataContainer?.course?.totalSubjectCount} {specificCourseDataContainer?.course?.totalSubjectCount > 1 ? "Subjects" : "Subject"}  </span>
                                    )}
                                    {specificCourseDataContainer?.course?.totalChapterCount > 0 && (
                                        <span> • {specificCourseDataContainer?.course?.totalChapterCount} {specificCourseDataContainer?.course?.totalChapterCount > 1 ? "Chapters" : "Chapter"}</span>
                                    )}
                                </div>
                            </div>
                            {specificCourseDataContainer?.course?.subjects?.length ?
                                (hasMarketingPrivilege ?
                                    <></>
                                    :
                                    <div className='r-ac' style={{ marginRight: 4 }}>
                                        <div
                                            style={{ color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, height: 28, fontWeight: "bold", cursor: "pointer" }}
                                            onClick={() => {
                                                history.push({ pathname: "/classroom/resources", state: { key: "fromCourse" } })
                                            }}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</div>
                                        <div
                                            style={{ color: "#1089FF", height: 28, fontWeight: "bold", cursor: "pointer" }}
                                            onClick={() => { setIsCreateCurriculumModalVisible(true); setEditSubjectVisible(false) }}>
                                            <PlusCircleOutlined style={{ color: "#1089FF", marginRight: 5 }} />CREATE</div>
                                    </div>
                                )

                                :
                                <></>
                            }
                        </div>
                        {specificCourseDataContainer?.course?.subjects?.length != 0 ?
                            <div className={`r-c-sb flex-wrap scroll-bar-universal ${isCoursePlanningEditClicked ? "curriculum-scroll-planning-clicked" : isScrolled ? "curriculum-height-when-scrolled" : "curriculum-scroll-height"}`} id="curriculumScrollableDiv" ref={scrollContainerRef} style={{/*  height: isCoursePlanningEditClicked ? 180 : isScrolled ? 430 : 240, */ marginTop: specificCourseDataContainer?.course?.subjects?.length <= 2 ? 10 : 15 }} onScroll={specificCourseDataContainer?.course?.subjects?.length <= 2 ? "" : handleScroll}>
                                {specificCourseDataContainer?.course?.subjects?.map((item, index) => (
                                    <SingleSubjectCard
                                        item={item}
                                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                        setEditSubjectVisible={setEditSubjectVisible}
                                        setShowEditChapters={setShowEditChapters}
                                        specificCourseDataContainer={specificCourseDataContainer}
                                        index={index}
                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                    />
                                )
                                )}
                            </div>
                            :
                            <div className='r-c-c' style={{ flexDirection: "column", marginTop: isCoursePlanningEditClicked ? -25 : "" }}>
                                <img className='no-data-img-width' src={NoData} alt='' />
                                <div className='m-t-5' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No subject(s) added</div>

                                {!hasMarketingPrivilege && (
                                    <>
                                        {courseTypeName !== "videoResources" && (
                                            <center className='hide-text' style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>
                                                If you like you can browse from our resources or create manually
                                            </center>
                                        )}

                                        <div className='r-c-se m-t-10 m-b-10' style={{ width: "50%" }}>
                                             
                                            <Button
                                                icon={<PlusCircleOutlined style={{ color: "#1089FF", marginRight: 5 }} />}
                                                style={{ border: "1px solid #1089FF", color: "#1089FF" }}
                                                onClick={() => { setIsCreateCurriculumModalVisible(true); setEditSubjectVisible(false); }}
                                            >
                                                {"CREATE"}
                                            </Button>
                                            <Button
                                                style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF" }}
                                                onClick={() => {
                                                    if (courseTypeName !== "studyMaterials") {
                                                        history.push({ pathname: "/classroom/resources", state: { key: "fromCourse" } });
                                                    }
                                                }}
                                            >
                                                <SearchOutlined style={{ marginRight: 5 }} />
                                                BROWSE
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                    </>}
            </div>
        </div>
    )
}

export default LiveCourseCurriculum