import { DatePicker, Dropdown, Menu, Modal, Select } from 'antd'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import { CaretLeftOutlined, CaretRightOutlined, DownOutlined, LeftCircleFilled, LeftCircleOutlined, RightCircleFilled, RightCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import DemoDualAxes from 'components/dualAxisGraph';
import SwitchComponent from 'components/switchComponent';
import GroupedBarPlot from 'components/groupedBarPlot';

function CrmModalGraph({ isGraphFullScreen, setISGraphFullScreen, currentPie, setCurrentPie, dualAxesGraph, formCrmOverview, courseData, stdColors, studentData, graphData, courseType, isChecked, setIsMonth, setIsChecked, setIsYear, isMonth, isYear, setCourseType, visibleRange, setVisibleRange }) {
    const [isGraphFilterClicked, setIsGraphFilterClicked] = useState(false)



    const graphFilterArray = [
        { value: "liveCourse", label: "Live course" },
        { value: "videoResource", label: "Video course" },
        { value: "studyMaterial", label: "Study material" },
        { value: "testSeries", label: "Online test series" },
    ]
    const graphMenu = (
        <Menu className='different-bgc-dropdown' style={{ borderRadius: "16px 16px 8px 8px", padding: "10px", width: "fit-content", boxShadow: "-4px 4px 16px #00000029" }}>
            {graphFilterArray?.map((item) => (
                <div style={{ fontSize: 15, cursor: "pointer", backgroundColor: courseType == item?.value ? "#D6C8F5" : "#FFFFFF", color: courseType == item?.value ? "#191919" : "#636363", fontWeight: courseType == item?.value ? 600 : "normal", borderRadius: 4, marginTop: 5, padding: "2px 10px 2px 10px" }} onClick={() => { setCourseType(item?.value), setIsGraphFilterClicked(false) }}>{item?.label}</div>
            ))}
        </Menu>
    )
    const handleCancel = () => {
        setISGraphFullScreen(false);
    };





    const handleNext = () => {
        setCurrentPie((prevPie) => (prevPie % 2) + 1);
    };

    const handlePrev = () => {
        setCurrentPie((prevPie) => (prevPie === 1 ? 2 : prevPie - 1));
    };
 
    return (
        <div>
            <Modal width={800} closable={false} open={isGraphFullScreen} onOk={handleCancel} onCancel={handleCancel} footer={null} bodyStyle={{ padding: 40 }} className="modal-round-corner">
                <div>
                    {currentPie == 1 ? null :
                        <div><LeftCircleFilled style={{ fontSize: "25px", color: "#fff",/*  marginTop: `${currentPie == 2 ? '200px' : "-40px"}`, */position: 'absolute', marginLeft: "-80px", marginTop: "200px", fontSize:30 }} onClick={() => { handlePrev() }} /></div>}</div>
                <div>
                    {currentPie == 2 ? null :
                        <div><RightCircleFilled style={{ color: "#fff", fontSize: "25px", position: 'absolute', marginLeft: 770, marginTop: "190px" , fontSize:30}} onClick={() => { handleNext() }} /></div>}
                </div>
                {currentPie != 1 ?
                    <center style={{ fontSize: 20, fontWeight: 650 }}> Leads and Conversions</center>
                    :
                    <center style={{ fontSize: 20, fontWeight: 650 }}> Impressions and Engagements</center>}
                <div >
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        {currentPie != 1 ? <div style={{ marginLeft: -10, marginTop: -3 }}></div> :

                            <div style={{ marginLeft: -10, marginTop: -3 }}>
                                <Dropdown overlay={isGraphFilterClicked ? graphMenu : <></>} trigger={['click']} open={isGraphFilterClicked} >
                                    <div style={{ display: "flex", marginLeft: 10 }} onClick={(e) => { e.stopPropagation() }}>
                                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>{courseType == "liveCourse" ? "Live course" : courseType == "videoResource" ? "Video course" : courseType == "studyMaterial" ? "Study material" : courseType == "testSeries" ? "Online test series" : null}</div>
                                        <DownOutlined style={{ color: "#AEAEAE", fontSize: 14, marginLeft: 10, marginTop: 10 }} onClick={() => {
                                            setIsGraphFilterClicked(!isGraphFilterClicked)
                                            // setDropdownVisible1(false);
                                            // setDropdownVisible(false);

                                        }} />
                                    </div>
                                </Dropdown>
                                <div style={{ fontSize: 14, fontWeight: 500, color: '#191919', marginLeft: 10 }}>Impressions and Engagements</div>
                            </div>}
                        <div style={{ display: "flex", gap: 30 }}>
                            {!isChecked ?
                                <DatePicker
                                    allowClear={false}
                                    picker="month"
                                    format={'MMM'}
                                    value={moment(isMonth, 'MMM')}
                                    style={{ borderRadius: "50px", width: "80px", height: "25px" }}
                                    onChange={(date, dateString) => {
                                        setIsMonth(moment(date).format('MMM'))
                                    }} /> :
                                <DatePicker
                                    allowClear={false}

                                    picker="year"
                                    value={moment(isYear)}
                                    style={{ borderRadius: "50px", width: "80px", height: "25px" }}
                                    onChange={(date, dateString) => {
                                        setIsYear(moment(date).format('YYYY'))

                                    }}
                                />}
                            <SwitchComponent
                                setIsChecked={setIsChecked}
                                isChecked={isChecked} />
                            {/* <FullscreenOutlined style={{ display: "flex", justifyContent: "right", paddingRight: "10px", fontSize: "20px", marginTop: "3px" }} onClick={() => { setBarChartModal(true) }} /> */}

                        </div>
                    </div>
                </div>


                <div>
                    <div style={{ width: "100%", alignItems: "center" }}>
                        {currentPie === 1 && (<>
                            <div style={{ marginTop: "20px" }}>
                                <div style={{ marginTop: "20px" }}>
                                    <div style={{ width: '100%',height:280 }}>
                                        <GroupedBarPlot data={graphData?.length != 0 ? graphData : []} setVisibleRange={setVisibleRange} visibleRange={visibleRange} value1={'check'} formCrmOverview={formCrmOverview} />

                                    </div>
                                </div>
                                <div style={{ marginTop: 25 }}>
                                    <div style={{ display: "flex", gap: 10 }}>
                                        <div style={{ height: "13px", width: "13px", backgroundColor: "#594099", borderRadius: 2 }}></div>
                                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}> Impressions</div>
                                    </div>
                                    <div style={{ display: "flex", gap: 10, marginTop: "3px" }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#DFDEF5", borderRadius: 2 }}></div>
                                        <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}> Engagements</div>
                                    </div>
                                </div>

                            </div>
                        </>)}
                        {currentPie === 2 &&
                            (<>
                                <div style={{ marginTop: "20px" }}>
                                    <div style={{ marginTop: "20px" }}>
                                        <div style={{ width: '100%', height: "280px" ,marginTop:30}}>
                                            <DemoDualAxes data={dualAxesGraph?.length != 0 ? dualAxesGraph : []} fromFullScreen = {true} />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                    <div style={{ display: "flex", gap: 10 }}>
                                        <div style={{ height: "13px", width: "13px", backgroundColor: "#594099", borderRadius: 2 }}></div>
                                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}> Leads</div>
                                    </div>
                                    <div style={{ display: "flex", gap: 10, marginTop: "3px" }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#DFDEF5", borderRadius: 2 }}></div>
                                        <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}> Conversions</div>
                                    </div>
                                </div>

                                </div>
                            </>)}

                    </div>


                </div>

                <div className='r-c-sa m-t-30'><Button onClick={() => { setISGraphFullScreen(false) }} type="secondary">CLOSE</Button></div>
            </Modal>
        </div>
    )
}

export default CrmModalGraph