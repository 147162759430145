import { Col, Modal, Radio, Row, Tooltip, Button } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Title, SubHeading } from "components/Typography";
import { rateCard } from "./generateLinkModal";
import { saveSetting } from "actions/fees";

function SettingsModal({
  visible,
  onCancel,
  saveSetting,
  transactionFeePayer,
}) {
  const [transactionPaidBy, settransactionPaidBy] = useState("student");
  const [buttonLoader, toggleButtonLoader] = useState(false);

  useEffect(() => {
    settransactionPaidBy(transactionFeePayer);
  }, []);

  const handleSaveClick = () => {
    toggleButtonLoader(true);
    saveSetting(
      transactionPaidBy,
      () => toggleButtonLoader(false),
      successCallback
    );
  };

  const successCallback = () => {
    toggleButtonLoader(false);
    onCancel();
  };
  return (
    <Modal
      visible={visible}
      footer={false}
      closeIcon={<CloseCircleOutlined style={{ fontSize: 22 }} />}
      className="generateLinkModal"
      centered
      onCancel={() => {
        settransactionPaidBy(transactionFeePayer);
        onCancel();
      }}
    >
      <div>
        <Row>
          <Col>
            <Title bold color="#594099">
              Fee Settings
            </Title>
            {/* <Heading color="#8C8C8C">
                        Generate any payment link for students
                        </Heading> */}
          </Col>
        </Row>
        <Row className="m-t-30">
          <Col flex="auto">
            <SubHeading bold>
              Processing Fee
              <Tooltip
                className="m-l-5"
                trigger="click"
                placement="right"
                title={rateCard}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </SubHeading>
          </Col>
        </Row>
        <Row className="m-t-10">
          <Radio
            className="text-xs dark-grey"
            onClick={() => settransactionPaidBy("student")}
            checked={transactionPaidBy === "student"}
          >
            Processing fee payable by student
          </Radio>
        </Row>
        <Row className="m-t-10">
          <Radio
            className="text-xs dark-grey"
            onClick={() => settransactionPaidBy("institute")}
            checked={transactionPaidBy === "institute"}
          >
            Processing fee payable by institute
          </Radio>
        </Row>
        <Row justify="center" className="m-t-30">
          <Button
            type="primary"
            shape="round"
            style={{ width: 100 }}
            onClick={() => handleSaveClick()}
            loading={buttonLoader}
            disabled={transactionPaidBy === transactionFeePayer}
          >
            Save
          </Button>
        </Row>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { transactionFeePayer } = state.fees;
  return {
    transactionFeePayer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveSetting: (payer, callback, successCallback) =>
    dispatch(saveSetting(payer, callback, successCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
