import React, { useEffect } from "react";
import Layout from "antd/lib/layout";
import "antd/lib/layout/style/css";
// import { Header, SideMenu } from "../layout";
import { LivePopUp } from "../components";
import { BrigoshaMedia } from "services";
import { connect } from "react-redux";
import { WindowsFilled } from "@ant-design/icons";
import SideMenu from "layout/sideMenu";
import { useHistory } from "react-router";
import { savePageCoordinates, showMenu } from "actions/layout";
import NetworkStatus from "pages/networkStatus";
import ResponseModal from "layout/responseModal";
const { Content } = Layout;

// class MainLayout extends React.Component {
//     disableAudio() {
//       const { brigoshaMedia, isMyHandRaise } = this.props;
//       if (!brigoshaMedia || isMyHandRaise) {
//         return;
//       }
//       brigoshaMedia.pauseProducer(BrigoshaMedia.MEDIA_TYPE.audio);
//     }
//     render() {
//       const { children, showLivePopup, inConference, showSideMenu } = this.props;
//       return (
//         <Layout
//           style={{ height: "100vh", backgroundColor: "#fff" }}
//           onMouseUp={() => this.disableAudio()}
//         >
//           {/* <Header /> */}
//           <Layout
//             style={{
//               backgroundColor: "#fff",
//               display: "flex",
//               flexDirection: "row",
//               overflow: "hidden",
//             }}
//           >
//            {showSideMenu !== false ? <SideMenu /> : null}
//             <Content id="mainBody" style={{ padding: (window.location.pathname=='/broadcast'||window.location.pathname=='/recordings'||window.location.pathname=='/test')?0:20, flex: 1, overflow: window.location.pathname=='/broadcast'?'hidden':'scroll' }}>
//               {children}
//               <LivePopUp visible={inConference && showLivePopup} />
//             </Content>
//           </Layout>
//         </Layout>
//       );
//     }
//   }

const MainLayout = ({ children, showLivePopup, inConference, showSideMenu, brigoshaMedia, isMyHandRaise, xCoordinate, yCoordinate, dispatch, showMenu }) => {
  const history = useHistory();
  useEffect(()=>{
    if(window.location.pathname === '/broadcast' || window.location.pathname === '/recordings' || window.location.pathname === '/liveclass' || window.location.pathname === "/"|| window.location.pathname === '/home' || window.location.pathname === '/redirect-to' || window.location.pathname === '/location-details' || window.location.pathname === "/all-popular-courses" ||  window.location.pathname === '/all-courses'  || window.location.pathname === "/resource-details" || window.location.pathname === '/popular-courses' || window.location.pathname === '/search' || window.location.pathname === "/course-page" || window.location.pathname === "/shopping-cart" || window.location.pathname === "/saved-later" || window.location.pathname === "/test-series" || window.location.pathname ==="/study-materials" || window.location.pathname ==="/course-list" || window.location.pathname ==="/my-account/course"|| window.location.pathname ==="/checkout"|| window.location.pathname ==="/locations"|| window.location.pathname ==="/my-account"|| window.location.pathname ==="/course"|| window.location.pathname ==="/my-courses"|| window.location.pathname ==="/cart"|| window.location.pathname ==="/user-checkout"|| window.location.pathname ==="/saved-for-later"|| window.location.pathname ==="/resource-details/reviews"|| window.location.pathname ==="/course-content"|| window.location.pathname ==="/resource-details/pdf-view"){
      showMenu(false)
    } else{
      showMenu(true)
    }
  },[])
  useEffect(() => {
    if (history.action === "PUSH") {
      // dispatch(savePageCoordinates(0,0))
      document.getElementById("mainBody").scrollTo({
        top: 0,
        left: 0
      });
    } else {
      document.getElementById("mainBody").scrollTo({
        top: yCoordinate,
        left: xCoordinate
      });
    }
  }, [children, xCoordinate, yCoordinate]);

  const disableAudio = () => {
    if (!brigoshaMedia || isMyHandRaise) {
      return;
    }
    brigoshaMedia.pauseProducer(BrigoshaMedia.MEDIA_TYPE.audio);
  }
  return (
    <Layout
      style={{ height: "100vh", backgroundColor: "#FAFAFA" }}
      onMouseUp={() => disableAudio()}
    >
      {/* <Header /> */}
      <Layout
        style={{
          backgroundColor: "#FAFAFA",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
                {showSideMenu ? <SideMenu/> : null}
        {/* {(showSideMenu !== false && window.location.pathname != '/broadcast' && window.location.pathname != '/liveclass' && 
        window.location.pathname != '/home' && window.location.pathname != "/resource-details" && window.location.pathname != '/all-popular-courses' && window.location.pathname != '/search' && window.location.pathname != '/all-courses' && window.location.pathname != '/location-details' && window.location.pathname != "/mobile-search-page" &&
        window.location.pathname != "/course-page" && window.location.pathname != "/shopping-cart" && window.location.pathname != "/saved-later" && 
        window.location.pathname != "/test-series" && window.location.pathname != "/study-materials" && window.location.pathname !="/course-list" && 
        window.location.pathname !="/checkout"&& window.location.pathname !="/locations" && window.location.pathname != "/"&& window.location.pathname != "/cart"&& window.location.pathname != "/user-checkout"&& window.location.pathname != "/saved-for-later"&& window.location.pathname != "/resource-details/reviews"&& window.location.pathname != "/course-content"&& window.location.pathname != "/resource-details/pdf-view") ? <SideMenu /> : null} */}
<Content id="mainBody" style={{ padding: (window.location.pathname === '/broadcast' || window.location.pathname === '/recordings' || window.location.pathname === '/test' || window.location.pathname === '/liveclass' || window.location.pathname === "/" || window.location.pathname === '/home' || window.location.pathname === '/redirect-to' || window.location.pathname === '/location-details' || window.location.pathname === "/all-popular-courses" || window.location.pathname === '/all-courses' || window.location.pathname === "/resource-details" || window.location.pathname === '/popular-courses' || window.location.pathname === '/search' || window.location.pathname === "/course-page" || window.location.pathname === "/shopping-cart" || window.location.pathname === "/saved-later" || window.location.pathname === "/test-series" || window.location.pathname === "/study-materials" || window.location.pathname === "/course-list" || window.location.pathname === "/my-account/course" || window.location.pathname === "/checkout" || window.location.pathname === "/locations" || window.location.pathname === "/my-account" || window.location.pathname === "/course" || window.location.pathname === "/my-courses" || window.location.pathname === "/cart" || window.location.pathname === "/user-checkout" || window.location.pathname === "/saved-for-later" || window.location.pathname === "/resource-details/reviews" || window.location.pathname === "/course-content" || window.location.pathname === "/resource-details/pdf-view") ? 0 : 20, flex: 1, overflow: window.location.pathname == '/broadcast' || window.location.pathname == '/liveclass' ? 'hidden' : 'scroll' }}>
          {children}
          <LivePopUp visible={inConference && showLivePopup} />
        </Content>
      </Layout>
      <ResponseModal />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  const { brigoshaMedia } = state.brigoshaMedia;
  const { showLivePopup, isMyHandRaise } = state.liveClass;
  const { inConference } = state.today;
  const { showSideMenu, xCoordinate, yCoordinate } = state.layout;
  return {
    isMyHandRaise,
    brigoshaMedia,
    showLivePopup,
    inConference,
    showSideMenu,
    xCoordinate,
    yCoordinate
  };
};

const mapDispatchToProps = (dispatch) => ({
  showMenu: (bool) => dispatch(showMenu(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);