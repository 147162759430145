import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { setInvoiceFile } from "actions/feeManagement";
import { PageHeader } from "antd";
import React from "react";
import { BsDownload } from "react-icons/bs";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Api } from "services";
import axios from "axios";
import fileDownload from "js-file-download";


const ViewInvoice = ({ invoiceFile, setInvoiceFile }) => {
  const history = useHistory();
  const handleDownload = () => {
    axios
      .get(`${Api._s3_url}${invoiceFile}`, {
        responseType: "blob",
      })
      .then((res) => {
        if (res.data) {
          // console.log('res',res.data)
          fileDownload(res.data, "Transaction-Invoice.pdf");
        }
        //
      })
      .catch((e) => {
        console.log(e);
      });
  };
  let timeout;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >

{invoiceFile !== null ? 
        <div
          style={{
            width: "96%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: "-20px" }}>
            <PageHeader
              title={
                <div
                  className="text-md"
                  style={{
                    fontWeight: "bold",
                    height: "45px",
                    marginTop: "10px",
                    //   marginLeft: "-100px",
                  }}
                ></div>
              }
              onBack={() => {
                history.goBack();
                // setInvoiceFile(null);
              }}
              backIcon={<ArrowLeftOutlined />}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => handleDownload()}
          >
            <DownloadOutlined style={{ fontSize: 22, marginRight: "5px" }} />
            {/* <BsDownload /> */}
            Download
          </div>
        </div>: null}
        <div style={{ width: "100%", height: "100%" }}>
          {invoiceFile !== null ? 
          <iframe
            width="100%"
            height="100%"
            src={`${Api._s3_url}${invoiceFile}#toolbar=0`}
            frameborder="0"
          ></iframe> : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { invoiceFile } = state.feeManagement;
  return {
    invoiceFile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInvoiceFile: (file) => dispatch(setInvoiceFile(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);
