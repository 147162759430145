import React from 'react';
import courseOnlineIcon from "../../Assets/courseManagementV4/courseOnlineIcon.svg";
import courseOfflineIcon from "../../Assets/courseManagementV4/courseOfflineIcon.svg";
import courseHybridIcon from "../../Assets/courseManagementV4/courseHybridIcon.svg";

function OverviewList({ item }) {
    const icon = {
        online: courseOnlineIcon,
        offline: courseOfflineIcon,
        hybrid: courseHybridIcon
    }

    const text = {
        online: "online",
        offline: "offline",
        hybrid: "hybrid"
    }

    return (
        <div className='r-ac' style={{ marginTop: 11 }}>
            <div><img src={icon[item?.mode]} style={{ height: 25, width: 25 }} /></div>
            <div className='m-l-10'>
                <div style={{ color: "#636363", fontSize: '0.875rem', fontWeight: 500 }}>{item?.studentCount} students</div>
                <div style={{ color: "#737373", fontSize: '0.875rem', }}>in {item?.count} {text[item?.mode]} class</div>
            </div>
        </div>
    )
}

export default OverviewList