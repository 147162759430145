import React, { useState, useEffect } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Row, Col, Divider, Dropdown, Tooltip, Progress, Typography } from 'antd'
import { Color } from 'services'
import { useHistory } from 'react-router-dom';
import { MdMoreVert } from 'react-icons/md';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    setCModalType, setIsCModalVisible, setSelectedClassroom,
    setSelectedClassroomId, updateSelectedTest, updateSelectedTestId, createTestModal,
    storeGenerateQuestionsObj, showGenerateQuesListView, updateListViewType, fetchTestSubmitDetails,
    updateSubmittedTestsId,
    universalLoader,
    updateSelectedCardType,
    changeTestStatusModal,
    getTestClassList,
    storeCreateTestModalData,
    getEachQuesData,
    updateFilter, updateSubmittedTestsDetails, setSelectedSectionId, setStartTimeTest
} from 'actions/testAdmin';
import PageDataLoader from 'components/pageDataLoader';
import CustomMenu from './customMenu';
import { savePageCoordinates } from 'actions/layout';
const greenIcon = require('Assets/test/Group_12505.svg').default;
const blueIcon = require('Assets/test/Group_12506.svg').default;
const redIcon = require('Assets/test/Group_12507.svg').default;

const chapterTestIcon = require('Assets/test/ic-chapter-test.svg').default;
const fullTestIcon = require('Assets/test/ic-full-test.svg').default;
const partTestIcon = require('Assets/test/ic-part-test.svg').default;

const numtoMonth = {
    1: "JAN", 2: "FEB", 3: "MAR", 4: "APR", 5: "MAY", 6: "JUN",
    7: "JUL", 8: "AUG", 9: "SEP", 10: "OCT", 11: "NOV", 12: "DEC"
}

const monthtoNum = {
    "JAN": "01", "FEB": "02", "MAR": "03", "APR": "04", "MAY": "05", "JUN": "06",
    "JUL": "07", "AUG": "08", "SEP": "09", "OCT": "10", "NOV": "11", "DEC": "12"
}


const IconRender = ({ icon, count, title }) => {
    return (
        <div className="r-c-c">
            <Tooltip placement="topRight" trigger={'hover'} title={title} overlayInnerStyle={{ borderRadius: 5 }}>
                <img style={{ width: 15, height: 15, marginRight: 5 }} src={icon} alt="" />
            </Tooltip>

            <div style={{ fontSize: 10, color: "#636363", fontWeight: "500" }}>
                {count}
            </div>
        </div>
    )
}

const CountRow = ({ fullCount, partCount, chapterCount }) => {
    return (
        <div className="p-l-5 p-r-5 r-c-sb">
            <IconRender icon={greenIcon} count={chapterCount} title="Chapter test" />
            <IconRender icon={blueIcon} count={partCount} title="Part test" />
            <IconRender icon={redIcon} count={fullCount} title="Full test" />
        </div>
    )
}

function TestCard({ type,
    test,
    index,
    ispublished,
    sectionid,
    classdata,
    class_std_name,
    setSelectedClassroom,
    setSelectedClassroomId,
    handleResultCardClick,
    isPublishButtonVisible,
    fromTodayActivities,
    updateSelectedTest,
    updateSelectedTestId,
    setCModalType,
    setIsCModalVisible,
    isCModalVisible,
    cModalType,
    createTestModal, storeGenerateQuestionsObj, showGenerateQuesListView,
    createTestModalBool, generateQuesListViewBool, updateListViewType,
    updateSubmittedTestsId,
    fetchTestSubmitDetails, sendForFeedback, userType, dash = false, user, universalLoader, editDetailsLoader, cloneLoader,
    updateSelectedCardType,
    changeTestStatusModal,
    getTestClassList,
    storeCreateTestModalData,
    getEachQuesData, updateFilter, updateSubmittedTestsDetails,
    savePageCoordinates, setSelectedSectionId ,setStartTimeTest}) {

    const history = useHistory();
    const [pageLoader, togglePageLoader] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false)
    const handleSubjectClick = (selectedClass, selectedClassId, classname, section) => {
        setSelectedClassroom({ name: selectedClass, id: selectedClassId, classname, section });
        history.push('test-std-to-subjects-screen');
        console.log(document.getElementById('mainBody').scrollLeft, document.getElementById('mainBody').scrollTop)
        savePageCoordinates(document.getElementById('mainBody').scrollLeft, document.getElementById('mainBody').scrollTop)
    }
    const handleButtonClick = (id, type, test, e) => {
        // setSelectedId(id);
        e.stopPropagation();
        setShowDropdown(false)
        updateSelectedTestId(id);
        if (type == "Publish") {
            setCModalType("Publish");
            setIsCModalVisible(true);
        }
        else if (type == "PublishResult") {
            setCModalType("PublishResult");
            setIsCModalVisible(true);
        }
        else if (type == "Review") {
            updateSelectedTest(test);
            changeTestStatusModal("SendReview");
        }
        else if (type == "Delete") {
            setCModalType("Delete");
            setIsCModalVisible(true);
        }
        else if (type == "Cancel") {
            setCModalType("Cancel");
            setIsCModalVisible(true);
        }
        else if (type == "Unpublish") {
            setCModalType("Unpublish");
            setIsCModalVisible(true);
        }
        else if (type == "MarkReady") {
            changeTestStatusModal("MarkReady");
            // setCModalType("")
            // setIsCModalVisible(true)
        }
        else if (type == "Edit") {
            if (generateQuesListViewBool) {
                return
            }
            universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', true);
            updateListViewType('edit');
            updateSelectedTest(test);
            showGenerateQuesListView(() => {
                universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', false);
                createTestModal("test-edit", user, 'dropdown');
                history.push("/edit-test")
            }, "tabview");
        }
        else if (type == "Clone") {
            if (generateQuesListViewBool) {
                return
            }
            universalLoader('CLONE_LOADER', 'cloneLoader', true);
            updateListViewType('clone');
            updateSelectedTest(test);
            showGenerateQuesListView((classIds, chapters) => {
                const chapterList = [];
                for (let subject of chapters) {
                    for (let chapter of subject.chapters) {
                        chapterList.push(chapter.id);
                    }
                }
                getTestClassList(classIds, chapterList);
                universalLoader('CLONE_LOADER', 'cloneLoader', false);
                createTestModal("test-clone", user, 'dropdown');
                history.push("/edit-test");
                storeCreateTestModalData('tIndex', '');
            }, "tabview");
        }
    }

    if (type === "published") {
        // const resultTime = moment(test.resultTime, 'hh:mm:ss').format('hh:mm A')
        return (
            <div className="adminTestCard m-t-10 customCardShadow shadow-box hover-shadow testPublishedCard cursor-pointer relative" key={index} style={{ height: 150, width: "350px", position: "relative", padding: 10 }}
                onClick={() => {
                    updateFilter('studentSearch', '');
                    updateSubmittedTestsId(test.id);
                    updateSubmittedTestsDetails(test);
                    handleResultCardClick(test, type)
                }}>
                <div className="full-width r-c-fs" style={{ height: "60%" }}>
                    <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{ borderRadius: 5 }} title={test.syllabus}>
                        <img alt="" src={test.syllabus === "Full Test" ? fullTestIcon : test.syllabus === "Chapter Test" ? chapterTestIcon : test.syllabus === "Part Test" ? partTestIcon : fullTestIcon} style={{ height: 60, width: 60 }} className="m-r-10" />
                    </Tooltip>
                    <div style={{ width: "80%" }}>
                        <div className="testcard-testtitle text-overflow-ellipsis" style={{ width: "80%" }}>
                            {test.title}
                        </div>
                        <Typography.Text ellipsis={{tooltip:true}} className="text-overflow-ellipsis" style={{ fontSize: 11, color: Color.darkGrey, width: "80%" }}>
                            {test.standardNames} | {test.subjectNames?.split(',')?.join(" • ")}
                        </Typography.Text>
                        <div style={{ fontSize: 11, color: "#AEAEAE" }}>
                            {test.testType} &nbsp;•&nbsp; {test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round(test?.duration / 60)} mins &nbsp;•&nbsp; {test?.totalPoints} marks
                        </div>
                    </div>
                </div>
                <div className="r-jsb m-t-10">
                    {ispublished ?
                        <>
                            <div style={{ fontSize: 11, color: "#636363" }} >
                                <b>RESULT PUBLISHED ON</b> <br></br>{test?.resultDate}
                            </div>
                            <Button type="link" size="small" className="viewsolutionsbtn cursor-pointer" style={{ padding: 0, fontSize: 10 }} onClick={() => { }}>
                                VIEW RESULT <ArrowRightOutlined />
                            </Button>
                        </>
                        :
                        <>

                            <div style={{ fontSize: 10, color: "#636363" }}>
                                {
                                    test.testType == "Objective"
                                        ?
                                        <>RESULT TO BE PUBLISHED ON<br></br>{test?.resultDate?.replaceAll(',', '/')} {test.resultTime}</>
                                        :
                                        <><b> RESULT WILL BE PUBLISHED</b><br></br><b>AFTER EVALUATION </b></>

                                }
                            </div>

                            {userType == "admin"
                                ?
                                <Button className="purpleBtnWrap purpleButtonHoverEffect radius-100 text-xs" style={{ position: 'absolute', right: 20 }} onClick={(e) => handleButtonClick(test.id ? test.id : test.testId ? test.testId : null, "PublishResult", test, e)}>
                                    PUBLISH RESULT
                                </Button>
                                :
                                null}
                        </>
                    }
                </div>
                {
                    userType === 'admin'
                        ?
                        <Dropdown
                            visible={showDropdown}
                            onVisibleChange={visible => setShowDropdown(visible)}
                            overlay={<CustomMenu type={ispublished ? "publishedResult" : "notPublishedResult"} test={test} handleButtonClick={handleButtonClick}
                                isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                                generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                            placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                            <MdMoreVert className='text-lg a2grey cursor-pointer' style={{ position: "absolute", top: "7px", height: 25, right: "5px", zIndex: 5 }} />
                        </Dropdown>
                        : null
                }

            </div>

        )
    }
    else if (type === "published_in_tab") {
        let [date, month, year] = test.testDate?.split(/[\s,]+/)
        month = monthtoNum[month?.toUpperCase()?.slice(0, 3)];
        let resultDate = date + '/' + month + '/' + year;
        let [p_date, p_month, p_year] = test.publishDate ? test.publishDate?.split(',') : ['', '', '']
        month = monthtoNum[month?.toUpperCase()]
        let publishedDate = p_date + '/' + p_month?.slice(0, 3) + '/' + p_year
        return (
            <div className="adminTestCard m-t-10 customCardShadow shadow-box hover-shadow cursor-pointer publishedInTabCard" key={index}
                style={{ height: 130, width: "340px", minWidth: "340px" }}
                onClick={() => {
                    updateFilter('studentSearch', '');
                    updateSubmittedTestsDetails(test);
                    updateSubmittedTestsId(test.id);
                    handleResultCardClick(test, type)
                }}>
                <div>
                    <div className="testcard-testtitle">
                        {test.title}
                    </div>
                    <div style={{ fontSize: 10, color: "#AEAEAE" }}>
                        {test?.testType} &nbsp;•&nbsp; {test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round(test.duration / 60)} mins &nbsp;•&nbsp; {test?.totalPoints} marks
                    </div>
                </div>
                <div className="r-jsb m-t-30">
                    {ispublished ?
                        <>
                            <div className="simgrey text-xsm" >
                                <b>RESULT PUBLISHED ON</b> <br></br>{publishedDate == '//' ? '' : publishedDate} {test.publishedTime && <>: {test.publishedTime}</>}
                            </div>
                            <Button type="link" size="small" className="viewsolutionsbtn cursor-pointer" style={{ padding: 0, fontSize: 10 }}>
                                VIEW RESULT <ArrowRightOutlined />
                            </Button>
                        </>
                        :
                        <>
                            <div className="simgrey text-xsm">
                                {
                                    test.testType == "Objective"
                                        ?
                                        <><b>RESULT DUE ON</b> <br></br>{resultDate} {test.resultTime && <>: {test.resultTime}</>}</>
                                        :
                                        <><b> RESULT WILL BE PUBLISHED</b><br></br><b>AFTER EVALUATION </b></>
                                }
                            </div>
                            {
                                userType == "admin"
                                    ?
                                    <Button className="purpleBtnWrap radius-100 text-xs" onClick={(e) => handleButtonClick(test.id ? test.id : test.testId ? test.testId : null, "PublishResult", test, e)}>
                                        PUBLISH RESULT
                                    </Button>
                                    :
                                    null
                            }
                        </>
                    }
                </div>
            </div>

        )
    }
    else if (type === "adminlive") {
        let testDate = test.testDate?.replaceAll(',', '/')
        return (
            <div className="liveTestCard customCardShadow m-t-10 r-c-sb cursor-pointer" style={fromTodayActivities ? { backgroundColor: "#fff", width: "350px" } : { backgroundColor: "#fff" }}>
                <div className="cursor-pointer" style={{ maxWidth: "100%" }} onClick={() => {
                    updateListViewType('no-edit');
                    updateSelectedTest(test);
                    updateSelectedCardType('adminlive');
                    togglePageLoader(true);
                    showGenerateQuesListView((response) => {
                        console.log('resss', response)
                        if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                            getEachQuesData(response.subjects[0].questions[0].id);
                        }
                        if ((user?.role?.includes("Data Operator") || user?.role?.includes("Teacher"))) {
                            const currentTime = moment();
                              const startTime = moment(test?.startTime, 'hh:mm a');
                              const timeDifference = startTime.diff(currentTime, 'minutes')
                              if (timeDifference <= 15) {
                                history.push('/admin-test-view');
                              } else {
                                history.push('/admin-test');
                              }
                    }
                    else {
                        history.push('/admin-test');
                      }

                    

                        setStartTimeTest(test?.startTime)
                        
                        
                        togglePageLoader(false);
                    }, 'tabviewlive');
                }}>
                    <div className="display-flex">
                        {
                            fromTodayActivities ?
                                <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{ borderRadius: 5 }} title={test.syllabus}>
                                    <img alt="" src={test.syllabus === "Full Test" ? fullTestIcon : test.syllabus === "Chapter Test" ? chapterTestIcon : test.syllabus === "Part Test" ? partTestIcon : fullTestIcon} style={{ height: 60, width: 60 }} className="m-r-10" />
                                </Tooltip>
                                : null
                        }
                        <div style={{ width: "80%" }}>
                            <div className="r-jsb" style={{ maxWidth: "100%" }}>
                                <div className="testcard-testtitle text-overflow-ellipsis" style={{ maxWidth: "100%" }}>
                                    {test.title}
                                </div>
                            </div>
                            {fromTodayActivities
                                ?
                                <div
                                    style={{ fontSize: 11, color: Color.darkGrey, overflow: 'hidden', width: "80%", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                >
                                    {test.standardNames} | {test.subjectNames?.split(',')?.join(' • ')}
                                </div>
                                :
                                null
                            }
                            <div style={{ fontSize: 10, color: "#AEAEAE" }}>
                                {test.testType} &nbsp;•&nbsp;{test.totalQuestions}Qs &nbsp;•&nbsp;{Math.round(test.duration / 60)}mins &nbsp;•&nbsp;{test?.totalPoints}marks
                            </div>
                        </div>
                    </div>
                    {fromTodayActivities
                        ?
                        null
                        :
                        <>
                            <div className="m-t-20 bold-700" style={{ fontSize: 10, color: "#636363", overflow: 'hidden' }}>
                                LIVE TEST ON
                            </div>
                            <div style={{ fontSize: 11, color: "#636363", overflow: 'hidden' }}>
                                {testDate}  &nbsp;• &nbsp; {test.startTime}
                            </div>
                        </>
                    }

                </div>
                <div className="full-height" style={{ width: "15%", position: "relative", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <Dropdown
                        visible={showDropdown}
                        onVisibleChange={visible => setShowDropdown(visible)}
                        overlay={<CustomMenu type="adminlive" test={test} handleButtonClick={handleButtonClick}
                            isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                            generateQuesListViewBool={generateQuesListViewBool} userType={userType} editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                        placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                        <MdMoreVert className='text-lg a2grey cursor-pointer' style={{ position: "absolute", top: "5px", height: 25 }} />
                    </Dropdown>
                    <img src={require('Assets/live-dot.gif').default} width={50} height={22} alt="" style={{ position: "absolute", bottom: "5px" }} />
                </div>
                <PageDataLoader visible={pageLoader} />
            </div>
        )
    }
    else if (type === "submitted") {
        let [date, month, year] = fromTodayActivities ? test.testDate?.split(',') : test.testDate?.split(',')
        updateSelectedCardType('');
        month = month != undefined ? monthtoNum[month.toUpperCase()] : undefined
        let testDate = date + '/' + month + '/' + year;
        return (
            <div className="liveTestCard customCardShadow m-t-10 cursor-pointer" onClick={() => {
                updateFilter('studentSearch', '');
                togglePageLoader(true)
                updateSubmittedTestsId(test?.id)
                fetchTestSubmitDetails((bool) => {
                    togglePageLoader(bool)
                    history.push('test-submit-detail-screen')
                });
            }} style={{ height: 190, backgroundColor: "#fff" }}>
                <Row>
                    <Col span={24}>
                        <div className="testcard-testtitle">{test.title}
                        </div>
                        {fromTodayActivities
                            ?
                            <div
                                style={{ fontSize: 11, color: Color.darkGrey, overflow: 'hidden', width: "95%", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {test.standardNames} | {test.subjectNames?.split(',')?.join(' • ')}
                            </div>
                            :
                            null
                        }
                        <div style={{ fontSize: 10, color: "#AEAEAE" }}>
                            {test.testType} &nbsp;•&nbsp; {test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round((test.test?.duration != null ? test.duration : test.totalDuration != null ? test.totalDuration : test.duration != null ? test.duration : 0) / 60)} mins &nbsp;•&nbsp; {test.totalPoints} marks
                        </div>
                        <div className="">
                            <Progress
                                percent={Math.floor(test.submissionCount * 100 / test.studentCount)}
                                status="active"
                                size="small"
                                showInfo={false}
                                strokeColor={{
                                    "0%": "#594099",
                                    "100%": "#594099",
                                }}
                            />
                            <div className="r-jsb" style={{ fontSize: 10 }}>
                                <div style={{ color: "#AEAEAE" }}>Submission</div>
                                <div style={{ color: "#AEAEAE" }}>{test.submissionCount}/{test.studentCount}</div>
                            </div>
                            {test.testType == "Objective" ? <></> :
                            <>
                            <Progress
                                percent={test.gradedCount * 100 / test.submissionCount}
                                status="active"
                                size="small"
                                showInfo={false}
                                strokeColor={{
                                    "0%": "#28DF99",
                                    "100%": "#28DF99",
                                }}
                            />
                            <div className="r-jsb" style={{ fontSize: 10 }}>
                                <div style={{ color: "#AEAEAE" }}>Evaluation</div>
                                <div style={{ color: "#AEAEAE" }}>{test.gradedCount}/{test.submissionCount}</div>
                            </div>
                            </>
                            }
                        </div>
                        <div className="m-t-10 bold-700 simgrey text-xsm" style={{ overflow: 'hidden' }}>
                            TEST ENDED ON
                        </div>
                        <div className="simgrey fs-11" style={{ overflow: 'hidden' }}>
                            {testDate}  &nbsp;• &nbsp; {test?.endTime}
                        </div>
                    </Col>
                </Row>
                <PageDataLoader visible={pageLoader} />
            </div>

        )
    } else if (type == "submitted_today") {
        return (
            <div className="liveTestCard customCardShadow m-t-10 cursor-pointer" onClick={() => {
                updateFilter('studentSearch', '');
                togglePageLoader(true)
                updateSubmittedTestsId(test?.id)
                fetchTestSubmitDetails((bool) => {
                    togglePageLoader(bool)
                    history.push('test-submit-detail-screen')
                });
            }} style={{ backgroundColor: "#fff", width: 350, height: 170 }}>
                <Row>
                    <Col span={6} className="r-c-c">
                        <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{ borderRadius: 5 }} title={test.syllabus}>
                            <img alt="" src={test.syllabus === "Full Test" ? fullTestIcon : test.syllabus === "Chapter Test" ? chapterTestIcon : test.syllabus === "Part Test" ? partTestIcon : fullTestIcon} style={{ height: 60, width: 60 }} className="m-r-10" />
                        </Tooltip>
                    </Col>
                    <Col span={18}>
                        <div className="testcard-testtitle">{test.title}
                        </div>
                        {fromTodayActivities
                            ?
                            <div
                                style={{ fontSize: 11, color: Color.darkGrey, overflow: 'hidden', width: "95%", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {test.standardNames} | {test.subjectNames?.split(',')?.join(' • ')}
                            </div>
                            :
                            null
                        }
                        <div style={{ fontSize: 10, color: "#AEAEAE" }}>
                            {test.testType} &nbsp;•&nbsp; {test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round((test.test?.duration != null ? test.duration : test.totalDuration != null ? test.totalDuration : test.duration != null ? test.duration : 0) / 60)} mins &nbsp;•&nbsp; {test.totalPoints} marks
                        </div>

                    </Col>
                </Row>
                <div className="">
                    <Progress
                        percent={Math.floor(test.submissionCount * 100 / test.studentCount)}
                        status="active"
                        size="small"
                        showInfo={false}
                        strokeColor={{
                            "0%": "#594099",
                            "100%": "#594099",
                        }}
                    />
                    <div className="r-jsb" style={{ fontSize: 10 }}>
                        <div style={{ color: "#AEAEAE" }}>Submission</div>
                        <div style={{ color: "#AEAEAE" }}>{test.submissionCount}/{test.studentCount}</div>
                    </div>
                    {test.testType == "Objective" ? <></> :
                    <>
                    <Progress
                        percent={test.gradedCount * 100 / test.submissionCount}
                        status="active"
                        size="small"
                        showInfo={false}
                        strokeColor={{
                            "0%": "#28DF99",
                            "100%": "#28DF99",
                        }}
                    />
                    <div className="r-jsb" style={{ fontSize: 10 }}>
                        <div style={{ color: "#AEAEAE" }}>Evaluation</div>
                        <div style={{ color: "#AEAEAE" }}>{test.gradedCount}/{test.submissionCount}</div>
                    </div>
                    </>
                    }
                </div>
                <PageDataLoader visible={pageLoader} />
            </div>

        )
    }
    else if ((type === "scheduled") || (type === "cancelled")) {
        let [date, month] = test.testDate?.split(',')
        return (
            <div className="adminTestCard m-t-10 customCardShadow cursor-pointer shadow-box hover-shadow" key={index} style={{ height: 100, maxWidth: 350, padding: 0 }}
                onClick={
                    type == "cancelled"
                        ?
                        () => {
                            updateListViewType('edit');
                            updateSelectedTest(test);
                            updateSelectedCardType('');
                            togglePageLoader(true);
                            showGenerateQuesListView((response) => {
                                console.log('resss', response)
                                if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                                    getEachQuesData(response.subjects[0].questions[0].id);
                                }
                                history.push('admin-test');
                                togglePageLoader(false);
                            }, 'tabviewlive');
                        }
                        :
                        type == "scheduled"
                            ?
                            () => {
                                updateListViewType('edit');
                                updateSelectedTest(test);
                                updateSelectedCardType('');
                                togglePageLoader(true);
                                showGenerateQuesListView((response) => {
                                    console.log('resss', response)
                                    if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                                        getEachQuesData(response.subjects[0].questions[0].id);
                                    }
                                    if ( (user?.role?.includes("Data Operator") || user?.role?.includes("Teacher"))) {
                                        const currentTime = moment();
                                          const startTime = moment(test?.startTime, 'hh:mm a');
                                          const timeDifference = startTime.diff(currentTime, 'minutes')
                                          if (timeDifference <= 15) {
                                            history.push('/admin-test-view');
                                          } else {
                                            history.push('/admin-test');
                                          }
                                }
                                else {
                                    history.push('/admin-test');
                                  }
                                    togglePageLoader(false);
                                }, 'tabviewlive');
                            }
                            :
                            () => { }}>
                <Row className="full-height full-width">
                    <Col span={6} className="r-c-c">
                        <div className="dateCard r-c-c-c text-xs bold-700" style={{ color: "#594099", height: 80 }}>
                            <div style={{ textTransform: 'uppercase' }}>{month} {date}</div>
                            <div>{test.startTime}</div>
                        </div>
                    </Col>
                    <Col span={15} className="ca-r-fs-c-c">
                        <div className="testcard-testtitle">{test.title}</div>
                        <div
                            style={{ fontSize: 11, color: Color.darkGrey, overflow: 'hidden', width: "95%", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                            {test.standardNames} | {test.subjectNames?.split(',')?.join(' • ')}
                        </div>
                        <div className="fs-11 lowgrey">
                            {test.testType} &nbsp;•&nbsp; {test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round(test.duration / 60)} mins &nbsp;•&nbsp; {test.totalPoints} marks
                        </div>
                    </Col>
                    <Col span={3}>
                        {
                            type === 'scheduled'
                                ?
                                <div className="full-height full-width relative" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <Dropdown
                                        visible={showDropdown}
                                        onVisibleChange={visible => setShowDropdown(visible)}
                                        overlay={<CustomMenu type="upcoming" test={test} handleButtonClick={handleButtonClick}
                                            isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                                            generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                            editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                                        placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                                        <MdMoreVert className='text-lg a2grey cursor-pointer' style={{ position: "absolute", top: "5px", height: 25 }} />
                                    </Dropdown>
                                </div>
                                : null
                        }
                    </Col>
                </Row>
                <PageDataLoader visible={pageLoader} />
            </div>
        )
    }
    else if (type == "dash_scheduled") {
        let testDate = null;
        let startTime = null;
        let createdDate = null;
        if (test.testDate != null) {
            testDate = moment.utc(test.testDate).local().format('MMM DD').toString();
        }
        if (test.createdAt != null) {
            createdDate = moment.utc(test.createdAt).local().format("DD/MM/YYYY • hh:mm A");
        }
        let duration = Math.floor(test.duration / 60);

        const handleReadyCardClick = (e) => {
            e.stopPropagation();
            updateListViewType('edit');
            updateSelectedCardType('Ready');
            updateSelectedTest(test);
            togglePageLoader(true);
            showGenerateQuesListView((response) => {
                console.log('resss', response)
                if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                    getEachQuesData(response.subjects[0].questions[0].id);
                }
                history.push('admin-test');
                togglePageLoader(false);
            }, 'tabviewlive');
        }
        return (
            <div className="adminTestCard m-t-10 customCardShadow cursor-pointer shadow-box hover-shadow testsToPublishCard relative"
                key={index} style={{ height: 130, maxWidth: 350, padding: 0 }}
                onClick={handleReadyCardClick}
            >
                <Row className="full-height full-width">
                    <Col span={6} className="r-c-c">
                        <div className="dateCard r-c-c-c text-xs bold-700" style={{ color: "#594099", height: 80 }}>
                            <div style={{ textTransform: 'uppercase' }}> {testDate != null ? testDate : null}</div>
                            <div>{test.startTime}</div>
                        </div>
                    </Col>
                    <Col span={15} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }} onClick={() => { handleResultCardClick(test, type) }}>
                        <div>
                            <div className="testcard-testtitle">{test.title}</div>
                            <Typography.Text ellipsis={{tooltip:true}} style={{ fontSize: 11, color: Color.darkGrey, width:"200px" }}>
                                {test.std} - {test.section} | {test.subjectNames?.split(',')?.join(" • ")}
                            </Typography.Text>
                            <div className="fs-11 lowgrey">
                                {test.testType} &nbsp;•&nbsp; {test.totalQuestions ? test.totalQuestions : 0} Qs &nbsp;•&nbsp; {duration} mins &nbsp;•&nbsp; {test.totalPoints} marks
                            </div>
                        </div>
                        <div className="display-flex" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className="m-t-20 bold-700 text-xsm simgrey">CREATED ON</div>
                                <div className="fs-11 simgrey">{createdDate} </div>
                            </div>
                        </div>
                        <Button className="backBlue white radius-100 absolute" style={{ bottom: 10, fontSize: 12, right: -30 }} size="medium"
                            onClick={(e) => { handleButtonClick(test.id ? test.id : test.testId, "Publish", test, e) }}>
                            PUBLISH
                        </Button>
                    </Col>
                    <Col span={3}>
                        <div className="full-height full-width" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            <Dropdown
                                visible={showDropdown}
                                onVisibleChange={visible => setShowDropdown(visible)}
                                overlay={<CustomMenu type="dash_scheduled" test={test} handleButtonClick={handleButtonClick}
                                    isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                                    generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                    editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                                placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                                <MdMoreVert className='text-lg a2grey cursor-pointer' style={{ position: "absolute", top: "5px", height: 25 }} />
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                <PageDataLoader visible={pageLoader} />
            </div>
        )
    }
    else if (type == "class_card") {
        return (
            <div className="adminTestCard customCardShadow m-t-10 cursor-pointer shadow-box hover-shadow"
                key={index}
                style={{ height: 145, width: 200, minWidth: 120, borderRadius: 16, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}
                onClick={() => handleSubjectClick(class_std_name + ' ' + sectionid, classdata.id, class_std_name, sectionid)}>
                <div className="bold r-c-c-c m-b-10" style={{ color: "#594099", fontFamily: "serif" }}>
                    <div style={{ width: "80%" }} className='text-xlg text-center text-overflow p-r-5 p-l-5'>
                        {sectionid}
                    </div>
                    {classdata.stream ? <div className="full-width lowgrey text-center text-overflow p-r-5 p-l-5"> {classdata.stream}</div> : null}
                </div>
                <CountRow fullCount={classdata.fullTestCount} chapterCount={classdata.chapterTestCount} partCount={classdata.partTestCount} />
            </div>
        )
    }
    else if (type == "upcoming") {
        let [testDate, month] = test.testDate?.split(',');
        const handleUpcomingCardClick = () => {
            updateSelectedCardType('');
            updateListViewType('edit');
            updateSelectedTest(test);
            togglePageLoader(true);
            showGenerateQuesListView((response) => {
                console.log('resss', response)
                if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                    getEachQuesData(response.subjects[0].questions[0].id);
                }
                if ( (user?.role?.includes("Data Operator") || user?.role?.includes("Teacher"))) {
                    const currentTime = moment();
                      const startTime = moment(test?.startTime, 'hh:mm a');
                      const timeDifference = startTime.diff(currentTime, 'minutes')
                      if (timeDifference <= 15) {
                        history.push('/admin-test-view');
                      } else {
                        history.push('/admin-test');
                      }
            }
            else {
                history.push('/admin-test');
              }
                togglePageLoader(false);
            }, 'tabviewlive');
        }
        return (
            <div className="adminTestCard customCardShadow upcomingcard r-c-c shadow-box hover-shadow" key={index} style={{ height: 100, width: 340, minWidth: 340, maxWidth: 350, padding: 0 }}
                onClick={handleUpcomingCardClick}>
                <Row className="p-15 full-height ca-r-c-c-c" style={{ width: "90%" }}>
                    <Col span={6} className="r-c-c">
                        <div className="dateCard r-c-c-c text-xs bold-700" style={{ color: "#594099", height: 80 }}>
                            {testDate &&
                                <div style={{ textTransform: 'uppercase' }}>
                                    {month} {testDate}
                                </div>
                            }
                            <div>{test.startTime}</div>
                        </div>
                    </Col>
                    {/* onClick={()=>history.push('admin-test')} */}
                    <Col span={18} className="ca-r-fs-c-c p-15 cursor-pointer">
                        <div className="testcard-testtitle">{test.title}</div>
                        <div className="text-xsm lowgrey">{test.testType}&nbsp;•&nbsp;{test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round(test.duration / 60)} mins &nbsp;•&nbsp; {test.totalPoints}marks</div>
                    </Col>
                </Row>
                <div className="full-height relative" style={{ width: "10%" }} onClick={() => { }}>
                    <Dropdown
                        visible={showDropdown}
                        onVisibleChange={visible => setShowDropdown(visible)}
                        overlay={<CustomMenu type="upcoming" test={test} handleButtonClick={handleButtonClick}
                            isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                            generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                            editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                        placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                        <MdMoreVert className='text-lg a2grey cursor-pointer absolute' style={{ top: "14px", height: 25 }} />
                    </Dropdown>
                </div>
            </div>
        )
    }
    else if (type == "draft") {
        let testDate = null;
        let startTime = null;
        let createdDate = null;
        let createdTime = null;
        if (test.testDate != null) {
            testDate = moment.utc(test.testDate).local().format('MMM DD').toString();
        }
        if (test.createdAt != null) {
            createdDate = moment.utc(test.createdAt).local().format("DD/MM/YYYY • hh:mm A");
        }
        const handleDraftCardClick = (e) => {
            updateSelectedCardType('Draft');
            updateListViewType('edit');
            updateSelectedTest(test);
            togglePageLoader(true);
            showGenerateQuesListView((response) => {
                console.log('resss', response)
                if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length >= 0) {
                    if (response.subjects[0]?.questions[0]?.id) {
                        getEachQuesData(response.subjects[0]?.questions[0].id);

                    }
                    console.log('questionRes', response)

                }
                else if (response && response.subjects?.length > 0 && response.subjects[0]?.sections[0]?.questions?.length >= 0) {
                    if (response.subjects[0]?.sections[0]?.questions[0]?.id) {
                        getEachQuesData(response.subjects[0]?.sections[0]?.questions[0].id);
                    }

                }
                history.push('admin-test');
                togglePageLoader(false);
            }, 'tabviewlive');

        }
        return (
            <div className="adminTestCard m-t-10 customCardShadow shadow-box hover-shadow"
                key={index} style={{
                    height: 120,
                    minWidth: 340,
                    maxWidth: 350,
                    padding: 0
                }}
                onClick={handleDraftCardClick}>

                <Row className="full-height full-width">
                    <Col span={6} className="r-c-c">
                        <div className="dateCard r-c-c-c text-xs bold-700" style={{ color: "#594099", height: 80 }}>
                            {testDate && <div style={{ textTransform: 'uppercase' }}> {testDate}</div>}
                            <div>{test.startTime}</div>
                        </div>
                    </Col>
                    <Col span={12} className="ca-r-fs-c-c p-l-10 cursor-pointer">
                        <div className="testcard-testtitle">{test.title}</div>
                        <div className="text-xsm lowgrey" >
                            {test.testType} &nbsp;•&nbsp; {test.totalQuestions ? test.totalQuestions : 0} Qs &nbsp;•&nbsp; {Math.round(test.duration / 60)} mins &nbsp;•&nbsp; {test.totalMarks ? test.totalMarks : test.totalPoints ? test.totalPoints : 0} marks
                        </div>
                        <div className="m-t-20 bold-700 text-xsm simgrey" style={{ overflow: 'hidden' }}>
                            CREATED ON
                        </div>
                        <div className="fs-11 simgrey" style={{ overflow: 'hidden' }}>
                            {createdDate}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="full-height ca-r-fe-c relative" style={{ width: "90%" }}>
                            <Dropdown
                                visible={showDropdown}
                                onVisibleChange={visible => setShowDropdown(visible)}
                                overlay={<CustomMenu type="draft" test={test} handleButtonClick={handleButtonClick}
                                    isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                                    generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                    editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                                placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                                <MdMoreVert className='text-lg a2grey cursor-pointer absolute' style={{ top: "8px", height: 25 }} />
                            </Dropdown>
                            {isPublishButtonVisible ?
                                <Button className="backBlue white radius-100 text-xs absolute" style={{ bottom: "10px" }}
                                    onClick={(e) => { handleButtonClick(test.id ? test.id : test.testId, "Publish", test, e) }}>
                                    PUBLISH
                                </Button> :
                                null}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    else if (type == "Feedback") {
        let testDate = moment(test.testDate).local().format("YYYY-MM-DD HH:mm:ss")?.split(' ')[0]
        let month = testDate && numtoMonth[parseInt(testDate?.split('-')[1])]
        testDate = testDate?.split('-')[2]
        let duration = Math.floor(test.duration / 60)
        // let duration = durationList?parseInt(durationList[0])*60+parseInt(durationList[1]):0
        let title = test.title
        let testType = test.testType
        let totalMarks = test.totalPoints;
        let totalQuestions = test.totalQuestions;
        let reviewCount = test.reviewCount;
        return (
            <div className="adminTestCard m-t-10 customCardShadow testsToPublishCard r-c-c shadow-box hover-shadow" key={index} style={{ height: 100, maxWidth: 350, padding: 0 }}
                onClick={(e) => {
                    updateListViewType('edit');
                    updateSelectedTest(test);
                    updateSelectedCardType('Feedback');
                    togglePageLoader(true);
                    showGenerateQuesListView((response) => {
                        console.log('resss', response)
                        if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                            console.log('quesInside')
                            console.log('quesInside id', response.subjects[0].questions[0].id)
                            getEachQuesData(response.subjects[0].questions[0].id);
                        }
                        history.push('admin-test');
                        togglePageLoader(false);
                    }, 'tabviewlive');
                }}>
                    {console.log("AA-bb",test.startTime)}
                <Row className="r-c-c full-width full-height">
                    <Col span={6} className="r-c-c">
                        <div className="dateCard r-c-c-c text-xs bold-700 m-l-5" style={{ color: "#594099", height: 80 }}>
                            {testDate &&
                                <div style={{ textTransform: 'uppercase' }}>
                                    {month} {testDate}
                                </div>
                            }
                            <div>{test.startTime}</div>
                        </div>
                    </Col>
                    <Col span={15} className="ca-r-fs-c-c cursor-pointer">
                        <div className="r-c-fs" style={{ maxWidth: "100%" }}>
                            <div className="testcard-testtitle">{title}</div>
                            {(reviewCount != null && reviewCount > 0) ? <div className="m-l-10 r-c-c radius-5 bold-700 orange-yellow" style={{ height: 15, width: 25, backgroundColor: "#FFF4D5", fontSize: "9px" }}> {reviewCount}
                            </div> : null}
                        </div>
                        {dash
                            ?
                            <div style={{ fontSize: 11, color: Color.darkGrey }}
                                className="text-overflow-ellipsis full-width">
                                {test?.standardNames}  | {test.subjectNames?.split(',')?.join(" • ")}
                            </div>
                            :
                            null
                        }
                        <div className="text-xsm lowgrey text-overflow-ellipsis full-width">{testType}&nbsp;•&nbsp;{totalQuestions} Qs &nbsp;•&nbsp; {duration} mins &nbsp;•&nbsp; {totalMarks} marks</div>
                    </Col>
                    <Col span={3} className="full-height">
                        <div className="full-height full-width relative m-t-10" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }} onClick={() => { }}>
                            <Dropdown
                                visible={showDropdown}
                                onVisibleChange={visible => setShowDropdown(visible)} overlay={<CustomMenu type="feedback" test={test} handleButtonClick={handleButtonClick}
                                    isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                                    generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                    editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                                placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                                <MdMoreVert className='text-lg a2grey cursor-pointer' style={{ top: "14px", height: 25 }} />
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    else if (type == "ready") {
        let [testDate, month] = test.testDate?.split(',');
        const handleReadyCardClick = (e) => {
            e.stopPropagation();
            updateListViewType('edit');
            updateSelectedCardType('Ready');
            updateSelectedTest(test);
            togglePageLoader(true);
            showGenerateQuesListView((response) => {
                console.log('resss', response)
                if (response && response.subjects?.length > 0 && response.subjects[0]?.questions?.length > 0) {
                    getEachQuesData(response.subjects[0].questions[0].id);
                }
                history.push('admin-test');
                togglePageLoader(false);
            }, 'tabviewlive');
        }
        // let startTime = moment(test.startTime, 'hh:mm:ss').format('hh:mm A')
        return (
            <div className="adminTestCard customCardShadow upcomingcard r-c-c shadow-box hover-shadow" key={index} style={{ height: 100, width: 340, minWidth: 340, maxWidth: 350, padding: 0 }}
                onClick={handleReadyCardClick}>
                <Row className="p-15 full-height ca-r-c-c-c" style={{ width: "90%" }}>
                    <Col span={6} className="r-c-c">
                        <div className="dateCard r-c-c-c text-xs bold-700" style={{ color: "#594099", height: 80 }}>
                            {testDate &&
                                <div style={{ textTransform: 'uppercase' }}>
                                    {month} {testDate}
                                </div>
                            }
                            <div>{test.startTime}</div>
                        </div>
                    </Col>
                    <Col span={18} className="ca-r-fs-c-c p-15 cursor-pointer">
                        <div className="testcard-testtitle">{test.title}</div>
                        <div className="text-xsm lowgrey">{test.testType}&nbsp;•&nbsp;{test.totalQuestions} Qs &nbsp;•&nbsp; {Math.round(test.duration / 60)} mins &nbsp;•&nbsp; {test.totalPoints}marks</div>
                    </Col>
                </Row>
                <div className="full-height relative" style={{ width: "10%" }} onClick={() => { }}>
                    <Dropdown
                        visible={showDropdown}
                        onVisibleChange={visible => setShowDropdown(visible)}
                        overlay={<CustomMenu type="ready" test={test} handleButtonClick={handleButtonClick}
                            isCModalVisible={isCModalVisible} createTestModalBool={createTestModalBool}
                            generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                            editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader} />}
                        placement="bottomRight" arrow onClick={(e) => { e.stopPropagation(); }}>
                        <MdMoreVert className='text-lg a2grey cursor-pointer absolute' style={{ top: "14px", height: 25 }} />
                    </Dropdown>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { universalLoaderBool, isCModalVisible,
        cModalType, createTestModalBool, generateQuesListViewBool, userType, editDetailsLoader, cloneLoader } = state.testAdmin;
    const { user } = state.session;
    return {
        universalLoaderBool, isCModalVisible,
        cModalType, createTestModalBool, generateQuesListViewBool, userType, user, editDetailsLoader, cloneLoader
    };
};

const mapDispatchToProps = dispatch => ({
    setSelectedClassroom: (selectedClass) => dispatch(setSelectedClassroom(selectedClass)),
    setSelectedClassroomId: (selectedClassId) => dispatch(setSelectedClassroomId(selectedClassId)),
    updateSelectedTest: (test) => dispatch(updateSelectedTest(test)),
    updateSelectedTestId: (testId) => dispatch(updateSelectedTestId(testId)),
    setIsCModalVisible: (bool) => dispatch(setIsCModalVisible(bool)),
    setCModalType: (type) => dispatch(setCModalType(type)),
    createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
    storeGenerateQuestionsObj: (data) => dispatch(storeGenerateQuestionsObj(data)),
    showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
    updateListViewType: (type) => dispatch(updateListViewType(type)),
    fetchTestSubmitDetails: (callback) => dispatch(fetchTestSubmitDetails(callback)),
    updateSubmittedTestsId: value => dispatch(updateSubmittedTestsId(value)),
    universalLoader: (name, varname, bool) => dispatch(universalLoader(name, varname, bool)),
    updateSelectedCardType: (type) => dispatch(updateSelectedCardType(type)),
    changeTestStatusModal: (status) => dispatch(changeTestStatusModal(status)),
    getTestClassList: (classIds, chapterIds) => dispatch(getTestClassList(classIds, chapterIds)),
    storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
    getEachQuesData: (id) => dispatch(getEachQuesData(id)),
    updateFilter: (key, val) => dispatch(updateFilter(key, val)),
    updateSubmittedTestsDetails: (test) => dispatch(updateSubmittedTestsDetails(test)),
    savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y))
    ,
    setSelectedSectionId: (val) => dispatch(setSelectedSectionId(val)),
  setStartTimeTest:(val)=>dispatch(setStartTimeTest(val))

});

export default connect(mapStateToProps, mapDispatchToProps)(TestCard);