import React, {useEffect, useState} from 'react'
import moment from 'moment';
import { Checkbox, Modal } from 'antd'
import {Button} from 'components/Button';
import { Heading } from 'components/Typography';
import { DeleteOutlined } from '@ant-design/icons';

const MultipleLeaveDeleteModal = ({ visible, data, loading, setMultiLeaveDeleteModal, setDeleteLoader, deleteHistory, setSelectedRowKeys }) => {

        const [selectedDates, setSelectedDates] = useState([]);
        const [selectedId, setSelectedId] = useState([]);

        const successCallBack = () => {
                setMultiLeaveDeleteModal(false);
                setSelectedRowKeys([]);
        }

        const handleMultipleLeaveDelete = () => {
                let params = {
                        id: selectedId
                }
                setDeleteLoader(true)
                deleteHistory(params, () => setDeleteLoader(false), () => successCallBack(), 'LeaveSummary');
        }

        const checkOnChange = (e, item) => {
                setSelectedDates(e.target.checked);
                setSelectedId(prevSelectedId => prevSelectedId ? [...prevSelectedId, item?.id] : [item?.id]);
        };

        return (
        <div>
                <Modal
                        open={visible}
                        footer={false}
                        className="modal-round-corner"
                        onCancel={() => setMultiLeaveDeleteModal(false)}
                >
                        <div className='r-c-c m-t-20'>
                                <Heading>Confirm</Heading>
                        </div>
                        <center className='simgrey text-xmd'>Confirm the dates you want to delete</center>
                        {data?.requests?.map((item) => {
                                return  <div className='m-l-50 m-t-20'>
                                        <div style={{ display: 'flex'}}>
                                                <Checkbox value={selectedDates} disabled={moment(item?.date, 'YYYY-MM-DD') <= moment()} onChange={(e) => checkOnChange(e, item)}  style={{marginTop: 4}}></Checkbox>
                                                <div className='m-l-20 color-black text-sm bold-600'>{moment(item?.date).format('DD/MM/YYYY')}</div>
                                        </div>
                                        <div className='simgrey text-xs bold-600' style={{marginLeft: 37}}>{data?.duration == '0.5' ? 'Half day' : 'Full day'}</div>
                                </div>
                        })}
                        <center className='m-t-30'>
                                <Button type="delete" disabled={!selectedDates} icon={<DeleteOutlined />} loading={loading} onClick={() => handleMultipleLeaveDelete()}>DELETE</Button>
                        </center>
                </Modal>
        </div>
        )
}

export default MultipleLeaveDeleteModal