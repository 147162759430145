import React from "react";
import { connect } from "react-redux";
import "design/layout.css";

class VideoFeed extends React.Component {
  constructor() {
    super();
    this.state = {
      filteredMedia: []
    };
  }

  componentDidUpdate(prevProps) {
    const { remoteMedia, localMedia } = this.props;
    const tempArray = [];
    if (remoteMedia.length && prevProps.remoteMedia !== remoteMedia) {
      this.audioContainer = document.getElementById("audio-container");
      this.videoContainer = document.getElementById("video-container");
      for (let i = 0; i < remoteMedia.length; i++) {
        if (remoteMedia[i].video) {
          tempArray.push(remoteMedia[i].video.stream);
        }

        if (remoteMedia[i].audio && remoteMedia[i].audio.stream) {
          let elem = document.createElement("audio");
          elem.srcObject = remoteMedia[i].audio.stream;
          elem.id = remoteMedia[i].user_id + "audio";
          elem.playsinline = false;
          elem.autoplay = true;
          if (!this.audioContainer.childNodes[i]) {
            this.audioContainer.appendChild(elem);
          }
        }
      }
      console.log("tempArray", tempArray);
      this.setState({ filteredMedia: tempArray }, () => {
        if (tempArray.length == 1) {
          this.teacherVideo = document.getElementById("remote-video");
          this.teacherVideo.srcObject = tempArray[0];
        } else if (tempArray.length == 2) {
          this.teacherVideo = document.getElementById("remote-video");
          this.teacherVideo.srcObject = tempArray[0];
          this.studentVideo = document.getElementById("remote-video-second");
          this.studentVideo.srcObject = tempArray[1];
        }
      });
    }
    if (localMedia) {
      this.localVideo = document.getElementById("local-video");
      this.localVideo.srcObject = localMedia.stream;
    }
  }

  componentDidMount() {
    const { localMedia, remoteMedia } = this.props;
    dragElement(document.getElementById("mydiv"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        if (
          elmnt.offsetTop - pos2 < window.outerHeight - 500 &&
          elmnt.offsetTop - pos2 > 0 &&
          remoteMedia.length <= 1 &&
          localMedia.length < 1
        ) {
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        } else if (
          elmnt.offsetTop - pos2 < window.outerHeight - 350 &&
          elmnt.offsetTop - pos2 > 0
        ) {
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        }
        if (
          elmnt.offsetLeft - pos1 < window.outerWidth - 340 &&
          elmnt.offsetLeft - pos1 > -150 &&
          remoteMedia.length <= 1 &&
          localMedia.length < 1
        ) {
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        } else if (
          elmnt.offsetLeft - pos1 < window.outerWidth - 240 &&
          elmnt.offsetLeft - pos1 > -150
        ) {
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }

  render() {
    const { localMedia } = this.props;
    const { filteredMedia } = this.state;

    return (
      <div id="mydiv">
        {filteredMedia.length > 1 && filteredMedia[1] ? (
          <div className="r-c-c-c">
            {filteredMedia[1] ? (
              <video
                autoPlay
                className="halffeedvid rounded-top"
                id="remote-video-second"
              />
            ) : null}
            {filteredMedia[0] ? (
              <video
                autoPlay
                className="halffeedvid rounded-bottom"
                id="remote-video"
              />
            ) : null}
          </div>
        ) : (
          <>
            {localMedia ? (
              <div className="r-c-c-c">
                {filteredMedia[0] ? (
                  <video
                    autoPlay
                    className="halffeedvid rounded-top"
                    id="remote-video"
                  />
                ) : null}
                <video
                  autoPlay
                  className="halffeedvid rounded-bottom"
                  id="local-video"
                />
              </div>
            ) : (
              <div>
                <video
                  autoPlay
                  className="feedvid"
                  id="remote-video"
                  type="video/mp4"
                />
              </div>
            )}
          </>
        )}
        <div id="audio-container" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { handRaiseQArray } = state.liveClass;
  const { remoteMedia, localMedia } = state.brigoshaMedia;

  return {
    remoteMedia,
    handRaiseQArray,
    localMedia
  };
};

export default connect(mapStateToProps)(VideoFeed);
