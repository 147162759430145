import { updateAttendanceReminderSettings } from 'actions/profileNew';
import { Button, Modal,Switch, TimePicker } from 'antd';
import { connect } from 'react-redux';
import React,{ useEffect, useState } from 'react';
import moment from 'moment';
const ReminderModal = ({setIsModalVisible,isModalVisible,storeRemainderData,updateAttendanceReminderSettings}) => {
  const[isVisible,setIsVisible]=useState(false)
  const [selectedDay, setSelectedDay] = useState([]);
  const[clockInTime,setClockInTime]= useState(null)
  const[clockOutTime,setClockOutTime]= useState(null)
  const [isNo,setIsNo] = useState(false)


  useEffect(()=>{
    setIsNo(false)
    setIsVisible(storeRemainderData?.enabled)
  },[storeRemainderData?.enabled,isNo])

  const handleSave=()=>{
    // setIsNo(true)
    const params = {
      enabled:isVisible,
      settings: {
          clockInTime:moment(clockInTime, "HH:mm:ss" ).format('HH:mm:ss') ,
          clockOutTime:moment(clockOutTime, "HH:mm:ss").format('HH:mm:ss'),
          repeat: selectedDay
      }  
  }
  updateAttendanceReminderSettings(params, () => {setIsModalVisible(false);})
  }

  
  console.log("storeRemainderData",storeRemainderData);
  const handleOk = () => {
    setIsNo(true)
  };
  const handleCancel = () => {
    setIsNo(true)
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (storeRemainderData?.settings?.repeat) {
      setSelectedDay(storeRemainderData.settings.repeat);
    }
  }, [storeRemainderData]);
  const weekdays=[
      {
        day:'Mon'
      },
      {
        day:'Tue'
      },
      {
        day:'Wed'
      },
      {
        day:'Thu'
      },
      {
        day:'Fri'
      },
      {
        day:'Sat'
      },
      {
        day:'Sun'
      },
]
const handleDivClick = (day) => {
    const isSelected = selectedDay?.includes(day);
    if (isSelected) {
      setSelectedDay(selectedDay?.filter((selectedDay) => selectedDay !== day));
    } else {
      setSelectedDay([...selectedDay, day]);
    }
  };

  return (
    <>

      <Modal
       className="modal-round-corner"
       open={isModalVisible} 
       onOk={handleOk} 
       onCancel={handleCancel}
       footer={null}
       >
       <div style={{fontSize:"21px",fontWeight:"bold",textAlign:"center"}}>Setting</div>
       <div style={{fontSize:"14px",fontWeight:"bold",marginTop:"30px"}}>Reminder alert</div>
       <div style={{display:"flex",marginTop:"10px"}}>
        <div style={{fontSize:"12px",color:"#636363"}}>You’ll be reminded to Clock in/Clout out at your preferred timings.</div>
        <div style={{marginLeft:"70px"}}><Switch checked={isVisible} onChange={(e) => {setIsVisible(e);}}/></div>
       </div>
       {isVisible ? 
       (<>
             <div style={{display:"flex",justifyContent:"space-between",marginTop:"25px"}}>
            <div>
                <div>Clock in alert</div>
                <div><TimePicker
                defaultValue={!storeRemainderData?.settings?.clockInTime  ?'' : moment(storeRemainderData?.settings?.clockInTime ,'h:mm') }
                allowClear={false}
                onKeyDown={(e)=>{e.preventDefault()}}

                // value={ moment(clockInTime, "HH:mm:ss")}
                onChange={(e,time)=>{setClockInTime(time)}}
                /></div>
            </div>
            <div>
                <div>Clock out alert</div>
                <div><TimePicker
                defaultValue={!storeRemainderData?.settings?.clockOutTime  ?'' : moment(storeRemainderData?.settings?.clockOutTime ,'h:mm') }
                allowClear={false}
                onKeyDown={(e)=>{e.preventDefault()}}
                onChange={(e,time)=>{setClockOutTime(time)}}
                
                /></div>
            </div>
       </div>
       <div style={{marginTop:"20px",fontSize:"11px",fontWeight:"bold",color:"#636363"}}>Repeat</div>
       <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-between",marginTop:"15px",cursor:"pointer"}}>
       {weekdays?.map((item,index)=>
        (<div key={index}>
           <div style={{border:"1px solid #E6E6E6",height:"35px",width:"35px",textAlign:"center",
           backgroundColor: selectedDay?.includes(item.day)? "#E7F3FF": "#FFFFFF",}}onClick={() => handleDivClick(item.day)}>
            <p style={{fontSize:"11px",fontWeight:"bold",marginTop:"5px", color: selectedDay?.includes(item.day)? "#1089FF": "#AEAEAE",}}onClick={() => handleDivClick(item.day)}>{item?.day}</p>
         </div>
        </div>)
       )}
       </div>
       
       </>):null}
       <div style={{display:"flex",justifyContent:"center",marginTop:"25px"}}>
       <div>
       <Button 
       style={{
            borderRadius: "19px",
            width: "120px",
            height: "40px",
            fontWeight: "500",
            backgroundColor: "#FFFFFF",
            border: "1px solid #636363 ",
            color: "#636363",
        }} onClick={() => {handleCancel()}}>CANCEL</Button>
       </div>
       <div style={{marginLeft:"20px"}}>
       <Button
       style={{ 
            borderRadius: "19px",
            width: "120px",
            height: "40px",
            fontWeight: "500",
            backgroundColor: "#594099",
            color: "#FFFFFF",
            
       }} onClick={() => {handleSave()}}>SAVE</Button>
       </div>
       </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { attendanceDataContainer,studentMonthlySummary,studentattStartDate, studentattEndDate,storeRemainderData } = state.attendanceNew;
  return { attendanceDataContainer,studentMonthlySummary,studentattStartDate, studentattEndDate,storeRemainderData };
};

const mapDispatchToProps = (dispatch) => ({
  updateAttendanceReminderSettings: (params, callback) => dispatch(updateAttendanceReminderSettings(params, callback)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ReminderModal);
updateAttendanceReminderSettings