import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchChapterCardData, setImportMeta } from 'actions/testAdmin';
import Loader from './localComponent/loader';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const bodyStyle = {background: "#FAFAFA",
        width: "calc(100% + 30px)",
        height: "calc(100% + 40px)",
        margin:-20,
        padding:20,
        overflow:"hidden"
    }

const chapterStyle = {
    height:100,
    width: 250,
    borderRadius: 10,
    backgroundColor:"#FFFFFF",
    border: "1px solid #E6E6E6"
} 

const PageHeader = ({ goBack, onSearch, searchVal, selectedQBtype, selectedClassname, selectedSubjectName}) => {
  return (
    <div className="resultPageHeader full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
        <div className="text-md bold-700 m-l-20"> 
          <div>
            {selectedSubjectName}
          </div>
          <div style={{color:"#AEAEAE", fontSize:12}}>
          {selectedQBtype} / {selectedClassname}
          </div>
        </div>
      </div>
      <div className="testfiltersgroup">
          {/* <SearchBox loading={false} onChange={onSearch}  value={searchVal} className="m-r-10" placeholder="Search by test name" style={{width:230}}/> */}
        {/* <Button onClick={() => {}} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
          + CREATE TEST
        </Button> */}
      </div>
    </div>
  );
};


const ImportSubjectwiseChapters = ({fetchChapterCardData, importMeta, setImportMeta, loaders}) => {
    useEffect(()=>{
      fetchChapterCardData();
    }, []);
    const history = useHistory();
  const location = useLocation()

    const goBack = () => {
      history.goBack();
    }
    const onChapterClick = (chapterData) => {
      return () => {
        setImportMeta('topicwiseQuestionsList', []);
        setImportMeta('topicIndex', 0);
        setImportMeta('marksList', []);
        setImportMeta('selectedChapterObj', chapterData);
        setImportMeta('clearSelectionTree', null);
        history.push({pathname:'/sub-ques-import-page', state: location?.state });
      }
    }
    const ChapterCard = ({data}) => {
      return (
        <div className='customCardShadow m-10 cursor-pointer shadow-box hover-shadow' style={chapterStyle} onClick={onChapterClick(data)}>
          <div style={{display: "flex", padding: "20px 0 0 20px", color: "#636363"}}>
            <div className='m-r-10'>Chapter {data.chapterNo} {'  '}•{'  '} {data.noOfTopics} Topic</div>
          </div>
          <div className='m-t-10 p-l-20 p-r-20 bold-700 text-overflow-ellipsis' style={{color: "#191919"}}>
          {data.chapterName}
          </div>
        </div>
      )
  
    }
    return (
      <div className='chapters_Wrapper' style={bodyStyle}>
          <PageHeader selectedClassname={`${importMeta?.selectedStandardObj?.name} - ${importMeta?.selectedStandardObj?.stream===''?"NA":importMeta?.selectedStandardObj?.stream}`} selectedQBtype={'Subject-wise Question Papers'} selectedSubjectName={importMeta?.selectedSubjectObj?.subName} goBack={goBack}/>
          {
            loaders?.importChapter?
            <Loader style={{fontSize:40}}/>
            :
            <div className="full-height full-width" style={{overflowY:"scroll", paddingBottom:60}}>
              <div className="flex-wrap r-afs m-t-20">
              {importMeta?.chapterCardData?.map((item)=><ChapterCard data={item}/>)}
              </div>
            </div>
          }
      </div>
    )
}

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.testAdmin;
  return {  
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchChapterCardData: (callback) => dispatch(fetchChapterCardData(callback))
})


export default connect(mapStateToProps, mapDispatchToProps)(ImportSubjectwiseChapters);