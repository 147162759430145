import { AutoComplete, Dropdown, Menu, Radio, Row, Typography } from 'antd';
import { Button } from 'components/Button';
import { Heading, SubHeading } from 'components/Typography';
import PageHeader from 'components/pageHeader'
import React, { useState, useEffect } from 'react'
import testSeriesIcon from '../../Assets/ic-course.svg'
import { connect } from 'react-redux';
import { fetchStudentsByCourse, setWhichViewAllClicked, storeAllStudentByCourseDetails, storeFilterDetails, storeResourceData } from 'actions/feeManagement';
import PageDataLoader from 'components/pageDataLoader';
import { Api } from 'services';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined } from '@ant-design/icons';
import { getCoursesCategoriesForFee, getCoursesSubCategories, getSearchResultsData, setSearchResultPageParamsObj } from 'actions/courseManagement';
import { InputSearch } from 'components/customInputs';
import { setSelectedCourse } from 'actions/userManagementV2';

const defaultCourseImage = require('Assets/default_course-img.svg').default;

const Card = ({ title, onClick, image, description }) => (
    
    
    <div onClick={onClick} style={{ width: '23.5%', cursor: 'pointer'}}>
  
        {image && <img src={image} style={{ borderRadius: 8, border: '1px solid #E6E6E6', height: 160, width: 270, objectFit: 'cover' }} />}
        {/* {description && title && <div style={{ width: 270, height: 80, borderRadius: 8, border: '1px solid #EDEDED', backgroundColor: 'white' }}>
            <Row className='p-15'>
                <img src={testSeriesIcon} style={{ width: 50 }} />
                <div className='m-t-15 m-l-10 bold-600 simgrey'>{description}</div>
            </Row>
        </div>
        } */}
        <div style={{ width: 300, marginTop: 5, width: 250 }}>
            <SubHeading bold>{title}</SubHeading>
        </div>
    </div>
);

const Section = ({ title, onClickViewAll, cards }) => (
    <div className={title === 'Live Courses' ? 'm-t-50' : 'm-t-40'}>
        <div className='r-jsb' style={{ width: '100%' }}>
            <Heading bold>{title}</Heading>
            {!cards?.length ? (
                <></>
            ) : (
                <Button type='link' style={{ padding: 0, fontSize: 14 }} onClick={onClickViewAll}>
                    View all
                </Button>
            )}
        </div>
        <div className="cards-container display-flex flex-wrap" style={{ width: '100%', gap: 20, display: 'flex', flexWrap: 'wrap' }}>
            {cards?.length !== 0 ? cards?.map((card, index) => (
                <Card key={index} {...card} style={{ margin: '0 8px 16px 0', flex: '1 0 calc(33.3333% - 16px)' }} />
            )) : (
                <center className='r-c-c-c full-width'>
                    <img src={require('../../Assets/test/no-subjects.svg').default} style={{ width: 250 }} alt="No subjects" />
                    <div className='text-xmd bold-600 m-t-10'>NO COURSES</div>
                </center>
            )}
        </div>
    </div>
);

const AllStudentsByCourses = ({ history, setWhichViewAllClicked, fetchStudentsByCourse, studentByCourseData, storeResourceData, getCoursesCategoriesForFee, getCoursesSubCategories, courseSubCategoriesData, courseCategoryDataForFee, setSearchResultPageParamsObj, getSearchResultsData, allStudentsByCoursesReducer, storeAllStudentByCourseDetails, setSelectedCourse, storeFilterDetails, filterDetails }) => {

    console.log({ allStudentsByCoursesReducer });

    const bodyStyle = { backgroundColor: "#FAFAFA", width: " calc(100% + 30px)", height: "calc(100% + 40px)", margin: "-20px", padding: "20px", overflow: "auto" }
    const statusArray = [{ label: "Active", value: true }, { label: "Inactive", value: false }, { label: 'Deleted', value: 'inactive' }];
    const modeArray = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Hybrid", value: "hybrid" }, { label: "Offline", value: "offline" }];

    const [pageLoader, togglePageLoader] = useState(false);
    const [isMultipleFilterClicked, setMultipleFilterClicked] = useState(false);
    const [isFilterCategoryClicked, setFilterCategoryClicked] = useState(false);
    const [isFilterSubCategoryClicked, setFilterSubCategoryClicked] = useState(false);
    const [selectedSubCategory, setSeletedSubCategory] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);

    let params = {
        search: allStudentsByCoursesReducer?.search ? allStudentsByCoursesReducer?.search : undefined,
        limit: 4,
        activeStatus: allStudentsByCoursesReducer?.statusFilter == 'inactive' ? allStudentsByCoursesReducer?.statusFilter : undefined,
        mode: allStudentsByCoursesReducer?.mode ? allStudentsByCoursesReducer?.mode : undefined,
        published: allStudentsByCoursesReducer?.statusFilter !== 'inactive' ? allStudentsByCoursesReducer?.statusFilter : undefined,
        subcategoryId: allStudentsByCoursesReducer?.subcategoryId ? allStudentsByCoursesReducer?.subcategoryId : undefined
    }


    useEffect(() => {
        storeAllStudentByCourseDetails('mode', []);
        storeAllStudentByCourseDetails('statusFilter', true);
        storeAllStudentByCourseDetails('subcategoryId', null);
        storeAllStudentByCourseDetails('search', '');
        console.log('anurag')

        togglePageLoader(true)
        fetchStudentsByCourse(params, () => { togglePageLoader(false) });
        getCoursesCategoriesForFee(null, () => { })
    }, [])

    useEffect(() => {
        togglePageLoader(true)
        fetchStudentsByCourse(params, () => { togglePageLoader(false) });
    }, [allStudentsByCoursesReducer?.search, allStudentsByCoursesReducer?.subcategoryId])

    const FilterTitle = ({ titleName }) => {
        return (
            <div className='m-b-5' style={{ color: "#191919", fontWeight: 500 }}>{titleName}</div>
        )
    }

    const toggleMode = (mode) => {
        storeAllStudentByCourseDetails("mode", mode);
    };

    const toggleStatus = (status) => {
        storeAllStudentByCourseDetails("statusFilter", status);
    };

    const StatusFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course status"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {statusArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: allStudentsByCoursesReducer?.statusFilter === item?.value ? "#1089FF" : "#FFFFFF", border: allStudentsByCoursesReducer?.statusFilter === item?.value ? "1px solid #1089FF" : "1px solid #E6E6E6", color: allStudentsByCoursesReducer?.statusFilter === item?.value ? "#FFFFFF" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleStatus(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const ModeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Mode of class"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {modeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: allStudentsByCoursesReducer?.mode === item?.value ? "#1089FF" : "#FFFFFF", border: allStudentsByCoursesReducer?.mode === item?.value ? "1px solid #1089FF" : "1px solid #E6E6E6", color: allStudentsByCoursesReducer?.mode === item?.value ? "#FFFFFF" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleMode(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const handleCategoryDropdownVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);
        setFilterSubCategoryClicked(false);
    };

    const handleSubCategoryDropdownVisibleChange = (visible) => {
        // setFilterSubCategoryClicked(visible);
    };

    const handleApply = () => {
        togglePageLoader(true)
        fetchStudentsByCourse(params, () => { togglePageLoader(false) });
    }

    const handleReset = () => {
        storeAllStudentByCourseDetails('mode', [])
        storeAllStudentByCourseDetails('statusFilter', true)
        let resetParams = {
            mode: null,
            published: true,
            limit: 4
        }
        togglePageLoader(true)
        fetchStudentsByCourse(resetParams, () => { togglePageLoader(false) });
    }

    const filterSubCategoryMenu = (
        <Menu style={{ maxHeight: courseCategoryDataForFee?.length > 3 ? 150 : 'fit-content', overflowY: "scroll", padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
            <Radio.Group onChange={(e) => {
                console.log({ params })
                e.stopPropagation();
                setFilterCategoryClicked(true)
                const selectedSubcategory = e.target.value;
                storeAllStudentByCourseDetails("subcategoryId", selectedSubcategory?.id);
                togglePageLoader(true)
                fetchStudentsByCourse(params, () => { togglePageLoader(false) });
                setFilterCategoryClicked(false);
                setFilterSubCategoryClicked(false);
            }}
            >
                {courseSubCategoriesData?.map((subcategory) => (
                    <Radio key={subcategory?.name} value={subcategory} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{subcategory?.name}</Typography.Text>
                    </Radio>
                ))}
            </Radio.Group>
        </Menu>
    )

    const filterCategoryMenu = (
        <Dropdown
            visible={isFilterCategoryClicked}
            onVisibleChange={handleSubCategoryDropdownVisibleChange}
            overlay={isFilterSubCategoryClicked ? filterSubCategoryMenu : <></>}
            placement='right'
            open={isFilterSubCategoryClicked}
            onMouseEnter={() => setFilterSubCategoryClicked(false)} // Set false on hover
        >
            <Menu
                className='scroll-bar-universal'
                style={{
                    maxHeight: courseCategoryDataForFee?.length > 6 ? 300 : 'fit-content',
                    padding: 10,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "4px 4px 16px #00000014",
                }}
            >
                <Radio.Group
                    onChange={(e) => {
                        const selectedCat = e?.target?.value;
                        getCoursesSubCategories(selectedCat?.id, null);
                        setFilterSubCategoryClicked(true); // Set true on radio button click
                        setSelectedCategory(selectedCat?.id);
                    }}
                >
                    {courseCategoryDataForFee &&
                        courseCategoryDataForFee?.map((category) => (
                            <Radio
                                key={category?.id}
                                value={category}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 5,
                                }}
                            >
                                <Typography.Text
                                    ellipsis={{ tooltip: true }}
                                    style={{ color: "#636363", width: 160 }}
                                >
                                    {category?.name}
                                </Typography.Text>
                            </Radio>
                        ))}
                </Radio.Group>
            </Menu>
        </Dropdown>
    );
    
    const multipleFilterMenu = (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#FFF', padding: 20, borderRadius: 8 }}>
            <ModeFilter />
            <StatusFilter />
            <div style={{ borderBottom: '1px solid #AEAEAE', width: '100%' }}></div>
            <div className='r-jsb m-t-10'>
                <Button onClick={handleReset} type='link' style={{ fontSize: 14 }}>Reset</Button>
                <Button onClick={handleApply} type='primary' style={{ backgroundColor: '#1089FF', color: 'white', borderRadius: 4, width: '20%', border: 'none' }}>Apply</Button>
            </div>
        </div>
    )

    // This function will be called when the dropdown visibility changes
    const handleVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);

    };
    const handleVisible = (visible) => {
        setMultipleFilterClicked(visible);

    }
    const actions = [
        <div className='r-jsb' style={{ width: 550 ,}}>
            <Dropdown overlay={isFilterCategoryClicked ? filterCategoryMenu : <></>} trigger={['click']} open={isFilterCategoryClicked} onVisibleChange={handleVisibleChange}>
                <div
                    style={{
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#FFFFFF",
                        width: 300,
                        padding: "5px 10px",
                        marginRight: 20,
                        color: "#AEAEAE",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 32,
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setFilterCategoryClicked(!isFilterCategoryClicked);
                        setMultipleFilterClicked(false);
                        setFilterSubCategoryClicked(false);
                    }}
                    
                >
                    <div>Filter by category</div>
                    {!isFilterCategoryClicked && !isFilterSubCategoryClicked ? (
                        allStudentsByCoursesReducer?.subcategoryId ? (
                            <CloseOutlined
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilterSubCategoryClicked(false);
                                    storeAllStudentByCourseDetails("subcategoryId", null);
                                    togglePageLoader(true);
                                    fetchStudentsByCourse(params, () => { togglePageLoader(false) });
                                }}
                                style={{ marginLeft: 8 }}
                            />
                        ) : (
                            <CaretDownOutlined />
                        )
                    ) : (
                        isFilterCategoryClicked ? <CaretUpOutlined /> : null
                    )}
                </div>
            </Dropdown>


            {/* This is removed because this implementation is only valid for live courses, but this screen consists of all other courses aswell */}
            {/* <Dropdown overlay={isMultipleFilterClicked ? multipleFilterMenu : <></>} trigger={['click']} open={isMultipleFilterClicked} onVisibleChange={handleVisible}>
                <div
                    style={{
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#FFFFFF",
                        width: "30%",
                        padding: "5px 10px",
                        color: "#AEAEAE",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 43,
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setMultipleFilterClicked(!isMultipleFilterClicked);
                        setFilterCategoryClicked(false);
                        setFilterSubCategoryClicked(false);
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <img src={require("../../Assets/aulasMarket/filterIC.svg").default} style={{ height: 18, width: 18, marginRight: 10, marginTop: 3 }} />
                        <div style={{ color: "#191919" }}>Filter</div>
                    </div>
                    {!isMultipleFilterClicked ? (
                        <CaretDownOutlined onClick={() => { setMultipleFilterClicked(!isMultipleFilterClicked) }} />
                    ) : (
                        <CaretUpOutlined onClick={() => { setMultipleFilterClicked(!isMultipleFilterClicked) }} />
                    )}
                </div>
            </Dropdown> */}

            <InputSearch allowClear value={allStudentsByCoursesReducer?.search} onEnter={(e) => storeAllStudentByCourseDetails('search', (e))} placeholder={'Search course'} style={{ marginLeft: "10px", borderRadius: 32, backgroundColor: 'white', padding: 5 }} />
        </div>
    ];

    return (
        <div style={bodyStyle} >
            <PageHeader title='All students by courses' actions={actions} onBackPress={() => history.goBack()} />
            {pageLoader ? <></> :
                <>
                    {studentByCourseData?.courses?.items?.length > 0 ?
                        <Section
                            title={studentByCourseData?.courses?.items?.length > 0 ? 'Live Courses' : ""}
                            onClickViewAll={() => { setWhichViewAllClicked('courses'); history.push('/fee-management/all-students/courses/view-all') }}
                            cards={studentByCourseData?.courses?.items?.map(course => ({
                                title: course?.name,
                                onClick: () => { setSelectedCourse(course?.name); history.push('/fee-management/all-students'); storeResourceData(course); setWhichViewAllClicked('course') },
                                image: course?.image ? `${Api?.dlS3Url}${course?.image}` : defaultCourseImage
                            }))}
                        />
                        : null}
                    {studentByCourseData?.videoResources?.items?.length > 0 ?
                        <Section
                            title={studentByCourseData?.videoResources?.items?.length > 0 ? 'Video Resources' : ""}
                            onClickViewAll={() => { setWhichViewAllClicked('videoResource'); history.push('/fee-management/all-students/courses/view-all') }}
                            cards={studentByCourseData?.videoResources?.items?.map(video => ({
                                title: video?.name,
                                onClick: () => { history.push('/fee-management/course-details'); storeResourceData(video); setWhichViewAllClicked('videoResource') },
                                image: video?.image ? `${Api?.dlS3Url}${video?.image}` : defaultCourseImage
                            }))}
                        />
                        : null}
                    {studentByCourseData?.testSeries?.items?.length > 0 ?
                        <Section
                            title={studentByCourseData?.testSeries?.items?.length > 0 ? 'Online Test Series' : ""}
                            onClickViewAll={() => { setWhichViewAllClicked('testSeries'); history.push('/fee-management/all-students/courses/view-all') }}
                            cards={studentByCourseData?.testSeries?.items?.map(test => ({
                                description: `${test?.tCount} tests`,
                                onClick: () => { history.push('/fee-management/course-details'); storeResourceData(test); setWhichViewAllClicked('testSeries') },
                                image: test?.image ? `${Api?.dlS3Url}${test?.image}` : defaultCourseImage,
                                title: test?.name
                            }))}
                        />
                        : null}
                    {studentByCourseData?.studyMaterials?.items?.length > 0 ?
                        <Section
                            title={studentByCourseData?.studyMaterials?.items?.length > 0 ? 'Study Material' : ""}
                            onClickViewAll={() => { setWhichViewAllClicked('studyMaterial'); history.push('/fee-management/all-students/courses/view-all') }}
                            cards={studentByCourseData?.studyMaterials?.items?.map(studyMaterial => ({
                                description: `${studyMaterial?.m_count} materials`,
                                onClick: () => { history.push('/fee-management/course-details'); storeResourceData(studyMaterial); setWhichViewAllClicked('studyMaterial') },
                                image: studyMaterial?.image && studyMaterial?.image !== 'null' ? `${Api?.dlS3Url}${studyMaterial?.image}` : defaultCourseImage,
                                title: studyMaterial?.name
                            }))}
                        />
                        : null}
                </>
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { studentByCourseData, allStudentsByCoursesReducer, filterDetails } = state.feeManagement;
    const { courseSubCategoriesData, courseCategoryDataForFee } = state.courseManagement;
    return { studentByCourseData, courseSubCategoriesData, courseCategoryDataForFee, allStudentsByCoursesReducer, filterDetails };
};
const mapDispatchToProps = (dispatch) => ({
    setWhichViewAllClicked: val => dispatch(setWhichViewAllClicked(val)),
    fetchStudentsByCourse: (params, callback) => dispatch(fetchStudentsByCourse(params, callback)),
    storeResourceData: res => dispatch(storeResourceData(res)),
    getCoursesCategoriesForFee: callback => dispatch(getCoursesCategoriesForFee(callback)),
    getCoursesSubCategories: (id, callback) => dispatch(getCoursesSubCategories(id, callback)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    getSearchResultsData: callback => dispatch(getSearchResultsData(callback)),
    storeAllStudentByCourseDetails: (key, val) => dispatch(storeAllStudentByCourseDetails(key, val)),
    setSelectedCourse: res => dispatch(setSelectedCourse(res)),
    storeFilterDetails: (key, val) => dispatch(storeFilterDetails(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllStudentsByCourses)