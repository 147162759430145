  class PDFManager {
      constructor(){
        this.pdfjsLib = window['pdfjs-dist/build/pdf'];
        this.pdf = null;
      }
      async loadPDF(pdfUrl){
        this.pdfUrl = pdfUrl;
        return new Promise((resolve, reject)=>{
            const loadingTask = this.pdfjsLib.getDocument(pdfUrl).promise.then((obj)=>{
                this.pdf = obj
                resolve(obj.numPages)
            });
            // this.pdf = await loadingTask.promise;
            // return this.pdf
        })
      }
      async renderPDFOnCanvas(pageNo, canvas){
        const page = await this.pdf.getPage(pageNo);
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        // Get the PDF page's viewport at scale 1 (no scaling)
        const unscaledViewport = page.getViewport({ scale: 1 });
      
        // Get the available width and height from the container
        const availableWidth = canvas.parentElement.clientWidth;
        const availableHeight = canvas.parentElement.clientHeight;
      
        // Calculate scale to fit the page in the available space, while maintaining aspect ratio
        const widthRatio = availableWidth / unscaledViewport.width;
        const heightRatio = availableHeight / unscaledViewport.height;
        const scale = Math.min(widthRatio, heightRatio);
      
        // Get the scaled viewport
        const scaledViewport = page.getViewport({ scale });
      
        // Adjust for device pixel ratio (to prevent blur)
        const outputScale = window.devicePixelRatio || 1;
      
        // Set the canvas width and height based on the scaled viewport and pixel ratio
        canvas.width = scaledViewport.width * outputScale;
        canvas.height = scaledViewport.height * outputScale;
      
        // Set the CSS display size for the canvas (this keeps the visible size correct)
        canvas.style.width = `${scaledViewport.width}px`;
        canvas.style.height = `${scaledViewport.height}px`;
      
        // Scale the context to account for the pixel ratio
        context.scale(outputScale, outputScale);
      
        // Render the PDF page into the canvas
        const renderContext = {
          canvasContext: context,
          viewport: scaledViewport,
        };
        return new Promise((resolve, reject)=>{
          page.render(renderContext).promise.then(()=>{
            canvas.toBlob((blob)=>{
              if (blob) {
                const imgURL = URL.createObjectURL(blob);
                resolve(imgURL)
              } else {
                reject();
              }
            })
          }).catch((e)=>{
            reject(e);
          })
        })
      }
      async renderEmptyFrameOnCanvas(canvas){
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);      
        const outputScale = window.devicePixelRatio || 1;
        canvas.width = 180;
        canvas.height = 320;
        canvas.style.width = `180px`;
        canvas.style.height = `320px`;
        context.scale(outputScale, outputScale);
        return new Promise((resolve, reject)=>{
            canvas.toBlob((blob)=>{
              if (blob) {
                const imgURL = URL.createObjectURL(blob);
                resolve(imgURL)
              } else {
                reject();
              }
            })
        })
      }
  }

export default new PDFManager();
