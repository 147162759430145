import { Button, Modal } from 'antd';
import React, { useState } from 'react'

function ConfirmationModal({ isConfirmationModalVisible, setConfirmationModalVisible, title, description, cancelText, okText, exitOnClick, saveOnClick, loadingProp}) {
    const handleCancel = () => {
        setConfirmationModalVisible(false);
    };
    return (
        <>
            <Modal
                className="modal-round-corner"
                centered
                visible={isConfirmationModalVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
                header={null}
                footer={null}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "25px",
                    }}
                >
                    <div
                        style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}
                    >
                        {title}
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            color: "#636363",
                            fontWeight: "bold",
                            marginTop: "10px",
                            textAlign: 'center'
                        }}
                    >
                        {description}

                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                border: "1px solid  #636363",
                                backgroundColor: "#FFFFFF",
                                color: "#594099",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#636363",
                                    fontWeight: "bold",
                                }}
                                onClick={exitOnClick}
                            >
                                {cancelText}
                            </div>
                        </Button>
                    </div>
                    <div>
                        <Button
                            loading={loadingProp}
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                border: "1px solid #594099 ",
                                backgroundColor: "#594099",
                                fontSize: "16px",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                            }}
                            onClick={saveOnClick}
                        >
                            {okText}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ConfirmationModal