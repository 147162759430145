import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  fetchTeacherData,
  fetchAdminLibraryData,
  updateSetSort,
  fetchAdminChapterFilter,
  updateSetTopic,
  updateChapterFilter,
  toogleEditModal,
  deleteNotes,
  currentFileToShow,
  uploadContent,
  currentDocument,
  currentTypes,
  postEngagementHistory,
  IsHSL,
} from "actions/digitalLibrary";

import { connect } from "react-redux";
import EditInformation from "../modals/editInformation";
import { Api } from "services";
import { useHistory } from "react-router-dom";
import { Image, Typography } from "antd";
import videoThumbImg from 'Assets/digitalLibrary/videoThumbnail.png'

function TeachAndStudVideo({
  url,
  topic,
  chapterName,
  id,
  fileSize,
  toogleEditModal,
  deleteNotes,
  types,
  std,
  section,
  subject,
  currentFileToShow,
  uploadContent,
  currentDocument,
  currentTypes,
  postEngagementHistory,
  user,
  file,
  IsHSL,item
}) {
  useEffect(() => {
    //fetchTeacherData()
    //fetchAdminLibraryData()
    //fetchAdminChapterFilter()
  }, []);
  const handleDeletenotes = (id) => {
    deleteNotes(id);
  };

  const history = useHistory();

  const handleNotes = (id) => {
    IsHSL(true)
    uploadContent({ filePath: file ? file : url });
    console.log("documentInfo",item?.link);
    if(item?.link){
      currentFileToShow('link')
    }else{
      currentFileToShow("video");

    }
    currentDocument(id);
    currentTypes(types);
    if (
      user.access.includes("digital-library-teacher") ||
      user.access.includes("digital-library-student")
    ) {
      // if(user.role.includes("Teacher")||user.role.includes("Student")){
      let arr = [];
      arr[0] = id;
      postEngagementHistory({ libraryIds: arr, type: "engagement" });
    }
    history.push("/digital-library/view-content");
  };

  return (
    <div className="videocomponentmain"  onClick={() => handleNotes(id)} style={{cursor:"pointer",marginRight:"20px"}}>
      {/* <ReactPlayer
        width={"90%"}
        style={{ margin: "10px" }}
        height={"140px"}
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        // Disable right click
        onContextMenu={e => e.preventDefault()}
        url={file ? `${Api.hlsUrl}${file}` : url}
        controls={true}
        // light={true}
      /> */}
      <Image
      style={{height:"150px",width:"300px"}}
        src={videoThumbImg}
        preview={false}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: "8px",
        }}
      >
        <div style={{ width: "90%", paddingLeft: "10px" }}>
          <p
            className="lecturenotestitle cursor-pointer"
            id="contentofvideocomponent"
            style={{ fontSize: "16px" }}
           
          >
            {chapterName}
          </p>
          {/* <p className="lecturnotesdescription">{topic}</p> */}
          <Typography.Text className="lecturnotesdescription" style={{width:"150px"}} ellipsis={{tooltip:true}}>{topic}</Typography.Text>
          {/* <p className='lecturenotessize'>{fileSize}</p> */}
          <p style={{ color: "#AEAEAE", fontSize: "12px" }}>
            {subject} | {std}: {section}
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    updateChapterFilter,
    documentInfo,
  } = state.digitalLibrary;
  const { user } = state.session;
  return {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    updateChapterFilter,
    user,
    documentInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  fetchAdminLibraryData: () => dispatch(fetchAdminLibraryData()),
  updateChapterFilter: (value) => dispatch(updateChapterFilter(value)),
  fetchAdminChapterFilter: () => dispatch(fetchAdminChapterFilter()),
  toogleEditModal: (bool) => dispatch(toogleEditModal(bool)),
  deleteNotes: (id) => dispatch(deleteNotes(id)),
  currentFileToShow: (data) => dispatch(currentFileToShow(data)),
  uploadContent: (object) => dispatch(uploadContent(object)),
  currentDocument: (id) => dispatch(currentDocument(id)),
  currentTypes: (type) => dispatch(currentTypes(type)),
  postEngagementHistory: (object) => dispatch(postEngagementHistory(object)),
  IsHSL: (bool) => dispatch(IsHSL(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeachAndStudVideo);
