import React from 'react'
import defaultBannerIc from "../../Assets/aulasMarket/banner-default-img.svg"
function DefaultBanner({ orgName, defaultBannerState,bannerUrl }) {
    console.log("defaultBannerState", defaultBannerState)
    return (
        <div className="default-banner-bgc banner-zoom" style={{ height: "400px", width: "100%", position: "relative", padding: 40 }}>
            {defaultBannerState ?<div style={{cursor:"default"}} /* onClick={()=> window.open(bannerUrl, "_blank")} */><img  src={defaultBannerIc} style={{ height: "350px", width: "100%" }} /></div>  : <>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div className='default-banner-child1' style={{ color: "#191919", fontWeight: "bold", width: "60%", display: "flex", justifyContent: "center", flexDirection: "column", }}>
                        <div className='default-banner-title-font' style={{ marginTop: -20 }}>{orgName} is now digital</div>
                        <div className='m-t-10 default-banner-desc-font'>Check out the latest that we have to offer</div>
                    </div>
                    <div><img src={require("../../Assets/userMarketPlace/defaultBanner.svg").default} style={{ height: "90%", width: "100%" }} /></div>
                </div>
            </>}
        </div>
    )
}

export default DefaultBanner