import React from 'react'

export function NoDataCard({
    containerheight = "80%",
    imgwidth = "40%",
    imgheight = "40%",
  }) {
    return (
      <div className="r-c-c-c" style={{ height: containerheight }}>
        <img
          style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
          src={require("../../../Assets/test/no-subjects.svg").default}
          alt=""
        />
        <div className="r-c-c-c">
          <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
          <p style={{ color: "#636363", fontFamily: "roboto" }}>
            There is no data to show yet. Please check back later.
          </p>
        </div>
      </div>
    );
  }

export default NoDataCard