const Styles = {
  adminCardContainer: {
    margin: 5, //to remove
    padding: 20,
    // width: '100%',
    border: 0,
    borderRadius: '10px 10px ',
    height:'100px',
  },
  adminCardContainerBackground: {
    margin: 5,
    borderRadius: '0px 15px',
  },

};

export default Styles;
