import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { getOffersCarouselData, setOffersBarVisible } from 'actions/studentMarketPlace';
import { Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LinesCarousel } from './lineCarousel';

function MobileDiscountOffersCarousel({getOffersCarouselData,offersCarouselData, setOffersBarVisible}) {
  const [reset, setReset] = useState(false);
  const [values, setValues] = useState(0);
  const [isCopied, setIsCopied] = useState(false);

  const setCustomValue = (value) => {
    setValues(value);
  }
  const length = offersCarouselData?.length;
  useEffect(() => {
    if (!reset) {
      const mount = setInterval(() => {
        setValues((prev) => {
          if (prev == length - 1) {
            return prev - (length - 1);
          }
          else {
            return prev + 1;
          }
        })
      }, 5000)
      return () => clearInterval(mount);
    }
  }, [reset])

  useEffect(()=>{
    getOffersCarouselData()
  },[])

  const handleCopyCode = (code) => {
    const tempInput = document.createElement('input');
    tempInput.value = code;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setIsCopied(true); 
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  return (
    <div>
        <div className='home-discount-mobile-div'>
                  <LinesCarousel>
                    <Row style={{ height: 120 }}>
                      <div className='carousal' onMouseEnter={() => { setCustomValue(values); setReset(!reset) }}
                        onMouseLeave={() => setReset(!reset)}>
                        <div className='discount-mobile'>
                          <div style={{ display: "flex", justifyContent: "space-between", color: "#594099", fontWeight: "bold", fontSize: 16, alignItems: "center" }}>
                            <div>Discount</div>
                            <CloseOutlined style={{ color: "#FFFFFF", fontSize: 16, marginTop: -10 }} onClick={() => { setOffersBarVisible(false) }} />
                          </div>
                          <div className='r-c-sb'>
                            <div style={{ backgroundColor: "#FFFFFF", color: "#594099", padding: "3px 5px", fontSize: 12, width: "fit-content", color: "#594099" }}>Valid until {offersCarouselData?.length ? moment(offersCarouselData?.[values]?.endDate).format("DD-MM-YYYY") : null}</div>
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#594099" }}>{offersCarouselData?.length && offersCarouselData?.[values]?.discountType === "percent" ? `${offersCarouselData?.[values]?.discount}%` : `Rs. ${offersCarouselData?.[values]?.discount}`} off</div>
                          </div>
                          <div style={{ marginTop: 3, color: "#594099" }}><span>Use the promotional offer</span><span style={{ fontWeight: "bold" }}> {offersCarouselData?.[values]?.code}</span>
                            {isCopied ? <span className='text-16'> Copied!</span> :
                              <CopyOutlined style={{ fontSize: 18, marginLeft: 5, cursor: "pointer" }} onClick={() => { handleCopyCode(offersCarouselData?.[values]?.code) }} />
                            }
                            <span> to get an instant discount</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    {offersCarouselData?.length > 1 ?
                      <Row className='arrow-button'>
                        <div className='lines'>
                          {offersCarouselData?.map((ele, index) => {
                            return <div className={values === index ? 'blueline' : 'greyline'} onClick={() => { setCustomValue(index); setReset(!reset);setIsCopied(false); }}
                              onMouseLeave={() => setReset(!reset)}></div>
                          })}
                        </div>
                      </Row>
                      :
                      <></>}
                  </LinesCarousel>
                </div>
    </div>
  )
}
const mapStateToProps = (state) => {
    const {offersCarouselData } = state.studentMarketPlace;
    return { offersCarouselData};
  };
  
  const mapDispatchToProps = (dispatch) => ({
    getOffersCarouselData: () => dispatch(getOffersCarouselData()),
    setOffersBarVisible: (val) => dispatch(setOffersBarVisible(!!val)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(MobileDiscountOffersCarousel);