import { ArrowLeftOutlined } from '@ant-design/icons';
import { setCurrentRecordingPath, setSelectedRecordingsIndex } from 'actions/broadcast';
import React from 'react'
import ReactPlayer from 'react-player';
import {connect} from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactPlayerHoc from 'components/ReactPlayerHoc';

function PlayRecordings ({currentRecordingsPath, recordingsData, setCurrentRecordingPath,   
                        setSelectedRecordingsIndex, selectedRecordingsIndex, cardData, user}) {
    const history = useHistory();
    return(
        <div className="display-flex full-width recordingsPage" style={{height:"100%", width:"100%"}}>
            {/* <div style={{width:"30%", overflowY:"scroll"}} className="recordingsPageDataWrapper">
                {
                    recordingsData?.map((recording, index)=>(
                        <RecordingsCard name={recording.fileName}
                        key={recording.streamName} 
                        duration={recording.duration}
                        fileSize={recording.fileSize}
                        filePath={recording.url}
                        setCurrentRecordingPath={setCurrentRecordingPath}
                        history={history}
                        isFromModal={false}
                        index = {index}
                        setSelectedRecordingsIndex={setSelectedRecordingsIndex}
                        selectedIndex={selectedRecordingsIndex}
                        />
                    ))
                }
            </div> */}
            {console.log("currentRecordingsPath",currentRecordingsPath)}
            <div className="full-height" style={{width: "100%", height:"100%"}}>
                <div style={{width:"100%", padding:20, fontWeight:'700', fontSize:20}}>
                    <ArrowLeftOutlined onClick={()=>{
                        history.goBack();
                    }} style={{ fontSize: 20, marginRight:20 }} className="cursor-pointer"/>
                    {cardData.recordingName?cardData.recordingName:'Lecture video'}
                </div>
            <ReactPlayerHoc
            width={"100%"}
            height={"85%"}
            style={{width:"100%", height:"100%"}} 
            config={{ file: (user.privilage?.includes('SuperAdmin') || user.privilage?.includes('Admin')) ?{}: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
            onContextMenu={e => e.preventDefault()}
            url={currentRecordingsPath}
            controls={true}
            keyboardControls={true}
              /> 
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const {currentRecordingsPath, recordingsData, selectedRecordingsIndex} = state.broadcast;
    const {cardData} = state.schedule;
    const {user} = state.session;
    return {
        currentRecordingsPath, recordingsData, selectedRecordingsIndex,
        cardData,
        user
    }
}

const mapDispatchToProps = dispatch => ({
  setCurrentRecordingPath: (filepath) => dispatch(setCurrentRecordingPath(filepath)),
  setSelectedRecordingsIndex: (index) => dispatch(setSelectedRecordingsIndex(index))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayRecordings);