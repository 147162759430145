import React from 'react'
import { connect } from 'react-redux';
import AnnouncementAdmin from './announcementAdmin'


const Announcement = ({ user }) => {

    if (user.access.includes('announcement')) {
        return (
            <div>
                <AnnouncementAdmin />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
    } = state.announcement;
    const { user } = state.session;
    return {
        user
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Announcement);
