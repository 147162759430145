import {
  POST_TEST_CREATE,
  UPDATE_PIECHART_DATA,
  UPDATE_CLASSCARD_DATA,
  SET_SELECTED_CLASSROOM,
  UPDATE_RESULT_TO_PUBLISH,
  UPDATE_PUBLISHED_RESULT,
  UPDATE_TEST_TO_PUBLISH,
  STORE_CREATE_TEST_MODAL_DATA,
  GET_EXAMINER_NAME,
  GET_EXAMINER_LOADER,
  GET_SUBJECTS_NAME,
  GET_SUBJECTS_LOADER,
  GET_EVALUATION_PAGE_LOADER,
  REMOVE_SELECTED_SEARCHED_SUBJS,

  UPDATE_SUBMITTED_TESTS,
  UPDATE_SUBMITTED_TESTS_DETAILS,
  UPDATE_SUBMITTED_TESTS_USERS,
  UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID,
  UPDATE_CURRENT_QUESTION_INDEX,
  UPDATE_SUBMITTED_TESTS_ID,
  UPDATE_SUBMITTED_TESTS_SUBMITTED_ID,
  UPDATE_EVALUATED_MARK,
  UPDATE_EVALUATED_COMMENT,
  UPDATE_QUESTION_GRIDS_ARRAY,
  UPDATE_EVALUATION_QUESTION_ID,
  UPDATE_EVALUATION_QUESTION_DATA,
  UPDATE_POST_EVALUATION_OBJECT,

  UPDATE_ONGOING_TEST,
  UPDATE_DRAFTS,
  UPDATE_BARCHART_DATA,
  SET_SELECTED_CLASSROOM_ID,
  UPDATE_SUBJECTS_BY_CLASS,
  SET_SELECTED_SUBJECT,
  UPDATE_STANDARDWISE_BARCHART_DATA,
  UPDATE_TODAY_ACTIVITY,
  UPDATE_UPCOMING_TESTS,
  UPDATE_NO_DATA,
  UPDATE_STANDARDS_LIST,
  GET_TEST_NAME_ARR,
  UPDATE_STUDENT_RESULT,
  UPDATE_ONE_STUDENT_RESULT,
  UPDATE_SELECTED_TEST,
  UPDATE_SELECTED_STUDENT,
  GET_RESULTS_LOADER,
  UPDATE_CANCELLED_TESTS,
  UPDATE_SELECTED_TEST_ID,
  SET_C_MODAL_TYPE,
  SET_EDIT_MODAL_VISIBLE,
  SET_C_MODAL_VISIBLE,
  TOGGLE_RELOADER_FLAG,
  GET_INDEX_OF_TEST,
  CLEAR_TEST_CREATE_OBJ,
  STORE_GENERATE_QUESTIONS_OBJ,
  SHOW_TEXT_EDITOR,
  UPDATE_SUB_WITH_CHAPTERS,
  ADD_MARKS_FOR_QUES,
  RENDER_EDITOR_CONTENT,
  CHOOSE_CORRECT_ANSWER_ADMIN,
  STORE_TEST_QUES_TYPE,
  POST_QUESTION_CREATE,
  UPDATE_LIST_OF_QUESTIONS,
  STORE_EDITOR_REMOVED_IMGS,
  STORE_EDITOR_ADDED_IMGS,
  DELETE_QUILL_IMGS,
  CLEAR_GENERATE_QUES_FIELDS,
  RESET_GENERATE_QUES_FIELDS,
  STORE_EDIT_GENERATE_QUE_ID,
  GET_EACT_QUES_DATA_LOADER,
  GENERATE_QUES_LIST_VIEW_LOADER,
  STORE_GENERATE_QUES_LIST_VIEW,
  CHANGE_TEST_STATUS_LOADER,
  CHANGE_TEST_STATUS_MODAL,
  CREATE_TEST_MODAL,
  UNSET_CREATE_TEST_MODAL_BOOL,
  UPDATE_LISTVIEW_TYPE,
  UPDATE_FEEDBACK_DATA,
  UPDATE_READY_TO_PUBLISH,
  UPDATE_ROLE,
  UPDATE_FEEDBACK_FROM_TEACHER,
  UPDATE_DATA_OPERATOR_STATS,
  TESTING,
  UPDATE_TESTS_TO_REVIEW,
  UPDATE_RESULTS_DATA,
  UPDATE_SELECTED_CARD_TYPE,
  UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS,
  UPDATE_TOTAL_MARKS_OBTAINED,
  UPDATE_PALETTE_DATA,
  UPDATE_NUMERIC_ANSWER,
  UPDATE_POST_PARAMS2,
  SET_OPEN_TAB,
  UPDATE_STD_FOR_CREATING_TEST,
  SET_DASHBOARD_META,
  UPDATE_CHAPTER_FOR_CREATING_TEST,
  REMOVE_SELECTED_CHAPTER,
  UPDATE_SYLLABUS,
  UPDATE_FILTER,
  SET_CHAPTERS_LIST,
  SET_LOADER,
  SET_IMPORT_META,
  UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST,
  UPDATE_TODAY_RESULTS,
  SET_SELECTED_SECTION_ID,
  CHOOSE_BOOL_ANSWER,
  SET_SUBJECT_SECTION,
  STORE_MARKING_SCHEME_DATA,
  UPDATE_QUESTION_TYPE,
  STORE_QUESTION_INDEX,
  STORE_SECTION_INDEX,
  STORE_MARKING_SCHEME_CARD_DATA,
  SEARCH_FILTER,
  STORE_MARKING_SCHEME_TITLE,
  STORE_MARKING_SCHEME_DESC,
  STORE_MARKING_SCHEME_SUBJECTIVE,
  STORE_MARKING_SCHEME_OBJECTIVE,
  STORE_MARKED_SCHEME_CARD_ID,
  MARKED_SCHEME_CARD_DETAILS,
  // SET_SELECTED_SECTION_ID,
  // CHOOSE_BOOL_ANSWER,
  // SET_SUBJECT_SECTION,
  // STORE_MARKING_SCHEME_DATA,
  CREATE_ADDITIONAL_QUESTION,
  STORE_SCHEME_ID,
  FROM_CREATE_NEW_BUTTON,
  SET_FROM_SECTION_MARKING_SCHEME,
  ADD_MARKING_SCHEME_MARKS,
  TOTAL_QUES_IN_CHAPTER,
  STORE_DELETING_CHAPTER_ID,
  STORE_IMPORT_TEST_ID,
  STORE_SUBJECT_NAME,
  STORE_LIST_VIEW_DATA,
  STORE_PAGE_NUMBER,
  STORE_SUBJECT_ID,
  STORE_QUESTION_PALETTE_DATA,
  STORE_CHAPTERS,
  MODIFY_OPTIONS,
  SET_FROM_IMPORT_LIST_VIEW,
  SAVE_START_TIME
} from "actions/testAdmin";
import { filter } from "lodash";
import moment from "moment";


const initialState = {
  universalLoaderBool: false,
  piedata: {},
  classTestInfo: {},
  selectedClassroom: null,
  resultsToPublish: [],
  publishedResults: [],
  submittedTests: [],
  submittedTestsDetails: [],
  submittedTestsByStudentId: {},
  currentQuestionIndex: 0,
  submittedTestsUsers: false,
  testsToPublish: [],
  testCreateObj: { quesType: '', title: '', tIndex: '', class: '', heldDate: '', heldStartTime: '', heldEndTime: '', resultDate: '', resultTime: '', instruction: '', testType: '', teacherName: '', teacherId: '', subjectName: [], subjectId: [], standardId: [], syllabus: null, chapterList: [], totalQuestions: 0 },
  getExaminerArr: [],
  getExaminerBool: false,
  getSubjectsArr: [],
  getSubjectsBool: false,
  getEvaluationPageBool: false,

  submittedTestsId: 0,
  submittedTestsStudentsId: 0,
  evaluatedMark: 0,
  evaluatedComment: "",
  evaluationPageQuestionGrid: [],
  evaluationQuestionId: {},
  evaluationQuestionData: {},

  ongoingTests: [],
  drafts: [],
  barchartData: [],
  selectedClassroomId: null,
  subjectsByClass: [],
  selectedSubject: {},
  standardWiseBarChartData: [],
  isBarChartEmpty: false,
  todayActivities: {},
  upcomingTests: [],
  standards: [],
  getTestNameBool: false,
  TestNameArr: [],
  testStudentResult: {},
  selectedStudentResult: {},
  selectedTest: {},
  selectedStudent: {},
  submittedTests: [],
  getResultsBool: false,
  cancelledTests: [],
  selectedTestId: null,
  isCModalVisible: false,
  isEditModalVisible: false,
  cModalType: null,
  reloadToggler: false,
  getIndexOfTestBool: false,
  stdsForCreatingTest: [],
  generateQuesObj: {},
  textEditorBool: false,
  generateQuesSubChapters: {},
  quesPositiveMark: 0,
  quesNegativeMark: 0,
  generateQuesText: '',
  generateSolutionText: '',
  generateParagraphText: '',
  textEditorName: '',
  identifierIndex: '',
  identifierName: '',
  generateQuesOptions: [{ id: 0, value: '' }, { id: 1, value: '' }, { id: 2, value: '' }, { id: 3, value: '' }],
  generateQuesSelectedTestType: {},
  generateQuesSelectedChapter: {},
  postQuestionCreateBool: false,
  generateQuesRemovedImgs: [],
  generateQuesaddedImgs: [],
  deleteQuillImgsBool: false,
  generateQuesArr: {},
  generateQuesNumericText: '',
  editGenerateQueId: '',
  getEachQuesDataBool: false,
  generateQuesListViewBool: false,
  generateQuesListViewData: {},
  changeTestStatusBool: false,
  changeTestStatusModalBool: false,
  modalActiveName: '',
  createTestModalBool: false,
  createTestModalName: '',
  listViewType: '',
  sentForFeedback: [],
  feedbackFromTeacher: [],
  userType: 'admin',
  readyToPublish: [],
  generateCommentText: '',
  operatorStats: {},
  testing: '',
  editDetailsLoader: false,
  cloneLoader: false,
  selectedCardType: '',
  absentStudents: [],
  totalMarksObtained: 0,
  reRenderPalette: false,
  reRenderLegend: false,
  numericAnswer: '',
  postParams: {},
  openTab: 'draft',
  totalQuestions: 0,
  createTestModalSource: '',
  dashBoardMeta: {

  },
  chaptersForCreatingTest: [],
  syllabi: [],
  filter: {
    taDate: null,
    isTAdatefiltered: false,
    TAclasses: [],
    TAsyllabus: [],
    TAsearch: null,
    TApage: -1,
    VAtype: null,
    VAclasses: [],
    VAsearch: null,
    VAsyllabus: [],
    VApage: -1,
    isVA: false,
    vaDate: null,
    isVAdatefiltered: false,
    selectedSyllabus: null,
    subjectSearch: null,
    chapterSearch: null,
    isTabPage: false,
    tabSearch: null,
    selectedChapter: {},
    tabPage: -1,
    selectedGraphFilter: 'week',
    graphStartDate: moment().startOf("week").format("YYYY-MM-DD"),
    graphEndDate: moment().startOf("week").add(6, "days").format("YYYY-MM-DD"),
    graphYear: moment().format('YYYY'),
    standardGraphStartDate: moment().startOf("week").format("YYYY-MM-DD"),
    standardGraphEndDate: moment().startOf("week").add(6, "days").format("YYYY-MM-DD"),
    standardSelectedGraphFilter: 'week',
    standardGraphYear: moment().format('YYYY'),
    testTypeSelector: null,
    questionContent: '',
    answerContent: ''
  },
  orQuestionIndicator: false,
  compQuestionIndicator: false,
  chapterList: [],
  loaders: {
    tabPageLoader: false,
    vaPageLoader: false,
    taPageLoader: false,
    chapterPageLoader: false,
    subjectPageLoader: false,
    indexLoader: false,
    graphLoader: false,
    syllabusPageLoader: false,
    modalClassLoader: false,
    modalChapterLoader: false,
    modalSubjectLoader: false,
    updateQuestionLoader:false,
  },
  importMeta: {
    questionSelections: {

    },
    selectedTopic: {

    },
    topicsList: {

    }
  },
  groupedChapters: {

  },
  searchedGroupedChapters: {

  },
  todayResults: [],
  selectedSectionId: null,
  storeSubjectSection: [],
  storeMarkingSchemeRes: [],
  questionIndexStore: '',
  sectionIndexStore: '',

  markingSchemeCardDataContainer: {},
  searchedData: null,
  markingSchemeTitle: null,
  markingSchemeDescription: null,
  subjectiveTypeContainer: [],
  objectiveTypeContainer: [],
  markedSchemeCardIdContainer: null,
  cardDetailsContainer: [],
  selectedSectionId: null,
  storeSubjectSection: [],
  storeMarkingSchemeRes: [],
  isFromCreateNewButton: false,
  isFromSectionMarkingScheme: false,
  schemeId: null,
  totalQuesChapterData: null,
  deletingChapterId: null,
  importTestIdContainer: null,
  subjectNameContainer: [],
  listViewDataContainer: [],
  pageNumber: 1,
  subjectIdContainer: null,
  questionPaletteDataConatiner: [],
  chaptersArray: [],
  numberOfOptions: -1,
  isFromImportListView : false,
  savetestStartTime:''
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TESTING:
      console.log("123456");
      return {
        ...state,
        testing: action.data
      }
    case UPDATE_PIECHART_DATA:
      return {
        ...state,
        pieData: { ...action.pieData }
      };
    case UPDATE_CLASSCARD_DATA:
      return {
        ...state,
        classTestInfo: action.classTestInfo
      }
    case SET_SELECTED_CLASSROOM:
      return {
        ...state,
        selectedClassroom: action.newSelection
      }
    case UPDATE_RESULT_TO_PUBLISH:
      return {
        ...state,
        resultsToPublish: action.data ? action.data : [],
        // stdsForCreatingTest: action.data.standards
      }
    case UPDATE_PUBLISHED_RESULT:
      return {
        ...state,
        publishedResults: action.data
      }
    case UPDATE_SUBMITTED_TESTS:
      return {
        ...state,
        submittedTests: action.data
      }
    case UPDATE_SUBMITTED_TESTS_DETAILS:
      return {
        ...state,
        submittedTestsDetails: action.value
      }
    case UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID:
      return {
        ...state,
        submittedTestsByStudentId: action.value
      }
    case UPDATE_QUESTION_GRIDS_ARRAY:
      return {
        ...state,
        evaluationPageQuestionGrid: action.value
      }
    case UPDATE_EVALUATION_QUESTION_ID:
      return {
        ...state,
        evaluationQuestionId: action.value
      }
    case UPDATE_POST_EVALUATION_OBJECT:
      const evaluationQuestionIdNew = state.evaluationQuestionId
      const evaluationPageQuestionGridNew = state.evaluationPageQuestionGrid
      evaluationPageQuestionGridNew?.map((data) => {
        if (data.id === evaluationQuestionIdNew.id) {
          evaluationQuestionIdNew.id = data.id;
          evaluationQuestionIdNew.answerStatus = data.answerStatus;
          evaluationQuestionIdNew.submitStatus = data.submitStatus;
        }
      })
      return {
        ...state,
        evaluationQuestionId: { ...evaluationQuestionIdNew }
      }
    case UPDATE_EVALUATION_QUESTION_DATA:
      return {
        ...state,
        evaluationQuestionData: action.value
      }
    case UPDATE_CURRENT_QUESTION_INDEX:
      return {
        ...state,
        currentQuestionIndex: action.value
      }
    case UPDATE_SUBMITTED_TESTS_ID:
      return {
        ...state,
        submittedTestsId: action.value
      }
    case UPDATE_SUBMITTED_TESTS_SUBMITTED_ID:
      return {
        ...state,
        submittedTestsStudentsId: action.value
      }
    case UPDATE_EVALUATED_MARK:
      return {
        ...state,
        evaluatedMark: action.value
      }
    case UPDATE_EVALUATED_COMMENT:
      return {
        ...state,
        evaluatedComment: action.value
      }
    case SET_FROM_IMPORT_LIST_VIEW :
      return {
        ...state,
        isFromImportListView : action.val
      }
    case UPDATE_SUBMITTED_TESTS_USERS:
      return {
        ...state,
        submittedTestsUsers: action.data
      }
    case UPDATE_TEST_TO_PUBLISH:
      return {
        ...state,
        testsToPublish: action.data
      }
    // case CLEAR_LIVE_FILTER_PARAMS:
    //   return {
    //     ...state,
    //     liveFilterParams: {...initialState.liveFilterParams}
    //   };

    case STORE_CREATE_TEST_MODAL_DATA:
      let testCreateObjNew = state.testCreateObj;
      if (action.key == 'quesType') {
        testCreateObjNew.quesType = action.value;
      } else if (action.key === 'title') {
        testCreateObjNew.title = action.value;
      } else if (action.key === 'tIndex') {
        testCreateObjNew.tIndex = action.value;
      } else if (action.key === 'class') {
        testCreateObjNew.class = action.value;
        if (action.value === '') {
          testCreateObjNew.standardId = [];
        }
      } else if (action.key === 'heldDate') {
        testCreateObjNew.heldDate = action.value;
      } else if (action.key === 'heldStartTime') {
        testCreateObjNew.heldStartTime = action.value;
      } else if (action.key === 'heldEndTime') {
        testCreateObjNew.heldEndTime = action.value;
      } else if (action.key === 'resultDate') {
        testCreateObjNew.resultDate = action.value;
      } else if (action.key === 'resultTime') {
        testCreateObjNew.resultTime = action.value;
      } else if (action.key === 'instruction') {
        testCreateObjNew.instruction = action.value;
      } else if (action.key === 'testType') {
        testCreateObjNew.testType = action.value;
      } else if (action.key === 'examiner') {
        testCreateObjNew.teacherName = action.value.name;
        testCreateObjNew.teacherId = action.value.id;
      } else if (action.key === 'syllabus') {
        testCreateObjNew.syllabus = action.value
      } else if (action.key === 'subjects') {
        if (testCreateObjNew.subjectName?.length) {
          if (!testCreateObjNew.subjectName.includes(action.value.name ) && action.value.name) {
            testCreateObjNew.subjectName.push(action.value.name);
            testCreateObjNew.subjectId.push(action.value.id);
          }
        } else {
          testCreateObjNew.subjectName.push(action.value.name);
          testCreateObjNew.subjectId.push(action.value.id);
        }
      }
      else if (action.key === 'clearsubjects') {
        testCreateObjNew.subjectName = [];
        testCreateObjNew.subjectId = [];
      } else if (action.key === 'chapters') {
        if (testCreateObjNew.chapterList?.length) {
          if (!testCreateObjNew.chapterList?.map((item) => item.id)?.includes(action.value.id)) {
            testCreateObjNew.chapterList.push(action.value);
          }
        } else {
          testCreateObjNew.chapterList.push(action.value);
        }
      } else if (action.key === 'clearchapters') {
        testCreateObjNew.chapterList = [];
      } else if (action.key === 'removesubjectchapters') {
        let id = testCreateObjNew.subjectId[action.value];
        testCreateObjNew.chapterList = testCreateObjNew.chapterList?.filter((item) => item.subjectId != id);
      }
      else if (action.key === 'standardId') {
        testCreateObjNew.standardId = [action.value];
      } else if (action.key === "subjectsArray") {
        testCreateObjNew.subjects = []
      }


      return {
        ...state,
        testCreateObj: { ...testCreateObjNew }
      };
    case CLEAR_TEST_CREATE_OBJ:
      return {
        ...state,
        testCreateObj: { title: '', tIndex: '', class: '', heldDate: '', heldStartTime: '', heldEndTime: '', resultDate: '', resultTime: '', instruction: '', testType: '', teacherName: '', teacherId: '', subjectName: [], subjectId: [], standardId: [], syllabus: null, chapterList: [], totalQuestions: 0 },
        generateQuesArr: {},
      };
    case REMOVE_SELECTED_SEARCHED_SUBJS:
      let testCreateObjRemove = state.testCreateObj;
      if (action.text === 'removeAll') {
        testCreateObjRemove.subjectName.length = 0;
        testCreateObjRemove.subjectId.length = 0;
      } else {
        testCreateObjRemove.subjectName?.splice(action.index, 1,);
        testCreateObjRemove.subjectId?.splice(action.index, 1);
      }
      return {
        ...state,
        testCreateObj: { ...testCreateObjRemove },
        getSubjectsArr: action.text === 'removeAll' ? [] : [...state.getSubjectsArr]
      };
    case REMOVE_SELECTED_CHAPTER:
      let testCreateObjRemoveChapter = state.testCreateObj;
      testCreateObjRemoveChapter.chapterList = testCreateObjRemoveChapter.chapterList?.filter((item) => item.id != action.id);
      return {
        ...state,
        testCreateObj: { ...testCreateObjRemoveChapter },
      };
    case STORE_EDIT_GENERATE_QUE_ID:
      return {
        ...state,
        editGenerateQueId: action.id
      };
    case GET_EXAMINER_NAME:
      return {
        ...state,
        getExaminerArr: action.res
      };
    case GET_SUBJECTS_NAME:
      return {
        ...state,
        getSubjectsArr: action.res
      };
    case UPDATE_ONGOING_TEST:
      return {
        ...state,
        ongoingTests: action.ongoingTests
      }
    case UPDATE_DRAFTS:
      return {
        ...state,
        drafts: action.drafts
      }
    case UPDATE_BARCHART_DATA:
      return {
        ...state,
        barchartData: action.data
      }
    case SET_SELECTED_CLASSROOM_ID:
      return {
        ...state,
        selectedClassroomId: action.data
      }
    case UPDATE_SUBJECTS_BY_CLASS:
      return {
        ...state,
        subjectsByClass: action.data
      }
    case SET_SELECTED_SUBJECT:
      return {
        ...state,
        selectedSubject: action.data
      }
    case UPDATE_STANDARDWISE_BARCHART_DATA:
      return {
        ...state,
        standardWiseBarChartData: action.data
      }
    case UPDATE_TODAY_ACTIVITY:
      return {
        ...state,
        todayActivities: action.data
      }
    case UPDATE_UPCOMING_TESTS:
      return {
        ...state,
        upcomingTests: action.data
      }
    case UPDATE_NO_DATA:
      return {
        ...state,
        isBarChartEmpty: action.data
      }
    case UPDATE_STANDARDS_LIST:
      return {
        ...state,
        standards: action.data
      }
    case UPDATE_STUDENT_RESULT:
      return {
        ...state,
        testStudentResult: action.data
      }
    case UPDATE_ONE_STUDENT_RESULT:
      return {
        ...state,
        selectedStudentResult: action.data
      }
    case UPDATE_SELECTED_TEST:
      return {
        ...state,
        selectedTest: action.data,
      }
    case UPDATE_SELECTED_STUDENT:
      return {
        ...state,
        selectedStudent: action.data
      }
    case UPDATE_SUBMITTED_TESTS:
      return {
        ...state,
        submittedTests: action.data
      }
    case UPDATE_CANCELLED_TESTS:
      return {
        ...state,
        cancelledTests: action.data
      }
    case UPDATE_SELECTED_TEST_ID:
      return {
        ...state,
        selectedTestId: action.data
      }
    case SET_C_MODAL_TYPE:
      return {
        ...state,
        cModalType: action.data
      }
    case SET_C_MODAL_VISIBLE:
      return {
        ...state,
        isCModalVisible: action.data
      }
    case SET_EDIT_MODAL_VISIBLE:
      return {
        ...state,
        isEditModalVisible: action.data
      }
    case TOGGLE_RELOADER_FLAG:
      return {
        ...state,
        reloadToggler: !state.reloadToggler
      }
    case GET_TEST_NAME_ARR:
      return {
        ...state
      };
    case STORE_GENERATE_QUESTIONS_OBJ:
      let subjArr = (action.res.subjectNames != null && action.res.subjectNames?.length > 1) ? action?.res?.subjectNames : action?.res?.subjects;
      let subChapters = {};
      let pseudoTestType;
      if (action.res.syllabus === 'Chapter Test') {
        subChapters = {
          color: action?.res?.chapters[0]?.color,
          id: action?.res?.chapters[0]?.id,
          name: action?.res?.chapters[0]?.name
        }
        subChapters.chapters = []
        subChapters?.chapters?.push({ title: action?.res?.chapters[0]?.chapters[0]?.name, value: action?.res?.chapters[0]?.chapters[0]?.name, id: action?.res?.chapters[0]?.chapters[0]?.id })
        const sectionData = action?.res?.subjects?.find(item => item?.subName === subChapters?.name)
        console.log('sectionData', sectionData)

        if (action.res.testType === 'Subjective') {
          pseudoTestType = { 'key': 0, 'value': 0, 'children': action.res.testType }
          console.log('ssadsad', pseudoTestType)
        }

        return {
          ...state,
          generateQuesObj: action?.res,
          generateQuesSubChapters: { ...subChapters },
          generateQuesSelectedChapter: { key: action?.res?.chapters[0]?.chapters[0]?.id, value: action?.res?.chapters[0]?.chapters[0]?.id, children: action?.res?.chapters[0]?.chapters[0]?.name },
          selectedSectionId: (sectionData && sectionData?.sections !== undefined) ? sectionData?.sections[0]?.sectionId : null,
          schemeId: (sectionData && sectionData?.sections !== undefined) ? sectionData?.sections[0]?.schemeId : sectionData?.schemeId,
          generateQuesSelectedTestType: pseudoTestType?.children === 'Subjective' ? pseudoTestType : {},

        };
      } else {
        // action.res.chapters?.map((item, index) => {
        //   if (item.name === subjArr[index]) {
        //     subChapters = {'color':item.color,'id':item.id,'name':item.name};
        //     subChapters.chapters = []
        //     item.chapters.map((chap) => {
        //       subChapters.chapters.push({ title: chap.name, value: chap.name, id: chap.id })
        //     })
        //   }
        // })
        const found = action.res.chapters?.find(item => item.name === action.res.subjects?.[0]?.subName);
        subChapters = {
          color: found?.color,
          id: found?.id,
          name: found?.name,
          chapters: found?.chapters?.map(chap => {
            return {
              title: chap.name,
              value: chap.name,
              id: chap.id
            }
          })
        }
        if (action.res.testType === 'Subjective') {
          pseudoTestType = { 'key': 0, 'value': 0, 'children': action.res.testType }
          console.log('ssadsad', pseudoTestType)
        }
        const sectionData = action.res.subjects?.find(item => item.subName === found?.name)
        console.log('datata', sectionData)
        return {
          ...state,
          generateQuesObj: action.res,
          generateQuesSubChapters: { ...subChapters },
          selectedSectionId: (sectionData && sectionData?.sections !== undefined) ? sectionData?.sections[0]?.sectionId : null,
          schemeId: (sectionData && sectionData?.sections !== undefined) ? sectionData?.sections[0]?.schemeId : sectionData?.schemeId,
          generateQuesSelectedTestType: pseudoTestType?.children === 'Subjective' ? pseudoTestType : {},
        };
      }
    case UPDATE_SUB_WITH_CHAPTERS:
      let subChaptersNew = {};
      state.generateQuesObj.chapters?.map((item, index) => {
        if (item.name === action.name) {
          subChaptersNew = { 'color': item.color, 'id': item.id, 'name': item.name };
          subChaptersNew.chapters = []
          item.chapters.map((chap) => {
            subChaptersNew.chapters.push({ title: chap.name, value: chap.name, id: chap.id })
          })
        }
      })
      return {
        ...state,
        generateQuesSubChapters: state?.generateQuesObj?.syllabus === 'Chapter Test' ? state?.generateQuesSubChapters : { ...subChaptersNew },
      };

    case CLEAR_GENERATE_QUES_FIELDS:
      return {
        ...state,
        generateQuesSelectedTestType: state.generateQuesObj?.testType === 'Subjective' ? state.generateQuesSelectedTestType : {},
        generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {},
        quesPositiveMark: state.generateQuesObj?.testType === 'Subjective' && state.schemeId > 0 ? state.quesPositiveMark : 0,
        quesNegativeMark: state.generateQuesObj?.testType === 'Subjective' && state.schemeId > 0 ? state.quesNegativeMark : 0,
        generateQuesText: '',
        generateSolutionText: '',
        generateParagraphText: '',
        generateQuesNumericText: '',
        numericAnswer: '',
        textEditorName: '',
        generateQuesOptions: [{ id: 0, value: '' }, { id: 1, value: '' }, { id: 2, value: '' }, { id: 3, value: '' }],
        editGenerateQueId: action.val === 'Clear' ? state.editGenerateQueId : null,
        generateQuesRemovedImgs: [],
        generateCommentText: '',
        questionContent: '',
        answerContent: '',
        orQuestionIndicator: false,
        compQuestionIndicator: false,
        selectedSectionId: action.val === 'Clear' || action.val === 'Post' ? state.selectedSectionId : null,
        schemeId: action.val === 'Clear' || action.val === 'Post' || state.schemeId > 0 ? state.schemeId : null,
        questionIndexStore: '',
        numberOfOptions: -1,
      };
    case RESET_GENERATE_QUES_FIELDS:
      let resetSubChapters = {};
      let resetTestType = {};
      let orQuestionIndicatorVar = false;
      let compQuestionIndicatorVar = false;
      let numberOfOptionsVar
      state.generateQuesObj.chapters?.map((item, index) => {
        if (item.name === action.res.subjectName) {
          resetSubChapters = { 'color': item.color, 'id': item.id, 'name': item.name };
          resetSubChapters.chapters = []
          item.chapters.map((chap) => {
            resetSubChapters.chapters.push({ title: chap.name, value: chap.name, id: chap.id })
          })
        }
      })
      // if(action.res.type === 'MCQ' && action.res.type === 'T/F'){
      //   resetTestType = {key:0, value:0, children:'Multiple Choice Questions'}
      // } else if(action.res.type === 'Numeric'){
      //   resetTestType = {key:1, value:1, children:action.res.type}
      // } else {}
      if (action.res.type === 'MCQ') {
        orQuestionIndicatorVar = false,
          compQuestionIndicatorVar = false,
          resetTestType = { key: 0, value: 0, children: 'MCQ' }
      } else if (action.res.type === 'MSQ') {
        orQuestionIndicatorVar = false,
          compQuestionIndicatorVar = false,
          resetTestType = { key: 0, value: 0, children: 'MSQ' }
        numberOfOptionsVar = action.res.maxOptionCount
      } else if (action.res.type === 'OrQuestions') {
        compQuestionIndicatorVar = false
        if (action.res.questionContent.orQuestions) {
          orQuestionIndicatorVar = true
          if (action.res.questionContent.orQuestions[0].type === 'T/F') {
            resetTestType = { key: 1, value: 1, children: 'T/F' }
          } else if (action.res.questionContent.orQuestions[0].type === 'MCQ') {
            resetTestType = { key: 0, value: 0, children: 'MCQ' }
          } else if (action.res.questionContent.orQuestions[0].type === 'MSQ') {
            resetTestType = { key: 0, value: 0, children: 'MSQ' }
          } else if (action.res.questionContent.orQuestions[0].type === 'Numeric') {
            resetTestType = { key: 2, value: 2, children: 'Numeric' }
          } else {
            resetTestType = {}
          }
        }
      }
      else if (action.res.type === 'T/F') {
        orQuestionIndicatorVar = false,
          compQuestionIndicatorVar = false,
          resetTestType = { key: 1, value: 1, children: 'T/F' }
      } else if (action.res.type === 'Numeric') {
        orQuestionIndicatorVar = false,
          compQuestionIndicatorVar = false,
          resetTestType = { key: 2, value: 2, children: 'Numeric' }
      } else if (action.res.type === 'Comprehensive') {
        orQuestionIndicatorVar = false
        if (action.res.questionContent.subQuestions) {
          compQuestionIndicatorVar = true
          if (action.res.questionContent.subQuestions[0].type === 'T/F') {
            resetTestType = { key: 1, value: 1, children: 'CTF' }
          } else if (action.res.questionContent.subQuestions[0].type === 'MCQ') {
            resetTestType = { key: 0, value: 0, children: 'CMCQ' }
          } else if (action.res.questionContent.subQuestions[0].type === 'MSQ') {
            resetTestType = { key: 0, value: 0, children: 'CMSQ' }
            numberOfOptionsVar = action.res.maxOptionCount
          } else if (action.res.questionContent.subQuestions[0].type === 'Numeric') {
            resetTestType = { key: 2, value: 2, children: 'CNumeric' }
          } else {
            resetTestType = {}
          }
        }
      } else if (action.res.type === 'Subjective') {
        resetTestType = { 'key': 0, 'value': 0, 'children': 'Subjective' }

      }
      else {
        orQuestionIndicatorVar = false,
          compQuestionIndicatorVar = false,
          resetTestType = {}
      }
      // else if(action.res.type === 'Numeric'){
      //   resetTestType = {key:1, value:1, children:action.res.type}
      // }else if(action.res.type === 'T/F'){
      //   resetTestType = {key:2, value:2, children:action.res.type}
      // }else if(action.res.type === 'MSQ'){
      //   resetTestType = {key:3, value:3, children:action.res.type}
      // }

      console.log('prarara', action.res.questionContent)
      return {
        ...state,
        generateQuesSubChapters: resetSubChapters,
        generateQuesSelectedTestType: resetTestType,
        generateQuesSelectedChapter: { key: action.res.chapterId, value: action.res.chapterId, children: action.res.chapterName },
        quesPositiveMark: action.res.positiveMarks ? action.res.positiveMarks : 0,
        quesNegativeMark: action.res.negativeMarks ? action.res.negativeMarks : 0,
        generateQuesText: action.res.questionContent.statement,
        questionContent: action.res.questionContent,
        answerContent: action.res.answerContent,
        generateSolutionText: action.res.solution,
        generateParagraphText: action.res.questionContent.paragraph ? action.res.questionContent.paragraph : '',
        generateQuesNumericText: action.res.type === 'Numeric' ? action.res.answerContent.answer : '',
        numericAnswer: action.res.type === 'Numeric' ? action.res.answerContent.answer : '',
        // textEditorName: '',
        generateQuesOptions: action.res.type === 'MCQ' ? action.res.options : [{ id: 0, value: '' }, { id: 1, value: '' }, { id: 2, value: '' }, { id: 3, value: '' }],
        editGenerateQueId: action.res.id,
        generateCommentText: action.res?.remark == null ? '' : action.res.remark,
        orQuestionIndicator: orQuestionIndicatorVar,
        compQuestionIndicator: compQuestionIndicatorVar,
        numberOfOptions: Number(numberOfOptionsVar) > 0 ? Number(numberOfOptionsVar) : -1,
      };
    case ADD_MARKS_FOR_QUES:
      if (action.index !== undefined && action.index !== null) {
        console.log('index', action.index)
        let quesData = state.questionContent?.subQuestions
        let positiveNo = quesData[action.index]?.positiveMarks;
        let negativeNo = quesData[action.index]?.negativeMarks;

        if (action.name === 'positive') {
          if (action.operation === 'increment') {
            positiveNo = positiveNo + 1;
          } else {
            if (positiveNo !== 0) {
              positiveNo = positiveNo - 1;
            }
          }
          quesData[action.index].positiveMarks = positiveNo;
        } else {
          if (action.operation === 'increment') {
            negativeNo = negativeNo + 1;
          } else {
            if (negativeNo !== 0) {
              negativeNo = negativeNo - 1;
            }
          }
          quesData[action.index].negativeMarks = negativeNo;
        }



        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            subQuestions: [...quesData]
          }

        }


      } else {
        let positiveNo = state.quesPositiveMark;
        let negativeNo = state.quesNegativeMark;
        if (action.name === 'positive') {
          if (action.operation === 'increment') {
            positiveNo = positiveNo + 1;
          } else {
            if (positiveNo !== 0) {
              positiveNo = positiveNo - 1;
            }
          }
        } else {
          if (action.operation === 'increment') {
            negativeNo = negativeNo + 1;
          } else {
            if (negativeNo !== 0) {
              negativeNo = negativeNo - 1;
            }
          }
        }
        return {
          ...state,
          quesPositiveMark: positiveNo,
          quesNegativeMark: negativeNo,
        };

      }
    case RENDER_EDITOR_CONTENT:
      let commentText
      let textEditorNew
      let numericText
      let quesData
      let quesText
      let paragraphText
      if (state.identifierName === 'Comp') {
        quesData = state.questionContent.subQuestions
        console.log('datatat', quesData[state?.identifierIndex])
        console.log('solllll', quesData)
        commentText = state.generateCommentText;
        textEditorNew = state.textEditorName;
        numericText = state.generateQuesNumericText;
        paragraphText = state.generateParagraphText
        console.log('paraOut', paragraphText)
        if (action.name.key === 'question') {
          quesData[state.identifierIndex].questionContent.statement = action.value;
          quesText = action.value;
        } else if (action.name.key === 'options') {
          quesData[state.identifierIndex].questionContent.options?.map((item, index) => {
            if (index === action.name.index) {
              item.value = action.value;
            }
          })
        } else if (action.name.key === 'solution') {
          quesData[state.identifierIndex].solution = action.value;
        }
        else if (action.name.key === 'comment') {
          commentText = action.value;
        } else if (action.name.key === 'paragraph') {
          paragraphText = action.value
          console.log('para', paragraphText)
        } else {
          numericText = action.value;
        }
        textEditorNew.tags = action.value;

        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            paragraph: paragraphText,
            subQuestions: [...quesData]
          },
          generateQuesText: quesText,
          // answerContent:{
          //   ...state.answerContent,
          //   answer: soluText
          // },
          // generateSolutionText: soluText,
          generateParagraphText: paragraphText,
          generateQuesNumericText: numericText,
          // numericAnswer: state.numericAnswer,
          // generateQuesOptions: [...quesOption],
          textEditorName: { ...textEditorNew },
          generateCommentText: commentText
        };

      } else if (state.identifierName === 'Or') {
        quesData = state.questionContent.orQuestions
        console.log('datatat', quesData[state.identifierIndex])
        console.log('solllll', quesData)
        commentText = state.generateCommentText;
        textEditorNew = state.textEditorName;
        numericText = state.generateQuesNumericText;
        if (action.name.key === 'question') {
          quesData[state.identifierIndex].questionContent.statement = action.value;
          quesText = action.value;
        } else if (action.name.key === 'options') {
          quesData[state.identifierIndex].questionContent.options?.map((item, index) => {
            if (index === action.name.index) {
              item.value = action.value;
            }
          })
        } else if (action.name.key === 'solution') {
          quesData[state.identifierIndex].solution = action.value;
        }
        else if (action.name.key === 'comment') {
          commentText = action.value;
        } else {
          numericText = action.value;
        }
        textEditorNew.tags = action.value;

        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            orQuestions: [...quesData]
          },
          // generateQuesText: quesText,
          // answerContent:{
          //   ...state.answerContent,
          //   answer: soluText
          // },
          // generateSolutionText: soluText,
          generateQuesText: quesText,
          generateQuesNumericText: numericText,
          // numericAnswer: state.numericAnswer,
          // generateQuesOptions: [...quesOption],
          textEditorName: { ...textEditorNew },
          generateCommentText: commentText
        };
      } else {
        let quesText = state.questionContent.statement;
        let soluText = state.generateSolutionText
        numericText = state.generateQuesNumericText;
        let quesOption = state.questionContent?.options ?? null;
        textEditorNew = state.textEditorName;
        commentText = state.generateCommentText;
        if (action.name.key === 'question') {
          quesText = action.value;
        } else if (action.name.key === 'options') {
          quesOption?.map((item, index) => {
            if (index === action.name.index) {
              item.value = action.value;
            }
          })
        } else if (action.name.key === 'solution') {
          soluText = action.value;
        }
        else if (action.name.key === 'comment') {
          commentText = action.value;
        } else {
          numericText = action.value;
        }
        textEditorNew.tags = action.value;
        // console.log('quesText', quesText);
        // console.log('quesOption', quesOption);
        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            statement: quesText,
            options: quesOption ? [...quesOption] : null
          },
          generateQuesText: quesText,
          // answerContent:{
          //   ...state.answerContent,
          //   answer: soluText
          // },
          generateSolutionText: soluText,
          generateQuesNumericText: numericText,
          // numericAnswer: state.numericAnswer,
          // generateQuesOptions: [...quesOption],
          textEditorName: { ...textEditorNew },
          generateCommentText: commentText
        };
      }
    case CHOOSE_CORRECT_ANSWER_ADMIN:
      let quesOptionNew
      if (action.val === 'CMSQ') {
        quesOptionNew = state.questionContent.subQuestions
        quesOptionNew?.map((item, index) => {
          if (index === action.identifier) {
            item.questionContent.options.map((data, index) => {
              if (index === action.index) {
                data.correct = !data.correct;
              }
            })
          }
        })

        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            subQuestions: [...quesOptionNew]
          }
          // generateQuesOptions: [...quesOptionNew],
        }
      } else if (action.val === 'MSQ') {
        if (action.identifier !== null && action.identifier !== undefined) {
          quesOptionNew = state.questionContent.orQuestions
          quesOptionNew?.map((item, index) => {
            if (index === action.identifier) {
              item.questionContent.options.map((data, index) => {
                if (index === action.index) {
                  data.correct = !data.correct;
                }
              })
            }
          })

          return {
            ...state,
            questionContent: {
              orQuestions: [...quesOptionNew]
            }
            // generateQuesOptions: [...quesOptionNew],
          }

        } else {
          quesOptionNew = state.questionContent.options;
          quesOptionNew?.map((item, index) => {
            if (index === action.index) {
              item.correct = !item.correct;
            }
          })

          return {
            ...state,
            questionContent: {
              ...state.questionContent,
              options: [...quesOptionNew]
            }
            // generateQuesOptions: [...quesOptionNew],
          }
        }
      } else if (action.val === 'CMCQ') {
        quesOptionNew = state.questionContent.subQuestions
        quesOptionNew?.map((item, index) => {
          if (index === action.identifier) {
            item.questionContent.options.map((data, index) => {
              if (index === action.index) {
                data.correct = true;
              } else {
                data.correct = false;
              }
            })
          }
        })

        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            subQuestions: [...quesOptionNew]
          }
          // generateQuesOptions: [...quesOptionNew],
        }
      } else if (action.val === 'MCQ') {
        if (action.identifier !== null && action.identifier !== undefined) {
          quesOptionNew = state.questionContent.orQuestions
          quesOptionNew?.map((item, index) => {
            if (index === action.identifier) {
              item.questionContent.options.map((data, index) => {
                if (index === action.index) {
                  data.correct = true;
                } else {
                  data.correct = false;
                }
              })
            }
          })

          return {
            ...state,
            questionContent: {
              orQuestions: [...quesOptionNew]
            }
            // generateQuesOptions: [...quesOptionNew],
          }
        } else {
          quesOptionNew = state.questionContent.options;
          quesOptionNew?.map((item, index) => {
            if (index === action.index) {
              item.correct = true;
            } else {
              item.correct = false;
            }
          })

          return {
            ...state,
            questionContent: {
              ...state.questionContent,
              options: [...quesOptionNew]
            }
          }
        }
      }
    // if (action.val === 'MSQ' || action.val === 'CMSQ') {
    //   quesOptionNew = state.questionContent.options;
    //   quesOptionNew?.map((item, index) => {
    //     if (index === action.index) {
    //       item.correct = !item.correct;
    //     }
    //   })
    // } else {
    //   quesOptionNew = state.questionContent.options;
    //   quesOptionNew?.map((item, index) => {
    //     if (index === action.index) {
    //       item.correct = true;
    //     } else {
    //       item.correct = false;
    //     }
    //   })
    // }
    // return {
    //   ...state,
    //   questionContent: {
    //     ...state.questionContent,
    //     options: [...quesOptionNew]
    //   }
    //   // generateQuesOptions: [...quesOptionNew],
    // };

    case CHOOSE_BOOL_ANSWER:

      if (state.orQuestionIndicator || state.compQuestionIndicator) {
        // quesBoolAns=state.answerContent[action.index].answer
        // if(action.val !== quesBoolAns){
        //   quesBoolAns= !quesBoolAns
        // }

        const newAnswerContent = state.answerContent.map((el, index) => {
          if (index === action.index) {
            return { answer: action.bool }
          }
          return el;
        })

        return {
          ...state,
          answerContent: newAnswerContent
        }

      } else {
        let quesBoolAns = state.answerContent.answer;
        if (action.val !== quesBoolAns) {
          quesBoolAns = action.bool
        }
        return {
          ...state,
          answerContent: {
            ...state.answerContent,
            answer: quesBoolAns
          }
        }

      }


    case STORE_TEST_QUES_TYPE:
      let selectedtestType = state.generateQuesSelectedTestType;
      let selectedChapter = state.generateQuesSelectedChapter;
      if (action.name === 'quesType') {
        selectedtestType = action.obj;
      } else {
        selectedChapter = action.obj;
      }
      console.log('chapterInfo', selectedtestType)
      return {
        ...state,
        generateQuesSelectedTestType: { ...selectedtestType },
        generateQuesSelectedChapter: { ...selectedChapter }
      };
    case SHOW_TEXT_EDITOR:
      let resetQesImgs = Array.from(new DOMParser().parseFromString(action.name.tags, 'text/html')
        .querySelectorAll('img'))
        .map(img => img.getAttribute('src'));

      return {
        ...state,
        textEditorBool: !state.textEditorBool,
        textEditorName: action.name,
        identifierIndex: (action.index !== null && action.index !== undefined) ? action.index : '',
        identifierName: action.val ? action.val : '',
        generateQuesaddedImgs: !state.textEditorBool ? resetQesImgs : []
      };
    case STORE_EDITOR_REMOVED_IMGS:
      return {
        ...state,
        generateQuesRemovedImgs: action.arr,
      };
    case STORE_EDITOR_ADDED_IMGS:
      return {
        ...state,
        generateQuesaddedImgs: action.arr,
      };
    case UPDATE_LIST_OF_QUESTIONS:
      let totQues = 0;
      // let pseudoSchemeId = null;
      for (const subject of action?.res?.subjects) {
        if (subject.includeSectionFlag) {
          for (const section of subject?.sections) {
            totQues += section?.questions?.length ?? 0;
            // pseudoSchemeId = section?.schemeId
          }
        } else {
          totQues += subject?.questions?.length ?? 0;
          // pseudoSchemeId = subject?.schemeId
        }
      }
      console.log('updatelist of questions', action.res)
      console.log('total questions', totQues)

      return {
        ...state,
        generateQuesArr: action.res,
        // generateQuesArr: {
        //   ...state.generateQuesArr,
        //   subjects: action.res.subjects,
        //   res: action.res.res,
        // },
        totalQuestions: totQues,
        // schemeId: pseudoSchemeId,
      };
    case STORE_GENERATE_QUES_LIST_VIEW:
      return {
        ...state,
        generateQuesListViewData: action.res,
      };
    case CHANGE_TEST_STATUS_MODAL:
      return {
        ...state,
        changeTestStatusModalBool: !state.changeTestStatusModalBool,
        modalActiveName: action.name,
      };
    case CREATE_TEST_MODAL:
      let lvtype = state.listViewType;
      let userTypeNew = state.userType;
      let testOldData = state.generateQuesObj;
      let testStandrds = state.stdsForCreatingTest;
      let testObjReset = state.testCreateObj;
      let selectedClassroom = state.selectedClassroom;
      const reformat_chapters = (chapters) => {
        let reformatted_chapters = [];
        console.log("chapters123", chapters)
        for (let subject_index of chapters) {
          if (subject_index?.chapters?.length !== 0) {
            for (let chapter of subject_index.chapters) {
              console.log("chapters1234", chapter)

              reformatted_chapters.push({ ...chapter, subjectId: subject_index.id });
            }
          }
        }
        console.log("chapters12345", reformatted_chapters)
        return reformatted_chapters;
      }

      if ((action.name === 'test-edit') || (action.name === 'test-clone')) {
        console.log("chapters1", moment(testOldData.testDate).format('DD/MM/YYYY'))
        testObjReset.class = (lvtype === "edit" || lvtype === "clone") ? testOldData.standardNames : '';
        testObjReset.standardId = action.source === 'class' ? [selectedClassroom.id] : testOldData.standardIds;
        testObjReset.subjectName = testOldData.subjectNames;
        testObjReset.subjectId = testOldData.subjectIds?.split(',');
        testObjReset.tIndex = testOldData.index;
        testObjReset.teacherName = userTypeNew == "teacher" ? action.user?.name : testOldData.teacherName;
        testObjReset.teacherId = userTypeNew == "teacher" ? action.user?.id : testOldData.teacherId;
        testObjReset.title = testOldData.title;
        testObjReset.instruction = testOldData.instructions;
        testObjReset.testType = testOldData.testType;
        testObjReset.resultTime = testOldData.resultTime;
        testObjReset.heldStartTime = testOldData.startTime;
        testObjReset.heldEndTime = testOldData.endTime;
        testObjReset.resultDate = testOldData.resultDate ? moment(testOldData.resultDate).format('DD/MM/YYYY') : testOldData.resultDate;
        testObjReset.heldDate = testOldData.testDate ? moment(testOldData.testDate).format('DD/MM/YYYY') : testOldData.testDate;
        testObjReset.syllabus = testOldData.syllabus === 'Full Test' ? 'Full' : testOldData.syllabus === 'Chapter Test' ? 'Chapter' : testOldData.syllabus === 'Part Test' ? 'Part' : '';
        testObjReset.chapterList = testOldData.syllabus === 'Full Test' ? [] : reformat_chapters(testOldData.chapters);
        testObjReset.totalQuestions = testOldData.totalQuestions;
        testObjReset.standardAutocomplete = testOldData.standardAutocomplete ? testOldData.standardAutocomplete : [],
        testObjReset.includeSectionFlag = testOldData.includeSectionFlag
      }
      // let chapterList = [];
      // if(action.name === 'test-create' && action.source === 'chapter'){
      //   // chapterList = state.chapterList;
      //   const {chapters} = state.groupedChapters?.[state.selectedSubject.subjectId];
      //   chapterList = chapters
      // }
      // console.log("AA-chapterList",chapterList);

      return {
        ...state,
        generateQuesSelectedTestType: action.source == "navigation" ? state.generateQuesSelectedTestType : state.generateQuesSelectedTestType,
        createTestModalBool: !state.createTestModalBool,
        createTestModalName: action.name,
        testCreateObj: ((action.name === 'test-edit') || (action.name === 'test-clone'))
          ?
          { ...testObjReset }
          :
          {
            standardId: action?.source === 'class' || action?.source === 'syllabus' || action?.source === 'subject' || action?.source === 'chapter' ? [selectedClassroom?.id] : [],
            title: '',
            tIndex: '',
            class: action?.source === 'class' || action?.source === 'syllabus' || action?.source === 'subject' || action?.source === 'chapter' ? selectedClassroom?.classname + ' - ' + selectedClassroom?.section : '',
            heldDate: '',
            heldStartTime: '',
            heldEndTime: '',
            resultDate: '',
            resultTime: '',
            instruction: '',
            testType: '',
            teacherName: userTypeNew == "teacher" ? action.user?.name : '',
            teacherId: userTypeNew == "teacher" ? action.user?.id : '',
            subjectName: action.source === 'chapter' || (action.source === 'subject' && state.selectedSubject.isCombined === false) ? [state.selectedSubject?.subjectName] : [],
            subjectId: action.source === 'chapter' || (action.source === 'subject' && state.selectedSubject.isCombined === false) ? [state.selectedSubject.subjectId] : [],
            syllabus: action.source === 'syllabus' || action.source === 'subject' || action.source === 'chapter' ? state.filter?.selectedSyllabus?.split(' ')[0] : null,
            chapterList: action.source === 'chapter' && state.filter?.selectedChapter?.chapterId
              ? [{ id: state.filter.selectedChapter.chapterId, name: state.filter.selectedChapter.chapterName, subjectId: state.selectedSubject.subjectId }]
              : [],
            totalQuestions: 0
          },
        createTestModalSource: action.source,
        groupedChapters: action.name === 'test-create' ? {} : state.groupedChapters,
        searchedGroupedChapters: action.name === 'test-create' ? {} : state.groupedChapters
      };
    case UNSET_CREATE_TEST_MODAL_BOOL:
      return {
        ...state,
        createTestModalBool: false
      }
    case UPDATE_LISTVIEW_TYPE:
      return {
        ...state,
        listViewType: action.data
      }
    case UPDATE_FEEDBACK_DATA:
      return {
        ...state,
        feedbackFromTeacher: action.res.feedbackFromTeacher,
        sentForFeedback: action.res.sentForFeedback
      }
    case UPDATE_FEEDBACK_FROM_TEACHER:
      console.log("updating fedeback in reducer 1389");
      return {
        ...state,
        feedbackFromTeacher: action.data
      }
    case UPDATE_TESTS_TO_REVIEW:
      return {
        ...state,
        sentForFeedback: action.data
      }
    case UPDATE_READY_TO_PUBLISH:
      return {
        ...state,
        readyToPublish: action.data
      }
    case UPDATE_DATA_OPERATOR_STATS:
      return {
        ...state,
        operatorStats: action.data
      }
    case UPDATE_ROLE:
      let inferredType = '';
      if (action.user.access.includes("test-admin") || action.user.access.includes("test-superadmin")) {
        console.log('admin on test')
        inferredType = 'admin'
      }
      else if (action.user.access.includes("dataoperator-test")) {
        inferredType = 'dataOperator'
      }
      else if (action.user.access.includes("teacher-test")) {
        console.log('teacher on test')

        inferredType = 'teacher'
      }

      console.log("inferred type is ", inferredType);
      return {
        ...state,
        userType: inferredType
      }
    case UPDATE_RESULTS_DATA:
      return {
        ...state,
        resultsToPublish: action.data
      }
    case UPDATE_SELECTED_CARD_TYPE:
      return {
        ...state,
        selectedCardType: action.data
      }
    case UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS:
      return {
        ...state,
        absentStudents: action.data
      }
    case UPDATE_TOTAL_MARKS_OBTAINED:
      return {
        ...state,
        totalMarksObtained: action.marks
      }
    case UPDATE_PALETTE_DATA:
      // let newQuestionGrid = [];
      let newSubmittedtestbyid = state.submittedTestsByStudentId;
      function getvar(val) {
        if (val == "Correct") {
          return "correctCount"
        }
        else if (val == "PartialCorrect") {
          return "partialCorrectCount"
        }
        else if (val == "Incorrect") {
          return "inCorrectCount"
        }
        else if (val == "None") {
          return "notEvaluatedCount"
        }
      }
      for (let subject of state.evaluationPageQuestionGrid) {
        for (let question of subject.questions) {
          if ((question.index - 1) === action.qIndex) {
            question.answerStatus = action.newType
            newSubmittedtestbyid[getvar(question.answerStatus)] -= 1
            newSubmittedtestbyid[getvar(action.newType)] += 1
          }
        }
      }
      return {
        ...state,
        submittedTestsByStudentId: newSubmittedtestbyid,
        reRenderPalette: !state.reRenderPalette,
        reRenderLegend: !state.reRenderLegend
      }
    case UPDATE_NUMERIC_ANSWER:
      let newAnswer
      if (action.index !== null && action.index !== undefined) {
        newAnswer = state.answerContent.map((item, index) => {
          if (index === action.index) {
            item.answer = action.newAnswer
          }
          return item
        })

        console.log('newAnswer', newAnswer)

        return {
          ...state,
          answerContent: [...newAnswer]
        }
      } else {
        newAnswer = state.answerContent
        return {
          ...state,
          answerContent: {
            ...state.answerContent,
            answer: action.newAnswer
          }
        }
      }
    case UPDATE_POST_PARAMS2:
      return {
        ...state,
        postParams: action.newParams
      }
    case SET_OPEN_TAB:
      return {
        ...state,
        openTab: action.tabName
      }
    case UPDATE_STD_FOR_CREATING_TEST:
      return {
        ...state,
        stdsForCreatingTest: action.classes
      }
    case SET_DASHBOARD_META:
      return {
        ...state,
        dashBoardMeta: action.val
      }
    case UPDATE_CHAPTER_FOR_CREATING_TEST:
      return {
        ...state,
        chaptersForCreatingTest: action.chapters
      }
    case UPDATE_SYLLABUS:
      return {
        ...state,
        syllabi: action.res
      }
    case UPDATE_FILTER:
      let newfilter = { ...state.filter }
      newfilter[action.key] = action.val;
      return {
        ...state,
        filter: newfilter
      }
    case SET_CHAPTERS_LIST:
      return {
        ...state,
        chapterList: action.res
      }
    case SET_LOADER:
      let newloader = { ...state.loaders };
      newloader[action.key] = action.val;
      return {
        ...state,
        loaders: newloader
      }
    case SET_IMPORT_META:
      let newImportMeta = { ...state.importMeta }
      if (action.key === 'questionSelections') {
        newImportMeta.questionSelections[action.key] = action.value;
      } else if (action.key === 'setImportClassroom') {
        newImportMeta.classCardData = action.value
      } else if (action.key === 'setImportSampleQuestionPapers') {
        newImportMeta.sampleQuestionPaperList = action.value
      } else if (action.key === 'setSampleQuestionTree') {
        newImportMeta.sampleQuestionTree = action.value
      } else if (action.key === 'clearSelectionTree') {
        newImportMeta.selectionTree = [];
        newImportMeta.marksList = [];
      } else if (action.key === 'setSelectionTree') {
        let topicIdList = newImportMeta.selectionTree?.map((item) => item.topicId);
        for (const topic of action.value) {
          if (!(topicIdList.includes(topic.topicId))) {
            newImportMeta.selectionTree.push({ ...topic, selectedQuestions: [], selectAllBool: false })
          }
        }
      } else if (action.key === 'setSelectionTreeQuestionSelection') {
        const { topicId, selections } = action.value;
        const result = {};
        newImportMeta?.topicwiseQuestionsList.forEach(question => {
          const { id, type, questionContent } = question;
          if (type === "Comprehensive") {
            const subQuestionLength = questionContent.subQuestions.length;
            result[id] = `${type}-${subQuestionLength}`;
          } else {
            result[id] = type;
          }
        });
        for (const topic of newImportMeta.selectionTree) {
          if (topic.topicId === topicId) {
            for (let selection of selections) {
              if (!(selection in newImportMeta.marksList)) {
                const questionCheck = result[selection]?.split('-');
                const questionType = questionCheck[0];
                let subQuestionLength = 0;
                if (questionType == 'Comprehensive') {
                  subQuestionLength = parseInt(questionCheck[1])
                }
                //check questionType is comprehensive or not
                if (questionType == 'Comprehensive') {
                  newImportMeta.marksList[selection] = {}
                  let subQues = new Array(subQuestionLength).fill(
                    {
                      positiveMark: 0,
                      negativeMark: 0
                    }
                  )
                  console.log("selection",newImportMeta.marksList,selection)
                  Object.assign(newImportMeta.marksList[selection], {subQuestionMarks:subQues })
                  // newImportMeta.marksList[selection].subQuestionMarks = subQues
                  // newImportMeta.marksList[selection]["subQuestionMarks"] = Array(subQuestionLength).fill(
                  //   {
                  //     positiveMark: 0,
                  //     negativeMark: 0
                  //   }
                  // )
                } else {
                  newImportMeta.marksList[selection] = { positiveMark: 0, negativeMark: 0 }
                }
                // newImportMeta.marksList[selection] = { positiveMark: 0, negativeMark: 0 }
              }
            }
            topic.selectedQuestions = selections
            break
          }
        }
      } else if (action.key === 'setAllSelectBool') {
        const { topicId, bool } = action.value;
        for (const topic of newImportMeta.selectionTree) {
          if (topic.topicId === topicId) {
            topic.selectAllBool = bool
            break
          }
        }
      } else if (action.key === 'changeMark') {
        const { operation, markType, questionId, type, marksIndex, subQuestionLength} = action.value;
        if(type == 'Comprehensive'){
          console.log("newImportMeta123",newImportMeta,questionId,marksIndex)
          // if(newImportMeta.marksList[questionId]["subQuestionMarks"] == undefined){
          //   newImportMeta.marksList[questionId]["subQuestionMarks"] = Array(subQuestionLength).fill(
          //     {
          //       positiveMark : 0,
          //       negativeMark : 0
          //     }
          //   )
          // }
          let positiveMark = newImportMeta.marksList[questionId]["subQuestionMarks"][marksIndex]?.positiveMark;
          let negativeMark = newImportMeta.marksList[questionId]["subQuestionMarks"][marksIndex]?.negativeMark;
          if (markType === 'positive') {
            if (operation === 'increment') {
              positiveMark = positiveMark + 1;
            } else {
              if (positiveMark !== 0) {
                positiveMark = positiveMark - 1;
              }
            }
          } else {
            if (operation === 'increment') {
              negativeMark = negativeMark + 1;
            } else {
              if (negativeMark !== 0) {
                negativeMark = negativeMark - 1;
              }
            }
          }
          let tmp = JSON.parse(
            JSON.stringify(newImportMeta.marksList[questionId]["subQuestionMarks"])
          );
          tmp[marksIndex].positiveMark = positiveMark;
          tmp[marksIndex].negativeMark = negativeMark
          newImportMeta.marksList[questionId]["subQuestionMarks"] = tmp;
          // newImportMeta.marksList[questionId]["subQuestionMarks"][marksIndex].positiveMark = positiveMark;
          // newImportMeta.marksList[questionId]["subQuestionMarks"][marksIndex].negativeMark = negativeMark;
          console.log("newImportMeta123456",tmp,newImportMeta.marksList[questionId]["subQuestionMarks"])
        }else{
          let positiveMark = newImportMeta.marksList[questionId].positiveMark;
          let negativeMark = newImportMeta.marksList[questionId].negativeMark;
          if (markType === 'positive') {
            if (operation === 'increment') {
              positiveMark = positiveMark + 1;
            } else {
              if (positiveMark !== 0) {
                positiveMark = positiveMark - 1;
              }
            }
          } else {
            if (operation === 'increment') {
              negativeMark = negativeMark + 1;
            } else {
              if (negativeMark !== 0) {
                negativeMark = negativeMark - 1;
              }
            }
          }
          newImportMeta.marksList[questionId].positiveMark = positiveMark;
          newImportMeta.marksList[questionId].negativeMark = negativeMark;
        }

      }
      else {
        newImportMeta[action.key] = action.value
      }
      return {
        ...state,
        importMeta: newImportMeta
      }
    case UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST:
      let newGroupedChapters = {};
      let newSearchedGroupedChapters = {};
      if (!action.isSearch) {
        for (let chapter of action.chapterList) {
          if (chapter.subjectId in newGroupedChapters) {
            newGroupedChapters[chapter.subjectId].chapters.push({ ...chapter });
          } else {
            newGroupedChapters[chapter.subjectId] = {
              subjectId: chapter.subjectId,
              subjectName: chapter.subjectName, chapters: [{ ...chapter }]
            }
          }
        }
        newSearchedGroupedChapters = { ...newGroupedChapters };
      } else {
        newGroupedChapters = JSON.parse(JSON.stringify(state.groupedChapters))
        newSearchedGroupedChapters = { ...state.groupedChapters }
        newSearchedGroupedChapters[action.subjectId].chapters = action.chapterList
      }
      return {
        ...state,
        groupedChapters: newGroupedChapters,
        searchedGroupedChapters: newSearchedGroupedChapters
      }
    case UPDATE_TODAY_RESULTS:
      return {
        ...state,
        todayResults: action.res
      }
    case SET_SELECTED_SECTION_ID:
      return {
        ...state,
        selectedSectionId: action.val
      }
    // case ADD_NEW_QUESTIONS:
    //   let questionArr = state.generateQuesObj.subjects.map((item)=>{
    //     if(item.sections){
    //       item.sections.map((el)=>{
    //         if(action.value == el.sectionId){
    //           return el.questions
    //         }
    //       })
    //     }else{
    //       if(action.value == item.subjId){
    //         return item.questions
    //       }
    //     }
    //   })

    //   if(action.name === 'ADD'){
    //     questionArr = questionArr.push({id:id,index:action.index})
    //   }
    //   return{
    //     ...state,

    //   }
    case SET_SUBJECT_SECTION:
      return {
        ...state,
        storeSubjectSection: action.val
      }
    case STORE_MARKING_SCHEME_DATA:
      return {
        ...state,
        storeMarkingSchemeRes: action.res
      }
    case FROM_CREATE_NEW_BUTTON:
      return {
        ...state,
        isFromCreateNewButton: action.bool
      }

    case UPDATE_QUESTION_TYPE:
      let questionPseudo
      let answerPseudo
      let optionValue
      console.log('valueeeeeeeeeee', action.value)
      if (action.value === 'MCQ' || action.value === 'MSQ') {
        optionValue = action?.options?.noOfOptions ? action?.options?.noOfOptions : -1
        console.log('optNorm', optionValue)
        questionPseudo = {
          statement: null,
          options: (action.value === 'MSQ' && Number(optionValue) > 0) ? Array(Number(optionValue)).fill(null).map((u, i) => ({
            id: i,
            value: null,
            correct: false
          })) :
            [
              {
                id: 0,
                value: null,
                correct: false
              },
              {
                id: 1,
                value: null,
                correct: false
              },
              {
                id: 2,
                value: null,
                correct: false
              },
              {
                id: 3,
                value: null,
                correct: false
              }
            ]
        }
        answerPseudo = action.value === 'MCQ' ? { answer: null } : { answers: [] }

        return {
          ...state,
          compQuestionIndicator: false,
          orQuestionIndicator: false,
          quesNegativeMark: 0,
          quesPositiveMark: 0,
          questionContent: { ...questionPseudo },
          answerContent: { ...answerPseudo },
          generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {}
        }
      } else if (action.value === 'Numeric') {
        return {
          ...state,
          compQuestionIndicator: false,
          orQuestionIndicator: false,
          quesNegativeMark: 0,
          quesPositiveMark: 0,
          questionContent: {
            statement: null,
          },
          answerContent: { answer: null },
          generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {}
        }
      } else if (action.value === 'T/F') {
        return {
          ...state,
          compQuestionIndicator: false,
          orQuestionIndicator: false,
          quesNegativeMark: 0,
          quesPositiveMark: 0,
          questionContent: {
            statement: null,
          },
          answerContent: {
            answer: null
          },
          generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {}
        }
      } else if (action.value === 'CMCQ' || action.value === 'CMSQ') {
        optionValue = action?.options?.noOfOptions ? action?.options?.noOfOptions : -1,
          console.log('optComp', optionValue)
        questionPseudo = {
          statement: null,
          options: (action.value === 'CMSQ' && Number(optionValue) > 0) ? Array(Number(optionValue)).fill(null).map((u, i) => ({
            id: i,
            value: null,
            correct: false
          })) :
            [
              {
                id: 0,
                value: null,
                correct: false
              },
              {
                id: 1,
                value: null,
                correct: false
              },
              {
                id: 2,
                value: null,
                correct: false
              },
              {
                id: 3,
                value: null,
                correct: false
              }
            ]
        }
        answerPseudo = action.value === 'CMCQ' ? [{ answer: null }] : [{ answers: [] }]

        return {
          ...state,
          compQuestionIndicator: true,
          orQuestionIndicator: false,
          quesNegativeMark: 0,
          quesPositiveMark: 0,
          questionContent: {
            paragraph: null,
            subQuestions: [
              {
                questionContent: { ...questionPseudo },
                positiveMarks: 0,
                negativeMarks: 0,
                type: action.value,
                solution: null,
              }
            ]
          },
          answerContent: [...answerPseudo],
          generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {}
        }
      } else if (action.value === 'CNumeric') {
        return {
          ...state,
          compQuestionIndicator: true,
          orQuestionIndicator: false,
          quesNegativeMark: 0,
          quesPositiveMark: 0,
          questionContent: {
            paragraph: null,
            subQuestions: [
              {
                questionContent: { statement: null },
                positiveMarks: 0,
                negativeMarks: 0,
                type: action.value,
                solution: null,
              }
            ]
          },
          answerContent: [{ answer: null }],
          generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {}

        }
      } else if (action.value === 'CTF') {
        return {
          ...state,
          compQuestionIndicator: true,
          orQuestionIndicator: false,
          quesNegativeMark: 0,
          quesPositiveMark: 0,
          questionContent: {
            paragraph: null,
            subQuestions: [
              {
                questionContent: { statement: null },
                positiveMarks: 0,
                negativeMarks: 0,
                type: action.value,
                solution: null,

              }

            ]
          },
          answerContent: [
            { answer: null }
          ],
          generateQuesSelectedChapter: state.generateQuesObj?.syllabus === 'Chapter Test' ? { ...state.generateQuesSelectedChapter } : {}
        }

      }
    case STORE_QUESTION_INDEX:
      return {
        ...state,
        questionIndexStore: action.value
      }

    case STORE_SECTION_INDEX:
      return {
        ...state,
        sectionIndexStore: action.value
      }
    case STORE_MARKING_SCHEME_CARD_DATA:
      return {
        ...state,
        markingSchemeCardDataContainer: action.val
      }
    case SEARCH_FILTER:
      return {
        ...state,
        searchedData: action.val
      }
    case STORE_MARKING_SCHEME_TITLE:
      return {
        ...state,
        markingSchemeTitle: action.val
      }
    case STORE_MARKING_SCHEME_DESC:
      return {
        ...state,
        markingSchemeDescription: action.val
      }
    case STORE_MARKING_SCHEME_SUBJECTIVE:
      return {
        ...state,
        subjectiveTypeContainer: action.val
      }
    case STORE_MARKING_SCHEME_OBJECTIVE:
      return {
        ...state,
        objectiveTypeContainer: action.val
      }
    case STORE_MARKED_SCHEME_CARD_ID:
      return {
        ...state,
        markedSchemeCardIdContainer: action.val
      }
    case MARKED_SCHEME_CARD_DETAILS:
      return {
        ...state,
        cardDetailsContainer: action.val
      }
    case SET_FROM_SECTION_MARKING_SCHEME:
      return {
        ...state,
        isFromSectionMarkingScheme: action.val
      }
    case CREATE_ADDITIONAL_QUESTION:
      let questionDummy
      let answerDummy
      let indicatorToggle
      if (action.identifier === 'OR') {
        indicatorToggle = true
        if (action.value === 'MCQ' || action.value === 'MSQ') {
          questionDummy = {
            questionContent: {
              statement: null,
              options: (action.value === 'MSQ' && (state.numberOfOptions !== undefined && state.numberOfOptions !== null && Number(state.numberOfOptions) > 0)) ? Array(Number(state.numberOfOptions)).fill(null).map((u, i) => ({
                id: i,
                value: null,
                correct: false
              })) :
                [
                  {
                    id: 0,
                    value: null,
                    correct: false
                  },
                  {
                    id: 1,
                    value: null,
                    correct: false
                  },
                  {
                    id: 2,
                    value: null,
                    correct: false
                  },
                  {
                    id: 3,
                    value: null,
                    correct: false
                  }
                ]
            },
            type: action.value,
            solution: null
          }
          answerDummy = action.value === 'MCQ' ? { answer: null } : { answers: [] }

          return {
            ...state,
            compQuestionIndicator: false,
            orQuestionIndicator: indicatorToggle,
            // quesNegativeMark: 0,
            // quesPositiveMark: 0,
            questionContent: {
              orQuestions: state.orQuestionIndicator
                ? [...state.questionContent.orQuestions, { ...questionDummy }]
                : [{ questionContent: { ...state.questionContent }, type: action.value, solution: state.generateSolutionText }, { ...questionDummy }]
            },
            answerContent: state.orQuestionIndicator
              ? [...state.answerContent, { ...answerDummy }]
              : [{ ...state.answerContent }, { ...answerDummy }],
          }
        } else if (action.value === 'Numeric') {
          questionDummy = {
            questionContent: {
              statement: null,
            },
            type: action.value,
            solution: null
          }
          answerDummy = { answer: null }
          return {
            ...state,
            compQuestionIndicator: false,
            orQuestionIndicator: indicatorToggle,
            // quesNegativeMark: 0,
            // quesPositiveMark: 0,
            questionContent: {
              orQuestions: state.orQuestionIndicator
                ? [...state.questionContent.orQuestions, { ...questionDummy }]
                : [{ questionContent: { ...state.questionContent }, type: action.value, solution: state.generateSolutionText }, { ...questionDummy }]
            },
            answerContent: state.orQuestionIndicator
              ? [...state.answerContent, { ...answerDummy }]
              : [{ ...state.answerContent }, { ...answerDummy }],
          }
        } else if (action.value === 'T/F') {
          questionDummy = {
            questionContent: {
              statement: null,
            },
            type: action.value,
            solution: null
          }
          answerDummy = { answer: null }
          return {
            ...state,
            compQuestionIndicator: false,
            orQuestionIndicator: indicatorToggle,
            // quesNegativeMark: 0,
            // quesPositiveMark: 0,
            questionContent: {
              orQuestions: state.orQuestionIndicator
                ? [...state.questionContent.orQuestions, { ...questionDummy }]
                : [{ questionContent: { ...state.questionContent }, type: action.value, solution: state.generateSolutionText }, { ...questionDummy }]
            },
            answerContent: state.orQuestionIndicator
              ? [...state.answerContent, { ...answerDummy }]
              : [{ ...state.answerContent }, { ...answerDummy }],
          }
        }
      } else if (action.identifier === 'COMP') {
        indicatorToggle = true
        if (action.value === 'CMCQ' || action.value === 'CMSQ') {
          questionDummy = {
            questionContent: {
              statement: null,
              options: (action.value === 'CMSQ' && (state.numberOfOptions !== undefined && state.numberOfOptions !== null && Number(state.numberOfOptions > 0))) ? Array(Number(state.numberOfOptions)).fill(null).map((u, i) => ({
                id: i,
                value: null,
                correct: false
              })) :
                [
                  {
                    id: 0,
                    value: null,
                    correct: false
                  },
                  {
                    id: 1,
                    value: null,
                    correct: false
                  },
                  {
                    id: 2,
                    value: null,
                    correct: false
                  },
                  {
                    id: 3,
                    value: null,
                    correct: false
                  }
                ]
            },
            type: action.value,
            solution: null,
            positiveMarks: (state?.schemeId > 0 && state?.quesPositiveMark !== 0) ? state?.quesPositiveMark : 0,
            negativeMarks: (state?.schemeId > 0 && state?.quesNegativeMark !== 0) ? state?.quesNegativeMark : 0,
          }
          answerDummy = action.value === 'CMCQ' ? [{ answer: null }] : [{ answers: [] }]

          return {
            ...state,
            compQuestionIndicator: indicatorToggle,
            orQuestionIndicator: false,
            // quesNegativeMark: 0,
            // quesPositiveMark: 0,
            questionContent: {
              paragraph: state.questionContent.paragraph,
              subQuestions: [...state.questionContent.subQuestions, { ...questionDummy }],
              //   subQuestions: [
              //     {
              //       questionContent: { ...questionPseudo },
              //       positiveMarks: 0,
              //       negativeMarks: 0,
              //       type: action.value,
              //     }
              //   ]
              // },
            },
            answerContent: [...state.answerContent, ...answerDummy],
          }
        } else if (action.value === 'CNumeric') {

          questionDummy = {
            questionContent: {
              statement: null,
            },
            type: action.value,
            solution: null,
            positiveMarks: (state?.schemeId > 0 && state?.quesPositiveMark !== 0) ? state?.quesPositiveMark : 0,
            negativeMarks: (state?.schemeId > 0 && state?.quesNegativeMark !== 0) ? state?.quesNegativeMark : 0,
          }
          answerDummy = { answer: null }
          return {
            ...state,
            compQuestionIndicator: true,
            orQuestionIndicator: false,
            // quesNegativeMark: 0,
            // quesPositiveMark: 0,
            questionContent: {
              paragraph: state.questionContent.paragraph,
              subQuestions: [...state.questionContent.subQuestions, { ...questionDummy }],
              // subQuestions: [
              //   {
              //     questionContent: { statement: null },
              //     positiveMarks: 0,
              //     negativeMarks: 0,
              //     type: action.value,
              //   }
              // ]
            },
            answerContent: [...state.answerContent, { ...answerDummy }],

          }
        } else if (action.value === 'CTF') {
          questionDummy = {
            questionContent: {
              statement: null,
            },
            type: action.value,
            solution: null,
            positiveMarks: (state?.schemeId > 0 && state?.quesPositiveMark !== 0) ? state?.quesPositiveMark : 0,
            negativeMarks: (state?.schemeId > 0 && state?.quesNegativeMark !== 0) ? state?.quesNegativeMark : 0,
          }
          answerDummy = { answer: null }
          return {
            ...state,
            compQuestionIndicator: true,
            orQuestionIndicator: false,
            // quesNegativeMark: 0,
            // quesPositiveMark: 0,
            questionContent: {
              paragraph: state.questionContent.paragraph,
              subQuestions: [...state.questionContent.subQuestions, { ...questionDummy }],
              // subQuestions: [
              //   {
              //     questionContent: { statement: null },
              //     positiveMarks: 0,
              //     negativeMarks: 0,
              //     type: action.value,

              //   }

              // ]
            },
            answerContent: [...state.answerContent, { ...answerDummy }],
          }

        }
      }
      return {
        ...state,

      }

    case STORE_SCHEME_ID:
      return {
        ...state,
        schemeId: action.value
      }

    case ADD_MARKING_SCHEME_MARKS:
      if (state?.generateQuesSelectedTestType?.children === 'CMSQ' || state?.generateQuesSelectedTestType?.children === 'CMCQ' || state?.generateQuesSelectedTestType?.children === 'CTF' || state?.generateQuesSelectedTestType?.children === 'CNumeric') {
        let quesData = state.questionContent?.subQuestions
        quesData[0].positiveMarks = action.data.positiveMark;
        quesData[0].negativeMarks = action.data.negativeMark;

        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            subQuestions: [...quesData]
          },
          quesPositiveMark: action.data.positiveMark,
          quesNegativeMark: action.data.negativeMark,
          numberOfOptions: action.data.noOfOptions ? action.data.noOfOptions : null,

        }
      }
      return {
        ...state,
        quesPositiveMark: action.data.positiveMark,
        quesNegativeMark: action.data.negativeMark,
        numberOfOptions: action.data.noOfOptions
      };
    case TOTAL_QUES_IN_CHAPTER:
      return {
        ...state,
        totalQuesChapterData: action.val
      }
    case STORE_DELETING_CHAPTER_ID:
      return {
        ...state,
        deletingChapterId: action.val
      }
    case STORE_IMPORT_TEST_ID:
      return {
        ...state,
        importTestIdContainer: action.val
      }
    case STORE_SUBJECT_NAME:
      return {
        ...state,
        subjectNameContainer: action.val
      }
    case STORE_LIST_VIEW_DATA:
      return {
        ...state,
        listViewDataContainer: action.val
      }
    case STORE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.val
      }
    case STORE_SUBJECT_ID:
      return {
        ...state,
        subjectIdContainer: action.val
      }
    case STORE_QUESTION_PALETTE_DATA:
      return {
        ...state,
        questionPaletteDataConatiner: action.val
      }
    case STORE_CHAPTERS:
      return {
        ...state,
        chaptersArray: action.val
      }
    case SAVE_START_TIME:
      return{
        ...state,
        savetestStartTime:action.val
      }
    case MODIFY_OPTIONS:
      let quesAnsData
      if (action.qType === 'Comp') {
        quesAnsData = state.questionContent?.subQuestions
        if (action.operation === 'delete') {
          if (action.index > -1) {
            quesAnsData?.[action.qIndexType]?.questionContent?.options.splice(action.index, 1)
          }
        }
        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            subQuestions: [...quesAnsData]
          },
        }
      } else if (action.qType === 'Or') {
        quesAnsData = state.questionContent?.orQuestions
        if (action.operation === 'delete') {
          if (action.index > -1) {
            quesAnsData?.[action.qIndexType]?.questionContent?.options.splice(action.index, 1)
          }
        }
        return {
          ...state,
          questionContent: {
            ...state.questionContent,
            orQuestions: [...quesAnsData]
          },
        }

      } else {
        quesAnsData = state.questionContent
        if (action.operation === 'delete') {
          if (action.index > -1) {
            quesAnsData?.options?.splice(action.index, 1)
          }
        }
        return {
          ...state,
          questionContent: { ...quesAnsData },
        }

      }
    case action.type:
      return {
        ...state,
        [action.varname]: action.bool
      }
    

    default:
      return state;
  }
};

export default reducer;