import { fetchStudentListData, postLogOfflineAttendance, setPresentStudents, setPageToggle } from 'actions/schedule'
import { PageHeader } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import SubmitModal from './modal/submitModal'
import AttendanceSummaryCard from './components/attendanceSummaryCard'
import StudentCardsDisplay from './components/studentCardsDisplay'
import SubjectCardDisplay from './components/subjectCardDisplay'


const ScheduleAttendance = ({ studentAttendanceData, subjectAttendanceData, fetchStudentListData, presentStudents, setPresentStudents, postLogOfflineAttendance, scheduleList, cardData, initialSummary, setPageToggle }) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const history = useHistory()

  console.log('data2', cardData)

  useEffect(() => {
    setPageToggle('2')
  })

  const submitHandler = () => {
    setIsModalVisible(true)
    setPresentStudents(null)
  }

  const okHandler = () => {
    setIsModalVisible(false)
    postLogOfflineAttendance()
    history.push('/schedule')
  }

  const cancelHandler = () => {
    setIsModalVisible(false)
  }
  let presentStudentsArray = []
  let absentStudentsArray = []
  return (
    <div style={{ height: '80vh' }}>
      <PageHeader
        className="site-page-header"
        onBack={() => (history.goBack())}
        title={'Attendance'}
      />
      <div style={{ display: 'flex' }}>
        <div className='attendance-studentCard'>
          <StudentCardsDisplay presentStudentsArray={presentStudentsArray} absentStudentsArray={absentStudentsArray} data={studentAttendanceData} submitHandler={submitHandler} attendanceIndicator={subjectAttendanceData?.attendanceMarked} presentStudents={presentStudents} />
        </div>
        <div>
          <SubjectCardDisplay data={subjectAttendanceData} />
          <AttendanceSummaryCard onGoingItem={cardData} presentStudents={presentStudents} studentAttendanceData={studentAttendanceData} initialSummary={initialSummary} />
        </div>
      </div>
      <SubmitModal presentStudentsArray={presentStudentsArray} absentStudentsArray={absentStudentsArray} initialSummary={initialSummary} presentStudents={presentStudents} studentAttendanceData={studentAttendanceData} visible={isModalVisible} okHandler={okHandler} cancelHandler={cancelHandler} />
    </div>
  )
}


const mapStateToProps = state => {
  const {
    studentAttendanceData,
    subjectAttendanceData,
    selectedStudents,
    presentStudents,
    scheduleList,
    cardData,
    initialSummary,
  } = state.schedule

  return {
    studentAttendanceData,
    subjectAttendanceData,
    selectedStudents,
    presentStudents,
    scheduleList,
    cardData,
    initialSummary,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchStudentListData: (value) => dispatch(fetchStudentListData(value)),
  setPresentStudents: (id) => dispatch(setPresentStudents(id)),
  postLogOfflineAttendance: (callback) => dispatch(postLogOfflineAttendance(callback)),
  setPageToggle: (value) => dispatch(setPageToggle(value))
})
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAttendance);
