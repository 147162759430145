import './styles.css';
import { Avatar, Typography } from 'antd';
import { connect } from 'react-redux';
import { IMG_URL } from '../../env.json';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { RightOutlined } from '@ant-design/icons';
import StdRegistrationRequest from './modals/stdRegistrationRequest';
import { downloadTemplate, fetchCourses, fetchStandards, getDashboard, setQuickAccessItem, setRole, setSelectedCourse, setSelectedStandard, setStudentWithoutData, setUserids, updateRegistrationSettings, updateUserRegistrations } from 'actions/userManagementV2';
import PageDataLoader from 'components/pageDataLoader';
import UserTable from './userTable';
import AddUser from './modals/addUser';
import ImportUser from './modals/importUser';
import moment from 'moment';
import { storeResourceData } from 'actions/feeManagement';

const studentsIcon = require('../../Assets/userManagementV2/ic-students.svg').default;
const teachersIcon = require('../../Assets/userManagementV2/ic-teachers.svg').default;
const dataOpsIcon = require('../../Assets/userManagementV2/ic-dataops.svg').default;
const opsIcon = require('../../Assets/userManagementV2/ic-ops.svg').default;
const noStudents = require('../../Assets/userManagementV2/no-students.svg').default;
const excelFileIcon = require('../../Assets/userManagementV2/excelFileIcon.svg').default;
const infoCircleIcon = require('../../Assets/userManagementV2/infoCircleIcon.svg').default;
const analyticsIcon = require('../../Assets/userManagementV2/analyticsIcon.svg').default;
const deleteBinIcon = require('../../Assets/userManagementV2/deleteBinIcon.svg').default;
const NoData = require("../../Assets/ill-no-data.svg").default;

const NoDataCard = () => {
        return (
                <div className='r-c-c-c' style={{ position: 'absolute', left: '25%', top: '20%' }}>
                        <img src={NoData} />
                        <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</div>
                        <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no requests to show yet. Please check back later.</div>
                </div>
        )
}

const roleDetails = (data, history, setRole, setStudentWithoutData, setSelectedCourse) => {

        if (data) {
                return [
                        { label: 'Students', count: data[0]?.totalCount, icon: studentsIcon, onClick: () => { setSelectedCourse(''); setStudentWithoutData(undefined); history.push('/user-management/courses'); setRole('userRole', 'Students') }, lastWeekCount: data[0]?.createdLastWeekCount },
                        { label: 'Teachers', count: data[1]?.totalCount, icon: teachersIcon, onClick: () => { setSelectedCourse(''); setStudentWithoutData(undefined); history.push('/user/staff'); setRole('userRole', 'Teachers') }, lastWeekCount: data[1]?.createdLastWeekCount },
                        { label: 'Data Operators', count: data[2]?.totalCount, icon: dataOpsIcon, onClick: () => { setSelectedCourse(''); setStudentWithoutData(undefined); history.push('/user/staff'); setRole('userRole', 'Data Operators') }, lastWeekCount: data[2]?.createdLastWeekCount },
                        { label: 'Operations', count: data[3]?.totalCount, icon: opsIcon, onClick: () => { setSelectedCourse(''); setStudentWithoutData(undefined); history.push('/user/staff'); setRole('userRole', 'Operations') }, lastWeekCount: data[3]?.createdLastWeekCount },
                ]
        } else {
                return [
                        { label: 'Students', count: "loading...", icon: studentsIcon },
                        { label: 'Teacher', count: "loading...", icon: teachersIcon },
                        { label: 'Data Operators', count: "loading...", icon: dataOpsIcon },
                        { label: 'Operations', count: "loading...", icon: opsIcon },
                ]
        }
}

const RoleCard = ({ data, index }) => {
        return (
                <div key={index} onClick={data?.onClick} className='CardShadow cursor-pointer' style={{ border: '1px solid #E6E6E6', height: '14vh', width: '17vw', borderRadius: 8, marginTop: 5, minWidth: '250px' }}>
                        <div className='p-20'>
                                <div className='r-jsb' style={{ width: '100%' }}>
                                        <div style={{}} className='simGrey text-md bold-600'>{data?.count}</div>
                                        <div> <img src={data?.icon} style={{ width: 45 }} /></div>
                                </div>
                                <div style={{ fontSize: 12, marginTop: -6, color: '#AEAEAE' }}>{data?.label == 'Teachers' || data?.label == 'Data Operators' || data?.label == 'Operations' ? 'Staff' : 'Students'} in <span style={{ color: '#636363', fontWeight: 700 }}>{data?.label}</span></div>
                                <div style={{ fontSize: 10, color: "#28DF99", fontWeight: 600 }}>
                                        {data?.lastWeekCount != 0 ?
                                                <>
                                                        {data?.lastWeekCount > 0 ?
                                                                <div style={{ fontSize: 10, color: "#28DF99", fontWeight: 600 }}>
                                                                        +{data?.lastWeekCount} from last week
                                                                </div>
                                                                :
                                                                <div style={{ fontSize: 10, color: '#FF414D' }}>
                                                                        {data?.lastWeekCount} from last week
                                                                </div>
                                                        }
                                                </> : null
                                        }
                                </div>
                        </div>
                </div>
        );
};

const AddUsers = ({ setAdduserModal, setImportModal, handleDownloadTemplate, downloadLoader }) => {
        return (
                <div className='addUsersCard CardShadow' style={{ width: '98%', height: '100%', backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6', borderRadius: 8, padding: 10, display: 'flex' }}>
                        <img src={noStudents} style={{ width: '25%', height: '100%' }} />
                        <div className='flex-column' style={{ marginLeft: 30, marginTop: 10 }}>
                                <div style={{ color: '#191919', fontSize: 16, fontWeight: 700 }}>Add users</div>
                                <div style={{ color: '#636363', fontSize: 14, fontWeight: 500 }}>Add users by importing in groups or by filling a form manually</div>
                                <div onClick={(e) => handleDownloadTemplate(e)} className='display-flex cursor-pointer' style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={excelFileIcon} style={{ width: 15, marginRight: 10 }} /><Button loading={downloadLoader} type='transparent' style={{ color: '#1089FF', padding: 0 }}>DOWNLOAD EXCEL TEMPLATE {!downloadLoader ? <span style={{ backgroundColor: "#FF414D", color: '#FFF', marginLeft: 5, fontSize: 10, width: 40, borderRadius: 2, padding: 3 }}>NEW</span> : null}</Button>
                                </div>
                                <div className='display-flex'>
                                        <img src={infoCircleIcon} style={{ width: 15, marginRight: 10 }} /><div style={{ color: '#FF414D' }}>Please download the new Excel Template</div>
                                </div>
                                <div className='display-flex m-t-10'>
                                        <Button type='primary' onClick={() => { setAdduserModal(true) }}>FILL FORM</Button>
                                        <Button type='transparent' onClick={() => setImportModal(true)} style={{ color: '#594099' }}>IMPORT</Button>
                                </div>
                        </div>
                </div>
        )
}

const AnalyticsAndDeleteUserCards = ({ icon, title, subTitle, actionButton, onClick }) => {
        return (
                <div className='CardShadow' style={{ border: '1px solid #E6E6E6', height: '100%', width: '96%', backgroundColor: 'white', borderRadius: 8, padding: 13 }}>
                        <div className='flex-column'>
                                <img src={icon} style={{ width: 50 }} />
                                <div className='m-t-5' style={{ color: '#191919', fontWeight: 700, fontSize: 16 }}>{title}</div>
                                <div className='m-t-5' style={{ color: '#636363', fontWeight: 500, fontSize: 14 }}>{subTitle}</div>
                                <Button type='transparent' style={{ color: '#1089FF', fontSize: 16, padding: 0, cursor: title == 'Analytics' ? 'Not-allowed' : 'pointer' }} onClick={onClick}>{actionButton}</Button>
                        </div>

                </div>
        )
}

const RegistrationRequestCard = ({ image, userName, course, approvalType, updateUserRegistrations, id, togglePageLoader, params, getDashboard, timeDiff, user }) => {

        const [approveLoader, setApproveLoader] = useState(false);
        const [rejectLoader, setRejectLoader] = useState(false);

        const timeSinceNow = moment.utc(timeDiff).local().fromNow();

        const successCallback = () => {
                togglePageLoader(true);
                getDashboard(params, () => togglePageLoader(false));
        }

        const handleButtonActions = (status, id) => {
                const registrationsParams = {
                        requestStatuses: [{
                                userId: id,
                                status: status
                        }]
                };

                const setLoadingState = (loaderState) => {
                        if (status === 'Approved') {
                                setApproveLoader(loaderState);
                        } else {
                                setRejectLoader(loaderState);
                        }
                };

                setLoadingState(true);
                updateUserRegistrations(registrationsParams, () => setLoadingState(false), () => successCallback());
        };

        return (
                <div className='r-c-c' style={{}}>
                        <div className='r-ac flex-2'>
                                <Avatar src={image} style={{ borderRadius: '50%', marginTop: 10, width: 50, height: 50 }}></Avatar>
                                <div className='p-10 m-t-10' style={{ width: 220 }}>
                                        <div><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, fontSize: 14 }}>{userName}</Typography.Text></div>
                                        <div><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#636363', fontSize: 12 }}>{course ?? 'NA'}</Typography.Text></div>
                                </div>
                        </div>
                        <div className='flex-1' style={{ width: '100%' }}>
                                {approvalType === 'Auto Approval' ?
                                        <div style={{ color: '#636363', padding: 0 }}>{timeSinceNow}</div>
                                        : user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') ?
                                                <div className='r-jsb' style={{ width: '100%' }}>
                                                        <Button loading={rejectLoader} type='transparent' onClick={() => handleButtonActions('Rejected', id)} style={{ width: '20%', padding: 0, color: '#FF414D' }}>REJECT</Button>
                                                        <Button loading={approveLoader} type='primary' onClick={() => handleButtonActions('Approved', id)} style={{ width: '20%', backgroundColor: '#28DF99', border: 'none', color: 'white' }}>APPROVE</Button>
                                                </div>
                                                : null
                                }
                        </div>
                </div>
        )
}

const UserRegistrationWrapper = ({ heading, approvalType, changeSetting, data, setChangeSetting, handleVAClick, updateUserRegistrations, getDashboard, params, togglePageLoader, user }) => {
        return (
                <div className='CardShadow' style={{ border: '1px solid #E6E6E6', borderRadius: 8, height: '100%', width: '98%', padding: 15, overflow: 'auto', backgroundColor: '#FFF' }}>
                        <div style={{ color: '#191919', fontWeight: 700, fontSize: 16 }}>{heading}</div>
                        <div className='display-flex'>
                                <div style={{ fontWeight: 600, fontSize: 14, color: '#636363' }}>{approvalType}</div>
                                {/* {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ?
                                        <div className='m-l-5 cursor-pointer' onClick={() => setChangeSetting(true)} style={{ color: '#1089FF', fontWeight: 600, fontSize: 14 }}>{changeSetting}</div>
                                        :
                                        null
                                } */}
                        </div>
                        <div style={{ height: '85%', position: 'relative' }}>
                                {data?.length === 0 ? (
                                        <NoDataCard />
                                ) : (
                                        data?.map((item) => {
                                                if (item?.role.includes('Student')) {
                                                        return <div style={{ padding: 0 }}><RegistrationRequestCard timeDiff={item?.createdAt} togglePageLoader={togglePageLoader} params={params} getDashboard={getDashboard} id={item?.id} image={`${IMG_URL}/${item?.image}`} userName={item?.name} course={item?.preferredCourse} approvalType={approvalType} updateUserRegistrations={updateUserRegistrations} user={user} /></div>
                                                } else {
                                                        return <div style={{ padding: 0 }}><RegistrationRequestCard timeDiff={item?.createdAt} togglePageLoader={togglePageLoader} params={params} getDashboard={getDashboard} id={item?.id} image={`${IMG_URL}/${item?.image}`} userName={item?.name} course={item?.role} updateUserRegistrations={updateUserRegistrations} user={user} /></div>
                                                }
                                        })
                                )}
                        </div>
                        <center>
                                <Button type='link' onClick={handleVAClick}>VIEW ALL</Button>
                        </center>
                </div>
        )
}

const UsersDashboard = ({ getDashboard, dashboardMeta, updateRegistrationSettings, setRole, downloadTemplate, updateUserRegistrations, user, fetchStandards, fetchCourses, setSelectedCourse, setStudentWithoutData, setUserids, setSelectedStandard, setQuickAccessItem , quickAccessItem}) => {

        const bodyStyle = {
                background: "#FAFAFA",
                width: "calc(100% + 30px)",
                height: "calc(100% + 40px)",
                margin: -20,
                padding: 20,
                overflow: "auto",
                marginTop:20
        };

        const location = useLocation();

        useEffect(() => {
                //fetches standards & course array for filters...
                fetchStandards();
                fetchCourses();
                setUserids([])
               
                if(quickAccessItem == 'addUser'){
                        setAdduserModal(true)  
                }
        }, []);

        const history = useHistory();
        // const [changeSettingOpen, setChangeSetting] = useState(false);
        const [dashboardDetails, setDashboardDetails] = useState({});
        const [pageLoader, togglePageLoader] = useState(false);
        const [addUserModal, setAdduserModal] = useState(false);
        const [importUsers, setImportModal] = useState(false);
        const [downloadLoader, setDownloadLoader] = useState(false);

        console.log({ dashboardDetails });

        let params = {
                userType: 'Student',
                status: 'Approved',
                limit: 10
        };

        useEffect(() => {
                setDashboardDetails({ ...dashboardMeta });
        }, [dashboardMeta]);

        useEffect(() => {
                togglePageLoader(true);
                getDashboard(params, () => togglePageLoader(false));
        }, [])

        // const onCloseSettingModal = () => {
        //         setChangeSetting(false);
        // };

        const handleDownloadTemplate = (e) => {
                e.stopPropagation();
                setDownloadLoader(true);
                downloadTemplate(() => setDownloadLoader(false))
        }

        return (
                <div style={bodyStyle}>
                        <div>
                                <PageHeader title='User management' actions={null} />
                        </div>
                        {
                                pageLoader ? (
                                        <></>
                                ) : (
                                        <>
                                                <div style={{ marginTop: 50, display: 'flex', justifyContent: 'space-between' }}>
                                                        {roleDetails(dashboardDetails?.roleWiseCountStats, history, setRole, setStudentWithoutData, setSelectedCourse)?.map((item, index) => (
                                                                <div style={{ flex: 1 }} key={index}>
                                                                        <RoleCard data={item} index={index} />
                                                                </div>
                                                        ))}
                                                </div>
                                                {((user?.privilage?.includes('FeeManager') || user?.privilage?.includes('LeaveManager')) && !user?.privilage?.includes('Admin')) ? null : (
                                                        <div className='r-jsb m-t-30'>
                                                                <div style={{ height: 190, flex: 1 }}>
                                                                        <AddUsers setAdduserModal={setAdduserModal} setImportModal={setImportModal} handleDownloadTemplate={handleDownloadTemplate} downloadLoader={downloadLoader} />
                                                                </div>
                                                                {/* <div style={{ flex: 1 }}>
                                                                        <AnalyticsAndDeleteUserCards icon={analyticsIcon} title={'Analytics'} subTitle={'Analyze and understand user groups and get instant visualization.'} actionButton={'VIEW'} />
                                                                </div> */}
                                                                <div style={{ flex: 1 }}>
                                                                        <AnalyticsAndDeleteUserCards icon={deleteBinIcon} title={'Suspended Users'} subTitle={'You can restore a deleted user, or permanently delete a user.'} actionButton={'VIEW'} onClick={() => history.push('/user-management/deleted-users')} />
                                                                </div>
                                                        </div>
                                                )}
                                                <div className='r-jsb' style={{ height: 560, marginTop: 30 }}>
                                                        <div style={{ height: '100%', flex: 1 }}>
                                                                <UserRegistrationWrapper togglePageLoader={togglePageLoader} params={params} getDashboard={getDashboard} updateUserRegistrations={updateUserRegistrations} handleVAClick={() => { setRole('userRole', 'Students'); setUserids([]); setStudentWithoutData(undefined); setSelectedStandard(''); setSelectedCourse('All Students'); history.push('/user/student'); }} data={dashboardDetails?.studentAutoApproved == false ? dashboardDetails?.studentRegistrationRequests?.items : dashboardDetails?.studentList?.items} heading='Student Registration' approvalType={dashboardDetails?.studentAutoApproved == false ? 'Manual Approval' : 'Auto Approval'} /* changeSetting={'CHANGE SETTING'} */ /* setChangeSetting={setChangeSetting} */ user={user} />
                                                        </div>
                                                        <div style={{ height: '100%', flex: 1 }}>
                                                                <UserRegistrationWrapper togglePageLoader={togglePageLoader} params={params} getDashboard={getDashboard} updateUserRegistrations={updateUserRegistrations} handleVAClick={() => { history.push('/user/registration/requests'); setRole('userRole', 'staff') }} data={dashboardDetails?.staffRegistrationRequests?.items} heading='Staff Registration Requests' approvalType={'Manual Approval'} user={user} />
                                                        </div>
                                                </div>
                                        </>
                                )
                        }
                        {/* {changeSettingOpen && <StdRegistrationRequest open={changeSettingOpen} onClose={onCloseSettingModal} updateRegistrationSettings={updateRegistrationSettings} getDashboard={getDashboard} params={params} togglePageLoader={togglePageLoader} dashboardDetails={dashboardDetails} />} */}
                        {addUserModal && <AddUser open={addUserModal} onCancel={() => {setAdduserModal(false); setQuickAccessItem({addUser:false})}} params={params} getDashboard={getDashboard} />}
                        {importUsers && <ImportUser open={importUsers} onCancel={() => setImportModal(false)} />}
                        <PageDataLoader visible={pageLoader} />
                </div>
        )
}

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { dashboardMeta, quickAccessItem } = state.userManagementV2;
        return { dashboardMeta, user, quickAccessItem }
};

const mapDispatchToProps = (dispatch) => ({
        getDashboard: (params, callback) => dispatch(getDashboard(params, callback)),
        updateRegistrationSettings: (params, callback) => dispatch(updateRegistrationSettings(params, callback)),
        setRole: (key, val) => dispatch(setRole(key, val)),
        downloadTemplate: (callback) => dispatch(downloadTemplate(callback)),
        updateUserRegistrations: (params, callback, successCallback) => dispatch(updateUserRegistrations(params, callback, successCallback)),
        fetchStandards: params => dispatch(fetchStandards(params)),
        fetchCourses: params => dispatch(fetchCourses(params)),
        setSelectedCourse: res => dispatch(setSelectedCourse(res)),
        setStudentWithoutData: res => dispatch(setStudentWithoutData(res)),
        setUserids: userIds => dispatch(setUserids(userIds)),
        setSelectedStandard: res => dispatch(setSelectedStandard(res)),
        setQuickAccessItem: (res) => dispatch(setQuickAccessItem(res)),

});

export default connect(mapStateToProps, mapDispatchToProps)(UsersDashboard);