import React, { useRef, useState } from 'react'
import { newCourseCreationCardStyle } from './courseCreationPage'
import RatingStars from 'pages/studentMarketPlace/ratingStars'
import { Button } from 'components/Button'
import { connect } from 'react-redux'
import { Typography } from 'antd'
import { Api } from 'services'
import { IsJsonString, isHTML } from "./courseInformationPage"
import HtmlParser from 'react-html-parser'
import YtPlayer from 'components/ytPlayer'
import ReactPlayer from 'react-player'
import PreviewCourse from 'pages/studentMarketPlace/coursePageCompo/previewCourse'
import { stripHtmlTags } from './courseDetailsComp'
import ReactPlayerHoc from 'components/ReactPlayerHoc'

function CoursePreviewCardComp({ courseCreateObj, courseTypeName, specificCourseDataContainer }) {
    const [isVideoPreview, setVideoPreview] = useState(false);
    const [previewLink, setPreviewLink] = useState(null);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const playerRef = useRef(null);
    const handleVideoPause = () => {
        setVideoPreview(true);
        if (playerRef.current && videoPlaying) {
            playerRef.current.seekTo(0)
            setVideoPlaying(false);
        } else {
            setVideoPlaying(true);
        }
        setPreviewLink(courseCreateObj?.previewLink);
    }
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const isLearningsPresent = courseCreateObj?.learnings?.some((item) => item?.name);
    const isPrerequisitesPresent = courseCreateObj?.prerequisites?.some((item) => item?.name);
    const isFeaturesPresent = courseCreateObj?.features?.some((item) => item?.name);
    const isDetailsPresent = courseCreateObj?.details?.some((item) => item?.name);

    return (
        <div className='scroll-bar-universal' style={{ ...newCourseCreationCardStyle, height: 706 }}>
            <div className='r-c-c-c' style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Preview will appear here</div>
            <div className='r-c-sb m-t-20' style={{ width: "100%" }}>
                <div style={{ width: "fit-content" }}>
                    <div className='r-ac'>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontWeight: 700, fontSize: 16, width: courseCreateObj?.courseName ? 300 : "" }}>{courseCreateObj?.courseName ? courseCreateObj?.courseName : "Course name"} </Typography.Text>
                        <div className='m-l-10' style={{ color: "#FFA931", fontSize: 10, fontWeight: 700 }}>NEW</div>
                    </div>
                    {courseTypeName == "onlineTestSeries" || courseTypeName == "studyMaterials" ? <></> : <div className='m-t-5' style={{ color: "#191919", maxHeight: "3.6em", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", minHeight: 40 }}>{courseCreateObj?.brief ? IsJsonString(courseCreateObj?.brief) ? HtmlParser(JSON.parse(courseCreateObj?.brief)?.mobile) : stripHtmlTags(courseCreateObj?.brief) : "Course brief"}</div>}
                    <div className='m-t-10 r-ac'> <div className='m-r-10' style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12, width: "fit-content" }}>Bestseller</div>
                        <div className=' m-r-5' style={{ color: "#FFA931", fontWeight: "bold" }}>5</div>
                        <div style={{ marginTop: 1 }}><RatingStars star={"5"} size={12} /> </div>
                        <div style={{ color: "#636363", fontSize: 12, fontWeight: 600, marginLeft: 10 }}>(158,352 ratings)</div>
                    </div>
                    <div className='r-ac m-t-8' style={{ color: "#191919", fontWeight: "bold" }}>
                        ₹ {courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.discountedCourseAmount ? specificCourseDataContainer?.course?.discountedCourseAmount : ((courseTypeName == "videoResources" || courseTypeName == "studyMaterials") && specificCourseDataContainer?.discountedCourseAmount) ? specificCourseDataContainer?.discountedCourseAmount : courseTypeName == "onlineTestSeries" && specificCourseDataContainer?.testSeriesDetails?.discountedCourseAmount ? specificCourseDataContainer?.testSeriesDetails?.discountedCourseAmount : "XXXX"}
                        <span className='m-l-10' style={{ color: "#636363", fontSize: 12, fontWeight: 600, textDecoration: "line-through" }}>
                            ₹ {courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.amount ? specificCourseDataContainer?.course?.amount : ((courseTypeName == "videoResources" || courseTypeName == "studyMaterials") && specificCourseDataContainer?.amount) ? specificCourseDataContainer?.amount : courseTypeName == "onlineTestSeries" && specificCourseDataContainer?.testSeriesDetails?.amount ? specificCourseDataContainer?.testSeriesDetails?.amount : "XXX"}</span>
                        <span className='m-l-10' style={{ color: "#28DF99", fontSize: 12, fontWeight: 600 }}>{courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.discountPercent ? specificCourseDataContainer?.course?.discountPercent : ((courseTypeName == "videoResources" || courseTypeName == "studyMaterials") && specificCourseDataContainer?.discountPercent) ? specificCourseDataContainer?.discountPercent : courseTypeName == "onlineTestSeries" && specificCourseDataContainer?.testSeriesDetails?.discountPercent ? specificCourseDataContainer?.testSeriesDetails?.discountPercent : "50"}% Off</span></div>
                    <Button type="primary" style={{ marginTop: 8, fontSize: 10, cursor: "default" }}>REGISTER NOW</Button>
                </div>
                <div className='m-r-10' style={{ width: "35%" }}>
                    <img src={courseCreateObj?.image ? `${Api.dlS3Url}${courseCreateObj?.image}` : require("../../Assets/courseManagementV4/course-preview-default-img.svg").default} style={{ width: 200 }} />
                </div>
            </div>
            <div className='r-jsb m-t-30' style={{ width: "100%" }}>
                <div className='m-r-20' style={{ width: "35%" }}>
                    {courseCreateObj?.previewLink && courseCreateObj?.previewLink === null || courseCreateObj?.previewLink === "" || courseCreateObj?.previewLink?.match(regExp) ?
                        <div>
                            <ReactPlayerHoc
                                height='16dvh'
                                width='100%'
                                url={courseCreateObj?.previewLink}
                                controls={true}
                                keyboardControls={true}
                            />
                        </div>
                        :
                        <div style={{ position: "relative", height: 120, width: "100%", backgroundColor: "#636363" }}>
                            <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                                <img src={require("../../Assets/courseManagementV4/video-play-img.svg").default} />
                            </div>
                        </div>
                    }
                    <center onClick={() => {
                        if (courseCreateObj?.previewLink && courseCreateObj?.previewLink === null || courseCreateObj?.previewLink === "" || courseCreateObj?.previewLink?.match(regExp)) {
                            handleVideoPause()
                        }
                    }} style={{ color: "#594099", fontWeight: "bold", cursor: courseCreateObj?.previewLink && courseCreateObj?.previewLink === null || courseCreateObj?.previewLink === "" || courseCreateObj?.previewLink?.match(regExp) ? "pointer" : "default" }}>Preview this course</center>
                </div>
                <div style={{ width: "65%", textAlign: "left", marginTop: -30 }}>
                    <div style={{ color: "#191919", fontWeight: 700 }}>Description</div>
                    <div className={courseCreateObj?.courseDescription ? "m-t-10 scroll-bar-universal" : "border-dashed-box m-t-10"} style={{ height: 150 }}>
                        <div style={{ color: "#191919", fontSize: 12 }}>{courseCreateObj?.courseDescription ? IsJsonString(courseCreateObj?.courseDescription) ? HtmlParser(JSON.parse(courseCreateObj?.courseDescription)?.mobile) : stripHtmlTags(courseCreateObj?.courseDescription) : "Course description will appear here"}</div>
                    </div>
                </div>
            </div>
            {courseTypeName == "onlineTestSeries" ? <></> :
                <div className='m-t-20' style={{ border: "1px solid #E6E6E6", width: "100%", height: "fit-content", padding: 10 }}>
                    <div style={{ color: "#191919", fontWeight: 700 }}>What you'll learn</div>
                    {isLearningsPresent ?
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                            {courseCreateObj?.learnings?.map((item, index) => (
                                <>
                                    {item?.name ?
                                        <div className='r-c-fs' style={{ alignItems: "baseline", width: courseCreateObj?.learnings?.length == 1 ? "100%" : "49%" }}>
                                            <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                            <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                        </div>
                                        :
                                        <></>}
                                </>
                            ))}
                        </div>
                        :
                        <div className='m-t-5'>• <span style={{ color: "#636363", fontSize: 12 }}>What your students learn from this course will appear here</span></div>}
                </div>
            }
            {courseTypeName == "studyMaterials" ? <></> :
                <div className='m-t-20' style={{ border: "1px solid #E6E6E6", width: "100%", height: "fit-content", padding: 10 }}>
                    <div style={{ color: "#191919", fontWeight: 700 }}>Prerequisites</div>
                    {isPrerequisitesPresent ?
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                            {courseCreateObj?.prerequisites?.map((item, index) => (
                                <>
                                    {item?.name ?
                                        <div className='r-c-fs' style={{ alignItems: "baseline", width: courseCreateObj?.prerequisites?.length == 1 ? "100%" : "49%" }}>
                                            <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                            <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                        </div>
                                        :
                                        <></>}
                                </>
                            ))}
                        </div>
                        :
                        <div className='m-t-5'>• <span style={{ color: "#636363", fontSize: 12 }}>Prerequisites need for this course will appear here</span></div>}
                </div>
            }
            <div className='m-t-20' style={{ border: "1px solid #E6E6E6", width: "100%", height: "fit-content", padding: 10 }}>
                <div style={{ color: "#191919", fontWeight: 700 }}>Salient features</div>
                {isFeaturesPresent ?
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                        {courseCreateObj?.features?.map((item, index) => (
                            <>
                                {item?.name ?
                                    <div className='r-c-fs' style={{ alignItems: "baseline", width: courseCreateObj?.features?.length == 1 ? "100%" : "49%" }}>
                                        <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                        <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                    </div>
                                    :
                                    <></>}
                            </>
                        ))}
                    </div>
                    :
                    <div className='m-t-5'>• <span style={{ color: "#636363", fontSize: 12 }}>Added salient features will appear here</span></div>}
            </div>
            {courseTypeName == "videoResources" ?
                <div className='m-t-20' style={{ border: "1px solid #E6E6E6", width: "100%", height: "fit-content", padding: 10 }}>
                    <div style={{ color: "#191919", fontWeight: 700 }}>This course includes</div>
                    {isDetailsPresent ?
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                            {courseCreateObj?.details?.map((item, index) => (
                                <>
                                    {item?.name ?
                                        <div className='r-c-fs' style={{ alignItems: "baseline", width: courseCreateObj?.details?.length == 1 ? "100%" : "49%" }}>
                                            <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                            <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                        </div>
                                        :
                                        <></>}
                                </>
                            ))}
                        </div>
                        :
                        <div className='m-t-5'>• <span style={{ color: "#636363", fontSize: 12 }}>Course includes will appear here</span></div>}
                </div>
                :
                <></>}
            {isVideoPreview &&
                <PreviewCourse
                    isVideoPreview={isVideoPreview}
                    setVideoPreview={setVideoPreview}
                    previewLink={previewLink}
                />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { courseCreateObj, courseTypeName, specificCourseDataContainer } = state.courseManagement
    return {
        courseCreateObj, courseTypeName, specificCourseDataContainer
    };
};

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(CoursePreviewCardComp);