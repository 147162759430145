import React, { useEffect, useState } from 'react'
import QuizDesign from './quizDesign'
import CourseResults from './courseResults'
import ViewQuizSolutions from './viewQuizSolutions'
import PageHeader from 'components/pageHeader'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getStudyMaterialData, setParamAttemptQuiz, setQuizFlowCount } from 'actions/studentMarketPlace'
import { connect } from 'react-redux'
import { Button } from 'components/Button'
import CourseDetailsDescriptionSection from '../courseDetailsDescriptionSection'
import StudyMaterialContent from '../coursePageCompo/studyMaterialContent'
import CourseDetailsLearnings from '../courseDetailsLearnings'
import RelatedStudyMaterial from '../coursePageCompo/relatedStudyMaterial'
import PageDataLoader from 'components/pageDataLoader'


function StudyMaterials({ getStudyMaterialData,setParamAttemptQuiz,paramAttemptQuiz, setQuizFlowCount, fromStudyMaterialPage, studyMaterialData, quizFlowCount }) {

    const history = useHistory();
    const location = useLocation();
    const [pageLoader, togglePageLoader] = useState(false);
    // const [paramAttemptQuiz, setParamAttemptQuiz] = useState({
    //     id: null,
    //     itemId: location.state.fromStudyMaterialPage.id,
    //     type: "studyMaterial",
    // });
    useEffect(() => {
        togglePageLoader(true);
        getStudyMaterialData(location.state.fromStudyMaterialPage?.id, () => {  
            togglePageLoader(false);
        });
    }, [])

    console.log("studyResource", studyMaterialData?.studyMaterial)
    return (
        <div className='parent'>
            <div className='parent-compo'>
                <div className='container-1'>
                    <PageHeader title={"Study Material"} style={{}} onBackPress={() => { history.push('/courses-list') }} />
                </div>
                <div className='container-3-videoResource'>
                    {quizFlowCount == 0 ? (
                        <>
                            <CourseDetailsDescriptionSection singleCourseDetailsDataContainer={studyMaterialData?.studyMaterial} fromStudyMaterialPage={location.state.fromStudyMaterialPage} />
                            {studyMaterialData?.studyMaterial?.topics?.length > 0 ?
                                <StudyMaterialContent singleCourseDetailsDataContainer={studyMaterialData?.studyMaterial?.topics} fromStudyMaterialPage={location.state.fromStudyMaterialPage}  />
                                : <></>}
                            {studyMaterialData?.studyMaterial?.learnings?.length > 0 ?
                                <CourseDetailsLearnings singleCourseDetailsDataContainer={studyMaterialData?.studyMaterial} fromStudyMaterialPage={location.state.fromStudyMaterialPage} />
                                : <></>}
                            {studyMaterialData?.related?.length > 0 ?
                                <RelatedStudyMaterial singleCourseDetailsDataContainer={studyMaterialData?.related} fromStudyMaterialPage={location.state.fromStudyMaterialPage} />
                                : <></>}
                        </>
                    ) : (
                        quizFlowCount == 1 ? (
                            <QuizDesign studyKey={true} />
                        ) : (
                            quizFlowCount == 2 ? (
                                <CourseResults studyKey={true} />
                            ) : (
                                quizFlowCount == 3 ? (
                                    <ViewQuizSolutions studyKey={true}  />
                                ) : null
                            )
                        )
                    )}


                    {/* <QuizDesign studyKey={true} paramAttemptQuiz={paramAttemptQuiz} /> */}

                    {/* <CourseResults studyKey={true} /> */}
                    {/* <ViewQuizSolutions   studyKey={true} /> */}
                </div>

            </div>
            <PageDataLoader visible={pageLoader} />
        </div>

    )
}

const mapStateToProps = (state) => {
    const { studyMaterialData, quizFlowCount,paramAttemptQuiz } = state.studentMarketPlace
    return {
        studyMaterialData, quizFlowCount,paramAttemptQuiz
    }
};

const mapDispatchToProps = (dispatch) => ({
    getStudyMaterialData: (id,callback) => dispatch(getStudyMaterialData(id,callback)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
    setParamAttemptQuiz: (val) => dispatch(setParamAttemptQuiz(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterials)
