import React, { useEffect, useState } from "react";
import { Dropdown, Tooltip, Typography } from 'antd';
import "./styles.css";
import pencilOutlined from "../Assets/layout/newFilledIcons/dark-pencil.svg";
import QuickAccessModal from "./quickAccessModal";
import { setMenuSettingsData } from "actions/userManagementV2";
import { connect } from "react-redux";
import { useHistory, withRouter } from 'react-router';


const CollapseQuickAccessModal = ({ items, updateUserMenuSettings, userId, fetchUserMenuSettingsData, allItems, userMenusettingsData, menuSettingsData, setDropdownVisibleCollapse, setCourseTypeName,setQuickAccessItem, setEditClick, editClick }) => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const history = useHistory();
    useEffect(() => {

        if (!dropdownVisible1) {
            fetchUserMenuSettingsData(userId, () => { }, () => { setSelectedItems(userMenusettingsData?.menuSettings); })
            setSelectedItems(userMenusettingsData?.menuSettings);
        }

    }, [dropdownVisible1]);

    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible1(visible);
        if (!visible) {
            if (menuSettingsData.length < 1) {
                alert("You must select at least 1 items.");
                setDropdownVisible1(true); // Keep the dropdown open
                return;
            }

            updateUserMenuSettings(userId, () => { }, () => { fetchUserMenuSettingsData(userId, () => { }, () => { setSelectedItems(userMenusettingsData?.menuSettings) }) })
        }
    };

    return (
        <div className="quick-access-menu" style={{ width: 'fit-content', marginLeft:60,marginTop:-40}}>

            {items.map((item, index) => (
                
                
                <div key={index}
                style={{margin:3}}
                onClick={() => {
                    if (item?.label == 'Add user') {
                        setQuickAccessItem( "addUser", true )
                        history.push({
                            pathname: "/user-management", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    } else if (item?.label == 'Add fee request') {
                        setQuickAccessItem("addFee",true)
                        history.push({
                            pathname: "/fee-management", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    } else if (item?.label == 'Add schedule') {
                        setQuickAccessItem("addSchedule",true)
                        history.push({
                            pathname: "/schedule", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    } else if (item?.label == 'Add announcement') {
                        setQuickAccessItem("addAnnounce",true)
                        history.push({
                            pathname: "/announcement", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    } else if (item?.label == 'Create course') {
                        setCourseTypeName('liveCourses')
                        history.push({
                            pathname: "/add-live-courses", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    } else if (item?.label == 'Add test') {
                        setQuickAccessItem("addTest",true)
                        history.push({
                            pathname: "/test", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    } else if (item?.label == 'Add resource') {
                        setQuickAccessItem("addResource",true)
                        history.push({
                            pathname: "/digital-library/division-card", state: {
                                key: {
                                    fromSideMenu: true,
                                }
                            }
                        })
                    }else if (item?.label == 'Mark attendance') {
                        setQuickAccessItem("markAttendance",true)
                        history.push({
                            pathname: "/user-profile/others",
                        })
                    }else if (item?.label == 'Apply leave') {
                        setQuickAccessItem("applyLeave",true)
                        history.push({
                            pathname: "/user-profile/others",
                        })
                    }

                }}
                >
                    {console.log('indexindex',index)}
                    <div
                        className="collapse-menu-item"
                        style={{ display: 'flex', padding: '10px', cursor: 'pointer', paddingTop:index == 0 ? 13 : 9 }}
                        onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
                        onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
                    >
                        <div>
                            <img
                                src={hoveredIndex === index ? item?.whiteIcon : item?.enabledIcon}
                                style={{ height: 24, width: 24 }}
                                alt="menu-icon"
                            />
                        </div>
                        <div style={{ marginLeft: 10,marginRight:20 }}>{item?.label}</div>
                    </div>
                </div>
            ))}
            <div className="collapse-menu-item" style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>


                <div className='custom-dropdown-position'>

                    <Dropdown
                        overlay={<QuickAccessModal
                            selectedItems={selectedItems}
                            allItems={allItems}
                            setSelectedItems={setSelectedItems}
                            setEditClick={setEditClick}
                            editClick = {editClick}

                        />}
                        visible={dropdownVisible1}
                        onVisibleChange={handleDropdownVisibleChange}
                        trigger={['click']}
                        placement='topLeft'
                        overlayStyle={{
                            width: "250px",
                            marginLeft: 80,
                        }}
                    >
                        <div style={{display:'flex'}}><img src={pencilOutlined} style={{ height: 20, width: 20 }} />
                            <div style={{ marginLeft: 15, alignItems:'center', paddingBottom:3 }} onClick={() => { setDropdownVisibleCollapse(false); setEditClick(true) }}>Edit list</div>
                        </div>


                    </Dropdown>
                    {/* </DndProvider> */}
                </div>
                {/* */}
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    const { userMenusettingsData, menuSettingsData } = state.userManagementV2
    return {
        userMenusettingsData, menuSettingsData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMenuSettingsData: (res) => dispatch(setMenuSettingsData(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseQuickAccessModal)
