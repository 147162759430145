import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Color } from "services";
import { orangecircle } from "./ongoingTest";
import { AiOutlinePlus } from "react-icons/ai";
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import {
	updateCurrentQ
} from "actions/marketTest";

const Up = require("../../Assets/test-new/ic-chevron-up.svg").default;
const Down = require("../../Assets/test-new/ic-chevron-down.svg").default;


const testPaletteStyle = (item, currentQIndex) => ({
	borderRadius: "50%",
	border: `${currentQIndex === item.index ? "2px" : "1px"} solid ${Color.midGrey}`,
	backgroundColor:
		currentQIndex === item.index
			? Color.midGrey
			: item.status === "Attempted" || item.status === "AttemptedAndBookmarked"
				? Color.purple
				: item.status === "Unanswered" || item.status === "Bookmarked"
					? Color.wrong
					: "transparent",
	color:
		currentQIndex === item.index || item.status === "Unseen"
			? Color.white
			: item.status == "None" || !item.status
				? Color.darkGrey
				: "white",
	position: "relative",
});

const resultPaletteStyle = (item, currentQIndex) => ({
	borderRadius: "50%",
	border: `${currentQIndex === item.index ? "3px" : "1px"} solid ${Color.midGrey}`,
	backgroundColor:item?.submitStatus === "Unseen" ? '#FFFFFF' : 
		item?.correct === "Correct"
			? Color.seaGreen
			: item?.correct === "Incorrect"
				? Color.wrong
				: item?.correct === "PartialCorrect"
					? "linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)"
					: (item?.submitStatus === "Unanswered" ||
						item?.submitStatus === "Bookmarked" ||
						item?.correct === "None")
						? "#AEAEAE"
						: item?.submitStatus === "Unseen"
							? "#FFFFFF"
							: "transparent",
	color:
		item?.submitStatus === "Unanswered" ||
			item?.submitStatus === "Unseen" ||
			item?.submitStatus === "Bookmarked" ||
			item?.correct === "None"
			? Color.black
			: "white",
	// position: "relative",
});

const circle = (item, currentQIndex, onQuestionSelect, isResult) => {
	console.log("item",item);
	return (
		<Col
			key={item.index}
			className="questionSelector r-c-c cursor-pointer m-t-5"
			style={
				isResult
					? resultPaletteStyle(item, currentQIndex)
					: testPaletteStyle(item, currentQIndex)
			}
			onClick={
				isResult
					? () => onQuestionSelect(item.index)
					: () => onQuestionSelect(null, undefined, item.index)
			}
		>
			<span> {item.index}</span>
			{item?.status == "Bookmarked" || item?.status == "AttemptedAndBookmarked"
				? orangecircle(0)
				: null}
		</Col>
	);
};

export function MarketTestPalette(props) {
	const { currentQIndex, onQuestionSelect, palateData, isResult } = props;

	const [closed, setClosed] = useState({});

	useEffect(() => {
		const closed_obj = {};
		palateData?.map((item, index) => {
			closed_obj[item.subject?.name] = false;
		});
		setClosed(closed_obj);
	}, []);

	const toggleViewHandler = (name) => {
		setClosed((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};

	return (
		<div className="questionPalette">
			<div className="bold-600" style={{ color: "#AEAEAE", marginBottom: 10 }}>
				ALL QUESTIONS
			</div>

			{palateData?.map((subject, index) => (
				<div key={index} style={{}}>
					<div
						className="r-jsb"
						style={{ cursor: "pointer" }}
						onClick={() => toggleViewHandler(subject?.subject?.name)}
					>
						<div className="text-sm bold-600 m-b-10">
							{subject?.subject?.name}
						</div>
						<div><img src={closed[subject?.subject?.name] ? Down : Up} /></div>
					</div>

					{!closed[subject?.subject?.name] &&
						subject?.sections?.map((section, index) => (
							<div key={index} className="text-xmd bold-600 m-b-10 m-l-10">
								{section.sectionName != "_default_" && (
									<div>
										{section.sectionName}
										<span style={{ color: "grey" }} className="bold-400">
											&ensp; | &ensp;
											{`${section?.questions[0]?.index} - 
										${section?.questions[0]?.index + section?.questions?.length - 1}`}
										</span>
									</div>
								)}

								<Row>
									{section?.questions.map((item) =>
										circle(item, currentQIndex, onQuestionSelect, isResult)
									)}
								</Row>
							</div>
						))}
				</div>
			))}
		</div>
	);
}


function AdminPaletteComponent({
	currentQId,
	selectedSecId,
	onQuestionSelect,
	palateData,
	buttonLoader,
	updateCurrentQ
}) {

	const [closed, setClosed] = useState({});

	useEffect(() => {
		const closed_obj = {};
		palateData?.map((item, index) => {
			closed_obj[item.testSeriesSubject?.subject?.name] = false;
		});
		setClosed(closed_obj);
	}, []);

	const toggleViewHandler = (name) => {
		setClosed((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};

	let idx = 0

	return (
		<div className="questionPalette" style={{ height: "97%" }}>
			<div className="bold-600" style={{ color: "#AEAEAE", marginBottom: 10 }}>
				ALL QUESTIONS
			</div>

			{palateData?.map((subject, index) => (
				<div key={index} style={{}}>
					<div
						className="r-jsb"
						style={{ cursor: "pointer" }}
						onClick={() => toggleViewHandler(subject?.testSeriesSubject?.subject?.name)}
					>
						<div style={{ color: '#636363' }} className="text-sm bold-600 m-b-10">
							{subject?.testSeriesSubject?.subject?.name}
							{subject?.individualTestSection[0]?.name == "_default_" && subject?.individualTestSection[0]?.mandatoryQuestions != "-1" &&
								<span style={{ color: "grey", fontSize: '0.8em' }} className="bold-400">
									&ensp; | &ensp;
									{`${subject?.individualTestSection[0]?.mandatoryQuestions} / ${subject?.individualTestSection[0]?.totalQuestions}`}
								</span>
							}
						</div>

						<div><img src={closed[subject?.testSeriesSubject?.subject?.name] ? Down : Up} /></div>
					</div>

					{!closed[subject?.testSeriesSubject?.subject?.name] &&
						subject?.individualTestSection?.map((section, index) => (
							<div key={index} className="text-xmd m-b-10">
								{section.name != "_default_" && (
									<div className="bold-600">
										{section.name}
										{section?.mandatoryQuestions != "-1" &&
											<span style={{ color: "grey" }} className="bold-400">
												&ensp; | &ensp;
												{`${section?.mandatoryQuestions} / ${section?.totalQuestions}`}
											</span>
										}
									</div>
								)}

								<Row>
									{section?.individualTestQuestion?.map((item) => {
										item['index'] = ++idx

										return <Col
											key={item.id}
											className="questionSelector r-c-c cursor-pointer m-t-5"
											style={{
												borderRadius: "50%",
												border: `2px solid`,
												borderColor: currentQId === item.id ? '#636363' : Color.midGrey
											}}
											onClick={() => { onQuestionSelect(item.index, item.id, section.id, subject.id, section.markingSchemeData) }}
										>
											{(buttonLoader && currentQId === item.id) ? <LoadingOutlined /> : idx}
										</Col>
									})}

									<Col
										onClick={() => {
											onQuestionSelect(null, null, section.id, subject.id, section.markingSchemeData);
										}}
										style={{
											fontSize: 16,
											borderRadius: 0, // Set borderRadius to 0 to remove the circle
											// border: `2px solid transparent`, // Set border to transparent to hide the border
											borderColor: (!currentQId && section.id === selectedSecId) ? '#636363' : Color.midGrey
										}}
										className="questionSelector r-c-c cursor-pointer m-t-5"
									>
										{buttonLoader && (!currentQId && section.id === selectedSecId) ? (
											<LoadingOutlined />
										) : null}
									</Col>
								</Row>
							</div>
						))}
				</div>
			))}
		</div >
	);
}



const mapStateToProps = (state) => {
	const { palateData, currentQ, currentQId } = state.marketTest;
	return { palateData, currentQ, currentQId };
};

const mapDispatchToProps = (dispatch) => ({
	updateCurrentQ: (data) => dispatch(updateCurrentQ(data)),
});

export const AdminPalette = connect(mapStateToProps, mapDispatchToProps)(AdminPaletteComponent);