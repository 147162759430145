import React, { useState, useEffect } from 'react'
import BarsComponent from './barsComponent'
import { Popover, Select } from 'antd';
import { connect } from 'react-redux';
import image2 from '../../../Assets/care/ic-calendar.svg';
import { setIsImpressionsOrEngagements, fetchDashBoardData, fetchPerfomanceGraph, setIsMonthOrWeek } from 'actions/digitalLibrary';
import ToolTipB from '../components/toolTipB';
import image from '../../../Assets/care/no-subjects.svg'
import CalenderPopUp from '../components/calenderPopUp';
import moment from 'moment';
import { CaretLeftOutlined, CaretRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';





setIsMonthOrWeek('week')

const content = (
    <div className="popOverBp" style={{ width: "300px" }}>
        <CalenderPopUp digitalLib={true} />
    </div>
);
var max = 0;

function setScale(array) {

    array.forEach(function (item) {
        if (max < parseInt(item)) {
            max = item;
        }
    })

    var var1 = parseInt(Math.ceil((max) / 5));
    for (let i = 0; i < 6; i++) {
        arrayAxis[5 - i] = (var1 * i);

    }
   

}

const arrayAxis = []

function AdminSummary({storePerfomanceData,adminDashBoardData, fetchDashBoardData,fetchPerfomanceGraph, togglePageLoader, setIsImpressionsOrEngagements, isImpressionsOrEngagements, pageLoader,loaders, startDate, endDate ,storeMonthOrWeek,setIsMonthOrWeek}) {
    console.log("AA-isMonthOrWeekq",storeMonthOrWeek);
   
    useEffect(() => {
        // setIsMonthOrWeek('week')
        togglePageLoader(true)
        fetchPerfomanceGraph(isYear,()=>{},togglePageLoader(false))
    }, [])
    
    const [scaleY, setScaleY] = useState([])
    const [isYear,setIsYear] = useState(moment().format('YYYY'))
    var array = [];

    useEffect(() => {
        var count = 0;
        if(storeMonthOrWeek == 'week'){
        for (let i = 0; i < storePerfomanceData?.length ; i++) {
            array[count++] = isImpressionsOrEngagements == 'Impressions' ? storePerfomanceData[i].impressionCountStudent : storePerfomanceData[i].engagementCountStudent;
            array[count++] = isImpressionsOrEngagements == 'Impressions' ? storePerfomanceData[i].impressionCountTeacher : storePerfomanceData[i].engagementCountTeacher;
        }
        }else{
            console.log("AA-month");
        for (let i = 0; i < storePerfomanceData?.monthlyImpressionsEngagements?.length ; i++) {

            array[count++] = isImpressionsOrEngagements == 'Impressions' ? storePerfomanceData?.monthlyImpressionsEngagements[i].impressionCountStudent : storePerfomanceData?.monthlyImpressionsEngagements[i].engagementCountStudent;
            array[count++] = isImpressionsOrEngagements == 'Impressions' ? storePerfomanceData?.monthlyImpressionsEngagements[i].impressionCountTeacher : storePerfomanceData?.monthlyImpressionsEngagements[i].engagementCountTeacher;
        }
        
        }
        setScale(array)
        setScaleY([...arrayAxis]);
       
        
    }, [storePerfomanceData,])
    
    function handleChange1(value) {
        togglePageLoader(true)
        setIsMonthOrWeek(value)
        // fetchDashBoardData(() => togglePageLoader(false))
        
        fetchPerfomanceGraph(isYear,()=>{},togglePageLoader(false))
    }

    function handleChange(value) {
        togglePageLoader(true)
        setIsImpressionsOrEngagements(value)
        // fetchDashBoardData(() => togglePageLoader(false))
        fetchPerfomanceGraph(isYear,()=>{},togglePageLoader(false))

    }
    const onClickArrow=(direction)=>{
        if(direction == 'left'){
           const year= moment(isYear)
           setIsYear(year.subtract(1,'years'))
           fetchPerfomanceGraph(year,()=>{},togglePageLoader(false))   
        }
        else{
            const year= moment(isYear)
            setIsYear(year.add(1,'years'))
            fetchPerfomanceGraph(year,()=>{},togglePageLoader(false))

            
        }

    }

    return (
        <div className="adminSummaryCardB" style={{ width: "98%" }} >
            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "90%" }}>
                <div style={{ display: "flex", msFlexDirection: "row", justifyContent: "space-between", width: "40%" }}>
                    <div>
                        <h1 className="adminSummary_h1" style={{ marginBottom: "0px", display: "flex" }}>Performance<span style={{ marginLeft: "4px", width: "15px" }}><ToolTipB content='right' /></span></h1>
                    </div>
                    {storeMonthOrWeek == 'week' ?
                                <p className="adminSummary_p">{adminDashBoardData?.[0]?.date ? adminDashBoardData?.[0]?.date : moment().startOf('week').format('YYYY-MM-DD')} - {adminDashBoardData?.[6]?.date ? adminDashBoardData?.[6]?.date : moment().startOf('week').add(6, 'days').format('YYYY-MM-DD')}</p>
                                :
                                <p className="adminSummary_p">Jan-Dec {moment().year()} </p>
                            }
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "start" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#FF83D0", marginRight: "10px" }}></div><span>Student</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", marginTop: "2%" }}>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#579EFF", marginRight: "10px" }}></div><span style={{ marginRight: "10px" }}>Teacher</span>
                    </div>
                    <div>

                    </div>
                    <div style={{ display: "flex", marginLeft: "3%", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
                            <div><img style={{ width: "50px", height: "15px" }} src={image2} alt="" /></div>
                            <div id="testHomeDropdown" style={{display:'flex'}}>
                                <Select
                                    className="inputSelecter"
                                    value={storeMonthOrWeek}
                                    style={{ width: "100%", border: "none" }}
                                    onChange={handleChange1}
                                    bordered={false}
                                    removeIcon={true}
                                    dropdownMatchSelectWidth={true}
                                    getPopupContainer={() => document.getElementById("testHomeDropdown")}
                                >
                                    <Option value="week">Week</Option>
                                    <Option value="month">Months</Option>
                                </Select>
                                {storeMonthOrWeek == 'week' ?
                                <Popover placement="bottomRight" dropdownClassName={"dropDownB"} content={content} trigger="click" style={{ width: '300px' }} getPopupContainer={() =>document.getElementById("testHomeDropdown")}>
                                    <img className="calenderIcon" style={{ width: "20px", height: "20px", marginRight: "2px",marginTop:'5px'  }} src={image} alt="" />
                                </Popover>
                                : null}
                            </div>    
                        </div>
                    <div style={{ width: "127px" }} id="testHomeDropdown">
                        <Select defaultValue="Impressions" style={{ width: 127 }} onChange={handleChange}
                            getPopupContainer={() => document.getElementById("testHomeDropdown")}

                        >
                            <Select.Option value="Impressions">Impressions</Select.Option>
                            <Select.Option value="Engagements">Engagements</Select.Option>
                        </Select>

                    </div>
                </div>
            </div> */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "98%" }}>
                    <div style={{ display: "flex", msFlexDirection: "row", justifyContent: "space-between", width: "40%" }}>
                        <div>
                            {console.log("startDate",startDate)}
                            <h1 className="adminSummary_h1" style={{ marginBottom: "0px", display: "flex" }}>Performance<span style={{ marginLeft: "4px", width: "15px" }}><ToolTipB content='right' /></span></h1>
                            {storeMonthOrWeek == '' || storeMonthOrWeek == 'week' ?
                                <p className="adminSummary_p">
                                    {startDate !='' ? startDate :moment().startOf('week').format('DD-MM-YYYY')} - {endDate !='' ? endDate  :moment().startOf('week').format('DD-MM-YYYY') }{/* {moment(storePerfomanceData?.[0]?.date).format('DD-MM-YYYY') ?moment(storePerfomanceData?.[0]?.date).format('DD-MM-YYYY') : moment().startOf('week').format('DD-MM-YYYY')} - { moment(storePerfomanceData?.[6]?.date).format('DD-MM-YYYY') ? moment(storePerfomanceData?.[6]?.date).format('DD-MM-YYYY') : moment().startOf('week').add(6, 'days').format('DD-MM-YYYY')} */}</p>
                                :
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "60%" }}>
   
                           <CaretLeftOutlined onClick={()=>{onClickArrow('left')}} />
                           <div style={{ marginBottom: "0px" }}>{moment(isYear).format('YYYY')}</div>
                           {moment(isYear).format('YYYY') < moment().format('YYYY') ? 
                           <CaretRightOutlined onClick={()=>{onClickArrow('right')}}/>:null}
                       </div>
                            }
                        </div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start" }}>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                            <div style={{ width: "20px", height: "20px", backgroundColor: "#FF83D0", marginRight: "10px" }}></div><span>Students</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", marginTop: "2%" }}>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#579EFF", marginRight: "10px" }}></div><span style={{ marginRight: "10px" }}>Teacher</span>
                        </div>
                        <div style={{ display: "flex", marginLeft: "3%", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
                            {/* <div><img style={{ width: "50px", height: "15px" }} src={image2} alt="" /></div> */}
                            <div id="testHomeDropdown" style={{display:'flex'}}>
                                {console.log("AA-j",storeMonthOrWeek)}
                                <Select
                                    className="inputSelecter"
                                    // defaultValue={storeMonthOrWeek}
                                    value={storeMonthOrWeek == '' ? 'week' : storeMonthOrWeek}
                                    style={{ width: "100%", border: "none" }}
                                    onChange={handleChange1}
                                    bordered={false}
                                    removeIcon={true}
                                    dropdownMatchSelectWidth={true}
                                    getPopupContainer={() => document.getElementById("testHomeDropdown")}
                                >
                                    <Option value="week">Week</Option>
                                    <Option value="month">Months</Option>
                                </Select>
                                {storeMonthOrWeek == 'week'?
                                <Popover placement="bottomRight" dropdownClassName={"dropDownB"} content={content} trigger="click" style={{ width: '300px' }} getPopupContainer={() =>document.getElementById("testHomeDropdown")}>
                                    <img className="calenderIcon" style={{ width: "20px", height: "20px", marginRight: "2px",marginTop:'5px'  }} src={image2} alt="" />
                                </Popover>
                                : null}
                            </div>
                            
                        </div>



                        <div style={{ marginLeft: "2%" }}>

                            <Select defaultValue="Impressions" style={{ width: 120 }} onChange={handleChange}
                                getPopupContainer={() => document.getElementById("testHomeDropdown")}

                            >
                                <Option value="Impressions">Impressions</Option>
                                <Option value="Engagements">Engagements</Option>
                            </Select>

                        </div>
                    </div>
                </div>
            {scaleY[4] != 0 ?
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "5%", height: "220px", justifyContent: "space-between" }}>
                        {scaleY.map((item) =>
                            <div className="daysOrMonths" key={item.id}>{item}</div>
                        )}
                    </div>
                    {storeMonthOrWeek == 'week' ? 
                    <div className="barGraphArea"  >
                        <div style={{ width: "100%", height: "210px" }}>
                            <div style={{ display: "flex", flexDirection: "row", width: "97%", height: "210px", justifyContent: "space-between" }}>
                               
                                {Array.isArray(storePerfomanceData) && storePerfomanceData?.map((item) =>

                                    <div key={item.id} style={{ height: "100%", width: "100%", display: pageLoader ? 'none' : 'inline' }}>
                                        <BarsComponent impressionCountStudent={isImpressionsOrEngagements == 'Impressions' ? item.impressionCountStudent : item.engagementCountStudent} impressionCountTeacher={isImpressionsOrEngagements == 'Impressions' ? item.impressionCountTeacher : item.engagementCountTeacher} dateOrMonth={item.short_date ? item.short_date : item.month} maxHeight={Math.max(...arrayAxis)} />
                                    </div>

                                )}
                            </div>
                        </div>
                    </div> :
                    <div className="barGraphArea"  >
                    <div style={{ width: "100%", height: "210px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "97%", height: "210px", justifyContent: "space-between" }}>

                           
                            { storePerfomanceData?.monthlyImpressionsEngagements?.map((item) =>

                                <div key={item.id} style={{ height: "100%", width: "100%", display: pageLoader ? 'none' : 'inline' }}>
                                    <BarsComponent impressionCountStudent={isImpressionsOrEngagements == 'Impressions' ? item.impressionCountStudent : item.engagementCountStudent} impressionCountTeacher={isImpressionsOrEngagements == 'Impressions' ? item.impressionCountTeacher : item.engagementCountTeacher} dateOrMonth={item.short_date ? item.short_date : item.month} maxHeight={Math.max(...arrayAxis)} />
                                </div>

                            )}
                        </div>
                    </div>
                </div>}
                </div>
                :
                <div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ width: "30%", height: "30%", marginBottom: "20px" }} src={image} alt="" />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try later.</p>
                        </div>
                    </div>
                </div>

            }

        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        adminDashBoardData,
        isImpressionsOrEngagements,
        storeMonthOrWeek,
        storePerfomanceData,
        startDate,
        endDate,
        

    } = state.digitalLibrary;
    const { loaders } = state.testAdmin
    return {

        adminDashBoardData,
        isImpressionsOrEngagements,
        storeMonthOrWeek,
        storePerfomanceData,
        loaders,
        startDate,
        endDate,
        
        
    };
};

const mapDispatchToProps = (dispatch) => ({
    setIsImpressionsOrEngagements: (m) => dispatch(setIsImpressionsOrEngagements(m)),
    fetchDashBoardData: (callback) => dispatch(fetchDashBoardData(callback)),
    setIsMonthOrWeek: (m) => dispatch(setIsMonthOrWeek(m)),
    fetchPerfomanceGraph:(year,callback)=>dispatch(fetchPerfomanceGraph(year,callback)),
    setIsMonthOrWeek:(res) => dispatch(setIsMonthOrWeek(res))


});

export default connect(
    mapStateToProps,
    mapDispatchToProps,

)(AdminSummary);



