import { Color } from "services";

const Styles = {
  toolbarContainer: {
    paddingTop:30,
    paddingBottom:30,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 40,
    padding: '10px',
    height: "70%",
    borderRadius: 10,
    boxShadow: '1px 3px 10px #d4d3d3',
    // float: 'none',
    marginLeft: '20px',
    justifyContent: 'space-around',
    position:"absolute",
    zIndex:100,
    // minHeight: "450px"
    // left: '5%',
    // transform: 'translateX(-50%)'
  },
  toolbarBtn: {
    height: 30,
    width:30,
    margin: 10,
    marginBottom: 2,
    cursor: 'pointer'
  },
  colorPickerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  colorPickerBtn: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: 'green',
  },
  fab: {
    backgroundColor: Color.purple,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    width: 50,
    height: 50,
    bottom: 20,
    right: 20,
    position: 'absolute',
  },
  centeredView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
  },
  bottomButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
  },
  modalDetails: {
    width: '100%',
    maxWidth: 600,
    padding: 15,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  vidbuttons: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: 10,
    margin: 10,
    borderRadius: 10,
  },
  vidRowbuttons: {
    backgroundColor: Color.purple,
    borderRadius: 10,
    padding: 10,
  },
  IconVideo: {
    fontSize: 24,
    color: 'white',
  },
  modalView: {
    paddingBottom: 10,
    justifyContent: 'center',
    backgroundColor: Color.primary,
    flexDirection: 'row',
    width: '100%',
  },
  modalViewRow: {
    maxWidth: 600,
    padding: 10,
    justifyContent: 'space-around',
    flexDirection: 'row',
    backgroundColor: Color.primary,
    flex: 1,
  },
  endBox: {
    position: 'absolute',
    bottom: 70,
    left: 0,
    right: 0,
    alignItems: 'center',
  },
  endCall: {
    padding: 10,
    backgroundColor: Color.pinkRed,
    borderRadius: 40,
    alignItems: 'center',
  },
  toggleContainer: {
    position: 'relative',
    backgroundColor: Color.midGrey,
    borderRadius: 10,
    margin: 10,
  },
  toggleViewSmall: {
    position: 'relative',
    backgroundColor: Color.midGrey,
    borderRadius: 100,
    marginHorizontal: 100,
    marginTop: 25,
    marginBottom: 15,
  },
  toggleView: {
    backgroundColor: 'white',
  },
  buttonBorder: {
    margin: 3,
    borderRadius: 15,
  },
  buttonAnim: {
    height: '100%',
    width: '50%',
    position: 'absolute',
    backgroundColor: Color.purple,
    margin: 3,
    borderRadius: 100,
  },
  onLineCardBox: {
    display:"flex",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    marginBottom:10
  },
  listIcons: {
    color: Color.dryGrey,
    fontSize: 20,
  },
  doubtBtn: {
    backgroundColor: Color.purple,
    padding: 10,
    borderRadius: 10,
    width:100,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  doubtBtnTxt: {
    color: '#fff',
    fontSize: 16,
  },
  topBarStyle: {
    zIndex: 1,
    backgroundColor: 'white',
    padding: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  leftModal: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    margin: 0,
    width: 400,
    minWidth: 400,
    alignSelf: 'flex-end',
  },
  greenBadge: {
    width: 17,
    height: 17,
    borderRadius: 9,
    top: -6,
    right: -6,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.brightGreen,
    position: 'absolute',
  },
  redBadge: {
    width: 17,
    height: 17,
    borderRadius: 9,
    top: -6,
    right: -6,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.pinkRed,
    position: 'absolute',
  },
};

export default Styles;
