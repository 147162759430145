import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "./Layout.js";

const PrivateRoute = ({ component, exact = false, path, allowed }) => (
  <Route
    exact={exact}
    path={path}
    render={props =>
      allowed ? (
        <Layout>{React.createElement(component, props)}</Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

// const { object, bool, string, func } = PropTypes;

// PrivateRoute.propTypes = {
//   component: func.isRequired,
//   exact: bool,
//   path: string.isRequired,
//   authenticated: bool.isRequired,
//   location: object
// };

export default PrivateRoute;
