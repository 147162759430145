import message from "antd/lib/message";
import "antd/lib/message/style/index";
import { Api } from "../services";
import moment from "moment";

export const UPDATE_ASSIGNMENT_LIST = "UPDATE_ASSIGNMENT_LIST";
export const SET_LOCAL_IMAGE = "SET_LOCAL_IMAGE";
export const SET_FINISH_MODE = "SET_FINISH_MODE";
export const UPDATE_STUDENT_ASSIGNMENT_LIST = "UPDATE_STUDENT_ASSIGNMENT_LIST";
export const TOGGLE_ASSIGNMENT_LOADER = "TOGGLE_ASSIGNMENT_LOADER";
export const TOGGLE_ASSIGNMENT_TASK_LOADER = "TOGGLE_ASSIGNMENT_TASK_LOADER";
export const SET_CURRENT_ASSIGNMENT = "SET_CURRENT_ASSIGNMENT";
export const UPDATE_FILTER_PARAMS = "UPDATE_FILTER_PARAMS";
export const SET_CURRENT_ASSIGNMENT_TASK = "SET_CURRENT_ASSIGNMENT_TASK";
export const TOGGLE_ASSIGNMENT_MODAL = "TOGGLE_ASSIGNMENT_MODAL";
export const TOGGLE_QUESTION_MODAL = "TOGGLE_QUESTION_MODAL";
export const TOGGLE_INSTRUCTION_MODAL = "TOGGLE_INSTRUCTION_MODAL";
export const UPDATE_POST_PARAMS = "UPDATE_POST_PARAMS";
export const TOGGLE_STATUS_LOADER = "TOGGLE_STATUS_LOADER";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const SET_LOCAL_STATUS_STUDENT = "SET_LOCAL_STATUS_STUDENT";
export const REMOVE_CURRENT_QUESTION = "REMOVE_CURRENT_QUESTION";
export const SET_CURRENT_STATEMENT = "SET_CURRENT_STATEMENT";
export const SET_CURRENT_ANSWER = "SET_CURRENT_ANSWER";
export const SET_CURRENT_IMAGE = "SET_CURRENT_IMAGE";
export const SET_CURRENT_MARKS = "SET_CURRENT_MARKS";
export const SET_ANSWER_TYPE = "SET_ANSWER_TYPE";
export const SET_ANSWER = "SET_ANSWER";
export const SET_CURRENT_OPTIONS = "SET_CURRENT_OPTIONS";
export const UPDATE_STUDENT_LIST = "UPDATE_STUDENT_LIST";
export const UPDATE_TEACHER_PAGE = "UPDATE_TEACHER_PAGE";
export const TOGGLE_ANS_LOADER = "TOGGLE_ANS_LOADER";
export const SET_STUDENT_ANSWERS = "SET_STUDENT_ANSWERS";
export const UPDATE_STUDENT_REVIEW = "UPDATE_STUDENT_REVIEW";
export const UPDATE_REVIEW_PARAMS = "UPDATE_REVIEW_PARAMS";
export const UPDATE_TOTAL_MARKS = "UPDATE_TOTAL_MARKS";
export const TOGGLE_CORRECT_OPT = "TOGGLE_CORRECT_OPT";
export const SET_OPTION_IMAGE = "SET_OPTION_IMAGE";
export const UPDATE_QUESTION_FEEDBACK = "UPDATE_QUESTION_FEEDBACK";
export const SET_QUESTIONS_FEEDBACK = "SET_QUESTIONS_FEEDBACK";
export const TOGGLE_RESULT_LOADER = "TOGGLE_RESULT_LOADER";
export const TOGGLE_REMINDER_LOADER = "TOGGLE_REMINDER_LOADER";
export const TOGGLE_REVIEW_LOADER = "TOGGLE_REVIEW_LOADER";
export const SET_CURRENT_ASSIGNMENT_PARAMS = "SET_CURRENT_ASSIGNMENT_PARAMS";
export const SET_CURRENT_QUESTION_PARAMS = "SET_CURRENT_QUESTION_PARAMS";
export const UPDATE_STUDENT_ASSIGNMENT_STATUS =
  "UPDATE_STUDENT_ASSIGNMENT_STATUS";
export const UPLOAD_IMG_ARR = "UPLOAD_IMG_ARR";
export const REMOVE_UPLOADED_IMG = "REMOVE_UPLOADED_IMG";
export const GET_REMOVE_RESPONSE = "GET_REMOVE_RESPONSE";
export const UPDATE_CSS = "UPDATE_CSS";
export const SET_CKEDITOR_STATEMENT = "SET_CKEDITOR_STATEMENT";
export const SET_CKEDITOR_OPTION = "SET_CKEDITOR_OPTION";
export const CLEAR_CKEDITOR_STATEMENT = "CLEAR_CKEDITOR_STATEMENT";
export const UPDATE_IMAGE_LIST = "UPDATE_IMAGE_LIST";
export const READ_QUILL_EDITOR_TEXT = "READ_QUILL_EDITOR_TEXT";
export const DISPLAY_OPTION_MODAL = "DISPLAY_OPTION_MODAL";
export const DISPLAY_OPTION_INDEX_READ = "DISPLAY_OPTION_INDEX_READ";
export const SET_OPTION_VALUE = "SET_OPTION_VALUE";
export const SET_STUDENT_ASSIGNMENT = "SET_STUDENT_ASSIGNMENT";
export const SET_STUDENT_QUESTION_INDEX = "SET_STUDENT_QUESTION_INDEX";
export const UPDATE_ASSIGNMENT_SEARCH = "UPDATE_ASSIGNMENT_SEARCH";
export const UPDATE_SUB_FILTER = "UPDATE_SUB_FILTER";
export const SET_STUDENT_ASSIGNMENT_TASKS = "SET_STUDENT_ASSIGNMENT_TASKS";
export const DELETE_ANSWER_IMAGE = "DELETE_ANSWER_IMAGE";
export const REPLACE_ANSWER_IMAGE = "REPLACE_ANSWER_IMAGE";
export const SET_REMOTE_IMAGE = "SET_REMOTE_IMAGE";
export const BOOKMARK_QUESTION = "BOOKMARK_QUESTION";
export const UPDATE_DONUT_DATA = "UPDATE_DONUT_DATA";
export const UPDATE_SUBMIT_DETAILS = "UPDATE_SUBMIT_DETAILS";
export const ANSWERING_LOADER_TOGGLE = "ANSWERING_LOADER_TOGGLE";
export const SUBMIT_MODAL_TOGGLE = "SUBMIT_MODAL_TOGGLE";
export const SET_STUDENT_ID = "SET_STUDENT_ID";
export const UPDATE_Q_TIME = "UPDATE_Q_TIME";
export const UPDATE_SEARCHED_CHAPTERS = "UPDATE_SEARCHED_CHAPTERS";
export const UPDATE_NEW_STUDENT_ASSIGNMENT_LIST =
  "UPDATE_NEW_STUDENT_ASSIGNMENT_LIST";
export const UPDATE_SELECTED_TAB = "UPDATE_SELECTED_TAB";
export const TOGGLE_VIEW_Q_MODAL = "TOGGLE_VIEW_Q_MODAL";
export const UPDATE_REVIEW_TIME = "UPDATE_REVIEW_TIME";
export const RESET_DELETED_ASSIGNMENT_IMAGES =
  "RESET_DELETED_ASSIGNMENT_IMAGES";
export const TOGGLE_TEACHER_ASSIGNMENT_REMINDER_LOADER = "TOGGLE_TEACHER_ASSIGNMENT_REMINDER_LOADER";


export const updateReviewTime = value => ({
  type: UPDATE_REVIEW_TIME,
  value
});

export const notifyTeacherAssignment = () =>{
  return (dispatch, getState) => {
    const { currentAssignment } = getState().assignment;
    const params = {
      teacherId: currentAssignment.teacherId,
      assignmentId: currentAssignment.id,
    };
    dispatch(toggleTeacherTestRemincerLoader(true))
    const url = `/assignment/send-reminder`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          dispatch(toggleTeacherTestRemincerLoader(false))
        });
    });
  };
}

const toggleTeacherTestRemincerLoader = (bool) => ({
  type: TOGGLE_TEACHER_ASSIGNMENT_REMINDER_LOADER,
  bool
})

export const updateSelectedTab = key => ({
  type: UPDATE_SELECTED_TAB,
  key
});

export const setCkeditorStatement = entities => ({
  type: SET_CKEDITOR_STATEMENT,
  entities
});
export const setCkeditorOption = entities => ({
  type: SET_CKEDITOR_OPTION,
  entities
});

export const updateCss = bool => ({
  type: UPDATE_CSS,
  bool
});
export const uploadedImgArr = obj => ({
  type: UPLOAD_IMG_ARR,
  obj
});
export const removeUploadedImg = index => ({
  type: REMOVE_UPLOADED_IMG,
  index
});
export const getRemoveResponse = bool => ({
  type: GET_REMOVE_RESPONSE,
  bool
});
export const toggleAssignmentModal = state => ({
  type: TOGGLE_ASSIGNMENT_MODAL,
  state
});

export const setOptionImage = (index, value, image) => ({
  type: SET_OPTION_IMAGE,
  index,
  value,
  image
});

export const toggleCorrectOption = index => ({
  type: TOGGLE_CORRECT_OPT,
  index
});

export const addTotalMarks = totalMarksObtained => ({
  type: UPDATE_TOTAL_MARKS,
  totalMarksObtained
});

export const toggleQuestionModal = state => ({
  type: TOGGLE_QUESTION_MODAL,
  state
});

export const toggleInstructionModal = state => ({
  type: TOGGLE_INSTRUCTION_MODAL,
  state
});

export const updateAssignmentList = response => ({
  type: UPDATE_ASSIGNMENT_LIST,
  payload: response
});

export const updateTeacherCurrentPage = page => ({
  type: UPDATE_TEACHER_PAGE,
  page
});

export const toggleAssignmentLoader = value => ({
  type: TOGGLE_ASSIGNMENT_LOADER,
  value
});

export const toggleStatusLoader = value => ({
  type: TOGGLE_STATUS_LOADER,
  value
});

export const toggleAssignmentTaskLoader = value => ({
  type: TOGGLE_ASSIGNMENT_TASK_LOADER,
  value
});

export const answeringLoaderToggle = value => ({
  type: ANSWERING_LOADER_TOGGLE,
  value
});

export const toggleViewQuestionModal = bool => {
  return {
    type: TOGGLE_VIEW_Q_MODAL,
    bool
  };
};

export const setCurrentAssignment = data => ({
  type: SET_CURRENT_ASSIGNMENT,
  data
});

export const setOptionValue = (data, index) => ({
  type: SET_OPTION_VALUE,
  data,
  index
});

export const setCurrentAssignmentTasks = (assignmentTasks, pageMeta) => ({
  type: SET_CURRENT_ASSIGNMENT_TASK,
  assignmentTasks,
  pageMeta
});

export const setCurrentQuestionParams = (value, key) => ({
  type: SET_CURRENT_QUESTION_PARAMS,
  param: { [key]: value }
});

export const setCurrentQuestion = data => ({
  type: SET_CURRENT_QUESTION,
  data
});

export const removeCurrentQuestion = () => ({
  type: REMOVE_CURRENT_QUESTION
});

export const setCurrentStatement = value => ({
  type: SET_CURRENT_STATEMENT,
  value
});

export const setCurrentAnswer = value => ({
  type: SET_CURRENT_ANSWER,
  value
});

export const setCurrentImage = (value, image) => ({
  type: SET_CURRENT_IMAGE,
  value,
  image
});

export const setCurrentMarks = value => ({
  type: SET_CURRENT_MARKS,
  value
});

export const setAnswerType = (value, id) => ({
  type: SET_ANSWER_TYPE,
  value,
  id
});

export const setCurrentOptions = (value, index) => ({
  type: SET_CURRENT_OPTIONS,
  value,
  index
});

export const saveReview = params => {
  const url = `/assignment/review-assignment`;
  return dispatch => {
    dispatch(toggleAnsLoaderLoader(true));
    return Api.post(url)
      .params(params)
      .send((response, error) => {
        dispatch(toggleAnsLoaderLoader(false));
        if (response && response.show.type === "success") {
          dispatch(
            updateStudentAssignmentStatus(
              "Graded",
              params.totalMarks,
              params.studentId
            )
          );
        }
      });
  };
};

export const updateStudentAssignmentStatus = (status, marks, index) => ({
  type: UPDATE_STUDENT_ASSIGNMENT_STATUS,
  status,
  marks,
  index
});

export const getAssignment = (params = null) => {
  const url = `/assignment`;
  return dispatch => {
    dispatch(toggleAssignmentLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response) {
            dispatch(updateAssignmentList(response));
          }
          dispatch(toggleAssignmentLoader(false));
        });
    });
  };
};

export const fetchStudentAnswers = (assignmentId, studentId, page) => {
  const url = `/assignment/student/submit-assignment?assignmentId=${assignmentId}&studentId=${studentId}&page=${page}`;

  return dispatch => {
    dispatch(toggleAnsLoaderLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        resolve();
        if (response) {
          dispatch(setStudentAnswers(response));
        }
        dispatch(toggleAnsLoaderLoader(false));
      });
    });
  };
};

export const toggleAnsLoaderLoader = value => ({
  type: TOGGLE_ANS_LOADER,
  value
});

export const setStudentAnswers = response => ({
  type: SET_STUDENT_ANSWERS,
  response
});

export const updateAssignmentStatus = (params, status, history = null) => {
  return dispatch => {
    dispatch(toggleStatusLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(`/assignment/status/${params.id}`)
        .params({ status })
        .send(async (response, error) => {
          resolve();
          if (response && response.show && response.show.type === "success") {
            if (status === "Published") {
              const date = moment().format("YYYY-MM-DD");
              dispatch(updateCurrentAssignment(status, "status"));
              dispatch(handleFilterParams(status, 'status'));
              dispatch(updateCurrentAssignment(date, "publishDate"));
            }
            // else if (status === "Deleted") {

            // }
            if (history) {
              history.goBack();
            }
          }
          dispatch(toggleStatusLoader(false));
        });
    });
  };
};

export const createNewAssignment = (params, history, nextPage) => {
  return (dispatch, getState) => {
    dispatch(toggleAssignmentLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(`/assignment`)
        .params(params)
        .send(async (response, error) => {
          resolve();
          dispatch(toggleAssignmentLoader(false));
          if (response && response.id) {
            params.status = "Draft";
            params.id = response.id;
            dispatch(toggleAssignmentModal(false));
            dispatch(setCurrentAssignment({id: params.id}));
            //
            dispatch(handleAssignmentInput("", "selectedSubject"));
            dispatch(handleAssignmentInput([], "standardIds"));
            dispatch(handleAssignmentInput(null, "subjectId"));
            dispatch(handleAssignmentInput("", "selectedTeacher"));
            dispatch(handleAssignmentInput(null, "teacherId"));
            dispatch(handleAssignmentInput(null, "deadlineDate"));
            dispatch(handleAssignmentInput(null, "resultDueDate"));
            dispatch(handleAssignmentInput("Ungraded", "gradingType"));
            dispatch(handleAssignmentInput("", "title"));
            dispatch(handleAssignmentInput("", "instructions"));
            dispatch(handleAssignmentInput(null, "id"));
            dispatch(handleAssignmentInput([], "chapterIds"));
            dispatch(handleAssignmentInput([], "chapterNames"));
            dispatch(handleAssignmentInput(null, "index"));
            dispatch(handleAssignmentInput(null, "cloneAssignmentId"));
            if (nextPage === "assignment-logger") {
              history.push(`/assignment/${response.id}`);
              dispatch(
                setCurrentAssignmentTasks([], { currentPage: 1, totalItems: 0 })
              );
            } else if (nextPage === "assignment-teacher") {
              history.push(`/assignment/submissions/${response.id}`);
              dispatch(
                setCurrentAssignmentTasks([], { currentPage: 1, totalItems: 0 })
              );
              dispatch(updateSubmissionsList([], params.gradingType, 0, 0));
            } else {
              dispatch(handleFilterParams('Draft', 'status'));
              const filter = getState().assignment.filterParams;
              dispatch(getAssignment(filter));
              dispatch(getAssignmentById(response.id, 1))
            }
            if(params.cloneAssignmentId){
              dispatch(getAssignmentById(response.id, 1, null));
            }
          }
        });
    });
  };
};

const updateSubmissionsList = (
  studentList,
  graded,
  submitted,
  totalStudents,
  pageMeta
) => ({
  type: UPDATE_STUDENT_LIST,
  studentList,
  graded,
  submitted,
  totalStudents
});

export const getAssignmentById = (id, page, view = null) => {
  return (dispatch, getState) => {
    if (!page) page = 1;
    const url = `/assignment/${id}?page=${page}`;
    dispatch(toggleAssignmentTaskLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (response && response.assignmentTasks) {
          dispatch(
            setCurrentAssignmentTasks(
              response.assignmentTasks,
              response.pageMeta
            )
          );
          if (view === "assignment-teacher") {
            dispatch(
              updateSubmissionsList(
                response.submission,
                response.graded,
                response.submitted,
                response.totalStudents
              )
            );
          }
          const assignmentData = {
            id: response.id,
            title: response.title,
            assignmentStandard: response.assignmentStandard,
            author: response.author,
            chapterIds: response.chapterIds,
            chapterNames: response.chapterNames,
            deadlineDate: response.deadlineDate,
            description: response.description,
            graded: response.graded,
            gradingType: response.gradingType,
            index: response.index,
            instructions: response.instructions,
            markedForReview:  response.markedForReview,
            publishDate: response.publishDate,
            resultDueDate: response.resultDueDate,
            std: response.std,
            status: response.status,
            standardIds: response.standardIds,
            sub: response.sub,
            subjectId: response.subjectId,
            submissions: response.submissions,
            submitted: response.submitted,
            taskCount: response.taskCount,
            teacherId: response.teacherId,
            title: response.title,
            totalPoints: response.totalPoints,
            declared: response.declared
          }
          dispatch(setCurrentAssignment(assignmentData));


        }

        dispatch(toggleAssignmentTaskLoader(false));
        resolve();
      });
    });
  };
};

export const getAssignmentTasks = (id, page) => {
  return (dispatch, getState) => {
    if (!page) page = 1;
    const url = `/assignment/all-task-by-assignment/${id}?page=${page}`;
    dispatch(toggleAssignmentTaskLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (response && response.assignmentTasks) {
          dispatch(
            setCurrentAssignmentTasks(
              response.assignmentTasks,
              response.pageMeta
            )
          );
        }
        dispatch(toggleAssignmentTaskLoader(false));
        resolve();
      });
    });
  };
};

export const createNewAssignmentTask = (params, view) => {
  return (dispatch, getState) => {
    const {
      currentAssignment,
      assignmentPageTaskDetails
    } = getState().assignment;
    dispatch(toggleAssignmentTaskLoader(true));
    return Api.post(`/assignment/assignment-question`)
      .params(params)
      .send(async (response, error) => {
        if (response && response.show.type === "success") {
          let totalPoints = currentAssignment.totalPoints
            ? currentAssignment.totalPoints
            : 0;
          totalPoints = totalPoints + params.positiveMarks;
          dispatch(updateCurrentAssignment(totalPoints, "totalPoints"));
          dispatch(
            getAssignmentTasks(
              currentAssignment.id,
              assignmentPageTaskDetails.currentPage
            )
          );
          dispatch(removeCurrentQuestion());
          dispatch(clearCkeditorStatement());
        }else{
          dispatch(toggleAssignmentTaskLoader(false));
        }
        //
      });
  };
};
export const clearCkeditorStatement = () => ({
  type: CLEAR_CKEDITOR_STATEMENT
});

export const deleteAssignmentTask = (id, positiveMarks) => {
  if (!id) return message("Question already deleted, reload!");
  const url = `/assignment/assignment-task/${id ? id : ""}`;
  return (dispatch, getState) => {
    const {
      currentAssignment,
      assignmentPageTaskDetails
    } = getState().assignment;
    dispatch(toggleAssignmentLoader(true));
    return Api.delete(url).send((response, error) => {
      if (response && response.show.type === "success") {
        let totalPoints = currentAssignment.totalPoints
          ? currentAssignment.totalPoints
          : 0;
        totalPoints = totalPoints - positiveMarks;
        dispatch(updateCurrentAssignment(totalPoints, "totalPoints"));
        dispatch(
          getAssignmentTasks(
            currentAssignment.id,
            assignmentPageTaskDetails.currentPage
          )
        );
      }
      dispatch(toggleAssignmentLoader(false));
    });
  };
};

export const patchAssignmentTask = params => {
  return (dispatch, getState) => {
    const {
      currentQuestionData,
      currentAssignment,
      assignmentPageTaskDetails
    } = getState().assignment;
    if (!currentQuestionData.id)
      return message("Question mignt be deleted please reload");
    const url = `/assignment/assignment-task/${
      currentQuestionData.id ? currentQuestionData.id : ""
    }`;
    dispatch(toggleAssignmentTaskLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response && response.show.type === "success") {
            if (currentAssignment.gradingType === "Graded") {
              let totalPoints = currentAssignment.totalPoints
                ? currentAssignment.totalPoints
                : 0;
              totalPoints =
                totalPoints - params.oldMarks + params.positiveMarks;
              dispatch(updateCurrentAssignment(totalPoints, "totalPoints"));
            }
            dispatch(
              getAssignmentTasks(
                currentAssignment.id,
                assignmentPageTaskDetails.currentPage
              )
            );
            dispatch(toggleQuestionModal(false));
            dispatch(removeCurrentQuestion());
            dispatch(setCurrentQuestion(false));
          }
          dispatch(toggleAssignmentTaskLoader(false));
        });
    });
  };
};

export const saveAssignmentFeedback = params => {
  return (dispatch, getState) => {
    const {
      currentAssignment,
      assignmentPageTaskDetails
    } = getState().assignment;
    const url = `/assignment/review-assignment-task`;
    dispatch(toggleAssignmentTaskLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response && response.show.type === "success") {
            dispatch(
              updateCurrentAssignment(
                response.assignmentMarkedForReview,
                "markedForReview"
              )
            );
            dispatch(
              getAssignmentTasks(
                currentAssignment.id,
                assignmentPageTaskDetails.currentPage
              )
            );
            dispatch(setQuestionFeedBack({}));
          }
          dispatch(toggleAssignmentTaskLoader(false));
        });
    });
  };
};

export const unmarkForReview = (id, view = null) => {
  return dispatch => {
    const url = `/assignment/update-assignment-review-status/${id}`;
    dispatch(toggleMarkForReviewLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(url).send(async (response, error) => {
        resolve();
        if (response && response.show.type === "success") {
          dispatch(updateCurrentAssignment(false, "markedForReview"));
        }
        dispatch(toggleMarkForReviewLoader(false));
      });
    });
  };
};

export const deleteImages = (images, index = null) => {
  return dispatch => {
    dispatch(getRemoveResponse(true));
    return new Promise((resolve, reject) => {
      resolve();
      const params = { deletedFiles: images };
      Api.delete("/assignment/delete-image")
        .params(params)
        .send((response, error) => {
          if (response) {
            dispatch(getRemoveResponse(false));
          }
        });
    });
  };
};

export const updateImageList = (images, index) => ({
  type: UPDATE_IMAGE_LIST,
  images,
  index
});

export const publishResults = () => {
  return (dispatch, getState) => {
    const { currentAssignment } = getState().assignment;
    const url = `/assignment/publish-result/${currentAssignment.id}`;
    dispatch(toggleResultLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(url).send(async (response, error) => {
        resolve();
        if (response && response.show.type === "success") {
          dispatch(updateCurrentAssignment(true, "declared"));
        }
        dispatch(toggleResultLoader(false));
      });
    });
  };
};

export const sendReminder = () => {
  return (dispatch, getState) => {
    const { currentAssignment, studentList } = getState().assignment;
    dispatch(toggleReminderLoader(true));
    const studentIds = studentList
      .filter(student => student.status === "Pending")
      .map(student => student.id);
    const url = `/assignment/send-reminder`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params({ studentIds, assignmentId: currentAssignment.id })
        .send(async (response, error) => {
          resolve();
          dispatch(toggleReminderLoader(false));
        });
    });
  };
};

export const toggleResultLoader = value => ({
  type: TOGGLE_RESULT_LOADER,
  value
});

export const toggleMarkForReviewLoader = value => ({
  type: TOGGLE_REVIEW_LOADER,
  value
});

export const toggleReminderLoader = value => ({
  type: TOGGLE_REMINDER_LOADER,
  value
});

export const setQuestionFeedBack = value => ({
  type: SET_QUESTIONS_FEEDBACK,
  value
});

export const handleFilterParams = (value, key) => ({
  type: UPDATE_FILTER_PARAMS,
  filterParams: { [key]: value }
});

export const handleAssignmentInput = (value, key) => ({
  type: UPDATE_POST_PARAMS,
  postParams: { [key]: value }
});

export const updateReviewItem = (value, key, index) => ({
  type: UPDATE_REVIEW_PARAMS,
  param: { [key]: value },
  index
});

export const updateCurrentAssignment = (value, key) => ({
  type: SET_CURRENT_ASSIGNMENT_PARAMS,
  currentAssignment: { [key]: value }
});

export const updateQuestionFeedback = (value, key, index) => ({
  type: UPDATE_QUESTION_FEEDBACK,
  param: { [key]: value },
  index
});

export const updateStudentAssignmentList = value => ({
  type: UPDATE_STUDENT_ASSIGNMENT_LIST,
  value
});

export const updateNewStudentAssignmentList = value => ({
  type: UPDATE_NEW_STUDENT_ASSIGNMENT_LIST,
  value
});

export const setStudentAssignnent = value => ({
  type: SET_STUDENT_ASSIGNMENT,
  value
});

export const setStudentAssignnentTasks = value => ({
  type: SET_STUDENT_ASSIGNMENT_TASKS,
  value
});

export const updateAssignmentSearch = value => ({
  type: UPDATE_ASSIGNMENT_SEARCH,
  value
});

export const updateSubFilter = value => ({
  type: UPDATE_SUB_FILTER,
  value
});

export const setAnswer = (answer, index) => ({
  type: SET_ANSWER,
  answer,
  index
});

export const setLocalImage = (file, url, index) => ({
  type: SET_LOCAL_IMAGE,
  file,
  url,
  index
});

export const setRemoteImage = (files, index) => ({
  type: SET_REMOTE_IMAGE,
  files,
  index
});

export const setStudentId = id => ({
  type: SET_STUDENT_ID,
  value: id
});

export const replaceAnswerImage = (index, imageIndex) => ({
  type: REPLACE_ANSWER_IMAGE,
  index,
  imageIndex
});

export const deleteAnswerImage = (index, imageIndex, kind) => ({
  type: DELETE_ANSWER_IMAGE,
  index,
  imageIndex,
  kind
});

export const bookmarkQuestion = index => ({
  type: BOOKMARK_QUESTION,
  index
});

export const setLocalStatusStudent = value => ({
  type: SET_LOCAL_STATUS_STUDENT,
  value
});

export const updateDountData = data => ({
  type: UPDATE_DONUT_DATA,
  data
});

export const updateSubmitDetails = data => ({
  type: UPDATE_SUBMIT_DETAILS,
  data
});

export const submitModalToggle = value => ({
  type: SUBMIT_MODAL_TOGGLE,
  value
});

export const setFinishMode = value => ({
  type: SET_FINISH_MODE,
  value
});

export const setStudentQuestionIndex = value => ({
  type: SET_STUDENT_QUESTION_INDEX,
  value
});

export const updateQtime = (index, value) => ({
  type: UPDATE_Q_TIME,
  index,
  value
});

export const resetDeletedAssignmentImages = () => ({
  type: RESET_DELETED_ASSIGNMENT_IMAGES
});

export const submitAssignment = () => {
  return (dispatch, getState) => {
    const { studentCurrentAssignment, studentId } = getState().assignment;
    const { user } = getState().session;
    const userId = user.id || studentId;
    const url = `/assignment/student/submit-assignment?assignmentId=${studentCurrentAssignment.id}&studentId=${userId}`;
    dispatch(toggleAssignmentLoader(true));
    dispatch(answeringLoaderToggle(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        dispatch(answeringLoaderToggle(false));
        resolve();
        dispatch(submitModalToggle(true));
        if (response && response !== "") {
          dispatch(updateSubmitDetails(response));
          dispatch(toggleAssignmentLoader(false));
        }
      });
    });
  };
};

export const searchChapter = query => {
  return (dispatch, getState) => {
    const { postParams } = getState().assignment;
    const params = {
      standardIds: postParams.standardIds,
      subjectId: postParams.subjectId,
      chapterName: query
    };
    const url = `/assignment/filter/autocomplete-chapters`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (!response.length) {
            dispatch(
              updateSearchedChapters([
                {
                  id: -1,
                  value: "No chapters found",
                  disabled: true
                }
              ])
            );
          } else {
            dispatch(updateSearchedChapters(response));
          }
        });
    });
  };
};

export const updateSearchedChapters = chapters => ({
  type: UPDATE_SEARCHED_CHAPTERS,
  chapters
});

export const getStudentAssignment = (callback) => {
  return dispatch => {
    const url = `/assignment/student/all-assignments`;
    dispatch(toggleAssignmentLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        resolve();
        dispatch(toggleAssignmentLoader(false));
        callback(response);
        if (response !== "" && response !== undefined) {
          if (response.assignments) {
            dispatch(
              updateDountData({
                submitted: response.completedAssignments,
                graded: response.graded,
                inProgress: response.draftCount,
                pending: response.pendingCount,
                total: response.totalAssignments,
                new: response.assignmentOfTodays.length
              })
            );
            const listAssignment = [
              ...response.assignmentOfTodays,
              ...response.assignments
            ];
            dispatch(updateSubFilter(response.subjects));
            dispatch(updateStudentAssignmentList(listAssignment));
            dispatch(
              updateNewStudentAssignmentList(response.assignmentOfTodays)
            );
          }
        }
      });
    });
  };
};

export const getStudentAssignmentbyType = type => {
  return dispatch => {
    const url = `/assignment/student/all-assignments/?type=${type}`;
    dispatch(toggleAssignmentLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(toggleAssignmentLoader(false));
      if (response !== "" && response !== undefined) {
        if (response.assignments) {
          dispatch(
            updateDountData({
              submitted: response.completedAssignments,
              graded: response.graded || 0,
              inProgress: response.draftCount,
              total: response.totalAssignments,
              pending: response.pendingCount,
              new: response.assignmentOfTodays.length
            })
          );
          const listAssignment = [
            ...response.assignmentOfTodays,
            ...response.assignments
          ];
          dispatch(updateSubFilter(response.subjects));
          dispatch(updateStudentAssignmentList(listAssignment));
          dispatch(updateNewStudentAssignmentList(response.assignmentOfTodays));
        }
      }
    });
  };
};

export const findBySubject = (id, date) => {
  return dispatch => {
    let url = `/assignment/filter/autocomplete?&dueDate=${date}&subjectId=${id}`;
    if (!id) {
      url = `/assignment/filter/autocomplete?&dueDate=${date}`;
    } else if (!date) {
      url = `/assignment/filter/autocomplete?&subjectId=${id}`;
    } else {
    }
    dispatch(toggleAssignmentLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(toggleAssignmentLoader(false));
      if (response !== "" && response !== undefined) {
        dispatch(updateStudentAssignmentList(response));
      }
    });
  };
};

export const searchByText = text => {
  return dispatch => {
    const url = `/assignment/filter/autocomplete?name=${text}`;
    dispatch(toggleAssignmentLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(toggleAssignmentLoader(false));
      if (response !== "" && response !== undefined) {
        dispatch(updateStudentAssignmentList(response));
      }
    });
  };
};

export const getStudentAssignmentDetail = (id, data, history) => {
  return dispatch => {
    const url = `/assignment/student/questions/${id}`;
    dispatch(toggleAssignmentLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(toggleAssignmentLoader(false));
      if (response !== "" && response !== undefined) {
        data.reviewDuration = response.reviewDuration;
        dispatch(setStudentAssignnent(data));
        // set bookmark state from preserveAspectRatio
        response.assignmentTasks.forEach(item => {
          if (
            item.submitStatus == "AttemptedAndBookmarked" ||
            item.submitStatus == "Bookmarked"
          ) {
            item.markedForReview = true;
          }
        });
        dispatch(setStudentAssignnentTasks(response.assignmentTasks));
        // history.push(`${id}`);
        history.push(`/web-assignment/${id}`);
      }
    });
  };
};

export const getStudentAssignmentResults = (id, data, history) => {
  return (dispatch, getState) => {
    const { studentId } = getState().assignment;
    const { user } = getState().session;
    const userId = user.id || studentId;
    const url = `/assignment/student/submit-assignment?assignmentId=${id}&studentId=${userId}`;
    dispatch(toggleAssignmentLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(submitModalToggle(true));
      dispatch(toggleAssignmentLoader(false));
      dispatch(setStudentAssignnent(data));
      // history.push(`${id}`);
      history.push(`/web-assignment/${id}`);
      if (response && response !== "" && !response.show) {
        dispatch(updateSubmitDetails(response));
      }
    });
  };
};

export const setStudentAssignmentStatus = status => {
  return (dispatch, getState) => {
    const url = "/assignment/update/student-assignment-status";
    const { studentCurrentAssignment, studentId } = getState().assignment;
    const { user } = getState().session;
    const userId = user.id || studentId;
    const params = {
      studentId: userId,
      assignmentId: studentCurrentAssignment.id,
      status: status,
      reviewDuration: studentCurrentAssignment.reviewDuration
    };
    // dispatch(answeringLoaderToggle(true));
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .params(params)
        .send(async (response, error) => {
          resolve(response);
          reject(error);
          dispatch(setLocalStatusStudent(status));
          // dispatch(answeringLoaderToggle(false));
        });
    });
  };
};

export const getStudentAssignmentById = () => {
  return (dispatch, getState) => {
    const { studentCurrentAssignment } = getState().assignment;
    const url = `/assignment/${studentCurrentAssignment.id}`;
    dispatch(toggleAssignmentLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        resolve(response);
        reject(error);
        dispatch(toggleAssignmentLoader(false));
      });
    });
  };
};

export const postAssignmentQuestions = (
  index,
  carouselRef,
  seconds,
  status = "Attempted"
) => {
  return (dispatch, getState) => {
    const {
      studentCurrentAssignmentTasks,
      studentCurrentAssignment
    } = getState().assignment;
    dispatch(updateQtime(index, seconds));
    let params = {
      assignmentTaskId: studentCurrentAssignmentTasks[index].id,
      duration: seconds,
      submitStatus: status,
      reviewDuration: studentCurrentAssignment.reviewDuration
    };
    if (studentCurrentAssignmentTasks[index].type === "Image") {
      return dispatch(setQImage(index, carouselRef, seconds, status));
    } else {
      const sendable = studentCurrentAssignmentTasks[index].selectedKey
        ? studentCurrentAssignmentTasks[index].selectedKey.toString()
        : "";
      params.answer = status == "Unanswered" ? null : sendable;
    }
    const url = `/assignment/add-std-ans`;
    dispatch(answeringLoaderToggle(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          dispatch(answeringLoaderToggle(false));
          // if its the last question dont change the carousel
          if (
            index === response.studentAnswer.totalQuestions - 1 ||
            !carouselRef
          )
            return;
          carouselRef.next();
        });
    });
  };
};

export const patchAssignmentQuestions = index => {
  return (dispatch, getState) => {
    const { studentCurrentAssignmentTasks, studentId } = getState().assignment;
    const { user } = getState().session;
    const userId = user.id || studentId;
    let params = {
      studentId: userId,
      assignmentId: studentCurrentAssignmentTasks[index].id,
      status: "Submitted",
      duration: 20
    };
    if (studentCurrentAssignmentTasks[index].type === "Image") {
      params.files = studentCurrentAssignmentTasks[index].files;
    } else {
      params.answer = studentCurrentAssignmentTasks[index].selectedKey;
    }
    const url = `/assignment/update/student-assignment-status`;
    Api.patch(url)
      .params(params)
      .send((response, error) => {});
  };
};

export const setQImage = (index, carouselRef, seconds, status) => {
  return (dispatch, getState) => {
    const {
      studentCurrentAssignmentTasks,
      deletedAssignmentImages,
      studentCurrentAssignment
    } = getState().assignment;
    let i = 0;
    let sendObj = [];
    let len = 0;
    if (typeof studentCurrentAssignmentTasks[index].files !== "undefined") {
      len = studentCurrentAssignmentTasks[index].files.length;
      if (
        studentCurrentAssignmentTasks[index].files &&
        studentCurrentAssignmentTasks[index].files.length
      ) {
        len = studentCurrentAssignmentTasks[index].files.length;
        for (i = 0; i < len; i++) {
          if (!studentCurrentAssignmentTasks[index].files[i].remote) {
            sendObj[i] = studentCurrentAssignmentTasks[index].files[i].local;
          }
        }
      }
    }

    let params = {
      assignmentTaskId: studentCurrentAssignmentTasks[index].id,
      duration: seconds,
      reviewDuration: studentCurrentAssignment.reviewDuration,
      submitStatus: status
    };

    if (sendObj.length) {
      params.files = sendObj;
    }

    // if (
    //   sendObj.length ||
    //   (studentCurrentAssignmentTasks[index].selectedKey &&
    //     studentCurrentAssignmentTasks[index].selectedKey.length)
    // ) {
    //   params.submitStatus = "Attempted";
    // } else {
    //   params.submitStatus = "Unanswered";
    // }

    if (deletedAssignmentImages.length) {
      params.deletedFiles = JSON.stringify(deletedAssignmentImages);
    }
    dispatch(answeringLoaderToggle(true));
    return new Promise((resolve, reject) => {
      Api.post("/assignment/add-std-ans").upload(
        params,
        async (percent, response) => {
          if (percent === -1) {
            resolve();
            dispatch(answeringLoaderToggle(false));
          }
          if (response !== undefined && percent === 100) {
            resolve();
            dispatch(resetDeletedAssignmentImages());
            dispatch(answeringLoaderToggle(false));
            // if last question dont go to next carousel item
            if (
              index !== response.data.studentAnswer.totalQuestions - 1 &&
              carouselRef
            ) {
              carouselRef.next();
            }
            if (response.data.studentAnswer.image) {
              dispatch(
                setRemoteImage(response.data.studentAnswer.image, index)
              );
            }
          }
        }
      );
    });
  };
};

export const readQuillEditorText = (data, id) => ({
  type: READ_QUILL_EDITOR_TEXT,
  data,
  id
});

export const displayOptionModal = bool => ({
  type: DISPLAY_OPTION_MODAL,
  bool
});

export const displayOptionIndexRead = index => ({
  type: DISPLAY_OPTION_INDEX_READ,
  index
});
