import React from "react";
import { Api } from '../../services';
import { Quill } from "react-quill";
import FormulaEditor from './index'

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);


// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Quill.register(Font, true);
export let uploadedImageName = "";
// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: [[{ 'size': ['small', false, 'large'] }], ['bold', 'italic', 'underline'],  [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'script': 'sub'}, { 'script': 'super' }],  [{ 'color': [] }, { 'background': [] }],   [ 'image', 'formula', 'code-block']],
    handlers: {
      image: imageHandler,
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "formula",
  "code-block",
  "small",
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Default</option>
        <option value="small">Small</option>
        <option value="medium">Medium</option>
        <option value="large">Large</option>
        <option value="huge"></option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
    </span>
    <span className="ql-formats">
      {/*<select className="ql-align" />*/}
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-image" />
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
    </span>
  </div>
  );


export function imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async function () {
        const file = input.files[0];

        const formData = new FormData()
        if (file !== null) {
            formData.append('file', file)
        }
        console.log("Api req", FormulaEditor.uploadUrl, {upload: file});
        // Api.post('/assignment/upload-image').upload({upload: file}, (percent, response)=> {
          Api.post(FormulaEditor.uploadUrl).upload({upload: file}, (percent, response)=> {
             console.log("Api res", response);
            if(response){
                  
                  const cursorPosition = FormulaEditor.getRef().getEditor().getSelection();
                  const imagePath = response.data.url;
                  uploadedImageName = response.data.fileName;
                  FormulaEditor.getRef().getEditor().insertEmbed(cursorPosition.index, 'image', imagePath, Quill.sources.USER);
                  FormulaEditor.getRef().getEditor().setSelection(FormulaEditor.getRef().getEditor().getSelection().index+1, 0)
            }
        }
      )
    };
}


export default QuillToolbar;
