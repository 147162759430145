import { ArrowLeftOutlined, ConsoleSqlOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import SearchBox from 'pages/Test/localComponent/searchBox'
import React, { useEffect, useState } from 'react'
import DropDownCheckBox from './dropDownCheckBox';
import { Button, Checkbox, Input, Pagination, Tabs, Tag, Typography } from 'antd';
import { useHistory } from 'react-router';
import { getLeaveRequestData,
    searchFilter,
    roleFilter,
    typeFilter, 
    setCardId,
    requestedActionData,
    setActionData, 
    setStatusData ,
    setBackToPrivilegedView,
    setRequestedIndex,
    storeUsersName,
    requestViewAllClicked,
    storeRequestViewAllPage,
    setLeaveIds
    } from 'actions/attendanceNew';
import { setSpecificUserId } from 'actions/userManagement';
import { connect } from 'react-redux';
import moment from 'moment';
import { Api } from '../../services';
import PageDataLoader from 'components/pageDataLoader';
import Notification from 'pages/notification/notification';
import { NodataCard } from 'pages/Test';
import ConfirmRejectModal from './Modals/confirmRejectModal';
import ConfirmApproveModal from './Modals/confirmApproveModal';
import e from 'cors';
import DropDownCheckBoxWithStudent from './dropDownCheckBoxStudent';
import { InputSearch } from 'components/customInputs';
// import AttendanceDetails from './attendanceTab';

const imgs = require("../../Assets/user/com-default-pic.svg").default;
const image=require("../../Assets/Icon awesome-user-alt.svg").default;
const { Search } = Input;
const { TabPane } = Tabs;

const ButtonsDiv = ({
    temp, handleChange,
    handleCheckAll,
    requests,
    storeLeaveId,
    requestedActionData,
    setCardId,
    id,
    setActionData,
    userRemainingLeaves,
    setSpecificUserId,
    requestedUserId,
    leaveRequestDataContainer,
    index,
    isRejectModalVisible,
    setIsRejectModalVisible,
    isApproveModalVisible,
    setIsApproveModalVisible,
    getLeaveRequestData,
    setIsButtonDivVisible,
    togglePageLoader,
    userLeaveType,
    profileTags }) => {


    const history = useHistory();
    return (
        <div>
            {profileTags === "Leave" ?
                <div >
                    <div style={{ marginLeft: "30px", marginTop: "45px" }} onClick={() => {
                        e.stopPropagation();
                    }}>
                        <Checkbox
                            checked={temp?.length > 0 && requests?.length === temp?.length}
                            onChange={(e) => {
                                handleCheckAll(e.target.checked)
                                setIsButtonDivVisible(true)
                                e.stopPropagation();

                            }} > <p style={{ color: "#636363", font: "normal normal normal 15px/18px Roboto" }}>Select All</p></Checkbox>
                    </div>
                    {requests?.map((item) => (
                        <div style={{ background: "#FAFAFA 0% 0% no-repeat padding-box", borderRadius: "6px", marginTop: "30px", borderBottom: "1px solid #FAFAFA" }}>
                            <div style={{ display: "flex", marginTop: "-25px", marginLeft: "30px" }}>
                                <Checkbox
                                    checked={storeLeaveId?.includes(item?.id)}
                                    onChange={(e) => {
                                        handleChange(item?.id, e.target.checked)
                                        setIsButtonDivVisible(true)
                                        e.stopPropagation();
                                    }}
                                > <p style={{ font: "normal normal normal 15px/18px Roboto", marginTop: "10px" }}>{moment(item?.date).format('DD-MM-YYYY')} </p></Checkbox>
                                <div style={{ font: "normal normal normal 15px/18px Roboto", marginTop: "10px", marginLeft: "10px", }}>{leaveRequestDataContainer?.requests?.items[index]?.duration == 0.5 ? 'Half day' : 'Full day'}</div>
                                <div style={{
                                    marginLeft: "50px",
                                    height: "14px",
                                    width: "47px",
                                    marginTop: "15px",
                                    border: `${item?.status === "Pending" ? "1px solid #FFA931" : item?.status === "Approved" ? "1px solid #28DF99" : item?.status === "Rejected" ? "1px solid #FF414D" : null}`,
                                    borderRadius: " 3px",
                                    marginRight: "10px",
                                    background: `${item?.status === "Pending" ? "#FFF4D5" : item?.status === "Approved" ? "#E9FCF5" : item?.status === "Rejected" ? "#FFE4D9" : null}`,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <div style={{
                                        font: "normal normal bold 8px/10px Roboto",
                                        color: `${item?.status === "Pending" ? "#FFA931" : item?.status === "Approved" ? "#28DF99" : item?.status === "Rejected" ? "#FF414D" : null}`
                                    }}>{item?.status === "Pending" ? "PENDING" : item?.status === "Approved" ? "APPROVED" : item?.status === "Rejected" ? "REJECTED" : null}</div>
                                </div>
                            </div>
                        </div>

                    ))}
                </div> : null}
            {leaveRequestDataContainer?.requests?.items[index]?.requestType === "Leave" ?
                <div style={{ display: "flex", }}>
                    <div style={{ marginLeft: "30px", marginTop: "6px" }}>
                        <ExclamationCircleOutlined />
                    </div>
                    <div style={{ font: "normal normal bold 11px/16px Roboto", color: "#636363", marginTop: "10px", marginLeft: "5px" }}>
                        {userRemainingLeaves} {userLeaveType} {" "}
                        {userRemainingLeaves > 1 ? "leaves" : "leave"} remaining
                    </div>
                    <div style={{ font: " normal normal bold 11px/16px Roboto", color: "#1089FF", marginLeft: "155px", marginTop: "10px" }}
                        onClick={() => {
                            history.push({
                                pathname: "/user/details",
                                state:
                                {
                                    tab: (profileTags === "Leave" ? "leave" : "attendance")
                                }
                            });
                            setSpecificUserId(requestedUserId)
                        }}>VIEW MORE</div>
                </div>
                :
                <div style={{
                    font: " normal normal bold 11px/16px Roboto",
                    color: "#1089FF",
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "30px"
                }}
                    onClick={() => {
                        history.push({
                            pathname: "/user/details",
                            state:
                            {
                                tab: (profileTags === "Leave" ? "leave" : "attendance")
                            }
                        });
                        setSpecificUserId(requestedUserId)
                    }}>VIEW MORE</div>
            }
            <div style={{ display: "flex", marginLeft: "85px", marginTop: "15px" }}>
                <div>
                    <Button
                        style={{
                            width: "110px",
                            height: "36px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #636363",
                            borderRadius: " 100px"
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsRejectModalVisible(true);
                        }}
                    >REJECT</Button>
                    <ConfirmRejectModal
                        isRejectModalVisible={isRejectModalVisible}
                        setIsRejectModalVisible={setIsRejectModalVisible}
                        setCardId={setCardId}
                        id={id}
                        setActionData={setActionData}
                        requestedActionData={requestedActionData}
                        functionProp={() => {
                            getLeaveRequestData()
                        }}
                        setIsButtonDivVisible={setIsButtonDivVisible}
                        togglePageLoader={togglePageLoader}
                    />
                </div>
                <div>
                    <Button
                        style={{
                            width: "110px",
                            height: "36px",
                            background: "#28DF99 0% 0% no-repeat padding-box",
                            borderRadius: " 100px",
                            color: "#FFFFFF",
                            marginLeft: "25px"
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsApproveModalVisible(true)
                        }}
                    >APPROVE</Button>
                    <ConfirmApproveModal
                        isApproveModalVisible={isApproveModalVisible}
                        setIsApproveModalVisible={setIsApproveModalVisible}
                        setCardId={setCardId}
                        id={id}
                        setActionData={setActionData}
                        requestedActionData={requestedActionData}
                        functionProp={getLeaveRequestData}
                        setIsButtonDivVisible={setIsButtonDivVisible}
                        togglePageLoader={togglePageLoader}
                    />
                </div>
            </div>
        </div>
    )
}
const RejectedDiv = ({
    actionByName,
    actionByRole,
    actionByimage,
    isRejectModalVisible,
    setIsRejectModalVisible,
    requestedActionData,
    setCardId,
    id,
    setActionData,
    getLeaveRequestData,
    setIsButtonDivVisible,
    togglePageLoader,
    profileTags,
    userStartDate }) => {
    // const [isRejectDivLoading,setIsRejectDivLoading]=useState(false);
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {profileTags === "Leave" && moment(userStartDate).diff(moment(moment().format('YYYY-MM-DD')), "days") > 0 ?
                    <div>
                        <Button
                            style={{ font: " normal normal bold 13px/19px Roboto", color: "#FF414D", marginTop: '30px', border: "none" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsRejectModalVisible(true);
                            }}
                        >Change to Rejected</Button>
                        <ConfirmRejectModal
                            isRejectModalVisible={isRejectModalVisible}
                            setIsRejectModalVisible={setIsRejectModalVisible}
                            setCardId={setCardId}
                            id={id}
                            setActionData={setActionData}
                            requestedActionData={requestedActionData}
                            functionProp={() => {
                                getLeaveRequestData('hi',
                                    togglePageLoader(false)
                                )
                            }}
                            setIsButtonDivVisible={setIsButtonDivVisible}
                            togglePageLoader={togglePageLoader}
                        />
                    </div>
                    :
                    <div style={{ width: "200px" }}>{" "}</div>}
                <div style={{ width: "150px", height: "55px", marginTop: "15px", marginRight: "5px" }}>
                    <div style={{ font: "normal normal bold 10px/16px Roboto", color: "#AEAEAE" }}>APPROVED BY</div>
                    <div style={{ display: "flex", marginTop: "5px" }}>
                        <div><img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${actionByimage}`}></img></div>
                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <Typography.Text style={{ font: "normal normal bold 14px/16px Roboto", color: "#191919", width: "80px" }} ellipsis={{ tooltip: true }}>{actionByName}</Typography.Text>
                            <div style={{ font: "normal normal normal 11px/16px Roboto", color: " #636363", }}>{actionByRole}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ApprovedDiv = ({
    actionByName,
    actionByRole,
    actionByimage,
    isApproveModalVisible,
    setIsApproveModalVisible,
    requestedActionData,
    setCardId,
    id,
    setActionData,
    setIsButtonDivVisible,
    getLeaveRequestData,
    togglePageLoader }) => {
    // const [isApproveDivLoading,setIsApproveDivLoading]=useState(false);
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>

                <Button
                    // loading={isApproveDivLoading}x
                    style={{ font: " normal normal bold 13px/19px Roboto", color: "#28DF99", marginTop: '30px', border: "none" }}
                    //  onClick={(e)=>{
                    //     setIsApproveDivLoading(true)
                    //     e.stopPropagation();
                    //     setCardId(id);
                    //     setActionData("Approved");
                    //     requestedActionData(()=>{
                    //         setIsApproveDivLoading(false)
                    //     })}}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsApproveModalVisible(true);
                    }}
                >Change to Approved</Button>
                <ConfirmApproveModal
                    isApproveModalVisible={isApproveModalVisible}
                    setIsApproveModalVisible={setIsApproveModalVisible}
                    setCardId={setCardId}
                    id={id}
                    setActionData={setActionData}
                    requestedActionData={requestedActionData}
                    functionProp={getLeaveRequestData}
                    setIsButtonDivVisible={setIsButtonDivVisible}
                    togglePageLoader={togglePageLoader}
                />
                <div style={{ width: "150px", height: "55px", marginTop: "15px", marginRight: "5px", }}>
                    <div style={{ font: "normal normal bold 10px/16px Roboto", color: "#AEAEAE" }}>REJECTED BY</div>
                    <div style={{ display: "flex", marginTop: "5px" }}>
                        <div><img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${actionByimage}`}></img></div>
                        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                            <Typography.Text style={{ font: "normal normal bold 14px/16px Roboto", color: "#191919", width: "80px" }} ellipsis={{ tooltip: true }}>{actionByName}</Typography.Text>
                            <div style={{ font: "normal normal normal 11px/16px Roboto", color: " #636363" }}>{actionByRole}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const leaveCardStyle = () => {
    return(
        <div style={{font: "normal normal normal 13px/18px Roboto",color: "#636363"}}></div>
    )
}
const LeaveApplicationCard = ({
    storeLeaveId,
    requests,
    setLeaveIds,
    profileImage,
    profileName,
    profileDesignation,
    profileStatus,
    profileTags,
    userLeaveType,
    userLeaveDuration,
    userStartDate,
    userEndDate,
    userLeaveReason,
    userClockIn,
    userClockOut,
    userTotalHours,
    cardIndex,
    setCardId,
    cardStatus,
    setActionData,
    requestedActionData,
    actionByimage,
    actionByName,
    actionByRole,
    userRemainingLeaves,
    userIsNew,
    requestedUserId,
    setSpecificUserId,
    leaveRequestDataContainer,
    index,
    getLeaveRequestData,
    togglePageLoader,
    setRequestedIndex,
    storeRequestedIndex
}) => {

    const [isButtonDivVisible, setIsButtonDivVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
    const [reload, setReload] = useState(true);

    useEffect(() => {
        return () => {
            setIsButtonDivVisible(false)
        }
    }, [])

    const handleCheckAll = (e) => {
        temp = []
        if (e) {
            requests?.forEach(element => {
                temp?.push(element?.id)
            })
        }
        setLeaveIds(temp)
        setReload(!reload);
    }
    let temp = storeLeaveId

    const handleChange = (id, e) => {
        if (!e) {
            temp = temp?.filter((e) => e != id);
        }
        else {
            temp?.push(id)
        }
        setLeaveIds(temp)
        setReload(!reload);
    }


    return (
        <div className='requestCard boxShadow118 border-16'
            style={{
                width: "350px",
                height: `${isButtonDivVisible ? "fit-content" : "280px"}`,
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #E6E6E6",
                // borderRadius:"5px",
                cursor: "pointer",
                marginTop: "20px",
                marginRight: "20px",
                paddingBottom: "30px"
            }}
            onClick={(e) => {
                setRequestedIndex(index)
                // let temp=isButtonDivVisible
                e.stopPropagation();
                setIsButtonDivVisible(!isButtonDivVisible)
                setIsButtonDivVisible(!isButtonDivVisible)
                setLeaveIds([])

            }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    <Tag style={{
                        color: `${profileTags === "Leave" ? "#1089FF" : "#594099"}`,
                        backgroundColor: `${profileTags === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
                        borderColor: `${profileTags === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
                        fontSize: "13px",
                        fontWeight: 500,
                        borderRadius: '10px 0 0 0',
                        marginTop: -15,
                    }}>{profileTags}</Tag>
                </div>
                <div style={{ display: userIsNew ? "view" : "none" }}>
                    <Tag color="#FFA931" style={{ fontWeight: "bold", border: "none", fontSize: "9px", borderRadius: "100px", marginRight: "25px", marginTop: "10px" }}>New</Tag>
                </div>
            </div>
            <div style={{ display: "flex", marginTop: "15px", justifyContent: "space-between", marginLeft: -10 }}>
                <div style={{ display: "flex", width: 100 }}>
                    <div ><img style={{ marginLeft: "25px", width: "60px", height: "60px", borderRadius: "50%" }} src={`${Api._s3_url}${profileImage}`} ></img></div>
                    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                        <Typography.Text style={{ font: " normal normal bold 15px/21px Roboto", color: "#191919", width: "150px" }} ellipsis={{ tooltip: true }}>{profileName}</Typography.Text>
                        <div style={{ font: "normal normal normal 13px/19px Roboto", color: " #636363", marginTop: "1px" }}>{profileDesignation}</div>
                    </div>
                </div>
                <div style={{ fontSize: "12px", color: "#AEAEAE", marginTop: "34px", marginRight: "40px" }}>
                    {moment.utc(profileStatus).local().format("DD/MM/YYYY • hh:mm A")}
                </div>
            </div>
            <div style={{
                background: "#FAFAFA 0% 0% no-repeat padding-box",
                borderRadius: "6px",
                height: "fit-content",
                paddingBottom: "8px",
                width: "310px",
                marginTop: "20px",
                marginLeft: "15px"
            }}>
                {
                    profileTags === "Leave" ? <>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, color: "#636363", width: 80 }} className="m-t-15">Leave type</div>
                            <div style={{ leaveCardStyle, color: "#636363" }} className="m-t-15">:</div>
                            <div style={{ leaveCardStyle, color: "#191919" }} className="m-t-15 m-l-15">{userLeaveType}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Day(s)</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <div style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919" }}>{userLeaveDuration}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Date(s)</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <div style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919" }}>{moment(userStartDate).format('DD-MM-YYYY')}</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#191919" }}>- {moment(userEndDate).format('DD-MM-YYYY')}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Reason</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919", width: 200 }}>{userLeaveReason}</Typography.Text>
                        </div>

                    </> : <>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Date</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <div style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919" }}>{moment(userStartDate).format('DD-MM-YYYY')}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Clock-in</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <div style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919" }}>{userClockIn}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Clock-out</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <div style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919" }}>{userClockOut}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Total hours</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <div style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919" }}>{userTotalHours}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363", width: 80 }}>Reason</div>
                            <div style={{ leaveCardStyle, marginTop: "5px", color: "#636363" }}>:</div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ leaveCardStyle, marginLeft: "15px", marginTop: "5px", color: "#191919", width: 200 }}>  {userLeaveReason}</Typography.Text>
                        </div>
                    </>
                }
            </div>

            {
                storeRequestedIndex === index &&
                    isButtonDivVisible ? cardStatus === "Pending" ?
                    <ButtonsDiv
                        // id={cardId}
                        id={cardIndex}
                        userLeaveType={userLeaveType}
                        setCardId={setCardId}
                        setActionData={setActionData}
                        status={cardStatus}
                        requestedActionData={requestedActionData}
                        userRemainingLeaves={userRemainingLeaves}
                        requestedUserId={requestedUserId}
                        setSpecificUserId={setSpecificUserId}
                        leaveRequestDataContainer={leaveRequestDataContainer}
                        isRejectModalVisible={isRejectModalVisible}
                        setIsRejectModalVisible={setIsRejectModalVisible}
                        isApproveModalVisible={isApproveModalVisible}
                        setIsApproveModalVisible={setIsApproveModalVisible}
                        isButtonDivVisible={isButtonDivVisible}
                        setIsButtonDivVisible={setIsButtonDivVisible}
                        index={index}
                        getLeaveRequestData={getLeaveRequestData}
                        togglePageLoader={togglePageLoader}
                        profileTags={profileTags}
                        temp={temp}
                        requests={requests}
                        handleChange={handleChange}
                        handleCheckAll={handleCheckAll}
                        storeLeaveId={storeLeaveId}
                    /> : cardStatus === "Rejected" && storeRequestedIndex === index ?
                        <ApprovedDiv
                            actionByimage={actionByimage}
                            actionByName={actionByName}
                            actionByRole={actionByRole}
                            id={cardIndex}
                            setCardId={setCardId}
                            setActionData={setActionData}
                            requestedActionData={requestedActionData}
                            isApproveModalVisible={isApproveModalVisible}
                            setIsApproveModalVisible={setIsApproveModalVisible}
                            isButtonDivVisible={isButtonDivVisible}
                            setIsButtonDivVisible={setIsButtonDivVisible}
                            getLeaveRequestData={getLeaveRequestData}
                            togglePageLoader={togglePageLoader}
                        /> : cardStatus === "Approved" && storeRequestedIndex === index ?
                            <RejectedDiv
                                actionByimage={actionByimage}
                                actionByName={actionByName}
                                actionByRole={actionByRole}
                                id={cardIndex}
                                setCardId={setCardId}
                                setActionData={setActionData}
                                requestedActionData={requestedActionData}
                                isRejectModalVisible={isRejectModalVisible}
                                setIsRejectModalVisible={setIsRejectModalVisible}
                                isButtonDivVisible={isButtonDivVisible}
                                setIsButtonDivVisible={setIsButtonDivVisible}
                                getLeaveRequestData={getLeaveRequestData}
                                togglePageLoader={togglePageLoader}
                                profileTags={profileTags}
                                userStartDate={userStartDate}
                            /> : null : null
            }

        </div>
    )
}

function RequestViewAll({
    getLeaveRequestData,
    searchFilter,
    leaveRequestDataContainer,
    roleFilter,
    typeFilter,
    roleFilterContainer,
    setCardId,
    setActionData,
    requestedActionData,
    setStatusData,
    setSpecificUserId,
    searchFilterContainer,
    setBackToPrivilegedView,
    storeBackToPrivilegedView,
    setRequestedIndex,
    storeRequestedIndex,
    storeUsersName,
    usersNameContainer,
    viewAllClickedData,
    requestViewAllClicked,
    storeRequestViewAllPage,
    pageNumber,
    setLeaveIds,
    storeLeaveId
}) {

    const history = useHistory();
    const [pageLoader, togglePageLoader] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setStatusData('Pending'),
            searchFilter(""),
            typeFilter(["All"]),
            storeRequestViewAllPage(1)
        togglePageLoader(true)
        getLeaveRequestData(() => {
            togglePageLoader(false)
        })
    }, [])

    const onSearch = (e) => {
        searchFilter(e);
        togglePageLoader(true)
        getLeaveRequestData(() => {
            togglePageLoader(false)
        });
    }
    const Reload = () => {
        onSearch()
        togglePageLoader(true)
        getLeaveRequestData(() => {
            togglePageLoader(false)
        });
    }

    const onSelection = () => {
        togglePageLoader(true)
        getLeaveRequestData(() => {
            togglePageLoader(false)
        });
    }

    const planFilters = [
        { val: "All", id: 1, label: "All" },
        { val: "Teacher", id: 2, label: "Teachers" },
        { val: "Data Operator", id: 3, label: "Data Operators" },
        { val: "Operation", id: 4, label: "Operations" },
        { val: "Student", id: 5, label: "Students" },
    ];

    const planFilters1 = [
        { val: "All", id: 1, label: "All" },
        { val: "Leave", id: 2, label: "Leave Applications" },
        { val: "Attendance", id: 3, label: "Attendance Requests" },
    ];


    function callback(key) {
        setStatusData(key),
            setRequestedIndex(false);
        storeRequestViewAllPage(1)
        togglePageLoader(true)
        getLeaveRequestData(() => {
            togglePageLoader(false)
        })
    }

    const handlePageination = (value) => {
        storeRequestViewAllPage(value);
        togglePageLoader(true);
        getLeaveRequestData(() => togglePageLoader(false));
    };
    return (
        <div style={{
            background: "#FAFAFA",
            width: "calc(100% + 40px)",
            height: "calc(100% + 40px)",
            margin: -20,
            padding: 20,
            overflow: "auto",
        }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "700", display: "flex", flexDirection: "row", marginTop: "5px" }}>
                        <ArrowLeftOutlined
                            style={{ marginTop: "10px", fontSize: "22px" }}
                            onClick={() => {
                                history.goBack();
                                setBackToPrivilegedView(true)
                            }} />
                        <div style={{ marginLeft: "15px", fontSize: "24px" }}>Requests</div>
                    </div>
                    <div
                        style={{
                            fontWeight: "Regular",
                            fontSize: "22px",
                            color: "#AEAEAE",
                            padding: "2px",
                            marginTop: "5px",
                            marginLeft: "5px"
                        }}
                    >
                        ({leaveRequestDataContainer?.requests?.meta?.totalItems})
                    </div>
                </div>
                <div className="display-flex">
                    <div style={{ marginRight: "30px", marginTop: "10px" }}>
                        <DropDownCheckBoxWithStudent
                            DATA={planFilters}
                            placeholder={`Role`}
                            type={`Role`}
                            className="full-width"
                            onSelection={onSelection}
                            onDropdownClose={() => { }}
                            defaultValue={null}
                            roleFilter={roleFilter}
                            role={usersNameContainer} />
                    </div>
                    <div style={{ marginRight: "30px", marginTop: "10px" }}>
                        <DropDownCheckBoxWithStudent
                            DATA={planFilters1}
                            placeholder={`Type`}
                            type={'Type'}
                            className="full-width"
                            onSelection={onSelection}
                            onDropdownClose={() => { }}
                            defaultValue={null}
                            typeFilter={typeFilter} />
                    </div>
                    <div style={{ marginTop: "10px", width: "200px" }}>
                        <InputSearch allowClear placeholder="Search" onInputChange={(e) => onSearch(e)} onClear={() => { onSearch('') }} />
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "30px" }}>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="PENDING" key="Pending" >
                        {leaveRequestDataContainer?.requests?.items?.length > 0 ? (
                            <div className="flex-wrap " style={{ display: "flex" }}>
                                {leaveRequestDataContainer?.requests?.items?.map((item, index) => (
                                    <div>
                                        <LeaveApplicationCard
                                            profileImage={item?.user?.image}
                                            profileName={item?.user?.name}
                                            profileDesignation={item?.user?.role}
                                            profileStatus={item?.createdAt}
                                            profileTags={item?.requestType}
                                            userLeaveType={item?.leaveType}
                                            userLeaveDuration={item?.duration}
                                            userStartDate={item?.startDate}
                                            userEndDate={item?.endDate}
                                            userClockIn={item?.clockIn}
                                            userClockOut={item?.clockOut}
                                            userTotalHours={item?.totalHours}
                                            userLeaveReason={item?.reason}
                                            userRemainingLeaves={item?.remaingLeaves}
                                            userIsNew={item?.isNew}
                                            cardIndex={item?.id}
                                            setCardId={setCardId}
                                            cardStatus={item?.status}
                                            setActionData={setActionData}
                                            requestedActionData={requestedActionData}
                                            requestedUserId={item?.user?.id}
                                            setSpecificUserId={setSpecificUserId}
                                            leaveRequestDataContainer={leaveRequestDataContainer}
                                            index={index}
                                            getLeaveRequestData={Reload}
                                            togglePageLoader={togglePageLoader}
                                            setRequestedIndex={setRequestedIndex}
                                            storeRequestedIndex={storeRequestedIndex}
                                            setLeaveIds={setLeaveIds}
                                            requests={item?.requests}
                                            storeLeaveId={storeLeaveId}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) :
                            <div style={{
                                width: "100%",
                                height: "400px",
                                marginTop: "50px",
                            }}>
                                <NodataCard />
                            </div>
                        }

                    </TabPane>
                    <TabPane tab="APPROVED" key="Approved">
                        {leaveRequestDataContainer?.requests?.items?.length > 0 ? (
                            <div className="flex-wrap " style={{ display: "flex" }}>
                                {leaveRequestDataContainer?.requests?.items?.map((item, index) => (
                                    <div>
                                        <LeaveApplicationCard
                                            profileImage={item?.user?.image}
                                            profileName={item?.user?.name}
                                            profileDesignation={item?.user?.role}
                                            profileStatus={item?.createdAt}
                                            profileTags={item?.requestType}
                                            userLeaveType={item?.leaveType}
                                            userLeaveDuration={item?.duration}
                                            userStartDate={item?.startDate}
                                            userEndDate={item?.endDate}
                                            userLeaveReason={item?.reason}
                                            userClockIn={item?.clockIn}
                                            userClockOut={item?.clockOut}
                                            userTotalHours={item?.totalHours}
                                            cardIndex={item?.id}
                                            setCardId={setCardId}
                                            cardStatus={item?.status}
                                            setActionData={setActionData}
                                            requestedActionData={requestedActionData}
                                            actionByimage={item?.actionBy?.image}
                                            actionByName={item?.actionBy?.name}
                                            actionByRole={item?.actionBy?.role}
                                            getLeaveRequestData={Reload}
                                            togglePageLoader={togglePageLoader}
                                            setRequestedIndex={setRequestedIndex}
                                            storeRequestedIndex={storeRequestedIndex}
                                            index={index}
                                            setLeaveIds={setLeaveIds}
                                            requests={item?.requests}
                                            storeLeaveId={storeLeaveId}

                                        />
                                    </div>
                                ))}
                            </div>
                        ) :
                            <div style={{
                                width: "100%",
                                height: "400px",
                                marginTop: "50px",
           /*  minHeight:"600px",
            maxHeight:"400px" */}}>
                                <NodataCard />
                            </div>
                        }
                        {/* <PageDataLoader visible={pageLoader}/>   */}
                    </TabPane>
                    <TabPane tab="REJECTED" key="Rejected">
                        {leaveRequestDataContainer?.requests?.items?.length > 0 ? (
                            <div className="flex-wrap " style={{ display: "flex" }}>
                                {leaveRequestDataContainer?.requests?.items?.map((item, index) => (
                                    <div>
                                        <LeaveApplicationCard
                                            profileImage={item?.user?.image}
                                            profileName={item?.user?.name}
                                            profileDesignation={item?.user?.role}
                                            profileStatus={item?.createdAt}
                                            profileTags={item?.requestType}
                                            userLeaveType={item?.leaveType}
                                            userLeaveDuration={item?.duration}
                                            userStartDate={item?.startDate}
                                            userEndDate={item?.endDate}
                                            userLeaveReason={item?.reason}
                                            userClockIn={item?.clockIn}
                                            userClockOut={item?.clockOut}
                                            userTotalHours={item?.totalHours}
                                            cardIndex={item?.id}
                                            setCardId={setCardId}
                                            cardStatus={item?.status}
                                            setActionData={setActionData}
                                            requestedActionData={requestedActionData}
                                            actionByimage={item.actionBy?.image}
                                            actionByName={item.actionBy?.name}
                                            actionByRole={item.actionBy?.role}
                                            getLeaveRequestData={getLeaveRequestData}
                                            togglePageLoader={togglePageLoader}
                                            setRequestedIndex={setRequestedIndex}
                                            storeRequestedIndex={storeRequestedIndex}
                                            index={index}
                                            setLeaveIds={setLeaveIds}
                                            requests={item?.requests}
                                            storeLeaveId={storeLeaveId}

                                        />
                                    </div>
                                ))}
                            </div>
                        ) :
                            <div style={{
                                width: "100%",
                                height: "400px",
                                marginTop: "50px",
           /*  minHeight:"600px",
            maxHeight:"400px" */}}>
                                <NodataCard />
                            </div>
                        }
                    </TabPane>
                </Tabs>
            </div>
            <div style={{ position: "absolute", right: "20px", bottom: "20px" }}>
                <Pagination
                    size='small'
                    current={leaveRequestDataContainer?.requests?.meta?.currentPage}
                    defaultCurrent={1}
                    total={leaveRequestDataContainer?.requests?.meta?.totalPages * 10}
                    onChange={handlePageination}
                    showSizeChanger={false}
                />
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

// export default RequestViewAll

const mapStateToProps = (state) => {
    const {storeLeaveId,leaveRequestDataContainer,roleFilterContainer,cardId,actionDataContainer,statusDataContainer,requestedUserIdContainer,searchFilterContainer,storeBackToPrivilegedView,storeRequestedIndex,usersNameContainer,viewAllClickedData,pageNumber} =
      state.attendanceNew;
    return {
        storeLeaveId,leaveRequestDataContainer,roleFilterContainer,cardId,actionDataContainer,statusDataContainer,requestedUserIdContainer,searchFilterContainer,storeBackToPrivilegedView,storeRequestedIndex,usersNameContainer,viewAllClickedData,pageNumber
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    getLeaveRequestData:(callback)=>(dispatch(getLeaveRequestData(callback))),
    searchFilter:(value)=>dispatch(searchFilter(value)),
    roleFilter:(val)=>dispatch(roleFilter(val)),
    typeFilter:(val)=>dispatch(typeFilter(val)),
    setCardId:(id)=>dispatch(setCardId(id)),
    setActionData:(val)=>dispatch(setActionData(val)),
    requestedActionData:(callback,successCallBack)=>dispatch(requestedActionData(callback,successCallBack)),
    setStatusData:(val)=>dispatch(setStatusData(val)),
    setSpecificUserId:(val)=>dispatch(setSpecificUserId(val)),
    setBackToPrivilegedView:(val)=>dispatch(setBackToPrivilegedView(val)),
    setRequestedIndex:(val)=>dispatch(setRequestedIndex(val)),
    storeUsersName:(val)=>dispatch(storeUsersName(val)),
    requestViewAllClicked:(val)=>dispatch(requestViewAllClicked(val)),
    storeRequestViewAllPage:(val)=>dispatch(storeRequestViewAllPage(val)),
    setLeaveIds:(val) => dispatch(setLeaveIds(val))

  });

  export default connect(mapStateToProps, mapDispatchToProps)(RequestViewAll);