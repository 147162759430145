import React from "react";
import image from "Assets/user-management/ic-students.svg";
import "./styles.css";
import { useHistory } from "react-router";
import { setDashBoardRole, setEndDate, setStartDate, storeAdduserFields, setMonthOrWeek } from "actions/userManagement";
import { connect } from "react-redux";

function RoleDetailsCard({
  setDashBoardRole,
  image,
  count,
  role,
  lastWeekCount,
  storeAdduserFields,
  setStartDate,
  setEndDate,
  setMonthOrWeek
}) {
  const handleClick = (history) => {
    setMonthOrWeek("week");

    if (role == "Operations") {
      setDashBoardRole("Operation");
      storeAdduserFields('role', 'Operation')
    } else {
      setDashBoardRole(role);
      storeAdduserFields('role', role)
    }

    if (role == "Student") {
      setStartDate('')
      setEndDate('')
      history.push("/user-management/students");
    } else if (role != "Data operator") {
      history.push(`/user-management/${role.toLowerCase()}`);
    } else {
      history.push("/user-management/data-operator");
    }
  };

  const history = useHistory();
  return (
    <div
      className="roleDetailCard"
      onClick={() => handleClick(history)}
      style={{
        
      }}
    >
      <div 
      style={{height: 100, width: "100%"}}>
        <div className="r-c-c-c" style={{height: 140, width: '100%'}}>
        <img style={{ width: "40%", height: "40%" }} src={image} alt="" />
          <div className="r-c-c-c">
            <div style={{color: '#191919', fontSize: 14, fontWeight: 600, marginTop: 10}}>{count}</div>
            <div>{role}</div>
            {lastWeekCount != 0 ? (
            <>
              {lastWeekCount > 0 ? (
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "11px",
                    color: "#28DF99",
                  }}
                >
                  +{lastWeekCount} from last week
                </p>
              ) : (
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "11px",
                    color: "#FF414D",
                  }}
                >
                  {lastWeekCount} from last week
                </p>
              )}
            </>
          ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

// export default RoleDetailsCard

const mapStateToProps = (state) => {
  const { tab, dashBoardRole } = state.userManagement;
  return {
    tab,
    dashBoardRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  setTab: (m) => dispatch(setTab(m)),
  setDashBoardRole: (m) => dispatch(setDashBoardRole(m)),
  storeAdduserFields: (key,val) => dispatch(storeAdduserFields(key,val)),
  setStartDate: (res) => dispatch(setStartDate(res)),
  setEndDate: (res) => dispatch(setEndDate(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetailsCard);