import { Api } from "../services";
import moment from "moment";
import axios from "axios";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const FETCHING_MESSAGES = "FETCHING_MESSAGES";
export const FETCHING_RECENT_USERS = "FETCHING_RECENT_USERS";
export const FETCHING_FAQS = "FETCHING_FAQS";
export const SET_OVERALL_STATUS = "SET_OVERALL_STATUS";
export const SET_DATE = "SET_DATE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const IS_MONTH_OR_WEEK = "IS_MONTH_OR_WEEK";
export const STATISTICS_PLOT = "STATISTICS_PLOT";
export const SET_ROLE = "SET_ROLE";
export const SET_BATCH = "SET_BATCH";
export const SET_NAME = "SET_NAME";
export const LOAD_ERLIER_MESSAGE = "LOAD_ERLIER_MESSAGE";
export const CONVERSATION_OPENED_CLOSED_USERS =
  "CONVERSATION_OPENED_CLOSED_USERS";
export const CLOSE_CONVERSATION = "CLOSE_CONVERSATION";
export const CLOSE_OR_OPEN_CONVERSATION = "CLOSE_OR_OPEN_CONVERSATION";
export const SEND_USER_MESSAGES = "SEND_STUDENT_MESSAGES";
export const SET_SPECIFIC_STUDENT_USERID = "SET_SPECIFIC_STUDENT_USERID";
export const SET_CURRENT_PAGE_NUMBER = "SET_CURRENT_PAGE_NUMBER";
export const SET_COVID_COUNT = "SET_COVID_COUNT";
export const SET_SHOW_CARE_HOME = "SET_SHOW_CARE_HOME";
export const SET_AULAS_CARE_CHAT = "SET_AULAS_CARE_CHAT";
export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const SET_NEW_CHATES = "SET_NEW_CHATES";
export const SOCKET_CHAT_UPDATE='SOCKET_CHAT_UPDATE'

export const saveSupportInfo = (params, callback, history) => (dispatch) => {
  Api.post("/care/support/profile")
    .params(params)
    .send((response) => {
      callback();
      if (response) {
        history.push("/care/submit-success");
      }
    });
};

export const setNewChats = (res) => ({
  type: SET_NEW_CHATES,
  payload: res,
});

export const updateChats = (res) => ({
  type: SOCKET_CHAT_UPDATE,
  payload: res,
});

export const getUnreadCount = (res) => ({
  type: SET_UNREAD_COUNT,
  payload: res,
});

export const fecthingMessage = (res, page) => ({
  type: FETCHING_MESSAGES,
  payload: res,
  page,
});

export const fetchingRecentUser = (res) => ({
  type: FETCHING_RECENT_USERS,
  payload: res,
});

export const fetchingFaqs = (res) => ({
  type: FETCHING_FAQS,
  payload: res,
});

export const setDate = (res) => ({
  type: SET_DATE,
  payload: res,
});

export const getOverallStatus = (res) => ({
  type: SET_OVERALL_STATUS,
  payload: res,
});

export const setStartDate = (res) => ({
  type: SET_START_DATE,
  payload: res,
});

export const setEndDate = (res) => ({
  type: SET_END_DATE,
  payload: res,
});

export const setMonthOrWeek = (res) => ({
  type: IS_MONTH_OR_WEEK,
  payload: res,
});

export const barGraphPloat = (res) => ({
  type: STATISTICS_PLOT,
  payload: res,
});

export const setRole = (res) => ({
  type: SET_ROLE,
  payload: res,
});

export const setBatch = (res) => ({
  type: SET_BATCH,
  payload: res,
});

export const setName = (res) => ({
  type: SET_NAME,
  payload: res,
});

export const loadEarlierMsg = (res) => ({
  type: LOAD_ERLIER_MESSAGE,
  payload: res,
});

export const getConversationOpenClosedUsers = (res, page) => {
  return {
    type: CONVERSATION_OPENED_CLOSED_USERS,
    payload: res,
    page,
  };
};

export const closeCoversation = (res) => ({
  type: CLOSE_CONVERSATION,
  payload: res,
});

export const setCloseOrOpenConversation = (res) => ({
  type: CLOSE_OR_OPEN_CONVERSATION,
  payload: res,
});

export const sendUserMessage = (res) => ({
  type: SEND_USER_MESSAGES,
  payload: res,
});

export const setSpecificStudentUseId = (res) => ({
  type: SET_SPECIFIC_STUDENT_USERID,
  payload: res,
});

export const setCurrentPageNo = (res) => ({
  type: SET_CURRENT_PAGE_NUMBER,
  payload: res,
});

export const setAulasCareChat = (res) => ({
  type: SET_AULAS_CARE_CHAT,
  payload: res,
});

export const getCovidCouts = (
  registrations,
  Y_registrations,
  delivered,
  Y_delivered,
  vaccinated,
  Y_vaccinated
) => ({
  type: SET_COVID_COUNT,
  registrations,
  Y_registrations,
  delivered,
  Y_delivered,
  vaccinated,
  Y_vaccinated,
});

export const addChat = (chat) => {
  return (dispatch) =>
    dispatch({
      type: UPDATE_MESSAGE,
      payload: chat,
    });
};

export const postCareUserChat = (msg, tp) => (dispatch) => {
  const params = {
    message: msg,
    type: tp,
  };

  Api.post("/care/chat")
    .params(params)
    .send((response) => {
      if (response) {
        dispatch(getCareChat());
      }
    });
};

export const postCareChat =
  (specificStudentId, message, type, page, batch, role, date, name) =>
  (dispatch) => {
    const params = {
      userId: parseInt(specificStudentId),
      message,
      type,
    };
    Api.post("/care/chat-admin")
      .params(params)
      .send((response, error) => {
        if (response) {
          dispatch(
            getConversationOpenedClosedUsersFunction(
              page,
              batch,
              role,
              date,
              name,
              specificStudentId
            )
          );
        }
      });
  };

export const postCareSendImg =
  (file, specificStudentId, adminId, callback=null) =>
  // (file, specificStudentId, adminId, page, batch, role, date, name) =>
  (dispatch, getState) => {
    const { name } = getState().adminCare;
    const params = {
      file,
      userId: parseInt(specificStudentId),
      adminId,
      type: "Image",
    };

    Api.post("/care/files").upload(params, (percent, response) => {
      if (response && percent == 100) {
        if(callback){
          console.log('msg1',response,percent)
          callback(response?.data?.message)
        }
        if (adminId == 1) {
          dispatch(
            getConversationOpenedClosedUsersFunction(
              // page,
              // batch,
              // role,
              // date,
              // name,
              1,
              null,
              null,
              null,
              name,
              specificStudentId
            )
          );
        } else {
          dispatch(getCareChat(1));
        }
      }
    });
  };

export const getCareUsers =
  (batch, role, date, name, callback = null) =>
  (dispatch) => {
    const params = {
      id: batch,
      role: role,
      // date: date ? moment(date).format("YYYY-MM-DD") : null,
      name: name,
    };

    return Api.get("/care/admin/coversation-list")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(fetchingRecentUser(response));
        }
      });
  };

export const getOverallStatusFunction = (callback) => (dispatch, getState) => {
  const { startDate, endDate, isMonthOrWeek } = getState().adminCare;
  const params = {
    startDate: startDate || moment().startOf('week').format("YYYY-MM-DD"),
    endDate: endDate || moment().add(6,'days').format("YYYY-MM-DD"),
    type: isMonthOrWeek || "week",
    // type: "month"
  };
  return Api.get("/care/admin")
    .params(params)
    .send((response) => {
      callback();
      if (response) {
        if (response.support === false) {
          return dispatch(setShowCareHome(false));
        }
        dispatch(setShowCareHome(true));
        dispatch(getOverallStatus(response));
        dispatch(barGraphPloat(response.summary));
      }
    });
};

const setShowCareHome = (bool) => ({
  type: SET_SHOW_CARE_HOME,
  bool,
});

export const getCareChat =
  (page, callback = null) =>
  (dispatch) => {
    const params = {
      page: page || 1,
      limit: 15,
    };
    return Api.get("/care/chat")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(fecthingMessage(response, response?.page));
        }
      });
  };

export const getAdminFaqs = () => (dispatch) => {
  const params = {};
  return Api.get("/care/admin-page")
    .params(params)
    .send((response) => {
      dispatch(fetchingFaqs(response));
    });
};

export const getStudentFaqs = () => (dispatch) => {
  const params = {};
  return Api.get("/care")
    .params(params)
    .send((response) => {
      dispatch(fetchingFaqs(response));
    });
};

export const barGraphPlotFunction = (callback) => {
  return (dispatch, getState) => {
    const { startDate, endDate, isMonthOrWeek } = getState().adminCare;

    const params = {
      startDate: startDate || moment().startOf("week").format("YYYY-MM-DD"),
      endDate: endDate || moment().endOf("week").format("YYYY-MM-DD"),
      type: isMonthOrWeek || "week",
      // type: "month"
    };
    return Api.get("/care/admin/filter")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          dispatch(barGraphPloat(response));
        }
      });
  };
};

export const getConversationOpenedClosedUsersFunction =
  (page, batch, role, date, name, userId, callback = null) =>
  (dispatch) => {
    const params = {
      page: page || 1,
      limit: 15,
      id: batch,
      role,
      // date: date ? moment(date).format("YYYY-MM-DD") : null,
      name,
      userId,
    };
    return Api.get("/care/admin/user-conversation")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }

        if (response != undefined) {
          dispatch(getConversationOpenClosedUsers(response, response.page));
        }
      });
  };

export const getCovid19Count = () => (dispatch) => {
  const params = {};
  axios
    .get(
      "https://cdn-api.co-vin.in/api/v1/reports/v2/getPublicReports?state_id=&district_id=&date=",
      {
        params: {},
      }
    )
    .then(function (response) {
      const base = dispatch(
        getCovidCouts(
          response.data?.topBlock?.registration.total,
          response.data?.topBlock?.registration.yesterday,
          response.data?.topBlock.vaccination?.total,
          response.data?.topBlock.vaccination?.yesterday_vac,
          response.data?.topBlock.vaccination.tot_dose_2,
          response.data?.topBlock.vaccination.yesterday_dose_two
        )
      );
    });
};

export const sentCareMsg =
  (message, callback = null) =>
  (dispatch, getState) => {
    const params = {
      message: message,
      type: "Text",
      masterAdminId: "1234",
    };

    return Api.post("/admin-care/chat", params).send((response) => {
      if (callback) {
        callback();
      }

      if (response !== undefined) {
        dispatch(getChatMasterAdmin());
      }
    });
  };

export const getChatMasterAdmin =
  (callback = null, page) =>
  (dispatch, getState) => {
    const params = {
      masterAdminId: "1234",
      page: page ? page : 1,
    };

    return Api.get("/admin-care/organisation-chat")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(fecthingMessage(response, response?.page));
        }
      });
  };

export const sentCareImag =
  (file, callback = null) =>
  (dispatch, getState) => {
    const params = {
      file: file,
      type: "Image",
      masterAdminId: "1234",
    };

    Api.post("/admin-care/files").upload(params, (percent, response) => {
      if (response && percent == 100) {
        console.log('first',response)
        callback(response?.data?.message)
        dispatch(getChatMasterAdmin());
      }
    });
  };

export const getChatUnreadCountForInst =
  (callback = null) =>
  (dispatch, getState) => {
    const params = {};

    return Api.get("/admin-care/unreadMessage")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(getUnreadCount(response.unreadMessage));
        }
      });
  };
