import { Image, Typography } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import lectureNotes from 'Assets/digitalLibrary/lectureNotes.svg'
import videos from 'Assets/digitalLibrary/videos.svg'
import questionBank from 'Assets/digitalLibrary/question-bank.svg'
import { updateSelectedChapter } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import { useDrag, useDrop } from 'react-dnd'

const ItemType = 'CHAPTER';

const ChapterCard = ({ chapterItem, index, updateSelectedChapter, isEditOrderClicked, moveChapter, user }) => {
    const history = useHistory()
    const handleClick = () => {
        if (!isEditOrderClicked) {
        updateSelectedChapter(chapterItem)
        history.push('/digital-library/chapter-details')
        }
    }

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { chapterItem, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item) {
            if (!item || item.index === index) {
                return;
            }
            moveChapter(item.index, index);
            item.index = index;
        },
    });

    const totalSize = parseInt(chapterItem?.totalSize) || 0;
    const totalSizeInMB = (totalSize / 1048576).toFixed(2);
    const totalSizeInGB = (totalSize / 1073741824).toFixed(2);
    const displaySize = totalSize === 0 
        ? '0 MB' 
        : (totalSizeInGB > 1 ? `${totalSizeInGB} GB` : `${totalSizeInMB} MB`);

    return (
        <div style={{ marginRight: "15px",marginBottom:"10px" }}>
            <div ref={(node) => isEditOrderClicked ? drag(drop(node)) : null} style={{ opacity: isDragging ? 0.5 : 1, cursor: isEditOrderClicked ? 'move' : 'pointer', position: "relative" }} className='digital-library-chapter-card cursor-pointer' onClick={() => handleClick()} >
                <div style={{ padding: "15px", marginBottom:5 }}>
                    <Typography.Text className='text-content-library'><span style={{ fontSize: "12px", color: "#636363" }}>Chapter {index + 1}</span>  <span style={{ fontWeight: 'bold', color: "#636363", fontSize: "12px" }}>• {chapterItem?.topicCount} Topic(s)</span></Typography.Text>
                    <div style={{ width: '100%' }}>
                        <Typography.Paragraph className='text-heading-library'
                            // style={{ width: '100%', marginBottom: 0 }}
                            ellipsis={{
                                rows: 1,
                                tooltip: true
                            }}
                        >
                            {chapterItem?.chapterName}
                        </Typography.Paragraph>
                    </div>
                </div>
                {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                    <div style={{ position: "absolute", display: "flex", justifyContent: 'flex-start', height: 30, width: '100%', background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", bottom: 0, right: 0 }}>
                        <div style={{ color: "#FFFFFF", fontWeight: "bold" }}> {displaySize} data</div>
                    </div> : <></>}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" ,marginBottom:"10px",marginTop:"3px", width: "255px" }}>
                <div ><span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {chapterItem?.notesCount ? chapterItem?.notesCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Notes</span> <span style={{ fontSize: "13px", color: "#AEAEAE",marginLeft:"6px" }}>|</span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {chapterItem?.videoCount ? chapterItem?.videoCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Videos</span> <span style={{ fontSize: "13px", color: "#AEAEAE",marginLeft:"6px" }}>|</span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {chapterItem?.quesBankCount ? chapterItem?.quesBankCount : 0}  </span> <span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Test papers</span></div>
            </div>
        </div>
                /* <div>
                    <Typography.Text className='text-content-library'>Chapter {chapterItem?.chapterNo} • {chapterItem?.topicCount} Topic(s)</Typography.Text>
                </div>
                <div style={{ marginTop: '15px', width: '100%' }}>
                    <Typography.Paragraph className='text-heading-library'
                        // style={{ width: '100%', marginBottom: 0 }}
                        ellipsis={{
                            rows: 2,
                            tooltip: true
                        }}
                    >
                       {chapterItem?.chapterName}
                    </Typography.Paragraph>
                </div> */

    /* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}>
                        <Image src={lectureNotes} preview={false}></Image>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '11px', marginLeft: '2px' }} ellipsis={{ rows: 1, tooltip: true }}>{chapterItem?.notesCount}</Typography.Text>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}>
                        <Image src={videos} preview={false}></Image>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '11px', marginLeft: '2px' }} ellipsis={{ rows: 1, tooltip: true }}>{chapterItem?.videoCount}</Typography.Text>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={questionBank} preview={false}></Image>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '11px', marginLeft: '2px' }} ellipsis={{ rows: 1, tooltip: true }}>{chapterItem?.quesBankCount}</Typography.Text>
                    </div>

                </div> */

          
       
    )
}

const mapStateToProps = (state) => {
    const {
    } = state.digitalLibrary;
    const { user } = state.session
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSelectedChapter: (object) => dispatch(updateSelectedChapter(object)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ChapterCard);
