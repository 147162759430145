import React, { useEffect, useState } from 'react'
import { AutoComplete, Modal, Input, Typography, Button, DatePicker, TimePicker, Tag, Tooltip, Alert } from 'antd'
import '../styles.css'
import { fetchClassList, fetchSubList, fetchTeacherList, postCreateSchedule, setClass, setEndTime, setMode, setSartDate, setEndDate, setStartTime, setSubject, setTeacher, setWeekDays, setScheduleIdDelete, fetchTimetableData, fetchSchedule, setDelOrCancel, setCurrentDate, fetchScheduleFilter } from 'actions/schedule'
import { connect } from 'react-redux'
import moment from 'moment'

import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { MdCastConnected } from 'react-icons/md'
import Conference from "Assets/schedule/confrence.svg"
import Broadcast from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import Conferencewhite from "Assets/schedule/confrence-white.svg"
import BoradcastWhite from "Assets/schedule/Broadcast-white.svg"
import OfflineWhite from "Assets/schedule/ofline-white.svg"
import OtherColor from "Assets/schedule/other_color.svg"
import OtherWhite from "Assets/schedule/other_white.svg"
import { EventRegister } from 'react-native-event-listeners'
const { Text } = Typography

const EditFormModal = ({ isModalVisible, setModalVisible, editData, subList, fetchSubList, fetchClassList, classList, setSubject, setClass, subId, teacherList, fetchTeacherList,
    setTeacher, setStartTime, setSartDate, setEndDate, setEndTime, setMode, weekdays, setWeekDays, classId, teacherId, startTime, endTime, startDate, endDate,
    postCreateSchedule, mode, toogleDeleteModal, setScheduleIdDelete, fetchSchedule, togglePageLoader, tabType, stdTimetable, fetchTimetableData, setDelOrCancel, setCurrentDate, currentDate, fetchScheduleFilter
    , getModes, teacherSearch, classFilter, subFilter
}) => {

    const [colorDays, setColorDays] = useState([false, false, false, false, false, false, false])
    const [optionSub, setOptionsSub] = useState([])
    const [optionClass, setOptionsClass] = useState([])
    const [optionTeacher, setOptionsTeacher] = useState([])
    const [subName, setSubName] = useState('')
    const [className, setClassName] = useState('')
    const [teacherName, setTeacherName] = useState('')
    const [scheduleId, setScheduleId] = useState(null)
    let [classNameArr, setClassNameArr] = useState([])
    const [buttonColor, setButtonColor] = useState([false, false, false, false, false])
    const [subjectLoader, setSubjectLoader] = useState(false)
    const [stdLoader, setStdLoader] = useState(false)
    const [teacherLoader, setTeacherLoader] = useState(false)
    const [saveLoader, setSaveLoader] = useState(false)

    const todayDate = moment().format('YYYY-MM-DD')
    const currentTime = moment().format('HH:mm')
    const [accessEdit, setAccessEdit] = useState(false)

    const format = 'hh:mm A'
    const formatDate = "DD/MM/YYYY"
    useEffect(() => {
        if (Object.keys(editData).length != 0) {
            setSubName(editData?.schedule?.subject?.name)
            setSubject(editData?.schedule?.subject?.id)

            if (editData?.schedule?.subject?.id) {
                fetchClassList('', editData?.schedule?.subject?.id)
            }
            if (editData.schedule != null) {
                editData.schedule.standards.map((item) => (
                    classId.push(item.id),
                    classNameArr.push({ id: item.id, name: item.std + '-' + item.section })
                ))
            }
            setClass(classId)
            setClassNameArr(classNameArr)
            setTeacherName(editData?.schedule?.teacher?.name)
            setTeacher(editData?.schedule?.teacher?.id)
            setStartTime(`${moment(editData?.schedule?.startTime, "HH:mm:ss").format("HH:mm")}`)
            setSartDate(editData?.schedule?.startDate)
            setEndTime(`${moment(editData?.schedule?.endTime, "HH:mm:ss").format("HH:mm")}`)
            setMode(editData?.schedule?.mode)
            if (editData?.schedule?.mode == 'Zoom 40') {
                buttonColor[0] = true
            }
            else if (editData?.schedule?.mode == 'Conference') {
                buttonColor[1] = true
            }
            else if (editData?.schedule?.mode == 'Broadcast') {
                buttonColor[2] = true
            }
            else if (editData?.schedule?.mode == 'Offline') {
                buttonColor[3] = true
            }else if(editData?.schedule?.mode == 'Others'){
                buttonColor[4] = true
            }
            setEndDate(editData?.schedule?.endDate)
            if (editData.schedule != null) {
                editData.schedule.weekdays.map((item, index) => (
                    weekdays.push(item)
                ))
                setWeekDays(weekdays)
                editData.schedule.weekdays.map((item) => {
                    if (item == "sunday") {
                        return colorDays[0] = true
                    }
                    else if (item == "monday") {
                        return colorDays[1] = true
                    }
                    else if (item == "tuesday") {
                        return colorDays[2] = true
                    }
                    else if (item == "wednesday") {
                        return colorDays[3] = true
                    }
                    else if (item == "thursday") {
                        return colorDays[4] = true
                    }
                    else if (item == "friday") {
                        return colorDays[5] = true
                    }
                    else if (item == "saturday") {
                        return colorDays[6] = true
                    }
                })
            }

            setColorDays([...colorDays])
            setScheduleId(editData?.scheduleId)
            setScheduleIdDelete(editData?.scheduleId)
            if (todayDate == editData?.date) {
                if (currentTime >= moment(editData?.startTime, 'HH:mm:ss').format('HH:mm') && currentTime <= moment(editData?.endTime, 'HH:mm:ss').format('HH:mm')) {
                    setAccessEdit(true)
                }
            }
        }
    }, [])
    const handleDaysButtonColor = (value, active) => {

        if (value == "Su") {

            colorDays[0] = !active
            setColorDays([...colorDays])
            if (colorDays[0] == true) {
                weekdays.push("sunday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'sunday'
                })
            }
        }
        else if (value == "M") {
            colorDays[1] = !active
            setColorDays([...colorDays])
            if (colorDays[1] == true) {
                weekdays.push("monday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'monday'
                })
            }
        }
        else if (value == "T") {
            colorDays[2] = !active
            setColorDays([...colorDays])
            if (colorDays[2] == true) {
                weekdays.push("tuesday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'tuesday'
                })
            }
        }
        else if (value == "W") {
            colorDays[3] = !active
            setColorDays([...colorDays])
            if (colorDays[3] == true) {
                weekdays.push("wednesday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'wednesday'
                })
            }
        }
        else if (value == "Th") {
            colorDays[4] = !active
            setColorDays([...colorDays])
            if (colorDays[4] == true) {

                weekdays.push("thursday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'thursday'
                })
            }
        }
        else if (value == "F") {
            colorDays[5] = !active
            setColorDays([...colorDays])
            if (colorDays[5] == true) {
                weekdays.push("friday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'friday'
                })
            }
        }
        else if (value == "S") {
            colorDays[6] = !active
            setColorDays([...colorDays])
            if (colorDays[6] == true) {
                weekdays.push("saturday")
            }
            else {
                weekdays = weekdays.filter(week => {
                    return week != 'saturday'
                })
            }
        }

        setWeekDays(weekdays)

    }
    const [validModes, setValidModes] = useState([])
    useEffect(() => {
        fetchSubList()
        fetchTeacherList('')
        if (getModes.length != 0) {
            getModes.map((item) => (
                validModes.push(item.mode)
            ))
        setValidModes([...validModes, 'Others'])
        }
    }, [])
    const searchResult = (query) =>
        query.map((item, index) => {
            return {
                key: index,
                value: item.id,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {item.name}
                        </span>
                    </div>
                ),
            };
        });

    const searchResultClass = (query) =>
        query.map((item, index) => {
            return {
                key: index,
                value: item.id,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {item.std + '-' + item.section}
                        </span>
                    </div>
                ),
            };
        });
    useEffect(() => {
        setOptionsSub(subList ? [...searchResult(subList)] : [])
    }, [subList])

    useEffect(() => {
        setOptionsClass(classList ? [...searchResultClass(classList)] : [])
    }, [classList])

    useEffect(() => {
        setOptionsTeacher(teacherList ? [...searchResult(teacherList)] : [])
    }, [teacherList])


    const handleSearchSub = (value) => {
        if (!value) {
            setSubName('')
        }
        else {
            setSubName(value)
            setSubjectLoader(true)
        }
        fetchSubList(value, () => setSubjectLoader(false))
    }

    const handleChangeSub = (value, obj) => {
        setSubject(null)
        setClass([])
        setClassName('')
        setClassNameArr([])
        setTeacher(null)
        setTeacherName('')
        if (value) {
            setSubName(obj?.label?.props?.children?.props?.children)
        }
        else {

            setSubName('')


        }
    }

    const onSelectSub = (value, obj) => {
        if (value) {
            setSubject(value)

            setSubName(obj?.label?.props?.children?.props?.children)
            setClass([])
            setClassNameArr([])
            fetchClassList('', value)

        }
        else {
            setSubject(null)
            setSubName('')
            setClass([])
            setClassNameArr([])
            setTeacher(null)
            setTeacherName('')
        }
    }

    const handleSearchClass = (value) => {
        if (value) {
            setClassName(value)
            setStdLoader(true)
            fetchClassList(value, subId, () => setStdLoader(false))
        }
        else {
            setClassName('')
            fetchClassList('', subId, () => setStdLoader(false))
        }
    }

    const handleChangeClass = (value, obj) => {
        if (!value) {
            // setClass([])
            setClassName('')
            // setClassNameArr([])
            // setTeacher(null)
            // setTeacherName('')
        }
    }

    const onSelectClass = (value, obj) => {
        if (value) {
            if (!classId.includes(value)) {
                classId.push(value)
                setClass(classId)
                setClassName(obj?.label?.props?.children?.props?.children)
                classNameArr.push({ id: value, name: obj?.label?.props?.children?.props?.children })
                setClassNameArr(classNameArr)
            }

            fetchTeacherList('')
        }
        else {
            setClass([])
            setClassName('')
            setClassNameArr([])
            setTeacher(null)
            setTeacherName('')
        }
    }

    const handleSearchTeacher = (value) => {
        if (value) {
            setTeacherName(value)
            setTeacherLoader(true)

        }
        else {
            setTeacherName('')
        }
        fetchTeacherList(value, () => setTeacherLoader(false))
    }

    const onSelectTeacher = (value, obj) => {
        if (value) {
            setTeacher(value)
            setTeacherName(obj?.label?.props?.children?.props?.children)
        }
        else {
            setTeacher(null)
            setTeacherName('')
        }
    }

    const handleChangeTeacher = (value, obj) => {
        setTeacher(null)
        setTeacherName('')
        if (!value) {
            setTeacher(null)
            setTeacherName('')
        }
    }

    const onChangeStartDate = (date, dateString) => {
        if (dateString) {
            setSartDate(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        }
        else {
            setSartDate('')
        }
    }

    const onChangeLastDate = (date, dateString) => {
        if (dateString) {
            setEndDate(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        }
        else {
            setEndDate('')
        }
    }

    const onChangeStartTime = (time, timestring) => {
        setEndTime('');
        if (timestring) {
            const timestring24 = moment(timestring, format).format('HH:mm');
            if (mode === "Zoom 40") {
                setEndTime(moment(timestring24, 'HH:mm').add(40, 'minutes').format('HH:mm'));
            }
            setStartTime(timestring24);
        } else {
            setStartTime('');
        }
    };

    const onChangeEndTime = (time, timestring) => {
        if (timestring) {
            const timestring24 = moment(timestring, format).format('HH:mm');
            setEndTime(timestring24);
        } else {
            setEndTime('');
        }
    };

    const handleClickMode = (value) => {
        setMode(value)
        if (value == "Zoom 40") {
            buttonColor[0] = true
            buttonColor[1] = false
            buttonColor[2] = false
            buttonColor[3] = false
            buttonColor[4] = false
            // setEndTime(moment(startTime, "HH:mm").add(40, 'minutes').format('HH:mm'))
        }
        else if (value == "Conference") {
            buttonColor[0] = false
            buttonColor[1] = true
            buttonColor[2] = false
            buttonColor[3] = false
            buttonColor[4] = false
        }
        else if (value == "Broadcast") {
            buttonColor[0] = false
            buttonColor[1] = false
            buttonColor[2] = true
            buttonColor[3] = false
            buttonColor[4] = false
        }
        else if (value == "Offline") {
            buttonColor[0] = false
            buttonColor[1] = false
            buttonColor[2] = false
            buttonColor[3] = true
            buttonColor[4] = false
        }else if(value=="Others"){
            buttonColor[0] = false
            buttonColor[1] = false
            buttonColor[2] = false
            buttonColor[3] = false
            buttonColor[4] = true
        }
        setButtonColor(buttonColor)
    }

    const onClose = (value) => {

        classNameArr = classNameArr.filter((item) => {
            return item.id != value
        })
        classId = classId.filter((item) => {
            return item != value
        })
        setClass(classId)
        setClassName('')
        // setTeacher(null)
        // setTeacherName('')

    }
    const disabledDate = (current) => {
        return (current && current < moment(startDate, "YYYY-MM-DD")) || (current && current < moment(todayDate, 'YYYY-MM-DD'))
    }

    const disabledStartDate = (current) => {
        return current < moment(todayDate, 'YYYY-MM-DD')
    }

    const disabledStartTimeHours = () => {

        var hours = []
        if (moment().format('YYYY-MM-DD') == startDate) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }
        }
        return hours
    }

    const disabledStartTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour() && moment().format('YYYY-MM-DD') === startDate) {
            for (var i = 0; i < moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const disabledEndTimeHours = () => {

        var hours = []
        for (var i = 0; i < moment(startTime, "HH").format('HH'); i++) {
            hours.push(i)
        }

        return hours
    }

    const disabledEndTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour() && moment().format('YYYY-MM-DD') === startDate) {
            for (var i = 0; i < moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }
    const removeSelectedClass = (currClass) => {
        for (let i = 0; i < classNameArr.length; i++) {
            if (currClass?.label?.props?.children?.props?.children === classNameArr[i]?.name) {
                return false
            }
        }
        return true
    }

    const createSchedule = () => {
        if (scheduleId && subId && startTime && endTime && startDate && endDate && mode && classId.length != 0 && teacherId && weekdays.length != 0) {
            var objectSchedule = {
                scheduleId: scheduleId,
                teacherId: teacherId,
                subjectId: subId,
                classIds: classId,
                weekdays: weekdays,
                startTime: startTime,
                endTime: endTime,
                mode: mode,
                startDate: startDate,
                endDate: endDate
            }

            setSaveLoader(true)
            postCreateSchedule(objectSchedule, () => setSaveLoader(false), successCallback)




        }
        else {
            alert('please fill the all information')
        }
    }

    const successCallback = () => {
        EventRegister.emit('apicall:scheduleHome')
        setSaveLoader(false)
        setSubject(null)
        setSubName('')
        setTeacher(null)
        setSartDate('')
        setEndDate('')
        setStartTime('')
        setEndTime('')
        setMode('')
        setWeekDays([])
        setClass([])
        setClassNameArr([])
        setScheduleId(null)
        togglePageLoader(true)
        if (tabType == 'schedule') {

            if (currentDate === moment().format('DD/MM/YYYY')) {
                fetchSchedule(() => togglePageLoader(false))
            }
            else {
                fetchScheduleFilter(moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), teacherSearch, classFilter, subFilter, () => togglePageLoader(false))
            }
        }
        else if (tabType = 'timetable') {
            fetchTimetableData(stdTimetable, () => togglePageLoader(false))
        }

        setModalVisible(false)



    }

    const onKeyDown = (e) => {
        e.preventDefault();
    }


    const handleCancle = () => {
        setSubject(null)
        setSubName('')
        setTeacher(null)
        setSartDate('')
        setEndDate('')
        setStartTime('')
        setEndTime('')
        setMode('')
        setWeekDays([])
        setClass([])
        setClassNameArr([])
        setScheduleId(null)
        setAccessEdit(false)
        setModalVisible(false)

    }
    const handleDelete = () => {
        setScheduleIdDelete(editData?.scheduleId)
        setSubject(null)
        setSubName('')
        setTeacher(null)
        setSartDate('')
        setEndDate('')
        setStartTime('')
        setEndTime('')
        setMode('')
        setWeekDays([])
        setClass([])
        setClassNameArr([])
        setScheduleId(null)
        setDelOrCancel('deleteSchedule')
        toogleDeleteModal(true)
        setModalVisible(false)
    }
    return (
        <Modal
            className="createForm"
            visible={isModalVisible}
            footer={null}
            centered={true}
            closable={true}
            bodyStyle={{ height: "450px" }}
            onCancel={() => handleCancle()}
        >
            <div className="createForm-scroll">
                <div className="createForm-input">
                    {accessEdit == true ? <div>
                        <Alert message="Informational Notes" type="info" showIcon description='On-going class is not editable. Please try again once the class is over.' style={{ backgroundColor: '#00BFFF', borderRadius: '16px' }} />
                    </div> : null}
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '10px' }}>Subject</Text><br></br>
                        <AutoComplete
                            allowClear={true}
                            dropdownMatchSelectWidth={252}
                            className="autocompletetoupload"
                            options={optionSub}
                            value={subName}
                            onChange={handleChangeSub}
                            onSelect={onSelectSub}
                            onSearch={handleSearchSub}
                            loading={subjectLoader}

                        >
                            <Input size="middle" className="input-component" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select subject' />
                        </AutoComplete>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Class</Text><br></br>
                        <AutoComplete
                            allowClear={true}
                            dropdownMatchSelectWidth={252}
                            className="autocompletetoupload"
                            options={optionClass ? optionClass?.filter(removeSelectedClass) : []}
                            value={className}
                            onChange={handleChangeClass}
                            onSelect={onSelectClass}
                            onSearch={handleSearchClass}
                            loading={stdLoader}
                            disabled={subId ? false : true}
                        >
                            <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select class' />
                        </AutoComplete>
                        {classNameArr.length != 0 ? classNameArr.map((item) => (
                            <Tag closable color="#594099" style={{ marginTop: '10px', height: '20px' }} onClose={() => onClose(item.id)}>{item.name}</Tag>
                        )) : null}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Teacher</Text><br></br>
                        <AutoComplete
                            allowClear={true}
                            dropdownMatchSelectWidth={252}
                            className="autocompletetoupload"
                            value={teacherName}
                            options={optionTeacher}
                            onChange={handleChangeTeacher}
                            onSelect={onSelectTeacher}
                            onSearch={handleSearchTeacher}
                            loading={teacherLoader}
                        // disabled={classId.length != 0 ? false : true}
                        >
                            <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select teacher' />
                        </AutoComplete>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Mode of class</Text><br></br>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop:10 }}>

                            {/* <Button style={{ backgroundColor: buttonColor[0] == true ? "#1089FF" : '#E7F3FF', display: validModes.includes('Zoom 40') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: '16px' }} onClick={() => handleClickMode("Zoom 40")}><IoMdVideocam style={{ color: buttonColor[0] == true ? '#FAFAFA' : '#1089FF', textAlign: 'center' }} /><Text style={{ fontSize: '10px', color: buttonColor[0] == true ? '#FAFAFA' : '#1089FF', marginLeft: '5px' }}>Zoom 40</Text></Button> */}
                            <Button style={{ width:190, backgroundColor: buttonColor[1] == true ? '#594099' : '#F6F4FE', display: validModes.includes('Conference') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', borderRadius: '16px' }} onClick={() => handleClickMode("Conference")}><img src={buttonColor[1] == false ? Conference : Conferencewhite} style={{ color: buttonColor[1] == true ? '#FAFAFA' : '#594099', textAlign: 'center', height: "16px", width: "16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[1] == true ? '#FAFAFA' : '#594099', marginLeft: '5px' }}>Conference</Text></Button>
                            <Button style={{ width:190, backgroundColor: buttonColor[2] == true ? '#FFA931' : '#FFF4D5', display: validModes.includes('Broadcast') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center',  borderRadius: '16px' }} onClick={() => handleClickMode("Broadcast")}><img src={buttonColor[2] == false ? Broadcast : BoradcastWhite} style={{ color: buttonColor[2] == true ? '#FAFAFA' : '#FFA931', textAlign: 'center', height: "16px", width: "16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[2] == true ? '#FAFAFA' : '#FFA931', marginLeft: '5px' }}>Broadcast</Text></Button>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop:15 }}>
                            <Button style={{ width:190, backgroundColor: buttonColor[3] == true ? "#28DF99" : '#E9FCF5', display: validModes.includes('Offline') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center',  borderRadius: '16px' }} onClick={() => handleClickMode("Offline")}><img src={buttonColor[3] == false ? Offline : OfflineWhite} style={{ color: buttonColor[3] == true ? '#FAFAFA' : '#28DF99', textAlign: 'center', height: "16px", width: "16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[3] == true ? '#FAFAFA' : '#28DF99', marginLeft: '5px' }}>Offline</Text></Button>
                            <Button style={{ width:190, backgroundColor: buttonColor[4] == true ? "#E50087" : '#FFE5F4', display: validModes.includes('Others') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center',  borderRadius: '16px' }} onClick={() => handleClickMode("Others")}><img src={buttonColor[4] == false ? OtherColor : OtherWhite} style={{ color: buttonColor[4] == true ? '#FAFAFA' : '#E50087', textAlign: 'center',height:"16px",width:"16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[4] == true ? '#FAFAFA' : '#E50087', marginLeft: '5px' }}>Other</Text></Button>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Start date</Text><br></br>
                        <DatePicker onKeyDown={(e) => onKeyDown(e)} value={startDate ? moment(startDate, "YYYY-MM-DD") : null} style={{ width: "100%", background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select date' format={formatDate} onChange={onChangeStartDate} disabledDate={disabledStartDate} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <Text style={{ marginBottom: '5px' }}>Start time</Text><br></br>
                            <TimePicker onKeyDown={(e) => onKeyDown(e)} value={startTime ? moment(startTime, 'HH:mm') : null} format={format} style={{ width: '170px', background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} onChange={onChangeStartTime} showNow={false} disabledHours={() => disabledStartTimeHours()} disabledMinutes={disabledStartTimeMinutes} disabled={startDate ? false : true} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <Text style={{ marginBottom: '5px' }}>End time</Text><br></br>
                            <TimePicker onKeyDown={(e) => onKeyDown(e)} value={endTime ? moment(endTime, 'HH:mm') : null} format={format} style={{ width: '170px', background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} disabled={startTime ? false : true} showNow={false} onChange={onChangeEndTime} disabledHours={() => disabledEndTimeHours()} disabledMinutes={disabledEndTimeMinutes} />
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Repeat on</Text><br></br>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button shape='circle' type={colorDays[0] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("Su", colorDays[0])}>Su</Button>
                            <Button shape='circle' type={colorDays[1] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("M", colorDays[1])}>M</Button>
                            <Button shape='circle' type={colorDays[2] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("T", colorDays[2])}>T</Button>
                            <Button shape='circle' type={colorDays[3] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("W", colorDays[3])}>W</Button>
                            <Button shape='circle' type={colorDays[4] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("Th", colorDays[4])}>Th</Button>
                            <Button shape='circle' type={colorDays[5] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("F", colorDays[5])}>F</Button>
                            <Button shape='circle' type={colorDays[6] == true ? 'primary' : 'default'} onClick={() => handleDaysButtonColor("S", colorDays[6])}>S</Button>
                        </div>

                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Last date</Text><br></br>
                        <DatePicker onKeyDown={(e) => onKeyDown(e)} value={endDate ? moment(endDate, "YYYY-MM-DD") : null} style={{ width: "100%", background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select date' format={formatDate} onChange={onChangeLastDate} disabledDate={disabledDate} disabled={startDate ? false : true} />
                    </div>

                </div>
                <div style={{ width: '60%', marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button className='BlankGreyBtnWrap radius-100' style={{ width: 120 }} onClick={() => handleDelete()} disabled={subId && startTime && endTime && startDate && endDate && mode && classId.length != 0 && teacherId && weekdays.length != 0 && (accessEdit == false) ? false : true}>Delete</Button>
                    <Button loading={saveLoader} className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118' onClick={createSchedule} disabled={subId && startTime && endTime && startDate && endDate && mode && classId.length != 0 && teacherId && weekdays.length != 0 && (accessEdit == false) ? false : true}>Save</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const {
        subList,
        subId,
        classList,
        teacherList,
        weekdays,
        classId,
        teacherId,
        startTime,
        endTime,
        startDate,
        endDate,
        mode,
        tabType,
        stdTimetable,
        currentDate,
        getModes,
        teacherSearch,
        classFilter,
        subFilter
    } = state.schedule;
    return {
        subList,
        subId,
        classList,
        teacherList,
        weekdays,
        classId,
        teacherId,
        startTime,
        endTime,
        startDate,
        endDate,
        mode,
        tabType,
        stdTimetable,
        currentDate,
        getModes,
        teacherSearch,
        classFilter,
        subFilter
    };
};

const mapDispatchToProps = (dispatch) => ({
    setSubject: (subId) => dispatch(setSubject(subId)),
    setClass: (classId) => dispatch(setClass(classId)),
    setTeacher: (teacherId) => dispatch(setTeacher(teacherId)),
    setStartTime: (time) => dispatch(setStartTime(time)),
    setEndTime: (time) => dispatch(setEndTime(time)),
    setSartDate: (date) => dispatch(setSartDate(date)),
    setEndDate: (enddate) => dispatch(setEndDate(enddate)),
    setMode: (mode) => dispatch(setMode(mode)),
    setWeekDays: (week) => dispatch(setWeekDays(week)),
    fetchSubList: (search, callback) => dispatch(fetchSubList(search, callback)),
    fetchClassList: (search, subId, callback) => dispatch(fetchClassList(search, subId, callback)),
    fetchTeacherList: (search, callback) => dispatch(fetchTeacherList(search, callback)),
    postCreateSchedule: (object, callback, successCallback) => dispatch(postCreateSchedule(object, callback, successCallback)),
    setScheduleIdDelete: (id) => dispatch(setScheduleIdDelete(id)),
    fetchTimetableData: (stdTimetable, callback) => dispatch(fetchTimetableData(stdTimetable, callback)),
    fetchSchedule: (callback) => dispatch(fetchSchedule(callback)),
    setDelOrCancel: (delOrCancel) => dispatch(setDelOrCancel(delOrCancel)),
    setCurrentDate: (date) => dispatch(setCurrentDate(date)),
    fetchScheduleFilter: (date, topic, std, sub, callback) => dispatch(fetchScheduleFilter(date, topic, std, sub, callback))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(EditFormModal);