import React from "react";
import { Api, Notification } from "../../../services";
import { connect } from "react-redux";
import Typography from "antd/lib/typography";
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import DatePicker from "antd/lib/date-picker";
import Modal from "antd/lib/modal";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import Collapse from "antd/lib/collapse";
import Input from "antd/lib/input";
import "antd/lib/typography/style/index";
import "antd/lib/upload/style/index";
import "antd/lib/message/style/index";
import "antd/lib/date-picker/style/index";
import "antd/lib/modal/style/index";
import "antd/lib/select/style/index";
import "antd/lib/button/style/index";
import "antd/lib/collapse/style/index";
import "antd/lib/input/style/index";
import { Color } from "../../../services";
import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  studentModalVisible,
  saveUser,
  updateUserInput,
  editUsers,
  fetchRollNo,
  togglePermanentAddressCheckbox,
} from "../../../actions/user";
import "./styles.css";
import moment from "moment";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Avatar from "antd/lib/avatar/avatar";
import { Divider, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
}

export function IsJsonString(str) {
  if (
    /^[\],:{}\s]*$/.test(
      str
        .replace(/\\["\\\/bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  } else {
    return false;
  }
}

class StudentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      imgLoading: false,
      role: "Student",
      permission: "None",
      imageAdd: "",
      activeKey: null,
    };
  }

  componentDidMount() {
    const { StudentSingle } = this.props;

    const currentAddressRaw =
      StudentSingle.currentAddressRaw && StudentSingle.currentAddressRaw != ""
        ? StudentSingle.currentAddressRaw
        : StudentSingle.currentAddress &&
          IsJsonString(StudentSingle.currentAddress)
        ? JSON.parse(StudentSingle.currentAddress)
        : StudentSingle.currentAddress;
    const permanentAddressRaw =
      StudentSingle.permanentAddressRaw &&
      StudentSingle.permanentAddressRaw != ""
        ? StudentSingle.permanentAddressRaw
        : StudentSingle.permanentAddress &&
          IsJsonString(StudentSingle.permanentAddress)
        ? JSON.parse(StudentSingle.permanentAddress)
        : StudentSingle.permanentAddress;
    // const permanentAddressRaw = JSON.parse(StudentSingle.permanentAddress);

    this.handleUserInput(currentAddressRaw, "currentAddressRaw");
    this.handleUserInput(permanentAddressRaw, "permanentAddressRaw");
  }

  componentWillUnmount() {
    this.props.togglePermanentAddressCheckbox(false);
  }

  updatePermanentAddress(bool) {
    this.props.togglePermanentAddressCheckbox(bool);
    if (bool) {
      this.handleUserInput(
        { ...this.props.StudentSingle.currentAddressRaw },
        "permanentAddressRaw"
      );
    } else {
      this.handleUserInput(
        { locality: null, city: null, state: null, pincode: null },
        "permanentAddressRaw"
      );
    }
  }

  uploadImage = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ imgLoading: true });
      return;
    }
    if (info.file) {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          imgLoading: false,
          imageAdd: info.file.originFileObj,
        })
      );
    }
  };
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  pageStudentAdd() {
    const { StudentSingle, positionCurrent } = this.props;
    if (!StudentSingle.email || StudentSingle.email === "") {
      delete StudentSingle.email;
    }

    console.log({ StudentSingle });
    if (
      !StudentSingle.currentAddressRaw ||
      // !StudentSingle.currentAddressRaw.locality || StudentSingle.currentAddressRaw.locality === "" ||
      !StudentSingle.currentAddressRaw.city ||
      StudentSingle.currentAddressRaw.city === "" ||
      !StudentSingle.currentAddressRaw.state ||
      StudentSingle.currentAddressRaw.state === "" ||
      // !StudentSingle.currentAddressRaw.pincode || StudentSingle.currentAddressRaw.pincode === "" ||
      !StudentSingle.name ||
      StudentSingle.name === "" ||
      // !StudentSingle.gender || StudentSingle.gender === "" ||
      // !StudentSingle.dob || StudentSingle.dob === "" ||
      !StudentSingle.phone ||
      StudentSingle.phone === ""
      // !StudentSingle.emergencyContactName || StudentSingle.emergencyContactName === "" ||
      // !StudentSingle.emergencyPhone || StudentSingle.emergencyPhone === "" ||
      // !StudentSingle.motherName || StudentSingle.motherName === ""
    ) {
      return Notification.error(
        "error",
        "All mandatory fields must be filled!"
      );
    }

    StudentSingle.permanentAddress = StudentSingle.permanentAddressRaw
      ? JSON.stringify(StudentSingle.permanentAddressRaw)
      : "";
    StudentSingle.currentAddress = StudentSingle.currentAddressRaw
      ? JSON.stringify(StudentSingle.currentAddressRaw)
      : "";

    Object.assign(StudentSingle, { role: "Student" });
    let newUser = {
      file: this.state.imageAdd,
      data: StudentSingle,
    };
    this.props.saveUser(newUser, positionCurrent);
    this.setState({
      imageAdd: "",
      imageUrl: "",
    });
    const stdId = StudentSingle.standardId || "-1";
    this.props.studentAdd(stdId);
  }

  pageStudentEdit(StudentSingle) {
    const stdId = StudentSingle.standardId || "-1";
    if (
      !StudentSingle.currentAddressRaw ||
      // !StudentSingle.currentAddressRaw.locality || StudentSingle.currentAddressRaw.locality === "" ||
      !StudentSingle.currentAddressRaw.city ||
      StudentSingle.currentAddressRaw.city === "" ||
      !StudentSingle.currentAddressRaw.state ||
      StudentSingle.currentAddressRaw.state === "" ||
      // !StudentSingle.currentAddressRaw.pincode || StudentSingle.currentAddressRaw.pincode === "" ||
      !StudentSingle.name ||
      StudentSingle.name === "" ||
      // !StudentSingle.gender || StudentSingle.gender === "" ||
      // !StudentSingle.dob || StudentSingle.dob === "" ||
      !StudentSingle.phone ||
      StudentSingle.phone === ""
    ) {
      return Notification.error(
        "error",
        "All mandatory fields must be filled!"
      );
    }
    StudentSingle.permanentAddress = StudentSingle.permanentAddressRaw
      ? JSON.stringify(StudentSingle.permanentAddressRaw)
      : "";
    StudentSingle.currentAddress = StudentSingle.currentAddressRaw
      ? JSON.stringify(StudentSingle.currentAddressRaw)
      : "";
    if (!StudentSingle.email) {
      delete StudentSingle.email;
    }
    let oldUser = {
      file: this.state.imageAdd,
      data: StudentSingle,
    };
    oldUser.data.role = "Student";
    this.props.editUsers(oldUser, this.props.filterParams.role, stdId);
    this.setState({
      imageAdd: "",
      imageUrl: "",
    });
    // delete StudentSingle.secondaryPhone;
    // delete StudentSingle.guardianPhone;
    // delete StudentSingle.guardianEmail;
  }

  handleUserInput(val, key) {
    this.props.updateUserInput(val, key);
  }

  handleCancelClick() {
    this.props.studentModalVisible({}, false);
    this.setState({
      imageAdd: "",
      imageUrl: "",
      activeKey: "0",
    });
  }

  handleStandardSelection(value) {
    if (this.props.StudentSingle.standardId !== -1) {
      this.handleUserInput(
        this.props.StudentSingle.standardId,
        "oldStandardId"
      );
    }
    this.handleUserInput(value, "standardId");
    this.props.fetchRollNo(value);
  }

  callback(key) {
    this.setState({
      activeKey: key[0],
    });
  }

  handleDobChange(e) {
    const date = e ? moment(e).format("YYYY-MM-DD") : null;
    this.handleUserInput(date, "dob");
  }

  render() {
    const {
      StudentSingle,
      userAddLoader,
      editable,
      permanentAddressChkboxBool,
    } = this.props;
    console.log("StudentSingle", StudentSingle);
    // const classValue =
    //   typeof StudentSingle.std == "undefined"
    //     ? null
    //     : `${StudentSingle.std}-${StudentSingle.section}`;
    const uploadButton = (
      <div>
        {this.state.imgLoading ? (
          <LoadingOutlined />
        ) : StudentSingle.image ? (
          <Avatar
            src={`${Api._s3_url}${StudentSingle.image}`}
            alt=""
            style={{ width: "100%" }}
          />
        ) : (
          <CameraOutlined style={{ color: Color.primary, fontSize: "2em" }} />
        )}
      </div>
    );
    const { imageUrl } = this.state;

    return StudentSingle ? (
      <div>
        <Modal
          visible={this.props.studentModalBool}
          centered
          width={900}
          style={{ padding: 0 }}
          closable={true}
          maskClosable={false}
          onCancel={() => this.props.studentModalVisible({}, false)}
          footer={[
            <div className="r-c-c">
              {StudentSingle.id ? (
                <Button
                  loading={userAddLoader}
                  className="mod-assign-btn"
                  key="back"
                  onClick={() => this.pageStudentEdit(StudentSingle)}
                >
                  Save Student
                </Button>
              ) : (
                <Button
                  loading={userAddLoader}
                  className="mod-assign-btn"
                  key="back"
                  onClick={() => this.pageStudentAdd()}
                >
                  Add Student
                </Button>
              )}
              <Button
                className="mod-cancel-btn"
                key="submit"
                loading={false}
                onClick={() => this.handleCancelClick()}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <div>
            <div className="r-c-sb">
              <Title style={{ color: Color.primary, margin: 0 }} level={4}>
                {StudentSingle.id ? "Edit" : "Add"} Student
              </Title>
              <div className="r-ac">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="administratorUploader cursor-pointer"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={this.beforeUpload}
                  onChange={this.uploadImage}
                >
                  {imageUrl ? (
                    <Avatar
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <div
                  className="bold-600 text-sm m-l-10"
                  style={{ color: Color.darkGrey }}
                >
                  {StudentSingle.image && StudentSingle.image !== "default.png"
                    ? "Change Image"
                    : "Upload Image"}
                  <span style={{ color: Color.red }}>
                    {/* {imageUrl ? "" : "*"} */}
                  </span>
                </div>
              </div>
            </div>
            {StudentSingle.standardId === -1 &&
            StudentSingle.preferredClass &&
            StudentSingle.preferredClass != -1 ? (
              <div className="r-ac m-b-20 m-t-20">
                Student has applied for{" "}
                <span className="bold-600 m-l-5 text-sm">
                  {StudentSingle.preferredClass}
                </span>
              </div>
            ) : null}
            <div className="r-ac m-b-20 m-t-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  Name <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "name")
                    }
                    value={StudentSingle.name}
                    placeholder="Enter name"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="r-ac flex-1">
                <div className="m-r-20">
                  <div className="dark-grey">Gender</div>
                  <div
                    className="r-c-sb"
                    style={{
                      backgroundColor: "#F9F9F9",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <Select
                      onChange={(e) => this.handleUserInput(e, "gender")}
                      className="borderLessSelect bold-600"
                      value={StudentSingle.gender}
                      placeholder="Select gender"
                      style={{
                        color: Color.primary,
                        width: "100px",
                        fontSize: 16,
                      }}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </div>
                </div>
                <div className="m-r-20">
                  <div className="dark-grey">Blood Group</div>
                  <div
                    className="r-c-sb"
                    style={{
                      backgroundColor: "#F9F9F9",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    {/* <Input onChange={(e) => this.handleUserInput(e.target.value, 'bloodGroup')} value={StudentSingle.bloodGroup} placeholder="B +ve"  style={{border:'none', width:'100px',  color:Color.primary, fontSize:16, fontWeight:600}}/> */}
                    <Select
                      onChange={(e) => this.handleUserInput(e, "bloodGroup")}
                      className="borderLessSelect bold-600"
                      value={StudentSingle.bloodGroup}
                      placeholder="Select blood group"
                      style={{
                        color: Color.primary,
                        width: "100px",
                        fontSize: 16,
                      }}
                    >
                      <Option value="NA">NA</Option>
                      <Option value="A-">A-</Option>
                      <Option value="A+">A+</Option>
                      <Option value="B-">B-</Option>
                      <Option value="B+">B+</Option>
                      <Option value="O-">O-</Option>
                      <Option value="O+">O+</Option>
                      <Option value="AB+">AB+</Option>
                      <Option value="AB-">AB-</Option>
                    </Select>
                  </div>
                </div>
                <div className="">
                  <div className="dark-grey">Date Of Birth</div>
                  <div
                    className="r-c-sb"
                    style={{
                      backgroundColor: "#F9F9F9",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <DatePicker
                      onChange={(e) => this.handleDobChange(e)}
                      format="DD-MM-YYYY"
                      disabledDate={disabledDate}
                      value={
                        StudentSingle.dob
                          ? StudentSingle.dob == "0000-00-00"
                            ? null
                            : moment(StudentSingle.dob, "YYYY-MM-DD")
                          : ""
                      }
                      style={{
                        border: "none",
                        width: "150px",
                        color: Color.primary,
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Email ID</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "email")
                    }
                    value={StudentSingle.email}
                    placeholder="Enter email"
                    style={{
                      border: "none",
                      width: "100%",
                      color: !StudentSingle.id ? Color.primary : Color.aeGrey,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                    disabled={StudentSingle.id}
                  />
                </div>
                <div style={{ color: "red", fontSize: 10, padding: 2 }}>
                  Email ID is not editable
                </div>
              </div>
              <div className="flex-1">
                <div className="dark-grey">
                  Phone Number <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    disabled={StudentSingle.id}
                    value={StudentSingle.phone}
                    onCountryChange={(e) => console.log("code", e)}
                    onChange={(e) => this.handleUserInput(e, "phone")}
                    maxLength={(StudentSingle.phone?.startsWith('+91') || StudentSingle.phone?.startsWith('91')) && StudentSingle.phone?.length == 13 ? 11 : ""}
                  />
                  {/*<Input onChange={(e) => this.handleUserInput(e.target.value, 'phone')} value={StudentSingle.phone} placeholder="Enter Phone No"  style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}/>*/}
                </div>
                <div style={{ color: "red", fontSize: 10, padding: 2 }}>
                  Phone no is not editable
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Father's Name</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "fatherName")
                    }
                    value={StudentSingle.fatherName}
                    placeholder="enter father name"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="dark-grey">Father's Occupation </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "fatherOccupation")
                    }
                    value={StudentSingle.fatherOccupation}
                    placeholder="Enter father occupation"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Mother's Name</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "motherName")
                    }
                    value={StudentSingle.motherName}
                    placeholder="enter mother name"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="dark-grey">Mother's Occupation </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "motherOccupation")
                    }
                    value={StudentSingle.motherOccupation}
                    placeholder="Enter mother occupation"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Guardian's Email </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "guardianEmail")
                    }
                    value={StudentSingle.guardianEmail}
                    placeholder="enter guardian email"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Guardian's Phone </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={StudentSingle.guardianPhone}
                    onCountryChange={(e) => console.log("code", e)}
                    onChange={(e) => this.handleUserInput(e, "guardianPhone")}
                    maxLength={(StudentSingle.guardianPhone?.startsWith('+91') || StudentSingle.guardianPhone?.startsWith('91')) && StudentSingle.guardianPhone?.length == 13 ? 11 : ""}
                  />
                  {/*<Input onChange={(e) => this.handleUserInput(e.target.value, 'guardianPhone')} value={StudentSingle.guardianPhone} placeholder="enter guardian's phone number"  style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}/>*/}
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Family Income</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(e.target.value, "annualIncome")
                    }
                    type="number"
                    value={StudentSingle.annualIncome}
                    placeholder="enter family income"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="dark-grey" style={{ fontSize: 16 }}>
              Current Address <span style={{ color: Color.red }}>*</span>
            </div>
            <Divider />
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">House No./ Locality/ Area</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.currentAddressRaw,
                          locality: e.target.value,
                        },
                        "currentAddressRaw"
                      )
                    }
                    value={StudentSingle?.currentAddressRaw?.locality}
                    placeholder="Enter House No./ Locality/ Area"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  City <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.currentAddressRaw,
                          city: e.target.value,
                        },
                        "currentAddressRaw"
                      )
                    }
                    value={StudentSingle?.currentAddressRaw?.city}
                    placeholder="Enter City"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">
                  State <span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.currentAddressRaw,
                          state: e.target.value,
                        },
                        "currentAddressRaw"
                      )
                    }
                    value={StudentSingle?.currentAddressRaw?.state}
                    placeholder="Enter State"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">PIN/ ZIP Code</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="number"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.currentAddressRaw,
                          pincode: e.target.value,
                        },
                        "currentAddressRaw"
                      )
                    }
                    value={StudentSingle?.currentAddressRaw?.pincode}
                    placeholder="Enter Pincode"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="dark-grey" style={{ fontSize: 16 }}>
              Permanent Address
            </div>
            <Divider />
            <Row className="m-t-20 m-b-20">
              <span className="m-r-5">
                <Checkbox
                  checked={permanentAddressChkboxBool}
                  onClick={(e) => this.updatePermanentAddress(e.target.checked)}
                />
              </span>{" "}
              Same as Current Address
            </Row>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">House No./ Locality/ Area</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.permanentAddressRaw,
                          locality: e.target.value,
                        },
                        "permanentAddressRaw"
                      )
                    }
                    value={StudentSingle?.permanentAddressRaw?.locality}
                    placeholder="Enter House No./ Locality/ Area"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">City</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.permanentAddressRaw,
                          city: e.target.value,
                        },
                        "permanentAddressRaw"
                      )
                    }
                    value={StudentSingle?.permanentAddressRaw?.city}
                    placeholder="Enter City"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">State</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.permanentAddressRaw,
                          state: e.target.value,
                        },
                        "permanentAddressRaw"
                      )
                    }
                    value={StudentSingle?.permanentAddressRaw?.state}
                    placeholder="Enter State"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="m-r-20 flex-1">
                <div className="dark-grey">PIN/ ZIP Code</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    type="number"
                    onChange={(e) =>
                      this.handleUserInput(
                        {
                          ...StudentSingle.permanentAddressRaw,
                          pincode: e.target.value,
                        },
                        "permanentAddressRaw"
                      )
                    }
                    value={StudentSingle?.permanentAddressRaw?.pincode}
                    placeholder="Enter Pincode"
                    rows={3}
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="m-b-10 bold-600" style={{ color: Color.primary }}>
              Emergency Contact
            </div>
            <div className="r-ac m-b-20">
              <div className="m-r-20 flex-1">
                <div className="dark-grey">Name</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Input
                    onChange={(e) =>
                      this.handleUserInput(
                        e.target.value,
                        "emergencyContactName"
                      )
                    }
                    value={StudentSingle.emergencyContactName}
                    placeholder="Enter name"
                    style={{
                      border: "none",
                      width: "100%",
                      color: Color.primary,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="dark-grey">Emergency Phone Number</div>
                <div
                  className="r-c-sb"
                  style={{
                    backgroundColor: "#F9F9F9",
                    width: "235px",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <PhoneInput
                    style={{ border: "none", width: "100%" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={StudentSingle.emergencyPhone}
                    onCountryChange={(e) => console.log("code", e)}
                    onChange={(e) => this.handleUserInput(e, "emergencyPhone")}
                    maxLength={(StudentSingle.emergencyPhone?.startsWith('+91') || StudentSingle.emergencyPhone?.startsWith('91')) && StudentSingle.emergencyPhone?.length == 13 ? 11 : ""}
                  />
                  {/*<Input onChange={(e) => this.handleUserInput(e.target.value, 'emergencyPhone')} value={StudentSingle.emergencyPhone} placeholder="Enter Phone"  style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}/>*/}
                </div>
              </div>
            </div>
            <Collapse
              activeKey={this.state.activeKey}
              onChange={(key) => this.callback(key)}
              bordered={false}
              style={{ backgroundColor: Color.primaryBkg }}
            >
              <Panel header="Assign a Standard" key="0">
                <div className="r-ac">
                  <div className="flex-1">
                    <div
                      className="bold-600 m-r-10"
                      style={{ color: Color.darkGrey }}
                    >
                      Assign To Class{" "}
                    </div>
                    <div
                      className="r-c-sb"
                      style={{
                        backgroundColor: "#F9F9F9",
                        width: "235px",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      <Select
                        value={StudentSingle.standardId}
                        onChange={(value) =>
                          this.handleStandardSelection(value)
                        }
                        className="borderLessSelect bold-600"
                        style={{
                          color: Color.primary,
                          width: "235px",
                          fontSize: 16,
                        }}
                        placeholder="Select a class"
                      >
                        <Option key={-1} value={-1} disabled>
                          Select Standard
                        </Option>
                        {this.props.studentStandards &&
                          this.props.studentStandards.map((data, key) => (
                            <Option key={key} value={data.id}>
                              {data.std.toString() +
                                " - " +
                                data.section.toString()}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="dark-grey">Roll Number</div>
                    <div
                      className="r-c-sb"
                      style={{
                        backgroundColor: "#F9F9F9",
                        width: "235px",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      <Input
                        onChange={(e) =>
                          this.handleUserInput(e.target.value, "rollNo")
                        }
                        value={StudentSingle.rollNo || "NA"}
                        placeholder="Roll no"
                        style={{
                          border: "none",
                          width: "100%",
                          color: Color.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Modal>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const {
    studentModalBool,
    StudentSingle,
    userAddLoader,
    studentStandards,
    positionCurrent,
    filterParams,
    permanentAddressChkboxBool,
  } = state.user;
  const { orgId } = state.session.user;
  return {
    studentModalBool,
    StudentSingle,
    userAddLoader,
    studentStandards,
    positionCurrent,
    orgId,
    filterParams,
    permanentAddressChkboxBool,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserInput: (value, key) => dispatch(updateUserInput(value, key)),
  saveUser: (value, position) => dispatch(saveUser(value, position)),
  editUsers: (value, position, selectedStandard) =>
    dispatch(editUsers(value, position, selectedStandard)),
  studentModalVisible: (data, value) =>
    dispatch(studentModalVisible(data, value)),
  fetchRollNo: (value) => dispatch(fetchRollNo(value)),
  togglePermanentAddressCheckbox: (bool) =>
    dispatch(togglePermanentAddressCheckbox(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentModal);
