const Styles = {
  announcemntCardContainer: {
    margin: 10,
    border: 0,
    borderRadius: 20,
    height:'200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

};

export default Styles;
