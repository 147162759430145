import React from 'react'
import {Modal, Button, Input} from 'antd';
import { Api } from 'services';

function ImgViewModal({
    closeModal, visible,
    fileName
}) {
    return (
        <Modal
            className="modal-padding-0"
            style={{maxWidth: '80%', width: 'fit-content'}}
            visible={visible}
            footer={false}
            onCancel={() => closeModal()}
        >
            <img src={`${Api._s3_url}${fileName}`}
                width={"100%"}
            />
        </Modal>
    )
}

export default ImgViewModal;
