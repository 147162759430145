import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import Popover from 'antd/lib/popover';
import Tag from 'antd/lib/tag';
import PageHeader from 'antd/lib/page-header';
import Modal from 'antd/lib/modal';
import Pagination from 'antd/lib/pagination';
import Empty from 'antd/lib/empty';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/popover/style/index';
import 'antd/lib/tag/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { FileTextOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined,
ExclamationCircleOutlined, CloseCircleOutlined, LoadingOutlined, EyeOutlined} from '@ant-design/icons';
import { GoPencil } from 'react-icons/go';
import { FiChevronDown } from "react-icons/fi";
import { FaRegCommentAlt } from "react-icons/fa";
import { MdPublish, MdDelete } from "react-icons/md";
import AssignmentUpdateModal from './modals/updateAssignment';
import { setCurrentQuestion, deleteAssignmentTask, toggleAssignmentModal, getAssignmentById, toggleQuestionModal, toggleInstructionModal,
  handleAssignmentInput, updateAssignmentStatus, unmarkForReview, getAssignmentTasks, toggleViewQuestionModal, notifyTeacherAssignment } from '../../actions/assignment';
import { fetchClasslist } from '../../actions/calendar';
import './styles.css';
import { Color } from '../../services';
import QuestionModal from './modals/questionModal'
import ViewQuestionModal from './modals/viewQuestionModal'
import InstructionModal from './modals/instructionModal'
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import { Col, Row } from 'antd';


const { confirm } = Modal;
const pageSize = 20;


class LoggerView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  async componentDidMount(){
    const {currentAssignment, assignmentPageTaskDetails, getAssignmentById} = this.props;
    const page = assignmentPageTaskDetails &&  assignmentPageTaskDetails.currentPage  ? assignmentPageTaskDetails.currentPage :  1;
    await getAssignmentById(currentAssignment.id, page, 'assignment-teacher');
  }

  showDeleteTaskConfirm(id, marks, self) {
    return confirm({
      title: `Do you want to delete this question?`, //${text}
      icon: <CloseCircleOutlined style={{color: Color.red}}/>,
      content: 'Please click OK to confirm',
      onOk() {self.props.deleteAssignmentTask(id, marks);},
      onCancel() {},
    })
  }

  handleEditQuestionModal = (data) => {
    const { setCurrentQuestion, toggleQuestionModal } =  this.props;
    setCurrentQuestion(data);
    toggleQuestionModal(true);
  }

  async openEditModal(){
    const {currentAssignment, handleAssignmentInput, fetchClasslist, toggleAssignmentModal} = this.props;
    await fetchClasslist(currentAssignment.subjectId);
    handleAssignmentInput(currentAssignment.standardIds.map(id => parseInt(id)),'standardIds');
    handleAssignmentInput(currentAssignment.id,'id');
    handleAssignmentInput(currentAssignment.title,'title');
    handleAssignmentInput(currentAssignment.sub,'selectedSubject');
    handleAssignmentInput(currentAssignment.subjectId,'subjectId');
    handleAssignmentInput(currentAssignment.author.name,'selectedTeacher');
    handleAssignmentInput(currentAssignment.teacherId,'teacherId');
    handleAssignmentInput(currentAssignment.gradingType,'gradingType');
    handleAssignmentInput(currentAssignment.deadlineDate,'deadlineDate');
    handleAssignmentInput(currentAssignment.resultDueDate,'resultDueDate');
    handleAssignmentInput(currentAssignment.instructions,'instructions');
    handleAssignmentInput(currentAssignment.chapterNames,'chapterNames');
    handleAssignmentInput(currentAssignment.chapterIds,'chapterIds');
    handleAssignmentInput(currentAssignment.index,'index');

    toggleAssignmentModal(true)
  }


  componentWillUnmount(){
    this.props.handleAssignmentInput([],'standardIds');
    this.props.handleAssignmentInput(null,'id');
    this.props.handleAssignmentInput('','title');
    this.props.handleAssignmentInput('','selectedSubject');
    this.props.handleAssignmentInput(null,'subjectId');
    this.props.handleAssignmentInput('','selectedTeacher');
    this.props.handleAssignmentInput(null,'teacherId');
    this.props.handleAssignmentInput('Ungraded','gradingType');
    this.props.handleAssignmentInput(null,'deadlineDate');
    this.props.handleAssignmentInput(null,'resultDueDate');
    this.props.handleAssignmentInput('','instructions');
    this.props.handleAssignmentInput([],'chapterNames');
    this.props.handleAssignmentInput([],'chapterIds');
    this.props.handleAssignmentInput(null,'index');
  }


  showConfirm(action, self) {
    const icon = action === "Delete" ? <CloseCircleOutlined style={{color: Color.red}}/> : <ExclamationCircleOutlined />
    return confirm({
      title: `Do you want to ${action} this assignment?`, //${text}
      icon: icon,
      content: 'Please click OK to confirm',
      onOk() {self.handleConfirmClick(action)},
      onCancel() {},
    })
  }

  handleConfirmClick(action){
    action = action === "Delete" ? "Deleted" : action === "Publish" ? "Published" : null
    const params =  this.props.currentAssignment;
    this.props.updateAssignmentStatus(params, action, this.props.history)
  }

  handlePageChange(page) {
    const { getAssignmentTasks } = this.props;
    getAssignmentTasks(this.props.currentAssignment.id, page)
  }

  publishDDl = () => {
    const { access } = this.props.user;
    const { currentAssignment } =  this.props;
    const statusList = access.includes('assignment-teacher') && currentAssignment.status !== "Published"? [
      {
        text: 'Publish',
        icon: <MdPublish />
      },
      {
        text: 'Delete',
        icon: <MdDelete />
      }
    ] : [{
      text: 'Delete',
      icon: <MdDelete />
    }
    ]
    return (
      <Menu className='assignmentddlmenu'>
        {statusList.map((data, index) =>
          <Menu.Item key={index}>
            <div
              onClick={() =>
                this.showConfirm(data.text, this)
              }
              className='color-purple p-l-10 p-r-10'
            >
            <Menu.Divider/>
              {data.icon} {data.text}
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  taskChange = (data, history) => (
    <Menu className='assignmentddlmenu'>
      <Menu.Item key="4">
        <div onClick={(e) => {e.stopPropagation(); this.handleViewQuestionModal(data)}}>
          <EyeOutlined /> View
        </div>
      </Menu.Item>
      {this.props.currentAssignment.status !=="Published" &&
      <>
        <Menu.Item key="0">
          <div onClick={() => this.handleEditQuestionModal(data)}>
            <EditOutlined /> Edit
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div onClick={() => {
              this.showDeleteTaskConfirm(data.id, data.positiveMarks, this);
            }}>
            <DeleteOutlined /> Delete
          </div>
        </Menu.Item>
      </>}
    </Menu>
  );

  handleViewQuestionModal = (data) => {
    const { setCurrentQuestion, toggleViewQuestionModal } =  this.props;
    setCurrentQuestion(data);
    toggleViewQuestionModal(true);
  }

  assignmentDataBox = () => {
    const { assignmentPageTaskDetails, currentAssignmentTasks, currentAssignment,  statusLoader, notifyTeacherAssignment, assignmentTeacherReminderLoader
       } = this.props
    return (
      <div className='assignmentShadowBox m-b-10 r-jsb'>
        <div className='text-xmd bold firstCapital dark-grey'>
          Title: <br/>
          Subject: <br/>
          {/*Chapters: <br/>*/}
        </div>
        <div className='m-l-10 text-xmd color-purple flex-1'>
          <span className='r-c-fs'>
          {currentAssignment.title}
          <Tag className='m-l-5' color={currentAssignment.status === 'Draft' ? 'red' : currentAssignment.status === 'Published' ? 'green' : 'transparent'}>
            {currentAssignment.status}
          </Tag>
            <InfoCircleOutlined
              className='cursor-pointer'
              onClick={() => this.setState({ToggleInstructionModal: true})}
            />
          </span>
          {currentAssignment.sub} | {currentAssignment.author ? currentAssignment.author.name : null}<br/>
          {currentAssignment.chapter ? currentAssignment.chapter : null}
        </div>
        <div>
          <div className='color-purple r-c-fe' style={{marginTop: -10}}>
            Total Questions: {assignmentPageTaskDetails && assignmentPageTaskDetails.totalItems ? assignmentPageTaskDetails.totalItems : 0 }
            {currentAssignment.gradingType === "Graded" ? <> <span className='med-grey text-md bold-600'>|</span>
            Total Marks: {currentAssignment && currentAssignment.totalPoints ? currentAssignment.totalPoints : 0 }</> : null}
          </div>
          <div className='r-c-fe'>
            <div className='m-r-10'>
               <Button type="primary" className='radius-10 m-r-10' loading={assignmentTeacherReminderLoader} onClick={() => notifyTeacherAssignment()} disabled={!currentAssignmentTasks.length}>
                 Notify Teacher
               </Button>
              </div>
            {currentAssignment.markedForReview ? <Button
              className='radius-10 m-r-10'
              loading={false}
              disabled={currentAssignment.status === "Published"}
              onClick={() => this.props.unmarkForReview(currentAssignment.id)}
            >
              Close Issues
            </Button> :  null}
            {currentAssignmentTasks.length ?
              <div className='m-r-10'>
                {this.addQuestionBtn()}
              </div>
              : null
            }
            <Button
              className='radius-10 m-r-10'
              type="primary"
              loading={false}
              disabled={currentAssignment.status === "Published"}
              onClick={() => this.openEditModal()}
            >
              <GoPencil style={{marginRight: 10}}/>Edit
            </Button>
            <Button
              type="primary"
              style={{borderRadius: 5}}
              loading={false}
              disabled={currentAssignment.status === "Published"}
              // onClick={() => console.log('disc')}
            >
              { statusLoader ? <LoadingOutlined /> :
                <Dropdown
                  trigger={['click']}
                  overlay={this.publishDDl}>
                  <FiChevronDown style={{color: 'white'}} />
                </Dropdown>
              }
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderAssignmentTasks(data, index){
    const { assignmentPageTaskDetails, currentAssignment} = this.props;
    return (
      <div className='assignmentItem m-b-10 r-c-sb'
        style={{
          padding: 0,
          backgroundColor: '#FFF'
        }}
      >
        <div
          style={{
            borderRight: '1px solid #D9D9D9',
            padding: '10px 20px',
          }}
          className='text-sm m-r-50 text-center bold'
        >
          <Avatar style={{backgroundColor: 'transparent', color: Color.black, border: data.markedForReview ? '2px solid  red' : false}}>
          {(parseInt(assignmentPageTaskDetails.totalItems) - (parseInt(assignmentPageTaskDetails.currentPage) - 1) * pageSize - (index))} </Avatar>
        </div>
        <div style={{flex: 6, width: "95%", overflowY: 'hidden', maxHeight: '400px'}}>
            <Row className='text-sm bold r-c-sb'>
              <Col span={22}>
                {ReactHtmlParser(data.statement, { transform: (node) => {
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return <img style={{maxHeight: '40vh', maxWidth: '60vh'}} src={node.attribs.src} alt="" />;
                  }
                }})}
              </Col>
              {/*<Truncate
                lines={1}
                ellipsis={
                  <span> ...
                    <Popover
                      placement="right"
                      content={
                        <CustomCKEditor disable={false} ckdata={data.statement} onChange={(val) => console.log(val)}/>
                      }
                      trigger="click">
                      <a style={{fontSize: 12}}> Read More</a>
                    </Popover>
                  </span>
                }>
                  <CustomCKEditor width={100} disable={false} ckdata={data.statement} onChange={(val) => console.log(val)}/>
              </Truncate>*/}
                {data.remark ?
              <Col span={1} offset={1}>
              <Popover
                placement="right"
                title="Comments"
                content={
                  <div id="comment" style={{maxHeight: '15vh', overflowY: 'auto'}}>
                    {data.remark.map((item, i) =>
                    <div style={{backgroundColor: Color.midGrey, borderRadius: 5, margin: 3, padding: 4}} key={i.toString()}>
                      <div>{item.text}</div>
                      <div style={{fontSize: 8}}>{item.time}</div>
                    </div>)}
                  </div>
                }
                trigger="click">
                <FaRegCommentAlt className="cursor-pointer"/>
              </Popover>
              </Col>
              :null}

          </Row>
        </div>
        <div style={{flex:1, textAlign: 'center'}}>
        {data.type === "MCQ" ?
          <div className='text-sm bold'>
          <Popover
            placement="right"
            style={{}}
            content={<div style={{maxHeight: '40vh', overflowY: 'auto'}}>
              {data && data.options && data.options !== 'string' ? data.options.map(item =>
                <div key={item.id ? item.id.toString() : ''}>
                  <div className='display-flex'>
                    <div>{String.fromCharCode(65 + item.id)}.</div>
                    <div style={{marginLeft: 5}}>
                      {ReactHtmlParser(item.value, { transform: (node) => {
                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                          return <TeX math={`${node.attribs['data-value']}`} />;
                        }
                        if (node.type === 'tag' && node.name === 'img') {
                          return <img style={{maxHeight: '40vh', maxWidth: '60vh'}} src={node.attribs.src} alt="" />;
                        }
                      }})}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>}
            title="Options"
            trigger="click"
          >
            <FileTextOutlined className="cursor-pointer"/>
          </Popover>
          </div>
          :  'NA'}
        </div>
        {currentAssignment.gradingType === "Graded" ?
        <div style={{flex:1, textAlign: 'center'}}>
          <div className='text-sm bold'>
            {data.positiveMarks}
          </div>
        </div> : null}
        <div style={{flex:1, textAlign: 'center'}}>
          <Dropdown
            trigger={['click', 'hover']}
            overlay={() => this.taskChange(data, this.props.history)}
          >
            <EllipsisOutlined
              className='m-l-10 text-sm color-purple cursor-pointer'
          />
          </Dropdown>
        </div>

      </div>
    )
  }

  addQuestionBtn = () => (
    <Button className='radius-10' onClick={() => this.props.toggleQuestionModal(true)} type="primary" disabled={this.props.currentAssignment.status === "Published"}>
      + Add Question
    </Button>
  )

  noQuestions = () => (
    <Empty
      description={
        <span style={{
            backgroundColor: '#EEEEEE',
            borderRadius: 10,
            padding: 10,
          }} className='bold dark-grey text-xmd'>
          No Questions Found!
        </span> }>
      {this.addQuestionBtn()}
    </Empty>
  )

  render() {
    const { history, currentAssignmentTasks, assignmentTaskLoader, assignmentPageTaskDetails, currentAssignment } =  this.props
    const { ToggleQuestionModal, ToggleInstructionModal } = this.state;

    return (
      <div style={{height: '100%'}}>
        <PageHeader
          className="site-page-header"
          onBack={() => this.props.history.goBack()}
          title='Assignment Details'
          />

        {this.assignmentDataBox()}
        <div style={{
            marginTop: 15,
            padding: 10,
            height: '77%',
            overflowY: 'auto',
            backgroundColor: Color.lightGrey,
            borderRadius: 10
          }}
        >
          {assignmentTaskLoader ?
            <div className='r-c-c-c' style={{marginTop: '30%'}}>
              <LoadingOutlined style={{color: '#5843BE', fontSize: 100}} />
            </div>
            :
            <div style={{height: '100%'}}>
              {
                currentAssignmentTasks && currentAssignmentTasks.length ?
                <div>
                  <div className='r-jsb bold text-center'>
                    <div className='m-l-10'>
                      S.No.
                    </div>
                    <div style={{flex: 6}}>
                      Statement
                    </div>
                    <div style={{flex: 1}}>
                      Options
                    </div>
                    {currentAssignment.gradingType === "Graded" ?
                     <div style={{flex: 1}}>
                      Marks
                    </div> : null}
                    <div style={{flex: 1}}>
                      Actions
                    </div>
                  </div>
                  {currentAssignmentTasks.map((item, index) =>
                    this.renderAssignmentTasks(item, index)
                  )}
                </div>
                :
                <div style={{height: '100%'}} className='r-c-c-c'>{this.noQuestions()}</div>
                }
            </div>
          }
        </div>
        <div style={{height: '10%', textAlign: 'right'}}>
          <Pagination
            size="small"
            current={assignmentPageTaskDetails ? parseInt(assignmentPageTaskDetails.currentPage) || 1 :  1}
            onChange={(page) => this.handlePageChange(page)}
            pageSize={20}
            total={assignmentPageTaskDetails ? assignmentPageTaskDetails.totalItems: 0}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            showSizeChanger={false}
          />
        </div>
        <QuestionModal visible={ToggleQuestionModal} id={1} />
        <ViewQuestionModal />
        <InstructionModal
        modalVisible={ToggleInstructionModal}
        closeModal={() => this.setState({ToggleInstructionModal: false})}
        instructions={currentAssignment.instructions}
        />
        <AssignmentUpdateModal history={history} />
      </div>
    )
  }

}

const mapStateToProps = state => {
  const { assignmentList, currentAssignment, assignmentLoader, currentAssignmentTasks, assignmentTaskLoader, pageMeta, filterParams, statusLoader,
    assignmentPageTaskDetails, assignmentTeacherReminderLoader} = state.assignment;
  const {
    classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses, teachers,
    subClasses, subClassesLoader
  } = state.calendar;
  const { searchLoader, subjectsFound } = state.classRoom;
  const { user } = state.session;

  return { classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses,
    user, teachers, subClasses, subClassesLoader, searchLoader, subjectsFound, assignmentList, currentAssignment, assignmentLoader,
    assignmentTaskLoader, currentAssignmentTasks, pageMeta, filterParams, statusLoader, assignmentPageTaskDetails, assignmentTeacherReminderLoader
  };
};

const mapDispatchToProps = dispatch => ({
  toggleAssignmentModal: (state) => dispatch(toggleAssignmentModal(state)),
  toggleQuestionModal: (state) => dispatch(toggleQuestionModal(state)),
  setCurrentQuestion: (data) => dispatch(setCurrentQuestion(data)),
  toggleInstructionModal: (state) => dispatch(toggleInstructionModal(state)),
  getAssignmentById: (id, page) => dispatch(getAssignmentById(id, page)),
  deleteAssignmentTask: (id, marks) => dispatch(deleteAssignmentTask(id, marks)),
  handleAssignmentInput: (val, key) => dispatch(handleAssignmentInput(val, key)),
  fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
  updateAssignmentStatus: (params, status, history) => dispatch(updateAssignmentStatus(params, status, history)),
  unmarkForReview: (id) => dispatch(unmarkForReview(id)),
  getAssignmentTasks: (id, page) => dispatch(getAssignmentTasks(id, page)),
  toggleViewQuestionModal: (state) => dispatch(toggleViewQuestionModal(state)),
  notifyTeacherAssignment: () => dispatch(notifyTeacherAssignment())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggerView);
