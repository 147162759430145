import { Pie } from '@ant-design/charts'
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal'
import React from 'react'

const SubmitModal = ({ presentStudents, studentAttendanceData, visible, okHandler, cancelHandler,initialSummary ,presentStudentsArray,absentStudentsArray}) => {

  // const all0 = pieData?.chapterTestCount===0&&pieData?.partTestCount==0&&pieData?.fullTestCount===0

  {
    const data = [
      {
        type: 'Present',
        value: presentStudents?.length ? presentStudents?.length : 0,
      },
      {
        type: 'Absent',
        value: studentAttendanceData?.length ? (studentAttendanceData?.length - presentStudents?.length) : 0,
      }
    ];

    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      color: ['#28DF99', '#FF414D'],
      radius: 0.8,
      autoFit: true,
      legend: false,
      innerRadius: 0.68,
      label: {
        type: 'inner',
        offset: '-50%',
        content: "",
        style: {
          fill: '#fff',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 8
          },
          customHtml: function customHtml() {
            return (
              <div>
                <div style={{ fontSize: "20px", fontWeight: "700" }}>{studentAttendanceData ? studentAttendanceData.length : 0}</div>
                <div style={{ marginTop: "5px", fontSize: "10px", fontWeight: "100", color: "grey" }}>Total Students</div>
              </div>)
          }
        },
      },
      annotations: [
      ],
    };
console.log("AA-studentAttendanceData",studentAttendanceData);
    const tags = ["Present", "Absent"]
    const tagcolor = ['#28DF99', '#FF414D']



    return (
      <Modal className='modal-round-corner' visible={visible} onCancel={cancelHandler} footer={null} centered>
        <div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "25px",
          }}>
            <div style={{ color: '#191919', fontSize: '20px', fontWeight: 'bold' }}>Confirm to Submit</div>
            <div style={{ color: '#636363', fontSize: '16px' }}>Are you sure you want to submit attendance?</div>
            <div style={{ width: '280px' }}>
              <Pie style={{ height: '250px' }} {...config} />
            </div>
          </div>

          <div style={{
            display: "flex",
            justifyContent: 'center'
          }}>
            {tags?.map((tag, index) =>
              <div className="r-c-c m-r-15" key={index}>
                <div style={{
                  backgroundColor: tagcolor[index],
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                  marginRight: "5px"
                }}>

                </div>
                {tag == 'Present' ?
                  <div style={{ fontSize: 12, color: '#636363' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#191919' }}>{presentStudentsArray?.length}</span>  {tag}
                  </div>
                  :
                  <div style={{ fontSize: 12, color: '#636363' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#191919' }}>{absentStudentsArray?.length}</span>  {tag}
                  </div>
                }

              </div>
            )
            }
          </div>
          <div style={{
            display: "flex",
            justifyContent: 'center',
            marginTop: '25px',
            marginBottom: '20px'
          }}>
            <div style={{ marginRight: '20px' }}>
              <Button style={{ backgroundColor: '#fff', borderRadius: '20px', color: '#AEAEAE', borderColor: '#E6E6E6', minWidth: '120px' }} onClick={cancelHandler}>NO</Button>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <Button style={{ backgroundColor: '#594099', borderRadius: '20px', color: '#fff', minWidth: '120px' }} onClick={okHandler}>YES</Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}


export default SubmitModal