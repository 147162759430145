import React from 'react';
import Collapse from 'antd/lib/collapse';
import 'antd/lib/collapse/style/index';
import "./style.css";

const { Panel } = Collapse;


const CollapseGlobal = ({headers, body, tab1, tab2, onClick}) => {

  return (
      <Collapse bordered={false} defaultActiveKey={['0']} style={{margin:'0px -12px'}}>
        {headers.map((head, index) => (
          <Panel header={head} key={index} className='globalCollapse'>
            <div style={{height:303, overflow:'scroll'}}>
              {index === 0 ? tab1.map((data) => (
                  <div className='display-flex m-b-20'>
                    <img src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="" style={{width:40, height:40, borderRadius:100}}/>
                    <div className='m-l-10'>
                      <div className='color-black bold-600'>{data.name}</div>
                      <div className='dark-grey text-xs'>{data.title}</div>
                    </div>
                  </div>
              )) : index === 1 ? tab2.map((data) => (
                  <div className='display-flex m-b-20'>
                    <img src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="" style={{width:40, height:40, borderRadius:100}}/>
                    <div className='m-l-10'>
                      <div className='color-black bold-600'>{data.name}</div>
                      <div className='dark-grey text-xs'>{data.title}</div>
                    </div>
                  </div>
              )) : null}
            </div>
          </Panel>
        ))}
      </Collapse>
  )
};

export default CollapseGlobal;
