import React from "react";
import Calendar from "antd/lib/calendar";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button";
import "antd/lib/calendar/style/index";
import "antd/lib/typography/style/index";
import "antd/lib/button/style/index";
import { Color } from "../services";
import { LeftOutlined, RightOutlined, CalendarFilled } from "@ant-design/icons";
import "./CalendarSm.css";

const Styles = {
  calendar: {},
  calendarWrapper: {
    display: "flex",
    padding: 10,
    alignItems: "center",
    justifyContent: "space-between"
  },
  monthWrapper: {
    display: "flex",
    alignItems: "center"
  },
  calendarIcon: {
    marginRight: 6,
    fontSize: 20,
    color: Color.primary
  },
  calendarHeader: {
    marginBottom: 0,
    color: Color.primary
  }
};

const CalendarSm = ({ onPanelChange, onSelect, date }) => {
  return (
  <Calendar
    style={Styles.calendar}
    fullscreen={false}
    value={date}
    headerRender={({ value, type, onChange, onTypeChange }) => {
      const currentMonth = value.month();

      return (
        <div style={Styles.calendarWrapper}>
          <div style={Styles.monthWrapper}>
            <CalendarFilled style={Styles.calendarIcon} value={date}/>
            <Typography.Title level={4} style={Styles.calendarHeader}>
              {value.format("MMMM YYYY")}
            </Typography.Title>
          </div>
          <div>
            <Button
              style={{ color: Color.black }}
              type="link"
              icon={<LeftOutlined />}
              onClick={() => {
                const newValue = value.clone();
                newValue.month(currentMonth - 1);
                onChange(newValue);
              }}
            />
            <Button
              style={{ color: Color.black }}
              type="link"
              icon={<RightOutlined />}
              onClick={() => {
                const newValue = value.clone();
                newValue.month(currentMonth + 1);
                onChange(newValue);
              }}
            />
          </div>
        </div>
      );
    }}
    onSelect={date => onSelect(date)}
    onPanelChange={onPanelChange}
  />
)};

export default CalendarSm;
