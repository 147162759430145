import React from 'react';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Avatar from 'antd/lib/avatar';
import Divider from 'antd/lib/divider';
import 'antd/lib/divider/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import { Color } from '../../../services';
import { toggleNotifyModal } from '../../../actions/notification';
import '../styles.css';
import { Api } from '../../../services';
import moment from 'moment';
import { Typography } from 'antd'

const { Text } = Typography



class NotificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    }
  }




  render() {
    const { notifyModalVisible, notifyModalData, notifyModalBody, orgId } = this.props;
    return (
      <div>
        <Modal
          visible={notifyModalVisible}
          centered
          width={800}
          closable={false}
          footer={[
            <div className='r-c-c'>
              <Button className='mod-notifyApprove-btn' style={{ background: Color.darkGrey }} key="submit" loading={false} onClick={() => this.props.dispatch(toggleNotifyModal(false, {}))}>
                Close
              </Button>
            </div>,
          ]}
        >
          <div>
            <div className='r-c-sb'>
              <div className='flex-1'>
                <div className='bold-600' style={{ color: Color.black }}>{notifyModalData.title}</div>
              </div>
            </div>
            <Divider />
            <div className='r-c-sb'>
              <div className='flex-1' style={{width:'100%'}}>
                <div style={{ color: Color.black }}>
                  <Text >{notifyModalBody}</Text>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="r-jsb">
            <div><span style={{ fontWeight: 600 }}>Date: </span>{moment(notifyModalData.createdAt).format('DD-MM-YYYY')}</div>
            <div><span style={{ fontWeight: 600 }}>Time: </span>{moment(notifyModalData.createdAt).format('hh:mm A')}</div>
            {/* <div>
                <div>
                  -{notifyModalData.authorName} {notifyModalData.authorImage && <Avatar src={`${Api._s3_url}${notifyModalData.authorImage}`} size="small"/>} 
                </div>
              </div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    notifyModalVisible, notifyModalData, notifyModalBody
  } = state.notification;
  const { orgId } = state.session.user
  return {
    notifyModalVisible, notifyModalData, orgId, notifyModalBody
  };
};

export default connect(mapStateToProps)(NotificationModal);
