import React, {useState} from 'react'
import ReadyToPublishCard from "./localComponents/readyToPublishCard"
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from './localComponents/searchBox';
import CustomDatePicker from '../Test/localComponent/customDatePicker';
import DropdownCheckbox from '../Test/localComponent/dropdownCheckbox';





const bodyStyle = {background: "#FAFAFA",
        width: "105%",
        height: "94vh",
        margin:-20,
        padding:20,
        overflow:"auto"
    }

const PageHeader = ({ goBack, onSearch, searchVal}) => {
        return (
          <div className="resultPageHeader full-width" style={{marginBottom: 80}}>
            <div className="r-c-fs m-r-30">
              <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
              <div className="text-md bold-700 m-l-20"> 
                <div>
                Practice test(s) ready to be published
                </div>
              </div>
            </div>
             <div className="testfiltersgroup m-r-50">
                <SearchBox  loading={false} onChange={onSearch}  value={searchVal} className="m-r-10" placeholder="Search by test name" style={{width:230}}/>
              {/* <Button onClick={() => {}} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
                + CREATE TEST
        </Button>*/} 
            </div> 
          </div>
        );
};


const PracReadyToPublish = () => {

    const [selectedDateFilter, setSelectedDateFilter] = useState('Week')
    // const [selectedClassFilter, setSelectedClassFilter] = useState(null); 
    const [filtersChanged, setFiltersChanged] = useState(false);

    const syllabusFilters = [{val:'Chapter Test', id:1}, {val:'Part Test', id:2}, {val:'Full Test', id:3}]

    const classFilters = [{val: '7th', id: 1}, {val: '8th', id: 2}, {val: '9th', id: 3}]

//class filters
/* ...standards?.map(item => ({val:`${item.standard_std} - ${item.standard_section}`, id:item.standard_id})) */

    const handleSyllabusFilterSelect = (selection)=>{
        // console.log("syllabus selection 0 ", selection)
        // let tempsyllabus = [];
        // for (let id of selection){
        //     tempsyllabus.push(syllabusFilters[id-1].val);
        // }
        // setFiltersChanged(true);
        // updateFilter('VAsyllabus', tempsyllabus);
        // console.log("syllabus selection 1 ", tempsyllabus)
    }

    const handleClassFilterSelect = (selection)=>{
        // console.log("class selection 0 ", selection);
        // setFiltersChanged(true);
        // updateFilter('VAclasses', selection);
    }

    const onDateChange = (v) => {
        /* if (v==undefined){
        updateFilter('isVAdatefiltered', false);
        updateFilter('vaDate', null);
        }else{
            updateFilter('isVAdatefiltered', true);
            updateFilter('vaDate', v.format("YYYY-MM-DD"));
        } */
    }

    const onDropdownClose = () => {
        if (filtersChanged){
            // toggleReloaderFlag();
            setFiltersChanged(false);
        }
    }

    const syllabusDefaultVals = [];
    /* syllabusFilters.forEach((item)=>{
        console.log("syllabus 0 ", filter?.VAsyllabus)
        if (filter?.VAsyllabus.includes(item.val)){
            syllabusDefaultVals.push(item.id);
        } 
    })*/

  return (
    <div style={bodyStyle}>
        <PageHeader/>
        <div className='display-flex full-width m-b-20 m-l-10 flex-wrap'>
        <DropdownCheckbox 
                    DATA={syllabusFilters}
                    placeholder={`Fliter by syllabus`}
                    onSelection={handleSyllabusFilterSelect}
                    onDropdownClose={onDropdownClose}
                    defaultValue={syllabusDefaultVals}
                    />

        <CustomDatePicker 
                    style={{borderRadius:5, width:180}} className="m-r-10 m-l-10"
                    onDateChange={onDateChange}
                    defaultDate={"2022-01-01"}
                    placeholder={`Filter by date`}
                    />

        <DropdownCheckbox
                    DATA={classFilters}
                    placeholder={`Filter by class`}
                    onSelection={handleClassFilterSelect}
                    onDropdownClose={onDropdownClose}
                    defaultValue={null}            
                    /> 
        </div>
       <div className='display-flex flex-wrap'>
           {Array(10).fill(1)?.map(()=>( <ReadyToPublishCard
                    // imgval={chapterTestImg}
                    title={"Electrostatic Potential and Capacitance"}  
                    standardName={"JEE Mains: A (NEET)"} 
                    subjectName={"Mathematics"} 
                    subject_name={"Physics"}
                    totalQues={"72"} 
                    totalTime={"60"} 
                    totalPoints={"100"}
        />))}
       </div>
    </div>
  )
}

export default PracReadyToPublish