import moment from "moment";
import axios from "axios";
import Axios from "axios";
import { sessionService } from 'redux-react-session';
import fileDownload from "js-file-download";
import { DashboardByRole } from "pages";
import { Api } from "../services";
import { getFeeOverView } from "./feeManagement";
export const SET_TAB = "SET_TAB";
export const SET_STATUS = "SET_STATUS";
export const SET_ADD_ROLE = "SET_ADD_ROLE";
export const SET_ROLE_DASHBOARD = "SET_ROLE_DASHBOARD";
export const SET_MONTH_OR_WEEK = "SET_MONTH_OR_WEEK";
export const SET_USER_DASHBOARD = "SET_USER_DASHBOARD";
export const SET_USERBY_STATS = "SET_USERBY_STATS";
export const SET_ROLE = "SET_ROLE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_SEARCH_NAME = "SET_SEARCH_NAME";
export const SET_ROLE_DASHBOARD_DATA = "SET_ROLE_DASHBOARD_DATA";
export const SET_UNASSIGNED_STUDENTS = "SET_UNASSIGNED_STUDENTS";
export const SET_SORT_TYPE = "SET_SORT_TYPE";
export const SET_SECTION_DETAILS = "SET_SECTION_DETAILS";
export const SET_STUDENTSLIST_BY_STD = "SET_STUDENTSLIST_BY_STD";
export const SET_USERSLIST_BY_ROLE = "SET_USERSLIST_BY_ROLE";
export const SET_STARTDATE = "SET_STARTDATE";
export const SET_ENDDATE = "SET_ENDDATE";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const SET_GENDER_RATIO = "SET_GENDER_RATIO";
export const SET_SPECIFIC_USER_ID = "SET_SPECIFIC_USER_ID";
export const SET_USER_DETAILS_BY_ID = "SET_USER_DETAILS_BY_ID";
export const SET_AUTO_APPROVAL = "SET_AUTO_APPROVAL";

export const SET_USERNAME = "SET_USERNAME";
export const SET_PHONE = "SET_PHONE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_PRIVILAGE = "SET_PRIVILAGE";
export const SET_USER_CLASS = "SET_USER_CLASS";
export const SET_CLASS_ID = "SET_CLASS_ID";
export const SET_ROLL_NUMBER = "SET_ROLL_NUMBER";
export const GET_CLASS_NAMES = "GET_CLASS_NAMES";
export const GET_ROLL_NUMBERS = "GET_ROLL_NUMBERS";
export const STORE_SEARCHED_RESULTS = "STORE_SEARCHED_RESULTS"
export const SET_PAGE = "SET_PAGE";
export const SET_LIMIT = "SET_LIMIT";
export const FETCH_UNASSIGNED_COURSES = "FETCH_UNASSIGNED_COURSES";
export const STORE_SELECTED_COURSE = "STORE_SELECTED_COURSE";
export const STORE_SELECTED_DATE = "STORE_SELECTED_DATE";

//for the editing user details form//

//FOR BASIC INFO
export const SET_EDIT_NAME = "SET_EDIT_NAME";
export const SET_EDIT_ROLE = "SET_EDIT_ROLE";
export const SET_EDIT_PHONE = "SET_EDIT_PHONE";
export const SET_EDIT_EMAIL = "SET_EDIT_EMAIL";
export const SET_EDIT_GENDER = "SET_EDIT_GENDER";
export const SET_EDIT_BLOOD_GROUP = "SET_EDIT_BLOOD_GROUP";
export const SET_EDIT_DATE_OF_BIRTH = "SET_EDIT_DATE_OF_BIRTH";
export const SET_EDIT_EDIT_PRIVILAGES = "SET_EDIT_EDIT_PRIVILAGES";

//FOR CURRENT ADDRESS
export const SET_EDIT_CURRENT_ADDRESS = "SET_EDIT_CURRENT_ADDRESS";
export const SET_EDIT_CURRENT_PINCODE = "SET_EDIT_CURRENT_PINCODE";
export const SET_EDIT_CURRENT_STATE = "SET_EDIT_CURRENT_STATE";
export const SET_EDIT_CURRENT_DISTRICT = "SET_EDIT_CURRENT_DISTRICT";
export const SET_EDIT_CURRENT_COUNTRY = "SET_EDIT_CURRENT_COUNTRY";

//FOR PERMINENT ADDRESS
export const SET_EDIT_PERMANENT_ADDRESS = "SET_EDIT_PERMANENT_ADDRESS";
export const SET_EDIT_PERMANENT_PINCODE = "SET_EDIT_PERMANENT_PINCODE";
export const SET_EDIT_PERMANENT_STATE = "SET_EDIT_PERMANENT_STATE";
export const SET_EDIT_PERMANENT_DISTRICT = "SET_EDIT_PERMANENT_DISTRICT";
export const SET_EDIT_PERMANENT_COUNTRY = "SET_EDIT_PERMANENT_COUNTRY";

//FOR EMERGENCY DETAILS
export const SET_EDIT_EMERGENCY_NAME = "SET_EDIT_EMERGENCY_NAME";
export const SET_EDIT_EMERGENCY_PHONE = "SET_EDIT_EMERGENCY_PHONE";
export const SET_EDIT_EMERGENCY_RELATION = "SET_EDIT_EMERGENCY_RELATION";

//FOR LINKING COURSE WITH STUDENT DETAILS
export const SET_COURSE_DETAILS = "SET_COURSE_DETAILS";

//FOR UNASSIGNED STUDENTS
export const STORE_UNASSIGNED_STUDENTS = 'STORE_UNASSIGNED_STUDENTS';
export const SET_ASSIGN_META = 'SET_ASSIGN_META';
export const STD_ID = 'STD_ID';

//UPLOAD TYPE MODAL
export const STORE_CREATEUPLOAD_TYPE = 'STORE_CREATEUPLOAD_TYPE'
export const STORE_ADDUSER_FIELDS = 'STORE_ADDUSER_FIELDS'
export const CLEAR_ADDUSER_FIELDS = 'CLEAR_ADDUSER_FIELDS'
export const DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE'
export const STORE_EXCEL_DETAILS = 'STORE_EXCEL_DETAILS'
export const STORE_EXCEL_FILEID = 'STORE_EXCEL_FILEID'
export const STORE_EXCEL_DATA = 'STORE_EXCEL_DATA'
export const STORE_PENDING_USERS = 'STORE_PENDING_USERS'

export const STORE_STANDARDS = 'STORE_STANDARDS'

//////////////////////////////////////
//FOR BASIC INFO
export const setCourseDetails = (key, res) => ({
  type: SET_COURSE_DETAILS,
  res,
  key,
});

export const setAutoApproval = (res) => ({
  type: SET_AUTO_APPROVAL,
  payload: res,
});
export const setEditName = (res) => ({
  type: SET_EDIT_NAME,
  payload: res,
});
export const setUserDetailsById = (res) => ({
  type: SET_USER_DETAILS_BY_ID,
  payload: res,
});
export const setSpecificUserId = (res) => ({
  type: SET_SPECIFIC_USER_ID,
  payload: res,
});

//////
export const setEditRole = (res) => ({
  type: SET_EDIT_ROLE,
  payload: res,
});
export const setEditPhone = (res) => ({
  type: SET_EDIT_PHONE,
  payload: res,
});
export const setEditEmail = (res) => ({
  type: SET_EDIT_EMAIL,
  payload: res,
});
export const setEditGender = (res) => ({
  type: SET_EDIT_GENDER,
  payload: res,
});
export const setEditBloodGroup = (res) => ({
  type: SET_EDIT_BLOOD_GROUP,
  payload: res,
});
export const setEditDateOfBirth = (res) => ({
  type: SET_EDIT_DATE_OF_BIRTH,
  payload: res,
});

export const setEditPrivilages = (res) => ({
  type: SET_EDIT_EDIT_PRIVILAGES,
  payload: res,
});

//FOR CURRENT ADDRESS

export const setEditCurrentAddress = (res) => ({
  type: SET_EDIT_CURRENT_ADDRESS,
  payload: res,
});
export const setEditCurrentPinCode = (res) => ({
  type: SET_EDIT_CURRENT_PINCODE,
  payload: res,
});
export const setEditCurrentState = (res) => ({
  type: SET_EDIT_CURRENT_STATE,
  payload: res,
});

export const setEditCurrentDistrict = (res) => ({
  type: SET_EDIT_CURRENT_DISTRICT,
  payload: res,
});

export const setEditCurrentCountry = (res) => ({
  type: SET_EDIT_CURRENT_COUNTRY,
  payload: res,
});

//FOR PERMANENT ADDRESS

export const setEditPermanentAddress = (res) => ({
  type: SET_EDIT_PERMANENT_ADDRESS,
  payload: res,
});
export const setEditPermanentPinCode = (res) => ({
  type: SET_EDIT_PERMANENT_PINCODE,
  payload: res,
});
export const setEditPermanentState = (res) => ({
  type: SET_EDIT_PERMANENT_STATE,
  payload: res,
});

export const setEditPermanentDistrict = (res) => ({
  type: SET_EDIT_PERMANENT_DISTRICT,
  payload: res,
});

export const setEditPermanentCountry = (res) => ({
  type: SET_EDIT_PERMANENT_COUNTRY,
  payload: res,
});
//FOR EMERGENCY DETAILS
export const setEditEmergencyName = (res) => ({
  type: SET_EDIT_EMERGENCY_NAME,
  payload: res,
});
export const setEditEmergencyPhone = (res) => ({
  type: SET_EDIT_EMERGENCY_PHONE,
  payload: res,
});
export const setEditEmergencyRelation = (res) => ({
  type: SET_EDIT_EMERGENCY_RELATION,
  payload: res,
});
///////////////////////////////////////////////////

//for general use

export const setTab = (res) => ({
  type: SET_TAB,
  payload: res,
});
export const setNextRollNo = (res) => ({
  type: GET_ROLL_NUMBERS,
  payload: res,
});
export const setClassId = (res) => ({
  type: SET_CLASS_ID,
  payload: res,
});
export const getClassNames = (res) => ({
  type: GET_CLASS_NAMES,
  payload: res,
});
export const setPrivilage = (res) => ({
  type: SET_PRIVILAGE,
  payload: res,
});
export const setGenderRatio = (res) => ({
  type: SET_GENDER_RATIO,
  payload: res,
});
export const setGraphData = (res) => ({
  type: SET_GRAPH_DATA,
  payload: res,
});

export const setStartDate = (res) => ({
  type: SET_STARTDATE,
  payload: res,
});

export const setEndDate = (res) => ({
  type: SET_ENDDATE,
  payload: res,
});
export const setSectionById = (res) => ({
  type: SET_SECTION_DETAILS,
  payload: res,
});

export const setStatus = (res) => ({
  type: SET_STATUS,
  payload: res,
});

export const setAddRole = (res) => ({
  type: SET_ADD_ROLE,
  payload: res,
});

export const setDashBoardRole = (res) => ({
  type: SET_ROLE_DASHBOARD,
  payload: res,
});

export const setMonthOrWeek = (res) => ({
  type: SET_MONTH_OR_WEEK,
  payload: res,
});
export const setUserDashboard = (res) => ({
  type: SET_USER_DASHBOARD,
  payload: res,
});
export const setUserByStats = (res) => ({
  type: SET_USERBY_STATS,
  payload: res,
});
export const setRole = (res) => ({
  type: SET_ROLE,
  payload: res,
});
export const setUserDetails = (res) => ({
  type: SET_USER_DETAILS,
  payload: res,
});
export const setName = (res) => ({
  type: SET_SEARCH_NAME,
  payload: res,
});
export const setUserName = (res) => ({
  type: SET_USERNAME,
  payload: res,
});
export const setPhone = (res) => ({
  type: SET_PHONE,
  payload: res,
});
export const setEmail = (res) => ({
  type: SET_EMAIL,
  payload: res,
});
export const setClass = (res) => ({
  type: SET_USER_CLASS,
  payload: res,
});
export const setRollNumber = (res) => ({
  type: SET_ROLL_NUMBER,
  res,
});
export const setRoleDashBoardData = (res) => ({
  type: SET_ROLE_DASHBOARD_DATA,
  payload: res,
});
export const setUnAssignedStudents = (res) => ({
  type: SET_UNASSIGNED_STUDENTS,
  payload: res,
});
export const setSortType = (res) => ({
  type: SET_SORT_TYPE,
  payload: res,
});
export const setStudentsListOfStd = (res) => ({
  type: SET_STUDENTSLIST_BY_STD,
  payload: res,
});
export const setUserListByRole = (res) => ({
  type: SET_USERSLIST_BY_ROLE,
  payload: res,
});

///////////////////////////////////
export const unAssignedStudentsList = (id) => ({
  type: STORE_UNASSIGNED_STUDENTS,
  payload: id
})

export const setAssignMeta = (key, res) => ({
  type: SET_ASSIGN_META,
  res,
  key,
});

export const trackStdIds = (id) => ({
  type: STD_ID,
  id
})
///////////////////////////////////
export const storeAddUsers = (key, val) => ({
  type: STORE_CREATEUPLOAD_TYPE,
  key, val
})

export const storeAdduserFields = (key, val) => ({
  type: STORE_ADDUSER_FIELDS,
  key, val
})

export const clearAddUserFields = (key, val) => ({
  type: CLEAR_ADDUSER_FIELDS,
  key, val
})
///////////////////////////////////

export const fetchPinCodeDetails = (pin, callback) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const request = {
        method: "get",
        url: `https://api.postalpincode.in/pincode/${pin}`,
      };
      axios(request)
        .then((response, error) => {
          console.log("APi res", response);
          callback(response.data[0]);
        })
        .catch((error, response) => {
          console.log(error);
        });
    });
  };
};

export const getStats = (callback = null) => (dispatch, getState) => {
  const { startDate, endDate, isMonthOrWeek } = getState().userManagement;

  const params = {
    type: isMonthOrWeek ? isMonthOrWeek : "week",
    fromDate: startDate == "" ? moment().subtract(6, "days").format("YYYY-MM-DD") : startDate,
    toDate: endDate === "" ? moment().format("YYYY-MM-DD") : endDate,
  };

  return Api.get("/user/stats").params(params).send((response) => {
    if (callback) {
      callback();
    }
    if (response !== undefined) {
      dispatch(setUserDashboard(response));
      dispatch(setGraphData(response?.growthSummary));
      dispatch(setAutoApproval(response?.studentAutoApproved));
    }
  });
};

export const getUserRequests = (callback = null, page) => (dispatch, getState) => {
  const { startDate, endDate, isMonthOrWeek, role, tab, search } = getState().userManagement;

  const params = {
    page,
    status: tab,
    role: role,
    search: search,
  };

  return Api.get("/user/byStatus").params(params).send((response) => {
    if (callback) {
      callback();
    }

    if (response !== undefined) {
      dispatch(setUserByStats(response));
    }
  });
};

function capitalize(str) {
  return str && str[0].toUpperCase() + str.slice(1);
}

export const postApproveReject = (callback = null, callbackButton = null, id, buttonClicked) => (dispatch, getState) => {
  const { userRequestStatus, userDetails } = getState().userManagement;
  let Id;
  if (buttonClicked) {
    Id = id;
  } else {
    Id = userDetails?.id;
  }
  const params = {
    userId: Id,
    status: capitalize(userRequestStatus),
  };

  return Api.post("/user/updateStatus", params).send((response) => {
    if (callback) {
      callback();
    }

    if (response !== undefined) {
      if (callbackButton) {
        callbackButton();
      }
    }
  });
};

export const getUserDashBoardData = (callback = null,) => (dispatch, getState) => {
  const { startDate, endDate, dashBoardRole, isMonthOrWeek, sort, search, pageNumber } = getState().userManagement;
  let str = dashBoardRole;
  const params = {
    page: pageNumber,
    search: search,
    role: str?.replace(" ", ""),
    sort: sort,
    type: isMonthOrWeek ? isMonthOrWeek : "week",
    fromDate: startDate == "" ? moment().subtract(6, "days").format("YYYY-MM-DD") : startDate,
    toDate: endDate === "" ? moment().format("YYYY-MM-DD") : endDate,
  };

  return Api.get("/user").params(params).send((response) => {
    if (callback) {
      callback();
    }

    if (response !== undefined) {
      dispatch(setRoleDashBoardData(response));
      dispatch(setGraphData(response?.growthSummary));
      dispatch(setGenderRatio(response?.usersByGender));
    }
  });
};

export const unAssaignedStudents = (callback = null, page) => (dispatch, getState) => {
  const { userName, sort, selectedCourseName, selectedDate, pageLimit } = getState().userManagement;

  const params = {
    page,
    search: userName,
    sort: sort,
    courseName: selectedCourseName,
    fromDate: selectedDate,
    limit: pageLimit
  };

  return Api.get("/user/student/unAssigned").params(params).send((response) => {
    if (callback) {
      callback();
    }

    if (response !== undefined) {
      dispatch(setUnAssignedStudents(response));
    }
  });
};

export const getStudentsBySecId =
  (callback = null, page) =>
    (dispatch, getState) => {
      const { search, sectionDetails } = getState().userManagement;

      const params = {
        page,
        search: search,
      };

      return Api.get(`/user/student/standard/${sectionDetails?.id}`)
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            dispatch(setStudentsListOfStd(response));
            // console.log(response);
          }
        });
    };

export const addUserByRole =
  (callback = null, callbackLoader = null) =>
    (dispatch, getState) => {
      const {
        storeAddUserDetails,
      } = getState().userManagement;

      let params = {
        name: storeAddUserDetails?.name,
        email: storeAddUserDetails?.email,
        phone: storeAddUserDetails?.phoneNo,
        privilage: storeAddUserDetails?.privilage,
        role: storeAddUserDetails?.role,
      };

      if (storeAddUserDetails?.role === "Student") {
        params = {
          ...params,
          courseId: storeAddUserDetails?.course ? storeAddUserDetails?.course : null,
          email: storeAddUserDetails?.email ? storeAddUserDetails?.email : null
        };
      }
      if (storeAddUserDetails?.role !== "Student") {
        params = {
          ...params,
          privilage: storeAddUserDetails?.privilage,
          email: storeAddUserDetails?.email ? storeAddUserDetails?.email : null
        };
      }

      return Api.post("/user/addUser")
        .params(params)
        .send((response, error) => {
          if (error) {
          }
          if (callbackLoader) {
            callbackLoader();
          }

          if (response !== undefined) {
            if (callback) {
              callback();
            }
            dispatch(setStudentsListOfStd(response));
          }
        });
    };

export const getStudentsclass = (callback = null) => (dispatch, getState) => {

  const { search, sectionDetails } = getState().userManagement;

  return Api.get("/user/courses").send((response) => {
    if (callback) callback();
    if (response !== undefined) {
      dispatch(getClassNames(response));
    }
  });
};

export const getStudentsRollNo = (classId, callback = null, isStudentSelected) => (dispatch, getState) => {
  const { search, sectionDetails, userDetailsById } = getState().userManagement;
  const params = {
    studentId: isStudentSelected ? userDetailsById?.id : "",
  };

  return Api.get(`/user/${classId}/rollNo`).params(params).send((response) => {
    if (callback) {
      callback();
    }
    if (response !== undefined) {
      dispatch(setNextRollNo(response?.rollNo));
      if (response?.courseUpdate?.coursePopUp) {
        dispatch(setCourseDetails("sectionCoursePop", true));
      } else {
        dispatch(setCourseDetails("sectionCoursePop", false));
      }
      dispatch(setCourseDetails("isSectionChanged", response));
      dispatch(getStudentsDetailsById());
      // console.log(response);
    }
  });
};

export const updateApprovalStatus = (callback = null, val) => (dispatch, getState) => {
  const params = {
    studentAutoApproved: val,
  };

  return Api.post("/user/addUserSettings").params(params).send((response) => {
    callback();
    if (response !== undefined) { }
  });
};

function parseData(data) {
  // console.log("parsedata", data);
  if (!data) return {};
  if (typeof data === "object") return data;
  if (data[0] === "{") {
    if (typeof data === "string") return JSON.parse(data);
  }
}

export const getStudentsDetailsById = (callback = null, id = null) =>
  (dispatch, getState) => {
    const { search, sectionDetails, userDetailsById, classId } = getState().userManagement;

    let specificUserId = null;
    if (window.location.pathname === '/user-management/profile') {
      specificUserId = getState().userManagementV2.specificUserId;
    } else {
      specificUserId = getState().userManagement.specificUserId;
    }

    dispatch(setClassId(userDetailsById?.student?.standardId > 0 ? userDetailsById?.student?.standardId : null));
    dispatch(setNextRollNo(userDetailsById?.student?.rollNo != 0 ? userDetailsById?.student?.rollNo : null));
    dispatch(setEditName(userDetailsById?.name));
    dispatch(setEditRole(userDetailsById?.role?.[0]));
    dispatch(setEditPhone(userDetailsById?.phone));
    dispatch(setEditEmail(userDetailsById?.email));
    dispatch(setEditGender(userDetailsById?.basicprofile?.gender));
    dispatch(setEditBloodGroup(userDetailsById?.basicprofile?.bloodGroup));
    dispatch(setEditDateOfBirth(userDetailsById?.basicprofile?.dob !== "0000-00-00" ? userDetailsById?.basicprofile?.dob : ""));
    dispatch(setEditPrivilages(userDetailsById?.privilage));
    dispatch(setEditEmergencyName(userDetailsById?.basicprofile?.emergencyContactName));
    dispatch(setEditEmergencyPhone(userDetailsById?.basicprofile?.emergencyPhone));
    dispatch(setEditEmergencyRelation(userDetailsById?.basicprofile?.relationship));
    dispatch(setEditCurrentAddress(parseData(userDetailsById?.basicprofile?.currentAddress)?.h_no));
    dispatch(setEditCurrentState(parseData(userDetailsById?.basicprofile?.currentAddress)?.state));
    dispatch(setEditCurrentPinCode(parseData(userDetailsById?.basicprofile?.currentAddress)?.pincode));
    dispatch(setEditCurrentDistrict(parseData(userDetailsById?.basicprofile?.currentAddress)?.city));
    dispatch(setEditCurrentCountry(parseData(userDetailsById?.basicprofile?.currentAddress)?.country ? parseData(userDetailsById?.basicprofile?.currentAddress)?.country : "India"));
    dispatch(setEditPermanentAddress(parseData(userDetailsById?.basicprofile?.permanentAddress)?.h_no));
    dispatch(setEditPermanentState(parseData(userDetailsById?.basicprofile?.permanentAddress)?.state));
    dispatch(setEditPermanentPinCode(parseData(userDetailsById?.basicprofile?.permanentAddress)?.pincode));
    dispatch(setEditPermanentDistrict(parseData(userDetailsById?.basicprofile?.permanentAddress)?.city));
    dispatch(setEditPermanentCountry(parseData(userDetailsById?.basicprofile?.permanentAddress)?.country ? parseData(userDetailsById?.basicprofile?.permanentAddress)?.country : "India"));

    return Api.get(`/user/userId/${id ? id : specificUserId}`).send((response) => {
      if (response !== undefined) {
        if (callback) {
          callback();
        }
        dispatch(setEditName(response?.name));
        dispatch(setUserDetailsById(response));
        dispatch(setEditPhone(response?.phone));
        dispatch(setEditEmail(response?.email));
        dispatch(setEditRole(response?.role[0]));
        dispatch(setEditPrivilages(response?.privilage));
        dispatch(setEditGender(response?.basicprofile?.gender));
        dispatch(setEditBloodGroup(response?.basicprofile?.bloodGroup));
        dispatch(setEditEmergencyPhone(response?.basicprofile?.emergencyPhone));
        dispatch(setEditEmergencyRelation(response?.basicprofile?.relationship));
        dispatch(setEditEmergencyName(response?.basicprofile?.emergencyContactName));
        dispatch(setEditCurrentState(parseData(response?.basicprofile?.currentAddress)?.state));
        dispatch(setEditCurrentAddress(parseData(response?.basicprofile?.currentAddress)?.h_no));
        dispatch(setEditCurrentDistrict(parseData(response?.basicprofile?.currentAddress)?.city));
        dispatch(setNextRollNo(response?.student?.rollNo != 0 ? response?.student?.rollNo : null));
        dispatch(setEditCurrentPinCode(parseData(response?.basicprofile?.currentAddress)?.pincode));
        dispatch(setEditPermanentState(parseData(response?.basicprofile?.permanentAddress)?.state));
        dispatch(setEditPermanentAddress(parseData(response?.basicprofile?.permanentAddress)?.h_no));
        dispatch(setEditPermanentDistrict(parseData(response?.basicprofile?.permanentAddress)?.city));
        dispatch(setClassId(response?.student?.standardId > 0 ? response?.student?.standardId : null));
        dispatch(setEditPermanentPinCode(parseData(response?.basicprofile?.permanentAddress)?.pincode));
        dispatch(setEditDateOfBirth(response?.basicprofile?.dob !== "0000-00-00" ? response?.basicprofile?.dob : ""));
        dispatch(setEditCurrentCountry(parseData(response?.basicprofile?.currentAddress)?.country ? parseData(response?.basicprofile?.currentAddress)?.country : "India"));
        dispatch(setEditPermanentCountry(parseData(response?.basicprofile?.permanentAddress)?.country ? parseData(response?.basicprofile?.permanentAddress)?.country : "India"));
      }
    });
  };

export const editUserInfo =
  (
    handleSuccessCallback = null,
    callback = null,
    fileInputValue = null,
    currentAddPin,
    perminentAddpin,
    sameAsCA,
    notification = null
  ) =>

    (dispatch, getState) => {


      const {
        startDate,
        endDate,
        dashBoardRole,
        role,
        tab,
        sort,
        search,
        editName,
        editRole,
        editPhone,
        editEmail,
        editGender,
        editBloodGroup,
        editPrivilage,
        editEmergencyName,
        editEmergencyPhone,
        editEmergencyRelation,
        editCurrentAddress,
        editCurrentPinCode,
        editCurrentState,
        editCurrentDistrict,
        editCurrentCountry,
        editPermanentAddress,
        editPermanentPinCode,
        editPermanentState,
        editPermanentDistrict,
        editPermanentCountry,
        userDetailsById,
        specificUserId,
        editDateOfBirth,
        nextRollNo,
        classId,
        studentCourseDetails,
      } = getState().userManagement;
      let curretAdd = {
        h_no: editCurrentAddress,
        state: editCurrentState,
        pincode: editCurrentPinCode,
        city: editCurrentDistrict,
        locality: parseData(userDetailsById?.basicprofile?.currentAddress)
          ?.locality,
        country: editCurrentCountry,
        PinAvailable: currentAddPin,
      };
      let permanentAdd = {
        h_no: editPermanentAddress,
        state: editPermanentState,
        pincode: editPermanentPinCode,
        city: editPermanentDistrict,
        locality: parseData(userDetailsById?.basicprofile?.permanentAddress)
          ?.locality,
        country: editPermanentCountry,
        sameAsCa: sameAsCA,
        PinAvailable: perminentAddpin,
      };
      let params = {
        id: specificUserId,
        name: editName,
        email: editEmail,
        phone: editPhone,
        role: editRole,
        privilage: editPrivilage,
        status: userDetailsById?.status,

        currentAddress: JSON.stringify(curretAdd),
        permanentAddress: JSON.stringify(permanentAdd),
        emergencyContactName: editEmergencyName,
        emergencyPhone: editEmergencyPhone,
        gender: editGender,
        dob: editDateOfBirth,
        bloodGroup: editBloodGroup,
      };

      let fd = new FormData();
      fd.append("id", specificUserId);
      fd.append("name", editName);
      if (editEmail) {
        fd.append("email", editEmail);
      } else {
        fd.append("email", "");
      }
      fd.append("phone", editPhone);
      fd.append("oldPhone", userDetailsById?.phone);
      // editRole!==[]&&editRole.forEach((item)=>fd.append("role[]",item))
      editPrivilage !== [] &&
        editPrivilage?.forEach((item) => fd.append("privilage[]", item));

      editRole && fd.append("role", editRole);
      // fd.append("privilage",editPrivilage)
      fd.append("status", userDetailsById?.status);
      fd.append("currentAddress", JSON.stringify(curretAdd));
      fd.append("permanentAddress", JSON.stringify(permanentAdd));
      fd.append("emergencyContactName", editEmergencyName !== '' ? editEmergencyName : null);
      fd.append("emergencyPhone", editEmergencyPhone);
      editEmergencyRelation &&
        fd.append("emergencyContactRelation", editEmergencyRelation);
      editGender && fd.append("gender", editGender);
      editDateOfBirth && fd.append("dob", editDateOfBirth);
      editBloodGroup && fd.append("bloodGroup", editBloodGroup);

      // if(fileInputValue){
      if (fileInputValue && fileInputValue !== "") {
        fd.append("image", fileInputValue);
        // console.log("image added");
      } else if (userDetailsById != undefined && userDetailsById?.image) {
        fd.append("image", userDetailsById?.image);
      }
      if (notification) {
        fd.append("notification", true);
      } else {
        fd.append("notification", false);
      }
      // console.log("images", fd);

      // oldStandardId:null,

      //students
      ///////
      // preferredClass: -1,
      if (dashBoardRole === "Student" || editRole == "Student") {
        // if(nextRollNo){

        // }
        if ((classId && classId !== "") || nextRollNo) {
          fd.append("rollNo", nextRollNo);
          fd.append("standardId", studentCourseDetails?.sectionId);
        }
      }
      // console.log("hellow", fd);

      return Api.post("/user/updateUser")
        .params(fd)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            handleSuccessCallback();
            if (response["token"] !== undefined) {
              let token = response["token"];
              sessionService.initSessionService(store).then((data) => {
                let { user } = store.getState()["session"];
                user.accessToken = token;
                sessionService.saveSession({ token }).then(() => {
                  sessionService.saveUser(user).catch(err => console.error(err));
                }).catch(err => console.error(err));
              });
              Api.header(token);
            }


            // dispatch(setUnAssignedStudents(response));
            // if (response?.courseUpdate?.coursePopUp) {
            //   dispatch(setCourseDetails("sectionCoursePop", true));
            // } else {
            //   dispatch(setCourseDetails("sectionCoursePop", false));
            // }
            // dispatch(setCourseDetails("isSectionChanged", response));

            // console.log(response);
          }
        });
    };
export const deleteUserById =
  (callback = null) =>
    (dispatch, getState) => {
      const { search, sectionDetails, classId, specificUserId } =
        getState().userManagement;

      return Api.delete(`/user/delete/${specificUserId}`).send((response) => {
        if (response !== undefined) {
          if (callback) {
            callback();
          }
        }
      });
    };

export const sendRemaider =
  (callback = null) =>
    (dispatch, getState) => {
      const { search, sectionDetails, specificUserId, classId } =
        getState().userManagement;

      return Api.get(`/user/sendReminder/${specificUserId}`).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
        }
      });
    };

export const deleteProfilePic =
  (callback = null,) =>
    (dispatch, getState) => {
      const { search, sectionDetails, specificUserId, classId } =
        getState().userManagement;
      return Api.patch(`/user/remove-image/${specificUserId}`).send(
        (response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
          }
        }
      );
    };

const returnNames = (val) => {
  console.log("returnNames", val);
  if (val == "30") {
    return "Month(s)";
  } else if (val == "360" || val == "365") {
    return "Year(s)";
  } else if (val == "1") {
    return "Day(s)";
  }
};

const returnPlanNames = (val) => {
  console.log("returnPlanNames", val);

  if (val == "30") {
    return "Monthly";
  } else if (val == "90") {
    return "Quarterly";
  } else if (val == "180") {
    return "Half Yearly";
  } else if (val == "360" || val == "365") {
    return "Annually";
  } else if (val == "One time") {
    return "One time";
  } else if (val == "Customize") {
    return "Customize";
  }
};

export const assignCourseToStudent =
  (
    callback = null,
    successCallBack = null,
    edit,
    deactive,
    startDate,
    deleteCourse
  ) =>
    (dispatch, getState) => {
      const {
        search,
        sectionDetails,
        classId,
        studentCourseDetails,
        nextRollNo,
        userDetailsById,
      } = getState().userManagement;
      const {
        installments,
        coursePrice,
        courseBillingPlan,
        courseRef,
        courseDuration,
      } = getState().course;
      // standardId: studentCourseDetails?.section
      //   ? parseInt(studentCourseDetails?.section)
      //   : userDetailsById?.student?.standardId,
      // standardId:
      //   userDetailsById?.student?.standardId != -1
      //     ? userDetailsById?.student?.standardId
      //     : parseInt(studentCourseDetails?.section),
      let specificUserId = null;
      if (window.location.pathname === '/user-management/profile') {
        specificUserId = getState().userManagementV2.specificUserId;
      } else {
        specificUserId = getState().userManagement.specificUserId;
      }


const params1 = {resourceType:studentCourseDetails?.courseType,resourceId:studentCourseDetails?.addCourse?.name,userIds:[specificUserId]}

      const params = {
        userId: specificUserId,
        courseId: studentCourseDetails?.addCourse?.name,
        

        shiftCourse:
          studentCourseDetails?.addCourse?.natureOfCourse == "shift"
            ? true
            : false,
      };
      if (edit) {
        params["startDate"] = startDate;
      } else {
        params["startDate"] = studentCourseDetails?.addCourse?.startDate
          ? studentCourseDetails?.addCourse?.startDate
          : studentCourseDetails?.selectedCourse?.startDate;
        if (studentCourseDetails?.dismissCard) {
          params["dismissCard"] = studentCourseDetails?.dismissCard;
        }
      }

      if (edit) {
        params["id"] = edit ? studentCourseDetails?.selectedCourse?.id : null;
        params["activateCourse"] = deactive ? false : true;
        params["deactivateCourse"] = deactive;
        params["deleteCourse"] = deleteCourse;
      } else {
      }
      // hasValidGst: null,

      // isActive: 1,

      // inActiveDate: null,

      // transactionIds: "",
      console.log("params", params);
const url = studentCourseDetails?.courseType == 'course' ? "/user/student/assignCourseV2" : "/course/assign-non-live-course"



      return Api.post(url, studentCourseDetails?.courseType == 'course'? params : params1).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(getStudentsDetailsById());
        }
      });
    };


export const assignNonLiveCourseToStudent   = () =>{
  
}

export const changeCourseName =
  (callback = null, successCallBack = null, startDate) =>
    (dispatch, getState) => {
      const {
        search,
        sectionDetails,
        specificUserId,
        classId,
        studentCourseDetails,
        nextRollNo,
      } = getState().userManagement;
      const { installments, coursePrice, courseBillingPlan, courseRef } =
        getState().course;
      const params = {
        courseId: studentCourseDetails?.course,
        startDate: startDate,
        id: studentCourseDetails?.selectedCourse?.id,
      };

      console.log("params", params);

      return Api.post("/user/student/assignCourse", params).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(getStudentsDetailsById());
        }
      });
    };

export const getCourseDetalis =
  (callback = null) =>
    (dispatch, getState) => {
      const {
        search,
        sectionDetails,
        specificUserId,
        classId,
        studentCourseDetails,
        nextRollNo,
      } = getState().userManagement;

      return Api.get(
        `/user/student/courseDetails/${studentCourseDetails?.selectedCourse?.id}`
      ).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(setCourseDetails("selectedCourse", response));
          dispatch(
            setCourseDetails(
              "savedSholarshipLength",
              response?.scholarshipDetails
                ? JSON.parse(response?.scholarshipDetails)?.length
                : 0
            )
          );
          dispatch(setCourseDetails("removedScholarships", []));
          dispatch(setCourseDetails("scholarshipsRemoved", false));
        }
      });
    };

export const deleteCourseWithId =
  (callback = null, successCallBack = null, val) =>
    (dispatch, getState) => {
      const {
        search,
        sectionDetails,
        specificUserId,
        classId,
        studentCourseDetails,
        nextRollNo,
      } = getState().userManagement;
      // const params = {
      //   userId: specificUserId,
      //   courseId: studentCourseDetails?.addCourse?.name,
      //   standardId: parseInt(studentCourseDetails?.section),
      //   rollNo: nextRollNo,
      //   startDate: studentCourseDetails?.addCourse?.startDate,
      // };

      return Api.delete(
        `/user/student/courseDetails/${studentCourseDetails?.selectedCourse?.id}`
      ).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(setCourseDetails("selectedCourse", response));
        }
      });
    };
export const updateScholarshipOrAddition =
  (callback = null, successCallBack = null, val) =>
    (dispatch, getState) => {
      const {
        search,
        sectionDetails,
        classId,
        studentCourseDetails,
        nextRollNo,
        userDetailsById
      } = getState().userManagement;
      // userId: specificUserId,
      let specificUserId = null;
      if (window.location.pathname === '/user-management/profile') {
        specificUserId = getState().userManagementV2.specificUserId;
      } else {
        specificUserId = getState().userManagement.specificUserId;
      }
      const params = {
        userId: specificUserId,
        amount: studentCourseDetails?.feeAmount || userDetailsById?.student?.feeDetails?.totalFee,
        // courseId: studentCourseDetails?.selectedCourse?.id,
        // scholarshipOrAddition:
        //   studentCourseDetails?.type == "scholarship"
        scholarshipDetails: studentCourseDetails?.scholarships,
        additionDetails: studentCourseDetails?.additions,

        // additonDetails: JSON.stringify(studentCourseDetails?.additions),
        // type: studentCourseDetails?.type,
        // method: studentCourseDetails?.method,
        // removeKey: studentCourseDetails?.removeScholarshipIndex,
      };

      return Api.post(`/user/student/updatefee`)
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            // dispatch(getCourseDetalis());
            // dispatch(setCourseDetails("selectedCourse", response));
            dispatch(getStudentsDetailsById());
          }
        });
    };

export const requestTransaction =
  (callback = null, successCallBack = null, id, dueDate) =>
    (dispatch, getState) => {
      const params = {
        id: id,
        dueDate: dueDate,
      };

      return Api.post("/feeV2/update-request-status", params).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
    };

export const updateTransactions =
  (callback = null, successCallBack = null, deletedIds) =>
    (dispatch, getState) => {
      const {
        search,
        sectionDetails,
        classId,
        studentCourseDetails,
        nextRollNo,
      } = getState().userManagement;
      const { coursePrice } = getState().course;
      console.log("deletedIds1", deletedIds)
      let specificUserId = null;
      if (window.location.pathname === '/user-management/profile') {
        specificUserId = getState().userManagementV2.userRowDetails?.id;
      } else {
        specificUserId = getState().userManagement.specificUserId;
      }
      const params = {
        userId: specificUserId,
        amount: coursePrice,
        installmentDetails: studentCourseDetails?.newInstallments,
        deletedIds: deletedIds
      };

      return Api.post("/user/student/updateinstallments", params).send(
        (response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            dispatch(getStudentsDetailsById());
            // dispatch(getFeeOverView());
          }
        }
      );
    };

export const unassignStudent =
  (callback = null, successCallBack = null, id, dueDate) =>
    (dispatch, getState) => {
      const { } = getState().userManagement;

      let specificUserId = null;
      if (window.location.pathname === '/user-management/profile') {
        specificUserId = getState().userManagementV2.specificUserId;
      } else {
        specificUserId = getState().userManagement.specificUserId;
      }
      return Api.patch(`/user/unassignStudent/${specificUserId}`).send(
        (response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            dispatch(getStudentsDetailsById());
            // dispatch(getFeeOverView());
          }
        }
      );
    };

export const dismissPreferredCourse =
  (callback = null, successCallBack = null, id, dueDate) =>
    (dispatch, getState) => {

      let specificUserId = null;
      if (window.location.pathname === '/user-management/profile') {
        specificUserId = getState().userManagementV2.specificUserId;
      } else {
        specificUserId = getState().userManagement.specificUserId;
      }

      return Api.patch(`/user/dismissPrefferedCourse/${specificUserId}`).send(
        (response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            dispatch(getStudentsDetailsById());
            // dispatch(getFeeOverView());
          }
        }
      );
    };

export const editPaidTransaction =
  (callback = null, successCallBack = null, id, dueDate) =>
    (dispatch, getState) => {
      const { specificUserId, studentCourseDetails } = getState().userManagement;
      const { coursePrice } = getState().course;
      const { allActivitiesFilter, feeMangementData } = getState().feeManagement;

      const params = {
        transactionId: feeMangementData?.selectedTransaction?.id,
        amount: parseFloat(feeMangementData?.EditedTotalPay),
        note: feeMangementData?.editPaidDisc,
        discountDetails: JSON.stringify(studentCourseDetails?.scholarships),
        additionDetails: JSON.stringify(studentCourseDetails?.additions),
        paymentMethod: feeMangementData?.modeOfPay,
        chequeNo: feeMangementData?.generateOfflinePayCheckNumber,
        paymentDate: feeMangementData?.paidDate,
      };

      return Api.post(`/feev2/edit-offline-payment`)
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            dispatch(getStudentsDetailsById());
            dispatch(getFeeOverView());
          }
        });
    };

export const assignStudents = (userIds, Ids, callback, successCallBack) => {
  return () => {
    const params = {
      userIds: userIds,
      standardId: Ids
    }
    return Api.post(`/user/assign-class`).params(params).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response !== undefined) {
        if (successCallBack) {
          successCallBack();
        }
      }
    });
  }
}

export const UpdateRollNumber = (standardId) =>
  (dispatch, getState) => {
    const { studentRollNo } = getState().userManagementV2;


    let specificUserId = null;
    if (window.location.pathname === '/user-management/profile') {
      specificUserId = getState().userManagementV2.specificUserId;
    } else {
      specificUserId = getState().userManagement.specificUserId;
    }

    let params = {
      userId: specificUserId,
      rollNo: studentRollNo,
      standardId: standardId
    }

    return Api.patch(`/user/updateRollNo`).params(params).send(
      (response) => {
        if (response !== undefined) {
          if (response?.show?.type == 'success') {
            return Notification.success('success', response?.show?.message)
          }
        }
      }
    );
  };

export const getSearchedStudents =
  (callback = null) =>
    (dispatch, getState) => {
      const { search, pageNumber, pageLimit } = getState().userManagement;
      const params = {
        page: pageNumber,
        studentSearch: search,
        limit: pageLimit
      };
      return Api.get(`/user/searchStudent`)
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(storeSearchedResults(response));
          }
        });
    };

export const storeSearchedResults = (val) => ({
  type: STORE_SEARCHED_RESULTS,
  val
})

export const setPage = (val) => ({
  type: SET_PAGE,
  val
})

export const setLimit = (val) => ({
  type: SET_LIMIT,
  val
})

export const getAllUnassignedStudentCourses = () => {
  return (dispatch, getState) => {
    return Api.get(`/user/getUnassignedCourses`)
      .params({})
      .send((response) => {
        if (response !== undefined) {
          dispatch(fetchUnassignedCourses(response));
        }
      });
  };
};

export const fetchUnassignedCourses = (val) => ({
  type: FETCH_UNASSIGNED_COURSES,
  val
})

export const storeSelectedCourse = (val) => ({
  type: STORE_SELECTED_COURSE,
  val
})

export const storeSelectedDate = (val) => ({
  type: STORE_SELECTED_DATE,
  val
})

export const downloadBulkUploadTemplate = (res) => ({
  type: DOWNLOAD_TEMPLATE,
  res
})

export const storeDownloadTemplateId = (id) => ({
  type: STORE_EXCEL_FILEID,
  id
})

export const downloadTemplate = (callback) => {
  return (dispatch, getState) => {
    return Api.get(`/user/getExcel`)
      .params({})
      .send((response) => {
        if (response !== undefined) {
          if (callback) {
            callback();
          }
          console.log('res', response)
          // dispatch(storeDownloadTemplateId(response?.id))
          const fileName = 'excel-template.xlsx';
          Axios.get(response.fileName, {
            responseType: "blob",
          }).then((res) => {
            fileDownload(res.data, fileName);
          });
        }
      });
  };
};

export const storeExcelUploadDetails = (res) => ({
  type: STORE_EXCEL_DETAILS,
  res
})

export const storeExcelData = (res) => ({
  type: STORE_EXCEL_DATA,
  res
})

export const storePendingStatus = (res) => ({
  type: STORE_PENDING_USERS,
  res
})

export const uploadBulkUsers = (callback = null, successCallBack) =>
  (dispatch, getState) => {
    const { excelFileId, excelFile } = getState().userManagement;
    const params = {
      excel: excelFile
    };

    return Api.post("/user/addUsers").upload(params, (percent, response) => {
      if (response !== undefined) {
        console.log('respo', response)
        dispatch(storeDownloadTemplateId(response?.data?.fileId));
        dispatch(storePendingStatus(response?.data?.data/* ?.map((item)=>{
            item
          }) */))
        if (percent == 100) {
          callback();
          if (successCallBack) {
            successCallBack();
          }
        }
      }
    })
  };

export const fetchExcelData = (callback) => {

  return (dispatch, getState) => {
    const { excelFileId } = getState().userManagement;
    return Api.get(`/user/get-excel-status?fileId=${excelFileId}`).send((response) => {
      if (response !== undefined) {
        if (callback) {
          callback();
        }
        dispatch(storeExcelData(response));
      }
    });
  };
};

export const deleteExcelOnComplete = () =>
  (dispatch, getState) => {
    const { excelFileId } = getState().userManagement;
    return Api.delete(`/user/delete-excel-users?fileId=${excelFileId}`).send((response) => {
      if (response !== undefined) {

      }
    });
  };

export const storeStandards = (res) => ({
  type: STORE_STANDARDS,
  res
});

export const fetchStandards = (params) => {
  return (dispatch, getState) => {
    const url = `/user/standards`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeStandards(response));
        };
      })
    );
  };
};