import {
    SET_NUMBER_VERIFIED,
    SET_TAB_KEY,
    STORE_USER_KYC_STATUS,
    STORE_WALLET_DASHBOARD_DATA,
    STORE_USER_KYC_DATA,
    STORE_PAYMENT_REQ_MODAL_DATA,
    STORE_PAYMENT_HISTORY,
    STORE_TRANSACTION_MODAL_DATA,
    STORE_SUMMARY_MONTH,
    STORE_PAYMENT_HISTORY_PARAMS,
    STORE_PAYMENT_REQUESTS_PARAMS,
    SET_FROM_PAYMENT_HISTORY,
    STORE_WALLET_FEE_OVERVIEW_DATA,
    SET_SINGLE_BAR_DATA,
    SET_SUBMIT_CLICKED
} from '../actions/aulasWallet';

const initialState = {
    isNumberVerified: false,
    tabKey: "1",
    userKycStatus: "",
    walletDashboardData: [],
    userKycDataContainer: {},
    paymentReqModalData: [],
    paymentHistoryObj: { page: 1, paymentStartDate: null, paymentEndDate: null, paymentMode: null, paymentStatus: null, limit: 10, monthYear: [] },
    paymentRequestsObj: { page: 1, issueDate: null, dueDate: null, overdueStatus: null, limit: 10 },
    isFromPaymentHistory: false,
    paymentHistoryContainer: [],
    transactionModalData: [],
    summaryMonth: null,
    feeOverviewData: [],
    singleBarData: {},
    submitClicked:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NUMBER_VERIFIED:
            return {
                ...state,
                isNumberVerified: action.bool
            }
        case SET_TAB_KEY:
            return {
                ...state,
                tabKey: action.val
            }
        case STORE_USER_KYC_STATUS:
            return {
                ...state,
                userKycStatus: action.val
            }
        case STORE_WALLET_DASHBOARD_DATA:
            return {
                ...state,
                walletDashboardData: action.val
            }
        case STORE_USER_KYC_DATA:
            return {
                ...state,
                userKycDataContainer: action.val
            }
        case STORE_PAYMENT_REQ_MODAL_DATA:
            return {
                ...state,
                paymentReqModalData: action.val
            }
        case STORE_PAYMENT_HISTORY:
            return {
                ...state,
                paymentHistoryContainer: action.val
            }
        case STORE_TRANSACTION_MODAL_DATA:
            return {
                ...state,
                transactionModalData: action.val
            }
        case STORE_SUMMARY_MONTH:
            return {
                ...state,
                summaryMonth: action.val
            }
        case STORE_PAYMENT_HISTORY_PARAMS:
            let newPaymentHistoryObj = { ...state.paymentHistoryObj }
            newPaymentHistoryObj[action.key] = action.val;
            return {
                ...state,
                paymentHistoryObj: newPaymentHistoryObj,
            }
        case STORE_PAYMENT_REQUESTS_PARAMS:
            let newPaymentRequestsObj = { ...state.paymentRequestsObj }
            newPaymentRequestsObj[action.key] = action.val;
            return {
                ...state,
                paymentRequestsObj: newPaymentRequestsObj,
            }
        case SET_FROM_PAYMENT_HISTORY:
            return {
                ...state,
                isFromPaymentHistory: action.val
            }
        case STORE_WALLET_FEE_OVERVIEW_DATA:
            return {
                ...state,
                feeOverviewData: action.val
            }
        case SET_SINGLE_BAR_DATA:
            return {
                ...state,
                singleBarData: action.val
            }
        case SET_SUBMIT_CLICKED:
            return {
                ...state,
                submitClicked: action.val
            }
        default:
            return state;
    }
}

export default reducer;