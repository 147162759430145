
import { Dropdown, Image, Typography, Menu, Avatar, Tooltip } from 'antd'
import React, { useState } from 'react'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { FaGraduationCap } from 'react-icons/fa'
import { ClockCircleFilled, LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Api, Notification } from 'services'
import { setStreamId } from 'actions/broadcast'
import { joinClass, setBroadcastSelectedClassroom, setJoinLoader, fetchStudentListData, setCardData, setSelectedId, setLiveStreamUrl } from 'actions/schedule'
import { SquareSubIcon } from 'components/subIcon'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import moment from 'moment'
import Conference from "Assets/schedule/confrence.svg"
import Broadcast from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import { handleJoinClass } from './handleJoinClass'
import { EventRegister } from 'react-native-event-listeners'
import { EmbedModal, regexMeet } from './onGoingClassCard'
import { postEmbedLink } from 'actions/broadcast'
import OtherColor from "Assets/schedule/other_color.svg"
import { Button } from 'components/Button'

const { Text } = Typography
const OnGoingClassTandS = ({ user, onGoingItem, setStreamId, joinClass, setBroadcastSelectedClassroom, setJoinLoader, isJoinLoader, 
    fetchStudentListData, setCardData, setSelectedId, postEmbedLink, setLiveStreamUrl }) => {
    const history = useHistory()
    const handleContact = () => {
        history.push("/care/chat")
    }
    const [embedModalVisible, setEmbedModalVisible] = useState(false);
    const [embedding, setEmbedding] = useState(false);
    
    const playStream = () => {
        if (onGoingItem.sUrl.match(regexMeet)){
            window.open(onGoingItem.sUrl, '_blank');
        }else{
            setLiveStreamUrl(onGoingItem.sUrl)
            history.push('/liveplayer')
        }
    }
  
  
    const onEmbed = async(link) => {
      setEmbedding(true);
      await postEmbedLink({id:onGoingItem.id, sUrl: link})
      setEmbedding(false);
      setEmbedModalVisible(false);
      EventRegister.emit('apicall:scheduleHome');
    }
    const markAttendanceHandler = () => {
        setSelectedId(onGoingItem?.id)
        setCardData(onGoingItem)
        fetchStudentListData()
        history.push('/schedule-attendance')

    }

    const handleJoin = async () => {
        setJoinLoader(true);
        setCardData(onGoingItem);
        if (onGoingItem?.mode == "Broadcast") {
            let currentTime = new Date();
            if (onGoingItem.endTime){
                // Parse end time
                const [hours, minutes, seconds] = onGoingItem.endTime.split(':').map(Number);
                let endDateTime = new Date();
                endDateTime.setHours(hours, minutes, 60, 0);

                // Calculate the difference in milliseconds
                let differenceInMilliseconds = endDateTime - currentTime;
                let differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
                if (differenceInSeconds<0){
                    Notification.error("Error", "Class end time has passed")
                    setJoinLoader(false)
                    return
                }
            }
            setStreamId(onGoingItem?.roomName != null ? onGoingItem.roomName : '1234')
            setBroadcastSelectedClassroom(onGoingItem)
            await joinClass(onGoingItem.id)
            history.push('/broadcast')
        }
        else if (onGoingItem?.mode == "Zoom 40") {
            await joinClass(onGoingItem.id)
            setJoinLoader(false)
            // history.push('/zoom')
            Notification.error("Error", "Zoom is not supported on web presently. Please join the class from the mobile.")
        } else if (onGoingItem?.mode == "Conference" || onGoingItem?.mode == "Offline") {
            setJoinLoader(false)
            Notification.error("Error", "This mode is not supported on web presently")
        }else if(onGoingItem?.mode == "Others") {
            setJoinLoader(false);
            setEmbedModalVisible(true);
        }

    }
    const menu = (
        <Menu style={{ width: "230px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            {/* <Menu.Item key="1" onClick={() => handleContact()}>
                Contact Your Admin
            </Menu.Item> */}
            {
                user.access.includes("schedule-teacher") && user.id === onGoingItem.teacherId ?
                <>
                <Menu.Item key="2" onClick={()=>{handleJoin()}}>
                    {onGoingItem.sUrl!=undefined?'Replace link':'Add link'} 
                </Menu.Item>
                {onGoingItem?.sUrl != undefined ?
                <Menu.Item key="3" onClick={()=>{playStream()}}>
                    Play stream
                </Menu.Item>
                :null}
                </>
            :null}
        </Menu>

    );
    if (user.access.includes("schedule-teacher")) {
        return (
            <div className="OnGoingClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="ongoingclass-img-text">
                        <div>
                            <SquareSubIcon icon={onGoingItem?.subject?.icon ? onGoingItem?.subject?.icon : ''} name={onGoingItem?.subject?.name ? onGoingItem?.subject?.name : ''} size={40} />

                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {onGoingItem.mode == "Zoom 40" ?
                                <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                :onGoingItem.mode == "Broadcast" ?
                                    <div style={{ width: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "18px", width: "18px" }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :
                                    onGoingItem.mode == "Conference" ?
                                        <div style={{ width: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Conference} style={{ color: '#594099', height: "18px", width: "18px" }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                        onGoingItem.mode == 'Others' ?
                                        <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                            <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                            <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                        </div>
                                        :
                                        <div style={{ width: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "18px", width: "18px" }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>
                            }
                            <Tooltip title={onGoingItem?.subject?.name ? onGoingItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{onGoingItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>
                    {!user.privilage.includes('Institute Support') && onGoingItem.mode==='Others' ?
                        <div>
                            <Dropdown overlay={menu} disabled={false} placement="bottomRight" >
                                <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                            </Dropdown>
                        </div> : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Tooltip title={onGoingItem?.standards.length != 0 ? onGoingItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                            <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
                        </Tooltip>
                        {onGoingItem?.standards.length != 0 ? onGoingItem?.standards?.map((item, index, array) => (
                            <Text style={{ fontSize: '80%', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} key={index}>{item?.std + "-" + item?.section}{index === array.length - 1 ? '' : '|'}</Text>
                        )) : null}
                    </div>
                    {/* <div>
                        <Avatar
                            style={{ backgroundColor: '#594099' }}
                            size={14}
                            icon={<UserOutlined />}
                        />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>25 Online</Text>
                    </div> */}
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title={`${moment(onGoingItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(onGoingItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}>
                            <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        </Tooltip>
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{`${moment(onGoingItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(onGoingItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                    {onGoingItem?.mode === 'Others'?null:
                    (onGoingItem?.mode === 'Offline') && (user?.role?.includes('Teacher')) && (onGoingItem?.teacher?.id === user?.id) ?
                        <div>
                            <Button type='primary' style={{ width: '190px', borderRadius: '8px' }}
                                onClick={() => markAttendanceHandler()}
                                disabled={isJoinLoader}
                                loading={isJoinLoader}
                            >{onGoingItem?.attendanceMarked ? `${onGoingItem?.attendanceData?.presentCount}/${onGoingItem?.attendanceData?.studentCount}` : 'MARK ATTENDANCE'}</Button>
                        </div>
                        :
                        <div>
                            <Button type='primary' style={{ width: '190px', borderRadius: '8px' }}
                                onClick={() => handleJoin()}
                                disabled={isJoinLoader}
                                loading={isJoinLoader}
                            >Join Now</Button>
                        </div>
                    }
                </div>
                {embedModalVisible ? <EmbedModal adding={onGoingItem.sUrl==undefined} embedding={embedding} visible={embedModalVisible} onClose={()=>{setEmbedModalVisible(false)}} onOk={onEmbed}/>:null}
            </div>
        )
    }
    else if (user?.access?.includes("schedule-student")) {
        return (
            <div className="OnGoingClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="ongoingclass-img-text">
                        <div>
                            <SquareSubIcon icon={onGoingItem?.subject?.icon ? onGoingItem?.subject?.icon : ''} name={onGoingItem?.subject?.name ? onGoingItem?.subject?.name : ''} size={40} />

                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {onGoingItem.mode == "Zoom 40" ?
                                <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                :onGoingItem.mode == "Broadcast" ?
                                    <div style={{ width: '90px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "18px", width: "18px" }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :onGoingItem.mode == "Conference" ?
                                        <div style={{ width: '77px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Conference} style={{ color: '#594099', height: "18px", width: "18px" }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                        onGoingItem.mode == 'Others' ?
                                        <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                            <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                            <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                        </div>
                                        :
                                        <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "18px", width: "18px" }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>
                            }
                            <Tooltip title={onGoingItem?.subject?.name ? onGoingItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{onGoingItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Avatar shape='circle' size='small' src={`${Api._s3_url}${onGoingItem?.teacher?.image}`} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{onGoingItem?.teacher?.name}</Text>
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(onGoingItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(onGoingItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
                {onGoingItem?.mode === 'Others' && onGoingItem.sUrl == undefined?
                null:
                (onGoingItem?.mode !== 'Offline') ?
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                        <Button type='primary' style={{ width: '190px', borderRadius: '8px' }}
                            onClick={() => {
                                if (onGoingItem.mode==='Others'){
                                    playStream();
                                }else{
                                    handleJoin();
                                }
                            }}
                            loading={isJoinLoader}
                            disabled={isJoinLoader}
                        >{onGoingItem?.mode!='Others'? 'Join Now':'Play Stream'}</Button>
                    </div>
                    :
                    null
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        isJoinLoader
    } = state.schedule;
    const { user } = state.session;
    return {
        user,
        isJoinLoader
    };
};

const mapDispatchToProps = (dispatch) => ({
    setStreamId: (streamId) => dispatch(setStreamId(streamId)),
    joinClass: (id) => dispatch(joinClass(id)),
    setBroadcastSelectedClassroom: (newClass) => dispatch(setBroadcastSelectedClassroom(newClass)),
    setJoinLoader: (bool) => dispatch(setJoinLoader(bool)),
    fetchStudentListData: (id) => dispatch(fetchStudentListData(id)),
    setSelectedId: (id) => dispatch(setSelectedId(id)),
    setCardData: (value) => dispatch(setCardData(value)),
    postEmbedLink: (params, c, sc) => dispatch(postEmbedLink(params, c, sc)),
    setLiveStreamUrl: (url) => dispatch(setLiveStreamUrl(url))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(OnGoingClassTandS);


