import { Modal } from 'antd'
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react'

function ConfirmationModal({ isConfirmModalVisible, setIsConfirmModalVisible, postToggleCourseAdmissions, togglePageLoader, getSpecificCourseData, admissionState, unassignStudents, selectedStudentsName, tab, courseName, setIsUnassignStudentsModalVisible, postUnassignStudentsFromCourse, selectedStudents, getCourseStudentsData, setSelectedStudents, isSendReminderClicked, postSendReminder, singleFeeItem, isRevokeCancelClicked, isCancelRequestClicked, postCancelTransactionRequest, revokeCancelledInstallment }) {
    const handleCancel = () => {
        setIsConfirmModalVisible(false);
        if (unassignStudents) {
            setIsUnassignStudentsModalVisible(true)
        }
    }
    const [btnLoader, setBtnLoader] = useState(false);

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isConfirmModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Confirm</center>
            {isSendReminderClicked || isCancelRequestClicked || isRevokeCancelClicked ?
                <center style={{ color: "#636363", fontSize: 16, marginTop: 5, padding: "0px 20px" }}> Are you sure you want to {isRevokeCancelClicked ? "revoke cancelled installment" : isCancelRequestClicked ? "cancel installment request" : "send reminder"} ?</center>
                :
                unassignStudents ?
                    <center style={{ color: "#636363", fontSize: 16, marginTop: 5, padding: "0px 20px" }}>
                        <p>Are you sure you want to unassign <span style={{ fontWeight: "bold" }}>{selectedStudentsName}</span> from <span style={{ fontWeight: "bold" }}>{tab == 2 ? courseName : /* `${selectedSection?.std} : ${selectedSection?.section}` */ ""}</span>?</p>
                    </center>
                    :
                    <center style={{ color: "#636363", fontSize: 16, marginTop: 5, padding: "0px 20px" }}> Are you sure you want to {admissionState ? "allow" : "stop"} admissions to this course ? </center>
            }
            <center>
                <div style={{ display: "flex", justifyContent: "space-around", width: "75%", marginTop: 60 }}>
                    <Button
                        type="link"
                        loading={btnLoader}
                        style={{ color: "#FF414D", fontSize: 16 }}
                        onClick={() => {
                            setBtnLoader(true);
                            if (isSendReminderClicked) {
                                postSendReminder(
                                    { userId: singleFeeItem?.transaction?.userId, transactionId: singleFeeItem?.transaction?.id },
                                    () => setBtnLoader(false),
                                    () => {
                                        setIsConfirmModalVisible(false);
                                        togglePageLoader(true);
                                        getCourseStudentsData(() => togglePageLoader(false))
                                    })
                            } else if (isRevokeCancelClicked) {
                                revokeCancelledInstallment(
                                    singleFeeItem?.transaction?.id,
                                    () => setBtnLoader(false),
                                    () => {
                                        setIsConfirmModalVisible(false);
                                        togglePageLoader(true);
                                        getCourseStudentsData(() => togglePageLoader(false))
                                    }
                                )
                            } else if (isCancelRequestClicked) {
                                postCancelTransactionRequest(
                                    singleFeeItem?.transaction?.id,
                                    () => setBtnLoader(false),
                                    () => {
                                        setIsConfirmModalVisible(false);
                                        togglePageLoader(true);
                                        getCourseStudentsData(() => togglePageLoader(false))
                                    })
                            } else if (unassignStudents) {
                                if (tab == 2) {
                                    postUnassignStudentsFromCourse(
                                        selectedStudents,
                                        () => setBtnLoader(false),
                                        () => {
                                            setSelectedStudents([])
                                            setIsConfirmModalVisible(false);
                                            togglePageLoader(true);
                                            getCourseStudentsData(() => togglePageLoader(false))
                                        }
                                    )
                                }
                            } else {
                                postToggleCourseAdmissions(
                                    !admissionState,
                                    () => setBtnLoader(false),
                                    () => {
                                        setIsConfirmModalVisible(false)
                                        togglePageLoader(true);
                                        getSpecificCourseData(() => togglePageLoader(false))
                                    }
                                )
                            }
                        }}>
                        YES
                    </Button>
                    <Button type="primary" onClick={() => { handleCancel() }}>NO</Button>
                </div>
            </center>
        </Modal>
    )
}

export default ConfirmationModal