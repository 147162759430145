import React, { useEffect } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { setEditDateOfBirth } from "actions/userManagement";
// import { setInstallments } from "actions/course";

function StartDate_Picker({
  value,
  setCourseStartDate,
  disabledate,
  userDetailsById
}) {
  function onChange(date, dateString) {
    if (
      moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setCourseStartDate(moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
    } else {
    }

  }
  function disabledDate(current) {
    // Can not select days before today and today
    // if (installments?.length) {
    //   return current > installments[index - 1] ? true : false;
    // console.log(
    //   "datepic",
    //   !(current && current > moment()),
    //   moment() < current,
    //   current < moment().add(10, "days")
    //   // current
    // );
    // }
    // return !(current && current > moment());
    let data=userDetailsById?.student?.courses;
    let date=null;
    for(let i=0;i<data?.length;i++){
        if(data[i]?.id==disabledate){
            date=data[i]?.startDate;
        }

    }
    // console.log(date)
    if(current.format('YYYY-MM-DD')<date){
        return true;
    }
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={50}>
      <DatePicker
        allowClear={false}
        className="datePicker"
        onChange={onChange}
        value={
          value !== null
            ? moment(
                moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                "DD-MM-YYYY"
              )
            : ""
        }
        placeholder={["Select a date"]}
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
      />
    </Space>
  );
}

const mapStateToProps = (state) => {
  const {userDetailsById} = state.userManagement;
  return {userDetailsById};
};

const mapDispatchToProps = (dispatch) => ({
  setEditDateOfBirth: (date) => dispatch(setEditDateOfBirth(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartDate_Picker);
