// import React from 'react'

// function quesAndAnswer() {
//   return (
//     <div>quesAndAnswer</div>
//   )
// }

// export default quesAndAnswer

import { setAllFalse } from 'actions/schedule';
import TeX from '@matejmazur/react-katex';
import { Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Api } from 'services';

const QuesAndAnswer = ({ showTextEditor,
  generateQuesText,
  editGenerateQueId,
  generateQuesArr,
  chooseCorrectAnswer,
  generateQuesOptions,
  generateQuesNumericText,
  updateNumericAnswer,
  renderEditorContent,
  numericAnswer,
  generateQuesObj,
  generateQuesSelectedTestType,
  questionContent, schemeId, modifyOptions,
  answerContent, addMarksForQues, positiveMark, negativeMark,
  chooseBoolAnswer, orIndex, compIndex, paragraphText, sectionIndexStore, storeQuestionIndex, nonEditable, solutionContent, compQuestionIndicator, questionIndexStore, setImageViewerVisibility, setImgUrl, orQuestionIndexChar }) => {

  console.log('questionContent', questionContent, answerContent);
  console.log('+veM', positiveMark)
  console.log('-veM', negativeMark)

  const Row4 = ({ showTextEditor, generateQuesText, statement, editGenerateQueId, generateQuesArr, compIndex, orIndex, sectionIndexStore, storeQuestionIndex, nonEditable, compQuestionIndicator, questionIndexStore, addMarksForQues, positiveMark, negativeMark, schemeId, setImgUrl, setImageViewerVisibility }) => {
    if (editGenerateQueId == '' || editGenerateQueId == undefined) {

    } else {
      generateQuesArr?.subjects?.forEach(subject => {
        if (subject?.includeSectionFlag && subject?.includeSectionFlag !== 0) {
          subject?.sections[sectionIndexStore]?.questions?.forEach((question, index) => {
            if (question.id === editGenerateQueId) {
              storeQuestionIndex(question.index)
            }
          })
        }
        else {
          subject?.questions?.forEach((question) => {
            if (question.id === editGenerateQueId) {
              storeQuestionIndex(question.index)
            }
          })

        }

      });
    }
    const showTextEditorHandler = () => {
      if (compIndex !== null && compIndex !== undefined) {
        showTextEditor({ key: 'question', index: 0, tags: statement }, compIndex, 'Comp')
      } else if (orIndex !== null && orIndex !== undefined) {
        showTextEditor({ key: 'question', index: 0, tags: statement }, orIndex, 'Or')
      } else {
        showTextEditor({ key: 'question', index: 0, tags: statement })
      }
    }

    const MarksSection = () => {
      return (
        <div>
          <div className='r-ac m-b-10'>
            <div className='text-xmd bold-600 color-black m-r-5' style={{ color: '#636363', fontSize: '12px' }}>Marks</div>
            <Tooltip placement="right" trigger="hover" title={
              <div>
                <div className='r-ac'>
                  <div className='radius-100' style={{ width: 10, height: 10, background: '#6BDB9E' }}></div>
                  <div className='text-xmd color-black m-l-10'>Positive Marks</div>
                </div>
                <div className='r-ac'>
                  <div className='radius-100' style={{ width: 10, height: 10, background: '#ec5254' }}></div>
                  <div className='text-xmd color-black m-l-10'>Negative Marks</div>
                </div>
              </div>
            } overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px", background: '#fff' }}>
              <ExclamationCircleOutlined style={{ color: "#1089FF" }} className='text-xs cursor-pointer' />
            </Tooltip>
          </div>
          <div className='r-ac'>
            <div className='r-c-c test-count-seq-box' style={{ height: '25px', width: '50px' }} >
              <AiOutlinePlus onClick={() => schemeId > 0 ? null : addMarksForQues('increment', 'positive', compIndex)} className='cursor-pointer' />
              <div className='text-xmd m-l-5 m-r-5'>{positiveMark}</div>
              <AiOutlineMinus onClick={() => schemeId > 0 ? null : addMarksForQues('decrement', 'positive', compIndex)} className='cursor-pointer' />
            </div>
            <div className='r-c-c test-count-seq-box m-l-10' style={{ background: '#FFE4D9', borderColor: '#EC5254', height: '25px', width: '50px' }}>
              <AiOutlinePlus onClick={() => schemeId > 0 ? null : addMarksForQues('increment', 'negative', compIndex)} className='cursor-pointer' />
              <div className='text-xmd m-l-5 m-r-5'>{negativeMark}</div>
              <AiOutlineMinus onClick={() => schemeId > 0 ? null : addMarksForQues('decrement', 'negative', compIndex)} className='cursor-pointer' />
            </div>
          </div>
        </div>
      )
    }
    // console.log('para',paragraphText.length)
    return (
      <div className='p-20'>
        {compQuestionIndicator
          ?
          <div className='m-b-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {' '} {questionIndexStore}{compIndex !== null && compIndex !== undefined ? `(${String.fromCharCode('a'.charCodeAt() + compIndex)})` : null}</div>
            <MarksSection />
          </div>
          :
          <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {' '} {questionIndexStore}{compIndex !== null && compIndex !== undefined ? `(${String.fromCharCode('a'.charCodeAt() + compIndex)})` : null}{orQuestionIndexChar}</div>
        }
        <div onClick={(e) => {
          if (!nonEditable) {
            console.log('e.targett', e.target.children)
            showTextEditorHandler()
          }
        }}
          className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
          style={{ overflowX: "hidden" }}>
          {!statement || statement === "" || statement === '<p><br></p>' ? <div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Question</div>
            : ReactHtmlParser(statement,
              {
                transform: (node) => {
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return (<img className='imgDiv' onClick={(e) => {
                      e.stopPropagation()
                      setImgUrl(`${node.attribs.src}`)
                      setImageViewerVisibility(true);
                    }} style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />);
                  }
                }
              })
          }
        </div>
      </div>
    )
  }

  const Row5 = ({ showTextEditor, options, chooseCorrectAnswer, compIndex, orIndex, nonEditable, modifyOptions, setImgUrl, setImageViewerVisibility }) => {

    // const showTextEditorHandler = (index,tag)=>{
    //   if(compIndex !== null && compIndex !== undefined){
    //     showTextEditor({ key: 'options', index: index, tags: tag},compIndex,'Comp')
    //   }else if(orIndex !== null && orIndex !== undefined){
    //     showTextEditor({ key: 'options', index: index, tags: tag},orIndex,'Or')
    //   }else{
    //     showTextEditor({ key: 'options', index: index, tags: tag})
    //   }
    // }

    return (
      <div className='p-l-20 p-r-20'>
        <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Options</div>
        {options?.map((item, index) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='r-ac m-b-10' style={{ width: '93%' }} key={index}>
              <div
                style={{ background: item.correct ? '#28df99' : '', color: item.correct ? '#fff' : '', borderColor: item.correct ? '#28df99' : '' }}
                className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : index === 3 ? 'D' : 'E'}
              </div>
              <div
                style={{ background: item.correct ? '#E9FCF5' : '', width: "100%", overflowX: "hidden" }}
                className='cursor-pointer test-ques-window p-10 r-c-sb'
                onClick={() => {
                  if (!nonEditable) {
                    if (compIndex !== null && compIndex !== undefined) {
                      showTextEditor({ key: 'options', index: index, tags: item.value }, compIndex, 'Comp')
                    } else if (orIndex !== null && orIndex !== undefined) {
                      showTextEditor({ key: 'options', index: index, tags: item.value }, orIndex, 'Or')
                    } else {
                      showTextEditor({ key: 'options', index: index, tags: item.value })
                    }
                  }
                }
                }
              >
                <div className='resize-editor-img' style={{ overflowX: 'hidden', maxWidth: '850px' }} >
                  {!item.value || item.value === "" || item.value === '<p><br></p>' ? <div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Option {index + 1}</div>
                    : ReactHtmlParser(item.value,
                      {
                        transform: (node) => {
                          if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                            return <TeX math={`${node.attribs['data-value']}`} />;
                          }
                          if (node.type === 'tag' && node.name === 'img') {
                            return (<img className='imgDiv' onClick={(e) => {
                              e.stopPropagation()
                              setImgUrl(`${node.attribs.src}`)
                              setImageViewerVisibility(true);
                            }} style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />);
                          }
                        }
                      })
                  }
                </div>
                <div onClick={(e) => {
                  if (!nonEditable) {
                    e.stopPropagation();
                    if (orIndex !== null && orIndex !== undefined) {
                      if (generateQuesSelectedTestType.children === 'MSQ') {
                        chooseCorrectAnswer('MSQ', index, orIndex)
                      } else if (generateQuesSelectedTestType.children === 'MCQ') {
                        chooseCorrectAnswer('MCQ', index, orIndex)
                      }
                    } else if (compIndex !== null && compIndex !== undefined) {
                      if (generateQuesSelectedTestType.children === 'CMSQ') {
                        chooseCorrectAnswer('CMSQ', index, compIndex)
                      } else if (generateQuesSelectedTestType.children === 'CMCQ') {
                        chooseCorrectAnswer('CMCQ', index, compIndex)
                      }
                    } else {
                      if (generateQuesSelectedTestType.children === 'MSQ') {
                        chooseCorrectAnswer('MSQ', index);
                      } else {
                        chooseCorrectAnswer('MCQ', index);
                      }
                    }
                  }
                }} keys={index.toString()} className='r-ac m-l-5'>
                  {item.correct
                    ?
                    <RiCheckboxCircleLine className='text-sm seaGreen' />
                    :
                    <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                  }
                  <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                if (!nonEditable) {
                  if (options?.length > 1) {
                    if (compIndex !== null && compIndex !== undefined) {
                      modifyOptions(index, 'delete', compIndex, 'Comp')
                    } else if (orIndex !== null && orIndex !== undefined) {
                      modifyOptions(index, 'delete', orIndex, 'Or')
                    } else {
                      modifyOptions(index, 'delete')
                    }
                  }
                }
              }}
              style={{ marginTop: '1%' }}
              className='r-c-c radius-100 color-black test-circle-avatar m-r-5 m-l-10'>
              {'X'}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const RowTrueFalse = ({ answerContent, chooseCorrectAnswer, chooseBoolAnswer, orIndex, compIndex, nonEditable }) => {
    console.log('tffff', answerContent)
    return (
      <div className='p-l-20 p-r-20'>
        <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Options</div>
        <div className='r-ac m-b-10'>
          <div
            style={{ background: answerContent.answer === true ? '#28df99' : '', color: answerContent.answer === true ? '#fff' : '', borderColor: answerContent.answer === true ? '#28df99' : '' }}
            className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
            {'A'}
          </div>
          <div
            style={{ background: answerContent.answer === true ? '#E9FCF5' : '', width: "100%", overflowX: "hidden" }}
            className='cursor-pointer test-ques-window p-10 r-c-sb'
          ><div>
              True
            </div>
            <div onClick={(e) => {
              if (!nonEditable) {
                e.stopPropagation();
                if (orIndex !== null && orIndex !== undefined) {
                  console.log('indexxxt', orIndex)
                  chooseBoolAnswer('true', true, orIndex);
                } else if (compIndex !== null && compIndex !== undefined) {
                  chooseBoolAnswer('true', true, compIndex);
                } else {
                  chooseBoolAnswer('true', true);
                }
              }

            }} className='r-ac m-l-5'>
              {answerContent.answer === true
                ?
                <RiCheckboxCircleLine className='text-sm seaGreen' />
                :
                <RiCheckboxBlankCircleLine className='text-sm a2grey' />
              }
              <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
            </div>
          </div>
        </div>
        <div className='r-ac m-b-10'>
          <div
            style={{ background: answerContent.answer === false ? '#28df99' : '', color: answerContent.answer === false ? '#fff' : '', borderColor: answerContent.answer === false ? '#28df99' : '' }}
            className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
            {'B'}
          </div>
          <div
            style={{ background: answerContent.answer === false ? '#E9FCF5' : '', width: "100%", overflowX: "hidden" }}
            className='cursor-pointer test-ques-window p-10 r-c-sb'
          >
            <div>
              False
            </div>
            <div onClick={(e) => {
              if (!nonEditable) {
                e.stopPropagation();
                if (orIndex !== null && orIndex !== undefined) {
                  console.log('indexxxf', orIndex)
                  chooseBoolAnswer('false', false, orIndex);
                } else if (compIndex !== null && compIndex !== undefined) {
                  chooseBoolAnswer('false', false, compIndex);
                } else {
                  chooseBoolAnswer('false', false);
                }
              }
            }} className='r-ac m-l-5'>
              {answerContent.answer === false
                ?
                <RiCheckboxCircleLine className='text-sm seaGreen' />
                :
                <RiCheckboxBlankCircleLine className='text-sm a2grey' />
              }
              <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // const RowNumeric = ({ showTextEditor, generateQuesNumericText, updateNumericAnswer, renderEditorContent, numericAnswer, orIndex, compIndex, answerContent, nonEditable }) => {
  //   // const isValidNumeric = (val) => {
  //   //   let pattern1 = /[^\d.-]+/;
  //   //   let pattern2 = /^[^.]+(\.{0}[^.]*|\.{1}[^.]{0,})$/;
  //   //   let pattern3 = /[^-]+[-]+/;
  //   //   let pattern4 = /[-]{2,}/
  //   //   return val==="" || (pattern2.test(val) && !pattern1.test(val) && !pattern3.test(val) && !pattern4.test(val));
  //   // }
  //   const updateNumeric = (val, index) => {
  //     updateNumericAnswer(val, index)
  //   }

  //   // onChange={(e) => {
  //   //   // if (isValidNumeric(e.target.value)){
  //   //     if(orIndex !==null && orIndex!== undefined){
  //   //       console.log('orI',orIndex)
  //   //       updateNumericAnswer(e.target.value,orIndex)
  //   //     }else if(compIndex !==null && compIndex!== undefined){
  //   //       console.log('compI',compIndex)
  //   //       updateNumericAnswer(e.target.value,compIndex)
  //   //     }else{
  //   //       updateNumericAnswer(e.target.value)
  //   //     }
  //   //   // }
  //   // }}

  //   // const [answerContentState,setAnswerContentState]=useState(answerContent)

  //   // () => {
  //   //   // if (isValidNumeric(e.target.value)){
  //   //     if(orIndex !==null && orIndex!== undefined){
  //   //       console.log('orI',orIndex)
  //   //       updateNumericAnswer(numericValue,orIndex)
  //   //     }else if(compIndex !==null && compIndex!== undefined){
  //   //       console.log('compI',compIndex)
  //   //       updateNumericAnswer(numericValue,compIndex)
  //   //     }else{
  //   //       updateNumericAnswer(numericValue)
  //   //     }
  //   //   // }
  //   // }

  //   const [number, setNumber] = useState(numericAnswer)

  //   return (
  //     <div className='p-20 '>
  //       <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Answer</div>
  //       <Input
  //         disabled={!nonEditable ? false : true}
  //         placeholder="Type the answer here..."
  //         // value={numericAnswer}
  //         value={number}
  //         onChange={(e) => {
  //           // if (isValidNumeric(e.target.value)){
  //           // console.log('value', e.target.value)
  //           // setNumber(e.target.value)
  //           if (!nonEditable) {
  //             e.stopPropagation()
  //             if (orIndex !== null && orIndex !== undefined) {
  //               console.log('orI', orIndex)
  //               setNumber(e.target.value)
  //               updateNumericAnswer(e.target.value, orIndex)
  //             } else if (compIndex !== null && compIndex !== undefined) {
  //               console.log('compI', compIndex)
  //               updateNumericAnswer(e.target.value, compIndex)
  //               setNumber(e.target.value)
  //             } else {
  //               updateNumericAnswer(e.target.value)
  //               setNumber(e.target.value)
  //             }
  //           }
  //           // }
  //         }}
  //         type={'number'}
  //         style={{ borderRadius: 5 }}
  //       />
  //     </div>
  //   )
  // }

  const RowSolution = ({ showTextEditor, solutionContent, compIndex, orIndex, nonEditable, setImgUrl, setImageViewerVisibility }) => {

    console.log('solutionContent', solutionContent)
    console.log('compIndex', compIndex)
    console.log('orIndex', orIndex)
    console.log('nonEditable', nonEditable)
    return (
      <div className='p-20 m-b-50'>
        <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Solution</div>
        <div onClick={() => {
          if (!nonEditable) {
            if (compIndex !== null && compIndex !== undefined) {
              showTextEditor({ key: 'solution', index: 0, tags: solutionContent }, compIndex, 'Comp')
            } else if (orIndex !== null && orIndex !== undefined) {
              showTextEditor({ key: 'solution', index: 0, tags: solutionContent }, orIndex, 'Or')
            } else {
              showTextEditor({ key: 'solution', index: 0, tags: solutionContent })
            }
          }
        }}
          className='cursor-pointer test-ques-window resize-editor-img p-10'
          style={{ width: "100%", overflowX: "hidden" }}>
          {!solutionContent || solutionContent === "" || solutionContent === '<p><br></p>' ? <div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Solution</div>
            : ReactHtmlParser(solutionContent,
              {
                transform: (node) => {
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return (<img className='imgDiv' onClick={(e) => {
                      e.stopPropagation()
                      setImgUrl(`${node.attribs.src}`)
                      setImageViewerVisibility(true);
                    }} style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />);
                  }
                }
              })
          }
        </div>
      </div>
    )
  }

  console.log('testing', questionContent)

  return (
    <div>
      <Row4 showTextEditor={showTextEditor}
        statement={questionContent.statement}
        generateQuesText={generateQuesText}
        editGenerateQueId={editGenerateQueId}
        generateQuesArr={generateQuesArr}
        compIndex={compIndex}
        orIndex={orIndex}
        sectionIndexStore={sectionIndexStore}
        storeQuestionIndex={storeQuestionIndex}
        nonEditable={nonEditable}
        compQuestionIndicator={compQuestionIndicator}
        questionIndexStore={questionIndexStore}
        addMarksForQues={addMarksForQues}
        positiveMark={positiveMark}
        negativeMark={negativeMark}
        schemeId={schemeId}
        setImageViewerVisibility={setImageViewerVisibility}
        setImgUrl={setImgUrl} />
      {generateQuesObj.testType === 'Subjective'
        ?
        <RowSolution showTextEditor={showTextEditor} solutionContent={solutionContent} nonEditable={nonEditable} setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} />
        :
        Object.keys(generateQuesSelectedTestType)?.length
          ?
          generateQuesSelectedTestType.children === 'MCQ' || generateQuesSelectedTestType.children === 'MSQ' || generateQuesSelectedTestType.children === 'CMCQ' || generateQuesSelectedTestType.children === 'CMSQ'
            ?
            <div>
              <Row5
                showTextEditor={showTextEditor}
                chooseCorrectAnswer={chooseCorrectAnswer}
                // options={questionContent.orQuestions?questionContent.orQuestions[orIndex][options]:questionContent.subQuestions?questionContent.subQuestions[compIndex][options]:questionContent.options}
                options={questionContent.options}
                orIndex={orIndex}
                compIndex={compIndex}
                nonEditable={nonEditable}
                modifyOptions={modifyOptions}
                setImageViewerVisibility={setImageViewerVisibility}
                setImgUrl={setImgUrl} />
              <RowSolution showTextEditor={showTextEditor} solutionContent={solutionContent} compIndex={compIndex} orIndex={orIndex} nonEditable={nonEditable} setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} />
            </div>
            :
            generateQuesSelectedTestType.children === 'T/F' || generateQuesSelectedTestType.children === 'CTF'
              ?
              <div>
                <RowTrueFalse
                  answerContent={answerContent}
                  chooseCorrectAnswer={chooseCorrectAnswer}
                  chooseBoolAnswer={chooseBoolAnswer}
                  orIndex={orIndex}
                  compIndex={compIndex}
                  nonEditable={nonEditable} />
                <RowSolution showTextEditor={showTextEditor} solutionContent={solutionContent} compIndex={compIndex} orIndex={orIndex} nonEditable={nonEditable} setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} />
              </div>
              :
              <div>
                {/* <RowNumeric showTextEditor={showTextEditor}
                  generateQuesNumericText={generateQuesNumericText}
                  updateNumericAnswer={updateNumericAnswer}
                  renderEditorContent={renderEditorContent}
                  numericAnswer={answerContent?.answer}
                  orIndex={orIndex}
                  compIndex={compIndex}
                  nonEditable={nonEditable}
                /> */}
                <div className='p-20 '>
                  <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Answer</div>
                  <Input
                    disabled={!nonEditable ? false : true}
                    placeholder="Type the answer here..."
                    value={answerContent?.answer}
                    // value={number}
                    onChange={(e) => {
                      if (!nonEditable) {
                        e.stopPropagation()
                        if (orIndex !== null && orIndex !== undefined) {
                          console.log('orI', orIndex)
                          // setNumber(e.target.value)
                          updateNumericAnswer(e.target.value, orIndex)
                        } else if (compIndex !== null && compIndex !== undefined) {
                          console.log('compI', compIndex)
                          updateNumericAnswer(e.target.value, compIndex)
                          // setNumber(e.target.value)
                        } else {
                          updateNumericAnswer(e.target.value)
                          // setNumber(e.target.value)
                        }
                      }
                      // }
                    }}
                    type={'number'}
                    style={{ borderRadius: 5 }}
                  />
                </div>
                <RowSolution showTextEditor={showTextEditor} solutionContent={solutionContent} compIndex={compIndex} orIndex={orIndex} nonEditable={nonEditable} setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} />
              </div>

          :
          null
      }

    </div>
  )
}

export default QuesAndAnswer