import React,{useEffect, useState}from 'react'
import { Modal,Typography,Select} from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchTeacherData,postTeachersArray} from 'actions/digitalLibrary'
import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'components/Button'

const {Text} = Typography
const {Option}=Select


const AddTeacherFormView = ({
  visibleForm, setVisibleForm,fetchTeacherData,teacherMangementList,selectedSubject,selectedStd
  ,postTeachersArray,addedTeacher
})=>{
  const [teachers,setTeachers] = useState([])
  const [deleteTeacher,setDelTeacher] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false)
  let {teacherIds} = []
  let [deleteTeacherIds,setDeleteTeacherIds] = useState([])
  const [teacherName,setTeacherName] = useState(null)
  useEffect(()=>{
    fetchTeacherData()
    setTeachers([...addedTeacher])
  },[])
  const history = useHistory();


  function submit(e){
    
    teacherIds = teachers.filter(({ id: id1 }) => !addedTeacher.some(({ id: id2 }) => id2 === id1));
    setSubmitLoader(true)
    postTeachersArray({teacherIds:teacherIds.map((item)=>item.id) ,standardId: selectedStd.std_id,subjectId:selectedSubject.subjects_id,deletedTeacherIds:deleteTeacherIds.map((item)=>item.id)}
    ,()=>setSubmitLoader(false),succesCallback)
  }

  const succesCallback=()=>{
    setDelTeacher([])
    setSubmitLoader(false)
    setVisibleForm(false)
  }

  const handleCancel=()=>{
        setVisibleForm(false)
  }

  const handleSelectTeacher=(value,item)=>{
    setTeacherName(item.children)
    if(!teachers.map((teacher)=>teacher.id).includes(value))
    {  
      teachers.push({id:value,name:item.children})
      setTeachers([...teachers])   
    }
  }

  const removeTeacher =(e,index,teacherId)=>{
    e.stopPropagation()
    setTeacherName(null)
    deleteTeacher.push(teacherId)
    setDelTeacher([...deleteTeacher])
    teachers.splice(index,1)
    setTeachers([...teachers]) 
    deleteTeacherIds = addedTeacher.filter(({ id: id1 }) => deleteTeacher.some((id2) => id2 === id1));
    setDeleteTeacherIds([...deleteTeacherIds])
  }
    return(
        <Modal 
        className='rounded-border'
        visible={visibleForm} 
        footer={null} 
        centered={true} 
        closable={false}
        bodyStyle={{height:'90%'}}
        maskClosable={false}
        onCancel={handleCancel}
        >
            <div>
                <h2 style={{fontFamily:"roboto",fontWeight:"700"}}>Viewing access</h2>
                <Text style={{fontFamily:"roboto",fontWeight:"700"}}>Teacher</Text><br></br>
                {console.log("teacherName",teacherName)}
                <Select
                  showSearch={true}
                  style={{ width: '100%',backgroundColor:"#FAFAFA", marginTop:5}}
                  placeholder="Add a teacher"
                  optionFilterProp="children"
                  onSelect={handleSelectTeacher}
                  value={teacherName}
                  filterOption={(inputValue, optionData) =>
                      optionData.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }>
                  {teacherMangementList?teacherMangementList.map((item,index)=>(
                      <Option key={item.id}>{item.name}</Option>
                        )):null
                  }
                </Select>
                <div style={{display:'flex', height:'90%', flexDirection:'row',flexWrap:'wrap',alignItems:"flex-start", marginBottom:'15px',marginTop:"10px"}}>
                  {teachers ? teachers.map((item,index)=>(
                        <div style={{height:"32px",margin:"5px", marginLeft:0}}>
                            <div style={{width:"100%",display:'flex',flexDirection:'row'}}>
                                <Button type="primary">
                                  {item.name}
                                  <CloseOutlined onClick={(e)=>removeTeacher(e,index,item.id)}/>
                                </Button>
                            </div>
                        </div>
                  )):null}
                </div>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                  <Button style={{width:"122px",marginRight:"5px"}} type="default" onClick={()=>handleCancel()}>Cancel</Button>
                  <Button loading={submitLoader} style={{width:"122px",marginRight:"5px"}} type="primary" onClick={submit} disabled={(teachers?.length!=0 || deleteTeacherIds?.length!=0)?false:true}>Submit</Button>
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = (state) => {
  const {
      classLibraryList,
      classSubjectList,
      teacherMangementList,
      selectedStd,
      selectedSubject,
      addedTeacher
    
  } = state.digitalLibrary;
  return {
      classLibraryList,
      classSubjectList,
      teacherMangementList,
      selectedStd,
      selectedSubject,
      addedTeacher
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  postTeachersArray:(data,callback,succesCallback)=>dispatch(postTeachersArray(data,callback,succesCallback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeacherFormView);