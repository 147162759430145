import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { Row, Col, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';


import {
	getCurriculum,
	updateCheckedTeachers
} from "../../actions/curriculum";
import { addSubjects } from 'actions/course';
import { deleteSubject } from 'actions/classRoom';


import SubjectRow from "./subjectRow";
import DropDownCheckBox from "./dropDownCheckBox";
import PageHeaderCustom from "components/pageHeader";
import PageDataLoader from "components/pageDataLoader";
import { Button } from 'components/Button';
import CreateSubjectsModal from 'pages/courseModule/createSubjectsModal';
import CourseDeleteConfirmModal from 'pages/courseModule/courseDeleteConfirmModal';
import { useLocation } from "react-router-dom";
import { fetchStudentStandards } from "actions/schedule";
import { postCreateCurriculum } from "actions/courseManagement";
import CreateCurriculumModal from "pages/adminMarketPlaceCourse/createCurriculumModal";
const NoData = require("../../Assets/ill-no-data.svg").default


const bodyStyle = {
	background: "#FAFAFA",
	width: "calc(100% + 30px)",
	height: "calc(100% + 40px)",
	margin: -20,
	padding: 20,
	overflow: "auto",
};


const NoDataCard = () => {
	return (
		<div className='r-c-c-c'>
			<img src={NoData} />
			<div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "18px" }}>No Subjects</div>
		</div>
	)
}




export const studentHeader = (data, setSelectedStandards, selectedStandards, studentStandardFilters, getCurriculum) => {

	const handleStandardChange = (value) => {
		getCurriculum(null, null, null, null, value);
		setSelectedStandards(value);
	};

	return (
		<div style={{ backgroundColor: '#FAFAFA', display: 'flex', alignItems: 'center', padding: '16px', justifyContent: 'space-between', width: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<h2>Syllabus ({data?.[0]?.subjects?.length || 0} Subjects)</h2>
				{data?.[0]?.std && <p>{data?.[0]?.std} : {data?.[0]?.section}</p>}
			</div>


			<div style={{ width: '40%' }}>
				<Select
					placeholder="Select standards"
					onChange={handleStandardChange}
					value={selectedStandards}
					style={{ width: '100%' }}
				>
					{studentStandardFilters?.studentStandards?.map((standard) => (
						<Select.Option key={standard.standardId} value={standard.standardId}>
							{`${standard.std} - ${standard?.section}`}
						</Select.Option>
					))}
				</Select>
			</div>
		</div>
	);
};


export const teacherHeader = (
	subjects,
	checkedSubjects,
	onSubjectSelect,
	classes,
	checkedClasses,
	onClassSelect
) => {

	const pageHeaderSubtitle = (
		<div style={{ fontSize: "1em" }}>
			All Classes ({subjects?.length || 0} Subjects)
		</div>
	);

	const actions = [
		<div
			style={{ marginRight: 10 }}
		>
			<DropDownCheckBox
				DATA={subjects}
				placeholder={'All Subject(s)'}
				checkedArray={checkedSubjects}
				onChange={onSubjectSelect}
			/>
		</div>,
		<div
			style={{ marginRight: 10 }}
		>
			<DropDownCheckBox
				DATA={classes}
				placeholder={'All Class(es)'}
				checkedArray={checkedClasses}
				onChange={onClassSelect}
			/>
		</div>,
	]

	return <PageHeaderCustom
		title={'Syllabus'}
		subtitle={pageHeaderSubtitle}
		givenStyle={{ backgroundColor: "#FAFAFA" }}
		actions={actions}
	/>
}


export const classroomHeader = (
	user,
	selectedSection,
	teachers,
	checkedTeachers,
	onTeacherSelect,
	checkAll,
	setIsCreateCurriculumModalVisible
) => {
	const history = useHistory();

	const pageHeaderSubtitle = (
		<div style={{ fontSize: "1em" }}>
			{`${selectedSection?.std} : ${selectedSection?.section}`}
		</div>
	);

	const actions = [
		<div
			style={{ marginRight: 10 }}
		>
			<DropDownCheckBox
				DATA={teachers}
				placeholder={'All Teacher(s)'}
				checkedArray={checkedTeachers}
				onChange={onTeacherSelect}
				checkAll={checkAll}
			/>
		</div>,
		(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) &&
		<Button style={{ marginRight: 10 }} onClick={() => { setIsCreateCurriculumModalVisible(true) }}>CREATE</Button>,
		(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) &&
		<Button style={{ marginRight: 10 }} type="primary" icon={<SearchOutlined />} onClick={() => { history.push("/classroom/resources") }}>BROWSE</Button>,
	]

	return <PageHeaderCustom
		title={'Curriculum'}
		subtitle={pageHeaderSubtitle}
		onBackPress={() => history.goBack()}
		givenStyle={{ backgroundColor: "#FAFAFA" }}
		actions={actions}
	/>
}


function Curriculum({
	getCurriculum,
	data,
	subjects,
	teachers,
	checkedTeachers,
	updateCheckedTeachers,
	classes,
	selectedSection,
	user,
	addSubjects,
	deleteSubject,
	fetchStudentStandards,
	studentStandardFilters,
	postCreateCurriculum
}) {

	const initialSelectedStandard = studentStandardFilters?.studentStandards?.length > 0 ? studentStandardFilters?.studentStandards[0]?.standardId : null;

	const location = useLocation();

	const [checkedSubjects, setCheckedSubjects] = useState([]);
	const [checkedClasses, setCheckedClasses] = useState([]);
	const [checkAll, setCheckAll] = useState(false);

	const [isCreateSubjectModalVisible, setCreateSubjectModalVisible] = useState(false);
	const [isEditSubjectModalVisible, setIsEditSubjectModalVisible] = useState(false);
	const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
	const [showEditChapters, setShowEditChapters] = useState([]);

	const [pageLoader, togglePageLoader] = useState(false);

	const [selectedStandards, setSelectedStandards] = useState(initialSelectedStandard)
	const [isCreateCurriculumModalVisible, setIsCreateCurriculumModalVisible] = useState(false);

	let dataExist = false;


	const onSubjectSelect = (item) => {
		if (checkedSubjects.includes(item.id))
			setCheckedSubjects(prev => prev.filter(el => el != item.id))
		else setCheckedSubjects(prev => [...prev, item.id])
	}

	const onClassSelect = (item) => {
		if (checkedClasses.includes(item.id))
			setCheckedClasses(prev => prev.filter(el => el != item.id))
		else setCheckedClasses(prev => [...prev, item.id])
	}

	const onTeacherSelect = (item) => {
		let newCheckedTeachers = [];
		if (checkedTeachers?.includes(item.id)) {
			setCheckAll(false)
			newCheckedTeachers = checkedTeachers.filter(el => el != item.id)
			updateCheckedTeachers(newCheckedTeachers)
		}
		else {
			// newCheckedTeachers = [ item.id ]
			if (item.id == 'All') {
				if (checkAll) {
					newCheckedTeachers = []
					setCheckAll(false)
				}
				else {
					newCheckedTeachers = teachers?.map(el => el.id)
					setCheckAll(true)
				}
			}
			else {
				newCheckedTeachers = [...checkedTeachers, item.id]
				if (newCheckedTeachers?.length == teachers?.length) setCheckAll(true)
			}
			updateCheckedTeachers(newCheckedTeachers)
		}

		const params = {
			// teacherIds: newCheckedTeachers,
		}
		getCurriculum(params, location.pathname, () => togglePageLoader(false), null);
	}


	useEffect(() => {
		let params = {}
		togglePageLoader(true)
		getCurriculum(params, location.pathname, () => togglePageLoader(false), (response) => updateCheckedTeachers(response), null);

		if (user && user.role == 'Student') fetchStudentStandards();

		//clearing the field on unmount
		return () => {
			// updateCurriculumClass(null);
			updateCheckedTeachers([]);
		}
	}, []);


	useEffect(() => {
		setCheckedSubjects(subjects?.map(el => el?.id));
		setCheckedClasses(classes?.map(el => el?.id));
	}, [subjects, classes])

	return (
		<>
			{(location.pathname == '/classroom/curriculum') && classroomHeader(user, selectedSection, teachers, checkedTeachers, onTeacherSelect, checkAll, setIsCreateCurriculumModalVisible)}

			{(user?.role?.[0] == 'Teacher' && location.pathname == '/syllabus') && teacherHeader(subjects, checkedSubjects, onSubjectSelect, classes, checkedClasses, onClassSelect)}

			{(user?.role?.[0] == 'Student') && studentHeader(data, setSelectedStandards, selectedStandards, studentStandardFilters, getCurriculum)}


			<div style={{
				...bodyStyle,
				marginTop: 70
			}}
			>
				{data?.map((standard, stdIdx) =>
					standard?.subjects?.map((subject, subIdx) => {
						if (checkedClasses?.includes(standard?.id) &&
							checkedSubjects?.includes(subject?.name)) {
							dataExist = true;
							return <SubjectRow
								subject={subject}
								subIdx={subIdx}
								std={standard?.std}
								section={standard?.section}
								setIsEditSubjectModalVisible={setIsEditSubjectModalVisible}
								setShowEditChapters={setShowEditChapters}
							/>
						}
					})
				)}
				{!dataExist && <NoDataCard />}
			</div>

			{isCreateSubjectModalVisible &&
				<CreateSubjectsModal
					isCreateSubjectModalVisible={isCreateSubjectModalVisible}
					setCreateSubjectModalVisible={setCreateSubjectModalVisible}
					addSubjects={addSubjects}
					fromClassroom={true}
					togglePageLoader={togglePageLoader}
					getSubjStudentsData={(pageLoaderCallback) => { getCurriculum({}, location.pathname, pageLoaderCallback), null }}
				// isEditSubjectVisible={isEditSubjectModalVisible}
				// setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
				/>
			}

			{isEditSubjectModalVisible &&
				<CreateSubjectsModal
					isCreateSubjectModalVisible={isEditSubjectModalVisible}
					setCreateSubjectModalVisible={setIsEditSubjectModalVisible}
					addSubjects={addSubjects}
					fromClassroom={true}
					togglePageLoader={togglePageLoader}
					getSubjStudentsData={(pageLoaderCallback) => { getCurriculum({}, location.pathname, pageLoaderCallback), null }}
					isEditSubjectVisible={isEditSubjectModalVisible}
					showEditChapters={showEditChapters}	//showEditChapters = subject{id,name,chapters}
					setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
					setIsFromSubjects={() => { }}
				/>
			}
			{isConfirmDeleteModalVisible &&
				<CourseDeleteConfirmModal
					isConfirmDeleteModalVisible={isConfirmDeleteModalVisible}
					setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
					togglePageLoader={togglePageLoader}
					isFromSubjects={true}
					showEditChapters={showEditChapters}
					deleteSubject={deleteSubject}
					setCreateSubjectModalVisible={setIsEditSubjectModalVisible}
					getCurriculum={getCurriculum}
					fromCurriculum={true}
				/>
			}
			{isCreateCurriculumModalVisible &&
				<CreateCurriculumModal
					isCreateCurriculumModalVisible={isCreateCurriculumModalVisible}
					setIsCreateCurriculumModalVisible={setIsCreateCurriculumModalVisible}
					togglePageLoader={togglePageLoader}
					getCurriculum={getCurriculum}
					postCreateCurriculum={postCreateCurriculum}
					fromCurriculum={true}
				/>
			}
			<PageDataLoader visible={pageLoader} />
		</>
	);
}

const mapStateToProps = (state) => {
	const { curriculum, checkedTeachers } = state?.curriculum;
	const { selectedSection } = state.classRoom;
	const { user } = state.session;
	const { studentStandardFilters } = state.schedule

	const { data, subjects, teachers, classes } = curriculum || {};
	return {
		data,
		subjects,
		teachers,
		classes,
		checkedTeachers,
		selectedSection,
		user,
		studentStandardFilters
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCurriculum: (params, location, pageLoaderCallback, callback, selectedStandard) => dispatch(getCurriculum(params, location, pageLoaderCallback, callback, selectedStandard)),
	updateCheckedTeachers: (val) => dispatch(updateCheckedTeachers(val)),
	addSubjects: (subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack) => dispatch(addSubjects(subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack)),
	deleteSubject: (subjectId, callback, successCallBack) => dispatch(deleteSubject(subjectId, callback, successCallBack)),
	fetchStudentStandards: () => dispatch(fetchStudentStandards()),
	postCreateCurriculum: (params, fromClassroom, callback, succesCallback) => dispatch(postCreateCurriculum(params, fromClassroom, callback, succesCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Curriculum);