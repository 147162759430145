import React from 'react';
import Result from 'antd/lib/result';
import 'antd/lib/result/style/index';
import { Button } from 'antd';
import {connect} from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function KycReject({rejectKycMessage}) {
    const history = useHistory()

    const modules = {
        toolbar: false,
      }

    return (
      <div style={{height: '100%'}} className="r-c-c-c">
            <div className="r-c-c">
                <img src={require('Assets/ill-fm-kyc.svg').default}  />
            </div>
            <div className="color-purple text-sm bold-600 r-c-c"
            >KYC Approval Unsuccessful</div>
            <div className="text-center  r-c-c">
                Some error/inconsistency has been noted in the submitted details or documents. Please review the entire form
                and submit it again.
            </div>
            <div className="r-c-c m-t-10" >
               <div className="commentBox">
                   <div className="text-xs bold-600 dark-grey">APPROVER'S COMMENT</div>
                   <div className='r-ac m-b-20 m-t-20'>
                        <div className='m-r-20 flex-1'>
                        <ReactQuill
                            theme={"snow"}
                            modules={modules}
                            style={{height: "100%", border: 'none'}}
                            readOnly={true}
                            // value={announcementSingle.details ? JSON.parse(announcementSingle.details).web : null}
                            value={rejectKycMessage}
                            placeholder={'Enter Reason'}
                            />
                        </div>
                    </div>
                    {/* <div>{rejectKycMessage}</div> */}
               </div>
            </div>
            <div className="r-c-c m-t-20">
                <Button type="primary" shape="round" style={{width:120}} onClick={() => history.push('/kyc-form')}>View Form</Button>
            </div>

      </div>

    )
}

const mapStateToProps = state => {
    const {
        rejectKycMessage
    } = state.fees;
    return {
        rejectKycMessage
    };
  };


export default connect(mapStateToProps)(KycReject);
