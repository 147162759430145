import { ExclamationCircleOutlined, FilePdfOutlined } from "@ant-design/icons";
import { setFeeDetails, setInvoiceFile } from "actions/feeManagement";
import { Button, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Api } from "services";

function SettlementDetailsModal({
  isModalVisible,
  setShowSettlementModal,
  feeMangementData,
  setInvoiceFile,
}) {
  const [payAfterDeduction, setPayAfterDeduction] = useState(
    parseFloat(feeMangementData?.selectedSettlement?.total) -
      parseFloat(feeMangementData?.selectedSettlement?.total) * 0.012
  );
  const [link, setLink] = useState(
    feeMangementData?.selectedSettlement?.invoice?.replace(Api._s3_url, "")
    // feeMangementData?.selectedSettlement?.invoice Api._s3_url
  );
  const history = useHistory();
  useEffect(() => {
    console.log("setData", feeMangementData?.selectedSettlement);
  }, []);

  const text = (
    <span>
      Transaction fee (1.2% of Total Amount) goes to aulas as a service charge.
    </span>
  );
  return (
    <div>
      <Modal
        className="settlementDetailsModalStyle"
        title="Basic Modal"
        visible={isModalVisible}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: "550",
            fontSize: "20px",
            marginBottom: "10px",
          }}
        >
          Settlement Details
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Settlement date
              </p>
              <div
                style={{
                  marginLeft: "10px",
                  color: "black",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
              >
                {moment(
                  feeMangementData?.selectedSettlement?.closedDate
                ).format("DD/MM/YYYY, hh:mm a")}
              </div>
            </div>
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Invoice
              </p>
              <div
                onClick={() => {
                  setInvoiceFile(link);
                  history.push("/fee-management/invoice");
                }}
                style={{
                  marginLeft: "10px",
                  color: "black",
                  fontWeight: "600",
                  color: "#1089FF",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                <FilePdfOutlined /> invoice.pdf
              </div>
            </div>
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Date range
              </p>
              <div
                style={{
                  marginLeft: "10px",
                  color: "black",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
              >
                {moment(feeMangementData?.selectedSettlement?.startDate).format(
                  "DD/MM/YYYY"
                )}
                -
                {moment(feeMangementData?.selectedSettlement?.endDate).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
          </div>
          <div>
            <div style={{ marginBottom: "65px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Transaction ID
              </p>
              <div
                style={{
                  marginLeft: "10px",
                  color: "black",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
              >
                {feeMangementData?.selectedSettlement?.transactionId}
              </div>
            </div>
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                No. of transactions
              </p>
              <div
                style={{
                  marginLeft: "10px",
                  color: "black",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
              >
                {feeMangementData?.selectedSettlement?.noOftransactions}
              </div>
            </div>
          </div>
          <div>
            {/* <div
              style={{
                color: "#1089FF",
                fontWeight: "700",
                fontSize: "11px",
                marginTop: "105px",
                cursor: "pointer",
              }}
            >
              VIEW ALL
            </div> */}
          </div>
        </div>
        <p
          style={{
            marginBottom: "5px",
            color: "#636363",
            fontWeight: "bold",
            fontSize: "12px",
            marginLeft: "10px",
            marginTop: "10px",
            marginTop: "20px",
          }}
        >
          Amount details
        </p>
        <div
          style={{
            border: "1px solid #E6E6E6",
            padding: "10px",
            borderRadius: "7px",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "black",
              fontSize: "13px",
              fontWeight: "600",
            }}
          >
            <p>Total amount</p>
            <p>₹ {feeMangementData?.selectedSettlement?.total}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#FF414D",
              fontSize: "13px",
              fontWeight: "600",
            }}
          >
            <p>
              Total amount
              <Tooltip placement="right" title={text}>
                <ExclamationCircleOutlined
                  style={{ color: "#1089FF", marginLeft: "5px" }}
                />
              </Tooltip>
            </p>
            <p>
              -₹{" "}
              {parseFloat(
                parseFloat(feeMangementData?.selectedSettlement?.total).toFixed(
                  2
                ) - parseFloat(payAfterDeduction)?.toFixed(2)
              )?.toFixed(2)}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "black",
              fontSize: "13px",
              fontWeight: "600",
              borderTop: "1px solid #E6E6E6",
              borderBottom: "1px solid #E6E6E6",
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
          >
            <p style={{ marginBottom: "0" }}>Total received after deduction</p>
            <p style={{ marginBottom: "0" }}>
              ₹ {parseFloat(payAfterDeduction)?.toFixed(2)}
            </p>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              setShowSettlementModal(false);
            }}
            style={{
              borderRadius: "20px",
              width: "100px",
              color: "#636363",
              fontSize: "12px",
              fontWeight: "600",
              height: "28px",
            }}
          >
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

// export default SettlementDetailsModal;
const mapStateToProps = (state) => {
  const { feeMangementData, allActivitiesFilter } = state.feeManagement;
  return {
    feeMangementData,
    allActivitiesFilter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  // setAllActivitiesFilter: (key, res) =>
  //   dispatch(setAllActivitiesFilter(key, res)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  setInvoiceFile: (val) => dispatch(setInvoiceFile(val)),
  //   getAllSettlements: (page) => dispatch(getAllSettlements(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettlementDetailsModal);
