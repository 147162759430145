import { Api } from '../services';

export const TOGGLE_FETCH_CLASS_LOADER = 'TOGGLE_FETCH_CLASS_LOADER';
export const UPDATE_CLASSES_LIST = 'UPDATE_CLASSES_LIST';
export const TOGGLE_FETCH_SECTION_LOADER = 'TOGGLE_FETCH_SECTION_LOADER';
export const UPDATE_STUDENT_DATA = 'UPDATE_STUDENT_DATA';
export const UPDATE_CLASSES_DATA = 'UPDATE_CLASSES_DATA';
export const RESET_CLASSES_DATA = 'RESET_CLASSES_DATA';
export const RESET_STUDENT_DATA = 'RESET_STUDENT_DATA';
export const UPDATE_STUDENTS_LIST = 'UPDATE_STUDENTS_LIST';
export const TOGGLE_STUDENT_LOADER = 'TOGGLE_STUDENT_LOADER';
export const UPDATE_STUDENT_ATTENDANCE = 'UPDATE_STUDENT_ATTENDANCE';
export const TOGGLE_STUDENT_MORE_DATA_LOADER = 'TOGGLE_STUDENT_MORE_DATA_LOADER';
export const UPDATE_CURRENT_STUDENT_PAGE = 'UPDATE_CURRENT_STUDENT_PAGE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const PUSH_STUDENT_ATTENDANCE = 'PUSH_STUDENT_ATTENDANCE';
export const CHANGE_SELECTED_ATTENDANCE_TAB = 'CHANGE_SELECTED_ATTENDANCE_TAB'; 
export const UPDATE_TEACHERS_LIST = 'UPDATE_TEACHERS_LIST';
export const TOGGLE_FETCH_TEACHERS_LOADER = 'TOGGLE_FETCH_TEACHERS_LOADER';
export const UPDATE_SELECTED_TEACHER = 'UPDATE_SELECTED_TEACHER';
export const TOGGLE_TEACHER_ATTENDANCE_LOADER = 'TOGGLE_TEACHER_ATTENDANCE_LOADER';
export const UPDATE_SELECTED_SECTION = 'UPDATE_SELECTED_SECTION';
export const UPDATE_TEACHER_ATTENDANCE = 'UPDATE_TEACHER_ATTENDANCE';
export const UPDATE_TEACHER_DETAILED_ATTENDANCE = 'UPDATE_TEACHER_DETAILED_ATTENDANCE';
export const UPDATE_CLASS_STUDENT_LIST = 'UPDATE_CLASS_STUDENT_LIST';
export const TOGGLE_STUDENT_BY_CLASS_LOADER = 'TOGGLE_STUDENT_BY_CLASS_LOADER';

export const updateSelectedSection = data => ({
    type: UPDATE_SELECTED_SECTION,
    data
})


export const changeSelectedAttendancetab = key => ({
    type: CHANGE_SELECTED_ATTENDANCE_TAB,
    key
})

export const getAllClasses = () => {
    const url = `/attendance`;
    return dispatch => {
        dispatch(toggleFetchClassesLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .send(async (response, error) => {
            resolve();
            if (response) {
                dispatch(upldateClassesList(response.classes));
            }
            dispatch(toggleFetchClassesLoader(false));
            });
        });
    };
}

const toggleFetchClassesLoader = (bool) => ({
    type: TOGGLE_FETCH_CLASS_LOADER,
    bool
});

const upldateClassesList = (data) => ({
    type: UPDATE_CLASSES_LIST,
    data
});

export const getStudentsByClass = (standardId) => {
    const url = `/attendance/standard/${standardId}`;
    return dispatch => {
        dispatch(toggleFetchSectionLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .send(async (response, error) => {
            resolve();
            if (response) {
                dispatch(updateStudentsList(response));
            }
            dispatch(toggleFetchSectionLoader(false));
            });
        });
    };
}

const toggleFetchSectionLoader = (bool) => ({
    type: TOGGLE_FETCH_SECTION_LOADER,
    bool
});

export const updateClassData = (data) => ({
    type: UPDATE_CLASSES_DATA,
    data
});

export const resetClassData = () => ({
    type: RESET_CLASSES_DATA,
});

export const updateStudentsList = data => ({
    type: UPDATE_STUDENTS_LIST,
    data
})

export const updateStudentData = data => ({
    type: UPDATE_STUDENT_DATA,
    data
})

export const resetStudentData = () => ({
    type: RESET_STUDENT_DATA,
});

export const getAttendanceByStudent = (studentId) => {
    return (dispatch, getState) => {
        const standardId = getState().attendance.classDetails.standardId;
        const date = getState().attendance.selectedDate;
        const url = `/attendance/student/${studentId}/${standardId}`;
        dispatch(toggleStudentLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .params({page: 1, date})
            .send(async (response, error) => {
            resolve();
            if (response) {
                dispatch(updateStudentAttendance(response));
            }
            dispatch(toggleStudentLoader(false));
            });
        });
    };
}

const toggleStudentLoader = (bool) => ({
    type: TOGGLE_STUDENT_LOADER,
    bool
});

export const updateStudentAttendance = data => ({
    type: UPDATE_STUDENT_ATTENDANCE,
    data
})

export const loadMoreData = (page) => {
    return (dispatch, getState) => {
        const standardId = getState().attendance.classDetails.standardId;
        const studentId = getState().attendance.selectedStudent.id;
        const url = `/attendance/student/${studentId}/${standardId}`;
        dispatch(toggleLoadMoreDataLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .params({page})
            .send(async (response, error) => {
            resolve();
            if (response) {
                dispatch(pushStudentAttendance(response));
                dispatch(updateCurrentStudentPage(page))
            }
            dispatch(toggleLoadMoreDataLoader(false));
            });
        });
    };
}

const pushStudentAttendance = data => ({
    type: PUSH_STUDENT_ATTENDANCE,
    data
})

const toggleLoadMoreDataLoader = (bool) => ({
    type: TOGGLE_STUDENT_MORE_DATA_LOADER,
    bool
});

export const updateCurrentStudentPage = page => ({
    type: UPDATE_CURRENT_STUDENT_PAGE,
    page
})

export const setSelectedDate = (date) => ({
    type: SET_SELECTED_DATE,
    date
})


export const getAllTeachers = () => {
    const url = `/attendance/teachers-list`;
    return dispatch => {
        dispatch(toggleFetchTeachersLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .send(async (response, error) => {
            resolve();
            if (response) {
                dispatch(updateTeachersList(response));
            }
            dispatch(toggleFetchTeachersLoader(false));
            });
        });
    };
}

const updateTeachersList = (data) => ({
    type: UPDATE_TEACHERS_LIST,
    data
})

const toggleFetchTeachersLoader = (bool) => ({
    type: TOGGLE_FETCH_TEACHERS_LOADER,
    bool
});

export const updateSelectedTeacher = data => ({
    type: "UPDATE_SELECTED_TEACHER",
    data
})

export const getTeacherAttendance = (teacherId, params = null) => {
    const url = `/attendance/teacher/${teacherId}`;
    return dispatch => {
        dispatch(toggleFetchTeachersAttendanceLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .params(params)
            .send(async (response, error) => {
            resolve();
            if (response) {
                if(!params) dispatch(updateTeachersAttendance(response));
                else dispatch(updateTeachersDetailedAttendance(response));
            }
            dispatch(toggleFetchTeachersAttendanceLoader(false));
            });
        });
    };
}

const updateTeachersAttendance = (data) => ({
    type: UPDATE_TEACHER_ATTENDANCE,
    data
});

const updateTeachersDetailedAttendance = (data) => ({
    type: UPDATE_TEACHER_DETAILED_ATTENDANCE,
    data
});

const toggleFetchTeachersAttendanceLoader = (bool) => ({
    type: TOGGLE_TEACHER_ATTENDANCE_LOADER,
    bool
});

export const getStudentsByConference = (conferenceId) => {
    const url = `/attendance/students-by-class/${conferenceId}`;
    return dispatch => {
        dispatch(toggleStudentsByClassAttendanceLoader(true));
        return new Promise((resolve, reject) => {
        Api.get(url)
            .send(async (response, error) => {
            resolve();
            if (response) {
                dispatch(updateClassStudentList(response));
            }
            dispatch(toggleStudentsByClassAttendanceLoader(false));
            });
        });
    };
}

const updateClassStudentList = (data) => ({
    type: UPDATE_CLASS_STUDENT_LIST,
    data
});

const toggleStudentsByClassAttendanceLoader = (bool) => ({
    type: TOGGLE_STUDENT_BY_CLASS_LOADER,
    bool
});


