import { Modal, Button } from 'antd'
import React from 'react'

const DeleteQuesModal = ({ handleOk, handleCancel, visible, loader }) => {


  return (
    <Modal
    centered={true}
    className="modal-round-corner"
    visible={visible}
    onCancel={handleCancel}
    footer={null}>
        
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "25px"}}>
        <div style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}>
            Confirm
        </div>
    
        <div style={{ fontSize: "16px", color: "#636363", fontWeight: "bold",marginTop: "10px"}}>
            Are you sure you want to delete this question?
        </div>
    </div>

    <div style={{ display: "flex",justifyContent: "space-evenly", marginTop: "30px"}}>
        <div>
            <Button style={{borderRadius: "19px", width: "100px", height: "35px", border: "1px solid  #636363", backgroundColor: "#FFFFFF", color: "#594099"}}>
            <div style={{fontSize: "13px", color: "#636363", fontWeight: "bold"}} onClick={handleCancel}>
                NO
            </div>
            </Button>
        </div>
    
        <div>
            <Button
            loading={loader}
            className='redBtnWrap'
            style={{borderRadius: "19px", width: "100px", height: "35px", fontSize: "13px", color: "#FFFFFF"}} 
            onClick={()=>{handleOk()}}
            >
                YES
            </Button>
        </div>
    </div>
    </Modal>
  )
}

export default DeleteQuesModal