import React, { useState } from 'react';
import { Button, DatePicker, Input, Modal, Space } from 'antd';
import moment from 'moment';
import { editHolidayData, getHolidayOverview, holidayDetail } from 'actions/attendanceNew';
import { connect } from 'react-redux';
import { toggleFeeButtonLoader } from 'actions/fees';
const EditHolidayModal = ({ editHolidayModal, setEditHolidayModal, props, editHolidayData, importHolidayListNew, holidayDetail, getHolidayOverview }) => {

  const convertedDate = moment(props?.date).format('YYYY-MM-DD');

  const [holidayName, setHolidayName] = useState(props.title || '')
  const [holidayDate, setHolidayDate] = useState(convertedDate || '')
  const [id, setId] = useState(props?.id)
  const [buttonLoader, toggleButtonLoader] = useState(false);


  const handleOk = () => {
    setEditHolidayModal(false);
  };

  const handleCancel = () => {
    setEditHolidayModal(false);
  };
  console.log('holidayDate',holidayDate);

  const handleCreate = () => {
    let params = {
      title: holidayName,
      date: holidayDate,
      id: id,
      geoFenceId: importHolidayListNew
    }
    const successCallback = () => {
      setEditHolidayModal(false);
      toggleButtonLoader(false);
      getHolidayOverview()

    }

    toggleButtonLoader(true);
    editHolidayData(params, () => toggleButtonLoader(false), () => successCallback())
  }

  return (
    <>
      <Modal
        className="modal-round-corner"
        footer={false} open={editHolidayModal} onOk={handleOk} onCancel={handleCancel}>
        <div
          style={{
            marginTop: "30px",
            fontSize: "24px",
            color: "#191919",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Edit Holiday
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <h6
              style={{
                fontSize: "14px",
                color: "#636363",
                fontWeight: "bold",
              }}
            >
              Holiday title
            </h6>
            <Input
              value={holidayName}
              onChange={(e) => setHolidayName(e.target.value)}
              style={{
                fontSize: "16px",
                color: "#191919",
              }}
              placeholder="Enter holiday name"
            />
          </div>
        </div>
        <div >
          <div style={{ marginTop: "20px" }}>
            <h6
              style={{
                fontSize: "14px",
                color: "#636363",
                fontWeight: "bold",
              }}
            >
              Holiday date
            </h6>
            <Space>
              <DatePicker
                value={moment(holidayDate)}
                format={'DD-MM-YYYY'}
                allowClear={false}
                onChange={(e) => { setHolidayDate(e.format('YYYY-MM-DD')) }}
                style={{ fontSize: "16px", color: "#AEAEAE", width: "470px" }}
                placeholder="Select holiday date"
              />
            </Space>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "50px",
          }}
        >
          <div>
            <Button
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#FFFFFF",
                border: "1px solid #636363 ",
                color: "#636363",
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
          <div>
            <Button
              loading={buttonLoader}
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#594099",
                color: "#FFFFFF",
                //   opacity: (createHolidayList.title == '' || createHolidayList.date == '') ? 0.5 : 1,
              }}
              onClick={() => handleCreate()}
            >
              UPDATE
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { importHolidayListNew, } = state.attendanceNew;
  return { importHolidayListNew, };
};

const mapDispatchToProps = (dispatch) => ({
  editHolidayData: (params, callBack, successCallBack) => dispatch(editHolidayData(params, callBack, successCallBack)),
  holidayDetail: () => dispatch(holidayDetail()),
  getHolidayOverview: () => dispatch(getHolidayOverview()),



});

export default connect(mapStateToProps, mapDispatchToProps)(EditHolidayModal);