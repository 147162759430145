import { applyPromoCheckout, checkLiveCourse, getCheckPayment, getCouponList, payNowCart, postCartPayment, postCourseEnrollNow, setActiveTermIndex, setFeePlanData, setSelectedCouponCode, setSelectedTerm, setStorePromoPrice, setTermList } from 'actions/studentMarketPlace';
import { Input, Modal, Slider, Switch } from 'antd'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import CouponsModal from '../couponsModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'components/Button';
import { InvalidCouponType, InvalidCouponMessage } from '../constants/studentMarketPlace';
import { setShowLoginModal } from 'actions/loginNew';


const InstallmentList = ({ list }) => {
    console.log("itemnddddd111", list);
    const scrollRef = useRef(null);

    useEffect(() => {
        // Scroll to the top when couponList changes
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [list]);
    return (
        <div>
            <div style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Roboto", marginTop: "10px" }}>Installments</div>
            <div ref={scrollRef} className='scroll-bar-universal' style={{ width: "100%", marginTop: 10, height: "20dvh" }}>
                <div className='course-installment-modal'>
                    {list?.map((item, index) => (
                        <div key={index} style={{ flexBasis: "25%", boxSizing: "border-box", display: "flex", justifyContent: "flex-start" }}>
                            <div className='m-t-10' style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <div style={{ display: "inline-block", width: 8, height: 8, backgroundColor: "#636363", borderRadius: "50%", margin: "10px 10px 10px 0px" }}></div>
                                <div className='' style={{
                                    display: "flex", alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    flexDirection: "column",
                                    fontSize: "14px",
                                    color: "#191919",
                                    fontWeight: 500,
                                    fontFamily: "Roboto",
                                }}>
                                    {console.log("itemnddddd", item)}
                                    Installment {index + 1}
                                    <div style={{ fontWeight: "bold" }}>₹ {item?.amount.toLocaleString()}</div>
                                    <div style={{ color: "#636363", fontSize: "12px" }}>{item?.dueDate ? `Due on ${moment(item?.dueDate)?.format('DD/MM/YYYY')}` : ""}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}



function CourseInstallmentModal({ user, couponList, activeTermIndex, setActiveTermIndex, setSelectedTerm, selectedTerm, termList, setTermList, storePromoPrice, setSelectedCouponCode, payNowCart, setStorePromoPrice, selectedCouponCode, showMenu, applyPromoCheckout, isInstallmentVisible, checkLiveCourse, postCourseEnrollNow, getCheckPayment, postCartPayment, getCouponList, setInstallmentVisible, singleCourseDetailsDataContainer, from, setShowLoginModal }) {

    const discountedFee = singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discountAmount
    const ActualFee = singleCourseDetailsDataContainer?.amount;
    const history = useHistory();
    const [hideProceed, setHideProceed] = useState(false);
    const [enroll, setEnroll] = useState({});
    const [payOfferCode, setPayOfferCode] = useState('');
    const [isCouponModal, setIsCouponModal] = useState(false);
    const [applyLoader, toggleApplyLoader] = useState(false);
    const [isInputTyped, setIsInputTyped] = useState(false);
    const [invalidCouponType, setInvalidCouponType] = useState(null);
    const [disablePay, setDisablePay] = useState(false);

    console.log("seleeeee", storePromoPrice?.courseBilling?.length, singleCourseDetailsDataContainer?.courseBilling?.length)

    useEffect(() => {
        getCouponList(paramsModal);
        if (singleCourseDetailsDataContainer?.owned == "1") {
            setHideProceed(true);
        } else {
            setHideProceed(false);
        }
        setPayOfferCode(selectedCouponCode);
    }, [singleCourseDetailsDataContainer, selectedCouponCode]);

    useEffect(() => {
        if (activeTermIndex == 0) {
            const firstTerm = storePromoPrice ? storePromoPrice.courseBilling?.[0] : singleCourseDetailsDataContainer.courseBilling?.[0];
            handleTermClick(activeTermIndex, firstTerm?.billingPlan, firstTerm?.installments);
        } else {
            const firstTerm1 = storePromoPrice ? storePromoPrice.courseBilling?.[activeTermIndex] : singleCourseDetailsDataContainer.courseBilling?.[activeTermIndex];
            handleTermClick(activeTermIndex, firstTerm1?.billingPlan, firstTerm1?.installments);
        }
    }, [storePromoPrice, singleCourseDetailsDataContainer, activeTermIndex]);


    const scrollRef = useRef(null);

    useEffect(() => {
        // Scroll to the top when couponList changes
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [activeTermIndex, selectedTerm]);

    useEffect(() => {
        if (invalidCouponType) {
            setDisablePay(true);
        } else {
            setDisablePay(false);
        }
    }, [invalidCouponType])

    const feeCardStyle = {
        width: '195px',
        height: 'fit-content',
        background: 'rgb(244, 240, 255)',
        borderRadius: '12px',
        opacity: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: " 10px 20px",
        color: "#636363",
        fontSize: "12px",

    };
    const selectedStyle = {
        background: '#594099',
        color: 'white',
        fontWeight: "bold",
        border: "none",
        cursor: "default"
    };
    const termStyle = {
        width: '250px',
        height: '40px',
        border: '1px solid #E6E6E6',
        opacity: 1,
        marginRight: '10px',
        borderRadius: '100px',
        cursor: storePromoPrice?.courseBilling?.length == 1 ? "default" : singleCourseDetailsDataContainer?.courseBilling?.length == 1 ? "default" : "pointer"
    };

    let balanceAfter = discountedFee - singleCourseDetailsDataContainer?.registrationFee - (singleCourseDetailsDataContainer?.offerDiscount || 0) ;
    if(balanceAfter < 0){
        balanceAfter = 0;
    }
    const handleInputCode = (e) => {
        const filteredValue = e.target.value.replace(/[^A-Za-z0-9]/g, '');
        setPayOfferCode(filteredValue);
        console.log("handlePromo", filteredValue, payOfferCode);
        setIsInputTyped(filteredValue.trim() !== '');
        setInvalidCouponType(null);
    }
    const paramsModal = { checkout: true, disablePagination: true, itemId: singleCourseDetailsDataContainer?.id, itemType: singleCourseDetailsDataContainer?.resourceType, updatedCourseBilling: true, ignoreMin: true }
    const handleBuyNow = (itemId, itemType) => {
        if (user?.accessToken) {
            if (from !== "checkout") {
                history.push({ pathname: '/user-checkout', state: { itemId, itemType, promo: selectedCouponCode } })
            } else {
                setInstallmentVisible(false);
            }
        } else {
            history.push('/login');
        }
    };

    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {

                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
                history.push("/courses-list")

            });
        } else {
            history.push('/login');
        }
    };

    const handleProceedToPayment = () => {
        // if (singleCourseDetailsDataContainer?.amount == 0) {
        //     if (user.accessToken) {

        //         handleEnroll(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
        //     } else {
        //         history.push("/login")
        //     }
        // } else {
        if (user.accessToken) {
            handleBuyNow(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
        } else {
            // history.push("/login")
            setShowLoginModal(true)
        }
        // }
    }
    const handleApplyClick = () => {
        let params = {
            code: payOfferCode,
            type: 'offer',
            id: singleCourseDetailsDataContainer?.id,
            itemType: singleCourseDetailsDataContainer?.resourceType,
            updatedCourseBilling: true
        };
        toggleApplyLoader(true);
        applyPromoCheckout(params, (res) => { if (res) { setInvalidCouponType(null); toggleApplyLoader(false) } }, ()=>{setSelectedCouponCode(payOfferCode)}, (err) => {
            if (err.invalid) {
                setInvalidCouponType(InvalidCouponType.invalid);
            } else if (err.expired) {
                setInvalidCouponType(InvalidCouponType.expired);
            } else if (err.minPurchase) {
                setInvalidCouponType(InvalidCouponType.minPurchase);
            } else if (err.usageLimit) {
                setInvalidCouponType(InvalidCouponType.usageLimit);
            }
            toggleApplyLoader(false);
        })
    };

    const handleTermClick = (index, term, fullterm) => {
        setActiveTermIndex(index);
        setSelectedTerm(term);
        setTermList(fullterm);
        console.log("term112", fullterm, term)
    };
    const handleRemoveClick = () => {
        const firstTerm = storePromoPrice ? storePromoPrice.courseBilling?.[0] : singleCourseDetailsDataContainer.courseBilling?.[0];
        handleTermClick(0, firstTerm?.billingPlan, firstTerm?.installments);
        // Assuming you have a default term and its associated term list stored somewhere
        // setSelectedCouponCode(null);
        setActiveTermIndex(0)
        setIsInputTyped(false);

    };
    return (
        <div>
            <Modal
                centered
                className="modal-round-corner"
                open={isInstallmentVisible}
                onCancel={() => { setInstallmentVisible(false); handleRemoveClick() }}
                footer={null}
                width={900}
                bodyStyle={{ height: storePromoPrice?.courseBilling?.length > 0 ? "90dvh" : "fit-content", marginTop: "-30px", fontFamily: "Roboto", }}>
                <div style={{ padding: "0px 20px 0px 20px", fontFamily: "Roboto" }}>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>FEE PLAN</div>
                    <div className='r-c-sb m-t-15' style={{ width: "100%" }} >
                        <div className='' style={feeCardStyle} >Actual fee<span style={{ fontWeight: "bold", color: "#191919", fontSize: "14px", marginTop: "5px" }}>₹ {ActualFee.toLocaleString()}</span></div>
                        <div className='' style={feeCardStyle} >Discounted fee<span style={{ fontWeight: "bold", color: "#191919", fontSize: "14px", marginTop: "5px" }}>₹ {discountedFee.toLocaleString()}</span></div>
                        <div className='' style={feeCardStyle} >Registration fee<span style={{ fontWeight: "bold", color: "#191919", fontSize: "14px", marginTop: "5px" }}>₹ {singleCourseDetailsDataContainer?.registrationFee.toLocaleString()}</span></div>
                        <div className='' style={feeCardStyle}>Balance after registration<span style={{ fontWeight: "bold", color: "#191919", fontSize: "14px", marginTop: "5px" }}>₹ {balanceAfter.toLocaleString()}</span></div>
                    </div>

                    <div className='r-c-sb m-t-20'>
                        {storePromoPrice ?
                            <div className='r-c-sb' style={{ width: "100%", fontFamily: "Roboto", fontWeight: "600", color: "#00A566" }}>
                                <div>{payOfferCode} applied (₹{storePromoPrice?.promoDiscount.toLocaleString()} additional discount applied on total fee)</div>
                                <div style={{ color: "#FF414D", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setSelectedCouponCode(null), setStorePromoPrice(false), setSelectedTerm(null), setActiveTermIndex(activeTermIndex), setIsInputTyped(false) }}>REMOVE</div>
                            </div>
                            : <>
                                {singleCourseDetailsDataContainer?.premium ? <></> : <>
                                    <div style={{ color: "#00A566", fontWeight: "600" }}>

                                        <div className=' r-c-c' style={{ display: 'flex' }}>
                                            <Input
                                                className='price-coupon-filed'
                                                type="text"
                                                placeholder='Enter promotional offer'
                                                onChange={(e) => handleInputCode(e)}
                                                // value={params?.code}
                                                style={{ height: "4.5vh", borderRadius: " 12px 0px 0px 12px", }}
                                            ></Input>

                                            <Button
                                                loading={applyLoader}
                                                className='r-c-c text-xm bold-600'
                                                type="button"
                                                onClick={handleApplyClick}

                                                style={{
                                                    cursor: isInputTyped ? 'pointer' : 'not-allowed',
                                                    border: ` 1px solid #594099 ${isInputTyped ? 1 : 0.4}`,
                                                    // border: ` 1px solid #594099 `,
                                                    borderRadius: " 0px 12px 12px 0px",
                                                    backgroundColor: isInputTyped ? "#594099" : "white",
                                                    padding: '5px 10px',
                                                    color: isInputTyped ? "white" : "#594099",
                                                    height: "4.5vh",
                                                    opacity: isInputTyped ? 1 : 0.4
                                                    // opacity: 1
                                                }}
                                                disabled={!isInputTyped}
                                            >
                                                APPLY
                                            </Button>
                                        </div>
                                        {invalidCouponType ?
                                            <div style={{ color: "red", fontWeight: "normal" }}>{InvalidCouponMessage[invalidCouponType] || ''}</div>
                                            : null}
                                    </div>
                                </>}
                                {couponList?.items?.length > 0 && balanceAfter !== 0 ?
                                    <div style={{ color: "#1089FF", cursor: "pointer", fontWeight: "bold" }} onClick={() => { setIsCouponModal(true), setSelectedCouponCode(null) }} >VIEW ALL PROMOTIONAL OFFERS</div>
                                    : null}
                            </>}
                    </div>

                    <div className='m-t-15 r-c-fs' style={{ fontSize: "14px", fontWeight: "600", color: "#636363", width: "100%", background: "#F4F0FF", borderRadius: "12px", opacity: 1, padding: "10px 20px" }} >Total payable amount (after registration and discounts): <span style={{ color: "black", fontWeight: "bold" }}>&nbsp;₹ {storePromoPrice ? storePromoPrice?.balancePayable.toLocaleString() : balanceAfter.toLocaleString()}</span> </div>

                    <div>
                        {storePromoPrice?.courseBilling?.length > 0 /* || singleCourseDetailsDataContainer?.courseBilling?.length > 0 */ ? <>
                            <div style={{ color: "#191919", fontWeight: "600", marginTop: "10px" }}>Payment term</div>

                            <div style={{ display: "flex", marginTop: "10px" }}>
                                {(storePromoPrice?.courseBilling?.length > 0 ? storePromoPrice?.courseBilling : singleCourseDetailsDataContainer?.courseBilling)?.map((term, index) => (
                                    <div

                                        key={index}
                                        className='r-c-c'
                                        style={{ ...termStyle, ...(selectedTerm === term?.billingPlan ? selectedStyle : {}) }}
                                        onClick={() => handleTermClick(index, term?.billingPlan, term?.installments)}
                                    >
                                        {term?.billingPlanLabel}
                                    </div>
                                ))}
                            </div>

                            {termList && <InstallmentList list={termList} />}
                        </> : <>
                            {termList?.length > 0 ? <>
                                <div style={{ color: "#191919", fontWeight: "600", marginTop: "10px" }}>Payment term</div>

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    {(storePromoPrice?.courseBilling?.length > 0 ? storePromoPrice?.courseBilling : singleCourseDetailsDataContainer?.courseBilling)?.map((term, index) => (
                                        <div
                                            key={index}
                                            className='r-c-c'
                                            style={{ ...termStyle, ...(selectedTerm === term?.billingPlan ? selectedStyle : {}) }}
                                            onClick={() => handleTermClick(index, term?.billingPlan, term?.installments)}
                                        >
                                            {term?.billingPlanLabel}
                                        </div>
                                    ))}

                                </div>
                                {termList && <InstallmentList list={termList} />}
                            </> : null}

                        </>}
                    </div>

                    <div className='m-t-20 r-c-sb' style={{ fontSize: "14px", width: "100%" }} >
                        <div className='r-c-sb m-t-20' style={{ borderTop: "1px solid #AEAEAE", width: "100%" }}>
                            <div className='m-t-10'>
                                <div style={{ color: "#636363", fontSize: "14px" }}>Payable now <div style={{ color: "#191919", fontWeight: "bold", fontSize: "16px" }}>₹ {singleCourseDetailsDataContainer?.registrationFee.toLocaleString()} <span style={{ color: "#636363", fontWeight: "normal", fontSize: "14px" }}>(Registration fee)</span> </div></div>
                            </div>
                            {hideProceed ? null :
                                <Button type="primary" disabled={disablePay} className=' cursor-pointer' onClick={handleProceedToPayment} style={{ fontSize: "14px", fontWeight: "bold" }}>{from == "checkout" ? "DONE" : "PROCEED TO CHECKOUT"}</Button>
                            }
                        </div>

                    </div>
                </div>
            </Modal >
            <CouponsModal
                isCouponModal={isCouponModal}
                setIsCouponModal={setIsCouponModal}
                from={"coursePage"}
                singleCourseDetailsDataContainer={singleCourseDetailsDataContainer}
                setInvalidCouponType={setInvalidCouponType}
            />

        </div >
    )
}


const mapStateToProps = (state) => {
    const { selectedCouponCode, storePromoPrice, selectedTerm, termList, couponList, activeTermIndex } = state.studentMarketPlace
    const { user } = state.session
    return { user, selectedCouponCode, storePromoPrice, selectedTerm, termList, couponList, activeTermIndex };
};

const mapDispatchToProps = (dispatch) => ({
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCheckPayment: (param, callback) => dispatch(getCheckPayment(param, callback)),
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),
    checkLiveCourse: (param) => dispatch(checkLiveCourse(param)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    payNowCart: (params, callback) => dispatch(payNowCart(params, callback)),
    getCouponList: (param) => dispatch(getCouponList(param)),

    setSelectedCouponCode: (val) => dispatch(setSelectedCouponCode(val)),
    setStorePromoPrice: (val) => dispatch(setStorePromoPrice(val)),
    setSelectedTerm: (val) => dispatch(setSelectedTerm(val)),
    setTermList: (val) => dispatch(setTermList(val)),
    setActiveTermIndex: (val) => dispatch(setActiveTermIndex(val)),
    applyPromoCheckout: (params, callback, succesCallback, errorCallback = null) => dispatch(applyPromoCheckout(params, callback, succesCallback, errorCallback)),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseInstallmentModal)