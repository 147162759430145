import moment from "moment";
import { Api } from "services";
import {
  UPDATE_DASHBOARD_DATA,
  UPDATE_STUDENT_TEST_FILTER,
  SET_SELECTED_SUB,
  UPDATE_STUDENT_TEST_COUNT,
  SET_SELECTED_TEST,
  UPDATE_TEST_RESULT,
  SET_TEST_DURATION,
  SET_CURRENT_STUDENT_QUESTION,
  SET_CURRENT_STUDENT_QUESTION_RESULT,
  UPDATE_PALATE_DATA,
  UPDATE_COUNT_DATA,
  SET_CURRENT_INDEX,
  UPDATE_TEST_SUBS_LIST,
  SET_SELECTED_SUB_INDEX,
  UPDATE_SOLUTION_COUNT_DATA,
  UPDATE_Q_PARAM,
  UPDATE_ANSWER_IMAGES,
  REMOVE_ANSWER_IMAGE,
  CLEAR_IMG_LIST,
  UPDATE_CURRENT_DURATION,
  UPDATE_SOLUTION_SUBS_LIST,
  TOGGLE_OUT_OF_TIME_MODAL,
  UPDATE_QUESTIONS_CONTAINER,
  UPDATE_CURRENT_SOLUTION_INDEX,
  UPDATE_TEST_TYPE,
  UPDATE_STUDENT_TEST_DATA,
  SET_STOP_TIME,
  CHECK_FOR_QUES_TYPE,
  STORE_SOLUTION_QUES_TYPE,
  STOP_TIMER,
  UPDATE_CURRENT_Q_ID,
  UPDATE_PREVIOUS_Q_ID
} from "../actions/studentTest";

const initialState = {
  ongoingTest: null,
  publishedTests: [],
  scheduledTests: [],
  attemptedTests: [],
  missedTests: [],
  totalTests: null,
  missedTestsCount: null,
  subjects: [],
  syllabuses: [],
  studentTestFilterParams: {
    rangeType: null,
    startDate: null,
    endDate: null,
    testStatus: "AVAILABLE",
  },
  selectedSub: {},
  selectedTest: {},
  testResult: {},
  timeLeft: {
    hours: null,
    minutes: null,
  },
  currentQ: {},
  palateData: [],
  qCounts: {
    attempted: 0,
    attemptedAndBookmarked: 0,
    bookmarked: 0,
    unanswered: 0,
    unseen: 0,
  },
  currentIndex: null,
  testSubjects: [],
  selectedSubIndex: 0,
  solutionCounts: {
    correct: 0,
    incorrect: 0,
    partialCorrect: 0,
    unattempted: 0,
    unseen: 0
  },
  currentImages: [],
  currentDuration: 0,
  isOutOfTimeModalVisible: false,
  questionsContainer: [],
  currentSolutionIndex: 0,
  testType: '',
  studentTestData: {},
  stopTime: false,
  quesType: '',
  solQuesType: '',
  currentQId: null,
  previousQId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_Q_ID:
      return {
        ...state,
        currentQId: action.currentQId
      }
    case UPDATE_PREVIOUS_Q_ID:
      return {
        ...state,
        previousQId: action.previousQId
      }
    case TOGGLE_OUT_OF_TIME_MODAL:
      return {
        ...state,
        isOutOfTimeModalVisible: action.value,
      };
    case UPDATE_CURRENT_DURATION:
      return {
        ...state,
        currentDuration: action.seconds,
      };
    case SET_STOP_TIME:
      return {
        ...state,
        stopTime: action.res
      }
    case CLEAR_IMG_LIST:
      return {
        ...state,
        currentImages: [],
      };
    case REMOVE_ANSWER_IMAGE:
      let removedImagePrefix = state.currentImages[action.index].split(":")[0];
      let noOfRemoteImages = state.currentQ.attempt ? state.currentQ.attempt.filename ? state.currentQ.attempt.filename.length : 0 : 0
      let localFileIndex = action.index - noOfRemoteImages;
      if (removedImagePrefix === 'https') {
        let API_URL = "https://s3.ap-south-1.amazonaws.com/"
        const [orgpath, ...rest] = state.currentImages[action.index].replace(API_URL, "").split('/')
        let filename = rest.join("/")
        if (state.currentQ.deletedFiles && state.currentQ.deletedFiles.length) {
          state.currentQ.deletedFiles.push(filename);
        } else {
          state.currentQ.deletedFiles = state.currentImages
            ? [filename]
            : [];
        }
      } else if (removedImagePrefix === 'data') {
        if (state.currentQ.files && state.currentQ.files.length) {
          state.currentQ.files.splice(localFileIndex, 1);
        }
      }
      state.currentImages.splice(action.index, 1); // has to be here

      return {
        ...state,
        currentQ: { ...state.currentQ },
        currentImages: [...state.currentImages],
      };
    case UPDATE_ANSWER_IMAGES:
      if (state.currentQ.files && state.currentQ.files.length) {
        state.currentQ.files.push(action.originalObject);
      } else {
        state.currentQ.files = [action.originalObject];
      }
      if (state.currentImages && state.currentImages.length) {
        state.currentImages.push(action.base64Img);
      } else {
        state.currentImages = [action.base64Img];
      }
      return {
        ...state,
        currentQ: { ...state.currentQ },
        currentImages: [...state.currentImages],
      };
    case SET_SELECTED_SUB_INDEX:
      return {
        ...state,
        selectedSubIndex: action.index,
      };
    case UPDATE_TEST_SUBS_LIST:
      return {
        ...state,
        testSubjects: [...action.data],
      };
    case UPDATE_SOLUTION_SUBS_LIST:
      return {
        ...state,
        solutionSubjects: [...action.data],
      };
    case UPDATE_Q_PARAM:
      return {
        ...state,
        currentQ: { ...state.currentQ, ...action.qParam },
      };
    case SET_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.index,
      };
    case UPDATE_SOLUTION_COUNT_DATA:
      return {
        ...state,
        solutionCounts: {
          ...state.solutionCounts,
          ...action.solutionCountData,
        },
      };
    case UPDATE_COUNT_DATA:
      return {
        ...state,
        qCounts: { ...state.qCounts, ...action.countData },
      };
    case UPDATE_PALATE_DATA:
      return {
        ...state,
        palateData: action.data,
      };
    case SET_CURRENT_STUDENT_QUESTION: {
      const answerObj = action.data?.attempt?.answerObj;
      const data = action.data;
      if (data?.type === "Subjective") {
        if (action.data.attempt?.filename) {
          state.currentImages = action.data.attempt?.filename.map(
            (path) => `${Api._s3_url}${path}`
          );
        }
      }
      return {
        ...state,
        currentQ: { ...action.data, answerObj },
        currentImages: [...state.currentImages],
        currentDuration: action.data?.attempt?.duration || 0,
      };
    }
    case SET_CURRENT_STUDENT_QUESTION_RESULT: {
      const currentAns = action.data?.attempt?.answer;
      const data = action.data;
      if (data?.type === "Subjective") {
        if (action.data.attempt?.filename) {
          state.currentImages = action.data.attempt?.filename.map(
            (path) => `${Api._s3_url}${path}`
          );
        }
      }
      return {
        ...state,
        currentQ: { ...action.data, currentAns },
        currentImages: [...state.currentImages],
        currentDuration: action.data?.attempt?.duration || 0,
      };
    }
    case SET_TEST_DURATION:
      if (!action.duration) {
        return {
          ...state,
          timeLeft: {
            hours: 0,
            minutes: 0,
            seconds: 0,
            inMinutes: 0,
          },
        };
      }
      return {
        ...state,
        timeLeft: {
          hours: Math.floor(action.duration / 3600).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          minutes: Math.floor((action.duration % 3600) / 60).toLocaleString(
            "en-US",
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          ),
          seconds: Math.floor((action.duration % 3600) % 60).toLocaleString(
            "en-US",
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          ),
          inMinutes: action.duration / 60,
        },
      };
    case UPDATE_TEST_RESULT:
      return {
        ...state,
        testResult: action.data,
      };
    case SET_SELECTED_TEST:
      return {
        ...state,
        selectedTest: { ...action.data },
      };
    case UPDATE_STUDENT_TEST_COUNT:
      return {
        ...state,
        subTestCount: action.count,
      };
    case SET_SELECTED_SUB:
      return {
        ...state,
        selectedSub: { ...action.data },
      };
    case UPDATE_STUDENT_TEST_FILTER:
      return {
        ...state,
        studentTestFilterParams: {
          ...state.studentTestFilterParams,
          ...action.filterParams,
        },
      };
    case UPDATE_DASHBOARD_DATA:
      let timeLeft;
      if (action.data.onGoingLiveTest) {
        const now = moment();
        timeLeft = moment
          .duration(
            moment(action.data.onGoingLiveTest.endTime, "HH:mm:ss").diff(
              now
            )
          )
          .asSeconds();
      }
      return {
        ...state,
        ongoingTest: action.data.onGoingLiveTests,
        publishedTests: action.data.publishedResults,
        scheduledTests: action.data.upcomingLiveTest,
        attemptedTests: action.data.attempted,
        totalTestsCount: action.data.testsAvailable ? action.data.testsAvailable : 0,
        missedTestsCount: action.data.missed ? action.data.missed : 0,
        missedTests: action.data.missedTests,
        subjects: action.data.subjects,
        syllabuses: action.data.syllabuses,

        timeLeft: {
          hours: Math.floor(timeLeft / 3600).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          minutes: Math.floor((timeLeft % 3600) / 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          seconds: Math.floor((timeLeft % 3600) % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          inMinutes: timeLeft / 60,
        },
      };
    case UPDATE_QUESTIONS_CONTAINER:
      return {
        ...state,
        questionsContainer: action.questions
      }
    case UPDATE_TEST_TYPE:
      return {
        ...state,
        testType: action.testType
      }
    case UPDATE_STUDENT_TEST_DATA: {
      let temp = { ...state.studentTestData };
      temp[action.key] = action.val;
      return {
        ...state,
        studentTestData: temp,
      };
    }

    case CHECK_FOR_QUES_TYPE:
      return {
        ...state,
        quesType: action.res
      }

    case STORE_SOLUTION_QUES_TYPE:
      return {
        ...state,
        solQuesType: action.res
      }

    case STOP_TIMER:
      return {
        ...state,
        stopTimer: action.res
      }

    default:
      return state;
  }
};

export default reducer;
