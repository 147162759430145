import "antd/lib/message/style/index";
import { Api } from "../services";

export const SUBMIT_MODAL_FUN = "SUBMIT_MODAL_FUN";
export const REVIEW_MODAL_FUN = "REVIEW_MODAL_FUN";
export const LEAVE_MODAL_FUN = "LEAVE_MODAL_FUN";
export const TIMER_OVER_MODAL_FUN = "TIMER_OVER_MODAL_FUN";
export const TIMER_ALERT_MODAL_FUN = "TIMER_ALERT_MODAL_FUN";
export const STUDENT_TEST_DATA = "STUDENT_TEST_DATA";
export const STUDENT_TEST_HOME_LOADER = "STUDENT_TEST_HOME_LOADER";
export const STUDENT_TEST_SUB_LOADER = "STUDENT_TEST_SUB_LOADER";
export const STUDENT_SUBJECT_DATA = "STUDENT_SUBJECT_DATA";
export const STUDENT_SUBJECT_HEADER = "STUDENT_SUBJECT_HEADER";
export const STUDENT_TEST_DETAIL_LOADER = "STUDENT_TEST_DETAIL_LOADER";
export const STUDENT_TEST_DETAIL = "STUDENT_TEST_DETAIL";
export const STUDENT_TEST_COLLECT_QUES_INDEX = "STUDENT_TEST_COLLECT_QUES_INDEX";
export const STUDENT_TEST_COLLECT_MCQ = "STUDENT_TEST_COLLECT_MCQ";
export const STUDENT_TEST_COLLECT_BOOKMARK = "STUDENT_TEST_COLLECT_BOOKMARK";
export const STUDENT_TEST_UPDATE_ANS = "STUDENT_TEST_UPDATE_ANS";
export const STUDENT_TEST_COLLECT_IMAGE = "STUDENT_TEST_COLLECT_IMAGE";
export const DELETE_QUES_IMG_MODAL_FUN = "DELETE_QUES_IMG_MODAL_FUN";
export const CONFROM_DELETE_QUES_IMG_MODAL_FUN = "CONFROM_DELETE_QUES_IMG_MODAL_FUN";
export const STUDENT_TEST_POST_QUES_LOADER = "STUDENT_TEST_POST_QUES_LOADER";
export const GET_STUDENT_TEST_PUBLISH_DATE = "GET_STUDENT_TEST_PUBLISH_DATE";
export const STUDENT_TEST_RESULT_DATA = "STUDENT_TEST_RESULT_DATA";
export const STUDENT_TEST_RESULT_LOADER = "STUDENT_TEST_RESULT_LOADER";
export const STUDENT_TEST_ANSWER_SHEET = "STUDENT_TEST_ANSWER_SHEET";
export const UPDATE_STUDENT_TEST_ANSWER_INDEX = "UPDATE_STUDENT_TEST_ANSWER_INDEX";
export const STUDENT_TEST_DURATION_TIMER = "STUDENT_TEST_DURATION_TIMER";
export const STUDENT_TEST_DURATION_DECREMENT = "STUDENT_TEST_DURATION_DECREMENT";
export const IMAGE_ZOOM_MODAL_FUN = "IMAGE_ZOOM_MODAL_FUN";
export const SET_IMG_OF_REVIEW_QUESTION = "SET_IMG_OF_REVIEW_QUESTION";
export const STUDENT_TEST_CURRENT_TIME_LOADER = "STUDENT_TEST_CURRENT_TIME_LOADER";
export const UPDATE_STUDENT_TEST_CURRENT_TIME = "UPDATE_STUDENT_TEST_CURRENT_TIME";
export const STUDENT_TEST_POST_SUBMIT_LOADER = "STUDENT_TEST_POST_SUBMIT_LOADER";


export const testSutdentLoader = (boolGroup, boolName, bool) => ({
  type: boolGroup,
  boolName,
  bool
});
export const getStudentTestData = (subjectId, callback) => {
  return (dispatch) => {
    if(subjectId === ''){
      dispatch(testSutdentLoader(STUDENT_TEST_HOME_LOADER, 'studentTestLoader',  true));
    }else{
      dispatch(testSutdentLoader(STUDENT_TEST_SUB_LOADER, 'studentTestSubLoader',  true));
    }
    const url = subjectId === '' ? `/test/get/all-tests-for-student` : `/test/get/all-tests-for-student?subjectId=${subjectId}`;
    return Api.get(url).send((response, error) => {
      console.log('response student test data++', response);
      dispatch(testSutdentLoader(STUDENT_TEST_HOME_LOADER, 'studentTestLoader', false));
      dispatch(testSutdentLoader(STUDENT_TEST_SUB_LOADER, 'studentTestSubLoader',  false));
      if(typeof response != undefined) {
        if(subjectId === ''){
          dispatch(studentTestData(response));
        }else{
          dispatch(studentSubjectData(response));
        }
        callback(response);
      }
    });
  }
}
export const studentTestData = res => ({
  type: STUDENT_TEST_DATA,
  res
});
export const studentSubjectData = res => ({
  type: STUDENT_SUBJECT_DATA,
  res
});
export const getStudentSubjectHeader = (name, id) => ({
  type: STUDENT_SUBJECT_HEADER,
  name,
  id
});

export const submitModalFun = bool => ({
  type: SUBMIT_MODAL_FUN,
  bool
});
export const reviewModalFun = bool => ({
  type: REVIEW_MODAL_FUN,
  bool
});
export const leaveModalFun = bool => ({
  type: LEAVE_MODAL_FUN,
  bool
});
export const timerOverModalFun = bool => ({
  type: TIMER_OVER_MODAL_FUN,
  bool
});
export const timerAlertModalFun = bool => ({
  type: TIMER_ALERT_MODAL_FUN,
  bool
});
export const imageZoomModalFun = (bool, index) => ({
  type: IMAGE_ZOOM_MODAL_FUN,
  bool,
  index
});
export const deleteQuesImgModalFun = (bool, index) => ({
  type: DELETE_QUES_IMG_MODAL_FUN,
  bool,
  index
});
export const confromDeleteQuesImgModalFun = () => ({
  type: CONFROM_DELETE_QUES_IMG_MODAL_FUN,
});

export const getStudentTestDetail = (id, tabname, callback) => {
  return (dispatch) => {
    dispatch(testSutdentLoader(STUDENT_TEST_DETAIL_LOADER, 'studentTestDetailLoader',  true));
    return Api.get(`/test/${id}`).send((response, error) => {
      console.log('response student test details++', response);
      dispatch(testSutdentLoader(STUDENT_TEST_DETAIL_LOADER, 'studentTestDetailLoader',  false));
      // alert('insidee fn')
      if(response !== undefined) {
        dispatch(studentTestDetial(response, tabname, id));
        callback(response);
      }
    });
  }
}

export const studentTestDetial = (res, tabname, testLiveId) => ({
  type: STUDENT_TEST_DETAIL,
  res,
  tabname,
  testLiveId,
});
export const studentTestCollectQuesIndex = index => ({
  type: STUDENT_TEST_COLLECT_QUES_INDEX,
  index
});
export const studentTestcollectMcq = id => ({
  type: STUDENT_TEST_COLLECT_MCQ,
  id
});
export const studentTestcollectBookmark = () => ({
  type: STUDENT_TEST_COLLECT_BOOKMARK,
});
export const studentTestcollectImage = (imgObj, urlPath) => ({
  type: STUDENT_TEST_COLLECT_IMAGE,
  imgObj,
  urlPath,
});

export const postStudentTest = (params, type, callback) => {
  return (dispatch) => {
    if(params.testStatus === 'Submitted'){
      dispatch(testSutdentLoader(STUDENT_TEST_POST_SUBMIT_LOADER, 'studentTestPostSubmitLoader',  true));
    }else{
      dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  true));
    }
    if(type !== 'image'){
      return Api.post(`/test/add/test-student-ans`).params(params).send((response, error) => {
        console.log('response student test post api++', response);
        dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
        dispatch(testSutdentLoader(STUDENT_TEST_POST_SUBMIT_LOADER, 'studentTestPostSubmitLoader',  false));
        if(response !== undefined) {
          // dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
          dispatch(getStudentTestPublishDate(response));
          callback(response);
        }
      });
    }else{
      return Api.post("/test/add/test-student-ans").upload(
          params, (percent, response) => {
            // dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
            console.log('response image+++', response);
            console.log('response percent+++', percent);
            if (percent == -1) {
              dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
              dispatch(testSutdentLoader(STUDENT_TEST_POST_SUBMIT_LOADER, 'studentTestPostSubmitLoader',  false));
            }
            if(percent == 100 && response != undefined){
              dispatch(getStudentTestPublishDate(response.data));
              callback(response.data);
              dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
              dispatch(testSutdentLoader(STUDENT_TEST_POST_SUBMIT_LOADER, 'studentTestPostSubmitLoader',  false));
            }
          }
        );
    }
  }
}
export const studentTestUpdateAns = () => ({
  type: STUDENT_TEST_UPDATE_ANS,
});
export const getStudentTestPublishDate = (res) => ({
  type: GET_STUDENT_TEST_PUBLISH_DATE,
  res,
});

export const getStudentTestResult = (id, callback) => {
  return (dispatch) => {
    dispatch(testSutdentLoader(STUDENT_TEST_RESULT_LOADER, 'studentTestResultLoader',  true));
    return Api.get(`/test/result/${id}`).send((response, error) => {
      console.log('response student test result++', response);
      dispatch(testSutdentLoader(STUDENT_TEST_RESULT_LOADER, 'studentTestResultLoader',  false));
      if(response !== undefined) {
        dispatch(studentTestResultData(response));
        callback(response);
      }
    });
  }
}
export const studentTestResultData = (res) => ({
  type: STUDENT_TEST_RESULT_DATA,
  res,
});

export const studentTestAnswerSheet = (arr, index, qNo) => ({
  type: STUDENT_TEST_ANSWER_SHEET,
  arr,
  index,
  qNo,
});
export const updateStudentTestAnswerIndex = (index, QNo) => ({
  type: UPDATE_STUDENT_TEST_ANSWER_INDEX,
  index,
  QNo
});
export const getStudentTestDurationTimer = (time) => ({
  type: STUDENT_TEST_DURATION_TIMER,
  time,
});
export const getStudentTestDurationDecrement = (time) => ({
  type: STUDENT_TEST_DURATION_DECREMENT,
  time,
});
export const setImgsofReviewQuesiton = (arr) => ({
  type: SET_IMG_OF_REVIEW_QUESTION,
  arr,
});


export const updateStudentTestCurrentTime = res => ({
  type: UPDATE_STUDENT_TEST_CURRENT_TIME,
  res
});

export const getStudentTestCurrentTime = (id, callback) => {
  return (dispatch) => {
    dispatch(testSutdentLoader(STUDENT_TEST_CURRENT_TIME_LOADER, 'studentTestCurrentTimeLoader',  true));
    return Api.get(`/test/time/current-time/${id}`).send((response, error) => {
      console.log('current time', response);
      dispatch(testSutdentLoader(STUDENT_TEST_CURRENT_TIME_LOADER, 'studentTestCurrentTimeLoader',  false));
      if(response != undefined) {
        dispatch(updateStudentTestCurrentTime(response));
        callback(response);
      }
    });
  }
}
