import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'

import { AiOutlinePlus, } from "react-icons/ai";
const Up = require("../../../Assets/test-new/ic-chevron-up.svg").default;
const Down = require("../../../Assets/test-new/ic-chevron-down.svg").default;

const QueGridSide = ({ generateQuesObj, generateQuesArr, updateSubWithChapters, clearGenerateQuesFields, getMarkingSchemeMarks, schemeId,
  generateQuesSubChapters, getEachQuesData, editGenerateQueId, getEachQuesDataBool, updateNumericAnswer, selectedCardType, selectedSectionId, setSelectedSectionId, storeSectionIndex, storeSchemeId, nonEditable }) => {

  console.log('data', generateQuesArr.subjects)
  const [closed, setClosed] = useState({})

  useEffect(() => {
    if (generateQuesArr?.subjects) {
      generateQuesArr?.subjects?.map((item) => {
        // let id = item.subjId
        setClosed((prevState) => ({
          ...prevState,
          [item.subjId]: true
        }))
      })
    }
  }, [])

  console.log('closed', closed)

  let toggleViewHandler = (val) => {
    setClosed((prevState) => ({
      ...prevState,
      [val]: !prevState[val]
    }))
  }

  console.log('generateQuesArr', generateQuesArr)
  // console.log('')


  return (
    <div>
      {generateQuesArr && Object.keys(generateQuesArr)?.length ?
        generateQuesArr?.subjects?.map((item) => (
          <div keys={item.subjId}>
            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => { toggleViewHandler(item.subjId) }}>
              <div style={{ color: '#636363' }} className='text-sm bold-600 m-b-10'>{item.subName}</div>
              <div>
                <img src={closed[item.subjId] ? Up : Down} />
              </div>
            </div>
            {closed[item.subjId]
              ?
              <div>
                {item.sections
                  ?
                  item.sections.map((sec, secIndex) => (
                    <div>
                      <div style={{ color: '#191919' }} className='text-xmd bold-600 m-b-10'>{sec?.sectionName}</div>
                      <div className='r-ac flex-wrap' style={{ marginLeft: -10 }}>
                        {sec?.questions?.map((quee, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              updateNumericAnswer('');
                              getEachQuesData(quee.id);
                              setSelectedSectionId(sec?.sectionId)
                              storeSectionIndex(secIndex)
                              updateSubWithChapters(item.subName);
                              storeSchemeId(sec?.schemeId)
                            }}
                            style={{
                              borderColor: quee?.id === editGenerateQueId ? '#636363' : sec?.comments != null ? sec?.comments[index] == 1 ? 'orange' : '' : '',
                              borderWidth: quee?.id === editGenerateQueId ? 2 : 1,
                              color: sec?.comments != null ? sec?.comments[index] == 1 ? 'orange' : 'black' : 'black'
                            }}
                            className='r-c-c radius-100 test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
                            {getEachQuesDataBool && quee.id === editGenerateQueId ? <LoadingOutlined /> : quee.index}
                          </div>
                        ))}
                        {!nonEditable
                          ?
                          <div
                            onClick={() => {
                              updateSubWithChapters(item.subName);
                              storeSchemeId(sec?.schemeId)
                              clearGenerateQuesFields();
                              storeSectionIndex(secIndex)
                              setSelectedSectionId(sec?.sectionId)
                              if (generateQuesArr?.testType === 'Subjective' && (sec?.schemeId !== null && sec?.schemeId > 0)) {
                                console.log('inside')
                                setTimeout(() => {
                                  getMarkingSchemeMarks(generateQuesArr?.testType)
                                }, 3000)
                              }
                            }}
                            style={{ borderColor: (sec.sectionId === selectedSectionId && (editGenerateQueId === null || editGenerateQueId === undefined)) ? '#636363' : '', borderWidth: (sec.sectionId === selectedSectionId && (editGenerateQueId === null || editGenerateQueId === undefined)) ? 2 : 1 }}
                            className='r-c-c radius-100 a2grey text-sm test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
                            <AiOutlinePlus onClick={(val) => { console.log('val', val) }} style={{ color: (sec.sectionId === selectedSectionId && (editGenerateQueId === null || editGenerateQueId === undefined)) ? '#191919' : '' }} />
                          </div>
                          :
                          null
                        }

                      </div>

                    </div>

                  ))
                  :
                  <div className='r-ac flex-wrap' style={{ marginLeft: -10 }}>
                    {item.questions?.map((quee, index) => (

                      <div
                        key={index}
                        onClick={() => {
                          updateNumericAnswer('');
                          getEachQuesData(quee.id);
                          setSelectedSectionId(null)
                          storeSchemeId(item?.schemeId)
                        }}
                        style={{
                          borderColor: quee.id === editGenerateQueId ? '#636363' : item.comments != null ? item?.comments[index] == 1 ? 'orange' : '' : '',
                          borderWidth: quee.id === editGenerateQueId ? 2 : 1,
                          color: item.comments != null ? item?.comments[index] == 1 ? 'orange' : 'black' : 'black'
                        }}
                        className='r-c-c radius-100 test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
                        {getEachQuesDataBool && quee.id === editGenerateQueId ? <LoadingOutlined /> : quee.index}
                      </div>
                    ))}
                    {!nonEditable
                      ?
                      <div
                        onClick={() => {
                          updateSubWithChapters(item.subName);
                          storeSchemeId(item?.schemeId)
                          clearGenerateQuesFields();
                          setSelectedSectionId(null)
                          if (generateQuesArr?.testType === 'Subjective' && (item?.schemeId !== null && item?.schemeId > 0)) {
                            setTimeout(() => {
                              getMarkingSchemeMarks(generateQuesArr?.testType)
                            }, 3000)
                          }
                        }}
                        style={{ borderColor: (item.subName === generateQuesSubChapters.name && (editGenerateQueId === null || editGenerateQueId === undefined)) ? '#636363' : '', borderWidth: (item.subName === generateQuesSubChapters.name && (editGenerateQueId === null || editGenerateQueId === undefined)) ? 2 : 1 }}
                        className='r-c-c radius-100 a2grey text-sm test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
                        <AiOutlinePlus style={{ color: (item.subName === generateQuesSubChapters.name && (editGenerateQueId === null || editGenerateQueId === undefined)) ? '#191919' : '' }} />
                      </div>
                      :
                      null
                    }

                  </div>
                }
              </div>
              :
              null
            }


          </div>
        ))
        :
        null
      }

    </div >
  )
}

export default QueGridSide;