import { Api } from "services";

export const SET_CRM_DASHBOARD_DATA = "SET_CRM_DASHBOARD_DATA";
export const SET_CRM_LEADS_DATA = "SET_CRM_LEADS_DATA";
export const SET_CRM_CONVERSIONS_DATA = "SET_CRM_CONVERSIONS_DATA";
export const SET_CRM_IMPRESSION_GRAPH_DATA = "SET_CRM_IMPRESSION_GRAPH_DATA"
export const SET_CRM_LEADS_CONVERSIONS_GRAPH_DATA = "SET_CRM_LEADS_CONVERSIONS_GRAPH_DATA"
export const APPEND_CRM_CONVERSIONS_DATA = "APPEND_CRM_CONVERSIONS_DATA";
export const APPEND_CRM_LEADS_DATA = "APPEND_CRM_LEADS_DATA"


export const setCrmDashboardData = (val) => ({
    type: SET_CRM_DASHBOARD_DATA,
    val
})

export const setCrmLeadsData= (val) => ({
    type: SET_CRM_LEADS_DATA,
    val
})

export const setCrmConversionsData= (val) => ({
    type: SET_CRM_CONVERSIONS_DATA,
    val
})

export const setCrmImpressionGraphData= (val) => ({
    type: SET_CRM_IMPRESSION_GRAPH_DATA,
    val
})

export const setCrmLeadsConversionsGraph= (val) => ({
    type: SET_CRM_LEADS_CONVERSIONS_GRAPH_DATA,
    val
})

export const appendCrmLeadsData = res => ({
    type: APPEND_CRM_LEADS_DATA,
    res
})


export const appendCrmConversionsData = res => ({
    type: APPEND_CRM_CONVERSIONS_DATA,
    res
  })
  

  export const getCrmDashboardData = (startDate,endDate,callBack = null) => {
    return (dispatch, getState) => {
        const params = {
            startDate: startDate,
            endDate: endDate,
            // year: isYear,
            // month: moment(isMonth, 'MMM').format('MMMM')
        }
        return Api.get(`/market/v2/overview-crm`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setCrmDashboardData(response))
            }
        })
    }
}

  export const getCrmDashboardLeads = (search,filterParam,page,firstTimeLoading,callBack = null,successCallBack=null) => {
    return (dispatch, getState) => {
             console.log("check321",filterParam);
        const params = {
            // startDate: startDate,
            // endDate: endDate,
            search:search,
            page:page,
            limit:10,
            leadsType: filterParam == 'All' ? ['All'] : filterParam.type 
            // year: isYear,
            // month: moment(isMonth, 'MMM').format('MMMM')
        }
        return Api.get(`/market/v2/get-leads`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (firstTimeLoading) dispatch(setCrmLeadsData(response))
                    else dispatch(appendCrmLeadsData(response))
                if (successCallBack) {
                    successCallBack();
                }
            }
        })
    }
}


export const getCrmDashboardConversions = (search1,filterParamConversion,page1,firstTimeLoading,callBack = null,successCallBack=null) => {
    console.log("page1",filterParamConversion);
       return (dispatch, getState) => {
           const params = {
               // startDate: startDate,
               // endDate: endDate,
               search:search1,
               page:page1,
               limit:10,
               courseType: filterParamConversion == 'All' ? ['All'] : filterParamConversion.type
               // year: isYear,
               // month: moment(isMonth, 'MMM').format('MMMM')
           }
           return Api.get(`/market/v2/get-conversions`).params(params).send((response) => {
               if (callBack) {
                   callBack()
               }
               if (response !== undefined && response?.show?.type != 'error') {
                   if (firstTimeLoading) dispatch(setCrmConversionsData(response))
                    else dispatch(appendCrmConversionsData(response))
                   if (successCallBack) {
                       successCallBack();
                   }
               }
           })
       }
   }

export const getCourseImpressionGraph = (params,callBack = null) => {
 
    return (dispatch, getState) => {
      
        return Api.get(`/market/v2/course-impression-graph`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setCrmImpressionGraphData(response))
            }
        })
    }
}

export const getLeadsConversionsGraph = (params,callBack = null) => {
 
    return (dispatch, getState) => {
      
        return Api.get(`/market/v2/leads-conversions-graph`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setCrmLeadsConversionsGraph(response))
            }
        })
    }
}