import { cancelSchedule, deleteSchedule,fetchSchedule, fetchScheduleFilter, fetchTimetableData } from 'actions/schedule'
import { Button, Modal,Typography} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { EventRegister } from 'react-native-event-listeners'
import { connect } from 'react-redux'

const {Text} = Typography



const DelConfirmModal =({
    isModalVisible,setModalVisible,scheduleId,deleteSchedule,fetchSchedule,stdTimetable,tabType,fetchTimetableData,delOrCancel,cancelSchedule,
    currentDate,teacherSearch,classFilter,subFilter,fetchScheduleFilter
})=>{
    const [status,setStatus] = useState('')
    const handleCancle=()=>{
        setModalVisible(false)
    }
    const handleDelete= async ()=>{
        if(delOrCancel=='deleteSchedule'){
        await deleteSchedule(scheduleId)
        }
        else if(delOrCancel=='cancelOccurance'){
            await cancelSchedule(scheduleId)
        }
        EventRegister.emit('apicall:scheduleHome')
        if(tabType=="schedule"){
            if (currentDate === moment().format('DD/MM/YYYY')) {
                fetchSchedule()
            }
            else {
                fetchScheduleFilter(currentDate?moment(currentDate,'DD/MM/YYYY').format('YYYY-MM-DD'):'', teacherSearch,classFilter,subFilter)
            }
        }
        else if(tabType=='timetable'){
            fetchTimetableData(stdTimetable)
        }
        
        setModalVisible(false)
    }
    useEffect(()=>{
        if(delOrCancel=='deleteSchedule'){
            setStatus('delete')
            }
            else if(delOrCancel=='cancelOccurance'){
                setStatus('cancel')
            }
    },[])
    return(
        <Modal
        className="deleteModal"
        visible={isModalVisible}
        closable={true}
        footer={false}
        onCancel={()=>handleCancle()}
        >
            <div className="deleteModal-content">
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <Text style={{textAlign:'center',color:'#594099',fontWeight:'bold',fontFamily:'roboto',fontSize:'24px'}}>{`Confirm to ${status}?`}</Text><br></br>
                {status=='delete'?
                <Text style={{textAlign:'center',color:'#636363',fontFamily:'roboto',fontSize:'12px'}}>{` Are you sure you want to delete the schedule?`}</Text>
                :
                <Text style={{textAlign:'center',color:'#636363',fontFamily:'roboto',fontSize:'12px'}}>{`Are you sure you want to cancel the class for the chosen date?`}</Text>}
                </div>
                <div style={{width:'60%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginTop:'20px'}}>
                    <Button type='default' style={{width:'100px',borderRadius:'18px'}} onClick={()=>handleCancle()}>NO</Button>
                    <Button type='primary' style={{width:'100px',borderRadius:'18px'}} onClick={()=>handleDelete()}>YES</Button>
                </div>    
            </div>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    const {
        scheduleId,
        tabType,
        stdTimetable,
        currentDate,
        teacherSearch,
        classFilter,
        subFilter
    } = state.schedule;
    return {
        scheduleId,
        tabType,
        stdTimetable,
        currentDate,
        teacherSearch,
        classFilter,
        subFilter
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    deleteSchedule:(id)=>dispatch(deleteSchedule(id)),
    fetchSchedule:()=>dispatch(fetchSchedule()),
    fetchTimetableData:(stdTimetable)=>dispatch(fetchTimetableData(stdTimetable)),
    cancelSchedule:(id)=>dispatch(cancelSchedule(id)),
    fetchScheduleFilter: (date, topic, std, sub) => dispatch(fetchScheduleFilter(date, topic, std, sub))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  
  )(DelConfirmModal);

// export default DelConfirmModal