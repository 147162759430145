import { Color } from "services";
import styled, { css } from "styled-components";

/* New typographies */
//Page header
const PgHeader = styled.div`
  font-size: 1.75rem;
  font-family: Roboto;
  font-weight: 700;
  color: ${Color.black};
`;

//Title for a section
const Title = styled.span`
  font-size: 1.25rem;
  font-family: Roboto;
  color: ${(props) => props.color || Color.black};
  padding: 0;
  marginBottom: -10px;
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

//Title inside card
const Title2 = styled.span`
  font-size: 1rem;
  font-family: Roboto;
  color: ${(props) => props.color || Color.black};
  padding: 0;
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

  //Information
const Information = styled.span`
  font-size: 0.825rem;
  font-family: Roboto;
  color: ${(props) => props.color || Color.darkGrey};
  padding: 0;
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};
`;

//Smaller Information (tooltip text, timestamp, legend, hover card info etc)
const SmallInfo = styled.span`
  font-size: 0.75rem;
  font-family: Roboto;
  color: ${(props) => props.color || Color.midGrey};
  padding: 0;
  marginBottom: -10px;
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

//Modal Heading
const ModalHeading = styled.span`
  font-size: 1.125rem;
  font-family: Roboto;
  color: ${Color.black};
  padding: 0;
  font-weight: bold;
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

const Font17 = styled.span`
  font-size: 1.063rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color || "#191919"};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};
  text-align: ${(props) => (props.noJustify ? "" : "justify")};

  // @media (max-width: 1300px) {
  //   font-size: 0.938rem; 
  // }

  // @media (max-width: 1100px) {
  //   font-size: 0.813rem; 
  // }

  // @media (max-width: 900px) {
  //   font-size: 0.688rem; 
  //   line-height : 20px
  // }

  // @media (max-width: 700px) {
  //   font-size: 0.875rem;
  // }
`;

/* Old typographies */
const Heading = styled.h2`
  font-size: ${(props) => props.size || '1.2em'};
  font-weight: 600;
  color: ${(props) => props.color || Color.black};
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const ModalSubTitle = styled.h6`
  font-size: 0.875rem;
  color: ${(props) => props.color || Color.lightBlack};
  font-weight: 400;
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`

const GraphHeading = styled.h2`
  font-size: 1.2em;
  font-weight: 600;
  color: ${(props) => props.color || Color.black};
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const SubHeading = styled.div`
  font-size: 1em;
  color: ${(props) => props.color || Color.black};
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const SmallHeading = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.color || Color.black};
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

export { Title, Heading, ModalSubTitle, SubHeading, 
    SmallHeading, GraphHeading, PgHeader, Title2,
    Information, SmallInfo, ModalHeading, Font17 };