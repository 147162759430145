import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { Modal, Input, AutoComplete, Select, Progress } from 'antd';
import {
  SubHeading,
} from "components/Typography";
import { FilePdfOutlined } from '@ant-design/icons';
import { DeleteFilled } from '@ant-design/icons';
import {
  updatepdf, setCheckedVideo, setCheckedQuestion, setCheckedLecture, fetchAdminChapterFilter, fetchClasslist,
  setUploadContent, updateStdList, fetchFilterTopic, updateSetType, fetchLibraryData, setKey, postCreateTopic, uploadCompleted2,
  uploadBlock2, uploadVideoMP4, MergeChunk, fileKeyUpdate, updateAdminChapterInfo, updateFilterTopic, subjectFilter
} from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import UploadPdf from '../components/UploadPdf';
import { uploadlecturenotes } from 'actions/digitalLibrary';
import { v4 as uuidv4 } from "uuid";
import { Button } from 'components/Button';
const blockSize = 1048576 * 20;
const blockUploadUrl = "http://fa43-2409-4065-e08-d929-c8c3-8cf8-cffc-39e7.ngrok.io/api/admin-library/UploadChunk";
const uploadCompleteUrl = "http://fa43-2409-4065-e08-d929-c8c3-8cf8-cffc-39e7.ngrok.io/admin-library/UploadComplete";

function Uploadfiles({
  type, uploadlecturenotes,
  ismodelvisible,
  setmodevisible, setmodel,
  Pdftoupload, updatepdf, subjects,
  selectedStd, selectedSubject,
  setCheckedQuestion, setCheckedVideo, setCheckedLecture,
  getChaptersList, fetchAdminChapterFilter, fetchClasslist,
  getStdList, setUploadContent, updateStdList, fetchFilterTopic, topicList, tooglePageLoader,
  updateSetType, fetchLibraryData, setKey, setTabKey, contentPage, selectedChapter, selectedTopic,
  postCreateTopic, uploadCompleted2, uploadBlock2, uploadVideoMP4, fileKey, MergeChunk, fileKeyUpdate,
  updateAdminChapterInfo, classSubjectList, location, subjectFilter
}) {


  const [subjectname, setSubjectName] = useState(null)
  const [inputvalue, setinputvalue] = useState('')
  const [chaptersarray, setchapterarray] = useState([...getChaptersList])
  const [pdf, setpdf] = useState(null)
  const [chapter, setchapter] = useState('')
  const [classid, setclassid] = useState(null)
  const [subjectid, setsubjectid] = useState(null)
  const [chapterid, setchapterid] = useState(null)
  const [options, setOptions] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false)
  const [searchChapter, setSearchChapter] = useState("")
  const [optionsChapter, setOptionsChapter] = useState([]);
  const [chapterLoader, setChapterLoader] = useState(false)
  const [subjectLoder, setSubjectLoader] = useState(false)
  const [className, setClassName] = useState(null)
  const [topicId, setTopicId] = useState(null)
  const [topicName, setTopicName] = useState(null)
  const [classLoader, setClassLoader] = useState(false)
  const [topicLoader, setTopicLoader] = useState(false)
  let [topicListToShow, setTopicListToShow] = useState([])
  const [createLoader, setCreateLoader] = useState(false)
  // const inputFile = useRef(null);
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedFileSize, setSelectedFileSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressCheck, setProgressCheck] = useState(false)
  let blockStart = null;
  let blockEnd = null;
  let currentBlockNumber = null;
  let fileId = null;
  let totalBlocksCount = null;

  const inputchanged = (e) => {
    setinputvalue(e.target.value)
  }

  const searchResult = (query) =>
    query.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {item.name ? item.name : item.subjects_name}
            </span>
          </div>
        ),
      };
    });


  useEffect(() => {
    fileKeyUpdate('')
    subjectFilter('')
  }, [])



  if (location === "subject")
    useEffect(() => {
      console.log("if triggered", classSubjectList);
      setOptions(classSubjectList ? [...searchResult(classSubjectList)] : []);
    }, [classSubjectList])
  else
    useEffect(() => {
      console.log("else triggered", subjects);
      setOptions(subjects ? [...searchResult(subjects)] : [])
    }, [subjects])

  useEffect(() => {
    setOptionsChapter(getChaptersList ? [...searchResult(getChaptersList)] : []);
  }, [getChaptersList])

  useEffect(() => {
    if (selectedSubject && Object.keys(selectedSubject).length !== 0) {
      setsubjectid(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject.subjectId )
      setSubjectName(selectedSubject.subjects_name ? selectedSubject.subjects_name : selectedSubject?.subjectname)
      console.log("AA_selectedSubject",selectedSubject);
      fetchClasslist(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject.subjectId, '')
    }
    if (selectedStd && Object.keys(selectedStd).length !== 0) {
      setclassid(selectedStd.std_id ? selectedStd.std_id : selectedStd.classid)
      setClassName(selectedStd.standard ? selectedStd.standard + '-' + selectedStd.std_section : selectedStd?.className)
      // setclassid(selectedStd.std_id)
      // setClassName(selectedStd.standard + '-' + selectedStd.std_section)
    }

    if (selectedChapter && Object.keys(selectedChapter).length != 0) {
      // setchapterid(selectedChapter.chapterId)
      setchapterid(selectedChapter.chapterId ? selectedChapter.chapterId : selectedChapter?.chapterid)

      setchapter(selectedChapter.chapterName)
      setSearchChapter(selectedChapter.chapterName)
    }

    if (selectedTopic && Object.keys(selectedTopic).length != 0) {
      setTopicId(selectedTopic.topicId)
      setTopicName(selectedTopic.topicName)

    }

    // if (topicList?.length != 0) {
    //   setTopicListToShow([...topicList])
    // }
  }, [])

  useEffect(() => {
    if (topicList?.length != 0) {
      setTopicListToShow(topicList)
    }
    else {
      setTopicListToShow([])
    }
  }, [topicList])

  const handleSearch = (value) => {
    if (!value) {
      setSubjectName('')
      setsubjectid(null)
    }
    else {
      setSubjectName(value)
      setSubjectLoader(true)
    }
    subjectFilter(value, () => setSubjectLoader(false))
  };

  const handleChangeSub = (value, obj) => {
    console.log("changed", value)
    setsubjectid(null)
    setclassid(null)
    setClassName(null)
    setchapterid(null)
    setSearchChapter('')
    setTopicId(null)
    setTopicName(null)
    updateStdList([])
    if (value) {
      setSubjectName(obj?.label?.props?.children?.props?.children)
    }
    subjectFilter(value, () => { })
  }

  const handleSearchChapter = (value) => {
    setTopicId(null)
    setTopicName(null)
    if (!value) {
      setSearchChapter('')
    }
    else {
      setSearchChapter(value)
      setChapterLoader(true)
    }
    fetchAdminChapterFilter(value, classid, subjectid, () => setChapterLoader(false))

  };

  const onSelect = (value, obj) => {
    console.log('selected', obj?.label?.props?.children?.props?.children)

    setSubjectName(obj?.label?.props?.children?.props?.children)
    setsubjectid(value)
    setClassLoader(true)
    fetchClasslist(value, '', () => setClassLoader(false))
    fetchAdminChapterFilter('', classid, value)
    setchapterarray([...getChaptersList])

  };


  const onSelectChapter = (value, obj) => {
    updateFilterTopic([])
    setTopicId(null)
    setTopicName(null)
    setSearchChapter(obj?.label?.props?.children?.props?.children)
    setchapter(obj?.label?.props?.children?.props?.children)
    setchapterid(value)
    setTopicLoader(true)
    fetchFilterTopic(subjectid, classid, value, () => setTopicLoader(false))
  };

  const handleChangeChapter = (value, obj) => {
    setchapterid(null)
    setchapter('')
    setSearchChapter('')
    setTopicId(null)
    setTopicName(null)
    updateFilterTopic([])
    if (value) {
      setSearchChapter(obj?.label?.props?.children?.props?.children)
      setchapter(obj?.label?.props?.children?.props?.children)
    }
  }

  const onSelectTopic = (value, obj) => {
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      let object = {
        chapterId: chapterid,
        topicName: obj?.children[1]
      }
      setTopicName(null)
      postCreateTopic(object, () => setCreateLoader(false), successCallbackTopic)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
  }

  const successCallbackTopic = () => {
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleChangeTopic = (value, obj) => {
    // console.log(value, obj)
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      setTopicName(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleSearchTopic = (value) => {
    // console.log(value)

    if (value && value.trim() != '') {
      setTopicName(value)
      let topic;
      topic = topicList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
      setTopicListToShow(topic)
      setTopicId(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
      setTopicListToShow(topicList)
    }
  }



  // console.log(Pdftoupload)

  const uploadFunction = async (callback, successCallback) => {
    // console.log("value", selectedFile);
    // console.log("filesize", selectedFile.size / 1024 / 1024);
    totalBlocksCount = Pdftoupload?.size % blockSize === 0 ? Pdftoupload?.size / blockSize : Math.floor(Pdftoupload?.size / blockSize) + 1;
    // var completeFile = new Blob([selectedFile]);
    fileId = uuidv4() + "." + Pdftoupload.name.split('.').pop();
    blockStart = 0;
    blockEnd = blockSize;
    // console.log("fielId", fileId);
    // console.log("completefile", completeFile);
    for (currentBlockNumber = 1; currentBlockNumber <= totalBlocksCount; currentBlockNumber += 1) {
      // console.log("paramsssss 32", currentBlockNumber, blockStart, blockEnd);
      var block = Pdftoupload.slice(blockStart, blockEnd);
      // console.log("block ", currentBlockNumber, "   ", block);
      await uploadBlock(block, callback, successCallback);
    }
  };

  const uploadBlock = async (currentBlock, callback, successCallback) => {
    // console.log("block0");
    try {
      // console.log("block1");
      var params = {
        file: currentBlock,
        orderId: currentBlockNumber,
        fileName: fileId
      }
      await uploadBlock2(params, async (response) => {
        if (response != undefined) {
          // console.log("response 52", response)
          // console.log("resData", response);
          if (response?.data?.progress?.message === "uploaded") {
            blockStart = blockEnd;
            blockEnd += blockSize;
            console.log("block uploaded", currentBlockNumber, totalBlocksCount);
            if (currentBlockNumber === totalBlocksCount) {
              var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
              setProgress(percent);
              MergeChunk(fileId, callback, successCallback)
              // await uploadCompleted(callback, successCallback);
            } else {
              var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
              setProgress(percent);
            }
          }
        }
      })
      // const response = await axios.post(
      //     blockUploadUrl,formData, {headers: { 'Content-Type': 'multipart/form-data' }} 
      // );
    } catch (error) {
      console.log("error in block upload", error);
    }
  }

  const callbackChunk = () => {
    setSubmitLoader(false)
    setProgressCheck(false)
  }

  useEffect(() => {
    if (fileKey) {
      uploadCompleted(() => setSubmitLoader(false), successCallback)
    }
  }, [fileKey])

  const successCallbackMergeChunk = () => {
    if (fileKey) {
      uploadCompleted(() => setSubmitLoader(false), successCallback)
    }
  }

  const uploadCompleted = async (callback, successCallback) => {
    // console.log("upload completed api calling")
    var object = {
      subjectId: subjectid,
      chapterId: parseInt(chapterid),
      title: inputvalue,
      standardId: parseInt(classid),
      type,
      fileName: fileKey,
      chapterName: chapter,
      topicId: topicId,
      videoFileSize: Pdftoupload?.size
    }
    uploadCompleted2(object, (response) => {
      // console.log(response)
      if (callback) {
        callback()
      }
      if (response != undefined) {
        if (response) {
          setProgress(100);
          if (successCallback) {
            successCallback()
          }
          // console.log("complete api successss");
        }
      }
      else {
        if (callback) {
          callback()
        }
      }
    })
  };

  const successhandler = () => {
    console.log('subjectID',subjectid , chapterid , inputvalue , classid , chapter, topicId);
    if (Pdftoupload && subjectid && chapterid && inputvalue && classid && chapter, topicId) {
      var objectToUpload = {
        subjectId: subjectid,
        chapterId: parseInt(chapterid),
        title: inputvalue,
        standardId: parseInt(classid),
        type,
        file: Pdftoupload,
        chapterName: chapter,
        topicId: topicId,
      }
      setProgressCheck(true)
      if (type == 'lecture-notes' && contentPage) {
        setTabKey('1')
        updateSetType(type);
      }
      else if (type == 'video' && contentPage) {
        setTabKey('2')
        updateSetType(type);
      }
      else if (type == 'question-bank' && contentPage) {
        setTabKey('3')
        updateSetType(type);
      }
      setSubmitLoader(true)
      if (type == 'video') {
        uploadFunction(callbackChunk, successCallbackMergeChunk)
      }
      else {
        uploadlecturenotes(objectToUpload, () => setSubmitLoader(false), successCallback, setProgress)
      }
      setpdf(null)
    }


    if (subjectid && chapterid && inputvalue && classid && chapter && subjectname && className && topicId) {
      var selectedUploadObject = {
        subjectId: subjectid,
        subjectname: subjectname,
        classid: parseInt(classid),
        className: className,
        chapterid: parseInt(chapterid),
        chapterName: chapter,
        topicId: parseInt(topicId),
        topicName: topicName,
        title: inputvalue
      }

      setUploadContent(selectedUploadObject)
    }
  }

  const successCallback = () => {
    updatepdf(null)
    setTopicListToShow([])
    setProgressCheck(false)
    fileKeyUpdate('')
    setmodevisible(false)
    setmodel(true)
  }

  const handleCancel = () => {
    updatepdf(null)
    setchapterid(null)
    setOptions([])
    setSearchChapter('')
    setchapter('')
    setinputvalue('')
    fileKeyUpdate('')
    setProgressCheck(false)
    setTopicListToShow([])
    setCheckedVideo(false)
    setCheckedQuestion(false)
    setCheckedLecture(false)
    setmodevisible(false);
  };

  return (
    <Modal className='rounded-border' width='700px' visible={ismodelvisible} footer={null} closable={!progressCheck} onCancel={handleCancel} maskClosable={false}>

      <div className='lecturenotestitle' style={{ width: '100%', textAlign: 'center', padding: '0px' }}>
        <p style={{ margin: '0px' }}> Upload content for digital library</p>
      </div>
      <div className='lecturnotesdescription' style={{ padding: '0px', width: '100%', textAlign: 'center' }}>
        <p style={{ margin: '0px', marginBottom: '30px' }}> Kindly attach the file and fill in the informations below</p>
      </div>
      <div style={{ display: (progressCheck) ? 'inline' : 'none', marginBottom: '5px' }}>
        Uploading...
        <Progress type='line' style={{ width: '100%', display: progressCheck ? 'inline' : 'none' }} percent={progress} />
      </div>
      <div style={{ display: 'flex', height: '400px', justifyContent: 'space-between' }}>
        {
          !pdf ?
            <div style={{ width: '49%' }} >
              <div
                className='uploadfiles'
                style={{ width: "100%" }}
              >
                <UploadPdf type={type} progressCheck={progressCheck}></UploadPdf>
              </div>
            </div>
            :
            <div style={{ width: '49%' }} >
              <div className='uploadfiles'>
                <div style={{ marginTop: '45%', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '10%' }}>
                  <div><FilePdfOutlined style={{ color: 'red', width: '10%', height: '10%' }} /></div>
                  <p style={{ margin: '0px' }}>{pdf}</p>
                  <p style={{ margin: '0px' }}>2.50 MB</p>
                </div>
                <div style={{ marginTop: '20%', alignItems: 'center', display: !progressCheck ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'center', height: '10%', width: '100%' }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', paddingRight: '5px' }}>
                    <DeleteFilled onClick={() => setpdf(null)} style={{ color: '#AEAEAE' }} />
                  </div >
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <a style={{ paddingLeft: '5px', marginBottom: '0px', color: '#AEAEAE', font: 'normal normal normal 12px/26px Roboto', fontSize: '12px', opacity: '1' }} onClick={() => setpdf(null)}>Remove</a>
                  </div>
                </div>
              </div>
            </div>
        }
        <div className='uploadsearchbox' style={{ width: '49%' }}>
          <div className='full-width'>

            <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Subject <span style={{color:"red"}}>*</span></p>
            <AutoComplete
              getPopupContainer={(trigger) => trigger.parentElement}
              allowClear={subjectname ? true : false}
              dropdownMatchSelectWidth={252}
              className='autocompletetoupload'
              options={options}
              value={subjectname}
              onSelect={onSelect}
              onSearch={handleSearch}
              onChange={handleChangeSub}
              loading={subjectLoder}
              disabled={progressCheck}
            >
              <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Search subject" />
            </AutoComplete>
          </div>
          <div className='udloadFilesDropdown' style={{ width: '100%' }}>
            <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Class <span style={{color:"red"}}>*</span></p>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              allowClear={className ? true : false}
              defaultActiveFirstOption={false}
              notFoundContent={null}
              size="middle"
              showSearch
              value={className}
              style={{
                width: "100%",
                backgroundColor: "#FAFAFA",
              }}
              bordered={false}
              placeholder={
                <SubHeading color="#AEAEAE">
                  Select class
                </SubHeading>
              }
              loading={classLoader}
              disabled={subjectid && !progressCheck ? false : true}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value, obj) => {
                if (value) {
                  setclassid(value)
                  setClassName(obj?.children[0] + obj?.children[1] + obj?.children[2])
                  setChapterLoader(true)
                  updateAdminChapterInfo([])
                  setchapterid(null)
                  setchapter('')
                  setSearchChapter('')
                  setTopicId(null)
                  setTopicName(null)
                  fetchAdminChapterFilter('', value, subjectid, () => setChapterLoader(false))
                  setchapterarray([...getChaptersList])
                }
                else {
                  setclassid(null)
                  setClassName(null)
                  setchapterid(null)
                  setchapter('')
                  setSearchChapter('')
                  setTopicId(null)
                  setTopicName(null)
                }
              }}
            >
              {
                getStdList?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.std} - {item.section}
                  </Select.Option>
                ))
              }
            </Select>
          </div>


          <div style={{ width: '100%' }}>
            <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Chapter <span style={{color:"red"}}>*</span></p>
            <div style={{ width: '100%' }}>
              <AutoComplete
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear={searchChapter ? true : false}
                dropdownMatchSelectWidth={252}
                disabled={classid && subjectid && !progressCheck ? false : true}
                className='autocompletetoupload'
                options={optionsChapter}
                value={searchChapter}
                onChange={handleChangeChapter}
                onSelect={onSelectChapter}
                onSearch={handleSearchChapter}
                loading={chapterLoader}
              >
                <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Search Chapter" />
              </AutoComplete>
            </div>
          </div>


          <div style={{ width: '100%' }}>
            <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Topic <span style={{color:"red"}}>*</span></p>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              allowClear={topicName ? true : false}
              defaultActiveFirstOption={false}
              notFoundContent={null}
              size="middle"
              showSearch
              style={{
                width: "100%",
                backgroundColor: "#FAFAFA",
              }}
              bordered={false}
              placeholder={
                <div color="#AEAEAE">
                  Select Topic
                </div>
              }
              disabled={chapterid && !progressCheck ? false : true}
              loading={topicLoader}
              value={topicName}
              // optionFilterProp="children"
              // filterOption={(input, option) =>
              //    option.children[0].indexOf(input) >= 0
              // }
              filterOption={false}
              onChange={handleChangeTopic}
              onSelect={onSelectTopic}
              onSearch={handleSearchTopic}
            >
              {topicListToShow?.length != 0 ?
                topicListToShow?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))
                :
                topicName ?
                  <Select.Option value={-1}>Create {topicName}</Select.Option>
                  : null
              }

            </Select>

          </div>
          <div style={{ width: '100%' }}> <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Title <span style={{color:"red"}}>*</span></p>
            <div className='uploadfilesinput'>
              <Input size='middle' bordered={false} onChange={inputchanged} value={inputvalue} placeholder='Enter title'
                style={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box',
                  borderRadius: '4px', opacity: '1'
                }}
                disabled={progressCheck}
              >
              </Input>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ display: (progressCheck) ? 'inline' : 'none' }}>
        Uploading...
        <Progress type='line' style={{ width: '100%', display: progressCheck ? 'inline' : 'none' }} percent={progress} />
      </div> */}

      <div style={{ width: '100%', marginTop: '3%' }}>
        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Button style={{ width: "122px", marginRight: "5px", }} type="default" onClick={handleCancel} disabled={progressCheck}>CANCEL</Button>
          <Button loading={submitLoader} style={{ marginLeft: "5px", width: '122px' }} type="primary" disabled={(Pdftoupload && subjectid && chapterid && inputvalue.trim() != '' && classid && chapter && topicId) ? false : true} onClick={successhandler}>PUBLISH</Button>
        </div>
      </div>

    </Modal>
  )

}

const mapStateToProps = (state) => {
  const {
    Pdftoupload,
    subjects,
    selectedStd,
    selectedSubject,
    checkedLecture,
    checkedVideo,
    checkedQuestion,
    getChaptersList,
    getStdList,
    topicList,
    selectedChapter,
    selectedTopic,
    fileKey,
    classSubjectList,

  } = state.digitalLibrary;
  return {
    Pdftoupload,
    subjects,
    selectedStd,
    selectedSubject,
    checkedLecture,
    checkedVideo,
    checkedQuestion,
    getChaptersList,
    getStdList,
    topicList,
    selectedChapter,
    selectedTopic,
    fileKey,
    classSubjectList
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatepdf: (data) => dispatch(updatepdf(data)),
  subjectFilter: (data, callback) => dispatch(subjectFilter(data, callback)),
  uploadlecturenotes: (data, callback, successCallback, setProgress) => dispatch(uploadlecturenotes(data, callback, successCallback, setProgress)),
  setCheckedLecture: (bool) => dispatch(setCheckedLecture(bool)),
  setCheckedVideo: (bool) => dispatch(setCheckedVideo(bool)),
  setCheckedQuestion: (bool) => dispatch(setCheckedQuestion(bool)),
  fetchAdminChapterFilter: (value, classid, subjectid, callback) => dispatch(fetchAdminChapterFilter(value, classid, subjectid, callback)),
  fetchClasslist: (subid, chapterName, callback) => dispatch(fetchClasslist(subid, chapterName, callback)),
  setUploadContent: (obj) => dispatch(setUploadContent(obj)),
  updateStdList: (std) => dispatch(updateStdList(std)),
  fetchFilterTopic: (subId, stdId, chapterId, callback) => dispatch(fetchFilterTopic(subId, stdId, chapterId, callback)),
  updateSetType: (type) => dispatch(updateSetType(type)),
  fetchLibraryData: (search, callback) => dispatch(fetchLibraryData(search, callback)),
  setKey: (key) => dispatch(setKey(key)),
  postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
  uploadBlock2: (params, callback) => dispatch(uploadBlock2(params, callback)),
  uploadCompleted2: (object, callback) => dispatch(uploadCompleted2(object, callback)),
  uploadVideoMP4: (data) => dispatch(uploadVideoMP4(data)),
  MergeChunk: (fileId, callback, successCallback) => dispatch(MergeChunk(fileId, callback, successCallback)),
  fileKeyUpdate: (key) => dispatch(fileKeyUpdate(key)),
  updateAdminChapterInfo: (data) => dispatch(updateAdminChapterInfo(data)),
  updateFilterTopic: (data) => dispatch(updateFilterTopic(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(Uploadfiles);
