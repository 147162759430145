import React from "react";
import { connect } from "react-redux";
import Button from "antd/lib/button";
import {
	MdKeyboardBackspace,
	MdEdit,
	MdAdd,
	MdDelete,
	MdSearch,
	MdMoreVert,
} from "react-icons/md";
import { Checkbox, Dropdown, Menu, Typography } from "antd";
import Input from "antd/lib/input";
import "antd/lib/input/style/index";
import "antd/lib/button/style/index";
import {
	EditFilled,
	EditOutlined,
	ExclamationCircleOutlined,
	LoadingOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import {
	toggleCreateSubjectModal,
	setCurrentSubject,
	getClass,
	toggleAddChapterModal,
	deleteChapters,
	editChapters,
	deleteSubject,
} from "../../actions/classRoom";
import "./styles.css";
import AddChapterModal from "./modals/addChapter";
import AddSubjectModal from "./modals/addSubject";
import PageDataLoader from "components/pageDataLoader";
import EditSubjectModal from "./modals/editSubjectName";
import Modal from "antd/lib/modal/Modal";
import { Color } from "services";
import { SquareSubIcon } from "components/subIcon";
import { savePageCoordinates } from "actions/layout";
import PageHeader from "components/pageHeader";

const singleSub = (
	item,
	index,
	selectedSubject,
	setCurrentSubject,
	openEditModal,
	confirmDeleteChapter,
	self,
	deleteSubLoader
) => (
	<div
		className="r-jsb m-b-20 cursor-pointer"
		key={index.toString()}
		onClick={() => setCurrentSubject(item)}
		style={{
			width: 320,
			padding: item.id === selectedSubject?.id ? "10px" : "0px 0px 0px 10px",
			borderRadius: item.id === selectedSubject?.id ? "15px" : "0px",
			boxShadow:
				item.id === selectedSubject?.id ? "4px 4px 18px #302a5f14" : "none",
		}}
	>
		<div className="r-ac">
			<SquareSubIcon name={item.name} icon={item.icon} size={24} />
			<div className="m-l-10">
				{/* <div className="text-xmd dark-black bold-600" style={{width:10}}>{item.name}</div> */}
				<Typography.Text className='text-xmd dark-black bold-600' ellipsis={{ tooltip: true }} style={{width: 150}}>
					{item.name}
				</Typography.Text>
				<div className="text-xs dark-grey">{item.chapters.length} Chapters</div>
			</div>
		</div>
		{item.id === selectedSubject?.id ? (
			<div className="r-jsb m-r-20" style={{ alignItems: "center" }}>
				<div className="text-mdl" onClick={() => openEditModal(item)}>
					<EditFilled style={{ color: Color.textOrange }} />
				</div>
				<div
					className="text-mdl m-l-20"
					onClick={() => confirmDeleteChapter(self, item)}
				>
					{deleteSubLoader ? (
						<LoadingOutlined />
					) : (
						<MdDelete style={{ color: Color.red }} />
					)}
				</div>
			</div>
		) : null}
	</div>
);

class ClassRoomEditChapters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editableChapter: false,
			chapters: [],
			selectedChapters: [],
			deleteLoader: false,
			doneLoader: false,
			showEditSubjectModal: false,
			selectedSub: {},
			deleteSubLoader: false,
		};
	}

	componentDidMount() {
		// this.props.getClass();
	}

	componentWillUnmount() {
		if (!window.location.pathname.includes("/classroom")) {
			this.props.savePageCoordinates(0, 0);
		}
	}

	allowEditChapter() {
		const chapters = [...this.props.selectedSubject?.chapters];
		this.setState({ chapters, editableChapter: true });
	}

	handleSubjectClick = (subject) => {
		this.props.setCurrentSubject(subject);
		this.setState({ editableChapter: false, chapters: [] });
	};

	handleChapterChange(e, index) {
		const chapters = this.state.chapters;
		chapters[index] = { ...chapters[index], name: e.target.value };
		this.setState({ chapters });
	}

	handleAddChapterClick() {
		const chapters = this.state.chapters;
		chapters.push({ name: "" });
		this.setState({ chapters });
	}

	handleChaptersSelect(id, e) {
		const selectedChapters = this.state.selectedChapters;
		if (e.target.checked) {
			selectedChapters.push(id);
		} else {
			const index = selectedChapters.indexOf(id);
			selectedChapters.splice(index, 1);
		}
		this.setState({ selectedChapters });
	}

	handleDeleteClick(self) {
		self.setState({ deleteLoader: true });
		const chapters = self.state.selectedChapters;
		self.props.deleteChapters(
			chapters,
			() => self.setState({ deleteLoader: false }),
			() => this.setState({ editableChapter: false, chapters: [], selectedChapters: [] })
		);
	}

	confirmDelete(self) {
		return Modal.confirm({
			title: "Delete Chapters",
			icon: <ExclamationCircleOutlined />,
			content: `Are you sure you want to delete the selected chapters?`,
			okText: "Confirm",
			onOk() {
				self.handleDeleteClick(self);
			},
			onCancel() { },
		});
	}

	handleDeleteSub(self, sub) {
		self.setState({ deleteSubLoader: true });
		self.props.deleteSubject(sub.id, () =>
			self.setState({ deleteSubLoader: false })
		);
	}

	confirmDeleteChapter(self, sub) {
		return Modal.confirm({
			title: "Delete Subject",
			icon: <ExclamationCircleOutlined />,
			content: `Are you sure you want to delete ${sub.name}?`,
			okText: "Confirm",
			onOk() {
				self.handleDeleteSub(self, sub);
			},
			onCancel() { },
		});
	}

	handleSaveClick() {
		let chaptersToUpdate = [];
		this.setState({ doneLoader: true });
		const originalChapters = this.props.selectedSubject.chapters;
		this.state.chapters.forEach((chapter, index) => {
			if (!chapter.id) {
				if (chapter.name) {
					chaptersToUpdate.push(chapter);
				}
			} else if (originalChapters[index].name !== chapter.name) {
				chaptersToUpdate.push(chapter);
			}
		});
		if (!chaptersToUpdate.length) {
			this.setState({ doneLoader: false });
			return this.setState({ editableChapter: false });
		}
		this.props.editChapters(
			chaptersToUpdate,
			() => this.setState({ doneLoader: false }),
			() => this.setState({ editableChapter: false, chapters: [] })
		);
	}

	handleCancelClick() {
		this.setState({ editableChapter: false, chapters: [], selectedChapters: [] });
	}

	handleSubjectEdit = (sub) =>
		this.setState({ showEditSubjectModal: true, selectedSub: sub });

	render() {
		const {
			history,
			selectedSectionDetails,
			showAddChapterModal,
			showCreateSubModal,
			toggleCreateSubjectModal,
			selectedSubject,
			selectedSectionLoader,
			toggleAddChapterModal,
		} = this.props;
		const {
			editableChapter,
			chapters,
			selectedChapters,
			deleteLoader,
			doneLoader,
			showEditSubjectModal,
			selectedSub,
			deleteSubLoader,
		} = this.state;

		const menu = (
			<Menu className="batch-detail__dropdown-body">
				<Menu.Item
					key="3"
					className="m-b-10"
					onClick={() => history.push("/classroom/resources")}
				>
					<div className="r-ac">
						<MdSearch className="color-black" />
						<div className="color-black m-l-10">Browse resources</div>
					</div>
				</Menu.Item>
				<Menu.Item
					key="4"
					className="r-ac"
					onClick={() => toggleCreateSubjectModal(true)}
				>
					<div className="r-ac">
						<MdAdd className="color-black" />
						<div className="color-black m-l-10">Create Subject</div>
					</div>
				</Menu.Item>
			</Menu>
		);

		const pageHeaderTitle = (
			<div style={{ fontSize: "1em" }}>
				{selectedSectionDetails
					? `${selectedSectionDetails?.std} : ${selectedSectionDetails?.section}`
					: ""}
			</div>
		);
		const pageHeaderSubtitle =
			`${selectedSectionDetails?.courseName}` !== "null"
				? ` ${selectedSectionDetails?.courseName}`
				: "";

		const actions = [
			editableChapter ? <Button
				className="btn-radius-15__height-38 m-r-20 dark-grey"
				style={{ width: "130px" }}
				onClick={() => {
					this.handleCancelClick();
				}}
			>
				Cancel
			</Button> : null,
			(editableChapter && selectedChapters.length) ? (
				<Button
					type="primary"
					className="btn-radius-15__height-38"
					style={{ width: "130px" }}
					loading={deleteLoader}
					onClick={() => this.confirmDelete(this)}
				>
					Delete
				</Button>
			) : null,
			(editableChapter && !selectedChapters.length) ? (
				<Button
					loading={doneLoader}
					type="primary"
					className="btn-radius-15__height-38"
					style={{ width: "130px" }}
					onClick={() => this.handleSaveClick()}
					disabled={chapters?.filter((item) => item.name.trim() == "").length}
				>
					Save
				</Button>
			) : null,
		];

		return (
			<div className="full-height display-flex r-c-sb">
				<PageHeader
					title={pageHeaderTitle}
					subtitle={pageHeaderSubtitle}
					onBackPress={() => history.goBack()}
					actions={actions}
				/>

				<div
					className="content-box__height-40 display-flex content-box__raduis-15"
					style={{ width: "29.5%", marginTop: 120 }}
				>
					{/* child doesn't inherit relative widths from parent */}
					<div className="full-height flex-1" style={{ width: 'inherit'}}>
						<div className="r-c-sb m-b-20">
							<div className="text-sm bold-600 m-r-20">
								{selectedSectionDetails.subjects.length > 1
									? "Subjects "
									: "Subject "}
								<span className="dark-grey" style={{ fontWeight: 400 }}>
									({selectedSectionDetails.subjects.length})
								</span>
							</div>
							<Dropdown
								overlay={menu}
								trigger={["click"]}
								placement="bottomRight"
							>
								<div
									onClick={(e) => e.preventDefault()}
									className="r-c-c cursor-pointer"
									style={{fontSize: 17}}
								>
									<MdMoreVert />
								</div>
							</Dropdown>
						</div>

						<div
							className="content-box__height-60 scroll-bar-universal">
							{!selectedSectionDetails.subjects ||
								!selectedSectionDetails.subjects.length ? (
								<div className="r-c-c-c content-box__height-60">
									<img
										src={
											require("../../Assets/classroom/noSubjects.svg").default
										}
										style={{ width: 200, height: 200 }}
									/>
									<div className="text-sm bold-600 color-purple">
										No Subjects!
									</div>
									<div className="text-xmd color-black">
										There are no subjects added to this class!
									</div>
									<div className="r-c-sb m-t-30" style={{ width: "260px" }}>
										<Button
											className="btn-radius-15__height-38 dark-grey"
											style={{ width: "120px" }}
											onClick={() => toggleCreateSubjectModal(true)}
										>
											Create
										</Button>
										<Button
											loading={false}
											type="primary"
											className="btn-radius-15__height-38"
											style={{ width: "120px" }}
											onClick={() => history.push("/classroom/resources")}
										>
											Browse
										</Button>
									</div>
								</div>
							) : (
								selectedSectionDetails.subjects.map((item, index) =>
									singleSub(
										item,
										index,
										selectedSubject,
										this.handleSubjectClick,
										this.handleSubjectEdit,
										this.confirmDeleteChapter,
										this,
										deleteSubLoader
									)
								)
							)}
						</div>
					</div>
				</div>

				<div
					className="content-box__height-40 display-flex content-box__raduis-15"
					style={{ width: "69%", marginTop: 120}}
				>
					<div className="full-height flex-1">
						<div className="r-c-sb ">
							<div className="text-sm bold-600 m-r-20">
								{selectedSubject?.chapters?.length > 1 ? "Chapters" : "Chapter"}
								<span className="m-l-5 dark-grey">
									(
									{selectedSubject && selectedSubject.chapters
										? selectedSubject?.chapters.length
										: 0}
									)
								</span>
							</div>
							{!editableChapter && (
								<Button
									className="btn-radius-15__height-38 m-r-20 dark-grey"
									icon={<EditOutlined />}
									onClick={() => this.allowEditChapter()}
									disabled={!selectedSubject?.chapters?.length}
								>
									Edit Chapters
								</Button>
							)}
						</div>
						{!selectedSubject?.chapters || !selectedSubject?.chapters.length ? (
							<div className="r-c-c-c content-box__height-60">
								<img
									src={require("../../Assets/classroom/noSubjects.svg").default}
									style={{ width: 200, height: 200 }}
								/>
								{!selectedSubject?.id ? (
									<div className="text-sm bold-600 color-purple">
										Select any Subject!
									</div>
								) : (
									<div className="r-c-c-c">
										<div className="text-sm bold-600 color-purple">
											No Chapters!
										</div>
										<div className="text-xmd color-black">
											There are no chapters added for this subject yet!
										</div>
										<div className="r-c-c m-t-30">
											<Button
												icon={<PlusOutlined />}
												className="btn-radius-15__height-38 text-xmd"
												type="primary"
												onClick={() => toggleAddChapterModal(true)}
											>
												Add Chapters
											</Button>
										</div>
									</div>
								)}
							</div>
						) : editableChapter ? (
							<div
								className="content-box__height-60 scroll-bar-universal"
								style={{ padding: "20px 10px 0px 0px" }}
							>
								{chapters.map((item, index) => (
									<div className="r-ac m-b-20" key={index}>
										<Checkbox
											onChange={(e) => this.handleChaptersSelect(item.id, e)}
										// checked={}
										>
											<div className="text-xmd color-black">{index + 1}</div>
										</Checkbox>
										<Input
											className="input-text-grey m-l-20"
											placeholder="What’s the name of the chapter?"
											value={item.name}
											onChange={(e) => this.handleChapterChange(e, index)}
										/>
									</div>
								))}
								<div
									className="text-xs bold-600 text-right blue cursor-pointer m-t-10"
									onClick={() => this.handleAddChapterClick()}
								>
									+ Add Chapter
								</div>
							</div>
						) : (
							<div
								className="content-box__height-60 scroll-bar-universal"
								style={{ padding: "20px 0px 0px 0px" }}
							>
								{selectedSubject?.chapters.map((item, index) => (
									<div className="r-ac m-b-20" key={index}>
										<div
											className="text-xmd dark-grey"
											style={{ width: "90px" }}
										>
											Chapter {index + 1}
										</div>
										<Typography.Text
											className="text-xmd dark-black bold-600 text-overflow-ellipsis"
											ellipsis={{ tooltip: true }}
											style={{ width: 350 }}
										>
											{item.name}
										</Typography.Text>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				{showEditSubjectModal && (
					<EditSubjectModal
						visible={showEditSubjectModal}
						subject={selectedSub}
						onClose={() => this.setState({ showEditSubjectModal: false })}
					/>
				)}
				{showAddChapterModal && <AddChapterModal />}
				{showCreateSubModal && <AddSubjectModal />}
				<PageDataLoader visible={selectedSectionLoader} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		selectedSectionDetails,
		showAddChapterModal,
		showCreateSubModal,
		selectedSubject,
		selectedSectionLoader,
	} = state.classRoom;
	return {
		selectedSectionDetails,
		showAddChapterModal,
		showCreateSubModal,
		selectedSubject,
		selectedSectionLoader,
	};
};

const mapDispatchToProps = (dispatch) => ({
	toggleCreateSubjectModal: (bool) => dispatch(toggleCreateSubjectModal(bool)),
	setCurrentSubject: (data) => dispatch(setCurrentSubject(data)),
	getClass: () => dispatch(getClass()),
	toggleAddChapterModal: (bool) => dispatch(toggleAddChapterModal(bool)),
	deleteChapters: (chapters, callback, successCallback) =>
		dispatch(deleteChapters(chapters, callback, successCallback)),
	editChapters: (chapters, callback, successCallback) =>
		dispatch(editChapters(chapters, callback, successCallback)),
	deleteSubject: (subId, callback) => dispatch(deleteSubject(subId, callback)),
	savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClassRoomEditChapters);
