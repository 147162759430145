import React from 'react'
import TopBar from './topBar'
import Footer from './footer'

function SearchError() {
    return (
        <div style={{ backgroundColor: "#FAFAFA", width: "calc(100% + 40px)", height: "fit-content", margin: "50px 0px 0px 0px" }}>
            <div className="p-h-100" style={{ backgroundColor: "#FAFAFA", width: "calc(100% + 40px)", height: "50vh", margin: "50px 0px 0px 0px" }}>
                <div><TopBar /></div>
                <div className='p-20 m-l-40 font-bold text-md' >Sorry, we couldn't find any results for "xyz"</div>
                <div className='p-20 m-l-40 font-bold text-sm'>
                    Suggestions:
                    <div className='m-t-20 '>
                    <li> Make sure that all words are spelled correctly.</li>
                    <li>Try different keywords.</li>
                    <li>Try more general keywords.</li>
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    )
}

export default SearchError
