import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import './modal.css';
import SubmittedSuccessModal from './submittedSuccessModal';
import {connect} from 'react-redux';
import { finalSubmit } from 'actions/studentTest';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, StarFilled, StopOutlined } from '@ant-design/icons';
 

const SubmitModal = ({
  timeLeft, isModalVisible, handleOk,
  handleCancel, qCounts, finalSubmit, setStopTimer
}) => {
  const [buttonLoader, toggleButtonLoader] = useState(false);

  const handleSubmit = () => {
    toggleButtonLoader(true)
    setStopTimer(timeLeft)
    finalSubmit(() => toggleButtonLoader(false), handleOk)
  }

  return (
      <Modal 
        className="submitModal" visible={isModalVisible} 
        onOk={() => handleSubmit()}  
        okText={"SUBMIT"} 
        cancelText={"NOT YET"} 
        okButtonProps={{loading: buttonLoader}}
        onCancel={handleCancel}
        width={650}
        maskClosable={false}
      >
        
        <h2 style={{fontWeight:"700",marginBottom:"0px", marginTop: 30}}>Submit test</h2>
        <p style={{color:"#636363", opacity:"100%"}}>Are you sure you want to submit the test?</p>
        <div style={{width: "100%", padding: 20, marginTop: 30,  backgroundColor: "#FAFAFA", borderRadius: 5}}>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <ClockCircleOutlined style={{color: "#554394", }} />
            </Col>
            <Col span={18}>
              Time left
            </Col>
            <Col span={4} className="text-right" >
            {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <CheckCircleOutlined style={{color: "#554394", }}/>
            </Col>
            <Col span={18}>
              Attempted
            </Col>
            <Col span={4} className="text-right">
              {qCounts.attempted}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <CloseCircleOutlined style={{color: "#FF414D", }} />
            </Col>
            <Col span={18}>
              Unattempted
            </Col>
            <Col span={4} className="text-right">
              {qCounts.unanswered}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <StarFilled style={{color: "#FFA931", }}/>
            </Col>
            <Col span={18}>
              Attempted & Marked for review
            </Col>
            <Col span={4} className="text-right">
              {qCounts.attemptedAndBookmarked}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <StarFilled style={{color: "#FFA931", }}/>
            </Col>
            <Col span={18}>
              Unattempted & Marked for review
            </Col>
            <Col span={4} className="text-right">
              {qCounts.bookmarked}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <StopOutlined style={{color: "#AEAEAE", }}/>
            </Col>
            <Col span={18}>
              Unseen
            </Col>
            <Col span={4} className="text-right">
              {qCounts.unseen}
            </Col>
          </Row>
        </div>
               
      </Modal>
  );
};


const mapStateToProps = state => {
  const {
     timeLeft, qCounts
  } = state.studentTest;
  return {
     timeLeft, qCounts
  };
};

const mapDispatchToProps = dispatch => ({
  finalSubmit: (buttonCallback, successCallback) => dispatch(finalSubmit(buttonCallback, successCallback)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SubmitModal);