import { Avatar } from 'antd';
import React from 'react';

function getFirstAndLastLetters(name) {
    // const words = name.split(/\s+/);
    // let initials = '';
    // if (words.length > 0) {
    //     initials += words[0].charAt(0).toUpperCase();
    //     if (words.length > 1) {
    //         initials += words[words.length - 1].charAt(0).toUpperCase();
    //     }
    // }
    // return initials;

    // this function read hindi kannada and english and give an output of first letter       

    const words = name?.trim()?.split(/\s+/); 
    let initials = '';
    if (words?.length > 0) {
        initials += words[0]?.charAt(0);
        if (words?.length > 1) {
            initials += words[words?.length - 1]?.charAt(0);
        }
    }
    return initials?.toUpperCase();
}

const colors = [
    {
        bg: "#C9E8F2",
        text: "#32869F"
    },
    {
        bg: "#EFE1E4",
        text: "#B44950"
    },
    {
        bg: "#F1E6CB",
        text: "#99790A"
    },
    {
        bg: "#D3E9E6",
        text: "#008A67"
    },
    {
        bg: "#DFDEF5",
        text: "#594099"
    },
    {
        bg: "#E1EBCC",
        text: "#5E840E"
    }
];

const UserAvatar = React.memo((props) => {
    const { name, img, size, shape } = props;
    const colorIndex = name ? name?.length % colors?.length : 0;
    const backgroundColor = colors[colorIndex]?.bg;
    const textColor = colors[colorIndex]?.text;


    return (
        <div>
            {img ? (
                <Avatar size={size} shape={shape} style={{ borderRadius: size * 2 }} src={img} />
            ) : (
                <Avatar size={size} shape={shape} style={{ textTransform: 'uppercase', background: backgroundColor, color: textColor, fontWeight:props?.fontWeight ? 500 :"bold", fontFamily:"roboto"}}>
                    {getFirstAndLastLetters(name)}
                </Avatar>
            )}
        </div>
    );
});

export default UserAvatar;