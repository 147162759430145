import { Api } from '../services';
import { changeStatus as changeBadgeStatus, notificationBadgeList } from './login'


export const TOGGLE_DETAIL_MODAL = 'TOGGLE_DETAIL_MODAL';
export const TOGGLE_NOTIFY_MODAL = 'TOGGLE_NOTIFY_MODAL';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const REJECT_LOADER = 'REJECT_LOADER';
export const APPROVE_LOADER = 'APPROVE_LOADER';
export const SELECT_STATUS = 'SELECT_STATUS';
export const SELECT_TYPE = 'SELECT_TYPE';
export const SELECT_PAGE = 'SELECT_PAGE';
export const UPDATE_COUNT = 'UPDATE_COUNT';
export const TOGGLE_SCHEDULE_MODAL = 'TOGGLE_SCHEDULE_MODAL';
export const TOGGLE_NOTIFICATION_LOADER = 'TOGGLE_NOTIFICATION_LOADER';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const SELECT_NOTIFICATION_TAB = 'SELECT_NOTIFICATION_TAB';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
// export const SET_COUNT = 'SET_COUNT';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const SEARCH = 'SEARCH';
export const SET_LOADER = 'SET_LOADER';
export const SET_CARD_ID = "SET_CARD_ID";
export const SET_ACTION_DATA = "SET_ACTION_DATA";
export const SET_FROM_NOTIFICATION = "SET_FROM_NOTIFICATION";
export const IS_TRUE = 'IS_TRUE';


export const rejectRequest = (input, page) => {
  return (dispatch, getState) => {
    const notifications = getState().login.notifyBadgeList;
    const index = notifications.map(item => item.id).indexOf(input);
    dispatch(rejectLoader(true));
    const url = `/notification/request/${input}`;
    const params = { "status": "Rejected" }
    return Api.patch(url).params(params).send((response, error) => {
      dispatch(rejectLoader(false));
      dispatch(toggleDetailModal(false, {}));
      dispatch(toggleScheduleModal(false, {}));
      dispatch(changeBadgeStatus(index, "Rejected"));
      dispatch(getNotification('Requests', 'Pending', page));
    });
  }
};
export const approveRequest = (input, page) => {
  return (dispatch, getState) => {
    const notifications = getState().login.notifyBadgeList;
    const index = notifications.map(item => item.id).indexOf(input);
    dispatch(approveLoader(true));
    const url = `/notification/request/${input}`;
    const params = { "status": "Approved" }
    return Api.patch(url).params(params).send((response, error) => {
      dispatch(approveLoader(false));
      dispatch(toggleDetailModal(false, {}));
      dispatch(toggleScheduleModal(false, {}));
      dispatch(changeBadgeStatus(index, "Approved"));
      dispatch(getNotification('Requests', 'Pending', page));
    });
  }
};


export const rejectLoader = (bool) => ({
  type: REJECT_LOADER,
  bool,
});


export const approveLoader = (bool) => ({
  type: APPROVE_LOADER,
  bool,
});


// export const getRequest = (input) => {
//   return (dispatch) => {
//     const url = `/notification?type=Requests&status=${input}`
//     return Api.get(url).send((response, error) => {
//       dispatch(updateRequest(response));
//     });
//   }
// };
export const updateRequest = (res) => ({
  type: UPDATE_REQUEST,
  res,
});


export const selectStatus = (status) => ({
  type: SELECT_STATUS,
  status,
});


export const updateCount = (count) => ({
  type: UPDATE_COUNT,
  count,
})

export const markAsRead = (id, index) => {
  return (dispatch) => {
    const url = `/notification/${id}`
    dispatch(changeStatus(index));
    return Api.patch(url).send((response, error) => {
    });
  }
};

export const changeStatus = (index) => ({
  type: CHANGE_STATUS,
  index
})

export const selectNotificationTab = key => ({
  type: SELECT_NOTIFICATION_TAB,
  key
})

export const updateFilter = (key, val) => ({
  type: UPDATE_FILTER,
  key,
  val
})

export const setLoaders = (key, val) => ({
  type: SET_LOADER,
  key,
  val
})

export const getNotification = (type, status = "Pending", page = 1) => {
  // page = page == 'undefined' ? 1: page;
  return (dispatch) => {
    dispatch(selectType(type))
    dispatch(toggleNotificationLoader(true));
    if (type === "Requests") {
      dispatch(updateRequest(false));
      const url = `/notification?type=Requests&status=${status}&page=${page}`
      return Api.get(url).send((response, error) => {
        if (response) dispatch(updateRequest(response));
        dispatch(toggleNotificationLoader(false));

      })
    } else {
      dispatch(updateNotification(false));
      const url = `/notification?type=Notifications&page=${page}`
      return Api.get(url).send((response, error) => {
        dispatch(updateNotification(response));
        dispatch(toggleNotificationLoader(false));
      })
    }
  }
};

const toggleNotificationLoader = bool => ({
  type: TOGGLE_NOTIFICATION_LOADER,
  bool
})

// export const getNotification = (input) => {
//   return (dispatch) => {
//     const url = `/notification?${input}`
//     return Api.get(url).send((response, error) => {
//       dispatch(updateNotification(response));
//     });
//   }
// };
export const updateNotification = (res) => ({
  type: UPDATE_NOTIFICATION,
  res,
});
export const toggleDetailModal = (value, data) => ({
  type: TOGGLE_DETAIL_MODAL,
  value,
  data,
});
export const toggleScheduleModal = (value, data) => ({
  type: TOGGLE_SCHEDULE_MODAL,
  value,
  data,
});

export const toggleNotifyModal = (value, data, body) => ({
  type: TOGGLE_NOTIFY_MODAL,
  value,
  data,
  body
});
export const selectPage = (page) => ({
  type: SELECT_PAGE,
  page,
});
export const selectType = (tab) => ({
  type: SELECT_TYPE,
  tab,
});

export const setNotifications = (res) => ({
  type: SET_NOTIFICATIONS,
  res
})

export const getNotifications = (res) => ({
  type: GET_NOTIFICATIONS,
  res
})




// export const setcount =(res)=> ({
//   type: SET_COUNT,
//   res
// })

export const fetchNotifications = (callback, ispatch = false) => {
  return (dispatch, getState) => {
    if (ispatch === false) {
      dispatch(setLoaders('generalLoader', true))
    }
    const { selectedNotificationTab, filter, } = getState().notification;
    const params = {
      category: selectedNotificationTab,
      filter: filter?.dateFilter,
      page: filter?.currentPage,
      search: filter?.search,
      limit: 10,


    }
    return Api.get(`/notification/v2`).params(params).send((response, error) => {
      if (callback) {
        callback();
      }
      if (response != undefined) {
        console.log("response", response);
        dispatch(setNotifications(response))

      }
      dispatch(setLoaders('generalLoader', false))
    })
  }
}

export const patchNotifications = (callback) => {
  return (dispatch, getState) => {

    const { filter } = getState().notification;
    const params = {


    }
    return Api.patch(`/notification/${filter.selectedNotification.id}`).params(params).send((response, error) => {
      if (response != undefined) {
        dispatch(notificationBadgeList());
        // dispatch(fetchNotifications(()=>{}, true));
        console.log("response", response);
      }
    })
  }
}

export const setCardId = (id) => ({
  type: SET_CARD_ID,
  id,
});

export const setActionData = (val) => ({
  type: SET_ACTION_DATA,
  val,
});

export const postUserStatus = (callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {
    const { cardId, actionDataContainer } = getState().notification
    return Api.post(`/user/updateStatus`)
      .params({
        userId: cardId,
        status: actionDataContainer
      })
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined) {
          if (successCallBack) {
            successCallBack()
          }
          dispatch(fetchNotifications())
        }
      });
  };
};

export const setFromNotification = (val) => ({
  type: SET_FROM_NOTIFICATION,
  val
})
export const Istrue = bool => ({
  type: IS_TRUE,
  bool
})