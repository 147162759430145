import {
    UPDATE_CLASSES, TOGGLE_CLASS_FETCH_LOADER, NAV_TO_CLASS_DETAILS, UPDATE_SUBJECT_LIST,
    TOGGLE_SEARCH_LOADER, TOGGLE_NEW_CLASS_MODAL, TOGGLE_NEW_SEC_MODAL,
    TOGGLE_EDIT_CLASS_MODAL, TOGGLE_CLONE_LOADER, TOGGLE_CLASS_CREATED_MODAL, TOGGLE_CLASS_TRANSFER_MODAL,
    TOGGLE_CLASS_TRANSFER_LOADER, TOGGLE_CLASS_CREATE_LOADER, UPDATE_NEW_CLASS_DETAILS,
    TOGGLE_EDIT_CLASS_LOADER, UPDATE_SELECTED_SECTION, UPDATE_SEC_DETAILS, TOGGLE_SEC_LOADER,
    TOGGLE_ADD_CHAPTER_MODAL, TOGGLE_ADD_STUDENT_MODAL, TOGGLE_CREATE_SUB_MODAL,
    SET_CURRENT_SUB, UPDATE_CURRICULUMS, UPDATE_SELECTED_CURRICULUM, UPDATE_MASTER_SUBJECTS,
    STORE_SELECTED_CLASS_DETAILS, STORE_SINGLE_SECTION_DETAILS, STORE_SECTION_ID, SET_ALL_STUDENTS_PAGE_PARAMS,
    SET_FROM_CLASSROOM, UPDATE_STUDENTS_LOADER, STORE_All_STUDENTS
  } from '../actions/classRoom';

  const initialState = {
    classes: {},
    classesOverview: [],
    classFetchLoader: false,
    newClassRoomModal: false,
    searchLoader: false,
    subjectsFound: [],
    searchLoader: false,
    showNewClassModal: false,
    showNewSecModal: false,
    showEditModal: false,
    cloneSecParams: {
        name: null,
        stdId: -1
      },
    cloneSecLoader: false,
    selectedClass: {},
    showClassCreatedModal: false,
    showTransferClassModal: false,
    sectionToTransfer: {},
    transferClassLoader: false,
    classCreateLoader: false,
    classCreatedModal: false,
    newClassDetails: {},
    editClassLoader: false,
    selectedSection: {},
    selectedSectionDetails: {},
    selectedSectionLoader: false,
    showAddChapterModal: false,
    showAddStudentModal: false,
    showCreateSubModal: false,
    addSubLoader: false,
    selectedSubject: {},
    curriculums: [],
    selectedMasterStd: {},
    masterSubjects: [],
    selectedCurriculumId: null,
    selectedClassDetails:[],
    selectedSectionData:[],
    sectionId:null,
    allStudentsParamsObj:{ page: 1, search: null, sort: "ASC", limit: 10 },
    isFromClassRoomDetails: false,
    getStudentsListLoader: false,
    allStudentsData:[]
    // showAddCurriculumModal: false
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case TOGGLE_ADD_CURRICULUM_MODAL:
        //     return {
        //         ...state,
        //         showAddCurriculumModal: action.bool
        //     }
        case UPDATE_MASTER_SUBJECTS:
            return {
                ...state,
                masterSubjects: [...action.data.subjects]
            }
        case UPDATE_SELECTED_CURRICULUM:
            return {
                ...state,
                selectedMasterStd: {...action.data},
                selectedCurriculumId: action.curriculumId
            }
        case UPDATE_CURRICULUMS:
            return {
                ...state,
                curriculums: [...action.data.curriculums]
            }
        case SET_CURRENT_SUB:
            return {
                ...state,
                selectedSubject: action.data
            }
        case TOGGLE_CREATE_SUB_MODAL:
            return {
                ...state,
                showCreateSubModal: action.bool
            }
        case TOGGLE_ADD_STUDENT_MODAL:
            return {
                ...state,
                showAddStudentModal: action.bool
            }
        case TOGGLE_ADD_CHAPTER_MODAL:
            return {
                ...state,
                showAddChapterModal: action.bool
            }
        case TOGGLE_SEC_LOADER:
            return {
                ...state,
                selectedSectionLoader: action.bool
            }
        case UPDATE_SEC_DETAILS:
            const students = action.data.students.map(student => {
                student.key = student.id;
                return student;
            })
            return {
                ...state,
                selectedSectionDetails: {...action.data , students}
            }
        case UPDATE_SELECTED_SECTION:
            return {
                ...state,
                selectedSection: {...action.data}
            }
        case TOGGLE_EDIT_CLASS_LOADER:
            return {
                ...state,
                editClassLoader: action.bool
            }
        case UPDATE_NEW_CLASS_DETAILS:
            return {
                ...state,
                newClassDetails: action.data
            }
        case TOGGLE_CLASS_CREATE_LOADER:
            return {
                ...state,
                classCreatedModal: action.bool
            }
        case TOGGLE_CLASS_CREATE_LOADER:
            return {
                ...state,
                classCreateLoader: action.bool
            }
        case TOGGLE_CLASS_TRANSFER_LOADER:
            return {
                ...state,
                transferClassLoader: action.bool
            }
        case TOGGLE_CLASS_TRANSFER_MODAL:
            return {
                ...state,
                showTransferClassModal: action.bool,
                sectionToTransfer: {...action.data}
            }
        case TOGGLE_CLASS_CREATED_MODAL:
            return {
                ...state,
                showClassCreatedModal: action.bool
            }
        case TOGGLE_CLONE_LOADER:
            return {
                ...state,
                cloneSecLoader: action.bool
            }
        case TOGGLE_EDIT_CLASS_MODAL:
            return {
                ...state,
                showEditModal: action.bool,
                selectedClass: action.key ? {name: action.key, sections: state.classes[action.key]} : {}
            }
        case TOGGLE_NEW_SEC_MODAL:
            return {
                ...state,
                showNewSecModal: action.bool,
                selectedClass: action.key ? {name: action.key, sections: state.classes[action.key]} : []
            }
        case TOGGLE_NEW_CLASS_MODAL:
            return {
                ...state,
                showNewClassModal: action.bool
            }
        case UPDATE_SUBJECT_LIST:
            return {
                ...state,
                subjectsFound: [...action.subjects]
            }
        case TOGGLE_SEARCH_LOADER:
            return {
                ...state,
                searchLoader: action.value,
            }
        case TOGGLE_CLASS_FETCH_LOADER:
            return {
                ...state,
                classFetchLoader: action.bool
            }
        case UPDATE_CLASSES:
            // delete action?.classes?.summary?.totalStudents
            return {
              ...state,
              classes: action.classes.data,
              classesOverview: action?.classes?.summary,
            }
        case NAV_TO_CLASS_DETAILS:
            return {
                ...state,
                newClassRoomModal: !state.newClassRoomModal,
            };
        case STORE_SELECTED_CLASS_DETAILS :
            return{
                ...state,
                selectedClassDetails : action.val
            }
        case STORE_SINGLE_SECTION_DETAILS :
            return{
                ...state,
                selectedSectionData : action.val
            } 
        case STORE_SECTION_ID : 
            return{
                ...state,
                sectionId : action.val
            }
        case SET_ALL_STUDENTS_PAGE_PARAMS :
            let newParamsObj = { ...state.allStudentsParamsObj }
            newParamsObj[action.key] = action.val;
            return {
                ...state,
                allStudentsParamsObj: newParamsObj,
            }
        case SET_FROM_CLASSROOM: 
            return{
                ...state,
                isFromClassRoomDetails : action.val
            }
        case UPDATE_STUDENTS_LOADER :
            return{
                ...state,
                getStudentsListLoader : action.val
            }
        case STORE_All_STUDENTS :
            return{
                ...state,
                allStudentsData: action.val
            }
        default:
            return state;
    }
  };

  export default reducer;