import React from 'react';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal';
import 'antd/lib/modal/style/index';
import '../styles.css';

class ImageModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }



  render(){
    const {visible, onCancel, path} = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={() => onCancel()}
        footer={false}>
          <img width={'100%'} src={path} alt=""/>
      </Modal>)

  }
}



export default connect()(ImageModal);
