import { FullscreenOutlined, LockOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { getAutocompleteSearch, getCourseActivityData, getCourseDashboardData, getCourseGraphData, getCoursePieChartData, setCourseTypeName, setSearchResultPageParamsObj, storeAutocompleteSearchData, storeSingleCourseId } from 'actions/courseManagement'
import { setOthersSelectedTab } from 'actions/profileNew'
import { getMarketSettingsData } from 'actions/studentMarketPlace'
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2'
import { AutoComplete, Carousel, Checkbox, DatePicker, Dropdown, Menu, Typography } from 'antd'
import BarChartModal from 'components/barChartModal'
import BarPlotModal from 'components/barPlotModal'
import { Button } from 'components/Button'
import CustomDots from 'components/CustomDots'
import FormattedDate from 'components/formatCreatedAt'
import GroupedBarPlot from 'components/groupedBarPlot'
import PageDataLoader from 'components/pageDataLoader'
import PageHeader from 'components/pageHeader'
import PieCharts from 'components/pieCharts'
import SwitchComponent from 'components/switchComponent'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api, Color } from 'services'
import classesScheduled from "../../Assets/courseManagementV4/classesScheduled.svg"
import liveCourseIcon from "../../Assets/courseManagementV4/liveCourseIcon.svg"
import filterIcon from "../../Assets/courseManagementV4/new-filter-icon.png"
import studentIcon from "../../Assets/courseManagementV4/studentIcon.svg"
import LiveCourseIcon from "../../Assets/courseManagementV4/studentModalLiveCourse.svg"
import StudyMaterialIcon from "../../Assets/courseManagementV4/studentModalStudy.svg"
import TestSeriesIcon from "../../Assets/courseManagementV4/studentModalTestSeries.svg"
import VideoCourseIcon from "../../Assets/courseManagementV4/studentModalVideoCourse.svg"
import studyMaterialIcon from "../../Assets/courseManagementV4/studyMaterialIcon.svg"
import testScheduled from "../../Assets/courseManagementV4/testScheduled.svg"
import testSeriesIcon from "../../Assets/courseManagementV4/testSeriesIcon.svg"
import totalBatches from "../../Assets/courseManagementV4/totalBatches.svg"
import totalSections from "../../Assets/courseManagementV4/totalSections.svg"
import videoResourceIcon from "../../Assets/courseManagementV4/videoResourceIcon.svg"
import CourseOverviewCard from './components/courseOverviewCard'
import RecentAddedCourseModal from './components/recentAddedCourseModal'
import RecentAddedStudentsModal from './components/recentAddedStudentsModal'
import CourseTypesToggleModal from './courseTypesToggleModal'
import OverviewList from './overviewList'
import "./styles.css"
import { Information, SmallInfo, Title, Title2 } from 'components/Typography';
import { setOffersTypeIndex } from 'actions/offers'

export const bodyStyle = {
    backgroundColor: "#FAFAFA",
    width: " calc(100% + 30px)",
    height: "calc(100% + 60px)",
    margin: "-20px",
    padding: "20px",
}
export const Heading = ({ heading }) => {
    return (
        <div style={{ fontSize: 20, fontWeight: "normal", fontFamily: "roboto", color: "#191919" }}>{heading}</div>
    )
}

function CourseDashboard({ getCourseDashboardData, courseDashboardData, getCourseActivityData, courseActivityData, autocompleteSearchData, setCourseTypeName, getAutocompleteSearch, setSearchResultPageParamsObj, storeAutocompleteSearchData, storeSingleCourseId, zIndexState, getMarketSettingsData, getCourseGraphData, getCoursePieChartData, storeGraphData, storePieChartData, setUserDetails, setSpecificUserId, setOthersSelectedTab, user, setOffersTypeIndex }) {
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(["All"]);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [isCourseTypeToggleModalVisible, setIsCourseTypeToggleModalVisible] = useState(false);
    const [isCourseModalVisible, setIsCourseModalVisible] = useState(false);
    const [isStudentModalVisible, setIsStudentModalVisible] = useState(false)
    const [singleStudentData, setSingleStudentData] = useState(null)
    const [singleCourseData, setSingleCourseData] = useState(null)
    const [isChecked, setIsChecked] = useState(true);
    const [courseType, setCourseType] = useState('liveCourse')
    const [isMonth, setIsMonth] = useState(moment().format('MMM'))
    const [isYear, setIsYear] = useState(moment().format('YYYY'))
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 10 });
    const [graphData, setGraphData] = useState([]);
    const colors = ['#B59009', '#DAAB00', '#E9CC64', '#F9E7A3'];
    const stdColors = ['#594099', '#967DD5', '#C3B1EC', '#DACFF2']
    const [pieChartModal, setPieChartModal] = useState(false)
    const [barChartModal, setBarChartModal] = useState(false)
    const [currentPie, setCurrentPie] = useState(1);
    const [isGraphFilterClicked, setIsGraphFilterClicked] = useState(false)
    const history = useHistory();
    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');

    useEffect(() => {
        if (page == 1 && pageLoader) {
            setisLoading(true)
        }
        const params = {
            type: selectedOptions?.includes("All") ? null : selectedOptions[0],
            limit: 10,
            page
        }
        getCourseActivityData(params, (page == 1 ? true : false), () => { setisLoading(false) });
    }, [page]);

    const observer = useRef()
    const lastElementRef = useCallback(node => {
        if (isLoading || !node) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && courseActivityData?.meta?.currentPage < courseActivityData?.meta?.totalPages) {
                setPage(prevPage => prevPage + 1);
            }
        });
        observer.current.observe(node);
    }, [isLoading, courseActivityData]);

    useEffect(() => {
        storeAutocompleteSearchData([])
        setSearchResultPageParamsObj("search", null)
        togglePageLoader(true);
        getCourseDashboardData(() => { togglePageLoader(false) })
        getMarketSettingsData(true, () => { })
    }, [])

    const handleAutocompleteSearch = (value) => {
        setSearchResultPageParamsObj("search", value)
        setSearchLoader(true);
        getAutocompleteSearch(value, () => { setSearchLoader(false) })
    }
    const renderTitle = (title) => (
        <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
    );
    const searchOptions = [
        {
            label: autocompleteSearchData?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
            options: autocompleteSearchData?.suggestions?.length ? autocompleteSearchData?.suggestions?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => { setCourseTypeName("searchResult"); history.push({ pathname: "/search-result", state: { key: { fromDashboardSearch: true, dashboardSearchedValue: item } } }); }}>
                        <SearchOutlined /><div style={{ color: '#191919', fontWeight: 600, marginLeft: 10 }}>{item}</div>
                    </div>
                ),
            }))
                :
                []
        },
        {
            label: autocompleteSearchData?.resources?.length != 0 ? renderTitle("Courses") : null,
            options: autocompleteSearchData?.resources?.length ? autocompleteSearchData?.resources?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => { setCourseTypeName(item?.type == "course" ? "liveCourses" : item?.type == "videoResource" ? "videoResources" : item?.type == "studyMaterial" ? "studyMaterials" : item?.type == "testSeries" ? "onlineTestSeries" : null); storeSingleCourseId(item?.id); history.push({ pathname: "/course-details", state: { key: { fromDashboardSearch: true } } }) }}>
                        {(item?.type == "studyMaterial" || item?.type == "testSeries") ? <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} /> : <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 70, height: 40 }} />}
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 270 }}>{item?.name}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
    ];

    const actions = [
        <AutoComplete
            popupMatchSelectWidth={500}
            style={{ marginRight: 20, width: 310 }}
            options={(autocompleteSearchData?.suggestions?.length > 0 || autocompleteSearchData?.resources?.length > 0) ? searchOptions : []}
            onSearch={handleAutocompleteSearch}
            placeholder={<span style={{ color: "#999999" }}><SearchOutlined style={{ color: "#C8C8C8" }} /> Search courses</span>}
            loading={searchLoader}
            allowClear
            className='round-bordered-search-course'
        ></AutoComplete>,
        hasMarketingPrivilege ? <></> : <Button onClick={() => { setIsCourseTypeToggleModalVisible(true) }} type="secondary" icon={<SettingOutlined />}>CUSTOMIZE</Button>
    ]

    const imageSources = {
        liveCourse: liveCourseIcon,
        videoResource: videoResourceIcon,
        studyMaterial: studyMaterialIcon,
        testSeries: testSeriesIcon
    }

    const recentTitle = {
        liveCourse: "Live course",
        videoResource: "Video course",
        studyMaterial: "Study material",
        testSeries: "Test series"
    }

    const RecentlyAddedCard = ({ icon, title, item, isLast, isStudent, hasMarketingPrivilege }) => {
        return (
            <div className='recently-added-card-course' style={{ marginBottom: !isLast ? 10 : 1 }} onClick={() => {
                if (isStudent) {
                    setSingleStudentData(item); setIsStudentModalVisible(true)
                } else {
                    setSingleCourseData(item); setIsCourseModalVisible(true)
                }
            }}>
                <div className='recently-added-card-course-content r-c-fs'>
                    <img src={icon} style={{ height: 45, width: 45, marginLeft: -8 }} />
                    <div className='m-l-10' style={{ flex: 1, overflow: 'hidden', marginLeft: 10 }}>
                        <div className='r-c-sb' style={{ width: "100%" }}>
                            <div className="recently-added-title"><Title2>{title}</Title2></div>
                            <SmallInfo style={{ color: "#AEAEAE", fontSize: "0.75rem", flexShrink: 0 }}><FormattedDate createdAt={item?.createdAt} /></SmallInfo>
                        </div>
                        <Information className='m-t-4'>
                            {isStudent ? (
                                <span>{item?.userName} has {item.courseType === "liveCourse" ? 'enrolled to ' : 'purchased '}{item?.courseName}</span>
                            ) : (
                                item?.createdByName ? (
                                    <span>{item?.courseName} was added by {item?.createdByName}</span>
                                ) : (
                                    <span style={{ fontWeight: "normal" }}>{item?.courseName} has been added </span>
                                )
                            )}
                        </Information>
                    </div>
                </div>
            </div>
        )
    }

    const filterArray = [
        { label: "All", value: "All" },
        { label: "Courses", value: "course" },
        { label: "Students", value: "student" },
    ]
    const handleOptionSelect = (option) => {
        setPage(1);
        setIsScrolled(true)
        let updatedOptions = [];
        if (selectedOptions?.includes(option)) {
            updatedOptions = selectedOptions?.filter(item => item !== option);
        } else {
            updatedOptions = [option];
        }
        if (updatedOptions.length === 0) {
            updatedOptions = ["All"];
        }
        setSelectedOptions(updatedOptions);
        setisLoading(true);
        const type = updatedOptions?.includes("All") ? null : updatedOptions[0];
        const params = {
            type,
            limit: 10,
            page: 1
        };
        getCourseActivityData(params, (page == 1 ? true : false), () => setisLoading(false));
    }

    const menu = (
        <Menu className='different-bgc-dropdown' style={{ borderRadius: "8px", padding: "10px 10px", width: 200, marginTop: -36, boxShadow: "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1) !important", backgroundColor: "#FFFFFF" }}>
            <div className='r-c-sb'>
                <div style={{ color: "#191919" }}>{selectedOptions == "course" ? "Courses" : selectedOptions == "student" ? "Students" : "All"}</div>
                <img style={{ width: 15, height: 15, cursor: "pointer" }} src={filterIcon} onClick={() => { setIsFilterClicked(!isFilterClicked) }} />
            </div>
            <div className='r-c-c-c m-t-5 m-b-5' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            {filterArray.map(option => (
                <Menu.Item key={option.value}
                    style={{ cursor: "default", borderRadius: 4, fontWeight: selectedOptions?.includes(option?.value) ? 600 : "normal", color: selectedOptions?.includes(option?.value) ? "#191919" : "#AEAEAE" }}
                    onClick={() => handleOptionSelect(option.value)}
                >
                    <Checkbox
                        checked={selectedOptions?.includes(option.value)}
                        onChange={() => handleOptionSelect(option.value)}
                        style={{ cursor: "pointer" }}
                    >
                        {option.label}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    )

    const graphFilterArray = [
        { value: "liveCourse", label: "Live courses" },
        { value: "videoResource", label: "Video courses" },
        { value: "studyMaterial", label: "Study materials" },
        { value: "testSeries", label: "Test series" },
    ]

    const graphMenu = (
        <Menu className='different-bgc-dropdown' style={{ borderRadius: "8px", padding: "10px", width: "fit-content", boxShadow: "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1) !important", backgroundColor: "#FFFFFF" }}>
            {graphFilterArray?.map((item) => (
                <Menu.Item style={{ fontSize: 15, cursor: "pointer", color: courseType == item?.value ? "#191919" : "#636363", fontWeight: courseType == item?.value ? 600 : "normal", borderRadius: 4, marginTop: 5, padding: "5px 10px" }} onClick={() => { setCourseType(item?.value) }}>{item?.label}</Menu.Item>
            ))}
        </Menu>
    )

    useEffect(() => {
        getCourseGraphData(courseType, isMonth, isYear, isChecked, () => { })
    }, [courseType, isMonth, isYear, isChecked])

    useEffect(() => {
        if (!storeGraphData || !storeGraphData.courseDetails) return;
        const temp = [];
        for (let i = 0; i < storeGraphData.courseDetails.length; i++) {
            let obj = {}
            obj['value'] = storeGraphData.courseDetails[i].amount,
                obj['label'] = storeGraphData.courseDetails[i].name.slice(0, 10)
            obj['type'] = 'Revenue ',
                obj['typeRev'] = storeGraphData.courseDetails[i].indexLevel
            temp.push(obj);
            let obj1 = {}
            obj1['value'] = storeGraphData.courseDetails[i].popularIndex,
                obj1['label'] = storeGraphData.courseDetails[i].name.slice(0, 10)
            obj1['type'] = 'Popularity',
                obj1['typeRev'] = storeGraphData.courseDetails[i].indexLevel
            temp.push(obj1);
        }
        setGraphData(temp);
    }, [storeGraphData]);

    useEffect(() => {
        getCoursePieChartData(() => { })
    }, [])

    const courseData = [
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[0]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[0]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[0]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[0]?.subtitle,
            tooltip: 'courseDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[1]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[1]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[1]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[1]?.subtitle,
            tooltip: 'courseDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[2]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[2]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[2]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[2]?.subtitle,
            tooltip: 'courseDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[3]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[3]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[3]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[3]?.subtitle,
            tooltip: 'courseDec'
        },

    ];

    const studentData = [
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[0]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[0]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[0]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[0]?.subtitle,
            tooltip: 'studentDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[1]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[1]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[1]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[1]?.subtitle,
            tooltip: 'studentDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[2]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[2]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[2]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[2]?.subtitle,
            tooltip: 'studentDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[3]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[3]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[3]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[3]?.subtitle,
            tooltip: 'studentDec'
        },

    ];

    const assignClassOnclick = () => {
        const newItem = {
            ...singleStudentData,
            id: singleStudentData?.userId,
            name: singleStudentData?.userName,
            role: [singleStudentData?.role]
        };
        delete newItem.userId;
        delete newItem.userName;
        setUserDetails(newItem)
        setSpecificUserId(newItem?.id);
        setOthersSelectedTab('Classroom & course(s)');
        history.push({
            pathname: '/user-management/profile',
            state: {
                key: true
            }
        })
    }

    const SingleOverviewCard = ({ count, subTitle, imageSources, list, studentCount, item }) => {
        const reorderedModeCountList = [
            item?.modeCountList?.find(item => item?.mode === 'online'),
            item?.modeCountList?.find(item => item?.mode === 'offline'),
            item?.modeCountList?.find(item => item?.mode === 'hybrid')
        ];
        return (
            <div className="overview-length1-card" onClick={() => {
                setCourseTypeName("liveCourses");
                history.push("/live-courses-data")
            }}>
                <div className='overview-info-one-card'>
                    <div className="" style={{}}>
                        <Title>{count}</Title>
                        <div className='r-ac'>
                            <Information>
                                {subTitle.toLowerCase()}
                            </Information>
                            <Information className='m-l-5'>
                                (<span>{studentCount || 0}</span> {studentCount > 1 ? "students" : "student"})
                            </Information>
                        </div>
                    </div>
                    <div className="card-img-parent"> <img className='card-img' src={imageSources} alt={"images"} style={{ marginRight: -8, width: 45, height: 45 }} /></div>
                </div>
                <div className='r-jsb' style={{ width: "fit-content", flexWrap: "wrap", width: "100%" }}>
                    {reorderedModeCountList?.map((item, _index) => (
                        <OverviewList item={item} />
                    ))}
                </div>
            </div>
        )
    }

    const PromotionalOffersCard = ({ lessPadding }) => {
        return (
            <div className='recently-added-card r-c-fs' style={{ width: "100%", alignItems: "center", padding: lessPadding ? "15px 20px" : 20 }}>
                <img src={require("../../Assets/courseManagementV4/new-promo-offers-icon.svg").default} style={{ height: 45, width: 45, marginLeft: -8 }} />
                <div className='m-l-10 r-c-sb' style={{ width: "100%" }}>
                    <div>
                        <Title>Promotional offers</Title>
                        <div>
                            <Information>
                                {courseDashboardData?.offers?.items?.length}&nbsp; active promotional offers
                            </Information>
                        </div>
                        <div style={{ color: "#636363", fontSize: '0.875rem' }}></div>
                    </div>
                    <LockOutlined style={{ fontSize: 24, color: "#AEAEAE" }} />
                </div>
            </div>
        )
    }

    const BatchesCard = ({ moreHeight }) => {
        const batchesArray = [
            {
                image: totalBatches,
                count: parseInt(courseDashboardData?.batchDetails?.standardCount) || 0,
                desc: "total batches"
            },
            {
                image: totalSections,
                count: parseInt(courseDashboardData?.batchDetails?.sectionCount) || 0,
                desc: "total sections"
            },
            {
                image: classesScheduled,
                count: parseInt(courseDashboardData?.batchDetails?.testCount) || 0,
                desc: "tests scheduled"
            },
            {
                image: testScheduled,
                count: parseInt(courseDashboardData?.batchDetails?.scheduledCount) || 0,
                desc: "classes scheduled"
            },
        ]
        return (
            <div className='recently-added-card' id="batchesCard" style={{ width: "100%", alignItems: "center" }}>
                <div className='r-c-sb'>
                    <Title>Batches</Title>
                    {hasMarketingPrivilege ? <></> :
                        <Button type="link" onClick={() => { history.push({ pathname: "/classroom", state: { key: "fromCourseHome" } }) }}>VIEW ALL</Button>
                    }
                </div>
                <div className='r-jsb' style={{ flexWrap: "wrap", width: "100%" }}>
                    {batchesArray?.map((item, index) => (
                        <div className='r-ac m-t-10' style={{ width: "50%", marginBottom: (index == 0 || index == 1) && moreHeight ? 15 : (index == 0 || index == 1) ? 5 : 0 }}>
                            <img src={item?.image} style={{ height: 40, width: 40, marginLeft: -6 }} />
                            <div className='m-l-10'>
                                <div><Title2>{item?.count}</Title2></div>
                                <div><Information>{item?.desc}</Information></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                const scrollTop = div.scrollTop;
                const scrollHeight = div.scrollHeight;
                const clientHeight = div.clientHeight;
                if (scrollTop > 0 && scrollHeight > clientHeight) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            }
        };
        const div = document.getElementById('scrollableDiv');
        if (div) {
            div.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (div) {
                div.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const div = document.getElementById('scrollableDiv');
        if (isScrolled) {
            setTimeout(() => {
                div.scrollTop = 1
            }, 900)
        }
    }, [isScrolled]);

    const Legend = ({ color, text, count }) => {
        return (
            <div className='r-ac'>
                <div style={{ height: "12px", width: "12px", backgroundColor: color, borderRadius: 2 }}></div>
                <SmallInfo color={Color.darkGrey} className='m-l-8'>{text} {count ? `(${count})` : null}</SmallInfo>
            </div>
        )
    }

    const CoursePieGraph = () => {
        const CourseDistLegendArray = [
            { color: "#B59009", text: "Live courses", count: storePieChartData?.graph?.pieChart?.courseDetails[0]?.count ? storePieChartData?.graph?.pieChart?.courseDetails[0]?.count : 0 },
            { color: "#DAAB00", text: "Video courses", count: storePieChartData?.graph?.pieChart?.courseDetails[1]?.count ? storePieChartData?.graph?.pieChart?.courseDetails[1]?.count : 0 },
            { color: "#E9CC64", text: "Test series", count: storePieChartData?.graph?.pieChart?.courseDetails[2]?.count ? storePieChartData?.graph?.pieChart?.courseDetails[2]?.count : 0 },
            { color: "#F9E7A3", text: "Study materials", count: storePieChartData?.graph?.pieChart?.courseDetails[3]?.count ? storePieChartData?.graph?.pieChart?.courseDetails[3]?.count : 0 },
        ]
        const StudentsDistLegendArray = [
            { color: "#594099", text: "Live courses", count: storePieChartData?.graph?.pieChart?.studentDetails[0]?.count ? storePieChartData?.graph?.pieChart?.studentDetails[0]?.count : 0 },
            { color: "#967DD5", text: "Video courses", count: storePieChartData?.graph?.pieChart?.studentDetails[1]?.count ? storePieChartData?.graph?.pieChart?.studentDetails[1]?.count : 0 },
            { color: "#C3B1EC", text: "Test series", count: storePieChartData?.graph?.pieChart?.studentDetails[2]?.count ? storePieChartData?.graph?.pieChart?.studentDetails[2]?.count : 0 },
            { color: "#DACFF2", text: "Study materials", count: storePieChartData?.graph?.pieChart?.studentDetails[3]?.count ? storePieChartData?.graph?.pieChart?.studentDetails[3]?.count : 0 },
        ]
        return (
            <div>
                <div className='r-c-sb' style={{ marginTop: -7 }}>
                    <Title>Course types</Title>
                    <FullscreenOutlined className='full-screen-outlined-icon' onClick={() => { setPieChartModal(true), setCurrentPie(1) }} />
                </div>
                <div className='r-c-sa' style={{ width: "100%" }}>
                    <div style={{ width: "50%" }}>
                        <div className='r-c-c-c'>
                            <div className='pie-chart-size' >
                                <PieCharts color={colors} data={courseData?.length != 0 ? courseData : []} />
                            </div>
                        </div>
                        <div style={{ marginLeft: "15%" }}>
                            <Title2 style={{ marginBottom: 10, marginTop: 2 }}>Course distribution</Title2>
                            {CourseDistLegendArray?.map((item) => (
                                <div className='m-t-5'>
                                    <Legend color={item?.color} text={item?.text} count={item?.count} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div className='r-c-c-c'>
                            <div className='pie-chart-size'>
                                <PieCharts color={stdColors} data={studentData?.length != 0 ? studentData : []} />
                            </div>
                        </div>
                        <div style={{ marginLeft: "15%" }}>
                            <Title2 style={{ marginBottom: 10, marginTop: 2 }}>Students distribution</Title2>
                            {StudentsDistLegendArray?.map((item) => (
                                <div className='m-t-5'>
                                    <Legend color={item?.color} text={item?.text} count={item?.count} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const BarGraphLegendArray = [
        { color: "#594099", text: "Revenue (₹)" },
        // { color: "#BBB8E8", text: "Popularity" },
    ]
    const CourseBarGraph = ({ heading, value }) => {
        return (
            <div style={{ height: "100%" }}>
                <div className='r-c-sb' style={{ width: "100%" }}>
                    <div style={{ marginTop: -7 }}>
                        {value == "liveCourseGraph" ?
                            <Title>{heading}</Title> :
                            <Dropdown overlay={isGraphFilterClicked ? graphMenu : <></>} trigger={['click']} open={isGraphFilterClicked} >
                                <div style={{ display: "flex" }} onClick={(e) => { e.stopPropagation() }}>
                                    <Title>{heading}</Title>
                                    {isGraphFilterClicked ?
                                        <FiChevronUp style={{ color: "#AEAEAE", fontSize: 20, marginLeft: 10, marginTop: 8, cursor: "pointer" }} onClick={() => { setIsGraphFilterClicked(false) }} /> :
                                        <FiChevronDown style={{ color: "#AEAEAE", fontSize: 20, marginLeft: 10, marginTop: 8, cursor: "pointer" }} onClick={() => { setIsGraphFilterClicked(true) }} />}
                                </div>
                            </Dropdown>
                        }
                    </div>
                    <div style={{ display: "flex", gap: 20 }}>
                        {!isChecked ?
                            <DatePicker
                                allowClear={false}
                                picker="month"
                                format={'MMM'}
                                value={moment(isMonth, 'MMM')}
                                style={{ borderRadius: "50px", width: "80px", height: "25px", border: "1px solid #BDB6D5" }}
                                onChange={(date, dateString) => {
                                    setIsMonth(moment(date).format('MMM'))
                                }}
                            /> :
                            <DatePicker
                                allowClear={false}
                                picker="year"
                                value={moment(isYear)}
                                style={{ borderRadius: "50px", width: "80px", height: "25px", border: "1px solid #BDB6D5" }}
                                onChange={(date, dateString) => {
                                    setIsYear(moment(date).format('YYYY'))
                                }}
                            />}
                        <SwitchComponent
                            setIsChecked={setIsChecked}
                            isChecked={isChecked} />
                        <FullscreenOutlined className='full-screen-outlined-icon' onClick={() => { setBarChartModal(true), setCurrentPie(1) }} />
                    </div>
                </div>
                {/* <div><Information>Popularity & revenue</Information></div> */}
                <div style={{ marginTop: "10px" }}><GroupedBarPlot data={graphData?.length != 0 ? graphData : []} setVisibleRange={setVisibleRange} visibleRange={visibleRange} length={storeGraphData?.courseDetails?.length} source={"courseHome"} /></div>
                {BarGraphLegendArray?.map((item) => (
                    <Legend color={item?.color} text={item?.text} />
                ))}
            </div>
        )
    }

    const [currentPageCarousel, setCurrentPage] = useState(0);
    const carouselRef = useRef(null);

    const handleBeforeChange = (current, next) => {
        setCurrentPage(next);
    };

    const handleDotClick = (index) => {
        setCurrentPage(index);
        if (carouselRef.current) {
            carouselRef.current.goTo(index);
        }
    };

    useEffect(() => {
        if (currentPageCarousel === 1) {
            setIsGraphFilterClicked(false);
        }
    }, [currentPageCarousel, setIsGraphFilterClicked]);

    const pageHeader = document.getElementById("pageHeader");
    const pageHeaderHeight = pageHeader?.clientHeight;
    const overviewDiv = document.getElementById("overviewDiv");
    const overviewDivHeight = overviewDiv?.clientHeight;
    const batchesPromoDiv = document.getElementById("batchesPromoDiv");
    const batchesPromoDivHeight = batchesPromoDiv?.clientHeight;

    return (
        <div style={{ height: "100%", width: "100%" }} onClick={(e) => { e.stopPropagation(); setIsFilterClicked(false); setIsGraphFilterClicked(false) }}>
            <div id="pageHeader">
                <PageHeader title='Courses' actions={actions} path="courseHome" />
            </div>
            <div style={{ height: `calc(100% - ${pageHeaderHeight}px)` }}>
                {courseDashboardData?.overview?.length == 1 ?
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "100%" }}>
                        <div style={{ width: "50%", marginRight: 20, height: "100%", paddingTop: 10 }}>
                            <div id="overviewDiv" style={{
                                opacity: isScrolled ? 0 : 1,
                                transition: 'opacity 2s ease',
                                transform: isScrolled ? 'translateY(-150%)' : 'translateY(0)'
                            }}>
                                {courseDashboardData?.overview?.map((item, index) => (
                                    <SingleOverviewCard count={item?.count} subTitle={item?.subtitle} imageSources={imageSources[item?.overviewType]} list={item?.list} studentCount={item?.studentCount} item={item} />
                                ))}
                            </div>
                            <div style={{ height: isScrolled ? "100%" : `calc(97% - ${overviewDivHeight}px)`, width: "100%", marginTop: isScrolled ? -148 : 20, transition: 'margin-top 1s ease' }}>
                                <div className='r-jsb'>
                                    <Title>Recently added</Title>
                                    <Dropdown overlay={isFilterClicked ? menu : <></>} trigger={['click']} open={isFilterClicked} >
                                        <div className='dropdownStyle' style={{ border: "1px solid #CDC6E0", backgroundColor: "#FFFFFF", width: 200, height: "2rem", padding: "2px 10px", color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 8, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setIsFilterClicked(!isFilterClicked) }}>
                                            <div style={{ color: "#191919" }}>{selectedOptions == "course" ? "Courses" : selectedOptions == "student" ? "Students" : "All"}</div>
                                            <img style={{ width: 15, height: 15 }} src={filterIcon} />
                                        </div>
                                    </Dropdown>
                                </div>
                                <div id="scrollableDiv" className={`scroll-bar-universal ${isLoading ? "r-c-c-c" : ""}`} style={{ height: isScrolled ? "93%" : "90.5%", marginTop: 20 }}>
                                    {isLoading ?
                                        <div>
                                            <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                                        </div>
                                        :
                                        <>
                                            {courseActivityData?.items?.map((item, index) => (
                                                <>
                                                    {item?.activityType == "student" ?
                                                        <div ref={(index === courseActivityData?.items?.length - 1) ? lastElementRef : null}>
                                                            <RecentlyAddedCard title={"Student enrolled or student registered"} icon={studentIcon} item={item} isLast={index === courseActivityData.items.length - 1} isStudent={true} hasMarketingPrivilege={hasMarketingPrivilege} />
                                                        </div>
                                                        :
                                                        <>
                                                            {item?.activityType == "course" ?
                                                                <div ref={(index === courseActivityData?.items?.length - 1) ? lastElementRef : null}>
                                                                    <RecentlyAddedCard title={recentTitle[item?.courseType] + " " + "added"} icon={imageSources[item?.courseType]} item={item} isLast={index === courseActivityData.items.length - 1} hasMarketingPrivilege={hasMarketingPrivilege} />
                                                                </div>
                                                                :
                                                                <></>}
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "48%", height: "100%", paddingTop: 10 }}>
                            <div id="batchesPromoDiv">
                                <BatchesCard />
                                <div className='m-t-20'>
                                    <PromotionalOffersCard />
                                </div>
                            </div>
                            <div className='graph-card-live m-t-20' style={{ height: `calc(97.1% - ${batchesPromoDivHeight}px)` }}>
                                <CourseBarGraph heading={"Live course"} value={"liveCourseGraph"} />
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "100%" }}>
                        <div style={{ width: "50%", marginRight: 20, height: "100%", paddingTop: 10 }}>
                            <div id="overviewDiv" style={{
                                opacity: isScrolled ? 0 : 1,
                                transition: 'opacity 2s ease',
                                transform: isScrolled ? 'translateY(-150%)' : 'translateY(0)'
                            }}>
                                <div style={{ opacity: isScrolled ? 0 : 1, transition: "opacity 2s ease" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
                                        {courseDashboardData?.overview?.map((item, index) => (
                                            <div style={{ marginBottom: 20, width: courseDashboardData?.overview?.length == 3 && index == 2 ? "105%" : "50%", marginLeft: -9 }}>
                                                <CourseOverviewCard count={item?.count} subTitle={item?.subtitle == "Online Test Series" ? "test series" : item?.subtitle} imageSources={imageSources[item?.overviewType]} setCourseTypeName={setCourseTypeName} history={history} item={item} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: isScrolled ? "100%" : `calc(100% - ${overviewDivHeight}px)`, width: "100%", marginTop: courseDashboardData?.overview?.length == 2 && isScrolled ? -115 : isScrolled ? -229 : 0, transition: 'margin-top 1s ease' }}>
                                <div className='r-jsb'>
                                    <Title>Recently added</Title>
                                    <Dropdown overlay={isFilterClicked ? menu : <></>} trigger={['click']} open={isFilterClicked} >
                                        <div className='dropdownStyle' style={{ border: "1px solid #CDC6E0", backgroundColor: "#FFFFFF", height: "2rem", width: 200, padding: "2px 10px", color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 8, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setIsFilterClicked(!isFilterClicked) }}>
                                            <div style={{ color: "#191919" }}>{selectedOptions == "course" ? "Courses" : selectedOptions == "student" ? "Students" : "All"}</div>
                                            <img style={{ width: 15, height: 15 }} src={filterIcon} />
                                        </div>
                                    </Dropdown>
                                </div>
                                <div className={`scroll-bar-universal ${isLoading ? "r-c-c-c" : ""}`} id="scrollableDiv" style={{ transition: 'height 2s ease', marginTop: 20, height: courseDashboardData?.overview?.length == 2 && !isScrolled ? "91%" : isScrolled ? "93%" : "89%" }}>
                                    {isLoading ?
                                        <div>
                                            <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                                        </div>
                                        :
                                        <>
                                            {courseActivityData?.items?.map((item, index) => (
                                                <>
                                                    {item?.activityType == "student" ?
                                                        <div ref={(index === courseActivityData?.items?.length - 1) ? lastElementRef : null}>
                                                            <RecentlyAddedCard title={item.courseType === "liveCourse" ? "Student enrolled" : "Course purchased"} icon={studentIcon} item={item} isLast={index === courseActivityData.items.length - 1} isStudent={true} hasMarketingPrivilege={hasMarketingPrivilege} />
                                                        </div>
                                                        :
                                                        <>
                                                            {item?.activityType == "course" ?
                                                                <div ref={(index === courseActivityData?.items?.length - 1) ? lastElementRef : null}>
                                                                    <RecentlyAddedCard title={recentTitle[item?.courseType] + " " + "added"} icon={imageSources[item?.courseType]} item={item} isLast={index === courseActivityData.items.length - 1} hasMarketingPrivilege={hasMarketingPrivilege} />
                                                                </div>
                                                                :
                                                                <></>}
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "48%", height: "100%", paddingTop: 10 }}>
                            <div id="batchesPromoDiv">
                                <BatchesCard />
                                <div className='m-t-20' ><PromotionalOffersCard /></div>
                            </div>
                            <div className='graph-card m-t-20' style={{ height: `calc(97.1% - ${batchesPromoDivHeight}px)` }}>
                                <Carousel
                                    ref={carouselRef}
                                    autoPlaySpeed={7000}
                                    dots={false}
                                    autoplay={true}
                                    beforeChange={handleBeforeChange}>
                                    <CoursePieGraph />
                                    <CourseBarGraph heading={courseType == "liveCourse" ? "Live courses" : courseType == "videoResource" ? "Video courses" : courseType == "studyMaterial" ? "Study materials" : courseType == "testSeries" ? "Test series" : null} />
                                </Carousel>
                            </div>
                            <CustomDots currentPage={currentPageCarousel} totalSlides={2} onDotClick={handleDotClick} />
                        </div>
                    </div>
                }
            </div>
            {isCourseTypeToggleModalVisible &&
                <CourseTypesToggleModal
                    isCourseTypeToggleModalVisible={isCourseTypeToggleModalVisible}
                    setIsCourseTypeToggleModalVisible={setIsCourseTypeToggleModalVisible}
                    getCourseDashboardData={getCourseDashboardData}
                    togglePageLoader={togglePageLoader}
                    getCoursePieChartData={getCoursePieChartData}
                />}
            {isCourseModalVisible &&
                <RecentAddedCourseModal
                    isCourseModalVisible={isCourseModalVisible}
                    setIsCourseModalVisible={setIsCourseModalVisible}
                    courseType={singleCourseData?.courseType == "liveCourse" ? "Live course" : singleCourseData?.courseType == "videoResource" ? "Video course" : singleCourseData?.courseType == "studyMaterial" ? "Study material" : singleCourseData?.courseType == "testSeries" ? "test series" : null}
                    courseName={singleCourseData?.courseName}
                    fee={(singleCourseData?.amount - singleCourseData?.discountAmount)?.toFixed(2)}
                    installments={"0"}
                    createdBy={singleCourseData?.createdByName}
                    createdAt={moment(singleCourseData?.createdAt).format("DD/MM/YYYY")}
                />}
            {isStudentModalVisible &&
                <RecentAddedStudentsModal
                    isStudentModalVisible={isStudentModalVisible}
                    setIsStudentModalVisible={setIsStudentModalVisible}
                    name={singleStudentData?.userName}
                    image={singleStudentData?.image}
                    role={singleStudentData?.role}
                    number={singleStudentData?.phone}
                    email={singleStudentData?.email}
                    courseType={singleStudentData?.courseType}
                    icon={singleStudentData?.courseType == "liveCourse" ? LiveCourseIcon : singleStudentData?.courseType == "videoResource" ? VideoCourseIcon : singleStudentData?.courseType == "studyMaterial" ? StudyMaterialIcon : singleStudentData?.courseType == "testSeries" ? TestSeriesIcon : null}
                    createdAt={moment(singleStudentData?.createdAt).format("DD/MM/YYYY")}
                    courseName={singleStudentData?.courseName}
                    showAssignClass={parseInt(singleStudentData?.standardCount) == 0 && singleStudentData?.courseType == "liveCourse"}
                    assignClassOnclick={assignClassOnclick}
                    showAssignGeofence={!singleStudentData?.geoFenceId && !singleStudentData?.geoFenceTitle}
                    togglePageLoader={togglePageLoader}
                    getCourseActivityData={getCourseActivityData}
                    userId={singleStudentData?.userId}
                    hasMarketingPrivilege={hasMarketingPrivilege}
                />}
            {
                <BarPlotModal
                    setPieChartModal={setPieChartModal}
                    pieChartModal={pieChartModal}
                    colors={colors}
                    courseData={courseData}
                    stdColors={stdColors}
                    studentData={studentData}
                    graphData={graphData}
                    courseType={courseType}
                    isChecked={isChecked}
                    setIsMonth={setIsMonth}
                    setIsChecked={setIsChecked}
                    setIsYear={setIsYear}
                    isMonth={isMonth}
                    isYear={isYear}
                    setCourseType={setCourseType}
                    data={storePieChartData?.graph?.pieChart}
                    setCurrentPie={setCurrentPie}
                    currentPie={currentPie}
                />
            }
            {
                <BarChartModal
                    setPieChartModal={setBarChartModal}
                    pieChartModal={barChartModal}
                    colors={colors}
                    courseData={courseData}
                    stdColors={stdColors}
                    studentData={studentData}
                    graphData={graphData}
                    courseType={courseType}
                    isChecked={isChecked}
                    setIsMonth={setIsMonth}
                    setIsChecked={setIsChecked}
                    setIsYear={setIsYear}
                    isMonth={isMonth}
                    isYear={isYear}
                    setCourseType={setCourseType}
                    data={storePieChartData?.graph?.pieChart}
                    setCurrentPie={setCurrentPie}
                    currentPie={currentPie}
                    courseTypeLength={courseDashboardData?.overview?.length} />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { courseDashboardData, courseActivityData, autocompleteSearchData, storeGraphData, storePieChartData } = state.courseManagement;
        const { user } = state.session;
    return { courseDashboardData, courseActivityData, autocompleteSearchData, storeGraphData, storePieChartData, user };
};

const mapDispatchToProps = (dispatch) => ({
    getCourseDashboardData: (callBack) => dispatch(getCourseDashboardData(callBack)),
    getCourseActivityData: (params, loadmore, callBack) => dispatch(getCourseActivityData(params, loadmore, callBack)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    getAutocompleteSearch: (search, callBack) => dispatch(getAutocompleteSearch(search, callBack)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    getMarketSettingsData: (all, callBack) => dispatch(getMarketSettingsData(all, callBack)),
    getCourseGraphData: (courseType, isMonth, isYear, isChecked, callBack) => dispatch(getCourseGraphData(courseType, isMonth, isYear, isChecked, callBack)),
    getCoursePieChartData: (callBack) => dispatch(getCoursePieChartData(callBack)),
    setUserDetails: (res) => dispatch(setUserDetails(res)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    setOffersTypeIndex: (val) => dispatch(setOffersTypeIndex(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseDashboard);