import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { Api, Color } from "services";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  ArrowLeftOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
  CopyFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  currentDocument,
  currentTypes,
  deleteNotes,
  fetchAdminLibraryData,
  removemodel,
  setEditObject,
  setUploadContent,
  updateDocumentInfo,
  IsHSL
} from "../../../actions/digitalLibrary";
import EditInformation from "../modals/editInformation";
import CloneInformation from "../modals/cloneInformation";
import DeleteConfirm from "../modals/deleteConfirm";
import YTPlayer from "components/ytPlayer";
import Axios from 'axios'
import fileDownload from 'js-file-download'
import ReactPlayerHoc from "components/ReactPlayerHoc";

const { confirm } = Modal;

function Viewpdf({
  file,
  currentfiletoshow,
  currentId,
  currentDocument,
  deleteNotes,
  fetchAdminLibraryData,
  currentType,
  currentTypes,
  removemodel,
  user,
  documentInfo,
  updateDocumentInfo,
  setEditObject,
  setUploadContent,
  isHSL
}) {
  const history = useHistory();
  const [isVisibl, setIsModalVisibl] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [modal, setmodal] = useState(false);
  const [linkPath, setLinlkPath] = useState('')
  removemodel();

  const handleHistory = () => {
    updateDocumentInfo({});
    currentDocument(0);
    setEditObject({});
    setUploadContent({});
    IsHSL(false)
    history.goBack();
  };

  const handleDeletenotes = (id) => {
    // return confirm({
    //     title: `Do you want to delete the content?`,
    //     icon: <ExclamationCircleOutlined />,
    //     content: 'Please click OK to confirm',
    //     onOk() {deleteNotes(id,false)
    //       history.goBack()

    //     },
    //     onCancel() {},
    //   })
    setIsDeleteModal(true);
  };
  useEffect(() => {
    if (isHSL) {
      setLinlkPath(file ? `${Api.hlsUrl}${file}` : "")
    }
    else {
      setLinlkPath(file ? `${Api.dlS3Url}${file}` : "")
    }
  }, [])

  const handleDownload = (file, name, type) => {
    console.log("name", name)
    Axios
        .get(`${Api.dlS3Url}${file}`, {
            responseType: "blob",
        })
        .then((res) => {
            if (res.data) {
              fileDownload(res.data, `${name}.${type == "video" ? "video" : "pdf"}`);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "5%",
          marginBottom: "5px",
          background: "",
          justifyContent: "space-between",
          display: "flex",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowLeftOutlined
            style={{ fontSize: "150% ", color: "#636363" }}
            onClick={() => handleHistory()}
          />
        </div>
        {user.access.includes("digital-library-admin") ||
          user.access.includes("digital-library-superadmin") ||
          user.access.includes("digital-library-dataoperator") ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EditFilled style={{ fontSize: "150% ", color: "#636363" }} />
            <div
              className="cursor-pointer"
              style={{
                marginRight: "20px",
                marginLeft: "5px",
                fontSize: "14px",
                color: "#636363",
                font: "normal normal normal 14px/16px Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setIsModalVisibl(true)}
            >
              {" "}
              Edit
            </div>
            {isVisibl && (
              <EditInformation
                ismodelvisible={isVisibl}
                setmodevisible={setIsModalVisibl}
                id={currentId}
                setmodal={setmodal}
                types={currentType}
                title={documentInfo.title}
              />
            )}

            <CopyFilled style={{ fontSize: "150% ", color: "#636363" }} />
            <div
              className="cursor-pointer"
              style={{
                marginRight: "20px",
                marginLeft: "5px",
                fontSize: "14px",
                color: "#636363",
                font: "normal normal normal 14px/16px Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setCloneModal(true)}
            >
              Clone
            </div>
            {cloneModal && (
              <CloneInformation
                ismodelvisible={cloneModal}
                setmodevisible={setCloneModal}
                id={currentId}
                setmodal={setmodal}
                types={currentType}
                title={documentInfo.title}
              />
            )}

            <DeleteFilled style={{ fontSize: "150% ", color: "#636363" }} />
            <div
              className="cursor-pointer"
              style={{
                marginRight: "20px",
                marginLeft: "5px",
                fontSize: "14px",
                color: "#636363",
                font: "normal normal normal 14px/16px Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleDeletenotes()}
            >
              Delete
            </div>
            {(documentInfo?.status == 'Published' && documentInfo?.file && (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin'))) &&
            <>
            <DownloadOutlined style={{ fontSize: "150% ", color: "#636363" }}/>
            <div
              className="cursor-pointer"
              style={{
                marginRight: "20px",
                marginLeft: "5px",
                fontSize: "14px",
                color: "#636363",
                font: "normal normal normal 14px/16px Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleDownload(documentInfo?.file, documentInfo?.title, documentInfo?.type)}
            >
              Download
            </div>
            </>}
          </div>
        ) : null}
        {isDeleteModal && (
          <DeleteConfirm
            isModalVisible={isDeleteModal}
            setModalVisible={setIsDeleteModal}
            id={currentId}
            notes={true}
            history={history}
          />
        )}
      </div>
      {console.log("currentfiletoshow",currentfiletoshow)}
      { currentfiletoshow == 'link' ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
padding:"50px"
          }}
        >
          {/* <ReactPlayer
            style={{ marginTop: 30 }}
            height={"70%"}
            width={"90%"}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={e => e.preventDefault()}
            url={linkPath}
            controls={true}
          /> */}
          <YTPlayer videoUrl={linkPath} keyboardControls={true}/>
        </div>
      ) :
(documentInfo?.type == 'lecture-notes' || currentfiletoshow == 'pdf') ? 
        (
          <div style={{ height: "100%", width: "100%" }}>
            <iframe
              width="100%"
              height="100%"
              src={`${Api.dlS3Url}${file}#toolbar=0`}
              frameborder="0"
            ></iframe>
          </div>
):
        (
          <div style={{ height: "100%", width: "100%" }}>
            <ReactPlayerHoc
            style={{ marginTop: 30 }}
            height={"70%"}
            width={"90%"}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={e => e.preventDefault()}
            url={linkPath}
            controls={true}
            // contentId={currentId}
            keyboardControls={true}
            // moduleType={'DIGITALLIBRARY'}
          />
          </div>
        )
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  const { file, currentfiletoshow, currentId, currentType, documentInfo, isHSL } =
    state.digitalLibrary;
  const { user } = state.session;
  return {
    file,
    currentfiletoshow,
    currentId,
    currentType,
    user,
    documentInfo,
    isHSL
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteNotes: (id, fetch) => dispatch(deleteNotes(id, fetch)),
  currentDocument: (id) => dispatch(currentDocument(id)),
  currentTypes: (type) => dispatch(currentTypes(type)),
  fetchAdminLibraryData: () => dispatch(fetchAdminLibraryData()),
  removemodel: () => dispatch(removemodel()),
  updateDocumentInfo: (data) => dispatch(updateDocumentInfo(data)),
  setEditObject: (object) => dispatch(setEditObject(object)),
  setUploadContent: (object) => dispatch(setUploadContent(object)),
  IsHSL: (bool) => dispatch(IsHSL(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(Viewpdf);
