import React from 'react'
import {Menu} from 'antd'
import { EditFilled, LoadingOutlined, MessageOutlined, StopOutlined, UploadOutlined } from '@ant-design/icons';
import {  AiFillDelete, AiFillCopy} from "react-icons/ai";
import {connect} from 'react-redux';

function CustomMenu({
    type, test, handleButtonClick, changeTestStatusModalBool, createTestModalBool,
    userType, editDetailsLoader, cloneLoader, user}) {
        
    return (
        <>
    <Menu className='p-l-20 p-r-20 p-t-10 p-b-10 editMenuShadow'  
        style={((changeTestStatusModalBool)||(createTestModalBool))?{display:'none'}:{}}>
        {((type=="draft")||(type=="dash_scheduled")||(type=="feedback")||((userType=="teacher")&&(type=="ready")))
            ?
            <>
            <div className='r-ac cursor-pointer m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'Edit', test, e)}>
                {editDetailsLoader?<LoadingOutlined />:<EditFilled  className='text-md'/>}
                <div className='text-xs color-black m-l-10'>Edit Details</div>
            </div>
            {((type=="draft")||(type=="feedback"))
            ?
                (test.totalQuestions!=undefined && test.totalQuestions>0) &&( userType=="dataOperator" || (userType==='admin'&&test.authorId===user.id&&test.authorId!==test.teacherId))
                ?
                <div className='r-ac cursor-pointer m-t-20 m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'Review', test,e)}>
                    <MessageOutlined  className='text-md'/>
                    <div className='text-xs color-black m-l-10'>Send for Review</div>
                </div>
                :
                    (test.totalQuestions!=undefined && test.totalQuestions>0) && userType=="teacher"
                    ?
                    <div className='r-ac cursor-pointer m-t-20 m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'MarkReady', test, e)}>
                        <UploadOutlined  className='text-md'/>
                        <div className='text-xs color-black m-l-10'>Mark as Ready to be Published</div>
                    </div>
                    :
                    null
            :
            null}

            </>
        :
        null}
        {/* {(((userType=="admin")||(userType=="dataOperator"))&&(type=="feedback"))
        ?
        <div className='r-ac cursor-pointer m-t-20 m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'sendForFeedback', test, e)}>
            <MessageOutlined  className='text-md'/>
            <div className='text-xs color-black m-l-10'>Send for Feedback</div>
        </div>
        :
        null} */}
        {userType=="admin" && type!="published" && (test.totalQuestions!=undefined && test.totalQuestions>0) 
            ?
            <div className='r-ac cursor-pointer m-t-20 m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'Publish', test, e)}>
                <UploadOutlined  className='text-md'/>
                <div className='text-xs color-black m-l-10'>Publish</div>
            </div>
            :
            null
        }
        {type=="published"
        ?
            userType=="admin"
            ?
            <div className='r-ac cursor-pointer m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'Unpublish', test, e)}>
                <StopOutlined  className='text-md'/>
                <div className='text-xs color-black m-l-10'>Unpublish</div>
            </div>
            :
            null
        :null
        }
      {
        //   ((userType=='teacher')||(userType=='dataOperator'))
        //   ?
          <div className='r-ac cursor-pointer m-t-20 m-b-10' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'Clone', test, e)}>
            {cloneLoader?<LoadingOutlined />:<AiFillCopy  className='text-md'/>}
            <div className='text-xs color-black m-l-10'>Clone</div>
          </div>
        //   :
        //   null
      }
      {
        ((type=="draft")||(userType=="admin"&&type!=="publishedResult"&&type!=="notPublishedResult"&&type!=="adminlive")||((userType=="teacher")&&((type=="feedback")||(type=="ready"))))
        ?
            <div className='r-ac cursor-pointer m-t-20' onClick={(e)=>handleButtonClick(test.id?test.id:test.testId, 'Delete', test, e)}>
                <AiFillDelete  className='text-md'/>
                <div className='text-xs color-black m-l-10'>Delete</div>
            </div>
        :
        null
      }

    </Menu>
        </>

    )
}

const mapStateToProps = state => {
    const {} = state.practiseTest;
      const {user} = state.session;
    return {user};
  };
  
  const mapDispatchToProps = dispatch => ({
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(CustomMenu);

