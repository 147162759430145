import {
    ExclamationCircleOutlined,
    LeftOutlined,
    LoadingOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { Button, PageHeader, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Color } from "services";
import { connect } from "react-redux";
import { getSolutions, updateCurrentQ } from "actions/marketTest";
import PageDataLoader from "components/pageDataLoader";
import { MarketTestPalette } from "./marketTestPalette";
import { setCurrentIndex } from "actions/studentTest";
import ViewImgModal from "pages/studentTest/modals/viewImgModal";
import { QuestionTypes } from "./questionTypes";



const answerStatus = [
    {
        title: "Correct",
        key: "correct",
        background: "#28DF99",
        border: `1px solid ${Color.midGrey}`,
        color: "white",
        gradient: false,
    },
    {
        title: "Incorrect",
        key: "incorrect",
        background: Color.wrong,
        border: "none",
        color: "white",
        gradient: false,
    },
    // {
    //     title: "Partially correct",
    //     key: "partial",
    //     background: "linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)",
    //     border: "none",
    //     color: "white",
    //     gradient: false,
    // },
    {
        title: "Unattempted",
        key: "unattempted",
        background: "#AEAEAE",
        border: "none",
        color: "black",
        gradient: false,
    },
    {
        title: "Unseen",
        key: "unseen",
        background: "#FFFFFF",
        border: "1px solid black",
        color: "black",
        gradient: false,
    },
];


const legand = (solutionCounts) => {
    return (
        <div className="legendPalette">
            {answerStatus?.map((item, index) => (
                !item?.gradient && (
                    <div className="answerTypeContainer" key={index.toString()}>
                        <div
                            className="countBadge r-c-c"
                            style={{
                                background: item.background,
                                border: item.border,
                                color: item.color,
                                borderRadius: "50%",
                                maxWidth: "50%",
                            }}
                        >
                            <div>
                                {solutionCounts?.[item.key] ?? 0}
                            </div>
                        </div>
                        <div style={{ maxWidth: "50%", fontSize: "1vw" }}>
                            {" "}
                            {item.title}
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};




function SolutionScreen({
    getSolutions,
    palateData,
    currentIndex,
    currentQ,
    updateCurrentQ,
    setCurrentIndex,
    testResult,selectedTest
}) {

    const [pageLoader, togglePageLoader] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [showImgModal, toggleImageModal] = useState(false);

    const history = useHistory();

    const totalQuestions = palateData
        ?.at(-1)
        ?.sections?.at(-1)
        ?.questions?.at(-1)?.index;


    useEffect(() => {
        togglePageLoader(true)
        getSolutions(() => togglePageLoader(false))
    }, []);

    useEffect(() => {
        palateData?.forEach((subject) => {
            subject.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    if (question.index == currentIndex) updateCurrentQ(question)
                })
            })
        })

    }, [currentIndex]);



    // const formatTime = () => {
    //     return [
    //         Math.floor(currentQ.duration / 60).toLocaleString("en-US", {
    //             minimumIntegerDigits: 2,
    //             useGrouping: false,
    //         }),
    //         (currentQ.duration % 60).toLocaleString("en-US", {
    //             minimumIntegerDigits: 2,
    //             useGrouping: false,
    //         }),
    //     ];
    // };


    const handleNavigateClick = (index) => {
        if (index > totalQuestions) index = totalQuestions;
        if (index < 1) index = 1;

        setCurrentIndex(index)
    };

console.log("palateData",selectedTest);
    return (
        <div className="TestSolutions" style={{ height: "100%" }}>
            <PageHeader
                style={{ backgroundColor: "#F6F4FE" }}
                title={<span> {selectedTest?.title} </span>}
                onBack={() => history.goBack()}
                extra={
                    <div className="leftInnerNav">
                        <div className="innerNavBtn">
                            {palateData?.map((item, index) => (
                                <Button
                                    key={index}
                                    onClick={() => { handleNavigateClick(item.startIndex) }}
                                    style={
                                        (item?.startIndex <= currentIndex <= item?.endIndex)
                                            ? {
                                                backgroundColor: "#E6E6E6",
                                                color: "#191919",
                                                border: "none",
                                            }
                                            : null
                                    }
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {item?.subject?.name}
                                    </div>
                                </Button>
                            ))}
                        </div>
                    </div>
                }
            />

            <div className="testContainer">
                <div className="lqPanel">
                    <div
                        style={{
                            height: "100%",
                            borderRight: `1px solid ${Color.midGrey}`,
                        }}
                    >
                        <div className="questionContainer">
                            <div className="questionHeader">
                                <span className="m-t-20">{currentIndex}</span>
                                <div className="leftText r-c-sa">
                                    <span style={{ color: "#AEAEAE" }}>
                                        {" "} Marks obtained:
                                        <span style={
                                            currentQ?.marksObtained > 0
                                                ? { color: "green", fontWeight: 750 }
                                                : currentQ?.marksObtained < 0
                                                    ? { color: "green", fontWeight: 750 }
                                                    : null
                                        }>
                                            {" "}{currentQ?.marksObtained ?? 0}{" "}
                                        </span>
                                    </span>

                                    <span>
                                        {" "}
                                        {/* {formatTime()[0] != "NaN" ? formatTime()[0] : "0"} mins :{" "}
                                        {formatTime()[1] != "NaN" ? formatTime()[1] : "0"} secs{" "} */}
                                    </span>
                                </div>
                            </div>

                            <div className="qaSection">
                                <QuestionTypes
                                    currentIndex={currentIndex}
                                    toggleImageModal={toggleImageModal}
                                    setCurrentImg={setCurrentImg}
                                    currentQ={currentQ}
                                    isResult={true}
                                />
                            </div>
                        </div>

                        <div className="actionContainer">
                            <div className="footerBtns">
                                <div className="qNavBtn r-c-sb" style={{ width: "100%" }}>
                                    <Button
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                        disabled={currentIndex <= 1}
                                        onClick={() => { handleNavigateClick(currentIndex - 1) }}
                                    >
                                        <LeftOutlined />
                                        PREVIOUS
                                    </Button>
                                    <Button
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                        disabled={currentIndex >= totalQuestions}
                                        onClick={() => { handleNavigateClick(currentIndex + 1) }}
                                    >
                                        NEXT
                                        <RightOutlined />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rqPanel">
                    <div style={{ height: "100%" }}>
                        {legand(testResult?.myTest)}
                        <MarketTestPalette
                            palateData={palateData}
                            currentQIndex={currentIndex}
                            onQuestionSelect={handleNavigateClick}
                            isResult={true}
                        />
                    </div>
                </div>
            </div>

            <ViewImgModal
                visible={showImgModal}
                imgPath={currentImg}
                onCancel={() => toggleImageModal(false)}
            />
            <PageDataLoader visible={pageLoader} />

        </div>
    );
}

const mapStateToProps = (state) => {
    const { palateData, currentIndex, currentQ, testResult } = state.marketTest;
    const {selectedTest} = state.studentTest;
    return { palateData, currentIndex, currentQ, testResult,selectedTest };
};

const mapDispatchToProps = (dispatch) => ({
    getSolutions: (loaderCallback) => dispatch(getSolutions(loaderCallback)),
    updateCurrentQ: (data) => dispatch(updateCurrentQ(data)),
    setCurrentIndex: (data) => dispatch(setCurrentIndex(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SolutionScreen);
