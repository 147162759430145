import React from "react";
import {
  updateAssignmentSearch,
  searchByText,
  getStudentAssignmentDetail
} from "actions/assignment";
import { IoIosArrowRoundBack, IoMdSearch, IoIosClose } from "react-icons/io";
import { SyncOutlined } from "@ant-design/icons";
import { AssignmentCard } from "components";
import Modal from "antd/lib/modal";
import { Color } from "services";
import { connect } from "react-redux";
import "./styles.css";
import "../../design/layout.css";
import "../../design/assignment.css";

class SearchPage extends React.Component {
  handleAssignmentSearch(searchValue) {
    const { searchByText } = this.props;
    searchByText(searchValue);
  }

  handleGoBack() {
    const { updateAssignmentSearch, history } = this.props;
    updateAssignmentSearch("");
    history.push("/web-assignment");
  }

  render() {
    const {
      history,
      searchValue,
      assignmentLoader,
      studentAssignmentList,
      updateAssignmentSearch,
      getStudentAssignmentDetail
    } = this.props;
    return (
      <div className="NutinFont">
        <div className="r-c-sb p-l-10 p-r-10 p-t-15 p-b-15">
          <IoIosArrowRoundBack
            onClick={() => this.handleGoBack()}
            className="color-black text-mdl"
          />
          <div
            className="greyBackTest flex-1 searchUi m-r-10 m-l-10"
            style={{ alignItems: "center" }}
          >
            <input
              autoFocus
              value={searchValue}
              onChange={event => {
                updateAssignmentSearch(event.target.value);
              }}
              type="text"
              className="border-0 greyBackTest full-width color-black"
            />
            <div onClick={() => updateAssignmentSearch("")}>
              <IoIosClose
                className="color-purple"
                style={{ fontSize: "1.7em" }}
              />
            </div>
          </div>
          <div onClick={() => this.handleAssignmentSearch(searchValue)}>
            <IoMdSearch
              className="color-purple"
              style={{ fontSize: "1.7em" }}
            />
          </div>
        </div>
        <div className="p-10">
          {studentAssignmentList.length &&
            studentAssignmentList.map((data, index) => (
              <AssignmentCard
                subject={data.sub}
                topic={data.title}
                status={data.remainder}
                dueDate={data.deadlineDate}
                onPress={() =>
                  getStudentAssignmentDetail(data.id, data, history)
                }
                completion={data.attemptedQuestions / data.totalQuestions}
              />
            ))}
        </div>
        <Modal
          visible={assignmentLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>

            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
            <div className="text-xmd m-t-10">Searching assignment ...</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    searchValue,
    studentAssignmentList,
    assignmentLoader
  } = state.assignment;
  return {
    assignmentLoader,
    searchValue,
    studentAssignmentList
  };
};

const mapDispatchToProps = dispatch => ({
  updateAssignmentSearch: value => dispatch(updateAssignmentSearch(value)),
  searchByText: text => dispatch(searchByText(text)),
  getStudentAssignmentDetail: (id, data, history) =>
    dispatch(getStudentAssignmentDetail(id, data, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
