import { Heading, SubHeading } from 'components/Typography';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { Button } from 'components/Button';
import { EditOutlined } from '@ant-design/icons';
import { Checkbox, Col, Row, Select, Tooltip } from 'antd';
import UpdateGeofenceModal from './updateGeofenceModal';
import { connect } from 'react-redux';
import { getHolidays, saveHolidays } from 'actions/profileNew';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PageDataLoader from 'components/pageDataLoader';
const GeofenceIcon = require("../../Assets/ic-geo-fence.svg").default;

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const currentYear = new Date().getFullYear();
const weedays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]


const Holidays = ({ getHolidays, user, setPageLoader, holidays, saveHolidays, specificUserId }) => {
    const currentYear = new Date().getFullYear();
    const [editGeofenceModal, setEditGeofenceModal] = useState(false);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [editable, setEditable] = useState(false);
    const [holidayList, setHolidayList] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);

    const location = useLocation();
    const otherYear = selectedYear === currentYear ? currentYear - 1 : currentYear;

    useEffect(() => {
        setPageLoader(true)
        const params = {
            edit: true,
            userId: location?.pathname == "/user-management/profile" ? specificUserId : user.id,
            year: selectedYear
        }
        getHolidays(params, () => setPageLoader(false));
    }, [selectedYear]);

    useEffect(() => {
        if (holidays) {
            setHolidayList(holidays.holidayList ? [...holidays.holidayList] : [])
        }

    }, [holidays])

    const handleSaveClick = () => {
        setButtonLoader(true)
        const dates = []
        holidayList.forEach(month =>
            dates.push(...month.data.filter(holiday => holiday.selected === true).map(item => moment(item.date).format("YYYY-MM-DD")))
        )

        const params = {
            userId: location?.pathname == "/user-management/profile" ? specificUserId : user.id,
            edit: true,
            year: selectedYear,
            dates: dates
        }
        console.log(params);
        saveHolidays(params, () => setButtonLoader(false), () => { setPageLoader(false); setEditable(false) })
    }

    const handleHolidayCheck = (e, dateIndex, monthIndex) => {
        holidayList[monthIndex].data[dateIndex].selected = e.target.checked;
        setHolidayList([...holidayList])
    }


    console.log({ holidays });
    return (
        <div>
            <div className='r-jsb'>
                <div>
                    <Heading>Holidays & Observances</Heading>
                    {editable ? <div style={{ fontSize: 12, color: 'red' }}>NOTE: Holidays of past dates cannot be edited.</div> : null}
                </div>
                {editable ?
                    <div className='r-ac'>
                        <Button type="transparent" style={{ color: "#636363", fontWeight: 600 }} onClick={() => setEditable(false)}>CANCEL</Button>
                        <Button type="link" style={{ fontSize: 14 }} onClick={handleSaveClick} loading={buttonLoader}>UPDATE</Button>
                    </div>
                    :
                    <div className='r-ac'>
                        {user?.privilage?.includes('SuperAdmin') ?
                            <Button /* disabled={!holidays?.currentLocation} */ type="link" style={{ marginRight: 20, fontSize: 14 }} icon={<EditOutlined />} onClick={() => setEditable(true)} >EDIT</Button>
                            : null}
                        <Select value={selectedYear}  onChange={val => setSelectedYear(val)}>
                        <Select.Option value={selectedYear}>{selectedYear}</Select.Option>
                        <Select.Option value={otherYear}>{otherYear}</Select.Option>
                        </Select>
                    </div>
                }
            </div>
            {editable ?
                <div>
                    {holidayList.map((item, index) => (
                        <div key={index} style={{ width: "100%" }}>
                            <div style={{ fontSize: 16, fontWeight: 500 }} className="m-b-10 m-t-20 ">{item.month}</div>
                            <Row>
                                {item.data.map((day, i) => (
                                    <Col span={12}>
                                        <Row className='p-5'>
                                            <Col span={1}><Checkbox checked={day.selected} onChange={(e) => handleHolidayCheck(e, i, index)} disabled={moment(day.date) < moment()} /></Col>
                                            <Col span={2} offset={1} style={{ fontWeight: 500, flex: 1 }} >{moment(day.date).format("DD")}</Col>
                                            <Col span={3} style={{ color: "#AEAEAE", flex: 1 }} >{weedays[moment(day.date).day()]}</Col>
                                            <Col span={16} style={{ flex: 11 }}>{day.title}</Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </div>
                :
                <div>
                    <div className="r-jsb" style={{ alignItems: "center" }}>
                        <div className='r-ac'>
                            <img src={GeofenceIcon} alt="" />
                            <div className='m-l-10'>
                                <div style={{ fontSize: 16, fontWeight: 500 }}>{holidays?.currentLocation?.title}</div>
                                <div style={{ color: "#636363" }}>{holidays?.currentLocation?.address}</div>
                            </div>
                        </div>
                        {user?.privilage?.includes('SuperAdmin') ? <Button type="link" style={{ fontSize: 14 }} onClick={() => setEditGeofenceModal(true)}>CHANGE</Button> : null}
                    </div>
                    <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginTop: 20 }} />
                    <Row>
                    {holidays?.holidayList && months?.map((monthName, monthIndex) => {
                            const OnHolidayHover = ({ e }) => {
                                let day = moment(e.date).format('DD-MM-YYYY');

                                // filter working for all cases.
                                const holidayDetails = holidays?.holidayList?.filter(item => item?.month == monthName)[0]?.data?.filter(item => moment(item?.date).format('DD-MM-YYYY') === day)

                                if (holidayDetails?.length && holidayDetails[0]?.selected) {
                          
                                    return (<Tooltip title={holidayDetails[0]?.title}>
                                        <div style={{ color: "#FF414D", fontWeight: 600, height: 30, width: 30, borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", lineHeight: 0.2 }}>{moment(e.date).format('D')}</div>
                                    </Tooltip>)
                                } else if ((!holidays?.edit && holidayDetails && holidayDetails[0])) {
                               
                                    return (<Tooltip title={holidayDetails && holidayDetails[0]?.title}>
                                        <div style={{ color: "#FF414D", fontWeight: 600, height: 30, width: 30, borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", lineHeight: 0.2 }}>{moment(e.date).format('D')}</div>
                                    </Tooltip>)
                                } else {
              
                                    return <div style={{ color: 'black' }}>{moment(e.date).format('D')}</div>
                                }
                            }
                            const date = new Date(selectedYear, monthIndex, 1);
                            return (
                                <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} key={monthIndex} className="month-calendar">
                                    <div style={{ width: "90%" }} className="m-t-10">
                                        <div className="r-c-c" style={{ background: "#F6F4FE", padding: "5px 0", fontSize: 16, color: "#594099", fontWeight: 500 }}>
                                            {date.toLocaleString('default', { month: 'long' })}
                                        </div>
                                        <Calendar
                                            className="profile-holidays"
                                            value={date}
                                            tileContent={(e) => <OnHolidayHover e={e} />}
                                            tileClassName="custom-today-class"
                                            showNavigation={false}
                                            showNeighboringMonth={false}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            }
            <UpdateGeofenceModal
                visible={editGeofenceModal}
                onClose={() => setEditGeofenceModal(false)}
                pagecallback={setPageLoader}
                selectedYear={selectedYear}
            />
            <PageDataLoader visible={buttonLoader} />
        </div>

    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { holidays } = state.profileNew;
    const { specificUserId } = state.userManagementV2;
    return {
        user, holidays, specificUserId
    }
}

const mapDispatchToProps = (dispatch) => ({
    getHolidays: (params, callback) => dispatch(getHolidays(params, callback)),
    saveHolidays: (params, callback, pagecallback) => dispatch(saveHolidays(params, callback, pagecallback))
})

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
