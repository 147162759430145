import { ArrowLeftOutlined } from "@ant-design/icons";
import { setEditDocLink } from "actions/profile";
import { PageHeader } from "antd";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IMG_URL } from "../../env.json";

function ProfileViewPdf({ documentLink,setEditDocLink }) {
  const history = useHistory();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <PageHeader
        title={
          <div
            className="text-md"
            style={{ fontWeight: "bold", height: "45px", marginTop: "10px" }}
          >
            Invoice
          </div>
        }
        onBack={() => {
          history?.push({
            pathname: '/profile',
            state: {
              key: 3
            }
          });
          setEditDocLink(null)
        }}
        backIcon={<ArrowLeftOutlined />}
      />
      <div style={{width:'100%',height:'100%'}}>
      <iframe style={{width:'100%',height:'100%'}} src={`${IMG_URL}/${documentLink}#toolbar=0`} title="View Pdf"></iframe>
      </div>
    </div>
  );
}

// export default ProfileViewPdf;
const mapStateToProps = (state) => {
  const { documentLink } = state.profile;
  return { documentLink };
};

const mapDispatchToProps = (dispatch) => ({
  // setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  // getStats: (m) => dispatch(getStats(m)),
  setEditDocLink: (file) => dispatch(setEditDocLink(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileViewPdf);
