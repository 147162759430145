import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import { Button } from 'components/Button';
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import closeWrong from "../../../Assets/aulasMarket/CloseCircle.svg"
import attempted from "../../../Assets/aulasMarket/ic-attempted.svg"
import { getQuizSolutions, setQuizFlowCount } from 'actions/studentMarketPlace'
import ReactHtmlParser from "react-html-parser";
import TeX from "@matejmazur/react-katex";


export const HtmlComponent = ({ item, toggleImageModal, setCurrentImg }) => {
    return ReactHtmlParser(item, {
        transform: (node) => {
            if (
                node.type === "tag" &&
                node.name === "span" &&
                node.attribs["data-value"]
            ) {
                return <TeX math={`${node.attribs["data-value"]}`} />;
            }
            if (node.type === "tag" && node.name === "img") {
                return (
                    <img
                        alt="question"
                        style={{ maxHeight: "160px", maxWidth: "160px" }}
                        src={node.attribs.src}
                        className="m-t-10 m-r-10 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleImageModal(true);
                            setCurrentImg(node.attribs.src);
                        }}
                    />
                );
            }
        },
    });
};

function ViewQuizSolutions({ quizResults, quizItems, setQuizFlowCount, studyKey, quizSolution, paramAttemptQuiz, getQuizSolutions }) {
    const [step, setStep] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [pageLoader, togglePageLoader] = useState(false);
    const [evaluation, setEvalution] = useState(null);

    useEffect(() => {
        togglePageLoader(true);
        getQuizSolutions(paramAttemptQuiz, () => {
            togglePageLoader(false)
        })
    }, [])

    const handleNext = () => {
        if (step < quizSolution?.length - 1) {
            setStep(step + 1);
            setCurrentIndex((prevIndex) => (prevIndex + 1 < quizSolution.length ? prevIndex + 1 : prevIndex));
        }
    };

    const handlePrevious = () => {
        if (step > 0) {
            console.log('inside step > 0')
            setStep((prevStep) => prevStep - 1);
            setCurrentIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
        } else {
            // If step is already at the beginning, set quizFlowCount to 2
            console.log('inside step < 0')
            setQuizFlowCount(0);
        }
    };




    const Questions = ({ dataList, index, selectedOption, onOptionClick }) => {

        const optionsArray = dataList?.question?.questionContent?.options
        const trueSelectedOption = dataList?.attempt?.answer
        const trueCorrectOption = dataList?.question?.type == 'MSQ' ? dataList?.question?.answerContent?.answers : dataList?.question?.answerContent?.answer
        console.log("Abcdef", optionsArray, trueSelectedOption, trueCorrectOption);

        setEvalution(dataList?.evaluation)
        const evaluationStyle = (evaluation) => {
            switch (evaluation) {
                case 'Incorrect':
                    return { color: '#FF414D' };
                case 'Correct':
                    return { color: '#28DF99' };
                case 'None':
                    return { color: '#636363', position: 'relative' };
                default:
                    return {};
            }
        };


        // console.log("quizSolution2", dataList);
        console.log("quizSolution1", trueSelectedOption, trueCorrectOption);

        return (
            <div className={`${studyKey ? "quiz-question-study-container" : "quiz-question-container"}`} style={{ height: 370, overflowX: 'hidden' }}>
                <div className='r-c-fe m-r-15 font-bold' style={{ fontSize: "12px" }}>
                    <div style={evaluationStyle(dataList?.evaluation)}>
                        {dataList?.evaluation === 'Incorrect'
                            ? '0 Marks'
                            : dataList?.evaluation === 'Correct'
                                ? `${dataList?.question?.positiveMarks} Marks`
                                : dataList?.evaluation === 'None'
                                    ? <div><span style={{
                                        width: "10px", height: "10px", borderRadius: "50%",
                                        display: "inline-block",
                                        margin: "0 10px",
                                        backgroundColor: "#AEAEAE",
                                        opacity: 1
                                    }}>
                                    </span> Unattempted</div>
                                    : null}
                    </div>
                </div>
                <div className='' style={{ fontSize: "13px", display: "flex", justifyContent: "flex-start" }}>
                    <span className='m-r-10'>{[index + 1]}.</span><HtmlComponent
                        item={dataList?.question?.questionContent?.statement}
                        toggleImageModal={() => { }}
                        setCurrentImg={() => { }}
                    />
                </div>

                <div className='scroll-bar-universal' style={{height:"260px"}}>
                    {dataList?.question?.type == 'T/F' ?
                        (<>
                            <div className={`r-c-fs p-15 ${studyKey ? 'option-1' : 'option'} m-l-20 ${trueSelectedOption == true && trueCorrectOption == true ? "selectedOp" : trueCorrectOption == true ? "selectedOp" : trueSelectedOption == false || trueSelectedOption == undefined ? '' : "wrongOp"}`}>
                                True
                                <div className='r-c-fe' style={{ width: "100%" }}>
                                    {trueSelectedOption == true && trueCorrectOption == true ? <>
                                        <div><img src={attempted} />Your answer</div>
                                    </> : <>
                                        {trueCorrectOption == true ?
                                            <div><img src={attempted} />Correct answer</div>
                                            :
                                            trueSelectedOption == false || trueSelectedOption == undefined ? <></> : <div><img src={closeWrong} />Your answer</div>
                                        }
                                    </>}
                                </div>
                            </div>
                            <div className={`r-c-fs p-15 ${studyKey ? 'option-1' : 'option'} m-l-20 ${trueSelectedOption == false && trueCorrectOption == false ? "selectedOp" : trueCorrectOption == false ? "selectedOp" : trueSelectedOption == true || trueSelectedOption == undefined ? '' : "wrongOp"}`}>
                                False
                                <div className='r-c-fe' style={{ width: "100%" }}>
                                    {trueSelectedOption == false && trueCorrectOption == false ? <>
                                        <div><img src={attempted} />Your answer</div>
                                    </> : <>
                                        {trueCorrectOption == false ?
                                            <div><img src={attempted} />Correct answer</div>
                                            :
                                            trueSelectedOption == true || trueSelectedOption == undefined ? <></> : <div><img src={closeWrong} />Your answer</div>

                                        }
                                    </>}
                                </div>
                            </div>
                        </>) :

                        dataList?.question?.type == 'MSQ' ?
                            <div  className='scroll-bar-universal' style={{height:"260px"}}>
                                {optionsArray?.map((optionItem, index) => {
                                    return (
                                        <div style={{ justifyContent: "space-between" }} className={`r-c-fs p-15 ${studyKey ? 'option-1' : 'option'} m-l-20 ${(trueSelectedOption?.includes(index) && trueCorrectOption?.includes(index) && 'selectedOp')} 
                                    ${trueSelectedOption?.includes(index) && !trueCorrectOption?.includes(index) && 'wrongOp'} ${!trueSelectedOption?.includes(index) && trueCorrectOption?.includes(index) && 'selectedOp'}`

                                        }>
                                            <HtmlComponent
                                                item={optionItem?.value}
                                                toggleImageModal={() => { }} setCurrentImg={() => { }} />


                                            {trueSelectedOption?.includes(index) && trueCorrectOption?.includes(index) &&
                                                <div ><img src={attempted} />Your answer</div>
                                            }

                                            {trueSelectedOption?.includes(index) && !trueCorrectOption?.includes(index) &&
                                                <div>
                                                    <div><img src={closeWrong} />Your answer</div>
                                                </div>
                                            }

                                            {!trueSelectedOption?.includes(index) && trueCorrectOption?.includes(index) &&
                                                <div><img src={attempted} />Correct answer</div>
                                            }

                                            {!trueSelectedOption?.includes(index) && !trueCorrectOption?.includes(index) &&
                                                <div></div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <div  className='scroll-bar-universal' style={{height:"260px"}}>
                                {optionsArray?.map((optionItem, index) => {
                                    console.log("correcccc",trueCorrectOption,index,trueSelectedOption,index,trueSelectedOption == index && !trueCorrectOption == index)
                                    return (
                                        <div style={{ justifyContent: "space-between" }} className={`r-c-fs p-15 ${studyKey ? 'option-1' : 'option'} m-l-20 
                                    ${trueSelectedOption == index && trueCorrectOption == index && 'selectedOp'}
                                    ${trueSelectedOption == index && trueCorrectOption != index && 'wrongOp'}
                                    ${trueSelectedOption != index && trueCorrectOption == index && 'selectedOP'}
                                    `}>
                                            <HtmlComponent
                                                item={optionItem?.value}
                                                toggleImageModal={() => { }} setCurrentImg={() => { }} />
                                            {trueSelectedOption == index && trueCorrectOption == index &&
                                                <div><img src={attempted} />Your answer</div>
                                            }
                                            {trueSelectedOption == index && trueCorrectOption != index &&
                                                <div>
                                                    <div><img src={closeWrong} />Your answer</div>
                                                </div>
                                            }
                                            {trueSelectedOption != index && trueCorrectOption == index &&
                                                <div><img src={attempted} />Correct answer</div>
                                            }
                                            {!trueSelectedOption == index && trueCorrectOption != index &&
                                                <div></div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={`${studyKey ? 'study-quiz-container' : 'quiz-container'} `}>
            <div style={{ margin: '10px 10px' }}>
                <div className='font-bold text-sm'>{quizItems?.name}</div>
                <div className='r-c-sa'>
                    {quizSolution?.map((item, index) => (
                        <div key={index} style={{ width: '100%', margin: '10px 0px' }}>
                            <div className='steps' style={{ backgroundColor: step >= index ? '#594099' : '#E6E6E6' }}>{/* Add your content for each step here */}
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    {quizSolution?.length > 0 && (
                        <Questions dataList={quizSolution[currentIndex]} index={currentIndex} />
                    )}
                </div>
            </div>

            <div  style={{display:"flex",justifyContent:"space-between",padding:10,position: "relative",top: "-70px"}}>
                <div  onClick={handlePrevious}>
                    <Button disabled={currentIndex === 0} className='btns-prev'>PREVIOUS</Button>
                </div>

                {step < quizSolution?.length - 1 ? (
                    <div >
                        <Button style={{ backgroundColor: "#594099", color: "#FFFFFF", border: "1px solid #594099" }} onClick={handleNext}>NEXT</Button>
                    </div>
                ) : (
                    <div >
                        <Button style={{ backgroundColor: "#594099", color: "#FFFFFF", border: "1px solid #594099" }} disabled >NEXT</Button>
                    </div>
                )}
            </div>

            <PageDataLoader visible={pageLoader} />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { myVideoResources, quizResults, quizSolution, paramAttemptQuiz } = state.studentMarketPlace
    return {
        myVideoResources, quizResults, quizSolution, paramAttemptQuiz
    }
};

const mapDispatchToProps = (dispatch) => ({
    getQuizSolutions: (paramAttemptQuiz, callback) => dispatch(getQuizSolutions(paramAttemptQuiz, callback)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuizSolutions)
