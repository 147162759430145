import {
	UPDATE_PALATE_DATA,
	UPDATE_COUNT_DATA,
	UPDATE_CURRENT_Q_ID,
	UPDATE_PREVIOUS_Q_ID,
	SET_CURRENT_INDEX,
	UPDATE_CURRENT_Q,
	UPDATE_Q_PARAM,
	UPDATE_ANSWER_OBJ,
	UPDATE_TEST_RESULT,
	UPDATE_INDIVIDUAL_TEST,
	UPDATE_CREATE_PAYLOAD,
	SET_CREATE_PAYLOAD,
	STORE_MARKING_SCHEME_DATA,
	STORE_ALL_SUBJECTS,
	INDIVIDUAL_TEST_ID,
	STORE_ONLINE_TEST_SERIES_ID,
	UPDATE_LIST_VIEW_DATA,
	CLEAR_QUESTIONS,
	SET_OWNED_TEST_SERIES
	
} from '../actions/marketTest';


const initialState = {
	qCounts: {
		attempted: 0,
		attemptedAndBookmarked: 0,
		bookmarked: 0,
		unanswered: 0,
		unseen: 0,
	},
	palateData: [],
	currentQId: null,
	previousQId: null,
	currentIndex: 1,
	currentQ: null,
	answerObj: null,
	testResult: null,
	individualTest: null,
	markingSchemes: [],
	allSubjects: [],
	individualTestId: null,
	onlineTestSeriesId: null,
	listViewData: null,
	referDuration: 0,
	currentDuration: 0,
	storeOwnedData:''
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PALATE_DATA:
			return {
				...state,
				palateData: action.data
			};
		case UPDATE_COUNT_DATA:
			return {
				...state,
				qCounts: { ...state.qCounts, ...action.countData },
			};
		case UPDATE_CURRENT_Q_ID:
			return {
				...state,
				currentQId: action.currentQId
			}
		case UPDATE_PREVIOUS_Q_ID:
			return {
				...state,
				previousQId: action.previousQId
			}
		case SET_CURRENT_INDEX:
			return {
				...state,
				currentIndex: action.index,
			};
		case UPDATE_CURRENT_Q:
			return {
				...state,
				currentQ: action.data,
			};
		case UPDATE_ANSWER_OBJ:
			return {
				...state,
				answerObj: action.data,
			};
		case UPDATE_Q_PARAM:
			return {
				...state,
				currentQ: { ...state.currentQ, ...action.qParam },
			};
		case UPDATE_CREATE_PAYLOAD:
			return {
				...state,
				createPayload: { ...state.createPayload, ...action.pl },
			};
		case SET_CREATE_PAYLOAD:
			return {
				...state,
				createPayload: action.data,
			};
		case UPDATE_TEST_RESULT:
			return {
				...state,
				testResult: action.data,
			};
		case UPDATE_INDIVIDUAL_TEST:
			return {
				...state,
				individualTest: action.data,
			};
		case STORE_MARKING_SCHEME_DATA:
			return {
				...state,
				markingSchemes: action.data,
			};
		case STORE_ALL_SUBJECTS:
			return {
				...state,
				allSubjects: action.data,
			};
		case INDIVIDUAL_TEST_ID:
			return {
				...state,
				individualTestId: action.data,
			};
		case STORE_ONLINE_TEST_SERIES_ID:
			return {
				...state,
				onlineTestSeriesId: action.data,
			};
		case UPDATE_LIST_VIEW_DATA:
			return {
				...state,
				listViewData: action.data,
			};
		case SET_OWNED_TEST_SERIES : 
		return{
			...state,
			storeOwnedData:action.data
		}

		case CLEAR_QUESTIONS:
      return{
        ...state,
        currentQ: null,
        referDuration: 0,
        currentDuration: 0,
      }

		default:
			return state;
	}
};

export default reducer;
