import { ArrowLeftOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { getAllPaymentHistory, storePaymentRequestsParams, setFromPaymentHistory, storePaymentHistory, storePaymentReqModalData } from 'actions/aulasWallet';
import { InputSearch } from 'components/customInputs';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PaymentReqListView from './paymentReqListView';
import PaymentRequestsCard from './paymentRequests';
import PageDataLoader from 'components/pageDataLoader';
import { Pagination, Select } from 'antd';
import { NodataCard } from 'pages/Test';
import EmailIdModal from './modal/emailIdModal';

const bodyStyle = {
    background: "#FAFAFA",
    margin: -20,
    padding: 20,
    overflow: "hidden",
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
};

const { Option } = Select;

function PaymentRequestsPage({ getAllPaymentHistory, storePaymentRequestsParams, paymentHistoryContainer, paymentRequestsObj, setFromPaymentHistory, storePaymentHistory, storePaymentReqModalData }) {
    const history = useHistory();
    const [view, setView] = useState(2);
    const [pageLoader, togglePageLoader] = useState(false);
    const [isEmailIdModalVisible, setIsEmailIdModalVisible] = useState(false);

    useEffect(() => {
        storePaymentHistory([])
        setFromPaymentHistory(false)
        storePaymentRequestsParams("issueDate", null);
        storePaymentRequestsParams("dueDate", null);
        storePaymentRequestsParams("page", 1);
        storePaymentRequestsParams("overdueStatus", null);
        storePaymentRequestsParams("limit", 10);
        togglePageLoader(true)
        getAllPaymentHistory(() => { togglePageLoader(false) })
    }, [view])
    const handlePageination = (value) => {
        storePaymentRequestsParams("page", value);
        togglePageLoader(true)
        getAllPaymentHistory(() => { togglePageLoader(false) })
    };
    return (
        <div style={bodyStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "700", display: "flex", flexDirection: "row", marginTop: "5px" }}>
                        <ArrowLeftOutlined
                            style={{ marginTop: "10px", fontSize: "22px" }}
                            onClick={() => {
                                history.goBack();
                            }} />
                        <div style={{ marginLeft: "15px", fontSize: "24px" }}>Payment Requests <span style={{ color: "#aeaeae" }}> ({paymentHistoryContainer?.meta?.totalItems})</span></div>
                    </div>
                </div>
                <div className="display-flex">
                    <div style={{ marginRight: "20px", marginTop: "10px", color: "#636363", backgroundColor: "FAFAFA", boxShadow: view == 1 ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "", height: 45, width: 105, padding: 10, borderRadius: 10 }}>
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => setView(1)}>
                            <UnorderedListOutlined style={{ fontSize: 14, marginRight: 5, marginTop: 6 }} />
                            <div style={{ fontSize: 16 }}>List view</div>
                        </div>
                    </div>
                    <div style={{ marginRight: 30, marginTop: "10px", color: "#aeaeae", backgroundColor: "FAFAFA", boxShadow: view == 2 ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "", height: 45, width: 110, padding: 10, borderRadius: 10 }}>
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => {
                            setView(2);
                            togglePageLoader(true)
                            getAllPaymentHistory(() => { togglePageLoader(false) })
                        }}>
                            <img style={{ color: "#aeaeae", width: 18, height: 18, marginTop: 4, marginRight: 4 }} src={require("../../Assets/aulasWallet/grid.svg").default} alt='' />
                            <div style={{ fontSize: 16 }}>Grid view</div>
                        </div>
                    </div>
                </div>
            </div>
            {view == 2 ?
                <div style={{ overflowX: "scroll", height: "83vh" }}>
                    {paymentHistoryContainer?.transactions &&
                        paymentHistoryContainer?.transactions?.length != 0 ?
                        <div className='m-t-20 m-l-10' style={{ display: "flex", flexWrap: "wrap" }}>
                            {paymentHistoryContainer?.transactions?.map((item, index) => (
                                <PaymentRequestsCard
                                    item={item}
                                    setIsEmailIdModalVisible={setIsEmailIdModalVisible}
                                    storePaymentReqModalData={storePaymentReqModalData} />
                            ))}
                        </div>
                        :
                        <div className='r-c-c-c' style={{ height: "70%" }}>
                            <NodataCard />
                        </div>
                    }

                </div>
                :
                view == 1 ?
                    <PaymentReqListView
                        item={paymentHistoryContainer?.transactions}
                        storePaymentRequestsParams={storePaymentRequestsParams}
                        paymentRequestsObj={paymentRequestsObj}
                        togglePageLoader={togglePageLoader}
                        getAllPaymentHistory={getAllPaymentHistory}
                        setIsEmailIdModalVisible={setIsEmailIdModalVisible} />
                    :
                    <></>
            }
            <div style={{ position: "absolute", right: "20px", bottom: 10, }}>
                <Pagination
                    size="small"
                    current={paymentHistoryContainer?.meta?.currentPage}
                    defaultCurrent={1}
                    total={paymentHistoryContainer?.meta?.totalPages * 10}
                    onChange={handlePageination}
                    showSizeChanger={false}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    // left: "250px",
                    bottom: "10px",
                    color: "#636363",
                }}
            >
                <Select
                    style={{ width: 100, marginRight: 10 }}
                    defaultValue={"10/ page"}
                    value={paymentRequestsObj?.limit}
                    onChange={(value) => {
                        storePaymentRequestsParams("limit", value);
                        togglePageLoader(true);
                        getAllPaymentHistory(() => togglePageLoader(false))
                    }}>
                    <Option value={10}>10/ page</Option>
                    <Option value={12}>12/ page</Option>
                    <Option value={16}>16/ page</Option>
                    <Option value={18}>18/ page</Option>
                </Select>
                {paymentHistoryContainer?.meta?.itemsPerPage *
                    (paymentHistoryContainer?.meta?.currentPage - 1) +
                    1}{" "}
                -{" "}
                {paymentHistoryContainer?.meta?.itemsPerPage *
                    paymentHistoryContainer?.meta?.currentPage <
                    paymentHistoryContainer?.meta?.totalItems
                    ? paymentHistoryContainer?.meta?.itemsPerPage *
                    paymentHistoryContainer?.meta?.currentPage
                    : paymentHistoryContainer?.meta?.totalItems}{" "}
                of {paymentHistoryContainer?.meta?.totalItems} items
            </div>
            {isEmailIdModalVisible &&
                <EmailIdModal
                    isEmailIdModalVisible={isEmailIdModalVisible}
                    setIsEmailIdModalVisible={setIsEmailIdModalVisible}
                />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = state => {
    const { paymentHistoryContainer, transactionModalData, paymentRequestsObj, isFromPaymentHistory } = state.aulasWallet;
    return { paymentHistoryContainer, transactionModalData, paymentRequestsObj, isFromPaymentHistory };
};

const mapDispatchToProps = dispatch => ({
    getAllPaymentHistory: (callBack) => dispatch(getAllPaymentHistory(callBack)),
    storePaymentRequestsParams: (key, val) => dispatch(storePaymentRequestsParams(key, val)),
    setFromPaymentHistory: (val) => dispatch(setFromPaymentHistory(val)),
    storePaymentHistory: (val) => dispatch(storePaymentHistory(val)),
    storePaymentReqModalData: (val) => dispatch(storePaymentReqModalData(val))
});


export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequestsPage);

// export default PaymentRequestsPage