import React, { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import { connect } from 'react-redux';
import { deleteUsersPermanent } from 'actions/userManagementV2';
import { deleteCrmtable } from 'actions/courseManagement';


const CrmDeleteModal = ({ open, onCancel, deleteUsersPermanent, selectedRowKeys, setSelectedRowKeys, params, togglePageLoader,
         getEnquiryFormsData, getCoursesInCartData,getWhistledCoursesData,tab,deleteCrmtable,setPage, setPermanentDeletModal,selectedDeleteButton
}) => {
        const [deleteLoader, setDeleteLoader] = useState(false);
        const [check, setCheck] = useState(false)
   


        const successCallback = () => {
                setSelectedRowKeys([]);
                onCancel();
                togglePageLoader(true);
                // if (isFromCourse) {
                //         getCourseStudentsData(() => togglePageLoader(false))
                // } else {
                //         getUsers(params, () => togglePageLoader(false))
                // }
                setPage(1);
                if (tab == 1) {
                    getEnquiryFormsData('Enquiry',1, true,() => { togglePageLoader(false) })
                } else if (tab == 2) {
                    setType('Registration')
                    getEnquiryFormsData('Registration',1, true,() => { togglePageLoader(false) })
                } else if (tab == 3) {
                    getCoursesInCartData(1, true,() => { togglePageLoader(false) })
                }else{
                    getWhistledCoursesData(1, true,() => { togglePageLoader(false) })
                }
        // }
    }

        const handleDeleteClick = () => {

            
                // let params = {
                //         id: selectedRowKeys
                // }
                const id =  selectedRowKeys?.length > 0 ? selectedRowKeys : [selectedDeleteButton];

                setDeleteLoader(true);
                if(tab==1){

                        const params = id
                deleteCrmtable(params, () => setDeleteLoader(false), () => successCallback());
                }
                
                if(tab==2){
                        const userIds = id
                        const params = {userIds}
                deleteUsersPermanent  (params, () => setDeleteLoader(false), () => {
                        setPermanentDeletModal(false)
                                getEnquiryFormsData('Registration',1, true,() => { togglePageLoader(false) })
                })    
                }
        }

        return (
                <div>
                        <Modal
                                centered
                                open={open}
                                footer={null}
                                className='modal-round-corner'
                                onCancel={onCancel}
                                closable={false}
                        >
                                <center className='p-20'>
                                        <Heading style={{ color: "#FF414D" }}>You are about to permanently delete user(s)</Heading>
                                        <div style={{ marginTop: "10px" }}> The user(s) will be permanently deleted from the database along with their data. You cannot undo this action.</div>
                                        <div style={{ width: '80%', marginTop: "15px" }}>
                                                <Checkbox onChange={(e) => { setCheck(e.target.checked) }} />
                                                <h style={{ marginLeft: "10px" }}>I understand that deleting user(s) will remove it permanently from the system.</h></div>

                                        <div className='r-jsb m-t-40' style={{ width: '70%' }}>
                                                <Button disabled={!check} loading={deleteLoader} onClick={() => handleDeleteClick()} type='transparent' style={{ color: '#FF414D' }}>DELETE</Button>
                                                <Button onClick={() => onCancel()} type='primary'>CANCEL</Button>
                                        </div>
                                </center>
                        </Modal>
                </div>
        );
};

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { } = state.userManagementV2;
        return {};
};

const mapDispatchToProps = (dispatch) => ({
        deleteUsersPermanent: (params, callback, successCallback) => dispatch(deleteUsersPermanent(params, callback, successCallback)),
        deleteCrmtable: (params, callback, successCallback) => dispatch(deleteCrmtable(params, callback, successCallback))
});



export default connect(mapStateToProps, mapDispatchToProps)(CrmDeleteModal);