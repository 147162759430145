import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import { Button } from 'components/Button';
import { Progress } from 'antd'

import questionMarkIcon from "../../../Assets/aulasMarket/questionMark.svg"
import correctIcon from "../../../Assets/aulasMarket/ic-attempted result.svg"
import wrongIcon from "../../../Assets/aulasMarket/CloseCircle Red.svg"
import unattemptedIcon from "../../../Assets/aulasMarket/ic-unattempted-result.svg"
import { connect } from 'react-redux';
import { setQuizFlowCount } from 'actions/studentMarketPlace';

function CourseResults({ quizResults, studyKey, setQuizFlowCount }) {
    const handleReAttempt = () => {
        setQuizFlowCount(1);
    };
    const handleViewSolution = (id, itemId, type) => {
        setQuizFlowCount(3)
    }
    const totalQuestions = quizResults.totalQuestions || 0;
    const correctQuestions = quizResults.correctQuestions || 0;
    return (
        <>
            <div className={`${studyKey ? 'study-quiz-container' : 'quiz-container'}`}>
                <div style={{ margin: '10px 10px' }}>
                    <div className='font-bold' style={{ fontSize: "16px" }}>RESULT</div>
                </div>

                <div className='r-c-c m-t-20 '>
                    <Progress
                        strokeColor="#594099"
                        type="circle"
                        percent={(correctQuestions / totalQuestions) * 100} // Calculate the percentage
                        format={(percent) => (
                            <div className='r-c-c-c font-bold text-sm'>
                                <div>{quizResults?.score}
                                    <div style={{ width: "40px", border: "1px solid", marginTop: 5, marginBottom: 5 }}></div>
                                    <div>
                                        {quizResults?.totalPoints}
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div>
                    <div className='r-c-sb text-xmd font-bold' style={{ margin: "20px 20px" }}>
                        <div className='r-c-sb' style={{ width: "30%" }}>
                            <div>
                                <img style={{ width: "20px" }} src={questionMarkIcon} />
                                <span className='m-l-10'>Total questions</span>
                            </div>
                            <span>{quizResults?.totalQuestions}</span>
                        </div>
                        <div className='r-c-sb' style={{ width: "30%" }}>
                            <div>
                                <img style={{ width: "20px" }} src={wrongIcon} />
                                <span className='m-l-10'>Wrong answers</span>
                            </div>
                            <span>{quizResults?.incorrectQuestions}</span>
                        </div>
                    </div>
                    <div className='r-c-sb text-xmd font-bold' style={{ margin: "20px 20px" }}>
                        <div className='r-c-sb' style={{ width: "30%" }}>
                            <div>
                                <img style={{ width: "20px" }} src={correctIcon} />
                                <span className='m-l-10'>Correct answers</span>
                            </div>
                            <span>{quizResults?.correctQuestions}</span>
                        </div>
                        <div className='r-c-sb' style={{ width: "30%" }}>
                            <div>
                                <img style={{ width: "20px" }} src={unattemptedIcon} />
                                <span className='m-l-10'>Skipped questions</span>
                            </div>
                            <span>{quizResults?.skippedQuestions}</span>
                        </div>
                    </div>

                    <div className='r-c-fe' style={{ borderTop: "1px solid #E6E6E6",position:"relative",top:90 }}>
                        <div style={{ marginTop: "8px", marginRight: "20px" }} onClick={() => handleReAttempt()}><Button className='btns-prev '>RE-ATTEMPT</Button></div>
                        <div className='r-c-sb' style={{ marginTop: "8px", marginRight: "20px" }}>
                            <div><Button style={{ backgroundColor: "#594099", color: "#FFFFFF" }} onClick={handleViewSolution}>VIEW SOLUTION</Button></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { quizResults } = state.studentMarketPlace
    return {
        quizResults
    }
};

const mapDispatchToProps = (dispatch) => ({
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseResults)
