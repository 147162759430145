import React, { useEffect, useState } from 'react'
import { Checkbox, Radio, Select, Space, Table, Typography } from 'antd'
import PageHeader from 'components/pageHeader'
import { useHistory } from 'react-router'
import { InputSearch } from 'components/customInputs'
import { getUsers, setSpecificUserId, setUserDetails, updateUserToTranferData, fetchStandards, fetchCourses, } from 'actions/userManagementV2'
import { IMG_URL } from "../../env.json";
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Button } from 'components/Button'
import UserDeleteModal from './modals/userDelete'
import PermanentDeleteModal from './modals/parmanentDeleteModal'
import { setOthersSelectedTab } from 'actions/profileNew'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'


const UserTable = ({ userType, getUsers, usersList, userObj, setSpecificUserId, updateUserToTranferData, setUserDetails, fetchStandards, standards, fetchCourses, courses, setOthersSelectedTab, user, selectedStandard, selectedCourse, resourceData, storeWithoutData, userIds }) => {

        const history = useHistory();
        const location = useLocation()
        console.log("location", storeWithoutData);
        let fromUserManagement = true

        const [sort, setSort] = useState('');
        const [search, setSearch] = useState('');
        const [courseIds, setCourseIds] = useState([]);
        const [standardIds, setStandardIds] = useState([]);
        const [selectedRows, setSelectedRows] = useState([]);
        const [pageLoader, togglePageLoader] = useState(false);
        const [selectedRowKeys, setSelectedRowKeys] = useState([]);
        const [filter, setFilter] = useState({ page: 1, limit: 25 });
        const [userDeleteModalBool, setUserDeleteModalOpen] = useState(false);
        const [parmanentDeleteModal, setPermanentDeletModal] = useState(false)
        const [defaultUser, setDefaultUser] = useState(userObj?.userRole === 'Students' ? 'Student' : 'Staff')
        const [selectedRoles, setSelectedRoles] = useState([]); 
        const [tempSelectedRoles, setTempSelectedRoles] = useState([]); 
        const [filterApplied, setFilterApplied] = useState(false); 
        const [storedUserType,setStoredUserType] =useState([])
        const [selectedSortRoles, setSelectedSortRoles] = useState(''); 
        const [tempSelectedSortRoles, setTempSelectedSortRoles] = useState(''); 
        const [filterSortApplied, setFilterSortApplied] = useState(false);
        const [storedSortType, setStoredSortType] = useState(''); 
        console.log("resourceData", sort);
        let params = {
                userType: defaultUser,
                status: ['Approved'],
                limit: 25,
                search,
                page: search ? undefined : filter?.page,
                courseIds: location?.state?.key == "fromStandardClick" ? [resourceData?.id] : defaultUser === 'Staff' ? undefined : resourceData == -1 || userIds ? undefined : [resourceData?.id],
                standardIds: location?.state?.key == "fromStandardClick" ? [selectedStandard?.standard_id] :defaultUser === 'Staff' || selectedCourse !== 'Live Courses' ? undefined : [selectedStandard?.standard_id],
                sort: storedSortType,
                withoutCourse: selectedCourse !== 'Live Courses' || selectedCourse !== 'Video Courses' || selectedCourse !== 'Online Test SEries' || selectedCourse !== 'Study Materials' ? storeWithoutData : undefined,
                resourceType: selectedCourse == 'Video Courses' ? 'videoResource' :
                        selectedCourse == 'Online Test Series' ? 'testSeries' :
                                selectedCourse == 'Study Materials' ? 'studyMaterial' : undefined,
                userIds: location?.state?.key == "fromStandardClick" ? undefined : userIds ? userIds : undefined,
                roles:storedUserType
        };

        console.log({ resourceData })
        console.log({ selectedCourse })


        useEffect(() => {
                togglePageLoader(true);
                getUsers(params, () => togglePageLoader(false));
        }, [search, filter, standardIds, courseIds, defaultUser, sort,storedUserType,storedSortType]);

        useEffect(() => {
                togglePageLoader(true);
                getUsers(params, () => togglePageLoader(false));
        }, [])

        const setIdToDelete = (id) => {
                selectedRowKeys.push(id);
                setSelectedRowKeys([...selectedRowKeys]);
        };

        const openDeleteModal = (e, record) => {
                setUserDeleteModalOpen(true);
                e.stopPropagation();
                setIdToDelete(record?.id);
        }
        const openPermanentDeleteModal = (e, record) => {
                setPermanentDeletModal(true);
                e.stopPropagation();
                setIdToDelete(record?.id);
        }
       
        
        const handleRoleChange = (checkedValues) => {
            setTempSelectedRoles(checkedValues); 
        };
        
        const applyFilters = (closeDropdown) => {
            setSelectedRoles(tempSelectedRoles); 
            setStoredUserType(tempSelectedRoles)
            setFilterApplied(true); 
            closeDropdown(); 
        };
        
        const resetFilters = (closeDropdown) => {
            setTempSelectedRoles([]); 
            setSelectedRoles([]); 
            setFilterApplied(false); 
            closeDropdown(); 
            setStoredUserType([])
        };
        const handleSortChange = (selectedValue) => {
                setTempSelectedSortRoles(selectedValue); 
            };
            
            const applySortFilters = (closeDropdown) => {
                setSelectedSortRoles(tempSelectedSortRoles); 
                setStoredSortType(tempSelectedSortRoles); 
                setFilterSortApplied(!!tempSelectedSortRoles); 
                closeDropdown();
            };
            
            const resetSortFilters = (closeDropdown) => {
                setSelectedSortRoles(''); 
                setTempSelectedSortRoles(''); 
                setFilterSortApplied(false); 
                closeDropdown(); 
                setStoredSortType(''); 
            };
            
      
    
    const filterMenu = (closeDropdown) => (
        <div style={{ padding: '10px' }}>
            <div>
                <Checkbox.Group
                    options={[
                        { label: 'Teacher', value: 'Teacher' },
                        { label: 'Data Operator', value: 'Data Operator' },
                        { label: 'Operations', value: 'Operation' },
                    ]}
                    value={tempSelectedRoles} 
                    onChange={handleRoleChange}
                    style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div  onClick={()=>{if(tempSelectedRoles?.length == 0){}else{resetFilters(closeDropdown)}}} style={{ cursor: 'pointer', color: '#594099' ,opacity:tempSelectedRoles?.length != 0 ? 1:0.5,cursor:tempSelectedRoles?.length != 0 ? 'pointer' : 'not-allowed'}}>RESET</div>
                <div onClick={() => {if(tempSelectedRoles?.length == 0){}else{applyFilters(closeDropdown)}}}style={{ cursor: 'pointer', color: '#fff',width:30,height:24 ,display:"flex",justifyContent:"center",backgroundColor: '#594099',fontSize:"14px",opacity:tempSelectedRoles?.length != 0 ? 1:0.5,cursor:tempSelectedRoles?.length != 0 ? 'pointer' : 'not-allowed'}}>OK</div>
            </div>
        </div>
    );  

    const renderSortMenu = (closeDropdown) => (
        <div style={{ padding: '10px' }}>
            <div style={{display:"flex",flexDirection:"column"}}>
                <Checkbox
                    checked={tempSelectedSortRoles === 'alphabetical'}
                    onChange={() => handleSortChange('alphabetical')}
                >
                    Alphabetical order
                </Checkbox>
                <Checkbox
                style={{marginLeft:0}}
                    checked={tempSelectedSortRoles === 'newest'}
                    onChange={() => handleSortChange('newest')}
                >
                    Newest first
                </Checkbox>
                <Checkbox
                style={{marginLeft:0}}
                    checked={tempSelectedSortRoles === 'oldest'}
                    onChange={() => handleSortChange('oldest')}
                >
                    Oldest first
                </Checkbox>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div
                    onClick={() => {
                        if (tempSelectedSortRoles) resetSortFilters(closeDropdown);
                    }}
                    style={{
                        cursor: tempSelectedSortRoles ? 'pointer' : 'not-allowed',
                        color: '#594099',
                        opacity: tempSelectedSortRoles ? 1 : 0.5,
                    }}
                >
                    RESET
                </div>
                <div
                    onClick={() => {
                        if (tempSelectedSortRoles) applySortFilters(closeDropdown);
                    }}
                    style={{
                        cursor: tempSelectedSortRoles ? 'pointer' : 'not-allowed',
                        color: '#fff',
                        width: 30,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#594099',
                        fontSize: '14px',
                        opacity: tempSelectedSortRoles ? 1 : 0.5,
                    }}
                >
                    OK
                </div>
            </div>
        </div>
    );
    
    
    
 

   console.log("sort-126",sort);
        const columns = (userType, usersList, user) => {
                const renderName = (value, record) => (
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 0 }}>
                                {console.log("record",record)}
                                <img src={`${IMG_URL}/${record?.image ? record?.image : 'default.png'}`} alt="" style={{ width: '20px', marginRight: '10px' }} />
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontWeight: 600 }}>
                                        <Typography.Text ellipsis={{ tooltip: true }}>{value}</Typography.Text>
                                </div>
                        </div>
                );
                const baseColumns = [
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            width: '20%',
                            render: renderName,
                            filters: null,
                            filterDropdown: ({ confirm }) => renderSortMenu(confirm), 
                            filteredValue: filterSortApplied ? [selectedSortRoles] : null, 
                            onFilter: (value, record) => {
                                if (!selectedSortRoles) return true; 
                                return true; 
                            },
                            sorter: (a, b) => {
                                if (storedSortType === 'newest') {
                                    return new Date(b.date) - new Date(a.date); 
                                }
                                if (storedSortType === 'oldest') {
                                    return new Date(a.date) - new Date(b.date); 
                                }
                                if (storedSortType === 'alphabetical') {
                                    return a.name.localeCompare(b.name); 
                                }
                                return 0; 
                            },
                        },
                    ];
                    
                    
                    
                

                if (userType !== 'Students') {
                        baseColumns.push({
                                title: 'Role',
                                dataIndex: 'role',
                                key: 'role',
                                render: (value, record) => (
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        {record?.role ? record?.role : 'NA'}
                                    </div>
                                ),
                                filters: null, 
                                filterDropdown: ({ confirm }) => filterMenu(confirm), 
                                filteredValue: filterApplied ? selectedRoles : null, 
                                onFilter: (value, record) => selectedRoles.length === 0 || selectedRoles.includes(record?.role),
                            });

                        baseColumns.push({
                                title: 'Privilage(s)',
                                dataIndex: 'privilages',
                                key: 'privilages',
                                render: (value, record) => <div style={{ display: 'flex', justifyContent: 'flex-start' }}>{record?.privilage ? record?.privilage : 'NA'}</div>


                        });
                } else {
                        baseColumns.push(
                                {
                                        title: 'Mobile No',
                                        dataIndex: 'mobileNo',
                                        key: 'mobileNo',
                                        render: (value, record) => <div style={{ display: 'flex', justifyContent: 'flex-start' }}>{record?.phone ? record?.phone : 'NA'}</div>,
                                },
                                {
                                        title: 'Courses',
                                        dataIndex: 'courses',
                                        key: 'courses',
                                        render: (value, record) => (
                                                <div style={{
                                                        textAlign: 'left',
                                                        width: 250,
                                                }}>
                                                        <Typography.Text style={{ width: '100%' }} ellipsis={{ tooltip: true }}>
                                                                {record?.courses ? record?.courses : 'NA'}
                                                        </Typography.Text>
                                                </div>
                                        ),
                                },
                                {
                                        title: 'Registration on',
                                        dataIndex: 'registered on',
                                        key: 'registered on',
                                        render: (value, record) => <div>{moment(record?.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
                                }
                        );
                }

                baseColumns.push({
                        title: 'Action',
                        dataIndex: 'status',
                        key: 'status',
                        render: (value, record) => {
                                return <div className='' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) && (
                                                <>
                                                        <Button onClick={(e) => openDeleteModal(e, record)} type='transparent' style={{ color: '#636363', padding: 0 }} disabled={selectedRowKeys.includes(record.id)}>SUSPEND</Button>
                                                        <Button onClick={(e) => openPermanentDeleteModal(e, record)} type='transparent' style={{ color: '#FF414D', marginLeft: 10 }} disabled={selectedRowKeys.includes(record.id)}>DELETE</Button>

                                                        {record?.role == "Teacher" && (
                                                                <Button type='transparent' onClick={(e) => { e.stopPropagation(); updateUserToTranferData(record); history.push('/user-management/transfer-data') }} style={{ color: '#FFA931' }} disabled={selectedRowKeys.includes(record.id)}>MANAGE</Button>
                                                        )}
                                                </>
                                        )
                                        }
                                </div >
                        }
                });

                return baseColumns;
        };


        const rowSelection = {
                type: "checkbox",
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys, selections) => {
                        setSelectedRows([...selections?.map(item => item?.fileKey)]);
                        setSelectedRowKeys([...selectedRowKeys]);
                },
        };

        const actions = [
                <>
                        {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ?
                                <>
                                        <Button type='archive' style={{ marginRight: 10, }} disabled={selectedRowKeys?.length < 1} onClick={() => { setUserDeleteModalOpen(true); }}>SUSPEND</Button>
                                        <Button type='delete' style={{ marginRight: 10, }} disabled={selectedRowKeys?.length < 1} onClick={() => { setPermanentDeletModal(true) }}>DELETE</Button>
                                </>
                                : null}
                        <InputSearch allowClear placeholder='Search by name/mobile' onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} style={{ borderRadius: "32px" }} />
                </>
        ];

        const onDeleteModalCancel = () => {
                setUserDeleteModalOpen(false)
        };

        const onPermanentDeleteModalCancel = () => {
                setPermanentDeletModal(false)
        };

        const onRow = (record, rowIndex) => {
                return {
                        style: { cursor: 'pointer' },
                        onClick: () => {
                                history.push({
                                        pathname: '/user-management/profile',
                                        state: {
                                                key: fromUserManagement
                                        }
                                })
                                setSpecificUserId(record?.id);
                                setUserDetails(record);
                                setOthersSelectedTab('Basic Profile');
                        }
                }
        }



        return (
                <div>
                        <PageHeader
                                title={
                                        userObj?.userRole === 'Students'
                                                ? `${selectedStandard?.std == undefined ? selectedCourse : selectedStandard?.std} - ${selectedStandard?.section == undefined ? '' : selectedStandard?.section} (${usersList?.meta?.totalItems})`
                                                : (userObj?.userRole === 'Teachers' || userObj?.userRole === 'Data Operators' || userObj?.userRole === 'Operations')
                                                        ? 'Staff'
                                                        : selectedCourse
                                }
                                actions={actions}
                                onBackPress={() => history.goBack()}
                        />
                        {pageLoader ? <></> :
                                <div className='m-t-60'>
                                        <Table
                                                loading={pageLoader}
                                                style={{ width: "100vw" }}
                                                columns={columns(userObj?.userRole, usersList, user)}
                                                dataSource={usersList?.items || []}
                                                rowSelection={rowSelection}
                                                rowKey={record => record?.id}
                                                onRow={onRow}
                                                pagination={{
                                                        total: usersList?.meta?.totalItems,
                                                        pageSize: filter?.limit,
                                                        current: filter?.page,
                                                        size: 'small',
                                                        showTotal: (total, range) => <div>Showing {range[0]} - {range[1]} of {total}</div>,
                                                        onChange: (page) => setFilter({ ...filter, page }),
                                                        showSizeChanger: false
                                                }}
                                        />
                                </div>
                        }
                        <PageDataLoader visible={pageLoader} />
                        {userDeleteModalBool && <UserDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={userDeleteModalBool} onCancel={() => onDeleteModalCancel()} />}
                        {parmanentDeleteModal && <PermanentDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={parmanentDeleteModal} onCancel={() => onPermanentDeleteModalCancel()} />}
                </div>
        )
}

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { usersList, userObj, standards, courses, selectedStandard, selectedCourse, storeWithoutData, userIds } = state.userManagementV2;
        const { resourceData } = state.feeManagement;
        return { usersList, userObj, standards, courses, user, selectedStandard, selectedCourse, resourceData, storeWithoutData, userIds }
};

const mapDispatchToProps = (dispatch) => ({
        getUsers: (params, callback) => dispatch(getUsers(params, callback)),
        setSpecificUserId: id => dispatch(setSpecificUserId(id)),
        updateUserToTranferData: data => dispatch(updateUserToTranferData(data)),
        setUserDetails: res => dispatch(setUserDetails(res)),
        setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);