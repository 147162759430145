import React from "react";
import { Col, Row, Input, Select, Radio } from "antd";
import { CountryDropdown } from "react-country-region-selector";
import { Color } from "services";

const { Option } = Select;
const businessTypes = [
  "Private Limited",
  "Proprietorship",
  "Partnership",
  "LLP",
  "Trust",
  "Society",
  "Not Registered",
];

const strengthTypes = ["1-50", "51-500", "500+"];

export const businessInfo = (
  kycDetails,
  handleUpdateDetails,
  validateData,
  handlePinUpdate,
  changeGstAvailability
) => (
  <div style={{ width: "100%" }}>
    <Row className="m-t-20">
      <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          Institute Name <span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          disabled
          onChange={(e) => handleUpdateDetails(e.target.value, "name")}
          value={kycDetails.name}
          placeholder="Name of the institute"
          className="radius-5 kyc-input-fields"
          style={{
            background: Color.lightGrey,
            border: "none",
            color: "black",
          }}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 11, offset: 1 }}
        style={{ width: "100%" }}
      >
        <div className="text-xs color-black m-b-5 bold-600">Website </div>
        <Input
          onChange={(e) => handleUpdateDetails(e.target.value, "website")}
          value={kycDetails.website}
          placeholder="Institute Website"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
      </Col>
    </Row>
    <Row className="m-t-20">
      <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          Institute Type <span style={{ color: Color.red }}>*</span>
        </div>
        <Select
          value={kycDetails.orgType}
          rows={3}
          className="kyc-input-fields borderLessSelect"
          style={{ width: "100%", height: 50 }}
          onSelect={(val) => handleUpdateDetails(val, "orgType")}
          placeholder="Business Type"
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        // onClear={val => this.pageAddOrgDetail(null, 'orgType')}
        >
          {businessTypes.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 11, offset: 1 }}
        style={{ width: "100%" }}
      >
        <div className="text-xs color-black m-b-5 bold-600">
          No. of Students
        </div>
        <Select
          value={kycDetails.noOfStudents}
          rows={3}
          className="kyc-input-fields borderLessSelect"
          style={{ width: "100%", height: 50 }}
          onSelect={(val) => handleUpdateDetails(val, "noOfStudents")}
          placeholder="No of students in the institute"
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        // onClear={val => this.pageAddOrgDetail(null, 'orgType')}
        >
          {strengthTypes.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
    {kycDetails.orgType === "Private Limited" ||
      kycDetails.orgType === "LLP" ||
      kycDetails.orgType === "Trust" ||
      kycDetails.orgType === "Society" ? (
      <Row className="m-t-20">
        <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
          <div className="text-xs color-black m-b-5 bold-600">
            Business PAN <span style={{ color: Color.red }}>*</span>
          </div>
          <Input
            onChange={(e) => {
              e.target.value.length <= 10
                ? handleUpdateDetails(
                  e.target.value.toUpperCase().trim(),
                  "businessPanNo"
                )
                : e.preventDefault();
            }}
            value={kycDetails.businessPanNo}
            placeholder="10 digit PAN Number of the business"
            className="radius-5 kyc-input-fields"
            onBlur={(e) => validateData(e.target.value, "institute-pan")}
            style={{ background: Color.lightGrey, border: "none" }}
            disabled={
              kycDetails.kycStatus === "Submitted" ||
              kycDetails.kycStatus === "Approved"
            }
          />
          {kycDetails.institutePanValid === false ? (
            <div className="text-xs red bold-600">Enter valid PAN</div>
          ) : null}
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 11, offset: 1 }}
          style={{ width: "100%" }}
        >
          {kycDetails.orgType === "LLP" ? (
            <>
              <div className="text-xs color-black m-b-5 bold-600">
                LLPIN <span style={{ color: Color.red }}>*</span>
              </div>
              <Input
                onChange={(e) =>
                  e.target.value.length > 7
                    ? e.preventDefault()
                    : handleUpdateDetails(
                      e.target.value.toUpperCase().trim(),
                      "llpin"
                    )
                }
                value={kycDetails.llpin}
                placeholder="LLPIN of the institute"
                className="radius-5 kyc-input-fields"
                onBlur={(e) => validateData(e.target.value, "llpin")}
                style={{ background: Color.lightGrey, border: "none" }}
                disabled={
                  kycDetails.kycStatus === "Submitted" ||
                  kycDetails.kycStatus === "Approved"
                }
              />
              {kycDetails.llpinValid === false ? (
                <div className="text-xs red bold-600">Enter valid LLPIN</div>
              ) : null}
            </>
          ) : kycDetails.orgType === "Private Limited" ? (
            <>
              <div className="text-xs color-black m-b-5 bold-600">
                CIN <span style={{ color: Color.red }}>*</span>
              </div>
              <Input
                onChange={(e) =>
                  e.target.value.length > 21
                    ? e.preventDefault()
                    : handleUpdateDetails(
                      e.target.value.toUpperCase().trim(),
                      "cin"
                    )
                }
                value={kycDetails.cin}
                placeholder="Corporate Identification Number"
                className="radius-5 kyc-input-fields"
                onBlur={(e) => validateData(e.target.value, "cin")}
                style={{ background: Color.lightGrey, border: "none" }}
                disabled={
                  kycDetails.kycStatus === "Submitted" ||
                  kycDetails.kycStatus === "Approved"
                }
              />
              {kycDetails.cinValid === false ? (
                <div className="text-xs red bold-600">Enter valid CIN</div>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
    ) : null}
    <Row className="m-t-20">
      <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          Authorized Signatory PAN <span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          onBlur={(e) => validateData(e.target.value, "personal-pan")}
          onChange={(e) => {
            e.target.value.length <= 10
              ? handleUpdateDetails(
                e.target.value.toUpperCase().trim(),
                "personalPanNo"
              )
              : e.preventDefault();
          }}
          value={kycDetails.personalPanNo}
          placeholder="10 digit PAN Number of the authorized signatory"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
        {kycDetails.personalPanValid === false ? (
          <div className="text-xs red bold-600">Enter valid PAN</div>
        ) : null}
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 11, offset: 1 }}
        style={{ width: "100%" }}
      >
        <div className="text-xs color-black m-b-5 bold-600">
          PAN Owner's Name<span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          onChange={(e) => handleUpdateDetails(e.target.value, "panOwnerName")}
          value={kycDetails.panOwnerName}
          placeholder="Name of the authorized signatory as mentioned on the PAN Card"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
      </Col>
    </Row>
    {kycDetails.orgType !== "Not Registered" ? (
      <Row className="m-t-20">
        <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
          <div className="text-xs color-black m-b-5 bold-600">
            GST No. <span style={{ color: Color.red }}>*</span>
          </div>
          <div className="r-c-c-c" style={{ height: 50 }}>
            <Radio.Group
              style={
                kycDetails.kycStatus === "Submitted" ||
                  kycDetails.kycStatus === "Approved"
                  ? { cursor: "not-allowed", pointerEvents: "none" }
                  : {}
              }
              //  disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
              onChange={(e) => changeGstAvailability(e)}
              value={kycDetails.gstAvailable}
            >
              <Radio value={true}>We have a registered GSTIN</Radio>
              <Radio value={false}>We don't have a GSTIN</Radio>
            </Radio.Group>
          </div>
        </Col>
        {kycDetails.gstAvailable ? (
          <Col
            xs={{ span: 24 }}
            md={{ span: 11, offset: 1 }}
            style={{ width: "100%" }}
          >
            <div
              className="text-xs color-black m-b-5 bold-600"
              style={{ height: 10 }}
            ></div>
            <Input
              onChange={(e) =>
                e.target.value.length <= 15
                  ? handleUpdateDetails(
                    e.target.value.toUpperCase().trim(),
                    "gstNo"
                  )
                  : e.preventDefault()
              }
              value={kycDetails.gstNo}
              placeholder="GST Number"
              className="radius-5 kyc-input-fields"
              disabled={
                kycDetails.kycStatus === "Submitted" ||
                kycDetails.kycStatus === "Approved"
              }
              onBlur={(e) => validateData(e.target.value, "gst")}
              style={{ background: Color.lightGrey, border: "none" }}
            />
            {kycDetails.gstValid === false ? (
              <div className="text-xs red bold-600">Enter valid GST Number</div>
            ) : null}
          </Col>
        ) : null}
      </Row>
    ) : null}
    <Row className="m-t-20">
      <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          HSN/SAC
        </div>

        <Input
          onChange={(e) => handleUpdateDetails(e.target.value, "hsn_sac")}
          value={kycDetails.hsn_sac}
          placeholder="HSN/SAC"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}

        />
      </Col>

      <Col xs={{ span: 24 }} md={{ span: 11, offset: 1 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          Building/Locality/Area <span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          onChange={(e) => handleUpdateDetails(e.target.value, "locality")}
          value={kycDetails.locality}
          placeholder="Building/Locality/Area"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
      </Col>



      <Col
        xs={{ span: 24 }}
        md={{ span: 12, offset: 0 }}
        style={{ width: "100%" }}
      >
        <div className="text-xs color-black m-b-5 bold-600">
          PIN Code <span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          onChange={(e) =>
            e.target.value.length > 6
              ? e.preventDefault()
              : handleUpdateDetails(e.target.value, "pincode")
          }
          onBlur={(e) => validateData(e.target.value, "picode")}
          value={kycDetails.pincode}
          placeholder="PIN Code"
          type="number"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
          onBlur={(e) => handlePinUpdate(e)}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
        {kycDetails.pincodeValid === false ? (
          <div className="text-xs red bold-600">Enter valid PINCODE</div>
        ) : null}
      </Col>
    </Row>
    <Row className="m-t-20">
      <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          District <span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          onChange={(e) => handleUpdateDetails(e.target.value, "city")}
          value={kycDetails.city}
          placeholder="District"
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
        />
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 11, offset: 1 }}
        style={{ width: "100%" }}
      >
        <div className="text-xs color-black m-b-5 bold-600">
          State <span style={{ color: Color.red }}>*</span>
        </div>
        <Input
          onChange={(e) => handleUpdateDetails(e.target.value, "state")}
          value={kycDetails.state}
          placeholder="State"
          className="radius-5 kyc-input-fields"
          style={{ background: Color.lightGrey, border: "none" }}
          disabled={
            kycDetails.kycStatus === "Submitted" ||
            kycDetails.kycStatus === "Approved"
          }
        />
      </Col>
    </Row>
    <Row className="m-t-20">
      <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
        <div className="text-xs color-black m-b-5 bold-600">
          Country <span style={{ color: Color.red }}>*</span>
        </div>
        <div
          className="kyc-input-fields"
          style={{ background: Color.lightGrey, padding: "0px 10px" }}
        >
          <CountryDropdown
            style={{
              border: "none",
              background: Color.lightGrey,
              width: "100%",
            }}
            className="kyc-input-fields"
            placeholder="Select Country*"
            value={kycDetails.country}
            onChange={(val) => handleUpdateDetails(val, "country")}
            disabled={
              kycDetails.kycStatus === "Submitted" ||
              kycDetails.kycStatus === "Approved"
            }
          />
        </div>
      </Col>
    </Row>
  </div>
);
