import { deleteOffer, patchPublishCourse, patchPublishTest, patchPublishOffers, patchPublishStudyMaterials, patchPublishVideoResource, storeSpecificCourseData } from 'actions/courseManagement';
import { Modal } from 'antd'
import { Button } from 'components/Button';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ConfirmPublishOrUnpublishModal({
    isConfirmPublishModalVisible,
    setIsConfirmPublishModalVisible,
    setSelectedPublishCards,
    setSelectedUnpublishCards,
    selectedPublishCards,
    selectedUnpublishCards,
    togglePageLoader,
    getTotalCoursesList,
    getOnlineTestSeries,
    patchPublishCourse,
    patchPublishTest,
    fromCourseDetailsPage,
    specificCourseDataContainer,
    courseTypeName,
    patchPublishStudyMaterials,
    getStudyMaterialsList,
    patchPublishVideoResource,
    getTotalsVideoResource,
    publish,
    isFromOffersDelete,
    isFromOffersGetPage,
    selectedOfferId,
    deleteOffer,
    getOffers,
    isOffersPublishedClicked,
    isNew,
    patchPublishOffers,
    published,
    isSearchedSuggestionClicked,
    searchSelectedCourseType,
    getSearchResultsData,
    getSpecificCourseData,
    getSpecificSMData,
    getOnlineTestSeriesCourseDetails,
    getSpecificVideoCourseData,
    fromEditCoursePage,
    storeSpecificCourseData,
    postCreateOffers,
    selectedCourseType,
    selectedCategories,
    selectedSubcategories,
    clearValues,
    selectedCourse,
    fromMarketPlaceMenu,
    setRenderPage,
    renderPage,
    setHoveredCardId
}) {
    const handleCancel = () => {
        setIsConfirmPublishModalVisible(false)
        setHoveredCardId(null)
        if (!fromCourseDetailsPage && !isFromOffersDelete && !fromEditCoursePage) {
            setSelectedUnpublishCards([]);
            setSelectedPublishCards([]);
        } else { }
    }
    const [btnLoader, setBtnLoader] = useState(false);
    let showPublish = false;
    if ((isOffersPublishedClicked && isNew || isOffersPublishedClicked && !isNew && !published)) {
        showPublish = true
    } else if (fromCourseDetailsPage && publish) {
        showPublish = true
    } else if (fromCourseDetailsPage && !publish) {
        showPublish = false
    } else if (selectedPublishCards?.length != 0) {
        showPublish = false
    } else if (selectedUnpublishCards?.length != 0) {
        showPublish = true
    } else if (specificCourseDataContainer?.published != 1) {
        showPublish = true
    }
    const history = useHistory();
    const actionMessage = fromEditCoursePage ? "discard changes" : (isFromOffersDelete && !isOffersPublishedClicked) ? "delete" : showPublish ? "publish" : "unpublish";

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isConfirmPublishModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>Confirm</div>
            <center style={{ color: "#636363", fontSize: 16, marginTop: 5, padding: "0px 20px" }}> {`Are you sure you want to ${actionMessage} ?`} </center>
            <center>
                <div style={{ display: "flex", justifyContent: "space-around", width: "75%", marginTop: 60 }}>
                    <Button onClick={() => { handleCancel() }}>NO</Button>
                    <Button
                        type="primary"
                        loading={btnLoader}
                        onClick={() => {
                            setBtnLoader(true);
                            if (fromEditCoursePage) {
                                storeSpecificCourseData([])
                                setIsConfirmPublishModalVisible(false)
                                togglePageLoader(true)
                                if (courseTypeName == "liveCourses") {
                                    getSpecificCourseData(() => { togglePageLoader(false) })
                                } else if (courseTypeName == "videoResources") {
                                    getSpecificVideoCourseData(() => { togglePageLoader(false) })
                                } else if (courseTypeName == "studyMaterials") {
                                    getSpecificSMData(() => { togglePageLoader(false) })
                                } else if (courseTypeName == "onlineTestSeries") {
                                    getOnlineTestSeriesCourseDetails(() => { togglePageLoader(false) })
                                }
                            } else if (isFromOffersDelete && !isOffersPublishedClicked) {
                                deleteOffer(
                                    selectedOfferId,
                                    () => { setBtnLoader(false) },
                                    () => {
                                        handleCancel();
                                        if (!isFromOffersGetPage) {
                                            history.push("/offers")
                                        } else {
                                            togglePageLoader(true);
                                            getOffers(1, () => { togglePageLoader(false) })
                                        }
                                    }
                                )
                            } else if ((isOffersPublishedClicked && isNew || isOffersPublishedClicked && !isNew && !published)) {
                                postCreateOffers(
                                    true,
                                    selectedCourseType,
                                    selectedCategories,
                                    selectedSubcategories,
                                    selectedCourse,
                                    () => { setBtnLoader(false) },
                                    () => { handleCancel(); clearValues(); history.push("/offers") }
                                )
                            } else if (isOffersPublishedClicked && published) {
                                patchPublishOffers(
                                    selectedOfferId,
                                    showPublish,
                                    () => { setBtnLoader(false) },
                                    () => {
                                        handleCancel();
                                        history.push("/offers")
                                    }
                                )
                            } else if ((isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse")) {
                                patchPublishCourse(
                                    selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards,
                                    showPublish,
                                    () => { setBtnLoader(false) },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => { togglePageLoader(false) })
                                    }
                                )
                            } else if ((isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial")) {
                                patchPublishStudyMaterials(
                                    selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards,
                                    showPublish,
                                    () => { setBtnLoader(false) },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => { togglePageLoader(false) })
                                    }
                                )
                            } else if ((isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo")) {
                                patchPublishVideoResource(
                                    selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards,
                                    showPublish,
                                    () => { setBtnLoader(false) },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => { togglePageLoader(false) })
                                    }
                                )
                            }
                            else if (courseTypeName == "liveCourses") {
                                patchPublishCourse(
                                    fromCourseDetailsPage || fromMarketPlaceMenu ? [specificCourseDataContainer?.course?.id || specificCourseDataContainer?.id] : selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards, showPublish,
                                    () => { setBtnLoader(false), fromMarketPlaceMenu ? setRenderPage(!renderPage) : null },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        if (fromCourseDetailsPage) {
                                            getSpecificCourseData(() => { togglePageLoader(false) })
                                        } else {
                                            getTotalCoursesList(() => { togglePageLoader(false) })
                                        }
                                    }
                                )
                                if (fromCourseDetailsPage) {
                                    getSpecificCourseData(() => { togglePageLoader(false) })
                                }

                            } else if (courseTypeName == "onlineTestSeries" || (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests")) {
                                
                                patchPublishTest(
                                    fromCourseDetailsPage || fromMarketPlaceMenu ? [specificCourseDataContainer?.testSeriesDetails?.id] : selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards,
                                    showPublish,
                                    () => { setBtnLoader(false), fromMarketPlaceMenu ? setRenderPage(!renderPage) : null },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        if (fromCourseDetailsPage) {
                                            getOnlineTestSeriesCourseDetails(() => { togglePageLoader(false); fromMarketPlaceMenu ? setRenderPage(!renderPage) : null })
                                        } else {

                                            getOnlineTestSeries(() => togglePageLoader(false))
                                        }
                                    }
                                )
                                if (fromCourseDetailsPage) {
                                    getOnlineTestSeriesCourseDetails(() => { togglePageLoader(false); fromMarketPlaceMenu ? setRenderPage(!renderPage) : null })
                                }


                            } else if (courseTypeName == "studyMaterials" || (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial")) {
                                patchPublishStudyMaterials(
                                    fromCourseDetailsPage || fromMarketPlaceMenu ? [specificCourseDataContainer?.id] : selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards,
                                    showPublish,
                                    () => { setBtnLoader(false), fromMarketPlaceMenu ? setRenderPage(!renderPage) : null },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        if (fromCourseDetailsPage) {
                                            getSpecificSMData(() => { togglePageLoader(false) })
                                        } else {
                                            getStudyMaterialsList(() => { togglePageLoader(false) })
                                        }
                                    }
                                )
                                if (fromCourseDetailsPage) {
                                    getSpecificSMData(() => { togglePageLoader(false) })
                                }
                            } else if (courseTypeName == "videoResources" || (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo")) {
                                console.log('herere',fromCourseDetailsPage,fromMarketPlaceMenu,specificCourseDataContainer?.id,selectedPublishCards?.length,selectedPublishCards,selectedUnpublishCards);

                                patchPublishVideoResource(
                                    fromCourseDetailsPage || fromMarketPlaceMenu ? [specificCourseDataContainer?.id] : selectedPublishCards?.length != 0 ? selectedPublishCards : selectedUnpublishCards,
                                    showPublish,
                                    () => { setBtnLoader(false), fromMarketPlaceMenu ? setRenderPage(!renderPage) : null },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        if (fromCourseDetailsPage) {
                                            getSpecificVideoCourseData(() => { togglePageLoader(false) })
                                        } else {
                                            getTotalsVideoResource(() => { togglePageLoader(false) })
                                        }
                                    }
                                )
                                if (fromCourseDetailsPage) {
                                    getSpecificVideoCourseData(() => { togglePageLoader(false) })
                                } 
                            }
                        }}>YES</Button>
                </div>
            </center>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    const { } = state.courseManagement;
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
    patchPublishCourse: (ids, publish, callBack, successCallBack) => dispatch(patchPublishCourse(ids, publish, callBack, successCallBack)),
    patchPublishTest: (ids, publish, callBack, successCallBack) => dispatch(patchPublishTest(ids, publish, callBack, successCallBack)),
    patchPublishStudyMaterials: (ids, publish, callBack, successCallBack) => dispatch(patchPublishStudyMaterials(ids, publish, callBack, successCallBack)),
    patchPublishVideoResource: (ids, isPublish, callBack, successCallBack) => dispatch(patchPublishVideoResource(ids, isPublish, callBack, successCallBack)),
    deleteOffer: (id, callBack, successCallBack) => dispatch(deleteOffer(id, callBack, successCallBack)),
    patchPublishOffers: (id, publish, callBack, successCallBack) => dispatch(patchPublishOffers(id, publish, callBack, successCallBack)),
    storeSpecificCourseData: (val) => dispatch(storeSpecificCourseData(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPublishOrUnpublishModal);