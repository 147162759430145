import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { setAulasCareChat } from 'actions/adminCare';
import { fetchBuyStorage, getSubscriptionDetails, postStorageUpgrade, setSubscriptionPlan } from 'actions/digitalLibrary';
import { Button, Typography } from 'antd';
import PageDataLoader from 'components/pageDataLoader';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Notification } from 'services';
import SubscriptionModal from '../modals/subscriptionModal';
import PageHeader from 'components/pageHeader';




const BuyStoragePage = ({ fetchBuyStorage, buyStorageDetails, getSubscriptionDetails,postStorageUpgrade, setAulasCareChat,setSubscriptionPlan,storeSubscriptionPlanData }) => {
    
    const history = useHistory()
    const [pageLoader, setPageLoader] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false)
    const [subscriptionDetails, setSubscriptionDetails] = useState({})

    const handleHistory = () => {
        history.push('/digital-library/division-card')
    }

    useEffect(() => {
        setPageLoader(true)
        fetchBuyStorage(() => setPageLoader(false));
    }, [])

    const handleViewAulasChatClick = () => {

        setAulasCareChat(true)

        history.push('/care/chat')

    }

    const handleSubscribe = (item) => {
        if (Math.floor((parseFloat(buyStorageDetails?.spaceUsed / 1024 / 1024 / 1024).toFixed(2))) <= parseInt(item?.space)) {
            setSubscriptionDetails(item)

            setModalVisible(true)
            console.log("abcs",item);
            getSubscriptionDetails(()=>{},item?.space)

        }
        else{
            Notification.error('Error',`You are already using more than ${item?.space} storage.`)
        }
    }

    const handleBackPress = () => {
        history.goBack();
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    {/* <PageHeader
                        title={<div style={{ fontSize: "25px", height: '50px', display: 'flex', alignItems: 'center' }}>Get More Storage</div>}
                        onBack={() => handleHistory()}
                        backIcon={<ArrowLeftOutlined />}
                    /> */}

                    <PageHeader goBack={true} title='Get More Storage' onBackPress={()=>handleBackPress()} />
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginTop: 50 }}>
                    <div className='text-heading-library' style={{ fontSize: '17px' }}>{buyStorageDetails && buyStorageDetails?.currentPlan && Object.keys(buyStorageDetails?.currentPlan)?.length != 0 ? 'Change Subscription Plan' : 'Subscribe to any storage plan for Library'}</div>
                    <div className='text-content-library' style={{ fontSize: '11px' }}>{buyStorageDetails && buyStorageDetails?.currentPlan && Object.keys(buyStorageDetails?.currentPlan)?.length != 0 ? 'Browse to upgrade your subscription plan.' : 'Increase storage capacity to upload more content.'}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '18px', flexWrap: 'wrap' }}>
                    {buyStorageDetails?.storagePlans?.length != 0 ? buyStorageDetails?.storagePlans?.map((item) => (
                        <div className='storageCard'>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography.Text className='text-heading-library' style={{ fontSize: '30px' }}>{item?.space}</Typography.Text>
                                    <Typography.Text className='text-content-library'>Storage space</Typography.Text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography.Text className='text-heading-library' style={{ fontSize: '16px', color: '#1089FF' }}>₹ {item?.price}</Typography.Text>
                                    <Typography.Text className='text-content-library'>Price</Typography.Text>
                                </div>

                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {!item?.current ?
                                    <Button className={item?.disable ? 'button-buy-storage-disabled' : 'button-buy-storage'} style={{borderRadius:8}} onClick={!item?.current ? () => handleSubscribe(item) : () => { }} >{item?.current ? <span><CheckCircleOutlined style={{ marginRight: '5px' }} />CUURENT PLAN</span> : 'SUBSCRIBE'}</Button> :
                                    <div style={{ marginLeft: '10px', width: "90%", borderRadius: '8px', border: '1px solid #594099', textAlign: 'center', height: '32px', padding: '4px', color: '#594099' }}><CheckCircleOutlined style={{ marginRight: '5px' }} />CURRENT PLAN</div>}
                            </div>

                        </div>
                    )) : null}
                    {(isModalVisible && <SubscriptionModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} subscriptionDetails={subscriptionDetails} setPageLoader={setPageLoader} currentPlan={buyStorageDetails?.currentPlan}  storeSubscriptionPlanData={storeSubscriptionPlanData}/>)}

                    {/* openRazorPay(item?.space, item?.price) */}

                    {/* commented due to aulas care is not in PROD */}

                    {/* <div className='storageCard'>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography.Text className='text-heading-library' style={{ fontSize: '30px' }}>Custom Storage</Typography.Text>
                            <Typography.Text className='text-content-library'>Reach out to get a quote</Typography.Text>
                        </div>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button className='button-buy-storage' style={{borderRadius:8}} onClick={() => handleViewAulasChatClick()}>CONTACT US</Button>
                        </div>
                    </div> */}

                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    );
};


const mapStateToProps = (state) => {
    const {
        buyStorageDetails,
        storeSubscriptionPlanData
    } = state.digitalLibrary;
    const { user } = state.session;
    return {
        storeSubscriptionPlanData,
        buyStorageDetails
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchBuyStorage: (callback) => dispatch(fetchBuyStorage(callback)),
    postStorageUpgrade: (object, callback, successCallback) => dispatch(postStorageUpgrade(object, callback, successCallback)),
    setAulasCareChat: (chat) => dispatch(setAulasCareChat(chat)),
    getSubscriptionDetails:(callback,subscriptionDetails)=>dispatch(getSubscriptionDetails(callback,subscriptionDetails))
    // setSubscriptionPlan:(res)=>dispatch(setSubscriptionPlan(res))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(BuyStoragePage);