import { postEditOfflinePayment, postMarkAsPaidOffline } from 'actions/courseManagement';
import { DatePicker, Divider, Input, Modal, Typography } from 'antd'
import { Button } from 'components/Button';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

function EditOfflinePaymentModal({ isEditOfflinePaymentModalVisible, setEditOfflinePaymentModalVisible, transactionData, setPaymentModalParamsObj, paymentModalParamsObj, postEditOfflinePayment, togglePageLoader, getCourseStudentsData, isAddDiscountModalVisible, setIsAddDiscountModalVisible, isDiscount, setIsDiscount, isEditOfflinePaymentClicked, postMarkAsPaidOffline }) {
    const handleCancel = () => {
        setEditOfflinePaymentModalVisible(false);
        setPaymentModalParamsObj("discountDetails", []);
        setPaymentModalParamsObj("additionDetails", []);
        setPaymentModalParamsObj("amount", parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)));
        setPaymentModalParamsObj("paymentDate", transactionData?.paymentDate ? transactionData?.paymentDate : null);
        setPaymentModalParamsObj("paymentMethod", transactionData?.payment_method);
        setPaymentModalParamsObj("chequeNo", transactionData?.cheque_no);
        setPaymentModalParamsObj("EditedTotalPay", null);
        setPaymentModalParamsObj("editPaidAmount", null);
        setAmount(parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)));
        setPaymentModalParamsObj("totalPay", transactionData?.amount);
    }

    const [amount, setAmount] = useState(paymentModalParamsObj?.editPaidAmount && paymentModalParamsObj?.editPaidAmount != parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)) ? paymentModalParamsObj?.editPaidAmount : parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)))

    useEffect(() => {
        setPaymentModalParamsObj("amount", parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)));
        if (isEditOfflinePaymentClicked) {
            setPaymentModalParamsObj("paymentDate", transactionData?.paymentDate ? transactionData?.paymentDate : null);
        }
        setPaymentModalParamsObj("paymentMethod", transactionData?.payment_method);
        if (paymentModalParamsObj?.discountDetails?.length == 0) {
            setPaymentModalParamsObj("discountDetails", transactionData?.discount_details ? JSON.parse(transactionData?.discount_details) : []);
        }
        if (paymentModalParamsObj?.additionDetails?.length == 0) {
            setPaymentModalParamsObj("additionDetails", transactionData?.addition_details ? JSON.parse(transactionData?.addition_details) : []);
        }
        setPaymentModalParamsObj("totalPay", transactionData?.amount);
        setPaymentModalParamsObj("chequeNo", transactionData?.cheque_no);
    }, [transactionData?.id])

    useEffect(() => {
        let discountAmount = paymentModalParamsObj?.discountDetails?.length != 0 ? paymentModalParamsObj?.discountDetails?.[0]?.amount : 0;
        let additionAmount = paymentModalParamsObj?.additionDetails?.length != 0 ? paymentModalParamsObj?.additionDetails?.[0]?.amount : 0;
        setPaymentModalParamsObj("totalPay", parseFloat(amount) - parseFloat(discountAmount) + parseFloat(additionAmount));
        setPaymentModalParamsObj("EditedTotalPay", parseFloat(amount) - parseFloat(discountAmount) + parseFloat(additionAmount));
    }, [paymentModalParamsObj?.discountDetails?.length, paymentModalParamsObj?.additionDetails?.length, paymentModalParamsObj?.editPaidAmount]);

    function disabledDate(current) {
        if (current > moment().endOf("day")) {
            return true;
        } else {
            return false;
        }
    }
    const modeOfPaymentArray = [{ label: "CASH", value: "Cash" }, { label: "DEBIT/CREDIT CARD", value: "Debit/Credit card" }, { label: "CHEQUE", value: "Cheque" }, { label: "BANK TRANSFER", value: "Bank transfer" }, { label: "UPI", value: "UPI" }];

    const toggleModeOfPayment = (mode) => {
        setPaymentModalParamsObj("paymentMethod", mode);
    };
    const [btnLoader, setBtnLoader] = useState(false);
    function removeElement(array, index) {
        let tmp = [];
        for (let i = 0; i < array.length; i++) {
            if (i == index) {
            } else {
                tmp.push(array[i]);
            }
        }
        return tmp;
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isEditOfflinePaymentModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            width={550}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>{isEditOfflinePaymentClicked ? "Edit offline payment" : "Mark as paid offline"}</center>
            <div className='m-t-30 r-jsb' style={{ width: "100%" }}>
                <div style={{ width: "48%" }}>
                    <div className='m-b-5' style={{ color: "#8C8C8C" }}>Amount<span style={{ color: 'red' }}> *</span></div>
                    <Input placeholder='Enter amount' prefix="₹" onChange={(e) => {
                        setPaymentModalParamsObj("editPaidAmount", e.target.value);
                        setAmount(e.target.value);
                    }} value={amount} />
                </div>
                <div style={{ width: "48%" }}>
                    <div className='m-b-5' style={{ color: "#8C8C8C" }}>Paid date<span style={{ color: 'red' }}> *</span></div>
                    <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        allowClear={false}
                        className="datePicker"
                        onChange={(date, dateString) => { setPaymentModalParamsObj("paymentDate", moment(dateString, "DD/MM/YYYY")) }}
                        value={paymentModalParamsObj?.paymentDate !== null ? moment(paymentModalParamsObj?.paymentDate, "DD/MM/YYYY") : ""}
                        placeholder={["Select a date"]}
                        style={{ width: 240 }}
                        format={"DD/MM/YYYY"}
                        disabledDate={disabledDate} />
                </div>
            </div>
            <div className='m-t-20' style={{ color: "#8C8C8C" }}>Amount details</div>
            <div className='r-jsb m-t-10'>
                <div style={{ color: "#191919", fontSize: 15 }}>Fee amount</div>
                <div style={{ color: "#191919", fontWeight: "bold" }}>₹ {amount ? parseFloat(amount).toFixed(2) : 0}</div>
            </div>
            {transactionData?.gstApplicable && transactionData?.gst &&
                <div className='r-jsb m-t-5'>
                    <div style={{ color: "#AEAEAE", fontSize: 15 }}>GST</div>
                    <div style={{ color: "#AEAEAE", fontWeight: "bold" }}>+ ₹ {amount ? parseFloat(amount * 0.18).toFixed(2) : 0}</div>
                </div>
            }
            {paymentModalParamsObj?.discountDetails?.length ?
                <>
                    {paymentModalParamsObj?.discountDetails?.map((item, index) => (
                        <div className='r-jsb m-t-5'>
                            <div className='display-flex'>
                                <Typography.Text style={{ width: "100px", color: "#1089FF" }} ellipsis={{ tooltip: true }}>{item?.name}</Typography.Text>
                                <span
                                    className='r-c-c-c'
                                    onClick={() => {
                                        let temp = paymentModalParamsObj?.discountDetails?.length ? paymentModalParamsObj?.discountDetails : [];
                                        let temp1 = removeElement(temp, index);
                                        setPaymentModalParamsObj("discountDetails", temp1);
                                    }}
                                    style={{ color: "red", fontWeight: "500", fontSize: "9px", marginLeft: "5px", border: "1px solid red", borderRadius: "50px", padding: "2px 8px", cursor: "pointer" }}>
                                    Remove
                                </span>
                            </div>
                            <div style={{ color: "#1089FF", fontWeight: "bold" }}>- ₹ {item?.amount}</div>
                        </div>
                    ))}
                </> :
                <div className='r-jsb m-t-5'>
                    <div style={{ color: "#1089FF", fontSize: 15 }}>Discount</div>
                    <div style={{ color: "#1089FF", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setEditOfflinePaymentModalVisible(false); setIsAddDiscountModalVisible(true); setIsDiscount(true) }}>- ₹ ADD</div>
                </div>
            }
            {paymentModalParamsObj?.additionDetails?.length ?
                <>
                    {paymentModalParamsObj?.additionDetails?.map((item, index) => (
                        <div className='r-jsb m-t-5'>
                            <div className='display-flex'>
                                <Typography.Text style={{ width: "100px", color: "#FFA931" }} ellipsis={{ tooltip: true }}>{item?.name}</Typography.Text>
                                <span
                                    className='r-c-c-c'
                                    onClick={() => {
                                        let temp = paymentModalParamsObj?.additionDetails?.length ? paymentModalParamsObj?.additionDetails : [];
                                        let temp1 = removeElement(temp, index);
                                        setPaymentModalParamsObj("additionDetails", temp1);
                                    }}
                                    style={{ color: "red", fontWeight: "500", fontSize: "9px", marginLeft: "5px", border: "1px solid red", borderRadius: "50px", padding: "2px 8px", cursor: "pointer" }}>
                                    Remove
                                </span>
                            </div>
                            <div style={{ color: "#FFA931", fontWeight: "bold" }}>+ ₹ {item?.amount}</div>
                        </div>
                    ))}
                </> :
                <div className='r-jsb m-t-5'>
                    <div style={{ color: "#FFA931", fontSize: 15 }}>Addition</div>
                    <div style={{ color: "#FFA931", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setEditOfflinePaymentModalVisible(false); setIsAddDiscountModalVisible(true); setIsDiscount(false) }}>+ ₹ ADD</div>
                </div>
            }
            <Divider style={{ color: "1px solid #E6E6E6", margin: "10px 0px" }} />
            <div className='r-jsb m-t-10'>
                <div style={{ color: "#191919", fontSize: 15 }}>Total payable</div>
                <div style={{ color: "#191919", fontWeight: "bold" }}>
                    ₹ {amount ? (parseFloat(paymentModalParamsObj?.totalPay) + (transactionData?.gstApplicable ? parseFloat(amount * 0.18) : 0)).toFixed(2) : 0}
                </div>
            </div>
            <Divider style={{ color: "1px solid #E6E6E6", margin: "10px 0px" }} />
            <div className='m-t-20' style={{ color: "#8C8C8C" }}>Mode of payment</div>
            <div className='m-t-10' style={{ display: "flex", flexWrap: "wrap" }}>
                {modeOfPaymentArray?.map((item, index) => (
                    <div className='m-r-10 m-b-10'>
                        <div style={{ height: "fit-content", width: "fit-content", backgroundColor: paymentModalParamsObj?.paymentMethod === item?.value ? "#D6C8F5" : "#FFFFFF", border: paymentModalParamsObj?.paymentMethod === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: paymentModalParamsObj?.paymentMethod === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 15px 5px 15px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleModeOfPayment(item?.value)}>{item?.label}</div>
                    </div>
                ))}
            </div>
            {paymentModalParamsObj?.paymentMethod && paymentModalParamsObj?.paymentMethod != "Cash" ?
                <>
                    <div className='m-t-5 m-b-5' style={{ color: "#8C8C8C" }}>{paymentModalParamsObj?.paymentMethod == "Cheque" ? "Cheque number" : "Transaction ID"}</div>
                    <Input
                        value={paymentModalParamsObj?.chequeNo || paymentModalParamsObj?.chequeNo !== null ? paymentModalParamsObj?.chequeNo : ""}
                        onChange={(e) => { setPaymentModalParamsObj("chequeNo", e.target.value) }}
                        style={{ width: "100%" }}
                        placeholder={`Enter ${paymentModalParamsObj?.paymentMethod == "Cheque" ? "cheque number" : "transaction ID"}`}
                    />
                </> : <></>}
            <center>
                <div className='r-jsb m-t-30' style={{ width: "60%" }}>
                    <div style={{ color: "#636363", fontWeight: "bold", cursor: "pointer" }} onClick={handleCancel}>CANCEL</div>
                    <Button type="primary"
                        loading={btnLoader}
                        disabled={!paymentModalParamsObj?.amount || !paymentModalParamsObj?.paymentDate}
                        onClick={() => {
                            setBtnLoader(true);
                            if (isEditOfflinePaymentClicked) {
                                const params = {
                                    transactionId: transactionData?.id,
                                    amount: parseFloat(paymentModalParamsObj?.EditedTotalPay),
                                    discountDetails: JSON.stringify(paymentModalParamsObj?.discountDetails),
                                    additionDetails: JSON.stringify(paymentModalParamsObj?.additionDetails),
                                    paymentMethod: paymentModalParamsObj?.paymentMethod,
                                    chequeNo: paymentModalParamsObj?.chequeNo,
                                    paymentDate: paymentModalParamsObj?.paymentDate,
                                }
                                postEditOfflinePayment(params,
                                    () => setBtnLoader(false),
                                    () => {
                                        handleCancel()
                                        togglePageLoader(true);
                                        getCourseStudentsData(() => togglePageLoader(false))
                                    })
                            } else {
                                const params = {
                                    transactionId: transactionData?.id,
                                    amount: parseFloat(paymentModalParamsObj?.EditedTotalPay),
                                    discountDetails: JSON.stringify(paymentModalParamsObj?.discountDetails),
                                    additionDetails: JSON.stringify(paymentModalParamsObj?.additionDetails),
                                    paymentMethod: paymentModalParamsObj?.paymentMethod,
                                    chequeNo: paymentModalParamsObj?.chequeNo,
                                    paymentDate: paymentModalParamsObj?.paymentDate,
                                    sendInvoice: true
                                }
                                postMarkAsPaidOffline(params,
                                    () => setBtnLoader(false),
                                    () => {
                                        handleCancel()
                                        togglePageLoader(true);
                                        getCourseStudentsData(() => togglePageLoader(false))
                                    })
                            }
                        }}
                    >SAVE</Button>
                </div>
            </center>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { paymentModalParamsObj } = state.courseManagement;
    return {
        paymentModalParamsObj
    };
};

const mapDispatchToProps = (dispatch) => ({
    postEditOfflinePayment: (params, callback, successCallBack) => dispatch(postEditOfflinePayment(params, callback, successCallBack)),
    postMarkAsPaidOffline: (params, callback, successCallBack) => dispatch(postMarkAsPaidOffline(params, callback, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOfflinePaymentModal);