import { FullscreenOutlined } from '@ant-design/icons'
import { Title, Title2 } from 'components/Typography'
import PieCharts from 'components/pieCharts'
import { Legend } from 'pages/courseManagement/pages/homePage'
import React from 'react'

function CoursePieGraph({ storePieChartData, setPieChartModal, setCurrentPie, colors, courseData, stdColors, studentData }) {
    const courseDetails = storePieChartData?.graph?.pieChart?.courseDetails || [];
    const studentDetails = storePieChartData?.graph?.pieChart?.studentDetails || [];

    const CourseDistLegendArray = courseDetails.map((item, index) => {
        const colors = ["#B59009", "#DAAB00", "#E9CC64", "#F9E7A3"];
        return {
            color: colors[index],
            text: item.subtitle,
            count: item.count
        };
    });

    const StudentsDistLegendArray = studentDetails.map((item, index) => {
        const colors = ["#594099", "#967DD5", "#C3B1EC", "#DACFF2"];
        return {
            color: colors[index],
            text: item.subtitle,
            count: item.count
        };
    });

    return (
        <div>
            <div className='r-c-sb' style={{ marginTop: -7 }}>
                <Title>Course types</Title>
                <FullscreenOutlined className='full-screen-expand-icon' onClick={() => { setPieChartModal(true); setCurrentPie(1); }} />
            </div>
            <div className='r-c-sa' style={{ width: "100%" }}>
                <div style={{ width: "50%" }}>
                    <div className='r-c-c-c'>
                        <div className='pie-chart-size'>
                            <PieCharts color={colors} data={courseData?.length !== 0 ? courseData : []} />
                        </div>
                    </div>
                    <div style={{ marginLeft: "15%" }}>
                        <Title2 style={{ marginBottom: 10, marginTop: 2 }}>Course distribution</Title2>
                        {CourseDistLegendArray.map((item) => (
                            <div className='m-t-5' key={item.text}>
                                <Legend color={item.color} text={item.text} count={item.count} />
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ width: "50%" }}>
                    <div className='r-c-c-c'>
                        <div className='pie-chart-size'>
                            <PieCharts color={stdColors} data={studentData?.length !== 0 ? studentData : []} />
                        </div>
                    </div>
                    <div style={{ marginLeft: "15%" }}>
                        <Title2 style={{ marginBottom: 10, marginTop: 2 }}>Students distribution</Title2>
                        {StudentsDistLegendArray.map((item) => (
                            <div className='m-t-5' key={item.text}>
                                <Legend color={item.color} text={item.text} count={item.count} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoursePieGraph