import { Avatar, Typography, Menu, Dropdown, Tooltip } from 'antd'
import React from 'react'
import { ClockCircleFilled } from '@ant-design/icons'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { Api } from 'services'
import moment from 'moment'
import { SquareSubIcon } from 'components/subIcon'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { connect } from 'react-redux'
import { FaGraduationCap } from 'react-icons/fa'
const avattar = require("../../Assets/Group 11416.svg").default
import { fetchStudentListData, setCardData, setSelectedId } from 'actions/schedule'
import { useHistory } from 'react-router'
import Conference from "Assets/schedule/confrence.svg"
import Broadcast from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import UserAvatar from 'components/userAvatar'
import OtherColor from "Assets/schedule/other_color.svg"



const { Text } = Typography
const CompletedClassCard = ({
    completedItem, user, handleReplay, setSelectedId, setCardData, fetchStudentListData
}) => {
    const CustomMenu = ({ streamId, classMode, subject, isTeacher }) => (
        <Menu style={{ width: "200px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            {
                classMode == "Broadcast"
                    ?
                    <Menu.Item key="1" onClick={() => handleReplay(streamId, subject, completedItem)}>
                        Replay live class
                    </Menu.Item>
                    :
                    null
            }
        </Menu>

    );

    const history = useHistory();


    const markAttendanceHandler = () => {
        setSelectedId(completedItem?.id)
        setCardData(completedItem)
        fetchStudentListData()
        history.push('/schedule-attendance')

    }

    const menu = (
        <Menu style={{ width: "200px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            <Menu.Item key="1" onClick={() => markAttendanceHandler()}>

                Edit Attendance
            </Menu.Item>
        </Menu>

    );

    const markingTimeBool = () => {
        let endTimeMoment = moment(completedItem?.endTime, 'hh:mm:ss');
        let currentTimeMoment = moment();
        let durationSeconds = moment.duration(currentTimeMoment.diff(endTimeMoment)).asSeconds();
        console.log("endTime", completedItem?.endTime, durationSeconds);
        if (durationSeconds < 300) {
            return true
        }
        return false
    }

    return (
        <div className="scheduleclass-main">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="scheduleclass-img-text">
                    <div>
                        <SquareSubIcon icon={completedItem?.subject?.icon ? completedItem?.subject?.icon : ''} name={completedItem?.subject?.name ? completedItem?.subject?.name : ''} size={40} />
                    </div>
                    <div style={{ width: "200px", marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                        {completedItem.mode == "Zoom 40" ?
                            <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                <IoMdVideocam style={{ color: '#1089FF' }} />
                                <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                            </div>
                            : completedItem.mode == "Broadcast" ?
                                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '3px 7px' }}>
                                    <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "16px", width: "16px" }} />
                                    <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                </div>
                                :
                                completedItem.mode == "Conference" ?
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={Conference} style={{ color: '#594099', height: "16px", width: "16px" }} />
                                        <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                    </div>
                                    :
                                    completedItem.mode == 'Others' ?
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                        <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                    </div>
                                    :
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "16px", width: "16px" }} />
                                        <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                    </div>

                        }
                        <Tooltip title={completedItem?.subject?.name ? completedItem?.subject?.name : null}>
                            <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{completedItem?.subject?.name}</Text>
                        </Tooltip>
                        <div style={{ width: '100%',  display:"flex", flexDirection:"row",alignItems:"center",  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>

                            <Tooltip title={completedItem?.standards.length != 0 ? completedItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                                <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
                            </Tooltip>
                            {completedItem?.standards.length != 0 ? completedItem?.standards?.map((item, index, array) => (
                                <Text style={{ fontSize: '80%', fontFamily: 'roboto' }} key={index}>{item?.std + "-" + item?.section}{index === array.length - 1 ? '' : ' |'}</Text>
                            )) : null}
                        </div>
                    </div>
                    {(user.privilage.includes('SuperAdmin') || user.privilage.includes('Admin') || user.role.includes('Data Operator')) && completedItem.mode == 'Broadcast' ?
                        <div>
                            <Dropdown overlay={<CustomMenu streamId={completedItem.roomName} classMode={completedItem.mode} subject={completedItem.subject} isTeacher={true} />} disabled={false} placement="bottomRight" >
                                <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                            </Dropdown>
                        </div> : null}
                    {(user?.privilage?.includes('SuperAdmin')) && (completedItem.mode == 'Offline') ?
                        <div>
                            {completedItem?.attendanceMarked ?
                                <Dropdown overlay={menu} disabled={false} placement="bottomRight" >
                                    <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                                </Dropdown>
                                :
                                null}
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <UserAvatar size="small" shape="circle" name={completedItem?.teacher?.name} img={completedItem?.teacher?.image == "default.png" ? "" : `${Api._s3_url}${completedItem?.teacher?.image}`} />
                    <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{completedItem?.teacher?.name}</Text>
                </div>
                {(user?.privilage?.includes('SuperAdmin') && (completedItem?.mode === 'Offline')) ?
                    <div style={{ marginLeft: '15px' }}>
                        {completedItem?.attendanceMarked ?
                            <div>
                                <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                                <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(completedItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(completedItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                            </div>
                            :
                            <div onClick={markAttendanceHandler} style={{ cursor: 'pointer' }}>
                                <Avatar shape='circle' size='small' src={avattar} />
                                <Text style={{ marginLeft: '5px', color: completedItem?.attendanceMarked ? '#636363' : '#1089FF', fontSize: '9px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold', cursor: 'pointer' }} >{'MARK ATTENDANCE'}</Text>
                            </div>
                        }
                    </div>
                    :
                    (user?.privilage?.includes('AttendanceMarker')) && (completedItem?.mode === 'Offline') && (markingTimeBool()) ?
                        <div onClick={markAttendanceHandler} style={{ cursor: 'pointer' }}>
                            <Avatar shape='circle' size='small' src={avattar} />
                            <Text style={{ marginLeft: '5px', color: completedItem?.attendanceMarked ? '#636363' : '#1089FF', fontSize: '9px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold', cursor: 'pointer' }} >{'MARK ATTENDANCE'}</Text>
                        </div>
                        :
                        <div>
                            <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                            <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(completedItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(completedItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                        </div>

                }

            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const {
    } = state.schedule;
    const { user } = state.session;
    return {
        user
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchStudentListData: (id) => dispatch(fetchStudentListData(id)),
    setSelectedId: (id) => dispatch(setSelectedId(id)),
    setCardData: (value) => dispatch(setCardData(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(CompletedClassCard);