import {
    GET_STANDARDS,
    GET_ANNOUNCEMENT,
    SET_SORT_FILTER,
    SET_SEARCH_FILTER,
    POST_ANNOUNCEMENT_DATA,
    ANNOUNCEMENT_TYPE,
    STORE_ANNOUNCE_PAGE,
    SWITCH_ANNOUNCEMENT_DATA,
    SET_SCHEDULE_PAGE,
    SET_DRAFT_PAGE,SET_ANNOUNCE_KEY,
    SET_STD_FILTER_VALUE
} from '../actions/announcement';

const initialState = {
    standardList: [],
    announcement: {},
    sortFilter: '',
    searchFilter: '',
    storeAnnouncementData: '',
    announcementType: {type: ''},
    storePageData:1,
    draftPage:1,
    schedulePage:1,
    storeAnnouncementvalue:'true',
    storeKey:'',
    selectedStd:null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_STANDARDS:
            return {
                ...state,
                standardList: [...action.std]
            }
        case GET_ANNOUNCEMENT:
            return {
                ...state,
                announcement: action.announcement
            }
        case SET_SORT_FILTER:
            return {
                ...state,
                sortFilter: action.sortData
            }
        case SET_DRAFT_PAGE:
            return{
                ...state,
                draftPage:action.res
            }
        case SET_SCHEDULE_PAGE:
            return{
                ...state,
                schedulePage:action.res
            }
        case SET_SEARCH_FILTER:
            return {
                ...state,
                searchFilter: action.search
            }
        case POST_ANNOUNCEMENT_DATA:
            return {
                ...state,
                storeAnnouncementData: action.res
            }
        case STORE_ANNOUNCE_PAGE :
            return{
                ...state,
                storePageData:action.val
            }
        case SWITCH_ANNOUNCEMENT_DATA:
            return{
                ...state,
                storeAnnouncementvalue:action.res
            }
        case SET_ANNOUNCE_KEY:
            return{
                ...state,
                storeKey:action.val
            }
        case ANNOUNCEMENT_TYPE:
            let announcementTypeNew = {...state.announcementType}

            if(action.key == 'type'){
                announcementTypeNew.type = action.val
            }
            return{
                ...state,
                announcementType: announcementTypeNew
            }
        case SET_STD_FILTER_VALUE :
            return{
                ...state,
                selectedStd : action.val
            }
        default:
            return state

    }
}

export default reducer