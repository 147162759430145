import React, { useEffect, useState } from 'react'
import TopBar from '../topBar'
import Footer from '../footer'
import { ArrowLeftOutlined, CaretRightOutlined, FilterOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import "../coursePageCompo/courseContent.css"
import { Col, Collapse, Divider, Progress, Row, Tooltip } from 'antd'
import physics from "../../../Assets/aulasMarket/sbj-physics.svg"
import { connect } from 'react-redux'
import { Api } from 'services'
import PageHeader from 'components/pageHeader'
import { SquareSubIcon, SubIcon } from 'components/subIcon'
import DropDownCheckBox from 'pages/curriculum/dropDownCheckBox'
import userImg from "../../../Assets/aulasMarket/profileIcon.svg"
import digLibIcon from "../../../Assets/aulasMarket/ic-digital-library.svg"
import practiceIcon from "../../../Assets/aulasMarket/ic-practice.svg"
import LiveTestIcon from "../../../Assets/aulasMarket/ic-test.svg"
import { getChapterDataCourseContent, getSubjectsDataCourseContent } from 'actions/studentMarketPlace'





const PanelContent = ({ courseChapter }) => {
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const itemsToShow = showAll ? courseChapter?.length : 10;

    const progressFilterArray = [
        {
            label: 'Completed',
            id: 'Completed'
        },
        {
            label: 'Pending',
            id: 'Pending'
        },
        {
            label: 'Not Started',
            id: 'Not Started'
        },
    ]
    const [progressFilter, setProgressFilter] = useState(['Pending', 'Completed', 'Not Started'])
    const onProgressFilterSelect = (item) => {
        if (progressFilter.includes(item.id))
            setProgressFilter(prev => prev.filter(el => el != item.id))
        else setProgressFilter(prev => [...prev, item.id])
    }
    return (
        <>
            <div style={{ color: "#636363" }}>
                <Row style={{ fontWeight: 600, color: "#636363", minHeight: 40 }} className="r-ac">
                    <Col offset={1} span={3}>Chapter</Col>
                    <Col span={6}>Name</Col>
                    {/* <Col span={5} className="r-c-sb">
                        Progress
                        <DropDownCheckBox
                            placeholder={<FilterOutlined style={{ cursor: 'pointer' }} />}
                            DATA={progressFilterArray}
                            checkedArray={progressFilter}
                            onChange={onProgressFilterSelect}
                        />
                    </Col>
                    <Col offset={1} span={3}>Updated by</Col>
                    <Col offset={1} span={2}>Related Content</Col> */}
                </Row>

            </div>

            {courseChapter?.slice(0, itemsToShow).map((item, index) => (
                <div key={index} style={{ color: "#636363" }}>
                    <Row
                        style={{
                            fontWeight: "normal",
                            color: "#636363",
                            padding: 10,
                            borderBottom: "1px solid #ddd",
                        }}
                        className="r-ac"
                    >
                        <Col offset={1} span={3}>
                            {index + 1}
                        </Col>
                        <Col span={6}>
                            {item?.name}
                        </Col>
                        {/* <Col span={5} className="r-c-sb">
                            <Progress />
                        </Col>
                        <Col offset={1} span={4}>
                            <img src={userImg} style={{width:30 , height:30,marginRight:5}} />
                            <span style={{color:"#636363",fontSize:"14px"}}>Rosie C. Carrasco</span>
                        </Col>
                        <Col  span={4} className='r-c-sa'>
                            <div>
                                <Tooltip title="Digital library">
                                <img src={digLibIcon} style={{cursor:"pointer",marginRight:5}} />
                                </Tooltip>
                                <span>2</span>
                            </div>
                            <div>
                            <Tooltip title="Practice test">
                            <img src={practiceIcon} style={{cursor:"pointer",marginRight:5}} />
                            </Tooltip>
                                <span>2</span>
                            </div>
                            <div>
                            <Tooltip title="Live test">
                            <img src={LiveTestIcon} style={{cursor:"pointer",marginRight:5}} />
                            </Tooltip>
                                <span>2</span>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            ))}
            {courseChapter?.length > 10 ?
                <div className="r-c-c font-bold" style={{ color: "#1089FF", cursor: "pointer", margin: 10 }}>
                    <div style={{ cursor: "pointer" }} onClick={toggleShowAll}>
                        {showAll ? "VIEW LESS" : "VIEW ALL"}
                    </div>
                </div>
                : null}
        </>
    );
};

function CourseContentList({ singleCourseDetailsDataContainer, getChapterDataCourseContent, collapseChapterList, storeStudentStandard }) {
 
    const history = useHistory();
    const [isExpended, setExpended] = useState(false);
    const [activePanels, setActivePanels] = useState([]);
    const location = useLocation();
    const [singleCourseChapterList, setSingleCourseChapterList] = useState([]);
    const [progressFilter, setProgressFilter] = useState([])

    const chapterParam = {
        standardId: location?.state?.standardId,
        subjectId: location?.state?.subjectId,
        progressType: progressFilter === 'ALL' ? undefined : progressFilter,
    };

    useEffect(() => {
        if (singleCourseDetailsDataContainer?.studentCourseStandards?.length > 0) {
            getChapterDataCourseContent(chapterParam, (res) => {
                console.log("calllllcccc", res);
                setSingleCourseChapterList(res);
            });
        }
    }, [progressFilter])


    console.log("paaaaaadddd",progressFilter, chapterParam );
    const PanelContent = ({ courseChapter }) => {

        const [showAll, setShowAll] = useState(false);
        const toggleShowAll = () => {
            setShowAll(!showAll);
        };
        const itemsToShow = showAll ? courseChapter?.length : 10;

        const progressFilterArray = [
            {
                label: 'Completed',
                id: 'completed'
            },
            {
                label: 'Pending',
                id: 'pending'
            },
            {
                label: 'Not Started',
                id: 'not started'
            },
        ]
        
        const onProgressFilterSelect = (item) => {
            console.log("fiiiillll",item)
           
            if (progressFilter.includes(item.id))
                setProgressFilter(prev => prev.filter(el => el != item.id))
            else setProgressFilter(prev => [...prev, item.id])
        }
        return (
            <>
                <div style={{ color: "#636363" }}>
                    <Row style={{ fontWeight: 600, color: "#636363", minHeight: 40 }} className="r-ac">
                        <Col offset={1} span={3}>Chapter</Col>
                        <Col span={6}>Name</Col>
                        {singleCourseDetailsDataContainer?.studentCourseStandards?.length > 0 ? <>
                            <Col span={5} className="r-c-sb">
                                Progress
                                <DropDownCheckBox
                                    placeholder={<FilterOutlined style={{ cursor: 'pointer' }} />}
                                    DATA={progressFilterArray}
                                    checkedArray={progressFilter}
                                    onChange={onProgressFilterSelect}
                                />
                            </Col>
                            <Col offset={1} span={3}>Updated by</Col>
                            <Col offset={1} span={2}>Related Content</Col>
                        </> : <></>}
                    </Row>

                </div>

                {courseChapter?.slice(0, itemsToShow).map((item, index) => (
                    <div key={index} style={{ color: "#636363" }}>
                        <Row
                            style={{
                                fontWeight: "normal",
                                color: "#636363",
                                padding: 10,
                                borderBottom: "1px solid #ddd",
                            }}
                            className="r-ac"
                        >
                            <Col offset={1} span={3}>
                                {index + 1}
                            </Col>
                            <Col span={6}>
                                {item?.name}
                            </Col>
                            {singleCourseDetailsDataContainer?.studentCourseStandards?.length > 0 ? <>
                                <Col span={5} className="r-c-sb">
                                    <Progress percent={item?.progress} />
                                </Col>
                                <Col offset={1} span={4}>
                                    {item?.actionBy?.name ?
                                        <>
                                            <img src={`${Api._s3_url}${item?.actionBy?.image}`}  style={{ width: 30, height: 30, marginRight: 5,borderRadius:"100px" }} />
                                            <span style={{ color: "#636363", fontSize: "14px" }}>{item?.actionBy?.name}</span>
                                        </> : <>
                                        </>}
                                </Col>
                                <Col span={4} className='r-c-sa'>
                                    <div>
                                        <Tooltip title="Digital library">
                                            <img src={digLibIcon} style={{ cursor: "pointer", marginRight: 5 }} />
                                        </Tooltip>
                                        <span>{item?.digitalLibrary}</span>
                                    </div>
                                    <div>
                                        <Tooltip title="Live test">
                                            <img src={LiveTestIcon} style={{ cursor: "pointer", marginRight: 5 }} />
                                        </Tooltip>
                                        <span>{item?.tests}</span>
                                    </div>
                                </Col>
                            </> : <></>}
                        </Row>
                    </div>
                ))}
                {courseChapter?.length > 10 ?
                    <div className="r-c-c font-bold" style={{ color: "#1089FF", cursor: "pointer", margin: 10 }}>
                        <div style={{ cursor: "pointer" }} onClick={toggleShowAll}>
                            {showAll ? "VIEW LESS" : "VIEW ALL"}
                        </div>
                    </div>
                    : null}
            </>
        );
    };
    const PanelHeader = ({ chaptersName }) => (
        <Row className="r-c-sb" style={{ padding: "10px" }}>
            <span className='r-c-c' style={{ fontSize: 16, fontWeight: "600", color: "#636363" }}>
                {chaptersName?.icon ?
                    <img style={{ width: 45, height: 35, marginRight: 10 }} src={`${Api._s3_url}${chaptersName?.icon?.iconPath ? chaptersName?.icon?.iconPath : chaptersName?.icon}`} alt="Physics" />
                    :
                    <span style={{ width: 45, height: 35, marginRight: 10 }}>
                        <SquareSubIcon name={chaptersName?.name} size={20} />
                    </span>
                }
                {chaptersName?.name}
            </span>
            <span className="r-c-c" style={{ fontSize: 16, color: "#AEAEAE", fontWeight: "normal" }}>
                {chaptersName?.courseChapter?.length ||singleCourseChapterList?.chapters?.length || chaptersName?.testSeriesChapter?.length} Chapters
            </span>
        </Row>
    );
    const handlePanelChange = (panelKey) => {
        setActivePanels((prevPanels) =>
            prevPanels.includes(panelKey)
                ? prevPanels.filter((key) => key !== panelKey)
                : [...prevPanels, panelKey]
        );
    };
    const ChapterList = ({ chapList, index }) => {
        return (
            <div>
                {chapList?.map((i, index) => (
                    <div className='chapter-list-mobile-container'>
                        {console.log("chapLissiiii", i)}
                        <div className='m-r-20' style={{ fontSize: "14px", color: "#8C8C8C" }}>{index + 1}</div>
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>{i?.name}</div>
                    </div>
                ))}
            </div>
        )
    }

    console.log('zzzz',singleCourseDetailsDataContainer);
    return (
        <div>

            <div className='course-content-parent' style={{ backgroundColor: "#FAFAFAFA", padding: "20px 20px" }}>
                <PageHeader title={location?.state?.fromTest?"Syllabus":"Course content" } onBackPress={() => { history.goBack(); }} />
                <div className='chapter-content-header r-c-fs m-t-20'>
                </div>

                {location?.state?.fromTest

                ?
                
                <div className='chapter-container-parent'>
                {singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries?.length > 0 ? <>
                    {singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries?.map((item, index) => (
                            <Collapse
                                key={index}
                                accordion={false}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: 15 }} />
                                )}
                                style={{
                                    marginBottom: 20,
                                    borderRadius: 10,
                                }}
                                onChange={() => handlePanelChange(index)}
                                activeKey={activePanels}
                            >
                                <Collapse.Panel
                                    header={<PanelHeader chaptersName={item?.subject} />}
                                    key={index}
                                >
                                    <PanelContent courseChapter={item?.subject?.testSeriesChapter} />
                                </Collapse.Panel>
                            </Collapse>
                        ))}
                </> :
                    <>
                    </>}
            </div>
                :
                <div className='chapter-container-parent'>
                    {singleCourseDetailsDataContainer?.studentCourseStandards?.length > 0 ? <>
                        {singleCourseChapterList?.subject &&
                            <Collapse
                                key={singleCourseChapterList?.subject?.id}
                                accordion={false}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: 15 }} />
                                )}
                                style={{
                                    marginBottom: 20,
                                    borderRadius: 10,
                                }}
                                onChange={() => handlePanelChange(singleCourseChapterList?.subject?.id)}
                                activeKey={activePanels}
                            >
                                <Collapse.Panel
                                    header={<PanelHeader chaptersName={singleCourseChapterList?.subject} />}
                                    key={singleCourseChapterList?.subject?.id}
                                >
                                    <PanelContent courseChapter={singleCourseChapterList?.chapters} />
                                </Collapse.Panel>
                            </Collapse>
                        }
                    </> :
                        <>
                            {singleCourseDetailsDataContainer?.course?.subjects?.map((item, index) => (
                                <Collapse
                                    key={index}
                                    accordion={false}
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: 15 }} />
                                    )}
                                    style={{
                                        marginBottom: 20,
                                        borderRadius: 10,
                                    }}
                                    onChange={() => handlePanelChange(index)}
                                    activeKey={activePanels}
                                >
                                    <Collapse.Panel
                                        header={<PanelHeader chaptersName={item} />}
                                        key={index}
                                    >
                                        <PanelContent courseChapter={item?.courseChapter} />
                                    </Collapse.Panel>
                                </Collapse>
                            ))}
                        </>}
                </div>
                }
                
            </div>
            <div className='course-content-parent-mobile'>
                {location?.state?.fromTest
                
                ?
                <div className='chapter-container-parent-mobile'>
                    {singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries?.map((item, index) => (
                        <>
                            <ChapterList chapList={item?.subject?.testSeriesChapter} />
                        </>
                    ))}
                </div>
                :
                <div className='chapter-container-parent-mobile'>
                    {singleCourseDetailsDataContainer?.course?.subjects?.map((item, index) => (
                        <>
                            <ChapterList chapList={item?.courseChapter} />
                        </>
                    ))}
                </div>
                }
                
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { singleCourseDetailsDataContainer,storeStudentStandard } = state.studentMarketPlace;
    return { singleCourseDetailsDataContainer,storeStudentStandard };
};

const mapDispatchToProps = (dispatch) => ({
    getSubjectsDataCourseContent: (id, callback) => dispatch(getSubjectsDataCourseContent(id, callback)),
    getChapterDataCourseContent: (param, callback) => dispatch(getChapterDataCourseContent(param, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseContentList);
