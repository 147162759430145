import React, { useState } from 'react';
import { Button, Modal, Radio } from 'antd';

const DeleteModalOffer = ({ chooseModal, setChooseModal, deleteOffer, getSpecificCourseData, selectedOfferId ,setAddFeeTermPlan,getSpecificVideoCourseData,courseTypeName,getSpecificSMData,deleteBroadcastRecording,modalTrue,roomName,setRecordingsModalVisibility,getOnlineTestSeriesCourseDetails, togglePageLoader}) => {
    const [loader,setLoader] =  useState(false)
    const handleOK = () => {
     
        if(courseTypeName == 'liveCourses'){
            deleteOffer(
                selectedOfferId,
                () => { },
                () => {
                    setChooseModal(false);
                    if(togglePageLoader){
                        togglePageLoader(true);
                    }
                    getSpecificCourseData(() => {if(togglePageLoader){togglePageLoader(false)}setAddFeeTermPlan(false) })
                }
            )
        }else if(courseTypeName == 'studyMaterials'){
            deleteOffer(
                selectedOfferId,
                () => { },
                () => {
                    setChooseModal(false);
                    if(togglePageLoader){
                        togglePageLoader(true);
                    }
                    getSpecificSMData(() => {if(togglePageLoader){
                        togglePageLoader(false);
                    }})
                }
            ) 
        }else if (modalTrue == 'digital library') {
            setLoader(true)
            deleteBroadcastRecording(roomName,()=>{setLoader(false)},()=>{setRecordingsModalVisibility(false),
                setChooseModal(false)
            })
        }
        else if (courseTypeName == 'videoResources'){
            deleteOffer(
                selectedOfferId,
                () => { },
                () => {
                    setChooseModal(false);
                    if(togglePageLoader){
                        togglePageLoader(true);
                    }
                    getSpecificVideoCourseData(() => {if(togglePageLoader){
                        togglePageLoader(false);
                    } })
                }
            ) 
        }
        else{
            deleteOffer(
                selectedOfferId,
                () => { },
                () => {
                    setChooseModal(false);
                    if(togglePageLoader){
                        togglePageLoader(true);
                    }
                    getOnlineTestSeriesCourseDetails(() => {if(togglePageLoader){
                        togglePageLoader(false);
                    }})
                }
            ) 
        }
        
    };

    const handleCancel = () => {
        setChooseModal(false);
        if (modalTrue == 'digital library') {
            setRecordingsModalVisibility(true)

        }
    };


    return (
        <>

            <Modal
                className="modal-round-corner"
                open={chooseModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={false}
                bodyStyle={{ borderRadius: "10px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Confirm</div>
                <div
                    style={{
                        fontSize: "16px",
                        color: "#636363",
                        fontWeight: "bold",
                        marginTop: "10px",
                        textAlign: 'center'
                    }}
                >
                    Are you sure you want to delete ?

                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                border: "1px solid  #636363",
                                backgroundColor: "#FFFFFF",
                                color: "#594099",
                            }}
                            onClick={handleCancel}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#636363",
                                    fontWeight: "bold",
                                }}
                                
                            >
                                NO
                            </div>
                        </Button>
                    </div>
                    <div>
                        <Button
                        loading={loader}
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                border: "1px solid #594099 ",
                                backgroundColor: "#594099",
                                fontSize: "16px",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                            }}
                            onClick={() => { handleOK() }}

                        >
                            YES
                        </Button>
                    </div>
                </div>
            </Modal>

        </>
    );
};
export default DeleteModalOffer;