import PageHeader from 'components/pageHeader';
import React from 'react';
import { useHistory } from 'react-router';
const Logo = require('Assets/logo.svg').default;


const AboutAulas = () => {
    const history = useHistory();
    return (
        <div style={{height: "100%"}}>
            <PageHeader title="About aulas" onBackPress={() => history.goBack()}/>
            <div className='r-c-c m-t-100'>
                <img src={Logo} height={120} />
            </div>
            <div className='p-30 text-justify' style={{fontSize: 16, color: "#636363"}}>
            An online learning infrastructure in the form of a single ecosystem comprising of all facilities required to readily integrate on existing learning systems. Our live classroom enables seamless-student teacher interaction with the help of features interactive like hand-raise, touch-to-talk, chat-box, digital white-board that will provide the students and the teacher with an ‘in class’ experience. Besides, there are a host of other features for overall management of the institute like attendance portal, assignment & test portal, digital library, planning and scheduling, fee management, faculty management, student progress and analytics, parent teacher meetings and much more.
            </div>
        </div>
    );
}

export default AboutAulas;
