import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";

const UsersWorkSummMonthGraph = ({color,data,usersData}) => {
   
  const config = {
    data,
    xField:"month",
    yField: "duration",
    color:"#9876E6",
    tooltip:
    {customContent:(val ,item)=>{
      console.log("item",item)
      return(
        <div style={{width:"fit-content",height:"70px",padding:"10px",flexDirection: 'row'}}>
        <div style={{fontSize: '11px',fontFamily:"Roboto",}}>WORKING HOURS</div>
        <div style={{fontSize:"14px",fontWeight:"bold",marginTop:"5px"}}>
          {item[0]?.data?.duration!==null?item[0]?.data?.duration:"0:0"}</div>
        <div style={{fontSize: '11px',fontFamily:"Roboto",marginTop:"7px",}}>
        {item[0]?.data?.percentageThanPrevDay!==null?item[0]?.data?.percentageThanPrevDay:"0:0"}% than previous month</div>
      </div>
      )
    }},

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Attendance",
      },
      // sales: {
      //   alias: "Attendance",
      // },
    },
    minColumnWidth: 35,
    maxColumnWidth: 35,
  };

  
  return <Column {...config} />;
};
export default UsersWorkSummMonthGraph;