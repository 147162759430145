import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
// import { Button } from 'antd';
import React from 'react';
import { Color } from '../services';




const NumericInput = ({value, onAdd, onSub, max, min, onChange, disabled}) => {


  return (
    <div className='r-jsb r-ac' style={{border: '2px solid #D9D9D9', borderRadius: 30, padding: 5}}>
      <MinusCircleFilled style={{color: Color.primary, fontSize: 30}} onClick={(e) => onSub(e)}/>
      <input type='number' value={value} style={{width: '2vw', border: 0, textAlign: 'right' }} disabled={disabled} onChange={(e) => onChange(e.target.value)} />/ {max}
      <PlusCircleFilled style={{color: Color.primary, fontSize: 30, marginLeft: 5}} onClick={(e) => onAdd(e)}/>
    </div>
  )
}

export default NumericInput
