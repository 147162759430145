import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { deleteHolidayData } from 'actions/attendanceNew';
const HolidayDeleteModal = ({ holidayDeleteModal, setHolidayDeleteModal, id, deleteHolidayData }) => {
  const [holidayDeleteId, setHolidayDeleteId] = useState(id)

  const handleOk = () => {
    setHolidayDeleteModal(false);
  };
  const handleCancel = () => {
    setHolidayDeleteModal(false);
  };
  return (
    <>
      <Modal
        className="modal-round-corner"
        open={holidayDeleteModal} onOk={handleOk} onCancel={handleCancel} footer={false}>
        <div style={{
          fontFamily: "Roboto",
          textAlign: "center",
          color: "#594099",
          marginBottom: "0px",
          fontSize: "25px",
          fontWeight: "bold",
        }}>Confirm</div>
        <div style={{
          fontFamily: "Roboto",
          textAlign: "center",
          color: "#636363",
        }}>Are you sure you want to Delete ?</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "50px",
          }}
        >
          <div>
            <Button
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#FFFFFF",
                border: "1px solid #636363 ",
                color: "#636363",
              }}
              onClick={handleCancel}

            >
              CANCEL
            </Button>
          </div>
          <div>
            <Button
              // loading={holidayCreateLoader}
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#594099",
                color: "#FFFFFF",
                //   opacity: (createHolidayList.title == '' || createHolidayList.date == '') ? 0.5 : 1,
              }}
              onClick={() => {
                setHolidayDeleteModal(false)
                //  setHolidayDeleteId(id)
                deleteHolidayData(holidayDeleteId, () => window.location.reload());
              }}
            >
              DELETE
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {

};

const mapDispatchToProps = (dispatch) => ({
  deleteHolidayData: (holidayDeleteId, callBack) => dispatch(deleteHolidayData(holidayDeleteId, callBack)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayDeleteModal);