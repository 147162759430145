import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Radio,
  Input,
  Select,
  Search,
  AutoComplete,
  Typography,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  fetchTransaction,
  generatePayRequest,
  getFeeOverView,
  getTransactionsOfStudent,
  getUserOfClass,
  postOfflineTransaction,
  setAllActivitiesFilter,
  setFeeDetails,
  setStudentName,
} from "actions/feeManagement";
import Paid_date from "../userManagement/editPaidDate";
import feeCalender from "./feeCalender";

// import Search from "antd/lib/transfer/search";

function OfflinePayReq({
  isModalVisible,
  setIsModalVisible,
  setShowAddDetailsModal,
  setIsAddOrGenModal,
  setIsAdditionModal,
  setFeeDetails,
  feeMangementData,
  getUserOfClass,
  generatePayRequest,
  setAllActivitiesFilter,
  getFeeOverView,
  fetchTransaction,
  getTransactionsOfStudent,
  postOfflineTransaction,
  setShowReasonModal,
  setStudentName,
  studentName
}) {
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [value, setValue] = React.useState(1);
  const [selectedBtn, setSelectedBtn] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [optionStudent, setOptionStudent] = useState([]);
  const [optionDesc, setOptionDesc] = useState([]);
  // const [studentName, setStudentName] = useState(null);
  const [studentDesc, setStudentDesc] = useState(null);
  const [autoSearchLoading, setAutoSearchLoading] = useState(false);
  const [descAutoSearchLoading, setDescAutoSearchLoading] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const [studentDescId, setStudentDescId] = useState(null);
  const [addLdr, setAddLdr] = useState(false);
  const [apiCall, toggleApiCall] = useState(false);
  const [paidDate, setPaidDate] = useState(null);

  // useEffect(() => {
  // setFeeDetails("paidDate", paidDate);
  // }, [paidDate]);
  console.log("AA-fee", feeMangementData);
  function removeElement(array, index) {
    let tmp = [];
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
      } else {
        tmp.push(array[i]);
      }
    }
    return tmp;
  }
  const isJsonOrString = (temp) => {
    try {
      JSON.parse(temp);
      return true;
    } catch (error) {
      return false;
    }
  };
  const searchResult = (query) =>
    query?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{item.name}</span>
          </div>
        ),
      };
    });
  const searchResultDesc = (query) =>
    query?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{item.note}</span>
          </div>
        ),
      };
    });
  const checkDescMatch = () => {
    for (
      let i = 0;
      i < feeMangementData?.allTransactionsOfStudent?.length;
      i++
    ) {
      if (
        feeMangementData?.allTransactionsOfStudent?.[i]?.note ==
        feeMangementData?.selectedStudentDesc
      ) {
        toggleApiCall(true);
      }
    }
  };
  useEffect(() => {
    checkDescMatch();
  }, [feeMangementData?.selectedStudentDesc]);
  useEffect(() => {
    setOptionStudent(
      feeMangementData?.allStudents
        ? [...searchResult(feeMangementData?.allStudents)]
        : []
    );
  }, [feeMangementData?.allStudents]);
  useEffect(() => {
    setOptionDesc(
      feeMangementData?.allTransactionsOfStudent
        ? [...searchResultDesc(feeMangementData?.allTransactionsOfStudent)]
        : []
    );
  }, [feeMangementData?.allTransactionsOfStudent]);
  useEffect(() => {
    let additionAmount = feeMangementData?.generatePayAdditions
      ? calcSum(feeMangementData?.generatePayAdditions)
      : 0;
    let discountAmount = feeMangementData?.generatePayDiscount
      ? calcSum(feeMangementData?.generatePayDiscount)
      : 0;
    let totalAmount = feeMangementData?.generateOfflinePayReqAmount
      ? parseFloat(feeMangementData?.generateOfflinePayReqAmount)
      : 0;
    let finalPay = totalAmount - discountAmount + additionAmount;
    if (feeMangementData?.selectedSingleTransaction?.amountPayable) {
      setFeeDetails(
        "finalFeeRequested",
        feeMangementData?.selectedSingleTransaction?.amountPayable
      );
      // setFeeDetails(
      //   "finalFeeRequestedWithGst",
      //   feeMangementData?.selectedSingleTransaction?.amountPayable
      // );
    } else {
      if (feeMangementData?.dashBoardDetails?.gst) {
        let temp = finalPay ? finalPay * 0.18 : 0;
        temp = parseFloat(temp + finalPay);
        setFeeDetails("finalFeeRequestedWithGst", temp);
      } else {
        setFeeDetails("finalFeeRequestedWithGst", finalPay);
      }
      setFeeDetails("finalFeeRequested", finalPay);
    }
  }, [
    feeMangementData?.generatePayAdditions,
    feeMangementData?.generatePayDiscount,
    feeMangementData?.generateOfflinePayReqAmount,
  ]);
  useEffect(() => {
    // if (feeMangementData?.selectedStudentDescId && apiCall) {
    if (feeMangementData?.selectedStudentDescId && apiCall) {
      let temp = feeMangementData?.allTransactionsOfStudent?.filter(
        (item) => item.id == feeMangementData?.selectedStudentDescId
      );
      // let temp = [];
      setFeeDetails("selectedSingleTransaction", temp?.[0]);
      setFeeDetails("finalFeeRequested", temp?.[0]?.amountPayable);
      // setFeeDetails("finalFeeRequestedWithGst", temp?.[0]?.amountPayable);

      setFeeDetails(
        "generateOfflinePayReqAmount",
        temp?.[0]?.amount -
        temp?.[0]?.total_addition +
        temp?.[0]?.total_discount
      );

      setFeeDetails(
        "generatePayAdditions",
        isJsonOrString(temp?.[0]?.addition_details)
          ? JSON.parse(temp?.[0]?.addition_details)
          : []
      );
      setFeeDetails(
        "generatePayDiscount",
        isJsonOrString(temp?.[0]?.discount_details)
          ? JSON.parse(temp?.[0]?.discount_details)
          : []
      );
      setFeeDetails("finalFeeRequested", temp?.[0]?.amountPayable);
      let newVar =
        temp?.[0]?.transactionFee && temp?.[0]?.transactionPaidBy != "institute"
          ? temp?.[0]?.transactionFee
          : 0;
      // console.log('apiCall',apiCall,feeMangementData?.selectedStudentDescId);
      setFeeDetails(
        "finalFeeRequestedWithGst",
        temp?.[0]?.amountPayable - newVar
      );
    } else {
      // setFeeDetails("selectedSingleTransaction", {});
      // setFeeDetails("finalFeeRequested", null);
      // setFeeDetails("generateOfflinePayReqAmount", null);
      // setFeeDetails("generatePayAdditions", null);
      // setFeeDetails("generatePayDiscount", null);
    }
    // }
  }, [feeMangementData?.selectedStudentDescId, apiCall]);

  useEffect(() => {
    setFeeDetails("modeOfPay", "Cash");

    setIsAdditionModal(false);
    setIsAddOrGenModal(true);
  }, []);
  const handleChangeStudent = (val, obj) => {
    if (val) {
      setStudentName(obj?.label?.props?.children?.props?.children);
      setFeeDetails(
        "selectedStudentName",
        obj?.label?.props?.children?.props?.children
      );
    } else {
      setStudentName(null);
      setFeeDetails("selectedStudentName", null);
      setFeeDetails("selectedStudentDesc", null);
      setFeeDetails("selectedStudentDescId", null);
    }
  };
  const handleChangeDesc = (val, obj) => {
    if (val) {
      setStudentDesc(obj?.label?.props?.children?.props?.children);
      setFeeDetails("selectedStudentDesc", val);
      toggleApiCall(false);
      checkDescMatch();
      setFeeDetails("selectedSingleTransaction", {});
      setFeeDetails("finalFeeRequested", null);
      setFeeDetails("finalFeeRequestedWithGst", null);
      setFeeDetails("generateOfflinePayReqAmount", null);
      setFeeDetails("generatePayAdditions", null);
      setFeeDetails("generatePayDiscount", null);
      // setFeeDetails("selectedStudentDesc", null);
    } else {
      setStudentDesc(null);
      setFeeDetails("selectedSingleTransaction", {});
      setFeeDetails("finalFeeRequested", null);
      setFeeDetails("finalFeeRequestedWithGst", null);
      setFeeDetails("generateOfflinePayReqAmount", null);
      setFeeDetails("generatePayAdditions", null);
      setFeeDetails("generatePayDiscount", null);
      setFeeDetails("selectedStudentDesc", null);
    }
  };
  const onSelectStudent = (val, obj) => {
    if (val) {
      setStudentId(val);
      setFeeDetails("selectedStudentId", [val]);
      setStudentName(obj?.label?.props?.children?.props?.children);
    } else {
      setStudentId(null);
      setStudentName(null);
      setFeeDetails("selectedStudentId", null);
    }
    getTransactionsOfStudent(() => setDescAutoSearchLoading(false), "");
    setFeeDetails("allTransactionsOfStudent", []);
    setFeeDetails("selectedStudentDesc", null);
    setFeeDetails("selectedStudentDescId", null);
  };
  const onSelectStudentDesc = (val, obj) => {
    if (val) {
      if (obj.key != -1) {
        setStudentDescId(val);
        setFeeDetails("selectedStudentDescId", val);
        setStudentDesc(obj?.label?.props?.children?.props?.children);
        setFeeDetails(
          "selectedStudentDesc",
          obj?.label?.props?.children?.props?.children
        );
      } else {
        // setStudentDesc(obj?.label?.props?.children?.props?.children);
        setFeeDetails("selectedStudentDesc", val?.replace("create ", ""));
      }
    } else {
      setStudentDescId(null);
      setStudentDesc(null);
      setFeeDetails("selectedStudentDescId", null);
    }
  };

  const handleSearchStudent = (val) => {
    if (val) {
      setStudentName(val);
    } else {
      setStudentName(null);
    }
    setAutoSearchLoading(true);
    getUserOfClass(() => setAutoSearchLoading(false));
    setFeeDetails("selectedStudentDesc", null);
    setFeeDetails("selectedStudentDescId", null);
  };
  const handleSearchStudentDesc = (val) => {
    if (val) {
      setStudentDesc(val);
    } else {
      setStudentDesc(null);
    }
    setDescAutoSearchLoading(true);
    // getUserOfClass(() => setAutoSearchLoading(false), studentName);
    // setFeeDetails("selectedStudentDescId", null);

    getTransactionsOfStudent(
      () => setDescAutoSearchLoading(false),
      studentDesc
    );
  };
  const calcSum = (temp) => {
    let sum = 0;
    for (let i = 0; i < temp?.length; i++) {
      sum = sum + parseFloat(temp[i].amount);
    }
    return sum;
  };
  // useEffect(() => {}, [third]);
  const [sendInvoice, setSendInvoice] = useState(feeMangementData?.dashBoardDetails?.invoiceType == "automatic" ? true : false)
  // useEffect(() => {
  //   setSendInvoice(feeMangementData?.selectedSingleTransaction?.sendInvoice)
  // }, [feeMangementData?.selectedSingleTransaction?.sendInvoice])

  return (
    <div style={{ fontFamily: "roboto" }}>
      <Modal
        maskClosable={false}
        className="generatePayModalB"
        okText={"ADD"}
        cancelText={"CANCEL"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{
          loading: addLdr,
          disabled: !(
            feeMangementData?.generateOfflinePayReqAmount &&
            feeMangementData?.selectedClassId &&
            feeMangementData?.selectedStudentName &&
            feeMangementData?.paidDate &&
            feeMangementData?.selectedStudentDesc?.trim()?.length != 0

          ),
        }}
        onOk={() => {
          if (feeMangementData?.dashBoardDetails?.kycStatus !== 'Approved') {
            setShowReasonModal(true);
            setIsModalVisible(false);
          } else {
            checkDescMatch();
            setIsAddOrGenModal(false);

            setAddLdr(true);

            if (feeMangementData?.selectedStudentDescId && apiCall) {
              postOfflineTransaction(
                sendInvoice,
                () => {
                  setAddLdr(false);
                },
                () => {
                  setIsModalVisible(false);
                  setFeeDetails("selectedStudentDesc", null);
                  setFeeDetails("selectedStudentDescId", null);
                  setFeeDetails("selectedSingleTransaction", []);
                  setIsModalVisible(false);
                  setFeeDetails("generatePayDate", null);
                  setFeeDetails("generatePayDescription", null);
                  setFeeDetails("generatePayReqAmount", null);
                  setFeeDetails("generatePayAdditions", null);
                  setFeeDetails("generatePayDiscount", null);

                  ////
                  setFeeDetails("generatePayDate", null);
                  setFeeDetails("generateOfflinePayDescription", null);
                  setFeeDetails("generateOfflinePayReqAmount", null);
                  setFeeDetails("generatePayAdditions", null);
                  setFeeDetails("generatePayDiscount", null);
                  setFeeDetails("modeOfPay", null);
                  setFeeDetails("selectedClassId", null);
                  setFeeDetails("selectedStudentName", null);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setAllActivitiesFilter("userId", null);
                  setFeeDetails("selectedStudentDesc", null);
                  setFeeDetails("selectedStudentDescId", null);
                  setFeeDetails("finalFeeRequestedWithGst", null);
                  setFeeDetails("selectedSingleTransaction", []);
                  setStudentName(null)

                  getFeeOverView();
                  fetchTransaction();
                }
              );
            } else {
              generatePayRequest(
                1, sendInvoice,
                () => {
                  setAddLdr(false);
                },
                () => {
                  setIsModalVisible(false);
                  setFeeDetails("generatePayDate", null);
                  setFeeDetails("generatePayDescription", null);
                  setFeeDetails("generatePayReqAmount", null);
                  setFeeDetails("generatePayAdditions", null);
                  setFeeDetails("generatePayDiscount", null);

                  ////
                  setFeeDetails("generatePayDate", null);
                  setFeeDetails("generateOfflinePayDescription", null);
                  setFeeDetails("generateOfflinePayReqAmount", null);
                  setFeeDetails("generatePayAdditions", null);
                  setFeeDetails("generatePayDiscount", null);
                  setFeeDetails("modeOfPay", null);
                  setFeeDetails("selectedClassId", null);
                  setFeeDetails("selectedStudentName", null);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setAllActivitiesFilter("userId", null);
                  setFeeDetails("selectedStudentDesc", null);
                  setFeeDetails("selectedStudentDescId", null);
                  setFeeDetails("finalFeeRequestedWithGst", null);
                  setFeeDetails("selectedSingleTransaction", []);
                  setStudentName(null)

                  // tooglePageLoder(true);
                  getFeeOverView();
                  fetchTransaction();
                },
                true
              );
              // setIsModalVisible(false);
            }
          }
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setIsAddOrGenModal(false);
          setStudentName('')
          setFeeDetails("generatePayDate", null);
          setFeeDetails("generateOfflinePayDescription", null);
          setFeeDetails("generateOfflinePayReqAmount", null);
          setFeeDetails("generatePayAdditions", null);
          setFeeDetails("generatePayDiscount", null);
          setFeeDetails("modeOfPay", null);
          setFeeDetails("selectedClassId", null);
          setFeeDetails("selectedStudentName", null);
          setFeeDetails("selectedStudentId", null);
          setFeeDetails("generateOfflinePayCheckNumber", null);
          setFeeDetails("selectedStudentDesc", null);
          setFeeDetails("selectedStudentDescId", null);
          setFeeDetails("finalFeeRequestedWithGst", null);
          setFeeDetails("selectedSingleTransaction", []);
          setStudentName(null)
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Add Offline Payment
        </h2>
        <div
          style={{ overflowY: "auto", fontFamily: "roboto", height: "500px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "25px",
              marginBottom: "25px",
            }}
          >
            <div id="testHomeDropdown" style={{ width: "50%", marginLeft: "10px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >
                Class<span style={{ color: "red" }}> *</span>
              </p>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}


                placeholder="Select class"
                defaultValue={null}
                style={{ width: "100%" }}
                value={feeMangementData?.selectedClassId}
                onChange={(e) => {
                  setStudentName('')
                  setFeeDetails("selectedClassId", e);
                  getUserOfClass();
                  setFeeDetails("selectedStudentName", null);
                  setFeeDetails("allStudents", []);
                  setFeeDetails("selectedStudentDesc", null);
                  setFeeDetails("selectedStudentDescId", null);
                }}
              // size="large"
              >
                {feeMangementData?.dashBoardDetails?.recentTransactions?.standards?.map(
                  (item) => (
                    <Option value={item.id}>
                      {item?.std === "unassigned" ? "Unassigned" : <> {item?.std}-{item?.section}</>}

                    </Option>
                  )
                )}
              </Select>
            </div>
            <div
              style={{
                marginBottom: "10px",
                width: "315px",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >
                Student’s name/mobile<span style={{ color: "red" }}> *</span>
              </p>
              {console.log("AA-studentName", studentName)}
              {/* <br></br> */}
              <AutoComplete
                getPopupContainer={(trigger) => trigger.parentElement}

                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "3px",
                }}
                // allowClear={true}
                dropdownMatchSelectWidth={252}
                className="autocompletetoupload"
                options={optionStudent}
                value={studentName}
                onChange={handleChangeStudent}
                onSelect={onSelectStudent}
                onSearch={handleSearchStudent}
                loading={autoSearchLoading}
                disabled={!feeMangementData?.selectedClassId}
              >
                <Input
                  prefix={<SearchOutlined />}
                  size="middle"
                  className="input-component"
                  style={{
                    background: "#FAFAFA 0% 0% no-repeat padding-box",
                    border: "none",
                  }}
                  placeholder="Student’s name/mobile"
                />
              </AutoComplete>
            </div>
          </div>
          <div
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "600", marginTop: -25 }}
          >
            Details
          </div>
          <div
            style={{
              marginBottom: "10px",
              width: "96%",
              marginLeft: "10px",
              // marginRight: "-50px",
            }}
          >
            {/* <Typography.Text style={{ marginBottom: "10px" }}>
                      Subject
                    </Typography.Text> */}
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Description<span style={{ color: "red" }}> *</span>
            </p>
            {/* <br></br> */}
            <AutoComplete
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "3px",
                width: "100%",
              }}
              // allowClear={true}
              dropdownMatchSelectWidth={252}
              className="autocompletetoupload"
              options={
                optionDesc?.length
                  ? optionDesc
                  : feeMangementData?.selectedStudentDesc
                    ? [
                      {
                        key: -1,
                        value: feeMangementData?.selectedStudentDesc
                          ? `create ${feeMangementData?.selectedStudentDesc}`
                          : null,
                      },
                    ]
                    : []
              }
              value={feeMangementData?.selectedStudentDesc}
              onChange={handleChangeDesc}
              onSelect={onSelectStudentDesc}
              onSearch={handleSearchStudentDesc}
              loading={descAutoSearchLoading}
              disabled={!feeMangementData?.selectedStudentId}
            >
              <Input
                prefix={<SearchOutlined />}
                size="middle"
                className="input-component"
                style={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  border: "none",
                }}
                placeholder="Search description"
              />
            </AutoComplete>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              // backgroundColor: "red",
            }}
          >
            <div style={{ marginLeft: "10px", width: "50%" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Amount<span style={{ color: "red" }}> *</span>
              </p>
              <Input
                type="number"
                readOnly={apiCall}
                value={feeMangementData?.generateOfflinePayReqAmount}
                onChange={(e) => {
                  e.target.value = e.target.value < 10000000 ? parseInt(e.target.value) : 0

                  if (Number(e.target.value) || e.target.value == "") {
                    setFeeDetails(
                      "generateOfflinePayReqAmount",
                      e.target.value
                    );
                  }
                }}
                prefix="₹"
                style={{
                  width: "196%",
                  marginBottom: "20px",
                  border: "#E6E6E6 solid 1px",
                  borderRadius: "5px",
                  color: "#000000",
                  marginRight: "5px",
                }}
                placeholder="Enter amount"
              />{" "}
            </div>

            <div
              style={{
                width: "50%",
                backgroundColor: "blue",
                marginTop: "10px",
              }}
            >
              {/* <Date_Picker
              value={item?.dueDate}
              setInstallments={setInstallments}
              installments={installments}
              index={index}
              price={item?.installment}
              setInstCust={setInstCust}
            /> */}
            </div>
          </div>
          <div
            style={{ marginLeft: "10px", width: "96%", marginBottom: "10px", position: "relative" }}
          >
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Paid date<span style={{ color: "red" }}> *</span>
            </p>
            <Paid_date
              setPaidDate={setPaidDate}
              value={feeMangementData?.paidDate}
            />
          </div>
          {/* <div style={{ marginLeft: "10px", width: "95%" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Description
            </p>
            <Input
              value={feeMangementData?.generateOfflinePayDescription}
              onChange={(e) => {
                if (e.target.value == " ") {
                  setFeeDetails("generateOfflinePayDescription", null);
                } else {
                  setFeeDetails(
                    "generateOfflinePayDescription",
                    e.target.value
                  );
                }
              }}
              style={{
                width: "102%",
                marginBottom: "20px",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
                color: "#000000",
                marginRight: "5px",
              }}
              placeholder="Enter Description"
            />{" "}
          </div> */}
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Amount details
            </p>
            <div
              style={{
                border: "1px solid #E6E6E6",
                borderRadius: "10px",
                padding: "10px",
                width: "98%",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "40px",
                  justifyContent: "space-between",
                }}
              >
                <div>Fee amount</div>
                <div>
                  ₹{" "}
                  {feeMangementData?.generateOfflinePayReqAmount
                    ? feeMangementData?.generateOfflinePayReqAmount
                    : 0}
                </div>
              </div>
              {feeMangementData?.generatePayDiscount?.length != 0 &&
                feeMangementData?.generatePayDiscount?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#1089FF",
                    }}
                  >
                    <div>
                      <Typography.Text
                        style={{ width: "100px", color: "#1089FF" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = feeMangementData?.generatePayDiscount
                            ?.length
                            ? feeMangementData?.generatePayDiscount
                            : [];

                          let temp1 = removeElement(temp, index);
                          setFeeDetails("generatePayDiscount", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                          display: apiCall ? "none" : "",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                      onClick={() => {
                        // if (apiCall) {
                        // } else {
                        //   setIsAdditionModal(false);
                        //   setShowAddDetailsModal(true);
                        //   setIsModalVisible(false);
                        // }
                      }}
                    // style={{ cursor: "pointer" }}
                    >
                      -₹ {item?.amount}
                    </div>
                  </div>
                ))}
              {!apiCall ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "#1089FF",
                  }}
                >
                  <div>Discount</div>
                  <div
                    onClick={() => {
                      if (apiCall) {
                      } else {
                        setShowAddDetailsModal(true);
                        // setIsModalVisible(false);
                        setIsAdditionModal(false);
                      }
                    }}
                    style={{ cursor: !apiCall ? "pointer" : "" }}
                  >
                    -₹ ADD
                  </div>
                </div>
              ) : null}
              {feeMangementData?.generatePayAdditions?.length != 0 &&
                feeMangementData?.generatePayAdditions?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#FFA931",
                    }}
                  >
                    <div>
                      {/* {item?.name} */}
                      <Typography.Text
                        style={{ width: "100px", color: "#FFA931" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = feeMangementData?.generatePayAdditions
                            ?.length
                            ? feeMangementData?.generatePayAdditions
                            : [];

                          let temp1 = removeElement(temp, index);
                          setFeeDetails("generatePayAdditions", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                          display: apiCall ? "none" : "",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                    // onClick={() => {
                    //   if (apiCall) {
                    //   } else {
                    //     setIsAdditionModal(false);

                    //     setShowAddDetailsModal(true);
                    //     setIsModalVisible(false);
                    //   }
                    // }}
                    // style={{ cursor: "pointer" }}
                    >
                      +₹ {item?.amount}
                    </div>
                  </div>
                ))}
              {!apiCall ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "#FFA931",
                  }}
                >
                  <div>Addition</div>
                  <div
                    onClick={() => {
                      if (apiCall) {
                      } else {
                        setShowAddDetailsModal(true);
                        // setIsModalVisible(false);
                        setIsAdditionModal(true);
                      }
                    }}
                    style={{ cursor: !apiCall ? "pointer" : "" }}
                  >
                    +₹ ADD
                  </div>
                </div>
              ) : null}
              {!feeMangementData?.selectedSingleTransaction?.gstApplicable ==
                true && feeMangementData?.dashBoardDetails?.gst ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "grey",
                  }}
                >
                  <div>GST</div>
                  <div>
                    +₹{" "}
                    {feeMangementData?.finalFeeRequested
                      ? parseFloat(
                        feeMangementData?.finalFeeRequested * 0.18
                      ).toFixed(2)
                      : 0}
                  </div>
                </div>
              ) : null}
              {feeMangementData?.selectedSingleTransaction?.gstApplicable ==
                true ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "grey",
                  }}
                >
                  <div>GST</div>
                  <div
                  // onClick={() => {
                  //   setIsAdditionModal(true);
                  //   setShowAddDetailsModal(true);
                  //   setIsModalVisible(false);
                  // }}
                  // style={{ cursor: "pointer" }}
                  >
                    +₹{" "}
                    {feeMangementData?.selectedSingleTransaction?.gst
                      ? parseFloat(
                        feeMangementData?.selectedSingleTransaction?.gst
                      ).toFixed(2)
                      : 0}
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #E6E6E6",
                  borderBottom: "1px solid #E6E6E6",
                  height: "40px",
                }}
              >
                <div>Total payable</div>
                <div>
                  ₹{" "}
                  {feeMangementData?.finalFeeRequestedWithGst
                    ? parseFloat(
                      feeMangementData?.finalFeeRequestedWithGst
                    ).toFixed(2)
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "20px", marginTop: "20px" }}>
            {
              feeMangementData?.selectedSingleTransaction?.invoiceType ? (
                <>
                  {feeMangementData?.selectedSingleTransaction?.invoiceType == "manual" &&
                    <Radio.Group onChange={(e) => { setSendInvoice(e.target.value) }} value={sendInvoice}>
                      <p
                        style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px", marginTop: "-12px" }}
                      >
                        Send Invoice
                      </p>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}
                      // onClick={() => { setManual(true) }}
                      >
                        No
                      </Radio>
                    </Radio.Group>
                  }
                </>
              ) : (
                <>
                  {feeMangementData?.dashBoardDetails?.invoiceType == "manual" &&
                    <Radio.Group onChange={(e) => { setSendInvoice(e.target.value) }} value={sendInvoice}>
                      <p
                        style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px", marginTop: "-12px" }}
                      >
                        Send Invoice
                      </p>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}
                      // onClick={() => { setManual(true) }}
                      >
                        No
                      </Radio>
                    </Radio.Group>
                  }
                </>
              )

            }

            {/* {
              feeMangementData?.selectedSingleTransaction?.invoiceType ? feeMangementData?.selectedSingleTransaction?.invoiceType == "manual" : 
                <Radio.Group onChange={(e) => { setSendInvoice(e.target.value) }} value={sendInvoice}>
                  <p
                    style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px", marginTop: "12px" }}
                  >
                    Send Invoice
                  </p>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}
                  // onClick={() => { setManual(true) }}
                  >
                    No
                  </Radio>
                </Radio.Group> :
                null
            } */}
          </div>
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
                marginTop: "30px",
              }}
            >
              Mode of payment
            </p>
            {/* <div style={{ fontWeight: "500" }}>Mode of payment</div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "500px",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedBtn(1);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setFeeDetails("modeOfPay", "Cash");
                }}
                style={{
                  backgroundColor: selectedBtn == 1 ? "#594099" : "white",
                  color: selectedBtn == 1 ? "white" : "#8c8c8c",
                }}
                className={
                  selectedBtn == 1 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Cash
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 2 ? "#594099" : "white",
                  color: selectedBtn == 2 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setSelectedBtn(2);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setFeeDetails("modeOfPay", "Debit/Credit card");
                }}
                className={
                  selectedBtn == 2 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Debit/Credit card
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 3 ? "#594099" : "white",
                  color: selectedBtn == 3 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "Bank transfer");
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setSelectedBtn(3);
                }}
                className={
                  selectedBtn == 3 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Bank transfer
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 4 ? "#594099" : "white",
                  color: selectedBtn == 4 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "Cheque");
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setSelectedBtn(4);
                }}
                className={
                  selectedBtn == 4 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Cheque
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 5 ? "#594099" : "white",
                  color: selectedBtn == 5 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "UPI");
                  setSelectedBtn(5);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                }}
                className={
                  selectedBtn == 5 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                UPI
              </Button>
            </div>
            {feeMangementData?.modeOfPay != "Cash" ? (
              <div style={{ marginTop: "20px", width: "95%" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                  }}
                >
                  {feeMangementData?.modeOfPay == "Cheque"
                    ? " Cheque number"
                    : "Transaction ID"}
                </p>
                <Input
                  min={1}
                  maxLength={64}
                  value={feeMangementData?.generateOfflinePayCheckNumber}
                  onChange={(e) => {
                    setFeeDetails(
                      "generateOfflinePayCheckNumber",
                      e.target.value
                    );
                  }}
                  style={{
                    width: "102%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                    color: "#000000",
                    marginRight: "5px",
                  }}
                  placeholder={`Enter ${feeMangementData?.modeOfPay == "Cheque"
                    ? "cheque number"
                    : "transaction ID"
                    }`}
                />{" "}
              </div>
            ) : null}
          </div>

        </div>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  const { feeMangementData, studentName } = state.feeManagement;
  // const { feeMangementData } = state.feeMangement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    feeMangementData,
    studentName
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  getUserOfClass: (callback) =>
    dispatch(getUserOfClass(callback)),
  generatePayRequest: (val, sendInvoice, callback, successCallBack, payType) =>
    dispatch(generatePayRequest(val, sendInvoice, callback, successCallBack, payType)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  getTransactionsOfStudent: (callback, desc) =>
    dispatch(getTransactionsOfStudent(callback, desc)),
  postOfflineTransaction: (sendInvoice, callback, successCallBack) =>
    dispatch(postOfflineTransaction(sendInvoice, callback, successCallBack)),
  setStudentName: (val) => dispatch(setStudentName(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(OfflinePayReq);
