import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { Api } from 'services';
import { SubIcon } from "components/subIcon";
import { Avatar } from "antd";


const StudentBarGraph = ({ data }) => {
  // const data = [
  //   {
  //     name: "Present",
  //     subject: "11/12",
  //     range: 58.9,
  //   },
  //   {
  //     name: "Present",
  //     subject: "12/12",
  //     range: 100,
  //   },
  //   {
  //     name: "Present",
  //     subject: "13/12",
  //     range: 62,
  //   },
  //   {
  //     name: "Present",
  //     subject: "14/12",
  //     range: 62,
  //   },
  //   {
  //     name: "Present",
  //     subject: "15/12",
  //     range: 62,
  //   },
  //   {
  //     name: "Absent",
  //     subject: "11/12",
  //     range: 10,
  //   },
  //   {
  //     name: "Absent",
  //     subject: "13/12",
  //     range: 9,
  //   },
  //   {
  //     name: "Absent",
  //     subject: "14/12",
  //     range: 9,
  //   },
  //   {

  //     name: "Absent",
  //     subject: "15/12",
  //     range: 9,
  //   },
  // ];

  var config = {
    Avatar,
    data,
    isGroup: true,
    // xAxis:false,
    xField: "key",
    yField: "range",
    seriesField: "name",
    tooltip: {
      customContent: (val, item) => {
        // console.log("hhh",item)
        return (
          <div>
            <div style={{ marginLeft: "-20px", marginRight: "-20px", alignItems: "center", height: "100px" }}>
              <div
                style={{
                  minWidth: "200px"
                }}></div>
              <div style={{ display: "flex", marginTop: "8px", width: "190px", marginLeft: "20px" }}>
                {/* <div><img style={{ width: "45px", height: "45px" }} src={`${Api._s3_url}${item[0]?.data?.icon}`} /></div> */}
                <div>
                  <SubIcon size={40} icon={item[0]?.data?.icon} name={item[0]?.data?.subjectName} />
                </div>
                <div>


                  <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", marginTop: "10px", width: "100px", marginLeft: "8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item[0]?.data?.subjectName}</div>
                  <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", marginTop: "5px", width: "150px", marginLeft: "8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item[0]?.data?.teacherName}</div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", width: "150px", marginLeft: "20px" }}>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto" }}>Present  </div>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", fontWeight: "bold" }}>{parseInt(item[0]?.data?.presentCount)} </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", width: "150px", marginLeft: "20px" }}>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto" }}>Absent  </div>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", fontWeight: "bold" }}>{parseInt(item[1]?.data?.absentCount)}</div>
              </div>

            </div>
          </div>

        )

      }
    },
    columnStyle: {
      radius: [3, 3, 0, 0],

    },

    color: ["#28DF99", "#FF414D"],
    legend: false,
    minColumnWidth: 35,
    maxColumnWidth: 20,
  };
  return <Column {...config} />;
};
export default StudentBarGraph

