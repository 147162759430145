import styled from "styled-components";

export const LinesCarousel = styled.div`
        text-align:left;
        @media screen and (max-width: 800px){
        .lines{
          display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;    
      }
        .carousal{
          margin - top: 50px
      }
        .arrow-button{
        display:flex;
        justify-content:flex-end;
        padding - bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif; 
        margin-top:20px    
      }
        .blueline{
          width:60px;
        height:4px;
        background-color:#9671BC;
        border-radius:3px;
        cursor:pointer;
      }
        .greyline{
          width:10px;
        height:4px;
        background-color:#9D9D9D;
        border-radius:3px;
        cursor:pointer;
      }
        .carousal-image{
          width: 80%;
        height: 250px;
        object-fit: fill;
    }
        .image-container{
          display: flex;
        justify-content: center;
        align-items: center;
        width:50%;
        height:50%;
  }
    }
        ` 