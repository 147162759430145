import { BellOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd'
import { Heading } from 'components/Typography'
import React, { useState } from 'react'
import TitleAndDescription from '../titleAndDescription';
import moment from 'moment';

function PaymentReqDetailsModal({ isPaymentDetailsVisible, setPaymentDetailsVisible, setIsEmailIdModalVisible, paymentReqModalData }) {
    const handleCancel = () => {
        setPaymentDetailsVisible(false);
    };
    return (
        <Modal
            className="modal-round-corner"
            centered
            visible={isPaymentDetailsVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            header={null}
            footer={null}
        >
            <Heading bold className='r-c-c' style={{ fontSize: 22 }}>Payment Requests Details</Heading>
            <div style={{ padding: "10px 20px 0px 20px" }}>
                <div className='r-c-sb'>
                    <TitleAndDescription
                        title={"Amount"}
                        description={"₹" + " " + paymentReqModalData?.amountToShow ? paymentReqModalData?.amountToShow : "0"}
                    />
                    <TitleAndDescription
                        title={"Issue date"}
                        description={paymentReqModalData?.date ? moment(paymentReqModalData?.date, "DD-MM-YYYY, hh:mm A").format("DD/MM/YYYY, hh:mm A"): "-"}
                    />
                </div>
                <div className=' display-flex m-t-20'>
                    <div style={{ width: "19.5vw" }}>
                        <TitleAndDescription
                            title={"Due date"}
                            description={paymentReqModalData?.dueDate ? moment(paymentReqModalData?.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY") : "-"}
                        />
                    </div>
                    <div className='font'>
                        <div style={{ color: "#636363", fontSize: 12 }}>Status</div>
                        <div style={{ color: paymentReqModalData?.status === 'Overdue' ? "red" : paymentReqModalData?.status === 'Overdue' ? "#FFA931" : "#FFA931", fontSize: 14 }}>{paymentReqModalData?.status.toUpperCase()} {paymentReqModalData?.reminderCount > 0 ? <span className='m-l-10 m-r-5'><BellOutlined style={{ color: "#FFA931" }} /></span> : <></>}</div>
                    </div>
                </div>
                <div className='m-t-20'>
                    <TitleAndDescription
                        title={"Description"}
                        description={paymentReqModalData?.note ? paymentReqModalData?.note : "-"}
                    />
                </div>
                <div className='m-t-20 '>
                    <TitleAndDescription
                        title={"Amount details"}
                    />
                </div>
                <div className='font m-t-10 r-c-sb' style={{ color: "#191919", fontSize: 14 }}>
                    <div>Fee amount</div>
                    <div>₹ {paymentReqModalData?.amount ? Math.round((parseFloat(paymentReqModalData?.amount) + paymentReqModalData?.total_discount - paymentReqModalData?.total_addition )*100)/100 || "0" : "0"}</div>
                </div>
                {paymentReqModalData?.discount_details &&
                    JSON.parse(paymentReqModalData?.discount_details)?.length != 0 ?
                    <>
                        {JSON.parse(paymentReqModalData?.discount_details)?.map((item, index) => (
                            <div className='font r-c-sb m-t-5' style={{ color: "#1089FF", fontSize: 14 }}>
                                <div>{item?.name}</div>
                                <div>- ₹ {item?.amount}</div>
                            </div>
                        ))}
                    </>
                    :
                    <></>
                }
                {paymentReqModalData?.addition_details &&
                    JSON.parse(paymentReqModalData?.addition_details)?.length != 0 ?
                    <>
                        {JSON.parse(paymentReqModalData?.addition_details)?.map((item, index) => (
                            <div className='font r-c-sb m-t-5' style={{ color: "#FFA931", fontSize: 14 }}>
                                <div>{item?.name}</div>
                                <div>+ ₹ {item?.amount}</div>
                            </div>
                        ))}
                    </>
                    :
                    <></>
                }
                <div className='font m-t-10 r-c-sb' style={{ color: "grey", fontSize: 14 }}>
                    <div>GST</div>
                    <div>+ ₹ {paymentReqModalData?.gst}</div>
                </div>
                <div className='m-t-10' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
                <div className='font r-c-sb m-t-5' style={{ color: "#191919", fontSize: 14 }}>
                    <div>Total payable</div>
                    <div>₹ {paymentReqModalData?.amountToShow}</div>
                </div>
                <div className='m-t-10' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
                <div className='r-c-c'>
                    <Button
                        type="primary"
                        style={{ width: "40%", marginTop: 20, height: 35, borderRadius: 100 }}
                        onClick={() => {
                            setPaymentDetailsVisible(false);
                            setIsEmailIdModalVisible(true)
                        }}>
                        PAY NOW</Button>
                </div>
            </div>
        </Modal>
    )
}

export default PaymentReqDetailsModal