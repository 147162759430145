import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_LOADER,
  UPDATE_ADMIN_PROFILE_DATA,
  SET_EDIT_INSTITUTE_NAME,
  SET_EDIT_INSTITUTE_TYPE,
  SET_EDIT_INSTITUTE_WEBSITE,
  SET_EDIT_INSTITUTE_BUILDING,
  SET_EDIT_INSTITUTE_DISTRICT,
  SET_EDIT_INSTITUTE_STATE,
  SET_EDIT_INSTITUTE_PINCODE,
  SET_EDIT_INSTITUTE_COUNTRY,
  SET_EDIT_CONTACT_NAME,
  SET_EDIT_CONTACT_PHONE,
  SET_EDIT_CONTACT_EMAIL,
  SET_EDIT_CONTACT_DESIGNATION,
  SET_EDIT_BILLING_NO,
  SET_EDIT_BILLING_DISTRICT,
  SET_EDIT_BILLING_STATE,
  SET_EDIT_BILLING_PINCODE,
  SET_EDIT_BILLING_COUNTRY,
  SET_EDIT_BILLING_GSTNO,
  SET_EDIT_BILLING_PANNO,
  SET_EDIT_AUTOAPPROVAL,
  SET_EDIT_TRANSACTION_FEE,
  SET_EDIT_SUPPORT_PHONENUMBERS,
  SET_USER_CURRENT_PERMINANT_ADD,
  SET_EDIT_DESIGNATION,
  SET_USER_IMG,
  SET_DOC_LINK,
  UPDATE_BILLING_HISTORY,
  SUBSCRIPTION_PLAN, STORE_SUBSCRIPTION,
  FETCH_SUBS_STAT,
  HOURS_COUNT
} from "../actions/profile";

const initialState = {
  profileSingle: false,
  profileLoader: false,
  adminProfileData: {},
  editInstituteName: null,
  editInstituteType: null,
  editInstituteWebsite: null,
  editInstituteBuilding: null,
  editInstituteDistrict: null,
  editInstituteState: null,
  editInstitutePinode: null,
  editInstituteCountry: null,
  editContactName: null,
  editContactPhone: null,
  editContactEmail: null,
  editContactDesignation: null,
  editBillingNo: null,
  editBillingDistrict: null,
  editBillingState: null,
  editBillingPincode: null,
  editBillingCountry: null,
  editBillingGSTNo: null,
  editBillingPANNo: null,
  editAutoapproval: null,
  editTransactionFee: null,
  editSupportPhoneNo: [],
  superAdminAddInfo: {},
  editDesignation: null,
  superAdminImg: "default.png",
  documentLink: null,
  getBillingHistory: {},
  broadSubscriptionPlans: [],
  storeSubscriptionPlan: {},
  subsStatData: [],
  countingHours: 1
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CURRENT_PERMINANT_ADD:
      return {
        ...state,
        superAdminAddInfo: action.payload,
      };
    case UPDATE_ADMIN_PROFILE_DATA:
      return {
        ...state,
        adminProfileData: action.data,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profileSingle: action.profile,
      };
    case UPDATE_PROFILE_LOADER:
      return {
        ...state,
        profileLoader: action.bool,
      };
    case SET_DOC_LINK:
      return {
        ...state,
        documentLink: action.payload,
      };
    case SET_USER_IMG:
      return {
        ...state,
        superAdminImg: action.payload,
      };
    case SET_EDIT_DESIGNATION:
      return {
        ...state,
        editDesignation: action.payload,
      };
    case SET_EDIT_INSTITUTE_NAME:
      return {
        ...state,
        editInstituteName: action.payload,
      };
    case SET_EDIT_INSTITUTE_TYPE:
      return {
        ...state,
        editInstituteType: action.payload,
      };
    case SET_EDIT_INSTITUTE_WEBSITE:
      return {
        ...state,
        editInstituteWebsite: action.payload,
      };
    case SET_EDIT_INSTITUTE_BUILDING:
      return {
        ...state,
        editInstituteBuilding: action.payload,
      };
    case SET_EDIT_INSTITUTE_DISTRICT:
      return {
        ...state,
        editInstituteDistrict: action.payload,
      };
    case SET_EDIT_INSTITUTE_STATE:
      return {
        ...state,
        editInstituteState: action.payload,
      };
    case SET_EDIT_INSTITUTE_PINCODE:
      return {
        ...state,
        editInstitutePinode: action.payload,
      };
    case SET_EDIT_INSTITUTE_COUNTRY:
      return {
        ...state,
        editInstituteCountry: action.payload,
      };
    case SET_EDIT_CONTACT_NAME:
      return {
        ...state,
        editContactName: action.payload,
      };
    case SET_EDIT_CONTACT_PHONE:
      return {
        ...state,
        editContactPhone: action.payload,
      };
    case SET_EDIT_CONTACT_EMAIL:
      return {
        ...state,
        editContactEmail: action.payload,
      };
    case SET_EDIT_CONTACT_DESIGNATION:
      return {
        ...state,
        editContactDesignation: action.payload,
      };
    case SET_EDIT_BILLING_NO:
      return {
        ...state,
        editBillingNo: action.payload,
      };
    case SET_EDIT_BILLING_DISTRICT:
      return {
        ...state,
        editBillingDistrict: action.payload,
      };
    case SET_EDIT_BILLING_STATE:
      return {
        ...state,
        editBillingState: action.payload,
      };
    case SET_EDIT_BILLING_PINCODE:
      return {
        ...state,
        editBillingPincode: action.payload,
      };
    case SET_EDIT_BILLING_COUNTRY:
      return {
        ...state,
        editBillingCountry: action.payload,
      };
    case SET_EDIT_BILLING_GSTNO:
      return {
        ...state,
        editBillingGSTNo: action.payload,
      };
    case SET_EDIT_BILLING_PANNO:
      return {
        ...state,
        editBillingPANNo: action.payload,
      };
    case SET_EDIT_AUTOAPPROVAL:
      return {
        ...state,
        editAutoapproval: action.payload,
      };
    case SET_EDIT_TRANSACTION_FEE:
      return {
        ...state,
        editTransactionFee: action.payload,
      };
    case SET_EDIT_SUPPORT_PHONENUMBERS:
      return {
        ...state,
        editSupportPhoneNo: action.payload,
      };

    case UPDATE_BILLING_HISTORY:
      return {
        ...state,
        getBillingHistory: action.data
      }
    case SUBSCRIPTION_PLAN:
      return {
        ...state,
        broadSubscriptionPlans: action.res
      }
    case STORE_SUBSCRIPTION:
      return {
        ...state,
        storeSubscriptionPlan: action.res
      }

    case FETCH_SUBS_STAT:
      return {
        ...state,
        subsStatData: action.res
      }
    case HOURS_COUNT:
      return {
        ...state,
        countingHours: action.res
      }

    default:
      return state;
  }
};

export default reducer;
