import { fetchStandards, setWhichViewAllClicked, storeStandardData } from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PageHeader from "components/pageHeader";
import BackToTop from "components/backToTop";
import { fetchcourseStandards, setSelectedCourse, setSelectedStandard, getUsers, setSpecificUserId, setUserDetails, setRole, setUserids, setStudentWithoutData } from "actions/userManagementV2";
import { StandardCard } from "pages/userManagementV2/courseStandards";
import { Typography } from "antd";
import { InputSearch } from "components/customInputs";

const AllStudentsFile = ({ fetchcourseStandards, resourceData, courseStandards, storeStandardData, setWhichViewAllClicked, selectedCourse, viewAllIs }) => {
  const history = useHistory();
  const [pageLoader, togglePageLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [parmanentDeleteModal, setPermanentDeletModal] = useState(false)
  const [userDeleteModalBool, setUserDeleteModalOpen] = useState(false);

  console.log({ selectedCourse });
  console.log({ resourceData });
  console.log({ viewAllIs });

  let params = {
    search: search == '' ? search : search,
    userType: 'Student',
    courseIds: [resourceData?.id]
  }

  useEffect(() => {
    setSearch('')
    togglePageLoader(true);
    fetchcourseStandards({ courseId: resourceData?.id }, () => togglePageLoader(false));
  }, []);

  useEffect(() => {
    togglePageLoader(true)
    getUsers(params, () => togglePageLoader(false))
  }, [search]);

  const handleBackPress = () => {
    setWhichViewAllClicked('course')
    history.goBack();
  }

  const handleStandardClick = (item) => {
    setWhichViewAllClicked('course')
    storeStandardData(item);
    history.push('/fee-management/course-details');
  }

  return (
    <div style={{ fontFamily: "roboto" }}>
      <div style={{ marginTop: -10, height: 50 }}>
        <PageHeader title={selectedCourse} onBackPress={() => handleBackPress()} />
      </div>
      {pageLoader ? null :
        courseStandards?.standardsArray && courseStandards?.standardsArray?.length !== 0 ? (
          <div>
            <div style={{ padding: 20 }}>
              <div className="m-t-10 bold-600" style={{ fontSize: "16px" }}>
                All Classes
              </div>
            </div>
            {courseStandards?.standardsArray?.map((item, idx) => (
              <div key={idx} style={{ padding: 20, marginTop: -40 }}>
                <h2 className="m-t-10 bold-600" style={{ fontSize: "14px" }}>
                  {item.std}
                </h2>
                <div className="r-c-fs flex-wrap">
                  {courseStandards?.standardsArray[idx]?.standards?.map((standard) => (
                    <div key={standard.id} className='' style={{ padding: 5 }}>
                      <div onClick={() => handleStandardClick(standard)} className='CardShadow cursor-pointer' style={{ border: '1px solid #E6E6E6', borderRadius: 8, height: '21vh', width: '11vw' }}>
                        <center className='flex-column m-t-10'>
                          <center>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#594099', fontSize: '2.5rem', fontFamily: 'PT serif', fontWeight: 600, width: 100 }}>{standard?.section}</Typography.Text>
                          </center>
                          <center>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 120 }} className='simGrey bold-500'>{standard?.courseName ?? 'NA'}</Typography.Text>
                          </center>
                          <div style={{ width: '80%', border: '1px solid #E6E6E6', marginLeft: 15, marginTop: 5 }}></div>
                          <div className="r-c-c m-t-10">
                            <div style={{ color: '#28DF99' }}>{standard?.studentCount}</div>
                            <div className='bold-500 m-l-5'>{standard?.studentCount > 1 ? 'Students' : 'Student'}</div>
                          </div>
                        </center>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) :
          <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <div className='r-c-c-c m-t-80'>
              <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
              <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
            </div>
          </center>
      }
      <PageDataLoader visible={pageLoader} />
      <BackToTop />
    </div>
  );
};
const mapStateToProps = (state) => {
  const { feeMangementData, courseDetailsOfResources, resourceData, viewAllIs } = state.feeManagement;
  const { courseStandards, selectedCourse } = state.userManagementV2;
  return {
    feeMangementData, courseDetailsOfResources, courseStandards, resourceData, selectedCourse, viewAllIs
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchStandards: (callback) => dispatch(fetchStandards(callback)),
  fetchcourseStandards: (params, callback) => dispatch(fetchcourseStandards(params, callback)),
  storeStandardData: res => dispatch(storeStandardData(res)),
  setWhichViewAllClicked: res => dispatch(setWhichViewAllClicked(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllStudentsFile);