import { setFeeDetails } from "actions/feeManagement";
import { Input, Button, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ScholarshipAndAdditionModal from "./scholarshipAndAdditionModal";
import Paid_date from "./editPaidDate";
import moment from "moment";
import { editPaidTransaction, setCourseDetails } from "actions/userManagement";

function EditOfflinePayment({
  feeMangementData,
  setFeeDetails,
  showEditOffline,
  setShowEditOffline,
  setShowEditSA,
  setIsAdditionModal,
  studentCourseDetails,
  setCourseDetails,
  editPaidTransaction,
}) {
  const [selectedBtn, setSelectedBtn] = useState(null);
  const [btnLoader, setBtnLoade] = useState(false);
  const [amount, setAmount] = useState(
    parseFloat(
      parseFloat(
        feeMangementData?.selectedTransaction?.amount -
          feeMangementData?.selectedTransaction?.total_addition +
          feeMangementData?.selectedTransaction?.total_discount
      ).toFixed(2)
    )
  );
  const [disc, setDisc] = useState(feeMangementData?.selectedTransaction?.note);
  const [feeAmont, setFeeAmount] = useState(20);
  const [totalPay, setTotalPay] = useState(
    feeMangementData?.selectedTransaction?.amountPayable
  );
  const [paidDate, setPaidDate] = useState(null);
  function removeElement(array, index) {
    let tmp = [];
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
      } else {
        tmp.push(array[i]);
      }
    }
    return tmp;
  }
  useEffect(() => {
    // setFeeDetails('paidDate',moment(feeMangementData?.selectedTransaction?.paymentDate))
    // console.log(
    //   "paid date",
    //   moment(
    //     feeMangementData?.selectedTransaction?.paymentDate,
    //     "DD/MM/YYYY, h:mm a"
    //   ).format("DD/MM/YYYY")
    // );
    setPaidDate(
      // moment(
      moment(
        feeMangementData?.selectedTransaction?.paymentDate,
        "DD/MM/YYYY, h:mm a"
      ).format("YYYY/MM/DD")
      // )
    );
    // setFeeDetails(
    //   "modeOfPay",
    //   feeMangementData?.selectedTransaction?.payment_method
    // );
    setFeeDetails(
      "generateOfflinePayCheckNumber",
      feeMangementData?.selectedTransaction?.cheque_no
    );
    let str = feeMangementData?.selectedTransaction?.payment_method;
    setSelectedBtn(
      str == "Cash"
        ? 1
        : str == "Debit/Credit card"
        ? 2
        : str == "Bank transper"
        ? 3
        : str == "Cheque"
        ? 4
        : str == "UPI"
        ? 5
        : 1
    );
    setFeeDetails("modeOfPay", str);

    // setCourseDetails("additions", temp1);
  }, []);

  // useEffect(() => {
  //   setFeeDetails("paidDate", paidDate);
  // }, [paidDate]);
  useEffect(() => {
    let sa =
      studentCourseDetails?.scholarships?.length != 0
        ? studentCourseDetails?.scholarships[0]?.amount
        : 0;
    let aa =
      studentCourseDetails?.additions?.length != 0
        ? studentCourseDetails?.additions[0]?.amount
        : 0;
    setTotalPay(parseFloat(amount) - parseFloat(sa) + parseFloat(aa));
    // console.log("new", parseFloat(amount), parseFloat(sa), parseFloat(aa));
    // setFeeDetails(
    //   "EditedTotalPay",
    //   parseFloat(amount) - parseFloat(sa) + parseFloat(aa)
    // );
    setFeeDetails(
      "EditedTotalPay",
      parseFloat(amount) - parseFloat(sa) + parseFloat(aa)
    );
  }, [
    studentCourseDetails?.scholarships?.length,
    studentCourseDetails?.additions?.length,
    feeMangementData?.editPaidAmount,
  ]);

  return (
    <div>
      <Modal
        className="generatePayModalB"
        title="Basic Modal"
        visible={showEditOffline}
        okButtonProps={{ loading: btnLoader }}
        onOk={() => {
          // setFeeDetails("editPaidDisc", disc);
          // setFeeDetails("editPaidAmount", amount);
          // setCourseDetails("scholarships", temp1);
          // setCourseDetails("additions", temp1);
          // setFeeDetails("generateOfflinePayCheckNumber", null);
          // setFeeDetails("modeOfPay", "Cash");
          setBtnLoade(true);
          editPaidTransaction(
            () => {
              setBtnLoade(false);
            },
            () => {
              setShowEditOffline(false);
            }
          );
        }}
        onCancel={() => {
          setShowEditOffline(false);
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Edit Offline Payment
        </h2>
        <div style={{ height: "530px", overflowY: "auto" }}>
          <div
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "600" }}
          >
            Details
          </div>
          <div
            style={{
              marginBottom: "10px",
              width: "96%",
              marginLeft: "10px",
              // marginRight: "-50px",
            }}
          >
            {/* <Typography.Text style={{ marginBottom: "10px" }}>
                      Subject
                    </Typography.Text> */}
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Description<span style={{ color: "red" }}> *</span>
            </p>

            <Input
              value={feeMangementData?.editPaidDisc}
              onChange={(e) => {
                setDisc(e.target.value);
                setFeeDetails("editPaidDisc", e.target.value);
              }}
              size="middle"
              style={{
                background: "#FAFAFA 0% 0% no-repeat padding-box",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
              }}
              placeholder="Input search description"
            />
          </div>
          <div style={{ marginLeft: "10px", width: "50%" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Amount<span style={{ color: "red" }}> *</span>
            </p>
            <Input
              onChange={(e) => {
                setAmount(e.target.value);
                setFeeDetails("editPaidAmount", e.target.value);
              }}
              value={amount}
              prefix="₹"
              style={{
                width: "192%",
                marginBottom: "20px",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
                color: "#000000",
                marginRight: "5px",
              }}
              placeholder="Enter amount"
            />{" "}
          </div>
          <div
            style={{ marginLeft: "10px", width: "95%", marginBottom: "10px" }}
          >
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Paid Date<span style={{ color: "red" }}> *</span>
            </p>
            <Paid_date
              setPaidDate={setPaidDate}
              value={feeMangementData?.paidDate}
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Amount details
            </p>
            <div
              style={{
                border: "1px solid #E6E6E6",
                borderRadius: "10px",
                padding: "10px",
                width: "98%",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "40px",
                  justifyContent: "space-between",
                }}
              >
                <div>Fee amount</div>
                <div>₹ {parseFloat(amount).toFixed(2)}</div>
              </div>
              {studentCourseDetails?.scholarships?.length != 0 &&
                studentCourseDetails?.scholarships?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#1089FF",
                    }}
                  >
                    <div>
                      <Typography.Text
                        style={{ width: "100px", color: "#1089FF" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = studentCourseDetails?.scholarships?.length
                            ? studentCourseDetails?.scholarships
                            : [];

                          let temp1 = removeElement(temp, index);
                          setCourseDetails("scholarships", temp1);

                          // setFeeDetails("generatePayDiscount", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                          // display: apiCall ? "none" : "",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                      onClick={() => {
                        // if (apiCall) {
                        // } else {
                        //   setIsAdditionModal(false);
                        //   setShowAddDetailsModal(true);
                        //   setIsModalVisible(false);
                        // }
                      }}
                      // style={{ cursor: "pointer" }}
                    >
                      -₹ {item?.amount}
                    </div>
                  </div>
                ))}
              {/* {feeMangementData?.generatePayDiscount?.length != 0 &&
                feeMangementData?.generatePayDiscount?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#1089FF",
                    }}
                  >
                    <div>
                      <Typography.Text
                        style={{ width: "100px" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = feeMangementData?.generatePayDiscount
                            ?.length
                            ? feeMangementData?.generatePayDiscount
                            : [];

                          let temp1 = removeElement(temp, index);
                          setFeeDetails("generatePayDiscount", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                          display: apiCall ? "none" : "",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                      onClick={() => {
                        // if (apiCall) {
                        // } else {
                        //   setIsAdditionModal(false);
                        //   setShowAddDetailsModal(true);
                        //   setIsModalVisible(false);
                        // }
                      }}
                      // style={{ cursor: "pointer" }}
                    >
                      -₹ {item?.amount}
                    </div>
                  </div>
                ))} */}
              {studentCourseDetails?.scholarships?.length == 0 ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "#1089FF",
                  }}
                >
                  <div>Discount</div>
                  <div
                    onClick={() => {
                      setShowEditSA(true);
                      setShowEditOffline(false);
                      setIsAdditionModal(false);
                    }}
                    //   onClick={() => {
                    //     if (apiCall) {
                    //     } else {
                    //       setShowAddDetailsModal(true);
                    //       setIsModalVisible(false);
                    //       setIsAdditionModal(false);
                    //     }
                    //   }}
                    style={{ cursor: true ? "pointer" : "" }}
                  >
                    -₹ ADD
                  </div>
                </div>
              ) : null}
              {studentCourseDetails?.additions?.length != 0 &&
                studentCourseDetails?.additions?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#FFA931",
                    }}
                  >
                    <div>
                      <Typography.Text
                        style={{ width: "100px", color: "#FFA931" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = studentCourseDetails?.additions?.length
                            ? studentCourseDetails?.additions
                            : [];

                          let temp1 = removeElement(temp, index);
                          setCourseDetails("additions", temp1);

                          // setFeeDetails("generatePayDiscount", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                          // display: apiCall ? "none" : "",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                      onClick={() => {
                        // if (apiCall) {
                        // } else {
                        //   setIsAdditionModal(false);
                        //   setShowAddDetailsModal(true);
                        //   setIsModalVisible(false);
                        // }
                      }}
                      // style={{ cursor: "pointer" }}
                    >
                      +₹ {item?.amount}
                    </div>
                  </div>
                ))}
              {studentCourseDetails?.additions?.length == 0 ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "#FFA931",
                  }}
                >
                  <div>Addition</div>
                  <div
                    onClick={() => {
                      setShowEditSA(true);
                      setShowEditOffline(false);
                      setIsAdditionModal(true);
                    }}
                    //   onClick={() => {
                    //     if (apiCall) {
                    //     } else {
                    //       setShowAddDetailsModal(true);
                    //       setIsModalVisible(false);
                    //       setIsAdditionModal(true);
                    //     }
                    //   }}
                    style={{ cursor: true ? "pointer" : "" }}
                  >
                    +₹ ADD
                  </div>
                </div>
              ) : null}
              {!feeMangementData?.selectedSingleTransaction?.gstApplicable ==
                true && feeMangementData?.dashBoardDetails?.gst ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "grey",
                  }}
                >
                  <div>GST</div>
                  <div>+₹ {parseFloat(totalPay * 0.18).toFixed(2)}</div>
                </div>
              ) : null}
              {/* {!feeMangementData?.selectedSingleTransaction?.gstApplicable ==
                true && feeMangementData?.dashBoardDetails?.gst ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "grey",
                  }}
                >
                  <div>GST</div>
                  <div>+₹ 222</div>
                </div>
              ) : null} */}

              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #E6E6E6",
                  borderBottom: "1px solid #E6E6E6",
                  height: "40px",
                }}
              >
                <div>Total payable</div>
                <div>
                  ₹{" "}
                  {/* {feeMangementData?.finalFeeRequestedWithGst
                    ? parseFloat(
                        feeMangementData?.finalFeeRequestedWithGst
                      ).toFixed(2)
                    : 0} */}
                  {parseFloat(
                    totalPay +
                      (!feeMangementData?.selectedSingleTransaction
                        ?.gstApplicable
                        ? parseFloat(totalPay * 0.18)
                        : 0)
                  ).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
                marginTop: "30px",
              }}
            >
              Mode of payment
            </p>
            {/* <div style={{ fontWeight: "500" }}>Mode of payment</div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "500px",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedBtn(1);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setFeeDetails("modeOfPay", "Cash");
                }}
                style={{
                  backgroundColor: selectedBtn == 1 ? "#594099" : "white",
                  color: selectedBtn == 1 ? "white" : "#8c8c8c",
                }}
                className={
                  selectedBtn == 1 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Cash
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 2 ? "#594099" : "white",
                  color: selectedBtn == 2 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setSelectedBtn(2);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setFeeDetails("modeOfPay", "Debit/Credit card");
                }}
                className={
                  selectedBtn == 2 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Debit/Credit card
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 3 ? "#594099" : "white",
                  color: selectedBtn == 3 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "Bank transfer");
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setSelectedBtn(3);
                }}
                className={
                  selectedBtn == 3 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Bank transfer
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 4 ? "#594099" : "white",
                  color: selectedBtn == 4 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "Cheque");
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setSelectedBtn(4);
                }}
                className={
                  selectedBtn == 4 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Cheque
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 5 ? "#594099" : "white",
                  color: selectedBtn == 5 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "UPI");
                  setSelectedBtn(5);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                }}
                className={
                  selectedBtn == 5 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                UPI
              </Button>
            </div>
            {feeMangementData?.modeOfPay != "Cash" ? (
              <div style={{ marginTop: "20px", width: "95%" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                  }}
                >
                  {feeMangementData?.modeOfPay == "Cheque"
                    ? " Cheque number"
                    : "Transaction ID"}
                </p>
                <Input
                  value={feeMangementData?.generateOfflinePayCheckNumber}
                  onChange={(e) => {
                    setFeeDetails(
                      "generateOfflinePayCheckNumber",
                      e.target.value
                    );
                  }}
                  style={{
                    width: "102%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                    color: "#000000",
                    marginRight: "5px",
                  }}
                  placeholder={`Enter ${
                    feeMangementData?.modeOfPay == "Cheque"
                      ? "cheque number"
                      : "transaction ID"
                  }`}
                />{" "}
              </div>
            ) : (
              <div style={{ height: "100px" }}></div>
            )}
          </div>
        </div>
        <ScholarshipAndAdditionModal />
      </Modal>
    </div>
  );
}

// export default EditOfflinePayment;
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    studentCourseDetails,
  } = state.userManagement;
  const { feeMangementData } = state.feeManagement;
  // const { feeMangementData } = state.feeMangement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    feeMangementData,
    studentCourseDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  //   getUserOfClass: (callback, studentName) =>
  //     dispatch(getUserOfClass(callback, studentName)),
  //   generatePayRequest: (val, callback, successCallBack, payType) =>
  //     dispatch(generatePayRequest(val, callback, successCallBack, payType)),
  //   setAllActivitiesFilter: (key, val) =>
  //     dispatch(setAllActivitiesFilter(key, val)),
  //   getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  //   fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  //   getTransactionsOfStudent: (callback, desc) =>
  //     dispatch(getTransactionsOfStudent(callback, desc)),
  //   postOfflineTransaction: (callback, successCallBack) =>
  //     dispatch(postOfflineTransaction(callback, successCallBack)),
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  editPaidTransaction: (callback, success) =>
    dispatch(editPaidTransaction(callback, success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOfflinePayment);
