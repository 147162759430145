import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, PageHeader } from "antd";
import { Color } from "services";
import { showMenu } from "actions/layout";
import { startTest, navigateQuestion, updateAnswerObj, clearQuestions } from "actions/marketTest";
import { MarketTestPalette } from "./marketTestPalette";
import PageDataLoader from "components/pageDataLoader";
import {
    LeftOutlined,
    LoadingOutlined,
    RightOutlined,
} from "@ant-design/icons";
import ViewImgModal from "pages/studentTest/modals/viewImgModal";
import { QuestionTypes } from "./questionTypes";
import SubmitTestModal from "./submitTestModal";
import SubmittedSuccessTest from "./submitSuccess"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setDuration, setSelectedSubIndex, setStopTimer, toggleOutOfTimeModal, updateCurrentDuration } from "actions/studentTest";
import { TestTimer } from "./marketTestTimer";
import ExitSuccessModal from '../marketTest/exitModalTest'







export const orangecircle = (position_val) => (
    <div
        style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#FFA931",
            position: "absolute",
            top: position_val,
            right: position_val,
        }}
    ></div>
);

const answerStatus = [
    {
        title: "Attempted",
        key: "attempted",
        backgroundColor: Color.purple,
        border: `1px solid ${Color.midGrey}`,
        color: "white",
    },
    {
        title: "Unattempted",
        key: "unanswered",
        backgroundColor: Color.wrong,
        border: "none",
        color: "white",
    },
    {
        title: "Attempted & Marked for Review",
        key: "attemptedAndBookmarked",
        backgroundColor: Color.purple,
        border: "none",
        color: "white",
    },
    {
        title: "Unattempted & Marked for Review",
        key: "bookmarked",
        backgroundColor: Color.wrong,
        border: "none",
        color: "white",
    },
    {
        title: "Unseen",
        key: "unseen",
        backgroundColor: Color.white,
        border: `1px solid ${Color.black}`,
        color: "black",
    },
];

// const legand = (qCounts) => {
//     return (
//         <div className="legendPalette">
//             {answerStatus?.map((item, index) => (
//                 <div className="answerTypeContainer" key={index.toString()}>
//                     <div
//                         className="countBadge r-c-c"
//                         style={{
//                             backgroundColor: item.backgroundColor,
//                             border: item.border,
//                             color: item.color,
//                             position: "relative",
//                             marginRight: "10px",
//                         }}
//                     >
//                       {/* {item.key === "unseen" ? qCounts[item.key] - 1  <= 0 ? 0 :qCounts[item.key] - 1 : qCounts[item.key]} */}
//                       {/* {item.key === "unanswered" ? qCounts["unseen"] - 1  <= 0 ? 0 :qCounts[item.key] + 1 : qCounts[item.key]} */}
//                       {console.log('qCounts',qCounts)}
//                         <div>{qCounts ? qCounts[item.key] : 0}</div>
//                         {item?.key == "bookmarked" || item?.key == "attemptedAndBookmarked"
//                             ? orangecircle(-3)
//                             : null}
//                     </div>
//                     <div style={{ maxWidth: "50%", fontSize: "0.9vw" }}>
//                         {" "}
//                         {item.title}{" "}
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

const legand = (qCounts) => {
    // const checker = (qCounts,key) => {
    //   if (qCounts["unseen"] > 1) {
    //     switch (key) {
    //       case "unseen":
    //         qCounts[key] - 1;
    //         break;
    //       case "unanswered":
    //         qCounts[key] + 1;
    //         break;
    //       default:
    //         return qCounts[key];
    //     }
    //   } else {
    //     return qCounts[key];
    //   }
    // };

    let [quesCount, setQuesCount] = useState(qCounts);

    useEffect(() => {

        let unseen = qCounts.unseen
        let unanswerd = qCounts.unseen - 1 < 0 ? qCounts.unanswered : qCounts.unanswered + 1
        unseen = unseen - 1 < 0 ? 0 : unseen - 1
        if (qCounts.saveCheck ) {
            unseen = unseen - 1 < 0 ? 0 : unseen - 1
            unanswerd = unseen - 1 < 0 ? unanswerd : unanswerd + 1

        }
        setQuesCount({
            ...qCounts,
            unseen:unseen ,
            unanswered:unanswerd
                
        });
    }, [JSON.stringify(qCounts)])
    return (
        <div className="legendPalette">
            {answerStatus?.map((item, index) => (
                <div className="answerTypeContainer" key={index.toString()}>
                    <div
                        className="countBadge r-c-c"
                        style={{
                            backgroundColor: item.backgroundColor,
                            border: item.border,
                            color: item.color,
                            position: "relative",
                            marginRight: "10px",
                        }}
                    >
                        {/* {item.key === "unseen" ? qCounts[item.key] - 1  <= 0 ? 0 :qCounts[item.key] - 1 : qCounts[item.key]} */}
                        {/* {item.key === "unanswered" ? qCounts["unseen"] - 1  <= 0 ? 0 :qCounts[item.key] + 1 : qCounts[item.key]} */}
                        {/* {console.log("qCounts", qCounts)} */}
                        <div>{quesCount ? quesCount[item.key] : 0}</div>
                        {/* <div>{qCounts ? checker(qCounts,item.key) : 0}</div> */}

                        {item?.key == "bookmarked" || item?.key == "attemptedAndBookmarked"
                            ? orangecircle(-3)
                            : null}
                    </div>
                    <div style={{ maxWidth: "50%", fontSize: "0.9vw" }}>
                        {" "}
                        {item.title}{" "}
                    </div>
                </div>
            ))}
        </div>
    );
};


const actionButtons = (
    currentQ,
    answerObj,
    currentIndex,
    handleNavigateClick,
    buttonLoader,
    selectedStatus,
    totalQuestions,
    setLastIndex
) => {
    let saveDisabled = true;

    if (
        currentQ?.questionSnapshot?.type === "MSQ" &&
        answerObj?.answers?.length
    )
        saveDisabled = false;
    if (
        currentQ?.questionSnapshot?.type === "MCQ" &&
        answerObj?.answer != null
    )
        saveDisabled = false;
    if (
        currentQ?.questionSnapshot?.type === "Numeric" &&
        answerObj?.answer
    )
        saveDisabled = false
    if (
        currentQ?.questionSnapshot?.type === "T/F" &&
        answerObj?.answer != null
    )
        saveDisabled = false;

    if (currentQ?.questionSnapshot?.type === "OrQuestions") {
        let subType;
        if (currentQ?.questionSnapshot?.questionContent?.orQuestions)
            subType =
                currentQ?.questionSnapshot?.questionContent?.orQuestions[0]?.type;

        if (subType === "MSQ" && answerObj?.answers?.length)
            saveDisabled = false;
        if (subType === "MCQ" && answerObj?.answer)
            saveDisabled = false;
        if (subType === "Numeric" && answerObj?.answer)
            saveDisabled = false;
        if (subType === "T/F" && answerObj?.answer != null)
            saveDisabled = false;
    }
    if (currentQ?.questionSnapshot?.type === "Comprehensive") {
        let subType;
        if (currentQ?.questionSnapshot?.questionContent?.subQuestions)
            subType =
                currentQ?.questionSnapshot?.questionContent?.subQuestions[0]?.type;

        if (answerObj?.length) {
            for (let eachAns of answerObj) {
                if (subType === "MSQ" && eachAns?.answers?.length) saveDisabled = false;
                if (subType === "MCQ" && eachAns?.answer) saveDisabled = false;
                if (subType === "Numeric" && eachAns?.answer)
                    saveDisabled = false;
                if (subType === "T/F" && eachAns?.answer != null) saveDisabled = false;
            }
        }
    }

    return (
        <div className="actionButtons">
            <Button
                type="primary"
                shape="round"
                className="actionButton"
                loading={selectedStatus === "Attempted" && buttonLoader}
                style={{ backgroundColor: Color.purple, border: "none" }}
                onClick={() => {
                    handleNavigateClick("Attempted", "Attempted", currentIndex + 1, null, 'saveCheck');
                    setLastIndex(currentIndex == totalQuestions)
                }}
                disabled={saveDisabled}
            >
                {console.log('currentIndex', currentIndex, totalQuestions)}
                {currentIndex == totalQuestions ? "SAVE" : "SAVE & NEXT"}
            </Button>
            <Button
                type="primary"
                shape="round"
                className="actionButton"
                style={{ backgroundColor: Color.seaGreen, border: "none" }}
                loading={selectedStatus === "AttemptedAndBookmarked" && buttonLoader}
                onClick={() => {
                    handleNavigateClick("AttemptedAndBookmarked", "AttemptedAndBookmarked", currentIndex + 1, null, 'saveCheck');
                    setLastIndex(currentIndex == totalQuestions)

                }}
                disabled={saveDisabled}
            >
                SAVE & MARK FOR REVIEW
            </Button>
            <Button
                type="primary"
                shape="round"
                className="actionButton"
                style={{ backgroundColor: Color.yellow, border: "none" }}
                loading={selectedStatus === "Bookmarked" && buttonLoader}
                onClick={() => {
                    handleNavigateClick("Bookmarked", "Bookmarked", currentIndex + 1, null, 'saveCheck');
                    setLastIndex(currentIndex == totalQuestions)
                }}
                disabled={answerObj}
            >
                {currentIndex === totalQuestions
                    ? "MARK FOR REVIEW"
                    : "MARK FOR REVIEW & NEXT"}
            </Button>
            <Button
                shape="round"
                loading={selectedStatus === "Clear" && buttonLoader}
                className="actionButton"
                onClick={() => handleNavigateClick("Clear", "Unanswered", currentIndex, null, null)}
                disabled={!answerObj}
            >
                CLEAR RESPONSE
            </Button>
        </div>
    );
};

function OngoingTest({
    showMenu,
    startTest,
    qCounts,
    palateData,
    currentIndex,
    navigateQuestion,
    currentQ,
    updateAnswerObj,
    answerObj, setDuration, setStopTimer, updateCurrentDuration,
    selectedTest,
    timeLeft,
    stopTimer,
    toggleOutOfTimeModal,
    setSelectedSubIndex, currentDuration

}) {
    let timer = 0;
    const history = useHistory();
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedStatus, updateSelectedStatus] = useState(null);
    const [buttonLoader, toggleButtonLoader] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [showImgModal, toggleImageModal] = useState(false);
    const [SM_isModalVisible, setIsModalVisible] = useState(false);
    const [showSubmitSuccess, toggleSubmitSuccess] = useState(false);
    const [exitModal, setExitModal] = useState(false);
    const [isLastIndex, setLastIndex] = useState(false);


    // useEffect(()=>{

    // },[])

    // const [autoSubmitVisible, setAutoSubmitVisible] = useState(false);

    { console.log("selectedTest12", selectedTest) }


    const totalQuestions = palateData
        ?.at(-1)
        ?.sections?.at(-1)
        ?.questions?.at(-1)?.index;

    useEffect(() => {
        togglePageLoader(true)
        startTest(() => { togglePageLoader(false) });
    }, []);

    useEffect(() => {
        showMenu(false);
        setStopTimer(null)

        clearInterval(timer);
        updateCurrentDuration(0)
        TestTimer.startTimer(
            selectedTest,
            setDuration,
            updateCurrentDuration,
            handleAutoSubmit
        );

        return () => {
            TestTimer.clearTimer(setDuration);
            showMenu(true);
            setSelectedSubIndex(0);
        };
    }, [selectedTest])

    const handleAutoSubmit = () => {
        console.log('auto submit');
        SM_handleSubmit()


    };

    const SM_showModal = () => {
        setIsModalVisible(true);
    };

    const SM_handleCancel = () => {
        setIsModalVisible(false);
    };

    const SM_handleSubmit = () => {

        handleNavigateClick(null, undefined, currentIndex, "Submitted", null)
        setIsModalVisible(false);
        toggleSubmitSuccess(true);
        clearQuestions()
        setStopTimer(timeLeft)



    }




    // const SM_showModal = (item = null) => {
    //     // togglePageLoader(true);
    //     // selectQIndex(
    //     //     item = { qId: currentQ.id, index: currentIndex },
    //     //     (bool) => togglePageLoader(bool),
    //     //     () => setIsModalVisible(true)
    //     // );
    // };


    const handleNavigateClick = (dir, status, index, testStatus = null, saveCheck) => {


        console.log("auto status", saveCheck, dir);
        let callback;
        if (dir) {
            updateSelectedStatus(dir);
            toggleButtonLoader(true);
            callback = () => {
                toggleButtonLoader(false);
                updateSelectedStatus("");
            }
        }
        else {
            callback = () => {
                // if(testStatus == 'Submitted'){
                //     history.push('/courses-list')
                // }
            }
        }
        console.log("auto statusclear", saveCheck);

        if (dir === "Clear") updateAnswerObj(null);

        if (index > totalQuestions) index = totalQuestions;
        if (index < 1) index = 1;

        const item = {
            index,
            qId: currentQ?.id,
        };

        palateData?.forEach((subject) => {
            subject.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    if (question.index == index) item.qId = question.qId;
                });
            });
        });
        console.log("auto status5", saveCheck);
        navigateQuestion(item, status, testStatus, callback, saveCheck);
        console.log("auto status3", saveCheck);

    };

    console.log('palateData', palateData);

    return (
        <div
            className="TestScreen"
            style={{ height: "100%", minWidth: "580px", width: "100%" }}
        >
            <PageHeader
                style={{ backgroundColor: "#F6F4FE", width: "100%" }}
                title={<div style={{ minWidth: 1000 }}> {selectedTest?.title} </div>}
                onBack={() => {
                    setExitModal(true);
                }}
                extra={
                    <div className="leftInnerNav">
                        <div className="clockTime r-c-fs" style={{ alignItems: "center" }}>
                            <img
                                style={{ width: 25, height: 25 }}
                                src={require("Assets/ic-stop-watch.svg").default}
                            />
                            <span
                                className="text-md m-l-5"
                                style={{ color: "#594099", marginRight: 250 }}
                            >
                                <span className="text-md m-l-5" style={{ color: "#594099" }}>
                                    {stopTimer ? <span className="text-md m-l-5" style={{ color: "#594099" }}>
                                        {'00'}:{'00'}:{'00'}
                                    </span> : timeLeft?.hours <= 0 && timeLeft?.minutes <= 0 && timeLeft?.seconds <= 0 ?
                                        <span className="text-md m-l-5" style={{ color: "#594099" }}>
                                            {'00'}:{'00'}:{'00'}
                                        </span>
                                        :
                                        <span className="text-md m-l-5" style={{ color: "#594099" }}>
                                            {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                                        </span>
                                    }
                                </span>
                            </span>
                        </div>

                        <div className="innerNavBtn">
                            {palateData?.map((item, index) => (
                                <Button
                                    key={index}
                                    onClick={() => { handleNavigateClick(null, undefined, item?.startIndex, null, null) }}
                                    style={
                                        (item?.startIndex <= currentIndex <= item?.endIndex)
                                            ? {
                                                backgroundColor: "#E6E6E6",
                                                color: "#191919",
                                                border: "none",
                                            }
                                            : null
                                    }
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {item?.subject?.name}
                                    </div>
                                </Button>
                            ))}
                        </div>
                    </div>
                }
            />

            <div className="testContainer">
                <div className="lqPanel">
                    <div
                        style={{
                            height: "100%",
                            borderRight: `1px solid ${Color.midGrey}`,
                        }}
                    >
                        <div className="questionContainer">
                            <div className="questionHeader">
                                <span>Q {currentIndex}</span>
                                <div className="leftText r-c-sa dark-grey">
                                    <span>
                                        <b>Marks: </b> + {currentQ?.questionSnapshot?.positiveMarks}{" "}
                                        {`  - ${currentQ?.questionSnapshot?.negativeMarks}`}
                                    </span>
                                    <span>
                                        {" "}
                                        {/* <b>Time: </b>
                                    {Math.floor(currentDuration / 60).toLocaleString(2)} :{" "}
                                    {Math.floor(currentDuration % 60).toLocaleString(2)}{" "} */}
                                    </span>
                                </div>
                            </div>

                            <QuestionTypes
                                currentIndex={currentIndex}
                                toggleImageModal={toggleImageModal}
                                updateAnswerObj={updateAnswerObj}
                                answerObj={answerObj}
                                currentQ={currentQ}
                                setCurrentImg={setCurrentImg}
                            />
                        </div>

                        <div className="actionContainer">
                            {actionButtons(
                                currentQ,
                                answerObj,
                                currentIndex,
                                handleNavigateClick,
                                buttonLoader,
                                selectedStatus,
                                totalQuestions,
                                setLastIndex
                            )}
                            <div className="footerBtns">
                                <div className="qNavBtn">
                                    <Button
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                        disabled={currentIndex <= 1}
                                        onClick={() => handleNavigateClick("prev", undefined, currentIndex - 1, null, null)}
                                    >
                                        {selectedStatus === "prev" && buttonLoader ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <LeftOutlined />
                                        )}
                                        PREVIOUS
                                    </Button>
                                    <Button
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                        disabled={currentIndex >= totalQuestions}
                                        onClick={() => handleNavigateClick("next", undefined,
                                            currentIndex + 1, null, null)}
                                    >
                                        NEXT
                                        {selectedStatus === "next" && buttonLoader ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <RightOutlined />
                                        )}
                                    </Button>
                                </div>
                                <div className="cursor-pointer" style={{
                                    width: '190px',
                                    height: '40px',
                                    background: '#594099 0% 0% no-repeat padding-box',
                                    border: '1px solid #594099',
                                    borderRadius: '27px',
                                    opacity: 1,
                                    color: '#FAFAFA',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    padding: '5px 30px 5px 60px'
                                }}>
                                    <span

                                        onClick={() => { setIsModalVisible(true) }}
                                    >
                                        {" "}
                                        SUBMIT{" "}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rqPanel">
                    <div style={{ height: "100%" }}>
                        {legand(qCounts)}
                        <MarketTestPalette
                            palateData={palateData}
                            currentQIndex={currentIndex}
                            onQuestionSelect={handleNavigateClick}
                            isResult={false}
                        />
                    </div>
                </div>
            </div>

            <SubmitTestModal
                visible={SM_isModalVisible}
                isLastIndex={isLastIndex}
                handleCancel={SM_handleCancel}
                handleSubmit={SM_handleSubmit}
                setStopTimer={setStopTimer}
            />
            <ViewImgModal
                visible={showImgModal}
                imgPath={currentImg}
                onCancel={() => toggleImageModal(false)}
            />
            <SubmittedSuccessTest
                item="beforeResults"
                isModalVisible={showSubmitSuccess}
                history={history}
                close={() => toggleSubmitSuccess(false)}
            />
            <ExitSuccessModal
                item="beforeResults"
                isModalVisible={exitModal}
                history={history}
                close={() => setExitModal(false)}
            />
            <PageDataLoader visible={pageLoader} />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { qCounts, palateData, currentIndex, currentQ, answerObj } = state.marketTest;
    const {
        selectedTest,
        timeLeft,
        currentDuration,
        stopTimer,
    } = state.studentTest;

    return {
        qCounts,
        palateData,
        currentIndex,
        currentQ,
        answerObj,
        selectedTest,
        timeLeft,
        currentDuration,
        stopTimer,
    };
};

const mapDispatchToProps = (dispatch) => ({
    showMenu: (bool) => dispatch(showMenu(bool)),
    startTest: (callback) => dispatch(startTest(callback)),
    navigateQuestion: (item, status, testStatus, buttonLoaderCallBack, saveCheck) =>
        dispatch(navigateQuestion(item, status, testStatus, buttonLoaderCallBack, saveCheck)),
    updateAnswerObj: (data) => dispatch(updateAnswerObj(data)),
    clearQuestions: () => dispatch(clearQuestions()),
    setStopTimer: (res) => dispatch(setStopTimer(res)),
    setDuration: (duration) => dispatch(setDuration(duration)),
    updateCurrentDuration: (seconds) => dispatch(updateCurrentDuration(seconds)),
    toggleOutOfTimeModal: (value) => dispatch(toggleOutOfTimeModal(value)),
    setSelectedSubIndex: (index) => dispatch(setSelectedSubIndex(index)),





});

export default connect(mapStateToProps, mapDispatchToProps)(OngoingTest);
