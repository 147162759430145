import { Button, Result } from 'antd';
import React from 'react'
import { useHistory } from 'react-router-dom';

function SubmitSuccess() {
    const history = useHistory();

    return (
        <div className="r-c-c-c" style={{height: "100%"}}>
            <Result
                status="success"
                title="Successfully Submitted!"
                subTitle="Your details have been submitted successfully. You can start using aulas care now!"
            >
                
            </Result>
            <div className="r-c-c">
                <Button type="primary" shape="round" onClick={() => history.push('/care/admin')}>
                    Get Started
                </Button>
            </div>
        </div>
    )
}

export default SubmitSuccess;