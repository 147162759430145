import React, { useEffect, useState } from 'react'
import TopBar from './topBar'
import Footer from './footer'
import "./saveForLater.css"
import RatingStars from './ratingStars'
import { Button } from 'components/Button'
import RemoveModal from './removeModal'
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal'
import arrowUpIcon from "../../Assets/aulasMarket/ic-chevron-up.svg"
import arrowDownIcon from "../../Assets/aulasMarket/ic-chevron-down.svg"
import NoData from "../../Assets/aulasMarket/ill-no-data.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import { getCartCount, getCheckOutItem, getSaveForLater, postAddToCartSave, postCourseEnrollNow, setRenderPage, storeCourseIdAndType, storeSingleBuyNow } from 'actions/studentMarketPlace'
import PageDataLoader from 'components/pageDataLoader'
import { Api } from 'services'
import { Tooltip } from 'antd'
import MobilePageHeader from './mobilePageHeader'
import { showMenu } from 'actions/layout'
import { CheckCircleFilled } from '@ant-design/icons';
import HtmlParser from 'react-html-parser';
import NewFooter from './newFooter'
import DownloadAppModal from './downloadAppModal'

const defaultCourseImage = require('Assets/default_course-img.svg').default;


function SavedForLater({ getSaveForLater, getCheckOutItem, postCourseEnrollNow, renderPageLoading, setRenderPage, storeSingleBuyNow, postAddToCartSave, showMenu, getCartCount, storeCourseIdAndType, isDownloadBarVisible, userMarketPlaceHomeData, showContactBar }) {
    const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
    const [removeId, setRemoveId] = useState({});
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [pageLoader, setToggleLoader] = useState(false);
    const [saveForLaterList, setSaveForLaterList] = useState([]);
    const [shareUrl, setShareUrl] = useState();
    const [arrowStates, setArrowStates] = useState({});
    const [cart, setCart] = useState({});
    const history = useHistory();
    const [enroll, setEnroll] = useState({})
    const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)

    useEffect(() => {
        setToggleLoader(true);
        getSaveForLater((res) => {
            setSaveForLaterList(res);
            setToggleLoader(false);
        })
    }, [renderPageLoading])

    const SaveLaterCourseCard = ({ item }) => {
        const handleRemoveId = (id, itemType) => {
            setRemoveId({
                itemId: id,
                itemType: itemType,
            });
            setRemoveModalVisible(true);
        }

        const handleAddToCart = (id, itemType) => {
            postAddToCartSave(id, itemType, (res) => {
                if (res) {
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            })
        }

        const toggleArrowState = (itemId) => {
            setArrowStates((prevStates) => ({
                ...prevStates,
                [itemId]: !prevStates[itemId],
            }));
        };

        const handleBuyNow = (itemId, itemType) => {
            setToggleLoader(true);
            history.push({ pathname: '/user-checkout', state: { itemId, itemType } });
        }
        const handleEnroll = (id, itemType) => {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        };
        function IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
        function isHTML(str) {
            var a = document.createElement('div');
            a.innerHTML = str;

            for (var c = a.childNodes, i = c.length; i--;) {
                if (c[i].nodeType === 1) return true;
            }
            return false;
        }

        return (
            <div className='save-parent-container'>
                <div className='img-card-parent'
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        storeCourseIdAndType("id", item?.id);
                        storeCourseIdAndType("type", item?.resourceType)
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                            }
                        })

                    }}>
                    {item?.resourceType == 'studyMaterial' ?
                        (<>
                            <div style={{ height: "100px", paddingTop: '10px' }}>
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 2, marginTop: "9px" }}>
                                    <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50, marginRight: "20px" }} />
                                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                            </div>
                        </>) :
                        <img className='cart-img-card' src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} />}
                    {item?.mode == null ? <></> : (<>
                        <div className="card-mode-label" style={{ backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div>
                    </>)}
                    {item?.amount > 0 ? <div className='card-amount-label'>₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0)}</span> : <></>}</div> : <></>}
                </div>
                <div className='save-title-parent'>
                    <div className='save-card-title' >{item?.resourceType == 'course' || item?.resourceType == 'videoResource' ? item?.courseName : item?.name}
                        <span className='mobile-new-tag' style={{ marginLeft: 5 }}>{item?.new === "1" ? "NEW" : null}</span>
                    </div>
                    <div className='save-card-description'>
                        <Tooltip title={IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}>
                            {IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}
                        </Tooltip>
                    </div>

                    <div className='r-c-sb m-t-10 mobile-disable'>
                        <div className='r-c-fs' style={{ width: "40%" }} >
                            {item?.bestseller ?
                                <div className='save-best-seller'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='r-c-fs '><RatingStars star={item?.rating} />
                                    <span style={{ marginLeft: 5 }}>({item?.ratingCount})</span>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        {item?.owned == 1 ?
                            <Button onClick={() => { /* showMenu(true); history.push("/courses-list") */ setShowDownloadAppModal(true)}} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "10vw", fontSize: 13, marginTop: 5 }}>VIEW COURSE</Button>
                            :
                            <div className='r-c-c'>
                                {enroll[item?.id] || item?.owned == 1 ?
                                    <div style={{ display: "flex", alignItems: "center", margin: "30px 0px" }}>
                                        <CheckCircleFilled style={{ fontSize: 24, marginRight: 10, color: "#61E3A7" }} />
                                        <div style={{ color: "#594099", fontSize: 18, fontWeight: "bold" }}>ENROLLED</div>
                                    </div>
                                    :
                                    (<>
                                        {
                                            item?.amount == 0 || item?.resourceType == "course" ? <></> :
                                                <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, width: 140, height: 35 }} onClick={(e) => {
                                                    e.stopPropagation()
                                                    if ((item?.inCart == 1 || cart[item?.id])) {
                                                        history.push("/cart")
                                                    } else {
                                                        handleAddToCart(item?.id, item?.resourceType)
                                                        getCartCount()
                                                    }
                                                }}>{item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                        }
                                        <Button type="primary" onClick={() => {
                                            if (item?.amount == 0) {
                                                handleEnroll(item?.id, item?.resourceType)
                                            } else {
                                                handleBuyNow(item?.id, item?.resourceType)
                                            }
                                        }} style={{ width: 140, height: 35 }}>{item?.amount == 0 || item?.resourceType == "course" ? "ENROLL NOW" : "BUY NOW"}</Button>
                                    </>)
                                }
                            </div >
                        }
                    </div >
                    <div className='mobile-enable'>
                        <div className='r-c-fs m-t-5' style={{ width: "100%" }} >
                            {item?.bestseller ?
                                <div className='save-best-seller'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='mobile-rating'><img className='mobile-star' src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /><span style={{ color: "#191919", fontWeight: "550" }}>{item?.rating}</span>
                                    <span style={{ marginLeft: 5 }}>({item?.ratingCount})</span>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <div className="r-c-fe">
                            <span className='save-share'><img className='save-share-img' onClick={() => {
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                            }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} /></span>
                            <span className='save-share' onClick={() => { toggleArrowState(item?.id); }}><img className='save-arrow-img' src={arrowStates[item?.id] ? arrowUpIcon : arrowDownIcon} /></span>
                            {/* <span style={{ fontWeight: 600, color: "#FF414D", fontSize: 12, cursor: "pointer" }}>REMOVE</span> */}
                        </div>
                    </div>
                    {
                        item?.amount > 0 && (
                            <div className='mobile-enable m-t-10 font-bold' style={{ fontSize: 12 }}>
                                ₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}
                                {item?.discountAmount > 0 && (
                                    <>
                                        <span style={{ color: '#636363', marginLeft: 5, textDecoration: 'line-through', fontWeight: "normal", fontSize: 10 }}>
                                            ₹ {(parseFloat(item?.amount).toFixed(2))}
                                        </span>
                                        <span style={{ color: "#28DF99", marginLeft: 5, fontSize: 10, fontWeight: "normal", fontSize: 10 }}>{(parseFloat(item?.discountPercent).toFixed(0))}% off</span>
                                    </>
                                )}
                            </div>
                        )
                    }

                    {
                        arrowStates[item?.id] ?
                            <div className='r-c-sb m-t-10 font-bold'>
                                {item?.mode == "free" || item?.amount == 0 || item?.resourceType == "course" ?
                                    <span className='mobile-add' onClick={() => handleEnroll(item?.id, item?.resourceType)} >ENROLL NOW</span>
                                    : (<>
                                        <span className='mobile-add' onClick={() => {
                                            {
                                                item?.inCart === "1" ?
                                                    history.push("/cart")
                                                    : handleAddToCart(item?.id, item?.resourceType); getCartCount()
                                            }
                                        }}>{item?.inCart === "1" || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</span>
                                        <span className='mobile-buy' onClick={() => handleBuyNow(item?.id, item?.resourceType)} >BUY NOW</span>
                                        <span className='mobile-delete' onClick={() => handleRemoveId(item?.id, item?.resourceType)}>DELETE</span>                                    </>)
                                }
                            </div>
                            : null
                    }
                    <div className='m-t-10 mobile-disable'>
                        <span className='save-share' onClick={() => {
                            setShareModalVisible(true);
                            setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                        }}><img className='save-share-img' src={require("../../Assets/aulasMarket/shareIcon.svg").default} /><span className='m-l-5'>SHARE</span></span>
                        <span className='save-remove' onClick={() => handleRemoveId(item?.id, item?.resourceType)} >REMOVE</span>
                    </div>
                </div >
            </div >
        )
    }

    const NoItems = () => {
        return (
            <div>
                <div className='r-c-c-c' style={{ width: "100%", height: "80vh" }}>
                    <div className='empty-save-item r-c-c' ><img src={NoData} style={{ width: "100%" }} /></div>
                    <div className='font-bold m-t-20' style={{ color: "#191919", fontSize: 16 }}>No course saved!</div>
                    <div className='' style={{ color: "#636363", fontSize: 14 }}>You have not saved any courses yet!</div>
                    <div className='m-t-30'>
                        <Button type="secondary" style={{ color: "#594099", border: "1px solid #594099" }} onClick={() => { history.push("/") }}>EXPLORE COURSES</Button>
                    </div>
                </div>
            </div>
        )
    }
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const CalculateMarginTop = () => {
        if (isDownloadBarVisible && showContactBar) {
            return 160
        } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
            return 110
        } else if (!isDownloadBarVisible && !showContactBar) {
            return 60
        }
    }
    return (
        <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div className='mobile-topbar-parent'>
                <TopBar isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked} />
            </div>
            <div className='mobile-header-parent'>
                <MobilePageHeader title={"Saved for Later"}
                    onBackPress={() => { history.goBack() }} sider={true} />
            </div>
            <div style={{ backgroundColor: "#FAFAFA", minHeight: "calc(100vh - 50px)" }}>
                <div className='save-main-container'>
                    <div className='mobile-disable-save-header' >Saved for later ss {saveForLaterList?.length > 0 ? <span style={{ color: "#AEAEAE" }}>({saveForLaterList?.length})</span> : <></>}</div>
                    {saveForLaterList?.length > 0 ?
                        <div className='save-card-container'>
                            {saveForLaterList?.map((item, index) => (
                                <div key={index} className='save-card-border'>
                                    <SaveLaterCourseCard item={item[item?.itemType]} />
                                </div>
                            ))}
                        </div>
                        :
                        <NoItems />
                    }
                </div>
            </div>
            <NewFooter/>
            {isRemoveModalVisible &&
                <RemoveModal
                    isRemoveModalVisible={isRemoveModalVisible}
                    setRemoveModalVisible={setRemoveModalVisible}
                    removeId={removeId}
                />
            }
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl}
                />}
            {showDownloadAppModal &&
                <DownloadAppModal
                    showDownloadAppModal={showDownloadAppModal}
                    setShowDownloadAppModal={setShowDownloadAppModal}
                    userMarketPlaceHomeData={userMarketPlaceHomeData}
                />}
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { renderPageLoading, isDownloadBarVisible, showContactBar, userMarketPlaceHomeData } = state.studentMarketPlace
    return { renderPageLoading, isDownloadBarVisible, showContactBar, userMarketPlaceHomeData }
};

const mapDispatchToProps = (dispatch) => ({
    getSaveForLater: (callback) => dispatch(getSaveForLater(callback)),
    postAddToCartSave: (id, itemType, callback) => dispatch(postAddToCartSave(id, itemType, callback)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    setRenderPage: (val) => dispatch(setRenderPage(val)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    getCartCount: () => dispatch(getCartCount()),
    storeCourseIdAndType: (key, val) => dispatch(storeCourseIdAndType(key, val))
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedForLater)
