import React, { useEffect, useRef, useState } from 'react'
import "./myAcc.css"
import Search from 'antd/lib/input/Search'
import dropDownIcon from "../../Assets/aulasMarket/ic-arrow-head-down.svg"
import dropUpIcon from "../../Assets/aulasMarket/ic-arrow-head-up.svg"
import { Dropdown, Menu, Rate, Button, Progress, Modal, Tooltip, Col, Collapse, Divider, Row, AutoComplete } from 'antd'
import { AddSaveLater, AllCoursesData, AttemptQuiz, CoursePageApi, LikeApi, MyLiveCourses, MyResource, MyResourse, MyStudyMaterial, QArequist, QuizResults, RemoveSaveLater, SubmitQuiz, VideoResourcePageApi, ViewSolutions, setAllCourseData, setCoursePage, setItemId, setLoader, setMyResources, setPopularRender, setQuizData, setTestSeriesData } from 'actions/aulasMarket'
import courseImage from '../../Assets/aulasMarket/teamgrp.png';
import checkIcon from "../../Assets/aulasMarket/ic-check-filled.svg";
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFill from "../../Assets/aulasMarket/saveFilledIcon.svg"
import CourseProfile from './courseProfile'
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import chemistry from "../../Assets/aulasMarket/sbj-chemistry.svg"
import physics from "../../Assets/aulasMarket/sbj-physics.svg"
import computer from "../../Assets/aulasMarket/sbj-computerscience.svg"
import english from "../../Assets/aulasMarket/sgj-english.svg"
import botany from "../../Assets/aulasMarket/sbj-botany 1.svg"
import { ArrowLeftOutlined, CaretDownOutlined, CaretRightOutlined, DownOutlined, FilterOutlined, UpOutlined } from '@ant-design/icons';
import DropDownCheckBox from 'pages/curriculum/dropDownCheckBox'
const { Panel } = Collapse;
import ArrowUp from "../../Assets/aulasMarket/ic-arrow-head-up.svg"
import ArrowDown from "../../Assets/aulasMarket/ic-arrow-head-down.svg"
import SearchCompo from 'components/SearchCompo'
import videoGrey from "../../Assets/aulasMarket/previewLightColor.png"
import VideoColor from "../../Assets/aulasMarket/previewDarkColor.png"
import pdfIcon from "../../Assets/aulasMarket/icons8-pdf-40.png"
import quizIcon from "../../Assets/aulasMarket/ic-practice-test.svg"
import ratingProfile from "../../Assets/aulasMarket/ratingIcon.png"
import LikedOutlined from "../../Assets/aulasMarket/likeOutlined.svg"
import LikedColored from "../../Assets/aulasMarket/likeColored.svg"
import questionMarkIcon from "../../Assets/aulasMarket/questionMark.svg"
import correctIcon from "../../Assets/aulasMarket/correctIcon.svg"
import wrongIcon from "../../Assets/aulasMarket/wrongIcon.svg"
import unattemptedIcon from "../../Assets/aulasMarket/unattemptedIcon.svg"
import closeWrong from "../../Assets/aulasMarket/CloseCircle.svg"
import attempted from "../../Assets/aulasMarket/ic-attempted.svg"
import { Api } from 'services'
import noImage from "../../Assets/aulasMarket/no-image.svg"
import StudyMaterial from './studyMaterial'
import TestSeriesProfile from './testSeriesProfile'
import { logout } from 'actions/login'
import ReactPlayer from 'react-player'
import AddRatingModal from './addRatingModal'
import NoData from "../../Assets/aulasMarket/empty-courses.png";
import ReactPlayerHoc from 'components/ReactPlayerHoc'


const liveCourses = [
    {
        name: "course 1",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "online",
        amount: "₹3,199",
        free: false,
        uid: 1,
        new: true,
        Live: true,
        progress: 50,
    },
    {
        name: "course 2",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "hybrid",
        amount: "₹3,199",
        free: false,
        new: true,
        Live: true,
        uid: 2,
        progress: 70,
    },
    {
        name: "course 3",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "offline",
        uid: 3,
        amount: "₹3,199",
        free: false,
        new: false,
        Live: true,
        progress: 90,
    },
    {
        name: "course 4",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "online",
        amount: "₹3,199",
        free: false,
        new: true,
        Live: true,
        uid: 4,
        progress: 20,
    },
    {
        name: "course 5",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "hybrid",
        amount: "₹3,199",
        free: false,
        new: true,
        Live: true,
        uid: 5,
        progress: 100,
    },
    {
        name: "course 6",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "online",
        amount: "₹3,199",
        free: false,
        new: false,
        Live: true,
        uid: 6,
        progress: 30,
    },
    {
        name: "course 7",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "Offilne",
        amount: "₹3,199",
        free: false,
        new: true,
        Live: true,
        uid: 7,
        progress: 0,
    },
    {
        name: "course 8",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        mode: "online",
        amount: "₹3,199",
        free: false,
        new: false,
        Live: true,
        uid: 8,
        progress: 100,
    },
];
const testData = [
    {
        name: "125 tests",
        description: "JEE Mains - 2023 test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
        progress: 50,
    },
    {
        name: "125 tests",
        description: "National Eligibility cum Entrance Test",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
        progress: 40,
    },
    {
        name: "125 tests",
        description: "NEET Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: false,
        progress: 20,
    },
    {
        name: "125 tests",
        description: "NEET Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
        progress: 70,
    },
    {
        name: "125 tests",
        description: "National Eligibility cum Entrance Test (NEET) Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: false,
        progress: 100,
    },
    {
        name: "125 tests",
        description: "National Eligibility cum Entrance Test (NEET) Test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
        progress: 50,
    },
    {
        name: "125 tests",
        description: "JEE Mains - 2023 test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: false,
        progress: 60,
    },
    {
        name: "125 tests",
        description: "JEE Mains - 2023 test series",
        rating: 4,
        totalRatings: 215,
        tokenFree: true,
        progress: 20,
    },
]

export const LiveCourses = ({ setPopularRender, loader, setLoader, token, myResourceData }) => {
    return (
        <>
            <div className='parent-compo'>
                <div className='container-1'>
                    <div className='text-sm font-bold' onClick={() => { setPopularRender(0); console.log("clickedback") }}  ><img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer" }} /><span className='m-l-10 m-t-5'>Live Courses</span></div>
                </div>
                <div className='container-2'>
                    <CourseProfile live={true} />
                </div>
            </div>
        </>
    )
}

const PdfView = ({ file, setPopularRender }) => {
    console.log("fileurl", file)
    return (
        <>
            <div className='parent-compo'>
                <div className='conatiner-1'>
                    <div className='text-sm font-bold' onClick={() => setPopularRender(2)}>< ArrowLeftOutlined /><span className='m-l-10 m-t-5'>{file?.name}</span></div>
                </div>
                <iframe
                    width="100%"
                    style={{ height: "100vh" }}
                    // height="100%"
                    src={`${Api.dlS3Url}${file?.url}#toolbar=0`}
                    frameborder="0"
                ></iframe>


            </div>
        </>
    )
}

export const VideoCourses = ({ setPopularRender, setPDFFile, quizData, setQuizData, myResourceData, coursePageData, setMyResources, loader, setLoader, setCoursePage, token, itemId }) => {
    const [results, setResults] = useState();
    const [quiz, setQuiz] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [viewSolution, setViewSolution] = useState(false);
    const [quizAttempt, setQuizAttempt] = useState(false);
    const [quizId, setQuizId] = useState(null);
    const [videoUrl, setVideoUrl] = useState(coursePageData?.videoResourceId?.preview);
    const [paramAttemptQuiz, setParamAttemptQuiz] = useState({
        id: quizId,
        itemId: coursePageData?.videoResource?.id,
        type: "videoResource",
    });

    console.log("quiz", quiz, "submit", submit, "viewaolution", viewSolution, "quizattempt", quizAttempt);
    console.log("videooooo", coursePageData?.videoResource)
    useEffect(() => {
        setLoader(true);
        MyResource(token, itemId, (res) => {
            if (res) {
                setCoursePage(res);
            }
        }, setLoader)
    }, [])

    useEffect(() => {
        if (quiz && !quizAttempt) {
            setLoader(true);
            AttemptQuiz(paramAttemptQuiz, (res) => {
                if (res) {
                    setQuizData(res);
                    console.log("qaqaqaqa", res);
                }
            }, setLoader)
        } else if (quizAttempt) {
            QuizResults(paramAttemptQuiz, (res) => {
                if (res) {
                    setQuizData(res);
                    setResults(res?.result)
                }
            }, setLoader)
        }
    }, [quiz])



const VideoResources = ({ data, fullData }) => {
        const destruct = data?.questions;
        console.log("vvvvvvvvid", data);

        const [search, setSearch] = useState('');
        const [param, setParam] = useState({
            type: "question",
            videoResourceId: coursePageData?.videoResource?.id,
            question: null,
        })

        const handleSearch = (e) => {
            console.log('searching', e.target.value);
            setSearch(e.target.value);
            setParam({
                type: "question",
                videoResourceId: coursePageData?.videoResource?.id,
                question: e.target.value,
            })
        }
        const handlePost = () => {
            QArequist(param);
        }
        const [viewAll, setViewAll] = useState(false);
        const itemsToShow = viewAll ? coursePageData?.qa?.length : 6;
        const toggleViewAll = () => {
            setViewAll(!viewAll);
        };

        const PanelList = ({ data }) => {
            console.log("insidePanelList", data?.file?.url);
            setPDFFile(data?.fileUrl)
            setQuizId(data?.quizId);
            return (
                <>
                    {data?.type === "video" ? <>
                        <div className='r-c-sb' style={{ marginTop: "5px" }} >
                            <img style={{ width: "35px" }} src={data?.preview ? VideoColor : videoGrey} />
                            <div className='r-c-c-fs m-l-5' style={{ width: "80%", height: "50px" }}>
                                <span onClick={() => { setQuiz(false); setViewSolution(false); setVideoUrl(data?.file?.url) }} style={{
                                    position: "relative",
                                    top: "8px",
                                    cursor: "pointer"
                                }}>{data?.name}
                                </span>
                                <Progress style={{ width: "90%" }} strokeColor="#594099" percent={data?.progress} size="small" />
                            </div>
                            <div style={{ fontSize: "10px" }}>{data?.duration}</div>
                        </div>
                    </> : null}
                    <div className='r-c-sb'>
                        {data?.quiz?.name ? (
                            <div className='cursor-pointer' onClick={() => {
                                setQuiz(true);
                                setParamAttemptQuiz(prevParam => ({
                                    ...prevParam,
                                    id: data?.quizId,
                                    itemId: coursePageData?.videoResource?.id,
                                    type: "videoResource",
                                }));
                                    if (data?.attempted) {
                                        setQuizAttempt(true);
                                        setResults(true);
                                    }
                            }}>
                                <img style={{ width: "35px", marginLeft: "2px" }} src={quizIcon} />
                                {data.quiz.name}
                            </div>
                        ) : null}

                    </div>
                    <div className='r-c-sb'>
                        {data?.type === "notes" ? (
                            <div className='cursor-pointer' onClick={() => { setPopularRender(4) }}><img style={{ width: "35px", marginLeft: "2px" }} src={pdfIcon} />{data?.name}</div>
                        ) : null}
                    </div>


                    {/* {data?.courseFaq ? (
                        <div onClick={() => setPopularRender(4)}><img style={{ width: "35px" }} src={pdfIcon} />{data?.courseFaq}</div>
                    ) : null} */}
                </>
            )
        }

        const Review = ({ rList }) => {
            console.log("reviewwwwww", rList);
            const [liked, setLiked] = useState(false);
            const [likeCount, setLikeCount] = useState(0);

            // Add param to the dependency array to make sure it's updated when 'param' changes
            const handleLike = (id) => {
                const newLiked = !liked;
                setLiked(newLiked);
                LikeApi(id, coursePageData?.videoResource?.id, !newLiked, (res) => {
                    if (res) {
                        console.log("naveen", res?.likeCount)
                        setLikeCount(res?.likeCount);
                    }
                });
            };


            const DateDisplay = ({ date }) => {
                // Parse the backend date string into a JavaScript Date object
                const backendDate = new Date(date);

                // Calculate the time difference in milliseconds
                const timeDifference = Date.now() - backendDate.getTime();

                // Calculate the number of days
                const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

                // Calculate the number of hours
                const hoursDifference = Math.floor(timeDifference / (1000 * 3600));

                // Calculate the number of minutes
                const minutesDifference = Math.floor(timeDifference / (1000 * 60));

                // Display different formats based on elapsed time
                if (daysDifference > 0) {
                    return <div>{daysDifference} {daysDifference === 1 ? 'day' : 'days'} ago</div>;
                } else if (hoursDifference > 0) {
                    return <div>{hoursDifference} {hoursDifference === 1 ? 'hour' : 'hours'} ago</div>;
                } else if (minutesDifference > 0) {
                    return <div>{minutesDifference} {minutesDifference === 1 ? 'minute' : 'minutes'} ago</div>;
                } else {
                    return <div>Now</div>;
                }
            };


            return (
                <>
                    <div className='m-t-15' style={{ display: "flex" }}>
                        <div style={{
                            position: " relative",
                        }}>
                            <img style={{ borderRadius: "100%", width: "50px", height: "50px" }} src={`${Api._s3_url}${rList?.image}`} />
                        </div>
                        <div className='m-l-10'>
                            <span className='font-bold text-xmd' style={{ marginBottom: "20px" }} >{rList?.question}</span>
                            <div style={{ fontSize: "14px", paddingTop: "5px" }}>{rList?.answer}</div>
                            <div className='font-bold' style={{ fontSize: "14px", display: "flex", justifyContent: "space-between", paddingTop: "5px", width: "950px" }}>
                                <div className='r-c-fs'>{likeCount ? likeCount : rList?.likeCount} likes
                                    <div style={{ display: "flex", marginLeft: "10px" }} onClick={() => handleLike(rList?.id)}><img src={liked ? LikedColored : rList?.liked ? LikedColored : LikedOutlined} style={{ width: "20px", cursor: "pointer" }} /></div>
                                </div>

                                <div className='r-c-fe' style={{ fontWeight: "lighter" }}>{rList?.username}
                                    <div style={{
                                        width: "8px", height: "8px", borderRadius: "50%",
                                        display: "inline-block",
                                        margin: "0 10px",
                                        border: "1px solid black",
                                        backgroundColor: "black",
                                        opacity: 0.4
                                    }}>
                                    </div>
                                    <div><DateDisplay date={rList?.createdAt} /></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </>
            )
        }

        const QuizDesign = ({ data }) => {
            console.log("qzzzzz", data);
            const [selectedOption, setSelectedOption] = useState(null);
            const [step, setStep] = useState(0);
            const [selected, setSelected] = useState(false);
            const [currentIndex, setCurrentIndex] = useState(0);
            const [paramsQuestion, setParamsQuestion] = useState(data?.map(() => ({ id: null, attempt: { answer: null } })));// Initialize with an array of objects


            const handleSubmit = (id) => {
                console.log("qqqaaid", id)
                setLoader(true);
                console.log("paramAttemptQuiz",paramAttemptQuiz)
                SubmitQuiz(paramsQuestion, paramAttemptQuiz, (res) => {
                    if (res) {
                        setResults(res?.result)
                        setQuiz(false);
                        setSubmit(true)
                    }
                }, setLoader);
            }

            const handleNext = () => {
                if (step < data?.length - 1) {
                    setStep(step + 1);
                    setSelected(false)
                    setCurrentIndex((prevIndex) => (prevIndex + 1 < data.length ? prevIndex + 1 : prevIndex));
                }
            };

            const handlePrevious = () => {
                if (step > 0) {
                    setStep(step - 1);
                    setCurrentIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
                }
            };

            const handleOptionClick = (index, selectedOption, newId, dataList) => {
                setSelectedOption(index);
                setSelected(true);
                setParamsQuestion((prevParams) =>
                    prevParams.map((param, paramIndex) => {
                        if (paramIndex === currentIndex) {
                            if (dataList?.type === 'MCQ') {
                                return { ...param, id: newId, attempt: { answer: selectedOption } };
                            } else if (dataList?.type === 'MSQ') {
                                // Initialize an array if not already set
                                const selectedAnswers = param.attempt.answer || [];
                                return {
                                    ...param,
                                    id: newId,
                                    attempt: { answer: selectedAnswers.concat(selectedOption) }
                                };
                            } else if (dataList?.type === 'T/F') {
                                return { ...param, id: newId, attempt: { answer: selectedOption === 0 ? 0 : 1 } };
                            }
                        }
                        return param;
                    })
                );
            };

            // console.log("clickedparams", paramsQuestion);

            // Assuming you fetch or set noQs data from an API or other source

            const Questions = ({ dataList, index, selectedOption, onOptionClick }) => {
                console.log("lslslsls", dataList?.type)

                return (
                    <div className='font-bold'>
                        <div className='r-c-fe m-r-15 font-bold' style={{ color: '#594099' }}>
                            {dataList?.positiveMarks}Marks
                        </div>
                        <div className=''>{[index + 1]}  {dataList?.questionContent?.statement}</div>
                        {dataList?.questionContent?.options?.map((option, optionIndex) => {
                            return (
                                <div
                                    key={optionIndex}
                                    className={`r-c-fs p-15 cursor-pointer option m-l-20 ${selectedOption === optionIndex ? 'selected' : ''}`}
                                    onClick={() => onOptionClick(index, optionIndex, dataList?.id, dataList)}
                                >
                                    {option?.value}
                                </div>
                            );
                        })}
                        {(!dataList?.questionContent?.options || dataList?.questionContent?.options.length === 0) &&
                            dataList?.type === 'T/F' && (
                                <div>
                                    <div
                                        className={`r-c-fs p-15 cursor-pointer option m-l-20 ${selectedOption === 0 ? 'selected' : ''
                                            }`}
                                        onClick={() => onOptionClick(1, 0, dataList?.id, dataList)}
                                    >
                                        True
                                    </div>
                                    <div
                                        className={`r-c-fs p-15 cursor-pointer option m-l-20 ${selectedOption === 1 ? 'selected' : ''
                                            }`}
                                        onClick={() => onOptionClick(1, 1, dataList?.id, dataList)}
                                    >
                                        False
                                    </div>

                                </div>
                            )}
                    </div>
                )
            }

            return (
                <div className='quiz-container'>
                    <div style={{ margin: '10px 10px' }}>
                        <div className='font-bold text-sm'>Quiz 1</div>
                        <div className='r-c-sa'>
                            {data?.map((item, index) => (
                                <div key={index} style={{ width: '100%', margin: '10px 0px' }}>
                                    <div className='steps' style={{ backgroundColor: step >= index ? '#594099' : '#E6E6E6' }}>{/* Add your content for each step here */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            {data?.length > 0 && (
                                <Questions dataList={data[currentIndex]} index={currentIndex} selectedOption={paramsQuestion[currentIndex].attempt.answer}
                                    onOptionClick={handleOptionClick} />
                            )}
                        </div>
                    </div>
                    <div className='r-c-sb' style={{ borderTop: "1px solid #E6E6E6", }}>
                        <div style={{ marginTop: "15px", marginLeft: "20px" }} onClick={handlePrevious}><Button className='btns-prev '>Previous</Button></div>

                        {step < data?.length - 1 ? <>
                            <div style={{ marginTop: "15px", marginRight: "20px" }}>
                                {selected ?
                                    <Button className='btns-save' onClick={handleNext}>SAVE & NEXT</Button>
                                    :
                                    <Button className='btns-save' onClick={handleNext}>NEXT</Button>
                                }
                            </div>
                        </> : <>
                            <div className='r-c-sb' style={{ marginTop: "15px" }}>
                                <div><Button className='btns-save m-r-10'>Save</Button></div>
                                <div><Button className='btns-save m-r-20' onClick={() => { handleSubmit(data[currentIndex]?.id) }}>Submit</Button></div>
                            </div>
                        </>}

                    </div>
                </div>
            );
        }

        const VideoDesign = ({ item }) => {
            console.log("videoddd", item?.preview);
            setVideoUrl(item?.preview)
            return (
                <>
                    <div className='video-container'>
                        <ReactPlayerHoc
                            style={{ marginTop: 30 }}
                            height={"90%"}
                            width={"100%"}
                            keyboardControLS={true}
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            // Disable right click
                            onContextMenu={e => e.preventDefault()}
                            url={`${Api.dlS3Url}${videoUrl}`}
                            controls={true}
                            light={true}
                        />
                    </div>
                </>
            )
        }

        const Results = ({ }) => {
            const handleReAttempt = () => {
                setSubmit(false);
                setQuiz(true);
                setQuizAttempt(false);

            };
            console.log("resulssss", results);
            const handleViewSolution = (id, itemId, type) => {
                console.log("valueeeeeeee", id, itemId, type);
                setSubmit(false);
                setViewSolution(true);
                setQuizAttempt(false);
                ViewSolutions(paramAttemptQuiz, (res) => { setQuizData(res); }, setLoader);
            }
            const totalQuestions = results.totalQuestions || 0;
            const correctQuestions = results.correctQuestions || 0;
            return (
                <>
                    <div className='quiz-container'>
                        <div style={{ margin: '10px 10px' }}>
                            <div className='font-bold text-sm'>RESULTS</div>
                        </div>

                        <div className='r-c-c m-t-20 '>
                            <Progress
                                strokeColor="#594099"
                                type="circle"
                                percent={(correctQuestions / totalQuestions) * 100} // Calculate the percentage
                                format={(percent) => (
                                    <div className='r-c-c-c font-bold text-sm'>
                                        <div>{correctQuestions}
                                            <div style={{ width: "40px" }}></div>
                                            <div>
                                                {totalQuestions}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div>
                            <div className='r-c-sb text-xmd font-bold' style={{ margin: "20px 20px" }}>
                                <div className='r-c-sb' style={{ width: "30%" }}>
                                    <div>
                                        <img src={questionMarkIcon} />
                                        <span className='m-l-10'>Total questions</span>
                                    </div>
                                    <span>{results?.totalQuestions}</span>
                                </div>
                                <div className='r-c-sb' style={{ width: "30%" }}>
                                    <div>
                                        <img src={wrongIcon} />
                                        <span className='m-l-10'>Wrong answers</span>
                                    </div>
                                    <span>{results?.incorrectQuestions}</span>
                                </div>
                            </div>
                            <div className='r-c-sb text-xmd font-bold' style={{ margin: "20px 20px" }}>
                                <div className='r-c-sb' style={{ width: "30%" }}>
                                    <div>
                                        <img src={correctIcon} />
                                        <span className='m-l-10'>Correct answers</span>
                                    </div>
                                    <span>{results?.correctQuestions}</span>
                                </div>
                                <div className='r-c-sb' style={{ width: "30%" }}>
                                    <div>
                                        <img src={unattemptedIcon} />
                                        <span className='m-l-10'>Skipped questions</span>
                                    </div>
                                    <span>{results?.skippedQuestions}</span>
                                </div>
                            </div>

                            <div className='r-c-fe' style={{ borderTop: "1px solid #E6E6E6", marginTop: "70px" }}>
                                <div style={{ marginTop: "8px", marginRight: "20px" }} onClick={handleReAttempt}><Button className='btns-prev '>RE-ATTEMPT</Button></div>
                                <div className='r-c-sb' style={{ marginTop: "8px", width: "140px" }}>
                                    <div><Button className='btns-save' onClick={() => handleViewSolution(coursePageData?.videoResource?.id, results?.quizId, "videoResource")}>VIEW SOLUTION</Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        const ViewSolutionDesign = ({ solution }) => {
            console.log("qzzzzznnnn", solution);
            const [selectedOption, setSelectedOption] = useState(null);
            const [step, setStep] = useState(0);
            const [selected, setSelected] = useState(false);
            const [currentIndex, setCurrentIndex] = useState(0);

            const handleNext = () => {
                if (step < solution?.length - 1) {
                    setStep(step + 1);
                    setSelected(false)
                    setCurrentIndex((prevIndex) => (prevIndex + 1 < solution.length ? prevIndex + 1 : prevIndex));
                }
            };

            const handlePrevious = () => {
                if (step > 0) {
                    setStep(step - 1);
                    setCurrentIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
                }
            };


            // Assuming you fetch or set noQs solution from an API or other source

            const Questions = ({ dataList, index, selectedOption, onOptionClick }) => {

                let correctAns = dataList?.question?.answerContent?.answer;
                let selectedAns = dataList?.attempt?.answer;
                console.log("lslslsls", correctAns, correctAns)

                return (
                    <div className='font-bold'>
                        <div className='r-c-fe m-r-15 font-bold' style={{ color: '#594099' }}>
                            {dataList?.question?.positiveMarks}
                        </div>
                        <div className=''>{[index + 1]}  {dataList?.question?.questionContent?.statement}</div>
                        {dataList?.question?.type === 'T/F' ? ( // Check if it's a True/False question
                            <div>
                                <div
                                    className={`r-c-fs p-15 option m-l-20 ${selectedAns === correctAns ? 'selectedOp' : selectedAns !== correctAns ? 'wrongOp' : ''
                                        }`}
                                >
                                    True
                                    {selectedAns === correctAns ? (
                                        <div style={{ color: "#636363" }}>
                                            <img src={attempted} /> Correct answer
                                        </div>
                                    ) : selectedAns !== correctAns ? (
                                        <>
                                            <div style={{ color: "#636363" }}>
                                                <img src={closeWrong} /> Your answer
                                            </div>
                                            {/* <div style={{ color: "#636363" }}>
                                                <img src={attempted} /> Correct answer
                                            </div> */}
                                        </>
                                    ) : null}
                                </div>
                                <div
                                    className={`r-c-fs p-15 option m-l-20 ${selectedAns === correctAns ? 'selectedOp' : selectedAns !== correctAns ? 'wrongOp' : ''
                                        }`}
                                >
                                    False
                                    {selectedAns === false ? (
                                        <div style={{ color: "#636363" }}>
                                            <img src={attempted} /> Correct answer
                                        </div>
                                    ) : selectedAns !== false ? (
                                        <>
                                            <div style={{ color: "#636363" }}>
                                                <img src={closeWrong} /> Your answer
                                            </div>
                                            {/* <div style={{ color: "#636363" }}>
                                                <img src={attempted} /> Correct answer
                                            </div> */}
                                        </>
                                    ) : null}
                                </div>
                            </div>

                        ) : (
                            // For other question types
                            dataList?.question?.questionContent?.options?.map((option, optionIndex) => {
                                const isCorrectAnswer = Array.isArray(correctAns)
                                    ? correctAns.includes(optionIndex)
                                    : correctAns === optionIndex;

                                const isSelectedAnswer = Array.isArray(selectedAns)
                                    ? selectedAns.includes(optionIndex)
                                    : selectedAns === optionIndex;

                                const optionClassName = `r-c-fs p-15 option m-l-20 ${isSelectedAnswer && isCorrectAnswer ? 'selectedOp' : isSelectedAnswer ? 'wrongOp' : ''
                                    }`;

                                const correctAnswerIcon = isCorrectAnswer && !isSelectedAnswer ? (
                                    <div style={{
                                        color: "#636363", display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                        fontSze: "10px"
                                    }}>
                                        <img src={attempted} /> <span style={{ fontSze: "10px" }}> Correct answer</span>
                                    </div>
                                ) : null;

                                const yourAnswerIcon = isSelectedAnswer ? (
                                    <div style={{
                                        color: "#636363", display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",

                                    }}>
                                        {isCorrectAnswer ? (
                                            <div  >
                                                <img src={attempted} />  <span style={{ fontSze: "10px" }}> Correct answer</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <img src={closeWrong} />  <span style={{ fontSze: "10px" }}> Your answer</span>
                                            </div>
                                        )}
                                    </div>
                                ) : null;
                                return (
                                    <div key={optionIndex} className={optionClassName}>
                                        {option?.value}
                                        {correctAnswerIcon}
                                        {yourAnswerIcon}
                                    </div>
                                );
                            })
                        )}

                    </div >
                )
            }

            return (
                <div className='quiz-container'>
                    <div style={{ margin: '10px 10px' }}>
                        <div className='font-bold text-sm'>Solutions 1</div>
                        <div className='r-c-sa'>


                        </div>
                        <div>
                            {solution?.length > 0 && (
                                <Questions dataList={solution[currentIndex]} index={currentIndex} />
                            )}
                        </div>
                    </div>

                    <div className='r-c-sb' style={{ borderTop: "1px solid #E6E6E6", }}>
                        <div style={{ marginTop: "15px", marginLeft: "20px" }} onClick={handlePrevious}><Button className='btns-prev '>Previous</Button></div>

                        {step < solution?.length - 1 ? <>
                            <div style={{ marginTop: "15px", marginRight: "20px" }}>

                                <Button className='btns-save' onClick={handleNext}>NEXT</Button>

                            </div>
                        </> : <div style={{ marginTop: "15px", marginRight: "20px" }}>
                            <Button className='btns-save' disabled >NEXT</Button>
                        </div>}

                    </div>
                </div>
            );
        }
        const RelatedCourses = ({ course, index }) => {
            const [saved, setSaved] = useState(false);
            console.log("data", course);
            const handleSaveForLater = (id, itemType) => {
                if (token) {
                    if (saved[id]) {
                        RemoveSaveLater(token, id, itemType, (res) => {
                            if (res) {
                                setSaved((prevSavedItems) => ({
                                    ...prevSavedItems,
                                    [id]: false,
                                }));
                            }
                        }, setLoader);
                    } else {
                        AddSaveLater(token, id, itemType, (res) => {
                            if (res) {
                                setSaved((prevSavedItems) => ({
                                    ...prevSavedItems,
                                    [id]: true,
                                }));
                            }
                        }, setLoader);
                    }
                } else {
                    history.push('/login');
                }
            };
            return (
                <div className='relatedCourses' style={{}} >
                    <div className='rel-card' >
                        <div className='r-c-sb' style={{ padding: 10 }}>
                            <img className='' style={{ width: 150, borderRadius: 12 }} src={`${Api.dlS3Url}${course?.image}`} />
                            <div className='rel-card-btn m-l-20' style={{}}>
                                <div className='rel-name text-sm font-bold'>{course?.name}</div>
                                <div className='rel-des' style={{}} >{course?.brief}</div>
                                <div className='r-c-c' style={{}} >
                                    <div className='sellerButton2 m-t-5'>Bestseller</div>
                                    <div className='rel-ratings m-l-10'>
                                        <div style={{ color: "#191919", fontSize: 14 }}>
                                            {course?.ratingValue}
                                        </div>
                                        <Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931", fontSize: 18 }} />
                                        <div className='total-ratings' style={{ fontSize: 12, margin: "-9px 0px 0px 0px" }}>{course?.ratingCount}</div>
                                    </div>
                                    <div className='rel-cost' style={{ fontSize: 16 }}>₹{course?.amount}</div>
                                    <div className='rel-bookmark' style={{ width: 34, height: 34, }}>
                                        <div onClick={() => handleSaveForLater(course?.id, "videoResource")} style={{ cursor: "pointer" }} >
                                            <Tooltip title="Save for later" >
                                                <img style={{}} src={saved[course?.id] ? saveFill : saveOutlined} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        const inputRef = useRef(null);
        const clearInput = () => {
            if (inputRef.current) {
                inputRef.current.value = ''; // Clear the input field
            }
            setSearch(null);
        };
        console.log("nameeeessss", destruct)
        return (
            <>
                <div className='r-c-sb font-bold text-sm'>
                    <div>{coursePageData?.videoResource?.name}</div>
                    {/* <div>20% Completed</div> */}
                </div>
                <div className='r-c-sb video-parent m-t-10' style={{}}>

                    {quiz ? (
                        submit || quizAttempt ? (
                            <Results />
                        ) : viewSolution ? (
                            <ViewSolutionDesign solution={quizData} />
                        ) : (
                            <QuizDesign data={destruct} />
                        )
                    ) : (
                        submit || quizAttempt ? (
                            <Results />
                        ) : (
                            viewSolution ? (
                                <div>
                                    <ViewSolutionDesign solution={quizData} />
                                </div>
                            ) : (
                                <VideoDesign item={coursePageData?.videoResource} />
                            )
                        )
                    )}
                    <div className='video-resource'>
                        {coursePageData?.videoResource?.sections?.map((i) => {
                            console.log("paneeeeee", i)
                            return (
                                <Collapse accordion={false} style={{}}>
                                    <Panel header={<div className='r-c-fs m-t-15'>{i?.name}</div>} key="1">
                                        {i?.topics?.map((child) => (
                                            <div className='panel-list' style={{}}>
                                                {console.log("pannel", child)}
                                                <PanelList data={child} allData={i} />
                                            </div>
                                        ))}
                                    </Panel>

                                </Collapse>
                            )
                        })}
                    </div>
                </div >
                <div className='r-c-sb m-t-20'>
                    <div className='font-bold text-sm'>Q&A</div>
                    <div style={{ width: "300px" }}>
                        <AutoComplete suffixIcon showSearch={true} placeholder="Search" style={{ width: "100%" }} />
                    </div>
                </div>
                <div>
                    <div className='m-t-20'><input ref={inputRef} placeholder={"Couldn't find your question, post it now!"} onChange={handleSearch} setSearch={setSearch} style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "8px" }} /></div>
                    <div className='r-c-fe font-bold m-t-10'>
                        <div className='m-r-20 cursor-pointer' onClick={handlePost} style={{ color: "#594099" }}>POST</div>
                        <div onClick={clearInput} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                    </div>
                </div>

                <div className='m-t-20' style={{}}>
                    {Array.isArray(coursePageData?.qa) ? coursePageData.qa.slice(0, itemsToShow).map((item, index) => (
                        <Review key={index} rList={item} />
                    )) : null}


                    {coursePageData?.qa?.length > 6 && (
                        <div className='r-c-c font-bold m-t-10 cursor-pointer' style={{ color: "#2680EB" }} onClick={toggleViewAll}>
                            {viewAll ? 'View Less' : 'View All'}
                        </div>
                    )}
                </div>
                <div style={{ marginTop: 50 }} >
                    <div className='text-sm font-bold'>Related Courses</div>
                    <div className='rela-parent' >
                        {coursePageData?.related?.map((item, index) => (
                            <RelatedCourses
                                key={index}
                                index={index}
                                course={item}
                            />
                        ))}
                    </div>
                </div>

            </>
        )
    }

    return (
        <>
            <div className='parent-compo'>
                <div className='container-1'>
                    <div className='text-sm font-bold' onClick={() => setPopularRender(0)}  ><img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer" }} /><span className='m-l-10 m-t-5'>Video Resources</span></div>
                </div>

                <div className='container-2'>
                    <VideoResources data={quizData} fullData={coursePageData?.videoResource} />
                </div>
            </div>
        </>
    )
}

export const MyCourseList = ({ coursePageData, setPopularRender, token, }) => {
    const [isExpended, setExpended] = useState(false);
    const [isActive, setActive] = useState(false);
    console.log("fffffffffff", token);

    const panelContent = [
        {
            title: "Introduction",
            duration: "06:30",
            active: false,
            img: chemistry,
            id: 1,
        },
        {
            title: "About us",
            duration: "05:30",
            active: true,
            img: physics,
            id: 2,
        },
        {
            title: "Hello world",
            duration: "09:30",
            active: true,
            img: english,
            id: 3,
        },
        {
            title: "AWS account",
            duration: "04:30",
            active: false,
            img: computer,
            id: 4,
        },
        {
            title: "Weloome account",
            duration: "09:30",
            active: true,
            img: botany,
            id: 5,
        },
    ]
    const courseContent = [
        {
            name: "the world physics",
            progress: 40,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
        {
            name: "the world physics",
            progress: 60,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
        {
            name: "the world physics",
            progress: 40,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
        {
            name: "the world physics",
            progress: 100,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
        {
            name: "the world physics",
            progress: 40,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
        {
            name: "the world physics",
            progress: 100,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
        {
            name: "the world physics",
            progress: 90,
            updatedBy: "Naveen",
            digital: 4,
            live: 5,
            practice: 6,
            prog: "as on 28-06-2023"
        },
    ]
    const PanelContent = ({ data }) => {
        const [showAll, setShowAll] = useState(false);
        const progressFilterArray = [
            {
                label: 'Completed',
                id: 'Completed'
            },
            {
                label: 'Pending',
                id: 'Pending'
            },
            {
                label: 'Not Started',
                id: 'Not Started'
            },
        ]
        const [progressFilter, setProgressFilter] = useState(['Pending', 'Completed', 'Not Started'])
        const onProgressFilterSelect = (item) => {
            if (progressFilter.includes(item.id))
                setProgressFilter(prev => prev.filter(el => el != item.id))
            else setProgressFilter(prev => [...prev, item.id])
        }

        const toggleShowAll = () => {
            setShowAll(!showAll);
        };
        const itemsToShow = showAll ? panelContent?.length : 4;
        return (
            <>
                <div className="" style={{ color: "#636363" }}>
                    <Row
                        style={{
                            fontWeight: 600,
                            color: "#636363",
                            minHeight: 40,
                            padding: 10,
                            fontSize: 16,
                        }}
                        className="r-c-sb"
                    >
                        <Col offset={1} span={1}>Chapter</Col>
                        <Col span={4}>Name</Col>
                        <Col span={6}
                            className="r-c-sb"
                            style={{
                                paddingRight: 65
                            }}
                        >Progress
                            <DropDownCheckBox
                                placeholder={<FilterOutlined style={{ cursor: 'pointer' }} />}
                                DATA={progressFilterArray}
                                checkedArray={progressFilter}
                                onChange={onProgressFilterSelect}
                            />
                        </Col>
                        <Col span={4}>Updated By</Col>
                        <Col span={4}>Related Content</Col>
                    </Row>
                </div>

                {
                    data?.slice(0, itemsToShow).map((content, index) => (
                        <div className='' style={{ color: "#636363" }}>
                            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                            <Row style={{
                                color: "#636363",
                                minHeight: 40,
                                padding: 10,
                                fontSize: 16,
                            }}
                                className="r-c-sa">
                                <Col offset={1} span={1}> {index + 1}</Col>
                                <Col span={4} >{content?.name}</Col>
                                <Col span={6} className='r-c-sb' style={{ alignItems: "baseline" }} >
                                    <div style={{ width: "80%" }}>
                                        <Progress style={{}} strokeColor={content?.progress === 100 ? "#28DF99" : "#594099"} percent={content?.progress} size="small" showInfo={false} />
                                        <span >
                                            {content?.prog}
                                        </span>
                                    </div>
                                    <div className='r-c-c font-medium' style={{ width: "20%", color: "#636363" }}>
                                        {content?.progress}%
                                    </div>
                                </Col>
                                <Col span={4} className='r-c-fs'>
                                    <div><img src={require("../../Assets/aulasMarket/updatedProfile.png").default} /></div>
                                    <div className='m-l-10' >{content?.updatedBy}</div>
                                </Col>
                                <Col span={4} className='r-c-sa'>
                                    <Tooltip placement='bottomRight' title="Digital library">
                                        <div className='r-c-c' style={{}}>
                                            <img src={require("../../Assets/aulasMarket/ic-digital-library.svg").default} />
                                            <span className='m-l-5' >{content?.digital}</span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement='bottomRight' title="Practice test">
                                        <div className='r-c-c'>
                                            <img src={require("../../Assets/aulasMarket/ic-practice.svg").default} />
                                            <span className='m-l-5' >{content?.live}</span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement='bottomRight' title="Live test">
                                        <div className='r-c-c'>
                                            <img src={require("../../Assets/aulasMarket/ic-test.svg").default} />
                                            <span className='m-l-5' >{content?.practice}</span>
                                        </div>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div>

                    ))
                }
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                <div className='r-c-c font-bold' style={{ color: "#1089FF", cursor: "pointer", height: "3em" }} >
                    {data?.length > 3 && (
                        <div style={{ cursor: "pointer" }} onClick={toggleShowAll}>
                            {showAll ? 'VIEW LESS' : 'VIEW ALL'}
                        </div>
                    )}
                </div>
            </>

        );
    };

    const header = (val) => {
        console.log("naaaaa", val)
        return (
            <Row className="r-c-sb" style={{ padding: "10px" }}>
                <span style={{ fontSize: 16, fontWeight: "bold", color: "#636363" }}>
                    <img style={{ width: 45, height: 35 }} src={physics} /> {val?.name}
                </span>
                <span className='r-c-c' style={{ fontSize: 16, color: "#AEAEAE" }}>
                    17 Chapters
                </span>
            </Row>
        )
    }
    console.log("ccccccccc", coursePageData?.course);
    return (
        <>
            <div className='parent-compo'>
                <div className='container-1'>
                    <div className='text-sm font-bold' onClick={() => { setPopularRender(1); console.log("clickedback") }} >  <img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer" }} /><span className='m-l-10 m-t-5'>Course Content</span></div>
                </div>

                <div className='collapse-list'>
                    <div className='content-collapse' style={{}} >
                        {coursePageData?.course?.subjects?.map((section, index) => (
                            <Collapse accordion={false}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 270} style={{}} />
                                )}
                                style={{
                                    marginBottom: 20,
                                    backgroundColor: isActive ? '#F6F4FE' : 'white',
                                    borderRadius: 10,
                                }}
                                onChange={() => {
                                    if (isActive) setExpended(false)
                                    setActive(prev => !prev)
                                }}
                                key={section?.id}
                            >
                                <Panel
                                    header={header(section)}
                                    key={section?.id} // Use a unique key for each panel
                                    style={{}}
                                >
                                    <PanelContent data={courseContent} />
                                </Panel>
                            </Collapse>
                        ))}
                    </div>
                </div>


            </div>

        </>
    )
}

export const StudyMaterials = ({ coursePageData, setLoader, setPopularRender, token }) => {
    return (
        <>
            <div className='parent-compo'>

                <div className='container-1'>
                    <div className='text-sm font-bold' onClick={() => setPopularRender(0)}  ><img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer" }} /><span className='m-l-10 m-t-5'>Study Material</span></div>
                </div>

                <div className='container-2'>
                    <StudyMaterial study={true} setLoader={setLoader} />
                </div>
            </div>
        </>
    )
}

export const OnlineTest = ({ coursePageData, setPopularRender, token }) => {
    return (
        <div className='parent-compo'>
            <div className='container-1'>
                <div className='text-sm font-bold' onClick={() => setPopularRender(0)}  ><img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer" }} /><span className='m-l-10 m-t-5'>Test Series</span></div>
            </div>
            <div className='' style={{
                margin: "0 auto",
                padding: "2rem"
            }}>
                <TestSeriesProfile test={true} />
            </div>
        </div>

    )
}




function MyCourses({ popularRenderPage, quizData, setQuizData, setTestSeriesData, setItemId, itemId, setPopularRender, loader, setLoader, allCoursesData, setCoursePage, coursePageData, loginDetails, setMyResources, myResourceData }) {
    const token = loginDetails?.accessToken;
    const [activeButton, setActiveButton] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const history = useHistory();
    const [pdfFile, setPDFFile] = useState(null);
    const [isRatingModalVisible, setIsRatingModalVisible] = useState(false);
    const [itemFlag, setItemType] = useState();
    const [courseId, setCourseId] = useState();
    const [videoId, setVideoId] = useState();
    const [studyId, setStudyId] = useState();
    const [ratingVal, setRatingVal] = useState(null);
    const [ratingExp, setRatingExp] = useState(null);
    const [selected, setSelected] = useState("");

    const initialUserRating = {
        ratingValue: "",
        ratingExplanation: ""
    };
    const [userRating, setUserRating] = useState(initialUserRating);

    const [param, setParam] = useState({
        period: null,
        mode: null,
        free: null,
    })

    const handleUserRating = (val, key) => {
        setUserRating({
            ...userRating,
            ratingValue: val,
            ratingExplanation: key,
        });
    }

    const handleFilter = (period, mode, free, search) => {
        setParam({
            period: period,
            mode: mode,
            free: free,
            search: search,
        })
    }

    useEffect(() => {
        coursePageData?.videoResource ? setItemType("videoResource") : setItemType("course");
        setLoader(false)
    }, []);

    const fetchMyResourse = () => {
        setLoader(true);
        MyResourse(token, param, (res) => {
            if (res) {
                setMyResources(res)
            } else {
                console.error('Course not available');
            }
        }, setLoader);
    }
    useEffect(() => {
        popularRenderPage === 0 ?
            fetchMyResourse() : null;

    }, [popularRenderPage, param])

    const [saved, setSaved] = useState({});


    const handleClassClick = (buttonName) => {
        setSelected(buttonName);
        console.log("btnName", buttonName);
        if (buttonName) {
            setActiveButton(buttonName);

        } else {
            setActiveButton("All");
        }
    };

    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible);
    };

    const menu = (
        <Menu visible={dropdownVisible} style={{ width: "400px" }}>
            <Menu.ItemGroup title={<span style={{ color: '#594099', fontSize: 16 }}>Classes</span>}>
                <Menu.Item className='r-c-c' key="class1">
                    <Button
                        type="text"
                        value={selected}
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === '' ? '#594099' : 'transparent',
                            color: activeButton === '' ? 'white' : '',
                            border: activeButton === '' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => {
                            handleClassClick('');
                            setSelected("All");
                            setLoader(true);
                            handleFilter(null, null, null)
                        }}
                    >
                        All
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        value={selected}
                        type="text"

                        style={{
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'ongoing' ? '#594099' : 'transparent',
                            color: activeButton === 'ongoing' ? 'white' : '',
                            border: activeButton === 'ongoing' ? 'none' : '1px solid #594099',
                            width: "fit-content",
                        }}
                        onClick={() => {
                            handleClassClick('ongoing'); setSelected("Ongoing");
                            handleFilter("ongoing", null, null)
                        }}
                    >
                        Ongoing
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        value={selected}
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'upcoming' ? '#594099' : 'transparent',
                            color: activeButton === 'upcoming' ? 'white' : '',
                            border: activeButton === 'upcoming' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('upcoming'); setSelected("Upcoming"); handleFilter("upcoming", null, null) }}
                    >
                        Upcoming
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        value={selected}
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'completed' ? '#594099' : 'transparent',
                            color: activeButton === 'completed' ? 'white' : '',
                            border: activeButton === 'completed' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('completed'); setSelected("completed"); handleFilter("completed", null, null) }}
                    >
                        Completed
                    </Button>
                </Menu.Item>

            </Menu.ItemGroup>
            <Menu.ItemGroup title={<span style={{ color: '#594099', fontSize: 16 }}>Mode</span>}>
                <Menu.Item key="mode1">
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === '' ? '#594099' : 'transparent',
                            color: activeButton === '' ? 'white' : '',
                            border: activeButton === '' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => {
                            handleClassClick('');
                            ExploreCourseData(token, " ", (res) => setExploreCourseData(res), setLoader, page, exploreCourseData?.meta?.itemsPerPage);
                            handleFilter(null, null, null)
                        }}
                    >
                        All
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'online' ? '#594099' : 'transparent',
                            color: activeButton === 'online' ? 'white' : '',
                            border: activeButton === 'online' ? 'none' : '1px solid #594099',
                            width: "fit-content",
                        }}
                        onClick={() => { handleClassClick('online'); handleFilter(null, "online", null) }}
                    >
                        Online
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'offline' ? '#594099' : 'transparent',
                            color: activeButton === 'offline' ? 'white' : '',
                            border: activeButton === 'offline' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('offline'); handleFilter(null, "offline", null) }}
                    >
                        Offline
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'hybrid' ? '#594099' : 'transparent',
                            color: activeButton === 'hybrid' ? 'white' : '',
                            border: activeButton === 'hybrid' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick('hybrid'); handleFilter(null, "hybrid", null) }}
                    >
                        Hybrid
                    </Button>
                </Menu.Item>

                {/* Add more items as needed */}
            </Menu.ItemGroup>
            <Menu.ItemGroup title={<span style={{ color: '#594099', fontSize: 16 }}>Type</span>}>
                <Menu.Item key="type1">
                    <Button
                        type="text"
                        style={{
                            width: "fit-content",
                            borderRadius: "100px",
                            backgroundColor: activeButton === '' ? '#594099' : 'transparent',
                            color: activeButton === '' ? 'white' : '',
                            border: activeButton === '' ? 'none' : '1px solid #594099'
                        }}
                        onClick={() => { handleClassClick(''); handleFilter(null, null, null) }}
                    >
                        All
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="text"
                        style={{
                            borderRadius: "100px",
                            backgroundColor: activeButton === 'free' ? '#594099' : 'transparent',
                            color: activeButton === 'free' ? 'white' : '',
                            border: activeButton === 'free' ? 'none' : '1px solid #594099',
                            width: "fit-content",
                        }}
                        onClick={() => { handleClassClick('free'); handleFilter(null, null, 1) }}
                    >
                        Free
                    </Button>
                </Menu.Item>

                {/* Add more items as needed */}
            </Menu.ItemGroup>
        </Menu>
    );


    const LiveCourse = ({ liveCourses, index }) => {
        console.log("naaaa", liveCourses?.userRating?.ratingValue);
        setCourseId(liveCourses?.id);
        const handleCourseClick = (id) => {
            console.log("iddddd", id);
            setItemId(id)
            // setLoader(true);
            setPopularRender(1)
        };
        // console.log("naaaaaavv1", itemId);

        return (
            <div className='live-course-parent m-t-20'>
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(liveCourses?.id)}>
                    {/* {liveCourses?.mode === "online" ?
                        <div className='freeBanner'><img src={require("../../Assets/aulasMarket/freePng (2).png").default} /></div>
                        : <div className=''></div>}
                    <div className={`font-bold r-c-c m-t-10 ${liveCourses?.mode === 'hybrid' ? 'hybrid' : (liveCourses?.mode === 'online' ? 'online' : 'offline')}`} style={{}}>{liveCourses?.mode}</div> */}

                    <div>
                        {liveCourses?.image !== null ?
                            <div style={{}}>
                                <img className='popular-Img' style={{}} src={`${Api.dlS3Url}${liveCourses?.image}`} alt={liveCourses.name} />
                            </div>
                            :
                            <div className='r-c-c' style={{ width: "240px", border: "1px solid #E6E6E6", boxShadow: "4px 4px 16px #302A5F29", height: "135px" }}>
                                <img className='popular-Img' style={{ width: "40px" }} src={noImage} />
                            </div>
                        }
                    </div>
                    {/* {liveCourses?.mode === "online" ? <div className=''></div> :
                        <div className='cost r-c-c font-bold' style={{}}>₹{liveCourses?.amount}</div>
                    } */}
                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title' style={{}}>{liveCourses?.courseName}</div>
                    <div style={{ color: "#636363", fontSize: 13, }}>{liveCourses?.courseInfo}</div>
                    <div style={{ width: "90%" }}>
                        <div className='r-c-sb' style={{ width: "100%" }}>
                            <Progress strokeColor={liveCourses?.progress === 100 ? "#28DF99" : "#594099"} percent={liveCourses?.progress} size="small" showInfo={false} />
                            {liveCourses?.progress === 100 ?
                                <img src={checkIcon} style={{ width: 20, marginLeft: 10 }} />
                                : null}
                        </div>

                        {liveCourses?.progress === 0 ?
                            <div className='r-c-fs text-xs' style={{ color: "#AEAEAE" }}>Start course</div>
                            :
                            liveCourses?.progress === 100 ? null :
                                <div className='r-c-fe text-xs' style={{ color: "#AEAEAE" }}>{liveCourses?.progress}% Completed</div>
                        }

                    </div>
                    <div className='r-c-c' style={{}}>
                        {liveCourses?.rating ?
                            <div className='display-flex' >
                                <div style={{ margin: "-4px 5px 0px 5px" }}><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{liveCourses?.ratingValue}</div>
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={liveCourses?.rating} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12 }}>({liveCourses?.ratingCount})</div>
                            </div>
                            : null}
                        <div className='font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 13, marginLeft: 10 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(liveCourses?.userRating?.ratingValue, liveCourses?.userRating?.ratingExplanation)
                        }}>Add a Rating</div>
                    </div>
                </div>

            </div>
        )
    }
    const VideoResources = ({ course, index }) => {
        setVideoId(course?.id);
        setRatingVal(course?.userRating?.ratingValue);
        setRatingExp(course?.userRating?.ratingExplanation)
        const handleCourseClick = (id) => {
            setItemId(id)
            setPopularRender(2);
        };
        return (
            <div className='live-course-parent m-t-20' style={{}}>
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(course?.id)}>

                    <div>
                        <img className='popular-Img' style={{}} src={`${Api.dlS3Url}${course?.image}`} alt={course.name} />
                    </div>

                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title' style={{}}>{course?.name}</div>
                    <div style={{ color: "#636363", fontSize: 13, }}>{course?.brief}</div>
                    <div style={{ width: "90%" }}>
                        {/* <div className='r-c-sb' style={{ width: "100%" }}>
                            <Progress strokeColor={course?.progress === 100 ? "#28DF99" : "#594099"} percent={course?.progress} size="small" showInfo={false} />
                            {course?.progress === 100 ?
                                <img src={checkIcon} style={{ width: 20, marginLeft: 10 }} />
                                : null}
                        </div> */}

                        {/* {course?.progress === 0 ?
                            <div className='r-c-fs text-xs' style={{ color: "#AEAEAE" }}>Start course</div>
                            :
                            course?.progress === 100 ? null :
                                <div className='r-c-fe text-xs' style={{ color: "#AEAEAE" }}>50% Completed</div>
                        } */}

                    </div>
                    <div className='r-c-c' style={{}}>
                        {course?.rating ?
                            <div className='display-flex' >
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={1} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div style={{ margin: "-2px 5px 0px 5px" }}><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{course?.ratingValue}</div>
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12, }}>({course?.ratingCount})</div>
                            </div>
                            : null}
                        <div className='font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 13, marginLeft: 10 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(course?.userRating?.ratingValue, course?.userRating?.ratingExplanation)
                        }
                        }>Add a Rating</div>
                    </div>
                </div>
            </div>
        )
    }

    const TestSeries = ({ testData, id, flag }) => {
        // console.log("itttt", testData)
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} onClick={() => { setPopularRender(6) }}>

                    <div className='card-design' style={{}}>

                        <div className='card-content-free' style={{}}>

                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../Assets/aulasMarket/onlineTestIcon.svg").default} />
                            </div>

                            <div className='m-l-10 cursor-pointer' >
                                {testData?.name}
                            </div>

                        </div>
                    </div>

                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.description}</div>
                    <div style={{ width: "90%" }}>
                        <div className='r-c-sb' style={{ width: "100%" }}>
                            <Progress strokeColor={testData?.progress === 100 ? "#28DF99" : "#594099"} percent={testData?.progress} size="small" showInfo={false} />
                            {testData?.progress === 100 ?
                                <img src={checkIcon} style={{ width: 20, marginLeft: 10 }} />
                                : null}
                        </div>

                        {testData?.progress === 0 ?
                            <div className='r-c-fs text-xs' style={{ color: "#AEAEAE" }}>Start course</div>
                            :
                            testData?.progress === 100 ? null :
                                <div className='r-c-fe text-xs' style={{ color: "#AEAEAE" }}>{testData?.progress}% Completed</div>
                        }

                    </div>

                    <div className='r-c-c' style={{}}>

                        {testData?.rating ?
                            <div className='display-flex' >
                                <div style={{ margin: "-2px 5px 0px 5px" }}><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{testData?.rating}</div>
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={testData?.rating} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12 }}>({testData?.totalRatings})</div>
                            </div>
                            : null}
                        <div className='font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 13, marginLeft: 10 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(testData?.userRating?.ratingValue, testData?.userRating?.ratingExplanation)
                        }}>Add a Rating</div>
                    </div>
                </div>

            </div>
        )
    }

    const StudySeries = ({ testData, id, flag }) => {
        console.log("studydata", testData)
        setStudyId(testData?.id)
        const handleMaterial = (id) => {
            console.log("nnnnnnnnnnid", id)
            setLoader(true);
            MyStudyMaterial(token, id, (res) => {
                if (res) {
                    setTestSeriesData(res);
                    setPopularRender(5)
                } else {
                    console.log("errrr")
                    setLoader(false);
                }
            }, setLoader)
        }
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} >
                    <div className='card-design cursor-pointer' style={{}}>
                        <div className='card-content-free' onClick={() => handleMaterial(testData?.id)} style={{}}>
                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../Assets/aulasMarket/studyMaterialIcon.svg").default} />
                            </div>
                            <div className='m-l-10'>
                                {testData?.name}
                            </div>

                        </div>

                    </div>

                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.brief}</div>

                    <div className='r-c-c' style={{}}>
                        {testData?.rating ?
                            <div className='display-flex' >
                                <div style={{ margin: "-2px 5px 0px 5px" }}><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{testData?.ratingValue}</div>
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={testData?.rating} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12 }}>({testData?.ratingCount})</div>
                            </div>
                            : null}
                        <div className='font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 13, marginLeft: 10 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(testData?.userRating?.ratingValue, testData?.userRating?.ratingExplanation)
                        }}>Add a Rating</div>
                    </div>
                </div>

            </div>
        )
    }

    const RelatedCourses = ({ course, index }) => {
        console.log("data", course);

        const handleSaveForLater = (id, itemType) => {
            if (token) {
                if (saved[id]) {
                    RemoveSaveLater(token, id, itemType, (res) => {
                        if (res) {
                            setSaved((prevSavedItems) => ({
                                ...prevSavedItems,
                                [id]: false,
                            }));
                        }
                    }, setLoader);
                } else {
                    AddSaveLater(token, id, itemType, (res) => {
                        if (res) {
                            setSaved((prevSavedItems) => ({
                                ...prevSavedItems,
                                [id]: true,
                            }));
                        }
                    }, setLoader);
                }
            } else {
                history.push('/login');
            }
        };
        return (
            <div className='relatedCourses' style={{}} >
                <div className='rel-card' >
                    <div className='r-c-sb' style={{ padding: 10 }}>
                        <img className='' style={{ width: 150, height: "101px", borderRadius: 12 }} src={`${Api.dlS3Url}${course?.image}`} />
                        <div className='rel-card-btn m-l-20' style={{}}>
                            <div className='rel-name text-sm font-bold'>{course?.name}</div>
                            <div className='rel-des' style={{}} >{course?.brief}</div>
                            <div className='r-c-c' style={{}} >
                                <div className='sellerButton2 m-t-5'>Bestseller</div>
                                <div className='rel-ratings m-l-10'>
                                    <div style={{ color: "#191919", fontSize: 14 }}>
                                        {course?.ratingValue}
                                    </div>
                                    <Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931", fontSize: 18 }} />
                                    <div className='total-ratings' style={{ fontSize: 12, margin: "-9px 0px 0px 0px" }}>{course?.ratingCount}</div>
                                </div>
                                <div className='rel-cost' style={{ fontSize: 16 }}>₹{course?.amount}</div>
                                <div className='rel-bookmark' style={{ width: 34, height: 34, }}>
                                    <div onClick={() => handleSaveForLater(course?.id, "course")} style={{ cursor: "pointer" }} >
                                        <Tooltip title="Save for later" >
                                            <img style={{}} src={saved[course?.id] ? saveFill : saveOutlined} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const NoItems = () => {
        return (
            <div>
                <div className='r-c-c-c'>
                    <div><img style={{ width: "100%", height: "100%" }} src={NoData} /></div>
                    <div className='font-bold text-sm' style={{ color: "#191919" }}>No course available!</div>
                    <div className='text-sm' style={{ color: "#636363" }}>You have not registered for any courses yet!</div>
                    <div className='r-c-c m-t-20'>
                        <Button className='font-bold cursor-pointer' onClick={() => { history.push("/") }} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE COURSES</Button>
                    </div>
                </div>
            </div>
        )
    }
    const areAllArraysEmpty = myResourceData && Object.keys(myResourceData).every(key => myResourceData[key]?.length === 0);
    return (
        <div>
            <div className='parent'>
                <>
                    {popularRenderPage === 0 ?
                        <>
                            <div className='parent-compo'>
                                <div className='container-1'>
                                    <div className='font-bold text-sm'>My Courses</div>
                                    <div className='search-filter'>
                                        <div style={{ width: "60%" }}>
                                            <AutoComplete showSearch={true} placeholder="Search" style={{ width: "100%", height: "35px" }} onChange={(value) => handleFilter(null, null, null, value)} />
                                        </div>
                                        <div className='' style={{ width: "36%" }}>
                                            <Dropdown
                                                overlay={menu}
                                                visible={dropdownVisible}
                                                onVisibleChange={handleDropdownVisibleChange}
                                            >
                                                <div className='filter-Btn' style={{}}>
                                                    <img src={require("../../Assets/aulasMarket/filterIC.svg").default} /><div className='mobile-hidden'> Filter</div> <img className='mobile-hidden' src={dropdownVisible ? dropUpIcon : dropDownIcon} /> </div>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className='container-2'>
                                    <div className='ctn-parent'>
                                        {areAllArraysEmpty ? (
                                            <NoItems />
                                        ) : (
                                        <>
                                            <div>
                                                {myResourceData?.courses && myResourceData?.courses?.length > 0 ? (<>
                                                    <div className='text-sm font-bold'>Live Courses</div>
                                                    <div className='courses-container-p1 '>
                                                        {myResourceData?.courses?.map((item, index) => (
                                                            <LiveCourse
                                                                key={index}
                                                                index={index}
                                                                liveCourses={item}
                                                            />
                                                        ))}
                                                    </div>
                                                </>) : null}
                                            </div>
                                            <div style={{ marginTop: 50 }} >
                                                {myResourceData?.videoResource && myResourceData?.videoResource?.length > 0 ? (<>
                                                    <div className='text-sm font-bold'>Video Resources</div>
                                                    <div className='courses-container-p1 '>
                                                        {myResourceData?.videoResource?.map((i) => {
                                                            return (
                                                                <div>
                                                                    <VideoResources course={i} />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </>) : null}
                                            </div>
                                            <div style={{ marginTop: 50 }} >
                                                {myResourceData?.tests && myResourceData?.tests?.length > 0 ? (<>
                                                    <div className='text-sm font-bold'>Online Test Series</div>
                                                    <div className='courses-container-p1 '>
                                                        {testData?.map((item, index) => (
                                                            <TestSeries
                                                                key={index}
                                                                index={index}
                                                                testData={item}
                                                            />
                                                        ))}
                                                    </div>
                                                </>) : null}
                                            </div>
                                            <div style={{ marginTop: 50 }} >
                                                {myResourceData?.studyMaterial && myResourceData?.studyMaterial?.length > 0 ? (<>
                                                    <div className='text-sm font-bold'>Study Materials</div>
                                                    <div className='courses-container-p1 '>
                                                        {myResourceData?.studyMaterial?.map((item, index) => (
                                                            <>
                                                                {console.log("cccccc", item)}
                                                                <StudySeries
                                                                    key={index}
                                                                    index={index}
                                                                    testData={item}
                                                                />
                                                            </>
                                                        ))}
                                                    </div>
                                                </>) : null}
                                            </div>
                                            <div style={{ marginTop: 50 }} >
                                                {myResourceData?.related && myResourceData?.related?.length > 0 ? (<>
                                                    <div className='text-sm font-bold'>Related Courses</div>
                                                    <div className='rela-parent' >
                                                        {myResourceData?.related?.map((item, index) => (
                                                            <RelatedCourses
                                                                key={index}
                                                                index={index}
                                                                course={item}
                                                            />
                                                        ))}
                                                    </div>
                                                </>) : null}
                                            </div>
                                        </>)}
                                    </div>

                                </div>
                            </div>

                        </>
                        :
                        popularRenderPage === 1 ? (<LiveCourses setPopularRender={setPopularRender} popularRenderPage={popularRenderPage} setLoader={setLoader} history={history} setCoursePage={setCoursePage} token={token} />) :
                            popularRenderPage === 2 ? (<VideoCourses setPDFFile={setPDFFile} quizData={quizData} setQuizData={setQuizData} myResourceData={myResourceData} coursePageData={coursePageData} setMyResources={setMyResources} itemId={itemId} setPopularRender={setPopularRender} popularRenderPage={popularRenderPage} history={history} setCoursePage={setCoursePage} token={token} setLoader={setLoader} />) : (
                                popularRenderPage === 3 ? (<MyCourseList setPopularRender={setPopularRender} setLoader={setLoader} popularRenderPage={popularRenderPage} history={history} setCoursePage={setCoursePage} coursePageData={coursePageData} token={token} />) : (
                                    popularRenderPage === 4 ? (<PdfView file={pdfFile} setPopularRender={setPopularRender} setLoader={setLoader} popularRenderPage={popularRenderPage} history={history} setCoursePage={setCoursePage} coursePageData={coursePageData} token={token} />) : (
                                        popularRenderPage === 5 ? (<StudyMaterials setPopularRender={setPopularRender} popularRenderPage={popularRenderPage} history={history} setCoursePage={setCoursePage} setLoader={setLoader} coursePageData={coursePageData} token={token} />) : (
                                            popularRenderPage === 6 ? (<OnlineTest setPopularRender={setPopularRender} popularRenderPage={popularRenderPage} history={history} setCoursePage={setCoursePage} coursePageData={coursePageData} token={token} setLoader={setLoader} />) : null
                                        )
                                    )
                                )
                            )
                    }
                </>
            </div>
            <PageDataLoader visible={loader} />

            {isRatingModalVisible &&
                <AddRatingModal
                    isRatingModalVisible={isRatingModalVisible}
                    setIsRatingModalVisible={setIsRatingModalVisible}
                    id={courseId || videoId || studyId}
                    type={itemFlag}
                    userRating={userRating}
                />}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { popularRenderPage, loader, allCoursesData, coursePageData, loginDetails, myResourceData, itemId, quizData, setQuizData } = state.aulasMarket
    return {
        popularRenderPage, loader, allCoursesData, coursePageData, loginDetails, myResourceData, itemId, quizData, setQuizData
    }
};

const mapDispatchToProps = (dispatch) => ({
    setPopularRender: (val) => dispatch(setPopularRender(val)),
    setLoader: (val) => dispatch(setLoader(val)),
    setCoursePage: (val) => dispatch(setCoursePage(val)),
    setAllCourseData: (val) => dispatch(setAllCourseData(val)),
    setMyResources: (val) => dispatch(setMyResources(val)),
    setItemId: (val) => dispatch(setItemId(val)),
    setTestSeriesData: (val) => dispatch(setTestSeriesData(val)),
    setQuizData: (val) => dispatch(setQuizData(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCourses)