import PageHeader from 'components/pageHeader';
import React from 'react';
import { useHistory } from 'react-router';

const Tnc = () => {
    const history = useHistory();
    return (
        <div style={{height: "100%"}}>
            <PageHeader title="Terms and Conditions" onBackPress={() => history.goBack()}/>\
            <div className='p-30 text-justify'>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    User Terms And Conditions
                </div>
                <div  className="m-t-20" style={{color: "#636363"}}>
                    The User Terms and Conditions (hereinafter referred to as “the UT&C” or “this UT&C”) contains the terms of usage for use of the technology-based platform named and branded as “AULASTM” (hereinafter referred to as “the Platform”) owned and managed by VECTORS EDUCATION LLP (hereinafter referred to as “VEL”).
                </div>
                <div className="m-t-5" style={{color: "#636363"}}>
                    The UT&C are proposed by the Institution with whom the User is affiliated / employed or is a student of. The UT&C are accepted and agreed upon unconditionally and wholly by the User (defined in A3 below) who has provided his / her details at the time of signing in on the Platform.
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    A. Definitons
                </div>
                <div  className="m-t-20" style={{color: "#636363"}}>
                    Unless repugnant to the context, the following words and expressions, when used in this UT&C, shall have the meanings as mentioned here in below:
                </div>
                <div  className="m-t-20 " style={{color: "#636363"}}>
                    <div className='display-flex'>
                        <div>A1.</div>
                        <div className='m-l-10'> 
                            <b>Super-admin</b> – Any individual who has been assigned the roles, responsibilities and privileges of acting as a Super-admin on the Platform by the Institution. An individual who has been authorized by the Super-admin to act as the Super-admin whether with or without the authorization of the Institution will also be deemed to be a Super-admin.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>A2.</div>
                        <div className='m-l-10'> 
                            <b> Admin </b> - Any individual(s) who has been assigned the roles, responsibilities and privileges of acting as an Admin on the Platform by a Super-admin.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>A3.</div>
                        <div className='m-l-10'> 
                            <b>User</b> – Any individual(s) who has been added or approved by anyone acting on behalf of the Institution and will also include any individual(s) added or approved by a Super-admin or by an Admin or by any one authorized by the Super-admin / Admin. The term User inter alia includes Super-admin, Admins, teachers, students, planners, data operators, operation personnel.
                        </div>
                    </div>
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    B. Ownership & Roles
                </div>
                <div  className="m-t-20" style={{color: "#636363"}}>
                    VEL has developed and is owning a technology-based platform branded as AULASTM (hereinafter referred to as “the Platform”). The Platform is the exclusive property of VEL.
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    C. Privacy
                </div>
                <div  className="m-t-20 " style={{color: "#636363"}}>
                    <div className='display-flex'>
                        <div>C1.</div>
                        <div className='m-l-10'> 
                            VEL is concerned about privacy of all Users who use the Platform.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>C2.</div>
                        <div className='m-l-10'> 
                            VEL follows the Privacy Policy as contained in Schedule A of this UT&C.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>C3.</div>
                        <div className='m-l-10'> 
                            While VEL will make all attempts to safeguard the privacy of all Users, no warranties, either direct or implied are provided against any unintended breach of privacy.
                        </div>
                    </div>
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    D. Content
                </div>
                <div  className="m-t-20 " style={{color: "#636363"}}>
                    <div className='display-flex'>
                        <div>D1.</div>
                        <div className='m-l-10'> 
                            Any Content posted by any User on the Platform will be and shall remain exclusive property and responsibility of the Institution.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>D2.</div>
                        <div className='m-l-10'> 
                            The Institution is duty-bound to ensure that the Content posted by any User on the Platform does not violate any laws, public policy, social norms and is not offensive to any religion, community, gender, caste, race, state, nation or people.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>D3.</div>
                        <div className='m-l-10'> 
                            In case any User posts any objectionable Content on the platform, the Institution will promptly remove the concerned Content and the User will be immediately stripped of his / her roles and privileges on the platform. This is without prejudice to the rights of the Institution to initiate civil / criminal / disciplinary proceedings against the User.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>D4.</div>
                        <div className='m-l-10'> 
                            The Content posted by any User must not violate any law relating to copyright or trademark or any other intellectual property.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>D5.</div>
                        <div className='m-l-10'> 
                            The above clauses related to Content will apply in full to the lectures delivered by teachers in classes and to comments / actions by students during classes.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>D6.</div>
                        <div className='m-l-10'> 
                            The Institution, as well as VEL, has the undisputed right to suspend or cancel the access of the User in case of violation of any of the provisions of this part (D) or the next part (E) of the UT&C.
                        </div>
                    </div>
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    E. Code of Conduct
                </div>
                <div  className="m-t-20 " style={{color: "#636363"}}>
                    <div className='display-flex'>
                        <div>E1.</div>
                        <div className='m-l-10'> 
                            Do not do anything illegal.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E2.</div>
                        <div className='m-l-10'> 
                            Do not provide any information on the Platform that is wrong or inaccurate or misleading or incomplete or outdated either at the time of registration or at any time thereafter.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E3.</div>
                        <div className='m-l-10'> 
                            Do not use the Platform for any purpose other than educational and academic. In particular, do not use the Platform for sending personal or political or religious or social messages.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E4.</div>
                        <div className='m-l-10'> 
                            Do not post any content or engage in any activity that exploits, harms or threatens to harm children or other Users or the Institution or any other members of the society at large.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E5.</div>
                        <div className='m-l-10'> 
                            Do not use language that is abusive or violates socially accepted norms of decent behaviour.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E6.</div>
                        <div className='m-l-10'> 
                            Do not send spam or engage in phishing. Spam is unwanted or unsolicited bulk email, postings, contact requests, SMS (text messages), instant messages or similar electronic communications. Phishing is sending emails or other electronic communications to fraudulently or unlawfully induce recipients to reveal personal or sensitive information, such as passwords, dates of birth, Aadhar numbers, passport numbers, credit card information, financial information or other sensitive information, or to gain access to accounts or records, exfiltration of documents or other sensitive information, payment and / or financial benefit.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E7.</div>
                        <div className='m-l-10'> 
                            Do not publicly display or use the Platform to share inappropriate content or material (involving, for example, nudity, bestiality, pornography, offensive language, graphic violence or criminal activity) or Content / material that does not comply with local laws or regulations.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E8.</div>
                        <div className='m-l-10'> 
                            Do not indulge in any form of sexual harassment.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E9.</div>
                        <div className='m-l-10'> 
                        Do not engage in activity that is fraudulent, false or misleading (e.g. asking for money under false pretences, impersonating someone else) or libellous or defamatory.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E10.</div>
                        <div className='m-l-10'> 
                        Do not try to seek or get, either on the Platform or off the Platform, any money or business from other Users except what the Institution is officially charging and collecting from the Users.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E11.</div>
                        <div className='m-l-10'> 
                        Do not make any comments or share material that is anti-national or makes derogatory comments or indications against any country, people or society.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E12.</div>
                        <div className='m-l-10'> 
                        Do not make any comments or share material that denigrates or makes derogatory comments or indications against any gender, religion, sect, caste, community, race, economic or social group.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E13.</div>
                        <div className='m-l-10'> 
                        Do not create groupings or classes or categories based on gender, race, caste, religion, sect, community, political ideology or economic status.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E14.</div>
                        <div className='m-l-10'> 
                        Do not circumvent any restrictions on access to or availability of the Platform.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E15.</div>
                        <div className='m-l-10'> 
                        Do not engage in activity that is harmful to the Institution, the Platform or others (e.g. transmitting viruses, stalking, posting terrorist or violent extremist content, communicating hate speech or advocating violence against others).
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E16.</div>
                        <div className='m-l-10'> 
                        Do not engage in activity that may damage, disable, overburden, impair or otherwise interfere with or disrupt the services or any servers or networks connected to the services or security systems of the Platform.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E17.</div>
                        <div className='m-l-10'> 
                        Do not infringe upon the rights of others (e.g., unauthorised sharing of copyrighted material, resale or distribution of books, articles, maps or photographs).
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E18.</div>
                        <div className='m-l-10'> 
                        Do not engage in activity that violates the privacy or data protection rights of others.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E19.</div>
                        <div className='m-l-10'> 
                        Do not pass on or transfer any information about other Users or the Institution to third parties unless specifically authorized to do so by the Institution.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E20.</div>
                        <div className='m-l-10'> 
                        Do not download or transfer the Content either in violation of the rules framed by the Institution or in any manner that may harm the business or other interests of the Institution.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>E21.</div>
                        <div className='m-l-10'> 
                        Do not help others break these rules.
                        </div>
                    </div>
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    F. Miscellaneous
                </div>
                <div  className="m-t-20 " style={{color: "#636363"}}>
                    <div className='display-flex'>
                        <div>F1.</div>
                        <div className='m-l-10'> 
                           <b>Indemnification</b> - The User hereby indemnifies the Institution from any liabilities or claims or losses arising from any breach of the UT&C (including the Code of Conduct contained in E above) by him/her.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>F2.</div>
                        <div className='m-l-10'> 
                           <b>Amendments</b> - The UT&C may be amended / modified at any time by the Institution without any notice to the User. The amended / modified UT&C will be published on the Platform
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>F3.</div>
                        <div className='m-l-10'> 
                           <b>Duration</b>  - This UT&C will come in operation from the time that the User accepts it online by clicking on the relevant button. The UT&C will remain in full force till the User continues to use the services of the Platform or till Institution / VEL cancels the Platform’s access to the User, whichever is earlier.
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div>F4.</div>
                        <div className='m-l-10'> 
                           <b>Schedule</b> - Schedule to this UT&C is an integral part of the UT&C.
                        </div>
                    </div>
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    Cancellation/Refund Policy
                </div>
                <div  className="m-t-20 " style={{color: "#636363"}}>
                    Any subscription term will end on the expiration date and you cannot cancel it before its expiration. Vectors Education LLP will not provide refunds if you decide to stop using the platform aulas during your subscription term. Refund is applicable only until the subscription of aulas is deployed. Unfortunately, there can be no refund made once the subscription request has been activated. Refunded amount shall be credited to your account within a period of 5-7 working days.
                </div>

            </div>
        </div>
    );
}

export default Tnc;
