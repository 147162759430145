import * as moment from 'moment'
import {
  UPDATE_TEST_LIST,
  TOGGLE_TEST_LOADER,
  TOGGLE_TEST_MODAL,
  UPDATE_TEST_POST_PARAMS,
  RESET_TEST_PARAMS,
  UPDATE_STANDARDS_LIST,
  UPDATE_TEST_FILTER_PARAMS,
  SET_CURRENT_TEST,
  UPDATE_QUESTIONS_LIST,
  SET_TEST_PARAMS,
  UPDATE_TEST_Q_PARAMS,
  RESET_TEST_Q_PARAMS,
  TOGGLE_Q_POST_LOADER,
  TOGGLE_QUESTION_MODAL,
  ADD_OPTION,
  UPDATE_Q_OPTION,
  SET_TEST_OPTION_IMAGE,
  SET_CURRENT_TEST_QUESTION,
  GET_REMOVE_RESPONSE,
  REMOVE_OPTION,
  UPDATE_QUESTION_FEEDBACK,
  SET_CURRENT_TEST_PARAMS,
  UPDATE_SUBMISSION_DATA,
  TOGGLE_ANS_LOADER,
  SET_STUDENT_ANSWERS,
  TOGGLE_REVIEW_LOADER,
  UPDATE_TEST_PARAMS,
  RESET_CURRENT_TEST,
  TOGGLE_INSTRUCTION_MODAL,
  TOGGLE_TEST_QUESTIONS_LOADER,
  UPDATE_REVIEW_PARAMS,
  UPDATE_TOTAL_MARKS,
  SET_QUESTIONS_FEEDBACK,
  TOGGLE_RESULT_LOADER,
  TOGGLE_STATUS_LOADER,
  UPDATE_SELECTED_TAB,
  UPDATE_SEARCHED_CHAPTERS_IN_TEST,
  TOGGLE_VIEW_Q_MODAL,
  TOGGLE_TEACHER_TEST_REMINDER_LOADER,
  UPDATE_STUDENT_TEST_STATUS,
  CLEAR_TEST_FILTER_PARAMS
  } from '../actions/test_old';
  
  const initialState = {
    testList: [],
    testLoader: false,
    testModal: false,
    testQLoader: false,
    testParams: {
      title:  "",
      subjectNames: [],
      subjectIds: [],
      teacherId: null,
      teacherName: "",
      standardIds: [],
      testDate: null,
      resultDate: null,
      startTime: null,
      endTime: null,
      chapterIds: [],
      chapterNames: [],
      index: null,
      instructions: "",
      cloneTestId: null
    },
    currentTestDetails: {
      title:  "",
      subjectNames: [],
      subjectIds: [],
      teacherId: null,
      teacherName: "",
      standardIds: [],
      testDate: null,
      resultDate: null,
      startTime: null,
      endTime: null,
      chapterIds: [],
      chapterNames: [],
      index: null,
      instructions: "",
    },
    testFilterParams: {
      status: "Draft",
      page: 1,
      standardId: null,
      standardName: "",
      subjectId: null,
      subjectName: "",
      teacherId: null,
      teacherName: "",
      testDate: null
    },
    standardsList: [],
    testQList: [],
    testQPageMeta: {
      totalItems: 0,
      currentPage: 1
    },
    totalPoints: 0,
    qParams: {
      id: null,
      statement: "",
      type: "MCQ",
      options: [],
      answer: "",
      positiveMarks: 0,
      negativeMarks: 0,
      testId: null,
      chapterId: null,
      chapterName: "",
      details: "NA"
    },
    qPostLoader: false,
    questionModal: false,
    questionImagesList: [], 
    deletedImages: [], 
    oldImageList: [],
    getRemoveResponseBool: false,
    optionsImagesList: [[], [], [], []],
    questionsFeedback: [],
    studentList: [],
    graded: 0,
    submitted: 0,
    totalStudents: null,
    saveButton: false,
    answerLoader: false,
    studentResponse: {},
    studentReview: [],
    showInstructionModal: false,
    resultLoader: false,
    statusLoader: false,
    selectedTab: "Question",
    searchedChaptersList: [],
    viewQModal: false,
    testTeacherReminderLoader: false
  };
  
  const reducer = (state = initialState, action) => {
    let review, reviews;
    switch(action.type){
      case CLEAR_TEST_FILTER_PARAMS:
        return {
          ...state,
          testFilterParams: {...initialState.testFilterParams}
        }
      case UPDATE_STUDENT_TEST_STATUS:
      const index = state.studentList.map(item => item.studentId).indexOf(action.index);
      const student = {
        ...state.studentList[index],
        gradeObtained: action.marks,
        submitStatus: action.status
      };
      state.studentList[index] = student;
      const gradedCount = state.studentList.filter(
        item => item.submitStatus === "Graded"
      ).length;
      return {
        ...state,
        studentList: [...state.studentList],
        graded: gradedCount
      };
      case TOGGLE_TEACHER_TEST_REMINDER_LOADER:
        return {
          ...state,
          testTeacherReminderLoader: action.bool
        };
      case TOGGLE_VIEW_Q_MODAL:
        return {
          ...state,
          viewQModal: action.bool
        };
      case UPDATE_SEARCHED_CHAPTERS_IN_TEST:
        return {
          ...state,
          searchedChaptersList: [...action.chapters]
        };
      case UPDATE_SELECTED_TAB: 
        return {
          ...state,
          selectedTab: action.key
        };
      case TOGGLE_STATUS_LOADER:
        return {
          ...state,
          statusLoader: action.value,
        }
      case TOGGLE_RESULT_LOADER:
        return {
          ...state,
          resultLoader: action.value,
        }
      case SET_QUESTIONS_FEEDBACK:
        return {
          ...state,
          questionsFeedback: action.value,
        }
      case UPDATE_TOTAL_MARKS:
        return {
          ...state,
          studentResponse: {...state.studentResponse, totalMarksObatined: action.totalMarksObtained},
        }
      case UPDATE_REVIEW_PARAMS:
        review = state.studentReview[action.index];
        review = {...review, ...action.param}
        state.studentReview[action.index] = review;
        let allChecked = true ;
        state.studentReview.forEach ((item,index) =>{
          if(item.correct === null && !(state.studentResponse.questions[index].answer === null && state.studentResponse.questions[index].filename === null)){
            allChecked = false;
            return
          }
        });
      return {
        ...state,
        studentReview: [...state.studentReview],
        saveButton: allChecked,
        // studentResponse: {...studentResponse, }
      }
      case TOGGLE_TEST_QUESTIONS_LOADER:
        return {
          ...state,
          testQLoader: action.bool
        }
      case RESET_CURRENT_TEST:
        return {
          ...state,
          currentTestDetails: {...initialState.currentTestDetails},
        }
      case TOGGLE_INSTRUCTION_MODAL:
        return {
          ...state,
          showInstructionModal: action.state,
        }
      case UPDATE_TEST_PARAMS:
        return {
          ...state,
          currentTestDetails: {...state.currentTestDetails, ...action.testParams},
        }
      case TOGGLE_REVIEW_LOADER:
        return {
          ...state,
          reviewLoader: action.value,
        }
      case TOGGLE_ANS_LOADER:
        return {
          ...state,
          answerLoader: action.value,
        }
      case SET_STUDENT_ANSWERS:
        reviews = action.response.questions ? action.response.questions.map(item => ({
          testQuestionId: item.id,
          score: item.marksObtained,
          comment: item.comment,
          correct: item.correct,
          sent: false
        })) : []
        const answered = action.response.questions ? action.response.questions.filter((item,index) =>
        !(item.answer === null && item.filename === null)) : false
        return {
          ...state,
          studentResponse: action.response,
          studentReview: [...reviews],
          saveButton: !answered.length ? true : false
        }
      case UPDATE_SUBMISSION_DATA:
        return {
          ...state,
          studentList: action.studentList,
          graded:  action.graded,
          submitted:  action.submitted,
          totalStudents: action.totalStudents,
        }
      case UPDATE_QUESTION_FEEDBACK:
        review = state.questionsFeedback[action.index];
        review = {...review, ...action.param}
        state.questionsFeedback[action.index] = review;
        return {
          ...state,
          questionsFeedback: {...state.questionsFeedback},
        }
      case REMOVE_OPTION:
        const size = state.qParams.options.length;
        for (let i = action.id; i < size - 1; i++) {
          state.qParams.options[i] = state.qParams.options[i + 1];
          state.optionsImagesList[i] = state.optionsImagesList[i+1]
          state.qParams.options[i].id = i;
        }
        for(let j = size - 1; j <4; j++){
          state.optionsImagesList[j] = []
        }
        state.qParams.options.splice(size - 1, 1);
        return {
          ...state,
          qParams: {...state.qParams, options: state.qParams.options},
        }
      case SET_CURRENT_TEST_PARAMS:
        return {
          ...state,
          currentTestDetails: {...state.currentTestDetails, ...action.currentTestDetails},
        }
      case GET_REMOVE_RESPONSE:
        return {
          ...state,
          getRemoveResponseBool: action.bool,
        }
      case SET_TEST_OPTION_IMAGE:
        state.optionsImage[action.index] = action.image;
        return {
          ...state,
          optionsImage: {...state.optionsImage}
        }
      case SET_CURRENT_TEST_QUESTION:
        return {
          ...state,
          qParams: action.data ? {...action.data} : {...initialState.qParams}
        }
      case UPDATE_TEST_LIST:
        return {
          ...state,
          testList: action.payload,
        }
      case UPDATE_Q_OPTION:
        state.qParams.options[action.index].value = action.val 
        return {
          ...state,
          qParams: {...state.qParams, options: [...state.qParams.options]}
        }
      case ADD_OPTION:
        let options = state.qParams.options;
        if(!options) options = [];
        let optionsImage = state.qParams.optionsImage;
        if(!optionsImage) optionsImage = [];
        options.push(action.obj);
        optionsImage.push([])
        return {
          ...state,
          optionsImage: [...optionsImage],
          qParams: {...state.qParams, options: [...options]} ,
        }
      case TOGGLE_QUESTION_MODAL:
        return {
          ...state,
          questionModal: action.bool,
        }
      case TOGGLE_Q_POST_LOADER:
        return {
          ...state,
          qPostLoader: action.bool,
        }
      case UPDATE_TEST_Q_PARAMS:
        return {
          ...state,
          qParams: {...state.qParams, ...action.qParams},
        }
      case RESET_TEST_Q_PARAMS:
        return {
          ...state,
          qParams: {...initialState.qParams, options: []},
        }
      case UPDATE_QUESTIONS_LIST:
        const feedback = {};
        const currentFeedback = state.questionsFeedback;
        if(action.questions)  {
          action.questions.forEach(item => {
          if(item.markedForReview ||  item.remark) {
            feedback[item.id] = {
            markedForReview: currentFeedback[item.id] && currentFeedback[item.id].markedForReview !== undefined ? currentFeedback[item.id].markedForReview : item.markedForReview,
            comment: currentFeedback[item.id]  && currentFeedback[item.id].comment && currentFeedback[item.id].comment !== "" ?
             currentFeedback[item.id].comment : item.remark,
            }
          }
        })}
        return {
          ...state,
          questionsFeedback: {...currentFeedback, ...feedback},
          testQList: [...action.questions],
          testQPageMeta: {...action.pageMeta},
          totalPoints: action.totalPoints
        }
      case SET_CURRENT_TEST:
        const chapterNames = action.data.chapterNames ? action.data.chapterNames.split(',') : [];
        const subjectNames = action.data.subjectNames ? action.data.subjectNames.split(',') : [];
        const standardIds = action.data.standardIds ? action.data.standardIds.split(',').map(id => parseInt(id)) : [];
        const subjectIds = action.data.subjectIds ? action.data.subjectIds.split(',').map(id => parseInt(id)) : [];
        const chapterIds = action.data.chapterIds ? action.data.chapterIds.split(',').map(id => parseInt(id)) : [];
        const testDate = action.data.testDate ? moment(action.data.testDate).format('YYYY-MM-DD') : null;
        const publishDate = action.data.publishDate ? moment(action.data.publishDate).format('YYYY-MM-DD') : null;
        const resultDate = action.data.resultDate ? moment(action.data.resultDate).format('YYYY-MM-DD') : null;
        return {
          ...state,
          currentTestDetails: action.data ? 
          {
            ...action.data, 
            chapterNames, 
            subjectNames, 
            standardIds,
            testDate,
            publishDate,
            resultDate,
            chapterIds,
            subjectIds
          } 
          : {...initialState.currentTestDetails},
        }
      case SET_TEST_PARAMS:
        return {
          ...state,
          testParams: {...state.currentTestDetails},
        }
      case TOGGLE_TEST_LOADER:
        return {
          ...state,
          testLoader: action.state
        }
      case RESET_TEST_PARAMS:
        return {
          ...state,
          testParams: {...initialState.testParams, 
            subjectNames: [],
            subjectIds: [],
            standardIds: [],
            chapterIds: [],
            chapterNames: [],
        }
        }
      case UPDATE_TEST_FILTER_PARAMS:
        return {
          ...state,
          testFilterParams: {...state.testFilterParams, ...action.filterParams }
        }
      case TOGGLE_TEST_MODAL:
        return {
          ...state,
          testModal: action.state
        }
      case UPDATE_STANDARDS_LIST:
        return {
          ...state,
          standardsList: action.standards
        }
      case UPDATE_TEST_POST_PARAMS:
        return {
          ...state,
          testParams: {...state.testParams, ...action.postParams}
        }
      default:
        return state;
    }
  };
  
  export default reducer;
  