import React from 'react';
import { SmallInfo } from './Typography';
import { Color } from 'services';

const formatDate = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);

    if (now.toDateString() === createdDate.toDateString()) {
        return 'Today';
    }

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (yesterday.toDateString() === createdDate.toDateString()) {
        return 'Yesterday';
    }

    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = createdDate.toLocaleDateString(undefined, options);
    const [month, day, year] = formattedDate.split('/');
    return `${day}/${month}/${year}`;
};

const FormattedDate = ({ createdAt }) => {
    const formattedDate = formatDate(createdAt);
    return <SmallInfo color={Color.aeGrey}>{formattedDate}</SmallInfo>;
};

export default FormattedDate;