import { Switch } from "antd";
import AnimatedTextField from "components/AnimatedTextField";
import React from "react";
function CreateOfferSettings({ offersObj, setOffersObjKey, inputStyle, hasPromo, cardClassName, shortenField }) {
    let minCartMessage = 'Minimum cart value to avail this offer';
    if ((offersObj?.type == 'course' && (offersObj.applicableLive && !(offersObj.applicableVideo || offersObj.applicableStudy || offersObj.applicableTest))) ||
        (offersObj?.type == 'singleCourse' && (offersObj.courseId && !(offersObj.videoResourceId || offersObj.studyMaterialId || offersObj.testSeriesId)))
    ) {
        minCartMessage = `Minimum course fee for this offer to ${!shortenField ? 'be applicable' : 'apply'}`;
    }
    const showMinPurhcase = offersObj?.type == 'course' || offersObj.type == 'singleCourse' || offersObj.type == 'All';
    return (
        <div className={cardClassName} style={{ marginBottom: 10 }}>
            <div style={{ color: "#636363", fontSize: 18, fontWeight: 700 }}>Settings</div>
            <div className='r-c-fs' style={{ gap: '1%' }}>
                {hasPromo ?
                    <AnimatedTextField
                        label={`Maximum users can avail this ${!shortenField ? 'promotional' : ''} offer`}
                        isMandatory={false}
                        inputId={"-1017"}
                        value={offersObj?.maxUses || null}
                        handleInputChange={(e) => {
                            let enteredValue = parseInt(e.target.value) || 0;
                            enteredValue = enteredValue < 0 ? 0 : enteredValue;
                            setOffersObjKey("maxUses", enteredValue)
                        }}
                        style={{ marginTop: 30, width: "49%" }}
                        inputstyle={inputStyle}
                        type="number"
                    /> : null
                }
                {showMinPurhcase ?
                    <AnimatedTextField
                        label={minCartMessage}
                        isMandatory={false}
                        inputId={"-1037"}
                        value={offersObj?.minPurchase || null}
                        handleInputChange={(e) => {
                            let enteredValue = parseInt(e.target.value) || 0;
                            enteredValue = enteredValue < 0 ? 0 : enteredValue;
                            setOffersObjKey("minPurchase", enteredValue)
                        }}
                        style={{ marginTop: 30, width: "49%" }}
                        inputstyle={inputStyle}
                        type="number"
                        prefix="₹"
                    /> : null}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                <div style={{ fontSize: 14, color: "#636363" }}>Show on market place banner</div>
                <Switch checked={!offersObj?.code ? false : offersObj?.banner} disabled={!offersObj?.code} onChange={() => { setOffersObjKey('banner', !offersObj?.banner) }} />
            </div>
        </div>
    );
}

export default CreateOfferSettings;