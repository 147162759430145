import { updateEmailInfo } from 'actions/aulasWallet';
import { getCfrt } from 'actions/profileNew';
import { getStudentsDetailsById } from 'actions/userManagement';
import { Button, Checkbox, Input, Modal } from 'antd'
import { Heading, SubHeading } from 'components/Typography'
import { LabelDiv } from 'pages/profile/detailFields'
import { generatePaymentRequest } from 'pages/profile/paymentGateway';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { validateEmail } from '../studentKycData';

function EmailIdModal({ isEmailIdModalVisible, setIsEmailIdModalVisible, updateEmailInfo, getCfrt, user, paymentReqModalData, getStudentsDetailsById, userDetailsById }) {
    const [email, setEmail] = useState('');
    const [saveEmailChecked, setSaveEmailChecked] = useState(false);
    const [emailBtnLoader, setEmailBtnLoader] = useState(false)
    const handleCancel = () => {
        setIsEmailIdModalVisible(false)
    }
    useEffect(() => {
        setEmail(userDetailsById ? userDetailsById?.email : "")
    }, [])
    const handleNext = () => {
        paymentReqModalData.transactionId = paymentReqModalData?.id
        const generateOrderId = user?.orgId + user?.id + paymentReqModalData?.transactionId + Math.floor(Math.random() * 100);
        let params = {
            id: paymentReqModalData?.transactionId || null,
            orderAmount: paymentReqModalData?.amountPayable.toString() || null,
            orderCurrency: 'INR',
            orderId: generateOrderId,
            customerDetails: {
                customer_id: user?.id?.toString() || null,
                customer_email: user?.email || null,
                customer_phone: user?.phone?.toString() || null
            }
        };
        if (saveEmailChecked) {
            setEmailBtnLoader(true)
            updateEmailInfo(
                email,
                () => {
                    setEmailBtnLoader(false)
                },
                () => {
                    setIsEmailIdModalVisible(false);
                    getStudentsDetailsById(() => { })
                    getCfrt(params, (response) => generatePaymentRequest(response, paymentReqModalData))
                }
            )
        } else {
            getCfrt(params, (response) => generatePaymentRequest(response, paymentReqModalData))
        }
    }
    return (
        <Modal
            className="modal-round-corner"
            centered
            visible={isEmailIdModalVisible}
            onOk={handleCancel}
            onCancel={handleCancel}
            header={null}
            footer={null}
            width={600}>
            <Heading bold className='r-c-c m-t-10'>Email ID required</Heading>
            <SubHeading color="#636363" className='r-c-c m-t-10'>Your email ID is required to make the payment. Kindly fill in your email ID below.</SubHeading>
            <div className='m-t-20'>
                <div className='r-jsb'>
                    <LabelDiv>
                        <div style={{ color: "636363" }}>Email Id</div>
                    </LabelDiv>
                    {validateEmail(email) ?
                        <div style={{ color: "red" }}>Enter valid email id</div>
                        :
                        <></>
                    }
                </div>
                <Input
                    style={{
                        width: "100%",
                        marginBottom: "10px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        height: "40px"
                    }}
                    value={email}
                    placeholder={"Enter email ID"}
                    onChange={(e) => { setEmail(e.target.value) }}>
                </Input>
                <Checkbox onChange={() => { setSaveEmailChecked(true) }} disabled={userDetailsById?.email == email}>Save my email ID</Checkbox>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "40px",
                    marginBottom: 10
                }}>
                <Button
                    style={{
                        borderRadius: "19px",
                        width: "120px",
                        height: "35px",
                        border: "1px solid  #636363",
                        backgroundColor: "#FFFFFF",
                        color: "#594099",
                        marginLeft: 100
                    }}
                    onClick={handleCancel}>
                    CANCEL
                </Button>
                <Button
                    loading={emailBtnLoader}
                    disabled={!email || validateEmail(email)}
                    style={{
                        borderRadius: "19px",
                        width: "120px",
                        height: "35px",
                        marginRight: 100
                    }}
                    type='primary'
                    onClick={handleNext}>
                    NEXT
                </Button>
            </div>

        </Modal>
    )
}
const mapStateToProps = state => {
    const { paymentReqModalData } = state.aulasWallet;
    const { user } = state.session;
    const { userDetailsById } = state.userManagement
    return { user, paymentReqModalData, userDetailsById };
};
const mapDispatchToProps = dispatch => ({
    updateEmailInfo: (email, callback, successCallBack) => dispatch(updateEmailInfo(email, callback, successCallBack)),
    getCfrt: (params, callback) => dispatch(getCfrt(params, callback)),
    getStudentsDetailsById: (callback) => dispatch(getStudentsDetailsById(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailIdModal);

// export default EmailIdModal