import { ArrowLeftOutlined, BellOutlined, CalendarOutlined, CaretDownOutlined, DownloadOutlined, ArrowUpOutlined, ArrowDownOutlined, CloudDownloadOutlined, FilterFilled, CalendarFilled } from '@ant-design/icons';
import { getAllPaymentHistory, setFromPaymentHistory, storePaymentHistory, storePaymentHistoryParams, storeTransactionModalData } from 'actions/aulasWallet';
import { Button, Checkbox, Col, DatePicker, Pagination, Popover, Radio, Row, Select, Space, Typography } from 'antd';
import { InputSearch } from 'components/customInputs';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import DownloadInvoiceModal from './modal/downloadInvoiceModal';
import TransactionDeatilsModal from './modal/transactionDeatilsModal';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
import fileDownload from "js-file-download";
import { Api } from 'services';
import Axios from 'axios';
import { deleteZipFile, downloadInvoices } from 'actions/profileNew';

const { RangePicker } = DatePicker;
const { Option } = Select;
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
const bodyStyle = {
    background: "#FAFAFA",
    margin: -20,
    padding: 20,
    overflow: "hidden",
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
};

const tableDataStyle = {
    color: "#191919", textAlign: "center", fontSize: 14, fontFamily: "roboto", fontWeight: "bold"
}

const PaymentHistoryTable = ({ paymentHistoryContainer, storeTransactionModalData, transactionModalData, togglePageLoader, storePaymentHistoryParams, getAllPaymentHistory, paymentHistoryObj, isFromPaymentHistory }) => {
    const [isTransactionDetailsVisible, setTransactionDetailsVisible] = useState(false);
    const [isStatusSelected, setStatusSelected] = useState(false);
    const [isModeSelected, setModeSelected] = useState(false);
    const [isDateSelected, setIsDateSelected] = useState(false)
    const [showCustomCal, setShowCustomCal] = useState(false)

    useEffect(() => {
        togglePageLoader(true)
        getAllPaymentHistory(() => togglePageLoader(false))
    }, []);

    useEffect(() => {
        if (paymentHistoryObj?.paymentStatus) {
            setStatusSelected(true)
        } else {
            setStatusSelected(false)
        }
        if (paymentHistoryObj?.paymentMode) {
            setModeSelected(true)
        } else {
            setModeSelected(false)
        } if ((paymentHistoryObj?.monthYear?.length != 0 || (paymentHistoryObj?.paymentStartDate != null && paymentHistoryObj?.paymentEndDate != null && paymentHistoryObj?.paymentStartDate && paymentHistoryObj?.paymentEndDate))) {
            setIsDateSelected(true)
        } else {
            setIsDateSelected(false)
        }
    }, [paymentHistoryObj?.paymentStatus, paymentHistoryObj?.paymentMode, paymentHistoryObj?.monthYear, paymentHistoryObj?.paymentStartDate, paymentHistoryObj?.paymentEndDate])


    const CalenderPopup = () => {
        function onChange(dates, value) {
            storePaymentHistoryParams("paymentStartDate", value[0]);
            storePaymentHistoryParams("paymentEndDate", value[1]);
            togglePageLoader(true)
            getAllPaymentHistory(() => togglePageLoader(false));
        }
        return (
            <div className="rangePicker">
                <Space direction="vertical" mode={'month'} size={12} style={{ width: '250px' }}>
                    <RangePicker value={[paymentHistoryObj?.paymentStartDate ? moment(paymentHistoryObj?.paymentStartDate, "DD/MM/YYYY") : null, paymentHistoryObj?.paymentEndDate ? moment(paymentHistoryObj?.paymentEndDate, "DD/MM/YYYY") : null]} dropdownClassName={'rangePickerB'} placeholder={['Start Date', 'End Date']} onChange={onChange} renderExtraFooter={() => 'extra footer'} showTime format={'DD/MM/YYYY'} />
                </Space>
            </div>
        )
    }
    const content1 = () => {
        const [selectedMonths, setSelectedMonths] = useState([]);
        const months = Object.keys(paymentHistoryContainer?.filterMonth);
        const handleCheckboxToggle = (item, checked) => {
            setSelectedMonths((prevSelectedMonths) => {
                if (checked) {
                    const updatedMonths = [...prevSelectedMonths, item];
                    storePaymentHistoryParams('monthYear', updatedMonths);
                    togglePageLoader(true);
                    getAllPaymentHistory(() => togglePageLoader(false));
                    return updatedMonths;
                } else {
                    const updatedMonths = prevSelectedMonths.filter((month) => month !== item);
                    storePaymentHistoryParams('monthYear', updatedMonths);
                    togglePageLoader(true);
                    getAllPaymentHistory(() => togglePageLoader(false));
                    return updatedMonths;
                }
            });
        };

        return (
            <div className="popOverB" style={{ width: showCustomCal ? "200px" : "250px" }}>
                {showCustomCal ?
                    <div
                        id='checkbox'
                        className="flex-column organistationcardfilter"
                        style={{
                            marginLeft: -16,
                            marginRight: -16,
                            marginTop: -12,
                            width: "fit-content",
                            height: "fit-content",
                        }}>
                        <div style={{ color: "#AEAEAE", fontSize: 12, padding: 10, fontWeight: "bold" }}>FILTER OPTIONS</div>
                        <Checkbox.Group
                            className="flex-column"
                        >
                            {months?.map((item, index) => (
                                <Checkbox
                                    className="black p-10 m-l-0 full-width"
                                    value={item}
                                    onChange={(e) => {
                                        storePaymentHistoryParams("paymentStartDate", null);
                                        storePaymentHistoryParams("paymentEndDate", null);
                                        handleCheckboxToggle(e.target.value, e.target.checked)
                                    }}
                                >
                                    {item}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                        <div style={{ fontSize: 14, color: "#636363", fontWeight: "bold", padding: 10, backgroundColor: (paymentHistoryObj?.paymentStartDate != null && paymentHistoryObj?.paymentEndDate != null) ? "#F6F4FE" : "", cursor: "pointer" }} onClick={() => { storePaymentHistoryParams('monthYear', null); setShowCustomCal(false) }}>Customise range</div>
                    </div>
                    :
                    <CalenderPopup />
                }
            </div>
        )
    }
    const statusContent = () => {
        const [value, setValue] = useState(null);
        return (
            <div
                id='checkbox'
                className="flex-column organistationcardfilter"
                style={{
                    marginLeft: -16,
                    marginRight: -16,
                    marginTop: -12,
                    width: 160,
                    height: 120
                }}>
                <Radio.Group
                    className="flex-column"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                        storePaymentHistoryParams('paymentStatus', e.target.value);
                    }}
                >
                    <Radio value={"Paid"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Paid</Radio>
                    <Radio value={"Refunded"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Refunded</Radio>
                </Radio.Group>
                <div style={{ borderBottom: "1px solid #E6E6E6" }}></div>
                <div className='r-jsb' style={{ padding: 10 }}>
                    <div
                        style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                            setValue(null)
                            storePaymentHistoryParams('paymentStatus', null);
                            togglePageLoader(true);
                            getAllPaymentHistory(() => togglePageLoader(false));
                        }}
                    > RESET</div>
                    <Button
                        type="primary"
                        style={{ width: 50, borderRadius: 16, height: 25, display: "flex", alignItems: "center", fontWeight: "bold" }}
                        onClick={() => {
                            togglePageLoader(true);
                            getAllPaymentHistory(() => togglePageLoader(false));
                        }}
                    >OK</Button>
                </div>
            </div>
        )
    }
    const modeContent = () => {
        const [value, setValue] = useState(null);
        return (
            <div
                id='checkbox'
                className="flex-column organistationcardfilter"
                style={{
                    marginLeft: -16,
                    marginRight: -16,
                    marginTop: -12,
                    width: 160,
                    height: 120
                }}>
                <Radio.Group
                    className="flex-column"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                        storePaymentHistoryParams('paymentMode', e.target.value);
                    }}
                >
                    <Radio value={"online"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Online</Radio>
                    <Radio value={"offline"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Offline</Radio>
                </Radio.Group>
                <div style={{ borderBottom: "1px solid #E6E6E6" }}></div>
                <div className='r-jsb' style={{ padding: 10 }}>
                    <div
                        style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                            setValue(null)
                            storePaymentHistoryParams('paymentMode', null);
                            togglePageLoader(true);
                            getAllPaymentHistory(() => togglePageLoader(false));
                        }}
                    > RESET</div>
                    <Button
                        type="primary"
                        style={{ width: 50, borderRadius: 16, height: 25, display: "flex", alignItems: "center", fontWeight: "bold" }}
                        onClick={() => {
                            togglePageLoader(true);
                            getAllPaymentHistory(() => togglePageLoader(false));
                        }}
                    >OK</Button>
                </div>
            </div>
        )
    }
    return (
        <div style={{ width: "100%", height: "80vh", overflowY: "scroll", marginTop: 20 }}>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr
                        style={{
                            backgroundColor: "#F6F4FE",
                            padding: "20px",
                            height: "50px",
                        }}
                    >
                        <th style={{ color: "#636363", color: "#636363", textAlign: "left", paddingLeft: 10, width: 100 }}>REQUEST DETAILS</th>
                        <th style={{ color: "#636363" }}>AMOUNT</th>
                        <th style={{ color: "#636363" }}>
                            PAYMENT DATE
                            <Popover
                                placement="bottomRight"
                                content={content1}
                                trigger="click"
                            >
                                {isDateSelected ?
                                    <CalendarFilled
                                        style={{
                                            fontSize: "18px",
                                            color: "#1089FF",
                                            marginLeft: "20px",
                                        }}
                                        onClick={() => setShowCustomCal(true)}
                                    />
                                    :
                                    <CalendarOutlined
                                        style={{
                                            fontSize: "18px",
                                            color: "#AEAEAE",
                                            marginLeft: "20px",
                                        }}
                                        onClick={() => setShowCustomCal(true)}
                                    />
                                }
                            </Popover>
                        </th>
                        <th style={{ color: "#636363" }}>STATUS
                            <Popover
                                placement="bottomRight"
                                content={statusContent}
                                trigger="click"
                            >
                                <FilterFilled
                                    style={{
                                        fontSize: "18px",
                                        color: isStatusSelected ? "#1089FF" : "#AEAEAE",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Popover></th>
                        <th style={{ color: "#636363" }}>MODE
                            <Popover
                                placement="bottomRight"
                                content={modeContent}
                                trigger="click"
                            >
                                <FilterFilled
                                    style={{
                                        fontSize: "18px",
                                        color: isModeSelected ? "#1089FF" : "#AEAEAE",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Popover></th>
                    </tr>
                </thead>
                {paymentHistoryContainer?.transactions?.map((item, index) => (
                    <tr
                        className={"cursor-pointer"}
                        style={{ backgroundColor: "#ffffff", height: "60px" }}
                        onClick={() => {
                            setTransactionDetailsVisible(true)
                            storeTransactionModalData(item);
                        }}
                    >
                        <td>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", textAlign: "left", fontSize: 14, fontFamily: "roboto", fontWeight: "bold", width: 250, marginLeft: 10 }}>
                                {item?.note}
                            </Typography.Text>
                        </td>
                        <td>
                            <div style={tableDataStyle}>
                                ₹ {item?.amountToShow}
                            </div>
                        </td>
                        <td>
                            <div style={{ ...tableDataStyle, color: "#636363" }}>
                                {item?.payment_type == 'offline' ? moment(item?.paymentDate, 'DD-MM-YYYY').format("DD/MM/YYYY") : moment(item?.paymentDate, 'DD-MM-YYYY, hh:mm A').format("DD/MM/YYYY, hh:mm A")}
                            </div>
                        </td>
                        <td>
                            <div style={{ ...tableDataStyle, color: item?.status == "Refunded" ? "#594099" : "#191919" }}>
                                {item?.status.toUpperCase()}
                            </div>
                        </td>
                        <td>
                            <div style={{ ...tableDataStyle, color: item?.payment_type == "online" ? "#28DF99" : "#636363" }}>
                                {item?.payment_type.toUpperCase()}
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
            {paymentHistoryContainer?.transactions?.length == 0 ?
                <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
                    <div
                    >
                        <img src={illNoData} />
                    </div>
                    <div>
                        <h4>
                            <b>No data!</b>
                        </h4>
                    </div>
                    <div>
                        <h5>There are no data to show yet. Please check back later</h5>
                    </div>
                </div>
                : null
            }
            {isTransactionDetailsVisible &&
                <TransactionDeatilsModal
                    isTransactionDetailsVisible={isTransactionDetailsVisible}
                    setTransactionDetailsVisible={setTransactionDetailsVisible}
                    transactionModalData={transactionModalData} />
            }
        </div>
    )
}

function PaymentHistory({ getAllPaymentHistory, paymentHistoryContainer, storeTransactionModalData, transactionModalData, storePaymentHistoryParams, paymentHistoryObj, setFromPaymentHistory, isFromPaymentHistory, storePaymentHistory, downloadInvoices, deleteZipFile, user }) {
    const history = useHistory();
    const [isDownloadInvoiceVisible, setDownloadInvoiceVisible] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    useEffect(() => {
        storePaymentHistory([])
        setFromPaymentHistory(true)
        storePaymentHistoryParams("paymentStartDate", null);
        storePaymentHistoryParams("paymentEndDate", null);
        storePaymentHistoryParams("page", 1);
        storePaymentHistoryParams("paymentMode", null);
        storePaymentHistoryParams("paymentStatus", null);
        storePaymentHistoryParams("limit", 10);
        storePaymentHistoryParams("monthYear", []);
        togglePageLoader(true)
        getAllPaymentHistory(() => { togglePageLoader(false) })
    }, [])
    const handlePageination = (value) => {
        storePaymentHistoryParams("page", value);
        togglePageLoader(true)
        getAllPaymentHistory(() => { togglePageLoader(false) })
    };

    return (
        <div style={bodyStyle}>
            <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "700", display: "flex", flexDirection: "row", marginTop: "5px" }}>
                    <ArrowLeftOutlined
                        style={{ marginTop: "10px", fontSize: "22px" }}
                        onClick={() => {
                            history.goBack();
                        }} />
                    <div style={{ marginLeft: "15px", fontSize: "24px" }}>Transaction History</div>
                </div>
            </div>
            <PaymentHistoryTable
                paymentHistoryContainer={paymentHistoryContainer}
                storeTransactionModalData={storeTransactionModalData}
                transactionModalData={transactionModalData}
                storePaymentHistoryParams={storePaymentHistoryParams}
                togglePageLoader={togglePageLoader}
                getAllPaymentHistory={getAllPaymentHistory}
                paymentHistoryObj={paymentHistoryObj}
                isFromPaymentHistory={isFromPaymentHistory} />
            <div style={{ position: "absolute", right: "20px", bottom: "10px" }}>
                <Pagination
                    size="small"
                    current={paymentHistoryContainer?.meta?.currentPage}
                    defaultCurrent={1}
                    total={paymentHistoryContainer?.meta?.totalPages * 10}
                    onChange={handlePageination}
                    showSizeChanger={false}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    // left: "250px",
                    bottom: "10px",
                    color: "#636363",
                }}
            >
                <Select
                    style={{ width: 100, marginRight: 10 }}
                    defaultValue={"10/ page"}
                    value={paymentHistoryObj?.limit}
                    onChange={(value) => {
                        storePaymentHistoryParams("limit", value);
                        togglePageLoader(true);
                        getAllPaymentHistory(() => togglePageLoader(false))
                    }}>
                    <Option value={10}>10/ page</Option>
                    <Option value={12}>12/ page</Option>
                    <Option value={16}>16/ page</Option>
                    <Option value={18}>18/ page</Option>
                </Select>
                {paymentHistoryContainer?.meta?.itemsPerPage *
                    (paymentHistoryContainer?.meta?.currentPage - 1) +
                    1}{" "}
                -{" "}
                {paymentHistoryContainer?.meta?.itemsPerPage *
                    paymentHistoryContainer?.meta?.currentPage <
                    paymentHistoryContainer?.meta?.totalItems
                    ? paymentHistoryContainer?.meta?.itemsPerPage *
                    paymentHistoryContainer?.meta?.currentPage
                    : paymentHistoryContainer?.meta?.totalItems}{" "}
                of {paymentHistoryContainer?.meta?.totalItems} items
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = state => {
    const { paymentHistoryContainer, transactionModalData, paymentHistoryObj, isFromPaymentHistory } = state.aulasWallet;
    const { user } = state.session
    return { paymentHistoryContainer, transactionModalData, paymentHistoryObj, isFromPaymentHistory, user };
};

const mapDispatchToProps = dispatch => ({
    getAllPaymentHistory: (callBack) => dispatch(getAllPaymentHistory(callBack)),
    storeTransactionModalData: (val) => dispatch(storeTransactionModalData(val)),
    storePaymentHistoryParams: (key, val) => dispatch(storePaymentHistoryParams(key, val)),
    setFromPaymentHistory: (val) => dispatch(setFromPaymentHistory(val)),
    storePaymentHistory: (val) => dispatch(storePaymentHistory(val)),
    downloadInvoices: (params, callback, successCallback) => dispatch(downloadInvoices(params, callback, successCallback)),
    deleteZipFile: (params, callback) => dispatch(deleteZipFile(params, callback)),
});


export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);

// export default PaymentHistory