import { Button, Modal, Typography } from 'antd';
import React, { useState } from 'react'
import courseImage from '../../../Assets/dashBoard/ill-courses.png'
import moment from 'moment';


const CourseDetailModal = ({isCourseModal,setIsCourseModal,allData}) => {

console.log("AA-allData",allData);
  const showModal = () => {
    setIsCourseModal(true);
  };
  const handleOk = () => {
    setIsCourseModal(false);
  };
  const handleCancel = () => {
    setIsCourseModal(false);
  };
  return (
    <>
      <Modal 
      className="modal-round-corner"
      open={isCourseModal} 
      onOk={handleOk} 
      footer={null}
      onCancel={handleCancel}>
        <div style={{display:"flex",justifyContent:"center" ,fontSize:"21px",fontWeight:"bold",marginTop:"15px"}}>New course added to your profile</div>
        <div style={{width:"100%",height:"250px",backgroundColor:"#FAFAFA",marginTop:"15px"}}>
            <div style={{display:"flex",justifyContent:"space-around"}}>
                <div style={{marginTop:"25px"}}><img src={courseImage}/></div>
                <div style={{marginTop:"40px"}}>
                <Typography.Text ellipsis={{ tooltip: true }}  style={{ width: "220px", fontFamily: 'roboto', color: '#191919', fontSize: '15px', fontWeight: 'bold',}}>
                {allData?.meta?.courseName}

                </Typography.Text>
                    <div style={{fontSize:"13px",color:"#191919"}}>{allData?.meta?.duration} {allData?.meta?.durationType}</div>
                    <div style={{marginTop:"20px",display:"flex",justifyContent:"space-between"}}>
                    <div >
                        <div style={{fontSize:"9px",color:"#636363"}}>start date</div>
                        <div style={{fontSize:"13px"}}>{moment(allData?.meta?.startDate).format('DD-MM-YYYY')}</div>
                    </div>
                    <div style={{marginLeft:"10px"}}>
                        <div style={{fontSize:"9px",color:"#636363"}}>End date</div>
                        <div style={{fontSize:"13px"}}>{moment(allData?.meta?.endDate).format('DD-MM-YYYY')}</div>
                    </div>

                    </div>
                    
                </div>
            </div>
           
            
        </div>
        <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>

        <Button 
            style={{
                borderRadius: "19px",
                width: "90px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#FFFFFF",
                color: "#636363",
                fontSize:"13px",
             
            }}
            onClick={handleCancel}
            >CLOSE</Button>
        </div>

      </Modal>
    </>
  );
};
export default CourseDetailModal;