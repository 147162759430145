import {
    PlusCircleOutlined,
    ArrowLeftOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import moment from 'moment';
import Notification from "services/Notification";
import { Tooltip } from 'antd';

import {
    getTest,
    setCreatePayload,
    fetchMarkingSchemeDataTS,
    fetchAllSubjects,
    createTest
} from "actions/marketTest";

import PageDataLoader from "components/pageDataLoader";
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    Input,
    Select,
    TimePicker,
} from "antd";
import CreateMarkingScheme from "pages/Test/modals/createMarkingScheme";
import EditMarkingSchemeModal from "pages/Test/modals/editMarkingSchemeModal";
import { fromCreateNewButton, setFromSectionMarkingScheme } from "actions/testAdmin";

const bodyStyle = {
    background: "#FAFAFA",
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
    padding: 20,
    margin: -20,
    color: "FFFFFF",
};

const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};

function SingleSection({
    section,
    markingSchemes,
    secIdx,
    testSeriesSubjectId,
    createPayload,
    setCreatePayload,
    allQuesMandatory,
    sameMarkingSc,
    handleSameMS,
    fromCreateNewButton,
    setFromSectionMarkingScheme,
    storeSubjectSection

}) {
    const [isCreateMarkingSchemeVisible, setIsCreateMarkingSchemeVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisisble] = useState(false)
    const [subjectSection, setSubjectSection] = useState(storeSubjectSection)


    useEffect(() => {
        setSubjectSection(storeSubjectSection)
    }, [storeSubjectSection])


    const handleInputChange = (val, key) => {
        const newPayload = { ...createPayload };
        const newSection = newPayload.subjectsSection.find(
            (el) => el.testSeriesSubjectId == testSeriesSubjectId
        )?.sections?.[secIdx];

        newSection[key] = val; //will also change newPayload

        setCreatePayload(newPayload);
    };

    const onFillSubjectiveSection = (markingSchemeId, msTitle) => {
        handleInputChange(markingSchemeId, "markingSchemeId");
        handleInputChange(msTitle, "markingSchemeTitle");
        if (sameMarkingSc) handleSameMS(true)
    }

    return (
        <div style={{ width: "100%", marginTop: 45 }}>
            <div className="r-c-fs" style={{ position: "relative" }}>
                {section?.name != "_default_" && (
                    <div>
                        <div className="bold-600 text-xs">Enter section name</div>
                        <Input
                            className="m-t-5 test-custom-select createTestModalValue"
                            style={{ width: "40%", position: "absolute" }}
                            placeholder="Enter section name"
                            onChange={(e) => {
                                handleInputChange(e.target.value, "name");
                            }}
                            value={section?.name}
                        />
                    </div>
                )}

                {!allQuesMandatory && (
                    <>
                        <div
                            className=""
                            style={{ marginLeft: section?.name != "_default_" ? "33%" : 0 }}
                        >
                            <div className="bold-600 text-xs">Total questions</div>
                            <Input
                                // onKeyDown={() => { }}
                                type="number"
                                style={{
                                    width: section?.name != "_default_" ? "20%" : "40%",
                                    position: "absolute",
                                }}
                                placeholder="Enter value"
                                className="m-t-5 test-custom-select createTestModalValue"
                                onChange={(e) => {
                                    handleInputChange(e.target.value, "totalQuestions");
                                }}
                                value={(section?.totalQuestions == -1) ? null : section?.totalQuestions}
                            />
                        </div>

                        <div
                            className=""
                            style={{
                                marginLeft: section?.name != "_default_" ? "15%" : "35%",
                            }}
                        >
                            <div className="bold-600 text-xs">Mandatory questions</div>
                            <Input
                                // onKeyDown={}
                                type="number"
                                placeholder="Enter value"
                                style={{
                                    width: section?.name != "_default_" ? "20%" : "40%",
                                    position: "absolute",
                                }}
                                className="m-t-5 test-custom-select createTestModalValue"
                                onChange={(e) => {
                                    handleInputChange(e.target.value, "mandatoryQuestions");
                                }}
                                value={(section?.mandatoryQuestions == -1) ? null : section?.mandatoryQuestions}
                            />
                        </div>
                    </>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="bold-600 text-xs"
                    style={{
                        color: "#636363",
                        marginTop: ((section?.name == "_default_") && allQuesMandatory) ? 0 : 50
                    }}
                >
                    Marking scheme
                </div>
                <div className="bold-600 text-xs" style={{ display: 'flex', height: 'fit-content', marginTop: ((section?.name == "_default_") && allQuesMandatory) ? 0 : 50 }}>
                    {!section?.id &&
                        <div style={{ display: 'flex' }} >
                            <div style={{ color: '#1089FF', cursor: 'pointer' }}
                                onClick={() => {
                                    fromCreateNewButton(true);
                                    setFromSectionMarkingScheme(false);
                                    setIsCreateMarkingSchemeVisible(true)
                                }}
                            >
                                + CREATE NEW
                            </div>
                            <div style={{ marginLeft: 10, color: '#636363' }}>
                                |
                            </div>
                        </div>
                    }


                    <div style={{
                        marginLeft: 10, color: '#636363',
                        cursor: section?.markingSchemeId ? 'pointer' : 'not-allowed'
                    }}
                        onClick={
                            section?.markingSchemeId ?
                                () => {
                                    fromCreateNewButton(false);
                                    setFromSectionMarkingScheme(false);
                                    setIsEditModalVisisble(true);
                                } : () => { }}
                    >
                        PREVIEW
                    </div>
                </div>
            </div>
            <Select
                allowClear
                onClear={() => {
                    handleInputChange(null, "markingSchemeId");
                    handleInputChange(null, "markingSchemeTitle");
                    if (sameMarkingSc) handleSameMS(true)
                }}
                className="m-t-5 test-custom-select createTestModalValue"
                style={{ width: "100%", minWidth: "40%" }}
                placeholder="Select marking scheme"
                value={section?.markingSchemeTitle ?? (section?.id ? "NA" : null)}
                onChange={(markingSchemeId, value) => {
                    handleInputChange(markingSchemeId, "markingSchemeId");
                    handleInputChange(value?.children, "markingSchemeTitle");
                    if (sameMarkingSc) handleSameMS(true)
                }}
                disabled={section?.id || (sameMarkingSc && secIdx != 0)}
            >
                {markingSchemes?.map((item) => (
                    <Option value={item?.id} key={item.id}>
                        {item?.title}
                    </Option>
                ))}
            </Select>

            {isCreateMarkingSchemeVisible &&
                <CreateMarkingScheme
                    isCreateMarkingSchemeVisible={isCreateMarkingSchemeVisible}
                    setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                    fromTestSeries={true}
                />
            }
            {isEditModalVisible &&
                <EditMarkingSchemeModal
                    isEditModalVisible={isEditModalVisible}
                    setIsEditModalVisisble={setIsEditModalVisisble}
                    fromTestSeries={true}
                    TSdata={
                        {
                            sectionId: section?.id,
                            msID: section?.markingSchemeId
                        }
                    }
                />
            }
        </div>
    );
}


function SubjectSectionsCard({ chapSubject, subject, createPayload, setCreatePayload }) {
    const [error, setError] = useState(false);
    const [isOther, setIsOther] = useState(false)
    const [sameMarkingSc, setSameMarkingSc] = useState(false)


    const totalSections = subject?.sections.length

    const oldSections = createPayload.subjectsSection.find(
        (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
    ).sections.filter((el) => (el?.id)).length

    const handleIncludeSections = (checked) => {
        const newPayload = { ...createPayload }

        let newSections = [];
        if (checked) {
            newSections.push({
                id: null,
                name: "",
            })
        }
        else {
            newSections.push({
                id: null,
                name: "_default_",
            })
        }
        newPayload.subjectsSection.find(
            (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
        ).sections = newSections

        setCreatePayload(newPayload)
    }

    const handleSectionSelect = (item) => {
        if (item == "others") {
            setIsOther(prev => !prev)
            return
        }
        else if (item < 7) setIsOther(false)

        const newPayload = { ...createPayload }

        const firstSec = newPayload.subjectsSection.find(
            (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
        ).sections?.[0]

        let dif = item - totalSections

        while ((item >= oldSections) && dif < 0 && dif++) {
            newPayload.subjectsSection.find(
                (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
            ).sections.pop()
        }

        while (dif > 0 && dif--) {
            newPayload.subjectsSection.find(
                (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
            ).sections.push({
                id: null,
                name: "",
                markingSchemeId: (sameMarkingSc) ? firstSec?.markingSchemeId : null,
                markingSchemeTitle: (sameMarkingSc) ? firstSec?.markingSchemeTitle : null,
            })
        }

        setCreatePayload(newPayload)
    }

    const validateInput = (value) => {
        if (value < 7) setError(true);
        else {
            setError(false);
            handleSectionSelect(value)
        }
    };

    const handleMandatoryClick = (checked) => {
        const newPayload = { ...createPayload }

        if (checked) {
            newPayload.subjectsSection.find(
                (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
            ).sections?.map(section => {
                section['totalQuestions'] = -1
                section['mandatoryQuestions'] = -1
            })
        }
        else {
            newPayload.subjectsSection.find(
                (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
            ).sections?.map(section => {
                section['totalQuestions'] = null
                section['mandatoryQuestions'] = null
            })
        }

        setCreatePayload(newPayload)
    }

    const handleChapterClick = (id, name) => {
        if (
            !subject?.chosenChapters?.find(
                (el) => el.id == id
            )
        ) {
            const newPayload = { ...createPayload };
            newPayload.subjectsSection.find(
                (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
            ).chosenChapters?.push({
                id,
                name
            })
            setCreatePayload(newPayload);
        }
    }

    const handleDeleteChapter = (id) => {

        const newPayload = { ...createPayload };

        newPayload.subjectsSection.find(
            (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
        ).chosenChapters = subject?.chosenChapters?.filter(el => el.id != id)

        setCreatePayload(newPayload);
    }

    const handleSameMS = (checked) => {
        const newPayload = { ...createPayload }

        const firstSec = newPayload.subjectsSection.find(
            (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
        ).sections?.[0]

        if (checked) {
            setSameMarkingSc(true)
            newPayload.subjectsSection.find(
                (el) => el.testSeriesSubjectId == subject?.testSeriesSubjectId
            ).sections?.map(section => {
                section['markingSchemeId'] = firstSec.markingSchemeId
                section['markingSchemeTitle'] = firstSec.markingSchemeTitle
            })
        }
        else {
            setSameMarkingSc(false)
        }

        setCreatePayload(newPayload)
    }

    return (
        <div
            style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #E4E4E4",
                height: "fit-content",
                width: "100%",
                borderRadius: 8,
                marginTop: "20px",
            }}
        >
            <div className="r-fs-c-c p-20" style={{ width: "100%" }}>
                <div className="r-jsb" style={{ width: "100%" }}>
                    <div className="bold-600 text-xmd" style={{ color: "#636363" }}>
                        {subject?.name}
                    </div>

                    <div className="display-flex" style={{ marginRight: -5 }}>
                        {(subject?.sections?.length > 1 && !oldSections) && (
                            <div className="display-flex m-r-20">
                                <Checkbox
                                    style={{ fontSize: 5, marginTop: -3 }}
                                    onChange={(e) => { handleSameMS(e.target.checked) }}
                                    checked={sameMarkingSc}
                                />
                                <div className="m-l-5 text-xs" style={{ color: "#191919" }}>
                                    Same marking scheme for all sections
                                </div>
                            </div>
                        )}

                        <div className="display-flex" style={{ marginRight: 20 }}>
                            <Checkbox
                                style={{ fontSize: 5, marginTop: -3 }}
                                checked={subject?.sections?.[0].name != "_default_"}
                                onChange={(e) => { handleIncludeSections(e.target.checked) }}
                                disabled={oldSections}
                            />
                            <div className="m-l-5 text-xs" style={{ color: "#191919" }}>
                                Include Sections
                            </div>
                        </div>

                        <div
                            className="display-flex"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <Checkbox
                                style={{ fontSize: 5, marginTop: -3 }}
                                checked={subject?.sections?.[0].totalQuestions == -1}
                                onChange={(e) => { handleMandatoryClick(e.target.checked) }}
                                disabled={oldSections}
                            />
                            <div className="m-l-5 text-xs" style={{ color: "#191919" }}>
                                All questions are mandatory
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bold-600 text-xs m-t-20">
                    Chapters
                </div>
                <Select
                    className="m-t-5 test-custom-select createTestModalValue"
                    style={{ width: "100%", minWidth: "40%" }}
                    placeholder="Select chapters"
                    value={null}
                    onChange={(id, value) => { handleChapterClick(id, value.children) }}
                // disabled={subject?.individualTestSubjectId}
                >
                    {chapSubject?.chapters?.map((item) => {
                        return <Option value={item.id} key={item.id} style={{
                            backgroundColor: (subject?.chosenChapters?.find(
                                (el) => el.id == item.id
                            )) && '#f6f2ff'
                        }}>
                            {item.name}
                        </Option>
                    })}
                </Select>

                {subject?.chosenChapters?.length > 0 && (
                    <div className="flex-wrap r-ac">
                        {subject?.chosenChapters?.map((chap) => (
                            <div
                                key={chap.id}
                                className="shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac"
                                style={{ background: "#594099" }}
                            >
                                <div className="text-xs">{chap.name}</div>
                                <AiOutlineClose
                                    onClick={() => { handleDeleteChapter(chap.id) }}
                                    className="m-l-10 text-xs cursor-pointer"
                                />
                            </div>
                        ))}
                    </div>
                )}

                {subject?.sections?.[0].name != "_default_" && <>
                    <div
                        className="bold-600 text-xs m-t-30"
                        style={{ color: "#636363", marginBottom: 5 }}
                    >
                        No of sections needed
                    </div>
                    <div className="display-flex">
                        {[1, 2, 3, 4, 5, 6, "others"]?.map((item, index) => {
                            return (
                                <div className="display-flex" style={{ width: 50 }}>
                                    <div
                                        onClick={() => { handleSectionSelect(item) }}
                                        className={`r-c-c text-xmd bold-600 cursor-pointer ${item == totalSections ? "purpleBtnWrap" : "blankGreyBtnWrap"}`}
                                        style={{
                                            minWidth: 45,
                                            height: 45,
                                            borderRadius: "100%",
                                            border: "1px solid #E6E6E6",
                                            fontFamily: "roboto",
                                            cursor: (item >= oldSections || item == 'others') ? "pointer" : "not-allowed"
                                        }}
                                    >
                                        {item}
                                    </div>
                                </div>
                            );
                        })}
                        {isOther &&
                            <div style={{ marginLeft: "40px", marginTop: "5px" }}>
                                <Form>
                                    <Form.Item
                                        label="Input"
                                        validateStatus={error ? 'error' : ''}
                                        help={error ? 'Value must be 7 or greater.' : ''}
                                    >
                                        <Input
                                            style={{ width: "300px" }}
                                            placeholder='Enter values'
                                            type="number"
                                            onChange={(e) => { validateInput(e.target.value) }}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        }
                    </div>
                </>}



                {subject?.sections?.map((section, secIdx) => (
                    <ConnectedSection
                        section={section}
                        secIdx={secIdx}
                        testSeriesSubjectId={subject.testSeriesSubjectId}
                        allQuesMandatory={subject?.sections?.[0].totalQuestions == -1}
                        sameMarkingSc={sameMarkingSc}
                        handleSameMS={handleSameMS}
                    />
                ))}
            </div>
        </div>
    );

}

function CreateIndividualTest({
    getTest,
    createPayload,
    setCreatePayload,
    fetchMarkingSchemeDataTS,
    fetchAllSubjects,
    allSubjects,
    createTest
}) {
    const [pageLoader, togglePageLoader] = useState(false);
    const [buttonLoader, toggleButtonLoader] = useState(false);
    const location = useLocation();

    const history = useHistory();

    useEffect(() => {
        togglePageLoader(true);
        getTest(() => togglePageLoader(false));

        fetchMarkingSchemeDataTS();
        fetchAllSubjects();
    }, []);

    const validateFields = () => {

        let secValidation = true, mandatoryValidation = true
        createPayload?.subjectsSection?.forEach(subject => {
            subject.sections?.forEach(section => {
                if (!section?.name) secValidation = false
                if (parseInt(section?.totalQuestions) < parseInt(section?.mandatoryQuestions)) mandatoryValidation = false
            })
        })

        if (!secValidation)
            return Notification.error("Error", 'Please enter section name');

        if (!mandatoryValidation)
            return Notification.error("Error", 'Mandatory questions are greater than total questions');


        toggleButtonLoader(true)
        createTest(
            () => toggleButtonLoader(false),
            () => history.push("/testSeries/addQuestions")
        )
    }

    console.log('aaaa', location?.state);

    const PageHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <div className="r-jsb p-10" style={{ width: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <ArrowLeftOutlined
                            style={{ marginTop: "5px", fontSize: "22px", marginRight: 20 }}
                            onClick={() => {
                                // location?.state?.key?.fromCourseInfoPage ?
                                    history.push({
                                        pathname: '/course-details',
                                        state: {
                                            key: {
                                                isEdit: "isFromEdit",
                                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                searchedValue: location?.state?.key?.searchedValue
                                            }
                                        }
                                    })
                                    // :
                                    // history.push({
                                    //     pathname: '/add-online-test-series',
                                    //     state: {
                                    //         key: {
                                    //             isEdit: "isFromEdit",
                                    //             fromCourseCard: location?.state?.key?.fromCourseCard,
                                    //             fromCourseDetails: true,
                                    //             fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                    //             searchedValue: location?.state?.key?.searchedValue
                                    //         }
                                    //     }
                                    // })
                            }}
                        />
                        <div className="bold-700 text-md" style={{ fontFamily: "roboto" }}>
                            {(createPayload?.id) ? "Edit Test" : "Create Test"}
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button
                            className="blankGreyBtnWrap radius-100 m-r-30"
                            style={{ width: 120, marginRight: "25px" }}
                            onClick={() => {

                                history.push({
                                    pathname: '/course-details',

                                    state: {
                                        key: {
                                            isEdit: "isFromEdit",

                                        }
                                    }
                                })
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            loading={buttonLoader}
                            className="purpleBtnWrap radius-100 purpleButtonHoverEffect"
                            style={{ width: 120 }}
                            onClick={validateFields}
                        >
                            {createPayload?.id ? <></> : <PlusCircleOutlined />}
                            {createPayload?.id ? "SAVE" : "CREATE"}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const handleSubjectClick = (testSeriesSubjectId, value) => {
        if (
            !createPayload?.subjectsSection?.find(
                (el) => el.testSeriesSubjectId == testSeriesSubjectId
            )
        ) {
            const newPayload = { ...createPayload };
            newPayload.subjectsSection.push({
                individualTestSubjectId: null,
                testSeriesSubjectId,
                name: value.children,
                sections: [
                    {
                        id: null,
                        name: "_default_",
                    },
                ],
                chosenChapters: []
            });
            setCreatePayload(newPayload);
        }
    };

    const handleDeleteSubject = (subject) => {
        const newPayload = { ...createPayload };

        newPayload.subjectsSection = newPayload?.subjectsSection?.filter(
            (el) => el.testSeriesSubjectId != subject.testSeriesSubjectId
        )

        setCreatePayload(newPayload);
    }

    return (
        <div style={bodyStyle}>
            <PageHeader />

            <div
                className="m-t-30"
                style={{
                    overflowY: "scroll",
                    height: "calc(100% + -80px)",
                    padding: "0px 150px 0px 150px",
                }}
            >
                <div
                    className="text-xmd bold-600 m-b-5 m-t-20"
                    style={{ color: "#636363" }}
                >
                    Title
                </div>
                <Input
                    value={createPayload?.title}
                    onChange={(e) => {
                        setCreatePayload({ ...createPayload, title: e.target.value })
                    }}
                    style={{ borderRadius: 5, width: "100%" }}
                    placeholder="Enter title"
                    bordered={true}
                />

                <div className="m-t-20">
                    <div
                        className="bold-600 text-xmd"
                        style={{ color: "#636363", marginBottom: 5 }}
                    >
                        Subject(s)
                    </div>
                    <Select
                        className="test-custom-select createTestModalValue"
                        style={{ width: "100%", color: "#AEAEAE" }}
                        placeholder={"Select subject(s)"}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        showArrow={true}
                        loading={false}
                        value={null}
                        onSearch={(text) => { }}
                        onSelect={(testSeriesSubjectId, value) => {
                            handleSubjectClick(testSeriesSubjectId, value);
                        }}
                    >
                        {allSubjects?.map((subject, idx) => {
                            return <Option
                                className="createTestModalDropdown"
                                key={idx}
                                value={subject?.testSeriesSubjectId}
                                style={{
                                    backgroundColor: (createPayload?.subjectsSection?.find(
                                        el => el?.testSeriesSubjectId == subject?.testSeriesSubjectId
                                    ))
                                        && '#f6f2ff'
                                }}
                            >
                                {subject?.subjectName}
                            </Option>
                        })}
                    </Select>

                    {createPayload?.subjectsSection?.length > 0 && (
                        <div className="flex-wrap r-ac">
                            {createPayload?.subjectsSection?.map((subject, subIdx) => (
                                <div
                                    key={subIdx}
                                    className="shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac"
                                    style={{ background: "#594099" }}
                                >
                                    <div className="text-xs">{subject?.name}</div>
                                    <AiOutlineClose
                                        onClick={() => { handleDeleteSubject(subject) }}
                                        className="m-l-10 text-xs cursor-pointer"
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {createPayload?.subjectsSection?.map((subject, subIdx) => (
                        <SubjectSectionsCard
                            chapSubject={allSubjects?.find(el =>
                                el.testSeriesSubjectId == subject.testSeriesSubjectId
                            )}
                            subject={subject}
                            setCreatePayload={setCreatePayload}
                            createPayload={createPayload}
                        />
                    ))}
                </div>

                <div className="r-c-sb  m-t-20">
                    <div > <span className="bold-700 text-xmd">Test to be live on</span> <span style={{ color: "#636363" }}>(optional)</span>
                        {/* <Tooltip title={'If you specify the date & time, students can take the test only within the specified time.'}
                       overlayStyle={{ maxWidth: '300px' }}
                       placement="right"
                       >
                        <span style={{ color: '#1089FF', marginLeft:'5px' }}>
                            <InfoCircleOutlined />
                        </span>
                    </Tooltip> */}
                    </div>

                    <div
                        className="r-c-fe bold-400"
                    >
                        <Checkbox
                            checked={createPayload?.freePreview}
                            onChange={(e) => {
                                setCreatePayload({
                                    ...createPayload,
                                    freePreview: e.target.checked
                                })
                            }}
                        />
                        <div className="m-l-5 bold-600 text-xmd">
                            FREE ATTEMPT
                        </div>
                    </div>
                </div>
                <div style={{ color: "#636363", fontSize: 13 }}>If you specify the date & time, students can take the test only within the specified time.</div>



                <div className="r-jsb m-t-10" style={{ width: "100%" }}>
                    <div className="flex-1 m-r-20">
                        <div
                            className="bold-600 text-xmd"
                            style={{ color: "#636363", marginBottom: 5 }}
                        >
                            Date
                        </div>
                        <DatePicker
                            format="DD/MM/YYYY"
                            className="createmodalbg"
                            style={{ width: "100%", minWidth: "40%" }}
                            dropdownClassName="legend-range-calendar-drpdwn"
                            placeholder={"Publish date"}
                            value={createPayload?.testDate ? moment(createPayload?.testDate, 'YYYY-MM-DD') : ''}
                            showToday={false}
                            allowClear={true}
                            onChange={(e, date) => {
                                setCreatePayload({
                                    ...createPayload,
                                    testDate: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') == 'Invalid date'
                                        ? null : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                                })
                            }}
                            disabledDate={(date) => date < moment().startOf('day')}
                        />
                    </div>
                    <div className="flex-1">
                        <div
                            className="bold-600 text-xmd"
                            style={{ color: "#636363", marginBottom: 5 }}
                        >
                            Start time
                        </div>
                        <TimePicker
                            minuteStep={10}
                            showNow={false}
                            format={"HH:mm"}
                            inputReadOnly={true}
                            disabled={(!createPayload?.testDate || (moment().format('YYYY-MM-DD') > createPayload?.testDate))}
                            style={{ width: "100%", minWidth: "40%" }}
                            disabledHours={() => {
                                if (moment().format('YYYY-MM-DD') >= createPayload?.testDate)
                                    return range(0, 24).splice(0, moment().hour())
                            }}
                            disabledMinutes={(selectedHour) => {
                                if (selectedHour == moment().hour() && moment().format('YYYY-MM-DD') >= createPayload?.testDate)
                                    return range(0, 60).splice(0, moment().minute())
                            }}
                            className="m-b-5 test-custom-select createTestModalValue createmodalbg"
                            value={createPayload?.startTime ? moment(createPayload?.startTime, 'HH:mm') : ''}
                            onChange={(e, time) => {
                                setCreatePayload({
                                    ...createPayload,
                                    startTime: moment(time, 'HH:mm').format('HH:mm') == 'Invalid date'
                                        ? null : moment(time, 'HH:mm').format('HH:mm')
                                })
                            }}
                        />
                    </div>
                    <div className="flex-1 m-l-20">
                        <div
                            className="bold-600 text-xmd"
                            style={{ color: "#636363", marginBottom: 5 }}
                        >
                            Duration (in mins)
                        </div>
                        <Input
                            placeholder='Enter values'
                            type="number"
                            value={createPayload?.duration}
                            onChange={(e) => {
                                setCreatePayload({
                                    ...createPayload,
                                    duration: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>
            </div>

            <PageDataLoader visible={pageLoader} />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { createPayload, markingSchemes, allSubjects } = state.marketTest;
    const { storeSubjectSection } = state.testAdmin
    return { createPayload, markingSchemes, allSubjects, storeSubjectSection };
};

const mapDispatchToProps = (dispatch) => ({
    getTest: (loaderCallback) => dispatch(getTest(loaderCallback)),
    setCreatePayload: (data) => dispatch(setCreatePayload(data)),
    fetchMarkingSchemeDataTS: () => dispatch(fetchMarkingSchemeDataTS()),
    fetchAllSubjects: () => dispatch(fetchAllSubjects()),
    createTest: (loaderCallback, successCallBack) => dispatch(createTest(loaderCallback, successCallBack)),
    fromCreateNewButton: (bool) => dispatch(fromCreateNewButton(bool)),
    setFromSectionMarkingScheme: (val) => dispatch(setFromSectionMarkingScheme(val)),
});

const ConnectedSection = connect(mapStateToProps, mapDispatchToProps)(SingleSection)
export default connect(mapStateToProps, mapDispatchToProps)(CreateIndividualTest)
