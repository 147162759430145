import React, { useState } from 'react'
import TopBar from './topBar'
import Footer from './footer'
import './myAcc.css'
import './styles.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { connect } from 'react-redux'




export const LocationList = () => {
    return (
        <>
            <div className='location-design'>
                <div className='' style={{ margin: "15px 1rem" }}>
                    <div className='text-sm font-bold'>Vectors Academy</div>
                    <div style={{}}>
                        2nd & 3rd Floor, 67 B, Fatick Chandra Rd, <br />
                        Latasil, Uzan Bazar, Guwahati, <br />
                        Assam 781001
                    </div>
                </div>
            </div>
            <div className='location-design'>
                <div className='' style={{ margin: "15px 1rem" }}>
                    <div className='text-sm font-bold'>Vectors Academy</div>
                    <div style={{}}>
                        2nd & 3rd Floor, 67 B, Fatick Chandra Rd, <br />
                        Latasil, Uzan Bazar, Guwahati, <br />
                        Assam 781001
                    </div>
                </div>
            </div>
            <div className='location-design'>
                <div className='' style={{ margin: "15px 1rem" }}>
                    <div className='text-sm font-bold'>Vectors Academy</div>
                    <div style={{}}>
                        2nd & 3rd Floor, 67 B, Fatick Chandra Rd, <br />
                        Latasil, Uzan Bazar, Guwahati, <br />
                        Assam 781001
                    </div>
                </div>
            </div>
            <div className='location-design'>
                <div className='' style={{ margin: "15px 1rem" }}>
                    <div className='text-sm font-bold'>Vectors Academy</div>
                    <div style={{}}>
                        2nd & 3rd Floor, 67 B, Fatick Chandra Rd, <br />
                        Latasil, Uzan Bazar, Guwahati, <br />
                        Assam 781001
                    </div>
                </div>
            </div>
        </>
    )
}

function Locations() {
    const [latitude, setLatitude] = useState(12.912877221923127)
    const [longi, setLongi] = useState(77.64481588526345)
    const [map, setMap] = React.useState(null)
    const history = useHistory();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANNuGsbyjTzqrtYc65aRapQ1fkfaVh0aI",
        libraries: ["places"]
    })
    const containerStyle = {
        width: "100%",
        height: "70vh"
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])
    // const createMarkers = () => {
    //     {
    //         if (latitude && longi) {
    //             return <Marker
    //                 position={{ lat: latitude, lng: longi }}
    //             />
    //         } else { return null }
    //     }


    // }

    return (
        <div>
            <div className="top-bar">
                <TopBar />
            </div>
            <div style={{ backgroundColor: "#FAFAFA" }}>
                <div className='main-container-parent'>
                    <div className="main-container" style={{}}>

                        <div className='' style={{}}>
                            <div className='text-sm font-bold ' onClick={() => history.push("/")}  ><img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} style={{ cursor: "pointer" }} /><span className='m-l-10 m-t-5'>All Locations</span></div>

                            <div className='location-parent'>

                                <LocationList />

                            </div>
                          
                        </div>
                    </div>
                    <div>
                                {isLoaded ? <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    onLoad={onLoad}
                                    center={{ lat: latitude, lng: longi }}
                                    defaultZoom={15}
                                    zoom={13}
                                >
                                    {/* {createMarkers()} */}

                                </GoogleMap> : null}
                            </div>
                </div>
                <div className='m-t-100' style={{}}>
                    <Footer />
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { coursePageData, homePageData, loginDetails, } = state.aulasMarket
    return { coursePageData, homePageData, loginDetails,  }
};

const mapDispatchToProps = (dispatch) => ({
 
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations)
