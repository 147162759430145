import { Select } from 'antd';
import PageHeader from 'components/pageHeader';
import DatePicker from 'pages/care/datePicker'
import React, { useEffect, useState } from 'react'
import BroadcastCard from './components/broadcastCard'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentIcon, updateSetType, currentTypes, checkBoxValue } from 'actions/digitalLibrary'
import { useHistory } from 'react-router';
// import BroadCastRecordingsModal from './modals/broadCastRecordingsModal';
import BroadCastSubjectCard from './components/broadcastSubject'
import { Heading } from 'components/Typography';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


const BroadCastRecordings = ({ fetchClassLibraryData, updateCurrentSub, updateCurrentIcon, updateSetType, currentTypes, classLibraryList, checkBoxValue,getStudentHome,
  classSubjectList, user,
  selectedSubject,
  addedTeacher, }) => {
  const location = useLocation();

  const [isRecordingsModalVisible, SetIsRecordingsModalVisible] = useState(false)
  useEffect(() => {
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes('digital-library-dataoperator')) {
      // if(user.access.includes("Admin")||user.role.includes("SuperAdmin")||user.role.includes("Data Operator")){
      fetchClassLibraryData()
    }
  }, [])
  const history = useHistory();

  const handleBackPress = () => {
    history.goBack();
  }


  return (
    <div>
      <PageHeader title={'Live class recordings'} onBackPress={() => handleBackPress()} />
      <div style={{ marginTop: "60px" }}>
      <Heading className='m-t-20 m-l-10'>Subjects ({ user.access.includes("digital-library-student") ? getStudentHome?.subjects?.length : classSubjectList?.length})</Heading>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {user.access.includes("digital-library-student") ? (<>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {getStudentHome?.subjects?.map((item, index) => (
                <div style={{ margin: "7px" }}>
                  <BroadCastSubjectCard title={item?.subjects_name ? item?.subjects_name : item?.subjectName} filesNO={item?.broadcastCount ? item?.broadcastCount : item?.broadcastCount} images={item?.icon ? item?.icon : item?.path} index={index} classSubjectList={classSubjectList} item={item} subjectsId={item?.subjects_id ? item?.subjects_id : item?.subjectId} location={location} checkBoxValue={checkBoxValue} />
                </div>
              ))}
            </div></>) :
            (<> <div style={{ display: "flex", flexWrap: "wrap" }}>
              {classSubjectList?.map((item, index) => (
                <div style={{ margin: "7px" }}>
                  <BroadCastSubjectCard title={item?.subjects_name ? item?.subjects_name : item?.subjectName} filesNO={item?.broadcastCount ? item?.broadcastCount : item?.broadcastCount} images={item?.icon ? item?.icon : item?.path} index={index} classSubjectList={classSubjectList} item={item} subjectsId={item?.subjects_id ? item?.subjects_id : item?.subjectId} location={location} checkBoxValue={checkBoxValue} />
                </div>
              ))}
            </div></>)}
        </div>
      </div>
      {/*        
        <BroadCastRecordingsModal
            isRecordingsModalVisible={isRecordingsModalVisible}
            SetIsRecordingsModalVisible={SetIsRecordingsModalVisible}
            /> */}
    </div>
  )
}
const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    selectedSubject,
    addedTeacher,
    getStudentHome

  } = state.digitalLibrary;
  const { user } = state.session
  return {
    classLibraryList,
    classSubjectList,
    selectedSubject,
    addedTeacher,
    user,
    getStudentHome

  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClassLibraryData: () => dispatch(fetchClassLibraryData()),
  updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
  updateCurrentIcon: (images) => dispatch(updateCurrentIcon(images)),
  updateSetType: (type) => dispatch(updateSetType(type)),
  currentTypes: (type) => dispatch(currentTypes(type)),
  checkBoxValue: (value) => dispatch(checkBoxValue(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(BroadCastRecordings);

