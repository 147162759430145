import {
  ArrowLeftOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Checkbox,
  Pagination,
  Popover,
  Typography,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import image from "../../Assets/feeManagement/Mask Group 2.png";
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
import SelectRangeModal from "./selectRangeModal";
import OfflinePayReq from "./addOfflinePayReqModal";
import AddDetailsModal from "./addDetailsModal";
import {
  fetchTransaction,
  setAllActivitiesFilter,
  setInvoiceFile,
  setFeeDetails,
  postSendInvoice
} from "actions/feeManagement";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import { Api } from "services";
import CalenderPopup from "./calenderPop";
import ShowReasonModal from "./showReasonModal";
import { Button } from 'components/Button'
import PageHeader from 'components/pageHeader'
import { InputSearch } from "components/customInputs";
import { fetchStudentsClassList } from "actions/userManagementV2";


const liveCoursesIcon = require('Assets/liveCoursesIcon.svg').default;
const videoResourceIcon = require('Assets/videoResourceIcon.svg').default;
const studyMaterialIcon = require('Assets/studyMaterialIcon.svg').default;
const testSeriesIcon = require('Assets/testSeriesIcon.svg').default;


function AllTransactions({
  fetchTransaction,
  feeMangementData,
  setAllActivitiesFilter,
  allActivitiesFilter,
  setInvoiceFile,
  setFeeDetails,
  postSendInvoice,
  fetchStudentsClassList,
  classList
}) {
  console.log("AA", feeMangementData?.selectedStudentDescId);
  const [showSelectRangeModal, setShowSelectRangeModal] = useState(false);
  const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
  const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
  const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
  const [pageLoader, tooglePageLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [showCustomCal, setShowCustomCal] = useState(true);
  const [isAdditionModal, setIsAdditionModal] = useState(false);
  const [showGeneratePayReq, setShowGeneratePayReq] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);

  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }

  const handlePageination = (value) => {
    setPage(value);
    setAllActivitiesFilter("page", value);
    tooglePageLoader(true);

    fetchTransaction(() => tooglePageLoader(false));
  };
  const history = useHistory();
  const goBack = (history) => {
    history.goBack();
    setAllActivitiesFilter("page", 1);
  };
  useEffect(() => {
    setAllActivitiesFilter("page", 1);
    setAllActivitiesFilter("status", ["paidAndRefunded"]);
    setAllActivitiesFilter("search", null);
    setAllActivitiesFilter("section", null);
    setAllActivitiesFilter("standardId", null);
    setAllActivitiesFilter("mode", null);
    setAllActivitiesFilter("startDate", null);
    setAllActivitiesFilter("endDate", null);
    setFeeDetails("generatePayDiscount", null);
    setAllActivitiesFilter("filter", null);
    setFeeDetails("generatePayAdditions", null);
    setInvoiceFile(null);
    tooglePageLoader(true);
    fetchTransaction(() => tooglePageLoader(false));
    fetchStudentsClassList(null, () => { });
  }, []);

  console.log({ classList })

  const handleFilterOption = (value) => {
    setAllActivitiesFilter("filter", value);
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);
    fetchTransaction(() => tooglePageLoader(false));
  };

  const handleClick = (invoiceFile) => {
    setInvoiceFile(invoiceFile);
    history.push("/fee-management/invoice");
  };

  const content = (
    <div>
      <p>
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setAllActivitiesFilter("mode", "online");
            } else {
              setAllActivitiesFilter("mode", null);
            }
            tooglePageLoader(true);
            setAllActivitiesFilter("page", 1);

            fetchTransaction(() => tooglePageLoader(false));
          }}
          checked={allActivitiesFilter?.mode == "online" ? true : false}
        >
          Online
        </Checkbox>
      </p>
      <p>
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setAllActivitiesFilter("mode", "offline");
            } else {
              setAllActivitiesFilter("mode", null);
            }
            tooglePageLoader(true);
            setAllActivitiesFilter("page", 1);

            fetchTransaction(() => tooglePageLoader(false));
          }}
          checked={allActivitiesFilter?.mode == "offline" ? true : false}
        >
          Offline
        </Checkbox>
      </p>
    </div>
  );

  const content1 = (
    <div>
      {showCustomCal ? (
        <div
          style={{
            fontFamily: "roboto",
            width: "200px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
          }}
        >
          <div className="dropmenuBph" style={{ color: "#AEAEAE" }}>
            FILTER OPTIONS{" "}
            <span
              onClick={() => {
                setAllActivitiesFilter("startDate", null);
                setAllActivitiesFilter("endDate", null);
                handleFilterOption(null);
              }}
              style={{
                color: "#FF414D",
                fontWeight: "600",
                fontSize: "12px",
                marginLeft: "11px",
                cursor: "pointer",
              }}
            >
              CLEAR ALL
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("today")}
            >
              Today
            </div>
            {allActivitiesFilter?.filter == "today" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("yesterday")}
            >
              Yesterday
            </div>
            {allActivitiesFilter?.filter == "yesterday" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("month")}
            >
              This month
            </div>
            {allActivitiesFilter?.filter == "month" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("lastmonth")}
            >
              Past month
            </div>
            {allActivitiesFilter?.filter == "lastmonth" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => handleFilterOption("threemonths")}
            >
              Past 3 months
            </div>
            {allActivitiesFilter?.filter == "threemonths" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => {
                // handleFilterOption(" Customise range");
                setAllActivitiesFilter("filter", "Customise range");

                setShowCustomCal(false);
              }}
            >
              Customise range
            </div>
            {allActivitiesFilter?.filter == "Customise range" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          <CalenderPopup
            tooglePageLoader={tooglePageLoader}
            showCustomCal={setShowCustomCal}
          />
        </div>
      )}
    </div>
  );

  const handleChangeStdFilter = (value) => {
    const mappedValue = value.map(item => item === "unassigned" ? -1 : item);
    setAllActivitiesFilter("section", mappedValue);
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);
    fetchTransaction(() => tooglePageLoader(false));
  };

  const handleCourseSelect = (value) => {
    console.log({ value });
    setAllActivitiesFilter('courseIds', value)
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);
    fetchTransaction(() => tooglePageLoader(false));
  };

  useEffect(() => {
    setAllActivitiesFilter('courseIds', [])
    setAllActivitiesFilter('courseId', [])
  }, [])


  const content2 = (
    <div
      style={{
        fontFamily: "roboto",
        width: "200px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Checkbox.Group
        style={{ display: "flex", flexDirection: "column" }}
        onChange={(value) => handleChangeStdFilter(value)}
        defaultValue={location?.state?.classroom ? location?.state?.classroom.toString() : null}
      >
        {feeMangementData?.dashBoardDetails?.recentTransactions?.standards
          ?.length != 0
          ? feeMangementData?.dashBoardDetails?.recentTransactions?.standards?.map(
            (item) => (
              <Checkbox
                className="dropmenuBph"
                style={{ color: "black", marginLeft: "0" }}
                value={item?.id}
              >
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "130px" }}
                >
                  {item?.std}-{item?.section}
                </Typography.Text>
              </Checkbox>
            )
          )
          : null}
      </Checkbox.Group>
    </div>
  );

  const courseContent = (
    <div
      style={{
        fontFamily: "roboto",
        width: "200px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Checkbox.Group
        style={{ display: "flex", flexDirection: "column" }}
        onChange={(value) => handleCourseSelect(value)}
      // defaultValue={location?.state?.classroom ? location?.state?.classroom.toString() : null}
      >
        {classList?.response?.items?.length != 0
          ? classList?.response?.items?.map(
            (item) => (
              <Checkbox
                className="dropmenuBph"
                style={{ color: "black", marginLeft: "0" }}
                value={item?.id}
              >
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "130px" }}
                >
                  {item?.courseName}
                </Typography.Text>
              </Checkbox>
            )
          )
          : null}
      </Checkbox.Group>
    </div>
  );

  const actions = [
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: 500 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
          marginBottom: "25px",
          height: 'fit-content',
          width: '80%'
        }}
      >
        <div className="" style={{ width: '100%' }}>
          <div style={{ marginBottom: '10px', display: 'flex', width: 500, justifyContent: 'space-between' }}>
            <Button type='secondary' onClick={() => { setShowSelectRangeModal(true) }} icon={<DownloadOutlined />} style={{ color: '#1089FF', border: '1px solid #1089FF', marginRight: 20, width: 240 }}>DOWNLOAD REPORT</Button>
            <Button style={{ width: 240 }} type='primary' onClick={() => { setShowOfflinePayReqModal(true); setFeeDetails("paidDate", null); }} icon={<PlusCircleOutlined />}>ADD OFFLINE PAYMENT</Button>
          </div>
          <div className="r-jsb" style={{ width: '500px' }}>
            <Popover
              placement="bottomRight"
              //   title={text}
              content={content2}
              trigger="click"
            >
              <div
                className="classFilter"
                style={{
                  width: "240px",
                  height: "30px",
                  borderRadius: "2px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    color: "#d9d9d9",
                    marginBottom: "0",
                    marginLeft: "5px",
                  }}
                >
                  Filter by: Classes (
                  {
                    feeMangementData?.dashBoardDetails?.recentTransactions
                      ?.standards.length
                  }
                  )<span style={{ marginLeft: "70px" }}>▼</span>
                </p>
              </div>
            </Popover>
            <Popover
              placement="bottomRight"
              content={courseContent}
              trigger="click"
            >
              <div
                className="classFilter"
                style={{
                  width: "240px",
                  height: "30px",
                  borderRadius: "2px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    color: "#d9d9d9",
                    marginBottom: "0",
                    marginLeft: "5px",
                  }}
                >
                  Filter by: Courses ({classList?.response?.items.length})
                  <span style={{ marginLeft: "70px" }}>▼</span>
                </p>
              </div>
            </Popover>
          </div>

        </div>

        <div style={{ marginLeft: "20px" }}></div>
      </div>
    </div>
  ]

  const handleBackPress = () => {
    history.goBack();
  }

  return (
    <div style={{ fontFamily: "roboto", overflow: "hidden", height: "86vh", marginTop: 50 }}>
      {/* <div style={{ marginLeft: "-20px" }}>
        <PageHeader
          title={
            <div
              className="text-md"
              style={{
                fontWeight: "bold",
                height: "45px",
                marginTop: "10px",
                //   marginLeft: "-100px",
              }}
            >
              Transactions
            </div>
          }
          onBack={() => goBack(history)}
          backIcon={<ArrowLeftOutlined />}
        />
      </div> */}
      {/* <div style={{ position: "absolute", right: "30px", top: "50px" }}>
        <Button
          onClick={() => {
            setShowSelectRangeModal(true);
          }}
          style={{
            borderRadius: "20px",
            border: "1px solid #1089FF",
            color: "#1089FF",
            fontWeight: "600",
            height: "40px",
            marginRight: "20px",
            height: "35px",
            fontSize: "12px",
          }}
        >
          <DownloadOutlined style={{ fontSize: "15px" }} /> DOWNLOAD REPORT
        </Button>
        <Button
          onClick={() => {
            setShowOfflinePayReqModal(true);
            setFeeDetails("paidDate", null);
          }}
          style={{
            borderRadius: "20px",
            border: "1px solid #594099",
            backgroundColor: "#594099",
            color: "white",
            fontWeight: "600",
            height: "35px",
            fontSize: "12px",
          }}
        >
          <PlusCircleOutlined style={{ fontSize: "15px" }} /> ADD OFFLINE
          PAYMENT
        </Button>
      </div> */}

      <div>
        <PageHeader title='Transactions' actions={actions} onBackPress={() => handleBackPress()} />
      </div>

      <div style={{ width: "100%", height: "76vh", overflowY: "scroll", paddingBottom: "30px", marginTop: 60 }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr
              style={{
                backgroundColor: "#F6F4FE",
                padding: "20px",
                height: "50px",
              }}
            >
              <th style={{ color: "#636363" }}>STUDENT’S DETAILS</th>
              <th style={{ color: "#636363" }}>DESCRIPTIONS </th>
              <th style={{ color: "#636363" }}>
                PAYMENT DATE
                <Popover
                  placement="leftTop"
                  //   title={text}
                  content={content1}
                  trigger="click"
                >
                  {/* <Button>RT</Button> */}
                  <CalendarOutlined
                    onClick={() => {
                      setShowCustomCal(true);
                    }}
                    style={{
                      fontSize: "18px",
                      color: "#1089FF",
                      marginLeft: "20px",
                    }}
                  />
                </Popover>
              </th>
              <th style={{ color: "#636363" }}>
                TYPE OF PAYMENT
                <Popover
                  placement="rightTop"
                  //   title={text}
                  content={content}
                  trigger="click"
                >
                  {/* <Button>RT</Button> */}
                  <CaretDownOutlined
                    onClick={() => {
                      setShowCustomCal(true);
                    }}
                  />
                </Popover>
              </th>
              <th style={{ color: "#636363" }}>INVOICE NUMBER</th>
              <th style={{ color: "#636363" }}>PAYMENT MODE</th>
              <th></th>

            </tr>

          </thead>

          {/* <tr> */}
          {
            feeMangementData?.allActiviies?.items?.length != 0
              ? feeMangementData?.allActiviies?.items?.map((item, index) => (
                <tr
                  className={"cursor-pointer"}
                  style={
                    index % 2
                      ? {
                        backgroundColor: "#FAFAFA",
                        // padding: "100px",
                        height: "100px",
                        // marginLeft: "30px",
                      }
                      : {
                        // padding: "100px"
                        height: "100px",
                      }
                  }
                  onClick={() => {
                    // item?.payment_type == "online"
                    // ?
                    handleClick(item?.invoice);
                    // : {};
                  }}
                >
                  <td
                    style={{
                      // backgroundColor: "red",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100px",
                      marginRight: "-10px",

                      // width: "200px",
                    }}
                  >
                    <div
                      className="r-ac"
                      style={{
                        fontFamily: "roboto",
                        marginLeft: "-20px",
                        width: "160px",
                      }}
                    >
                      <div  >
                        <img src={Api._s3_url + item?.user?.image} style={{ width: "35px", height: "35px", marginLeft: "10px" }} alt="" />

                      </div>
                      <div style={{ marginLeft: "10px" }}>
                        <div style={{ fontWeight: "550" }}>
                          <Typography.Text ellipsis={{ tooltip: true }}>
                            <div style={{ width: "150px", fontFamily: 'roboto', fontWeight: "550", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.user?.name}</div>
                          </Typography.Text>
                        </div>
                        <div style={{ color: "#636363" }}>
                          <Typography.Text ellipsis={{ tooltip: true }}>
                            <div style={{ width: "150px", fontFamily: 'roboto', fontWeight: "550", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.resourceType != null ? item?.resourceLabel : item?.standardNames}</div>
                          </Typography.Text>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style={{ height: "100px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ fontWeight: "550" }}>
                        ₹ {
                          item?.amountPayable &&
                          numberWithCommas(item?.amountPayable)
                        }

                      </div>
                      <div>
                        <img src={item?.resourceType && (item?.resourceLabel == 'Live Course' ? liveCoursesIcon : item?.resourceLabel == 'Video Course' ? videoResourceIcon : item?.resourceLabel == 'Study Materials' ? studyMaterialIcon : testSeriesIcon)} style={{ width: 20 }} />
                        <Typography.Text style={{ color: "#636363", width: "200px", textAlign: "center" }} ellipsis={{ tooltip: true }}>
                          {item?.resourceName || item?.note || 'NA'}
                        </Typography.Text>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        color: "#636363",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginLeft: "10px",
                        // backgroundColor: "red",
                        justifyContent: "center",
                      }}
                    >
                      <div>{item?.paymentDate}</div>
                      {/* <div>09:34 PM</div> */}

                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        // className="r-ac"
                        style={{
                          borderRadius: "7px",
                          textAlign: "center",
                          fontSize: "10px",
                          padding: "5px",
                          fontWeight: "700",
                          color: "white",
                          backgroundColor: `${item?.payment_type == "online"
                            ? "#28DF99"
                            : "#636363"
                            }`,
                          width: "100px",
                        }}
                      >
                        {item?.payment_type}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style={{ color: "#191919", textAlign: "center" }}>
                      {item?.paymentId}
                    </div>
                  </td>
                  <td>

                    <div style={{ color: "#191919", textAlign: "center" }}>
                      {item?.payment_method}
                    </div>

                  </td>
                  <td>

                    <Tooltip placement="topLeft" title={"Send Invoice"}>
                      <UploadOutlined style={{

                        color: "#1089FF", cursor: "pointer",
                        fontSize: "20px"

                      }}
                        onClick={(e) => {
                          e.stopPropagation()
                          postSendInvoice(item?.id)
                        }}
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))
              : null
            /* </tr> */
          }
        </table>
        {
          feeMangementData?.allActiviies?.items?.length == 0 ? (
            <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
              <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
              >
                <img src={illNoData} />
              </div>
              <div>
                <h4>
                  <b>No data!</b>
                </h4>
              </div>
              <div>
                <h5>There are no data to show yet. Please check back later</h5>
              </div>
            </div>
          ) : null
        }
      </div >
      <div style={{ position: "absolute", right: "20px", bottom: "30px" }}>
        {/* <Pagination defaultCurrent={1} total={50} /> */}
        <Pagination
          size="small"
          current={feeMangementData?.allActiviies?.meta?.currentPage}
          defaultCurrent={1}
          total={feeMangementData?.allActiviies?.meta?.totalPages * 10}
          onChange={handlePageination}
          showSizeChanger={false}
        />
      </div>
      <div
        style={{
          position: "absolute",
          // left: "250px",
          bottom: "30px",
          color: "#636363",
        }}
      >
        Showing{" "}
        {feeMangementData?.allActiviies?.meta?.itemsPerPage *
          (feeMangementData?.allActiviies?.meta?.currentPage - 1) +
          1}{" "}
        -{" "}
        {feeMangementData?.allActiviies?.meta?.itemsPerPage *
          feeMangementData?.allActiviies?.meta?.currentPage <
          feeMangementData?.allActiviies?.meta?.totalItems
          ? feeMangementData?.allActiviies?.meta?.itemsPerPage *
          feeMangementData?.allActiviies?.meta?.currentPage
          : feeMangementData?.allActiviies?.meta?.totalItems}{" "}
        of {feeMangementData?.allActiviies?.meta?.totalItems}
      </div>
      <PageDataLoader visible={pageLoader} />

      {
        showSelectRangeModal && (
          <SelectRangeModal
            isModalVisible={showSelectRangeModal}
            setShowSelectRangeModal={setShowSelectRangeModal}
            showReasonModal={showReasonModal}
            setShowReasonModal={setShowReasonModal}
            kycStatus={feeMangementData?.dashBoardDetails?.kycStatus}
          />
        )
      }
      {
        showReasonModal &&
        (<ShowReasonModal
          showReasonModal={showReasonModal}
          setShowReasonModal={setShowReasonModal}
          kycStatus={feeMangementData?.dashBoardDetails?.kycStatus}
          rejectKycMessage={feeMangementData?.dashBoardDetails?.rejectKycMessage ? feeMangementData?.dashBoardDetails?.rejectKycMessage : ''} />)
      }
      {
        showOfflinePayReqModal && (
          <OfflinePayReq
            isModalVisible={showOfflinePayReqModal}
            setIsModalVisible={setShowOfflinePayReqModal}
            setShowAddDetailsModal={setShowAddDetailsModal}
            setIsAddOrGenModal={setIsAddOrGenModal}
            setIsAdditionModal={setIsAdditionModal}
            showReasonModal={showReasonModal}
            setShowReasonModal={setShowReasonModal}
          />
        )
      }
      {
        showAddDetailsModal && (
          <AddDetailsModal
            isModalVisible={showAddDetailsModal}
            setIsAddOrGenModal={setIsAddOrGenModal}
            isAddOrGenModal={isAddOrGenModal}
            isAdditionModal={isAdditionModal}
            handleOk={() => {
              setShowAddDetailsModal(false);
              if (isAddOrGenModal == true) {
                setShowOfflinePayReqModal(true);
                setIsAddOrGenModal(false);
              } else {
                setShowGeneratePayReq(true);
                setIsAddOrGenModal(false);
              }
            }}
            handleCancel={() => {
              setShowAddDetailsModal(false);
              if (isAddOrGenModal == true) {
                setShowOfflinePayReqModal(true);
                setIsAddOrGenModal(false);
              } else {
                setShowGeneratePayReq(true);
                setIsAddOrGenModal(false);
              }
            }}
            setShowGeneratePayReq={setShowGeneratePayReq}
          />
        )
      }
    </div >
  );
}

const mapStateToProps = (state) => {
  const { feeMangementData, allActivitiesFilter } = state.feeManagement;
  const { classList } = state.userManagementV2;
  return {
    feeMangementData,
    allActivitiesFilter,
    classList
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  setAllActivitiesFilter: (key, res) =>
    dispatch(setAllActivitiesFilter(key, res)),
  setInvoiceFile: (file) => dispatch(setInvoiceFile(file)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  postSendInvoice: (id) => dispatch(postSendInvoice(id)),
  fetchStudentsClassList: (params, callback) => dispatch(fetchStudentsClassList(params, callback))

});

export default connect(mapStateToProps, mapDispatchToProps)(AllTransactions);