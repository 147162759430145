import { getAutocompleteSearchOffers, getCoursesCategories, getCoursesSubCategories, storeAutocompleteSearchData } from 'actions/courseManagement';
import { getOffers, getSingleOffer, postCreateOffers, setCreateOffersObj, setCreateOffersObjKey, storeSingleOffer } from 'actions/offers';
import { Button } from 'components/Button';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OffersConfirmationModal from '../modals/offersConfirmationModal';
import '../styles.css';
import CreateOfferApplicable from './createOfferApplicable';
import CreateOfferMandatoryInfo from './createOfferMandatoryInfo';
import CreateOfferPromo from './createOfferPromo';
import CreateOfferSettings from './createOfferSettings';
const inputStyle = {
    color: '#636363',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    backgroundColor: "#FFFFFF"
}
function CreateOffers({ setCreateOffersObjKey, setCreateOffersObj, offersObj, getCoursesCategories, getCoursesSubCategories, courseCategoryData, courseSubCategoriesData,
    postCreateOffers, getSingleOffer, selectedOfferId, getAutocompleteSearchOffers, autocompleteSearchData, storeAutocompleteSearchData, setMainSectionType,
    setOfferFilterParams, setOffersTypeIndex, setPreviousMainSectionType, previousMainSectionType, setIsOfferModalVisible, setModalActionType, setSelectedOffer,
    isOfferModalVisible, modalActionType, selectedOffer, setSelectedItems, cardClassName, hideHeader, courseDetails, setFormHasPromo, setFormHasOfferDate,
}) {
    const [pageLoader, setPageLoader] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [isDiscountDropDownOpen, setIsDiscountDropDownOpen] = useState(false);
    const [hasOfferDate, setHasOfferDate] = useState(false);
    const [hasPromo, setHasPromo] = useState(false);
    let disableSaveOffer = true;
    if (
        !offersObj?.title ||
        !offersObj?.discountType ||
        !offersObj?.discount > 0 ||
        (hasPromo && !offersObj?.code) ||
        !offersObj?.type ||
        (!offersObj?.privateOffer && offersObj?.type == "course" &&
            !offersObj?.applicableLive && !offersObj?.applicableVideo && !offersObj?.applicableStudy && !offersObj?.applicableTest) ||
        (!offersObj?.privateOffer && offersObj?.type == "category" && selectedCategories?.length == 0) ||
        (!offersObj?.privateOffer && offersObj?.type == "subcategory" && selectedSubcategories?.length == 0) ||
        (!offersObj?.privateOffer && offersObj?.type == "singleCourse" && selectedCourse?.length == 0) ||
        (offersObj?.privateOffer && offersObj?.maxUses <= 0) ||
        (!offersObj?.privateOffer && hasOfferDate && (!offersObj?.startDate || !offersObj?.endDate))
    ) {
        disableSaveOffer = true
    } else {
        disableSaveOffer = false
    }

    useEffect(() => {
        if (selectedOfferId) {
            setPageLoader(true);
            getSingleOffer(selectedOfferId, () => {
                setPageLoader(false)
            }, (item) => {
                if (item.startDate || item.endDate) {
                    if (!item.privateOffer) {
                        setHasOfferDate(true);
                        if (setFormHasOfferDate) {
                            setFormHasOfferDate(true);
                        }
                    }
                    item.startDate = moment(item.startDate, 'YYYY-MM-DD', true).isValid() ? item.startDate : null;
                    item.endDate = moment(item.endDate, 'YYYY-MM-DD', true).isValid() ? item.endDate : null;
                }
                if (item.type == 'course' && item.applicableLive && item.applicableStudy && item.applicableVideo && item.applicableTest) {
                    item.type = 'All';
                }
                if (item.type == 'liveCourse' || item.type == 'videoResource' || item.type == 'studyMaterial' || item.type == 'testSeries') {
                    item.type = 'singleCourse';
                }
                setCreateOffersObj(item);
                if (item?.type == 'category') {
                    const categories = item?.categories?.map((cat) => ({
                        id: cat.id,
                        label: cat.name,
                        value: cat.name,
                    })) || [];
                    setSelectedCategories(categories);
                    if (categories?.length) {
                        setCreateOffersObjKey('categoryIds', categories.map((item) => item.id));
                    }
                } else if (item?.type == 'subcategory') {
                    const subcategories = item?.subcategories?.map((cat) => ({
                        id: cat.id,
                        label: cat.name,
                        value: cat.name,
                    })) || [];
                    setSelectedSubcategories(subcategories)
                    if (subcategories?.length) {
                        setCreateOffersObjKey('subcategoryIds', subcategories.map((item) => item.id));
                    }
                } else if (item?.type == 'singleCourse') {
                    const course = item.course?.[0];
                    if (course) {
                        setSelectedCourse([{
                            id: -1,
                            itemId: course.itemId,
                            itemType: course.type,
                            name: course.name,
                        }]);
                        const { videoResourceId, courseId, studyMaterialId, testSeriesId, ...others } = item;
                        const itemKey = `${course.type || course.itemType}Id`;
                        setCreateOffersObj({ ...others, [itemKey]: (course.itemId) })
                    }
                }
                if (item.startDate || item.endDate) {
                    setHasOfferDate(true);
                    if (setFormHasOfferDate) {
                        setFormHasOfferDate(true);
                    }
                    setCreateOffersObjKey('startDate', item.startDate ? moment(item.startDate, 'YYYY-MM-DD').format('DD/MM/YY') : null);
                    setCreateOffersObjKey('endDate', item.endDate ? moment(item.endDate, 'YYYY-MM-DD').format('DD/MM/YY') : null);
                }
                if (item.code?.trim()) {
                    setHasPromo(true);
                if (setFormHasPromo) {
                        setFormHasPromo(true);
                    }
                } else {
                    setHasPromo(false);
                    if (setFormHasPromo) {
                        setFormHasPromo(false);
                    }
                }
            });
        } else {
            if (courseDetails) {
                setSelectedCourse([{
                    id: -1,
                    itemId: courseDetails.id,
                    itemType: courseDetails.type,
                    name: courseDetails.name,
                }]);
                setCreateOffersObj({
                    discountType: 'amount',
                    type: 'singleCourse',
                    [`${courseDetails?.type || courseDetails?.itemType}Id`]: courseDetails.id,
                });
            } else {
                setCreateOffersObj({ discountType: 'amount' });
            }
        }
    }, [selectedOfferId])
    const createSectionCardClassName = cardClassName || 'offer-create-card';
    return (
        <div style={{ height: "100%", width: '100%' }} onClick={(e) => { e.stopPropagation(); setIsDiscountDropDownOpen(false); }}>
            {pageLoader
                ? <div className='r-c-c' style={{ width: "100%", height: "100%" }}>
                    <img src={require('Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                </div>
                :
                <div className='flex-column' style={{ height: "100%" }}>
                    {!hideHeader ?
                        <div className='r-c-sb m-b-20' style={{ zIndex: 100, marginTop: -46 }}>
                            <div className='m-l-25' style={{ color: "#191919", fontSize: 18, fontWeight: 700 }}>{offersObj?.id ? 'Edit promotional offer' : 'Create promotional offer'}</div>
                            <div className='display-flex'>
                                <Button style={{ marginRight: 20, backgroundColor: "#E1E3EA", border: "1px solid #E1E3EA", color: "#636363" }} onClick={() => {
                                    setPreviousMainSectionType('createEdit');
                                    setMainSectionType(previousMainSectionType);
                                }}>CANCEL</Button>
                                <Button disabled={disableSaveOffer}
                                    loading={saveBtnLoader}
                                    type="primary"
                                    style={{ alignText: 'center' }}
                                    onClick={() => {
                                        setSaveBtnLoader(true)
                                        if (!offersObj?.id) {
                                            postCreateOffers(
                                                null,
                                                () => { setSaveBtnLoader(false) },
                                                (id) => {
                                                    if (offersObj?.banner || offersObj?.privateOffer) {
                                                        setPreviousMainSectionType('createEdit');
                                                        setOffersTypeIndex(1);
                                                        setMainSectionType(previousMainSectionType);
                                                        setOfferFilterParams({ available: true });
                                                    } else {
                                                        setIsOfferModalVisible(true);
                                                        setSelectedOffer({
                                                            id,
                                                            published: false,
                                                            privateOffer: false,
                                                            expired: false,
                                                            type: 'course',
                                                        })
                                                        setModalActionType('createPublish');
                                                    }
                                                }
                                            )
                                        } else {
                                            postCreateOffers(
                                                null,
                                                () => { setSaveBtnLoader(false) },
                                                () => {

                                                    setPreviousMainSectionType('createEdit');
                                                    setMainSectionType(previousMainSectionType);
                                                    setOfferFilterParams((params) => {
                                                        const { available, ...others } = params;
                                                        return available != null ? { available } : {};
                                                    });
                                                }
                                            )
                                        }
                                    }}>SAVE</Button>
                            </div>
                        </div>
                        : null}
                    <div className='scroll-bar-universal' style={{ height: "100%", width: "100%", overflowX: "hidden" }}>
                        <CreateOfferMandatoryInfo
                            offersObj={offersObj}
                            setOffersObjKey={setCreateOffersObjKey}
                            inputStyle={inputStyle}
                            cardClassName={createSectionCardClassName}
                            isDiscountDropDownOpen={isDiscountDropDownOpen}
                            setIsDiscountDropDownOpen={setIsDiscountDropDownOpen}
                        ></CreateOfferMandatoryInfo>
                        <CreateOfferPromo
                            offersObj={offersObj}
                            setOffersObjKey={setCreateOffersObjKey}
                            inputStyle={inputStyle}
                            cardClassName={createSectionCardClassName}
                            setOffersObj={setCreateOffersObj}
                            hasPromo={hasPromo}
                            setHasPromo={setHasPromo}
                            hidePrivate={!!courseDetails}
                            setFormHasPromo={setFormHasPromo}
                            hasOfferDate={hasOfferDate}
                            setHasOfferDate={setHasOfferDate}
                            setFormHasOfferDate={setFormHasOfferDate}
                        >
                        </CreateOfferPromo>
                        {!offersObj?.privateOffer
                            ? <div>
                                <CreateOfferApplicable
                                    offersObj={offersObj}
                                    setOffersObjKey={setCreateOffersObjKey}
                                    setOffersObj={setCreateOffersObj}
                                    inputStyle={inputStyle}
                                    cardClassName={createSectionCardClassName}
                                    storeAutocompleteSearchData={storeAutocompleteSearchData}
                                    getAutocompleteSearchOffers={getAutocompleteSearchOffers}
                                    getCoursesCategories={getCoursesCategories}
                                    getCoursesSubCategories={getCoursesSubCategories}
                                    courseCategoryData={courseCategoryData}
                                    courseSubCategoriesData={courseSubCategoriesData}
                                    autocompleteSearchData={autocompleteSearchData}
                                    selectedCategories={selectedCategories}
                                    setSelectedCategories={setSelectedCategories}
                                    selectedSubcategories={selectedSubcategories}
                                    setSelectedSubcategories={setSelectedSubcategories}
                                    selectedCourse={selectedCourse}
                                    setSelectedCourse={setSelectedCourse}
                                    courseDetails={courseDetails}
                                    hasOfferDate={hasOfferDate}
                                    setHasOfferDate={setHasOfferDate}
                                    setFormHasOfferDate={setFormHasOfferDate}
                                ></CreateOfferApplicable>
                                <CreateOfferSettings
                                    offersObj={offersObj}
                                    setOffersObjKey={setCreateOffersObjKey}
                                    inputStyle={inputStyle}
                                    cardClassName={createSectionCardClassName}
                                    hasPromo={hasPromo}
                                    shortenField={!!courseDetails}
                                >
                                </CreateOfferSettings>
                            </div>
                            : null
                        }
                    </div>
                </div>
            }
            {isOfferModalVisible &&
                <OffersConfirmationModal
                    isModalVisible={isOfferModalVisible}
                    setModalVisible={setIsOfferModalVisible}
                    togglePageLoader={setPageLoader}
                    actionType={modalActionType}
                    selectedOfferId={selectedOffer.id}
                    isPublished={false}
                    setMainSectionType={setMainSectionType}
                    previousMainSectionType={previousMainSectionType}
                    setOfferFilterParams={setOfferFilterParams}
                    setOffersTypeIndex={setOffersTypeIndex}
                    setSelectedItems={setSelectedItems}
                    setSelectedOffer={setSelectedOffer}
                />
            }

        </div >
    )
}
const mapStateToProps = (state) => {
    const { offersObj } = state.offers;
    const { courseCategoryData, courseSubCategoriesData, autocompleteSearchData } = state.courseManagement;
    return { offersObj, courseCategoryData, courseSubCategoriesData, autocompleteSearchData };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateOffersObjKey: (key, val) => dispatch(setCreateOffersObjKey(key, val)),
    setCreateOffersObj: (val) => dispatch(setCreateOffersObj(val)),
    getCoursesCategories: (callBack) => dispatch(getCoursesCategories(callBack)),
    getCoursesSubCategories: (id, callBack) => dispatch(getCoursesSubCategories(id, callBack)),
    postCreateOffers: (publish, callBack, successCallBack) => dispatch(postCreateOffers(publish, callBack, successCallBack)),
    getSingleOffer: (id, callBack, succesCallback) => dispatch(getSingleOffer(id, callBack, succesCallback)),
    getAutocompleteSearchOffers: (search, callBack, emptySearch = null) => dispatch(getAutocompleteSearchOffers(search, callBack, emptySearch)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    storeSingleOffer: (val) => dispatch(storeSingleOffer(val)),
    getOffers: (params, callback) => dispatch(getOffers(params, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateOffers);