import React from 'react'
import AdminEmptystate from '../pages/AdminEmptystate';

function DivisionCard() {
    
    return (
        <AdminEmptystate></AdminEmptystate>
    )
}

export default DivisionCard

