import { SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { getAutocompleteSearch, getCourseActivityData, getCourseDashboardData, getCourseGraphData, getCoursePieChartData, setCourseTypeName, setSearchResultPageParamsObj, storeAutocompleteSearchData, storeSingleCourseId } from 'actions/courseManagement'
import { setOthersSelectedTab } from 'actions/profileNew'
import { getMarketSettingsData } from 'actions/studentMarketPlace'
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2'
import { AutoComplete, Carousel, Checkbox, Dropdown, Menu, Typography } from 'antd'
import BarChartModal from 'components/barChartModal'
import BarPlotModal from 'components/barPlotModal'
import { Button } from 'components/Button'
import CustomDots from 'components/CustomDots'
import PageDataLoader from 'components/pageDataLoader'
import PageHeader from 'components/pageHeader'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api, Color } from 'services'
import liveCourseIcon from "../../../../Assets/courseManagementV4/liveCourseIcon.svg"
import filterIcon from "../../../../Assets/courseManagementV4/new-filter-icon.png"
import studentIcon from "../../../../Assets/courseManagementV4/studentIcon.svg"
import LiveCourseIcon from "../../../../Assets/courseManagementV4/studentModalLiveCourse.svg"
import StudyMaterialIcon from "../../../../Assets/courseManagementV4/studentModalStudy.svg"
import TestSeriesIcon from "../../../../Assets/courseManagementV4/studentModalTestSeries.svg"
import VideoCourseIcon from "../../../../Assets/courseManagementV4/studentModalVideoCourse.svg"
import studyMaterialIcon from "../../../../Assets/courseManagementV4/studyMaterialIcon.svg"
import testSeriesIcon from "../../../../Assets/courseManagementV4/testSeriesIcon.svg"
import videoResourceIcon from "../../../../Assets/courseManagementV4/videoResourceIcon.svg"
import { Information, SmallInfo, Title, Title2 } from 'components/Typography'
import CourseTypesCustomizeModal from 'pages/courseManagement/modals/courseTypesCustomizeModal'
import SingleOverviewCard from 'pages/courseManagement/components/homePage/singleOverviewCard'
import RecentlyAddedCard from 'pages/courseManagement/components/homePage/recentlyAddedCard'
import RecentAddedCourseModal from 'pages/courseManagement/modals/recentAddedCourseModal'
import RecentAddedStudentsModal from 'pages/courseManagement/modals/recentAddedStudentsModal'
import BatchesCard from 'pages/courseManagement/components/homePage/batchesCard'
import PromotionalOffersCard from 'pages/courseManagement/components/homePage/promotionalOffersCard'
import CourseBarGraph from 'pages/courseManagement/components/homePage/courseBarGraph'
import "./styles.css"
import CourseOverviewCard from 'pages/courseManagement/components/homePage/courseOverviewCard'
import CoursePieGraph from 'pages/courseManagement/components/homePage/coursePieGraph'
import { getCourseData, getStudentData } from 'pages/courseManagement/components/homePage/chartData'
import { setOffersTypeIndex } from 'actions/offers'
import RecentlyAddedHeader from './recentlyAddedHeader'

export const Legend = ({ color, text, count }) => {
    return (
        <div className='r-ac'>
            <div className='graph-legend-style' style={{ backgroundColor: color, }}></div>
            <SmallInfo color={Color.darkGrey} className='m-l-8'>{text} {count== 0 ? "(0)" : count ? `(${count})` : null}</SmallInfo>
        </div>
    )
}

function CourseDashboard({ getCourseDashboardData, courseDashboardData, getCourseActivityData, courseActivityData, autocompleteSearchData, setCourseTypeName, getAutocompleteSearch, setSearchResultPageParamsObj,
    storeAutocompleteSearchData, storeSingleCourseId, getMarketSettingsData, getCourseGraphData, getCoursePieChartData, storeGraphData, storePieChartData, setUserDetails, setSpecificUserId, setOthersSelectedTab,
    user, setOffersTypeIndex }) {
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(["All"]);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [isCourseTypeToggleModalVisible, setIsCourseTypeToggleModalVisible] = useState(false);
    const [isCourseModalVisible, setIsCourseModalVisible] = useState(false);
    const [isStudentModalVisible, setIsStudentModalVisible] = useState(false)
    const [singleStudentData, setSingleStudentData] = useState(null)
    const [singleCourseData, setSingleCourseData] = useState(null)
    const [isChecked, setIsChecked] = useState(true);
    const [courseType, setCourseType] = useState('liveCourse')
    const [isMonth, setIsMonth] = useState(moment().format('MMM'))
    const [isYear, setIsYear] = useState(moment().format('YYYY'))
    const [graphData, setGraphData] = useState([]);
    const colors = ['#B59009', '#DAAB00', '#E9CC64', '#F9E7A3'];
    const stdColors = ['#594099', '#967DD5', '#C3B1EC', '#DACFF2']
    const [pieChartModal, setPieChartModal] = useState(false)
    const [barChartModal, setBarChartModal] = useState(false)
    const [currentPie, setCurrentPie] = useState(1);
    const [isGraphFilterClicked, setIsGraphFilterClicked] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const history = useHistory();
    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');
    const [currentPageCarousel, setCurrentPage] = useState(0);
    const carouselRef = useRef(null);
    const courseData = getCourseData(storePieChartData);
    const studentData = getStudentData(storePieChartData);
    const [requestsLoader1, toggleReqsLoader1] = useState(false);

    useEffect(() => {
        if (page == 1 && pageLoader) {
            setisLoading(true)
        }
        const params = {
            type: selectedOptions?.includes("All") ? null : selectedOptions[0],
            limit: 10,
            page
        }
        toggleReqsLoader1(true)
        getCourseActivityData(params, (page == 1 ? true : false), () => { setisLoading(false); toggleReqsLoader1(false) });
    }, [page]);

    const observer = useRef()
    const lastElementRef = useCallback(node => {
        if (isLoading || !node) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && courseActivityData?.meta?.currentPage < courseActivityData?.meta?.totalPages) {
                setPage(prevPage => prevPage + 1);
            }
        });
        observer.current.observe(node);
    }, [isLoading, courseActivityData]);

    useEffect(() => {
        storeAutocompleteSearchData([])
        setSearchResultPageParamsObj("search", null)
        togglePageLoader(true);
        getCourseDashboardData(() => { togglePageLoader(false) })
        getMarketSettingsData(true, () => { })
    }, [])

    const handleAutocompleteSearch = (value) => {
        setSearchResultPageParamsObj("search", value)
        setSearchLoader(true);
        getAutocompleteSearch(value, () => { setSearchLoader(false) })
    }
    const renderTitle = (title) => (
        <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
    );
    const searchOptions = [
        {
            label: autocompleteSearchData?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
            options: autocompleteSearchData?.suggestions?.length ? autocompleteSearchData?.suggestions?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => { setCourseTypeName("searchResult"); history.push({ pathname: "/search-result", state: { key: { fromDashboardSearch: true, dashboardSearchedValue: item } } }); }}>
                        <SearchOutlined /><div style={{ color: '#191919', fontWeight: 600, marginLeft: 10 }}>{item}</div>
                    </div>
                ),
            })) : []
        },
        {
            label: autocompleteSearchData?.resources?.length != 0 ? renderTitle("Courses") : null,
            options: autocompleteSearchData?.resources?.length ? autocompleteSearchData?.resources?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => { setCourseTypeName(item?.type == "course" ? "liveCourses" : item?.type == "videoResource" ? "videoResources" : item?.type == "studyMaterial" ? "studyMaterials" : item?.type == "testSeries" ? "onlineTestSeries" : null); storeSingleCourseId(item?.id); history.push({ pathname: "/course-details", state: { key: { fromDashboardSearch: true } } }) }}>
                        { <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 70, height: 40 }} />}
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 270 }}>{item?.name}</Typography.Text>
                    </div>
                ),
            })) : []
        },
    ];

    const actions = [
        <AutoComplete
            popupMatchSelectWidth={500}
            style={{ marginRight: 20, width: 310 }}
            options={(autocompleteSearchData?.suggestions?.length > 0 || autocompleteSearchData?.resources?.length > 0) ? searchOptions : []}
            onSearch={handleAutocompleteSearch}
            placeholder={<span style={{ color: "#999999" }}><SearchOutlined style={{ color: "#C8C8C8" }} /> Search courses</span>}
            loading={searchLoader}
            allowClear
            className='round-bordered-search-course'
        ></AutoComplete>,
        hasMarketingPrivilege ? <></> : <Button onClick={() => { setIsCourseTypeToggleModalVisible(true) }} type="secondary" icon={<SettingOutlined />}>CUSTOMIZE</Button>
    ]

    const imageSources = {
        liveCourse: liveCourseIcon,
        videoResource: videoResourceIcon,
        studyMaterial: studyMaterialIcon,
        testSeries: testSeriesIcon
    }

    const recentTitle = {
        liveCourse: "Live course",
        videoResource: "Video course",
        studyMaterial: "Study material",
        testSeries: "Test series"
    }

    const filterArray = [
        { label: "All", value: "All" },
        { label: "Courses", value: "course" },
        { label: "Students", value: "student" },
    ]

    const handleOptionSelect = (option) => {
        setPage(1);
        setIsScrolled(true)
        let updatedOptions = [];
        if (selectedOptions?.includes(option)) {
            updatedOptions = selectedOptions?.filter(item => item !== option);
        } else {
            updatedOptions = [option];
        }
        if (updatedOptions.length === 0) {
            updatedOptions = ["All"];
        }
        setSelectedOptions(updatedOptions);
        setisLoading(true);
        const type = updatedOptions?.includes("All") ? null : updatedOptions[0];
        const params = {
            type,
            limit: 10,
            page: 1
        };
        toggleReqsLoader1(true)
        getCourseActivityData(params, (page == 1 ? true : false), () => {setisLoading(false); toggleReqsLoader1(false)});
    }

    const menu = (
        <Menu className='different-bgc-dropdown recently-added-dropdown'>
            <div className='r-c-sb' style={{cursor:'pointer'}}>
                <Information color={Color.black}>{selectedOptions == "course" ? "Courses" : selectedOptions == "student" ? "Students" : "All"}</Information>
                <img className='filter-icon-size' src={filterIcon} onClick={() => { setIsFilterClicked(!isFilterClicked) }} />
            </div>
            <div className='r-c-c-c m-t-5 m-b-5' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            {filterArray.map(option => (
                <Menu.Item key={option.value}
                    style={{ cursor: "default", borderRadius: 4, fontWeight: selectedOptions?.includes(option?.value) ? 600 : "normal", color: selectedOptions?.includes(option?.value) ? "#191919" : "#AEAEAE" }}
                    onClick={() => handleOptionSelect(option.value)}
                >
                    <Checkbox
                        checked={selectedOptions?.includes(option.value)}
                        onChange={() => handleOptionSelect(option.value)}
                        style={{ cursor: "pointer" }}
                    >
                        {option.label}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    )

    useEffect(() => {
        getCourseGraphData(courseType, isMonth, isYear, isChecked, () => { })
    }, [courseType, isMonth, isYear, isChecked])

    useEffect(() => {
        if (!storeGraphData || !storeGraphData.courseDetails) return;
        const temp = [];
        for (let i = 0; i < storeGraphData.courseDetails.length; i++) {
            let obj = {}
            obj['value'] = storeGraphData.courseDetails[i].amount,
                obj['label'] = storeGraphData.courseDetails[i].name
            obj['type'] = 'Revenue ',
                obj['typeRev'] = storeGraphData.courseDetails[i].indexLevel
            temp.push(obj);
            // let obj1 = {}
            // obj1['value'] = storeGraphData.courseDetails[i].popularIndex,
            //     obj1['label'] = storeGraphData.courseDetails[i].name.slice(0, 10)
            // obj1['type'] = 'Popularity',
            //     obj1['typeRev'] = storeGraphData.courseDetails[i].indexLevel
            // temp.push(obj1);
        }
        setGraphData(temp);
    }, [storeGraphData]);

    useEffect(() => {
        getCoursePieChartData(() => { })
    }, [])

    const assignClassOnclick = () => {
        const newItem = {
            ...singleStudentData,
            id: singleStudentData?.userId,
            name: singleStudentData?.userName,
            role: [singleStudentData?.role]
        };
        delete newItem.userId;
        delete newItem.userName;
        setUserDetails(newItem)
        setSpecificUserId(newItem?.id);
        setOthersSelectedTab('Classroom & course(s)');
        history.push({
            pathname: '/user-management/profile',
            state: { key: true }
        })
    }

    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                const scrollTop = div.scrollTop;
                const scrollHeight = div.scrollHeight;
                const clientHeight = div.clientHeight;
                if (scrollTop > 0 && scrollHeight > clientHeight) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            }
        };
        const div = document.getElementById('scrollableDiv');
        if (div) {
            div.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (div) {
                div.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const div = document.getElementById('scrollableDiv');
        if (isScrolled) {
            setTimeout(() => {
                div.scrollTop = 1
            }, 900)
        }
    }, [isScrolled]);

    const handleBeforeChange = (current, next) => {
        setCurrentPage(next);
    };

    const handleDotClick = (index) => {
        setCurrentPage(index);
        if (carouselRef.current) {
            carouselRef.current.goTo(index);
        }
    };

    useEffect(() => {
        if (currentPageCarousel === 1) {
            setIsGraphFilterClicked(false);
        }
    }, [currentPageCarousel, setIsGraphFilterClicked]);

    const pageHeader = document.getElementById("pageHeader");
    const pageHeaderHeight = pageHeader?.clientHeight;
    const overviewDiv = document.getElementById("overviewDiv");
    const overviewDivHeight = overviewDiv?.clientHeight;
    const batchesPromoDiv = document.getElementById("batchesPromoDiv");
    const batchesPromoDivHeight = batchesPromoDiv?.clientHeight;

    // const RecentlyAddedHeader = () => {
    //     return (
    //         <div className='r-jsb' >
    //             <Title>Recently added</Title>   
    //             <Dropdown  overlay={isFilterClicked ? menu : <></>} trigger={['click']} open={isFilterClicked} style={{cursor:'pointer'}}>
    //                 <div className='course-dropdownStyle r-c-sb' style={{cursor:'pointer'}} onClick={(e) => { e.stopPropagation(); setIsFilterClicked(!isFilterClicked) }}>
    //                     <Information color={Color.black}>{selectedOptions == "course" ? "Courses" : selectedOptions == "student" ? "Students" : "All"}</Information>
    //                     <img className='filter-icon-size' src={filterIcon} />
    //                 </div>
    //             </Dropdown>
    //         </div>
    //     )
    // }

    return (
        <div className='course-main-body' onClick={(e) => { e.stopPropagation(); setIsFilterClicked(false); setIsGraphFilterClicked(false) }}>
            <div id="pageHeader">
                <PageHeader title='Courses' actions={actions} path="courseHome" />
            </div>
            <div style={{ height: `calc(100% - ${pageHeaderHeight}px)` }}>
                <div className='r-jsb parent-section'>
                    <div className='child1-section'>
                        <div id="overviewDiv" style={{ opacity: isScrolled ? 0 : 1, transition: 'opacity 2s ease', transform: isScrolled ? 'translateY(-150%)' : 'translateY(0)' }}>
                            {courseDashboardData?.overview?.length == 1 ?
                                <>
                                    {courseDashboardData?.overview?.map((item, index) => (
                                        <div className='m-b-20'>
                                            <SingleOverviewCard count={item?.count} subTitle={item?.subtitle} imageSources={imageSources[item?.overviewType]} list={item?.list} studentCount={item?.studentCount} item={item} setCourseTypeName={setCourseTypeName} />
                                        </div>
                                    ))}
                                </>
                                :
                                <div style={{ opacity: isScrolled ? 0 : 1, transition: "opacity 2s ease" }}>
                                    <div className='r-jsb flex-wrap' style={{ width: "100%" }}>
                                        {courseDashboardData?.overview?.map((item, index) => (
                                            <div style={{ marginBottom: 20, width: courseDashboardData?.overview?.length == 3 && index == 2 ? "105%" : "50%", marginLeft: -9 }}>
                                                <CourseOverviewCard count={item?.count} subTitle={item?.subtitle == "Online Test Series" ? "test series" : item?.subtitle} imageSources={imageSources[item?.overviewType]} setCourseTypeName={setCourseTypeName} history={history} item={item} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                        </div>
                        <div style={{ height: isScrolled ? "100%" : `calc(97% - ${overviewDivHeight}px)`, width: "100%", marginTop: courseDashboardData?.overview?.length == 1 && isScrolled ? -168 : courseDashboardData?.overview?.length == 2 && isScrolled ? -115 : courseDashboardData?.overview?.length > 2 && isScrolled ? -229 : 0, transition: 'margin-top 1s ease' }}>
                        <RecentlyAddedHeader
                        isFilterClicked={isFilterClicked}
                        setIsFilterClicked={setIsFilterClicked}
                        menu={menu}
                        selectedOptions={selectedOptions}
                        
                        />
                         
                            <div id="scrollableDiv" className={`scroll-bar-universal ${isLoading ? "r-c-c-c" : ""}`} style={{ height: isScrolled ? "93%" : courseDashboardData?.overview?.length == 1 && !isScrolled ? "90.5%" : courseDashboardData?.overview?.length == 2 && !isScrolled ? "94.5%" : "93.5%", marginTop: 20 }}>
                                {isLoading ?
                                    <div>
                                        <img src={require('../../../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                                    </div>
                                    :
                                    <>
                                        {courseActivityData?.items?.map((item, index) => (
                                            <>
                                                {item?.activityType == "student" ?
                                                    <div ref={(index === courseActivityData?.items?.length - 1) ? lastElementRef : null}>
                                                        <RecentlyAddedCard title={"Student enrolled or student registered"} icon={studentIcon} item={item} isLast={index === courseActivityData.items.length - 1} isStudent={true} setSingleStudentData={setSingleStudentData} setIsStudentModalVisible={setIsStudentModalVisible} setSingleCourseData={setSingleCourseData} setIsCourseModalVisible={setIsCourseModalVisible} />
                                                    </div>
                                                    :
                                                    <>
                                                        {item?.activityType == "course" ?
                                                            <div ref={(index === courseActivityData?.items?.length - 1) ? lastElementRef : null}>
                                                                <RecentlyAddedCard title={recentTitle[item?.courseType] + " " + "added"} icon={imageSources[item?.courseType]} item={item} isLast={index === courseActivityData.items.length - 1} setSingleStudentData={setSingleStudentData} setIsStudentModalVisible={setIsStudentModalVisible} setSingleCourseData={setSingleCourseData} setIsCourseModalVisible={setIsCourseModalVisible} />
                                                            </div>
                                                            :
                                                            <></>}
                                                    </>
                                                }
                                            </>
                                        ))}

                                        {requestsLoader1 && (
                                            <div className='r-c-c-c' style={{ height: 200 }}>
                                                <img
                                                    src={require('../../../../Assets/gifs/section-loader.gif').default}
                                                    style={{ width: 200 }}
                                                />
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='child2-section'>
                        <div id="batchesPromoDiv">
                            <BatchesCard batchDetails={courseDashboardData?.batchDetails} hasMarketingPrivilege={hasMarketingPrivilege} />
                            <div className='m-t-20'>
                                <PromotionalOffersCard count={courseDashboardData?.offers?.items?.length} history={history} hasMarketingPrivilege={hasMarketingPrivilege} setOffersTypeIndex={setOffersTypeIndex}/>
                            </div>
                        </div>
                        <div className='live-course-graph-card m-t-20' style={{ height: `calc(97.1% - ${batchesPromoDivHeight}px)` }}>
                            {courseDashboardData?.overview?.length == 1 ?
                                <CourseBarGraph heading={"Live course"} value={"liveCourseGraph"} isGraphFilterClicked={isGraphFilterClicked} setIsGraphFilterClicked={setIsGraphFilterClicked} isMonth={isMonth} setIsMonth={setIsMonth} isYear={isYear} setIsYear={setIsYear} isChecked={isChecked} setIsChecked={setIsChecked} graphData={graphData} storeGraphData={storeGraphData} setBarChartModal={setBarChartModal} setCurrentPie={setCurrentPie} setCourseType={setCourseType} courseType={courseType} courseDashboardData={courseDashboardData} />
                                :
                                <Carousel
                                    ref={carouselRef}
                                    autoPlaySpeed={7000}
                                    dots={false}
                                    autoplay={true}
                                    beforeChange={handleBeforeChange}>
                                    <CoursePieGraph storePieChartData={storePieChartData} setPieChartModal={setPieChartModal} setCurrentPie={setCurrentPie} colors={colors} courseData={courseData} stdColors={stdColors} studentData={studentData} />
                                    <CourseBarGraph heading={courseType == "liveCourse" ? "Live courses" : courseType == "videoResource" ? "Video courses" : courseType == "studyMaterial" ? "Study materials" : courseType == "testSeries" ? "Test series" : null} isGraphFilterClicked={isGraphFilterClicked} setIsGraphFilterClicked={setIsGraphFilterClicked} isMonth={isMonth} setIsMonth={setIsMonth} isYear={isYear} setIsYear={setIsYear} isChecked={isChecked} setIsChecked={setIsChecked} graphData={graphData} storeGraphData={storeGraphData} setBarChartModal={setBarChartModal} setCurrentPie={setCurrentPie} setCourseType={setCourseType} courseType={courseType} courseDashboardData={courseDashboardData}/>
                                </Carousel>
                            }
                        </div>
                        {courseDashboardData?.overview?.length > 1 && <CustomDots currentPage={currentPageCarousel} totalSlides={2} onDotClick={handleDotClick} />}
                    </div>
                </div>
            </div>
            {isCourseTypeToggleModalVisible &&
                <CourseTypesCustomizeModal
                    isCourseTypeToggleModalVisible={isCourseTypeToggleModalVisible}
                    setIsCourseTypeToggleModalVisible={setIsCourseTypeToggleModalVisible}
                    getCourseDashboardData={getCourseDashboardData}
                    togglePageLoader={togglePageLoader}
                    getCoursePieChartData={getCoursePieChartData}
                    setIsScrolled={setIsScrolled}
                    setCourseType={setCourseType}
                />}
            {isCourseModalVisible &&
                <RecentAddedCourseModal
                    isCourseModalVisible={isCourseModalVisible}
                    setIsCourseModalVisible={setIsCourseModalVisible}
                    courseType={singleCourseData?.courseType == "liveCourse" ? "Live course" : singleCourseData?.courseType == "videoResource" ? "Video course" : singleCourseData?.courseType == "studyMaterial" ? "Study material" : singleCourseData?.courseType == "testSeries" ? "test series" : null}
                    courseName={singleCourseData?.courseName}
                    fee={(singleCourseData?.amount - singleCourseData?.discountAmount)?.toFixed(2)}
                    installments={"0"}
                    createdBy={singleCourseData?.createdByName}
                    createdAt={moment(singleCourseData?.createdAt).format("DD/MM/YYYY")}
                />}
            {isStudentModalVisible &&
                <RecentAddedStudentsModal
                    isStudentModalVisible={isStudentModalVisible}
                    setIsStudentModalVisible={setIsStudentModalVisible}
                    name={singleStudentData?.userName}
                    image={singleStudentData?.image}
                    role={singleStudentData?.role}
                    number={singleStudentData?.phone}
                    email={singleStudentData?.email}
                    courseType={singleStudentData?.courseType}
                    icon={singleStudentData?.courseType == "liveCourse" ? LiveCourseIcon : singleStudentData?.courseType == "videoResource" ? VideoCourseIcon : singleStudentData?.courseType == "studyMaterial" ? StudyMaterialIcon : singleStudentData?.courseType == "testSeries" ? TestSeriesIcon : null}
                    createdAt={moment(singleStudentData?.createdAt).format("DD/MM/YYYY")}
                    courseName={singleStudentData?.courseName}
                    showAssignClass={parseInt(singleStudentData?.standardCount) == 0 && singleStudentData?.courseType == "liveCourse"}
                    assignClassOnclick={assignClassOnclick}
                    showAssignGeofence={!singleStudentData?.geoFenceId && !singleStudentData?.geoFenceTitle}
                    togglePageLoader={togglePageLoader}
                    getCourseActivityData={getCourseActivityData}
                    userId={singleStudentData?.userId}
                    hasMarketingPrivilege={hasMarketingPrivilege}
                />}
            <BarPlotModal setPieChartModal={setPieChartModal} pieChartModal={pieChartModal} colors={colors} courseData={courseData} stdColors={stdColors} studentData={studentData} graphData={graphData} courseType={courseType} isChecked={isChecked} setIsMonth={setIsMonth} setIsChecked={setIsChecked} setIsYear={setIsYear} isMonth={isMonth} isYear={isYear} setCourseType={setCourseType} data={storePieChartData?.graph?.pieChart} setCurrentPie={setCurrentPie} currentPie={currentPie} />
            <BarChartModal setPieChartModal={setBarChartModal} pieChartModal={barChartModal} colors={colors} courseData={courseData} stdColors={stdColors} studentData={studentData} graphData={graphData} courseType={courseType} isChecked={isChecked} setIsMonth={setIsMonth} setIsChecked={setIsChecked} setIsYear={setIsYear} isMonth={isMonth} isYear={isYear} setCourseType={setCourseType} data={storePieChartData?.graph?.pieChart} setCurrentPie={setCurrentPie} currentPie={currentPie} courseTypeLength={courseDashboardData?.overview?.length} courseDashboardData={courseDashboardData} />
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { courseDashboardData, courseActivityData, autocompleteSearchData, storeGraphData, storePieChartData } = state.courseManagement;
    const { user } = state.session;
    return { courseDashboardData, courseActivityData, autocompleteSearchData, storeGraphData, storePieChartData, user };
};

const mapDispatchToProps = (dispatch) => ({
    getCourseDashboardData: (callBack) => dispatch(getCourseDashboardData(callBack)),
    getCourseActivityData: (params, loadmore, callBack) => dispatch(getCourseActivityData(params, loadmore, callBack)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    getAutocompleteSearch: (search, callBack) => dispatch(getAutocompleteSearch(search, callBack)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    getMarketSettingsData: (all, callBack) => dispatch(getMarketSettingsData(all, callBack)),
    getCourseGraphData: (courseType, isMonth, isYear, isChecked, callBack) => dispatch(getCourseGraphData(courseType, isMonth, isYear, isChecked, callBack)),
    getCoursePieChartData: (callBack) => dispatch(getCoursePieChartData(callBack)),
    setUserDetails: (res) => dispatch(setUserDetails(res)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    setOffersTypeIndex: (val) => dispatch(setOffersTypeIndex(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseDashboard);