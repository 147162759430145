import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  LiveSchedule,
  MobileWebAssignment,
  ReviewQuestions,
  StartAssignment,
  Profile,
  Calendar,
  Announcement,
  LiveClass,
  Login,
  User,
  ClassRoom,
  BatchDetails,
  ClassRoomAllStudents,
  ClassRoomEditChapters,
  BrowseResources,
  ResetPassword,
  FourOFourPage,
  ForgotPassword,
  Curriculum,
  ChatScreen,
  ChatWithAulas,
  Care,
  CareFaqs,
  CovidResources,
  StudentCare,
  StudentChat,
  SupportAdmin,
  ConversationPage,
  CareAdminChatPage,
  Notification,
  StudentRedirect,
  Attendance,
  ClassResource,
  AttendanceByStanard,
  Assignment,
  LoggerView,
  AssignmentSubmissionDetails,
  ResetSuccess,
  SearchAssignment,
  WebQuestions,
  FilterPage,
  AssignmentEditor,
  AttendanceByStudent,
  AttendanceByTeacher,
  AttendanceBySection,
  MobileTest,
  MobileTestSubjects,
  MobileTestStart,
  MobileTestQuestions,
  MobileTestResults,
  MobileTestReviewQuestions,
  MobileTestReviewAnswers,
  Test,
  TestLoggerView,
  TestSubmissionDetails,
  StudentTestDashboard,
  DigitalLibrary,
  StudentFees,
  AccessManagement,
  Transactions,
  AllFeeStudents,
  UserRequests,
  KycForm,
  KycSuccess,
  KycReject,
  TestBySubject,
  TestResult,
  TestScreen,
  ResultsSummary,
  TestSolutions,
  TestList,
  TestGenerateQuestions,
  SubjectPage,
  QuesGenerateListView,
  EvaluationQuestionsListView,
  ResultScreen,
  PublishResultScreen,
  StdToSubjectsScreen,
  SubmittedDetailScreen,
  StudSubmittedQueScreen,
  AdminEmptystate,
  AdminDashBoard,
  ClassDetailsEmpty,
  SubjectPageEmpty,
  SubjectDetails,
  Viewpdf,
  ResultList,
  TestQuesNonEditablScreen,
  CareInfoForm,
  CareFormSubmitSuccess,
  TeacherDashBoard,
  Broadcast,
  Conference,
  AdminProfile,
  ScheduleAdmin,
  Schedule,
  Zoom,
  UsersDashBoard,
  AllUsersRequest,
  StudentDashBoard,
  DashboardByRole,
  AllStudents,
  UserDetailsForm,
  StudentsForm,
  approvalForm,
  PlayRecordings,
  EditUserProfile,
  CourseDetailsPage,
  ChapterWiseViewPage,
  TopicWisePage,
  ContentPage,
  FeeManagement,
  AllTransactions,
  AllActivities,
  AllStudentsFile,
  ClassFeeDashBoard,
  AllActivitiesForClass,
  StudentsProfile,
  ViewInvoice,
  BuyStoragePage,
  AllSettlements,
  ImportClasses,
  InsideChapter,
  SyllabusSubjects,
  InsideChapterTestMod,
  SampleQuesPapers,
  SubQuestionImportPage,
  ImportSubjectwiseChapters,
  SampleQuestionImportPage,
  importTestSubjects,
  SampleGroupImport,
  PractiseTest,
  PractiseTestGenerateQuestions,
  PracticeSubjectPage,
  PracticeChapterPage,
  PracticeTabPage,
  Homepage,
  PractiseSampleQuesPaper,
  PractiseSampleGroupImport,
  ImportPracClasses,
  ImportPracSubwise,
  ImportPracSubChapters,
  PracReadyToPublish,
  PracSyllabusPage,
  PracSubjectPage,
  PracChapterPage,
  TestTabsPage,
  PracSubQuesImportPage,
  HomeNotification,
  // AttendanceNew,
  Leaves,
  AttendanceRequirement,
  Holiday,
  StudentAttendanceGraph,
  TeachersDashBoard,
  TeachersGeoFence,
  LeavesDashBoard,
  AttendanceDashBoard,
  ClassPage,
  StudentDetail,
  LeaveGraph,
  StudentProfileDetail,
  AllOperations,
  AttendanceTabDetails,
  // AttendanceDetails,
  RequestViewAll,
  MyAttendancePopulated,
  AttendanceStudent,
  AttendanceStudentGraph,
  // OperationDashboard,
  AttendanceView,
  MyAttendance,
  PractiseViewAll,
  PractiseQuesGenerateListView,
  practiceResultScreen,
  practiceTestScreen,
  PractiseTestSolution,
  ChaptertTestMod,
  PracticeTestQuesNonEditablScreen,
  AttendanceUser,
  AllTeachersViewAll,
  AttendanceHome,
  OverView,
  MyAttendanceStudent,
  StudentAnalyticGraph,
  ScheduleAttendance,
  AvailableSubscriptionPackages,
  MarkingSchemeDashboard,
  CreateTest,
  EditTest,
  AccountDetails,
  UserProfile,
  InstituteProfile,
  ViewKYC,
  GeneralSettings,
  SupportSettings,
  Subscriptions,
  AboutAulas,
  PrivacyPolicy,
  Tnc,
  ViewInstituteProfile,
  SampleQuestionPaper,
  SampleImportListView,
  Others,
  AllAnnouncementData,
  BroadCastRecordings,
  BroadCastRecordingsCards,
  LoginNew,
  DashBoardStudent,
  CourseDetails,
  AulasWalletDashBoard,
  CompleteStudentKyc,
  PaymentHistory,
  PaymentRequestsPage,
  KycDetails,
  PaymentSuccess,
  SingleSectionDetails,
  UsersDashboard,
  UserTable,
  RegistrationRequestsVA,
  DeletedUsers,
  TransferData,
  UserBasicProfile,
  CourseInformationPage,
  AdminMarketPlaceWithData,
  AttendanceStudents,
  Requests,
  staffLeaveVA,
  AddOnlineTestSeries,
  MarketPlaceCRM,
  StudentCoursesWClasses,
  CourseStandards,
  UnAssignedStudents,
  CoursesWOClasses,
  PdfCourseView,
  UserMarketPlaceHome,
  UserCourseDetailsPage,
  MobileSearchPage,
  InsideLiveCoursesStudents,
  AllStudentsUmV2,
  AllStudentsByCourses,
  AllStudentsCoursesVA,
  CourseDetailsFM,
  DashAttendance,
  StudentsViewAllPage,
  CourseDashboard,
} from "pages";
import PrivateRoute from "./PrivateRoute";
import ProfileViewPdf from "pages/profile/viewPdf";
import CourseMain from "pages/courseModule";
// import chapterTestNew from "pages/Test/chapterTestNew";
// import ChaptertTestMod from "pages/studentTest/chapterTestMod";
import attendanceGeoFence from "pages/attendanceNew/attendanceGeoFence";
import LeaveOverview from "pages/attendanceNew/leaveOverview";
import { loadFromLocalStorage } from "store";
import courseDetails from "pages/courseModule/courseDetails";
import paymentHistory from "pages/studentAulasWallet/paymentHistory";
import paymentRequestsPage from "pages/studentAulasWallet/paymentRequestsPage";
import kycDetails from "pages/studentAulasWallet/kycDetails";
import AddCoursePage from "pages/adminMarketPlaceCourse/addCoursePage";
import AddQuiz from "pages/adminMarketPlaceCourse/quiz";
import AulasMarketHome from "pages/aulasMarket/homePage";
import PopularCourses from "pages/aulasMarket/popularCourses";
import SearchError from "pages/aulasMarket/searchError";
import CourseProfile from "pages/aulasMarket/courseProfile";
import ShoppingCart from "pages/aulasMarket/shoppingCart";
import SavedLater from "pages/aulasMarket/savedLater"
import TestSeriesProfile from "pages/aulasMarket/testSeriesProfile";
import StudyMaterial from "pages/aulasMarket/studyMaterial";
import CourseList from "pages/aulasMarket/courseList";
import MyAccount from "pages/aulasMarket/myAccount";
import MyCourses from "pages/aulasMarket/myCourses";
import Checkout from "pages/aulasMarket/checkOut";
import Locations from "pages/aulasMarket/locations";
import OngoingTest from "pages/marketTest/ongoingTest";

import PromotionalOffersPage from 'pages/offers';
import createOffers from "pages/offers/components/createOffers";
import SolutionScreen from "pages/marketTest/solutionScreen";
import TestSeriesResult from "pages/marketTest/testSeriesResult";
import CreateIndividualTest from "pages/marketTest/createIndividualTest";
import AddQuestions from "pages/marketTest/addQuestions";
import AddCourseContent from "pages/adminMarketPlaceCourse/addCourseContent";
import CourseHome from "pages/studentMarketPlace/myCourses/courseHome";
import VideoResource from "pages/studentMarketPlace/myCourses/videoResource";

import QuestionCreation from "pages/adminMarketPlaceCourse/questionCreation";
import listView from "pages/marketTest/listView";
import AllCoursesDetails from "pages/studentMarketPlace/allCoursesDetail";
import AllCourseData from "pages/studentMarketPlace/allCoursesData";

import StudyMaterials from "pages/studentMarketPlace/myCourses/studyMaterial";
import QuestionAnswerSection from "pages/studentMarketPlace/myCourses/questionAnswerSection";
import Sidebar from "react-sidebar";
import SideBarStudentHome from "pages/studentMarketPlace/sideBarStudentHome";
import { connect } from "react-redux";
import SavedForLater from "pages/studentMarketPlace/savedForLater";
import StudentCart from "pages/studentMarketPlace/studentCart";
import StudentCheckOut from "pages/studentMarketPlace/studentCheckOut";
import { setSideBarStudentHome } from "actions/studentMarketPlace";
import { Layout } from "antd";
import AllCourseReviews from "pages/studentMarketPlace/coursePageCompo/allCourseReviews";
import ChapterContent from "pages/studentMarketPlace/coursePageCompo/chapterContent";
import studentPdfView from "pages/studentMarketPlace/coursePageCompo/studentPdfView";
import CourseDetailsPages from "pages/studentMarketPlace/myCourses/courseDetailPage";
import CourseContentList from "pages/studentMarketPlace/myCourses/courseContentList";
import locationDetails from "pages/studentMarketPlace/locationDetails";
import ResponseModal from "layout/responseModal";
import RedirectPage from "pages/studentMarketPlace/myCourses/RedirectPage";
import OnlineTestSeries from "pages/studentMarketPlace/myCourses/onlineTestSeries";
import MobileApplyCoupon from "pages/studentMarketPlace/mobileApplyCoupon";
import MobileFeePlan from "pages/studentMarketPlace/coursePageCompo/mobileFeePlan";
import feeDetails from "pages/adminMarketPlaceCourse/feeDetails";
import MarketPlace from "pages/marketPlace/marketPlace";
import StudentEnrolledTable from "pages/adminMarketPlaceCourse/components/studentEnrolled";
import Liveplayer from "pages/schedule/liveplayer";
import CourseCreationPage from "pages/adminMarketPlaceCourse/courseCreationPage";
import overviewCRM from "pages/MarketplaceCRM/overviewCRM";
import LoginPage from "pages/login-new";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SearchAllCoursesPage from "pages/adminMarketPlaceCourse/searchAllCoursesPage";



export const allowed = (props, serviceName, userAccess = null) => {
  if (props?.user) {
    const { user, authenticated } = props;
    const { services, access } = user;
    if (!userAccess) {
      userAccess = serviceName;
    }
    if (!services || !access) return;
    return (
      authenticated &&
      services.includes(serviceName) &&
      access.includes(userAccess)
    );
  }
}

const privateRoutes = (props) => {
  return [
    /*Schedule*/
    {
      path: "/", component: UserMarketPlaceHome, allowed: true,
      // path: "/", component: AulasMarketHome, allowed: true
      // path: "/", component: props?.user?.role?.includes('Student') ? DashBoardStudent : Schedule, allowed: allowed(props, "schedule", "live-class")
    },
    {
      path: "/schedule", component: Schedule,
      allowed: (
        allowed(props, "schedule", "schedule-student") ||
        allowed(props, "schedule", "schedule-teacher") ||
        allowed(props, "schedule", "schedule-admin") ||
        allowed(props, "schedule", "schedule-dataoperator") ||
        allowed(props, "schedule", "schedule-operation") ||
        allowed(props, "schedule", "schedule-superadmin")
      )
    },
    {
      path: "/schedule-attendance", component: ScheduleAttendance,
      allowed: (
        allowed(props, "schedule", "schedule-student") ||
        allowed(props, "schedule", "schedule-teacher") ||
        allowed(props, "schedule", "schedule-admin") ||
        allowed(props, "schedule", "schedule-dataoperator") ||
        allowed(props, "schedule", "schedule-operation") ||
        allowed(props, "schedule", "schedule-superadmin")
      )
    },
    /*Schedule Ends*/

    /*  Student DashBoard */
    {
      path: "/dashboard-student", component: DashBoardStudent, allowed: props?.authenticated
    },
    /* student dashboard end */

    /*Announcement*/
    {
      path: "/announcement", component: Announcement, allowed: allowed(props, "announcement")
    },
    {
      path: "/all-announcement", component: AllAnnouncementData, allowed: allowed(props, "announcement")
    },
    /*Announcement End*/

    // *  Student DashBoard */
    {
      path: "/dashboard-student", component: DashBoardStudent, allowed: props?.authenticated
    },
    /* student dashboard end */

    /*DL*/
    {
      path: "/digital-library/division-card", component: AdminEmptystate,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/broadcast-recordings", component: BroadCastRecordings,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/broadcast-recordings-details", component: BroadCastRecordingsCards,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },

    {
      path: "/digital-library/buy-storage", component: BuyStoragePage,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/graphCard", component: TeacherDashBoard,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/view-content", component: Viewpdf,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/dashbord-subject", component: ClassDetailsEmpty,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/subject-details", component: ChapterWiseViewPage,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/chapter-details", component: TopicWisePage,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    {
      path: "/digital-library/content", component: ContentPage,
      allowed: (
        allowed(props, "admin-library", "digital-library-admin") ||
        allowed(props, "admin-library", "digital-library-superadmin") ||
        allowed(props, "admin-library", "digital-library-student") ||
        allowed(props, "admin-library", "digital-library-teacher") ||
        allowed(props, "admin-library", "digital-library-dataoperator")
      )
    },
    /*DL END*/

    /*UserManagement*/
    // {path: "/user-management", component: UsersDashBoard, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/students", component: StudentDashBoard, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/students/unassigned", component: UnAssignedStudents, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/standard", component: AllStudents, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/teacher", component: DashboardByRole, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/planner", component: DashboardByRole, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/data-operator", component: DashboardByRole, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/operations", component: DashboardByRole, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/all-requests", component: AllUsersRequest, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/operation/details", component: UserDetailsForm, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/students/details", component: UserDetailsForm, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/data-operator/details", component: UserDetailsForm, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/teacher/details", component: UserDetailsForm, allowed: allowed(props, "user", "users")},
    // {path: "/user-management/all-requests/approval", component: approvalForm, allowed: allowed(props, "user", "users")},
    // {path: "/user/details", component: UserDetailsForm, allowed: allowed(props, "user", "users")},
    /*UserManagement End*/

    /* user-management-v2 starts */
    { path: "/user-management", component: UsersDashboard, allowed: allowed(props, "user", "users") },
    { path: "/user/student", component: UserTable, allowed: allowed(props, "user", "users") },
    { path: "/user/staff", component: UserTable, allowed: allowed(props, "user", "users") },
    { path: "/user/student-request", component: UserTable, allowed: allowed(props, "user", "users") },
    { path: "/user/staff-request", component: UserTable, allowed: allowed(props, "user", "users") },
    { path: "/user/registration/requests", component: RegistrationRequestsVA, allowed: allowed(props, "user", "users") },
    { path: "/user-management/deleted-users", component: DeletedUsers, allowed: allowed(props, "user", "users") },
    { path: "/user-management/transfer-data", component: TransferData, allowed: allowed(props, "user", "users") },
    { path: "/user-management/courses", component: StudentCoursesWClasses, allowed: allowed(props, "user", "users") },
    { path: "/user-management/standards", component: CourseStandards, allowed: allowed(props, "user", "users") },
    { path: "/user-management/profile", component: UserBasicProfile, allowed: allowed(props, "user", "users")  ||allowed(props, "standard", "course-view") },
    { path: "/user-management/courses/unassigned", component: CoursesWOClasses, allowed: allowed(props, "user", "users") },
    { path: "/user-management/students-by-courses", component: InsideLiveCoursesStudents, allowed: allowed(props, "user", "users") },
    { path: "/user-management/course-standards", component: AllStudentsUmV2, allowed: allowed(props, "user", "users") },

    /* user-management-v2 ends */


    // { path: "/course", component: CourseMain, allowed: allowed(props, "standard", "course") },
    // { path: "/course/details", component: CourseDetailsPage, allowed: allowed(props, "standard", "course") },

    /*CourseManagement*/
    { path: "/courses", component: CourseDashboard, allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view") },
    {
      path: `${props?.courseTypeName == "liveCourses" ? "/add-live-courses" : props?.courseTypeName == "videoResources" ? "/add-video-resources" : props?.courseTypeName == "onlineTestSeries" ? "/add-online-test-series" : props?.courseTypeName == "studyMaterials" ? "/add-study-materials" : "/add-live-courses"}`,
      component: CourseCreationPage,
      allowed: allowed(props, "standard", "course")
    },
    {
      path: `${props?.courseTypeName == "videoResources" ? "/add-video-resources-content" : "/add-study-materials-content"}`,
      component: AddCourseContent,
      allowed: allowed(props, "standard", "course")
    },
    {
      path: `${props?.courseTypeName == "searchResult" ? "/search-result" : props?.courseTypeName == "liveCourses" ? "/live-courses-data" : props?.courseTypeName == "videoResources" ? "/video-resources-data" : props?.courseTypeName == "onlineTestSeries" ? "/online-test-series-details" : props?.courseTypeName == "studyMaterials" ? "/study-materials-data" : "/live-courses-data"}`,
      component: AdminMarketPlaceWithData,
      allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view")
    },
    {
      path: "/search-results",
      component: SearchAllCoursesPage,
      allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view")
    },
    { path: "/course-details", component: CourseInformationPage, allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view") },
    { path: "/fee-details", component: feeDetails, allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view") },
    { path: "/students-view-all", component: StudentsViewAllPage, allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view")},
    { path: "/students-enrolled", component: StudentEnrolledTable, allowed: allowed(props, "standard", "course") || allowed(props, "standard", "course-view")},
    {
      path: '/course/add-quiz', component: AddQuiz, allowed: (allowed(props, "standard", "course"))
    },
    // { path: "/offers", component: PromotionalOffersPage, allowed: (allowed(props, "standard", "course")) },
    { path: "/create-offers", component: createOffers, allowed: (allowed(props, "standard", "course")) },
    { path: "/market-place-crm", component: MarketPlaceCRM, allowed: (allowed(props, "standard", "course")) || allowed(props, "standard", "course-view")  },
    { path: "/overview-crm", component: overviewCRM, allowed: (allowed(props, "standard", "course")) || allowed(props, "standard", "course-view") },
    /*CourseManagement End*/

    /* ONLINE TEST SERIES starts*/
    { path: '/course/online-test/series', component: AddOnlineTestSeries, allowed: allowed(props, 'standard', 'course') },
    { path: '/test-creation', component: QuestionCreation, allowed: allowed(props, 'standard', 'course') },

    /* ONLINE TEST SERIES ends*/

    // { path: "/course", component: CourseMain, allowed: allowed(props, "standard", "course") },
    // { path: "/course/details", component: CourseDetailsPage, allowed: allowed(props, "standard", "course") },
    // { path: "/course-details", component: CourseDetails, allowed: allowed(props, "standard", "course") },

    { path: "/home", component: UserMarketPlaceHome, allowed: true },
    { path: "/resource-details", component: UserCourseDetailsPage, allowed: true },
    { path: `/resource-details?id=${props?.courseIdAndTye?.id}&type=${props?.courseIdAndTye?.type}`, component: UserCourseDetailsPage, allowed: true },
    { path: "/course-details-page", component: CourseDetailsPages, allowed: true },
    { path: "/course-chapters", component: CourseContentList, allowed: true },
    { path: "/mobile-search-page", component: MobileSearchPage, allowed: true },

    /*Classroom*/
    {
      path: "/classroom", component: ClassRoom,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee") ||
        allowed(props, "standard", "course-view") 
      )
    },
    {
      path: "/classroom/classroom-details", component: SingleSectionDetails,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee") || 
        allowed(props, "standard", "course-view") 
      )
    },
    {
      path: "/classroom/resources", component: BrowseResources,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee")
      )
    },
    {
      path: "/classroom/editchapters", component: ClassRoomEditChapters,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee")
      )
    },
    {
      path: "/classroom/allstudents", component: ClassRoomAllStudents,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee") ||
        allowed(props, "standard", "course-view") 
      )
    },
    {
      path: "/students/enrolled", component: ClassRoomAllStudents,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee") ||
        allowed(props, "standard", "course-view") 
      )
    },
    {
      path: "/classroom/classroomdetails", component: BatchDetails,
      allowed: (
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee")
      )
    },
    {
      path: "/classroom/curriculum", component: Curriculum,
      allowed: (
        allowed(props, "standard", "classroom-superadmin") ||
        allowed(props, "standard", "classroom-admin") ||
        allowed(props, "standard", "classroom-leave") ||
        allowed(props, "standard", "classroom-fee")
      )
    },
    /*Classroom End*/

    /*Profile*/
    { path: '/payment-success', component: PaymentSuccess, allowed: props?.authenticated },
    { path: "/view-pdf", component: ProfileViewPdf, allowed: props.authenticated },
    { path: "/account-settings", component: AccountDetails, allowed: props.authenticated },
    { path: "/user-profile", component: UserProfile, allowed: props.authenticated },
    { path: "/institute-details", component: InstituteProfile, allowed: props.user.access?.includes("institute-edit") },
    { path: "/view-institute-details", component: ViewInstituteProfile, allowed: props.user.access?.includes("institute-view") },
    { path: "/view-kyc", component: ViewKYC, allowed: props.user.access?.includes("fees") },
    { path: "/general-settings", component: GeneralSettings, allowed: props.authenticated },
    { path: "/support-settings", component: SupportSettings, allowed: props.user.access?.includes("support-settings") },
    { path: "/subscriptions", component: Subscriptions, allowed: props.user.access?.includes("subscription-settings") },
    { path: '/about-aulas', component: AboutAulas, allowed: true },
    { path: '/privacy-policy', component: PrivacyPolicy, allowed: true },
    { path: '/tnc', component: Tnc, allowed: true },
    { path: '/user-profile/others', component: Others, allowed: props.authenticated },
    /*Profile Ends*/

    /*Care*/
    { path: "/care/submit-success", component: CareFormSubmitSuccess, allowed: allowed(props, "care", "care-admin") },
    { path: "/care/admin/conversations/user", component: CareAdminChatPage, allowed: allowed(props, "care", "care-admin") },
    { path: "/care/admin/conversations", component: ConversationPage, allowed: allowed(props, "care", "care-admin") },
    { path: "/care/faqs", component: CareFaqs, allowed: props.authenticated },
    { path: "/care/covid-resources", component: CovidResources, allowed: props.authenticated },
    { path: "/care/chat", component: StudentChat, allowed: props.authenticated },
    { path: "/care", component: StudentCare, allowed: props.authenticated },
    { path: "/admin-care", component: Care, allowed: props.authenticated },
    { path: "/admin-care/chat", component: ChatWithAulas, allowed: props.authenticated },
    { path: "/care/student-chat-page", component: ChatScreen, allowed: props.authenticated },
    { path: "/care/admin", component: props.showAdminCare ? SupportAdmin : CareInfoForm, allowed: allowed(props, "care", "care-admin") },
    /*Care End*/

    /*Notification*/
    { path: "/notifications-home", component: HomeNotification, allowed: props.authenticated },
    /*Notification End*/

    /* Curriculun starts here*/
    { path: "/syllabus", component: Curriculum, allowed: allowed(props, "standard", "curriculum") },
    /* Curriculun ends here*/

    /* Test starts here */
    {
      path: "/test-import-classes", component: ImportClasses,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-import-subjects", component: importTestSubjects,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-import-subjectwiseChapters", component: ImportSubjectwiseChapters,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-import-samplequestions", component: SampleQuesPapers,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/testlist", component: TestList,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/testlist", component: TestList,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/sub-ques-import-page", component: SubQuestionImportPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/sample-ques-import-page", component: SampleQuestionImportPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/sample-group-import", component: SampleGroupImport,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/sample-question-paper", component: SampleQuestionPaper,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/sample-list-view", component: SampleImportListView,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-subject", component: SubjectPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test", component: Test,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/admin-test", component: TestGenerateQuestions,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/admin-test-view", component: TestQuesNonEditablScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-generate-questions-list", component: QuesGenerateListView,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-evaluation-questions-list", component: EvaluationQuestionsListView,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-result-screen", component: ResultScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-publish-result-screen", component: PublishResultScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-std-to-subjects-screen", component: StdToSubjectsScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-submit-detail-screen", component: SubmittedDetailScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/test-stud-submit-ques-screen", component: StudSubmittedQueScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/create-test", component: CreateTest,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/edit-test", component: EditTest,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/marking-scheme-dashboard", component: MarkingSchemeDashboard,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path:
        `${props.filter?.VAtype === "results" ? "/result-list" : props.filter?.VAtype === "feedback" ? "/feedback-list" : props.filter?.VAtype === "review" ? "/review-list" : props.filter?.VAtype === "ready" ? "/publish-list" : "/result-list"}`, component: ResultList,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/syllabus-subjects", component: SyllabusSubjects,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/insideChapter", component: InsideChapter,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },
    /* Test ends here */

    /*  Market place starts here */
    { path: "/market-place", component: MarketPlace, allowed: allowed(props, 'market', 'market-admin') || allowed(props, 'market', 'marketplace-admin-view') },

    /* StudentTest starts here */
    { path: "/student-test/questions", component: TestScreen, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-test/result", component: TestResult, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-test/solutions", component: TestSolutions, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-test/subject/results", component: ResultsSummary, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-test/subject", component: TestBySubject, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-test", component: StudentTestDashboard, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-ChapterTest", component: ChaptertTestMod, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-part-Test", component: ChaptertTestMod, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-full-Test", component: ChaptertTestMod, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-InsideChapTest", component: InsideChapterTestMod, allowed: (allowed(props, "assignment", "student-test")) },
    /* StudentTest ends here */

    /* Practice-test starts here */
    {
      path: "/practise-test-SubQues-import-page", component: PracSubQuesImportPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-view-all", component: PractiseViewAll,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-tabs-page", component: TestTabsPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-chapter-page", component: PracChapterPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-subject-page", component: PracSubjectPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-syllabus-page", component: PracSyllabusPage,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-create-list-view", component: PractiseQuesGenerateListView,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-ready-to-publish", component: PracReadyToPublish,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },
    {
      path: "/liveplayer", component: Liveplayer, allowed:true
    },
    {
      path: "/practise-test-import-sub-chapters", component: ImportPracSubChapters,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-import-subwise", component: ImportPracSubwise,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },

    {
      path: "/practise-test-import-classes", component: ImportPracClasses,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },


    {
      path: "/practise-test-noneditable-questions", component: PracticeTestQuesNonEditablScreen,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },


    {
      path: "/practise-test-sample-group-import", component: PractiseSampleGroupImport,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },


    {
      path: "/practise-test-sam-question-paper", component: PractiseSampleQuesPaper,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },


    {
      path: "/practiseTest-questionScreen", component: PractiseTestGenerateQuestions,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },


    {
      path: "/practise-test", component: PractiseTest,
      allowed: (
        allowed(props, "assignment") ||
        allowed(props, "assignment", "teacher-test") ||
        allowed(props, "assignment", "test-admin") ||
        allowed(props, "assignment", "test-superadmin") ||
        allowed(props, "assignment", "dataoperator-test")
      )
    },
    /* Practice-test ends here */

    /*   Student-practice test starts here*/
    { path: "/student-practice-home", component: Homepage, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-practice-tabs", component: PracticeTabPage, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-practice-chapters", component: PracticeChapterPage, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/student-practise-subjects", component: PracticeSubjectPage, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/practice-result-screen", component: practiceResultScreen, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/practice-solution-screen", component: PractiseTestSolution, allowed: (allowed(props, "assignment", "student-test")) },
    { path: "/practice-testScreen", component: practiceTestScreen, allowed: (allowed(props, "assignment", "student-test")) },
    /*   Student-practice test ends here*/

    /* Attendance starts here*/
    { path: "/attendance-leaves", component: Leaves, allowed: (allowed(props, "standard", "course")) },
    { path: "/student-profile-detail", component: StudentProfileDetail, allowed: (allowed(props, "standard", "course")) },
    { path: "/attendance-requirements", component: AttendanceRequirement, allowed: (allowed(props, "attendance")) },
    { path: "/holiday-page", component: Holiday, allowed: (allowed(props, "attendance")) },
    { path: "/Student-attendance-graph", component: StudentAttendanceGraph, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-dashBoard", component: AttendanceDashBoard, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-teacher", component: TeachersDashBoard, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-data-operator", component: TeachersDashBoard, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-operation", component: TeachersDashBoard, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-student", component: StudentAttendanceGraph, allowed: (allowed(props, "attendance")) },
    { path: "/Class-page", component: ClassPage, allowed: (allowed(props, "attendance")) },
    { path: "/student-detail-page", component: StudentDetail, allowed: (allowed(props, "attendance")) },
    { path: "/leave-graph", component: LeaveGraph, allowed: (allowed(props, "attendance")) },
    { path: "/operations-view-all", component: AllOperations, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-details-view", component: AttendanceView, allowed: (allowed(props, "attendance")) },
    { path: "/users-dash-board", component: TeachersDashBoard, allowed: (allowed(props, "attendance")) },
    { path: "/users-geoFence", component: TeachersGeoFence, allowed: (allowed(props, "attendance")) },
    { path: "/leaves-dashboard", component: LeavesDashBoard, allowed: (allowed(props, "attendance")) },
    { path: "/leaves-overview", component: LeaveOverview, allowed: (allowed(props, "attendance")) },
    { path: "/attendancetab-details", component: AttendanceTabDetails, allowed: (allowed(props, "attendance")) },
    { path: "/request-viewall", component: RequestViewAll, allowed: (allowed(props, "attendance")) },
    { path: "/allteachers-viewall", component: AllTeachersViewAll, allowed: (allowed(props, "attendance")) },
    { path: "/allusers-view-all", component: AllOperations, allowed: (allowed(props, "attendance")) },
    { path: "/attendance-geofence", component: attendanceGeoFence, allowed: (allowed(props, "attendance")) },
    { path: "/my-attendance-student-dashBoard", component: MyAttendanceStudent, allowed: (allowed(props, "attendance")) },
    { path: "/student-analytic-graph", component: StudentAnalyticGraph, allowed: (allowed(props, "attendance")) },
    // { path: '/attendance/students', component: AttendanceStudents, allowed: (allowed(props, 'attendance')) },
    // { path: '/attendance/staff', component: AttendanceStudents, allowed: (allowed(props, 'attendance')) },
    // { path: '/attendance/requests', component: Requests, allowed: (allowed(props, 'attendance')) },
    // { path: '/staff-leave/summary', component: staffLeaveVA, allowed: (allowed(props, 'attendance')) },
    /* Attendance ends here*/

    /* Profile starts here */
    { path: "/broadcast-packages", component: AvailableSubscriptionPackages, allowed: (allowed(props, "user", "subscription-settings")) },
    { path: "/view-pdf", component: ProfileViewPdf, allowed: (allowed(props?.authenticated)) },
    /* Profile ends here */

    /* Fee starts here */
    { path: "/student-fees/all", component: AllFeeStudents, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/student-fees/transactions", component: Transactions, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/invoice", component: ViewInvoice, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/class-details/all-activities", component: AllActivitiesForClass, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/class-details", component: ClassFeeDashBoard, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/all-students", component: AllStudentsFile, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/all-activities", component: AllActivities, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/all-transactions", component: AllTransactions, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management", component: FeeManagement, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/all-settlements", component: AllSettlements, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/student-fees", component: StudentFees, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/kyc-form-rejected", component: KycReject, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/kyc-form-submitted", component: KycSuccess, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/kyc-form", component: KycForm, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/all-students/courses", component: AllStudentsByCourses, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/all-students/courses/view-all", component: AllStudentsCoursesVA, allowed: (allowed(props, "fee", 'fees')) },
    { path: "/fee-management/course-details", component: CourseDetailsFM, allowed: (allowed(props, "fee", 'fees')) },

    /* Fee ends here */

    /* Broadcast starts here */
    { path: "/broadcast", component: Broadcast, allowed: (allowed(props, "assignment", 'live-class')) },
    { path: "/liveclass", component: Conference, allowed: (allowed(props, "assignment", 'live-class')) },
    { path: "/recordings", component: PlayRecordings, allowed: true },

    /* Broadcast ends here */

    /* Conference starts here */
    { path: "/today/:liveClassId", component: LiveClass, allowed: (allowed(props, "schedule", 'today')) },
    { path: "/live-class", component: LiveSchedule, allowed: (allowed(props, "schedule", 'live-class')) },
    /* Conference ends here */

    /* Assignment starts here */
    {
      path: "/assignment/submissions/:assignmentId", component: AssignmentSubmissionDetails,
      allowed: (
        allowed("assignment", "assignment-teacher") ||
        allowed("assignment", "assignment-admin") ||
        allowed("assignment", "assignment-superadmin") ||
        allowed("assignment", "assignment-dataoperator")
      )
    },

    {
      path: "/assignment-editor", component: AssignmentEditor,
      allowed: (
        allowed("assignment", "assignment-teacher") ||
        allowed("assignment", "assignment-admin") ||
        allowed("assignment", "assignment-superadmin") ||
        allowed("assignment", "assignment-dataoperator")
      )
    },

    {
      path: "/assignment/:assignmentId", component: LoggerView,
      allowed: (
        allowed("assignment", "assignment-teacher") ||
        allowed("assignment", "assignment-admin") ||
        allowed("assignment", "assignment-superadmin") ||
        allowed("assignment", "assignment-dataoperator")
      )
    },

    {
      path: "/assignment", component: Assignment,
      allowed: (
        allowed("assignment", "assignment-teacher") ||
        allowed("assignment", "assignment-admin") ||
        allowed("assignment", "assignment-superadmin") ||
        allowed("assignment", "assignment-dataoperator")
      )
    },
    /* Assignment ends here */
    { path: "/aulas-wallet", component: AulasWalletDashBoard, allowed: true },
    { path: "/complete-kyc", component: CompleteStudentKyc, allowed: true },
    { path: '/payment-history', component: paymentHistory, allowed: true },
    { path: '/payment-request', component: paymentRequestsPage, allowed: true },
    { path: '/kyc-details', component: kycDetails, allowed: true },

    // { path: "/home", component: AulasMarketHome, allowed: true },
    { path: "/popular-courses", component: PopularCourses, allowed: true },
    { path: "/search", component: SearchError, allowed: true },
    { path: "/course-page", component: CourseProfile, allowed: true },
    { path: "/shopping-cart", component: ShoppingCart, allowed: true },
    { path: "/saved-later", component: SavedLater, allowed: true },
    { path: "/test-series", component: TestSeriesProfile, allowed: true },
    { path: "/study-materials", component: StudyMaterial, allowed: true },
    { path: "/course-list", component: CourseList, allowed: true },
    { path: "/courses-list", component: CourseHome, allowed: true },
    { path: "/my-account", component: MyAccount, allowed: true },
    { path: "/checkout", component: Checkout, allowed: true },
    { path: "/locations", component: Locations, allowed: true },
    // { path: "/courses", component: CourseHome, allowed: true },
    { path: "/video-resources", component: VideoResource, allowed: true },
    { path: "/pdf-view", component: PdfCourseView, allowed: true },
    { path: "/study-material", component: StudyMaterials, allowed: true },
    { path: "/online-test-series", component: OnlineTestSeries, allowed: true },
    { path: "/Q&A", component: QuestionAnswerSection, allowed: true },
    { path: "/saved-for-later", component: SavedForLater, allowed: true },
    { path: "/cart", component: StudentCart, allowed: true },
    { path: "/user-checkout", component: StudentCheckOut, allowed: true },
    { path: "/resource-details/reviews", component: AllCourseReviews, allowed: true },
    { path: "/course-content", component: ChapterContent, allowed: true },
    { path: "/pdf", component: studentPdfView, allowed: true },
    { path: "/apply-coupon", component: MobileApplyCoupon, allowed: true },
    { path: "/fee-plan", component: MobileFeePlan, allowed: true },







    // my course student market place 
    { path: '/all-popular-courses', component: AllCoursesDetails, allowed: true },
    { path: '/all-courses', component: AllCourseData, allowed: true },
    { path: '/location-details', component: locationDetails, allowed: true },
    { path: '/redirect-to', component: RedirectPage, allowed: true },


    /* online test series*/
    { path: "/ongoingTest/questions", component: OngoingTest, allowed: true },
    { path: "/testSeries/solutions", component: SolutionScreen, allowed: true },
    { path: "/testSeries/result", component: TestSeriesResult, allowed: true },
    { path: "/testSeries/createTest", component: CreateIndividualTest, allowed: true },
    { path: "/testSeries/addQuestions", component: AddQuestions, allowed: true },
    { path: "/testSeries/listView", component: listView, allowed: true },
  ]
}

const openRoutes = (props) => [
  { path: "/login/checkToken", component: ResetPassword },
  { path: "/forgot", component: ForgotPassword },
  { path: "/login/reset/success", component: ResetSuccess },
  { path: "/login", component: LoginPage }
]

const Root = (props) => {
  const { checked, user } = props;
  const isStudent = user?.role?.includes("Student");

  return (
    <Router>
      <InnerRoot checked={checked} user={user} {...props} />
    </Router>
  );
};

const InnerRoot = (props) => {
  const { checked, user } = props;
  const isStudent = user?.role?.includes("Student");
  const location = useLocation();
  
  return (
    <Sidebar
      sidebar={<SideBarStudentHome />}
      open={props?.sideBarStudentHome}
      sidebarClassName="custom-sider-style"
    >
      {checked && (
        <AnimatePresence>
          <Switch location={location} key={location?.key}>
            {/* {isStudent && (
              <Route exact path="/" component={UserMarketPlaceHome} />
            )} */}
            {openRoutes().map((page, index) => (
              <Route
                key={index}
                exact
                path={page.path}
                render={(props) => (
                  <>
                    <ResponseModal />
                    {React.createElement(page.component, props)}
                  </>
                )}
              />
            ))}
            {privateRoutes(props).map((page, index) => (
              <PrivateRoute
                key={index}
                exact
                path={page.path}
                component={page.component}
                allowed={page.allowed}
              />
            ))}
            <Route component={FourOFourPage} />
          </Switch>
        </AnimatePresence>
      )}
    </Sidebar>
  );
};


const mapStateToProps = (state) => {
  const { checked, authenticated, user } = state.session;
  const { showAdminCare } = state.adminCare;
  const { filter } = state.testAdmin;
  const { courseTypeName } = state.courseManagement;
  const { sideBarStudentHome, courseIdAndTye } = state.studentMarketPlace
  return {
    checked,
    authenticated,
    user,
    showAdminCare,
    filter,
    courseTypeName,
    sideBarStudentHome,
    courseIdAndTye
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSideBarStudentHome: (val) => dispatch(setSideBarStudentHome(val))

});
export default connect(mapStateToProps, mapDispatchToProps)(Root);