import React, { useEffect } from 'react';
import { Modal, Button, Tag } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { getStudentOverview } from 'actions/attendanceNew';
import "../style.css"
import { connect } from 'react-redux';
import moment from 'moment';
import { Api } from 'services';
import { SubIcon } from 'components/subIcon';
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { RiWirelessChargingLine } from 'react-icons/ri'
const image = require("../../../Assets/user/lab-conference.svg").default;
const image1 = require("../../../Assets/user/ic-present.svg").default;
const image2 = require("../../../Assets/user/Group 11858.svg").default;


const AttendanceReport = ({ isModalVisible, setIsModalVisible, getStudentOverview, storeSubjectDetails }) => {
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  /*  useEffect(() => {
     getStudentOverview()
   }, [])
    */
  console.log(storeSubjectDetails, 'storeSubjectDetails');
  return (
    <>
      <Modal
        centered
        className="modal-round-corner"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
        <div style={{ marginTop: "25px", color: "#191919", fontSize: "21px", fontWeight: "bold", textAlign: "center" }}>Attendance report</div>
        <div className="m-t-40" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <SubIcon size={60} icon={storeSubjectDetails?.icon} name={storeSubjectDetails?.subject} />
            <div className="m-l-10">

              {/* <Tag  style={{
                color: "#1089FF",
                backgroundColor: "#E7F3FF",
                borderColor: "#E7F3FF",
                fontSize: "8px",
                fontWeight: "bold"
              }}>{storeSubjectDetails?.mode}</Tag> */}

              <Tag style={{
                color: `${storeSubjectDetails.mode === 'Conference' ? ' #594099' :
                  storeSubjectDetails.mode === 'Zoom 40' ? ' #1089FF' : storeSubjectDetails.mode === 'Broadcast' ? '#FFA931' : storeSubjectDetails.mode === 'Offline' ? ' #28DF99' : null}`,
                backgroundColor: `${storeSubjectDetails.mode === 'Conference' ? ' #F6F4FE' :
                  storeSubjectDetails.mode === 'Zoom 40' ? ' #E7F3FF' : storeSubjectDetails.mode === 'Broadcast' ? '#FFF4D5' : storeSubjectDetails.mode === 'Offline' ? ' #E9FCF5' : null}`,
                borderColor: `${storeSubjectDetails.mode === 'Conference' ? ' #F6F4FE' :
                  storeSubjectDetails.mode === 'Zoom 40' ? ' #E7F3FF' : storeSubjectDetails.mode === 'Broadcast' ? '#FFF4D5' : storeSubjectDetails.mode === 'Offline' ? ' #E9FCF5' : null}`,
                fontSize: "8px",
                fontWeight: "bold",
              }}>
                <div style={{ display: "flex" }}>
                  <div>{storeSubjectDetails.mode === 'Conference' ? <RiWirelessChargingLine style={{ color: "#594099" }} /> :
                    storeSubjectDetails.mode === 'Zoom 40' ? <IoMdVideocam /> : storeSubjectDetails.mode === 'Broadcast' ? <MdCastConnected style={{ color: "#FFA931" }} /> : storeSubjectDetails.mode === 'Offline' ? <IoMdChatbubbles style={{ color: "#28DF99" }} /> : null}
                  </div>
                  <div style={{ marginLeft: "3px", marginTop: "-1px" }}>{storeSubjectDetails.mode === 'Conference' ? 'CONFERENCE' :
                    storeSubjectDetails.mode === 'Zoom 40' ? 'ZOOM 40' : storeSubjectDetails.mode === 'Broadcast' ? 'BROADCAST' : storeSubjectDetails.mode === 'Offline' ? 'OFFLINE' : null}</div>
                </div>
              </Tag>

              <div style={{ color: "#191919", fontSize: "15px", fontWeight: "bold" }}>{storeSubjectDetails?.subject}</div>
              <div style={{ color: "#636363", fontSize: "11px" }}> {storeSubjectDetails?.standards.replace(/\s+/g, '-')}</div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ color: `${storeSubjectDetails.attendanceStatus === "Present" ? "#28DF99" : "#FF414D"}`, marginLeft: 10 }}>{storeSubjectDetails.attendanceStatus === "Present" ? <CheckCircleFilled /> : <CloseCircleFilled/>}</div>
            <div className='m-l-5' style={{ color: `${storeSubjectDetails.attendanceStatus === "Present" ? "#28DF99" : "#FF414D"}` }}>{storeSubjectDetails?.attendanceStatus}</div>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "25px" }}>
          <div><img src={image2} /></div>
          <div className='p-5' style={{ color: "#636363", fontSize: "13px", }}>{moment(storeSubjectDetails?.date).format("DD-MM-YYYY")}{"  "}{"•"}{"  "}{(moment(storeSubjectDetails?.startTime, 'hh:mm:ss').format('hh:mm a'))}{" "}-{" "}{(moment(storeSubjectDetails?.endTime, 'hh:mm:ss').format('hh:mm a'))} </div>
          <div style={{ display: "flex", marginLeft: "50px" }}>
            <div>
              <SubIcon size={20} icon={storeSubjectDetails?.teacherImage} name={storeSubjectDetails?.subject} />
            </div>

            <div className='p-5' style={{ color: "#636363", fontSize: "13px" }}>{storeSubjectDetails?.teacherName}</div>
          </div>
        </div>
        <div
          style={{
            marginTop: "25px",
            marginLeft: "10px",
            borderRadius: "6px",
            alignItems: "center",
            Width: 170,
            height: 90,
            backgroundColor: "#FAFAFA",
          }}>
          <div style={{ display: "flex", justifyContent: "space-between", }}>
            <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px", marginTop: "10px" }}>Join time</div>
            <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", marginTop: "10px", fontWeight: "bold" }}>
              {
                storeSubjectDetails?.joinTime != null ?
                  moment(storeSubjectDetails?.joinTime, 'hh:mm:ss').format('hh:mm a')
                  :
                  "--:--"
              }
              {/* {moment(storeSubjectDetails?.joinTime === null ? "00" : storeSubjectDetails?.joinTime,'hh:mm' ).format('hh:mm')} */}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px" }}>Leave time</div>
            <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", fontWeight: "bold" }}>
              {
                storeSubjectDetails?.leaveTime != null ?
                  (moment(storeSubjectDetails?.leaveTime, 'hh:mm ').format('hh:mm a'))
                  :
                  "--:--"
              }
              {/* {moment(storeSubjectDetails?.leaveTime===null?"00":storeSubjectDetails?.leaveTime,'hh:mm').format('hh:mm')} */}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px" }}>Duration</div>
            <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", fontWeight: "bold", display: "flex" }}>
              {/* {storeSubjectDetails?.attendanceDuration===null?"0:00":storeSubjectDetails?.attendanceDuration} */}
              {storeSubjectDetails?.attendanceDuration === null ? "00:00" :
                <div>{parseInt(storeSubjectDetails?.attendanceDuration / 60).toString().padStart(2, '0')}:{parseInt(storeSubjectDetails?.attendanceDuration % 60).toString().padStart(2, '0')}</div>}
              <div>hrs</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: "center", marginTop: "25px" }}>
          <Button
            style={{
              borderRadius: "19px",
              width: "120px",
              height: "35px",
              fontWeight: "500",
              border: "1px solid #636363 ",
              backgroundColor: "#FFFFFF",
            }}

          >
            <div
              style={{
                fontSize: "16px",
                color: "#636363 ",
                fontWeight: "bold",
              }}
              onClick={handleCancel}
            >
              CLOSE
            </div>
          </Button>
        </div>
      </Modal>
    </>
  );

};



const mapStateToProps = (state) => {
  const { storeSubjectDetails } = state.attendanceNew;
  return { storeSubjectDetails };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentOverview: () => dispatch(getStudentOverview()),
  storeStudentSubjects: (val) => dispatch(storeStudentSubjects(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReport);