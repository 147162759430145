import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";

const TeachersWorkhrsGraph = ({color,data,teacherGraph}) => {
   
  const config = {
    data,
    xField:"unit",
    yField: "avgDurationInMinutes",
    tooltip:
    {customContent:(val ,item)=>{
      console.log("item",item);
      return(
        <div>
          {teacherGraph!==true?
          <div style=
          {{
            height:"50px",
            minWidth:"200px",
          }}>
            <div style={{ width: '220px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around',marginTop:"20px",borderRadius:"5px" }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: '11px',fontFamily:"Roboto" }}>CLOCK IN</div>
                  <div className='text-heading' style={{ fontSize: '14px',fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.clockIn}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: '11px',fontFamily:"Roboto"}}>CLOCK OUT</div>
                  <div className='text-heading' style={{ fontSize: '14px',fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.clockOut}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: '9px',fontFamily:"Roboto"}}>WORKING HRS</div>
                  <div className='text-heading' style={{ fontSize: '14px',fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.workingHours}</div>
              </div>
             </div>

              
          </div>
          :
          <div style={{width:"fit-content",height:"70px",padding:"10px",flexDirection: 'row'}}>
            <div style={{fontSize: '11px',fontFamily:"Roboto",}}>WORKING HOURS</div>
            <div style={{fontSize:"14px",fontWeight:"bold",marginTop:"5px"}}>
              {item[0]?.data?.avgWorkingHours!==null?item[0]?.data?.avgWorkingHours:"0:0"}</div>
            <div style={{fontSize: '11px',fontFamily:"Roboto",marginTop:"7px",}}>
            {item[0]?.data?.percentageThanPrevDay!==null?item[0]?.data?.percentageThanPrevDay:"0:0"}% than previous day</div>
          </div>
          }
        </div>
      )

    }},
    color:`${color}`,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Attendance",
      },
    },
    slider: {
      
      start: 0,
      end:0.5,
      // sales: {
      //   alias: "Attendance",
      // },
    },
    minColumnWidth: 25,
    maxColumnWidth: 20,
  };

  
  return <Column {...config} />;
};
export default TeachersWorkhrsGraph;
