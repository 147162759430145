import React,{useEffect,useState} from 'react'
import Divisioncomponent from '../components/Divisioncomponent'
import {connect} from 'react-redux'
import AdminDashBoardCard from './dashBoard'
import {fetchDashBoardData,storeArray} from 'actions/digitalLibrary'
import PageDataLoader from 'components/pageDataLoader'
import NoDataCard from '../components/noDataCard'
import { Heading } from 'components/Typography'

 function AdminDashBoard({
    fetchDashBoardData,standardsInfo,storeArray,totalCountList
}) {
    const [sd,setsd]=useState({})
    const[arr,setarr]=useState([])
    const [pageLoader, togglePageLoader] = useState(false)

    useEffect(() => {
        togglePageLoader(true)
        fetchDashBoardData(()=>togglePageLoader(false))   
       
    }, [])
    useEffect(() => {
            
            var m = {}
   
    if(standardsInfo){
        
        
        standardsInfo.map(({std_id,std_std,std_section,notesCount,videoCount,quesBankCount,std_stream})=>{
             if(m[std_std]){
            m[std_std]=[...m[std_std],{
                std_id:std_id,
                 std_section:std_section,
                notesCount:notesCount,
                videoCount:videoCount,
                quesBankCount:quesBankCount,
                std_stream:std_stream
            }]
        }
        else{
            m[std_std]=[{
                std_id:std_id,
                std_section:std_section,
                notesCount:notesCount,
                videoCount:videoCount,
                quesBankCount:quesBankCount,
                std_stream:std_stream
            }]
        }


        })
    }
    var arrk=Object.keys(m);
    setsd(m)
    var arr2=[];
    arrk.map((item)=>{
        arr2.push([item,m[item][0].std_id])
    })
    storeArray(arr2)
    setarr(arr2)
    }, [standardsInfo])
    return (
        <div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <div  style={{fontSize:'28px',fontWeight:"700", marginTop:'10px',marginBottom:'10px'}}>Digital library</div>
           
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <button style={{borderRadius:"30px",background:"#594099",marginBottom:"20px",width:"150px",color:"white",fontSize:"15px",border:"none",cursor:"not-allowed"}}>+UPLOAD</button>
                <p style={{color:"#1089FF",fontFamily:"roboto",fontWeight:"700",cursor:"not-allowed"}}>SEE MORE ANALYTICS</p>
            </div>
          </div>
            <div style={{display:'flex' ,justifyContent:'space-around',width:'100%'}}>
              <AdminDashBoardCard totalCountList={totalCountList}/>
        </div>
      
        <Heading className='m-t-10'>?All Classes</Heading>
            
            {arr?.length!=0?
            
            arr.map((items,index)=>{
                return( <div>
                
                            <p  className='lecturenotestitle'  style={{fontSize:'18px', marginTop:'10px',marginBottom:'10px'}}>{items[0]}</p>
                            <div style={{display:'flex',flexWrap:'wrap'}}>
                
                
                                    {
                                        sd[items[0]].map((item,index)=>{
                                            return (<Divisioncomponent std_section={item.std_section} notesCount={item.notesCount} videoCount={item.videoCount} quesBankCount={item.quesBankCount} index={index} stds={items} section_arr={sd} std_stream={item.std_stream}/>)
                                        })
                                    }
                            </div>
                            </div>

                )
            })
                

            :
            // <NoDataCard/>
            null
            }
        <PageDataLoader visible={pageLoader}/>

        </div>
    )
}



const mapStateToProps = (state) => {
  const {
      standardsInfo,
      totalCountList
    
  } = state.digitalLibrary;
  return {
      standardsInfo,
      totalCountList
  };
};

const mapDispatchToProps = (dispatch) => ({
    fetchDashBoardData: (callback) => dispatch(fetchDashBoardData(callback)),
    storeArray:(arr2)=> dispatch(storeArray(arr2))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  
)(AdminDashBoard);
