import React from 'react';
// import { Typography, Card, Avatar, Button } from 'antd';
import { Color } from '../../services';
import {
  FaMicrophoneSlash,
  FaMicrophone,
  FaVideoSlash,
  FaVideo
} from "react-icons/fa";

import './styles.css';
import '../../design/layout.css';

const ChatBox = ({img, name, rollNo, audioOn, videoOn, offLine}) => {
  return (
    <div className='chatContain'>
      <div className='chatView'>
        {img ?
          <img className='chatImg' src={img} alt="chat"/>
          :
          <div style={{backgroundColor: offLine ? Color.magenta : Color.lightGreen }}className='chatImg r-ac-jcc text-center text-sm bold grey'>
            {name[0]}
          </div>
        }
        <div className='p-l-10'>
          <div className='text-sm bold color-black'>{name}</div>
          <div className='text-xsm a2grey'>Roll No: {rollNo}</div>
        </div>
      </div>

      <div className='r-c-sb'>
        <div className='m-r-10'>
          {audioOn ?
            <FaMicrophone style={{color: Color.magenta, fontSize: 15, }} />
            :
            <FaMicrophoneSlash style={{color: Color.magenta, fontSize: 15, }} />
          }
        </div>
        <div>
          {videoOn ?
            <FaVideo style={{color: Color.magenta, fontSize: 15, }} />
            :
            <FaVideoSlash style={{color: Color.magenta, fontSize: 15, }} />
          }
        </div>
      </div>
    </div>
  )
};

export default ChatBox;
