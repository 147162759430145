import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Col, DatePicker, Input, Modal, Row, TimePicker, Select } from 'antd'
import { getAllLeave } from 'actions/attendanceNew'

const RequestLeavesModel = ({
    storeNoOfDay,
    getCaluclatedDates,
    isLeaveRequest,
    setIsLeaveRequest,
    setLeaveRequest,
    loaders,
    leaveRequest,
    user,
    AttendanceTab,
    leaveRequestObj,
    getAllLeave,
    setAllLeaves,
    togglePageLoader,
    getAttendanceDashBoardData, editLeaveObj ,storeNumberOfDays}) => {
        console.log("AA-storeNoOfDay",storeNoOfDay);
    const [subType, setSubType] = useState(false)
    const [halfDay, setHalfDay] = useState(false)
    const [backDays, setBackDays] = useState(null)
    const [startDate, setStartDate] = useState('')
    const [requestBtnLoader, setRequestBtnLoader] = useState(false)
    // const [diffT, setDiff] = useState(0)

    const dateFormat='DD/MM/YYYY'

    const handleOk = () => {
        setIsLeaveRequest(false);
        storeNumberOfDays()
    };

    const handleCancel = () => {
        setIsLeaveRequest(false);
        setLeaveRequest("noOfdays", '')
        setLeaveRequest("startDate", '')
        setLeaveRequest("endDate", '')

    };

    const handleRequestBtn = () => {
        setRequestBtnLoader(true)
        leaveRequest(
            () => {
            // setIsLeaveRequest(false);
            setRequestBtnLoader(false);
        },
            () => {
            setIsLeaveRequest(false);
                setLeaveRequest("noOfdays", '')
                setLeaveRequest("startDate", '')
                setLeaveRequest("endDate", '')
                togglePageLoader(true);
                getAttendanceDashBoardData(() => {
                    togglePageLoader(false)
                })
            }, 
            'Attendance Home'
            )
        setLeaveRequest('requestType', 'Leave')
    }

    const { Option } = Select;

    useEffect(() => {
        setLeaveRequest('startDate', '');
        setLeaveRequest('endDate', '');
    }, [halfDay])

    useEffect(()=>{
        console.log('days', moment().subtract(backDays, 'days').format('YYYY-MM-DD'), backDays)
        disabledDate(backDays)
    }, [leaveRequestObj?.leaveType])

    useEffect(() => {
        setLeaveRequest('startDate', '');
        setLeaveRequest('endDate', '');
        setLeaveRequest("noOfdays", '');
        setLeaveRequest('leaveType', '');
        setLeaveRequest('reason', '');
     
    }, [])


    useEffect(() => {
        if (leaveRequestObj?.startDate !== '' && leaveRequestObj?.endDate !== '' && halfDay === false) {
            let tempStartDate = moment(leaveRequestObj?.startDate)
            let tempEndDate = moment(leaveRequestObj?.endDate)
            console.log("date", leaveRequestObj?.startDate,)
            console.log("date", (tempEndDate.diff(tempStartDate, 'days') + 1))
            setLeaveRequest('noOfdays', (tempEndDate.diff(tempStartDate, 'days') + 1))
        }
    }, [leaveRequestObj?.startDate, leaveRequestObj?.endDate])

    function disabledDate(current) {
        return current < moment().subtract(backDays, 'days').startOf('day');
    }

    function disabledEndDate(current) {
        return current < moment(leaveRequestObj?.startDate);
    }

    const handleChange = (value) => {
        if (value === 'Half day') {
            setHalfDay(true);
            setLeaveRequest('noOfdays', 0.5)
        } else {
            setHalfDay(false)
            storeNumberOfDays()

        }
    }
    useEffect(()=>{
       storeNumberOfDays()
    },[])

    // const calcDiff = () => {
    //     let startDate = moment(leaveRequestObj?.startDate, 'YYYY-MM-DD');
    //     let endDate = moment(leaveRequestObj?.endDate, 'YYYY-MM-DD');
    //     let totalDays = endDate.diff(startDate, 'days');

    //     if(totalDays != NaN && totalDays != "NaN"){
    //         setDiff(totalDays)
    //     }
    // }

    // const startDate = moment(requestDto.startDate);
    // const endDate = moment(requestDto.endDate);
    // request.duration = (endDate.diff(startDate, 'days') + 1).toString()

    return (
        <>
            <Modal
                centered
                className="modal-round-corner"
                visible={isLeaveRequest}
                onOk={handleOk}
                onCancel={handleCancel}
                header={null}
                footer={null}>
                <div style={{
                    marginTop: "20px",
                    fontSize: "22px",
                    color: "#191919",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                }}>
                    {user?.privilage?.includes("SuperAdmin") ? "Mark Leave(s)" :
                        "Request Leave(s)"}
                </div>
                <Row>
                    <Col className='m-t-10 m-l-10'>
                        <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                            Leave type
                        </h6>
                        <div>
                            <Select onChange={(value, key) => {
                                setLeaveRequest('leaveType', value);
                                setSubType(setAllLeaves?.leaves[`${key.key}`].halfDayApplicable)
                                // setDiff('')
                                setBackDays(setAllLeaves?.leaves[`${key.key}`].backDays)
                            }} placeholder='Select leave type' style={{ width: "460px" }} >
                                {setAllLeaves?.leaves?.map((item, index) => (
                                    <Option key={index} className="createTestModalDropdown" value={item?.leaveType}>{item?.leaveType}</Option>
                                ))}

                            </Select>
                        </div>
                    </Col>
                </Row>

                {subType &&
                    (<div className='m-t-20 m-l-10'>
                        <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                            Sub type
                        </h6>
                        <div>
                            <Select
                                placeholder="Select sub type"
                                style={{ width: "100%" }}
                                onChange={handleChange}>
                                <Option value="Full day">Full day</Option>
                                <Option value="Half day">Half day</Option>
                            </Select>
                        </div>
                    </div>)
                }
                {console.log('sub', subType)}
                {halfDay != false && subType == true?
                    (<Col className='m-t-20 ' style={{ width: "50%" }}>
                        <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                            Date
                        </h6>
                        <div><DatePicker
                            format={'DD-MM-YYYY'}
                            allowClear={false}
                            disabledDate={user?.privilage?.includes("SuperAdmin") ? () => false : disabledDate}
                            placeholder='Select date'
                            style={{ width: "225px" }}
                            onChange={(e) => {
                                setStartDate(setLeaveRequest(e.format('YYYY-MM-DD'))),
                                    setLeaveRequest('startDate', e.format('YYYY-MM-DD')),
                                    setLeaveRequest('endDate', e.format('YYYY-MM-DD')); 
                            }} /></div>
                    </Col>
                    ) : 
                    (<Row>
                        <Col className='flex-row'>
                            <Col className='m-t-20 m-l-10' style={{ width: "50%" }}>
                                <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                                    Start Date
                                </h6>
                                <div><DatePicker
                                    format={dateFormat}
                                    allowClear={false}
                                    disabledDate={user?.privilage?.includes("SuperAdmin") ? () => false : disabledDate}
                                    placeholder='Select start date' style={{ width: "225px" }}
                                    onChange={(e) => {
                                        setStartDate(setLeaveRequest(e.format('YYYY-MM-DD'))),
                                        setLeaveRequest('startDate', e.format('YYYY-MM-DD')),
                                        setLeaveRequest('endDate', '')
                                    }} /></div>
                            </Col>
                            <Col className='m-t-20 m-l-10' style={{ width: "50%" }}>
                                <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                                    End Date
                                </h6>
                                <div><DatePicker
                                selected={leaveRequestObj?.endDate !== '' ? moment() : moment(leaveRequestObj?.endDate)}
                                    format={dateFormat}
                                    allowClear={false}
                                    disabledDate={disabledEndDate}
                                    placeholder='Select end date'
                                    style={{ width: "225px" }}
                                    onChange={(e) => {
                                        setLeaveRequest('endDate', e.format('YYYY-MM-DD'))
                                        getCaluclatedDates()

                                    }}
                                    value={leaveRequestObj?.endDate ? moment(leaveRequestObj?.endDate) : null}
                                    disabled={startDate === '' ? true : false} /></div>
                            </Col>
                        </Col>
                    </Row>)
                }
                {halfDay != false && subType == true ?
                    (<Row>
                        <div className='m-t-20 m-l-10'>
                            <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                                Number of days
                            </h6>
                            <Input readOnly value="0.5" style={{ width: "460px" }} ></Input>
                        </div>

                    </Row>
                    )
                    :
                    (<Row>
                        <div className='m-t-20 m-l-10'>
                            {console.log("AA-storeNoOfDay?.size",storeNoOfDay?.size)}
                            <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                                Number of days
                            </h6>
                            <Input readOnly
                                placeholder='Number of days'
                                // value={isNaN(diffT) ? 0 : diffT >= 0 ? diffT + 1 : leaveRequestObj?.endDate === '' ? 0 : null}
                                value={storeNoOfDay?.size}
                                style={{ width: "460px" }}></Input>
                        </div>

                    </Row>)
                }
                <Row>
                    <div className='m-t-20 m-l-10'>
                        <h6 style={{ fontSize: "14px", color: "#636363", fontWeight: "bold", }}>
                            Reason(s)
                        </h6>
                        <Input placeholder='Enter reason(s)' style={{ width: "460px", height: "70px" }}
                            onChange={(e) => { setLeaveRequest('reason', e.target.value.trim()) }}></Input>
                    </div>

                </Row>
                <Row style={{ marginLeft: "140px" }}>
                    <Col className='p-5'>
                        <Button className='m-t-20'
                            style={{
                                borderRadius: "19px",
                                width: "110px",
                                height: "35px",
                                fontWeight: "500",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #636363 ",
                                color: "#636363",
                            }} onClick={handleCancel}
                        >CANCEL
                        </Button>
                    </Col>
                    <Col className='p-5'>
                        <Button className='m-t-20'
                            disabled={
                                (leaveRequestObj.leaveType === '' ||
                                    leaveRequestObj.startDate === '' ||
                                    leaveRequestObj.endDate === '' ||
                                    leaveRequestObj.noOfdays === '' ||
                                    leaveRequestObj.reason === '')
                            /* halfDay */ ? true : false}
                            style={{
                                borderRadius: "19px",
                                width: "110px",
                                height: "35px",
                                fontWeight: "500",
                                backgroundColor: "#594099",
                                border: "1px solid #636363 ",
                                color: "#FFFFFF",
                                opacity: (leaveRequestObj.leaveType === '' ||
                                    leaveRequestObj.startDate === '' ||
                                    leaveRequestObj.endDate === '' ||
                                    leaveRequestObj.noOfdays === '' ||
                                    leaveRequestObj.reason === '') ? 0.5 : 1
                            }}
                            onClick={handleRequestBtn}
                            loading={requestBtnLoader}
                        >{user?.privilage?.includes("SuperAdmin") ? "Mark" :
                            "Request"}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default RequestLeavesModel