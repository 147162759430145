import React from 'react';
import Styles from './style';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import Typography from 'antd/lib/typography';
import Card from 'antd/lib/card';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index'
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/typography/style/index';
import 'antd/lib/card/style/index';
import { Color } from '../../services';
import { ClockCircleOutlined, EllipsisOutlined, VideoCameraFilled, VideoCameraOutlined } from '@ant-design/icons';
const { Text } = Typography;

const SummaryCard = ({
  teacher,subject,topic,time,buttonType,standard, color, onClick, isEditable = false,
  loading, onEditClick, onDelteClick,onEndClick, buttonName, labelName="Scheduled", days, roomname, live = false
}) => {
  const darkColor = Color[color];
  const lightColor = Color[`light${color}`];
  const subjLetter = subject.charAt(0);

  const menu = (
    <Menu style={{borderRadius:5, width: 80}} onClick={(value) => handleMenuItemClick(value)}>
      <Menu.Item key="0">
        Edit
      </Menu.Item>
      <Menu.Item key="1">
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleMenuItemClick = (value) => {
    if(value.key === "0") {
      onEditClick();
    } else if(value.key === "1") {
      onDelteClick();
    } else {
      // Do nothing
      onEndClick();
    }
  };

  return (
    <div style={{...Styles.cardContainer,backgroundColor: lightColor, margin:10, width: '96%'}}>
      <div style={{display: 'flex', width: '100%'}}>
        <div style={{flex: 1}}>
          <Avatar size={40} style={{ ...Styles.avatar, color: lightColor, backgroundColor: darkColor }}>{subjLetter}</Avatar>
        </div>
        <div style={{flex: 8,  width: '90%'}}>
          <Row>
            <Col span={20} style={{whiteSpace: 'nowrap', width: '95%', overflow:'hidden',
            textOverflow: 'ellipsis', color: darkColor, paddingBottom:0, fontSize: 14, fontWeight: 800}} level={4}>{topic}</Col>
            {isEditable && (
              <Col span={4} style={{marginBottom:-10}}>
                 {labelName !== "On Going" &&
              <Dropdown className='flex-1' overlay={menu} trigger={['click']}>
                  <EllipsisOutlined  style={{fontSize:30, color:darkColor, cursor:'pointer'}}/>
              </Dropdown> }
              </Col>

            )}
          </Row>
          <div style={{width: '90%',  fontWeight: 'bold', whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis'}}>
          <Text style={{color: darkColor,  fontSize: 12,}}> {teacher}</Text>
          </div>
          <div style={{whiteSpace: 'nowrap', width: '95%', overflow:'hidden', textOverflow: 'ellipsis'}} >
          <Text style={{color: darkColor,  fontSize: 12}}> {standard}</Text>
          </div>
          <div style={{whiteSpace: 'nowrap', width: '100%', overflow:'hidden', textOverflow: 'ellipsis'}} >
          <Text style={{color: darkColor,  fontSize: 12}}> {days}</Text>
          </div>

        </div>
      </div>
      <div style={Styles.timeWrapper}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <ClockCircleOutlined style={{...Styles.clockIcon, color: darkColor}}/>
          <Text strong style={{color: darkColor, fontSize: 12}}>{time}</Text>
        </div>
        <div>
          {live && <VideoCameraFilled className="m-r-10" style={{color: Color.red}}/>}
          {buttonType ?
            (
                <Button size="medium" style={{...Styles.startBtn,backgroundColor: darkColor}} onClick={onClick} loading={loading}>
                {buttonName}
              </Button>
              )
            :(
              <Text size="large" style={{backgroundColor: 'transparent', color: darkColor, marginRight: 5}}>
                {labelName === "On Going" ? <img width="60px" src={require("Assets/live.gif").default} /> : labelName}
              </Text>
          )}
        </div>
      </div>
    </div>
  )
};

const SummaryCardCalendar = ({subject,topic,time,classState,standard, color, onClick}) => {
  const darkColor = Color[color];
  const lightColor = Color[`light${color}`];
  const subjLetter = subject.charAt(0);

  return (
    <Card style={{...Styles.cardContainer,backgroundColor: lightColor, margin:10}}>
      <EllipsisOutlined />
      <div style={{display: 'flex', margin:'-15px'}}>
        <Avatar size={50} style={{ ...Styles.avatar, color: lightColor, backgroundColor: darkColor }}>{subjLetter}</Avatar>
        <div className='flex-1'>

          <div className='r-c-sb'>
            <Text style={{color: darkColor}}>{subject} • Class {standard}</Text>
            <Text strong style={{color: darkColor, fontSize: 15}}>{time}</Text>
          </div>
          <div className='r-c-sb'>
            <Text style={{color: darkColor}}>{topic}</Text>
            <Text strong style={{color: darkColor, fontSize: 15}}>{classState}</Text>
          </div>
        </div>
      </div>

    </Card>
  )
};

export {SummaryCard, SummaryCardCalendar};
