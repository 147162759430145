import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Slider } from 'antd';
import Cropper from 'react-easy-crop';
import { Button } from 'components/Button';


const CropModal = ({ visible, imageSrc, onCancel, onCropComplete, onCrop, isCrop, setIsCrop, setCreateCourseObj }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);


  useEffect(() => {
    if (visible) {
      setCroppedAreaPixels(null)
      setCreateCourseObj('image', null)
    }
  }, [visible])

  useEffect(() => {
    if (!isCrop) {
      setCroppedAreaPixels(null)
    }
  }, [isCrop])

  const onCropCompleteCallback = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    onCropComplete(croppedArea, croppedAreaPixels);
  }, [onCropComplete]);


  return (

    <Modal
      className="modal-round-corner"
      visible={visible}
      width={700}
      closable={false}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: '20px 20px 0px 20px', height: '550px', borderRadius: 12 }}

    >


      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'center', fontSize: 20, color: '#191919', width: '95%', fontWeight: 500, fontFamily: 'roboto', paddingLeft: '4%' }}>
          Crop image
        </div>
        <div className={!isCrop ? 'custom-crop-cancel-button' : 'custom-crop-cancel-button-hover'} onClick={() => { setIsCrop(!isCrop) }}>
          <img src={require("../../../Assets/courseManagementV4/crop.svg").default} style={{ width: 25, height: 25 }} />
        </div>
      </div>

      {!isCrop
        ?
        <div style={{ marginTop: '3%', height: 385 }}><img src={imageSrc} style={{ height: 385, width: '97%', marginLeft: '1.5%', borderRadius: 10 }} /></div>
        :
        <div style={{ padding: '20%', height: '80%' }} className="outer-div">
          {imageSrc && (
            <div
              style={{
                padding: '10%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'fit-content',
                borderRadius: 10
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1.5} // Adjust aspect ratio based on need
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropCompleteCallback}
              />
            </div>
          )
          }
        </div>
      }


      <div style={{ display: 'flex', justifyContent: 'space-around', width: '50%', marginLeft: '25%', marginTop: !isCrop ? 32 : 12 }}>
        <Button type='' style={{ backgroundColor: '#E1E3EA' }} onClick={() => { onCancel(); setIsCrop(false) }}>CANCEL</Button>
        <Button type='primary' onClick={() => { onCrop(croppedAreaPixels); setIsCrop(false) }}>SAVE</Button>
      </div>




    </Modal>
  );
};

export default CropModal;
