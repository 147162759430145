import { fetchcourseStandards, setSelectedStandard } from 'actions/userManagementV2';
import { Typography } from 'antd';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { StandardCard } from './courseStandards';

const coursesWOutClasses = ({ history, fetchcourseStandards, courseStandards, setSelectedStandard }) => {

  const [pageLoader, togglePageLoader] = useState(false);

  useEffect(() => {
    togglePageLoader(true);
    fetchcourseStandards({ courseId: -1 }, () => togglePageLoader(false));
  }, [])

  console.log({ courseStandards })

  return (
    <div>
      <PageHeader title='Un-Assigned standards' onBackPress={() => history.goBack()} />
      <div className='m-t-50 p-20'>
        {pageLoader ? <></> :
          courseStandards?.standardsArray && courseStandards?.standardsArray?.length !== 0 ? (
            courseStandards?.standardsArray?.map((item, idx) => (
              <div key={idx} className='m-t-40'>
                <h2 className="m-t-10 bold-600" style={{ fontSize: "14px" }}>
                  {item.std}
                </h2>
                <div className="r-c-fs flex-wrap">
                  {courseStandards?.standardsArray[idx]?.standards?.map((standard) => (
                    <div key={standard.id} className='' style={{ padding: 5 }}>
                      <StandardCard standard={standard} history={history} setSelectedStandard={setSelectedStandard} />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : 'NODATA'}
      </div>
      <PageDataLoader visible={pageLoader} />
    </div >
  )
};

const mapStateToProps = (state) => {
  const { user } = state.session;
  const { courseStandards, selectedCourse } = state.userManagementV2;
  return { courseStandards, selectedCourse }
};

const mapDispatchToProps = (dispatch) => ({
  fetchcourseStandards: (params, callback) => dispatch(fetchcourseStandards(params, callback)),
  setSelectedStandard: res => dispatch(setSelectedStandard(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(coursesWOutClasses);