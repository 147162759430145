import Avatar from 'antd/lib/avatar/avatar'
import moment from 'moment'
import React from 'react'
import image from '../../Assets/care/Icon awesome-image.svg'
import { SquareSubIcon } from 'components/subIcon'
import { FileImageFilled } from '@ant-design/icons'
import { Typography } from 'antd/lib'


function UserProfileCards({ imgUrl, userName, role, message, createdAt, type, chat, unreadCount }) {

    unreadCount=unreadCount > 10? '10+' : unreadCount;
    if (chat === false) {
        return (


            <div className="careUserCard" style={{height: "180px"}}>
                <div className='careUserCard-p m-t-15' style={{ marginLeft: "50%", width: "55%", fontSize: "12" }}>{moment(createdAt).format("DD/MM/YYYY, hh:mm A")} </div>
                <div style={{ display: "flex", flexDirection: "row" }} className=" m-t-15 p-l-10">
                    <div><Avatar style={{ width: "50px", height: "50px", marginRight: "15px", marginLeft: "0px", border: "solid #F6F4FE 1px", borderRadius: "25px" }} src={imgUrl} alt="" /></div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
                        <Typography.Text className="careUserCard-heading" ellipsis={{tooltip: true}} style={{ marginBottom: "0px", fontSize: 16, fontWeight: 'bold', width: 200 }}>{userName}</Typography.Text>
                        <p className='careUserCard-p'>{role}</p>
                    </div>
                </div>
                <div style={{ width: "100%",display:"flex",flexDirection:"row",alignItems:"center" }}>
                    {type == 'Image' ?
                        <div style={{ display: "flex", flexDirection: "row",marginRight:"66%" ,paddingLeft:"10px", fontWeight: unreadCount!=0 ? "bold" : "regular"}}>
                            <FileImageFilled style={{ fontSize:"20px",color:"#AEAEAE"}} />
                            <p style={{ marginLeft: "3%",  color: "grey" }}>Image</p>
                        </div>
                        :
                        <p className='careUserCard-p text-ellipsis--2 p-l-10' style={{width:"85%", fontWeight: unreadCount!=0 ? "bold" : "regular"}}>{message}</p>
                    }
                      {unreadCount!=0?
                    <div>
                        <p style={{width:"25px",height:"25px",backgroundColor:"#FFA931",color:"white",fontSize:"12px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",borderRadius:"50%",fontWeight:"600"}}>{unreadCount}</p>
                    </div>
                    :null}
                   
                </div>
            </div>)
    }

    if (chat === true) {
        return (


            <div style={{ display: "flex", marginBottom: "0px", flexDirection: "column", justifyContent: "left", alignItems: "start", width: "100%", padding: "7px" }}>
                <p className='careUserCard-p' style={{ marginLeft: "50%", width: "55%", fontSize: "12", marginBottom: "-5px" }}>{moment(createdAt).format("DD/MM/YYYY, hh:mm A")} </p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div><Avatar style={{ width: "50px", height: "50px", marginRight: "15px", marginLeft: "0px", border: "solid #F6F4FE 1px", borderRadius: "25px" }} src={imgUrl} alt="" /></div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
                        <h1 className="careUserCard-heading" style={{ marginBottom: "0px" }}>{userName}</h1>
                        <p className='careUserCard-p'>{role}</p>
                    </div>
                </div>
                <div style={{ width: "100%",display:"flex",flexDirection:"row",alignItems:"center" }}>
                    {type == 'Image' ?
                        <div style={{ display: "flex", flexDirection: "row" ,marginRight:"66%",paddingLeft:"10px"}}>
                            <FileImageFilled style={{ fontSize:"20px",color:"#AEAEAE",marginTop:"15px"}} />
                            <p style={{ marginLeft: "3%", marginTop: "23%", color: "grey" }}>Image</p>
                        </div>
                        :
                        <p className='careUserCard-p' style={{width:"85%", padding: "5%" }}>{message.length > 50 ? message.substring(0, 50) + '....' : message}</p>
                    }
                    {unreadCount!=0?
                    <div>
                        <p style={{width:"25px",height:"25px",backgroundColor:"#FFA931",color:"white",fontSize:"12px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",borderRadius:"50%",fontWeight:"600"}}>{unreadCount}</p>
                    </div>
                    :null}
                </div>
            </div>
        )

    }





}

export default UserProfileCards
