import { Avatar, Modal } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import React, {useState, useEffect} from 'react'
import { Api } from 'services';

const UserDetails = ({ open, onClose, data, updateUserRegistrations, togglePageLoader, params, getUsers }) => {

        console.log({ data });

        const [approveLoader, setApproveLoader] = useState(false);
        const [rejectLoader, setRejectLoader] = useState(false);



        const handleButtonActions = (status) => {
                const registrationsParams = {
                        requestStatuses: [{
                                userId: data?.id,
                                status: status
                        }]
                };

                const setLoadingState = (loaderState) => {
                        if (status === 'Approved') {
                                setApproveLoader(loaderState);
                        } else {
                                setRejectLoader(loaderState);
                        }
                };

                const successCallback = () => {
                        onClose();
                        togglePageLoader(true);
                        getUsers(params, () => togglePageLoader(false));
                }

                setLoadingState(true);
                updateUserRegistrations(registrationsParams, () => setLoadingState(false), () => successCallback());
        }

        return (
                <div>
                        <Modal
                                centered
                                closable
                                open={open}
                                footer={null}
                                className='modal-round-corner'
                                onCancel={onClose}
                        >
                                <div>
                                        <center>
                                                <Heading>Registration Details</Heading>
                                                <div className='m-t-30'>
                                                        <Avatar size={80} src={`${Api._s3_url}${data?.image}`}></Avatar>
                                                </div>
                                        </center>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', padding: 20, marginLeft: 30 }}>
                                                <div className='m-t-20'>
                                                        <div style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>Role</div>
                                                        <div style={{ color: '#191919', fontSize: 14, fontWeight: 600 }}>{data?.role ?? 'NA'}</div>
                                                </div>
                                                <div className='m-t-20'>
                                                        <div style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>Name</div>
                                                        <div style={{ color: '#191919', fontSize: 14, fontWeight: 600 }}>{data?.name ?? 'NA'}</div>
                                                </div>
                                                <div className='m-t-20'>
                                                        <div style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>Mobile number</div>
                                                        <div style={{ color: '#191919', fontSize: 14, fontWeight: 600 }}>{data?.phone ?? 'NA'}</div>
                                                </div>
                                                <div className='m-t-20'>
                                                        <div style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>Email ID</div>
                                                        <div style={{ color: '#191919', fontSize: 14, fontWeight: 600 }}>{data?.email || 'NA'}</div>
                                                </div>
                                                {data?.role == 'Student' ?
                                                        <div className='m-t-20'>
                                                                <div style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>Course</div>
                                                                <div style={{ color: '#191919', fontSize: 14, fontWeight: 600 }}>{data?.preferredCourse ? data?.preferredCourse : 'NA'}</div>
                                                        </div>
                                                        : null
                                                }
                                                {/* {data?.role != 'Student' ?
                                                        <div className='m-t-20'>
                                                                <div style={{ color: '#636363', fontSize: 12, fontWeight: 500 }}>Privilage</div>
                                                                <div style={{ color: '#191919', fontSize: 14, fontWeight: 600 }}>{data?.role ?? 'NA'}</div>
                                                        </div>
                                                        : null
                                                } */}
                                        </div>
                                </div>
                                <center>
                                        <div className='' style={{ width: '70%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Button loading={rejectLoader} onClick={() => handleButtonActions('Rejected')} type='delete' style={{ border: 'none', width: 120 }}>REJECT</Button>
                                                <Button loading={approveLoader} onClick={() => handleButtonActions('Approved')} type='primary' style={{ backgroundColor: '#28DF99', color: '#FFF', border: 'none' }}>APPROVE</Button>
                                        </div>
                                </center>
                        </Modal>
                </div>
        )
}

export default UserDetails