import React from "react";
import "../style.css";
import { Modal, Button } from "antd";

const image = require("../../../Assets/user/ill-success.svg").default;

const GeoFenceSuccessModal = ({isGeoFenceModal,setIsGeoFenceModal,close}) => {
  const handleOkay = () => {
    close(false);
  };
//   const handleCancel=()=>{
//       setIsGeoFenceModal(false)
//   }
  return (
  
        <Modal
          className="modal-round-corner"
          centered
          visible={true}
          onCancel={close}
          onOk={close}
          footer={null}
        >
            <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                <img src={image}/>
            </div>
          <div
            style={{
              marginTop: "30px",
              fontSize: "21px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              fontFamily:"Roboto"
            }}
          >
            Successfully assigned!
          </div>
          <div
            style={{
              marginTop: "5px",
              fontSize: "13px",
              color: "#636363",
              display: "flex",
              justifyContent: "center",
              fontFamily:"Roboto"
            }}
          >
            You have successfully assigned geo fence.
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "5px",
              flexDirection: "column",
            }}
          >
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#594099",
                  border: "1px solid #594099 ",
                  color: "#FFFFFF",
                }}
                onClick={()=>{handleOkay()}} 
              >
                OKAY
              </Button>
            </div>
        
        </div>
      </Modal>
     
  );
};

export default GeoFenceSuccessModal

