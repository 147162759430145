import React from "react";
import Tabs from "antd/lib/tabs";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Empty from "antd/lib/empty";
import Modal from "antd/lib/modal";
import "antd/lib/tabs/style/index";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/empty/style/index";
import "antd/lib/modal/style/index";
import { SyncOutlined } from "@ant-design/icons";
import { TiFilter } from "react-icons/ti";
import { IoMdSearch } from "react-icons/io";
import { Api, Color } from "services";
import { AssignmentCard } from "components";
import { PieChart } from "react-minimal-pie-chart";
import PullToRefresh from 'react-simple-pull-to-refresh';
import {
  getStudentAssignment,
  getStudentAssignmentbyType,
  getStudentAssignmentDetail,
  getStudentAssignmentResults,
  submitModalToggle,
  setStudentId,
  setStudentQuestionIndex
} from "actions/assignment";
import { updateUserAction } from "actions/login";
import { connect } from "react-redux";
import "./styles.css";
import "../../design/layout.css";
import "../../design/assignment.css";

const { TabPane } = Tabs;

class WebAssignment extends React.Component {
  componentDidMount() {
    localStorage.clear();
    const {
      submitModalToggle,
      getStudentAssignment,
      updateUserAction,
      setStudentQuestionIndex
    } = this.props;

    var url = new URL(window.location);
    var user = url.searchParams.get("user");

    if (user) {
      const userObj = JSON.parse(user);
      updateUserAction(userObj);
      Api.header(userObj.accessToken);
      Api.orgHeader(userObj.org.url);
    }

    submitModalToggle(false);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("setVisible");
    }
    setTimeout(() => getStudentAssignment(() => {}), 1000);
    setStudentQuestionIndex(0);
  }

  assignmentCardClick(id, data, history) {
    const {
      getStudentAssignmentDetail,
      getStudentAssignmentResults
    } = this.props;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("cardClick");
    }
    if (
      data.assignmentStatus === "Graded" ||
      data.assignmentStatus === "Submitted"
    ) {
      getStudentAssignmentResults(id, data, history);
    } else {
      getStudentAssignmentDetail(id, data, history);
    }
  }

  onRefresh = () => {
      return new Promise((resolve) => {
          // setTimeout(resolve, 1000);
          this.props.getStudentAssignment(resolve);
      });
  }

  render() {
    const {
      history,
      getStudentAssignment,
      getStudentAssignmentbyType,
      studentAssignmentList,
      studentNewAssignmentList,
      assignmentLoader,
      donutData
    } = this.props;

    const chartData = [
      {
        title: "New",
        value: donutData.new,
        color: "#40A1FF"
      },
      {
        title: "In Progress",
        value: donutData.inProgress,
        color: "#FDE261"
      },
      {
        title: "Pending",
        value: donutData.pending,
        color: "#ff9999"
      },
      {
        title: "Submitted",
        value: donutData.submitted,
        color: "#53E5AD"
      },
      {
        title: "Evaluated",
        value: donutData.graded,
        color: "#FFBA5A"
      }
    ];
    return (
      <div>
        {/*<div className='r-c-sb flex-1 full-width p-10'>
          <div className='NutinFont bold-700 text-lg'>
            Assignments
          </div>
          <div className='profileSize roundImage r-c-c'>
            <MdNotifications className='color-purple text-lg'/>
          </div>
          <div className='profileSize'>
            <img
              className='profileSize'
              src="https://images.unsplash.com/photo-1446071103084-c257b5f70672?ixlib=rb-1.2.1&auto=format&fit=crop&w=631&q=80"
              alt=""
            />
          </div>
        </div>*/}
        <PullToRefresh
          onRefresh={() => this.onRefresh()}
        >
          <Tabs defaultActiveKey="0" centered className="p-10">
            <TabPane
              tab={
                <div
                  onClick={() => getStudentAssignment(() => {})}
                  className="NutinFont bold-700 text-xmd"
                >
                  ALL TIME
                </div>
              }
              key="0"
            >
              <Row justify="center" align="center">
                <Col xs={13}>
                  <PieChart
                    labelPosition={0}
                    rounded
                    startAngle={-90}
                    totalValue={donutData.total}
                    lineWidth={10}
                    data={chartData}
                  />
                  <div className="NutinFont text-md titledonut absolute bold-800 text-center">
                    {donutData.total}
                    <br />
                    <span className="text-xs lowgrey bold-400">Total</span>
                  </div>
                </Col>
                <Col xs={11}>
                  <div className="p-10">
                    {chartData.map((data, index) => (
                      <div className="r-c-fs m-b-5 m-l-5" key={index.toString()}>
                        <div
                          className="radius-10"
                          style={{
                            width: 10,
                            height: 10,
                            marginRight: 10,
                            backgroundColor: data.color
                          }}
                        />{" "}
                        <span className="text-sm NutinFont color-black p-r-5">
                          {" "}
                          {data.value + " "}
                        </span>
                        <span className="text-xs NutinFont">{data.title}</span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={
                <div
                  onClick={() => getStudentAssignmentbyType("week")}
                  className="NutinFont bold-700 text-xmd"
                >
                  THIS WEEK
                </div>
              }
              key="1"
            >
              <Row justify="center" align="center">
                <Col xs={13}>
                  <PieChart
                    labelPosition={0}
                    rounded
                    startAngle={-90}
                    totalValue={donutData.total}
                    lineWidth={10}
                    data={chartData}
                  />
                  <div className="NutinFont text-md titledonut absolute bold-800 text-center">
                    {donutData.total}
                    <br />
                    <span className="text-xs lowgrey bold-400">Total</span>
                  </div>
                </Col>
                <Col xs={11}>
                  <div className="p-10">
                    {chartData.map((data, index) => (
                      <div className="r-c-fs m-b-5 m-l-5" key={index.toString()}>
                        <div
                          className="radius-10"
                          style={{
                            width: 10,
                            height: 10,
                            marginRight: 10,
                            backgroundColor: data.color
                          }}
                        />{" "}
                        <span className="text-sm NutinFont color-black p-r-5">
                          {" "}
                          {data.value + " "}
                        </span>
                        <span className="text-xs NutinFont">{data.title}</span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={
                <div
                  onClick={() => getStudentAssignmentbyType("month")}
                  className="NutinFont bold-700 text-xmd"
                >
                  THIS MONTH
                </div>
              }
              key="2"
            >
              <Row justify="center" align="center">
                <Col xs={13}>
                  <PieChart
                    labelPosition={0}
                    rounded
                    startAngle={-90}
                    totalValue={donutData.total}
                    lineWidth={10}
                    data={chartData}
                  />
                  <div className="NutinFont text-md titledonut absolute bold-800 text-center">
                    {donutData.total}
                    <br />
                    <span className="text-xs lowgrey bold-400">Total</span>
                  </div>
                </Col>
                <Col xs={11}>
                  <div className="p-10">
                    {chartData.map((data, index) => (
                      <div className="r-c-fs m-b-5 m-l-5" key={index.toString()}>
                        <div
                          className="radius-10"
                          style={{
                            width: 10,
                            height: 10,
                            marginRight: 10,
                            backgroundColor: data.color
                          }}
                        />{" "}
                        <span className="text-sm NutinFont color-black p-r-5">
                          {" "}
                          {data.value + " "}
                        </span>
                        <span className="text-xs NutinFont">{data.title}</span>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          <div className="NutinFont bold-700 text-mdl p-l-15">
            Your assignments
          </div>
          <div className="p-5 r-c-sb">
            <div
              onClick={() => history.push("/web-assignment/search")}
              className="greyBackTest flex-1 searchUi m-r-10 m-l-10"
            >
              <IoMdSearch
                className="color-purple"
                style={{ fontSize: "1.7em" }}
              />
            </div>
            <TiFilter
              onClick={() => history.push("/web-assignment/filter")}
              className="color-purple"
              style={{ fontSize: "1.7em" }}
            />
          </div>
          <Tabs className="m-l-10 m-r-10">
            <TabPane
              tab={<div className="NutinFont bold-700 text-xmd">ALL</div>}
              key="1"
            >
              {studentAssignmentList.length ? (
                studentAssignmentList.map((data, index) => (
                  <AssignmentCard
                    key={index.toString()}
                    newItem={data.remainder === "Today"}
                    subject={data.sub}
                    topic={data.title}
                    status={data.remainder}
                    dueDate={data.deadlineDate}
                    onPress={() =>
                      this.assignmentCardClick(data.id, data, history)
                    }
                    completion={parseInt(data.progress)}
                  />
                ))
              ) : (
                <Empty
                  description={
                    <div className="NutinFont bold-700 text-xmd">
                      No Assignments Found!
                    </div>
                  }
                />
              )}
            </TabPane>
            <TabPane
              tab={<div className="NutinFont bold-700 text-xmd">NEW</div>}
              key="2"
            >
              {studentNewAssignmentList.length ? (
                studentNewAssignmentList.map((data, index) => {
                  return (
                    <AssignmentCard
                      key={index.toString()}
                      newItem
                      subject={data.sub}
                      topic={data.title}
                      status={data.remainder}
                      dueDate={data.deadlineDate}
                      onPress={() =>
                        this.assignmentCardClick(data.id, data, history)
                      }
                      completion={parseInt(data.progress)}
                    />
                  );
                })
              ) : (
                <Empty
                  description={
                    <div className="NutinFont bold-700 text-xmd">
                      No Assignments Found!
                    </div>
                  }
                />
              )}
            </TabPane>
            <TabPane
              tab={<div className="NutinFont bold-700 text-xmd">IN PROGRESS</div>}
              key="3"
            >
              {studentAssignmentList.filter(
                item => item.assignmentStatus === "Draft"
              ).length ? (
                studentAssignmentList.map(data => {
                  if (data.assignmentStatus === "Draft") {
                    return (
                      <AssignmentCard
                        key={data.id.toString()}
                        subject={data.sub}
                        topic={data.title}
                        status={data.remainder}
                        dueDate={data.deadlineDate}
                        onPress={() =>
                          this.assignmentCardClick(data.id, data, history)
                        }
                        completion={parseInt(data.progress)}
                      />
                    );
                  } else return null;
                })
              ) : (
                <Empty
                  description={
                    <div className="NutinFont bold-700 text-xmd">
                      No Assignments Found!
                    </div>
                  }
                />
              )}
            </TabPane>
            <TabPane
              tab={<div className="NutinFont bold-700 text-xmd">PENDING</div>}
              key="4"
            >
              {studentAssignmentList.filter(
                item => item.assignmentStatus === "Pending"
              ).length ? (
                studentAssignmentList.map((data, index) => {
                  if (data.assignmentStatus === "Pending") {
                    return (
                      <AssignmentCard
                        key={data.id.toString()}
                        subject={data.sub}
                        topic={data.title}
                        status={data.remainder}
                        dueDate={data.deadlineDate}
                        onPress={() =>
                          this.assignmentCardClick(data.id, data, history)
                        }
                        completion={parseInt(data.progress)}
                      />
                    );
                  } else return null;
                })
              ) : (
                <Empty
                  description={
                    <div className="NutinFont bold-700 text-xmd">
                      No Assignments Found!
                    </div>
                  }
                />
              )}
            </TabPane>
            <TabPane
              tab={<div className="NutinFont bold-700 text-xmd">SUBMITTED</div>}
              key="5"
            >
              {studentAssignmentList.filter(
                item => item.assignmentStatus === "Submitted"
              ).length ? (
                studentAssignmentList.map((data, index) => {
                  if (data.assignmentStatus === "Submitted") {
                    return (
                      <AssignmentCard
                        key={data.id.toString()}
                        subject={data.sub}
                        topic={data.title}
                        status={data.remainder}
                        dueDate={data.deadlineDate}
                        onPress={() =>
                          this.assignmentCardClick(data.id, data, history)
                        }
                        completion={parseInt(data.progress)}
                      />
                    );
                  } else return null;
                })
              ) : (
                <Empty
                  description={
                    <div className="NutinFont bold-700 text-xmd">
                      No Assignments Found!
                    </div>
                  }
                />
              )}
            </TabPane>
            <TabPane
              tab={<div className="NutinFont bold-700 text-xmd">EVALUATED</div>}
              key="6"
            >
              {studentAssignmentList.filter(
                item => item.assignmentStatus === "Graded"
              ).length ? (
                studentAssignmentList.map((data, index) => {
                  if (data.assignmentStatus === "Graded") {
                    return (
                      <AssignmentCard
                        key={data.id.toString()}
                        subject={data.sub}
                        topic={data.title}
                        status={data.remainder}
                        dueDate={data.deadlineDate}
                        onPress={() =>
                          this.assignmentCardClick(data.id, data, history)
                        }
                        completion={parseInt(data.progress)}
                      />
                    );
                  } else return null;
                })
              ) : (
                <Empty
                  description={
                    <div className="NutinFont bold-700 text-xmd">
                      No Assignments Found!
                    </div>
                  }
                />
              )}
            </TabPane>
          </Tabs>
        </PullToRefresh>
        <Modal
          visible={assignmentLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
            <div className="text-xmd m-t-10">Loading Assignments</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state.session;
  const {
    assignmentLoader,
    studentAssignmentList,
    donutData,
    studentNewAssignmentList
  } = state.assignment;
  return {
    assignmentLoader,
    studentAssignmentList,
    user,
    donutData,
    studentNewAssignmentList
  };
};

const mapDispatchToProps = dispatch => ({
  getStudentAssignment: (callback) => dispatch(getStudentAssignment(callback)),
  submitModalToggle: value => dispatch(submitModalToggle(value)),
  updateUserAction: user => dispatch(updateUserAction(user)),
  setStudentId: value => dispatch(setStudentId(value)),
  getStudentAssignmentbyType: type =>
    dispatch(getStudentAssignmentbyType(type)),
  getStudentAssignmentDetail: (id, data, history) =>
    dispatch(getStudentAssignmentDetail(id, data, history)),
  getStudentAssignmentResults: (id, data, history) =>
    dispatch(getStudentAssignmentResults(id, data, history)),
  setStudentQuestionIndex: value => dispatch(setStudentQuestionIndex(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WebAssignment);
