import React, { useEffect, useState } from "react";
import AddUsers from "./addUsers";
import { Modal, Col, Row, Typography } from "antd";
import "./styles.css";
import AddStdTchrModal from "./addStdTchrModal";
import image3 from "Assets/user-management/add-students.svg";
import RoleDetailsCard from "./roleDetailsCard";
import image from "Assets/user-management/ic-students.svg";

import image1 from "Assets/user-management/ic-teachers.svg";
import planner from "Assets/user-management/ic-planner.svg";
import data_operator from "Assets/user-management/ic-data-operator.svg";
import operation from "Assets/user-management/ic-operation.svg";
import GrowthSummary from "./growthSummary";
import UserCard from "./userCard";
import ApprovalPanel from "./approvalPanel";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import {
  addUserByRole,
  getStats,
  setClass,
  setClassId,
  setDashBoardRole,
  setEmail,
  setEndDate,
  setNextRollNo,
  setPhone,
  setPrivilage,
  setRollNumber,
  setTab,
  setUserName,
  setStartDate,
  setMonthOrWeek,
  setSpecificUserId,
  setRole,
  clearAddUserFields,
  downloadTemplate,
  storeAddUsers,
  storeExcelUploadDetails,
  storeAdduserFields,
} from "actions/userManagement";
import { IMG_URL } from "../../env.json";
import ApprovalModal from "./approvalModal";
import moment from "moment";
import { useHistory } from "react-router";
import { setOverviewFilter } from "actions/feeManagement";
import { PlusCircleOutlined } from "@ant-design/icons";
import PageHeader from "components/pageHeader";
import { Heading } from "components/Typography";
import {Button} from 'components/Button'
import { showMenu } from "actions/layout";

const roleWiseDetails = (item, index) => {
  return (
  <Col className="m-t-20" style={{ cursor: "pointer", width: "22%", minWidth: 250}} >
        <RoleDetailsCard
          image={item.image}
          count={item.count}
          role={item.role}
          lastWeekCount={item.lastWeekCount}
        />
  </Col>
)}

function UsersDashBoard({
  setMonthOrWeek,
  getStats,
  dashboardData,
  setDashBoardRole,
  addUserByRole,
  setPrivilage,
  setEmail,
  setPhone,
  setUserName,
  setClass,
  setRollNumber,
  setClassId,
  setNextRollNo,
  setTab,
  setStartDate,
  setEndDate,
  setSpecificUserId,
  setRole,
  setOverviewFilter,
  user,
  storeAddUserDetails,
  clearAddUserFields,
  downloadTemplate,
  storeAddUsers,
  storeExcelUploadDetails,
  storeAdduserFields,
  dashBoardRole,
  showMenu
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [fieldDisabledForFee, setFieldDisabledForFee] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false)
  const history = useHistory();
  
  
  useEffect(() => {
    showMenu(true)
    let temp = false;
    temp =
      user.privilage.includes("SuperAdmin") || user.privilage.includes("Admin");
    setFieldDisabledForFee(!temp);
  }, []);
  
  
  useEffect(() => {
    setEndDate(moment().format("YYYY-MM-DD"));
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
  }, []);


  const showModal = () => {
    setIsModalVisible(true);
    storeAddUsers('type', '')
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsRoleModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    // storeExcelUploadDetails(null)
    clearAddUserFields('role', '')
    clearAddUserFields('name', '')
    clearAddUserFields('email', '')
    clearAddUserFields('phoneNo', null)
    clearAddUserFields('course', '')
    clearAddUserFields('privilage', [])
  };

  const handleClickOnUser = (item) => {
    setSpecificUserId(item?.id);
    setOverviewFilter("userId", item?.id);

    setRole(item?.role[0]);
    history.push("/user/details");
  };

  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);

  const roleDetails = [
    {
      image:image,
      count:dashboardData?.students?.totalStudents,
      role:"Student",
      lastWeekCount: dashboardData?.students?.totalStudentsCreatedLastWeek
      
    },
    {
      image:image1,
      count:dashboardData?.teachers?.totalTeachers,
      role:"Teacher",
      lastWeekCount:dashboardData?.teachers?.totalTeachersCreatedLastWeek
    },
    {
      image: data_operator,
      count:dashboardData?.dataOperators?.totalDataOperators,
      role:"Data operator",
      lastWeekCount:dashboardData?.dataOperators?.totalDataOperatorsCreatedLastWeek
    },
    {
      image:operation,
      count:dashboardData?.operations?.totalOpeations,
      role:"Operations",
      lastWeekCount: dashboardData?.operations?.totalOpeationsCreatedLastWeek
      
    },
    
  ]

  const showRoleModal = () => {
    setIsRoleModalVisible(true);
  };
  const successCallback = () => {
    setIsModalVisible(false)
    clearAddUserFields('role', '')
    clearAddUserFields('name', '')
    clearAddUserFields('email', '')
    clearAddUserFields('phoneNo', null)
    clearAddUserFields('course', '')
    clearAddUserFields('privilage', [])    
    setPageLoader(true);
    getStats(() => setPageLoader(false));
  };

  const toggleButtonLoader = () => {
    setButtonLoader(false);
  };

  const handleRoleOk = () => {
    if(dashBoardRole == 'Student'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Teacher'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Data operator'){
      storeAdduserFields('role', "Data Operator")
    }else if(dashBoardRole == 'operations'){
      storeAdduserFields('role', dashBoardRole)
    }
    console.log('role', storeAddUserDetails?.role)
    setButtonLoader(true);
    addUserByRole(
      () => successCallback(),
      () => toggleButtonLoader()
    );
  };

  const handleRoleCancel = () => {
    setButtonLoader(false);
    clearAddUserFields('role', '');
    clearAddUserFields('name', '');
    clearAddUserFields('email', '');
    clearAddUserFields('privilage', []);
    clearAddUserFields('phoneNo', null);
    clearAddUserFields('course', '');
    setIsModalVisible(false);
  };

  const [isApprovalVisible, setIsApprovalVisible] = useState(false);

  const showApprovalModal = () => {
    setIsApprovalVisible(true);
  };

  const handleApprovalOk = () => {
    setIsApprovalVisible(false);
  };

  const handleApprovalCancel = () => {
    setIsApprovalVisible(false);
  };

  useEffect(() => {
    setClassId(null);
    setDashBoardRole(null);
    setTab(null);
    setPrivilage([]);
    setEmail(null);
    setPhone(null);
    setUserName(null);
    setClass(null);
    setRollNumber(null);
    setPageLoader(true);
    setMonthOrWeek("week");
    getStats(() => setPageLoader(false));
  }, []);

  const actions = [
    (user?.privilage?.includes('Admin') || user?.privilage?.includes('SuperAdmin')) ?
      <Button type="primary" onClick={()=>{showModal()}} style={{}} icon={<PlusCircleOutlined/>}>ADD USER(S)</Button>
    : null
  ]

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {dashboardData && dashboardData?.recentlyAdded?.length !== 0 ? (
        <div style={{ width: "100%", height: "100%" }}>
            <PageHeader title='User Management' actions={actions}/>
          <Heading className="m-t-20 m-l-10">
            Overall Stats
          </Heading>
          <Row
            // gutter={{ xs: 8, sm: 16, md: 24}}
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "98.5%",
              marginBottom: 10,
              marginLeft: 10,
              marginTop: -20,
            }}
          >
            {roleDetails.map((item, index) => roleWiseDetails(item, index))}
          </Row>
          <div style={{}}>
            {(user?.privilage?.includes("FeeManager") || user?.privilage?.includes("LeaveManager")) && !user?.privilage?.includes('Admin') ?
              <></>
            :
            <Row className="r-jsb">
              <Col
                style={{ marginTop: "10px" }}
                md={{ span: 24 }}
                lg={{ span: 14 }}
              >
                <GrowthSummary setPageLoader={setPageLoader} />
              </Col>
              <Col md={{ span: 24 }} lg={{ span: 10 }} >
                <ApprovalPanel
                  fieldDisabledForFee={fieldDisabledForFee}
                  setIsApprovalVisible={setIsApprovalVisible}
                />
              </Col>
            </Row>
            }
            <Heading className="m-t-20 m-l-10">
              Recently added user(s)
            </Heading>
              <Row style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "98.5%", marginLeft: 10}} >
                {dashboardData?.recentlyAdded?.map((item) => (
                    <Col 
                      style={{ cursor: "pointer", width: "22%", minWidth: 250}}
                      onClick={() => handleClickOnUser(item)}
                      pull={0}
                    >
                      <UserCard
                        name={item?.name}
                        role={item?.role[0]}
                        privilage={item?.privilage}
                        avatar={`${IMG_URL}/${
                          item?.image ? item?.image : "default.png"
                        }`}
                        loc={'userDashboard'}
                      />
                    </Col>
                ))}
              </Row>
          </div>
        </div>
      ) : (
        <div>
          <h1 style={{ fontSize: "24px", fontWeight: "700" }}>
            User management
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 120
                
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <img
                  style={{ width: "90%", height: "90%", marginRight: "20px" }}
                  src={image3}
                  alt=""
                />
              </div>
              <h1 style={{ fontWeight: "700", marginBottom: "0" }}>
                No user added yet!
              </h1>
              <p style={{ color: "#636363" }}>
                Add users to your Institute to get started.
              </p>
              <Button
                onClick={showModal}
                type="primary"
                style={{
                  borderRadius: "16px",
                  width: "150px",
                  fontWeight: "500",
                }}
              >
                + ADD A USER 
              </Button>
            </div>
          </div>
        </div>
      )}

      {
        isModalVisible && <AddUsers
          isModalVisible={isModalVisible}
          showModal={showModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          showRoleModal={showRoleModal}
          addUserByRole={handleRoleOk}
          buttonLoader={buttonLoader}
          handleDone={()=>setIsModalVisible(false)}

        />
      }

      {isRoleModalVisible && (
        <AddStdTchrModal
          role="Student"
          isRoleModalVisible={isRoleModalVisible}
          showRoleModal={showRoleModal}
          handleRoleOk={handleRoleOk}
          handleRoleCancel={handleRoleCancel}
          buttonLoader={buttonLoader}
        />
      )}

      {isApprovalVisible && 
        <ApprovalModal
        setPageLoader={setPageLoader}
        isApprovalVisible={isApprovalVisible}
        showApprovalModal={showApprovalModal}
        handleApprovalCancel = {handleApprovalCancel}
        handleApprovalOk={handleApprovalOk}
        />
      }

      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

// export default UsersDashBoard

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, dashboardData, storeAddUserDetails } = state.userManagement;
  const { user } = state.session;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    user,
    storeAddUserDetails
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  getStats: (m) => dispatch(getStats(m)),
  setDashBoardRole: (role) => dispatch(setDashBoardRole(role)),
  addUserByRole: (callback, callbackLoader) =>
    dispatch(addUserByRole(callback, callbackLoader)),
  setPrivilage: (value) => dispatch(setPrivilage(value)),
  setEmail: (value) => dispatch(setEmail(value)),
  setPhone: (value) => dispatch(setPhone(value)),
  setUserName: (value) => dispatch(setUserName(value)),
  setClass: (value) => dispatch(setClass(value)),
  setRollNumber: (value) => dispatch(setRollNumber(value)),
  setClassId: (id) => dispatch(setClassId(id)),
  setNextRollNo: (role) => dispatch(setNextRollNo(role)),
  setTab: (m) => dispatch(setTab(m)),
  setStartDate: (date) => dispatch(setStartDate(date)),
  setEndDate: (date) => dispatch(setEndDate(date)),
  setRole: (role) => dispatch(setRole(role)),
  setSpecificUserId: (id) => dispatch(setSpecificUserId(id)),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
  clearAddUserFields: () => dispatch(clearAddUserFields()),
  downloadTemplate: (callback) => dispatch(downloadTemplate(callback)),
  storeAddUsers: (key,val) => dispatch(storeAddUsers(key,val)),
  storeExcelUploadDetails: (res) => dispatch(storeExcelUploadDetails(res)),
  storeAdduserFields: (key,val) => dispatch(storeAdduserFields(key,val)),
  clearAddUserFields: (key,val) => dispatch(clearAddUserFields(key,val)),
  showMenu: (bool) => dispatch(showMenu(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersDashBoard);