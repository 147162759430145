import { Modal } from 'antd';
import React from 'react';
import {Button} from 'components/Button';

const ConfirmLeaveDelete = ({onClose, visible, onDelete, deleteLoader}) => {
    return (
        <Modal
            open={visible}
            footer={false}
            className="modal-round-corner"
            closable={false}
        >
            <div className='r-c-c m-t-20'>
                <div style={{fontSize: 18, fontWeight: 500}}>Confirm</div>
            </div>
            <div className='r-c-c m-t-10'>
                <div style={{color: "#636363"}}>Are you sure you want to delete?</div>
            </div>
            <div className='r-c-c m-t-50'>
                <Button style={{width:180}} onClick={onClose}>NO</Button>
                <Button type="primary" style={{width:180, marginLeft: 20}} onClick={() => onDelete()} loading={deleteLoader}>YES</Button>
            </div>
            
        </Modal>
    );
}

export default ConfirmLeaveDelete;
