import { AddSaveLater, AddToCart, CartList, RemoveCart, RemoveSaveLater, applyPromo, fetchPromoCodes, payNowCart, setCartCount, setCouponInCart, setCoursePage, setLoader, storeSingleBuyNow } from 'actions/aulasMarket';
import { Button, Input, Modal, Rate, Typography } from 'antd';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import { generatePaymentRequest } from 'pages/profile/paymentGateway';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from 'services';
import CartIcon from "../../Assets/aulasMarket/Cart.svg";
import NoData from "../../Assets/aulasMarket/ill-no-data.svg";
import Footer from './footer';
import "./styles.css";
import TopBar from './topBar';

const { Text } = Typography;

export const SaveForLater = ({ cartSaveLater, setLoader, loginDetails, coursePageData, storeSingleBuyNow }) => {
    const token = loginDetails?.accessToken;
    const history = useHistory();
    const [removeModal, setRemoveModal] = useState();
    const [render, setRender] = useState(0)
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    console.log("lenth", coursePageData);

    const handleRemoveOk = () => {
        setLoader(true);
        RemoveSaveLater(token, removeModal, (res) => {
            if (res) {
                setRemoveModal(false);
            }
        }, setLoader)
    }

    const handleRemove = () => {
        setRemoveModal(false);
    }

    const CartList = ({ cartContent, key }) => {
        const handleAddToCart = (itemType, id) => {
            AddToCart(token, id, itemType, (res) => {
                setRender(1);
            }, setLoader);
        }
        const calculatedPriceOfCourse = cartContent?.course?.discountType === 'amount' ? cartContent?.course?.amount - cartContent?.course?.discount : (cartContent?.course?.amount - (cartContent?.course?.amount * (cartContent?.course?.discount / 100)))
        const calculatedPriceOfVideoResourse = cartContent?.videoResource?.discountType === 'amount' ? cartContent?.videoResource?.amount - cartContent?.videoResource?.discount : (cartContent?.videoResource?.amount - (cartContent?.videoResource?.amount * (cartContent?.videoResource?.discount / 100)))
        const calculatedPriceOfStudy = cartContent?.studyMaterial?.discountType === 'amount' ? cartContent?.studyMaterial?.amount - cartContent?.studyMaterial?.discount : (cartContent?.studyMaterial?.amount - (cartContent?.studyMaterial?.amount * (cartContent?.studyMaterial?.discount / 100)))
        // console.log('calculatedPrice', calculatedPrice);


        const name = cartContent ? (cartContent?.course?.courseName || cartContent?.videoResource?.courseName || cartContent?.studyMaterial?.name) : null;
        // const courseDescription =cartContent?.course?.brief  ? cartContent?.course?.brief : null;
        const courseDescription = 'now when i getcourseDescription ,i need to show in two lines if data is more  description-container height should set automatically '
        const courseImg = cartContent ? (cartContent?.course?.image || cartContent?.videoResource?.image) : null;
        const courseAmt = cartContent ? (cartContent?.course?.amount || cartContent?.videoResource?.amount) : null;
        const courseRating = cartContent ? (cartContent?.course?.rating || cartContent?.videoResource?.rating) : 0;
        const courseRatingValue = cartContent ? (cartContent?.course?.ratingValue || cartContent?.videoResource?.ratingValue) : 0;
        const courseRatingCount = cartContent ? (cartContent?.course?.ratingCount || cartContent?.videoResource?.ratingCount) : 0;
        console.log('lll', cartContent);

        return (
            <div className='savedContainer' style={{}}>
                <div style={{}}>
                    <img className="cart-img" style={{ objectFit: 'cover', verticalAlign: 'middle' }} src={`${Api.dlS3Url}${courseImg}`} />

                    <div className='cost-save font-bold' style={{ border: "1px solid black" }}>{'₹'}{calculatedPriceOfCourse || calculatedPriceOfVideoResourse || calculatedPriceOfStudy}</div>
                </div>
                <div className='course-container' >
                    <div className='text-sm font-bold'>{name}
                        <span style={{ color: "#FFA931", fontSize: 16, marginLeft: 10 }}>NEW</span>
                    </div>
                    <div className='m-t-10 description-container' title={courseDescription}>
                        {courseDescription}
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }}>
                            <div className='sellerButton2 m-t-5'>
                                <h style={{ fontWeight: '600', fontSize: 15 }}>Bestseller</h>
                            </div>
                            <div className='rating-container'>
                                <div style={{ color: "#FFA931", marginRight: '5px' }}>{courseRating || 0}</div>
                                <Rate disabled defaultValue={courseRatingValue || 0} style={{ color: "#FFA931", marginLeft: '5px' }} />
                                <div style={{ marginLeft: '5px', color: '#191919' }}>({courseRatingCount || 0} ratings)</div>
                            </div>
                        </div>



                        <div className='saved-btns' style={{}} >
                            {cartContent?.Live ? <div className='btn-reg-parent' style={{}}>
                                <Button className='btn-reg' style={{}}>REGISTER NOW</Button>
                            </div> :
                                <div className='btn-parent' style={{}} >
                                    <Button className='btn-add' onClick={() => handleAddToCart(cartContent?.itemType, cartContent?.courseId || cartContent?.videoResourceId)} style={{ fontWeight: 'bold' }}>ADD TO CART</Button>
                                    <Button className='btn-buy' onClick={() => { storeSingleBuyNow(cartContent?.course); history.push('/checkout'); }} >BUY NOW</Button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='r-c-fs m-t-10 text-xmd bold-600' style={{}}>
                        <div style={{ color: "#594099", cursor: "pointer", fontWeight: '500' }} onClick={() => {
                            setShareModalVisible(true);
                            setShareUrl(`${Api?._base_url}/course-page?id=${cartContent?.id}&type=${cartContent?.itemType}`)
                        }} ><img style={{ width: 18 }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} />&nbsp;&nbsp;SHARE</div>
                        <div className='m-l-20' style={{ cursor: "pointer", color: "#FF414D" }} onClick={() => setRemoveModal(cartContent?.id)}>REMOVE</div>
                    </div>
                </div>
            </div >
        )
    }

    const NoItems = () => {
        return (
            <div>
                <div className='r-c-c-c'>
                    <div><img src={NoData} /></div>
                    <div className='font-bold text-sm' style={{ color: "#191919" }}>No course saved!</div>
                    <div className='text-sm' style={{ color: "#636363" }}>You have not saved any courses yet!</div>
                    <div className='r-c-c m-t-20'>
                        <Button className='font-bold cursor-pointer' onClick={() => { history.push("/") }} style={{ color: "#594099", borderRadius: "100px" }}>EXPLORE COURSES</Button>
                    </div>
                </div>
            </div>
        )
    }
    console.log('ccccc', cartSaveLater);

    return (
        <div>
            <div style={{ backgroundColor: "#FAFAFA", marginTop: "40px" }}>
                <div className='main-container-parent'>
                    <div className={" "} style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
                        <div className='' style={{}} >
                            <div className='text-md font-bold m-b-10'>Saved for Later</div>
                            {Array.isArray(cartSaveLater) && cartSaveLater.length > 0 ? (
                                cartSaveLater.map((item) => (
                                    <CartList cartContent={item} key={item.id} coursePageData={coursePageData} />
                                ))
                            ) : (
                                <NoItems />
                            )}

                        </div>
                    </div>
                </div>

            </div>

            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}

            <Modal
                open={removeModal}
                footer={null}
                onCancel={handleRemove}
                onOk={handleRemoveOk}
                className="modal-round-corner"
                bodyStyle={{ borderRadius: "30px", padding: "20px", height: "auto" }}
            >
                <div style={{ marginBottom: '20px', fontSize: '23px', fontWeight: 'bold-400', fontFamily: 'Roboto', textAlign: 'center' }}>
                    Confirm
                </div>
                <div className='r-c-c text-xmd' style={{ color: "#636363", marginBottom: '20px' }}>
                    Are you sure you want to remove this course?
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div
                        className='text-sm bold-600'
                        style={{ color: "#FF414D", cursor: "pointer", marginRight: '10px' }}
                        onClick={handleRemoveOk}
                    >
                        REMOVE
                    </div>
                    <Button
                        className='bold-600'
                        onClick={handleRemove}
                        style={{
                            backgroundColor: "#594099",
                            width: "120px",
                            borderRadius: "100px",
                            color: "#FFFFFF",
                            fontSize: '15px',
                            paddingLeft: '15px',
                            paddingTop: '-15px',
                            marginLeft: 15

                        }}
                    >
                        CANCEL
                    </Button>
                </div>
            </Modal>




        </div>
    )
}




function ShoppingCart({ coursePageData, setCartCount, setCoursePage, fetchPromoCodes, setLoader, loader, loginDetails, promoCodeDetials, applyPromo, cartDetails, payNowCart, user, setCouponInCart, couponInCart, storeSingleBuyNow, storePromoPrice }) {
    const history = useHistory();
    const [render, setRender] = useState(0);
    const [promoCode, setPromoCode] = useState(promoCodeDetials)
    const token = loginDetails?.accessToken;
    setCartCount(coursePageData?.cart?.item?.length)
    const fetchCartList = () => {
        CartList(token, (res) => {
            setCoursePage(res);
        }, setLoader)
    }

    useEffect(() => {
        fetchCartList();
    }, [render])

    useEffect(() => {
        fetchPromoCodes(() => { });
    }, [])

    const EmptyCart = () => {
        return (
            <>
                <div className='r-c-c emptyCart'>
                    <div ><img src={CartIcon} /></div>
                    <div className='font-bold text-sm m-l-10'>Your cart is empty</div>
                </div>
            </>
        )
    }

    const CartMain = ({ applyPromo }) => {
        const [coupon, setCoupon] = useState(false);
        const [applyLoader, toggleApplyLoader] = useState(false);
        const [offerCode, setOfferCode] = useState('');
        const [paynowLoader, togglePaynowLoader] = useState(false);

        const fromMarket = true;

        const removeFromCart = (id) => {
            setLoader(true);
            RemoveCart(token, id, (res) => {
                if (res) {
                    setRender(1);
                }
            }, setLoader)
        };

        const handleSaveForLater = (id, itemType) => {
            AddSaveLater(token, id, itemType, (res) => {
                if (res) {
                }
            }, setLoader)
            toggleApplyLoader(true);
        }

        const handleApplyClick = () => {
            setCoupon(true);
            let params = {
                code: offerCode,
                type: 'offer'
            };
            toggleApplyLoader(true);

            console.log("hello");
            applyPromo(params, () => {
                toggleApplyLoader(false);
            }, () => {
                setCouponInCart(true);
                toggleApplyLoader(false)
            });
        }

        const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
        const handlePayNow = () => {
            let params = {
                orderAmount: couponInCart ? (storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount).toFixed(2) : (coursePageData?.cart?.amount - coursePageData?.cart?.discount).toFixed(2) || null,
                orderCurrency: 'INR',
                orderId: generateOrderId.toString() || null,
                customerDetails: {
                    customer_id: user?.id?.toString() || null,
                    customer_email: user?.email || null,
                    customer_phone: user?.phone?.toString() || null
                }
            };

            const callback = (response) => {
                togglePaynowLoader(false)
                generatePaymentRequest(response, null, user, fromMarket)
            }

            togglePaynowLoader(true)
            payNowCart(params, (response) => callback(response))
        }

        const CartList = ({ cartContent, key, coursePageData }) => {

            const name = cartContent ? (cartContent?.course?.courseName || cartContent?.videoResource?.courseName) : null;
            // const courseDescription = cartContent ? (cartContent?.course?.brief || cartContent?.videoResource?.brief) : null;
            const courseDescription = 'now when i getcourseDescription ,i need to show in two lines if data is more  description-container height should set automatically'

            const courseImg = cartContent ? (cartContent?.course?.image || cartContent?.videoResource?.image) : null;
            // const courseAmt = cartContent ? (cartContent?.course?.amount || cartContent?.videoResource?.amount) : null;
            const courseRating = cartContent ? (cartContent?.course?.rating || cartContent?.videoResource?.rating) : null;
            const courseRatingValue = cartContent ? (cartContent?.course?.ratingValue || cartContent?.videoResource?.ratingValue) : null;
            const courseRatingCount = cartContent ? (cartContent?.course?.ratingCount || cartContent?.videoResource?.ratingCount) : null;

            let calculatedPriceOfCourse = cartContent?.course?.discountType === 'amount' ? cartContent?.course?.amount - cartContent?.course?.discount : (cartContent?.course?.amount - (cartContent?.course?.amount * (cartContent?.course?.discount / 100)))
            let calculatedPriceOfVideoResourse = cartContent?.videoResource?.discountType === 'amount' ? cartContent?.videoResource?.amount - cartContent?.videoResource?.discount : (cartContent?.videoResource?.amount - (cartContent?.videoResource?.amount * (cartContent?.videoResource?.discount / 100)))
            let calculatedPriceOfStudy = cartContent?.studyMaterial?.discountType === 'amount' ? cartContent?.studyMaterial?.amount - cartContent?.studyMaterial?.discount : (cartContent?.studyMaterial?.amount - (cartContent?.studyMaterial?.amount * (cartContent?.studyMaterial?.discount / 100)))
            calculatedPriceOfCourse = isNaN(calculatedPriceOfCourse) ? 0 : Math.floor(calculatedPriceOfCourse * 10) / 10
            calculatedPriceOfVideoResourse = isNaN(calculatedPriceOfVideoResourse) ? 0 : Math.floor(calculatedPriceOfVideoResourse * 10) / 10
            calculatedPriceOfStudy = isNaN(calculatedPriceOfStudy) ? 0 : Math.floor(calculatedPriceOfStudy * 10) / 10


            return (
                <div className='cartContainer p-10' style={{}}>
                    <div>
                        <img className='cart-img' style={{}} src={`${Api.dlS3Url}${courseImg}`} />
                        <div className='cost-save r-c-c font-bold' style={{}}>{'₹'}{calculatedPriceOfCourse || calculatedPriceOfVideoResourse || calculatedPriceOfStudy}</div>

                    </div>
                    <div className='details' >
                        <div className='cart-title'>{name}
                            <span style={{ color: "#FFA931", fontSize: 16, marginLeft: 10 }}>NEW</span>
                        </div>
                        <div className='cart-des' title={courseDescription} >{courseDescription}</div>

                        <div style={{ display: 'flex' }}>

                            <div className='sellerButton2 m-t-5'>
                                <h style={{ fontWeight: '600', fontSize: 15 }}>Bestseller</h>
                            </div>
                            <div className='rating-container1'>
                                <div style={{ color: "#FFA931", marginRight: '5px', marginLeft: '25px' }}>{courseRating || 0}</div>
                                <Rate disabled defaultValue={courseRatingValue || 0} style={{ color: "#FFA931" }} />
                                <div style={{ marginLeft: '5px', color: '#191919' }}>({courseRatingCount || 0} ratings)</div>
                            </div>
                        </div>
                        <div className='cart-btns'>
                            <div className='cursor-pointer saveForLater' onClick={() => handleSaveForLater(cartContent?.courseId || cartContent?.videoResourceId, cartContent?.itemType)}>SAVE FOR LATER</div>
                            <div style={{ cursor: "pointer", marginLeft: '-50px' }} onClick={() => removeFromCart(cartContent?.id)}>REMOVE</div>
                        </div>
                    </div>
                </div>
            )
        }

        const totalAmount = storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount;
        const totalAmountBeforeDiscount = coursePageData?.cart?.amount - coursePageData?.cart?.discount;

        function numberWithCommas(x) {
            console.log('xx', x);
            return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
        }
        return (
            <>
                <div className='shopping-count'>{coursePageData?.cart?.items.length} Courses in Cart</div>
                <div className='shopping-parent' style={{}}>
                    <div>
                        {coursePageData?.cart?.items?.map((i) => (
                            <CartList cartContent={i} coursePageData={coursePageData} />
                        ))}
                    </div>
                    <div className='priceContainer' style={{}}>
                        <div className='r-c-fs p-10 ' style={{ color: "#AEAEAE", borderBottom: "1px solid #E6E6E6" }}>PRICE DETAILS</div>
                        <div className='p-10' style={{ color: "#636363", borderBottom: "1px solid #E6E6E6" }}>
                            {console.log("amount-details", storePromoPrice?.cart?.amount, coursePageData?.cart?.amount, couponInCart, storePromoPrice)}
                            <div className='r-c-sb'>Price <div>₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.amount?.toFixed(2)) : numberWithCommas(coursePageData?.cart?.amount.toFixed(2))}</div></div>
                            <div className='r-c-sb'>Discount <div style={{ color: "#28DF99" }}>₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.discount.toFixed(2)) : numberWithCommas(coursePageData?.cart?.discount.toFixed(2))}</div></div>
                        </div>
                        <div className='p-10' style={{ color: "#636363", borderBottom: "1px solid #E6E6E6" }}>
                            <div className='r-c-sb'>Total amount<div>₹{couponInCart ? numberWithCommas(totalAmount.toFixed(2)) : numberWithCommas(totalAmountBeforeDiscount.toFixed(2))}</div></div>
                        </div>
                        <div className='p-10' style={{ color: "#28DF99", borderBottom: "1px solid #E6E6E6" }}>
                            <div className='r-c-c'>You will save ₹{numberWithCommas(coursePageData?.cart?.discount.toFixed(2))} on this purchase</div>
                        </div>
                        <div className='p-10 m-t-10' >
                            {(couponInCart && storePromoPrice?.cart?.offerDiscount) || coursePageData?.cart?.offerDiscount ?
                                <div className='price-applied  ' style={{ alignItems: "flex-start" }}>
                                    <div className='r-c-c'><img style={{ marginTop: -20 }} src={require("../../Assets/aulasMarket/ic-check-filled.svg").default} />
                                        <div className='r-sfa m-l-10'>
                                            <div>You saved ₹{numberWithCommas((couponInCart ? storePromoPrice?.cart?.offerDiscount : coursePageData?.cart?.offerDiscount).toFixed(2))} with this code</div>
                                            <div style={{ color: "#AEAEAE" }}>Coupon Applied</div>
                                        </div>
                                    </div>
                                    <div style={{ color: '#FF414D', cursor: 'pointer' }} onClick={() => { setCouponInCart(false) }}>REMOVE</div>
                                    {/* <div style={{ color: '#FF414D', cursor: 'pointer' }} onClick={() => handleRemoveClick()}>REMOVE</div> */}

                                </div>
                                :
                                <div className=' r-c-c' style={{ display: 'flex' }}>
                                    <Input
                                        className='price-coupon-filed'
                                        type="text"
                                        placeholder='Enter coupon code'
                                        onChange={(e) => setOfferCode(e.target.value)}
                                        value={offerCode}
                                    ></Input>
                                    <Button
                                        loading={applyLoader}
                                        className='r-c-c text-xm bold-600 applyButton'
                                        type="button"
                                        onClick={handleApplyClick}
                                        style={{
                                            cursor: 'pointer',
                                            border: '1px solid #594099',
                                            borderTopRightRadius: '5px',
                                            borderBottomRightRadius: '5px',
                                            backgroundColor: 'white',
                                            padding: '5px 10px',
                                            color: "#594099",
                                            height: "6vh",
                                            opacity: 0.4
                                        }}
                                    >
                                        APPLY
                                    </Button>
                                </div>
                            }
                        </div>
                        <div className='r-c-c m-t-20'>
                            <Button loading={paynowLoader} onClick={() => handlePayNow()} className='btn-buy' >PAY NOW</Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (

        <>
            <div className="top-bar">
                <TopBar />
            </div>
            <div style={{ backgroundColor: "#FAFAFA" }}>
                <div className='main-container-parent'>

                    {/* {promoCode?.length == 0 ? null :
                        <>
                            <div className='discount-banner' style={{}}>
                            </div>
                            <div className='r-c-sb discount-container' style={{}}>
                                {promoCode[0]?.discountType == 'percent' ?
                                    <div className='text-mdl ' style={{ opacity: 1, color: "#594099" }}>Avail discount of <b style={{ fontWeight: 'bold' }}>{promoCode[0]?.discount}%</b></div>
                                    : <div className='text-mdl ' style={{ opacity: 1, color: "#594099" }}>Avail discount of <b style={{ fontWeight: 'bold' }}>₹{promoCode[0]?.discount}</b></div>
                                }
                                <div className='text-xmd promoCard' style={{}}>
                                    <span className='m-r-10' style={{ display: "flex", alignItems: "center" }}>Use the promotional offer <Paragraph className='copyable-paragraph' copyable >{promoCode[0]?.code}</Paragraph> to get an instant discount</span>
                                    <div className='r-c-c validity-tag'>Valid until {moment(promoCode[0]?.endDate).format('DD-MM-YYYY')}</div>
                                </div>
                            </div>
                        </>
                    } */}

                    <div className="main-container" style={{ marginTop: "0px" }}>
                        <div className='shopping-header text-md font-bold' >Cart</div>
                        {coursePageData?.cart && coursePageData?.savedItems ? (
                            <>
                                <CartMain setCoursePage={setCoursePage} coursePageData={coursePageData} setLoader={setLoader} history={history} applyPromo={applyPromo} promoCode={promoCode} />
                                <SaveForLater cartSaveLater={coursePageData?.savedItems} setLoader={setLoader} coursePageData={coursePageData} storeSingleBuyNow={storeSingleBuyNow} />
                            </>
                        ) : (
                            coursePageData?.cart ?
                                <>
                                    <CartMain setCoursePage={setCoursePage} coursePageData={coursePageData} setLoader={setLoader} history={history} applyPromo={applyPromo} promoCode={promoCode} />
                                </>
                                : <EmptyCart />)}
                        {coursePageData?.cart === null ?
                            (
                                <SaveForLater cartSaveLater={coursePageData?.savedItems} setLoader={setLoader} coursePageData={coursePageData} />
                            ) : <savedLater setLoader={setLoader} />}

                    </div >
                </div>
                <div className='m-t-100' style={{}}>
                    <Footer />
                </div>

                <PageDataLoader visible={loader} />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { coursePageData, loader, loginDetails, promoCodeDetials, cartDetails, couponInCart, storePromoPrice } = state.aulasMarket
    const { user } = state.session
    return { coursePageData, loader, loginDetails, promoCodeDetials, cartDetails, user, couponInCart, storePromoPrice }
};

const mapDispatchToProps = (dispatch) => ({
    setCoursePage: (val) => dispatch(setCoursePage(val)),
    setLoader: (val) => dispatch(setLoader(val)),
    applyPromo: (params, callback, succesCallback) => dispatch(applyPromo(params, callback, succesCallback)),
    payNowCart: (params, callback) => dispatch(payNowCart(params, callback)),
    fetchPromoCodes: (callback) => dispatch(fetchPromoCodes(callback)),
    setCartCount: (val) => dispatch(setCartCount(val)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
