import {
	ExclamationCircleOutlined,
	LeftOutlined,
	LoadingOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, PageHeader, Row, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Api, Color, Notification } from "services";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import TeX from "@matejmazur/react-katex";
import "./styles.css";
import {
	fetchTestSolutions,
	fetchTestSummary,
	handleSubjectSelection,
	setCurrentIndex,
	setCurrentStudentQResult,
} from "actions/studentTest";
import PageDataLoader from "components/pageDataLoader";
import ImageViewer from "components/ImageViewer";
import { AiOutlineFileText } from "react-icons/ai";
import { QuestionStatement, QuestionTypes } from "./components/questionTypes";
import QuestionPaletteResult from "./components/QuestionPaletteResult";

const { confirm } = Modal;

const answerStatus = [
	{
		title: "Correct",
		key: "correct",
		background: "#28DF99",
		border: `1px solid ${Color.midGrey}`,
		color: "white",
		gradient: false,
	},
	{
		title: "Incorrect",
		key: "incorrect",
		background: Color.wrong,
		border: "none",
		color: "white",
		gradient: false,
	},
	{
		title: "Partially correct",
		key: "partialCorrect",
		background: "linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)",
		border: "none",
		color: "white",
		gradient: false,
	},
	{
		title: "Unattempted",
		key: "unattempted",
		background: "#AEAEAE",
		border: "none",
		color: "black",
		gradient: false,
	},
	{
		title: "Unseen",
		key: "unseen",
		background: "#FFFFFF",
		border: "1px solid black",
		color: "black",
		gradient: false,
	},
];

const legand = (solutionCounts, selectedTest, questionsContainer) => {
	// console.log("solutionCounts", solutionCounts)
	const unseencount = solutionCounts
		? questionsContainer.length -
		(solutionCounts.correct +
			solutionCounts.incorrect +
			solutionCounts.partialCorrect +
			solutionCounts.unattempted)
		: 0;
	return (
		<div className="legendPalette">
			{answerStatus?.map((item, index) => (
				<>
					{item.gradient && selectedTest.testType == "Objective" ? null : (
						<div className="answerTypeContainer" key={index.toString()}>
							<div
								className="countBadge r-c-c"
								style={{
									background: item.background,
									border: item.border,
									color: item.color,
									borderRadius: "50%",
									maxWidth: "50%",
								}}
							>
								<div>
									{solutionCounts != null ? solutionCounts[item.key] : 0}
								</div>
							</div>
							<div style={{ maxWidth: "50%", fontSize: "1vw" }}>
								{" "}
								{item.title}
							</div>
						</div>
					)}
				</>
			))}
		</div>
	);
};

const singleQuestion = (
	currentQ,
	currentIndex,
	setStudentAns,
	beforeUpload,
	uploadImage,
	base64Images,
	removeAnswerImage,
	toggleImageModal,
	imgLoader,
	testType,
	quesType,
	setImgUrl,
	setImageViewerVisibility
) => {
	const isEmptyHtml = (content) => {
		let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
		return pattern.test(content);
	};

	let myQuestionContent = [];

	if (currentQ?.type == "OrQuestions") {
		currentQ?.questionContent?.orQuestions?.map((item, index) => {
			myQuestionContent.push(item);

			//may modify redux state
			myQuestionContent[index]["submitStatus"] = null;
			myQuestionContent[index]["answerContent"] = currentQ.answerContent?.[index];
		});

		if (currentQ?.answerObj?.orQuestionIndex != undefined) {
			myQuestionContent[currentQ?.answerObj?.orQuestionIndex]["answerObj"] =
				currentQ?.answerObj;
			myQuestionContent[currentQ?.answerObj?.orQuestionIndex]["submitStatus"] =
				currentQ?.submitStatus;
		}
	} else if (currentQ?.type == "Comprehensive") {
		currentQ?.questionContent?.subQuestions?.map((item, index) => {
			myQuestionContent.push(item);

			//may modify redux state
			if(myQuestionContent[index] != undefined)
			{
				myQuestionContent[index]["submitStatus"] = null;
				myQuestionContent[index]["answerContent"] = currentQ?.answerContent?.[index];
			}
		});

		currentQ?.answerObj?.map((item, index) => {
			if(item?.subQuestionIndex != undefined)
			{
				myQuestionContent[item.subQuestionIndex]["answerObj"] = item;
				myQuestionContent[item.subQuestionIndex]["submitStatus"] =
				currentQ?.submitStatus;
			}
		});
	}

	// console.log(myQuestionContent, 'myQuestionContent')

	return (
		<>
			{currentQ?.type == "Numeric" ||
				currentQ?.type == "MCQ" ||
				currentQ?.type == "MSQ" ||
				currentQ?.type == "Subjective" ||
				currentQ?.type == "T/F" ? (
				<>
					<div className="questionStatement" style={{ marginLeft: 8 }}>
						<QuestionStatement
							type={currentQ?.type}
							data={currentQ.questionContent?.statement}
							toggleImageModal={toggleImageModal}
						/>
					</div>

					{currentQ?.type != "Subjective" && (
						<div className="answerBox" id="solutionBox">
							{" "}
							{answerBox(currentQ, setImgUrl, setImageViewerVisibility)}
						</div>
					)}
				</>
			) : null}

			{currentQ?.type == "OrQuestions" || currentQ?.type == "Comprehensive" ? (
				<div>
					{/* outer question statement */}
					{currentQ?.type == "Comprehensive" ? (
						<div className="questionStatement" style={{ marginLeft: 8 }}>
							<QuestionStatement
								toggleImageModal={toggleImageModal}
								type={currentQ?.type}
								data={currentQ.questionContent?.paragraph}
							/>
						</div>
					) : null}

					{/* inner questions */}
					{myQuestionContent?.map((item, index) => (
						<div style={{ marginBottom: 20 }}>
							<div className="questionStatement" style={{ marginLeft: 2 }}>
								{currentQ?.type == "Comprehensive" ? (
									<div
										style={{
											fontWeight: 600,
											color: "#636363",
											marginBottom: 5,
										}}
									>{`Question ${currentIndex + 1} (${index + 1})`}</div>
								) : (
									<div
										style={{
											fontWeight: 600,
											color: "#636363",
											marginBottom: 5,
										}}
									>
										{index == 0
											? `Question ${currentIndex + 1}(a)`
											: `Question ${currentIndex + 1}(b)`}
									</div>
								)}

								<QuestionStatement
									toggleImageModal={toggleImageModal}
									type={item?.type}
									data={item?.questionContent?.statement}
								/>
							</div>

							<div className="answerBox" id="solutionBox">
								{" "}
								{answerBox(item, setImgUrl, setImageViewerVisibility)}
							</div>


							{/* inner solutions */}
							{item?.solution ? (
								<div className="answerBox m-t-20 m-l-10">
									<div className="r-ac m-b-20">
										<AiOutlineFileText className="color-purple text-sm m-r-5" />
										<div className="text-xmd bold-600 color-purple"> SOLUTION</div>
									</div>
									<div>
										{ReactHtmlParser(item?.solution, {
											transform: (node) => {
												if (
													node.type === "tag" &&
													node.name === "span" &&
													node.attribs["data-value"]
												) {
													return <TeX math={`${node.attribs["data-value"]}`} />;
												}
												if (node.type === "tag" && node.name === "img") {
													return (
														<img
															style={{ maxHeight: "40vh", maxWidth: "40vh" }}
															src={node.attribs.src}
															alt=""
															onClick={() => {
																setImgUrl(node.attribs.src);
																setImageViewerVisibility(true);
															}}
															className="cursor-pointer"
														/>
													);
												}
											},
										})}
									</div>
								</div>
							) : null}


							{/* this condition is to add HORIZONTAL OR LINE */}
							{currentQ?.type == "OrQuestions" &&
								index < myQuestionContent.length - 1 ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: 20,
									}}
								>
									<div
										style={{
											width: "40%",
											height: 1,
											borderBottom: "1px solid black",
											padding: "0 5px",
										}}
									></div>
									<span style={{ marginTop: -10, padding: "0px 10px" }}>
										OR
									</span>
									<div
										style={{
											width: "40%",
											height: 1,
											borderBottom: "1px solid black",
										}}
									></div>
								</div>
							) : null}
						</div>
					))}
				</div>
			) : null}

			{/* Subjective */}
			{testType != "Missed" && currentQ?.type == "Subjective" ? (
				currentQ && currentQ.filename != null ? (
					<div className="answerBox m-t-20">
						<div className="p-20 m-b-40">
							<div className="text-xmd bold-600 color-black">
								Uploaded Answer
							</div>
							<div className="r-ac" style={{ overflowX: "auto" }}>
								{currentQ?.filename?.split(",")?.map((item, index) => (
									<img
										src={`${Api._s3_url}${item}`}
										alt={""}
										text-align="left"
										style={{ display: "block", maxHeight: "160px", maxWidth: "160px" }}
										className="m-t-10 m-r-10 cursor-pointer"
										onClick={() => {
											setImgUrl(`${Api._s3_url}${item}`);
											setImageViewerVisibility(true);
										}}
									/>
								))}
							</div>
						</div>
					</div>
				) : null
			) : null}

			{currentQ?.type === "Subjective" ? (
				<div className="m-t-20 m-l-20">
					<div className="r-ac m-b-10">
						<div className="text-xmd bold-600 color-purple">Comments</div>
					</div>
					{currentQ?.comment != undefined && currentQ?.comment?.trim() !== "" ? (
						<div
							className="test-ques-window resize-editor-img p-10"
							style={{ width: "95%" }}
						>
							{ReactHtmlParser(currentQ?.comment, {
								transform: (node) => {
									if (
										node.type === "tag" &&
										node.name === "span" &&
										node.attribs["data-value"]
									) {
										return <TeX math={`${node.attribs["data-value"]}`} />;
									}
									if (node.type === "tag" && node.name === "img") {
										return (
											<img
												style={{ maxHeight: "40vh", maxWidth: "40vh" }}
												src={node.attribs.src}
												alt=""
											/>
										);
									}
								},
							})}
						</div>
					) : (
						<div className="m-t-10">NA</div>
					)}
				</div>
			) : null}

			{/* {!isEmptyHtml(currentQ?.solution) ? ( */}
			{currentQ?.solution ? (
				<div className="answerBox m-t-20 m-l-10">
					<div className="r-ac m-b-20">
						<AiOutlineFileText className="color-purple text-sm m-r-5" />
						<div className="text-xmd bold-600 color-purple"> SOLUTION</div>
					</div>
					<div>
						{ReactHtmlParser(currentQ?.solution, {
							transform: (node) => {
								if (
									node.type === "tag" &&
									node.name === "span" &&
									node.attribs["data-value"]
								) {
									return <TeX math={`${node.attribs["data-value"]}`} />;
								}
								if (node.type === "tag" && node.name === "img") {
									return (
										<img
											style={{ maxHeight: "40vh", maxWidth: "40vh" }}
											src={node.attribs.src}
											alt=""
											onClick={() => {
												setImgUrl(node.attribs.src);
												setImageViewerVisibility(true);
											}}
											className="cursor-pointer"
										/>
									);
								}
							},
						})}
					</div>
				</div>
			) : null}
		</>
	);
};

const customInputElement = (placeholder, color, comment) => {
	if (placeholder === true) placeholder = "True";
	else if (placeholder === false) placeholder = "False";
	else; //type is numeric

	return (
		<Input
			style={{
				backgroundColor:
					color === "Correct"
						? "#E9FCF5"
						: color === "Incorrect"
							? "#FFE4D9"
							: "",
				margin: 20,
				marginTop: 10,
				width: "95%",
			}}
			value={placeholder}
			suffix={
				<div className="answerType">
					<img
						style={{ width: "0.7rem", height: "0.7rem", fontSize: 12 }}
						src={
							color === "Correct"
								? require("Assets/ic-attempted.svg").default
								: require("Assets/CloseCircle.svg").default
						}
					></img>
					<span
						style={{
							color:
								color === "Correct"
									? "green"
									: color === "Incorrect"
										? "red"
										: "",
							fontSize: 12,
						}}
					>
						{" "}
						{comment}{" "}
					</span>
				</div>
			}
			disabled
		/>
	);
};

const optionElement = (
	optionType,
	option,
	index,
	setImgUrl,
	setImageViewerVisibility
) => {
	if (optionType == "correctanswer-but-notstudentanswer") {
		return (
			<div className="optionCard greenOptionCard" key={index}>
				<div className="answerType">
					<span>
						<img
							style={{ width: "0.7rem", height: "0.7rem" }}
							src={require("Assets/ic-attempted.svg").default}
						></img>
						<span style={{ color: "green", fontSize: 12 }}>
							{" "}
							Correct answer
						</span>
					</span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c greenRadioButton">
						{String.fromCharCode(65 + index)}
					</div>
					<div className="answerOptionKey">
						{ReactHtmlParser(option?.value, {
							transform: (node) => {
								if (
									node.type === "tag" &&
									node.name === "span" &&
									node.attribs["data-value"]
								) {
									return <TeX math={`${node.attribs["data-value"]}`} />;
								}
								if (node.type === "tag" && node.name === "img") {
									return (
										<img
											style={{ maxHeight: "40vh", maxWidth: "40vh" }}
											src={node.attribs.src}
											alt=""
											className="cursor-pointer"
											onClick={() => {
												setImgUrl(node.attribs.src);
												setImageViewerVisibility(true);
											}}
										/>
									);
								}
							},
						})}
					</div>
				</div>
			</div>
		);
	} else if (optionType == "correctanswer-and-studentanswer") {
		return (
			// <div className="optionCards">
			<div className="optionCard greenOptionCard" key={index}>
				<div className="answerType">
					<span>
						<img
							style={{ width: "0.7rem", height: "0.7rem" }}
							src={require("Assets/ic-attempted.svg").default}
						></img>
						<span style={{ color: "green", fontSize: 12 }}> Your answer </span>
					</span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c greenRadioButton">
						{String.fromCharCode(65 + index)}{" "}
					</div>
					<div className="answerOptionKey">
						{ReactHtmlParser(option?.value, {
							transform: (node) => {
								if (
									node.type === "tag" &&
									node.name === "span" &&
									node.attribs["data-value"]
								) {
									return <TeX math={`${node.attribs["data-value"]}`} />;
								}
								if (node.type === "tag" && node.name === "img") {
									return (
										<img
											style={{ maxHeight: "40vh", maxWidth: "40vh" }}
											src={node.attribs.src}
											alt=""
											className="cursor-pointer"
											onClick={() => {
												setImgUrl(node.attribs.src);
												setImageViewerVisibility(true);
											}}
										/>
									);
								}
							},
						})}
					</div>
				</div>
			</div>
			// </div>
		);
	} else if (optionType == "studentanswer-but-notcorrectanswer") {
		return (
			// <div className="optionCards">
			<div
				className="optionCard correctanswer-and-studentanswer redOptionCard"
				key={index}
			>
				<div className="answerType">
					<span>
						<img
							style={{ width: "0.7rem", height: "0.7rem" }}
							src={require("Assets/CloseCircle.svg").default}
						></img>
						<span style={{ color: "red", fontSize: 12 }}> Your answer </span>
					</span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c redRadioButton">
						{String.fromCharCode(65 + index)}{" "}
					</div>
					<div className="answerOptionKey">
						{ReactHtmlParser(option?.value, {
							transform: (node) => {
								if (
									node.type === "tag" &&
									node.name === "span" &&
									node.attribs["data-value"]
								) {
									return <TeX math={`${node.attribs["data-value"]}`} />;
								}
								if (node.type === "tag" && node.name === "img") {
									return (
										<img
											style={{ maxHeight: "40vh", maxWidth: "40vh" }}
											src={node.attribs.src}
											alt=""
											className="cursor-pointer"
											onClick={() => {
												setImgUrl(node.attribs.src);
												setImageViewerVisibility(true);
											}}
										/>
									);
								}
							},
						})}
					</div>
				</div>
			</div>
			// </div>
		);
	} else {
		return (
			// <div className="optionCards">
			<div className="optionCard" key={index}>
				<div className="answerType">
					<span></span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c">
						{String.fromCharCode(65 + index)}{" "}
					</div>
					<div className="answerOptionKey">
						{ReactHtmlParser(option?.value, {
							transform: (node) => {
								if (
									node.type === "tag" &&
									node.name === "span" &&
									node.attribs["data-value"]
								) {
									return <TeX math={`${node.attribs["data-value"]}`} />;
								}
								if (node.type === "tag" && node.name === "img") {
									return (
										<img
											style={{ maxHeight: "40vh", maxWidth: "40vh" }}
											src={node.attribs.src}
											alt=""
											className="cursor-pointer"
											onClick={() => {
												setImgUrl(node.attribs.src);
												setImageViewerVisibility(true);
											}}
										/>
									);
								}
							},
						})}
					</div>
				</div>
			</div>
			// </div>
		);
	}
};

const answerBox = (currentQ, setImgUrl, setImageViewerVisibility) => {
	if (currentQ?.type === "Subjective") {
		return <></>;
	} else if (currentQ?.type === "MCQ") {
		let optionTypes = [];

		for (
			let index = 0;
			index <= currentQ.questionContent.options.length;
			index++
		) {
			optionTypes.push(null);

			if (
				currentQ.submitStatus === "Attempted" ||
				currentQ.submitStatus === "AttemptedAndBookmarked"
			) {
				if (index == currentQ?.answerContent.answer) {
					optionTypes[index] =
						index == currentQ?.answerObj?.answer
							? "correctanswer-and-studentanswer"
							: "correctanswer-but-notstudentanswer";
				} else if (index == currentQ?.answerObj?.answer)
					optionTypes[index] = "studentanswer-but-notcorrectanswer";
				else; //do nothing
			} else if (index == currentQ?.answerContent?.answer)
				optionTypes[index] = "correctanswer-but-notstudentanswer";
			else; //do nothing
		}

		return (
			<div className="optionCards">
				{currentQ?.questionContent?.options?.map((option, index) =>
					optionElement(
						optionTypes[index],
						option,
						index,
						setImgUrl,
						setImageViewerVisibility
					)
				)}
			</div>
		);
	} else if (currentQ?.type === "MSQ") {
		let optionTypes = [];

		for (
			let index = 0;
			index <= currentQ.questionContent.options.length;
			index++
		) {
			optionTypes.push(null);

			if (
				currentQ.submitStatus === "Attempted" ||
				currentQ.submitStatus === "AttemptedAndBookmarked"
			) {
				if (currentQ?.answerContent?.answers?.includes(index)) {
					optionTypes[index] =
						index == currentQ?.answerObj?.answer
							? "correctanswer-and-studentanswer"
							: "correctanswer-but-notstudentanswer";
				} else if (currentQ?.answerObj?.answers?.includes(index))
					optionTypes[index] = "studentanswer-but-notcorrectanswer";
				else; //do nothing
			} else if (currentQ?.answerContent?.answers?.includes(index))
				optionTypes[index] = "correctanswer-but-notstudentanswer";
			else; //do nothing
		}

		return (
			<div className="optionCards">
				{currentQ?.questionContent?.options?.map((option, index) =>
					optionElement(
						optionTypes[index],
						option,
						index,
						setImgUrl,
						setImageViewerVisibility
					)
				)}
			</div>
		);
	} else if (currentQ?.type === "Numeric" || currentQ?.type === "T/F") {
		let answerInputs = [];

		if (
			currentQ.submitStatus === "Attempted" ||
			currentQ.submitStatus === "AttemptedAndBookmarked"
		) {
			if (currentQ?.answerContent?.answer === currentQ?.answerObj?.answer) {
				answerInputs.push(
					customInputElement(
						currentQ?.answerContent?.answer,
						"Correct",
						"Your answer"
					)
				);
			} else {
				answerInputs.push(
					customInputElement(
						currentQ?.answerObj?.answer,
						"Incorrect",
						"Your answer"
					)
				);
				answerInputs.push(
					customInputElement(
						currentQ?.answerContent?.answer,
						"Correct",
						"Correct answer"
					)
				);
			}
		} else {
			answerInputs.push(
				customInputElement(
					currentQ?.answerContent?.answer,
					"Correct",
					"Correct answer"
				)
			);
		}

		return (
			<>
				<div style={{ marginLeft: "20px", marginTop: "25px" }}>
					<span style={{ fontWeight: "bold" }}> Answer </span>
				</div>

				{answerInputs?.map((item) => item)}
			</>
		);
	} else {
		return null;
	}
};

function TestSolutions({
	currentQIndex,
	currentQ,
	setStudentAnswer,
	updateImage,
	base64Images,
	removeAnswerImage,
	fetchTestSolutions,
	fetchTestSummary,
	palateData,
	solutionCounts,
	selectedTest,
	solutionSubjects,
	currentIndex,
	setCurrentIndex,
	handleSubjectSelection,
	questionsContainer,
	setCurrentStudentQResult,
	testType,
	quesType,
	testSubjects,
}) {
	const history = useHistory();
	const [showImgModal, toggleImgModal] = useState(false);
	// const [currentQ1, setCurrentQ1] = useState(currentQs[0]);
	const [currentImg, setCurrentImg] = useState("");
	const [imgLoader, toggleImageLoader] = useState(false);
	const [pageLoader, togglePageLoader] = useState(false);
	const [buttonLoader, toggleButtonLoader] = useState(false);
	const [selectedStatus, updateSelectedStatus] = useState("");
	const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
	const [imgUrl, setImgUrl] = useState("");

	useEffect(() => {
		setCurrentIndex(0);
		//index = 1, loaderCallback, buttonLoaderCallBack
		fetchTestSolutions(
			null,
			(bool) => togglePageLoader(bool),
			(bool) => { }
		);
		// fetchTestSummary((bool) => togglePageLoader(bool));
		return () => { };
	}, []);

	useEffect(() => {
		setCurrentStudentQResult(questionsContainer[currentIndex]);
		updateSelectedStatus("");
	}, [currentIndex]);

	const [closed, setClosed] = useState({});

	useEffect(() => {
		const closed_obj = {};
		testSubjects?.map((item, index) => {
			closed_obj[item?.name] = false;
		});
		setClosed(closed_obj);
	}, [testSubjects]);

	const setStudentAns = (answer) => {
		setStudentAnswer(answer);
	};

	const handleNavigateClick = (direction, index) => {
		// navigateSolution(direction)
		updateSelectedStatus(direction);
		onQuestionSelect(index);
	};

	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			Notification.error("error", "You can only upload JPG/PNG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			Notification.error("error", "Image must smaller than 2MB!");
		}
		return isJpgOrPng && isLt2M;
	};

	const handlePaletteClick = (index) => {
		updateSelectedStatus("");
		onQuestionSelect(index);
	};
	const onQuestionSelect = (index) => {
		if (index === currentQIndex) {
			return;
		}
		toggleButtonLoader(true);
		setCurrentIndex(index);
	};
	const handleSubSelect = (index) => {
		updateSelectedStatus("");
		handleSubjectSelection(index, (bool) => togglePageLoader(bool));
	};
	const buttonLoaderCallBack = () => {
		toggleButtonLoader(false);
		updateSelectedStatus("");
	};

	const uploadImage = (info) => {
		if (info.file?.status === "uploading") {
			toggleImageLoader(true);
			return;
		}
		if (info.file) {
			getBase64(info.file.originFileObj, (imageUrl) => {
				updateImage(imageUrl, info.file.originFileObj);
				toggleImageLoader(false);
			});
		}
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const toggleImageModal = (bool, index) => {
		toggleImgModal(bool);
		if(index != -1) setCurrentImg(base64Images[index]);
	};
	const formatTime = () => {
		return [
			Math.floor(currentQ.duration / 60).toLocaleString("en-US", {
				minimumIntegerDigits: 2,
				useGrouping: false,
			}),
			(currentQ.duration % 60).toLocaleString("en-US", {
				minimumIntegerDigits: 2,
				useGrouping: false,
			}),
		];
	};
	

	return (
		<div className="TestSolutions" style={{ height: "100%" }}>
			<PageHeader
				style={{ backgroundColor: "#F6F4FE" }}
				title={<span> {selectedTest.title} </span>}
				onBack={() => history.goBack()}
				extra={
					<div className="leftInnerNav">
						<div className="innerNavBtn">
							{testSubjects?.map((item, index) => (
								<Button
									key={index}
									onClick={() => {
										handleSubSelect(index);
									}}
									style={
										currentQ?.subjectName === item?.name
											? {
												backgroundColor: "#E6E6E6",
												color: "#191919",
												border: "none",
												width: 120,
											}
											: { width: 120 }
									}
								>
									<div
										style={{
											width: "100%",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{item.name}
									</div>
								</Button>
							))}
						</div>
					</div>
				}
			/>
			<div className="testContainer">
				<div className="lqPanel">
					<div
						style={{
							height: "100%",
							borderRight: `1px solid ${Color.midGrey}`,
						}}
					>
						<div className="questionContainer">
							<div className="questionHeader">
								<span className="m-t-20">{currentIndex + 1}</span>
								<div className="leftText r-c-sa">
									{currentQ.submitStatus == "Unanswered" ||
										currentQ.submitStatus == "Unseen" ||
										currentQ.submitStatus == "Bookmarked" ? (
										<span style={{ color: "#AEAEAE" }}>
											{" "}
											Marks obtained: {currentQ?.marksObtained ?? 0}{" "}
										</span>
									) : currentQ.submitStatus == "Attempted" ||
										currentQ.submitStatus == "AttemptedAndBookmarked" ? (
										currentQ.marksObtained ? (
											currentQ.marksObtained >= 0 ? (
												<span style={{ color: "#AEAEAE" }}>
													{" "}
													Marks obtained:{" "}
													<span style={{ color: "green", fontWeight: 750 }}>
														{" "}
														{currentQ.marksObtained}{" "}
													</span>
												</span>
											) : (
												<span style={{ color: "#AEAEAE" }}>
													{" "}
													Marks obtained:{" "}
													<span style={{ color: "red", fontWeight: 750 }}>
														{" "}
														{currentQ.marksObtained}{" "}
													</span>{" "}
												</span>
											)
										) : (
											<span style={{ color: "#AEAEAE" }}>
												{" "}
												Marks obtained: 0{" "}
											</span>
										)
									) : (
										<span style={{ color: "#AEAEAE" }}>
											{" "}
											Marks obtained: 0{" "}
										</span>
									)}

									<span>
										{" "}
										{formatTime()[0] != "NaN" ? formatTime()[0] : "0"} mins :{" "}
										{formatTime()[1] != "NaN" ? formatTime()[1] : "0"} secs{" "}
									</span>
								</div>
							</div>

							<div className="qaSection">
								{singleQuestion(
									currentQ,
									currentIndex,
									setStudentAns,
									beforeUpload,
									uploadImage,
									base64Images,
									removeAnswerImage,
									toggleImageModal,
									imgLoader,
									testType,
									quesType,
									setImgUrl,
									setImageViewerVisibility
								)}
							</div>
						</div>

						<div className="actionContainer">
							<div className="footerBtns">
								<div className="qNavBtn r-c-sb" style={{ width: "100%" }}>
									<Button
										style={{ border: "none", backgroundColor: "transparent" }}
										disabled={currentIndex <= 0}
										onClick={() => {
											handleNavigateClick("prev", currentIndex - 1);
										}}
									>
										{selectedStatus === "prev" && buttonLoader ? (
											<LoadingOutlined />
										) : (
											<LeftOutlined />
										)}
										PREVIOUS
									</Button>
									<Button
										style={{ border: "none", backgroundColor: "transparent" }}
										disabled={currentIndex >= palateData?.length - 1}
										onClick={() => {
											handleNavigateClick("next", currentIndex + 1);
										}}
									>
										{" "}
										{selectedStatus == "next" && buttonLoader ? (
											<LoadingOutlined />
										) : (
											<RightOutlined />
										)}
										NEXT
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="rqPanel">
					<div style={{ height: "100%" }}>
						{legand(solutionCounts, selectedTest, questionsContainer)}
						<QuestionPaletteResult
							handlePaletteClick={handlePaletteClick}
							palateData={palateData}
							testSubjects={testSubjects}
						/>
					</div>
				</div>
			</div>
			{console.log("img", imgUrl)}
			{isImageViewerVisible && (
				<ImageViewer
					isPreviewModalVisible={isImageViewerVisible}
					image={imgUrl}
					setIsPreviewModalVisible={setImageViewerVisibility}
				/>
			)}
			<PageDataLoader visible={pageLoader} />
		</div>
	);
}

const mapStateToProps = (state) => {
	const {
		questionsList,
		currentQIndex,
		currentQ,
		base64Images,
		durationSeconds,
		time,
		qCounts,
		palateData,
		solutionCounts,
		selectedTest,
		solutionSubjects,
		currentIndex,
		questionsContainer,
		testType,
		quesType,
		testSubjects,
	} = state.studentTest;
	return {
		questionsList,
		currentQIndex,
		currentQ,
		base64Images,
		durationSeconds,
		time,
		qCounts,
		palateData,
		solutionCounts,
		selectedTest,
		solutionSubjects,
		currentIndex,
		questionsContainer,
		testType,
		quesType,
		testSubjects,
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchTestSolutions: (index, loaderCallback, buttonLoaderCallBack) =>
		dispatch(fetchTestSolutions(index, loaderCallback, buttonLoaderCallBack)),
	setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
	fetchTestSummary: (loaderCallback) =>
		dispatch(fetchTestSummary(loaderCallback)),
	handleSubjectSelection: (index, loaderCallback) =>
		dispatch(handleSubjectSelection(index, loaderCallback)),
	setCurrentStudentQResult: (currentQuesData) =>
		dispatch(setCurrentStudentQResult(currentQuesData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestSolutions);
