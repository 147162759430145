import { Typography, Menu, Avatar, Tooltip } from 'antd'
import React from 'react'
import { MdCastConnected } from 'react-icons/md'
import { FaGraduationCap } from 'react-icons/fa'
import { ClockCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import { Api } from 'services'
import { SquareSubIcon } from 'components/subIcon'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import Conference from "Assets/schedule/confrence.svg"
import Broadcast from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import OtherColor from "Assets/schedule/other_color.svg"


const { Text } = Typography
const CancelledClassTandS = ({ user, cancelledItem }) => {


    const menu = (
        <Menu style={{ width: "200px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            <Menu.Item key="1">
                Replay live class
            </Menu.Item>
            {/* commented due to aulas care is not on PROD */}
            {/* <Menu.Item key="2">
                Contact Your Admin
            </Menu.Item> */}
        </Menu>

    );
    if (user.access.includes("schedule-teacher")) {

        return (
            <div className="ScheduleClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="scheduleclass-img-text">
                        <div>
                            <SquareSubIcon icon={cancelledItem?.subject?.icon ? cancelledItem?.subject?.icon : ''} name={cancelledItem?.subject?.name ? cancelledItem?.subject?.name : ''} size={40} />

                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {cancelledItem.mode == "Zoom 40" ?
                                <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                : cancelledItem.mode == "Broadcast" ?
                                    <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "18px", width: "18px" }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :
                                    cancelledItem.mode == "Conference" ?
                                        <div style={{ width: '77px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Conference} style={{ color: '#594099', height: "18px", width: "18px" }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                        cancelledItem.mode == 'Others' ?
                                        <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                            <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                            <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                        </div>
                                        :
                                        <div style={{ width: '75px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "18px", width: "18px" }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>

                            }
                            <Tooltip title={cancelledItem?.subject?.name ? cancelledItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{cancelledItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>
                    {/* <div>
                        <Dropdown overlay={menu} disabled={false} placement="bottomRight" >
                            <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                        </Dropdown>
                    </div> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                    <div style={{ width: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Tooltip title={cancelledItem?.standards.length != 0 ? cancelledItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                            <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
                        </Tooltip>
                        {cancelledItem?.standards.length != 0 ? cancelledItem?.standards?.map((item, index, array) => (
                            <Text style={{ fontSize: '80%', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} key={index}>{item?.std + "-" + item?.section}{index === array.length - 1 ? '' : ' |'}</Text>
                        )) : null}
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(cancelledItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(cancelledItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
            </div>
        )
    }
    else if (user.access.includes("schedule-student")) {
        return (
            <div className="ScheduleClassTandS">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="scheduleclass-img-text">
                        <div>
                            <SquareSubIcon icon={cancelledItem?.subject?.icon ? cancelledItem?.subject?.icon : ''} name={cancelledItem?.subject?.name ? cancelledItem?.subject?.name : ''} size={40} />

                        </div>
                        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', width: '200px' }}>
                            {cancelledItem.mode == "Zoom 40" ?
                                <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px' }}>
                                    <IoMdVideocam style={{ color: '#1089FF' }} />
                                    <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                                </div>
                                : cancelledItem.mode == "Broadcast" ?
                                    <div style={{ width: '90px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '2px' }}>
                                        <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "18px", width: "18px" }} />
                                        <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                    </div>
                                    :
                                    cancelledItem.mode == "Conference" ?
                                        <div style={{ width: '90px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Conference} style={{ color: '#594099', height: "18px", width: "18px" }} />
                                            <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                        </div>
                                        :
                                        cancelledItem.mode == 'Others' ?
                                        <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                            <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                            <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                        </div>
                                        :
                                        <div style={{ width: '90px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '2px' }}>
                                            <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "18px", width: "18px" }} />
                                            <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                        </div>

                            }
                            <Tooltip title={cancelledItem?.subject?.name ? cancelledItem?.subject?.name : null}>
                                <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{cancelledItem?.subject?.name}</Text>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                    <div style={{ width: '50%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Avatar shape='circle' size='small' src={`${Api._s3_url}${cancelledItem?.teacher?.image}`} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{cancelledItem?.teacher?.name}</Text>
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
                        <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(cancelledItem.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(cancelledItem.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
    } = state.schedule;
    const { user } = state.session;
    return {
        user
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(CancelledClassTandS);
// export default CompletedClassTandS
