import React from 'react'
import { DatePicker, Space } from 'antd';
import { connect } from 'react-redux';
import { setDate, getCareUsers, getConversationOpenedClosedUsersFunction } from 'actions/adminCare';
import moment from 'moment';

function Date_Picker({ setDate, date, name,chatNameSearch, role, getConversationOpenedClosedUsersFunction, batch, specificStudentId, togglePageLoader, getCareUsers }) {
  function onChange(date, dateString) {
    setDate(dateString);
    togglePageLoader(true)
    if(chatNameSearch==true)
    {
      getConversationOpenedClosedUsersFunction(1, batch, role, date, name, specificStudentId, () => togglePageLoader(false))
    }else{
      getCareUsers(batch, role, dateString, name, () => togglePageLoader(false));
    }

  }
  return (
    <Space direction="vertical" size={50}>
      <DatePicker onChange={onChange} value={date ? moment(date, 'YYYY-MM-DD') : null} placeholder={['Select a date']} style={{ width: "100%" }} format="YYYY-MM-DD" />
    </Space>
  )
}

// export default Date_Picker1
const mapStateToProps = state => {
  const {
    role,
    batch,
    date,
    name,
    specificStudentId

  } = state.adminCare;
  return {
    role,
    batch,
    date,
    name,
    specificStudentId
  };
};

const mapDispatchToProps = dispatch => ({
  setDate: (res) => dispatch(setDate(res)),
  getCareUsers: (batch, role, date, name, callback) => dispatch(getCareUsers(batch, role, date, name, callback)),
  getConversationOpenedClosedUsersFunction: (page, batch, role, date, name, specificStudentId, callback) => dispatch(getConversationOpenedClosedUsersFunction(page, batch, role, date, name, specificStudentId, callback))

});


export default connect(mapStateToProps, mapDispatchToProps)(Date_Picker);



