import { useRef, useEffect, useCallback } from "react";
export default function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  if (delay == null) {
    return;
  }
  const savedCallback = useRef();
  // Remember the latest callback snapshot.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);
  
  const stop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };
  const start = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      savedCallback.current();
    }, delay);
  };
  return [start, stop];
}