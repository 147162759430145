import {
    CloseOutlined,
    ExclamationCircleOutlined,
    LeftOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    RightOutlined,
  } from "@ant-design/icons";
  import { Button, Col, Input, PageHeader, Row, Tabs, Upload, Modal } from "antd";
  import React, { useState, useEffect } from "react";
  import { useHistory } from "react-router";
  import { Color, Notification } from "services";
  import { connect } from "react-redux";
  import ReactHtmlParser from "react-html-parser";
  import TeX from "@matejmazur/react-katex";
  import {
    fetchTestQuestions,
    updateQParam,
    saveNext,
    setCurrentIndex,
    removeAnswer,
    navigateQuestion,
    handleSelectSubject,
    setSelectedSubIndex,
    updateImage,
    removeAnswerImage,
    updateCurrentDuration,
    finalSubmit,
    toggleOutOfTimeModal,
    selectQIndex,
    setLoader,
    clearQuestion
  } from "actions/studentPracticeTest";
import ImageViewer from "components/ImageViewer";
import moment from "moment";
  
//   import "./styles.css";
  // import './css/TestScreen.css';
//   import SubmitModal from "./modals/submitModal";
  import PageDataLoader from "../../components/pageDataLoader";
  import { showMenu } from "actions/layout";
  import { Prompt } from "react-router-dom";
  import { TestTimer } from "./timer";
  import { setDuration } from "../../actions/studentPracticeTest";
// import OutoftimeModal from "pages/studentTest/modals/outoftimeModal";
// import SubmittedSuccessModal from "pages/studentTest/modals/submittedSuccessModal";
import ViewImgModal from "pages/studentTest/modals/viewImgModal";
// import AutoSubmitModal from "pages/studentTest/modals/SubmitModalPractice";
import OutoftimeModalPractice from "./modals/outoftimeModalPractice";
import SubmittedSuccess from "./modals/submittedSuccess";
import SubmitModalPractice from "./modals/submitModalPractice";
import AutoSubmitModal from "pages/studentTest/modals/autoSubmitModal";
import ExitModalPractice from "./modals/exitModalPractice";
  
  
  const { TabPane } = Tabs;
  const { confirm } = Modal;
  
  var stringToHTML = function (str) {
    var dom = document.createElement("span");
    dom.innerHTML = str;
    return dom;
  };
  
  const orangecircle = (position_val) => (
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#FFA931",
        position: "absolute",
        top: position_val,
        right: position_val,
      }}
    ></div>
  );
  const answerStatus = [
    {
      title: "Attempted",
      key: "attempted",
      backgroundColor: Color.purple,
      border: `1px solid ${Color.midGrey}`,
      color: "white",
    },
    {
      title: "Unattempted",
      key: "unanswered",
      backgroundColor: Color.wrong,
      border: "none",
      color: "white",
    },
    {
      title: "Attempted & Marked for Review",
      key: "attemptedAndBookmarked",
      backgroundColor: Color.purple,
      border: "none",
      color: "white",
    },
    {
      title: "Unattempted & Marked for Review",
      key: "bookmarked",
      backgroundColor: Color.wrong,
      border: "none",
      color: "white",
    },
    {
      title: "Unseen",
      key: "unseen",
      backgroundColor: Color.white,
      border: `1px solid ${Color.black}`,
      color: "black",
    },
  ];
  const legand = (qCounts) => {
    return (
      <div className="legendPalette">
        {answerStatus?.map((item, index) => (
          <div className="answerTypeContainer" key={index.toString()}>
            <div
              className="countBadge r-c-c"
              style={{
                backgroundColor: item.backgroundColor,
                border: item.border,
                color: item.color,
                position: "relative",
                marginRight: "10px",
              }}
            >
              <div>{qCounts ? qCounts[item.key] : 0}</div>
              {item?.key == "bookmarked" || item?.key == "attemptedAndBookmarked"
                ? orangecircle(-3)
                : null}
            </div>
            <div style={{ maxWidth: "50%", fontSize: "0.9vw" }}>
              {" "}
              {item.title}{" "}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  const questionPalette = (questionsList, currentQIndex, onQuestionSelect) => {
    return (
      <div className="questionPalette">
        <span style={{ marginLeft: "2vh" }}> ALL QUESTIONS </span>
        <Row style={{ margin: 10, marginTop: "2vh" }}>
          {questionsList?.map((item, index) => {
            return (
              <Col
                key={index}
                className="questionSelector r-c-c cursor-pointer m-t-5"
                style={{
                  borderRadius: "50%",
                  border: currentQIndex === (index+1)? "2px solid #636363": `1px solid ${Color.midGrey}`,
                  backgroundColor:
                    currentQIndex === (index+1)
                      ? '#fff'
                      : item.status === "Attempted"
                      ? Color.purple
                      : item.status === "AttemptedAndBookmarked"
                      ? Color.purple
                      : item.status === "Unanswered"
                      ? Color.wrong
                      : item.status === "Bookmarked"
                      ? Color.wrong
                      : "transparent",
                  color:
                    currentQIndex === (index+1) || item.status === "NotVisited"
                      ? Color.darkGrey
                      : item.status == "None" || !item.status
                      ? Color.darkGrey
                      : "white",
                  position: "relative",
                }}
                onClick={() => onQuestionSelect(index+1)}
              >
                <span> {index + 1}</span>
                {item.status == "Bookmarked" ||
                item.status == "AttemptedAndBookmarked"
                  ? orangecircle(0)
                  : null}
              </Col>
            );
          })}
        </Row>
      </div>
    );
  
    // <div className="questionPalette">
    // </div>
  };
  
  const singleQuestion = (
    currentQ,
    currentQIndex,
    setStudentAns,
    beforeUpload,
    uploadImage,
    currentImages,
    removeAnswerImage,
    toggleImageModal,
    imgLoader,
    setImgUrl,
    setImageViewerVisibility
  ) => (
    <>
    <div className="answerBox m-b-50" id="answerbox">
      <div className="p-l-20 p-r-20">
      <div className="test-ques-window p-10" style={{color:'#191919', fontWeight:'500',fontSize:'15px'}} >
        {ReactHtmlParser(
            currentQ?.statement
            , {
          transform: (node) => {
            if (
              node.type === "tag" &&
              node.name === "span" &&
              node.attribs["data-value"]
            ) {
              return <TeX math={`${node.attribs["data-value"]}`} />;
            }
            if (node.type === "tag" && node.name === "img") {
              return (
                <img
                  style={{ maxHeight: "40vh", maxWidth: "40vh" }}
                  src={node.attribs.src}
                  alt=""
                  className="cursor-pointer"
                  onClick={()=>{
                    setImgUrl(node.attribs.src);
                    setImageViewerVisibility(true);
                  }}
                />
              );
            }
          },
        })}
      </div>
      </div></div>
      {/* <div style={{color: Color.aeGrey, marginBottom: 20}} >Your Answer</div> */}
      <div className="answerBox" id="answerbox">
        {" "}
        {answerBox(
          currentQ,
          currentQIndex,
          setStudentAns,
          beforeUpload,
          uploadImage,
          currentImages,
          removeAnswerImage,
          toggleImageModal,
          imgLoader,
          setImgUrl,
          setImageViewerVisibility
        )}
      </div>
    </>
  );
  
  const showConfirm = (onSave, history, status, toggleButtonLoader) => {
    return confirm({
      title: `Do you want to submit the test?` /*${item.name}*/,
      icon: <ExclamationCircleOutlined />,
      content: "Please click OK to confirm",
      onOk() {
        onSave(status, history, true, toggleButtonLoader);
      },
      onCancel() {},
    });
  };
  
  const answerBox = (
    currentQ,
    currentQIndex,
    setStudentAns,
    beforeUpload,
    uploadImage,
    currentImages,
    removeAnswerImage,
    toggleImageModal,
    imgLoader,
    setImgUrl,
    setImageViewerVisibility
  ) => {
    if (currentQ?.type === "MCQ") {
      return (
        <div className='p-l-20 p-r-20' >
        {
        currentQ?.options?.map((option, index) => (
          <div className='r-ac m-b-10 test-ques-window'  onClick={() => {setStudentAns(index.toString())}} key={index}       
                style={
                    currentQ?.currentAns === index.toString()
                      ? { backgroundColor: "#F6F4FE", borderRadius:10 }
                      : { borderRadius:10}
                  }>
            <div
            //   style={{color:currentQ?.currentAns=== index.toString() ? '#fff' : '', borderColor: currentQ?.currentAns === index.toString()? '#28df99' : ''}}
              className='r-c-c radius-100 color-black test-circle-avatar m-r-10 m-l-10'
              style={
                currentQ?.currentAns === index.toString()
                  ? {
                      backgroundColor: "#594099",
                      color: "white",
                      fontWeight: 600,
                    }
                  : {}
              }
              >
              {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'}
            </div>
            <div
              style={{width:"100%", overflowX:"hidden"}} 
              className='cursor-pointer p-10 r-c-sb'
              >
              <div className='resize-editor-img'>
                            {ReactHtmlParser(option.value,
                                          { transform: (node) => {
                                          if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                              return <TeX math={`${node.attribs['data-value']}`} />;
                                          }
                                          if (node.type === 'tag' && node.name === 'img') {
                                              return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} className="cursor-pointer" alt="" onClick={(e)=>{
                                                e.stopPropagation();
                                                setImgUrl(node.attribs.src);
                                                setImageViewerVisibility(true);
                                              }}/>;
                                          }
                                          }})
                                      }
              </div>
            </div>
          </div>
        ))}
      </div>
      );
    } else if (currentQ?.type === "Numeric") {
      return (
        <>
          <div style={{ marginLeft: "20px", marginTop: "25px" }}>
            <span style={{ fontWeight: "bold" }}> Answer </span>
          </div>
          <Input
            className="answerInput"
            placeholder="Type your answer here..."
            type="number"
            value={currentQ?.currentAns}
            style={{width:'100%'}}
            onChange={(e) => setStudentAns(e.target.value)}
          />


        </>
      );
    } else {
      return null;
    }
  };
  
  const actionButtons = (
    currentQ,
    currentQIndex,
    onSave,
    buttonLoader,
    selectedStatus,
    onClearResponse,
    currentImages
  ) => (
    <div className="actionButtons">
      <Button
        type="primary"
        shape="round"
        className="actionButton"
        loading={selectedStatus === "Attempted" && buttonLoader}
        style={{ backgroundColor: Color.purple, border: "none" }}
        onClick={() => onSave("Attempted")}
        disabled={
          (currentQ?.type !== "Subjective" &&
            (!currentQ?.currentAns || currentQ?.currentAns === ""))
        }
      >
        SAVE & NEXT
      </Button>
      
      <Button
        type="primary"
        shape="round"
        className="actionButton"
        style={{ backgroundColor: Color.yellow, border: "none" }}
        loading={selectedStatus === "Bookmarked" && buttonLoader}
        onClick={() => onSave("Bookmarked")}
        disabled={
          !(
            currentQ?.type !== "Subjective" &&
            (!currentQ?.currentAns || currentQ?.currentAns === "")
          )
        }
      >
        MARK FOR REVIEW & NEXT
      </Button>
      <Button
        shape="round"
        loading={selectedStatus === "Clear" && buttonLoader}
        className="actionButton"
        onClick={() => {
          onClearResponse();
        }}
        disabled={
          (currentQ?.type !== "Subjective" &&
            (!currentQ?.currentAns || currentQ?.currentAns === ""))
        }
      >
        CLEAR RESPONSE
      </Button>
      <Button
        type="primary"
        shape="round"
        className="actionButton"
        style={{ backgroundColor: Color.seaGreen, border: "none" }}
        loading={selectedStatus === "AttemptedAndBookmarked" && buttonLoader}
        onClick={() => onSave("AttemptedAndBookmarked")}
        disabled={
          (currentQ?.type !== "Subjective" &&
            (!currentQ?.currentAns || currentQ?.currentAns === ""))
        }
      >
        SAVE & MARK FOR REVIEW
      </Button>
    </div>
  );
  
  const warning = (count) => {
    Modal.warning({
      title: `You have only ${count - 1} chance left`,
      content: "Do not change screen or test will get auto submitted.",
    });
  };
  
  function StudentTestAnswer({
    fetchTestQuestions,
    currentQ,
    palateData,
    qCounts,
    selectedTest,
    currentIndex,
    updateQParam,
    saveNext,
    showMenu,
    setCurrentIndex,
    removeAnswer,
    navigateQuestion,
    testSubjects,
    handleSelectSubject,
    selectedSubIndex,
    setSelectedSubIndex,
    setDuration,
    updateImage,
    currentImages,
    removeAnswerImage,
    currentDuration,
    updateCurrentDuration,
    finalSubmit,
    toggleOutOfTimeModal,
    isOutOfTimeModalVisible,
    selectQIndex,
    setLoader,
    loaders,
    timeLeft,
    clearQuestion
  }) {
    let timer = 0;
    const history = useHistory();
    const [showImgModal, toggleImgModal] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [imgLoader, toggleImageLoader] = useState(false);
    const [autoSubmitVisible, setAutoSubmitVisible] = useState(false);
    const [warningsCount, setWarningsConut] = useState(2);
    const [SM_isModalVisible, setIsModalVisible] = useState(false);
    const [buttonLoader, toggleButtonLoader] = useState(false);
    const [selectedStatus, updateSelectedStatus] = useState("");
    const [showSubmitSuccess, toggleSubmitSuccess] = useState(false);
    const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
    const [isExitModalVisible, setExitModalVisibility] = useState(false);
    const [imgUrl, setImgUrl] = useState("");
    // console.log({currentImages});
    const SM_showModal = () => {
      setIsModalVisible(true);
    };
  
    const SM_handleOk = () => {
      setIsModalVisible(false);
      toggleSubmitSuccess(true);
      clearQuestion()
    };
  
    const SM_handleCancel = () => {
      setIsModalVisible(false);
    };
    const autoSubmitSuccessCallback = () => {
      setAutoSubmitVisible(false);
      toggleOutOfTimeModal(true);
    };
    const onClearResponse = () => {
      updateQParam(null, "currentAns");
      updateSelectedStatus("Clear");
      setLoader('testScreenLoader', true)
      removeAnswer("Unanswered", history, buttonLoaderCallBack, (bool) =>{
        fetchTestQuestions(()=>{
          setLoader('testScreenLoader', false)
        })
      }
      );
    };

    const handleExit = () => {
      setExitModalVisibility(false);
      history.goBack();
      if (window.location.pathname==='/practice-testScreen'){
        selectQIndex(0, (bool) => {
        }, true);
        setExitModalVisibility(false);
        history.goBack();
      }
    }

    const handleCancelExit = () => {
      setExitModalVisibility(false);
    }
    
    useEffect(() => {
      showMenu(false);
      setLoader('testScreenLoader', true);
      fetchTestQuestions((bool) => {
        setLoader('testScreenLoader', false);
      });
      clearInterval(timer);
      TestTimer.startTimer(
        selectedTest,
        setDuration,
        updateCurrentDuration,
        handleAutoSubmit
      );
      // window.history.pushState(
      //     { name: "browserBack" },
      //     "on browser back click",
      //     window.location.href
      //   );
  
      // window.onpopstate = _backConfirm;
  
      // window.addEventListener('focus', handleVisibilityFocus);
  
      return () => {
        // window.removeEventListener('focus',  handleVisibilityFocus);
        TestTimer.clearTimer(setDuration);
        setAutoSubmitVisible(false);
        showMenu(true);
        setSelectedSubIndex(0);
      };
    }, []);
  
    // const handleVisibilityFocus =() => {
    //     if (warningsCount === 0) {
    //         setAutoSubmit(true)
    //         clearTimer();
    //     } else {
    //       warning(warningsCount);
    //       // alert(`You have only ${this.state.warnings-1} warnings left`);
    //       warningsCount = warningsCount - 1
    //       setWarningsConut(warningsCount);
    //     }
    //   };
  
    const handleAutoSubmit = (isover) => {
          // finalSubmit(() => {
          //   toggleButtonLoader(false)}, SM_handleOk)
      setAutoSubmitVisible(true);
      selectQIndex(0, (bool) => {
        finalSubmit(() => {}, autoSubmitSuccessCallback);
      }, true);
    };
  
  
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        Notification.error("error", "You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Notification.error("error", "Subjective must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    };
  
    const onQuestionSelect = (index) => {
      if (index <= 0 || index > palateData?.length) {
        return;
      }
      // setCurrentIndex(index);
      setLoader('testScreenLoader', true);
      if (currentQ?.attempt?.submitStatus==='Attempted'){
        setCurrentIndex(index)
        fetchTestQuestions(()=>{
          setLoader('testScreenLoader', false);
        })
      }else{
        selectQIndex(index, (bool) => {
          setLoader('testScreenLoader', false);
        }, false)
      }

    };
  
    const uploadImage = (info) => {
      if (info.file.status === "uploading") {
        toggleImageLoader(true);
        return;
      }
      if (info.file.status === "done" || info.file.status === "error") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          updateImage(imageUrl, info.file.originFileObj);
          toggleImageLoader(false);
        });
      }
    };
  
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    };
  
    const toggleImageModal = (bool, index) => {
      toggleImgModal(bool);
      setCurrentImg(currentImages[index]);
    };
  
    const handleNavigateClick = (direction) => {
      updateSelectedStatus(direction);
      setLoader('testScreenLoader', true)
      navigateQuestion(direction, buttonLoaderCallBack, (bool) =>
        setLoader('testScreenLoader', false)
      );
    };
  
    const handleSubSelect = (index) => {
      setLoader('testScreenLoader', true);
      handleSelectSubject(index, (bool) => {
        setLoader('testScreenLoader', false);
      });
    };
  
    const onSave = (status) => {
      updateSelectedStatus(status);
      setLoader('testScreenLoader', true)
      saveNext(status, history, false, buttonLoaderCallBack, (bool) =>
          setLoader('testScreenLoader', false)
        );
      // if (currentIndex < palateData.length - 1) {
      //   saveNext(status, history, false, buttonLoaderCallBack, (bool) =>
      //     togglePageLoader(bool)
      //   );
      // } else {
      //   saveNext(status, history, false, buttonLoaderCallBack, (bool) =>
      //     togglePageLoader(bool)
      //   );
      //   // showConfirm(saveNext, history, status, buttonLoaderCallBack);
      // }
    };
  
    const buttonLoaderCallBack = () => {
      toggleButtonLoader(false);
      updateSelectedStatus("");
    };
  
    const setStudentAns = (ans) => {
      updateQParam(ans, "currentAns");
    };
    return (
      <div className="TestScreen" style={{ height: "100%", minWidth: "580px" }}>
        <PageHeader
          style={{ backgroundColor: "#F6F4FE" }}
          title={<div style={{minWidth:400}}>
             {selectedTest.title}
              </div>}
          onBack={() => {
            setExitModalVisibility(true);
          }}
          extra={
            <div className="leftInnerNav">
              <div className="clockTime r-c-fs" style={{ alignItems: "center" }}>
                <img
                  style={{ width: 25, height: 25 }}
                  src={require("Assets/ic-stop-watch.svg").default}
                ></img>
                {timeLeft?.hours <= 0 && timeLeft?.minutes <= 0 && timeLeft?.seconds <= 0 ? 
                <span className="text-md m-l-5" style={{ color: "#594099" }}>
                {'00'}:{'00'}:{'00'}
              </span>
              :
              <span className="text-md m-l-5" style={{ color: "#594099" }}>
                  {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                </span>
              }
                
              </div>
              <div className="innerNavBtn">
                {testSubjects?.map((item, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      if (testSubjects?.length>1){
                        handleSubSelect(index)
                      }}}
                    style={
                      currentQ?.chapter?.subject?.id === item.subject_id
                        ? {
                            backgroundColor: "#E6E6E6",
                            color: "#191919",
                            border: "none",
                            cursor:testSubjects?.length>1?"pointer":"not-allowed"
                          }
                        : {cursor:testSubjects?.length>1?"pointer":"not-allowed"}
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.subject_name}
                    </div>
                  </Button>
                ))}
              </div>
            </div>
          }
        />
        <div className="testContainer">
          <div className="lqPanel">
            <div
              style={{
                height: "100%",
                borderRight: `1px solid ${Color.midGrey}`,
              }}
            >
              <div className="questionContainer" style={{backgroundColor:"#FAFAFA", height:"100%"}}>
                <div className="questionHeader">
                  <span>{currentIndex}</span>
                  <div className="leftText r-c-sa dark-grey">
                    <span>
                      <b>Marks:  </b> + {currentQ?.positiveMarks}{" "}
                      {currentQ?.negativeMarks
                        ? `|  - ${currentQ?.negativeMarks}`
                        : null}
                    </span>
                    <span>
                      {" "}
                      <b>Time: </b>
                      {Math.floor(currentDuration / 60).toLocaleString(2)} :{" "}
                      {Math.floor(currentDuration % 60).toLocaleString(2)}{" "}
                    </span>
                  </div>
                </div>
                <div className="qaSection">
                  {singleQuestion(
                    currentQ,
                    currentIndex,
                    setStudentAns,
                    beforeUpload,
                    uploadImage,
                    currentImages,
                    removeAnswerImage,
                    toggleImageModal,
                    imgLoader,
                    setImgUrl,
                    setImageViewerVisibility
                  )}
                </div>
              </div>
              <div className="actionContainer">
                {actionButtons(
                  currentQ,
                  currentIndex,
                  onSave,
                  buttonLoader,
                  selectedStatus,
                  onClearResponse,
                  currentImages
                )}
                <div className="footerBtns">
                  <div className="qNavBtn">
                    <Button
                      style={{ border: "none", backgroundColor: "transparent" }}
                      disabled={currentIndex <= 1}
                      onClick={() => handleNavigateClick("prev")}
                    >
                      {selectedStatus === "prev" && buttonLoader ? (
                        <LoadingOutlined />
                      ) : (
                        <LeftOutlined />
                      )}
                      PREVIOUS
                    </Button>
                    <Button
                      style={{ border: "none", backgroundColor: "transparent" }}
                      disabled={currentIndex >= palateData?.length}
                      onClick={() => handleNavigateClick("next")}
                    >
                      NEXT
                      {selectedStatus === "next" && buttonLoader ? (
                        <LoadingOutlined />
                      ) : (
                        <RightOutlined />
                      )}
                    </Button>
                  </div>
                  <div className="submitBtn cursor-pointer">
                    <span
                      style={{ color: "blue", fontWeight: "bold" }}
                      onClick={SM_showModal}
                    >
                      {" "}
                      SUBMIT{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rqPanel">
            <div style={{ height: "100%" }}>
              {legand(qCounts)}
              {questionPalette(palateData, currentIndex, onQuestionSelect)}
            </div>
          </div>
        </div>
        {
            isImageViewerVisible && (
              <ImageViewer isPreviewModalVisible={isImageViewerVisible} 
              image={imgUrl} setIsPreviewModalVisible={setImageViewerVisibility}/>
            )
        }
        <ViewImgModal
          visible={showImgModal}
          imgPath={currentImg}
          onCancel={() => toggleImageModal(false)}
        />
        {autoSubmitVisible && ( <AutoSubmitModal visible={autoSubmitVisible} />) }
        <SubmitModalPractice visible={SM_isModalVisible} handleOk={SM_handleOk}
          handleCancel={SM_handleCancel}/>
        {loaders?.testScreenLoader && <PageDataLoader visible={loaders?.testScreenLoader} />}
        {/* <Prompt
          when={!isOutOfTimeModalVisible}
          message={(location) => `Are you sure you want to leave the test?`}
        /> */}
        <SubmittedSuccess
          item="beforeResults"
          isModalVisible={showSubmitSuccess}
          history={history}
          close={() => toggleSubmitSuccess(false)}
        />
        <OutoftimeModalPractice history={history} />
        {isExitModalVisible && <ExitModalPractice visible={isExitModalVisible} handleOk={handleExit} handleCancel={handleCancelExit}/>}
      </div>
    );
  }
  
  const mapStateToProps = (state) => {
    const {
      currentQ,
      palateData,
      qCounts,
      selectedTest,
      currentIndex,
      testSubjects,
      selectedSubIndex,
      currentImages,
      currentDuration,
      isOutOfTimeModalVisible,
      loaders,
      timeLeft
    } = state.studentPracticeTest;
    return {
      isOutOfTimeModalVisible,
      currentQ,
      palateData,
      qCounts,
      selectedTest,
      currentIndex,
      testSubjects,
      selectedSubIndex,
      currentImages,
      currentDuration,
      loaders,
      timeLeft
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    toggleOutOfTimeModal: (value) => dispatch(toggleOutOfTimeModal(value)),
    fetchTestQuestions: (loaderCallback) =>
      dispatch(fetchTestQuestions(loaderCallback)),
    updateQParam: (val, key) => dispatch(updateQParam(val, key)),
    saveNext: (
      status,
      history,
      finalSubmit,
      buttonLoaderCallBack,
      pageLoaderCallback
    ) =>
      dispatch(
        saveNext(
          status,
          history,
          finalSubmit,
          buttonLoaderCallBack,
          pageLoaderCallback
        )
      ),
    showMenu: (bool) => dispatch(showMenu(bool)),
    setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
    removeAnswer: (status, history, buttonLoaderCallBack, pageLoaderCallback) =>
      dispatch(
        removeAnswer(status, history, buttonLoaderCallBack, pageLoaderCallback)
      ),
    navigateQuestion: (dir, buttonLoaderCallBack, pageLoaderCallback) =>
      dispatch(navigateQuestion(dir, buttonLoaderCallBack, pageLoaderCallback)),
    handleSelectSubject: (index, pageLoaderCallback) =>
      dispatch(handleSelectSubject(index, pageLoaderCallback)),
    setSelectedSubIndex: (index) => dispatch(setSelectedSubIndex(index)),
    setDuration: (duration) => dispatch(setDuration(duration)),
    updateImage: (base64Image, originalImage) =>
      dispatch(updateImage(base64Image, originalImage)),
    removeAnswerImage: (index) => dispatch(removeAnswerImage(index)),
    updateCurrentDuration: (seconds) => dispatch(updateCurrentDuration(seconds)),
    finalSubmit: (buttonCallback, successCallback) =>
      dispatch(finalSubmit(buttonCallback, successCallback)),
    selectQIndex:(index, callback, bool) => dispatch(selectQIndex(index, callback, bool)),
    setLoader: (key, val) => dispatch(setLoader(key, val)),
    clearQuestion: () => dispatch(clearQuestion())
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(StudentTestAnswer);