export const InvalidCouponType = {
    invalid: 'invalid',
    expired: 'expired',
    usageLimit: 'usageLimit',
    minPurchase: 'minPurchase',
}

export const InvalidCouponMessage = {
    [InvalidCouponType.invalid] : 'Invalid coupon!',
    [InvalidCouponType.expired] : 'Coupon expired!',
    [InvalidCouponType.usageLimit] : 'Invalid coupon!',
    [InvalidCouponType.minPurchase] : 'Invalid coupon!',
}