import { PlusCircleOutlined } from '@ant-design/icons';
import { getOffersOverview, setOffersTypeIndex } from 'actions/offers';
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CreateOffers from './components/createOffers';
import OfferGraph from './components/offerGraph';
import OffersGridList from './components/offerGridList';
import OffersTableList from './components/offersTableList';
import OfferTypeButtonList from './components/offerTypeButtonList';
import { MainSectionTypes } from './constants/offers';
import './styles.css';
export const bodyStyle = {
    backgroundColor: "#FAFAFA",
    width: " calc(100% + 30px)",
    height: "calc(100% + 55px)",
    margin: "-20px",
    padding: "20px",
}

function OffersOverview({ offersOverview, getOffersOverviewData, offerData, offersTypeIndex, setOffersTypeIndex }) {
    const [mainSectionType, setMainSectionType] = useState(MainSectionTypes.gridList);
    const [previousMainSectionType, setPreviousMainSectionType] = useState(MainSectionTypes.gridList);
    const [pageLoading, setPageLoading] = useState(false);
    const [isFilterVisible, setFilterVisible] = useState(false);
    const [offerFilterParams, setOfferFilterParams] = useState({});
    const [selectedOffer, setSelectedOffer] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [isOfferModalVisible, setIsOfferModalVisible] = useState(false);
    //const [typeIndex, setTypeIndex] = useState(0);
    const [modalActionType, setModalActionType] = useState('');
    const history = useHistory();
    useEffect(() => {
        setPageLoading(true);
        const filterParam = {
        };
        if (offersTypeIndex == 1) {
            filterParam.available = true;
        } else if (offersTypeIndex == 2) {
            filterParam.available = false;
        }
        setOfferFilterParams((params) => ({
            ...params,
            ...filterParam,
        }));
        getOffersOverviewData(() => setPageLoading(false));
    }, [])
    useEffect(() => {
        if (previousMainSectionType == MainSectionTypes.createEdit) {
            setPageLoading(true);
            setSelectedItems([]);
            getOffersOverviewData(() => setPageLoading(false));
        }
    }, [
        mainSectionType
    ]);
    const actions = [];
    if (mainSectionType == MainSectionTypes.gridList || mainSectionType == MainSectionTypes.tableList) {
        if (!selectedItems?.length) {
            actions.push(
                <Button type="primary" onClick={() => { setSelectedOffer({}), setPreviousMainSectionType(mainSectionType); setMainSectionType(MainSectionTypes.createEdit) }} icon={<PlusCircleOutlined />}> Add A Promotional Offer</Button>
            );
        } else {
            actions.push(
                <Button type="transperent" style={{ backgroundColor: '#FAFAFA', color: '#FF414D', borderColor: '#FF414D' }} onClick={() => { setIsOfferModalVisible(true); setModalActionType('delete') }}>DELETE</Button>
            );
            if (selectedItems.every((item) => !item.privateOffer && !item.expired && item.published)) {
                actions.push(
                    <Button type="primary" style={{ marginLeft: '1rem' }} onClick={() => { setIsOfferModalVisible(true); setModalActionType('publish') }}>UNPUBLISH</Button>
                );
            } else if (selectedItems.every((item) => !item.privateOffer && !item.expired && !item.published)) {
                actions.push(
                    <Button type="primary" style={{ marginLeft: "1rem" }} onClick={() => { setIsOfferModalVisible(true); setModalActionType('publish') }}>PUBLISH</Button>
                );
            }
        }
    }
    const offerCounts = offersOverview?.offerCounts || {};
    const totalOfferCount = offerCounts?.total || 0;
    const newOfferTypeList = [
        { label: 'ALL', count: totalOfferCount, key: null },
        { label: 'AVAILABLE', count: offerCounts?.available || 0, key: 'available' },
        { label: 'UNAVAILABLE', count: offerCounts?.unavailable || 0, key: 'unavailable' },
    ];
    return (
        <div className='offer-overview' onClick={(e) => { e.stopPropagation(); setFilterVisible(false) }}>
            <header style={{ marginBottom: 35 }}>
                <PageHeader title='Promotional offers' onBackPress={() => { history.push("/courses"); }} actions={offerData?.items?.length ? actions : []} />
            </header>
            <div style={{ overflow: "hidden !important", ...bodyStyle }}>
                <div className='offer-overview-body'>
                    <div className='offer-overview-aside'>
                        <OfferTypeButtonList offerTypeList={newOfferTypeList} setOfferFilterParams={setOfferFilterParams}
                            setMainSectionType={setMainSectionType} setPreviousMainSectionType={setPreviousMainSectionType}
                            mainSectionType={mainSectionType} previousMainSectionType={previousMainSectionType}
                            offersTypeIndex={offersTypeIndex || 0} setOffersTypeIndex={setOffersTypeIndex}setSelectedItems={setSelectedItems}
                        />
                        <OfferGraph offerCounts={offerCounts} ></OfferGraph>
                    </div>
                    <div className='offer-overview-main'>
                        {
                            mainSectionType == MainSectionTypes.gridList
                                ? <OffersGridList
                                    offerFilterParams={offerFilterParams}
                                    setMainSectionType={setMainSectionType}
                                    previousMainSectionType={previousMainSectionType}
                                    setPreviousMainSectionType={setPreviousMainSectionType}
                                    setSelectedOffer={setSelectedOffer}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    setModalActionType={setModalActionType}
                                    setPageLoading={setPageLoading}
                                    pageLoading={pageLoading}
                                    isModalVisible={isOfferModalVisible}
                                    setIsOfferModalVisible={setIsOfferModalVisible}
                                    actionType={modalActionType}
                                    selectedOffer={selectedOffer}
                                    setOfferFilterParams={setOfferFilterParams}
                                    isFilterVisible={isFilterVisible}
                                    setFilterVisible={setFilterVisible}
                                >
                                </OffersGridList>
                                : null
                        }
                        {
                            mainSectionType == MainSectionTypes.tableList
                                ? <OffersTableList
                                    offerFilterParams={offerFilterParams}
                                    setMainSectionType={setMainSectionType}
                                    previousMainSectionType={previousMainSectionType}
                                    setPreviousMainSectionType={setPreviousMainSectionType}
                                    setIsOfferModalVisible={setIsOfferModalVisible}
                                    setSelectedOffer={setSelectedOffer}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    setModalActionType={setModalActionType}
                                    setPageLoading={setPageLoading}
                                    pageLoading={pageLoading}
                                    isModalVisible={isOfferModalVisible}
                                    actionType={modalActionType}
                                    selectedOffer={selectedOffer}
                                    setOfferFilterParams={setOfferFilterParams}
                                    isFilterVisible={isFilterVisible}
                                    setFilterVisible={setFilterVisible}
                                >
                                </OffersTableList>
                                : null
                        }
                        {
                            mainSectionType == MainSectionTypes.createEdit
                                ? <CreateOffers
                                    selectedOfferId={selectedOffer.id || null}
                                    setMainSectionType={setMainSectionType} setPreviousMainSectionType={setPreviousMainSectionType} previousMainSectionType={previousMainSectionType}
                                    offerFilterParams={offerFilterParams} setPageLoading={setPageLoading} setOfferFilterParams={setOfferFilterParams}
                                    setOffersTypeIndex={setOffersTypeIndex} setModalActionType={setModalActionType} setIsOfferModalVisible={setIsOfferModalVisible} setSelectedOffer={setSelectedOffer}
                                    isOfferModalVisible={isOfferModalVisible} modalActionType={modalActionType} selectedOffer={selectedOffer} setSelectedItems={setSelectedItems}
                                ></CreateOffers>
                                : null
                        }
                    </div>
                </div>
            </div>
            <PageDataLoader visible={pageLoading} />
        </div >
    );
}

const mapStateToProps = (state) => {
    const { offersOverview, offerData, offersTypeIndex } = state.offers;
    return { offersOverview, offerData, offersTypeIndex };
};

const mapDispatchToProps = (dispatch) => ({
    getOffersOverviewData: (callBack) => dispatch(getOffersOverview(callBack)),
    setOffersTypeIndex: (val) => dispatch(setOffersTypeIndex(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OffersOverview);