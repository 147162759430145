import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import Notification from "services/Notification";
import { Button, Checkbox, DatePicker, Input, Select, TimePicker } from 'antd'
import { ArrowLeftOutlined, CheckCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from 'antd/lib/input/TextArea';
import { getExaminer, getIndexOfTest, getTestChapterList, postTestEdit, storeCreateTestModalData, getSubjects, getTestClassList, removeSelectedSearchSubjs, removeSelectedChapter, setSubjectSection, fetchMarkingSchemeData, showGenerateQuesListView, createTestModal, fromCreateNewButton, setFromSectionMarkingScheme, storeMarkedSchemeCardId, setSubjectSectionAction, getQuestionNumbers, storeDeletingChapterId, DeleteChapterInTest, postImportSampleQuestionPaper, changeTestStatusModal, } from 'actions/testAdmin';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router';
import PageDataLoader from 'components/pageDataLoader';
import CreateMarkingScheme from '../modals/createMarkingScheme';
import EditMarkingSchemeModal from '../modals/editMarkingSchemeModal';
import ConfirmDeleteModal from '../modals/confirmDeleteModal';
import TestPublishModal from './testPublishModal';

let timer = false;
const { Option } = Select;
let selectedTestStartTime = null;

const handleKeyDown = (e) => {
    if (e.key === '+' || e.key === '-') {
        e.preventDefault();
    }
};

const SectionsNeeded = ({ storeMarkingSchemeRes, fetchMarkingSchemeData, onSectionChange, section, showFields, childIndex, mainIndex, onFillSubjectiveSection, createTestModalName, testCreateObj, setFromSectionMarkingScheme, storeMarkedSchemeCardId, fromCreateNewButton, isSameMarkingScheme, location }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [sectionState, setSectionState] = useState(section)
    const [isEditModal, setIsEditModal] = useState(false)
    const [selectedSectionMarkingValue, setSelectedSectionMarkingValue] = useState('')

    useEffect(() => {
        fetchMarkingSchemeData()
    }, [])

    const onFillSection = (mainIndex, index, key, value) => {
        const newSectionState = {
            ...sectionState,
            [key]: value
        }
        setSectionState(newSectionState);
        onSectionChange(index, newSectionState);
    }
    return (
        <>
            <div className='display-flex m-t-20' style={{ width: '100%' }}>
                <div style={{ width: "50%" }}>
                    <div className='bold-600 text-xs m-t-20'>Enter section name</div>
                    <Input
                        className="m-t-5 test-custom-select createTestModalValue"
                        style={{ transition: `${"width 0.5s"}` }}
                        placeholder="Enter section name"
                        onChange={(e) => { onFillSection(mainIndex, childIndex, "name", e.target.value) }}
                        defaultValue={section?.sectionName ? section?.sectionName : null}
                    />
                </div>
                <div style={{ display: "flex", width: "50%", justifyContent: "space-between", marginLeft: "8%" }}>
                    {showFields != true ?
                        <>
                            <div>
                                <div className='bold-600 text-xs m-t-20'>Total questions</div>
                                <Input
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    placeholder='Enter value'
                                    className="m-t-5 test-custom-select createTestModalValue"
                                    onChange={(e) => { onFillSection(mainIndex, childIndex, "totalQuestions", e.target.value); }}
                                    defaultValue={section?.totalQuestion ? section?.totalQuestion : ""}
                                    disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || location == '1'|| location == 'fromClassroom' || createTestModalName === 'test-clone')}
                                />
                            </div>
                            <div>
                                <div className='bold-600 text-xs m-t-20'>Mandatory questions</div>
                                <Input
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    placeholder='Enter value'
                                    className="m-t-5 test-custom-select createTestModalValue"
                                    disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone')}
                                    onChange={(e) => { onFillSection(mainIndex, childIndex, "mandatoryQuestions", e.target.value) }}
                                    defaultValue={section?.mandatoryQuestion ? section?.mandatoryQuestion : ''}

                                />
                            </div>
                        </>
                        : null}
                </div>
            </div>

            <div className='r-jsb m-t-20'>
                {(testCreateObj?.totalQuestions > 0 || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone') ?
                    <>
                        <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                        <div className='bold-600 text-xs' style={{ color: '#636363', cursor: sectionState?.schemeId == null || sectionState?.schemeId == '' || sectionState?.schemeId == undefined || sectionState?.schemeId == -1 ? "not-allowed" : "pointer" }}
                            onClick={() => {
                                if (!(sectionState?.schemeId == null || sectionState?.schemeId == '' || sectionState?.schemeId == undefined || sectionState?.schemeId == -1)) {
                                    storeMarkedSchemeCardId(sectionState?.schemeId);
                                    setIsEditModal(true);
                                    setFromSectionMarkingScheme(true)
                                }
                            }}>| PREVIEW</div>

                    </>
                    :
                    <>
                        <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: "#1089FF", fontWeight: "bold", fontSize: "11px", cursor: "pointer", marginRight: 5 }} onClick={() => { setFromSectionMarkingScheme(true); fromCreateNewButton(false); setIsModalVisible(true) }}><PlusOutlined />{" "}CREATE NEW {" "}</div>
                            <div className='bold-600 text-xs cursor-pointer' style={{ display: "flex", fontSize: "11px", color: '#636363', fontWeight: "bold" }}>
                                <div>
                                    {"  "}|{" "}
                                </div>
                                <div
                                    style={{ marginLeft: 5, cursor: (sectionState?.schemeId === "" || sectionState?.schemeId === null || sectionState?.schemeId === undefined || sectionState?.schemeId == -1) ? sectionState?.markingSchemeId ? "pointer" : "not-allowed" : "pointer" }}
                                    onClick={() => {
                                        if ((!(sectionState?.schemeId === "" || sectionState?.schemeId === null || sectionState?.schemeId === undefined)) || sectionState?.markingSchemeId || sectionState?.schemeId == -1) {
                                            storeMarkedSchemeCardId(sectionState?.markingSchemeId?.id ? sectionState?.markingSchemeId?.id : sectionState?.markingSchemeId ? sectionState?.markingSchemeId : sectionState?.schemeId);
                                            setIsEditModal(true);
                                            setFromSectionMarkingScheme(true);
                                        } else {

                                        }
                                    }}>PREVIEW</div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {isSameMarkingScheme && childIndex > 0 ?
                (<>
                    <Input
                        disabled
                        value={storeMarkingSchemeRes.find(el => (sectionState?.markingSchemeId == el.id))?.title}
                    />
                </>)
                :
                <Select
                    disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone')}
                    className="m-t-5 test-custom-select createTestModalValue"
                    style={{ width: '100%', minWidth: '40%' }}
                    placeholder="Select marking scheme"
                    onChange={(value) => {
                        setSelectedSectionMarkingValue(value)
                        onFillSection(mainIndex, childIndex, "markingSchemeId", value)
                    }}
                    value={section?.markingSchemeId?.title ? section?.markingSchemeId?.title : selectedSectionMarkingValue !== "" ? selectedSectionMarkingValue : section?.schemeName}
                >
                    {storeMarkingSchemeRes?.length && storeMarkingSchemeRes?.map((item) => (
                        <Option value={item?.id} key={item.id}>{item?.title}</Option>
                    ))}
                </Select>
            }
            {isModalVisible &&
                <CreateMarkingScheme
                    isCreateMarkingSchemeVisible={isModalVisible}
                    setIsCreateMarkingSchemeVisible={setIsModalVisible}
                    onFillSection={onFillSection}
                    mainIndex={mainIndex}
                    childIndex={childIndex}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                />
            }
            {isEditModal &&
                <EditMarkingSchemeModal
                    isEditModalVisible={isEditModal}
                    setIsEditModalVisisble={setIsEditModal}
                    onFillSection={onFillSection}
                    mainIndex={mainIndex}
                    childIndex={childIndex}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                />
            }
        </>
    )
}

const SectionCount = ({ setSectionVisible, sectionVisible, sectionCountChange, setSectionMeta, index, item, sectionMeta, subjectSection, uncheck, location, createTestModalName, setIsOther }) => {
    return (
        <div className='display-flex' style={{ width: 50 }}>
            <div style={{ cursor: (item > sectionMeta && location != '1' && createTestModalName != "test-clone") ? "pointer" : "not-allowed" }}>
                <div
                    className={`text-xmd bold-600
                ${sectionMeta == item ? 'purpleBtnWrap' : 'blankGreyBtnWrap'}`}
                    style={{
                        minWidth: 40,
                        minHeight: 40,
                        borderRadius: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #E6E6E6',
                        fontFamily: 'roboto',
                    }}
                    onClick={() => {
                        if (item > sectionMeta && location != '1' && createTestModalName != "test-clone") {
                            setSectionMeta(index + 1);
                            sectionCountChange(index + 1);
                            setSectionVisible(true);
                            uncheck();
                            setIsOther(false)
                        } else {

                        }
                    }}
                >
                    {item}
                </div>
            </div>
        </div>
    )
}

const SubjectSectionsCard = ({
    fetchMarkingSchemeData, storeMarkingSchemeRes, subjectSectionOnChange,
    item, testCreateObj, groupedChapters, setChapterSearchSubjectId,
    setSearchText, setSearchTextIndex, updateHandleRender, storeCreateTestModalData,
    searchedGroupedChapters, createTestModalName, removeSelectedChapter, sectionCheckbox,
    storeSubjectSection, mainIndex, includeSection, loaders, fromCreateNewButton,
    setFromSectionMarkingScheme, storeMarkedSchemeCardId, getQuestionNumbers,
    totalQuesChapterData, storeDeletingChapterId, DeleteChapterInTest, deletingChapterId, location, section, chaptersArray }) => {

    const [sectionMeta, setSectionMeta] = useState(null);
    const [subjectSection, setSubjectSectionAction] = useState(storeSubjectSection);
    const [sectionVisible, setSectionVisible] = useState(false);
    const [isCreateMarkingSchemeVisible, setIsCreateMarkingSchemeVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisisble] = useState(false)
    const [allQuesMandatory, setAllQuesMandatory] = useState(false);
    const [sameMarkingScheme, setSameMarkingScheme] = useState(false);
    const [selectedvalue, SetSelectedValue] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isSameMarkingScheme, setIsSameMarkingScheme] = useState(false);
    const [isOther, setIsOther] = useState(false)

    useEffect(() => {
        setSubjectSectionAction(storeSubjectSection)
    }, [storeSubjectSection])

    useEffect(() => {
        if (!section) {
            setSectionMeta(null);
            setSectionVisible(false);
            setAllQuesMandatory(false)
        }
    }, [section])

    const deleteChapter = true;

    useEffect(() => {
        if (subjectSection?.sections?.length > 0) {
            setSectionVisible(true)
        }
        if (subjectSection?.includeSectionFlag === 0) {
            includeSection(false)
            setSectionMeta(null)
        } else if (subjectSection?.includeSectionFlag === 1) {
            includeSection(true)
            setSectionMeta(subjectSection?.sections?.length);
        }
        if (subjectSection?.markingSchemeFlag === 1) {
            setSameMarkingScheme(true)
        }
        if (subjectSection?.allQuestionsMandatoryFlag == 1) {
            setAllQuesMandatory(true)
        }
    }, [])

    const onFillSubjectiveSection = (index, key, value) => {
        const newSubjectSection = {
            ...subjectSection,
            [key]: value
        }
        setSubjectSectionAction(newSubjectSection);
        subjectSectionOnChange(index, newSubjectSection);
    }

    // const onSectionChange = (sectionIndex, value) => {
    //     onFillSubjectiveSection(mainIndex,
    //         "sections",
    //         [...subjectSection?.sections?.slice(0, sectionIndex), value, ...subjectSection?.sections?.slice(sectionIndex + 1)]
    //     )
    // }

    const onSectionChange = (sectionIndex, value) => {
        const newSectionsArr = [...subjectSection?.sections?.slice(0, sectionIndex), value, ...subjectSection?.sections?.slice(sectionIndex + 1)]
        if (isSameMarkingScheme) {
            newSectionsArr.forEach((section) => {
                section.markingSchemeId = newSectionsArr[0]?.markingSchemeId;
            })
        }
        onFillSubjectiveSection(mainIndex, "sections", newSectionsArr)
    }

    const sameForAll = () => {
        const newSectionsArr = subjectSection?.sections?.length ? [...subjectSection?.sections] : [];
        if (!isSameMarkingScheme) {
            newSectionsArr.forEach((section) => {
                section.markingSchemeId = newSectionsArr[0]?.schemeId ? newSectionsArr[0]?.schemeId : newSectionsArr[0]?.markingSchemeId;
            })
        }
        onFillSubjectiveSection(mainIndex, "sections", newSectionsArr)
    }

    const sectionCountChange = (count) => {
        if (subjectSection?.sections?.length > count) {
            onFillSubjectiveSection(mainIndex, 'sections', subjectSection?.sections?.slice(0, count))
        } else if (!subjectSection?.sections || subjectSection?.sections?.length < count) {
            ;
            const newSections = Array(count - (subjectSection?.sections ? subjectSection?.sections?.length : 0))?.fill()?.map(el => ({}))
            onFillSubjectiveSection(mainIndex, 'sections', [...(subjectSection?.sections ? subjectSection?.sections : []), ...newSections])
        }
    }
    const uncheck = () => {
        if (sectionMeta !== subjectSection?.sections?.length) {
            setSameMarkingScheme(false)
        }
    }
    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF', border: '1px solid #E4E4E4', height: 'fit-content', width: '100%', borderRadius: 8, marginTop: '20px' }}>
                <div className='p-20'>
                    <div className='r-jsb' style={{ width: '100%' }}>
                        <div className='bold-600 text-xmd' style={{ color: '#636363' }}>{storeSubjectSection?.subName}</div>
                        <div className='display-flex' style={{ marginRight: -5, }}>
                            {sectionCheckbox === false ?
                                <></>
                                :
                                <div className='display-flex m-r-20'>
                                    <Checkbox style={{ fontSize: 5, marginTop: -3 }} checked={sameMarkingScheme} disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone')}
                                        onChange={(e) => {
                                            setSameMarkingScheme(e.target.checked);
                                            setIsSameMarkingScheme(!isSameMarkingScheme),
                                                sameForAll();
                                            if (e.target.checked) {
                                                onFillSubjectiveSection(mainIndex, "markingSchemeFlag", 1);
                                            } else if (!e.target.checked) {
                                                onFillSubjectiveSection(mainIndex, "markingSchemeFlag", 0);
                                            }
                                        }}></Checkbox>
                                    <div className='m-l-5 text-xs' style={{ color: '#191919', }}>Same marking scheme for all sections</div>
                                </div>
                            }
                            {console.log("subjectSection123",allQuesMandatory, subjectSection, subjectSection?.allQuestionsMandatoryFlag === 1, allQuesMandatory || subjectSection?.allQuestionsMandatoryFlag === 1)}
                            <div className='display-flex' style={{ justifyContent: "flex-end" }}>
                                <Checkbox style={{ fontSize: 5, marginTop: -3 }} defaultChecked={allQuesMandatory || subjectSection?.allQuestionsMandatoryFlag === 1} disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone')} /* defaultChecked={(e)=>e.target.checked} */
                                    onChange={(e) => {
                                        setAllQuesMandatory(e.target.checked);
                                        if (e.target.checked) {
                                            onFillSubjectiveSection(mainIndex, "allQuestionsMandatoryFlag", 1);
                                        } else if (!e.target.checked) {
                                            onFillSubjectiveSection(mainIndex, "allQuestionsMandatoryFlag", 0);
                                        }
                                    }}></Checkbox>
                                <div className='m-l-5 text-xs' style={{ color: '#191919', }}>All questions are mandatory</div>
                            </div>
                        </div>
                    </div>
                    {location == '1' || location == 'fromClassroom' ?
                        <>
                            {Object.keys(groupedChapters)?.map((subjectId) => {
                                if (subjectId === `${storeSubjectSection?.subjId}`) {
                                    return (
                                        <>
                                            <div className="text-xs color-black bold-600 m-b-5 m-t-20">{testCreateObj.syllabus === "Chapter" ? `${groupedChapters[subjectId].subjectName} Chapter` : `${groupedChapters[subjectId].subjectName} Chapter(s)`}</div>
                                            <div className='flex-wrap r-ac'>
                                                {chaptersArray?.map((item, index) => (
                                                    <>
                                                        {
                                                            item?.subjectId === storeSubjectSection?.subjId ?
                                                                <>
                                                                    <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{ background: '#594099' }}>
                                                                        <div className='text-xs'>{item.name}</div>
                                                                    </div>

                                                                </> :
                                                                <></>
                                                        }
                                                    </>

                                                ))}
                                            </div>
                                        </>
                                    )
                                }
                            })}
                            {/* </>
                :
                <></>} */}
                        </>
                        :
                        <>
                            {
                                testCreateObj.syllabus != '' && testCreateObj.syllabus != undefined && testCreateObj.syllabus != 'Full' && testCreateObj?.subjectName?.length > 0 && groupedChapters != undefined && Object.keys(groupedChapters)?.length > 0 && testCreateObj.standardId?.length > 0 ?
                                    <>
                                        {
                                            Object.keys(groupedChapters)?.map((subjectId) => {
                                                if (subjectId === `${storeSubjectSection?.subjId}`) {
                                                    return (
                                                        <>
                                                            <>
                                                                <div className="text-xs color-black bold-600 m-b-5 m-t-20">{testCreateObj.syllabus === "Chapter" ? `${groupedChapters[subjectId].subjectName} Chapter` : `${groupedChapters[subjectId].subjectName} Chapter(s)`}</div>
                                                                <Select
                                                                    disabled={(/* (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || */ /* (createTestModalName === 'test-clone') || */ createTestModalName === 'test-clone' || testCreateObj.class === '' || testCreateObj.class == undefined || (testCreateObj.syllabus === "Chapter" && testCreateObj.chapterList?.length >= 1)) ? true : false}
                                                                    showSearch
                                                                    value={testCreateObj.syllabus === 'Chapter' ? `Search ${groupedChapters[subjectId].subjectName} chapter` : `Search ${groupedChapters[subjectId].subjectName} Chapter(s)`}
                                                                    className='test-custom-select createTestModalValue'
                                                                    placeholder={testCreateObj.syllabus === 'Chapter' ? 'Search chapter' : 'Search Chapter(s)'}
                                                                    style={{ width: '100%', color: "#AEAEAE" }}
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={true}
                                                                    loading={loaders?.modalChapterLoader}
                                                                    filterOption={false}
                                                                    onSearch={(text) => {
                                                                        setChapterSearchSubjectId(subjectId);
                                                                        setSearchText(text);
                                                                        setSearchTextIndex('chapters');
                                                                        updateHandleRender(true)
                                                                    }}
                                                                    onSelect={(text, value) => {
                                                                        storeCreateTestModalData('chapters', { id: value.value[0], name: value.children, subjectId: value.value[1] });
                                                                    }
                                                                    }
                                                                    notFoundContent={null}
                                                                >
                                                                    {searchedGroupedChapters[subjectId]?.chapters?.map((item, index) => {
                                                                        if (testCreateObj.chapterList?.map((item) => item.id).includes(item.id)) {
                                                                            return null
                                                                        }
                                                                        return <Option className="createTestModalDropdown" key={item.id} value={[item.id, item.subjectId]}>{item.name}</Option>
                                                                    })}
                                                                </Select>
                                                                {testCreateObj.subjectName?.length
                                                                    ?
                                                                    <div className='flex-wrap r-ac'>
                                                                        {testCreateObj.chapterList?.map((item, index) => {
                                                                            if (groupedChapters[subjectId].chapters?.map(chapterObj => chapterObj.id)?.includes(item.id)) {
                                                                                return (
                                                                                    <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{ background: '#594099' }}>
                                                                                        <div className='text-xs'>{item.name}</div>
                                                                                        {
                                                                                            <AiOutlineClose
                                                                                                onClick={() => {
                                                                                                    storeDeletingChapterId(item?.id)
                                                                                                    getQuestionNumbers(
                                                                                                        () => setIsDeleteModalVisible(true)
                                                                                                    )
                                                                                                    /* removeSelectedChapter(item.id);  */
                                                                                                }}

                                                                                                className='m-l-10 text-xs cursor-pointer' />
                                                                                            // (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || createTestModalName === 'test-clone'
                                                                                            //     ? null
                                                                                            //     :
                                                                                            //     <AiOutlineClose onClick={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || (createTestModalName === 'test-clone'))
                                                                                            //         ? () => { }
                                                                                            //         : () => {
                                                                                            //             storeDeletingChapterId(item?.id)
                                                                                            //             getQuestionNumbers(
                                                                                            //                 ()=>setIsDeleteModalVisible(true)
                                                                                            //             )
                                                                                            //             /* removeSelectedChapter(item.id);  */
                                                                                            //         }}
                                                                                            //         className='m-l-10 text-xs cursor-pointer' />
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            return null
                                                                        })}
                                                                    </div>
                                                                    : null}
                                                            </>

                                                        </>
                                                    )

                                                }



                                            })
                                        }
                                    </>
                                    : null
                            }
                        </>}

                    {!sectionCheckbox ?
                        <>
                            <div className='r-jsb m-t-20'>
                                {(testCreateObj?.totalQuestions > 0 || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone') ?
                                    <>
                                        <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                                        <div className='bold-600 text-xs' style={{ color: '#636363', cursor: subjectSection?.schemeId == null || subjectSection?.schemeId == '' || subjectSection?.schemeId == undefined || subjectSection?.schemeId == -1 ? "not-allowed" : "pointer" }}
                                            onClick={() => {
                                                if (!(subjectSection?.schemeId == null || subjectSection?.schemeId == '' || subjectSection?.schemeId == undefined || subjectSection?.schemeId == -1)) {
                                                    storeMarkedSchemeCardId(subjectSection?.schemeId);
                                                    setIsEditModalVisisble(true);
                                                    fromCreateNewButton(true)
                                                }
                                            }}>| PREVIEW</div>
                                    </>
                                    :
                                    <>
                                        <div className='bold-600 text-xs' style={{ color: '#636363' }}>Marking scheme</div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ color: "#1089FF", fontWeight: "bold", fontSize: "11px", cursor: "pointer", marginRight: 5 }} onClick={() => { fromCreateNewButton(true); setFromSectionMarkingScheme(false); setIsCreateMarkingSchemeVisible(true) }}><PlusOutlined />{" "}CREATE NEW {" "}</div>
                                            <div className='bold-600 text-xs cursor-pointer' style={{ display: "flex", fontSize: "11px", color: '#636363', fontWeight: "bold" }}>
                                                <div>
                                                    {"  "}|{" "}
                                                </div>
                                                <div
                                                    style={{ marginLeft: 5, cursor: (subjectSection?.schemeId == null || subjectSection?.schemeId == '' || subjectSection?.schemeId == undefined || subjectSection?.schemeId == -1) ? subjectSection?.markingSchemeId ? "pointer" : "not-allowed" : "pointer" }}
                                                    onClick={
                                                        () => {
                                                            if ((!(subjectSection?.schemeId == null || subjectSection?.schemeId == '' || subjectSection?.schemeId == undefined)) || subjectSection?.markingSchemeId || subjectSection?.schemeId == -1) {
                                                                storeMarkedSchemeCardId(subjectSection?.markingSchemeId?.id ? subjectSection?.markingSchemeId?.id : subjectSection?.markingSchemeId ? subjectSection?.markingSchemeId : subjectSection?.schemeId);
                                                                setIsEditModalVisisble(true);
                                                                fromCreateNewButton(true)
                                                            } else {

                                                            }
                                                        }}>
                                                    PREVIEW</div>
                                            </div>
                                        </div>
                                    </>}
                            </div>
                            <Select
                                className="m-t-5 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%' }}
                                placeholder="Select marking scheme"
                                onChange={(value) => {
                                    SetSelectedValue(value)
                                    onFillSubjectiveSection(mainIndex, "markingSchemeId", value);
                                }}
                                value={subjectSection?.markingSchemeId?.title ? subjectSection?.markingSchemeId?.title : selectedvalue !== '' ? selectedvalue : subjectSection?.schemeName}
                                disabled={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone')}

                            >
                                {storeMarkingSchemeRes?.length && storeMarkingSchemeRes?.map((item) => (
                                    <Option value={item?.id} key={item.id}>{item?.title}</Option>
                                ))}
                            </Select>
                            {allQuesMandatory != true ?
                                <>
                                    <div className='r-jsb m-t-10' style={{ width: '100%' }}>
                                        <div className='flex-1 m-r-20'>
                                            <div className='bold-600 text-xs' style={{ color: '#636363', marginBottom: 5 }}>Total questions</div>
                                            <Input
                                                disabled={location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone'}
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                placeholder='Enter value'
                                                className="m-b-15 test-custom-select createTestModalValue"
                                                style={{ width: '100%', minWidth: '40%' }}
                                                defaultValue={subjectSection?.totalQuestion ? subjectSection?.totalQuestion : ''}
                                                onChange={(e) => {
                                                    onFillSubjectiveSection(mainIndex, "totalQuestions", e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <div className='bold-600 text-xs' style={{ color: '#636363', marginBottom: 5 }}>Mandatory questions</div>
                                            <Input
                                                disabled={location == '1' || location == 'fromClassroom' || createTestModalName === 'test-clone'}
                                                type="number"
                                                onKeyDown={handleKeyDown}
                                                placeholder='Enter value'
                                                className="m-b-15 test-custom-select createTestModalValue"
                                                style={{ width: '100%', minWidth: '40%' }}
                                                defaultValue={subjectSection?.mandatoryQuestion ? subjectSection?.mandatoryQuestion : ''}
                                                onChange={(e) => {
                                                    onFillSubjectiveSection(mainIndex, "mandatoryQuestions", e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                        </>
                        :
                        <></>
                    }

                    {sectionCheckbox === true ?
                        <>
                            <div className='' style={{ width: '100%' }}>
                                <div className='bold-600 text-xs m-t-20' style={{ color: '#636363', marginBottom: 5 }}>No of sections needed</div>
                                <div className='display-flex'>
                                    {[1, 2, 3, 4, 5, 6]?.map((item, index) => {
                                        return (
                                            <>
                                                <SectionCount
                                                    sectionMeta={sectionMeta}
                                                    setSectionMeta={setSectionMeta}
                                                    index={index}
                                                    item={item}
                                                    sectionCountChange={sectionCountChange}
                                                    setSectionVisible={setSectionVisible}
                                                    sectionVisible={sectionVisible}
                                                    subjectSection={subjectSection}
                                                    uncheck={uncheck}
                                                    location={location}
                                                    createTestModalName={createTestModalName}
                                                    setIsOther={setIsOther}
                                                />
                                            </>
                                        )

                                    })}
                                    <div style={{ minWidth: 60, minHeight: 40, borderRadius: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #E6E6E6', fontFamily: 'roboto' }} onClick={() => { setIsOther(true) }}>others</div>
                                    {isOther ?
                                        <div style={{ marginLeft: "40px", marginTop: "5px" }}><Input style={{ width: "300px" }} placeholder='Enter value' onChange={(e) => { setSectionMeta(parseInt(e.target.value)); setSectionVisible(true) }} /></div>
                                        : null}
                                </div>
                            </div>
                            {sectionMeta > 0 && sectionMeta !== null ?
                                <>
                                    {Array(sectionMeta)?.fill()?.map((item, index) => {
                                        return (
                                            <>
                                                {
                                                    sectionVisible ?
                                                        <SectionsNeeded
                                                            showFields={allQuesMandatory}
                                                            // storeSubjectSection={storeSubjectSection}
                                                            section={subjectSection?.sections?.[index]}
                                                            childIndex={index}
                                                            mainIndex={mainIndex}
                                                            onFillSubjectiveSection={onFillSubjectiveSection}
                                                            onSectionChange={onSectionChange}
                                                            storeMarkingSchemeRes={storeMarkingSchemeRes}
                                                            fetchMarkingSchemeData={fetchMarkingSchemeData}
                                                            testCreateObj={testCreateObj}
                                                            createTestModalName={createTestModalName}
                                                            setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
                                                            isCreateMarkingSchemeVisible={isCreateMarkingSchemeVisible}
                                                            setFromSectionMarkingScheme={setFromSectionMarkingScheme}
                                                            storeMarkedSchemeCardId={storeMarkedSchemeCardId}
                                                            fromCreateNewButton={fromCreateNewButton}
                                                            isSameMarkingScheme={isSameMarkingScheme}
                                                            location={location}
                                                        /> : null
                                                }

                                            </>
                                        )
                                    })}
                                </>
                                :
                                null
                            }
                        </>
                        : null}

                </div>
            </div>
            {isCreateMarkingSchemeVisible &&
                <CreateMarkingScheme
                    isCreateMarkingSchemeVisible={isCreateMarkingSchemeVisible}
                    setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                    mainIndex={mainIndex}
                />
            }
            {isEditModalVisible &&
                <EditMarkingSchemeModal
                    isEditModalVisible={isEditModalVisible}
                    setIsEditModalVisisble={setIsEditModalVisisble}
                    onFillSubjectiveSection={onFillSubjectiveSection}
                    mainIndex={mainIndex}
                />
            }
            {
                isDeleteModalVisible &&
                <ConfirmDeleteModal
                    isDeleteModalVisible={isDeleteModalVisible}
                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                    deleteChapter={deleteChapter}
                    totalQuesChapterData={totalQuesChapterData}
                    removeSelectedChapter={removeSelectedChapter}
                    DeleteChapterInTest={DeleteChapterInTest}
                    deletingChapterId={deletingChapterId}
                />
            }
        </>
    )
}

const EditTest = ({
    loaders, getExaminerBool, postTestEdit, fetchMarkingSchemeData, storeMarkingSchemeRes,
    storeSubjectSection, setSubjectSectionAction, removeSelectedChapter, searchedGroupedChapters,
    groupedChapters, stdsForCreatingTest, removeSelectedSearchSubjs, createTestModalSource,
    getTestClassList, createTestModalName, testCreateObj, storeCreateTestModalData, getExaminer,
    getExaminerArr, userType, getSubjects, getIndexOfTest, getSubjectsBool, getSubjectsArr,
    getTestChapterList, selectedClassroom, showGenerateQuesListView, createTestModal, user, listViewType,
    fromCreateNewButton, setFromSectionMarkingScheme, storeMarkedSchemeCardId, getQuestionNumbers,
    totalQuesChapterData, storeDeletingChapterId, DeleteChapterInTest, deletingChapterId,
    postImportSampleQuestionPaper, changeTestStatusModal, chaptersArray }) => {

    const bodyStyle = {
        background: "#FAFAFA",
        width: "calc(100% + 40px)",
        height: "calc(100% + 40px)",
        padding: 20,
        margin: -20,
        color: "FFFFFF",
    };

    const [section, includeSection] = useState(false)
    // const [sectionMeta, setSectionMeta] = useState(null)
    const [searchText, setSearchText] = useState('');
    const [searchTextIndex, setSearchTextIndex] = useState('');
    const [handleRender, updateHandleRender] = useState(false);
    const [chapterSearchSubjectId, setChapterSearchSubjectId] = useState(null);
    const [pageLoader, togglePageLoader] = useState(false);
    const [editSaveLoader, setEditSaveLoader] = useState(false);
    const [sectionValue , setSectionValue]=useState(false);
    const location = useLocation();
    const isClassScreen = window.location.pathname == '/test-std-to-subjects-screen' ? true : false;
    useEffect(()=>{
        fetchMarkingSchemeData()
    },[testCreateObj?.testType])

    // useEffect(() => {
    //     togglePageLoader(true)
    //     showGenerateQuesListView(() => {
    //         // universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', false);
    //         createTestModal("test-edit", user, 'navigation');
    //         togglePageLoader(false)
    //     }, "navigation");
    // }, [])

    useEffect(() => {
        if (createTestModalName === 'test-create' && testCreateObj.syllabus != undefined && testCreateObj.syllabus != '' && testCreateObj.subjectName?.length && testCreateObj.class != '' && testCreateObj.class != undefined) {
            getIndexOfTest();
        }
        getExaminer('');
        getSubjects('', isClassScreen ? selectedClassroom?.id : '');
        if(testCreateObj?.includeSectionFlag == 1){
            setSectionValue(true)
        }else{
            setSectionValue(false) 
        }
    }, []);

    useEffect(()=>{
        if(testCreateObj?.includeSectionFlag == 1){
            setSectionValue(true)
        }else if (testCreateObj?.includeSectionFlag == 0){
            setSectionValue(false) 
        } 
    },[])

    useEffect(() => {
        if (handleRender) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (searchTextIndex === 'examiner') {
                    getExaminer(searchText)
                    updateHandleRender(false);
                } else if (searchTextIndex === 'chapters') {
                    getTestChapterList(searchText, chapterSearchSubjectId);
                    updateHandleRender(false);
                }
                else {
                    getSubjects(searchText, isClassScreen ? selectedClassroom?.id : '')
                    updateHandleRender(false);
                }
            }, 800);
        }
    }, [searchText, searchTextIndex, handleRender])

    const syllabusTypes = [{ id: 0, type: "Chapter" }, { id: 1, type: "Part" }, { id: 2, type: "Full" }]

    const timerDisable = (dependency) => {
        for (let x of dependency) {
            let bool = testCreateObj[x] === '' || testCreateObj[x] === 'Invalid date' || testCreateObj[x] == undefined
            if (bool) return true
        }
        return false
    }

    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    }

    const disabledResultDate = (current) => {
        return current && current < moment(testCreateObj?.heldDate, "DD/MM/YYYY").subtract(1, 'day').endOf('day');
    }

    const disabledStartTimeHours = () => {
        const hours = [];
        if (moment().format('DD/MM/YYYY') === testCreateObj?.heldDate) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }

        }
        return hours;
    };

    const disabledStartTimeMinutes = (selectedHour) => {
        const minutes = [];
        if (selectedHour === moment().hour()) {
            for (var i = 0; i <= moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const disabledEndTimeHours = () => {
        const hours = [];
        for (var i = 0; i < moment(testCreateObj?.heldStartTime, 'HH:mm:ss').hour(); i++) {
            hours.push(i);
        }
        return hours
    }

    const disabledEndTimeMinutes = (selectedHour) => {
        const minutes = [];
        if (selectedHour === moment(testCreateObj.heldStartTime, 'HH:mm:ss').hour()) {
            for (var i = 0; i <= moment(testCreateObj.heldStartTime, 'HH:mm:ss').minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const disabledResultTimeHours = () => {
        const hours = [];
        if (testCreateObj.heldDate === testCreateObj.resultDate) {
            for (var i = 0; i < moment(testCreateObj.heldEndTime, 'HH:mm:ss').hour(); i++) {
                hours.push(i)
            }
        }
        return hours
    }

    const disabledResultTimeMinutes = (selectedHour) => {
        const minutes = [];
        if (testCreateObj.heldDate === testCreateObj.resultDate && selectedHour === moment(testCreateObj.heldEndTime, 'HH:mm:ss').hour()) {
            for (var i = 0; i <= moment(testCreateObj.heldEndTime, 'HH:mm:ss').minute(); i++) {
                minutes.push(i)
            }
        }
        return minutes
    }

    const subjectSectionOnChange = (mainIndex, value) => {
        setSubjectSectionAction([...storeSubjectSection.slice(0, mainIndex), value, ...storeSubjectSection.slice(mainIndex + 1)])


    }
    const selectSubject = (value) => {
        storeCreateTestModalData('subjects', { name: value.value, id: value.key });
        setSubjectSectionAction([...storeSubjectSection, { name: value.value, id: value.key }])
    }

    const DeleteSubjects = (id) => {
        let temp = storeSubjectSection
        temp.splice(id, 1);
        setSubjectSectionAction([...temp])
    }

    const CreateNewObj = (value) => {
        setSubjectSectionAction([...storeSubjectSection,
        {
            subName: value.value,
            subjId: value.key,
            markingSchemeFlag: null,
            includeSectionFlag: testCreateObj?.includeSectionFlag,
            schemeId: null,
            sections: []
        }
        ])

    }
    const PageHeader = () => {
        const history = useHistory();
        const handleBackPress = () => {
            if (location?.state?.key == '1' || location?.state?.key == "fromClassroom") {
                changeTestStatusModal('ExitTest')
            }else if(location?.state?.key == "fromClassroom"){
                history.push({pathname:'/test-std-to-subjects-screen', state:{key:"fromClassroom"}});
            }
             else if (location?.state?.key == "fromImportListViewEdit") {
                history.goBack();
                showGenerateQuesListView(() => { }, "importListView")
            } else {
                history.goBack();
                for (let i = 0; i < storeSubjectSection?.length; i++) {
                    delete storeSubjectSection[i]?.markingSchemeId
                    for (let j = 0; j < storeSubjectSection?.[i]?.sections?.length; j++) {
                        delete storeSubjectSection[i].sections[j].markingSchemeId
                    }
                }
            }
        }
        return (
            <div style={{ width: '100%' }}>
                <div className='r-jsb p-10' style={{ width: '100%' }}>
                    <div style={{display:"flex"}}>
                    <ArrowLeftOutlined style={{ marginTop: "5px", fontSize: "22px", marginRight:20 }} onClick={handleBackPress} />
                    <div className='bold-700 text-md' style={{ fontFamily: 'roboto' }}>{location?.state?.key == '1' || location?.state?.key == "fromClassroom" ? "Create Test" : listViewType === 'clone' ? "Clone Test" : "Edit Test"}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button
                            className='blankGreyBtnWrap radius-100 m-r-30'
                            style={{ width: 120, marginRight: "25px" }}
                            onClick={handleBackPress}
                        >CANCEL</Button>
                        <Button
                            loading={editSaveLoader}
                            className='purpleBtnWrap radius-100 purpleButtonHoverEffect'
                            style={{ width: 120 }}
                            onClick={() => {
                                for (let i = 0; i < storeSubjectSection?.length; i++) {
                                    storeSubjectSection[i].id = storeSubjectSection[i].id ? storeSubjectSection[i].id : storeSubjectSection[i]?.subjId
                                    storeSubjectSection[i].name = storeSubjectSection[i].name ? storeSubjectSection[i].name : storeSubjectSection[i]?.subName
                                    storeSubjectSection[i].markingSchemeId = storeSubjectSection[i].markingSchemeId?.id ? storeSubjectSection[i].markingSchemeId?.id : storeSubjectSection[i].markingSchemeId ? storeSubjectSection[i].markingSchemeId : storeSubjectSection[i]?.schemeId
                                    storeSubjectSection[i].totalQuestions = storeSubjectSection[i].totalQuestions ? storeSubjectSection[i].totalQuestions : storeSubjectSection[i]?.totalQuestion
                                    storeSubjectSection[i].mandatoryQuestions = storeSubjectSection[i].mandatoryQuestions ? storeSubjectSection[i].mandatoryQuestions : storeSubjectSection[i]?.mandatoryQuestion
                                    // delete storeSubjectSection[i]?.subName
                                    // delete storeSubjectSection[i]?.subjId
                                    // delete storeSubjectSection[i]?.schemeId
                                    // delete storeSubjectSection[i]?.totalQuestion
                                    // delete storeSubjectSection[i]?.mandatoryQuestion
                                    for (let j = 0; j < storeSubjectSection?.[i]?.sections?.length; j++) {
                                        storeSubjectSection[i].sections[j].markingSchemeId = storeSubjectSection[i].sections[j].markingSchemeId?.id ? storeSubjectSection[i].sections[j].markingSchemeId?.id : storeSubjectSection[i].sections[j].markingSchemeId ? storeSubjectSection[i].sections[j].markingSchemeId : storeSubjectSection[i].sections[j].schemeId
                                        storeSubjectSection[i].sections[j].id = storeSubjectSection[i].sections[j].id ? storeSubjectSection[i].sections[j].id : storeSubjectSection[i].sections[j].sectionId
                                        storeSubjectSection[i].sections[j].name = storeSubjectSection[i].sections[j].name ? storeSubjectSection[i].sections[j].name : storeSubjectSection[i].sections[j].sectionName
                                        storeSubjectSection[i].sections[j].totalQuestions = storeSubjectSection[i].sections[j].totalQuestions ? storeSubjectSection[i].sections[j].totalQuestions : storeSubjectSection[i].sections[j].totalQuestion
                                        storeSubjectSection[i].sections[j].mandatoryQuestions = storeSubjectSection[i].sections[j].mandatoryQuestions ? storeSubjectSection[i].sections[j].mandatoryQuestions : storeSubjectSection[i].sections[j].mandatoryQuestion
                                        // delete storeSubjectSection[i].sections[j].schemeId
                                        // delete storeSubjectSection[i].sections[j].sectionId
                                        // delete storeSubjectSection[i].sections[j].sectionName
                                        // delete storeSubjectSection[i].sections[j].totalQuestion
                                        // delete storeSubjectSection[i].sections[j].mandatoryQuestion
                                    }
                                }
                                setEditSaveLoader(true)
                                {
                                    location?.state?.key == '1' || location?.state?.key == "fromClassroom" ?
                                    postImportSampleQuestionPaper(() => {
                                        setEditSaveLoader(false)
                                    },
                                        () => {
                                            showGenerateQuesListView(() => { }, "importListView")
                                            history.push({ pathname: "/test-generate-questions-list", state: { key: location?.state?.key == "fromClassroom" ? "fromClassroom" :  2 } })
                                        })
                                    :
                                    postTestEdit(
                                        sectionValue,
                                        () => {
                                        setEditSaveLoader(false)
                                        },
                                        () => {
                                            if(location?.state?.key == "fromClassroom"){
                                                history.push({pathname:'admin-test', state:{key:"fromClassroom"}});
                                            }else{
                                                history.push('admin-test');
                                            }
                                            createTestModal(createTestModalName, userType, '');
                                        })
                                }
                            }} ><CheckCircleOutlined />{location?.state?.key == '1' || location?.state?.key == "fromClassroom" ? "CREATE" : "SAVE"}</Button>

                    </div>

                </div>
            </div>
        )
    }

    const startDate = moment(testCreateObj?.heldDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

    return (
        <div style={bodyStyle}>
            <PageHeader />
            <div className='m-t-30' style={{ overflowY: "scroll", height: "calc(100% + -80px)" }}>
                <div style={{ padding: "0px 150px 0px 150px" }}>
                    <div>
                        <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Question type</div>
                        <Select
                            disabled={testCreateObj.totalQuestions > 0 || location?.state?.key == '1' || location?.state?.key == "fromClassroom" ? true : false}
                            className='test-custom-select createTestModalValue'
                            style={{ width: '100%', borderRadius: 5 }}
                            placeholder={"Choose test type"}
                            onChange={(value) => { storeCreateTestModalData('testType', value) }}
                            value={testCreateObj?.testType}
                            bordered={true}>
                            <Option value="Objective">Objective type questions</Option>
                            <Option value="Subjective">Subjective type questions</Option>
                        </Select>
                    </div>

                    <div className='m-t-20'>
                        <div className='text-xmd bold-600 m-b-5 m-t-20' style={{ color: '#636363' }}>Title</div>
                        <Input value={testCreateObj?.title} onChange={(e) => storeCreateTestModalData('title', e.target.value)} style={{ borderRadius: 5, width: '100%' }} placeholder="Enter title" bordered={true} />
                    </div>

                    <div className='r-jsb m-t-20' style={{ width: '100%' }}>
                        <div className='flex-1 m-r-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Examiner</div>
                            <Select
                                disabled={((userType == "teacher" || createTestModalName === 'test-edit') && location?.state?.key != '1' && location?.state?.key != "fromClassroom") ? true : false}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%' }}
                                placeholder={"Search examiner"}
                                showSearch
                                loading={getExaminerBool}
                                value={(testCreateObj.teacherName == '' || testCreateObj.teacherName == undefined) ? null : testCreateObj.teacherName}
                                showArrow={userType == 'teacher' ? false : true}
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                notFoundContent={null}
                                onSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true) }}
                                onChange={(text, value) => storeCreateTestModalData('examiner', { name: value.value, id: value.key })}
                            >
                                {!getExaminerBool && getExaminerArr?.map((item) => {
                                    if (item?.id != testCreateObj?.teacherId) {
                                        return <Option key={item?.id} className="createTestModalDropdown" value={item.value}>{item.value}</Option>
                                    }
                                    return null
                                })}
                            </Select>
                        </div>

                        <div className='flex-1'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Syllabus</div>
                            <Select
                                disabled={testCreateObj.totalQuestions > 0 || location?.state?.key == '1' || location?.state?.key == "fromClassroom" ? true : false}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%', color: testCreateObj.syllabus === '' ? '#a2a2a2' : '' }}
                                placeholder={"Select syllabus"}
                                value={(testCreateObj.syllabus === '' || testCreateObj.syllabus == undefined) ? null : testCreateObj.syllabus}
                                onChange={(value) => {
                                    storeCreateTestModalData('syllabus', value);
                                    if (!isClassScreen) {
                                        storeCreateTestModalData('class', '');
                                    }
                                    storeCreateTestModalData('clearsubjects', null);
                                    storeCreateTestModalData('clearchapters', null);
                                    storeCreateTestModalData('tIndex', 0);
                                    storeCreateTestModalData('subjectsArray', []);
                                    includeSection(false);
                                    setSubjectSectionAction([]);
                                    getSubjects('', isClassScreen ? selectedClassroom?.id : '')
                                }}
                                notFoundContent={null}>
                                {syllabusTypes?.map((item, index) => (
                                    <Option key={item.id} value={item.type} className="createTestModalDropdown">{item.type}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {location?.state?.key == '1' || location?.state?.key == "fromClassroom" ?
                        <>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Subject(s)</div>
                            {
                                typeof testCreateObj?.subjectName !== 'string' ?
                                    testCreateObj?.subjectName
                                        ?
                                        <div className='flex-wrap r-ac' style={{}}>
                                            {testCreateObj?.subjectName?.map((item, index) => (
                                                <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-10 r-ac' style={{ background: '#594099' }}>
                                                    <div className='text-xs'>{item}</div>
                                                </div>
                                            ))}
                                        </div>
                                        : null
                                    : null
                            }
                        </>
                        :
                        <div>
                            <div className='r-jsb' style={{ width: '100%' }}>
                                <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Subject(s)</div>
                                <div className='display-flex'>
                                    {/* Show sections only if include sections is checked */}
                                    <Checkbox checked={section || testCreateObj?.includeSectionFlag === 1}
                                        onChange={(e) => {
                                            includeSection(e.target.checked);
                                            setSectionValue(e.target.checked);
                                            // setSectionMeta(null);
                                            if (e.target.checked === true) {

                                            } else if (e.target.checked === false) {
                                                // setSectionMeta(null);
                                                for (let i = 0; i < storeSubjectSection?.length; i++) {
                                                    delete storeSubjectSection[i]?.sections,
                                                        storeSubjectSection[i].includeSectionFlag = 0
                                                }
                                            }
                                        }} disabled={(createTestModalName === 'test-edit' && testCreateObj?.totalQuestions > 0) || createTestModalName === 'test-clone'}></Checkbox>
                                    <div className='text-xs m-l-5 m-t-5' style={{ color: '#191919' }}>Include sections</div>
                                </div>
                            </div>
                            <Select
                                disabled={(testCreateObj?.syllabus == undefined || testCreateObj?.syllabus === "" || (createTestModalName === 'test-edit' && testCreateObj?.totalQuestions > 0) || (createTestModalName === 'test-clone') || (testCreateObj.syllabus === 'Chapter' && testCreateObj.subjectName?.length >= 1) || (testCreateObj.testType === 'Subjective' && testCreateObj.subjectName?.length >= 1)) ? true : false}
                                showSearch
                                loading={loaders?.modalSubjectLoader}
                                value={testCreateObj.syllabus === 'Chapter' ? 'Search subject' : testCreateObj.testType === 'Objective' ? 'Search subject(s)' : 'Search subject'}
                                className='test-custom-select createTestModalValue'
                                style={{ width: '100%', color: "#AEAEAE" }}
                                //   placeholder={"Search subject(s)"}
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                showArrow={true}
                                onSearch={(text) => { setSearchText(text); setSearchTextIndex('subjects'); updateHandleRender(true) }}
                                onSelect={(text, value) => {
                                    storeCreateTestModalData('clearchapters', null);
                                    if (!testCreateObj?.subjectId?.includes(value.key)) {
                                        storeCreateTestModalData('subjects', { name: value.value, id: value.key });
                                        selectSubject(value)
                                        if (!isClassScreen) {
                                            storeCreateTestModalData('class', '');
                                        }
                                        else {
                                            getIndexOfTest();
                                        }
                                        getTestClassList(testCreateObj.subjectId ?? []);
                                        fetchMarkingSchemeData()
                                        if (isClassScreen && testCreateObj.syllabus !== 'Full') {
                                            getTestChapterList();
                                        }
                                    }
                                    CreateNewObj(value)
                                }}
                            >
                                {!getSubjectsBool && getSubjectsArr?.map((item, index) => {
                                    if (!testCreateObj?.subjectId?.includes("" + item.id)) {
                                        return <Option className="createTestModalDropdown" key={item?.id} value={item?.value}>{item?.value}</Option>
                                    } else {
                                        return null
                                    }
                                })}
                            </Select>
                            {
                                typeof testCreateObj?.subjectName !== 'string' ?
                                    testCreateObj?.subjectName
                                        ?
                                        <div className='flex-wrap r-ac'>
                                            {testCreateObj?.subjectName?.map((item, index) => (
                                                <div key={index} className='shadow-box white p-l-10 p-r-10 p-t-5 p-b-5 m-r-15 m-t-15 r-ac' style={{ background: '#594099' }}>
                                                    <div className='text-xs'>{item}</div>
                                                    {
                                                        (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || createTestModalName === 'test-clone'
                                                            ?
                                                            null
                                                            :
                                                            <AiOutlineClose onClick={((createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0) || (createTestModalName === 'test-clone'))
                                                                ?
                                                                () => { }
                                                                :
                                                                () => {
                                                                    DeleteSubjects(index)
                                                                    storeCreateTestModalData('removesubjectchapters', index);
                                                                    if (testCreateObj.class !== 0 && testCreateObj?.subjectId?.length !== 1) {
                                                                        getIndexOfTest();
                                                                    }
                                                                    if (testCreateObj.subjectId?.length > 1) {
                                                                        getTestClassList(testCreateObj.subjectId ?? []);
                                                                        fetchMarkingSchemeData()
                                                                        getTestChapterList();
                                                                    }
                                                                    if (createTestModalSource !== 'class' && testCreateObj?.subjectId?.length === 1) {
                                                                        storeCreateTestModalData('class', '');
                                                                        storeCreateTestModalData('tIndex', 0);
                                                                    }
                                                                    removeSelectedSearchSubjs(index, 'removeOne');
                                                                }}
                                                                className='m-l-10 text-xs cursor-pointer' />
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        : null
                                    : null
                            }
                        </div>
                    }

                    <div className='r-jsb m-t-20' style={{ width: '100%' }}>
                        <div className='flex-1 m-r-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>class</div>
                            <Select
                                disabled={((createTestModalName !== 'test-clone') && (isClassScreen || (createTestModalName === 'test-edit' && testCreateObj.totalQuestions > 0)
                                    || testCreateObj?.subjectName?.length === 0)
                                ) ? true : false}
                                onChange={(text, value) => {
                                    if (createTestModalName !== 'test-clone') {
                                        storeCreateTestModalData('clearchapters', null);
                                    }
                                    storeCreateTestModalData('standardId', value.key);
                                    storeCreateTestModalData('class', value.value);
                                    getIndexOfTest();
                                    if (createTestModalName !== 'test-clone' && testCreateObj.syllabus !== 'Full') {
                                        getTestChapterList();
                                    }
                                }}
                                loading={loaders?.modalClassLoader && !isClassScreen}
                                value={testCreateObj.class === '' ? 'Select class' : testCreateObj.class}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%' }}
                                placeholder={"Search class"}
                                showSearch
                                filterOption={false}
                                notFoundContent="No data found"
                            >
                                {location?.state?.key == '1' || location?.state?.key == "fromClassroom" ?
                                    <>
                                        {testCreateObj.standardAutocomplete?.map((item, index) => (
                                            <Option className="createTestModalDropdown" key={item.id} value={`${item.std} - ${item.section}`}>{`${item.std} - ${item.section}`}</Option>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {stdsForCreatingTest?.map((item, index) => (
                                            <Option className="createTestModalDropdown" key={item.id} value={`${item.std} - ${item.section}`}>{`${item.std} - ${item.section}`}</Option>
                                        ))}
                                    </>
                                }
                            </Select>
                        </div>

                        <div className='flex-1'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>index</div>
                            <Input
                                value={testCreateObj.tIndex === '' ? 0 : testCreateObj.tIndex}
                                className="m-b-15 test-custom-select createTestModalValue"
                                style={{ width: '100%', minWidth: '40%', borderRadius: 5 }}
                                readOnly
                                disabled={true} />
                        </div>
                    </div>

                    <div className='bold-700 text-xmd m-t-20'>Test to be held on</div>
                    <div className='r-jsb m-t-10' style={{ width: '100%' }}>
                        <div className='flex-1 m-r-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Date</div>
                            <DatePicker
                                format='DD/MM/YYYY'
                                className="createmodalbg"
                                style={{ width: '100%', minWidth: '40%' }}
                                dropdownClassName='legend-range-calendar-drpdwn'
                                value={testCreateObj?.heldDate === '' || testCreateObj?.heldDate === 'Invalid date' || testCreateObj?.heldDate === undefined ? '' : moment(testCreateObj?.heldDate, 'DD/MM/YYYY')}
                                showToday={false}
                                allowClear={false}
                                onChange={(e, date) => { storeCreateTestModalData('heldDate', date); selectedTestStartTime = date }}
                                disabledDate={disabledDate}
                            />
                        </div>

                        <div className='flex-1'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Start time</div>
                            <TimePicker
                                allowClear={true}
                                minuteStep={10}
                                showNow={false}
                                format={'HH:mm'}
                                inputReadOnly={true}
                                disabled={timerDisable(['heldDate'])}
                                disabledMinutes={disabledStartTimeMinutes}
                                disabledHours={disabledStartTimeHours}
                                style={{ width: '100%', minWidth: '40%' }}
                                className="m-b-5 test-custom-select createTestModalValue createmodalbg"
                                value={(testCreateObj?.heldStartTime === '' || testCreateObj?.heldStartTime === undefined || testCreateObj?.heldStartTime === "Invalid date") ? '' : moment(testCreateObj?.heldStartTime, 'HH:mm:ss')}
                                onChange={(e, time) => storeCreateTestModalData('heldStartTime', moment(time, 'HH:mm').format('HH:mm:ss'))}

                            />
                        </div>

                        <div className='flex-1 m-l-20'>
                            <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>End time</div>
                            <TimePicker
                                minuteStep={10}
                                allowClear={true}
                                value={(testCreateObj?.heldEndTime === '' || testCreateObj?.heldEndTime === 'Invalid date' || testCreateObj?.heldEndTime === undefined) ? '' : moment(testCreateObj?.heldEndTime, 'HH:mm:ss')}
                                format={'HH:mm'}
                                showNow={false}
                                inputReadOnly={true}
                                disabledHours={() => disabledEndTimeHours()}
                                disabledMinutes={disabledEndTimeMinutes}
                                style={{ width: '100%', minWidth: '40%' }}
                                disabled={timerDisable(['heldDate', 'heldStartTime'])}
                                className="m-b-5 test-custom-select createTestModalValue createmodalbg"
                                onChange={(e, time) => storeCreateTestModalData('heldEndTime', moment(time, 'HH:mm').format('HH:mm:ss'))} />
                        </div>
                    </div>
                    {testCreateObj.testType == 'Subjective'
                        ?
                        null
                        :
                        <>
                            <div className='bold-700 text-xmd m-t-20'>Result to be published on </div>
                            <div className='r-jsb m-t-10' style={{ width: '100%' }}>
                                <div className='flex-1 m-r-20'>
                                    <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Date</div>
                                    <DatePicker
                                        format='DD/MM/YYYY'
                                        allowClear={false}
                                        showToday={false}
                                        disabledDate={disabledResultDate}
                                        style={{ width: '100%', minWidth: '40%' }}
                                        dropdownClassName='legend-range-calendar-drpdwn'
                                        className="m-b-15 test-custom-select createTestModalValue"
                                        onChange={(e, date) => storeCreateTestModalData('resultDate', date)}
                                        disabled={timerDisable(['heldDate', 'heldStartTime', 'heldEndTime'])}
                                        value={(testCreateObj?.resultDate === '' || testCreateObj?.resultDate == 'Invalid date' || testCreateObj?.resultDate == undefined) ? '' : moment(testCreateObj?.resultDate, 'DD/MM/YYYY')}
                                    />
                                </div>

                                <div className='flex-1'>
                                    <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Time</div>
                                    <TimePicker
                                        allowClear={true}
                                        showNow={false}
                                        minuteStep={30}
                                        format={'HH:mm'}
                                        inputReadOnly={true}
                                        style={{ width: '100%', minWidth: '40%' }}
                                        disabledMinutes={disabledResultTimeMinutes}
                                        disabledHours={() => disabledResultTimeHours()}
                                        className="m-b-15 test-custom-select createTestModalValue createmodalbg"
                                        disabled={timerDisable(['heldDate', 'heldStartTime', 'heldEndTime', 'resultDate'])}
                                        value={(testCreateObj?.resultTime === '' || testCreateObj?.resultTime === 'Invalid date' || testCreateObj?.resultTime == undefined) ? '' : moment(moment(testCreateObj?.resultTime, "HH:mm A").format("HH:mm"), "HH:mm:ss")}
                                        onChange={(e, time) => storeCreateTestModalData('resultTime', moment(time, 'HH:mm').format('HH:mm:ss'))} />


                                </div>
                            </div>
                        </>
                    }


                    <div>
                        <div className='bold-600 text-xmd' style={{ color: '#636363', marginBottom: 5 }}>Instructions</div>
                        <TextArea
                            style={{ width: '100%' }}
                            value={testCreateObj?.instruction}
                            className='test-custom-select createTestModalValue'
                            onChange={(e) => storeCreateTestModalData('instruction', e.target.value)}
                        />
                    </div>
                    {
                        typeof testCreateObj?.subjectName !== 'string' ?
                            testCreateObj?.subjectName?.map((item, mainIndex) => {
                                return <SubjectSectionsCard
                                    item={item}
                                    testCreateObj={testCreateObj}
                                    groupedChapters={groupedChapters}
                                    setChapterSearchSubjectId={setChapterSearchSubjectId}
                                    setSearchText={setSearchText}
                                    setSearchTextIndex={setSearchTextIndex}
                                    updateHandleRender={updateHandleRender}
                                    storeCreateTestModalData={storeCreateTestModalData}
                                    searchedGroupedChapters={searchedGroupedChapters}
                                    createTestModalName={createTestModalName}
                                    removeSelectedChapter={removeSelectedChapter}
                                    sectionCheckbox={section}
                                    mainIndex={mainIndex}
                                    storeSubjectSection={storeSubjectSection[mainIndex]}
                                    subjectSectionOnChange={subjectSectionOnChange}
                                    storeMarkingSchemeRes={storeMarkingSchemeRes}
                                    fetchMarkingSchemeData={fetchMarkingSchemeData}
                                    includeSection={includeSection}
                                    loaders={loaders}
                                    fromCreateNewButton={fromCreateNewButton}
                                    setFromSectionMarkingScheme={setFromSectionMarkingScheme}
                                    storeMarkedSchemeCardId={storeMarkedSchemeCardId}
                                    getQuestionNumbers={getQuestionNumbers}
                                    totalQuesChapterData={totalQuesChapterData}
                                    storeDeletingChapterId={storeDeletingChapterId}
                                    DeleteChapterInTest={DeleteChapterInTest}
                                    deletingChapterId={deletingChapterId}
                                    location={location?.state?.key}
                                    section={section}
                                    chaptersArray={chaptersArray}
                                // sectionMeta={sectionMeta}
                                // setSectionMeta={setSectionMeta}
                                />
                            }) :
                            null
                    }
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
            <TestPublishModal location={location}/>
        </div>
    )
}

const mapStateToProps = state => {
    const { testCreateObj, getExaminerArr, getExaminerBool, getSubjectsBool, getSubjectsArr,
        universalLoaderBool, getIndexOfTestBool, stdsForCreatingTest, createTestModalBool, createTestModalName,
        generateQuesObj, userType, selectedTest, listViewType, createTestModalSource, selectedClassroomId, chaptersForCreatingTest,
        selectedClassroom, loaders, groupedChapters, searchedGroupedChapters, storeSubjectSection, storeMarkingSchemeRes,
        totalQuesChapterData, deletingChapterId, chaptersArray } = state.testAdmin;
    const { user } = state.session;
    return {
        testCreateObj, getExaminerArr, getExaminerBool, getSubjectsBool, getSubjectsArr,
        universalLoaderBool, getIndexOfTestBool, stdsForCreatingTest, createTestModalBool, createTestModalName,
        generateQuesObj, userType, selectedTest, listViewType, createTestModalSource, selectedClassroomId, chaptersForCreatingTest,
        selectedClassroom, loaders, groupedChapters, searchedGroupedChapters, storeSubjectSection, storeMarkingSchemeRes, user,
        totalQuesChapterData, deletingChapterId, chaptersArray
    };
};

const mapDispatchToProps = dispatch => ({
    storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
    getSubjects: (text, standardId) => dispatch(getSubjects(text, standardId)),
    getExaminer: (text) => dispatch(getExaminer(text)),
    removeSelectedSearchSubjs: (index, text) => dispatch(removeSelectedSearchSubjs(index, text)),
    postTestEdit: (sectionValue, callback, success) => dispatch(postTestEdit(sectionValue,callback, success)),
    getIndexOfTest: () => dispatch(getIndexOfTest()),
    createTestModal: (name, userType, source) => dispatch(createTestModal(name, userType, source)),
    showGenerateQuesListView: (callback, pagesource) => dispatch(showGenerateQuesListView(callback, pagesource)),
    updateSelectedCardType: (type) => dispatch(updateSelectedCardType(type)),
    getTestClassList: (classIds) => dispatch(getTestClassList(classIds)),
    getTestChapterList: (search, subId, iscreate, stdid) => dispatch(getTestChapterList(search, subId, iscreate, stdid)),
    removeSelectedChapter: (id) => dispatch(removeSelectedChapter(id)),
    setSubjectSectionAction: (val) => dispatch(setSubjectSectionAction(val)),
    fetchMarkingSchemeData: () => dispatch(fetchMarkingSchemeData()),
    fromCreateNewButton: (bool) => dispatch(fromCreateNewButton(bool)),
    setFromSectionMarkingScheme: (val) => dispatch(setFromSectionMarkingScheme(val)),
    storeMarkedSchemeCardId: (val) => dispatch(storeMarkedSchemeCardId(val)),
    getQuestionNumbers: (callBack) => dispatch(getQuestionNumbers(callBack)),
    storeDeletingChapterId: (val) => dispatch(storeDeletingChapterId(val)),
    DeleteChapterInTest: (callBack) => dispatch(DeleteChapterInTest(callBack)),
    postImportSampleQuestionPaper: (callback, success) => dispatch(postImportSampleQuestionPaper(callback, success)),
    changeTestStatusModal: (name) => dispatch(changeTestStatusModal(name)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditTest); 