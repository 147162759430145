import { Modal } from "antd";
import React, { useState } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import {
  getStats,
  setAutoApproval,
  updateApprovalStatus,
} from "actions/userManagement";

const { Option } = Select;
function ApprovalModal({
  isApprovalVisible,
  showApprovalModal,
  handleApprovalCancel,
  handleApprovalOk,
  dashboardData,
  updateApprovalStatus,
  getStats,
  setPageLoader,
  setAutoApproval,
  autoApproval,
}) {
  const [loader, toggleLoader] = useState(false);

  const successCallback = () => {
    toggleLoader(false);
    handleApprovalOk();
    setPageLoader(true);
    getStats(() => setPageLoader(false));
  };

  function handleChange(value) {
    toggleLoader(true);
    console.log(`selected ${value}`);
    setAutoApproval(value);
    updateApprovalStatus(successCallback, value);
  };
  
  return (
    <div>
      <Modal
        maskClosable={false}
        className="approvalModal"
        title="Basic Modal"
        visible={isApprovalVisible}
        onOk={handleApprovalOk}
        onCancel={handleApprovalCancel}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Roboto",
            marginBottom: "50px",
            fontWeight: "700",
          }}
        >
          Settings
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "50px",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3
              style={{
                fontSize: "18px",
                fontWeight: "700",
                fontFamily: "Roboto",
              }}
            >
              Students' registration request(s)
            </h3>
            <p
              style={{
                fontSize: "12px",
                fontFamily: "Roboto",
                color: "#636363",
              }}
            >
              Enable students' registration request(s) to be auto/manually
              approved
            </p>
          </div>
          <div>
            <Select
              style={{ width: 150 }}
              onChange={handleChange}
              placeholder={"Select option"}
              size="middle"
              className="approvalMenu"
              loading={loader}
              value={autoApproval}
            >
              <Option value={true}>Auto approval</Option>
              <Option value={false}>Manual approval</Option>
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    sectionDetails,
    dashBoardDataByRole,
    studentsListByStd,
    autoApproval,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashBoardDataByRole,
    dashboardData,
    sectionDetails,
    studentsListByStd,
    autoApproval,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStats: (m) => dispatch(getStats(m)),
  updateApprovalStatus: (callback, val) => dispatch(updateApprovalStatus(callback, val)),
  setAutoApproval: (m) => dispatch(setAutoApproval(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalModal);
