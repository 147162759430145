import {
  assignCourseToStudent,
  getStudentsDetailsById,
  setCourseDetails,
} from "actions/userManagement";
import { Button, Checkbox, Input, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
// import Date_Picker from "./datePickerForCourse";
import StartDate_Picker from "./dateSelecter";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import { fetchStudentsClassList, fetchAutocompleteCoursesList } from 'actions/userManagementV2'

function AddCourseModal({
  isModalVisible,
  handleOk,
  handleCancel,
  isAdditionModal,
  setCourseDetails,
  studentCourseDetails,
  setViewAddCourseModal,
  userDetailsById,
  assignCourseToStudent,
  getStudentsDetailsById,
  fetchStudentsClassList,
  fetchAutocompleteCoursesList,
  courses,
  autocompleteCoursesData
}) {
  const [courseName, setCourseName] = useState(null);
  const [courseType, setCourseType] = useState('course');
  const [courseStartDate, setCourseStartDate] = useState(null);
  const [courseNature, setCourseNature] = useState("add");
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [renderEndDate, setRenderEndDate] = useState(false);

  useEffect(() => {
    if (studentCourseDetails?.isSectionChanged?.courseUpdate?.coursePopUp == true) {
 
      setCourseDetails("courseId", studentCourseDetails?.isSectionChanged?.courseUpdate?.courseId);
      setCourseName(studentCourseDetails?.isSectionChanged?.courseUpdate?.courseId);
    }
  }, [studentCourseDetails?.isSectionChanged]);

  useEffect(() => {
    if (userDetailsById?.student?.preferredCourseCard?.preferredCourseCard == true) {
      setCourseName(userDetailsById?.student?.preferredCourseCard?.prefferedCourse);
    }
  }, [userDetailsById?.student?.preferredCourseCard]);

  useEffect(() => {
    fetchAutocompleteCoursesList({ emptySearch: true, names: true, fromCourse: true }, () => { })
  }, [])

  const handleCourseChange = (value) => {

    const selectedItem = autocompleteCoursesData.find((item) => item.name === value);
 
    setCourseName(selectedItem?.itemId)
    setCourseType(selectedItem?.itemType)
    setCourseDetails("courseType", selectedItem?.itemType)
  };


  return (
    <div>
      <Modal
        maskClosable={false}
        closable={false}
        className="scholorshipAndAdditionModalStyles"
        title="Basic Modal"
        visible={isModalVisible}
        onOk={() => {
          setViewAddCourseModal(false);
          setCourseType('course')
        }}
        onCancel={() => {
          setViewAddCourseModal(false);
          setCourseDetails("sectionCoursePop", false);
          setCourseDetails("preferredPop", false);
          setCourseDetails("dismissCard", false);
          setCourseType('course')
        }}
      >
        <div
          className="flex_column_center"
          style={{
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "roboto",
            justifyContent: "center",
            marginBottom: "35px",
          }}
        >
          Pick a course
        </div>
        <div style={{ width: "100%" }}>
          <p className="dropDownLabelBasicStyle" style={{ marginBottom: courseType == 'course' ? '0px' : '30px' }}>Course name</p>
          <Select
            size="middle"
            placeholder="Select Course name"
            style={{ width: "97%", marginBottom: "20px" }}
            defaultValue={studentCourseDetails?.sectionCoursePop ? studentCourseDetails?.isSectionChanged?.courseUpdate?.courseName : userDetailsById?.student?.preferredCourseCard?.preferredCourseCard ? userDetailsById?.student?.preferredCourseCard?.preferredCourseName : null}
            onChange={handleCourseChange}
          >
            {autocompleteCoursesData?.map((item, idx) => {

              return <Option key={item?.id} value={item.name} >{item?.name}</Option>
            }
            )}
          </Select>
        </div>

        {courseType == 'course' ? <div style={{ width: "97%" }}>
          <p className="dropDownLabelBasicStyle">Start date</p>
          <StartDate_Picker
            disabledate={courseName}
            value={courseStartDate}
            setCourseStartDate={setCourseStartDate}
            setRenderEndDate={setRenderEndDate}
          />
        </div> : <></>}
        {userDetailsById?.student?.userAssignedCourses?.length > 0 && courseType == 'course' ? (
          <div
            style={{
              color: "#636363",
              fontFamily: "roboto",
              marginTop: "25px",
              marginBottom: "50px",
            }}
          >
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nature of new course
            </div>
            <div
              className="flex_row_center"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <Checkbox
                  checked={courseNature == "add" ? true : false}
                  onChange={() => {
                    setCourseNature("add");
                  }}
                >
                  Add a new course
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={courseNature == "shift"}
                  onChange={() => {
                    setCourseNature("shift");
                  }}
                >
                  Shift to a new course
                </Checkbox>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: courseType == 'course' ? "100px" : '0px' }}></div>
        )}
        <div
          className="flex_row_center"
          style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <Button
            onClick={() => {
              setViewAddCourseModal(false);
              setCourseDetails("sectionCoursePop", false);
              setCourseDetails("preferredPop", false);
              setCourseDetails("dismissCard", false);
            }}
          >
            CANCEL
          </Button>
          <Button
            loading={saveBtnLoader}
            disabled={
              courseType === 'course'
                ? courseName == null || courseName === "" || courseStartDate == null || courseStartDate === ""
                : courseName == null || courseName === ""
            }
            onClick={() => {
              setSaveBtnLoader(true);
              setCourseDetails("addCourse", {
                name: courseName,
                startDate: courseStartDate,
                natureOfCourse: courseNature,
              });
              assignCourseToStudent(
                () => {
                  setSaveBtnLoader(false);
                },
                () => {
                  setCourseDetails("preferredPop", false);
                  setCourseDetails("dismissCard", false);
                  setCourseDetails("sectionCoursePop", false);

                  setViewAddCourseModal(false);
                  setCourseDetails("addCourse", {

                    name: null,
                    startDate: null,
                    natureOfCourse: null,
                  });
                  setPageLoader(true);
                  getStudentsDetailsById(() => setPageLoader(false));
                }
              );
            }}
            style={{ backgroundColor: "#594099", color: "white" }}
          >
            SAVE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  } = state.userManagement;

  const { courses, autocompleteCoursesData } = state.userManagementV2

  return {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
    courses,
    autocompleteCoursesData
  };
};

const mapDispatchToProps = (dispatch) => ({
  assignCourseToStudent: (callback, successCallback) => dispatch(assignCourseToStudent(callback, successCallback)),
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  getStudentsDetailsById: (callback) => dispatch(getStudentsDetailsById(callback)),
  fetchStudentsClassList: (params, callback) => dispatch(fetchStudentsClassList(params, callback)),
  fetchAutocompleteCoursesList: (params, callBack) => dispatch(fetchAutocompleteCoursesList(params, callBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseModal);
