import fileDownload from "js-file-download";
import { Api } from "../services";
import { ONLINE_TEST_SERIES_COMBINEDATA } from "./onlineTestactions";
import Axios from "axios";

export const UPDATE_PALATE_DATA = "UPDATE_PALATE_DATA";
export const UPDATE_COUNT_DATA = "UPDATE_COUNT_DATA";
export const UPDATE_CURRENT_Q_ID = "UPDATE_CURRENT_Q_ID";
export const UPDATE_PREVIOUS_Q_ID = "UPDATE_PREVIOUS_Q_ID";
export const SET_CURRENT_INDEX = "SET_CURRENT_INDEX";
export const UPDATE_CURRENT_Q = "UPDATE_CURRENT_Q";
export const UPDATE_Q_PARAM = "UPDATE_Q_PARAM";
export const UPDATE_ANSWER_OBJ = "UPDATE_ANSWER_OBJ";
export const UPDATE_TEST_RESULT = "UPDATE_TEST_RESULT";
export const UPDATE_INDIVIDUAL_TEST = "UPDATE_INDIVIDUAL_TEST";
export const UPDATE_CREATE_PAYLOAD = "UPDATE_CREATE_PAYLOAD";
export const SET_CREATE_PAYLOAD = "SET_CREATE_PAYLOAD";
export const STORE_MARKING_SCHEME_DATA = "STORE_MARKING_SCHEME_DATA";
export const STORE_ALL_SUBJECTS = "STORE_ALL_SUBJECTS";
export const INDIVIDUAL_TEST_ID = "INDIVIDUAL_TEST_ID";
export const STORE_ONLINE_TEST_SERIES_ID = "STORE_ONLINE_TEST_SERIES_ID"
export const UPDATE_LIST_VIEW_DATA = "UPDATE_LIST_VIEW_DATA";
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS'
export const SET_OWNED_TEST_SERIES = 'SET_OWNED_TEST_SERIES'



export const clearQuestions = () => ({
	type: CLEAR_QUESTIONS,

})


export const updateListViewData = (data) => ({
	type: UPDATE_LIST_VIEW_DATA,
	data,
});

export const updatePalateData = (data) => ({
	type: UPDATE_PALATE_DATA,
	data,
});

export const updateCountData = (value, key) => ({
	type: UPDATE_COUNT_DATA,
	countData: { [key]: value },
});


export const updateCurrentQId = (currentQId) => ({
	type: UPDATE_CURRENT_Q_ID,
	currentQId,
});

export const updatePreviousQId = (previousQId) => ({
	type: UPDATE_PREVIOUS_Q_ID,
	previousQId,
});

export const setCurrentIndex = (index) => ({
	type: SET_CURRENT_INDEX,
	index,
});

export const updateCurrentQ = (data) => ({
	type: UPDATE_CURRENT_Q,
	data,
});

export const updateAnswerObj = (data) => ({
	type: UPDATE_ANSWER_OBJ,
	data,
});


export const updateQParam = (val, key) => ({
	type: UPDATE_Q_PARAM,
	qParam: { [key]: val },
});

export const updateTestResult = (data) => ({
	type: UPDATE_TEST_RESULT,
	data,
});

export const updateIndividualTest = (data) => ({
	type: UPDATE_INDIVIDUAL_TEST,
	data,
});

export const updateCreatePayload = (val, key) => ({
	type: UPDATE_CREATE_PAYLOAD,
	pl: { [key]: val },
});

export const setCreatePayload = (data) => ({
	type: SET_CREATE_PAYLOAD,
	data,
});

const storeMarkingSchemeData = (data) => ({
	type: STORE_MARKING_SCHEME_DATA,
	data
})

const storeAllSubjects = (data) => ({
	type: STORE_ALL_SUBJECTS,
	data
})


export const setIndividualTestId = (data) => ({
	type: INDIVIDUAL_TEST_ID,
	data
})

export const setOnlineTestSeriesId = (data) => ({
	type: STORE_ONLINE_TEST_SERIES_ID,
	data
})

export const setOwnedTestSeries = (data) => ({
	type: SET_OWNED_TEST_SERIES,
	data
})

export const fetchMarkingSchemeDataTS = () => {
	return (dispatch, getState) => {
		const params = {
			orgurl: Api?._headers?.orgurl,
			testType: 'Objective'
		}
		return Api.get(`/marking-scheme/autocomplete`).params(params).send((response) => {
			if (response) {
				dispatch(storeMarkingSchemeData(response))
			}
		})
	}
}


export const fetchAllSubjects = () => {
	return (dispatch, getState) => {
		const { onlineTestSeriesId } = getState().marketTest

		const params = {
			testSeriesId: onlineTestSeriesId
		}

		return Api.get(`/testSeries/autocomplete`).params(params).send((response) => {
			if (response?.show?.type != "error") {
				dispatch(storeAllSubjects(response))
			}
		})
	}
}


export const startTest = (callback) => {
	return (dispatch, getState) => {
		const url = `/testSeries/startTest`;

		const { individualTestId } = getState().marketTest

		const params = {
			individualTestId,
		};

		Api.get(url)
			.params(params)
			.send((response, error) => {
				if (callback) {
					callback();
				}
				if (response) {
					dispatch(updatePalateData(response.subjectSectionDetails));
					dispatch(
						updateCurrentQId(
							response.subjectSectionDetails[0].sections[0].questions[0].qId
						)
					);
					dispatch(
						setCurrentIndex(
							response.subjectSectionDetails[0].sections[0].questions[0].index
						)
					);

					dispatch(getQuestionOngoing());
				}
			});
	};
};

export const getQuestionOngoing = (buttonLoaderCallBack = null, saveCheck) => {

	let counter = saveCheck == 'saveCheck' ? 1 : 0

	console.log('counter',saveCheck,counter);

	return (dispatch, getState) => {
		const { currentQId, previousQId, palateData, individualTestId } = getState().marketTest;

		const url = `/testSeries/questionOngoing/${individualTestId}`;

		const params = {
			currentQId,
			previousQId,
		};

		Api.get(url)
			.params(params)
			.send((response, error) => {
				if (response) {
					dispatch(updateCurrentQ(response.question))
					dispatch(updateAnswerObj(response.question.attempt))

					dispatch(updateCountData(response.Attempted, "attempted"));
					dispatch(
						updateCountData(
							response.AttemptedAndBookmarked,
							"attemptedAndBookmarked"
						)
					);
					dispatch(updateCountData(response.Bookmarked, "bookmarked"));
					dispatch(updateCountData(response.Unseen > 1 ? response.Unseen + counter : response.Unseen, "unseen"));
					dispatch(updateCountData(response.Unseen > 1 ? response.Unanswered - counter : response.Unanswered, "unanswered"));
					dispatch(updateCountData(counter == 1? true : false , "saveCheck"));


					const { currentQStatus, previousQStatus } = response;
					palateData.forEach(subject => {
						subject.sections.forEach((section) => {
							section.questions.forEach((question) => {
								if (question.qId == currentQStatus.qId) question.status = currentQStatus.status
								if (question.qId == previousQStatus.qId) question.status = previousQStatus.status
							})
						})
					})

					dispatch(updatePalateData(palateData))
				}
				if (buttonLoaderCallBack) buttonLoaderCallBack();
			});
	};
};

export const navigateQuestion = (
	item,
	status,
	testStatus,
	buttonLoaderCallBack,
	saveCheck
) => {

	console.log('auto status1',saveCheck);
	return (dispatch, getState) => {
		const url = `/testSeries/studentAnswer`;

		let { currentQ, answerObj } = getState().marketTest;
		answerObj = answerObj == null || answerObj?.answer == "" ? null : answerObj;
		const {  currentDuration } =
		getState().studentTest;

		const params = {
			testQuestionId: currentQ?.id,
			answerObj:
				status ||
					(currentQ?.submitStatus != "Attempted" &&
						currentQ?.submitStatus != "AttemptedAndBookmarked")
					? answerObj
					: currentQ.attempt,
			submitStatus:
				status ??
				(currentQ?.submitStatus == "Unseen"
					? "Unanswered"
					: currentQ?.submitStatus),
			duration: currentDuration, //todoooo
			testStatus,
		};

		Api.post(url)
			.params(params)
			.send((response, error) => {
				if (response) {
					dispatch(updatePreviousQId(currentQ.id));
					dispatch(updateCurrentQId(item.qId));
					dispatch(setCurrentIndex(item.index));
					if (testStatus != 'Submitted') {
						dispatch(getQuestionOngoing(buttonLoaderCallBack, saveCheck));

					}
				}
			});
	};
};


export const getSolutions = (loaderCallback) => {
	return (dispatch, getState) => {
		const url = `/testSeries/studentSolution`;

		const { individualTestId } = getState().marketTest

		const params = {
			testId: individualTestId,
		};

		Api.get(url)
			.params(params)
			.send((response, error) => {
				if (response) {
					dispatch(updatePalateData(response.subjectSectionDetails))
					dispatch(
						setCurrentIndex(
							response.subjectSectionDetails[0].sections[0].questions[0].index
						)
					);
				}
				if (loaderCallback) loaderCallback()
			});
	};
};


export const getResult = (loaderCallback) => {
	return (dispatch, getState) => {
		const url = `/testSeries/studentResult`;

		const { individualTestId } = getState().marketTest

		const params = {
			testId: individualTestId,
		};

		Api.get(url)
			.params(params)
			.send((response, error) => {
				if (response) {
					dispatch(updateTestResult(response))
				}
				if (loaderCallback) loaderCallback()
			});
	};
};


export const getListView = (loaderCallback) => {
	return (dispatch, getState) => {
		const url = `/testSeries/getListView`;

		let { individualTestId } = getState().marketTest

		const params = {
			id: individualTestId,
		};

		Api.get(url)
			.params(params)
			.send((response, error) => {
				if (response) {
					dispatch(updateListViewData(response))
				}
				if (loaderCallback) loaderCallback()
			});
	};
}

export const getTest = (loaderCallback, successCallBack = null) => {
	return (dispatch, getState) => {
		const url = `/testSeries/getTest`;

		let { individualTestId, onlineTestSeriesId } = getState().marketTest

		if (!individualTestId) {
			dispatch(setCreatePayload({
				title: null,
				testDate: null,
				startTime: null,
				duration: null,
				freePreview: false,
				subjectsSection: [],
				testSeriesId: onlineTestSeriesId
			}))
			if (loaderCallback) loaderCallback()
			return
		}

		const params = {
			id: individualTestId,
		};

		Api.get(url)
			.params(params)
			.send((response, error) => {
				if (response) {
					dispatch(updateIndividualTest(response))

					dispatch(updatePalateData(response?.individualTestSubjects))


					const createPl = {
						id: response.id,
						title: response.title,
						testDate: response.publishDate,
						startTime: response.startTime,
						duration: response.duration,
						freePreview: response.freePreview,
						subjectsSection: [],
						testSeriesId: onlineTestSeriesId
					}

					response?.individualTestSubjects?.forEach((subject, index) => {
						const subjectPl = {}
						subjectPl['name'] = subject?.testSeriesSubject.subject.name
						subjectPl['individualTestSubjectId'] = subject.id
						subjectPl['testSeriesSubjectId'] = subject.testSeriesSubjectId
						subjectPl['sections'] = []
						subjectPl['chosenChapters'] = subject?.testSeriesSubject?.chapters

						subject?.individualTestSection?.forEach((section, idx) => {
							subjectPl.sections.push({
								id: section?.id,
								name: section?.name,
								markingSchemeId: section?.markingSchemeData?.id,
								markingSchemeTitle: section?.markingSchemeData?.title,
								totalQuestions: section?.totalQuestions,
								mandatoryQuestions: section?.mandatoryQuestions
							})
						})

						createPl['subjectsSection'].push(subjectPl)
					})

					dispatch(setCreatePayload(createPl))

					if (successCallBack) successCallBack()
				}
				if (loaderCallback) loaderCallback()
			});
	};
};


export const createTest = (loaderCallback = null, successCallBack = null) => {
	return (dispatch, getState) => {
		const url = `/testSeries/createTest`;

		const { createPayload } = getState().marketTest

		Api.post(url)
			.params(createPayload)
			.send((response, error) => {
				if (response?.show?.type == "success") {
					dispatch(setIndividualTestId(response?.individualTestId))
					dispatch(getTest(loaderCallback, successCallBack))
				}
				else loaderCallback()
			});
	};
};

export const downloadReportTestSeries =
	(id, callback = null, successCallBack = null) =>
		(dispatch, getState) => {
			let params = {
				id: id,
				download: true,
			}
			return Api.get("/market/getTestSeriesDetails").params(params).send((response) => {
				if (callback) {
					callback();
				}
				if (response !== undefined) {

					let fileName = response.path.split('/')[response.path.split('/').length - 1];
					Axios.get(response.path, {
						responseType: "blob",
					}).then((res) => {
						if (successCallBack) {
							successCallBack();
						}
						fileDownload(res.data, fileName);
					});
				}
			});
		};

export const importQPaper = (loaderCallback = null, successCallBack = null) => {
	return (dispatch, getState) => {
		const url = `/testSeries/importQPaper`;

		let { onlineTestSeriesId } = getState().marketTest
		const { importMeta } = getState().testAdmin;

		const payload = {
			questionPaperId: importMeta?.selectedPaperObj?.id,
			testSeriesId: onlineTestSeriesId
		}

		Api.post(url)
			.params(payload)
			.send((response, error) => {
				if (response?.show?.type == "success") {
					dispatch(setIndividualTestId(response?.individualTestId))
					successCallBack()
				}
				else loaderCallback()
			});
	};
};


export const importSelectedQ = (loaderCallback = null, successCallBack = null) => {
	return (dispatch, getState) => {
		const url = `/testSeries/importSelectedQ`;

		const { importMeta } = getState().testAdmin;
		let { individualTestId } = getState().marketTest

		let questions = [];
		for (let topic of importMeta.selectionTree) {
			for (let questionId of topic.selectedQuestions) {
				questions.push({
					questionId,
					positiveMarks: importMeta.marksList[questionId]?.positiveMark,
					negativeMarks: importMeta.marksList[questionId]?.negativeMark,
					subQuestionMarks: importMeta.marksList[questionId]?.subQuestionMarks
				})
			}
		}
		const payload = {
			questions,
			individualTestId
		}
		Api.post(url)
			.params(payload)
			.send((response, error) => {
				if (response?.show?.type == "success") {
					dispatch(getTest(loaderCallback, successCallBack))
				}
				else loaderCallback()
			});
	}
}



export const getQuestion = (loaderCallback, successCallBack) => {
	return (dispatch, getState) => {

		const { currentQId } = getState().marketTest

		const url = `/testSeries/getQuestion/${currentQId}`;

		Api.get(url)
			.params()
			.send((response, error) => {
				if (response) {
					dispatch(updateCurrentQ({
						...response,
						sectionId: response.individualTestSectionId
					}))


					if (successCallBack) successCallBack()
				}
				if (loaderCallback) loaderCallback()
			});
	};
};

export const saveQuestion = (loaderCallback, successCallBack = null) => {
	return (dispatch, getState) => {

		const { currentQ } = getState().marketTest

		const url = `/testSeries/createQuestion`;

		Api.post(url)
			.params(currentQ)
			.send((response, error) => {
				if (response?.show.type == "success") {
					dispatch(updateIndividualTest(response?.individualTest))

					dispatch(updatePalateData(response?.individualTest?.individualTestSubjects))

					if (successCallBack) successCallBack()
				}
				if (loaderCallback) loaderCallback()
			});
	};
};


export const deleteQuestion = (loaderCallback, successCallBack) => {
	return (dispatch, getState) => {

		const { currentQId } = getState().marketTest

		const url = `/testSeries/question/${currentQId}`;

		Api.delete(url)
			.params()
			.send((response, error) => {
				if (response?.show.type == "success") {
					dispatch(updateIndividualTest(response?.individualTest))

					dispatch(updatePalateData(response?.individualTest?.individualTestSubjects))

					if (successCallBack) successCallBack()
				}
				if (loaderCallback) loaderCallback()
			});
	};
};
