import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { Dropdown, Menu, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { MdMoreVert } from 'react-icons/md';
import {
  fetchTeacherData, fetchAdminLibraryData, updateSetSort, fetchAdminChapterFilter, updateSetTopic, updateChapterFilter, toogleEditModal,
  deleteNotes, updateSetType, fetchTeacherLibraryData
} from 'actions/digitalLibrary'

import { Api } from 'services';

import { connect } from 'react-redux'
import EditInformation from '../modals/editInformation';
import CloneInformation from '../modals/cloneInformation';
import ReactPlayerHoc from 'components/ReactPlayerHoc';

const { confirm } = Modal


function Videocomponent({
  url, topic, chapterName, id, fileSize, toogleEditModal, deleteNotes, types, updateSetType, chapterId, user,file
}) {

  useEffect(() => {
    fetchAdminChapterFilter()
  }, [])
  const handleDeletenotes = (id) => {
    return confirm({
      title: `Do you want to delete the content?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Please click OK to confirm',
      onOk() {
        deleteNotes(id)
        updateSetType(types)
        if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes('digital-library-dataoperator')) {
          fetchAdminLibraryData()
        }
        else {
          fetchTeacherLibraryData()
        }
      },
      onCancel() { },
    })

  }
  const [isVisibl, setIsModalVisibl] = useState(false)
  const [modal, setmodal] = useState(false)
  const [cloneModal, setCloneModal] = useState(false)
  const menuVideo = (
    <Menu style={{ width: "100px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
      <Menu.Item key="1" onClick={() => setIsModalVisibl(true)} icon={< EditOutlined />}>
        Edit
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setCloneModal(true)} icon={<CopyOutlined />}>
        Clone
      </Menu.Item>
      <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeletenotes(id)}>
        Delete
      </Menu.Item>
    </Menu>

  );
  return (
    <div className='videocomponentmain'>
      <ReactPlayerHoc
        width={'90%'}
        style={{margin:'10px' }}
        height={'160px'}
        url={`${Api._s3_url}${file}`}
        keyboardControls={true}
        controls={true}
      />
      {user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes('digital-library-dataoperator') ?
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '8px' }}>
          <div style={{ width: '90%' }}>
            <p className='lecturenotestitle' id='contentofvideocomponent' style={{ fontSize: '16px' }}>{chapterName}</p>
            <p className='lecturnotesdescription'>{topic}</p>
            <p className='lecturenotessize'>{fileSize}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '10%' }}>
            <Dropdown disabled={false} overlay={menuVideo} placement="bottomRight" >
              <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
            </Dropdown>
            {(isVisibl && <EditInformation ismodelvisible={isVisibl} setmodevisible={setIsModalVisibl} id={id} setmodal={setmodal} types={types} chapterName={chapterName} chapterId={chapterId} topic={topic} />)}

            {(cloneModal && <CloneInformation ismodelvisible={cloneModal} setmodevisible={setCloneModal} id={id} setmodal={setmodal} types={types} chapterName={chapterName} chapterId={chapterId} topic={topic} />)}
          </div>

        </div> :
        (
          <div style={{padding:'10px'}}>
            <p className='lecturnotesdescription'>{topic}</p>
          </div>
        )}

    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    updateChapterFilter


  } = state.digitalLibrary;
  const { user } = state.session;
  return {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    updateChapterFilter, user
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  fetchAdminLibraryData: (callback) => dispatch(fetchAdminLibraryData(callback)),
  updateChapterFilter: (value) => dispatch(updateChapterFilter(value)),
  fetchAdminChapterFilter: () => dispatch(fetchAdminChapterFilter()),
  toogleEditModal: (bool) => dispatch(toogleEditModal(bool)),
  deleteNotes: (id) => dispatch(deleteNotes(id)),
  updateSetType: (type) => dispatch(updateSetType(type)),
  fetchTeacherLibraryData: () => dispatch(fetchTeacherLibraryData())

});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(Videocomponent);
