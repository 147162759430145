import {
    UPDATE_STADARDS_DROPDOWN_LIST,
    UPDATE_STADARDS_INFO,
    PDF_UPLOADED,
    SUBJECT_UPLOAD,
    UPDATE_CHAPTERS,
    UPLOAD_LECTURENOTES,
    UPLOAD_DONE,
    SET_PERFOMANCE_GRAPH_DATA,

    GET_STORAGE_BY_CATOGIRY,
    UPDATE_CLASS_LIBRARY_INFO,
    UPDATE_CLASS_SUBJECT_INFO,
    UPDATE_TEACHER_MANAGEMENT_INFO,
    UPDATE_ADMIN_LIBRARY_INFO,
    UPDATE_CURRENT_SUBJECT,
    UPDATE_CURRENT_STD,
    UPDATE_CURRENT_SORT,
    UPDATE_ADMIN_CHAPTER_INFO,
    UPDATE_SEARCHED_TOPIC,
    UPDATE_SELECTED_TEACHER,
    CURRENT_FILE_TO_UPLOAD,
    UPDATE_CHAPTER_FILTER,
    TOOGLE_EDIT_MOODLE,
    UPDATE_ADMIN_ADD_TEACHER,
    STORE_ARRAY_DATA,
    DELETE_TEACHER,
    UPDATE_TOTAL_COUNT,
    UPDATE_CURRENT_ICON,
    UPDATE_CURRENT_ID,
    UPDATE_CURRENT_TYPE,
    UPDATE_TAB_TYPE,
    UPDATE_TEACHER_LIBRARY_DATA,
    GET_TEACHERS_HOME_DATA,
    UPDATE_CHECKED_LECTURE,
    UPDATE_CHECKED_VIDEO,
    UPDATE_CHECKED_QUESTION,
    UPDATE_CHAPTER_INFO_FILTER,
    UPDATE_CLASS_ANALTYICS_INFO,
    GET_ADMIN_DASHBOARD_DATA,
    SET_IMPRESSION_ENGAUGEMENT,
    SET_MONTH_OR_WEEK,
    SET_IS_MONTH_OR_WEEK,
    SET_ENDDATE,
    SET_STARTDATE,
    UPDATE_STUDENT_HOME_INFO,
    UPDATE_STANDARDS_STUDENT,
    UPDATE_STD_LIST,
    UPDATE_DOCUMENT_INFO,
    SET_UPLOAD_CONTENT,
    UPDATE_CLASS_INFO,
    GET_ACTIVE_TIME,
    SET_SHOW_LIBRARY,
    SET_POST_ID,
    SET_EDIT_OBJECT,
    SET_KEY,
    GET_CHAPTER_WISE_DATA,
    UPDATE_SELECTED_CHAPTER,
    GET_TOPIC_WISE_LIST,
    UPDATE_SELECTED_TOPIC,
    UPDATE_FILTER_TOPIC,
    UPDATE_LIBARY_DATA,
    SET_IS_VIDEO_PREMIUM,
    UPDATE_BUY_STORAGE_DETAILS,
    UPDATE_SIZE_OF_CONTENT,
    UPDATE_TOTAL_STORAGE_SIZE,
    UPDATE_TOTAL_UNUSED_STORAGE_SIZE,
    IS_HSL,
    UPLOAD_VIDEO_MP4,
    FILE_KEY_UPDATE,
    UPDATE_PAGE_NUMBER,
    GET_CURRENT_SUBSCRIPTION,
    SET_IS_SUBJECT_ASSIGNED,
    UPDATE_TITLE,
    SET_TRAFFIC_DATA,
    SET_SUBSCRIPTION_PLAN,
    SET_RECORDINGS_DETAILS,
    SET_STORE_TEACHER,
    SET_STORE_SUBJECT_ID,
    SET_DATE_FILTER,
    CHECK_BOX_VALUE,
    SET_STORE_STREAM_ID

} from '../actions/digitalLibrary';
import moment from 'moment';

const initialState = {
    standardsInfo: [],
    stdDropdownList: [],
    Pdftoupload: null,
    subjects: [],
    chapterstofetch: [],
    uploadsuccessful: false,

    classLibraryList: [],
    classSubjectList: [],
    teacherMangementList: [],
    adminLibraryList: [],
    selectedSubject: {},
    selectedStd: {},
    setSort: "ALL",
    getChaptersList: [],
    setSearchedTopic: "",
    selectedTeacherList: {},
    file: '',
    currentfiletoshow: null,
    seletedChapter: "",
    toogleEdit: false,
    arrStoreData: [],
    deleteId: 0,
    totalCountList: {},
    icons: "",
    addedTeacher: [],
    currentId: 0,
    currentType: "",
    setTabType: "",
    getTeacherLibraryList: [],
    getTeachersHomeData: {},
    getTeachersHomeDataSummary: {},
    checkedLecture: false,
    checkedVideo: false,
    checkedQuestion: false,
    getChaptersListForFilter: [],
    getAnalyticsData: [],
    adminDashBoardData: [],
    isImpressionsOrEngagements: 'Impressions',
    startDate: moment().startOf('week').format('YYYY-MM-DD'),
    endDate: moment().startOf('week').add(6, 'days').format('YYYY-MM-DD'),
    isMonthOrWeek: "week",
    storeMonthOrWeek:'week',
    getAnalyticsData: [],
    getStudentHome: [],
    getStudentStd: {},
    getStdList: [],
    documentInfo: {},
    uploadContent: {},
    classInfo: {},
    activeTime: {},
    showLibrary: false,
    postId: null,
    editObject: {},
    key: '1',
    chapterWiseDataList: [],
    selectedChapter: {},
    topicWiseList: [],
    selectedTopic: {},
    topicList: [],
    libraryData: {},
    videoPremium: false,
    buyStorageDetails: [],
    sizeOfContents: {},
    totalStorageSize: null,
    totalUnusedStorageSize: null,
    isHSL: false,
    videoFileMP4: null,
    fileKey:'',
    pageNumber:1,
    currentSubscription:{},
    subjectAssigned:{},
    changedTitle: "",
    storePerfomanceData:[],
    storeTrafficData:[],
    storeSubscriptionPlanData:'',
    storeBroadcastRecordings:[],
    storeTeacher:[],
    storeSubjectId:[],
    storeDateFilter:'',
    storeCheckValue:false,
    storeStreamId:''


    // newSizeOfStorage:{}
};

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_STORAGE_BY_CATOGIRY:
            let newSizeOfContents = { ...state.sizeOfContents };
            newSizeOfContents[action.key]=action.val;
            return{
                ...state,
                sizeOfContents:newSizeOfContents
            }

        case UPDATE_STADARDS_INFO:
            return {
                ...state,
                standardsInfo: [...action.data]
            }
        
        case SET_IS_SUBJECT_ASSIGNED:
            return {
                ...state,
                subjectAssigned: action.data
            }    
        case CURRENT_FILE_TO_UPLOAD:
            return {
                ...state,
                currentfiletoshow: action.data

            }

        case UPLOAD_DONE:
            return {
                ...state,
                uploadsuccessful: false,
            }
        case UPLOAD_LECTURENOTES:
            return {
                ...state,
                uploadsuccessful: true,
                file: action.data.filePath ? action.data.filePath : action.data.fileLink,
                currentId: action.data.postId,
                currentType: action.data.type

            }
        case UPDATE_CHAPTERS:
            return {
                ...state,
                chapterstofetch: [...action.data]
            }
        case UPDATE_STADARDS_DROPDOWN_LIST:
            return {
                ...state,
                stdDropdownList: [action.data]
            }
        case UPDATE_CLASS_LIBRARY_INFO:
            return {
                ...state,
                classLibraryList: action.data ? [...action.data] : []
            }
        case UPDATE_CLASS_SUBJECT_INFO:
            return {
                ...state,
                classSubjectList: [...action.classSubjectData]
            }
        case UPDATE_TEACHER_MANAGEMENT_INFO:
            return {
                ...state,
                teacherMangementList: [...action.teacherData]
            }
        case UPDATE_ADMIN_LIBRARY_INFO:
            return {
                ...state,
                adminLibraryList: [...action.adminLibraryData]
            }
        case SUBJECT_UPLOAD:
            return {
                ...state,
                subjects: [...action.data]

            }
        case PDF_UPLOADED:
            return {
                ...state,
                Pdftoupload: action.data

            }

        case CHECK_BOX_VALUE:
            return{
                ...state,
                storeCheckValue:action.value
            }
        case SET_STORE_STREAM_ID:
            return{
                ...state,
                storeStreamId:action.value
            }

        case UPDATE_CURRENT_SUBJECT:
            return {
                ...state,
                selectedSubject: action.currentSub
            }
        case UPDATE_CURRENT_STD:
            return {
                ...state,
                selectedStd: action.currentStd
            }
        case UPDATE_CURRENT_SORT:
            return {
                ...state,
                setSort: action.currentSort
            }
        case UPDATE_ADMIN_CHAPTER_INFO:
            return {
                ...state,
                getChaptersList: [...action.adminChapterData]
            }
        case UPDATE_SEARCHED_TOPIC:
            return {
                ...state,
                setSearchedTopic: action.searchedTopic
            }
        case UPDATE_SELECTED_TEACHER:
            return {
                ...state,
                selectedTeacherList: action.teachers
            }
        case UPDATE_CHAPTER_FILTER:
            return {
                ...state,
                seletedChapter: action.chapFilter
            }
        case TOOGLE_EDIT_MOODLE:
            return {
                ...state,
                toogleEdit: action.bool
            }
        case UPDATE_ADMIN_ADD_TEACHER:
            return {
                ...state,
                addedTeacher: [...action.adminTeacherData]
            }
        case STORE_ARRAY_DATA:
            return {
                ...state,
                arrStoreData: [action.arrData]
            }
        case DELETE_TEACHER:
            return {
                ...state,
                deleteId: action.del_id
            }
        case UPDATE_TOTAL_COUNT:
            return {
                ...state,
                totalCountList: action.totalCount
            }
        case UPDATE_CURRENT_ICON:
            return {
                ...state,
                icons: action.icons
            }
        case UPDATE_CURRENT_ID:
            return {
                ...state,
                currentId: action.docId
            }
        case UPDATE_CURRENT_TYPE:
            return {
                ...state,
                currentType: action.data
            }
        case UPDATE_TAB_TYPE:
            return {
                ...state,
                setTabType: action.getTabType
            }
        case UPDATE_TEACHER_LIBRARY_DATA:
            return {
                ...state,
                getTeacherLibraryList: [...action.data]
            }
        case GET_TEACHERS_HOME_DATA:
            return {
                ...state,
                getTeachersHomeData: action.payload
            }
        case UPDATE_CHECKED_LECTURE:
            return {
                ...state,
                checkedLecture: action.bool
            }
        case UPDATE_CHECKED_LECTURE:
            return {
                ...state,
                checkedLecture: action.bool
            }
        case UPDATE_CHECKED_VIDEO:
            return {
                ...state,
                checkedVideo: action.bool
            }
        case UPDATE_CHECKED_QUESTION:
            return {
                ...state,
                checkedQuestion: action.bool
            }
        case UPDATE_CHAPTER_INFO_FILTER:
            return {
                ...state,
                getChaptersListForFilter: [...action.data]
            }
        case UPDATE_CLASS_ANALTYICS_INFO:
            return {
                ...state,
                getAnalyticsData: action.analyticsData
            }
        case GET_ADMIN_DASHBOARD_DATA:
            return {
                ...state,
                adminDashBoardData: action.payload
            }
        case SET_IMPRESSION_ENGAUGEMENT:
            return {
                ...state,
                isImpressionsOrEngagements: action.payload
            }
        case SET_STARTDATE:
            return {
                ...state,
                startDate: action.payload
            }
        case SET_ENDDATE:
            return {
                ...state,
                endDate: action.payload
            }
        case SET_MONTH_OR_WEEK:
            return {
                ...state,
                isMonthOrWeek: action.payload
            }
        case SET_IS_MONTH_OR_WEEK:
            return{
                ...state,
                storeMonthOrWeek :action.res
            }
        case UPDATE_STUDENT_HOME_INFO:
            return {
                ...state,
                getStudentHome: action.res
            }
        case UPDATE_STANDARDS_STUDENT:
            return {
                ...state,
                getStudentStd: action.standardDetails
            }
        case UPDATE_STD_LIST:
            return {
                ...state,
                getStdList: [...action.standard]
            }
        case UPDATE_DOCUMENT_INFO:
            return {
                ...state,
                documentInfo: action.data
            }
        case SET_UPLOAD_CONTENT:
            return {
                ...state,
                uploadContent: action.object
            }
        case UPDATE_CLASS_INFO:
            return {
                ...state,
                classInfo: action.data
            }
        case GET_ACTIVE_TIME:
            return {
                ...state,
                activeTime: action.data
            }
        case SET_SHOW_LIBRARY:
            return {
                ...state,
                showLibrary: action.bool
            }
        case SET_POST_ID:
            return {
                ...state,
                postId: action.id
            }
        case SET_EDIT_OBJECT:
            return {
                ...state,
                editObject: action.object
            }
        case SET_KEY:
            return {
                ...state,
                key: action.key
            }
        case GET_CHAPTER_WISE_DATA:
            return {
                ...state,
                chapterWiseDataList: action.data
            }
        case UPDATE_SELECTED_CHAPTER:
            return {
                ...state,
                selectedChapter: action.chapter
            }
        case GET_TOPIC_WISE_LIST:
            return {
                ...state,
                topicWiseList: action.topic
            }
        case UPDATE_SELECTED_TOPIC:
            return {
                ...state,
                selectedTopic: action.topic
            }
        case UPDATE_FILTER_TOPIC:
            return {
                ...state,
                topicList: [...action.topic]
            }
        case UPDATE_LIBARY_DATA:
            return {
                ...state,
                libraryData: action.data
            }
        case SET_IS_VIDEO_PREMIUM:
            return {
                ...state,
                videoPremium: action.bool
            }
        case UPDATE_BUY_STORAGE_DETAILS:
            return {
                ...state,
                buyStorageDetails: action.data
            }
        case UPDATE_SIZE_OF_CONTENT:
            return {
                ...state,
                sizeOfContents: action.data
            }
        case UPDATE_TOTAL_STORAGE_SIZE:
            return {
                ...state,
                totalStorageSize: action.data
            }
        case UPDATE_TOTAL_UNUSED_STORAGE_SIZE:
            return {
                ...state,
                totalUnusedStorageSize: action.data
            }
        case IS_HSL:
            return {
                ...state,
                isHSL: action.bool
            }
        case UPLOAD_VIDEO_MP4:
            return {
                ...state,
                uploadsuccessful: true,
                videoFileMP4: action.data
            }
        case FILE_KEY_UPDATE:
            return{
                ...state,
                fileKey:action.data
            }  
        case UPDATE_PAGE_NUMBER:
            return{
                ...state,
                pageNumber:action.data
            }  
        case GET_CURRENT_SUBSCRIPTION:
            return{
                ...state,
                currentSubscription:action.data
            }       
        case UPDATE_TITLE:
            return{
                ...state,
                changedTitle:action.val
            }    
        case SET_PERFOMANCE_GRAPH_DATA:
            return{
                ...state,
                storePerfomanceData:action.res
            }   
        case SET_TRAFFIC_DATA:
            return{
                ...state,
                storeTrafficData:action.res
            } 
        case SET_SUBSCRIPTION_PLAN:
            return{
                ...state,
                storeSubscriptionPlanData:action.res
            }
        case SET_RECORDINGS_DETAILS:
            return{
                ...state,
                storeBroadcastRecordings:action.res
            }
        case SET_STORE_TEACHER:
            return{
                ...state,
                storeTeacher:action.res
            }
        case SET_STORE_SUBJECT_ID:
            return{
                ...state,
                storeSubjectId:action.res
            }
        case SET_DATE_FILTER:
            return{
                ...state,
                storeDateFilter:action.res
            }
        default:
            return state;
    }
};

export default reducer;