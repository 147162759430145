import { Modal } from 'antd'
import React, { useEffect } from 'react'
import LoginCardComponent from './loginCardComponent';
import { connect } from 'react-redux';
import { getOrgDetails, setShowRegisterForm } from 'actions/loginNew';

function LoginModal({ showLoginModal, setShowLoginModal, setShowRegisterForm, getOrgDetails }) {
    const handleCancel = () => {
        setShowLoginModal(false)
        setShowRegisterForm(false)
    }
    useEffect(() => {
        setShowRegisterForm(false)
        getOrgDetails()
    }, [])
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={showLoginModal}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <LoginCardComponent isLoginModal={true} handleCancel={handleCancel} />
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { } = state.loginNew;
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
    getOrgDetails: () => dispatch(getOrgDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);