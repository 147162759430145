import { Button, Modal } from 'antd';
import React, { useState } from 'react'
import courseImage from '../../../Assets/dashBoard/ill-courses.png'
import moment from 'moment';
import ic_file_add from "../../../Assets/images/ic_file_add.svg"
import { DownloadOutlined } from '@ant-design/icons';
import fileDownload from "js-file-download";
import { Api } from 'services';
import Axios from 'axios';


const ViewAnnouncementModal = ({ isViewModal, setIsViewModal, allData }) => {
  function removeHTMLTags(sentence) {
    const regex = /<[^>]*>/g;
    return sentence?.replace(regex, '');
  }
  console.log("AA123-allData", allData);
  const showModal = () => {
    setIsViewModal(true);
  };
  const handleOk = () => {
    setIsViewModal(false);
  };
  const handleCancel = () => {
    setIsViewModal(false);
  };

  const downloadFile = (file) => {
    let fileName = file.key
    Axios.get(Api._s3_url + file.data, {
      responseType: "blob",
    }).then((res) => {

      fileDownload(res.data, fileName);
    });
  }
  return (
    <>
      <Modal
        className="modal-round-corner"
        open={isViewModal}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "21px", fontWeight: "bold", marginTop: "15px" }}>Details</div>
        <div style={{ width: "100%", height: "200px", backgroundColor: "#FAFAFA", marginTop: "15px" }}>
          <div style={{ textAlign: "center", fontSize: "15px", fontWeight: "bold" }}>{allData?.meta?.title}</div>
          {allData?.meta?.typeAnnouncement != 'file' ?
            <div style={{ marginLeft: "20px", textAlign: "left", fontSize: "13px", fontWeight: "bold", color: "#636363" }}>{removeHTMLTags(allData?.meta?.text)}</div>
            :
            (<>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                <div><img src={ic_file_add} style={{ height: "35px", width: "35px", color: "#1089FF" }} /></div>
                <div style={{ marginLeft: "20px" }}>
                  <div style={{ fontSize: "15px" }}>{allData?.meta?.filenames[0]?.key}</div>
                  <div style={{ fontSize: "12px", color: "#AEAEAE" }}>{allData?.meta?.filenames[0]?.sizeInKB}KB</div>
                </div>
                <div style={{ color: "#1089FF", fontSize: "25px", marginLeft: "20px" }}><DownloadOutlined onClick={() => { downloadFile(allData?.meta?.filenames[0]) }} /></div>
              </div>
            </>)}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {allData?.meta?.standardNames?.length == 0 ? null :
            (
              <>
              <div>
              <div style={{fontSize: "11px", color: "#636363", fontWeight: "bold", marginTop: "20px"}}>Class</div>
                <div style={{ fontSize: "11px", color: "#636363", fontWeight: "bold",}}>{allData?.meta?.standardNames}</div>

              </div>
               
              </>
            )
          }
          <div>

            <div style={{ textAlign: "right", fontSize: "11px", color: "#636363", marginRight: "50px", fontWeight: "bold", marginTop: "20px" }}>Announced on</div>
            <div style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }}>{moment(allData?.meta?.announcedOn).format('DD-MM-YYYY h:mm a')}</div>
          </div>

        </div>


        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

          <Button
            style={{
              borderRadius: "19px",
              width: "90px",
              height: "40px",
              fontWeight: "500",
              backgroundColor: "#FFFFFF",
              color: "#636363",
              fontSize: "13px",

            }}
            onClick={handleCancel}
          >CLOSE</Button>
        </div>

      </Modal>
    </>
  );
};
export default ViewAnnouncementModal;