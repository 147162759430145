// import message from "antd/lib/message";
import { Api } from "../services";
import Notification from "../services/Notification";
// import moment from "moment";

export const UPDATE_TEST_LIST = "UPDATE_TEST_LIST";
export const TOGGLE_TEST_LOADER = "TOGGLE_TEST_LOADER";
export const TOGGLE_TEST_MODAL = "TOGGLE_TEST_MODAL";
export const UPDATE_TEST_FILTER_PARAMS = "UPDATE_TEST_FILTER_PARAMS";
export const UPDATE_TEST_POST_PARAMS = "UPDATE_TEST_POST_PARAMS";
export const UPDATE_STANDARDS_LIST = "UPDATE_STANDARDS_LIST";
export const RESET_TEST_PARAMS = "RESET_TEST_PARAMS"
export const SET_CURRENT_TEST = "SET_CURRENT_TEST";
export const TOGGLE_TEST_QUESTIONS_LOADER = "TOGGLE_TEST_QUESTIONS_LOADER";
export const UPDATE_QUESTIONS_LIST = "UPDATE_QUESTIONS_LIST";
export const SET_TEST_PARAMS = "SET_TEST_PARAMS";
export const RESET_TEST_Q_PARAMS = "RESET_TEST_Q_PARAMS";
export const UPDATE_TEST_Q_PARAMS = "UPDATE_TEST_Q_PARAMS";
export const TOGGLE_Q_POST_LOADER = "TOGGLE_Q_POST_LOADER";
export const TOGGLE_QUESTION_MODAL = "TOGGLE_QUESTION_MODAL";
export const SET_CURRENT_IMAGE = "SET_CURRENT_IMAGE";
export const ADD_OPTION = "ADD_OPTION";
export const UPDATE_Q_OPTION = "UPDATE_Q_OPTION";
export const SET_TEST_OPTION_IMAGE = "SET_TEST_OPTION_IMAGE";
export const SET_CURRENT_TEST_QUESTION = "SET_CURRENT_TEST_QUESTION";
export const GET_REMOVE_RESPONSE = "GET_REMOVE_RESPONSE";
export const REMOVE_OPTION = "REMOVE_OPTION";
export const UPDATE_QUESTION_FEEDBACK = "UPDATE_QUESTION_FEEDBACK";
export const SET_QUESTIONS_FEEDBACK = "SET_QUESTIONS_FEEDBACK";
export const SET_CURRENT_TEST_PARAMS = "SET_CURRENT_TEST_PARAMS";
export const UPDATE_SUBMISSION_DATA = "UPDATE_SUBMISSION_DATA";
export const TOGGLE_ANS_LOADER = "TOGGLE_ANS_LOADER";
export const SET_STUDENT_ANSWERS = "SET_STUDENT_ANSWERS";
export const TOGGLE_REVIEW_LOADER = "TOGGLE_REVIEW_LOADER";
export const UPDATE_TEST_PARAMS = "UPDATE_TEST_PARAMS";
export const TOGGLE_INSTRUCTION_MODAL = "TOGGLE_INSTRUCTION_MODAL";
export const RESET_CURRENT_TEST = "RESET_CURRENT_TEST"
export const UPDATE_REVIEW_PARAMS = "UPDATE_REVIEW_PARAMS";
export const UPDATE_STUDENT_TEST_STATUS = "UPDATE_STUDENT_TEST_STATUS";
export const UPDATE_TOTAL_MARKS = "UPDATE_TOTAL_MARKS";
export const TOGGLE_RESULT_LOADER = "TOGGLE_RESULT_LOADER";
export const TOGGLE_REMINDER_LOADER = "TOGGLE_REMINDER_LOADER";
export const TOGGLE_STATUS_LOADER = "TOGGLE_STATUS_LOADER";
export const UPDATE_SELECTED_TAB = "UPDATE_SELECTED_TAB";
export const UPDATE_SEARCHED_CHAPTERS_IN_TEST = "UPDATE_SEARCHED_CHAPTERS_IN_TEST";
export const TOGGLE_VIEW_Q_MODAL = "TOGGLE_VIEW_Q_MODAL";
export const TOGGLE_TEACHER_TEST_REMINDER_LOADER = "TOGGLE_TEACHER_TEST_REMINDER_LOADER";
export const CLEAR_TEST_FILTER_PARAMS = "CLEAR_TEST_FILTER_PARAMS";


export const toggleViewQuestionModal = bool => ({
  type: TOGGLE_VIEW_Q_MODAL,
  bool
})

export const clearTestFilterParams = () => ({
  type: CLEAR_TEST_FILTER_PARAMS,
})

export const notifyTeacher = () =>{
  return (dispatch, getState) => {
    const { currentTestDetails } = getState().test;
    const params = {
      teacherId: currentTestDetails.teacherId,
      testId: currentTestDetails.id,
    };
    dispatch(toggleTeacherTestRemincerLoader(true))
    const url = `/test/send-reminder`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          dispatch(toggleTeacherTestRemincerLoader(false))
        });
    });
  };
}

const toggleTeacherTestRemincerLoader = (bool) => ({
  type: TOGGLE_TEACHER_TEST_REMINDER_LOADER,
  bool
})

export const searchChapter = query => {
  return (dispatch, getState) => {
    const { testParams } = getState().test;
    const params = {
      standardIds: testParams.standardIds,
      subjectIds: testParams.subjectIds,
      chapterName: query
    };
    const url = `/assignment/filter/autocomplete-chapters`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (!response.length) {
            dispatch(
              updateSearchedChapters([
                {
                  id: -1,
                  value: "No chapters found",
                  disabled: true
                }
              ])
            );
          } else {
            dispatch(updateSearchedChapters(response));
          }
        });
    });
  };
};

export const updateSearchedChapters = chapters => ({
  type: UPDATE_SEARCHED_CHAPTERS_IN_TEST,
  chapters
});

export const updateSelectedTab = key => ({
  type: UPDATE_SELECTED_TAB,
  key
})

export const toggleStatusLoader = value => ({
  type: TOGGLE_STATUS_LOADER,
  value
});

export const getRemoveResponse = bool => ({
  type: GET_REMOVE_RESPONSE,
  bool
});

export const toggleTestModal = state => ({
  type: TOGGLE_TEST_MODAL,
  state
});

export const toggleTestLoader = state => ({
  type: TOGGLE_TEST_LOADER,
  state
});

export const toggleInstructionModal = state => ({
  type: TOGGLE_INSTRUCTION_MODAL,
  state
});

export const updateTestList = response => ({
  type: UPDATE_TEST_LIST,
  payload: response
});

export const resetTestParams = () => ({
  type: RESET_TEST_PARAMS,
});

export const toggleTestQuestionsLoader = bool => ({
  type: TOGGLE_TEST_QUESTIONS_LOADER,
  bool
});

export const updateQOptionParam = (val, index) => ({
  type: UPDATE_Q_OPTION,
  val, 
  index
})

export const setOptionImage = (index, value, image) => ({
  type: SET_TEST_OPTION_IMAGE,
  index,
  value,
  image
});

export const setCurrentTest = (data = false) => ({
  type: SET_CURRENT_TEST,
  data
});

export const setTestParams = () => ({
  type: SET_TEST_PARAMS,
});

export const updateQParams = (value, key, index = -1) => ({
  type: UPDATE_TEST_Q_PARAMS,
  qParams: { [key]: value },
  index
});

const updateTestParam = (value, key) => ({
  type: UPDATE_TEST_PARAMS,
  testParams: {[key]: value}
})

export const resetQParams = () => ({
  type: RESET_TEST_Q_PARAMS,
});

export const setCurrentImage = (value, image) => ({
  type: SET_CURRENT_IMAGE,
  value,
  image
});

export const addOption = obj => ({
  type: ADD_OPTION,
  obj
});

export const addTotalMarks = totalMarksObtained => ({
  type: UPDATE_TOTAL_MARKS,
  totalMarksObtained
});

export const saveReview = params => {
  const url = `/test/review-test`;
  return dispatch => {
    dispatch(toggleAnsLoaderLoader(true));
    return Api.post(url)
      .params(params)
      .send((response, error) => {
        dispatch(toggleAnsLoaderLoader(false));
        if (response && response.show.type === "success") {
          dispatch(
            updateStudentTestStatus(
              "Graded",
              params.totalMarks,
              params.studentId
            )
          );
        }
      });
  };
};

export const updateStudentTestStatus = (status, marks, index) => ({
  type: UPDATE_STUDENT_TEST_STATUS,
  status,
  marks,
  index
});

export const sendReminder = () => {
  return (dispatch, getState) => {
    const { currentAssignment, studentList } = getState().assignment;
    dispatch(toggleReminderLoader(true));
    const studentIds = studentList
      .filter(student => student.status === "Pending")
      .map(student => student.id);
    const url = `/assignment/send-reminder`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params({ studentIds, assignmentId: currentAssignment.id })
        .send(async (response, error) => {
          resolve();
          dispatch(toggleReminderLoader(false));
        });
    });
  };
};

export const toggleReminderLoader = value => ({
  type: TOGGLE_REMINDER_LOADER,
  value
});


export const postQuestion = params => {
  const url = `/test/test-question`;
  return (dispatch) => {
    dispatch(toggleQuestionPostLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response && response.show && response.show.type === "success") {
            dispatch(getTestById(params.testId, 1));
            dispatch(resetQParams());
            if(params.id){
              dispatch(toggleQuestionModal(false))
            }
          }
         dispatch(toggleQuestionPostLoader(false));
        });
    });
  };
}

export const deleteImages = (images, index = null) => {
  return dispatch => {
    dispatch(getRemoveResponse(true));
    return new Promise((resolve, reject) => {
      resolve();
      const params = { deletedFiles: images };
      Api.delete("/assignment/delete-image")
        .params(params)
        .send((response, error) => {
          if (response) {
            dispatch(getRemoveResponse(false));
          }
        });
    });
  };
};

export const removeOptionFromParams = id => ({
  type: REMOVE_OPTION,
  id
})

export const toggleQuestionModal = bool => ({
  type: TOGGLE_QUESTION_MODAL,
  bool
});


const toggleQuestionPostLoader = bool => ({
  type: TOGGLE_Q_POST_LOADER,
  bool
})

export const getTest = () => {
  const url = `/test`;
  return (dispatch, getState) => {
    const params = getState().test.testFilterParams;
    dispatch(toggleTestLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response) {
            dispatch(updateTestList(response));
          }
          dispatch(toggleTestLoader(false));
        });
    });
  };
};

export const fetchStandards = (subjectIds) => {
  const url = `/test/filter/standard-by-subjects`;
  const params = {subjectIds}
  return dispatch => {
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response) {
            dispatch(updateStandardsList(response));
          }
          dispatch(toggleTestLoader(false));
        });
    });
  };
}

export const updateStandardsList = (standards) => ({
  type: UPDATE_STANDARDS_LIST,
  standards
})

export const fetchStudentAnswers = (testId, studentId, page) => {
  const url = `/test/student/submit-test?testId=${testId}&studentId=${studentId}&page=${page}`;

  return dispatch => {
    dispatch(toggleAnsLoaderLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        resolve();
        if (response) {
          dispatch(setStudentAnswers(response));
        }
        dispatch(toggleAnsLoaderLoader(false));
      });
    });
  };
};


export const toggleAnsLoaderLoader = value => ({
  type: TOGGLE_ANS_LOADER,
  value
});

export const setStudentAnswers = response => ({
  type: SET_STUDENT_ANSWERS,
  response
});

export const updateTestStatus = (params, status, history = null) => {
  return dispatch => {
    dispatch(toggleTestLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(`/test/status/${params.id}`)
        .params({ status })
        .send(async (response, error) => {
          resolve();
          if (response && response.show && response.show.type === "success") {
            if (status === "Published") {
              // dispatch(getTest());
              dispatch(handleTestFilterParams(status, 'status'));
            }
            // else if (status === "Deleted") {
              if (history) {
                history.goBack();
              }else{
                dispatch(getTest());
              // }
            }
          }else{
            dispatch(toggleTestLoader(false));
          }
        });
    });
  };
};

export const createNewTest = (params, history, nextPage) => {
  return dispatch => {
    dispatch(toggleTestLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(`/test`)
        .params(params)
        .send(async (response, error) => {
          resolve();
          dispatch(toggleTestLoader(false));
          if (response && response.id) {
            params.status = "Draft";
            params.id = response.id;
            dispatch(toggleTestModal(false));
            dispatch(setCurrentTest(response));
            if (nextPage === "assignment-logger") {
              history.push(`/test/${response.id}`);
              dispatch(
                setCurrentTestTasks([], { currentPage: 1, totalItems: 0 })
              );
            } else if (nextPage === "assignment-teacher") {
              history.push(`/test/submissions/${response.id}`);
              dispatch(
                setCurrentTestTasks([], { currentPage: 1, totalItems: 0 })
              );
              dispatch(updateSubmissionsList([], 0, 0, 0));
            }else {
              dispatch(handleTestFilterParams('Draft', 'status'));
              dispatch(getTest());
            }
            if(params.cloneTestId){
              dispatch(getTestById(response.id, 1, null));
            }
            dispatch(resetTestParams());         

          }
        });
    });
  };
};

const updateSubmissionsList = (
  studentList,
  graded,
  submitted,
  totalStudents,
  pageMeta
) => ({
  type: UPDATE_SUBMISSION_DATA,
  studentList,
  graded,
  submitted,
  totalStudents
});

export const setCurrentTestQuestion = (data) => ({
  type: SET_CURRENT_TEST_QUESTION,
  data
});

export const getTestById = (id, page, view = null) => {
  return (dispatch, getState) => {
    if (!page) page = 1;
    const url = `/test/${id}?page=${page}`;
    dispatch(toggleTestQuestionsLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (response && response.questions) {
          dispatch(
            setCurrentTestTasks(
              response.questions,
              response.pageMeta,
              response.totalPoints
            )
          );
          if (view === "assignment-teacher" && response.submission) {
            dispatch(
              updateSubmissionsList(
                response.submission,
                response.graded,
                response.submitted,
                response.totalStudents
              )
            );
          }
        }
        dispatch(toggleTestQuestionsLoader(false));
        resolve();
      });
    });
  };
};

const setCurrentTestTasks = (questions, pageMeta, totalPoints) => ({
  type: UPDATE_QUESTIONS_LIST,
  questions, 
  pageMeta,
  totalPoints
})

export const deleteTestQuestion = (id, positiveMarks) => {
  if (!id) return  Notification.error("Error","Question already deleted, reload!");
  const url = `/test/test-question/${id}`;
  return (dispatch, getState) => {
    const {currentTestDetails, testFilterParams} = getState().test;
    dispatch(toggleTestQuestionsLoader(true));
    return Api.delete(url).send((response, error) => {
      if (response && response.show.type === "success") {
        dispatch(
          getTestById(
            currentTestDetails.id,
            testFilterParams.page
          )
        );
      }else{
        dispatch(toggleTestQuestionsLoader(false));
      }
      // 
    });
  };
};


export const saveTestFeedback = params => {
  return (dispatch, getState) => {
    const {
      currentTestDetails,
      testQPageMeta
    } = getState().test;
    const url = `/test/review-test-question`;
    dispatch(toggleTestQuestionsLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          resolve();
          if (response && response.show.type === "success") {
            dispatch(
              updateCurrentTestParam(
                response.testMarkedForReview,
                "markedForReview"
              )
            );
            dispatch(
              getTestById(
                currentTestDetails.id,
                testQPageMeta.currentPage
              )
            );
            dispatch(setQuestionFeedBack({}));
          }
          dispatch(toggleTestQuestionsLoader(false));
        });
    });
  };
};

export const unmarkForReview = (id, view = null) => {
  return (dispatch) => {
    const url = `/test/update-test-review-status/${id}`;
    dispatch(toggleMarkForReviewLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(url).send(async (response, error) => {
        resolve();
        if (response && response.show.type === "success") {
          dispatch(updateTestParam(false, "markedForReview"));
        }
        dispatch(toggleMarkForReviewLoader(false));
      });
    });
  };
};

export const publishResults = () => {
  return (dispatch, getState) => {
    const {
      currentTestDetails,
    } = getState().test;
    const url = `/test/publish-result/${currentTestDetails.id}`;
    dispatch(toggleResultLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(url).send(async (response, error) => {
        resolve();
        if (response && response.show.type === "success") {
          dispatch(updateTestParam(true, "declared"));
        }
        dispatch(toggleResultLoader(false));
      });
    });
  };
};

export const toggleResultLoader = value => ({
  type: TOGGLE_RESULT_LOADER,
  value
});

export const toggleMarkForReviewLoader = value => ({
  type: TOGGLE_REVIEW_LOADER,
  value
});

export const setQuestionFeedBack = value => ({
  type: SET_QUESTIONS_FEEDBACK,
  value
});

export const handleTestFilterParams = (value, key) => ({
  type: UPDATE_TEST_FILTER_PARAMS,
  filterParams: { [key]: value }
});

export const handleTestInput = (value, key) => ({
  type: UPDATE_TEST_POST_PARAMS,
  postParams: { [key]: value }
});

export const updateReviewItem = (value, key, index) => ({
  type: UPDATE_REVIEW_PARAMS,
  param: { [key]: value },
  index
});

export const updateCurrentTestParam = (value, key) => ({
  type: SET_CURRENT_TEST_PARAMS,
  currentTestDetails: { [key]: value }
});

export const updateQuestionFeedback = (value, key, index) => ({
  type: UPDATE_QUESTION_FEEDBACK,
  param: { [key]: value },
  index
});


