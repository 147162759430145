import React, { useEffect, useState } from "react";
import image from "Assets/user-management/Icon awesome-user-alt.svg";
import { IMG_URL } from "../../env.json";
import { Tooltip, Checkbox, Typography, Tag } from "antd";
import { connect } from "react-redux";
import {
  setOverviewFilter,
  setAllActivitiesFilter,
} from "actions/feeManagement";
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";

const singlePrivilegeTag = (role) => {
  let color, border, bgColor, text;
  switch (role){
    case "Admin":
      color="#28DF99";
      border="#28DF99 solid 1px";
      bgColor="#EAFCF5";
      text="ADMIN";
      break;
    case "AttendanceMarker":
      color="#998248";
      border="#998248 solid 1px";
      bgColor="#F7E6B8";
      text="ATTENDANCE MARKER";
      break;
    case "FeeManager":
      color="#1089FF";
      border="#1089FF solid 1px";
      bgColor="#E7F3FF";
      text="FEE MANAGER";
      break;
    case "InstituteSupport":
      color="#B441CC";
      border="#B441CC solid 1px";
      bgColor="#F7E2FC";
      text="INSTITUTE SUPPORT";
      break;
    case "Planner":
      color="#FFA931";
      border="#FFA931 solid 1px";
      bgColor="#FFF4D5";
      text="PLANNER";
      break;
    case "LeaveManager":
      color="#FFA931";
      border="#FFA931 solid 1px";
      bgColor="#FFF4D5";
      text="LEAVE MANAGER";
      break;
  }
   
  return (
    <div
      style={{
        position: "inherit",
        marginTop: "1px",
        color: color,
        border: border,
        backgroundColor: bgColor,
        textAlign: "center",
        fontWeight: "600",
        fontSize: "10px",
        width: "fit-content",
        padding: "2px",
        paddingLeft: "3px",
        paddingRight: "5px",
        borderRadius: "5px",
      }}
    >
      {text}
    </div>
  )
}

function UserCard({
  phoneNo,
  newOrOld,
  rollNo,
  rollNumVal,
  cell,
  name,
  role,
  avatar,
  privilage,
  specificUserId,
  setAllActivitiesFilter,
  setOverviewFilter,
  unassignedStudsList,
  unAssignedStudentsList,
  userId,
  temp,
  checked,
  onCheckChange,
  reload,
  location,
  user,
  loc
  // privilage
}) {

  // const [check, setCheck] = useState(false)
  // useEffect(()=>{
  //   console.log("USERID",userId)
  //   if(temp.includes(userId)){
  //     setCheck(true);
  //   }
  // },[])
  const text = (
    <div>
      {privilage &&
        privilage.map((item) => (
          <p style={{ marginBottom: "0" }}>
            {item === "InstituteSupport" ? "Institute Support" : null}
            {item === "SuperAdmin" ? "Super Admin" : null}
            {item === "Admin" || item === "Planner" ? item : null}
            {item === "FeeManager" ? "Fee Manager" : null}
            {item === "AttendanceMarker" ? "Attendance Marker" : null}
            {item === "LeaveManager" ? "Leave Manager" : null}
          </p>
        ))}
    </div>
  );


  return (
    <div
      style={{width:"100%"}}
      onClick={() => {
        // console.log("clicked user");
        setAllActivitiesFilter("search", null);
        setAllActivitiesFilter("standardId", null);
      }}
    >
      <div
        className="border-16 recentlyAddedCards"
        style={{
          width:"100%",
          border: "#302A5F14 solid 1px",
          padding: "0 10px 10px 20px",
        }}
      >
        {location == 'unAssigned' && (user?.privilage.includes("SuperAdmin")||user?.privilage.includes("Admin")) ? 
        <div style={{ marginRight: 20, display: 'flex', alignItems: 'center', float: 'left'}} onClick={(e)=>{
          e?.stopPropagation();
          onCheckChange();
        }}>
          {temp?.includes(userId) ? <CheckSquareOutlined style={{fontSize: 15}} />:<BorderOutlined style={{fontSize: 15}}/>}
          </div> 
      : null}
          <div style={{ display: "flex", flexDirection: "row", marginTop: 12, marginLeft: loc == 'userDashboard' ? 10 : 0, marginLeft: loc == 'dashByRole' ? 10 : 0, marginLeft: loc == 'studentRole' ? 10 : -5, }}>
          {avatar.substring(avatar.length - 11, avatar.length) !==
          "default.png" ? (
            <img
              width="55px"
              height="55px"
              style={{ borderRadius: "50%" }}
              src={avatar}
            ></img>
          ) : (
            <img
              width="55px"
              height="55px"
              style={{
                borderRadius: "50%",
                backgroundColor: "#F6F4FE",
                padding: "15px",
              }}
              src={image}
            ></img>
          )}
        </div>

        <div style={{ marginLeft: "10px", marginTop: "12px" }}>
          <Typography.Text
          ellipsis={{tooltip: true}}
            style={{
              marginBottom: "0",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100px",
              textOverflow: "ellipsis",
              fontWeight: 600,
              fontFamily: 'roboto'
              // border: '1px solid red'
            }}
          >
            {name}
          </Typography.Text>
          {rollNo ? (
            <Typography.Text ellipsis={{tooltip: true}} style={{ color: "#AEAEAE", width: 150 }}>roll No. {rollNumVal}</Typography.Text>
          ) : (
            <div>
              {phoneNo ? (
                <Typography.Text ellipsis={{tooltip: true}} style={{
                  color: "#AEAEAE",
                  marginBottom: "0",
                  overflow: "hidden",
                  width: "90%",
                  textOverflow: "ellipsis",
                }}>
               
                  {cell}
                </Typography.Text>
              ) : (
                <p style={{ color: "#AEAEAE", marginBottom: "0" }}>{role}</p>
              )}
            </div>
          )}
          {privilage && privilage.length === 1 ? (
            // <Tooltip placement="rightBottom" title={text}>
            <div style={{ width: "fit-content" }}>
              {singlePrivilegeTag(privilage[0])}
              {/* {privilage[0] === "Admin" ? (
                <p
                  style={{
                    position: "inherit",
                    width: "fit-content",
                    marginTop: "1px",
                    color: "#28DF99",
                    border: "#28DF99 solid 1px",
                    backgroundColor: "#EAFCF5",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "12px",
                    padding: "2px",
                    paddingLeft: "3px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  ADMIN
                </p>
              ) : null}
              {privilage[0] === "AttendanceMarker" ? (
                <p
                  style={{
                    position: "inherit",
                    marginTop: "1px",
                    color: "#998248",
                    border: "#998248 solid 1px",
                    backgroundColor: "#F7E6B8",
                    textAlign: "center",
                    width: "fit-content",
                    fontWeight: "600",
                    fontSize: "12px",
                    padding: "2px",
                    paddingLeft: "3px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  ATTENDANCE MARKER
                </p>
              ) : null}
              {privilage[0] === "FeeManager" ? (
                <p
                  style={{
                    position: "inherit",
                    marginTop: "1px",
                    color: "#1089FF",
                    border: "#1089FF solid 1px",
                    backgroundColor: "#E7F3FF",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "12px",
                    width: "fit-content",
                    padding: "2px",
                    paddingLeft: "3px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  FEE MANAGER
                </p>
              ) : null}
              {privilage[0] === "InstituteSupport" ? (
                <p
                  style={{
                    position: "inherit",
                    marginTop: "1px",
                    color: "#B441CC",
                    border: "#B441CC solid 1px",
                    backgroundColor: "#F7E2FC",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "12px",
                    width: "fit-content",
                    padding: "2px",
                    paddingLeft: "3px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  INSTITUTE SUPPORT
                </p>
              ) : null}
              {privilage[0] === "Planner" ? (
                <div
                  style={{
                    position: "inherit",
                    marginTop: "1px",
                    color: "#FFA931",
                    border: "#FFA931 solid 1px",
                    backgroundColor: "#FFF4D5",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "10px",
                    width: "fit-content",
                    padding: "2px",
                    paddingLeft: "3px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  PLANNER
                </div>
              ) : null} */}
                {/* {privilage[0] === "LeaveManager" ? (
                <div
                  style={{
                    position: "inherit",
                    marginTop: "1px",
                    color: "#FFA931",
                    border: "#FFA931 solid 1px",
                    backgroundColor: "#FFF4D5",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "10px",
                    width: "fit-content",
                    padding: "2px",
                    paddingLeft: "3px",
                    paddingRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  Leave Manager
                </div>
              ) : null} */}
            </div>
          ) : (
            // </Tooltip>
            <>
              {privilage && privilage.length > 1 ? (
                <Tooltip placement="rightBottom" title={text}>
                  <div
                    style={{
                      position: "inherit",
                      marginTop: "1px",
                      color: "#594099",
                      border: "#594099 solid 1px",
                      backgroundColor: "#F6F4FE",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "10px",
                      width: "fit-content",
                      padding: "2px",
                      paddingLeft: "3px",
                      paddingRight: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    PRIVILEGES
                  </div>
                </Tooltip>
              ) : null}
            </>
          )}
        </div>
        {newOrOld ? (
          <p
            style={{
              background: "#FFA931",
              borderRadius: "10px",
              width: "30px",
              textAlign: "center",
              fontSize: "12px",
              position: "relative",
              color: "white",
              height: "20px",
              marginLeft: -10
            }}
          >
            new
          </p>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { specificUserId } = state.userManagement;
  const { user } = state.session;
  return {
    specificUserId,
    user
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
