import { Modal } from 'antd'
import AnimatedCustomSelect from 'components/AnimatedSelectComp'
import { Button } from 'components/Button'
import React, { useState } from 'react'

function NewTransferStudentsModal({ isTransferStudentsModalVisible, setIsTransferStudentsModalVisible, standardsFromStudentIdsData, classes, setSelectedStudents, selectedStudents, togglePageLoader, getCourseStudentsData, postNewTransferStudents }) {
    const handleCancel = () => {
        setIsTransferStudentsModalVisible(false)
    }
    const [paramsObj, setParamsObj] = useState({
        selectedStd: null,
        selectedSec: null,
        targetStd: null,
        targetSec: null
    })
    const [transferLoader, serTransferLoader] = useState(false)
    const classKeysArray = Object.keys(classes)?.map((key) => ({ name: key }));
    const sections = paramsObj?.targetStd && classes[paramsObj?.targetStd]?.map(cls => ({ id: cls.id, value: cls.section }));
    const fromclassKeysArray = Object.keys(standardsFromStudentIdsData?.items ?? {})?.map((key) => ({ name: key }));
    const fromSections = paramsObj?.selectedStd && standardsFromStudentIdsData?.items[paramsObj?.selectedStd]?.map(cls => ({ id: cls.id, value: cls.section }));

    const handleTransferClick = () => {
        const params = {
            targetStandard: paramsObj?.targetSec,
            students: selectedStudents,
            standardId: paramsObj?.selectedSec
        }
        serTransferLoader(true);
        postNewTransferStudents(
            params,
            () => { serTransferLoader(false); setSelectedStudents([]) },
            () => {
                setIsTransferStudentsModalVisible(false);
                setSelectedStudents([])
                togglePageLoader(true);
                getCourseStudentsData(() => togglePageLoader(false))
            }
        )
    }

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isTransferStudentsModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Transfer student(s)</center>
            <center className='m-t-20' style={{ color: "#191919", fontWeight: 600 }}>FROM</center>
            <div>
                <AnimatedCustomSelect
                    label={"Batch"}
                    required={true}
                    options={fromclassKeysArray}
                    handleSelectChange={(value) => { setParamsObj({ ...paramsObj, selectedStd: value, selectedSec: null }) }}
                    value={paramsObj?.selectedStd}
                    isSelected={paramsObj?.selectedStd ? true : false} />
            </div>
            <div className='m-t-20'>
                <AnimatedCustomSelect
                    label={"Section"}
                    required={true}
                    options={fromSections}
                    handleSelectChange={(value) => setParamsObj({ ...paramsObj, selectedSec: value })}
                    value={paramsObj?.selectedSec}
                    isSelected={paramsObj?.selectedSec ? true : false} />
            </div>
            <center className='m-t-20' style={{ color: "#191919", fontWeight: 600 }}>TO</center>
            <div>
                <AnimatedCustomSelect
                    label={"Batch"}
                    required={true}
                    options={classKeysArray}
                    handleSelectChange={(value) => { setParamsObj({ ...paramsObj, targetStd: value, targetSec: null }) }}
                    value={paramsObj?.targetStd}
                    isSelected={paramsObj?.targetStd ? true : false} />
            </div>
            <div className='m-t-20'>
                <AnimatedCustomSelect
                    label={"Section"}
                    required={true}
                    options={sections}
                    handleSelectChange={(value) => setParamsObj({ ...paramsObj, targetSec: value })}
                    value={paramsObj?.targetSec}
                    isSelected={paramsObj?.targetSec ? true : false} />
            </div>
            <center>
                <div className='r-jsb m-t-30' style={{ width: "60%" }}>
                    <div style={{ color: "#636363", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setIsTransferStudentsModalVisible(false) }}>CANCEL</div>
                    <Button type="primary"
                        loading={transferLoader}
                        disabled={!paramsObj?.selectedStd || !paramsObj?.selectedSec || !paramsObj?.targetStd || !paramsObj?.targetSec}
                        onClick={handleTransferClick}
                    >TRANSFER</Button>
                </div>
            </center>
        </Modal>
    )
}

export default NewTransferStudentsModal