import React, { useEffect, useRef, useState } from 'react';
import "./loginPage.css"
import { Font13, Font15, Font17, Font21, Font23, Heading } from 'components/responsiveTypography';
import AnimatedPhoneInput from 'components/AnimatedPhoneInput';
import { connect } from 'react-redux';
import { postGenerateOtp, storePhoneNumber } from 'actions/loginNew';
import { Button } from 'components/Button';
import ReCaptchaV3 from 'pages/loginNew/reCaptchaV3';
import VerifyOtpForm from './verifyOtpForm';
import LoginEmailForm from './loginEmailForm';

export const Separator = () => {
    return (
        <div className="separator">
            <hr className="line" />
            <span className="or-text">Or</span>
            <hr className="line" />
        </div>
    );
};

function LoginSignInForm({ postGenerateOtp, storePhoneNumber, showVerifyOtpForm, setShowVerifyOtpForm, userRegister, setShowRegisterForm, setPhoneNumber, phoneNumber, setUserRegister, isLoginModal}) {
    const [emailIdSignIn, setEmailIdSignIn] = useState(false);
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [storeTypedOtp, setStoreTypedOtp] = useState('');

    const sitekey = '6LcAkU4pAAAAANllAlCwLHYgaGPsqk4-KF9PKnT5';
    const { executeReCaptcha } = ReCaptchaV3({ sitekey });

    const handlePhoneInputChange = (e) => {
        if (e != undefined) {
            setPhoneNumber(e);
            storePhoneNumber(e)
        } else {
            setPhoneNumber("");
        }
    }

    const handleNumberSubmit = async () => {
        if (!phoneNumber) {
        } else {
            setVerifyLoader(true);
            try {
                const recaptchaToken = await executeReCaptcha('mobileLogin');
                postGenerateOtp(
                    recaptchaToken,
                    () => { setVerifyLoader(false); setStoreTypedOtp('') },
                    (res) => {
                        setShowVerifyOtpForm(res);
                    })
            } catch (error) {
                console.error('Error executing ReCaptcha:', error);
                setVerifyLoader(false);
            }
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePhoneInputKeyPress = (e) => {

        if (e.key === 'Enter' && phoneNumber) { // Check if the Enter key is pressed and phoneNumber is not empty
            handleNumberSubmit();
        }
    };
   

    return (
        <>
            {emailIdSignIn ?
                <LoginEmailForm
                    setEmailIdSignIn={setEmailIdSignIn}
                    setShowVerifyOtpForm={setShowVerifyOtpForm}
                    isLoginModal={isLoginModal} />
                :
                showVerifyOtpForm ?
                    <VerifyOtpForm
                        phoneNumber={phoneNumber}
                        storeTypedOtp={storeTypedOtp}
                        setStoreTypedOtp={setStoreTypedOtp}
                        verifyLoader={verifyLoader}
                        setVerifyLoader={setVerifyLoader}
                        setShowVerifyOtpForm={setShowVerifyOtpForm}
                        userRegister={userRegister}
                        setUserRegister={setUserRegister}
                        setPhoneNumber={setPhoneNumber}
                        setEmailIdSignIn={setEmailIdSignIn}
                        setShowRegisterForm={setShowRegisterForm} 
                        isLoginModal={isLoginModal}/>
                    :
                    <div className={isLoginModal ? 'login-modal-form-width' : 'form-width'}>
                        <div className='m-b-70'><Font21 bold color='#191919'>Enter your mobile number to get started</Font21></div>
                        <div style={{ marginBottom: 73 }}>
                            <AnimatedPhoneInput
                                label="Mobile Number"
                                handleInputChange={handlePhoneInputChange}
                                isMandatory={false}
                                inputId="phone1"
                                disabled={false}
                                value={phoneNumber || ''}
                                onKeyDown={handlePhoneInputKeyPress}
                   
                            />
                        </div>
                        <div className='m-b-10'><Font13 color='#636363'>By continuing, you agree to the <a href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer"> Terms of the service</a> and <a href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a> </Font13></div>
                        <Button
                            loading={verifyLoader}
                            type="primary"
                            style={{ width: "100%", marginBottom: 20 }}
                            disabled={!phoneNumber}
                            onClick={handleNumberSubmit}
                        >
                            {"VERIFY"}
                        </Button>
                        <Separator />
                        {isMobile ?
                            <Button
                                type="primary"
                                style={{ width: "100%", backgroundColor: "#FFFFFF", color: "#594099" }}
                                onClick={() => { setEmailIdSignIn(true); setShowVerifyOtpForm(false); }}
                            >CONTINUE WITH EMAIL ID</Button> :
                            <Font15>Sign in using <span style={{ color: "#1089FF", cursor: "pointer", fontWeight: 700 }} onClick={() => { setEmailIdSignIn(true); setShowVerifyOtpForm(false); }}>Email ID</span></Font15>}
                    </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    const { resendOtpTimer, timer, phoneNo, isUserRegistered } = state.loginNew;
    return { resendOtpTimer, timer, phoneNo, isUserRegistered };
};

const mapDispatchToProps = (dispatch) => ({
    postGenerateOtp: (captchaToken, callBack, successCallBack) => dispatch(postGenerateOtp(captchaToken, callBack, successCallBack)),
    postVerifyOtp: (otp, history, callBack, successCallBack, invalidOtpCallBack) => dispatch(postVerifyOtp(otp, history, callBack, successCallBack, invalidOtpCallBack)),
    storePhoneNumber: (val) => dispatch(storePhoneNumber(val)),
    postEmailLogIn: (captchaToken, userEmailRegister, history, callBack) => dispatch(postEmailLogIn(captchaToken, userEmailRegister, history, callBack)),
    sendForgotLink: (userEmailRegister, history, callBack) => dispatch(sendForgotLink(userEmailRegister, history, callBack)),
    postRegisterDetails: (userRegister, callback, successCallBack, history = null) => dispatch(postRegisterDetails(userRegister, callback, successCallBack, history)),
    postGenerateRegistrationOtp: (email, callback, successCallBack) => dispatch(postGenerateRegistrationOtp(email, callback, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignInForm);
