import React, { useState, useEffect } from 'react'
import { Modal, Typography } from 'antd'
import logo from '../assets/Model/Group-1.png'
import { fetchTeacherData } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import AddTeacherFormView from './addTeacherFormView'
import { Button } from 'components/Button'
const { Text } = Typography


const AddTeacherModal = ({ isModalVisible, setIsModalVisible, teacherMangementList, setVisibleForm }) => {

  //  const [visibleForm,setVisibleForm] = useState(false)
  useEffect(() => {
    fetchTeacherData()
  }, [])
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleClick = () => {
    setVisibleForm(true)
    setIsModalVisible(false)
  }
  return (
    <Modal
      className='rounded-border'
      visible={isModalVisible} footer={null} centered={true}
      closable={false} bodyStyle={{ height: "400px", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}
      maskClosable={false}
    >
      <div style={{ width: "470px", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
        <img src={logo} className="img-mod" />
        <Text style={{ margin: "0px" }}>Viewing access</Text>
        <Text>Kindly give viewing access to concerned teacher</Text><br></br>
        <div style={{ width: "470px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Button style={{ width: "122px", marginRight: "5px",}} type="default" onClick={() => { handleCancel() }}>Later</Button>
          <Button style={{ marginLeft: "5px"}} type="primary" onClick={() => handleClick()} icon={"+"}>Add a teacher</Button>

          {/* {(visibleForm && <AddTeacherFormView visibleForm={visibleForm} setVisibleForm={setVisibleForm} teacherMangementList={teacherMangementList}/>)} */}
        </div>

      </div>
    </Modal>
  )
}
const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    teacherMangementList

  } = state.digitalLibrary;
  return {
    classLibraryList,
    classSubjectList,
    teacherMangementList

  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeacherModal);