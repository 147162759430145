import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined, RightCircleFilled } from '@ant-design/icons';
import { PageHeader, Row, Col, Select, Progress } from 'antd';
import './styles.css'
import ResultBySubject from './resultBySubject';
import { connect } from "react-redux";
import { updateTestType } from 'actions/studentTest';


function ResultsSummary({updateTestType}) {
    const history = useHistory();
    const handleViewSolutionsClick = () => {
        updateTestType("Attempted")
        history.push('/student-test/subject/test1/solutions')
    }
    return (

        <div style={{height:"100%", marginLeft:"5px"}}>
              <PageHeader
                title={<div className="text-md"> Result</div>}
                onBack={() => history.goBack()}
                backIcon={<ArrowLeftOutlined  style={{fontSize: "24px",fontFamily:"Roboto",fontWeight:'bold'}} />	}
                />
                <div style={{marginLeft:"2%"}}>
                    <h3 style={{fontFamily:"Roboto", fontSize:"24px",fontWeight:'bold',marginBottom:"0px"}}>JEE Mock Test 2021</h3>
                    <p style={{fontFamily:"Roboto", fontSize:"14px"}}>Mathematics • Physics • Chemistry</p>
                </div>
            <div className="responsive_style" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                <div className="circleStroke" style={{display:"flex", flexDirection:"row",height:"70%",width:"600px", boxShadow:"10px 10px 18px #302A5F14",borderRadius:"10px",alignItems:"center",justifyContent:"flex-start"}}>

                    <div>
                        <div><h3 style={{fontWeight:"700",marginLeft:"30px",marginTop:"-30px",marginBottom:"30px"}}>Score</h3></div>
                        <div style={{marginLeft:"20%"}}>
                            <Progress
                                        type="circle"
                                        percent={75}
                                        strokeWidth={10}
                                        width={200}
                                        strokeLinecap="square"
                                        strokeColor="#594099"
                                        trailColor="#E6E6E6"
                                        format={(percent) => (
                                            <>
                                                <div className="bold-600 m-b-10 " style={{fontSize: "0.6em"}}>75</div>
                                                <div className="bold-600 m-b-10 " style={{fontSize: "0.6em",margin:"0 auto",width:"20%",borderBottom:"black solid 1px"}}></div>
                                                <div className="bold-600 m-b-10 " style={{fontSize: "0.6em"}}>100</div>

                                            </>

                                            )}

                            />
                        </div>

                    </div>
                    <div style={{display:"flex",flexDirection:"row",marginTop:"6%",marginLeft:"15%"}}>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"center"}}>


                            <div><img style={{ marginBottom:"40%",width:"80%",height:"80%"}} src={require('Assets/images/ic-faq.svg').default} alt="" /></div>
                            <div><img style={{ marginBottom:"40%",width:"80%",height:"80%"}} src={require('Assets/images/ic-call-us.svg').default} alt="" /></div>
                            <div><img style={{ marginBottom:"40%",width:"80%",height:"80%"}} src={require('Assets/images/ic-chat.svg').default} alt="" /></div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"left",alignItems:"start",marginTop:"4%"}}>
                            <div className="noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>20</h3><p className="results_p1">corrrect</p></div>
                            <div className="noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>12</h3><p className="results_p1">incorrect</p></div>
                            <div className="noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>10</h3><p className="results_p1">Unattempted</p></div>

                        </div>
                    </div>
                </div>
                {/* ////////leftpart */}

                <div className="rightPart">
                    <h3 style={{marginTop:"3%",marginBottom:"3%",fontWeight:"700"}}>Performance analysis</h3>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                    <div style={{}}>
                            <div className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10684.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Rank</p></div>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10690.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Accuracy</p></div>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10691.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Speed</p></div>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10685.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Percentile</p></div>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"left",alignItems:"start",marginTop:"-4%",paddingRight:"5%"}}>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>23</h3><p className="results_p">/45</p></div>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>8</h3><p className="results_p">%</p></div>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>2</h3><p className="results_p">Mins/Qs</p></div>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>96</h3><p className="results_p">%</p></div>

                    </div>
                    </div>

                </div>

                {/* //////bottomleft content */}
                <div className="mediaBottompart" style={{ boxShadow: "0px 10px 10px 5px #302A5F14",   borderRadius:"10px",paddingRight:"10px", marginTop:"20px",paddingLeft:"20px"}}>
                    <h3 style={{marginLeft:"3%",fontWeight:"700",fontFamily:"roboto",marginTop:"20px"}}>Section</h3>
                    <div>
                        <div style={{borderBottom:"#E6E6E6 solid 0.01rem",paddingBottom:"0px"}}>
                             <ResultBySubject subject='Mathematics'/>
                        </div >
                        <div style={{borderBottom:"#E6E6E6 solid 0.01rem"}}>
                            <ResultBySubject subject='Physics'/>
                        </div>
                        <div style={{}}>
                            <ResultBySubject subject='Chemistry'/>
                        </div>
                    </div>
                     <div className="viewBtn"><button className="viewSolutionsbtn" onClick={() =>handleViewSolutionsClick()}><span style={{fontSize:"10px",fontWeight:"600"}}>VIEW SOLUTIONS</span></button></div>
                </div>
            </div>
        </div>


    )
}

const mapStateToProps = state => {
    return {
    };
  };

  const mapDispatchToProps = dispatch => ({
    updateTestType: (newType)=>dispatch(updateTestType(newType))
  });
export default connect(mapStateToProps, mapDispatchToProps)(ResultsSummary);