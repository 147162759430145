import {
  TOGGLE_USER_DETAIL_MODAL,
  TOGGLE_GENERATE_LINK_MODAL,
  TOTAL_LINKS_PENDING,
  TOTAL_LINKS_OVERDUE,
  TOTAL_LINKS_PAID,
  TOTAL_LINKS_REQUESTED,
  TOTAL_LINKS_SETTLED,
  TOTAL_LINKS_REFUNDED,
  UPDATE_ALL_TRANSACTIONS,
  UPDATE_RECENT_TRANSACTIONS,
  UPDATE_TABLE_NAME,
  TOGGLE_FEE_SEARCH_LOADER,
  CURRENT_TRANSACTION_USER,
  UPDATE_USER_TRANSACTIONS,
  TOGGLE_FEE_LOADER,
  UPDATE_FEE_STANDARDS,
  UPDATE_FEE_USERS,
  TOGGLE_FEE_BUTTON_LOADER,
  UPDATE_FEE_STUDENTS,
  UPDATE_SELECTED_FEE_STUDENTS,
  UPDATE_REJECT_KYC_MESSAGE,
  UPDATE_VALID_GST,
  UPDATE_TRANSACTION_FEE_PAYER,
  TOTAL_LINKS_CANCELLED,
} from "actions/fees";

const initialState = {
  feeStudents: [],
  feeLoader: false,
  tableName: "All transactions",
  showUserDetailModal: false,
  showGenerateLinkModal: false,
  totalLinksSettled: {
    count: 0,
    amount: 0.0,
  },
  totalLinksRefunded: {
    count: 0,
    amount: 0.0,
  },
  totalLinksRequested: {
    count: 0,
    amount: 0.0,
  },
  totalLinksPaid: {
    count: 0,
    amount: 0.0,
  },
  totalLinksOverdue: {
    count: 0,
    amount: 0.0,
  },
  totalLinksPending: {
    count: 0,
    amount: 0.0,
  },
  totalLinksCancelled: {
    count: 0,
    amount: 0.0,
  },
  recentTransactions: [],
  allTransactions: [],
  userTransactions: [],
  feeSearchLoader: false,
  transactionUser: {},
  standards: [],
  feeUsers: [],
  feeButtonLoader: false,
  selectedFeeStudents: {
    ids: [],
    rows: [],
  },
  rejectKycMessage: "",
  gstValid: false,
  transactionFeePayer: "student",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TRANSACTION_FEE_PAYER:
      return {
        ...state,
        transactionFeePayer: action.payer,
      };
    case UPDATE_VALID_GST:
      return {
        ...state,
        gstValid: action.bool,
      };
    case UPDATE_REJECT_KYC_MESSAGE:
      return {
        ...state,
        rejectKycMessage: action.message,
      };
    case UPDATE_SELECTED_FEE_STUDENTS:
      return {
        ...state,
        selectedFeeStudents: {
          ids: [...action.ids],
          rows: [...action.rows],
        },
      };
    case UPDATE_FEE_STUDENTS:
      return {
        ...state,
        feeStudents: [...action.data],
      };
    case TOGGLE_FEE_BUTTON_LOADER:
      return {
        ...state,
        feeButtonLoader: action.value,
      };
    case UPDATE_FEE_USERS:
      return {
        ...state,
        feeUsers: [...action.users],
      };
    case UPDATE_FEE_STANDARDS:
      return {
        ...state,
        standards: [...action.data],
      };
    case TOGGLE_FEE_LOADER:
      return {
        ...state,
        feeLoader: action.value,
      };
    case UPDATE_USER_TRANSACTIONS:
      return {
        ...state,
        userTransactions: [...action.data],
      };
    case CURRENT_TRANSACTION_USER:
      return {
        ...state,
        transactionUser: action.user,
      };
    case TOGGLE_FEE_SEARCH_LOADER:
      return {
        ...state,
        feeSearchLoader: action.value,
      };
    case UPDATE_TABLE_NAME:
      return {
        ...state,
        tableName: action.name,
      };
    case TOTAL_LINKS_PENDING:
      return {
        ...state,
        totalLinksPending: { ...action.data },
      };
    case TOTAL_LINKS_SETTLED:
      return {
        ...state,
        totalLinksSettled: { ...action.data },
      };
    case TOTAL_LINKS_REFUNDED:
      return {
        ...state,
        totalLinksRefunded: { ...action.data },
      };
    case TOTAL_LINKS_CANCELLED:
      return {
        ...state,
        totalLinksCancelled: { ...action.data },
      };
    case TOTAL_LINKS_OVERDUE:
      return {
        ...state,
        totalLinksOverdue: { ...action.data },
      };
    case TOTAL_LINKS_PAID:
      return {
        ...state,
        totalLinksPaid: { ...action.data },
      };
    case TOTAL_LINKS_REQUESTED:
      return {
        ...state,
        totalLinksRequested: { ...action.data },
      };
    case TOGGLE_USER_DETAIL_MODAL:
      return {
        ...state,
        showUserDetailModal: action.value,
      };
    case TOGGLE_GENERATE_LINK_MODAL:
      return {
        ...state,
        showGenerateLinkModal: action.value,
      };
    case UPDATE_ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: [...action.data],
      };
    case UPDATE_RECENT_TRANSACTIONS:
      return {
        ...state,
        recentTransactions: [...action.data],
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
