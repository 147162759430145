

import { Col, DatePicker, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DemoColumn from "./antGraph";
import image1 from "../../Assets/care/no-subjects.svg";
import { getFeeOverView, setOverviewFilter } from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";

const { Option } = Select;


function TransactionGraph({
  feeMangementData,
  setOverviewFilter,
  getFeeOverView,
  overviewFilter,
  storeTransactionData
}) {
  let [graphData, setGraphData] = useState([]);
  let [sum, setSum] = useState(0);
  const [type, setType] = useState(null);
  const [pageLoaderFilter, tooglePageLoaderFilter] = useState(false);
  const [dateClose, setDateClose] = useState(false)

  console.log("AA-abcdef", storeTransactionData?.graph);
  useEffect(() => {
    setOverviewFilter("type", "week");
    setOverviewFilter(
      "startDate",
      moment().subtract(6, "days").format("YYYY-MM-DD")
    );
    setOverviewFilter(
      "endDate",
      moment().format("YYYY-MM-DD")
    );
    setType("week");
  }, []);

  useEffect(() => {
    graphData = [];
    sum = 0;
    if (storeTransactionData?.graph?.length != 0) {
      storeTransactionData?.graph?.map(
        (item) => (
          (sum = sum + parseFloat(item?.totalSumRequested)),
          graphData.push({
            type: "Payment Requested",
            short_date: item?.short_date,
            Payment_Requested: parseFloat(item?.totalSumRequested),
            totalCountequested: item?.totalCountequested
          })
        )

      )
    }

    setSum(sum);
    setGraphData([...graphData]);
  }, [storeTransactionData?.graph]);

  return (
    <div>
      <div
        className="careAdminSummaryCard"
        style={{
          boxShadow: "none",
          height: "300px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "-10px",
            marginBottom: "10px",
          }}
        >
        </div>
        <div style={{ width: "100%", height: "97%" }}>
          {sum == 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "30%", height: "30%", marginBottom: "20px" }}
                src={image1}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontWeight: "500" }}>No data!</h1>
                <p
                  style={{
                    color: "#636363",
                    width: "60%",
                    textAlign: "center",
                  }}
                >
                  There are no data to show yet. Please try some other date
                  range.
                </p>
              </div>
            </div>
          ) : (
            <DemoColumn
              graphData={graphData}
              xField={"short_date"}
              yField={"Payment_Requested"}
            />
          )}
        </div>
      </div>
      {/* <PageDataLoader visible={pageLoaderFilter} /> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { storeTransactionData } = state.profileNew
  const { userRowDetails } = state.userManagementV2;
  return { storeTransactionData, userRowDetails }
}

const mapDispatchToProps = (dispatch) => ({
  setOverviewFilter: (key, res) => dispatch(setOverviewFilter(key, res)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionGraph);
