import {
    HANDLE_RESEND_OTP_TIMER,
    UPDATE_ORGS_STANDARD_LIST,
    SET_SHOW_REGISTER_FORM,
    STORE_PHONE_NUMBER,
    UPDATE_ORG_DATA,
    SET_IS_USER_REGISTERED,
    SET_IS_OTP_VERIFIED,
    SET_SHOW_DOWNLOAD_APP_MODAL,
    SET_REGISTER_SUCCESS_MODAL,
    SET_SHOW_LOGIN_MODAL,
    SET_ENROLLED_ITEM_ID,
    SET_SHOW_BOTTOM_SHEET
} from "../actions/loginNew";

const initialState = {
    orgData: {
        logo: "",
        orgId: ""
    },
    phoneNo: "",
    showRegisterForm: false,
    resendOtpTimer: false,
    isUserRegistered: false,
    timer: 30,
    orgStandards: [],
    isOtpVerified: false,
    showDownloadAppModal: false,
    showRegisterSuccessModal: false,
    showLoginModal: false,
    courseEnrolledId:{id:null, type:''},
    showBottomSheet:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_RESEND_OTP_TIMER:
            return {
                ...state,
                resendOtpTimer: action.bool,
                timer: action.sec
            };
        case UPDATE_ORGS_STANDARD_LIST:
            return {
                ...state,
                orgStandards: action.orgStandards
            };
        case SET_SHOW_REGISTER_FORM:
            return {
                ...state,
                showRegisterForm: action.bool
            }
        case STORE_PHONE_NUMBER:
            return {
                ...state,
                phoneNo: action.val
            }
        case UPDATE_ORG_DATA:
            return {
                ...state,
                orgData: { ...action.orgData }
            };
        case SET_IS_USER_REGISTERED:
            return {
                ...state,
                isUserRegistered: action.val
            }
        case SET_IS_OTP_VERIFIED:
            return {
                ...state,
                isOtpVerified: action.val
            }
        case SET_SHOW_DOWNLOAD_APP_MODAL:
            return {
                ...state,
                showDownloadAppModal: action.val
            }
        case SET_REGISTER_SUCCESS_MODAL:
            return {
                ...state,
                showRegisterSuccessModal: action.val
            }
        case SET_SHOW_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: action.val
            }
        case SET_ENROLLED_ITEM_ID:
            return {
                ...state,
                courseEnrolledId: action.val
            }
        case SET_SHOW_BOTTOM_SHEET:
            return {
                ...state,
                showBottomSheet: action.val
            }
        default:
            return state;
    }
}

export default reducer;