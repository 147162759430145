import React from "react";
import courseIcon from "../../Assets/course/ic-course (2).svg";
import moment from "moment";
import { StopOutlined } from "@ant-design/icons";
import { Typography } from "antd";

function CourseCard({ item }) {
  return (
    <div
      className="box-shadow flex_row_center"
      style={{
        width: "330px",
        padding: "20px",
        margin: "20px",
        height: "100px",
        cursor: "pointer",
      }}
    >
      <div className="flex_row_center" style={{ justifyContent: "center" }}>
        <div style={{ marginLeft: "0px" }}>
          <img width="30%" src={courseIcon} alt="" />
        </div>
      </div>
      <div style={{ marginLeft: "-100px", width: "150px" }}>
        <Typography.Text
          ellipsis={{ tooltip: true }}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "0px",
            width: "80px",
          }}
        >
          {item?.courseName}
        </Typography.Text>
        <div
          style={{
            color: "#636363",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          Since {moment(item?.startDate).format("DD/MM/YYYY")}
        </div>
      </div>
      {item?.isActive != 1 ? (
        <div
          style={{
            color: "#FF414D",

            marginTop: "-40px",
            fontSize: "11px",
            fontFamily: "roboto",
            fontWeight: "bold",
          }}
        >
          <StopOutlined style={{ marginRight: "3px" }} />
          INACTIVE
        </div>
      ) : (
        <div style={{ height: "20px" }}></div>
      )}
    </div>
  );
}

export default CourseCard;
