import { ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { deleteMarkingScheme, getMarkingSchemeData, searchFilter, storeMarkedSchemeCardId, storeMarkingSchemeObjective } from 'actions/testAdmin';
import { Button, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import CreateMarkingScheme from './modals/createMarkingScheme';
import PageDataLoader from 'components/pageDataLoader';
import { useHistory } from 'react-router';
import EditMarkingSchemeModal from './modals/editMarkingSchemeModal';
import ConfirmDeleteModal from './modals/confirmDeleteModal';
import { InputSearch } from 'components/customInputs';

const { Search } = Input;

const PredefinedTemplateCards = ({cardData, setIsEditModalVisisble, storeMarkedSchemeCardId,setIsPreDefined,fromPreDefined}) => {
  return(
    <>
      <div style={{
        height: "210px",
        width: "283px",
        background: "#FFFFFF",
        border: "1px solid #E6E6E6",
        borderRadius: "16px",
        margin: "10px 20px 0px 0px",
        padding: "20px",
        cursor: "pointer",
        display: 'flex',
        flexDirection: 'column'
      }}
        onClick={() => {
          setIsPreDefined(fromPreDefined)
          setIsEditModalVisisble(true);
          storeMarkedSchemeCardId(cardData?.schemeId)
          }}
          >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img style={{ width: "60px", height: "60px" }} src={require("../../Assets/test/icdlquestionbank.svg").default} />
        </div>
        <div style={{ fontSize: "18px", fontWeight: "bold", color: "#191919", marginTop: 30 }}>{cardData?.title}</div>
        <Typography.Text style={{ fontSize: "15px", fontWeight: "normal", color: "#636363", }} ellipsis={{ tooltip: true }}>{cardData?.description}</Typography.Text>
        <div style={{ fontSize: "14px", fontWeight: "normal", color: "#AEAEAE" }}>{cardData?.count} Conditions</div>
      </div>
    </>
  )
}

const NoMarkingSchemeCard = ({setIsCreateMarkingSchemeVisible, storeMarkedSchemeCardId}) => {
  const history=useHistory();
  return(
    <div style={{
      width: "100%",
      alignItems: "center",
      border: "#E6E6E6 solid 1px",
      borderRadius: "14px",
      height: "85vh",
      backgroundColor: "#FFFFFF",
      marginTop: "20px",
      cursor: "pointer"}}>
<div
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  }}
>
  <div style={{marginLeft:"50px"}}>
    <img style={{width:"30vw",height:"30vh"}}src={require("../../Assets/ill-no-data.svg").default} />
  </div>
  <div
    style={{
      fontSize: "21px",
      fontWeight: "bold",
      color: "#191919",         
    }}
  >
    No Marking Scheme available!
  </div>
  <div style={{ fontSize: "14px",color: "#636363",fontWeight:"normal" }}>
  Create a system for awarding marks for correct answers.
  </div>
  <div style={{ marginTop: "15px" }} 
  >
    <Button
      style={{
        borderRadius: "19px",
        width: "120px",
        height: "38px",
        fontWeight: "500",
        border: "1px solid #594099 ",
        backgroundColor: "#594099",
        color:"#FFFFFF"
      }}
      onClick={()=>{setIsCreateMarkingSchemeVisible(true);storeMarkedSchemeCardId(null)}}
    >
      <div>
        <PlusCircleOutlined className="m-r-5" />   Create
      </div>
    </Button>        
  </div>
  <div>          
  </div>
</div>
</div>
  )
}

function MarkingSchemeDashboard({getMarkingSchemeData,markingSchemeCardDataContainer,searchFilter,storeMarkedSchemeCardId,deleteMarkingScheme,storeMarkingSchemeObjective, searchedData}) {
  const [isCreateMarkingSchemeVisible,setIsCreateMarkingSchemeVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisisble] = useState(false)
  const [pageLoader, togglePageLoader] = useState(false);
  const [isDeleteModalVisible,setIsDeleteModalVisible] = useState(false)
  const [FromMarkingScheme,setFromMarkingScheme] = useState(true)
  const [isPreDefined, setIsPreDefined] = useState(false);

  const history=useHistory()

  useEffect(() => {
    togglePageLoader(true)
    getMarkingSchemeData(null,()=>{
    togglePageLoader(false)
    })
  }, [])

  const handleSearch = (value) => {
    searchFilter(value);
    togglePageLoader(true)
    getMarkingSchemeData(value, () => {
      togglePageLoader(false)})
}

  return (
    <div style={{ background: "#FAFAFA", width: "calc(100% + 40px)", height: "calc(100% + 40px)", margin: -20, padding: 20, overflowY: "auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div style={{ fontWeight: "700", display: "flex", flexDirection: "row" }}>
              <ArrowLeftOutlined
                style={{ marginTop: "10px", fontSize: "21px" }}
                onClick={() => { history.goBack();setFromMarkingScheme(false) }} />
              <div style={{ marginLeft: "15px", fontSize: "23px", color: "#191919", fontWeight: "bold" }}>Marking Schemes</div>
            </div>
            <div style={{ display: "flex", }}>
              <InputSearch value={searchedData} placeholder="search" style={{ width: '80%', marginRight: 50 }} allowClear onClear={()=>{handleSearch('')}} onSearch={(value)=>{handleSearch(value)}} onEnter={(e)=>{handleSearch(e)}} />
              <Button
                style={{
                  borderRadius: "19px",
                  width: "9vw",
                  height: "38px",
                  fontWeight: "500",
                  border: "1px solid #594099 ",
                  backgroundColor: "#594099",
                  color: "#FFFFFF"
                }}
                onClick={() => { setIsCreateMarkingSchemeVisible(true);storeMarkedSchemeCardId(null) }}>
                <div>
                  <PlusCircleOutlined className="m-r-5" />Create
                </div>
              </Button>
            </div>
          </div>
          {(markingSchemeCardDataContainer?.customized?.length > 0 || markingSchemeCardDataContainer?.preDefined?.length > 0) ?
          <>
          {markingSchemeCardDataContainer?.customized?.length > 0 ?
            <>
              <div style={{ fontSize: "19px", color: "#191919", fontWeight: "bold", marginTop: 40 }}>Your customized templates</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {markingSchemeCardDataContainer?.customized?.map((item) => (
                  <PredefinedTemplateCards
                    cardData={item}
                    setIsEditModalVisisble={setIsEditModalVisisble}
                    storeMarkedSchemeCardId={storeMarkedSchemeCardId}
                    storeMarkingSchemeObjective={storeMarkingSchemeObjective}
                    setIsPreDefined={setIsPreDefined}
                    fromPreDefined = {false}
                  />
                ))
                }
              </div>
            </>
            :
            null
          }
          {markingSchemeCardDataContainer?.preDefined?.length > 0 ?
            <>
              <div style={{ fontSize: "19px", color: "#191919", fontWeight: "bold", marginTop: 40 }}>Pre-defined templates</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {markingSchemeCardDataContainer?.preDefined?.map((item) => (
                  <PredefinedTemplateCards
                    cardData={item}
                    setIsEditModalVisisble={setIsEditModalVisisble}
                    storeMarkedSchemeCardId={storeMarkedSchemeCardId}
                    storeMarkingSchemeObjective={storeMarkingSchemeObjective}
                    setIsPreDefined={setIsPreDefined}
                    fromPreDefined = {true}
                  />
                ))
                }
              </div>
            </>
            :
            null
          }
          </>
          :
          <NoMarkingSchemeCard
          setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
          storeMarkedSchemeCardId={storeMarkedSchemeCardId}
          />
      }
      {isCreateMarkingSchemeVisible &&
        <CreateMarkingScheme
          isCreateMarkingSchemeVisible={isCreateMarkingSchemeVisible}
          setIsCreateMarkingSchemeVisible={setIsCreateMarkingSchemeVisible}
          togglePageLoader={togglePageLoader}
          getMarkingSchemeData={getMarkingSchemeData}
        />
      }
      {
        isEditModalVisible &&
        <EditMarkingSchemeModal 
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisisble={setIsEditModalVisisble}
        getMarkingSchemeData={getMarkingSchemeData}
        isDeleteModalVisible={isDeleteModalVisible}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
        FromMarkingScheme={FromMarkingScheme}
        isPreDefined={isPreDefined}
        />
      }
      {
        isDeleteModalVisible &&
        <ConfirmDeleteModal
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          setIsEditModalVisisble={setIsEditModalVisisble}
          deleteMarkingScheme={deleteMarkingScheme}
          togglePageLoader={togglePageLoader}
          getMarkingSchemeData={getMarkingSchemeData}
        />
      }
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {markingSchemeCardDataContainer, searchedData} = state?.testAdmin;
  return {
    markingSchemeCardDataContainer, searchedData}
};

const mapDispatchToProps = (dispatch) => ({
  getMarkingSchemeData: (search,callBack) => (dispatch(getMarkingSchemeData(search,callBack))),
  searchFilter:(val)=>(dispatch(searchFilter(val))),
  storeMarkedSchemeCardId: (val) => dispatch(storeMarkedSchemeCardId(val)),
  deleteMarkingScheme:(callBack,successCallBack) => dispatch(deleteMarkingScheme(callBack,successCallBack)),
  storeMarkingSchemeObjective:(val) => dispatch(storeMarkingSchemeObjective(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(MarkingSchemeDashboard);

// export default MarkingSchemeDashboard;