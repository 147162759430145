import React, { useEffect, useState } from 'react'
import "./courseContent.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'
import { Select, Tooltip } from 'antd'
import ChapterContent from './chapterContent'
import { SquareSubIcon } from 'components/subIcon'
import { connect } from 'react-redux'
import { getSubjectsDataCourseContent, setFromTestSeries } from 'actions/studentMarketPlace'

function CourseContent({ singleCourseDetailsDataContainer, fullData, getChapterDataCourseContent, getSubjectsDataCourseContent, fromMyCourcesPage, resourceType, setFromTestSeries, fromTestSeriesPage }) {
    const [courseChapters, setCourseChapters] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState();
    const [defaultSelectedCourse, setDefaultSelectedCourse] = useState('');
    const selectedStandardId = singleCourseDetailsDataContainer?.selectedStandard;
    const history = useHistory();
    console.log("courseChapters", singleCourseDetailsDataContainer?.subjects)

    const ChapterList = ({ courseChapter, index }) => {
        return (
            <div className='chapter-list-parent'>
                <div className='chapter-list-child'>
                    <div className='chapter-counts-index'>
                        Chapter {index + 1}
                    </div>
                    <div className='chapter-names'>
                        <Tooltip title={courseChapter?.name} placement='left'>
                            {courseChapter?.name}
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (fullData?.studentCourseStandards?.length > 0) {
            getSubjectsDataCourseContent(singleCourseDetailsDataContainer?.selectedStandard, (res) => {
                setCourseChapters(res);
            })
        }
    }, [])

    const handleSelectChange = (value) => {
        const selectedCourse = fullData?.studentCourseStandards[value];
        setSelectedCourse(selectedCourse?.id);
        getSubjectsDataCourseContent(selectedCourse?.id, (res) => {
            setCourseChapters(res);
        })

    };

    return (
        <div className={fromMyCourcesPage ? 'courses-content-container-my-course' : "courses-content-container"}>
            <div className='r-c-sb course-content-header m-t-30'> {singleCourseDetailsDataContainer?.subjects?.length && resourceType == "course" ? "Curriculum" : fullData?.studentCourseStandards?.length > 0 ? resourceType == "testSeries" ? 'Syllabus' : 'Course content' : null}
                {fullData?.studentCourseStandards?.length > 0 ?
                    <div className='filterDesign' style={{ width: "150px", height: "35px", cursor: "pointer" }}>
                        <Select
                            style={{ width: "100%", borderRadius: "100px" }}
                            onChange={handleSelectChange}
                            defaultValue={
                                selectedStandardId
                                    ? fullData?.studentCourseStandards?.find(
                                        (course) => course?.id === selectedStandardId
                                    )?.name
                                    : undefined
                            }
                            value={selectedCourse ? selectedCourse?.name : undefined}
                            dropdownClassName="custom-dropdown"
                        >
                            {fullData?.studentCourseStandards?.map((course, index) => (
                                <Option key={index} value={index}>
                                    {course?.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    : <></>}
            </div>
            <div className='course-chapter-parent'>
                {courseChapters?.length > 0 ? <>
                    {courseChapters?.map((item, index) => (
                        <div className='course-chapter-child' key={index} style={{ height: item?.courseChapter?.length > 6 ? '50dvh' : 'fit-content' }}>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent'>
                                    {item?.icon ? (
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} alt={`Chapter Icon for ${item?.name}`} />
                                    ) : (
                                        <SquareSubIcon name={item?.name} size={24} />
                                    )}
                                </div>
                                <div className='course-chapter-title'>
                                    <span className='chapter-name'>{item?.name}</span>
                                    <span className='chapter-total-count'>
                                        {fromMyCourcesPage ? item?.chapters?.length : item?.courseChapter?.length} {fromMyCourcesPage
                                            ? item?.chapters?.length == 1
                                                ? 'Chapter'
                                                : 'Chapters'
                                            : item?.courseChapter?.length == 1
                                                ? 'Chapter'
                                                : 'Chapters'}</span>
                                </div>
                            </div>
                            <div className='chapter-border-bottom'></div>
                            {((item?.chapters?.slice(0, 6) || item?.courseChapter?.slice(0, 6)) || []).map((chapter, chapterIndex) => (
                                <ChapterList key={chapterIndex} courseChapter={chapter} index={chapterIndex} />
                            ))}
                            {item?.courseChapter?.length > 6 || item?.chapters?.length > 6 ? (
                                <>
                                    {fullData?.studentCourseStandards?.length > 0 ? <>
                                        <div className='view-all-content p-10' onClick={() => {

                                            history.push({
                                                pathname: "/course-chapters",
                                                state: {
                                                    standardId: selectedStandardId,
                                                    subjectId: item?.id,
                                                }
                                            })
                                        }}>VIEW ALL</div>
                                    </> : <>
                                        <div className='view-all-content p-20' onClick={() => { fromMyCourcesPage ? history.push("/course-chapters") : history.push("/course-content") }}>VIEW ALL</div>
                                    </>}
                                </>
                            ) : null}
                        </div>
                    ))}
                </> : <>
                    {singleCourseDetailsDataContainer?.subjects?.map((item, index) => (
                        <div className='course-chapter-child' key={index} style={{ height:350 /* item?.courseChapter?.length > 6 ? '55dvh' : 'fit-content'  */}}>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent'>
                                    {item?.icon ? (
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} alt={`Chapter Icon for ${item?.name}`} />
                                    ) : (
                                        <SquareSubIcon name={item?.name} size={24} />
                                    )}
                                </div>
                                <div className='course-chapter-title'>
                                    <span className='chapter-name'>{item?.name}</span>
                                    <span className='chapter-total-count'>
                                        {fromMyCourcesPage ? item?.chapters?.length : item?.courseChapter?.length} {fromMyCourcesPage
                                            ? item?.chapters?.length == 1
                                                ? 'Chapter'
                                                : 'Chapters'
                                            : item?.courseChapter?.length == 1
                                                ? 'Chapter'
                                                : 'Chapters'}</span>
                                </div>
                            </div>
                            <div className='chapter-border-bottom'></div>
                            {((item?.chapters?.slice(0, 6) || item?.courseChapter?.slice(0, 6)) || []).map((chapter, chapterIndex) => (
                                <ChapterList key={chapterIndex} courseChapter={chapter} index={chapterIndex} />
                            ))}
                            {item?.courseChapter?.length > 6 || item?.chapters?.length > 6 ? (
                                <>
                                    {fullData?.studentCourseStandards?.length > 0 ? <>
                                        <div className='view-all-content p-20' onClick={() => {

                                            history.push({
                                                pathname: "/course-chapters",
                                                state: {
                                                    standardId: selectedStandardId,
                                                    subjectId: item?.id,
                                                }
                                            })
                                        }}>VIEW ALL</div>
                                    </> : <>
                                    <div className='view-all-content p-20' onClick={() => {fromMyCourcesPage? history.push("/course-chapters") : history.push({pathname : "/course-content", state: {key:{selectedSubject : index} }});setFromTestSeries(false) }}>VIEW ALL</div>
                                    </>}
                                </>
                            ) : null}
                        </div>
                    ))}
                </>}

            </div>
            <div className='course-chapter-parent-mobile'>
                {singleCourseDetailsDataContainer?.subjects?.map((item, index) => (
                    item?.courseChapter?.length > 0 ? (
                        <div className='course-chapter-child-mobile'>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent' >
                                    {item?.icon ?
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} />
                                        :
                                        <SquareSubIcon name={item?.name} size={24} />
                                    }
                                </div>
                                <div className='course-chapter-title' onClick={() => history.push({ pathname: "/course-content", state: { type: item } })}>
                                    <span className='chapter-name-mobile'>{item?.name}</span>
                                    <span className='chapter-total-count-mobile'> {item?.courseChapter?.length} {item?.courseChapter?.length === 1 ? 'Chapter' : 'Chapters'}</span>
                                </div>
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
        </div>
    )
}
<ChapterContent />


const mapStateToProps = (state) => {
    // const { } = state.studentMarketPlace
    // return {

    // }
};

const mapDispatchToProps = (dispatch) => ({
    getSubjectsDataCourseContent: (id, callback) => dispatch(getSubjectsDataCourseContent(id, callback)),
    getChapterDataCourseContent: (param, callback) => dispatch(getChapterDataCourseContent(param, callback)),
    setFromTestSeries: (val) => dispatch(setFromTestSeries(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContent)