import React from 'react'
import { RadialBar, Pie } from '@ant-design/charts';
import { useEffect, useRef } from 'react'

const StaffLeaveChartGraph = ({ leavesOverviewOfStaff }) => {
    {
        const data = [
            {
                type: 'Teachers',
                value: leavesOverviewOfStaff?.staffLeaveSummary?.Teacher ? leavesOverviewOfStaff?.staffLeaveSummary?.Teacher : 0
            },
            {
                type: 'Data Operators',
                value: leavesOverviewOfStaff?.staffLeaveSummary?.['Data Operator'] ? leavesOverviewOfStaff?.staffLeaveSummary?.['Data Operator'] : 0
            },
            {
                type: 'Operations',
                value: leavesOverviewOfStaff?.staffLeaveSummary?.Operation ? leavesOverviewOfStaff?.staffLeaveSummary?.Operation : 0
            }
        ];
        const config = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            color: ['#594099', '#8660E5', '#BBA8EA'],
            radius: 0.8,
            autoFit: true,
            legend: false,
            innerRadius: 0.68,
            label: {
                type: 'inner',
                offset: '-50%',
                content: "",
                style: {
                    fill: '#fff',
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 8
                    },
                    customHtml: function customHtml() {
                        return (
                            <div>
                                <div style={{ fontSize: "20px", fontWeight: "700" }}>{/* {attendanceDataContainer?.monthlyAttendance?.averagePercentage} */}{leavesOverviewOfStaff?.staffLeaveSummary?.totalLeaves
                                }</div>
                                <div style={{ fontSize: "10px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto", color: "grey" }}>Total staff on leave</div>
                            </div>
                        )
                    }
                },
            },
            annotations: [],
        };

        return (
            <>
                <div
                    style={{
                        width: "240px",
                        height: "355px"
                    }}>
                    <Pie {...config} />
                </div>
            </>

        )
    }
}

export default StaffLeaveChartGraph