function removeImage(image, discardedImages, imagesList, index) {
  imagesList.splice(index, 1);
  if(!discardedImages.includes(image)){
    discardedImages.push(image)
    return discardedImages
  }else{
    return
  }
}


function getDeletedImages(removedList, imageList){
  const deleteList = [];
  removedList.forEach(img => {
    if(!imageList.includes(img)){
      deleteList.push(img)
    }
  })
  return deleteList;
}


export {removeImage, getDeletedImages}
