var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
let liveCheckInterval;
import React, { useEffect, useState } from 'react';
import Hls from 'hls.js';
import Loader from 'pages/Test/localComponent/loader';
function ReactHlsPlayer(props) {
    const [isLoading, setIsLoading] = useState(true);
    var hlsConfig = props.hlsConfig;
    var _b = props.playerRef;
    var playerRef = _b === void 0 ? React.createRef() : _b;
    var src = props.src;
    var autoPlay = props.autoPlay;
    var liveCheckCallback = props.liveCheckCallback?props.liveCheckCallback:()=>{};
    var onEndCallback = props.onEndCallback ? props.onEndCallback : () => {};
    var videoProps = __rest(props, ["hlsConfig", "playerRef", "src", "autoPlay", "liveCheckCallback", "onEndCallback"]);
    const startLiveCheck = () => {
        liveCheckInterval = setInterval(() => {
            if (playerRef.current) {
                const currentTime = playerRef.current.currentTime;
                const duration = playerRef.current.duration;
                if (duration - currentTime < 8) { // 10 seconds threshold
                    liveCheckCallback(true);
                } else {
                    liveCheckCallback(false);
                }
            }
        }, 1000); // Check every second
    };
    const stopLiveCheck = () => {
        if (liveCheckInterval) {
            clearInterval(liveCheckInterval);
        }
    };
    useEffect(function () {
        var hls;
        function _initPlayer() {
            setIsLoading(true)
            if (hls != null) {
                hls.destroy();
            }
            var newHls = new Hls(__assign({ enableWorker: false }, hlsConfig));
            if (playerRef.current != null) {
                newHls.attachMedia(playerRef.current);
            }
            newHls.on(Hls.Events.MEDIA_ATTACHED, function () {
                newHls.loadSource(src);
                newHls.on(Hls.Events.MANIFEST_PARSED, function () {
                    var _a;
                    if (autoPlay) {
                        (_a = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _a === void 0 ? void 0 : _a.play().catch(function () {
                            return console.log('Unable to autoplay prior to user interaction with the dom.');
                        });
                    }
                    startLiveCheck();
                });
            });
            newHls.on(Hls.Events.BUFFERING, () => {
                setIsLoading(true);
            });

            newHls.on(Hls.Events.BUFFER_APPENDED, () => {
                setIsLoading(false);
            });

            newHls.on(Hls.Events.ERROR, function (event, data) {
                console.log("eee error", event, data)
                setIsLoading(true);
                // if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            newHls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            newHls.recoverMediaError();
                            break;
                        default:
                            _initPlayer();
                            break;
                    }
                // }
            });
            hls = newHls;
        }
        if (Hls.isSupported()) {
            _initPlayer();
        }
        return function () {
            stopLiveCheck();
            if (hls != null) {
                hls.destroy();
            }
        };
    }, [autoPlay, hlsConfig, playerRef, src]);
    if (Hls.isSupported())
        return (
            <div style={{position:"relative"}}>
            <video ref={playerRef} onEnded={onEndCallback} {...videoProps}/>
            {isLoading && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 0,
                    color: '#fff',
                    padding: '20px 25px',
                    borderRadius: '5px',
                    background:"rgba(0, 0, 0, 0.4)",
                    pointerEvents:"none"
                }} onClick={(e)=>{e.stopPropagation()}}>
                    <Loader style={{color:"lightgray", fontSize:40}} />
                    <div className='m-t-15 disable-text-selection' style={{color:"lightgray", fontWeight:'bold'}}>Buffering .....</div>
                </div>
            )}
            </div>
        )
    return React.createElement("video", __assign({ ref: playerRef, src: src, autoPlay: autoPlay }, videoProps));
}
export default ReactHlsPlayer;
