import React from 'react'
import { Select, Input, Tooltip} from 'antd';
import './styles.css';
import { ArrowLeftOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import TestCard from './localComponents/practiseTestCards';
import { useHistory } from 'react-router-dom';
import {  fetchTests, fetchTestsByStage, toggleReloaderFlag, updateFilter, updateSelectedTest } from 'actions/practiseTest';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
import ConfirmationModal from './modals/confirmationModal';
import { NodataCard } from './index';
import DropdownCheckbox from 'pages/Test/localComponent/dropdownCheckbox';
import SearchBox from './localComponents/searchBox';
import Loader from './localComponents/loader';
import CustomDatePicker from 'pages/Test/localComponent/customDatePicker';
import CreateTestModal from './modals/createTestModal';
import TestPublishModal from './localComponents/testPublishModal';
const {Option} = Select;
let timer = false;


    

const PageHeader = ({classFilters, handleDateFilterSelect, selectedDateFilter, getResultsBool, setSearchText, 
    handleClassFilterSelect, title, goBack, syllabusFilters, handleSyllabusFilterSelect, onDropdownClose, filter, searchVal, onDateChange, 
    defaultDate, syllabusDefaultVals}) => {
    const onSearchChange = (e) => {
        setSearchText(e.target.value);
    }
    return (
        <div className="resultPageHeader full-width">
                        <div className="r-c-fs m-r-30">
                            <ArrowLeftOutlined onClick={goBack} style={{fontSize:18}}/>
                            <div className="text-md bold-700 m-l-20"> {title} </div>
                        </div>
                        <div className="testfiltersgroup">
                            <DropdownCheckbox DATA={syllabusFilters} 
                                              placeholder={`Filter by syllabus`}
                                              onSelection={handleSyllabusFilterSelect}
                                              onDropdownClose={onDropdownClose}
                                              defaultValue={syllabusDefaultVals}/>

                            <CustomDatePicker style={{borderRadius:5, width:180}} className="m-r-10 m-l-10"
                                              onDateChange={onDateChange}
                                              defaultDate={defaultDate}
                                              placeholder={filter?.VAtype==='results'?'Filter by result date':'Filter by test date'}
                                            />

                            <DropdownCheckbox DATA={classFilters} 
                                              placeholder={`Filter by class`}
                                              onSelection={handleClassFilterSelect}
                                              onDropdownClose={onDropdownClose}
                                              defaultValue={filter?.VAclasses}/>
                            
                            <SearchBox  loading={false} 
                                        onChange={onSearchChange}
                                        placeholder="Search by test name"
                                        style={{width:180, marginLeft:10}}
                                        value={searchVal}/>
                        </div>
                    </div>
    )
}



function PractiseViewAll({
    resultsToPublish,
    publishedResults,
    standards,
    fetchTests,
    getResultsBool,
    updateSelectedTest,
    isCModalVisible, 
    cModalType,
    reloadToggler,
    fetchTestsByStage,
    filter,
    updateFilter,
    toggleReloaderFlag,
    loaders,
    sentForFeedback, 
    feedbackFromTeacher, 
    testsToPublish,
    createTestModalBool
}) {
    const [pageLoader, togglePageLoader] = useState(false);
    const [isdateFiltered, setIsDateFiltered] = useState(false);
    const [selectedDateFilter, setSelectedDateFilter] = useState('Week')
    const [selectedClassFilter, setSelectedClassFilter] = useState(null); 
    const [filtersChanged, setFiltersChanged] = useState(false);
    let count = 0;
    var startDate = ""
    var endDate = ""
    useEffect(() => {
        setIsDateFiltered(false);
        // togglePageLoader(true);
        fetchTestsByStage(filter?.VAtype, ()=>{});
        
    }, [reloadToggler]);

    useEffect(()=>{
    }, [selectedClassFilter]);

    useEffect(() => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fetchTestsByStage(filter?.VAtype, ()=>{});
            },800);
        }
      , [filter?.VAsearch]);
      useEffect(() => {
            fetchTestsByStage(filter?.VAtype, ()=>{});
    }
  , [filter?.vaDate]);
    const history = useHistory();
    const resultsDueToPublish = [];
    const setSearchText = (val) => {
        updateFilter('VAsearch', val)
    }
    const handleResultCardClick = (test) => {
        updateSelectedTest(test)
        history.push('test-publish-result-screen')
      }
    const handleClassFilterSelect = (selection)=>{
        setFiltersChanged(true);
        updateFilter('VAclasses', selection);
    }
    const goBack = () => {
        history.goBack();
    }
    const onDropdownClose = () => {
        if (filtersChanged){
            toggleReloaderFlag();
            setFiltersChanged(false);
        }
    }
    const handleDateFilterSelect = (dateFilter, customDate)=>{
        endDate = moment().format("YYYY-MM-DD").toString();
        if (dateFilter&&dateFilter?.value == "Week"){
            startDate = moment().subtract(7,'d').format('YYYY-MM-DD').toString();
        }
        else if (dateFilter&&dateFilter?.value=="Month"){
            startDate = moment().startOf('month').format("YYYY-MM-DD").toString();
        }
        else if (dateFilter&&dateFilter?.value=="All"){
            endDate="";
            startDate="";
        }
        if (customDate?.length==2){
            startDate = customDate[0]?.split(' ')[0];
            endDate = customDate[1]?.split(' ')[0];
        }
        togglePageLoader(true);
        fetchTests("test-result-publish", (bool)=>{}, startDate, endDate);
        fetchTests("test-result-published", (bool)=>togglePageLoader(bool), startDate, endDate);
    }
    const classFilters = [...standards?.map(item => ({val:`${item.standard_std} - ${item.standard_section}`, id:item.standard_id}))]
    const syllabusFilters = [{val:'Chapter Test', id:1}, {val:'Part Test', id:2}, {val:'Full Test', id:3}, {val:'Previous Year Paper', id:4}]
    const syllabusDefaultVals = [];
    syllabusFilters.forEach((item)=>{
        if (filter?.VAsyllabus.includes(item.val)){
            syllabusDefaultVals.push(item.id);
        }
    })
    let currentdate = moment().format('YYYY/MM/DD').toString()
    const handleSyllabusFilterSelect = (selection)=>{
        let tempsyllabus = [];
        for (let id of selection){
            tempsyllabus.push(syllabusFilters[id-1].val);
        }
        setFiltersChanged(true);
        updateFilter('VAsyllabus', tempsyllabus);
    }

    const onDateChange = (v) => {
        if (v==undefined){
        updateFilter('isVAdatefiltered', false);
        updateFilter('vaDate', null);
        }else{
            updateFilter('isVAdatefiltered', true);
            updateFilter('vaDate', v.format("YYYY-MM-DD"));
        }
    }
    return (
        <div className="testListPage" style={{backgroundColor:"#FAFAFA", margin:-20, padding:20, overflow:"hidden", height:"calc(100% +40vh)"}}>
            <PageHeader classFilters={classFilters}
                        syllabusFilters={syllabusFilters} 
                        title={filter?.VAtype==="results"?"Result(s)":filter?.VAtype==='feedback'?'Feedback(s)':filter?.VAtype==='review'?'Test(s) to be reviewed':filter?.VAtype==='ready'?'Test(s) ready to be published':''}
                        goBack={goBack}
                        handleDateFilterSelect={handleDateFilterSelect} 
                        setSearchText={setSearchText} 
                        selectedDateFilter={selectedDateFilter} 
                        getResultsBool={getResultsBool}
                        handleClassFilterSelect={handleClassFilterSelect}
                        handleSyllabusFilterSelect={handleSyllabusFilterSelect}
                        onDropdownClose={onDropdownClose}
                        filter={filter}
                        searchVal={filter?.VAsearch}
                        onDateChange={onDateChange}
                        defaultDate={filter?.vaDate}
                        syllabusDefaultVals={syllabusDefaultVals}
                        />
                    {
                        loaders?.vaPageLoader?
                        <div style={{height:"88vh"}}>
                        <Loader style={{fontSize:40}}/>
                        </div>
                        :
                        <div style={{overflowY:"auto", height:"100%", paddingBottom:20}} className="m-t-20">
                            {
                                filter?.VAtype==='feedback'?
                                <>
                                <div className="r-c-fs flex-wrap">
                                {feedbackFromTeacher?.map((test, index) => (
                                    <TestCard
                                        type="feedback"
                                        page={"home"}
                                        test={test}
                                        key={index}
                                        index={index}
                                        handleResultCardClick={handleResultCardClick}
                                        sendForFeedback={false}
                                        fromHome={true}
                                        dash={true}
                                    />
                                    ))
                                }
                                </div>
                                {feedbackFromTeacher?.length===0?
                                <div style={{height:"83vh"}}> <NodataCard imgheight="35%" imgwidth="35%"/></div>:null}
                                </>
                                :filter?.VAtype==='ready'?
                                <>
                                <div className="r-c-fs flex-wrap">
                                {testsToPublish?.map((test, index)=>(
                                    <TestCard type='ready' 
                                              page={'home'}
                                              test={test}
                                              key={index} 
                                              index={index} 
                                              handleResultCardClick={handleResultCardClick}/>
                                    ))
                                }
                                </div>
                                {testsToPublish?.length===0?
                                <div style={{height:"83vh"}}> <NodataCard imgheight="35%" imgwidth="35%"/></div>:null}
                                </>
                                :filter?.VAtype==='review'?
                                <>
                                <div className="r-c-fs flex-wrap">
                                {sentForFeedback?.map((test, index) => (
                                    <TestCard
                                        type="review"
                                        test={test}
                                        key={index}
                                        page={"home"}
                                        index={index}
                                        handleResultCardClick={handleResultCardClick}
                                        sendForFeedback={true}
                                        dash={true}
                                    />
                                    ))}
                                </div>
                                {sentForFeedback?.length===0?<div style={{height:"83vh"}}> <NodataCard imgheight="35%" imgwidth="35%"/></div>:null}
                                </>
                                :null
                            }
                        </div>
                    }
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
        <PageDataLoader visible={pageLoader}/>
        {createTestModalBool && <CreateTestModal history={history} />}
        <TestPublishModal history={history} />
        </div>
    )
}
const mapStateToProps = state =>{
    const {resultsToPublish, publishedResults, standards, getResultsBool, isCModalVisible, 
        cModalType, reloadToggler, filter, loaders, sentForFeedback, feedbackFromTeacher, testsToPublish, createTestModalBool}=state.practiseTest;
    return { resultsToPublish, publishedResults, standards, getResultsBool, isCModalVisible, 
        cModalType, reloadToggler, filter, loaders, sentForFeedback, feedbackFromTeacher, testsToPublish, createTestModalBool}
}

const mapDispatchToProps = dispatch=> ({
    fetchTests: (type, callback, startDate, endDate, searchText)=>dispatch(fetchTests(type, callback, startDate, endDate, searchText)),
    updateSelectedTest: (test)=> dispatch(updateSelectedTest(test)),
    fetchTestsByStage: (type, callback) => dispatch(fetchTestsByStage(type, callback)),
    updateFilter: (key, val) => dispatch(updateFilter(key, val)),
    toggleReloaderFlag: () => dispatch(toggleReloaderFlag()),
})
export default connect(mapStateToProps, mapDispatchToProps)(PractiseViewAll);
