import React, { useState } from 'react'
import { Input, Tabs, Dropdown, Image, Select } from 'antd'
import emptyimage from "../assets/emty-tabs-subject-details/ill-es-sbj.png"
import UploadModal from '../modals/uploadModel'
import Uploadfiles from '../modals/uploadfiles'
import Videouploadmodel from '../modals/Videouploadmodel'
import { connect } from 'react-redux'
import { Button } from 'components/Button'
const EmptyCard = ({ uploadButton, arrStoreData }) => {

  const [modalUpload, setModalUpload] = useState(false)
  const [modal, setmodel] = useState(false)
  const [uploadFileModal, toggleUploadFileModel] = useState(false)
  const [type, setType] = useState('')


  return (
    <div className="all-cont">
      <div className="img-tex-btn">
        <img className="img-emp-cl" src={emptyimage} />
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
          {uploadButton ?
            (<div>
              <p className="p-emp">No content available!</p>
              <p className="p-c-emp">There is no content uploaded yet</p>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Button className='btn-primary-upload' type="primary" onClick={() => setModalUpload(true)}>Upload</Button>
              </div>
              {/* <div>
                <UploadModal visible={modalUpload} setVisible={setModalUpload} toggleUploadFileModel={toggleUploadFileModel} setType={setType} />
                {type === 'video' ? (uploadFileModal && <Videouploadmodel type={type} classess={arrStoreData[0]} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} />) : (uploadFileModal && <Uploadfiles classess={arrStoreData[0]} type={type} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} />)}
              </div> */}
            </div>)
            :
            (<div>
              <p className="p-emp">No content available!</p>
              <p className="p-c-emp">There is no content uploaded yet</p>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    updateChapterFilter,
    addedTeacher,
    arrStoreData,
    getTeacherLibraryList


  } = state.digitalLibrary;
  return {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    updateChapterFilter,
    addedTeacher,
    arrStoreData,
    getTeacherLibraryList
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(EmptyCard);
