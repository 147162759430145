import React, {useState} from 'react';
import { Checkbox, Modal } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import { connect } from 'react-redux';
import { deleteUsersPermanent } from 'actions/userManagementV2';


const PermanentDeleteModal = ({ open, onCancel, deleteUsersPermanent, selectedRowKeys, setSelectedRowKeys, params, togglePageLoader, getUsers }) => {

        const [deleteLoader, setDeleteLoader] = useState(false);
        const [check ,setCheck]=useState(false)

        console.log("abcd",{selectedRowKeys})

        const successCallback = () => {
                setSelectedRowKeys([]);
                onCancel();

                togglePageLoader(true);
                getUsers(params, () => togglePageLoader(false))
        }

        const handleDeleteClick = () => {

                let params= {
                        userIds: selectedRowKeys
                }

                setDeleteLoader(true);
                deleteUsersPermanent(params, () => setDeleteLoader(false), () => successCallback());
        }
        
        return (
                <div>
                        <Modal
                                centered
                                open={open}
                                footer={null}
                                className='modal-round-corner'
                                onCancel={onCancel}
                        >
                                <center className='p-20'>
                                        <Heading style={{color:"#FF414D"}}>You are about to permanently delete user(s)</Heading>
                                        <div style={{marginTop:"10px"}}> The user(s) will be permanently deleted from the database along with their data. You cannot undo this action.</div>
                                        <div style={{ width: '80%',marginTop:"15px" }}>
                                            <Checkbox onChange={(e)=>{setCheck(e.target.checked)}}/>
                                            <h style={{marginLeft:"10px"}}>I understand that deleting user(s) will remove it permanently from the system.</h></div>

                                        <div className='r-jsb m-t-40' style={{ width: '70%' }}>
                                                <Button disabled={!check} loading={deleteLoader} onClick={() => handleDeleteClick()} type='transparent' style={{ color: '#FF414D' }}>DELETE</Button>
                                                <Button onClick={() => onCancel()} type='primary'>CANCEL</Button>
                                        </div>
                                </center>
                        </Modal>
                </div>
        );
};

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { } = state.userManagementV2;
        return { };
};

const mapDispatchToProps = (dispatch) => ({
        deleteUsersPermanent: (params, callback, successCallback) => dispatch(deleteUsersPermanent(params, callback, successCallback))
});



export default connect(mapStateToProps, mapDispatchToProps)(PermanentDeleteModal);