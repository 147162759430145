import { CheckCircleFilled } from '@ant-design/icons'
import { showMenu } from 'actions/layout'
import { applyPromo, checkLiveCourse, fetchPromoCodes, getCartCount, getCartDetails, getCheckPayment, getCouponList, payNowCart, postAddSaveForLater, postAddSaveItem, postAddToCartSave, postCartPayment, postCourseEnrollNow, removeSaveItem, setCouponInCart, setPromoCode, setRenderPage, setSelectedCouponCode, setStorePromoPrice, storeCartCountData, storeCourseIdAndType, storeSingleBuyNow } from 'actions/studentMarketPlace'
import { Input, Tooltip } from 'antd'
import { Button } from 'components/Button'
import PageDataLoader from 'components/pageDataLoader'
import moment from 'moment'
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal'
import { generatePaymentRequest } from 'pages/profile/paymentGateway'
import React, { useEffect, useState } from 'react'
import HtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'
import CartIcon from "../../Assets/aulasMarket/Cart.svg"
import arrowDownIcon from "../../Assets/aulasMarket/ic-chevron-down.svg"
import arrowUpIcon from "../../Assets/aulasMarket/ic-chevron-up.svg"
import NoData from "../../Assets/aulasMarket/ill-no-data.svg"
import saveFill from "../../Assets/aulasMarket/saveFilledIcon.svg"
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import CouponsModal from './couponsModal'
import Footer from './footer'
import MobilePageHeader from './mobilePageHeader'
import "./mobilePageHeader.css"
import MobilePriceDetails from './mobilePriceDetails'
import RatingStars from './ratingStars'
import RemoveModal from './removeModal'
import "./saveForLater.css"
import "./studentCart.css"
import TopBar from './topBar'
import NewFooter from './newFooter'
import { InvalidCouponMessage, InvalidCouponType } from './constants/studentMarketPlace'
const defaultCourseImage = require('Assets/default_course-img.svg').default;
import DownloadAppModal from './downloadAppModal'
import FreeCourseConfirmationModal from './myCourses/freeConfirmationModal'

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
        if (c[i].nodeType === 1) return true;
    }
    return false;
}

export const CartSavedLater = ({ savedList, postCourseEnrollNow, postAddToCartSave, setRenderPage, renderPageLoading, getCartCount, setPromoCode, setShowDownloadAppModal }) => {
    const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
    const [removeId, setRemoveId] = useState({});
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState();
    const [arrowStates, setArrowStates] = useState({});
    const [cart, setCart] = useState({});
    const history = useHistory();
    const [enroll, setEnroll] = useState({})

    const [freeCourseName, setFreeCourseName] = useState('')
    const [freeCourseType, setFreeCourseType] = useState('')
    const [freeConfirmModal, setFreeConfirmModal] = useState(false)
    const [enrollCourseDetails, setEnrollCourseDetails] = useState({id:'',resourceType:''})

    useEffect(() => {
        getCartCount()
    }, [])

    const handleEnroll = (id, itemType) => {
        postCourseEnrollNow(id, itemType, (res) => {
            if (res) {
                setEnroll((prevSavedItems) => ({
                    ...prevSavedItems,
                    [id]: true,
                }));
                setFreeConfirmModal(false)
            }
             setShowDownloadAppModal(true)
        });
    };
    const SaveLaterCourseCard = ({ item }) => {
        const handleRemoveId = (id, itemType) => {
            setRemoveId({
                itemId: id,
                itemType: itemType,
            });
            setRemoveModalVisible(true);
        }
        const handleAddToCart = (id, itemType) => {
            postAddToCartSave(id, itemType, (res) => {
                if (res) {
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    getCartCount()
                    setRenderPage(!renderPageLoading)
                }
            })
        }

        const toggleArrowState = (itemId) => {
            setArrowStates((prevStates) => ({
                ...prevStates,
                [itemId]: !prevStates[itemId],
            }));
        };
        const handleBuyNow = (itemId, itemType) => {
            history.push({ pathname: '/user-checkout', state: { itemId, itemType } });
        }

        return (
            <div className='save-parent-container'>
                <div className='img-card-parent'>
                    {item?.resourceType == 'studyMaterial' ?
                        (<>
                            <div style={{ height: "100px", paddingTop: '10px' }}>
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 2, marginTop: "9px", }}>
                                    <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50, marginRight: "20px" }} />
                                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                            </div>
                        </>) :
                        <img className='cart-img-card' src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} />
                    }
                    {item?.mode ?
                        <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div>
                        : <></>}
                    {item?.amount > 0 ? <div className='card-amount-label'>₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount))?.toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0)}</span> : <></>}</div> : <></>}
                </div>
                <div className='save-title-parent'>
                    <div className='save-card-title' >{item?.resourceType == 'course' || item?.resourceType == "videoResource" ? item?.courseName : item?.name}
                        <span className='mobile-new-tag' style={{ marginLeft: 5 }}>{item?.new === "1" ? "NEW" : null}</span>
                    </div>
                    <div className='save-card-description'>
                        <Tooltip title={IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}>
                            {IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}
                        </Tooltip>
                    </div>

                    <div className='r-c-sb m-t-10 mobile-disable'>
                        <div className='r-c-fs' style={{ width: "40%" }} >
                            {item?.bestseller ?
                                <div className='save-best-seller'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='r-c-fs '><RatingStars star={item?.rating} />
                                    <span style={{ marginLeft: 5 }}>({item?.ratingCount})</span>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        {item?.owned == 1 ?
                            <Button onClick={() => { /* showMenu(true); history.push("/courses-list") */ setShowDownloadAppModal(true) }} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "10vw", fontSize: 13, marginTop: 5 }}>VIEW COURSE</Button>
                            :
                            <div className='r-c-c'>

                                {enroll[item?.id] || item?.owned == 1 ?
                                    <div style={{ display: "flex", alignItems: "center", margin: "30px 0px" }}>
                                        <CheckCircleFilled style={{ fontSize: 24, marginRight: 10, color: "#61E3A7" }} />
                                        <div style={{ color: "#594099", fontSize: 18, fontWeight: "bold" }}>ENROLLED</div>
                                    </div>
                                    :
                                    (<>
                                        {item?.amount == 0 || item?.resourceType == "course" ? <></> :
                                            <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, width: 140, height: 35 }} onClick={(e) => {
                                                e.stopPropagation()
                                                if ((item?.inCart == 1 || cart[item?.id])) {
                                                    history.push("/cart")
                                                } else {
                                                    handleAddToCart(item?.id, item?.resourceType);
                                                }
                                            }}>{item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                        }
                                        {item?.disableAdmission == 1 ? <></> :
                                            <Button type="primary" onClick={() => {
                                                if (item?.amount == 0) {
                                                    setFreeConfirmModal(true)
                                                    setFreeCourseType(item?.resourceType)
                                                    setFreeCourseName(item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName)
                                                    setEnrollCourseDetails({id:item?.id,resourceType:item?.resourceType})
                                                } else {
                                                    handleBuyNow(item?.id, item?.resourceType);
                                                    getCartCount()
                                                }
                                            }} style={{ width: 140, height: 35 }}>{item?.amount == 0 || item?.resourceType == "course" ? "ENROLL NOW" : "BUY NOW"}</Button>}
                                    </>)
                                }
                            </div>
                        }
                    </div>
                    <div className='mobile-enable'>
                        <div className='r-c-fs m-t-5' style={{ width: "100%" }} >
                            {item?.bestseller ?
                                <div className='save-best-seller'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='mobile-rating'><img className='mobile-star' src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /><span style={{ color: "#191919", fontWeight: "550" }}>{item?.rating}</span>
                                    <span style={{ marginLeft: 5 }}>({item?.ratingCount})</span>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <div className="r-c-fe">
                            <span className='save-share'><img className='save-share-img' onClick={() => {
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.itemType}`)
                            }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} /></span>
                            <span className='save-share' onClick={() => { toggleArrowState(item?.id); }}><img className='save-arrow-img' src={arrowStates[item?.id] ? arrowUpIcon : arrowDownIcon} /></span>
                            {/* <span style={{ fontWeight: 600, color: "#FF414D", fontSize: 12, cursor: "pointer" }}>REMOVE</span> */}
                        </div>
                    </div>
                    {item?.amount > 0 && (
                        <div className='mobile-enable m-t-10 font-bold' style={{ fontSize: 12 }}>
                            ₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}
                            {item?.discountAmount > 0 && (
                                <>
                                    <span style={{ color: '#636363', marginLeft: 5, textDecoration: 'line-through', fontWeight: "normal", fontSize: 10 }}>
                                        ₹ {(parseFloat(item?.amount).toFixed(2))}
                                    </span>
                                    <span style={{ color: "#28DF99", marginLeft: 5, fontSize: 10, fontWeight: "normal", fontSize: 10 }}>{(parseFloat(item?.discountPercent).toFixed(0))}% off</span>
                                </>
                            )}
                        </div>
                    )}
                    {arrowStates[item?.id] ?
                        <div className='r-c-sb m-t-10'>
                            {item?.mode == "free" || item?.amount == 0 || item?.resourceType == "course" ?
                                <span className='mobile-add' onClick={() => {
                                    setFreeConfirmModal(true)
                                    setFreeCourseType(item?.resourceType)
                                    setFreeCourseName(item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName)
                                    setEnrollCourseDetails({id:item?.id,resourceType:item?.resourceType})
                                }} >ENROLL NOW</span>
                                : (<>
                                    <span className='mobile-add' onClick={() => {
                                        {
                                            item?.inCart === "1" ?
                                                history.push("/cart")
                                                : handleAddToCart(item?.id, item?.resourceType)
                                        }
                                    }}>{item?.inCart === "1" || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</span>
                                    <span className='mobile-buy' onClick={() => handleBuyNow(item?.id, item?.resourceType)} >BUY NOW</span>
                                    <span className='mobile-delete' onClick={() => handleRemoveId(item?.id, item?.resourceType)}>DELETE</span>                                    </>)
                            }
                        </div>
                        : null}
                    <div className='m-t-10 mobile-disable'>
                        <span className='save-share' onClick={() => {
                            setShareModalVisible(true);
                            setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                        }}><img className='save-share-img' src={require("../../Assets/aulasMarket/shareIcon.svg").default} /><span className='m-l-5'>SHARE</span></span>
                        <span className='save-remove' onClick={() => handleRemoveId(item?.id, item?.resourceType)} >REMOVE</span>
                    </div>
                </div>
            </div>
        )
    }

    const handleFree = () => {
        handleEnroll(enrollCourseDetails?.id, enrollCourseDetails?.resourceType)
    }

    const NoItems = () => {
        return (
            <div>
                <div className='r-c-c-c' style={{ width: "100%", height: "80vh" }}>
                    <div className='empty-save-item r-c-c' ><img src={NoData} style={{ width: "100%" }} /></div>
                    <div className='font-bold m-t-20' style={{ color: "#191919", fontSize: 16 }}>No course saved!</div>
                    <div className='' style={{ color: "#636363", fontSize: 14 }}>You have not saved any courses yet!</div>
                    <div className='m-t-30'>
                        <Button type="secondary" style={{ color: "#594099", border: "1px solid #594099" }} onClick={() => { history.push("/") }}>EXPLORE COURSES</Button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div style={{ backgroundColor: "#FAFAFA", minHeight: "calc(100vh - 50px)" }}>
                <div className={'cart-main-container m-t-30'}>
                    <div className='saved-later-title'  >Saved for later   {savedList?.length > 0 ? <span style={{ color: "#AEAEAE" }}>({savedList?.length})</span> : <></>}</div>

                    {savedList?.length > 0 ?
                        <div className='save-card-container'>
                            {savedList?.map((item, index) => (
                                <div key={index} className='save-card-border'>
                                    <SaveLaterCourseCard item={item[item?.itemType]} />
                                </div>
                            ))}
                        </div>
                        :
                        <NoItems />
                    }
                </div>
            </div>
            {isRemoveModalVisible &&
                <RemoveModal
                    isRemoveModalVisible={isRemoveModalVisible}
                    setRemoveModalVisible={setRemoveModalVisible}
                    removeId={removeId}
                />
            }
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl}
                />}

            {freeConfirmModal && <FreeCourseConfirmationModal
                freeConfirmModal={freeConfirmModal}
                setFreeConfirmModal={setFreeConfirmModal}
                freeCourseName={freeCourseName}
                freeCourseType={freeCourseType}
                enrollCourseDetails={enrollCourseDetails}
                handleFree={handleFree} />
            }
        </div>
    )
}

function StudentCart({ getCartDetails, setSelectedCouponCode, couponList, getCheckPayment, selectedCouponCode, checkLiveCourse, getCouponList, postCourseEnrollNow, removeSaveItem, postAddSaveItem, postCartPayment, getCheckOutItem, setRenderPage, postAddToCartSave, renderPageLoading, postAddSaveForLater, applyPromo, payNowCart, setCartCount, storeSingleBuyNow, setCouponInCart, fetchPromoCodes, promoCodeDetials, cartDetails, user, couponInCart, storePromoPrice, offersCarouselData, showMenu, getCartCount, setPromoCode, storeCourseIdAndType, isDownloadBarVisible, showContactBar, isOffersBarVisible, setStorePromoPrice,storeCartCountData, userMarketPlaceHomeData }) {
    const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
    const [applyLoader, toggleApplyLoader] = useState(false);
    const [cartRemoveParam, setCartRemoveParam] = useState({});
    const [paynowLoader, togglePaynowLoader] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [cartList, setCartList] = useState([]);
    const [params, setParams] = useState({
        amount: null,
        code: '',
        updatedCourseBilling: true,
    });
    const [isLiveCourseInCart, setIsLiveCourseInCart] = useState(false);
    const [isInputTyped, setIsInputTyped] = useState(false);
    const history = useHistory();

    const [isCouponModal, setIsCouponModal] = useState(false);
    const [invalidCouponType, setInvalidCouponType] = useState(null);
    const paramsModal = { disablePagination: true, updatedCourseBilling: true }
    useEffect(() => {
        togglePageLoader(true);
        getCartDetails((res) => {
            setCartList(res);
            setParams((prev) => ({
                ...prev,
                amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
            }))
            togglePageLoader(false);
        });
        getCouponList(paramsModal);
        fetchPromoCodes(() => {
            togglePageLoader(false);
        });
        setCouponInCart(false)
    }, [renderPageLoading,]);

    const [isCourseInCart, setIsCourseInCart] = useState(false);

    let foundCourse = false;
    useEffect(() => {
        if (Array.isArray(cartList?.cart?.items)) {

            for (const item of cartList?.cart?.items) {
                if (item?.itemType === "course") {
                    foundCourse = true;
                    break; // Exit the loop once a course is found
                }
            }

            setIsCourseInCart(foundCourse);
        }
    }, [cartList]);


    useEffect(() => {
        getCartCount();
        setStorePromoPrice(null);
        setSelectedCouponCode(null);
    }, [])
    const EmptyCart = () => {
        return (
            <>
                <div className='empty-student-cart'>
                    <div ><img src={CartIcon} /></div>
                    <div className='m-l-10' style={{ fontSize: "16px", fontWeight: 600 }}>Your cart is empty</div>
                </div>
            </>
        )
    }
    const CartCard = ({ item, key }) => {
        const history = useHistory()
        setIsLiveCourseInCart(item?.itemType == "course" ? true : false)
        const [saved, setSaved] = useState({});
        const handleSaveForLater = (id, itemType) => {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        };
        return (
            <div className='cart-parent-container' >
                <div className='cart-img-card-parent'  >
                    {item?.resourceType == 'studyMaterial' ?
                        (<>
                            <div style={{ height: "100px", paddingTop: '10px', cursor: "pointer" }}
                                onClick={() => {
                                    storeCourseIdAndType("id", item?.id);
                                    storeCourseIdAndType("type", item?.resourceType)
                                    history.push({
                                        pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                        state: {
                                            id: item?.id,
                                            resourceType: item?.resourceType,
                                        }
                                    })
                                }}>
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 2, marginTop: "9px", }}>
                                    <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50, marginRight: "20px" }} />
                                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                            </div>
                        </>) :
                        item?.resourceType == "testSeries" ?
                            (<>
                                <div style={{ height: "100px", paddingTop: '10px', cursor: "pointer" }}
                                    onClick={() => {
                                        storeCourseIdAndType("id", item?.id);
                                        storeCourseIdAndType("type", item?.resourceType)
                                        history.push({
                                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                            state: {
                                                id: item?.id,
                                                resourceType: item?.resourceType,
                                            }
                                        })
                                    }}
                                >
                                    <div style={{ color: "#594099", fontSize: 16, marginLeft: 2, marginTop: "9px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                        <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50, marginRight: "10px" }} />
                                        {item?.tCount} {item?.tCount <= 1 ? "Test" : "Tests"}
                                        <div></div>
                                    </div>

                                </div>
                            </>) :
                            <img style={{ cursor: "pointer" }} className='cart-img-card ' src={`${Api.dlS3Url}${item?.image}`}
                                onClick={() => {
                                    storeCourseIdAndType("id", item?.id);
                                    storeCourseIdAndType("type", item?.resourceType)
                                    history.push({
                                        pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                        state: {
                                            id: item?.id,
                                            resourceType: item?.resourceType,
                                        }
                                    })
                                }} />}



                    {item?.mode ?
                        <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div>
                        : <></>}
                    {item?.amount > 0 ? <div className='card-amount-label'>₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0)}</span> : <></>}</div> : <></>}
                </div>
                <div className='cart-title-parent' >
                    <div className='cart-card-title'>{item?.resourceType == 'course' || item?.resourceType == "videoResource" ? item?.courseName : item?.name}
                        {item?.new === "0" ? null :
                            <span className='mobile-new-tag m-l-5'>NEW</span>
                        }
                    </div>
                    <div className='cart-card-description' >
                        <Tooltip title={IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}>
                            {IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}
                        </Tooltip>
                    </div>

                    <div className='r-c-sb m-t-10 mobile-disable'>
                        <div className='r-c-fs' style={{ width: "100%" }} >
                            {item?.bestseller ?
                                <div className='cart-best-seller m-r-10'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='r-c-fs '><RatingStars star={item?.rating} />
                                    <span style={{ marginLeft: 5 }}>({item?.ratingCount})</span>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <div className='mobile-enable'>
                        <div className='cart-seller-btn'  >
                            {item?.bestseller ?
                                <div className='save-best-seller'>Bestseller</div>
                                : null}
                            {item?.rating > 0 ?
                                <div className='mobile-rating'><img className='mobile-star' src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} /><span style={{ color: "#191919", fontWeight: "550" }}>{item?.rating}</span>
                                    <span style={{ marginLeft: 5 }}>({item?.ratingCount})</span>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        <div className="r-c-fe m-l-10">
                            {item?.owned == 1 ? <></> :
                                <span style={{ marginRight: 10, fontWeight: 600, color: "#594099", fontSize: 12, cursor: "pointer" }}>
                                    <img className='cart-delete-outline' onClick={(e) => { e.stopPropagation(); handleSaveForLater(item?.id, item?.resourceType) }} src={saved[item?.id] || item?.inSaved == 1 ? saveFill : saveOutlined} />
                                </span>
                            }
                            <span
                                onClick={() => {
                                    setCartRemoveParam({
                                        itemId: item?.id,
                                        itemType: item?.resourceType
                                    })
                                    setRemoveModalVisible(true);
                                }} className='mobile-delete' ><img className='cart-delete-outline' src={require("../../Assets/aulasMarket/ic-delete.svg").default} /></span>
                            {/* <span style={{ fontWeight: 600, color: "#FF414D", fontSize: 12, cursor: "pointer" }}>REMOVE</span> */}
                        </div>

                    </div>
                    {item?.amount > 0 && (
                        <div className='mobile-enable m-t-10 font-bold' style={{ fontSize: 12 }}>
                            ₹ {(parseFloat(item?.amount) - parseFloat(item?.discountAmount)).toFixed(2)}
                            {item?.discountAmount > 0 && (
                                <>
                                    <span style={{ color: '#636363', marginLeft: 5, textDecoration: 'line-through', fontWeight: "normal", fontSize: 10 }}>
                                        ₹ {(parseFloat(item?.amount).toFixed(2))}
                                    </span>
                                    <span style={{ color: "#28DF99", marginLeft: 5, fontSize: 10, fontWeight: "normal", fontSize: 10 }}>{(parseFloat(item?.discountPercent).toFixed(0))}% off</span>
                                </>
                            )}
                        </div>
                    )}
                    <div className='m-t-15 mobile-disable'>
                        <span onClick={() => {
                            togglePageLoader(true);
                            postAddSaveForLater(item?.id, item?.resourceType, (res) => {
                                togglePageLoader(false);
                                setRenderPage(!renderPageLoading)
                            })
                        }} style={{ marginRight: 20, fontWeight: 'bold', color: "#191919", fontSize: 12, cursor: "pointer" }}>SAVE FOR LATER</span>
                        <span onClick={() => {
                            setCartRemoveParam({
                                itemId: item?.id,
                                itemType: item?.resourceType
                            })
                            setRemoveModalVisible(true);
                        }} style={{ fontWeight: "bold", color: "#191919", fontSize: 12, cursor: "pointer" }}>REMOVE</span>
                    </div>
                </div>
            </div>
        )
    }
    const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
    const fromMarket = true;

    const handlePayNow = () => {
        const isLiveParam = {
            userId: cartList?.cart?.userId,
            userAccessToken: user?.accessToken
        }
        togglePaynowLoader(true)
        let paramsPay = {
            orderAmount: cartList?.cart?.totalAmount || null,
            orderCurrency: 'INR',
            orderId: generateOrderId.toString() || null,
            customerDetails: {
                customer_id: user?.id?.toString() || null,
                customer_email: user?.email || null,
                // customer_phone: '+916360068158'
                customer_phone: user?.phone?.toString() || null
            }
        };


        const checkPaymentParams = {
            amount: (isCouponApplied ? storePromoPrice?.cart?.totalAmount : cartList?.cart?.totalAmount) || 0,
            code: selectedCouponCode || '',
        }
        if (checkPaymentParams.amount == 0) {
            let paramProcess = {
                promo: params?.code,
                free: true,
                updatedCourseBilling: true,
            }
            getCheckPayment(checkPaymentParams, (res) => {
                if (res?.valid) {
                    postCartPayment(paramProcess, (res) => {
                        if (res) {
                            if (cartList?.cart?.hasLiveCourse) {
                                checkLiveCourse(isLiveParam);
                            }
                            setRenderPage(!renderPageLoading)
                            // history.push("/courses-list")
                            setShowDownloadAppModal(true)
                        }
                    }, () => togglePaynowLoader(false))
                }
                togglePaynowLoader(false)
            })
        } else {
            // let paramProcess = {
            //     promo: params?.code,
            // }
            getCheckPayment(checkPaymentParams, (res) => {
                if (res?.valid) {
                    paramsPay.orderAmount = checkPaymentParams.amount;
                    payNowCart(paramsPay, (res) => {
                        console.log("response123456", res)
                        togglePaynowLoader(false);
                        setPromoCode(params?.code || selectedCouponCode || '');
                        generatePaymentRequest(res, null, user, fromMarket)
                        // postCartPayment(paramProcess, (res) => {
                        //     console.log("response",res);
                        //     if (res) {
                        //         setRenderPage(!renderPageLoading)
                        //         history.push("/courses-list")
                        //     }
                        // })
                        togglePaynowLoader(false)
                    }, () => togglePaynowLoader(false), params?.code || selectedCouponCode)
                }
            })
        }


    }

    const numberWithCommas = (x) => {
        return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
    }
    const totalAmount = storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount;
    const totalAmountBeforeDiscount = cartList?.cart?.amount - cartList?.cart?.discount;
    const handleApplyClick = () => {
        let paramsOffer = {
            code: params?.code,
            type: 'offer'
        };
        toggleApplyLoader(true);
        applyPromo(paramsOffer, (res, error) => {
            toggleApplyLoader(false);
            if (res) {
                setInvalidCouponType(null);
            } else {
                setCouponInCart(false);
            }
        }, () => {
            setCouponInCart(true);
            toggleApplyLoader(false)
        }, (err) => {
            if (err.invalid) {
                setInvalidCouponType(InvalidCouponType.invalid);
            } else if (err.expired) {
                setInvalidCouponType(InvalidCouponType.expired);
            } else if (err.minPurchase) {
                setInvalidCouponType(InvalidCouponType.minPurchase);
            } else if (err.usageLimit) {
                setInvalidCouponType(InvalidCouponType.usageLimit);
            }
        });
    }
    const handleInputPromo = (e) => {
        const filteredValue = e.target.value.replace(/[^A-Za-z0-9]/g, ''); // Allow only alphanumeric characters
        setParams((prev) => ({
            ...prev,
            code: filteredValue
        }))
        setIsInputTyped(filteredValue.trim() !== '');
        setInvalidCouponType(null);
    }
    const isCouponApplied = !!couponInCart && !!storePromoPrice;
    const PriceDetails = () => {
        return (
            <div className='price-parent-container'>
                <div className='cart-price-details-title'>PRICE DETAILS</div>
                <div className='price-border'></div>
                <div className='cart-price'>Price
                    <span>₹{isCouponApplied ? storePromoPrice?.cart?.exAmount : cartList?.cart?.exAmount}</span>
                </div>
                <div className='cart-discount'>Discount
                    <span>-&nbsp;₹{isCouponApplied ? storePromoPrice?.cart?.eDiscountEx : cartList?.cart?.eDiscountEx}</span>
                </div>
                {(isCouponApplied && storePromoPrice?.cart?.offerDiscount) || cartList?.cart?.offerDiscount ?
                    <div className='cart-discount'>Offer Discount
                        <span style={{ color: "#28DF99" }}>
                            -&nbsp;₹{(isCouponApplied ? storePromoPrice?.cart?.offerDiscountEx : cartList?.cart?.offerDiscountEx)?.toLocaleString() || '0'}
                        </span>
                    </div> : null
                }
                {cartList?.cart?.isGSTEnabled ? <>
                    <div className='r-c-sb cart-price m-b-10' >
                        <div>GST</div>
                        <div>+&nbsp;₹{isCouponApplied ? storePromoPrice?.cart?.gst : cartList?.cart?.gst}</div>
                    </div>
                </> : <></>}
                <div className='price-border'></div>
                <div className='cart-total-amount'>Total amount
                    <span>
                        ₹{isCouponApplied ? storePromoPrice?.cart?.totalAmount : cartList?.cart?.totalAmount}
                    </span>
                </div>
                {/* <div className='price-border'></div> */}

            </div>
        )
    }
    const [showMobDiscount, setShowMobDiscount] = useState(true)
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const CalculateMarginTop = () => {
        const margins = {
            isDownloadBarVisible: 55,
            showContactBar: 55,
            isOfferBarVisible: 60,
            isMinOfferVisible: 55,
        }
        return 60 + (isDownloadBarVisible ? margins.isDownloadBarVisible : 0) +
            (showContactBar ? margins.showContactBar : 0) +
            (isOffersBarVisible ? margins.isOfferBarVisible : 0);
        // if (isDownloadBarVisible && showContactBar) {
        //     return 160
        // } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
        //     return 110
        // } else if (!isDownloadBarVisible && !showContactBar) {
        //     return 60
        // }
    }
    const [isCategories, setCategories] = useState([{
        categoriesName: null,
        subCategoriesName: null
      }])
const [hideBanner, SetHidBanner] = useState(false)
const [userSearchDataContainer, setUserSearchData] = useState(null);
const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null)
const [courses, setCourses] = useState(storeCartCountData.items || []);
const [isCategoryId, setCategoryId] = useState(null)
const [page, setPage] = useState(1);
const [hasMore, setHasMore] = useState(true);
const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)

    return (
        <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div className='mobile-topbar-parent'>
                <TopBar  SetHidBanner={SetHidBanner}
setCategoryId={setCategoryId}
setPage={setPage}
setHasMore={setHasMore}
page={page}
        setCourses={setCourses}
        userSearchData={setUserSearchData} autocompleteSearched={setAutocompleteSearchedValue} 
                setCategories={setCategories} offersCarouselData={offersCarouselData} isOffersBarVisible={isOffersBarVisible} isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked} />
            </div>
            <div className='mobile-header-parent'>
                <MobilePageHeader title={"Cart"} onBackPress={() => { history.goBack() }} sider={true} />
            </div>
            <div style={{ backgroundColor: "#FAFAFA", minHeight: "calc(100vh - 50px)" }}>
                {/* <OffersCarousel />
                {showMobDiscount && offersCarouselData?.length > 0 ?
                    <MobileDiscountOffersCarousel setShowMobDiscount={setShowMobDiscount} />
                    :
                    <></>
                } */}
                <div className='student-cart-parent'>
                    <div className='mobile-cart-header m-b-10' >Cart {cartList?.cart?.items?.length > 0 ? <span style={{ color: "#AEAEAE" }}>({cartList?.cart?.items?.length})</span> : <></>}</div>
                    {cartList?.cart?.items?.length > 0 ?
                        <div className='mobile-flex-price'>
                            {/* <div className='mobile-disable-cart-subheader'></div> */}
                            <div className='student-cart-child'>
                                <div className='cart-course-container'>
                                    {cartList?.cart?.items?.map((item, index) => (
                                        <div key={index} className='save-card-border'>
                                            <CartCard item={item[item?.itemType]} key={index} />
                                        </div>
                                    ))}
                                </div>
                                <div className='cart-price-details'>
                                    <PriceDetails />
                                    <div className='price-coupon-field'>
                                        {couponInCart ?
                                            <div className='price-applied  ' style={{ alignItems: "flex-start" }}>
                                                <div className='r-c-c'><img style={{ marginTop: -20 }} src={require("../../Assets/aulasMarket/ic-check-filled.svg").default} />
                                                    <div className='r-sfa m-l-10'>
                                                        <div style={{ color: "#191919", fontSize: 13, fontWeight: "600" }}>You saved ₹{numberWithCommas(storePromoPrice?.cart?.promoDiscount)} with this code</div>
                                                        <div style={{ color: "#AEAEAE", fontSize: 12 }}>Coupon Applied</div>
                                                    </div>
                                                </div>
                                                <div style={{ color: '#FF414D', cursor: 'pointer', marginLeft: 5, fontWeight: "500" }} onClick={() => { setCouponInCart(false), setRenderPage(!renderPageLoading), setSelectedCouponCode(null), setParams((prev) => ({ ...prev, code: '' })) }}>REMOVE</div>


                                            </div>
                                            :
                                            <div className=' r-c-c' style={{ display: 'flex' }}>
                                                <Input
                                                    className='price-coupon-filed'
                                                    type="text"
                                                    placeholder='Enter promotional offer'
                                                    onChange={(e) => handleInputPromo(e)}
                                                    value={params?.code}
                                                    style={{ height: "4.5vh", }}
                                                ></Input>
                                                <Button
                                                    loading={applyLoader}
                                                    className='r-c-c text-xm bold-600'
                                                    type="button"
                                                    onClick={handleApplyClick}

                                                    style={{
                                                        cursor: isInputTyped ? 'pointer' : 'not-allowed',
                                                        border: ` 1px solid #594099 ${isInputTyped ? 1 : 0.4}`,
                                                        // border: ` 1px solid #594099 `,
                                                        borderRadius: " 0px 4px 4px 0px",
                                                        backgroundColor: isInputTyped ? "#594099" : "white",
                                                        padding: '5px 10px',
                                                        color: isInputTyped ? "white" : "#594099",
                                                        height: "4.5vh",
                                                        opacity: isInputTyped ? 1 : 0.4
                                                        // opacity: 1
                                                    }}
                                                    disabled={!isInputTyped}
                                                >
                                                    APPLY
                                                </Button>
                                            </div>
                                        }
                                        {invalidCouponType ?
                                            <div style={{ color: "red", fontWeight: "normal" }}>{InvalidCouponMessage[invalidCouponType] || ''}</div>
                                            : null}
                                        {couponInCart || cartList?.cart?.totalAmount == 0 ? <></> : couponList?.items?.length > 0 ? <div onClick={() => { setIsCouponModal(true) }} style={{ fontSize: "13px", fontWeight: "bold", fontWeight: "bold", color: "#1089FF", cursor: "pointer", marginTop: 10 }}>VIEW ALL PROMOTIONAL OFFERS</div> : <></>}
                                        {
                                            !couponList?.minOffers?.length ? <></> : (<>
                                                <div className='cart-diffrence-amount'>
                                                    Add items worth ₹{couponList?.minOffers?.[0]?.dif?.toLocaleString() || ''} to get {couponList?.minOffers?.[0]?.discountType == 'percent' ? `${couponList?.minOffers?.[0]?.discount}%` : `₹${couponList?.minOffers?.[0]?.discount}`} off above ₹{couponList?.minOffers?.[0]?.minPurchase?.toLocaleString()}
                                                </div>
                                            </>)
                                        }
                                        {(isCouponApplied ? !storePromoPrice?.cart?.discount : !cartList?.cart?.discount ) ? <></> : (<>
                                            <div className='cart-diffrence-amount'>
                                                Your total savings on this purchase is ₹<span style={{ marginRight: 5 }}>{isCouponApplied ? storePromoPrice?.cart?.discount : cartList?.cart?.discount}</span>{" "}
                                            </div>
                                        </>)}
                                    </div>
                                    <div className='r-c-c' style={{ width: "100%" }}>
                                        <Button
                                            type="primary"
                                            loading={paynowLoader}
                                            onClick={() => handlePayNow()}
                                        >
                                            {!couponInCart ? (totalAmountBeforeDiscount === 0 ? "ENROLL NOW" : "PAY NOW") : (totalAmount === 0 ? "ENROLL NOW" : "PAY NOW")}
                                        </Button>

                                    </div>
                                </div>
                            </div>
                            <div className='mobile-price-enable'>
                                <MobilePriceDetails cartList={cartList} />
                            </div>

                        </div>
                        : <EmptyCart />}

                    <CartSavedLater savedList={cartList?.savedItems} postAddToCartSave={postAddToCartSave} setRenderPage={setRenderPage} renderPageLoading={renderPageLoading} postCourseEnrollNow={postCourseEnrollNow} getCartCount={getCartCount} setShowDownloadAppModal={setShowDownloadAppModal}/>
                </div>
            </div>
            <NewFooter />
            {
                isRemoveModalVisible &&
                <RemoveModal
                    isRemoveModalVisible={isRemoveModalVisible}
                    setRemoveModalVisible={setRemoveModalVisible}
                    cartRemoveParam={cartRemoveParam}
                />
            }
            {isCouponModal &&
                <CouponsModal
                    isCouponModal={isCouponModal}
                    setIsCouponModal={setIsCouponModal}
                    setParams={setParams}
                    setInvalidCouponType={setInvalidCouponType}
                />
            }
            {showDownloadAppModal &&
                <DownloadAppModal
                    showDownloadAppModal={showDownloadAppModal}
                    setShowDownloadAppModal={setShowDownloadAppModal}
                    userMarketPlaceHomeData={userMarketPlaceHomeData}
                />}


            <PageDataLoader visible={pageLoader} />
        </div >
    )
}

const mapStateToProps = (state) => {
    const { promoCodeDetials, cartDetails, couponList, selectedCouponCode, couponInCart, storePromoPrice, renderPageLoading, offersCarouselData, isDownloadBarVisible, showContactBar, isOffersBarVisible, userMarketPlaceHomeData } = state.studentMarketPlace
    const { user } = state.session
    return { promoCodeDetials, cartDetails, couponList, selectedCouponCode, user, couponInCart, storePromoPrice, renderPageLoading, offersCarouselData, isDownloadBarVisible, showContactBar, isOffersBarVisible, userMarketPlaceHomeData }
};

const mapDispatchToProps = (dispatch) => ({
    checkLiveCourse: (param) => dispatch(checkLiveCourse(param)),
    getCartDetails: (callback) => dispatch(getCartDetails(callback)),
    getCouponList: (param) => dispatch(getCouponList(param)),
    applyPromo: (params, callback, succesCallback, errorCallback = null) => dispatch(applyPromo(params, callback, succesCallback, errorCallback)),
    payNowCart: (params, callback, secondCallBack, promoCode = null) => dispatch(payNowCart(params, callback, secondCallBack, promoCode)),
    fetchPromoCodes: (callback) => dispatch(fetchPromoCodes(callback)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    postAddSaveForLater: (id, itemType, callback) => dispatch(postAddSaveForLater(id, itemType, callback)),
    setRenderPage: (val) => dispatch(setRenderPage(val)),
    postAddToCartSave: (id, itemType, callback) => dispatch(postAddToCartSave(id, itemType, callback)),
    getCheckPayment: (param, callback) => dispatch(getCheckPayment(param, callback)),
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    getCartCount: () => dispatch(getCartCount()),
    setPromoCode: val => dispatch(setPromoCode(val)),
    setSelectedCouponCode: (val) => dispatch(setSelectedCouponCode(val)),
    storeCourseIdAndType: (key, val) => dispatch(storeCourseIdAndType(key, val)),
    setStorePromoPrice: (val) => dispatch(setStorePromoPrice(val)),
  storeCartCountData: (val) => dispatch(storeCartCountData(val)),

});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCart)
