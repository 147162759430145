import React from "react";
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Select, Divider, Modal } from 'antd'
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { addMessageToGroup, setRightView, setRightViewType, updateUserList, updateUserCount, setCurrentPrivateChatReciever } from 'actions/broadcast'
import { GiftedChat, Composer, Send } from 'react-web-gifted-chat'
import { renderTime, renderBubble, renderInputToolbar } from "./giftedChatComponenets";
import { v4 as uuidv4 } from 'uuid';
import { Notification, Api } from "services";
import user from "pages/user/user";
import RaiseHandList from "./raiseHandList";
import { onResetChatBadge, postChat, postChatImage, updateChat } from "actions/liveClass";

const UserCard = ({ userId, profilepic, name, joinedBefore, isMuted, imageUrl, user,
    setRightViewType, setCurrentPrivateChatReciever, sessionuser, statustoshow }) => {
    return (
        <div className="m-b-5" style={{ height: '10%', width: "100%", display: 'flex', flexDirection: 'row' }}>
            <div className="r-c-sb" style={{ width: "85%", height: "100% !important" }}>
                <div className="r-c-c" style={{ marginRight: "5%" }}>
                    <img style={{ borderRadius: "50%", height: 40, width: 40 }} src={`${Api._s3_url}${imageUrl}`} alt=""></img>
                </div>
                <div style={{ width: "100%" }}>
                    <div className="white rfbc0p8 bold">
                        {name}
                    </div>

                    <div className="white rfbc0p8 opacity50" style={{ whiteSpace: 'nowrap' }}>
                        {user.id == sessionuser.id ? "(You) " : ""}{user.access?.includes("schedule-teacher") ? "Teacher" : ""}
                        {/* {`Joined ${joinedBefore} mins ago`}z */}
                    </div>
                </div>
            </div>
            <div style={{ width: "15%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                    style={{
                        height: '0.8vw', width: '0.8vw', borderRadius: "50%",
                        backgroundColor: statustoshow == "online" ? "#32cd32" : "#E83100",
                        marginRight: "20%"
                    }}>
                </div>
            </div>
        </div>
    )
}

const UsersList = ({ usersList, setRightViewType, setCurrentPrivateChatReciever, sessionuser, setRightView }) => {
    return (
        <>
            {usersList && usersList.length > 0
                ?
                <div style={{ height: "95%", overflowY: 'auto' }} className="m-t-20">
                    {usersList?.map((userarr, index) => (

                        <UserCard key={index} userId={userarr.id} name={userarr.name}
                            joinedBefore={'1'} isMuted={true} imageUrl={userarr.image}
                            user={userarr} setRightViewType={setRightViewType}
                            setCurrentPrivateChatReciever={setCurrentPrivateChatReciever}
                            sessionuser={sessionuser} statustoshow={userarr.statustoshow}
                        />))}
                </div>
                :
                null
            }
        </>
    )
}


export const NodataCard = ({ type }) => {
    return (
        <div style={{ height: "85%" }} className="r-c-c-c p-30">
            <div style={{ height: "40%", width: "80%" }}>
                <img className="full-height full-width" src={require('./assets/Group 11949.svg').default} alt=""></img>
            </div>
            {
                type == "Recordings"
                    ?
                    <div>
                        No recordings found !
                    </div>
                    :
                type == 'HandRaise'
                    ?
                    <div className="white text-center m-t-10">
                        No raised hands!
                    </div>
                    :
                    <div>
                        <div className="white text-center">
                            {`Start a ${type} chat now!`}
                        </div>
                        <div className="white opacity50 text-center">
                            {
                                type == "Private"
                                    ?
                                    "Chat Privately with any participant"
                                    :
                                    "Communicate with all the participants."
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

const placeholder = 'Type something here …'


function PreviewModalGroup({ 
    isModalVisible,
    handleCancel,
    handleOk,
    image
}) {
    return (

        <Modal wrapClassName="imagePreview" centered={true} okText={"Send"} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ width: "fit-content", height: "fit-content" }}><img style={{ width: "100%", height: "100%" }} src={`${image}`} alt="" /></div>
        </Modal>
    );
}
const GroupChatView = ({ chat,
    inc,
    setInc,
    addMessageToGroup,
    groupMessages,
    sessionuser,
    roomId,
    setRightView,
    setRightViewType,
    streamId,
    chatClient,
    onGroupChatSend,
    handleImageUploadGroup,
    onResetChatBadge
}) => {
    const [fileInputValue, setfileInputValue] = useState("")
    const [imageUrl, setImageUrl] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    }
    useEffect(() => {
        onResetChatBadge();
    }, [groupMessages]);
    const inputFile = useRef(null);
    const onChangeFile = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file != undefined) {
            const imgUrl = URL.createObjectURL(file);
            setfileInputValue(file)
            setImageUrl(imgUrl)
            showModal();
        }
    }
    const onImageUploadClick = () => {
        inputFile.current.click();
    };

    const user = {
        id: sessionuser.id,
        name: sessionuser.name,
        avatar: `${Api._s3_url}${sessionuser.image}`,
    }

    const imageBeforeUpload = file => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
        const reader = new FileReader();
        if (!isJpgOrPng) {
            Notification.error("error", "You can only upload JPG/PNG file!");
        } else if (!isLt2M) {
            Notification.error("error", "Image must smaller than 2MB!");
        } else {
            reader.addEventListener("load", () => {
                handleImageUploadGroup(file);
            });
            reader.readAsDataURL(file);
        }
        return false;
        // return isJpgOrPng && isLt2M;
    };
    const handleOk = () => {
        setIsModalVisible(false);
        inputFile.current.value = ""
        imageBeforeUpload(fileInputValue);
    };

    const handleCancel = () => {
        inputFile.current.value = ""
        setIsModalVisible(false);
    };

    const renderSend = (props) => (
        <div className="r-c-c" style={{ height: 60, position: 'absolute', right: 5, bottom: -50 }}>
            <div>
                <label htmlFor="file-input-image">
                    <input type='file' id='file' ref={inputFile}
                        onChange={(e) => { onChangeFile(e) }}
                        style={{ display: 'none' }} accept='image/*' />
                    <img className="m-r-10 cursor-pointer"
                        style={{ width: 25, height: 25 }}
                        onClick={onImageUploadClick}
                        src={require('./assets/Group 11771.svg').default} />
                </label>
            </div>
            <Send {...props} containerStyle={{
                border: 'none'
            }} onClick={() => { }}>
                <img style={{ width: 25, height: 25 }} onClick={() => { }}
                    src={require('./assets/Group 11770.svg').default} />
            </Send>
            <PreviewModalGroup
                image={imageUrl}
                isModalVisible={isModalVisible}
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
    return (
        <>
            <div className="absolute" style={{ height: "90%", bottom: 10, width: "94%" }}>
                {
                    groupMessages && groupMessages.length > 0
                        ?
                        <GiftedChat
                            renderChatEmpty={<></>}
                            onLongPress={() => { }}
                            placeholder={placeholder}
                            selectable={true}
                            renderInputToolbar={renderInputToolbar}
                            renderBubble={renderBubble}
                            showUserAvatar={true}
                            renderTime={renderTime}
                            // renderUsernameOnMessage={!studentChat}
                            // loadEarlier={!disableLoadEarlier}
                            onSend={onGroupChatSend}
                            messages={groupMessages?.slice().reverse()}
                            renderComposer={props1 => (<Composer {...props1}
                                textInputProps={{ blurOnSubmit: false }}
                                textInputStyle={{
                                    position: 'absolute', bottom: -38, left: -8, color: "grey",
                                    width: '80%', height: 'fit-content', border: 'none',
                                    backgroundColor: '#3e3e3e'
                                }} />)}
                            user={user}
                            minComposerHeight={40}
                            renderUsernameOnMessage={true}
                            minInputToolbarHeight={60}
                            renderSend={renderSend}
                        />
                        :
                        <>
                            <NodataCard type="Group" />
                            <div style={{ height: "15%" }}>
                                <GiftedChat
                                    renderChatEmpty={<></>}
                                    onLongPress={() => { }}
                                    placeholder={placeholder}
                                    selectable={true}
                                    renderInputToolbar={renderInputToolbar}
                                    renderBubble={renderBubble}
                                    showUserAvatar={true}
                                    renderTime={renderTime}
                                    // renderUsernameOnMessage={!studentChat}
                                    // loadEarlier={!disableLoadEarlier}
                                    messages={[]}
                                    onSend={onGroupChatSend}
                                    renderComposer={props1 => (<Composer {...props1}
                                        textInputProps={{ blurOnSubmit: false }}
                                        textInputStyle={{
                                            position: 'absolute', bottom: -38, left: -8, color: "grey",
                                            width: '80%', height: 'fit-content', border: 'none',
                                            backgroundColor: '#3e3e3e'
                                        }} />)}
                                    user={user}
                                    minComposerHeight={40}
                                    minInputToolbarHeight={60}
                                    renderSend={renderSend}
                                />
                            </div>
                        </>
                }

            </div>
        </>
    )
}
const RightColumn = ({ rightViewType,
    setRightViewType,
    inc,
    setInc,
    addMessageToGroup,
    groupMessages,
    postChatImage,
    sessionuser,
    roomId,
    updateUserList,
    updateUserCount,
    chat,
    setCurrentPrivateChatReciever,
    currentPrivateChatReciever,
    privateMessages,
    setRightView,
    streamId,
    chatClient,
    onlineUsers,
    offlineUsers,
    roomName,
    updateChat,
    postChat,
    chats,
    onResetChatBadge
}) => {

    const usersList = onlineUsers.map(item=>({...item, statustoshow:"online"})).concat(offlineUsers);
    useEffect(() => {
        // chat.initsocket();


    }, []);
    
    useEffect(() => {

    }, [chats]);
    const onGroupChatSend = (messages) => {
        if (messages[0].text == "") {
            Notification.hide()
            Notification.error("error", "Please enter a message")
        }else{
            messages[0]._id = messages[0].id
            updateChat(messages[0]);
            const params = {
                userId: sessionuser.id,
                message: messages[0].text ,
                chatType: 'Group',
                roomName,
                messageType: 'Text',
            };
            postChat(params, messages[0]);
        }
    }
    const handleImageUploadGroup = (file) => {

        const params = {
            file,
            userId: sessionuser.id,
            roomName,
            messageType: 'Image',
            chatType: 'Group',
        };
        postChatImage(params, sessionuser);
    }
    return (
        <div className="greyBackground full-height full-width p-10 relative rightColumnBC">
            {rightViewType == 'participantsView'
                ?
                <UsersList usersList={usersList} setRightViewType={setRightViewType}
                    setCurrentPrivateChatReciever={setCurrentPrivateChatReciever}
                    sessionuser={sessionuser} setRightView={setRightView} />
                :
                rightViewType == 'raiseHandList'?
                <RaiseHandList/>
                :
                        rightViewType == 'groupChatView'
                            ?
                            <GroupChatView setRightViewType={setRightViewType}
                                addMessageToGroup={addMessageToGroup}
                                chat={chat}
                                inc={inc}
                                setInc={setInc}
                                groupMessages={chats}
                                sessionuser={sessionuser}
                                roomId={roomId}
                                setRightView={setRightView}
                                streamId={streamId}
                                chatClient={chatClient}
                                onGroupChatSend={onGroupChatSend}
                                handleImageUploadGroup={handleImageUploadGroup}
                                onResetChatBadge={onResetChatBadge}
                            />
                            :
                            null
            }
        </div>
    )
}

const mapStateToProps = state => {
    const { rightViewType,
        roomId,
        currentPrivateChatReciever,
        privateMessages, streamId } = state.broadcast;
    const {onlineUsers, offlineUsers, roomName, chats} = state.liveClass;
    const { user } = state.session;
    const sessionuser = user;
    return {
        rightViewType,
        sessionuser,
        roomId,
        currentPrivateChatReciever,
        privateMessages,
        streamId,
        onlineUsers,
        offlineUsers,
        roomName,
        chats
    }
}

const mapDispatchToProps = dispatch => ({
    setRightViewType: (viewType) => dispatch(setRightViewType(viewType)),
    addMessageToGroup: (newMessage) => dispatch(addMessageToGroup(newMessage)),
    postChatImage: (params, user) => dispatch(postChatImage(params, user)),
    setCurrentPrivateChatReciever: (newUser) => dispatch(setCurrentPrivateChatReciever(newUser)),
    setRightView: (bool) => dispatch(setRightView(bool)),
    updateChat: message => dispatch(updateChat(message)),
    postChat: (payload, message) => dispatch(postChat(payload, message)),
    onResetChatBadge: () => dispatch(onResetChatBadge())
})

export default connect(mapStateToProps, mapDispatchToProps)(RightColumn)