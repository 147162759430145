import React, { useState } from "react";
import { Modal, Button, Avatar, Typography } from "antd";
import { connect } from "react-redux";
import {
  deleteCourseWithId,
  getStudentsDetailsById,
} from "actions/userManagement";
import {
  cancelTransaction,
  deleteTransaction,
  fetchTransaction,
  getFeeOverView,
  sendReminder,
} from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";
import { Api } from "services";
import moment from "moment";
import image1 from "../../Assets/care/no-subjects.svg";

function SendReminderModal({
  isModalVisible,
  setShowHistory,
  showHistory,
  userApiCall,
  setShowPayRequestModal,
  setShowConfirmationModal,
  setShowSendReminder,
  fetchTransaction,
  cancelTransaction,
  deleteApiCall,
  deleteTransaction,
  getFeeOverView,
  setPageLoader,
  sendReminder,
  item,
  getStudentsDetailsById,
  getTransactionData,startDate, endDate, week, isYear

  // setPageLoader,
}) {
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  return (
    <div>
      {console.log(item,"itemmmmmmmmm")}
      <Modal
        maskClosable={false}
        className="reminderModal"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{ loading: btnLoader }}
        onOk={() => {
          //   setDeleteBtnLoader(true);
          setBtnLoader(true);
          sendReminder(
            () => {
              setBtnLoader(false);
            },
            () => {
              setShowSendReminder(false);
              setShowHistory(false);
              setPageLoader(true);

              if (userApiCall) {
                getStudentsDetailsById(() => {
                  setPageLoader(false);
                });
              } else {
                getFeeOverView(() => {
                  setPageLoader(false);
                });
              }
            },item?.id
          );
          // setShowSendReminder(false);
          // setShowHistory(false);
        }}
        onCancel={() => {
          setShowHistory(false);
          setShowSendReminder(false);
          //   deleteTransaction();
          //   setViewCourseDeleteConfirmModal(false);
        }}
      >
        {showHistory == false ? (
          <div>
            <h2
              style={{
                fontFamily: "Roboto",
                textAlign: "center",
                color: "#594099",
                marginBottom: "0px",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              Confirm
            </h2>
            <p
              style={{
                fontFamily: "Roboto",
                textAlign: "center",
                color: "#636363",
              }}
            >
              Are you sure you want to send reminder?
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <Button
                  onClick={() => {
                    setShowSendReminder(false);
                    setShowHistory(false);
                  }}
                  style={{
                    marginTop: "20px",
                    borderRadius: "20px",
                    color: "#636363",
                    border: "1px solid #636363",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100px",
                    marginRight: "12px",
                  }}
                >
                  NO
                </Button>
                <Button
                  loading={btnLoader}
                  onClick={() => {
                    setBtnLoader(true);
                    sendReminder(
                      () => {
                        setBtnLoader(false);
                      },
                      () => {
                        setShowSendReminder(false);
                        getTransactionData(startDate, endDate, week, isYear ,()=>{},()=>{})
                        setShowHistory(false);
                        setPageLoader(true);
                        if (userApiCall) {
                          getStudentsDetailsById(() => {
                            setPageLoader(false);
                          });
                        } else {
                          getFeeOverView(() => {
                            setPageLoader(false);
                          });
                        }
                      },item?.id
                    );
                  }}
                  style={{
                    marginTop: "20px",
                    borderRadius: "20px",
                    color: "white",
                    border: "1px solid #636363",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100px",
                    marginLeft: "12px",
                    backgroundColor: "#594099",
                  }}
                >
                  YES
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "600",
                fontSize: "18px",
                marginBottom: "25px",
              }}
            >
              Reminder History
            </div>
            {console.log("AA-item?.feeReminders",item)}
            <div style={{ height: "350px", overflow: "auto" }}>
              {item?.feeReminders?.length != 0 ? (
                item?.feeReminders?.map((item) => (
                  <div
                    className="r-ac"
                    style={{
                      fontFamily: "roboto",
                      width: "400px",
                      border: "1px solid #E6E6E6",
                      borderRadius: "10px",
                      padding: "10px",
                      margin: "10px",
                    }}
                  >
                    <div>
                      <Avatar
                        style={{ width: "50px", height: "50px" }}
                        src={Api._s3_url + item?.reminderImage}
                      />
                    </div>
                    <div style={{ marginLeft: "40px" }}>
                      {/* <div style={{ fontWeight: "550" }}>
                                  {item?.user?.name}
                                </div> */}
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                          color: "#AEAEAE",
                          width: "300px",
                          fontWeight: "550",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          {item?.date
                            ? moment(item?.date).format("DD/MM/YYYY, h:mm a")
                            : null}{" "}
                        </span>
                        . {item?.date ? moment(item?.date).fromNow() : null}
                      </Typography.Text>
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                          color: "#AEAEAE",
                          width: "300px",
                          fontWeight: "500",
                        }}
                      >
                        <span style={{ color: "#636363" }}>
                          {item?.reminderName}
                        </span>{" "}
                        , {item?.reminderPrivilege}
                      </Typography.Text>
                      <div style={{ color: "#636363" }}></div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "40%",
                      height: "40%",
                      marginBottom: "20px",
                    }}
                    src={image1}
                    alt=""
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "500", fontSize: "18px" }}>
                      No data!
                    </h1>
                    <p
                      style={{
                        color: "#636363",
                        width: "60%",
                        textAlign: "center",
                      }}
                    >
                      There are no data to show yet. Please try later.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <Button
              onClick={() => {
                setShowSendReminder(false);
                setShowHistory(false);
              }}
              style={{
                marginTop: "20px",
                borderRadius: "20px",
                color: "#636363",
                border: "1px solid #636363",
                fontSize: "14px",
                fontWeight: "600",
                width: "100px",
              }}
            >
              CLOSE
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCourseWithId: (callback, successCallBack) =>
    dispatch(deleteCourseWithId(callback, successCallBack)),
  getStudentsDetailsById: (callback) =>
    dispatch(getStudentsDetailsById(callback)),
  cancelTransaction: (callback, successCallback) =>
    dispatch(cancelTransaction(callback, successCallback)),
  fetchTransaction: () => dispatch(fetchTransaction()),
  deleteTransaction: (callback, successCallBack) =>
    dispatch(deleteTransaction(callback, successCallBack)),
  getFeeOverView: (callback, successCallBack) =>
    dispatch(getFeeOverView(callback, successCallBack)),
  sendReminder: (callback, successCallBack,transId) =>
    dispatch(sendReminder(callback, successCallBack,transId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendReminderModal);
