import { Checkbox, Switch, Tag } from "antd";
import AnimatedDatePicker from "components/AnimatedDatePicker";
import AnimatedCustomSelect from "components/AnimatedSelectComp";
import moment from "moment";
import React, { useEffect, useState } from "react";
function CreateOfferApplicable({ offersObj,
    setOffersObjKey,
    inputStyle,
    cardClassName,
    storeAutocompleteSearchData,
    getAutocompleteSearchOffers,
    getCoursesCategories,
    getCoursesSubCategories,
    courseCategoryData,
    courseSubCategoriesData,
    autocompleteSearchData,
    selectedCategories,
    setSelectedCategories,
    selectedSubcategories,
    setSelectedSubcategories,
    selectedCourse,
    setSelectedCourse,
    setOffersObj,
    courseDetails,
    hasOfferDate,
    setHasOfferDate,
    setFormHasOfferDate,
}) {
    const [categoryListToShow, setCategoryListToShow] = useState([]);
    const [subCategoryListToShow, setSubCategoryListToShow] = useState([]);
    const [searchName, setSearchName] = useState(null);
    const offerTypesArray = [
        { id: "course", value: "Course type" },
        { id: "category", value: "Course category" },
        { id: "subcategory", value: "Course subcategory" },
        { id: "singleCourse", value: "A course" },
        { id: "All", value: 'All' }
    ];
    const getOffersValue = (type) => {
        if (type == 'liveCourse' || type == 'videoResource' || type == 'studyMaterial' || type == 'testSeries') {
            return 'A course'
        } else {
            return offerTypesArray.find((item) => item.id == type)?.value || null;
        }
    }
    function checkApplicableAll(live, video, study, testSeries) {
        if (live && video && study && testSeries) {
            setOffersObjKey('type', 'All');
        }
    }
    const getCategoryOptions = () => {
        let options = categoryListToShow;
        if (selectedCategories?.length) {
            options = options.filter((item) => !selectedCategories.some((ele) => ele.id == item.id));
        }
        if (searchName) {
            options = options.filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase()))
        }
        return options;
    }
    useEffect(() => {
        storeAutocompleteSearchData([])
        if (offersObj?.type == "category") {
            getCoursesCategories(() => { updateCategoriesList() })
        } else if (offersObj?.type == "subcategory") {
            getCoursesSubCategories(null, () => { updateSubcategoriesList() })
        } else if (offersObj?.type == "singleCourse") {
            getAutocompleteSearchOffers(null, () => { updateSubcategoriesList() }, true)
        }
    }, [offersObj?.type])

    const updateCategoriesList = () => {
        setCategoryListToShow(courseCategoryData?.map(item => Object.assign(item, { key: item.id, label: item.name, value: item.id })))
    }

    const updateSubcategoriesList = () => {
        setSubCategoryListToShow(courseSubCategoriesData?.map(item => Object.assign(item, { key: item.id, label: item.name, value: item.id })))
    }

    const handleCategorySelect = (value, obj) => {
        if (!selectedCategories?.some((item) => item.id == obj.id)) {
            setSelectedCategories((selectedCategories) => [...selectedCategories, obj]);
            setOffersObjKey('categoryIds', offersObj?.categoryIds?.length ? [...offersObj.categoryIds, obj.id] : [obj.id]);
        }
        setSearchName('');
    }

    const handleSubcategorySelect = (value, obj) => {
        if (!selectedSubcategories?.some((item) => item.id == obj.id)) {
            setSelectedSubcategories((selectedSubcategories) => [...selectedSubcategories, obj]);
            setOffersObjKey('subcategoryIds', offersObj?.subcategoryIds?.length ? [...offersObj.subcategoryIds, obj.id] : [obj.id]);
        }
        setSearchName('');
    }

    const disabledStartDate = (current) => {
        const today = moment();
        return current && current < today.startOf('day');
    };
    const disabledEndDate = (current) => {
        const today = moment().format('YYYY-MM-DD');
        const currentDate = current.format('YYYY-MM-DD');
        return current && (currentDate < today || (offersObj?.startDate && currentDate < moment(offersObj.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')));
    };

    const handleCourseAutoCompleteSearch = (value) => {
        getAutocompleteSearchOffers(value);
    }
    const handleCourseSelect = (value, obj) => {
        if (!selectedCourse?.map(item => item.id)?.includes(obj.key - 1)) {
            const newCourse = autocompleteSearchData?.[obj.key - 1];
            if (newCourse) {
                setSelectedCourse([{
                    id: newCourse.id - 1,
                    name: newCourse.name,
                    itemId: newCourse.itemId,
                    itemType: newCourse.itemType,
                }]);
                const { videoResourceId, courseId, studyMaterialId, testSeriesId, ...others } = offersObj;
                const itemKey = `${newCourse.itemType}Id`;
                setOffersObj({ ...others, [itemKey]: (newCourse.itemId) })
            }
            setSearchName('')
        }
    }
    const handleSearchCategory = (query) => {
        if (query != null) {
            setSearchName(query);
        }
    }

    const handleSearchSubcategory = (query) => {
        if (query != null) {
            setSearchName(query);
        }
    }

    const handleCategoryTagClose = (id) => {
        setSelectedCategories((selectedCategories) => selectedCategories.filter((item) => item.id != id));
        setOffersObjKey('categoryIds', (offersObj?.categoryIds || []).filter((item) => item.id != id));
    }

    const handleSubcategoryTagClose = (id) => {
        setSelectedSubcategories((selectedSubcategories) => selectedSubcategories.filter((item) => item.id != id));
        setOffersObjKey('subcategoryIds', (offersObj?.subcategoryIds || []).filter((item) => item.id != id));
    }

    const getSubcategoryOptions = () => {
        let options = subCategoryListToShow;
        if (selectedSubcategories?.length) {
            options = options.filter((item) => !selectedSubcategories.some((ele) => ele.id == item.id));
        }
        if (searchName) {
            options = options.filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase()))
        }
        return options;
    }
    return (
        <div className={cardClassName} style={{ marginBottom: 10 }}>
            <div style={{ color: "#636363", fontSize: 18, fontWeight: 700 }}>Offer applicability</div>
            <div className='offer-applicable-selector'>
                <AnimatedCustomSelect
                    label={"Promotional Offer applicable for"}
                    required={true}
                    options={offerTypesArray}
                    value={courseDetails ? 'singleCourse' : getOffersValue(offersObj?.type)}
                    disabled={!!courseDetails}
                    isSelected={getOffersValue(offersObj?.type) ? true : false}
                    handleSelectChange={(value) => {
                        setOffersObjKey("type", value);
                        if (value == 'course') {
                            setOffersObjKey("applicableLive", false);
                            setOffersObjKey("applicableVideo", false);
                            setOffersObjKey("applicableStudy", false);
                            setOffersObjKey("applicableTest", false);
                        }
                    }}
                    style={{ marginTop: 10, cursor: 'pointer !important' }}
                />
            </div>
            {offersObj?.type === 'course'
                ? <div className='r-c-fs' style={{ gap: '3rem' }}>
                    <div className='r-c-c' style={{ gap: '1rem' }}>
                        <Checkbox
                            checked={offersObj?.type == 'course' && offersObj?.applicableLive}
                            //style={{ marginRight: 10 }}
                            onChange={(e) => {
                                setOffersObjKey('applicableLive', !offersObj?.applicableLive);
                                checkApplicableAll(!offersObj?.applicableLive, offersObj?.applicableVideo, offersObj?.applicableStudy, offersObj?.applicableTest);
                            }} />
                        <span style={{ color: "#636363" }}>Live courses</span>
                    </div>
                    <div className='r-c-c' style={{ gap: '1rem' }}>
                        <Checkbox
                            checked={offersObj?.type == 'course' && offersObj?.applicableVideo}
                            //style={{ marginRight: 10 }}
                            onChange={(e) => {
                                setOffersObjKey('applicableVideo', !offersObj?.applicableVideo);
                                checkApplicableAll(offersObj?.applicableLive, !offersObj?.applicableVideo, offersObj?.applicableStudy, offersObj?.applicableTest);
                            }} />
                        <span style={{ color: "#636363" }}>Video courses</span>
                    </div>
                    <div className='r-c-c' style={{ gap: '1rem' }}>
                        <Checkbox
                            checked={offersObj?.type == 'course' && offersObj?.applicableTest}
                            //style={{ marginRight: 10 }}
                            onChange={(e) => {
                                setOffersObjKey('applicableTest', !offersObj?.applicableTest);
                                checkApplicableAll(offersObj?.applicableLive, offersObj?.applicableVideo, offersObj?.applicableStudy, !offersObj?.applicableTest);
                            }} />
                        <span style={{ color: "#636363" }}>Online test series</span>
                    </div>
                    <div className='r-c-c' style={{ gap: '1rem' }}>
                        <Checkbox
                            checked={offersObj?.type == 'course' && offersObj?.applicableStudy}
                            //style={{ marginRight: 10 }}
                            onChange={(e) => {
                                setOffersObjKey('applicableStudy', !offersObj?.applicableStudy);
                                checkApplicableAll(offersObj?.applicableLive, offersObj?.applicableVideo, !offersObj?.applicableStudy, offersObj?.applicableTest);
                            }} />
                        <span style={{ color: "#636363" }}>Study materials</span>
                    </div>
                </div>
                : null
            }
            {offersObj?.type === 'category'
                ? <div>
                    <AnimatedCustomSelect
                        label={"Select course categories"}
                        required={true}
                        options={getCategoryOptions()}
                        value={searchName}
                        isSelected={searchName ? true : false}
                        handleSelectChange={e => { setSearchName(e) }}
                        handleSelectOnSelect={(value, obj) => handleCategorySelect(value, obj)}
                        handleSelectOnSearch={(query) => { setSearchName(query); handleSearchCategory(query) }}
                        showSearch={true}
                        allowClear={searchName ? true : false}
                        onClear={() => setSearchName('')}
                        style={{ marginBottom: 0 }}
                        autoFocus={true}
                    />
                    <div className="flex-wrap m-t-10">
                        {selectedCategories?.map((category) =>
                            <Tag
                                key={category.id}
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#191919',
                                    padding: "5px 10px",
                                    borderRadius: 20,
                                    border: "1px solid #636363",
                                    fontSize: 14,
                                    marginBottom: 10,
                                }}
                                closable
                                onClose={() => handleCategoryTagClose(category.id)}
                            >
                                {category?.value}
                            </Tag>
                        )}
                    </div>
                </div>
                : null
            }
            {offersObj?.type === 'subcategory'
                ? <div>
                    <AnimatedCustomSelect
                        label={"Select course subcategories"}
                        required={true}
                        options={getSubcategoryOptions()}
                        value={searchName}
                        isSelected={searchName ? true : false}
                        handleSelectChange={e => { setSearchName(e) }}
                        handleSelectOnSelect={(value, obj) => handleSubcategorySelect(value, obj)}
                        handleSelectOnSearch={(query) => { setSearchName(query); handleSearchSubcategory(query) }}
                        showSearch={true}
                        allowClear={searchName ? true : false}
                        onClear={() => setSearchName('')}
                        style={{ marginBottom: 0 }}
                        autoFocus={true}
                    />
                    <div className="flex-wrap m-t-10">
                        {selectedSubcategories?.map((category) =>
                            <Tag
                                key={category.id}
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#191919',
                                    padding: "5px 10px",
                                    borderRadius: 20,
                                    border: "1px solid #636363",
                                    fontSize: 14,
                                    marginBottom: 10,
                                }}
                                closable
                                onClose={() => handleSubcategoryTagClose(category.id)}
                            >
                                {category?.value}
                            </Tag>
                        )}
                    </div>
                </div>
                : null
            }
            {courseDetails || offersObj?.type === 'singleCourse' || offersObj?.type == 'liveCourse' || offersObj?.type == 'videoResource' || offersObj?.type == 'studyMaterial' || offersObj?.type == 'testSeries'
                ? <div>
                    <AnimatedCustomSelect
                        label={"Select course"}
                        required={true}
                        options={autocompleteSearchData}
                        value={courseDetails ? courseDetails?.name : (searchName || selectedCourse?.[0]?.name || '')}
                        isSelected={searchName ? true : false}
                        handleSelectChange={e => { setSearchName(e) }}
                        onClear={() => { setSearchName(''); setSelectedCourse([]) }}
                        disabled={!!courseDetails}
                        handleSelectOnSelect={(value, option) => handleCourseSelect(value, option)}
                        handleSelectOnSearch={(query) => { setSearchName(query); handleCourseAutoCompleteSearch(query); }}
                        showSearch={true}
                        allowClear={searchName || selectedCourse?.[0]?.name ? true : false}
                        style={{ marginBottom: 0 }}
                    />
                </div>
                : null
            }
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                <div style={{ fontSize: 14, color: "#636363" }}>Valid through</div>
                <Switch checked={hasOfferDate} onChange={() => {
                    setHasOfferDate((item) => {
                        if (item) {
                            setOffersObjKey('startDate', null);
                            setOffersObjKey('endDate', null);
                        }
                        if (setFormHasOfferDate) {
                            setFormHasOfferDate(!item);
                        }
                        return !item;
                    });
                }} />
            </div>
            {hasOfferDate
                ? <div className='r-c-fs' style={{ gap: "2rem", marginTop: 10, }}>
                    <div style={{ width: "50%" }}>
                        <AnimatedDatePicker
                            bordered={null}
                            allowClear={true}
                            placeholder={"Select start date"}
                            label="Start date"
                            isMandatory={true}
                            value={offersObj?.startDate ? moment(offersObj?.startDate, 'DD/MM/YYYY') : null}
                            format={'DD/MM/YYYY'}
                            disabledDate={disabledStartDate}
                            handleDateChange={(date, dateString) => { setOffersObjKey('startDate', dateString); setOffersObjKey('endDate', null); }}
                            inputId={"-999"}
                        />
                    </div>
                    <div style={{ width: "50%" }}>
                        <AnimatedDatePicker
                            label="End date"
                            allowClear={true}
                            isMandatory={true}
                            style={{ marginLeft: "-10px" }}
                            value={offersObj?.endDate ? moment(offersObj?.endDate, 'DD/MM/YYYY') : null}
                            format={'DD/MM/YYYY'}
                            disabledDate={disabledEndDate}
                            handleDateChange={(date, dateString) => { setOffersObjKey('endDate', dateString) }}
                            inputId={"-998"}
                        />
                    </div>
                </div>
                : null}
        </div>
    );
}
export default CreateOfferApplicable;