import { transferStandard } from 'actions/classRoom';
import { Checkbox, Input, Modal, Select } from 'antd';
import AnimatedCustomSelect from 'components/AnimatedSelectComp';
import AnimatedTextField from 'components/AnimatedTextField';
import { Button } from 'components/Button';
import { ModalSubTitle } from 'components/Typography';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Option } = Select

function TransferClassModal({ isTransferClassVisible, setIsTransferModalVisible, classes, className, sectionName, selectedClassId, handleTransferSection, transferStandard, fromCourseDetails, isFromCourseDetailsReducer }) {
  const [transferClassObj, setTransferClassObj] = useState({
    selectedStd: selectedClassId,
    targetStd: null,
    targetSection: null,
    keepSubjects: false
  });
  const [transferBtnLoader, setTransferBtnLoader] = useState(false);
  const history = useHistory()
  const handleCancel = () => {
    setIsTransferModalVisible(false)
  }
  const classKeysArray = Object.keys(classes)?.map((key) => ({ name: key }));
const [sectionModal,setSectionModal]= useState(false)
  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={isTransferClassVisible}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}>
        <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Transfer section"}</div>
        <div style={{ fontSize: "16px", color: "#636363", fontFamily: "roboto", marginTop: "10px", textAlign: 'center' }}>
          Transfer all the students of <span style={{ fontWeight: "bold" }}>{`${className}`} : {`${sectionName}`}</span> to a new section
        </div>
        <div style={{ padding: 20 }}>
          <AnimatedCustomSelect
            label={"Target classroom"}
            required={true}
            options={classKeysArray}
            handleSelectChange={(value) => setTransferClassObj({ ...transferClassObj, targetStd: value })}
            value={transferClassObj?.targetStd}
            isSelected={transferClassObj?.targetStd ? true : false} 
            />
          {/* <ModalSubTitle>Target classroom <span style={{ color: "red" }}> *</span></ModalSubTitle>
          <Select
            placeholder="Select a classroom"
            style={{ width: "100%", marginBottom: 10 }}
            value={transferClassObj?.targetStd}
            onChange={(value) => setTransferClassObj({ ...transferClassObj, targetStd: value })}>
            {Object.keys(classes).map(item =>
              <Option value={item}>{item}</Option>
            )}
          </Select> */}
          <AnimatedTextField
            label={"Section"}
            isMandatory={true}
            inputId={"-1000"}
            value={transferClassObj?.targetSection}
          
            handleInputChange={(e) => {
              if (e.target.value.length > 3) {
                return e.preventDefault();

              }
              setTransferClassObj({ ...transferClassObj, targetSection: e.target.value })
            }}
            sectionModal={sectionModal}
          />
          <Checkbox style={{ color: "#191919", fontFamily: "roboto", marginTop: 10 }} onChange={(e) => setTransferClassObj({ ...transferClassObj, keepSubjects: e.target.checked })}>Keep the subject(s)</Checkbox>
        </div>
        <div className='r-c-c m-t-10 m-b-10'>
          <Button
            type="primary"
            loading={transferBtnLoader}
            disabled={!transferClassObj?.targetStd || !transferClassObj?.targetSection}
            onClick={() => {
              setTransferBtnLoader(true);
              transferStandard(
                transferClassObj,
                () => setTransferBtnLoader(false),
                () => {
                  setIsTransferModalVisible(false);
                  if (fromCourseDetails || isFromCourseDetailsReducer) {
                    history.push({ pathname: "/course-details", state: { key: "openBatches" } })
                  } else {
                    history.push("/classroom");
                  }
                  handleTransferSection(transferClassObj?.selectedStd)
                })
            }}>
            TRANSFER
          </Button>
        </div>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => {
  const { classes } = state.classRoom;
  const { isFromCourseDetailsReducer } = state.courseManagement;
  return {
    classes, isFromCourseDetailsReducer
  };
};

const mapDispatchToProps = dispatch => ({
  transferStandard: (params, callBack, successCallBack) => dispatch(transferStandard(params, callBack, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferClassModal)
// export default TransferClassModal