import PageHeader from 'components/pageHeader';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Heading } from 'components/Typography';
// import Carousel from 'react-multi-carousel';
import OverViewCard from 'components/overViewCard';
import { setTab, storeSingleCourseId, setCourseTypeName, } from 'actions/courseManagement';
import { getCrmDashboardData, getCrmDashboardLeads, getCrmDashboardConversions, getCourseImpressionGraph, getLeadsConversionsGraph, setCrmConversionsData, setCrmLeadsData, setCrmDashboardData, } from 'actions/marketPlaceCRM';
import { connect } from 'react-redux';
import PieCharts from 'components/pieCharts';
import { Dropdown, DatePicker, Menu, Tooltip, Typography, Progress, AutoComplete, Space, Carousel } from 'antd';
const { RangePicker } = DatePicker;
import { DownOutlined, FilterFilled, FullscreenOutlined, SearchOutlined } from '@ant-design/icons';
import SwitchComponent from 'components/switchComponent'
import GroupedBarPlot from 'components/groupedBarPlot'
import moment from 'moment'
import SearchAndFilters from 'components/searchAndFilters';
import studentIcon from "../../Assets/courseManagementV4/studentIcon.svg";
import FormattedDate from 'components/formatCreatedAt';
import UserAvatar from 'components/userAvatar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PageDataLoader from 'components/pageDataLoader';
import { NodataCard } from 'pages/Test';
import DemoDualAxes from 'components/dualAxisGraph';
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2';
import { setOthersSelectedTab } from 'actions/profileNew';
import { Button } from 'components/Button';
import CrmModalGraph from 'pages/MarketplaceCRM/components/crmGraphModal';
import CrmOverviewCard from 'pages/MarketplaceCRM/components/crmOverviewCard';
import LeadsFilterModal from 'pages/MarketplaceCRM/components/leadsFilterModal';
const imageSources = {
    visitors: require('../../Assets/crmOverview/Group 38872.png').default,
    download: require('../../Assets/crmOverview/Group 39589.png').default,
    enrollPurchase: require('../../Assets/crmOverview/Group 39582.png').default,
    leadsNotConverted: require('../../Assets/crmOverview/Group 39580.png').default,
    bookmark: require('../../Assets/crmOverview/Component 364 – 19.png').default,
    cart: require('../../Assets/crmOverview/cart.svg').default,
    enquiry: require('../../Assets/crmOverview/Component 363 – 25.png').default,
    liveCourse: require('../../Assets/crmOverview/ic-students (1).png').default,
    videoResourse: require('../../Assets/crmOverview/ic-students (2).png').default,
    testSeries: require('../../Assets/crmOverview/ic-students (3).png').default,
    studyMaterial: require('../../Assets/crmOverview/ic-students (4).png').default,
}




const OverViewCRM = ({ setCrmConversionsData, crmDashboardData, getCrmDashboardData, getCrmDashboardLeads, crmLeadsData, getCrmDashboardConversions, crmConversionsData, getCourseImpressionGraph, crmImpressionGraphDataData,
    setTab, setSpecificUserId, setUserDetails, setOthersSelectedTab, storeSingleCourseId, setCourseTypeName, crmLeadsConversiopnsGraph, getLeadsConversionsGraph, setCrmLeadsData, user }) => {
    const bodyStyle = {
        backgroundColor: "#FAFAFA",
        width: " calc(100% + 30px)",
        height: "calc(100% + 40px)",
        margin: "-20px",
        padding: "20px",
        // overflow: "auto"
    }

    const [pageLoader, togglePageLoader] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [isScrolled, setIsScrolled] = useState(false);
    const [isGraphFilterClicked, setIsGraphFilterClicked] = useState(false)
    const [isMonth, setIsMonth] = useState(moment().format('MMM'))
    const [isYear, setIsYear] = useState(moment().format('YYYY'))
    const [barChartModal, setBarChartModal] = useState(false)
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 10 });
    const [courseType, setCourseType] = useState('liveCourse')
    const [isChecked, setIsChecked] = useState(true);
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false)
    const [search, setSearch] = useState('');
    const [search1, setSearch1] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);
    const [page, setPage] = useState(1);
    const [page1, setPage1] = useState(1);
    const [isGraphFullScreen, setISGraphFullScreen] = useState(false)
    const [currentPie, setCurrentPie] = useState(1);
    const [isGraphLoader, setIsGraphLoader] = useState(false)
    const [requestsLoader, toggleReqsLoader] = useState(false);
    const [requestsLoader1, toggleReqsLoader1] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [leadsFilterClick, setLeadsFilterClick] = useState(false);


    const [filterParam, setFilterParam] = useState({
        type: ['All']
    });
    const [filterParamConversion, setFilterParamConversion] = useState({
        type: ['All']
    });
    const [currentPageCarousel, setCurrentPage] = useState(0);
    const [check, setCheck] = useState(false)
    const handleAfterChange = (current) => {
        setCurrentPage(current);
    };

    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');

    useEffect(() => {


        if (currentPageCarousel == 1) {
            setIsGraphFilterClicked(false)
        }
    }, [currentPageCarousel])

    // useEffect(() => {
    //     setPage(1)
    // }, [startDate, endDate, filterParam])

    const history = useHistory();

    const typeMapping = {
        Register: 'Registration',
        AddToCart: 'Add to cart',
        Bookmark: 'Bookmark',
        Enquiry: 'Enquiry',
    };
    const displayTypes = filterParam.type.map(type => typeMapping[type] || type);
    const allTypes = ['AddToCart', 'Bookmark', 'Register', 'Enquiry'];
    const showAll = allTypes.every(type => filterParam.type.includes(type));

    const typeMapping1 = {
        Courses: "Live course",
        VideoResources: "Video course",
        TestSeries: "Test series",
        StudyMaterials: "Study materials",
    };

    const displayTypes1 = filterParamConversion.type.map(type => typeMapping1[type] || type);
    const allTypes1 = ['Courses', 'VideoResources', 'TestSeries', 'StudyMaterials'];
    const showAll1 = allTypes1.every(type => filterParamConversion.type.includes(type));





    const colors = ['#F9E7A3', '#E9CC64', '#B59009', '#DAAB00',];
    const greyColor = ['#AEAEAE', '#AEAEAE', '#AEAEAE', '#AEAEAE']
    const Types = [{ label: "All", value: 'All' }, { label: "Registration", value: "Register" }, { label: "Enquiry", value: "Enquiry" }, { label: "Add to cart", value: "AddToCart" }, { label: "Bookmark", value: "Bookmark" }]
    const Types1 = [{ label: "All", value: 'All' }, { label: "Live course", value: "Courses" }, { label: "Video course", value: "VideoResources" }, { label: "Test series", value: "TestSeries" }, { label: "Study materials", value: "StudyMaterials" }]



    const graphFilterArray = [
        { value: "liveCourse", label: "Live course" },
        { value: "videoResource", label: "Video course" },
        { value: "studyMaterial", label: "Study material" },
        { value: "testSeries", label: "Online test series" },
    ]

    const graphMenu = (
        <Menu className='different-bgc-dropdown graphMenu-crm' >
            {graphFilterArray?.map((item) => (
                <div style={{ fontSize: 15, cursor: "pointer", backgroundColor: courseType == item?.value ? "#D6C8F5" : "#FFFFFF", color: courseType == item?.value ? "#191919" : "#636363", fontWeight: courseType == item?.value ? 600 : "normal", borderRadius: 4, marginTop: 5, padding: "2px 10px 2px 10px" }} onClick={() => { setCourseType(item?.value) }}>{item?.label}</div>
            ))}
        </Menu>
    )

    useEffect(() => {
        togglePageLoader(true);
        getCrmDashboardData(startDate, endDate, () => { togglePageLoader(false) })


    }, [startDate, endDate])




    useEffect(() => {
        setIsGraphLoader(true);
        const params = {
            courseType: courseType,
            period: isChecked ? 'year' : 'month',
            year: isYear,
            month: moment(isMonth, 'MMM').format('MMMM')
        }
        getCourseImpressionGraph(params, () => { setIsGraphLoader(false) })


    }, [courseType, isChecked, isMonth, isYear])

    useEffect(() => {
        setIsGraphLoader(true);
        const params = {
            // courseType: courseType,
            period: isChecked ? 'year' : 'month',
            year: isYear,
            month: moment(isMonth, 'MMM').format('MMMM')
        }
        getLeadsConversionsGraph(params, () => { setIsGraphLoader(false) })


    }, [isChecked, isMonth, isYear])

    const [dualAxesGraph, setDualAxesGraph] = useState([])

    useEffect(() => {
        if (!crmLeadsConversiopnsGraph) return;
        const temp = [];
        for (let i = 0; i < crmLeadsConversiopnsGraph?.length; i++) {
            let obj = {}
            if (isChecked) {
                obj['year'] = crmLeadsConversiopnsGraph[i]?.date

            } else {
                obj['year'] = moment(crmLeadsConversiopnsGraph[i]?.date, 'DD/MM/YYYY').format('DD')

            }
            obj['leads'] = crmLeadsConversiopnsGraph[i]?.leads
            obj['conversions'] = crmLeadsConversiopnsGraph[i]?.conversions

            temp.push(obj);

        }
        setDualAxesGraph(temp);
    }, [crmLeadsConversiopnsGraph]);

    useEffect(() => {

        if (page == 1) {
            setDataLoading(true)
            // setPage(1)
        }
        toggleReqsLoader(true)
        getCrmDashboardLeads(search, filterParam, page, (page == 1 ? true : false), () => { setDataLoading(false) }, () => { toggleReqsLoader(false) })


    }, [search, page])


    useEffect(() => {
        // togglePageLoader(true);
        if (page1 == 1) {
            setisLoading(true)
        }
        toggleReqsLoader1(true)
        getCrmDashboardConversions(search1, filterParamConversion, page1, (page1 == 1 ? true : false), () => { }, () => { setisLoading(false), toggleReqsLoader1(false) })

    }, [search1, page1])

    useEffect(() => {
        if (!crmImpressionGraphDataData) return;
        const temp = [];
        for (let i = 0; i < crmImpressionGraphDataData?.length; i++) {
            let obj = {}
            obj['value'] = crmImpressionGraphDataData[i]?.impressionCount,
                obj['label'] = crmImpressionGraphDataData[i]?.courseNames?.slice(0, 10)
            obj['type'] = 'Impression',
                temp.push(obj);
            let obj1 = {}
            obj1['value'] = crmImpressionGraphDataData[i]?.engagementCount,
                obj1['label'] = crmImpressionGraphDataData[i]?.courseNames?.slice(0, 10)
            obj1['type'] = 'Engagement',
                temp.push(obj1);
        }
        setGraphData(temp);
    }, [crmImpressionGraphDataData]);
    const [isCheck, setIsCheck] = useState(null)


    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                const scrollTop = div.scrollTop;
                console.log({ scrollTop });
                const scrollHeight = div.scrollHeight;
                const clientHeight = div.clientHeight;

                console.log({ scrollTop });
                if (scrollTop > 0 && scrollHeight > clientHeight) {
                    console.log('scrollTop12', scrollTop);
                    setIsScrolled(true);


                } else if (scrollTop == 0) {

                    setIsScrolled(false);
                    setIsCheck('scrollup')
                }

            }
        };
        const div = document.getElementById('scrollableDiv');
        if (div) {
            div.addEventListener('scroll', handleScroll);
        }
        return () => {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                div.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const div = document.getElementById('scrollableDiv');
        if (isScrolled) {
            setTimeout(() => {
                div.scrollTop = 1
            }, 900)
        }
    }, [isScrolled]);

    const courseData = [
        {
            type: 'Bookmark',
            value: crmDashboardData?.leadsPercentage?.bookMarCount > 0 ? crmDashboardData?.leadsPercentage?.bookMarCount : 0,
            tooltip: 'CRM'
        },
        {
            type: 'Cart',
            value: crmDashboardData?.leadsPercentage?.cartCount > 0 ? crmDashboardData?.leadsPercentage?.cartCount : 0,
            tooltip: 'CRM'
        },
        {
            type: 'Enquiry',
            value: crmDashboardData?.leadsPercentage?.enquiryUserCount > 0 ? crmDashboardData?.leadsPercentage?.enquiryUserCount : 0,
            tooltip: 'CRM'
        },
        {
            type: 'Registration',
            value: crmDashboardData?.leadsPercentage?.registeredCount > 0 ? crmDashboardData?.leadsPercentage?.registeredCount : 0,
            tooltip: 'CRM'
        },

    ];

    const allZeroValues = (!crmDashboardData?.leadsPercentage?.bookMarCount && !crmDashboardData?.leadsPercentage?.cartCount && !crmDashboardData?.leadsPercentage?.enquiryUserCount && !crmDashboardData?.leadsPercentage?.registeredCount)


    const handleDropdownVisibleChange = (visible) => {
        if (isScrolled) {
            // setDropdownVisible(visible);

        }
        // setCheck(true)
        // setIsCheck(visible)
        // setIsScrolled(true)
    };

    const handleDropdownVisibleChange1 = (visible) => {
        setDropdownVisible1(visible);
    };



    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '', fontSize: 14, fontWeight: "bold", marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }





    const ConversionsCard = ({ icon, item, isLast }) => {
        const text = `${item?.userName} (${item?.userPhone}) has ${item?.status} ${item?.name}`;
        console.log("qqqqq", item?.itemType, imageSources.videoResource);
        return (
            <div style={{
                width: '100%',
                boxShadow: '4px 4px 16px #0000000F',
                padding: 10,
                cursor: 'pointer',
                backgroundColor: '#FFFFFF',
                borderRadius: 17,
                marginBottom: 10,
                display: 'flex',
                alignItems: 'center'
            }}
                onClick={() => {
                    storeSingleCourseId(item?.id);
                    if (item?.itemType === "Course") {
                        setCourseTypeName("liveCourses");
                    } else if (item?.itemType === "videoResource") {
                        setCourseTypeName("videoResources");
                    } else if (item?.itemType === "testSeries") {
                        setCourseTypeName("onlineTestSeries");
                    } else if (item?.itemType === "studyMaterial") {
                        setCourseTypeName("studyMaterials");
                    }
                    history.push({
                        pathname: "/course-details",
                        state: {
                            fromCRMPage: true,
                            courseType: item?.itemType === "Course" ? 'liveCourses' : item?.itemType === "videoResource" ? "videoResources" : item?.itemType === "testSeries" ? "onlineTestSeries" : "studyMaterials",
                        }
                    });
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                    <img src={
                        item?.itemType === 'Course' ? imageSources.liveCourse :
                            item?.itemType === 'videoResource' ? require('../../Assets/crmOverview/ic-students (2).png').default :
                                item?.itemType === 'studyMaterial' ? imageSources.studyMaterial :
                                    imageSources.testSeries
                    } style={{ height: 45, width: 45 }} alt={item?.itemType} />
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{
                            fontWeight: '600',
                            fontSize: 14,
                            flex: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'gray'
                        }}>
                            {item?.itemType === 'Course' ? 'Live course' :
                                item?.itemType === 'videoResource' ? 'Video course' :
                                    item?.itemType === 'studyMaterial' ? 'Study material' : 'Test series'} {item?.status === 'Enrolled' ? "enrolled" : 'purchased'}
                        </div>
                        <div style={{ fontSize: 9, color: "#636363", marginLeft: 10 }}>
                            <FormattedDate createdAt={item?.latest || item?.date} />
                        </div>
                    </div>
                    <div
                        style={{
                            fontSize: 13,
                            whiteSpace: 'pre-wrap',
                            maxWidth: '100%'
                        }}
                    >
                        <b style={{ color: 'gray' }}>{item?.userName} </b> <span>({item?.userPhone})</span> has {item?.status} <b style={{ color: 'gray' }}>{item?.name}</b>
                    </div>
                </div>
            </div>
        );
    }

    const ContactInitiatedCard = ({ item }) => {
        return (
            <div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', width: '37%' }}>
                        <div>
                            <UserAvatar size="5vmin" shape="circle" name={item?.name} img={item?.image ? `${Api._s3_url}${item?.image}` : ""} fontWeight={true} />
                        </div>
                        <div style={{ marginLeft: 13 }}>
                            <div
                                style={{
                                    width: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            // title={item?.name} 
                            >
                                {item?.name}
                            </div>
                            <div style={{ color: '#AEAEAE', fontSize: 10 }}>{item?.phone}</div>
                        </div>
                    </div>

                    <div style={{ fontSize: 10, marginTop: 10, color: '#AEAEAE' }}> {item?.time ? moment(item.time, 'HH:mm:ss').format('hh:mm A') : ''}</div>
                </div>
                <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: 5, marginBottom: 5 }}></div>
                {/* <hr style={{ backgroundColor: "#E6E6E6", border: "none", height: "1px" }} /> */}
            </div>
        )
    }

    const RecentlyAddedCard = ({ icon, item, isLast }) => {

        const ellipsisStyle = {
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal'
        };

        const title = item.type == 'register' ? 'Student registered' : item.type == 'enquiry' ? 'Enquiry' : item.type == 'cart' ? 'Course added to cart' : 'Course bookmarked'
        return (
            <div
                className={`recently-added-card-course-crm r-c-fs ${hasMarketingPrivilege ? '' : item.type == 'register' ? 'cursor-pointer' : ''}`}
                style={{ marginBottom: !isLast ? 10 : 1 }}
                onClick={() => {
                    if (item?.type == 'register' && !hasMarketingPrivilege) {
                        history.push({
                            pathname: "/user-management/profile",
                            state: {
                                key: true,
                            }
                        });
                        setSpecificUserId(item?.id);
                        setUserDetails(item);
                        setOthersSelectedTab('Basic Profile');


                    }
                }}>
                <img src={item?.type == 'register' ? studentIcon : item?.type == 'bookMark' ? imageSources.bookmark : item?.type == 'cart' ? imageSources.cart : imageSources.enquiry} style={{ height: 45, width: 45 }} />
                <div className='m-l-10' style={{ flex: 1, overflow: 'hidden', marginLeft: 10 }}>
                    <div className='r-c-sb' style={{ width: '100%' }}>
                        <div style={{ color: 'gray', fontSize: 14, fontWeight: 600 }}>{title}</div>
                        <div style={{ color: '#636363', fontSize: 12, flexShrink: 0 }}>
                            <FormattedDate createdAt={item?.latest} />
                        </div>
                    </div>

                    <Tooltip>
                        <Typography.Text ellipsis={{ tooltip: false }} style={{ ...ellipsisStyle, fontSize: 14 }}>
                            {item?.type === 'register' ? (
                                <span style={{ fontWeight: 'bold', color: 'gray' }}>
                                    {item?.userName} <span style={{ fontWeight: 'normal', color: "#191919" }}>({item?.userPhone})</span>
                                    <span style={{ fontWeight: 'bold', color: "gray" }}> registered.</span>
                                </span>
                            ) : item?.type === 'enquiry' ? (
                                <span style={{ fontWeight: 'bold', color: 'gray' }}>
                                    {item?.userName} <span style={{ fontWeight: 'normal', color: "#191919" }}>({item?.userPhone})</span>
                                    <span style={{ fontWeight: 'normal', color: "#191919" }}>
                                        &nbsp; has filled up <b style={{ color: 'gray' }}>Visitors enquiry form</b>
                                    </span>
                                </span>
                            ) : item?.type === 'cart' ? (
                                <span style={{ fontWeight: 'bold', color: 'gray' }}>
                                    {item?.userName} <span style={{ fontWeight: 'normal', color: "#191919" }}>({item?.userPhone})</span>
                                    <span style={{ fontWeight: 'normal' }}>
                                        &nbsp; has added <b style={{ color: 'gray' }}>{item?.name}</b> to <b style={{ color: 'gray' }}>cart</b>
                                    </span>
                                </span>
                            ) : (
                                <span style={{ fontWeight: 'bold', color: 'gray' }}>
                                    {item?.userName} <span style={{ fontWeight: 'normal', color: "#191919" }}>({item?.userPhone})</span>
                                    <span style={{ fontWeight: 'normal' }}>
                                        &nbsp;  has added <b style={{ color: 'gray' }}>{item?.name}</b> to <b style={{ color: 'gray' }}>bookmark</b>
                                    </span>
                                </span>
                            )}
                        </Typography.Text>
                    </Tooltip>
                </div>
            </div>
        )
    }

    const TypeFilterConversions = () => {

        const handleTypeToggle = (val) => {
            const updatedFilterParam = { ...filterParamConversion };
            updatedFilterParam.type = updatedFilterParam.type || [];

            if (val === 'All') {
                updatedFilterParam.type = ['All'];
            } else {
                const index = updatedFilterParam.type.indexOf('All');
                if (index !== -1) {
                    updatedFilterParam.type.splice(index, 1);
                }

                const indexOfVal = updatedFilterParam.type.indexOf(val);
                if (indexOfVal !== -1) {
                    updatedFilterParam.type.splice(indexOfVal, 1);
                } else {
                    updatedFilterParam.type.push(val);
                }

                if (
                    updatedFilterParam.type.includes("Courses") &&
                    updatedFilterParam.type.includes("VideoResources") &&
                    updatedFilterParam.type.includes("TestSeries") &&
                    updatedFilterParam.type.includes("StudyMaterials")
                ) {
                    updatedFilterParam.type = ['All'];
                }

                if (updatedFilterParam.type.length === 0) {
                    updatedFilterParam.type.push('All');
                }
            }

            setFilterParamConversion(updatedFilterParam);
        };


        return (
            <div className=''>
                <div style={{ marginTop: -7 }}>
                    <FilterTitleName filterName="Type" />
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {Types1?.map((item) => (
                        <div
                            key={item.value}
                            className='sortByFilter'
                            style={{
                                backgroundColor: filterParamConversion?.type?.includes(item?.value) ? "#D6C8F5" : '#FFFFFF',
                                color: filterParamConversion?.type?.includes(item?.value) ? '#191919' : "#636363",
                                border: filterParamConversion?.type?.includes(item?.value) ? 'none' : '1px solid #E6E6E6',
                                fontWeight: filterParamConversion?.type?.includes(item?.value) ? "650" : "normal",
                                maxWidth: 130,
                                minWidth: 100,
                                justifyContent: 'center', // Centers horizontally
                                alignItems: 'center', // Centers vertically
                            }}
                            onClick={() => handleTypeToggle(item?.value)}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const TypeFilter = () => {
        const handleTypeToggle = (val) => {
            const updatedFilterParam = { ...filterParam };

            updatedFilterParam.type = updatedFilterParam.type || [];

            if (val === 'All') {
                updatedFilterParam.type = ['All'];
            } else {
                const index = updatedFilterParam.type.indexOf('All');
                if (index !== -1) {
                    updatedFilterParam.type.splice(index, 1);
                }

                const indexOfVal = updatedFilterParam.type.indexOf(val);
                if (indexOfVal !== -1) {
                    updatedFilterParam.type.splice(indexOfVal, 1);
                } else {
                    updatedFilterParam.type.push(val);
                }

                if (updatedFilterParam.type.length === 4) {
                    updatedFilterParam.type = ['All'];
                }

                if (updatedFilterParam.type.length === 0) {
                    updatedFilterParam.type.push('All');
                }
            }

            setFilterParam(updatedFilterParam);
        };


        return (
            <div className=''>
                <div style={{ marginTop: -7 }}>
                    <FilterTitleName filterName="Type" />
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {Types?.map((item) => (
                        <div
                            key={item.value}
                            className='sortByFilter'
                            style={{
                                display: 'flex', // Add flex display
                                alignItems: 'center', // Center vertically
                                justifyContent: 'center', // Center horizontally (optional, if needed)
                                backgroundColor: filterParam?.type?.includes(item?.value) ? "#D6C8F5" : '#FFFFFF',
                                color: filterParam?.type?.includes(item?.value) ? '#191919' : "#636363",
                                border: filterParam?.type?.includes(item?.value) ? 'none' : '1px solid #E6E6E6',
                                fontWeight: filterParam?.type?.includes(item?.value) ? "650" : "normal",
                                maxWidth: 130,
                                minWidth: 100,
                                height: '35px', // Ensure the height is set to make the centering noticeable
                            }} onClick={() => handleTypeToggle(item?.value)}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (isScrolled == false) {
            setDropdownVisible(false)
        }
    }, [isScrolled])
    const [isLoadingTrue, setIsLoadingTrue] = useState(false)
    // console.log('isScrolled',scrollTop);
    const applyFilters = (type) => {

        setPage(1)
        setPage1(1)
        let requestParams
        if (type == 'leads') {

            setIsScrolled(true)
            setLeadsFilterClick(true)
            const div = document.getElementById('scrollableDiv');
            div.scrollTop = 1
            console.log('scrollTop1', div.scrollTop);
            requestParams = {
                page: page,
                limit: 10,
                type: filterParam?.type?.includes('All') ? ['All'] : filterParam?.type,
            }
            // setIsLoadingTrue(true);
            // setCrmLeadsData([])
            // toggleReqsLoader(true);
            // setDropdownVisible(false);
            // setDataLoading(true)
            getCrmDashboardLeads(search, filterParam, 1, true, () => {
                // setDataLoading(false);
                // setDataLoading(false)
                // setIsLoadingTrue(false) , 
            }, () => {
                setFilterModal(false)
                setIsLoadingTrue(false)
            })


        } else {
            requestParams = {
                page: page1,
                limit: 10,
                type: filterParamConversion?.type?.includes('All') ? ['All'] : filterParamConversion?.type,

            }
            setIsLoadingTrue(true);
            setisLoading(true)
            setCrmConversionsData([])
            toggleReqsLoader1(true)
            getCrmDashboardConversions(search, filterParamConversion, 1, true, () => {
                setisLoading(false); setIsLoadingTrue(false),
                    setisLoading(false);
                setDropdownVisible1(false);
            }, () => { toggleReqsLoader1(false) })

        }

    }

    const filterDropDownMenuConversions = (
        <Menu visible={dropdownVisible1} className='filterParent-1' style={{ width: 380, height: 200, marginLeft: -270, marginTop: 25 }}>
            <TypeFilterConversions />
            <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: "5px" }}></div>
            <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
                <Button
                    type='link'
                    style={{
                        color: "#594099",
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        setCrmConversionsData([])
                        setDropdownVisible1(false);
                        getCrmDashboardConversions(search1, 'All', page1, (page1 === 1), () => {
                            togglePageLoader(false);
                            toggleReqsLoader1(false);
                            setFilterParamConversion({ type: ['All'] });

                        });
                    }}
                >
                    RESET
                </Button>
                <Button loading={isLoadingTrue} type='primary' onClick={() => applyFilters('conversions')}>APPLY</Button>
            </div>
        </Menu >
    );


    const filterDropDownMenu = (
        <Menu visible={dropdownVisible} className='filterParent-1' style={{ width: 380, height: 200, marginTop: 25, marginLeft: -230 }}>
            <TypeFilter />
            <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: "5px" }}></div>
            <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
                <div style={{ color: "#594099", fontWeight: 500, fontSize: "16px", marginTop: "5px", cursor: "pointer" }}
                    onClick={() => {
                        setDropdownVisible(false)
                        getCrmDashboardLeads(search, 'All', page, (page == 1 ? true : false), () => { togglePageLoader(false) }, () => { toggleReqsLoader(false), setFilterParam({ type: ['All'] }) })


                    }}
                >RESET</div>
                <Button loading={isLoadingTrue} type='primary' onClick={() => applyFilters('leads')}>APPLY</Button>
            </div>
        </Menu >
    );


    const observer = useRef()

    const lastElementRef = useCallback(node => {
        if (isLoading || !node) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && crmConversionsData?.meta?.currentPage < crmConversionsData?.meta?.totalPages) {
                setPage1(prevPage => prevPage + 1);
            }
        });
        observer.current.observe(node);
    }, [isLoading, crmConversionsData]);

    const observerData = useRef()
    const lastElementRefData = useCallback(node => {
        if (dataLoading || !node) return;
        if (observerData.current) observerData.current.disconnect();
        observerData.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && crmLeadsData?.meta?.currentPage < crmLeadsData?.meta?.totalPages) {
                setPage(prevPage => prevPage + 1);
            }
        });
        observerData.current.observe(node);
    }, [dataLoading, crmLeadsData]);




    const handleRangePicker = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0].format('YYYY-MM-DD'));
            setEndDate(dates[1].format('YYYY-MM-DD'));
        } else {
            setStartDate(moment().format('YYYY-MM-DD'));
            setEndDate(moment().format('YYYY-MM-DD'));
        }

    };

    const preventTyping = (e) => {
        e.preventDefault();
    };

    const Graph1 = () => {
        return (
            <div style={{ width: '100%', height: "100%", display: isScrolled ? "none" : "block", }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: -5 }}>

                    <div style={{ marginLeft: -10, marginTop: -3 }}>
                        <Dropdown
                            getPopupContainer={trigger => trigger.parentNode}
                            overlay={isGraphFilterClicked ? graphMenu : <></>} trigger={['click']} open={isGraphFilterClicked} >
                            <div style={{ display: "flex", marginLeft: 10 }} onClick={(e) => { e.stopPropagation() }}>
                                <div style={{ color: "#191919", fontWeight: "600", fontSize: 18 }}>{courseType == "liveCourse" ? "Live course" : courseType == "videoResource" ? "Video course" : courseType == "studyMaterial" ? "Study material" : courseType == "testSeries" ? "Online test series" : null}</div>

                                <img style={{ fontSize: 14, marginLeft: 10, marginTop: 7, cursor: 'pointer' }}
                                    src={require('../../Assets/crmOverview/Path 97884.svg').default} onClick={() => {
                                        setIsGraphFilterClicked(!isGraphFilterClicked),
                                            setDropdownVisible1(false);
                                        setDropdownVisible(false);

                                    }} />

                            </div>
                        </Dropdown>
                        <div style={{ fontSize: 14, fontWeight: 500, color: '#191919', marginLeft: 10 }}>Impressions and Engagements</div>
                    </div>
                    <div style={{ display: "flex", gap: 15, marginTop: 5 }}>
                        {!isChecked ?
                            <DatePicker
                                inputReadOnly={true}
                                allowClear={false}
                                picker="month"
                                format={'MMM'}
                                value={moment(isMonth, 'MMM')}
                                style={{ borderRadius: "50px", width: "80px", height: "25px", border: '1px solid #E6E6E6' }}
                                onChange={(date, dateString, e) => {
                                    setIsMonth(moment(date).format('MMM'))
                                }}
                                onKeyDown={preventTyping}
                            /> :
                            <DatePicker
                                inputReadOnly={true}
                                allowClear={false}
                                picker="year"
                                value={moment(isYear)}
                                style={{ borderRadius: "50px", width: "80px", height: "25px", border: '1px solid #E6E6E6' }}
                                onChange={(date, dateString, e) => {
                                    setIsYear(moment(date).format('YYYY'))
                                }}
                                onKeyDown={preventTyping}
                            />
                        }
                        <SwitchComponent
                            setIsChecked={setIsChecked}
                            isChecked={isChecked} />
                        <FullscreenOutlined style={{ display: "flex", justifyContent: "right", fontSize: "17px", marginTop: "3px", cursor: "pointer", color: "#AEAEAE" }} onClick={() => { setISGraphFullScreen(true), setCurrentPie(1) }} />
                    </div>



                </div>
                <div style={{ height: "120px", marginTop: "10px", marginLeft: 5, display: isScrolled ? "none" : "block", }}>
                    <GroupedBarPlot data={graphData?.length != 0 ? graphData : []} setVisibleRange={setVisibleRange} visibleRange={visibleRange} formCrmOverview={true} isGraphLoader={isGraphLoader} />

                </div>
                <div style={{ marginTop: isGraphLoader ? 0 : 45 }}>
                    <div style={{ display: "flex", gap: 10, marginTop: -3 }}>
                        <div style={{ height: "13px", width: "13px", backgroundColor: "#594099", borderRadius: 2 }}></div>
                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}> Impressions</div>
                    </div>
                    <div style={{ display: "flex", gap: 10, marginTop: "3px" }}>
                        <div style={{ height: "13px", width: "13px", backgroundColor: "#DFDEF5", borderRadius: 2 }}></div>
                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}> Engagements</div>
                    </div>
                </div>
            </div>
        )
    }

    const Graph2 = () => {
        return (
            <div style={{ width: '100%', height: "100%", display: isScrolled ? "none" : "block", opacity: currentPageCarousel == 0 ? 0 : '' }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: -5 }}>

                    <div >
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: -5 }}>
                            <div style={{ marginLeft: 5, marginTop: 5 }}>
                                <span style={{ color: "#191919", fontWeight: "600", fontSize: 18 }}>Leads and Conversions</span>
                            </div>

                        </div>
                    </div>

                    <div style={{ display: "flex", gap: 15, marginTop: 5 }}>
                        {!isChecked ?
                            <DatePicker
                                inputReadOnly={true}
                                allowClear={false}
                                picker="month"
                                format={'MMM'}
                                value={moment(isMonth, 'MMM')}
                                style={{ borderRadius: "50px", width: "80px", height: "25px", border: '1px solid #E6E6E6' }}
                                onChange={(date, dateString, e) => {

                                    setIsMonth(moment(date).format('MMM'))

                                }}
                                onKeyDown={preventTyping}
                            /> :
                            <DatePicker
                                inputReadOnly={true}
                                allowClear={false}
                                picker="year"
                                value={moment(isYear)}
                                style={{ borderRadius: "50px", width: "80px", height: "25px", border: '1px solid #E6E6E6' }}
                                onChange={(date, dateString, e) => {

                                    setIsYear(moment(date).format('YYYY'))

                                }}
                                onKeyDown={preventTyping}

                            />}
                        <SwitchComponent
                            setIsChecked={setIsChecked}
                            isChecked={isChecked} />
                        <FullscreenOutlined style={{ display: "flex", justifyContent: "right", fontSize: "17px", marginTop: "3px", cursor: "pointer", color: "#AEAEAE" }} onClick={() => { setISGraphFullScreen(true), setCurrentPie(1) }} />
                    </div>



                </div>
                <div className='leads-graph-legend'>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div style={{ height: "12px", width: "12px", backgroundColor: "#594099", borderRadius: 2 }}></div>
                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}>Leads</div>
                    </div>
                    <div style={{ display: "flex", gap: 10, }}>
                        <div style={{ height: "12px", width: "12px", backgroundColor: "#DFDEF5", borderRadius: 2 }}></div>
                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-2px", fontWeight: 500 }}>Conversions</div>
                    </div>
                </div>
                <div style={{ height: "180px", marginTop: "10px", display: isScrolled ? "none" : "block", }}>
                    <DemoDualAxes data={dualAxesGraph?.length != 0 ? dualAxesGraph : []} formCrmOverview={true} isGraphLoader={isGraphLoader} />


                </div>

            </div>
        )
    }

    return (

        <div style={{ ...bodyStyle }} onClick={(e) => { e.stopPropagation(); setIsGraphFilterClicked(false); }} >
            <div style={{ marginBottom: "45px" }}>
                <PageHeader title='CRM' />
            </div>
            <div style={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>

                <div style={{ overflow: "hidden !important", width: "70%" }}>
                    {!isScrolled ? <div style={{ display: 'flex', }}>
                        <div className='heading-size'>Overview</div>
                        <div style={{ marginLeft: 12, borderRadius: 17, height: "24px", width: 245, backgroundColor: '#FFFFFF', boxShadow: ' 4px 4px 16px #0000000F', marginTop: 5 }}>
                            <RangePicker
                                bordered={null}
                                style={{ marginTop: '-15px' }}
                                value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                                format="DD-MM-YYYY"
                                onChange={handleRangePicker}
                            />
                        </div>
                    </div>
                        :
                        <></>
                    }

                    <div style={{
                        width: '98%', display: "flex", justifyContent: "space-between",
                        // opacity: isScrolled ? 0 : 1,
                        // transition: 'opacity 1s ease',
                        // transform: isScrolled ? 'translateY(-110%)' : 'translateY(0)'
                    }}>
                        <div style={{ width: "60%", marginTop: 10, opacity: isScrolled ? 0 : 1, transition: "opacity 1s ease", transform: isScrolled ? 'translateY(-110%)' : 'translateY(0)' }}>
                            <div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                {crmDashboardData?.overview?.map((item, index) => (
                                    <div style={{ marginBottom: 20, width: "47.8%", marginRight: index == 0 || index == 2 ? 10 : '' }}>
                                        <CrmOverviewCard count={item?.count || 0} subTitle={item?.subtitle} imageSources={imageSources[item?.overviewType]} item={item} hoverNone={true} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#ffffff', borderRadius: 17, width: '37.5%', /* display: isScrolled ? "none" :"block", */  height: "183px", padding: '9px', boxShadow: ' 4px 4px 16px #0000000F', marginTop: isScrolled ? 39 : 10 }}>
                            <div style={{ fontWeight: 'bold', fontSize: 22, marginLeft: 8 }}>{crmDashboardData?.leadsGenerated}</div>
                            <div style={{ marginLeft: 8, marginTop: -3 }}>leads generated</div>
                            <div style={{ display: "flex", justifyContent: "space-between", }}>
                                <div className='crm-piechart' style={{ width: "118px", height: "118px", marginLeft: "-1px", }}>
                                    <PieCharts color={allZeroValues ? greyColor : colors} data={courseData?.length != 0 ? courseData : []} />

                                </div>
                                <div style={{ paddingRight: 10 }}>
                                    <div style={{ display: "flex", gap: 5 }} onClick={() => {
                                        history.push("/market-place-crm",)
                                        setTab('1')
                                    }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#B59009", marginTop: 11, cursor: 'pointer', borderRadius: 2 }}></div>
                                        <div className='legend-fonts' >Enquiries ({crmDashboardData?.leadsCount?.enquiryUserCount})</div>
                                    </div>
                                    <div style={{ display: "flex", gap: 5, }} onClick={() => {
                                        history.push("/market-place-crm",)
                                        setTab('2')
                                    }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#DAAB00", marginTop: 11, cursor: 'pointer', borderRadius: 2 }}></div>
                                        <div className='legend-fonts'>Registrations ({crmDashboardData?.leadsCount?.registeredCount})</div>
                                    </div>
                                    <div style={{ display: "flex", gap: 5 }} onClick={() => {
                                        history.push("/market-place-crm",)
                                        setTab('3')
                                    }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#E9CC64", marginTop: 11, cursor: 'pointer', borderRadius: 2 }}></div>
                                        <div className='legend-fonts'>Added to cart ({crmDashboardData?.leadsCount?.cartCount})</div>
                                    </div>
                                    <div style={{ display: "flex", gap: 5, }} onClick={() => {
                                        history.push("/market-place-crm",)
                                        setTab('4')
                                    }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#F9E7A3", marginTop: 11, cursor: 'pointer', borderRadius: 2 }}></div>
                                        <div className='legend-fonts'>Bookmarked ({crmDashboardData?.leadsCount?.bookMarCount})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: '98%', justifyContent: 'space-between', marginTop: isScrolled ? -234 : 0 }}>
                        <div style={{ width: '60%', }}>

                            <div style={{ height: "275px", transform: isScrolled ? 'translateY(-110%)' : 'translateY(0)'/* display: isScrolled ? "none" :"block" */, opacity: isScrolled ? 0 : 1, transition: "opacity 1s ease" }}>
                                <div className='graph-card-crm' style={{ width: '100%', height: "100%", display: isScrolled ? "none" : "block", }}>
                                    <div >

                                    </div>
                                    {!isScrolled ?
                                        <Carousel

                                            autoPlaySpeed={7000}
                                            dots={false}
                                            autoplay={true}
                                            afterChange={handleAfterChange} style={{ height: "275px", display: isScrolled ? "none" : "block", }} >
                                            <Graph1 />
                                            <Graph2 />

                                        </Carousel>
                                        : <></>}
                                </div>

                            </div>
                            <div style={{ marginTop: isScrolled ? -264 : 30, transition: 'margin-top 1s ease' }}>
                                <div style={{ display: "flex", width: '100%' }}>
                                    <div className='heading-size' style={{ width: '35%', }}>Leads ({crmLeadsData?.meta?.totalItems})</div>
                                    <div style={{ width: '100%' }}>
                                        <SearchAndFilters
                                            onSearch={(e) => { setSearch(e); setIsScrolled(true); setPage(1) }}
                                            filterDropDownMenu={filterDropDownMenu}
                                            handleDropdownVisibleChange={handleDropdownVisibleChange}
                                            dropdownVisible={dropdownVisible}
                                            searchPlaceholder={<div style={{ marginBottom: 18 }}>Search</div>}
                                            fromCrmOverview={true}
                                            setIsScrolled={setIsScrolled}
                                            setFilterModal={setFilterModal}
                                        />
                                    </div>
                                </div>


                                <div style={{ display: 'flex', width: "100%", justifyContent: 'flex-end', alignItems: 'center', marginLeft: "-10px" }}>
                                    <div style={{ display: 'flex', fontSize: 12, color: '#AEAEAE', alignItems: 'center' }}>
                                        <span>Type:</span>
                                        <div style={{
                                            color: 'black',
                                            display: 'inline-block',
                                            marginLeft: '5px',
                                            verticalAlign: 'bottom'
                                        }}>
                                            {showAll ? 'All' : displayTypes.join(', ')}
                                        </div>
                                    </div>
                                </div>

                                <div className={`scroll-bar-universal ${(dataLoading ? "r-c-c-c" : "") || (!isScrolled ? 'leads-collapse-height' : 'leads-expand-height')}`} style={{ backgroundColor: "#FAFAFA", marginTop: 10, width: '100%', }} id="scrollableDiv" >
                                    {(dataLoading && page == 1 && requestsLoader) ?
                                        <div>
                                            <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                                        </div>
                                        :
                                        <>

                                            {crmLeadsData?.data?.length > 0 ? (
                                                <>
                                                    {crmLeadsData.data.map((item, index) => (
                                                        <div
                                                            key={item.id}
                                                            ref={index === crmLeadsData.data.length - 1 ? lastElementRefData : null}
                                                        >
                                                            <RecentlyAddedCard
                                                                icon={studentIcon}
                                                                item={item}
                                                                isLast={index === crmLeadsData.data.length - 1}
                                                                isStudent={true}
                                                                type={item.type}
                                                            />
                                                        </div>
                                                    ))}
                                                    {requestsLoader && (
                                                        <div className='r-c-c-c' style={{ height: 200 }}>
                                                            <img
                                                                src={require('../../Assets/gifs/section-loader.gif').default}
                                                                style={{ width: 200 }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div style={{ marginTop: isScrolled ? '20px' : '5px', height: isScrolled ? 100 : 80 }}>
                                                    <NodataCard />
                                                </div>
                                            )}

                                        </>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className='scroll-bar-universal' style={{ marginTop: isScrolled ? 254 : 0, backgroundColor: '#ffffff', borderRadius: 17, width: '37.5%', height: "60vh" /* display: isScrolled ? "none" :"block" */, padding: '14px', boxShadow: ' 4px 4px 16px #0000000F', }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ fontSize: 22, fontWeight: 'bold' }}>{crmDashboardData?.contactInitatedCount}</div>
                                    <div style={{}}> contacts initiated</div>
                                </div>
                                <div>
                                    <div style={{ borderRadius: '50%', height: "20px", width: '20px', marginRight: '35px', marginTop: 2, }}>
                                        <Progress type="circle" percent={(crmDashboardData?.contactInitatedCount / 100) * 100} strokeWidth={18} width={51} strokeColor="#2EA083" trailColor='#D5F8EF' format={() => null} />
                                    </div>
                                </div>
                            </div>

                            <div style={{ borderBottom: "1px solid #AEAEAE", marginTop: 18 }}></div>
                            <div style={{ marginTop: 15, fontWeight: '500', marginBottom: 12 }}>Upcoming follow ups  <span style={{ color: '#AEAEAE' }}>({crmDashboardData?.followUps?.length})</span></div>

                            {crmDashboardData?.followUps?.length > 0 ? (crmDashboardData?.followUps?.map((item, index) => (
                                <>

                                    <div /* ref={(index === crmDashboardData?.followUps?.length - 1) ? lastElementRef : null }*/>
                                        <ContactInitiatedCard item={item} />
                                    </div>

                                </>
                            ))) :
                                <div style={{ marginTop: '80px' }}>
                                    <NodataCard />
                                </div>
                            }

                        </div>


                    </div>

                </div>
                <div style={{ width: "29.5%" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
                        <div ><div className='heading-size'>Conversions ({crmConversionsData?.meta?.totalItems})</div></div>
                        <div ><SearchAndFilters
                            onSearch={(e) => { setSearch1(e); setPage1(1) /* setCrmConversionsData([]) */ }}
                            filterDropDownMenu={filterDropDownMenuConversions}
                            handleDropdownVisibleChange={handleDropdownVisibleChange1}
                            dropdownVisible={dropdownVisible1}
                            searchPlaceholder={<div style={{ marginBottom: 18 }}>Search</div>}
                            fromCrmConversions={true} /></div>
                    </div>
                    <div style={{ display: 'flex', width: "100%", justifyContent: 'flex-end', alignItems: 'center', marginLeft: "-10px", }}>
                        <div style={{ display: 'flex', fontSize: 12, color: '#AEAEAE', alignItems: 'center' }}>
                            <span>Type:</span>
                            <div style={{
                                color: 'black',
                                display: 'inline-block',
                                marginLeft: '5px',
                                verticalAlign: 'bottom'
                            }}>
                                {showAll1 ? 'All' : displayTypes1.join(', ')}
                                {/* {showAll ? 'All' : displayTypes.join(', ')} */}
                            </div>
                        </div>
                    </div>

                    <div className='scroll-bar-universal conversions-scroll-height' id="scrollableDiv">
                        {isLoading && page1 === 1 && requestsLoader1 ? (
                            <div className='r-c-c-c' style={{ height: 520 }}>
                                <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                            </div>
                        ) : (
                            <>
                                {crmConversionsData?.data?.length > 0 ? (
                                    <>
                                        {crmConversionsData.data.map((item, index) => (
                                            <div
                                                key={item.id}
                                                ref={index === crmConversionsData.data.length - 1 ? lastElementRef : null}
                                            >
                                                <ConversionsCard
                                                    icon={studentIcon}
                                                    item={item}
                                                    isLast={index === crmConversionsData.data.length - 1}
                                                    isStudent={true}
                                                    type={item.type}
                                                />
                                            </div>
                                        ))}
                                        {requestsLoader1 && (
                                            <div className='r-c-c-c' style={{ height: 200 }}>
                                                <img
                                                    src={require('../../Assets/gifs/section-loader.gif').default}
                                                    style={{ width: 200 }}
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    crmConversionsData?.data?.length === 0 && page1 === 1 && (
                                        <div style={{ marginTop: '20px', height: 100 }}>
                                            <NodataCard />
                                        </div>
                                    )
                                )}

                            </>
                        )}



                    </div>


                </div>

            </div>
            <CrmModalGraph isGraphFullScreen={isGraphFullScreen}
                setISGraphFullScreen={setISGraphFullScreen}
                setCurrentPie={setCurrentPie}
                currentPie={currentPie}
                dualAxesGraph={dualAxesGraph}
                formCrmOverview={true}
                courseType={courseType}
                isChecked={isChecked}
                setIsMonth={setIsMonth}
                setIsChecked={setIsChecked}
                setIsYear={setIsYear}
                isMonth={isMonth}
                isYear={isYear}
                setCourseType={setCourseType}
                graphData={graphData}
                setVisibleRange={setVisibleRange}
                visibleRange={visibleRange} />


            {filterModal &&
                <LeadsFilterModal
                    filterModal={filterModal}
                    setFilterModal={setFilterModal}
                    filterParam={filterParam}
                    setFilterParam={setFilterParam}
                    setDropdownVisible={setDropdownVisible}
                    getCrmDashboardLeads={getCrmDashboardLeads}
                    page={page}
                    togglePageLoader={togglePageLoader}
                    toggleReqsLoader={togglePageLoader}
                    isLoadingTrue={isLoadingTrue}
                    applyFilters={applyFilters}
                    search={search}
                    isScrolled={isScrolled}
                    setIsLoadingTrue={setIsLoadingTrue}
                />
            }
            <PageDataLoader visible={pageLoader} />


        </div>
    );
};


const mapStateToProps = (state) => {
    const { crmDashboardData, crmLeadsData, crmConversionsData, crmImpressionGraphDataData, crmLeadsConversiopnsGraph, } = state.marketPlaceCRM;
    const { user } = state.session;
    return { crmDashboardData, crmLeadsData, crmConversionsData, crmImpressionGraphDataData, crmLeadsConversiopnsGraph, user };
};

const mapDispatchToProps = (dispatch) => ({
    getCrmDashboardData: (startDate, endDate, callBack) => dispatch(getCrmDashboardData(startDate, endDate, callBack)),
    getCrmDashboardConversions: (search1, filterParamConversion, page1, firstTimeLoading, callBack, successCallBack) => dispatch(getCrmDashboardConversions(search1, filterParamConversion, page1, firstTimeLoading, callBack, successCallBack)),
    getCrmDashboardLeads: (search, filterParam, page, firstTimeLoading, callBack, successCallBack) => dispatch(getCrmDashboardLeads(search, filterParam, page, firstTimeLoading, callBack, successCallBack)),
    getCourseImpressionGraph: (params, callBack) => dispatch(getCourseImpressionGraph(params, callBack)),
    getLeadsConversionsGraph: (params, callBack) => dispatch(getLeadsConversionsGraph(params, callBack)),
    setTab: (res) => dispatch(setTab(res)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setUserDetails: res => dispatch(setUserDetails(res)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    setCrmConversionsData: (val) => dispatch(setCrmConversionsData(val)),
    setCrmLeadsData: (val) => dispatch(setCrmLeadsData(val)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverViewCRM);