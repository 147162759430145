import React from 'react';
import '../design/layout.css';
import { Button, Badge } from 'antd';
import { ArrowRightOutlined, ClockCircleOutlined, StopOutlined , CheckCircleOutlined} from '@ant-design/icons';
// const phIc = require('../Assets/sub/physics.svg').default;
// const chIc = require('../Assets/sub/chem.svg').default;
// const boIc = require('../Assets/sub/bio.svg').default;
// const hiIc = require('../Assets/sub/Economics.svg').default;
// const mtIc = require('../Assets/sub/mathema.svg').default;

const TestCard = ({
  subject,
  topic,
  testDate,
  testProgress,
  icon,
  startTime,
  endTime,
  onPress,
}) => {

  return (
  <div className='relative mobileTestCardWrap NutinFont p-20 m-r-20 display-flex' style={{flexDirection:'column', justifyContent:'space-between'}}>
    <div>
      <div className=''>
        <img src={icon} alt="" style={{width:'60px', height:'60px', borderRadius:'15px'}}/>
        <div className='m-t-10'>
          <div className='text-md bold-600 color-black'>
            {subject}
          </div>
          <div className='text-sm simgrey'>
            {topic}
          </div>
        </div>
      </div>
      <div className='r-ac m-t-5'>
        <div className='simgrey text-xmd'>{testDate}</div>
        <Badge status="default" size="small" className='m-l-10'/>
        <div className='simgrey text-xmd'>
          {startTime.slice(0, startTime.length - 3)} {startTime.slice(0, 2) >= 12 ? 'PM' : 'AM'} - {endTime.slice(0, endTime.length - 3)} {endTime.slice(0, 2) >= 12 ? 'PM' : 'AM'}
        </div>
      </div>
    </div>

    {(testProgress === 'LIVE')
    ?
      <div className='r-c-c m-t-30'>
        <Button onClick={onPress} className='mobileTestGreenBtnWrap' >START NOW <ArrowRightOutlined /></Button>
      </div>
    : (testProgress === 'INPROGRESS')
      ?
        <div className='r-c-c m-t-30'>
          <Button onClick={onPress} className='mobileTestGreenBtnWrap' >CONTINUE <ArrowRightOutlined /></Button>
        </div>
      : (testProgress === 'SUBMITTED')
        ?
        <div className='r-c-c m-t-30'>
          <Button disabled className='mobileTestGreyBtnWrap' ><CheckCircleOutlined /> SUBMITTED</Button>
        </div>
      : (testProgress === 'UPCOMING')
        ?
        <div className='r-c-c m-t-30'>
          <Button disabled className='mobileTestGreyBtnWrap' ><ClockCircleOutlined /> SCHEDULED</Button>
        </div>
      : (testProgress === 'GRADED')
        ?
        <div className='r-c-c m-t-30'>
          <Button onClick={onPress} className='mobileTestGreenLightBtnWrap' >VIEW RESULT <ArrowRightOutlined /></Button>
        </div>
      : null
    }

    {(testProgress === 'UPCOMING')
    ?
      <div className='text-xs testNewTag p-t-5 p-b-5 p-l-10 p-r-10 yellowBack white bold-600 absolute'>
        <div className='tagShadow absolute' style={{top:'6.5px'}}/>
        {testProgress}
      </div>
    : (testProgress === 'LIVE' || testProgress === 'INPROGRESS')
      ?
        <div className='text-xs testNewTag p-t-5 p-b-5 p-l-10 p-r-10 white bold-600 absolute' style={{background:'#FF414D'}}>
          <div className='tagShadow absolute' style={{top:'6.5px', borderColor:'transparent transparent transparent #B72045'}}/>
          {testProgress === 'INPROGRESS' ? 'IN PROGRESS' : testProgress}
        </div>
      :
        null
    }
  </div>
)}

const TestSubjectsCard = ({
  subject,
  topic,
  testDate,
  // status,
  icon,
  startTime,
  endTime,
  onPress,
  testProgress,
  cardType,
  attempts,
  declared
}) => {

  return (
  <div onClick={cardType === 'live' ? () => {} : onPress} className='relative mobileTestCardWrap NutinFont p-20 m-r-20'>
    <div className=''>
      <div className='m-t-10'>
        <div className='text-md bold-600 color-black'>
          {topic}
        </div>
      </div>
    </div>
    {cardType === 'live'
    ?
      <div>
        <div className='r-ac'>
          <div className='simgrey text-xmd'>{testDate}</div>
          <Badge status="default" size="small" className='m-l-10'/>
          <div className='simgrey text-xmd'>
            {startTime.slice(0, startTime.length - 3)} {startTime.slice(0, 2) >= 12 ? 'PM' : 'AM'} - {endTime.slice(0, endTime.length - 3)} {endTime.slice(0, 2) >= 12 ? 'PM' : 'AM'}
          </div>
        </div>
        {(testProgress === 'INPROGRESS' || testProgress === 'LIVE')
        ?
          <div className='r-c-c m-t-30'>
            <Button onClick={onPress} className='mobileTestGreenBtnWrap' >{testProgress === 'LIVE' ? 'START NOW' : 'CONTINUE'} <ArrowRightOutlined /></Button>
          </div>
        : (testProgress === 'UPCOMING')
          ?
          <div className='r-c-c m-t-30'>
            <Button disabled className='mobileTestGreyBtnWrap' ><ClockCircleOutlined /> SCHEDULED</Button>
          </div>
          : (testProgress === 'SUBMITTED')
            ?
            <div className='r-c-c m-t-30'>
              <Button disabled className='mobileTestGreyBtnWrap' ><CheckCircleOutlined /> SUBMITTED</Button>
            </div>
            : (testProgress === 'GRADED')
              ?
              <div className='r-c-c m-t-30'>
                <Button onClick={onPress} className='mobileTestGreenLightBtnWrap' >VIEW RESULT <ArrowRightOutlined /></Button>
              </div>
              : (testProgress === 'MISSED' && !declared)
                ?
                <div className='r-c-c m-t-30'>
                  <Button disabled className='mobileTestGreyBtnWrap' ><StopOutlined /> MISSED</Button>
                </div>
              : (testProgress === 'MISSED' && declared)
                ?
                <div className='r-c-c m-t-30'>
                  <Button onClick={onPress} className='mobileTestGreyBtnWrap' >View Questions</Button>
                </div>
              : null
        }
      </div>
    :
      <div>
        <div className='r-ac'>
          <div className='simgrey text-xmd'>1 hour</div>
          <Badge status="default" size="small" className='m-l-10'/>
          <div className='simgrey text-xmd'>Easy Level</div>
        </div>

        {attempts === 0
          ?
            <div className='r-c-c'>
            {testProgress === 'NEW'
            ?
              <Button onClick={onPress} className='mobileTestGreenBtnWrap m-t-20' >START NOW <ArrowRightOutlined /></Button>
            :
              <Button onClick={onPress} className='mobileTestGreenBtnWrap m-t-20' >CONTINUE <ArrowRightOutlined /></Button>
            }
            </div>
          :
            <div>
              <div className='r-c-c'>
              {testProgress === 'GRADED'
              ?
                <Button onClick={onPress} className='mobileTestGreenBtnWrap m-t-20' >START NOW <ArrowRightOutlined /></Button>
              :
                <Button onClick={onPress} className='mobileTestGreenBtnWrap m-t-20' >CONTINUE <ArrowRightOutlined /></Button>
              }
              </div>
              <div className='r-c-sb' style={{background:'#EAFCF5', margin:'20px -20px -20px', borderRadius:'0px 0px 19px 19px', padding:'5px 20px'}}>
                <div style={{color:'#AEAEAE'}}>
                  No. of attempts : {attempts}
                </div>
                <div style={{color:'#28DF99'}} className='text-sm bold-600 cursor-pointer'>
                  VIEW RESULT <ArrowRightOutlined />
                </div>
              </div>
            </div>

        }
      </div>
    }

    {(testProgress === 'UPCOMING')
    ?
      <div className='text-xs testNewTag p-t-5 p-b-5 p-l-10 p-r-10 yellowBack white bold-600 absolute'>
        <div className='tagShadow absolute' style={{top:'1.8px'}}/>
        {testProgress}
      </div>
    : (testProgress === 'LIVE' || testProgress === 'INPROGRESS')
      ?
        <div className='text-xs testNewTag p-t-5 p-b-5 p-l-10 p-r-10 white bold-600 absolute' style={{background:'#FF414D'}}>
          <div className='tagShadow absolute' style={{top:'1.8px', borderColor:'transparent transparent transparent #B72045'}}/>
          {testProgress === 'INPROGRESS' ? 'IN PROGRESS' : testProgress}
        </div>
      :
        null
    }
  </div>
)}

export {TestCard, TestSubjectsCard};
