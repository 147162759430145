import { Col, Row } from 'antd'
import React from 'react'
import TeacherSummary from './teacherGraphs'

function TeacherDashBoard({ togglePageLoader, pageLoader, standardId }) {
    return (
        <div >
            <Row>
                <Col md={{ span: 24 }} lg={{ span: 9 }} >
                    <TeacherSummary cardSide='left' colors="#9464F9" togglePageLoader={togglePageLoader} pageLoader={pageLoader} standardId={standardId}/>

                </Col>
                <Col md={{ span: 24 }} lg={{ span: 15 }}>
                    <TeacherSummary cardSide='right' colors="#FF83D0" togglePageLoader={togglePageLoader} pageLoader={pageLoader} standardId={standardId}/>
                </Col >
            </Row>
        </div>
    )
}

export default TeacherDashBoard
