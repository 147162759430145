import { notification } from 'antd';


class MessageNotification {
    group() {
      notification.info({
          description: "New Message in group chat",
          duration: 3,
          style: {
            borderRadius: 11,
          }
        });
    };
    private(fromusername){
        notification.info({
            description: `New Message from ${fromusername}`,
            duration: 3,
            style: {
              borderRadius: 11,
            },
          });
    }

}
export default new MessageNotification();
