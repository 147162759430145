import React, { useState, useEffect } from 'react'
import { Avatar, Col, Modal, Row } from 'antd'
import { Heading } from 'components/Typography'
import { IMG_URL } from "../../env.json";
import { Button } from 'components/Button';
import { leaveOrAttendanceRequestActions } from 'actions/profileNew';
import { connect } from 'react-redux';

const AttendanceRequestsModal = ({ open, onClose, data, leaveOrAttendanceRequestActions, user }) => {
        console.log({ data })
        const [buttonLoader, setButtonLoader] = useState(false);
        const [rejectBtnLoader, setRejectbtnLoader] = useState(false);
        const [approveBtnLoader, setApprovebtnLoader] = useState(false);

        const handleChangeStatus = (status, commonId) => {
                let params = {
                        action: status,
                        commonId: commonId,
                        requestIds: []
                }

                const callback = () => {
                        onClose();
                        setButtonLoader(false)
                };

                setButtonLoader(true)
                leaveOrAttendanceRequestActions(params, () => callback(), () => { })
        }

        const handleReject = (commonId) => {
                let params = {
                        action: 'Rejected',
                        commonId: commonId,
                        requestIds: []
                }

                const callback = () => {
                        onClose();
                        setRejectbtnLoader(false)
                };

                setRejectbtnLoader(true)
                leaveOrAttendanceRequestActions(params, () => callback(), () => { })
        };

        const handleApprove = (commonId) => {
                let params = {
                        action: 'Approved',
                        commonId: commonId,
                        requestIds: []
                }

                const callback = () => {
                        onClose();
                        setApprovebtnLoader(false)
                };

                setApprovebtnLoader(true)
                leaveOrAttendanceRequestActions(params, () => callback(), () => { })
        }

        return (
                <Modal
                        centered
                        closable
                        open={open}
                        footer={null}
                        className='modal-round-corner'
                        onCancel={onClose}
                >
                        <center>
                                <Heading>Details</Heading>
                        </center>
                        <div className='p-20'>
                                <Row className='r-jsb' style={{ width: '80%' }}>
                                        <Col>
                                                <div className='simgrey'>Date</div>
                                                <div className='text-xmd bold-600'>{data?.startDate || 'NA'}</div>
                                        </Col>

                                        <Col>
                                                <div className='simgrey'>Status</div>
                                                <div className='text-xmd bold-600' style={{ color: data?.status == 'Rejected' ? '#FF414D' : data?.status == 'Approved' ? '#28DF99' : '#FFA931' }}>{data?.status.toUpperCase() || 'NA'}</div>
                                        </Col>
                                </Row>

                                <Row className='r-jsb m-t-20' style={{ width: '80%' }}>
                                        <Col>
                                                <div className='simgrey'>Clock in</div>
                                                <div className='text-xmd bold-600'>{data?.clockIn || 'NA'}</div>
                                        </Col>

                                        <Col>
                                                <div className='simgrey'>Clock out</div>
                                                <div className='text-xmd bold-600'>{data?.clockOut || 'NA'}</div>
                                        </Col>
                                </Row>

                                <div className='simgrey m-t-20'>Total hours</div>
                                <div className='text-xmd bold-600'>{data?.duration || 'NA'}</div>

                                <div className='simgrey m-t-20'>Reason(s)</div>
                                <div className='text-xmd bold-600'>{data?.reason || 'NA'}</div>

                                {data?.status == 'Pending' ? null :
                                        <>
                                                <div className='simgrey m-t-20'>Managed by</div>
                                                <Row>
                                                        <div><Avatar size={50} src={`${IMG_URL}/${data?.actionBy?.image ?? 'default.png'}`}></Avatar></div>
                                                        <Col className='m-l-10'>
                                                                <div className='color-black bold-600' >{data?.actionBy?.name || "NA"}</div>
                                                                <div className='simgrey'>{data?.actionBy?.role || 'NA'}</div>
                                                        </Col>
                                                </Row>

                                        </>
                                }

                                {(data?.status == 'Rejected' && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager'))) ?
                                        <center>
                                                <Button
                                                        loading={buttonLoader}
                                                        onClick={() => handleChangeStatus(
                                                                data?.status == 'Approved' ? 'Rejected' :
                                                                        data?.status == 'Rejected' ? 'Approved' : null,
                                                                data?.commonId
                                                        )}
                                                        style={{
                                                                color: data?.status == 'Approved' ? '#FF414D' :
                                                                        data?.status == 'Rejected' ? '#28DF99' : null,
                                                                fontSize: 14,
                                                                marginTop: 30
                                                        }}
                                                        type='link'
                                                >
                                                        CHANGE STATUS TO APPROVED
                                                </Button>
                                        </center>
                                        :
                                        (data?.status === 'Pending' && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager'))) ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Row style={{ justifyContent: 'space-between', width: '60%', marginTop: 30 }}>
                                                                <Button onClick={() => handleReject(data?.commonId)} loading={rejectBtnLoader} type='primary' style={{ backgroundColor: '#FF414D', border: 'none' }}>REJECT</Button>
                                                                <Button onClick={() => handleApprove(data?.commonId)} loading={approveBtnLoader} type='primary' style={{ backgroundColor: '#28DF99', border: 'none' }}>APPROVE</Button>
                                                        </Row>
                                                </div>
                                                :
                                                null
                                }
                        </div>
                </Modal>
        )
}

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { } = state.profileNew;
        const { } = state.userManagementV2
        return { user }
}

const mapDispatchToProps = (dispatch) => ({
        leaveOrAttendanceRequestActions: (params, callback, pagecallback) => dispatch(leaveOrAttendanceRequestActions(params, callback, pagecallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceRequestsModal)