import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Radio,
  Input,
  Select,
  Search,
  AutoComplete,
} from "antd";
import Paid_date from "../userManagement/editPaidDate";
import { connect } from "react-redux";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  fetchTransaction,
  generatePayRequest,
  getFeeOverView,
  getTransactionsOfStudent,
  getUserOfClass,
  postOfflineTransaction,
  setAllActivitiesFilter,
  setFeeDetails,
  setStudentName,
  getTransactionData
} from "actions/feeManagement";
import { getStudentsDetailsById } from "actions/userManagement";

function OfflinePayReq({
  item,
  isModalVisible,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,
  type,
  name,
  role,
  setIsModalVisible,
  setShowAddDetailsModal,
  setIsAddOrGenModal,
  setIsAdditionModal,
  setFeeDetails,
  feeMangementData,
  getUserOfClass,
  generatePayRequest,
  setAllActivitiesFilter,
  getFeeOverView,
  fetchTransaction,
  getTransactionsOfStudent,
  postOfflineTransaction,
  userDetailsById,
  setPageLoader,
  getStudentsDetailsById,
  userApiCall,
  setStudentName,
  getTransactionData,
  startDate,
  endDate,
  week,
  isYear
}) {
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [value, setValue] = React.useState(1);
  const [selectedBtn, setSelectedBtn] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [optionStudent, setOptionStudent] = useState([]);
  const [optionDesc, setOptionDesc] = useState([]);
  const [studentDesc, setStudentDesc] = useState(null);
  const [autoSearchLoading, setAutoSearchLoading] = useState(false);
  const [descAutoSearchLoading, setDescAutoSearchLoading] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const [studentDescId, setStudentDescId] = useState(null);
  const [addLdr, setAddLdr] = useState(false);
  const [apiCall, toggleApiCall] = useState(false);
  const [scholar, setScholar] = useState(item?.discount_details ? JSON.parse(item?.discount_details) : []);
  const [paidDate, setPaidDate] = useState(null);
  const [additions, setAdditions] = useState([]);

  function removeElement(array, index) {
    let tmp = [];
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
      } else {
        tmp.push(array[i]);
      }
    }
    return tmp;
  }
  const isJsonOrString = (temp) => {
    try {
      JSON.parse(temp);
      return true;
    } catch (error) {
      return false;
    }
  };
  const searchResult = (query) =>
    query?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{item.name}</span>
          </div>
        ),
      };
    });
  const searchResultDesc = (query) =>
    query?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{item.note}</span>
          </div>
        ),
      };
    });
  const checkDescMatch = () => {
    for (
      let i = 0;
      i < feeMangementData?.allTransactionsOfStudent?.length;
      i++
    ) {
      if (
        feeMangementData?.allTransactionsOfStudent?.[i]?.note ==
        feeMangementData?.selectedStudentDesc
      ) {
        toggleApiCall(true);
      }
    }
  };

  useEffect(() => {
    setFeeDetails("modeOfPay", "Cash");
    setFeeDetails("paidDate", null);
    setFeeDetails("selectedStudentDescId", item?.id);

    setIsAdditionModal(false);
    setIsAddOrGenModal(true);
  }, []);
  const handleChangeStudent = (val, obj) => {
    if (val) {
      setStudentName(obj?.label?.props?.children?.props?.children);
      setFeeDetails(
        "selectedStudentName",
        obj?.label?.props?.children?.props?.children
      );
    } else {
      setStudentName(null);
      setFeeDetails("selectedStudentName", null);
      setFeeDetails("selectedStudentDesc", null);
      setFeeDetails("selectedStudentDescId", null);
    }
  };
  const handleChangeDesc = (val, obj) => {
    if (val) {
      setStudentDesc(obj?.label?.props?.children?.props?.children);
      setFeeDetails("selectedStudentDesc", val);
      toggleApiCall(false);
      checkDescMatch();
      setFeeDetails("selectedSingleTransaction", {});
      setFeeDetails("finalFeeRequested", null);
      setFeeDetails("finalFeeRequestedWithGst", null);
      setFeeDetails("generateOfflinePayReqAmount", null);
      setFeeDetails("generatePayAdditions", null);
      setFeeDetails("generatePayDiscount", null);
    } else {
      setStudentDesc(null);
      setFeeDetails("selectedSingleTransaction", {});
      setFeeDetails("finalFeeRequested", null);
      setFeeDetails("finalFeeRequestedWithGst", null);
      setFeeDetails("generateOfflinePayReqAmount", null);
      setFeeDetails("generatePayAdditions", null);
      setFeeDetails("generatePayDiscount", null);
      setFeeDetails("selectedStudentDesc", null);
    }
  };
  const onSelectStudent = (val, obj) => {
    if (val) {
      setStudentId(val);
      setFeeDetails("selectedStudentId", [val]);
      setStudentName(obj?.label?.props?.children?.props?.children);
    } else {
      setStudentId(null);
      setStudentName(null);
      setFeeDetails("selectedStudentId", null);
    }
    getTransactionsOfStudent(() => setDescAutoSearchLoading(false), "");
    setFeeDetails("allTransactionsOfStudent", []);
    setFeeDetails("selectedStudentDesc", null);
    setFeeDetails("selectedStudentDescId", null);
  };
  const onSelectStudentDesc = (val, obj) => {
    if (val) {
      if (obj.key != -1) {
        setStudentDescId(val);
        setFeeDetails("selectedStudentDescId", val);
        setStudentDesc(obj?.label?.props?.children?.props?.children);
        setFeeDetails(
          "selectedStudentDesc",
          obj?.label?.props?.children?.props?.children
        );
      } else {
        setFeeDetails("selectedStudentDesc", val?.replace("create ", ""));
      }
    } else {
      setStudentDescId(null);
      setStudentDesc(null);
      setFeeDetails("selectedStudentDescId", null);
    }
  };

  const handleSearchStudent = (val) => {
    if (val) {
      setStudentName(val);
    } else {
      setStudentName(null);
    }
    setAutoSearchLoading(true);
    getUserOfClass(() => setAutoSearchLoading(false), val);
    setFeeDetails("selectedStudentDesc", null);
    setFeeDetails("selectedStudentDescId", null);
  };
  const handleSearchStudentDesc = (val) => {
    if (val) {
      setStudentDesc(val);
    } else {
      setStudentDesc(null);
    }
    setDescAutoSearchLoading(true);

    getTransactionsOfStudent(
      () => setDescAutoSearchLoading(false),
      studentDesc
    );
  };
  const calcSum = (temp) => {
    let sum = 0;
    for (let i = 0; i < temp?.length; i++) {
      sum = sum + parseFloat(temp[i].amount);
    }
    return sum;
  };

  const [sendInvoice, setSendInvoice] = useState(feeMangementData?.dashBoardDetails?.invoiceTyoe == "automatic" ? true : false)

  const onChangeValue = (e) => {
    setSendInvoice(e.target.value);
  };

  return (
    <div style={{ fontFamily: "roboto" }}>
      <Modal
        maskClosable={false}
        className="generatePayModalB"
        okText={"SAVE"}
        cancelText={"CANCEL"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{
          loading: addLdr,
          disabled:
            !(feeMangementData?.paidDate),
        }}
        onOk={() => {
          checkDescMatch();
          setIsAddOrGenModal(false);
          setAddLdr(true);
          postOfflineTransaction(
            sendInvoice,
            () => {
              setAddLdr(false);
            },
            () => {
              setIsModalVisible(false);
              setFeeDetails("selectedStudentDesc", null);
              setFeeDetails("selectedStudentDescId", null);
              setFeeDetails("selectedSingleTransaction", []);
              setIsModalVisible(false);
              setFeeDetails("generatePayDate", null);
              setFeeDetails("generatePayDescription", null);
              setFeeDetails("generatePayReqAmount", null);
              setFeeDetails("generatePayAdditions", null);
              setFeeDetails("generatePayDiscount", null);
              setFeeDetails("generatePayDate", null);
              setFeeDetails("generateOfflinePayDescription", null);
              setFeeDetails("generateOfflinePayReqAmount", null);
              setFeeDetails("generatePayAdditions", null);
              setFeeDetails("generatePayDiscount", null);
              setFeeDetails("modeOfPay", null);
              setFeeDetails("selectedClassId", null);
              setFeeDetails("selectedStudentName", null);
              setFeeDetails("generateOfflinePayCheckNumber", null);
              setAllActivitiesFilter("userId", null);
              setFeeDetails("selectedStudentDesc", null);
              setFeeDetails("selectedStudentDescId", null);
              setFeeDetails("finalFeeRequestedWithGst", null);
              setFeeDetails("selectedSingleTransaction", []);
              setFeeDetails("paidDate", null);

              if (userApiCall) {
                if (window.location.pathname !== '/fee-management/all-transactions')
                  getStudentsDetailsById(() => {
                    setPageLoader(false);
                  });
              } else {
                getFeeOverView(() => {
                  setPageLoader(false);
                });
              }
              fetchTransaction();
              getTransactionData(startDate, endDate, week, isYear, () => { }, () => { })
            }
          );
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setIsAddOrGenModal(false);
          setFeeDetails("generatePayDate", null);
          setFeeDetails("generateOfflinePayDescription", null);
          setFeeDetails("generateOfflinePayReqAmount", null);
          setFeeDetails("generatePayAdditions", null);
          setFeeDetails("generatePayDiscount", null);
          setFeeDetails("modeOfPay", null);
          setFeeDetails("selectedClassId", null);
          setFeeDetails("selectedStudentName", null);
          setFeeDetails("selectedStudentId", null);
          setFeeDetails("generateOfflinePayCheckNumber", null);
          setFeeDetails("selectedStudentDesc", null);
          setFeeDetails("selectedStudentDescId", null);
          setFeeDetails("finalFeeRequestedWithGst", null);
          setFeeDetails("selectedSingleTransaction", []);
          setFeeDetails("paidDate", null);
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Mark As Paid Offline
        </h2>
        <div
          style={{ overflowY: "auto", fontFamily: "roboto", height: "500px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "25px",
              marginBottom: "25px",
            }}
          >
            <div style={{ width: "50%", marginLeft: "10px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >
                Class
              </p>
              <Input
                readOnly
                value={userDetailsById?.student?.studentStandards?.map((item) => item?.std).join(', ')}
                style={{
                  border: "#E6E6E6 solid 1px",
                  borderRadius: "5px",
                  color: "#000000",
                  marginRight: "5px",
                }}
              />
            </div>

            <div
              style={{
                marginBottom: "10px",
                width: "315px",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >
                Student’s name/mobile<span style={{ color: "red" }}> *</span>
              </p>
              {/* <br></br> */}
              <Input
                readOnly
                value={userDetailsById?.name}
                style={{
                  width: "96%",
                  border: "#E6E6E6 solid 1px",
                  borderRadius: "5px",
                  color: "#000000",
                  marginRight: "5px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: "10px",
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "-20px",
            }}
          >
            Details
          </div>
          <div
            style={{
              marginBottom: "10px",
              width: "96%",
              marginLeft: "10px",
              // marginRight: "-50px",
            }}
          >
            {/* <Typography.Text style={{ marginBottom: "10px" }}>
                      Subject
                    </Typography.Text> */}
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Description<span style={{ color: "red" }}> *</span>
            </p>
            {/* <br></br> */}
            {/* <AutoComplete
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "3px",
                width: "100%",
              }}
              allowClear={true}
              dropdownMatchSelectWidth={252}
              className="autocompletetoupload"
              options={
                optionDesc?.length
                  ? optionDesc
                  : feeMangementData?.selectedStudentDesc
                  ? [
                      {
                        key: -1,
                        value: feeMangementData?.selectedStudentDesc
                          ? `create ${feeMangementData?.selectedStudentDesc}`
                          : null,
                      },
                    ]
                  : []
              }
              value={feeMangementData?.selectedStudentDesc}
              onChange={handleChangeDesc}
              onSelect={onSelectStudentDesc}
              onSearch={handleSearchStudentDesc}
              loading={descAutoSearchLoading}
              disabled={!feeMangementData?.selectedStudentId}
            >
              <Input
                prefix={<SearchOutlined />}
                size="middle"
                className="input-component"
                style={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  border: "none",
                }}
                placeholder="Input search description"
              />
            </AutoComplete> */}
            <Input
              readOnly
              value={item?.note}
              style={{
                //   width: "196%",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
                color: "#000000",
                marginRight: "5px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              // backgroundColor: "red",
            }}
          >
            <div style={{ marginLeft: "10px", width: "50%" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Amount<span style={{ color: "red" }}> *</span>
              </p>
              <Input
                // type="number"
                readOnly
                value={item?.amount}
                onChange={(e) => {
                  if (Number(e.target.value) || e.target.value == "") {
                    setFeeDetails(
                      "generateOfflinePayReqAmount",
                      e.target.value
                    );
                  }
                }}
                prefix="₹"
                style={{
                  width: "196%",
                  marginBottom: "20px",
                  border: "#E6E6E6 solid 1px",
                  borderRadius: "5px",
                  color: "#000000",
                  marginRight: "5px",
                }}
                placeholder="Enter amount"
              />{" "}
            </div>

            <div
              style={{
                width: "50%",
                backgroundColor: "blue",
                marginTop: "10px",
              }}
            >
              {/* <Date_Picker
              value={item?.dueDate}
              setInstallments={setInstallments}
              installments={installments}
              index={index}
              price={item?.installment}
              setInstCust={setInstCust}
            /> */}
            </div>
          </div>
          <div
            style={{ marginLeft: "10px", width: "96%", marginBottom: "10px" }}
          >
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Paid date<span style={{ color: "red" }}> *</span>
            </p>
            <Paid_date
              setPaidDate={setPaidDate}
              value={feeMangementData?.paidDate}
            />
          </div>
          {/* <div style={{ marginLeft: "10px", width: "95%" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Description
            </p>
            <Input
              value={feeMangementData?.generateOfflinePayDescription}
              onChange={(e) => {
                if (e.target.value == " ") {
                  setFeeDetails("generateOfflinePayDescription", null);
                } else {
                  setFeeDetails(
                    "generateOfflinePayDescription",
                    e.target.value
                  );
                }
              }}
              style={{
                width: "102%",
                marginBottom: "20px",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
                color: "#000000",
                marginRight: "5px",
              }}
              placeholder="Enter Description"
            />{" "}
          </div> */}
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Amount details
            </p>
            <div
              style={{
                border: "1px solid #E6E6E6",
                borderRadius: "10px",
                padding: "10px",
                width: "98%",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "40px",
                  justifyContent: "space-between",
                }}
              >
                <div>Fee amount</div>
                {item?.status == "Not Requested" ? (
                  <>
                    {feeMangementData?.dashBoardDetails?.gst ? (
                      <div>₹
                        {(parseFloat(item?.amount) - parseFloat(item?.amountPayable * 0.18)).toFixed(2)}
                      </div>

                    ) : (
                      <div>₹ {item?.amount}j</div>

                    )}
                  </>
                ) : (
                  <div>₹ {item?.amount}</div>

                )}
              </div>
              {scholar &&
                scholar?.length != 0 &&
                scholar?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "25px",
                      color: "#1089FF",
                    }}
                  >
                    <div>{item?.name}</div>
                    <div

                    // style={{ cursor: "pointer" }}
                    >
                      -₹ {item?.amount}
                    </div>
                  </div>
                ))}

              {item?.addition_details &&
                JSON.parse(item?.addition_details)?.length != 0 &&
                JSON.parse(item?.addition_details)?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "25px",
                      color: "#FFA931",
                    }}
                  >
                    <div>{item?.name}</div>
                    <div

                    // style={{ cursor: "pointer" }}
                    >
                      +₹ {item?.amount}
                    </div>
                  </div>
                ))}

              {console.log("AA-item", item?.status)}
              {item?.status == "Not Requested" ? (
                <>
                  {feeMangementData?.dashBoardDetails?.gst && (
                    <div
                      style={{
                        fontFamily: "roboto",
                        fontWeight: "600",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "40px",
                        color: "grey",
                      }}
                    >
                      <div>GST</div>
                      <div>+₹ {parseFloat(item?.amountPayable * 0.18).toFixed(2)}</div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {item?.gst ? (
                    <div
                      style={{
                        fontFamily: "roboto",
                        fontWeight: "600",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "40px",
                        color: "grey",
                      }}
                    >
                      <div>GST</div>
                      <div>+₹ {parseFloat(item?.gst).toFixed(2)}</div>
                    </div>
                  ) : null}
                </>
              )}

              {/* {feeMangementData?.selectedSingleTransaction?.gstApplicable ==
              true ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "grey",
                  }}
                >
                  <div>GST</div>
                  <div
                  // onClick={() => {
                  //   setIsAdditionModal(true);
                  //   setShowAddDetailsModal(true);
                  //   setIsModalVisible(false);
                  // }}
                  // style={{ cursor: "pointer" }}
                  >
                    +₹{" "}
                    {feeMangementData?.selectedSingleTransaction?.gst
                      ? parseFloat(
                          feeMangementData?.selectedSingleTransaction?.gst
                        ).toFixed(2)
                      : 0}
                  </div>
                </div>
              ) : null} */}
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #E6E6E6",
                  borderBottom: "1px solid #E6E6E6",
                  height: "40px",
                }}
              >
                <div>Total payable</div>
                <div>
                  ₹{" "}
                  {item?.amountPayable}
                </div>
              </div>
            </div>
            <div>
              {
                feeMangementData?.dashBoardDetails?.invoiceType === "manual" ?
                  <Radio.Group onChange={(e) => { setSendInvoice(e.target.value) }} value={sendInvoice}>
                    <p
                      style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px", marginTop: "12px" }}
                    >
                      Send Invoice
                    </p>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}

                    >
                      No
                    </Radio>
                  </Radio.Group> :
                  null
              }
            </div>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
                marginTop: "30px",
              }}
            >
              Mode of payment
            </p>
            {/* <div style={{ fontWeight: "500" }}>Mode of payment</div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "500px",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedBtn(1);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setFeeDetails("modeOfPay", "Cash");
                }}
                style={{
                  backgroundColor: selectedBtn == 1 ? "#594099" : "white",
                  color: selectedBtn == 1 ? "white" : "#8c8c8c",
                }}
                className={
                  selectedBtn == 1 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Cash
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 2 ? "#594099" : "white",
                  color: selectedBtn == 2 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setSelectedBtn(2);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setFeeDetails("modeOfPay", "Debit/Credit card");
                }}
                className={
                  selectedBtn == 2 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Debit/Credit card
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 3 ? "#594099" : "white",
                  color: selectedBtn == 3 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "Bank transfer");
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setSelectedBtn(3);
                }}
                className={
                  selectedBtn == 3 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Bank transfer
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 4 ? "#594099" : "white",
                  color: selectedBtn == 4 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "Cheque");
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                  setSelectedBtn(4);
                }}
                className={
                  selectedBtn == 4 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                Cheque
              </Button>
              <Button
                style={{
                  backgroundColor: selectedBtn == 5 ? "#594099" : "white",
                  color: selectedBtn == 5 ? "white" : "#8c8c8c",
                }}
                onClick={() => {
                  setFeeDetails("modeOfPay", "UPI");
                  setSelectedBtn(5);
                  setFeeDetails("generateOfflinePayCheckNumber", null);
                }}
                className={
                  selectedBtn == 5 ? "buttonStylesBpActive" : "buttonStylesBp"
                }
              >
                UPI
              </Button>
            </div>
            {feeMangementData?.modeOfPay != "Cash" ? (
              <div style={{ marginTop: "20px", width: "95%" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                  }}
                >
                  {feeMangementData?.modeOfPay == "Cheque"
                    ? " Cheque number"
                    : "Transaction ID"}
                </p>
                <Input
                  value={feeMangementData?.generateOfflinePayCheckNumber}
                  onChange={(e) => {
                    setFeeDetails(
                      "generateOfflinePayCheckNumber",
                      e.target.value
                    );
                  }}
                  style={{
                    width: "102%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                    color: "#000000",
                    marginRight: "5px",
                  }}
                  placeholder={`Enter ${feeMangementData?.modeOfPay == "Cheque"
                    ? "cheque number"
                    : "transaction ID"
                    }`}
                />{" "}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    userDetailsById,
  } = state.userManagement;
  const { feeMangementData } = state.feeManagement;


  // const { feeMangementData } = state.feeMangement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    feeMangementData,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  getUserOfClass: (callback) => dispatch(getUserOfClass(callback)),
  generatePayRequest: (val, callback, successCallBack, payType) => dispatch(generatePayRequest(val, callback, successCallBack, payType)),
  setAllActivitiesFilter: (key, val) => dispatch(setAllActivitiesFilter(key, val)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  getTransactionsOfStudent: (callback, desc) => dispatch(getTransactionsOfStudent(callback, desc)),
  postOfflineTransaction: (sendInvoice, callback, successCallBack,) => dispatch(postOfflineTransaction(sendInvoice, callback, successCallBack,)),
  getStudentsDetailsById: (callback) => dispatch(getStudentsDetailsById(callback)),
  setStudentName: (val) => dispatch(setStudentName(val))

});

export default connect(mapStateToProps, mapDispatchToProps)(OfflinePayReq);
