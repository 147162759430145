import React from 'react'
import ReactPlayer from 'react-player';
import { useState, useEffect } from 'react';
import { Modal, Input, AutoComplete, Select, Progress } from 'antd';
import {
  SubHeading,
} from "components/Typography";
import { subjectFilter, fetchAdminChapterFilter, setCheckedVideo, setCheckedQuestion, setCheckedLecture, fetchClasslist, setUploadContent, updateStdList, fetchFilterTopic, updateSetType, fetchLibraryData, setKey, postCreateTopic, updateAdminChapterInfo, updateFilterTopic } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import { uploadlecturenotes } from 'actions/digitalLibrary';
import YTPlayer from 'components/ytPlayer';
import { Button } from 'components/Button';

function Videouploadmodel({ type,
  uploadlecturenotes,
  ismodelvisible, setmodevisible,
  setmodel,
  subjectFilter,
  subjects,
  selectedStd, selectedSubject, fetchAdminChapterFilter, getChaptersList, setCheckedVideo, setCheckedQuestion, setCheckedLecture,
  fetchClasslist, getStdList, setUploadContent, updateStdList, fetchFilterTopic, updateSetType, selectedChapter, selectedTopic, topicList, contentPage, setTabKey, postCreateTopic,
  updateAdminChapterInfo, updateFilterTopic
}) {


  const [subjectname, setSubjectName] = useState(null)
  const [inputvalue, setinputvalue] = useState('')
  const [chaptersarray, setchapterarray] = useState([...getChaptersList])
  const [chapter, setchapter] = useState('')
  const [classid, setclassid] = useState(null)
  const [subjectid, setsubjectid] = useState(null)
  const [chapterid, setchapterid] = useState(null)
  const [link, setlink] = useState(null)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [searchChapter, setSearchChapter] = useState("")
  const [optionsChapter, setOptionsChapter] = useState([]);
  const [chapterLoader, setChapterLoader] = useState(false)
  const [subjectLoder, setSubjectLoader] = useState(false)
  const [className, setClassName] = useState(null)
  const [topicId, setTopicId] = useState(null)
  const [topicName, setTopicName] = useState(null)
  const [classLoader, setClassLoader] = useState(false)
  const [topicLoader, setTopicLoader] = useState(false)
  let [topicListToShow, setTopicListToShow] = useState([])
  const [createLoader, setCreateLoader] = useState(false)
  const [progress, setProgress] = useState(0);
  const [progressCheck, setProgressCheck] = useState(false)


  let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  const linkedchanged = (e) => {
    setlink(e.target.value)

  }

  const inputchanged = (e) => {

    setinputvalue(e.target.value)
  }
  const searchResult = (query) =>
    query.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {item.name}
            </span>
          </div>
        ),
      };
    });

  useEffect(() => {
    subjectFilter('')
  }, [])

  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(subjects ? [...searchResult(subjects)] : []);
  }, [subjects])

  useEffect(() => {
    setOptionsChapter(getChaptersList ? [...searchResult(getChaptersList)] : []);
  }, [getChaptersList])

  useEffect(() => {
    if (selectedSubject && Object.keys(selectedSubject).length !== 0) {
      // setsubjectid(selectedSubject.subjects_id)
      // setSubjectName(selectedSubject.subjects_name)
      fetchClasslist(selectedSubject.subjects_id, '')
      setsubjectid(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId)
      setSubjectName(selectedSubject.subjects_name ? selectedSubject.subjects_name :selectedSubject.subjectname)
    }
    if (selectedStd && Object.keys(selectedStd).length !== 0) {
      console.log("selectedStd32",selectedStd);
      setclassid(selectedStd.std_id ? selectedStd.std_id : selectedStd.classid)
      setClassName(selectedStd.standard ? selectedStd.standard + '-' + selectedStd.std_section : selectedStd?.className)
      // setclassid(selectedStd.std_id)
      // setClassName(selectedStd.standard + '-' + selectedStd.std_section)
    }

    if (selectedChapter && Object.keys(selectedChapter).length != 0) {
      setchapterid(selectedChapter.chapterId ? selectedChapter.chapterId : selectedChapter?.chapterid)
      setchapter(selectedChapter.chapterName)
      setSearchChapter(selectedChapter.chapterName)
    }

    if (selectedTopic && Object.keys(selectedTopic).length != 0) {
      setTopicId(selectedTopic.topicId)
      setTopicName(selectedTopic.topicName)

    }
  }, [])

  useEffect(() => {
    if (topicList?.length != 0) {
      setTopicListToShow(topicList)
    }
    else {
      setTopicListToShow([])
    }
  }, [topicList])

  const handleSearch = (value) => {
    if (!value) {
      setSubjectName('')
      setsubjectid(null)
    }
    else {
      setSubjectName(value)
      setSubjectLoader(true)
    }
    subjectFilter(value, () => setSubjectLoader(false))

  };


  const handleChangeSub = (value, obj) => {
    setsubjectid(null)
    setclassid(null)
    setClassName(null)
    setchapterid(null)
    setSearchChapter('')
    setTopicId(null)
    setTopicName(null)
    updateStdList([])
    if (value) {
      setSubjectName(obj?.label?.props?.children?.props?.children)
    }
  }

  const handleSearchChapter = (value) => {
    setTopicId(null)
    setTopicName(null)
    if (!value) {
      setSearchChapter('')
    }
    else {
      setSearchChapter(value)
      setChapterLoader(true)
    }
    fetchAdminChapterFilter(value, classid, subjectid, () => setChapterLoader(false))

  };


  const onSelect = (value, obj) => {
    setSubjectName(obj?.label?.props?.children?.props?.children)
    setsubjectid(value)
    setClassLoader(true)
    fetchClasslist(value, '', () => setClassLoader(false))
    fetchAdminChapterFilter('', classid, value)
    setchapterarray([...getChaptersList])

  };

  const onSelectChapter = (value, obj) => {
    setTopicId(null)
    setTopicName(null)
    updateFilterTopic([])
    setSearchChapter(obj?.label?.props?.children?.props?.children)
    setchapter(obj?.label?.props?.children?.props?.children)
    setchapterid(value)
    setTopicLoader(true)
    fetchFilterTopic(subjectid, classid, value, () => setTopicLoader(false))

  };

  const handleChangeChapter = (value, obj) => {
    setchapterid(null)
    setchapter('')
    setSearchChapter('')
    setTopicId(null)
    setTopicName(null)
    updateFilterTopic([])
    if (value) {
      setSearchChapter(obj?.label?.props?.children?.props?.children)
      setchapter(obj?.label?.props?.children?.props?.children)
    }
  }

  const onSelectTopic = (value, obj) => {
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      let object = {
        chapterId: chapterid,
        topicName: obj?.children[1]
      }
      setTopicName(null)
      postCreateTopic(object, () => setCreateLoader(false), successCallbackTopic)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const successCallbackTopic = () => {
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleChangeTopic = (value, obj) => {
    // console.log(value, obj)
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      setTopicName(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
  }

  const handleSearchTopic = (value) => {
    // console.log(value)
    if (value && value.trim() != '') {
      setTopicName(value)
      let topic;
      topic = topicList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
      setTopicListToShow(topic)
      setTopicId(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
      setTopicListToShow(topicList)
    }
  }

  const successhandler = async () => {
    if (subjectid && chapterid && inputvalue && classid && chapter && link) {
      var objectToUpload = {
        subjectId: subjectid,
        chapterId: parseInt(chapterid),
        title: inputvalue,
        standardId: parseInt(classid),
        type,
        link: link,
        chapterName: chapter,
        topicId: topicId,
      }

      if (type == 'lecture-notes' && contentPage) {
        setTabKey('1')
        updateSetType(type);
      }
      else if (type == 'video' && contentPage) {
        setTabKey('2')
        updateSetType(type);
      }
      else if (type == 'question-bank' && contentPage) {
        setTabKey('3')
        updateSetType(type);
      }
      setProgressCheck(true)
      setSubmitLoader(true)
      uploadlecturenotes(objectToUpload, () => setSubmitLoader(false), successCallback, setProgress)
      if (subjectid && chapterid && inputvalue && classid && chapter && subjectname && className) {
        var selectedUploadObject = {
          subjectId: subjectid,
          subjectname: subjectname,
          classid: parseInt(classid),
          className: className,
          chapterid: parseInt(chapterid),
          chapterName: chapter,
          topicId: parseInt(topicId),
          topicName: topicName,
          title: inputvalue
        }

        setUploadContent(selectedUploadObject)
      }

    }
  }

  const successCallback = () => {
    setlink(null)
    setchapterid(null)
    setlink(null)
    setSearchChapter('')
    setchapter('')
    setinputvalue('')
    setProgressCheck(false)
    setTopicListToShow([])
    setmodevisible(false)
    setmodel(true)
  }


  const handleCancel = () => {
    setchapterid(null)
    setlink(null)
    setSearchChapter('')
    setchapter('')
    setinputvalue('')
    setTopicListToShow([])
    setProgressCheck(false)
    setCheckedVideo(false)
    setCheckedQuestion(false)
    setCheckedLecture(false)
    setmodevisible(false);
  };

  return (
    <Modal className='rounded-border' width='60%'
      visible={ismodelvisible} footer={null} closable={!progressCheck}
      onCancel={handleCancel} maskClosable={false}
    >
      <div className='lecturenotestitle' style={{ width: '100%', textAlign: 'center', margin: '0px', padding: '0px' }}>
        <p style={{ margin: '0px' }}> Upload content for digital library</p>
      </div>

      <div className='lecturnotesdescription' style={{ width: '100%', textAlign: 'center' }}>
        <p style={{ margin: '0px', padding: '0px' }}> Kindly attach the file and fill in the informations below</p>
      </div>

      <div style={{ margin: '7%' }}>
        <div style={{ width: '100%' }}>        
          <div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <div>
               <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Video link</p>
              </div>
            <div style={{ marginBottom: '1px', fontSize: '12px', color: '#FF414D', font: 'normal normal bold 12px/16px Roboto' }}>
            { link === null || link === "" || link?.match(regExp)  ? null : (<>Invalid Youtube Link</>)}
            </div>
            </div>
            <Input onChange={linkedchanged} value={link}></Input>
          </div>
          <p style={{ fontSize: '10px', font: 'normal normal normal 10px/26px Roboto', opacity: '1', color: '#AEAEAE', marginTop: '2%' }}>Example: http://www.websitename.com/watch?v=2Qsg3rQcfIE</p>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            link && link?.match(regExp)?
            <YTPlayer keyboardControls={true} videoUrl={link}/>
              // <ReactPlayer
              //   style={{ marginTop: 30 }}
              //   height='20%'
              //   width='60%'
              //   url={link}
              //   controls={true}
              // />
              : null
          }
        </div>

        <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: '48%', padding: '2%' }}>
            <div className='generaltwocomponent'>
              <div className='full-width'>
                <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Subject</p>
                <AutoComplete allowClear={subjectname ? true : false}
                  dropdownMatchSelectWidth={252}
                  options={options}
                  onSelect={onSelect}
                  onSearch={handleSearch}
                  onChange={handleChangeSub}
                  value={subjectname}
                  className='autocompletetoupload'
                  loading={subjectLoder}
                  disabled={progressCheck}
                >
                  <Input size="middle"
                    style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }}
                    placeholder="Select subject" />
                </AutoComplete>
              </div>
            </div>
            <div className='full-width' style={{ height: '100%' }}>
              <div className='generaltwocomponent' style={{ width: '100%' }}><p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Chapter</p>
                <div style={{ width: '100%' }}>
                  <AutoComplete
                    allowClear={searchChapter ? true : false}
                    dropdownMatchSelectWidth={252}
                    className='autocompletetoupload'
                    disabled={classid && subjectid && !progressCheck ? false : true}
                    options={optionsChapter}
                    value={searchChapter}
                    onChange={handleChangeChapter}
                    onSelect={onSelectChapter}
                    onSearch={handleSearchChapter}
                    loading={chapterLoader}
                  >
                    <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Search Chapter" />
                  </AutoComplete>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '48%', padding: '2%' }}>
            <div className='generaltwocomponent'>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Class</p>
              <div className='udloadFilesDropdown' >
                <Select
                  allowClear
                  defaultActiveFirstOption={false}
                  notFoundContent={null}
                  size="middle"
                  showSearch
                  value={className}
                  style={{
                    width: "100%",
                    backgroundColor: "#FAFAFA",
                  }}
                  bordered={false}
                  placeholder={
                    <SubHeading color="#AEAEAE">
                      Select class
                    </SubHeading>
                  }
                  loading={classLoader}
                  disabled={subjectid && !progressCheck ? false : true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children[0].indexOf(input) >= 0
                  }
                  onChange={(value, obj) => {
                    if (value) {
                      setclassid(value)
                      setClassName(obj?.children[0] + obj?.children[1] + obj?.children[2])
                      setChapterLoader(true)
                      fetchAdminChapterFilter('', value, subjectid, () => setChapterLoader(false))
                      setchapterarray([...getChaptersList])
                      updateAdminChapterInfo([])
                      setchapterid(null)
                      setchapter('')
                      setSearchChapter('')
                      setTopicId(null)
                      setTopicName(null)
                    }
                    else {
                      setclassid(null)
                      setClassName(null)
                      setchapterid(null)
                      setchapter('')
                      setSearchChapter('')
                      setTopicId(null)
                      setTopicName(null)
                      updateAdminChapterInfo([])
                    }

                  }}
                >
                  {
                    getStdList?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.std} - {item.section}
                      </Select.Option>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Topic</p>
              <Select
                allowClear
                defaultActiveFirstOption={false}
                notFoundContent={null}
                size="middle"
                showSearch
                style={{
                  width: "100%",
                  backgroundColor: "#FAFAFA",
                }}
                bordered={false}
                placeholder={
                  <div color="#AEAEAE">
                    Select Topic
                  </div>
                }
                disabled={chapterid && !progressCheck ? false : true}
                loading={topicLoader}
                value={topicName}
                // optionFilterProp="children"
                // filterOption={(input, option) =>
                //    option.children[0].indexOf(input) >= 0
                // }
                filterOption={false}
                onChange={handleChangeTopic}
                onSelect={onSelectTopic}
                onSearch={handleSearchTopic}
              >
                {topicListToShow?.length != 0 ?
                  topicListToShow?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))
                  :
                  topicName ?
                    <Select.Option value={-1}>Create {topicName}</Select.Option>
                    : null
                }

              </Select>

            </div>


          </div>
        </div>
        <div>
          <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Title</p>
          <div className='uploadfilesinput'>
            <Input size='middle' bordered={false} onChange={inputchanged} value={inputvalue} placeholder='Enter title'
              style={{
                background: '#FAFAFA 0% 0% no-repeat padding-box', borderRadius: '4px',
                opacity: '1'
              }}
              disabled={progressCheck}
            >
            </Input>
          </div>
        </div>
        <div style={{ display: (progressCheck) ? 'inline' : 'none' }}>
          Uploading...
          <Progress type='line' style={{ width: '100%', display: progressCheck ? 'inline' : 'none' }} percent={progress} />
        </div>
        <div style={{ width: '100%', marginTop: '10%' }}>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button style={{ width: "122px", marginRight: "5px", color: '#AEAEAE' }} type="default" onClick={handleCancel}>CANCEL</Button>
            <Button loading={submitLoader} style={{ width: "122px", marginLeft: "5px", }} type="primary" onClick={successhandler} disabled={subjectid && chapterid && inputvalue.trim() != '' && classid && chapter && link && link?.match(regExp) && topicId ? false : true}>PUBLISH</Button>
          </div>
        </div>
      </div>

    </Modal>
  )
}

const mapStateToProps = (state) => {
  const {
    subjects,
    selectedStd,
    selectedSubject,
    getChaptersList,
    getStdList,
    topicList,
    selectedChapter, selectedTopic

  } = state.digitalLibrary;
  return {
    subjects,
    selectedStd,
    selectedSubject,
    getChaptersList,
    getStdList,
    topicList,
    selectedChapter, selectedTopic

  };
};

const mapDispatchToProps = (dispatch) => ({
  subjectFilter: (data, callback) => dispatch(subjectFilter(data, callback)),
  uploadlecturenotes: (data, callback, successCallback, setProgress) => dispatch(uploadlecturenotes(data, callback, successCallback, setProgress)),
  fetchAdminChapterFilter: (value, classid, subjectid, callback) => dispatch(fetchAdminChapterFilter(value, classid, subjectid, callback)),
  setCheckedLecture: (bool) => dispatch(setCheckedLecture(bool)),
  setCheckedVideo: (bool) => dispatch(setCheckedVideo(bool)),
  setCheckedQuestion: (bool) => dispatch(setCheckedQuestion(bool)),
  fetchClasslist: (subid, chapterName, callback) => dispatch(fetchClasslist(subid, chapterName, callback)),
  setUploadContent: (object) => dispatch(setUploadContent(object)),
  updateStdList: (std) => dispatch(updateStdList(std)),
  fetchFilterTopic: (subId, stdId, chapterId, callback) => dispatch(fetchFilterTopic(subId, stdId, chapterId, callback)),
  updateSetType: (type) => dispatch(updateSetType(type)),
  fetchLibraryData: (search, callback) => dispatch(fetchLibraryData(search, callback)),
  setKey: (key) => dispatch(setKey(key)),
  postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
  updateAdminChapterInfo: (data) => dispatch(updateAdminChapterInfo(data)),
  updateFilterTopic: (data) => dispatch(updateFilterTopic(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Videouploadmodel)