import { Modal, AutoComplete } from 'antd';
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react'

const MarketPlaceCrmFilterModal = ({ setIsFilterModal, tab, setCRMPageParamsObj, isFilterModal,storeCRMTableData,
    Types1, Types, filterParam, setFilterParam, setPage, autocompleteSearchData,isValue,setIsValue,
    crmParamsObj, setIsApply, tempFilterParam, setTempFilterParam, getCRMAutocompleteSearch, setApplyButton, setStoreFilter }) => {


    const [autoSearchLoader, setAutoSearchLoader] = useState(false);
    const [autoSearch, setAutoSearch] = useState(null);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        setIsValue(crmParamsObj?.filterByCourseName);
    }, [crmParamsObj]);

    const handleCancel = (e) => {
        // console.log('handleCancel',applyButton);
        e.stopPropagation();
        setIsFilterModal(false);
        setTempFilterParam({ ...filterParam });
        // setCRMPageParamsObj("filterByCourseName", null);
        setIsValue(null)
        setAutoSearch(null);

    };

    const handleAutocompleteSearch = (value) => {
        setAutoSearch(value)
        setAutoSearchLoader(true);
        getCRMAutocompleteSearch(value, tab, () => { setAutoSearchLoader(false) })
    }

    useEffect(()=>{
        getCRMAutocompleteSearch(autoSearch ? autoSearch : '', tab, () => { setAutoSearchLoader(false) })
    },[])


    const searchIcon = require('../../Assets/crmOverview/Search.png').default

    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '', fontSize: 14, fontWeight: "bold", marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }
    const TypeFilter = () => {

        const handleTypeToggle = (val) => {
            const updatedFilterParam = { ...tempFilterParam };
            if (val === 'All') {
                updatedFilterParam.status = 'All';
            } else {
                if (updatedFilterParam.status === 'All') {
                    updatedFilterParam.status = val;
                } else if (updatedFilterParam.status === val) {
                    updatedFilterParam.status = 'All';
                } else {
                    updatedFilterParam.status = val;
                }
            }
            setTempFilterParam(updatedFilterParam);
        };
        return (
            <div className='' style={{ marginTop: 10 }}>
                <FilterTitleName filterName="Status" />
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {Types?.map((item) => (
                        <div
                            key={item.value}
                            className='sortByFilter'
                            style={{
                                backgroundColor: tempFilterParam?.status === item?.value ? '#D6C8F5' : '#FFFFFF',
                                color: tempFilterParam?.status === item?.value ? '#191919' : '#636363',
                                border: tempFilterParam?.status === item?.value ? 'none' : '1px solid #E6E6E6',
                                fontWeight: tempFilterParam?.status === item?.value ? '650' : 'normal',
                                maxWidth: 160,
                                minWidth: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => handleTypeToggle(item?.value)}
                        >
                            {item?.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const handleTypeToggle = (val) => {
        const allOptions = ['course', 'videoResource', 'testSeries', 'studyMaterial'];
        let updatedType = [...(tempFilterParam?.type || [])];
    

    
        if (val === 'All') {
            if (updatedType.includes('All')) {
                updatedType = updatedType.filter(item => item !== 'All');
            } else {
                updatedType = ['All'];
            }
        } else {
            if (updatedType.includes(val)) {
                updatedType = updatedType.filter(item => item !== val);
            } else {
                updatedType.push(val);
            }
            if (allOptions.every(option => updatedType.includes(option))) {
                updatedType = ['All'];
            } else if (updatedType.includes('All')) {
                updatedType = updatedType.filter(item => item !== 'All');
            }
        }
    
        // If all other options are unselected, select 'All'
        if (updatedType.length === 0) {
            updatedType = ['All'];
        }
    
        setTempFilterParam({ ...tempFilterParam, type: updatedType });
    };
    
    
return (
    <Modal
        centered
        className="modal-round-corner"
        visible={isFilterModal}
        onCancel={handleCancel}
        footer={null}
        title='Filters'
        width={755}
        style={{ position: 'relative' }}
    >
        <div>
            <div>
                <FilterTitleName filterName="Course type" />
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Types1?.map((item) => (
                    <div
                        key={item.value}
                        className='sortByFilter'
                        style={{
                            backgroundColor: tempFilterParam?.type?.includes(item?.value) ? '#D6C8F5' : '#FFFFFF',
                            color: tempFilterParam?.type?.includes(item?.value) ? '#191919' : '#636363',
                            border: tempFilterParam?.type?.includes(item?.value) ? 'none' : '1px solid #E6E6E6',
                            fontWeight: tempFilterParam?.type?.includes(item?.value) ? '650' : 'normal',
                            maxWidth: 130,
                            minWidth: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => handleTypeToggle(item?.value)}
                    >
                        {item?.label}
                    </div>
                ))}
            </div>
        </div>

        <TypeFilter />

        <div style={{ marginTop: 8 }}>
            <FilterTitleName filterName="Course name" />
        </div>

        {(autoSearch == null || autoSearch == '') && (crmParamsObj.filterByCourseName == null || crmParamsObj.filterByCourseName == '') && (isValue=='' || isValue==null)
            ?
            <img src={searchIcon} style={{ position: 'absolute', zIndex: 10, marginLeft: 15, marginTop: 10, height: 15, width: 15 }} />
            :
            <></>
        }

        <AutoComplete
            className="searc-tag-crm serach-card"
            allowClear
            popupClassName="certain-category-search-dropdown"
            // popupMatchSelectWidth={500}
            style={{ marginRight: 20, width: '100%', borderRadius: '17px', marginBottom: 300 }}
            options={autocompleteSearchData?.length > 0 ? autocompleteSearchData.map(value => ({ value })) : []}
            onSearch={(value) => {
                handleAutocompleteSearch(value);
                setDropdownVisible(true);
            }}
            onSelect={(value) => {
                setIsValue(value)
                setPage(1)
                setDropdownVisible(false);
            }}
            placeholder={<div style={{ marginLeft: 26, marginTop: 28 }}>Search by course name</div>}
            open={dropdownVisible} 
            onDropdownVisibleChange={(visible) => {
                if (visible !== dropdownVisible) {
                    setDropdownVisible(visible);
                }
            }}
            onFocus={() => setDropdownVisible(true)}
            onBlur={() => setDropdownVisible(false)}
            onClear={()=>{
                setIsValue('')
            }}
                value={isValue || autoSearch}
                notFoundContent={autocompleteSearchData?.length == 0 ? 'No data' : null}
        />

        <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: '5px' }}></div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <Button
                type='link'
                style={{ color: '#594099', cursor: 'pointer' }}
                onClick={() => {
                    setCRMPageParamsObj('status', 'All');
                    setCRMPageParamsObj('sort', 'newest');
                    setCRMPageParamsObj('filterByCourseName', null);
                    setCRMPageParamsObj('filterByCourseType', ['All']);
                    setFilterParam({ status: 'All', sort: 'newest', type: ['All'] });
                    setTempFilterParam({ status: 'All', sort: 'newest', type: ['All'] });
                    setIsApply(true);
                    setIsFilterModal(false);
                    setAutoSearch(null);
                    setApplyButton(false)
                    setStoreFilter([])
                }}
            >
                RESET
            </Button>
            <Button
                type='primary'
                style={{ borderRadius: 5 }}
                onClick={() => {
                    setApplyButton(true)
                    setIsApply(true);
                    setCRMPageParamsObj('status', tempFilterParam.status);
                    setCRMPageParamsObj('filterByCourseType', tempFilterParam.type);
                    setCRMPageParamsObj('filterByCourseName' , isValue)
                    setIsFilterModal(false);
                    setFilterParam(tempFilterParam);
                    setStoreFilter(tempFilterParam)
                    

                }}
            >
                APPLY
            </Button>
        </div>
    </Modal>
)
}

export default MarketPlaceCrmFilterModal