import { setGsSelectedTab, updateMarketSettings } from 'actions/profileNew'
import { getMarketSettingsData } from 'actions/studentMarketPlace'
import { Checkbox, Modal } from 'antd'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function CourseTypesToggleModal({ isCourseTypeToggleModalVisible, setIsCourseTypeToggleModalVisible, setGsSelectedTab, marketSettingData, updateMarketSettings, togglePageLoader, getCourseDashboardData, getMarketSettingsData ,getCoursePieChartData, user}) {
    const handleCancel = () => {
        setIsCourseTypeToggleModalVisible(false)
    }
    const history = useHistory();
    const marketPlaceChecks = ['Live courses', 'Video courses', 'Online test series', 'Study materials'];
    const [checkedItems, setCheckedItems] = useState(['Live courses', 'Video courses', 'Online test series', 'Study materials']);
    const [saveChangesEnabled, setSaveChangesEnabled] = useState(false);
    const [initalCheckBoxStatus, toggleInitialCheckBoxStatus] = useState([marketSettingData?.liveCourseSelected, marketSettingData?.videoResourceSelected, marketSettingData?.onlineTestSelected, marketSettingData?.studyMaterialSelected])
    const [saveLoader, setSaveLoader] = useState(false)
    useEffect(() => {
        getMarketSettingsData(true, () => { })
        setSaveChangesEnabled(false);
    }, []);

    useEffect(() => {
        let arr = ['Live courses']
        if (marketSettingData?.onlineTestSelected) {
            arr?.push('Online test series')
        }
        if (marketSettingData?.videoResourceSelected) {
            arr?.push('Video courses')
        }
        if (marketSettingData?.studyMaterialSelected) {
            arr?.push('Study materials')
        }
        setCheckedItems(arr)
        toggleInitialCheckBoxStatus([...arr]);
    }, [marketSettingData?.onlineTestSelected, marketSettingData?.liveCourseSelected, marketSettingData?.videoResourceSelected, marketSettingData?.studyMaterialSelected])

    const handleCheckboxChange = (item) => {
        let array = [];
        // if (mPlaceSwitch && item === 'Live courses') {
        //     setCheckedItems([...checkedItems, item]);
        //     return;
        // }
        if (checkedItems?.includes(item)) {
            array = checkedItems?.filter((checkedItem) => checkedItem !== item)
        } else {
            array = [...checkedItems, item]
        }
        setCheckedItems(array);

        let isEqual = true;
        if (array?.length == initalCheckBoxStatus?.length) {
            for (let i = 0; i < array?.length; i++) {
                if (!initalCheckBoxStatus?.includes(array[i])) {
                    isEqual = false;
                    break;
                }
            }
        } else {
            isEqual = false;
        }
        setSaveChangesEnabled(!isEqual);
    };

    const handleSectionSaveChanges = () => {
        setSaveLoader(true)
        const params = {
            liveCourseSelected: checkedItems?.includes('Live courses') ? true : false,
            videoResourceSelected: checkedItems?.includes('Video courses') ? true : false,
            onlineTestSelected: checkedItems?.includes('Online test series') ? true : false,
            studyMaterialSelected: checkedItems?.includes('Study materials') ? true : false,
        };
        updateMarketSettings({ settingData: params }, () => { setSaveLoader(false) }, () => { setIsCourseTypeToggleModalVisible(false); getMarketSettingsData(true, () => { }); togglePageLoader(true); getCoursePieChartData(()=>{}); getCourseDashboardData(() => { togglePageLoader(false) }) });
    }

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isCourseTypeToggleModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <div>
                <div className='r-c-sb m-t-15'>
                    <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Course types</div>
                    {user?.privilage?.includes("SuperAdmin") ? <div style={{ color: "#594099", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setGsSelectedTab("Marketplace"); history.push({ pathname: "/general-settings", state: { key: "fromCourse" } }) }}> MARKETPLACE SETTINGS</div>:<></>}
                </div>
                <div className='m-t-10' style={{ color: "#636363" }}>Select the types of courses you would like to create and sell through the platform.</div>
                <div className='m-t-20' style={{ display: "flex", flexWrap: "wrap" }}>
                    {marketPlaceChecks?.map((item, index) => (
                        <div key={index} style={{ marginBottom: '20px', display: 'flex', width: "50%" }}>
                            <Checkbox disabled={item === 'Live courses'} checked={checkedItems?.includes(item)} onChange={() => handleCheckboxChange(item)}>
                                <span style={{color:"#191919"}}>{item}</span>
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <div className='r-c-c-c'>
                    <Button onClick={() => {
                        handleSectionSaveChanges();
                    }} loading={saveLoader} type='link' disabled={!saveChangesEnabled} style={{ opacity: !saveChangesEnabled ? 0.5 : 1 }}>SAVE CHANGES</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { marketSettingData } = state.studentMarketPlace;
    const { user } = state.session;
    return {
        marketSettingData, user
    }
}

const mapDispatchToProps = (dispatch) => ({
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    getMarketSettingsData: (all, callBack) => dispatch(getMarketSettingsData(all, callBack)),
    updateMarketSettings: (params, callback, successCallback) => dispatch(updateMarketSettings(params, callback, successCallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseTypesToggleModal);
// export default CourseTypesToggleModal