// import firebase from 'firebase'
import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"


const firebaseConfig = {
    apiKey: "AIzaSyANNuGsbyjTzqrtYc65aRapQ1fkfaVh0aI",
    authDomain: "aulas-bf253.firebaseapp.com",
    projectId: "aulas-bf253",
    storageBucket: "aulas-bf253.appspot.com",
    messagingSenderId: "1070296453855",
    appId: "1:1070296453855:web:a2a570c79002d2571e0f6a",
    measurementId: "G-WFFZ1K1PK6"
  };

const firebaseApp= initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp);
export default firebaseApp