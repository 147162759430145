import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal, Select } from 'antd';
import { useHistory } from 'react-router';
import AssignLocModal from './assignLocModal';
import { connect } from 'react-redux';
import { getTeachersGeofenceData, searchFilter,deallocateTeachersGeofence, roleFilter, getTeachersGeofenceLocations, storeGeoFenceUserId, getAllStudentStandards, storeSelectedClass} from 'actions/attendanceNew';
import { Api } from '../../services';
import PageDataLoader from 'components/pageDataLoader';
import GeoFenceCard from './geoFenceCard';
import { NodataCard } from 'pages/Test';
import DropDownCheckBox from './dropDownCheckBox';
import DropDownCheckBoxWithStudent from './dropDownCheckBoxStudent';
import EditGeoFenceModal from './Modals/editGeoFence'
import { InputSearch } from 'components/customInputs';
const { Search } = Input;


const TableCard = ({
  serialNO,
  teacherImage,
  teacherName,
  userLabel,
  teacherGlobalTag,
  teacherLocationTag,
  teachersGeofenceDataContainer,
  index,
  setCheckedArray,
  checkedArray,
  deallocateTeachersGeofence,
  getTeachersGeofenceData,
  togglePageLoader,
  teachersGeofenceLocDataContainer,
  getTeachersGeofenceLocations,
  userId,
  storeGeoFenceUserId,
  disableTemporary,
  setDisableTemporary
}) => {

  const [isEditModalVisible,setIsEditModalVisible] = useState(false);
  
function onChange(e) {
  let temp=checkedArray;
  let temp1=disableTemporary
  console.log("abc",teachersGeofenceDataContainer)
  if(temp.includes(teachersGeofenceDataContainer[index].userId)){
    temp.splice(temp.indexOf(teachersGeofenceDataContainer[index].userId),1)
    const indexToRemove = temp1.findIndex(item => item?.userId === teachersGeofenceDataContainer[index].userId);
    if (indexToRemove !== -1) {
      temp1.splice(indexToRemove, 1);
    }
  }else{
    temp.push(teachersGeofenceDataContainer[index]?.userId)
    temp1.push({
      userId : teachersGeofenceDataContainer[index]?.userId,
      location : teachersGeofenceDataContainer[index]?.geoFenceAllocations.some(item => item.title === "Global")})
  }
  setCheckedArray([...temp])
  setDisableTemporary([...temp1])
}

const showModal = () => {
  if(!isEditModalVisible){
    setIsEditModalVisible(true)
  }
  // setIsEditModalVisible(true)
}
    return(
        <div style={{display:"flex",flexDirection:"row",height:"fit-content"}} >
        <div style={{width:"12%",background:`${index%2===0?"#FFFFFF":"#FAFAFA"}`}}>
            <div style={{display:"flex",marginLeft:"6px"}}>
            <div style={{marginLeft:"25px",marginTop:"22px"}}>
            <Checkbox  onChange={onChange} checked={checkedArray?.includes(teachersGeofenceDataContainer[index]?.userId)}/>
            </div>
            <div style={{color:"#191919",fontFamily:"roboto",marginTop:"22px",marginLeft:"15px",fontSize:"15px"}}>{serialNO}</div>
            </div>
        </div>
        <div style={{width:"40%",background:`${index%2===0?"#FFFFFF":"#FAFAFA"}`,borderLeft:"1px solid #e0e0e0",cursor:"pointer"}} onClick={()=>{showModal();storeGeoFenceUserId(userId)}}>
        <div style={{display:"flex",alignItems:"center",marginTop:"15px",marginLeft:"35px",marginBottom:"10px"}}>
        <div><img style={{width:"40px",height:"40px",borderRadius:"50%"}} src={`${Api._s3_url}${teacherImage}`}></img></div>
        <div style={{display:"flex",flexDirection:"column"}}>
          <div className="text-overflow-ellipsis" style={{fontSize:"15px",fontWeight: "bold" ,marginLeft:"20px"}} >{teacherName}</div>
          <div style={{fontSize:"13px",marginLeft:"20px",color:"#636363"}}>{userLabel}</div>
          </div>
          </div>
        </div>
        <div style={{width:"48%",background:`${index%2===0?"#FFFFFF":"#FAFAFA"}`,marginLeft:"3px",borderLeft:"1px solid  #e0e0e0",display:"flex"}}>         
                <div className="flex-wrap " style={{display:"flex",flexDirection:"row",height:"fit-content",}}>
                {teacherLocationTag?.map((item,index)=>(
                   <GeoFenceCard
          teacherLocationTag={teacherLocationTag}
          getTeachersGeofenceData={getTeachersGeofenceData}
          deallocateTeachersGeofence={deallocateTeachersGeofence}
          togglePageLoader={togglePageLoader}
          item={item}
          index={index}
          />
                ))
                }
                {isEditModalVisible && 
                <EditGeoFenceModal
                isEditModalVisible={isEditModalVisible}
                setIsEditModalVisible={setIsEditModalVisible}
                teacherLocationTag={teacherLocationTag}
                togglePageLoader={togglePageLoader}
                getTeachersGeofenceData={getTeachersGeofenceData}
                getTeachersGeofenceLocations={getTeachersGeofenceLocations}
                teachersGeofenceLocDataContainer={teachersGeofenceLocDataContainer}
                />
                } 
            </div> 
            
        </div>
        
        </div>
    )
}

const GeofenceTable = ({
  teachersGeofenceDataContainer,
  checkedArray,
  setCheckedArray,
  deallocateTeachersGeofence,
  getTeachersGeofenceData,
  togglePageLoader,
  teachersGeofenceLocDataContainer,
  getTeachersGeofenceLocations,
  storeGeoFenceUserId,
  disableTemporary,
  setDisableTemporary
}) => {

  function onSelectAll(e) {
    let temp=[];
    if(e.target.checked){
    for(let i=0;i<teachersGeofenceDataContainer?.length;i++){
      temp.push(teachersGeofenceDataContainer[i]?.userId)
    }
  }
    setCheckedArray([...temp])
  }
    return(
        <div style={{marginTop:"40px",width:"100%",height:"fit-content",border: "1px solid #E6E6E6",borderRadius:"4px",background:"#FFFFFF"}}>
           <div style={{height:"50px",width:"100%",background: "#F6F4FE",borderRadius:" 5px 5px 0px 0px"}}>
               <div style={{display:"flex",flexDirection:"row",}}>
            <div style={{display:"flex",width:"12%"}}>
            <div style={{marginLeft:"30px",marginTop:"14px"}}>
              {console.log("checkedArrayyyyy",checkedArray,checkedArray?.length,teachersGeofenceDataContainer?.length)}
            <Checkbox checked={checkedArray?.length===teachersGeofenceDataContainer?.length && checkedArray?.length!==0} onChange={onSelectAll}/>
            </div>
            <div style={{marginTop:"14px",marginLeft:"15px",fontSize:"15px",color:"#636363",fontFamily:"roboto",}}>SL.NO</div>
            </div>
            <div style={{marginTop:"14px",fontSize:"15px",color:"#636363",fontFamily:"roboto",width:"30%",marginLeft:"40px"}}>NAME</div>
            <div style={{marginTop:"14px",fontSize:"15px",color:"#636363",fontFamily:"roboto",width:"48%",marginLeft:"170px"}}>LOCATION(s)</div>
            </div>
           </div>
          {teachersGeofenceDataContainer?.length!==0?
           <div className="flex-wrap " >
               {teachersGeofenceDataContainer?.map((item,index)=>(
                <TableCard
                serialNO={index+1}
                teacherImage={item.image}
                teacherName={item.name}
                userLabel={item?.label}
                // teacherGlobalTag="Global"
                teacherLocationTag={item.geoFenceAllocations}
                index={index}
                userId={item?.userId}
                storeGeoFenceUserId={storeGeoFenceUserId}
                teachersGeofenceDataContainer={teachersGeofenceDataContainer}
                checkedArray={checkedArray}
                setCheckedArray={setCheckedArray}
                deallocateTeachersGeofence={deallocateTeachersGeofence}
                getTeachersGeofenceData={getTeachersGeofenceData}
                togglePageLoader={togglePageLoader}
                teachersGeofenceLocDataContainer={teachersGeofenceLocDataContainer}
                getTeachersGeofenceLocations={getTeachersGeofenceLocations}
                disableTemporary={disableTemporary}
                setDisableTemporary={setDisableTemporary}
                // checkedList={checkedList}
                // plainOptions={plainOptions}
                />
               ))}
           
           </div>
           :
           <div style={{height:"500px",marginTop:"100px"}}>
           <NodataCard/>
           </div>
               }
        </div>
    )
}


function TeachersGeoFence({ getTeachersGeofenceData, teachersGeofenceDataContainer, searchFilter, usersNameContainer, deallocateTeachersGeofence, roleFilter, teachersGeofenceLocDataContainer, getTeachersGeofenceLocations, storeGeoFenceUserId, roleFilterContainer, getAllStudentStandards, standardsData, storeSelectedClass }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkedArray, setCheckedArray] = useState([])
  const [pageLoader, togglePageLoader] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [disableTemporary,setDisableTemporary] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (roleFilterContainer?.length==0) {
      setIsStudent(true)
    }
    for (let i = 0; i < roleFilterContainer?.length; i++) {
      if(roleFilterContainer?.length==1 && roleFilterContainer?.includes("Student")){
        setIsStudent(true)
      }else{
        setIsStudent(false);
        storeSelectedClass(null)
      }
    }
  }, [JSON.stringify(roleFilterContainer)])

  console.log("roleFilterContainer",roleFilterContainer,isStudent)

  useEffect(() => {
    getAllStudentStandards();
    storeSelectedClass(null)
  }, [])

  useEffect(() => {
    searchFilter(null);
    togglePageLoader(true)
    getTeachersGeofenceData(() => {
      togglePageLoader(false)
    });
  }, [])

  const onSearch = (e) => {
    searchFilter(e);
    togglePageLoader(true)
    getTeachersGeofenceData(() => {
      togglePageLoader(false)
    });
    setCheckedArray([])
  }
  const planFilters = [
    { val: "All", id: 1, label: "All" },
    { val: "Teacher", id: 2, label: "Teachers" },
    { val: "Data Operator", id: 3, label: "Data Operators" },
    { val: "Operation", id: 4, label: "Operations" },
    { val: "Student", id: 5, label: "Students" },
  ];

  const onSelection = () => {
    togglePageLoader(true)
    getTeachersGeofenceData(() => {
      togglePageLoader(false)
    });
    setCheckedArray([])
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div style={{ display: "flex" }}>
          <InputSearch placeholder="Search" style={{ width: "20vw" }} onInputChange={(e)=>{onSearch(e)}} allowClear onClear={()=>{onSearch('')}}/>
          <div style={{ marginLeft: "30px" }}>
            <DropDownCheckBoxWithStudent
              DATA={planFilters}
              placeholder={`Role`}
              type={`Role`}
              className="full-width"
              onSelection={onSelection}
              onDropdownClose={() => { }}
              defaultValue={null}
              roleFilter={roleFilter}
              role={usersNameContainer}
            />
          </div>
          {(isStudent == true && roleFilterContainer.includes('Student') /* && roleFilterContainer.includes('Operation') && roleFilterContainer.includes('Data Operator') */) ?
            <div style={{ marginLeft: "30px" }} id="testHomeDropdown">
              <Select
                style={{ width: 150 }}
                placeholder="Class"
                onChange={(value) => {
                  storeSelectedClass(value);
                  togglePageLoader(true);
                  getTeachersGeofenceData(() => { togglePageLoader(false) });
                  setCheckedArray([]);
                }}
                getPopupContainer={() => document.getElementById("testHomeDropdown")}>
                {standardsData?.map((item) => (
                  <Option value={item?.id}>{item?.name} - {item?.section}</Option>
                ))}
              </Select>
            </div>
            :
            <></>
            }
        </div>
        {checkedArray?.length > 0 ?
          <div>
            <Button type='default' style={{ borderRadius: '16px', color: '#1089FF', fontWeight: "bold", borderColor: "#1089FF", width: "8vw" }}
              onClick={() => { setIsModalVisible(true) }}>ASSIGN</Button>

            {isModalVisible && (
              <AssignLocModal
                unCheckArray={setCheckedArray}
                checkedUserIds={checkedArray}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                getTeachersGeofenceData={getTeachersGeofenceData}
                pageLoader={pageLoader}
                togglePageLoader={togglePageLoader}
                disableTemporary={disableTemporary}
              />
            )}
          </div> : null}
      </div>
      <GeofenceTable
        teachersGeofenceDataContainer={teachersGeofenceDataContainer}
        checkedArray={checkedArray}
        setCheckedArray={setCheckedArray}
        deallocateTeachersGeofence={deallocateTeachersGeofence}
        getTeachersGeofenceData={getTeachersGeofenceData}
        togglePageLoader={togglePageLoader}
        teachersGeofenceLocDataContainer={teachersGeofenceLocDataContainer}
        getTeachersGeofenceLocations={getTeachersGeofenceLocations}
        storeGeoFenceUserId={storeGeoFenceUserId} 
        disableTemporary={disableTemporary}
        setDisableTemporary={setDisableTemporary}
        />
      {/* <PageDataLoader visible={pageLoader} /> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  const {teachersGeofenceDataContainer,usersNameContainer,teachersGeofenceLocDataContainer,roleFilterContainer,standardsData} =
    state.attendanceNew;
  return {
    teachersGeofenceDataContainer,usersNameContainer,teachersGeofenceLocDataContainer,roleFilterContainer,standardsData
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTeachersGeofenceData:(callback)=>(dispatch(getTeachersGeofenceData(callback))),
  searchFilter:(value)=>dispatch(searchFilter(value)),
  roleFilter:(val)=>dispatch(roleFilter(val)),
  getTeachersGeofenceLocations:(search,callBack)=>dispatch(getTeachersGeofenceLocations(search,callBack)),
  storeGeoFenceUserId:(val)=>dispatch(storeGeoFenceUserId(val)),
  getAllStudentStandards:()=>dispatch(getAllStudentStandards()),
  storeSelectedClass:(val)=>dispatch(storeSelectedClass(val))
  // deallocateTeachersGeofence:(allocatedLoc,callback)=>dispatch(deallocateTeachersGeofence(allocatedLoc,callback)),
  // allocateTeachersGeofence:(checkedArray)=>(dispatch(allocateTeachersGeofence(checkedArray))),
  });

export default connect(mapStateToProps, mapDispatchToProps)(TeachersGeoFence);

// export default TeachersGeoFence