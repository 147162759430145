import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

const TrafficGraph = ({storeTrafficData}) => {

    const data =storeTrafficData ? storeTrafficData?.map((item)=>({
        'hour':item.day ? item?.day : item?.hour,'count':item.count
    })):{}
  const config = {
    data,
    xField: 'hour',
    yField: 'count',
    color:'#9464F9',
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
    minColumnWidth: 20,
    maxColumnWidth: 20,
  };
  return <Column {...config} />;
};

export default TrafficGraph;

