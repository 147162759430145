import React from 'react';
import {connect} from 'react-redux';
import AutoComplete from 'antd/lib/auto-complete';
import DatePicker from 'antd/lib/date-picker';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
import Select from 'antd/lib/select';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Tag from 'antd/lib/tag';
import 'antd/lib/auto-complete/style/index';
import 'antd/lib/date-picker/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import 'antd/lib/input-number/style/index';
import 'antd/lib/tag/style/index';
import {  FiChevronDown } from "react-icons/fi";
import Color from '../../../services/Color'
import '../styles.css';
import moment from 'moment';
import {
 toggleAssignmentModal, handleAssignmentInput, createNewAssignment, searchChapter,
 updateSearchedChapters
} from '../../../actions/assignment';
import { fetchClasslist, searchUser } from '../../../actions/calendar';
import {searchSubject} from '../../../actions/classRoom';

const { Option } = Select;


const ModalDropdown = ({title, overlay, value, end, dt}) => (
  <div className='flex-1' style={{marginRight: end ? end === true ? 0 : end : 20}}>
    <div className='text-xs'>
      {title}
    </div>
    <Dropdown
      overlay={overlay}
      trigger={['click']}
      >
      <div className='assignmentDropdown text-xmd'>
        <div className='text-center flex-1'>
          {value}
        </div>
        <FiChevronDown className='color-purple m-r-5p'/>
      </div>
    </Dropdown>
  </div>
);


function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}




class AssignmentUpdateModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      chapterQuery: ""

    }
  }

  disabledResultDate = (current) => {
    // Can not select days before today and today
    const {postParams} = this.props;
    if(postParams.deadlineDate){
      return current < moment(postParams.deadlineDate, "YYYY-MM-DD").endOf('day');
    }else{
      return current < moment().endOf('day');
    }
    // return testParams.testDate ? current & current < moment(testParams.testDate, "YYYY-MM-DD").endOf('day') : current && current < moment().endOf('day');
  }

  handleAddClassToAssignment(std) {
    this.props.handleAssignmentInput(std, 'standardIds');
  }


  handleTeacherSelect(teacher, obj){
    if(!obj || obj.id === -1) {
      return console.log('return');
    };
    this.props.handleAssignmentInput(obj.id,'teacherId');
  }

  handleChapterSelect(chapter, obj){
    if(!obj || obj.id === -1) {
      return console.log('return');
    };
    const {chapterIds, chapterNames} = this.props.postParams;
    if(!chapterNames.includes(chapter)){
      chapterNames.push(chapter);
      chapterIds.push(obj.id)
      // this.props.handleAssignmentInput(chapterNames,'chapterNames');
      // this.props.handleAssignmentInput(chapterIds,'chapterIds');
    }
    this.setState({chapterQuery: ""})
  }

  removeChapterFormArray(e, index){
    e.preventDefault();
    const {chapterIds, chapterNames} = this.props.postParams;
    chapterNames.splice(index,1);
    chapterIds.splice(index,1);
    this.props.handleAssignmentInput([...chapterNames],'chapterNames');
    this.props.handleAssignmentInput([...chapterIds],'chapterIds');
  }

  handleDateChange = (date, type) => {
    if (!date) return;
    if (type === 'submit') {
      this.props.handleAssignmentInput(moment(date._d).format('YYYY-MM-DD'),'deadlineDate');
    }
    if (type === 'result') {
      this.props.handleAssignmentInput(moment(date._d).format('YYYY-MM-DD'),'resultDueDate');
    }
  }

  handleSubjectSelect(subject, obj) {
    if(!obj || obj.id === -1) {
      return console.log('return');
    }
    this.props.handleAssignmentInput([],'standardIds');
    this.props.handleAssignmentInput(subject,'selectedSubject');
    this.props.handleAssignmentInput(obj.id,'subjectId');
    this.props.fetchClasslist(obj.id)
  }

  async handleSubjectSearch (query){
    const response = await this.props.searchSubject(query);
    this.props.handleAssignmentInput(-1,'subjectId');
    return response;
  }

  closeModal(){
    this.props.toggleAssignmentModal(false)
    this.props.handleAssignmentInput([],'standardIds');
    this.props.handleAssignmentInput(-1,'id');
    this.props.handleAssignmentInput("",'title');
    this.props.handleAssignmentInput("",'selectedSubject');
    this.props.handleAssignmentInput(-1,'subjectId');
    this.props.handleAssignmentInput("",'selectedTeacher');
    this.props.handleAssignmentInput(null,'teacherId');
    this.props.handleAssignmentInput("Ungraded",'gradingType');
    this.props.handleAssignmentInput(null,'deadlineDate');
    this.props.handleAssignmentInput(null,'resultDueDate');
    this.props.handleAssignmentInput("",'instructions');
    this.props.handleAssignmentInput([],'chapterNames');
    this.props.handleAssignmentInput([],'chapterIds');
    this.props.handleAssignmentInput(null,'index');
    this.props.handleAssignmentInput(null,'cloneAssignmentId');
    this.props.updateSearchedChapters([]);

    
  }

  gradeMenu = () => (
    <Menu className='assignmentddlmenu' >
      <Menu.Item key="0">
        <div style={{color: Color.black}} onClick={() => this.props.handleAssignmentInput('Graded', 'gradingType')}>
          Graded
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div style={{color: Color.black}}  onClick={() => this.props.handleAssignmentInput('Ungraded', 'gradingType')}>
          Ungraded
        </div>
      </Menu.Item>
    </Menu>
  );

  async saveClick(){
    const {user} = this.props;
    const params = this.props.postParams;
    params.author = {name: this.props.postParams.selectedTeacher}
    if((!params.teacherId) && (user.access.includes("assignment-teacher") && !(user.access.includes("assignment-admin") || user.access.includes("assignment-superadmin")))){
      params.teacherId = user.id;
      params.author = {name: user.name}
    }
    params.sub = this.props.postParams.selectedSubject;
    let nextPage = null;
    if(!params.id){
      nextPage = user.access.includes('assignment-teacher') ? 'assignment-teacher' :
                      user.access.includes('assignment-dataoperator') ? 'assignment-dataoperator' : null
    }
    this.props.updateSearchedChapters([])
    await this.props.createNewAssignment(params, this.props.history, nextPage);
  }

  handleSubjectChange(value){
    this.props.handleAssignmentInput(value, 'selectedSubject');
    if(!value || value === ""){
      this.props.handleAssignmentInput([], 'standardIds');
      this.props.handleAssignmentInput([], 'chapterNames');
      this.props.handleAssignmentInput([], 'chapterIds');
      this.props.updateSearchedChapters([]);

    }
  }


  render(){
      const { user, assignmentLoader, teachers, subClasses, searchedChaptersList,
      subjectsFound, searchLoader,togglePostLoader, showAssignmentModal, postParams } = this.props;
      return (
        <Modal
          visible={showAssignmentModal}
          onCancel={() => this.closeModal()}
          maskClosable={false}
          title={
            <div className='color-purple text-xmd'>
              Assignment Details
            </div>
          }
          footer={[
            <div className = 'r-c-c'>
              <Button
                loading={false}
                style={{backgroundColor: Color.midGrey, color: Color.black, borderRadius: 10}}
                onClick={() => this.closeModal()}
              >
                Discard
              </Button>
              <Button
                type='primary'
                loading={assignmentLoader}
                style={{borderRadius: 10}}
                onClick={() => this.saveClick()}
                >
                {postParams.id ? 'Update Details':'Save & Next'}
              </Button>


            </div>,
          ]}
        >
        <div className='m-t-10 flex-1'>
          <div className='dark-grey m-b-5'>Title</div>
          <input
            value={postParams.title}
            onChange={(e) => this.props.handleAssignmentInput(e.target.value, 'title')}
            type='text'
            style={{width: '100%', marginBottom: 20, color: Color.black}}
            className='flex-1 assignmentDropdown text-xmd border-0 p-l-10'
          />
        </div>
        <div className='r-c-sb'>
          <div>
            <div className='dark-grey m-b-5'>Subject</div>
            <div
              className='r-c-sb'
              style={{backgroundColor:'#F9F9F9', padding: 5, borderRadius:5}}
              >
              <AutoComplete
                value={postParams ? postParams.selectedSubject : ''}
                onChange={(value) =>
                  this.handleSubjectChange(value)
                }
                options={subjectsFound}
                style={{
                  border:'none',
                  color:Color.primary,
                  fontSize:16,
                  fontWeight:600,
                  backgroundColor: '#EEEEEE',
                }}
                disabled={postParams && postParams.cloneAssignmentId }
                onSelect={(value, obj) => this.handleSubjectSelect(value, obj)}
                onSearch={(query) => this.handleSubjectSearch(query)}
                >
                <Input.Search
                  style={{ border:'none', borderRadius:5, height: 32}}
                  placeholder="Search subject..."
                  loading={searchLoader}
                  allowClear
                />
              </AutoComplete>
            </div>
          </div>
          {!(user.access.includes('assignment-teacher') && !( user.access.includes('assignment-superadmin') || user.access.includes('assignment-admin') || user.access.includes('assignment-dataoperator')))
          &&
          <div>
            <div className='dark-grey m-b-5'>Teacher</div>
            <div
              className='r-c-sb'
              style={{
                backgroundColor:'#F9F9F9',
                padding:5,
                borderRadius:5
              }}
            >
              <AutoComplete
                options={teachers}
                style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                onSelect={(value, obj) => this.handleTeacherSelect(value, obj)}
                onSearch={(query) => this.props.searchUser(query)}
                value={postParams ? postParams.selectedTeacher : undefined}
                onChange={(value) => this.props.handleAssignmentInput(value,'selectedTeacher')}
                >
                <Input.Search style={{ border:'none', borderRadius:5, height: 32}}  placeholder="Search name..."  loading={togglePostLoader} allowClear/>
              </AutoComplete>
            </div>
          </div>}
        </div>
        
          <div className='flex-1'>
            <div className='dark-grey m-t-5 m-b-5'>Class</div>
            <div
              className='r-c-sb flex-1'
              style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}
              >
              <Select
                className='borderLessSelect bold-600'
                // disabled //{postParams && postParams.subjectId && postParams.subjectId != -1}
                value={postParams ? postParams.standardIds[0] : undefined}
                style={{ flex: 1, width: 100, overflow: 'hidden'}}
                onChange={(value) => this.handleAddClassToAssignment([value])}
                disabled={!postParams || !postParams.subjectId || postParams.subjectId === -1 }
                loading={searchLoader}
                
                >

                {subClasses && subClasses.map((item) =>
                  <Option value={item.id}>
                    {item.std}-{item.section}
                  </Option>
                )}
              </Select>
            </div>
          </div> 
        <div className='r-jsb m-t-10'>
          <ModalDropdown
            title={<div className='dark-grey m-b-5' style={{fontSize: 14}}>Grading Type</div>}
            overlay={this.gradeMenu}
            value={postParams.gradingType}
            />
          <div className='flex-1'>
            <div className='dark-grey m-b-5'>Due Date</div>
            <DatePicker
              size='default'
              style={{
                display: 'block',
                marginRight: 10,
                fontSize: '1em',
                borderRadius: '10px',
                backgroundColor: '#EEEEEE',
                color: '#5448B7',
              }}
              onChange={(date) => this.handleDateChange(date, 'submit')}
              format="DD-MM-YYYY"
              value={postParams.deadlineDate ? moment(postParams.deadlineDate): null}
              disabledDate={disabledDate}
            />
          </div>
          <div className='flex-1'>
          <div className='dark-grey m-b-5'>Result Date</div>
            <DatePicker
              size='default'
              style={{
                display: 'block',
                fontSize: '1em',
                borderRadius: '10px',
                backgroundColor: '#EEEEEE',
                color: '#5448B7',
              }}
              value={postParams.resultDueDate ? moment(postParams.resultDueDate): null}
              onChange={(date) => this.handleDateChange(date, 'result')}
              format="DD-MM-YYYY"
              disabledDate={current => this.disabledResultDate(current)}
            />
          </div>
        </div>
        <div className="r-jsb m-t-10 display-flex">
          <div className="flex-2">
            <div className='dark-grey m-b-5'>Chapter(s)</div>
            <div
              className='r-c-sb m-r-5'
              style={{
                backgroundColor:'#F9F9F9',
                padding:5,
                borderRadius:5
              }}
            >
              <AutoComplete
                options={searchedChaptersList}
                style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                onSelect={(value, obj) => this.handleChapterSelect(value, obj)}
                onSearch={(query) => this.props.searchChapter(query)}
                value={this.state.chapterQuery}
                onChange={(value) => this.setState({chapterQuery: value})}
                disabled={!postParams || !postParams.subjectId || postParams.subjectId === -1 || !postParams.standardIds || !postParams.standardIds.length }
                >
                <Input.Search 
                  style={{ border:'none', borderRadius:5, height: 32}} 
                  placeholder="Search chapter..."  loading={togglePostLoader} allowClear
                 />
              </AutoComplete>
            </div>
            <div> {postParams.chapterNames && postParams.chapterNames.map((chapter, chapIndex) => 
            <Tag key={chapIndex.toString()} style={{borderRadius: 10, margin: 2, marginTop: 5}} onClose={(e) => this.removeChapterFormArray(e,chapIndex)} closable>{chapter}</Tag> 
            )}    </div>
          </div>
          <div className="flex-1">
            <div className='dark-grey m-b-5'>Index</div>
            <div
              style={{
                backgroundColor:'#F9F9F9',
                padding:5,
                borderRadius:5
              }}
            >
              <InputNumber 
              value={postParams.index}
              onChange={(val) => this.props.handleAssignmentInput(val, 'index')}//
              min={1}
              type='number'
              style={{border: 'none', width: "100%"}}
              disabled={!postParams || !postParams.subjectId || postParams.subjectId === -1 || !postParams.standardIds || !postParams.standardIds.length }
              />
            </div>
          </div>
        </div>
        <div className='m-t-10 flex-1'>
        <div className='dark-grey m-b-5'>Instructions</div>
          <textarea
            value={postParams.instructions}
            onChange={(e) => this.props.handleAssignmentInput(e.target.value, 'instructions')}
            type='text'
            style={{width: '100%', color: Color.black}}
            className='flex-1 assignmentDropdown text-xmd border-0 p-l-10'
          />
        </div>
      </Modal>
      )
  }
}

const mapStateToProps = state => {
  const {showAssignmentModal, postParams, assignmentLoader, searchedChaptersList} = state.assignment
  const { teachers, subClasses } = state.calendar;
  const { searchLoader } = state.classRoom;
  const {user} = state.session
  const { subjectsFound } = state.classRoom;
  return {
    teachers, subjectsFound, user, showAssignmentModal, postParams, subClasses, assignmentLoader, searchedChaptersList, searchLoader
  }
};

const mapDispatchToProps = dispatch => ({
  searchUser: (query) => dispatch(searchUser(query)),
  toggleAssignmentModal: (state) => dispatch(toggleAssignmentModal(state)),
  handleAssignmentInput: (val, key) => dispatch(handleAssignmentInput(val, key)),
  fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
  searchSubject: (query) => dispatch(searchSubject(query)),
  createNewAssignment: (params, history, nextPage) => dispatch(createNewAssignment(params, history, nextPage)),
  // toggleAssignmentModal: (state) => dispatch(toggleAssignmentModal(state)),
  searchChapter: (query) => dispatch(searchChapter(query)),
  updateSearchedChapters: chapters => dispatch(updateSearchedChapters(chapters))

});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentUpdateModal);
