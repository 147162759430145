import React, { useState } from 'react';
import { NoData, newCardStyle } from "./courseInformationPage";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserAvatar from 'components/userAvatar';
import { Divider, Typography } from 'antd';
import { IMG_URL } from "../../env.json";
import { FiChevronDown } from 'react-icons/fi';
import { Api } from 'services';

function StudentsEnrolledComp({ specificCourseDataContainer, isStudentsExpandClicked, setIsStudentExpandClicked, setIsBatchesExpandClicked, setWasStudentCardExpanded }) {
    const history = useHistory();
    return (
        <div className={!isStudentsExpandClicked ? 76 : "students-enrolled-expand-height"} style={{ ...newCardStyle, /* height: !isStudentsExpandClicked ? 76 : 517.5, */ marginTop: 20, transition: !isStudentsExpandClicked ? "" : "height 0.2s ease" }}>
            <div className='r-c-sb'>
                <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Students enrolled
                    {isStudentsExpandClicked && specificCourseDataContainer?.students?.length > 0 ? <span className={specificCourseDataContainer?.students?.length > 99 ? 'font-size-12' : ''} style={{ color: "#AEAEAE" }}>{specificCourseDataContainer?.students?.length > 99 ? ' (+99)' : ` (${specificCourseDataContainer?.students?.length})`}
                    </span> : <></>} </div>
                {isStudentsExpandClicked ?
                    <>
                        {specificCourseDataContainer?.students?.length > 0 ? <div className={specificCourseDataContainer?.students?.length > 99 ? 'font-size-12' : ''} style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", cursor: "pointer" }} onClick={() => { history.push("/students-view-all") }}> VIEW ALL</div> : <></>}
                    </>
                    :
                    <FiChevronDown style={{ fontSize: 22, color: "#636363", cursor: "pointer" }} onClick={() => {
                        setIsStudentExpandClicked(!isStudentsExpandClicked);
                        setIsBatchesExpandClicked(false);
                        setWasStudentCardExpanded(true)
                    }} />
                }
            </div>
            {isStudentsExpandClicked ?
                <>
                    {specificCourseDataContainer?.students?.length > 0 ?
                        <div className='scroll-bar-universal m-t-15' style={{ height: 425 }}>
                            {specificCourseDataContainer?.students?.map((item, index) => {
                                return (
                                    <div className='sroll-bar-universal' style={{ display: index <= 5 ? 'view' : "none" }}>
                                        <div className='r-ac' style={{ fontFamily: "roboto" }}>
                                            <div>
                                                <UserAvatar size="4vmin" shape="circle" name={item?.name} img={item?.image == "default.png" ? "" : `${Api._s3_url}${item?.image}`} />
                                            </div>
                                            <div className="m-l-10">
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#19919", fontSize: 15, width: 200 }}>{item.name}</Typography.Text>
                                                <div style={{ color: "#AEAEAE" }}>
                                                    {item?.rollNo && (
                                                        <span>Roll.No {item?.rollNo} </span>
                                                    )}
                                                    {item?.standardName && (
                                                        <Typography.Text ellipsis={{tooltip:true}} style={{width:130, color: "#AEAEAE"}}> • {item?.standardName}</Typography.Text>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {index == 5 ? <></> : <Divider style={{ margin: "10px 0px" }} />}
                                    </div>
                                )
                            })
                            }
                        </div>
                        :
                        <div className='r-c-c m-t-100' style={{ flexDirection: "column", marginBottom: 149 }}>
                            <img className='no-data-img-width' src={NoData} alt=''  />
                            <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto" }}>No data available!</div>
                            <center className='m-t-5' style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                        </div>
                    }
                </>
                :
                <></>
            }
        </div>
    )
}

export default StudentsEnrolledComp