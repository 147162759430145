import React, { useEffect, useState } from 'react'
import TableComponent from "./localComponents/studentTableComponent"
import { Popover, Select, Tooltip, Typography } from "antd";
import { ArrowLeftOutlined, DownloadOutlined, CheckCircleFilled } from "@ant-design/icons";
import Progress from "antd/lib/progress";
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { getStudentOverview, requestedActionData, setActionData, setCardId, storeMonth, studentDownloadReport } from 'actions/attendanceNew';
import moment from 'moment';
import PageDataLoader from 'components/pageDataLoader';
import { Api } from 'services';
import LeaveDetailsModal from './leaveDetailsModal';
import { RequestHistoryCard } from './attendanceTabDetails';
import { NodataCard } from 'pages/Test';
import { SubIcon } from 'components/subIcon';

const warning = require("../../Assets/ic-warning.svg").default
const image = require("../../Assets/user/ic-absent.svg").default;
const image1 = require("../../Assets/user/ic-overall.svg").default;
const image2 = require("../../Assets/user/ic-present.svg").default;
const images = require("../../Assets/settingss/ic-holidays.svg").default;
const averageWorkingHoursImg = require('../../Assets/ic-avg-working-hrs.svg').default;
const totalWorkingHOursImage = require('../../Assets/ic-working-hrs.svg').default;
const halfDay = require('../../Assets/Attendance/Group 13469.svg').default;

const bodyStyle = {
  width: "calc(100% + 40px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflowY: "auto",
};

const AttendanceCard = ({ data, check, Check1 }) => {
  // console.log("AA-data", Check1);
  // const content = (
  //   <div style={{ fontSize: '9px', width: "100px" }}>
  //     This student attendance is {Check1} % lower than minimum requirements`
  //   </div>
  // );
  return (
    <div style={{
      marginTop: "15px",
      marginLeft: "10px",
      borderRadius: "4px",
      borderColor: "#E6E6E6",
      alignItems: "center",
      border: "#E6E6E6 solid 1px",
      width: "32%",
      height: 90,
      backgroundColor: "#FFFFFF",
      flexWrap: 'wrap',
      minWidth: 220
    }}>

      <div style={{ display: "flex", marginTop: "10px", marginLeft: "15px" }}>
        <div>
          <img src={
            data?.[0] === "PresentCount" ? image2 :
              data?.[0] === "AbsentCount" ? image :
                data?.[0] === 'HalfPresentCount' ? halfDay :
                  data?.[0] === "overallPercentage" ? image1 :
                    data?.[0] === 'totalWorkingHoursTillDate' ? totalWorkingHOursImage :
                      data?.[0] === 'averageWorkingHoursTillDate' ? averageWorkingHoursImg
                        :
                        null
          }
          />
        </div>

        <div style={{ marginLeft: '10px' }}>
          <div style={{ fontSize: "21px", color: "#191919", fontWeight: "bold", }}>{data?.[1]}</div>
          <div style={{ fontSize: "11px", color: "#191919", fontWeight: 500, marginRight: "38px", width: "130px" }}>
            {console.log('res', data)}
            {
              data?.[0] === "PresentCount" ? "Present day(s)" :
                data?.[0] === "AbsentCount" ? "Absent day(s)" :
                  data?.[0] === 'HalfPresentCount' ? 'Half day(s)' :
                    data?.[0] === "overallPercentage" ? "Overall percentage" :
                      data?.[0] === 'totalWorkingHoursTillDate' ? 'Total working hours' :
                        data?.[0] === 'averageWorkingHoursTillDate' ? 'Average working hours'
                          :
                          null
            }
          </div>
        </div>
      </div>
      {
        data?.[0] === 'averageWorkingHoursTillDate' && check == true ?
          <center style={{ fontSize: 6, marginTop: -15, marginLeft: 8 }}>
            <Tooltip trigger={'hover'} placement='bottom' title={`Your attendance is ${Check1}% lower than minimum requirements`} overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px" }} >
              <div className='' style={{ color: "#FF414D", fontSize: "10px" }}><img className='p-2' style={{ height: "15px", width: "15px", marginTop: -3 }} src={warning} />Low working hours</div>
            </Tooltip>
          </center>
          : null
      }
    </div>
  )
}

const SubjectCard = (props) => {
  return (
    <div style={{
      marginTop: "20px",
      // marginLeft: "10px",
      marginRight: "10px",
      borderRadius: "6px",
      borderColor: "#E6E6E6",
      alignItems: "center",
      border: "#E6E6E6 solid 1px",
      minWidth: 260,
      height: 120,
      backgroundColor: "#FFFFFF",

      // borderLeft: "5px solid" ,
    }}>
      <div style={{ display: "flex", marginTop: "15px", marginLeft: "20px" }}>
        <div>
          <SubIcon size={40} icon={props?.images} name={props?.subjectname} />
        </div>
        <div style={{ marginTop: "8px", marginRight: "15px" }}>
          <div style={{ fontWeight: "bold", color: "#191919", fontSize: "13px", fontFamily: "Roboto" }}>{props.subjectname}</div>
          <div style={{ color: "#636363", fontSize: "11px", fontFamily: "Roboto" }}>{props.author}</div>
        </div>
      </div>
      <div ><Progress
        percent={props.averageAttendance}
        style={{ width: "85%", marginLeft: "20px" }}
        showInfo={false}
        strokeColor="#594099"
        trailColor="#F6F4FE"
      /></div>
      <div style={{ display: "flex", justifyContent: "space-around" }}><div style={{ color: "#636363", fontSize: "12px", fontFamily: "Roboto" }}>Average attendance</div>
        <div style={{ color: "#636363", fontSize: "12px", marginLeft: "20px" }}>{props.averageAttendance}{'%'}</div></div>
    </div>
  )
}

const OverViewCard = ({ studentOverviewDetail }) => {
  const attendanceCards = Object.entries(studentOverviewDetail?.attendanceTab?.overview ? studentOverviewDetail?.attendanceTab?.overview : {})
  console.log("aaa", attendanceCards)
  return (
    <div style={{
      width: "100%",
      height: "fit-content",
      borderRadius: 6,
      backgroundColor: "#FFFFFF",
      border: "1px solid #E6E6E6",
      padding: 10,
      // marginLeft: 50,
    }}>
      <div style={{ marginLeft: "8px" }}>
        <div style={{ fontSize: "15px", color: "#191919", fontWeight: "bold", fontFamily: "Roboto" }}>Overview</div>
        <div style={{ fontSize: "11px", color: "#636363", fontFamily: "Roboto" }}>
          {studentOverviewDetail?.attendanceTab?.since === 'Invalid date' ?
            null
            :
            <>
              From {(moment(studentOverviewDetail?.attendanceTab?.since).format("DD-MM-YYYY"))}{" "}To{" "}{(moment(studentOverviewDetail?.attendanceTab?.tillDate).format("DD-MM-YYYY"))}
            </>
          }
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: 'wrap' }}>
        <AttendanceCard data={attendanceCards[0]} check={false} Check1={false} />
        <AttendanceCard data={attendanceCards[1]} check={false} Check1={false} />
        <AttendanceCard data={attendanceCards[2]} check={false} Check1={false} />
        <AttendanceCard data={attendanceCards[3]} check={false} Check1={false} />
        <AttendanceCard data={attendanceCards[4]} check={false} Check1={false} />
        <AttendanceCard data={attendanceCards[5]} check={studentOverviewDetail?.attendanceTab?.warning} Check1={studentOverviewDetail?.attendanceTab?.shortFallPercentage} />
      </div>
    </div>
  )
}

const StudentProfileDetail = ({ getStudentOverview, storeStudentId, studentOverviewDetail, studentPageLoader, storeSelectingMonth, storeMonth, studentDownloadReport, user, setCardId, setActionData, requestedActionData }) => {
  // const [month,setMonth]=useState(moment().format('MMMM'))

  useEffect(() => {
    storeMonth(moment().format('MMMM'))
    getStudentOverview()
  }, [])

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }

  const fromStudentProfile = true;

  return (
    <div style={bodyStyle}>
      <div style={{ display: "flex", width: "100%", marginLeft: "20px" }}>
        <div style={{ width: "100%" }} >
          <div style={{ width: "100%" }}>
            <OverViewCard
              storeStudentId={storeStudentId}
              studentOverviewDetail={studentOverviewDetail}
            />
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ color: "#191919", fontSize: "14px", fontWeight: "bold", fontFamily: "Roboto" }}>Subjects</div>
            <div style={{ color: "#AEAEAE", fontSize: "14px", fontWeight: "bold" }}>{" "}({studentOverviewDetail?.attendanceTab?.subjects?.length})</div></div>

          <div className='p-5 scroll-bar-universal' style={{ display: "flex", width: "100%" }} >
            {studentOverviewDetail?.attendanceTab?.subjects?.map((item) => (
              <SubjectCard
                images={item.icon}
                author={item.teacherName}
                subjectname={item.subjectName}
                averageAttendance={item.averageAttendance}
                studentOverviewDetail={studentOverviewDetail}
              />))}
          </div>
          <div style={{ display: "flex", marginTop: "20px", width: "100%", justifyContent: "space-between" }}>
            <div style={{ color: "#191919", fontSize: "14px", fontWeight: "bold", fontFamily: "Roboto", marginTop: "8px" }}>Attendance details</div>
            <div style={{ display: "flex", marginLeft: "340px" }}>
              <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold", marginTop: "5px", fontFamily: "Roboto", cursor: "pointer" }} onClick={() => {
                studentDownloadReport()

              }}><DownloadOutlined style={{ color: "#1089FF", marginTop: 7 }} /> DOWNLOAD REPORT</div>

              <div style={{ marginLeft: "20px", }}>
                <Select style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: "13px", width: "110px" }} onChange={(value) => {
                  storeMonth(value);
                  getStudentOverview()
                }}
                  value={storeSelectingMonth}>
                  <Option value="January">January</Option>
                  <Option value="February">February</Option>
                  <Option value="March">March</Option>
                  <Option value="April">April</Option>
                  <Option value="May">May</Option>
                  <Option value="June">June</Option>
                  <Option value="July">July</Option>
                  <Option value="August">August</Option>
                  <Option value="September">September</Option>
                  <Option value="October">October</Option>
                  <Option value="November">November</Option>
                  <Option value="December">December</Option>
                </Select>
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <TableComponent />
          </div>
          <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold", marginTop: "20px" }}>Attendance Request History</div>
          <div style={{
            width: "100%",
            height: "fit-content",
            background: "#FFFFFF",
            border: "1px solid #E6E6E6",
            borderRadius: "6px",
            marginTop: "20px",
            paddingBottom: "20px"
          }}>
            {studentOverviewDetail?.attendanceTab?.attendanceHistory?.length > 0
              ?
              <div className="flex-wrap " style={{ display: "flex", }}>
                {studentOverviewDetail?.attendanceTab?.attendanceHistory?.map((items) => (
                  <div>
                    <RequestHistoryCard
                      startDate={items?.startDate}
                      leaveStatus={items?.status}
                      reason={items?.reason}
                      leaveCardId={items?.id}
                      createdAtTime={items.createdAt}
                      clockInTime={items.clockIn}
                      clockOutTime={items.clockOut}
                      totalHoursDone={items.totalHours}
                      actionByImage={items.actionBy?.image}
                      actionByName={items.actionBy?.name}
                      actionByRole={items.actionBy?.role}
                      setCardId={setCardId}
                      setActionData={setActionData}
                      requestedActionData={requestedActionData}
                      getStudentOverview={getStudentOverview}
                      fromStudentProfile={fromStudentProfile}
                      // getLeavesTabData={getLeavesTabData}
                      // togglePageLoader={togglePageLoader}
                      requestType={items.requestType}
                      user={user}
                    />
                  </div>
                ))}
              </div>
              :
              <div style={{ marginTop: "40px" }}>
                <NodataCard />
              </div>
            }
          </div>

        </div>
        <PageDataLoader visible={studentPageLoader} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { studentOverviewDetail, storeStudentId, studentPageLoader, storeSelectingMonth } = state.attendanceNew;
  const { user } = state.session
  return { studentOverviewDetail, storeStudentId, studentPageLoader, storeSelectingMonth, user };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentOverview: () => dispatch(getStudentOverview()),
  storeMonth: (val) => dispatch(storeMonth(val)),
  studentDownloadReport: (val) => dispatch(studentDownloadReport(val)),
  setCardId: (id) => dispatch(setCardId(id)),
  setActionData: (val) => dispatch(setActionData(val)),
  requestedActionData: (callback, successCallBack) => dispatch(requestedActionData(callback, successCallBack)),

});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfileDetail);
