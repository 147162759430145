import { handleResendOtpTimer, storePhoneNumber } from 'actions/loginNew';
import { getEnquiryFormCourseListData, getUserMarketPlaceHomeData, postGenerateEmailEnquiryOtp, postGenerateEnquiryOtp, postHomeReachUsForm, postUpdateEnquiryDetails, postVerifyEmailEnquiryOtp, postVerifyEnquiryOtp, setIsOtpVerified } from 'actions/studentMarketPlace';
import { Checkbox, Input, Modal, Radio, Select } from 'antd'
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react'
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { connect } from 'react-redux';
import "./userHomeStyles.css"
import AnimatedTextField from 'components/AnimatedTextField';
import wormLoader from "../../Assets/userMarketPlace/wormLoader.gif"
import AnimatedCustomSelect from 'components/AnimatedSelectComp';
import AnimatedPhoneInput from 'components/AnimatedPhoneInput';
import { parsePhoneNumber } from 'react-phone-number-input';
import AnimatedOTPInput from 'components/AnimatedOtpInput';

function AdmissionEnquiryForm({ isAdmissionModalVisible, setIsAdmissionModalVisible, userMarketPlaceHomeData, storePhoneNumber, timer, postVerifyEnquiryOtp, postGenerateEnquiryOtp, setIsOtpVerified, isOtpVerified, postUpdateEnquiryDetails, phoneNo, handleResendOtpTimer, postGenerateEmailEnquiryOtp, postVerifyEmailEnquiryOtp, from, getUserMarketPlaceHomeData, getEnquiryFormCourseListData, enquiryFormCourseListData }) {
    const [enquiryResponse, setEnquiryResponse] = useState(
        userMarketPlaceHomeData?.defaultEnquiryForm ?
            [
                {
                    "id": -100,
                    "type": "email",
                    "questionContent": {
                        "statement": "Email"
                    },
                    "required": userMarketPlaceHomeData?.emailMandatoryEF,
                    "verify": false,
                    "answer": {
                        "answer": ""
                    }
                },
                {
                    "id": -99,
                    "type": "short",
                    "questionContent": {
                        "statement": "Message"
                    },
                    "required": false,
                    "verify": false,
                    "answer": {
                        "answer": ""
                    }
                }
            ]
            :
            userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.map(question => ({
                ...question,
                answer: {
                    answer: ""
                }
            }))
    );
    const [reachUsParams, setReachUsParams] = useState({
        name: null,
        phone: null,
    })
    const [submitLoader, setSubmitLoader] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
    const [otp, setOtp] = useState('');
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [sendOtpLoader, setSendOtpLoader] = useState(false);
    const [mobileToken, setMobileToken] = useState(null)

    const [emailOtp, setEmailOtp] = useState(Array(enquiryResponse?.length).fill(''));
    const [emailSendOtpLoader, setEmailSendOtpLoader] = useState(Array(enquiryResponse?.length).fill(false));
    const [isEmailSendOtpClicked, setIsEmailSendOtpClicked] = useState(Array(enquiryResponse?.length).fill(false));
    const [emailVerifyLoader, setEmailVerifyLoader] = useState(Array(enquiryResponse?.length).fill(null));
    const [isEmailOtpVerified, setIsEmailOtpVerified] = useState(enquiryResponse?.map(item => (item.type === 'email' && item.verify) ? false : true));
    const [emailToken, setEmailToken] = useState(Array(enquiryResponse?.length)?.fill(null))

    const [customizePhoneOtp, setCustomizePhoneOtp] = useState(Array(enquiryResponse?.length).fill(''));
    const [customizePhoneSendOtpLoader, setCustomizePhoneSendOtpLoader] = useState(Array(enquiryResponse?.length).fill(false));
    const [isCustomizePhoneSendOtpClicked, setIsCustomizePhoneSendOtpClicked] = useState(Array(enquiryResponse?.length).fill(false));
    const [customizePhoneVerifyLoader, setCustomizePhoneVerifyLoader] = useState(Array(enquiryResponse?.length).fill(null));
    const [isCustomizePhoneOtpVerified, setIsCustomizePhoneOtpVerified] = useState(enquiryResponse?.map(item => (item.type === 'phone' && item.verify) ? false : true))
    const [customizePhoneToken, setCustomizePhoneToken] = useState(Array(enquiryResponse?.length).fill(null))

    const clearValues = () => {
        setIsChecked(false);
        storePhoneNumber(null)
        setReachUsParams({
            name: "",
            phone: "",
        });
        setIsOtpVerified(false)
        setIsSendOtpClicked(false);
        setOtp(null);
    }

    useEffect(() => {
        clearValues();
        userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.forEach(question => {
            if (question?.type === "dropdown") {
                question?.questionContent?.options?.forEach(item => {
                    if (item?.courseList) {
                        getEnquiryFormCourseListData();
                    }
                });
            }
        });
    }, []);


    const validateEmail = (emailId) => {
        const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailId?.match(email)) {
            return false
        } else {
            return true
        }
    }

    const handleNameInputChange = (e) => {
        setReachUsParams({ ...reachUsParams, name: e.target.value })
    };

    const handleDefaultInputChange = (e, item) => {
        const newResponse = {
            ...item,
            answer: { answer: e.target.value }
        };
        const existingItemIndex = enquiryResponse?.findIndex(responseItem => responseItem.id === item.id);
        if (existingItemIndex !== -1) {
            const updatedResponse = [...enquiryResponse];
            updatedResponse[existingItemIndex] = newResponse;
            setEnquiryResponse(updatedResponse);
        } else {
            setEnquiryResponse([...enquiryResponse, newResponse]);
        }
    };

    const handleInputChange = (e, item) => {
        let inputValue = e.target.value;
        if (item?.type === "short" && inputValue.length > 256) {
            inputValue = inputValue.slice(0, 256);
        }
        const newResponse = {
            ...item,
            answer: { answer: inputValue }
        };
        const existingItemIndex = enquiryResponse.findIndex(responseItem => responseItem.id === item.id);
        if (existingItemIndex !== -1) {
            const updatedResponse = [...enquiryResponse];
            updatedResponse[existingItemIndex] = newResponse;
            setEnquiryResponse(updatedResponse);
            if (item?.type === "email") {
                setIsEmailSendOtpClicked(prevState => {
                    const newState = [...prevState];
                    newState[existingItemIndex] = false;
                    return newState;
                });
                setEmailOtp(prevState => {
                    const newState = [...prevState];
                    newState[existingItemIndex] = '';
                    return newState;
                });
                setIsEmailOtpVerified(prevState => {
                    const newState = [...prevState];
                    if (item?.verify) {
                        newState[existingItemIndex] = false;
                    } else {
                        newState[existingItemIndex] = true;
                    }
                    return newState;
                });
            }
        } else {
            setEnquiryResponse([...enquiryResponse, newResponse]);
        }
    };

    const handleCustomizePhoneInputChange = (e, item) => {
        let phoneNumber;
        let countryCode
        if (e) {
            phoneNumber = parsePhoneNumber(e);
            if (phoneNumber) {
                countryCode = getCountryCallingCode(phoneNumber?.country);
            } else { }
        }
        const newResponse = {
            ...item,
            answer: { answer: e, cCode: "+" + countryCode },
        };
        const existingItemIndex = enquiryResponse.findIndex(responseItem => responseItem?.id === item?.id);
        if (existingItemIndex !== -1) {
            const updatedResponse = [...enquiryResponse];
            updatedResponse[existingItemIndex] = newResponse;
            setEnquiryResponse(updatedResponse);
            setIsCustomizePhoneSendOtpClicked(prevState => {
                const newState = [...prevState];
                newState[existingItemIndex] = false;
                return newState;
            });
            setCustomizePhoneOtp(prevState => {
                const newState = [...prevState];
                newState[existingItemIndex] = '';
                return newState;
            });
            setIsCustomizePhoneOtpVerified(prevState => {
                const newState = [...prevState];
                if (item?.verify) {
                    newState[existingItemIndex] = false;
                } else {
                    newState[existingItemIndex] = true;
                }
                return newState;
            });
            handleResendOtpTimer(false, "")
        } else {
            setEnquiryResponse([...enquiryResponse, newResponse]);
        }
    }

    const handlePhoneInputChange = (e) => {
        const inputValue = e
        const sanitizedValue = inputValue?.replace(/[^0-9\+]/g, '');    
        
        setReachUsParams({ ...reachUsParams, phone: sanitizedValue });
        storePhoneNumber(sanitizedValue)
        if (sanitizedValue != phoneNo) {
            setIsSendOtpClicked(false)
            handleResendOtpTimer(false, "")
            setOtp('');
            setIsOtpVerified(false)
        }
    }

    const handleSelectChange = (value, item) => {
        const newResponse = {
            ...item,
            answer: { answer: item?.questionContent?.options?.[0]?.courseList ? value : [value] }
        };
        const existingItemIndex = enquiryResponse?.findIndex(responseItem => responseItem.id === item.id);
        if (existingItemIndex !== -1) {
            const updatedResponse = [...enquiryResponse];
            updatedResponse[existingItemIndex] = newResponse;
            setEnquiryResponse(updatedResponse);
        } else {
            setEnquiryResponse([...enquiryResponse, newResponse]);
        }
    };

    const handleCheckboxChange = (checkedValues, item) => {
        const newResponse = {
            ...item,
            answer: { answer: checkedValues }
        };
        const existingItemIndex = enquiryResponse?.findIndex(responseItem => responseItem.id === item.id);
        if (existingItemIndex !== -1) {
            const updatedResponse = [...enquiryResponse];
            updatedResponse[existingItemIndex] = newResponse;
            setEnquiryResponse(updatedResponse);
        } else {
            setEnquiryResponse([...enquiryResponse, newResponse]);
        }
    };

    const getCheckedValues = (item) => {
        const existingItemIndex = enquiryResponse?.findIndex(responseItem => responseItem?.id === item?.id);
        if (existingItemIndex !== -1) {
            return enquiryResponse[existingItemIndex]?.answer?.answer;
        }
        return [];
    };

    const handleMCQChange = (e, item) => {
        const newResponse = {
            ...item,
            answer: { answer: [e.target.value] }
        };
        const existingItemIndex = enquiryResponse?.findIndex(responseItem => responseItem?.id === item?.id);
        if (existingItemIndex !== -1) {
            const updatedResponse = [...enquiryResponse];
            updatedResponse[existingItemIndex] = newResponse;
            setEnquiryResponse(updatedResponse);
        } else {
            setEnquiryResponse([...enquiryResponse, newResponse]);
        }
    };

    let disableSubmit = true;
    if (userMarketPlaceHomeData?.defaultEnquiryForm) {
        if(!userMarketPlaceHomeData?.enquiryData?.verifyPhone){
            disableSubmit = enquiryResponse?.some(item => {
                if ((item?.required && !item?.answer?.answer) || (validateEmail(item?.answer?.answer) && item?.type === "email" && item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked) {
                    return true;
                } else {
                    return false;
                }
            });
        }else {
            disableSubmit = enquiryResponse?.some(item => {
                if ((item?.required && !item?.answer?.answer) || (validateEmail(item?.answer?.answer) && item?.type === "email" && item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isOtpVerified || !isChecked) {
                    return true;
                } else {
                    return false;
                }
            });
        }
    } else if (userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.length == 0) {
        if (!userMarketPlaceHomeData?.enquiryData?.verifyPhone) {
            if (!reachUsParams?.name || !reachUsParams?.phone || !isChecked) {
                disableSubmit = true;
            } else {
                disableSubmit = false;
            }
        } else {
            if (!reachUsParams?.name || !reachUsParams?.phone || !isChecked || !isOtpVerified) {
                disableSubmit = true;
            } else {
                disableSubmit = false;
            }
        }
    } else if (userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.length > 0) {
        if (userMarketPlaceHomeData?.enquiryData?.verifyPhone) {
            disableSubmit = enquiryResponse?.some(item => {
                if (item?.type == "email") {
                    if (item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || (validateEmail(item?.answer?.answer) && item?.type === "email" && item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked || isEmailOtpVerified?.some(verified => !verified) || !isOtpVerified) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (!item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || (validateEmail(item?.answer?.answer) && item?.type === "email" && item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked || !isOtpVerified) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else if (item?.type == "checkbox" || item?.type == "MCQ" || item?.type == "dropdown") {
                    if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isOtpVerified || !isChecked) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (item?.type == "phone") {
                    if (item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked || isCustomizePhoneOtpVerified?.some(verified => !verified) || !isOtpVerified) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (!item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked || !isOtpVerified) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isOtpVerified || !isChecked) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
        }
        else if (!userMarketPlaceHomeData?.enquiryData?.verifyPhone) {
            disableSubmit = enquiryResponse?.some(item => {
                if (item?.type == "email") {
                    if (item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || (validateEmail(item?.answer?.answer) && item?.type === "email" && item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked || isEmailOtpVerified?.some(verified => !verified)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (!item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || (validateEmail(item?.answer?.answer) && item?.type === "email" && item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else if (item?.type == "checkbox" || item?.type == "MCQ" || item?.type == "dropdown") {
                    if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (item?.type == "phone") {
                    if (item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked || isCustomizePhoneOtpVerified?.some(verified => !verified)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (!item?.verify) {
                        if ((item?.required && item?.answer?.answer?.length == 0) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    if ((item?.required && !item?.answer?.answer) || !reachUsParams?.name || !reachUsParams?.phone || !phoneNo || !isChecked) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
        }
    } else {
        disableSubmit = false
    }

    const handleCancel = () => {
        setIsAdmissionModalVisible(false)
        getUserMarketPlaceHomeData(undefined, () => { })
    }

    const CalcHeight = () => {
        if (!userMarketPlaceHomeData?.defaultEnquiryForm) {
            if (userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.length == 0 && !userMarketPlaceHomeData?.enquiryData?.verifyPhone) {
                return 180
            } else if (userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.length == 0 && userMarketPlaceHomeData?.enquiryData?.verifyPhone) {
                return 270
            } else if (userMarketPlaceHomeData?.enquiryData?.enquiryQuestions?.length > 0) {
                return 350
            }
        }
    }

    const handlePhoneOtpOnChange = (e) => {
        setOtp(e.target.value);
        setIsOtpVerified(false);
    }

    const handleEmailOtpOnChange = (e, responseItem, index) => {
        const newOtps = [...emailOtp];
        newOtps[index] = e.target.value;
        setEmailOtp(newOtps);
        const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
        setIsEmailOtpVerified(prevState => {
            const newState = [...prevState];
            newState[currentIndex] = false;
            return newState;
        });
    }

    const handleCustomPhoneOtpOnChange = (e, responseItem, index) => {
        const newOtps = [...customizePhoneOtp];
        newOtps[index] = e.target.value;
        setCustomizePhoneOtp(newOtps);
        const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
        setIsCustomizePhoneOtpVerified(prevState => {
            const newState = [...prevState];
            newState[currentIndex] = false;
            return newState;
        });
    }

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isAdmissionModalVisible}
            onOk={(e) => { handleCancel() }}
            onCancel={(e) => { handleCancel() }}
            header={null}
            footer={null}
        >
            <center style={{ fontSize: 18, color: "#191919", fontWeight: "bold", marginBottom: 20 }}>ADMISSIONS ENQUIRY FORM</center>
            <div className='scroll-bar-universal' style={{ height: !userMarketPlaceHomeData?.defaultEnquiryForm ? CalcHeight() : 350 }}>
                <div className='name-input'>
                    <AnimatedTextField
                        label="Name"
                        value={reachUsParams?.name}
                        handleInputChange={handleNameInputChange}
                        isMandatory={true}
                        inputId="name"
                        isDisabled={from == "settings" ? true : false}
                    />
                </div>
                {!userMarketPlaceHomeData?.enquiryData?.verifyPhone ?
                    <div className='m-t-30' style={{ width: "100%" }}>
                        <div className='mobile-input' style={{ width: "100%" }}>
                            <AnimatedPhoneInput
                                label="Mobile Number"
                                handleInputChange={handlePhoneInputChange}
                                isMandatory={true}
                                inputId="phone1"
                                disabled={from == "settings" ? true : false}
                                value={reachUsParams?.phone || ''}
                            />
                        </div>
                    </div>
                    :
                    <>
                        <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                            <div className='mobile-input'>
                                <AnimatedPhoneInput
                                    label="Mobile Number"
                                    handleInputChange={handlePhoneInputChange}
                                    isMandatory={true}
                                    inputId="phone"
                                    value={reachUsParams?.phone || ''}
                                />
                            </div>
                            {isSendOtpClicked ?
                                <>
                                    {timer == '' && !isOtpVerified ?
                                        <Button
                                            disabled={!reachUsParams?.phone}
                                            type="link"
                                            shape="round"
                                            className="r-c-c bold-bold"
                                            style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}
                                            onClick={() => {
                                                if (!reachUsParams?.phone) { }
                                                else {
                                                    setSendOtpLoader(true);
                                                    storePhoneNumber(reachUsParams?.phone)
                                                    setOtp("");
                                                    let formattedPhoneNumber = phoneNo?.replace(/-|\s/g, "");
                                                    formattedPhoneNumber = "+" + formattedPhoneNumber;
                                                    postGenerateEnquiryOtp(formattedPhoneNumber, () => setSendOtpLoader(false), () => setIsSendOtpClicked(true))
                                                }
                                            }}
                                            loading={sendOtpLoader}
                                        >
                                            RESEND OTP
                                        </Button>
                                        :
                                        <>
                                            {isOtpVerified ?
                                                <></>
                                                :
                                                <div style={{ color: "#FF414D", fontWeight: "bold", marginTop: 30, marginLeft: 10 }}> {`00 : ${timer}`}</div>}
                                        </>
                                    }
                                </>
                                :
                                <Button
                                    disabled={!reachUsParams?.phone}
                                    type="link"
                                    shape="round"
                                    className="r-c-c bold-bold"
                                    style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}
                                    onClick={() => {
                                        if (!reachUsParams?.phone) { }
                                        else {
                                            setSendOtpLoader(true)
                                            storePhoneNumber(reachUsParams?.phone)
                                            let formattedPhoneNumber = phoneNo?.replace(/-|\s/g, "");
                                            formattedPhoneNumber = "+" + formattedPhoneNumber;
                                            postGenerateEnquiryOtp(formattedPhoneNumber, () => setSendOtpLoader(false), () => setIsSendOtpClicked(true))
                                        }
                                    }}
                                    loading={sendOtpLoader}
                                >
                                    SEND OTP
                                </Button>
                            }
                        </div>
                        <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                            <div className='otp-input' >
                                <AnimatedOTPInput
                                    label="Verify Mobile Number"
                                    value={otp || ''}
                                    inputId="phoneVerify"
                                    handleInputChange={handlePhoneOtpOnChange}
                                    disabled={!isSendOtpClicked}
                                    isMandatory={true}
                                />
                            </div>
                            <Button
                                disabled={!otp || otp?.length < 6}
                                type="link"
                                shape="round"
                                className="r-c-c bold-bold"
                                style={{ color: isOtpVerified ? "#00A566" : "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, cursor: (!otp || otp?.length < 6) ? "not-allowed" : isOtpVerified ? "default" : "pointer" }}
                                onClick={() => {
                                    if (isOtpVerified) { }
                                    else {
                                        setVerifyLoader(true);
                                        let formattedPhoneNumber = phoneNo?.replace(/-|\s/g, "");
                                        formattedPhoneNumber = "+" + formattedPhoneNumber;
                                        postVerifyEnquiryOtp(otp, formattedPhoneNumber,
                                            () => setVerifyLoader(false), (res) => { setMobileToken(res?.token); setIsOtpVerified(true) })
                                    }
                                }}
                                loading={verifyLoader}
                            >
                                {isOtpVerified ? "VERIFIED" : "VERIFY OTP"}
                            </Button>
                        </div>
                    </>
                }
                {userMarketPlaceHomeData?.defaultEnquiryForm ?
                    <>
                        {enquiryResponse?.map((item) => {
                            const responseItem = enquiryResponse?.find(response => response.id === item.id);
                            return (
                                <div className='email-input m-t-30' style={{ width: "100%" }}>
                                    <AnimatedTextField
                                        label={item?.questionContent?.statement}
                                        handleInputChange={(e) => handleDefaultInputChange(e, item)}
                                        isMandatory={item?.required}
                                        inputId={item?.id}
                                        value={responseItem?.answer?.answer || ''}
                                    />
                                    {(item?.type == "email" && responseItem?.answer?.answer && validateEmail(responseItem?.answer?.answer)) ? <div style={{ color: "red", fontSize: 12, fontWeight: 600, display: "flex", justifyContent: "flex-end", marginRight: 10 }}>Enter valid email id</div> : <></>}
                                </div>
                            )
                        })}
                    </>
                    :
                    <>
                        {enquiryResponse?.map((item, index) => {
                            const responseItem = enquiryResponse?.find(response => response.id === item.id);
                            return (
                                <>
                                    {item?.type == "email" ?
                                        <>
                                            {item?.type == "email" && !item?.verify ?
                                                <div className='r-jsb m-t-10'>
                                                    <div className='email-input' style={{ width: "100%" }}>
                                                        <AnimatedTextField
                                                            label={item?.questionContent?.statement}
                                                            handleInputChange={(e) => handleInputChange(e, item)}
                                                            isMandatory={item?.required}
                                                            inputId={item?.id}
                                                            value={responseItem?.answer?.answer || ''}
                                                        />
                                                        {responseItem?.answer?.answer && validateEmail(responseItem?.answer?.answer) ? <div style={{ color: "red", fontSize: 12, fontWeight: 600, display: "flex", justifyContent: "flex-end", marginRight: 10 }}>Enter valid email id</div> : <></>}
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                                                        <div className='email-input' style={{ width: item?.type == "email" && item?.verify ? "70%" : "100%" }}>
                                                            <AnimatedTextField
                                                                label={item?.questionContent?.statement}
                                                                handleInputChange={(e) => handleInputChange(e, item)}
                                                                isMandatory={item?.required}
                                                                inputId={item?.id}
                                                                validateEmail={true}
                                                                value={responseItem?.answer?.answer || ''}
                                                            />
                                                            {responseItem?.answer?.answer && validateEmail(responseItem?.answer?.answer) ? <div style={{ color: "red", fontSize: 12, fontWeight: 600, display: "flex", justifyContent: "flex-end", marginRight: 10 }}>Enter valid email id</div> : <></>}
                                                        </div>
                                                        {isEmailSendOtpClicked[index] ?
                                                            <>
                                                                {timer == '' && !isEmailOtpVerified[index] ?
                                                                    <Button
                                                                        disabled={!responseItem?.answer?.answer || validateEmail(responseItem?.answer?.answer)}
                                                                        type="link"
                                                                        shape="round"
                                                                        className="r-c-c bold-bold"
                                                                        style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}
                                                                        onClick={() => {
                                                                            if (!responseItem?.answer?.answer || validateEmail(responseItem?.answer?.answer)) { }
                                                                            else {
                                                                                const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
                                                                                setEmailSendOtpLoader(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[currentIndex] = true;
                                                                                    return newState;
                                                                                });
                                                                                const newOtps = [...emailOtp];
                                                                                newOtps[index] = "";
                                                                                setEmailOtp(newOtps);
                                                                                postGenerateEmailEnquiryOtp(
                                                                                    responseItem?.answer?.answer,
                                                                                    () => {
                                                                                        setEmailSendOtpLoader(prevState => {
                                                                                            const newState = [...prevState];
                                                                                            newState[currentIndex] = false;
                                                                                            return newState;
                                                                                        });
                                                                                    },
                                                                                    () => {
                                                                                        setIsEmailSendOtpClicked(prevState => {
                                                                                            const newState = [...prevState];
                                                                                            newState[currentIndex] = true;
                                                                                            return newState;
                                                                                        });
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                        loading={emailSendOtpLoader[index]}
                                                                    >
                                                                        RESEND OTP
                                                                    </Button>
                                                                    :
                                                                    <>
                                                                        {isEmailOtpVerified[index] ?
                                                                            <></>
                                                                            :
                                                                            <div style={{ color: "#FF414D", fontWeight: "bold", marginTop: 30, marginLeft: 10 }}> {`00 : ${timer}`}</div>}
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <Button
                                                                disabled={!responseItem?.answer?.answer || validateEmail(responseItem?.answer?.answer)}
                                                                type="link"
                                                                shape="round"
                                                                className="r-c-c bold-bold"
                                                                style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}
                                                                onClick={() => {
                                                                    if (!responseItem?.answer?.answer || validateEmail(responseItem?.answer?.answer)) { }
                                                                    else {
                                                                        const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
                                                                        setEmailSendOtpLoader(prevState => {
                                                                            const newState = [...prevState];
                                                                            newState[currentIndex] = true;
                                                                            return newState;
                                                                        });
                                                                        postGenerateEmailEnquiryOtp(
                                                                            responseItem?.answer?.answer,
                                                                            () => {
                                                                                setEmailSendOtpLoader(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[currentIndex] = false;
                                                                                    return newState;
                                                                                });
                                                                            },
                                                                            () => {
                                                                                setIsEmailSendOtpClicked(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[currentIndex] = true;
                                                                                    return newState;
                                                                                });
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                                loading={emailSendOtpLoader[index]}
                                                            >
                                                                SEND OTP
                                                            </Button>
                                                        }
                                                    </div>
                                                    <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                                                        <div className='otp-input' >
                                                            <AnimatedOTPInput
                                                                label="Verify email id"
                                                                value={emailOtp[index] || ""}
                                                                inputId={item?.id ? `${item.id}_customEmail` : "customEmail"}
                                                                handleInputChange={(e) => handleEmailOtpOnChange(e, responseItem, index)}
                                                                disabled={!isEmailSendOtpClicked[index]}
                                                                isMandatory={true}
                                                            />
                                                        </div>
                                                        <Button
                                                            disabled={!emailOtp[index] || emailOtp[index].length < 6}
                                                            type="link"
                                                            shape="round"
                                                            className="r-c-c bold-bold"
                                                            style={{ color: isEmailOtpVerified[index] ? "#00A566" : "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, cursor: (!emailOtp[index] || emailOtp[index].length < 6) ? "not-allowed" : isEmailOtpVerified[index] ? "default" : "pointer" }}
                                                            onClick={() => {
                                                                const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
                                                                if (isEmailOtpVerified[index]) { }
                                                                else {
                                                                    setEmailVerifyLoader(prevState => {
                                                                        const newState = [...prevState];
                                                                        newState[currentIndex] = true;
                                                                        return newState;
                                                                    });
                                                                    postVerifyEmailEnquiryOtp(emailOtp[index], responseItem?.answer?.answer,
                                                                        () => {
                                                                            setEmailVerifyLoader(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[currentIndex] = false;
                                                                                return newState;
                                                                            });
                                                                        },
                                                                        (res) => {
                                                                            setIsEmailOtpVerified(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[currentIndex] = true;
                                                                                return newState;
                                                                            })
                                                                            setEmailToken(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[currentIndex] = res?.token;
                                                                                return newState;
                                                                            })
                                                                        })
                                                                }
                                                            }}
                                                            loading={emailVerifyLoader[index]}
                                                        >
                                                            {isEmailOtpVerified[index] ? "VERIFIED" : "VERIFY OTP"}
                                                        </Button>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        : <></>}
                                    {item?.type == "short" ?
                                        <div className='name-input m-t-30'>
                                            <AnimatedTextField
                                                label={item?.type == "short" ? item?.questionContent?.statement : ""}
                                                handleInputChange={(e) => handleInputChange(e, item)}
                                                isMandatory={item?.type == "short" && item?.required}
                                                inputId={item?.id}
                                                value={responseItem?.answer?.answer || ''}
                                            />
                                        </div>
                                        : <></>}
                                    {item?.type == "paragraph" ?
                                        <div className='name-input m-t-30'>
                                            <AnimatedTextField
                                                label={item?.type == "paragraph" ? item?.questionContent?.statement : ""}
                                                handleInputChange={(e) => handleInputChange(e, item)}
                                                isMandatory={item?.type == "paragraph" && item?.required}
                                                inputId={item?.id}
                                                value={responseItem?.answer?.answer || ''}
                                            />
                                        </div>
                                        : <></>}
                                    {item?.type === "checkbox" && (
                                        <div className='m-t-20'>
                                            <div style={{ color: "#AEAEAE" }}>{item?.questionContent?.statement}{item?.required ? <span style={{ color: 'red' }}> *</span> : null}</div>
                                            <Checkbox.Group onChange={(checkedValues) => handleCheckboxChange(checkedValues, item)} value={getCheckedValues(item)}>
                                                {item.questionContent?.options?.map((option) => (
                                                    <div className='m-t-10' key={option.id}>
                                                        <Checkbox style={{ marginRight: 10, color: "#191919", fontWeight: 400 }} value={option.id}>{option.value}</Checkbox>
                                                    </div>
                                                ))}
                                            </Checkbox.Group>
                                        </div>
                                    )}
                                    {item?.type === "MCQ" && (
                                        <div className='m-t-20'>
                                            <div style={{ color: "#AEAEAE" }}>{item?.questionContent?.statement}{item?.required ? <span style={{ color: 'red' }}> *</span> : null}</div>
                                            <Radio.Group onChange={(e) => handleMCQChange(e, item)}/*  value={getSelectedMCQValue(item)} */>
                                                {item?.questionContent?.options?.map((option) => (
                                                    <div className='m-t-10' key={option.id}>
                                                        <Radio style={{ marginRight: 10, color: "#191919", fontWeight: 400 }} value={option.id}>{option.value}</Radio>
                                                    </div>
                                                ))}
                                            </Radio.Group>
                                        </div>
                                    )}
                                    {item?.type == "dropdown" ?
                                        <div className='m-t-20'>
                                            <AnimatedCustomSelect
                                                label={item?.questionContent?.statement}
                                                required={item?.required}
                                                options={item?.questionContent?.options?.some(option => option?.courseList) ? enquiryFormCourseListData : item?.questionContent?.options}
                                                handleSelectChange={(value) => handleSelectChange(value, item)}
                                                isSelected={responseItem?.answer?.answer?.length > 0 ? true : false} 
                                                value={responseItem?.answer?.answer}/>
                                        </div>
                                        : <></>}
                                    {item?.type == "phone" ?
                                        <>
                                            {item?.type == "phone" && !item?.verify ?
                                                <div className='m-t-30' style={{ width: "100%" }}>
                                                    <div className='mobile-input' style={{ width: "100%" }}>
                                                        <AnimatedPhoneInput
                                                            label={item?.questionContent?.statement}
                                                            handleInputChange={(e) => handleCustomizePhoneInputChange(e, item)}
                                                            isMandatory={item?.required}
                                                            inputId={item?.id}
                                                            value={responseItem?.answer?.answer || ''}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                                                        <div className='mobile-input'>
                                                            <AnimatedPhoneInput
                                                                label={item?.questionContent?.statement}
                                                                handleInputChange={(e) => handleCustomizePhoneInputChange(e, item)}
                                                                isMandatory={item?.required}
                                                                inputId={item?.id}
                                                                value={responseItem?.answer?.answer || ''}
                                                            />
                                                        </div>
                                                        {isCustomizePhoneSendOtpClicked[index] ?
                                                            <>
                                                                {timer == '' && !isCustomizePhoneOtpVerified[index] ?
                                                                    <Button
                                                                        disabled={!responseItem?.answer?.answer}
                                                                        type="link"
                                                                        shape="round"
                                                                        className="r-c-c bold-bold"
                                                                        style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}
                                                                        onClick={() => {
                                                                            if (!responseItem?.answer?.answer) { }
                                                                            else {
                                                                                const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
                                                                                setCustomizePhoneSendOtpLoader(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[currentIndex] = true;
                                                                                    return newState;
                                                                                });
                                                                                const newOtps = [...customizePhoneOtp];
                                                                                newOtps[index] = "";
                                                                                setCustomizePhoneOtp(newOtps);
                                                                                postGenerateEnquiryOtp(
                                                                                    responseItem?.answer?.answer,
                                                                                    () => {
                                                                                        setCustomizePhoneSendOtpLoader(prevState => {
                                                                                            const newState = [...prevState];
                                                                                            newState[currentIndex] = false;
                                                                                            return newState;
                                                                                        });
                                                                                    },
                                                                                    () => {
                                                                                        setIsCustomizePhoneSendOtpClicked(prevState => {
                                                                                            const newState = [...prevState];
                                                                                            newState[currentIndex] = true;
                                                                                            return newState;
                                                                                        });
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                        loading={customizePhoneSendOtpLoader[index]}
                                                                    >
                                                                        RESEND OTP
                                                                    </Button>
                                                                    :
                                                                    <>
                                                                        {isCustomizePhoneOtpVerified[index] ?
                                                                            <></>
                                                                            :
                                                                            <div style={{ color: "#FF414D", fontWeight: "bold", marginTop: 30, marginLeft: 10 }}> {`00 : ${timer}`}</div>}
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <Button
                                                                disabled={!responseItem?.answer?.answer}
                                                                type="link"
                                                                shape="round"
                                                                className="r-c-c bold-bold"
                                                                style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}
                                                                onClick={() => {
                                                                    if (!responseItem?.answer?.answer) { }
                                                                    else {
                                                                        const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
                                                                        setCustomizePhoneSendOtpLoader(prevState => {
                                                                            const newState = [...prevState];
                                                                            newState[currentIndex] = true;
                                                                            return newState;
                                                                        });
                                                                        postGenerateEnquiryOtp(
                                                                            responseItem?.answer?.answer,
                                                                            () => {
                                                                                setCustomizePhoneSendOtpLoader(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[currentIndex] = false;
                                                                                    return newState;
                                                                                });
                                                                            },
                                                                            () => {
                                                                                setIsCustomizePhoneSendOtpClicked(prevState => {
                                                                                    const newState = [...prevState];
                                                                                    newState[currentIndex] = true;
                                                                                    return newState;
                                                                                });
                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                                loading={customizePhoneSendOtpLoader[index]}
                                                            >
                                                                SEND OTP
                                                            </Button>
                                                        }
                                                    </div>
                                                    <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                                                        <div className='otp-input' >
                                                            <AnimatedOTPInput
                                                                label="Verify mobile number"
                                                                value={customizePhoneOtp[index] || ""}
                                                                inputId={item?.id ? `${item.id}_customPhone` : "customPhone"}
                                                                handleInputChange={(e) => handleCustomPhoneOtpOnChange(e, responseItem, index)}
                                                                disabled={!isCustomizePhoneSendOtpClicked[index]}
                                                                isMandatory={true}
                                                            />
                                                        </div>
                                                        <Button
                                                            disabled={!customizePhoneOtp[index] || customizePhoneOtp[index]?.length < 6}
                                                            type="link"
                                                            shape="round"
                                                            className="r-c-c bold-bold"
                                                            style={{ color: isCustomizePhoneOtpVerified[index] ? "#00A566" : "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, cursor: (!customizePhoneOtp[index] || customizePhoneOtp[index]?.length < 6) ? "not-allowed" : isCustomizePhoneOtpVerified[index] ? "default" : "pointer" }}
                                                            onClick={() => {
                                                                const currentIndex = enquiryResponse.findIndex(item => item.id === responseItem.id);
                                                                if (isCustomizePhoneOtpVerified[index]) { }
                                                                else {
                                                                    setCustomizePhoneVerifyLoader(prevState => {
                                                                        const newState = [...prevState];
                                                                        newState[currentIndex] = true;
                                                                        return newState;
                                                                    });
                                                                    postVerifyEnquiryOtp(customizePhoneOtp[index], responseItem?.answer?.answer,
                                                                        () => {
                                                                            setCustomizePhoneVerifyLoader(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[currentIndex] = false;
                                                                                return newState;
                                                                            });
                                                                        },
                                                                        (res) => {
                                                                            setIsCustomizePhoneOtpVerified(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[currentIndex] = true;
                                                                                return newState;
                                                                            })
                                                                            setCustomizePhoneToken(prevState => {
                                                                                const newState = [...prevState];
                                                                                newState[currentIndex] = res?.token;
                                                                                return newState;
                                                                            })
                                                                        })
                                                                }
                                                            }}
                                                            loading={customizePhoneVerifyLoader[index]}
                                                        >
                                                            {isCustomizePhoneOtpVerified[index] ? "VERIFIED" : "VERIFY OTP"}
                                                        </Button>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        : <></>}
                                </>
                            )
                        })}
                    </>
                }
                <Checkbox style={{ marginRight: 10, color: "#191919", fontWeight: 500, marginTop: 20 }} checked={isChecked} onChange={(e) => { setIsChecked(e.target.checked) }}>I hereby authorise {userMarketPlaceHomeData?.orgName} to contact me.</Checkbox>
            </div>
            <div className='r-c-c-c' style={{ height: 60, margin: "20px -25px -25px -25px", boxShadow: "0px -3px 10px #00000029", borderRadius: " 0px 0px 16px 16px" }}>
                <Button type="primary"
                    loading={submitLoader}
                    onClick={() => {
                        let formattedPhoneNumber = phoneNo?.replace(/-|\s/g, "");
                        formattedPhoneNumber = "+" + formattedPhoneNumber;
                        const updatedEnquiryResponse = enquiryResponse.map((item, index) => {
                            if (item.type === "email") {
                                return {
                                    ...item,
                                    token: emailToken[index]
                                };
                            } else if (item.type === "phone") {
                                return {
                                    ...item,
                                    token: customizePhoneToken[index]
                                };
                            } else {
                                return item;
                            }
                        });
                        const filteredEnquiryResponse = updatedEnquiryResponse.map(item => {
                            if (item.id && item.id < 0) {
                                const { id, ...newItem } = item;
                                return newItem;
                            } else {
                                return item;
                            }
                        });
                        const params = {
                            phone: formattedPhoneNumber,
                            name: reachUsParams?.name,
                            pToken: mobileToken,
                            enquiryResponse: filteredEnquiryResponse
                        }
                        setSubmitLoader(true);
                        postUpdateEnquiryDetails(
                            params,
                            () => { setSubmitLoader(false) },
                            () => { handleCancel(); clearValues() },
                            (response) => {
                                if (response?.refresh) {
                                    handleCancel();
                                    clearValues();
                                }
                            }
                        )
                    }}
                    disabled={disableSubmit}
                >
                    SUBMIT </Button>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    const { timer, phoneNo } = state.loginNew;
    const { userMarketPlaceHomeData, isOtpVerified, enquiryFormCourseListData } = state.studentMarketPlace;
    return { userMarketPlaceHomeData, timer, isOtpVerified, phoneNo, enquiryFormCourseListData };
};

const mapDispatchToProps = (dispatch) => ({
    storePhoneNumber: (val) => dispatch(storePhoneNumber(val)),
    setIsOtpVerified: (val) => dispatch(setIsOtpVerified(val)),
    postHomeReachUsForm: (reachUsParams, callback, successCallback) => dispatch(postHomeReachUsForm(reachUsParams, callback, successCallback)),
    postVerifyEnquiryOtp: (otp, phone, callBack, succesCallback) => dispatch(postVerifyEnquiryOtp(otp, phone, callBack, succesCallback)),
    postVerifyEmailEnquiryOtp: (otp, email, callBack, succesCallback) => dispatch(postVerifyEmailEnquiryOtp(otp, email, callBack, succesCallback)),
    postGenerateEnquiryOtp: (phone, callback, successCallBack) => dispatch(postGenerateEnquiryOtp(phone, callback, successCallBack)),
    postUpdateEnquiryDetails: (params, callback, successCallBack, errorCallBack) => dispatch(postUpdateEnquiryDetails(params, callback, successCallBack, errorCallBack)),
    handleResendOtpTimer: (bool, sec) => dispatch(handleResendOtpTimer(bool, sec)),
    postGenerateEmailEnquiryOtp: (email, callback, successCallBack) => dispatch(postGenerateEmailEnquiryOtp(email, callback, successCallBack)),
    getUserMarketPlaceHomeData: (noCourse, callBack) => dispatch(getUserMarketPlaceHomeData(noCourse, callBack)),
    getEnquiryFormCourseListData: () => dispatch(getEnquiryFormCourseListData())
});
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionEnquiryForm);
// export default AdmissionEnquiryForm