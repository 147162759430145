import { ArrowLeftOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import {
  addUserByRole,
  getSearchedStudents,
  getUserDashBoardData,
  setClass,
  setClassId,
  setDashBoardRole,
  setEmail,
  setEndDate,
  setLimit,
  setName,
  setNextRollNo,
  setPage,
  setPhone,
  setPrivilage,
  setRollNumber,
  setSectionById,
  setSpecificUserId,
  setStartDate,
  setUserName,
  storeSearchedResults,
} from "actions/userManagement";
import { Col, Input, Pagination, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import AddStdTchrModal from "./addStdTchrModal";
import AddUsers from "./addUsers";
import ClassCard from "./classCard";
import GenderRatio from "./genderRatio";
import GrowthSummary from "./growthSummary";
import UserCard from "./userCard";
import { IMG_URL } from "../../env.json";
import PageDataLoader from "components/pageDataLoader";
import moment from "moment";
import { setOverviewFilter } from "actions/feeManagement";
import SearchedResultCard from "./searchedResultCard";
import { storeRequestViewAllPage } from "actions/attendanceNew";
import { NodataCard } from "pages/Test";
import PageHeader from "components/pageHeader";
import BackToTop from "components/backToTop";
import addUsers from "./addUsers";
import { Heading } from "components/Typography";
import {Button} from 'components/Button'
import { InputSearch } from "components/customInputs";

const { Search } = Input;

const goBack = (history) => {
  history.goBack();
};
function StudentDashBoard({
  getUserDashBoardData,
  userManagement,
  setSectionById,
  dashBoardDataByRole,
  setPrivilage,
  setEmail,
  setPhone,
  setUserName,
  setClass,
  setRollNumber,
  addUserByRole,
  setNextRollNo,
  setClassId,
  setSpecificUserId,
  setEndDate,
  setStartDate,
  setOverviewFilter,
  user,
  setName,
  getSearchedStudents,
  storeSearchResponse,
  setPage,
  storeSearchedResults,
  setLimit,
  search,
  setDashBoardRole,
  dashBoardRole
}) {
  const history = useHistory();
  const [fieldDisabledForFee, setFieldDisabledForFee] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);


  useEffect(() => {
    let temp = false;
    temp =
      user.privilage.includes("SuperAdmin") || user.privilage.includes("Admin");
    // console.log("temp", temp);
    setFieldDisabledForFee(!temp);
  }, []);

  useEffect(() => {
    setEndDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    setClassId(null);
    setNextRollNo(null);
    setPrivilage([]);
    setEmail(null);
    setPhone(null);
    setUserName(null);
    setClass(null);
    setRollNumber(null);
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false));
  }, []);

  const handleViewMore = () => {
    history.push("/user-management/students/unassigned");
  };

  useEffect(() => {
    storeSearchedResults([]);
    setPage(1);
    setLimit(25);
    setName("");
  }, [])
  
  const successCallback = () => {
    setPrivilage([]);
    setEmail(null);
    setPhone(null);
    setUserName(null);
    setClassId(null);
    setNextRollNo(null);
    setClass(null);
    setRollNumber(null);
    setIsRoleModalVisible(false);
    setPageLoader(true);
    getUserDashBoardData(() => setPageLoader(false));
  };

  const handleViewDetails = (item) => {
    setSpecificUserId(item?.id);
    setOverviewFilter("userId", item?.id);
    history.push("/user-management/students/details");
  };

  const toggleButtonLoader = () => {
    setButtonLoader(false);
  };

  const showRoleModal = () => {
    setIsRoleModalVisible(true);
  };

  const handleRoleOk = () => {
    setButtonLoader(true);
    addUserByRole(
      () => successCallback(false),
      () => toggleButtonLoader()
    );
  };

  const handleRoleCancel = () => {
    setPrivilage([]);
    setEmail(null);
    setPhone(null);
    setUserName(null);
    setClassId(null);
    setNextRollNo(null);
    setClass(null);
    setRollNumber(null);
    setButtonLoader(false);
    setIsRoleModalVisible(false);
  };
  const handleViewStudents = (id) => {
    setSectionById(id);
    history.push("/user-management/standard");
  };

  const onSearch = (e) => {
    setName(e)
    setPageLoader(true);
    getSearchedStudents(() => setPageLoader(false));
  }

  const handleBackPress = () => {
    history.goBack();
  }

  const actions = [
    user?.privilage?.includes('FeeManager') || user?.privilage?.includes('LeaveManager') ? <></> : <Button type="primary" onClick={()=>{showRoleModal()}}  style={{}} icon={<PlusCircleOutlined/>}>ADD A USER</Button>
  ]

  const easySwitch = [
    {label: 'Students', action: () => {setDashBoardRole('Student') ;history.push('/user-management/students')}},
    {label: 'Teachers', action: () => {setDashBoardRole('Teacher') ;history.push('/user-management/teacher')}},
    {label: 'Data Operators', action: () => {setDashBoardRole('Data operator'); history.push('/user-management/data-operator')}},
    {label: 'Operations', action: () => {setDashBoardRole('Operation'); history.push('/user-management/operations')}}
  ]

  return (
    <div>
      <div style={{ marginTop: -10, height: 50, width: '100%'}}>
        <PageHeader title='Students' actions={actions} onBackPress={()=>handleBackPress()} easySwitch={easySwitch}/>
      </div>
      <div className="m-t-20">
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 16 }}>
            <GrowthSummary setPageLoader={setPageLoader} />
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <GenderRatio StudentDashBoard={true} />
          </Col>
        </Row>
      </div>
      <div style={{ width: "100%", margin: "30px 0px 0px 0px", display: "flex", flexDirection: "row-reverse" }}>
          <InputSearch
            style={{ fontSize: "1em" }}
            placeholder="Search all students"
            onEnter={onSearch}
            allowClear = {true}
          ></InputSearch>
        </div>
      {
        search?.length?
          <>
            {
              storeSearchResponse?.allStudents?.length ?
                <>
                  <SearchedResultCard
                    storeSearchResponse={storeSearchResponse}
                    setPage={setPage}
                    setLimit={setLimit}
                    setPageLoader={setPageLoader}
                    getSearchedStudents={getSearchedStudents}
                    handleViewDetails={handleViewDetails} />
                </> :
                <>
                  <h2>
                    Search Result {" "}
                  </h2>
                  <NodataCard />
                </>
            }
          </> :
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px 20px",
              }}
            >
              <Heading>
                Unassigned {" "}
                <span style={{ color: "grey" }}>
                  ({dashBoardDataByRole?.totalUnassigned})
                </span>
              </Heading>

              <p
                onClick={handleViewMore}
                style={{ color: "#1089FF", fontWeight: "bold", cursor: "pointer" }}
              >
                VIEW ALL
              </p>

              
            </div>
            <Row style={{flexWrap: "wrap", gap: 32}}>
              {dashBoardDataByRole?.unAssigned?.length !== 0 &&
                dashBoardDataByRole?.unAssigned?.map((item) => (
                  <Col 
                      style={{ cursor: "pointer", width: "22%", minWidth: 250}}
                      onClick={() => handleViewDetails(item)}
                      pull={0}
                  >
                    <UserCard
                      phoneNo={true}
                      cell={item?.phone}
                      name={item?.name}
                      avatar={`${IMG_URL}/${item?.image ? item?.image : "default.png"
                        }`}
                      loc={'studentRole'}
                    />
                  </Col>
                ))}
            </Row>
          </>
      }
      {search?.length? 
      <></>
      :
      <>
      <Heading className="m-t-20 m-l-10 m-b-20">
        All Classes
      </Heading>

      <div>
        {dashBoardDataByRole &&
          "studentsByClass" in dashBoardDataByRole &&
          Object.entries(dashBoardDataByRole?.studentsByClass)?.length !== 0 &&
          Object.entries(dashBoardDataByRole?.studentsByClass)?.map((item) => (
            <div>
              <h1
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  marginLeft: "10px",
                }}
              >
                {item[0]}
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginBottom: "20px",
                }}
              >
                {item[1]?.map((val) => (
                  <div
                    style={{ cursor: "pointer", height: "170px"}}
                    onClick={() => handleViewStudents(val)}
                  >
                    <ClassCard
                      section={val?.section}
                      stream={val?.courseName}
                      total_students={val?.total_students}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
      </>
}
      
      <PageDataLoader visible={pageLoader} />

      {/* {isModalVisible && 
        <AddUsers/>
      } */}

      {isRoleModalVisible && (
        <AddStdTchrModal
          role="Student"
          isRoleModalVisible={isRoleModalVisible}
          showRoleModal={showRoleModal}
          handleRoleOk={handleRoleOk}
          handleRoleCancel={handleRoleCancel}
          buttonLoader={buttonLoader}
        />
      )}

      {<addUsers/>}
      <BackToTop />
    </div>
  );
}

// export default StudentDashBoard

const mapStateToProps = (state) => {
  const { tab, userDetails, userManagement, dashBoardDataByRole , storeSearchResponse, search, dashBoardRole} =
    state.userManagement;
  const { user } = state.session;
  return {
    tab,
    userDetails,
    userManagement,
    dashBoardDataByRole,
    user,
    storeSearchResponse,
    search, 
    dashBoardRole
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserDashBoardData: (callback) => dispatch(getUserDashBoardData(callback)),
  setSectionById: (id) => dispatch(setSectionById(id)),
  setPrivilage: (value) => dispatch(setPrivilage(value)),
  setEmail: (value) => dispatch(setEmail(value)),
  setPhone: (value) => dispatch(setPhone(value)),
  setUserName: (value) => dispatch(setUserName(value)),
  setClass: (value) => dispatch(setClass(value)),
  setRollNumber: (value) => dispatch(setRollNumber(value)),
  addUserByRole: (callback, callbackLoader) =>
    dispatch(addUserByRole(callback, callbackLoader)),
  setNextRollNo: (rollNo) => dispatch(setNextRollNo(rollNo)),
  setClassId: (id) => dispatch(setClassId(id)),
  setSpecificUserId: (id) => dispatch(setSpecificUserId(id)),
  setStartDate: (date) => dispatch(setStartDate(date)),
  setEndDate: (date) => dispatch(setEndDate),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
  setName:(res) => dispatch(setName(res)),
  setPage:(val)=>dispatch(setPage(val)),
  setLimit:(val)=>dispatch(setLimit(val)),
  getSearchedStudents:(callback) => dispatch(getSearchedStudents(callback)),
  storeSearchedResults:(val) => dispatch(storeSearchedResults(val)),
  setDashBoardRole:(m) => dispatch(setDashBoardRole(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashBoard);