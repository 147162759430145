import React, { useEffect, useState } from 'react'
import {Modal, Input, Button, DatePicker, Select} from 'antd'
import moment from 'moment'
import { editLeave } from 'actions/attendanceNew'


const EditLeaves = ({ setisEditModalVisible,isEditModalVisible, AttendanceTab, setEditLeave, editLeaveModal, loaders, selectedLeaves, storeSelectedLeaves, editLeaveMeta, idSelectedStore, leaveRequest, user }) => {

    const [dropDownData ,setDropDownData]=useState([])
    const [diffT, setDiff] = useState(0)

    const {TextArea} = Input; 
    const handleCancel = () =>{
        setisEditModalVisible(false)
    }
 
    useEffect(() => {
        let temp=[];
        let dataArray=AttendanceTab?.leaveTab?.leaveSummary;
        for(let i=0;i<dataArray?.length;i++){
            temp.push(dataArray[i]?.leaveType)
        }
        setDropDownData(temp)

        // console.log('action', storeSelectedLeaves)
        setEditLeave('id', storeSelectedLeaves?.id)
        setEditLeave('endDate', storeSelectedLeaves?.endDate)
        setEditLeave('startDate', storeSelectedLeaves?.startDate)
        setEditLeave('reason', storeSelectedLeaves?.reason)
        setEditLeave('leaveType', storeSelectedLeaves?.leaveType)
        setEditLeave('noOfdays', storeSelectedLeaves?.duration)
    }, [AttendanceTab])

    
    const handleSaveBtn = () => {
        {user?.privilage?.includes("SuperAdmin") ? editLeaveModal(()=>{setisEditModalVisible(false)}) : leaveRequest(()=>{setisEditModalVisible(false)})}
    }

    const calcDiff = () => {
        let startDate = moment(editLeaveMeta?.startDate, 'YYYY-MM-DD');
        let endDate = moment(editLeaveMeta?.endDate, 'YYYY-MM-DD');
        let totalDays = endDate.diff(startDate, 'days');

        if(totalDays != NaN && totalDays != "NaN"){
            setDiff(totalDays)
        }
    }

    useEffect(() => {
        calcDiff()
    }, [editLeaveMeta?.startDate, editLeaveMeta?.endDate])
    
    return (

        <div>
        <Modal
        centered
        className="modal-round-corner"
        visible={isEditModalVisible}
        onOk={/* handleOk */ null}
        onCancel={()=>{setisEditModalVisible(false)}}
        header={null}
        footer={null}
        >
            <div>
                <div style={{marginTop:"25px",color:"#191919",fontSize:"21px",fontWeight:"bold",textAlign:"center", marginBottom: 20, fontFamily: 'roboto'}}>Edit Leave(s)</div>

                <div className='m-b-20' style={{color: '#636363', fontSize: 12, fontWeight: 500}}>Leave type
                    <Select 
                    style={{marginTop: 5, fontSize: 12, width: '100%'}}
                    placeholder= 'Sick'
                    onChange={(value)=>{setEditLeave('leaveType', value)}}
                    value={editLeaveMeta?.leaveType}
                    >
                    {dropDownData.map((item)=>(
                        <Option key={item.id}  className="createTestModalDropdown" value={item}>{item}</Option>
                    ))}
                    </Select>
                </div>

                <div className='m-b-20' style={{display: 'flex'}}>
                    <div className='m-b-10' style={{display: 'flex', flexDirection: 'column', color: '#636363', fontSize: 12, fontWeight: 500 }}>Start date 
                        <DatePicker
                        style={{width: '150%', marginTop: 5, fontSize: 12}}
                        // format= 'HH:mm'
                        onChange={(e)=>{setEditLeave('startDate', e.format('YYYY-MM-DD'))}}
                        value={moment(editLeaveMeta?.startDate)}
                        />
                    </div>

                    <div className='m-b-10' style={{display: 'flex', flexDirection: 'column', marginLeft: 110, color: '#636363', fontSize: 12, fontWeight: 500}}>End date 
                        <DatePicker
                        // moment
                        style={{width: '150%', marginTop: 5, fontSize: 12}}
                        onChange={(e)=>{setEditLeave('endDate', e.format('YYYY-MM-DD'))}}
                        value={moment(editLeaveMeta?.endDate)}
                        />
                    </div>
                </div>

                <div>
                    <div className='m-b-20' style={{color: '#636363', fontSize: 12, fontWeight: 500}}>Number of days 
                    <Input
                    style={{marginTop: 5, fontSize: 12}}
                    onChange={(e)=>{setEditLeave('noOfdays', e.target.value)}}
                    readOnly={true}
                    value={isNaN(diffT) ? 0 : diffT > 0 ? diffT + 1 : editLeaveMeta?.noOfdays}
                    />
                    </div>

                    <div className='m-b-20' style={{color: '#636363', fontSize: 12, fontWeight: 500}}>Reason(s)
                    <TextArea
                    rows={4}
                    style={{marginTop: 5, fontSize: 12}}
                    placeholder='Enter reason(s)'
                    onChange={(e)=>{setEditLeave('reason', e.target.value)}}
                    value={editLeaveMeta?.reason}
                    />
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={()=>{handleCancel()}} className='blankGreyBtnWrap radius-100'>CANCEL</Button>
                    <Button disabled={diffT < 0 ? true : false} loading={loaders?.modalEditLeave} onClick={()=>{handleSaveBtn()}} className='purpleBtnWrap radius-100 m-l-20'>SAVE</Button>
                </div>

            </div>

        </Modal>
    </div>
  )
}

export default EditLeaves