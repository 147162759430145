import { DatePicker, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DemoColumn from "./antGraph";
import image1 from "../../Assets/care/no-subjects.svg";
import { fetchCourseDetails, fetchGraphData, getFeeOverView, setOverviewFilter } from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";

const { Option } = Select;

function CourseFmGraph({ setOverviewFilter, overviewFilter, fetchCourseDetails, fetchGraphData, insideGraphData, viewAllIs, standardData, resourceData }) {
  let [graphData, setGraphData] = useState([]);
  let [sum, setSum] = useState(0);
  const [type, setType] = useState('week');
  const [pageLoaderFilter, tooglePageLoaderFilter] = useState(false);
  const [dateClose, setDateClose] = useState(false)

  let calendarParams = {
    period: type,
    year: type == 'week' ? undefined : parseInt(overviewFilter?.year),
    startDate: type == 'week' ? overviewFilter?.startDate : undefined,
    endDate: type == 'week' ? overviewFilter?.endDate : undefined,
    resourceType: viewAllIs,
    resourceId: resourceData?.id,
    standardId: viewAllIs == 'course' ? standardData?.standard_id : undefined,
    getTotalStats: true
  };

  useEffect(() => {
    setType("week");
    setOverviewFilter("startDate", moment().subtract(6, "days").format("YYYY-MM-DD"));
    setOverviewFilter("endDate", moment().format("YYYY-MM-DD"));

    tooglePageLoaderFilter(false);
    fetchCourseDetails(calendarParams, () => tooglePageLoaderFilter(false));
    fetchGraphData(calendarParams, () => { tooglePageLoaderFilter(false) });
  }, [])

  useEffect(() => {
    tooglePageLoaderFilter(true);
    fetchGraphData(calendarParams, () => { tooglePageLoaderFilter(false) });
  }, [type, overviewFilter?.endDate, overviewFilter?.year])

  useEffect(() => {
    graphData = [];
    sum = 0;

    if (insideGraphData?.summaryGraph?.length != 0) {
      insideGraphData?.summaryGraph?.map(
        (item) => (
          (sum = sum + parseFloat(item?.totalSumRequested)),
          graphData.push({
            type: "Payment Requested",
            short_date: item?.short_date,
            Payment_Requested: item?.amount,
            totalCountequested: item?.count
          })
        )
      );
    }

    setSum(sum);
    setGraphData([...graphData]);
  }, [insideGraphData?.summaryGraph, overviewFilter?.type, overviewFilter?.startDate, overviewFilter?.year]);

  const handleWeekOrMonth = (value) => {
    setType(value)
    setOverviewFilter('type', value)
    if (value == 'month') {
      setOverviewFilter('year', moment().year())
    }
  }

  const handleCalenderChange = (date, dateString) => {
    if (dateString[0]) {
      if (dateString[0] !== moment(overviewFilter?.startDate).format('DD-MM-YYYY')) {
        setOverviewFilter("startDate", moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD"));
        setOverviewFilter("endDate", moment(dateString[0], "DD-MM-YYYY").add(6, 'days').format("YYYY-MM-DD"));
      }
      else {
        setOverviewFilter("endDate", moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD"));
        setOverviewFilter("startDate", moment(dateString[1], "DD-MM-YYYY").subtract(6, 'days').format("YYYY-MM-DD"));
      }
    }
    setDateClose(false)
  };

  const handleChangeYear = (value, year) => {
    console.log({ year })
    if (year) {
      setOverviewFilter("year", year);
    } else {
      setOverviewFilter("year", moment().year());
    }
  };

  const disable = (current) => {
    return current && current.format("YYYY") > moment().format("YYYY");
  };

  return (
    <div>
      <div
        className="careAdminSummaryCard"
        style={{
          boxShadow: "none",
          height: viewAllIs == 'course' ? "310px" : 440,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "-10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              width: "50%",
              marginRight: "10px",
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {type == "week" ? (

              <DatePicker.RangePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker='week'
                showWeekNumber={false}
                value={[
                  moment(
                    moment(overviewFilter?.startDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    ),
                    "DD-MM-YYYY"
                  ),
                  moment(
                    moment(overviewFilter?.endDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    ),
                    "DD-MM-YYYY"
                  ),
                ]}
                allowClear={false}
                style={{ width: "80%" }}
                format={"DD-MM-YYYY"}
                onCalendarChange={(value, dateString) => { handleCalenderChange(value, dateString) }}
                onOpenChange={(open) => {
                  setDateClose(open)
                  if (open == true) {
                  }

                }}
              />

            ) : (
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker="year"
                onChange={handleChangeYear}
                value={moment(overviewFilter?.year, "YYYY")}
                disabledDate={disable}
              />
            )}
          </div>
          <div
            id="testHomeDropdown"
            style={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Select
              style={{ width: "100%" }}
              onChange={handleWeekOrMonth}
              value={type}
              getPopupContainer={() => document.getElementById("testHomeDropdown")}
            >
              <Option value={"week"} >Week</Option>
              <Option value={"month"}>Months</Option>
            </Select>
          </div>
        </div>
        <div style={{ width: "100%", height: "97%", marginTop: 50 }}>
          {sum == 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "30%", height: "30%", marginBottom: "20px" }}
                src={image1}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontWeight: "500" }}>No data!</h1>
                <p
                  style={{
                    color: "#636363",
                    width: "60%",
                    textAlign: "center",
                  }}
                >
                  There are no data to show yet. Please try some other date
                  range.
                </p>
              </div>
            </div>
          ) : (
            <DemoColumn
              graphData={graphData}
              xField={"short_date"}
              yField={"Payment_Requested"}
              viewAllIs={viewAllIs}
            />
          )}
        </div>
      </div>
      <PageDataLoader visible={pageLoaderFilter} />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { selectedStd, feeMangementData, overviewFilter, courseDetailsOfResources, insideGraphData, viewAllIs, standardData, resourceData } = state.feeManagement;
  return {
    selectedStd,
    feeMangementData,
    overviewFilter,
    courseDetailsOfResources, insideGraphData, viewAllIs, standardData, resourceData
  };
};

const mapDispatchToProps = (dispatch) => ({
  setOverviewFilter: (key, res) => dispatch(setOverviewFilter(key, res)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  fetchCourseDetails: (params, callback) => dispatch(fetchCourseDetails(params, callback)),
  fetchGraphData: (params, callback) => dispatch(fetchGraphData(params, callback)),


});

export default connect(mapStateToProps, mapDispatchToProps)(CourseFmGraph);