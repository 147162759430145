import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";

const StudentAttendanceBarGraph = ({data}) =>  {
  console.log("data",data)

  const config = {
    data,
    isStack: true,
    xField: 'date',
    yField: 'range',
    seriesField: 'name',
  
    tooltip:
      {customContent:(val,item)=>{
        // console.log("sbd",item)
        return(
          <div style={{height:"85px"}}>
              <div style={{marginLeft:"20px",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.date}</div>
             <div style={{display:"flex",justifyContent:"space-between",marginTop:"8px",width:"150px",marginLeft:"20px"}}>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto" }}>Total Classes   </div>
              <div style={{fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold"}}>{item[0]?.data?.total}</div>
              </div>

              <div style={{display:"flex",justifyContent:"space-between",marginTop:"8px",width:"150px",marginLeft:"20px"}}>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto"}}>Present  </div>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold"}}>{item[0]?.data?.present} </div>
              </div>

              <div style={{display:"flex",justifyContent:"space-between",marginTop:"8px",width:"150px",marginLeft:"20px"}}>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto"}}>Absent  </div>
              <div style={{color:"#000000",fontSize:"11px",fontFamily:"Roboto",fontWeight:"bold" }}>{item[1]?.data?.absent}</div>
              </div>
              </div>
          )}},
    color: [" #28DF99","#FF414D","#FF414D"],
    columnStyle: {
      radius: [3, 3, 0, 0],
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        }, 
        {
          type: 'interval-hide-overlap',
        }, 
        {
          type: 'adjust-color',
        },
      
      ],
    },
    minColumnWidth: 30,
    maxColumnWidth: 30,
    
  };
 
  return <Column {...config} />;
};

export default StudentAttendanceBarGraph;
