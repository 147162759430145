import { EditOutlined } from '@ant-design/icons';
import { Skeleton, Tag, Typography } from 'antd';
import YtPlayer from 'components/ytPlayer';
import RatingStars from 'pages/studentMarketPlace/ratingStars';
import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from 'services';
import { newCardStyle, descStyle, IsJsonString, isHTML } from "./courseInformationPage"
import HtmlParser from 'react-html-parser';
import "./styles.css"
import onlineTestSeries from 'pages/studentMarketPlace/myCourses/onlineTestSeries';
import { FaIcicles } from 'react-icons/fa';
import ReactPlayerHoc from 'components/ReactPlayerHoc';

export const stripHtmlTags = (html) => {
    if (typeof html === 'string') {
        return html.replace(/<[^>]*>/g, '');
    }
    return '';
};

function CourseDetailsComp({ specificCourseDataContainer, courseTypeName, location, isBatchesExpandClicked, setIsViewDetailsClicked, isViewDetailsClicked, handleVideoPause, setIsCoursePlanningEditClicked, setIsBatchesExpandClicked, setIsFeeClosed, isVideoPreview, pageLoader, showSkeletonOnCancel, isScrolled, setWasDetailsCardExpanded, wasDetailsCardExpanded, isExamPattern, setIsExamPatern, isSyllabas, setIsSyllabas, setIsTestExpand, isTestExpan, hasMarketingPrivilege }) {
    const history = useHistory();
    const learnings = courseTypeName == "liveCourses" ? specificCourseDataContainer?.course?.learnings : (courseTypeName == "videoResources" || courseTypeName == "studyMaterials") ? specificCourseDataContainer?.learnings : null;
    const prerequisites = courseTypeName == "liveCourses" ? specificCourseDataContainer?.course?.prerequisites : courseTypeName == "videoResources" ? specificCourseDataContainer?.prerequisites : courseTypeName == "onlineTestSeries" ? specificCourseDataContainer?.testSeriesDetails?.prerequisites : null;
    const features = courseTypeName == "liveCourses" ? specificCourseDataContainer?.course?.features : (courseTypeName == "videoResources" || courseTypeName == "studyMaterials") ? specificCourseDataContainer?.features : courseTypeName == "onlineTestSeries" ? specificCourseDataContainer?.testSeriesDetails?.features : null;
    const details = courseTypeName == "videoResources" ? specificCourseDataContainer?.details : null;
    const tags = courseTypeName == "liveCourses" ? specificCourseDataContainer?.course?.tags : (courseTypeName == "videoResources" || courseTypeName == "studyMaterials") ? specificCourseDataContainer?.tags : courseTypeName == "onlineTestSeries" ? specificCourseDataContainer?.testSeriesDetails?.tags : null;
    return (
        <div className={`course-details-comp-parent ${isScrolled ? "height-fit-content" : isBatchesExpandClicked ?
            "course-details-height-batches-expand" : courseTypeName == 'videoResources' && isViewDetailsClicked ?
                "vs-course-details-expanded-height" : isViewDetailsClicked ?
                    "course-details-expanded-height" :
                    courseTypeName == 'onlineTestSeries' && !isExamPattern && !isSyllabas && !isTestExpan ? 'test-details-collapsed-height' :
                        courseTypeName == 'onlineTestSeries' && isExamPattern ? 'course-details-collapsed-height' :
                            courseTypeName == 'onlineTestSeries' && !isSyllabas && !isTestExpan ? 'test-details-collapsed-height' :
                                courseTypeName == 'onlineTestSeries' && isSyllabas ? 'course-details-collapsed-height' :
                                    courseTypeName == 'onlineTestSeries' && !isTestExpan ? 'test-details-collapsed-height' :
                                        courseTypeName == 'onlineTestSeries' && isTestExpan ? 'course-details-collapsed-height' :
                                            "course-details-collapsed-height"}`} style={{ ...newCardStyle, /* height: isScrolled ? "fit-content" : isBatchesExpandClicked ? 172.5 : courseTypeName == 'videoResources' && isViewDetailsClicked ? 614 : isViewDetailsClicked ? 614 : 173,  */transition: "height 0.2s ease" }}>
            {pageLoader ? <Skeleton active /> :
                <>
                    <div className='r-c-sb'>
                        <div className='r-ac' style={{ width: "80%" }}>
                            <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20, maxHeight: "3.6em", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical" }}>{courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? specificCourseDataContainer?.name : courseTypeName == 'onlineTestSeries' ? specificCourseDataContainer?.testSeriesDetails?.name : specificCourseDataContainer?.course?.courseName} </div>
                            {((courseTypeName == 'onlineTestSeries') && specificCourseDataContainer?.testSeriesDetails?.new) || ((courseTypeName == 'videoResources' || courseTypeName == "studyMaterials") && specificCourseDataContainer?.new) || (courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.new) ? <span style={{ color: "#FFA931", fontSize: 12, fontWeight: "bold", margin: "3px 0px 0px 10px" }}>NEW</span> : <></>}
                        </div>
                        <div className='r-ac'>
                            {hasMarketingPrivilege ?
                                <></>
                                :
                                <div className='cursor-pointer show-edit-on-hover' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginRight: 20 }} onClick={() => {
                                    history.push({
                                        pathname: courseTypeName == "liveCourses" ? "/add-live-courses" : courseTypeName == "videoResources" ? "/add-video-resources" : courseTypeName == "onlineTestSeries" ? "/add-online-test-series" : courseTypeName == "studyMaterials" ? "/add-study-materials" : "/add-live-courses",
                                        state: {
                                            key: {
                                                isEdit: "isFromEdit",
                                                fromCourseDetails: true,
                                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                searchedValue: location?.state?.key?.searchedValue
                                            }
                                        }
                                    })
                                }}><EditOutlined /> EDIT</div>
                            }

                            <div className='r-ac' style={{ color: "#636363", cursor: "pointer" }} onClick={() => {
                                setIsViewDetailsClicked(!isViewDetailsClicked);
                                setIsCoursePlanningEditClicked(false);
                                setIsExamPatern(false)
                                setIsSyllabas(false)
                                setIsBatchesExpandClicked(false);
                                setIsFeeClosed(false);
                                setIsTestExpand(false)
                                setWasDetailsCardExpanded(!wasDetailsCardExpanded)
                            }}>
                                {isViewDetailsClicked ? <FiChevronUp style={{ fontSize: 18, marginLeft: 5 }} /> : <FiChevronDown style={{ fontSize: 18, marginLeft: 5 }} />}
                            </div>
                        </div>
                    </div>
                    {!isViewDetailsClicked && !isScrolled ?
                        <>
                            <div className='m-t-10' style={{ color: "#191919", fontWeight: 700, maxHeight: "3.6em", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}>
                                {courseTypeName === "videoResources" ? IsJsonString(specificCourseDataContainer?.brief) ? HtmlParser(JSON.parse(specificCourseDataContainer?.brief)?.mobile) : stripHtmlTags(specificCourseDataContainer?.brief) : courseTypeName == 'liveCourses' ? IsJsonString(specificCourseDataContainer?.course?.brief) ? HtmlParser(JSON.parse(specificCourseDataContainer?.course?.brief)?.mobile) : stripHtmlTags(specificCourseDataContainer?.course?.brief) :
                                    courseTypeName == "studyMaterials" ?
                                        <>{specificCourseDataContainer?.description ? !IsJsonString(JSON.parse(specificCourseDataContainer?.description)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.description)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.description)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.description)?.mobile) : <></>}</> :
                                        (isExamPattern || isSyllabas || isTestExpan) ?
                                            (<>
                                                {specificCourseDataContainer?.testSeriesDetails?.description ? !IsJsonString(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : <></>}
                                            </>) : <></>
                                }
                            </div>
                            <div className='m-t-15 r-ac'>
                                {((courseTypeName == 'videoResources' || courseTypeName == "studyMaterials") && specificCourseDataContainer?.bestseller == 1) || ((courseTypeName == 'onlineTestSeries') && specificCourseDataContainer?.testSeriesDetails?.bestseller == 1) || (courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.bestseller == 1)
                                    ? <div className='m-r-10' style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12, width: "fit-content" }}>Bestseller</div> : <></>}
                                {(courseTypeName == 'videoResources' || courseTypeName == "studyMaterials") && (specificCourseDataContainer?.ratingValue && specificCourseDataContainer?.ratingValue > 0) || (courseTypeName == 'onlineTestSeries') && (specificCourseDataContainer?.testSeriesDetails?.ratingValue && specificCourseDataContainer?.testSeriesDetails?.ratingValue > 0) || (courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.ratingValue && specificCourseDataContainer?.course?.ratingValue > 0) ? <RatingStars star={courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? specificCourseDataContainer?.ratingValue : specificCourseDataContainer?.course?.ratingValue} /> : <></>}
                                <div style={{ color: "#636363", fontSize: 12, fontWeight: 600, marginLeft: 10 }}>{((courseTypeName == 'videoResources' || courseTypeName == "studyMaterials") && specificCourseDataContainer?.ratingCount > 0) || ((courseTypeName == 'onlineTestSeries') && specificCourseDataContainer?.testSeriesDetails?.ratingCount > 0) || (courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.ratingCount > 0) ? `(${((courseTypeName == 'videoResources' || courseTypeName == "studyMaterials") && specificCourseDataContainer?.ratingCount) || (courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.ratingCount)} ratings)` : null}</div>
                            </div>
                            {(isExamPattern == false && courseTypeName == 'onlineTestSeries' && isSyllabas == false && isTestExpan == false) ? (<>
                                <div className='m-t-10' style={{ color: "#191919", fontWeight: 700, maxHeight: "3.6em", }}>
                                    {specificCourseDataContainer?.testSeriesDetails?.description ? !IsJsonString(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : <></>}
                                </div></>) : (<></>)}
                        </>
                        :
                        <></>
                    }
                </>}
            {isViewDetailsClicked ?
                <div className='scroll-bar-universal m-t-10 course-details-scroll-height'>
                    <div style={{ color: "#191919", fontWeight: 700, maxHeight: "3.6em", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}>
                        {courseTypeName === "videoResources" ? IsJsonString(specificCourseDataContainer?.brief) ? HtmlParser(JSON.parse(specificCourseDataContainer?.brief)?.mobile) : stripHtmlTags(specificCourseDataContainer?.brief) : IsJsonString(specificCourseDataContainer?.course?.brief) ? HtmlParser(JSON.parse(specificCourseDataContainer?.course?.brief)?.mobile) : stripHtmlTags(specificCourseDataContainer?.course?.brief)}
                    </div>
                    <div className='m-t-15 r-ac'>
                        {courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? specificCourseDataContainer?.bestseller == 1 : courseTypeName == 'onlineTestSeries' ? specificCourseDataContainer?.testSeriesDetails?.bestseller == 1 : specificCourseDataContainer?.course?.bestseller == 1 ? <div className='m-r-10' style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12, width: "fit-content" }}>Bestseller</div> : <></>}
                        {courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? (specificCourseDataContainer?.ratingValue && specificCourseDataContainer?.ratingValue > 0) : courseTypeName == 'onlineTestSeries' ? (specificCourseDataContainer?.testSeriesDetails?.ratingValue && specificCourseDataContainer?.testSeriesDetails?.ratingValue > 0) : (specificCourseDataContainer?.course?.ratingValue && specificCourseDataContainer?.course?.ratingValue > 0) ? <RatingStars star={courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? specificCourseDataContainer?.ratingValue : courseTypeName == 'onlineTestSeries' ? specificCourseDataContainer?.testSeriesDetails?.ratingValue : specificCourseDataContainer?.course?.ratingValue} /> : <></>}
                        <div style={{ color: "#636363", fontSize: 12, fontWeight: 600, marginLeft: 10 }}>{courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? specificCourseDataContainer?.ratingCount > 0 : courseTypeName == 'onlineTestSeries' ? specificCourseDataContainer?.testSeriesDetails?.ratingCount > 0 : specificCourseDataContainer?.course?.ratingCount > 0 ? `(${courseTypeName == 'videoResources' || courseTypeName == "studyMaterials" ? specificCourseDataContainer?.ratingCount : courseTypeName == 'onlineTestSeries' ? specificCourseDataContainer?.testSeriesDetails?.ratingCount : specificCourseDataContainer?.course?.ratingCount} ratings)` : null}</div>
                    </div>
                    <div className='r-jsb m-t-20' style={{ width: "100%" }}>
                        {specificCourseDataContainer?.preview != null || specificCourseDataContainer?.course?.preview != null ?
                            <div className='course-details-preview-video'>
                                {isVideoPreview ? null :
                                    (<>
                                        {specificCourseDataContainer?.previewType == 'link' || specificCourseDataContainer?.course?.previewType == 'link' ?
                                            <div style={{ height: "25dvh", width: courseTypeName == 'videoResources' ? '250px' : '' }}><YtPlayer videoUrl={`${Api.proDlS3Url}${courseTypeName == 'videoResources' ? specificCourseDataContainer?.preview : specificCourseDataContainer?.course?.preview}`} /></div> :
                                            <ReactPlayerHoc
                                                height='25dvh'
                                                width='100%'
                                                keyboardControls={true}
                                                url={`${Api.proDlS3Url}${courseTypeName == 'videoResources' ? specificCourseDataContainer?.preview : specificCourseDataContainer?.course?.preview}`}
                                                controls={true}
                                            />}
                                    </>)}
                                <center onClick={() => {
                                    handleVideoPause()
                                }} style={{ color: "#594099", fontWeight: "bold", marginTop: 5, cursor: "pointer" }}>Preview this course</center>
                            </div>
                            : (<></>)
                        }
                        <div style={{ width: courseTypeName == 'videoResources' ? "60%" : courseTypeName == 'onlineTestSeries' ? '100%' : '65%', marginTop: -10 }}>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>Description</div>
                            <div style={{ ...descStyle, /* overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 8, WebkitBoxOrient: "vertical" */ }} className='m-t-5'>
                                {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ?
                                    (<>
                                        {IsJsonString(specificCourseDataContainer?.description) ? HtmlParser(JSON.parse(specificCourseDataContainer?.description)?.mobile) : stripHtmlTags(specificCourseDataContainer?.description)}
                                    </>) :
                                    courseTypeName == 'onlineTestSeries' ? (<>
                                        {IsJsonString(specificCourseDataContainer?.testSeriesDetails?.description) ? HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : stripHtmlTags(specificCourseDataContainer?.testSeriesDetails?.description)}
                                    </>)
                                        :
                                        (<>
                                            {IsJsonString(specificCourseDataContainer?.course?.courseDescription) ? HtmlParser(JSON.parse(specificCourseDataContainer?.course?.courseDescription)?.mobile) : stripHtmlTags(specificCourseDataContainer?.course?.courseDescription)}
                                        </>)}
                            </div>
                        </div>
                    </div>
                    {learnings?.length ?
                        <div className='m-t-20' style={{ border: "1px solid #E6E6E6", borderRadius: 8, width: "100%", padding: "10px 20px" }}>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>What you'll learn</div>
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                {learnings?.map((item, index) => (
                                    <div className='r-c-fs' style={{ alignItems: "baseline", width: learnings?.length == 1 ? "100%" : "49%" }}>
                                        <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                        <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <></>}
                    {prerequisites?.length ?
                        <div className='m-t-20' style={{ border: "1px solid #E6E6E6", borderRadius: 8, width: "100%", padding: "10px 20px" }}>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>Prerequisites</div>
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                {prerequisites?.map((item, index) => (
                                    <div className='r-c-fs' style={{ alignItems: "baseline", width: prerequisites?.length == 1 ? "100%" : "49%" }}>
                                        <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                        <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <></>}
                    {features?.length ?
                        <div className='m-t-20' style={{ border: "1px solid #E6E6E6", borderRadius: 8, width: "100%", padding: "10px 20px" }}>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>Salient features</div>
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                {features?.map((item, index) => (
                                    <div className='r-c-fs' style={{ alignItems: "baseline", width: features?.length == 1 ? "100%" : "49%" }}>
                                        <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                        <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <></>}
                    {details?.length ?
                        <div className='m-t-20' style={{ border: "1px solid #E6E6E6", borderRadius: 8, width: "100%", padding: "10px 20px" }}>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>This course includes :</div>
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                {details?.map((item, index) => (
                                    <div className='r-c-fs' style={{ alignItems: "baseline", width: details?.length == 1 ? "100%" : "49%" }}>
                                        <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                        <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <></>}
                    {tags?.length ?
                        <div className="flex-wrap m-t-20" >
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>Tags</div>
                            {tags?.map((tag, index) =>
                                <Tag
                                    key={tag?.id}
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                        color: '#191919',
                                        padding: "5px 10px",
                                        borderRadius: 20,
                                        border: "1px solid #E6E6E6",
                                        fontSize: 14,
                                        marginTop: 10
                                    }}
                                >
                                    {tag?.tag}
                                </Tag>
                            )}
                        </div> : <></>}
                </div>
                :
                <></>
            }
        </div>
    )
}

export default CourseDetailsComp