import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import { Tabs, Tag, DatePicker, Checkbox, Dropdown, Space } from 'antd';
import moment from 'moment';
// import DatePicker from 'pages/care/datePicker';
import React, { useEffect, useState } from 'react'
import { Api } from '../../services';

import SearchBox from './localComponents/searchBox';
import DropDownCheckBox from './dropDownCheckBox';
import DetailLeaveModal from '../attendanceNew/Modals/detailLeaveModal'
import AllTeachersViewAll from './allTeachersViewAll';
import { useHistory } from "react-router";
import { connect } from 'react-redux';
import { storeUsersName, attendnaceLeaveOverview, storeStaffLeave, roleFilter, typeFilter, getLeaveOverViewData, setBackToPrivilegedView, setGraphDate, setRoleFilter } from "actions/attendanceNew";
// import TeacherLeaveTable from './localComponents/leaveTeacherTable';
import PageDataLoader from 'components/pageDataLoader'
import NoDataCard from 'pages/digitalLibrary/components/noDataCard';
import CustomCheckBox from './localComponents/customCheckbox';
import StaffLeaveChart from './localComponents/staffLeaveDonutChart';
import LeaveTypeGraph from './localComponents/leaveTypeChart';
import PageHeader from 'components/pageHeader';
import { Row } from 'antd/lib';

const bodyStyle = {
    background: "#FAFAFA",
    width: "calc(100% + 30px)",
    height: "calc(100% + 40px)",
    margin: -20,
    padding: 20,
    overflow: "hidden",
};

const LeavereqCard = ({ name, image, role, duration, reason, actionBy, startDate, endDate, status }) => {

    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);

    return (
        <>
            <div
                className='border-16 boxShadow118'
                style={{
                    marginRight: 10,
                    borderColor: "#E6E6E6",
                    alignItems: "center",
                    border: "#E6E6E6 solid 1px",
                    minWidth: "250px",
                    width: "24%",
                    backgroundColor: "#FFFFFF",
                    cursor: "pointer",
                    marginBottom: "10px",
                    padding: "0px 10px 10px 0px"
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div className="m-b-5" >
                        <Tag style={{
                            color: `${status === "Leave" ? " #1089FF" : "#594099"}`,
                            backgroundColor: `${status === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
                            borderColor: `${status === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
                            fontSize: "10px",
                            fontWeight: "bold",
                            borderRadius: '10px 0 0 0'
                        }}
                        > {status}</Tag>
                    </div>
                </div>
                <div className="display-flex" style={{ marginLeft: "10px" }}>
                    <div><img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${image}`}></img></div>
                    <div className="m-l-10">
                        <div
                            style={{
                                fontSize: "13px",
                                color: "#191919",
                                fontWeight: 550,
                                fontFamily: "Roboto",
                            }}
                        >
                            {name}
                        </div>
                        <div style={{ fontSize: "11px", color: "#636363", fontFamily: "Roboto", }}>
                            {role}
                        </div>
                        <div style={{ color: "#1089FF", fontSize: "10px", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setIsLeaveModalVisible(true) }}>VIEW REQUEST</div>
                    </div>
                </div>
                {isLeaveModalVisible && (
                    <DetailLeaveModal
                        isLeaveModalVisible={isLeaveModalVisible}
                        setIsLeaveModalVisible={setIsLeaveModalVisible}
                        name={name}
                        image={image}
                        role={role}
                        duration={duration}
                        reason={reason}
                        actionBy={actionBy}
                        startDate={startDate}
                        endDate={endDate}
                        status={status}

                    />
                )}
            </div>
        </>
    );
};

function LeaveOverview({ storeGraphDate, storeUsersName, setGraphDate, getLeaveOverViewData, attendnaceLeaveOverview, storeAttendanceLeaveOverview, storeStaffLeave, storeLeaveDate, roleFilter, filterRole, typeFilter, setBackToPrivilegedView }) {

    const history = useHistory();


    const [pageLoader, setPageLoader] = useState(false)
    const [selectedLeaveTypes, setSelectedLeaveTypes] = useState([]);

    useEffect(() => {
        setPageLoader(true)
        attendnaceLeaveOverview(() => { setPageLoader(false) }, selectedLeaveTypes)
    }, [JSON.stringify(selectedLeaveTypes)])

    useEffect(() => {
        setPageLoader(true)
        attendnaceLeaveOverview(() => { setPageLoader(false) }, selectedLeaveTypes)
        storeStaffLeave(moment().format('YYYY-MM-DD'))
        setGraphDate(moment().format('YYYY-MM-DD'))
    }, [])

    const planFilters = [
        { val: "All", id: 1, label: "All" },
        { val: "Teacher", id: 2, label: "Teacher" },
        { val: "Data Operator", id: 3, label: "Data Operator" },
        { val: "Operation", id: 4, label: "Operation" },
    ];

    const onSelection = () => {
        setPageLoader(true)
        attendnaceLeaveOverview(() => { setPageLoader(false) }, selectedLeaveTypes)
    }

    useEffect(() => {
        roleFilter([]);
        storeUsersName();
    }, [])

    const handleBackPress = () => {
        history.goBack();
        setBackToPrivilegedView(true);
        roleFilter([]);
        storeStaffLeave();
        setGraphDate();
    }
    return (
        <div className={'m-t-40'}>
            <div style={{ marginBottom: 20, marginLeft: -10 }}>
                <PageHeader title='Leave Overview' onBackPress={() => handleBackPress()} />
            </div>

            <Tabs defaultActiveKey="1">
                <Tabs.TabPane style={{ fontSize: "13px", fontWeight: "bold" }} tab="OVERVIEW" key="1" onChange={() => {
                    setPageLoader(true)
                    attendnaceLeaveOverview(() => { setPageLoader(false) })
                }}
                >

                    <div style={{ textAlign: "right" }}>

                        <DatePicker
                            format={'DD-MM-YYYY'}
                            defaultValue={moment()}
                            // value={storeGraphDate}
                            allowClear={false}
                            onChange={(e) => {
                                setGraphDate(e.format("YYYY-MM-DD"))
                                setPageLoader(true)
                                attendnaceLeaveOverview(() => { setPageLoader(false) })

                            }}


                        // value={storeLeaveDate}
                        // allowClear={false}
                        // disabledDate={disabledDate}
                        // placeholder='Select date'
                        // style={{ width: "225px" }}
                        // onChange={(e) => {
                        //     console.log("changed")
                        //     storeStaffLeave(e.format("YYYY-MM-DD"))
                        // }}

                        />
                        {console.log("Date", storeLeaveDate)}
                    </div>
                    <Row style={{ display: "flex", marginTop: "20px", justifyContent: 'space-between' }}>
                        <div span={11} className='scroll container border-16 boxShadow118'
                            style={{
                                width: "50%",
                                height: "300px",
                                // borderRadius: 2,
                                backgroundColor: "#FFFFFF",
                                borderColor: "#E6E6E6",

                            }}
                        >
                            <div
                                style={{ color: "#191919", fontSize: "15px", fontWeight: "bold", fontFamily: "Roboto", marginTop: "10px", marginLeft: "20px" }}>
                                Staff Leave Summary
                            </div>
                            <div style={{ marginTop: "-85px", marginLeft: "75px", }}>
                                <StaffLeaveChart storeAttendanceLeaveOverview={storeAttendanceLeaveOverview} />
                                <div style={{ display: "flex", marginTop: "-76px" }}>
                                    <div
                                        style={{
                                            backgroundColor: "#9463FA",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "3px",
                                            marginRight: "5px"
                                        }}>

                                    </div>
                                    <div style={{ fontSize: 12, marginTop: "-5px" }}>
                                        Teachers
                                    </div>
                                    <div style={{ marginLeft: "8px", display: "flex" }}>
                                        <div style={{
                                            backgroundColor: "#99DED9",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "3px",
                                            marginRight: "5px"
                                        }}>

                                        </div>
                                        <div style={{ fontSize: 12, marginTop: "-5px" }}>
                                            Data Operators
                                        </div>

                                    </div>
                                    <div style={{ marginLeft: "8px", display: "flex" }}>
                                        <div style={{
                                            backgroundColor: "#589DFF",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "3px",
                                            marginRight: "5px"
                                        }}>

                                        </div>
                                        <div style={{ fontSize: 12, marginTop: "-5px" }}>
                                            Operations
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div span={12} className='container boxShadow118 border-16 m-l-10' style={{

                            width: "48%",
                            height: "300px",
                            // borderRadius: 2,
                            backgroundColor: "#FFFFFF",
                            borderColor: "#E6E6E6",
                        }}>
                            {/* <StaffLeaveGraph /> */}
                            {/* <NoDataCard /> */}
                            <div
                                style={{ color: "#191919", fontSize: "15px", fontWeight: "bold", fontFamily: "Roboto", marginTop: "10px", marginLeft: "20px" }}>
                                Leave type summary
                            </div>
                            <div style={{ marginLeft: "-75px", width: "580px", height: "300px", marginTop: "-35px" }}>
                                <LeaveTypeGraph storeAttendanceLeaveOverview={storeAttendanceLeaveOverview} />

                            </div>

                        </div>

                    </Row>


                    <Row className="display-flex" style={{ justifyContent: "space-between", marginTop: "20px" }}>
                        <div style={{ marginRight: "30px", marginTop: "10px", fontSize: "15px", color: "#191919", fontWeight: "bold", fontFamily: "Roboto" }}>
                            Staff(s) on leave
                        </div>
                        <div className="display-flex" style={{ justifyContent: 'space-between', width: '55%' }}>
                            <div style={{ marginTop: "10px" }}>
                                <DatePicker
                                    format={'DD-MM-YYYY'}
                                    defaultValue={moment()}
                                    allowClear={false}
                                    // disabledDate={disabledDate}
                                    placeholder='Select date'
                                    style={{ width: "225px" }}
                                    onChange={(e) => {
                                        storeStaffLeave(e.format("YYYY-MM-DD"))
                                        setPageLoader(true)
                                        attendnaceLeaveOverview(() => { setPageLoader(false) }, selectedLeaveTypes)

                                    }}

                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <DropDownCheckBox
                                    DATA={planFilters}
                                    placeholder={`Role`}
                                    type={`Role`}
                                    className="full-width"
                                    onSelection={onSelection}
                                    onDropdownClose={() => { }}
                                    defaultValue={null}
                                    roleFilter={roleFilter}
                                    role={filterRole}
                                    typeFilter={typeFilter}
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <>
                                    <CustomCheckBox
                                        DATA={storeAttendanceLeaveOverview?.leaveTypes}
                                        placeholder={`Leave Types`}
                                        className="full-width"
                                        onDropdownClose={() => { }}
                                        setFilters={setSelectedLeaveTypes}
                                        onSelection={onSelection}

                                    />
                                </>
                            </div>
                        </div>
                    </Row>
                    <div style={{ marginTop: "20px", display: "flex", flexWrap: 'wrap' }}>

                        {
                            storeAttendanceLeaveOverview?.staffsOnLeave != 0 ?
                                storeAttendanceLeaveOverview?.staffsOnLeave?.map((item) => (
                                    <LeavereqCard
                                        // leaveType={item?.request?.leaveType}
                                        name={item?.request?.user?.name}
                                        image={item?.request?.user?.image}
                                        role={item?.request?.user?.role[0]}
                                        duration={item?.request?.duration}
                                        reason={item?.request?.reason}
                                        actionBy={item?.request?.actionBy}
                                        startDate={item?.request?.startDate}
                                        endDate={item?.request?.endDate}
                                        status={item?.request?.leaveType}
                                    />
                                ))
                                :
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><NoDataCard /></div>
                        }
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane style={{ fontSize: "13px", fontWeight: "bold" }} tab="USERS LEAVES" key="2" onChange={() => {
                    setPageLoader(true)
                    getLeaveOverViewData(() => { setPageLoader(false) })
                }}
                >
                    <AllTeachersViewAll />
                </Tabs.TabPane>
            </Tabs>
            <PageDataLoader visible={pageLoader} />
        </div >
    )
}

const mapStateToProps = (state) => {
    const { storeAttendanceLeaveOverview, storeLeaveDate, filterRole, storeGraphDate } =
        state.attendanceNew;
    const { user } = state.session
    return {
        storeAttendanceLeaveOverview, storeLeaveDate, filterRole, storeGraphDate

    };
};

const mapDispatchToProps = (dispatch) => ({

    attendnaceLeaveOverview: (callback, selectedLeaveTypes) => dispatch(attendnaceLeaveOverview(callback, selectedLeaveTypes)),
    storeStaffLeave: (val) => dispatch(storeStaffLeave(val)),
    roleFilter: (val) => dispatch(roleFilter(val)),
    typeFilter: (val) => dispatch(typeFilter(val)),
    getLeaveOverViewData: (val) => dispatch(getLeaveOverViewData(val)),
    setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
    setGraphDate: (val) => dispatch(setGraphDate(val)),
    storeUsersName: (val) => (dispatch(storeUsersName(val))),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveOverview)