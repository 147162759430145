
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { Checkbox, Input, Typography, Pagination, Select } from 'antd';
import { IMG_URL } from "../../../env.json";
import UserAvatar from 'components/userAvatar'

import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getCourseStudentsData } from 'actions/courseManagement';
import ConfirmDeleteModal from 'pages/classRoom/modals/confirmDeleteModal';
import { removeStudents, setAllStudentsPageParam } from 'actions/classRoom';
import ConfirmDeleteStudentModal from 'pages/adminMarketPlaceCourse/components/studentDeleteFomEnoled';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
import { Api } from 'services'
import { InputSearch } from 'components/customInputs';
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2';
import { setOthersSelectedTab } from 'actions/profileNew';
const illNoData = require("../../../Assets/feeManagement/ill-no-data.svg").default;

const { Option } = Select;





const bodyStyle = {
	width: "calc(100% + 30px)",
	height: "calc(100% + 40px)",
	margin: -20,
	padding: 20,
	overflow: "auto",
};
const tableDataStyle = {
	color: "#191919", textAlign: "center", fontSize: 14, fontFamily: "roboto"
}


function StudentEnrolledTable({ user, getCourseStudentsData, allStudentsEnrolledData, setAllStudentsPageParam, allStudentsParamsObj, courseTypeName, specificCourseDataContainer, setUserDetails, setSpecificUserId, setOthersSelectedTab }) {
	const [selectedStudents, setSelectedStudents] = useState([])
	const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [pageLoader, togglePageLoader] = useState(false);
	const [fomAssign, setFromAssign] = useState(false)


	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		setAllStudentsPageParam("search", null);
		setAllStudentsPageParam("page", 1);
		setAllStudentsPageParam("limit", 10);
		setAllStudentsPageParam("standardIds", []);
		// storeStudentRollNo(null)
		togglePageLoader(true)
		getCourseStudentsData(() => togglePageLoader(false))
	}, [])

	useEffect(() => {
		setSelectedStudents([])
		togglePageLoader(true)
		getCourseStudentsData(() => togglePageLoader(false))
	}, [allStudentsParamsObj?.search, allStudentsParamsObj?.page, allStudentsParamsObj?.limit, allStudentsParamsObj?.standardIds?.length])

	const handleCheckbox = (value) => {

		setSelectedStudents((prevSelectedOptions) => {
			const isChecked = prevSelectedOptions.includes(value);
			if (isChecked) {
				const updatedOptions = prevSelectedOptions.filter((option) => option !== value);
				setSelectedStudents(updatedOptions)
			} else {
				const updatedOptions = [...prevSelectedOptions, value];
				setSelectedStudents(updatedOptions)
			}
		});
	};
	function onSelectAll(e) {

		let temp = [];
		if (e.target.checked) {
			for (let i = 0; i < allStudentsEnrolledData?.students?.items?.length; i++) {
				temp.push(allStudentsEnrolledData?.students?.items?.[i]?.id)
			}
		}
		setSelectedStudents([...temp])

	}

	const handlePageination = (value) => {
		setAllStudentsPageParam("page", value);
		setSelectedStudents([])
		togglePageLoader(true);
		getCourseStudentsData(() => { togglePageLoader(false) })

	};
	const handleSearch = (e) => {
		setAllStudentsPageParam("search", e);
	}

	const actions = [
		selectedStudents?.length > 1 ? <Button style={{ marginRight: 20, backgroundColor: "#1089FF", color: "#FFFFFF", borderRadius: 16, }} onClick={() => { setFromAssign(true), setConfirmDeleteModal(true) }}>UNASSIGN</Button> : <></>,
		selectedStudents?.length > 1 ? <Button style={{ marginRight: 20, backgroundColor: "#FF414D", color: "#FFFFFF", borderRadius: 16, }} onClick={() => { setFromAssign(false), setConfirmDeleteModal(true) }}>DELETE</Button> : <></>,
		!selectedStudents?.length && <InputSearch
			allowClear
			placeholder="Search by name / mobile number"
			onEnter={(e) => { handleSearch(e) }}
			onClear={() => { handleSearch("") }}
			value={allStudentsParamsObj?.search}
			style={{ width: 300, marginRight: 20 }} />,
	];
	return (
		<div style={bodyStyle}>
			<div style={{ marginBottom: 90 }}>
				<PageHeader
					title={'Students enolled'}
					// subtitle={location?.state?.key == "fromCourseStudents" ? null : pageHeaderSubtitle}
					actions={actions}
					onBackPress={() => history.goBack()}
				/>
			</div>
			<div>
				<div style={{ width: "100%", paddingBottom: "20px",/*  height: "78vh", overflowY: "scroll" */ }}>
					<table style={{ width: "100%", overflowY: "scroll" }}>
						<thead>
							<tr style={{ backgroundColor: "#F6F4FE", padding: "20px", height: "50px", fontFamily: "roboto" }}>

								<th style={{ color: "#636363", }}>
									<Checkbox className='m-r-10' checked={selectedStudents?.length === allStudentsEnrolledData?.students?.items?.length && selectedStudents?.length !== 0} onChange={onSelectAll} />

								</th>

								<th style={{ color: "#636363", textAlign: "left", width: allStudentsEnrolledData?.students?.items?.length == 0 ? '' : 210 }}>Name</th>
								<th style={{ color: "#636363", textAlign: "left", width: allStudentsEnrolledData?.students?.items?.length == 0 ? '' : 150 }}>Mobile No.</th>
								<th style={{ color: "#636363", textAlign: "left", width: allStudentsEnrolledData?.students?.items?.length == 0 ? '' : 160 }}>EMAIL ID</th>
								<th style={{ color: "#636363", textAlign: "left", width: allStudentsEnrolledData?.students?.items?.length == 0 ? '' : 100 }}>ENROLLED DATE</th>
								<th style={{ color: "#636363", textAlign: "left", width: allStudentsEnrolledData?.students?.items?.length == 0 ? '' : 70 }}>FEE</th>
								{/* <div></div> */}
								<th style={{ color: "#636363", textAlign: "left", width: allStudentsEnrolledData?.students?.items?.length == 0 ? '' : 100 }}>ACTIONS</th>
								{/* <th  style={{ color: "#636363", textAlign:"left"}}></th> */}

							</tr>
						</thead>
						{console.log("allStudentsEnrolledData", allStudentsEnrolledData)}
						{allStudentsEnrolledData?.students?.items?.map((item, index) => (
							<tr style={{ cursor: "pointer" }}
               onClick={(e) => {
                  if(!hasMarketingPrivilege){
								e.stopPropagation()
								setUserDetails(item)
								setSpecificUserId(item?.id);
								setOthersSelectedTab('Basic Profile');
								history.push({
									pathname: '/user-management/profile',
									state: {
										key: true
									}
								})
                }
							}}>
								<td style={{ width: 70 }} onClick={(e) => {
									e.stopPropagation()

								}}>

									<Checkbox className='m-r-10' checked={selectedStudents?.includes(allStudentsEnrolledData?.students?.items?.[index]?.id)} onChange={(e) => {
										handleCheckbox(item?.id)
									}} />
								</td>
								<td>
									<div style={{ marginTop: "10px", display: "flex", alignItems: "center", marginLeft: location?.state?.key == "fromCourseStudents" ? "2%" : ((user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin'))) ? '30%' : '35%', }}>
										<UserAvatar size="4vmin" shape="circle" name={item?.name} img={item?.image == "default.png" ? "" : `${Api._s3_url}${item?.image}`} />

										<Typography.Text ellipsis={{ tooltip: true }} className='m-l-20' style={{ ...tableDataStyle, maxWidth: 120 }}>{item?.name}</Typography.Text>
									</div>
								</td>
								<td><div style={{ ...tableDataStyle, textAlign: "left", marginTop: 10 }}>{item?.phoneNo}</div></td>
								<td><div style={{ ...tableDataStyle, textAlign: "left", marginTop: 10 }}>{item?.email}</div></td>
								<td><div style={{ ...tableDataStyle, textAlign: "left", marginTop: 10 }}>{moment(item?.enrolledOn).format('DD-MM-YYYY')}</div></td>
								<td><div style={{ ...tableDataStyle, textAlign: "left", marginTop: 10 }}>{item?.enrolledFee}</div></td>
								<td style={{ display: "flex", justifyContent: "left", marginTop: "14px" }}>
									<div style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
										<div style={{ ...tableDataStyle, color: "#1089FF", fontWeight: "bold", marginRight: 20, cursor: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? "not-allowed" : "pointer", opacity: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? 0.5 : 1 }}
											onClick={(e) => {
												e.stopPropagation()

												if (!(selectedStudents?.length > 1 && selectedStudents?.includes(item?.id))) {
													setFromAssign(true), setConfirmDeleteModal(true)
													setSelectedStudents([item?.id])
												}
											}}>UNASSIGN</div>
										<div style={{ ...tableDataStyle, color: "#FF414D", fontWeight: "bold", cursor: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? "not-allowed" : "pointer", opacity: selectedStudents?.length > 1 && selectedStudents?.includes(item?.id) ? 0.5 : 1 }}
											onClick={(e) => {
												e.stopPropagation()

												if (!(selectedStudents?.length > 1 && selectedStudents?.includes(item?.id))) {
													setFromAssign(false),
														setConfirmDeleteModal(true); setSelectedStudents([item?.id])
												}
											}}>DELETE</div>
									</div>
								</td>
							</tr>))}

					</table>
				</div>
			</div>
			{allStudentsEnrolledData?.students?.items?.length == 0 ?
				<div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
					<div><img src={illNoData} /></div>
					<div><h4><b>No data!</b></h4></div>
					<div><h5>There are no data to show yet. Please check back later</h5></div>
				</div>
				: null
			}
			<PageDataLoader visible={pageLoader} />

			{allStudentsEnrolledData?.students?.items?.length > 0 ?
				<div className='r-jsb'>
					<div style={{ color: "#636363", }}>
						<Select
							style={{ width: 100, marginRight: 10 }}
							defaultValue={"10/ page"}
							value={allStudentsParamsObj?.limit}
							onChange={(value) => {
								setAllStudentsPageParam("limit", value);
								setAllStudentsPageParam("page", 1);

								togglePageLoader(true);
								getCourseStudentsData(location.state.courseTypeName, () => { togglePageLoader(false) })

							}}>
							<Option value={10}>10/ page</Option>
							<Option value={12}>12/ page</Option>
							<Option value={16}>16/ page</Option>
							<Option value={18}>18/ page</Option>
						</Select>
						{allStudentsEnrolledData?.students?.meta?.itemsPerPage *
							(allStudentsEnrolledData?.students?.meta?.currentPage - 1) +
							1}{" "}
						-{" "}
						{allStudentsEnrolledData?.students?.meta?.itemsPerPage *
							allStudentsEnrolledData?.students?.meta?.currentPage <
							allStudentsEnrolledData?.students?.meta?.totalItems
							? allStudentsEnrolledData?.students?.meta?.itemsPerPage *
							allStudentsEnrolledData?.students?.meta?.currentPage
							: allStudentsEnrolledData?.students?.meta?.totalItems}{" "}
						of {allStudentsEnrolledData?.students?.meta?.totalItems} items
					</div>
					<div>
						<Pagination
							size="small"
							current={allStudentsEnrolledData?.students?.meta?.currentPage}
							defaultCurrent={1}
							total={allStudentsEnrolledData?.students?.meta?.totalPages * 10}
							onChange={handlePageination}
							showSizeChanger={false}
						/>
					</div>
				</div>
				:
				<></>
			}
			{showConfirmDeleteModal &&
				<ConfirmDeleteStudentModal
					showConfirmDeleteModal={showConfirmDeleteModal}
					setConfirmDeleteModal={setConfirmDeleteModal}
					togglePageLoader={togglePageLoader}
					selectedStudents={selectedStudents}
					setSelectedStudents={setSelectedStudents}
					fomAssign={fomAssign}
					setFromAssign={setFromAssign}
					allStudentsEnrolledData={allStudentsEnrolledData}
					getCourseStudentsData={getCourseStudentsData}
					courseTypeName={courseTypeName}
					courseName={specificCourseDataContainer?.name}
					courseId={specificCourseDataContainer?.id}
				/>
			}


		</div>
	)
}
const mapStateToProps = (state) => {
	const { user } = state.session
	const { allStudentsEnrolledData, allStudentsParamsObj, courseTypeName, specificCourseDataContainer } = state.courseManagement;




	return {
		user, allStudentsEnrolledData, allStudentsParamsObj, courseTypeName, specificCourseDataContainer
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCourseStudentsData: (callBack) => dispatch(getCourseStudentsData(callBack)),
	removeStudents: (params, callBack, successCallBack) => dispatch(removeStudents(params, callBack, successCallBack)),
	setAllStudentsPageParam: (key, val) => dispatch(setAllStudentsPageParam(key, val)),
	setUserDetails: (res) => dispatch(setUserDetails(res)),
	setSpecificUserId: id => dispatch(setSpecificUserId(id)),
	setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab))





});

export default connect(mapStateToProps, mapDispatchToProps)(StudentEnrolledTable);
// export default StudentEnrolledTable