import { Button, Input, Modal } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { connect } from 'react-redux';
import { postSubscription, hoursCount, storeSubscription } from '../../actions/profile'
// import { RAZORPAY_CREDS } from "../../env.json";




const PlanDetailModal = ({ storeSubscription, isModalOpen, setIsModalOpen, storeSubscriptionPlan, postSubscription, hoursCount, countingHours }) => {
    const [price, setPrice] = useState(null)
    // console.log("pricesss", price);
    useEffect(() => {
        console.log("storeSubscriptionPlan?.price", price);
        setPrice(parseFloat(storeSubscriptionPlan?.price))
        // console.log()
        document.title = "aulas";
        const razorpayScript = document.createElement("script");
        razorpayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(razorpayScript);
    }, [])

    const successCallback = () => {
        setIsModalOpen(false)
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        hoursCount(1)
    };

    const handleSuccess = () => {
        setIsModalOpen(false)
        hoursCount(1)
    }


    const openRazorPay = useCallback(
        (price) => {
            let amount = 0;
            amount = parseFloat(price) * countingHours
            let gst = parseFloat(price) * countingHours * 0.18;
            let amountGst = amount + gst;
            amountGst = amountGst.toFixed(2);
            // console.log("AA-Conting Hours Inside Modal", countingHours);
            // console.log("userHours", parseFloat((parseFloat(price * countingHours) + (parseFloat(price * countingHours) * 0.18))).toFixed(2) * 100);

            let options = {
                // "key": "rzp_live_rNxcoMgwpEVaxG",
                // "key_secret": "22RPEmRHUgNCZTpepDNreYz7",

                // "key": "rzp_test_rpXWPPV6VYnJyg",
                // "entity": "invoice",

                "key": "rzp_live_gzJ7uU1TrD46MO",
                "key_secret": "3xo1aQGRsg9LK05gpJvcLSUs",
                "amount": Math.round(parseFloat(amountGst) * 100), 
                // 2000 paise = INR 20, amount in paisa
                "name": "aulas",
                "description": "aulas Storage Annual Subscription",
                "curreny": "INR",
                "handler": function (response) {
                    if (response.razorpay_payment_id) {
                        // self.submit(response.razorpay_payment_id);
                        let object = {
                            // storage: space,
                            userHours: storeSubscriptionPlan?.userHours,
                            finalCost: amountGst,
                            tax: gst,
                            planCost: amount,
                            discount: 0,
                            netCostExTaxes: 0,
                            repeat: countingHours,
                            razorpay_payment_id:response.razorpay_payment_id

                        }
                        // setPageLoader(true)
                        handleSuccess()
                        postSubscription(object, () => successCallback)
                        console.log('res-razor', response);
                    }
                },
                // "prefill": {
                // "name": "kooljit",
                // "email": "kooljit.das@brigosha.com"
                // },
                "theme": {
                    // "color": Color.green28
                }
            };
            let rzp = new window.Razorpay(options);
            rzp.open();
        },
        [countingHours],
    )
    return (
        <>
            <Modal
                centered
                className="modal-round-corner"
                visible={isModalOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={null}

            >
                <div style={{ fontSize: "21px", fontFamily: "Roboto", fontWeight: "bold", textAlign: "center", marginTop: "20px" }}>Plan Details</div>
                <div style={{ width: "95%", marginLeft: '10px' }}>
                    <div style={{ display: "flex", marginTop: "25px" }}>
                        <div style={{ fontWeight: '700', fontSize: 35, fontFamily: "PT-Serif" }}>{storeSubscriptionPlan?.userHours}</div>
                        <div style={{ fontWeight: '700', fontSize: 21, fontFamily: "PT-Serif", marginTop: "15px", marginLeft: "8px" }}>User Hours</div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <div style={{ color: "#636363", fontWeight: 500, fontFamily: "Roboto" }}>Price</div>
                        <div style={{ color: "#636363", fontWeight: 500, fontFamily: "Roboto", marginRight: "45px" }}>Quanities</div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", }}>
                        <div style={{ fontSize: 13, fontFamily: "Roboto" }}> ₹ {storeSubscriptionPlan?.price}</div>
                        <div>
                            {console.log("AA-countingHours", countingHours)}
                            <Input
                                style={{ width: "100px", }}
                                type="number"
                                defaultValue={"1"}
                                value={countingHours}
                                onChange={(e) => {
                                    if (e.target.value === 0) {
                                        console.log("AA-countingvalue", e.target.value);
                                        e.target.value = 1;
                                    }
                                    hoursCount(parseInt(e.target.value))
                                }}
                                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (countingHours - 1 > 0) {
                                            hoursCount(parseInt(countingHours = countingHours - 1))
                                        }
                                    }}
                                />}
                                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                                    onClick={() => { hoursCount(parseInt(countingHours = countingHours + 1)) }}
                                />}
                            />
                        </div>

                    </div>
                    <div>
                    </div>
                    <div style={{ fontSize: "12px", fontFamily: "Roboto", marginTop: "15px" }}>Amount details</div>
                </div>

                <div
                    style={{
                        marginTop: "25px",
                        marginLeft: "10px",
                        borderRadius: "6px",
                        alignItems: "center",
                        Width: 170,
                        height: 120,
                        border: "1px solid #E6E6E6",
                        backgroundColor: "#FFFFFF",
                    }}>
                    <div style={{ width: "95%", marginLeft: '10px' }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <div style={{ fontFamily: "Roboto", fontSize: "13px" }}>Subtotal</div>
                            <div style={{ fontFamily: "Roboto", fontSize: "13px", }}> ₹ {countingHours > 0 ? storeSubscriptionPlan?.price * countingHours : 0}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <div style={{ fontFamily: "Roboto", fontSize: "13px", }}>IGST</div>
                            <div style={{ fontFamily: "Roboto", fontSize: "13px", }}>+ ₹ {parseFloat((0.18 * parseFloat(countingHours > 0 ? storeSubscriptionPlan?.price * countingHours : 0)).toFixed(2))}</div>
                        </div>
                        <div style={{ borderBottom: "1px solid #E6E6E6 ", marginTop: "8px" }}></div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ color: "#191919", fontFamily: "Roboto", fontSize: "13px", fontWeight: "700", marginTop: "10px" }}>Total payable</div>
                            <div style={{ color: "#191919", fontFamily: "Roboto", fontSize: "13px", fontWeight: "700", marginTop: "10px" }}>₹ {parseFloat(parseFloat(1.18 * parseFloat(countingHours > 0 ? storeSubscriptionPlan?.price * countingHours : 0)).toFixed(2))}</div>

                        </div>
                        <div style={{ borderBottom: "1px solid #E6E6E6 ", marginTop: "8px" }}></div>


                    </div>

                </div>
                <div style={{ width: "55%", display: "flex", justifyContent: "space-between", marginTop: "40px", marginLeft: "100px" }}>
                    <div >
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                fontWeight: "500",
                                border: "1px solid #636363 ",
                                backgroundColor: "#FFFFFF",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#636363 ",
                                    fontFamily: "Roboto",
                                    fontWeight: "bold",
                                }}
                                onClick={handleCancel}
                            >
                                CANCEL
                            </div>
                        </Button>
                    </div>
                    <div style={{}}>
                        <Button
                            disabled={!countingHours || countingHours === "" || countingHours === NaN}
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                fontWeight: "500",
                                border: "1px solid #636363 ",
                                backgroundColor: "#594099",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#FFFFFF ",
                                    fontFamily: "Roboto",
                                    fontWeight: "bold",
                                }}
                                onClick={() => { openRazorPay(storeSubscriptionPlan?.price) }}
                            >
                                PAY
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    const {
    } = state.digitalLibrary;
    const { countingHours } = state.profile;

    const { user } = state.session;
    return {
        countingHours
    };
};

const mapDispatchToProps = (dispatch) => ({
    postSubscription: (object, callback, successCallback) => dispatch(postSubscription(object, callback, successCallback)),
    hoursCount: (res) => dispatch(hoursCount(res)),
    storeSubscription: (res) => dispatch(storeSubscription(res))

    // setAulasCareChat: (chat) => dispatch(setAulasCareChat(chat))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetailModal);

// export default PlanDetailModal;

