import React from 'react'

export const CountBadge = ({count, style}) => {
    if (!count){
      return  null
    }
    return (
      <div
      style={
        {
          display:"flex",
          flexDirection:"row",
          alignItems:"center",
          justifyContent:"center",
          backgroundColor: "#FFA931",
          width: 20,
          height: 20,
          borderRadius: 50,
          position: 'absolute',
          top: -5,
          right: 0,
          ...style
        }
      }>
      <div style={{color: '#fff', fontSize: 10, fontWeight: '700'}}>
        {count?count>99?'99+':count:''}
      </div>
    </div>
    )
  }

const ActionButtons = ({onClick, icon, label, unreadCount=0, badgeStyle={}, type})=>{
    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", zIndex:10, position:"relative"}}>
        <div className={`m-b-5 r-c-c disable-text-selection ${label==='Leave'?'actBtnBc':'actBtnBc'} cursor-pointer`} style={{borderRadius:"50%", position:"relative"}} 
            onClick={onClick}>
                {icon}
                {
                  type==='mic'?
                  <div
                  id={'broadcast-profile-border'}
                    style={{
                        position:"absolute",
                        width: "3vw",
                        height: "3vw",
                        borderRadius: "50%",
                        zIndex: -1,
                        visibility: label==='Mic on' ? 'visible':'hidden',
                        backgroundColor: "rgba(62, 62, 62, 0.5)",
                    }}
                  ></div>
                  :false
                }

        </div>
        <div className="black rfbc0p8 disable-text-selection" style={{height:'0.8vw'}}>{label}</div>
        {
            unreadCount?
            <CountBadge count={unreadCount} style={badgeStyle}/>
            :null
        }
        </div>
    )
}


export default ActionButtons;