import { RightOutlined } from "@ant-design/icons";
import React from "react";
// import image from 'Assets/user-management/ic-students.svg'

function AddUserCard({ image, role }) {
  return (
    <div
      style={{
        border: "#302A5F14 solid 1px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-between",
        padding: "2px",
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        paddingLeft: "4%",
        paddingRight: "4%",
        marginBottom: "2%",
        cursor: "pointer",
      }}
    >
      <div>
        <img
          style={{
            width: "55px",
            height: "55px",
            marginRight: "20px",
            margin: "5px",
          }}
          src={image}
          alt=""
        />
        <span style={{ fontWeight: "bold", marginLeft: "10px" }}>{role}</span>
      </div>
      <p ><RightOutlined style={{ fontSize: "20px", color: "#8C8C8C" }}/></p>
    </div>
  );
}

export default AddUserCard;
