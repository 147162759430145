import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";


const BarGraph = ({data}) => {


  const config = {
    data,
    xField: "type",
    yField: "value",
    tooltip:
    {customContent:(val,item)=>{
      console.log("hh",item)
      return(
        <div>
          <div style={{
            height:"50px",
            minWidth:"150px"
          }}>
            <div style={{fontSize:"12px",fontFamily:"Roboto",fontWeight:"bold",marginTop:"20px"}}>AVERAGE ATTENDANCE</div>
              <div  style={{display:"flex",justifyContent:"space-between",marginTop:"10px",width:"150px"}}>
              <div style={{display:"flex"}}>
              <div style={{fontSize:"14px",fontFamily:"Roboto",fontWeight:"bold"}}>{item[0]?.data?.studentCount}</div>
              <div style={{fontSize:"10px",fontFamily:"Roboto",marginLeft:"2px"}}>Students</div>
            </div>
            <div style={{fontSize:"14px",fontFamily:"Roboto",fontWeight:"bold"}}>{item[0]?.data?.value} %</div>
         </div>
        </div>
          
          
        </div>
        
      )   
    }},
    color: "#9876E6",
    
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  
    meta: {
      type: {
        alias: "Attendance",
      },
      // attendance: {
      //   alias: "Attendance",
      // },
    },
    slider: {
      start: 0.1,
      end: 0.1,
    },

    minColumnWidth: 20,
    maxColumnWidth: 40,
  };
  return <Column {...config} />;
};
export default BarGraph;
