import React from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import { } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { changeTestStatusModal, changeTestStatus } from 'actions/testAdmin';
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdPublish, MdKeyboardBackspace } from "react-icons/md";
import '../styles.css';
import { Color } from 'services';
import { Col, Row, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;


function TestPublishModal({ history, changeTestStatusModalBool, changeTestStatusModal, modalActiveName,
  changeTestStatus, changeTestStatusBool, generateQuesObj, selectedTest, location, isFromClassRoomDetails}) {
  const history1 = useHistory();
  return (
    <Modal
      centered
      className='modal-round-corner'
      style={{ padding: 0 }}
      visible={changeTestStatusModalBool}
      onCancel={null}
      onOk={null}
      footer={null}
      closable={false}
    >
      {
        modalActiveName === 'ExitTest'
          ?
          <div>
            <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Exit create test ?</div>
            <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>Are you sure you want to exit create test?</div>
          </div>
          : modalActiveName === 'Published'
            ?
            <div>
              <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Publish Test</div>
              <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>Are you sure you want to publish this test?</div>
            </div>
            :
            modalActiveName == 'Ready'
              ?
              <div>
                <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Mark as ready</div>
                <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>
                  Are you sure you want to mark this test as ready for publishing?
                </div>
              </div>
              :
              modalActiveName == 'MarkReady'
                ?
                <div>
                  <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Mark as ready</div>
                  <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>
                    Are you sure you want to mark this test as ready for publishing?
                  </div>
                </div>
                :
                modalActiveName == 'Feedback'
                  ?
                  <div>
                    <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Send Feedback</div>
                    <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>
                      Are you sure you want to send this Feedback to
                      <sapn className='bold-600 color-black'> {generateQuesObj.authorName != undefined ? generateQuesObj.authorName : ''}? </sapn>
                    </div>
                  </div>
                  :
                  modalActiveName == 'SendReview'
                    ?
                    <div>
                      <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Send for review</div>
                      <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>
                        Are you sure you want to send the test for review to
                        <sapn className='bold-600 color-black'> {selectedTest.teacherName}? </sapn>
                      </div>
                    </div>
                    :
                    <div>
                      <div className='text-sm text-center m-t-10 m-b-10' style={{ fontSize: 24, fontWeight: "bold" }}>Send for review</div>
                      <div className='text-xs text-center m-b-20' style={{ fontSize: 16, color: "#636363" }}>
                        Are you sure you want to send the test for review to
                        <span className='bold-600 color-black'> {generateQuesObj?.test ? generateQuesObj?.test?.teacherName : generateQuesObj?.selectedTeacher != null ? generateQuesObj?.selectedTeacher : generateQuesObj?.teacherName}? </span>
                      </div>
                    </div>
      }
      <div className='r-c-c m-t-50'>
        <Button onClick={() => changeTestStatusModal(modalActiveName)} className='blankGreyBtnWrap radius-100'>CANCEL</Button>
        <Button
          onClick={() => {
          //   if (location?.state?.key == "2") {
          //     changeTestStatusModal('');
          //     changeTestStatus(history,'2')
          //     history1.push('/test')
          //   } else {
          //     if (modalActiveName !== 'ExitTest') {
          //       changeTestStatus(history,'')
          //     }
          //   }
          // }}
          if(location?.state?.key == "2"){
                changeTestStatus(history,"2")
              }else if(location?.state?.key == "fromClassroom"){
                changeTestStatusModal(modalActiveName);
                history1.push({pathname:"/test-std-to-subjects-screen", state:{key:"fromClassroom"}})
              }else if(location?.state?.key != "fromClassroom" && modalActiveName === 'ExitTest'){
                changeTestStatusModal(modalActiveName);
                history1.push('/test')
              }else if(modalActiveName == "Review" && location?.state?.key == "2"){
                changeTestStatus(modalActiveName,"2")
              }else if(modalActiveName !== 'ExitTest'){
                if(isFromClassRoomDetails){
                  changeTestStatus(history,"fromClassroom")
                }else{
                  changeTestStatus(history,"")
                }
              }
            }}
          loading={changeTestStatusBool}
          className='purpleBtnWrap radius-100 m-l-20'>{location?.state?.key == "1"  ? "YES, I WANT TO EXIT" : "YES"}</Button>
      </div>
    </Modal>
  );
}


const mapStateToProps = state => {
  const { changeTestStatusModalBool, modalActiveName, changeTestStatusBool, generateQuesObj,
    selectedTest } = state.testAdmin;
  const {isFromClassRoomDetails} = state.classRoom
  return {
    changeTestStatusModalBool, modalActiveName, changeTestStatusBool, generateQuesObj,
    selectedTest, isFromClassRoomDetails
  }
};

const mapDispatchToProps = dispatch => ({
  changeTestStatusModal: (name) => dispatch(changeTestStatusModal(name)),
  changeTestStatus: (history,path) => dispatch(changeTestStatus(history,path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestPublishModal);
