import { Modal } from 'antd';
import React from 'react';
import {Button} from 'components/Button';

const LogoutConfirm = ({visible, onCancel, onConfirm}) => {
    return (
        <Modal
            visible={visible}
            className="modal-round-corner"
            footer={false}
            closable={false}
        >
            <div  classname="r-c-c-c" style={{padding: 40}}>
                <div  className='r-c-c' style={{fontSize: 20, fontWeight: 500}}>Hope to see you back soon!</div>
                <div className='r-c-c m-t-10' style={{fontSize: 16, color: "#636363"}}>Are you sure you want to logout?</div>
                <div className='r-c-c m-t-50 '>
                    <Button type="primary" style={{width: "80%", fontWeight: 'bold'}} onClick={onCancel}>
                        CANCEL
                    </Button>
                </div>
                <div className='r-c-c m-t-20'>
                    <Button type="transparent" style={{fontWeight: 'bold', color: "#FF414D"}} onClick={onConfirm}>
                        LOGOUT
                    </Button>
                </div>
            </div>
            
        </Modal>
    );
}

export default LogoutConfirm;