import { searchUsers, transferSuperAdmin } from 'actions/profileNew';
import { AutoComplete, Checkbox, Modal } from 'antd';
import { Button } from 'components/Button';
import SuccessModal from "./successModal";

import { InputSearch } from 'components/customInputs';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { LabelDiv } from './detailFields';
import './styles.css';

const TransferOwnershipModal = ({visible, handleClose, user, searchUsers, searchedUsers, transferSuperAdmin, setShowTransferModal}) => {
    const [search, setSearch] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [updatePrimaryContact, setUpdatePrimaryContact] = useState(false);
    const [buttonLoader, updateButtonLoader] = useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);

    const history = useHistory();
    console.log({selectedUser});

    const handleSuccessCallback = () => {
        setSuccessModalVisible(true);
      };
    

    const handleConfirmClick = () => {
        updateButtonLoader(true);
        const params = {
            userId: selectedUser?.id,
            updatePrimaryContact
        }
        transferSuperAdmin(params, () => updateButtonLoader(false), ()=>{setSuccessModalVisible(true);setShowTransferModal(false)}, history)
    }

    return (
        <Modal
            visible={visible}
            className="modal-round-corner"
            centered
            header={null}
            footer={null}
            onCancel={handleClose}
            width={900}
        >
            <div 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "25px",
                }}
            >   
                <div
                    style={{ fontSize: "18px", fontWeight: 600 }}
                >
                    Transfer ownership
                </div>
                <div style={{textAlign: 'center', color:"#636363", width: "80%"}}>
                     We’re sad to see you go as Super Administrator of {user.org.name}! Before proceeding, please note that ownership can only be transferred to another user (Teacher/Data Operator/Operations) who is already added in the User management.
                </div>
                <div style={{width: "80%"}} className="m-t-20">
                    <LabelDiv>
                        User name
                    </LabelDiv>
                    <AutoComplete
                        value={search}
                        onChange={(value, obj) => {setSearch(value); setSelectedUser(null);}}
                        options={searchedUsers}
                        style={{border:'none', width:'100%', fontSize:16, fontWeight:600}}
                        loading={searchLoading}
                        onSelect={(value, obj) => {
                            setSelectedUser(obj);
                        }}
                        onSearch={(query) => {
                            setSearchLoading(true);
                            searchUsers(query, () => setSearchLoading(false))
                        }}
                    />
                    <div className='m-t-20' style={{color: "#636363"}} onClick={() => setUpdatePrimaryContact(!updatePrimaryContact)}><Checkbox checked={updatePrimaryContact} />&nbsp; Make the new Super Administrator as Secondary contact</div>
                    {/* <div className='m-t-10' style={{color: "#636363"}}><Checkbox />&nbsp; Delete my account! I no longer require access to this institute</div> */}
                </div>
                <Button type="primary" 
                    style={{marginTop: 100}} loading={buttonLoader}
                    onClick={() => {handleConfirmClick();handleSuccessCallback()}}
                    disabled={!selectedUser}
                >CONFIRM TRANSFER</Button>
            </div>
            <SuccessModal
            isModalVisible={successModalVisible}
            handleOk={handleConfirmClick}
            handleCancel={handleConfirmClick}
            />
           
        </Modal>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session
    const {searchedUsers} = state.profileNew;
    return {
        user,
        searchedUsers
    }
}

const mapDispatchToProps = (dispatch) => ({
    searchUsers: (search, callback) => dispatch(searchUsers(search, callback)),
    transferSuperAdmin: (params, callback, successCallback, history) => dispatch(transferSuperAdmin(params, callback, successCallback, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnershipModal);
