import { InfoCircleOutlined } from '@ant-design/icons';
import { getMarketPlaceSettings, setGsSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab, updateAttendanceReminderSettings, updateFeeSettings, updateMarketSettings, updateUserSettings } from 'actions/profileNew';
import { Col, Radio, Row, Switch, Tabs, TimePicker, Tooltip } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import moment from 'moment';
import AttendanceRequirement from 'pages/attendanceNew/attendanceRequirement';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { easySwitch } from './subComponents';
import MarketPlaceTab from './marketPlaceTab';
import GoLiveModal from './goLiveModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const days = {
    M: "monday",
    T: "tuesday",
    W: "wednesday",
    Th: "thursday",
    F: "friday",
    S: "saturday",
    Su: "sunday"
}

const tabs = (instituteProfile, updateSettings, saveRequirements, setSaveRequirements, user) => {
    const allTabs = {}
    if (user.privilage.includes("Admin") || user.privilage.includes("SuperAdmin")) {
        Object.assign(allTabs, {
            "User management": {
                body: (
                    <div>
                        <div style={{ fontSize: 18, fontWeight: 600 }} className="m-t-20">
                            Student's registration request
                        </div>
                        <div style={{ color: "#636363" }}>
                            student’s registration request to be auto approved.
                        </div>
                        <div className='m-t-20'>
                            {/* <Radio checked={instituteProfile?.settings?.studentAutoApprove === true} onClick={() => updateSettings(true, "studentAutoApprove")}>Auto approval</Radio> */}
                            <Radio checked={true} onClick={() => updateSettings(true, "studentAutoApprove")}>Auto approval</Radio>
                        </div>
                        {/* <div className='m-t-20'>
                            <Radio checked={instituteProfile?.settings?.studentAutoApprove === false} onClick={() => updateSettings(false, "studentAutoApprove")}>Manual approval</Radio>
                        </div> */}
                    </div>
                )
            },
        })
    }
    if (user.privilage.includes("FeeManager") || user.privilage.includes("SuperAdmin")) {
        Object.assign(allTabs, {
            "Fee management": {
                body: (
                    <div>
                        <div className='r-jsb m-t-20'>
                            <div style={{ fontSize: 18, fontWeight: 600 }} >
                                Processing fee
                            </div>
                            <div>
                                <Tooltip placement="left" title={
                                    <div>
                                        <div>UPI - Free</div>
                                        <div>Debit/credit card & Netbanking - 1.8%</div>
                                    </div>
                                }>
                                    <InfoCircleOutlined style={{ color: "#1089FF" }} />
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ color: "#636363" }}>
                            Selected party will bear the transaction charges while fee payment.
                        </div>
                        <div className='m-t-20'>
                            <Radio checked={instituteProfile?.settings?.transactionPaidBy === "student"} onClick={() => updateSettings("student", "transactionPaidBy")}>Processing fee payable by student</Radio>
                        </div>
                        <div className='m-t-20'>
                            <Radio checked={instituteProfile?.settings?.transactionPaidBy === "institute"} onClick={() => updateSettings("institute", "transactionPaidBy")}>Processing fee payable by institute</Radio>
                        </div>
                        <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 40 }} />
                        <div style={{ fontSize: 18, fontWeight: 600 }} className="m-t-20">
                            Payment invoice
                        </div>
                        <div style={{ color: "#636363" }}>
                            Generate and send payment invoice to payee automatically /manually after successfully transaction.
                        </div>
                        <div className='m-t-20'>
                            <Radio checked={instituteProfile?.settings?.invoiceType === "automatic"} onClick={() => updateSettings("automatic", "invoiceType")}>Automatic</Radio>
                        </div>
                        <div className='m-t-20'>
                            <Radio checked={instituteProfile?.settings?.invoiceType === "manual"} onClick={() => updateSettings("manual", "invoiceType")}>Manual</Radio>
                        </div>
                    </div>
                )
            }
        })
    }
    if (user.privilage.includes("LeaveManager") || user.privilage.includes("SuperAdmin")) {
        Object.assign(allTabs, {
            "Attendance": {
                body: (
                    <AttendanceRequirement isSubcomponent={true} saveRequirements={saveRequirements} setSaveRequirementsFalse={() => setSaveRequirements(false)} />

                )
            },
        })
    }
    Object.assign(allTabs, {
        "Attendance reminder": {
            body: (
                <div style={{ width: "100%" }}>
                    <div className='r-jsb' style={{ alignItems: 'center' }}>
                        <div>
                            <div style={{ fontSize: 18, fontWeight: 600 }} className="m-t-20">
                                Reminder alert
                            </div>
                            <div style={{ color: "#636363" }}>
                                You’ll be reminded to Clock in/Clout out at your preferred timings.
                            </div>
                        </div>
                        <div>
                            <Switch onChange={(e) => updateSettings(e, "reminderNeeded")} checked={instituteProfile.reminderNeeded} />
                        </div>
                    </div>
                    {instituteProfile.reminderNeeded &&
                        <>
                            <Row style={{ width: "100%", justifyContent: 'space-between' }} className="m-t-40">
                                <Col style={{ width: "50%" }}  >
                                    <div style={{ color: "#636363" }} className="m-b-5">
                                        Clockin alert
                                    </div>
                                    <TimePicker style={{ width: "90%" }}
                                        onChange={time => updateSettings(time, 'clockInTime')}
                                        inputReadOnly minuteStep={15} format="HH:mm"
                                        value={instituteProfile.clockInTime ? moment(instituteProfile.clockInTime, "HH:mm:ss") : null}
                                    />
                                </Col>
                                <Col style={{ width: "50%" }} >
                                    <div style={{ color: "#636363" }} className="m-b-5">
                                        Clockout alert
                                    </div>
                                    <TimePicker style={{ width: "90%" }}
                                        disabled={!instituteProfile.clockInTime}
                                        inputReadOnly
                                        onChange={time => updateSettings(time, 'clockOutTime')}
                                        minuteStep={15} format="HH:mm"
                                        value={instituteProfile.clockOutTime ? moment(instituteProfile.clockOutTime, "HH:mm:ss") : null}
                                    />
                                </Col>
                            </Row>
                            <div className="m-t-40">
                                <div style={{ color: "#636363" }} className="m-b-5">
                                    Repeat
                                </div>
                                <div>
                                    {Object.keys(days).map((day, index) =>
                                        <button key={index}
                                            style={{
                                                width: 45, height: 45, borderRadius: 25, cursor: 'pointer',
                                                fontWeight: instituteProfile?.repeatDays?.includes(day) ? "bold" : 'regular',
                                                color: instituteProfile?.repeatDays?.includes(day) ? "#594099" : '#AEAEAE',
                                                background: instituteProfile?.repeatDays?.includes(day) ? "#F6F4FE" : 'white',
                                                border: "1px solid #E6E6E6"
                                            }} className="m-r-10"
                                            onClick={() => updateSettings(day, 'repeatDays')}
                                        >
                                            {day}
                                        </button>)}
                                </div>
                            </div>
                        </>}
                </div>
            )
        }
    })
    if (user.privilage.includes("SuperAdmin")) {
        Object.assign(allTabs, {
            "Marketplace": {
                body: (
                    <div>
                        <MarketPlaceTab />
                    </div>
                )
            },
        })
    }
    return allTabs

}
const TabsComponent = ({ handleTabSelect, selectedTab, instituteProfile, updateSettings, saveRequirements, setSaveRequirements, user }) => {

    return (
        <Tabs onChange={handleTabSelect} activeKey={selectedTab}>
            {Object.keys(tabs(instituteProfile, updateSettings, saveRequirements, setSaveRequirements, user)).map((tab, index) => (
                <TabPane tab={tab} key={tab}>
                    {tabs(instituteProfile, updateSettings, saveRequirements, setSaveRequirements, user)[tab].body}
                </TabPane>
            )
            )}
        </Tabs>
    )
}


const GeneralSettings = ({ setGsSelectedTab, gsSelectedTab,
    instituteDetails, updateFeeSettings, updateUserSettings,
    updateAttendanceReminderSettings, attendanceReminderSettings, user,
    setSubscriptionSelectedTab, setOthersSelectedTab, updateMarketSettings, getMarketPlaceSettings, marketPlaceData
}) => {
    const history = useHistory();
    const location = useLocation()
    useEffect(() => {
        const repeatDays = attendanceReminderSettings?.settings?.repeat?.map(item => Object.keys(days).find(key => days[key] === item))
        setProfileDetails({
            ...instituteDetails,
            settings: { ...instituteDetails.settings },
            ...attendanceReminderSettings.settings,
            repeatDays, reminderNeeded: attendanceReminderSettings?.enabled
        })
    }, [instituteDetails, attendanceReminderSettings]);

    const [instituteProfile, setProfileDetails] = useState({});
    const [saveButtonHidden, setSaveButtonHidden] = useState(true);
    const [saveRequirements, setSaveRequirements] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [goLiveLoader, toggleGoLiveLoader] = useState(false);
    const [goLiveModal, toggleGoLiveModal] = useState(false);

    useEffect(() => {
        switch (gsSelectedTab) {
            case "User management":
                if (instituteProfile?.settings?.studentAutoApprove !== instituteDetails?.settings?.studentAutoApprove) {
                    setSaveButtonHidden(false)
                } else {
                    setSaveButtonHidden(true)
                }
                break;
            case "Fee management":
                if (instituteProfile?.settings?.transactionPaidBy !== instituteDetails?.settings?.transactionPaidBy ||
                    instituteProfile?.settings?.invoiceType !== instituteDetails?.settings?.invoiceType) {
                    setSaveButtonHidden(false)
                } else {
                    setSaveButtonHidden(true)
                }
                break;
            case "Attendance reminder":
                if (instituteProfile.reminderNeeded) {
                    if (!instituteProfile.clockInTime || !instituteProfile.clockInTime ||
                        !instituteProfile.repeatDays || !instituteProfile.repeatDays.length
                    ) {
                        setSaveButtonHidden(true)
                    } else {
                        setSaveButtonHidden(false)
                    }
                    if (instituteProfile.clockInTime === attendanceReminderSettings?.settings?.clockInTime &&
                        instituteProfile.clockOutTime === attendanceReminderSettings?.settings?.clockOutTime
                    ) {
                        setSaveButtonHidden(true)
                    }
                } else if (instituteProfile.reminderNeeded !== attendanceReminderSettings?.enabled) {
                    setSaveButtonHidden(false)
                } else {
                    setSaveButtonHidden(true)
                }
                break;
            case "Attendance":
                setSaveButtonHidden(false)
                break;
            case "Marketplace":
                setSaveButtonHidden(true)
                break;
        }
    }, [instituteProfile, gsSelectedTab])

    useEffect(() => {
        if (!saveRequirements) {
            setButtonLoader(false)
        }
    }, [saveRequirements])

    useEffect(() => {
        getMarketPlaceSettings()
    }, [marketPlaceData?.active])

    const handleTabSelect = (key) => {
        setGsSelectedTab(key);
    }

    const updateSettings = (value, type) => {
        switch (type) {
            case "studentAutoApprove":
            case "invoiceType":
            case "transactionPaidBy":
                instituteProfile.settings[type] = value;
                break;
            case "repeatDays":
                if (!instituteProfile.repeatDays) {
                    instituteProfile.repeatDays = []
                }
                if (instituteProfile?.repeatDays?.includes(value)) {
                    instituteProfile?.repeatDays.splice(instituteProfile?.repeatDays.indexOf(value), 1)
                } else {
                    instituteProfile?.repeatDays.push(value)
                }
                break;
            default:
                instituteProfile[type] = value;
                break;
        }
        setProfileDetails({ ...instituteProfile })
    }
    const handleSaveClick = () => {
        setButtonLoader(true)
        switch (gsSelectedTab) {

            case "User management":
                updateUserSettings({ studentAutoApproved: instituteProfile?.settings?.studentAutoApprove }, () => setButtonLoader(false))
                break;
            case "Fee management":
                updateFeeSettings(
                    { transactionPaidBy: instituteProfile?.settings?.transactionPaidBy, invoiceType: instituteProfile.settings?.invoiceType },
                    () => setButtonLoader(false))
                break;
            case "Attendance":
                setSaveRequirements(true)
                // saveButtonRef.click();
                break;
            case "Attendance reminder":
                const params = {
                    enabled: instituteProfile.reminderNeeded,
                    settings: {
                        clockInTime: instituteProfile.clockInTime === attendanceReminderSettings?.settings?.clockInTime ? instituteProfile.clockInTime : moment(instituteProfile.clockInTime).format("HH:mm:ss"),
                        clockOutTime: instituteProfile.clockOutTime === attendanceReminderSettings?.settings?.clockOutTime ? instituteProfile.clockOutTime : moment(instituteProfile.clockOutTime).format("HH:mm:ss"),
                        repeat: instituteProfile.repeatDays?.map(item => days[item])
                    }
                }
                updateAttendanceReminderSettings(params, () => setButtonLoader(false))
                break;
        }
    }

    const handleBackPress = () => {
        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get('tab');
        const tabNames = ["Banners", "Enquiry form", "Outstanding Results", "Testimonials", "Footer"];
        const decodedTab = decodeURIComponent(tab);
        if (location.pathname === '/general-settings' && tabNames.includes(decodedTab)) {
            history.push('/account-settings');
        } else if (location?.state?.key === 'fromAdminDashboard') {
            history.push('/customize');
        } else if (location?.state?.fromMarketPlace) {
            history.push('/market-place');
        } else {
            if (["/general-settings", "/view-kyc", "/subscriptions", "/support-settings", "/user-profile/others"].includes(history.location?.state?.from)) {
                history.push('/account-settings');
            } else {
                history.goBack();
            }
        }
    }

    const handleGoliveClick = () => {
        toggleGoLiveLoader(true)
        updateMarketSettings({ settingData: { active: marketPlaceData?.active == false && true } }, () => toggleGoLiveLoader(false), () => { toggleGoLiveModal(false); getMarketPlaceSettings() });
    }

    const handleGoOffline = () => {
        toggleGoLiveLoader(true)
        updateMarketSettings({ settingData: { active: marketPlaceData?.active == true && false } }, () => toggleGoLiveLoader(false), () => { toggleGoLiveModal(false); getMarketPlaceSettings() });
    }

    const openGoLiveModal = () => {
        toggleGoLiveModal(true)
    }

    const actions = [
        gsSelectedTab === 'Marketplace'
            ? !marketPlaceData?.active ? (
                <Button onClick={openGoLiveModal} type='transparent' style={{ fontWeight: 700, color: '#594099' }}>
                    GO LIVE
                </Button>
            ) : (
                <Button onClick={openGoLiveModal} type='transparent' style={{ fontWeight: 700, color: '#FF414D' }}>
                    GO OFFLINE
                </Button>
            ) : null,
        (gsSelectedTab != 'User management' && gsSelectedTab != 'Marketplace') && <Button type="link" style={{ fontSize: 14 }} loading={buttonLoader} onClick={handleSaveClick}>SAVE</Button>,
    ];

    return (
        <div div={{ height: "100%" }} >
            <PageHeader 
                title="General Settings"
                onBackPress={handleBackPress}
                actions={actions}
                easySwitch={easySwitch(history, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab).length > 1 ? easySwitch(history, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab) : undefined}
            />
            <div className='r-c-c'>
                <div style={{ width: "100%" }} className="m-t-50">
                    <TabsComponent
                        handleTabSelect={handleTabSelect}
                        selectedTab={gsSelectedTab}
                        saveRequirements={saveRequirements}
                        setSaveRequirements={setSaveRequirements}
                        instituteProfile={instituteProfile}
                        updateSettings={updateSettings}
                        user={user}
                    />
                </div>
            </div>
            {goLiveModal && <GoLiveModal handleGoliveClick={handleGoliveClick} open={goLiveModal} onCancel={() => toggleGoLiveModal(false)} goLiveLoader={goLiveLoader} handleGoOffline={handleGoOffline} active={marketPlaceData?.active} toggleGoLiveModal={toggleGoLiveModal} />}
        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { gsSelectedTab, instituteDetails, attendanceReminderSettings, marketPlaceData } = state.profileNew;
    return {
        gsSelectedTab, instituteDetails, attendanceReminderSettings, user, marketPlaceData
    }
}

const mapDispatchToProps = (dispatch) => ({
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    updateFeeSettings: (params, callback) => dispatch(updateFeeSettings(params, callback)),
    updateUserSettings: (params, callback) => dispatch(updateUserSettings(params, callback)),
    updateAttendanceReminderSettings: (params, callback) => dispatch(updateAttendanceReminderSettings(params, callback)),
    setSubscriptionSelectedTab: (tab) => dispatch(setSubscriptionSelectedTab(tab)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    updateMarketSettings: (params, callback, successCallback) => dispatch(updateMarketSettings(params, callback, successCallback)),
    getMarketPlaceSettings: () => dispatch(getMarketPlaceSettings())

})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
