import { CaretDownOutlined } from "@ant-design/icons";
import { changeCrmStatus, getAutocompleteCourses } from "actions/courseManagement";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button } from "components/Button";
import UserAvatar from "components/userAvatar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Api } from 'services'

const { Option } = Select;

const StatusChangeModal = ({ setIsModalVisible, isModalVisible, crmStatus, setCrmStatus, getEnquiryFormsData, getCoursesInCartData, getWhistledCoursesData,
    autocompleteCoursesSearchData, getAutocompleteCourses, changeCrmStatus, tab, togglePageLoader, setPage }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCourse, setSelectCourse] = useState(null);
    const [selectedCourseType, setSelectCourseType] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [comment, setComment] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);

    const validateForm = () => {
        if (!selectedStatus) {
            setIsFormValid(false);
            return;
        }

        if (selectedStatus === 'Not Interested' || selectedStatus === 'Interested' || selectedStatus === 'Contact Initiated') {
            setIsFormValid(true);
            return;
        }

        if (selectedStatus === 'Follow Up Requested') {
            const isInvalid = !selectedDate || !selectedTime ||
                !moment(selectedDate).isValid() || !moment(selectedTime, 'hh:mm a').isValid();
            setIsFormValid(!isInvalid);
            return;
        }


        setIsFormValid(false);
    };



    useEffect(() => {
        getAutocompleteCourses(() => { });
    }, []);

    useEffect(() => {
        setSelectedStatus(crmStatus?.crmStatus)
    }, [crmStatus]);

    const handleChange = (value) => {
        const selectedItem = autocompleteCoursesSearchData.find(item => item.id === value);
        setSelectedOption(selectedItem?.id);
        setSelectCourse(selectedItem ? selectedItem.itemId : null);
        setSelectCourseType(selectedItem ? selectedItem.itemType : null)
    };

    useEffect(() => {
        validateForm();
    }, [selectedOption, selectedCourse, selectedStatus, selectedDate, selectedTime, comment]);

    const handleOk = () => {

        const formattedTime = moment.isMoment(selectedTime) ? selectedTime.format('HH:mm:ss') : selectedTime;

        let params = {
            type: tab == 1 ? 'Enquiry' : tab == 2 ? 'Registration' : tab == 3 ? 'CourseCart' : 'Bookmark',
            status: selectedStatus,
            courseId: selectedCourse,
            date: selectedDate,
            time: formattedTime,
            comment: comment,
            courseName: selectedOption,
            courseType: selectedCourseType
        }

        if (tab == 1) {
            params['enquiryId'] = parseInt(crmStatus?.id)
        } else if (tab == 2) {
            params['userId'] = parseInt(crmStatus?.userId)
        } else {
            params['userId'] = parseInt(crmStatus?.id)
        }

        // setIsModalVisible(false);
        changeCrmStatus(params, () => { }, () => {
            successCallback()
        })


    };

    const successCallback = () => {
        setIsModalVisible(false);
        togglePageLoader(true);
        setPage(1)
        if (tab == 1) {
            getEnquiryFormsData('Enquiry', 1, true, () => { togglePageLoader(false) })
        } else if (tab == 2) {
            getEnquiryFormsData('Registration', 1, true, () => { togglePageLoader(false) })
        } else if (tab == 3) {
            getCoursesInCartData(1, true, () => { togglePageLoader(false) })
        } else {
            getWhistledCoursesData(1, true, () => { togglePageLoader(false) })
        }
        // }
    }

    const handleDateChange = (date) => {
        setSelectedDate(date.format('YYYY-MM-DD'));
    };

    const handleTimeChange = (time, timeString) => {
        const formattedTime = time ? time.format('HH:mm:ss') : '';
        setSelectedTime(formattedTime);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedStatus(null);
        setSelectedOption(null)


    };

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
        // setCrmStatus(status)
    };

    useEffect(() => {
        if (crmStatus?.courseId && autocompleteCoursesSearchData) {
            const selectedCourseItem = autocompleteCoursesSearchData.find(item => item.itemId === crmStatus.courseId);
            setSelectedOption(selectedCourseItem ? selectedCourseItem.name : null);
        }
        setSelectedDate(crmStatus?.crmDate);
        setSelectedTime(crmStatus?.crmTime ? moment(crmStatus.crmTime, 'HH:mm:ss') : null);
        setComment(crmStatus?.crmComment);
    }, [crmStatus]);
    console.log({ crmStatus });

    const isToday = (date) => {
        const today = moment().startOf('day');
        return moment(date).isSame(today, 'day');
    }

    return (
        <>
            <Modal
                className="modal-round-corner"
                centered
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={false}
            >
                <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600', fontSize: 16 }}>Enquiry status</div>

                <div style={{ marginLeft: 58, fontWeight: '500', marginTop: 30 }}>Change status</div>
                <div style={{ marginTop: 10, marginLeft: 55, display: 'flex' }}>
                    <div style={{
                        border: selectedStatus === 'Interested' ? '1px solid #D6C8F5' : '1px solid #AEAEAE',
                        backgroundColor: selectedStatus === 'Interested' ? '#D6C8F5' : '#FFFFFF',
                        width: '170px',
                        height: '30px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '15px',
                        transition: 'background-color 0.3s',
                        cursor: 'pointer'
                    }}
                        onClick={() => handleStatusChange('Interested')}
                    >
                        INTERESTED
                    </div>
                    <div style={{
                        border: selectedStatus === 'Not Interested' ? '1px solid #D6C8F5' : '1px solid #AEAEAE',
                        backgroundColor: selectedStatus === 'Not Interested' ? '#D6C8F5' : '#FFFFFF',
                        width: '170px',
                        height: '30px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '15px',
                        transition: 'background-color 0.3s',
                        cursor: 'pointer',
                        marginLeft: 20
                    }}
                        onClick={() => handleStatusChange('Not Interested')}
                    >
                        NOT INTERESTED
                    </div>
                </div>
                <div style={{ marginTop: 10, marginLeft: 55, display: 'flex' }}>
                    <div style={{
                        border: selectedStatus === 'Contact Initiated' ? '1px solid #D6C8F5' : '1px solid #AEAEAE',
                        backgroundColor: selectedStatus === 'Contact Initiated' ? '#D6C8F5' : '#FFFFFF',
                        width: '170px',
                        height: '30px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '15px',
                        transition: 'background-color 0.3s',
                        cursor: 'pointer'
                    }}
                        onClick={() => handleStatusChange('Contact Initiated')}
                    >
                        CONTACT INITIATED
                    </div>
                    <div style={{
                        border: selectedStatus === 'Follow Up Requested' ? '1px solid #D6C8F5' : '1px solid #AEAEAE',
                        backgroundColor: selectedStatus === 'Follow Up Requested' ? '#D6C8F5' : '#FFFFFF',
                        width: '170px',
                        height: '30px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '15px',
                        transition: 'background-color 0.3s',
                        cursor: 'pointer',
                        marginLeft: 20
                    }}
                        onClick={() => handleStatusChange('Follow Up Requested')}
                    >
                        FOLLOW UP REQUESTED
                    </div>
                </div>

                {selectedStatus !== 'Not Interested' && selectedStatus !== 'New' ? (
                    <>
                        <div style={{ color: '#8C8C8C', marginLeft: 58, fontSize: 13, marginTop: '15px', fontWeight: '600' }}>Course interested in</div>
                        <div style={{ width: 356, marginLeft: 58, }}>
                            <Select
                                className="no-border-select"
                                style={{ width: '100%', border: 'none' }}
                                onChange={handleChange}
                                value={selectedOption}
                                placeholder="Course name"
                                suffixIcon={<CaretDownOutlined />}

                            >
                                {autocompleteCoursesSearchData?.map(item => (
                                    <Option key={item?.id} value={item?.id} style={{color:`${selectedOption == item?.name ? 'black':''}`,backgroundColor:`${selectedOption == item?.name ? '#D6C8F5':''}`, borderRadius:`${selectedOption == item?.name ? '8px':''}`}}>
                                        {item?.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        {selectedStatus == 'Follow Up Requested' && (
                            <>
                                <div style={{ marginLeft: 58, marginTop: '15px', fontWeight: '600' }}>Schedule</div>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <div style={{ color: '#8C8C8C', marginLeft: 58, fontSize: 10, marginTop: '10px', fontWeight: '600' }}>Date <span style={{ color: 'red' }}>*</span></div>
                                        <div><DatePicker style={{ marginLeft: 58, width: '150px', border: 'none', borderBottom: '1px solid #8C8C8C' }}
                                            value={selectedDate ? moment(selectedDate, 'YYYY-MM-DD') : null}
                                            format="DD/MM/YYYY"
                                            inputReadOnly={true}
                                            onChange={handleDateChange} /></div>
                                    </div>
                                    <div>
                                        <div style={{ color: '#8C8C8C', marginLeft: 58, fontSize: 10, marginTop: '10px', fontWeight: '600' }}>Time <span style={{ color: 'red' }}>*</span></div>
                                        <div>
                                            <TimePicker
                                                style={{ marginLeft: 58, width: '150px', border: 'none', borderBottom: '1px solid #8C8C8C' }}
                                                value={selectedTime ? moment(selectedTime, 'HH:mm:ss') : null}
                                                format="hh:mm A"
                                                onChange={handleTimeChange}
                                                 inputReadOnly={true}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {crmStatus?.crmComments?.length > 0 ? (
                            <div style={{ marginLeft: 58, marginTop: '15px',width:'100%' }}>
                                <div style={{ fontWeight: '600' }}>Comments</div>
                                <div className='scroll-bar-universal' style={{ height: crmStatus?.crmComments?.length > 1 ? "110px" : "50px" , width:'85%'}}>

                                    {crmStatus.crmComments.map((comment, index) => (
                                        <div key={index}>
                                            <div style={{ display: "flex", gap: 10 }}>
                                                <UserAvatar
                                                    size={35}
                                                    shape="circle"
                                                    name={comment?.authorName}
                                                    img={comment?.authorImage == "default.png" ? "" : `${Api._s3_url}${comment?.authorImage}`}
                                                />
                                                <div>
                                                    <div style={{ display: "flex", gap: 10 }}>
                                                        <div style={{ fontSize: 12, fontWeight: 500 }}>{comment?.authorName}</div>
                                                        <div style={{ color: '#636363', marginTop: -2 }}>•</div>
                                                        <div style={{ fontSize: 12, fontWeight: 500 }}>
                                                            {isToday(comment?.created)
                                                                ? moment(comment?.created).format('hh:mm A')
                                                                : moment(comment?.created).format('DD/MM/YYYY hh:mm A')}
                                                        </div>
                                                    </div>
                                                    <div className='comment' style={{ fontSize: 12 , width:'88.5%'}}>{comment?.comment}</div>
                                                </div>
                                            </div>
                                            {index < crmStatus.crmComments.length - 1 && (
                                                <div style={{ borderLeft: '1px solid black', height: 20, marginLeft: 15, marginTop: 6, marginBottom: 6 }}></div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                            </div>
                        ) : null}


                        <div style={{ color: '#8C8C8C', marginLeft: 58, fontSize: 13, marginTop: '15px', fontWeight: '600' }}>Add comment</div>
                        <div style={{ width: 356, marginLeft: 58 }}>
                            <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #8C8C8C', resize: 'none', height: '26px', outline: 'none' }} placeholder="Add comment" value={comment} onChange={handleCommentChange}></input>
                        </div>
                    </>
                ) : <></>}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                            onClick={handleCancel}
                        >
                            CANCEL
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            onClick={handleOk}
                            disabled={!isFormValid}
                        >
                            SAVE
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    const { autocompleteCoursesSearchData } = state.courseManagement
    return {
        autocompleteCoursesSearchData
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAutocompleteCourses: (callback) => dispatch(getAutocompleteCourses(callback)),
    changeCrmStatus: (params, callback, successCallback) => dispatch(changeCrmStatus(params, callback, successCallback)),


})

export default connect(mapStateToProps, mapDispatchToProps)(StatusChangeModal);