import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import ReactQuill from "react-quill";
import { useHistory } from "react-router";

const ShowReasonModal = ({showReasonModal,setShowReasonModal,rejectKycMessage,kycStatus}) => {

   const handleOk = () => {
    setShowReasonModal(false);
  };
  const handleCancel = () => {
    setShowReasonModal(false);
  };
  
  const history = useHistory();
  return (
    <>
      <Modal
        className="modal-round-corner"
        centered
        visible={showReasonModal}
        onOk={handleOk}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
        <div 
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              color: "#636363",
              fontWeight: "bold",
              marginTop: "10px",
              textAlign:'center'
            }}
          >
            <div style={{height: '100%'}} className="r-c-c-c">
                {kycStatus ==='Pending' ?
                   ( <div>
                    <img src={require('Assets/kyc.png').default} height={250} alt="" />
                    </div>) :
                kycStatus=='Submitted' ?
                (<div>
                    <img src={require('Assets/ill-pending.svg').default}  />
                </div>) :
                    <div className="r-c-c">
                    <img src={require('Assets/ill-fm-kyc.svg').default}  />
                </div>
                }
            <div className="color-purple text-sm bold-600 r-c-c">
                {kycStatus ==='Pending' ?
                 'Activate KYC' :
                  kycStatus=='Submitted' ? 
                  'KYC Documents Under Review' :
                  'KYC Approval Unsuccessful'}
                
            </div>
            {kycStatus ==='Pending' ?
                ( <div>
                    You are just one step away from collecting fee from your students. All you need is to complete the KYC process to start accepting payments.
                </div>) :
                kycStatus=='Submitted' ?
                (<div>
                    The details and documents you had submitted are currently being verified. For any further assistance please drop us a mail at care@aulas.in
                </div>) :
                    <React.Fragment>
                    <div className="r-c-c m-t-10" >
                        <div className="text-xs bold-600 dark-grey">APPROVER'S COMMENT</div>
                    </div>
                    <div>
                        {rejectKycMessage}
                    </div>
                </React.Fragment>
            }
            <div className="r-c-c m-t-20">
                <Button type="primary" shape="round" style={{width:120}} onClick={() => history.push('/kyc-form')}>
                    {kycStatus ==='Pending' ? "Activate":"View Form"}</Button>
            </div>

      </div>
          </div>     
        </div>
                
      </Modal>
    </>
  );
};

export default ShowReasonModal;


