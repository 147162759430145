import {
  setCourseDetails,
  updateScholarshipOrAddition,
} from "actions/userManagement";
import { Button, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function ScholarshipAndAdditionModal({
  isModalVisible,
  handleOk,
  handleCancel,
  isAdditionModal,
  setCourseDetails,
  studentCourseDetails,
  updateScholarshipOrAddition,
  userDetailsById,
}) {
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  const [amountOrPercentage, setAmountOrPercentage] = useState(null);
  const [btnLoader, setBtnLoade] = useState(false);

  useEffect(() => {
    if (isAdditionModal) {
      setName(
        JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.[0]
          ?.name
      );
      setDesc(
        JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.[0]
          ?.details
      );
      setAmountOrPercentage(
        JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.[0]
          ?.amount
      );
    }
  }, []);

  return (
    <div>
      <Modal
        maskClosable={false}
        className="scholorshipAndAdditionModalStyles"
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          className="flex_column_center"
          style={{
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "roboto",
            justifyContent: "center",
            marginBottom: "35px",
          }}
        >
          {isAdditionModal ? "Addition" : "Scholarship"} details
        </div>
        <div style={{ width: "100%" }}>
          <p className="dropDownLabelBasicStyle">
            {isAdditionModal ? "Addition" : "Scholarship"} name{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            // placeholder="Select  Class..."
            defaultValue={null}
            style={{ width: "100%" }}
            value={name}
            placeholder={`Add a ${isAdditionModal ? "Addition" : "Scholarship"} name...`}
            onChange={(e) => {
              // setCourseDetails()
              // setName(e.target.value);
              if (e.target.value == " ") {
                setName(null);
              } else {
                setName(e.target.value);
              }
            }}
          ></Input>
        </div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <p className="dropDownLabelBasicStyle">
            {isAdditionModal ? "Addition" : "Scholarship"} details
          </p>
          <TextArea
            placeholder="Enter details..."
            defaultValue={null}
            style={{ width: "100%", height: "100px" }}
            // value={classId}
            value={desc}
            onChange={(e) => {
              // setDesc(e.target.value);
              if (e.target.value == " ") {
                setDesc(null);
              } else {
                setDesc(e.target.value);
              }
            }}
          ></TextArea>
        </div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <p className="dropDownLabelBasicStyle">
            {isAdditionModal ? "Amount" : "Amount"}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            prefix="₹"
            type={"number"}
            defaultValue={null}
            style={{ width: "100%" }}
            value={amountOrPercentage}
            onChange={(e) => {
              // if (e.target.value?.indexOf("e") != -1) {
              // setAmountOrPercentage(amountOrPercentage);
              // } else {
              setAmountOrPercentage(e.target.value);
              // }
            }}
          ></Input>
        </div>
        <div
          className="flex_row_center"
          style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <Button onClick={handleCancel}>CANCEL</Button>
          <Button
            loading={btnLoader}
            disabled={
              name == null ||
              name == "" ||
              amountOrPercentage == null ||
              amountOrPercentage == ""
            }
            onClick={() => {
              setBtnLoade(true);
              setCourseDetails("method", "add");
              setCourseDetails("addedNotSaved", true);

              if (isAdditionModal) {
                let temp = studentCourseDetails?.additions
                  ? studentCourseDetails?.additions
                  : [];
                  console.log("lllll", amountOrPercentage);
                temp = [
                  {
                    name: name,
                    amount: amountOrPercentage,
                    details: desc,
                  },
                ];
                setCourseDetails("additions", temp);
                setCourseDetails("type", "addition");
                updateScholarshipOrAddition(
                  () => {
                    setBtnLoade(false);
                  },
                  () => {
                    handleOk();
                  }
                );
              } else {
                let temp = studentCourseDetails?.scholarships
                  ? studentCourseDetails?.scholarships
                  : [];
                temp.push({
                  name: name,
                  amount: amountOrPercentage,
                  details: desc,
                });
                setCourseDetails("scholarships", temp);
                setCourseDetails("type", "scholarship");

                updateScholarshipOrAddition(
                  () => {
                    setBtnLoade(false);
                  },
                  () => {
                    handleOk();
                  }
                );
              }
            }}
            style={{ backgroundColor: "#594099", color: "white" }}
          >
            SAVE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

// export default ScholarshipAndAdditionModal;
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  updateScholarshipOrAddition: (callback, successCallBack) =>
    dispatch(updateScholarshipOrAddition(callback, successCallBack)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScholarshipAndAdditionModal);
