import { Input } from 'antd'
import { SmallHeading } from 'components/Typography'
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import CaptchaComponent from './captchaComponent';
import { Button } from 'components/Button';

function EmailSignInForm({ userEmailRegister, setUserEmailRegister, forGotPressed, setForgotPressed, sendForgotLink, handleEmailSubmit, captcha, setCaptcha, text, setText }) {
    const [sendPassword, setSendPassword] = useState(false);
    const history = useHistory();
    const handleForgotPassword = () => {
        if (userEmailRegister?.email == '') { }
        else {
            setSendPassword(true);
            sendForgotLink(
                userEmailRegister?.email,
                history,
                () => { setSendPassword(false); }
            )

        }
    }
    return (
        <div style={{ marginTop: -30 }}>
            {forGotPressed ?
                <>
                    <SmallHeading bold color="#636363">Email ID</SmallHeading>
                    <Input placeholder="Enter email ID" className="m-t-5 aulas-login__input"
                        value={userEmailRegister?.email} onChange={(em) => setUserEmailRegister({ ...userEmailRegister, email: em.target.value })}
                        onPressEnter={handleForgotPassword} />
                    <div className="m-t-30 m-b-10 r-c-c-c">
                        <Button
                            loading={sendPassword}
                            type="primary" style={{opacity: userEmailRegister?.email == '' ? 0.5 : 1, cursor: userEmailRegister?.email == '' ? "not-allowed" : "pointer", width:"100%" }}
                            onClick={handleForgotPassword}
                        >
                            Send
                        </Button>
                    </div>
                    <div className="text-center a2grey cursor-pointer m-t-20 text-xmd"
                        onClick={() => { setForgotPressed(false); setText('') }}>
                        Go Back
                    </div>
                </>
                :
                <>
                    <div>
                        <SmallHeading bold color="#636363">Email ID</SmallHeading>
                        <Input placeholder="Enter email ID" className="m-t-5 aulas-login__input"
                            value={userEmailRegister?.email} onChange={(em) => setUserEmailRegister({ ...userEmailRegister, email: em.target.value })}
                            onPressEnter={handleEmailSubmit}
                        />
                    </div>
                    <div className="m-t-20">
                        <SmallHeading bold color="#636363">Password </SmallHeading>
                        <Input.Password className="m-t-5 aulas-login__input" placeholder="Enter password"
                            value={userEmailRegister?.password} onChange={(pass) => setUserEmailRegister({ ...userEmailRegister, password: pass.target.value })}
                            onPressEnter={handleEmailSubmit}
                        />
                    </div>
                    <div className="m-t-5 m-b-10" style={{display:"flex",justifyContent:"flex-end"}}>
                        <Button onClick={() => { setForgotPressed(true) }} type="link">
                            Forgot Password ?
                        </Button>
                    </div>
                    {/* <CaptchaComponent
                        text={text}
                        setText={setText}
                        fromEmail={true}
                        handleEmailSubmit={handleEmailSubmit}
                    /> */}
                </>
            }
        </div>
    )
}

export default EmailSignInForm