const hrGif = require("Assets/HR.gif").default;
const whiteBoardGif = require("Assets/White board.gif").default;
const touchToTalkGif = require("Assets/Touch to talk.gif").default;
const chatGif = require("Assets/chat.gif").default;
const attendanceGif = require("Assets/ATD.gif").default;
const assignGif = require("Assets/ASSIG.gif").default;
const libraryGif = require("Assets/Digital library.gif").default;
const performanceGif = require("Assets/Performance.gif").default;

const carouselData = [
  {
    image: hrGif,
    title: "Hand Raise",
    description:
      "Raise your hand and the classroom is yours to address in company of your teacher.",
  },
  {
    image: whiteBoardGif,
    title: "Digital White Board",
    description:
      "You can't call it a classroom if it doesn't have a board, right? This one's a lot smarter than any you have seen before.",
  },
  {
    image: touchToTalkGif,
    title: "Touch-to-Talk",
    description: "Ever used a walkie-talkie? That’s what this is.",
  },
  {
    image: chatGif,
    title: "Chat Box",
    description:
      "The communication nerve-centre of classrooms where teachers and students can share ideas and resources.",
  },
  {
    image: attendanceGif,
    title: "Attendance",
    description:
      " Whether a teacher or a student, just forget about marking attendance ever again.",
  },
  {
    image: assignGif,
    title: "Assignments/Tests",
    description:
      " You wouldn’t believe that assignment/test creation, submission & evaluation could be done so easily.",
  },
  {
    image: libraryGif,
    title: "Digital Library",
    description: "Lecture Notes, Video Lectures, Question Banks and much more.",
  },
  {
    image: performanceGif,
    title: "Performance",
    description: "A systematic analysis of overall student progress.",
  },
];

export default carouselData;
