import React from 'react';
import { Popover } from 'antd';

const Yvalue=[];
const Maxheight=24;
const x=Math.ceil(24/6);
Yvalue.push(5*x,4*x, 3*x, 2*x, 1*x, 0*x);

const Values=(props)=>{
    return(
        
        <div style={{fontSize:12,marginTop:"23px",color: "#636363",marginLeft:"10px"}}>{`${props.Yvalue}`+ ` hrs`}</div>
    )
}

const BarGraph = ({height,date}) => {
    return(
        <div style={{display:"flex",flexDirection:"column",marginRight:"40px"}}>
            <div style={{width: "30px",
                    height: height   /* `(${props.h1} * 10.5px)` */ ,
                    backgroundColor:"#28DF99",
                    marginTop:`calc(252px - ${height} )`
                    }}>
          
        </div>
        <div style={{ fontSize: 10,textAlign:"center",marginTop:"10px"}}>{date}</div>
        </div>
    )
}

// const content = (
//     <div style={{width:"130px",height:"40px"}}>
//         <div style={{color:"#636363",fontFamily: "Roboto"}}>
//         <div style={{fontSize:"11px"}}>AVERAGE ATTENDANCE</div>
//         <div style={{display:"flex", justifyContent:"space-between",marginTop:"5px"}}>
//         <div style={{display:"flex"}}>
//             <div style={{fontSize:"14px"}}>240</div>
//             <div  style={{fontSize:"11px",marginTop:"2px",marginLeft:"5px"}}>students</div>
//         </div>
//         <div style={{fontSize:"14px"}}>65%</div>
//         </div>
//         </div>

//     </div>
// )

const content = ({leavesTabDataContainer,index}) => {
    console.log("hhfugiu",leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails[index]?.workingHours)
    return(    
    <div style={{width:"240px",height:"60px",}}>
     <div style={{display:"flex",color:"#636363",fontFamily: "Roboto",}}>
    <div>
        <div style={{fontSize:"10px",}}>CLOCK IN</div>
        <div style={{fontSize:"13px",fontWeight:"bold"}}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails[index]?.clockIn===null?
            "0":"leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails[index]?.clockIn"}</div>
    </div>
    <div style={{marginLeft:"20px"}}>
        <div style={{fontSize:"10px"}}>CLOCK OUT</div>
        <div style={{fontSize:"13px",fontWeight:"bold"}}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails[index]?.clockOut===null?
            "0":"leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails[index]?.clockOut"}</div>
    </div>
    <div style={{marginLeft:"20px"}}>
        <div style={{fontSize:"10px"}}>WORKING HOURS</div>
        <div style={{fontSize:"13px",fontWeight:"bold"}}>
        {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails[index]?.workingHours}</div>
    </div>
    </div>
    <div style={{marginTop:"10px",color:"#636363",fontFamily: "Roboto",fontSize:"12px"}}>-25% than previous day</div>
    </div>
)
    }

// const content = (
//     <div style={{width:"100px",height:"50px"}}>
//         <div style={{display:"flex", flexDirection:"column",color:"#636363",fontFamily: "Roboto"}}>
//             <div style={{fontSize:"10px"}}>WORKING HOURS</div>
//             <div style={{fontSize:"13px",fontWeight:"bold"}}>08:24</div>
//             <div style={{fontSize:"10px"}}>+2% than previous day</div>
//         </div>

//     </div>
// )

function AttendanceGraph({leavesTabDataContainer,index}) {
    
  return (
    <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
        <div style={{display:"flex",flexDirection:"column",width:"6%",height:"200px"}}>
            <div style={{fontSize:12,marginLeft:"10px",color:" #636363"}}>{`${Maxheight}`+ ` hrs`}</div>
            {Yvalue.map((item)=>(
              <Values
              Yvalue={item}/>
          ))}
        </div>
    <div className='container-scrollbar' style={{margin:"0",
        padding: "0px",
        width: "85%",
        marginTop: "10px",
        marginLeft:"30px",
        background: "white",
        backgroundImage:" linear-gradient(#302a5f14 0.1em, transparent 0.1em)",
        backgroundSize:" 3em 3em",
        overflowX:"scroll",height:"420px" 
            }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",}}>
                    {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails?.map((item,index)=>(
                        <Popover
                         content={content(leavesTabDataContainer,index)}
                         leavesTabDataContainer={leavesTabDataContainer} 
                         arrowPointAtCenter>
                        <span>
                        <BarGraph
                        height={`${item.durationInMinutes}`+`px`}
                        date={item.unit}/>
                        </span>
                        </Popover>
                    ))}
                </div>
            </div>
    </div>
  )
}

export default AttendanceGraph