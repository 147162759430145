import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Button } from 'components/Button'
import { Heading } from 'components/Typography'

const unAssignConfirmModal = ({ open, onCancel, selectedClass, userData, unAssignStudentfromStd, getStudentsDetailsById }) => {

  console.log({ selectedClass })
  console.log({ userData })

  const [buttonLoader, setButtonLoader] = useState(false);

  const callback = () => {
    setButtonLoader(false)
    onCancel();
    getStudentsDetailsById(() => { }, userData?.id)
  }

  const onYes = () => {


    setButtonLoader(true);
    unAssignStudentfromStd({ userId: userData?.id, standardId: selectedClass?.standardId }, () => callback())
  }

  return (
    <Modal
      centered
      closable
      footer={null}
      open={open}
      onCancel={onCancel}
      className='modal-round-corner'
    >
      <center><Heading>Unassign</Heading></center>
      <center>
        <div className='m-t-30 text-xmd bold-500'>Are you sure to unassign <span style={{ fontWeight: 600 }}>{userData && userData?.name}</span> from  <span style={{ fontWeight: 600 }}>{selectedClass?.std} - {selectedClass?.section}</span></div>
        <div className='r-jsb' style={{ width: '60%', marginTop: 30 }}>
          <Button onClick={() => onCancel()} type='secondary' >CANCEL</Button>
          <Button loading={buttonLoader} onClick={() => onYes()} type='primary'>YES</Button>
        </div>
      </center>
    </Modal>
  )
}

export default unAssignConfirmModal;


// import React, { useState } from "react";
// import { Modal, Button } from "antd";
// import { connect } from "react-redux";
// import {
//   deleteCourseWithId,
//   getStudentsDetailsById,
//   unassignStudent,
// } from "actions/userManagement";

// function UnassignConfirmModal({
//   isModalVisible,
//   setShowUnassign,
//   unassignStudent,
// }) {
//   const [btnLoader, setBtnLoader] = useState(false);
//   return (
//     <div>
//       <Modal
//         maskClosable={false}
//         className="deleteUserModal"
//         okText={"Yes"}
//         cancelText={"No"}
//         title="Basic Modal"
//         visible={isModalVisible}
//         okButtonProps={{ loading: btnLoader }}
//         onOk={() => {
//           setBtnLoader(true);
//           unassignStudent(
//             () => {
//               setBtnLoader(false);
//             },
//             () => {
//               setShowUnassign(false);
//             }
//           );
//         }}
//         onCancel={() => {
//           setShowUnassign(false);
//         }}
//       >
//         <h2
//           style={{
//             fontFamily: "Roboto",
//             textAlign: "center",
//             color: "#594099",
//             marginBottom: "0px",
//             fontSize: "25px",
//             fontWeight: "bold",
//           }}
//         >
//           Confirm
//         </h2>
//         <p
//           style={{
//             fontFamily: "Roboto",
//             textAlign: "center",
//             color: "#636363",
//           }}
//         >
//           Are you sure you want to unassign?
//         </p>
//       </Modal>
//     </div>
//   );
// }

// {
//   /* export default DeleteModal; */
// }
// const mapStateToProps = (state) => {
//   const { tab, dashBoardRole, usersByStats, userRequestStatus } =
//     state.userManagement;
//   return {
//     tab,
//     dashBoardRole,
//     usersByStats,
//     userRequestStatus,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   unassignStudent: (callback, success) =>
//     dispatch(unassignStudent(callback, success)),
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(UnassignConfirmModal);
