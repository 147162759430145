import { CheckOutlined } from '@ant-design/icons'
import { addUserByRole, addUserFieldsData, fetchStudentsClassList, fetchStudentsWRTCourseType, fetchAutocompleteCoursesList } from 'actions/userManagementV2'
import { Checkbox, Col, Input, Modal, Row, Select } from 'antd'
import { Button } from 'components/Button'
import { Heading } from 'components/Typography'
import { IMG_URL } from "env.json"
import { EditImage, ImageComponent } from 'pages/profile/subComponents'
import React, { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { connect } from 'react-redux'
import { Notification } from 'services'
const defaultImg = require("../../../Assets/Icon awesome-user-alt.svg").default

const { Option } = Select;

const AddUser = ({ open, onCancel, addUserByRole, addUserFieldsData, addUserFields, classList, user, fetchStudentsWRTCourseType, autocompleteCoursesData, fetchAutocompleteCoursesList,getDashboard }) => {

        const roles = ['Student', 'Teacher', 'Data Operator', 'Operation'];

        const [buttonLoader, toggleButtonLoader] = useState(false);
        const [isAdminSelected, setIsAdminSelected] = useState(false);
        const [editProfileModal, setEditProfileModal] = useState(false);
        const [deleteConfirmation, setdeleteConfirmation] = useState(false);

 
        const fillTheDetails = addUserFields?.role == '' || addUserFields?.role == undefined || addUserFields?.name?.trim() == '' || addUserFields?.phone == '' || addUserFields?.phone == undefined || addUserFields?.phone == null;
        const inputProfilePic = useRef();

        const callback = (res) => {
                console.log(res,"mcres");
                if(res !== undefined){
                        addUserFieldsData('role', '');
                        addUserFieldsData('file', '');
                        addUserFieldsData('phone', null);
                        addUserFieldsData('email', '');
                        addUserFieldsData('course', null);
                        addUserFieldsData('name', '');
                        addUserFieldsData('privilage', []);
                        
                        onCancel();
                }
                toggleButtonLoader(false);
                                // onCancel();
        }

        useEffect(() => {
                addUserFieldsData('role', '');
                addUserFieldsData('file', '');
                addUserFieldsData('phone', null);
                addUserFieldsData('email', '');
                addUserFieldsData('course', null);
                addUserFieldsData('name', '');
                addUserFieldsData('privilage', []);
        }, []);

        useEffect(()=>{
                if (!autocompleteCoursesData?.length || addUserFields?.role == 'Student') {
                        fetchAutocompleteCoursesList({emptySearch:true,names:true,fromCourse:true}, () => { })
                }
        },[addUserFields?.role]);

        // if Admin is selected from the privilege list then, Planner and Institute support checkboxes get ticked and disables. 
        useEffect(() => {
                if (addUserFields?.privilage?.includes("Admin")) {
                        setIsAdminSelected(true);
                        if (addUserFields?.privilage?.indexOf("Planner") > -1) {
                                addUserFields?.privilage?.splice(addUserFields?.privilage?.indexOf("Planner"), 1);
                        }
                        if (addUserFields?.privilage?.indexOf("InstituteSupport") > -1) {
                                addUserFields?.privilage?.splice(addUserFields?.privilage?.indexOf("InstituteSupport"), 1);
                        }
                } else {
                        setIsAdminSelected(false);
                }
        }, [addUserFields?.privilage]);

        const onChange = (checkedValues) => {
                addUserFieldsData('privilage', checkedValues);
        }



        const handleAddClick = () => {
                if (fillTheDetails) {
                        Notification.error('Error', 'Please fill all the mandatory details');
                } else {
                        toggleButtonLoader(true);
                        addUserByRole(callback,null);
                        fetchStudentsWRTCourseType(null, callback)
                        let params = {
                                userType: 'Student',
                                status: 'Approved',
                                limit: 10
                        };
                                getDashboard(params, () =>{})
                        
                        

                };
        };

        const onDeleteConfirm = (callback, successCallback) => {
                callback()
                setImageRemove(false)
                successCallback()
            }

        const handleProfileModalOk = () => {
                setEditProfileModal(false);
                setImageRemove(true)
                inputProfilePic?.current?.click();
        };

        const [imageRemove ,setImageRemove] = useState(false)

        return (
                <div>
                        <Modal
                                centered
                                closable
                                open={open}
                                footer={null}
                                className='modal-round-corner'
                                onCancel={onCancel}
                        >
                                <div className='p-20'>
                                        <center>
                                                <Heading>Add a user</Heading>
                                        </center>
                                        <center className='' style={{ marginTop: -60 }}>
                                      
                                                <ImageComponent
                                                        src={addUserFields?.photo ? addUserFields?.photo : 'default.png'}
                                                        onChange={() => { }}
                                                        handleEditModal={setEditProfileModal}
                                                        ref={inputProfilePic}
                                                        imageRemove={imageRemove}
                                                        defaultImg={defaultImg}
                                                        setfileInputValue={(file) => { addUserFieldsData('file', file) || 'default.png' }}
                                                />
                                        </center>

                                        <div style={{ float: 'left', fontWeight: 500, }}>Role <span style={{ color: 'red' }}>*</span></div>
                                        <Select onChange={(value) => addUserFieldsData('role', value)} style={{ width: '100%' }} placeholder='Select role'>
                                                {roles?.map((role, index) => {
                                                        // Check if the pathname is "/user-management/courses" and the role is "Student"
                                                        if (window.location.pathname === "/user-management/courses" && role === 'Student') {
                                                                return <Option value={role} key={index}>{role}</Option>;
                                                        }
                                                        // If the pathname is not "/user-management/courses", or if the role is not "Student", show all roles
                                                        else if (window.location.pathname !== "/user-management/courses") {
                                                                return <Option value={role} key={index}>{role}</Option>;
                                                        }
                                                })}
                                        </Select>

                                        <div style={{ float: 'left', marginTop: 30, fontWeight: 500, marginTop: 20 }}>Name <span style={{ color: 'red' }}>*</span></div>
                                        <Input style={{ width: '100%' }} value={addUserFields?.name} placeholder='Enter name' onChange={(e) => addUserFieldsData('name', e.target.value)} />

                                        <div style={{ float: 'left', marginTop: 30, fontWeight: 500, marginTop: 20 }}>Mobile number <span style={{ color: 'red' }}>*</span></div>
                                        <PhoneInput onChange={(e) => addUserFieldsData('phone', e)} className="PhoneInputBa" defaultCountry="IN" placeholder='Enter mobile number' height={"40px"} style={{ width: '100%' }} maxLength={(addUserFields?.phone?.startsWith('+91') || addUserFields?.phone?.startsWith('91')) && addUserFields?.phone?.length == 13 ? 11 : ""}/>

                                        <div style={{ float: 'left', marginTop: 30, fontWeight: 500, marginTop: 20 }}>Email ID</div>
                                        <Input value={addUserFields?.email} style={{ width: '100%' }} onChange={(e) => addUserFieldsData('email', e.target.value)} />
                                        {addUserFields?.role == 'Student' ?
                                                <>
                                                        <div className='m-t-20' style={{ fontWeight: 500 }}>Course</div>
                                                        <Select onChange={(val,obj) => {
                                                                const course = autocompleteCoursesData[obj.key -1];
                                                                if(course){
                                                                        addUserFieldsData('course', course.itemId);
                                                                        addUserFieldsData('courseType',course.type || '' );
                                                                }
                                                                }}
                                                                placeholder='Select a course'
                                                                style={{ width: '100%' }}
                                                        >
                                                                {autocompleteCoursesData?.map((item) => {
                                                                        return <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                                                                })}
                                                        </Select>
                                                </>
                                                :
                                                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                        <Checkbox.Group
                                                                style={{ width: "100%", fontFamily: "Roboto" }}
                                                                onChange={onChange}
                                                                value={addUserFields?.privilage}
                                                        >
                                                                <Row>
                                                                        <Col style={{ marginBottom: "20px" }} span={8}>
                                                                                <Checkbox disabled={user?.privilage?.includes("Admin") && !user?.privilage?.includes("SuperAdmin") ? true : false}
                                                                                        value="Admin"
                                                                                >
                                                                                        Admin
                                                                                </Checkbox>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                                {isAdminSelected ?
                                                                                        <div style={{ marginTop: 3 }}>
                                                                                                <div style={{ height: 17, width: 17, border: "1px solid #E6E6E6", display: "flex", alignItems: "center", cursor: "not-allowed", backgroundColor: "#E6E6E6" }}>
                                                                                                        <CheckOutlined style={{ color: "white" }} />
                                                                                                        <div style={{ padding: 5, color: "#E6E6E6", fontSize: 14, marginLeft: 5 }}>Planner</div>
                                                                                                </div>
                                                                                        </div>
                                                                                        :
                                                                                        <Checkbox disabled={isAdminSelected} value="Planner">
                                                                                                Planner
                                                                                        </Checkbox>

                                                                                }
                                                                        </Col>
                                                                        <Col span={8}>
                                                                                <Checkbox
                                                                                        disabled={
                                                                                                user?.privilage?.includes("Admin") &&
                                                                                                        !user?.privilage?.includes("SuperAdmin")
                                                                                                        ? true
                                                                                                        : false
                                                                                        }
                                                                                        value="FeeManager"
                                                                                >
                                                                                        Fee manager
                                                                                </Checkbox>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                                {isAdminSelected ?
                                                                                        <div style={{ marginTop: 3 }}>
                                                                                                <div style={{ height: 17, width: 17, border: "1px solid #E6E6E6", display: "flex", alignItems: "center", cursor: "not-allowed", backgroundColor: "#E6E6E6" }}>
                                                                                                        <CheckOutlined style={{ color: "white" }} />
                                                                                                        <div style={{ padding: 5, color: "#E6E6E6", fontSize: 14, marginLeft: 5 }}>Institute support</div>
                                                                                                </div>
                                                                                        </div> :
                                                                                        <Checkbox
                                                                                                disabled={isAdminSelected}
                                                                                                value="InstituteSupport"
                                                                                        >
                                                                                                Institute support
                                                                                        </Checkbox>
                                                                                }
                                                                        </Col>
                                                                        <Col
                                                                                style={{ display: addUserFields?.role === "Teacher" ? "none" : "block" }}
                                                                                span={8}
                                                                        >
                                                                                <Checkbox
                                                                                        value="AttendanceMarker"
                                                                                >
                                                                                        Attendance marker
                                                                                </Checkbox>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                                <Checkbox
                                                                                        value="LeaveManager"
                                                                                >
                                                                                        Leave Manager
                                                                                </Checkbox>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                                <Checkbox
                                                                                        value="Marketing"
                                                                                >
                                                                                        Marketing
                                                                                </Checkbox>
                                                                        </Col>
                                                                </Row>
                                                        </Checkbox.Group>
                                                </div>
                                        }

                                        <center className='m-t-40'>
                                                <Button disabled={fillTheDetails} loading={buttonLoader} type='primary' onClick={() => handleAddClick()}>ADD</Button>
                                        </center>
                                </div>
                        </Modal>
                        {editProfileModal &&
                                <EditImage
                                        imageUrl={addUserFields?.file?.name || 'default.png'}
                                        onEditCancel={() => setEditProfileModal(false)}
                                        editModalVisible={editProfileModal}
                                        handleEditOk={handleProfileModalOk}
                                        deleteConfirm={() => {
                                                setdeleteConfirmation(true), setEditProfileModal(true);
                                        }}
                                        deleteModalVisible={deleteConfirmation}
                                        onDeleteCancel={() => setdeleteConfirmation(false)}
                                        setEditProfileModal={setEditProfileModal}
                                        setdeleteConfirmation={setdeleteConfirmation}
                                        addUserFieldsData={addUserFieldsData}
                                        setImageRemove={setImageRemove}
                                onDeleteConfirm={(callback, successCallback) => onDeleteConfirm(callback, successCallback)}
                                />
                        }
                </div>
        )
}

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { classList, addUserFields, autocompleteCoursesData } = state.userManagementV2;
        return { classList, addUserFields, autocompleteCoursesData }
};

const mapDispatchToProps = (dispatch) => ({
        addUserFieldsData: (key, val) => dispatch(addUserFieldsData(key, val)),
        fetchStudentsWRTCourseType: (params, callback) => dispatch(fetchStudentsWRTCourseType(params, callback)),
        addUserByRole: (callback, successCallBack) => dispatch(addUserByRole(callback, successCallBack)),
        fetchStudentsClassList: (params, callBack) => dispatch(fetchStudentsClassList(params, callBack)),
        fetchAutocompleteCoursesList: (params, callBack) => dispatch(fetchAutocompleteCoursesList(params,callBack)),
});



export default connect(mapStateToProps, mapDispatchToProps)(AddUser)