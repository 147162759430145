import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Radio, Typography } from 'antd'
import logo from '../assets/UploadModal/Group 11286.png'
import logo1 from '../assets/UploadModal/illustration-videos.png'
import logo2 from '../assets/UploadModal/Group 11289.png'
import {
    fetchTeacherData, fetchAdminLibraryData, fetchAdminChapterFilter,
    setCheckedVideo, setCheckedQuestion, setCheckedLecture, subjectFilter
} from 'actions/digitalLibrary'
import { Button } from 'components/Button'
const { Text } = Typography



const UploadModal = ({ visible, setVisible, toggleUploadFileModel, setType, arrStoreData, setCheckedQuestion, setCheckedVideo, setCheckedLecture, checkedLecture, checkedVideo, checkedQuestion,
    selectedStd, selectedSubject, fetchAdminChapterFilter, setIsVideoTypeModal, setVideoType, videoPremium, subjectFilter, setQuickAccessItem }) => {


    useEffect(() => {
        setVideoType('')
    }, [])

    const [types, setTypes] = useState(null)

    const handleUpload = (e) => {
        setTypes(e)
        if (e == 1) {
            setType('lecture-notes')
            setCheckedVideo(false)
            setCheckedQuestion(false)
            setCheckedLecture(true)

        }
        else if (e == 2) {
            setType('video')
            setCheckedQuestion(false)
            setCheckedLecture(false)
            setCheckedVideo(true)
        }
        else {
            setType('question-bank')
            setCheckedLecture(false)
            setCheckedVideo(false)
            setCheckedQuestion(true)

        }
    }
    const handleNext = (e) => {
        if(types == 2){
            subjectFilter()
        }
        if (types == 1 || types == 3) {
            toggleUploadFileModel(true)
            setVisible(false)
            setCheckedLecture(false)
            setCheckedVideo(false)
            setCheckedQuestion(false)
            subjectFilter()
            // fetchAdminChapterFilter('', selectedStd ? selectedStd.std_id : null, selectedSubject ? selectedSubject.subjects_id : null)
        }
        else {
            if (videoPremium==true) {
                setIsVideoTypeModal(true)
                setVisible(false)
            }
            else{
                setVideoType('videoLink')
                setIsVideoTypeModal(false)
                toggleUploadFileModel(true)
                setVisible(false)
                subjectFilter()
            }
            
            setIsVideoTypeModal(true)
            setCheckedLecture(false)
            setCheckedVideo(false)
            setCheckedQuestion(false)
        }

        setTypes(null)

    }

    const handleCancel = () => {
        setVisible(false)
        setCheckedLecture(false)
        setCheckedVideo(false)
        setCheckedQuestion(false)
        setQuickAccessItem({addResource:'false'})
    }

    return (
        <Modal className='rounded-border' visible={visible} footer={null} centered={true}  maskClosable={false} closable={false}
            onCancel={() => handleCancel()} width="820px" bodyStyle={{ width: "800px", height: "480px" }}>
            <div style={{ textAlign: "center", marginBottom: "70px" }}>
                <Text style={{ color: "191919", font: "normal normal bold 20px/28px Roboto" }}>Upload content of library</Text><br></br>
                <Text style={{ color: "#636363", font: "normal normal normal 12px/24px Roboto" }}>Please select type of content</Text>
            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "250px", height: "220px", boxShadow: "4px 4px 18px #302A5F14", borderRadius: "4px", marginRight: "10px", border: " 1px solid #E6E6E6" }} >
                        <div className="cursor-pointer" style={{ width: "100%", height: "100%" }} onClick={() => handleUpload(1)}>
                            <Radio checked={checkedLecture} style={{ marginLeft: "220px", marginTop: "8px" }}></Radio>

                            <div style={{ marginTop: "15px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: "200px", marginBottom: "15px" }} src={logo} />

                                <Text style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}>Notes</Text>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "250px", height: "220px", boxShadow: "4px 4px 18px #302A5F14", borderRadius: "4px", marginRight: "10px", border: " 1px solid #E6E6E6" }} >
                        <div className="cursor-pointer" style={{ width: "100%", height: "100%" }} onClick={() => handleUpload(2)}>
                            <Radio checked={checkedVideo} style={{ marginLeft: "220px", marginTop: "8px" }}></Radio>
                            <div style={{ marginTop: "15px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: "200px", marginBottom: "15px" }} src={logo1} />
                                <Text style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}>Videos</Text>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "250px", height: "220px", boxShadow: "4px 4px 18px #302A5F14", borderRadius: "4px", border: " 1px solid #E6E6E6" }} >
                        <div className="cursor-pointer" style={{ width: "100%", height: "100%" }} onClick={() => handleUpload(3)}>
                            <Radio checked={checkedQuestion} style={{ marginLeft: "220px", marginTop: "8px" }}></Radio>
                            <div style={{ marginTop: "15px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: "200px", marginBottom: "15px" }} src={logo2} />
                                <Text style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}>Test papers</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "70px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Button type="default" style={{ width: "100px", marginRight: "5px" }} onClick={() => handleCancel()}>Cancel</Button>
                <Button type="primary" style={{ width: "100px", marginLeft: "5px" }} disabled={!(checkedLecture || checkedVideo || checkedQuestion)} onClick={() => handleNext()}>Next</Button>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    const {
        standardsInfo,
        arrStoreData,
        checkedLecture,
        checkedVideo,
        checkedQuestion,
        selectedStd,
        selectedSubject,
        videoPremium,

    } = state.digitalLibrary;
    return {
        standardsInfo,
        arrStoreData,
        checkedLecture,
        checkedVideo,
        checkedQuestion,
        selectedStd,
        selectedSubject,
        videoPremium

    };
};

const mapDispatchToProps = (dispatch) => ({
    setCheckedLecture: (bool) => dispatch(setCheckedLecture(bool)),
    setCheckedVideo: (bool) => dispatch(setCheckedVideo(bool)),
    setCheckedQuestion: (bool) => dispatch(setCheckedQuestion(bool)),
    fetchAdminChapterFilter: (name, std, sub) => dispatch(fetchAdminChapterFilter(name, std, sub)),
    subjectFilter : (subject, callback) => dispatch(subjectFilter(subject,callback))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(UploadModal);

