import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dropdown, Menu, Popover, Typography } from 'antd';
import CreateAnnouncement from './modal/createAnnouncement';
import ic_file_add from "../../Assets/images/ic_file_add.svg"
import CloneAnnouncement from './modal/cloneModal'


import moment from 'moment';
import HtmlParser from 'react-html-parser';
import { isNull } from 'lodash';
import { CopyOutlined, MoreOutlined } from '@ant-design/icons';

const { Text } = Typography
const AnnounceDraft = styled.div`
width:330px;
height:115px;
border: 1px solid #E6E6E6;
border-radius: 8px;
box-shadow: 1px 1px 6px #302A5F14;
margin: 10px 15px 15px 10px;
&:hover {
    border: none; 
    box-shadow: 2px 3px 8px #c7cece;
}
`;

const AnnounceDraftMain = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    justify-content:center;
`;

const DateAndTime = styled.div`
    width:90%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    margin-top:5px;
    margin-right:10px;
    margin-left:10px;
`;

const TitleAndDescription = styled.div`
    width:90%;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    justify-content:center;
`;


const AnnounceDraftCard = ({ draftItem, togglePageLoader, SetKey, setIsAnnouncement ,setIsSearch,setSort, setStdFilterValue}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false)
    const [isCloneModal,setIsCloneModal] =useState(false)
    const [storeCloneData,setCloneData] =useState([])
    console.log("AA- draftItem", draftItem);
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const htmlText = draftItem?.description ? JSON.parse(draftItem.description)?.mobile : ''
    const menu = (
        <div onClick={(e) => { e.stopPropagation() }}>
          <Menu
           style={{ width: "100%", }}>
            <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => {setIsCloneModal(true);setCloneData(draftItem)}}>
              <CopyOutlined />
              Clone
            </Menu.Item>
          </Menu>
        </div>
      );

        const date = new Date(draftItem?.createdAt);
        const options = { timeZone: "Asia/Kolkata" };
        const istDate = date.toLocaleDateString("en-GB", options); // "18-12-2024"

        // Format time to HH:mm AM/PM
        const istTime = date.toLocaleTimeString("en-US", {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
        }); // "07:39 PM"
    return (
        <div>
            <AnnounceDraft className='cursor-pointer' onClick={() => {setIsModalVisible(true)}}>
            
                <AnnounceDraftMain>
                    {
                        draftItem?.typeAnnouncement === 'file' ?
                            <>
                                <TitleAndDescription>
                                    <div style={{display:"flex", justifyContent: 'space-between', width: '100%' }}>
                                    <div>
                                        <Typography.Text ellipsis={{ tooltip: true }}>
                                            <div style={{ width: "250px", fontFamily: 'roboto', color: '#191919', fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{draftItem?.title}</div>
                                        </Typography.Text>
                                    </div>
                                    <div>
                                        <Dropdown placement="bottomRight" overlay={menu}>
                                            <MoreOutlined style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation() }} />
                                        </Dropdown>

                                    </div>

                                    </div>
                                    
                                    
                                    <div className='' style={{ paddingLeft: '2px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {draftItem?.fileName?.map((i) => (
                                            <div style={{ fontSize: "12px" }}><img src={ic_file_add} />{i.key}</div>

                                        ))}</div>
                                </TitleAndDescription>
                                <DateAndTime>

                        {console.log("AA-draftItem?.time",draftItem?.time,draftItem?.date)}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "160px" ,marginTop:"10px"}}>
                            <Text style={{ fontSize: "12px", color: "#AEAEAE", marginRight: "5px", fontSize: "12px" }}>{ draftItem?.date ? moment(draftItem?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : istDate } </Text>
                            <div style={{ width: "4px", height: "4px", backgroundColor: "grey", borderRadius: "50%" }}></div>
                            <Text style={{ color: "#AEAEAE", marginLeft: "5px", fontSize: "12px" }}> { draftItem?.time != "00:00:00" ? moment.utc(draftItem?.time, 'HH:mm:ss').format('hh:mm A') : istTime}</Text>
                        </div>
                        <Text style={{ color: "#AEAEAE", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',marginTop:"11px" }}>{draftItem?.target}</Text>
                    </DateAndTime>
                                
                            </> :
                            <>
                            <TitleAndDescription >
                                <div style={{display:"flex", justifyContent: 'space-between', width: '100%',}}>
                                <div >
                                    <Typography.Text ellipsis>
                                        <div style={{ width: "250px", fontFamily: 'roboto', color: '#191919', fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{draftItem?.title}</div>
                                    </Typography.Text>
                                </div>
                                <div>
                                        <Dropdown placement="bottomRight" overlay={menu}>
                                            <MoreOutlined style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation() }} />
                                        </Dropdown>

                                    </div>

                                </div>
                                <div  style={{cursor:"pointer"}}  >
                                <Typography.Text  style={{ fontFamily: 'roboto', color: '#636363', fontSize: '14px',  width: 250,height:"55px" }} ellipsis={{tooltip:true}}>
                                {!IsJsonString(JSON.parse(draftItem?.description)?.mobile) && isHTML(JSON.parse(draftItem?.description)?.mobile) ? HtmlParser(JSON.parse(draftItem?.description)?.mobile) : HtmlParser(JSON.parse(draftItem?.description)?.mobile)}
                                </Typography.Text>

                                </div>

                            </TitleAndDescription>
                    
                    <DateAndTime>

                        {console.log("AA-draftItem?.time",draftItem?.time,draftItem?.date)}

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "160px" ,marginTop:"-5px"}}>
                            <Text style={{ fontSize: "12px", color: "#AEAEAE", marginRight: "5px", fontSize: "12px" }}>{ draftItem?.date ? moment(draftItem?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : istDate }</Text>
                            <div style={{ width: "4px", height: "4px", backgroundColor: "grey", borderRadius: "50%" }}></div>
                            <Text style={{ color: "#AEAEAE", marginLeft: "5px", fontSize: "12px" }}>{ draftItem?.time != "00:00:00" ? moment.utc(draftItem?.time, 'HH:mm:ss').format('hh:mm A') : istTime}</Text>
                        </div>
                        <Text style={{ color: "#AEAEAE", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',marginTop:"-15px" }}>{draftItem?.target}</Text>
                    </DateAndTime>
                    </>

                    }
                </AnnounceDraftMain>
            </AnnounceDraft>
            {(isModalVisible && <CreateAnnouncement setIsSearch={setIsSearch} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} edit='draft'  editData={draftItem} togglePageLoader={togglePageLoader} SetKey={SetKey} setSort={setSort} setStdFilterValue={setStdFilterValue}/>)}
            {(isCloneModal &&<CloneAnnouncement isCloneModal={isCloneModal} setIsCloneModal={setIsCloneModal} setIsModalVisible={setIsModalVisible}  edit='clone'  editData={draftItem} togglePageLoader={togglePageLoader} SetKey={SetKey} setSort={setSort} />)}

        </div>
    )
}

export default AnnounceDraftCard