import moment from "moment";
import { Api } from "../services";
export const SET_COURSE_NAME = "SET_COURSE_NAME";
export const SET_COURSE_PRICE = "SET_COURSE_PRICE";
export const SET_COURSE_DURATION = "SET_COURSE_DURATION";
export const SET_COURSE_REF = "SET_COURSE_REF";
export const SET_COURSE_BILLING_PLAN = "SET_COURSE_BILLING_PLAN";
export const SET_INSTALLMENTS = "SET_INSTALLMENTS";
export const SET_SEARCH_COURSE = "SET_SEARCH_COURSE";
export const SET_ALL_COURSES = "SET_ALL_COURSES";
export const SET_SPECIFIC_COURSE_DETAILS = "SET_SPECIFIC_COURSE_DETAILS";
export const STORE_PAGE_NUMBER = 'STORE_PAGE_NUMBER';
export const SET_IS_FROM_DETAILS_PAGE = 'SET_IS_FROM_DETAILS_PAGE';
export const STORE_SELECTED_COURSE_DATA = 'STORE_SELECTED_COURSE_DATA';
export const STORE_SELECTED_COURSE_ID = "STORE_SELECTED_COURSE_ID"

export const setSpecificCourseDetails = (res) => ({
  type: SET_SPECIFIC_COURSE_DETAILS,
  payload: res,
});
export const setAllCourses = (res) => ({
  type: SET_ALL_COURSES,
  payload: res,
});
export const setSearchCourse = (res) => ({
  type: SET_SEARCH_COURSE,
  payload: res,
});
export const setCourseName = (res) => ({
  type: SET_COURSE_NAME,
  payload: res,
});
export const setInstallments = (res) => ({
  type: SET_INSTALLMENTS,
  payload: res,
});
export const setCoursePrice = (res) => ({
  type: SET_COURSE_PRICE,
  payload: res,
});
export const setCourseDuration = (res) => ({
  type: SET_COURSE_DURATION,
  payload: res,
});
export const setCourseRef = (res) => ({
  type: SET_COURSE_REF,
  payload: res,
});
export const setCourseBillingPlan = (res) => ({
  type: SET_COURSE_BILLING_PLAN,
  payload: res,
});
export const storePageNumber = (val) => ({
  type: STORE_PAGE_NUMBER,
  val
})
export const setIsFromDetailsPage = (val) => ({
  type: SET_IS_FROM_DETAILS_PAGE,
  val
})

function parseData(data) {
  // console.log("parsedata", data);
  if (!data) return {};
  if (typeof data === "object") return data;
  if (data[0] === "{") {
    if (typeof data === "string") return JSON.parse(data);
  }
}

export const getAllCourses =
  (callback = null, page, fromClassroom) =>
    (dispatch, getState) => {
      const { search } = getState().course;
      const params = {
        search: search,
        page,
      };
      if (fromClassroom) {
        params.skipExpired = true
        params.allCourses = true
      }
      return Api.get("/standards/allCourses")
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            // dispatch(setUserByStats(response));
            dispatch(setAllCourses(response));
            if (id) {
              console.log("idout", id);
              let temp = response?.response?.items?.filter((item) => {
                return item.id == id;
              });
              dispatch(setSpecificCourseDetails(temp[0]));
            }
          }
        });
    };

const returnNames = (val) => {
  console.log("returnNames", val);
  if (val == "30") {
    return "Month(s)";
  } else if (val == "360" || val == "365") {
    return "Year(s)";
  } else if (val == "1") {
    return "Day(s)";
  }
};

const returnPlanNames = (val) => {
  console.log("returnPlanNames", val);

  if (val == "30") {
    return "Monthly";
  } else if (val == "90") {
    return "Quarterly";
  } else if (val == "180") {
    return "Half Yearly";
  } else if (val == "360" || val == "365") {
    return "Annually";
  } else if (val == "One time") {
    return "One time";
  } else if (val == "Customize") {
    return "Customize";
  }
};
export const addCourses =
  (
    callback = null,
    successCallBack = null,
    isAdding,
    desc,
    startDate,
    endDate
  ) =>
    (dispatch, getState) => {
      const {
        courseName,
        courseRef,
        coursePrice,
        courseDuration,
        installments,
        courseBillingPlan,
        selectedCourseId,
      } = getState().course;
      const params = {
        id: isAdding ? selectedCourseId : null,
        courseName: courseName,
        duration: courseDuration,
        durationType: returnNames(courseRef),
        amount:
          coursePrice && parseFloat(coursePrice) != NaN
            ? parseFloat(coursePrice)
            : 0,
        billingPlan: returnPlanNames(courseBillingPlan),
        installmentDetails: JSON.stringify(installments),
        courseDescription: desc,
        endDate,
        startDate,
      };
      return Api.post("/standards/updateCourse")
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined && response?.show?.type !== 'error') {
            dispatch(storeSelectedCourseId(response?.courseId))
            if (successCallBack) {
              successCallBack();
            }
          }
        });
    };

export const deleteCourse =
  (callback = null, successCallBack = null) =>
    (dispatch, getState) => {
      const { selectedCourseId } = getState().course;

      return Api.delete(
        `/standards/deleteCouse/${selectedCourseId}`
      ).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(setUserByStats(response));
          // dispatch(setAllCourses(response));
          console.log(response);
          // console.log(response);
        }
      });
    };

export const getSelectedCourseData = (callback = null) => {
  return (dispatch, getState) => {
    const { selectedCourseId } = getState().course;
    return Api.get(`/standards/course/${selectedCourseId}`).send((response) => {
      if (callback) {
        callback()
      }
      if (response !== undefined && response?.show?.type != 'error') {
        console.log("response123", response)
        dispatch(storeSelectedCourseData(response))
      }
    })
  }
}

export const storeSelectedCourseData = (val) => ({
  type: STORE_SELECTED_COURSE_DATA,
  val
})

export const storeSelectedCourseId = (val) => ({
  type: STORE_SELECTED_COURSE_ID,
  val
})

export const addSubjects = (subjectName, addChapter, subjectId, fromClassroom, callBack = null, successCallBack = null) =>
  (dispatch, getState) => {
    const { selectedCourseId } = getState().course;
    const { newCourseId } = getState().courseManagement;
    const { selectedSection } = getState().classRoom;
    const filteredChapters = addChapter.filter(item => item !== '');
    const params = {
      subjectName,
      subjectId,
      courseId: newCourseId,
      chapters: filteredChapters
    };
    const classroomParams = {
      subjectName,
      subjectId,
      standardId: selectedSection?.id,
      chapters: filteredChapters
    }
    return Api.post("/subjects/createSubjectV2")
      .params(fromClassroom ? classroomParams : params)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined && response?.show?.type !== 'error') {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };

  export const addCurriculumToTest = (subjects, callBack, successCallBack) => {
    return (dispatch, getState) => {
      const { selectedMasterStd, selectedCurriculumId } = getState().classRoom;
      const { newCourseId } = getState().courseManagement;
      const params = {
        curriculumId: selectedCurriculumId,
        masterStandardId: selectedMasterStd.id,
        subjects,
        testSeriesId: newCourseId
      }
      return Api.post('/testSeries/addTestChapters').params(params).send((response, error) => {
        if (callBack) {
          callBack()
        }
        if (typeof response != "undefined") {
          if (successCallBack) {
            successCallBack()
          }
        }
      });
    }
  }

  export const addSubjectFromOnlineTest = (subjectName, addChapter, subjectId, fromClassroom, callBack = null, successCallBack = null) =>
  (dispatch, getState) => {
    const { selectedCourseId } = getState().course;
    const { newCourseId } = getState().courseManagement;
    const { selectedSection } = getState().classRoom;
    const filteredChapters = addChapter.filter(item => item !== '');
    const params = {
      subjectName,
      subjectId,
      testSeriesId: newCourseId,
      chapters: filteredChapters
    };
    const classroomParams = {
      subjectName,
      subjectId,
      standardId: selectedSection?.id,
      chapters: filteredChapters
    }
    return Api.post("/testSeries/createSubject")
      .params(fromClassroom ? classroomParams : params)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined && response?.show?.type !== 'error') {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };

export const deleteSubject = (subjectId, callback = null, successCallBack = null) =>
  (dispatch, getState) => {
    const { newCourseId } = getState().courseManagement;
    return Api.delete(`/subjects/delete-subjects`)
      .params({
        subjectId,
        courseId: newCourseId
      })
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };

export const addCurriculumToCourse = (subjects, callBack, successCallBack, history) => {
  return (dispatch, getState) => {
    const { selectedMasterStd, selectedCurriculumId, selectedSectionDetails, isFromClassRoomDetails } = getState().classRoom;
    const { newCourseId } = getState().courseManagement;
    const params = {
      standardId: selectedSectionDetails.id,
      curriculumId: selectedCurriculumId,
      masterStandardId: selectedMasterStd.id,
      subjects,
      courseId: newCourseId
    }
    return Api.post('/subjects/add-chapters-course-subject').params(params).send((response, error) => {
      if (callBack) {
        callBack()
      }
      if (typeof response != "undefined") {
        if (successCallBack) {
          successCallBack()
          if(isFromClassRoomDetails){
            history.push({pathname:'/course-details', state:{key:"fromClassroom"}})
          }else{
            history.push('/course-details')
          }
        }
      }
    });
  }
}