import { CameraFilled, CameraOutlined, CloseCircleFilled, CloseOutlined, ConsoleSqlOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, MinusCircleOutlined, MoreOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, Row, Switch, Upload, Select, message, Avatar, Dropdown, Menu } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { Notification, Utils } from 'services';
import { getDropDownCourseList, getMarketPlaceSettings, getToggleButtonCheck, updateMarketSettings } from 'actions/profileNew';
import { IMG_URL } from "../../env.json";
import MapsComponent from './mapsComponent';
import ImageChangeModal from 'components/imageChangeModal';
import LocationModal from './locationmodal';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { AutoComplete } from 'pages/attendanceNew/attendanceGeoFence';
import Geocode from "react-geocode";
import { v4 as uuidv4 } from 'uuid';
import { NodataCard } from 'pages/classRoom';
import './styles.css';
import { getAddressFromPin } from './postalApi';
import { fromValue } from 'long';
import "../studentMarketPlace/userHomeStyles.css";

import shortText from "../../Assets/ProfileAssets/short-text-ic.svg"
import paraText from "../../Assets/ProfileAssets/para-text-ic.svg"
import paraTextColored from "../../Assets/ProfileAssets/ic-para-color.svg"
import multipleIc from "../../Assets/ProfileAssets/select-radio-active.svg"
import multipleIcColored from "../../Assets/ProfileAssets/select-radio-active-colored.svg"
import checkboxIc from "../../Assets/ProfileAssets/select-check-active.svg"
import checkboxColored from "../../Assets/ProfileAssets/checkbox-colored.svg"
import emailIc from "../../Assets/ProfileAssets/email-svg-ic.svg"
import emailIcColored from "../../Assets/ProfileAssets/email-svgrepo-com.svg"
import phoneColored from "../../Assets/ProfileAssets/phone-colored.svg"
import phoneIc from "../../Assets/ProfileAssets/phone-rounded-svgrepo-com.svg"
import dropDownIc from "../../Assets/ProfileAssets/dropdown-selecte-ic.svg"
import dropDownIcColored from "../../Assets/ProfileAssets/ic-arrow-down-colored.svg"
import AnimatedTextField from 'components/AnimatedTextField';
import styled from 'styled-components';
import AdmissionEnquiryForm from 'pages/studentMarketPlace/admissionEnquiryForm';
import EnquiryFormPreviewModal from './enquiryFormPreviewModal';
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import footerBackground from '../../Assets/Footer/NewFooter.svg'
import locations from '../../Assets/Footer/locations.svg'
import appStore from '../../Assets/Footer/Appstore.svg'
import playStore from '../../Assets/Footer/googleplay.svg'

const { Dragger } = Upload;
const { Option } = Select;

const marketPlaceChecks = ['Live courses', 'Video courses', 'Online test series', 'Study materials']
const socialMedia = ['Instagram', 'WhatsApp', 'Twitter', 'LinkedIn', 'Facebook'];

const Banners = ({ updateMarketSettings, getToggleButtonCheck, getMarketPlaceSettings, marketPlaceData }) => {
    const [banners, setBanners] = useState([{ key: '', urlPath: '', active: false }]);
    const storedBanners = useRef(null);
    const [bannerSaveLoader, toggleBannerSaveLoader] = useState(false);
    const [imageDeleteLoader, toggleImageDeleteLoader] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [defaultBannerSwitch, toggleDefaultBannerSwitch] = useState(marketPlaceData?.defaultBanner ? true : false)

    useEffect(() => {

        const mappingBanners = marketPlaceData?.bannerData?.map((item) => {
            const newBannerData = {
                key: item?.key || undefined,
                urlPath: item?.urlPath,
                active: item?.active,
                id: item?.id,
                fileUrl: item?.key
            }
            return newBannerData;
        });
        storedBanners.current = mappingBanners ? JSON.parse(JSON.stringify(mappingBanners)) : []

        setBanners(marketPlaceData?.bannerData?.length > 0 ? mappingBanners : [{ key: '', urlPath: '', active: false }]);
    }, [JSON.stringify(marketPlaceData?.bannerData)]);

    const addBanner = () => {
        // Get the last banner
        const lastBanner = banners[banners?.length - 1];

        // Regular expression to match URL structure
        const urlRegex = /^(ftp|http|https):\/\/(?:www\.)?[^ "]+$/;

        // Check if the last banner has a valid URL
        if (lastBanner.urlPath === '' || urlRegex.test(lastBanner.urlPath)) {
            setBanners([...banners, { file: null, active: false, urlPath: '' }]);
        } else {
            Notification.error('Error', 'Invalid URL');
        }
    };

    const removeBanner = (banner, index) => {
        const updatedBanners = [...banners?.filter((_, id) => (id != index))];
        if (banner?.id) {
            storedBanners.current.forEach((item) => {
                if (item.id === banner?.id) {
                    item.delete = true
                }
            })
        }

        if (updatedBanners.length === 0) {
            // If there are no banners left after removing the current one, set a default banner
            setBanners([{ key: '', urlPath: '', active: false }]);
        } else {
            // Otherwise, update the banners state with the filtered array
            setBanners(updatedBanners);
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedBanners = [...banners];
        updatedBanners[index][name] = value;
        setBanners(updatedBanners);
    };

    const handleUploadChange = (info, index) => {
        const { status } = info.file;
        const file = info?.file?.originFileObj;
        const isLt5M = info.file?.originFileObj?.size / 1024 / 1024 < 5;

        if (status === 'done' || status === 'error') {

            if (!isLt5M && (info.file.originFileObj.type === "image/jpeg" || info.file.originFileObj.type === "image/png" || info.file.originFileObj.type === "image/jpg")) {

            } else if (info.file.originFileObj.type != "image/jpeg" && info.file.originFileObj.type != "image/png" && info.file.originFileObj.type != "image/jpg") {
                Notification.error('error', "File extension not supported");
            }
            const updatedBanners = [...banners];
            // this is to show preview after loading the image and before sending to API.
            if (file && (info.file.originFileObj.type == "image/jpeg" || info.file.originFileObj.type == "image/png" || info.file.originFileObj.type == "image/jpg")) {
                const reader = new FileReader();
                reader.onload = () => {
                    updatedBanners[index].file = reader.result;
                    updatedBanners[index].fileObj = file;
                    setBanners(updatedBanners);
                };
                reader.readAsDataURL(file);
            }

        }
        console.log({ banners })
    };

    const beforeUpload = (file) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        const isLt200MB = file.size / 1024 / 1024 < 200;
        if (!isLt5M && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")) {

        } else if (!isLt200MB && file.type == "video/mp4" || file.type == "video/x-matroska") {
            Notification.error('error', "File must be smaller than 200MB!");
        }
    };

    const handleImageDelete = (banner, index) => {
        const updatedBanners = [...banners];
        updatedBanners[index].file = null;
        updatedBanners[index].key = null;
        if (banner.id) {
            storedBanners.current.forEach((item) => {
                if (item.id === banner.id) {
                    item.imageAction = 'delete'
                }
            })
        }
        updatedBanners[index].image = null
        delete updatedBanners[index].fileUrl
        setBanners(updatedBanners);
        setSelectedImage(index);

    }
    const urlRegex = /^(ftp|http|https):\/\/(?:www\.)?[^ "]+$/;
    console.log({ banners })
    const handleBannerSave = () => {
        let count = 0;
        let hasInvalidUrl = false;
        let hasMissingKeyOrFile = false;

        const updatedBanners = banners?.map(item => {
            const { file, fileObj, fileUrl, key, ...rest } = item;
            if (fileObj) {
                rest.imageIndex = count++;
            }

            // Check if the URL is invalid
            if (rest.urlPath !== '' && !urlRegex.test(rest.urlPath)) {
                hasInvalidUrl = true;
            }

            if ((!fileUrl || !key) && !defaultBannerSwitch) {
                if (!fileObj) { // Only set hasMissingKeyOrFile to true if fileObj is not present
                    hasMissingKeyOrFile = true;
                }
            } else if (fileObj) {
                hasMissingKeyOrFile = false;
            }

            return rest;
        });

        // Check for invalid URL
        if (!defaultBannerSwitch && hasInvalidUrl) {
            Notification.error('error', 'Please enter a valid URL');
            return;
        }

        if (hasMissingKeyOrFile) {
            if (!defaultBannerSwitch) {
                Notification.error('error', 'Please upload an image');
            }
            return;
        }

        storedBanners.current?.forEach((item) => {
            if (!updatedBanners?.map(item1 => item1?.id).includes(item?.id)) {
                updatedBanners?.unshift(item)
            }
        })
        const params = {
            bannerData: updatedBanners,
            images: banners?.filter((a) => a?.file != undefined)?.map((item) => {
                return [item?.fileObj]
            })
        }

        const callback = () => {
            toggleBannerSaveLoader(false);
        };
        let paramsToggle = {
            defaultBanner: !defaultBannerSwitch,

        };

        toggleBannerSaveLoader(true);
        console.log("bannersss", defaultBannerSwitch);
        if (defaultBannerSwitch) {
            // getToggleButtonCheck(paramsToggle, (res) => {
            //     if (res?.show?.type === "success") {
            //         // toggleDefaultBannerSwitch(!defaultBannerSwitch);
            //         toggleBannerSaveLoader(false);
            //     }
            // });
            updateMarketSettings(params, callback, () => {
                getMarketPlaceSettings()
            });
        } else {
            updateMarketSettings(params, callback, () => {
                getMarketPlaceSettings()
            });
        }
    }

    const validateSave = () => {
        const isValid = banners?.every(banner => (banner?.file || banner?.key));
        return isValid;
    };

    const handleToggleButton = (checked) => {

        let params = {
            defaultBanner: !defaultBannerSwitch,

        };
        getToggleButtonCheck(params, (res) => {
            if (res?.show?.type === "success") {
                toggleDefaultBannerSwitch(!defaultBannerSwitch);
            }
        });

    };

    return (
        <div style={{ border: '1px solid #AEAEAE', borderRadius: 8, padding: 20, display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Row>
                <Heading>Add banner(s)</Heading><span style={{ fontSize: 12, color: '#AEAEAE', margin: '5px 0 0 5px' }}>(Maximum 10)</span>
            </Row>
            <div className='simGrey text-xmd' style={{ width: '85%' }}>A banner on a website serves as a visual focal point, instantly conveying key information or branding to visitors, enhancing user experience and promoting engagement.</div>
            <Row className='r-jsb m-t-10' style={{ width: '100%' }}>
                <div className='text-xmd'>If you don't have a banner image, we will provide a default banner. Want to use default banner?</div>
                <div><Switch checked={defaultBannerSwitch} onChange={handleToggleButton} /></div>
            </Row>
            {banners?.map((banner, index) => (
                <div key={index} className='banner-container'>
                    <div className='display-flex m-t-30' style={{ justifyContent: 'space-between' }}>
                        {!defaultBannerSwitch ?
                            <React.Fragment>
                                <div className='flex-column' style={{ width: '55%' }}>
                                    {(banner?.key == '' || banner?.key == null) && banner?.file == null ? (
                                        <>
                                            <div className='bold-600 color-black' style={{ fontSize: 16 }}>
                                                {`Banner ${index + 1}`}
                                            </div>

                                            <Dragger
                                                showUploadList={false}
                                                onChange={(e) => handleUploadChange(e, index)}
                                                beforeUpload={(e) => beforeUpload(e)}
                                                accept={"image/png, image/jpeg, image/jpg"}
                                                style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", marginTop: 5 }}
                                            >
                                                <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600 }}>
                                                    Drag and drop or
                                                    <span style={{ color: "#1089FF", margin: "0px 5px " }}> Choose file </span>to Upload
                                                </div>
                                            </Dragger>
                                            <div className='r-jsb m-t-20' style={{ width: '100%' }}>
                                                <div className='text-xs simGrey'>Files supported: PNG, JPEG, and JPG</div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='bold-600 color-black' style={{ fontSize: 16 }}>{`Banner ${index + 1}`}</div>
                                            <div style={{ position: 'relative', width: "100%", height: "100%" }}>
                                                <div onClick={() => handleImageDelete(banner, index)} style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }}>{imageDeleteLoader && banner?.id == selectedImage ? <LoadingOutlined style={{ color: 'white', fontSize: 16 }} /> : <CloseCircleFilled style={{ color: 'red', fontSize: 16 }} />}</div>
                                                {banner.key ?
                                                    <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} src={`${IMG_URL}/${banner.key}`} alt='banner image' />
                                                    :
                                                    <img style={{ width: "100%", height: "100%", objectFit: 'cover' }} src={banner?.file} alt='banner image' />
                                                }
                                            </div>
                                        </>

                                    )}
                                </div>
                            </React.Fragment>
                            : null
                        }
                        {
                            !defaultBannerSwitch ?
                                <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                    <div className='bold-600 color-black' style={{ fontSize: 16 }}>URL</div>
                                    <div style={{ marginTop: -5 }}>
                                        <Input placeholder='Enter URL' name="urlPath" value={banner?.urlPath} onChange={(e) => handleInputChange(e, index)} style={{ height: 61, marginTop: 6 }} />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        (banners && banners?.length >= 1) && !defaultBannerSwitch ?
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -30 }}>
                                <Button disabled={null} type='delete' onClick={() => removeBanner(banner, index)} style={{ fontSize: 14, marginTop: 10 }}>
                                    {'DELETE'}
                                </Button>
                            </div>
                            :
                            null
                    }
                </div>
            ))}

            {banners && banners.length < 10 && !defaultBannerSwitch && (
                <center style={{}}>
                    <Button disabled={!(banners[banners?.length - 1]?.file !== '')} style={{ color: '#1089FF', fontSize: 14, marginTop: 10 }} type='link' icon={<PlusCircleOutlined />} onClick={addBanner}>
                        ADD BANNER
                    </Button>
                </center>
            )}
            {
                defaultBannerSwitch ?
                    null :
                    <center className='m-t-30'><Button loading={bannerSaveLoader} onClick={() => handleBannerSave()} disabled={banners?.length === 0} type='link' style={{ fontSize: 14, fontWeight: 700 }}>{"SAVE"}</Button></center>
            }
        </div>
    );
};

const Testimonials = ({ testimonialsSwitch, updateMarketSettings, getMarketPlaceSettings, marketPlaceData, handleEnquirySwitch }) => {

    const [testimonials, setTestimonials] = useState([{ key: '', name: '', review: '', file: null, auxIndex: uuidv4() }]);
    const [testimonialSave, toggleTestimonialSave] = useState(false);
    const [testimonialImageIndex, setTestimonialImageIndex] = useState(-1);
    const [imageChangeModalVisible, setImageChangeModalVisible] = useState(false);
    const selectedIndex = useRef(null);
    const inputProfilePic = useRef(null);
    const clickfrommodal = useRef(null);
    const storedBanners = useRef([]);

    useEffect(() => {
        const mappingTestimonials = marketPlaceData?.testimonialsData?.map((item) => {
            const newTestimonialsData = {
                key: item?.key || undefined,
                name: item?.name,
                review: item?.review,
                file: item?.key,
                id: item?.id,
                auxIndex: uuidv4()
            }
            return newTestimonialsData;
        })
        storedBanners.current = marketPlaceData?.testimonialsData?.length ? JSON.parse(JSON?.stringify(mappingTestimonials)) : []
        setTestimonials(marketPlaceData?.testimonialsData?.length > 0 ? mappingTestimonials : [{ key: '', name: '', review: '', file: null }])
    }, [JSON.stringify(marketPlaceData?.testimonialsData)])

    const handleAddTestimonial = () => {
        setTestimonials([...testimonials, { name: '', review: '', file: null, auxIndex: uuidv4() }]);
    };

    const handleRemoveTestimonial = (testimonial, index) => {
        const updatedBanners = [...testimonials?.filter((item, id) => (item?.auxIndex != testimonial?.auxIndex))];
        storedBanners?.current?.forEach((item, i) => {
            if (item.auxIndex === testimonial?.auxIndex) {
                item.delete = true
            }
        })
        setTestimonials(updatedBanners);
    };

    const handleChangeName = (index, e) => {
        const { value } = e.target;
        // Regular expression to allow only alphabetic characters and spaces
        const regex = /^[a-zA-Z\s]*$/;

        // Check if the value matches the regex
        if (!regex.test(value)) {
            // If the value contains non-alphabetic characters, do not update the state
            return;
        }

        // Update the testimonial's name
        const updatedTestimonials = [...testimonials];
        updatedTestimonials[index].name = value;
        setTestimonials(updatedTestimonials);
    };

    const handleChangeComment = (index, e) => {
        const updatedTestimonials = [...testimonials];
        updatedTestimonials[index].review = e.target.value;
        setTestimonials(updatedTestimonials);
    };

    const handleTestimonialSave = () => {
        const allReviewsValid = testimonials?.every(
            (testimonial) => testimonial?.review?.length >= 200 && testimonial?.review?.length <= 300
        );

        if (!allReviewsValid) {
            Notification.error('Error', 'Please ensure all reviews have 200-300 words.');
            return;
        }

        let count = 0
        const updatedTestimonials = testimonials?.map(item => {
            const { file, fileObj, image, ...rest } = item;
            if (fileObj) {
                rest.imageIndex = count++;
            }
            return rest;
        });
        storedBanners.current?.forEach((item) => {
            if (!updatedTestimonials?.map(item1 => item1?.id).includes(item?.id)) {
                updatedTestimonials?.unshift(item)
            }
        })

        const testimonialParams = {
            testimonialData: updatedTestimonials,
            images: testimonials?.filter((a) => a?.fileObj != undefined)?.map((item) => {
                return [item?.fileObj]
            })
        };
        if (!marketPlaceData?.testimonials) {
            handleEnquirySwitch(true, 'testimonial')
        }
        toggleTestimonialSave(true);
        updateMarketSettings(testimonialParams, () => { toggleTestimonialSave(false) }, () => { getMarketPlaceSettings() });
    };

    const uploadingProgress = () => {
        const hide = message.loading('Upload in progress..', 0);
        setTimeout(hide, 1500);
    };

    const beforeUpload = (file) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        const isLt200MB = file.size / 1024 / 1024 < 200;
        if (!isLt5M && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")) {

        } else if (!isLt200MB && file.type == "video/mp4" || file.type == "video/x-matroska") {
            Notification.error('error', "File must be smaller than 200MB!");
        }
    };

    const handleImageChange = (info, index) => {
        console.log("369xx ii", index)
        const file = info?.file;
        const isLt5M = info.file?.size / 1024 / 1024 < 5;

        if (!isLt5M && (info.file.type === "image/jpeg" || info.file.type === "image/png" || info.file.type === "image/jpg")) {

        } else if (info.file.type != "image/jpeg" && info.file.type != "image/png" && info.file.type != "image/jpg") {
            Notification.error('error', "File extension not supported");
        }

        const updatedTestimonials = [...testimonials];
        if (info?.file && (info.file.type == "image/jpeg" || info.file.type == "image/png" || info.file.type == "image/jpg")) {
            const reader = new FileReader();
            reader.onload = () => {
                updatedTestimonials[index].image = reader.result;
                updatedTestimonials[index].file = file
                updatedTestimonials[index].fileObj = file;
                setTestimonials(updatedTestimonials);
            };
            reader.onerror = (error) => {
                console.error('Error reading the file:', error);
            };
            reader.readAsDataURL(file);
        }

        // uploadingProgress();
    };

    const validateTestimonials = () => {
        return testimonials?.every(testimonial => {
            return testimonial?.name !== '' && testimonial?.review !== '' && testimonial?.file !== null;
        });
    };
    const removeStudentImage = (index) => {
        storedBanners?.current?.forEach((item, i) => {
            if (i === index) {
                item.imageAction = 'delete'
            }
        })
        const updatedTestimonials = [...testimonials];
        // updatedStudents[index].students[studentIndex].imageAction = 'delete';
        // updatedStudents[index].students[studentIndex].id = student?.id;
        // updatedStudents[index].students[studentIndex].key = student.key;
        updatedTestimonials[index].image = undefined;
        updatedTestimonials[index].key = undefined;
        setTestimonials(updatedTestimonials)
    }

    return (
        <Col style={{ width: '100%' }}>
            <div className='r-jsb flex-wrap' style={{ border: testimonials?.length ? '1px solid #AEAEAE' : 'none', padding: 20, marginTop: 15, borderRadius: 8 }}>
                {testimonials?.map((testimonial, index) => (
                    <center key={index} style={{ border: '1px solid #E6E6E6', backgroundColor: '#F6F4FE', width: testimonials?.length % 2 !== 0 ? '100%' : '49%', height: '54vh', borderRadius: 8, position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px 5px 0 0' }}>
                            <Button style={{ padding: 0 }} type='transparent' icon={<CloseCircleFilled style={{ color: '#FF414D', fontSize: 16 }} />} onClick={() => handleRemoveTestimonial(testimonial, index)}></Button>
                        </div>
                        <div style={{ position: "relative" }}>
                            {testimonial?.image ? (
                                <img
                                    src={testimonial.image}
                                    style={{ cursor: 'pointer', width: 120, height: 120, borderRadius: '50%' }}
                                    alt="Uploaded Image"
                                />
                            ) :
                                testimonial?.key ? (
                                    <img
                                        src={`${IMG_URL}/${testimonial.key}`}
                                        style={{ cursor: 'pointer', width: 120, height: 120, borderRadius: '50%' }}
                                        alt="Uploaded Image"
                                    />
                                ) : (
                                    <div onClick={() => {
                                        selectedIndex.current = index
                                        clickfrommodal.current = false; inputProfilePic?.current?.click();
                                    }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: "1px solid #aeaeae", height: 120, width: 120, borderRadius: 60, backgroundColor: "white", cursor: "pointer" }}>
                                        <Button type='transparent' style={{ padding: 0 }} icon={<PlusCircleOutlined style={{ color: '#1089FF' }} />}></Button>
                                        <div style={{ color: '#1089FF', padding: 0, fontWeight: 600 }}>ADD IMAGE</div>
                                    </div>
                                )}
                            {
                                (testimonial?.image || testimonial?.key) ?
                                    <div className="r-c-c" style={{ position: 'absolute', bottom: 0, right: "45%", border: '1px solid #EDEDED', borderRadius: '50%', width: 30, height: 30, backgroundColor: "white", }}>
                                        <Button onClick={() => {
                                            selectedIndex.current = index
                                            setImageChangeModalVisible(true);
                                        }} type='transparent' style={{ padding: 0 }} className='r-c-c' icon={<CameraFilled style={{ color: '#1089FF', fontSize: 18, marginLeft: 6 }} />}></Button>
                                    </div>
                                    : null
                            }

                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Input placeholder='Enter name' style={{ width: '80%' }} onChange={(e) => handleChangeName(index, e)} value={testimonial?.name} />
                        </div>
                        <div style={{ margin: '10px 0 0 0' }}>
                            <Input.TextArea maxLength={300} placeholder='Please type 200-300 characters' style={{ width: '80%', resize: 'none', height: '15vh' }} onChange={(e) => handleChangeComment(index, e)} value={testimonial?.review} />
                        </div>
                    </center>
                ))}
            </div>
            <center style={{}}>
                <Button disabled={!validateTestimonials()} style={{ color: '#1089FF', fontSize: 14, marginTop: 10 }} type='link' icon={<PlusCircleOutlined />} onClick={handleAddTestimonial}>ADD TESTIMONIALS</Button>
                <Button disabled={!testimonials?.length || !validateTestimonials()} onClick={() => handleTestimonialSave()} loading={testimonialSave} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button>
            </center>
            <input
                type="file"
                id="file"
                ref={inputProfilePic}
                onChange={(e) => {
                    if (clickfrommodal?.current) {
                        removeStudentImage(selectedIndex?.current);
                    }
                    handleImageChange({ file: e.target.files[0] }, selectedIndex.current)
                }}
                style={{ display: "none" }}
                accept="image/*"
                value=""
            />
            <ImageChangeModal disabled={selectedIndex?.current != undefined ? (testimonials[selectedIndex?.current]?.image || testimonials[selectedIndex?.current]?.key) ? false : true : false} isModalVisible={imageChangeModalVisible} onCancel={() => { setImageChangeModalVisible(false) }} onRemove={() => {
                removeStudentImage(selectedIndex.current);
                setImageChangeModalVisible(false)
            }} onChange={() => {
                setImageChangeModalVisible(false)
                clickfrommodal.current = true
                inputProfilePic.current?.click();
            }} />
        </Col>
    )
}

const Footer = ({ toggleMultipleLocationSwitch, multipleLocationSwitch, updateMarketSettings, getMarketPlaceSettings, marketPlaceData }) => {


    const [footerSaveLoader, toggleFooterSaveLoader] = useState(false);
    const [formValues, setFormValues] = useState({
        primaryLocation: '',
        website: '',
        emailId: '',
        mobileNumber1: '',
        mobileNumber2: '',
        socialMedia: [],
        locations: [{ name: '', address: '', latitude: null, longitude: null }],
        address: { locality: '', city: '', country: '', state: '', district: '', pincode: '', }
    });
    const [addingNewLocation, setAddingNewLocation] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [editingLocation, setEditingLocation] = useState(false);
    const [mapData, setMapData] = useState({ latitude: '', longitude: '', address: '', name: "" });
    const [toggler, setToggler] = useState(false);
    const [searchVal, setSearchVal] = useState('')
    const [map, setMap] = React.useState(null);
    const [saveLoader, toggleSaveLoader] = useState(false);

    if (!Array.isArray(formValues?.socialMedia) || !formValues?.socialMedia?.length) {
        Object.assign(formValues, {
            socialMedia: [
                {
                    "Instagram": ""
                },
                // {
                //     "WhatsApp": ""
                // },
                // {
                //     "Twitter": ""
                // },
                // {
                //     "Facebook": ""
                // },
                // {
                //     "LinkedIn": ""
                // }
            ]
        })
    }

    const containerStyle = {
        width: "100%",
        height: "58vh"
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    let searchRender = (e) => {
        setSearchVal(e)
    }

    let buttonHandler = (e) => {
        extractData(e)
        searchRender(e)
    }

    let enterHandler = (e) => {
        console.log('entering')
        if (e.key === 'Enter') {
            extractData(searchVal)
        }
    };


    let allowClearHandler = () => {
        setSearchVal('')
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANNuGsbyjTzqrtYc65aRapQ1fkfaVh0aI",
        libraries: ["places"]
    })

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map?.fitBounds(bounds);

        setMap(map)
    }, [])

    const createMarkers = () => {
        {
            if (mapData?.latitude && mapData?.longitude) {
                return <Marker
                    position={{ lat: mapData?.latitude, lng: mapData?.longitude }}
                />
            } else { return null }
        }
    };

    const extractData = (searchVal) => {
        Geocode?.setApiKey("AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ");
        Geocode?.setLanguage("en");

        Geocode?.fromAddress(searchVal).then(
            (response) => {
                const { lat, lng } = response?.results[0]?.geometry?.location;
                setMapData((prev) => ({ ...prev, latitude: lat, longitude: lng }))
            },
            (error) => {
                console.error(error);
            }
        );
    }

    useEffect(() => {
        if (marketPlaceData && marketPlaceData.footerData) {
            const initialFormValues = {
                id: marketPlaceData?.footerData?.id,
                website: marketPlaceData?.footerData?.website || '',
                emailId: marketPlaceData?.footerData?.emailId || '',
                mobileNumber1: marketPlaceData?.footerData?.mobileNumber1 || marketPlaceData?.supportNo?.[0] || '',
                mobileNumber2: marketPlaceData?.footerData?.mobileNumber2 || (marketPlaceData?.footerData?.mobileNumber1 ? '' : marketPlaceData?.supportNo?.[1]) || '',
                socialMedia: marketPlaceData?.footerData?.socialMedia || [],
                locations: marketPlaceData?.footerData?.locations || [{ name: '', address: '' }],
                address: marketPlaceData?.footerData?.primaryLocation || marketPlaceData?.address
            };
            setFormValues(JSON.parse(JSON.stringify(initialFormValues)));
        }
    }, [JSON?.stringify(marketPlaceData?.footerData)]);

    const areMandatoryFieldsFilled = () => {
        // Check if all mandatory fields are filled out
        const mandatoryFields = [
            formValues?.address?.locality,
            formValues?.address?.pincode,
            formValues?.address?.state,
            formValues?.address?.city,
            formValues?.emailId,
            formValues?.mobileNumber1
        ];

        return mandatoryFields.every(field => field !== undefined && field !== '');
    };


    const [emailError, setEmailError] = useState('');


    // const handleInputChange = (field, value) => {
    //     let newValue = value;
    //     let isValid = true;
    //     // Email validation
    //     if (field === 'emailId') {
    //         const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //         if (!emailRegex.test(value)) {
    //             // If the entered email is not valid, set the error state
    //             setEmailError('Please enter a valid email address.');
    //             isValid = false; // Mark as invalid
    //         } else {
    //             setEmailError(''); // Clear error message if valid
    //         }
    //     }

    //     // Update form values
    //     setFormValues((prevValues) => ({
    //         ...prevValues,
    //         [field]: newValue,
    //     }));

    // };

    const [validNumber1, setValidNumber1] = useState('');
    const [validNumber2, setValidNumber2] = useState('');
    const [valid1Btn, setValid1Btn] = useState(false);
    const [valid2Btn, setValid2Btn] = useState(false);

    const handleInputChange = (field, value) => {
        // if (field === 'mobileNumber1' || field === 'mobileNumber2') {
        //     if (!Utils.onlyDigits(value)) {
        //         return
        //     }
        // }
        let newValue = value;
        let isValid = true;

        // Mobile number validation
        // if (field === 'mobileNumber1') {
        //     const mobileRegex = /^\d{0,10}$/; // Allow up to 10 digits

        //     if (!mobileRegex.test(value)) {
        //         setValidNumber1("Enter valid phone number");
        //         setValid1Btn(true);
        //         isValid = false; // Mark as invalid
        //     } else {
        //         setValidNumber1(""); // Clear error message for mobileNumber1
        //         setValid1Btn(false);
        //     }
        // }
        // if (field === 'mobileNumber2') {
        //     const mobileRegex = /^\d{0,10}$/; // Allow up to 10 digits

        //     if (!mobileRegex.test(value)) {
        //         setValidNumber2("Enter valid phone number");
        //         setValid2Btn(true);
        //         isValid = false; // Mark as invalid
        //     } else {
        //         setValidNumber2(""); // Clear error message for mobileNumber2
        //         setValid2Btn(false);
        //     }
        // }

        // Email validation
        if (field === 'emailId') {
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            if (!emailRegex.test(value)) {
                setEmailError('Please enter a valid email address.');
                isValid = false; // Mark as invalid
            } else {
                setEmailError(''); // Clear error message if valid
            }
        }

        // Update form values
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: newValue,
        }));
    };

    const handleCountryCodeChange = (field,value) => {
        let callingCode = null;
        try{
            callingCode = getCountryCallingCode(value);
        }catch(e){callingCode = '91';}
        if(callingCode){
            setFormValues((prevValues) => ({
                ...prevValues,
                [field]: callingCode || '91',
            }));
        }
    }

    // Check if email is in valid format


    const isEmailValid = () => {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(formValues?.emailId);
    };

    // Check if mobile numbers contain only numbers
    const areMobileNumbersValid = () => {
        return formValues.mobileNumber1 && formValues?.mobileNumber1 != ''

        // const mobileNumberRegex = /^\d+$/;
        // return mobileNumberRegex.test(formValues?.mobileNumber1);
    };

    // Disable SAVE button if email or mobile numbers are not valid
    const isSaveDisabled = () => {
        return !areMobileNumbersValid() || !areMandatoryFieldsFilled() || valid1Btn == true || valid2Btn == true;
    };

    const [selectedValues, setSelectedValues] = useState([]);

    const onInputChange = (value, index, ref, handle) => {
        const updateSocialMedia = [...formValues.socialMedia];

        if (ref === 'select') {
            updateSocialMedia[index].key = value;
            setSelectedValues(prevSelectedValues => [
                ...prevSelectedValues.slice(0, index),
                { type: 'select', value: value },
                ...prevSelectedValues.slice(index + 1),
            ]);
        } else if (ref === 'input') {
            updateSocialMedia[index].value = value;
        } else {
            updateSocialMedia[index].name = value;
        }

        setFormValues(prevValues => ({ ...prevValues, socialMedia: updateSocialMedia }));
    };


    const appendToLocs = () => {
        setFormValues({
            ...formValues,
            locations: formValues?.locations ? [...formValues?.locations, { name: mapData?.name, address: mapData?.address, latitude: mapData?.latitude, longitude: mapData?.longitude }] : [{ name: mapData?.name, address: mapData?.address, latitude: mapData?.latitude, longitude: mapData?.longitude }]
        })
        setToggler(!toggler)
        setAddingNewLocation(false);
        setSearchVal('')
        setMapData({ latitude: '', longitude: '', address: '', name: "" });
    }

    const editLocs = () => {
        let locations = [...formValues?.locations]
        locations[selectedIndex] = { ...mapData }
        setFormValues({ ...formValues, locations: locations })
        setToggler(!toggler)
        setEditingLocation(false);
        setSearchVal('')
        setMapData({ latitude: '', longitude: '', address: '', name: "" });
    }

    // const handleFooterSave = () => {

    //     const socialMediaObject = {};

    //     if (formValues?.address?.city == '' || formValues?.address?.city == undefined || formValues?.address?.pincode == '' || formValues?.address?.pincode == undefined || formValues?.address?.country == '' || formValues?.address?.country == undefined || formValues?.address?.state == '' || formValues?.address?.state == undefined || formValues?.address?.locality == ''
    //         || formValues?.address?.locality == undefined || formValues?.mobileNumber1 == '' || formValues?.mobileNumber1 == undefined
    //         || formValues?.emailId == '' || formValues?.emailId == undefined) {
    //         Notification.error('error', "Please fill all the mandatory fields before saving");
    //         return
    //     }

    //     formValues?.socialMedia?.forEach((entry) => {
    //         const key = entry.key;
    //         const value = entry.value;
    //         socialMediaObject[key] = value;
    //     });
    //     const updatedFormValues = {
    //         ...formValues,
    //         socialMedia: socialMediaObject,
    //         id: formValues?.id
    //     };


    //     toggleFooterSaveLoader(true)
    //     if (multipleLocationSwitch) {
    //         updateMarketSettings({ footerData: updatedFormValues, settingData: { locationsActive: true } }, () => { toggleFooterSaveLoader(false) }, () => { getMarketPlaceSettings() });
    //     } else {
    //         if (updatedFormValues?.locations) {
    //             delete updatedFormValues?.locations
    //         }
    //         updateMarketSettings({ footerData: updatedFormValues, settingData: { locationsActive: false } }, () => { toggleFooterSaveLoader(false) }, () => { getMarketPlaceSettings() });
    //     }
    // };

    const handleFooterSave = () => {
        const socialMediaArray = [];

        if (
            formValues?.address?.city === '' || formValues?.address?.city === undefined ||
            formValues?.address?.pincode === '' || formValues?.address?.pincode === undefined ||
            formValues?.address?.country === '' || formValues?.address?.country === undefined ||
            formValues?.address?.state === '' || formValues?.address?.state === undefined ||
            formValues?.address?.locality === '' || formValues?.address?.locality === undefined ||
            formValues?.mobileNumber1 === '' || formValues?.mobileNumber1 === undefined ||
            formValues?.emailId === '' || formValues?.emailId === undefined
        ) {
            Notification.error('error', 'Please fill all the mandatory fields before saving');
            return;
        }

        // Check for empty social media key or value from index 1 onwards
        const hasEmptySocialMedia = formValues?.socialMedia?.slice(1).some(entry => !entry.key || !entry.value);
        if (hasEmptySocialMedia || (formValues?.socialMedia?.[0]?.key && !formValues?.socialMedia?.[0]?.value)) {
            Notification.error('error', 'Please fill all the social media fields before saving');
            return;
        }

        formValues?.socialMedia?.forEach((entry) => {
            const key = entry.key;
            const value = entry.value;
            const name = entry.name
            socialMediaArray.push({ key, value, name });
        });

        const updatedFormValues = {
            ...formValues,
            socialMedia: socialMediaArray,
            id: formValues?.id
        };

        toggleFooterSaveLoader(true);

        if (multipleLocationSwitch) {
            updateMarketSettings(
                { footerData: updatedFormValues, settingData: { locationsActive: true } },
                () => toggleFooterSaveLoader(false),
                () => getMarketPlaceSettings()
            );
        } else {
            if (updatedFormValues?.locations) {
                delete updatedFormValues?.locations;
            }
            updateMarketSettings(
                { footerData: updatedFormValues, settingData: { locationsActive: false } },
                () => toggleFooterSaveLoader(false),
                () => getMarketPlaceSettings()
            );
        }
    };



    const handleEditLocation = (editedLocation, index) => {
        // Implement logic to handle editing a location
        // You might want to open a modal or navigate to an edit page
        setMapData({ latitude: editedLocation?.latitude, longitude: editedLocation?.longitude, name: editedLocation?.name, address: editedLocation?.address })
        setSearchVal(editedLocation.address)
        setAddingNewLocation(false)
        setEditingLocation(true)
        setSelectedIndex(index)
    };

    const handleDeleteLocation = (locationToDelete, index) => {
        // Implement logic to handle deleting a location
        let locations = [...formValues?.locations?.filter((_, i) => (i != index))]
        setFormValues({ ...formValues, locations: locations })
        setToggler(!toggler)
        setAddingNewLocation(false);
        setSearchVal('')
        setMapData({ latitude: '', longitude: '', address: '', name: "" });

    };

    const menu = (item, index) => (
        <Menu>
            <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => handleEditLocation(item, index)}>
                Edit
            </Menu.Item>
            <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={() => handleDeleteLocation(item, index)}>
                Delete
            </Menu.Item>
        </Menu>
    );

    const [autoFillPin, setAutoFillPin] = useState(false);


    const changeAddress = (key, value) => {
        // Update the formValues with the new input
        formValues.address[key] = value;
        setFormValues({ ...formValues });

        console.log("inputssss", key, value);

        // Check if the key is 'pincode' before calling getAddressFromPin
        if (key === 'pincode') {
            setAutoFillPin(true)
            console.log("autoAddressPin")
        }
    };

    console.log("autoFillPin", autoFillPin)

    useEffect(() => {
        if (autoFillPin) {
            getAddressFromPin(formValues.address.pincode, (city, state, country) => {
                setFormValues(prevAutoAddress => ({
                    ...prevAutoAddress,
                    address: {
                        ...prevAutoAddress.address,
                        city,
                        state,
                        country,
                    },
                }));
            });
        }
    }, [autoFillPin, formValues.address.pincode]);

    console.log("formsss", formValues?.address)
    const socialMediaOptions = ['WhatsApp', 'Facebook', 'Instagram', 'Twitter', 'LinkedIn', 'YouTube'];
    const handleAddField = () => {
        setFormValues(prevState => ({
            ...prevState,
            socialMedia: [...prevState.socialMedia, { key: '', value: '' }],
        }));
    };
    const handleRemoveField = (index) => {
        setFormValues(prevState => ({
            ...prevState,
            socialMedia: prevState.socialMedia.filter((_, i) => i !== index),
        }));
    };
    const uniqueSocialKeys = new Set();

    const renderedSocialMedia = marketPlaceData?.footerData?.socialMedia
        .filter(item => !uniqueSocialKeys.has(item?.key) && uniqueSocialKeys.add(item?.key))
        .map(item => (
            
            
            <div key={item?.key} >
    
                {item?.key == "Instagram" ? <img className="" style={{marginBottom:15, cursor:'not-allowed', height:25, width:25, marginRight:10}} src={require("../../Assets/Footer/insta.svg").default}  /> : <></>}
                {item?.key == "YouTube" ? <img className="" style={{marginBottom:15, cursor:'not-allowed', height:25, width:25, marginRight:10}} src={require("../../Assets/Footer/yt.svg").default}  /> : <></>}
                {item?.key == "WhatsApp" ? <img className="" style={{marginBottom:15, cursor:'not-allowed', height:25, width:25, marginRight:10}} src={require("../../Assets/Footer/whatsapp.svg").default}  /> : <></>}
                {item?.key == "Facebook" ? <img className="" style={{marginBottom:15, cursor:'not-allowed', height:25, width:25, marginRight:10}} src={require("../../Assets/Footer/fb1.svg").default}  /> : <></>}
                {item?.key == "Twitter" ? <img className="" style={{marginBottom:15, cursor:'not-allowed', height:25, width:25, marginRight:10}} src={require("../../Assets/Footer/twitter1.svg").default}  /> : <></>}
                {item?.key == "LinkedIn" ? <img className="" style={{marginBottom:15, cursor:'not-allowed', height:25, width:25, marginRight:10}} src={require("../../Assets/Footer/LinkedIn.svg").default}  /> : <></>}

                {/* {item.key === "Instagram" && <img style={{ fontSize: 10, cursor: "not-allowed" }} className="market-footer-logo-size" src={require("../../Assets/aulasMarket/instagram-logo.svg").default} alt="Instagram Logo" />}
                {item.key === "WhatsApp" && <img style={{ fontSize: 10, cursor: "not-allowed" }} className="market-footer-logo-size" src={require("../../Assets/aulasMarket/whatsApp-Logo.svg").default} alt="WhatsApp Logo" />}
                {item.key === "Facebook" && <img style={{ fontSize: 10, cursor: "not-allowed" }} className="market-footer-logo-size" src={require("../../Assets/aulasMarket/facebook-logo.svg").default} alt="Facebook Logo" />}
                {item.key === "Twitter" && <img style={{ fontSize: 10, cursor: "not-allowed" }} className="market-footer-logo-size" src={require("../../Assets/aulasMarket/X-logo.svg").default} alt="Twitter Logo" />}
                {item.key === "LinkedIn" && <img style={{ fontSize: 10, cursor: "not-allowed" }} className="market-footer-logo-size" src={require("../../Assets/aulasMarket/icons8-linkedin.svg").default} alt="LinkedIn Logo" />} */}
            </div>
        ));

    const handleSocialMediaClick = (item) => {
        if (item?.urls?.length > 1) {
            setIsSocialMedia(true)
            setSocialMediaData(item)
        } else {
            window.open(item?.url, '_blank')
        }

    }

    const websiteUrl = marketPlaceData?.footerData?.website;
    const validUrl = typeof websiteUrl === 'string' && (websiteUrl.startsWith('http://') || websiteUrl.startsWith('https://'))
    ? websiteUrl
    : `https://${websiteUrl}`;
    return (
        <div style={{ border: '1px solid #AEAEAE', borderRadius: 8, padding: 20, overflowX: "hidden" }}>
            <Heading>Footer<span style={{ color: 'red' }}>*</span></Heading>
            <div className='simgrey'>Provides vital information, enhances navigation, and fosters trust and connectivity on your platform for a seamless user experience.</div>

            <div className='scroll-bar-universal' style={{ marginTop: 10 }}>
                <Row className='r-jsb' style={{ width: '100%' }}>
                    <Col style={{ width: '50%' }}>
                        <div className='text-xmd bold-600 m-b-5' style={{}}>House No./Locality/Area<span style={{ color: 'red' }}>*</span></div>
                        <Input style={{ width: '95%' }} placeholder='Enter House No./Locality/Area' onChange={(e) => { changeAddress('locality', e.target.value) }} value={formValues?.address?.locality} /* onChange={(e) => handleInputChange('primaryLocation', e.target.value)} */ draggable />
                    </Col>
                    {console.log("footerdata", marketPlaceData?.footerData)}
                    <Col style={{ width: '50%' }}>
                        <div className='text-xmd bold-600 m-b-5' style={{}}>Pincode<span style={{ color: 'red' }}>*</span></div>
                        <Input style={{ width: '100%' }} placeholder='Enter pincode' value={formValues?.address?.pincode} onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^0-9]/g, '').substring(0, 6);
                            changeAddress('pincode', numericValue);
                        }} />
                    </Col>
                </Row>

                <Row className='r-jsb m-t-20' style={{ width: '100%' }}>
                    <Col style={{ width: '50%' }}>
                        <div className='text-xmd bold-600 m-b-5' style={{}}>State<span style={{ color: 'red' }}>*</span></div>
                        <Input style={{ width: '95%' }} placeholder='Enter state' value={formValues?.address?.state} onChange={(e) => { changeAddress('state', e.target.value) }} draggable />
                    </Col>
                    <Col style={{ width: '50%' }}>
                        <div className='text-xmd bold-600 m-b-5' style={{}}>District<span style={{ color: 'red' }}>*</span></div>
                        <Input style={{ width: '100%' }} placeholder='Enter district' value={formValues?.address?.city} onChange={(e) => { changeAddress('city', e.target.value) }} draggable />
                    </Col>
                </Row>

                <Row className='r-jsb m-t-20' style={{ width: '100%' }}>
                    <Col style={{ width: '50%' }}>
                        <div className='text-xmd bold-600 m-b-5' style={{}}>Country<span style={{ color: 'red' }}>*</span></div>
                        <Select open={false} style={{ width: '95%', cursor: "default" }} placeholder='Enter country' value={formValues?.address?.country} onChange={(e) => { changeAddress('Country', e.target.value) }} draggable />
                    </Col>

                </Row>

                <Col className='m-t-30'>
                    <div className='text-xmd bold-600' style={{}}>Website</div>
                    <Input placeholder='Enter website' value={formValues?.website} onChange={(e) => handleInputChange('website', e.target.value)} draggable />
                </Col>

                <Col className='m-t-30'>
                    <div className='text-xmd bold-600' style={{}}>Email ID<span style={{ color: 'red' }}>*</span></div>
                    <Input placeholder='Enter e-mail' value={formValues?.emailId} onChange={(e) => handleInputChange('emailId', e.target.value)} draggable />
                    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                </Col>

                <Row className='m-t-30 r-jsb' style={{}}>
                    <Col className='' style={{ width: '48%' }}>
                        <div className='text-xmd bold-600' style={{}}>Mobile number 1<span style={{ color: 'red' }}>*</span></div>

                        {/* <Input prefix={<div>+91</div>} placeholder='Enter first mobile number' style={{ width: '100%' }} value={formValues?.mobileNumber1} onChange={(e) => handleInputChange('mobileNumber1', e.target.value)} /> */}
                        <PhoneInput
                            style={{
                                width: "100%",
                                border: "1px solid #D9D9D9",
                                paddingLeft: 5,
                                backgroundColor: "transparent",
                            }}
                            addInternationalOption={true}
                            defaultCountry="IN"
                            placeholder="Enter first mobile number"
                            height={"40px"}
                            value={formValues?.mobileNumber1}
                            onCountryChange={(e) => handleCountryCodeChange('mobileNumber1Code',e) }
                            onChange={(e) => handleInputChange('mobileNumber1', e)}
                            maxLength={formValues?.mobileNumber1?.startsWith('+91') && formValues?.mobileNumber1?.length == 13 ? 11 : ""}
                        />
                        {/* {validNumber1 && <div style={{ color: 'red', fontSize: "12px" }}>{validNumber1}</div>} */}
                    </Col>
                    <Col className='' style={{ width: '48%' }}>
                        <div className='text-xmd bold-600' style={{}}>Mobile number 2</div>

                        {/* <Input prefix={<div>+91</div>} placeholder='Enter second mobile number' style={{ width: '100%' }} value={formValues?.mobileNumber2} onChange={(e) => handleInputChange('mobileNumber2', e.target.value)} /> */}
                        <PhoneInput
                            style={{
                                width: "100%",
                                border: "1px solid #D9D9D9",
                                paddingLeft: 5,
                                backgroundColor: "transparent",
                            }}
                            addInternationalOption={true}
                            defaultCountry="IN"
                            placeholder="Enter second mobile number"
                            height={"40px"}
                            value={formValues?.mobileNumber2}
                            onCountryChange={(e) => handleCountryCodeChange('mobileNumber2Code',e)}
                            onChange={(e) => handleInputChange('mobileNumber2', e)}
                            maxLength={formValues?.mobileNumber2?.startsWith('+91') && formValues?.mobileNumber2?.length == 13 ? 11 : ""}
                        />
                        {/* {validNumber2 && <div style={{ color: 'red', fontSize: "12px" }}>{validNumber2}</div>} */}
                    </Col>
                </Row>
                <Row className='full-width'>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, width: '100%' }}>
                        <div style={{ width: '38%', height: 1, borderBottom: '1px solid black', padding: '0 5px' }}></div>
                        <span style={{ marginTop: -10, padding: '0px 30px', fontWeight: 600 }}>Social media handle</span>
                        <div style={{ width: '38%', height: 1, borderBottom: '1px solid black' }}></div>
                    </div>
                </Row>

                <div className='m-t-30'>
                    {formValues?.socialMedia?.map((handle, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                            <Select
                                value={handle?.key || undefined}
                                style={{ width: '100%', marginRight: 10 }}
                                placeholder="Select social media handle"
                                onChange={(value) => onInputChange(value, index, 'select', handle)}
                            >
                                {socialMediaOptions?.map((socialHandle) => (
                                    <Option key={socialHandle} value={socialHandle}>
                                        {socialHandle}
                                    </Option>
                                ))}
                            </Select>
                            <Input
                                prefix={(() => {
                                    switch (handle?.key) {
                                        case 'WhatsApp':
                                            return <div>https://wa.me/</div>;
                                        case 'Facebook':
                                            return <div>https://facebook.com/</div>;
                                        case 'Instagram':
                                            return <div>https://instagram.com/</div>;
                                        case 'Twitter':
                                            return <div>https://twitter.com/</div>;
                                        case 'LinkedIn':
                                            return <div>https://linkedin.com/</div>;
                                        case 'YouTube':
                                            return <div>https://youtube.com/</div>;
                                        default:
                                            return null;
                                    }
                                })()}
                                value={handle?.value}
                                style={{ width: '100%', marginRight: "10px" }}
                                onChange={(e) => onInputChange(e.target.value, index, 'input', handle)}
                            />
                            <Input
                                placeholder='Enter url name'
                                style={{ width: '100%' }}
                                value={handle?.name}
                                onChange={(e) => onInputChange(e.target.value, index, 'urlName', handle)}
                            />
                            <div className='m-l-5 cursor-pointer' onClick={() => handleRemoveField(index)}>
                                {(index === 0 && handle?.key) || (index >= 1) ?
                                    <CloseCircleFilled style={{ color: "red" }} />
                                    : <></>}
                            </div>

                        </div>
                    ))}
                    <div className='r-c-c'>
                        <Button type='link' icon={<PlusCircleOutlined style={{ fontSize: 14 }} />} style={{ fontSize: "14px" }} onClick={handleAddField}
                        // disabled={formValues?.socialMedia?.every(item => !item.key || !item.value)}

                        >Add</Button>
                    </div>

                </div>

                <div className='r-jsb' style={{ width: '98%', marginTop: 20, overflowX: "hidden" }}>
                    <Col style={{ width: '100%' }}>
                        <Heading>Add multiple locations</Heading>
                        <div style={{ width: '95%' }}>Display various hubs, improving accessibility and connection, making your platform more inclusive for users worldwide.</div>
                        {multipleLocationSwitch &&
                            <>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "45%", marginRight: 40, height: addingNewLocation || editingLocation ? "75vh" : "67vh" }} className="scroll-bar-universal">
                                        <div style={{ fontWeight: "bold", fontSize: 14, marginTop: 10 }}>
                                            Location(s)
                                        </div>
                                        {(formValues && formValues?.locations && formValues?.locations?.length) ? formValues?.locations?.map((item, index) => (
                                            <div key={item?.id} style={{ width: '100%', borderRadius: 8, padding: 15, border: '1px solid #AEAEAE', marginTop: 20 }}>
                                                <Col>
                                                    <Row className='r-jsb'>
                                                        <div className='color-black bold-600' style={{ fontSize: '1rem' }}>{item?.name}</div>
                                                        <div>
                                                            <Dropdown overlay={menu(item, index)}>
                                                                <MoreOutlined />
                                                            </Dropdown>
                                                        </div>
                                                    </Row>
                                                    <div className='color-black' style={{ fontSize: "0.8rem" }}>{item?.address}</div>
                                                </Col>
                                            </div>
                                        )) : <div className='r-c-c full-height'><NodataCard imgwidth={'70%'} imgheight={'50%'} text="No location(s) added yet" /></div>}
                                    </div>
                                    <div style={{ width: '90%', marginTop: 10, marginBottom: 20, height: "100%" }}>
                                        {
                                            addingNewLocation || editingLocation ?
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div>
                                                        <span className="m-b-5 m-r-5">Address Name<span style={{ color: 'red' }}>*</span></span>
                                                        <Input
                                                            placeholder='Enter name'
                                                            style={{ width: '95%' }}
                                                            value={mapData.name}
                                                            onChange={(e) => setMapData(prev => ({ ...prev, name: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className="m-b-5 m-r-5">Location<span style={{ color: 'red' }}>*</span></span>
                                                        <Input
                                                            placeholder='Enter address'
                                                            style={{ width: '95%', }}
                                                            value={mapData.address}
                                                            onChange={(e) => setMapData(prev => ({ ...prev, address: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="m-t-15">
                                                        <Button onClick={() => {
                                                            setEditingLocation(false)
                                                            setAddingNewLocation(false)
                                                            setSearchVal('')
                                                            setMapData({ latitude: '', longitude: '', address: '', name: "" });
                                                        }} type='transparent' style={{ padding: 0 }} icon={<CloseCircleFilled style={{ color: 'red', fontSize: 18 }} />}></Button>
                                                    </div>
                                                </div>
                                                :
                                                <center>
                                                    <Button style={{ color: '#1089FF', fontSize: 14 }} type='link' icon={<PlusCircleOutlined />} onClick={() => {
                                                        setEditingLocation(false)
                                                        setAddingNewLocation(true)
                                                    }}>
                                                        ADD NEW LOCATION
                                                    </Button>
                                                </center>
                                        }
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: "100%", paddingTop: 10 }}>
                                            {
                                                addingNewLocation || editingLocation ?
                                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                                        <div style={{ width: "100%" }}>
                                                            <AutoComplete style={{ width: "100%" }} value={searchVal} searchRender={searchRender} buttonHandler={buttonHandler} setSearchVal={setSearchVal} isEdit={false} allowClearHandler={allowClearHandler} enterHandler={enterHandler} />
                                                        </div>
                                                        <div style={{ width: "10%", marginLeft: 20 }}>
                                                            <Button style={{ color: '#1089FF', fontSize: 14 }} disabled={mapData?.latitude === '' || mapData?.longitude === '' || mapData?.address == undefined || mapData?.name == undefined || mapData?.address?.trim() == '' || mapData?.name?.trim() == ''} type='link' icon={addingNewLocation ? <PlusCircleOutlined /> : null} onClick={() => {
                                                                if (addingNewLocation) {
                                                                    appendToLocs()
                                                                } else if (editingLocation) {
                                                                    editLocs()
                                                                }
                                                            }}>
                                                                {addingNewLocation ? 'ADD' : editingLocation ? 'EDIT' : null}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div style={{ marginTop: 20 }}>
                                                {isLoaded ? <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    onLoad={onLoad}
                                                    center={{ lat: mapData?.latitude, lng: mapData?.longitude }}
                                                    defaultZoom={15}
                                                    zoom={13}
                                                >
                                                    {createMarkers()}

                                                </GoogleMap> : null}
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </>
                        }
                        <div className='border-dashed r-c-c' style={{ marginTop: 20, width: '107%', height: multipleLocationSwitch ?  '35%' : '90%' }}>
                            <div className='preview-text'>Preview</div>
                            <div className='web-footer' >
                                <div style={{ position: "relative", overflow: "hidden", display: "flex" }}>
                                    <div style={{
                                        backgroundImage: `url(${footerBackground})`
                                    }}>
                                        <img src={footerBackground} style={{ width: '100%', }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', position: "absolute", paddingTop: '3%', paddingLeft: '7%', paddingRight: '9%', width: '100%' }}>
                                        <div style={{}}>
                                            <div style={{ fontSize: 17, color: '#FFEF00' }}>{marketPlaceData?.orgName}</div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontSize: 13, color: '#FAFAFA', marginTop: 12 }}>{marketPlaceData?.footerData?.primaryLocation?.locality},{" "}{marketPlaceData?.footerData?.primaryLocation?.city}</div>
                                                <div style={{ fontSize: 13, color: '#FAFAFA', }}>{" "}{marketPlaceData?.footerData?.primaryLocation?.state}</div>
                                                <div style={{ fontSize: 13, color: '#FAFAFA', }}>{" "}{marketPlaceData?.footerData?.primaryLocation?.country}
                                                    {" "}{marketPlaceData?.footerData?.primaryLocation?.pincode}</div>
                                            </div>

                                            {marketPlaceData?.footerData?.locations?.length > 0 && multipleLocationSwitch ? <div style={{ display: 'flex', marginTop: 20, }}>

                                                <div style={{ color: '#FAFAFA', fontSize: 17, cursor: 'not-allowed' }}>View other locations</div>
                                                <img src={locations} style={{ marginTop: 6, marginLeft: 10, cursor: 'not-allowed' }}  />
                                            </div>
                                                : <></>}


                                        </div>

                                        <div style={{}}>
                                            <div style={{ fontSize: 17, color: '#FFEF00' }}>Contact us</div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <a href={`tel:${marketPlaceData?.footerData?.mobileNumber1}`}
                                                    style={{ fontSize: 13, color: '#FAFAFA', marginTop: 12, textDecoration: 'none' }}>
                                                    {marketPlaceData?.footerData?.mobileNumber1?.replace(/^(\+\d{2})(\d+)/, '$1 $2')}
                                                </a>


                                                {marketPlaceData?.footerData?.mobileNumber2 && (
                                                    <a href={`tel:${marketPlaceData?.footerData?.mobileNumber2}`}
                                                        style={{ fontSize: 13, color: '#FAFAFA', marginTop: 6, textDecoration: 'none' }}>
                                                        {marketPlaceData?.footerData?.mobileNumber2?.replace(/^(\+\d{2})(\d+)/, '$1 $2')}
                                                    </a>
                                                )}


                                                <a href={`mailto:${marketPlaceData?.footerData?.emailId}`}
                                                    className="cursor-pointer"
                                                    style={{ fontSize: 13, color: '#FAFAFA', textDecoration: 'none', marginTop: 4 }}>
                                                    {marketPlaceData?.footerData?.emailId}
                                                </a>
                                            </div>

                                        </div>

                                        <div style={{}}>
                                            <div style={{ fontSize: 17, color: '#FFEF00' }}>Download our app now </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <img className='m-t-20 cursor-pointer' style={{ width: 120 }} src={playStore} onClick={() => window.open(marketPlaceData?.pSLink, "_blank")} />
                                                <img className='cursor-pointer responsive-margin' style={{ width: 120 }} src={appStore} onClick={() => window.open(marketPlaceData?.aSLink, "_blank")} />

                                            </div>

                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: -20 }}>
                                            {renderedSocialMedia?.length > 0 || marketPlaceData?.footerData?.website ? <div style={{ fontSize: 17, color: '#FFEF00' }}>Visit us at</div>: <></>}
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                <div className='cursor-pointer' style={{ fontSize: 13, color: '#FAFAFA', marginTop: 12, display: 'flex', }} onClick={() => { window.open(validUrl, "_blank") }}>{marketPlaceData?.footerData?.website}</div>
                                                {/* {marketPlaceData?.footerData?.socialMedia?.length > 0 ?
                                    <>

                                        <div style={{ width: "150px", display: "flex", flexWrap: "wrap", marginTop: -5, }}>
                                            {marketPlaceData?.footerData?.socialMedia?.map((item) => (
                                                <div style={{ marginTop: 20 }}>
                                                    {item?.name == "Instagram" ? <img className="footer-logo-size" src={require("../../Assets/Footer/insta.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "WhatsApp" ? <img className="footer-logo-size" src={require("../../Assets/Footer/whatsapp.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "Facebook" ? <img className="footer-logo-size" src={require("../../Assets/Footer/fb.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "Twitter" ? <img className="footer-logo-size" src={require("../../Assets/Footer/twitter.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "LinkedIn" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/icons8-linkedin.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    :
                                    <></>} */}
                                    <div style={{height:'75px',display:'flex', flexWrap:'wrap',width:'8vw', marginTop:15,}}>
                                    {renderedSocialMedia?.length > 0 ? renderedSocialMedia : null}
                                    </div>

                                                
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ position: 'absolute', display: 'flex', bottom: '5%', paddingLeft: '7%', paddingRight: '9%', width: '100%', flexDirection: 'column' }}>
                                        <div style={{ border: '1px solid #A987FF', width: '100%', height: '1px' }}></div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                            <div style={{ fontSize: 13, color: '#FAFAFA' }}>Copyright @ Vectors Education LLP. All rights reserved.</div>
                                            <div style={{ fontSize: 13, color: '#FAFAFA' }}>Privacy policy &nbsp;   |  &nbsp; T & C</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                    <div className='m-t-30'>
                        <Switch checked={multipleLocationSwitch} onChange={(e) => toggleMultipleLocationSwitch(e)} />
                    </div>
                </div>
            </div>
            <center>
                <Button disabled={isSaveDisabled()} loading={footerSaveLoader} onClick={handleFooterSave} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button>
            </center>
        </div>
    );
}


const OutstandingResults = ({ outstandingSwitch, marketPlaceData, updateMarketSettings, getMarketPlaceSettings, handleEnquirySwitch }) => {


    const [imageChangeModalVisible, setImageChangeModalVisible] = useState(false);
    const selectedImage = useRef({ mainIndex: 0, subIndex: 0 });
    const inputProfilePic = useRef(null);
    const storedBanners = useRef(null);
    const [studentImageIndex, setStudentImageIndex] = useState(-1);
    const [outstandingSaveLoader, toggleOutStandingSaveLoader] = useState(false);
    const [outstandingResultsArr, setOutstandingResultsArr] = useState([]);


    const handleStudentImageChange = (info, index, studentIndex) => {
        const file = info?.file;
        const isLt5M = info.file?.size / 1024 / 1024 < 5;

        if (!isLt5M && (info.file.type === "image/jpeg" || info.file.type === "image/png" || info.file.type === "image/jpg")) {

        } else if (info?.file?.type != "image/jpeg" && info?.file?.type != "image/png" && info?.file?.type != "image/jpg") {
            Notification.error('error', "File extension not supported");
        }

        const updatedstudentsArr = [...outstandingResultsArr];
        updatedstudentsArr[index].students[studentIndex].imageIndex = studentImageIndex + 1;
        updatedstudentsArr[index].students[studentIndex].file = info?.file;

        if (info?.file && (info?.file?.type == "image/jpeg" || info?.file?.type == "image/png" || info?.file?.type == "image/jpg")) {
            const reader = new FileReader();
            reader.onload = () => {
                updatedstudentsArr[index].students[studentIndex].image = reader.result;
                updatedstudentsArr[index].students[studentIndex].fileObj = file;
                setOutstandingResultsArr(updatedstudentsArr);
            };
            reader.onerror = (error) => {
                console.error('Error reading the file:', error);
            };
            reader.readAsDataURL(info?.file);
        }


        setStudentImageIndex(studentImageIndex + 1);
    };

    useEffect(() => {
        if (!marketPlaceData?.outStandingResultDataArray || marketPlaceData?.outStandingResultDataArray?.length === 0) {
            const defaultExamCard = {
                examName: '',
                auxIndex: uuidv4(),
                students: []
            };
            storedBanners.current = []
            setOutstandingResultsArr([]);
        } else {
            const mappingOutstandingResults = marketPlaceData?.outStandingResultDataArray?.map((result) => {
                const newResult = {
                    examName: result?.examName || '',
                    auxIndex: uuidv4(),
                    students: result?.students?.map((student) => {
                        return {
                            id: student?.id || undefined,
                            key: student?.key || '',
                            name: student?.name || '',
                            rank: student?.rank || '',
                            scoreObtain: student?.scoreObtain || 0,
                            totalMarks: student?.totalMarks || 0,
                            imageUrl: student?.key,
                            auxIndex: uuidv4(),
                            additionalInfo: student?.additionalInfo
                        };
                    }) || []
                };
                return newResult;
            });

            // Set the mapped results to the state
            storedBanners.current = mappingOutstandingResults ? JSON?.parse(JSON?.stringify(mappingOutstandingResults)) : []
            setOutstandingResultsArr(mappingOutstandingResults ? JSON?.parse(JSON?.stringify(mappingOutstandingResults)) : []);
        }
    }, [JSON.stringify(marketPlaceData?.outStandingResultDataArray)]);

    const removeStudentImage = (index, studentIndex) => {
        storedBanners?.current[index]?.students?.forEach((item, i) => {
            if (item.auxIndex === studentIndex) {
                item.imageAction = 'delete'
            }
        })
        const updatedStudents = [...outstandingResultsArr];
        updatedStudents[index].students[studentIndex].imageUrl = '';
        updatedStudents[index].students[studentIndex].key = '';
        updatedStudents[index].students[studentIndex].image = '';
        setOutstandingResultsArr(updatedStudents);
    }

    const removeStudentCard = (index, studentIndex) => {
        console.log(outstandingResultsArr, index, storedBanners, 'standing')
        outstandingResultsArr[index].students = outstandingResultsArr[index]?.students?.filter((item, i) => item?.auxIndex != studentIndex)
        storedBanners.current[index].students?.forEach((item, i) => {
            if (item.auxIndex === studentIndex) {
                item.delete = true
            }
        })
        setOutstandingResultsArr([...outstandingResultsArr])
    }


    const removeBanner = (index) => {
        const updatedBanners = [...outstandingResultsArr?.filter((item, id) => (item.auxIndex != index))];
        setOutstandingResultsArr(updatedBanners);
    }

    const addNewStudent = (resultIndex) => {
        setOutstandingResultsArr((prevResults) => {
            const updatedResults = [...prevResults];
            if (resultIndex >= 0 && resultIndex < updatedResults?.length) {
                const newStudent = {
                    key: '',
                    name: '',
                    rank: '',
                    scoreObtain: 0,
                    totalMarks: 0,
                    auxIndex: uuidv4()
                };

                if (!updatedResults[resultIndex].students) {
                    updatedResults[resultIndex].students = [];
                }

                updatedResults[resultIndex]?.students?.push(newStudent);
            }
            return updatedResults;
        });
    };

    const addNewExamCard = (e) => {
        e.preventDefault();

        const newExamCard = {
            examName: '',
            auxIndex: uuidv4(),
            students: [{
                key: '',
                name: '',
                rank: '',
                scoreObtain: 0,
                totalMarks: 0,
                auxIndex: uuidv4(),
            }]
        };

        const newExamArr = [...outstandingResultsArr, newExamCard];
        storedBanners.current.push({ ...newExamCard })

        setOutstandingResultsArr([...newExamArr]);
    };

    const containerHeight = '45vh';
    const firstDivHeight = 60;
    const remainingHeight = '80%';

    const validateStudents = () => {
        let isValid = true;

        outstandingResultsArr.forEach((examCard, index) => {
            if (examCard?.examName == '' || examCard?.examName == undefined) {
                isValid = false;
                return
            }
            if (examCard?.students?.length === 0) {
                isValid = false
                return
            }

            examCard?.students.forEach((student, studentIndex) => {
                if (student?.image == '' || student?.rank == '' || student?.name == '') {
                    isValid = false;
                    return;
                }
            });
        });

        return isValid;
    };

    const handleOutstandingSave = () => {
        let count = 0
        const updatedBanners = [];
        for (let i = 0; i < outstandingResultsArr?.length; i++) {
            let subArray = outstandingResultsArr[i]
            updatedBanners?.push({
                ...subArray, students: subArray?.students?.map((item, subIndex) => {
                    const { file, fileObj, image, ...rest } = item;
                    if (fileObj) {
                        rest.imageIndex = count++;
                    }
                    return rest;
                })
            })
        }
        storedBanners?.current?.forEach((subArray, mainIndex) => {
            let correspondingindex = updatedBanners?.findIndex((item) => item?.auxIndex == subArray?.auxIndex)
            if (correspondingindex >= 0) {
                subArray?.students?.forEach((item, subIndex) => {
                    if (item?.id && !updatedBanners?.[correspondingindex]?.students?.map(item1 => item1?.id).includes(item?.id)) {
                        updatedBanners?.[correspondingindex]?.students?.unshift(item)
                    }
                })
            }
        })
        storedBanners?.current?.forEach((item) => {
            if (!updatedBanners?.map((itemq) => itemq?.auxIndex)?.includes(item?.auxIndex)) {
                let temp = item;
                item?.students?.forEach((item) => {
                    item.delete = true
                })
                updatedBanners?.unshift(temp)
            }
        })
        let imagesList = [];
        outstandingResultsArr?.forEach((subArray, mainIndex) => {
            subArray?.students?.forEach((item, subIndex) => {
                if (item?.fileObj != undefined) {
                    imagesList?.push([item.fileObj])
                }
            })
        })
        if (!marketPlaceData?.outStandingResult) {
            handleEnquirySwitch(true, 'outstanding')
        }
        let outStandingParams = {
            outStandingResultData: updatedBanners,
            images: imagesList
        }

        toggleOutStandingSaveLoader(true)
        updateMarketSettings(outStandingParams, () => { toggleOutStandingSaveLoader(false) }, () => { getMarketPlaceSettings() })
    }

    return (
        <div>
            {outstandingResultsArr?.map((examCard, index) => {
                return (
                    <div key={`banner${index}`} style={{ border: '1px solid #AEAEAE', width: '105%', borderRadius: 8, marginTop: 20 }}>
                        <div className='r-c-c' style={{ height: firstDivHeight, width: '100%', border: '1px solid #AEAEAE', borderTopRightRadius: 8, borderTopLeftRadius: 8, backgroundColor: '#594099', color: 'white', fontSize: 16 }}>
                            <div className='r-c-c' style={{ width: '80%', display: 'flex', marginLeft: 100 }}>
                                <div style={{ width: '80%' }}>
                                    <Input
                                        onChange={(e) => {
                                            const updateStudentName = [...outstandingResultsArr];
                                            updateStudentName[index].examName = e.target.value;
                                            setOutstandingResultsArr(updateStudentName);
                                        }}
                                        style={{ backgroundColor: 'white', color: 'black', width: '100%', fontSize: 20, textAlign: 'center' }} value={examCard?.examName} className='black-placeholder' placeholder='Enter exam name here' />
                                </div>
                                <div onClick={() => { removeBanner(examCard?.auxIndex) }} style={{ marginLeft: 'auto', cursor: 'pointer', cursor: "pointer" }}>
                                    <CloseOutlined style={{ fontSize: 20 }} />
                                </div>
                            </div>
                        </div>
                        <div className='scroll-bar-universal' key={`scroll1${index}`} style={{ width: '100%', borderBottomRightRadius: 8, borderBottomLeftRadius: 8, backgroundColor: '#F6F4FE' }}>
                            <div className='display-flex p-15' key={`scroolx${index}`} style={{ gap: 15 }}>
                                {examCard?.students?.map((student, studentIndex) => (
                                    <div key={index} style={{ width: 170, marginRight: 30, borderRadius: 8, border: (student?.image || student?.key) ? '1px solid #AEAEAE' : '1px dashed #1089FF', position: 'relative' }}>
                                        <div style={{ height: 140, width: 168, backgroundColor: 'white', borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
                                            {student?.imageUrl || student?.image ?
                                                <>
                                                    <img src={student?.image ? student?.image : `${IMG_URL}/${student?.imageUrl}`} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                                                    <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                                        <Button onClick={() => {
                                                            selectedImage.current = {
                                                                mainIndex: index,
                                                                subIndex: student?.auxIndex
                                                            }
                                                            removeStudentCard(index, student?.auxIndex)
                                                        }} type='transparent' style={{ padding: 0 }} icon={<CloseCircleFilled style={{ color: 'red', fontSize: 18 }} />}></Button>
                                                    </div>
                                                    <div className='r-c-c' style={{ position: 'absolute', top: 105, right: 8, border: '1px solid #EDEDED', backgroundColor: "white", borderRadius: '50%', width: 30, height: 30 }}>
                                                        <Button onClick={() => {
                                                            selectedImage.current = {
                                                                mainIndex: index,
                                                                subIndex: studentIndex
                                                            }
                                                            setImageChangeModalVisible(true);
                                                        }} className='r-c-c' type='transparent' style={{ padding: 0 }} icon={<CameraFilled style={{ color: '#1089FF', fontSize: 18, marginLeft: 6 }} />}></Button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                                        <Button onClick={() => {
                                                            selectedImage.current = {
                                                                mainIndex: index,
                                                                subIndex: student.auxIndex
                                                            }
                                                            removeStudentCard(index, student?.auxIndex)
                                                        }} type='transparent' style={{ padding: 0 }} icon={<CloseCircleFilled style={{ color: 'red', fontSize: 18 }} />}></Button>
                                                    </div>
                                                    <Upload
                                                        showUploadList={false}
                                                        customRequest={() => { }}
                                                        onChange={(info) => handleStudentImageChange(info, index, studentIndex)}
                                                        beforeUpload={() => false}
                                                        className='r-c-c-c cursor-pointer'
                                                        accept={".jpeg,.png,.jpg"}
                                                    >
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 50 }}>
                                                            <Button type='transparent' style={{ padding: 0 }} icon={<PlusCircleOutlined style={{ color: '#1089FF' }} />}></Button>
                                                            <div style={{ color: '#1089FF', padding: 0, fontWeight: 600 }}>ADD IMAGE</div>
                                                        </div>
                                                    </Upload>
                                                </>
                                            }
                                        </div>
                                        <Input
                                            placeholder='Enter name'
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const alphabetsOnly = /^[a-zA-Z\s]*$/; // Regular expression to allow only alphabets and spaces

                                                if (alphabetsOnly.test(inputValue) || inputValue === "") {
                                                    const updatedOutstandingResultsArr = [...outstandingResultsArr];
                                                    updatedOutstandingResultsArr[index].students[studentIndex].name = inputValue;
                                                    setOutstandingResultsArr(updatedOutstandingResultsArr);
                                                }
                                            }}
                                            style={{ backgroundColor: 'white', color: 'black', width: '100%', fontSize: 12, textAlign: 'center' }}
                                            value={student.name}
                                            className='simgrey bold-500 r-c-c'
                                        />
                                        <Input
                                            placeholder='marks/rank/percentile'
                                            value={student?.rank} onChange={(e) => {
                                                const updatedStudents = [...outstandingResultsArr];
                                                updatedStudents[index].students[studentIndex].rank = e.target.value;
                                                setOutstandingResultsArr(updatedStudents);
                                            }} className='r-c-c bold-600' style={{ height: 29, width: "100%", backgroundColor: 'white', borderBottomRightRadius: 8, borderBottomLeftRadius: 8, color: 'black', textAlign: 'center' }} />
                                        <div className='r-c-c-c m-t-5'>
                                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>Marks:
                                                <Input className='color-black bold-600 r-c-c m-l-5 m-r-5'
                                                    onChange={(e) => {
                                                        const updateStudentMarks = [...outstandingResultsArr];
                                                        updateStudentMarks[index].students[studentIndex].scoreObtain = e.target.value;
                                                        setOutstandingResultsArr(updateStudentMarks);
                                                    }}
                                                    value={`${student?.scoreObtain}`} style={{ backgroundColor: 'white', color: 'black', width: 50, fontSize: 12 }} />
                                                /
                                                <Input className='color-black bold-600 r-c-c m-l-5'
                                                    onChange={(e) => {
                                                        const updateStudentMarks = [...outstandingResultsArr];
                                                        updateStudentMarks[index].students[studentIndex].totalMarks = e.target.value;
                                                        setOutstandingResultsArr(updateStudentMarks);
                                                    }}
                                                    value={`${student?.totalMarks}`} style={{ backgroundColor: 'white', color: 'black', width: 50, fontSize: 12 }} />
                                            </div> */}
                                            {console.log({ student })}
                                            <Input placeholder='Enter additional information, if any' value={student?.additionalInfo} onChange={(e) => {
                                                const updateStudentMarks = [...outstandingResultsArr];
                                                updateStudentMarks[index].students[studentIndex].additionalInfo = e.target.value;
                                                setOutstandingResultsArr(updateStudentMarks);
                                            }} style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8, textAlign: 'center' }} />
                                        </div>
                                    </div>
                                ))}
                                {examCard?.students?.every(
                                    (student) => (student?.image && student?.rank && student?.name) || (student?.key && student?.rank && student?.name)
                                ) && (
                                        <div className='r-c-c-c' onClick={() => addNewStudent(index)}>
                                            <Button type='transparent' style={{ padding: 0 }} icon={<PlusCircleOutlined style={{ color: '#1089FF', fontSize: 16 }} />}></Button>
                                            <Button type='transparent' style={{ padding: 0, fontSize: 16, color: '#1089FF', fontWeight: 600 }}>ADD STUDENT</Button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
            <center>
                <Button onClick={(e) => addNewExamCard(e)} type='link' style={{ padding: 0, fontSize: 15 }}>{outstandingResultsArr?.length >= 1 ? 'ADD ANOTHER EXAM' : 'ADD EXAM'}</Button>
                <Button disabled={!outstandingResultsArr || outstandingResultsArr?.length === 0 || !validateStudents()} loading={outstandingSaveLoader} type='link' onClick={handleOutstandingSave} style={{ padding: 0, fontSize: 14 }}>SAVE</Button>
            </center>
            <input
                type="file"
                id="file"
                ref={inputProfilePic}
                onChange={(e) => {
                    removeStudentImage(selectedImage?.current?.mainIndex, selectedImage?.current?.subIndex);
                    handleStudentImageChange({ file: e.target.files[0] }, selectedImage?.current?.mainIndex, selectedImage?.current?.subIndex)
                }}
                style={{ display: "none" }}
                accept="image/*"
                value=""
            />
            <ImageChangeModal isModalVisible={imageChangeModalVisible} onCancel={() => { setImageChangeModalVisible(false) }} onRemove={() => {
                removeStudentImage(selectedImage.current?.mainIndex, selectedImage?.current?.subIndex);
                setImageChangeModalVisible(false)
            }} onChange={() => {
                setImageChangeModalVisible(false)
                inputProfilePic?.current?.click();
            }} />
        </div>
    );
};

const MarketPlaceTab = ({ updateMarketSettings, getDropDownCourseList, getToggleButtonCheck, getMarketPlaceSettings, marketPlaceData }) => {

    const history = useHistory();
    const location = useLocation();
    const tabNames = ['Banners', 'Enquiry form', 'Outstanding Results', 'Testimonials', 'Footer'];
    const tabStyle = { padding: '10px', textAlign: 'center', cursor: 'pointer', borderRadius: 4, color: '#fff', width: 150 };

    const [selectedTab, setTab] = useState("Banners");
    const [checkedItems, setCheckedItems] = useState(['Live courses', 'Video courses', 'Online test series', 'Study materials']);
    const [mPlaceSwitch, toggleMPlaceSwitch] = useState(marketPlaceData?.draft);
    const [enquiryFormSwitch, toggleEnquiryFormSwitch] = useState(marketPlaceData?.guestEnquiryForm);
    const [outstandingSwitch, toggleOutStandingSwitch] = useState(marketPlaceData?.outStandingResult);
    const [emailSwitch, toggleEmailSwitch] = useState(marketPlaceData?.emailMandatoryEF);
    const [testimonialsSwitch, toggleTestimonialsSwitch] = useState(marketPlaceData?.testimonials);
    const [multipleLocationSwitch, toggleMultipleLocationSwitch] = useState(marketPlaceData?.locations);
    const [visitorsLoader, toggleVisitorsLoader] = useState(false);
    const [saveChangesEnabled, setSaveChangesEnabled] = useState(false);
    const [initalCheckBoxStatus, toggleInitialCheckBoxStatus] = useState([marketPlaceData?.liveCourse, marketPlaceData?.videoResource, marketPlaceData?.onlineTestSeries, marketPlaceData?.studyMaterial])

    const [customBtn, setCustomBtn] = useState(false);
    const [enquiryQuestions, setEnquiryQuestions] = useState([]);
    const [activeCard, setActiveCard] = useState(0);
    const [defaultForm, setDefaultForm] = useState(false);
    const [previewForm, setPreviewForm] = useState(false);
    const [addOthersVisible, setAddOthersVisible] = useState(true);
    const [mobileOtpEnable, setMobileOtpEnable] = useState(false);
    const [switchAddStudent, setSwitchAddStudent] = useState(false);
    const [dropdownCourseList, setDropDownCourseList] = useState([]);
    const [isEnquiryPreviewModal, setIsEnquiryPreviewModal] = useState(false);
    const [zeroIndex, setZeroIndex] = useState(null);
    const dropdownQuestion = marketPlaceData?.enquiryForm?.enquiryQuestions?.find(question => question?.type === "dropdown");
    const courseListOption = dropdownQuestion?.questionContent?.options?.[0]?.courseList;
    const [isPublished, setIsPublished] = useState(courseListOption);

    const textColors = {
        short: "#601212",
        paragraph: "#DAAB00",
        dropdown: "#9876E6",
        phone: "#14B789",
        MCQ: "#E50087",
        checkbox: "#594099",
        email: "#0AB1E6",
        activeCardColor: "#F6F4FE"
    }
    const backGroundColors = {
        short: "#EFE6E6",
        paragraph: "#FDF3CE",
        dropdown: "#DDD9E5",
        phone: "#D5F8EF",
        MCQ: "#FFE5F4",
        checkbox: "#DFDEF5",
        email: "#D8F6FF",
    }
    const questionTypes = {
        short: "Short answer",
        paragraph: "Paragraph",
        dropdown: "Dropdown",
        phone: "Mobile number",
        MCQ: "Multiple choice",
        checkbox: "Checkboxes",
        email: "Email",
    }
    const iconTypes = {
        short: shortText,
        paragraph: paraTextColored,
        dropdown: dropDownIcColored,
        phone: phoneColored,
        MCQ: multipleIcColored,
        checkbox: checkboxColored,
        email: emailIcColored,
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabParam = queryParams.get('tab');
        if (tabParam) {
            setTab(tabParam);
        }
    }, [location]);

    useEffect(() => {
        setDefaultForm(marketPlaceData?.enquiryForm?.defaultForm)
        setMobileOtpEnable(marketPlaceData?.enquiryForm?.verifyPhone)
        setSwitchAddStudent(marketPlaceData?.enquiryForm?.registerUser)
        if (!marketPlaceData?.enquiryForm?.defaultForm) {
            setCustomBtn(true)
        }
    }, [])



    useEffect(() => {
        getDropDownCourseList(isPublished, (res) => {
            setDropDownCourseList(res);
        });
    }, [])


    useEffect(() => {
        if (marketPlaceData?.enquiryForm?.enquiryQuestions?.length > 0) {
            setEnquiryQuestions(marketPlaceData?.enquiryForm?.enquiryQuestions)
        }
    }, [selectedTab])


    useEffect(() => {
        setEnquiryQuestions(marketPlaceData?.enquiryForm?.enquiryQuestions)
        setMobileOtpEnable(marketPlaceData?.enquiryForm?.verifyPhone)
        setSwitchAddStudent(marketPlaceData?.enquiryForm?.registerUser)
    }, [customBtn])


    useEffect(() => {
        if (marketPlaceData?.enquiryForm?.enquiryQuestions?.length > 0) {
            setEnquiryQuestions(marketPlaceData?.enquiryForm?.enquiryQuestions)
        }
    }, [marketPlaceData])

    const handleAddQuestion = () => {
        const newQuestions = [...enquiryQuestions];
        const maxOptionsAllowed = 4;

        let defaultQuestionType = "short";
        if (enquiryQuestions?.some(question => question.type === "short")) {
            defaultQuestionType = "paragraph";
        }

        const initialOptions = {
            id: 0,
            value: null,
            courseList: false,
            courseOptions: dropdownCourseList?.map(option => ({
                ...option,
                isChecked: true
            })), selectedItems: []
        };

        const newQuestion = {
            id: null,
            type: defaultQuestionType,
            questionContent: {
                statement: null,
                options: [initialOptions]
            },
            required: false,
            verify: false
        };

        if (newQuestion.type === "MCQ" && newQuestion.questionContent.options.length >= maxOptionsAllowed) {
            alert("Cannot add more than 4 options for multiple choice questions.");
            return;
        }

        newQuestions.push(newQuestion);
        setEnquiryQuestions(newQuestions);
        setActiveCard(newQuestions.length - 1);
    };

    const handleOnChangeCheckBox = (questionIndex, optionIndex) => {
        console.log("Question Index", questionIndex, "Option Index", optionIndex);
        let updatedQuestions = [...enquiryQuestions];

        let courseOptions = updatedQuestions[questionIndex].questionContent.options[0].courseOptions;

        const isLastChecked = courseOptions.filter(option => option.isChecked).length === 1;
        const isCurrentOptionChecked = courseOptions[optionIndex].isChecked;

        if (!(isLastChecked && isCurrentOptionChecked)) {
            courseOptions[optionIndex].isChecked = !isCurrentOptionChecked;

            let selectedItems = courseOptions.filter(option => option.isChecked);
            updatedQuestions[questionIndex].questionContent.options[0].selectedItems = selectedItems;

            setEnquiryQuestions(updatedQuestions);
        } else {
            console.warn("At least one option must be selected.");
        }
    };


    useEffect(() => {
        toggleEnquiryFormSwitch(marketPlaceData?.guestEnquiryForm ? marketPlaceData?.guestEnquiryForm : false)
        toggleOutStandingSwitch(marketPlaceData?.outStandingResult ? marketPlaceData?.outStandingResult : false)
        toggleEmailSwitch(marketPlaceData?.emailMandatoryEF ? marketPlaceData?.emailMandatoryEF : false)
    }, [marketPlaceData?.guestEnquiryForm, marketPlaceData?.outStandingResult, marketPlaceData?.emailMandatoryEF])

    useEffect(() => {
        getMarketPlaceSettings();
        setSaveChangesEnabled(false);
    }, []);



    useEffect(() => {
        let arr = ['Live courses']
        if (marketPlaceData.onlineTestSeries) {
            arr?.push('Online test series')
        }
        if (marketPlaceData.videoResource) {
            arr?.push('Video courses')
        }
        if (marketPlaceData.studyMaterial) {
            arr?.push('Study materials')
        }
        setCheckedItems(arr)
        toggleInitialCheckBoxStatus([...arr]);
    }, [marketPlaceData?.onlineTestSeries, marketPlaceData?.liveCourse, marketPlaceData?.videoResource, marketPlaceData?.studyMaterial])

    const handleCheckboxChange = (item) => {
        let array = [];

        if (mPlaceSwitch && item === 'Live courses') {
            setCheckedItems([...checkedItems, item]);
            return;
        }

        if (checkedItems?.includes(item)) {
            array = checkedItems?.filter((checkedItem) => checkedItem !== item)
        } else {
            array = [...checkedItems, item]
        }
        setCheckedItems(array);

        let isEqual = true;
        if (array?.length == initalCheckBoxStatus?.length) {
            for (let i = 0; i < array?.length; i++) {
                if (!initalCheckBoxStatus?.includes(array[i])) {
                    isEqual = false;
                    break;
                }
            }
        } else {
            isEqual = false;
        }
        setSaveChangesEnabled(!isEqual);
    };

    const handleTabClick = (tabName) => {
        setTab(tabName);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('tab', tabName);
        history.push({ search: queryParams.toString() });
    };

    const handleSettingsSwitch = () => {
        const params = {
            draft: !mPlaceSwitch,
        };

        updateMarketSettings({ settingData: params }, () => { }, () => { getMarketPlaceSettings() });
    };
    const handleSectionSaveChanges = () => {
        const params = {
            draft: mPlaceSwitch ? mPlaceSwitch : false,
            liveCourseSelected: checkedItems?.includes('Live courses') ? true : false,
            videoResourceSelected: checkedItems?.includes('Video courses') ? true : false,
            onlineTestSelected: checkedItems?.includes('Online test series') ? true : false,
            studyMaterialSelected: checkedItems?.includes('Study materials') ? true : false,
        };

        updateMarketSettings({ settingData: params }, () => { }, () => { getMarketPlaceSettings() });
    }


    const handleEnquirySwitch = (e, type) => {

        let params = {}
        if (type == 'enquiry') {
            if (e) {
                params = {
                    settingData: { enquiryFormActive: e, emailMandatoryEF: emailSwitch, }, enquiryData: {
                        verifyPhone: switchAddStudent ? true : false,
                        defaultForm: true,
                        registerUser: switchAddStudent ? true : false,
                        enquiryQuestions: [],
                        enabled: true,
                    }
                }
            } else {
                params = {
                    settingData: {
                        enquiryFormActive: e,
                    },
                    enquiryData: {
                        enabled: e,
                        defaultForm: false,
                        verifyPhone: false,
                        enquiryQuestions: [],
                        registerUser: false,
                    }
                }
            }
        } else if (type == 'outstanding') {
            params = { settingData: { outStandingStudentActive: e } }
        } else {
            params = { settingData: { testimonialActive: e } }
        }
        updateMarketSettings(params, () => { toggleVisitorsLoader(false) }, () => { getMarketPlaceSettings() });
    }
    const fromCards = {
        width: '48%',
        background: " #FFFFFF",
        boxShadow: "4px 4px 16px #00000014",
        borderRadius: "8px",
        opacity: 1,
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    }
    const formQuestion = {
        width: '100%',
        background: "#FFFFFF",
        boxShadow: "4px 4px 16px #00000014",
        borderRadius: "8px",
        opacity: 1,
        height: "fit-content",
        marginTop: "15px",
        padding: "20px"
    }
    const [allQuestionsFilled, setAllQuestionsFilled] = useState(true);
    const [checkSave, setCheckSave] = useState(false);

    useEffect(() => {
        let areAllQuestionsFilled = true;
        enquiryQuestions?.forEach(question => {
            if (
                !question.questionContent ||
                question.questionContent.statement === null ||
                question.questionContent.statement === "" ||
                !question.type
            ) {
                areAllQuestionsFilled = false;
                return;
            }
        });
        setAllQuestionsFilled(areAllQuestionsFilled);
    }, [enquiryQuestions, checkSave]);

    // Function to add a new option to a specific question
    const handleCustomForms = (e) => {
        if (!enquiryQuestions) {
            Notification.error("No questions to process.");
            return;
        }

        let allowUpdate = true;
        let allQuestionsFilled = true;

        enquiryQuestions.forEach(question => {
            if (!question.questionContent || question.questionContent.statement == null) {
                allQuestionsFilled = false;
            }
            if (question.type === "MCQ" && question.questionContent.options.length > 4) {
                allowUpdate = false;
            }
        });

        if (!allQuestionsFilled) {
            Notification.error("Please fill all the questions");
            return;
        }

        if (!allowUpdate) {
            Notification.error("Multiple choice questions should not exceed more than 4 options");
            return;
        }

        let cleanedEnquiryQuestions = enquiryQuestions.map(question => {
            let cleanedOptions = question.questionContent?.options?.filter(option => option.value != null && option.value !== "") || [];
            cleanedOptions = cleanedOptions.map(option => {
                if (option.selectedItems && option.selectedItems.length === 0) {
                    const { selectedItems, ...cleanedOption } = option;
                    return cleanedOption;
                }
                return option;
            });

            return {
                ...question,
                questionContent: {
                    ...question.questionContent,
                    options: cleanedOptions
                }
            };
        });

        if (e && allowUpdate) {
            const params = {
                settingData: { enquiryFormActive: e },
                enquiryData: {
                    verifyPhone: mobileOtpEnable,
                    defaultForm: false,
                    registerUser: switchAddStudent,
                    enquiryQuestions: cleanedEnquiryQuestions,
                    enabled: e,
                }
            };
            toggleVisitorsLoader(true);
            updateMarketSettings(params, (res) => {
                toggleVisitorsLoader(false);
            },
                () => {
                    getMarketPlaceSettings();
                }
            );
        }
    };

    const determineInputType = (inputText) => {
        const lowerCaseText = inputText.toLowerCase();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmail = emailRegex.test(inputText);
        if (isEmail) return "email";
        if (lowerCaseText.includes("email")) return "email";
        if (lowerCaseText.includes("phone") || lowerCaseText.includes("number") || lowerCaseText.includes("contact") || lowerCaseText.includes("contact number") || lowerCaseText.includes("mobile") || lowerCaseText.includes("mobile number")) return "phone";
        if (lowerCaseText.match(/\b(multiple choice|multiple|choices|mcq)\b/)) return "MCQ";
        if (lowerCaseText.includes("list") || lowerCaseText.includes("courses") || lowerCaseText.includes("lists")) return "checkbox"; // Assuming lists/answers imply multiple selectable answers
        if (lowerCaseText.match(/\b(dropdown|select)\b/)) return "dropdown";
        if (lowerCaseText.match(/\b(comments|comment|answers|brief|description|describe)\b/)) return "paragraph";
        const isParagraph = inputText.length > 100;
        if (isParagraph) return "paragraph";
        return "short";
    };


    const handleInputQuestionChange = (e, index, newId) => {
        setCheckSave(!checkSave);
        const newQuestions = [...enquiryQuestions];
        const inputType = determineInputType(e.target.value);
        newQuestions[index].questionContent.statement = e.target.value;
        newQuestions[index].type = inputType;
        newQuestions[index].id = newId;
        setEnquiryQuestions(newQuestions);
    };

    const handleSelectChange = (value, index) => {
        const newQuestions = [...enquiryQuestions];
        newQuestions[index].type = value;

        newQuestions[index].type = value;

        if (value === "checkbox" || value === "MCQ") {
            newQuestions[index].questionContent.options = newQuestions[index].questionContent.options.slice(0, 4);
            newQuestions[index].questionContent.options.forEach(option => {
                option.courseList = false;
            });
        }
        setEnquiryQuestions(newQuestions);
    };


    const handleDeleteQuestion = (index) => {
        const updatedQuestions = [...enquiryQuestions];
        updatedQuestions.splice(index, 1);
        setEnquiryQuestions(updatedQuestions);
    };
    const handleQuestionMandatory = (index) => {
        const newQuestions = [...enquiryQuestions];
        newQuestions[index].required = !newQuestions[index].required;
        setEnquiryQuestions(newQuestions);
    };
    const handleEmailMandatory = (index) => {
        const newQuestions = [...enquiryQuestions];
        newQuestions[index].verify = !newQuestions[index].verify;
        if (newQuestions[index].verify) {
            newQuestions[index].required = true
        }
        setEnquiryQuestions(newQuestions);
    };

    const addOption = (questionIndex) => {
        const updatedQuestions = [...enquiryQuestions];
        const options = updatedQuestions[questionIndex].questionContent?.options || [];
        const maxId = Math.max(...options.map(option => option.id), 0);
        updatedQuestions[questionIndex].questionContent.options.push({
            id: maxId + 1,
            value: ''
        });

        setEnquiryQuestions(updatedQuestions);
    };

    const removeOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...enquiryQuestions];
        if (updatedQuestions[questionIndex].questionContent?.options[optionIndex]?.value === 'Others') {
            setAddOthersVisible(true);
        }
        updatedQuestions[questionIndex].questionContent.options.splice(optionIndex, 1);
        updatedQuestions[questionIndex].questionContent.options.forEach((option, index) => {
            option.id = index;
        });

        setEnquiryQuestions(updatedQuestions);
    };

    const handleOptionChange = (questionIndex, optionIndex, e) => {
        const updatedQuestions = [...enquiryQuestions];
        updatedQuestions[questionIndex].questionContent.options[optionIndex].id = optionIndex;
        updatedQuestions[questionIndex].questionContent.options[optionIndex].value = e.target.value;
        setEnquiryQuestions(updatedQuestions);
    };

    const addOthersOption = (questionIndex) => {
        const updatedQuestions = [...enquiryQuestions];
        const options = updatedQuestions?.[questionIndex]?.questionContent?.options || [];
        const maxId = Math.max(...options.map(option => option.id), 0);
        updatedQuestions?.[questionIndex]?.questionContent?.options.push({
            id: maxId + 1,
            value: 'Others',
            others: true
        });

        setAddOthersVisible(false);
        setEnquiryQuestions(updatedQuestions);
        console.log("New options length:", updatedQuestions?.[questionIndex].questionContent?.options.length);
    };



    const handleAddCourseList = (questionIndex, optionIndex, type) => {

        const updatedQuestions = [...enquiryQuestions];
        const currentOption = updatedQuestions[questionIndex].questionContent.options[optionIndex];
        const currentCourseList = currentOption?.courseList;
        setIsPublished(true);
        if (type != "delete") {
            setZeroIndex(updatedQuestions[questionIndex].questionContent.options?.[0]?.value)
        }
        if (type == "delete") {

            setIsPublished(false);
            updatedQuestions[questionIndex].questionContent.options[optionIndex].courseList = !currentCourseList;
            updatedQuestions[questionIndex].questionContent.options[optionIndex].value = zeroIndex;
            updatedQuestions[questionIndex].questionContent.options[optionIndex].id = 0;
        } else {
            if (typeof currentCourseList !== undefined) {
                updatedQuestions[questionIndex].questionContent.options[optionIndex].courseList = !currentCourseList;
                updatedQuestions[questionIndex].questionContent.options[optionIndex].value = "courseList";
                updatedQuestions[questionIndex].questionContent.options[optionIndex].id = 0;
            }
        }
        setEnquiryQuestions(updatedQuestions);
    }




    const CustomSelect = styled(Select)`
    &.enquiryFormSelect .ant-select-selector {
      border: none !important;
      border-bottom: 1px solid #636363 !important;
      background-color: #F6F4FE  !important;
    }
  `;

    const existingTypes = enquiryQuestions?.map(question => question.type);
    const availableTypes = ["short", "paragraph", "email", "MCQ", "checkbox", "dropdown"];
    const remainingTypes = availableTypes?.filter(type => !existingTypes?.includes(type));
    return (
        <div className='m-t-50 scroll-bar-universal' style={{}}>
            <div className='r-jsb' style={{ width: '98%' }}>
                <Col>
                    <Heading>Marketplace</Heading>
                    <div style={{ width: '100%' }}>Enhance your online platform with a wide variety of courses, knowledgeable instructors, and limitless opportunities for personal and professional growth.</div>
                </Col>
                {/* <div className='m-t-30'>
                    <Switch checked={mPlaceSwitch} onChange={(checked) => {
                        toggleMPlaceSwitch(checked);
                    }}
                        disabled={marketPlaceData?.active}
                    />
                </div> */}
            </div>
            <div className='m-t-30 full-width r-jsb'>
                {marketPlaceChecks?.map((item, index) => (
                    <div key={index} style={{ marginBottom: '10px', display: 'flex' }}>
                        <Checkbox disabled={item === 'Live courses'} checked={checkedItems?.includes(item)} onChange={() => handleCheckboxChange(item)}>
                            {item}
                        </Checkbox>
                    </div>
                ))}
                <div>
                    <Button onClick={() => {
                        handleSectionSaveChanges();
                    }} type='link' disabled={!saveChangesEnabled} style={{ color: '#1089FF', fontSize: 16, marginTop: -5 }}>SAVE CHANGES</Button>
                </div>
            </div>


            <div>
                <div className='scroll-bar-universal' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30, whiteSpace: 'nowrap' }}>
                    {tabNames?.map((tabName) => (
                            <div
                                key={tabName}
                                onClick={() => tabName !== 'Enquiry form' && handleTabClick(tabName)}
                                title={tabName === 'Enquiry form' ? 'Temporarily Disabled' : ''}
                                style={{
                                    ...tabStyle,
                                    backgroundColor: selectedTab === tabName ? '#594099' : '',
                                    border: selectedTab !== tabName ? '1px solid #191919' : '',
                                    color: tabName === 'Enquiry form' ? '#B0B0B0' : selectedTab !== tabName ? '#191919' : '#FFF',
                                    fontWeight: selectedTab === tabName ? 600 : '',
                                    cursor: tabName === 'Enquiry form' ? 'not-allowed' : 'pointer',
                                    opacity: tabName === 'Enquiry form' ? 0.6 : 1,
                                }}
                            >
                                {tabName}
                            </div>
                        ))}
                    </div>
                </div>
                {selectedTab === 'Banners' ? (
                    <div className='m-t-30'>
                        <Banners updateMarketSettings={updateMarketSettings} getMarketPlaceSettings={getMarketPlaceSettings} marketPlaceData={marketPlaceData} getToggleButtonCheck={getToggleButtonCheck} />
                    </div>
                ) : selectedTab === 'Enquiry form' ? (
                    <div className='r-jsb' style={{ marginTop: 30, width: '100%', border: '1px solid #AEAEAE', padding: 20, borderRadius: 8, }}>
                        <div style={{ width: "95%" }}>
                            <div className='r-c-sb'>
                                <div style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Roboto" }}>Admission enquiry form</div>
                                <div>{enquiryFormSwitch ? customBtn ?
                                    <div className='r-c-se'>
                                        <Button style={{ fontSize: "14px", padding: "15px", color: "#594099", borderColor: "#594099" }} type={previewForm ? "primary" : "secondary"} onClick={() => { setIsEnquiryPreviewModal(true) }}>PREVIEW FORM</Button> &nbsp;&nbsp;
                                        <Button style={{ fontSize: "14px", padding: "15px" }} type="primary" onClick={() => { setDefaultForm(true), setCustomBtn(false) }}>USE DEFAULT FORM</Button>
                                    </div>
                                    :
                                    <div className='r-c-se'>
                                        {/* <Button style={{ fontSize: "14px", padding: "15px", marginRight: "15px" }} type={previewForm ? "primary" : "secondary"} onClick={() => { setIsEnquiryPreviewModal(true) }}>PREVIEW FORM</Button>
                                    <Button type="primary" style={{ fontSize: "14px", padding: "15px" }} onClick={() => { setCustomBtn(true), setDefaultForm(false) }} ><span> <SettingOutlined style={{ marginRight: "5px", }} /></span>CUSTOMIZE FORM</Button> */}
                                    </div>
                                    : <></>}</div>
                            </div>
                            <div className='r-c-sb m-t-15'>
                                <div style={{ width: '80%', fontSize: "16px" }}>Implementing admission enquiry forms is crucial for gathering insights and fostering better communication with potential visitors, ultimately elevating the overall guest</div>
                                <div className='m-t-10'>
                                    <Switch checked={enquiryFormSwitch}
                                        onChange={(e) => {
                                            if (enquiryFormSwitch) {
                                                toggleEnquiryFormSwitch(e);
                                                setCustomBtn(false)
                                            } else {
                                                toggleEnquiryFormSwitch(e);
                                                setSwitchAddStudent(false);
                                                setCustomBtn(false)
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                            <div className='r-c-sb m-t-15'>
                                {enquiryFormSwitch ?
                                    <>
                                        <div style={{ width: '80%', fontSize: "16px" }}>Auto register student upon submission of their enquiry form.</div>
                                        <div className='m-t-10'>
                                            <Switch checked={switchAddStudent} onChange={() => { setSwitchAddStudent(!switchAddStudent); setMobileOtpEnable(true) }}
                                            />
                                        </div>
                                    </> : null}

                            </div>
                            {console.log("casesss", enquiryFormSwitch, customBtn, defaultForm)}

                            {enquiryFormSwitch ?
                                !customBtn ? <><div className='m-t-10 r-c-fs '>
                                    <label style={{ color: "#1089FF", cursor: "pointer" }}>
                                        <Checkbox checked={emailSwitch} style={{ color: "#1089FF" }} onChange={() => { toggleEmailSwitch(!emailSwitch) }} />&nbsp;
                                        Make Email ID mandatory
                                    </label>
                                </div>
                                    {console.log("casesss1", enquiryFormSwitch, customBtn, defaultForm)}
                                    {enquiryFormSwitch ?
                                        <>
                                            <div className='border-dashed r-c-c' style={{ marginTop: 20, height: switchAddStudent ? "85dvh" : "73dvh" }}>
                                                <div className='preview-text'>Preview</div>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div style={{ borderRadius: 16, backgroundColor: "#FFFFFF", padding: 20, width: 700, margin: "20px 0px", backgroundColor: '#FAFAFA', borderRadius: 8, boxShadow: '5px 5px 13px -7px rgba(0,0,0,0.43)' }}>

                                                        <div className='r-c-c' style={{ fontSize: 18, color: "#191919", fontWeight: "bold", marginBottom: 20 }}>ADMISSIONS ENQUIRY FORM </div>
                                                        <div className='r-c-fe' style={{ color: "#594099", fontSize: "12px", fontWeight: "600", marginTop: "-42px" }}>
                                                            <div style={{ cursor: "pointer" }} onClick={() => { setCustomBtn(true) }}>
                                                                <EditOutlined style={{ color: "#594099", marginRight: "5px" }} /> CUSTOMIZE FORM
                                                            </div>
                                                        </div>
                                                        <div className='name-input'>
                                                            <AnimatedTextField
                                                                label="Name"
                                                                isMandatory={true}
                                                                inputId="name"
                                                                isDisabled={true}
                                                                isActive={true}
                                                            />
                                                        </div>
                                                        <div className='m-t-20'>
                                                            <div style={{ color: "#AEAEAE", fontWeight: 500 }}>Mobile Number <span style={{ color: 'red' }}>*</span></div>
                                                            <PhoneInput
                                                                className='custom-phone-input-enquiry m-t-5 p-10'
                                                                type="tel"
                                                                disabled={true}
                                                                defaultCountry="IN"
                                                                addInternationalOption={true}
                                                                // countrySelectComponent={CustomCountrySelect}
                                                                style={{ backgroundColor: "#FAFAFA !important", width: "100% " }}
                                                            />
                                                        </div>
                                                        {switchAddStudent ? <>
                                                            <div className='r-jsb' style={{ width: "98%" }}>
                                                                <div className='otp-input m-t-20' >
                                                                    <div style={{ color: "#AEAEAE", }}>Verify Mobile Number <span style={{ color: 'red' }}>*</span></div>
                                                                    <div style={{ position: "relative", display: "flex", justifyContent: "flex-start" }}>
                                                                        <div className="r-c-c" style={{ fontWeight: 700 }}>
                                                                            <div className="otpInputNew-1">{/* ? "*" : null */}</div>
                                                                            <div className="otpInputNew-1">{ /* ? "*" : null */}</div>
                                                                            <div className="otpInputNew-1">{ /* ? "*" : null */}</div>
                                                                            <div className="otpInputNew-1">{ /* ? "*" : null */}</div>
                                                                            <div className="otpInputNew-1">{ /* ? "*" : null */}</div>
                                                                            <div className="otpInputNew-1">{ /* ? "*" : null */}</div>
                                                                        </div>
                                                                        <input type="number" className='aulas-login__input-verify-otp'
                                                                            style={{ width: "100%", cursor: "not-allowed", opacity: 0.5 }}

                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Button
                                                                    disabled={true}
                                                                    type="link"
                                                                    shape="round"
                                                                    className="r-c-c bold-bold"
                                                                    style={{ color: "#1089FF", fontWeight: "bold", marginTop: 55, fontSize: 14, cursor: "not-allowed" }}
                                                                >
                                                                    {"VERIFY OTP"}
                                                                </Button>
                                                            </div>
                                                        </> : null}


                                                        <>
                                                            <div className='r-jsb m-t-10'>
                                                                <div className='email-input' style={{ width: "100%" }}>
                                                                    <AnimatedTextField
                                                                        label="Email ID"
                                                                        isMandatory={emailSwitch}
                                                                        inputId="email"
                                                                        isDisabled={true}
                                                                        isActive={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='name-input m-t-30'>
                                                                <AnimatedTextField
                                                                    label={"Message"}
                                                                    inputId="message"
                                                                    isDisabled={true}
                                                                    isActive={true}
                                                                />
                                                            </div>
                                                        </>
                                                        <div className='m-t-20'>
                                                            <Checkbox disabled={true} style={{ marginRight: 10, color: "#191919", fontWeight: 500 }} >I hereby authorise {marketPlaceData?.orgName} to contact me.</Checkbox>
                                                            <div className='r-c-c-c' style={{ height: 60, margin: "20px -19px -25px -19px", boxShadow: "0px -3px 10px #00000029", borderRadius: " 0px 0px 16px 16px" }}>
                                                                <center><Button type="primary" disabled={true}>SUBMIT </Button></center></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }

                                    <center><Button loading={visitorsLoader} onClick={(e) => { toggleVisitorsLoader(true); handleEnquirySwitch(enquiryFormSwitch, 'enquiry') }} disabled={customBtn == false ? false : enquiryFormSwitch === marketPlaceData?.guestEnquiryForm && emailSwitch === marketPlaceData?.emailMandatoryEF} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button></center>

                                </>
                                    :
                                    <>
                                    </>
                                :
                                <>
                                    <center><Button loading={visitorsLoader} onClick={(e) => { toggleVisitorsLoader(true); handleEnquirySwitch(enquiryFormSwitch, 'enquiry') }} disabled={!enquiryFormSwitch == false || enquiryFormSwitch === marketPlaceData?.guestEnquiryForm && emailSwitch === marketPlaceData?.emailMandatoryEF} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button></center>
                                </>
                            }
                            {enquiryFormSwitch && customBtn ?
                                <div className='scroll-bar-universal'>
                                    <div className='m-t-15 ' style={{ width: "100%" }}>
                                        <div className='r-c-sb'>
                                            <div className='' style={{ ...fromCards, cursor: "not-allowed" }}>
                                                <div className='' style={{ padding: "20px", fontSize: "14px", }}>Enter name *
                                                    <div style={{ border: "1px solid #8C8C8C", marginTop: 10 }}></div>
                                                </div>
                                            </div>
                                            <div className='' style={{ ...fromCards }}>
                                                <div className='r-c-sb' style={{ width: "100%" }}>
                                                    <div className='' style={{ padding: "20px", fontSize: "14px", cursor: "not-allowed" }}>Mobile number *
                                                        <div style={{ border: "1px solid #8C8C8C", marginTop: 10 }}></div>
                                                    </div>
                                                    <div className='r-c-fs' style={{ padding: "0px 20px", alignItems: "flex-end" }}>
                                                        OTP verification needed
                                                        <div className='m-t-10 m-l-10'>
                                                            <Switch disabled={switchAddStudent} checked={mobileOtpEnable} onChange={() => { setMobileOtpEnable(!mobileOtpEnable) }} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                    {customBtn ?
                                        <>
                                            {enquiryQuestions?.map((question, index) => (
                                                <div style={{ cursor: "default" }} onClick={() => setActiveCard(index)}>
                                                    <div style={{ ...formQuestion, backgroundColor: activeCard === index ? "#F6F4FE" : "#FFFFFF", }}>
                                                        <div className='' style={{ width: "100%" }}>
                                                            <div className='r-c-sb' style={{ width: "100%", alignItems: "flex-end" }}>
                                                                <div style={{ width: "70%", marginRight: "20px" }}>
                                                                    <AnimatedTextField
                                                                        inputId={question?.type == "email" ? "no" : index}
                                                                        style={{ width: "100%", border: "1px solid red" }}
                                                                        label={"Question"}
                                                                        value={question?.questionContent?.statement}
                                                                        handleInputChange={(e) => handleInputQuestionChange(e, index, question?.id)}
                                                                        isMandatory={question?.verify ? true : question?.required}
                                                                        // isDisabled={index !== activeCard ? true : false}
                                                                        isActive={index === activeCard ? true : false}
                                                                    />
                                                                </div>

                                                                {index === activeCard ?
                                                                    <div style={{}}>
                                                                        <CustomSelect
                                                                            className="enquiryFormSelect"
                                                                            defaultValue={question?.type}
                                                                            style={{ width: "200px", alignItems: "center" }}
                                                                            onChange={(value) => handleSelectChange(value, index)}
                                                                        >
                                                                            <Option value="short"><img loading='lazy' alt="Short answer" src={shortText} />&nbsp;&nbsp; Short answer</Option>
                                                                            <Option value="paragraph"><img loading='lazy' alt="Paragraph" src={paraText} />&nbsp;&nbsp; Paragraph</Option>
                                                                            <Option value="email"><img loading='lazy' alt="email" src={emailIc} />&nbsp;&nbsp;Email</Option>
                                                                            <Option value="phone"><img loading='lazy' alt="email" src={phoneIc} />&nbsp;&nbsp; Mobile number</Option>
                                                                            <Option value="MCQ"><img loading='lazy' alt="Multiple choice" src={multipleIc} />&nbsp;&nbsp;Multiple choice</Option>
                                                                            <Option value="checkbox"><img loading='lazy' alt="Checkboxes" src={checkboxIc} />&nbsp;&nbsp;Checkboxes</Option>
                                                                            <Option value="dropdown"><img loading='lazy' alt="Dropdown" src={dropDownIc} />&nbsp;&nbsp;Dropdown</Option>
                                                                            {/* {(question.type !== "dropdown") && !enquiryQuestions?.find(q => q.type === "dropdown") && (
                                                                            <Option value="dropdown"><img loading='lazy' alt="dropdown" src={dropDownIc} /> &nbsp;&nbsp;Drop Down  </Option>
                                                                        )} */}
                                                                        </CustomSelect>
                                                                    </div>
                                                                    :
                                                                    <div className='r-c-fe' style={{ width: "30%" }}>
                                                                        <div className='m-t-15' style={{ fontFamily: "Roboto", width: "70%" }}>
                                                                            {index !== activeCard ? <div className='r-c-c' style={{ color: textColors[question?.type], width: "100%", height: "32px", borderRadius: "8px", backgroundColor: backGroundColors[question?.type] }}> <img src={iconTypes[question?.type]} loading="lazy" />&nbsp;&nbsp;{questionTypes[question?.type]} </div> : null}
                                                                        </div>
                                                                        {/* <div>{index === activeCard ? null : <span style={{ color: "#594099" }}>{question?.verify ? "OTP verification enabled" : null}</span>}</div> */}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {console.log("debug1", question?.type)}
                                                        {question?.type == "MCQ" || question?.type == "checkbox" || question?.type == "dropdown" ?
                                                            <>
                                                                <div>
                                                                    {!question?.questionContent?.options?.[0]?.courseList && (
                                                                        <>
                                                                            {question?.questionContent?.options?.map((option, optionIndex) => (
                                                                                <div key={optionIndex} className='r-c-sb'>
                                                                                    <div className='r-c-fs' style={{ alignItems: question?.type == "dropdown" && index === activeCard ? "flex-end" : "baseline" }}>
                                                                                        {question?.type == "dropdown" ? <>{optionIndex + 1}. &nbsp;</> :
                                                                                            <div style={{ width: "15px", height: "15px", marginRight: "15px", borderRadius: question?.type == "checkbox" ? "30%" : "100%", border: "1px solid #AEAEAE" }}></div>
                                                                                        }
                                                                                        {option === 'Others' && addOthersVisible ? (
                                                                                            <div className='' style={{ padding: "10px 0px" }}>{option?.value}</div>
                                                                                        ) : (index === activeCard ?
                                                                                            <AnimatedTextField
                                                                                                inputId={option?.value}
                                                                                                label={`Option ${optionIndex + 1}`}
                                                                                                value={option?.value}
                                                                                                isDisabled={option?.value == "Others" ? true : false}
                                                                                                isActive={activeCard === index ? "#F6F4FE" : "#FFFFFF"}
                                                                                                handleInputChange={(value) => handleOptionChange(index, optionIndex, value)}
                                                                                            /> : <div className='' style={{ padding: "10px 0px" }}>{option?.value || `Option ${optionIndex + 1}`}</div>
                                                                                        )}
                                                                                    </div>
                                                                                    {question?.questionContent?.options?.length > 1 && index === activeCard && (
                                                                                        <div className='m-r-40 cursor-pointer' onClick={() => removeOption(index, optionIndex)}>
                                                                                            <CloseOutlined />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                    {console.log("debug2", enquiryQuestions?.[index]?.questionContent?.options?.some(option => option.others == true))}
                                                                    <div className='r-c-fs' style={{ padding: "10px 0px" }}>
                                                                        {question?.type == "dropdown" && index === activeCard && !question?.questionContent?.options?.[0]?.courseList ? <><span>{question?.questionContent?.options?.length + 1}</span>.</> :
                                                                            <>
                                                                                {question?.questionContent?.options?.[0]?.courseList ? null :
                                                                                    <>{
                                                                                        !(question.type === "MCQ" && question.questionContent?.options?.length >= 4) && index === activeCard ? (
                                                                                            <div style={{ width: "15px", height: "15px", marginRight: "15px", borderRadius: question?.type == "checkbox" ? "30%" : "100%", border: "1px solid #AEAEAE" }}></div>
                                                                                        ) : null
                                                                                    }
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {question?.questionContent?.options?.[0]?.courseList ? null :
                                                                            <>
                                                                                {
                                                                                    !(question.type === "MCQ" && question.questionContent?.options?.length >= 4) && index === activeCard ? (

                                                                                        <div onClick={index === activeCard ? () => addOption(index) : undefined}><span style={{ marginLeft: question?.type === "dropdown" ? "8px" : "", textDecoration: index === activeCard ? "underline" : "none", cursor: index === activeCard ? "pointer" : "default" }}>Add options</span></div>
                                                                                    ) : null
                                                                                }

                                                                                {

                                                                                    question?.type !== "dropdown" &&
                                                                                    index === activeCard &&
                                                                                    !enquiryQuestions?.[index]?.questionContent?.options?.some(option => option.others) && (
                                                                                        <div style={{ color: "#1089FF", marginLeft: "5px" }} onClick={() => addOthersOption(index)}>
                                                                                            {
                                                                                                !(question.type === "MCQ" && question.questionContent?.options?.length >= 4) && (
                                                                                                    <div style={{ cursor: "pointer" }}>
                                                                                                        <span style={{ color: "black" }}>or</span> add "others"
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {question?.type === "dropdown" && !question?.questionContent?.options?.[0]?.courseList && index === activeCard && !question?.questionContent?.options?.[0]?.value ? <Button type="secondary" onClick={() => { handleAddCourseList(index, 0) }} style={{ marginLeft: "20px", backgroundColor: "#F6F4FE", color: "#FFFFFF", backgroundColor: "#9876E6", border: "none" }}>Add course lists</Button> : null}
                                                                            </>
                                                                        }
                                                                        {question?.type == "dropdown" && question?.questionContent?.options?.[0]?.courseList ? <div className='' style={{ display: "flex", flexDirection: "column" }}>
                                                                            <div style={{ color: "#636363" }} > All course list <span style={{ fontWeight: "bold" }}>(Total courses {question?.questionContent?.options?.[0]?.selectedItems?.length > 0 ? question?.questionContent?.options?.[0].selectedItems?.length : ""})</span> {index === activeCard &&
                                                                                <CloseOutlined onClick={() => { handleAddCourseList(index, 0, "delete") }} />}</div>
                                                                            <div className='m-t-10 scroll-bar-universal' style={{ width: "100%", height: question.questionContent.options[0].courseOptions?.length > 15 ? "400px" : "fit-content" }}>
                                                                                {question?.questionContent?.options?.[0]?.courseOptions?.length > 0
                                                                                    ? <>
                                                                                        {question.questionContent.options[0].courseOptions?.map((item, subindex) => (
                                                                                            <div key={subindex} className='r-c-fs'>
                                                                                                <Checkbox
                                                                                                    checked={item?.isChecked}
                                                                                                    style={{ marginRight: 10 }}
                                                                                                    onChange={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        e.stopPropagation();
                                                                                                        handleOnChangeCheckBox(index, subindex);
                                                                                                    }}
                                                                                                />
                                                                                                <div>{item.name}</div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                    : <></>
                                                                                }
                                                                            </div>
                                                                        </div> : null}
                                                                    </div>
                                                                </div>

                                                            </>
                                                            :
                                                            <>
                                                                <div className='m-t-20'>{index === activeCard ? null : <span style={{ color: "#594099", fontWeight: "600" }}>{question?.verify ? "OTP verification enabled" : null}</span>}</div>
                                                            </>
                                                        }
                                                        {index === activeCard &&
                                                            <div className='r-c-fe m-t-30' style={{ borderTop: question?.type == "MCQ" || question?.type == "checkbox" || question?.type == "dropdown" ? "1px solid #AEAEAE" : "none" }}>
                                                                <div className='m-r-10 cursor-pointer' style={{ color: "#FF414D" }} onClick={() => handleDeleteQuestion(index)}><DeleteOutlined style={{ color: "#FF414D" }} /> Delete</div>
                                                                {question?.type == "email" || question?.type == "phone" ?
                                                                    <div className='m-r-10' style={{ color: "#191919" }}>
                                                                        <Checkbox checked={question?.verify} style={{}} onChange={() => { handleEmailMandatory(index) }} />&nbsp;
                                                                        OTP verification needed
                                                                    </div>
                                                                    : null}
                                                                <div style={{ color: "#191919" }}>
                                                                    <Checkbox disabled={question?.verify ? true : false} checked={question?.verify ? true : question?.required} style={{}} onChange={() => { handleQuestionMandatory(index) }} />&nbsp;
                                                                    Mandatory
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>))}
                                        </>
                                        : null}

                                    <div className='m-t-15'>
                                        <center><Button disabled={enquiryQuestions?.[activeCard]?.questionContent?.statement ? false : !allQuestionsFilled} onClick={handleAddQuestion} type='link' style={{ fontSize: 14, marginTop: 20 }}><PlusCircleOutlined style={{ color: "#1089FF", fontWeight: "bold" }} />&nbsp; ADD QUESTION</Button></center>
                                        <center><Button disabled={!allQuestionsFilled} loading={visitorsLoader} onClick={(e) => { handleCustomForms(enquiryFormSwitch) }} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button></center>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                ) : selectedTab === 'Outstanding Results' ? (
                    <div style={{ marginTop: 30, width: '100%', border: '1px solid #AEAEAE', padding: 20, borderRadius: 8, height: '100%' }}>
                        <div className="r-jsb">
                            <Col style={{ width: '95%' }}>
                                <Heading>Outstanding results</Heading>
                                <div style={{ width: '100%' }}>Highlighting student accomplishments fosters excellence on your platform, elevating credibility and engagement.</div>
                                {outstandingSwitch &&
                                    <OutstandingResults handleEnquirySwitch={handleEnquirySwitch} outstandingSwitch={outstandingSwitch} marketPlaceData={marketPlaceData} updateMarketSettings={updateMarketSettings} getMarketPlaceSettings={getMarketPlaceSettings} handleSettingsSwitch={handleSettingsSwitch} />
                                }
                            </Col>
                            <div className='m-t-30'>
                                <Switch checked={outstandingSwitch} onChange={(e) => { toggleOutStandingSwitch(e); }} />
                            </div>
                        </div>
                        {
                            outstandingSwitch ? null :
                                <center><Button loading={visitorsLoader} onClick={(e) => { toggleVisitorsLoader(true); handleEnquirySwitch(outstandingSwitch, 'outstanding') }} disabled={outstandingSwitch === marketPlaceData?.outStandingResult} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button></center>
                        }
                    </div>
                ) : selectedTab === 'Testimonials' ? (
                    <div style={{ marginTop: 30, width: '100%', border: '1px solid #AEAEAE', padding: 20, borderRadius: 8 }}>
                        <div className='r-jsb' >
                            <Col style={{ width: '100%' }}>
                                <Heading>What our learners say!</Heading>
                                <div style={{ width: '100%' }}>Share learner success stories for trust and connection, boosting your platform's reputation.</div>
                                {testimonialsSwitch &&
                                    <div style={{ width: '105%' }}>
                                        <Testimonials testimonialsSwitch={testimonialsSwitch} updateMarketSettings={updateMarketSettings} getMarketPlaceSettings={getMarketPlaceSettings} marketPlaceData={marketPlaceData} handleEnquirySwitch={handleEnquirySwitch} />
                                    </div>
                                }
                            </Col>
                            <div className='m-t-30'>
                                <Switch checked={testimonialsSwitch} onChange={(e) => toggleTestimonialsSwitch(e)} />
                            </div>
                        </div>
                        {
                            testimonialsSwitch ? null :
                                <center><Button loading={visitorsLoader} onClick={(e) => { toggleVisitorsLoader(true); handleEnquirySwitch(testimonialsSwitch, 'testimonial') }} disabled={testimonialsSwitch === marketPlaceData?.testimonials} type='link' style={{ fontSize: 14, marginTop: 20 }}>SAVE</Button></center>
                        }
                    </div>
                ) : selectedTab === 'Footer' ? (
                    <div className='m-t-30'>
                        <Footer toggleMultipleLocationSwitch={toggleMultipleLocationSwitch} multipleLocationSwitch={multipleLocationSwitch} updateMarketSettings={updateMarketSettings} getMarketPlaceSettings={getMarketPlaceSettings} marketPlaceData={marketPlaceData} />
                    </div>
                ) : null
                }
            </div>
            {EnquiryFormPreviewModal &&
                <EnquiryFormPreviewModal
                    isEnquiryPreviewModal={isEnquiryPreviewModal}
                    setIsEnquiryPreviewModal={setIsEnquiryPreviewModal}
                    previewData={enquiryQuestions}
                    orgName={marketPlaceData?.orgName}
                    defaultForm={customBtn}
                    emailSwitch={emailSwitch}
                    mobileOtpEnable={mobileOtpEnable}
                />}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { marketPlaceData } = state.profileNew;
    return { marketPlaceData };
};

const mapDispatchToProps = (dispatch) => ({
    getToggleButtonCheck: (params, callback) => dispatch(getToggleButtonCheck(params, callback)),
    updateMarketSettings: (params, callback, successCallback) => dispatch(updateMarketSettings(params, callback, successCallback)),
    getMarketPlaceSettings: () => dispatch(getMarketPlaceSettings()),
    getDropDownCourseList: (params, callback) => dispatch(getDropDownCourseList(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceTab)