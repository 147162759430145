import React, { useEffect, useState } from 'react'
import "./loginPage.css"
import { Font15, Font17, Font21, Heading } from 'components/responsiveTypography'
import { Button } from 'components/Button';
import { connect } from 'react-redux';
import { postGenerateOtp, postGenerateRegistrationOtp, postRegisterDetails, postVerifyOtp, setEnrolledItemId, setRegisterSuccessModal, setShowDownloadAppModal, setShowLoginModal } from 'actions/loginNew';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Separator } from './loginSignInForm';
import LoginModal from './loginModal';
import { getUserMarketPlaceHomeData, postCourseEnrollNow } from 'actions/studentMarketPlace';
import { useRef } from 'react';

function VerifyOtpForm({ phoneNumber, storeTypedOtp, setStoreTypedOtp, verifyLoader, setVerifyLoader, isUserRegistered,
    postGenerateOtp, setShowVerifyOtpForm, postGenerateRegistrationOtp, resendOtpTimer, timer, postVerifyOtp, setUserRegister,
    userRegister, postRegisterDetails, setPhoneNumber, setEmailIdSignIn, setShowRegisterForm, isLoginModal, setShowLoginModal,
    setRegisterSuccessModal, marketSettingData, setShowDownloadAppModal, getUserMarketPlaceHomeData, courseEnrolledId , setEnrolledItemId, postCourseEnrollNow}) {

    const [showInValidOtp, setShowInvalidOtp] = useState(false);
    const [sendOtpLoaderToggle, setSendOtpLoaderToggle] = useState(false);
    const history = useHistory();

    const handleVerifyOtpSubmit = () => {
        if ((storeTypedOtp?.length > 5)) {
            setVerifyLoader(true)
            postVerifyOtp(
                storeTypedOtp,
                history,
                () => { setVerifyLoader(false); },
                () => {
                    setUserRegister({
                        role: "",
                        name: "",
                    });
                    if (!isUserRegistered) {
                        postRegisterDetails(
                            userRegister,
                            () => { },
                            () => {
                                setShowVerifyOtpForm(false);
                                setPhoneNumber('');
                                setShowLoginModal(false);
                                if (marketSettingData?.active) {
                                    if(!courseEnrolledId?.id){
                                        setRegisterSuccessModal(true);
                                        }
                                        else{
                                            postCourseEnrollNow(courseEnrolledId?.id, courseEnrolledId?.type, (res) => {
                                                if (res) {
                                                  console.log('rrreeessss',res,courseEnrolledId?.id);
                                                    if(res?.show?.type == 'success' && courseEnrolledId?.id){
                                                  console.log('rrreeessss1',res,courseEnrolledId?.id);
           
                                                  setTimeout(() => {
                                                    window.location.reload();
                                                  }, 1000);
                                                    }
                                                  
                                                    // setEnroll((prevSavedItems) => ({
                                                    //     ...prevSavedItems,
                                                    //     [id]: true,
                                                    // }));
                                                    // setFreeConfirmModal(false)
                                                    // history.push('/courses-list')
                                                }
                                                setShowDownloadAppModal(true)
                                            });
                                            setEnrolledItemId({id:null, type:''})
                                        }   
                                } else {
                                    getUserMarketPlaceHomeData(undefined, () => { })
                                    setShowDownloadAppModal(true)
                                }
                            },
                            history,
                        )
                    } else {
                        setShowLoginModal(false);
                    }
                },
                (res) => {
                    if (res === 'error1') {
                        setPhoneNumber('');
                        setStoreTypedOtp('')
                        setEmailIdSignIn(false);
                        setShowVerifyOtpForm(false);
                    } else if (res === 'error2') {
                        setShowInvalidOtp(true);
                    } else {
                        setShowInvalidOtp(false)
                    }
                }
            )
        } else { }
    }

    const handleOtpVerifyKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleVerifyOtpSubmit();
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const otpInputRefs = useRef([]);

    useEffect(() => {
        if (otpInputRefs.current[0]) {
            otpInputRefs.current[0].focus();
        }
    }, []);

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value)) {
            const newOtp = storeTypedOtp.split("");
            newOtp[index] = value;
            setStoreTypedOtp(newOtp.join(""));
            if (index < otpInputRefs.current.length - 1) {
                otpInputRefs.current[index + 1].focus();
            }
        }
    };

    const handleOtpKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            const newOtp = storeTypedOtp.split("");
            if (!newOtp[index]) {
                if (index > 0) {
                    otpInputRefs.current[index - 1].focus();
                    newOtp[index - 1] = "";
                }
            } else {
                newOtp[index] = "";
            }
            setStoreTypedOtp(newOtp.join(""));
        } else if (e.key === "ArrowLeft" && index > 0) {
            otpInputRefs.current[index - 1].focus();
        } else if (e.key === "ArrowRight" && index < otpInputRefs.current.length - 1) {
            otpInputRefs.current[index + 1].focus();
        } else if (e.key === "Enter") {
            // Check if OTP is valid and trigger submit
            if (storeTypedOtp.length == 6) {
                handleVerifyOtpSubmit();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text");
        if (/^\d{6}$/.test(pasteData)) {
            const newOtp = pasteData.split("");
            setStoreTypedOtp(pasteData);
            newOtp.forEach((digit, index) => {
                if (otpInputRefs.current[index]) {
                    otpInputRefs.current[index].value = digit;
                }
            });
            otpInputRefs.current[5].focus();
        }
    };

    const otpLength = storeTypedOtp.split("").filter((digit) => digit).length;
    const isSubmitDisabled = otpLength < 6;

    return (
        <>
            <div className={isLoginModal ? 'login-modal-form-width' : 'form-width'}>
                <div className='m-b-20'><Font21 bold color='#191919'>Verify your mobile number</Font21></div>
                <div className='m-b-25'><Font15 color='#636363'>Please enter the OTP we have sent you on {phoneNumber}  <span className='cursor-pointer' style={{ color: "#1089FF", fontWeight: 700 }} onClick={() => { setShowVerifyOtpForm(false) }}>EDIT</span></Font15></div>
                <div className='m-b-30'>
                    <Font17 color='#636363'>OTP</Font17>
                    <div className="otp-container">
                        {Array.from({ length: 6 }).map((_, index) => (
                            <input
                                key={index}
                                className="new-otp-input"
                                type="text"
                                maxLength="1"
                                value={storeTypedOtp[index] || ""}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                onPaste={handlePaste} // Handle paste event
                                ref={(input) => (otpInputRefs.current[index] = input)} // Save ref for each input
                            />
                        ))}
                    </div>
                    {showInValidOtp &&
                        <div style={{ display: "flex", justifyContent: "flex-end", color: "#FF414D", marginTop: 5, width: "100%" }}>
                            Invalid OTP
                        </div>
                    }
                </div>
                <div className='r-ac m-b-20'>
                    <Font15 color='#636363'>Didn’t receive an OTP?</Font15>
                    <Button type="link"
                        onClick={() => {
                            setStoreTypedOtp('');
                            setShowInvalidOtp(false)
                            setSendOtpLoaderToggle(true);
                            if (isUserRegistered) {
                                postGenerateOtp(
                                    "",
                                    () => { setSendOtpLoaderToggle(false) },
                                    (res) => {
                                        setShowVerifyOtpForm(res);
                                    })
                            } else {
                                postGenerateRegistrationOtp("", () => { setSendOtpLoaderToggle(false) }, "")
                            }
                        }}
                        loading={sendOtpLoaderToggle}
                        disabled={verifyLoader || resendOtpTimer}
                        style={{ marginLeft: 8, marginRight: 8 }}>
                        Resend OTP
                    </Button>
                    {timer && <Font15 color='#636363'>in {`${timer}`} seconds</Font15>}
                </div>
                <div style={{ width: "100%", display: "flex", gap: 20, marginBottom: 20 }}>
                    {!isUserRegistered &&
                        <Button
                            type="primary"
                            style={{ width: "50%", backgroundColor: "#FFFFFF", color: "#594099" }}
                            onClick={() => { setShowRegisterForm(true) }}
                        >EDIT DETAILS</Button>
                    }
                    <Button
                        loading={verifyLoader}
                        type="primary"
                        disabled={isSubmitDisabled}
                        style={{ width: !isUserRegistered ? "50%" : "100%" }}
                        onClick={handleVerifyOtpSubmit}
                    >SUBMIT
                    </Button>
                </div>
                <Separator />
                {isMobile ?
                    <Button
                        type="primary"
                        style={{ width: "100%", backgroundColor: "#FFFFFF", color: "#594099" }}
                        onClick={() => { setEmailIdSignIn(true); setShowVerifyOtpForm(false); }}
                    >CONTINUE WITH EMAIL ID</Button> :
                    <Font15>Sign in using <span style={{ color: "#1089FF", cursor: "pointer", fontWeight: 700 }} onClick={() => { setEmailIdSignIn(true); setShowVerifyOtpForm(false); }}>Email ID</span></Font15>}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { resendOtpTimer, timer, phoneNo, isUserRegistered, showLoginModal } = state.loginNew;
    const { marketSettingData } = state.studentMarketPlace;
    const { courseEnrolledId } = state.loginNew
    return { resendOtpTimer, timer, phoneNo, isUserRegistered, showLoginModal, marketSettingData, courseEnrolledId };
};

const mapDispatchToProps = (dispatch) => ({
    postGenerateOtp: (captchaToken, callBack, successCallBack) => dispatch(postGenerateOtp(captchaToken, callBack, successCallBack)),
    postVerifyOtp: (otp, history, callBack, successCallBack, invalidOtpCallBack) => dispatch(postVerifyOtp(otp, history, callBack, successCallBack, invalidOtpCallBack)),
    storePhoneNumber: (val) => dispatch(storePhoneNumber(val)),
    postEmailLogIn: (captchaToken, userEmailRegister, history, callBack) => dispatch(postEmailLogIn(captchaToken, userEmailRegister, history, callBack)),
    sendForgotLink: (userEmailRegister, history, callBack) => dispatch(sendForgotLink(userEmailRegister, history, callBack)),
    postRegisterDetails: (userRegister, callback, successCallBack, history = null) => dispatch(postRegisterDetails(userRegister, callback, successCallBack, history)),
    postGenerateRegistrationOtp: (email, callback, successCallBack) => dispatch(postGenerateRegistrationOtp(email, callback, successCallBack)),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
    setRegisterSuccessModal: (val) => dispatch(setRegisterSuccessModal(val)),
    setShowDownloadAppModal: (val) => dispatch(setShowDownloadAppModal(val)),
    getUserMarketPlaceHomeData: (noCourse, callBack) => dispatch(getUserMarketPlaceHomeData(noCourse, callBack)),
    setEnrolledItemId: (val) => dispatch(setEnrolledItemId(val)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtpForm);