import React from 'react';
import {Col, Row, Checkbox } from 'antd';
import { Color } from 'services';
import moment from 'moment';

export const agreement = (kycDetails, handleUpdateDetails) => (
    <div style={{height: "100%"}} className="r-c-jsb">
        <div style={{margin: '10px 50px', textAlign: 'justify'}}>
            <div className="text-center bold-700 text-sm">PAYMENT AND SETTLEMENT TERMS AND CONDITIONS</div>
            <div className="m-t-20">
                The <span className="bold-600"> Payment And Settlement Terms and Conditions</span> 
                (hereinafter referred to as <span className="bold-600">“the PST&C”</span> or <span className="bold-600">“this PST&C”)</span> 
                contains the terms of usage for use of payment and settlement of fees and other charges at the technology-based
                platform named and branded as <span className="bold-600">“AULAS<sup>TM</sup>”</span> (hereinafter referred to as <span className="bold-600">“the Platform”</span>) 
                owned and managed by <span className="bold-600">VECTORS EDUCATION LLP</span>, a Limited Liability Partnership Firm 
                incorporated under the Limited Liability Partnership Act, 
                2008 with registered office at 2nd & 3rd Floor, 67 B, F.C. Road, Latasil, Uzanbazar, Guwahati -781001, Assam, 
                India having LLP Identity No.AAK-9626 and having office at D-102, ND Oliva, HSR Layout Sector-2, Bengaluru, 560102, 
                Karnataka, India (hereinafter referred to as <span className="bold-600">“VEL”</span>, which expression shall unless repugnant to the context include its successors, heirs and permitted assigns). 
            </div>
            <div className="m-t-20">
                The PST&C are accepted and agreed upon unconditionally and wholly by <span className="bold-600">the Institution</span> whose name, 
                address, PAN and such other details are contained in the <span className="bold-600">Schedule A</span> to this PST&C.
            </div>
            <div className="m-b-10 m-t-20 bold-600">
                <span className='text-oo font-bold'>A.</span>
                <span className='text-oo font-bold m-l-10 b-b-2' style={{borderBottom: "1px solid black"}}>Definitions</span>
            </div>
            <div className='m-l-30 m-b-10'>
                <div className='text-oo'>Unless repugnant to the context, the following words and expressions, when used in this PST&C, shall have the meanings as mentioned hereinbelow:

                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>A1.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">System Provider</span> – The term “System Provider” has the same meaning as assigned 
                    to it under sub-section 2(1)(p) of the The Payment And Settlement Systems Act, 2007 (No. 51 of 2007) 
                    (hereinafter referred to as <span className="bold-600">“the Act”</span>) and specifically refers to the 
                    System Provider chosen by the Platform or VEL to provide services as System Provider for the purpose of 
                    providing services of a payment system at the Platform.

                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>A2.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Bank</span> – Bank or “the Bank” has the same meaning as assigned to it under 
                    sub-section 2(1)(a) of the Act and specifically refers to the Bank chosen by the Platform or VEL to provide
                     services as a nodal bank for the purpose of providing services related to payment and settlement at the 
                     Platform.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>A3.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Intermediary</span> – Intermediary and Intermediaries will have the 
                    same meaning as in the definition contained in Clause 2.1 of the Circular No. RBI/2009-10/231 
                    DPSS.CO.PD.No.1102/02.14.08/2009-10 dated 24 November 2009 (hereinafter referred to as 
                    <span className="bold-600">“the RBI Circular”</span>).
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>A4.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">User</span> – Any individual(s) who has been added or approved by anyone acting 
                    on behalf of the Institution and will also include any individual(s) added or approved by a Super-admin 
                    or by an Admin or by any one authorized by the Super-admin / Admin. The term User inter alia includes 
                    Super-admin, Admins, teachers, students, planners, data operators, operation personnel. 
                </div>
            </div>
            <div className="m-b-10 m-t-20 bold-600">
                <span className='text-oo font-bold'>B.</span>
                <span className='text-oo font-bold m-l-10 b-b-2' style={{borderBottom: "1px solid black"}}>Ownership & Roles</span>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>B1.</div>
                <div className='text-oo flex-12'>
                    VEL has developed and is owning a technology-based platform branded as <span className="bold-600">“AULAS<sup>TM</sup>”</span> (hereinafter
                     referred to as <span className="bold-600">“the Platform”</span>) for enabling institutions to 
                     integrate their learning systems in a manner that shall allow them to remotely carry out all academic, 
                     managerial and ancillary processes including but not limited to features such as online classes, 
                     assignments, schedule management, testing, digital library, attendance and fee management. 

                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>B2.</div>
                <div className='text-oo flex-12'>
                    VEL will act as Intermediary for all payments made by the User to the Institution through the facilities 
                    provided on the Platform with the support of the System Provider and the Bank.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>B3.</div>
                <div className='text-oo flex-12'>
                    In case the Institution desires (on case-to-case basis), VEL operating through the Platform may 
                    act as Intermediary for any refunds from the Institution to the User.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>B4.</div>
                <div className='text-oo flex-12'>
                    Role of the Institution will be as that of a Merchant as defined in the definition contained in Clause 2.2 
                    of the RBI Circular.
                </div>
            </div>
            <div className="m-b-10 m-t-20 bold-600">
                <span className='text-oo font-bold'>C.</span>
                <span className='text-oo font-bold m-l-10 b-b-2' style={{borderBottom: "1px solid black"}}>Legal Obligations</span>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>C1.</div>
                <div className='text-oo flex-12'>
                    The Platform (and VEL) shall comply with all statutory obligations of an Intermediary as provided in 
                    the RBI Circular as amended from time to time and also as provided under the Act.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>C2.</div>
                <div className='text-oo flex-12'>
                    The Institution as well as VEL are fully aware of their respective obligations, rights and 
                    responsibilities under the Act as well as under the RBI Circular. Both hereby commit to abide 
                    by the same at all times.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>C3.</div>
                <div className='text-oo flex-12'>
                    VEL shall engage only such parties as System Provider and as Bank which are operating under 
                    and in accordance with the authorisations issued by the Reserve Bank of India under the Act.
                </div>
            </div>
            <div className="m-b-10 m-t-20 bold-600">
                <span className='text-oo font-bold'>D.</span>
                <span className='text-oo font-bold m-l-10 b-b-2' style={{borderBottom: "1px solid black"}}>Commission & Charges</span>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>D1.</div>
                <div className='text-oo flex-12'>
                    VEL will deduct a consolidated commission from all payments processed and settled through the Platform. 
                    The consolidated commission will include three parts – (a) Charges + GST levied by the System Provider (b) 
                    Charges + GST levied by the Bank and (c) VEL charges + GST.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>D2.</div>
                <div className='text-oo flex-12'>
                    Charges levied by the System Provider and the Bank will vary from time to time and will also vary based on the 
                    mode of payment (UPI / Credit Card / Debit Card / Net Banking etc.).
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>D3.</div>
                <div className='text-oo flex-12'>
                    VEL will keep the Institution informed about the charges levied by the System Provider and the Bank in different 
                    situations from time to time.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>D4.</div>
                <div className='text-oo flex-12'>
                    VEL Charges included in the consolidated commission will not 
                    exceed <span className="bold-600">1% (One per cent) plus GST</span>, as applicable, of the amount 
                    processed through the Platform whether the amount processed is from the User to the Institution or 
                    from the Institution to the User.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>D5.</div>
                <div className='text-oo flex-12'>
                    VEL will not deduct any amount other than the consolidated commission provided in this 
                    Part of the PST&C.
                </div>
            </div>
            <div className="m-b-10 m-t-20 bold-600">
                <span className='text-oo font-bold'>E.</span>
                <span className='text-oo font-bold m-l-10 b-b-2' style={{borderBottom: "1px solid black"}}>Limitation of Liabilities</span>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>E1.</div>
                <div className='text-oo flex-12'>
                    The Institution agrees and acknowledges that VEL shall have no other obligations and/or duties, 
                    whether express or implied, under this PST&C in respect of all payments processed through the Platform 
                    except as specifically provided herein. In particular, VEL shall not be obliged to:
                    <ol type="a" className="m-t-20">
                        <li>
                            act as per or even acknowledge any communications or instructions received by it directly from a User;
                        </li>
                        <li>
                            take note of or take any action in respect of any dispute between a User and the Institution or between two or more Users or between two or more Institutions;
                        </li>
                        <li>
                            coordinate, verify or take any other action in respect of any refunds which may be due from the Institution to a User and the same shall be settled directly by and between the Institution and the concerned User(s);
                        </li>
                        <li>
                            do or omit to do anything if it would or might in its reasonable opinion constitute a breach of any Applicable Law.
                        </li>

                    </ol>

                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>E2.</div>
                <div className='text-oo flex-12'>
                    In the performance of its duties under this PST&C, VEL may rely on: 
                    <ol type="a" className="m-t-20">
                        <li>
                            any instruction, notice, statement or document believed by it to be genuine, correct and appropriately authorised by the Institution and shall have no duty to verify any signature on any document; and
                        </li>
                        <li>
                            any statement purportedly made by a director, authorised signatory or employee of the Institution regarding any matters which may reasonably be assumed to be within his / her knowledge or within his / her power to verify.
                        </li>
                    </ol>

                </div>
            </div>
            <div className="m-b-10 m-t-20 bold-600">
                <span className='text-oo font-bold'>F.</span>
                <span className='text-oo font-bold m-l-10 b-b-2' style={{borderBottom: "1px solid black"}}>Miscellaneous</span>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F1.</div>
                <div className='text-oo flex-12'>
                    This PST&C shall be read and interpreted in consonance with the Technology Platform Usage Terms And Conditions. Nothing contained herein negates 
                    or overrides the terms and conditions contained in the Technology Platform Usage Terms And Conditions.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F2.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Indemnification</span> – The Institution expressly and unequivocally agrees to and hereby does indemnify, save, 
                    defend and hold harmless VEL and its officers, directors / partners / designated partners, employees,
                     shareholders, agents, consultants and other representatives, successors and assigns of, from and against all, 
                     direct and indirect, claims, damages, losses, costs and expenses, arising out of any action omission, 
                     breach or default by the TSP&C and/or its employees or agents under or in the course of performing under 
                     this PST&C and/or otherwise incurred by VEL in the course of performance of its obligations under this 
                     PST&C.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F3.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Force Majeure</span> – Either party (VEL or the Institution) is not responsible 
                    or liable for any failure to perform or delay in performing its obligations under this PST&C to 
                    the extent that the failure or delay is caused by circumstances beyond the party’s reasonable control 
                    (such as labour disputes, acts of God, war or terrorist activity, malicious damage, accidents or 
                    compliance with any applicable law or government order). Each party will endeavour to minimise 
                    the effects of any of these events and to perform the obligations that are not affected.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F4.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Amendments</span> – The PST&C may be amended / modified at any time by VEL. 
                    Any such amendment / modification will be informed to the Institution at least fifteen (15) days 
                    before the amendment / notification comes into effect. The Institution will have the option of 
                    cancelling its use of the Platform. 
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F5.</div>
                <div className='text-oo flex-12'>
                    VEL and the Institution are independent entities and will 
                    remain so. Either party is not an agent of the other. Neither party will make any presumptions or 
                    assumptions about the relationship between the two parties beyond the specific terms contained 
                    in this PST&C. Either party has no rights to bind the other or to create liabilities or give 
                    commitments on behalf of the other to any third party in any manner whatsoever.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F6.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Duration</span> – This PST&C will come in operation from the time that the Institution 
                    accepts it online by clicking on the relevant button. The PST&C will remain in full force till the Institution 
                    continues to use the services of the Platform or till VEL cancels the Platform’s access to the Users of the 
                    Institution, whichever is earlier.  
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F7.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Applicable Law</span> – The law as applicable in the state of Karnataka, India will 
                    apply.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F8.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Jurisdiction</span> – Neither Party will approach any court unless the Arbitrator has considered the dispute and has made arbitral award. Thereafter, the courts at Bengaluru, Karnataka, 
                    India will have jurisdiction.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F9.</div>
                <div className='text-oo flex-12'>
                    <span className="b-b-b-1">Schedule</span> – Schedule to this PST&C is an integral part of the PST&C.
                </div>
            </div>
            <div className="m-b-10 m-l-30 display-flex" >
                <div className='text-oo flex-1'>F10.</div>
                <div className='text-oo flex-12'>
                Unless otherwise stated, notices to be given under this PST&C shall be in writing and shall be given by email or by registered mail to the respective addresses of VEL or the Institution, as the case may be.
                </div>
            </div>
            <div className="m-b-10 m-t-30 m-l-30 r-jsb bold-600" >
                <div>
                VECTORS EDUCATION LLP
                </div>
                <div>
                THE INSTITUTION
                </div>
            </div>
            <div className="m-b-10 m-t-30 m-l-30 r-jsb">
                <div>
                    Date: <span className="bold-600">{kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved" ? 
                    moment(kycDetails.kycSubmittedOn).format("DD-MM-YYYY") : 
                    moment().format("DD-MM-YYYY")}</span>   
                </div>
                <div>
                    Time: <span className="bold-600">{kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved" ? 
                    moment(kycDetails.kycSubmittedOn).format("hh:mm A") : 
                    moment().format("hh:mm A")} </span>
                </div>
            </div>
            <div className="r-c-fe">
                <span className="b-b-b-1 bold-600">Schedule A</span>
            </div>
            <div className="r-c-c bold-600 ">
                <span className="b-b-b-1">
                    THE INSTITUTION
                </span>
            </div>
            <div className="r-c-c m-t-20" style={{textAlign: 'left'}}>
               <table className="agreementTable" style={{width: "100%"}}>
                   <tbody>
                   <tr>
                       <td style={{width: "40%"}}>
                            Name of the Institution
                       </td>
                       <td style={{width: "60%"}}>
                            <span style={{fontWeight: 800}}>{kycDetails?.name}</span>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                            Type of Organization
                       </td>
                       <td style={{width: "60%"}}>
                        <span style={{fontWeight: 800}}>{kycDetails?.orgType ? kycDetails?.orgType : "-"}</span>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                            Registered Office / Principal Office
                       </td>
                       <td style={{width: "60%"}}>
                           <div style={{fontWeight: 800}}>{kycDetails.locality}</div>
                           <div>District: <span style={{fontWeight: 800}}>{kycDetails.city}</span></div>
                           <div>State: <span style={{fontWeight: 800}}>{kycDetails.state}</span></div>
                           <div>Pincode: <span style={{fontWeight: 800}}>{kycDetails.pincode}</span></div>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                            PAN
                       </td>
                       <td style={{width: "60%"}}>
                        <span style={{fontWeight: 800}}>
                            {["Private Limited","LLP","Trust","Society",].includes(kycDetails.orgType)? kycDetails?.businessPanNo : kycDetails.personalPanNo ?  kycDetails.personalPanNo : "-"} 
                            </span>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                       Name of Authorized Officer Accepting on behalf of the Institution
                       </td>
                       <td style={{width: "60%"}}>
                        <span style={{fontWeight: 800}}>{kycDetails?.superAdminName}</span>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                        Designation of Authorized Officer
                       </td>
                       <td style={{width: "60%"}}>
                        <span style={{fontWeight: 800}}>{kycDetails?.superAdminDesignation}</span>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                       Contact No. of Authorized Officer
                       </td>
                       <td style={{width: "60%"}}>
                        <span style={{fontWeight: 800}}>{kycDetails?.phone}</span>
                       </td>
                   </tr>
                   <tr>
                       <td style={{width: "40%"}}>
                       Email ID of Authorized Officer
                       </td>
                       <td style={{width: "60%"}}>
                        <span style={{fontWeight: 800}}>{kycDetails?.email}</span>
                       </td>
                   </tr>
                   </tbody>
               </table>
           </div>
        </div>
           
        <div style={{margin: '10px 50px', textAlign: 'justify'}} className="display-flex">
              <div className="m-r-10 ">
              <Checkbox checked={kycDetails.agreementAccepted} 
              disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
              onClick={e => handleUpdateDetails(true, 'agreementAccepted')}/>
              </div>
              <div>
                I have filled the KYC details correctly to the best of my knowledge. Also, I have read the terms of the agreement and agree to the same.
                And I hereby authorize aulas to collect the fees from our students on our behalf.
              </div>
        </div>
    </div>
)