import React from 'react';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import 'antd/lib/typography/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/card/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/collapse/style/index';
import 'antd/lib/radio/style/index';
import { Color } from '../../../services';
import "react-quill/dist/quill.snow.css";
import '../styles.css';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import { toggleViewQuestionModal, toggleQuestionModal, resetQParams } from '../../../actions/test_old';


function getFilename(url){
    return url ? url.split('/').pop().split('#').shift().split('?').shift() : null;
}

const answerType = {
  Subjective: "Numeric",
  Image: "Subjective",
  MCQ: "Objective"
}

class ViewQuestionModal extends React.Component {
  

    handleClose(){
        this.props.toggleViewQuestionModal(false);
        this.props.resetQParams();
    }

    handleEditQuestionModal = () => {
        const { questionImagesList, optionsImagesList, oldImageList, toggleQuestionModal, qParams, toggleViewQuestionModal} =  this.props;
        toggleViewQuestionModal(false);
        toggleQuestionModal(true);
        Array.from( new DOMParser().parseFromString( qParams.statement, 'text/html' )
        .querySelectorAll( 'img' ) )
        .map( img => {
          questionImagesList.push(getFilename(img.getAttribute( 'src' )))
          oldImageList.push(getFilename(img.getAttribute( 'src' )))
          return 1
        })
        if(qParams.options){
          qParams.options.forEach((option, index) => {
            Array.from( new DOMParser().parseFromString( option.value, 'text/html' )
            .querySelectorAll( 'img' ) )
            .map( (img) => {
              optionsImagesList[index].push(getFilename(img.getAttribute( 'src' )))
              oldImageList.push(getFilename(img.getAttribute( 'src' )))
              return 1
            } )
          })
        }
    
      }


  render() {
    const { viewQModal, qParams, currentTestDetails } = this.props;

    // const uploadButton = (
    //   <div>
    //     {this.state.imgLoading ? <LoadingOutlined /> :
    //       <MdImage style={{color:Color.primary, fontSize:'1.6em'}} />
    //     }
    //   </div>
    // );
    

    return (
      <Modal
        centered
        width={'50%'}
        // style={{}}
        footer={
            <div>
                 <Button type="ghost" style={{borderRadius: 10}} onClick={() => this.handleClose()}>
                    Close
                </Button>
                {currentTestDetails.status !== "Published" &&
                <Button type="primary" style={{borderRadius: 10}} onClick={() => this.handleEditQuestionModal()}>
                    Edit
                </Button>}
            </div>
            
        }
        onCancel={() => {
            this.handleClose()
        }}
        closable={true}
        // closable={qParams.id ? false : true}
        visible={viewQModal}
        style={{
          height:'100%',
          overflowY: 'auto',
        }}
        className='radius-10 qmodal'
        title={<div className='color-purple'>
            View Question
        </div>}
      >
        <div>
          <div className='r-jsb'>
            <div style={{flex: 1}} className='color-greenblack'>
              <div className='p-5 questionLightGreyBox'>
                <div style={{display: 'flex'}}>
                  <div className='color-purple text-xmd'>
                    Statement <span style={{color: Color.red}}>*</span>
                  </div>
                </div>

                <div className="quest">{ ReactHtmlParser(qParams.statement, { transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }

                    if (node.type === 'tag' && node.name === 'img') {
                      return <img style={{maxHeight: '40vh', maxWidth: '30vw'}} src={node.attribs.src} alt=""/>;
                    }
                  }}) }</div>
              </div>
              {(qParams.type !== 'MCQ') ?
                <div className='p-5 questionLightGreyBox'>
                  <div className='color-purple text-xmd'>
                    Answer {qParams.type === "Image" ?  '(optional)' : <span style={{color: Color.red}}>*</span>}
                  </div>
                  <div>{qParams.answer}</div>
                </div>
                : null
              }
            </div>
            <div>
           
            <div style={{margin: '0px 0px 0px 10px'}} className='p-5 questionLightGreyBox'>
              <div  className='color-purple text-xmd'>
                Marks
              </div>
            </div>
            <div>Positive: {qParams.positiveMarks}</div> 
            { qParams.negativeMarks ? <div>Negative: {qParams.negativeMarks}  </div> : null}
            <div style={{margin: '0px 0px 10px 10px'}} className='p-5 questionLightGreyBox'>
              <div className='color-purple text-xmd'>
                Answer Type
              </div>
            </div>
            <div className='r-c-c'>{answerType[qParams.type]}</div>
            <div style={{margin: '10px 0px 10px 10px'}} className='p-5 questionLightGreyBox'>
              <div className='color-purple text-xmd'>
                Chapter
              </div>
            </div>
              <div className='r-c-c'>{qParams.chapterName}</div>
            </div>
          </div>
          
          <div>
            {qParams.type === "MCQ" && qParams.options && qParams.options.length ?
              qParams.options.map((data, index) => //qParams.options
               { 
                 const objAns = typeof(qParams.answer) === "string" ? parseInt(qParams.answer) : qParams.answer;
                 return (<div key={index.toString()} style={{padding: 0}} className='assignmentItem m-t-10'>
                  <div
                    className='r-jsb'
                  >
                    <div
                      className='color-purple text-xmd p-5'>
                      {String.fromCharCode(65 + index)} :
                    </div>
                    <div className='flex-1 color-greenblack'>
                      <div className='color-purple text-xmd m-t-5'>
                        Option: 
                        { qParams.options[index] ?
                          ReactHtmlParser(qParams.options[index].value, { transform: (node) => {
                              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                return <TeX math={`${node.attribs['data-value']}`} />;
                              }
                              // if (node.type === 'tag' && node.name === 'math') {
                              //   return <MathJax math={String.raw`${qParams.options[index].value}`} />;
                              // }
                              if (node.type === 'tag' && node.name === 'img') {
                                return <img style={{maxWidth: '40vw',  maxHeight: '40vh'}} src={node.attribs.src} alt="" />;
                              }
                            }}) : '' }
                      </div>
                    </div>
                    <Radio
                      style={{padding: 5,}}
                      key={index.toString()}
                      disabled
                      value={index}
                      checked={objAns !== null ? objAns === index : false}
                    />
                  </div>
                </div>)}
              )
            : null}
          </div>
        </div>
      </Modal>

      /*<Modal
      visible={displayOptionModalBool}
      width={"70%"}
      title="Editor"
      closable={false}
      footer={
        <div>
          <Button type="primary" onClick={() => this.props.displayOptionModal(false)}>Done</Button>
        </div>
      }
      >

      <FormulaEditor
          id={`option${displayOptionIndex}`}
          optText={quillEditorText['option'+displayOptionIndex]}
          // data={info.data}
          onChange={(value) => {
            // this.setState({info: {...this.state.info, data: value}})
            // setCurrentTestQuestion(value)
          }}
          // imagesList={optionsImagesList[index]}
          user={user}
          deletedImages={deletedImages}
          imagesList={questionImagesList }
          // deleteImages={(imgList, index) => deleteImages(imgList, index)}
       />
      </Modal>*/
    )
  }
}

const mapStateToProps = state => {
  const { viewQModal, qParams, questionImagesList, optionsImagesList, oldImageList, currentTestDetails } = state.test;
  return { viewQModal, qParams, questionImagesList, optionsImagesList, oldImageList, currentTestDetails };
};

const mapDispatchToProps = dispatch => ({ 
  toggleViewQuestionModal: (bool) => dispatch(toggleViewQuestionModal(bool)),
  toggleQuestionModal: bool => dispatch(toggleQuestionModal(bool)),
  resetQParams: () => dispatch(resetQParams()),

//   updateQParams: (value, key, index) =>  dispatch(updateQParams(value, key, index)),
//   postQuestion: (data) =>  dispatch(postQuestion(data)),
//   searchChapter: query => dispatch(searchChapter(query)),
//   setCurrentImage: (val,image) => dispatch(setCurrentImage(val,image)),
//   addOption: obj => dispatch(addOption(obj)),
//   updateQOptionParam: (val, index) => dispatch(updateQOptionParam(val, index)),
//   setOptionImage: ( index, value, image) => dispatch(setOptionImage(index, value, image)),
//   deleteImages: (image, index) => dispatch(deleteImages(image, index)),
//   removeOptionFromParams: id => dispatch(removeOptionFromParams(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestionModal);
