import React from 'react';
import {Modal} from 'antd';
import { Button } from 'antd';

function SubmitSuccess({
    visible, onClose, history
}) {

    const handleOkClick = () => {
        onClose();
        history.push('/kyc-form-submitted')
    }

    return (
        <Modal
            visible={visible}
            footer={false}
            className="modal-border-15"
            closable={false}
        >
            <div className="r-c-c">
                <img src={require('Assets/ill-submitted.svg').default}  />
            </div>
            <div className="color-purple text-sm bold-600 r-c-c"
            >KYC Submitted Successfully</div>
            <div className="text-center  r-c-c">
                Your KYC Details have been received and sent for approval. We will notify you once approved.
            </div>
            <div className="r-c-c m-t-20">
                <Button type="primary" shape="round" style={{width:100}} onClick={() => handleOkClick()}>Okay</Button>
            </div>

        </Modal>
    )
}

export default SubmitSuccess;
