import React from 'react'
import { Tooltip } from 'antd';
const bookGreen = require("Assets/test/bookGreen.svg").default;
const bookBlue = require("Assets/test/bookBlue.svg").default;

const IconAndVal = ({ val, refer }) => {
    const iconUrl = refer?bookGreen:bookBlue
    return (
      <div>
        <Tooltip trigger={'hover'} placement='topRight' title={refer?"Objective test":"Subjective test"}>
          <img
            style={{
              width: "18px",
              height: "18px",
            }}
            src={iconUrl}
            alt=""
          />
        </Tooltip>
        <span style={{ marginLeft: "5px" }}>{val}</span>
      </div>
    );
  };

export default IconAndVal