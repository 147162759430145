import React from 'react';
import { FormulaEditor } from '../../../components';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import InputNumber from 'antd/lib/input-number';
import 'antd/lib/typography/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/card/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/collapse/style/index';
import 'antd/lib/radio/style/index';
import 'antd/lib/input-number/style/index';
import { Color, Notification } from '../../../services';
import "react-quill/dist/quill.snow.css";
import { EditOutlined, CloseOutlined} from '@ant-design/icons';
import { IoMdAddCircle } from 'react-icons/io';
import { FiAlignLeft } from "react-icons/fi";
import { FaRegDotCircle } from "react-icons/fa";
import { updateQParams, postQuestion, resetQParams, toggleQuestionModal, setCurrentImage, addOption, 
  updateQOptionParam, setOptionImage, deleteImages, removeOptionFromParams } from '../../../actions/test_old';
import {searchChapter} from '../../../actions/assignment'
import '../styles.css';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';


// import Quill from '../../../Assets/js/quill.js'

const qTypes = [
  {
    label: "Numeric",
    value: "Subjective",
    icon: <span style={{
      border: '1px solid #5448B7',
      borderRadius: 3,
      padding: 1,
      fontSize: 10,
      color: '#5448B7'}}>
      01
    </span>,
  }, 
  {
    label: "Objective",
    value: "MCQ",
    icon: <FaRegDotCircle/>,
  }, 
  {
    label: "Subjective",
    value: "Image",
    icon: <FiAlignLeft />,
  }, 
]
const {Option} = Select;
const answerTypeList = [
  {
    id: 0,
    key: '0',
    icon: <span style={{
      border: '1px solid #5448B7',
      borderRadius: 3,
      padding: 1,
      fontSize: 10,
      color: '#5448B7'}}>
      01
    </span>,
    text: 'Numeric',
    send: 'Subjective',
  },
  {
    id: 1,
    key: '1',
    icon: <FaRegDotCircle/>,
    text: 'Objective',
    send: 'MCQ',
  },
  {
    id: 2,
    key: '2',
    icon: <FiAlignLeft />,
    text: 'Subjective',
    send: 'Image',
  },
];

class QuestionModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      imageAdd: false,
      imgLoading: false,
      editorModal: false,
      editIndex: -1,
      editorValue: ""
    }
  }

  componentDidMount(){
    FormulaEditor.uploadUrl = '/test/upload-image';
  }

  updateTestQuestion() {
    const { qParams, postQuestion, deletedImages, questionImagesList, oldImageList, optionsImagesList, currentTestDetails } = this.props;

    let params = {
      id: qParams.id,
      statement: qParams.statement,
      answer: qParams.answer,
      type: qParams.type,
      positiveMarks:  qParams.positiveMarks,
      negativeMarks: qParams.negativeMarks,
      details: '',
      remarks: '',
      assignmentId: qParams.id,
      markedForReview: false,
      deletedFiles: [...deletedImages],
      info: {},
      options: JSON.stringify(qParams.options),
      testId: currentTestDetails.id,
      chapterId: qParams.chapterId
    }

    if(!qParams.positiveMarks){
      return Notification.error('Error', 'Please enter positive marks.');
    }

    if (qParams.type === "MCQ") {
      if (qParams.options.length <  2) {
        return Notification.error('Error', 'Atleast two options are required for an objective type question');
      } else {
        for(const item of qParams.options){
          if(item.value === "" || item.value === "<p><br></p>"){ 
            return Notification.error('Error', 'All or some options are blank. Either add an image or a statement or remove the option');
          }
        }
      }
    }


    if ((qParams.type === "MCQ"  || qParams.type === "Subjective") && qParams.answer === '') {
      return Notification.error('Error', 'Provide the correct answer');
    }else if((qParams.type === "MCQ"  || qParams.type === "Subjective") && qParams.answer === null){
      return Notification.error('Error', 'Provide the correct answer');
    }


    postQuestion(params);
    questionImagesList.splice(0, questionImagesList.length )
    deletedImages.splice(0, deletedImages.length)
    oldImageList.splice(0, oldImageList.length)
    for(const i in [0,1,2,3]){
      if(optionsImagesList[i].length)
        optionsImagesList[i].splice(0, optionsImagesList[i].length)
    }
  };

  addOption = () => {
    const {addOption, qParams} = this.props;
    addOption({
      id: qParams.options.length,
      value: '',
    });
    this.openEditor(qParams.options.length-1);
    // this.props.displayOptionModal(true);
  };

  removeOption = (id) => {
    let {  setOptionImage, optionsImagesList, deletedImages, qParams, updateQParams, removeOptionFromParams } = this.props;

    optionsImagesList[id].forEach(item => deletedImages.push(item));
    if(qParams.answer === id){
      updateQParams(null, 'answer')
    }

    removeOptionFromParams(id);

    // for(let i = id; i < 3; i++){
    // }
    setOptionImage(id, null, null)
  };

  answerTypes = () => (
    <Menu className='assignmentddlmenu'>
      {answerTypeList.map((data, index) =>
        <Menu.Item key={index.toString()}>
          <div onClick={() => this.props.setAnswerType(data.send, data.id)} className='color-purple'>
            {data.icon} {data.text}
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  async setCorrectOption(index){
    const { updateQParams } = this.props;
    updateQParams(index, 'answer')
  }


  clearImages(){
    const { questionImagesList, optionsImagesList, deleteImages, qParams, oldImageList,
    deletedImages } =  this.props;
    let deletedList = [];
    if(qParams.id){ //Edit question case
      deletedImages.forEach(img => {
        if(!oldImageList.includes(img)){
          deletedList.push(img)
        }
      })
    }else{ //Add Question case
      deletedList = [...deletedImages];
      questionImagesList.forEach(img => {
        if(!deletedImages.includes(img)){
          deletedList.push(img)
        }
      })
      for(const i in [0,1,2,3]){
        optionsImagesList[i].forEach(img => {
          if(!deletedImages.includes(img)){
            deletedList.push(img)
          }
      })
      }
    }
    if(deletedList.length){
     deleteImages(deletedList);
    }
    questionImagesList.splice(0, questionImagesList.length )
    deletedImages.splice(0, deletedImages.length)
    oldImageList.splice(0, oldImageList.length)
    for(const i in [0,1,2,3]){
      if(optionsImagesList[i].length)
        optionsImagesList[i].splice(0, optionsImagesList[i].length)
    }
  }


   resetEditor = async () => (
    await this.setState({
      editIndex: -1,
      editorValue: "",
      editorModal: false
    })
  )

  openEditor = (index) => {
    this.setState({
      editorModal: true, 
      editIndex: index})
  }


  editorModal = () => {
    const {deleteImages, deletedImages,  qParams, updateQParams, 
    questionImagesList, optionsImagesList, user, updateQOptionParam} = this.props;
    const { editorModal, editIndex } =  this.state;
    let id = editIndex === -1 ? "statement" : editIndex.toString();
    const onChange = (editorValue) => {
      if(editIndex === -1){
        updateQParams(editorValue, 'statement', editIndex);
      }
      else {
        id = editIndex.toString();
        updateQOptionParam(editorValue, editIndex)
      }
    }
    const data = editIndex === -1 ? qParams.statement : //
             qParams.options[editIndex] ?  qParams.options[editIndex].value : ''


    return (
      
        editorModal ? 
        <Modal
          visible={editorModal}
          width={"50%"}
          title="Editor"
          closable={false}
          footer={false}
        >
          <FormulaEditor
              id={id}
              data={data}
              disableOptAdd={qParams.options && qParams.options.length>=4 }
              imagesList={editIndex === -1 ? questionImagesList : optionsImagesList[editIndex] || []}
              deletedImages={deletedImages}
              imgPath={"/uploads/testQuestion/"}
              deleteImages={(imgList, index) => deleteImages(imgList, index)}
              resetEditor={() => this.resetEditor()}
              onChange={(value) => onChange(value)}
              clearImages={() => this.clearImages()}
              user={user}
              addOption={this.addOption}

          />
        </Modal> : null

      
    
  )}

  handleChapterSelect(value, object){
    this.props.updateQParams(value, 'chapterId'); 
    this.props.updateQParams(object.children, 'chapterName');
  }


  render() {
    const { toggleQuestionModal, setCurrentImage, questionModal, qPostLoader, resetQParams, updateQParams, 
      qParams, currentTestDetails } = this.props;

    // const uploadButton = (
    //   <div>
    //     {this.state.imgLoading ? <LoadingOutlined /> :
    //       <MdImage style={{color:Color.primary, fontSize:'1.6em'}} />
    //     }
    //   </div>
    // );


    return (
      <Modal
        centered
        width={'50%'}
        maskClosable={false}
        // style={{}}
        footer={[
          <div className='r-c-fe'>
            {/*<Button
              className='radius-10 m-r-10'
              type="default"
              onClick={() => {
                setCurrentStatement('');
                removeCurrentQuestion()
                toggleQuestionModal(false)
                setCurrentImage(null, null)
                this.clearImages()
              }}
              >
              Cancel
            </Button>*/}
            {qParams.id ? null :
             <Button
              className='radius-10 m-r-10'
              type="ghost"
              onClick={() => {
                resetQParams()
                toggleQuestionModal(false)
                setCurrentImage(null, null)
                this.clearImages()
              }}
            >
             Cancel
            </Button>}
            <Button
              disabled={qParams.statement === ''  || qParams.statement == "<p><br></p>"}
              className='radius-10 m-r-10'
              type="primary"
              loading={qPostLoader}
              onClick={() => this.updateTestQuestion()}
            >
              Save & Next
            </Button>
           
          </div>
        ]}
        // onCancel={() => {
        //   setCurrentStatement('');
        //   removeCurrentQuestion()
        //   toggleQuestionModal(false)
        //   setCurrentImage(null, null)
        //   this.clearImages()
        // }}
        closable={false}
        // closable={qParams.id ? false : true}
        visible={questionModal}
        style={{
          height:'100%',
          overflowY: 'auto',
        }}
        className='radius-10 qmodal'
        title={<div className='color-purple'>
          {qParams.id ? 'Edit Question' : 'Add Question'}
        </div>}
      >
        <div

        >
          <div className='r-jsb'>
            <div style={{flex: 1}} className='color-greenblack'>
              <div className='p-5 questionLightGreyBox'>
                <div style={{display: 'flex'}}>
                  <div className='color-purple text-xmd'>
                    Statement <span style={{color: Color.red}}>*</span>
                  </div>
                  <div className=' text-xmd color-purple cursor-pointer'>
                    <EditOutlined onClick={() => this.openEditor(-1)}/>
                  </div>
                </div>

                <div className="quest">{ ReactHtmlParser(qParams.statement, { transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }

                    if (node.type === 'tag' && node.name === 'img') {
                      return <img style={{maxHeight: '40vh', maxWidth: '30vw'}} src={node.attribs.src} alt=""/>;
                    }
                  }}) }</div>
              </div>
              {(qParams.type !== 'MCQ') ?
                <div className='p-5 questionLightGreyBox'>
                  <div className='color-purple text-xmd'>
                    Answer {qParams.type === "Image" ?  '(optional)' : <span style={{color: Color.red}}>*</span>}
                  </div>
                    <input
                      className='modalTextbox'
                      value={qParams.answer}
                      type={qParams.type === "Image" ? "text" : "number"}
                      onChange={(e) => updateQParams(e.target.value, 'answer')}
                    />
                </div>
                : null
              }
            </div>
            <div>
           
            <div style={{margin: '0px 0px 0px 10px'}} className='p-5 questionLightGreyBox'>
              <div  className='color-purple text-xmd'>
                Marks
              </div>
            </div>
            <div>
             Positive: <InputNumber style={{margin: '10px 20px 10px 10px', borderRadius: 10, maxWidth: "5vw"}} min={0} 
              value={qParams.positiveMarks} onChange={(value) =>
                {updateQParams(value, 'positiveMarks')}} /> 
           </div>
            {qParams.type !== 'Image' && <div>
              Negative: <InputNumber style={{margin: '10px 20px 10px 10px', borderRadius: 10, maxWidth: "5vw"}} min={0} 
              value={qParams.negativeMarks} onChange={(value) =>
                {updateQParams(value, 'negativeMarks')}} /> 
            </div>}
            <div style={{margin: '0px 0px 10px 10px'}} className='p-5 questionLightGreyBox'>
              <div className='color-purple text-xmd'>
                Answer Type
              </div>
            </div>
            <Select
              className='m-l-10 p-l-10 p-r-10 cursor-pointer'
              style={{width: "12vw", borderRadius: 10}}
              value={qParams.type}
              onSelect={(value) => {updateQParams(value, 'type'); updateQParams(null, 'answer')}}
            >
             {qTypes.map(type => <Option value={type.value}>{type.icon} {type.label}</Option>) }
            </Select>
            <div style={{margin: '10px 0px 10px 10px'}} className='p-5 questionLightGreyBox'>
              <div className='color-purple text-xmd'>
                Chapter
              </div>
            </div>
              <Select
                className='m-l-10 p-l-10 p-r-10 cursor-pointer'
                style={{width: "fit-content", borderRadius: 10}}
                placeholder="Select chapter"
                value={qParams.chapterId}
                onSelect={(value, obj) => this.handleChapterSelect(value, obj)}//this.selectChapter(value, 'type')}
                autoSize={true}
              >
                {currentTestDetails.chapterIds.map((id, index) => 
                  (<Option value={id}>{currentTestDetails.chapterNames[index]}</Option>)

                )}
              </Select>
              {/* <AutoComplete
                options={searchedChaptersList}
                style={{ width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                onSelect={(value, obj) => {updateQParams(obj.id, 'chapterId'); updateQParams(value, 'chapterName')}}
                onSearch={(query) => this.props.searchChapter(query)}
                value={qParams.chapterName}
                onChange={(value) => updateQParams(value, 'chapterName')}
                >
                <Input.Search 
                  style={{ borderRadius:10, height: 32}} 
                  placeholder="Search chapter..."  loading={toggleSearchLoader} allowClear
                 />
              </AutoComplete> */}
            
            </div>
          </div>
          
          {qParams.type === "MCQ" ?
            <div className='r-jsb' style={{marginTop: 30}}>
              <Button
                icon={<IoMdAddCircle color='#FFFFFF' size='1.2em' style={{margin: 5}}/>}
                type='primary'
                onClick={() => {
                  this.addOption();
                }}
                className='text-xmd r-c-c radius-10 m-t-5 m-b-5'
                disabled={qParams.options && qParams.options.length>=4 } //
              >
               Add Option
              </Button>
              <div style={{fontSize: 12, color: Color.red, display: 'flex', alignItems: 'flex-end'}}>
              *Mark the correct option
              </div>
            </div>
            : null
          }
          <div>
            {qParams.type === "MCQ" && qParams.options && qParams.options.length ?
              qParams.options.map((data, index) => //qParams.options
               { 
                 const objAns = typeof(qParams.answer) === "string" ? parseInt(qParams.answer) : qParams.answer;
                 return (<div key={index.toString()} style={{padding: 0}} className='assignmentItem m-t-10'>
                  <div
                    className='r-jsb'
                    style={{
                      borderBottom: this.state[`file${index + 1}`] ? "1px solid #E4E4E4" : "0px solid"
                    }}
                  >
                    <div
                      className='color-purple text-xmd p-5'>
                      {String.fromCharCode(65 + index)} :
                    </div>
                    <div className='flex-1 color-greenblack'>
                      <div className='color-purple text-xmd m-t-5'>
                        Option: <EditOutlined onClick={() => this.openEditor(index)}/>
                        { qParams.options[index] ?
                          ReactHtmlParser(qParams.options[index].value, { transform: (node) => {
                              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                return <TeX math={`${node.attribs['data-value']}`} />;
                              }
                              // if (node.type === 'tag' && node.name === 'math') {
                              //   return <MathJax math={String.raw`${qParams.options[index].value}`} />;
                              // }
                              if (node.type === 'tag' && node.name === 'img') {
                                return <img style={{maxWidth: '40vw',  maxHeight: '40vh'}} src={node.attribs.src} alt="" />;
                              }
                            }}) : '' }
                      </div>
                    </div>
                    <Radio
                      style={{padding: 5,}}
                      key={index.toString()}
                      value={index}
                      checked={objAns !== null ? objAns === index : false}
                      onClick={(e) => this.setCorrectOption(index)}
                    />
                    <div
                      onClick={() => this.removeOption(index)}
                      className='text-xmd text-center p-5 color-purple'
                    >
                      <CloseOutlined style={{fontSize: '1.3em', cursor: 'pointer'}} />
                    </div>
                  </div>
                </div>)}
              )
            : null}
          </div>
        </div>
        {this.editorModal()}
      </Modal>

      /*<Modal
      visible={displayOptionModalBool}
      width={"70%"}
      title="Editor"
      closable={false}
      footer={
        <div>
          <Button type="primary" onClick={() => this.props.displayOptionModal(false)}>Done</Button>
        </div>
      }
      >

      <FormulaEditor
          id={`option${displayOptionIndex}`}
          optText={quillEditorText['option'+displayOptionIndex]}
          // data={info.data}
          onChange={(value) => {
            // this.setState({info: {...this.state.info, data: value}})
            // setCurrentTestQuestion(value)
          }}
          // imagesList={optionsImagesList[index]}
          user={user}
          deletedImages={deletedImages}
          imagesList={questionImagesList }
          // deleteImages={(imgList, index) => deleteImages(imgList, index)}
       />
      </Modal>*/
    )
  }
}

const mapStateToProps = state => {
  const {qParams, currentTestDetails, qPostLoader, questionModal, questionImagesList,  deletedImages, oldImageList, optionsImagesList } = state.test;
  const { orgId } = state.session.user
  const { user } = state.session;
  return { orgId, questionImagesList, optionsImagesList, user, deletedImages, oldImageList, 
    qParams, currentTestDetails, qPostLoader, questionModal};
};

const mapDispatchToProps = dispatch => ({ 
  updateQParams: (value, key, index) =>  dispatch(updateQParams(value, key, index)),
  postQuestion: (data) =>  dispatch(postQuestion(data)),
  resetQParams: () => dispatch(resetQParams()),
  searchChapter: query => dispatch(searchChapter(query)),
  toggleQuestionModal: bool => dispatch(toggleQuestionModal(bool)),
  setCurrentImage: (val,image) => dispatch(setCurrentImage(val,image)),
  addOption: obj => dispatch(addOption(obj)),
  updateQOptionParam: (val, index) => dispatch(updateQOptionParam(val, index)),
  setOptionImage: ( index, value, image) => dispatch(setOptionImage(index, value, image)),
  deleteImages: (image, index) => dispatch(deleteImages(image, index)),
  removeOptionFromParams: id => dispatch(removeOptionFromParams(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionModal);
