import { getCourseDetails, getLiveCourseDetails, getStudyMaterialData, getStudyMaterialDetails, getVideoResourceDetails, postUsersAddRating, getTestSeriesDetails } from 'actions/studentMarketPlace'
import { Modal, Rate } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { Button } from 'components/Button'
import React, { useState } from 'react'
import { connect } from 'react-redux'

function UserAddRatingModal({ isUserAddRatingModalVisible, getLiveCourseDetails, getVideoResourceDetails, getStudyMaterialDetails, user, fromMyCourcesPage, getCourseDetails, setUserAddRatingModalVisible, courseId, resourceType, postUsersAddRating,getStudyMaterialData, getTestSeriesDetails }) {
    const [addRatingBtnLoader, setAddRatingBtnLoader] = useState(false)
    const [params, setParams] = useState({
        id: courseId,
        resourceType: resourceType,
        ratingValue: 0,
        ratingExplanation: "",
    });
    

    

    console.log("addraaatin", resourceType)
    const handleCancel = () => {
        setUserAddRatingModalVisible(false)
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isUserAddRatingModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={true}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>Add a rating</div>
            <div className='m-t-20' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Rate style={{ fontSize: 30, color: "#FFA931" }} value={params?.ratingValue} onChange={(value) => { console.log("value123", value); setParams((prev) => ({ ...prev, ratingValue: value })) }} allowHalf />
            </div>
            <div className='m-t-20'><TextArea rows={3} value={params?.ratingExplanation} onChange={(e) => setParams((prev) => ({ ...prev, ratingExplanation: e.target.value }))} style={{ resize: 'none', borderRadius: "8px" }} placeholder='Add a review' /></div>
            <center className='m-t-20'>
                {console.log("fromMyCourcesPage",fromMyCourcesPage,resourceType)}
                <Button
                    type="primary"
                    loading={addRatingBtnLoader}
                    onClick={() => {
                        setAddRatingBtnLoader(true);
                        postUsersAddRating(params, () => {
                            setAddRatingBtnLoader(false);
                            setUserAddRatingModalVisible(false);
                            if (fromMyCourcesPage) {
                                getCourseDetails(user?.accessToken, fromMyCourcesPage?.id, () => {
                                });
                            }
                            else if (resourceType == "course") {
                                getLiveCourseDetails(courseId, () => { });
                                
                            }
                            else if (resourceType == "videoResource") {
                                getVideoResourceDetails(courseId, () => { });
                            }
                            else if (resourceType == "testSeries") {
                                getTestSeriesDetails(courseId, () => { });
                            }
                            else {
                                getStudyMaterialDetails(courseId, () => { });
                                getStudyMaterialData(courseId, () => { })

                            }
                        });
                    }}
                   
                    disabled={params.ratingValue === 0}
                >
                    SUBMIT
                </Button>

            </center>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    const { } = state.courseManagement;
    const { user } = state.session
    return { user };
};


const mapDispatchToProps = (dispatch) => ({
    postUsersAddRating: (param, callback) => dispatch(postUsersAddRating(param, callback)),
    getCourseDetails: (token, id, callback) => dispatch(getCourseDetails(token, id, callback)),
    getLiveCourseDetails: (id, callBack) => dispatch(getLiveCourseDetails(id, callBack)),
    getVideoResourceDetails: (id, callback) => dispatch(getVideoResourceDetails(id, callback)),
    getStudyMaterialDetails: (id, callback) => dispatch(getStudyMaterialDetails(id, callback)),
    getStudyMaterialData: (id,callback) => dispatch(getStudyMaterialData(id,callback)),
    getTestSeriesDetails: (id, callback) => dispatch(getTestSeriesDetails(id, callback))

});
export default connect(mapStateToProps, mapDispatchToProps)(UserAddRatingModal)