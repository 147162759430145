import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import { Button } from 'components/Button';
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import { getAttemptQuizData, postSubmitQuizData, setQuizFlowCount, setQuizSavedData } from 'actions/studentMarketPlace'
import ReactHtmlParser from "react-html-parser";
import TeX from "@matejmazur/react-katex";
import { Notification } from 'services';

export const HtmlComponent = ({ item, toggleImageModal, setCurrentImg }) => {
    return ReactHtmlParser(item, {
        transform: (node) => {
            if (
                node.type === "tag" &&
                node.name === "span" &&
                node.attribs["data-value"]
            ) {
                return <TeX math={`${node.attribs["data-value"]}`} />;
            }
            if (node.type === "tag" && node.name === "img") {
                return (
                    <img
                        alt="question"
                        style={{ maxHeight: "160px", maxWidth: "160px" }}
                        src={node.attribs.src}
                        className="m-t-10 m-r-10 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleImageModal(true);
                            setCurrentImg(node.attribs.src);
                        }}
                    />
                );
            }
        },
    });
};

function QuizDesign({ quizItems, savedQuizData, setQuizSavedData, studyKey, paramAttemptQuiz, setQuizFlowCount, postSubmitQuizData, getAttemptQuizData, courseQuizQuestions }) {

    // const quizQuestions = quizItems?.sections
    const [selectedOption, setSelectedOption] = useState(null);
    const [step, setStep] = useState(0);
    const [selected, setSelected] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [pageLoader, togglePageLoader] = useState(false);
    const initialParamsQuestion = courseQuizQuestions?.questions?.map(() => ({
        id: null,
        attempt: { answer: null }
    })) || [];
    const [paramsQuestion, setParamsQuestion] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState(Array(courseQuizQuestions?.questions?.length).fill([]));
    const nonNullParams = paramsQuestion?.filter((param) => param?.id !== null && param?.attempt?.answer !== null);
    useEffect(() => {
        togglePageLoader(true);
        getAttemptQuizData(paramAttemptQuiz?.id, paramAttemptQuiz?.itemId, paramAttemptQuiz?.type, () => {
            // setQuizFlowCount(1);
            togglePageLoader(false);
        })
    }, [])

    useEffect(() => {
        setParamsQuestion(courseQuizQuestions?.questions?.map(() => ({
            id: null,
            attempt: { answer: null }
        })) || []);
    }, [courseQuizQuestions]);

    const handleSubmit = (id) => {
        togglePageLoader(true);
        postSubmitQuizData(nonNullParams, paramAttemptQuiz, () => {
            togglePageLoader(false)
        });
    }

    const handleNext = () => {
        if (step < courseQuizQuestions?.questions?.length - 1) {
            setStep(step + 1);
            setSelected(false)
            setCurrentIndex((prevIndex) => (prevIndex + 1 < courseQuizQuestions?.questions.length ? prevIndex + 1 : prevIndex));
        }
    };

    const handlePrevious = () => {
        if (step > 0) {
            setStep(step - 1);
            setCurrentIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
        }
    };

    const handleOptionClick = (index, selectedOptionItem, newId, dataList) => {
        setParamsQuestion((prevParams) =>
            prevParams?.map((param, paramIndex) => {
                if (paramIndex === currentIndex) {
                    let newIdValue, newAttemptValue;

                    switch (dataList?.type) {
                        case 'MCQ':
                            newIdValue = newId !== null ? newId : param?.id;
                            newAttemptValue = selectedOptionItem !== null ? { answer: selectedOptionItem } : param?.attempt;
                            // setSelectedOption(selectedOptionItem);
                            break;

                        case 'MSQ':
                            const selectedAnswers = selectedOptions[currentIndex] || [];
                            const newSelectedAnswers = selectedAnswers.includes(selectedOptionItem)
                                ? selectedAnswers.filter((option) => option !== selectedOptionItem)
                                : [...selectedAnswers, selectedOptionItem];

                            setSelectedOptions((prevSelectedOptions) => {
                                const updatedSelectedOptions = [...prevSelectedOptions];
                                updatedSelectedOptions[currentIndex] = newSelectedAnswers;
                                return updatedSelectedOptions;
                            });

                            newIdValue = newId;
                            newAttemptValue = { answer: newSelectedAnswers };
                            break;

                        case 'T/F':
                            newIdValue = newId;
                            newAttemptValue = { answer: selectedOptionItem === 0 ? false : true };
                            break;

                        default:
                            break;
                    }

                    return { ...param, id: newIdValue, attempt: newAttemptValue };
                }
                return param;
            })
        );
    };

    const Questions = ({ dataList, index, selectedOptionItem, onOptionClick }) => {
        return (
            <div className={`${studyKey ? "quiz-question-study-container" : "quiz-question-container"} scroll-bar-universal`} style={{height:340, overflowX: 'hidden'}}>
                <div className='r-c-fe m-r-15 font-bold' style={{ color: '#594099' }}>
                    {dataList?.positiveMarks}&nbsp;Marks
                </div>
                <div className='' style={{ fontSize: "13px", display: "flex", justifyContent: "flex-start" }}>
                    <span className='m-r-10'>{[index + 1]}.</span><HtmlComponent
                        item={dataList?.questionContent?.statement}
                        toggleImageModal={() => { }}
                        setCurrentImg={() => { }}
                    />

                </div>
                <div style={{height:"200px"}} >
                    {dataList?.questionContent?.options?.map((option, optionIndex) => {
                        return (
                            <div
                                key={optionIndex}
                                className={`r-c-fs p-15 cursor-pointer ${studyKey ? "option-1" : "option"}  m-l-20 ${dataList?.type === 'MSQ'
                                    ? selectedOptions[currentIndex]?.includes(optionIndex)
                                        ? (studyKey ? 'selected-1' : 'selected-op')
                                        : ''
                                    : selectedOptionItem === optionIndex
                                        ? (studyKey ? 'selected-1' : 'selected-op')
                                        : ''
                                    }`}
                                onClick={() => { onOptionClick(index, optionIndex, dataList?.id, dataList), window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }}
                            >
                                <div style={{ zIndex: 1, color: "#191919", fontSize: "13px" }}>
                                    <HtmlComponent
                                        item={option?.value}
                                        toggleImageModal={() => { }}
                                        setCurrentImg={() => { }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    {(!dataList?.questionContent?.options || dataList?.questionContent?.options?.length === 0) &&
                        dataList?.type === 'T/F' && (
                            <div>
                                <div
                                    className={`r-c-fs p-15 cursor-pointer ${studyKey ? "option-1" : "option"}  m-l-20 ${selectedOptionItem === true ? (studyKey ? 'selected-1' : 'selected-op') : ''}`}
                                    onClick={() => onOptionClick(0, 1, dataList?.id, dataList)}
                                >
                                    True
                                </div>
                                <div
                                    className={`r-c-fs p-15 cursor-pointer ${studyKey ? "option-1" : "option"}  m-l-20 ${selectedOptionItem === false ? (studyKey ? 'selected-1' : 'selected-op') : ''}`}
                                    onClick={() => onOptionClick(1, 0, dataList?.id, dataList)}
                                >
                                    False
                                </div>
                            </div>
                        )}
                </div>
            </div>
        )
    }

    const handleSaveOptions = () => {
        // setParamsQuestion()
        setQuizSavedData(paramsQuestion)
        Notification.success("success", 'Answers saved successfully.');

    }

    return (
        <>
            <div className={`${studyKey ? 'study-quiz-container' : 'quiz-container'}`}>
                <div style={{ margin: '10px 10px', height: '70%' }}>
                    <div className='font-bold' style={{ fontSize: "14px" }}>{quizItems?.name}</div>
                    <div className='r-c-sa'>
                        {courseQuizQuestions?.questions?.map((item, index) => (
                            <div key={index} style={{ width: '100%', margin: '10px 0px' }}>
                                <div className={studyKey ? "steps-1" : 'steps'} style={{ backgroundColor: step >= index ? '#594099' : '#E6E6E6' }}>{/* Add your content for each step here */}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ height: '100%' }}>
                        {courseQuizQuestions?.questions?.length > 0 && (
                            <Questions
                                dataList={courseQuizQuestions?.questions[currentIndex]}
                                index={currentIndex}
                                selectedOptionItem={paramsQuestion[currentIndex]?.attempt?.answer}
                                onOptionClick={handleOptionClick}
                            />
                        )}

                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between",padding:10,marginTop:"75px"}} >
                    <div  onClick={handlePrevious}><Button disabled={step === 0 ? true : false} className=''>PREVIOUS</Button></div>
                    {step < courseQuizQuestions?.questions?.length - 1 ? <>
                        <div >
                            {selected ?
                                <Button style={{ backgroundColor: "#594099", color: "#FFFFFF", border: "1px solid #594099" }} onClick={handleNext}>SAVE & NEXT</Button>
                                :
                                <Button style={{ backgroundColor: "#594099", color: "#FFFFFF", border: "1px solid #594099" }} onClick={handleNext}>NEXT</Button>
                            }
                        </div>
                    </> : <>
                        <div >
                            {/* <div><Button className='' style={{ marginRight: "20px" }} onClick={() => handleSaveOptions()} >SAVE</Button></div> */}
                            <div><Button className='' style={{ backgroundColor: "#594099", color: "#FFFFFF", border: "1px solid #594099"}} onClick={() => { handleSubmit(courseQuizQuestions?.questions[currentIndex]?.id) }}>SUBMIT</Button></div>
                        </div>
                    </>}
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </>
    );
}

const mapStateToProps = (state) => {
    const { myVideoResources, courseQuizQuestions, paramAttemptQuiz, savedQuizData } = state.studentMarketPlace
    return {
        myVideoResources, courseQuizQuestions, paramAttemptQuiz, savedQuizData
    }
};

const mapDispatchToProps = (dispatch) => ({
    getAttemptQuizData: (id, itemId, type, callback) => dispatch(getAttemptQuizData(id, itemId, type, callback)),
    postSubmitQuizData: (paramsQuestion, paramAttemptQuiz, callback) => dispatch(postSubmitQuizData(paramsQuestion, paramAttemptQuiz, callback)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
    setQuizSavedData: (val) => dispatch(setQuizSavedData(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizDesign)
