import { useEffect, useRef, useState } from 'react'
import { computePointInCanvas } from './utils';
let mouseDownPoint = {x:0, y:0};
let initialTouches = [];
let lastPinchDistance = 0;
let numberoftouches = 0;
let numberofchangedtouches = 0;
let areaofTouch = 0;
let MAX_TOUCH_AREA = 10;
let mouseDown = false
let pointermovefired = false;
let isTouchStarted = false;


export const useCanvas = (onDraw, onPointerDown, onPointerUp, offsets, onMousePan, touchotherhandler, platform, onMousePinch, mode) => {
  MAX_TOUCH_AREA = platform==='ios'?10000:10
  const scale = offsets?.zoomLevel;
  const scaleOffset = offsets?.scaleOffset;
  const panOffset = offsets?.panOffset;

  const canvasRef = useRef(null)
  const overlayRef = useRef(null);
  const prevPoint = useRef(null)

  const onMouseDown = (e) => {
    numberoftouches = 1
    mouseDownPoint = {x: e.clientX, y:e.clientY};
    mouseDown = true
    // mouseDownHandler(e)
  }

  const clear = () => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  const clearOverlay = () => {
    const canvas = overlayRef.current
    if (!canvas) return

    const ctxo = canvas.getContext('2d')
    if (!ctxo) return

    ctxo.clearRect(0, 0, canvas.width, canvas.height)
  }

  useEffect(() => {
    const handler = (e) => {
      if (!mouseDown) return
      if (numberoftouches>1||areaofTouch>MAX_TOUCH_AREA) return
      pointermovefired = true
      const currentPoint = computePointInCanvas(e, panOffset, scaleOffset, scale)

      const ctx = canvasRef.current?.getContext('2d')
      const ctxo = overlayRef.current?.getContext('2d')
      if (!ctx || !currentPoint) return

      onDraw({ ctx, currentPoint, prevPoint: prevPoint.current, ctxo })
      prevPoint.current = currentPoint
    }


    const wheelHandler = (e) => {
      e.preventDefault();
     const currentPoint = computePointInCanvas(e, panOffset, scaleOffset, scale)
     const ctx = canvasRef.current?.getContext('2d')
     const ctxo = overlayRef.current?.getContext('2d')
     if (!ctx || !currentPoint) return
     if (e.ctrlKey){
       currentPoint.delta = currentPoint.deltaY;
       onMousePinch({ ctx, currentPoint, prevPoint: prevPoint.current, ctxo })
     }else{
       onMousePan({ ctx, currentPoint, prevPoint: {...prevPoint.current}, ctxo })
     }
     prevPoint.current = currentPoint
   }

    const pointerUpHandler = (e) => {
      pointermovefired = false
      isTouchStarted = false
      if (numberoftouches>1||areaofTouch>MAX_TOUCH_AREA) return
      const ctx = canvasRef.current?.getContext('2d')
      const ctxo = overlayRef.current?.getContext('2d')
      const currentPoint = computePointInCanvas(e, panOffset, scaleOffset, scale)
      mouseDown = false
      prevPoint.current = null
      onPointerUp({ctx, currentPoint, ctxo})
      numberoftouches = 0;
      numberofchangedtouches = 0;
      areaofTouch = 0
    }
    const mouseLeaveHandler = (e) => {
      mouseDown = false
      // pointerUpHandler(e)
    }
    function pointerDownWrapper(e){
      // this is the only latest pointer related change
      if (!isTouchStarted){
        pointerDownHandler(e)
      }
    }
    function pointerDownHandler(e){
      isTouchStarted = true
      if (numberoftouches>1||areaofTouch>MAX_TOUCH_AREA) return
      const ctx = canvasRef.current?.getContext('2d')
      const ctxo = overlayRef.current?.getContext('2d')
      const currentPoint = computePointInCanvas(e, panOffset, scaleOffset, scale)
      mouseDown = true
      onPointerDown({ctx, currentPoint, ctxo});
    }

    const touchStartHandler = (event) => {
      if (mode==='move'){
        if (event.touches.length===2){
          initialTouches = [{
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
        }];
        }
        return
      }
      numberoftouches = event.touches.length
      if (numberoftouches>1){
        if (numberoftouches==2){
          const ctx = canvasRef.current?.getContext('2d')
          const ctxo = overlayRef.current?.getContext('2d')
          const currentPoint = computePointInCanvas(event, panOffset, scaleOffset, scale)
          onPointerUp({ctx, currentPoint, ctxo, unintended:true})
        }else{

        }
        return
      }
      numberofchangedtouches = event.changedTouches.length
      areaofTouch = Math.PI * event.touches[0].radiusX * event.touches[0].radiusY;
      touchotherhandler(event)
      pointerDownHandler(event)
    }

    const touchMoveHandler = (event) => {
      if (mode==='move'){
        if (event.touches.length===2){
          const touch1 = event.touches[0];
          const touch2 = event.touches[1];
  
          // Calculate the distance between the two touches
          const distance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
          if (lastPinchDistance === 0) {
            lastPinchDistance = distance;
            return;
          }
          const currentPoint = {};
          const delta = distance-lastPinchDistance;
          currentPoint.delta = -delta
          const ctx = canvasRef.current?.getContext('2d')
          const ctxo = overlayRef.current?.getContext('2d')
          if (!ctx || !currentPoint) return
          onMousePinch({ ctx, currentPoint, prevPoint: prevPoint.current, ctxo })
          lastPinchDistance = distance;
          prevPoint.current = currentPoint
        }else if(event.touches.length===1){
          const currentTouches = [{
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
          }];
          if (initialTouches.length===0){
            const point = {x: currentTouches[0].x-1,
                           y: currentTouches[0].y-1}
            initialTouches = [point]
          }
          let midx = currentTouches[0].x
          let midy = currentTouches[0].y
          let omidx = initialTouches[0].x
          let omidy = initialTouches[0].y
          let deltaX = omidx - midx
          let deltaY = omidy - midy
          const currentPoint = {deltaX, deltaY}
          const ctx = canvasRef.current?.getContext('2d')
          const ctxo = overlayRef.current?.getContext('2d')
          if (!ctx || !currentPoint) return
          onMousePan({ ctx, currentPoint, prevPoint: prevPoint.current, ctxo })
          initialTouches = currentTouches
          prevPoint.current = currentPoint
        }
      }else{
      touchotherhandler(event)
      areaofTouch = Math.PI * event.touches[0].radiusX * event.touches[0].radiusY;
      numberoftouches = event.touches.length
      numberofchangedtouches = event.changedTouches.length
      if (!pointermovefired){
        handler(event);
      }
      }
    }

    const touchEndHandler = (e) => {
      if (mode==='move'){
        if (e.touches.length===2){
          lastPinchDistance = 0
          initialTouches = [];
        }else{
          initialTouches = [];
          lastPinchDistance = 0
        }
        return
      }
      isTouchStarted = false
      touchotherhandler(e)
      areaofTouch = 0
      numberoftouches = e.touches.length
      numberofchangedtouches = e.changedTouches.length
      pointermovefired = false
    }
    const touchendhandlerwrap = (e) => {
      touchEndHandler(e)
    }

    const touchcancelwrap = (e) => {
      touchEndHandler(e)
    }

    const onmousedown = (e) => {
      initialTouches = []
      mouseDown = true
    }

    const onmouseup = (e) => {
      initialTouches = []
      mouseDown = false
    }
    const onmousemove = (e) => {
      if (!mouseDown) return
      const currentTouches = [{
        x: e.clientX,
        y: e.clientY
      }];
      if (initialTouches.length===0){
        const point = {x: currentTouches[0].x-1,
                       y: currentTouches[0].y-1}
        initialTouches = [point]
      }
      let midx = currentTouches[0].x
      let midy = currentTouches[0].y
      let omidx = initialTouches[0].x
      let omidy = initialTouches[0].y
      let deltaX = omidx - midx
      let deltaY = omidy - midy
      const currentPoint = {deltaX, deltaY}
      const ctx = canvasRef.current?.getContext('2d')
      const ctxo = overlayRef.current?.getContext('2d')
      if (!ctx || !currentPoint) return
      onMousePan({ ctx, currentPoint, prevPoint: prevPoint.current, ctxo })
      initialTouches = currentTouches
      prevPoint.current = currentPoint
    }

    // Add event listeners
    overlayRef.current?.addEventListener('pointerup', pointerUpHandler)
    overlayRef.current?.addEventListener('mouseleave', mouseLeaveHandler)
    overlayRef.current?.addEventListener('touchend', touchendhandlerwrap)
    overlayRef.current?.addEventListener('touchcancel', touchcancelwrap)
    overlayRef.current?.addEventListener("mousemove", onmousemove);
    overlayRef.current?.addEventListener("mousedown", onmousedown);
    overlayRef.current?.addEventListener("mouseup", onmouseup);

    overlayRef.current?.addEventListener('wheel', wheelHandler, {passive: false})

    overlayRef.current?.addEventListener('pointermove', handler)
    overlayRef.current?.addEventListener('touchmove', touchMoveHandler)

    overlayRef.current?.addEventListener('pointerdown', pointerDownWrapper)
    overlayRef.current?.addEventListener('touchstart', touchStartHandler)


    // Remove event listeners
    return () => {
      overlayRef.current?.removeEventListener('pointerup', pointerUpHandler)
      overlayRef.current?.removeEventListener('mouseleave', mouseLeaveHandler)
      overlayRef.current?.removeEventListener('touchend', touchendhandlerwrap)
      overlayRef.current?.removeEventListener('touchcancel', touchcancelwrap)
      overlayRef.current?.removeEventListener('wheel', wheelHandler)
      overlayRef.current?.removeEventListener('pointermove', handler)
      overlayRef.current?.removeEventListener('touchmove', touchMoveHandler)
      overlayRef.current?.removeEventListener("mousemove", onmousemove);
      overlayRef.current?.removeEventListener("mousedown", onmousedown);
      overlayRef.current?.removeEventListener("mouseup", onmouseup);

      overlayRef.current?.removeEventListener('pointerdown', pointerDownWrapper)
      overlayRef.current?.removeEventListener('touchstart', touchStartHandler)
    }
  }, [onDraw, onPointerDown, onPointerUp, scale, scaleOffset, panOffset])

  return { canvasRef, overlayRef, onMouseDown, clear, mouseDownPoint, clearOverlay }
}