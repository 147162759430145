import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { fetchBuyStorage, postStorageUpgrade } from 'actions/digitalLibrary';
import { Alert, Input, Modal, Typography } from 'antd';
import { Button } from 'components/Button';
import moment from 'moment';
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { connect } from 'react-redux';

const SubscriptionModal = ({ isModalVisible, setModalVisible,storeSubscriptionPlanData, subscriptionDetails, setPageLoader, postStorageUpgrade, currentPlan, fetchBuyStorage }) => {
  console.log("AA-storeSubscriptionPlanData1",storeSubscriptionPlanData);
    const handleCancle = () => {
        // e.stopPropagation();
        setModalVisible(false)
    }

    const [price, setPrice] = useState(null)
    const [billingDate, setBillingDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        // if (currentPlan && Object.keys(currentPlan)?.length != 0) {
        //     setPrice(parseFloat(parseFloat(subscriptionDetails?.price) - (parseFloat((parseFloat(currentPlan?.price) / 12) * parseFloat((moment(currentPlan?.endDate, 'YYYY-MM-DD').diff(moment(), 'month', true)).toFixed(2))).toFixed(2))))
        //     setBillingDate(moment(currentPlan?.endDate).add(1, 'day').format('DD MMMM YYYY'))
        //     setEndDate(moment(currentPlan?.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))
        // }
        // else {
        //     setPrice(parseFloat(subscriptionDetails?.price))
        //     setBillingDate(moment().add(1, 'year').add(1, 'day').format('DD MMMM YYYY'))
        //     setEndDate(moment().add(1, 'year').format('YYYY-MM-DD'))
        // }
        // console.log()
        document.title = "aulas";
        const razorpayScript = document.createElement("script");
        razorpayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(razorpayScript);
    }, [])

    const successCallback = () => {
        setModalVisible(false)
        fetchBuyStorage(() => setPageLoader(false))
    }



    const openRazorPay = useCallback(

        (space, price, endDate) => {

            let options = {

                // "key": "rzp_live_rNxcoMgwpEVaxG",

                // "key_secret": "22RPEmRHUgNCZTpepDNreYz7",

                // "key": "rzp_test_rpXWPPV6VYnJyg",
                // "entity": "invoice",
                "key": "rzp_live_gzJ7uU1TrD46MO",
                "key_secret": "3xo1aQGRsg9LK05gpJvcLSUs",

                "amount": parseInt((parseFloat(subscriptionDetails?.price) + parseFloat(subscriptionDetails?.price) * 0.18)).toFixed(2) * 100, // 2000 paise = INR 20, amount in paisa

                "name": "aulas",

                "description": "aulas Storage Annual Subscription",

                "curreny": "INR",

                "handler": function (response) {

                    if (response.razorpay_payment_id) {

                        // self.submit(response.razorpay_payment_id);

                        let object = {
                            storage: space,
                            finalCost: parseFloat((parseFloat(storeSubscriptionPlanData?.price) + parseFloat(storeSubscriptionPlanData?.price) * 0.18)).toFixed(2),
                            tax: (parseFloat(storeSubscriptionPlanData?.price) * 0.18).toFixed(2),
                            planCost: storeSubscriptionPlanData?.price,
                            discount: 0,
                            netCostExTaxes: 0,
                            endDate: storeSubscriptionPlanData?.expiryDate,
                            razorpay_payment_id:response.razorpay_payment_id
                        }
                        setPageLoader(true)
                        setModalVisible(false)
                        postStorageUpgrade(object, () => setPageLoader(false), successCallback)
                        console.log('res-razor', response);
                    }


                },

                // "prefill": {

                // "name": "kooljit",

                // "email": "kooljit.das@brigosha.com"

                // },

                "theme": {

                    // "color": Color.green28

                }

            };

            let rzp = new window.Razorpay(options);

            rzp.open();

        },

        [],

    )

    return (
        <Modal
            className="subscriptionLibrary"
            visible={isModalVisible}
            closable={true}
            footer={false}
            onCancel={() => handleCancle()}
            maskClosable={false}
        >

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='text-heading-library' style={{ width: '100%', textAlign: 'center', fontSize: '18px', marginTop: '10px', marginBottom: '10px' }}>
                    Plan Details
                </div>
                <div className='text-heading-library' style={{ fontSize: '24px' }}>
                    {subscriptionDetails?.space}
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginBottom: '15px', marginTop: '5px' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography.Text style={{ fontSize: '13px', color: '#191919', fontWeight: '500' }}>Starting today</Typography.Text>
                        <Typography.Text style={{ fontSize: '13px', color: '#191919', fontWeight: '500' }}>₹ {storeSubscriptionPlanData?.price}/ Year</Typography.Text>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography.Text style={{ fontSize: '13px', color: '#191919', fontWeight: '500' }}>Next billing date</Typography.Text>
                        <Typography.Text style={{ fontSize: '13px', color: '#191919', fontWeight: '500' }}>{(moment(storeSubscriptionPlanData?.expiryDate).format('DD-MM-YYYY'))}</Typography.Text>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} >
                    <Typography.Text className='text-content-library'>Amount details</Typography.Text>
                    <div style={{ width: '100%', padding: '10px', border: '1px solid #E6E6E6', borderRadius: '14px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <Typography.Text style={{ fontSize: '13px', color: '#191919', fontWeight: '500' }}>Subtotal</Typography.Text>
                            <Typography.Text style={{ fontSize: '13px', color: '#191919', fontWeight: '500' }}>₹ {storeSubscriptionPlanData?.price}</Typography.Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <Typography.Text className='text-content-library' style={{ fontSize: '13px' }}>IGST</Typography.Text>
                            <Typography.Text className='text-content-library' style={{ fontSize: '13px' }}>+ ₹ {parseFloat((0.18 * parseFloat(storeSubscriptionPlanData?.price)).toFixed(2))}</Typography.Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderTop: '1px solid #E6E6E6', borderBottom: '1px solid #E6E6E6', marginBottom: '5px' }}>
                            <Typography.Text style={{ fontSize: '14px', color: '#191919', fontWeight: '600' }}>Total payable</Typography.Text>
                            <Typography.Text style={{ fontSize: '14px', color: '#191919', fontWeight: '600' }}>₹ {parseFloat(parseFloat(1.18 * parseFloat(storeSubscriptionPlanData?.price)).toFixed(2))}</Typography.Text>
                        </div>
                    </div>
                </div>
                <div>
                    <Alert message="You will have to renew the subscription  after 1 year" showIcon icon={<InfoCircleOutlined />} style={{ backgroundColor: '#FFFFFF', color: '#636363', border: 'none', fontSize: '11px' }} />
                </div>
                <center>
                <div className='r-c-sb' style={{display:"flex", width: '70%',marginBottom: '10px', marginTop: '10px' }}>
                    <Button type='default' style={{  width: '120px', marginRight: '5px' }} onClick={() => handleCancle()}>Cancel</Button>
                    <Button type='primary' style={{  width: '120px', marginLeft: '5px' }} onClick={() => openRazorPay(subscriptionDetails?.space, storeSubscriptionPlanData?.price, storeSubscriptionPlanData?.expiryDate)}>Pay</Button>
                </div>
                </center>
            </div>



        </Modal >
    )
}


const mapStateToProps = (state) => {
    const {
    } = state.digitalLibrary;
    const { user } = state.session;
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchBuyStorage: (callback) => dispatch(fetchBuyStorage(callback)),
    postStorageUpgrade: (object, callback, successCallback) => dispatch(postStorageUpgrade(object, callback, successCallback)),
    // setAulasCareChat: (chat) => dispatch(setAulasCareChat(chat))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionModal);