import { getRecordings, setCompletedSubName } from 'actions/broadcast'
import { fetchScheduleFilterTeacherandStudent, fetchScheduleTeacher, setTeacherDate, setPageToggle, setJoinLoader, setCardData } from 'actions/schedule'
import { DatePicker, PageHeader, Input, Image, Typography, Select, Button } from 'antd'
import PageDataLoader from 'components/pageDataLoader'
import moment from 'moment'
import RecordingsModal from 'pages/broadcast/localComponent/recordingsModal'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import emptyImage from '../../Assets/schedule/Group 10647.svg'
import CancelledClassTandS from './cancelledClassTandS'
import CompletedClassTandS from './completedClassTandS'
import OnGoingClassTandS from './onGoingClassTandS'
import ScheduleClassTandS from './scheduleClassTandS'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { EventRegister } from 'react-native-event-listeners'

const { Search } = Input
const { Text } = Typography
const { Option } = Select
const ScheduleTeacher = ({ fetchScheduleTeacher, scheduleList, fetchScheduleFilterTeacherandStudent, stdListFilter,
    getRecordings, setCompletedSubName, setTeacherDate, teacherDate, pageToggle, setPageToggle, setJoinLoader, setCardData }) => {
    const [pageLoader, togglePageLoader] = useState(false);
    const [isRecordingsModalVisible, setRecordingsModalVisibility] = useState(false);
    // const [date, setDate] = useState('')
    const [searchSub, setSearchSub] = useState('')
    const [std, setStd] = useState([])
    const [date, setDate] = useState(null);

    const apilistener = useRef(null);
    useEffect(()=>{
        apilistener.current = EventRegister.addEventListener('apicall:scheduleHome', ()=>{
            togglePageLoader(true)
            fetchScheduleFilterTeacherandStudent(teacherDate?.date, searchSub, null, () => togglePageLoader(false))
        })
        return () => {
            EventRegister.removeEventListener(apilistener.current)
        }
    }, [teacherDate?.date, searchSub]);
    
    useEffect(() => {
        setJoinLoader(false)
        if (pageToggle == '1') {
            togglePageLoader(true)
            fetchScheduleTeacher(() => togglePageLoader(false))
            setTeacherDate('stringDate', moment().format('DD/MM/YYYY'))
        }
        else {
            togglePageLoader(true)
            fetchScheduleFilterTeacherandStudent(teacherDate?.date, searchSub, null, () => togglePageLoader(false))
            setPageToggle('1')
        }
    }, [])

    // useEffect(()=>{
    //     console.log('123456',teacherDate)
    // }, [teacherDate])

    const onChangeDate = (date, dateString) => {
        setDate(dateString)
        togglePageLoader(true)
        fetchScheduleFilterTeacherandStudent(dateString, searchSub, null, () => togglePageLoader(false))
    }
    const onSearchSubject = (value) => {
        setSearchSub(value)
        fetchScheduleFilterTeacherandStudent(date, value, std)
    }

    const onchangeClass = (value) => {
        setStd(value)
        fetchScheduleFilterTeacherandStudent(date, searchSub, value)
    }
    const handleReplay = (streamId, subject, completedItem) => {
        setCardData(completedItem)
        setCompletedSubName(subject.name);
        getRecordings(streamId, () => {
            setRecordingsModalVisibility(true);
        });
    }
    const location = useLocation()
    const history = useHistory()
    return (
        <div>
            <div className="ScheduleTeacherHeaders">
                {location?.state?.key ?
                    <div style={{ width: '40%' }}>
                        <PageHeader
                            title={<div className="text-md" style={{ fontWeight: "bold", fontSize: "8px !important" }}>Schedule</div>}
                            onBack={() => { history.goBack(); }}
                        />
                    </div>
                    :
                    <div style={{ width: '40%' }}>
                        <PageHeader
                            title={<div className="text-md" style={{ fontWeight: "bold", fontSize: "8px !important" }}>Schedule</div>}
                        />
                    </div>
                }
           
                <div className="ScheduleTeacher-datepicker-search">
                    <DatePicker style={{ width: '30%' }} onChange={onChangeDate} value={date ? moment(date, 'DD/MM/YYYY') : moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')} format={'DD/MM/YYYY'} />
                    <Input.Search size={"middle"} type="test" placeholder="Search by Subject"
                        style={{ border: "0px", fontSize: "1em", marginLeft: '30px', width: '30%' }}
                        allowClear onSearch={onSearchSubject}
                    />
                    <Select
                        mode="multiple"
                        placeholder='Select Class'
                        showSearch
                        allowClear={true}
                        style={{ width: "40%", marginLeft: '30px', height: '32px' }}
                        optionFilterProp="children"
                        onChange={onchangeClass}
                        filterOption={true}
                    >
                        {stdListFilter?.length != 0 ? stdListFilter.map((item, index) => (
                            <Option value={item.id}>{item.std + '-' + item.section}</Option>
                        ))
                            : null}
                    </Select>
                </div>
            </div>
            {scheduleList?.ongoing?.length === 0 && scheduleList?.scheduled?.length === 0 && scheduleList?.completed?.length === 0 && scheduleList?.cancelled?.length === 0 ?
                <div className="ScheduleTeacherContentMain">
                    <div className="ScheduleTeacherContentEmpty">
                        <Image src={emptyImage} preview={false} />
                        <Text style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'roboto' }}>No live classes scheduled!</Text>
                        <Text style={{ color: "#636363", fontSize: '14px', fontFamily: 'roboto', textAlign: 'center', marginBottom: '8px' }}>There are no live classes scheduled yet.</Text>
                    </div>
                </div> :
                <div>
                    {scheduleList?.ongoing?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Ongoing live classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.ongoing?.map((item) => (<OnGoingClassTandS onGoingItem={item} />))}
                            </div>
                        </div>
                        : null}
                    {scheduleList?.scheduled?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Schedule classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.scheduled?.map((item) => (<ScheduleClassTandS scheduleItem={item} />))}
                            </div>
                        </div>
                        : null}
                    {scheduleList?.completed?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Completed classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.completed?.map((item) => (<CompletedClassTandS completedItem={item} handleReplay={handleReplay} />))}
                            </div>
                        </div>
                        : null}
                    {scheduleList?.cancelled?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Cancelled classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.cancelled?.map((item) => (<CancelledClassTandS cancelledItem={item} />))}
                            </div>
                        </div>
                        : null}
                </div>

            }
            <PageDataLoader visible={pageLoader} />
            <RecordingsModal isRecordingsModalVisible={isRecordingsModalVisible}
                setRecordingsModalVisibility={setRecordingsModalVisibility} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        scheduleList,
        stdListFilter,
        teacherDate,
        pageToggle
    } = state.schedule;
    return {
        scheduleList,
        stdListFilter,
        teacherDate,
        pageToggle
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchScheduleTeacher: (callback) => dispatch(fetchScheduleTeacher(callback)),
    fetchScheduleFilterTeacherandStudent: (date, search, classIds, callback) => dispatch(fetchScheduleFilterTeacherandStudent(date, search, classIds, callback)),
    getRecordings: (streamId, callback) => dispatch(getRecordings(streamId, callback)),
    setCompletedSubName: (name) => dispatch(setCompletedSubName(name)),
    setTeacherDate: (key, value) => dispatch(setTeacherDate(key, value)),
    setPageToggle: (value) => dispatch(setPageToggle(value)),
    setJoinLoader: (bool) => dispatch(setJoinLoader(bool)),
    setCardData: (val) => dispatch(setCardData(val))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ScheduleTeacher);
