import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { openDB, deleteDB } from 'idb';
import fixWebmDuration from 'webm-duration-fix';

export default class BrowserRecorder
{
	constructor()
	{

		// MediaRecorder
		this.recorder = null;
		this.recordingMimeType = null;
		this.recordingData = [];
		this.recorderStream = null;
		this.gdmStream = null;
		this.fileName = 'default';

		// IndexedDB
		this.idbDB = null;
		this.logToIDB = true;
		this.idbName = 'default';
		this.idbStoreName = 'chunks';

		this.RECORDING_SLICE_SIZE = 10000;
	}
	mixer(audiotrack, videotrack){
        return new MediaStream([
            audiotrack,
            videotrack,
          ]);
	}

	async startLocalRecording({
            additionalVideoTracks,
            additionalAudioTracks, 
            recordingMimeType='video/webm', 
            roomname
		}){

		this.recordingMimeType = recordingMimeType;
		console.log('startLocalRecording()');

		// Check
		if (typeof MediaRecorder === undefined){
			throw new Error('Unsupported media recording API');
		}
		// Check mimetype is supported by the browser
		if (MediaRecorder.isTypeSupported(this.recordingMimeType) === false){
			throw new Error('Unsupported media recording format %O', this.recordingMimeType);
		}

		try{
			// Screensharing
			this.gdmStream = additionalVideoTracks
            console.log("107")
			this.gdmStream.getVideoTracks().forEach((track) => {
				track.addEventListener('ended', (e) => {
					console.log(`gdmStream ${track.kind} track ended event: ${JSON.stringify(e)}`);
					this.stopLocalRecording();
				});
			});

			if (additionalAudioTracks.length>0){
				// add mic track
                console.log("107 tracks found")
				this.recorderStream = this.mixer(additionalAudioTracks[0], this.gdmStream.getVideoTracks()[0]);
				// add other audio tracks
			}else{
                console.log("114 tracks found", additionalAudioTracks)
				this.recorderStream = this.mixer(null, this.gdmStream);
			}

			const dt = new Date();
			const rdt = `${dt.getFullYear() }-${ (`0${ dt.getMonth()+1}`).slice(-2) }-${ (`0${ dt.getDate()}`).slice(-2) }_${dt.getHours() }_${(`0${ dt.getMinutes()}`).slice(-2) }_${dt.getSeconds()}`;
            console.log("118", this.recorderStream)
			this.recorder = new MediaRecorder(
				this.recorderStream, { mimeType: this.recordingMimeType }
			);

			const ext = this.recorder.mimeType.split(';')[0].split('/')[1];
            this.startTime = Date.now();

			this.fileName = `${roomname}-recording-${rdt}.${ext}`;

			if (typeof indexedDB === 'undefined' || typeof indexedDB.open === 'undefined'){
				console.warn('IndexedDB API is not available in this browser. Fallback to ');
				this.logToIDB = false;
			}
			else{
				this.idbName = roomname;
				const idbStoreName = this.idbStoreName;

				this.idbDB = await openDB(this.idbName, 1, {
						upgrade(db) {
							db.createObjectStore(idbStoreName);
						}
					}
				);
			}

			let chunkCounter = 0;

			// Save a recorded chunk (blob) to indexedDB
			const saveToDB = async (data) => {
                let fData = {groupId: this.startTime, data: data}
				return await this.idbDB.put(this.idbStoreName, fData, Date.now());
			};

			if (this.recorder){
				this.recorder.ondataavailable = (e) => {
                    console.log("data available", e)
					if (e.data && e.data.size > 0){
						chunkCounter++;
						console.log(`put chunk: ${chunkCounter}`);
						if (this.logToIDB){
							try {
								saveToDB(e.data);
							}catch (error){
								console.error('Error during saving data chunk to IndexedDB! error:%O', error);
							}
						}else{
                            console.log("logtoidb false")
							this.recordingData.push(e.data);
						}
					}
				};

                this.recorder.onstart = (e) => {
                    console.log("localrecording started", e)
                }

				this.recorder.onerror = (error) => {
					console.error(`Recorder onerror: ${error}`);
				};

				this.recorder.onstop = (e) => {
					console.log(`Logger stopped event: ${e}`);
				};
                console.log("211")
				this.recorder.start(this.RECORDING_SLICE_SIZE);
				// abort so it dose not look stuck

				window.onbeforeunload = () => {
					if (this.recorder !== null) {
						this.stopLocalRecording();
						// evt.returnValue = 'Are you sure you want to leave? Recording in process';
					}
				};
			}
		}
		catch (error){
			console.error('startLocalRecording() [error:"%o"]', error);

			if (this.recorder) this.recorder.stop();
			if (typeof this.gdmStream !== 'undefined' && this.gdmStream && typeof this.gdmStream.getTracks === 'function'){
				// this.gdmStream.getTracks().forEach((track) => track.stop());
			}
			this.gdmStream = null;
			this.recorderStream = null;
			this.recorder = null;
			return -1;
		}
	}
	// eslint-disable-next-line no-unused-vars
	async stopLocalRecording()
	{
		console.log('stopLocalRecording()');
		try{
			this.recorder.stop();
		}catch (error){
		    console.error('stopLocalRecording() [error:"%o"]', error);
		}
	}
	async pauseLocalRecording(){
		this.recorder.pause();
	}
	async resumeLocalRecording(){
		this.recorder.resume();
	}
    getBlobs(key){
        let thiz = this;
        return new Promise((resolve, reject)=>{
            thiz.idbDB.get(thiz.idbStoreName, key).then((blob) => resolve(blob));
        })
    }
    async getBlobs2(array){
        const groupedData = {};
  
        array.forEach(({ groupId, data }) => {
          if (!groupedData[groupId]) {
            groupedData[groupId] = [];
          }
          groupedData[groupId].push(data);
        });
      
        // Step 2: Create provisional blobs for each group
        const provisionalBlobs = [];
        for (const groupId in groupedData) {
          const blobs = groupedData[groupId];
          const fixBlob = await fixWebmDuration(new Blob(blobs, { type: this.recordingMimeType }));
          provisionalBlobs.push(fixBlob);
        }
        const zip = new JSZip();
        provisionalBlobs.forEach((blob, index) => {
            zip.file(`${this.fileName}_${index}.mp4`, blob);
        });

        // Step 4: Generate the zip file and save it
        zip.generateAsync({ type: 'blob' }).then((content) => {
            // saveAs(content, 'videos.zip');
            console.log("content generated")
            saveAs(content, `${this.fileName}.zip`);
        });
    }
    async isDatabasePresent(dbName) {
        try {
          const databases = await indexedDB.databases();
          return databases.some(db => db.name === dbName);
        } catch (error) {
          console.error("Error checking databases:", error);
          return false;
        }
      }
    async checkLocalRecordingsAvailable(roomName){
        return new Promise((resolve)=>{
            this.isDatabasePresent(roomName).then(exists => {
                resolve(exists)
              });
        })
    }
    async externalDownloadRecording(roomName=false, fileName){
        const idbName = roomName?roomName:this.idbName
        this.fileName = fileName?fileName:idbName
        this.fileName = this.fileName?.split('_namespace_')?.join(' ')
        this.idbName = idbName
        this.idbDB = await openDB(idbName, 1, {
            upgrade(db) {
                db.createObjectStore(this.idbStoreName);
            }
        }
        );
        this.idbDB.getAllKeys(this.idbStoreName).then((keys) => {
            // recursive function to save the data from the indexed db
            if (keys.length){
                let PromiseIterable = keys?.map((key)=>this.getBlobs(key))
                Promise.allSettled(PromiseIterable).then(async (values)=>{
                    const blobs = values.map((item)=>item.value)
                    const blob = await this.getBlobs2(blobs)
                    // // destroy
                    this.saveRecordingCleanup(this.idbDB, idbName);
                });
            }
        });
    }
    async externalClearData(){
		// destroy
		this.idbDB.close();
		deleteDB(this.idbName);
		// https://bugzilla.mozilla.org/show_bug.cgi?id=934640
		this.recordingMimeType = null;
		this.recordingData = [];
		this.recorder = null;
        this.recorderStream = null;
        console.log("350 recorder set null")
    }

	// save recording with Stream saver and destroy
	saveRecordingWithStreamSaver(keys, writer, stop = false, db, dbName){
        console.log("299", keys, writer, stop, db, dbName)
		let readableStream = null;

		let reader = null;

		let pump = null;

		const key = keys[0];

		// we remove the key that we are removing
		keys.shift();
		db.get(this.idbStoreName, key).then((blob) => {
			if (keys.length === 0){
				// if this is the last key we close the writable stream and cleanup the indexedDB
				readableStream = blob.stream();
				reader = readableStream.getReader();
				pump = () => reader.read().then((res) => (res.done
						? this.saveRecordingCleanup(db, dbName, writer)
						: writer.write(res.value).then(pump)));
				pump();
			}else{
				// push data to the writable stream
				readableStream = blob.stream();
				reader = readableStream.getReader();
				pump = () => reader.read().then((res) => (res.done
						? this.saveRecordingWithStreamSaver(keys, writer, false, db, dbName)
						: writer.write(res.value).then(pump)));
				pump();
			}
		});
	}

	saveRecordingCleanup(db, dbName, writer = null){
		if (writer != null){
			writer.close();
		}
		// destroy
		db.close();
		deleteDB(dbName);
		// https://bugzilla.mozilla.org/show_bug.cgi?id=934640
		this.recordingMimeType = null;
		this.recordingData = [];
		this.recorder = null;
        this.recorderStream = null;
        console.log("350 recorder set null")
	}
    invokeSaveAsDialog(blob)
	{
		const link = document.createElement('a');

		link.style = 'display:none;opacity:0;color:transparent;';
		link.href = URL.createObjectURL(blob);
		link.download = this.fileName;

		(document.body || document.documentElement).appendChild(link);
		if (typeof link.click === 'function')
		{
			link.click();
		}
		else
		{
			link.target = '_blank';
			link.dispatchEvent(new MouseEvent('click',
				{
					view       : window,
					bubbles    : true,
					cancelable : true
				}));
		}
		URL.revokeObjectURL(link.href);

	}
}
export const recorder = new BrowserRecorder();