import { combineReducers } from "redux";
import layout from "./layout";
import liveClass from "./liveClass";
import calendar from "./calendar";
import today from "./today";
import classRoom from "./classRoom";
import login from "./login";
import curriculum from "./curriculum";
import profile from "./profile";
import notification from "./notification";
import user from "./user";
import announcement from "./announcement";
import attendance from "./attendance";
import classResources from "./classResources";
import assignment from "./assignment";
import mobileTest from "./mobileTest";
import brigoshaMedia from "./brigoshaMedia";
import test from "./test_old";
import testAdmin from "./testAdmin";
import studentTest from "./studentTest";
import digitalLibrary from "./digitalLibrary";
import fees from "./fees";
import kyc from "./kyc";
import adminCare from "./adminCare";
import broadcast from "./broadcast";
import schedule from "./schedule";
import userManagement from "./userManagement";

import course from "./course";
import { sessionReducer } from "redux-react-session";
import feeManagement from "./feeManagement";
import sideMenu from "./sideMenu";
import attendanceNew from "./attendanceNew"
import studentPracticeTest from "./studentPracticeTest";
import profileNew from "./profileNew";
import loginNew from "./loginNew";
import dashBoard from "./dashBoard";
import aulasWallet from './aulasWallet';
import userManagementV2 from './userManagementV2'
import common from './common';
import courseManagement from './courseManagement';
import aulasMarket from "./aulasMarket";
import onlineTestReducer from "./onlineTestReducer"
import marketTest from "./marketTest";
import studentMarketPlace from "./studentMarketPlace"
import marketPlaceCRM from "./marketPlaceCRM"
import offers from './offers';

export default combineReducers({
  session: sessionReducer,
  layout,
  today,
  liveClass,
  calendar,
  classRoom,
  announcement,
  login,
  curriculum,
  profile,
  notification,
  user,
  attendance,
  classResources,
  assignment,
  mobileTest,
  brigoshaMedia,
  test,
  testAdmin,
  studentTest,
  digitalLibrary,
  fees,
  kyc,
  adminCare,
  broadcast,
  schedule,
  userManagement,
  course,
  feeManagement,
  sideMenu,
  attendanceNew,
  studentPracticeTest,
  profileNew,
  loginNew,
  dashBoard,
  aulasWallet,
  userManagementV2,
  common,
  courseManagement,
  aulasMarket,
  onlineTestReducer,
  marketTest,
  studentMarketPlace,
  marketPlaceCRM,
  offers,
  // practiseTest

});
