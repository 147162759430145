import React, { useState } from 'react'
import "./studyMaterialContent.css"
import RatingStars from '../ratingStars'
import { Typography } from 'antd'
import "./../allCourseDetailsStyles.css"
import "./../userHomeStyles.css"
import Slider from "react-slick";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import { storeCourseIdAndType } from 'actions/studentMarketPlace'

function RelatedStudyMaterial({ singleCourseDetailsDataContainer, fromStudyMaterialPage, fromTestSeriesPage, storeCourseIdAndType }) {
    const history = useHistory();
    const [showMore, setShowMore] = useState(false);
    const contentToShow = showMore ? Array(20)?.length : 8;

    const MobileView = ({ item }) => {
        const history = useHistory();
        return (
            <div className='m-r-30 m-t-20'>
                <div>
                    <div className='study-material-card'
                        onClick={() => {
                            storeCourseIdAndType("id",item?.id);
                            storeCourseIdAndType("type",item?.resourceType)
                            history.push({
                                pathname:  `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                state: {
                                    id: item?.id,
                                    resourceType: item?.resourceType
                                }
                            })
                        }}>
                        {item?.feeType == 'free' ?
                            <div className='study-material-free' >
                                <div style={{ textAlign: "center", fontSize: "10px", color: '#FFFFFF', fontWeight: "700px", marginTop: 2 }}>FREE</div></div> : <div style={{ width: '60px', height: 20 }} ></div>}
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <img src={require("../../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />
                            {/* <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>{item?.m_count} Materials</div> */}
                            {fromTestSeriesPage
                                ?
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>
                                    {item?.tCount} {item?.tCount <= 1 ? "Test" : "Tests"}
                                </div>
                                :
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>
                                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}
                                </div>
                            }

                        </div>
                        <div style={{ marginRight: "10px", marginTop: "-10px" }}>
                            {item?.feeType == 'free' ? (<></>) :
                                <div className='study-material-amount'>
                                    <div style={{ textAlign: "center", fontSize: "10px", color: '#594099', fontWeight: "700px", marginTop: 2 }}>₹ {item?.amount}</div>
                                </div>}
                        </div>
                    </div>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200, marginTop: "5px" }}>{item?.name}</Typography.Text>
                    <div style={{ display: "flex" }}>
                        {item?.rating > 0 ?
                            <RatingStars star={item?.rating} /> : null}
                        {item?.ratingCount > 0 ?
                            <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.ratingCount})</h> : null}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={fromStudyMaterialPage || fromTestSeriesPage ? 'study-course-parent' : 'study-content-parent'}>

                {fromTestSeriesPage ?
                    singleCourseDetailsDataContainer?.length != 0 ?
                        <div className='study-content-header'>Related Test Series ({singleCourseDetailsDataContainer?.length})</div>
                        : (<></>)
                    :
                    singleCourseDetailsDataContainer?.length != 0 ?
                        <div className='study-content-header'>Related Study Material ({singleCourseDetailsDataContainer?.length})</div>
                        : (<></>)
                }

                <div className='study-content-child'>
                    {singleCourseDetailsDataContainer?.slice(0, contentToShow).map((item, index) => (
                        <div className=''>
                            <div style={{ margin: "10px 20px 20px 0px" }}>
                                <div>
                                    <div className='study-material-card'
                                        onClick={() => {
                                            storeCourseIdAndType("id",item?.id);
                                            storeCourseIdAndType("type",item?.resourceType)
                                            history.push({
                                                pathname:  `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                                state: {
                                                    id: item?.id,
                                                    resourceType: item?.resourceType
                                                }
                                            })
                                        }}>
                                        {item?.feeType == 'free' ?
                                            <div className='study-material-free' >
                                                <div style={{ textAlign: "center", fontSize: "10px", color: '#FFFFFF', fontWeight: "700px", marginTop: 2 }}>FREE</div></div> : <div style={{ width: '60px', height: 20 }} ></div>}
                                        <div style={{ display: "flex", marginLeft: "20px" }}>
                                            <img src={require("../../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />

                                            {/* <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>{item?.m_count} Materials</div> */}
                                            {fromTestSeriesPage
                                                ?
                                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>
                                                    {item?.tCount} {item?.tCount <= 1 ? "Test" : "Tests"}

                                                </div>
                                                :
                                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>
                                                    {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}
                                                </div>
                                            }

                                        </div>
                                        <div style={{ marginRight: "10px", marginTop: "-10px" }}>
                                            {item?.feeType == 'free' ? (<></>) :
                                                <div className='study-material-amount'>
                                                    <div style={{ textAlign: "center", fontSize: "10px", color: '#594099', fontWeight: "700px", marginTop: 2 }}>₹ {item?.amount}</div>
                                                </div>}
                                        </div>
                                    </div>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200, marginTop: "5px" }}>{item?.name}</Typography.Text>
                                    {fromTestSeriesPage
                                        ?
                                        <div style={{ display: "flex" }}>
                                            {item?.rating > 0 ?
                                                <RatingStars star={item?.rating} /> : null}
                                            {item?.totalReviews > 0 ?
                                                <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.totalReviews})</h> : null}
                                        </div>
                                        :
                                        <div style={{ display: "flex" }}>
                                            {item?.rating > 0 ?
                                                <RatingStars star={item?.rating} /> : null}
                                            {item?.ratingCount > 0 ?
                                                <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.ratingCount})</h> : null}
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='scroll-container-only-mobile ' >
                    {singleCourseDetailsDataContainer?.map((item, index) => (
                        <MobileView item={item} />
                    ))}
                </div>
                {singleCourseDetailsDataContainer?.length > 8 ?
                    <div className='study-show-more cursor-pointer' onClick={() => setShowMore(!showMore)} >{showMore ? "Show Less" : "Show more"}</div>
                    : (<></>)}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => ({
    storeCourseIdAndType: (key, val) => dispatch(storeCourseIdAndType(key, val))
});
export default connect(mapStateToProps, mapDispatchToProps)(RelatedStudyMaterial);
