import { getCouponList, getLiveCourseDetails, getMarketSettingsData, getOffersCarouselData, getStudyMaterialDetails, getTestSeriesDetails, getUserMarketPlaceHomeData, getVideoResourceDetails, setActiveTermIndex, setSelectedCouponCode, setSelectedTerm, storeCartCountData } from 'actions/studentMarketPlace'
import { Tag } from 'antd'
import PageDataLoader from 'components/pageDataLoader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CourseDetailsDescriptionSection from './courseDetailsDescriptionSection'
import CourseDetailsFeatures from './courseDetailsFeatures'
import CourseDetailsIncludes from './courseDetailsIncludes'
import CourseDetailsLearnings from './courseDetailsLearnings'
import './courseDetailsStyles.css'
import CourseContent from './coursePageCompo/courseContent'
import CourseContentPage from './coursePageCompo/courseContentPage'
import CourseRatings from './coursePageCompo/courseRatings'
import CourseTestContent from './coursePageCompo/courseTestContent'
import ExamPattern from './coursePageCompo/examPattern'
import IndividualTests from './coursePageCompo/individualTests'
import Prerequests from './coursePageCompo/prerequests'
import RelatedCourse from './coursePageCompo/relatedCourse'
import RelatedStudyMaterial from './coursePageCompo/relatedStudyMaterial'
import StudyMaterialContent from './coursePageCompo/studyMaterialContent'
import Footer from './footer'
import MobilePageHeader from './mobilePageHeader'
import TopBar from './topBar'
import NewFooter from './newFooter'

function UserCourseDetailsPage({ getLiveCourseDetails, setSelectedTerm, setSelectedCouponCode, setActiveTermIndex, getStudyMaterialDetails, singleCourseDetailsDataContainer, getVideoResourceDetails, 
  getTestSeriesDetails, getUserMarketPlaceHomeData, isFromAdminPreview, getOffersCarouselData, offersCarouselData, isOffersBarVisible, couponList, 
  getCouponList,storeCartCountData, getMarketSettingsData }) {
  const [pageLoader, togglePageLoader] = useState(false);
  const location = useLocation()
  const history = useHistory();
  let url = new URL(window.location.href);
  let courseId = url.searchParams.get("id");
  useEffect(() => {
    if (courseId == 'schollIntigratedNeet') {
      togglePageLoader(true);
      getLiveCourseDetails(264, () => { togglePageLoader(false) })
    }
    else if (courseId == 'schollIntigratedJee') {
      togglePageLoader(true);
      getLiveCourseDetails(261, () => { togglePageLoader(false) })
    }

  }, [])

  useEffect(()=>{
    if(courseId == 'schollIntigratedNeet'){
       togglePageLoader(true);
       getLiveCourseDetails(15, () => { togglePageLoader(false) })
     }
     else if(courseId == 'schollIntigratedJee'){
       togglePageLoader(true);
       getLiveCourseDetails(16, () => { togglePageLoader(false) })
     }
 
   },[])

  useEffect(() => {
    let currentURL = window.location.href;
    let id = currentURL?.split('=')[1]?.split('&')[0];
    const parts = currentURL?.split('&');
    const typePart = parts?.find(part => part?.includes('type='));
    getUserMarketPlaceHomeData(true, () => { })
    if (typePart) {
      const typeValue = typePart?.split('=')[1];
      if ((id && (typeValue == "liveCourses" || typeValue == "course"))) {
        togglePageLoader(true);
        getLiveCourseDetails(id, () => { togglePageLoader(false) })
      } else if ((id && (typeValue == "videoResources" || typeValue == "videoResource"))) {
        togglePageLoader(true);
        getVideoResourceDetails(id, () => { togglePageLoader(false) })
      } else if ((id && (typeValue == "studyMaterials" || typeValue == "studyMaterial"))) {
        togglePageLoader(true);
        getStudyMaterialDetails(id, () => { togglePageLoader(false) });
      } else if ((id && (typeValue == "testSeries" || typeValue == "testSeries"))) {
        getTestSeriesDetails(id, () => { togglePageLoader(false) });
      }
    } else {
      getMarketSettingsData(false, (res) => {
        if (res?.active) {
          if (location?.state?.resourceType == "course") {
            togglePageLoader(true);
            getLiveCourseDetails(location?.state?.id, () => { togglePageLoader(false) })
          } else if (location?.state?.resourceType == "videoResource") {
            togglePageLoader(true);
            getVideoResourceDetails(location?.state?.id, () => { togglePageLoader(false) })
          } else if (location?.state?.resourceType == "studyMaterials" || location?.state?.resourceType == "studyMaterial") {
            togglePageLoader(true);
            getStudyMaterialDetails(location?.state?.id, () => { togglePageLoader(false) });
          } else if (location?.state?.resourceType == "testSeries") {
            togglePageLoader(true);
            getTestSeriesDetails(location?.state?.id, () => { togglePageLoader(false) });
          }
        } else{
          history.push("/login")
        }
      })
    }
  }, [location?.state?.id]);
  useEffect(() => { setActiveTermIndex(0); setSelectedTerm(null); getCouponList({ disablePagination: true }); getOffersCarouselData() }, [])
  const [isCategoryClicked, setIsCategoryClicked] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);

  const selectedTags = location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ? singleCourseDetailsDataContainer?.course?.tags :
    location?.state?.resourceType == "videoResource" || singleCourseDetailsDataContainer?.videoResource ? singleCourseDetailsDataContainer?.videoResource?.tags :
      location?.state?.resourceType == "studyMaterial" || singleCourseDetailsDataContainer?.studyMaterial ? singleCourseDetailsDataContainer?.studyMaterial?.tags :
        singleCourseDetailsDataContainer?.testSeriesDetails?.tags
        const [isCategories, setCategories] = useState([{
          categoriesName: null,
          subCategoriesName: null
        }])
  const [hideBanner, SetHidBanner] = useState(false)
  const [userSearchDataContainer, setUserSearchData] = useState(null);
  const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null)
  const [courses, setCourses] = useState(storeCartCountData.items || []);
  const [isCategoryId, setCategoryId] = useState(null)
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

 
        return (
    <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} style={{ backgroundColor: "#FAFAFA" }}>
      <div className='mobile-topbar-parent'>
        <TopBar  
        setCategories={setCategories}
        SetHidBanner={SetHidBanner} 
        setCourses={setCourses}
setPage={setPage}
setHasMore={setHasMore}
page={page}

        setCategoryId={setCategoryId} 
        userSearchData={setUserSearchData} autocompleteSearched={setAutocompleteSearchedValue} 
         couponList={couponList} isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked} />
      </div>
      <div className='mobile-header-parent'>
        <MobilePageHeader title={
          location?.state?.resourceType == "course" ? "Live Courses" :
            location?.state?.resourceType == "videoResource" ? "Video Courses" : location?.state?.resourceType == "testSeries" ? "Online Test Series" :
              "Study Material"
        }
          onBackPress={() => { history.push("/home") }} sider={true} />
      </div>
      <div style={{ backgroundColor: "#FAFAFA" }}>
        {singleCourseDetailsDataContainer?.course || singleCourseDetailsDataContainer?.videoResource || singleCourseDetailsDataContainer?.studyMaterial || singleCourseDetailsDataContainer?.testSeriesDetails ? (<>
          <CourseDetailsDescriptionSection
            singleCourseDetailsDataContainer={
              location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ?
                singleCourseDetailsDataContainer?.course :
                location?.state?.resourceType == "videoResource" || singleCourseDetailsDataContainer?.videoResource ?
                  singleCourseDetailsDataContainer?.videoResource :
                  location?.state?.resourceType == "testSeries" || singleCourseDetailsDataContainer?.testSeriesDetails ? singleCourseDetailsDataContainer?.testSeriesDetails :
                    singleCourseDetailsDataContainer?.studyMaterial
            }
            clickFromTestCard={location?.state?.resourceType == "testSeries" ? true : false}
            testCount={singleCourseDetailsDataContainer?.testData?.length}
            isMinOfferVisible={couponList?.minOffers?.[0]}
          />
        </>) : <></>}
        {singleCourseDetailsDataContainer?.course?.learnings?.length > 0 || singleCourseDetailsDataContainer?.videoResource?.learnings?.length > 0 || singleCourseDetailsDataContainer?.studyMaterial?.learnings?.length > 0 ?
          <CourseDetailsLearnings singleCourseDetailsDataContainer={
            location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ?
              singleCourseDetailsDataContainer?.course :
              (location?.state?.resourceType == "videoResource" || singleCourseDetailsDataContainer?.videoResource ?
                singleCourseDetailsDataContainer?.videoResource :
                singleCourseDetailsDataContainer?.studyMaterial
              )
          } /> : (<></>)}
        {singleCourseDetailsDataContainer?.course?.prerequisites?.length > 0 || singleCourseDetailsDataContainer?.videoResource?.prerequisites?.length > 0 ?
          <Prerequests singleCourseDetailsDataContainer={location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ? singleCourseDetailsDataContainer?.course?.prerequisites : singleCourseDetailsDataContainer?.videoResource?.prerequisites} /> : null}
        {singleCourseDetailsDataContainer?.course?.features?.length > 0 || singleCourseDetailsDataContainer?.videoResource?.features?.length > 0 || singleCourseDetailsDataContainer?.studyMaterial?.features?.length > 0 || singleCourseDetailsDataContainer?.testSeriesDetails?.features?.length > 0 ?
          <CourseDetailsFeatures
            singleCourseDetailsDataContainer={
              location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ? singleCourseDetailsDataContainer?.course :
                location?.state?.resourceType == "videoResource" || singleCourseDetailsDataContainer?.videoResource ? singleCourseDetailsDataContainer?.videoResource :
                  location?.state?.resourceType == "studyMaterial" || singleCourseDetailsDataContainer?.studyMaterial ? singleCourseDetailsDataContainer?.studyMaterial :
                    singleCourseDetailsDataContainer?.testSeriesDetails
            } /> : (<></>)}
        {singleCourseDetailsDataContainer?.course?.details?.length > 0 || singleCourseDetailsDataContainer?.videoResource?.details?.length > 0 ?
          <CourseDetailsIncludes singleCourseDetailsDataContainer={location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ? singleCourseDetailsDataContainer?.course : singleCourseDetailsDataContainer?.videoResource} /> : null}
        {
          location?.state?.resourceType === "testSeries" || singleCourseDetailsDataContainer?.testData ?
            <IndividualTests singleCourseDetailsDataContainer={singleCourseDetailsDataContainer} />
            :
            <></>
        }
        {location?.state?.resourceType === "course" || singleCourseDetailsDataContainer?.course ? (
          singleCourseDetailsDataContainer?.course?.subjects?.length > 0 ? (
            <CourseContent singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.course} resourceType={location?.state?.resourceType} />
          ) : (
            <></>
          )
        ) : location?.state?.resourceType === "testSeries" || singleCourseDetailsDataContainer?.testSeriesDetails ? (
          singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries?.length > 0 ? (
            <CourseTestContent singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries} resourceType={location?.state?.resourceType} />
            // <CourseContent singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries} resourceType={location?.state?.resourceType} />
          ) : (
            <></>
          )
        ) : location?.state?.resourceType === "videoResource" || singleCourseDetailsDataContainer?.videoResource ? (
          <CourseContentPage />
        ) : (
          singleCourseDetailsDataContainer?.studyMaterial?.topics?.length > 0 ? (
            <StudyMaterialContent singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.studyMaterial?.topics} />
          ) : (
            <></>
          )
        )}
        {
          location?.state?.resourceType === "testSeries" && singleCourseDetailsDataContainer?.testSeriesDetails?.examPattern ?
            <ExamPattern singleCourseDetailsDataContainer={singleCourseDetailsDataContainer} />
            :
            <></>
        }
        {isFromAdminPreview && (singleCourseDetailsDataContainer?.course?.tags?.length > 0 || singleCourseDetailsDataContainer?.videoResource?.tags?.length > 0 || singleCourseDetailsDataContainer?.studyMaterial?.tags?.length > 0 || singleCourseDetailsDataContainer?.testSeriesDetails?.tags?.length > 0) ?
          <div className='course-details-learnings-parent' style={{ marginTop: 30 }}>
            <div className='course-content-header'>Tags</div>
            <div className="flex-wrap m-t-10">
              {selectedTags?.map((tag, index) =>
                <Tag
                  key={index}
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#191919',
                    padding: "5px 10px",
                    borderRadius: 20,
                    border: "1px solid #636363",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {tag}
                </Tag>
              )}
            </div>
          </div> : <></>}
        {isFromAdminPreview ? <></> :
          <>
            {location?.state?.resourceType === "studyMaterial" || singleCourseDetailsDataContainer?.studyMaterial ? (
              singleCourseDetailsDataContainer?.relatedResources?.length > 0 ? (
                <RelatedStudyMaterial singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.relatedResources} />
              ) : (
                <></>
              )
            ) : (
              singleCourseDetailsDataContainer?.relatedCourses?.length > 0 ||
                singleCourseDetailsDataContainer?.relatedResources?.length > 0 ? (
                <RelatedCourse
                  singleCourseDetailsDataContainer={
                    location?.state?.resourceType === "course" || singleCourseDetailsDataContainer?.course
                      ? singleCourseDetailsDataContainer?.relatedCourses
                      : singleCourseDetailsDataContainer?.relatedResources
                  }
                  fromTestSeries={location?.state?.resourceType == "testSeries" ? true : false}
                />
              ) : (
                <></>
              )
            )}
            {singleCourseDetailsDataContainer?.course?.ratingValue > 0 || singleCourseDetailsDataContainer?.videoResource?.ratingValue > 0 || singleCourseDetailsDataContainer?.studyMaterial?.ratingValue || singleCourseDetailsDataContainer?.testSeriesDetails?.averageRating > 0 ? (<>
              <CourseRatings
                singleCourseDetailsDataContainer={location?.state?.resourceType == "course" || singleCourseDetailsDataContainer?.course ? singleCourseDetailsDataContainer?.course
                  : location?.state?.resourceType == "videoResource" || singleCourseDetailsDataContainer?.videoResource ?
                    singleCourseDetailsDataContainer?.videoResource : location?.state?.resourceType == "testSeries" || singleCourseDetailsDataContainer?.testSeriesDetails ? singleCourseDetailsDataContainer : singleCourseDetailsDataContainer?.studyMaterial}
                location={location} />
            </>) : <></>}
          </>
        }
      </div>
      <div style={{ marginTop: 100 }}>
        <NewFooter />
      </div>

      

      <PageDataLoader visible={pageLoader} />
    </div>
  )
}
const mapStateToProps = (state) => {
  const { singleCourseDetailsDataContainer, couponList, offersCarouselData, isOffersBarVisible } = state.studentMarketPlace;
  const { isFromAdminPreview } = state.courseManagement;
  const { user } = state.session
  return { singleCourseDetailsDataContainer, user, isFromAdminPreview, couponList, offersCarouselData, isOffersBarVisible };
};
const mapDispatchToProps = (dispatch) => ({
  getLiveCourseDetails: (id, callBack) => dispatch(getLiveCourseDetails(id, callBack)),
  getVideoResourceDetails: (id, callback) => dispatch(getVideoResourceDetails(id, callback)),
  getStudyMaterialDetails: (id, callback) => dispatch(getStudyMaterialDetails(id, callback)),
  getTestSeriesDetails: (id, callback) => dispatch(getTestSeriesDetails(id, callback)),
  getUserMarketPlaceHomeData: (noCourse, callBack) => dispatch(getUserMarketPlaceHomeData(noCourse, callBack)),
  setActiveTermIndex: (val) => dispatch(setActiveTermIndex(val)),
  setSelectedCouponCode: (val) => dispatch(setSelectedCouponCode(val)),
  setSelectedTerm: (val) => dispatch(setSelectedTerm(val)),
  getOffersCarouselData: () => dispatch(getOffersCarouselData()),
  getCouponList: (param) => dispatch(getCouponList(param)),
  storeCartCountData: (val) => dispatch(storeCartCountData(val)),
  getMarketSettingsData: (all, callBack) => dispatch(getMarketSettingsData(all, callBack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserCourseDetailsPage)