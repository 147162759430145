import React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import {
  EditOutlined,
  RollbackOutlined
} from "@ant-design/icons";
import Tooltip from 'antd/lib/tooltip';
import Popover from 'antd/lib/popover';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/popover/style/index';
import Styles from "./style";
import Slider from 'antd/lib/slider';
import 'antd/lib/slider/style/index';
import html2canvas from "html2canvas";
import { Modal } from "antd";
import { Notification, Api, BrigoshaMedia } from "services";
import { postChatImage, storePaths } from "actions/broadcast";
import { connect } from "react-redux";
import { FaEraser } from "react-icons/fa";
import { EventRegister } from "react-native-event-listeners";
import { emptyPathArray, switchStudentCanvasAction } from "actions/liveClass";
import { AiFillDelete } from "react-icons/ai";


const brigoshaMedia = new BrigoshaMedia();
class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.getPath = this.getPath.bind(this);
    this.loadPath = this.loadPath.bind(this);
    this.handlePenClick = this.handlePenClick.bind(this);
    this.openWidthPicker = this.openWidthPicker.bind(this);
    this.handleUndoClick = this.handleUndoClick.bind(this);
    this.openColorPicker = this.openColorPicker.bind(this);
    // this.handleRedoClick = this.handleRedoClick.bind(this);
    // this.handleClearClick = this.handleClearClick.bind(this);
    this.handleEraserClick = this.handleEraserClick.bind(this);
    this.handlePathUpdate = this.handlePathUpdate.bind(this);
    this.streamId = this.props.streamId;
    this.state = {
      canvasnum: 1,
      pen: true,
      color: "black",
      showColorPicker: false,
      path: false,
      width: 2,
      screenshoturl: '',
      isModalVisible: false,
      screenshotfile: null,
      pages: [
        // props.streamId in props.paths
        // ?
        // this.props.paths[this.props.streamId]
        // :  
        [{
          paths: [{ x: null, y: null }],
          strokeWidth: 0,
          strokeColor: 0,
          drawMode: 0,
          startTimestamp: 0,
          endTimestamp: 0
        }],
        [{
          paths: [{ x: null, y: null }],
          strokeWidth: 0,
          strokeColor: 0,
          drawMode: 0,
          startTimestamp: 0,
          endTimestamp: 0
        }],
        [{
          paths: [{ x: null, y: null }],
          strokeWidth: 0,
          strokeColor: 0,
          drawMode: 0,
          startTimestamp: 0,
          endTimestamp: 0
        }]
      ]
    };
  }
  componentDidMount() {
    let thiz = this;
    EventRegister.addEventListener('boardDataRcv', data => {
      switch (data.type) {
        case 'clearCanvas':
          this.props.emptyPathArray();
          thiz.canvas.current.clearCanvas();
          break;
        case 'undoCanvas':
          thiz.undoCanvas();
          break;
        default:
        // Do nothing
      }
    });
  }

  componentWillUnmount() {
    // const thiz = this;
    // let pages = [...this.state.pages];
    // this.canvas.current.exportPaths().then(data=>{
    //   pages[this.state.canvasnum-1] = data;
    //   console.log("unmounting", pages, thiz.streamId);
    //   thiz.props.storePaths(thiz.streamId, data);
    // })
  }
  switchCanvas2(canvasno) {
    const thiz = this;
    let pages = [...this.state.pages];
    if (this.state.canvasnum != canvasno) {
      this.canvas.current.exportPaths().then(data => {
        pages[this.state.canvasnum - 1] = data;
        thiz.setState({ pages });
        thiz.canvas.current.clearCanvas();
        thiz.canvas.current.loadPaths(thiz.state.pages[canvasno - 1]);
        thiz.setState({ canvasnum: canvasno });
        thiz.canvas.current.eraseMode(false);
      });

    }
    const index = canvasno-1
    const data = {
      type: 'switchPage',
      data: index,
      // pageData: pages[index],
    };
    // allowCanvasSwitch(false);
    brigoshaMedia.sendCanvasData(data);
    this.props.switchStudentCanvasAction(index);
  }
  selectCanvas(canvasno) {
    let currentcanvas = null
    if (canvasno == 1) {
      currentcanvas = this.canvas1
    }
    else if (canvasno == 2) {
      currentcanvas = this.canvas2
    }
    else if (canvasno == 3) {
      currentcanvas = this.canvas3
    }
    this.setState({
      canvasnum: canvasno,
      canvas: currentcanvas,
      showColorPicker: false
    });
  }
  content = (
    <div style={Styles.colorPickerWrapper}>
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "red" }}
        onClick={() => this.selectColor("red")}
      />
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "blue" }}
        onClick={() => this.selectColor("blue")}
      />
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "green" }}
        onClick={() => this.selectColor("green")}
      />
      <button
        className="cursor-pointer"
        style={{ ...Styles.colorPickerBtn, backgroundColor: "black" }}
        onClick={() => this.selectColor("black")}
      />
    </div>
  );

  componentDidUpdate(prevProps) {
    const { switchCanvas, pathData, showToolBar, prevPages } = this.props;
    if (!showToolBar) {
      this.canvas.current.clearCanvas();
      let jsdata = [];
      this.props.pages[this.props.switchStudentCanvas]?.path?.forEach((strokeData, index)=>{
        let widthRatio = this.sketchContainer.clientWidth / strokeData.size.width;
        let heightRatio = this.sketchContainer.clientHeight / strokeData.size.height;
        jsdata.push({
          drawMode: true,
          strokeColor:strokeData.path.color,
          strokeWidth: strokeData.path.width,
          paths: strokeData.path.data.map((item)=>{
            let [x, y] = item.split(',')
            x = parseFloat(x)*widthRatio
            y = parseFloat(y)*heightRatio
            return {
              x: x,
              y: y
            }
          })
        })
        this.canvas.current.loadPaths(jsdata);
      })
    }
  }

  loadCanvasPaths() {
    const { prevPages, switchCanvas } = this.props;
    if (typeof prevPages === "undefined") return;
    this.handleClearClick();
    if (prevPages[switchCanvas].path) {
      for (let i = 0; i < prevPages[switchCanvas].path.length; i += 1) {
        this.loadPath(prevPages[switchCanvas].path[i]);
      }
    }
  }

  selectColor(color) {
    this.setState({
      color,
      showColorPicker: false
    });
  }
  undoCanvas() {
    let newpages = [...this.state.pages];
    const thiz = this;
    this.canvas.current.exportPaths().then(data => {
      let data2 = [...data];
      data2.pop();
      let newpaths = data2;
      newpages[thiz.state.canvasnum - 1] = newpaths;
      thiz.setState({ pages: newpages });
      thiz.canvas.current.clearCanvas();
      thiz.canvas.current.loadPaths(thiz.state.pages[thiz.state.canvasnum - 1]);
    });
  }
  handleUndoClick() {
    this.undoCanvas();
    const data = {
      type: 'undoCanvas',
      data: true,
    };
    brigoshaMedia.sendCanvasData(data);
  }


  handleRedoClick() {
    this.canvas.current.redo();
  }

  handleClearClick() {
    this.canvas.current.clearCanvas();
    const data = {
      type: 'clearCanvas',
      data: true,
    };
    brigoshaMedia.sendCanvasData(data);
  }
  changeWidth(value) {
    this.setState({
      width: value
    })
  }
  handlePenClick() {
    this.canvas.current.eraseMode(false);
    this.setState((prevState) => {
      return {
        // ...prevState,
        pen: true,
        width: prevState?.width ? prevState.width : 2
      }
    })
    this.openWidthPicker();
  }

  openColorPicker() {
    this.canvas.current.eraseMode(false);
    this.setState({ showColorPicker: !this.state.showColorPicker, pen: true });
  }
  openWidthPicker() {
    this.setState({ showWidthPicker: !this.state.showWidthPicker });
  }
  handleEraserClick() {
    this.setState({ pen: false, showWidthPicker:false });
    this.canvas.current.eraseMode(true);
  }

  getPath() {
    this.canvas.current
      .exportPaths()
      .then(data => {
        this.setState({ path: data });
      })
      .catch(err => { });
  }

  handlePathUpdate(data) {

    // this.props.onUpdate(data[0]);
  }


  loadPath(path) {
    let element = path;
    let mainArr = [];
    let paths = [];
    let mainObj = {};
    // const sourceRatio = element.size.width / element.size.height;
    // const currentRatio =
    //   this.sketchContainer.clientWidth / this.sketchContainer.clientHeight;

    const widthRatio = this.sketchContainer.clientWidth / element.size.width;
    const heightRatio = this.sketchContainer.clientHeight / element.size.height;

    element.path.data.forEach((item, index) => {
      const items = item.split(",");
      let obj = { x: "", y: "" };
      // obj.x = parseFloat(items[0]);
      // obj.y = parseFloat(items[1]);
      obj.x = parseFloat(items[0]) * widthRatio;
      obj.y = parseFloat(items[1]) * heightRatio;
      // obj.x = (parseFloat(items[0]) / sourceRatio) * currentRatio * 0.6;
      // obj.y = (parseFloat(items[1]) / sourceRatio) * currentRatio * 0.6;

      paths.push(obj);
    });
    mainObj.paths = paths;
    mainObj.strokeWidth = element.path.width;
    if (element.path.color === "#00000000") {
      mainObj.strokeColor = "#ffffffff";
    } else {
      mainObj.strokeColor = element.path.color;
    }
    mainObj.drawMode = true;
    mainArr.push(mainObj);
    this.canvas.loadPaths(mainArr);
  }

  handleRef(ref) {
    if (ref !== null) {
      this.canvas = ref;
      //   const { onCanvasRef } = this.props;
      //   onCanvasRef(ref);
    }
  }

  handleDivRef(divRef) {
    if (divRef !== null) {
      this.sketchContainer = divRef;
    }
  }

  slider = () => {
    return (
      <div style={Styles.colorPickerWrapper}>
        <Slider
          style={{ width: '100px' }}
          min={1}
          max={10}
          onChange={(value) => this.changeWidth(value)}
          value={this.state.width}
          onAfterChange={() => this.openWidthPicker()}
        />
      </div>
    )
  };
  onStroke = (data, bool) => {
  let newColor = !data.drawMode?'white':data.strokeColor === 'black'? '#2d3436':data.strokeColor
  let newPath = {
    drawer:null,
    size: {
      width: this.sketchContainer.clientWidth,
      height: this.sketchContainer.clientHeight
    },
    path: {
      id: Math.floor(10000000 + Math.random() * 90000000),
      color: newColor,
      width:data.strokeWidth,
      data: data.paths.map(item=>`${item.x},${item.y}`)
    }
  };
  const finalData = {
    type: 'path',
    data: {
      path:newPath,
      currentPage:this.state.canvasnum-1,
    },
  };
  brigoshaMedia.sendCanvasData(finalData);

  }
  render() {
    const user = {
      id: this.props.sessionuser.id,
      name: this.props.sessionuser.name,
      avatar: `${Api._s3_url}${this.props.sessionuser.image}`,
    }
    const handleImageUpload = (file) => {
      const params = {
        file,
        userId: user.id,
        roomName: this.props.streamId?.split("xxxx")[1],
        type: "image"
      };
      this.props.postChatImage(params, user,
        (newMessage) => { this.props.chat.sendmsg(newMessage, 'Image'); },
        "Group");
    }

    const imageBeforeUpload = file => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const reader = new FileReader();
      if (!isJpgOrPng) {
        Notification.error("error", "You can only upload JPG/PNG file!");
      } else if (!isLt2M) {
        Notification.error("error", "Image must smaller than 2MB!");
      } else {
        reader.addEventListener("load", () => {
          handleImageUpload(file);
        });
        reader.readAsDataURL(file);
      }
      return false;
    };
    return (
      <div className="full-height full-width r-c-fs"
        ref={divElement => {
          this.handleDivRef(divElement);
        }}
      >
        {
          this.props.showToolBar?
          <div style={Styles.toolbarContainer}>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Pen">
              <div className="full-width full-height r-c-c" style={this.state.pen?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={this.handlePenClick}>
                <EditOutlined />
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Color Picker">
              <div className="full-width full-height r-c-c"
                onClick={this.openColorPicker}
              >
                <div style={{ width: 20, height: 20, minHeight: 12, minWidth: 12, borderRadius: 20, backgroundColor: this.state?.color }}> </div>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Undo">
              <div className="full-width full-height r-c-c"
                onClick={this.handleUndoClick}
              >
                <RollbackOutlined />
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Erase">
              <div className="full-width full-height r-c-c" style={!this.state.pen?{backgroundColor:"gray", borderRadius:'100%', padding:5}:{}}
                onClick={this.handleEraserClick}
              >
                <FaEraser/>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Canvas 1">
              <div className="full-width full-height toolbarNum r-c-c disable-text-selection" style={this.state.canvasnum==1?{borderLeftWidth:2, borderLeftColor:"grey", borderLeftStyle:'solid'}:{}}
                type="text"
                shape="circle"
                onClick={() => { this.switchCanvas2(1) }}
              >
                1
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Canvas 2">
              <div className="full-width full-height toolbarNum r-c-c disable-text-selection" style={this.state.canvasnum==2?{borderLeftWidth:2, borderLeftColor:"grey", borderLeftStyle:'solid'}:{}}
                onClick={() => { this.switchCanvas2(2) }}
              >
                2
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Canvas 3">
              <div
                className="full-width full-height toolbarNum r-c-c disable-text-selection" style={this.state.canvasnum==3?{borderLeftWidth:2, borderLeftColor:"grey", borderLeftStyle:'solid'}:{}}
                type="text"
                shape="circle"
                onClick={() => { this.switchCanvas2(3) }}
              >
                3
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Clear">
              <div
                className="full-width full-height r-c-c"
                type="text"
                shape="circle"
                onClick={()=>{this.handleClearClick()}}
              >
                <AiFillDelete/>
              </div>
            </Tooltip>
          </div>
          <div style={Styles.toolbarBtn}>
            <Tooltip placement="left" title="Snip">
              <div
                type="text"
                shape="circle"
                className='r-c-c disable-text-selection'
                onClick={() => {
                  html2canvas(document.querySelector(".videoboxcard")).then(canvas => {
                    this.setState({
                      screenshoturl: canvas.toDataURL(),
                      isModalVisible: true
                    })
                  });
                }}
              >
                <img style={{ width: 20, height: 20, minHeight: 12, minWidth: 12 }} src={require('./assets/Group 11812.svg').default} />
              </div>
            </Tooltip>
          </div>
        </div>
        :null
        }
        <ReactSketchCanvas
          ref={this.canvas}
          className="relative"
          style={{ borderRadius: "15px", minHeight: "450px", height: "100%" }}
          strokeWidth={this.props.showToolBar?this.state.width:0}
          strokeColor={this.state.color}
          eraserWidth={this.props.showToolBar?10:0}
          onStroke={this.onStroke}
          onUpdate={data => this.handlePathUpdate(data)}
        />
        <Popover placement="left" content={this.content} title="Choose a colour" trigger="click" visible={this.state.showColorPicker}></Popover>
        <Popover placement="left" content={this.slider} title="Choose a width" trigger="click" visible={this.state.showWidthPicker}></Popover>
        <Modal wrapClassName="imagePreview" centered={true} okText={"Send"}
          visible={this.state.isModalVisible}
          onOk={async () => {
            let blob = await fetch(this.state.screenshoturl).then(r => r.blob());
            this.setState({
              screenshotfile: blob,
              screenshoturl: '',
              isModalVisible: false
            });
            imageBeforeUpload(this.state.screenshotfile);
          }}
          onCancel={() => {
            this.setState({
              screenshoturl: '',
              isModalVisible: false
            })
          }}>
          <div style={{ width: "60%", height: "60%" }}>
            <img style={{ width: "100%", height: "100%" }} src={`${this.state.screenshoturl}`} alt="" />
          </div>
        </Modal>
      </div>

    );
  }
}

const mapStateToProps = state => {
  const { user } = state.session;
  const { streamId, paths } = state.broadcast;
  const sessionuser = user;
  return {
    sessionuser,
    streamId,
    paths
  }
}

const mapDispatchToProps = dispatch => ({
  postChatImage: (params, user, callback, type) => dispatch(postChatImage(params, user, callback, type)),
  storePaths: (roomName, paths) => dispatch(storePaths(roomName, paths)),
  switchStudentCanvasAction: (index) => dispatch(switchStudentCanvasAction(index)),
  emptyPathArray: () => dispatch(emptyPathArray())
})


export default connect(mapStateToProps, mapDispatchToProps)(Canvas);

{/* <div style={Styles.toolbarBtn}>
  <Tooltip placement="left" title="Draw shapes">
    <div className="full-width full-height"
      onClick={()=>{}}
    >
        <img style={{ width: '80%', height:'80%',  minHeight:12, minWidth:12 }} src={require('./assets/Rectangle 4309.svg').default} />
    </div>
  </Tooltip>
</div> */}