import React, { useEffect, useRef, useState } from 'react'
import TopBar from './topBar';
import { connect } from 'react-redux';
import Footer from './footer';
import { Button, Collapse, Input, Modal, Rate, Tooltip } from 'antd';
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"
import videoGrey from "../../Assets/aulasMarket/previewLightColor.png"
import VideoBlack from "../../Assets/aulasMarket/previewDarkColor.png"
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import practiceTest from "../../Assets/aulasMarket/ic-practice-test.svg"
import saveFill from "../../Assets/aulasMarket/saveFilledIcon.svg"
import starOutline from "../../Assets/aulasMarket/ic-star-outLine.svg"
import startFilled from "../../Assets/aulasMarket/ic-star-fill.svg"
import VideoPreview from './videoPreview';
import "./styles.css"
import chemistry from "../../Assets/aulasMarket/sbj-chemistry.svg"
import physics from "../../Assets/aulasMarket/sbj-physics.svg"
import computer from "../../Assets/aulasMarket/sbj-computerscience.svg"
import english from "../../Assets/aulasMarket/sgj-english.svg"
import botany from "../../Assets/aulasMarket/sbj-botany 1.svg"
import zoology from "../../Assets/aulasMarket/sbj-zoology.svg"
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AddSaveLater, AddToCart, MyLiveCourses, RemoveSaveLater, setLoader, setMyResources, setPopularRender, storeSingleBuyNow, CoursePageApi, VideoResourcePageApi, StudyMaterialPage } from 'actions/aulasMarket';
import PageDataLoader from 'components/pageDataLoader';
import { Api } from 'services';
import HtmlParser from 'react-html-parser';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import AddRatingModal from './addRatingModal';
import ReactPlayer from 'react-player';
import checkIcon from "../../Assets/aulasMarket/ic-check-filled.svg";
import ReactPlayerHoc from 'components/ReactPlayerHoc';

const { Panel } = Collapse;
const { TextArea } = Input;

function CourseProfile({ coursePageData, storeSingleBuyNow, live, setPopularRender, itemType, setLoader, loader, loginDetails, myResourceData, popularRenderPage, itemId, setMyResources, CoursePageApi, VideoResourcePageApi, StudyMaterialPage }) {
  const history = useHistory();
  const token = loginDetails?.accessToken;
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [displayCount, setDisplayCount] = useState(6);
  const [itemFlag, setItemType] = useState();
  const [isVideoPreview, setVideoPreview] = useState(false);
  const [previewLink, setPreviewLink] = useState(null);
  const [isRatingModalVisible, setIsRatingModalVisible] = useState(false);
  const [save, setSave] = useState(false);
  const [saved, setSaved] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);
  const [owned, setOwned] = useState(coursePageData?.course?.owned);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [enroll, setEnroll] = useState(false);
  // console.log("whichstate",coursePageData);

  const hitMyLiveResource = () => {
    setLoader(true);
    MyLiveCourses(token, itemId, (res) => {
      if (res) {
        setMyResources(res);
      }
    }, setLoader);
  }
  useEffect(() => {
    if (window?.location?.pathname === "/course-page") {
      null;
      setMyResources(null);
    } else {
      hitMyLiveResource();
    }
  }, [])

  useEffect(() => {
    if (coursePageData) {
      coursePageData?.videoResource ? setItemType("videoResource") : setItemType("course");
    }
    setLoader(false)
  }, []);


  const courseAmt = coursePageData ? (coursePageData?.course?.amount || coursePageData?.videoResource?.amount) : null;
  const discountAmt = coursePageData ? (coursePageData?.course?.discount || coursePageData?.videoResource?.discount) : null;
  const discountT = coursePageData ? (coursePageData?.course?.discountType || coursePageData?.videoResource?.discountType) : null;

  let finalAmount;

  if (discountT === "percent" && discountAmt !== null && discountAmt !== undefined && discountAmt !== 0) {
    // If the discount type is percentage, calculate the final amount by subtracting the discount percentage
    finalAmount = courseAmt - (courseAmt * (discountAmt / 100));
  } else if (discountT === "amount" && discountAmt !== null && discountAmt !== undefined) {
    // If the discount type is amount, directly subtract the discount amount
    finalAmount = courseAmt - discountAmt;
  }

  // Check if the finalAmount is a valid number, and if so, display it
  if (!isNaN(finalAmount)) {
    console.log("Final Amount: " + finalAmount, discountAmt);
  } else {
    console.log("Unable to calculate final amount. Please check your inputs.",);
    finalAmount = coursePageData?.course?.amount || coursePageData?.videoResource?.amount
  }


  const name = coursePageData ? (coursePageData?.course?.courseName || coursePageData?.videoResource?.name) : null;
  const courseDescription = coursePageData ? (coursePageData?.course?.brief || coursePageData?.videoResource?.brief) : null;
  const coursePre = coursePageData ? (coursePageData?.course || coursePageData?.videoResource) : null;
  const courserating = coursePageData ? (coursePageData?.course?.rating || coursePageData?.videoResource?.rating) : null;
  const courseRatingValue = coursePageData ? (coursePageData?.course?.ratingValue || coursePageData?.videoResource?.ratingValue) : null;
  const courseRatingCount = coursePageData ? (coursePageData?.course?.ratingCount || coursePageData?.videoResource?.ratingCount) : null;
  const courseMode = coursePageData ? (coursePageData?.course?.mode || coursePageData?.videoResource?.mode) : null;
  const courseId = coursePageData ? (coursePageData?.course?.id || coursePageData?.videoResource?.id) : null;

  const handlePreviewLink = (link) => {
    setPreviewLink(link);
  }

  useEffect(() => {
    let currentURL = window.location.href;
    let id = currentURL?.split('=')[1]?.split('&')[0];
    console.log("Type Id", id);
    const parts = currentURL?.split('&');
    const typePart = parts?.find(part => part?.includes('type='));
    if (typePart) {
      const typeValue = typePart?.split('=')[1];
      console.log('Type Value:', typeValue);
      if (id && typeValue == "liveCourses") {
        setLoader(true)
        CoursePageApi(token, id, () => { }, null)
      } else if (id && typeValue == "videoResources") {
        VideoResourcePageApi(token, id, () => { }, setLoader)
      } else if (id && typeValue == "onlineTestSeries") {

      } else if (id && typeValue == "studyMaterials") {
        StudyMaterialPage(token, id, () => { }, setLoader)
      }
    }
  }, []);

  const handleSaveForLater = (id, itemType) => {
    console.log("saveid", id);
    setLoader(true);
    setSaved(!saved);
    if (saved) {
      RemoveSaveLater(token, id, itemType, (res) => {
        if (res) {
          setSave(false);
          setLoader(false)
        }
      }, setLoader)
    } else {
      AddSaveLater(token, id, itemType, (res) => {
        console.log("addedsave", res);
        if (res) {
          setSave(true);
          setLoader(false)
        }
      }, setLoader)

    }
  }

  const [showCard, setShowCard] = useState(false);
  const counts = myResourceData ? myResourceData?.related?.length : coursePageData?.relatedCourses?.length
  const itemsCardShow = showCard ? counts : 3;
  const toggleCardShow = () => {
    setShowCard(!showCard);
  }

  const togglePanel = (panelKey) => {
    const isExpanded = expandedPanels.includes(panelKey);
    if (isExpanded) {
      // Panel is currently expanded, so we collapse it
      setExpandedPanels((prevExpanded) => prevExpanded.filter((key) => key !== panelKey));
    } else {
      // Panel is currently collapsed, so we expand it
      setExpandedPanels((prevExpanded) => [...prevExpanded, panelKey]);
    }
  };
  const toggleAllPanels = () => {
    const allPanelKeys = [];

    // Iterate through coursePageData and collect id values
    coursePageData?.course?.sections?.forEach((section) => {
      allPanelKeys.push(section?.id);
    });
    const isAllExpanded = expandedPanels.length === allPanelKeys.length;
    setExpandedPanels(isAllExpanded ? [] : allPanelKeys);

  };

  const RelatedCourses = ({ course, index }) => {
    const [bookmark, setBookmark] = useState(false);
    console.log("data", course);

    const handleSaveForLater = (id, itemType) => {
      if (token) {
        if (saved[id]) {
          // If the item is already saved, remove it
          RemoveSaveLater(token, id, itemType, (res) => {
            if (res) {
              setSaved((prevSavedItems) => ({
                ...prevSavedItems,
                [id]: false,
              }));
            }
          }, setLoader);
        } else {
          // If the item is not saved, add it
          AddSaveLater(token, id, itemType, (res) => {
            if (res) {
              setSaved((prevSavedItems) => ({
                ...prevSavedItems,
                [id]: true,
              }));
            }
          }, setLoader);
        }
      } else {
        history.push('/login');
      }
    };

    return (
      <div className='relatedCourses' >
        <div className='rel-card' >
          <div className='r-c-sb' style={{}}>
            <img className='rel-img' style={{}} src={require("../../Assets/aulasMarket/related1.svg").default} />
            <div className='rel-card-btn m-l-20' style={{}}>
              <div className='rel-title '>{course?.name}</div>
              <div className='rel-des' style={{}} >{course?.courseInfo}</div>
              <div className='sellerButton2 m-t-5'>Bestseller</div>
            </div>
          </div>

          <div className='rel-res-bigscreen'>

            <div className='rel-card-share' style={{ padding: 10 }}>

              {course?.ratingValue === null || course?.c_count === 0 ? null :
                <div className='rel-ratings'>
                  <div style={{ color: "#FFA931" }}>
                    {course?.ratingValue}
                  </div>
                  <Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931" }} />
                  <div className='total-ratings'>{course?.c_count}</div>
                </div>
              }

              <div className='rel-cost' style={{}}>₹{course?.amount}</div>
              <div className='rel-bookmark' style={{ width: 34, height: 34, }}>
                <Tooltip title="Save for later" >
                  <div onClick={() => handleSaveForLater(course?.id, itemFlag)} style={{ cursor: "pointer" }} ><img style={{ width: "18px" }} src={saved[course?.id] ? saveFill : saveOutlined} /> </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const RelatedCourse = ({ course, index }) => {
    const handleSaveForLater = (id, itemType) => {
      if (token) {
        if (saved[id]) {
          // If the item is already saved, remove it
          RemoveSaveLater(token, id, itemType, (res) => {
            if (res) {
              setSaved((prevSavedItems) => ({
                ...prevSavedItems,
                [id]: false,
              }));
            }
          }, setLoader);
        } else {
          // If the item is not saved, add it
          AddSaveLater(token, id, itemType, (res) => {
            if (res) {
              setSaved((prevSavedItems) => ({
                ...prevSavedItems,
                [id]: true,
              }));
            }
          }, setLoader);
        }
      } else {
        history.push('/login');
      }
    };
    return (
      <div className='relatedCourses' style={{}} >
        <div className='rel-card' >
          <div className='r-c-sb' style={{ padding: 10 }}>
            <img className='' style={{ width: 150, borderRadius: 12, height: "101px" }} src={`${Api.dlS3Url}${course?.image}`} />
            <div className='rel-card-btn m-l-20' style={{}}>
              <div className='rel-title text-sm'>{course?.name}</div>
              <div className='rel-des' style={{}} >{course?.brief}</div>
              <div className='r-c-c' style={{}} >
                <div className='sellerButton2 m-t-5'>Bestseller</div>
                <div className='rel-ratings m-l-10'>
                  <div style={{ color: "#191919", fontSize: 14 }}>
                    {course?.ratingValue}
                  </div>
                  <Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931", fontSize: 18 }} />
                  <div className='total-ratings' style={{ fontSize: 12, margin: "-9px 0px 0px 0px" }}>{course?.ratingCount}</div>
                </div>
                <div className='rel-cost' style={{ fontSize: 16 }}>₹{course?.amount}</div>
                <div className='rel-bookmark' style={{ width: 34, height: 34, }}>
                  <Tooltip title="Save for later" >
                    <div onClick={() => handleSaveForLater(course?.id, itemFlag)} style={{ cursor: "pointer" }} ><img style={{ width: "18px" }} src={saved[course?.id] ? saveFill : saveOutlined} /> </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }

  const DateDisplay = ({ date }) => {
    // Parse the backend date string into a JavaScript Date object
    const backendDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = Date.now() - backendDate.getTime();

    // Calculate the number of days
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    // Calculate the number of hours
    const hoursDifference = Math.floor(timeDifference / (1000 * 3600));

    // Calculate the number of minutes
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    // Display different formats based on elapsed time
    if (daysDifference > 0) {
      return <div>{daysDifference} {daysDifference === 1 ? 'day' : 'days'} ago</div>;
    } else if (hoursDifference > 0) {
      return <div>{hoursDifference} {hoursDifference === 1 ? 'hour' : 'hours'} ago</div>;
    } else if (minutesDifference > 0) {
      return <div>{minutesDifference} {minutesDifference === 1 ? 'minute' : 'minutes'} ago</div>;
    } else {
      return <div>Now</div>;
    }
  };

  const CourseRating = ({ course }) => {
    console.log("reeee", course)
    return (
      <div className='courseRating'>
        <div className='r-c-fs m-t-20'>
          <div>
            <img style={{ width: 50 }} src={require("../../Assets/aulasMarket/ratingIcon.png").default} />
            {/* <img style={{ width: 50 }} src={`${Api.dlS3Url}${course?.image}`} /> */}
          </div>
          <div className='m-l-20' >
            <div className='text-sm font-bold'>{course?.title}</div>
            <div className='r-c-fs' >
              <div className='r-c-sb'><Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931" }} />
                <div className='m-l-10 text-xmd' style={{ color: "#AEAEAE" }}><DateDisplay date={course?.createdAt} /></div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-xmd bold-600' style={{ maxWidth: "350px" }}>"{course?.ratingExplanation}"</div>
      </div>
    )
  }

  const ChapterList = ({ data }) => {
    console.log("fffff", data?.courseChapter)
    return (
      <div className='' style={{}}>
        {data?.courseChapter?.slice(0, 6).map((i, index) => (
          <div className='r-c-fs' key={index} style={{ margin: "10px" }}>
            <div className='font-bold text-xmd' style={{ color: "#636363" }}>
              Chapter {index + 1}
            </div>
            <div className='font-bold text-xmd m-l-15'>
              {i?.name}
            </div>
          </div>
        ))}
      </div>
    )
  }
  const [showAll, setShowAll] = useState(false);

  const itemsToShow = showAll ? coursePageData?.course?.courseReviews.length : 6;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };



  const PanelContent = ({ content }) => {
    console.log("iiii", content)
    setFileUrl(content?.fileUrl);
    return (
      <div className='r-c-sb text-sm ' style={{ padding: "10px 30px" }}>
        <div className='r-c-fs'>
          <div><img style={{ width: 30 }} src={content?.preview ? VideoBlack : videoGrey} /></div>
          <div className='m-l-10'>{content?.name}</div>
        </div>
        <div className='r-c-fe'>
          {content?.preview ?
            <div className='m-r-30 text-xmd' onClick={() => {
              setVideoPreview(true);
              handlePreviewLink(content?.fileUrl);
            }} style={{ textDecoration: "underline", cursor: "pointer" }}>Preview</div> : null
          }
          <div>{content?.duration}</div>
        </div>
      </div>
    )
  }
  const ChapterContent = ({ data }) => {
    console.log("ddddddd", data)
    return (
      <>
        <div className={live ? "chapterContent-1" : 'chapterContent'}>
          <div className='chapter-title r-c-fs' style={{}}>
            <img src={data?.name === "Physics" ? physics : data?.name === "Chemistry" ? chemistry : computer} />
            <div className='m-l-10 font-bold text-sm ' style={{ color: "" }}>
              {data?.name}
              <div className='text-xmd font-medium' style={{ color: "#636363" }}>
                {data?.des}
              </div>
            </div>
          </div>
          <div className='border'></div>
          <div className='chapter-list'>
            <ChapterList data={data} />
          </div>
          {data?.courseChapter?.length < 6 ? null : (
            <div className='r-c-c font-bold' style={{ color: "#1089FF", cursor: "pointer" }}>
              {live ? (
                <span onClick={() => { setPopularRender(3); console.log("viewall") }}>
                  VIEW ALL
                </span>
              ) : (
                <Link to="/course-list">VIEW ALL</Link>
              )}
            </div>
          )}


        </div>
      </>
    )
  }

  const handleAddToCart = (id) => {
    // Your logic to add the item to the cart goes here
    // For the sake of this example, I'll set a timeout to simulate an API call
    setLoader(true);
    AddToCart(token, id, itemFlag, (res) => {
      console.log("addedd", res);
      if (res) {
        setIsAddedToCart(true);
        setAddLoader(false);
      }
    }, () => {
      setLoader(false);
    });
  };

  const handleGoToCart = () => {
    history.push('/shopping-cart');
  };


  console.log("videosections", coursePre?.sections)

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  }

  const handleBuyNow = () => {
    storeSingleBuyNow(coursePageData?.course)
    history.push('/checkout');
  }

  const handleEnrollNow = () => {
    console.log("enrolled")
    setEnroll(true);
  }

  const courseDesc = myResourceData ? myResourceData?.course?.courseDescription : coursePre?.courseDescription;
  const mylearnings = myResourceData ? myResourceData?.course?.learnings : coursePre?.learnings;
  const myPrerequisites = myResourceData ? myResourceData?.course?.prerequisites : coursePre?.prerequisites;
  const mySubjects = myResourceData ? myResourceData?.course?.subjects : coursePre?.subjects;
  const myDetails = myResourceData ? myResourceData?.course?.details : coursePre?.details;
  const linkPreview = coursePageData?.course ? coursePageData?.course?.preview : coursePageData?.videoResource?.preview;
  return (
    <>
      {live ? null :
        <div className="top-bar">
          <TopBar />
        </div>
      }
      <div style={{ backgroundColor: "#FAFAFA" }}>
        <div className='main-container-parent'>
          <div className={live ? "acc-parent" : 'main-container'}>

            <div className={live ? "content-parent-1" : "content-parent"} style={{}}>
              <div className='coures-content' style={{}}>
                {coursePageData?.videoResource ? null :
                  <div className='modeTag' style={{ color: "#FAFAFA" }}>{myResourceData ? myResourceData?.course?.mode?.toUpperCase() : courseMode?.toUpperCase()}</div>
                }
                <div className='test-title'>
                  {myResourceData ? myResourceData?.course?.courseName : name}<span style={{ color: "#FFA931", fontSize: 16, marginLeft: 10 }}>NEW</span>
                </div>
                <div className='test-des' >
                  {myResourceData ? myResourceData?.course?.brief : courseDescription}
                </div>
                <div className='test-options'>
                  <div className='opt-1' style={{ width: "100%" }}>
                    {live ? null :
                      <div className='sellerButton'>Bestseller</div>
                    }
                    <div className={live ? "test-rate" : 'test-ratings'}><div className='ratings' style={{ color: "#FFA931" }}>{myResourceData ? myResourceData?.course?.ratingValue : courseRatingValue}</div>
                      {courseRatingValue || myResourceData ?
                        <div style={{ width: "100%" }}>
                          <Rate disabled defaultValue={myResourceData ? myResourceData?.course?.ratingValue : courseRatingValue} style={{ color: "#FFA931", width: "100%", fontSize: "18px" }} />
                          <div className='test-total-ratings'>{myResourceData ? myResourceData?.course?.ratingCount : courseRatingCount}</div>
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className='opt-2'>
                    {owned ?
                      <div className='add-ratings' onClick={() => setIsRatingModalVisible(true)}>Add a rating</div>
                      : null}
                    {live ? null :
                      <>
                        <div className='test-share' onClick={() => {
                          setShareModalVisible(true);
                          setShareUrl(`${Api?._base_url}/course-page?id=${myResourceData ? myResourceData?.course?.id : courseId}&type=${myResourceData ? "video" : "course"}`)
                        }}><img src={require("../../Assets/aulasMarket/shareIcon.svg").default} />Share</div>
                        <div className='test-save' onClick={() => handleSaveForLater(courseId, itemFlag)} ><img src={save ? saveFill : saveOutlined} />Save for later</div>
                      </>}
                  </div>
                </div>
                {live ? null :
                  <>
                    <div className='test-cost'>
                      ₹{myResourceData ? myResourceData?.course?.amount : (discountAmt !== 0 ? finalAmount : coursePageData?.course?.amount)}
                    </div>
                    {enroll ? <>
                      <div style={{ color: "#594099", fontWeight: "600", fontSize: "18px" }}> <img src={checkIcon} /> ENROLLED</div>
                    </> :
                      <>
                        <div className='test-btns-parent'  >
                          <Button
                            className='content-add'
                            loading={addLoader}
                            onClick={() => {
                              if (!isAddedToCart) {
                                handleAddToCart(courseId);
                                setAddLoader(true);
                              } else {
                                handleGoToCart();
                              }
                            }}
                            style={{}}
                          >
                            {isAddedToCart ? 'GO TO CART' : 'ADD TO CART'}
                          </Button>
                          {courseAmt === 0 || courseAmt === null ?
                            <Button className='content-reg' onClick={handleEnrollNow} style={{}}>ENROLL NOW</Button>
                            :
                            <Button className='content-reg' onClick={handleBuyNow} style={{}}>BUY NOW</Button>
                          }
                        </div>
                      </>
                    }
                  </>
                }
              </div>
              <div className={live ? 'content-img' : "ctn2-img "} style={{}}><img className={live ? 'ctn2-img' : "ctn-img "} style={{}} src={require("../../Assets/aulasMarket/CoursePageProfile.png").default} />
              </div>
            </div>
            <div className='content-preview-parent m-t-20 ' style={{}}>
              {linkPreview !== null ?
                <div className='preview-content'>
                  <div className='preview' >
                    {/* <div className='r-c-c' style={{}}>
                    <img src={require("../../Assets/aulasMarket/playCircleIc.svg").default} />
                  </div> */}
                    <ReactPlayerHoc
                      style={{}}
                      height={"100%"}
                      width={"100%"}
                      keyboardControls={true}
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                      // Disable right click
                      onContextMenu={e => e.preventDefault()}
                      url={`${Api.dlS3Url}${linkPreview}`}
                      controls={true}
                      light={`${Api.dlS3Url}${coursePageData?.course ? coursePageData?.course?.image : coursePageData?.videoResource?.image}`}
                    />
                  </div>
                  <span className=' text-sm font-bold' onClick={() => {
                    setVideoPreview(true);
                    setPreviewLink(linkPreview)
                  }} style={{ color: "#594099", cursor: "pointer" }}> Preview this course</span>
                </div>
                : null}
              <div className='descriptions'>
                <span className='text-sm bold-600'>Description</span>
                <span className='des-text text-xs m-t-20' >

                  {window.location.pathname !== "/course-page" ? (
                    courseDesc
                  ) : (
                    <>
                      {IsJsonString(coursePre?.courseDescription) && isHTML(JSON.parse(coursePre?.courseDescription).mobile) ? (
                        HtmlParser(JSON.parse(coursePre.courseDescription).mobile)
                      ) : (
                        HtmlParser(coursePre.courseDescription)
                      )}
                    </>
                  )}

                </span>
              </div>
            </div>

            {mylearnings?.length > 0 ? <>
              <div className='content1'>
                <div className='content1-header'>What you'll learn</div>
                <div className='' style={{}}>
                  <ul className='custom-list' style={{ color: "#636363", columns: "2 auto" }} >
                    {mylearnings?.map((item) => {
                      return (
                        <>
                          <li><span style={{ color: "", fontSize: "16px" }}>{item?.name}</span></li>
                        </>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </> : null}
            {coursePre?.type === "recorded" || myResourceData ?
              <div className='content1'>
                <div className='content1-header '>This course includes:</div>
                <div className='' style={{}}>
                  <ul className='custom-list' style={{ columns: "2 auto" }} >
                    {myDetails?.map((item) => {
                      return (
                        <>
                          <li><span style={{ color: "" }}>{item?.name}</span></li>
                        </>
                      )
                    })}
                  </ul>

                </div>
              </div>
              : null}

            {coursePageData?.videoResource?.length > 0 ?
              <>
                <div className='content-course'>Course Content</div>
              </>
              : null}

            {coursePageData?.videoResource ?
              <>
                <div className='content-course-main '>
                  <div className='content-duration ' style={{}}>12 sections &nbsp;&nbsp;&nbsp; <li>36 topics</li>&nbsp;&nbsp;&nbsp; 22h 13m total length</div>
                  <div className='content-collapse-expand' onClick={toggleAllPanels} style={{}}>Expand all sections</div>
                </div>
                <div className='content-collapse' >


                  <Collapse accordion={false} activeKey={expandedPanels}>
                    {coursePre?.sections?.map((section, index) => (
                      <>
                        {console.log("insideloop", section)}
                        <Panel
                          header={
                            <div className="r-c-sb p-0">
                              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                                {section?.name}
                              </span>
                              <span className='r-c-c' style={{ fontSize: 16, color: "#AEAEAE" }}>
                                {section?.topicsCount} lectures &nbsp;&nbsp;&nbsp; <li>{section?.tDuration}</li>
                              </span>
                            </div>
                          }
                          key={section?.index} // Use a unique key for each panel
                          onClick={() => togglePanel(section?.index)}
                        >
                          {section?.topics?.map((item) => (
                            <PanelContent key={item.id} content={item} />
                          ))}
                        </Panel>
                      </>
                    ))}
                  </Collapse>

                </div>
              </>
              : <div className={live ? "chapter-parent-2" : 'chapter-parent'}>
                {mySubjects?.map((item) => (
                  <ChapterContent data={item} />
                ))}
              </div>}
            <div>
            </div>
            {myPrerequisites?.length > 0 ?
              <>
                <div className='content-pre'>Prerequisites</div>
                <div className='content-pre-li'>
                  {myPrerequisites?.map((item) => {
                    return (
                      <>
                        <li><span style={{ color: "", fontSize: "16px" }}>{item?.name}</span></li>
                      </>
                    )
                  })}
                </div>
              </> : null}

            {coursePageData?.relatedCourses?.length > 0 || myResourceData?.related?.length > 0 ?

              <div className='rela-parent' style={{}}>
                <div className='text-sm font-bold m-t-40'>Related Courses</div>
                <div className='m-t-20'>

                  {live ? (
                    myResourceData?.related?.slice(0, itemsCardShow).map((item) => (
                      <RelatedCourse course={item} /> // Render the RelatedCourse component when `live` is true
                    ))
                  ) : (
                    coursePageData?.relatedCourses?.slice(0, itemsCardShow).map((item) => (
                      <RelatedCourses key={item?.uid} index={item?.uid} course={item} />
                    ))
                  )}

                  {(coursePageData?.relatedCourses?.length) || (myResourceData?.related?.length) > 3 && (
                    <div className='r-c-c relatedCourses' style={{ height: 40 }}>
                      <div style={{ cursor: "pointer" }} onClick={toggleCardShow}>
                        {showCard ? 'Show Less' : 'Show More'}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              : null}



            {coursePre?.courseReviews ?
              <>
                {coursePre.courseReviews?.length > 0 ?
                  <>
                    <div className='course-rating-header' style={{}}><span><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} />{coursePre?.ratingValue} course rating</span>&nbsp;&nbsp;<li>
                      {coursePre?.ratingCount} rating</li> </div>
                    <div className='course-related-container'>
                      {coursePre?.courseReviews?.slice(0, itemsToShow).map((item, index) => (
                        <div key={index}>
                          <CourseRating course={item} />
                        </div>
                      ))}
                    </div>
                    <div className='test-showAll' style={{ color: "#636363", cursor: "pointer" }} >
                      {coursePre?.courseReviews?.length > 6 && (
                        <div onClick={toggleShowAll}>
                          {showAll ? 'Show Less' : 'Show More'}
                        </div>
                      )}
                    </div>
                  </>
                  : null}
              </>
              : null}

          </div>
        </div>
        {live ? null :
          <div className='m-t-100' style={{}}>
            <Footer />
          </div>
        }
      </div >

      {/* share Modal  */}

      {isShareModalVisible &&
        <ShareCourseModal
          isShareModalVisible={isShareModalVisible}
          setShareModalVisible={setShareModalVisible}
          shareUrl={shareUrl} />}
      {/* Add rating Modal */}
      {isRatingModalVisible &&
        <AddRatingModal
          isRatingModalVisible={isRatingModalVisible}
          setIsRatingModalVisible={setIsRatingModalVisible}
          id={coursePageData?.course?.id}
          type={itemFlag}
        />}
      <PageDataLoader visible={loader} />
      {isVideoPreview &&
        <VideoPreview
          isVideoPreview={isVideoPreview}
          setVideoPreview={setVideoPreview}
          previewLink={previewLink}
        />
      }

    </>
  )
}

const mapStateToProps = (state) => {
  const { coursePageData, popularRenderPage, itemType, loader, loginDetails, myResourceData, itemId } = state.aulasMarket;
  // console.log("pppp", state.aulasMarket);
  return {
    coursePageData, popularRenderPage, itemType, loader, loginDetails, myResourceData, itemId
  }
};

const mapDispatchToProps = (dispatch) => ({
  setPopularRender: (val) => dispatch(setPopularRender(val)),
  setLoader: (val) => dispatch(setLoader(val)),
  setMyResources: (val) => dispatch(setMyResources(val)),
  storeSingleBuyNow: res => dispatch(storeSingleBuyNow(res)),
  CoursePageApi: (token, id, callback, loader) => dispatch(CoursePageApi(token, id, callback, loader)),
  VideoResourcePageApi: (token, id, callback, loader) => dispatch(VideoResourcePageApi(token, id, callback, loader)),
  StudyMaterialPage: (token, id, callback, loader) => dispatch(StudyMaterialPage(token, id, callback, loader)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CourseProfile);