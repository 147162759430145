import { ArrowLeftOutlined, CameraFilled } from "@ant-design/icons";
import { Button, Checkbox, Input, PageHeader, Progress, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import image from "Assets/user-management/Group 19.png";
import PhoneInput from "react-phone-number-input";
import Date_Picker from "./datePicker";
import DeleteModal from "./deleteModal";
const { Option } = Select;

var key = 0;
const setHeading = (setKeys, str) => {
  const element = document.getElementById(str);
  // if(element!==undefined)
  // {
  const { top } = element ? element.getBoundingClientRect() : 200;
  // }
  //    top= alert(document.getElementById(str).offsetTop)

  const screenHeight = window.innerHeight;

  if (top > screenHeight) {
  } else {
    if (str == "PermanentAddress" && 150 < top && top < 500) {
      key = 3;
      if (setKeys) {
        setKeys(3);
      }
    }
    if (str == "basicInfo" && 150 < top && top < 500) {
      key = 1;
      if (setKeys) {
        setKeys(1);
      }
    }
    if (str == "currentAddress" && 150 < top && top < 500) {
      key = 1;
      if (setKeys) {
        setKeys(3);
      }
    }
    if (str == "emergencyInfo" && 150 < top && top < 600) {
      key = 1;
      if (setKeys) {
        setKeys(4);
      }
    }
  }
};

const goBack = (history) => {
  history.goBack();
};
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}

function StudentsForm() {
  const [keys, setKeys] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [scroll, setScroll] = useState(0);
  const basicInfoRef = useRef(null);
  const previlageRef = useRef(null);
  const addressRef = useRef(null);
  const emergencyRef = useRef(null);
  const formCntr = useRef(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const history = useHistory();

  const element = document.getElementById("basicInfo");
  const handleScroll = () => {
    setScroll(scroll + 1);
  };
  useEffect(() => {
    setHeading(setKeys, "PermanentAddress");
    setHeading(setKeys, "currentAddress");
    setHeading(setKeys, "basicInfo");
    setHeading(setKeys, "emergencyInfo");
    setHeading(setKeys, "Privilages");

    // console.log('useeffect')
  }, [scroll]);

  return (
    <div style={{ width: "100%", height: "100%", marginTop: "-20px" }}>
      <div style={{ height: "10px", marginLeft: "-20px" }}>
        <PageHeader
          title={<div></div>}
          onBack={() => goBack(history)}
          backIcon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
        />
        {/* <p>hi tyhtrdyguhii cfyuy gygiu</p> */}
      </div>

      <div
        onScroll={() => handleScroll()}
        style={{
          width: "100%",
          marginTop: "40px",
          height: "95%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "100%",
            boxShadow: " 1px 1px 16px #E6E6E6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <h3
              style={{
                cursor: "pointer",
                borderRadius: "3px",
                padding: "10px",
                marginRight: "20px",
                color: keys === 1 ? "#594099" : "#AEAEAE",
                backgroundColor: keys === 1 ? "#F6F4FE" : "white",
              }}
              onClick={() =>
                basicInfoRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Basic information
            </h3>
            {/* <h3 style={{ cursor: "pointer", borderRadius: "3px", height: "50px", marginRight: "20px",  color: keys===2 ? "#594099" : "#AEAEAE", backgroundColor: keys===2 ? "#F6F4FE" : "white", padding: "10px" }}>Privilages</h3> */}
            <h3
              style={{
                cursor: "pointer",
                borderRadius: "3px",
                padding: "10px",
                height: "50px",
                marginRight: "20px",
                color: keys === 3 ? "#594099" : "#AEAEAE",
                backgroundColor: keys === 3 ? "#F6F4FE" : "white",
              }}
              onClick={() =>
                addressRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Address
            </h3>
            <h3
              style={{
                cursor: "pointer",
                borderRadius: "3px",
                padding: "10px",
                marginRight: "20px",
                color: keys === 4 ? "#594099" : "#AEAEAE",
                backgroundColor: keys === 4 ? "#F6F4FE" : "white",
              }}
              onClick={() =>
                emergencyRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Emergency contact
            </h3>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <div style={{ width: "80%", margin: "auto" }}>
              <Progress strokeColor="#594099" percent={50} showInfo={false} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "auto",
              }}
            >
              <p style={{ color: "#AEAEAE" }}>In-progress</p>
              <p style={{ color: "#AEAEAE" }}>15%</p>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                margin: "auto",
              }}
            >
              <Button
                onClick={showModal}
                style={{
                  border: "#AEAEAE solid 1px",
                  borderRadius: "20px",
                  width: "140px",
                  margin: "2px",
                  height: "40px",
                  fontFamily: "Roboto",
                  textAlign: "center",
                  color: "#AEAEAE",
                }}
              >
                DELETE
              </Button>
              <Button
                style={{
                  border: "#FFA931 solid 1px",
                  borderRadius: "20px",
                  width: "140px",
                  margin: "2px",
                  height: "40px",
                  fontFamily: "Roboto",
                  textAlign: "center",
                  color: "#FFA931",
                }}
              >
                SEND REMINDER
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "65%",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingRight: "20px",
            padding: "8px",
          }}
        >
          {/* {window.scrollTo(0, 2000)} */}
          <div
            ref={basicInfoRef}
            style={{
              boxShadow: " 1px 1px 16px #E6E6E6",
              borderRadius: "10px",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <img width="130px" src={image} alt=""></img>
              <div
                style={{
                  backgroundColor: "grey",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "inherit",
                  marginLeft: "88px",
                  marginTop: "-30px",
                  backgroundColor: "white",
                }}
              >
                <CameraFilled
                  style={{
                    color: "#1089FF",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <p style={{ color: "#636363", fontFamily: "roboto" }}>
                Since 18th January, 2020
              </p>
            </div>
            <h2
              style={{
                fontWeight: "bold",
                paddingLeft: "30px",
                fontFamily: "Roboto",
              }}
            >
              Basic information
            </h2>

            <div
              id="basicInfo"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </p>
                  <Input
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      border: "#E6E6E6 solid 1px",
                      borderRadius: "5px",
                    }}
                    placeholder="Enter name"
                    type="text"
                    onChange={() => {}}
                  />
                  <div
                    className="supportPhone"
                    style={{ marginBottom: "20px" }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      Phone Number
                    </p>

                    <PhoneInput
                      style={{
                        width: "100%",
                        border: "1px solid #d9d9d9",
                        paddingLeft: 5,
                        backgroundColor: "transparent",
                      }}
                      defaultCountry="IN"
                      placeholder="Enter phone number"
                      // value={phone}
                      height={"40px"}
                      onCountryChange={(e) => console.log("code", e)}
                      onChange={(e) => setPhone(e)}
                      maxLength={(phone?.startsWith('+91') || phone?.startsWith('91')) && phone?.length == 13 ? 11 : ""}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "47%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                        }}
                      >
                        Gender
                      </p>
                      <Select
                        placeholder="Select  Privilage"
                        defaultValue={null}
                        style={{ width: "100%" }}
                        onChange={() => {}}
                      >
                        <Option value="Admin">Admin</Option>
                        <Option value="Fee Manager">Fee Manager</Option>
                        <Option value="Aulas Support">Aulas Support</Option>
                        <Option value="Data operator">Data operator</Option>
                      </Select>
                    </div>
                    <div style={{ width: "47%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                        }}
                      >
                        Blood Group
                      </p>
                      <Select
                        placeholder="Select  Blood Group"
                        defaultValue={null}
                        style={{ width: "100%" }}
                        onChange={() => {}}
                      >
                        <Option value="Admin">Admin</Option>
                        <Option value="Fee Manager">Fee Manager</Option>
                        <Option value="Aulas Support">Aulas Support</Option>
                        <Option value="Data operator">Data operator</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "50%" }}>
                <div
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "47%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Class
                      </p>
                      <Select
                        placeholder="Select  Class"
                        defaultValue={null}
                        style={{ width: "100%" }}
                        onChange={() => {}}
                      >
                        <Option value="Admin">Admin</Option>
                        <Option value="Fee Manager">Fee Manager</Option>
                        <Option value="Aulas Support">Aulas Support</Option>
                        <Option value="Data operator">Data operator</Option>
                      </Select>
                    </div>
                    <div style={{ width: "47%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                        }}
                      >
                        Roll number
                      </p>
                      <Input
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          border: "#E6E6E6 solid 1px",
                          borderRadius: "5px",
                        }}
                        placeholder="Enter Roll number"
                        type="text"
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Email ID
                  </p>
                  <Input
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      border: "#E6E6E6 solid 1px",
                      borderRadius: "5px",
                    }}
                    placeholder="Enter Email ID"
                    type="text"
                    onChange={() => {}}
                  />
                  <div style={{ width: "200%" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      Date of birth
                    </p>
                    <Date_Picker />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={addressRef}
            id="currentAddress"
            style={{
              marginTop: "30px",
              boxShadow: " 1px 1px 16px #E6E6E6",
              borderRadius: "10px",
              paddingTop: "30px",
            }}
          >
            <h2
              style={{
                marginLeft: "3%",
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              Current address
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginLeft: "20px",
              }}
            >
              <div style={{ width: "47%", marginRight: "5px" }}>
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  House No./Locality/Area
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Address"
                  type="text"
                  onChange={() => {}}
                />
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    marginTop: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  State
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter State"
                  type="text"
                  onChange={() => {}}
                />
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Country
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Country"
                  type="text"
                  onChange={() => {}}
                />
              </div>
              <div style={{ width: "47%", marginLeft: "25px" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Pincode
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Pincode"
                  type="text"
                  onChange={() => {}}
                />
                <div style={{ marginLeft: "38%", marginTop: "-16px" }}>
                  <Checkbox onChange={onChange}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                      }}
                    >
                      I do not have a Pincode
                    </p>
                  </Checkbox>
                </div>

                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  District
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter District"
                  type="text"
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div
            id="PermanentAddress"
            className="PermanentAddress"
            style={{
              marginTop: "30px",
              boxShadow: " 1px 1px 16px #E6E6E6",
              borderRadius: "10px",
              paddingTop: "30px",
            }}
          >
            <h2
              style={{
                marginLeft: "3%",
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              Permanent address
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginLeft: "20px",
              }}
            >
              <div style={{ width: "47%", marginRight: "5px" }}>
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  House No./Locality/Area
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Address"
                  type="text"
                  onChange={() => {}}
                />
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    marginTop: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  State
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter State"
                  type="text"
                  onChange={() => {}}
                />
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Country
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Country"
                  type="text"
                  onChange={() => {}}
                />
              </div>
              <div style={{ width: "47%", marginLeft: "25px" }}>
                <div
                  style={{
                    marginLeft: "38%",
                    marginTop: "-56px",
                    position: "inherit",
                  }}
                >
                  <Checkbox onChange={onChange}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "30px",
                      }}
                    >
                      Same as current address
                    </p>
                  </Checkbox>
                </div>

                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Pincode
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Pincode"
                  type="text"
                  onChange={() => {}}
                />
                <div style={{ marginLeft: "38%", marginTop: "-16px" }}>
                  <Checkbox onChange={onChange}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                      }}
                    >
                      I do not have a Pincode
                    </p>
                  </Checkbox>
                </div>

                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  District
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter District"
                  type="text"
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div
            ref={emergencyRef}
            id="emergencyInfo"
            style={{
              marginTop: "30px",
              boxShadow: " 1px 1px 16px #E6E6E6",
              borderRadius: "10px",
              paddingTop: "30px",
            }}
          >
            <h2
              style={{
                marginLeft: "3%",
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              Emergency Contact
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginLeft: "20px",
              }}
            >
              <div style={{ width: "47%", marginRight: "5px" }}>
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter name"
                  type="text"
                  onChange={() => {}}
                />
                <p
                  style={{
                    color: "#636363",
                    marginBottom: "7px",
                    marginTop: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Relationship
                </p>
                <Input
                  style={{
                    width: "97%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter Relationship"
                  type="text"
                  onChange={() => {}}
                />
              </div>
              <div style={{ width: "47%", marginLeft: "25px" }}>
                <div className="supportPhone" style={{ marginBottom: "20px" }}>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Phone number{" "}
                  </p>

                  <PhoneInput
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      paddingLeft: 5,
                      backgroundColor: "transparent",
                    }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    // value={phone}
                    height={"40px"}
                    onCountryChange={(e) => console.log("code", e)}
                    onChange={(e) => setPhone(e)}
                    maxLength={(phone?.startsWith('+91') || phone?.startsWith('91')) && phone?.length == 13 ? 11 : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default StudentsForm;
