import React, { useEffect, useState } from 'react'
import {  Select, Tabs, Input} from 'antd';
import './styles.css';
import { ArrowLeftOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import TestCard from './localComponent/testCards';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTodayTest, setOpenTab, toggleReloaderFlag, updateFilter, updateSelectedTest } from 'actions/testAdmin';
import PageDataLoader from 'components/pageDataLoader';
import ConfirmationModal from './modals/confirmationModal';
import { NodataCard } from './index';
import CreateTestModal from './modals/createTestModal'
import DropdownCheckbox from './localComponent/dropdownCheckbox';
import SearchBox from './localComponent/searchBox';
import Loader from './localComponent/loader';

const {TabPane} = Tabs;
let timer = false;

const PageHeader = ({classFilters, getResultsBool, setSearchText, title, goBack, syllabusFilters, handleClassFilterSelect, 
    handleSyllabusFilterSelect,onDropdownClose, updateFilter, filter, syllabusDefaultVals}) => {
    const onSearchChange = (e) => {
        setSearchText(e.target.value);
    }
    return (
        <div className="resultPageHeader full-width">
                        <div className="r-c-fs m-r-30">
                            <ArrowLeftOutlined onClick={goBack} style={{fontSize:18}}/>
                            <div className="text-md bold-700 m-l-20"> {title} </div>
                        </div>
                        <div className="testfiltersgroup">
                            <DropdownCheckbox DATA={syllabusFilters} 
                                              placeholder={`Filter by syllabus`}
                                              className="m-r-15"
                                              onSelection={handleSyllabusFilterSelect}
                                              onDropdownClose={onDropdownClose}
                                              defaultValue={syllabusDefaultVals}/>


                            <DropdownCheckbox DATA={classFilters} 
                                              placeholder={`Filter by class`}
                                              onSelection={handleClassFilterSelect}
                                              onDropdownClose={onDropdownClose}
                                              defaultValue={filter?.TAclasses}/>
                            

                            <SearchBox  loading={false} 
                                        onChange={onSearchChange}
                                        placeholder="Search by test name"
                                        style={{width:200, marginLeft:20}}
                                        value={filter?.TAsearch}/>
                        </div>
                    </div>
    )
}

function displaycards(tests, type, isloading, handleResultCardClick){
        
    return (
            <>
            {isloading?
            <div style={{height:'65vh'}}>
            <Loader style={{fontSize:40}}/>
            </div>
            :
            <>
            {tests && tests.length ?
            <div className="m-t-10"> 
                <div className="r-c-fs flex-wrap">            
                    {tests?.map((test,index) =>
                        <TestCard type={type} ispublished={type==='published'?test.declared?true:false:false} test={test} key={index} index={index} fromTodayActivities={true} handleResultCardClick={handleResultCardClick}/>)}
                </div>
            </div>
            : 
            <div style={{width:"100%", height:"80vh"}}>
            <NodataCard imgheight="30%" imgwidth="30%"/>
            </div>
            }</>
            }
            </>
    )
}

function TestList ({
    upcomingTests,
    fetchTodayTest,
    standards,
    getResultsBool,
    ongoingTests,
    cancelledTests,
    submittedTests,
    isCModalVisible, 
    cModalType,
    reloadToggler,
    setOpenTab,
    openTab,
    toggleReloaderFlag,
    updateFilter,
    filter,
    loaders,
    todayResults,
    updateSelectedTest    
}) 
    {
    
    const [filtersChanged, setFiltersChanged] = useState(false);
    // useEffect(() => {
    //     fetchTodayTest(openTab, ()=>{});
    // }, [filtersChanged]);
    useEffect(() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fetchTodayTest(openTab, ()=>{});
        },800);
    }
  , [filter?.TAsearch]);
    const onDropdownClose = () => {
        if (filtersChanged){
            toggleReloaderFlag();
            setFiltersChanged(false);
        }
    }
    const setSearchText = (val) => {
        updateFilter('TAsearch', val)
    }
    const handleClassFilterSelect = (selection)=>{
        setFiltersChanged(true);
        updateFilter('TAclasses', selection);
        fetchTodayTest(openTab, ()=>{});
    }
    const handleResultCardClick = (test) => {
        updateSelectedTest(test)
        history.push('test-publish-result-screen')
      }
    const goBack = () => {
        history.goBack();
    }
    const classFilters = [...standards?.map(item => ({val:`${item.standard_std} - ${item.standard_section}`, id:item.standard_id}))]
    const syllabusFilters = [{val:'Chapter Test', id:1}, {val:'Part Test', id:2}, {val:'Full Test', id:3}];
    const syllabusDefaultVals = [];
    syllabusFilters.forEach((item)=>{
        if (filter?.TAsyllabus.includes(item.val)){
            syllabusDefaultVals.push(item.id);
        }
    })
    const handleSyllabusFilterSelect = (selection)=>{
        let tempsyllabus = [];
        for (let id of selection){
            tempsyllabus.push(syllabusFilters[id-1].val);
        }
        setFiltersChanged(true);
        updateFilter('TAsyllabus', tempsyllabus);
        fetchTodayTest(openTab, ()=>{});
    }
    const history = useHistory();
    return (
        <div className="testListPage" style={{height: "99vh", backgroundColor:"#FAFAFA", margin:-20, padding:20}}>
            <PageHeader classFilters={classFilters}
                        syllabusFilters={syllabusFilters} 
                        title={"Today’s activities"}
                        goBack={goBack}
                        setSearchText={setSearchText} 
                        getResultsBool={getResultsBool}
                        handleClassFilterSelect={handleClassFilterSelect}
                        handleSyllabusFilterSelect={handleSyllabusFilterSelect}
                        onDropdownClose={onDropdownClose}
                        updateFilter={updateFilter}
                        filter={filter}
                        syllabusDefaultVals={syllabusDefaultVals}/>

            <Tabs className="testTab full-height subjectTestTab" defaultActiveKey={openTab}
                    style={{overflowY:'scroll'}}
                    onChange={(activeKey)=>{
                        setOpenTab(activeKey)
                        fetchTodayTest(activeKey, ()=>{});
                        toggleReloaderFlag();
                    }}>
                <TabPane tab="UPCOMING" key="Upcoming" >
                        {displaycards(upcomingTests, "scheduled", loaders?.taPageLoader)}
                </TabPane>
                <TabPane tab="ON-GOING" key="Ongoing" >
                    {displaycards(ongoingTests, "adminlive", loaders?.taPageLoader)}
                </TabPane>
                <TabPane tab="SUBMITTED" key="Submitted">  
                    {displaycards(submittedTests, "submitted_today",  loaders?.taPageLoader)}
                </TabPane>
                <TabPane tab="CANCELLED" key="Cancelled">
                    {displaycards(cancelledTests, "cancelled",  loaders.taPageLoader)}
                </TabPane>
                <TabPane tab="RESULT(S)" key="Results">
                    {displaycards(todayResults, "published",  loaders.taPageLoader, handleResultCardClick)}
                </TabPane>

            </Tabs>
        <CreateTestModal history={history} />
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
        </div>
    )
}
const mapStateToProps = state => {
    const {upcomingTests, standards, getResultsBool, ongoingTests, 
        cancelledTests, submittedTests,  isCModalVisible, 
        cModalType, reloadToggler, openTab, filter, loaders,
        todayResults
    } = state.testAdmin;
    return {upcomingTests, standards, getResultsBool, 
            ongoingTests, submittedTests, cancelledTests,  
            isCModalVisible, cModalType, reloadToggler, openTab, filter, loaders, todayResults}
}

const mapDispatchToProps = dispatch => ({
    fetchTodayTest: (type, callback) => dispatch(fetchTodayTest(type, callback)),
    setOpenTab: (type) => dispatch(setOpenTab(type)),
    toggleReloaderFlag: () => dispatch(toggleReloaderFlag()),
    updateFilter: (key, val) => dispatch(updateFilter(key, val)),
    updateSelectedTest: (test) => dispatch(updateSelectedTest(test))
})

export default connect(mapStateToProps, mapDispatchToProps)(TestList)
