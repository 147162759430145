import React, { useEffect, useState } from 'react'
import MobilePageHeader from './mobilePageHeader'
import { Input } from 'antd';
import { Button } from 'components/Button';
import { connect } from 'react-redux';
import { applyPromo, applyPromoCheckout, getCartDetails, setCouponInCart } from 'actions/studentMarketPlace';
import activeRadioIc from "../../Assets/aulasMarket/select-radio-active.svg"
import emptyRadio from "../../Assets/aulasMarket/select-radio-empty.svg"
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function MobileApplyCoupon({ applyPromo,  applyPromoCheckout, singleBuyNowDetails, setCouponInCart, couponList, getCartDetails, singleCourseDetailsDataContainer }) {
    const [applyLoader, toggleApplyLoader] = useState(false);
    const [isInputTyped, setIsInputTyped] = useState(false);
    const [cartList, setCartList] = useState([]);
    const [params, setParams] = useState({
        amount: (cartList?.cart?.amount - cartList?.cart?.discount).toFixed(2),
        code: '',
    });
    const [selectedCouponCode, setSelectedCouponCode] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const couponKey = location.state?.from;
    console.log("couponKey", params)

    const handleRadioClick = (code) => {
        // console.log("couponKey", couponKey, code)
        setSelectedCouponCode((prevCode) => (prevCode === code ? null : code));
        let paramsOffer = {
            code: code,
            type: 'offer',
        };
        let paramsCheckout = {
            code: code,
            type: 'offer',
            id: singleBuyNowDetails?.item?.[0]?.id,
            itemType: singleBuyNowDetails?.item?.[0]?.resourceType,
        };
        let paramsCoursePage = {
            code: code,
            type: 'offer',
            id: singleCourseDetailsDataContainer?.course?.id || null,
            itemType: singleCourseDetailsDataContainer?.course?.resourceType || null,
        };
        toggleApplyLoader(true);
        if (couponKey !== "checkout" && couponKey !== "courseDescription") {
            applyPromo(paramsOffer, (res) => {
                setParams((prev) => ({
                    ...prev,
                    amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
                    code: code,
                }))
                setCouponInCart(true)
                history.push({
                    pathname: "/cart",
                    state: {
                        amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
                        code: code,
                    }
                });
                toggleApplyLoader(false);
            }, (res) => {
                setCouponInCart(true);
                toggleApplyLoader(false)
            });
        } else if (couponKey === "checkout") {
            applyPromoCheckout(paramsCheckout, () => {
                // history.push("/user-checkout")
                history.push({
                    pathname: "/user-checkout",
                    state: {
                        code: code,
                    }
                });
            }, () => {
                setCouponInCart(true);
            });
        } else if (couponKey === "courseDescription") {
            console.log("iniiiidddd")
             applyPromoCheckout(paramsCoursePage, (res) => {
                history.push({
                    pathname: "/fee-plan",
                    state: {
                        singleCourseDetailsDataContainer:singleCourseDetailsDataContainer,
                        code: code,
                        
                    }
                });
            })
        }
    };

    console.log("couponKey", couponKey, selectedCouponCode, singleCourseDetailsDataContainer?.course?.id)

    useEffect(() => {
        if (couponKey !== "courseDescription") {
            getCartDetails((res) => {
                setCartList(res);
                setParams((prev) => ({
                    ...prev,
                    amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
                }))

            });
        }
    }, [])

    const CouponDesign = ({ item }) => {
        const dateString = item?.endDate;
        const dateObject = new Date(dateString);
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObject.getUTCFullYear();

        const formattedDate = `${day}-${month}-${year}`;
        return (
            <div style={{ width: "100%", height: "100px", marginBottom: "15px", borderRadius: "8px", backgroundColor: "#FFFFFF", boxShadow: "0px 0px 6px #00000029" }}>
                <div className='r-c-sb' style={{ padding: 20, alignItems: "flex-start" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{item?.code}
                        <div style={{ color: "#636363", fontSize: "12px", fontWeight: "normal" }}>Use this promotional offer to get an instant discount. Valid until {formattedDate}</div>
                    </div>
                    <div className='cursor-pointer' style={{ position: "relative", transform: "translate(5px, -10px)" }} onClick={() => handleRadioClick(item?.code)}>   <img src={selectedCouponCode === item?.code ? activeRadioIc : emptyRadio} alt="Radio Icon" /></div>
                </div>
            </div>
        )
    }
    const handleInputPromo = (e) => {
        const filteredValue = e.target.value.replace(/[^A-Za-z0-9]/g, ''); // Allow only alphanumeric characters
        setParams((prev) => ({
            ...prev,
            code: filteredValue
        }))
        console.log("filteredValue", filteredValue)
        setIsInputTyped(filteredValue.trim() !== '');
    }
    const handleApplyClick = () => {
        let paramsOffer = {
            code: params?.code,
            type: 'offer',
        };
        let paramsCheckout = {
            code: params?.code,
            type: 'offer',
            id: singleBuyNowDetails?.item?.[0]?.id,
            itemType: singleBuyNowDetails?.item?.[0]?.resourceType,
        };
        toggleApplyLoader(true);
        if (couponKey !== "checkout") {
            applyPromo(paramsOffer, (res) => {
                setParams((prev) => ({
                    ...prev,
                    amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
                }))
                setCouponInCart(true)
                history.push("/cart")
                toggleApplyLoader(false);
            }, (res) => {
                setCouponInCart(true);
                toggleApplyLoader(false)
            });
        } else if (couponKey === "checkout") {
            applyPromoCheckout(paramsCheckout, () => {
                history.push("/user-checkout")
            }, () => {
                setCouponInCart(true);
            });
        }
    }
    return (
        <div className='scroll-bar-universal' style={{}}>
            <MobilePageHeader title={"Apply coupon"} onBackPress={() => { history.goBack() }} sider={true} />
            <div className='p-15'>
                <div className=' r-c-c' style={{ display: 'flex' }}>
                    <Input
                        className='price-coupon-filed'
                        type="text"
                        placeholder='Enter coupon code'
                        onChange={(e) => handleInputPromo(e)}
                        value={params?.code}
                        style={{ height: "4.5vh", }}
                    ></Input>
                    <Button
                        loading={applyLoader}
                        className='r-c-c text-xm bold-600'
                        type="button"
                        onClick={handleApplyClick}

                        style={{
                            cursor: isInputTyped ? 'pointer' : 'not-allowed',
                            border: ` 1px solid #594099 ${isInputTyped ? 1 : 0.4}`,
                            borderRadius: " 0px 4px 4px 0px",
                            backgroundColor: 'white',
                            padding: '5px 10px',
                            color: "#594099",
                            height: "4.5vh",
                            opacity: isInputTyped ? 1 : 0.4
                        }}
                        disabled={!isInputTyped}
                    >
                        APPLY
                    </Button>
                </div>
                <div className='m-t-20' style={{}}>{couponList?.items?.map((item, index) => (
                    <CouponDesign item={item} />
                ))}</div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { renderPageLoading, couponInCart, storePromoPrice, couponList, singleBuyNowDetails, singleCourseDetailsDataContainer } = state.studentMarketPlace
    const { user } = state.session
    return { user, couponInCart, storePromoPrice, renderPageLoading, couponList, singleBuyNowDetails, singleCourseDetailsDataContainer }
};

const mapDispatchToProps = (dispatch) => ({
    applyPromoCheckout: (params, callback, succesCallback) => dispatch(applyPromoCheckout(params, callback, succesCallback)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    getCartDetails: (callback) => dispatch(getCartDetails(callback)),
    applyPromo: (params, callback, succesCallback) => dispatch(applyPromo(params, callback, succesCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileApplyCoupon)
