import { CaretDownOutlined } from '@ant-design/icons';
import { Checkbox, Input, Popover, Tooltip } from 'antd';
import { TMPX } from 'ifsc/src/node/bank';
import React, { useEffect, useState } from 'react'

function DropDownCheckBoxWithStudent({
    DATA,
    placeholder,
    style,
    className,
    onSelection,
    onDropdownClose,
    defaultValue,
    roleFilter,
    typeFilter,
    role,
    type
  }) {
    const [checked, setChecked] = useState(type==='Type'?[...DATA].fill():type==='Role'?[role==='All'?true:false,role==='Teacher'?true:false,role==='Data Operator'?true:false,role==='Operation'?true:false,role==='Student'?true:false ]:[]);
    const [trigger, setTrigger] = useState(false);
    const [roleArray,setRoleArray] = useState([]);
    const [checkArray,setCheckArray] = useState([]);
    const [typeArray,setTypeArray] = useState([])
    const [checkRoleArray,setCheckRoleArray] = useState([]);

    useEffect(() => {
      let Role = role==="All"?[1]:role==='Teacher'?[2]:role==='Data Operator'?[3]:role==='Operation'?[4]:role==='Student'?[5]:0;
      if(Role){
      setCheckRoleArray([...Role])
      }else{
        setCheckRoleArray([1])
      }
    }, [])

    useEffect(() => {
      if(checked[0]===true){
        roleFilter(["Teacher","Data Operator","Operation","Student"])
    }
    else if(checked[1]===true){
        roleFilter(["Teacher"])
        setRoleArray([...roleArray,'Teacher'])
    }else if(checked[2]===true)
    {
      roleFilter(["Data Operator"])
      setRoleArray([...roleArray,'Data Operator'])
    }else if(checked[3]===true){
      roleFilter(["Operation"])
      setRoleArray([...roleArray,'Operation'])
    }else if(checked[4]===true){
        roleFilter(["Student"])
        setRoleArray([...roleArray,'Student'])
      }
    }, [])
       
  const onChange = (index, item) => {
    let max = 3;
    if (['Teacher', 'Data Operator', 'Operation', 'Student'].includes(item?.val)) {
      max = 4;
    } else {
      max = 2;
    }
    let temp = checkRoleArray;
    if (index === 0) {
      if (temp.length) {
        if (temp[0] !== 1) {
          temp = [1];
        } else {
          temp = [];
        }
      } else {
        temp = [1]
      }
    } else {
      let tIndex = temp.indexOf(1);
      if (tIndex !== -1) {
        temp.splice(tIndex, 1);
      }
      let t2Index = temp.indexOf(index + 1);
      if (t2Index !== -1) {
        temp.splice(t2Index, 1);
      } else {
        temp.push(parseInt(index + 1))
      }
      if (temp.length == 0 || temp.length == max) {
        temp = [1];
      }
      console.log("max", max)
    }
    setCheckRoleArray([...temp])
    console.log("checkRoleArray", checkRoleArray, checkRoleArray.length);

    let temp1=roleArray;
    if((DATA?.[index].val) == 'All'){
      temp1 =['Teacher', 'Data Operator', 'Operation', 'Student']; 
    }
      if(temp1.includes(DATA?.[index].val)){
        temp1.splice(temp1?.indexOf(DATA?.[index]?.val),1);
        }else{
        temp1.push(DATA?.[index].val)
      }
      if(temp[0] == 1){
        setRoleArray([]);
      }else{
        setRoleArray(temp1);
      }
      
      console.log("temp1",temp1,roleArray)
      if(roleFilter){
        roleFilter(temp1)}; 

        let temp2=typeArray;
        if(temp2.includes(DATA?.[index].val)){
          temp2.splice(temp2?.indexOf(DATA?.[index]?.val),1);
          }else{
          temp2.push(DATA?.[index].val)
        }
        if(temp[0] == 1){
          setTypeArray([]);
        }else{
          setTypeArray(temp2);
        }
        console.log("temp2",temp2,typeArray);
        if(typeFilter){
          typeFilter(temp2)
        };

    // let temp1 = roleArray;
    // if((DATA?.[index].val) == 'All'){
    //     temp1 =['Teacher', 'Data Operator', 'Operation', 'Student'];
    // }else{
    //   if (temp1.includes(DATA?.[index].val)) {
    //     temp1.splice(temp1?.indexOf(DATA?.[index]?.val), 1);
    //   } else {
    //     temp1.push(DATA?.[index].val)
    //   }
    // }   
    // setRoleArray(temp1);
    // console.log("temp1", temp1, roleArray)
    // if (roleFilter) {
    //   roleFilter(temp1)
    // };
  }

    const CheckboxContent = () => {
        useEffect(() => {}, [trigger,]);
        // getVal(setCheckArray,type,role);
        
        return (
          <div
            id='checkbox'
            className="flex-column organistationcardfilter"
            style={{
              maxWidth: "200px",
              maxHeight: "30vw",
              overflowY: "auto",
              overflowX: "hidden",
              marginLeft: -16,
              marginRight: -16,
              marginTop: -12,
              width: "fit-content",
              // position: 'relative'
            }}
          >
            <Checkbox.Group
              className="flex-column"
              onChange={onSelection}
              // defaultValue={defaultValue}
              value={checkRoleArray}
            >
              {DATA?.length > 0
                ? DATA?.map((item, index) => (
                    <Checkbox
                      getPopupContainer={() => document.getElementById('checkbox')}
                      className="black p-10 m-l-0 full-width"
                      style={{
                        backgroundColor: checkRoleArray.includes(index+1) ? "#F6F4FE" : null,
                        fontWeight: checkRoleArray.includes(index+1) ? "700" : "400",
                      }}
                      value={item?.id}
                      onChange={()=>{onChange(index,item)}}
                      // defaultChecked={type!='Role'?false:index==1 && role==='Teacher'?true: index==2 &&role==='Data Operator'?true:index==3&& role==='Operation'?true:false}
                      /* checked={true} */
                      // defaultChecked={true}
                    >
                      <Tooltip
                        title={item.label}
                        placement="left"
                        mouseEnterDelay={0.2}
                        overlayInnerStyle={{
                          borderRadius: 5,
                          marginLeft: 7,
                          marginRight: 7,
                        }}
                      >
                        <div
                          className="m-l-10 text-overflow-ellipsis full-width"
                          style={{ fontSize: 14 }}
                        >
                          {item.label}
                        </div>
                      </Tooltip>
                    </Checkbox>
                  ))
                : null}
            </Checkbox.Group>
          </div>
        );
      };
    return (
        <Popover
        placement="bottomRight"
        content={CheckboxContent}
        trigger="click"
        overlayInnerStyle={{ borderRadius: 7 }}
        onVisibleChange={(bool) => {
          if (!bool && onDropdownClose != undefined) {
            onDropdownClose();
          }
        }}
      >
        <div
          className="r-c-c"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            border: "1px solid #E6E6E6",
          }}
        >
          <Input
            style={{ ...style, width: "150px", height: "30px" }}
            className={className}
            placeholder={placeholder}
            size="small"
            value={null}
            bordered={false}
            // suffix={}
            readOnly
          />
          <CaretDownOutlined style={{ color: "#AEAEAE" }} className="m-r-5" />
        </div>
      </Popover>
  )
}

export default DropDownCheckBoxWithStudent