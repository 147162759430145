import React, { useState, useEffect } from 'react'
import { Input, Tabs, Dropdown, Image, Select, PageHeader, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import AddTeacherModal from "../modals/addTeacher"
import { connect } from 'react-redux'
import {
  fetchTeacherData, fetchAdminLibraryData, updateSetSort, updateSetTopic, updateChapterFilter,
  updateCurrentSub, updateCurrentIcon, updateSetType, fetchTeacherLibraryData, updatepdf, fetchAdminChapterFilter2
} from 'actions/digitalLibrary'
import EmptyCard from "./emptyCard";
import { useHistory } from 'react-router-dom'
import LectureNotes from '../components/LectureNotes'
import Videocomponent from '../components/Videocomponent'
import lecturenotes from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg"
import questBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg"
import PageDataLoader from 'components/pageDataLoader'
import UploadModal from '../modals/uploadModel'
import Uploadfiles from '../modals/uploadfiles'
import Videouploadmodel from '../modals/Videouploadmodel'
import Succesfulmodel from '../modals/Succesfulmodel'
import { Button } from 'components/Button'

const { Search } = Input
const { TabPane } = Tabs
const { Option } = Select
const { Text } = Typography



const SubjectPageEmpty = ({
  fetchTeacherData, teacherMangementList, fetchAdminLibraryData, adminLibraryList, selectedSubject, updateSetSort,
  updateSetTopic, selectedStd, updateChapterFilter, arrStoreData, updateCurrentSub, updateCurrentIcon, updateSetType,
  fetchTeacherLibraryData, updatepdf, setTabType, user, fetchAdminChapterFilter2, pageLoader, togglePageLoader, getChaptersListForFilter, currentType
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalUpload, setModalUpload] = useState(false)
  const [modal, setmodel] = useState(false)
  const [uploadFileModal, toggleUploadFileModel] = useState(false)
  const [type, setType] = useState('')
  const [tabKey, setTabKey] = useState('1')
  const history = useHistory();

  useEffect(() => {
    togglePageLoader(true);
    updateSetTopic('')
    updateChapterFilter('')

    if (currentType == 'lecture-notes') {
      setTabKey('1')
      updateSetType(currentType)
    }
    else if (currentType == 'video') {
      setTabKey('2')
      updateSetType(currentType)
    }
    else if (currentType == 'question-bank') {
      setTabKey('3')
      updateSetType(currentType)
    }
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
      fetchAdminLibraryData(() => togglePageLoader(false))
      fetchAdminChapterFilter2()
      fetchTeacherData()
    }
    else {
      fetchTeacherLibraryData()
    }
    updatepdf(null)
  }, [])

  const onChange = (value) => {
    updateChapterFilter(value)
    togglePageLoader(true);
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
      fetchAdminLibraryData(() => togglePageLoader(false))
    }
    else {
      fetchTeacherLibraryData()
    }
  }
  const onChangeSort = (value) => {
    updateSetSort(value)
    togglePageLoader(true);
    updateSetType(setTabType);
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
      fetchAdminLibraryData(() => togglePageLoader(false))
    }
    else {
      fetchTeacherLibraryData()
    }
  }
  const onSearch = (val) => {
    console.log('search:', val);
  }

  const onSearchTopic = (value) => {
    updateSetTopic(value)
    togglePageLoader(true);
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
      fetchAdminLibraryData(() => togglePageLoader(false))
    }
    else {
      fetchTeacherLibraryData()
    }
  }

  const handleHistory = () => {
    updateCurrentSub({})
    updateCurrentIcon('')
    updateChapterFilter('')
    history.goBack()
  }

  const onDeselect = () => {
    updateChapterFilter();
    togglePageLoader(true);
    if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
      fetchAdminLibraryData(() => togglePageLoader(false))
    }
    else {
      fetchTeacherLibraryData()
    }
  }

  const handleTabChange = (key) => {
    setTabKey(key)
    if (key == '1') {
      updateSetType("lecture-notes")
      togglePageLoader(true);
      if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
        fetchAdminLibraryData(() => togglePageLoader(false))
      }
      else {
        fetchTeacherLibraryData()
      }
    }
    else if (key == '2') {
      updateSetType("video")
      togglePageLoader(true);
      if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
        fetchAdminLibraryData(() => togglePageLoader(false))
      }
      else {
        fetchTeacherLibraryData()
      }
    }
    else if (key == '3') {
      updateSetType("question-bank")
      togglePageLoader(true);
      if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
        fetchAdminLibraryData(() => togglePageLoader(false))
      }
      else {
        fetchTeacherLibraryData()
      }
    }

  }

  return (
    <div>
      <div>
        <div className='r-c-sb'>
          <div style={{ width: "10%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <PageHeader
              title={<div className="text-md" style={{ fontSize: "8px !important" }}>{selectedSubject.subjects_name}</div>}
              onBack={() => handleHistory()}
              backIcon={<ArrowLeftOutlined />}
            />
          </div>
          <div style={{ display: "flex", width: "55%", marginRight: "3%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Button className='btn-radius-15__height-38 m-l-20 text-xmd' type="default" onClick={() => setIsModalVisible(true)} icon={"+"}>Add A Teacher</Button>

            <Button className='m-l-15 m-r-10' style={{width: "180px"}} onClick={() => setModalUpload(true)} type="primary" icon={"+"}>Upload</Button>
            <UploadModal visible={modalUpload} setVisible={setModalUpload} toggleUploadFileModel={toggleUploadFileModel} setType={setType} />
            {type === 'video' ? <Videouploadmodel type={type} classess={arrStoreData[0]} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} /> : (uploadFileModal && <Uploadfiles classess={arrStoreData[0]} type={type} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} />)}


            <Search disabled={false} className="input-search-text-empty" placeholder="Search" bordered="false" allowClear="true" onSearch={onSearchTopic} />
          </div>
          <AddTeacherModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} teacherMangementList={teacherMangementList} />
        </div>
        <Text style={{ marginLeft: "55px", color: "#AEAEAE", font: "normal normal normal 14px/19px Roboto" }}>{selectedStd?.standard}:{selectedStd?.std_section}</Text>
      </div>
      <div>
        <Tabs activeKey={tabKey}
          onTabClick={handleTabChange}
        >
          <TabPane tab="Notes" key="1">
            <div className="sel-chap-div">
              <Select
                showSearch
                allowClear={true}
                style={{ width: 300, borderRadius: '8px' }}
                placeholder="Select Chapter"
                optionFilterProp="children"
                onChange={onChange}
                onDeselect={onDeselect}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {getChaptersListForFilter ? getChaptersListForFilter.map((item) => (
                  <Option value={item.name}>{item.name}</Option>
                )) : null
                }
              </Select>

            </div>
            <div className="chapter-flex">
              {adminLibraryList?.length != 0 ? adminLibraryList?.map((item, index) =>
              (<div>
                <LectureNotes chapterName={item.chapterName} topic={item.title} file={item.file} id={item.id} filesSize={item.size} types={"lecture-notes"} img={lecturenotes} chapterId={item.chapterId} index={index} />
              </div>
              )
              ) :
                (
                  <EmptyCard uploadButton={true} />
                )
              }
            </div>
          </TabPane>
          <TabPane tab="Videos" key="2">
            <div className="sel-chap-div">
              <Select
                showSearch
                allowClear={true}
                style={{ width: 300, borderRadius: '8px' }}
                placeholder="Select Chapter"
                optionFilterProp="children"
                onChange={onChange}
                onDeselect={onDeselect}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {getChaptersListForFilter ? getChaptersListForFilter.map((item) => (
                  <Option value={item.name}>{item.name}</Option>
                )) : null
                }
              </Select>
            </div>
            <div className="chapter-flex">
              {adminLibraryList?.length != 0 ? adminLibraryList?.map((item) =>
              (<div>
                <Videocomponent url={item.link} chapterName={item.chapterName} topic={item.title} id={item.id} filesSize={item.size} types={"video"} chapterId={item.chapterId} />
              </div>
              )
              ) :
                (
                  <EmptyCard uploadButton={true} />
                )
              }
            </div>

          </TabPane>
          <TabPane tab="Question Bank" key="3">
            <div className="sel-chap-div">
              <Select
                showSearch
                allowClear={true}
                style={{ width: 300, borderRadius: '8px' }}
                placeholder="Select Chapter"
                optionFilterProp="children"
                onChange={onChange}
                onDeselect={onDeselect}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {getChaptersListForFilter ? getChaptersListForFilter.map((item) => (
                  <Option value={item.name}>{item.name}</Option>
                )) : null
                }
              </Select>
            </div>
            <div className="chapter-flex">
              {adminLibraryList?.length != 0 ? adminLibraryList?.map((item, index) =>
              (<div>
                <LectureNotes chapterName={item.chapterName} topic={item.title} file={item.file} id={item.id} filesSize={item.size} types={"question-bank"} img={questBankImg} chapterId={item.chapterId} index={index} />
              </div>
              )
              ) :
                (
                  <EmptyCard uploadButton={true} />
                )}
            </div>

          </TabPane>
        </Tabs>
      </div>
      <Succesfulmodel togglePageLoader={togglePageLoader} fetchApi={true} />
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    selectedStd,
    getChaptersListForFilter,
    arrStoreData,
    currentType

  } = state.digitalLibrary;
  const { user } = state.session;
  return {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    selectedStd,
    user,
    getChaptersListForFilter,
    arrStoreData,
    currentType

  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  updateSetSort: (value) => dispatch(updateSetSort(value)),
  updateSetTopic: (value) => dispatch(updateSetTopic(value)),
  fetchAdminLibraryData: (callback) => dispatch(fetchAdminLibraryData(callback)),
  fetchAdminChapterFilter2: () => dispatch(fetchAdminChapterFilter2()),
  updateSetType: (e) => dispatch(updateSetType(e)),
  updateChapterFilter: (value) => dispatch(updateChapterFilter(value)),
  updatepdf: (data) => dispatch(updatepdf(data)),
  updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
  updateCurrentIcon: (data) => dispatch(updateCurrentIcon(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(SubjectPageEmpty);