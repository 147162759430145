import {
  UPDATE_BRIGOSHA_MEDIA,
  UPDATE_LOCAL_MEDIA,
  UPDATE_REMOTE_MEDIA,
  UPDATE_ACTIVE_SPEAKER,
  UPDATE_CONNECTION_STATE,
} from 'actions/brigoshaMedia';

const initialState = {
  brigoshaMedia: false,
  localMedia: false,
  remoteMedia: [],
  activeSpeaker: '',
  isMediaDisconnected: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONNECTION_STATE:
      return {
        ...state,
        isMediaDisconnected: action.value,
      };
    case UPDATE_BRIGOSHA_MEDIA:
      return {
        ...state,
        brigoshaMedia: action.media,
      };
    case UPDATE_LOCAL_MEDIA:
      return {
        ...state,
        localMedia: action.media,
      };
    case UPDATE_ACTIVE_SPEAKER:
      return {
        ...state,
        activeSpeaker: action.name,
      };
    case UPDATE_REMOTE_MEDIA:
      const videoArray = [];
      for (let i = 0; i < action.media.length; i += 1) {
          videoArray.push(action.media[i]);
      }
      return {
        ...state,
        remoteMedia: [...videoArray],
      };
    default:
      return state;
  }
};

export default reducer;
