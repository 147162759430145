import { ArrowLeftOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { fetchClassByCurrData, setImportMeta } from "actions/practiseTest";
import { Col, Progress, Row } from "antd";
import PageDataLoader from "components/pageDataLoader";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Loader from "./localComponents/loader";





const ImportPracClasses = ({importMeta, fetchClassByCurrData, setImportMeta, loaders}) => {
  const history = useHistory();
  useEffect(()=>{
    setImportMeta('sampleGroupBool', true);
    fetchClassByCurrData();
  }, []);
  const ClassCard = ({standardData, curriculumId,setSelectedClassData})=>{
    return (
        <div className="adminTestCard customCardShadow m-t-10 cursor-pointer shadow-box hover-shadow m-r-20"
                style={{height: 130, width:250, minWidth:120, padding: 0, borderRadius:10, padding:20}}
                onClick={() => {
                    setImportMeta('selectedStandardObj', {...standardData, curriculumId});
                    if (importMeta.selectedTypeId===1){
                      setImportMeta('selectedSampleQuestionPapers', []);
                      setImportMeta('sampleGroupBool', true);
                      setImportMeta('sampleIndividualBool', false);
                      setImportMeta('sampleGroupAllSelectBool' , false);
                      history.push("practise-test-sample-group-import");
                    }else if(importMeta.selectedTypeId===2){
                      history.push('practise-test-import-subwise')
                    }
                }}>
            <Row  style={{height:"56%"}}>
                <Col span={24}>
                    <div className="bold r-c-c-c" style={{color: "#594099", fontFamily:"serif"}}>
                        <div style={{width:"100%"}} className='text-xlg text-center text-overflow p-r-5 p-l-5'>
                            {standardData.name}
                        </div>
                                <div style={{ width:'100%', color:"#8C8C8C"}} className="text-center text-overflow p-r-5 p-l-5">
                                                {standardData.stream==""?"NA":standardData.stream}
                                </div>
                    </div>
                </Col>
            </Row>
        </div>
      )
  }
  const goBack = () => {
    history.goBack();
  }
  return (
    <div style={{
      backgroundColor:"#FAFAFA", margin:-20, padding:20
    }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontWeight: "800", fontSize: "20px" }} className="m-b-20"><ArrowLeftOutlined className="cursor-pointer m-r-20" onClick={goBack}/> {importMeta?.selectedQBType}</div>
      </div>
      {
        loaders?.importClassLoader?
        <div className="full-width r-c-c" style={{height:"90vh"}}>
        <Loader style={{fontSize:40}} />
        </div>
        :
        <>
          {importMeta?.classCardData?.map((curriculumData, index) => (
            <>
              <div className="text-xmd bold-600 color-black m-b-15">
                {" "}
                {curriculumData.name}{" "}
              </div>
              <div className="full-width r-afs flex-wrap m-b-20">
                {curriculumData.standards?.map((standardData, index) => (
                  <ClassCard
                    type="classcard"
                    standardData={standardData}
                    curriculumId={curriculumData.id}
                  />
                ))}
              </div>
            </>
          ))}
        </>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.practiseTest;
  return {  
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchClassByCurrData: (callback) => dispatch(fetchClassByCurrData(callback))
})



export default connect(mapStateToProps,mapDispatchToProps)(ImportPracClasses)