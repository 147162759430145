import { Modal } from 'antd'
import AnimatedTextField from 'components/AnimatedTextField'
import { Button } from 'components/Button'
import { validateEmail } from 'pages/studentAulasWallet/studentKycData'
import React, { useState } from 'react'

function SendInvoiceEmailModal({ sendInvoiceEmailModalVisible, setSendInvoiceEmailModalVisible, emailId, setEmailId, handleSendInvoiceOnClick, sendInvoiceBtnLoader, singleFeeItem }) {
    const handleCancel = () => {
        setSendInvoiceEmailModalVisible(false)
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={sendInvoiceEmailModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Confirm</center>
            <center className='m-t-10' style={{ color: "#636363", fontSize: 16 }}>Send invoice to the following email id</center>
            <div className='m-t-30'>
                <AnimatedTextField
                    label={"Email ID"}
                    isMandatory={true}
                    inputId={"-997"}
                    value={emailId}
                    handleInputChange={(e) => { setEmailId(e.target.value); }}
                />
                {(emailId && validateEmail(emailId)) ? <div style={{ color: "red", fontSize: 12, fontWeight: 600, display: "flex", justifyContent: "flex-end", marginRight: 10 }}>Enter valid email id</div> : <></>}
            </div>
            <center>
                <div className='r-jsb m-t-50' style={{ width: "60%" }}>
                    <div style={{ color: "#636363", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setSendInvoiceEmailModalVisible(false) }}>CANCEL</div>
                    <Button type="primary"
                        loading={sendInvoiceBtnLoader}
                        disabled={!emailId || validateEmail(emailId)}
                        onClick={() => handleSendInvoiceOnClick(singleFeeItem?.transaction?.id)}
                    >SEND</Button>
                </div>
            </center>
        </Modal>
    )
}

export default SendInvoiceEmailModal