import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import userAvatar from "Assets/user-management/Icon awesome-user-alt.svg";
import React, { useState } from "react";
// import image from 'Assets/user-management/ic-students.svg'
import image from "Assets/user-management/Mask Group 2.png";
import moment from "moment";
import { connect } from "react-redux";
import {
  getStats,
  getUserRequests,
  postApproveReject,
  setPhone,
  setStatus,
} from "actions/userManagement";
import { useHistory } from "react-router";
import PageDataLoader from "components/pageDataLoader";
import DeleteModal from "./deleteModal";

function RequestUserCard({
  border,
  rejected,
  name,
  role,
  phoneNo,
  tab,
  getStats,
  createdAt,
  avatar,
  postApproveReject,
  getUserRequests,
  id,
  setStatus,
  fieldDisabledForFee,
  item
}) {
  const [pageLoader, setPageLoader] = useState(false);
  const [approved, setApproved] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const handleOk = () => {
    // e.stopPropagation();
    
    setPageLoader(true);
    postApproveReject(()=>{setButtonClicked(false),setPageLoader(false),setShowModal(false)}, chndleChangeButton, id, true);

  };
  const handleCancel = () => {
    setShowModal(false);
    return true;
  };
  const successApproveCallback = () => {
    
    
   
  };
  const viewForm = () => {
    history.push("/user-management/all-requests/approval");
  };
  const chndleChangeButton = () => {
    setApproved(true);
    setPageLoader(true);
    // console.log("after success");
    if (tab !== null) {
      getUserRequests(() => setPageLoader(false));
    } else {
      getStats(() => setPageLoader(false));
    }
  };
  const handleApprove = (e) => {
    e.stopPropagation();
    setShowModal(true);
    setStatus("approved");
    // setButtonClicked(true)

    // console.log("button clicked", buttonClicked);
  };

  const handleReject = (e) => {
    e.stopPropagation();
    setStatus("rejected");

    setShowModal(true);
  };
  return (
    <div
      className="requestCardStyle"
      style={{ border: border ? "#E6E6E6 solid 1px" : null }}
    >
      <div
        onClick={() => {
          if (border) viewForm();
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          cursor: border ? "pointer" : "default",
          minWidth: "220px",
        }}
      >
        {/* <img
          width="60px"
          height="60px"
          style={{ borderRadius: "50%" }}
          src={avatar}
        ></img> */}
        {avatar.substring(avatar.length - 11, avatar.length) !==
        "default.png" ? (
          <img
            width="70px"
            height="70px"
            style={{ borderRadius: "50%" }}
            src={avatar}
          ></img>
        ) : (
          <img
            width="70px"
            height="70px"
            style={{
              borderRadius: "50%",
              backgroundColor: "#F6F4FE",
              padding: "15px",
            }}
            src={userAvatar}
          ></img>
        )}
        <div style={{ marginLeft: "10px" }}>
          <h3
            style={{
              marginBottom: "0",
              fontFamily: "Roboto",
              maxWidth: "160px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </h3>
          <p style={{ color: "#AEAEAE", marginBottom: "0" }}>{phoneNo}</p>
          <p style={{ color: "#AEAEAE", marginBottom: "0" }}>{role}</p>
          {rejected?
          <p style={{ color: "#AEAEAE" }}>{moment(item?.updatedAt).fromNow()}</p>
          :
          <p style={{ color: "#AEAEAE" }}>{moment(createdAt).fromNow()}</p>
          }
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "40%",
          justifyContent: "right",
          pointerEvents: fieldDisabledForFee ? "none" : null,
        }}
      >
        {!rejected ? (
          <CloseCircleOutlined
            className="styleCloseCircleOutlinedB"
            onClick={handleReject}
            style={{ zIndex: 5}}
          />
        ) : null}
        <CheckCircleOutlined
          className="styleCheckCircleOutlinedB"
          onClick={handleApprove}
          style={{ zIndex: 5 }}
        />
      </div>
      {/* <PageDataLoader visible={pageLoader} /> */}
      <DeleteModal
        isModalVisible={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        type="aproveOrReject"
        name={name}
        role={role}
        loading={pageLoader}
       
      />
    </div>
  );
}

// export default RequestUserCard

const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    sectionDetails,
    dashBoardDataByRole,
    studentsListByStd,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashBoardDataByRole,
    dashboardData,
    sectionDetails,
    studentsListByStd,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setUserName: (name) => dispatch(setUserName(name)),
  getUserRequests: (callback) => dispatch(getUserRequests(callback)),
  postApproveReject: (callback, callbackButton, id, buttonClicked) =>
    dispatch(postApproveReject(callback, callbackButton, id, buttonClicked)),
  setStatus: (m) => dispatch(setStatus(m)),
  getStats: (m) => dispatch(getStats(m)),

  // setName: (search) => dispatch(setName(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestUserCard);
