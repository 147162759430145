import {
  CloseOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, PageHeader, Row, Tabs, Upload, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Color, Notification } from "services";
import { connect } from "react-redux";
import {
  fetchTestQuestions,
  updateQParam,
  saveNext,
  setCurrentIndex,
  removeAnswer,
  navigateQuestion,
  handleSelectSubject,
  setSelectedSubIndex,
  updateImage,
  removeAnswerImage,
  updateCurrentDuration,
  finalSubmit,
  toggleOutOfTimeModal,
  selectQIndex,
  setStopTimer,
  fetchTestDetails,
  updatePreviousQId,
} from "actions/studentTest";
import ViewImgModal from "./modals/viewImgModal";
import AutoSubmitModal from "./modals/autoSubmitModal";
import "./styles.css";
// import './css/TestScreen.css';
import SubmitModal from "./modals/submitModal";
import PageDataLoader from "components/pageDataLoader";
import { showMenu } from "actions/layout";
import { Prompt } from "react-router-dom";
import { TestTimer } from "./timer";
import { setDuration } from "../../actions/studentTest";
import SubmittedSuccessModal from "./modals/submittedSuccessModal";
import OutoftimeModal from "./modals/outoftimeModal";
import ImageViewer from "components/ImageViewer";
import ExitModalTest from "./modals/exitModalTest";
import OrQuestion from "./components/orQuestions";
import Comprehensive from "./components/comprehensive";
import { QuestionTypes } from "./components/questionTypes";
import QuestionPalette from "./components/QuestionPalette";

const { TabPane } = Tabs;
const { confirm } = Modal;

var stringToHTML = function (str) {
  var dom = document.createElement("span");
  dom.innerHTML = str;
  return dom;
};

export const orangecircle = (position_val) => (
  <div
    style={{
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: "#FFA931",
      position: "absolute",
      top: position_val,
      right: position_val,
    }}
  ></div>
);
const answerStatus = [
  {
    title: "Attempted",
    key: "attempted",
    backgroundColor: Color.purple,
    border: `1px solid ${Color.midGrey}`,
    color: "white",
  },
  {
    title: "Unattempted",
    key: "unanswered",
    backgroundColor: Color.wrong,
    border: "none",
    color: "white",
  },
  {
    title: "Attempted & Marked for Review",
    key: "attemptedAndBookmarked",
    backgroundColor: Color.purple,
    border: "none",
    color: "white",
  },
  {
    title: "Unattempted & Marked for Review",
    key: "bookmarked",
    backgroundColor: Color.wrong,
    border: "none",
    color: "white",
  },
  {
    title: "Unseen",
    key: "unseen",
    backgroundColor: Color.white,
    border: `1px solid ${Color.black}`,
    color: "black",
  },
];
const legand = (qCounts) => {
  return (
    <div className="legendPalette">
      {answerStatus?.map((item, index) => (
        <div className="answerTypeContainer" key={index.toString()}>
          <div
            className="countBadge r-c-c"
            style={{
              backgroundColor: item.backgroundColor,
              border: item.border,
              color: item.color,
              position: "relative",
              marginRight: "10px",
            }}
          >
            <div>{qCounts ? qCounts[item.key] : 0}</div>
            {item?.key == "bookmarked" || item?.key == "attemptedAndBookmarked"
              ? orangecircle(-3)
              : null}
          </div>
          <div style={{ maxWidth: "50%", fontSize: "0.9vw" }}>
            {" "}
            {item.title}{" "}
          </div>
        </div>
      ))}
    </div>
  );
};


const showConfirm = (onSave, history, status, toggleButtonLoader) => {
  return confirm({
    title: `Do you want to submit the test?` /*${item.name}*/,
    icon: <ExclamationCircleOutlined />,
    content: "Please click OK to confirm",
    onOk() {
      onSave(status, history, true, toggleButtonLoader);
    },
    onCancel() { },
  });
};


const actionButtons = (
  currentQ,
  currentQIndex,
  onSave,
  buttonLoader,
  selectedStatus,
  onClearResponse,
  currentImages,
  totalQuestions
) => {

  let saveDisabled = true;

  if(currentQ?.type !== "Subjective")
  {

    if(currentQ.type === "MSQ" && currentQ?.answerObj?.answers?.length) saveDisabled = false;
    if(currentQ.type === "MCQ" && currentQ?.answerObj?.answer != null) saveDisabled = false;
    if(currentQ.type === "Numeric" && currentQ?.answerObj?.answer != null) saveDisabled = false;
    if(currentQ.type === "T/F" && currentQ?.answerObj?.answer != null) saveDisabled = false;

    if(currentQ.type === "OrQuestions")
    {
      let subType;
      if(currentQ?.questionContent?.orQuestions)
        subType = currentQ?.questionContent?.orQuestions[0]?.type;

      if(subType === "MSQ" && currentQ?.answerObj?.answers?.length) saveDisabled = false;
      if(subType === "MCQ" && currentQ?.answerObj?.answer != null) saveDisabled = false;
      if(subType === "Numeric" && currentQ?.answerObj?.answer != null) saveDisabled = false;
      if(subType === "T/F" && currentQ?.answerObj?.answer != null) saveDisabled = false;
    }
    if(currentQ.type === "Comprehensive")
    {
      let subType;
      if(currentQ?.questionContent?.subQuestions)
        subType = currentQ?.questionContent?.subQuestions[0]?.type;

      if(currentQ?.answerObj)
      {
        for(let eachAns of currentQ?.answerObj)
        {
          if(subType === "MSQ" && eachAns?.answers?.length) saveDisabled = false;
          if(subType === "MCQ" && eachAns?.answer != null) saveDisabled = false;
          if(subType === "Numeric" && eachAns?.answer != null) saveDisabled = false;
          if(subType === "T/F" && eachAns?.answer != null) saveDisabled = false;
        }
      }
    }
  }
  else {
    saveDisabled = !currentImages?.length
  }



  return <div className="actionButtons">
    <Button
      type="primary"
      shape="round"
      className="actionButton"
      loading={selectedStatus === "Attempted" && buttonLoader}
      style={{ backgroundColor: Color.purple, border: "none", color:"#FFFFFF" }}
      onClick={() => onSave("Attempted")}
      disabled={saveDisabled}
    >
      {currentQIndex == totalQuestions ? "SAVE" : "SAVE & NEXT"}
    </Button>
    <Button
      type="primary"
      shape="round"
      className="actionButton"
      style={{ backgroundColor: Color.seaGreen, border: "none", color:"#FFFFFF" }}
      loading={selectedStatus === "AttemptedAndBookmarked" && buttonLoader}
      onClick={() => onSave("AttemptedAndBookmarked")}
      disabled={saveDisabled}
    >
      SAVE & MARK FOR REVIEW
    </Button>
    <Button
      type="primary"
      shape="round"
      className="actionButton"
      style={{ backgroundColor: Color.yellow, border: "none",color:"#FFFFFF" }}
      loading={selectedStatus === "Bookmarked" && buttonLoader}
      onClick={() => onSave("Bookmarked")}
      disabled={
        !(
          currentQ.type !== "Subjective" &&
          (!currentQ?.answerObj ||
            currentQ?.answerObj === "" ||
            currentQ?.answerObj === "null")
        ) && !(currentQ.type === "Subjective" && !currentImages?.length)
      }
    >
      {currentQIndex === totalQuestions
        ? "MARK FOR REVIEW"
        : "MARK FOR REVIEW & NEXT"}
    </Button>
    <Button
      shape="round"
      loading={selectedStatus === "Clear" && buttonLoader}
      className="actionButton"
      onClick={() => {
        onClearResponse();
      }}
      disabled={
        (currentQ.type !== "Subjective" &&
          (!currentQ?.answerObj ||
            currentQ?.answerObj === "" ||
            currentQ?.answerObj === "null")) ||
        (currentQ.type === "Subjective" && !currentImages?.length)
      }
    >
      CLEAR RESPONSE
    </Button>
  </div>
};

const warning = (count) => {
  Modal.warning({
    title: `You have only ${count - 1} chance left`,
    content: "Do not change screen or test will get auto submitted.",
  });
};

function StudentTestAnswer({
  fetchTestDetails,
  fetchTestQuestions,
  currentQ,
  palateData,
  qCounts,
  selectedTest,
  currentIndex,
  updateQParam,
  saveNext,
  showMenu,
  setCurrentIndex,
  removeAnswer,
  navigateQuestion,
  testSubjects,
  handleSelectSubject,
  selectedSubIndex,
  setSelectedSubIndex,
  timeLeft,
  setDuration,
  updateImage,
  currentImages,
  removeAnswerImage,
  currentDuration,
  updateCurrentDuration,
  finalSubmit,
  toggleOutOfTimeModal,
  isOutOfTimeModalVisible,
  selectQIndex,
  quesType,
  setStopTimer,
  stopTimer
}) {
  let timer = 0;
  const history = useHistory();
  const [showImgModal, toggleImgModal] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  const [imgLoader, toggleImageLoader] = useState(false);
  const [autoSubmitVisible, setAutoSubmitVisible] = useState(false);
  const [SM_isModalVisible, setIsModalVisible] = useState(false);
  const [buttonLoader, toggleButtonLoader] = useState(false);
  const [selectedStatus, updateSelectedStatus] = useState("");
  const [pageLoader, togglePageLoader] = useState(false);
  const [showSubmitSuccess, toggleSubmitSuccess] = useState(false);
  const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
  const [isExitModalVisible, setExitModalVisibility] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  // console.log({currentImages});
  const SM_showModal = (item = null) => {
    togglePageLoader(true);
    selectQIndex(
      item = {qId: currentQ.id, index: currentIndex}, 
      (bool) => togglePageLoader(bool),
      () => setIsModalVisible(true)
    );
  };

  const SM_handleOk = () => {
    setIsModalVisible(false);
    toggleSubmitSuccess(true);
  };

  const SM_handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClearResponse = () => {
    if (
      (currentQ?.attempt?.answerObj && currentQ?.attempt?.answerObj != "") ||
      (currentQ?.answerObj && currentQ?.answerObj != "") ||
      (currentQ?.file && currentQ?.file?.length != 0) ||
      (currentImages && currentImages?.length != 0)
    ) {
      updateQParam(null, "answerObj");
      updateSelectedStatus("Clear");
      toggleButtonLoader(true);
      removeAnswer("Unanswered", history, buttonLoaderCallBack, (bool) => {
        fetchTestQuestions(() => {
          togglePageLoader(bool);
        });
      });
    }
  };

  useEffect(() => {
    showMenu(false);
    setStopTimer(null)
    fetchTestDetails(
      (bool) => togglePageLoader(bool),
      () => fetchTestQuestions((bool) => togglePageLoader(bool))
    );
    clearInterval(timer);
    TestTimer.startTimer(
      selectedTest,
      setDuration,
      updateCurrentDuration,
      handleAutoSubmit
    );

    return () => {
      TestTimer.clearTimer(setDuration);
      setAutoSubmitVisible(false);
      showMenu(true);
      setSelectedSubIndex(0);
    };
  }, []);

  const handleAutoSubmit = () => {
    setAutoSubmitVisible(true);
    finalSubmit(() => { }, autoSubmitSuccessCallback);
  };

  const autoSubmitSuccessCallback = () => {
    setAutoSubmitVisible(false);
    toggleOutOfTimeModal(true);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Notification.error("error", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Notification.error("error", "Subjective must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onQuestionSelect = (item) => {
    togglePageLoader(true);
    selectQIndex(item, (bool) => togglePageLoader(bool));
  };

  const uploadImage = (info) => {
    if (info.file.status === "uploading") {
      toggleImageLoader(true);
      return;
    }
    if (info.file.status === "done" || info.file.status === "error") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        updateImage(imageUrl, info.file.originFileObj);
        toggleImageLoader(false);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const toggleImageModal = (bool, index) => {
    toggleImgModal(bool);
    if(index != -1) setCurrentImg(currentImages[index]);
  };

  const handleNavigateClick = (direction) => {
    updateSelectedStatus(direction);
    toggleButtonLoader(true);
    navigateQuestion(direction, buttonLoaderCallBack, (bool) =>
      togglePageLoader(bool)
    );
  };

  const handleSubSelect = (index) => {
    handleSelectSubject(index, (bool) => togglePageLoader(bool));
  };

  const onSave = (status) => {
    updateSelectedStatus(status);
    toggleButtonLoader(true);
    saveNext(status, history, false, buttonLoaderCallBack, (bool) =>
      togglePageLoader(bool)
    );
  };

  const buttonLoaderCallBack = () => {
    toggleButtonLoader(false);
    updateSelectedStatus("");
  };

  const handleExit = () => {
    setExitModalVisibility(false);
    history.goBack();
  };
  const handleCancelExit = () => {
    setExitModalVisibility(false);
  };

  const setAnswerObj = (answer) => {
    updateQParam(answer, 'answerObj')
  }

  const totalQuestions = palateData?.at(-1)?.endIndex;

  return (
    <div
      className="TestScreen"
      style={{ height: "100%", minWidth: "580px", width: "100%" }}
    >
      <PageHeader
        style={{ backgroundColor: "#F6F4FE", width: "100%" }}
        title={<div style={{ minWidth: 1000 }}> {selectedTest.title} </div>}
        onBack={() => {
          setExitModalVisibility(true);
        }}
        extra={
          <div className="leftInnerNav">
            <div className="clockTime r-c-fs" style={{ alignItems: "center" }}>
              <img
                style={{ width: 25, height: 25 }}
                src={require("Assets/ic-stop-watch.svg").default}
              ></img>
              <span
                className="text-md m-l-5"
                style={{ color: "#594099", marginRight: 250 }}
              >
                {stopTimer ? <span className="text-md m-l-5" style={{ color: "#594099" }}>
                {'00'}:{'00'}:{'00'}
              </span> : timeLeft?.hours <= 0 && timeLeft?.minutes <= 0 && timeLeft?.seconds <= 0 ?
                <span className="text-md m-l-5" style={{ color: "#594099" }}>
                  {'00'}:{'00'}:{'00'}
                </span>
                :
                <span className="text-md m-l-5" style={{ color: "#594099" }}>
                  {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
                </span>
              }
              </span>
            </div>
            <div className="innerNavBtn">
              {testSubjects?.map((item, index) => (
                <Button
                  key={index}
                  disabled={testSubjects.length <= 1 ? true : false}
                  onClick={() => {
                    if (testSubjects && testSubjects?.length > 1) {
                      handleSubSelect(index);
                    }
                  }}
                  style={
                    currentQ?.chapter?.subject?.name == item?.name
                      ? {
                        backgroundColor: "#E6E6E6",
                        color: "#191919",
                        border: "none",
                      }
                      : null
                  }
                >
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor:
                        testSubjects?.length > 1 ? "pointer" : "not-allowed",
                    }}
                  >
                    {item.name}
                  </div>
                </Button>
              ))}
            </div>
          </div>
        }
      />
      <div className="testContainer">
        <div className="lqPanel">
          <div
            style={{
              height: "100%",
              borderRight: `1px solid ${Color.midGrey}`,
            }}
          >
            <div className="questionContainer">
              <div className="questionHeader">
                <span>Q {currentIndex}</span>
                <div className="leftText r-c-sa dark-grey">
                  <span>
                    <b>Marks: </b> + {currentQ.positiveMarks}{" "}
                    {currentQ.negativeMarks
                      ? `  - ${currentQ.negativeMarks}`
                      : null}
                  </span>
                  <span>
                    {" "}
                    <b>Time: </b>
                    {Math.floor(currentDuration / 60).toLocaleString(2)} :{" "}
                    {Math.floor(currentDuration % 60).toLocaleString(2)}{" "}
                  </span>
                </div>
              </div>


              {quesType == 'OrQuestions' ? <div><OrQuestion data={currentQ} currQuesIndex={currentIndex} setAnswerObj={setAnswerObj} answerObj={currentQ?.answerObj} toggleImageModal={toggleImageModal} setCurrentImg={setCurrentImg} /></div> : null}

              {quesType == 'Comprehensive' ? <div><Comprehensive data={currentQ} 
              currQuesIndex={currentIndex}
              setAnswerObj={setAnswerObj} answerObj={currentQ?.answerObj} toggleImageModal={toggleImageModal} setCurrentImg={setCurrentImg} /></div> : null}

              {(quesType == 'Numeric' || quesType == 'MCQ' || quesType == 'MSQ' || quesType == 'T/F') ? <div><QuestionTypes toggleImageModal={toggleImageModal} type={currentQ?.type} quesContent={currentQ.questionContent} setAnswerObj={setAnswerObj} answerObj={currentQ?.answerObj} setCurrentImg={setCurrentImg}/></div>  : null}


              {quesType == 'Subjective' ?
                <QuestionTypes type={currentQ?.type} quesContent={currentQ.questionContent} setAnswerObj={setAnswerObj} answerObj={currentQ?.answerObj} currentImages={currentImages} toggleImageModal={toggleImageModal} removeAnswerImage={removeAnswerImage}
                imgLoader={imgLoader} beforeUpload={beforeUpload} uploadImage={uploadImage} 
                setCurrentImg={setCurrentImg} />
              : null}
              

            </div>
            <div className="actionContainer">
              {actionButtons(
                currentQ,
                currentIndex,
                onSave,
                buttonLoader,
                selectedStatus,
                onClearResponse,
                currentImages,
                totalQuestions
              )}
              <div className="footerBtns">
                <div className="qNavBtn">
                  <Button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={currentIndex <= 1}
                    onClick={() => handleNavigateClick("prev")}
                  >
                    {selectedStatus === "prev" && buttonLoader ? (
                      <LoadingOutlined />
                    ) : (
                      <LeftOutlined />
                    )}
                    PREVIOUS
                  </Button>
                  <Button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    disabled={currentIndex >= totalQuestions}
                    onClick={() => handleNavigateClick("next")}
                  >
                    NEXT
                    {selectedStatus === "next" && buttonLoader ? (
                      <LoadingOutlined />
                    ) : (
                      <RightOutlined />
                    )}
                  </Button>
                </div>
                <div className="submitBtn cursor-pointer r-c-c-c">
                  <span
                    style={{ color: "#FFFFFF", fontWeight: "bold" }}
                    onClick={SM_showModal}
                  >
                    {" "}
                    SUBMIT{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rqPanel">
          <div style={{ height: "100%" }}>
            {legand(qCounts)}
            {<QuestionPalette
              palateData={palateData}
              currentQIndex={currentIndex}
              onQuestionSelect={onQuestionSelect}
              testSubjects={testSubjects}
            />}
          </div>
        </div>
      </div>
      {isImageViewerVisible && (
        <ImageViewer
          isPreviewModalVisible={isImageViewerVisible}
          image={imgUrl}
          setIsPreviewModalVisible={setImageViewerVisibility}
        />
      )}
      <ViewImgModal
        visible={showImgModal}
        imgPath={currentImg}
        onCancel={() => toggleImageModal(false)}
      />
      <AutoSubmitModal visible={autoSubmitVisible} />
      <SubmitModal
        isModalVisible={SM_isModalVisible}
        handleOk={SM_handleOk}
        handleCancel={SM_handleCancel}
        setStopTimer={setStopTimer}
      />
      <PageDataLoader visible={pageLoader} />
      
      <SubmittedSuccessModal
        item="beforeResults"
        isModalVisible={showSubmitSuccess}
        history={history}
        close={() => toggleSubmitSuccess(false)}
      />
      <OutoftimeModal history={history} />
      {isExitModalVisible && (
        <ExitModalTest
          visible={isExitModalVisible}
          handleOk={handleExit}
          handleCancel={handleCancelExit}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    currentQ,
    palateData,
    qCounts,
    selectedTest,
    currentIndex,
    testSubjects,
    selectedSubIndex,
    timeLeft,
    currentImages,
    currentDuration,
    isOutOfTimeModalVisible,
    quesType,
    stopTimer
  } = state.studentTest;
  return {
    isOutOfTimeModalVisible,
    currentQ,
    palateData,
    qCounts,
    selectedTest,
    currentIndex,
    testSubjects,
    selectedSubIndex,
    timeLeft,
    currentImages,
    currentDuration,
    quesType,
    stopTimer
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleOutOfTimeModal: (value) => dispatch(toggleOutOfTimeModal(value)),
  fetchTestDetails: (loaderCallback, successCallBack) =>
    dispatch(fetchTestDetails(loaderCallback, successCallBack)),
  fetchTestQuestions: (loaderCallback, submitCallback) =>
  dispatch(fetchTestQuestions(loaderCallback, submitCallback)),
  updateQParam: (val, key) => dispatch(updateQParam(val, key)),
  saveNext: (
    status,
    history,
    finalSubmit,
    buttonLoaderCallBack,
    pageLoaderCallback
  ) =>
    dispatch(
      saveNext(
        status,
        history,
        finalSubmit,
        buttonLoaderCallBack,
        pageLoaderCallback
      )
    ),
  showMenu: (bool) => dispatch(showMenu(bool)),
  setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
  removeAnswer: (status, history, buttonLoaderCallBack, pageLoaderCallback) =>
    dispatch(
      removeAnswer(status, history, buttonLoaderCallBack, pageLoaderCallback)
    ),
  navigateQuestion: (dir, buttonLoaderCallBack, pageLoaderCallback) =>
    dispatch(navigateQuestion(dir, buttonLoaderCallBack, pageLoaderCallback)),
  handleSelectSubject: (index, pageLoaderCallback) =>
    dispatch(handleSelectSubject(index, pageLoaderCallback)),
  setSelectedSubIndex: (index) => dispatch(setSelectedSubIndex(index)),
  setDuration: (duration) => dispatch(setDuration(duration)),
  updateImage: (base64Image, originalImage) =>
    dispatch(updateImage(base64Image, originalImage)),
  removeAnswerImage: (index) => dispatch(removeAnswerImage(index)),
  updateCurrentDuration: (seconds) => dispatch(updateCurrentDuration(seconds)),
  finalSubmit: (buttonCallback, successCallback) =>
    dispatch(finalSubmit(buttonCallback, successCallback)),
  selectQIndex: (index, callback, submitCallback) => dispatch(selectQIndex(index, callback, submitCallback)),
  setStopTimer: (res) => dispatch(setStopTimer(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentTestAnswer);