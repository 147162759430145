import { Popover } from 'antd'
import { clearGroupChat, clearPrivateChat, setRightView, setRightViewType } from 'actions/broadcast'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import groupImg from './assets/Group11903.svg';
import { VideoCameraOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { BsCameraVideoFill } from "react-icons/bs";
import Loader from 'pages/Test/localComponent/loader';
import { MdStopScreenShare, MdOutlineFrontHand } from "react-icons/md";


const ParticipantsButton = ({setRightView, rightView, setRightViewType, rightViewType})=>{
    return (
        <div className="m-b-5 r-c-c actBtnBc cursor-pointer" style={{borderRadius:"50%"}} 
            onClick={()=>{ if ((rightView==false)||(rightViewType=='')){
                                setRightView(true);
                                setRightViewType('participantsView');

                            }
                            else{
                                if (rightViewType=='participantsView'){
                                    setRightView(false); 
                                }
                                else{
                                setRightViewType('participantsView');}
                            }
                        }}>
            <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group 11815.svg').default} alt="" />
        </div>
    )
}

const PrivateChatButton = ({setRightView, setRightViewType, rightViewType, rightView
})=>{
    return (
        <div className="greyBackground m-b-5 r-c-c actBtnBc cursor-pointer" style={{borderRadius:"50%"}}
        onClick={()=>{
            if ((rightView==false)||(rightViewType=='')){
                setRightView(true);
                setRightViewType('privateChatView');

            }
            else{
                if (rightViewType=='privateChatView'){
                    setRightView(false); 
                }
                else{
                setRightViewType('privateChatView');}
            }
            }}> 
             <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group 11867.svg').default} alt="" />
        </div>
    )
}

const GroupChatButton = ({setRightView, setRightViewType, rightViewType, rightView, chatCount})=>{
    return (
        <div className="greyBackground m-b-5 r-c-c actBtnBc cursor-pointer relative" style={{borderRadius:"50%"}}
            onClick={()=>{
                if ((rightView==false)||(rightViewType=='')){
                    setRightView(true);
                    setRightViewType('groupChatView');

                }
                else{
                    if (rightViewType=='groupChatView'){
                        setRightView(false); 
                    }
                    else{
                    setRightViewType('groupChatView');}
                }
                }}>
            <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group 11830.svg').default} alt="" />
           {chatCount? <div className='r-c-c white bold' style={{backgroundColor:"red", borderRadius:13, position:"absolute", top:-10, right:-5, height:25, width:25, fontSize:10}}>{chatCount>99?'99+':chatCount}</div>:null} 
        </div>
    )
}
let t1 = null
const LeaveButton = ({chat, clearGroupChat, clearPrivateChat, 
                    setRightView, setRightViewType, sessionuser, streamId, adapter, onLeave})=>{
    const history=useHistory();
    return (
        <div className="greyBackground r-c-c leaveBtnBc cursor-pointer" style={{borderRadius:"50%"}} 
                onClick={()=>{
                    onLeave();
                            }}>
            <img style={{height:"40%", width:"40%"}}  src={require('./assets/Icon metro-phone.svg').default} alt="" />
        </div>
    )
}

const CameraOnButton = ({isCamOn, isPublishing, onCameraClick})=>{
    return (
        <div className="greyBackground m-b-5 r-c-c actBtnBc cursor-pointer" style={{borderRadius:"50%"}} 
                    onClick={onCameraClick}>
            {isCamOn
            ?
                <BsCameraVideoFill color="white" style={{height:"40%", width:"40%"}}/>
            :   
                <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group 11810.svg').default} alt="" />
            }
            
        </div>
    )
}

const MicButton = ({micIsMuted, isPublishing, onMicClick}) => {
    return (
        <div className="greyBackground m-b-5 r-c-c actBtnBc cursor-pointer" style={{borderRadius:"50%"}} 
                onClick={onMicClick}>
            {micIsMuted
            ?
                <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group 11818.svg').default} alt="" />
            :
                <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group 11777.svg').default} alt="" />
            }
        </div>
    )
}

const ShareScreenPopover = () => {
    return (
    <div className="p-10">
        {/* present
        <img style={{height:14, width:14}}  src={require('./assets/Group 11830.svg')} alt="" />  */}
        <div className="r-c-fs m-b-10">
            <div className="m-r-10">
                <img style={{height:14, width:14}}  src={require('./assets/Group11903b.svg').default} alt="" /> 
            </div>
            <div>
                Your entire screen
            </div>
        </div>
        <div className="r-c-fs m-b-10">
            <div className="m-r-10">
                <img style={{height:14, width:14}}  src={require('./assets/Group11903b.svg').default} alt="" /> 
            </div>
            <div>
                A window
            </div>
        </div>
        <div className="r-c-fs">
            <div className="m-r-10">
                <img style={{height:14, width:14}}  src={require('./assets/Group11903b.svg').default} alt="" /> 
            </div>
            <div>
                A tab
            </div>
        </div>
    </div>)
}
const ShareScreenButton = ({onShareClick, isPublishing}) => {
    return (
            <div className="greyBackground m-b-5 r-c-c actBtnBc cursor-pointer" style={{borderRadius:"50%"}} 
                    onClick={onShareClick}>
                {
                    isPublishing==2?
                    <MdStopScreenShare color={"white"} size={10}/>
                    :isPublishing==1?
                    <Loader style={{fontSize:10}}/>
                    :
                    <img style={{height:"40%", width:"40%"}}  src={require('./assets/Group11903.svg').default} alt="" />

                }
            </div>
    )
}

const RaiseHandButton = ({onClick}) => (
    <div className="greyBackground m-b-5 r-c-c actBtnBc cursor-pointer" style={{borderRadius:"50%"}} onClick={onClick}>
            <MdOutlineFrontHand color={"white"} size={15}/>
        </div>
)

const ActionButtons = ({type, setRightView, rightView, setRightViewType, 
                    micIsMuted, isCamOn, chat, 
                    userType, rightViewType, clearGroupChat, clearPrivateChat, sessionuser, 
                    isPublishing, onShareClick, onMicClick, onCameraClick=null, streamId, adapter=null, 
                    onLeave=null, onRaiseHandClick=null, isTeacher, 
                    isAudio,
                    isVideo,
                    isSpeaker,
                    orientation,
                    audioJack,
                    bluetoothHeadset,
                    chatCount})=>{
    return(
        <div className="r-c-c-c">
            {
                type=='partiButton'
                ?
                <ParticipantsButton setRightView={setRightView} rightView={rightView} setRightViewType={setRightViewType} rightViewType={rightViewType}/>
                :
                type=='privateButton'
                ?
                <PrivateChatButton setRightView={setRightView} setRightViewType={setRightViewType} rightViewType={rightViewType} rightView={rightView}/>
                :
                type=='groupButton'
                ?
                <GroupChatButton setRightView={setRightView} setRightViewType={setRightViewType} rightViewType={rightViewType} rightView={rightView} chatCount={chatCount}/>
                :
                type=='leaveButton'
                ?
                <LeaveButton 
                                chat={chat} clearGroupChat={clearGroupChat}
                                clearPrivateChat={clearPrivateChat}
                                setRightView={setRightView}
                                setRightViewType={setRightViewType}
                                sessionuser={sessionuser}
                                streamId={streamId}
                                adapter={adapter}
                                onLeave={onLeave}
                                />
                :
                type=='camOnButton'
                ?
                <CameraOnButton isCamOn={isVideo} isPublishing={isPublishing} onCameraClick={onCameraClick}/>
                :
                type=='speakOnButton'
                ?
                <MicButton  micIsMuted={!isAudio} isPublishing={isPublishing} onMicClick={onMicClick}/>
                :
                type=='shareScreenButton'
                ?
                <ShareScreenButton onShareClick={onShareClick} isPublishing={isPublishing}/>
                :
                type=='raiseHandButton'?
                <RaiseHandButton onClick={onRaiseHandClick}/>
                :
                    null
                }
            <div className="white rfbc0p8">
                {
                    type=='partiButton'
                    ?
                    <>Participants</>
                    :
                    type=='raiseHandButton'?
                    <>{isTeacher?'Raised Hands':'Raise Hand'}</>
                    :
                    type=='privateButton'
                    ?
                    <>Private chat</>
                    :
                    type=='groupButton'
                    ?
                    <>Chat</>
                    :
                    type=='leaveButton'
                    ?
                    <>Leave</>
                    :
                    type=='camOnButton'
                        ?
                        isCamOn
                        ?
                        <>Camera on</>
                        :
                        <>Camera off</>
                    :
                    type=='speakOnButton'
                    ?
                        micIsMuted
                        ?
                        <>Mic off</>
                        :
                        <>Mic on</>
                    :
                    type=='shareScreenButton'
                    ?
                    isPublishing===2?
                    <>Stop sharing</>
                    :
                    <>Share screen</>
                    :
                    null
                }
            </div>
        </div>
    )
}



const mapStateToProps = state=>{
    const {rightView, micIsMuted, isCamOn, userType, rightViewType} = state.broadcast;
    const {isTeacher, chatCount} = state.liveClass;
    const {user} = state.session;
    const {
        isAudio,
        isVideo,
        isSpeaker,
        orientation,
        audioJack,
        bluetoothHeadset,
      } = state.common;
    const sessionuser = user;
    return {
        rightView, micIsMuted, isCamOn, userType, rightViewType,sessionuser,
        isTeacher,
        isAudio,
        isVideo,
        isSpeaker,
        orientation,
        audioJack,
        bluetoothHeadset,
        chatCount
    };
}

const mapDispatchToProps = (dispatch)=>({
    setRightView: (val) => dispatch(setRightView(val)),
    setRightViewType: (viewType)=> dispatch(setRightViewType(viewType)),
    clearGroupChat: ()=> dispatch(clearGroupChat()),
    clearPrivateChat: ()=>dispatch(clearPrivateChat())
})
export default connect(mapStateToProps, mapDispatchToProps)(ActionButtons);