import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'components/Button'
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { bodyStyle } from './studentsViewAllPage'
import BasicInformationComp from './basicInformationComp'
import KeyInformationComp from './keyInformationComp'
import AdditionalInformationComp from './additionalInformationComp'
import CourseSettingsComp from './courseSettingsComp'
import CoursePreview from './coursePreview'
import CoursePreviewCardComp from './coursePreviewCardComp'
import PageDataLoader from 'components/pageDataLoader'
import { getCoursesCategories, getOnlineTestSeriesCourseDetails, getSpecificCourseData, getSpecificSMData, getSpecificVideoCourseData, postLiveCourseCreate, postOnlineTestSeries, postStudyMaterialsCreate, postVideoResourceCreate, setCreateCourseObj, storeSpecificCourseData } from 'actions/courseManagement'
import SuccessModal from 'pages/courseModule/successModal'
import { getExaminer } from 'actions/testAdmin'

export const newCourseCreationCardStyle = {
    backgroundColor: "#FFFFFF",
    padding: "20px",
    boxShadow: "4px 4px 16px #0000000F",
    borderRadius: "17px",
    width: "100%"
}

function CourseCreationPage({ courseTypeName, specificCourseDataContainer, getSpecificCourseData, setCreateCourseObj, getCoursesCategories, storeSpecificCourseData, postLiveCourseCreate, postVideoResourceCreate, postStudyMaterialsCreate, postOnlineTestSeries, courseCreateObj, getExaminer, getSpecificSMData, totalTagsData, getSpecificVideoCourseData, getOnlineTestSeriesCourseDetails }) {
    const history = useHistory();
    const location = useLocation();
    const [pageLoader, togglePageLoader] = useState(false);
    const [expandedSection, setExpandedSection] = useState("basic");
    const [courseLearningFields, setCourseLearningFields] = useState([{ id: null, name: '' }]);
    const [deletedLearnings, setDeletedLearnings] = useState([]);
    const [coursePrerequisitesFields, setCoursePrerequisitesFields] = useState([{ id: null, name: '' }]);
    const [deletedPrerequisites, setDeletedPrerequisites] = useState([]);
    const [courseIncludesFields, setCourseIncludesFields] = useState([{ id: null, name: '' }]);
    const [deletedDetails, setDeletedDetails] = useState([]);
    const [courseFeaturesFields, setCourseFeaturesFields] = useState([{ id: null, name: '' }]);
    const [deletedfeatures, setDeletedFeatures] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isCreateBtnLoading, setCreateBtnLoading] = useState(false);
    const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
    const [isBelowSeats, setIsBelowSeats] = useState(false);

    const handleSectionToggle = (section) => {
        if (expandedSection === section) {
            setExpandedSection(null);
        } else {
            setExpandedSection(section);
        }
    };

    const succesCallback = () => {
        if (location?.state?.key?.isEdit == "isFromEdit") {
            history.push("/course-details")
        } else {
            setSuccessModalVisible(true);
            clearValues();
        }
    }

    
    let disableCreateUpdateBtn = true;
    if (courseTypeName == "liveCourses" && (!courseCreateObj?.mode || !courseCreateObj?.courseName || !courseCreateObj?.brief || !courseCreateObj?.courseDescription || !courseCreateObj?.image || courseCreateObj?.teacherIds?.length == 0 || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty || !courseCreateObj?.duration || !courseCreateObj?.durationType || (isBelowSeats && location?.state?.key?.isEdit == 'isFromEdit') || (courseCreateObj?.recordLive && (!courseCreateObj?.recordVideoResourceName || courseCreateObj?.vrTeacherIds?.length == 0)))) {
        disableCreateUpdateBtn = true
    } else if (courseTypeName == "videoResources" && (!courseCreateObj?.courseName || !courseCreateObj?.brief || !courseCreateObj?.courseDescription || !courseCreateObj?.image || courseCreateObj?.teacherIds?.length == 0 || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty)) {
        disableCreateUpdateBtn = true
    } else if (courseTypeName == "studyMaterials" && (!courseCreateObj?.courseName || !courseCreateObj?.courseDescription || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty || courseCreateObj?.teacherIds?.length == 0)) {
        disableCreateUpdateBtn = true
    } else if (courseTypeName == "onlineTestSeries" && (!courseCreateObj?.courseName || !courseCreateObj?.courseDescription || !courseCreateObj?.categoryId || !courseCreateObj?.subcategoryId || !courseCreateObj?.difficulty)) {
        disableCreateUpdateBtn = true
    } else {
        disableCreateUpdateBtn = false
    }

    const actions = [
        <Button
            loading={isCreateBtnLoading}
            disabled={disableCreateUpdateBtn}
            type="primary"
            style={{}} icon={<PlusCircleOutlined />}
            onClick={() => {
                setCreateCourseObj("deletedCourseLearnings", deletedLearnings);
                setCreateCourseObj("deletedCoursePrerequisites", deletedPrerequisites);
                setCreateCourseObj("deletedCourseDetails", deletedDetails);
                setCreateCourseObj("deletedCourseFeatures", deletedfeatures);
                if (courseTypeName == "studyMaterials") {
                    setCreateBtnLoading(true);
                    postStudyMaterialsCreate(
                        () => { setCreateBtnLoading(false) },
                        () => { succesCallback() }
                    )
                } else if (courseTypeName == 'videoResources') {
                    setCreateBtnLoading(true);
                    postVideoResourceCreate(
                        () => { setCreateBtnLoading(false) },
                        () => { succesCallback() }
                    )
                }
                else if (courseTypeName == 'onlineTestSeries') {
                    setCreateBtnLoading(true);
                    postOnlineTestSeries(
                        () => { setCreateBtnLoading(false) },
                        () => { succesCallback() }
                    );
                }
                else {
                    setCreateBtnLoading(true);
                    postLiveCourseCreate(
                        () => { setCreateBtnLoading(false) },
                        () => { succesCallback() }
                    );
                }
            }}
        >
            {location?.state?.key?.isEdit == "isFromEdit" ? "UPDATE" : "CREATE"}</Button>
    ]

    const clearValues = () => {
        setCreateCourseObj("id", null);
        setCreateCourseObj("mode", null);
        setCreateCourseObj("courseName", null);
        setCreateCourseObj("brief", null);
        setCreateCourseObj("courseDescription", null);
        setCreateCourseObj("image", null);
        setCreateCourseObj("difficulty", null);
        setCreateCourseObj("duration", null);
        setCreateCourseObj("durationType", null);
        setCreateCourseObj("teacherId", null);
        setCreateCourseObj("teacherIds", []);
        setCreateCourseObj("vrTeacherIds", []);
        setCreateCourseObj("features", []);
        setCreateCourseObj("learnings", []);
        setCreateCourseObj("prerequisites", []);
        setCreateCourseObj("details", []);
        setCreateCourseObj("deletedCourseLearnings", []);
        setCreateCourseObj("deletedCoursePrerequisites", []);
        setCreateCourseObj("deletedCourseDetails", []);
        setCreateCourseObj("deletedCourseFeatures", []);
        setCreateCourseObj("tags", []);
        setCreateCourseObj("premium", false);
        setCreateCourseObj("recordLive", false);
        setCreateCourseObj("recordVideoResourceName", null);
        // setCreateCourseObj('vrTeacherId', null);
        setCreateCourseObj("enableQA", false);
        setCreateCourseObj("preview", null);
        setCreateCourseObj("previewLink", null);
        setCreateCourseObj("previewType", null);
        setCreateCourseObj("categoryId", null);
        setCreateCourseObj("subcategoryId", null);
        setCreateCourseObj("langId", null);
        setCreateCourseObj("totalSeats", null);
    }

    useEffect(() => {
        getCoursesCategories()
    }, [])

    const returnDays = (val) => {
        if (val == "Day(s)") {
            return "1";
        } else if (val == "Month(s)") {
            return "30";
        } else if (val == "Year(s)") {
            return "360";
        }
    };

    useEffect(() => {
        if ((location?.state?.key?.isEdit == "isFromEdit" && courseTypeName == "liveCourses")) {
            setExpandedSection(null);
            togglePageLoader(true);
            getSpecificCourseData(() => togglePageLoader(false));
            setCreateCourseObj("id", location?.state?.key?.isClone ? null : specificCourseDataContainer?.course?.id ? specificCourseDataContainer?.course?.id : null);
            setCreateCourseObj("mode", specificCourseDataContainer?.course?.mode);
            setCreateCourseObj("courseName", specificCourseDataContainer?.course?.courseName);
            setCreateCourseObj("brief", specificCourseDataContainer?.course?.brief);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.course?.courseDescription);
            setCreateCourseObj("image", specificCourseDataContainer?.course?.image);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.course?.category?.parent?.name, id: specificCourseDataContainer?.course?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.course?.category?.name, id: specificCourseDataContainer?.course?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.course?.difficulty);
            setCreateCourseObj("premium", specificCourseDataContainer?.course?.premium);
            setCreateCourseObj("recordLive", specificCourseDataContainer?.course?.recordLive);
            setCreateCourseObj("recordVideoResourceName", specificCourseDataContainer?.course?.recordVideoResource?.name);
            setCreateCourseObj("duration", specificCourseDataContainer?.course?.duration);
            setCreateCourseObj("durationType", returnDays(specificCourseDataContainer?.course?.durationType));
            setCreateCourseObj("features", specificCourseDataContainer?.course?.features);
            setCreateCourseObj("learnings", specificCourseDataContainer?.course?.learnings);
            setCreateCourseObj("prerequisites", specificCourseDataContainer?.course?.prerequisites);
            setCreateCourseObj("langId", specificCourseDataContainer?.course?.langId);
            setCreateCourseObj("totalSeats", specificCourseDataContainer?.course?.totalSeats);
            setCreateCourseObj("enrolledCount", specificCourseDataContainer?.course?.enrolledCount);
            setCreateCourseObj('teacherIds', specificCourseDataContainer?.course?.teachers);
            setCreateCourseObj("vrTeacherIds", specificCourseDataContainer?.course?.recordVideoResource?.teachers);


            const featuresTemp = (specificCourseDataContainer?.course?.features || [])?.map((feature) => ({

                id: feature?.id,
                name: feature?.name,
            }));
            setCourseFeaturesFields(specificCourseDataContainer?.course?.features?.length > 0 ? featuresTemp : [{ id: null, name: '' }]);
            const learningsTemp = (specificCourseDataContainer?.course?.learnings || [])?.map((learning) => ({
                id: learning?.id,
                name: learning?.name,
            }));
            setCourseLearningFields(specificCourseDataContainer?.course?.learnings?.length > 0 ? learningsTemp : [{ id: null, name: '' }]);
            const prerequisitesTemp = (specificCourseDataContainer?.course?.prerequisites || [])?.map((prerequisite) => ({
                id: prerequisite?.id,
                name: prerequisite?.name,
            }));
            setCoursePrerequisitesFields(specificCourseDataContainer?.course?.prerequisites?.length > 0 ? prerequisitesTemp : [{ id: null, name: '' }]);
            let selectedTagsName = (specificCourseDataContainer?.course?.tags || [])?.map((tags) => ({
                id: tags?.id,
                tag: tags?.tag,
            }))
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("previewType", specificCourseDataContainer?.course?.previewType);
            if (specificCourseDataContainer?.course?.previewType == "file") {
                setCreateCourseObj("preview", specificCourseDataContainer?.course?.preview);
            } else {
                setCreateCourseObj("previewLink", specificCourseDataContainer?.course?.preview);
            }
        } else if (location?.state?.key?.isEdit == "isFromEdit" && courseTypeName == "studyMaterials") {
            setExpandedSection(null);
            togglePageLoader(true);
            getSpecificSMData(() => togglePageLoader(false))
            setCreateCourseObj("id", location?.state?.key?.isClone ? null : specificCourseDataContainer?.id ? specificCourseDataContainer?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.name);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.description);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.category?.parent?.name, id: specificCourseDataContainer?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.category?.name, id: specificCourseDataContainer?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.difficulty);
            setCreateCourseObj("features", specificCourseDataContainer?.features);
            setCreateCourseObj("learnings", specificCourseDataContainer?.learnings);
            setCreateCourseObj("premium", specificCourseDataContainer?.premium);
            setCreateCourseObj("langId", specificCourseDataContainer?.langId);
            setCreateCourseObj("image", specificCourseDataContainer?.image);
            setCreateCourseObj('teacherIds', specificCourseDataContainer?.teachers);
            

            const featuresTemp = (specificCourseDataContainer?.features || [])?.map((feature) => ({
                id: feature?.id,
                name: feature?.name,
            }));
            setCourseFeaturesFields(specificCourseDataContainer?.features?.length > 0 ? featuresTemp : [{ id: null, name: '' }]);
            const learningsTemp = (specificCourseDataContainer?.learnings || [])?.map((learning) => ({
                id: learning?.id,
                name: learning?.name,
            }));
            setCourseLearningFields(specificCourseDataContainer?.learnings?.length > 0 ? learningsTemp : [{ id: null, name: '' }]);
            let selectedTagsName = totalTagsData
                ?.filter((item) => specificCourseDataContainer?.tags?.some((tag) => tag.id === item.id))
                ?.map((item) => ({
                    id: item?.id,
                    value: item?.tag,
                }));
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("previewType", specificCourseDataContainer?.previewType);
            if (specificCourseDataContainer?.previewType == "file") {
                setCreateCourseObj("preview", specificCourseDataContainer?.preview);
            } else {
                setCreateCourseObj("previewLink", specificCourseDataContainer?.preview);
            }
        } else if ((location?.state?.key?.isEdit == 'isFromEdit' && courseTypeName == "videoResources")) {
            setExpandedSection(null);
            togglePageLoader(true);
            getSpecificVideoCourseData(() => togglePageLoader(false))
            setCreateCourseObj("id", location?.state?.key?.isClone ? null : specificCourseDataContainer?.id ? specificCourseDataContainer?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.name);
            setCreateCourseObj("brief", specificCourseDataContainer?.brief);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.description);
            setCreateCourseObj("image", specificCourseDataContainer?.image);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.category?.parent?.name, id: specificCourseDataContainer?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.category?.name, id: specificCourseDataContainer?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.difficulty);
            setCreateCourseObj("teacherId", { name: specificCourseDataContainer?.teacher?.name, id: specificCourseDataContainer?.teacher?.id });
            setCreateCourseObj("features", specificCourseDataContainer?.features);
            setCreateCourseObj("learnings", specificCourseDataContainer?.learnings);
            setCreateCourseObj("prerequisites", specificCourseDataContainer?.prerequisites);
            setCreateCourseObj("details", specificCourseDataContainer?.details);
            setCreateCourseObj("premium", specificCourseDataContainer?.premium);
            setCreateCourseObj("enableQA", specificCourseDataContainer?.enableQA);
            setCreateCourseObj("langId", specificCourseDataContainer?.langId);
            setCreateCourseObj('teacherIds', specificCourseDataContainer?.teachers);

            const featuresTemp = (specificCourseDataContainer?.features || [])?.map((feature) => ({
                id: feature?.id,
                name: feature?.name,
            }));
            setCourseFeaturesFields(specificCourseDataContainer?.features?.length > 0 ? featuresTemp : [{ id: null, name: '' }]);
            const learningsTemp = (specificCourseDataContainer?.learnings || [])?.map((learning) => ({
                id: learning?.id,
                name: learning?.name,
            }));
            setCourseLearningFields(specificCourseDataContainer?.learnings?.length > 0 ? learningsTemp : [{ id: null, name: '' }]);
            const prerequisitesTemp = (specificCourseDataContainer?.prerequisites || [])?.map((prerequisite) => ({
                id: prerequisite?.id,
                name: prerequisite?.name,
            }));
            setCoursePrerequisitesFields(specificCourseDataContainer?.prerequisites?.length > 0 ? prerequisitesTemp : [{ id: null, name: '' }]);
            const detailsTemp = (specificCourseDataContainer?.details || [])?.map((detail) => ({
                id: detail?.id,
                name: detail?.name,
            }));
            setCourseIncludesFields(specificCourseDataContainer?.details?.length > 0 ? detailsTemp : [{ id: null, name: '' }]);
            let selectedTagsName = (specificCourseDataContainer?.tags || [])?.map((tags) => ({
                id: tags?.id,
                value: tags?.tag,
            }))
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("previewType", specificCourseDataContainer?.previewType);
            if (specificCourseDataContainer?.previewType == "file") {
                setCreateCourseObj("preview", specificCourseDataContainer?.preview);
            } else {
                setCreateCourseObj("previewLink", specificCourseDataContainer?.preview);
            }
        } else if (location?.state?.key?.isEdit == 'isFromEdit' && courseTypeName == "onlineTestSeries") {
            setExpandedSection(null);
            togglePageLoader(true);
            getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
            setCreateCourseObj("id", location?.state?.key?.isClone ? null : specificCourseDataContainer?.testSeriesDetails?.id ? specificCourseDataContainer?.testSeriesDetails?.id : null);
            setCreateCourseObj("courseName", specificCourseDataContainer?.testSeriesDetails?.name);
            setCreateCourseObj("courseDescription", specificCourseDataContainer?.testSeriesDetails?.description);
            setCreateCourseObj("categoryId", { name: specificCourseDataContainer?.testSeriesDetails?.category?.parent?.name, id: specificCourseDataContainer?.testSeriesDetails?.category?.parent?.id });
            setCreateCourseObj("subcategoryId", { name: specificCourseDataContainer?.testSeriesDetails?.category?.name, id: specificCourseDataContainer?.testSeriesDetails?.category?.id });
            setCreateCourseObj("difficulty", specificCourseDataContainer?.testSeriesDetails?.difficulty);
            setCreateCourseObj("features", specificCourseDataContainer?.testSeriesDetails?.features);
            setCreateCourseObj("prerequisites", specificCourseDataContainer?.testSeriesDetails?.prerequisites);
            setCreateCourseObj("langId", specificCourseDataContainer?.testSeriesDetails?.langId);
            setCreateCourseObj("image", specificCourseDataContainer?.testSeriesDetails?.image);

            const featuresTemp = (specificCourseDataContainer?.testSeriesDetails?.features || [])?.map((feature) => ({
                id: feature?.id,
                name: feature?.name,
            }));
            setCourseFeaturesFields(specificCourseDataContainer?.testSeriesDetails?.features?.length > 0 ? featuresTemp : [{ id: null, name: '' }]);
            const prerequisitesTemp = (specificCourseDataContainer?.testSeriesDetails?.prerequisites || [])?.map((prerequisite) => ({
                id: prerequisite?.id,
                name: prerequisite?.name,
            }));
            setCoursePrerequisitesFields(specificCourseDataContainer?.testSeriesDetails?.prerequisites?.length > 0 ? prerequisitesTemp : [{ id: null, name: '' }]);
            setCreateCourseObj("premium", specificCourseDataContainer?.testSeriesDetails?.premium);
            let selectedTagsName = totalTagsData
                ?.filter((item) => specificCourseDataContainer?.testSeriesDetails?.tags?.some((tag) => tag.id === item.id))
                ?.map((item) => ({
                    id: item?.id,
                    value: item?.tag,
                }));
            setSelectedTags(selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("tags", selectedTagsName ? [...selectedTagsName] : []);
            setCreateCourseObj("previewType", specificCourseDataContainer?.testSeriesDetails?.previewType);
            if (specificCourseDataContainer?.testSeriesDetails?.previewType == "file") {
                setCreateCourseObj("preview", specificCourseDataContainer?.testSeriesDetails?.preview);
            } else {
                setCreateCourseObj("previewLink", specificCourseDataContainer?.testSeriesDetails?.preview);
            }
        } else {
            clearValues();
            setCreateCourseObj("categoryId", null);
            setCreateCourseObj("subcategoryId", null);
            getExaminer('');
            storeSpecificCourseData([])
        }
    }, [specificCourseDataContainer?.course?.id])

console.log('ssssss',courseCreateObj.teacherIds);

    const onPageHeaderCancel = () => {
        if (location?.state?.key?.fromCourseDetails) {
            history.push({
                pathname: "/course-details",
                state: {
                    key: {
                        fromCourseCard: location?.state?.key?.fromCourseCard,
                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                        searchedValue: location?.state?.key?.searchedValue,
                        showSkeleton: true
                    }
                }
            })
        } else {
            history.push(courseTypeName == "liveCourses" ?
                {
                    pathname: "/live-courses-data", state: {
                        key: {
                            fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                            searchedValue: location?.state?.key?.searchedValue,
                            fromSubcategory: location?.state?.key?.fromSubcategory,
                            fromCourseCard: location?.state?.key?.fromCourseCard,
                            fromDashboardSearch: location?.state?.key?.fromDashboard
                        }
                    }
                } :
                courseTypeName == "videoResources" ?
                    {
                        pathname: "/video-resources-data", state: {
                            key: {
                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                searchedValue: location?.state?.key?.searchedValue,
                                fromSubcategory: location?.state?.key?.fromSubcategory,
                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                fromDashboardSearch: location?.state?.key?.fromDashboard
                            }
                        }
                    } :
                    courseTypeName == "onlineTestSeries" ?
                        {
                            pathname: "/online-test-series-details", state: {
                                key: {
                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                    searchedValue: location?.state?.key?.searchedValue,
                                    fromSubcategory: location?.state?.key?.fromSubcategory,
                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                    fromDashboardSearch: location?.state?.key?.fromDashboard
                                }
                            }
                        } :
                        courseTypeName == "studyMaterials" ?
                            {
                                pathname: "/study-materials-data", state: {
                                    key: {
                                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                        searchedValue: location?.state?.key?.searchedValue,
                                        fromSubcategory: location?.state?.key?.fromSubcategory,
                                        fromCourseCard: location?.state?.key?.fromCourseCard,
                                        fromDashboardSearch: location?.state?.key?.fromDashboard
                                    }
                                }
                            }
                            : "/live-courses-data"
            );
        }
        clearValues();
        storeSpecificCourseData([])
    }

    return (
        <div>
            <div style={{ marginBottom: "23px" }}>
                <PageHeader maxWidth={800} title={
                    courseTypeName == "liveCourses"
                        ? location?.state?.key?.isEdit == "isFromEdit" && location?.state?.key?.isClone ? `Clone ${specificCourseDataContainer?.course?.courseName}` : location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.course?.courseName}` : "Add a live courses"
                        : courseTypeName == "videoResources"
                            ? location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.name}` : "Add a video resources"
                            : courseTypeName == "onlineTestSeries"
                                ? location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.testSeriesDetails?.name}` : "Add a online test series"
                                : courseTypeName == "studyMaterials"
                                    ? location?.state?.key?.isEdit == "isFromEdit" ? `Edit ${specificCourseDataContainer?.name}` : "Add a study materials"
                                    : "Add Live Courses"
                }
                    onBackPress={onPageHeaderCancel}
                    actions={actions} />
            </div>
            <div style={bodyStyle}>
                <div className='r-jsb m-t-30' style={{ width: "100%" }}>
                    <div className='m-r-20 scroll-bar-universal' style={{ width: "50%", height: 706 }}>
                        <BasicInformationComp
                            isExpanded={expandedSection === 'basic'}
                            setIsExpanded={() => handleSectionToggle('basic')}
                            location={location}
                            clearValues={clearValues}
                            expandedSection={expandedSection}
                            isBelowSeats={isBelowSeats}
                            setIsBelowSeats={setIsBelowSeats}
                        />
                        <KeyInformationComp
                            isExpanded={expandedSection === 'key'}
                            setIsExpanded={() => handleSectionToggle('key')}
                            location={location}
                            clearValues={clearValues}
                            expandedSection={expandedSection}
                        />
                        <AdditionalInformationComp
                            isExpanded={expandedSection === 'additionalInfo'}
                            setIsExpanded={() => handleSectionToggle('additionalInfo')}
                            courseLearningFields={courseLearningFields}
                            setCourseLearningFields={setCourseLearningFields}
                            deletedLearnings={deletedLearnings}
                            setDeletedLearnings={setDeletedLearnings}
                            coursePrerequisitesFields={coursePrerequisitesFields}
                            setCoursePrerequisitesFields={setCoursePrerequisitesFields}
                            deletedPrerequisites={deletedPrerequisites}
                            setDeletedPrerequisites={setDeletedPrerequisites}
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                            courseFeaturesFields={courseFeaturesFields}
                            setCourseFeaturesFields={setCourseFeaturesFields}
                            deletedfeatures={deletedfeatures}
                            setDeletedFeatures={setDeletedFeatures}
                            courseIncludesFields={courseIncludesFields}
                            setCourseIncludesFields={setCourseIncludesFields}
                            deletedDetails={deletedDetails}
                            setDeletedDetails={setDeletedDetails}
                            location={location}
                            clearValues={clearValues}
                            expandedSection={expandedSection}
                        />
                        <CourseSettingsComp
                            isExpanded={expandedSection === 'settings'}
                            setIsExpanded={() => handleSectionToggle('settings')}
                            location={location}
                            clearValues={clearValues}
                            expandedSection={expandedSection} />
                        <CoursePreview
                            isExpanded={expandedSection === 'preview'}
                            setIsExpanded={() => handleSectionToggle('preview')}
                            location={location}
                            clearValues={clearValues}
                            expandedSection={expandedSection} />
                    </div>
                    <div style={{ width: "50%" }}>
                        <CoursePreviewCardComp />
                    </div>
                </div>
            </div>
            {
                isSuccessModalVisible &&
                <SuccessModal
                    isSuccessModalVisible={isSuccessModalVisible}
                    setSuccessModalVisible={setSuccessModalVisible}
                    courseTypeName={courseTypeName} />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { courseTypeName, specificCourseDataContainer, courseCreateObj, totalTagsData } = state.courseManagement
    return {
        courseTypeName, specificCourseDataContainer, courseCreateObj, totalTagsData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    getSpecificCourseData: (callback) => dispatch(getSpecificCourseData(callback)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
    getOnlineTestSeriesCourseDetails: (callback) => dispatch(getOnlineTestSeriesCourseDetails(callback)),
    getCoursesCategories: () => dispatch(getCoursesCategories()),
    storeSpecificCourseData: (val) => dispatch(storeSpecificCourseData(val)),
    getExaminer: (text) => dispatch(getExaminer(text)),
    postLiveCourseCreate: (callBack, successCallBack) => dispatch(postLiveCourseCreate(callBack, successCallBack)),
    postVideoResourceCreate: (callback, successCallBack) => dispatch(postVideoResourceCreate(callback, successCallBack)),
    postStudyMaterialsCreate: (callBack, successCallBack) => dispatch(postStudyMaterialsCreate(callBack, successCallBack)),
    postOnlineTestSeries: (callBack, successCallBack) => dispatch(postOnlineTestSeries(callBack, successCallBack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseCreationPage);