import React, { useState } from "react";
import { Modal, Button, Row, Col } from "antd";
// import "./modal.css";
// import SubmittedSuccessModal from "./submittedSuccessModal";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  StarFilled,
  StopOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import { setIsEditModalVisible } from "actions/testAdmin";
import { fetchPerformance, fetchResult, toggleOutOfTimeModal } from 'actions/studentPracticeTest';

const OutoftimeModalPractice = ({
  isOutOfTimeModalVisible,
  qCounts,
  selectedTest,
  toggleOutOfTimeModal,
  history,
  timeLeft,
  fetchPerformance,
  fetchResult
}) => {
  const handleHomeClick = () => {
    toggleOutOfTimeModal(false);
    history.push('/student-practice-home')
  }

  const handleResultClick = () => {
    fetchResult(()=>{
      fetchPerformance(()=>{
        toggleOutOfTimeModal(false);
        history.push('/practice-result-screen')
      })
    })
  }
  return (
    <Modal
      visible={isOutOfTimeModalVisible}
      className="outOfTime"
      cancelButtonProps={{ display: "none" }}
      onCancel={handleHomeClick}
      width={'600px'}
      height={'900px'}
    >
      <h2 style={{ fontWeight: "700", marginBottom: "0px" }}>
        You are out of time
      </h2>
      <p style={{ color: "#636363", opacity: "100%" }}>
        The time is up but your test is submitted.
      </p>
      <div
        style={{
          width: "100%",
          padding: 20,
          marginTop: 30,
          backgroundColor: "#FAFAFA",
          borderRadius: 5,
        }}
      >
        {/* <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <ClockCircleOutlined style={{color: "#554394", }} />
            </Col>
            <Col span={18}>
              Time left
            </Col>
            <Col span={4} className="text-right" >
            {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </Col>
          </Row> */}
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <ClockCircleOutlined style={{color: "#554394", }} />
            </Col>
            <Col span={18}>
              Time left
            </Col>
            <Col span={4} className="text-right" >
            {'00'}:{'00'}:{'00'}
            </Col>
          </Row>
        <Row style={{ padding: "5px 0" }}>
          <Col span={2}>
            <CheckCircleOutlined style={{ color: "#554394" }} />
          </Col>
          <Col span={18}>Attempted</Col>
          <Col span={4} className="text-right">
            {qCounts.attempted}
          </Col>
        </Row>
        <Row style={{ padding: "5px 0" }}>
          <Col span={2}>
            <CloseCircleOutlined style={{ color: "#FF414D" }} />
          </Col>
          <Col span={18}>Unattempted</Col>
          <Col span={4} className="text-right">
            {qCounts.unanswered}
          </Col>
        </Row>
        <Row style={{ padding: "5px 0" }}>
          <Col span={2}>
            <StarFilled style={{ color: "#FFA931" }} />
          </Col>
          <Col span={18}>Attempted & Marked for review</Col>
          <Col span={4} className="text-right">
            {qCounts.attemptedAndBookmarked}
          </Col>
        </Row>
        <Row style={{ padding: "5px 0" }}>
          <Col span={2}>
            <StarFilled style={{ color: "#FFA931" }} />
          </Col>
          <Col span={18}>Unattempted & Marked for review</Col>
          <Col span={4} className="text-right">
            {qCounts.bookmarked}
          </Col>
        </Row>
        <Row style={{ padding: "5px 0" }}>
          <Col span={2}>
            <StopOutlined style={{ color: "#AEAEAE" }} />
          </Col>
          <Col span={18}>Unseen</Col>
          <Col span={4} className="text-right">
            {qCounts.unseen}
          </Col>
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
        //   className="belowdata"
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     height: "30%",
        //     marginTop: "5%",
        //   }}
        >
          {/* <div
            style={{
              padding: "10px 10px",
              width: "450px",
              borderRadius: "15px",
              backgroundColor: "#FAFAFA",
              opacity: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p  style={{color:"grey" ,marginBottom:"0px"}}>Your results will be published</p>
            <p  style={{color:"black",fontWeight:"700"}}>{selectedTest.testType=="Objective"?"on "+moment(selectedTest.resultDate).format("DD MMMM YYYY"):"After evaluation"}</p>
          </div> */}
          <div className="m-t-50">
          <Button
          className="m-r-20"
            shape="round"
            type="primary"
            style={{ width: 120,backgroundColor:'white', color:'#636363',borderColor:'#636363' }}
            onClick={handleHomeClick}
    
          >
            <div className="bold-600" style={{ fontSize: 12,}}>
              HOME
            </div>
          </Button>
            <Button
            shape="round"
            type="primary"
            style={{ width: 120 }}
            onClick={handleResultClick}
          >
            <div className="bold-600" style={{ fontSize: 12 }}>
              VIEW RESULTS
            </div>
          </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { timeLeft, qCounts, selectedTest, isOutOfTimeModalVisible } =
    state.studentPracticeTest;
  return {
    timeLeft,
    qCounts,
    selectedTest,
    isOutOfTimeModalVisible
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleOutOfTimeModal: (value) => dispatch(toggleOutOfTimeModal(value)),
  fetchResult:(callback)=>dispatch(fetchResult(callback)),
  fetchPerformance:(callback)=>dispatch(fetchPerformance(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutoftimeModalPractice);