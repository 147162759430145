import PageHeader from 'components/pageHeader';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, Prompt, useLocation } from 'react-router';
import { Button } from 'components/Button';
import { DetailSection, EditImage, ImageComponent } from './subComponents';
import TransferOwnershipModal from './transferOwnershipModal';
import SaveConfirmation from './saveConfirmation';
import { deleteProfilePic, saveProfileDetails } from 'actions/profileNew';
import { getAddressFromPin } from './postalApi';
import { otherUserProfileDetails, superAdminProfileDetails } from './userProfileData';
import moment from 'moment';
import { fetchSpecificUserProfile } from 'actions/userManagementV2';
import PageDataLoader from 'components/pageDataLoader';
import { setEditPrivilages } from 'actions/userManagement';
import { ConsoleSqlOutlined } from '@ant-design/icons';

export const designations = ["Director", "Principal", "Centre Head", "Administrator", "Teacher", "Manager", "Marketing", "Others"]



const UserProfile = ({
    user,
    userDetails,
    saveProfileDetails,
    deleteProfilePic,
    fetchSpecificUserProfile,
    specificUserId,
    specificUserProfile,
    userRowDetails,
    fromWhere,
    othersSelectedTab,
    editPrivilage,
    setEditPrivilages
}) => {

    const handleInputChange = (val, key, childkey1, childkey2) => {
   
  
        if (childkey1) {
            if (childkey2) {
                userProfileDetails[key][childkey1][childkey2] = val;

            } else {
                userProfileDetails[key][childkey1] = val;
            }
        } else {
            userProfileDetails[key] = val;
        }

        setProfileDetails({ ...userProfileDetails })
    }

    const handleFocusChange = (val, key, childkey1, childkey2) => {
        if (childkey1) {
            if (childkey2) {
                if (val !== "" && val?.length < 6) {
                    userProfileDetails[key][childkey1].pincodeValid = true;
                    hendlePinChange(null, childkey1)
                } else {
                    userProfileDetails[key][childkey1].pincodeValid = undefined;
                }
                if (childkey2 === "pincode" && val.length === 6) {
                    hendlePinChange(val, childkey1)
                }
            }
        } else {
            userProfileDetails[key] = val;
        }
        setProfileDetails({ ...userProfileDetails })
    }
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {

        let currentAddress = {}, permanentAddress = {}

        if (location?.state?.key === true) {

            try {
                currentAddress = JSON.parse(specificUserProfile?.basicprofile?.currentAddress)
            } catch (e) { }
            try {
                permanentAddress = JSON.parse(specificUserProfile?.basicprofile?.permanentAddress)
            } catch (e) { }

            setProfileDetails({
                ...specificUserProfile,
                basicprofile: {
                    ...specificUserProfile?.basicprofile,
                    currentAddress: {
                        pincode: currentAddress?.pincode,
                        state: currentAddress?.state,
                        city: currentAddress?.city,
                        country: currentAddress?.country,
                        h_no: currentAddress?.h_no,
                        pincodeValid: currentAddress?.pincodeValid
                    },
                    permanentAddress: {
                        pincode: permanentAddress?.pincode,
                        state: permanentAddress?.state,
                        city: permanentAddress?.city,
                        country: permanentAddress?.country,
                        h_no: permanentAddress?.h_no,
                        pincodeValid: permanentAddress?.pincodeValid
                    }
                }
            }
            )

            if(specificUserProfile?.privilage?.includes("SuperAdmin") ){
                setPrivilages([
                    { label: 'Super Admin', isSelected: true, value: 'Admin', disabled: true },
                ])
            }else{
                setPrivilages([
                    { label: 'Admin', isSelected: specificUserProfile?.privilage?.includes('Admin'), value: 'Admin', disabled: false },
                    { label: 'Planner', isSelected: specificUserProfile?.privilage?.includes('Planner'), value: 'Planner', disabled: false },
                    { label: 'Fee Manager', isSelected: specificUserProfile?.privilage?.includes('FeeManager'), value: 'FeeManager', disabled: false },
                    { label: 'Leave Manager', isSelected: specificUserProfile?.privilage?.includes('LeaveManager'), value: 'LeaveManager', disabled: false },
                    { label: 'Institute support', isSelected: specificUserProfile?.privilage?.includes('InstituteSupport'), value: 'InstituteSupport', disabled: false },
                    { label: 'Attendance marker', isSelected: specificUserProfile?.privilage?.includes('AttendanceMarker'), value: 'AttendanceMarker', disabled: false },
                    { label: 'Marketing', isSelected: specificUserProfile?.privilage?.includes('Marketing'), value: 'Marketing', disabled: false }
                ])
            }

            
        } else {
            try {
                currentAddress = JSON.parse(userDetails?.basicprofile?.currentAddress)
            } catch (e) { }
            try {
                permanentAddress = JSON.parse(userDetails?.basicprofile?.permanentAddress)
            } catch (e) { }

            setProfileDetails({
                ...userDetails,
                basicprofile: {
                    ...userDetails?.basicprofile,
                    currentAddress: {
                        pincode: currentAddress?.pincode,
                        state: currentAddress?.state,
                        city: currentAddress?.city,
                        country: currentAddress?.country,
                        h_no: currentAddress?.h_no,
                        pincodeValid: currentAddress?.pincodeValid
                    },
                    permanentAddress: {
                        pincode: permanentAddress?.pincode,
                        state: permanentAddress?.state,
                        city: permanentAddress?.city,
                        country: permanentAddress?.country,
                        h_no: permanentAddress?.h_no,
                        pincodeValid: permanentAddress?.pincodeValid
                    }
                }
            }
            )

            if(specificUserProfile?.privilage?.includes('SuperAdmin') ){
                setPrivilages([
                    { label: 'Super Admin', isSelected: true, value: 'Admin', disabled: true },
                ])
            }else{
                setPrivilages([
                    { label: 'Admin', isSelected: userDetails?.privilage?.includes('Admin'), value: 'Admin', disabled: user?.privilage?.includes('Admin') },
                    { label: 'Planner', isSelected: userDetails?.privilage?.includes('Planner'), value: 'Planner', disabled: false },
                    { label: 'Fee Manager', isSelected: userDetails?.privilage?.includes('FeeManager'), value: 'FeeManager', disabled: user?.privilage?.includes('Admin') },
                    { label: 'Leave Manager', isSelected: userDetails?.privilage?.includes('LeaveManager'), value: 'LeaveManager', disabled: user?.privilage?.includes('Admin') },
                    { label: 'Institute support', isSelected: userDetails?.privilage?.includes('InstituteSupport'), value: 'InstituteSupport', disabled: false },
                    { label: 'Attendance marker', isSelected: userDetails?.privilage?.includes('AttendanceMarker'), value: 'AttendanceMarker', disabled: false },
                    { label: 'Marketing', isSelected: userDetails?.privilage?.includes('Marketing'), value: 'Marketing', disabled: false },
                    
                ])
            }
            
        }
    }, [userDetails, specificUserProfile]);

    const inputProfilePic = useRef(null);
    const [userProfileDetails, setProfileDetails] = useState({});
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [isSaveHidden, setIsSaveHidden] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [deleteConfirmation, setdeleteConfirmation] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);

    const [privilages, setPrivilages] = useState();




    useEffect(() => {
        if (location?.state?.key == true) {
            togglePageLoader(true)
            fetchSpecificUserProfile(userRowDetails?.id, () => togglePageLoader(false));
        } else { }
    }, [location?.state?.key])

    console.log({ userProfileDetails });

    useEffect(() => {
        const tempDetails = {
            ...userProfileDetails,
            basicprofile: {
                ...userProfileDetails?.basicprofile,
                permanentAddress: { ...userProfileDetails?.basicprofile?.permanentAddress },
                currentAddress: { ...userProfileDetails?.basicprofile?.currentAddress }
            }
        };
        delete tempDetails?.basicprofile?.permanentAddress?.sameAsCa
        delete userDetails?.basicprofile?.permanentAddress?.sameAsCa
        setIsSaveHidden(
            JSON.stringify(userDetails) === JSON.stringify(tempDetails)
        )
    }, [userProfileDetails])

    const handleSaveClick = (callback, buttonCallback) => {

        userProfileDetails.basicprofile.currentAddress = JSON.stringify(userProfileDetails.basicprofile.currentAddress)
        userProfileDetails.basicprofile.permanentAddress = JSON.stringify(userProfileDetails.basicprofile.permanentAddress)
        userProfileDetails.basicprofile.dob = userProfileDetails.basicprofile.dob ? moment(userProfileDetails.basicprofile.dob).format("YYYY-MM-DD") : null;
        const params = { ...userProfileDetails, ...userProfileDetails?.basicprofile, id: userProfileDetails?.id };
        delete params.basicprofile;
        saveProfileDetails(params, (location?.state?.key == true), () => onSaveSuccess(callback), buttonCallback);
    }

    console.log({ userRowDetails })

    const onSaveSuccess = (callback) => {
        callback();
        setIsSaveHidden(true);
        if (location?.state?.key == true) {
            togglePageLoader(true)
            fetchSpecificUserProfile(userRowDetails?.id, () => togglePageLoader(false));
        }
    }

    const handleProfileModalOk = () => {
        setEditProfileModal(false);
        inputProfilePic?.current?.click();
    };

    const hendlePinChange = (pincode, childkey) => {
        getAddressFromPin(pincode, (district, state, country) => {
            handleInputChange(district, "basicprofile", childkey, 'city')
            handleInputChange(state, "basicprofile", childkey, 'state')
            handleInputChange(country, "basicprofile", childkey, 'country')
            if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa && childkey === "currentAddress") {
                handleInputChange(district, "basicprofile", "permanentAddress", 'city')
                handleInputChange(state, "basicprofile", "permanentAddress", 'state')
                handleInputChange(country, "basicprofile", "permanentAddress", 'country')

            }
        })
    }

    const onDeleteConfirm = (callback, successCallback) => {

if(userProfileDetails?.image == 'default.png' && userProfileDetails?.file){
    setProfileDetails((prevDetails) => ({
        ...prevDetails,
        file: 'default.png'
    }));
    successCallback()
}else{
    deleteProfilePic(callback, (location?.state?.key == true), () => {
        successCallback();
        handleInputChange('default.png', 'image')
        handleInputChange(undefined, 'file')
        inputProfilePic.current.value = null
    })
}
        
    }

    const handleAddrDuplicate = (val,userProfileDetails) => {
        handleInputChange(val, "basicprofile", "permanentAddress", "sameAsCa")
        if(val){
            userProfileDetails.user.basicprofile.permanentAddress = {
                ...userProfileDetails?.basicprofile?.currentAddress,
                sameAsCa: true
            }
        }else
        {
            userProfileDetails.user.basicprofile.permanentAddress = {
                h_no: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                sameAsCa: false
            }
        }
        if (val) {
            userProfileDetails.basicprofile.permanentAddress = {
                ...userProfileDetails?.basicprofile?.currentAddress,
                sameAsCa: true
            }
        } else {
            userProfileDetails.basicprofile.permanentAddress = {
                h_no: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                sameAsCa: false
            }
        }
        setProfileDetails({ ...userProfileDetails })
    }

    function onCheckChange(checkedValues) {
        console.log(checkedValues, 'here')

        let plannerdisabled = false, supportdisabled = false

        if (checkedValues?.includes('Admin')) {
            // checkedValues.push('Planner')
            // checkedValues.push('InstituteSupport')
            plannerdisabled = true,
                supportdisabled = true
        }

        setPrivilages([
            { label: 'Admin', isSelected: checkedValues?.includes('Admin'), value: 'Admin', disabled: user?.privilage?.includes('Admin') },
            { label: 'Planner', isSelected: checkedValues?.includes('Planner'), value: 'Planner', disabled: plannerdisabled },
            { label: 'Fee Manager', isSelected: checkedValues?.includes('FeeManager'), value: 'FeeManager', disabled: user?.privilage?.includes('Admin') },
            { label: 'Leave Manager', isSelected: checkedValues?.includes('LeaveManager'), value: 'LeaveManager', disabled: user?.privilage?.includes('Admin') },
            { label: 'Institute support', isSelected: checkedValues?.includes('InstituteSupport'), value: 'InstituteSupport', disabled: supportdisabled },
            { label: 'Attendance marker', isSelected: checkedValues?.includes('AttendanceMarker'), value: 'AttendanceMarker', disabled: supportdisabled },
            { label: 'Marketing', isSelected: checkedValues?.includes('Marketing'), value: 'Marketing'}
        ])
        setProfileDetails({
            ...userProfileDetails,
            privilage: checkedValues
        })
    };


    return (
        <div style={{ height: "100%" }}>
            {othersSelectedTab == 'Basic Profile' && location?.state?.key ?
                <PageHeader
                    title={location?.state?.key ? `${userRowDetails?.name}'s Profile` : "Edit Profile"}
                    onBackPress={() => history.goBack()}
                    actions={
                        isSaveHidden ? [] : (
                            fromWhere === 'userManagementProfile' && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ? (
                                [<Button type="link" style={{ fontSize: 14 }} disabled={userProfileDetails?.name?.trim() === ""} onClick={() => setShowConfirmModal(true)}>SAVE</Button>]
                            ) : null
                        )
                    }
                    givenStyle={{ marginLeft: location?.state?.key ? -128 : 0 }}
                />
                :
                <PageHeader
                    title={"Edit Profile"}
                    onBackPress={() => history.goBack()}
                    actions={
                        isSaveHidden ? [] : [<Button type="link" style={{ fontSize: 14 }} disabled={userProfileDetails?.name?.trim() === ""} onClick={() => setShowConfirmModal(true)}>SAVE</Button>]
                    }
                />
            }
  
            <ImageComponent src={userProfileDetails?.image || 'default.png'}
                onChange={() => { }}
                handleEditModal={setEditProfileModal}
                ref={inputProfilePic}
                setfileInputValue={(file) => { handleInputChange(file, 'file'); }}
                userProfileDetails={userProfileDetails}
            />
            <div style={{ padding: 20 }}>
                {user?.privilage?.includes("SuperAdmin") ? superAdminProfileDetails(userProfileDetails, handleInputChange, user, handleFocusChange, handleAddrDuplicate, userRowDetails, onCheckChange, privilages)?.map((section, index) => (
                    <DetailSection section={section} index={index} userProfileDetails={userProfileDetails} />
                ))
                    :
                    otherUserProfileDetails(userProfileDetails, handleInputChange, user, handleFocusChange, handleAddrDuplicate).map((section, index) => (
                        <DetailSection section={section} index={index} userProfileDetails={userProfileDetails} />
                    )
                    )}
            </div>
            {(user?.id !== specificUserId && fromWhere !== 'userManagementProfile') ?
                <div className='cursor-pointer'>
                    <Button type="transparent"
                        style={{ fontSize: 16, fontWeight: 600, marginTop: -20 }} className="m-b-40"
                        onClick={() => setShowTransferModal(true)}
                    >
                        Transfer ownership
                    </Button>
                </div>
                : null}
            {showTransferModal && <TransferOwnershipModal
                handleClose={() => setShowTransferModal(false)}
                visible={showTransferModal}
                setShowTransferModal={setShowTransferModal}
            />}
            <SaveConfirmation
                saveConfirmationModal={showConfirmModal}
                onCancel={() => setShowConfirmModal(false)}
                handleOk={(callback, buttonCallback) => handleSaveClick(callback, buttonCallback)}
            />
            <EditImage
                imageUrl={userProfileDetails?.file}
                onEditCancel={() => setEditProfileModal(false)}
                editModalVisible={editProfileModal}
                handleEditOk={handleProfileModalOk}
                deleteConfirm={() => {
                    setdeleteConfirmation(true), setEditProfileModal(false);
                }}
                deleteModalVisible={deleteConfirmation}
                onDeleteCancel={() => setdeleteConfirmation(false)}
                onDeleteConfirm={(callback, successCallback) => onDeleteConfirm(callback, successCallback)}
                setEditProfileModal={setEditProfileModal}
                setdeleteConfirmation={setdeleteConfirmation}
                fromUserProfile= {true}
                setProfileDetails={setProfileDetails}
                userProfileDetails={userProfileDetails}
            />
            {fromWhere != 'userManagementProfile' ?
                <Prompt
                    when={!isSaveHidden}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                : null}

            {location?.state?.key ? <PageDataLoader visible={pageLoader} /> : <PageDataLoader visible={pageLoader} />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { userDetails, instituteDetails, othersSelectedTab } = state.profileNew;
    const { specificUserId, specificUserProfile } = state.userManagementV2
    const { editPrivilage } = state.userManagement

    return {
        user,
        userDetails,
        instituteDetails,
        specificUserId,
        specificUserProfile,
        othersSelectedTab,
        editPrivilage
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteProfilePic: (callback, stopGetAccountDetails, successCallback) => dispatch(deleteProfilePic(callback, stopGetAccountDetails, successCallback)),
    saveProfileDetails: (params, stopGetAccountDetails, callback, buttonCallback) => dispatch(saveProfileDetails(params, stopGetAccountDetails, callback, buttonCallback)),
    fetchSpecificUserProfile: (id, callback) => dispatch(fetchSpecificUserProfile(id, callback)),
    setEditPrivilages: (privilage) => dispatch(setEditPrivilages(privilage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
