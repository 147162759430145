import { sessionService } from "redux-react-session";
import { Api } from "../services";
import { getStorageData, getStorageDetails } from "./digitalLibrary";
import { updateOrgDetails } from "./login";
import { setUserDetailsById } from "./userManagement";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_LOADER = "UPDATE_PROFILE_LOADER";
export const UPDATE_ADMIN_PROFILE_DATA = "UPDATE_ADMIN_PROFILE_DATA";

//institute details

export const SET_EDIT_INSTITUTE_NAME = "SET_EDIT_INSTITUTE_NAME";
export const SET_EDIT_INSTITUTE_TYPE = "SET_EDIT_INSTITUTE_TYPE";
export const SET_EDIT_INSTITUTE_WEBSITE = "SET_EDIT_INSTITUTE_WEBSITE";
export const SET_EDIT_INSTITUTE_BUILDING = "SET_EDIT_INSTITUTE_BUILDING";
export const SET_EDIT_INSTITUTE_DISTRICT = "SET_EDIT_INSTITUTE_DISTRICT";
export const SET_EDIT_INSTITUTE_STATE = "SET_EDIT_INSTITUTE_STATE";
export const SET_EDIT_INSTITUTE_PINCODE = "SET_EDIT_INSTITUTE_PINCODE";
export const SET_EDIT_INSTITUTE_COUNTRY = "SET_EDIT_INSTITUTE_COUNTRY";
//contact person information
export const SET_EDIT_CONTACT_NAME = "SET_EDIT_CONTACT_NAME";
export const SET_EDIT_CONTACT_PHONE = "SET_EDIT_CONTACT_PHONE";
export const SET_EDIT_CONTACT_EMAIL = "SET_EDIT_CONTACT_EMAIL";
export const SET_EDIT_CONTACT_DESIGNATION = "SET_EDIT_CONTACT_DESIGNATION";
//bulding details
export const SET_EDIT_BILLING_NO = "SET_EDIT_BILLING_NO";
export const SET_EDIT_BILLING_DISTRICT = "SET_EDIT_BILLING_DISTRICT";
export const SET_EDIT_BILLING_STATE = "SET_EDIT_BILLING_STATE";
export const SET_EDIT_BILLING_PINCODE = "SET_EDIT_BILLING_PINCODE";
export const SET_EDIT_BILLING_COUNTRY = "SET_EDIT_BILLING_COUNTRY";
export const SET_EDIT_BILLING_GSTNO = "SET_EDIT_BILLING_GSTNO";
export const SET_EDIT_BILLING_PANNO = "SET_EDIT_BILLING_PANNO";
//SETTINGS
export const SET_EDIT_AUTOAPPROVAL = "SET_EDIT_AUTOAPPROVAL";
export const SET_EDIT_TRANSACTION_FEE = "SET_EDIT_TRANSACTION_FEE";
//SUPPORT NUMBERS
export const SET_EDIT_SUPPORT_PHONENUMBERS = "SET_EDIT_SUPPORT_PHONENUMBERS";
//superAdmin address details
export const SET_USER_CURRENT_PERMINANT_ADD = "SET_USER_CURRENT_PERMINANT_ADD";
export const SET_EDIT_DESIGNATION = "SET_EDIT_DESIGNATION";
export const SET_USER_IMG = "SET_USER_IMG";
export const SET_DOC_LINK = "SET_DOC_LINK";
// storage details
export const UPDATE_BILLING_HISTORY = "UPDATE_BILLING_HISTORY"
//FOR SUPERADMIN
export const SUBSCRIPTION_PLAN = "SUBSCRIPTION_PLAN"
export const STORE_SUBSCRIPTION = 'STORE_SUBSCRIPTION'
export const FETCH_SUBS_STAT = "FETCH_SUBS_STAT"
//FOR HOURS COUNT
export const HOURS_COUNT = 'HOURS_COUNT'

export const setEditDocLink = (res) => ({
  type: SET_DOC_LINK,
  payload: res,
});
export const setEditSuperImg = (res) => ({
  type: SET_USER_IMG,
  payload: res,
});
export const setEditSuperAdminAddInfo = (res) => ({
  type: SET_USER_CURRENT_PERMINANT_ADD,
  payload: res,
});
export const setEditDesignation = (res) => ({
  type: SET_EDIT_DESIGNATION,
  payload: res,
});
//actions for institute information

export const setEditInstitutesName = (res) => ({
  type: SET_EDIT_INSTITUTE_NAME,
  payload: res,
});
export const setEditInstitutesType = (res) => ({
  type: SET_EDIT_INSTITUTE_TYPE,
  payload: res,
});
export const setEditInstitutesWebsite = (res) => ({
  type: SET_EDIT_INSTITUTE_WEBSITE,
  payload: res,
});
export const setEditInstitutesBuilding = (res) => ({
  type: SET_EDIT_INSTITUTE_BUILDING,
  payload: res,
});
export const setEditInstitutesDistrict = (res) => ({
  type: SET_EDIT_INSTITUTE_DISTRICT,
  payload: res,
});
export const setEditInstitutesState = (res) => ({
  type: SET_EDIT_INSTITUTE_STATE,
  payload: res,
});
export const setEditInstitutesPincode = (res) => ({
  type: SET_EDIT_INSTITUTE_PINCODE,
  payload: res,
});
export const setEditInstitutesCountry = (res) => ({
  type: SET_EDIT_INSTITUTE_COUNTRY,
  payload: res,
});
//action for contact persons info
export const setEditContactName = (res) => ({
  type: SET_EDIT_CONTACT_NAME,
  payload: res,
});
export const setEditContactPhone = (res) => ({
  type: SET_EDIT_CONTACT_PHONE,
  payload: res,
});
export const setEditContactEmail = (res) => ({
  type: SET_EDIT_CONTACT_EMAIL,
  payload: res,
});
export const setEditContactDesignation = (res) => ({
  type: SET_EDIT_CONTACT_DESIGNATION,
  payload: res,
});
//actions for building details
export const setEditBuildingNo = (res) => ({
  type: SET_EDIT_BILLING_NO,
  payload: res,
});
export const setEditBuildingDistrict = (res) => ({
  type: SET_EDIT_BILLING_DISTRICT,
  payload: res,
});
export const setEditBuildingState = (res) => ({
  type: SET_EDIT_BILLING_STATE,
  payload: res,
});
export const setEditBuildingPincode = (res) => ({
  type: SET_EDIT_BILLING_PINCODE,
  payload: res,
});
export const setEditBuildingCountry = (res) => ({
  type: SET_EDIT_BILLING_COUNTRY,
  payload: res,
});
export const setEditBuildingGSTNo = (res) => ({
  type: SET_EDIT_BILLING_GSTNO,
  payload: res,
});
export const setEditBuildingPANNo = (res) => ({
  type: SET_EDIT_BILLING_PANNO,
  payload: res,
});
//SETTINGS
export const setEditAutoApproval = (res) => ({
  type: SET_EDIT_AUTOAPPROVAL,
  payload: res,
});
export const setEditTransactionFee = (res) => ({
  type: SET_EDIT_TRANSACTION_FEE,
  payload: res,
});
export const setEditSupportPhone = (res) => ({
  type: SET_EDIT_SUPPORT_PHONENUMBERS,
  payload: res,
});
/////////////////////////////////////////////////
const updateAdminProfileDaya = (data) => ({
  type: UPDATE_ADMIN_PROFILE_DATA,
  data,
});

export const updateBillingHistory = (data) => ({
  type: UPDATE_BILLING_HISTORY,
  data
})

const subscriptionPlans = (res) => ({
  type: SUBSCRIPTION_PLAN,
  res,
});

export const storeSubscription = (res) => ({
  type: STORE_SUBSCRIPTION,
  res
})

export const FetchSubscriptionStat = (res) => ({
  type: FETCH_SUBS_STAT,
  res
})


export const getAdminProfile = (callback) => {
  
  return (dispatch,getState) => {
  const { user } = getState().session;


    const url = `/user/admin-profile`;
    return Api.get(url).send((response, error) => {
      if (callback) {
        callback();
      }
    
      dispatch(updateAdminProfileDaya(response));
      dispatch(setUserDetailsById(response?.adminProfile));
      dispatch(updateBillingHistory(response?.getBillingHistory))
      // dispatch(getStorageData())
      dispatch(getStorageDetails());
        // sessionService.saveUser({...response?.user})
      // dispatch(updateOrgDetails({
      //   title:response?.instituteInfo?.instituteName,
      //   logo:response?.instituteInfo?.logo,
      //   website:response?.instituteInfo?.website,
      //   instituteId:response?.instituteInfo?.instituteId
      // }));
      console.log(response)
    });
  };
};

export const getProfile = (input) => {
  return (dispatch) => {
    const url = `/user/userId/${input}`;
    dispatch(updateProfileLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(updateProfile(response));
      dispatch(updateProfileLoader(false));
    });
  };
};
export const updateProfile = (profile) => ({
  type: UPDATE_PROFILE,
  profile,
});

const updateProfileLoader = (bool) => ({
  type: UPDATE_PROFILE_LOADER,
  bool,
});

export const hoursCount = (res) => ({
  type: HOURS_COUNT,
  res
})

export const editSuperAdmin = (
  callback,
  handleSuccessCallback,
  fileInputValueArg
) => {
  return (dispatch, getState) => {
    const {
      startDate,
      endDate,
      dashBoardRole,
      role,
      tab,
      sort,
      search,
      editName,
      editRole,
      editPhone,
      editEmail,
      editGender,
      editBloodGroup,
      editPrivilage,
      editEmergencyName,
      editEmergencyPhone,
      editEmergencyRelation,
      editCurrentAddress,
      editCurrentPinCode,
      editCurrentState,
      editCurrentDistrict,
      editCurrentCountry,
      editPermanentAddress,
      editPermanentPinCode,
      editPermanentState,
      editPermanentDistrict,
      editPermanentCountry,
      userDetailsById,
      specificUserId,
      editDateOfBirth,
      nextRollNo,
      classId,
    } = getState().userManagement;
    const {
      profileSingle,
      profileLoader,
      adminProfileData,
      editInstituteName,
      editInstituteType,
      editInstituteWebsite,
      editInstituteBuilding,
      editInstituteDistrict,
      editInstituteState,
      editInstitutePinode,
      editInstituteCountry,
      editContactName,
      editContactPhone,
      editContactEmail,
      editContactDesignation,
      editBillingNo,
      editBillingDistrict,
      editBillingState,
      editBillingPincode,
      editBillingCountry,
      editBillingGSTNo,
      editBillingPANNo,
      editAutoapproval,
      editTransactionFee,
      editSupportPhoneNo,
      superAdminAddInfo,
      editDesignation,
    } = getState().profile;
    console.log("AA-superAdminAddInfo",superAdminAddInfo?.sameAsCuAa);

    const { user } = getState().session;
    let temp = [];
    for (let i = 0; i < editSupportPhoneNo?.length; i++) {
      // console.log('phone',editSupportPhoneNo[i]?.length)
      if (editSupportPhoneNo[i] && editSupportPhoneNo[i].length != 0 && !temp?.includes(editSupportPhoneNo[i])) {
        temp.push(editSupportPhoneNo[i]);
      }

    }
    console.log('Phone', temp)
    let curretAdd = {
      h_no: editCurrentAddress,
      state: editCurrentState,
      pincode: editCurrentPinCode,
      city: editCurrentDistrict,
      country: editCurrentCountry,
      PinAvailable: superAdminAddInfo?.currentPinCode,
    };

    // locality: parseData(userDetailsById?.basicprofile?.permanentAddress)
    //   ?.locality,
    let permanentAdd = {
      h_no: editPermanentAddress,
      state: editPermanentState,
      pincode: editPermanentPinCode,
      city: editPermanentDistrict,
      country: editPermanentCountry,
      sameAsCa: superAdminAddInfo?.sameAsCuAa,
      PinAvailable: superAdminAddInfo?.checkPermanent,
    };

    const params = {
      // user: {
      privilage: editPrivilage,
      userId: user.id,

      files: [superAdminAddInfo?.userImage, fileInputValueArg],
      superAdminImage:
        superAdminAddInfo?.userImage &&
          superAdminAddInfo?.userImage !== undefined
          ? true
          : false,
      orgLogo:
        fileInputValueArg && fileInputValueArg !== undefined ? true : false,
      // orgLogo: fileInputValueArg,
      name: editName,
      phone: editPhone,
      gender: editGender,
      bloodGroup: editBloodGroup,
      dob: editDateOfBirth,
      role: editRole,
      // permission: "None",
      currentAddress: JSON.stringify(curretAdd),
      permanentAddress: JSON.stringify(permanentAdd),
      emergencyContactName: editEmergencyName,
      emergencyPhone: editEmergencyPhone,
      emergencyContactRelation: editEmergencyRelation,
      // },
      // org: {
      instituteName: editInstituteName,
      instituteType: editInstituteType,
      website: editInstituteWebsite,
      locality: editInstituteBuilding,
      city: editInstituteDistrict,
      state: editInstituteState,
      pincode: editInstitutePinode,
      country: editInstituteCountry,
      contactName: editContactName,
      contactPhone: editContactPhone,
      contactEmail: editContactEmail,
      localityBilling: editBillingNo,
      cityBilling: editBillingDistrict,
      stateBilling: editBillingState,
      pincodeBilling: editBillingPincode,
      countryBilling: editBillingCountry,
      panNo: editBillingPANNo,
      gstNo: editBillingGSTNo,
      supportPhone: temp,
      transactionPaidBy: editTransactionFee,
      studentAutoApprove: editAutoapproval,
      contactDesignation: editContactDesignation,
      superAdminDesignation: editDesignation,
      // },
    };
    // console.log("type", typeof editSupportPhoneNo);
    // console.log("params", typeof params.supportPhone);
    // console.log("image", superAdminAddInfo?.userImage);

    return Api.post("/user/update-admin-profile").uploadMultiple(
      params,
      (percent, response) => {
        // console.log("hitting the api");

        if (
          typeof response != "undefined" &&
          response.data.show &&
          response.data.show.type !== "error"
        ) {
          if (percent === 100) {
            if (callback) {
              callback();
            }
            // console.log("successCallback", handleSuccessCallback);
            handleSuccessCallback();
            dispatch(getAdminProfile());
          }
        }
        if (percent === -1) {
          callback();
        }
      }
    );
  };
};

export const deleteOrgLogo =
  (callback = null) =>
    (dispatch, getState) => {
      const { search, sectionDetails, specificUserId, classId } =
        getState().userManagement;
      // console.log("api delete logo");
      return Api.patch(`/user/remove-org-logo`).send((response) => {
        if (callback) {
          callback();
        }
        // console.log("api delete logo success");

        if (response !== undefined) {
        }
      });
    };

export const verifyPoneNewPhoneNumber =
  (phone, callback = null, successCallBack) =>
    (dispatch, getState) => {
      return Api.post("/user/generateOtpV2")
        .params({ phoneNo: phone })
        .send((response, error) => {
          if (response) {
            if (callback) {
              callback();
            }
            if (response.show.type === "success") {
              if (successCallBack) {
                successCallBack();
              }
            }
          } else {
            console.log(error);
          }
        });
    };
export const verifyPoneNewPhoneNumberAndOtp =
  (phone, otp, callback = null, successCallBack) =>
    (dispatch, getState) => {
      return Api.post("/user/verifyOtp")
        .params({ phoneNo: phone, otp: otp })
        .send((response, error) => {
          if (callback) {
            callback();
          }
          if (response) {
            if (response.show.type === "success") {
              // console.log("inside the opt success api");
              successCallBack();
            }
          } else {
            console.log(error);
          }
        });
    };





export const getSubscriptionPlan = () => {
  const url = `/schedule-new/subscription/plans`;
  return (dispatch, getState) => {
    // dispatch(setleavePageLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).send((response, error) => {
        resolve();
        if (response) {
          dispatch(subscriptionPlans(response));
        }
        // dispatch((false));
      });
    });
  };
};

export const postSubscription = (object, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/subscription/on-payment-success`;
    return new Promise((resolve, reject) => {
      Api.post(url, object).send((response, error) => {
        if (response != undefined) {
          dispatch(hoursCount(1));

          if (callback) {
            callback();
          }
          if (successCallback) {
            successCallback(subscriptionStatus());
          } else {
            if (callback) {
              callback();
            }
          }
          // console.log("response=", response);
        }
      });
    });
  };
};

export const subscriptionStatus = () => {
  return (dispatch) => {
    const url = `/schedule-new/subscription`;
    return new Promise((resolve, reject) => {
      Api.get(url).send((response, error) => {
        if (response != undefined) {
          dispatch(FetchSubscriptionStat(response))
        }
      });
    });
  };
};
