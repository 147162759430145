import { Modal } from 'antd'
import { Button } from 'components/Button';
import React from 'react'
import { useHistory } from 'react-router';
const successImage = require("Assets/user/ill-success.svg").default;

function SuccessModal({ isSuccessModalVisible, setSuccessModalVisible, courseTypeName }) {
    const history = useHistory();
    const handleCancel = () => {
        setSuccessModalVisible(false);
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isSuccessModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <div className='r-c-c' style={{ flexDirection: "column", padding: "0px 20px 20px 20px" }}>
                <img src={successImage} alt="" style={{ height: 150, width: 150 }} />
                <div
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "roboto",
                        marginBottom: "2px",
                    }}
                >
                    A new {courseTypeName == "studyMaterials" ?  "study material"  :  courseTypeName == "videoResources" ? 'video resource' : courseTypeName == "onlineTestSeries" ? 'online test series': "course"} successfully added!
                </div>
                <center
                    style={{
                        fontSize: "16px",
                        color: "#636363",
                        fontFamily: "roboto",
                    }}
                >
                    Would you like to add fee details and {courseTypeName == "studyMaterials" ?  "resources"  :  courseTypeName == "videoResources" ? 'video resource' : courseTypeName == "onlineTestSeries" ? 'online test series': "curriculum"} to the newly added {courseTypeName == "studyMaterials" ?  "study material"  :  courseTypeName == "videoResources" ? 'video resource' : courseTypeName == "onlineTestSeries" ? 'online test series': "course"}?
                </center>
            </div>
            <div className='r-c-se m-t-10 m-b-10'>
                <Button onClick={() => {
                    setSuccessModalVisible(false);
                    history.push({ pathname: courseTypeName == "liveCourses" ? "/live-courses-data" : courseTypeName == "videoResources" ? "/video-resources-data" : courseTypeName == "onlineTestSeries" ? "/online-test-series-details" : courseTypeName == "studyMaterials" ? "/study-materials-data" : "/live-courses-data", state: { key: "fromMaybeLaterBtn" } })
                }}>
                    MAYBE LATER
                </Button>
                <Button type="primary" onClick={() => { history.push('/course-details') }}>ADD NOW</Button>
            </div>
        </Modal>
    )
}

export default SuccessModal