import React from "react";
import { connect } from "react-redux";
import Layout from "antd/lib/layout";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import PageHeader from "antd/lib/page-header";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Carousel from "antd/lib/carousel";
import { Divider } from 'antd';
import "antd/lib/layout/style/index";
import "antd/lib/button/style/index";
import "antd/lib/input/style/index";
import "antd/lib/page-header/style/index";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/carousel/style/index";
import { Heading, SubHeading, SmallHeading } from "components/Typography";
import { Notification } from "../../services";
import { checkToken, updatePassword } from "../../actions/login";
import carouselData from "./carouselData";
import Card from "components/card";
import "./styles.css";

const appStore = require("Assets/applestore.png").default;
const playStore = require("Assets/playstore.png").default;
const logo = require("Assets/logo.png").default;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      password: "",
      confirmPassword: "",
    };
  }

  componentDidMount() {
    let url = new URL(window.location.href);
    let userId = url.searchParams.get("userId");
    let token = url.searchParams.get("token");
    let source = url.searchParams.get("source");
    this.setState({ userId });
    this.props.checkToken(userId, token, source);

    window.history.forward();
    //   function noBack() {
    //       window.history.forward();
    //   }
  }

  handleResetClick() {
    const { userId, password, confirmPassword } = this.state;
    const { history } = this.props;
    if (confirmPassword !== password) {
      return Notification.error(
        "Error",
        "Password and Confirm Password should be the same"
      );
    }
    this.props.updatePassword(userId, password, history);
  }

  render() {
    const { history, isResetDisabled, resetLoader } = this.props;

    return (
      <div className='aulas-login__body-wrap'>
        <Row className='aulas-login__header-wrap'>
          <Col xs={7} sm={5} md={3} lg={2}>
            <img src={logo} alt="aulas" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        <Row  justify="space-around" align="middle" className='flex-1'>
          <Col xs={0} md={12} style={{ marginRight: 20 }}>
            <Carousel autoplay style={{}} dots={false}>
              {carouselData.map((data, index) => (
                <div className="text-center" key={index}>
                  <div className="r-c-c-c">
                    <Col xs={12}>
                      <img src={data.image} alt={data.title} style={{ maxWidth: "100%" }} />
                    </Col>
                  </div>
                  <div style={{}}>
                    <Col flex="auto">
                      <Heading bold>{data.title}</Heading>
                      <SubHeading color="#636363">{data.description}</SubHeading>
                    </Col>
                  </div>
                </div>
              ))}
            </Carousel>
          </Col>
          <Col xs={23} md={9} style={{}}>
            <Card className='aulas-login__reset-password-wrap'>
              <div className="r-c-c-c">
                <div className='m-b-20'><img src={logo} width={100} alt="" /></div>
                <div className='text-center tabsHolder color-black' style={{width:'250px'}}>
                    Set A New Password
                </div>
              </div>
              <div style={{}}>
                <div className="m-t-5">
                  <SmallHeading bold color="#636363">New password</SmallHeading>
                  <Input.Password className="aulas-login__input" placeholder="******" disabled={isResetDisabled}
                    onChange={(em) => this.setState({ password: em.target.value })}
                  />
                </div>
                <div className="m-t-20">
                  <SmallHeading bold color="#636363">Confirm Password</SmallHeading>
                  <Input.Password className="aulas-login__input" placeholder="******" disabled={isResetDisabled}
                    onChange={(pass) => this.setState({ confirmPassword: pass.target.value })}
                  />
                </div>
                <div className="m-t-30 m-b-10">
                  <Button block type="primary" shape="round" className="r-c-c bold-bold" style={{ padding: 20 }}
                    onClick={() => this.handleResetClick()} loading={resetLoader}>
                    Set Password
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className='aulas-login__footer-wrap'>
          <Col xs={24}>
            <div className='r-c-c flex-wrap p-10'>
              <div className='r-ac p-t-10 p-b-10'>
                <img src={require("Assets/purple-heart.svg").default} alt="aulas App Store" style={{ maxWidth: "15px" }} />
                <div className='m-l-5 blue'>aulas care</div>
              </div>
              <a href='https://www.aulas.in/' target='_blank()' className='m-l-20 p-t-10 p-b-10 blue'>www.aulas.in</a>
              <div className='m-l-20 p-t-10 p-b-10'>Login using aulas app</div>
              <a href='https://apps.apple.com/in/app/aulas/id1564724183' target='_blank()' className='cursor-pointer m-l-20 p-t-10 p-b-10'>
                <img src={appStore} alt="aulas App Store" style={{ maxWidth: "80px" }} />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.classroom.aulas' target='_blank()' className='cursor-pointer m-l-20 p-t-10 p-b-10'>
                <img src={playStore} alt="aulas Play Store" style={{ maxWidth: "80px" }} />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isResetDisabled, resetLoader } = state.login;
  return {
    isResetDisabled,
    resetLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkToken: (userId, token, source) => dispatch(checkToken(userId, token, source)),
  updatePassword: (userId, password, history) =>
    dispatch(updatePassword(userId, password, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
