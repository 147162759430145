import { Modal } from 'antd'
import React from 'react'
import { Button } from 'components/Button';

const LeadsFilterModal = ({ filterModal, setFilterModal,filterParam, setFilterParam, setDropdownVisible, getCrmDashboardLeads, page,togglePageLoader,toggleReqsLoader, isLoadingTrue, applyFilters,search, setIsLoadingTrue }) => {

    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '', fontSize: 14, fontWeight: "bold", marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }


    const handleCancel = () => {
        setFilterModal(false)
    }
    const Types = [{ label: "All", value: 'All' }, { label: "Registration", value: "Register" }, { label: "Enquiry", value: "Enquiry" }, { label: "Add to cart", value: "AddToCart" }, { label: "Bookmark", value: "Bookmark" }]

    const handleTypeToggle = (val) => {
        const updatedFilterParam = { ...filterParam };

        updatedFilterParam.type = updatedFilterParam.type || [];

        if (val === 'All') {
            updatedFilterParam.type = ['All'];
        } else {
            const index = updatedFilterParam.type.indexOf('All');
            if (index !== -1) {
                updatedFilterParam.type.splice(index, 1);
            }

            const indexOfVal = updatedFilterParam.type.indexOf(val);
            if (indexOfVal !== -1) {
                updatedFilterParam.type.splice(indexOfVal, 1);
            } else {
                updatedFilterParam.type.push(val);
            }

            if (updatedFilterParam.type.length === 4) {
                updatedFilterParam.type = ['All'];
            }

            if (updatedFilterParam.type.length === 0) {
                updatedFilterParam.type.push('All');
            }
        }

        setFilterParam(updatedFilterParam);
    };



    return (
        <Modal
  centered
  className="modal-round-corner"
  visible={filterModal}
  onCancel={handleCancel}
  onOk={handleCancel}
  footer={null}
  width={380}
>
  <div style={{ marginTop: -7 }}>
    <FilterTitleName filterName="Type" />
  </div>

  <div style={{ display: "flex", flexWrap: "wrap" }}>
    {Types?.map((item) => (
      <div
        key={item.value}
        className='sortByFilter'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: filterParam?.type?.includes(item?.value) ? "#D6C8F5" : '#FFFFFF',
          color: filterParam?.type?.includes(item?.value) ? '#191919' : "#636363",
          border: filterParam?.type?.includes(item?.value) ? 'none' : '1px solid #E6E6E6',
          fontWeight: filterParam?.type?.includes(item?.value) ? "650" : "normal",
          maxWidth: 130,
          minWidth: 100,
          height: '35px',
          cursor: 'pointer',
          marginBottom: '5px',
          marginTop: '6px',
        }}
        onClick={() => handleTypeToggle(item?.value)}
      >
        {item?.label}
      </div>
    ))}
  </div>

  <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: "5px" }}></div>
  <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
    <Button
      style={{
        color: "#594099",
        cursor: "pointer"
      }}

      type='link'
      onClick={() => {
        handleCancel()
        setDropdownVisible(false);
        getCrmDashboardLeads(search, 'All', page, (page == 1 ? true : false), () => {
          togglePageLoader(false);
        }, () => {
          toggleReqsLoader(false); 
          setFilterParam({ type: ['All'] });
        });
      }}
    >
      RESET
    </Button>
    <Button loading={isLoadingTrue} type='primary' onClick={() => {applyFilters('leads'); setIsLoadingTrue(true)}}>
      APPLY
    </Button>
  </div>
</Modal>

    )
}

export default LeadsFilterModal