import { StarFilled } from '@ant-design/icons';
import { Rate } from 'antd';
import React, { useEffect } from 'react'

// function RatingStars({ star }) {
//     const fullStars = Math.floor(star);
//     const halfStar = star % 1 === 0.5;
//     const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
//     const stars = [];
//     for (let i = 0; i < fullStars; i++) {
//         stars.push(<StarFilled key={i} className="fas fa-star" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
//     }
//     if (halfStar) {
//         stars.push(<StarFilled key="half" className="fas fa-star-half-alt" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
//     }
//     for (let i = 0; i < emptyStars; i++) {
//         stars.push(<StarFilled key={`empty-${i}`} className="fas fa-star" style={{ color: '#AEAEAE', marginLeft: 2, fontSize: 12 }} />);
//     }
//     return (
//         <div style={{ display: "flex" }}>
//             <div style={{ color: "#FFA931", fontWeight: "bold", marginRight: 5 }}>{star}</div>
//             <div >{stars.map((starElement) => starElement)}</div>
//         </div>
//     );
// }

const RatingStars = ({ star }) => 
            <Rate disabled value={star} allowHalf style={{ color: '#FFA931', marginLeft: 2, fontSize: 13, }} />;

export default RatingStars
