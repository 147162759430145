import { Tooltip, Typography } from 'antd';
import { Information, SmallInfo, Title, Title2 } from 'components/Typography';
import React from 'react';
import { OfferTypeGraphColor } from '../constants/offers';

function OfferGraph({ offerCounts }) {
    const totalOfferCount = parseInt(offerCounts?.total) || 0;
    const privateOfferCount = parseInt(offerCounts?.privateCount) || 0;
    const bannerOfferCount = parseInt(offerCounts?.banner) || 0;
    const publishedCount = parseInt(offerCounts?.published) || 0;
    const expiredCount = parseInt(offerCounts?.expired) || 0;
    const unpublishedCount = parseInt(offerCounts?.unpublished) || 0;
    const timesAvailedCount = parseInt(offerCounts?.timesAvailed) || 0;
    const availableCount = parseInt(offerCounts?.available) || 0;
    const unavailableCount = parseInt(offerCounts?.unavailable) || 0;
    const availableSumCount = bannerOfferCount + privateOfferCount + publishedCount; // availableSumCount <= available count (published includes banner)
    let sum = 0, previousSum = 0; // round of to 100
    let bannerPercentage = bannerOfferCount * 100 / (availableSumCount || 1);
    sum = previousSum = bannerPercentage;
    bannerPercentage = Math.round(bannerPercentage);
    let privateOfferPercentage = Math.round(privateOfferCount * 100 / (availableSumCount || 1));
    sum += privateOfferPercentage;
    privateOfferPercentage = Math.round(sum) - Math.round(previousSum);
    let publishedOfferPercentage = Math.round(publishedCount * 100 / (availableSumCount || 1));
    previousSum = sum;
    sum += publishedOfferPercentage;
    publishedOfferPercentage = Math.round(sum) - Math.round(previousSum);

    const expiredPercentage = Math.round(expiredCount * 100 / (unavailableCount || 1));
    const availableItems = {
        items: [{
            type: 'banner',
            count: bannerOfferCount,
            width: `${bannerPercentage}%`,
            subtitle: 'code in display',
            color: OfferTypeGraphColor.banner,
        }, {
            type: 'privateOffer',
            count: privateOfferCount,
            width: `${privateOfferPercentage}%`,
            subtitle: 'private codes',
            color: OfferTypeGraphColor.privateOffer,
        },
        {
            type: 'published',
            count: publishedCount,
            width: bannerPercentage === 0 && privateOfferPercentage === 0 && publishedCount === 0 ? "100%" : `${publishedOfferPercentage}%`,
            subtitle: 'published codes',
            color: bannerPercentage === 0 && privateOfferPercentage === 0 && publishedCount === 0 ? "#E6E6E6" : OfferTypeGraphColor.published,
        }
        ],
        legendData: [{
            subtitle: 'published codes',
            count: publishedCount,
            color: OfferTypeGraphColor.published,
        }, {
            subtitle: 'private codes',
            count: privateOfferCount,
            color: OfferTypeGraphColor.privateOffer,
        }, {
            subtitle: 'code in display',
            count: bannerOfferCount,
            color: OfferTypeGraphColor.banner,
        }]
    };
    const unavailableItems = {
        items: [{
            type: 'expired',
            count: expiredCount,
            width: `${expiredPercentage}%`,
            subtitle: 'expired codes',
            color: OfferTypeGraphColor.expired,

        }, {
            type: 'unpublished',
            count: unpublishedCount,
            width: `${100 - expiredPercentage}%`,
            subtitle: 'unpublished codes',
            color: unpublishedCount == 0 && expiredCount == 0 ? "#E6E6E6" : OfferTypeGraphColor.unpublished,
        }],
        legendData: [{
            subtitle: 'unpublished codes',
            count: unpublishedCount,
            color: OfferTypeGraphColor.unpublished,
        }, {
            subtitle: 'expired codes',
            count: expiredCount,
            color: OfferTypeGraphColor.expired,

        }],
    }
    const availedItems = {
        items: [{
            type: 'availed',
            count: timesAvailedCount,
            width: `100%`,
            subtitle: 'times availed',
            color: timesAvailedCount > 0 ? OfferTypeGraphColor.timesAvailed : "#E6E6E6",
            opacity: 1,
        }],
        legendData: [{
            subtitle: 'times availed',
            count: timesAvailedCount,
            color: OfferTypeGraphColor.timesAvailed,
        }],
    };
    const graphOfferSections = [
        {
            title: "AVAILABLE CODES",
            items: availableItems,
        },
        {
            title: "BLANK SPACE",
            items: { items: [], legendData: [] },
        },
        {
            title: "UNAVAILABLE CODES",
            items: unavailableItems,
        },
        {
            title: "TIMES AVAILED",
            items: availedItems,
        }
    ]
    return (
        <div className="offer-graph">
            <section className='offer-graph-header'>
                <Title color="#191919" className='offer-graph-title'>{totalOfferCount}</Title>
                <Title2 color="#636363" className='offer-graph-title-description'>{`total promotional offers created`}</Title2>
            </section>
            <section className='offer-graph-distribution'>
                {graphOfferSections.map((section, index) => {
                    return (
                        <>
                            {index === 1 ? <div></div>
                                :
                                <div key={index} className='graph-offer-section'>
                                    <article className='graph-offer-type'>
                                        <Title2 color='#636363'>{section.title}</Title2>
                                        <div className='offer-graph-bar'>
                                            {section.items.items.map((item, index) => {
                                                return (
                                                    <Tooltip
                                                        key={item.type}
                                                        title={
                                                            <div style={{
                                                                width: '100%', height: '100%', marginTop: '2px', padding: '12px',
                                                            }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                                    <Typography.Text><Information>{item.count}</Information></Typography.Text>
                                                                    <Typography.Text><Information>{item.subtitle}</Information></Typography.Text>
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <div className='offer-graph-division' style={{ width: item.width, backgroundColor: item.color, opacity: item.opacity || 1 }}></div>
                                                    </Tooltip>
                                                );
                                            })
                                            }
                                        </div>
                                    </article>
                                    <article className='offer-graph-legend'>
                                        {section.items.legendData.map((item, index) => {
                                            return (
                                                <article key={index} className='offer-legend-item'>
                                                    <div style={{ height: "0.8rem", width: "0.8rem", backgroundColor: item.color, borderRadius: "0.2rem" }}></div>
                                                    <div className='offer-legend-item-label'>
                                                        <SmallInfo color='#191919'>{`${item.subtitle} (${item.count})`}</SmallInfo>
                                                    </div>
                                                </article>
                                            );
                                        })}
                                    </article>
                                </div>}
                        </>
                    );
                })
                }
            </section>
        </div>
    )
}

export default OfferGraph;