import { createTestModal, fetchQuestionPaletteData, getListViewData, getTestSampleQuestionPaperData, storeListViewData, storePageNumber, storeSubjectId } from 'actions/testAdmin'
import { Tabs } from 'antd'
import { Button } from 'components/Button'
import PageDataLoader from 'components/pageDataLoader'
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import ReactHtmlParser from 'react-html-parser';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import TeX from '@matejmazur/react-katex';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { importQPaper } from 'actions/marketTest'


const { TabPane } = Tabs;

export function NodataCard({ containerheight = "80%", imgwidth = "30%", imgheight = "30%" }) {
    return (
        <div className="r-c-c-c" style={{ height: containerheight }}>
            <img style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }} src={require('../../Assets/ill-no-data.svg').default} alt="" />
            <div className="r-c-c-c">
                <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                <p style={{ color: "#636363", fontFamily: "roboto" }}>There are no questions to show yet. You can create one from here.</p>
            </div>
        </div>
    )
}


function SampleImportListView({importQPaper, importMeta, getTestSampleQuestionPaperData, createTestModal, user, subjectNameContainer, storePageNumber, getListViewData, storeSubjectId, storeListViewData, subjectIdContainer, listViewDataContainer, fetchQuestionPaletteData}) {
    const history = useHistory();
    const [importLoader, setImportLoader] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    let [page, setPage] = useState(1);
    const location = useLocation()
    useEffect(() => {
        togglePageLoader(true);
        fetchQuestionPaletteData(() => togglePageLoader(false))
        // togglePageLoader(true);
        // getListViewData(() => togglePageLoader(false));
        return () => {
            storeListViewData([])
            storePageNumber(1)
        }
    }, [])

    useEffect(() => {
        window.onbeforeunload = function () {
            return true;
        };
        if (window.onbeforeunload) {
            storeListViewData([])
            storePageNumber(1)
            togglePageLoader(true);
            getListViewData(() => togglePageLoader(false));
        }
        return () => {
            window.onbeforeunload = null;
        };

    }, []);

    const callback = (key) => {
        setPage(1)
        storePageNumber(1);
        storeListViewData([])
        togglePageLoader(true);
        getListViewData(() => togglePageLoader(false));
    };

    const QuesAnsView = ({ questionData, indexMain }) => {

        const QuestionContainer = ({ questionData, indexMain, comprehensiveData, orQuestionsData, compIndex }) => {
            console.log('indexMain', indexMain)
            console.log('questionData', questionData)
            console.log('comprehensiveData', comprehensiveData)
            console.log('orQuestionsData', orQuestionsData)
            if (questionData?.type === 'Comprehensive') {
                return (
                    <div>
                        <div className='r-jsb m-t-20'>
                            <div className='display-flex flex-1'>
                                <div className='text-xmd bold-600 color-black m-r-5'>{questionData?.index}.</div>
                                <div className='text-xmd color-black resize-editor-img'>
                                    {ReactHtmlParser(questionData?.questionContent?.paragraph,
                                        {
                                            transform: (node) => {
                                                if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                    return <TeX math={`${node.attribs['data-value']}`} />;
                                                }
                                                if (node.type === 'tag' && node.name === 'img') {
                                                    return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                                }
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div>
                        </div>
                        <div className='display-flex flex-1'>
                            <div className='text-xmd bold-600 color-black m-r-5'>{indexMain + 1}{compIndex !== null && compIndex !== undefined ? `(${String.fromCharCode('a'.charCodeAt() + compIndex)}).` : null}</div>
                            <div className='text-xmd color-black resize-editor-img'>
                                {ReactHtmlParser(comprehensiveData?.questionContent?.statement,
                                    {
                                        transform: (node) => {
                                            if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                return <TeX math={`${node.attribs['data-value']}`} />;
                                            }
                                            if (node.type === 'tag' && node.name === 'img') {
                                                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            } else if (questionData?.type === 'OrQuestions') {
                return (
                    <div className='r-jsb m-t-20'>
                        <div className='display-flex flex-1'>
                            <div className='text-xmd bold-600 color-black m-r-5'>{questionData?.index}.</div>
                            <div className='text-xmd color-black resize-editor-img'>
                                {ReactHtmlParser(orQuestionsData?.questionContent?.statement,
                                    {
                                        transform: (node) => {
                                            if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                return <TeX math={`${node.attribs['data-value']}`} />;
                                            }
                                            if (node.type === 'tag' && node.name === 'img') {
                                                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div>
                    </div>
                )

            } else {
                return (
                    <div className='r-jsb m-t-20'>
                        <div className='display-flex flex-1'>
                            <div className='text-xmd bold-600 color-black m-r-5'>{questionData?.index}.</div>
                            <div className='text-xmd color-black resize-editor-img'>
                                {ReactHtmlParser(questionData?.questionContent?.statement,
                                    {
                                        transform: (node) => {
                                            if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                return <TeX math={`${node.attribs['data-value']}`} />;
                                            }
                                            if (node.type === 'tag' && node.name === 'img') {
                                                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                        {questionData.type === 'Subjective'
                            ?
                            <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks}</div>
                            :
                            <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div>
                        }
                    </div>

                )
            }
        }

        const AnswerContainer = ({ answerData, questionData, identifier, answerIndex }) => {

            if (answerData?.type === 'MCQ' || answerData?.type === 'CMCQ' || answerData?.type === 'CMSQ' || answerData?.type === 'MSQ' || questionData?.type === 'MCQ' || questionData?.type === 'MSQ') {
                return (
                    <div className='m-t-20' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.questionContent?.options : questionData?.questionContent?.options)?.map((opt, i) => (
                            <div keys={opt.id} className='test-ques-window r-ac m-b-10 p-10' style={{ background: opt.correct ? '#E9FCF5' : '', width: '49%' }}>
                                <div
                                    style={{ background: opt.correct ? '#28df99' : '', color: opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : '' }}
                                    className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                    {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                                </div>
                                <div
                                    // style={{ background: opt.correct ? '#E9FCF5' : '' }}
                                    className='r-c-sb flex-1'>
                                    <div className='flex-1 resize-editor-img'>
                                        <div>
                                            {ReactHtmlParser(opt.value,
                                                {
                                                    transform: (node) => {
                                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                                        }
                                                        if (node.type === 'tag' && node.name === 'img') {
                                                            return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                                        }
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='r-ac m-l-5'>
                                        {opt.correct
                                            ?
                                            <RiCheckboxCircleLine className='text-sm seaGreen' />
                                            :
                                            <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                        }
                                        <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )

            } else if (answerData?.type === 'Numeric' || answerData?.type === 'CNumeric' || questionData?.type === 'Numeric') {
                console.log('answerDataNUmeric', answerData)
                return (
                    <div className='m-t-20'>
                        <div className='text-xmd bold-600 color-black'>Answer</div>
                        <div className='test-ques-window resize-editor-img p-10'>
                            {ReactHtmlParser(((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer))}
                        </div>
                    </div>

                )

            }
            else if (answerData?.type === 'T/F' || answerData?.type === 'CTF' || questionData?.type === 'T/F') {

                return (
                    <div className='m-t-20'>
                        <div className='text-xmd bold-600 color-black m-b-5'>Options</div>
                        <div style={{ display: 'flex' }}>
                            <div className='test-ques-window r-ac m-r-10 p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#E9FCF5' : '', }}>
                                <div
                                    style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '' }}
                                    className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                    {'A'}
                                </div>
                                <div
                                    style={{ width: "90%", overflowX: "hidden" }}
                                    className='cursor-pointer p-10 r-c-sb'
                                ><div>
                                        True
                                    </div>
                                    <div className='r-ac m-l-5'>
                                        {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true
                                            ?
                                            <RiCheckboxCircleLine className='text-sm seaGreen' />
                                            :
                                            <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                        }
                                        <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                                    </div>
                                </div>
                            </div>
                            <div className='test-ques-window r-ac p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#E9FCF5' : '', }}>
                                <div
                                    style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '' }}
                                    className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                    {'B'}
                                </div>
                                <div
                                    style={{ width: "100%", overflowX: "hidden" }}
                                    className='cursor-pointer p-10 r-c-sb'
                                >
                                    <div>
                                        False
                                    </div>
                                    <div className='r-ac m-l-5'>
                                        {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false
                                            ?
                                            <RiCheckboxCircleLine className='text-sm seaGreen' />
                                            :
                                            <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                        }
                                        <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )

            } else {
                return null
            }
        }

        const SolutionContainer = ({ questionData, identifier, answerData }) => {
            return (
                <div className='m-t-20'>
                    <div className='text-xmd bold-600 color-black'>Solution</div>
                    <div className='test-ques-window resize-editor-img p-10'>
                        {ReactHtmlParser((questionData?.solution),
                            {
                                transform: (node) => {
                                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                        return <TeX math={`${node.attribs['data-value']}`} />;
                                    }
                                    if (node.type === 'tag' && node.name === 'img') {
                                        return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                    }
                                }
                            })
                        }
                    </div>
                </div>
            )

        }
        if (questionData?.type === 'Comprehensive') {
            return (
                questionData?.questionContent?.subQuestions?.map((item, index) => (
                    <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
                        <QuestionContainer questionData={questionData} indexMain={indexMain} compIndex={index} comprehensiveData={item} />
                        <AnswerContainer answerData={item} questionData={questionData} identifier={'Comp'} answerIndex={index} />
                        <SolutionContainer questionData={item} identifier={'Comp'} />
                    </div>
                ))
            )
        }
        else if (questionData?.type === 'OrQuestions') {
            return (
                questionData?.questionContent?.orQuestions?.map((item, index) => (
                    <>
                        <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
                            <QuestionContainer questionData={questionData} indexMain={indexMain} orQuestionsData={item} />
                            <AnswerContainer answerData={item} questionData={questionData} identifier={'OrQues'} answerIndex={index} />
                            <SolutionContainer questionData={item} identifier={'OrQues'} />
                        </div>
                        {index != questionData?.questionContent?.orQuestions?.length - 1 ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
                                <span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
                                <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
                            </div>
                            : null
                        }
                    </>
                ))

            )
        } else {

            return (
                <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
                    <QuestionContainer questionData={questionData} indexMain={indexMain} />
                    <AnswerContainer questionData={questionData} />
                    <SolutionContainer questionData={questionData} />
                </div>
            )
        }


    }
    const actions = [
        <Button type="" onClick={() => { history.goBack() }} style={{ marginRight: 20 }}>CANCEL</Button>,
        <Button 
        loading = {importLoader}
        type="primary" 
        onClick={() => {
            setImportLoader(true);

            if(location?.state?.testSeriesData)
                importQPaper(
                    () => setImportLoader(false),
                    () => history.push('/testSeries/createTest')
                )
            else
                getTestSampleQuestionPaperData(
                    ()=>{setImportLoader(false)},
                    ()=>{
                        createTestModal("test-edit", user, '');
                        if(location?.state?.key){
                            history.push({pathname:"/edit-test",state:{key : "fromClassroom"}})
                        }else{
                            history.push({pathname:"/edit-test",state:{key : 1}})
                        }
                    })
        }} 
            style={{}}>IMPORT</Button>
    ]
    return (
        <div style={{ background: "#FAFAFA", width: "calc(100%+50px)", height: "calc(100% + 40px)", margin: -20, padding: 20, overflow: "hidden" }}>
            <div style={{marginBottom:100}}>
            <PageHeader
                title={importMeta?.selectedPaperObj?.name}
                subtitle={`Import/${importMeta?.selectedStandardObj?.name}`}
                actions={actions}
                onBackPress={() => { history.goBack() }} />
                </div>
                <div style={{ height: '90%', overflowY:"scroll" }}>
                <div className='p-l-20 p-r-20 p-b-20' >
                    {subjectNameContainer?.length && (
                        <Tabs activeKey={`${subjectIdContainer}`} onChange={(e) => {
                            storeSubjectId(e)
                            callback();
                        }} style={{ display: "flex" }}>
                            {subjectNameContainer?.map((item, index) => (
                                <TabPane tab={item?.name} key={item?.id} style={{ backgroundColor: '#F6F4FE' }}>
                                    {listViewDataContainer?.items?.length ?
                                        listViewDataContainer?.items?.map((item, index) => {
                                            return(
                                              <>
                                              {listViewDataContainer?.sections?.map((section, secIndex)=>{
                                                return(
                                                    <>
                                                    {index + 1 == section?.firstQuestionNumber && 
                                                    <>
                                                    <div className='r-c-c-c' style={{ fontSize:16, fontWeight:"bold"}}>{section?.name}</div>
                                                    </>
                                                     }
                                                    </>
                                                )
                                              })}
                                                <QuesAnsView questionData={item} indexMain={index} />
                                              </>  
                                            )
                                        }
                                        )
                                        :
                                        <NodataCard />
                                        }
                                </TabPane>
                            ))}
                        </Tabs>
                    )}
                </div>
             {(listViewDataContainer?.meta?.currentPage !== listViewDataContainer?.meta?.totalPages) && listViewDataContainer?.meta?.totalItems !== 0 ?
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                         <Button style={{
                             color: "#1089FF",
                             fontWeight: "bold",
                             justifyContent: "center",
                             display: "flex",
                             cursor: "pointer",
                             border: "none",
                             marginBottom:40
                         }}
                             onClick={() => {
                                 setPage(page += 1)
                                 storePageNumber(page);
                                 togglePageLoader(true);
                                 getListViewData(()=>togglePageLoader(false));
                             }} >LOAD MORE</Button>
                     </div>
                     :
                     <></>
                 }
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
      importMeta,
      subjectNameContainer,
      subjectIdContainer,
      listViewDataContainer
    } = state.testAdmin;
    const {user} = state.session;
    return {  
      importMeta,
      user,
      subjectNameContainer,
      subjectIdContainer,
      listViewDataContainer
    };
  }
  
  const mapDispatchToProps = (dispatch) =>  ({
    getTestSampleQuestionPaperData: (callback, successCallBack) => dispatch(getTestSampleQuestionPaperData(callback, successCallBack)),
    createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
    storePageNumber: (val) => dispatch(storePageNumber(val)),
    getListViewData: (callBack) => dispatch(getListViewData(callBack)),
    storeSubjectId: (val) => dispatch(storeSubjectId(val)),
    storeListViewData: (val) => dispatch(storeListViewData(val)),
  fetchQuestionPaletteData: (callback) => dispatch(fetchQuestionPaletteData(callback)),
    importQPaper: (loaderCallback, successCallBack) => dispatch(importQPaper(loaderCallback, successCallBack)),


    //  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
    // fetchClassByCurrData: (callback) => dispatch(fetchClassByCurrData(callback))
  })
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(SampleImportListView);

// export default SampleImportListView