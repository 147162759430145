import React, { useEffect, useState } from 'react'
import TopBar from './topBar'
import Footer from './footer'
import "./testSeries.css";
import "./styles.css";
import { connect } from 'react-redux';
import { Button, Collapse, Input, Modal, Progress, Rate } from 'antd';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"
import starOutline from "../../Assets/aulasMarket/ic-star-outLine.svg"
import startFilled from "../../Assets/aulasMarket/ic-star-fill.svg"
import { AttemptQuiz, QuizResults, SubmitQuiz, ViewSolutions, setLoader, setQuizData, setRenderTest, setTestSeriesData } from 'actions/aulasMarket';
import LockIcon from "../../Assets/aulasMarket/icons8-lock.svg";
import { Api } from 'services';
import questionMarkIcon from "../../Assets/aulasMarket/questionMark.svg"
import correctIcon from "../../Assets/aulasMarket/correctIcon.svg"
import wrongIcon from "../../Assets/aulasMarket/wrongIcon.svg"
import unattemptedIcon from "../../Assets/aulasMarket/unattemptedIcon.svg"
import closeWrong from "../../Assets/aulasMarket/CloseCircle.svg"
import attempted from "../../Assets/aulasMarket/ic-attempted.svg"
import HtmlParser from 'react-html-parser';

import Viewpdf from 'pages/digitalLibrary/pages/Viewpdf';
import { uploadContent } from 'actions/digitalLibrary';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';

const { Panel } = Collapse;
const { TextArea } = Input;


export const QuizField = ({ testSeriesData, quizId, setLoader, attempetQuiz, quizState, quizData, setQuizData, renderTest, setRenderTest, }) => {

    console.log("testmateril", testSeriesData?.studyMaterial?.name, quizData)
    const [results, setResults] = useState();
    const [quiz, setQuiz] = useState(quizState);
    const [submit, setSubmit] = useState(false);
    const [viewSolution, setViewSolution] = useState(false);
    const [quizAttempt, setQuizAttempt] = useState(attempetQuiz);
    const [param, setParam] = useState({
        id: quizId,
        itemId: testSeriesData?.studyMaterial?.id,
        type: "studyMaterial",
    });

    const destruct = quizData?.questions;
    console.log("paramssssss", quizData);

    useEffect(() => {
    }, [])

    useEffect(() => {
        setLoader(true);
        if (quiz && !quizAttempt) {
            AttemptQuiz(param, (res) => {
                if (res) {
                    setQuizData(res);
                    // console.log("qaqaqaqa", res);
                }
            }, setLoader)
        } else if (quizAttempt) {
            QuizResults(param, (res) => {
                if (res) {
                    setQuizData(res);
                    console.log("qaqaqaqa", res);
                    setResults(res?.result)
                }
            }, setLoader)
        }
    }, [quiz])

    const handleUpdateParam = (newId, newItemId, newType) => {
        // Update the param state with the new values
        setParam({
            id: newId,
            itemId: newItemId,
            type: newType,
        });
    };



    const QuizDesign = ({ data }) => {
        const [selectedOption, setSelectedOption] = useState(null);
        const [step, setStep] = useState(0);
        const [selected, setSelected] = useState(false);
        const [currentIndex, setCurrentIndex] = useState(0);
        const [params, setParams] = useState(data?.map(() => ({ id: null, attempt: { answer: null } })));// Initialize with an array of objects


        const handleSubmit = (id) => {
            console.log("qqqaaid", id)
            setLoader(true);

            SubmitQuiz(params, param, (res) => {
                if (res) {
                    setResults(res?.result)
                    setQuiz(false);
                    setSubmit(true)
                }
            }, setLoader);
        }

        const handleNext = () => {
            if (step < data?.length - 1) {
                setStep(step + 1);
                setSelected(false)
                setCurrentIndex((prevIndex) => (prevIndex + 1 < data.length ? prevIndex + 1 : prevIndex));
            }
        };

        const handlePrevious = () => {
            if (step > 0) {
                setStep(step - 1);
                setCurrentIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
            }
        };

        const handleOptionClick = (index, selectedOption, newId, dataList) => {
            setSelectedOption(index);
            setSelected(true);
            // setSelectedOption((prevSelectedOption) => {
            //     if (prevSelectedOption.includes(index)) {
            //       // If the option is already selected, remove it
            //       return prevSelectedOption.filter((item) => item !== index);
            //     } else {
            //       // If the option is not selected, add it
            //       return [...prevSelectedOption, index];
            //     }
            //   });

            // Create a copy of the params array and update the selected answer for the current object
            setParams((prevParams) =>
                prevParams.map((param, paramIndex) => {
                    if (paramIndex === currentIndex) {
                        if (dataList?.type === 'MCQ') {
                            return { ...param, id: newId, attempt: { answer: selectedOption } };
                        } else if (dataList?.type === 'MSQ') {
                            // Initialize an array if not already set
                            const selectedAnswers = param.attempt.answer || [];
                            return {
                                ...param,
                                id: newId,
                                attempt: { answer: selectedAnswers.concat(selectedOption) }
                            };
                        } else if (dataList?.type === 'T/F') {
                            return { ...param, id: newId, attempt: { answer: selectedOption === 0 ? 0 : 1 } };
                        }
                    }
                    return param;
                })
            );
        };

        console.log("clickedparams", params);

        // Assuming you fetch or set noQs data from an API or other source

        const Questions = ({ dataList, index, selectedOption, onOptionClick }) => {
            console.log("lslslsls", dataList?.type)

            return (
                <div className='font-bold'>
                    <div className='r-c-fe m-r-15 font-bold' style={{ color: '#594099' }}>
                        {dataList?.positiveMarks}Marks
                    </div>
                    <div className=''>{[index + 1]}  {dataList?.questionContent?.statement}</div>
                    {dataList?.questionContent?.options?.map((option, optionIndex) => {
                        return (
                            <div
                                key={optionIndex}
                                className={`r-c-fs p-15 cursor-pointer option m-l-20 ${selectedOption === optionIndex ? 'selected' : ''}`}
                                onClick={() => onOptionClick(index, optionIndex, dataList?.id, dataList)}
                            >
                                {option?.value}
                            </div>
                        );
                    })}
                    {(!dataList?.questionContent?.options || dataList?.questionContent?.options.length === 0) &&
                        dataList?.type === 'T/F' && (
                            <div>
                                <div
                                    className={`r-c-fs p-15 cursor-pointer option m-l-20 ${selectedOption === 0 ? 'selected' : ''
                                        }`}
                                    onClick={() => onOptionClick(1, 0, dataList?.id, dataList)}
                                >
                                    True
                                </div>
                                <div
                                    className={`r-c-fs p-15 cursor-pointer option m-l-20 ${selectedOption === 1 ? 'selected' : ''
                                        }`}
                                    onClick={() => onOptionClick(1, 1, dataList?.id, dataList)}
                                >
                                    False
                                </div>

                            </div>
                        )}
                </div>
            )
        }

        return (
            <div className='' style={{ width: "100%", height: "70vh" }}>
                <div style={{ margin: '10px 10px' }}>
                    <div className='font-bold text-sm'>Quiz 1</div>
                    <div className='r-c-sa'>
                        {data?.map((item, index) => (
                            <div key={index} style={{ width: '100%', margin: '10px 0px' }}>
                                <div className='steps' style={{ backgroundColor: step >= index ? '#594099' : '#E6E6E6' }}>{/* Add your content for each step here */}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        {data?.length > 0 && (
                            <Questions dataList={data[currentIndex]} index={currentIndex} selectedOption={params[currentIndex].attempt.answer}
                                onOptionClick={handleOptionClick} />
                        )}
                    </div>
                </div>
                <div className='r-c-sb' style={{ borderTop: "1px solid #E6E6E6", }}>
                    <div style={{ marginTop: "15px", marginLeft: "20px" }} onClick={handlePrevious}><Button className='btns-prev '>Previous</Button></div>

                    {step < data?.length - 1 ? <>
                        <div style={{ marginTop: "15px", marginRight: "20px" }}>
                            {selected ?
                                <Button className='btns-save' onClick={handleNext}>SAVE & NEXT</Button>
                                :
                                <Button className='btns-save' onClick={handleNext}>NEXT</Button>
                            }
                        </div>
                    </> : <>
                        <div className='r-c-sb' style={{ marginTop: "15px" }}>
                            <div><Button className='btns-save m-r-10'>Save</Button></div>
                            <div><Button className='btns-save m-r-20' onClick={() => { handleSubmit(data[currentIndex]?.id); handleUpdateParam(data[currentIndex]?.id, data[currentIndex]?.quizId, "studyMaterial") }}>Submit</Button></div>
                        </div>
                    </>}

                </div>
            </div>
        );
    }

    const Results = ({ }) => {
        const handleReAttempt = () => {
            setSubmit(false);
            setQuiz(true);
            setQuizAttempt(null);
        };
        console.log("resulssss", results);
        const handleViewSolution = (id, itemId, type) => {
            console.log("valueeeeeeee", id, itemId, type);
            setSubmit(false);
            setViewSolution(true);
            setQuizAttempt(null);
            ViewSolutions(param, (res) => { setQuizData(res); });
        }
        const totalQuestions = results?.totalQuestions || 0;
        const correctQuestions = results?.correctQuestions || 0;

        return (
            <>
                <div className='' style={{ width: "100%", height: "70vh" }}>
                    <div style={{ margin: '10px 10px' }}>
                        <div className='font-bold text-sm'>RESULTS</div>
                    </div>

                    <div className='r-c-c m-t-20 '>
                        <Progress
                            strokeColor="#594099"
                            type="circle"
                            percent={(correctQuestions / totalQuestions) * 100} // Calculate the percentage
                            format={(percent) => (
                                <div className='r-c-c-c font-bold text-sm'>
                                    <div>{correctQuestions}
                                        <div style={{ border: "1px solid black", width: "40px" }}></div>
                                        <div>
                                            {totalQuestions}
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div>
                        <div className='r-c-sb text-xmd font-bold' style={{ margin: "20px 20px" }}>
                            <div className='r-c-sb' style={{ width: "30%" }}>
                                <div>
                                    <img src={questionMarkIcon} />
                                    <span className='m-l-10'>Total questions</span>
                                </div>
                                <span>{results?.totalQuestions}</span>
                            </div>
                            <div className='r-c-sb' style={{ width: "30%" }}>
                                <div>
                                    <img src={wrongIcon} />
                                    <span className='m-l-10'>Wrong answers</span>
                                </div>
                                <span>{results?.incorrectQuestions}</span>
                            </div>
                        </div>
                        <div className='r-c-sb text-xmd font-bold' style={{ margin: "20px 20px" }}>
                            <div className='r-c-sb' style={{ width: "30%" }}>
                                <div>
                                    <img src={correctIcon} />
                                    <span className='m-l-10'>Correct answers</span>
                                </div>
                                <span>{results?.correctQuestions}</span>
                            </div>
                            <div className='r-c-sb' style={{ width: "30%" }}>
                                <div>
                                    <img src={unattemptedIcon} />
                                    <span className='m-l-10'>Skipped questions</span>
                                </div>
                                <span>{results?.skippedQuestions}</span>
                            </div>
                        </div>

                        <div className='r-c-fe' style={{ borderTop: "1px solid #E6E6E6", marginTop: "70px" }}>
                            <div style={{ marginTop: "8px", marginRight: "20px" }} onClick={handleReAttempt}><Button className='btns-prev '>RE-ATTEMPT</Button></div>
                            <div className='r-c-sb' style={{ marginTop: "8px", width: "140px" }}>
                                <div><Button className='btns-save' onClick={handleViewSolution}>VIEW SOLUTION</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ViewSolutionDesign = ({ solution }) => {
        console.log("qzzzzznnnn", solution);
        const [selectedOption, setSelectedOption] = useState(null);
        const [step, setStep] = useState(0);
        const [selected, setSelected] = useState(false);
        const [currentIndex, setCurrentIndex] = useState(0);

        const handleNext = () => {
            if (step < solution?.length - 1) {
                setStep(step + 1);
                setSelected(false)
                setCurrentIndex((prevIndex) => (prevIndex + 1 < solution.length ? prevIndex + 1 : prevIndex));
            }
        };

        const handlePrevious = () => {
            if (step > 0) {
                setStep(step - 1);
                setCurrentIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
            }
        };


        // Assuming you fetch or set noQs solution from an API or other source

        const Questions = ({ dataList, index, selectedOption, onOptionClick }) => {
            console.log("lslslsls", dataList)

            let correctAns = dataList?.question?.answerContent?.answer;
            let selectedAns = dataList?.attempt?.answer;

            return (
                <div className='font-bold'>
                    <div className='r-c-fe m-r-15 font-bold' style={{ color: '#594099' }}>
                        {dataList?.question?.positiveMarks}
                    </div>
                    <div className=''>{[index + 1]}  {dataList?.question?.questionContent?.statement}</div>
                    {dataList?.question?.type === 'T/F' ? ( // Check if it's a True/False question
                        <div>
                            <div
                                className={`r-c-fs p-15 option m-l-20 ${selectedAns === 1 ? 'selectedOp' : ''}`}
                            >
                                True
                                {correctAns === 1 ? (
                                    <div style={{ color: "#636363" }}>
                                        <img src={attempted} /> Correct answer
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className={`r-c-fs p-15 option m-l-20 ${selectedAns === 0 ? 'selectedOp' : ''}`}
                            >
                                False
                                {correctAns === 0 ? (
                                    <div style={{ color: "#636363" }}>
                                        <img src={closeWrong} /> Correct answer
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        // For other question types
                        dataList?.question?.questionContent?.options?.map((option, optionIndex) => {
                            const isCorrectAnswer = Array.isArray(correctAns)
                                ? correctAns.includes(optionIndex)
                                : correctAns === optionIndex;

                            const isSelectedAnswer = Array.isArray(selectedAns)
                                ? selectedAns.includes(optionIndex)
                                : selectedAns === optionIndex;

                            const optionClassName = `r-c-fs p-15 option m-l-20 ${isSelectedAnswer && isCorrectAnswer ? 'selectedOp' : isSelectedAnswer ? 'wrongOp' : ''
                                }`;

                            const correctAnswerIcon = isCorrectAnswer && !isSelectedAnswer ? (
                                <div style={{ color: "#636363" }}>
                                    <img src={attempted} /> Correct answer
                                </div>
                            ) : null;

                            const yourAnswerIcon = isSelectedAnswer ? (
                                <div style={{ color: "#636363" }}>
                                    {isCorrectAnswer ? (
                                        <>
                                            <img src={attempted} /> Correct answer
                                        </>
                                    ) : (
                                        <>
                                            <img src={closeWrong} /> Your answer
                                        </>
                                    )}
                                </div>
                            ) : null;
                            return (
                                <div key={optionIndex} className={optionClassName}>
                                    {option?.value}
                                    {correctAnswerIcon}
                                    {yourAnswerIcon}
                                </div>
                            );
                        })
                    )}

                </div>
            )
        }

        return (
            <div className='' style={{ width: "100%", height: "70vh" }}>
                <div style={{ margin: '10px 10px' }}>
                    <div className='font-bold text-sm'>Solutions 1</div>
                    <div className='r-c-sa'>


                    </div>
                    <div>
                        {solution?.length > 0 && (
                            <Questions dataList={solution[currentIndex]} index={currentIndex} />
                        )}
                    </div>
                </div>

                <div className='r-c-sb' style={{ borderTop: "1px solid #E6E6E6", }}>
                    <div style={{ marginTop: "15px", marginLeft: "20px" }} onClick={handlePrevious}><Button className='btns-prev '>Previous</Button></div>

                    {step < solution?.length - 1 ? <>
                        <div style={{ marginTop: "15px", marginRight: "20px" }}>

                            <Button className='btns-save' onClick={handleNext}>NEXT</Button>

                        </div>
                    </> : <div style={{ marginTop: "15px", marginRight: "20px" }}>
                        <Button className='btns-save' disabled >NEXT</Button>
                    </div>}

                </div>
            </div>
        );
    }

    return (
        <>
            <div className='r-c-sb font-bold text-sm'>
                <div>{testSeriesData?.studyMaterial?.name}</div>
                {/* <div>20% Completed</div> */}
            </div>
            <div className='r-c-c video-parent' style={{}}>

                <div className="" style={{ width: "100%" }}>
                    {quiz ? (
                        submit || quizAttempt ? (
                            <Results />
                        ) : viewSolution ? (
                            <ViewSolutionDesign solution={quizData} />
                        ) : (
                            <QuizDesign data={destruct} />
                        )
                    ) : (
                        submit || quizAttempt ? (
                            <Results />
                        ) : (
                            viewSolution ? (
                                <div>
                                    {console.log("eeeeedviewd")}
                                    <ViewSolutionDesign solution={quizData} />
                                </div>
                            ) : (
                                null
                            )
                        )
                    )}
                </div>
            </div>
        </>
    )
}

const PdfView = ({ file, setRenderTest }) => {
    console.log("fileurl", file)
    return (
        <>
            <div className=''>
                <div className=''>
                    <div className='text-sm font-bold' onClick={() => setRenderTest(0)}  >< ArrowLeftOutlined /><span className='m-l-10 m-t-5'>{file?.name}</span></div>
                </div>
                <iframe
                    width="100%"
                    style={{ height: "100vh" }}
                    // height="100%"
                    src={`${Api.dlS3Url}${file?.url}#toolbar=0`}
                    frameborder="0"
                ></iframe>


            </div>
        </>
    )
}


function StudyMaterial({ testSeriesData, quizData, setLoader, setQuizData, setTestSeriesData, study, renderTest, setRenderTest }) {
    const history = useHistory();
    const [studyData, setStudyData] = useState(testSeriesData?.studyMaterial)
    console.log("testdataaaaaaaa", studyData)
    const [quizState, setQuizState] = useState(false)
    const [attempetQuiz, setAttempetQuiz] = useState(false);
    const [quizId, setQuizId] = useState();
    const [pdfUrl, setPDFUrl] = useState(null);

    console.log("idquiz", quizId);

    useEffect(() => {
        setLoader(true)
        setRenderTest(0);
        setLoader(false);
    }, []);

    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [addRating, setAddRating] = useState(false);
    const [save, setSave] = useState(false);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);



    const handleRatingOk = () => {
        setAddRating(false);
    };
    const handleRatingCancel = () => {
        setAddRating(false);
    };


    const courseRating = [
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
        {
            title: "courseName1",
            days: "10days ago",
            ratings: 4,
            description: "I really love how innovative and engaging I’ve felt the content has been so far. It really is like no other education I’ve had felt it before. I really love how innovative and engaging I’ve felt the content has been so far. I really love how innovative and engaging I’ve felt the content has been so far."
        },
    ]




    // console.log("tteesstt", testSeriesData)
    const [showAll, setShowAll] = useState(false);
    const [viewAll, setViewAll] = useState(false);
    const [showRatings, setShowRatings] = useState(false);
    const itemsToShow = viewAll ? testSeriesData?.topics?.length : 10;
    const itemsToShowCard = showAll ? testSeriesData?.related?.length : 8;
    const itemsToShowCourse = showRatings ? courseRating?.length : 6;



    const StarRatingNew = () => {
        const [rating, setRating] = useState(0);

        const handleStarClick = (clickedRating) => {
            setRating(clickedRating);
        };

        return (
            <div className='star-rating-new-parent' style={{}}>
                <div className="star-rating-new" style={{}}>
                    {[1, 2, 3, 4, 5].map((star) => (
                        <span
                            key={star}
                            onClick={() => handleStarClick(star)}
                            className=''
                        >
                            {star <= rating ? <img src={startFilled} /> : <img src={starOutline} />}

                        </span>
                    ))}
                </div>
            </div>
        );
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const toggleViewAll = () => {
        setViewAll(!viewAll);
    };
    const toggleRatting = () => {
        setShowRatings(!showRatings);
    };


    const Resources = ({ data, index }) => {
        console.log("pdfnnnnn", data?.attempted);
        setQuizId(data?.quizId)
        if (data?.attempted) {
            setAttempetQuiz(data?.attempted)
        }
        function openPdfInNewTab(pdfUrl) {
            window.open(pdfUrl, '_blank');
        }

        return (
            <>
                <div className='resources-desgin'>
                    <div className='r-afs' style={{ alignContent: "center" }}>
                        <div className='m-l-30 font-bold' style={{}}>
                            {index + 1}
                        </div>
                        <div className='m-l-20 font-bold' style={{ cursor: 'pointer' }} onClick={() => {
                            if (data?.quiz) {
                                setRenderTest(1);
                                setQuizState(true);
                            } else {
                                // openPdfInNewTab(data?.fileUrl);
                                // <PdfView file={data?.file} />
                                setRenderTest(2);
                                setPDFUrl(data?.file)
                            }
                        }
                        }
                        >
                            {data?.name}
                            <img className='m-l-10' width={15} src={data?.preview === 0 ? LockIcon : null} style={{}} />
                        </div>

                    </div>
                    <div className='r-c-c m-r-30' style={{ color: "#AEAEAE" }}>
                        {/* {data?.pageCount} */}
                        {data?.pages}
                    </div>
                </div >
            </>
        )
    }

    const StudySeries = ({ testData, id, flag }) => {
        console.log("itttt", testData)
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1 cursor-pointer' style={{}} onClick={() => history.push("/popular-courses")}>
                    {/* <Link to="/study-materials"> */}
                    <div className='card-design' style={{}}>
                        {testData?.amount ? (
                            <div className=''></div>
                        ) :
                            <div className='free-card font-bold r-c-c ' style={{}}>FREE</div>}
                        <div className='card-content-free' style={{}}>

                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../Assets/aulasMarket/onlineTestIcon.svg").default} />
                            </div>

                            <div className='m-l-10'>
                                {testData?.name}
                            </div>

                        </div>
                        {!testData?.tokenFree ? (
                            <div className='cost-card' style={{}}>₹{testData?.amount}</div>
                        ) : <div className=''></div>
                        }

                    </div>
                    {/* </Link> */}
                </div>

                <div className='popular-subTitle'>
                    <div className='popular-description' style={{}}>{testData?.brief}</div>

                    <div className='r-c-fs'>
                        <div className=' ratings font-bold' style={{}}>{testData?.rating}</div>
                        <div style={{ margin: "-8px 5px 0px 5px", width: "100%" }}><Rate disabled defaultValue={testData?.ratingValue} style={{ color: "#FFA931", width: "145px" }} /></div>
                        <div className='totalRatings' style={{}}>({testData?.ratingCount})</div>
                    </div>
                </div>
            </div>
        )
    }

    const CourseRating = ({ course }) => {
        console.log("reeee", course)
        const [time, setTime] = useState();

        const formatTimeAgo = (timestamp) => {
            const currentDate = new Date();
            const targetDate = new Date(timestamp);
            const seconds = Math.floor((currentDate - targetDate) / 1000);

            if (seconds < 60) {
                return seconds + ' seconds ago';
            }

            const minutes = Math.floor(seconds / 60);
            if (minutes < 60) {
                return minutes + ' minutes ago';
            }

            const hours = Math.floor(minutes / 60);
            if (hours < 24) {
                return hours + ' hours ago';
            }

            const days = Math.floor(hours / 24);
            if (days < 30) {
                return days + ' days ago';
            }

            const months = Math.floor(days / 30);
            if (months < 12) {
                return months + ' months ago';
            }

            const years = Math.floor(months / 12);
            return years + ' years ago';

        }

        useEffect(() => {
            const formattedTime = formatTimeAgo(course?.createdAt);
            setTime(formattedTime);
        }, [course?.createdAt])

        console.log("naeeeeee", testData?.topics);

        return (
            <div className='courseRating'>
                <div className='r-c-fs m-t-20'>
                    <div>
                        <img style={{ width: 50 }} src={`${Api._s3_url}${course?.image}`} />
                    </div>
                    <div className='m-l-20' >
                        <div className='text-sm font-bold'>{course?.name}</div>
                        <div className='r-c-fs' >
                            <div className='r-c-sb'><Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931" }} />
                                <div className='m-l-10 text-xmd' style={{ color: "#AEAEAE" }}>{time}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-xmd bold-600' style={{ width: "350px" }}>{course?.ratingExplanation}</div>
            </div>
        )
    }

    const Learnings = ({ item }) => {
        console.log("learn", item?.name)
        return (
            <>
                <div className='content1-list' style={{ columns: "2" }}>
                    <ul className='custom-list' style={{ color: "#636363", display: "flex", flexWrap: "wrap" }}>
                        <li style={{ flex: "50%", marginBottom: "10px" }}>
                            <span style={{ color: "" }}>{item?.name}</span>
                        </li>
                    </ul>

                    {/* <ul className='custom-list m-l-80'>
                                        <li><span style={{ color: "#636363" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                        <li><span style={{ color: "#636363" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                        <li><span style={{ color: "#636363" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                    </ul> */}
                </div>
            </>
        )
    }
    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }

    return (
        <>
            {renderTest === 0 ? (
                <>

                    {study ? null :
                        <div>
                            <TopBar />
                        </div>
                    }
                    <div className={study ? "test-grand-parentMin" : 'test-grand-parent'} style={{}}>
                        <div className='test-parent'>
                            <div className={study ? null : "test-series-container"} style={{}}>

                                {study ?
                                    <div className='r-c-sb'>
                                        <div style={{}}>

                                            <span className='test-title' >
                                                {studyData?.name}
                                            </span>

                                            <div style={{ fontSize: "18px", fontWeight: "bold" }}>{studyData?.brief}</div>
                                            <div className='r-c-fs'>
                                                <div><Rate disabled value={studyData?.ratingValue} /> {studyData?.ratingCount}</div>
                                                <div style={{ fontSize: "14px", fontWeight: "bold", color: "#1089FF" }} onClick={() => setAddRating(true)}>Rate this</div>
                                            </div>
                                        </div>
                                        <div className='test-progress' style={{}}>
                                            {/* 20% Completed */}
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className='test-title' style={{}}>
                                            {studyData?.name}

                                        </div>
                                        <div className='test-des' style={{}}>
                                            {studyData?.brief}
                                        </div>
                                    </>

                                }
                                {study ? null :
                                    <>
                                        <div className='test-options'>
                                            <div className='opt-1'>
                                                <div className='sellerButton'>Bestseller</div>
                                                <div className='test-ratings'><div className='ratings' style={{ color: "#FFA931" }}>{studyData?.rating}</div><Rate disabled defaultValue={studyData?.rating} style={{ color: "#FFA931" }} /> <div className='test-total-ratings'>({studyData?.ratingCount})</div></div>
                                            </div>
                                            <div className='opt-2'>
                                                <div className='add-ratings' onClick={() => setAddRating(true)}>Add a rating</div>
                                                <div className='test-share' onClick={() => {
                                                    setShareModalVisible(true);
                                                    setShareUrl(`${Api?._base_url}/course-page?id=${studyData?.id}&type=${"studyMaterial"}`)
                                                }}><img src={require("../../Assets/aulasMarket/shareIcon.svg").default} />Share</div>
                                                <div className='test-save' onClick={() => setSave(!save)} ><img src={save ? saveFilled : saveOutlined} />Save for later</div>
                                            </div>
                                        </div>
                                        <div className='test-cost'>₹{studyData?.amount}</div>
                                        <div className='test-btns-parent' style={{}} >
                                            <Button className='content-add' onClick={() => history.push("/shopping-cart")} style={{}}>ADD TO CART</Button>
                                            <Button className=' content-reg' onClick={() => history.push("/checkout")} style={{}}>BUY NOW</Button>
                                        </div>
                                        <div className='test-description' >Description</div>

                                        <div className='test-des-text'>
                                            {!IsJsonString(JSON.parse(studyData?.description)?.mobile) && isHTML(JSON.parse(studyData?.description)?.mobile) ? HtmlParser(JSON.parse(studyData?.description)?.mobile) : HtmlParser(JSON.parse(studyData?.description)?.mobile)}

                                            {/* {studyData?.description} */}
                                        </div>
                                        {/* <div className='test-des-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ips<br />
                            <br />
                            has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div> */}
                                        <div className='test-what-you-learn'>
                                            <div className='header-card'>What you'll learn</div>
                                            {studyData?.learnings?.map((i) => (
                                                <>
                                                    {console.log("learnings:", i)}
                                                    <Learnings item={i} />
                                                </>
                                            ))}
                                        </div>
                                    </>
                                }


                                <div className='syllabus-header' >Resources</div>
                                <div className='m-t-20'>
                                    {studyData?.topics?.slice(0, itemsToShow).map((item, index) => {
                                        return (
                                            <Resources data={item} index={index} />
                                        )
                                    })}
                                    {studyData?.topics?.length > 10 && (
                                        <div className='test-showAll' onClick={toggleViewAll}>
                                            {viewAll ? 'View Less' : 'View More'}
                                        </div>
                                    )}
                                </div>
                                {/* <div className='m-t-20'>
                            {studyData?.topics?.slice(0, itemsToShow).map((item, id) => {
                                console.log("pddddf", item)
                                return (
                                    <Resources data={item} key={id} />
                                )
                            })}
                            {studyData?.topics?.length > 10 && (
                                <div className='test-showAll' onClick={toggleViewAll}>
                                    {viewAll ? 'View Less' : 'View More'}
                                </div>
                            )}
                        </div> */}


                                {study ?
                                    <>
                                        <div className='test-what-you-learn'>
                                            <div className='header-card'>What you'll learn</div>
                                            {study?.learnings?.map((i) => (
                                                <>
                                                    {console.log("learnings:", i)}
                                                    <Learnings item={i} />
                                                </>
                                            ))}
                                        </div>
                                    </>
                                    : null}

                                <div className='related-series' style={{ color: "#191919" }}> Related Study Materials (542)<img style={{ marginTop: "-2px", marginLeft: "10px" }} src={require("../../Assets/aulasMarket/infoIcon.svg").default} /></div>

                                <div className="related-series-parent" style={{ color: "#191919" }}>

                                    {testSeriesData?.related?.slice(0, itemsToShowCard).map((item, index) => (
                                        <div key={index}>
                                            <StudySeries testData={item} key={index} />
                                        </div>
                                    ))}
                                </div>
                                {testSeriesData?.related?.length > 8 && (
                                    <div className='test-showAll' onClick={toggleShowAll}>
                                        {showAll ? 'Show Less' : 'Show More'}
                                    </div>
                                )}

                                {study ? null :
                                    <>
                                        <div className='course-rating-header' style={{}}><span><img src={require("../../Assets/aulasMarket/ic-star-fill.svg").default} />4.7 course rating</span>&nbsp;&nbsp;<li>221 ratings</li> </div>
                                        <div className='course-related-container'>
                                            {studyData?.reviews?.slice(0, itemsToShowCourse).map((item, index) => (
                                                <div key={index}>
                                                    <CourseRating course={item} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className='test-showAll' style={{ color: "#636363", cursor: "pointer" }} >
                                            {studyData?.reviews?.length > 6 && (
                                                <div onClick={toggleRatting}>
                                                    {showRatings ? 'Show Less' : 'Show More'}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                        {study ? null :
                            <div className='m-t-100' style={{}}>
                                <Footer />
                            </div>
                        }
                    </div>
                    {/* share Modal  */}
                    {isShareModalVisible &&
                        <ShareCourseModal
                            isShareModalVisible={isShareModalVisible}
                            setShareModalVisible={setShareModalVisible}
                            shareUrl={shareUrl} />}
                    {/* Add rating Modal */}
                    <Modal open={addRating} className='modal-round-corner' footer={null} onOk={handleRatingOk} onCancel={handleRatingCancel} bodyStyle={{ borderRadius: "8px", height: "50%" }}>
                        <div className='r-c-c text-md font-bold' style={{}}>Add a rating</div>
                        <div><StarRatingNew /></div>
                        <div className='m-t-10'><TextArea rows={4} style={{ resize: 'none', borderRadius: "8px" }} placeholder='Add review' /></div>
                        <div className='r-c-c m-t-20'><Button className='submitBtn'>SUBMIT</Button></div>
                    </Modal>
                </>
            ) : (
                renderTest === 1 ? (<QuizField quizId={quizId} setRenderTest={setRenderTest} setLoader={setLoader} testSeriesData={testSeriesData} attempetQuiz={attempetQuiz} quizState={quizState} quizData={quizData} setQuizData={setQuizData} renderTest={renderTest} setRenderTest={setRenderTest} />) : (
                    renderTest === 2 ? (<PdfView file={pdfUrl} setRenderTest={setRenderTest} />) : null
                )
            )}
        </>
    )
}


const mapStateToProps = (state) => {
    const { testSeriesData, renderTest, quizData } = state.aulasMarket;
    console.log("pppp", state.aulasMarket);
    return {
        testSeriesData, renderTest, quizData
    }
};

const mapDispatchToProps = (dispatch) => ({
    setTestSeriesData: (val) => dispatch(setTestSeriesData(val)),
    setRenderTest: (val) => dispatch(setRenderTest(val)),
    setQuizData: (val) => dispatch(setQuizData(val)),
    setLoader: (val) => dispatch(setLoader(val)),

});

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterial);

