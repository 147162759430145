import { Api, Utils } from "../services";
import { v4 as uuidv4 } from 'uuid';
import Axios from "axios";
import { store } from "App";
import { setRecordCount } from "./schedule";

export const SET_RIGHT_VIEW = 'SET_RIGHT_VIEW'
export const SET_RIGHT_VIEW_TYPE = 'SET_RIGHT_VIEW_TYPE'
export const TOGGLE_MUTE = 'TOGGLE_MUTE'
export const SET_CAMERA_STATE = 'SET_CAMERA_STATE'
export const SET_SCREEN_STATE = 'SET_SCREEN_STATE'
export const ADD_MESSAGE_TO_GROUP = 'ADD_MESSAGE_TO_GROUP'
export const CLEAR_GROUP_CHAT = 'CLEAR_GROUP_CHAT'
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST'
export const UPDATE_USER_COUNT = 'UPDATE_USER_COUNT'
export const SET_USER_TYPE = 'SET_USER_TYPE'
export const SET_STREAM_ID = 'SET_STREAM_ID'
export const SET_CURRENT_PRIVATE_CHAT_RECIEVER = 'SET_CURRENT_PRIVATE_CHAT_RECIEVER'
export const ADD_MESSAGE_TO_PRIVATE = 'ADD_MESSAGE_TO_PRIVATE'
export const CLEAR_PRIVATE_CHAT = 'CLEAR_PRIVATE_CHAT'
export const POST_CHAT = 'POST_CHAT'
export const COPY_CHATS = 'COPY_CHATS'
export const SET_BROADCAST_LOADER = 'SET_BROADCAST_LOADER'
export const SET_RECORDINGS_DATA = 'SET_RECORDINGS_DATA'
export const SET_CURRENT_RECORDING_PATH = 'SET_CURRENT_RECORDING_PATH'
export const SET_COMPLETED_SUBJECT_NAME = 'SET_COMPLETED_SUBJECT_NAME'
export const SET_SELECTED_RECORDINGS_INDEX = 'SET_SELECTED_RECORDINGS_INDEX'
export const STORE_PATHS = 'STORE_PATHS'
export const SET_ROOMBOARD_DATA = 'SET_ROOMBOARD_DATA'
export const SET_ROOMIMAGE_DATA = 'SET_ROOMIMAGE_DATA'
export const SET_SECTION_LIST = 'SET_SECTION_LIST'
export const SET_SCHEDULED_TEACHER = 'SET_SCHEDULED_TEACHER'
export const SET_DOWNLOAD_META = 'SET_DOWNLOAD_META'
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT'
export const SET_WITHOUT_RECORDING_COUNT = 'SET_WITHOUT_RECORDING_COUNT'
export const PDF_UPLOADED = "PDF_UPLOADED";
export const FILE_KEY_UPDATE = 'FILE_KEY_UPDATE';
export const SET_HIDE_MSG_TRUE = 'SET_HIDE_MSG_TRUE'
export const SET_LOCAL_RECORDING_NAME = 'SET_LOCAL_RECORDING_NAME';



export const setUserType2 = (user) => ({
  type: SET_USER_TYPE,
  user
})
export const setUserType = () => {
  return (dispatch, getState) => {
    const { user } = getState().session;
    dispatch(setUserType2(user))
  }
}

export const setRightView = (val) => ({
  type: SET_RIGHT_VIEW,
  val
})

export const setRightViewType = (viewType) => ({
  type: SET_RIGHT_VIEW_TYPE,
  viewType
})

export const setMuteState = (bool) => ({
  type: TOGGLE_MUTE,
  bool
})

export const setCameraState = (bool) => ({
  type: SET_CAMERA_STATE,
  bool
})

export const setScreenState = (bool) => ({
  type: SET_SCREEN_STATE,
  bool
})

export const addMessageToGroup = (newMessage) => ({
  type: ADD_MESSAGE_TO_GROUP,
  newMessage
})

export const clearGroupChat = () => ({
  type: CLEAR_GROUP_CHAT
})

export const postChatImage = (params, user, callback, type) => (dispatch, getState) => {
  const { user } = getState().session;
  const { currentPrivateChatReciever } = getState().broadcast;
  const createdAt = new Date();
  let uvid = uuidv4();
  params = {
    ...params,
    message: "img",
    messageType: "Image",
    chatType: type,
    createdAt: createdAt,
    messageId: uvid
  }
  // console.log("type logged", type);
  if (type == "Private") {
    // console.log("private logged")
    params.receiverId = currentPrivateChatReciever.id;
  }
  return Api.post("/chat/upload").upload(params, (percent, response) => {
    if (percent == 100 || percent == -1) {
    }
    const user2 = {
      id: user.id,
      name: user.name,
      avatar: `${Api._s3_url}${user.image}`,
    }
    if (typeof (response) !== "undefined" && percent == 100) {
      const newImageurl = `${Api._s3_url}${response.data.message}`;
      // console.log("response", response);
      let newMessage = {
        id: uvid,
        type: "Image",
        image: newImageurl,
        createdAt: createdAt,
        user: user2
      }

      callback(newMessage);
    }
  });
};

export const updateUserList = (usersList, broadcastList) => (
  {
    type: UPDATE_USERS_LIST,
    usersList,
    broadcastList
  })

export const updateUserCount = (newCount) => ({
  type: UPDATE_USER_COUNT,
  newCount
})

export const setStreamId = (streamId) => {
  let { user } = store.getState().session;
  let orgId = user.org ? user.org.id : user.orgId;
  return ({
    type: SET_STREAM_ID,
    streamId: `${orgId}xxxx${streamId}`,
  })
}

export const setCurrentPrivateChatReciever = (newUser) => ({
  type: SET_CURRENT_PRIVATE_CHAT_RECIEVER,
  newUser
})

export const addMessageToPrivate = (reciever, newMessage) => ({
  type: ADD_MESSAGE_TO_PRIVATE,
  reciever,
  newMessage
})

export const clearPrivateChat = () => ({
  type: CLEAR_PRIVATE_CHAT
})

export const postChat = (message, chatType) => (dispatch, getState) => {
  const { streamId } = getState().broadcast;
  const payload_message = message
  const params = {
    roomName: streamId?.split('xxxx')[1],
    message: payload_message.text,
    messageType: payload_message.type,
    chatType: chatType,
    createdAt: payload_message.createdAt,
    messageId: payload_message.id
  }
  if (chatType == "Private") {
    params.receiverId = payload_message.reciever;
  }
  return Api.post(`/chat`).params(params).send(response => {
  }, true);
};

export const copyChats = (groupMessages, privateMessages, sessionuser) => ({
  type: COPY_CHATS,
  groupMessages,
  privateMessages,
  sessionuser
})

export const setBroadcastLoader = (bool) => ({
  type: SET_BROADCAST_LOADER,
  bool
})

export const setRecordingsData = (data) => ({
  type: SET_RECORDINGS_DATA,
  data
})

export const getRecordings = (streamId, callback)=> {
  return (dispatch, getState)=>{
    let {user} = getState().session;
    let orgId = user.org.id;
    Axios.get(`https://broadcast.aulas.in/${Utils.getBroadcastUrls('server')}/aulasrecording/vod/${orgId}xxxx${streamId}`)
          .then((response)=>{
            if (response!=undefined){
              if (response?.data?.message){
                dispatch(setRecordingsData(response.data.message))
              }else{
                dispatch(setRecordingsData([]))
              }
              callback();
            }
    }).catch((response)=> {console.log("failure", response)});
  }}

export const setCurrentRecordingPath = (filePath) => {
  let { user } = store.getState().session;
  let orgId = user.org.id;
  return ({
    type: SET_CURRENT_RECORDING_PATH,
    filePath,
    orgId: orgId
  })
}

export const setCompletedSubName = (name) => ({
  type: SET_COMPLETED_SUBJECT_NAME,
  name
})

export const setSelectedRecordingsIndex = (index) => ({
  type: SET_SELECTED_RECORDINGS_INDEX,
  index
})

export const storePaths = (roomName, paths) => ({
  type: STORE_PATHS,
  roomName,
  paths
})

export const triggerHeadlessRecording = (filename, successCallback=null) => {
  console.log("web recording api hit")
  return (dispatch, getState)=>{
    const {streamId} = getState().broadcast;
      Axios.get(`https://headless.aulas.in/webrecheadless?streamId=${streamId}&fileName=${filename}`).then((response)=>{
        if (successCallback){
          successCallback()
        }
      }).catch((error)=>{
        console.log("error in triggering headless web recording", error)
      })
  }
} 

export const stopHeadlessRecording = (successCallback=null) => {
  return (dispatch, getState)=>{
    const {streamId} = getState().broadcast;
    Axios.delete(`https://headless.aulas.in/webrecheadless?streamId=${streamId}`).then((response)=>{
      if (successCallback){
        successCallback()
      }
    }).catch((error)=>{
    })
  }
}

export const setRoomBoardData = (id, data) => ({
  type: SET_ROOMBOARD_DATA,
  data: data,
  id
})

export const setRoomImageData = (data) => ({
  type: SET_ROOMIMAGE_DATA,
  data: data
})

export const postCanvasImage = (params, callback) => (dispatch, getState) => {
  console.log("params is", params)
  return Api.post("/schedule-new/room-file").upload(params, (percent, response) => {
    if (percent == 100 || percent == -1) {
    }
    if (typeof(response) !== "undefined" && percent == 100) {
      console.log("resp232 is", response)
      const newImageurl = `${Api._s3_url}${response.data.fileUrl}`;
      callback({image:newImageurl});
  }
  });
};


export const getVideoResourceSections = (ids, callback) => {
  const url = `/course/video-resource-sections`;
  return (dispatch, getState) => {
    // dispatch(setleavePageLoader(true));
    let params = { ids: ids }
    return new Promise((resolve, reject) => {
      Api.get(url).params(params).send((response, error) => {
        resolve();
        response.forEach(item => {
          item.title = item.name
        });
        dispatch(setSectionList(response))
      });
    });
  };
};

export const postRecordedVideoLesson = (params, callback, successCallback) => {
  return (dispatch) => {
    const url = `/course/schedule-recorded-video-lesson`;
    console.log("594 params", params)
    return new Promise((resolve, reject) => {
      Api.post(url).params(params).send((response, error) => {
        console.log("596", error, params, response)
        if (response != undefined) {
          console.log("597xx", response)
          dispatch(setRecordCount(1))
          resolve();
          if (successCallback) {
            successCallback();
          }
        }
        if (callback) {
          callback();
        }
      });
    });
  };
};

export const setSectionList = (val) => ({
  type: SET_SECTION_LIST,
  val
})

export const mergeVideoPlaylist = (streamId, callback, successCallBack) => {
  console.log("619", streamId)
  // {
  //   params: {
  //     folderName: streamId
  //   }
  // }
  return (dispatch, getState) => {
    let url = `https://kh7s7ip9r4.execute-api.ap-south-1.amazonaws.com/default/mergeVideos?folderName=${streamId}`
    Axios.get(url)
      .then((response) => {
        if (callback) {
          callback();
        }
        if (response != undefined) {
          console.log("629", response)
          if (successCallBack) {
            successCallBack(response)
          }
        }
      }).catch((response) => {
        console.log("634", response)
        if (callback) {
          callback()
        }
      });
  }
};

export const postRecordCount = (params, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/update-record-count`;
    console.log("594 params", params)
    return new Promise((resolve, reject) => {
      Api.post(url).params(params).send((response, error) => {
        console.log("596", error, params, response)
        if (response != undefined) {
          console.log("597xx", response)
          dispatch(setRecordCount(1))
          resolve();
          if (successCallback) {
            successCallback();
          }
        }
        if (callback) {
          callback();
        }
      });
    });
  };
};
export const postRoomData = (params, callback) => (dispatch, getState) => {
  return Api.post(`/schedule-new/room-data`).upload(params, (percent, response) => {
    if (percent == 100 || percent == -1) {
    }
    if (typeof (response) !== "undefined" && percent == 100) {

    }
  });
};

export const getRoomData = (callback = false) => {
  return (dispatch, getState) => {
    const { streamId } = getState().broadcast;
    return new Promise((resolve, reject) => {
      Api.get(`/schedule-new/room-data?roomId=${streamId?.split('xxxx')[1]}&last=true`).send(response => {
        if (response != undefined) {
          if (response.roomData?.length) {
            dispatch(setRoomBoardData(streamId, JSON.parse(response.roomData[0])))
            resolve();
          } else {
            dispatch(setRoomBoardData(streamId, {
              roomdata: {
              "1": [],
              "2": [],
              "3": [],
            }}
            ))
            resolve();
          }
        } else {
          resolve();
        }
      });
    })
  }
}

export const setScheduledTeacher = (val) => ({
  type: SET_SCHEDULED_TEACHER,
  val
})

export const setDownloadMeta = (key, val) => ({
  type: SET_DOWNLOAD_META,
  key,
  val
})

export const postMessageOpenStatus = (params, callback, successCallback) => {
  return (dispatch) => {
    const url = `/chat/update-chat-read`;
    return new Promise((resolve, reject) => {
      console.log("sdfsd params", params)
      Api.patch(url).params(params).send((response, error) => {
        if (response != undefined) {
          resolve();
        }
      });
    });
  };
};

export const updateUnreadCount = (key, val, id) => ({
  type: UPDATE_UNREAD_COUNT,
  key,
  val,
  id
})

export const BroadcastRecordingEdit = (params, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/update-recording-name`;
    return new Promise((resolve, reject) => {
      Api.patch(url).params(params).send((response, error) => {
        if (response != undefined) {
          resolve();
          if (successCallback) {
            successCallback();
          }
        }
        if (callback) {
          callback();
        }
        if (response) {
          // dispatch(fetchAnnouncement());
          successCallback()
        }
        else {
          if (callback){
            callback()
          }
        }
      });
    });
  };
};

export const deleteBroadcastRecording = (roomName, callback = null, succesCallback = null) => {
  return (dispatch, getState) => {


    const url = `/schedule-new/recording?roomName=${roomName}`
    return new Promise((resolve, reject) => {
      Api.delete(url)
        .send((response, error) => {
          console.log("response", response);

          if (callback) {
            callback();
          }
          console.log("response1", response);
          if (typeof response != "undefined" && response.show.type === "success") {
            console.log("response654", succesCallback);

            if (succesCallback) {
              console.log("response488", response);

              succesCallback()
            }
          }
        })
    })
  }
}

export const setRecordingWithoutCount = (res) => ({
  type: SET_WITHOUT_RECORDING_COUNT,
  res
})

export const setHideMsgTrue = (res) => ({
  type: SET_HIDE_MSG_TRUE,
  res
})

export const fetchBroadcastWithoutRecordingCOunt = (callBack, /* storeTeacher */) => {
  return (dispatch, getState) => {
    const { storeSubjectId, selectedStd, storeDateFilter } = getState().digitalLibrary
    console.log("AA-storeDateFilter", storeSubjectId, selectedStd, storeDateFilter);
    const params = {
      subjectIds: [storeSubjectId],
      // teacherIds: storeTeacher,
      classIds: [selectedStd?.std_id ? selectedStd?.std_id : selectedStd],
      date: storeDateFilter == 'Invalid date' ? '' : storeDateFilter,
      withoutRecording: true,
      sort: 'date'
    };
    const url = `/schedule-new/get-occurances`;
    return Api.get(url).params(params).send((response, error) => {
      if (callBack) {
        callBack();
      }
      if (response != undefined) {
        dispatch(setRecordingWithoutCount(response));
      }
    });
  };
};

export const updatepdf = (data) => {
  return (dispatch, getState) => {
    dispatch(updatepdfaction(data));
  };
};

const updatepdfaction = (data) => ({
  type: PDF_UPLOADED,
  data,
});

export const fileKeyUpdate = (data) => ({
  type: FILE_KEY_UPDATE,
  data,
});

export const uploadBroadcastBlock = (params, callback) => {
  const blockUploadUrl = `/schedule-new/UploadChunk`;
  console.log("params12", params);
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      Api.post(blockUploadUrl).upload(params, (percent, response) => {
        if (percent === -1) {
        }
        if (response !== undefined) {
          if (percent === 100) {
            callback(response);
            resolve();
          }
        }
      });
    });
  };
};

export const BroadcastMergeChunk =
  (fileId, callback = null) => (dispatch, getState) => {
    const params = {
      fileName: fileId,
    };
    console.log("params123", params);

    return Api.post(`/schedule-new/MergeChunks`)
      .params(params)
      .send((response) => {
        if (response != undefined) {
          if (response?.fileKey) {
            if (callback) {
              callback(response?.fileKey);
            }
          }
        }
      });
  };

export const postBroadcastVideoUpload = (params, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/update-record-count`;
    console.log("576 params", params)
    return new Promise((resolve, reject) => {
      Api.post(url).params(params).send((response, error) => {
        if (response != undefined) {
          resolve();
          if (successCallback) {
            successCallback();
          }
        }
        if (callback) {
          callback();
        }
      });
    });
  };
};

export const postEmbedLink = (params, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/update-occurance-url`;
    return new Promise((resolve, reject) => {
      Api.patch(url).params(params).send((response, error) => {
        if (response != undefined) {
          resolve();
          if (successCallback) {
            successCallback();
          }
        }
        if (callback) {
          callback();
        }
      });
    });
  };
};

export const publishBroadcastRecordings = (id,isPublish, callback , successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/update-occurance-published`;
    return new Promise((resolve, reject) => {
      console.log("sdfsd params", id,isPublish,)
      const params ={
        id: id,
        published: isPublish
      }
      Api.patch(url).params(params).send((response, error) => {
        if (response != undefined) {
          resolve();
          if (successCallback) {
            successCallback();
          }
        }
        if (callback) {
          callback();
        }
      });
    });
  };
};

export const setLocalRecordingName = (key, val) => ({
  type: SET_LOCAL_RECORDING_NAME,
  key,
  val
})

export const recordConsumption = () => {
  return new Promise(async (resolve, reject)=>{
    const consumptionData = consumptionTracker.getDurationData();
    const consumedVideoIds = Object.keys(consumptionData);
    if (consumedVideoIds.length===0){
      resolve()
      return
    }
    const videoReceive = 0
    const videoSent = 0
    console.log("875", videoReceive)
    const params = {
        videoDuration: {},
        totalUsage: {
        "received": 0,
        "sent": 0
        }
      };
    for (const id of consumedVideoIds){
      params.videoDuration[consumptionData[id].time] = {
        id: id,
        type: consumptionData[id].type,
        durations: Object.fromEntries(['360', '480', '720'].map(res => [res, consumptionData[id][res]])),
        totalVideoUsage: {
          "received": videoReceive-consumptionData[id].received,
          "sent": videoSent-consumptionData[id].sent
        }
      }
    }
    console.log("88889", JSON.stringify(params))
    const url = `/schedule-new/video-user-analytics`;
    Api.post(url).params(params).send((response, error) => {
      console.log("867", response)
      if (response != undefined) {
        resolve();
      }else{
        reject();
      }
    });
  })
}

export const triggerConversion = (fileUrl) => {
  return (dispatch, getState) => {
    const url = `https://pdf-to-ppt.aulas.in/convert-ppt-to-pdf`
    const splitarray = fileUrl.split('/');
    const inputBucket = splitarray.at(-4);
    const inputKey = splitarray.slice(-3, ).join('/');
    const outputKey = inputKey.split('.')[0]+'.pdf'
    return new Promise((resolve, reject) => {
      Axios.post(url,
        {
          inputBucket,
          inputKey,
          "outputBucket": inputBucket,
          outputKey
        }
        )
        .then((response) => {
          if (response != undefined) {
            response.endurl = fileUrl.split('/').slice(0, 4).join('/')+'/'+outputKey;
            resolve(response)
          }else{
            reject(false)
          }
        })

    })
  }
}

export const getConversionStatus = (jobId, callback=false, successCallBack=false) => {
  return (dispatch, getState) => {
    const url = `https://pdf-to-ppt.aulas.in/conversion-status/${jobId}`
    Axios.get(url)
      .then((response) => {
        if (response != undefined) {
          console.log("response", response)
          if (successCallBack){
            successCallBack(response)
          }
        }
        if (callback){
          callback()
        }
      })
  }
}