import { assignStudentToBatch } from 'actions/courseManagement'
import { addStudents } from 'actions/classRoom'
import { fetchStudentRollNo } from 'actions/userManagementV2'
import { Modal } from 'antd'
import AnimatedCustomSelect from 'components/AnimatedSelectComp'
import AnimatedTextField from 'components/AnimatedTextField'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

function AssignStudentsToBatchModal({ isAssignBatchModalVisible, setIsAssignBatchModalVisible, selectedStudents, classes, addStudents, togglePageLoader, setSelectedStudents, getCourseStudentsData, fetchStudentRollNo, studentRollNo, allStudentsParamsObj, setAllStudentsPageParam, assignStudentToBatch }) {
    const handleCancel = () => {
        setIsAssignBatchModalVisible(false)
    }
    const [paramsObj, setParamsObj] = useState({
        targetStd: null,
        targetSec: null
    })
    const [assignBtnLoader, setAssignBtnLoader] = useState(false);
    const classKeysArray = Object.keys(classes)?.map((key) => ({ name: key }));
    const sections = paramsObj?.targetStd && classes[paramsObj?.targetStd]?.map(cls => ({ id: cls.id, value: cls.section }));

    const handleAssignClick = () => {
        setAssignBtnLoader(true);
        if (selectedStudents?.length > 1) {
            addStudents(
                paramsObj?.targetSec,
                selectedStudents,
                () => setAssignBtnLoader(false),
                () => {
                    setIsAssignBatchModalVisible(false);
                    setSelectedStudents([])
                    togglePageLoader(true);
                    getCourseStudentsData(() => togglePageLoader(false))
                })
        } else {
            const params = {
                userId: selectedStudents[0],
                standardId: paramsObj?.targetSec,
                rollNo: allStudentsParamsObj?.rollNo?.toString()
            }
            assignStudentToBatch(
                params,
                () => setAssignBtnLoader(false),
                () => {
                    setIsAssignBatchModalVisible(false);
                    setSelectedStudents([])
                    togglePageLoader(true);
                    getCourseStudentsData(() => togglePageLoader(false))
                }
            )
        }
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isAssignBatchModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Assign to batch</center>
            <div className='m-t-20'>
                <AnimatedCustomSelect
                    label={"Batch"}
                    required={true}
                    options={classKeysArray}
                    handleSelectChange={(value) => { setParamsObj({ ...paramsObj, targetStd: value, targetSec: null }); setAllStudentsPageParam("rollNo", null); }}
                    value={paramsObj?.targetStd}
                    isSelected={paramsObj?.targetStd ? true : false} />
            </div>
            <div className='m-t-20'>
                <AnimatedCustomSelect
                    label={"Section"}
                    required={true}
                    options={sections}
                    handleSelectChange={(value) => {
                        setParamsObj({ ...paramsObj, targetSec: value });
                        fetchStudentRollNo(value, (res) => {
                            setAllStudentsPageParam("rollNo", res?.rollNo ? res?.rollNo : null)
                        })
                    }}
                    value={paramsObj?.targetSec}
                    isSelected={paramsObj?.targetSec ? true : false} />
            </div>
            {selectedStudents?.length == 1 ?
                <div className='m-t-30'>
                    <AnimatedTextField
                        label={"Roll number"}
                        isMandatory={true}
                        inputId={"-998"}
                        value={allStudentsParamsObj?.rollNo}
                        handleInputChange={(e) => {
                            const value = e.target.value;
                            const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                            const truncatedValue = sanitizedValue.slice(0, 9);
                            setAllStudentsPageParam("rollNo", truncatedValue);
                        }}
                    />
                </div>
                : <></>
            }
            <center>
                <div className='r-jsb m-t-30' style={{ width: "60%" }}>
                    <div style={{ color: "#636363", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setIsAssignBatchModalVisible(false) }}>CANCEL</div>
                    <Button type="primary"
                        loading={assignBtnLoader}
                        disabled={selectedStudents?.length == 1 ? !paramsObj?.targetStd || !paramsObj?.targetSec || !allStudentsParamsObj?.rollNo : !paramsObj?.targetStd || !paramsObj?.targetSec}
                        onClick={handleAssignClick}
                    >ASSIGN</Button>
                </div>
            </center>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    const { studentRollNo } = state.userManagementV2;
    const { allStudentsParamsObj } = state.courseManagement;
    return {
        studentRollNo, allStudentsParamsObj
    };
};

const mapDispatchToProps = (dispatch) => ({
    addStudents: (standardId, students, callback, successCb) => dispatch(addStudents(standardId, students, callback, successCb)),
    fetchStudentRollNo: (params, succesCallback) => dispatch(fetchStudentRollNo(params, succesCallback)),
    assignStudentToBatch: (params, callback, successCallback) => dispatch(assignStudentToBatch(params, callback, successCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignStudentsToBatchModal);
// export default AssignStudentsToBatchModal