import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';

import { MdKeyboardBackspace } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { Avatar} from 'antd';
import { RingProgress } from '@ant-design/charts';
import './styles.css';
import { useEffect, useState } from 'react';
import { fetchOneStudentResult, fetchTestSubmitDetails, updateCurrentQuestionIndex, updateSubmittedTestsId } from 'actions/testAdmin';
import PageDataLoader from 'components/pageDataLoader';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, StopOutlined } from '@ant-design/icons';
import { NodataCard } from './index';

const rankImg = require('Assets/test/rank.png').default;
const accuracyImg = require('Assets/test/accuracy.png').default;
const percentileImg = require('Assets/test/percentile.png').default;
const speedImg = require('Assets/test/speed.png').default;
const correctImg = require('Assets/test/correct.png').default;
const incorrectImg = require('Assets/test/incorrect.png').default;
const unattemptedImg = require('Assets/test/unattempted.png').default;

const PerformanceRow = ({img, title, param1, param2}) => {
  return(
    <div className='r-c-sb m-t-20'>
      <div className='r-ac'>
        <img src={img} className='result-icon-img' />
        <div className='text-xs bold-600 color-black m-l-10'>{title}</div>
      </div>
      <div className='r-ac'>
        <div className='text-sm bold-600 color-black'>{param1}</div>
        <div className='text-xs color-black m-l-5'>{param2}</div>
      </div>
    </div>
  )
}

const ScoreRow = ({icon, title, param1}) => {
  return(
    <div className='r-ac'>
      {icon}
      <div className='m-l-10' style={{display:"flex"}}>
        <div className='text-sm bold-600 color-black line-15 m-r-10'>{title}</div>
        <div className='text-xs color-black '>{param1}</div>
      </div>
    </div>
  )
}

const SectionsRow = ({subj, correct, incorrect, unattempt, obtain, total, unseen}) => {
  return(
    <div>
      <div className='text-xs bold-600 color-black m-b-10'>{subj}</div>
      <div className='r-c-sb'>
        <div className='r-ac'>
          <div className='r-ac m-r-20'>
            <div className='sectionRowRound' style={{ backgroundColor:"#28DF99"}}></div>
            <div className='text-xmd color-black m-l-5'>{correct}</div>
          </div>
          <div className='r-ac m-r-20'>
            <div className='sectionRowRound' style={{ backgroundColor:"#FF414D"}}></div>
            <div className='text-xmd color-black m-l-5'>{incorrect}</div>
          </div>
          <div className='r-ac m-r-20'>
            <div className='sectionRowRound' style={{ backgroundColor:"#AEAEAE"}}></div>
            <div className='text-xmd color-black m-l-5'>{unattempt}</div>
          </div>
          <div className='r-ac'>
            <div className='sectionRowRound' style={{ backgroundColor:"#FAFAFA", border:"1px solid black"}}></div>
            <div className='text-xmd color-black m-l-5'>{unseen}</div>
          </div>
        </div>
        <div className='text-sm bold-600 color-black'>{obtain}/{total}</div>
      </div>
    </div>
  )
}

const config = (totals)=> ({
    height: 200,
    width: 200,
    autoFit: false,
    percent: totals?.marksObtained/totals?.totalMarks,
    color: ['#594099', '#E6E6E6'],
    statistic: {
      content: false,
      title: {
        customHtml: () => <div className='r-c-c-c'>
                            <div className='text-sm bold-600'>{totals?.marksObtained}</div>
                            <div className='horizontal-divider' style={{width:'100%', backgroundColor:"black"}}></div>
                            <div className='text-sm'>{totals?.totalMarks}</div>
                          </div>
      },
    },
  });

function ResultScreen ({history, fetchOneStudentResult, selectedStudentResult, selectedStudent, selectedTest,
                      fetchTestSubmitDetails, updateSubmittedTestsId, updateCurrentQuestionIndex}) {
    const [pageLoader, togglePageLoader] = useState(false);
    useEffect(() => {
      togglePageLoader(true);
      fetchOneStudentResult(selectedTest.id, selectedStudent.userId, (bool)=>{togglePageLoader(bool)})
      return () => {
      }
    }, [])
    return (
      <div>
        <div className='r-ac m-b-30'>
          <MdKeyboardBackspace onClick={() => history.goBack()} className='text-md color-black m-r-10 cursor-pointer' />
          <div className='text-sm bold-600 color-black'>Result</div>
        </div>
        <div className='r-ac'>
          <Avatar size={50} icon={<FaUserAlt />} className='color-purple' style={{background:'#F6F4FE'}} />
          <div className='m-l-15'>
            <div className='text-xmd bold-600 color-black'>{selectedStudent.username}</div>
            <div className='text-xs a2grey'>Roll No. {selectedStudent.rollNo}</div>
          </div>
        </div>
        <div className='r-ac m-t-30'>
          <div className='shadow-box flex-1 p-20'>
            <div className='text-xmd bold-600 color-black m-b-20'>Score</div>
            <div className='r-c-se'>
              <RingProgress {...config(selectedStudentResult?.total?selectedStudentResult?.total:null)} />
              <div className='r-jsb-fc' style={{height:200}}>
                <ScoreRow icon={<CheckCircleOutlined style={{color:"#594099"}}/>} title={selectedStudentResult.total?.totalCorrect??0} param1={'Correct'} />
                {/* {selectedTest.testType==='Subjective'? */} <ScoreRow icon={<CheckCircleOutlined style={{color:"#594099"}}/>} title={selectedStudentResult.total?.totalPartialCorrect??0} param1={'Partially correct'} />{/* :null} */}
                <ScoreRow icon={<CloseCircleOutlined style={{color:"#594099"}}/>} title={selectedStudentResult.total?.totalIncorrect??0} param1={'Incorrect'} />
                <ScoreRow icon={<MinusCircleOutlined style={{color:"#594099"}}/>} title={selectedStudentResult.total?.totalUnattempted??0} param1={'Unattempted'} />
                <ScoreRow icon={<StopOutlined style={{color:"#594099"}}/>}        title={selectedStudentResult.total?.totalUnseen??0} param1={'Unseen'} />
                {/* <ScoreRow icon={<ClockCircleOutlined style={{color:"#594099"}}/>} title={selectedStudentResult.total?.totalDuration} param1={'Total Time Taken'} /> */}
              </div>
            </div>
          </div>
          {
            selectedStudentResult?.declared?
            <div className='shadow-box flex-1 m-l-50 p-20'>
            <div className='text-xmd bold-600 color-black'>Performance analysis</div>
            <PerformanceRow img={rankImg} title={'Rank'} param1={selectedStudentResult.rank?selectedStudentResult.rank:""} param2={``}/>
            <PerformanceRow img={accuracyImg} title={'Accuracy'} param1={selectedStudentResult.accuracy?selectedStudentResult.accuracy:""} param2={`%`}/>
            <PerformanceRow img={speedImg} title={'Speed'} param1={selectedStudentResult.speed?selectedStudentResult.speed:""} param2={`Mins/Qs`}/>
            <PerformanceRow img={percentileImg} title={'Percentile'} param1={selectedStudentResult.percentile!=null?selectedStudentResult.percentile:"NA"} param2={``}/>
            </div>
            :null
          }

        </div>
        {selectedStudentResult?.subjects && Object.keys(selectedStudentResult?.subjects)?.length > 0
                ?
        <div className='r-ac m-t-30 m-b-20'>
          <div className='shadow-box flex-1 p-20'>
            <div className='text-xmd bold-600 color-black m-b-20'>Sections</div>
            {   selectedStudentResult?.subjects && Object.keys(selectedStudentResult?.subjects)?.length > 0
                ?
                Object.keys(selectedStudentResult?.subjects)?.map((subjectkey, index)=>
                <>
                {index>0?<div className='horizontal-divider m-t-15 m-b-15'></div>:null}
                <SectionsRow subj={subjectkey} correct={selectedStudentResult.subjects[subjectkey]?.totalCorrect}
                                                incorrect={selectedStudentResult.subjects[subjectkey]?.totalIncorrect}
                                                unattempt={selectedStudentResult.subjects[subjectkey]?.totalUnattempted}
                                                obtain={selectedStudentResult.subjects[subjectkey]?.marksObtained}
                                                total={selectedStudentResult.subjects[subjectkey]?.totalMarks}
                                                unseen={selectedStudentResult.subjects[subjectkey]?.totalUnseen}/></>)
                :
                <NodataCard/>
            }
            <div className='r-c-c m-t-30'>
              <Button onClick={() => {
                updateCurrentQuestionIndex(0);
                togglePageLoader(true);
                fetchTestSubmitDetails((bool)=>{
                togglePageLoader(bool);
                history.push('test-stud-submit-ques-screen');
                
              });
              }} 
              className='purpleBtnWrap radius-100 purpleButtonHoverEffect'>VIEW SOLUTIONS</Button>
            </div>
          </div>
          <div className='flex-1 m-l-50 p-20'></div>
        </div>
        :
        <div className='r-c-c m-t-30'>
        <Button onClick={() => {
          updateCurrentQuestionIndex(0);
          togglePageLoader(true);
          fetchTestSubmitDetails((bool)=>{
          togglePageLoader(bool);
          history.push('test-stud-submit-ques-screen');
          
        });
        }} 
        className='purpleBtnWrap radius-100 purpleButtonHoverEffect'>VIEW SOLUTIONS</Button>
      </div>
        }
        
        <PageDataLoader visible={pageLoader}/>
      </div>
  );
}


const mapStateToProps = state => {
  const {selectedStudentResult, selectedStudent, selectedTest}=state.testAdmin;
  return {
    selectedStudentResult, selectedStudent, selectedTest
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOneStudentResult: (testId, studentId, callback)=> dispatch(fetchOneStudentResult(testId, studentId, callback)),
  updateSubmittedTestsId:(id) => dispatch(updateSubmittedTestsId(id)),
  fetchTestSubmitDetails: (callback)=>dispatch(fetchTestSubmitDetails(callback)),
  updateCurrentQuestionIndex: (index) => dispatch(updateCurrentQuestionIndex(index))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultScreen);
