import React, { useEffect, useState } from 'react'
import "./saveForLater.css"
import { Button } from 'components/Button'
import arrowUpIcon from "../../Assets/aulasMarket/ic-chevron-up.svg"
import arrowDownIcon from "../../Assets/aulasMarket/ic-chevron-down.svg"
import "./studentCart.css"
import { Input, Tooltip } from 'antd'
import { applyPromoCheckout, checkLiveCourse, fetchPromoCodes, getCheckPayment, payNowCart, postAddSaveForLater, postCartPayment, setCouponInCart } from 'actions/studentMarketPlace'
import { connect } from 'react-redux'
import moment from 'moment';
import { generatePaymentRequest } from 'pages/profile/paymentGateway'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function MobileCheckOut({ singleBuyNowDetails, couponList, isPayTerm, termList, applyPromoCheckout, checkLiveCourse, selectedCouponCode, setSelectedCouponCode, postCartPayment, getCheckPayment, couponInCart, user, payNowCart, setCouponInCart, storePromoPrice }) {
    const [isDetailsVisible, setDetailsVisible] = useState(false);
    const [disableHeader, setHeaderdisable] = useState(false);
    const [applyLoader, toggleApplyLoader] = useState(false);
    const [paynowLoader, togglePaynowLoader] = useState(false);
    const toggleDetails = () => {
        setDetailsVisible((prev) => !prev);
        setHeaderdisable(!disableHeader);
    };
    const [payOfferCode, setPayOfferCode] = useState('');
    const history = useHistory();
    const location = useLocation();
    console.log("sssdddd", payOfferCode)
    const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
    let fromMarket = true;
    const numberWithCommas = (x) => {
        return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
    }


    useEffect(() => {
        setPayOfferCode(location?.state?.code)
    }, [location?.state])

    useEffect(() => {

    }, [])

    const totalAmount = storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount || storePromoPrice?.amount - storePromoPrice?.discount;
    const totalAmountBeforeDiscount = singleBuyNowDetails?.amount - singleBuyNowDetails?.discount;

    const from = "checkout"
    const isLiveParam = {
        userId: user?.id,
        userAccessToken: user?.accessToken
    }
    const checkDiffTotalAmt = couponInCart ? (storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount)?.toFixed(2) : (singleBuyNowDetails?.amount - singleBuyNowDetails?.discount).toFixed(2) || null
    console.log("tototot", totalAmountBeforeDiscount, checkDiffTotalAmt)
    const handlePayNow = () => {
        togglePaynowLoader(true)
        let paramsPay = {
            orderAmount: singleBuyNowDetails?.totalAmount || null,
            orderCurrency: 'INR',
            orderId: generateOrderId.toString() || null,
            customerDetails: {
                customer_id: user?.id?.toString() || null,
                customer_email: user?.email || null,
                customer_phone: user?.phone?.toString() || null
                // customer_phone: '+916360068158'
            }
        };
        if (singleBuyNowDetails?.item && singleBuyNowDetails.item.length > 0) {
            let firstItem = singleBuyNowDetails?.item?.[0];
            let paramsCheckPayment = {
                amount: singleBuyNowDetails?.totalAmount || null,
                checkout: true,
                itemId: firstItem?.id,
                itemType: firstItem?.resourceType,
                code: payOfferCode,
                billingPlan: singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? isPayTerm : null,
                updatedCourseBilling:true,
            }
            if (paramsCheckPayment?.amount == 0 || checkDiffTotalAmt == 0) {
                let paramProcess = {
                    checkout: true,
                    type: paramsCheckPayment?.itemType,
                    id: paramsCheckPayment?.itemId,
                    promo: payOfferCode,
                    free: true,
                    billingPlan: singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? isPayTerm : null,
                    updatedCourseBilling:true,

                }
                // getCheckPayment(paramsCheckPayment, () => {
                //     postCartPayment(paramProcess, () => {
                //         history.push("/courses-list")
                //     })
                //     togglePaynowLoader(false)
                // })

                postCartPayment(paramProcess, () => {
                    if (singleBuyNowDetails?.item?.[0]?.resourceType === "course") {
                        checkLiveCourse(isLiveParam, () => {
                            history.push("/home")

                        });
                    }
                    // history.push("/courses-list")
                })
                togglePaynowLoader(false)
            } else {
                getCheckPayment(paramsCheckPayment, (res) => {
                    if (res?.valid) {
                        payNowCart(paramsPay, (response) => {
                            togglePaynowLoader(false)
                            generatePaymentRequest(response, null, user, fromMarket)
                            togglePaynowLoader(false)
                        })
                    }
                })
            }
        }
    }
    const handleApplyClick = () => {
        if (singleBuyNowDetails?.item && singleBuyNowDetails.item.length > 0) {
            const firstItem = singleBuyNowDetails.item?.[0];
            let params = {
                code: payOfferCode,
                type: 'offer',
                id: firstItem?.id,
                itemType: firstItem?.resourceType,
            };
            setPayOfferCode(payOfferCode)
            toggleApplyLoader(true);
            applyPromoCheckout(params, () => {
                toggleApplyLoader(false);
            }, () => {
                setCouponInCart(true);
                toggleApplyLoader(false)
            });
        }
    }
    const handleInputCodeMobile = (e) => {
        setPayOfferCode(e.target.value);

    }
    return (
        <div className='mobile-price-parent' style={{ fontFamily: "Roboto" }}>
            <div className='mobile-price-header' >PRICE DETAILS</div>
            <div className='mobile-price-details-container'>
                <div className='r-c-sb' style={{ fontSize: 12, fontWeight: 'bold' }}>
                    <span style={{ width: "100%", padding: 10 }} className={disableHeader ? "mobile-disable" : ""}>Total payable amount</span>
                    <span style={{ width: "100%", padding: 10 }} onClick={toggleDetails} className='r-c-fe'>
                        <span className={disableHeader ? "mobile-disable" : ""}> ₹{couponInCart ? numberWithCommas(totalAmount.toFixed(2)) : numberWithCommas(totalAmountBeforeDiscount.toFixed(2))}</span>
                        <img className='m-l-10' src={isDetailsVisible ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                    </span>
                </div>

                {isDetailsVisible && (
                    <div className='price-details'>
                        {singleBuyNowDetails?.item?.[0]?.resourceType === "course" ? <>
                            <div className='' style={{ fontSize: "14px", fontWeight: "bold", padding: "10px" }}>Pay after registration</div>
                            <div className='cart-price' style={{ padding: "4px 10px", fontSize: 12 }}>Balance after registration
                                <span /* style={{ color: "black" }} */>₹{singleBuyNowDetails?.balanceRegistration}</span>
                            </div>
                            {isPayTerm !== null || isPayTerm !== undefined || singleBuyNowDetails?.billingPlan !== null || singleBuyNowDetails?.billingPlan !== "none" ?
                                <>
                                    <div className='cart-price' style={{ padding: "4px 10px", fontSize: 12 }}>Payment term
                                        <span /* style={{ color: "black" }} */>{isPayTerm ? isPayTerm : singleBuyNowDetails?.billingPlan}</span>
                                    </div>
                                    <div className='r-c-sb m-b-10'>
                                        {/* <div className='' onClick={() => { history.push('/fee-plan') }} style={{ padding: "2px 10px", color: "#594099", fontSize: "11px", fontWeight: "bold", cursor: "pointer" }}>EDIT PLAN</div> */}
                                        <div className='' style={{ padding: "2px 10px", color: "#636363" }}>
                                            ({termList?.length || singleBuyNowDetails?.installmentCount} {termList?.length === 1 || singleBuyNowDetails?.installmentCount === 1 ? 'installment' : 'installments'})
                                        </div>
                                    </div>
                                </> : <></>
                            }
                            <div className='price-border'></div>
                            <div className='' style={{ fontSize: "14px", fontWeight: "bold", padding: "10px" }}>Pay now to registration</div>
                            <div className='cart-price' style={{ padding: "4px 10px", fontSize: 12 }}>Registration Fee*
                                <span /* style={{ color: "black" }} */>₹{singleBuyNowDetails?.exAmount}</span>
                            </div>
                            <div className='cart-price' style={{ padding: "4px 10px", fontSize: 12 }}>GST
                                <span /* style={{ color: "black" }} */>₹{singleBuyNowDetails?.gst}</span>
                            </div>
                            <div className='price-border'></div>
                            <div className='cart-total-amount' style={{ padding: 10, marginTop: "0px", fontSize: 12 }}>Total amount
                                <span>₹{singleBuyNowDetails?.totalAmount}</span>
                            </div>
                            <div className='cart-diffrence-amount'>You will save ₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.discount?.toFixed(2) || storePromoPrice?.discount?.toFixed(2)) : numberWithCommas(singleBuyNowDetails?.discount?.toFixed(2))} on this purchase</div>
                            <div className='price-border'></div>
                        </> : <>
                            <div className='cart-price' style={{ padding: 10, fontSize: 12 }}>{"Price"}
                                <span>₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.amount?.toFixed(2) || storePromoPrice?.amount?.toFixed(2)) : numberWithCommas(singleBuyNowDetails?.amount.toFixed(2))}</span>
                            </div>
                            <div className='cart-discount' style={{ padding: 10, marginTop: "-15px", fontSize: 12 }} >Discount
                                <span style={{ color: "#28DF99" }}>₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.discount.toFixed(2) || storePromoPrice?.discount?.toFixed(2)) : numberWithCommas(singleBuyNowDetails?.discount.toFixed(2))}</span>
                            </div>
                            <div className='price-border'></div>
                            <div className='cart-total-amount' style={{ padding: 10, marginTop: "0px", fontSize: 12 }}>Total amount
                                <span>₹{couponInCart ? numberWithCommas(totalAmount.toFixed(2)) : numberWithCommas(totalAmountBeforeDiscount.toFixed(2))}</span>
                            </div>
                            <div className='price-border'></div>
                            <div className='cart-diffrence-amount'>You will save ₹{couponInCart ? numberWithCommas(storePromoPrice?.cart?.discount?.toFixed(2) || storePromoPrice?.discount?.toFixed(2)) : numberWithCommas(singleBuyNowDetails?.discount?.toFixed(2))} on this purchase</div>
                            <div className='price-border'></div>
                        </>}
                        {/* <div className='price-coupon-field' style={{ padding: "10px" }}>
                            {couponInCart ?
                                <div className='r-c-sb' style={{ alignItems: "flex-start" }}>
                                    <div className='r-c-c'><img style={{ marginTop: -20 }} src={require("../../Assets/aulasMarket/ic-check-filled.svg").default} />
                                        <div className='r-sfa m-l-10'>
                                            <div style={{ color: "#191919", fontSize: 13, fontWeight: "600" }}>You saved ₹{numberWithCommas(storePromoPrice?.cart?.discount.toFixed(2)) || numberWithCommas(storePromoPrice?.discount?.toFixed(2))
                                            } with this code</div>
                                            <div style={{ color: "#AEAEAE", fontSize: 12 }}>Coupon Applied</div>
                                        </div>
                                    </div>
                                    <div style={{ color: '#FF414D', cursor: 'pointer', marginLeft: 5, fontWeight: "500" }} onClick={() => { setCouponInCart(false); setPayOfferCode('') }}>REMOVE</div>
                                   

                                </div>
                                :
                                <div className=' r-c-c' style={{ display: 'flex' }}>
                                    <Input
                                        className='price-coupon-filed'
                                        type="text"
                                        placeholder='Enter coupon code'
                                        onChange={handleInputCodeMobile}
                                        value={payOfferCode}
                                    ></Input>
                                    <Button
                                        loading={applyLoader}
                                        className='r-c-c text-xm bold-600'
                                        type="button"
                                        onClick={handleApplyClick}
                                        style={{
                                            cursor: 'pointer',
                                            border: '1px solid #594099',
                                            borderRadius: " 0px 4px 4px 0px",
                                            backgroundColor: 'white',
                                            padding: '5px 10px',
                                            color: "#594099",
                                            height: "6vh",
                                            opacity: 0.4
                                        }}
                                    >
                                        APPLY
                                    </Button>
                                </div>
                            }

                            {couponList?.items?.length > 0 ?
                                <div onClick={() => { history.push("/apply-coupon") }} style={{ fontSize: "12px", fontWeight: "bold", fontWeight: "bold", color: "#1089FF", cursor: "pointer", marginTop: 10 }}>VIEW ALL COUPONS</div>
                                : <></>}
                            {singleBuyNowDetails?.item?.[0]?.resourceType === "course" ?
                                <div style={{ fontSize: 12, marginTop: 10 }}>
                                    For the live courses in the cart only the registration fee will be charged, the total fees will be collected as per the fee plan
                                </div>
                                : <></>}                        
                            </div> */}

                    </div>
                )}

                <div className='r-c-c' style={{ marginTop: 10 }}>
                    <Button
                        style={{ width: "80%" }}
                        type="primary"
                        loading={paynowLoader}
                        onClick={() => handlePayNow()}
                    >
                        {!couponInCart ? (totalAmountBeforeDiscount === 0 ? "ENROLL NOW" : "PAY NOW") : (totalAmount === 0 ? "ENROLL NOW" : "PAY NOW")}

                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { promoCodeDetials, cartDetails, couponList, selectedCouponCode, couponInCart, storePromoPrice, singleBuyNowDetails } = state.studentMarketPlace
    const { user } = state.session
    return { promoCodeDetials, cartDetails, couponList, user, couponInCart, storePromoPrice, singleBuyNowDetails, selectedCouponCode }
};

const mapDispatchToProps = (dispatch) => ({
    checkLiveCourse: (param) => dispatch(checkLiveCourse(param)),
    setSelectedCouponCode: (val) => dispatch(setSelectedCouponCode(val)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    applyPromoCheckout: (params, callback, succesCallback) => dispatch(applyPromoCheckout(params, callback, succesCallback)),
    payNowCart: (params, callback) => dispatch(payNowCart(params, callback)),
    getCheckPayment: (param, callback) => dispatch(getCheckPayment(param, callback)),
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),

});

export default connect(mapStateToProps, mapDispatchToProps)(MobileCheckOut);
