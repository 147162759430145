import { CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { deleteInstallment, deleteOffer, getOnlineTestSeriesCourseDetails, getSpecificCourseData, getSpecificSMData, getSpecificVideoCourseData, patchPublishOffers, postAddFeeStudyMaterial, postAddLiveCourseInstallMents, postCreateFeeRegistration, postCreateLiveCourseOffers } from 'actions/courseManagement';
import { DatePicker, Dropdown, Input, Menu, Select, Switch, Tooltip, Typography } from 'antd';
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import { Information } from 'components/Typography';
import moment from 'moment';
import { OfferTagText, OfferTagTextColor, OfferTypeGraphColor } from 'pages/offers/constants/offers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import greyUpArrow from '../../Assets/aulasMarket/chevron-down-1.svg';
import greyDownArrow from '../../Assets/aulasMarket/chevron-down-right.svg';
import chevronDown from '../../Assets/aulasMarket/ic-chevron-down.svg';
import publish from '../../Assets/aulasMarket/ic-publish-1.svg';
import unPublish from '../../Assets/aulasMarket/ic-publish.svg';
import EditOfferDrawer from './components/editOfferDrawer';
import PublishModal from './components/publishModal';
import "./feeDetails.css";
import FeeTermPlanCard from './feeTermPlan';
import DeleteInstallmentModal from './installmentDeleteModal';
import DeleteModalOffer from './offerDeleteModal';
const offerIconPublished = require('Assets/offers/offer-icon-published.svg').default;
const offerIconUnpublished = require('Assets/offers/offer-icon-unpublished.svg').default;
const offerIconPrivate = require('Assets/offers/offer-icon-private.svg').default;
const offerIconExpired = require('Assets/offers/offer-icon-expired.svg').default;
const offerIconBanner = require('Assets/offers/offer-icon-banner.svg').default;



const { Option } = Select;

const bodyStyle = {
    backgroundColor: "#FAFAFA",
    width: " calc(100% + 30px)",
    height: "calc(100% + 60px)",
    margin: "-20px",
    padding: "20px",
}
const FeeDetails = ({ specificCourseDataContainer, deleteOffer, getSpecificCourseData, postCreateFeeRegistration, postCreateLiveCourseOffers, newCourseId, postAddLiveCourseInstallMents, deleteInstallment, courseTypeName, patchPublishOffers, postAddFeeStudyMaterial, getSpecificSMData, getSpecificVideoCourseData, user }) => {
    const history = useHistory();
    const [editValue, setEditValue] = useState(false)
    const [addAppCode, setAppCode] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const [downOutlined, setDownOutlined] = useState(Array(specificCourseDataContainer?.course?.offers.length).fill(false))
    const [addFeeTerm, setAddFeeTerm] = useState(Array(specificCourseDataContainer?.course?.courseBilling?.length).fill(false))
    const [isOfferDrawerVisible, setIsOfferDrawerVisible] = useState(false);
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [storeInstallmentData, setStoreInstallmentData] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isPublishModalVisible, setIsPublishModalVisible] = useState(false)

    const [isInstallDeleteModal, setInstallDeleteModal] = useState(false)
    const [storePromoData, setStorePromoData] = useState(null)
    const [promoCodeTitle, setPromoCodeTitle] = useState('')
    const [promoCodeDetails, setPromoCodeDetails] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [discountType, setDiscountType] = useState(null)
    const [discount, setDiscount] = useState(null)
    const [code, setCode] = useState('')
    const [minRegisterFee, setMinRegisterFee] = useState(null)
    const [totalFee, setTotalFee] = useState(null)
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [isDiscountValue, setIsDiscountValue] = useState(null)
    const [isFeeDetailsModalVisible, setFeeDetailsModalVisible] = useState(false);
    const [addFeeTermPlan, setAddFeeTermPlan] = useState(false)
    const [isSwitch, setIsSwitch] = useState(null)
    const [newPromoCodeTitle, setNewPromoCodeTitle] = useState('')
    const [newOfferDetail, setNewOfferDetails] = useState('')
    const [newStartDate, setNewStartDate] = useState('')
    const [newEndDate, setNewEndDate] = useState('')
    const [newDiscountType, setNewDiscountType] = useState('percent')
    const [newDiscount, setNewDiscount] = useState('')
    const [newPromoCode, setNewPromoCode] = useState('')
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [isPublish, setIsPublish] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [loader, setLoader] = useState(false)
    const [promoLoader, setPromoLoader] = useState(false)
    const [isDropDownOpen, setIsDropDown] = useState(false)
    const [isPromoDropDownOpen, setIsPromoDropDown] = useState(false)

    console.log("newStartDate", newStartDate);

    const offers = specificCourseDataContainer?.course?.offers || specificCourseDataContainer?.offers;
    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');


    const [feeDetails, setFeeDetails] = useState(
        [{
            id: '',
            planName: '',
            billingPlan: '',
            installments: [{
                amount: null,
                dueDate: null
            }],
            hasDueDate: null,
            defaultPlan: true,
            courseId: '',
            installmentTotal: 0
        }]
    )


    useEffect(() => {
        const feeTemp = specificCourseDataContainer?.course?.courseBilling?.map((item, index) => {
            const newTopic = {
                id: item?.id,
                planName: item?.planName,
                billingPlan: item?.billingPlan,
                installments: item?.installments?.map((installment, index) => ({
                    amount: installment?.amount,
                    dueDate: installment?.dueDate,
                })),
                defaultPlan: item?.defaultPlan,
                courseId: item?.courseId,
                hasDueDate: item?.hasDueDate,
                installmentTotal: item?.installmentTotal
            };
            return newTopic;
        });
        setFeeDetails(feeTemp)
    }, [specificCourseDataContainer?.course?.courseBilling])
    const toggleCard = (index) => {
        const newOpenStates = [...downOutlined];
        newOpenStates[index] = !newOpenStates[index];
        for (let i = 0; i < newOpenStates.length; i++) {
            if (i !== index) {
                newOpenStates[i] = false;
            }
        }
        setDownOutlined(newOpenStates);
    };

    function onChangeEndDate(date, dateString) {
        setEndDate(moment(dateString).format("DD-MM-YYYY"))
    }

    useEffect(() => {
        if (courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials') {
            setMinRegisterFee(specificCourseDataContainer?.registrationFee)
            setTotalFee(specificCourseDataContainer?.amount)
            setDiscountType(specificCourseDataContainer?.discountType);
            setIsDiscountValue(specificCourseDataContainer?.discount)
            if (specificCourseDataContainer?.discountType == "percent") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.amount - specificCourseDataContainer?.discount)
            }
        } else {
            setMinRegisterFee(specificCourseDataContainer?.course?.registrationFee)
            setTotalFee(specificCourseDataContainer?.course?.amount)
            setDiscountType(specificCourseDataContainer?.course?.discountType);
            setIsDiscountValue(specificCourseDataContainer?.course?.discount)
            if (specificCourseDataContainer?.course?.discountType == "percent") {
                setDiscountedPrice(Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100)
            } else if (specificCourseDataContainer?.course?.discountType == "amount") {
                setDiscountedPrice(specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount)
            }
        }

    }, [editValue])

    const calcDiscount = (e) => {
        if (discountType == "percent") {
            setDiscountedPrice(Math.round((totalFee - (totalFee * e.target.value) / 100) * 100) / 100)
        } else if (discountType == "amount") {
            setDiscountedPrice(totalFee - e?.target?.value)
        }
        setIsDiscountValue(e.target.value)
        // setCreateCourseObj("discount", parseInt(e.target.value))
    }
    useEffect(() => {
        setPromoCodeTitle(storePromoData?.name)
        setPromoCodeDetails(storePromoData?.details)
        setStartDate(startDate ? moment(storePromoData?.startDate).format('DD/MM/YY') : null)
        setEndDate(endDate ? moment(storePromoData?.endDate).format('DD/MM/YY') : null)
        if (storePromoData?.discountType == 'percent') {
            setNewDiscountType('percent')
            setDiscount(storePromoData?.amount ? storePromoData?.amount : null)
        } else {
            setNewDiscountType('amount')
            setDiscount(storePromoData?.amount ? storePromoData?.amount : null)
        }
        setCode(storePromoData?.code)
        setIsSwitch(storePromoData?.code ? true : false)

    }, [downOutlined])

    const study_promo_code = {
        studyMaterialId: newCourseId,
        id: selectedOfferId,
        type: 'studyMaterial',
        startDate: moment(startDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        endDate: moment(endDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        discountType: newDiscountType,
        discount: discount == undefined ? 0 : parseInt(discount),
        details: promoCodeDetails,
        code: code,
        title: promoCodeTitle,
    }

    const study_new_promo_code = {
        studyMaterialId: newCourseId,
        type: 'studyMaterial',
        startDate: moment(newStartDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        endDate: moment(newEndDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        discountType: newDiscountType,
        discount: newDiscount == '' ? 0 : parseInt(newDiscount),
        details: newOfferDetail,
        code: newPromoCode,
        title: newPromoCodeTitle,
    }
    console.log("newDiscountType", newDiscountType);
    const video_new_promo_code = {
        videoResourceId: newCourseId,
        type: 'videoResource',
        startDate: moment(newStartDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        endDate: moment(newEndDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        discountType: newDiscountType,
        discount: newDiscount == '' ? 0 : parseInt(newDiscount),
        details: newOfferDetail,
        code: newPromoCode,
        title: newPromoCodeTitle,
    }
    const video_promo_code = {
        videoResourceId: newCourseId,
        id: selectedOfferId,
        type: 'videoResource',

        startDate: moment(startDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        endDate: moment(endDate, 'DD/MM/YY').format('YYYY/MM/DD'),
        discountType: newDiscountType,
        discount: discount == undefined ? 0 : parseInt(discount),
        details: promoCodeDetails,
        code: code,
        title: promoCodeTitle,
    }


    const promo_code = {
        courseId: newCourseId,
        id: selectedOfferId,
        type: 'liveCourse',
        startDate: startDate ? moment(startDate, 'DD/MM/YY').format('YYYY/MM/DD') : null,
        endDate: endDate ? moment(endDate, 'DD/MM/YY').format('YYYY/MM/DD') : null,
        discountType: newDiscountType,
        discount: discount == undefined ? 0 : parseInt(discount),
        details: promoCodeDetails,
        code: code,
        title: promoCodeTitle,
    }

    const new_promo_code = {
        courseId: newCourseId,
        type: 'liveCourse',
        startDate: newStartDate ? moment(newStartDate, 'DD/MM/YY').format('YYYY/MM/DD') : null,
        endDate: newEndDate ? moment(newEndDate, 'DD/MM/YY').format('YYYY/MM/DD') : null,
        discountType: newDiscountType,
        discount: newDiscount == '' ? 0 : parseInt(newDiscount),
        details: newOfferDetail,
        code: newPromoCode,
        title: newPromoCodeTitle,
    }

    console.log("new_promo_code", new_promo_code);

    const toggleFeeCard = (index) => {
        const newOpenStates = [...addFeeTerm];
        newOpenStates[index] = !newOpenStates[index];
        for (let i = 0; i < newOpenStates.length; i++) {
            if (i !== index) {
                newOpenStates[i] = false;
            }
        }
        setAddFeeTerm(newOpenStates);

    };
    const menuItems = [
        { value: "percent", label: "Percentage" },
        { value: "amount", label: "Amount" },
    ]
    const menu = (
        <Menu className='different-bgc-dropdown' style={{ width: "120px" }}>
            {menuItems?.map((item) => (
                <div style={{ fontSize: 15, cursor: "pointer", borderRadius: 4, marginTop: 5, padding: "2px 10px 2px 10px" }} onClick={() => { console.log("item?.value", item?.value); setDiscountType(item?.value), setIsDropDown(false), setDiscount(null), setIsDiscountValue(null), setNewDiscount(null) }}>{item?.label}</div>
            ))}
        </Menu>
    )
    const disabledDate = (current) => {
        return current && current < moment().startOf('day');

    }
    const disabledEndDate = (current) => {
        return current && current <= moment(startDate, "DD-MM-YYYY").subtract(1, 'day').endOf('day');
    }
    const disabledEndDateS = (current) => {
        return current && current <= moment(newStartDate, "DD-MM-YYYY").subtract(1, 'day').endOf('day');
    }
    const setValues = (checked) => {
        setIsSwitch(checked)
        setCode('')

    }
    const PromoMenu = (
        <Menu className='different-bgc-dropdown' style={{ width: "120px" }}>
            {menuItems?.map((item) => (
                <div style={{ fontSize: 15, cursor: "pointer", borderRadius: 4, marginTop: 5, padding: "2px 10px 2px 10px" }} onClick={() => { console.log("item?.value", item?.value); setNewDiscountType(item?.value), setIsPromoDropDown(false), setDiscount(null), setIsDiscountValue(null), setNewDiscount(null) }}>{item?.label}</div>
            ))}
        </Menu>
    )

    const getOfferIcon = (offer) => {
        let offerIcon = offerIconPublished;
        if (offer.expired) {
            offerIcon = offerIconExpired;
        } else if (offer.privateOffer) {
            offerIcon = offerIconPrivate;
        } else if (!offer.published) {
            offerIcon = offerIconUnpublished;
        } else if (offer.banner) {
            offerIcon = offerIconBanner;
        }
        return offerIcon;
    };

    const getOfferTag = (offer) => {
        let offerTagBackground = OfferTypeGraphColor.published;
        let offerTagText = OfferTagText.published;
        let offerTagTextColor = OfferTagTextColor.published;
        if (offer.expired) {
            offerTagText = OfferTagText.expired;
            offerTagTextColor = OfferTagTextColor.expired;
            offerTagBackground = OfferTypeGraphColor.expired;
        } else if (offer.privateOffer) {
            offerTagText = OfferTagText.privateOffer;
            offerTagTextColor = OfferTagTextColor.privateOffer;
            offerTagBackground = OfferTypeGraphColor.privateOffer;
        } else if (!offer.published) {
            offerTagText = OfferTagText.unpublished;
            offerTagTextColor = OfferTagTextColor.unpublished;
            offerTagBackground = OfferTypeGraphColor.unpublished;
        } else if (offer.banner) {
            offerTagText = OfferTagText.banner;
            offerTagTextColor = OfferTagTextColor.banner;
            offerTagBackground = OfferTypeGraphColor.banner;
        }
        return {
            offerTagText,
            offerTagTextColor,
            offerTagBackground,
        }
    }

    return (
        <div onClick={(e) => { e.stopPropagation(); setIsDropDown(false), setIsPromoDropDown(false) }}>
            <PageHeader title={'Fee details'} onBackPress={() => { history.goBack() }} />
            <div style={{ ...bodyStyle }}>
                <div className='top-alignemnet-flex-box' >
                    <div style={{ width: "100%" }}>
                        {!editValue ?
                            <div className='fee-details-card border-radius card-container'>
                                {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? (<></>) :
                                    (<>
                                        <div className='fee-card-flex'>
                                            <div className='font-color-bold'>Registration fee</div>
                                            <div className='font-text'>₹ {specificCourseDataContainer?.course?.registrationFee}</div>
                                        </div>
                                        <div className='border-line'></div>
                                    </>)

                                }

                                <div className='fee-card-flex' style={{ marginTop: `${courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '20px' : ''}` }}>
                                    <div className='font-color-bold'>Total fee</div>
                                    <div className='font-text'>₹ {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? specificCourseDataContainer?.amount : specificCourseDataContainer?.course?.amount}</div>
                                </div>
                                <div className='border-line'></div>
                                <div className='fee-card-flex' style={{ marginTop: `${courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '20px' : ''}` }}>
                                    <div className='font-color-bold'>Discount</div>
                                    <div style={{ display: "flex" }}>
                                        <div className='font-color-bold' style={{ marginRight: "10px", marginTop: "-2px" }}>
                                            <h> {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? specificCourseDataContainer?.discountType : specificCourseDataContainer?.course?.discountType == 'percent' ? 'Percentage' : 'Amount'}</h>

                                        </div>
                                        <div className='font-text'>| {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? specificCourseDataContainer?.discount : specificCourseDataContainer?.course?.discount}</div>
                                    </div>
                                </div>
                                <div className='border-line'></div>
                                <div className='fee-card-flex' style={{ marginTop: `${courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '20px' : ''}` }}>
                                    <div className='font-color-bold'>Fee after discount</div>
                                    <div className='font-text'>₹ {
                                        courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ?
                                            specificCourseDataContainer?.discountType == "amount" ? specificCourseDataContainer?.amount - specificCourseDataContainer?.discount : (Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100)
                                            :
                                            specificCourseDataContainer?.course?.discountType == "amount" ? specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount : (Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100)}</div>

                                </div>
                                <div className='border-line'></div>
                                {hasMarketingPrivilege ? <></> :
                                    <div className='edit-text-fee' onClick={() => { setEditValue(true) }}><EditOutlined /> EDIT DETAILS</div>
                                }

                            </div> :
                            <div className='fee-details-card border-radius' >
                                {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? (<></>) :
                                    (<>
                                        <div className='input-text-headings' style={{ marginTop: "-5px" }}>Registration fee</div>
                                        <div style={{ display: "flex" }}> <div className='font-text' style={{ marginTop: "2px" }}> ₹ </div><div><Input type="number" bordered={null} value={minRegisterFee} placeholder='Enter amount' onChange={(e) => { setMinRegisterFee(e.target.value), setIsDiscountValue(null), setDiscountedPrice(null) }} /></div></div>
                                        <div className='save-border-line'></div>
                                    </>)}


                                <div className='input-text-headings' style={{ marginTop: 5 }}>Total fee</div>
                                <div style={{ display: "flex" }}> <div className='font-text' style={{ marginTop: "2px" }}> ₹ </div><div><Input type="number" bordered={null} placeholder='Enter amount' value={totalFee} onChange={(e) => { setTotalFee(e.target.value); setIsDiscountValue(null), setDiscountedPrice(null) }} /></div></div>
                                <div className='save-border-line'></div>
                                <div style={{ display: `${courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '' : "flex"}`, justifyContent: "space-between", marginTop: "5px" }}>
                                    <div>
                                        <div className='input-text-headings'>Discount</div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex" }}>
                                                <Dropdown bordered={false} overlay={isDropDownOpen ? menu : <></>} trigger={['click']} open={isDropDownOpen} style={{ backgroundColor: "red" }}>
                                                    <div style={{ display: "flex" }} onClick={(e) => { e.stopPropagation(); setIsDropDown(!isDropDownOpen) }}>
                                                        <img style={{ width: 15, height: 15, marginTop: "8px", cursor: "pointer" }} src={chevronDown} />
                                                        <div style={{ backgroundColor: "#FFFFFF", width: 85, padding: "2px 10px", color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 32, cursor: "pointer" }}>
                                                            <div style={{ color: "#636363", fontWeight: 400 }}>{discountType == "percent" ? "Percentage" : "Amount"}</div>
                                                        </div><div style={{ color: "#636363", fontWeight: 400, marginTop: "3px" }}>|</div>
                                                    </div>
                                                </Dropdown>
                                                <Input type="number" bordered={null} placeholder={`${discountType == 'percent' ? 'Enter %' : 'Enter Amount'}`} value={isDiscountValue} onChange={(e) => { calcDiscount(e) }} />
                                            </div>
                                        </div>

                                        {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? (<></>) : <div className='save-border-line' style={{ width: "80%", marginTop: "5px" }}></div>}

                                    </div>
                                    <div className='save-border-line'></div>

                                    <div>
                                        <div className='input-text-headings '>Fee after discount</div>
                                        <div style={{ display: "flex" }}>
                                            <div className='font-text' style={{ marginTop: "2px" }}> ₹ </div><div><Input readOnly bordered={null} placeholder='Enter amount' value={discountedPrice} /></div></div>
                                        <div className='save-border-line' style={{ marginTop: "5px" }}></div>

                                    </div>

                                </div>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: 10, gap: 50 }}>
                                    <div>
                                        <Button loading={loader} type='link' style={{
                                            fontSize: "14px", color: "#FF414D",
                                        }} onClick={() => {
                                            setEditValue(false)

                                        }}> CANCEL</Button>
                                    </div>
                                    <Button loading={loader} type='link' style={{ fontSize: "14px" }} onClick={() => {
                                        if (courseTypeName == 'studyMaterials') {
                                            setLoader(true)
                                            postAddFeeStudyMaterial(
                                                discountType, isDiscountValue, totalFee,
                                                () => { setEditValue(false) },
                                                () => { getSpecificSMData(() => { setAddFeeTermPlan(false), setLoader(false) }) }
                                            )
                                        } else if (courseTypeName == 'videoResources') {
                                            setLoader(true)
                                            postAddFeeStudyMaterial(
                                                discountType, isDiscountValue, totalFee,
                                                () => { setEditValue(false) },
                                                () => { getSpecificVideoCourseData(() => { setAddFeeTermPlan(false), setLoader(false) }) }
                                            )

                                        }
                                        else {
                                            setLoader(true)
                                            postCreateFeeRegistration(
                                                totalFee, minRegisterFee, discountType, isDiscountValue,
                                                () => { setEditValue(false), setLoader(false) },
                                                () => { getSpecificCourseData(() => { setAddFeeTermPlan(false) }) }
                                            )
                                        }

                                    }}><CheckCircleOutlined style={{ marginRight: "7px" }} /> SAVE</Button>
                                </div>
                            </div>}
                        <div className='fee-promo-card-details border-radius scroll-bar-universal' style={{ width: '100%' }}>
                            <div className='promo-code-text' style={{ marginLeft: 5, fontSize: "18px", marginTop: "3px" }}>Promotional offers</div>
                            <div>
                                {/* <div className=' m-t-10 p-10'>
                                    <div style={{ display: "flex", gap: 30 }}>
                                        <div><img src={disocuntImg} style={{ height: "65px", width: "65px" }} /></div>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className='promo-code-text'>App discounts</div>
                                                {addAppCode ? (

                                                    <>
                                                        {!isEdit ? <div className='app-btn cursor-pointer' style={{ marginRight: "20px" }} onClick={() => { setEdit(true) }}>SAVE</div> : <div className='app-btn cursor-pointer' onClick={() => { setEdit(false) }}>EDIT DETAILS</div>}
                                                    </>) : null}

                                            </div>
                                            <div style={{ fontSize: "11px", color: "#636363" }}>Your students will get additional discount for registering through the app.</div>
                                            {!addAppCode ? <div className='app-btn cursor-pointer' onClick={() => { setAppCode(true) }}><PlusCircleOutlined /><h> ADD APP DISCOUNTS</h></div> :
                                                (<>
                                                    {!isEdit ?
                                                        <div >
                                                            <div style={{ fontSize: "11px", color: "#8C8C8C", fontWeight: "bold" }}>Discount</div>
                                                            <div style={{ display: "flex", marginLeft: "-10px" }}>
                                                                <Select className='font-color-bold' suffixIcon={<CaretDownOutlined style={{ fontSize: "13px" }} />} bordered={null} defaultValue="Percentage" style={{ width: '200px', }} >
                                                                    <Option className='font-color-bold' value="percent">Percentage</Option>
                                                                    <Option className='font-color-bold' value="amount">Amount</Option>
                                                                </Select><h className='font-color-bold' style={{ marginTop: "2px" }}>|</h>
                                                                <Input bordered={null} placeholder='Enter %' />
                                                            </div>
                                                            <div className='save-border-line' style={{ width: "180px" }}></div>

                                                        </div> : <div className='discounted-text'>10% off</div>}
                                                </>)}
                                        </div>
                                    </div>
                                    <div className='border-line'></div>

                                </div> */}
                                {specificCourseDataContainer?.course?.offers?.length == 0 || specificCourseDataContainer?.offers?.length == 0 ? <div style={{ marginTop: "50px" }}>
                                    <div className="r-c-c-c" style={{ height: 'fit-content' }}>
                                        <img
                                            style={{ width: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '25%' : '30%', height: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '25%' : "30%", marginBottom: "20px" }}
                                            src={require("../../Assets/test/no-subjects.svg").default}
                                            alt=""
                                        />
                                        <div className="r-c-c-c">
                                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No promotional offers found</h1>
                                            <p style={{ color: "#636363", fontFamily: "roboto" }}>
                                                You can create promotional offers to give discounts to this course.
                                            </p>
                                        </div>
                                    </div>
                                </div> :
                                    (<>
                                        <div style={{ display: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? "flex" : '', flexWrap: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? 'wrap' : '', justifyContent: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? "space-between" : '', width: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? "100%" : '' }}>
                                            {offers?.map((item, index) => {
                                                const offerTag = getOfferTag(item);
                                                return (
                                                    <>{!downOutlined[index] ?
                                                        (<div className='m-t-10' onClick={() => { setSelectedOfferId(item.id); setIsOfferDrawerVisible(true); }} style={{ width: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? "47%" : '', cursor: 'pointer' }}>
                                                            <div style={{ display: "flex", gap: 20, }}>
                                                                <img src={getOfferIcon(item)} style={{ height: "65px", width: "65px" }} />
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", position: "relative" }}>
                                                                    <div style={{width:'64%'}}>
                                                                        <Typography.Text ellipsis={{ tooltip: true, color: "#636363" }}><Information bold={true}>{item.name}</Information></Typography.Text>
                                                                        <div className='r-c-fs'>
                                                                            <div className='r-c-fs'>
                                                                                <Typography.Text ellipsis={{ tooltip: true }} >
                                                                                    <Information style={{ fontWeight: 700, color: "#636363" }}>{item.discountType === 'amount' ? `₹${item.discount}` : `${item.discount}%`}</Information>
                                                                                </Typography.Text>
                                                                                <Information>&nbsp;off</Information>
                                                                            </div>
                                                                            {item.code
                                                                                ? <>
                                                                                    <Information style={{ margin: "0 0.5rem" }}>|</Information>
                                                                                    <Typography.Paragraph style={{ marginBottom: '0', color: "#636363" }} ellipsis={{ rows: 1, tooltip: true }}><Information>Promo code:</Information><Information style={{ fontWeight: 700, color: "#636363", marginRight: 5 }}>{` ${item.code}`}</Information></Typography.Paragraph>
                                                                                </>
                                                                                : null
                                                                            }
                                                                        </div>

                                                                        {item.startDate || item.endDate ? <div className='r-c-fs' style={{ position: "absolute" }}>
                                                                            <Information>Offer</Information>
                                                                            {item.startDate
                                                                                ? <><Information>&nbsp;starting from&nbsp;</Information><Information style={{ fontWeight: 700 }}>{moment(item.startDate).format('DD/MM/YYYY')}</Information></>
                                                                                : null
                                                                            }
                                                                            {item.endDate
                                                                                ? <><Information>&nbsp;ends on&nbsp;</Information><Information style={{ fontWeight: 700 }} >{moment(item.endDate).format('DD/MM/YYYY')}</Information></>
                                                                                : null
                                                                            }
                                                                        </div> : null}
                                                                        <div className='course-offer-type-tag' style={{ background: offerTag.offerTagBackground, color: offerTag.offerTagTextColor }}>
                                                                            <span>{offerTag.offerTagText}</span>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        hasMarketingPrivilege ? <></> :
                                                                            <div style={{ display: "flex", width: "36%", justifyContent: "flex-end", gap: "1rem" }}>
                                                                                <div style={{
                                                                                    color: "#1089FF",
                                                                                    fontWeight: 700,
                                                                                }} onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setIsPublishModalVisible(true);
                                                                                    setSelectedOfferId(item?.id);
                                                                                    setIsPublish(item?.published);
                                                                                }}>{!item?.published ? 'PUBLISH' : 'UNPUBLISH'}</div>
                                                                                <div style={{
                                                                                    color: "#FF414D",
                                                                                    fontWeight: 700,
                                                                                }} onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setSelectedOfferId(item?.id);
                                                                                    setIsModalVisible(true);
                                                                                }}> DELETE</div>
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className='border-line'></div>
                                                        </div>
                                                        ) :
                                                        (<div style={{ width: "100%", marginTop: 10 }}>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                <div style={{ width: "100%" }}>
                                                                    <div className='promo-edit-text'>Promotional offer title <span style={{ color: "red" }}>*</span></div>
                                                                    <div style={{ marginLeft: "-10px", }}>
                                                                        <Input bordered={null} placeholder='Enter title' value={promoCodeTitle} onChange={(e) => { setPromoCodeTitle(e.target.value) }} /></div>
                                                                </div>
                                                                <div style={{ display: "flex", width: courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? '10%' : "25%", justifyContent: "space-between", marginLeft: 5 }}>


                                                                    <Tooltip placement="top" overlay={!item?.published ? 'Publish' : 'Unpublish'}>
                                                                        <div
                                                                            style={{ cursor: "pointer", width: "30px", height: "30px" }}
                                                                            onClick={() => {
                                                                                setIsPublishModalVisible(true);
                                                                                setSelectedOfferId(item?.id);
                                                                                setIsPublish(item?.published);
                                                                            }}
                                                                        >
                                                                            <img src={!item?.published ? publish : unPublish} style={{}} alt={!item?.published ? 'Publish' : 'Unpublish'} />
                                                                        </div>
                                                                    </Tooltip>

                                                                    <Tooltip placement="top" overlay="Delete">
                                                                        <div
                                                                            style={{ color: "#FF414D", fontSize: "18px", cursor: "pointer", marginTop: "-3px" }}
                                                                            onClick={() => {
                                                                                setSelectedOfferId(item?.id);
                                                                                setIsModalVisible(true);
                                                                            }}
                                                                        >
                                                                            <DeleteOutlined />
                                                                        </div>
                                                                    </Tooltip>
                                                                    {/* <div style={{ cursor: "pointer", marginLeft: 5 }}
                                                                        onClick={() => { toggleCard(index) }}
                                                                    ><img src={downArrow} /></div> */}


                                                                </div>
                                                                {/* <div style={{ display: "flex", }}>
                                                                <span style={{ color: "#FF414D", fontSize: "15px", marginRight: "15px", cursor: "pointer" }} onClick={() => {
                                                                    setSelectedOfferId(item?.id)
                                                                    setIsModalVisible(true)
                                                                }}>
                                                                    <img src={closeOutlined} />
                                                                </span>
                                                                <span style={{ cursor: "pointer" }} onClick={() => { toggleCard(index) }} ><img src={purpuleUp} /></span>

                                                            </div> */}
                                                            </div>
                                                            <div className='border-line' style={{}}></div>

                                                            <div className='promo-edit-text m-t-10'>Offer details</div>
                                                            <div style={{ marginLeft: "-10px" }}><Input bordered={null} placeholder='Enter offer details' value={promoCodeDetails} onChange={(e) => { setPromoCodeDetails(e.target.value) }} /></div>
                                                            <div className='border-line'></div>
                                                            <div style={{ display: "flex", gap: 10, marginTop: "10px", width: "100%" }}>
                                                                <div>
                                                                    <div className='promo-edit-text'>Start date</div>
                                                                    <DatePicker bordered={null} allowClear={false} placeholder={"Select start date"}
                                                                        style={{ marginLeft: "-10px", width: 200 }}
                                                                        value={startDate ? moment(startDate, 'DD/MM/YY') : null}
                                                                        format={'DD/MM/YY'}
                                                                        disabledDate={disabledDate}
                                                                        onChange={(date, dateString) => { setStartDate(dateString), setEndDate(dateString) }}
                                                                    />
                                                                    <div className='border-line'></div>
                                                                </div>
                                                                <div>
                                                                    <div className='promo-edit-text'>End date</div>
                                                                    <DatePicker
                                                                        bordered={null} allowClear={false} placeholder={"Select end date"}
                                                                        style={{ marginLeft: "-10px" }}
                                                                        value={endDate ? moment(endDate, 'DD/MM/YY') : null}
                                                                        format={'DD/MM/YY'}
                                                                        disabledDate={disabledEndDate}
                                                                        onChange={(date, dateString) => { setEndDate(dateString) }}
                                                                    />

                                                                    <div className='border-line'></div>
                                                                </div>
                                                                <div>
                                                                    <div className='promo-edit-text' style={{ marginLeft: "10px" }}>Discount <span style={{ color: "red" }}>*</span></div>
                                                                    <div style={{ display: "flex", marginLeft: "10px", }}>
                                                                        <Dropdown bordered={false} overlay={isPromoDropDownOpen ? PromoMenu : <></>} trigger={['click']} open={isPromoDropDownOpen} style={{ backgroundColor: "red" }}>
                                                                            <div style={{ display: "flex" }} onClick={(e) => { e.stopPropagation(); setIsPromoDropDown(!isPromoDropDownOpen) }}>
                                                                                <img style={{ width: 15, height: 15, marginTop: "8px", cursor: "pointer", marginRight: "5px" }} src={chevronDown} />
                                                                                <div style={{ backgroundColor: "#FFFFFF", width: 100, padding: "2px 10px", color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 32, cursor: "pointer" }}>
                                                                                    <div style={{ color: "#636363", fontWeight: 400 }}>{newDiscountType == "percent" ? "Percentage" : "Amount"}</div>
                                                                                </div><div style={{ color: "#636363", fontWeight: 400, marginTop: "3px", marginLeft: "-10px" }}>|</div>
                                                                            </div>
                                                                        </Dropdown>
                                                                        <Input bordered={null} style={{ width: 100 }} placeholder={`${newDiscountType == 'percent' ? 'Enter %' : 'Enter Amount'}`} value={discount == null ? 0 : discount} onChange={(e) => { setDiscount(e.target.value) }} />
                                                                    </div>
                                                                    <div className='border-line' ></div>
                                                                </div>
                                                            </div>
                                                            <div className='m-t-10' style={{ fontSize: "15px", fontWeight: "bold" }}>Need to enter promotional offer</div>
                                                            <div style={{ display: "flex" }}>
                                                                <div style={{ color: "#636363", fontSize: "13px", fontWeight: "normal" }}>Elevate user engagement with exclusive discounts, fostering excitement and loyalty on your platform.</div>
                                                                <div><Switch checked={isSwitch} style={{ color: '#1089FF' }} onChange={(checked) => { setValues(checked) }} /></div>
                                                            </div>
                                                            {isSwitch ?
                                                                (<>
                                                                    <div className='promo-edit-text m-t-10'>Promotional offer <span style={{ color: 'red' }}>*</span></div>
                                                                    <div style={{ marginLeft: "-10px" }}><Input bordered={null} placeholder='Enter promotional offer' value={code} onChange={(e) => { setCode(e.target.value) }} /></div></>) : null}

                                                            <div className='border-line'></div>

                                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: 50 }}>
                                                                <div>
                                                                    <Button type='link' style={{
                                                                        fontSize: "14px", color: "#FF414D",
                                                                    }} onClick={() => {
                                                                        toggleCard(index)

                                                                    }}> CANCEL</Button>
                                                                </div>
                                                                <Button loading={promoLoader} type='link' style={{ fontSize: "14px" }}
                                                                    disabled={!promoCodeTitle || (!discount && discount != null) || (isSwitch && !code)}
                                                                    onClick={() => {
                                                                        if (courseTypeName == 'studyMaterials') {
                                                                            setPromoLoader(true)
                                                                            postCreateLiveCourseOffers(
                                                                                '', study_promo_code,
                                                                                () => { toggleCard(index), setPromoLoader(false) },
                                                                                () => { getSpecificSMData(() => { setAddFeeTermPlan(false) }) }
                                                                            )
                                                                        } else if (courseTypeName == 'videoResources') {
                                                                            setPromoLoader(true)
                                                                            postCreateLiveCourseOffers(
                                                                                video_promo_code, '',
                                                                                () => {
                                                                                    setPromoLoader(false)
                                                                                },
                                                                                () => {
                                                                                    getSpecificVideoCourseData(() => {
                                                                                        setAddFeeTermPlan(false)

                                                                                    })
                                                                                }
                                                                            )
                                                                        } else {
                                                                            if (promoCodeTitle == '' || discount == '' || isSwitch == true && code == '') {

                                                                            } else {
                                                                                setPromoLoader(true)
                                                                                postCreateLiveCourseOffers(
                                                                                    '', promo_code,
                                                                                    () => { toggleCard(index), setPromoLoader(false) },
                                                                                    () => { getSpecificCourseData(() => { setAddFeeTermPlan(false) }) }
                                                                                )
                                                                            }
                                                                        }


                                                                    }}
                                                                >SAVE</Button>
                                                            </div>
                                                            <div className='border-line'></div>

                                                        </div>)
                                                    }
                                                    </>

                                                )
                                            })}
                                        </div>
                                    </>)}

                                <DeleteModalOffer
                                    chooseModal={isModalVisible}
                                    setChooseModal={setIsModalVisible}
                                    deleteOffer={deleteOffer}
                                    getSpecificCourseData={getSpecificCourseData}
                                    selectedOfferId={selectedOfferId}
                                    setAddFeeTermPlan={setAddFeeTermPlan}
                                    getSpecificVideoCourseData={getSpecificVideoCourseData}
                                    getSpecificSMData={getSpecificSMData}
                                    courseTypeName={courseTypeName} />
                                <PublishModal
                                    chooseModal={isPublishModalVisible}
                                    setChooseModal={setIsPublishModalVisible}
                                    patchPublishOffers={patchPublishOffers}
                                    getSpecificCourseData={getSpecificCourseData}
                                    selectedOfferId={selectedOfferId}
                                    isPublish={isPublish}
                                    setAddFeeTermPlan={setAddFeeTermPlan}
                                    togglePageLoader={togglePageLoader}
                                    getSpecificVideoCourseData={getSpecificVideoCourseData}
                                    getSpecificSMData={getSpecificSMData}
                                    courseTypeName={courseTypeName} />
                            </div>
                            {isOfferDrawerVisible ? (
                                <EditOfferDrawer
                                    handleClose={() => { setIsOfferDrawerVisible(false) }}
                                    open={isOfferDrawerVisible}
                                    courseDetails={{
                                        id: specificCourseDataContainer?.course?.id,
                                        name: specificCourseDataContainer?.course?.courseName || specificCourseDataContainer?.course?.name,
                                        type: courseTypeName == "liveCourses" ? "course" : courseTypeName == "videoResources" ? "videoResource" : courseTypeName == "studyMaterials" ? "studyMaterial" : courseTypeName == "onlineTestSeries" ? "testSeries" : null,
                                    }}
                                    offerId={selectedOfferId}
                                    handleSave={() => {
                                        const getFunction = courseTypeName == "liveCourses" ? getSpecificCourseData : courseTypeName == "videoResources" ? getSpecificVideoCourseData : courseTypeName == "studyMaterials" ? getSpecificSMData : getOnlineTestSeriesCourseDetails;
                                        togglePageLoader(true);
                                        getFunction(() => {
                                            setIsOfferDrawerVisible(false);
                                            setAddFeeTermPlan(false);
                                            togglePageLoader(false);
                                        });
                                    }}
                                >
                                </EditOfferDrawer>
                                // <>
                                //     <div className='p-10 '>
                                //         <div>
                                //             <div className='promo-edit-text'>Promotional offer title <span style={{ color: "red" }}>*</span></div>
                                //             <div style={{ marginLeft: "-10px" }}><Input bordered={null} placeholder='Enter title' value={newPromoCodeTitle} onChange={(e) => { setNewPromoCodeTitle(e.target.value) }} /></div>
                                //             <div className='border-line'></div>
                                //         </div>
                                //         {/* <div style={{ color: "#FF414D", fontSize: "15px", cursor: "pointer", }} onClick={() => { setIsPromoCodeClicked(false) }}> <img src={closeOutlined} /></div> */}
                                //         {/* <div onClick={() => { setIsPromoCodeClicked(false) }}><span style={{ color: "#FF414D", fontSize: "15px" }}> <CloseCircleOutlined style={{ cursor: "pointer" }} /></span></div> */}

                                //         <div className='promo-edit-text m-t-10'>Offer details</div>
                                //         <div style={{ marginLeft: "-10px" }}><Input bordered={null} placeholder='Enter offer details' value={newOfferDetail} onChange={(e) => { setNewOfferDetails(e.target.value) }} /></div>
                                //         <div className='border-line'></div>
                                //         <div style={{ display: "flex", gap: 10, marginTop: "10px", width: "100%" }}>
                                //             <div>
                                //                 <div className='promo-edit-text'>Start date</div>
                                //                 <DatePicker
                                //                     bordered={null}
                                //                     allowClear={false}
                                //                     placeholder={"Select start date"}
                                //                     style={{ marginLeft: "-10px" }}
                                //                     // value={moment(newStartDate, 'DD-MM-YYYY')}
                                //                     format={'DD/MM/YY'}
                                //                     disabledDate={disabledDate}

                                //                     onChange={(date, dateString) => { setNewStartDate(dateString), setNewEndDate(dateString) }}
                                //                 />
                                //                 <div className='border-line'></div>
                                //             </div>
                                //             <div>
                                //                 <div className='promo-edit-text'>End date</div>
                                //                 <DatePicker bordered={null} allowClear={false} placeholder={"Select end date"}
                                //                     style={{ marginLeft: "-10px" }}
                                //                     // value={moment(newEndDate, 'DD-MM-YYYY')}
                                //                     format={'DD/MM/YY'}
                                //                     disabledDate={disabledEndDateS}

                                //                     onChange={(date, dateString) => { setNewEndDate(dateString) }}
                                //                 />
                                //                 <div className='border-line'></div>
                                //             </div>
                                //             <div style={{ height: "15px" }}>
                                //                 <div className='promo-edit-text' style={{ marginLeft: "8px" }}>Discount <span style={{ color: "red" }}>*</span></div>
                                //                 <div style={{ display: "flex", marginLeft: "10px" }}>

                                //                     <Dropdown bordered={false} overlay={isPromoDropDownOpen ? PromoMenu : <></>} trigger={['click']} open={isPromoDropDownOpen} style={{ backgroundColor: "red" }}>
                                //                         <div style={{ display: "flex" }} onClick={(e) => { e.stopPropagation(); setIsPromoDropDown(!isPromoDropDownOpen) }}>
                                //                             <img style={{ width: 15, height: 15, marginTop: "8px", cursor: "pointer", marginRight: "5px" }} src={chevronDown} />
                                //                             <div style={{ backgroundColor: "#FFFFFF", width: 100, padding: "2px 10px", color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: 32, cursor: "pointer" }}>
                                //                                 <div style={{ color: "#636363", fontWeight: 400 }}>{newDiscountType == "percent" ? "Percentage" : "Amount"}</div>
                                //                             </div><div style={{ color: "#636363", fontWeight: 400, marginTop: "3px", marginLeft: "-10px" }}>|</div>
                                //                         </div>
                                //                     </Dropdown>
                                //                     <Input bordered={null} placeholder={`${newDiscountType == 'percent' ? 'Enter %' : 'Enter Amount'}`} style={{ width: "100px" }} value={newDiscount} onChange={(e) => { setNewDiscount(e.target.value) }} />
                                //                 </div>
                                //                 <div className='border-line'></div>

                                //             </div>


                                //         </div>
                                //         <div className='m-t-10' style={{ fontSize: "15px", fontWeight: "bold" }}>Need to enter promotional offer</div>
                                //         <div style={{ display: "flex" }}>
                                //             <div style={{ color: "#636363", fontSize: "13px", fontWeight: "normal" }}>Elevate user engagement with exclusive discounts, fostering excitement and loyalty on your platform.</div>
                                //             <div><Switch onChange={(checked) => { setIsSwitch(checked) }} /></div>
                                //         </div>
                                //         {isSwitch ? (<><div className='promo-edit-text m-t-10'>Promotional offer <span style={{ color: 'red' }}>*</span></div>
                                //             <div style={{ marginLeft: "-10px" }}><Input bordered={null} placeholder='Enter promotional offer' value={newPromoCode} onChange={(e) => { setNewPromoCode(e.target.value) }} /></div>
                                //             <div className='border-line'></div></>)
                                //             : (<></>)}

                                //         <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: 50 }}>
                                //             <Button type='link' style={{
                                //                 fontSize: "14px", color: "#FF414D",
                                //             }} onClick={() => {
                                //                 setIsPromoCodeClicked(false)

                                //             }}> CANCEL</Button>
                                //             <Button loading={promoLoader} type='link' style={{ fontSize: "14px" }}
                                //                 disabled={newPromoCodeTitle == '' || newDiscount == '' || isSwitch == true && newPromoCode == ''}
                                //                 onClick={() => {
                                //                     if (courseTypeName == 'studyMaterials') {
                                //                         setPromoLoader(true)
                                //                         postCreateLiveCourseOffers(
                                //                             study_new_promo_code, '',
                                //                             () => {
                                //                                 setIsPromoCodeClicked(false), setIsPromoCodeClicked(false), clearValues(),
                                //                                     setPromoLoader(false)
                                //                             },
                                //                             () => {
                                //                                 getSpecificSMData(() => {
                                //                                     setAddFeeTermPlan(false)

                                //                                 })
                                //                             }
                                //                         )

                                //                     } else if (courseTypeName == 'videoResources') {
                                //                         setPromoLoader(true)
                                //                         postCreateLiveCourseOffers(
                                //                             video_new_promo_code, '',
                                //                             () => {
                                //                                 setIsPromoCodeClicked(false), setIsPromoCodeClicked(false), clearValues(),
                                //                                     setPromoLoader(false)
                                //                             },
                                //                             () => {
                                //                                 getSpecificVideoCourseData(() => {
                                //                                     setAddFeeTermPlan(false)

                                //                                 })
                                //                             }
                                //                         )
                                //                     }

                                //                     else {
                                //                         if (promoCodeTitle == '' || discount == '' || isSwitch == true && code == '') {

                                //                         } else {
                                //                             setPromoLoader(true)
                                //                             postCreateLiveCourseOffers(
                                //                                 new_promo_code, '',
                                //                                 () => {
                                //                                     setIsPromoCodeClicked(false), setIsPromoCodeClicked(false), clearValues(),
                                //                                         setPromoLoader(false)
                                //                                 },
                                //                                 () => {
                                //                                     getSpecificCourseData(() => {
                                //                                         setAddFeeTermPlan(false)

                                //                                     })
                                //                                 }
                                //                             )
                                //                         }
                                //                     }


                                //                 }}
                                //             >SAVE</Button>
                                //         </div>
                                //     </div>

                                // </>
                            ) : null}
                            {hasMarketingPrivilege ? null : (specificCourseDataContainer?.offers?.length == 0 || specificCourseDataContainer?.course?.offers?.length == 0 ? (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button style={{ border: '1px solid #636363' }}
                                        onClick={() => {
                                            setSelectedOfferId(null);
                                            setIsOfferDrawerVisible(true);
                                            // setEditValue(false);
                                        }}>
                                        <div style={{ color: "#636363", fontSize: "14px", fontWeight: '400px' }}>ADD</div>
                                    </Button>
                                </div>
                            ) : (
                                <div className='edit-text'
                                    style={{
                                        opacity: isOfferDrawerVisible ? 0.5 : 1,
                                        cursor: isOfferDrawerVisible ? 'not-allowed' : 'pointer'
                                    }}
                                    onClick={() => {
                                        setSelectedOfferId(null);
                                        setIsOfferDrawerVisible(true);
                                        // setEditValue(false);
                                    }}>
                                    <PlusCircleOutlined /> ADD PROMOTIONAL OFFERS
                                </div>
                            ))}
                        </div>


                    </div>
                    {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? (<></>) : (<>
                        <div className='fee-payment-term border-radius ' >
                            <div style={{ fontSize: "18px", fontWeight: "bold" }}>Fee payment term</div>
                            <div style={{ fontSize: "13px", color: "#636363" }}>Explore our website's Fee Payment section for clear and concise terms outlining deadlines, accepted payment methods, and any penalties for late payments. Ensure smooth transactions and mutual understanding between parties with our transparent fee payment policies.</div>
                            <div className='scroll-bar-universal universal-scroll-height'>
                                {feeDetails?.length == 0 ? (<div style={{ marginTop: "150px" }}><div className="r-c-c-c" style={{ height: 'fit-content' }}>
                                    <img
                                        style={{ width: '50%', height: "50%", marginBottom: "20px" }}
                                        src={require("../../Assets/test/no-subjects.svg").default}
                                        alt=""
                                    />
                                    <div className="r-c-c-c">
                                        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No fee term plan found</h1>
                                        <p style={{ color: "#636363", fontFamily: "roboto" }}>
                                            You can create fee term plan .
                                        </p>
                                    </div>
                                </div></div>) :
                                    (<div >{feeDetails?.map((item, index) => {
                                        return (
                                            <div className=''>
                                                <>
                                                    {addFeeTermPlan && index == feeDetails?.length - 1 ? (<> <FeeTermPlanCard
                                                        getSpecificCourseData={getSpecificCourseData}
                                                        specificCourseDataContainer={specificCourseDataContainer}
                                                        setAddFeeTermPlan={setAddFeeTermPlan}
                                                        addFeeTermPlan={addFeeTermPlan}
                                                        feeTerm={true}
                                                        setFeeDetails={setFeeDetails}
                                                        feeDetails={feeDetails}
                                                        parentIndex={index}
                                                        item={item}
                                                        isFilterClicked={isFilterClicked}
                                                        toggleFeeCard={toggleFeeCard}
                                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                                    />
                                                    </>) : (<>
                                                        {!addFeeTerm[index] ?
                                                            <div className='m-t-10 scroll-bar-universal '>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", gap: 10 }}>
                                                                        <div style={{ fontSize: "14px", fontWeight: "bold" }}>{item?.billingPlan == 'Customize' ? item?.planName : item?.billingPlan} </div>
                                                                        {item?.defaultPlan == false ? null :
                                                                            <div style={{ height: "20px", backgroundColor: "#E7F3FF", width: "110px", border: "1px solid #E7F3FF", textAlign: "center", borderRadius: "17px" }}>
                                                                                <div style={{ fontSize: "12px", color: "#1089FF", fontWeight: "bold" }}>DEFAULT PLAN</div>
                                                                            </div>}
                                                                    </div>
                                                                    <div style={{ cursor: "pointer" }} onClick={() => { toggleFeeCard(index), setIsFilterClicked(index) }}>
                                                                        <img src={greyDownArrow} />
                                                                    </div>
                                                                </div>
                                                                <div ><span style={{ fontSize: "12px", color: "#636363" }}>No. of installments - </span><span style={{ fontSize: 12, color: "#191919", fontWeight: "500px" }}> {item?.installments?.length}</span><span style={{ fontSize: "12px", color: "#636363", marginLeft: "50px" }}>Total payable </span><span style={{ fontSize: 12, color: "#191919", fontWeight: "500px" }}>{item?.installmentTotal}</span></div>
                                                                <div className='border-line'></div>
                                                            </div>
                                                            :
                                                            <div className=' m-t-10'>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div>{item?.installments?.length} {item?.installments?.length == 1 ? 'Installment' : 'Installments'}</div>

                                                                    <div style={{ display: "flex" }}>
                                                                        {hasMarketingPrivilege ? <></> :
                                                                            <div style={{ marginRight: "10px", color: "#FF414D", fontSize: "14px", fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                                                                                setIsFilterClicked(isFilterClicked)

                                                                                setStoreInstallmentData(item)
                                                                                setInstallDeleteModal(true)
                                                                            }}> DELETE</div>
                                                                        }

                                                                        {/* {feeDetails?.length > 1 ? (<>
                                                                        {item?.defaultPlan == false ?
                                                                            <div style={{ marginRight: "10px", color: "#1089FF", fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    const updateFee = [...feeDetails];
                                                                                    updateFee[index].defaultPlan = true;
                                                                                    setFeeDetails(updateFee);

                                                                                }}>MAKE DEFAULT PLAN</div> :
                                                                            <div style={{ marginRight: "10px", color: "#FFA931", fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    const updateFee = [...feeDetails];
                                                                                    updateFee[index].defaultPlan = false;
                                                                                    setFeeDetails(updateFee);

                                                                                }}
                                                                            >REMOVE DEFAULT PLAN</div>}</>) : (<></>)} */}
                                                                        {hasMarketingPrivilege ? <></> :
                                                                            <div style={{ marginRight: "10px", color: "#594099", fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                                                                                onClick={() => {

                                                                                    postAddLiveCourseInstallMents(
                                                                                        item,
                                                                                        () => { toggleFeeCard(index) },
                                                                                        () => { getSpecificCourseData(() => { }); },
                                                                                    );
                                                                                }}>SAVE</div>
                                                                        }

                                                                        <div style={{ cursor: "pointer", marginTop: "-3px" }} onClick={() => { toggleFeeCard(index) }} ><img src={greyUpArrow} /></div>
                                                                    </div>
                                                                </div>

                                                                <FeeTermPlanCard
                                                                    getSpecificCourseData={getSpecificCourseData}
                                                                    specificCourseDataContainer={specificCourseDataContainer}
                                                                    setAddFeeTermPlan={setAddFeeTermPlan}
                                                                    addFeeTermPlan={addFeeTermPlan}
                                                                    feeTerm={true}
                                                                    setFeeDetails={setFeeDetails}
                                                                    feeDetails={feeDetails}
                                                                    parentIndex={index}
                                                                    item={item}
                                                                    isFilterClicked={isFilterClicked}
                                                                    toggleFeeCard={toggleFeeCard}
                                                                    hasMarketingPrivilege={hasMarketingPrivilege}
                                                                />
                                                                <div className='border-line'></div>

                                                            </div>}</>)}
                                                </>

                                            </div>
                                        )
                                    })}</div>)}

                            </div>
                            {!hasMarketingPrivilege && (
                                feeDetails?.length == 0 ? (
                                    <div className='btn2' style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            disabled={addFeeTermPlan}
                                            style={{ border: '1px solid #636363' }}
                                            onClick={() => {
                                                setFeeDetails([...feeDetails, {
                                                    id: '',
                                                    planName: '',
                                                    billingPlan: specificCourseDataContainer?.course?.billingPlan,
                                                    installments: [{
                                                        amount: null,
                                                        dueDate: null
                                                    }],
                                                    defaultPlan: true,
                                                    courseId: '',
                                                    hasDueDate: null,
                                                    installmentTotal: specificCourseDataContainer?.course?.discountType == "percent" ?
                                                        (Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100) - specificCourseDataContainer?.course?.registrationFee
                                                        : (specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount) - specificCourseDataContainer?.course?.registrationFee
                                                }]);
                                                setAddFeeTermPlan(true);
                                            }}
                                        >
                                            <div style={{ color: "#636363", fontSize: "14px", fontWeight: '400px' }}>ADD PLAN</div>
                                        </Button>
                                    </div>
                                ) : (
                                    <>
                                        {!addFeeTermPlan ? (
                                            <div className='edit-text-plan-btn'
                                                style={{
                                                    cursor: !addFeeTermPlan ? 'pointer' : 'not-allowed',
                                                    opacity: !addFeeTermPlan ? 1 : 0.5,
                                                    fontSize: 14
                                                }}
                                                onClick={() => {
                                                    if (!addFeeTermPlan) {
                                                        setFeeDetails([...feeDetails, {
                                                            id: '',
                                                            planName: '',
                                                            billingPlan: specificCourseDataContainer?.course?.billingPlan,
                                                            installments: [{
                                                                amount: null,
                                                                dueDate: null
                                                            }],
                                                            defaultPlan: false,
                                                            courseId: '',
                                                            hasDueDate: null,
                                                            installmentTotal: specificCourseDataContainer?.course?.discountType == "percent" ?
                                                                (Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100) - specificCourseDataContainer?.course?.registrationFee
                                                                : (specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount) - specificCourseDataContainer?.course?.registrationFee
                                                        }]);
                                                        setAddFeeTermPlan(true);
                                                        // toggleFeeCard(isFilterClicked)
                                                    }
                                                }}
                                            >
                                                <PlusCircleOutlined /> ADD PLAN
                                            </div>
                                        ) : (
                                            <div className='btn23'></div>
                                        )}
                                    </>
                                )
                            )}

                        </div></>)}

                    <DeleteInstallmentModal
                        chooseModal={isInstallDeleteModal}
                        setChooseModal={setInstallDeleteModal}
                        deleteInstallment={deleteInstallment}
                        getSpecificCourseData={getSpecificCourseData}
                        storeInstallmentData={storeInstallmentData}
                        setStoreInstallmentData={setStoreInstallmentData}
                        toggleFeeCard={toggleFeeCard}
                        isFilterClicked={isFilterClicked}

                    />

                </div>
            </div>
            <PageDataLoader visible={pageLoader} />

        </div>
    )
}


const mapStateToProps = (state) => {
    const { specificCourseDataContainer, newCourseId, courseTypeName } = state.courseManagement;
    const { user } = state.session;
    return { specificCourseDataContainer, newCourseId, courseTypeName, user };
};

const mapDispatchToProps = (dispatch) => ({
    deleteOffer: (id, callBack, successCallBack) => dispatch(deleteOffer(id, callBack, successCallBack)),
    deleteInstallment: (storeInstallmentData, callBack, successCallBack) => dispatch(deleteInstallment(storeInstallmentData, callBack, successCallBack)),
    getSpecificCourseData: (callback) => dispatch(getSpecificCourseData(callback)),
    postCreateFeeRegistration: (totalFee, minRegisterFee, discountType, isDiscountValue, callback, succesCallback) => dispatch(postCreateFeeRegistration(totalFee, minRegisterFee, discountType, isDiscountValue, callback, succesCallback)),
    postCreateLiveCourseOffers: (new_promo_code, promo_code, callBack, successCallBack) => dispatch(postCreateLiveCourseOffers(new_promo_code, promo_code, callBack, successCallBack)),
    postAddLiveCourseInstallMents: (feeDetails, callback, successCallBack) => dispatch(postAddLiveCourseInstallMents(feeDetails, callback, successCallBack)),
    patchPublishOffers: (id, publish, callBack, successCallBack) => dispatch(patchPublishOffers(id, publish, callBack, successCallBack)),
    postAddFeeStudyMaterial: (discountType, discount, totalFee, callback, successCallBack) => dispatch(postAddFeeStudyMaterial(discountType, discount, totalFee, callback, successCallBack)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
    getOnlineTestSeriesCourseDetails: (callBack) => dispatch(getOnlineTestSeriesCourseDetails(callBack)),



});

export default connect(mapStateToProps, mapDispatchToProps)(FeeDetails);