import React from "react";
import { connect } from "react-redux";
import Notification from "services/Notification";
import { CalendarSm, SummaryCard } from "components";
import Typography from 'antd/lib/typography';
import Empty from 'antd/lib/empty';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import 'antd/lib/typography/style/index';
import 'antd/lib/empty/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import { Color } from "services";

import { withRouter } from "react-router-dom";
import {
  getSchedule,
  startClass,
  updateActiveClass,
  updateLiveFilterParams,
  clearLiveFilterParams
} from "actions/today";
import { getClassResources } from "actions/classResources";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Select } from "antd";
import {
  searchUser,
} from '../actions/calendar';

const { Title } = Typography;
const {Option} = Select;

class LiveSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClass: "",
      currentTime: moment().format("HH:mm:ss")
    };
  }
  componentDidMount() {
    const date = new Date();
    this.props.getSchedule("live");
  }

  componentWillUnmount(){
    this.props.clearLiveFilterParams()
  }

  handleDateChange(date) {
    this.props.updateLiveFilterParams(date.format("YYYY-MM-DD"), 'date')
    this.props.getSchedule("live");
  }

  handleButtonClick(item) {
    const { startClass, getClassResources, updateActiveClass } = this.props;
    updateActiveClass(item);

    this.setState({ selectedClass: item.id });
    const extraData = {
      conferenceId: item.id,
      topic: item.subject.name,
      subject: item.subject.name,
      standard: item.standards,
      roomName: item.roomName,
      date: this.state.date
    };

    if (item.visibility === "Completed") {
      if (!item.conference.length) {
        Notification.error("Error", "No data available for selected class");
        return;
      }
      getClassResources(
        item.conference[0].id,
        this.props.history,
        extraData,
        this.props.user.orgId
      );
    } else {
      startClass(item.id, this.props.history, extraData);
    }
  }

  allowed(serviceName, userAccess = null) {
    const { services, access } = this.props.user;
    if (!userAccess) {
      userAccess = serviceName;
    }
    return services.includes(serviceName) && access.includes(userAccess);
  }

  handleStandardSelect(val){
    this.props.updateLiveFilterParams(val, "standardId");
    this.props.getSchedule("live");
  }

  handleTacherSearch(teacher, obj){
    const { updateLiveFilterParams} = this.props;
    updateLiveFilterParams(teacher, 'teacherName');
    updateLiveFilterParams(obj.id, 'teacherId');
    if(teacher === ""){
      this.handleTeacherFilter(teacher, obj);
    }
    
  }


  handleTeacherFilter(teacher, obj) {
    const {getSchedule, updateLiveFilterParams} = this.props;
    if(obj.id === -1) return;
    updateLiveFilterParams(teacher, 'teacherName');
    updateLiveFilterParams(obj.id, 'teacherId');
    getSchedule();
  }

  render() {
    const { schedules, startLoader, updateClassLoader, user, fetchScheduleLoader, standards, teachers,
      liveFilterParams } = this.props;
    const { selectedClass } = this.state;

    return (
      <div style={{height: "100%"}}>
      <Row style={{backgroundColor:'#F9F9F9', padding: 10, borderRadius: 10}} className="m-b-20">
        <Title style={{color: Color.darkGrey, margin:0}} level={3}>Live Classes</Title>
          {!user.role.includes("Student") ? <Select
            className="m-l-20"
            value={liveFilterParams?.standardId}
            onSelect={val => this.handleStandardSelect(val)}
            style={{borderRadius:30, width: '200px', color:Color.primary, fontSize:'1.2em'}}
          >
            <Option key={-1} value="-1">All Classes</Option>
          {standards && standards.map((item, index) => <Option key={index} value={item.id}>{item.std} - {item.section}</Option>)}

          </Select> : null}
          {user.role.length === 1 && user.role.includes("Teacher")  ? null : <AutoComplete
            options={teachers}
            onSelect={(value, obj) => this.handleTeacherFilter(value, obj)}
            onChange={(value, obj) => this.handleTacherSearch(value,obj)}
            onSearch={(query) => this.props.searchUser(query)}
            style={{marginLeft: 10, border:'none', width:'15%',  color:Color.primary,
              fontSize:16, fontWeight:600, borderRadius:30, width: 220}}
              >
              <Input.Search style={{ border:'none', borderRadius:30}} placeholder="Search by teacher..." allowClear/>
          </AutoComplete>}
      </Row>
      <Row style={{ height: "90%" }}>
        <Col
          xs={{ span: 8 }}
          sm={{ span: 16 }}
          md={{ span: 16 }}
          lg={{ span: 16 }}
          xl={{ span: 18 }}
          xxl={{ span: 18 }}
        >
          
          {!fetchScheduleLoader ? 
          <Row>
            { !schedules || !schedules.length > 0 ? (
              <Col span={24} style={{ marginTop: 200 }}>
                <Empty
                  description={<span>No schedule found for selected date</span>}
                />
              </Col>
            ) : (
              schedules.map((item, index) => {
                // const buttonVisible =
                //   item.visibility == "Completed" &&
                //   this.allowed("digital-library", "class-resource")
                //     ? true
                //     : false;
                return (
                  <Col
                    key={index}
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 6 }}
                  >
                    <SummaryCard
                      key={index}
                      color={item.subject?.color}
                      loading={
                        item.id === selectedClass
                          ? item.visibility === "Completed"
                            ? updateClassLoader
                            : startLoader
                          : false
                      }
                      topic={item.subject ? item.subject.name : ""}
                      subject={item.subject ? item.subject.name : ""}
                      teacher={item.teacher ? item.teacher.name : ""}
                      days={item.weekdays.map((item, index, array) => (
                        <React.Fragment key={index}>
                          {" "}
                          {item.charAt(0).toUpperCase()}
                          {item.slice(1, 3)}{" "}
                          {index === array.length - 1 ? "" : "|"}{" "}
                        </React.Fragment>
                      ))}
                      standard={
                        item.standards
                          ? item.standards.map((item, index, array) => (
                              <React.Fragment key={index}>
                                {" "}
                                {item.std}-{item.section}{" "}
                                {index === array.length - 1 ? "" : "|"}{" "}
                              </React.Fragment>
                            ))
                          : null
                      }
                      time={`${moment(item.startTime, "HH:mm:ss").format(
                        "HH:mm"
                      )} - ${moment(item.endTime, "HH:mm:ss").format("HH:mm")}`}
                      buttonType={
                        user.role.includes("Student") &&
                        item.visibility === "Resume"
                      } // To enable join class just add the condition item.visibility == "Resume"  ||
                      buttonName={
                        item.visibility === "Completed" ? "Recap" : "Join Class"
                      }
                      roomname={item.roomname}
                      labelName={
                        item.visibility === "Resume"
                          ? "On Going"
                          : item.visibility
                      }
                      onClick={() => this.handleButtonClick(item)}
                    />
                  </Col>
                );
              })
            )}
          </Row>
          : <div className="r-c-c" style={{height: "100%"}}><LoadingOutlined style={{color: Color.primary, opacity: 0.5, fontSize: "10em"}}/> </div> }
        </Col>
        <Col
          xs={{ span: 16 }}
          sm={{ span: 8 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
          style={{
            backgroundColor: Color.lightprimary,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div style={{ marginTop: 20 }}>
            <CalendarSm onSelect={date => this.handleDateChange(date)} />
          </div>
        </Col>
      </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { schedules, startLoader, fetchScheduleLoader, standards, liveFilterParams } = state.today;
  const {teachers} = state.calendar;
  const { updateClassLoader } = state.classResources;
  const { user } = state.session;
  return {
    startLoader,
    schedules,
    user,
    updateClassLoader,
    fetchScheduleLoader,
    standards,
    teachers,
    liveFilterParams
  };
};

const mapDispatchToProps = dispatch => ({
  updateActiveClass: payload => dispatch(updateActiveClass(payload)),
  getSchedule: (mode) => dispatch(getSchedule(mode)),
  startClass: (scheduleId, history, extraData) =>
    dispatch(startClass(scheduleId, history, extraData)),
  getClassResources: (scheduleId, history, extraData, orgId) =>
    dispatch(getClassResources(scheduleId, history, extraData, orgId)),
  updateLiveFilterParams: (val, key) => dispatch(updateLiveFilterParams(val, key)),
  clearLiveFilterParams: () => dispatch(clearLiveFilterParams()),
  searchUser: (query) => dispatch(searchUser(query)),
});

const TodaySchedule = withRouter(LiveSchedule);

export default connect(mapStateToProps, mapDispatchToProps)(LiveSchedule);
