import React,{ useState, useEffect } from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import {  } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { showTextEditor, renderEditorContent, storeEditorRemovedImgs, storeEditoraddedImgs, deleteQuillImgs, postComment } from 'actions/practiseTest';
import {FormulaEditorNew, TestFormulaEditor} from 'components';
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdPublish, MdKeyboardBackspace } from "react-icons/md";
import '../styles.css';
import { Color } from 'services';
import { Col, Row , Typography} from 'antd';
import "react-quill/dist/quill.snow.css";

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;


function TextEditorModal ({history, textEditorBool, showTextEditor, renderEditorContent, textEditorName, user,
  storeEditorRemovedImgs, generateQuesRemovedImgs, generateQuesaddedImgs, storeEditoraddedImgs, deleteQuillImgs,
  deleteQuillImgsBool, userType, postParams, generateCommentText, selectedCardType, selectedTest, postComment}) {
    console.log('textEditorName.tags_+_+_+_+', textEditorName);

    useEffect(() => {
      FormulaEditorNew.uploadUrl = '/test/upload-image';
    }, [])
    return (
      <Modal
        centered
        className='modal-round-corner'
        style={{padding:0}}
        width={700}
        visible={textEditorBool}
        closable={false}
        maskClosable={false}
        onOk={null}
        footer={null}
      >
        <div className='test-texteditor-wrapper'>
          <div className='text-sm bold-600 color-black m-b-20'>Text editor</div>
          {/* <TestFormulaEditor
              // id={id}
              data={textEditorBool ? textEditorName.tags : ''}
              imagesList={{arr:generateQuesaddedImgs, callback: (res) => {
                storeEditoraddedImgs(res.arr)
                // console.log('generateQuesaddedImgs_+_+', generateQuesaddedImgs);
              }}}
              deletedImages={{arr:generateQuesRemovedImgs, callback: (res) => {
                storeEditorRemovedImgs(res.arr);
                // console.log('generateQuesRemovedImgs',generateQuesRemovedImgs);
              }}}
              imgPath={"/uploads/testQuestion/"}
              onChange={(value) => {
                    renderEditorContent(textEditorName, value);
              }}
              user={user}
              textEditorBool={textEditorBool}
              deleteQuillImgsBool={deleteQuillImgsBool}
              closeModal={() => {
                  if(generateQuesRemovedImgs.length){
                     deleteQuillImgs(() => {
                       showTextEditor(textEditorName);
                       storeEditorRemovedImgs([])
                     })
                  }else{
                    showTextEditor(textEditorName);
                  }
              }}
          /> */}
          <FormulaEditorNew
              postComment={postComment}
              postParams={postParams} 
              generateCommentText={generateCommentText} 
              selectedCardType={selectedCardType} 
              selectedTest={selectedTest}
              id={"statement"}
              data={textEditorBool ? textEditorName.tags : ''}
              disableOptAdd={false}
              imagesList={generateQuesaddedImgs}
              deletedImages={generateQuesRemovedImgs}
              // imgPath={"/uploads/testQuestion/"}
              uploadUrl={'/test/upload-image'}
              deleteImages={(imgList, index) => {}}
              resetEditor={() => showTextEditor(false)}
              onChange={(value, a1, a2, a3) => {
                renderEditorContent(textEditorName, value,(identifierIndex !== null && identifierIndex !== undefined)?identifierIndex:null,(identifierName !== null && identifierName !== undefined)?identifierName:null)
                // console.log('datatat',textEditorName,value)
              }}
              user={user}
              addOption={{}}
              userType={userType}
              textEditorNameKey={Object.keys(textEditorName)?.length?textEditorName.key:''}
          />
        </div>
      </Modal>
  );
}


const mapStateToProps = state => {
  const {textEditorBool, textEditorName, generateQuesRemovedImgs, generateQuesaddedImgs, deleteQuillImgsBool, 
    userType, postParams, generateCommentText, selectedCardType, selectedTest} = state.practiseTest;
  const { user } = state.session;
  const {identifierIndex,identifierName} = state.testAdmin;
  return {textEditorBool, textEditorName, user, generateQuesRemovedImgs, generateQuesaddedImgs,
    deleteQuillImgsBool, userType, postParams, generateCommentText, selectedCardType, selectedTest,identifierIndex,identifierName};
};

const mapDispatchToProps = dispatch => ({
  showTextEditor: (name) => dispatch(showTextEditor(name)),
  renderEditorContent: (name, value) => dispatch(renderEditorContent(name, value)),
  storeEditorRemovedImgs: (arr) => dispatch(storeEditorRemovedImgs(arr)),
  storeEditoraddedImgs: (arr) => dispatch(storeEditoraddedImgs(arr)),
  deleteQuillImgs: (callback) => dispatch(deleteQuillImgs(callback)),
  postComment: (params, callback) => dispatch(postComment(params, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditorModal);
