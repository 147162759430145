import React, { useState } from 'react';
import "./userHomeStyles.css"
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FooterSocialMediaModal from './footerSocialMediaModal';

function Footer({ userMarketPlaceHomeData }) {
    const history = useHistory()
    const [isSocialMedia, setIsSocialMedia] = useState(false);
    const [socialMediaData, setSocialMediaData] = useState(null);
    function handleClick(number) {
        window.location.href = `tel:${number}`;
    }
    const handleSocialMediaClick = (item) => {
        if (item?.urls?.length > 1) {
            setIsSocialMedia(true)
            setSocialMediaData(item)
        } else {
            window.open(item?.url, '_blank')
        }

    }
    return (
        <div style={{marginTop:50}}>
            <div style={{ backgroundColor: "#594099", height: "fit-content", width: "100%", padding: 10 }}>
                <div className='m-t-10 web-footer-parent'>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>{userMarketPlaceHomeData?.orgName}</div>
                        <div className='m-t-10'>
                            {userMarketPlaceHomeData?.footerData?.primaryLocation?.locality},
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.city},
                            <br />
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.state},
                            <br />
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.country}
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.pincode}
                        </div>
                        {userMarketPlaceHomeData?.footerData?.locations?.length > 0 ? <div className='m-t-20' style={{ fontSize: 18, fontWeight: "bold", cursor: "pointer" }} onClick={() => { history?.push('/location-details') }}>View Other Locations</div> : <></>}
                        {userMarketPlaceHomeData?.footerData?.website ?
                            <>
                                <div className='m-t-50' style={{ fontSize: 18, fontWeight: "bold" }}>Visit Us At</div>
                                <div className='m-t-20 cursor-pointer' onClick={() => {
                                    const targetUrl = `https://${userMarketPlaceHomeData?.footerData?.website}`;
                                    window.open(targetUrl, "_blank")
                                }}>{userMarketPlaceHomeData?.footerData?.website}</div>
                            </>
                            :
                            <></>}
                    </div>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        {(userMarketPlaceHomeData?.footerData?.mobileNumber1 || userMarketPlaceHomeData?.footerData?.mobileNumber2 || userMarketPlaceHomeData?.footerData?.emailId) ? <div style={{ fontSize: 18, fontWeight: "bold" }}>Contact Us</div> : <></>}
                        <div className='m-t-10'>{userMarketPlaceHomeData?.footerData?.mobileNumber1}</div>
                        {userMarketPlaceHomeData?.footerData?.mobileNumber2 ? <div className='m-t-5'>{userMarketPlaceHomeData?.footerData?.mobileNumber2}</div> : <></>}
                        <div className='m-t-5 cursor-pointer'>
                            <a href={`mailto:${userMarketPlaceHomeData?.footerData?.emailId}`} style={{ color: 'white', textDecoration: 'none' }}>
                                {userMarketPlaceHomeData?.footerData?.emailId}
                            </a>
                        </div>
                        {userMarketPlaceHomeData?.footerData?.socialMedia?.length > 0 ?
                            <>
                                <div className='m-t-50' style={{ fontSize: 18, fontWeight: "bold" }}>We're Social</div>
                                <div className='r-c-c m-t-20'>
                                    {userMarketPlaceHomeData?.footerData?.socialMedia?.map((item) => (
                                        <>
                                            {item?.name == "Instagram" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/instagram-logo.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "WhatsApp" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/whatsApp-Logo.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "Facebook" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/facebook-logo.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "Twitter" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/TwiterX-ic.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "LinkedIn" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/icons8-linkedin.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        </>
                                    ))}
                                </div>
                            </>
                            :
                            <></>}
                    </div>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        {userMarketPlaceHomeData?.pSLink ?
                            <>
                                <div style={{ fontSize: 18, fontWeight: "bold" }}>Download The {userMarketPlaceHomeData?.orgName} App</div>
                                <img className='m-t-10 play-store-img-size' src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} onClick={() => window.open(userMarketPlaceHomeData?.pSLink, "_blank")} />
                                <div className='m-t-30'>or</div>
                            </>
                            :
                            <></>}
                        <div style={{ fontSize: 18, fontWeight: "bold", marginTop: userMarketPlaceHomeData?.pSLink ? 30 : 0 }}>Login Using The aulas App</div>
                        <div className='m-t-10'>
                            <img className='play-store-img-size' src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.classroom.aulas", "_blank")} />
                            <img className='m-l-20 play-store-img-size' src={require("../../Assets/aulasMarket/appStoreRes.svg").default} onClick={() => window.open("https://apps.apple.com/in/app/aulas/id1564724183", "_blank")} />
                        </div>
                    </div>
                </div>
                <div className='m-t-50 web-footer-parent' style={{ color: "#FAFAFA" }}>Copyright @ Vectors Education LLP. All rights reserved.</div>
                <div className='mobile-footer-parent' style={{ fontSize: 12 }}>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        <div style={{ fontWeight: "bold" }}>{userMarketPlaceHomeData?.orgName}</div>
                        <div className='m-t-10'>
                            {userMarketPlaceHomeData?.footerData?.primaryLocation?.locality},
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.city},
                            <br />
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.state},
                            <br />
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.country}
                            {" "}{userMarketPlaceHomeData?.footerData?.primaryLocation?.pincode}
                        </div>
                        <div className='m-t-20' style={{ fontWeight: "bold", }} onClick={() => { history?.push('/location-details') }}>View Other Locations</div>
                        {userMarketPlaceHomeData?.pSLink ?
                            <>
                                <div className='m-t-20' style={{ fontWeight: "bold" }}>Download The {userMarketPlaceHomeData?.orgName} App</div>
                                <img className='m-t-10 play-store-img-size' src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} onClick={() => window.open(userMarketPlaceHomeData?.pSLink, "_blank")} />
                                <div className='m-t-10'>or</div>
                            </>
                            :
                            <></>
                        }
                        <div className='m-t-10' style={{ fontWeight: "bold" }}>Login Using The aulas App</div>
                        <div className='m-t-20'>
                            <img className='play-store-img-size' src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.classroom.aulas", "_blank")} />
                            <img className='m-l-10 play-store-img-size' src={require("../../Assets/aulasMarket/appStoreRes.svg").default} onClick={() => window.open("https://apps.apple.com/in/app/aulas/id1564724183", "_blank")} />
                        </div>
                    </div>
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                        {(userMarketPlaceHomeData?.footerData?.mobileNumber1 || userMarketPlaceHomeData?.footerData?.mobileNumber2 || userMarketPlaceHomeData?.footerData?.emailId) ? <div style={{ fontWeight: "bold" }}>Contact Us</div> : <></>}
                        <div className='m-t-10' onClick={() => { handleClick(userMarketPlaceHomeData?.footerData?.mobileNumber1) }}>{userMarketPlaceHomeData?.footerData?.mobileNumber1}</div>
                        {userMarketPlaceHomeData?.footerData?.mobileNumber2 ? <div className='m-t-5' onClick={() => { handleClick(userMarketPlaceHomeData?.footerData?.mobileNumber2) }}>{userMarketPlaceHomeData?.footerData?.mobileNumber2}</div> : <></>}
                        <div className='m-t-5 cursor-pointer'> <a href={`mailto:${userMarketPlaceHomeData?.footerData?.emailId}`} style={{ color: 'white', textDecoration: 'none' }}>
                            {userMarketPlaceHomeData?.footerData?.emailId}
                        </a></div>
                        {userMarketPlaceHomeData?.footerData?.website ?
                            <>
                                <div className='m-t-20' style={{ fontWeight: "bold" }}>Visit Us At</div>
                                <div className='m-t-10' onClick={() => {
                                    const targetUrl = `https://${userMarketPlaceHomeData?.footerData?.website}`;
                                    window.open(targetUrl, "_blank")
                                }}>{userMarketPlaceHomeData?.footerData?.website}</div>
                            </>
                            :
                            <></>}
                        {userMarketPlaceHomeData?.footerData?.socialMedia?.length > 0 ?
                            <>
                                <div className='m-t-20' style={{ fontWeight: "bold" }}>We're Social</div>
                                <div className='r-c-c m-t-20'>
                                    {userMarketPlaceHomeData?.footerData?.socialMedia?.map((item) => (
                                        <>
                                            {item?.name == "Instagram" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/instagram-logo.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "WhatsApp" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/whatsApp-Logo.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "Facebook" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/facebook-logo.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "Twitter" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/TwiterX-ic.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                            {item?.name == "LinkedIn" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/icons8-linkedin.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        </>
                                    ))}
                                </div>
                            </>
                            :
                            <></>}
                    </div>
                </div>
                <div className='m-t-20 mobile-footer-parent' style={{ color: "#FAFAFA" }}>Copyright @ Vectors Education LLP. All rights reserved.</div>
            </div>
            <FooterSocialMediaModal
                setIsSocialMedia={setIsSocialMedia}
                isSocialMedia={isSocialMedia}
                footerData={socialMediaData}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    return { userMarketPlaceHomeData };
};

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);