import { Api } from "services";
import { showApprovalPendingModal } from "./kyc";
import { showKycPendingModal } from "./kyc";

export const TOGGLE_USER_DETAIL_MODAL = "TOGGLE_USER_DETAIL_MODAL";
export const TOGGLE_GENERATE_LINK_MODAL = "TOGGLE_GENERATE_LINK_MODAL";
export const TOTAL_LINKS_REQUESTED = "TOTAL_LINKS_REQUESTED";
export const TOTAL_LINKS_PAID = "TOTAL_LINKS_PAID";
export const TOTAL_LINKS_OVERDUE = "TOTAL_LINKS_OVERDUE";
export const TOTAL_LINKS_PENDING = "TOTAL_LINKS_PENDING";
export const UPDATE_RECENT_TRANSACTIONS = "UPDATE_RECENT_TRANSACTIONS";
export const UPDATE_ALL_TRANSACTIONS = "UPDATE_ALL_TRANSACTIONS";
export const UPDATE_TABLE_NAME = "UPDATE_TABLE_NAME";
export const TOGGLE_FEE_SEARCH_LOADER = "TOGGLE_FEE_SEARCH_LOADER";
export const CURRENT_TRANSACTION_USER = "CURRENT_TRANSACTION_USER";
export const UPDATE_USER_TRANSACTIONS = "UPDATE_USER_TRANSACTIONS";
export const TOGGLE_FEE_LOADER = "TOGGLE_FEE_LOADER";
export const UPDATE_FEE_STANDARDS = "UPDATE_FEE_STANDARDS";
export const UPDATE_FEE_USERS = "UPDATE_FEE_USERS";
export const TOGGLE_FEE_BUTTON_LOADER = "TOGGLE_FEE_BUTTON_LOADER";
export const TOTAL_LINKS_SETTLED = "TOTAL_LINKS_SETTLED";
export const TOTAL_LINKS_REFUNDED = "TOTAL_LINKS_REFUNDED";
export const UPDATE_FEE_STUDENTS = "UPDATE_FEE_STUDENTS";
export const UPDATE_SELECTED_FEE_STUDENTS = "UPDATE_SELECTED_FEE_STUDENTS";
export const UPDATE_REJECT_KYC_MESSAGE = "UPDATE_REJECT_KYC_MESSAGE";
export const UPDATE_VALID_GST = "UPDATE_VALID_GST";
export const UPDATE_TRANSACTION_FEE_PAYER = "UPDATE_TRANSACTION_FEE_PAYER";
export const TOTAL_LINKS_CANCELLED = "TOTAL_LINKS_CANCELLED";

export const cancelPaymentRequest = (id, callback) => {
  return (dispatch, getState) => {
    const { transactionUser } = getState().fees;
    const params = {
      id,
      status: "Cancelled",
    };
    return new Promise((resolve, reject) => {
      Api.post("/fee/update")
        .params(params)
        .send(async (response, error) => {
          callback();
          if (response && response.show.type === "success") {
            dispatch(getUserTransactionDetails(transactionUser));
          }
        });
    });
  };
};

const updateTransactionFeePayer = (payer) => ({
  type: UPDATE_TRANSACTION_FEE_PAYER,
  payer,
});

export const saveSetting = (payer, callback, successCallback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Api.post("/fee/feepayer")
        .params({ feepayer: payer })
        .send(async (response, error) => {
          if (response) {
            if (response.kyc === "na" || response.kyc === "rejected") {
              return dispatch(showKycPendingModal(true));
            } else if (response.kyc === "submitted") {
              return dispatch(showApprovalPendingModal(true));
            }
            if (response.show.type === "success") {
              dispatch(updateTransactionFeePayer(payer));
              successCallback();
            }
          } else {
            callback();
          }
        });
    });
  };
};

export const toggleFeeLoader = (value) => ({
  type: TOGGLE_FEE_LOADER,
  value,
});

export const toggleFeeButtonLoader = (value) => ({
  type: TOGGLE_FEE_BUTTON_LOADER,
  value,
});

export const toggleUserDetailModal = (value) => ({
  type: TOGGLE_USER_DETAIL_MODAL,
  value,
});

export const toggleGenerateLinkModal = (value) => ({
  type: TOGGLE_GENERATE_LINK_MODAL,
  value,
});

export const totalLinksRequested = (data) => ({
  type: TOTAL_LINKS_REQUESTED,
  data,
});

export const totalLinksPaid = (data) => ({
  type: TOTAL_LINKS_PAID,
  data,
});

export const totalLinksCancelled = (data) => ({
  type: TOTAL_LINKS_CANCELLED,
  data,
});

export const totalLinksOverdue = (data) => ({
  type: TOTAL_LINKS_OVERDUE,
  data,
});

export const totalLinksPending = (data) => ({
  type: TOTAL_LINKS_PENDING,
  data,
});

export const totalLinksSettled = (data) => ({
  type: TOTAL_LINKS_SETTLED,
  data,
});

export const totalLinksRefunded = (data) => ({
  type: TOTAL_LINKS_REFUNDED,
  data,
});

export const updateRecentTransactions = (data) => ({
  type: UPDATE_RECENT_TRANSACTIONS,
  data,
});

export const updateAllTransactions = (data) => ({
  type: UPDATE_ALL_TRANSACTIONS,
  data,
});

export const updateUserTransactions = (data) => ({
  type: UPDATE_USER_TRANSACTIONS,
  data,
});

export const updateTableName = (name) => ({
  type: UPDATE_TABLE_NAME,
  name,
});

export const toggleFeeSearchLoader = (value) => ({
  type: TOGGLE_FEE_SEARCH_LOADER,
  value,
});

export const currentTransactionUser = (user) => ({
  type: CURRENT_TRANSACTION_USER,
  user,
});

export const updateFeeStandards = (data) => ({
  type: UPDATE_FEE_STANDARDS,
  data,
});

export const updateFeeUsers = (users) => ({
  type: UPDATE_FEE_USERS,
  users,
});

export const feeStudents = (data) => ({
  type: UPDATE_FEE_STUDENTS,
  data,
});

export const updateSelectedFeeStudents = (ids, rows) => ({
  type: UPDATE_SELECTED_FEE_STUDENTS,
  ids,
  rows,
});

export const getFeeData = (history) => {
  return (dispatch, getState) => {
    dispatch(toggleFeeLoader(true));
    const url = `/fee/userlist`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params({})
        .send(async (response, error) => {
          dispatch(toggleFeeLoader(false));
          if (typeof response !== "undefined") {
            if (response.kyc === "submitted") {
              return history.push("/kyc-form-submitted");
            } else if (response.kyc === "rejected") {
              dispatch(updateRejectKycMessage(response.comments));
              return history.push("/kyc-form-rejected");
            }
            dispatch(totalLinksRequested(response?.totalLinksRequested));
            dispatch(totalLinksPaid(response?.totalLinksPaid));
            dispatch(totalLinksCancelled(response?.totalLinksCancelled));
            dispatch(totalLinksOverdue(response?.totalLinksOverDue));
            dispatch(totalLinksPending(response?.totalLinksPending));
            dispatch(totalLinksSettled(response?.totalLinksSettled));
            dispatch(totalLinksRefunded(response?.totalRefunded));
            const recentTransactions = response?.allTransactionList.slice(0, 5);
            dispatch(updateRecentTransactions(recentTransactions));
            dispatch(updateFeeStandards(response?.standards));
            dispatch(updateGstValid(response?.validGst));
            dispatch(updateTransactionFeePayer(response?.feepayer));
          }
        });
    });
  };
};

const updateGstValid = (bool) => ({
  type: UPDATE_VALID_GST,
  bool,
});

export const updateRejectKycMessage = (message) => ({
  type: UPDATE_REJECT_KYC_MESSAGE,
  message,
});

export const getStandardData = (
  history = null,
  standardId = null,
  query = ""
) => {
  return (dispatch, getState) => {
    dispatch(toggleFeeLoader(true));
    const params = {
      standardId,
      search: query,
    };
    return new Promise((resolve, reject) => {
      Api.get("/fee/standardGet")
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeLoader(false));
          dispatch(toggleFeeSearchLoader(false));
          if (typeof response !== "undefined") {
            if (response.kyc === "submitted") {
              return history.push("/kyc-form-submitted");
            } else if (response.kyc === "rejected") {
              dispatch(updateRejectKycMessage(response.comments));
              return history.push("/kyc-form-rejected");
            }
            dispatch(totalLinksRequested(response?.totalLinksRequested));
            dispatch(totalLinksPaid(response?.totalLinksPaid));
            dispatch(totalLinksCancelled(response?.totalLinksCancelled));
            dispatch(totalLinksOverdue(response?.totalLinksOverDue));
            dispatch(totalLinksPending(response?.totalLinksPending));
            dispatch(totalLinksSettled(response?.totalLinksSettled));
            dispatch(totalLinksRefunded(response?.totalRefunded));
            dispatch(feeStudents(response?.students));
            dispatch(updateGstValid(response?.validGst));
            dispatch(updateTransactionFeePayer(response?.feepayer));
          }
        });
    });
  };
};

export const getTransactions = (query = "") => {
  return (dispatch, getState) => {
    dispatch(toggleFeeLoader(true));
    const url = `/fee`;
    const { tableName } = getState().fees;
    const params = {
      status: getStatus(tableName),
      search: query,
    };
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeLoader(false));
          dispatch(toggleFeeSearchLoader(false));
          if (typeof response !== "undefined") {
            if (response.kyc === "na") {
              return dispatch(showKycPendingModal(true));
            }
            dispatch(updateAllTransactions(response?.transactions));
          }
        });
    });
  };
};

export const getStatus = (tableName) => {
  let status = "";
  if (tableName == "Paid") {
    status = "Paid";
  } else if (tableName == "Payments Pending") {
    status = "Link Sent";
  } else if (tableName == "Overdue") {
    status = "Overdue";
  } else if (tableName == "Payments settled") {
    status = "Settled";
  } else if (tableName == "Payments refunded") {
    status = "Refunded";
  } else if (tableName == "Payments cancelled") {
    status = "Cancelled";
  } else {
    // Do nothing
  }
  return status;
};

export const getUserTransactionDetails = (user) => {
  return (dispatch, getState) => {
    const { tableName } = getState().fees;
    dispatch(toggleFeeLoader(true));
    dispatch(currentTransactionUser(user));
    const url = `/fee`;
    const params = {
      userId: user.id,
      status: getStatus(tableName),
    };
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeLoader(false));
          if (typeof response !== "undefined") {
            if (response.kyc === "na") {
              return dispatch(showKycPendingModal(true));
            }
            dispatch(updateUserTransactions(response?.transactions));
          }
        });
    });
  };
};

export const refundPayment = (transaction) => {
  return (dispatch, getState) => {
    dispatch(toggleFeeLoader(true));
    const { transactionUser } = getState().fees;
    const params = {
      paymentId: transaction.paymentId,
      receipt: transaction.receipt,
      note: "Fee Refund",
    };
    const url = `/refund/req`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeLoader(false));
          if (response && response.show?.type === "success") {
            dispatch(getUserTransactionDetails(transactionUser));
          }
        });
    });
  };
};

export const generateLink = (userIds, details) => {
  return (dispatch, getState) => {
    const params = {
      userIds: userIds,
      ...details,
    };
    const url = `/fee`;
    dispatch(toggleFeeButtonLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeButtonLoader(false));
          if (
            typeof response !== "undefined" &&
            response.show?.type === "success"
          ) {
            dispatch(getFeeData());
            dispatch(updateSelectedFeeStudents([], []));
            dispatch(toggleGenerateLinkModal(false));
          }
          if (typeof response !== "undefined") {
            dispatch(updateSelectedFeeStudents([], []));
            dispatch(toggleGenerateLinkModal(false));
            if (response.kyc === "na" || response.kyc === "rejected") {
              return dispatch(showKycPendingModal(true));
            } else if (response.kyc === "submitted") {
              return dispatch(showApprovalPendingModal(true));
            }
          }
        });
    });
  };
};

export const generateClassLink = (standardId, details) => {
  return (dispatch, getState) => {
    const params = {
      standardId,
      ...details,
    };
    const url = `/fee/standard`;
    dispatch(toggleFeeButtonLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeButtonLoader(false));
          if (
            typeof response !== "undefined" &&
            response.show?.type === "success"
          ) {
            dispatch(getFeeData());
            dispatch(toggleGenerateLinkModal(false));
          }
          if (typeof response !== "undefined") {
            dispatch(updateSelectedFeeStudents([], []));
            dispatch(toggleGenerateLinkModal(false));
            if (response.kyc === "na" || response.kyc === "rejected") {
              return dispatch(showKycPendingModal(true));
            } else if (response.kyc === "submitted") {
              return dispatch(showApprovalPendingModal(true));
            }
          }
        });
    });
  };
};

export const getFeeUser = (standardId, query) => {
  return (dispatch, getState) => {
    const params = {
      standardId,
      search: query,
    };
    const url = `/fee/getUser`;
    dispatch(toggleFeeSearchLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send(async (response, error) => {
          dispatch(toggleFeeSearchLoader(false));
          if (typeof response !== "undefined") {
            dispatch(updateFeeUsers(response));
          }
        });
    });
  };
};
