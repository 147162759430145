import React, { useEffect, useState, useRef } from 'react';
import "../AnimatedTextField/textFieldStyles.css"
import 'react-phone-number-input/style.css';

function AnimatedOTPInput({ label, value, handleInputChange, isMandatory, inputId, disabled }) {
    const [isFocused, setIsFocused] = useState(false);
    const otpInputRef = useRef(null);

    const handleInputFocus = () => {
        if (!disabled) {
            if (!value) {
                document.getElementById(`customInputLabel_${inputId}`).classList.add('shrink');
            } else if (value) {
                document.getElementById(`customInputLabel_${inputId}`).classList.add('abcd');
            }
            setIsFocused(true);
        }
    };

    const handleInputBlur = () => {
        if (!value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('shrink');
        } else if (value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('abcd');
        }
        setIsFocused(false);
    };

    const onBlur = () => {
        if (!value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('shrink');
            setIsFocused(false);
        } else if (value) {
            document.getElementById(`customInputLabel_${inputId}`).classList.remove('abcd');
            setIsFocused(true);
        }
    };

    useEffect(() => {
        if (isFocused && otpInputRef.current) {
            otpInputRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        if (!value) {
            setIsFocused(false);
        }
    }, [value]);

    return (
        <div className="custom-input-container m-t-20">
            <label htmlFor={`customInput_${inputId}`} id={`customInputLabel_${inputId}`} className={`custom-input-label ${value ? 'shrink' : ''}`}>
                {label}{" "} {isMandatory ? <span style={{ color: 'red' }}>*</span> : <></>}
            </label>
            {isFocused ?
                <div style={{ position: "relative", display: "flex", justifyContent: "flex-start" }}>
                    <div className="r-c-c" style={{ fontWeight: 700 }}>
                        <div className="otpInputNew">{value?.[0]}</div>
                        <div className="otpInputNew">{value?.[1]}</div>
                        <div className="otpInputNew">{value?.[2]}</div>
                        <div className="otpInputNew">{value?.[3]}</div>
                        <div className="otpInputNew">{value?.[4]}</div>
                        <div className="otpInputNew">{value?.[5]}</div>
                    </div>
                    <input ref={otpInputRef} type="number" className='aulas-login__input-verify-otp'
                        style={{ width: "100%", cursor: disabled ? "not-allowed" : "text" }}
                        value={value}
                        onChange={handleInputChange}
                        disabled={disabled}
                        onBlur={onBlur}
                    />
                </div>
                :
                <input
                    type="text"
                    id={`customInput_${inputId}`}
                    className="custom-input"
                    style={{ color: "#636363", fontSize: 14, width: "87%", cursor: disabled ? "not-allowed" : "text", backgroundColor: "#FFFFFF" }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    disabled={disabled}
                />
            }
        </div>
    )
}

export default AnimatedOTPInput;
