import React, { useState,useRef } from 'react';
import  '../component/chatStyles.css'
import { CloseOutlined, FormOutlined, PictureOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useHistory } from 'react-router';
import { SubIcon } from 'components/subIcon';
import { getCareChat, postCareUserChat } from 'actions/adminCare';
import { useEffect } from 'react';
import { Api } from "services";
import { connect } from 'react-redux';
import { getUserProfileDetails } from 'actions/profileNew';
import Chat from 'pages/care/chat';

var chat;

const ChatPopup = ({onClose,setAulasCareChat,getCareChat,chats,getUserProfileDetails,orgData,notificationCount,setNotificationCount,postCareUserChat,user,new_Chats}) => {
  
  useEffect(() => {
      getCareChat(()=>{});
      getUserProfileDetails()
      
  },[])

  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [socketMsg, setSocketMsg] = useState([])

  useEffect(()=>{
    setMessages([]);
    setMessages(chats?.slice(0,notificationCount)) 
  },[])


  // useEffect(() => {
  //   console.log('temp in useeffect', new_Chats)
  //   if (new_Chats?.length) {
  //     setSocketMsg([...new_Chats])
  //   }
  // }, [new_Chats])

  const handleMessageSend = () => {
    let targetId=user?.orgId;
    if (inputValue.trim() !== '') {
      const newMessage = {
         adminId: 0,
         message :inputValue,
         type:'Text',
         user:user,
         targetId:user?.orgId
      };
      let temp = messages;
      temp?.unshift(newMessage)
      setMessages(temp);
      chat.sendmsg({
        message: inputValue,
        createdAt: new Date(),
        targetId: user?.orgId,
        oldChats: new_Chats,
        user: user,
        type: 'Text'
      },'Text', targetId.toString())

      setInputValue('');
      postCareUserChat(newMessage?.message ,newMessage?.type)
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const callback = (e) =>{
   try {
    switch(e.action){
      case "rcvChat":
        let messageData = e?.data?.data;
        let message = messageData?.message;
        let type = messageData?.type;
        if(type ==='text'){
          const newMessage = {
            adminId: messageData.userId,
            user:messageData?.user,
            message :message,
            type:"Text"
         };
         let temp = messages;
         temp?.unshift(newMessage)
         setMessages(temp);
         break;
        }
      
      default:
        break;
    }
    return true;
   } catch (error) {
      console.log("CALLBACK-error",error)
   }
  }
  const socketConnection = () => {
    chat = new Chat({
      user: user,
      userId: user?.id?.toString(),
      userName: user?.name,
      orgId: (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes('InstituteSupport') || user?.privilage?.includes('Admin')) ? user?.orgId?.toString() : user?.id?.toString(),

      callback:callback,
      
    });
    chat.initsocket();
    console.log("AA-dataChat",chat);
  };
 

  
  useEffect(()=>{
    socketConnection()
    return () => {
      chat.close();
    }
  },[messages])
  return (
    <div className="chat-popup">
      <div className="chat-header">
        <h><img width={28} height={28} src={`${Api._s3_url}${orgData?.logo || "defaultLogo.png"}`}/>
          <h style={{color:"white",fontSize:"16px",marginLeft:"10px",fontWeight:"bold"}}>{orgData?.title}</h></h>
        <div><FormOutlined style={{color:"#FFFFFF",fontSize:"18px",fontWeight:"bold",marginRight:"10px"}} onClick={()=>{
        setAulasCareChat(false);
        history.push('/care/chat')}}/><CloseOutlined style={{color:"#FFFFFF",fontSize:"18px",fontWeight:"bold"}} onClick={()=>{onClose() ;setNotificationCount(0)}}/></div>
      </div>
      <div className="message-container">
      <div style={{marginLeft:"8px"}}>
            {messages?.slice(0).reverse()?.map((message, index) => {
              return(
                <div style={{
                  display: "flex",
                  justifyContent: message?.adminId === 0 ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                  padding:"15px"
                }}>
                  <div style={{display:"flex"}}>
                    <div style={{marginTop:"17px"}}>
                        {console.log("MES123",message)}
                        <SubIcon size={25} icon={message?.user.image} name={message?.user.image} />
                    </div>
                     <div className="message-body" style={{marginLeft:"8px"}}>{message.message}</div>
                 </div>
                </div>

              )
            })}
        </div>
      </div>
    
      <div style={{padding:"10px"}}>
      <Input
          placeholder="Type your message..."
          value={inputValue}
          onChange={handleInputChange}
          suffix={
            <span>
              <PictureOutlined
                style={{ marginRight: '10px', fontSize: '18px', color: '#594099', cursor: 'pointer' }}
              />
              <SendOutlined
                style={{ fontSize: '18px', fontWeight: 'bold', color: '#594099', cursor: 'pointer' }}
                onClick={handleMessageSend}
              />
            </span>
          }
            />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {dashBoardOverviewData,storeInfoBoardData} = state.dashBoard
  const {orgData} = state.login;
  const { user } = state.session;


  const {
    chats,
    currentPage,
    openCloseConversationListWithChat,
    batch,
    role,
    date,
    name,
    isClosedOrOpened,
    specificStudentId,
    disableLoadEarlier,
    chatWithAulas,
    new_Chats,
  } = state.adminCare;
  const {publishedTests,} = state.studentTest;
  return {dashBoardOverviewData,storeInfoBoardData,publishedTests,chats,orgData,user,new_Chats};
};

const mapDispatchToProps = (dispatch) => ({
  getCareChat: (callback) => dispatch(getCareChat(callback)),
  getUserProfileDetails: (callback) => dispatch(getUserProfileDetails(callback)),
  postCareUserChat: (message, type) =>dispatch(postCareUserChat(message, type)),


});

export default connect(mapStateToProps,mapDispatchToProps)(ChatPopup);
