import React from "react";

import {
  EditOutlined,
} from "@ant-design/icons";
import { IoArrowUndo } from "react-icons/io5";
import { IoArrowRedo } from "react-icons/io5";
import {HiOutlineMinus} from 'react-icons/hi'
import Tooltip from 'antd/lib/tooltip';
import Popover from 'antd/lib/popover';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/popover/style/index';
import Styles from "./style";
import Slider from 'antd/lib/slider';
import "../broadcastStyles.css"
import 'antd/lib/slider/style/index';
import { FaEraser, FaRegCircle } from "react-icons/fa";
import { AiFillDelete, AiFillEdit, AiOutlineSelect } from "react-icons/ai";
import { BsFillImageFill, BsFillShareFill } from 'react-icons/bs';
import { BiRectangle, BiSolidFilePdf } from 'react-icons/bi';
import SketchCanvasPro from "components/sketchCanvasPro/index";
import { colorList1, colorList2, colorList3, DraggableImage, DraggableShape } from "components/sketchCanvasPro/utils";
import TouchableOpacity from "./touchableOpacity";
import ImageManger from "components/sketchCanvasPro/ImageManager";
import PDFManager from "components/sketchCanvasPro/pdfManager";
import { Modal, Progress } from "antd";
import { FaFileUpload } from "react-icons/fa";
import { BiSolidZoomIn } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { Api, Utils, Notification } from "services";
import { getConversionStatus, postCanvasImage, postChatImage, postRoomData, setRoomBoardData, storePaths, triggerConversion } from "actions/broadcast";
import jsPDF from "jspdf";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { FaCirclePlus, FaCircleInfo } from "react-icons/fa6";
import { TiInfoLarge } from "react-icons/ti";
import Loader from "components/WormLoader";
import { Button } from "components/Button";
// import { fakecanvasdata } from "./fake";

let pdfimageArray = [];
const screenWidth = isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth;
const screenHeight = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
let offsets = {zoomLevel:1, scaleOffset: {x:0, y:0}, panOffset: {x:0, y:0}};
let pdfmeta = [];
let pageswitchtimeout = null;
let jobstatusinterval = null;

const ColorElement = ({color}) => (
  <div style={{ width: 16, height: 16, minHeight: 12, minWidth: 12, borderRadius: 16, backgroundColor: color }}> </div>
)

const InfoElement = ({Icon, label=''}) => (
  <div style={{display:"flex", flexDirection:"row", alignItems:"center", margin:5}}>
    <Icon style={{fontSize:20, color:"white"}}/>
    <div style={{color:"white", marginLeft:10}}>{label}</div>
  </div>
)

const MoreInfoElement = ({label=''}) => (
  <div style={{display:"flex", flexDirection:"row", alignItems:"center", margin:5}}>
    <TiInfoLarge style={{fontSize:20, color:"white"}}/>
    <div style={{color:"white", marginLeft:10}}>{label}</div>
  </div>
)

const PageContainer = ({currentPage, totalPages, switchCanvas, addCanvas, inputcanvasnum, setInputCanvasNum, onPageInputBlur}) => {
  return (
    <div data-html2canvas-ignore className="disable-text-selection topRowCards" style={{...Styles.shadow, cursor:"default", bottom:20, right:20, padding:10, display:"flex", flexDirection:"row", alignItems:"center", height:40}}>
          <TouchableOpacity className='r-c-c' onClick={()=>{switchCanvas(currentPage===1?totalPages:(currentPage-1)%(totalPages+1))}} style={{paddingLeft:10, paddingRight:0}}>
            <FaCaretLeft style={{color:"white", fontSize:22}}/>
          </TouchableOpacity>
          <div className='r-c-c' style={{display:"flex", color:"white", fontWeight:"bold", }}>
              <input style={{color:"white", backgroundColor:'transparent',width:30, border:"none",  borderBottom: '1px solid white',  outline:"none", textAlign:"center", fontWeight:"bold"}} onBlur={onPageInputBlur} value={inputcanvasnum} onChange={(e)=>{
                let text = e.target.value
                if (text!=undefined && (text==''|| Utils.onlyDigits(text))){
                  if(text==''){
                    text = ''
                  }
                  else if (parseInt(text)<1){
                    text = 1
                  }else if(parseInt(text)>totalPages){
                    text = totalPages
                  }
                  setInputCanvasNum(text)
                }
              }}/>
              {/* <div className='r-c-c m-r-5' style={{paddingLeft:10, paddingRight:10, color:"white", borderRadius:10, fontWeight:'bold'}}>{currentPage}</div> */}
              <div>
              / {totalPages}
              </div>
          </div>
          <TouchableOpacity className='r-c-c'  onClick={()=>{switchCanvas(currentPage===totalPages?1:(currentPage+1)%(totalPages+1))}} style={{paddingLeft:10, paddingRight:10}}>
            <FaCaretRight style={{color:"white", fontSize:22}}/>
          </TouchableOpacity>
          <TouchableOpacity className='r-c-c'  onClick={()=>{addCanvas()}} style={{paddingLeft:0, paddingRight:10}}>
            <FaCirclePlus style={{color:"white", fontSize:18}}/>
          </TouchableOpacity>
    </div>
  )
} 

class PassiveCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();
    this.inputFilePDF = React.createRef();
    this.canvasPro = React.createRef();
    this.handlePenClick = this.handlePenClick.bind(this);
    this.openWidthPicker = this.openWidthPicker.bind(this);
    this.handleUndoClick = this.handleUndoClick.bind(this);
    this.handleRedoClick = this.handleRedoClick.bind(this);
    this.openColorPicker = this.openColorPicker.bind(this);
    this.addCanvas = this.addCanvas.bind(this);
    this.addNewImage = this.addNewImage.bind(this);
    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    this.openEraserWidthPicker = this.openEraserWidthPicker.bind(this);
    // this.handleRedoClick = this.handleRedoClick.bind(this);
    // this.handleClearClick = this.handleClearClick.bind(this);
    this.handleEraserClick = this.handleEraserClick.bind(this);
    this.openShapesPicker = this.openShapesPicker.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleMoveClick = this.handleMoveClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePDFGeneration = this.handlePDFGeneration.bind(this);
    this.embedPDF = this.embedPDF.bind(this);
    this.state = {
      inputcanvasnum: 1,
      canvasnum: 1,
      mode: 'pen',
      color: "black",
      path: false,
      width: 3,
      canvasHeight: screenHeight,
      canvasWidth: screenWidth,
      toggler:false,
      showColorPicker: false,
      eraserWidth:10,
      screenshoturl: '',
      isModalVisible: false,
      screenshotfile: null,
      showWidthPicker:false,
      showShapesPicker:false,
      showEraserWidthPicker: false,
      shapeType: 'rect',
      canvaslength: 3,
      dataUrl: null,
      percentPDFLoaded: 0,
      PDFloadModalVisible: false,
      showToolbar: true,
      showInfoModal: false,
      showDeleteModal: false,
      uploadedPDFMeta: false,
      PDFdownloadModalVisible: false
    };
  }
  handleDimensions(meta){
    if (meta){
      this.canvasPro.current.resizeAll({width:meta.width, height:meta.height})
      const canvasHeight = Math.floor(meta.height * screenWidth / meta.width)
      const canvasWidth = Math.floor(meta.width * screenHeight/meta.height)
      if (canvasHeight!=this.state.canvasHeight){
        if (canvasHeight<=screenHeight){
          this.setState({canvasHeight: canvasHeight})
        }else if (canvasWidth<=screenWidth){
          this.setState({canvasWidth: canvasWidth})
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clearToggler!=this.props.clearToggler){
      this.canvasPro.current.clearCanvas();
      return
    }
  }
  handleKeyDown(event) {
    if (this.state.PDFloadModalVisible) return
    switch (event.key) {
      case 'ArrowRight': // Right arrow to seek forward
        this.switchCanvas2(this.state.canvasnum===this.state.canvaslength?1:(this.state.canvasnum+1)%(this.state.canvaslength+1))
        break;
      case 'ArrowLeft': // Left arrow to seek backward
        this.switchCanvas2(this.state.canvasnum===1?this.state.canvaslength:(this.state.canvasnum-1)%(this.state.canvaslength+1))
        break;
      default:
        break;
    }
  }
  async embedPDF2(meta){
    pdfimageArray = [];
    const canvas = document.getElementById('broadcast-canvas-get-image');
    const numPages = await PDFManager.loadPDF(meta.url);
    const insertionPage = meta.insertionPage;
    pdfmeta.push({url:meta.url, insertionPage, numPages});
    for (let i=1; i<=numPages; i++){
      const localurl = await PDFManager.renderPDFOnCanvas(i, canvas).catch(()=>PDFManager.renderEmptyFrameOnCanvas(i, canvas));
      pdfimageArray[i-1] = {url:localurl, localurl, pageNo:i};
      this.setState({percentPDFLoaded: Math.round(100*i/numPages)})
    }
    await ImageManger.loadAllImages(pdfimageArray.map(item=>item.localurl));
    pdfimageArray.forEach((item, index)=>{
        this.addPDFImage(item.url, item.localurl, insertionPage+index)
    })
    this.setState({PDFloadModalVisible: false, mode:'pen', uploadedPDFMeta: {insertionPage, numPages}})
  }
  componentDidMount(){
    pdfmeta = []
    window.addEventListener('keydown', this.handleKeyDown);
    this.setState({PDFloadModalVisible:true})

    setTimeout(async ()=>{
      const boardData = this.props.roomboardData[this.props.streamId];
      let totalPages = Object.keys(boardData.roomdata).length;
      if (boardData.pdfmeta && boardData.pdfmeta.length){
        const insertionpage = boardData.pdfmeta[0].insertionPage;
        const numPages = boardData.pdfmeta[0].numPages;
        const pdfinsertendpage = numPages+insertionpage-1;
        while (pdfinsertendpage>totalPages){
          totalPages+=1
          boardData.roomdata[totalPages] = []
          this.canvasPro.current.createPage(totalPages, [])
        }
      }
      this.setState({
        canvaslength: totalPages
      })
      for (let i=1; i<=totalPages; i++){
        this.canvasPro.current.createPage(i, boardData.roomdata[i])
      }
      if (boardData.pdfmeta && boardData.pdfmeta.length){
        await this.embedPDF2(boardData.pdfmeta[0])
      }
      for (let key in boardData.roomdata){
        console.log("sgdsg", key, boardData.roomdata[key].length)
        for (let i=0; i<boardData.roomdata[key].length; i++){
          const element = boardData.roomdata[key][i]
          console.log("227", element)
          if (element.shapeType==='image' && !element.isPDFImage){
            console.log("228", element.url)
            const localurl = await this.loadImage(element.url)
            boardData.roomdata[key][i] = new DraggableImage({...boardData.roomdata[key][i], localurl, image:ImageManger.getImage(element.url)});
          }else if(element.shapeType==='rect'||element.shapeType==='circle'||element.shapeType==='line'){
            boardData.roomdata[key][i] = new DraggableShape({...boardData.roomdata[key][i]})
          }
        }
      }
      console.log("236",  boardData.roomdata)
        if (boardData.roomdata){
            this.canvasPro.current.setBoardData(boardData.roomdata);
        }
        this.setState({PDFloadModalVisible: false, mode:'pen'})
    }, 1000);
  }
  componentWillUnmount(){
    const roomdata = this.canvasPro.current.getBoardData();
    Object.keys(roomdata).map((roomNo)=>{
        roomdata[roomNo] = roomdata[roomNo].filter((item)=>!(item.isPDFImage==true))
    })
    const roomdatawrapper = {roomdata, pdfmeta}
    this.props.setRoomBoardData(this.props.streamId, roomdatawrapper)
    if (this.props.isScheduledTeacher){
    this.props.postRoomData({roomId:this.props.streamId?.split('xxxx')[1], roomData: JSON.stringify(roomdatawrapper)}, ()=>{})
    }
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  switchCanvas2(canvasno) {
    this.setState({
      canvasnum: canvasno,
      inputcanvasnum: canvasno
    })
    window.ReactNativeWebView?.postMessage(JSON.stringify({event:'canvasfull', currentPath:null, fullPath: {path: this.canvasPro.current.getBoardData()[''+canvasno], offsets:this.canvasPro.current.getOffsets()[''+canvasno]}, lastUpdateIsImage:true}))
  }

  addCanvas(){
    this.canvasPro.current.createPage(this.state.canvaslength+1);
    this.setState({
      canvaslength: this.state.canvaslength + 1
    })
  }


  content = (
    <>
    <div style={Styles.colorPickerWrapper}>
      {colorList1.map((item)=>(
        <button
          key={item}
          className="cursor-pointer"
          style={{ ...Styles.colorPickerBtn, backgroundColor: item }}
          onClick={() => this.selectColor(item)}
        />
      ))}
    </div>
    <div style={Styles.colorPickerWrapper}>
      {colorList2.map((item)=>(
        <button
          key={item}
          className="cursor-pointer"
          style={{ ...Styles.colorPickerBtn, backgroundColor: item }}
          onClick={() => this.selectColor(item)}
        />
      ))}
    </div>
    <div style={Styles.colorPickerWrapper}>
      {colorList3.map((item)=>(
        <button
          key={item}
          className="cursor-pointer"
          style={{ ...Styles.colorPickerBtn, backgroundColor: item }}
          onClick={() => this.selectColor(item)}
        />
      ))}
    </div>
    </>
  );

  shapesType = () => (
    <div style={Styles.colorPickerWrapper}>
    <button className="cursor-pointer" onClick={() => this.selectShape("rect")}
     style={{ backgroundColor: "white", border:"none", padding:5}}>
      <BiRectangle style={{fontSize:20}}/>
    </button>
    <button className="cursor-pointer" onClick={() => this.selectShape("circle")}
      style={{ backgroundColor: "white", border:"none", padding:5 }}>
      <FaRegCircle style={{fontSize:20}}/>
    </button>
    <button className="cursor-pointer" onClick={() => this.selectShape("line")}
    style={{ backgroundColor: "white", border:"none", padding:5 }}>
      <HiOutlineMinus style={{transform:'rotate(20deg)', fontSize:25}} />
    </button>
  </div>
  )

  selectColor(color) {
    this.setState({
      color,
      showColorPicker: false
    });
  }

  selectShape(shape){
    this.setState({
      shapeType: shape,
      mode: 'drawShape',
      showShapesPicker:false
    })
  }

  handleUndoClick() {
    this.canvasPro.current.undoCanvas();
  }

  handleRedoClick(){
    this.canvasPro.current.redoCanvas();
  }


  handleClearClick() {
    this.canvasPro.current.clearCanvas();
    this.setState({
      mode: 'pen'
    })
    window.ReactNativeWebView?.postMessage(JSON.stringify({event:'clearCanvas'}))
  }

  handleClearOverlay(){
    this.canvasPro.current.clearOverlay();
    this.setState({
      mode: 'pen'
    })
    window.ReactNativeWebView?.postMessage(JSON.stringify({event:'clearOverlay'}))
  }

  handleClearPDF(){
    pdfmeta = []
    if (!this.state.uploadedPDFMeta) return
    this.canvasPro.current.deletePage(this.state.uploadedPDFMeta.insertionPage, this.state.uploadedPDFMeta.insertionPage+this.state.uploadedPDFMeta.numPages-1);
    let newlength = Object.keys(this.canvasPro.current.getBoardData()).length;
    while (newlength<3){
      newlength+=1
      this.canvasPro.current.createPage(newlength)
    }
    this.setState({
      mode: 'pen',
      inputcanvasnum:1,
      canvasnum:1,
      canvaslength: newlength,
      uploadedPDFMeta: false
    })
    this.canvasPro.current.forceRender();
    window.ReactNativeWebView?.postMessage(JSON.stringify({event:'clearPDF'}))
  }

  handleClearAll(){
    pdfmeta = []
    this.canvasPro.current.deletePage(1, this.state.canvaslength);
    let newlength = Object.keys(this.canvasPro.current.getBoardData()).length;
    while (newlength<3){
      newlength+=1
      this.canvasPro.current.createPage(newlength)
    }
    this.setState({
      mode: 'pen',
      inputcanvasnum:1,
      canvasnum:1,
      canvaslength: newlength,
      uploadedPDFMeta: false
    })
    setTimeout(()=>{
      this.canvasPro.current?.forceRender();
    }, 1000);
    window.ReactNativeWebView?.postMessage(JSON.stringify({event:'clearPDF'}))
  }

  changeWidth(value) {
    this.setState({
      width: value
    })
  }
  changeEraserWidth(value) {
    this.setState({
      eraserWidth: value
    })
  }
  handlePenClick() {
    this.setState((prevState) => {
      return {
        // ...prevState,
        mode: 'pen',
        showEraserWidthPicker:false,
        showColorPicker:false,
        showShapesPicker:false,
        width: prevState?.width ? prevState.width : 2
      }
    })
    this.openWidthPicker(!this.state.showWidthPicker);
  }

  openColorPicker() {
    this.toggleColorPicker(!this.state.showColorPicker)
    this.setState({ showWidthPicker:false, showEraserWidthPicker:false, showShapesPicker:false });
  }
  toggleColorPicker(bool){
    this.setState({showColorPicker: bool})
  }
  openWidthPicker(bool) {
    this.setState({ showWidthPicker: bool });
  }
  openShapesPicker(bool) {
    this.setState({showShapesPicker: bool})
  }
  openEraserWidthPicker(bool){
    this.setState({showEraserWidthPicker: bool })
  }
  handleEraserClick() {
    this.setState((prevState)=> {
      return { 
        mode: 'eraser', 
        showWidthPicker:false,
        showColorPicker:false,
        showShapesPicker:false,
        eraserWidth:prevState?.eraserWidth ? prevState.eraserWidth : 10
    } });
    this.openEraserWidthPicker(!this.state.showEraserWidthPicker);
  }



  async handlePDFGeneration(){
    this.setState({
      PDFdownloadModalVisible: true,
      percentPDFLoaded: 0
    })
    setTimeout(()=>{
      const pdf = new jsPDF({
        orientation: 'landscape',
      });
  
      const canvas = document.getElementById('broadcast-canvas-download');
      for (let i=1; i<=this.state.canvaslength; i++){
        this.canvasPro.current.drawDC(''+i);
        const imgData = canvas.toDataURL('image/jpeg');
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        if (i<this.state.canvaslength) {
          pdf.addPage();
        }
        this.setState({percentPDFLoaded: Math.round(100*i/this.state.canvaslength)})
      }
      this.setState({PDFdownloadModalVisible: false})
      pdf.save('whiteboard.pdf');
    }, 1000);
  }

  handleSelectClick() {
    this.setState({
      mode:'select'
    })
  }
  handleMoveClick(){
    this.setState({
      mode: 'move'
    })
  }
  handleDivRef(divRef) {
    if (divRef !== null) {
      this.sketchContainer = divRef;
    }
  }
  
  slider = () => {
    return (
      <div style={Styles.colorPickerWrapper}>
        <Slider
          style={{ width: '100px' }}
          min={2}
          step={0.1}
          max={50}
          tooltip={{formatter:null}}
          onChange={(value) => {
            if (this.state.showWidthPicker){
              this.changeWidth(value)
            }else if(this.state.showEraserWidthPicker){
              this.changeEraserWidth(value*10)
            }
            }}
          value={this.state.showWidthPicker?this.state.width:this.state.showEraserWidthPicker?Math.round(this.state.eraserWidth/10):this.state.mode==='pen'?this.state.width:Math.round(this.state.eraserWidth/10)}
          onAfterChange={() => {
              if (this.state.showWidthPicker){
                this.openWidthPicker(!this.state.showWidthPicker)
              }else if(this.state.showEraserWidthPicker){
                this.openEraserWidthPicker(!this.state.showEraserWidthPicker);
              }
            }}
        />
      </div>
    )
  };
  async addNewImage(url, localurl) {
    if (!this.canvasPro.current){
      return
    }
    let imgurl = this.props.reactiveCanvas?url:localurl
    ImageManger.loadImage(imgurl).then(()=>{
      let newImageMeta = {index:this.canvasPro.current?this.canvasPro.current.getBoardData()[this.state.canvasnum]?.length:0, localurl:localurl, url:url, x:Math.ceil(Math.random()*300), y:Math.ceil(Math.random()*300), width:200, height:200, isSelected:true, timestamp:Date.now(), shapeType:"image"}
      this.canvasPro.current.drawImage(newImageMeta);
    }).finally(()=>{
      this.inputFile.current.value = ""
      this.setState({
        mode:"select",
        toggler: !this.state.toggler
      });
    })
  }

  async loadImage(imgurl){
    const canvas = document.getElementById('broadcast-canvas-get-image');
    const localurl = await ImageManger.loadImage(imgurl).then((img)=>ImageManger.renderImageOnCanvas(img, canvas).catch(()=>ImageManger.renderEmptyFrameOnCanvas(canvas)));
    return localurl
  }
  
  addPDFImage = (url, localurl, pageNo) => {
    if (!this.canvasPro.current){
      return
    }
    let newImageMeta = {pageNo:pageNo, isPDFImage:true, index:0, localurl:localurl, url:url, x:Math.ceil(Math.random()*300), y:Math.ceil(Math.random()*300), width:200, height:200, isSelected:true, timestamp:Date.now(), shapeType:"image"}
    this.canvasPro.current.drawPDF(newImageMeta, pageNo);
    if (this.inputFilePDF?.current){
        this.inputFilePDF.current.value = ""
    }
    this.setState({
      mode:"select",
      toggler: !this.state.toggler
    });
  }
  findLastNonEmptyCanvasIndex(){
    const boardData = this.canvasPro.current.getBoardData();
    for (let i=Object.keys(boardData).length; i>0; i--){
      if (boardData[i] && Array.isArray(boardData[i]) && boardData[i].length){
        return i
      }
    }
    return 0
  }
  async embedPDF(url){
    console.log("trying to embed", url)
    pdfimageArray = [];
    const canvas = document.getElementById('broadcast-canvas-get-image');
    const numPages = await PDFManager.loadPDF(url);
    const insertionPage = 1+this.findLastNonEmptyCanvasIndex();
    pdfmeta.push({url, insertionPage, numPages});
    for (let i=1; i<=numPages; i++){
      const localurl = await PDFManager.renderPDFOnCanvas(i, canvas).catch(()=>PDFManager.renderEmptyFrameOnCanvas(i, canvas));
      pdfimageArray[i-1] = {url:localurl, localurl, pageNo:i};
      this.setState({percentPDFLoaded: Math.round(100*i/numPages)})
    }
    // ensuring minimum of 3 pages
    const totalPage = Math.max(3, insertionPage+numPages-1)
    this.setState({
      uploadedPDFMeta: {insertionPage, numPages}
    })
    let i = insertionPage;
    while (i<=totalPage){
      this.canvasPro.current.createPage(i)
      i+=1
    }
    const currentPageLength = this.state.canvaslength;
    this.setState({canvaslength: totalPage});
    // timeout is added so that canvaslength use state update have time to execute
    await ImageManger.loadAllImages(pdfimageArray.map(item=>item.localurl));
    pdfimageArray.forEach((item, index)=>{
        this.addPDFImage(item.url, item.localurl, insertionPage+index)
    })
    if (numPages<currentPageLength){
      this.canvasPro.current.deletePage(Math.max(4, insertionPage+numPages), currentPageLength);
    }

    this.setState({PDFloadModalVisible: false, mode:'pen', canvasnum:insertionPage, inputcanvasnum:insertionPage})
  }
  render() {
    const user = {
        id: this.props.sessionuser.id,
        name: this.props.sessionuser.name,
        avatar: `${Api._s3_url}${this.props.sessionuser.image}`,
      }
    const handleImageUpload = (file) => {
        const params = {
            file,
            userId: user.id,
            roomName: this.props.streamId?.split("xxxx")[1],
            type: "image"
          };
          this.props.postChatImage(params, user,
              (newMessage) => {
                  // chat.sendmsg(newMessage, 'Image');
                  this.props.chat.sendGroupChat(user.id, this.props.streamId, 
                      {
                          type: 'Image',
                          data: newMessage,
                          userId: user.id,
                          roomId: this.props.streamId,
                      }
                      )
              }, "Group");
    }
    const imageBeforeUpload = file => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const reader = new FileReader();
      if (!isJpgOrPng) {
        Notification.error("error", "You can only upload JPG/PNG file!");
      } else if (!isLt2M) {
        Notification.error("error", "Image must be smaller than 2MB!");
      } else {
        reader.addEventListener("load", () => {
          handleImageUpload(file);
        });
        reader.readAsDataURL(file);
      }
      return false;
    };
    const handleImageUploadPrivate = (file, imgUrl, isPDF) => {
        const isPPT = file.type === "application/vnd.ms-powerpoint" || 
        file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        const params = {
            file,
            roomId: this.props.streamId?.split('xxxx')[1],
            type: "image",
            hideMsg: true
        };
        if (isPDF){
          this.setState({ PDFloadModalVisible: true, percentPDFLoaded: 0 });
        }
        this.props.postCanvasImage(params, async (newMessage)=>{
            if (isPDF){
              if (isPPT){
                console.log("676", newMessage)
                const response = await this.props.triggerConversion(newMessage.image);
                if (jobstatusinterval){
                  clearInterval(jobstatusinterval)
                  jobstatusinterval = null
                }
                if (response){
                  console.log("679", response)
                  const jobId = response.data.jobId;
                  const endPDFurl = response.endurl;
                  jobstatusinterval = setInterval(()=>{
                    this.props.getConversionStatus(jobId, false, (response)=>{
                      if (response.data?.status!=='in-progress'){
                        clearInterval(jobstatusinterval)
                        jobstatusinterval =null
                        if (response.data?.status==='failed'){
                          Notification.error("error", "Embedding of ppt failed");
                          this.setState({PDFloadModalVisible: false, percentPDFLoaded:0,  mode:'pen'})
                      }else if(response.data.status==='completed'){
                        console.log("699", response.data.status)
                        this.embedPDF(endPDFurl)
                      }
                      }else{

                      }
                    })
                  }, 2000)
                }
              }else{
                this.embedPDF(newMessage.image)
              }
            }else{
                this.addNewImage(newMessage.image, imgUrl);
            }
        });
      }
    const imageBeforeUploadPrivate = (file, imgURL, isPDF=false) => {
        const isJpgOrPngPrivate = isPDF ? (file.type === "application/pdf" || 
                                    file.type === "application/vnd.ms-powerpoint" || 
                                    file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation") 
                                    : file.type === "image/jpeg" || file.type === "image/png" ;
        const isLt2MPrivate = file.size / 1024 / 1024 < 2;
        const isLt70MPrivate = file.size / 1024 / 1024 < 70;
        const readerPrivate = new FileReader();
        if (!isJpgOrPngPrivate) {
            if (isPDF){
                if (this.inputFilePDF?.current){
                    this.inputFilePDF.current.value = ""
                }
                Notification.error("error", "You can only upload pdf/ppt file!");
            }else{
                if (this.inputFilePDF?.current){
                    this.inputFilePDF.current.value = ""
                }
                Notification.error("error", "You can only upload JPG/PNG file!");
            }
            return
        } 
        if (isPDF && !isLt70MPrivate) {
            if (this.inputFilePDF?.current){
                this.inputFilePDF.current.value = ""
            }
            Notification.error("error", `File must be smaller than 70MB!`);
            return
        }else if(!isPDF && !isLt2MPrivate){
            if (this.inputFilePDF?.current){
                this.inputFilePDF.current.value = ""
            }
            Notification.error("error", `Image must be smaller than 70MB!`);
            return
        }
         else {
            readerPrivate.addEventListener("load", () => {
                handleImageUploadPrivate(file, imgURL, isPDF);
            });
            readerPrivate.readAsDataURL(file);
        }
        return false;
    };
    const onChangeFile = (e) => {
      this.setState({
        mode:'image'
      })
      e.preventDefault();
      const file = e.target.files[0];
      if (file != undefined) {
          const imgUrl = URL.createObjectURL(file);
          imageBeforeUploadPrivate(file, imgUrl, false)
      }
    }
    const onChangeFilePDF = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file != undefined) {
            const imgUrl = URL.createObjectURL(file);
            imageBeforeUploadPrivate(file, imgUrl, true)
        }
      }
    const debouncedPageSwitch = (text) => {
      if (pageswitchtimeout){
        clearTimeout(pageswitchtimeout)
        pageswitchtimeout = null
      }
      pageswitchtimeout = setTimeout(()=>{
        if (text!=''){
          this.setState({
            canvasnum: parseInt(text)
          })
        }
      }, 1000);
    }
    const setInputCanvasNum = (page) => {
      this.setState({
        inputcanvasnum: page
      })
      debouncedPageSwitch(page)
    }
    const onPageInputBlur = () => {
      if (this.state.inputcanvasnum===''||this.state.inputcanvasnum==undefined){
        this.setState({inputcanvasnum: this.state.canvasnum})
      }
    }
    return (
      <>
      <div className={`r-c-fs whiteBoard`}
        ref={divElement => {
          this.handleDivRef(divElement);
        }}
        style={{position:"relative", overflow:"hidden", width:screenWidth, height:screenHeight, visibility:this.props.visible?"visible":"hidden"}}
      >
          <PageContainer onPageInputBlur={onPageInputBlur} setInputCanvasNum={setInputCanvasNum} inputcanvasnum={this.state.inputcanvasnum} currentPage={this.state.canvasnum} addCanvas={this.addCanvas} switchCanvas={(page)=>{this.switchCanvas2(page)}} totalPages={this.state.canvaslength}/>
          <div data-html2canvas-ignore onClick={()=>{
            this.setState({
              showToolbar:true
            })
          }}  style={{zIndex:100, display:"flex", alignItems:"center", justifyContent:"center", height:50, backgroundColor:"#ADACB4", width:30, position:"absolute", top:"45%", borderTopRightRadius:50, borderBottomRightRadius:50, cursor:"pointer"}} className={`toolbar-container ${this.state.showToolbar ? 'move-out' : ''}`} >
            <FaChevronRight style={{color:"white"}}/>
          </div>
          <div data-html2canvas-ignore className={`toolbar-container ${!this.state.showToolbar ? 'move-out' : ''}`} style={{...Styles.toolbarContainer, cursor:"default"}}>
          <div style={{
              display:'grid',
              gridTemplateColumns: 'auto auto',
          }}>
          <div style={Styles.toolbarBtn}>
              <div className="full-width full-height r-c-c" style={{ padding:5}}
                onClick={this.handleSelectClick}>
                <AiOutlineSelect color={this.state.mode==='select'?'#6060AA':'#ADACB4'}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <Popover placement="left" content={this.slider} title={()=>(<div className='r-c-fs'><EditOutlined color={'grey'}/>{'  '} <span className="m-l-10">Width</span></div>)} trigger="click" open={this.state.showWidthPicker} onOpenChange={this.openWidthPicker}>
              <div className="full-width full-height r-c-c" style={{ padding:5}}
                onClick={this.handlePenClick}>
                <AiFillEdit color={this.state.mode==='pen'?'#6060AA':'#ADACB4'}/>
              </div>
              </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
              <Popover placement="left" content={this.shapesType} title={()=>(<div className='r-c-fs'>Draw <span className="m-l-5">Shapes</span></div>)} trigger="click" open={this.state.showShapesPicker} onOpenChange={this.openShapesPicker}>
              <div className="full-width full-height r-c-c" style={{ padding:5}}
                onClick={()=>{
                  this.setState({
                    mode: 'drawShape',
                    showShapesPicker: !this.state.showShapesPicker
                  })
                }}>
                  {
                    this.state.shapeType==='rect'?
                    <BiRectangle color={this.state.mode==='drawShape'?'#6060AA':'#ADACB4'}/>
                    :this.state.shapeType==='circle'?
                    <FaRegCircle color={this.state.mode==='drawShape'?'#6060AA':'#ADACB4'}/>
                    :this.state.shapeType==='line'?
                    <HiOutlineMinus style={{transform:'rotate(20deg)', color:this.state.mode==='drawShape'?'#6060AA':'#ADACB4'}} />
                    :null
                  }
              </div>
              </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                className="full-width full-height r-c-c"
                type="text"
                shape="circle"
                style={{ padding:5}}
                onClick={()=>{
                  this.setState({
                    mode:'select'
                  })
                  this.inputFile.current.click();
                        }}
              >
                <label htmlFor="file-input-image">
                    <input type='file' id='file' ref={(el)=>{this.inputFile.current=el}}
                        onChange={onChangeFile}
                        style={{ display: 'none' }} accept='image/*' />
                        <BsFillImageFill color={'#ADACB4'} className="cursor-pointer" />
                </label>
            </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <Popover placement="left" content={this.content} title="Choose a colour" trigger="click" open={this.state.showColorPicker} onOpenChange={this.toggleColorPicker}>
              <div className="full-width full-height r-c-c"
                onClick={this.openColorPicker} style={{padding:5}}
              >
                <ColorElement color={this.state?.color}/>
              </div>
              </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
              <Popover placement="left" content={this.slider} title={()=>(<div className='r-c-fs'><FaEraser/>{'  '} <span className="m-l-10">Width</span></div>)} trigger="click" open={this.state.showEraserWidthPicker} onOpenChange={this.openEraserWidthPicker}>
              <div className="full-width full-height r-c-c" style={{ padding:5}}
                onClick={this.handleEraserClick}
              >
                <FaEraser color={!this.state.mode==='pen'?'#6060AA':'#ADACB4'} />
              </div>
              </Popover>
          </div>
          <div style={Styles.toolbarBtn}>
              <div className="full-width full-height r-c-c"
                onClick={this.handleUndoClick} style={{padding:5}}
              >
                <IoArrowUndo style={{color:"#ADACB4"}}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div className="full-width full-height r-c-c"
                onClick={this.handleRedoClick} style={{padding:5}}
              >
                <IoArrowRedo style={{color:"#ADACB4"}}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                className="full-width full-height r-c-c"
                type="text"
                shape="circle"
                style={{padding:5}}
                onClick={()=>{
                  this.setState({
                    showDeleteModal:true
                  })
                }}
              >
                <AiFillDelete style={{color:"#ADACB4"}}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                type="text"
                shape="circle"
                style={{padding:5}}
                className='r-c-c'
                onClick={() => {
                    const canvas = document.getElementById('broadcast-canvas-download');
                    this.canvasPro.current.drawDC(''+this.state.canvasnum);
                    this.setState({
                        screenshoturl: canvas.toDataURL(),
                        isModalVisible: true
                      })
                }}
                >
                <BsFillShareFill style={{color:"#ADACB4"}}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                type="text"
                shape="circle"
                style={{padding:5}}
                className='r-c-c'
                onClick={() => {
                  this.handlePDFGeneration()
                }}>
                <BiSolidFilePdf style={{color:"#ADACB4"}}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                type="text"
                shape="circle"
                style={{padding:5}}
                className='r-c-c'
                onClick={()=>{
                  if (this.state.uploadedPDFMeta){
                    Notification.error("error", `Remove the uploaded file to upload a new one`);
                    return
                  }
                  this.setState({
                    mode: 'pen'
                  })
                  this.inputFilePDF.current.click();}}
                >
                <label htmlFor="file-input-pdf">
                    <input type='file' id='file-pdf' ref={(el)=>{this.inputFilePDF.current=el}}
                        onChange={onChangeFilePDF}
                        style={{ display: 'none' }} accept=".pdf, .ppt, .pptx" />
                    <FaFileUpload style={{color:"#ADACB4"}} className="cursor-pointer" />
                </label>
              </div>

          </div>

          <div style={Styles.toolbarBtn}>
              <div className="full-width full-height r-c-c" style={{ padding:5}}
                  onClick={()=>{
                    this.setState({
                      showToolbar: false
                    })
                  }}>
                  <FaChevronLeft style={{color:"#ADACB4"}}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                type="text"
                shape="circle"
                style={{padding:5}}
                className='r-c-c'
                onClick={this.handleMoveClick}>
                <BiSolidZoomIn style={{marginTop:2}} size={18} color={this.state.mode==='move'?'#6060AA':'#ADACB4'}/>
              </div>
          </div>
          <div style={Styles.toolbarBtn}>
              <div
                type="text"
                shape="circle"
                style={{padding:5}}
                className='r-c-c'
                onClick={()=>{
                  this.setState({
                    showInfoModal: true
                  })
                }}>
                <FaCircleInfo color={'#ADACB4'}/>
              </div>
          </div>
          </div>
        </div>
 
        <SketchCanvasPro 
          ref={this.canvasPro}
          style={this.props.reactiveCanvas
                ? {width: this.state.canvasWidth, height: this.state.canvasHeight, position:"absolute", top:0, left:0}
                : {width:screenWidth, height:screenHeight, top:0, left:0, position:"absolute", cursor:this.state.mode==='move'?'grab':'default'}} 
          mode={this.state.mode}
          penWidth={this.state.width}
          eraserWidth={this.state.eraserWidth}
          strokeColor={this.state.color}
          reactiveCanvas={this.props.reactiveCanvas?this.props.reactiveCanvas:false}
          shapeType={this.state.shapeType}
          onPath={(offsets, difftwoside, lastUpdateIsImage=false)=>{
            offsets = offsets
            window.ReactNativeWebView?.postMessage(JSON.stringify({event:'pathupdate', lastUpdateIsImage, difftwoside}))
          }}
          currentPage={this.state.canvasnum}
          totalPage={this.state.canvaslength}
          toggler={this.state.toggler}
          />
          <Modal
            centered
            visible={this.state.PDFloadModalVisible}
            className="modal-round-corner-broadcast custom-modal-background"
            style={{borderRadius:50}}
            footer={null}
            mask={true}
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            closable={false}>
              <div className='r-c-fs'>
                <div style={{color:"white"}}>Opening your file, might take a moment</div>
                <div style={{marginLeft:10}}> <Loader fontSize={20} color={"white"}/> </div>
              </div>
              <Progress percent={this.state.percentPDFLoaded} showInfo={false} />
          </Modal>
          <Modal
            centered
            visible={this.state.PDFdownloadModalVisible}
            className="modal-round-corner-broadcast custom-modal-background"
            style={{borderRadius:50}}
            footer={null}
            mask={true}
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            closable={false}>
              <div className='r-c-fs'>
                <div style={{color:"white"}}>Preparing file for download, might take a moment</div>
                <div style={{marginLeft:10}}> <Loader fontSize={20} color={"white"}/> </div>
              </div>
              <Progress percent={this.state.percentPDFLoaded} showInfo={false} />
          </Modal>
          <Modal
            centered
            visible={this.state.showDeleteModal}
            className="modal-round-corner-broadcast"
            style={{borderRadius:50}}
            footer={null}
            mask={true}
            closable={true}
            onCancel={()=>{
              this.setState({
                showDeleteModal: false
              })
            }}
            >
              <div style={{ color: '#FF6347', marginTop:30, marginBottom:10, fontWeight:"bold", fontSize:12 }}>Selecting any of the below option will also clear the undo/redo history</div>
              <div style={{display:'grid', gridTemplateColumns: 'auto auto', rowGap:20}}>
                <Button width={200} onClick={()=>{
                  const start = this.state.uploadedPDFMeta.insertionPage;
                  const end = this.state.uploadedPDFMeta.insertionPage + this.state.uploadedPDFMeta.numPages-1;

                  if (!this.state.uploadedPDFMeta|| this.state.canvasnum<start || this.state.canvasnum>end){
                    this.handleClearClick();
                  }else{
                    this.handleClearOverlay();
                  }
                  this.setState({
                    showDeleteModal: false
                  })
                }} type="primary">Clear page</Button>
                {
                  this.state.uploadedPDFMeta ?
                  <>
                  <Button width={200} onClick={()=>{
                    this.handleClearPDF();
                    this.setState({
                      showDeleteModal: false
                    })
                    Notification.success("success", "Deleted the file successfully");
                  }} type="primary">Delete uploaded file</Button>
                  </>
                  :
                  null
                }
                  <Button width={200} onClick={()=>{
                    this.handleClearAll();
                    this.setState({
                      showDeleteModal: false
                    })
                    Notification.success("success", "Deleted all successfully");
                  }} type="primary">Delete All</Button>
              </div>
          </Modal>
          <Modal
            centered
            visible={this.state.showInfoModal}
            className="modal-round-corner-broadcast custom-modal-background"
            style={{borderRadius:50}}
            footer={null}
            mask={true}
            onCancel={()=>{
              this.setState({
                showInfoModal: false
              })
            }}
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            closable={false}>
              <div style={{display:'grid', gridTemplateColumns: 'auto auto'}}>
                <InfoElement Icon={AiOutlineSelect} label={'Select shape/image'}/>
                <InfoElement Icon={AiFillEdit} label={'Pen'}/>
                <InfoElement Icon={this.state.shapeType==='rect'?BiRectangle:this.state.shapeType==='circle'?FaRegCircle:this.state.shapeType==='line'?HiOutlineMinus:null} label={'Choose a shape to draw'}/>
                <InfoElement Icon={BsFillImageFill} label={'Insert image'}/>
                <InfoElement Icon={()=><ColorElement color={this.state?.color}/>} label={'Choose color'}/>
                <InfoElement Icon={this.state.shapeType==='rect'?BiRectangle:this.state.shapeType==='circle'?FaRegCircle:this.state.shapeType==='line'?HiOutlineMinus:null} label={'Choose a shape to draw'}/>
                <InfoElement Icon={FaEraser} label={'Eraser'}/>
                <InfoElement Icon={IoArrowUndo} label={'Undo'}/>
                <InfoElement Icon={IoArrowRedo} label={'Redo'}/>
                <InfoElement Icon={AiFillDelete} label={'Clear current page'}/>
                <InfoElement Icon={BsFillShareFill} label={'Share current page in chat'}/>
                <InfoElement Icon={BiSolidFilePdf} label={'Download all pages as a pdf'}/>
                <InfoElement Icon={FaFileUpload} label={'Insert pdf/ppt'}/>
                <InfoElement Icon={FaChevronLeft} label={'Hide toolbar'}/>
                <InfoElement Icon={BiSolidZoomIn} label={'Zoom and then pan'}/>
                <InfoElement Icon={FaCirclePlus} label={'Insert a fresh board at the end'}/>
                <InfoElement Icon={FaCaretLeft} label={'Move to previous page'}/>
                <InfoElement Icon={FaCaretRight} label={'Move to next page'}/>
              </div>
              {/* <MoreInfoElement label={"Use pinch in gesture to zoom in/out if you're using touchpad"}/> */}
              {/* <MoreInfoElement label={"Use pan gesture to pan if you're using touchpad"}/> */}
              <MoreInfoElement label={"Select the zoom mode, then Press ctrl key and scroll the mouse wheel to zoom in/out if you're using hardware mouse"}/>
              <MoreInfoElement label={"By clicking and editing the current page input box on the bottom right page controller, you can navigate to the page of your choice"}/>
              {/* <MoreInfoElement label={"Press down the left key of mouse and drag to pan if you're using hardware mouse"}/> */}
          </Modal>
          <Modal wrapClassName="imagePreview" centered={true} okText={"Send"}
          visible={this.state.isModalVisible}
          onOk={async () => {
            let blob = await fetch(this.state.screenshoturl).then(r => r.blob());
            this.setState({
              screenshotfile: blob,
              screenshoturl: '',
              isModalVisible: false
            });
            imageBeforeUpload(this.state.screenshotfile);
          }}
          onCancel={() => {
            this.setState({
              screenshoturl: '',
              isModalVisible: false
            })
          }}>
          <div style={{height: "60%" }}>
            <img style={{ width: "100%", height: "100%" }} src={`${this.state.screenshoturl}`} alt="" />
          </div>
        </Modal>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
    const { user } = state.session;
    const { streamId, paths, roomboardData, isScheduledTeacher } = state.broadcast;
    const sessionuser = user;
    return {
      sessionuser,
      streamId,
      paths,
      roomboardData,
      isScheduledTeacher
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    postChatImage: (params, user, callback, type) => dispatch(postChatImage(params, user, callback, type)),
    storePaths: (roomName, paths) => dispatch(storePaths(roomName, paths)),
    setRoomBoardData: (id, data) => dispatch(setRoomBoardData(id, data)),
    postCanvasImage: (params, callback) => dispatch(postCanvasImage(params, callback)),
    postRoomData: (data, callback) => dispatch(postRoomData(data, callback)),
    triggerConversion: (fileUrl) => dispatch(triggerConversion(fileUrl)),
    getConversionStatus: (id, c, sc) => dispatch(getConversionStatus(id, c, sc))
  })
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(PassiveCanvas);
