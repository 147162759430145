import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import {setDeleteLoader,deleteLocationCard, deleteLeave} from "actions/attendanceNew";
import "../style.css";
import { connect } from "react-redux";

const ConfirmDeleteModal = ({isModalVisible,deleteLeave, setIsModalVisible,deleteLocationCard,deleteLoader,deleteVar, deleteEditVar,key,testing,title}) => {

  console.log("test",testing)
   const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    console.log('vars',deleteVar, deleteEditVar)
  };
  
  
  return (
    <>
      <Modal
        className="modal-round-corner"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
        <div 
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <div
            style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}
          >
            Confirm
          </div>
          <div
            style={{
              fontSize: "16px",
              color: "#636363",
              fontWeight: "bold",
              marginTop: "10px",
              textAlign:'center'
            }}
          >
            {deleteVar?'Are you sure you want to delete ?':`Are you sure you want to delete ${title?`(${title})`:''}?`}
            
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "30px",
          }}
        >
          <div>
            <Button
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid  #636363",
                backgroundColor: "#FFFFFF",
                color: "#594099",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
                onClick={handleOk}
              >
                NO
              </div>
            </Button>
          </div>
          <div>
            <Button
            loading={deleteLoader}
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid #594099 ",
                backgroundColor: "#594099",
                fontSize: "16px",
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
              onClick={()=>{
                // handleCancel();
                console.log('vars',deleteVar, deleteEditVar)
         
                if(deleteVar){
                  deleteLeave(
                    ()=>{
                      handleCancel()
                    }
                  );
                }else{
                  deleteLocationCard(
                    ()=>{
                      handleCancel()
                    }
                  );
                }
                }}
            >
                YES
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const {deleteLoader}=state.attendanceNew;
  return {deleteLoader};
};

const mapDispatchToProps = (dispatch) => ({
  deleteLeave: (callBack) => dispatch(deleteLeave(callBack)),
  deleteLocationCard: (callBack) => dispatch(deleteLocationCard(callBack)),
  setDeleteLoader:(val) => dispatch(setDeleteLoader(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal);


