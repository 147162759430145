import React from 'react';
import Result from 'antd/lib/result';
import 'antd/lib/result/style/index';


class ResetSuccess extends React.Component {

  render() {


    return (
      <div style={{height: '100%'}} className="r-c-c-c">
        <Result
          status="success"
          title="Password updated successfully!"
        />
      </div>

    )
  }
}


export default ResetSuccess;
