import { Utils, Api, BrigoshaMedia } from 'services';
import { toggleVideo, toggleAudio, toggleSnackbar } from 'actions/common';

const brigoshaMedia = new BrigoshaMedia();

export const SET_CANVAS_REF = 'SET_CANVAS_REF';
export const PEN_STROKE_SET = 'PEN_STROKE_SET';
export const ERASER_STROKE_SET = 'ERASER_STROKE_SET';
export const TOGGLE_CHAT_LOADER = 'TOGGLE_CHAT_LOADER';
export const TOGGLE_CALL_MODAL = 'TOGGLE_CALL_MODAL';
export const UPDATE_ROOM_NAME = 'UPDATE_ROOM_NAME';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const UPDATE_CHATS = 'UPDATE_CHATS';
export const RESET_CHATS = 'RESET_CHATS';
export const UPDATE_ONLINE_OFFLINE_USER = 'UPDATE_ONLINE_OFFLINE_USER';
export const UPDATE_ONLINE_USERS = 'UPDATE_ONLINE_USERS';
export const UPDATE_ROOM_USERS = 'UPDATE_ROOM_USERS';
export const TOGGLE_IMAGE_LOADER = 'TOGGLE_IMAGE_LOADER';
export const UPDATE_CANVAS_DATA = 'UPDATE_CANVAS_DATA';
export const UPDATE_PATH_DATA = 'UPDATE_PATH_DATA';
export const TOGGLE_TEACHER_CANVAS = 'TOGGLE_TEACHER_CANVAS';
export const TOGGLE_CHAT_BADGE = 'TOGGLE_CHAT_BADGE';
export const CLEAR_CANVAS = 'CLEAR_CANVAS';
export const INIT_CANVAS = 'INIT_CANVAS';
export const UNDO_CANVAS = 'UNDO_CANVAS';
export const UPDATE_CLASS_JOIN = 'UPDATE_CLASS_JOIN';
export const NEW_USER_JOIN = 'NEW_USER_JOIN';
export const USER_LEFT = 'USER_LEFT';
export const UPDATE_HAND_RAISE = 'UPDATE_HAND_RAISE';
export const UPDATE_PATH_ARRAY = 'UPDATE_PATH_ARRAY';
export const EMPTY_PATH_ARRAY = 'EMPTY_PATH_ARRAY';
export const UPDATE_STUDENT_PATH_ARRAY = 'UPDATE_STUDENT_PATH_ARRAY';
export const EMPTY_STUDENT_PATH_ARRAY = 'EMPTY_STUDENT_PATH_ARRAY';
export const POPPED_PATH_ARRAY = 'POPPED_PATH_ARRAY';
export const TOGGLE_DOUBLE_PIP = 'TOGGLE_DOUBLE_PIP';
export const UPDATE_MY_HAND_RAISE = 'UPDATE_MY_HAND_RAISE';
export const SWITCH_STUDENT_CANVAS = 'SWITCH_STUDENT_CANVAS';
export const ON_RESETCHAT_BADGE = 'ON_RESETCHAT_BADGE';
export const UPDATE_HAND_RAISE_Q = 'UPDATE_HAND_RAISE_Q';
export const UPDATE_STUDENT_HAND_RAISE_Q = 'UPDATE_STUDENT_HAND_RAISE_Q';
export const REMOVE_HAND_RAISE_Q = 'REMOVE_HAND_RAISE_Q';
export const UPDATE_IN_HAND_RAISE_Q = 'UPDATE_IN_HAND_RAISE_Q';
export const ACCEPT_HAND_RAISE_Q = 'ACCEPT_HAND_RAISE_Q';
export const UPDATE_STUDENT_PAGES = 'UPDATE_STUDENT_PAGES';
export const CLEAR_PAGES = 'CLEAR_PAGES';
export const RESET_PARTICIPANTS = 'RESET_PARTICIPANTS';
export const SAVE_STATE_TO_DB = 'SAVE_STATE_TO_DB';
export const UPDATE_TEACHER_STATUS = 'UPDATE_TEACHER_STATUS';
export const SET_LIVE_CLASS = 'SET_LIVE_CLASS';
export const RESET_HAND_RAISE_Q = 'RESET_HAND_RAISE_Q';
export const UPDATE_CONF_ID = 'UPDATE_CONF_ID';
export const UPDATE_CANVAS_RECONNECT = 'UPDATE_CANVAS_RECONNECT';
export const ALLOW_CANVAS_SWITCH = 'ALLOW_CANVAS_SWITCH';
export const UPDATE_SCREEN_RECORDING_STATUS = 'UPDATE_SCREEN_RECORDING_STATUS';
export const UPDATE_ALL_PAGES = 'UPDATE_ALL_PAGES';
export const UPDATE_AV_MUTE = 'UPDATE_AV_MUTE';
export const UPDATE_CLASS_MODE = 'UPDATE_CLASS_MODE';
export const UPDATE_SHOW_LOAD_EARLIER = 'UPDATE_SHOW_LOAD_EARLIER';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'

export const updateShowLoadEarlier = data => ({
  type: UPDATE_SHOW_LOAD_EARLIER,
  data,
});

export const updateAVMute = data => ({
  type: UPDATE_AV_MUTE,
  data,
});

export const updateScreenRecordingStatus = value => ({
  type: UPDATE_SCREEN_RECORDING_STATUS,
  value,
});

export const setLiveClass = payload => ({
  type: SET_LIVE_CLASS,
  payload,
});

export const updateTeacherStatus = value => ({
  type: UPDATE_TEACHER_STATUS,
  value,
});

export const saveStateToDb = () => ({
  type: SAVE_STATE_TO_DB,
});

export const canvasRefSet = ref => ({
  type: SET_CANVAS_REF,
  ref,
});

export const acceptHandRaiseQ = user => ({
  type: ACCEPT_HAND_RAISE_Q,
  user,
});

export const toggleCallModal = value => ({
  type: TOGGLE_CALL_MODAL,
  value,
});

export const resetParticipants = () => ({
  type: RESET_PARTICIPANTS,
});

export const resetHandRaiseQ = () => ({
  type: RESET_HAND_RAISE_Q,
});

export const updateInHandRaiseQ = value => ({
  type: UPDATE_IN_HAND_RAISE_Q,
  value,
});

export const removeHandRaiseQ = user => ({
  type: REMOVE_HAND_RAISE_Q,
  user,
});

export const updateStudentHandRaiseQ = users => ({
  type: UPDATE_STUDENT_HAND_RAISE_Q,
  users,
});

export const updateHandRaiseQ = users => ({
  type: UPDATE_HAND_RAISE_Q,
  users,
});

export const updateMyHandRaise = value => ({
  type: UPDATE_MY_HAND_RAISE,
  value,
});

export const updateStudentPages = (currentPage, isReconnect) => ({
  type: UPDATE_STUDENT_PAGES,
  currentPage,
  isReconnect,
});

export const allowCanvasSwitch = value => ({
  type: ALLOW_CANVAS_SWITCH,
  value,
});

export const updateCanvasReconnect = value => ({
  type: UPDATE_CANVAS_RECONNECT,
  value,
});

export const clearPages = () => ({
  type: CLEAR_PAGES,
});

export const toggleDoublePip = value => ({
  type: TOGGLE_DOUBLE_PIP,
  value,
});

export const poppedPathArray = array => ({
  type: POPPED_PATH_ARRAY,
  array,
});

export const updateStudentPathArray = paths => ({
  type: UPDATE_STUDENT_PATH_ARRAY,
  paths,
});

export const emptyPathArray = () => ({
  type: EMPTY_PATH_ARRAY,
});

export const updatePathArray = (path, currentPage) => ({
  type: UPDATE_PATH_ARRAY,
  path,
  currentPage,
});

export const updateAllPages = pages => ({
  type: UPDATE_ALL_PAGES,
  pages,
});

export const updateHandRaise = value => ({
  type: UPDATE_HAND_RAISE,
  value,
});

export const updateClassJoin = value => ({
  type: UPDATE_CLASS_JOIN,
  value,
});

export const clearCanvas = () => ({
  type: CLEAR_CANVAS,
});

export const undoCanvas = () => ({
  type: UNDO_CANVAS,
});

export const initCanvas = canvas => ({
  type: INIT_CANVAS,
  canvas,
});

export const toggleChatBadge = value => ({
  type: TOGGLE_CHAT_BADGE,
  value,
});

export const onResetChatBadge = () => ({
  type: ON_RESETCHAT_BADGE,
});

export const toggleTeacherCanvas = value => ({
  type: TOGGLE_TEACHER_CANVAS,
  value,
});

export const updateRoomName = roomName => ({
  type: UPDATE_ROOM_NAME,
  roomName,
});

export const updateClassMode = mode => ({
  type: UPDATE_CLASS_MODE,
  mode,
});

export const updateConfId = id => ({
  type: UPDATE_CONF_ID,
  id,
});

export const updateChat = chat => ({
  type: UPDATE_CHAT,
  chat,
});

export const updateChats = (chats, currentPage) => ({
  type: UPDATE_CHATS,
  chats,
  currentPage,
});

export const resetChats = (chats, currentPage) => ({
  type: RESET_CHATS,
  chats,
  currentPage,
});

export const toggleChatLoader = value => ({
  type: TOGGLE_CHAT_LOADER,
  value,
});

export const toggleImageLoader = value => ({
  type: TOGGLE_IMAGE_LOADER,
  value,
});

export const updateRoomUsers = (users, id) => ({
  type: UPDATE_ROOM_USERS,
  users,
  id,
});

export const updateOnlineUsers = users => ({
  type: UPDATE_ONLINE_USERS,
  users,
});

export const updateUsers = payload => ({
  type: UPDATE_ONLINE_OFFLINE_USER,
  payload,
});

export const updateCanvasData = data => ({
  type: UPDATE_CANVAS_DATA,
  data,
});

export const updatePathData = data => ({
  type: UPDATE_PATH_DATA,
  data,
});

export const newUserJoin = data => ({
  type: NEW_USER_JOIN,
  data,
});

export const switchStudentCanvasAction = data => ({
  type: SWITCH_STUDENT_CANVAS,
  data,
});

export const penStrokeSet = value => ({
  type: PEN_STROKE_SET,
  value,
});

export const eraserStrokeSet = value => ({
  type: ERASER_STROKE_SET,
  value,
});

export const showSnackBar = message => dispatch => {
  dispatch(toggleSnackbar(message));
};

export const updateLeaveToServer = () => (dispatch, getState) => {
  const { confId } = getState().liveClass;
  const params = {
    conferenceId: confId,
  };
  return Api.post('/attendance')
    .params(params)
    .send(() => { });
};

export const endCall = () => (dispatch, getState) => {

  const { isTeacher } = getState().liveClass;
  const { handRaisedUser, isInHandRaiseQ, isMyHandRaise, isHandRaiseActive } =
    getState().liveClass;
  dispatch(updateClassJoin(false));
  dispatch(updateClassMode(false));
  brigoshaMedia.updateClassJoinStatus(false);
  if (isInHandRaiseQ) {
    dispatch(updateInHandRaiseQ(false));
  }
  if (isMyHandRaise) {
    dispatch(updateMyHandRaise(false));
  }
  if (isHandRaiseActive && isTeacher) {
    dispatch(removeHandRaiseQ({ id: handRaisedUser }));
  }

  brigoshaMedia.exit();
  // TODO:
  // dispatch(updateLeaveToServer());
  dispatch(toggleAudio(true));
  dispatch(toggleVideo(true));
  dispatch(resetChats([], 1));
  dispatch(resetParticipants());
  dispatch(updateHandRaise(false));
};

export const getPreviousChat = (roomName, page) => dispatch => {
  const params = {
    roomName,
    page: page || 1,
  };
  return Api.get('/chat/load')
    .params(params)
    .send(response => {
      dispatch(toggleChatLoader(false));
      if (typeof response !== 'undefined') {
        dispatch(updateShowLoadEarlier(!response?.disableLoadEarlier));
        if (typeof response.chats.items !== 'undefined') {
          const messages = [];
          response.chats.items.forEach(item => {
            item.user.image = `${Api._s3_url}${item.user.image}`;
            if (item.type === 'Image') {
              messages.push(
                Utils.sendMessage(
                  '',
                  item.user,
                  { image: `${Api._s3_url}${item.message}` },
                  item.updatedAt,
                ),
              );
            } else {
              messages.push(
                Utils.sendMessage(item.message, item.user, {}, item.updatedAt),
              );
            }
          });
          dispatch(
            updateChats(messages.slice().reverse(), parseInt(response.chats.meta.currentPage)),
          );
        }
      }
    });
};

export const postChat = (payload, message) => (dispatch, getState) =>
  Api.post('/chat')
    .params(payload)
    .send((response, error) => {
      if (typeof response !== 'undefined') {
        brigoshaMedia.sendMessage(message);
      }
    });

export const postChatImage = (params, user) => (dispatch, getState) => {
  dispatch(toggleImageLoader(true));
  return Api.post('/chat/upload').upload(params, (percent, response) => {
    if (percent == 100 || percent == -1) {
      dispatch(toggleImageLoader(false));
    }
    if (typeof response !== 'undefined' && percent == 100) {
      const image = `${Api._s3_url}${response.data.message}`;
      let newUser = JSON.parse(JSON.stringify(user));
      newUser.image = `${Api._s3_url}${newUser.image}`;
      dispatch(
        updateChat(
          Utils.sendMessage('', newUser, { image }, response.data.updatedAt),
        ),
      );
      const message = Utils.sendMessage(
        '',
        newUser,
        {
          image,
        },
        response.data.updatedAt,
      );
      brigoshaMedia.sendMessage(message);
    }
  });
};

export const updateUserRole = payload => ({
  type: UPDATE_USER_ROLE,
  payload,
});
