import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";

const LeaveDonutGraph = ({leaves}) => {
  const color={
    Sick:"#FFA931",
    Marriage:"#99DED9",
    Paternity:"#28DF99",
    Maternity:"#B2DFFF",
    Casual:"#FEA5DD",
    Privilege:"#9876E6",
  }
  let colorArray=["#FFA931", "#FEA5DD", "#9876E6", "#99DED9", "#28DF99", "#FFA931"] ;
  const data = leaves?.map((item,index )=>{
    colorArray[index] = color[item.leaveType];
    return {
      type: item.leaveType +' Leave',
      value: item.numberOfDays,
       color:[`${color[item.leaveType]}`]
    }
  })
  console.log(data,"data")
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    // color:["#FFA931", "#FEA5DD", "#9876E6", "#99DED9", "#28DF99", "#FFA931"] ,
    color:colorArray,
    radius: 0.7,
    innerRadius: 0.7,
    label: {
      type: "inner",
      offset: "-50%",
      content: "",
      style: {
        textAlign: "center",
        fontSize: 8,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        customHtml: function customHtml() {
          return (
                  <div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <div style={{fontSize:"24px", fontWeight:"700"}}> {`${data?.map((item)=>item.value)?.reduce((a, b) => a + b, 0)}`}</div>
                      <div style={{fontSize: "14px", color: "#191919", fontWeight:"300",marginLeft:"5px",marginTop:"10px",fontFamily:"roboto"}}>Days</div>
                      </div>
                      <div style={{marginTop: "5px",fontSize: "14px", color: "#636363", fontWeight:"300",marginLeft:"10px"}}>
                      Total Leaves</div>
                  </div>)
        }
        // content: `${data?.map((item)=>item.value)?.reduce((a, b) => a + b, 0)} days Total leave`,
  
      },
    },
  };

  return <Pie {...config} />;
};

export default LeaveDonutGraph;

/* 
color:`${item.leaveType==="Sick"?"#FFA931":
item.leaveType==="Marriage"?"#99DED9":
item.leaveType==="Paternity"?"#28DF99":
item.leaveType==="Maternity"?"#B2DFFF":
item.leaveType==="Casual"?"#FEA5DD":
item.leaveType==="Privilege"?"#9876E6":null}`  */