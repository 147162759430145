export const urlWithoutMenu = [
    {
        urlPath:"/broadcast"
    },
    {
        urlPath:"/resource-details"
    },
    {
        urlPath:"/user-checkout"
    },
    {
        urlPath:"/saved-for-later"
    },
    {
        urlPath:"/cart"
    },
    {
        urlPath:"/all-popular-courses"
    },
    {
        urlPath:"/all-courses"
    },
    {
        urlPath:"/liveclass"
    },
    {
        urlPath:"/home"
    },
    {
        urlPath:"/search"
    },
    {
        urlPath:"/student-test/questions"
    },
    {
        urlPath:"/ongoingTest/questions"
    },
    {
        urlPath:"/"
    }
    

];