import React, { useState, useEffect } from 'react'
import { Api } from 'services';

export const generatePaymentRequest = (response, record, userDetails, fromMarket) => {
 console.log("record",record);
  const cashfree = Cashfree({
    mode: "production", // or "production"
    // other configuration options
  });

  console.log({ cashfree });
  console.log({ response });
  let checkoutOptions = {
    paymentSessionId: response?.payment_session_id,
    returnUrl:`${Api?._base_url}/redirect-to?orderId=${response?.order_id}&id=${record?.transactionId}&userId=${userDetails?.id}&fromMarket=${fromMarket}`,
    // redirectTarget: "_blank",
  }

  console.log({ checkoutOptions });

  cashfree.checkout(checkoutOptions).then(function (result) {
    console.log({ result });

    if (result.error) {
      alert(result.error.message)
    }
    if (result.redirect) {
      console.log("Redirection")
      
    }
  });

  console.log("AAA- success");
  let options = {
    values: {
      upiId: ""
    }
  };

  let component = cashfree.create("upiCollect", options);
  console.log({ component });

  const div = document.createElement('div');
  div.setAttribute("id", "componentContainer");
  document.body.appendChild(div);

  component.mount("#componentContainer");



  let paymentOptions = {
    paymentMethod: component,
    paymentSessionId: response?.payment_session_id,
    returnUrl: `${Api?._base_url}/payment-success/?orderId=${response?.order_id}&id=${record?.transactionId}&userId=${userDetails?.id}`,

  };

  console.log({ paymentOptions });

  cashfree.pay(paymentOptions).then(function (result) {
    console.log({ result });
    if (result.error) {
      //there is an error
      //message at result.error.message
    }
    if (result.cancelled) {
      // User cancelled the payment
      // Close the tab or perform any other action
      window.close();
    }
    if (result.redirect) {
      //console.log("User would be redirected");
    }
    if (result.paymentDetails) {
      //only when redirect = 'if_required' for UPI payment
      //payment is successful 
      //message is at result.paymentDetails.paymentMessage
    }
  });

}

// try {
//   const result = await cashfree.pay(checkoutOptions);
//   console.log("AAA-after  pay", result);

//   // Handle the payment result
//   if (result.error) {
//     // Handle payment error
//     alert(result.error.message);
//   } else if (result.cancelled) {
//     // User cancelled the payment
//     // Close the tab or perform any other action
//     window.close();
//   } else {
//     // Payment was successful
//     console.log('Payment successful');

//     // Call the API after successful payment
//     callYourApiFunction(response, record, userDetails);
//   }
// } catch (error) {
//   // Handle other errors
//   console.error('Error during payment:', error);
// }

// console.log("AAA- success");

// // ... (rest of the code)
// };