import React, { useEffect } from "react";
import "../style.css";
// import "antd/dist/antd.css";
import { Modal, Button, Input, DatePicker, Space } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createHoliday, createHolidayList, storeCreateHoliday,holidayLoader, clearHolidayList} from "actions/attendanceNew";
import moment from "moment";

const CreateModal = ({ holidayCreateLoader,isModalVisible, setIsModalVisible, createHoliday, storeCreateHoliday, createHolidayList, clearHolidayList }) => {
  const history = useHistory();
  // const [visible,setVisible]=useState(false)

  function disabledDate(current) {
    return current < moment().startOf('day');
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = () => {
    setIsModalVisible(false);
    // clearHolidayList()
//  createHoliday()
  };


  useEffect(() => {
    storeCreateHoliday("title",'');
    storeCreateHoliday("date",'');

  }, [])
  

  return (
  
        <Modal
          className="modal-round-corner"
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleCreate}
          footer={null}
        >
          <div
            style={{
              marginTop: "30px",
              fontSize: "24px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Create Holiday
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Holiday title
              </h6>
              <Input
               onChange={(e) => storeCreateHoliday('title', e.target.value.replace(/\s/g, '')) }
                style={{
                  fontSize: "16px",
                  color: "#191919",
                }}
                placeholder="Enter holiday name"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Holiday date
              </h6>
              <Space>
                <DatePicker 
                format={'DD-MM-YYYY'}
                allowClear={false}
              // disabledDate={disabledDate}

                
                 onChange={(e) =>{ 
                 
                  storeCreateHoliday('date', e.format('YYYY-MM-DD')) }}
                  style={{
                    fontSize: "16px",
                    color: "#AEAEAE",
                    width: "470px",
                  }}
                  placeholder="Select holiday date"
                />
              </Space>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #636363 ",
                  color: "#636363",
                }}
                onClick={handleCancel}
                
              >
                CANCEL 
              </Button>
            </div>
            <div>
              <Button
                // disabled={true}
                disabled={(createHolidayList.title === '' || createHolidayList.date === '') ? true : false}
                loading={holidayCreateLoader}
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#594099",
                  color: "#FFFFFF",
                  opacity: (createHolidayList.title == '' || createHolidayList.date == '') ? 0.5 : 1,
                }}
                onClick={()=>{
                  createHoliday(()=>{setIsModalVisible(false)})
                }}
              >
              CREATE 
            </Button>
          </div>
        </div>
      </Modal>
     
  );
};
const mapStateToProps = (state) => {
  const {createHolidayList,holidayCreateLoader} = state.attendanceNew;
  return {createHolidayList,holidayCreateLoader};
};

const mapDispatchToProps = (dispatch) => ({
  storeCreateHoliday:(key,value)=>dispatch(storeCreateHoliday(key,value)),
  createHoliday: (callBack)=> dispatch(createHoliday(callBack)),
  holidayLoader:(val)=>dispatch(holidayLoader(val)),
  // clearHolidayList:()=>dispatch(clearHolidayList())
  // createHoliday:(res)=>dispatch(createHoliday(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);

