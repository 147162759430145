import { Checkbox, Input, Modal, Select, Tooltip } from 'antd'
import { ModalSubTitle } from 'components/Typography'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IoMdMove } from "react-icons/io";
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { editClasses, getAllClasses, postAddClassWithImport } from 'actions/classRoom';
import ConfirmDeleteModal from './confirmDeleteModal';
import TransferClassModal from './transferClass';
import { Notification } from 'services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AnimatedTextField from 'components/AnimatedTextField';
import AnimatedDatePicker from 'components/AnimatedDatePicker';
import moment from 'moment';

const { Option } = Select

const SectionCourseRow = ({ section, courseName, index, courses, editClassDetails, setEditClassDetails, selectedClassDetails, setConfirmDeleteModal, storeSectionIndex, setSection, setIsTransferModalVisible, setSelectedClassId, id, setIsImportChecked, alreadyImported, fromCourseDetails, isFromCourseDetailsReducer, date }) => {
  const [isImported, setImported] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  function onFill(index, key, val) {
    const copydetails = [...editClassDetails]
    if (copydetails[index]) {
      copydetails[index][`${key}`] = val;
    } else {
      copydetails.push({
        index,
        [`${key}`]: val
      })
    }
    setEditClassDetails(copydetails)
  }

  useEffect(()=>{
    setSelectedDate(date ? moment(date).format('DD/MM/YYYY') : null)
  },[])

  const handleDateChange = (date, dateString) => {

    if (!dateString) {
      // Clear the selected date
      setSelectedDate(null);
      onFill(index, "startDate", null); // Pass null to indicate no date
  } else {
      setSelectedDate(dateString);
      onFill(index, "startDate", convertDateToISO(dateString));
  }

};

const convertDateToISO = (dateString) => {
  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split('/');
  // Return the ISO format as yyyy-mm-dd
  return `${year}-${month}-${day}`;
};

// startDate:selectedDate ? convertDateToISO(selectedDate) : null,
  return (
    <div className='r-jsb m-b-10'>
      <div className='m-r-20'>
        <div style={{width:350}}>
        <AnimatedTextField
          label={"Section"}
          isMandatory={true}
          inputId={id}
          value={section}
          handleInputChange={(e) => {
            if (e.target.value.length > 3) {
              return e.preventDefault();
            }
            onFill(index, "section", e.target.value)
          }}
        />
        </div>
        <div>
          <AnimatedDatePicker
              inputReadOnly={true}
              label={"Start date"}
              // isMandatory={true}
              // inputId={"-999"}
              value={selectedDate}
              handleDateChange={handleDateChange}
              allowClear={true}

            />
          </div>
        {/* <ModalSubTitle>Section<span style={{ color: "red" }}> *</span></ModalSubTitle>
        <Input
          className='m-b-10'
          placeholder="Maximum 3 characters"
          style={{ width: fromCourseDetails || isFromCourseDetailsReducer ? 350 : 230, marginLeft: 2 }}
          value={section}
          onChange={(e) => {
            if (e.target.value.length > 3) {
              return e.preventDefault();
            }
            onFill(index, "section", e.target.value)
          }}
        /> */}
        {fromCourseDetails || isFromCourseDetailsReducer ?
          <div className='r-ac'>
            <Checkbox style={{ color: "#191919", fontSize: 12, fontFamily: "roboto", marginTop: 5 }} onChange={(e) => {
              if (e.target.checked) setIsImportChecked(prev => [...prev, id]);
              else setIsImportChecked(prev => prev.filter(el => el != id));
              setImported(prev => !prev);
            }
            } disabled={(alreadyImported == editClassDetails?.[index]?.courseId)} checked={isImported || (alreadyImported == editClassDetails?.[index]?.courseId)}>Import predefined curriculum from course</Checkbox>
          </div>
          :
          <></>
        }
      </div>
      {fromCourseDetails || isFromCourseDetailsReducer ? <></> :
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div style={{display:'flex'}}><ModalSubTitle>Course</ModalSubTitle><span style={{ color: 'red' }}>&nbsp;*</span></div>    
            <Select
              placeholder="Select a course"
              style={{ width: 230 }}
              value={courseName}
              onChange={(value, key) => { setImported(false); onFill(index, "courseName", value.trim()); onFill(index, "courseId", key.key) }}>
              {courses?.response?.items?.map((item) => (
                <Option key={item?.id} value={item?.courseName}>{item?.courseName}</Option>
              ))}
            </Select>
          </div>
          <Checkbox style={{ color: "#191919", fontSize: 12, fontFamily: "roboto", marginTop: 5 }} onChange={(e) => {
            if (e.target.checked) setIsImportChecked(prev => [...prev, id]);
            else setIsImportChecked(prev => prev.filter(el => el != id));
            setImported(prev => !prev);
          }
          } disabled={(alreadyImported == editClassDetails?.[index]?.courseId)} checked={isImported || (alreadyImported == editClassDetails?.[index]?.courseId)}>Import predefined curriculum from selected course</Checkbox>
        </div>
      }
      <Tooltip title="Transfer Section">
        <div className="r-c-c icon-square-bg__yellow cursor-pointer m-t-30" onClick={() => { setSection(section); setSelectedClassId(id); setIsTransferModalVisible(true) }}>
          <IoMdMove />
        </div>
      </Tooltip>
      <Tooltip title="Delete Section">
        <DeleteOutlined
          style={{ color: "red", fontSize: 20, margin: "35px 15px 0px 0px", cursor: "pointer", }}
          onClick={() => {
            if (editClassDetails?.[index]?.studentsCount > 0) {
              Notification.error("Error", 'Cannot delete a class with students!');
            }
            else {
              storeSectionIndex(index); setSection(section); setConfirmDeleteModal(true);
            }
          }} />
      </Tooltip>
    </div>
  )
}

function EditClassModal({ showEditClassModal, setShowEditClassModal, selectedClassDetails, courses, editClasses, getAllClasses, postAddClassWithImport, isInsideSingleClass, togglePageLoader, getSubjStudentsData, selectedCourseId, getCourseData, fromCourseDetails, isFromCourseDetailsReducer, newCourseId }) {
  const [className, setClassName] = useState('');
  const [editClassDetails, setEditClassDetails] = useState([]);
  const [sectionIndex, storeSectionIndex] = useState(null)
  const [section, setSection] = useState(null)
  const [editClassLoader, setEditClassLoader] = useState(false);
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [isTransferClassVisible, setIsTransferModalVisible] = useState(false)
  const [sectionsToDelete, setSectionsToDelete] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [isImportChecked, setIsImportChecked] = useState([]);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setClassName(selectedClassDetails?.[0]?.std);
    let temp = [];
    for (let i = 0; i < selectedClassDetails?.length; i++) {
      temp.push({
        section: selectedClassDetails?.[i]?.section,
        courseName: selectedClassDetails?.[i]?.courseName,
        id: selectedClassDetails?.[i]?.id,
        studentsCount: selectedClassDetails?.[i]?.studentsCount,
        alreadyImported: selectedClassDetails?.[i]?.alreadyImported,
        courseId: selectedClassDetails?.[i]?.courseId,
        startDate:selectedClassDetails?.[i]?.startDate || null
      })
    }
    setEditClassDetails(temp)
  }, [])

  const handleCancel = () => {
    setShowEditClassModal(false)
  }

  const hasSectionAndNoCourseId = editClassDetails.some(
    (cls) => cls.section && (cls.courseId == null)
  );

 
  const onEditClassSave = () => {
    const sectionsToUpdate = editClassDetails.filter((item, index) => {
      const row = selectedClassDetails?.find(el => el?.id == item?.id)
      return item.section !== row?.section || item.courseName !== row?.courseName || item.startDate !== row?.startDate
    });
    const params = {
      std: className ? className : selectedClassDetails?.[0]?.std,
      standards: (selectedClassDetails?.[0]?.std != className) && isInsideSingleClass ? editClassDetails : sectionsToUpdate,
      deletedSections: sectionsToDelete,
      oldStd: selectedClassDetails?.[0]?.std,
      forAllSections: isInsideSingleClass ? "NO" : null,
      
    }

    const linkArray = editClassDetails?.filter((item, index) => isImportChecked?.includes(item?.id))?.map(item => ({ standardId: item?.id, courseId: item?.courseId }))

    if (isConfirmVisible) {
      setConfirmLoader(true)
    } else {
      setEditClassLoader(true);
    }
    editClasses(
      params,
      fromCourseDetails,
      () => { isConfirmVisible ? setConfirmLoader(true) : setEditClassLoader(false) },
      () => {
        setShowEditClassModal(false);
        if (isImportChecked?.length) {
          postAddClassWithImport(
            linkArray,
            () => {
              togglePageLoader(true)
              getSubjStudentsData(() => {
                if (selectedCourseId) {
                  getCourseData();
                } else {
                  null
                }
                togglePageLoader(false);
              });
            })
        }
        if (isInsideSingleClass && editClassDetails?.length == 0 && !isFromCourseDetailsReducer) {
          history.push("/classroom")
        } else if (isInsideSingleClass && editClassDetails?.length == 0 && isFromCourseDetailsReducer) {
          history.push({ pathname: "/course-details", state: { key: "openBatches" } })
        } else if (isInsideSingleClass) {
          togglePageLoader(true)
          getSubjStudentsData((courseId) => {
            if (courseId) {
              getCourseData();
            } else {
              null
            }
            togglePageLoader(false);
          });
        }
      });
  }
  const handleEditClassSave = () => {
    if (isImportChecked?.length) {
      setConfirmDeleteModal(true);
      setConfirmVisible(true)
    } else {
      onEditClassSave()
    }
  }
  const handleDeleteSection = (index) => {
    const deletedId = editClassDetails[index].id;
    sectionsToDelete.push(deletedId);
    setSectionsToDelete([...sectionsToDelete])
    editClassDetails?.splice(index, 1);
    setEditClassDetails([...editClassDetails])
    setConfirmDeleteModal(false)
  }
  const handleTransferSection = (id) => {
    const updatedClassDetails = editClassDetails.filter(item => item.id !== id);
    setEditClassDetails(updatedClassDetails);
    if (fromCourseDetails || isFromCourseDetailsReducer) {
      getAllClasses(newCourseId)
    } else {
      getAllClasses();
    }
  }

 
  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={showEditClassModal}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}
        width={fromCourseDetails || isFromCourseDetailsReducer ? 550 : 750}>
        <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Edit batch"}</div>
        <div style={{ padding: 20 }}>
          <div className='m-b-20'>
            <AnimatedTextField
              label={"Batch name"}
              isMandatory={true}
              inputId={"-1000"}
              value={className}
              handleInputChange={(e) => setClassName(e.target.value)}
            />
          </div>
          
          <div style={{ height: 300, overflowY: "scroll", marginTop:30 }}>
            {editClassDetails?.map((item, index) => (
              <SectionCourseRow
                key={item?.id}  //important
                section={item?.section}
                date={item?.startDate}
                courseName={item?.courseName}
                index={index}
                id={item?.id}
                courses={courses}
                editClassDetails={editClassDetails}
                setEditClassDetails={setEditClassDetails}
                alreadyImported={item?.alreadyImported}
                storeSectionIndex={storeSectionIndex}
                setSection={setSection}
                selectedClassDetails={selectedClassDetails}
                setConfirmDeleteModal={setConfirmDeleteModal}
                setIsTransferModalVisible={setIsTransferModalVisible}
                setSelectedClassId={setSelectedClassId}
                setIsImportChecked={setIsImportChecked}
                fromCourseDetails={fromCourseDetails}
                isFromCourseDetailsReducer={isFromCourseDetailsReducer} />
            ))}
          </div>
        </div>
        <div className='r-c-c'>
          <Button
            loading={editClassLoader}
            disabled={hasSectionAndNoCourseId || !className?.trim().length || editClassDetails.filter((item) => item.section === "").length >= 1
            }
            type="primary"
            onClick={handleEditClassSave}
          >UPDATE</Button>
        </div>
      </Modal>
      {showConfirmDeleteModal &&
        <ConfirmDeleteModal
          showConfirmDeleteModal={showConfirmDeleteModal}
          setConfirmDeleteModal={setConfirmDeleteModal}
          handleDeleteSection={handleDeleteSection}
          index={sectionIndex}
          className={className}
          sectionName={section}
          isConfirmVisible={isConfirmVisible}
          onEditClassSave={onEditClassSave}
          confirmLoader={confirmLoader}
        />
      }
      {isTransferClassVisible &&
        <TransferClassModal
          isTransferClassVisible={isTransferClassVisible}
          setIsTransferModalVisible={setIsTransferModalVisible}
          className={className}
          sectionName={section}
          selectedClassId={selectedClassId}
          fromCourseDetails={fromCourseDetails}
          handleTransferSection={handleTransferSection}
          isFromCourseDetailsReducer={isFromCourseDetailsReducer}
        />}
    </div>
  )
}
const mapStateToProps = state => {
  const { selectedClassDetails } = state.classRoom;
  const { courses } = state.course;
  const { newCourseId, isFromCourseDetailsReducer } = state.courseManagement;
  return {
    selectedClassDetails, courses, isFromCourseDetailsReducer, newCourseId
  };
};

const mapDispatchToProps = dispatch => ({
  editClasses: (params, fromCourseDetails, callBack, successCallBack) => dispatch(editClasses(params, fromCourseDetails, callBack, successCallBack)),
  getAllClasses: (id) => dispatch(getAllClasses(id)),
  postAddClassWithImport: (editClassDetails, successCallBack) => dispatch(postAddClassWithImport(editClassDetails, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClassModal)

// export default EditClassModal