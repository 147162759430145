import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, CaretDownOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Radio, Input, Checkbox, Row, Col, Dropdown, Menu } from "antd";
import { useHistory } from "react-router";
import {
  attendanceRequirementDetail, storeWorkingDays, storeAttendanceHoursMinutes
  , attendanceAllRequiredDetails, teacherHoursMinutes, storeAttendancDo, storeAttendanceOp,
  storeAttendanceMinRequirement, storeAttendanceMaXRequirementDo, storeAttendanceMaXRequirementTeacher,
  storeAttendanceMaXOperatiron, teacherHalfdayAttendance, DoHalfdayAttendance, opHalfDay, setBackToPrivilegedView, fullDayStudent, halfDayStudent, storeAttendanceMaXStudent, setSameMinimumAtt, setSameHalfAtt, setSameMaximumAtt, setApprovalId
} from "actions/attendanceNew";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import './style.css'
import PageHeader from "components/pageHeader";
import {Button} from 'components/Button'

const bodyStyle = (isSubcomponent) =>  {
  return{
    background: isSubcomponent ? "transparent" : "#FAFAFA",
    width: "calc(100% + 30px)",
    height: "calc(100% + 40px)",
    margin: -20,
    marginTop: !isSubcomponent ? 20 : -30,
    padding: 20,
    overflow: "auto",
  }
};
const images = require("../../Assets/user/ic-information.svg").default;

function RequirementCard({setSameMaximumAtt,sameHalfAtt,
  sameMaximumAtt,setSameHalfAtt, setSameMinimumAtt,sameMinimumAtt,attendanceRequirementDetail, getattendanceRequirement, storeWorkingDays, storeWorkingDaysDetails,
  staffAvgWorkingHrs, storeAttendanceHoursMinutes, attendanceTeacherHrsMinutes, teacherHoursMinutes,
  storeAttendancDo, storeAttendanceDataOperator, storeAttendanceOperation, storeAttendanceOp,
  storeAttendanceMinRequirement, storeAttendanceMin, storeMaxDataOperater, storeMaxOperations,
  storeAttendanceMaXRequirementDo, storeAttendanceMaXOperatiron, storeMaxTeacher, storeAttendanceMaXRequirementTeacher,
  teacherHalfDay, teacherHalfdayAttendance, DoHalfDay, DoHalfdayAttendance, operationHalfDay,
  opHalfDay, storeFullDayStudent, fullDayStudent, storeHalfDayStudent, halfDayStudent, storeAttendanceMaXStudent, storeMaxStudentData,
  isSubcomponent = false,storeApprovalId,setApprovalId
}) {
console.log("AA",sameMinimumAtt,sameHalfAtt,sameMaximumAtt);
  // const [sameMinimumAtt, setSameMinimumAtt] = useState(false);
  // const [sameMaximumAtt, setSameMaximumAtt] = useState(false);
  // const [sameHalfAtt, setSameHalfAtt] = useState(false);
  const [checked, setChecked] = useState(false);
  const [check, setCheck] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isValue, setIsValue] = useState(false);
  const [value, setValue] = useState(1);
  const onChangeLevel = (e) => {
    console.log('radio checked', e.target.value);
    setApprovalId(e.target.value);
  };

  const countE = () => {
    if (attendanceTeacherHrsMinutes?.hour < 24) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour + 1
      });
    }
    if (check && attendanceTeacherHrsMinutes?.hour < 24) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour + 1
      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour + 1
      });
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour + 1
      });
    }
  }
  const countF = () => {
    if (attendanceTeacherHrsMinutes?.hour > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour - 1
      });
    }
    if (check && attendanceTeacherHrsMinutes?.hour > 0) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour - 1
      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour - 1
      });
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour - 1
      });
    }
  }
  const countG = () => {
    if (attendanceTeacherHrsMinutes?.minute < 59) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        minute: attendanceTeacherHrsMinutes?.hour == 24 ? 0 : attendanceTeacherHrsMinutes?.minute + 1
      });
    }
    if (check && attendanceTeacherHrsMinutes?.minute < 59) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        // doMinute: storeAttendanceDataOperator?.doMinute +1
        doMinute: storeAttendanceDataOperator?.doHour == 24 ? 0 : storeAttendanceDataOperator?.doMinute + 1

      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        // opMinute: storeAttendanceOperation?.opMinute +1
        opMinute: storeAttendanceOperation?.opHour == 24 ? 0 : storeAttendanceOperation?.opMinute + 1

      });
      fullDayStudent({
        ...storeFullDayStudent,
        // opMinute: storeAttendanceOperation?.opMinute +1
        sMinute: storeFullDayStudent?.sHour == 24 ? 0 : storeFullDayStudent?.sMinute + 1

      });
    }
  }
  const countH = () => {
    if (attendanceTeacherHrsMinutes?.minute > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        minute: attendanceTeacherHrsMinutes?.minute - 1
      });
    }
    if (check && attendanceTeacherHrsMinutes.minute > 0) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doMinute: storeAttendanceDataOperator?.doMinute - 1
      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opMinute: storeAttendanceOperation?.opMinute - 1
      });
      fullDayStudent({
        ...storeFullDayStudent,
        // opMinute: storeAttendanceOperation?.opMinute +1
        sMinute: storeFullDayStudent?.sMinute - 1


      });
    }
  }

  const countI = () => {
    if (storeAttendanceDataOperator?.doHour < 24) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour + 1
      });
    }
    if (check && storeAttendanceDataOperator?.doHour < 24) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour + 1
      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour + 1
      });
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour + 1
      });
    }
  }
  const countJ = () => {
    if (storeAttendanceDataOperator?.doHour > 0) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour - 1
      });
    }
    if (check && storeAttendanceDataOperator?.doHour > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour - 1
      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour - 1
      });
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour - 1
      });
    }
  }
  const countK = () => {
    if (storeAttendanceDataOperator?.doMinute < 59) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        // doMinute: storeAttendanceDataOperator?.doMinute + 1
        doMinute: storeAttendanceDataOperator?.doHour == 24 ? 0 : storeAttendanceDataOperator?.doMinute + 1

      });
    }
    if (check && storeAttendanceDataOperator?.doMinute < 59) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        // minute: attendanceTeacherHrsMinutes?.minute + 1
        minute: attendanceTeacherHrsMinutes?.hour == 24 ? 0 : attendanceTeacherHrsMinutes?.minute + 1

      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        // opMinute: storeAttendanceOperation?.opMinute + 1
        opMinute: storeAttendanceOperation?.opHour == 24 ? 0 : storeAttendanceOperation?.opMinute + 1

      });
      fullDayStudent({
        ...storeFullDayStudent,
        // opMinute: storeAttendanceOperation?.opMinute + 1
        sMinute: storeFullDayStudent?.sHour == 24 ? 0 : storeFullDayStudent?.sMinute + 1

      });
    }
  }
  const countL = () => {
    if (storeAttendanceDataOperator?.doMinute > 0) {
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doMinute: storeAttendanceDataOperator?.doMinute - 1
      });
    }
    if (check && storeAttendanceDataOperator?.doMinute > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        minute: attendanceTeacherHrsMinutes?.minute - 1
      });
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opMinute: storeAttendanceOperation?.opMinute - 1
      });
      fullDayStudent({
        ...storeFullDayStudent,
        sMinute: storeFullDayStudent?.sMinute - 1
      });
    }
  }

  const countM = () => {
    if (storeAttendanceOperation?.opHour < 23) {
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour + 1
      });
    }
    if (check && storeAttendanceOperation?.opHour < 23) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour + 1
      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour + 1
      })
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour + 1
      })
    }
  }
  const countN = () => {
    if (storeAttendanceOperation?.opHour > 0) {
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour - 1

      });
    }
    if (check && storeAttendanceOperation.opHour > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour - 1
      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour - 1
      })
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour - 1
      })
    }
  }
  const countSt = () => {
    if (storeFullDayStudent?.sHour > 0) {
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour - 1

      });
    }
    if (check && storeFullDayStudent.sHour > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour - 1
      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour - 1
      })
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour - 1
      })
    }
  }
  const countSt1 = () => {
    if (storeFullDayStudent?.sHour < 23) {
      fullDayStudent({
        ...storeFullDayStudent,
        sHour: storeFullDayStudent?.sHour + 1
      });
    }
    if (check && storeFullDayStudent?.sHour < 23) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        hour: attendanceTeacherHrsMinutes?.hour + 1
      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doHour: storeAttendanceDataOperator?.doHour + 1
      })
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opHour: storeAttendanceOperation?.opHour + 1
      })
    }
  }

  const countO = () => {
    if (storeAttendanceOperation?.opMinute < 59) {
      storeAttendanceOp({
        ...storeAttendanceOperation,
        // opMinute: storeAttendanceOperation?.opMinute + 1
        opMinute: storeAttendanceOperation?.opHour == 24 ? 0 : storeAttendanceOperation?.opMinute + 1

      });
    }
    if (check && storeAttendanceOperation?.opMinute < 59) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        // minute: attendanceTeacherHrsMinutes?.minute + 1
        minute: attendanceTeacherHrsMinutes?.hour == 24 ? 0 : attendanceTeacherHrsMinutes?.minute + 1

      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        // doMinute: storeAttendanceDataOperator?.doMinute + 1
        doMinute: storeAttendanceDataOperator?.doHour == 24 ? 0 : storeAttendanceDataOperator?.doMinute + 1
      })
      fullDayStudent({
        ...storeFullDayStudent,
        // doMinute: storeAttendanceDataOperator?.doMinute + 1
        sMinute: storeFullDayStudent?.sHour == 24 ? 0 : storeFullDayStudent?.sMinute + 1


      })
    }
  }
  const countP = () => {
    if (storeAttendanceOperation?.opMinute > 0) {
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opMinute: storeAttendanceOperation?.opMinute - 1
      });
    }
    if (check && storeAttendanceOperation?.opMinute > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        minute: attendanceTeacherHrsMinutes?.minute - 1
      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doMinute: storeAttendanceDataOperator?.doMinute - 1
      })
      fullDayStudent({
        ...storeFullDayStudent,
        sMinute: storeFullDayStudent?.sMinute - 1
      })
    }
  }

  const countSt3 = () => {
    if (storeFullDayStudent?.sMinute < 59) {
      fullDayStudent({
        ...storeFullDayStudent,
        // opMinute: storeAttendanceOperation?.opMinute + 1
        sMinute: storeFullDayStudent?.sHour == 24 ? 0 : storeFullDayStudent?.sMinute + 1

      });
    }
    if (check && storeFullDayStudent?.sMinute < 59) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        // minute: attendanceTeacherHrsMinutes?.minute + 1
        minute: attendanceTeacherHrsMinutes?.hour == 24 ? 0 : attendanceTeacherHrsMinutes?.minute + 1

      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        // doMinute: storeAttendanceDataOperator?.doMinute + 1
        doMinute: storeAttendanceDataOperator?.doHour == 24 ? 0 : storeAttendanceDataOperator?.doMinute + 1
      })
      storeAttendanceOp({
        ...storeAttendanceOperation,
        // doMinute: storeAttendanceDataOperator?.doMinute + 1
        opMinute: storeAttendanceOperation?.opHour == 24 ? 0 : storeAttendanceOperation?.opMinute + 1


      })

    }
  }
  const countSt4 = () => {
    if (storeFullDayStudent?.sMinute > 0) {
      fullDayStudent({
        ...storeFullDayStudent,
        sMinute: storeFullDayStudent?.sMinute - 1
      });
    }
    if (check && storeFullDayStudent?.sMinute > 0) {
      teacherHoursMinutes({
        ...attendanceTeacherHrsMinutes,
        minute: attendanceTeacherHrsMinutes?.minute - 1
      });
      storeAttendancDo({
        ...storeAttendanceDataOperator,
        doMinute: storeAttendanceDataOperator?.doMinute - 1
      })
      storeAttendanceOp({
        ...storeAttendanceOperation,
        opMinute: storeAttendanceOperation?.opMinute - 1
      })
    }
  }





  const CounterI = () => {
    if (storeAttendanceMin < 100) {
      storeAttendanceMinRequirement(storeAttendanceMin + 1);
    }

  }
  const CounterJ = () => {
    if (storeAttendanceMin > 0) {
      storeAttendanceMinRequirement(storeAttendanceMin - 1);
    }
  }
  const CounterK = () => {
    if (storeMaxTeacher < 31) {
      storeAttendanceMaXRequirementTeacher(storeMaxTeacher + 1);
      if (isCheck) {
        storeAttendanceMaXRequirementDo(storeMaxDataOperater + 1);
        storeAttendanceMaXOperatiron(storeMaxOperations + 1);
        storeAttendanceMaXStudent(storeMaxStudentData + 1);

      }
    }
  }
  const CounterL = () => {
    if (storeMaxTeacher > 0) {
      storeAttendanceMaXRequirementTeacher(storeMaxTeacher - 1);
      if (isCheck && storeMaxTeacher > 0) {
        storeAttendanceMaXRequirementDo(storeMaxDataOperater - 1);
        storeAttendanceMaXOperatiron(storeMaxOperations - 1);
        storeAttendanceMaXStudent(storeMaxStudentData - 1);

      }
    }
  }
  const CounterM = () => {
    if (storeMaxDataOperater < 31) {
      storeAttendanceMaXRequirementDo(storeMaxDataOperater + 1);
      if (isCheck && storeMaxDataOperater < 31) {
        storeAttendanceMaXRequirementTeacher(storeMaxTeacher + 1);
        storeAttendanceMaXOperatiron(storeMaxOperations + 1);
        storeAttendanceMaXStudent(storeMaxStudentData + 1);


      }
    }
  }
  const CounterN = () => {
    if (storeMaxDataOperater > 0) {
      storeAttendanceMaXRequirementDo(storeMaxDataOperater - 1);
      if (isCheck && storeMaxDataOperater > 0) {
        storeAttendanceMaXRequirementTeacher(storeMaxTeacher - 1);
        storeAttendanceMaXOperatiron(storeMaxOperations - 1);
        storeAttendanceMaXStudent(storeMaxStudentData - 1);


      }
    }
  }
  const CounterO = () => {
    if (storeMaxOperations < 31) {
      storeAttendanceMaXOperatiron(storeMaxOperations + 1);
      if (isCheck && storeMaxOperations < 31) {
        storeAttendanceMaXRequirementTeacher(storeMaxTeacher + 1);
        storeAttendanceMaXRequirementDo(storeMaxDataOperater + 1);
        storeAttendanceMaXStudent(storeMaxStudentData + 1);


      }
    }
  }
  const CounterP = () => {
    if (storeMaxOperations > 0) {
      storeAttendanceMaXOperatiron(storeMaxOperations - 1);
      if (isCheck && storeMaxOperations > 0) {
        storeAttendanceMaXRequirementTeacher(storeMaxTeacher - 1);
        storeAttendanceMaXRequirementDo(storeMaxDataOperater - 1);
        storeAttendanceMaXStudent(storeMaxStudentData - 1);


      }
    }
  }
  const CounterQ = () => {
    if (storeMaxStudentData > 0) {
      storeAttendanceMaXStudent(storeMaxStudentData - 1);
      if (isCheck && storeMaxStudentData > 0) {
        storeAttendanceMaXRequirementTeacher(storeMaxTeacher - 1);
        storeAttendanceMaXRequirementDo(storeMaxDataOperater - 1);
        storeAttendanceMaXOperatiron(storeMaxOperations - 1);


      }
    }
  }
  const CounterR = () => {
    if (storeMaxStudentData < 31) {
      storeAttendanceMaXStudent(storeMaxStudentData + 1);
      if (isCheck && storeMaxStudentData < 31) {
        storeAttendanceMaXRequirementTeacher(storeMaxTeacher + 1);
        storeAttendanceMaXRequirementDo(storeMaxDataOperater + 1);
        storeAttendanceMaXOperatiron(storeMaxOperations + 1);


      }
    }
  }

  const count2 = () => {
    if (teacherHalfDay?.tHour < 24) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour + 1
      });
    }
    if (checked && teacherHalfDay?.tHour < 24) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour + 1
      });
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour + 1
      });
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour + 1
      });
    }

  }
  const count1 = () => {
    if (teacherHalfDay?.tHour > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour - 1
      });
    }
    if (checked && teacherHalfDay?.tHour > 0) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour - 1
      });
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour - 1
      });
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour - 1
      });
    }
  }
  const count4 = () => {
    if (teacherHalfDay?.tMinute < 59) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        // tMinute: teacherHalfDay?.tMinute + 1
        tMinute: teacherHalfDay?.tHour == 24 ? 0 : teacherHalfDay?.tMinute + 1


      });
    }
    if (checked && teacherHalfDay?.tMinute < 59) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        // dMinute: DoHalfDay?.dMinute + 1
        dMinute: DoHalfDay?.dHour == 24 ? 0 : DoHalfDay?.dMinute + 1


      });
      opHalfDay({
        ...operationHalfDay,
        // oMinute: operationHalfDay?.oMinute +1 
        oMinute: operationHalfDay?.oHour == 24 ? 0 : operationHalfDay?.oMinute + 1

      });
      halfDayStudent({
        ...storeHalfDayStudent,
        // oMinute: operationHalfDay?.oMinute +1 
        stMinute: storeHalfDayStudent?.stHour == 24 ? 0 : storeHalfDayStudent?.stMinute + 1

      });
    }
  }
  const count3 = () => {
    if (teacherHalfDay?.tMinute > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tMinute: teacherHalfDay?.tMinute - 1
      });
    }
    if (checked && teacherHalfDay?.tMinute > 0) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        dMinute: DoHalfDay?.dMinute - 1
      });
      opHalfDay({
        ...operationHalfDay,
        oMinute: operationHalfDay?.oMinute - 1
      });
      halfDayStudent({
        ...storeHalfDayStudent,
        stMinute: storeHalfDayStudent?.stMinute - 1
      });
    }
  }

  const count6 = () => {
    if (DoHalfDay?.dHour < 23) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour + 1
      });
    }
    if (checked && DoHalfDay?.dHour < 23) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour + 1
      });
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour + 1
      });
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour + 1
      });
    }
  }
  const count5 = () => {
    if (DoHalfDay?.dHour > 0) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour - 1
      });
    }
    if (checked && DoHalfDay?.dHour > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour - 1
      });
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour - 1
      });
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour - 1
      });
    }
  }
  const count8 = () => {
    if (DoHalfDay?.dMinute < 59) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        // dMinute: DoHalfDay?.dHour == 24 ? 0 : DoHalfDay?.dMinute + 1
        dMinute: DoHalfDay?.dHour == 24 ? 0 : DoHalfDay?.dMinute + 1
      });
    }

    if (checked && DoHalfDay?.dMinute < 59) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        // tMinute: teacherHalfDay?.tMinute + 1
        tMinute: teacherHalfDay?.tHour == 24 ? 0 : teacherHalfDay?.tMinute + 1

      });

      opHalfDay({
        ...operationHalfDay,
        // oMinute: operationHalfDay?.oMinute +1 
        oMinute: operationHalfDay?.oHour == 24 ? 0 : operationHalfDay?.oMinute + 1

      });
      halfDayStudent({
        ...storeHalfDayStudent,
        // oMinute: operationHalfDay?.oMinute +1 
        stMinute: storeHalfDayStudent?.stHour == 24 ? 0 : storeHalfDayStudent?.stMinute + 1

      });
    }
  }
  const count7 = () => {
    if (DoHalfDay?.dMinute > 0) {
      DoHalfdayAttendance({
        ...DoHalfDay,
        dMinute: DoHalfDay?.dMinute - 1
      });
    }
    if (checked && DoHalfDay?.dMinute > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tMinute: teacherHalfDay?.tMinute - 1
      });
      opHalfDay({
        ...operationHalfDay,
        oMinute: operationHalfDay?.oMinute - 1
      });
      halfDayStudent({
        ...storeHalfDayStudent,
        stMinute: storeHalfDayStudent?.stMinute - 1
      });
    }
  }

  const count10 = () => {
    if (operationHalfDay?.oHour < 24) {
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour + 1
      });
    }
    if (checked && operationHalfDay?.oHour < 23) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour + 1
      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour + 1
      })
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour + 1
      })
    }
  }
  const count9 = () => {
    if (operationHalfDay?.oHour > 0) {
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour - 1
      });
    }
    if (checked && operationHalfDay?.oHour > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour - 1
      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour - 1
      })
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour - 1
      })
    }
  }

  const counthst3 = () => {
    if (storeHalfDayStudent?.stHour > 0) {
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour - 1
      })
    }
    if (checked && operationHalfDay?.oHour > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour - 1
      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour - 1
      })

      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour - 1
      });
    }
  }
  const counthst2 = () => {
    if (storeHalfDayStudent?.stHour < 24) {
      halfDayStudent({
        ...storeHalfDayStudent,
        stHour: storeHalfDayStudent?.stHour + 1
      })

    }
    if (checked && operationHalfDay?.oHour < 23) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tHour: teacherHalfDay?.tHour + 1
      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        dHour: DoHalfDay?.dHour + 1
      })
      opHalfDay({
        ...operationHalfDay,
        oHour: operationHalfDay?.oHour + 1
      });
    }
  }
  const count12 = () => {
    if (operationHalfDay?.oMinute < 60) {
      opHalfDay({
        ...operationHalfDay,
        // oMinute: operationHalfDay?.oMinute + 1
        oMinute: operationHalfDay?.oHour === 24 ? 0 : operationHalfDay?.oMinute + 1

      });
    }
    if (checked && operationHalfDay?.oMinute < 59) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        // tMinute: teacherHalfDay?.tMinute + 1
        tMinute: teacherHalfDay?.tHour === 24 ? 0 : teacherHalfDay?.tMinute + 1

      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        // dMinute: DoHalfDay?.dMinute + 1
        dMinute: DoHalfDay?.dHour === 24 ? 0 : DoHalfDay?.dMinute + 1

      })
      halfDayStudent({
        ...storeHalfDayStudent,
        // dMinute: DoHalfDay?.dMinute + 1
        stMinute: storeHalfDayStudent?.stHour === 24 ? 0 : storeHalfDayStudent?.stMinute + 1

      })
    }
  }
  const count11 = () => {
    if (operationHalfDay?.oMinute > 0) {
      opHalfDay({
        ...operationHalfDay,
        oMinute: operationHalfDay?.oMinute - 1
      });
    }
    if (checked && operationHalfDay?.oMinute > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tMinute: teacherHalfDay?.tMinute - 1
      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        dMinute: DoHalfDay?.dMinute - 1
      })
      halfDayStudent({
        ...storeHalfDayStudent,
        stMinute: storeHalfDayStudent?.stMinute - 1
      })
    }

  }

  const counthst = () => {
    if (storeHalfDayStudent?.oMinute > 0) {
      halfDayStudent({
        ...storeHalfDayStudent,
        stMinute: storeHalfDayStudent?.stMinute - 1
      });
    }
    if (checked && storeHalfDayStudent?.stMinute > 0) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        tMinute: teacherHalfDay?.tMinute - 1
      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        dMinute: DoHalfDay?.dMinute - 1
      })
      opHalfDay({
        ...operationHalfDay,
        oMinute: operationHalfDay?.oMinute - 1
      });
    }
  }

  const counthst1 = () => {
    if (storeHalfDayStudent?.stMinute < 60) {
      halfDayStudent({
        ...storeHalfDayStudent,
        // oMinute: operationHalfDay?.oMinute + 1
        stMinute: storeHalfDayStudent?.stHour === 24 ? 0 : storeHalfDayStudent?.stMinute + 1

      });
    }
    if (checked && operationHalfDay?.oMinute < 59) {
      teacherHalfdayAttendance({
        ...teacherHalfDay,
        // tMinute: teacherHalfDay?.tMinute + 1
        tMinute: teacherHalfDay?.tHour === 24 ? 0 : teacherHalfDay?.tMinute + 1

      });
      DoHalfdayAttendance({
        ...DoHalfDay,
        // dMinute: DoHalfDay?.dMinute + 1
        dMinute: DoHalfDay?.dHour === 24 ? 0 : DoHalfDay?.dMinute + 1

      })
      opHalfDay({
        ...operationHalfDay,
        // oMinute: operationHalfDay?.oMinute + 1
        oMinute: operationHalfDay?.oHour === 24 ? 0 : operationHalfDay?.oMinute + 1

      });
    }
  }


  function handleOnChange(checkedValue) {
    storeWorkingDays(checkedValue)
  }

  return (
    <div
      className={isSubcomponent ? " " : "boxShadow118 border-16"}
      style={{
        width: "100%",
        alignItems: "center",
        border: isSubcomponent ? "none" : "#E6E6E6 solid 1px",
        padding: "10px",
        paddingTop: "10px",
        // marginLeft: "10px",
        borderColor: "#E6E6E6",
        backgroundColor:isSubcomponent? "transparent" : "#FFFFFF",
        marginTop: "30px",

      }}
    >
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <div style={{ marginTop: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "#191919",
                  fontFamily: "Roboto"
                }}
              >
                Working day(s)
              </div>
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  color: "#636363",
                  fontFamily: "Roboto"
                }}
              >
                Please select the day(s) which you would like to considers as working day(s)
              </div>
              <div style={{ marginLeft: "20px" }}>
              <Checkbox.Group
                value={storeWorkingDaysDetails}
                onChange={(e)=>{handleOnChange(e)}}
                style={{
                  width: '90%',
                  marginTop: "10px"
                }}
              >
                <Row style={{ width: "150%" }}>
                  <Col span={6}>
                    <Checkbox value="monday" style={{ fontSize: "13px" }}>Monday</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value="tuesday" style={{ fontSize: "13px" }}>Tuesday</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value="wednesday" style={{ fontSize: "13px" }}>Wednesday</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value="thursday" style={{ fontSize: "13px" }}>Thursday</Checkbox>
                  </Col>
                  <Col span={6} style={{ marginTop: "10px" }}>
                    <Checkbox value="friday" style={{ fontSize: "13px" }}>Friday</Checkbox>
                  </Col>
                  <Col span={6} style={{ marginTop: "10px" }}>
                    <Checkbox value="saturday" style={{ fontSize: "13px" }}>Saturday</Checkbox>
                  </Col>
                  <Col span={6} style={{ marginTop: "10px" }}>
                    <Checkbox value="sunday" style={{ fontSize: "13px" }}>Sunday</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>

            </div>
            </div>

            




          </div>
        </div>
        <div style={{ borderBottom: "1px solid", color: "#E6E6E6", marginTop: "20px" }}></div>


        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                color: "#191919",
                marginTop: "20px",
                fontFamily: "Roboto"
              }}
            >
              Average working hours
            </div>

            <div
              style={{ fontSize: "13px", color: "#636363", fontFamily: "Roboto" }}
            >
              Set an average working hours required for your users (Teachers, data operators, operations and students).
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "30px" }}>


            <div>
              <Input
                placeholder="Enter Hours"
                status={isValue ? "error" : null}
                style={{ width: "150px", marginRight: "15px", }}
                type="number"
                value={staffAvgWorkingHrs?.hours}
                onChange={(e) => {
             

                  e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                  storeAttendanceHoursMinutes({
                    ...staffAvgWorkingHrs,
                    hours: e.target.value ? parseInt(e.target.value) : 0,
                    // minutes: e.target.value < 24 ? staffAvgWorkingHrs?.minutes : 0
                    minutes: parseInt(staffAvgWorkingHrs?.hours) +1 < 24 ? staffAvgWorkingHrs?.minutes : 0
                  });
                }}
                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                  onClick={() => {
                    storeAttendanceHoursMinutes({
                      ...staffAvgWorkingHrs,
                      hours: parseInt(staffAvgWorkingHrs?.hours) - 1 < 0 ? 0 : parseInt(staffAvgWorkingHrs?.hours) - 1,
                    });
                  }}
                />}
                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                  onClick={() => {
                    storeAttendanceHoursMinutes({
                      ...staffAvgWorkingHrs,
                      hours: parseInt(staffAvgWorkingHrs?.hours) + 1 < 25 ? parseInt(staffAvgWorkingHrs?.hours) + 1 : 24,
                      minutes: staffAvgWorkingHrs?.minutes && parseInt(staffAvgWorkingHrs?.hours) < 24 ? staffAvgWorkingHrs?.minutes : 0
                    });
                  }}
                />}
              />
            </div> 
            <div style={{marginRight:"11px",height:"10px"}}> : </div>
            <div>
              <Input
                value={staffAvgWorkingHrs?.minutes}
                onChange={(e) => {

                  // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                  e.target.value = staffAvgWorkingHrs?.hours == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                  storeAttendanceHoursMinutes({
                    ...staffAvgWorkingHrs,
                    minutes: parseInt(e.target.value)
                  })
                }}
                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                  onClick={() => {
                    storeAttendanceHoursMinutes({
                      ...staffAvgWorkingHrs,
                      minutes: parseInt(staffAvgWorkingHrs?.minutes) - 1 > -1 ? parseInt(staffAvgWorkingHrs?.minutes) - 1 : 0
                    })
                  }}
                />}
                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                  onClick={() => {
                    storeAttendanceHoursMinutes({
                      ...staffAvgWorkingHrs,
                      // minutes: parseInt(staffAvgWorkingHrs?.minutes) + 1 < 60 ? parseInt(staffAvgWorkingHrs?.minutes) + 1 : 59
                      minutes: staffAvgWorkingHrs?.hours == 24 ? 0 : parseInt(staffAvgWorkingHrs?.minutes) + 1 < 60 ? parseInt(staffAvgWorkingHrs?.minutes) + 1 : 59
                    })
                  }}
                />
                }
                style={{ width: "150px" }}
                type="number"
                placeholder="Enter mins"
              />
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid", color: "#E6E6E6", marginTop: "20px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#191919",
              marginTop: "20px",
              fontFamily: "Roboto"
            }}>Minimum working hours required for a full-day</div>
            <div style={{ fontSize: "13px", color: "#636363", fontFamily: "Roboto" }}>Set minimum working hours required to be considered as full-day attendance.</div>
          </div>
          <div style={{ marginTop: "25px", color: "#191919", fontSize: "13px" }}
          ><Checkbox
            onChange={(e) => {
              setSameMinimumAtt(e.target.checked);
              setCheck(e.target.checked)
              if (e.target.checked) {
                teacherHoursMinutes({
                  ...attendanceTeacherHrsMinutes,
                  minute: parseInt(0),
                  hour: parseInt(0)
                });
                storeAttendancDo({
                  ...storeAttendanceDataOperator,
                  doMinute: parseInt(0),
                  doHour: parseInt(0)
                })
                storeAttendanceOp({
                  ...storeAttendanceOperation,
                  opMinute: parseInt(0),
                  opHour: parseInt(0)
                })
                fullDayStudent({
                  ...storeFullDayStudent,
                  sMinute: parseInt(0),
                  sHour: parseInt(0)
                })
              }
              console.log("ddd", e.target.checked)
            }} style={{ marginRight: "15px", fontFamily: "Roboto" }} checked = {sameMinimumAtt}

          />Same for all</div>
          {console.log("AA-sameMinimumAtt",sameMinimumAtt)}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", fontFamily: "Roboto", color: "#191919", fontSize: "15px", fontFamily: "Roboto", fontWeight: 500 }}>Teachers</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  placeholder="Enter hours"
                  status={isValue ? "error" : null}
                  style={{ width: "150px", marginRight: "15px", }}
                  type="number"
                  value={attendanceTeacherHrsMinutes?.hour}
                  onChange={(e) => {
                    e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                    teacherHoursMinutes({
                      ...attendanceTeacherHrsMinutes,
                      hour: parseInt(e.target.value),
                      // minute: e.target.value < 24 ? attendanceTeacherHrsMinutes?.minute : 0

                      minute:attendanceTeacherHrsMinutes?.hour ? (parseInt(attendanceTeacherHrsMinutes?.hour)  < 24 ? attendanceTeacherHrsMinutes?.minute : 0) : null
                    });
                    if (sameMinimumAtt) {
                      storeAttendancDo({
                        ...storeAttendanceDataOperator,
                        doHour: parseInt(e.target.value)
                      })
                      storeAttendanceOp({
                        ...storeAttendanceOperation,
                        opHour: parseInt(e.target.value)
                      })
                      fullDayStudent({
                        ...storeFullDayStudent,
                        sHour: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    onClick={() => {
                      teacherHoursMinutes({
                        ...attendanceTeacherHrsMinutes,
                        hour: parseInt(attendanceTeacherHrsMinutes?.hour) - 1 < 0 ? 0 : parseInt(attendanceTeacherHrsMinutes?.hour) - 1,
                      });
                      countF()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    onClick={() => {
                      teacherHoursMinutes({
                        ...attendanceTeacherHrsMinutes,
                        hour: parseInt(attendanceTeacherHrsMinutes?.hour) + 1 < 25 ? parseInt(attendanceTeacherHrsMinutes?.hour) + 1 : 24,
                        minute: parseInt(attendanceTeacherHrsMinutes?.hour) < 24 ? attendanceTeacherHrsMinutes?.minute : 0
                      });
                      countE()
                    }}
                  />}
                />
              </div>
              <div style={{marginRight:"11px",height:"10px"}}> : </div>
              <div>
                <Input
                  value={attendanceTeacherHrsMinutes?.minute}
                  onChange={(e) => {
                    // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59

                    e.target.value = attendanceTeacherHrsMinutes?.hour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                    teacherHoursMinutes({
                      ...attendanceTeacherHrsMinutes,
                      minute: parseInt(e.target.value)
                    })
                    if (sameMinimumAtt) {
                      storeAttendancDo({
                        ...storeAttendanceDataOperator,
                        doMinute: parseInt(e.target.value)
                      })
                      storeAttendanceOp({
                        ...storeAttendanceOperation,
                        opMinute: parseInt(e.target.value)
                      })
                      fullDayStudent({
                        ...storeFullDayStudent,
                        sMinute: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    onClick={() => {
                      teacherHoursMinutes({
                        ...attendanceTeacherHrsMinutes,
                        minute: parseInt(attendanceTeacherHrsMinutes?.minute) - 1 > -1 ? parseInt(attendanceTeacherHrsMinutes?.minute) - 1 : 0
                      })
                      countH()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    onClick={() => {
                      teacherHoursMinutes({
                        ...attendanceTeacherHrsMinutes,
                        minute: attendanceTeacherHrsMinutes?.hour == 24 ? 0 : parseInt(attendanceTeacherHrsMinutes?.minute) + 1 < 60 ? parseInt(attendanceTeacherHrsMinutes?.minute) + 1 : 59
                        // minute: parseInt(attendanceTeacherHrsMinutes?.minute) + 1 < 60 ? parseInt(attendanceTeacherHrsMinutes?.minute) + 1 : 59

                      })
                      countG()
                    }}
                  />
                  }
                  style={{ width: "150px" }}
                  type="number"
                  placeholder="Enter mins"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Data Operators</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  placeholder="Enter hours"
                  status={isValue ? "error" : null}
                  style={{ width: "150px", marginRight: "15px", }}
                  type="number"
                  value={storeAttendanceDataOperator?.doHour}
                  onChange={(e) => {
                    e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                    storeAttendancDo({
                      ...storeAttendanceDataOperator,
                      doHour: parseInt(e.target.value),
                      // doMinute: e.target.value < 24 ? storeAttendanceDataOperator?.doMinute : 0
                      doMinute: storeAttendanceDataOperator?.doHour ?( parseInt(storeAttendanceDataOperator?.doHour) +1 < 24 ? storeAttendanceDataOperator?.doMinute : 0) : null
                    });
                    if (sameMinimumAtt) {
                      teacherHoursMinutes({
                        ...attendanceTeacherHrsMinutes,
                        hour: parseInt(e.target.value)
                      })
                      storeAttendanceOp({
                        ...storeAttendanceOperation,
                        opHour: parseInt(e.target.value)
                      })
                      fullDayStudent({
                        ...storeFullDayStudent,
                        sHour: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    onClick={() => {
                      storeAttendancDo({
                        ...storeAttendanceDataOperator,
                        doHour: parseInt(storeAttendanceDataOperator?.doHour) - 1 < 0 ? 0 : parseInt(storeAttendanceDataOperator?.doHour) - 1,
                      });
                      countJ()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    onClick={() => {
                      storeAttendancDo({
                        ...storeAttendanceDataOperator,
                        doHour: parseInt(storeAttendanceDataOperator?.doHour) + 1 < 25 ? parseInt(storeAttendanceDataOperator?.doHour) + 1 : 24,
                        doMinute: parseInt(storeAttendanceDataOperator?.doHour) < 24 ? storeAttendanceDataOperator?.doMinute : 0
                      });
                      countI()
                    }}
                  />}
                />
              </div>
              <div style={{marginRight:"11px",height:"10px"}}> : </div>
              <div>
                <Input
                  value={storeAttendanceDataOperator?.doMinute}
                  onChange={(e) => {
                    // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                    e.target.value = storeAttendanceDataOperator?.doHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                    storeAttendancDo({
                      ...storeAttendanceDataOperator,
                      doMinute: parseInt(e.target.value)
                    })
                    if (sameMinimumAtt) {
                      teacherHoursMinutes({
                        ...attendanceTeacherHrsMinutes,
                        minute: parseInt(e.target.value)
                      })
                      storeAttendanceOp({
                        ...storeAttendanceOperation,
                        opMinute: parseInt(e.target.value)
                      })
                      fullDayStudent({
                        ...storeFullDayStudent,
                        sMinute: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    onClick={() => {
                      storeAttendancDo({
                        ...storeAttendanceDataOperator,
                        doMinute: parseInt(storeAttendanceDataOperator?.doMinute) - 1 > -1 ? parseInt(storeAttendanceDataOperator?.doMinute) - 1 : 0
                      })
                      countL()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    onClick={() => {
                      storeAttendancDo({
                        ...storeAttendanceDataOperator,
                        // doMinute: parseInt(storeAttendanceDataOperator?.doMinute) + 1 < 60 ? parseInt(storeAttendanceDataOperator?.doMinute) + 1 : 59
                        doMinute: storeAttendanceDataOperator?.doHour == 24 ? 0 : parseInt(storeAttendanceDataOperator?.doMinute) + 1 < 60 ? parseInt(storeAttendanceDataOperator?.doMinute) + 1 : 59
                      })
                      countK()
                    }}
                  />
                  }
                  style={{ width: "150px" }}
                  type="number"
                  placeholder="Enter mins"
                />
              </div>

            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Operations</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <Input
                    placeholder="Enter hours"
                    status={isValue ? "error" : null}
                    style={{ width: "150px", marginRight: "15px", }}
                    type="number"
                    value={storeAttendanceOperation?.opHour}
                    onChange={(e) => {
                      e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                      storeAttendanceOp({
                        ...storeAttendanceOperation,
                        opHour: parseInt(e.target.value),
                        // opMinute: e.target.value < 24 ? storeAttendanceOperation?.opMinute : 0
                        opMinute: storeAttendanceOperation?.opHour ?(parseInt(storeAttendanceOperation?.opHour) +1 < 24 ? storeAttendanceOperation?.opMinute : 0) : null
                      });
                      if (sameMinimumAtt) {
                        teacherHoursMinutes({
                          ...attendanceTeacherHrsMinutes,
                          hour: parseInt(e.target.value)
                        })
                        storeAttendancDo({
                          ...storeAttendanceDataOperator,
                          doHour: parseInt(e.target.value)
                        })
                        fullDayStudent({
                          ...storeFullDayStudent,
                          sHour: parseInt(e.target.value)
                        })
                      }
                    }}
                    prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                      onClick={() => {
                        storeAttendanceOp({
                          ...storeAttendanceOperation,
                          opHour: parseInt(storeAttendanceOperation?.opHour) - 1 < 0 ? 0 : parseInt(storeAttendanceOperation?.opHour) - 1,
                        });
                        countN()
                      }}
                    />}
                    suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                      onClick={() => {
                        storeAttendanceOp({
                          ...storeAttendanceOperation,
                          opHour: parseInt(storeAttendanceOperation?.opHour) + 1 < 25 ? parseInt(storeAttendanceOperation?.opHour) + 1 : 24,
                          opMinute: parseInt(storeAttendanceOperation?.opHour) < 24 ? storeAttendanceOperation?.opMinute : 0
                        });
                        countM()
                      }}
                    />}
                  />
                </div>
                <div style={{marginRight:"11px",height:"10px"}}> : </div>
                <div>
                  <Input
                    value={storeAttendanceOperation?.opMinute}
                    onChange={(e) => {
                      // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                      e.target.value = storeAttendanceOperation?.opHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                      storeAttendanceOp({
                        ...storeAttendanceOperation,
                        opMinute: parseInt(e.target.value)
                      })
                      if (sameMinimumAtt) {
                        teacherHoursMinutes({
                          ...attendanceTeacherHrsMinutes,
                          minute: parseInt(e.target.value)
                        })
                        storeAttendancDo({
                          ...storeAttendanceDataOperator,
                          doMinute: parseInt(e.target.value)
                        })
                        fullDayStudent({
                          ...storeFullDayStudent,
                          sMinute: parseInt(e.target.value)
                        })
                      }
                    }}
                    prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                      onClick={() => {
                        storeAttendanceOp({
                          ...storeAttendanceOperation,
                          opMinute: parseInt(storeAttendanceOperation?.opMinute) - 1 > -1 ? parseInt(storeAttendanceOperation?.opMinute) - 1 : 0
                        })
                        countP()
                      }}
                    />}
                    suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                      onClick={() => {
                        storeAttendanceOp({
                          ...storeAttendanceOperation,
                          // opMinute: parseInt(storeAttendanceOperation?.opMinute) + 1 < 60 ? parseInt(storeAttendanceOperation?.opMinute) + 1 : 59
                          opMinute: storeAttendanceOperation?.opHour == 24 ? 0 : parseInt(storeAttendanceOperation?.opMinute) + 1 < 60 ? parseInt(storeAttendanceOperation?.opMinute) + 1 : 59
                        })
                        countO()
                      }}
                    />
                    }
                    style={{ width: "150px" }}
                    type="number"
                    placeholder="Enter mins"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Students</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <Input
                    placeholder="Enter hours"
                    status={isValue ? "error" : null}
                    style={{ width: "150px", marginRight: "15px", }}
                    type="number"
                    value={storeFullDayStudent?.sHour}
                    onChange={(e) => {
                      e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                      fullDayStudent({
                        ...storeFullDayStudent,
                        sHour: parseInt(e.target.value),
                        // opMinute: e.target.value < 24 ? storeFullDayStudent?.opMinute : 0
                        sMinute: storeFullDayStudent?.sHour ? (parseInt(storeFullDayStudent?.sHour)  +1 < 24 ? storeFullDayStudent?.sMinute : 0 ) : null
                      });
                      if (sameMinimumAtt) {
                        teacherHoursMinutes({
                          ...attendanceTeacherHrsMinutes,
                          hour: parseInt(e.target.value)
                        })
                        storeAttendancDo({
                          ...storeAttendanceDataOperator,
                          doHour: parseInt(e.target.value)
                        })
                        storeAttendanceOp({
                          ...storeAttendanceOperation,
                          opHour: parseInt(e.target.value)
                        })
                      }
                    }}
                    prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                      onClick={() => {
                        fullDayStudent({
                          ...storeFullDayStudent,
                          sHour: parseInt(storeFullDayStudent?.sHour) - 1 < 0 ? 0 : parseInt(storeFullDayStudent?.sHour) - 1,
                        });
                        countSt()
                      }}
                    />}
                    suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                      onClick={() => {
                        fullDayStudent({
                          ...storeFullDayStudent,
                          sHour: parseInt(storeFullDayStudent?.sHour) + 1 < 25 ? parseInt(storeFullDayStudent?.sHour) + 1 : 24,
                          sMinute: parseInt(storeFullDayStudent?.sHour) < 24 ? storeFullDayStudent?.sMinute : 0
                        });
                        countSt1()
                      }}
                    />}
                  />
                </div>
                <div style={{marginRight:"11px",height:"10px"}}> : </div>
                <div>
                  <Input
                    value={storeFullDayStudent?.sMinute}
                    onChange={(e) => {
                      // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                      e.target.value = storeFullDayStudent?.sHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                      fullDayStudent({
                        ...storeFullDayStudent,
                        sMinute: parseInt(e.target.value)
                      })
                      if (sameMinimumAtt) {
                        teacherHoursMinutes({
                          ...attendanceTeacherHrsMinutes,
                          minute: parseInt(e.target.value)
                        })
                        storeAttendancDo({
                          ...storeAttendanceDataOperator,
                          doMinute: parseInt(e.target.value)
                        })
                        storeAttendanceOp({
                          ...storeAttendanceOperation,
                          opMinute: parseInt(e.target.value)
                        })
                      }
                    }}
                    prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                      onClick={() => {
                        fullDayStudent({
                          ...storeFullDayStudent,
                          sMinute: parseInt(storeFullDayStudent?.sMinutes) - 1 > -1 ? parseInt(storeFullDayStudent?.sMinute) - 1 : 0
                        })
                        countSt4()
                      }}
                    />}
                    suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                      onClick={() => {
                        fullDayStudent({
                          ...storeFullDayStudent,
                          // sMinutes: parseInt(storeFullDayStudent?.sMinutes) + 1 < 60 ? parseInt(storeFullDayStudent?.sMinutes) + 1 : 59
                          sMinute: storeFullDayStudent?.sHour == 24 ? 0 : parseInt(storeFullDayStudent?.sMinute) + 1 < 60 ? parseInt(storeFullDayStudent?.sMinute) + 1 : 59
                        })
                        countSt3()
                      }}
                    />
                    }
                    style={{ width: "150px" }}
                    type="number"
                    placeholder="Enter mins"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid", color: "#E6E6E6", marginTop: "20px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#191919",
              marginTop: "20px",
              fontFamily: "Roboto"
            }}>Minimum working hours required for a half-day</div>
            <div style={{ fontSize: "13px", color: "#636363", fontFamily: "Roboto" }}>Set minimum working hours required to be considered as half-day attendance.</div>
          </div>
          <div style={{ marginTop: "25px", color: "#191919", fontSize: "13px" }}><Checkbox
            onChange={(e) => {
              setSameHalfAtt(e.target.checked);
              setChecked(e.target.checked)
              if (e.target.checked) {
                teacherHalfdayAttendance({
                  ...teacherHalfDay,
                  tMinute: parseInt(0),
                  tHour: parseInt(0)
                })
                DoHalfdayAttendance({
                  ...DoHalfDay,
                  dMinute: parseInt(0),
                  dHour: parseInt(0)
                })
                opHalfDay({
                  ...operationHalfDay,
                  oMinute: parseInt(0),
                  oHour: parseInt(0)
                })
                halfDayStudent({
                  ...storeHalfDayStudent,
                  stMinute: parseInt(0),
                  stHour: parseInt(0)
                })
              }
              // console.log("fff",teacherHalfDay)
            }}
            style={{ marginRight: "15px", fontFamily: "Roboto" }} checked = {sameHalfAtt} />Same for all</div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", fontFamily: "Roboto", color: "#191919", fontSize: "15px", fontFamily: "Roboto", fontWeight: 500 }}>Teachers</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex" }}>

              <div>
                <Input
                  style={{ width: "150px", marginRight: "15px", }}
                  type="number"
                  value={teacherHalfDay?.tHour}
                  onChange={(e) => {
                    e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                    teacherHalfdayAttendance({
                      ...teacherHalfDay,
                      tHour: parseInt(e.target.value),
                      // tMinute: e.target.value < 24 ? teacherHalfDay?.tMinute : 0
                      tMinute: teacherHalfDay?.tHour ? (parseInt(teacherHalfDay?.tHour) +1 < 24 ? teacherHalfDay?.tMinute : 0) : null
                    })
                    if (sameHalfAtt) {
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dHour: parseInt(e.target.value)
                      })
                      opHalfDay({
                        ...operationHalfDay,
                        oHour: parseInt(e.target.value)
                      })
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stHour: parseInt(e.target.value)
                      })
                    }
                    console.log(e.target.value, "ftvghb");
                  }}
                  placeholder={"Enter hours"}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countB}
                    onClick={() => {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tHour: parseInt(teacherHalfDay?.tHour) - 1 < 0 ? 0 : parseInt(teacherHalfDay?.tHour) - 1,
                      })
                      count1()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countA}
                    onClick={() => {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tHour: parseInt(teacherHalfDay?.tHour) + 1 < 25 ? parseInt(teacherHalfDay?.tHour) + 1 : 24,
                        tMinute: parseInt(teacherHalfDay?.tHour) < 24 ? teacherHalfDay?.tMinute : 0
                      });
                      count2()
                    }}
                  />}
                />
              </div>
              <div style={{marginRight:"11px",height:"10px"}}> : </div>
              <div>
                <Input
                  value={teacherHalfDay?.tMinute}
                  onChange={(e) => {
                    // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                    e.target.value = teacherHalfDay?.tHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                    teacherHalfdayAttendance({
                      ...teacherHalfDay,
                      tMinute: parseInt(e.target.value)
                    })
                    if (sameHalfAtt) {
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dMinute: parseInt(e.target.value)
                      })
                      opHalfDay({
                        ...operationHalfDay,
                        oMinute: parseInt(e.target.value)
                      })
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stMinute: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countD}
                    onClick={() => {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tMinute: parseInt(teacherHalfDay?.tMinute) - 1 > -1 ? parseInt(teacherHalfDay?.tMinute) - 1 : 0
                      })
                      count3()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countC} />
                    onClick={() => {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        // tMinute: parseInt(teacherHalfDay?.tMinute) + 1 < 60 ? parseInt(teacherHalfDay?.tMinute) + 1 : 59
                        tMinute: teacherHalfDay?.tHour == 24 ? 0 : parseInt(teacherHalfDay?.tMinute) + 1 < 60 ? parseInt(teacherHalfDay?.tMinute) + 1 : 59
                      })
                      count4()
                    }}
                  />
                  }
                  style={{ width: "150px" }}
                  type="number"
                  placeholder="Enter mins"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", fontFamily: "Roboto", color: "#191919", fontSize: "15px", fontFamily: "Roboto", fontWeight: 500 }}>Data Operators</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  style={{ width: "150px", marginRight: "15px", }}
                  type="number"
                  value={DoHalfDay?.dHour}
                  onChange={(e) => {
                    e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                    DoHalfdayAttendance({
                      ...DoHalfDay,
                      dHour: parseInt(e.target.value),
                      // dMinute: e.target.value < 24 ? DoHalfDay?.dMinute : 0
                      dMinute: DoHalfDay?.dHour? (parseInt(DoHalfDay?.dHour) +1 < 24 ? DoHalfDay?.dMinute : 0): null
                    })
                    if (sameHalfAtt) {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tHour: parseInt(e.target.value)
                      })
                      opHalfDay({
                        ...operationHalfDay,
                        oHour: parseInt(e.target.value)
                      })
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stHour: parseInt(e.target.value)
                      })
                    }
                    console.log(e.target.value, "ftvghb");
                  }}
                  placeholder={"Enter hours"}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countB}
                    onClick={() => {
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dHour: parseInt(DoHalfDay?.dHour) - 1 < 0 ? 0 : parseInt(DoHalfDay?.dHour) - 1,
                      });
                      count5()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countA}
                    onClick={() => {
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dHour: parseInt(DoHalfDay?.dHour) + 1 < 25 ? parseInt(DoHalfDay?.dHour) + 1 : 24,
                        dMinute: parseInt(DoHalfDay?.dHour) < 24 ? DoHalfDay?.dMinute : 0
                      });
                      count6()
                    }}
                  />}
                />
              </div>
              <div style={{marginRight:"11px",height:"10px"}}> : </div>
              <div>
                <Input
                  value={DoHalfDay?.dMinute}
                  onChange={(e) => {
                    // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                    e.target.value = DoHalfDay?.dHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                    DoHalfdayAttendance({
                      ...DoHalfDay,
                      dMinute: parseInt(e.target.value)
                    })
                    if (sameHalfAtt) {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tMinute: parseInt(e.target.value)
                      })
                      opHalfDay({
                        ...operationHalfDay,
                        oMinute: parseInt(e.target.value)
                      })
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stMinute: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countD}
                    onClick={() => {
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dMinute: parseInt(DoHalfDay?.dMinute) - 1 > -1 ? parseInt(DoHalfDay?.dMinute) - 1 : 0
                      })
                      count7()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countC} />
                    onClick={() => {
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        // dMinute: parseInt(DoHalfDay?.dMinute) + 1 < 60 ? parseInt(DoHalfDay?.dMinute) + 1 : 59
                        dMinute: DoHalfDay?.dHour == 24 ? 0 : parseInt(DoHalfDay?.dMinute) + 1 < 60 ? parseInt(DoHalfDay?.dMinute) + 1 : 59
                      })
                      count8()
                    }}
                  />
                  }
                  style={{ width: "150px" }}
                  type="number"
                  placeholder="Enter mins"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", fontFamily: "Roboto", color: "#191919", fontSize: "15px", fontFamily: "Roboto", fontWeight: 500 }}>Operations</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  style={{ width: "150px", marginRight: "15px", }}
                  type="number"
                  value={operationHalfDay?.oHour}
                  onChange={(e) => {
                    e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                    opHalfDay({
                      ...operationHalfDay,
                      oHour: parseInt(e.target.value),
                      oMinute: operationHalfDay?.oMinute ? (parseInt(operationHalfDay?.oMinute) +1  < 24 ? operationHalfDay?.oMinute : 0) : null
                    })
                    if (sameHalfAtt) {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tHour: parseInt(e.target.value)
                      })
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dHour: parseInt(e.target.value)
                      })
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stHour: parseInt(e.target.value)
                      })
                    }
                    console.log(e.target.value, "ftvghb");
                  }}
                  placeholder={"Enter hours"}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countB}
                    onClick={() => {
                      opHalfDay({
                        ...operationHalfDay,
                        oHour: parseInt(operationHalfDay?.oHour) - 1 < 0 ? 0 : parseInt(operationHalfDay?.oHour) - 1,
                      });
                      count9()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countA}
                    onClick={() => {
                      opHalfDay({
                        ...operationHalfDay,
                        oHour: parseInt(operationHalfDay?.oHour) + 1 < 25 ? parseInt(operationHalfDay?.oHour) + 1 : 24,
                        // oMinute: parseInt(operationHalfDay?.oHour) < 24 ? operationHalfDay?.oMinute : 0
                        oMinute: parseInt(staffAvgWorkingHrs?.oHour)  < 24 ? staffAvgWorkingHrs?.oMinute : 0
                      });
                      count10()
                    }}
                  />}
                />
              </div>
              <div style={{marginRight:"11px",height:"10px"}}> : </div>
              <div>
                <Input
                  value={operationHalfDay?.oMinute}
                  onChange={(e) => {
                    // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                    e.target.value = operationHalfDay?.oHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                    opHalfDay({
                      ...operationHalfDay,
                      oMinute: parseInt(e.target.value)
                    })
                    if (sameHalfAtt) {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tMinute: parseInt(e.target.value)
                      })
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dMinute: parseInt(e.target.value)
                      })
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stMinute: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countD}
                    onClick={() => {
                      opHalfDay({
                        ...operationHalfDay,
                        oMinute: parseInt(operationHalfDay?.oMinute) - 1 > -1 ? parseInt(operationHalfDay?.oMinute) - 1 : 0
                      })
                      count11()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countC} />
                    onClick={() => {
                      opHalfDay({
                        ...operationHalfDay,
                        // oMinute: parseInt(operationHalfDay?.oMinute) + 1 < 60 ? parseInt(operationHalfDay?.oMinute) + 1 : 59
                        oMinute: operationHalfDay?.oHour == 24 ? 0 : parseInt(operationHalfDay?.oMinute) + 1 < 60 ? parseInt(operationHalfDay?.oMinute) + 1 : 59
                      })
                      count12()
                    }}
                  />
                  }
                  style={{ width: "150px" }}
                  type="number"
                  placeholder="Enter mins"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", fontFamily: "Roboto", color: "#191919", fontSize: "15px", fontFamily: "Roboto", fontWeight: 500 }}>Students</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  style={{ width: "150px", marginRight: "15px", }}
                  type="number"
                  value={storeHalfDayStudent?.stHour}
                  onChange={(e) => {
                    e.target.value = e.target.value < 25 ? parseInt(e.target.value) : 24
                    halfDayStudent({
                      ...storeHalfDayStudent,
                      stHour: parseInt(e.target.value),
                      stMinute: storeHalfDayStudent?.stMinute ? (parseInt(storeHalfDayStudent?.stMinute) +1 < 24 ? storeHalfDayStudent?.stMinute : 0) : null
                    })
                    if (sameHalfAtt) {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tHour: parseInt(e.target.value)
                      })
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dHour: parseInt(e.target.value)
                      })
                      opHalfDay({
                        ...operationHalfDay,
                        oHour: parseInt(e.target.value)
                      })
                    }
                    console.log(e.target.value, "ftvghb");
                  }}
                  placeholder={"Enter hours"}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countB}
                    onClick={() => {
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stHour: parseInt(storeHalfDayStudent?.stHour) - 1 < 0 ? 0 : parseInt(storeHalfDayStudent?.stHour) - 1,
                      });
                      counthst3()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countA}
                    onClick={() => {
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stHour: parseInt(storeHalfDayStudent?.stHour) + 1 < 25 ? parseInt(storeHalfDayStudent?.stHour) + 1 : 24,
                        // oMinute: parseInt(operationHalfDay?.oHour) < 24 ? operationHalfDay?.oMinute : 0
                        stMinute: parseInt(storeHalfDayStudent?.stHour) + 1 < 24 ? storeHalfDayStudent?.stMinute : 0
                      });
                      counthst2()
                    }}
                  />}
                />
              </div>
              <div style={{marginRight:"11px",height:"10px"}}> : </div>
              <div>
                <Input
                  value={storeHalfDayStudent?.stMinute}
                  onChange={(e) => {
                    // e.target.value = e.target.value < 60 ? parseInt(e.target.value) : 59
                    e.target.value = storeHalfDayStudent?.stHour == 24 ? 0 : e.target.value < 60 ? parseInt(e.target.value) : 59
                    halfDayStudent({
                      ...storeHalfDayStudent,
                      stMinute: parseInt(e.target.value)
                    })
                    if (sameHalfAtt) {
                      teacherHalfdayAttendance({
                        ...teacherHalfDay,
                        tMinute: parseInt(e.target.value)
                      })
                      DoHalfdayAttendance({
                        ...DoHalfDay,
                        dMinute: parseInt(e.target.value)
                      })
                      opHalfDay({
                        ...operationHalfDay,
                        oMinute: parseInt(e.target.value)
                      })
                    }
                  }}
                  prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                    // onClick={countD}
                    onClick={() => {
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        stMinute: parseInt(storeHalfDayStudent?.stMinute) - 1 > -1 ? parseInt(storeHalfDayStudent?.stMinute) - 1 : 0
                      })
                      counthst()
                    }}
                  />}
                  suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                    // onClick={countC} />
                    onClick={() => {
                      halfDayStudent({
                        ...storeHalfDayStudent,
                        // oMinute: parseInt(operationHalfDay?.oMinute) + 1 < 60 ? parseInt(operationHalfDay?.oMinute) + 1 : 59
                        stMinute: storeHalfDayStudent?.stHour == 24 ? 0 : parseInt(storeHalfDayStudent?.stMinute) + 1 < 60 ? parseInt(storeHalfDayStudent?.stMinute) + 1 : 59
                      })
                      counthst1()
                    }}
                  />
                  }
                  style={{ width: "150px" }}
                  type="number"
                  placeholder="Enter mins"
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ borderBottom: "1px solid", color: "#E6E6E6", marginTop: "20px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#191919",
              marginTop: "20px",
              fontFamily: "Roboto"
            }}>Students/Teachers minimum attendance required per class</div>
            <div style={{ fontSize: "13px", color: "#636363", fontFamily: "Roboto" }} >Set a minimum attendance required for students/teachers to be marked present in a live class.</div>
          </div>
          <div>
            <Input
              style={{ width: "150px", marginRight: "15px", marginTop: "25px" }}
              type="number"
              value={storeAttendanceMin}
              onChange={(e) => {
                e.target.value = e.target.value < 101 ? parseInt(e.target.value) : 100
                storeAttendanceMinRequirement(parseInt(e.target.value));
                // console.log(e.target.value, "ftvghb");
              }}
              placeholder={"Enter percentage"}
              prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                // onClick={countB}
                onClick={() => {
                  CounterJ()
                }}
              />}
              suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                // onClick={countA}
                onClick={() => {
                  CounterI()
                }}
              />}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid", color: "#E6E6E6", marginTop: "20px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#191919",
              marginTop: "20px",
              fontFamily: "Roboto"
            }}>Maximum request for attendance</div>
            <div style={{ fontSize: "13px", color: "#636363", fontFamily: "Roboto" }}>Set a limit to the amount of times your User's can request for manual Clock In/Clock Out</div>
          </div>
          <div>
            <div style={{ marginTop: "35px", color: "#191919", fontSize: "13px", fontFamily: "Roboto" }}>
              <Checkbox 
                checked={sameMaximumAtt}
                style={{ marginRight: "15px" }}
                onChange={(e) => {
                  setSameMaximumAtt(e.target.checked);
                  setIsCheck(e.target.checked)
                  if (e.target.checked) {
                    storeAttendanceMaXRequirementTeacher(0)
                    storeAttendanceMaXRequirementDo(0)
                    storeAttendanceMaXOperatiron(0)
                    storeAttendanceMaXStudent(0)
                  }
                }}
              />Same for all</div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Teachers</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div>
              <Input
                style={{ width: "150px", marginRight: "15px" }}
                type="number"
                value={storeMaxTeacher}
                onChange={(e) => {
                  e.target.value = e.target.value < 32 ? parseInt(e.target.value) : 31
                  storeAttendanceMaXRequirementTeacher(parseInt(e.target.value));
                  if (sameMaximumAtt) {
                    storeAttendanceMaXRequirementDo(parseInt(e.target.value))
                    storeAttendanceMaXOperatiron(parseInt(e.target.value))
                    storeAttendanceMaXStudent(parseInt(e.target.value))
                  }
                }}
                placeholder={"Enter limits"}
                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                  // onClick={countB}
                  onClick={() => {
                    CounterL()
                  }}
                />}
                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                  // onClick={countA}
                  onClick={() => {
                    CounterK()
                  }}
                />}
              />
            </div>
            <div style={{ marginTop: "5px", color: "#191919", fontSize: "13px", fontFamily: "Roboto" }}>
              Per Month
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Data Operators</div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div>
              <Input
                style={{ width: "150px", marginRight: "15px" }}
                type="number"
                value={storeMaxDataOperater}
                onChange={(e) => {
                  e.target.value = e.target.value < 32 ? parseInt(e.target.value) : 31
                  storeAttendanceMaXRequirementDo(parseInt(e.target.value));
                  if (sameMaximumAtt) {
                    storeAttendanceMaXRequirementTeacher(parseInt(e.target.value))
                    storeAttendanceMaXOperatiron(parseInt(e.target.value))
                    storeAttendanceMaXStudent(parseInt(e.target.value))

                  }
                }}
                placeholder={"Enter limits"}
                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                  // onClick={countB}
                  onClick={() => {
                    CounterN()
                  }}
                />}
                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                  // onClick={countA}
                  onClick={() => {
                    CounterM()
                  }}
                />}
              />
            </div>
            <div style={{ marginTop: "5px", color: "#191919", fontSize: "13px", fontFamily: "Roboto" }}>
              Per Month
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Operations</div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div>
              <Input
                style={{ width: "150px", marginRight: "15px" }}
                type="number"
                value={storeMaxOperations}
                onChange={(e) => {
                  e.target.value = e.target.value < 32 ? parseInt(e.target.value) : 31
                  storeAttendanceMaXOperatiron(parseInt(e.target.value));
                  if (sameMaximumAtt) {
                    storeAttendanceMaXRequirementTeacher(parseInt(e.target.value))
                    storeAttendanceMaXRequirementDo(parseInt(e.target.value))
                    storeAttendanceMaXStudent(parseInt(e.target.value))

                  }
                }}
                placeholder={"Enter limits"}
                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                  // onClick={countB}
                  onClick={() => {
                    CounterP()
                  }}
                />}
                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                  // onClick={countA}
                  onClick={() => {
                    CounterO()
                  }}
                />}
              />
            </div>
            <div style={{ marginTop: "5px", color: "#191919", fontSize: "13px", fontFamily: "Roboto" }}>
              Per Month
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}>Students</div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div>
              <Input
                style={{ width: "150px", marginRight: "15px" }}
                type="number"
                value={storeMaxStudentData}
                onChange={(e) => {
                  e.target.value = e.target.value < 32 ? parseInt(e.target.value) : 31
                  storeAttendanceMaXStudent(parseInt(e.target.value));
                  if (sameMaximumAtt) {
                    storeAttendanceMaXRequirementTeacher(parseInt(e.target.value))
                    storeAttendanceMaXRequirementDo(parseInt(e.target.value))
                    storeAttendanceMaXOperatiron(parseInt(e.target.value))

                  }
                }}
                placeholder={"Enter limits"}
                prefix={<AiOutlineMinus style={{ cursor: "pointer" }}
                  // onClick={countB}
                  onClick={() => {
                    CounterQ()
                  }}
                />}
                suffix={<AiOutlinePlus style={{ cursor: "pointer" }}
                  // onClick={countA}
                  onClick={() => {
                    CounterR()
                  }}
                />}
              />
            </div>
            <div style={{ marginTop: "5px", color: "#191919", fontSize: "13px", fontFamily: "Roboto" }}>
              Per Month
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid", color: "#E6E6E6", marginTop: "20px" }}></div>

        <div style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#191919",
              marginTop: "20px",
              fontFamily: "Roboto"
            }}>Approval structure</div>
            <div style={{ fontSize: "13px", color: "#636363", fontFamily: "Roboto" }}>Define the number of approval levels</div>
            <Radio.Group onChange={onChangeLevel} value={storeApprovalId}>
        <div style={{ marginTop: "10px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}><Radio  value={1}/>Level 1</div>
        <div style={{ marginTop: "10px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}><Radio  value={2}/>Level 2</div>
        <div style={{ marginTop: "10px", color: "#191919", fontSize: "15px", fontFamily: "Roboto" }}><Radio  value={3}/>Level 3</div>
        </Radio.Group>
      </div>
    </div>
  );
}

function AttendanceRequirement({setSameMaximumAtt,sameHalfAtt,sameMaximumAtt,
  setSameHalfAtt,setSameMinimumAtt,sameMinimumAtt, attendanceRequirementDetail, getattendanceRequirement, storeWorkingDaysDetails, storeWorkingDays, storeAttendanceHoursMinutes, staffAvgWorkingHrs
  , attendanceTeacherHrsMinutes, teacherHoursMinutes, storeAttendancDo, storeAttendanceDataOperator,
  storeAttendanceOperation, storeAttendanceOp, storeAttendanceMinRequirement, storeAttendanceMin,
  storeMaxTeacher, storeAttendanceMaXRequirementTeacher, storeMaxDataOperater, storeMaxOperations,
  storeAttendanceMaXRequirementDo, storeAttendanceMaXOperatiron, teacherHalfDay, teacherHalfdayAttendance,
  DoHalfdayAttendance, DoHalfDay, opHalfDay, operationHalfDay, attendanceAllRequiredDetails, attRequirementPageLoader,
  attendanceSaveLoader, storeMaxStudentData, setBackToPrivilegedView, storeFullDayStudent, fullDayStudent, storeHalfDayStudent, halfDayStudent, storeAttendanceMaXStudent,
  isSubcomponent=false, saveRequirements, setSaveRequirementsFalse,storeApprovalId,setApprovalId
}) {
  console.log("AA-here",sameHalfAtt,sameMaximumAtt);
  const [reload,setReload]=useState(false)
  useEffect(() => {
    attendanceRequirementDetail(),
    setApprovalId(getattendanceRequirement?.approvalLevel)
      storeWorkingDays(getattendanceRequirement?.workingDays)
    const hours = Math.floor(parseInt(getattendanceRequirement?.minAvgWorkingHrForStaff) / 60);
    const minutes = parseInt(getattendanceRequirement?.minAvgWorkingHrForStaff) % 60;
    storeAttendanceHoursMinutes({ hours, minutes });
    const hour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForFullDayTeacher) / 60);
    const minute = parseInt(getattendanceRequirement?.minWorkingHrForFullDayTeacher) % 60;
    teacherHoursMinutes({ hour, minute })
    const doHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForFullDayDO) / 60);
    const doMinute = parseInt(getattendanceRequirement?.minWorkingHrForFullDayDO) % 60;
    storeAttendancDo({ doHour, doMinute })
    const opHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForFullDayOperation) / 60);
    const opMinute = parseInt(getattendanceRequirement?.minWorkingHrForFullDayOperation) % 60;
    storeAttendanceOp({ opHour, opMinute })
    storeAttendanceMinRequirement(getattendanceRequirement?.minAttendancePercentageStudent)
    storeAttendanceMaXRequirementTeacher(getattendanceRequirement?.requestCountTeacher)
    storeAttendanceMaXRequirementDo(getattendanceRequirement?.requestCountDO)
    storeAttendanceMaXOperatiron(getattendanceRequirement?.requestCountOperation)
    storeAttendanceMaXStudent(getattendanceRequirement?.requestCountStudent)
    const tHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForHalfDayTeacher) / 60);
    const tMinute = parseInt(getattendanceRequirement?.minWorkingHrForHalfDayTeacher) % 60;
    teacherHalfdayAttendance({ tHour, tMinute })
    const dHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForHalfDayDO) / 60);
    const dMinute = parseInt(getattendanceRequirement?.minWorkingHrForHalfDayDO) % 60;
    DoHalfdayAttendance({ dHour, dMinute })
    const oHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForHalfDayOperation) / 60);
    const oMinute = parseInt(getattendanceRequirement?.minWorkingHrForHalfDayOperation) % 60;
    opHalfDay({ oHour, oMinute })
    const sHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForFullDayStudent) / 60);
    const sMinute = parseInt(getattendanceRequirement?.minWorkingHrForFullDayStudent) % 60;
    fullDayStudent({ sHour, sMinute })
    const stHour = Math.floor(parseInt(getattendanceRequirement?.minWorkingHrForHalfDayStudent) / 60);
    const stMinute = parseInt(getattendanceRequirement?.minWorkingHrForHalfDayStudent) % 60;
    halfDayStudent({ stHour, stMinute })

  }, [getattendanceRequirement?.minAvgWorkingHrForStaff])
  const history = useHistory();
  // const goBack = () => {
  //   history.goBack("Attendance-DashBoard");
  // };
  useEffect(() => {
    if(saveRequirements){
      setReload(true); 
      attendanceAllRequiredDetails(
        ()=>{setReload(false)},
        ()=>{setBackToPrivilegedView(true);
      })
      setSaveRequirementsFalse();
    }
  }, [saveRequirements])
  const handleBackPress = () => {
    history.push('/attendance-dashboard');
    setBackToPrivilegedView(true);
  }

  const onSavePress = () => {
    setReload(true); 
    attendanceAllRequiredDetails(
      ()=>{setReload(false)},
      ()=>{setBackToPrivilegedView(true);
       history.push("Attendance-DashBoard")
    })
  }
  
  const easySwitch = [
    {label: 'Leaves', action: ()=>{history.push('/leave-graph')}},
    {label: 'Geo Fence', action: ()=>{history.push('/attendance-geofence')}},
    {label: 'Holidays', action: ()=>{history.push('/holiday-page')}},
    {label: 'Attendance Requirement', action: ()=>{history.push('/attendance-requirements')}}
  ]

  const actions = [
    <Button type="primary" onClick={()=>{onSavePress()}} style={{}}>SAVE</Button>
  ]

  return (
    <div style={bodyStyle(isSubcomponent)}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
        {!isSubcomponent ? <PageHeader title='Attendance Requirements' onBackPress={()=>handleBackPress()} actions={actions} easySwitch={easySwitch}/> : null}
        </div>
      </div>
      <div>
        <RequirementCard
          isSubcomponent={isSubcomponent}
          attendanceRequirementDetail={attendanceRequirementDetail}
          getattendanceRequirement={getattendanceRequirement}
          storeWorkingDays={storeWorkingDays}
          storeWorkingDaysDetails={storeWorkingDaysDetails}
          staffAvgWorkingHrs={staffAvgWorkingHrs}
          storeAttendanceHoursMinutes={storeAttendanceHoursMinutes}
          attendanceTeacherHrsMinutes={attendanceTeacherHrsMinutes}
          teacherHoursMinutes={teacherHoursMinutes}
          storeAttendancDo={storeAttendancDo}
          storeAttendanceDataOperator={storeAttendanceDataOperator}
          storeAttendanceOperation={storeAttendanceOperation}
          storeAttendanceOp={storeAttendanceOp}
          storeAttendanceMin={storeAttendanceMin}
          storeAttendanceMinRequirement={storeAttendanceMinRequirement}
          storeMaxTeacher={storeMaxTeacher}
          storeAttendanceMaXRequirementTeacher={storeAttendanceMaXRequirementTeacher}
          storeAttendanceMaXRequirementDo={storeAttendanceMaXRequirementDo}
          storeMaxDataOperater={storeMaxDataOperater}
          storeMaxOperations={storeMaxOperations}
          storeAttendanceMaXOperatiron={storeAttendanceMaXOperatiron}
          teacherHalfDay={teacherHalfDay}
          teacherHalfdayAttendance={teacherHalfdayAttendance}
          DoHalfDay={DoHalfDay}
          DoHalfdayAttendance={DoHalfdayAttendance}
          opHalfDay={opHalfDay}
          operationHalfDay={operationHalfDay}
          attendanceAllRequiredDetails={attendanceAllRequiredDetails}
          storeFullDayStudent={storeFullDayStudent}
          fullDayStudent={fullDayStudent}
          storeHalfDayStudent={storeHalfDayStudent}
          halfDayStudent={halfDayStudent}
          storeAttendanceMaXStudent={storeAttendanceMaXStudent}
          storeMaxStudentData={storeMaxStudentData}
          setSameMinimumAtt={setSameMinimumAtt}
          sameMinimumAtt={sameMinimumAtt}
          setSameMaximumAtt={setSameMaximumAtt}
          setSameHalfAtt={setSameHalfAtt}
          sameHalfAtt={sameHalfAtt}
          sameMaximumAtt={sameMaximumAtt}
          setApprovalId={setApprovalId}
          storeApprovalId={storeApprovalId}

        />
      </div>
      <PageDataLoader visible={isSubcomponent ? false : attRequirementPageLoader} />
    </div>
  );
}
const mapStateToProps = (state) => {
  const { getattendanceRequirement,sameHalfAtt,sameMaximumAtt, storeWorkingDaysDetails, staffAvgWorkingHrs, attendanceTeacherHrsMinutes,
    storeAttendanceDataOperator, storeAttendanceOperation, storeAttendanceMin, storeMaxTeacher, storeMaxDataOperater,
    sameMinimumAtt, storeMaxOperations, teacherHalfDay, DoHalfDay, operationHalfDay, attRequirementPageLoader, attendanceSaveLoader, storeFullDayStudent, storeHalfDayStudent, storeMaxStudentData,storeApprovalId } = state.attendanceNew;
  return {
    getattendanceRequirement,sameHalfAtt,sameMaximumAtt, storeWorkingDaysDetails, staffAvgWorkingHrs, attendanceTeacherHrsMinutes,
    storeAttendanceDataOperator, storeAttendanceOperation, storeAttendanceMin, storeMaxTeacher, storeMaxDataOperater,sameMinimumAtt,
    storeMaxOperations, teacherHalfDay, DoHalfDay, operationHalfDay, attRequirementPageLoader, attendanceSaveLoader, storeFullDayStudent, storeHalfDayStudent, storeMaxStudentData,storeApprovalId

  };
};
const mapDispatchToProps = (dispatch) => ({
  storeWorkingDays: (value) => dispatch(storeWorkingDays(value)),
  attendanceAllRequiredDetails: (callBack,successCallBack) => dispatch(attendanceAllRequiredDetails(callBack,successCallBack)),
  attendanceRequirementDetail: () => dispatch(attendanceRequirementDetail()),
  storeAttendanceHoursMinutes: (value) => dispatch(storeAttendanceHoursMinutes(value)),
  teacherHoursMinutes: (value) => dispatch(teacherHoursMinutes(value)),
  storeAttendancDo: (value) => dispatch(storeAttendancDo(value)),
  storeAttendanceOp: (value) => dispatch(storeAttendanceOp(value)),
  storeAttendanceMinRequirement: (value) => dispatch(storeAttendanceMinRequirement(value)),
  storeAttendanceMaXRequirementTeacher: (value) => dispatch(storeAttendanceMaXRequirementTeacher(value)),
  storeAttendancTeacher: (value) => dispatch(storeAttendancTeacher(value)),
  storeAttendanceMaXRequirementDo: (value) => dispatch(storeAttendanceMaXRequirementDo(value)),
  storeAttendanceMaXOperatiron: (value) => dispatch(storeAttendanceMaXOperatiron(value)),
  teacherHalfdayAttendance: (val) => dispatch(teacherHalfdayAttendance(val)),
  DoHalfdayAttendance: (val) => dispatch(DoHalfdayAttendance(val)),
  opHalfDay: (val) => dispatch(opHalfDay(val)),
  attendanRequirementSaveLoader: (val) => dispatch(attendanRequirementSaveLoader(val)),
  setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
  fullDayStudent: (val) => dispatch(fullDayStudent(val)),
  halfDayStudent: (val) => dispatch(halfDayStudent(val)),
  storeAttendanceMaXStudent: (value) => dispatch(storeAttendanceMaXStudent(value)),
  setSameMinimumAtt:(res)=>dispatch(setSameMinimumAtt(res)),
  setSameHalfAtt:(res)=>dispatch(setSameHalfAtt(res)),
  setSameMaximumAtt:(res)=>dispatch(setSameMaximumAtt(res)),
  setApprovalId:(val)=>dispatch(setApprovalId(val))
  
});
export default connect(mapStateToProps, mapDispatchToProps)(AttendanceRequirement);