import {
    UPDATE_INSTITUTE_DETAILS,
    UPDATE_USER_DETAILS,
    UPDATE_SUPPORT_NUMBERS,
    SET_GS_TAB,
    SET_KYC_TAB,
    STORE_KYC_DETAILS,
    SET_SUBSCRIPTIONS_TAB,
    UPDATE_STORAGE_SUBS_DETAILS,
    UPDATE_SEARCHED_USERS_LIST,
    UPDATE_BROADCAST_SUBS_DETAILS,
    UPDATE_ATTENDANCE_SETTINGS,
    UPDATE_AULAS_SUBS_DETAILS,
    SET_OTHERS_TAB,
    UPDATE_STUDENT_CLASS_DETAILS,
    UPDATE_STUDENT_FEE_DETAILS,
    UPDATE_USER_ATTENDANCE_OVERVIEW,
    UPDATE_USER_ATTENDANCE_REQUESTS,
    UPDATE_HOLIDAYS,
    UPDATE_LEAVES_DATA,
    STORE_CFRT,
    PAYMENT_DETAILS,
    UPDATE_USER_ACTIVITY,
    APPEND_USER_ACTIVITY,
    SET_TRANSACTION_DATA,
    STORE_MARKETPLACE_SETTING
} from 'actions/profileNew';

const initialState = {
    instituteDetails: {},
    userDetails: {},
    attendanceReminderSettings: {},
    supportPhones: [],
    gsSelectedTab: "User management",
    kycSelectedTab: "Contact information",
    subscriptionsSelectedTab: "aulas Plan",
    othersSelectedTab: "Classroom & course(s)",
    kycDetails: {},
    storageSubscriptionDetails: {},
    searchedUsers: [],
    boradcastSubscriptionDetails: {},
    aulasSubscriptionDetails: {},
    studentCourseDetails: {},
    studentFeeDetails: {},
    attendanceOverview: {},
    attendanceRequests: {},
    holidays: {},
    leaveDetails: {},
    cfrToken: null,
    paymentDetails: {},
    userActivity: {},
    storeTransactionData: {},
    marketPlaceData: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_ACTIVITY:
            return {
                ...state,
                userActivity: action.data
            }
        case APPEND_USER_ACTIVITY:
            {
                let newUserActivity = state.userActivity

                newUserActivity.meta = action?.data?.meta
                let len = state.userActivity?.activity.length
                if (len && state.userActivity?.activity[len - 1]?.monthYear == action.data.activity[0].monthYear) {
                    newUserActivity.activity[len - 1]?.items
                        .push(...action.data.activity[0]?.items)

                    if (action.data.activity.length >= 1)
                        action.data.activity.shift()

                }

                newUserActivity?.activity
                    .push(...action.data.activity)

                return {
                    ...state,
                    userActivity: newUserActivity
                }
            }

        case UPDATE_LEAVES_DATA:
            return {
                ...state,
                leaveDetails: { ...action.data }
            }
        case UPDATE_HOLIDAYS:
            return {
                ...state,
                holidays: { ...action.data }
            }
        case UPDATE_USER_ATTENDANCE_REQUESTS:
            return {
                ...state,
                attendanceRequests: { ...action.data }
            }
        case UPDATE_USER_ATTENDANCE_OVERVIEW:
            return {
                ...state,
                attendanceOverview: { ...action.data }
            }
        case UPDATE_STUDENT_FEE_DETAILS:
            return {
                ...state,
                studentFeeDetails: { ...action.data }
            }
        case UPDATE_AULAS_SUBS_DETAILS:
            return {
                ...state,
                aulasSubscriptionDetails: { ...action.data }
            }
        case UPDATE_BROADCAST_SUBS_DETAILS:
            return {
                ...state,
                boradcastSubscriptionDetails: { ...action.data }
            }
        case UPDATE_SEARCHED_USERS_LIST:
            return {
                ...state,
                searchedUsers: [...action.data]
            }
        case UPDATE_STORAGE_SUBS_DETAILS:
            return {
                ...state,
                storageSubscriptionDetails: { ...action.data }
            }
        case SET_SUBSCRIPTIONS_TAB:
            return {
                ...state,
                subscriptionsSelectedTab: action.tab
            }
        case STORE_KYC_DETAILS:
            return {
                ...state,
                kycDetails: { ...action.data }
            }
        case SET_KYC_TAB:
            return {
                ...state,
                kycSelectedTab: action.tab,
            };
        case SET_GS_TAB:
            return {
                ...state,
                gsSelectedTab: action.tab,
            };
        case SET_OTHERS_TAB:
            return {
                ...state,
                othersSelectedTab: action.tab,
            };
        case UPDATE_ATTENDANCE_SETTINGS:
            return {
                ...state,
                attendanceReminderSettings: { ...action.data }
            }
        case UPDATE_STUDENT_CLASS_DETAILS:
            return {
                ...state,
                studentCourseDetails: { ...action.data }
            }
        case UPDATE_INSTITUTE_DETAILS:
            return {
                ...state,
                instituteDetails: { ...action.data },
            };
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                userDetails: { ...action.data },
            };
        case UPDATE_SUPPORT_NUMBERS:
            return {
                ...state,
                supportPhones: [...action.data],
            };
        case STORE_CFRT:
            return {
                ...state,
                cfrToken: action.val
            }
        case PAYMENT_DETAILS:
            return {
                ...state,
                paymentDetails: action.res
            }
        case SET_TRANSACTION_DATA:
            return {
                ...state,
                storeTransactionData: action.res
            };

        case STORE_MARKETPLACE_SETTING:
            return {
                ...state,
                marketPlaceData: action.res
            }

        default: {
            return {
                ...state
            }
        }
    }
}



export default reducer;