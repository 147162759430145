import { Modal } from 'antd';
import React from 'react';
import "./styles.css";

export const renderCardContent = (label, value, width) => (
  <div className="modal-light-purple-card" style={{ width: width }}>
    <div style={{ fontSize: "12px", color: "#636363" }}>{label}</div>
    <div className="m-t-5" style={{ fontSize: "14px", color: "#191919", fontWeight: "bold" }}>{value}</div>
  </div>
);

function RecentAddedCourseModal({ isCourseModalVisible, setIsCourseModalVisible, courseType, courseName, fee, installments, createdBy, createdAt }) {
  const handleCancel = () => {
    setIsCourseModalVisible(false);
  };

  return (
    <Modal
      centered
      className="modal-round-corner"
      visible={isCourseModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold", marginTop: -10 }}>{courseType} created</div>
      <div className="m-t-20">
        {renderCardContent("Course name", courseName, "100%")}
      </div>
      <div className="r-jsb m-t-10" style={{ width: "100%" }}>
        {renderCardContent("Fee", fee, "100%")}
      </div>
      <div className="r-jsb m-t-10" style={{ width: "100%" }}>
        {createdBy ? renderCardContent("Created by", createdBy, "49%") : null}
        {renderCardContent("Created on", createdAt, createdBy ? "49%" : "100%")}
      </div>
    </Modal>
  );
}

export default RecentAddedCourseModal;