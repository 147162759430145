import React, { useState } from 'react';
import { Modal, Button } from 'antd';
// import './modal.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchPerformance, fetchResult } from 'actions/studentPracticeTest';


const ExitSuccessModal = ({ isModalVisible, handleOk, close, history, selectedTest, fetchResult, fetchPerformance }) => {

    const handleHomeClick = () => {
        close();
        history.push('/')
        close();

    }

      const handleResultClick = () => {
            close();
      }

    return (
        <div >
            <Modal className="modal-round-corner" centered  visible={isModalVisible} onOk={handleOk} closable={true}  onCancel={close} footer={null}>

                <div className="message-area" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>

                    <div className="test1 m-t-10">
                        <h2 style={{ fontWeight: "700px", fontSize: "23px" }}>Are you sure want to exit ?</h2>
                    </div>

                    <div className='m-t-50' style={{ borderRadius: "5px", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                        <Button type="primary" shape="round" onClick={handleResultClick} style={{ 
                             width: "120px", height: "10%", color: "#636363", borderColor: '#636363', marginRight: '30px', backgroundColor: "white" }}
                           >NO</Button>
                        <Button type="primary" shape="round" onClick={handleHomeClick} style={{ backgroundColor: "#594099", width: "120px", height: "10%", color: "white" }}>YES</Button>

                    </div>
                </div>




            </Modal>
        </div>
    );
};


const mapStateToProps = state => {
    const {
        //  selectedTest
    } = state.studentPracticeTest;
    return {
        //  selectedTest
    };
};

const mapDispatchToProps = dispatch => ({
    //   fetchResult:(callback)=>dispatch(fetchResult(callback)),
    //   fetchPerformance:(callback)=>dispatch(fetchPerformance(callback)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ExitSuccessModal);
