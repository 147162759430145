import React, { useEffect, useState } from 'react'
import {Popover, Input, Checkbox, Tooltip} from'antd'
import { CaretDownOutlined } from '@ant-design/icons'


function DropdownCheckbox({DATA, placeholder, style, className, onSelection, onDropdownClose, defaultValue}) {
    const [checked, setChecked] = useState([...DATA].fill(false));
    const [trigger, setTrigger] = useState(false);
    const onChange = (index) => {
        return (e) => {
            checked[index] = !checked[index]
            setChecked(checked);
            setTrigger(!trigger);
        }
    }
    const CheckboxContent = () => {
        useEffect(()=>{

        }, [trigger]);
        return (
            <div className="flex-column" style={{ maxWidth: "200px", maxHeight: "30vw", overflowY: "auto", overflowX:"hidden", marginLeft:-16, marginRight: -16, marginTop:-12, width:"fit-content"}}>
            <Checkbox.Group className="flex-column" onChange={onSelection} defaultValue={defaultValue}>
              {DATA?.length > 0
                ? DATA?.map(
                    (item, index) => (
                      <Checkbox className="black p-10 m-l-0 full-width" style={{backgroundColor:checked[index]===true?"#F6F4FE":null, fontWeight:checked[index]?'700':'400'}}
                                value={item?.id}
                                onChange={onChange(index)}>
                        <Tooltip title={item.val} 
                                 placement="left" 
                                 mouseEnterDelay={0.2}
                                 overlayInnerStyle={{borderRadius:5, marginLeft:7, marginRight:7}}>
                            <div className="m-l-10 text-overflow-ellipsis full-width" style={{fontSize:14}}>
                                {item.val}
                            </div>
                        </Tooltip>
                      </Checkbox>
                    )
                  )
                : null}
            </Checkbox.Group>
          </div>
        )
    }

  return (
    <Popover    placement="bottomRight"
                content={CheckboxContent}
                trigger="click"
                overlayInnerStyle={{borderRadius:7}}
                onVisibleChange={(bool)=>{
                    if (!bool && onDropdownClose!=undefined){
                      onDropdownClose();
                    }
                }}>
        <div className="r-c-c" style={{backgroundColor:"#FFFFFF", borderRadius: "5px", border:"1px solid #E6E6E6"}}>
        <Input  style={{...style, width: "150px", height: "30px"}}
                className={className}
                placeholder={placeholder}
                size="small"
                value={null}
                bordered={false}
                // suffix={}
                readOnly
        />
        <CaretDownOutlined style={{color:"#AEAEAE"}} className="m-r-5"/>
        </div>
    </Popover>
  )
}

export default DropdownCheckbox