import React, { useState , useEffect} from 'react'
import { fetchTeacherData,fetchAdminLibraryData,
  updateSetSort,fetchAdminChapterFilter,
  updateSetTopic,updateChapterFilter,
  toogleEditModal,deleteNotes,currentFileToShow,uploadContent,currentDocument,currentTypes,postEngagementHistory} from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography } from 'antd';



 function TeachAndStudNotes({
  chapterName,topic,file,id,filesSize,toogleEditModal,deleteNotes,types,icons,currentFileToShow,uploadContent,currentDocument,
  currentTypes,std,section,img,postEngagementHistory,user,subject
}) {
    
    const history = useHistory();

    const handleNotes=(id)=>{
      //console.log("file",file)
      uploadContent({filePath:file})
      currentFileToShow('pdf')
      currentDocument(id)
      currentTypes(types)
      if(user.access.includes("digital-library-teacher")||user.access.includes('digital-library-student')){
        // if(user.role.includes("Teacher")||user.role.includes("Student")){
        let arr = []
        arr[0] = id
        postEngagementHistory({libraryIds:arr,type:'engagement'})
      }
      history.push("/digital-library/view-content")
      
    }
  
  const handleDeletenotes=(id)=>{
        deleteNotes(id)
        fetchAdminLibraryData() 
    }
      const [isVisibl,setIsModalVisibl] = useState(false)
      const[modal,setmodal]=useState(false)
    //   const menu = (
    //     <Menu  style={{width:"100px",background:"#FFFFFF",boxShadow:"8px 8px 18px #302A5F14",borderRadius:"8px"}}>
    //       <Menu.Item key="1" onClick={()=>setIsModalVisibl(true)} icon={< EditOutlined />}>
    //         Edit
    //       </Menu.Item>
    //       <Menu.Item key="2" icon={<DeleteOutlined />} onClick={()=>handleDeletenotes(id)}>
    //         Delete
    //       </Menu.Item>
    //     </Menu>
        
    //   );
    return (
        <div className='lecturenotesmain cursor-pointer' onClick={()=>handleNotes(id)}>
            <div className='lecturenotefirst'>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                {/* <SquareSubIcon icon={icons} name={chapterName} size={40}/> */}
                <img style={{marginRight:'170px'}} className="image" src ={img}/>
                    {/* <Dropdown overlay={menu} disabled={false}placement="bottomRight" >
                        <MdMoreVert style={{width:"24px", height:"24px", color:"#AEAEAE"}}/>
                    </Dropdown>
                    <EditInformation ismodelvisible={isVisibl} setmodevisible={setIsModalVisibl} id={id} setmodal={setmodal} types={types} /> */}
                </div>

              <div>
                  <p className='lecturenotestitle cursor-pointer' >{chapterName}</p>
                  {/* <Typography.Text className='lecturnotesdescription' style={{ width: "120px" }} ellipsis={{tooltip:true}}>{topic}</Typography.Text> */}
                  <Typography.Text className='lecturnotesdescription' style={{ width: "120px" }} ellipsis={{tooltip:true}}>{topic}</Typography.Text>
                  {/* <p className='lecturnotesdescription'>{topic}</p> */}
                  <p style={{color:'#AEAEAE',fontSize:'12px'}}>{subject} | {std}: {section}</p>
              </div>
          </div>
          
      </div>
  )
}

const mapStateToProps = (state) => {
  const {
      classLibraryList,
      classSubjectList,
      teacherMangementList,
      adminLibraryList,
      selectedSubject,
      getChaptersList,
      selectedStd,
      icons

    
  } = state.digitalLibrary;
  const {user} = state.session
  return {
      classLibraryList,
      classSubjectList,
      teacherMangementList,
      adminLibraryList,
      selectedSubject,
      getChaptersList,
      selectedStd,
      icons,
      user
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  fetchAdminLibraryData: ()=>dispatch(fetchAdminLibraryData()),
  fetchAdminChapterFilter: ()=>dispatch(fetchAdminChapterFilter()),
  toogleEditModal: (bool)=>dispatch(toogleEditModal(bool)),
  deleteNotes :(id)=>dispatch(deleteNotes(id)),
  currentFileToShow : (data)=> dispatch(currentFileToShow(data)),
  uploadContent : (object) => dispatch(uploadContent(object)),
  currentDocument : (id)=> dispatch(currentDocument(id)),
  currentTypes : (type)=> dispatch(currentTypes(type)),
  postEngagementHistory: (object)=> dispatch(postEngagementHistory(object))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(TeachAndStudNotes);