import { Heading } from "components/Typography";
import { PageHeader, Row, Col, Select, Progress, Button, Typography, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
// import RangePicker from "react-range-picker"
import { Color } from "services";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchDashBoardData,
  updateStudentTestFilter,
  setSelectedSub,
  setSelectedTest,
  setDuration,
  updateTestType,
  UpdateStudentTestData,
} from "actions/studentTest";
import PageDataLoader from "components/pageDataLoader";
import { SquareSubIcon } from "components/subIcon";
import StartTestModal from "./modals/startTestModal";
import { TestTimer } from "./timer";
import { DatePicker } from "antd";
import moment, { months } from "moment";

const { RangePicker } = DatePicker;

const { Option } = Select;

const rangeFilterValues = [
  {
    label: "All Time",
    payload: "All Time",
    value: null,
  },
  {
    label: "This Week",
    payload: "This Week",
    value: "week",
  },
  {
    label: "This Month",
    payload: "This Month",
    value: "month",
  },
  {
    label: "This Year",
    payload: "This Year",
    value: "year",
  },
  {
    label: "Customize date range",
    payload: "Pick a date range",
    value: "custom",
  },
];

const singleSub = (sub, index, handleSubjectClick) => {
  if (sub.subjectId !== null) {
    return (
      <div
        className="r-c-c-c subCard m-t-20"
        key={index}
        onClick={() => handleSubjectClick(sub)}
      >
        <SquareSubIcon name={sub.subjectName} icon={sub.icon} size={24} />
        <div className="bold-800 m-t-20">{sub.subjectName}</div>
        <div className="text-xs dark-grey">
          {sub.testCount} {sub.testCount === 1 ? "Test" : "Tests"}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
const bookGreen = require("../../Assets/test/bookGreen.svg").default;
const bookRed = require("../../Assets/test/bookRed.svg").default;
const bookBlue = require("../../Assets/test/bookBlue.svg").default;
const bookPurple = require("../../Assets/test/bookPurple.svg").default;
const chapterTest = require("../../Assets/test/chapterTest.svg").default;
const fullTest = require("../../Assets/test/fullTest.svg").default;
const MathTest = require("../../Assets/test/sbj-mathematics.svg").default;
const GeoTest = require("../../Assets/test/sbj-geography.svg").default;
const ChemTest = require("../../Assets/test/sbj-chemistry.svg").default;
const MockTest = require("../../Assets/test/question-bank.svg").default;
const ICChapterTest = require("../../Assets/test/ic-chapter-test.svg").default;

const IconAndVal = ({ val, iconUrl, refer }) => {
  return (
    <div>
      <Tooltip title={refer === 1 ? "Subjective Test" : refer === 0 ? "Objective Test" : ""} placement="topRight" trigger={"hover"} overlayInnerStyle={{ borderRadius: 5 }}>
        <img
          style={{
            width: "18px",
            height: "18px",
          }}
          src={iconUrl}
          alt=""
        />
      </Tooltip>
      <span style={{ marginLeft: "5px" }}>{val}</span>
    </div>
  );
};

const sub = [
  {
    iconUrl: require("../../Assets/test/ic-chapter-test.svg").default,
    testType: "Chapter",
    testDescp: "dasdasdada",
  },
  {
    iconUrl: require("../../Assets/test/ic-part-test.svg").default,
    testType: "Part",
    testDescp: "dasdasdada",
  },
  {
    iconUrl: require("../../Assets/test/ic-full-test.svg").default,
    testType: "Full",
    testDescp: "dasdasdada",
  },
];

// const RangePickerElement = <RangePicker dropdownClassName="studentTestRangePicker" format="DD/MM/YYYY" showTime/>
const SyllabusCard = ({
  iconUrl,
  testType,
  testDescp,
  objectiveVal,
  subjectiveVal,
  UpdateStudentTestData,
  item
}) => {
  const history = useHistory();
  const navigateToChapter = () => {
    if (testType?.[0] === "C") {
      history.push("student-ChapterTest");
    } else if (testType?.[0] === "P") {
      history.push("student-part-test")
    } else if (testType?.[0] === "F") {
      history.push("student-full-test")
    }

  };

  return (
    <>
      <div
        className="cursor-pointer shadow-box hover-shadow customCardShadow "
        style={{
          height: "180px",
          width: "250px",
          borderRadius: "10px",
          border: "1px solid #E6E6E6",
          margin: "10px 10px 10px 0",
        }}
        onClick={() => {
          navigateToChapter();
          UpdateStudentTestData("selectedTestCard", {
            testType,
          });
        }}
      >
        <div className="p-t-10">
          <div className="r-c-c-c m-t-10">
            {/* {console.log('aa', testType)} */}
            <Tooltip title={testType?.[0] == "C" ? "Chapter Test" : testType?.[0] == "F" ? "Full Test" : testType?.[0] == "P" ? "Part Test" : ''} placement="topRight" trigger={"hover"} overlayInnerStyle={{ borderRadius: 5 }}>
              <img
                style={{
                  width: "60px",
                  height: "60px",
                }}
                src={
                  testType?.[0] == "C"
                    ? ICChapterTest
                    : testType?.[0] == "F"
                      ? fullTest
                      : testType?.[0] == "P"
                        ? chapterTest
                        : null
                }
                alt=""
              />
            </Tooltip>
          </div>
          <h1
            className="bold-700"
            style={{
              color: "#191919",
              textAlign: "center",
              fontSize: "14px",
              paddingTop: "5px",
              marginBottom: "0px",
            }}
          >
            {testType}
          </h1>
          <div
            className="bold-600"
            style={{
              color: "#8C8C8C",
              textAlign: "center",
              fontSize: "10px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            {testType?.[0] == "C"
              ? "To provide a formative assessment of chapter-wise expertise"
              : testType?.[0] == "F"
                ? "To simulate an actual exam with full syllabus"
                : testType?.[0] == "P"
                  ? "To provide a formative assessment of a group of chapters"
                  : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "10px 10px 10px 10px",
          }}
        >
          <IconAndVal iconUrl={bookGreen} val={objectiveVal} refer={0} />
          <IconAndVal iconUrl={bookBlue} val={subjectiveVal} refer={1} />
        </div>
      </div>
    </>
  );
};

export const testCard = (
  type,
  test,
  handleStartClick = null,
  index = null,
  viewResult,
  timeLeft = {
    hours: 0,
    minutes: 0,
    seconds: 0,
    inMinutes: 0,
  },
  viewSolution
) => {
  if (type === "live") {

    const now = moment();
    timeLeft = moment
      .duration(
        moment(test.endTime, "HH:mm:ss").diff(
          now
        )
      )
      .asSeconds();

    timeLeft = {
      hours: Math.floor(timeLeft / 3600).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      minutes: Math.floor((timeLeft % 3600) / 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      seconds: Math.floor((timeLeft % 3600) % 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      inMinutes: timeLeft / 60,
    }

    return (
      <div className="testCard m-t-10">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "130%",
          }}
        >
          <div
            className="dateCard r-c-c-c text-xs bold-700"
            style={{ color: "#594099", marginRight: "20px" }}
          >
            {test?.publishDate && (
              <div style={{ textTransform: "uppercase" }}>
                {moment(test?.testDate, 'YYYY-MM-DD').format("MMM DD")}
              </div>
            )}
            <div>{moment(test?.startTime, "HH:mm:ss").format("h:mm a")}</div>
            {/* <div>{moment(test.startTime).format('h:mm a')}</div> */}
          </div>
          <div style={{ width: "55%" }}>
            <div style={{ width: "100%" }} className="r-jsb relative">
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {test?.title}
              </div>
              <div classname="absolute">
                <img
                  src={require("Assets/live-dot.gif").default}
                  width={50}
                  height={22}
                  alt=""
                />
              </div>
            </div>
            <div
              style={{
                fontSize: 12,
                color: Color.darkGrey,
                overflow: "hidden",
                width: "95%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test?.subjectNames?.split(",").map((item, index, array) => (
                <React.Fragment key={index}>
                  {" "}
                  {item} {index === array.length - 1 ? "" : "•"}{" "}
                </React.Fragment>
              ))}
            </div>
            <div style={{ fontSize: 11, color: Color.aeGrey }}>
              {test?.totalQuestions}Qs •{" "}
              {test?.duration ? test?.duration / 60 : 0}mins •{" "}
              {test?.totalPoints}marks
            </div>
          </div>
        </div>
        <div className="r-c-c m-t-20">
          <Button
            shape="round"
            type="primary"
            style={{ width: 200, height: "fit-content", minHeight: 40 }}
            onClick={() => handleStartClick(test)}
            disabled={
              test?.testProgress === "SUBMITTED" ||
              test?.testProgress === "GRADED" ||
              timeLeft.inMinutes < 0
            }
          >
            {test?.testProgress === "SUBMITTED" ? (
              <div className="bold-600" style={{ fontSize: 12 }}>
                SUBMITTED
              </div>
            ) : (
              <>
                <div className="bold-600" style={{ fontSize: 12 }}>
                  START TEST
                </div>
                {timeLeft.inMinutes >= 0 ? (
                  <div style={{ fontSize: 10 }}>
                    {/* Ends in {timeLeft?.hours ? `${timeLeft?.hours}hr` : null}{" "}
                    {timeLeft?.minutes || 0} mins */}
                    Ends in{" "}
                    {/* {moment(
                     moment.diff(moment(test?.endTime, "HH:mm:ss"))
                      - moment()
                    ).format("HH:mm:ss")}{" "} */}
                    {/* {moment( */}
                    {parseInt(
                      moment(test?.endTime, "hh:mm:ss").diff(
                        moment(),
                        "minute"
                      ) / 60
                    )}{" "}
                    hr{" "}
                    {parseInt(
                      moment(test?.endTime, "hh:mm:ss").diff(
                        moment(),
                        "minute"
                      ) % 60
                    )}
                    {/* // ).format("hh:mm:ss")} */}
                    mins
                  </div>
                ) : null}
              </>
            )}
          </Button>
        </div>
      </div>
    );
  } else if (type === "published") {
    return (
      <div className="testCard m-t-10" key={index} style={{ height: 150 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="r-c-c-c m-t-10">
            <img
              style={{
                width: "60px",
                height: "60px",
              }}
              src={
                test?.syllabus?.[0] == "C"
                  ? ICChapterTest
                  : test?.syllabus?.[0] == "F"
                    ? fullTest
                    : test?.syllabus?.[0] == "P"
                      ? chapterTest
                      : null
              }
              alt=""
            />
          </div>
          <div style={{ marginTop: "10px", marginLeft: "15px", width: '100%' }}>
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{
                fontSize: 14,
                fontWeight: 700,
                width: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test?.title}
            </Typography.Text>
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{
                fontSize: 12,
                color: Color.darkGrey,
                overflow: "hidden",
                width: "80%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test?.subjectNames?.split(",").map((item, index, array) => (
                <React.Fragment key={index}>
                  {" "}
                  {item} {index === array.length - 1 ? "" : "•"}{" "}
                </React.Fragment>
              ))}
            </Typography.Text>
            <div style={{ fontSize: 11, color: Color.aeGrey }}>
              {test?.totalQuestions}Qs •{" "}
              {test?.duration ? test.duration / 60 : 0}mins •{" "}
              {test?.totalPoints}
              marks
            </div>
          </div>
        </div>
        <div className="r-jsb m-t-30">
          <div style={{ fontSize: 11, color: Color.aeGrey }}>
            {test.testType === "Subjective"
              ? test?.declared
                ? `Result published on ${test?.resultDate}`
                : "Result will be published after evaluation"
              : test?.declared
                ? `Result published on ${test?.resultDate}`
                : `Result will be pubslished on ${test.resultDate}`}
          </div>
          {test?.declared ? (
            <Button
              type="link"
              size="small"
              className="viewsolutionsbtn"
              style={{ padding: 0, fontSize: 10 }}
              onClick={() => viewResult(test)}
            >
              VIEW RESULT <ArrowRightOutlined />
            </Button>
          ) : null}
        </div>
      </div>
    );
  } else if (type === "scheduled") {
    const testDate = test?.testDate?.split(",");
    return (
      <div
        className="testCard m-t-10 r-c-c"
        key={index}
        style={{ height: 120, maxWidth: 380 }}
      >
        <Row className="r-c-c full-width">
          <Col span={6}>
            <div
              className="dateCard r-c-c-c text-xs bold-700"
              style={{ color: "#594099" }}
            >
              {testDate && (
                <div style={{ textTransform: "uppercase" }}>
                  {testDate[1]} {testDate[0]}
                </div>
              )}
              <div>{test?.startTime}</div>
            </div>
          </Col>
          <Col span={18} className="p-l-10">
            <div
              style={{
                fontSize: 14,
                fontWeight: 700,
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test?.title}
            </div>
            <div
              style={{
                fontSize: 12,
                color: Color.darkGrey,
                overflow: "hidden",
                width: "95%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test?.subjectNames?.split(",").map((item, index, array) => (
                <React.Fragment key={index}>
                  {" "}
                  {item} {index === array.length - 1 ? "" : "•"}{" "}
                </React.Fragment>
              ))}
            </div>
            <div style={{ fontSize: 11, color: Color.aeGrey }}>
              {test?.totalQuestions ? test.totalQuestions : 0}Qs •{" "}
              {test?.duration ? test?.duration / 60 : 0}mins •{" "}
              {test?.totalPoints}
              marks
            </div>
          </Col>
        </Row>
      </div>
    );
  } else if (type === "published_missed") {
    return (
      <div
        className="testCard m-t-10"
        key={index}
        style={{ height: 150, width: 380 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="r-c-c-c m-t-10">
            <img
              style={{
                width: "60px",
                height: "60px",
              }}
              src={
                test?.syllabus?.[0] == "C"
                  ? ICChapterTest
                  : test?.syllabus?.[0] == "F"
                    ? fullTest
                    : test?.syllabus?.[0] == "P"
                      ? chapterTest
                      : null
              }
              alt=""
            />
          </div>
          <div style={{ marginTop: "10px", marginLeft: "15px" }}>
            <div
              className="text-overflow-ellipsis"
              style={{
                fontSize: 14,
                fontWeight: 700,
                width: "35%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test.title}
            </div>
            <div
              style={{
                fontSize: 12,
                color: Color.darkGrey,
                overflow: "hidden",
                width: "95%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {test.subjectNames?.split(",").map((item, index, array) => (
                <React.Fragment key={index}>
                  {" "}
                  {item} {index === array.length - 1 ? "" : "•"}{" "}
                </React.Fragment>
              ))}
            </div>
            <div style={{ fontSize: 11, color: Color.aeGrey }}>
              {test?.totalQuestions ? test?.totalQuestions : 0}Qs •{" "}
              {test.duration ? test.duration / 60 : 0}mins • {test.totalPoints}
              marks
            </div>
          </div>
        </div>

        <div className="r-jsb m-t-30">
          <div style={{ fontSize: 14, color: Color.aeGrey }}>Expired</div>
          <Button
            type="link"
            size="small"
            className="viewsolutionsbtn"
            style={{ padding: 0, fontSize: 10 }}
            onClick={() => viewSolution(test)}
          >
            VIEW SOLUTIONS <ArrowRightOutlined />
          </Button>
        </div>
      </div>
    );
  }
};

function StudentTestDashboard({
  fetchDashBoardData,
  ongoingTest,
  publishedTests,
  scheduledTests,
  totalTestsCount,
  missedTestsCount,
  subjects,
  syllabuses,
  studentTestFilterParams,
  updateStudentTestFilter,
  setSelectedSub,
  setSelectedTest,
  setDuration,
  timeLeft,
  updateTestType,
  studentTestData,
  UpdateStudentTestData,
}) {
  let timer = null;

  const history = useHistory();
  const location = useLocation();

  // const modalref = createRef();
  const [pageLoader, togglePageLoader] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  console.log({ ongoingTest })

  const handleStartClick = (test) => {
    setSelectedTest(test);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSubjectClick = (sub) => {
    setSelectedSub(sub);
    history.push("/student-test/subject");
  };

  const viewResult = (test) => {
    console.log("AA-test", test);
    setSelectedTest(test);
    history.push("/student-test/result");
  };

  useEffect(() => {
    UpdateStudentTestData("selectedTestCard", null);
    togglePageLoader(true);
    updateTestType("");
    fetchDashBoardData(() => {
      togglePageLoader(false);
      TestTimer.startTimer(ongoingTest, setDuration);
    });
    return () => {
      updateStudentTestFilter(null, "rangeType");
      TestTimer.clearTimer(setDuration);
    };
  }, []);

  const onRangeSelect = (val) => {
    updateStudentTestFilter(val, "rangeType");
    if (val !== "custom") {
      togglePageLoader(true);
      fetchDashBoardData(() => togglePageLoader(false));
    }
  };
  const selectButton = useRef(null);
  const handlePickerChange = (value, dateString) => {
    togglePageLoader(true);
    fetchDashBoardData(
      (bool) => togglePageLoader(false),
      dateString[0],
      dateString[1]
    );
  };
  return (
    <div style={{ height: "100%" }} className="studentTestHome">
      <Row style={{ height: "100%" }}>
        <Col
          md={{ span: 12 }}
          lg={{ span: 15 }}
          xl={{ span: 15 }}
          xxl={{ span: 17 }}
        >
          <div style={{ height: "100%" }}>
            <PageHeader title={<div className="text-md">Tests</div>} onBack={
              (location?.state?.syllabus) ? () => { history.goBack() } : null
            }
            />
            {/*Section 1 */}
            <div style={{ padding: "20px 20px" }}>
              <div className="r-jsb">
                <Heading bold>Overview</Heading>
                <div className="r-jfe">
                  {studentTestFilterParams?.rangeType == "custom" ? (
                    <RangePicker
                      className="RangePickerCustom"
                      getPopupContainer={(trigger) => trigger.parentElement}
                      style={{ width: "220px" }}
                      onChange={(val, datsring) =>
                        handlePickerChange(val, datsring)
                      }
                      footer={false}
                      dropdownClassName="studentTestRangePicker"
                      format="DD/MM/YYYY"
                      showTime
                    />
                  ) : null}
                  <Select
                    style={{ width: 150, marginLeft: "10px" }}
                    placeholder=""
                    value={studentTestFilterParams?.rangeType}
                    onChange={(val) => onRangeSelect(val)}
                    ref={selectButton}
                    optionLabelProp="payload"
                  >
                    {rangeFilterValues?.map((item) => (
                      <Option
                        key={item.value}
                        value={item.value}
                        payload={item.payload}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="r-c-c-c graphBox" style={{ height: 420 }}>
                <div>
                  <Progress
                    type="circle"
                    percent={Math.round(
                      ((totalTestsCount - missedTestsCount) / totalTestsCount) *
                      100
                    )}
                    strokeWidth={15}
                    width={220}
                    strokeLinecap="circle"
                    strokeColor="#A8DCD9"
                    trailColor="#BBDEFC"
                    format={(percent) => (
                      <>
                        <div
                          className="bold-600 m-b-10 "
                          style={{ fontSize: "0.6em" }}
                        >
                          {totalTestsCount}
                        </div>
                        <div
                          style={{ fontSize: "0.3em" }}
                          className="dark-grey bold-600"
                        >
                          Total Tests conducted
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="r-jsb m-t-50" style={{ width: 250 }}>
                  <div className="r-c-c">
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: "#A8DCD9",
                        borderRadius: 5,
                      }}
                    />
                    <div className="m-l-10">
                      <div className="bold-600">
                        {totalTestsCount - missedTestsCount}
                      </div>
                      <div className="text-xs dark-grey">Attempted</div>
                    </div>
                  </div>
                  <div className="r-c-c">
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: "#BBDEFC",
                        borderRadius: 5,
                      }}
                    />
                    <div className="m-l-10">
                      <div className="bold-600">{missedTestsCount}</div>
                      <div className="text-xs dark-grey">Missed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px 20px" }}>
              <div className="r-jsb">
                <Heading bold>Syllabus</Heading>
              </div>
              <div className="display-flex flex-wrap" style={{ height: 200 }}>
                {syllabuses?.map((item) => (
                  <div
                    onClick={() => {
                      console.log(item);
                    }}
                  >
                    <SyllabusCard
                      UpdateStudentTestData={UpdateStudentTestData}
                      iconUrl={item.iconUrl}
                      testType={item?.syllabus}
                      testDescp={item?.syllabus}
                      objectiveVal={item?.objectiveCount}
                      subjectiveVal={item?.subjectiveCount}
                      item={item}
                    />
                  </div>
                ))}
                {/* {subjects?.map((sub, index) =>
                  singleSub(sub, index, handleSubjectClick)
                )} */}
              </div>
            </div>
          </div>
        </Col>
        <Col
          md={{ span: 12 }}
          lg={{ span: 9 }}
          xl={{ span: 9 }}
          xxl={{ span: 7 }}
        >
          <div style={{ height: "100%" }}>

            {ongoingTest?.length > 0 ? <Heading bold>On-going Live Test</Heading>:<></>}
            {ongoingTest?.map((item) => {
              return (<div style={{ padding: "20px 20px", width: "100%" }}>
                {testCard(
                  "live",
                  item,
                  handleStartClick,
                  null,
                  null,
                  timeLeft
                )}
              </div>)
            })}
            {publishedTests?.length ? (
              <div style={{ padding: "20px 20px", width: "100%" }}>
                <div className="r-jsb">
                  <Heading bold>Published Result</Heading>
                  {/* {publishedTests?.length > 2 ? (
                    <Button type="link" size="small" style={{ padding: 0 }}>
                      View All
                    </Button>
                  ) : null} */}
                </div>
                {publishedTests?.map((test, index) => (
                  <div>
                    {index < 3
                      ? testCard(
                        "published",
                        test,
                        null,
                        index,
                        viewResult,
                        null,
                        history
                      )
                      : null}
                  </div>
                ))}
              </div>
            ) : null}
            {scheduledTests?.length ? (
              <div style={{ padding: "20px 20px", width: "100%" }}>
                <div className="r-jsb">
                  <Heading bold>Upcoming Test</Heading>
                  {/* {scheduledTests?.length > 2 ? (
                    <Button type="link" size="small" style={{ padding: 0 }}>
                      View All
                    </Button>
                  ) : null} */}
                </div>
                {scheduledTests?.map((test, index) =>
                  testCard("scheduled", test, null, index)
                )}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <PageDataLoader visible={pageLoader} />
      <StartTestModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    ongoingTest,
    publishedTests,
    scheduledTests,
    totalTestsCount,
    missedTestsCount,
    subjects,
    syllabuses,
    studentTestFilterParams,
    timeLeft,
    studentTestData,
  } = state.studentTest;
  return {
    ongoingTest,
    publishedTests,
    scheduledTests,
    totalTestsCount,
    missedTestsCount,
    subjects,
    syllabuses,
    studentTestFilterParams,
    timeLeft,
    studentTestData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashBoardData: (callback, startDate, endDate) =>
    dispatch(fetchDashBoardData(callback, startDate, endDate)),
  updateStudentTestFilter: (val, key) =>
    dispatch(updateStudentTestFilter(val, key)),
  setSelectedSub: (data) => dispatch(setSelectedSub(data)),
  setSelectedTest: (data) => dispatch(setSelectedTest(data)),
  setDuration: (duration) => dispatch(setDuration(duration)),
  updateTestType: (newType) => dispatch(updateTestType(newType)),
  UpdateStudentTestData: (key, val) =>
    dispatch(UpdateStudentTestData(key, val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentTestDashboard);
