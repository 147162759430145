import React, { useState } from 'react';
import { AutoComplete } from 'antd';
import "../AnimatedTextField/textFieldStyles.css"

const AnimatedAutoComplete = ({ label, value, onSelect, onSearch, loading, placeholder, renderOption, inputId, inputStyle }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className="custom-input-container m-t-20">
            <label htmlFor={`customInput_${inputId}`} className={`custom-input-label ${isFocused || value ? 'shrink' : ''}`}>
                {label} <span style={{ color: 'red' }}>*</span>
            </label>
            <AutoComplete
                allowClear={true}
                dropdownMatchSelectWidth={252}
                className="autocompletetoupload custom-autocomplete"
                onSelect={onSelect}
                onSearch={onSearch}
                loading={loading}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={{ marginTop: 5, ...inputStyle }}
                placeholder={placeholder}
                value={value}
            >
                {renderOption && renderOption()}
            </AutoComplete>
        </div>
    );
};

export default AnimatedAutoComplete;