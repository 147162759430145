const axios = require("axios");

const options = pin =>  ({
  method: 'GET',
  url: `https://api.postalpincode.in/pincode/${pin}`,

});


export const getAddressFromPin = async (pin, callback) => {
    await axios.request(options(pin)).then(function (response) {
        callback(
            response.data[0]?.PostOffice ? response?.data[0]?.PostOffice[0].District : "",
            response.data[0]?.PostOffice ?  response?.data[0]?.PostOffice[0]?.State : "",
            response.data[0]?.PostOffice ?  response?.data[0]?.PostOffice[0]?.Country : ""
        )
    }).catch(function (error) {
        console.error(error);
    });
}
