import {
  addCourses,
  deleteCourse,
  getSelectedCourseData,
  setCourseBillingPlan,
  setCourseDuration,
  setCourseName,
  setCoursePrice,
  setCourseRef,
  setInstallments,
} from "actions/course";
import { Input, Select, Modal } from "antd";
import { ceil, parseInt } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Date_Picker from "./datePicker";
import StartDate_Picker from "./courseStartDate";
import image from "../../Assets/ProfileAssets/Group 1.svg";
import moment from "moment";
import datePicker from "pages/care/datePicker";
import Notification from "services/Notification";
import { CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useHistory } from "react-router";
import { Button } from "components/Button";

function AddCourseModal({
  isModalVisible,
  handleOk,
  setIsModalVisible,
  courseName,
  courseDuration,
  courseRef,
  setCourseName,
  setCoursePrice,
  setCourseDuration,
  setCourseRef,
  setCourseBillingPlan,
  setInstallments,
  installments,
  isEditModalVisible,
  selectedCourseDataContainer,
  addCourses,
  togglePageLoader,
  setSuccessModalVisible,
  getSelectedCourseData,
  setConfirmDeleteModalVisible,
  setIsFromSubjects
}) {
  const [addButtonLdr, setAddButtonLdr] = useState(false);
  const [counter, setCounter] = useState(0);
  const [courseStartDate, setCourseStartDate] = useState(null);
  const [courseEndDate, setCourseEndDate] = useState(null);
  const [newCourseEndDate, setNewCourseEndDate] = useState(null);
  const [courseDescription, setCourseDescription] = useState("");
  const [renderEndDate, setRenderEndDate] = useState(false)

  useEffect(() => {
    if (newCourseEndDate < courseStartDate) {
      setNewCourseEndDate(null);
    }
  }, [newCourseEndDate, courseStartDate]);

  // useEffect(() => {
  //   if (courseStartDate && newCourseEndDate) {
  //     let tempStartDate = moment(courseStartDate)
  //     let tempEndDate = moment(newCourseEndDate)
  //     if (courseRef == "1" && courseDuration) {
  //       setCourseDuration((tempEndDate.diff(tempStartDate, 'days') + 1).toString())
  //     } else if (courseRef == "30" && courseDuration) {
  //       setCourseDuration((tempEndDate.diff(tempStartDate, 'months') + 1).toString())
  //     } else if (courseRef == "360" && courseDuration) {
  //       setCourseDuration((tempEndDate.diff(tempStartDate, 'years') + 1).toString())
  //     }
  //   }
  // }, [newCourseEndDate])

  // useEffect(() => {
  //   if (courseDuration && courseRef) {
  //     if ((parseInt(courseDuration) > 60 && parseInt(courseRef) == 30) || (parseInt(courseDuration) > 5 && parseInt(courseRef) == 360) || (parseInt(courseDuration) > 1825 && parseInt(courseRef) == 1)) {
  //       Notification.error("Error", 'Duration should not be greater than 5 years');
  //     }
  //   }
  // }, [courseDuration])

  useEffect(() => {
    if (renderEndDate) {
      const returnaDurationType = (val) => {
        if (val == "1") {
          return "days";
        } else if (val == "30") {
          return "months";
        } else if (val == "360") {
          return "years";
        }
      };
      let temp = moment(courseStartDate)
        .add(courseDuration, returnaDurationType(courseRef))
        .subtract(1, "days")
        .format("DD-MM-YYYY");
      setCourseEndDate(temp !== NaN && temp != "Invalid date" ? temp : "");
      if (temp !== NaN && temp != "Invalid date") {
        setNewCourseEndDate(moment(temp, "DD-MM-YYYY").format("YYYY-MM-DD"));
      }
    }
  }, [courseStartDate, courseDuration, courseRef]);

  useEffect(() => {
    let temp = 0;
    for (let i = 0; i < installments?.length; i++) {
      if (
        installments &&
        installments[i]?.installment &&
        installments[i]?.installment !== undefined
      ) {
        temp++;
      }
    }
    if (installments?.length == "0") {
      setCounter(0);
    } else {
      setCounter(temp);
    }
  }, [installments]);
  const returnDays = (val) => {
    if (val == "Day(s)") {
      return "1";
    } else if (val == "Monthly" || val == "month" || val == "Month(s)") {
      return "30";
    } else if (val == "Quarterly") {
      return "90";
    } else if (val == "Half Yearly") {
      return "180";
    } else if (val == "Annually" || val == "Year(s)") {
      return "360";
    } else if (val == "Yearly") {
      return "360";
    } else if (val == "One time") {
      return "One time";
    } else if (val == "Customize") {
      return "Customize";
    }
  };

  useEffect(() => {
    if (isEditModalVisible) {
      setCourseName(selectedCourseDataContainer?.courseName);
      setCourseDescription(selectedCourseDataContainer?.courseDescription);
      setCoursePrice(selectedCourseDataContainer?.amount);
      setCourseRef(returnDays(selectedCourseDataContainer?.durationType));
      setCourseDuration(selectedCourseDataContainer?.duration);
      setCourseBillingPlan(returnDays(selectedCourseDataContainer?.billingPlan));
      setCourseStartDate(selectedCourseDataContainer?.startDate)
      setNewCourseEndDate(selectedCourseDataContainer?.endDate)
      setInstallments(JSON.parse(selectedCourseDataContainer?.installmentDetails));
    } else {
      setCourseName(null);
      setCoursePrice(null);
      setCourseRef(null);
      setCourseDuration(null);
      setCourseBillingPlan(null);
    }
  }, []);

  let isUpdateDisable = false
  if (selectedCourseDataContainer?.courseName != courseName || selectedCourseDataContainer?.courseDescription != courseDescription || selectedCourseDataContainer?.duration != courseDuration || returnDays(selectedCourseDataContainer?.durationType) != courseRef || selectedCourseDataContainer?.startDate != courseStartDate || selectedCourseDataContainer?.endDate != newCourseEndDate) {
    isUpdateDisable = false
  } else {
    isUpdateDisable = true
  }

  return (
    <Modal
      centered
      className="modal-round-corner"
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        if (isEditModalVisible) {
          setCourseRef(returnDays(selectedCourseDataContainer?.durationType));
          setCourseDuration(selectedCourseDataContainer?.duration);
        } else {
          setCourseRef(null);
          setCourseDuration(null);
        }
      }}
      onOk={() => {
        handleOk();
      }}
      footer={null}>
      <div
        style={{
          fontSize: "22px",
          color: "#191919",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isEditModalVisible ? "Edit" : "Add a course"}
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        padding: 10
      }}>
        <div>
          <h6
            style={{
              fontSize: "14px",
              color: "#636363",
              fontWeight: "bold",
            }}
          >
            Course title<span style={{ color: "red" }}> *</span>
          </h6>
          <Input
            style={{ width: "100%" }}
            placeholder="Enter course title"
            value={courseName}
            onChange={(e) => {
              setCourseName(
                e.target.value == " " ? null : e.target.value
              );
            }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <h6
            style={{
              fontSize: "14px",
              color: "#636363",
              fontWeight: "bold",
            }}
          >
            Description
          </h6>
          <TextArea
            style={{ width: "100%", height: 120 }}
            placeholder="Enter description"
            value={courseDescription}
            onChange={(e) => {
              setCourseDescription(
                e.target.value == " " ? null : e.target.value
              );
            }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <h6
            style={{
              fontSize: "14px",
              color: "#636363",
              fontWeight: "bold",
            }}
          >
            Duration<span style={{ color: "red" }}> *</span>
          </h6>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Input
            style={{
              width: "49%",
              border: "#E6E6E6 solid 1px",
              borderRadius: "5px",
              color: "#000000",
              marginRight: "5px",
            }}
            placeholder="Enter Duration"
            value={courseDuration}
            onChange={(e) => {
              if ((parseInt(e.target.value) > 60 && parseInt(courseRef) == 30 && courseRef != '' && e.target.value != '') || (parseInt(e.target.value) > 5 && parseInt(courseRef) == 360 && courseRef != '' && e.target.value != '') || (parseInt(e.target.value) > 1825 && parseInt(courseRef) == 1 && courseRef != '' && e.target.value != '')) {
                Notification.error("Error", 'Duration should not be greater than 5 years');
              }
              if (
                e.target.value != " " &&
                e.target.value != "0" &&
                /^[\d]*$/.test(e.target.value)
              ) {
                setCourseBillingPlan(null);
              }
              setCourseDuration(
                e.target.value != " " &&
                  e.target.value != "0" &&
                  /^[\d]*$/.test(e.target.value)
                  ? e.target.value
                  : courseDuration
                    ? courseDuration
                    : null
              );
              setRenderEndDate(true)
            }}
            type="text"
          />{" "}
          <Select
            placeholder="Select plan"
            defaultValue={null}
            style={{ width: "49%" }}
            value={courseRef}
            onChange={(e) => {
              if ((parseInt(courseDuration) > 60 && courseDuration != '' && e == '30' && e != '') || (parseInt(courseDuration) > 5 && e == '360' && courseDuration != '' && e != '') || (parseInt(courseDuration) > 1825 && e == '1' && courseDuration != '' && e != '')) {
                Notification.error("Error", 'Duration should not be greater than 5 years');
              }
              setCourseRef(e);
              setCourseBillingPlan(null);
              setInstallments([])
              setCoursePrice(null)
              setRenderEndDate(true)
            }}
          >
            <Option value="1">Day(s)</Option>
            <Option value="30">Month(s)</Option>
            <Option value="360">Year(s)</Option>
          </Select>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", width: "100%" }}>
          <div style={{ width: "49%" }}>
            <h6
              style={{
                fontSize: "14px",
                color: "#636363",
                fontWeight: "bold",
              }}
            >
              Start date<span style={{ color: "red" }}> *</span>
            </h6>
            <StartDate_Picker
              value={courseStartDate}
              setCourseStartDate={setCourseStartDate}
              setRenderEndDate={setRenderEndDate}
            />
          </div>
          <div style={{ width: "49%" }}>
            <h6
              style={{
                fontSize: "14px",
                color: "#636363",
                fontWeight: "bold",
              }}
            >
              End date<span style={{ color: "red" }}> *</span>
            </h6>
            <StartDate_Picker
              value={newCourseEndDate}
              setCourseStartDate={setNewCourseEndDate}
              endDate={true}
              courseStartDate={courseStartDate}
              setRenderEndDate={setRenderEndDate}
            />
          </div>
        </div>
      </div>
      <div className="r-c-c m-t-20">
        {isEditModalVisible ?
          <div className="r-c-sa" style={{ width: "100%" }}>
            <Button
              style={{ color: "red", fontWeight: "bold", fontFamily: "roboto", border: "none" }}
              onClick={() => {
                setIsModalVisible(false);
                setIsFromSubjects(false)
                setConfirmDeleteModalVisible(true)
              }}>
              DELETE
            </Button>
            <Button
              type="primary"
              loading={addButtonLdr}
              disabled={
                isUpdateDisable ||
                courseName == "" || courseName == undefined ||
                courseRef == "" || courseRef == undefined ||
                courseDuration == "" || courseDuration == undefined ||
                courseStartDate == '' || courseStartDate == undefined ||
                newCourseEndDate == '' || newCourseEndDate == undefined ||
                counter !== installments?.length ||
                ((parseInt(courseDuration) > 60 && parseInt(courseRef) == 30 && courseDuration != '' && courseRef != '') || (parseInt(courseDuration) > 5 && parseInt(courseRef) == 360 && courseDuration != '' && courseRef != '') || (parseInt(courseDuration) > 1825 && parseInt(courseRef) == 1 && courseDuration != '' && courseRef != ''))
              }
              onClick={() => {
                setAddButtonLdr(true);
                addCourses(
                  () => setAddButtonLdr(false),
                  () => {
                    setIsModalVisible(false);
                    togglePageLoader(true);
                    getSelectedCourseData(() => togglePageLoader(false));
                  },
                  isEditModalVisible,
                  courseDescription,
                  courseStartDate,
                  newCourseEndDate
                );
              }}>
              UPDATE
            </Button>
          </div>
          :
          <Button
            type="primary"
            loading={addButtonLdr}
            disabled={
              courseName == "" || courseName == undefined ||
              courseRef == "" || courseRef == undefined ||
              courseDuration == "" || courseDuration == undefined ||
              courseStartDate == '' || courseStartDate == undefined ||
              newCourseEndDate == '' || newCourseEndDate == undefined ||
              counter !== installments?.length ||
              ((parseInt(courseDuration) > 60 && parseInt(courseRef) == 30 && courseDuration != '' && courseRef != '') || (parseInt(courseDuration) > 5 && parseInt(courseRef) == 360 && courseDuration != '' && courseRef != '') || (parseInt(courseDuration) > 1825 && parseInt(courseRef) == 1 && courseDuration != '' && courseRef != ''))
            }
            onClick={() => {
              setAddButtonLdr(true);
              addCourses(
                () => setAddButtonLdr(false),
                () => {
                  setIsModalVisible(false);
                  setSuccessModalVisible(true)
                },
                isEditModalVisible,
                courseDescription,
                courseStartDate,
                newCourseEndDate
              );
            }}
          >
            {"ADD"}
          </Button>
        }
      </div>
    </Modal>
  );
}


const mapStateToProps = (state) => {
  const { courseName, coursePrice, courseDuration, courseRef, courseBillingPlan, installments, selectedCourseDataContainer, } = state.course;
  return { courseName, coursePrice, courseDuration, courseRef, courseBillingPlan, installments, selectedCourseDataContainer, };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseName: (val) => dispatch(setCourseName(val)),
  setCoursePrice: (val) => dispatch(setCoursePrice(val)),
  setCourseDuration: (val) => dispatch(setCourseDuration(val)),
  setCourseRef: (val) => dispatch(setCourseRef(val)),
  setCourseBillingPlan: (val) => dispatch(setCourseBillingPlan(val)),
  setInstallments: (val) => dispatch(setInstallments(val)),
  addCourses: (callback, successCallBack, val, desc, startDate, endDate) => dispatch(addCourses(callback, successCallBack, val, desc, startDate, endDate)),
  deleteCourse: (callback, successCallBack) => dispatch(deleteCourse(callback, successCallBack)),
  getSelectedCourseData: (callback) => dispatch(getSelectedCourseData(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseModal);
