import React, { useEffect, useState } from 'react';
import {Col, Row, Input, Button, Modal } from 'antd';
import PhoneInput from 'react-phone-number-input';
import Country from 'react-phone-number-input/locale/en';
import { Color } from 'services';
import { Heading, SubHeading } from 'components/Typography';


export const contactInfo = (kycDetails, handleUpdateDetails,verifiedOtpforRegistration,setVerfiyOtpModal,postMobileNumberForRegistration,verfiyOtpModal,otpKey,verifyOtpLoader,postVerifyOtpForRegistration,resendOtpTimer,sendOtpLoader,timer,verifiedOtpForRegistrationAction,organisationDetails) => {

    const [otp,setOtp] = useState('');
    const [isOtpVerified,setIsOtpVerified] = useState(false);
    const [existingPhoneNum,setExistingPhoneNum] = useState(kycDetails?.contactPhone)    

    function sendOtpForRegistration(phone) {
        const number = phone.replace(/-/g, "").replace(/ /g, "");
        postMobileNumberForRegistration(number, "");
      }
    useEffect(() => {
        if (organisationDetails?.contactPhone !== kycDetails?.contactPhone) {
            setIsOtpVerified(true)
            verifiedOtpForRegistrationAction(false)
        } else {
            setIsOtpVerified(false);
        }
    }, [kycDetails?.contactPhone])

    return(
        <>
            <div style={{ width: "100%" }}>
                <Row className="m-t-20">
                    <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                        <div className='text-xs color-black m-b-5 bold-600' >Name <span style={{ color: Color.red }}>*</span></div>
                        <Input onChange={(e) => handleUpdateDetails(e.target.value, 'contactName')}
                            value={kycDetails?.contactName} placeholder="Name of the contact person"
                            className='radius-5 kyc-input-fields'
                            style={{ background: Color.lightGrey, border: 'none' }}
                            disabled={kycDetails?.kycStatus === "Submitted" || kycDetails?.kycStatus === "Approved"}
                        />
                    </Col>
                    <Col xs={{ span: 24, }} md={{ span: 11, offset: 1 }} style={{ width: '100%' }} >
                        <div className='text-xs color-black m-b-5 bold-600' >Phone <span style={{ color: Color.red }}>*</span> </div>
                        <div className="r-c-sb kyc-input-fields" style={{ padding: '0px 0px 0px 10px' }}>
                            <PhoneInput
                                style={{ width: '100%', height: "60px", minWidth: '100px', maxWidth: '100%' }}
                                className='orgPhoneInput'
                                defaultCountry="IN"
                                placeholder="Phone Number"
                                suffix={"abc"}
                                value={kycDetails?.contactPhone}
                                onCountryChange={(e) => {
                                    console.log('code', Country[e]);
                                }}
                                maxLength={(kycDetails?.contactPhone?.startsWith('+91') || kycDetails?.contactPhone?.startsWith('91')) && kycDetails?.contactPhone?.length == 13 ? 11 : ""}
                                disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                                onChange={(e) => { 
                                    handleUpdateDetails(e, 'contactPhone');                                   
                                      }}
                            />
                            {
                               isOtpVerified ?
                                    <>
                                        {
                                            verifiedOtpforRegistration
                                                ? <Button onClick={() => {
                                                    //Nothing
                                                }} type="text" className="bold-bold"
                                                    style={{
                                                        color: "#28DF99",
                                                        backgroundColor: 'transparent',
                                                        height: '20px',
                                                        marginTop: 0,
                                                        marginLeft: -120,
                                                    }} >
                                                    Verified
                                                </Button>
                                                : <Button onClick={() => {
                                                    sendOtpForRegistration(kycDetails?.contactPhone);
                                                    setVerfiyOtpModal(true);
                                                    setOtp("")
                                                }} type="text" className="bold-bold"
                                                    disabled={(kycDetails?.contactPhone?.length > 12 && kycDetails?.contactPhone?.length <= 17) ? false : true}
                                                    style={{
                                                        color: "#1089FF",
                                                        backgroundColor: 'transparent',
                                                        height: '20px',
                                                        marginTop: 0,
                                                        marginLeft: -120,
                                                        opacity: (kycDetails?.contactPhone?.length > 12 && kycDetails?.contactPhone?.length <= 17) ? 1.0 : 0.5,
                                                    }} >
                                                    SEND OTP
                                                </Button>
                                        }
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                        <div className='text-xs color-black m-b-5 bold-600' >Email <span style={{ color: Color.red }}>*</span></div>
                        <Input onChange={(e) => handleUpdateDetails(e.target.value, 'contactEmail')}
                            value={kycDetails?.contactEmail} placeholder="Email ID"
                            className='radius-5 kyc-input-fields' style={{ background: Color.lightGrey, border: 'none' }}
                            disabled={kycDetails?.kycStatus === "Submitted" || kycDetails?.kycStatus === "Approved"}
                        />
                    </Col>
                    <Col xs={{ span: 24, }} md={{ span: 11, offset: 1 }} style={{ width: '100%' }} >
                        {/* <div className="display-flex">        
                    <div className='text-xs color-black m-b-5 bold-600' >Alternate Email <span style={{color:Color.red}}>*</span></div>
                    <Tooltip title="In case a student does not provide an email, all payment related communications will be sent to this email.">
                        <InfoCircleOutlined style={{marginLeft: 10, color: Color.darkGrey}} />
                    </Tooltip>
                </div>    
                <Input onChange={(e) => handleUpdateDetails(e.target.value, 'alternateEmail')}
                value={kycDetails.contactEmail} placeholder="Alternate Email ID" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/> */}

                    </Col>
                </Row>
            </div>
            <Modal
                centered
                closable={true}
                footer={null}
                transitionName=""
                maskClosable={false}
                visible={verfiyOtpModal}
                className="verifyOtpModal"
                onCancel={() => {
                    setOtp("");
                    setVerfiyOtpModal(false);
                }}
            >
                <Heading bold className='r-c-c m-t-10'>Verify Your Number</Heading>
                <SubHeading color="#636363" className='r-c-c m-t-10'>Please enter the verification code we’ve sent you on</SubHeading>

                <div className='r-c-c m-t-5'>
                    <SubHeading bold color="#636363">{kycDetails?.contactPhone}</SubHeading>
                    <Button onClick={() => {
                        setOtp("");
                        setVerfiyOtpModal(false);
                    }} type="text" className="bold-bold"
                        style={{ color: '#1089FF' }} >
                        Edit
                    </Button>
                </div>

                <div style={{ position: "relative" }} className="r-c-c m-t-100">
                    <div className="r-c-c" style={{ fontWeight: 700 }}>
                        <div className="otpInputNew">{otp[0] ? "*" : null}</div>
                        <div className="otpInputNew">{otp[1] ? "*" : null}</div>
                        <div className="otpInputNew">{otp[2] ? "*" : null}</div>
                        <div className="otpInputNew">{otp[3] ? "*" : null}</div>
                        <div className="otpInputNew">{otp[4] ? "*" : null}</div>
                        <div className="otpInputNew">{otp[5] ? "*" : null}</div>
                    </div>
                    <input type="number" className='aulas-login__input-verify-otp'
                        value={otp}
                        onChange={(em) => setOtp(em.target.value.substring(0, 6))}
                    />
                </div>
                <div className="m-b-30" style={{ marginTop: 50, marginLeft: 130, marginRight: 130 }}>
                    <Button block type="primary" shape="round" className="r-c-c bold-bold" style={{ padding: 20, }}
                        onClick={() => postVerifyOtpForRegistration(kycDetails?.contactPhone, otp)} loading={verifyOtpLoader}
                    >
                        VERIFY
                    </Button>
                </div>

                <div className='r-c-c' style={{ marginLeft: 40 }}>
                    <SubHeading color="#636363">Didn’t receive a code?</SubHeading>
                    <Button block type="text"
                        className="bold-bold"
                        style={{ color: !resendOtpTimer ? '#1089FF' : '#28DF99', width: 200, marginLeft: -30 }}
                        onClick={() => {
                            sendOtpForRegistration(kycDetails?.contactPhone);
                        }}
                        loading={sendOtpLoader}
                        disabled={verifyOtpLoader || resendOtpTimer}
                    >
                        Resend OTP {timer == '' ? '' : `in ${timer}`}
                    </Button>
                </div>
            </Modal>
        </>
    )
}
    
