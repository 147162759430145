import '../styles.css';
import React, { Component } from 'react';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import 'antd/lib/select/style/index';
import 'antd/lib/typography/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import { Checkbox, Col, Row } from 'antd';
import { MdClose } from "react-icons/md";
import {connect} from 'react-redux';
import { Color } from 'services';
import { saveCurriculum } from 'actions/classRoom';
import { addCurriculumToCourse, addCurriculumToTest } from 'actions/course';
const { Title } = Typography;

class ResourceAddToSectionModal extends Component {
    constructor(){
      super();
      this.state = {
       curriculumName: '',
       selectedSubjects: [],
       buttonLoader: false
      }
    }
    componentDidMount(){
      const curriculum = this.props.curriculums.filter(item => item.id === this.props.selectedCurriculumId)[0];
      this.setState({curriculumName: curriculum.name});
      console.log("location",this.props.location)
    }

    handleSelectAll(e,value){
      // console.log("AA-value 1",e);

      const selectedSubjects = [...this.state.selectedSubjects];

      // if(e.target.checked){
      // this.setState({selectedSubjects:[]});        
      // }
      // else{
      const newSubIds = this?.props?.masterSubjects?.filter(sub => sub.type === value)?.map(item => item?.id);

      if(this.isSelectAll(value, selectedSubjects, this.props?.masterSubjects)){
        console.log("AA-value 1");

        this.setState({
          selectedSubjects: selectedSubjects.filter(sub => !newSubIds.includes(sub))
        });      
      }
      else{
        for(const id of newSubIds){
          if(!selectedSubjects?.includes(id))
            selectedSubjects.push(id);
        }
        this.setState({selectedSubjects});
      }

      // }
    }
    isSelectAll(value,selectedSubjects, masterSubjects){
      // const selectedSubjects = [...this.state.selectedSubjects];
      const newSubIds =  masterSubjects?.filter(sub => sub.type === value)?.map(item => item?.id);
      console.log("AA-new", selectedSubjects);
      for(const id of newSubIds){
        console.log("AA-false");
        if(!selectedSubjects?.includes(id))
        return false
      }
      console.log("AA-true");

      return true

    }

    handleSelectSubject(e, subjectId){
      const selectedSubjects = this.state.selectedSubjects;
      if(e.target.checked){
        selectedSubjects.push(subjectId)
      }else{
        const index = selectedSubjects.indexOf(subjectId);
        selectedSubjects.splice(index, 1)
      }
      this.setState({selectedSubjects});
    }

    handleOkClick(){
      this.setState({buttonLoader: true})
      const subjects = this.state.selectedSubjects;

      if(this.props.location === 'fromCourse')
        this.props.addCurriculumToCourse(subjects,() => this.setState({buttonLoader: false}), () => this.props.onClose(), this.props.history ) 
      else if(this.props.location === 'fromTestSeries')
        this.props.addCurriculumToTest(subjects,() => this.setState({buttonLoader: false}), () => this.props.onClose(), window.history.go(-1) ) 
      else
      this.props.saveCurriculum(subjects, () => this.props.onClose(), () => this.setState({buttonLoader: false}), window.history.go(-1))
    }


    render () {
      const { visible, toggleAddCurriculumModal, masterSubjects, selectedCurriculumId, 
        selectedMasterStd, curriculums, selectedSectionDetails, onClose, selectedSection } = this.props;

      const {curriculumName, selectedSubjects, buttonLoader } = this.state;
      console.log("AA-SelectedSubjects", selectedSubjects)

      return (
        <Modal
          visible={visible}
          className='modal-border-15'
          width={800}
          closable={true}
          footer={null}
          centered
          onCancel={() => onClose(false)}
        >
          <div>
            {this.props.location === 'fromCourse' || this.props.location === 'fromTestSeries' ?
              <div className='r-c-c-c'>
                <Title style={{ color: "#191919", margin: 0, fontFamily: "roboto" }} level={4}>Add to {(this.props.location === 'fromCourse') ? "course" : "test series"}</Title>
              </div>
              :
              <div className='r-c-c-c m-b-10'>
                <Title style={{ color: "#191919", margin: 0 }} level={4}>Add to Section</Title>
              </div>
            }
            {this.props.location === 'fromCourse' || this.props.location === 'fromTestSeries' ?
              <div className='r-c-c' style={{ color: "#636363", }}>Are you sure you want to add these subjects ?</div>
              :
              <center className='color-black m-b-30' style={{width:"100%"}}>Are you sure you want to add these subjects to <span className='bold-600'> {selectedSection.std}: {selectedSection.section} ?</span></center>
            }
            <div className='bold-600 text-sm color-black m-b-10 r-c-c m-t-10' style={{ fontFamily: "roboto" }}>{curriculumName} Syllabus: {selectedMasterStd.name} {selectedMasterStd.stream}</div>
            <div style={{ background: '#FAFAFA' }} className='p-15 radius-10'>
              <div className='bold-600 text-xmd dark-grey m-b-10'>Mandatory Subjects</div>
              <div>
                <Checkbox
                  onChange={(e) => { this.handleSelectAll(e, "Mandatory") }}
                  checked={this.isSelectAll("Mandatory", this.state.selectedSubjects, masterSubjects)}
                  style={{ marginBottom: 10 }}
                >
                  Select All
                </Checkbox>
              </div>
              <Row style={{ maxHeight: 150, overflowY: 'auto' }}>
                {masterSubjects.filter(sub => sub.type === "Mandatory").map((item, index) =>
                  <Col span={8} key={index.toString()}>
                    <div className='color-black m-b-10' >
                      <Checkbox onChange={(e) => this.handleSelectSubject(e, item.id)} checked={selectedSubjects?.includes(item.id)}>
                        <div className='color-black text-xmd'>{item.name}</div>
                      </Checkbox>
                    </div>
                  </Col>
                )}
              </Row>
              {masterSubjects.filter(sub => sub.type === "Optional").length ?
                <>
                  <div className='bold-600 text-xmd dark-grey m-b-10 m-t-20'>Elective Subjects</div>
                  <div>
                    <Checkbox
                      onChange={(e) => { this.handleSelectAll(e, "Optional") }}
                      checked={this.isSelectAll("Optional", this.state.selectedSubjects, masterSubjects)}
                    >
                      Select All
                    </Checkbox>
                  </div>

                  <Row style={{ maxHeight: 150, overflowY: 'auto' }}>

                    {masterSubjects.filter(sub => sub.type === "Optional").map((item, index) =>
                      <Col span={8} key={index.toString()}>
                        <div className='color-black m-b-10' >
                          <Checkbox onChange={(e) => this.handleSelectSubject(e, item.id)}>
                            <div className='color-black text-xmd'>{item.name}</div>
                          </Checkbox>
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
                : null}
            </div>

            <div className='r-c-sb m-t-30'>
              <Button className='blankGreyBtnWrap radius-100 dark-grey' style={{ width: '130px' }} onClick={() => onClose()}>
                No
              </Button>
              <Button type='primary' className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118' style={{ width: '120px' }}
                disabled={!selectedSubjects.length}
                loading={buttonLoader}
                onClick={() => this.handleOkClick()} >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      )
    }
}


const mapStateToProps = state => {
  const {
    showAddCurriculumModal, masterSubjects, selectedCurriculumId, selectedMasterStd, curriculums, 
    selectedSectionDetails, selectedSection
  } = state.classRoom;
  return {
    showAddCurriculumModal, masterSubjects, selectedCurriculumId, selectedMasterStd, curriculums, 
    selectedSectionDetails, selectedSection
  };
};

const mapDispatchToProps = dispatch => ({
  // toggleAddSectionModal: bool => dispatch(toggleAddSectionModal(bool)),
  saveCurriculum: (subjects, successCallback, buttonCallback, history) => dispatch(saveCurriculum(subjects, successCallback, buttonCallback, history)),
  addCurriculumToCourse: (subjects, callBack, successCallBack, history ) => dispatch(addCurriculumToCourse(subjects, callBack, successCallBack, history )),
  addCurriculumToTest: (subjects, callBack, successCallBack ) => dispatch(addCurriculumToTest(subjects, callBack, successCallBack ))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceAddToSectionModal);
