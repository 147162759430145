import {
    FETCHING_MESSAGES,
    UPDATE_MESSAGE,
    FETCHING_RECENT_USERS,
    FETCHING_FAQS,
    SET_DATE,
    SET_OVERALL_STATUS,
    SET_START_DATE,
    SET_END_DATE,
    IS_MONTH_OR_WEEK,
    STATISTICS_PLOT,
    SET_ROLE,
    SET_BATCH,
    SET_NAME,
    LOAD_ERLIER_MESSAGE,
    CONVERSATION_OPENED_CLOSED_USERS,
    CLOSE_CONVERSATION,
    CLOSE_OR_OPEN_CONVERSATION,
    SET_SPECIFIC_STUDENT_USERID,
    SET_CURRENT_PAGE_NUMBER,
    SET_COVID_COUNT,
    SET_SHOW_CARE_HOME,
    SET_AULAS_CARE_CHAT,
    SET_UNREAD_COUNT,
    SET_NEW_CHATES,
    SOCKET_CHAT_UPDATE
} from "actions/adminCare";
import moment from "moment";

import { GiftedChat } from "react-web-gifted-chat";

const initialState = {
    chats: [],
    recentUserList: [],
    faqs: {},
    date: moment().format('YYYY-MM-DD'),
    summaryData: {},
    startDate: moment().add(-6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    isMonthOrWeek: "month",
    totalProblemsWithCount: [],
    role: null,
    batch: null,
    name: '',
    currentPage: 1,
    openCloseConversationListWithChat: {},
    isClosed: false,
    isClosedOrOpened: true,
    specificStudentId: '',
    disableLoadEarlier: false,
    registrations: 0,
    Y_registrations: 0,
    delivered: 0,
    Y_delivered: 0,
    vaccinated: 0,
    Y_vaccinated: 0,
    chatWithAulas: false,
    unReadMsgCount: 0,
    new_Chats:[],
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOW_CARE_HOME:
            return {
                ...state,
                showAdminCare: action.bool
            }
            case SET_NEW_CHATES:
                return {
                    ...state,
                    new_Chats: action.payload
                }
        case SOCKET_CHAT_UPDATE:
            return {
                ...state,
                chats : action.payload
            }
        case SET_UNREAD_COUNT:
            return {
                ...state,
                unReadMsgCount:parseInt(action.payload?action.payload:'0')
            }
        case UPDATE_MESSAGE:
            return {
                ...state,
                chats: GiftedChat.append(state.chats, action.payload),
            };

        case FETCHING_MESSAGES:
            {
                let newChats = []
                if (action.page > 1) {
                    newChats = [...state.chats, ...action.payload.items];
                } else {
                    newChats = action.payload?.items
                }

                return {
                    ...state,
                    chats: newChats,
                    currentPage: parseInt(action.page),
                    disableLoadEarlier: action.payload.disableLoadEarlier
                }
            }


        case FETCHING_RECENT_USERS:
            return {
                ...state,
                recentUserList: action.payload.result,
                listOfStanderd: action.payload.standards
            }

        case FETCHING_FAQS:
            return {
                ...state,
                faqs: action.payload,
            }

        case SET_DATE:
                return {
                    ...state,
                    date: action.payload,
                }
        case SET_OVERALL_STATUS:
            return {
                ...state,
                summaryData: action.payload,
            }

        case SET_START_DATE:
            return {
                ...state,
                startDate: action.payload
            }

        case SET_END_DATE:
            return {
                ...state,
                endDate: action.payload
            }

        case IS_MONTH_OR_WEEK:
            return {
                ...state,
                isMonthOrWeek: action.payload
            }

        case STATISTICS_PLOT:
            return {
                ...state,
                totalProblemsWithCount: action.payload
            }

        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            }

        case SET_BATCH:
            return {
                ...state,
                batch: action.payload
            }

        case SET_NAME:
            return {
                ...state,
                name: action.payload
            }

        case LOAD_ERLIER_MESSAGE:
            return {
                ...state,
                // currentPage:action.page,
            }
        case SET_AULAS_CARE_CHAT:
            return {
                ...state,
                chatWithAulas: action.payload
            }

        case CONVERSATION_OPENED_CLOSED_USERS:
            {
                let newChats = []
                if (action.page > 1) {
                    newChats = [...state.chats, ...action.payload.items];
                } else {
                    newChats = action.payload?.items
                }

                return {
                    ...state,
                    openCloseConversationListWithChat: action.payload,
                    chats: newChats,
                    currentPage: parseInt(action.page),
                    disableLoadEarlier: action.payload.disableLoadEarlier
                }
            }
        case SET_CURRENT_PAGE_NUMBER:
            return {
                ...state,
                currentPage: parseInt(action.payload),
            }
        case CLOSE_CONVERSATION:
            return {
                ...state,
                isClosed: action.payload
            }

        case CLOSE_OR_OPEN_CONVERSATION:
            return {
                ...state,
                isClosedOrOpened: action.payload,
            }

        case SET_SPECIFIC_STUDENT_USERID:
            return {
                ...state,
                specificStudentId: action.payload,
            }
        case SET_COVID_COUNT:
            return {
                ...state,
                registrations: action.registrations,
                Y_registrations: action.Y_registrations,
                delivered: action.delivered,
                Y_delivered: action.Y_delivered,
                vaccinated: action.vaccinated,
                Y_vaccinated: action.Y_vaccinated
            }

        default:
            return state;

    }

}
export default reducer