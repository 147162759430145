import React from 'react';
import Icon from '@ant-design/icons';
import { AudioSvg, AudioOffSvg, VideoSvg, VideoOffSvg } from './svg';
import DropletSvg from './svg/drop';
import BookSvg from './svg/book';
import BarGraph from  './svg/bar-graph';
import UserPresent from './svg/user-present';
import UserMinus from './svg/user-minus';
import UserAbsent from './svg/user-absent';
import BookOpen from './svg/book-open';
import MoveSvg from './svg/move';

const AudioIcon = props => <Icon component={AudioSvg} {...props} />;
const VideoIcon = props => <Icon component={VideoSvg} {...props} />;

const AudioOffIcon = props => <Icon component={AudioOffSvg} {...props} />;
const VideoOffIcon = props => <Icon component={VideoOffSvg} {...props} />;
const DropletIcon = props => <Icon component={DropletSvg} {...props} />;
const BookIcon = props => <Icon component={BookSvg} {...props} />;
const BarGraphIcon = props => <Icon component={BarGraph} {...props} />;
const UserPresentIcon = props => <Icon component={UserPresent} {...props} />;
const UserMinusIcon = props => <Icon component={UserMinus} {...props} />;
const UserAbsentIcon = props => <Icon component={UserAbsent} {...props} />;
const BookOpenIcon = props => <Icon component={BookOpen} {...props} />;
const MoveIcon = props => <Icon component={MoveSvg} {...props} />;


export { AudioIcon, AudioOffIcon, VideoIcon, VideoOffIcon,DropletIcon,
  BookIcon, BarGraphIcon,UserPresentIcon, UserMinusIcon, UserAbsentIcon,BookOpenIcon, MoveIcon };
