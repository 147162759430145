import { Col, Row } from 'antd';
import PageHeader from 'components/pageHeader';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { instituteProfileDetails } from './instituteProfile';
import { ImageComponent, IndividualDetails } from './subComponents';

const SectionDetails = ({section, index}) => {
    return (
        <div key={index}>
            <div style={{fontSize: 16, fontWeight: 600,  marginTop: 30}}>{section.heading}</div>
            <Row>
                    {section.fields.map((item, index) => 
                    <Col md={{ span: 24 }} lg={{ span: 12 }}>
                        <IndividualDetails data={item} index={index} />
                    </Col>
                    )}
            </Row>
            <div style={{width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20}}/>
        </div>
    )
}

const ViewInstituteProfile = ({instituteDetails, user}) => {
    const history = useHistory()
    return (
        <div style={{height: "100%"}}>
            <PageHeader
                title="Your Institute"
                onBackPress={() => history.goBack()}
            />
            <ImageComponent src={instituteDetails?.logo || "defaultLogo.png"} editable={false}/>
            <div className='m-t-10' style={{padding:40}}>
                {instituteProfileDetails(instituteDetails, () => {}, user).map(
                    (section, index) => <SectionDetails section={section} index={index} />
                )}
            </div>
            
        </div>
    );
}

const mapStateToProps = (state) => { 
    const {user} = state.session;
    const {instituteDetails} = state.profileNew;
    return {
        user,
        instituteDetails
    }
}



export default connect(mapStateToProps)(ViewInstituteProfile);

