import React, { useEffect, useState } from 'react'
import BarsComponent from '../pages/barsComponent'
import { Select, Popover } from 'antd';
import { LeftOutlined, LockOutlined, RightOutlined } from '@ant-design/icons';
import image1 from '../../../Assets/care/ic-sort.svg';
import image from '../../../Assets/care/ic-calendar.svg';
import ToolTipB from './toolTipB';
import { connect } from 'react-redux';
import { adminFilterOfStudent, fetchTrafficData, setMonthOrWeek } from 'actions/digitalLibrary';
import image2 from '../../../Assets/care/no-subjects.svg'
import moment from 'moment';
import CalenderPopUp from './calenderPopUp';
import TrafficGraph from '../trafficGrpah';

const content = (
    <div className="popOverBp" style={{ width: "300px" }}>
        <CalenderPopUp digitalLib={true} />
    </div>
);

function setScale(array) {
    var max = 0;
    array.forEach(function (item) {
        if (max < parseInt(item)) {
            max = item;
        }
    })

    var var1 = parseInt(Math.ceil((max) / 5));
    for (let i = 0; i < 6; i++) {
        arrayAxis[5 - i] = (var1 * i);

    }

}

const arrayAxis = []

function TeacherSummary({ storeTrafficData,colors, cardSide, isMonthOrWeek, startDate, endDate, setMonthOrWeek, togglePageLoader,fetchTrafficData, adminFilterOfStudent, getAnalyticsData, classInfo, pageLoader, standardId, selectedStd }) {
    const [isDayOrWeek, setIsDayOrWeek] = useState('days')
    const [isImpresionOrEngaugement, setIsImpresionOrEngaugement] = useState('Impressions')
    const [isTrafficDate,setIsTrafficDate] =useState(moment().format('YYYY-MM-DD'))

    const { Option } = Select;

    function handleChange1(value) {
        togglePageLoader(true)
        setMonthOrWeek(value)
        adminFilterOfStudent(() => togglePageLoader(false))
    }
    function handleChange2(value) {
        setIsImpresionOrEngaugement(value);
    }

    function handleDayWeekSelector(value) {
        setIsDayOrWeek(value);
        fetchTrafficData(standardId?standardId:selectedStd?.std_id,value,isTrafficDate,()=>{})
    }
    const onClickArrow=(direction)=>{
        if(direction == 'left'){
           const date= moment(isTrafficDate)
           setIsTrafficDate(date.subtract(1,'days'))
           fetchTrafficData(standardId?standardId:selectedStd?.std_id,'hours',date,()=>{})
        }
        else{
            const date= moment(isTrafficDate)
            setIsTrafficDate(date.add(1,'days'))
            fetchTrafficData(standardId?standardId:selectedStd?.std_id,'hours',date,()=>{})
        }

    }

    const [scaleY, setScaleY] = useState([])
    const [scaleActive, setScaleActive] = useState([])

    useEffect(() => {
        adminFilterOfStudent()
    }, [startDate, endDate])

    useEffect(()=>{
        fetchTrafficData(standardId?standardId:selectedStd?.std_id,null,moment().format('YYYY-MM-DD'),null) 
    },[])
    useEffect(() => {
        var array = [];
        var count = 0;
        if(isMonthOrWeek == 'week'){
            Array.isArray(getAnalyticsData) && getAnalyticsData?.map((item) => {
                if (isImpresionOrEngaugement == 'Impressions') {
                    array[count++] = item.impressionCountStudent;
                } else {
                    array[count++] = item.engagementCountStudent;
                }
            })
        }
        else{
            getAnalyticsData?.monthlyImpressionsEngagements?.map((item) => {
                if (isImpresionOrEngaugement == 'Impressions') {
                    array[count++] = item.impressionCountStudent;
                } else {
                    array[count++] = item.engagementCountStudent;
                }
            })
        }

        
        setScale(array)
        setScaleY([...arrayAxis]);
    }, [isImpresionOrEngaugement, getAnalyticsData])

    useEffect(() => {
        var array1 = [];
        var count1 = 0;
        storeTrafficData?.map((item) => {
            array1[count1++] = item.userCount;
        })
        setScale(array1)
        setScaleActive([...arrayAxis]);
    }, [])

    return (
        <div className="teacherSummaryCardB" style={{ width: "95%" }}>
            {cardSide == 'right' ?
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "90%" }}>
                    <div style={{ display: "flex", msFlexDirection: "row", justifyContent: "space-between", width: "40%" }}>
                        <div>
                            <h1 className="adminSummary_h1" style={{ marginBottom: "0px", display: "flex" }}>Performance<span style={{ marginLeft: "4px", width: "15px" }}><ToolTipB content='right' /></span></h1>
                            {isMonthOrWeek == 'week' ?
                                <p className="adminSummary_p">{moment(startDate).format('DD-MM-YYYY') ? moment(startDate).format('DD-MM-YYYY') : moment().startOf('week').format('YYYY-MM-DD')} - {moment(endDate).format('DD-MM-YYYY') ? moment(endDate).format('DD-MM-YYYY') : moment().startOf('week').add(6, 'days').format('YYYY-MM-DD')}</p>
                                :
                                <p className="adminSummary_p">Jan-Dec {moment().year()} </p>
                            }
                        </div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start" }}>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                            <div style={{ width: "20px", height: "20px", backgroundColor: "#FF83D0", marginRight: "10px" }}></div><span>Students</span>
                        </div>
                        <div style={{ display: "flex", marginLeft: "3%", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
                            <div><img style={{ width: "50px", height: "15px" }} src={image1} alt="" /></div>
                            <div id="testHomeDropdown" style={{display:'flex'}}>
                                <Select
                                    className="inputSelecter"
                                    value={isMonthOrWeek}
                                    style={{ width: "100%", border: "none" }}
                                    onChange={handleChange1}
                                    bordered={false}
                                    removeIcon={true}
                                    dropdownMatchSelectWidth={true}
                                    getPopupContainer={() => document.getElementById("testHomeDropdown")}
                                >
                                    <Option value="week">Week</Option>
                                    <Option value="month">Months</Option>
                                </Select>
                                {isMonthOrWeek == 'week' ?
                                <Popover placement="bottomRight" dropdownClassName={"dropDownB"} content={content} trigger="click" style={{ width: '300px' }} getPopupContainer={() =>document.getElementById("testHomeDropdown")}>
                                    <img className="calenderIcon" style={{ width: "20px", height: "20px", marginRight: "2px",marginTop:'5px'  }} src={image} alt="" />
                                </Popover>
                                : null}
                            </div>
                            
                        </div>



                        <div style={{ marginLeft: "2%" }}>

                            <Select defaultValue="Impressions" style={{ width: 120 }} onChange={handleChange2}
                                getPopupContainer={() => document.getElementById("testHomeDropdown")}

                            >
                                <Option value="Impressions">Impressions</Option>
                                <Option value="Engagements">Engagements</Option>
                            </Select>

                        </div>
                    </div>
                </div> : <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <h1 className="adminSummary_h1" style={{ marginBottom: "0px", display: "flex" }}>Traffic<span style={{ marginLeft: "4px", width: "15px" }}><ToolTipB content="left" /></span></h1>
                    <div style={{ display: "flex", marginLeft: "3%", flexDirection: "row", width: "22%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
                            <div id="testHomeDropdown" style={{display:'flex'}}>
                                <Select
                                    className="inputSelecter"
                                    value={isDayOrWeek}
                                    style={{ width: "100%", border: "none" }}
                                    onChange={handleDayWeekSelector}
                                    bordered={false}
                                    removeIcon={true}
                                    dropdownMatchSelectWidth={true}
                                    getPopupContainer={() => document.getElementById("testHomeDropdown")}
                                >
                                    <Option value="days">Days</Option>
                                    <Option value="hours">Hours</Option>
                                </Select>
                            </div>
                            
                        </div>
                </div>}
            {/* {cardSide == 'left' && false ?
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "60%" }}>

                        <LeftOutlined />
                        <h3 style={{ marginBottom: "0px" }}>Friday</h3>
                        <RightOutlined />
                    </div>
                </div>
                : null} */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "300px" }}>
                {cardSide == 'right' && scaleY[4] ?
                    <div style={{ display: "flex", flexDirection: "column", width: "5%", height: "220px", justifyContent: "space-between" }}>
                        {scaleY.map((item) =>
                            <div className="daysOrMonths">{item}</div>
                        )}
                    </div>
                    :
                    <div>
                        {scaleActive[4] && cardSide == 'left' ?
                            <div style={{ display: "flex", flexDirection: "column", width: "5%", height: "220px", marginTop: '40px', justifyContent: "space-between" }}>
                                {scaleActive.map((item) =>
                                    <div className="daysOrMonths">{item}</div>
                                )}
                            </div> : null
                        }
                    </div>
                }

                {cardSide == "left" ?
                    <div className={scaleActive[4] ? "barGraphArea" : ''} style={{ width: "500%", height: "250px", marginTop: '40px' }} >
                        {isDayOrWeek == 'hours' ? 
                       <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                       <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "60%" }}>
   
                           <LeftOutlined onClick={()=>{onClickArrow('left')}} />
                           <h3 style={{ marginBottom: "0px" }}>{moment(isTrafficDate).format('dddd')}</h3>
                           
                           <RightOutlined onClick={()=>{onClickArrow('right')}}/>
                       </div>
                   </div>:null}
                        <div style={{ width: "93%", height: "210px" }}>
                            
                            <TrafficGraph storeTrafficData={storeTrafficData?.length != 0 ? storeTrafficData: []}/>
                            
                            {/* { cardSide == 'left' ?

                                
                                <div style={{ display: "flex", flexDirection: "row", width: "97%", height: "210px", justifyContent: "space-between" }}>
{console.log("AA-isWeek21",isDayOrWeek,scaleActive)}
                                    
                                    {isDayOrWeek == 'Days' ? storeTrafficData?.map((item) =>

                                        <div key={item.id} style={{ height: "100%", width: "100%", display: pageLoader ? 'none' : 'inline' }}>
                                       { console.log("AA-item",item)}
                                            
                                            <BarsComponent openproblemsNo={parseInt(item.count)}  dateOrMonth={item.day ? item?.day : item?.hour} teacherView={true} colors={colors} maxHeight={Math.max(...scaleActive)} />
                                        </div>

                                    ) :

                                        <div></div>

                                    }
                                </div> :
                                <div>
                                    <div style={{ height: "250px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <img style={{ width: "30%", height: "30%", marginBottom: "20px" }} src={image2} alt="" />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try some other date range.</p>
                                        </div>
                                    </div>
                                </div>} */}
                        </div>
                    </div>
                    : null}

                {cardSide == "right" ?
                    <div className={scaleY[4] ? "barGraphArea scroll-bar-universal" : ''} style={{ width: "500%", overflowY: 'hidden', height: "250px" }} >
                        {isMonthOrWeek == 'week' ? 
                        <div style={{ width: "100%", height: "210px" }}>
                            {scaleY[4] && cardSide == 'right' ?
                                <div style={{ display: "flex", flexDirection: "row", width: "97%", height: "210px", justifyContent: "space-between" }}>


                                    {Array.isArray(getAnalyticsData) && getAnalyticsData?.map((item) =>

                                        <div key={item.id} style={{ height: "100%", width: "100%", marginLeft: "5px", display: pageLoader ? 'none' : 'inline' }}>

                                            <BarsComponent openproblemsNo={isImpresionOrEngaugement == 'Impressions' ? item.impressionCountStudent : item.engagementCountStudent} closeproblemsNo={'1'} dateOrMonth={item.short_date ? item.short_date : item.month} teacherView={true} colors={colors} maxHeight={Math.max(...scaleY)} />
                                        </div>

                                    )}

                                </div> : <div>
                                    <div style={{ width: '100%', height: "250px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <img style={{ width: "30%", height: "30%", marginBottom: "20px" }} src={image2} alt="" />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try some other date range.</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>:





                        <div style={{ width: "100%", height: "210px" }}>
                            {scaleY[4] && cardSide == 'right' ?
                                <div style={{ display: "flex", flexDirection: "row", width: "97%", height: "210px", justifyContent: "space-between" }}>


                                    {getAnalyticsData?.monthlyImpressionsEngagements?.map((item) =>

                                        <div key={item.id} style={{ height: "100%", width: "100%", marginLeft: "5px", display: pageLoader ? 'none' : 'inline' }}>
                                            {console.log("AA-item",item)}
                                            <BarsComponent openproblemsNo={isImpresionOrEngaugement == 'Impressions' ? item.impressionCountStudent : item.engagementCountStudent} closeproblemsNo={'1'} dateOrMonth={item.short_date ? item.short_date : item.month} teacherView={true} colors={colors} maxHeight={Math.max(...scaleY)} />
                                        </div>

                                    )}

                                </div> : <div>
                                    <div style={{ width: '100%', height: "250px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <img style={{ width: "30%", height: "30%", marginBottom: "20px" }} src={image2} alt="" />
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try some other date range.</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>}
                    </div>
                    
                    : null}


            </div>



        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        getAnalyticsData,
        isMonthOrWeek,
        startDate,
        endDate,
        classInfo,
        storeTrafficData,
        selectedStd
    } = state.digitalLibrary;
    const { user } = state.session;
    return {
        user,
        getAnalyticsData,
        isMonthOrWeek,
        startDate,
        endDate,
        classInfo,
        storeTrafficData,
        selectedStd
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
    adminFilterOfStudent: (callback) => dispatch(adminFilterOfStudent(callback)),
    fetchTrafficData:(standardId,isDayOrWeek,date,callback)=> dispatch(fetchTrafficData(standardId,isDayOrWeek,date,callback)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(TeacherSummary);
