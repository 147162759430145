import { MinusCircleOutlined, PlusCircleOutlined, DragOutlined } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Notification } from 'services';

const ChapterItemType = 'CHAPTER';

const DraggableChapter = ({ chapterItem, chapterIndex, moveChapter, setAddChapter, addChapter, hasMarketingPrivilege }) => {
    const [, drop] = useDrop({
        accept: ChapterItemType,
        hover(item) {
            if (!item || item.index === chapterIndex) {
                return;
            }
            moveChapter(item.index, chapterIndex);
            item.index = chapterIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ChapterItemType,
        item: { chapterItem, index: chapterIndex },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={node => drag(drop(node))} style={{ opacity, marginBottom: 10 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className='m-t-5 m-r-10' style={{ fontSize: 14, color: "#191919", fontWeight: "bold", width: 30 }}>{chapterIndex + 1}.</div>
                <Input
                    style={{ width: "85%" }}
                    placeholder="What’s the name of the chapter?"
                    value={chapterItem.chapterName}
                    readOnly={hasMarketingPrivilege ? true : false}
                    onChange={(e) => {
                        const updatedAddChapter = [...addChapter];
                        updatedAddChapter[chapterIndex] = { chapterId: chapterItem?.chapterId ? chapterItem?.chapterId : null, chapterName: e.target.value }
                        setAddChapter(updatedAddChapter);
                    }}
                />

                {
                    hasMarketingPrivilege ?
                    <></>
                    :
                    (addChapter?.length > 1 ?
                        <DragOutlined style={{ fontSize: "18px", color: "#FFA931", cursor: "pointer", marginLeft: 10 }} />
                        : <></>)
                }

                

                {hasMarketingPrivilege ?
                    <></>
                    :
                    <MinusCircleOutlined
                        style={{ color: "red", cursor: "pointer", fontSize: 18, marginLeft: 10 }}
                        onClick={() => {
                            const updatedAddChapter = [...addChapter];
                            updatedAddChapter.splice(chapterIndex, 1);
                            setAddChapter(updatedAddChapter);
                        }}
                    />
                }

            </div>
        </div>
    );
};

function CreateSubjectsModal({
    getOnlineTestSeriesCourseDetails,
    isCreateSubjectModalVisible,
    setCreateSubjectModalVisible,
    addSubjects,
    togglePageLoader,
    getSpecificCourseData,
    isEditSubjectVisible,
    showEditChapters,
    setConfirmDeleteModalVisible,
    setIsFromSubjects,
    fromClassroom,
    getSubjStudentsData,
    courseTypeName,
    addSubjectFromOnlineTest,
    hasMarketingPrivilege
}) {
    const [addChapter, setAddChapter] = useState(isEditSubjectVisible ? [] : ['']);
    const [subjectName, setSubjectName] = useState("");
    const [addBtnLoader, setAddBtnLoader] = useState(false);
    const [subjectId, setSubjectId] = useState(null);

    const handleCancel = () => {
        setCreateSubjectModalVisible(false);
    };

    useEffect(() => {
        if (isEditSubjectVisible) {
            const chapters = showEditChapters?.courseChapter || showEditChapters?.chapters || showEditChapters?.subject?.testSeriesChapter || [];
            setSubjectName(showEditChapters?.name || showEditChapters?.subject?.name);
            setSubjectId(showEditChapters?.subject?.id || showEditChapters?.id );
            setAddChapter(chapters.map(chapter => ({ chapterId: chapter.id, chapterName: chapter.name })));
        } else {
            setSubjectName("");
            setSubjectId(null);
            setAddChapter([{ chapterId: null, chapterName: '' }]);
        }
    }, [isEditSubjectVisible, showEditChapters]);

    const addChaptersDisable = addChapter.some(chapter => !chapter.chapterName);

    const subjectUpdateDisable = showEditChapters?.courseChapter?.length === addChapter.length &&
        showEditChapters?.courseChapter.every((chapter, index) => chapter.name === addChapter[index]?.chapterName);

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isCreateSubjectModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={true}
        >
            <div style={{ fontSize: "22px", color: hasMarketingPrivilege ? "#ffffff" : "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>
            {isEditSubjectVisible ? "Edit" : "Create subject"}
            </div>
           
            <div style={{ padding: 20, height: 500, overflowY: "scroll" }}>
                <h6
                    style={{
                        fontSize: "14px",
                        color: "#636363",
                        fontWeight: "bold",
                    }}
                >
                    Subject name
                </h6>
                <Input
                    style={{ width: "100%" }}
                    onChange={(e) => setSubjectName(e.target.value)}
                    value={subjectName}
                    placeholder="What’s the name of the subject?"
                    readOnly={hasMarketingPrivilege}
                />
                <h6
                    style={{
                        fontSize: "14px",
                        color: "#636363",
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    Chapter(s)
                </h6>
                <DndProvider backend={HTML5Backend}>
                    {addChapter.map((item, index) => (
                        <DraggableChapter
                            key={index}
                            chapterItem={item}
                            chapterIndex={index}
                            moveChapter={(dragIndex, hoverIndex) => {
                                const updatedAddChapter = [...addChapter];
                                const draggedChapter = updatedAddChapter[dragIndex];
                                updatedAddChapter.splice(dragIndex, 1);
                                updatedAddChapter.splice(hoverIndex, 0, draggedChapter);
                                setAddChapter(updatedAddChapter);
                            }}
                            setAddChapter={setAddChapter}
                            addChapter={addChapter}
                            hasMarketingPrivilege={hasMarketingPrivilege}
                        />
                    ))}
                </DndProvider>
                {hasMarketingPrivilege
                    ?
                    <></>
                    :
                    <div className='m-t-20 r-c-c'>
                        <div
                            style={{
                                cursor: addChaptersDisable ? "not-allowed" : "pointer",
                                color: "#1089FF",
                                fontWeight: "600",
                                fontFamily: "roboto",
                                opacity: addChaptersDisable ? 0.5 : 1,
                                width: 120,
                            }}
                            onClick={() => { !addChaptersDisable ? setAddChapter([...addChapter, '']) : null }}>
                            <PlusCircleOutlined /><span className='m-l-5'>ADD CHAPTER</span>
                        </div>
                    </div>
                }

            </div>

            {hasMarketingPrivilege
                ?
                <></> :
                (isEditSubjectVisible ?
                    <div className="r-c-sa" style={{ width: "100%" }}>
                        <Button
                            style={{ color: "red", fontWeight: "bold", fontFamily: "roboto", border: "none" }}
                            onClick={() => {
                                setCreateSubjectModalVisible(false);
                                setIsFromSubjects(true);
                                setConfirmDeleteModalVisible(true)
                            }}
                        >
                            DELETE
                        </Button>
                        <Button
                            type="primary"
                            loading={addBtnLoader}
                            disabled={subjectUpdateDisable && showEditChapters?.name == subjectName}
                            onClick={() => {
                                if (addChapter.some((item, index) => addChapter.findIndex((otherItem) => otherItem.chapterName === item.chapterName) !== index)) {
                                    Notification.error("Error", 'Chapter name already exists');
                                }
                                else {

                                if (courseTypeName == 'onlineTestSeries') {
                                    addSubjectFromOnlineTest(
                                        subjectName,
                                        addChapter,
                                        subjectId,
                                        fromClassroom,
                                        () => {
                                            setAddBtnLoader(false)
                                        },
                                        () => {
                                            setCreateSubjectModalVisible(false);
                                            togglePageLoader(true);
                                            getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
                                        }
                                    )
                                } else {
                                    setAddBtnLoader(true)
                                    addSubjects(
                                        subjectName,
                                        addChapter,
                                        subjectId,
                                        fromClassroom,
                                        () => {
                                            setAddBtnLoader(false)
                                        },
                                        () => {
                                            setCreateSubjectModalVisible(false);
                                            togglePageLoader(true);
                                            if (fromClassroom) {
                                                getSubjStudentsData(() => togglePageLoader(false))
                                            } else {
                                                getSpecificCourseData(() => togglePageLoader(false))
                                            }
                                        }
                                    )
                                }
                            }
                        }}>
                        UPDATE
                    </Button>
                </div>
                :
                <div className='r-c-c'>
                    <Button
                        loading={addBtnLoader}
                        type="primary"
                        disabled={!subjectName || addChapter.some(chapter => !chapter.chapterName)}
                        onClick={() => {
                            if (addChapter.some((item, index) => addChapter.findIndex((otherItem) => otherItem.chapterName === item.chapterName) !== index)) {
                                Notification.error("Error", 'Chapter name already exists');
                            } else {
                                setAddBtnLoader(true);
                                const chaptersToAdd = addChapter.map(chapter => ({
                                    id: chapter.chapterId,
                                    name: chapter.chapterName
                                }));
                                if (courseTypeName === 'onlineTestSeries') {
                                    addSubjectFromOnlineTest(
                                        subjectName,
                                        chaptersToAdd,
                                        subjectId,
                                        fromClassroom,
                                        () => setAddBtnLoader(false),
                                        () => {
                                            setCreateSubjectModalVisible(false);
                                            togglePageLoader(true);
                                            getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
                                        }
                                    );
                                } else {
                                    addSubjects(
                                        subjectName,
                                        chaptersToAdd,
                                        subjectId,
                                        fromClassroom,
                                        () => setAddBtnLoader(false),
                                        () => {
                                            setCreateSubjectModalVisible(false);
                                            togglePageLoader(true);
                                            if (fromClassroom) {
                                                getSubjStudentsData(() => togglePageLoader(false))
                                            } else {
                                                getSpecificCourseData(() => togglePageLoader(false))
                                            }
                                        }
                                    );
                                }
                            }
                        }}
                    >
                        CREATE
                    </Button>
                </div>
)
            }
        </Modal>
    );
}

export default CreateSubjectsModal;