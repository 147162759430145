import React from "react";
import { Modal, Button } from "antd";
import "./modal.css";
import { useHistory } from "react-router";
import { connect } from "react-redux";
// import './StartTestModal.css'
import { setCurrentIndex, clearImageList } from "actions/studentTest";
import moment from "moment";

function StartTestModal({
  selectedTest,
  isModalVisible,
  handleOk,
  handleCancel,
  setCurrentIndex,
  timeLeft,
  clearImageList
}) {
  const history = useHistory();
  const handleStartTestClick = () => {
    clearImageList();
    setCurrentIndex(0);
    history.push("/student-test/questions");
  };
  const now = moment();
  const testStarted =
    moment
      .duration(moment(selectedTest.startTime, "HH:mm:ss").diff(now))
      .asSeconds() > 0;
  return (
    <Modal
      className="startTestModal"
      title=""
      centered
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="icon-cards">
        <div className="icon-card">
          <div className="icon-card-icon">
            <img src={require("Assets/ic-faq.svg").default}></img>
          </div>
          <div className="icon-card-text">
            <span className="icon-num-info">{selectedTest?.totalQuestions}</span>
            <span className="icon-text-info">Questions</span>
          </div>
        </div>
        <div className="icon-card">
          <div className="icon-card-icon">
            <img src={require("Assets/ic-call-us.svg").default}></img>
          </div>
          <div className="icon-card-text">
            {/* {testStarted ? (
              <span className="icon-num-info">
                {Math.round(selectedTest.duration)}
              </span>
            ) : (
              <span className="icon-num-info">
                {Math.round(timeLeft.inMinutes)}
              </span>
            )} */}
              <span className="icon-num-info">
                {/* {selectedTest?.duration!=undefined?(parseInt(moment(selectedTest?.duration, "hh:mm:ss").diff(moment(),'minute'))):null} */}
                {selectedTest?.duration!=undefined ? (parseInt(selectedTest?.duration / 60)) : 0}
              </span>
            <span className="icon-text-info">Minutes</span>
          </div>
        </div>
        <div className="icon-card">
          <div className="icon-card-icon">
            <img src={require("Assets/ic-chat.svg").default}></img>
          </div>
          <div className="icon-card-text">
            <span className="icon-num-info">
              {selectedTest?.totalPoints}
            </span>
            <span className="icon-text-info">Marks</span>
          </div>
        </div>
      </div>
      {/* <div className="container-answertype ct-xssm" style={{ padding: 10 }}>
        <p> Questions can be of three types: </p>
        <p>
          {" "}
          <b>1. Numeric: </b> The question will have a numeric answer. You can
          only enter a numeric value as the answer.
        </p>
        <p>
          {" "}
          <b>2. Subjective:</b> The question will have a descriptive answer. You
          can only upload a jpg/png format image of the solution. Please ensure
          that the quality of the image is good.
        </p>
        <p>
          {" "}
          <b>3. Objective: </b> The question will have multiple choices (maximum
          4) out of which you have to pick which one is correct.
        </p>
      </div> */}
      {/* <div className="hint-container">
                <img src={require('Assets/ic-attempted.svg').default}></img>
                <span>
                    Tap on options to select the correct answer
                </span>
            </div> */}
      <div className="test-info-container">
        <div className="subject-container m-t-20">
          <span>Subject(s)</span>
          <ul>
            {selectedTest?.subjectNames?.split(",").map((item, index) => (
              <li key={index}>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="chapter-container">
                    <span> Chapter(s)</span>
                    <ul>
                        <li><span>Whole Numbers</span></li>
                        <li><span>Knowing our numbers</span></li>
                        <li><span>Components of food</span></li>
                    </ul>
                </div> */}
      </div>
      <div className="introduction-container m-l-10">
        <span className="grey-font font-medium"> Instructions </span>
        <p className="font-medium">{selectedTest?.instructions}</p>
      </div>
      <div className=" m-l-10">
        <span className="grey-font font-medium"> Examiner </span>
        <p className="black-font font-medium">
          {" "}
          {selectedTest?.teacherName}
        </p>
      </div>

      <div className="button-container">
        <Button
          shape="round"
          type="primary"
          style={{ width: 120 }}
          onClick={() => handleStartTestClick()}
        >
          <div className="bold-600" style={{ fontSize: 12 }}>
            START TEST
          </div>
        </Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { selectedTest, timeLeft } = state.studentTest;
  return {
    selectedTest,
    timeLeft,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
  clearImageList: () => dispatch(clearImageList())
});

export default connect(mapStateToProps, mapDispatchToProps)(StartTestModal);
