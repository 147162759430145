import React, { useEffect, useState } from 'react'
import TopBar from './topBar'
import './allCourseDetailsStyles.css'
import { connect } from 'react-redux'
import { Api } from 'services';
import noImage from "../../Assets/aulasMarket/no-image.svg"
import { Popover, Rate, Typography, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
// import { Button } from 'components/Button';
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import { getAllCoursesData, getAllStudyMaterials, getCourseList, getVideoResourcesList, setResourceType } from 'actions/aulasMarket';
import RatingStars from './ratingStars';
import PageDataLoader from 'components/pageDataLoader';
import { getAllCoursesData, getAllStudyMaterials, getCartCount, getCheckOutItem, getCourseList, getVideoResourcesList, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, setResourceType, storeCourseIdAndType, storeSingleBuyNow } from 'actions/studentMarketPlace';
import NewFooter from './newFooter';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import HtmlParser from 'react-html-parser';

const defaultCourseImage = require('Assets/default_course-img.svg').default;

const additionalContent = [
    {
        title: "Syllabus"
    },
    {
        title: "Important dates"
    },
    {
        title: "Exam pattern"
    }
]
const AdditionalContent = ({ additionalContent, id }) => {
    return (
        <div className='additional-series-card' >

            <div className='additional-card-text' style={{ color: "#191919" }}>
                {additionalContent?.title}
            </div>

        </div>
    )
}

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
        if (c[i].nodeType === 1) return true;
    }
    return false;
}

const LiveCourseCards = ({ item, history, getCheckOutItem, user, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, getCartCount, storeCourseIdAndType }) => {
    console.log("itemLiccss", item?.resourceType);
    const [shareUrl, setShareUrl] = useState(null);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false)
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)


    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.inSaved, item?.owned])

    const handleSaveForLater = (id, itemType) => {
        setIsSavedClicked(!isSavedClicked)
        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {
        if (user?.accessToken) {
            postAddCartItem(id, itemType, (res) => {
                setAddToCartClicked(true)
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        } else {
            history.push('/login');
        }
    };

    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
                history.push('/courses-list')
            });
        } else {
            history.push('/login');
        }
    };

    return (

        <div className='live-course-cards ' style={{ height: "25vh" }} >
            <div className='live-course-not-hover-card '

            >
                <div style={{ width: "100%", height: "140px", position: "relative" }}>
                    <img className='main-image' src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{
                        borderRadius: 8, width: "100%", height: "140px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    }} />
                    {item?.feeType == "free" ? <img src={require("../../Assets/userMarketPlace/freeTag.svg").default} style={{ position: "absolute", left: 0, top: -5, height: 45, width: 45 }} /> : <></>}
                    {item?.mode ? <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : null}
                    {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <></>}
                </div>
                <Typography.Text className='course-name-div' ellipsis={{ tooltip: true }} style={{ color: "#191919", fontWeight: "bold", fontSize: 16, width: "100%" }}>{item?.courseName}</Typography.Text>
                {/* <div className='mobile-disable' style={{ color: "#636363" }}>20 hours videos | 6 quizzes | 14 notes</div> */}
                <div style={{ display: "flex" }}>
                    {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                    <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                </div>
            </div>
            {/* <div className='live-hover-card'> */}
            <div className='live-hover-card zoom123'
                onClick={() => {
                    storeCourseIdAndType("id", item?.id);
                    storeCourseIdAndType("type", item?.resourceType)
                    if (item?.resourceType == 'course') {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            }
                        })
                    } else {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            },
                        })

                    }
                }} >
                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 14, width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}>{item?.resourceType == "studyMaterial" ? item?.name : item?.courseName}</div>
                <div style={{ display: "flex" }}>
                    {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                    <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                </div>
                <div className={`m-t-5 ${item?.bestseller == 1 ? 'r-c-sb' : ""}`}>

                    {item?.bestseller == 1 ? <div style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12 }}>Bestseller</div> : <></>}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        {user?.role?.includes("Student") ? <>
                            {item?.owned == 1 ? <></> : <div onClick={(e) => {
                                e.stopPropagation();
                                if (user?.accessToken) {
                                    handleSaveForLater(item?.id, item?.resourceType)
                                }
                                else {
                                    history.push("/login")
                                }
                            }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || isSavedClicked ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div>}
                        </> : <></>}

                        <img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ marginLeft: 20, height: 20, width: 20 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                            }} />
                    </div>
                </div>
                <div className='ellipsis-text m-t-10' style={{ color: "#191919", fontSize: 12 }}>{IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}</div>
                {item?.mode ? <div className='m-t-10' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", fontWeight: "bold", width: "fit-content" }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
                {item?.mode ? <div className='m-t-5' style={{ fontSize: 11, color: "#AEAEAE" }}>{item?.mode == "hybrid" ? "Classes to be held in physical classroom and also over the internet." : item?.mode == "online" ? "Classes to be conducted over the internet." : "Classes to be held in physical classroom."}</div> : <></>}
                {user?.role?.includes("Student") ? <>
                    <center>
                        {item?.owned == 1 || enroll[item?.id] ?
                            <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "fit-content", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, marginTop: 5, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}>VIEW COURSE</Button>
                            :
                            <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                {item?.resourceType == "course" ? null :
                                    <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (!user?.accessToken) {
                                                history.push("/login")
                                            } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                history.push("/cart")
                                            } else {
                                                setAddCartLoader(true)
                                                handleAddToCart(item?.id, item?.resourceType)
                                                getCartCount()
                                            }
                                        }}>
                                        {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}
                                    </Button>
                                }
                                <Button loading={enrollLoader} type="primary" style={{ fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, width: item?.resourceType == "course" ? "100%" : "47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (user?.accessToken) {
                                            if (item?.feeType == "free") {
                                                setEnrollLoader(true)
                                                handleEnroll(item?.id, item?.resourceType)
                                            } else {
                                                history.push({
                                                    pathname: '/user-checkout',
                                                    state: { itemId: item?.id, itemType: item?.resourceType }
                                                });


                                            }
                                        } else {
                                            history.push("/login")
                                        }
                                    }}
                                >
                                    {enroll[item?.id] ? "ENROLLED" : item?.feeType == "free" || item?.resourceType == "course" ? "ENROLL NOW" : "BUY NOW"}
                                </Button>
                            </div>
                        }
                    </center>
                </> : <></>}
            </div>
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
        </div>


        // </div>

    );
};
// const LiveCourseCards = ({ item, history }) => {
//     console.log("AA-item", item);
//     return (
//         <div className='LiveCourseCards' style={{ cursor: "pointer" }}>
//             <div
//                 onClick={() => {
//                     history.push({
//                         pathname: '/resource-details',
//                         state: {
//                             id: item?.id,
//                             resourceType: item?.resourceType
//                         }
//                     })
//                 }}>
//                 <div style={{ width: "100%", height: "140px", position: "relative" }}>
//                     <img src={`${Api.dlS3Url}${item?.image}`} style={{ borderRadius: 8, width: "100%", height: "140px",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} />
//                     {item?.feeType == "free" ? <img src={require("../../Assets/userMarketPlace/freeTag.svg").default} style={{ position: "absolute", left: 0, top: -5, height: 45, width: 45 }} /> : <></>}
//                     <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div>
//                     {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {parseFloat(item?.amount)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)}</span> : <></>}</div> : <></>}
//                 </div>
//                 <Typography.Text className='course-name-div' ellipsis={{ tooltip: true }} style={{ color: "#191919", fontWeight: "bold", fontSize: 16, width: "100%" }}>{item?.courseName}</Typography.Text>
//                 {/* <div className='mobile-disable' style={{ color: "#636363" }}>20 hours videos | 6 quizzes | 14 notes</div> */}
//                 <div style={{ display: "flex" }}>
//                     {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
//                     <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
//                 </div>
//             </div>

//         </div>
//     );
// };
// yyyy

const StudyMaterialsCards = ({ item, history, getCheckOutItem, user, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, getCartCount, courseType, storeCourseIdAndType }) => {
    const [shareUrl, setShareUrl] = useState(null);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false)
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)


    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.inSaved, item?.owned])

    const handleSaveForLater = (id, itemType) => {
        setIsSavedClicked(!isSavedClicked)
        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {
        if (user?.accessToken) {
            postAddCartItem(id, itemType, (res) => {
                setAddToCartClicked(true)
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        } else {
            history.push('/login');
        }
    };

    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false);
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
                history.push('/courses-list');
            });
        } else {
            history.push('/login');
        }
    };
    { console.log('item', item) }
    return (
        <div >
            <div className='study-material-parent-card zoom123 '>
                <div className='study-course-not-hover-card '>
                    <div className='study-material-card'
                        onClick={() => {
                            storeCourseIdAndType("id", item?.id);
                            storeCourseIdAndType("type", item?.resourceType)
                            if (item?.resourceType == 'course') {
                                history.push({
                                    pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                    state: {
                                        id: item?.id,
                                        resourceType: item?.resourceType,
                                        selectedSuggestion: location?.state?.selectedSuggestion
                                    }
                                })
                            } else {
                                history.push({
                                    pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                                    state: {
                                        id: item?.id,
                                        resourceType: item?.resourceType,
                                        selectedSuggestion: location?.state?.selectedSuggestion
                                    }
                                })

                            }

                        }}>
                        {item?.feeType == 'free' ?
                            <div className='study-material-free' >
                                <div style={{ textAlign: "center", fontSize: "10px", color: '#FFFFFF', fontWeight: "700px", marginTop: 2 }}>FREE</div></div> : <div style={{ width: '60px', height: 20 }} ></div>}
                        <div style={{ display: "flex", marginLeft: "20px" }}>
                            <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />

                            {courseType == 'testSeries' ?
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>  {item?.tCount} {item?.tCount <= 1 ? "Test" : "Tests"}
                                </div>
                                :
                                <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px" }}>  {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                    {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                            }

                        </div>
                        <div style={{ marginRight: "10px", marginTop: "-10px" }}>
                            {item?.feeType == 'free' ? (<></>) :
                                <div className='study-material-amount'>
                                    <div style={{ textAlign: "center", fontSize: "10px", color: '#594099', fontWeight: "700px", marginTop: 2 }}>₹ {item?.amount}</div>
                                </div>}
                        </div>



                    </div>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200, marginTop: "5px" }}>{item?.name}</Typography.Text>
                    {courseType = 'testSeries'
                        ?
                        <div style={{ display: "flex" }}>
                            {item?.ratingValue > 0 ?
                                <RatingStars star={item?.ratingValue} /> : null}
                            {item?.ratingCount > 0 ?
                                <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.ratingCount})</h> : null}
                        </div>
                        :
                        <div style={{ display: "flex" }}>
                            {item?.rating > 0 ?
                                <RatingStars star={item?.rating} /> : null}
                            {item?.ratingCount > 0 ?
                                <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.ratingCount})</h> : null}
                        </div>
                    }

                </div>
                <div className='study-course-hover-card' onClick={() => {
                    storeCourseIdAndType("id", item?.id);
                    storeCourseIdAndType("type", item?.resourceType)
                    if (item?.resourceType == 'course') {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            }
                        })
                    } else {
                        history.push({
                            pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                            state: {
                                id: item?.id,
                                resourceType: item?.resourceType,
                                selectedSuggestion: location?.state?.selectedSuggestion
                            },
                        })

                    }

                }} >
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 14, width: "100%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}>{item?.resourceType == "studyMaterial" ? item?.name : item?.resourceType == "testSeries" ? item?.name : item?.courseName}</div>
                    {courseType == "testSeries"
                        ?
                        <div style={{ display: "flex" }}>
                            {(item?.ratingValue && item?.ratingValue > 0) ? <RatingStars star={item?.ratingValue} /> : <></>}
                            <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                        </div>
                        :
                        <div style={{ display: "flex" }}>
                            {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                            <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
                        </div>
                    }

                    <div className={`m-t-5 ${item?.bestseller == 1 ? 'r-c-sb' : ""}`}>
                        {item?.bestseller == 1 ? <div style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12 }}>Bestseller</div> : <></>}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {user?.role?.includes("Student") ? <>
                                {item?.owned == 1 ? <></> : <div onClick={(e) => {
                                    e.stopPropagation();
                                    if (user?.accessToken) {
                                        handleSaveForLater(item?.id, item?.resourceType)
                                    }
                                    else {
                                        history.push("/login")
                                    }
                                }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || isSavedClicked ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div>}
                            </> : <></>}

                            <img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ marginLeft: 20, height: 20, width: 20 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                                }} />
                        </div>
                    </div>
                    <div className='ellipsis-text m-t-10' style={{ color: "#191919", fontSize: 12 }}>{IsJsonString(item?.brief) ? !IsJsonString(JSON.parse(item?.brief)?.mobile) && isHTML(JSON.parse(item?.brief)?.mobile) ? HtmlParser(JSON.parse(item?.brief)?.mobile) : HtmlParser(JSON.parse(item?.brief)?.mobile) : item?.brief}</div>
                    {item?.mode ? <div className='m-t-10' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", fontWeight: "bold", width: "fit-content" }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
                    {item?.mode ? <div className='m-t-5' style={{ fontSize: 11, color: "#AEAEAE" }}>{item?.mode == "hybrid" ? "Classes to be held in physical classroom and also over the internet." : item?.mode == "online" ? "Classes to be conducted over the internet." : "Classes to be held in physical classroom."}</div> : <></>}
                    {user?.role?.includes("Student") ? <>
                        <center>
                            {item?.owned == 1 || enroll[item?.id] ?
                                <Button style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "fit-content", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, marginTop: 5, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}>VIEW COURSE</Button>
                                :
                                <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                    <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (!user?.accessToken) {
                                                history.push("/login")
                                            } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                history.push("/cart")
                                            } else {
                                                setAddCartLoader(true)
                                                handleAddToCart(item?.id, item?.resourceType)
                                                getCartCount()
                                            }
                                        }}>
                                        {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}
                                    </Button>
                                    <Button loading={enrollLoader} type="primary" style={{ fontSize: `clamp(0.7em, 1.5vmin, 0.7500em)`, width: "47%", padding: "5px 10px", borderRadius: 18, fontWeight: 600 }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (user?.accessToken) {
                                                if (item?.feeType == "free") {
                                                    setEnrollLoader(true)
                                                    handleEnroll(item?.id, item?.resourceType)
                                                } else {
                                                    history.push({
                                                        pathname: '/user-checkout',
                                                        state: { itemId: item?.id, itemType: item?.resourceType }
                                                    });


                                                }
                                            } else {
                                                history.push("/login")
                                            }
                                        }}
                                    >
                                        {enroll[item?.id] ? "ENROLLED" : item?.feeType == "free" ? "ENROLL NOW" : "BUY NOW"}
                                    </Button>
                                </div>
                            }
                        </center>
                    </> : <></>}

                </div>


            </div>
        </div>
    )
}


const TestSeriesCard = ({ item, history, storeCourseIdAndType }) => {
    return (
        <div >
            <div className='online-test-series-card' style={{ cursor: "pointer" }}
                onClick={() => {
                    storeCourseIdAndType("id", item?.id);
                    storeCourseIdAndType("type", item?.resourceType)
                    history.push({
                        pathname: `/resource-details?id=${item?.id}&type=${item?.resourceType}`,
                        state: {
                            id: item?.id,
                            resourceType: item?.resourceType
                        }
                    })
                }}>
                <div>
                    {item?.feeType == 'free' ?
                        <div className='study-material-free'>FREE</div> :
                        <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: "#fff", padding: "5px 20px", fontWeight: "bold", borderRadius: 4, border: " 1px solid #fff" }}>free</div>}
                </div>
                <div style={{ display: "flex", alignItems: "center", margin: "-16px 0px 0px 10px" }}>
                    <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />
                    <div style={{ color: "#594099", fontSize: 16, marginLeft: 15 }}>{item?.tCount} Tests</div>
                </div>
                {item?.feeType == 'free' ? (<></>) :
                    <div className='study-material-amount' >₹ {item?.amount}</div>}

            </div>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200, marginTop: "5px" }}>{item?.name}</Typography.Text>
            <div style={{ display: "flex" }}>
                {item?.rating > 0 ?
                    <RatingStars star={item?.rating} /> : null}
                {item?.totalReviews > 0 ?
                    <h style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600, }}>({item?.totalReviews})</h> : null}
            </div>
        </div>
    )
}


const AllCoursesDetails = ({ allCoursesData, parentLink, getCourseList, exploreCourseData, setResourceType, getVideoResourcesList, getAllStudyMaterials, getAllCoursesData, user, getCheckOutItem, postAddSaveItem, postAddCartItem, postCourseEnrollNow, removeSaveItem, getCartCount,storeCourseIdAndType, isDownloadBarVisible ,  showContactBar}) => {
    const history = useHistory()
    const location = useLocation()
    const [pageDataLoader, togglePageLoader] = useState(false)
    let url = new URL(window.location.href);
    console.log("url", url);
    let courseId = url.searchParams.get("id");
    console.log("url1", courseId);
    let currentURL = window.location.href;
    let id = currentURL?.split('=')[1]?.split('&')[0];
    console.log("categoryId", id)
    useEffect(() => {
        if (id == '4') {
            togglePageLoader(true),
            getAllCoursesData(user?.accessToken, '', 4, () => { togglePageLoader(false) })
        }else if(id == '6' ){
            togglePageLoader(true),
            getAllCoursesData(user?.accessToken, '', 6, () => { togglePageLoader(false) })
        }
        else {
            togglePageLoader(true),
            getAllCoursesData(user?.accessToken, '', '', () => { togglePageLoader(false) })
        if (location?.state?.type || location?.state?.fromSearch) {
            togglePageLoader(true)
            getAllCoursesData(user?.accessToken, location?.state?.type, location?.state?.categoryId, () => { togglePageLoader(false) }, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null)
        } else {
            togglePageLoader(true)
            getAllCoursesData(user?.accessToken, location?.state?.type, location?.state?.categoryId, () => { togglePageLoader(false) }, location?.state?.selectedSuggestion ? location?.state?.selectedSuggestion : null)
        }}
        
    }, [location?.state?.categoryId, location?.state?.selectedSuggestion])
    const handleExploreMore = (value) => {
        history.push({
            pathname: '/all-courses',
            state: {
                categoryId: location?.state?.categoryId,
                type: location?.state?.type,
                value: 'live'
            }
        })
    }
    const handleExploreMoreVideo = (value) => {
        history.push({
            pathname: '/all-courses',
            state: {
                categoryId: location?.state?.categoryId,
                type: location?.state?.type,
                value: 'recorded'
            }
        })
    }

    const [isHomeLinkHovered, setHomeLinkHovered] = useState(false);

    const handleHomeLinkHover = () => {
        setHomeLinkHovered(true);
    };

    const handleHomeLinkLeave = () => {
        setHomeLinkHovered(false);
    };

    const live = (
        <div onClick={(e) => { e.stopPropagation() }}>
            <div style={{ width: "200px" }}>User who enrolls to any live course can attend classes in real time and can also interact with the instructor. Live courses can be held in various modes such as online, offline as well as hybrid</div>
        </div>
    );
    const video = (
        <div onClick={(e) => { e.stopPropagation() }}>
            <div style={{ width: "300px" }}>User who enrolls to this course will be provided with a series of series of recorded videos which they can refer to at any time of their convenience. This course comes with lecture notes and quizzes for the self evaluation. There is an option to clarify their doubts in the dedicated Q&A section</div>
        </div>
    )
    const test = (
        <div onClick={(e) => { e.stopPropagation() }}>
            <div style={{ width: "200px" }}> User can enroll to any online test series and they will have access to a series of mock tests that they can take for self evaluation of their preparations of various competitive exams.</div>
        </div>

    )
    const study = (
        <div onClick={(e) => { e.stopPropagation() }}>
            <div style={{ width: "200px" }}>User can purchase various study materials that can help them in their preparations for various exam.</div>
        </div>
    )
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const CalculateMarginTop = () => {
        if (isDownloadBarVisible && showContactBar) {
            return 160
        } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
            return 110
        } else if (!isDownloadBarVisible && !showContactBar) {
            return 60
        }
    }
    return (
        <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div >
                <TopBar selectedSuggestionTemp={location?.state?.selectedSuggestion} isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked} />
            </div>
            <div className='bg-color'>
                {location?.state?.fromSearch && allCoursesData?.liveCourses?.length == 0 && allCoursesData?.videoResources?.length == 0 && allCoursesData?.testSeries?.length == 0 && allCoursesData?.studyMaterials?.length == 0 ?
                    <div>
                        <div className='p-20 m-l-40 font-bold text-md'>Sorry, we couldn't find any results for "{location?.state?.selectedSuggestion}"</div>
                        <div className='p-20 m-l-40 font-bold text-sm' style={{ color: "#191919" }}>
                            Suggestions:
                            <div className='m-t-20 '>
                                <li> Make sure that all words are spelled correctly.</li>
                                <li>Try different keywords.</li>
                                <li>Try more general keywords.</li>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='popular-breadcrumbs m-t-10'
                        onMouseEnter={handleHomeLinkHover} onMouseLeave={handleHomeLinkLeave}>
                        {location?.state?.categoryId == undefined ?
                            <div >
                                <Link to="/" style={{ color: isHomeLinkHovered ? '#000' : '#AEAEAE', }}>Home</Link>/<span><h>{location?.state?.fromSearch ? "Search Result" : "Popular Courses"}</h></span></div>
                            :
                            (<>
                                <Link to="/" style={{ color: isHomeLinkHovered ? '#000' : '#AEAEAE' }}>Home</Link>{" "}/ <span style={{ color: "#AEAEAE", fontFamily: "Roboto" }}>{parentLink?.parentName}</span> <span style={{ color: "#191919", fontFamily: "Roboto", fontWeight: 400 }}>{parentLink?.childName ? '/' : null}{parentLink?.childName}</span>

                            </>)
                        }
                    </div>
                }
                {allCoursesData?.liveCourses?.length != 0 ?
                    (<>
                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20, marginTop: "20px" }}>Live Courses {" "}({allCoursesData?.courseCount}) <h className='m-l-10' style={{ color: "#594099" }}>
                            <Popover content={live}>
                                <InfoCircleOutlined />
                            </Popover>

                        </h></div>
                        <div className='m-t-30 course-card' >
                            {allCoursesData?.liveCourses?.map((item) => {
                                return (
                                    <LiveCourseCards item={item} history={history} getCheckOutItem={getCheckOutItem} user={user} postAddSaveItem={postAddSaveItem} postAddCartItem={postAddCartItem} postCourseEnrollNow={postCourseEnrollNow} removeSaveItem={removeSaveItem} getCartCount={getCartCount} storeCourseIdAndType={storeCourseIdAndType}/>
                                )
                            })}
                        </div>
                        {allCoursesData?.liveCourses?.length >= 8 ?
                            <div className='r-c-c m-t-20'>
                                <Button className='font-bold' style={{ color: "#594099", borderRadius: "100px", borderColor: "1px solid #594099" }} onClick={() => { handleExploreMore('live'), setResourceType('live') }}>EXPLORE MORE</Button>
                            </div> : null}</>) : (<></>)}
                {allCoursesData?.videoResources?.length != 0 ?
                    (<>
                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20, marginTop: "20px" }} >Video Courses ({allCoursesData?.videoResourceCount}) <h className='m-l-10' style={{ color: "#594099" }}>
                            <Popover content={video}>
                                <InfoCircleOutlined />
                            </Popover>
                        </h></div>
                        <div className='m-t-30 course-card' >
                            {allCoursesData?.videoResources?.map((item) => {
                                return (
                                    <LiveCourseCards item={item} history={history} getCheckOutItem={getCheckOutItem} user={user} postAddSaveItem={postAddSaveItem} postAddCartItem={postAddCartItem} postCourseEnrollNow={postCourseEnrollNow} removeSaveItem={removeSaveItem} getCartCount={getCartCount} storeCourseIdAndType={storeCourseIdAndType}/>
                                )
                            })}
                        </div>
                        {allCoursesData?.videoResources?.length >= 8 ?
                            <div className='r-c-c m-t-20'>
                                <Button className='font-bold' style={{ color: "#594099", borderRadius: "100px", borderColor: "1px solid #594099" }} onClick={() => { handleExploreMoreVideo('recorded'), setResourceType('recorded') }}>EXPLORE MORE</Button>
                            </div> : null}
                    </>) : <></>
                }
                {
                    allCoursesData?.testSeries?.length != 0 ? (
                        <>
                            <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20, marginTop: "20px" }}>Online Test Series ({allCoursesData?.testSeriesCount})<h className='m-l-10' style={{ color: "#594099" }}>
                                <Popover content={test}>

                                    <InfoCircleOutlined />
                                </Popover>

                            </h></div>

                            <div className='m-t-20 course-card' >
                                {allCoursesData?.testSeries?.map((item) => {
                                    return (
                                        // <TestSeriesCard item={item} history={history} storeCourseIdAndType={storeCourseIdAndType}/>
                                        <StudyMaterialsCards item={item} history={history} getCheckOutItem={getCheckOutItem} user={user} postAddSaveItem={postAddSaveItem} postAddCartItem={postAddCartItem} postCourseEnrollNow={postCourseEnrollNow} removeSaveItem={removeSaveItem} courseType='testSeries' storeCourseIdAndType={storeCourseIdAndType} getCartCount={getCartCount}/>
                                    )
                                })}

                            </div>
                            {allCoursesData?.testSeries?.length >= 8 ?
                                <div className='r-c-c m-t-20'>
                                    <Button className='font-bold' style={{ color: "#594099", borderRadius: "100px", borderColor: "1px solid #594099" }} onClick={() => {
                                        setResourceType('test')
                                        history.push({
                                            pathname: '/all-courses',
                                            state: {
                                                categoryId: location?.state?.categoryId,
                                                type: location?.state?.type,
                                                value: 'test'
                                            }
                                        })
                                    }}>EXPLORE MORE</Button>
                                </div> : null}
                        </>
                    )
                        : <></>
                }
                {
                    allCoursesData?.studyMaterials?.length != 0 ? (
                        <>
                            <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20, marginTop: "20px" }}>Study Materials ({allCoursesData?.studyMaterialCount})<h className='m-l-10' style={{ color: "#594099" }}>
                                <Popover content={study}>

                                    <InfoCircleOutlined />
                                </Popover>

                            </h></div>
                            <div className='m-t-20 course-card' >
                                {allCoursesData?.studyMaterials?.map((item) => {
                                    return (
                                        <StudyMaterialsCards item={item} history={history} getCheckOutItem={getCheckOutItem} user={user} postAddSaveItem={postAddSaveItem} postAddCartItem={postAddCartItem} postCourseEnrollNow={postCourseEnrollNow} removeSaveItem={removeSaveItem} getCartCount={getCartCount} courseType='studyMaterial' storeCourseIdAndType={storeCourseIdAndType}/>
                                    )
                                })}

                            </div>
                            {allCoursesData?.studyMaterials?.length >= 8 ?
                                <div className='r-c-c m-t-20'>
                                    <Button className='font-bold' style={{ color: "#594099", borderRadius: "100px", borderColor: "1px solid #594099" }} onClick={() => {
                                        setResourceType('study')
                                        history.push({
                                            pathname: '/all-courses',
                                            state: {
                                                categoryId: location?.state?.categoryId,
                                                type: location?.state?.type,
                                                value: 'study'
                                            }
                                        })
                                    }}>EXPLORE MORE</Button>
                                </div> : null}
                        </>
                    )
                        : <></>
                }
                {/* <div className='headings-font' >Additional Content<h className='m-l-10' style={{ color: "#594099" }}><InfoCircleOutlined /></h></div>

                <div className="m-t-20" style={{ display: 'flex', gap: 55 }}>
                    {additionalContent.map((item, id) => (
                        <AdditionalContent additionalContent={item} key={id} />
                    )
                    )}
                </div> */}
            </div >
            <NewFooter/>
            <PageDataLoader visible={pageDataLoader} />
        </div >
    )
}


const mapStateToProps = (state) => {
    const { allCoursesData, exploreCourseData, parentLink, isDownloadBarVisible, showContactBar } = state.studentMarketPlace
    const { user } = state.session
    return {
        allCoursesData, exploreCourseData, parentLink, user, isDownloadBarVisible, showContactBar
    }
};




const mapDispatchToProps = (dispatch) => ({
    setResourceType: (val) => dispatch(setResourceType(val)),
    getCourseList: (filter, type, categoryId, totalPage, callback) => dispatch(getCourseList(filter, type, categoryId, totalPage, callback)),
    getVideoResourcesList: (filter, type, categoryId, totalPage, callback) => dispatch(getVideoResourcesList(filter, type, categoryId, totalPage, callback)),
    getAllStudyMaterials: (filtersData, type, categoryId, callback) => dispatch(getAllStudyMaterials(filtersData, type, categoryId, callback)),
    getAllCoursesData: (token, fromHomePage, cId, callBack, search) => dispatch(getAllCoursesData(token, fromHomePage, cId, callBack, search)),
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCartCount: () => dispatch(getCartCount()),
    storeCourseIdAndType:(key,val) => dispatch(storeCourseIdAndType(key,val))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllCoursesDetails)