import moment from "moment";
import { Api } from "services";
import {
  UPDATE_DASHBOARD_DATA,
  UPDATE_STUDENT_TEST_FILTER,
  SET_SELECTED_SUB,
  UPDATE_STUDENT_TEST_COUNT,
  SET_SELECTED_TEST,
  UPDATE_TEST_RESULT,
  SET_TEST_DURATION,
  SET_CURRENT_STUDENT_QUESTION,
  UPDATE_PALATE_DATA,
  UPDATE_COUNT_DATA,
  SET_CURRENT_INDEX,
  UPDATE_TEST_SUBS_LIST,
  SET_SELECTED_SUB_INDEX,
  UPDATE_SOLUTION_COUNT_DATA,
  UPDATE_Q_PARAM,
  UPDATE_ANSWER_IMAGES,
  REMOVE_ANSWER_IMAGE,
  CLEAR_IMG_LIST,
  UPDATE_CURRENT_DURATION,
  UPDATE_SOLUTION_SUBS_LIST,
  TOGGLE_OUT_OF_TIME_MODAL,
  UPDATE_QUESTIONS_CONTAINER,
  UPDATE_CURRENT_SOLUTION_INDEX,
  UPDATE_TEST_TYPE,
  UPDATE_STUDENT_TEST_DATA,
  UPDATE_HOME_PAGE_DATA,
  UPDATE_SUBJECT_IN_SYLLABUS,
  UPDATE_CHAPTER_IN_SYLLABUS,
  SET_FILTER,
  SET_CARDS,
  SET_LOADER,
  RELOAD_TOGGLER,
  SET_SOLUTION_SUBJECTS,
  REFER_DURATION,
  CLEAR_QUESTION
} from "../actions/studentPracticeTest";

const initialState = {
  ongoingTest: null,
  publishedTests: [],
  scheduledTests: [],
  attemptedTests: [],
  missedTests: [],
  totalTests: null,
  missedTestsCount: null,
  subjects: [],
  syllabuses: [],
  studentTestFilterParams: {
    rangeType: null,
    startDate: null,
    endDate: null,
    testStatus: "AVAILABLE",
  },
  selectedSub: {},
  selectedTest: {},
  testResult: {},
  timeLeft: {
    hours: null,
    minutes: null,
  },
  currentQ: {},
  palateData: [],
  qCounts: {
    attempted: 0,
    attemptedAndBookmarked: 0,
    bookmarked: 0,
    unanswered: 0,
    unseen: 0,
  },
  currentIndex: null,
  testSubjects: [],
  selectedSubIndex: 0,
  solutionCounts: {
    correct: 0,
    incorrect: 0,
    partialCorrect: 0,
    unattempted: 0,
    unseen: 0
  },
  currentImages: [],
  currentDuration: 0,
  isOutOfTimeModalVisible: false,
  questionsContainer: [],
  currentSolutionIndex: 0,
  testType: '',
  studentTestData:{},
  homePageData: {},
  SubjectInSyllabus:[],
  ChapterInSyllabusData:[],
  filter: {

  },
  cards:{

  },
  loaders: {
    testScreenLoader: false,
    homePageLoader: false
  },
  reloadToggler: false,
  solutionSubjectsList: [],
  referDuration: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_OUT_OF_TIME_MODAL:
      return {
        ...state,
        isOutOfTimeModalVisible: action.value,
      };
    case UPDATE_CURRENT_DURATION:
      return {
        ...state,
        currentDuration: action.seconds,
      };
    case CLEAR_IMG_LIST:
      return {
        ...state,
        currentImages: [],
      };
    case REMOVE_ANSWER_IMAGE:
      if (state.currentQ.deletedFiles && state.currentQ.deletedFiles.length) {
        if (state.currentQ.files) {
          state.currentQ.deletedFiles.push(state.currentQ.files[action.index]);
        }
      } else {
        state.currentQ.deletedFiles = state.currentQ.files
          ? [state.currentQ.files[action.index]]
          : [];
      }
      if (state.currentQ.files && state.currentQ.files.length)
        state.currentQ.files.splice(action.index, 1);
      state.currentImages.splice(action.index, 1);
      return {
        ...state,
        currentQ: { ...state.currentQ },
        currentImages: [...state.currentImages],
      };
    case UPDATE_ANSWER_IMAGES:
      if (state.currentQ.files && state.currentQ.files.length) {
        state.currentQ.files.push(action.originalObject);
      } else {
        state.currentQ.files = [action.originalObject];
      }
      if (state.currentImages && state.currentImages.length) {
        state.currentImages.push(action.base64Img);
      } else {
        state.currentImages = [action.base64Img];
      }
      return {
        ...state,
        currentQ: { ...state.currentQ },
        currentImages: [...state.currentImages],
      };
    case SET_SELECTED_SUB_INDEX:
      return {
        ...state,
        selectedSubIndex: action.index,
      };
    case UPDATE_TEST_SUBS_LIST:
      return {
        ...state,
        testSubjects: [...action.data],
      };
    case UPDATE_SOLUTION_SUBS_LIST:
      return {
        ...state,
        solutionSubjects: [...action.data],
      };
    case UPDATE_Q_PARAM:
      return {
        ...state,
        currentQ: { ...state.currentQ, ...action.qParam },
      };
    case SET_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.index,
      };
    case UPDATE_SOLUTION_COUNT_DATA:
      return {
        ...state,
        solutionCounts: {
          ...state.solutionCounts,
          ...action.solutionCountData,
        },
      };
    case UPDATE_COUNT_DATA:
      return {
        ...state,
        qCounts: { ...state.qCounts, ...action.countData },
      };
    case UPDATE_PALATE_DATA:
      return {
        ...state,
        palateData: action.data,
      };
    case SET_CURRENT_STUDENT_QUESTION: {
      const currentAns = action.data?.attempt?.answer;
      const data = action.data;
      if (data?.type === "Subjective") {
        if (action.data.attempt?.filename) {
          state.currentImages = action.data.attempt?.filename.map(
            (path) => `${Api._s3_url}${path}`
          );
        }
      }
      return {
        ...state,
        currentQ: { ...action.data, currentAns },
        currentImages: [...state.currentImages],
        currentDuration: action.data?.attempt?.duration || 0,
      };
    }
    case SET_TEST_DURATION:
      if (!action.duration) {
        return {
          ...state,
          timeLeft: {
            hours: 0,
            minutes: 0,
            seconds: 0,
            inMinutes: 0,
          },
        };
      }
      return {
        ...state,
        timeLeft: {
          hours: Math.floor(action.duration / 3600).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          minutes: Math.floor((action.duration % 3600) / 60).toLocaleString(
            "en-US",
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          ),
          seconds: Math.floor((action.duration % 3600) % 60).toLocaleString(
            "en-US",
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          ),
          inMinutes: action.duration / 60,
        },
      };
    case UPDATE_TEST_RESULT:
      return {
        ...state,
        testResult: action.data,
      };
    case SET_SELECTED_TEST:
      return {
        ...state,
        selectedTest: { ...action.data },
      };
    case UPDATE_STUDENT_TEST_COUNT:
      return {
        ...state,
        subTestCount: action.count,
      };
    case SET_SELECTED_SUB:
      return {
        ...state,
        selectedSub: { ...action.data },
      };
    case UPDATE_STUDENT_TEST_FILTER:
      return {
        ...state,
        studentTestFilterParams: {
          ...state.studentTestFilterParams,
          ...action.filterParams,
        },
      };
    case UPDATE_DASHBOARD_DATA:
      let timeLeft;
      if (action.data.onGoingLiveTest) {
        const now = moment();
        timeLeft = moment
          .duration(
            moment(action.data.onGoingLiveTest.endTime, "HH:mm:ss").diff(
              now
            )
          )
          .asSeconds();
      }
      return {
        ...state,
        ongoingTest: action.data.onGoingLiveTest,
        publishedTests: action.data.publishedResults,
        scheduledTests: action.data.upcomingLiveTest,
        attemptedTests: action.data.attempted,
        totalTestsCount: action.data.testsAvailable?action.data.testsAvailable:0,
        missedTestsCount: action.data.missed?action.data.missed:0,
        missedTests: action.data.missedTests,
        subjects: action.data.subjects,
        syllabuses:action.data.syllabuses,

        timeLeft: {
          hours: Math.floor(timeLeft / 3600).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          minutes: Math.floor((timeLeft % 3600) / 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          seconds: Math.floor((timeLeft % 3600) % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          inMinutes: timeLeft / 60,
        },
      };
    case UPDATE_QUESTIONS_CONTAINER:
      return{
        ...state,
        questionsContainer: action.questions
      }
    case UPDATE_TEST_TYPE:
      return{
        ...state,
        testType: action.testType
      }
      case UPDATE_STUDENT_TEST_DATA: {
        let temp = { ...state.studentTestData };
        temp[action.key] = action.val;
        return {
          ...state,
          studentTestData: temp,
        };
      }
    case UPDATE_HOME_PAGE_DATA: {
      let newHomePageData = {...state.homePageData};
      if (action.key==="set"){
        newHomePageData = action.value
      }
      return {
        ...state,
        homePageData: newHomePageData
      }
    }

    case UPDATE_SUBJECT_IN_SYLLABUS: {
      return {
        ...state,
        SubjectInSyllabus: action.value
      }
    }

    case UPDATE_CHAPTER_IN_SYLLABUS: {
      return {
        ...state,
        ChapterInSyllabusData: action.value
      }
    }

    // case UPDATE_TEST_RESULT: {
    //   return {
    //     ...state,
    //     testResult: action.data
    //   }
    // }

    // case UPDATE_TEST_PERFORMANCE: {
    //   return {
    //     ...state,
    //     testPerformance: action.data
    //   }
    // }

    // case UPDATE_TEST_SOLUTION: {
    //   return {
    //     ...state,
    //     testSolution: action.data
    //   }
    // }




    case SET_FILTER:
      let newFilter = {...state.filter}
      if (action.key==='something'){
        ////write some conditionhere
      }else{
        newFilter[action.key] = action.val
      }
    return{
      ...state,
      filter: newFilter
    }

    case SET_CARDS:
      let newCards = {...state.cards}
      if (action.key==='something'){
        ////write some conditionhere
      }else{
        newCards[action.key] = action.val
      }
      return{
        ...state,
        cards: newCards
      }
    case SET_LOADER:
      let newloader = {...state.loaders};
      newloader[action.key] = action.val;
      return {
        ...state,
        loaders: newloader
      }
    case SET_SOLUTION_SUBJECTS:
      return{
        ...state,
        solutionSubjectsList:action.data
      }
    case RELOAD_TOGGLER:
      return {
        ...state,
        reloadToggler: !state.reloadToggler
      }
      
    case REFER_DURATION:
      return {
        ...state,
        referDuration:action.seconds
      }

    case CLEAR_QUESTION:
      return{
        ...state,
        currentQ: null,
        referDuration: 0,
        currentDuration: 0,
      }
    default:
      return state;
  }
};



export default reducer;
