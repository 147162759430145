import { AutoComplete, Col, Input, Modal, Row, Select } from 'antd';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {toggleAccessForm, updateAccessPostParams, postAccessData, loadCurrentData, clearPostAccessParams} from 'actions/digitalLibrary_old';
import { searchUser } from '../../../actions/calendar';
import { Color } from 'services';
import { Button } from 'components/Button';

const {Option} = Select;


function AccessForm({
    accessFormVisible, toggleAccessForm,
    searchUser, postAccessParams,
    teachers, teacherSearchLoader,
    updateAccessPostParams, standardList,
    subjectList, postAccessControlParamsLoader,
    postAccessData, teacherList, loadCurrentData,
    clearPostAccessParams
    
}) {

    useEffect(() => {
        return () => {
            clearPostAccessParams()
        }
    }, [])

    const handleTeacherSelect = (teacher, obj) => {
        if(!obj || obj.id === -1) {
          return console.log('return');
        };
        updateAccessPostParams(obj.id,'teacherId');
        const teacherAccessIds = teacherList.map(data => data.teacherId)
        if(teacherAccessIds.includes(obj.id)){
            const index = teacherAccessIds.indexOf(obj.id);
            loadCurrentData(index)
        }
      }
    return (
        <Modal 
            className='rounded-border'
            visible={accessFormVisible}
            title={
                <>
                    <div style={{fontSize: 22, fontWeight: 600, color: Color.primary, marginTop: 20}}>View Management</div>
                    <div style={{fontSize: 12, color: Color.darkGrey, marginTop: 10}}>Manage Teacher's View of Digital Library Content</div>
                </>
            }
            onCancel={() => toggleAccessForm(false)}
            footer={
                <div className="r-c-c">
                    <Button type="primary" 
                        style={{width: 150}} shape="round" 
                        onClick={() => postAccessData()} loading={postAccessControlParamsLoader}
                        disabled={
                            !postAccessParams ||
                            !postAccessParams.teacherId ||
                            !postAccessParams.standardIds || !postAccessParams.standardIds.length ||
                            !postAccessParams.subjectIds || !postAccessParams.subjectIds.length
                        }
                    >
                        Done
                    </Button>
                </div>
            }
        >
            <Row>
                <Col span={24}>
                    <div style={{fontWeight: 500}}>Teacher<span style={{ color: Color.red }}>*</span></div>
                    <div className="m-t-10">
                    <AutoComplete
                        options={teachers}
                        style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                        onSelect={(value, obj) => handleTeacherSelect(value, obj)}
                        onSearch={(query) => searchUser(query)}
                        // value={postAccessParams ? postAccessParams.selectedTeacher : undefined}
                        onChange={(value) => updateAccessPostParams(value,'teacherName')}
                        loading={teacherSearchLoader}
                        value={postAccessParams.teacherName}
                        >
                        <Input.Search style={{ border:'none', borderRadius:5, height: 32}}  placeholder="Search name..." loading={teacherSearchLoader} allowClear/>
                    </AutoComplete>
                    </div>
                </Col>
            </Row>
            <Row className="m-t-20"> 
                    <Col span={24}>
                        <div style={{fontWeight: 500}}>Class(es)<span style={{ color: Color.red }}>*</span></div>
                        <div className="m-t-10">
                        <Select
                            mode="multiple"
                            placeholder="Select class(es)..."
                            style={{width: "100%"}}
                            value={postAccessParams.standardIds}
                            onChange={(val) => updateAccessPostParams(val,'standardIds')}
                        >
                               {standardList && 
                                standardList.map((standard, index) => 
                                (<Option key={index.toString()} value={standard.id}>{standard.std} - {standard.section} </Option>) 
                                )}
                            
                        </Select>
                        </div>

                    </Col>
                </Row>
                <Row>
                <Col span={24} className="m-t-20">
                    <div style={{fontWeight: 500}}>Subject(s)<span style={{ color: Color.red }}>*</span></div>
                    <div className="m-t-10">
                        <Select
                            mode="multiple"
                            placeholder="Select subject(s)..."
                            value={postAccessParams.subjectIds}
                            style={{width: "100%"}}
                            onChange={(val) => updateAccessPostParams(val,'subjectIds')}
                            >
                               {subjectList && 
                                    subjectList.map((subject, index) => 
                                    (<Option key={index.toString()} value={subject.id}>{subject.name}</Option>) 
                                )}
                        </Select>
                    </div>
                </Col>
            </Row>
                

        </Modal>

    )
}

const mapStateToProps = state => {
    const {
        accessFormVisible, postAccessParams, teacherSearchLoader, standardList, subjectList, postAccessControlParamsLoader, teacherList
    } = state.digitalLibrary;    
  const { teachers, subClasses } = state.calendar;
    return {
        accessFormVisible, postAccessParams, teachers, subClasses, teacherSearchLoader, standardList, subjectList, postAccessControlParamsLoader, teacherList
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    toggleAccessForm: bool => dispatch(toggleAccessForm(bool)),
    searchUser: (query) => dispatch(searchUser(query)),
    updateAccessPostParams: (val, key) => dispatch(updateAccessPostParams(val, key)),
    postAccessData: () => dispatch(postAccessData()),
    loadCurrentData: index => dispatch(loadCurrentData(index)),
    clearPostAccessParams: () => dispatch(clearPostAccessParams())
   
  });


export default connect(mapStateToProps, mapDispatchToProps)(AccessForm);
