import { CaretRightOutlined, CheckCircleOutlined, ClockCircleOutlined, CopyOutlined, DeleteOutlined, EditOutlined, FilePdfOutlined, PlusCircleOutlined, ProfileOutlined, QuestionCircleOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { getCoursePlanningData, getOnlineTestSeriesCourseDetails, getSpecificCourseData, getSpecificSMData, getSpecificVideoCourseData, postCoursePlanningData, postCreateCurriculum, postExamPaterrn, postToggleCourseAdmissions, setCreateCourseObj, setIsFromAdminPreview, deleteOffer, patchPublishOffers } from 'actions/courseManagement';
import { getAllClasses } from 'actions/classRoom';
import { addSubjectFromOnlineTest, addSubjects, deleteSubject } from 'actions/course';
import { setIndividualTestId, setOnlineTestSeriesId } from 'actions/marketTest';
import { setImportMeta } from 'actions/testAdmin';
import { Collapse, Divider, Dropdown, Menu, Tag, Tooltip, Typography } from 'antd';
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import { SquareSubIcon } from 'components/subIcon';
import UserAvatar from 'components/userAvatar';
import moment from "moment";
import { NodataCard } from 'pages/classRoom';
import CourseDeleteConfirmModal from 'pages/courseModule/courseDeleteConfirmModal';
import CreateSubjectsModal from 'pages/courseModule/createSubjectsModal';
import FeeDetailsModal from 'pages/courseModule/feeDetailsModal';
import PreviewCourse from 'pages/studentMarketPlace/coursePageCompo/previewCourse';
import React, { useEffect, useRef, useState } from 'react';
import HtmlParser from 'react-html-parser';
import { FiChevronDown } from 'react-icons/fi';
import { MdMoreVert } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import quiz from '../../Assets/aulasMarket/ic-practice-quiz.svg';
import video from '../../Assets/course/Video.png';
import newPublishIcon from "../../Assets/courseManagementV4/new-publish-icon.svg";
import newUnpublishIcon from "../../Assets/courseManagementV4/new-unpublish-icon.svg";
import newPreviewIcon from "../../Assets/courseManagementV4/preview-icon.svg";
import { IMG_URL } from "../../env.json";
import TestExamPattern from '../adminMarketPlaceCourse/components/examPattern';
import AddStudentsToCourse from './addStudentsToCourse';
import BatchesComponent from './batchesComponent';
import CourseEditFeeModal from './components/courseEditFeeDetails';
import EditOfferDrawer from './components/editOfferDrawer';
import TestSyllabus from './components/testSyllabus';
import CourseInfoTest from './components/tests';
import ConfirmPublishOrUnpublishModal from './confirmPublishOrUnpublishModal';
import ConfirmationModal from './confirmationModal';
import CourseContentComp from './courseContentComp';
import CourseDetailsComp from './courseDetailsComp';
import CreateCurriculumModal from './createCurriculumModal';
import DashboardOffersCard from './dashboardOffersCard';
import DeleteModal from './deleteModal';
import FeeContentComp from './feeContentComp';
import LiveCourseCurriculum from './liveCourseCurriculum';
import LiveCoursePlanning from './liveCoursePlanning';
import StudentsEnrolledComp from './studentsEnrolledComp';
import DeleteModalOffer from './offerDeleteModal';
import PublishModal from './components/publishModal';
import "./styles.css";
import './test.css';

const { Panel } = Collapse;
export const NoData = require("../../Assets/ill-no-data.svg").default;

export const newCardStyle = {
    backgroundColor: "#FFFFFF",
    padding: "24px",
    boxShadow: "4px 4px 16px #0000000F",
    borderRadius: "17px",
    width: "100%"
}

export const descStyle = {
    color: "#636363",
    fontSize: 14
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
        if (c[i].nodeType === 1) return true;
    }

    return false;
}

const bodyStyle = {
    backgroundColor: "#FAFAFA",
    width: " calc(100% + 30px)",
    height: "calc(100% + 40px)",
    margin: "-20px",
    padding: "20px 20px 15px 20px",
    overflow: "auto"
}

const cardstyle = {
    border: "1px solid #EDEDED", borderRadius: 8, backgroundColor: "#FFFFFF"
}
const titleStyle = {
    color: "#191919",
    fontSize: 13,
    fontWeight: 700,
    marginTop: 10
}

const tests = [
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
    { title: 'JEE Mains - Mock test 1', questions: `${150} `, marks: 50, time: `${60} ` },
]

function CourseInformationPage({ setImportMeta, getSpecificCourseData, specificCourseDataContainer, addSubjects, deleteSubject, courseTypeName, getSpecificSMData, getSpecificVideoCourseData,
    getOnlineTestSeriesCourseDetails, totalTagsData, setOnlineTestSeriesId, setIndividualTestId, newCourseId, addSubjectFromOnlineTest, getCoursePlanningData,
    postCoursePlanningData, getAllClasses, postCreateCurriculum, postToggleCourseAdmissions, isFromCourseDetailsReducer, courseCreateObj, setCreateCourseObj, postExamPaterrn,
    setIsFromAdminPreview, user, deleteOffer, patchPublishOffers }) {
    const history = useHistory();
    const [pageLoader, togglePageLoader] = useState(false);
    const [isCreateSubjectModalVisible, setCreateSubjectModalVisible] = useState(false);
    const [isCreateCurriculumModalVisible, setIsCreateCurriculumModalVisible] = useState(false);
    const [isEditSubjectVisible, setEditSubjectVisible] = useState(false);
    const [showEditChapters, setShowEditChapters] = useState([]);
    const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [isFromSubjects, setIsFromSubjects] = useState(false);
    const [isFeeDetailsModalVisible, setFeeDetailsModalVisible] = useState(false);
    const [isAddStudentsToCourseModalVisible, setAddStudentsToCourseModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isFeeExpanded, setIsFeeExpanded] = useState(true);
    const [isStudentsExpanded, setIsStudentsExpanded] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [coursePlanningData, setCoursePlanningData] = useState({
        plannedClasses: null,
        plannedHours: null,
        plannedTests: null
    })
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isCoursePlanningEditClicked, setIsCoursePlanningEditClicked] = useState(false);
    const [isViewDetailsClicked, setIsViewDetailsClicked] = useState(false);
    const [isStudentsExpandClicked, setIsStudentExpandClicked] = useState(false);
    const [isBatchesExpandClicked, setIsBatchesExpandClicked] = useState(false);
    const [isFeeClosed, setIsFeeClosed] = useState(false);
    const [isVideoPreview, setVideoPreview] = useState(false);
    const [previewLink, setPreviewLink] = useState(null);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const playerRef = useRef(null);
    const location = useLocation();
    const examPattern = specificCourseDataContainer?.testSeriesDetails?.examPattern;
    const [showSkeletonOnCancel, setShowSkeletonOnCancel] = useState(false);
    const [wasStudentCardExpanded, setWasStudentCardExpanded] = useState(false);
    const [wasDetailsCardExpanded, setWasDetailsCardExpanded] = useState(false)
    const [isCourseDeleteModal, setCourseDeleteModal] = useState(false)
    const [isOfferDrawerVisible, setIsOfferDrawerVisible] = useState(false);
    const [isExamPattern, setIsExamPatern] = useState(false)
    const [isSyllabas, setIsSyllabas] = useState(false)
    const [isTestExpan, setIsTestExpand] = useState(true)
    const [resources, setResources] = useState(location?.state?.key?.resources ? true : false)
    const [isEdit, setIsEdit] = useState(false);
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [isOfferDeleteModalVisible, setIsOfferDeleteModalVisible] = useState(false);
    const [isOfferPublishModalVisible, setIsOfferPublishModalVisible] = useState(false);
    const [isOfferPublish, setIsOfferPublish] = useState(false);
    const [isVideoResource, setIsVideoResource] = useState(location?.state?.key?.videoResources || location?.state?.fromBrodcastVR?.fromAddBroadcastPage ? true : false);

    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');

    useEffect(() => {
        setIsFromAdminPreview(false);
        togglePageLoader(true);
        if (courseTypeName == "studyMaterials" || (location?.state?.fromCRMPage && location?.state?.courseType == "studyMaterial")) {
           
            getSpecificSMData(() => togglePageLoader(false))
        } else if (courseTypeName == "videoResources" || (location?.state?.fromCRMPage && location?.state?.courseType == "videoResource")) {
            getSpecificVideoCourseData(() => togglePageLoader(false))
        }
        else if (courseTypeName == "onlineTestSeries") {
            setOnlineTestSeriesId(newCourseId)
            getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
        }
        else {
            if (location?.state?.key == "openBatches") {
                setIsBatchesExpandClicked(true);
                setIsViewDetailsClicked(false);
                setIsStudentExpandClicked(false);
                setIsFeeClosed(true)
            }
            if (location?.state?.key == "openStudents") {
                setIsStudentExpandClicked(true);
                setIsBatchesExpandClicked(false)
            }
            if (location?.state?.key?.showSkeleton) {
                setShowSkeletonOnCancel(true)
            }
            getCoursePlanningData((response) => {
                setCoursePlanningData({
                    plannedClasses: response?.plannedClasses,
                    plannedHours: response?.plannedHours,
                    plannedTests: response?.plannedTests
                });
            })
            getAllClasses(newCourseId)
            getSpecificCourseData(() => togglePageLoader(false));
        }
       
    }, []);

    useEffect(() => {
        let selectedTagsName = totalTagsData
            ?.filter((item) => courseTypeName == "liveCourses" ? specificCourseDataContainer?.course?.tags?.some((tag) => tag.id === item.id) : specificCourseDataContainer?.tags?.some((tag) => tag.id === item.id))
            ?.map((item) => ({
                id: item?.id,
                value: item?.tag,
            }));
        setSelectedTags(selectedTagsName ? [...selectedTagsName] : [])
    }, [specificCourseDataContainer?.course?.tags, specificCourseDataContainer?.tags])

    let publish = true;
    if ((courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.published == 1) || (courseTypeName == "studyMaterials" && specificCourseDataContainer?.published == 1) || (courseTypeName == "videoResources" && specificCourseDataContainer?.published == 1) || (courseTypeName == "onlineTestSeries" && specificCourseDataContainer?.testSeriesDetails?.published == true)) {
        publish = false
    } else {
        publish = true
    }
    const moreDropdownArray = (specificCourseDataContainer?.course?.students?.length >= specificCourseDataContainer?.course?.totalSeats && (specificCourseDataContainer?.course?.students?.length != 0 && specificCourseDataContainer?.course?.totalSeats != 0)) ?
    [
        {
            icon: 
            (courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials') && specificCourseDataContainer?.published ||
            (courseTypeName == 'onlineTestSeries' && specificCourseDataContainer?.testSeriesDetails?.published) ||
            (courseTypeName == 'liveCourses' && specificCourseDataContainer?.course?.published) ? newUnpublishIcon :
            newPublishIcon,

            text: 
            (courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials') && specificCourseDataContainer?.published ||
            (courseTypeName == 'onlineTestSeries' && specificCourseDataContainer?.testSeriesDetails?.published) ||
            (courseTypeName == 'liveCourses' && specificCourseDataContainer?.course?.published) ? "Unpublish" :
            "Publish",
        },
        {
            icon: newPreviewIcon,
            text: "Preview"
        },
        {
            icon: <CopyOutlined />,
            text: "Clone"
        },
        {
            icon: <DeleteOutlined />,
            text: "Delete"
        }
    ]
    :
     [
        {
            icon: 
            (courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials') && specificCourseDataContainer?.published ||
            (courseTypeName == 'onlineTestSeries' && specificCourseDataContainer?.testSeriesDetails?.published) ||
            (courseTypeName == 'liveCourses' && specificCourseDataContainer?.course?.published) ? newUnpublishIcon :
            newPublishIcon,

            text: 
            (courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials') && specificCourseDataContainer?.published ||
            (courseTypeName == 'onlineTestSeries' && specificCourseDataContainer?.testSeriesDetails?.published) ||
            (courseTypeName == 'liveCourses' && specificCourseDataContainer?.course?.published) ? "Unpublish" :
            "Publish",
        },
        {
            icon: newPreviewIcon,
            text: "Preview"
        },
        {
            icon: <CopyOutlined />,
            text: "Clone"
        },
        {
            icon: specificCourseDataContainer?.course?.disableAdmission ? <CheckCircleOutlined /> : <StopOutlined />,
            text: specificCourseDataContainer?.course?.disableAdmission ? "Allow admissions" : "Stop admissions"
        },
        {
            icon: <DeleteOutlined />,
            text: "Delete"
        }
    ]

    const menu = (
        <Menu style={{ width: "fit-content", background: "#FFFFFF", boxShadow: "-4px 4px 16px #00000029", borderRadius: "8px", padding: "10px", marginTop: 15 }}>
            <div>
                {moreDropdownArray?.map((item, index) => (
                    (index !== 3 || courseTypeName === "liveCourses") && (
                        <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }}
                            onClick={() => {
                                if (index === 0) {
                                    setIsConfirmPublishModalVisible(true);
                                } else if (index === 1) {
                                    setIsFromAdminPreview(true)
                                    history.push({
                                        pathname: "/resource-details",
                                        state: {
                                            resourceType: courseTypeName === "liveCourses" ? "course" : courseTypeName === "videoResources" ? "videoResource" : courseTypeName === "studyMaterials" ? "studyMaterials" : courseTypeName === "onlineTestSeries" ? "testSeries" : "",
                                            id: newCourseId,
                                        }
                                    })
                                } else if (index === 2) {
                                    history.push({
                                        pathname: courseTypeName === "liveCourses" ? "/add-live-courses" : courseTypeName === "videoResources" ? "/add-video-resources" : courseTypeName === "onlineTestSeries" ? "/add-online-test-series" : courseTypeName === "studyMaterials" ? "/add-study-materials" : "/add-live-courses",
                                        state: {
                                            key: {
                                                isEdit: "isFromEdit",
                                                isClone: true,
                                                fromCourseDetails: true,
                                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                searchedValue: location?.state?.key?.searchedValue
                                            }
                                        }
                                    });
                                } else if (index === 3) {
                                    setIsConfirmModalVisible(true);
                                } else if (index === 4) {
                                    setIsDeleteModalVisible(true);
                                }
                            }}>
                            {index === 0 || index === 1 ? <img style={{ width: 15 }} src={item?.icon} /> : <div style={{ fontSize: 16 }}>{item?.icon}</div>}
                            <div className='m-l-8'>{item?.text}</div>
                        </div>
                    )
                ))}
            </div>
        </Menu>
    );

    const PublishedOrNotTag = ({ title, bgc, color }) => {
        return (
            <div className='r-c-c-c' style={{ color: color, backgroundColor: bgc, padding: "3px 10px", width: "fit-content", height: "fit-content", fontWeight: "bold", borderRadius: 2 }}>{title}</div>
        )
    }

    const liveCourseAction = [
        (courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials') && specificCourseDataContainer?.published ||
            (courseTypeName == 'onlineTestSeries' && specificCourseDataContainer?.testSeriesDetails?.published) ||
            (courseTypeName == 'liveCourses' && specificCourseDataContainer?.course?.published) ? <PublishedOrNotTag title={"PUBLISHED"} bgc={"#D5F8EF"} color={"#2EA083"} /> :
            <PublishedOrNotTag title={"UNPUBLISHED"} bgc={"#FDF3CE"} color={"#DAAB00"} />,

        !hasMarketingPrivilege && (
            <div style={{ marginLeft: 10 }}>
                <Dropdown overlay={menu} placement="right">
                    <MdMoreVert style={{ width: "30px", height: "30px", color: "#AEAEAE", cursor: "pointer" }} />
                </Dropdown>
            </div>
        )
    ]

    const actions = [
        // <Button style={{ marginRight: 10 }}>PREVIEW</Button>,
        <Button style={{ color: "#FFFFFF", backgroundColor: "#FF414D", marginRight: 10, border: "1px solid #FF414D" }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>,
        <Button type="primary" onClick={() => { setIsConfirmPublishModalVisible(true) }}>{!publish ? "UNPUBLISH" : "PUBLISH"}</Button>,
    ]

    const StudyMaterialTopicsContent = ({ item, index }) => {
        return (
            <div style={{ border: "1px solid #E6E6E6", height: "fit-content", width: "100%", padding: "8px 20px", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#F6F4FE", fontSize: 16 }}>
                <div style={{ color: "#191919", fontWeight: "bold" }}>{index + 1}<span style={{ margin: "0px 10px 0px 20px" }}>{item?.name}</span>{item?.preview == 1 ? <></> : <img src={require("../../Assets/course/lockIcon.svg").default} />}</div>
                <div style={{ display: "flex" }}>
                    {/* <div style={{ color: "#636363", marginRight: 20 }}>24 Quiz</div> */}
                    <div style={{ color: "#AEAEAE" }}>{item?.pageCount} Pages</div>
                </div>
            </div>
        )
    }

    const handleVideoPause = () => {
        setVideoPreview(true);
        if (playerRef.current && videoPlaying) {
            playerRef.current.seekTo(0)
            setVideoPlaying(false);
        } else {
            setVideoPlaying(true);
        }
        setPreviewLink(specificCourseDataContainer?.course?.preview ? specificCourseDataContainer?.course?.preview : specificCourseDataContainer?.preview);
    }
    const learnings = specificCourseDataContainer?.course?.learnings || specificCourseDataContainer?.learnings;

    const LiveCourseFeeContent = ({ courseTypeName }) => {
        function numberWithCommas(x) {
            return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
        }
        const liveCourseFeeContentArray = [

            {
                label: "Total fee",
                value: specificCourseDataContainer?.amount || specificCourseDataContainer?.testSeriesDetails?.amount
            },
            {
                label: "Fee after discount",
                value: (courseTypeName == 'onlineTestSeries' && specificCourseDataContainer?.testSeriesDetails?.discountType == "amount" ? specificCourseDataContainer?.testSeriesDetails?.amount - specificCourseDataContainer?.testSeriesDetails?.discount : (Math.round((specificCourseDataContainer?.testSeriesDetails?.amount - (specificCourseDataContainer?.testSeriesDetails?.amount * specificCourseDataContainer?.testSeriesDetails?.discount) / 100) * 100) / 100)) || (specificCourseDataContainer?.discountType == "amount" ? specificCourseDataContainer?.amount - specificCourseDataContainer?.discount : (Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100))

                // value: specificCourseDataContainer?.discountType == "amount" ? specificCourseDataContainer?.amount - specificCourseDataContainer?.discount : (Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100) || specificCourseDataContainer?.testSeriesDetails?.discountType == "amount" ? specificCourseDataContainer?.testSeriesDetails?.amount - specificCourseDataContainer?.testSeriesDetails?.discount : (Math.round((specificCourseDataContainer?.testSeriesDetails?.amount - (specificCourseDataContainer?.testSeriesDetails?.amount * specificCourseDataContainer?.testSeriesDetails?.discount) / 100) * 100) / 100)
            },
        ]
        return (
            <div style={{ width: "100%" }}>
                {liveCourseFeeContentArray?.map((item, index) => (
                    <div>
                        <div className='r-c-sb m-b-10' style={{ marginTop: index == 0 ? 0 : 10 }}>
                            <div style={{ color: "#636363", fontWeight: 600 }}>{item?.label}</div>
                            {courseTypeName == 'onlineTestSeries' ? <>
                                <div style={{ color: "#636363", fontWeight: 600 }}><span style={{ color: "#191919" }}>₹ </span>{numberWithCommas(item?.value.toFixed(2))}{index == 1 ? <span style={{ color: "#28DF99" }}>{specificCourseDataContainer?.testSeriesDetails?.discount != 0 ? <> ({specificCourseDataContainer?.testSeriesDetails?.discount}{" "}{specificCourseDataContainer?.testSeriesDetails?.discountType == "amount" ? 'Rs' : '%'}) </> : null}</span> : <></>}</div>
                            </> :
                                <div style={{ color: "#636363", fontWeight: 600 }}><span style={{ color: "#191919" }}>₹ </span>{numberWithCommas(item?.value.toFixed(2))}{index == 1 ? <span style={{ color: "#28DF99" }}>{specificCourseDataContainer?.discount != 0 ? <> ({specificCourseDataContainer?.discount}{" "}{specificCourseDataContainer?.discountType == "amount" ? 'Rs' : '%'}) </> : null}</span> : <></>}</div>}
                        </div>
                        <div style={{ borderBottom: "1px solid #E6E6E6" }} />
                    </div>
                ))}

                {hasMarketingPrivilege ?
                    <div style={{ marginTop: 20, color: "#636363", fontWeight: "bold", fontSize: 16, }}>Promotional offers</div>
                    :
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div className='m-t-20 m-b-10' style={{ color: "#636363", fontWeight: "bold", fontSize: 16, }}>Promotional offers</div>
                        <div className='cursor-pointer m-t-20 m-l-10 ' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => { setSelectedOfferId(null); setIsOfferDrawerVisible(true) }}>ADD A NEW</div>
                    </div>
                }

                {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? (<>
                    {specificCourseDataContainer?.offers?.length != 0 ?
                        (<>
                            <div className="scroll-bar-universal" style={{ height: "140px" }}>
                                {specificCourseDataContainer?.offers?.map((item, index) => (
                                    <div key={item.id}>
                                        <DashboardOffersCard
                                            item={item} courseTypeName={courseTypeName}
                                            setIsOfferDrawerVisible={setIsOfferDrawerVisible}
                                            setSelectedOfferId={setSelectedOfferId}
                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                            setIsOfferPublish={setIsOfferPublish}
                                            setIsOfferDeleteModalVisible={setIsOfferDeleteModalVisible}
                                            setIsOfferPublishModalVisible={setIsOfferPublishModalVisible}
                                        />
                                    </div>
                                ))}</div></>) : (<><div className='r-c-c' style={{ flexDirection: "column" }}>
                                    <img src={NoData} alt='' style={{ width: 80 }} />
                                    <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                    <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                </div></>)}</>) : (<>
                                    {specificCourseDataContainer?.testSeriesDetails?.offers?.length != 0 ?
                                        (<>
                                            <div className="scroll-bar-universal" style={{ height: "140px" }}>
                                                {specificCourseDataContainer?.testSeriesDetails?.offers?.map((item, index) => (
                                                    <div key={item.id} >
                                                        <DashboardOffersCard
                                                            item={item}
                                                            courseTypeName={courseTypeName}
                                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                                            setIsOfferDrawerVisible={setIsOfferDrawerVisible}
                                                            setSelectedOfferId={setSelectedOfferId}
                                                            setIsOfferPublish={setIsOfferPublish}
                                                            setIsOfferDeleteModalVisible={setIsOfferDeleteModalVisible}
                                                            setIsOfferPublishModalVisible={setIsOfferPublishModalVisible}
                                                        />
                                                    </div>
                                                ))}</div></>) : (<><div className='r-c-c' style={{ flexDirection: "column" }}>
                                                    <img src={NoData} alt='' style={{ width: 80 }} />
                                                    <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                                    <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                                </div></>)}</>)
                }



            </div>
        )
    }

    const [isScrolled, setIsScrolled] = useState(false);
    const scrollContainerRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(scrollContainerRef.current.scrollTop > 0);
            setIsCoursePlanningEditClicked(false)
        };
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleScroll = (event) => {
        setIsScrolled(event.target.scrollTop)
    }
    const SingleSubjectCard = ({ item, setCreateSubjectModalVisible, setEditSubjectVisible, setShowEditChapters }) => {
      
        return (
            <div className="box-sequare__subject" style={{ height: 300, width: 235, }}>
                <div className="r-ac">
                    <SquareSubIcon name={item?.subject?.name} size={24} />
                    <div className="m-l-10">
                        <div className='text-xmd dark-black bold-600 text-overflow' style={{ width: 150, textTransform: 'uppercase' }}>{item?.subject?.name}</div>
                        <div style={{ color: "#636363", fontSize: 12 }}>
                            {item?.subject?.testSeriesChapter ? item?.subject?.testSeriesChapter?.length : 0} Chapters
                        </div>
                    </div>
                </div>
                <Divider style={{ margin: "10px 0px" }} />
                <div className="box-sequare__subject-chapter-wrap">
                    <div>
                        {item?.subject?.testSeriesChapter && item?.subject?.testSeriesChapter?.length
                            ? item?.subject?.testSeriesChapter
                                .filter((chap, i) => i < 6)
                                .map((chapter, chapIndex) => (
                                    <div className="r-ac m-b-5">
                                        <div className=" m-r-15" style={{ color: "#636363", fontSize: 13 }}>
                                            Chapter {chapIndex + 1}
                                        </div>
                                        <div className="dark-black bold-600 text-overflow" style={{ fontSize: 13 }}>
                                            {chapter.name}
                                        </div>
                                    </div>
                                ))
                            : null}
                    </div>
                    {item.courseChapter?.length > 4 || item?.subject?.testSeriesChapter?.length > 4 ? (
                        <div
                            className=" bold-600 text-center blue cursor-pointer"
                            onClick={() => {
                                setEditSubjectVisible(true)
                                setCreateSubjectModalVisible(true);
                                setShowEditChapters(item)
                            }}
                            style={{ fontSize: 13 }}
                        >
                            VIEW ALL
                        </div>
                    ) : (
                        <div
                            className="bold-600 text-center blue cursor-pointer"
                            style={{ fontSize: 13 }}
                            onClick={() => {
                                setEditSubjectVisible(true)
                                setCreateSubjectModalVisible(true)
                                setShowEditChapters(item)
                            }}
                        >
                            <PlusCircleOutlined /> ADD CHAPTERS
                        </div>
                    )}
                </div>
            </div>
        )
    }
    
    const AddSubjectButton = ({ setCreateSubjectModalVisible, history, setEditSubjectVisible, kii }) => (
        <div key={1} className="box-sequare__subject r-c-c-c" style={{ backgroundColor: "#F6F4FE", height: 300, width: 235 }}>
            <div
                style={{ fontWeight: 600, fontSize: 15, color: "#191919" }}
                className="display-flex "
            >
                Add Subjects
            </div>
            <div style={{ fontSize: 14, color: "#636363", textAlign: "center" }}>
                If you like you can browse from our resources or create manually
            </div>
            <Button onClick={() => { setEditSubjectVisible(false); setCreateSubjectModalVisible(true) }} style={{ marginBottom: 15, marginTop: 10 }}>CREATE</Button>
            <Button type="primary" onClick={() => history.push({ pathname: "/classroom/resources", state: { key: kii } })}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
        </div>
    );

    return (
        <div>
            <div style={{ marginBottom: "23px" }}>
                <PageHeader maxWidth={800} title={(courseTypeName == "studyMaterials" || courseTypeName == "videoResources") ? specificCourseDataContainer?.name : (courseTypeName == "onlineTestSeries") ? specificCourseDataContainer?.testSeriesDetails?.name : specificCourseDataContainer?.course?.courseName}
                    onBackPress={() => {
                        if (location?.state?.key?.fromDashboardSearch) {
                            history.push("/courses")
                        } else if (location?.state?.fromCRMPage) {
                            history.goBack();
                        } else if (location?.state?.key?.fromMarketPlaceMenu) {
                            history.push("/market-place")
                        }
                        else {
                            history.push(courseTypeName == "liveCourses" ?
                                {
                                    pathname: "/live-courses-data", state: {
                                        key: {
                                            fromCourseCard: location?.state?.key?.fromCourseCard,
                                            fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                            searchedValue: location?.state?.key?.searchedValue,
                                            fromDashboardSearch: location?.state?.key?.fromDashboard,
                                            isPublished:location?.state?.key?.isPublished,
                                            isGridView:location?.state?.key?.isGridView,
                                        }
                                    }
                                } :
                                courseTypeName == "videoResources" ?
                                    {
                                        pathname: "/video-resources-data", state: {
                                            key: {
                                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                searchedValue: location?.state?.key?.searchedValue,
                                                fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                isPublished:location?.state?.key?.isPublished,
                                                isGridView:location?.state?.key?.isGridView,
                                            }
                                        }
                                    } :
                                    courseTypeName == "onlineTestSeries" ?
                                        {
                                            pathname: "/online-test-series-details", state: {
                                                key: {
                                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                    searchedValue: location?.state?.key?.searchedValue,
                                                    fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                    isPublished:location?.state?.key?.isPublished,
                                                    isGridView:location?.state?.key?.isGridView,
                                                }
                                            }
                                        } :
                                        courseTypeName == "studyMaterials" ?
                                            {
                                                pathname: "/study-materials-data", state: {
                                                    key: {
                                                        fromCourseCard: location?.state?.key?.fromCourseCard,
                                                        fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                        searchedValue: location?.state?.key?.searchedValue,
                                                        fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                        isPublished:location?.state?.key?.isPublished,
                                                        isGridView:location?.state?.key?.isGridView,
                                                    }
                                                }
                                            }
                                            : "/live-courses-data"
                            );
                        }
                    }}
                    actions={liveCourseAction} />
            </div>

            <div style={bodyStyle}>
                {courseTypeName == "liveCourses" || courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' || courseTypeName == 'onlineTestSeries' ?
                    <>
                        <div className='r-jsb' style={{ width: "100%", marginTop: 30 }}>
                            <div style={{ width: "70%", marginRight: 20 }}>
                                {!resources && !isVideoResource ?
                                    <CourseDetailsComp
                                        specificCourseDataContainer={specificCourseDataContainer}
                                        courseTypeName={courseTypeName}
                                        location={location}
                                        isBatchesExpandClicked={isBatchesExpandClicked}
                                        setIsViewDetailsClicked={setIsViewDetailsClicked}
                                        isViewDetailsClicked={isViewDetailsClicked}
                                        handleVideoPause={handleVideoPause}
                                        setIsCoursePlanningEditClicked={setIsCoursePlanningEditClicked}
                                        setIsBatchesExpandClicked={setIsBatchesExpandClicked}
                                        setIsFeeClosed={setIsFeeClosed}
                                        isVideoPreview={isVideoPreview}
                                        pageLoader={pageLoader}
                                        showSkeletonOnCancel={showSkeletonOnCancel}
                                        isScrolled={isScrolled}
                                        setWasDetailsCardExpanded={setWasDetailsCardExpanded}
                                        wasDetailsCardExpanded={wasDetailsCardExpanded}
                                        isExamPattern={isExamPattern}
                                        setIsExamPatern={setIsExamPatern}
                                        isSyllabas={isSyllabas}
                                        setIsSyllabas={setIsSyllabas}
                                        isTestExpan={isTestExpan}
                                        setIsTestExpand={setIsTestExpand}
                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                    />
                                    : (<></>)}

                                {isBatchesExpandClicked || isViewDetailsClicked || isScrolled || courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? <></> :

                                    courseTypeName == 'onlineTestSeries' ?
                                        (<><TestExamPattern
                                            isCoursePlanningEditClicked={isCoursePlanningEditClicked}
                                            setIsCoursePlanningEditClicked={setIsCoursePlanningEditClicked}
                                            specificCourseDataContainer={specificCourseDataContainer}
                                            courseCreateObj={courseCreateObj}
                                            setCreateCourseObj={setCreateCourseObj}
                                            isExamPattern={isExamPattern}
                                            setIsExamPatern={setIsExamPatern}
                                            isSyllabas={isSyllabas}
                                            setIsSyllabas={setIsSyllabas}
                                            isTestExpan={isTestExpan}
                                            setIsTestExpand={setIsTestExpand}
                                            postExamPaterrn={postExamPaterrn}
                                            newCourseId={newCourseId}
                                            getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                                            hasMarketingPrivilege={hasMarketingPrivilege}

                                        /></>) :

                                        <LiveCoursePlanning
                                            isCoursePlanningEditClicked={isCoursePlanningEditClicked}
                                            setIsCoursePlanningEditClicked={setIsCoursePlanningEditClicked}
                                            postCoursePlanningData={postCoursePlanningData}
                                            getCoursePlanningData={getCoursePlanningData}
                                            coursePlanningData={coursePlanningData}
                                            setCoursePlanningData={setCoursePlanningData}
                                            setIsViewDetailsClicked={setIsViewDetailsClicked}
                                            hasMarketingPrivilege={hasMarketingPrivilege}

                                        />
                                }
                                {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ? (<>

                                    <>
                                        <CourseContentComp
                                            specificCourseDataContainer={specificCourseDataContainer}
                                            courseTypeName={courseTypeName}
                                            isBatchesExpandClicked={isBatchesExpandClicked}
                                            isViewDetailsClicked={isViewDetailsClicked}
                                            isCoursePlanningEditClicked={isCoursePlanningEditClicked}
                                            setResources={setResources}
                                            resources={resources}
                                            isEdit={isEdit}
                                            setIsEdit={setIsEdit}
                                            isVideoResource={isVideoResource}
                                            setIsVideoResource={setIsVideoResource}
                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                            togglePageLoader={togglePageLoader}
                                        />
                                    </>
                                </>) :
                                    (<>
                                        {isBatchesExpandClicked || isViewDetailsClicked ? <></> :
                                            courseTypeName == 'onlineTestSeries' ?
                                                (<>
                                                    <TestSyllabus
                                                        isViewDetailsClicked={isViewDetailsClicked}
                                                        specificCourseDataContainer={specificCourseDataContainer}
                                                        isCoursePlanningEditClicked={isCoursePlanningEditClicked}
                                                        courseTypeName={courseTypeName}
                                                        setIsCreateCurriculumModalVisible={setIsCreateCurriculumModalVisible}
                                                        setEditSubjectVisible={setEditSubjectVisible}
                                                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                                        setShowEditChapters={setShowEditChapters}
                                                        pageLoader={pageLoader}
                                                        showSkeletonOnCancel={showSkeletonOnCancel}
                                                        handleScroll={handleScroll}
                                                        scrollContainerRef={scrollContainerRef}
                                                        isScrolled={isScrolled}
                                                        setIsCoursePlanningEditClicked={setIsCoursePlanningEditClicked}
                                                        getSpecificCourseData={getSpecificCourseData}
                                                        deleteSubject={deleteSubject}
                                                        addSubjects={addSubjects}
                                                        addSubjectFromOnlineTest={addSubjectFromOnlineTest}
                                                        getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                                                        isExamPattern={isExamPattern}
                                                        setIsExamPatern={setIsExamPatern}
                                                        isSyllabas={isSyllabas}
                                                        setIsSyllabas={setIsSyllabas}
                                                        isTestExpan={isTestExpan}
                                                        setIsTestExpand={setIsTestExpand}
                                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                                    // courseTypeName={courseTypeName}

                                                    /></>) :


                                                <>
                                                    <LiveCourseCurriculum
                                                        isViewDetailsClicked={isViewDetailsClicked}
                                                        specificCourseDataContainer={specificCourseDataContainer}
                                                        isCoursePlanningEditClicked={isCoursePlanningEditClicked}
                                                        courseTypeName={courseTypeName}
                                                        setIsCreateCurriculumModalVisible={setIsCreateCurriculumModalVisible}
                                                        setEditSubjectVisible={setEditSubjectVisible}
                                                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                                        setShowEditChapters={setShowEditChapters}
                                                        pageLoader={pageLoader}
                                                        showSkeletonOnCancel={showSkeletonOnCancel}
                                                        handleScroll={handleScroll}
                                                        scrollContainerRef={scrollContainerRef}
                                                        isScrolled={isScrolled}
                                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                                    />
                                                </>}
                                    </>)}
                                {courseTypeName == 'onlineTestSeries' ?
                                    (<><CourseInfoTest
                                        specificCourseDataContainer={specificCourseDataContainer}
                                        courseTypeName={courseTypeName}
                                        isBatchesExpandClicked={isBatchesExpandClicked}
                                        isViewDetailsClicked={isViewDetailsClicked}
                                        isCoursePlanningEditClicked={isCoursePlanningEditClicked}
                                        setIsCoursePlanningEditClicked={setIsCoursePlanningEditClicked}
                                        isExamPattern={isExamPattern}
                                        setIsExamPatern={setIsExamPatern}
                                        isSyllabas={isSyllabas}
                                        setIsSyllabas={setIsSyllabas}
                                        isTestExpan={isTestExpan}
                                        setIsTestExpand={setIsTestExpand}
                                        setIndividualTestId={setIndividualTestId}
                                        setImportMeta={setImportMeta}
                                        setIsViewDetailsClicked={setIsViewDetailsClicked}
                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                    /></>) : (<></>)}


                            </div>

                            {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' ?
                                <div style={{ width: "30%" }}>

                                    <div style={{ ...newCardStyle, height: '342px' }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Fee details</div>
                                            {hasMarketingPrivilege ?
                                                <></>
                                                :
                                                <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => { setCourseDeleteModal(true) }}><EditOutlined /> EDIT FEE</div>
                                            }
                                        </div>
                                        {!specificCourseDataContainer?.amount ?
                                            <div className='r-c-c m-t-30' style={{ flexDirection: "column" }}>
                                                <img src={NoData} alt='' style={{ width: 150 }} />
                                                <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                                <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                                {/* <div className=' cursor-pointer m-t-20' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginBottom: 125 }} onClick={() => { history.push('/fee-details') }}><PlusCircleOutlined /> ADD FEE DETAILS</div> */}
                                            </div>
                                            :
                                            <div className='scroll-bar-universal' style={{ height: 450, marginTop: 10 }}>
                                                <LiveCourseFeeContent courseTypeName={courseTypeName} />
                                            </div>
                                        }
                                    </div>
                                    <div style={{ ...newCardStyle, height: '342px', marginTop: 20, }}>
                                        <div className='r-c-sb' style={{ marginTop: -5 }}>
                                            <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Students enrolled <span style={{ color: "#AEAEAE" }}>
                                                ({specificCourseDataContainer?.students?.length})
                                            </span></div>
                                            {specificCourseDataContainer?.students?.length == 0 ? <></> :
                                                <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", }} onClick={() => {
                                                    history.push({
                                                        pathname: "/students-enrolled",
                                                        state: {
                                                            courseTypeName: courseTypeName,
                                                            key: "fromCourseStudents"
                                                        }
                                                    })
                                                }}> VIEW ALL </div>}
                                        </div>
                                        {specificCourseDataContainer?.students?.length > 0 ?
                                            <div className='scroll-bar-universal m-t-15' style={{ height: 270, }}>
                                                {specificCourseDataContainer?.students?.map((item, index) => {
                                                    return (
                                                        <div className='sroll-bar-universal' style={{ display: index <= 6 ? 'view' : "none" }}>
                                                            <div className='r-ac' style={{ fontFamily: "roboto" }}>
                                                                <div>
                                                                    <UserAvatar size={45} shape="circle" name={item?.name} image={item?.image == "default.png" ? "" : `${IMG_URL}/${item?.image}`} />
                                                                </div>
                                                                <div className="m-l-10">
                                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#19919", fontSize: 15, width: 200 }}>{item.name}</Typography.Text>
                                                                    <div style={{ color: "#AEAEAE" }}>
                                                                        {item?.rollNo && (
                                                                            <span>Roll.No {item?.rollNo}</span>
                                                                        )}
                                                                        {item?.standardName && (
                                                                            <span> • {item?.standardName}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Divider style={{ margin: "10px 0px" }} />
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            :
                                            <div className='r-c-c m-t-50' style={{ flexDirection: "column" }}>
                                                <img src={NoData} alt='' style={{ width: 100, height: 100 }} />
                                                <div className='m-t-5' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                                <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                courseTypeName == 'onlineTestSeries' ? (<>
                                    <div style={{ width: "30%" }}>

                                        <div style={{ ...newCardStyle, height: '342px' }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Fee details</div>
                                                {hasMarketingPrivilege ? <></> :
                                                    <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => { setCourseDeleteModal(true) }}><EditOutlined /> EDIT FEE</div>
                                                }

                                            </div>
                                            {!specificCourseDataContainer?.testSeriesDetails?.amount ?
                                                <div className='r-c-c m-t-30' style={{ flexDirection: "column" }}>
                                                    <img src={NoData} alt='' style={{ width: 150 }} />
                                                    <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                                    <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                                    {/* <div className=' cursor-pointer m-t-20' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginBottom: 125 }} onClick={() => { history.push('/fee-details') }}><PlusCircleOutlined /> ADD FEE DETAILS</div> */}
                                                </div>
                                                :
                                                <div className='scroll-bar-universal' style={{ height: 450, marginTop: 10 }}>
                                                    <LiveCourseFeeContent courseTypeName={courseTypeName} />
                                                </div>
                                            }
                                        </div>
                                        <div style={{ ...newCardStyle, height: '342px', marginTop: 20, }}>
                                            <div className='r-c-sb' style={{ marginTop: -5 }}>
                                                <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Students enrolled {specificCourseDataContainer?.testSeriesDetails?.userTest?.length == 0 ? <></> : <span style={{ color: "#AEAEAE" }}>
                                                    ({specificCourseDataContainer?.testSeriesDetails?.userTest?.length})
                                                </span>}
                                                </div>
                                                {specificCourseDataContainer?.testSeriesDetails?.userTest?.length == 0 ? <></> :
                                                    <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", }} onClick={() => {
                                                        history.push({
                                                            pathname: hasMarketingPrivilege ? "/students/enrolled" : "/classroom/allstudents",
                                                            state: {
                                                                courseTypeName: courseTypeName,
                                                                key: "fromCourseStudents"
                                                            }
                                                        })
                                                    }}> VIEW ALL </div>}
                                            </div>
                                            {specificCourseDataContainer?.testSeriesDetails?.userTest?.length > 0 ?
                                                <div className='scroll-bar-universal m-t-15' style={{ height: 270, }}>
                                                    {specificCourseDataContainer?.testSeriesDetails?.userTest?.map((item, index) => {
                                                        return (
                                                            <div className='sroll-bar-universal' style={{ display: index <= 6 ? 'view' : "none" }}>
                                                            
                                                                <div className='r-ac' style={{ fontFamily: "roboto" }}>
                                                                    <div>
                                                                        <UserAvatar size={35} shape="circle" name={item?.user?.name} image={item?.user?.image == "default.png" ? "" : `${IMG_URL}/${item?.user?.image}`} />
                                                                    </div>
                                                                    <div className="m-l-10">
                                                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#19919", fontSize: 15, width: 200 }}>{item?.user?.name}</Typography.Text>
                                                                        <div style={{ color: "#AEAEAE" }}>
                                                                            {item?.user?.rollNo && (
                                                                                <span>Roll.No {item?.user?.rollNo}</span>
                                                                            )}
                                                                            {item?.user?.standardName && (
                                                                                <span> • {item?.user?.standardName}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ margin: "10px 0px" }} />
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                                :
                                                <div className='r-c-c m-t-50' style={{ flexDirection: "column" }}>
                                                    <img src={NoData} alt='' style={{ width: 100, height: 100 }} />
                                                    <div className='m-t-5' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                                    <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                                </div>
                                            }
                                        </div>
                                    </div></>) :
                                    <div style={{ width: "30%" }}>
                                        <FeeContentComp
                                            specificCourseDataContainer={specificCourseDataContainer}
                                            isStudentsExpandClicked={isStudentsExpandClicked}
                                            setIsStudentExpandClicked={setIsStudentExpandClicked}
                                            setIsBatchesExpandClicked={setIsBatchesExpandClicked}
                                            isFeeClosed={isFeeClosed}
                                            setIsFeeClosed={setIsFeeClosed}
                                            setWasStudentCardExpanded={setWasStudentCardExpanded}
                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                            setIsOfferDrawerVisible={setIsOfferDrawerVisible}
                                            setSelectedOfferId={setSelectedOfferId}
                                        />
                                        <StudentsEnrolledComp
                                            specificCourseDataContainer={specificCourseDataContainer}
                                            isStudentsExpandClicked={isStudentsExpandClicked}
                                            setIsStudentExpandClicked={setIsStudentExpandClicked}
                                            setIsBatchesExpandClicked={setIsBatchesExpandClicked}
                                            setWasStudentCardExpanded={setWasStudentCardExpanded}
                                        />
                                    </div>}
                        </div>
                        {courseTypeName == 'videoResources' || courseTypeName == 'studyMaterials' || courseTypeName == 'onlineTestSeries' ? (<></>) :
                            <div style={{ ...newCardStyle, marginTop: 20, width: "100%" }}>
                                {isBatchesExpandClicked ?
                                    <BatchesComponent
                                        setIsBatchesExpandClicked={setIsBatchesExpandClicked}
                                        setIsFeeClosed={setIsFeeClosed}
                                        wasStudentCardExpanded={wasStudentCardExpanded}
                                        setIsStudentExpandClicked={setIsStudentExpandClicked}
                                        wasDetailsCardExpanded={wasDetailsCardExpanded}
                                        setIsViewDetailsClicked={setIsViewDetailsClicked}
                                        hasMarketingPrivilege={hasMarketingPrivilege}
                                        specificCourseDataContainer={specificCourseDataContainer}
                                    />
                                    :
                                    <div className='r-c-sb'>
                                        <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Batches</div>
                                        <FiChevronDown style={{ fontSize: 22, color: "#636363", cursor: "pointer" }} onClick={() => { setIsBatchesExpandClicked(true); setIsViewDetailsClicked(false); setIsStudentExpandClicked(false); setIsFeeClosed(true); setIsScrolled(0) }} />
                                    </div>
                                }
                            </div>}
                    </>
                    :
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 20 }}>
                        <div style={{ ...cardstyle, width: "67.5%", padding: "15px 20px 20px 20px", height: "88vh" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>{courseTypeName == "studyMaterials" ? "Study material" : courseTypeName == "videoResources" ? "Video resource" : courseTypeName == "onlineTestSeries" ? "Online test series" : "Live course"} details</div>
                                <div className='cursor-pointer m-t-5' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => {
                                    history.push({
                                        pathname: courseTypeName == "liveCourses" ? "/add-live-courses" : courseTypeName == "videoResources" ? "/add-video-resources" : courseTypeName == "onlineTestSeries" ? "/add-online-test-series" : courseTypeName == "studyMaterials" ? "/add-study-materials" : "/add-live-courses",
                                        state: {
                                            key: {
                                                isEdit: "isFromEdit",
                                                fromCourseDetails: true,
                                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                searchedValue: location?.state?.key?.searchedValue
                                            }
                                        }
                                    })
                                }}><EditOutlined /> EDIT</div>
                            </div>
                            <div className='scroll-bar-universal' style={{ height: "75vh", marginTop: 10 }}>
                                <div style={titleStyle}>{courseTypeName == "studyMaterials" ? "Study material" : courseTypeName == "onlineTestSeries" ? "Online test series" : courseTypeName == "videoResources" ? "Video resource" : "Live course"} name</div>
                                <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", marginTop: 5 }}>{(courseTypeName == "studyMaterials" || courseTypeName == "videoResources") ? specificCourseDataContainer?.name : (courseTypeName == "onlineTestSeries") ? specificCourseDataContainer?.testSeriesDetails?.name : specificCourseDataContainer?.course?.courseName}</div>
                                {courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" ?
                                    <></>
                                    :
                                    <>
                                        <div style={titleStyle}>{courseTypeName == "videoResources" ? 'Video resource' : 'Live course'} brief</div>
                                        <div className='m-t-5' style={{ color: "#191919", fontSize: 14, fontWeight: 700 }}>{courseTypeName == "videoResources" ? specificCourseDataContainer?.brief : specificCourseDataContainer?.course?.brief}</div>
                                    </>
                                }
                                <div style={titleStyle}>Description</div>
                                <div style={descStyle} className='m-t-5'>

                                    {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources") && specificCourseDataContainer?.description ?
                                        <>
                                            <div style={{ fontSize: "14px", color: "black", }}>
                                                {IsJsonString(specificCourseDataContainer?.description) ? !IsJsonString(JSON.parse(specificCourseDataContainer?.description)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.description)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.description)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.description)?.mobile) : specificCourseDataContainer?.description}
                                            </div>
                                        </>
                                        :
                                        courseTypeName == "onlineTestSeries" ?
                                            <>

                                                {IsJsonString(specificCourseDataContainer?.testSeriesDetails?.description) ? !IsJsonString(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.description)?.mobile) : specificCourseDataContainer?.testSeriesDetails?.description}

                                            </>


                                            :
                                            courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.courseDescription ?
                                                <>
                                                    {!IsJsonString(JSON.parse(specificCourseDataContainer?.course?.courseDescription)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.course?.courseDescription)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.course?.courseDescription)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.course?.courseDescription)?.mobile)}
                                                </>
                                                :
                                                "-"}
                                </div>
                                {courseTypeName == 'onlineTestSeries' ? <></> : <div style={titleStyle}>What you'll learn</div>}

                                {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources") && specificCourseDataContainer?.learnings?.length ?
                                    <>
                                        {specificCourseDataContainer?.learnings?.map((item) => (
                                            <div style={descStyle} className='m-t-5'><span style={{ fontSize: 18 }}>• </span>{item?.name}</div>
                                        ))}
                                    </>
                                    :
                                    courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.learnings?.length ?
                                        <>
                                            {specificCourseDataContainer?.course?.learnings?.map((item) => (
                                                <div style={descStyle} className='m-t-5'><span style={{ fontSize: 18 }}>• </span>{item?.name}</div>
                                            ))}
                                        </>
                                        :
                                        (courseTypeName != "onlineTestSeries") ? <div style={descStyle} className='m-t-5'>-</div> : null
                                }
                                {courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" ?
                                    <></>
                                    :
                                    <>
                                        <div style={titleStyle}>Prerequisites</div>
                                        {(courseTypeName == "videoResources" && specificCourseDataContainer?.prerequisites?.length) ?
                                            <>
                                                {specificCourseDataContainer?.prerequisites?.map((item) => (
                                                    <div style={descStyle} className='m-t-5'><span style={{ fontSize: 18 }}>• </span>{item?.name}</div>
                                                ))}
                                            </>
                                            :
                                            courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.prerequisites?.length ?
                                                <>
                                                    {specificCourseDataContainer?.course?.prerequisites?.map((item) => (
                                                        <div style={descStyle} className='m-t-5'><span style={{ fontSize: 18 }}>• </span>{item?.name}</div>
                                                    ))}
                                                </>
                                                :
                                                <div style={descStyle} className='m-t-5'>-</div>
                                        }
                                    </>
                                }
                                {courseTypeName == "studyMaterials" || courseTypeName == "liveCourses" || courseTypeName == "onlineTestSeries" ?
                                    <></>
                                    :
                                    <>
                                        <div style={titleStyle}>This course includes:</div>
                                        {(courseTypeName == "videoResources" && specificCourseDataContainer?.details?.length) ?
                                            <>
                                                {specificCourseDataContainer?.details?.map((item) => (
                                                    <div style={descStyle} className='m-t-5'><span style={{ fontSize: 18 }}>• </span>{item?.name}</div>
                                                ))}
                                            </>
                                            :
                                            courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.details?.length ?
                                                <>
                                                    {specificCourseDataContainer?.course?.details?.map((item) => (
                                                        <div style={descStyle} className='m-t-5'><span style={{ fontSize: 18 }}>• </span>{item?.name}</div>
                                                    ))}
                                                </>
                                                :
                                                <div style={descStyle} className='m-t-5'>-</div>
                                        }
                                    </>
                                }
                                <div style={titleStyle}>Tags</div>
                                {selectedTags?.length > 0 ?
                                    <div className="flex-wrap m-t-10">
                                        {selectedTags?.map((tag, index) =>
                                            <Tag
                                                key={tag.id}
                                                style={{
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#191919',
                                                    padding: "5px 10px",
                                                    borderRadius: 20,
                                                    border: "1px solid #E6E6E6",
                                                    fontSize: 14
                                                }}
                                            >
                                                {tag?.value}
                                            </Tag>
                                        )}
                                    </div>
                                    :
                                    <div>-</div>
                                }
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold", marginTop: 20 }}>{courseTypeName == "studyMaterials" ? "Resources" : courseTypeName == "videoResources" ? "Course content" : courseTypeName == "onlineTestSeries" ? "Syllabus" : "Curriculum"}</div>
                                    {courseTypeName == "videoResources" ? <div style={{ fontSize: 18, fontWeight: "bold", color: "#594099", marginTop: 20 }}></div> : <></>}
                                </div>
                                {courseTypeName == "studyMaterials" && specificCourseDataContainer?.topics?.length != 0 ?
                                    <div style={{ margin: "20px 20px 20px 0px" }}>
                                        {specificCourseDataContainer?.topics?.map((item, index) => (
                                            <StudyMaterialTopicsContent
                                                item={item} index={index} />
                                        ))}
                                    </div>
                                    :
                                    courseTypeName == "liveCourses" && specificCourseDataContainer?.course?.subjects?.length != 0 ?
                                        <div className="r-ac flex-wrap">
                                            {specificCourseDataContainer?.course?.subjects?.map((item, index) => (
                                                <SingleSubjectCard
                                                    item={item}
                                                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                                    setEditSubjectVisible={setEditSubjectVisible}
                                                    setShowEditChapters={setShowEditChapters} />
                                            )
                                            )}
                                            {hasMarketingPrivilege ?
                                                <></>
                                                :
                                                <AddSubjectButton
                                                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                                    history={history}
                                                    setEditSubjectVisible={setEditSubjectVisible}
                                                    kii={"fromCourse"}
                                                />
                                            }

                                        </div>
                                        :
                                        courseTypeName == "videoResources" && specificCourseDataContainer?.sections?.length != 0 ?
                                            <div style={{ marginTop: "20px" }}>
                                                <Collapse
                                                    accordion
                                                    size='small'
                                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ marginTop: "-10px" }} />}
                                                >
                                                    {specificCourseDataContainer?.sections?.map((item) => (
                                                        <Panel
                                                            header={
                                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                                                    <div style={{ fontSize: "16px", fontFamily: "Roboto", fontWeight: "bold" }}>{item.name}</div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", gap: 20 }}>
                                                                        <div style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "Roboto", }}>{item?.topics?.length}{" "}{" "}<h style={{ marginRight: "15px" }}>lectures</h></div>
                                                                        <div style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "Roboto", }}>{item?.totalDuration}• mins </div>
                                                                    </div>
                                                                </div>}
                                                            key={item.key}
                                                        >
                                                            {item?.topics?.map((item) => (
                                                                <div key={item.key}>
                                                                  
                                                                    <div style={{ display: 'flex', padding: 5, justifyContent: "space-between" }}>
                                                                        <div style={{ display: "flex" }}>
                                                                            <div style={{ marginLeft: "20px" }}>
                                                                                {item?.type == 'video' || item?.videoType == 'recorded' ?
                                                                                    <img style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} src={video} alt="Video" />
                                                                                    :
                                                                                    item?.type == 'notes' ?
                                                                                        <FilePdfOutlined style={{ color: "#FF414D", marginLeft: 10, fontSize: 20, marginTop: "5px" }} />
                                                                                        :
                                                                                        (<>
                                                                                            <img src={quiz} style={{ width: "25px", height: "25px", marginLeft: "10px", marginTop: "5px" }} />

                                                                                        </>)

                                                                                }

                                                                            </div>
                                                                            <div style={{ fontSize: "14px", fontWeight: 700, marginLeft: "12px", marginTop: "3px" }}>
                                                                                {item?.name}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ color: "#AEAEAE", fontSize: "14px" }}>
                                                                            {item?.videoType == 'recorded' || item?.type == 'notes' || item?.type == 'quiz' || item?.videoType == 'link' ?
                                                                                null : (<> {item?.duration}</>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Panel>
                                                    ))}
                                                </Collapse>

                                            </div>
                                            :
                                            courseTypeName == "onlineTestSeries" && specificCourseDataContainer?.subjectTestSeries?.length != 0 ? <div className="r-ac flex-wrap">
                                                {specificCourseDataContainer?.testSeriesDetails?.subjectTestSeries?.map((item, index) => (

                                                    <SingleSubjectCard
                                                        item={item}
                                                        subject={item?.subject}
                                                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                                        setEditSubjectVisible={setEditSubjectVisible}
                                                        setShowEditChapters={setShowEditChapters}
                                                        courseTypeName={courseTypeName} />

                                                )
                                                )}
                                                {hasMarketingPrivilege ?
                                                    <></>
                                                    :
                                                    <AddSubjectButton
                                                        setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                                        history={history}
                                                        setEditSubjectVisible={setEditSubjectVisible}
                                                        kii={"fromTestSeries"}
                                                    />
                                                }

                                            </div>
                                                :
                                                <div className='r-c-c' style={{ flexDirection: "column", marginTop: 30 }}>
                                                    <img src={NoData} alt='' style={{ width: 100, height: 100 }} />
                                                    {hasMarketingPrivilege ? <></>
                                                        :
                                                        <div className='m-t-5' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>Add {courseTypeName == "studyMaterials" ? "study materials" : courseTypeName == "videoResources" ? "video resources" : "subjects"}</div>
                                                    }
                                                    {
                                                        hasMarketingPrivilege ? <></> :
                                                            (courseTypeName == "videoResources" ? <></> : <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>If you like you can browse from our resources or create manually</center>)
                                                    }

                                                    {
                                                        hasMarketingPrivilege ? <></> :
                                                            <div className='r-c-se m-t-20 m-b-10' style={{ width: "60%" }}>
                                                                <Button onClick={() => {
                                                                    if (courseTypeName == "videoResources") {
                                                                        history.push('/add-video-resources-content')
                                                                    } else if (courseTypeName == "studyMaterials") {
                                                                        history.push("/add-study-materials-content")
                                                                    } else {
                                                                        setCreateSubjectModalVisible(true); setEditSubjectVisible(false)
                                                                    }
                                                                }}>
                                                                    {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources") ? "ADD" : "CREATE"}</Button>
                                                                {courseTypeName == "videoResources" || courseTypeName == "studyMaterials" ?
                                                                    <></>
                                                                    :
                                                                    <Button type="primary" onClick={() => {
                                                                        if (courseTypeName == "studyMaterials") {

                                                                        } else {
                                                                            history.push({ pathname: "/classroom/resources", state: { key: "fromCourse" } })
                                                                        }
                                                                    }}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
                                                                }
                                                            </div>
                                                    }

                                                </div>
                                }

                                {courseTypeName == "onlineTestSeries" ?
                                    <>
                                        <div>
                                            <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", margin: "20px 0px" }}>Exam Pattern</div>
                                            <div style={{ width: '100%', height: 'fit-content', border: specificCourseDataContainer?.testSeriesDetails?.examPattern ? '1px solid #d9d9d9' : 'none', display: 'flex', justifyContent: 'center', padding: 10 }}>
                                                <div style={{ width: '80%', height: '80%' }}>
                                                    {IsJsonString(specificCourseDataContainer?.testSeriesDetails?.examPattern) ? !IsJsonString(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.examPattern)?.mobile) && isHTML(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.examPattern)?.mobile) ? HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.examPattern)?.mobile) : HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.examPattern)?.mobile) : specificCourseDataContainer?.testSeriesDetails?.examPattern}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : <></>}
                                {courseTypeName == "onlineTestSeries" ?
                                    <div style={{ color: "#191919", fontSize: 16, fontWeight: "bold", margin: "20px 0px" }}>Tests</div>
                                    : <></>}
                                {
                                    courseTypeName == "onlineTestSeries" ?
                                        <div style={{ width: '100%', height: 'fit-content', backgroundColor: '#fff' }}>
                                            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                                {specificCourseDataContainer?.testData?.length != 0 ?
                                                    specificCourseDataContainer?.testData?.map((item) => {

                                                        return (

                                                            <div style={{ backgroundColor: "#fff", width: '48%', height: '130px', marginBottom: 30, border: '1px solid #E6E6E6', borderRadius: '17px' }}>
                                                                <div style={{ cursor: 'pointer' }}

                                                                    onClick={() => {
                                                                        setIndividualTestId(item.id); history.push({
                                                                            pathname: "/testSeries/createTest",
                                                                            state: {
                                                                                key: {
                                                                                    isEdit: "isFromEdit",
                                                                                    fromCourseInfoPage: true
                                                                                }
                                                                            }
                                                                        })
                                                                    }}>
                                                                    <Tooltip title={item?.title} placement="top">
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                width: '60%',
                                                                                fontWeight: 'bold',
                                                                                marginTop: 20,
                                                                                fontSize: 17,
                                                                                marginLeft: 15,
                                                                                overflow: 'hidden',
                                                                                whiteSpace: 'nowrap',
                                                                                textOverflow: 'ellipsis',
                                                                            }}
                                                                        >
                                                                            {item?.title}
                                                                        </span>
                                                                    </Tooltip>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                                                            <div style={{ color: '#636363', marginTop: 10, fontSize: 13 }}> <QuestionCircleOutlined /> <h style={{ marginLeft: 5 }}>{item?.totalQuestions} Questions</h></div>
                                                                            <div style={{ color: '#636363', fontSize: 13 }}> <ProfileOutlined /><h style={{ marginLeft: 9 }}>{item?.totalMarks} Marks</h></div>
                                                                            <div style={{ color: '#636363', fontSize: 13 }}><ClockCircleOutlined /><h style={{ marginLeft: 9 }}>{item?.duration} Minutes</h></div>
                                                                        </div>
                                                                        <div style={{ width: '60%' }}>
                                                                            {item?.publishDate && item?.totalQuestions > 0 ?
                                                                                <div style={{ fontSize: 10, color: '#594099', marginLeft: '20px', marginTop: '51px' }}>Publishing on {moment(item?.publishDate).format('DD-MM-YYYY')}, {moment(item?.startTime, 'HH:mm').format('hh:mm A')}</div>
                                                                                :
                                                                                item?.totalQuestions === 0 ?
                                                                                    <div style={{ fontSize: 10, color: 'red', marginLeft: '20px', marginTop: '51px' }}>Test creation in progress</div>
                                                                                    : <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div style={{ width: '100%', height: 'fit-content', backgroundColor: '#fff', padding: 30 }}>
                                                        <NodataCard />
                                                        {
                                                            hasMarketingPrivilege ? <></> :
                                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '32%' }}>
                                                                    <Button onClick={() => { setIndividualTestId(null); history.push({ pathname: "/testSeries/createTest" }) }} style={{ backgroundColor: "#FFFFFF", marginRight: '20px' }}>ADD</Button>
                                                                    <Button
                                                                        type="primary"
                                                                        onClick={() => {
                                                                            setImportMeta("selectedQBType", "sample question papers");
                                                                            setImportMeta('selectedTypeId', 1);
                                                                            history.push({
                                                                                pathname: '/test-import-classes',
                                                                                state: {
                                                                                    testSeriesData: true
                                                                                }
                                                                            })
                                                                        }}
                                                                    ><SearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
                                                                </div>
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    
                        {courseTypeName == 'onlineTestSeries' ?
                            <div style={{ width: "30%" }}>

                                <div style={{ ...newCardStyle, height: '342px' }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Fee details</div>
                                        {hasMarketingPrivilege ? <></> :
                                            <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => { setCourseDeleteModal(true) }}><EditOutlined /> EDIT FEE</div>
                                        }

                                    </div>
                                    {!specificCourseDataContainer?.testSeriesDetails?.amount ?
                                        <div className='r-c-c m-t-30' style={{ flexDirection: "column" }}>
                                            <img src={NoData} alt='' style={{ width: 150 }} />
                                            <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                            <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                            {/* <div className=' cursor-pointer m-t-20' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginBottom: 125 }} onClick={() => { history.push('/fee-details') }}><PlusCircleOutlined /> ADD FEE DETAILS</div> */}
                                        </div>
                                        :
                                        <div className='scroll-bar-universal' style={{ height: 450, marginTop: 10 }}>
                                            <LiveCourseFeeContent courseTypeName={courseTypeName} />
                                        </div>
                                    }
                                </div>
                                <div style={{ ...newCardStyle, height: '342px', marginTop: 20, }}>
                                    <div className='r-c-sb' style={{ marginTop: -5 }}>
                                        <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Students enrolled {specificCourseDataContainer?.testSeriesDetails?.userTest?.length == 0 ? <></> : <span style={{ color: "#AEAEAE" }}>
                                            ({specificCourseDataContainer?.testSeriesDetails?.userTest?.length})
                                        </span>}
                                        </div>
                                        {specificCourseDataContainer?.testSeriesDetails?.userTest?.length == 0 ? <></> :
                                            <div className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", }} onClick={() => {
                                                history.push({
                                                    pathname: hasMarketingPrivilege ? "/students/enrolled" : "/classroom/allstudents",
                                                    state: {
                                                        courseTypeName: courseTypeName,
                                                        key: "fromCourseStudents"
                                                    }
                                                })
                                            }}> VIEW ALL </div>}
                                    </div>
                                    {specificCourseDataContainer?.testSeriesDetails?.userTest?.length > 0 ?
                                        <div className='scroll-bar-universal m-t-15' style={{ height: 270, }}>
                                            {specificCourseDataContainer?.testSeriesDetails?.userTest?.map((item, index) => {
                                                return (
                                                    <div className='sroll-bar-universal' style={{ display: index <= 6 ? 'view' : "none" }}>
                                                       
                                                        <div className='r-ac' style={{ fontFamily: "roboto" }}>
                                                            <div>
                                                                <UserAvatar size={35} shape="circle" name={item?.user?.name} image={item?.user?.image == "default.png" ? "" : `${IMG_URL}/${item?.user?.image}`} />
                                                            </div>
                                                            <div className="m-l-10">
                                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#19919", fontSize: 15, width: 200 }}>{item?.user?.name}</Typography.Text>
                                                                <div style={{ color: "#AEAEAE" }}>
                                                                    {item?.user?.rollNo && (
                                                                        <span>Roll.No {item?.user?.rollNo}</span>
                                                                    )}
                                                                    {item?.user?.standardName && (
                                                                        <span> • {item?.user?.standardName}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Divider style={{ margin: "10px 0px" }} />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        :
                                        <div className='r-c-c m-t-50' style={{ flexDirection: "column" }}>
                                            <img src={NoData} alt='' style={{ width: 100, height: 100 }} />
                                            <div className='m-t-5' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                            <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div style={{ width: "30%" }}>
                                <FeeContentComp
                                    specificCourseDataContainer={specificCourseDataContainer}
                                    isStudentsExpandClicked={isStudentsExpandClicked}
                                    setIsStudentExpandClicked={setIsStudentExpandClicked}
                                    setIsBatchesExpandClicked={setIsBatchesExpandClicked}
                                    isFeeClosed={isFeeClosed}
                                    setIsFeeClosed={setIsFeeClosed}
                                    setWasStudentCardExpanded={setWasStudentCardExpanded}
                                    hasMarketingPrivilege={hasMarketingPrivilege}
                                    setIsOfferDrawerVisible={setIsOfferDrawerVisible}
                                    setSelectedOfferId={setSelectedOfferId}
                                />
                                <StudentsEnrolledComp
                                    specificCourseDataContainer={specificCourseDataContainer}
                                    isStudentsExpandClicked={isStudentsExpandClicked}
                                    setIsStudentExpandClicked={setIsStudentExpandClicked}
                                    setIsBatchesExpandClicked={setIsBatchesExpandClicked}
                                    setWasStudentCardExpanded={setWasStudentCardExpanded}
                                />
                            </div>}
                        
                    </div>
                }
            </div>
            {isCreateSubjectModalVisible &&
                <CreateSubjectsModal
                    isCreateSubjectModalVisible={isCreateSubjectModalVisible}
                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                    addSubjects={addSubjects}
                    addSubjectFromOnlineTest={addSubjectFromOnlineTest}
                    togglePageLoader={togglePageLoader}
                    getSpecificCourseData={getSpecificCourseData}
                    getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                    isEditSubjectVisible={isEditSubjectVisible}
                    showEditChapters={showEditChapters}
                    setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                    setIsFromSubjects={setIsFromSubjects}
                    fromClassroom={false}
                    courseTypeName={courseTypeName}
                    hasMarketingPrivilege={hasMarketingPrivilege}
                />
            }
            {isCreateCurriculumModalVisible &&
                <CreateCurriculumModal
                    isCreateCurriculumModalVisible={isCreateCurriculumModalVisible}
                    setIsCreateCurriculumModalVisible={setIsCreateCurriculumModalVisible}
                    togglePageLoader={togglePageLoader}
                    getSpecificCourseData={getSpecificCourseData}
                    postCreateCurriculum={postCreateCurriculum}
                />
            }
            {isConfirmDeleteModalVisible &&
                <CourseDeleteConfirmModal
                    isConfirmDeleteModalVisible={isConfirmDeleteModalVisible}
                    setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                    togglePageLoader={togglePageLoader}
                    isFromSubjects={isFromSubjects}
                    showEditChapters={showEditChapters}
                    deleteSubject={deleteSubject}
                    getSpecificCourseData={getSpecificCourseData}
                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                    courseTypeName={courseTypeName}
                />
            }
            {isFeeDetailsModalVisible &&
                <FeeDetailsModal
                    isFeeDetailsModalVisible={isFeeDetailsModalVisible}
                    setFeeDetailsModalVisible={setFeeDetailsModalVisible}
                    togglePageLoader={togglePageLoader}
                    getSpecificCourseData={getSpecificCourseData}
                    getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                    courseTypeName={courseTypeName}
                    getSpecificSMData={getSpecificSMData}
                    getSpecificVideoCourseData={getSpecificVideoCourseData}
                    specificCourseDataContainer={specificCourseDataContainer}
                />
            }
            {isAddStudentsToCourseModalVisible &&
                <AddStudentsToCourse
                    isAddStudentsToCourseModalVisible={isAddStudentsToCourseModalVisible}
                    setAddStudentsToCourseModalVisible={setAddStudentsToCourseModalVisible}
                    togglePageLoader={togglePageLoader}
                    getSpecificCourseData={getSpecificCourseData}
                />}
            {isDeleteModalVisible &&
                <DeleteModal
                    isDeleteModalVisible={isDeleteModalVisible}
                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                    togglePageLoader={togglePageLoader}
                    fromCourseDetailsPage={true}
                    specificCourseDataContainer={specificCourseDataContainer}
                    courseTypeName={courseTypeName}
                    fromCourseCard={location?.state?.key?.fromCourseCard}
                    fromSearchResult={location?.state?.key?.isSearchedSuggestionClicked}
                    searchedValue={location?.state?.key?.searchedValue}
                    fromDashboardSearch={location?.state?.key?.fromDashboard}
                />
            }
            {isConfirmPublishModalVisible &&
                <ConfirmPublishOrUnpublishModal
                    isConfirmPublishModalVisible={isConfirmPublishModalVisible}
                    setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                    togglePageLoader={togglePageLoader}
                    fromCourseDetailsPage={true}
                    specificCourseDataContainer={specificCourseDataContainer}
                    courseTypeName={courseTypeName}
                    publish={publish}
                    getSpecificCourseData={getSpecificCourseData}
                    getSpecificSMData={getSpecificSMData}
                    getSpecificVideoCourseData={getSpecificVideoCourseData}
                    getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                />}
            {isVideoPreview &&
                <PreviewCourse
                    isVideoPreview={isVideoPreview}
                    setVideoPreview={setVideoPreview}
                    previewLink={previewLink}
                    courseName={specificCourseDataContainer?.course ? specificCourseDataContainer?.course : specificCourseDataContainer}
                    videoType={specificCourseDataContainer?.course?.previewType ? specificCourseDataContainer?.course?.previewType : specificCourseDataContainer?.previewType}
                    adminDetailsPage={true}
                    courseTypeName={courseTypeName}
                />
            }
            {isConfirmModalVisible &&
                <ConfirmationModal
                    isConfirmModalVisible={isConfirmModalVisible}
                    setIsConfirmModalVisible={setIsConfirmModalVisible}
                    postToggleCourseAdmissions={postToggleCourseAdmissions}
                    togglePageLoader={togglePageLoader}
                    getSpecificCourseData={getSpecificCourseData}
                    admissionState={specificCourseDataContainer?.course?.disableAdmission}
                />}
            {isCourseDeleteModal &&
                <CourseEditFeeModal
                    chooseModal={isCourseDeleteModal}
                    setChooseModal={setCourseDeleteModal} />}
            {isOfferDrawerVisible ? (
                <EditOfferDrawer
                    handleClose={() => { setIsOfferDrawerVisible(false) }}
                    open={isOfferDrawerVisible}
                    courseDetails={{
                        id: specificCourseDataContainer?.course?.id || specificCourseDataContainer?.id || specificCourseDataContainer?.testSeriesDetails?.id,
                        name: specificCourseDataContainer?.course?.courseName || specificCourseDataContainer?.course?.name || specificCourseDataContainer?.name || specificCourseDataContainer?.testSeriesDetails?.name,
                        type: courseTypeName == "liveCourses" ? "course" : courseTypeName == "videoResources" ? "videoResource" : courseTypeName == "studyMaterials" ? "studyMaterial" : courseTypeName == "onlineTestSeries" ? "testSeries" : null,
                    }}
                    offerId={selectedOfferId || null}
                    togglePageLoader={togglePageLoader}
                    handleSave={() => {
                        const getFunction = courseTypeName == "liveCourses" ? getSpecificCourseData : courseTypeName == "videoResources" ? getSpecificVideoCourseData : courseTypeName == 'studyMaterials' ? getSpecificSMData : getOnlineTestSeriesCourseDetails;
                        togglePageLoader(true);
                        getFunction(() => {
                            setIsOfferDrawerVisible(false);
                            togglePageLoader(false);
                        });
                    }}
                >
                </EditOfferDrawer>) : null}
            {isOfferDeleteModalVisible ? <DeleteModalOffer
                chooseModal={isOfferDeleteModalVisible}
                setChooseModal={setIsOfferDeleteModalVisible}
                deleteOffer={deleteOffer}
                getSpecificCourseData={getSpecificCourseData}
                selectedOfferId={selectedOfferId}
                togglePageLoader={togglePageLoader}
                getSpecificVideoCourseData={getSpecificVideoCourseData}
                getSpecificSMData={getSpecificSMData}
                getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                courseTypeName={courseTypeName} /> : null }
            {isOfferPublishModalVisible ? <PublishModal
                chooseModal={isOfferPublishModalVisible}
                setChooseModal={setIsOfferPublishModalVisible}
                patchPublishOffers={patchPublishOffers}
                getSpecificCourseData={getSpecificCourseData}
                selectedOfferId={selectedOfferId}
                isPublish={isOfferPublish}
                togglePageLoader={togglePageLoader}
                getSpecificVideoCourseData={getSpecificVideoCourseData}
                getSpecificSMData={getSpecificSMData}
                getOnlineTestSeriesCourseDetails={getOnlineTestSeriesCourseDetails}
                courseTypeName={courseTypeName} /> : null}
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { specificCourseDataContainer, courseTypeName, totalTagsData, newCourseId, isFromCourseDetailsReducer, courseCreateObj } = state.courseManagement;
    const { user } = state.session;
    return {
        specificCourseDataContainer, courseTypeName, totalTagsData, newCourseId, isFromCourseDetailsReducer, courseCreateObj, user
    };
};
const mapDispatchToProps = (dispatch) => ({
    getSpecificCourseData: (callback) => dispatch(getSpecificCourseData(callback)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    addSubjects: (subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack) => dispatch(addSubjects(subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack)),
    addSubjectFromOnlineTest: (subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack) => dispatch(addSubjectFromOnlineTest(subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack)),
    deleteSubject: (subjectId, callback, successCallBack) => dispatch(deleteSubject(subjectId, callback, successCallBack)),
    getSpecificVideoCourseData: (callback) => dispatch(getSpecificVideoCourseData(callback)),
    getOnlineTestSeriesCourseDetails: (callBack) => dispatch(getOnlineTestSeriesCourseDetails(callBack)),
    setIndividualTestId: (callback) => dispatch(setIndividualTestId(callback)),
    setOnlineTestSeriesId: (callback) => dispatch(setOnlineTestSeriesId(callback)),
    setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
    getCoursePlanningData: (callback) => dispatch(getCoursePlanningData(callback)),
    postCoursePlanningData: (params, callback, succesCallback) => dispatch(postCoursePlanningData(params, callback, succesCallback)),
    getAllClasses: (id) => dispatch(getAllClasses(id)),
    postCreateCurriculum: (params, fromClassroom, callback, succesCallback) => dispatch(postCreateCurriculum(params, fromClassroom, callback, succesCallback)),
    postToggleCourseAdmissions: (disableAdmission, callback, succesCallback) => dispatch(postToggleCourseAdmissions(disableAdmission, callback, succesCallback)),
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    postExamPaterrn: (examPattern, callBack, succesCallback) => dispatch(postExamPaterrn(examPattern, callBack, succesCallback)),
    setIsFromAdminPreview: (val) => dispatch(setIsFromAdminPreview(val)),
    deleteOffer: (id, callBack, successCallBack) => dispatch(deleteOffer(id, callBack, successCallBack)),
    patchPublishOffers: (id, publish, callBack, successCallBack) => dispatch(patchPublishOffers(id, publish, callBack, successCallBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseInformationPage);
// export default CourseInformationPage