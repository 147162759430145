import React, { useEffect, useState } from 'react';
import "./styles.css";
import { Button } from 'components/Button';
import ReactQuill from 'react-quill';
import HtmlParser from 'react-html-parser';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { CheckCircleOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';

export const newCardStyle = {
    backgroundColor: "#FFFFFF",
    padding: "24px",
    boxShadow: "4px 4px 16px #0000000F",
    borderRadius: "17px",
    width: "100%"
}
export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
    ],
}
function TestExamPattern({ isCoursePlanningEditClicked, setIsCoursePlanningEditClicked, specificCourseDataContainer, courseCreateObj, setCreateCourseObj, isExamPattern, setIsExamPatern, isSyllabas, setIsSyllabas, setIsTestExpand, postExamPaterrn, newCourseId, getOnlineTestSeriesCourseDetails, hasMarketingPrivilege }) {

    const [examPattern, setExamPatern] = useState(null)
    const [loader,setLoader] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [create, setCreate] = useState(false)

    useEffect(() => {
        setExamPatern(specificCourseDataContainer?.testSeriesDetails?.examPattern)
    }, [specificCourseDataContainer?.testSeriesDetails])
    let quillExam = null
    var details = {}

    const handleOnlineTestDescription = (content, delta, source, editor) => {
        if (source == 'user') {
            details = {
                mobile: quillExam.getEditorContents(),
                web: quillExam.editor.getContents()
            }
            setExamPatern(JSON.stringify(details))
        }
    }
    const handleOnlineTestDescriptionkeyUp = (e) => {
        if (quillExam.getEditorContents() != '<p><br></p>' && quillExam.editor.getText().trim() != '') {
            details = {
                mobile: quillExam.getEditorContents(),
                web: quillExam.editor.getContents()
            }
            setExamPatern(JSON.stringify(details))
        }
        else {
            setExamPatern('')
        }
    }
    const examPatternData = {
        id: newCourseId,
        examPattern: examPattern
    }
    return (
        <div style={{ ...newCardStyle, marginTop: 20, height: isExamPattern ? '320px' : 76, transition: "height 0.2s ease" }} className='course-planning-card'>
            {isExamPattern ?
                <>
                    <div className='r-c-sb'>
                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Exam pattern</div>
                        <div className='r-c-sb'>
                            {
                                (hasMarketingPrivilege ? <></> : create ?
                                    <Button loading={loader} type='link' className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginRight: 20 }}
                                        onClick={() => {
                                            setLoader(true)
                                            postExamPaterrn(examPatternData, () => { setLoader(false) }, () => { setCreate(false); getOnlineTestSeriesCourseDetails(() => { }) })
                                        }}> <CheckCircleOutlined /><span style={{ marginLeft: "5px" }}> SAVE</span></Button> : specificCourseDataContainer?.testSeriesDetails?.examPattern?.length != 0 ?
                                        <Button type='link' className='cursor-pointer' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", marginRight: 20 }}
                                            onClick={() => { setCreate(true) }}><EditOutlined /> <span style={{ marginLeft: "5px" }}>EDIT</span></Button>
                                        : (<></>)
                                )}

                            <Button
                                type="link"
                                style={{ fontSize: 14, color: '#636363' }}
                                onClick={() => {
                                    setIsCoursePlanningEditClicked(false);
                                    setIsExamPatern(false)
                                    setCreate(false)
                                    setIsSyllabas(false)
                                    setIsTestExpand(true)
                                }}
                            ><FiChevronUp style={{ fontSize: 18, marginLeft: 5 }} /></Button>
                        </div>
                    </div>
                    {(specificCourseDataContainer?.testSeriesDetails?.examPattern?.length != 0 && !create) ?
                        <div className=' m-t-10 '>
                            <div className='scroll-bar-universal' style={{ height: "250px" }}>
                                {IsJsonString(specificCourseDataContainer?.testSeriesDetails?.examPattern) ? HtmlParser(JSON.parse(specificCourseDataContainer?.testSeriesDetails?.examPattern)?.mobile) : specificCourseDataContainer?.testSeriesDetails?.examPattern}
                            </div>
                        </div> :
                        <div className=' m-t-10 scroll-bar-universal' style={{ height: "240px" }} >
                            {specificCourseDataContainer?.testSeriesDetails?.examPattern != 0 || create ? (<></>) :
                                <div className="r-c-c-c" style={{ height: 'fit-content' }}>
                                    <img
                                        style={{ width: "25%", height: "25%" }}
                                        src={require("../../../Assets/test/no-subjects.svg").default}
                                        alt=""
                                    />
                                    <div className="r-c-c-c">
                                        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>Exam pattern not added</h1>
                                        {hasMarketingPrivilege ? <></> :
                                            <p style={{ color: "#636363", fontFamily: "roboto" }}>
                                                You can create an exam pattern to help your students understand more about this test in details.
                                            </p>
                                        }

                                    </div>
                                    {
                                        hasMarketingPrivilege ? <></> :
                                        <Button type='' style={{ border: "1px solid #1089FF", color: "#1089FF" }} onClick={() => { setCreate(true) }}><PlusCircleOutlined /><span style={{ marginLeft: "5px" }}></span> CREATE</Button>
                                    }
                                    
                                </div>}

                            {create ?
                                <ReactQuill
                                    className='annocnementDescription link-div'
                                    theme='snow'
                                    style={{ height: '160px', width: "100%" }}
                                    modules={modules}
                                    value={examPattern ? JSON.parse(examPattern).mobile : ''}
                                    onChange={(content, delta, source, editor) => handleOnlineTestDescription(content, delta, source, editor)}
                                    ref={el => quillExam = el}
                                    placeholder="Add description"
                                    bounds={'.annocnementDescription'}
                                    onKeyUp={(e) => handleOnlineTestDescriptionkeyUp(e)}
                                /> : (<></>)}
                        </div>}
                </> :
                <div className='r-c-sb'>
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Exam pattern</div>
                    {/* <div style={{ color: "#636363", fontWeight: "normal" }}>Classes scheduled </div> */}

                    <div className='cursor-pointer show-course-planning-edit' style={{ color: '#636363', fontSize: 14, fontWeight: "bold" }} onClick={() => {
                        setIsCoursePlanningEditClicked(false);
                        setIsExamPatern(true)
                        setIsSyllabas(false)
                        setIsTestExpand(false)
                        setCreate(false)

                    }}>   <FiChevronDown style={{ fontSize: 18, marginLeft: 5 }} />
                    </div>
                </div>
            }
        </div >
    )
}

export default TestExamPattern