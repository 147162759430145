import {
  BellOutlined,
  CheckCircleOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  MoreOutlined,
  ReloadOutlined,
  SwapOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  refundedApi,
  sendReminder,
  setFeeDetails,
  setInvoiceFile,
} from "actions/feeManagement";
import { Button, Popover, Typography } from "antd";
import PageDataLoader from "components/pageDataLoader";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import OfflinePayReq from "../feeManagement/markAsPaidModal";
import SendReminderModal from "../feeManagement/sendReminderModal";
import AddDetailsModal from "../feeManagement/addDetailsModal";
import ConfirmationModal from "../feeManagement/confirmationModal";
import ViewTransactionDetailsModal from "../feeManagement/viewTransactionDetailsModal";
import PaymentRequestModal from "./paymentRequestModal";
import EditOfflinePayment from "./editOfflinePayment";
import EidtScholerShipAddModal from "./eidtScholerShipAddModal";
import { setCourseDetails } from "actions/userManagement";

function FeeCourseCard({
  item,
  amount,
  dueDate,
  issueDate,
  status,
  payment_type,
  instaCard,
  setFeeDetails,
  index,
  refundedApi,
  setInvoiceFile,
  payment_method,
  sendReminder,
  setCourseDetails,
}) {
  const [showTansDtls, setShowTansDtls] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
  const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
  // const [isAdditionModal, setIsAdditionModal] = useState(false);
  const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
  const [showSendReminder, setShowSendReminder] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showEditOffline, setShowEditOffline] = useState(false);
  const [showEditSA, setShowEditSA] = useState(false);
  const [isAdditionModal, setIsAdditionModal] = useState(false);

  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }

  const history = useHistory();
  const contentNotRequested = (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ color: "#636363" }}
    >
      <div
        style={{ cursor: item?.kycStatus != 'Approved' ? "not-allowed" : "pointer", margin: "3px" }}

        onClick={(e) => {
          e.stopPropagation();
          if (item?.kycStatus == "Approved") {
            setShowRequestModal(true);
          }
        }}
      >
        <SwapOutlined style={{ marginRight: "10px" }} />
        Request Payment
      </div>
      <div
        onClick={(e) => {
          setShowOfflinePayReqModal(true);
          e.stopPropagation();
          setFeeDetails("selectedSingleTransaction", item);
        }}
        style={{ cursor: "pointer", marginBottom: "4px" }}
      >
        <CheckCircleOutlined style={{ marginRight: "5px", fontSize: "15px" }} />
        Mark as paid offline
      </div>
      <div
        style={{ cursor: "pointer", margin: "3px" }}
        onClick={(e) => {
          e.stopPropagation();
          setFeeDetails("selectedTransaction", item);
          // deleteTransaction()
          setShowConfirmationModal(true);
        }}
      >
        <DeleteFilled style={{ marginRight: "10px" }} />
        Delete
      </div>
    </div>
  );
  const content = (
    <div>
      {item?.payment_type == "online" && item?.status == "Paid" ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ color: "#AEAEAE" }}
        >
          <p
            onClick={(e) => {
              e.stopPropagation();
              setFeeDetails("selectedTransaction", item);
              setInvoiceFile(item?.invoice);
              history.push("/fee-management/invoice");
            }}
            style={{
              marginBottom: "15px",
              marginTop: "10px",
              cursor: "pointer",
            }}
          >
            <FilePdfOutlined
              style={{ marginRight: "10px", fontSize: "20px" }}
            />
            View invoice
          </p>
          {item?.showRefund ? (
            <p
              onClick={(e) => {
                e.stopPropagation();
                setFeeDetails("selectedTransaction", item);
                setPageLoader(true);
                refundedApi(() => setPageLoader(false));
              }}
              style={{ cursor: "pointer" }}
            >
              <ReloadOutlined
                style={{ marginRight: "10px", fontSize: "20px" }}
              />{" "}
              Initiate refund
            </p>
          ) : null}
        </div>
      ) : (
        <div
          onClick={(e) => {
            // console.log("item", item);
            setFeeDetails("selectedTransaction", item);
            // deleteTransaction()
            e.stopPropagation();
            // setShowConfirmationModal(true);
          }}
          style={{
            color: "#AEAEAE",
            cursor: "pointer",
            marginTop: "10px",
            height: "20px",
            padding: "6px",
          }}
        >
          {/* <p
            onClick={(e) => {
              console.log("item", item);
              setFeeDetails("selectedTransaction", item);
              // deleteTransaction()
              e.stopPropagation();
              setShowConfirmationModal(true);
            }}
            style={{ marginTop: "-15px", marginBottom: "10px" }}
          >
            {" "}
            <DeleteFilled style={{ marginRight: "10px", fontSize: "20px" }} />
            Delete
          </p> */}

          <p
            onClick={() => {
              setShowEditOffline(true);
              let temp1 = item?.addition_details
                ? JSON.parse(item?.addition_details)
                : [];
              let temp2 = item?.discount_details
                ? JSON.parse(item?.discount_details)
                : [];
              setCourseDetails("additions", temp1);
              setCourseDetails("scholarships", temp2);
              setFeeDetails(
                "paidDate",
                moment(item?.paymentDate, "DD/MM/YYYY, h:mm a").format(
                  "YYYY/MM/DD"
                )
              );
              setFeeDetails("editPaidDisc", item?.note);
              setFeeDetails("editPaidAmount", item?.amount);

              // temp1 = item?.setCourseDetails("additions", temp);
              // setCourseDetails("scholarships", temp);
            }}
            style={{ marginBottom: "10px", marginTop: "-10px" }}
          >
            {" "}
            <EditOutlined style={{ marginRight: "10px", fontSize: "20px" }} />
            Edit
          </p>
        </div>
      )}
    </div>
  );

  const contentPending = (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ color: "#636363" }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setFeeDetails("selectedTransaction", item);
          setFeeDetails("selectedSingleTransaction", item);
          setShowSendReminder(true);
        }}
        style={{ cursor: "pointer", marginBottom: "4px" }}
      >
        <BellOutlined style={{ marginRight: "5px", fontSize: "15px" }} />
        Send reminder
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setFeeDetails("selectedSingleTransaction", item);
          setFeeDetails("selectedTransaction", item);
          setShowSendReminder(true);
          setShowHistory(true);
        }}
        style={{ cursor: "pointer", marginBottom: "4px" }}
      >
        <BellOutlined style={{ marginRight: "5px", fontSize: "15px" }} />
        <span
          style={{ marginLeft: "-15px", fontSize: "11px", marginRight: "6px" }}
        >
          !
        </span>{" "}
        Reminder history
      </div>
      <div
        onClick={(e) => {
          setShowOfflinePayReqModal(true);
          e.stopPropagation();
          setFeeDetails("selectedSingleTransaction", item);
        }}
        style={{ cursor: "pointer", marginBottom: "4px" }}
      >
        <CheckCircleOutlined style={{ marginRight: "5px", fontSize: "15px" }} />
        Mark as paid offline
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setFeeDetails("selectedSingleTransaction", item);
          setFeeDetails("selectedTransaction", item);
          // deleteTransaction()
          // e.stopPropagation();
          setShowConfirmationModal(true);
        }}
        style={{ cursor: "pointer", marginBottom: "4px" }}
      >
        <DeleteFilled style={{ marginRight: "5px", fontSize: "15px" }} />
        Delete
      </div>
    </div>
  );
  const text = <span>Title</span>;
  return (
    <div style={{}}>
      <div
        onClick={(e) => {
          if (item?.status == "Paid") {
            setShowTansDtls(true);
          }
          e.stopPropagation();
        }}
        className="box-shadow"
        style={{
          width: "290px",
          padding: "20px",
          margin: "15px",
          cursor: item?.status == "Paid" ? "pointer" : null,
          paddingTop: "30px",
          height: "185px",
        }}
      >
        {item?.status == "Paid" ? (
          <p
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              backgroundColor:
                item?.payment_type != "online" ? "#636363" : "#28DF99",
              color: "white",
              width: "70px",
              textAlign: "center",
              marginTop: "-28px",
              marginLeft: "-20px",
              borderRadius: "7px 0 0 0",
              marginBottom: "-3px",
            }}
          >
            {item?.payment_type}
          </p>
        ) : null}
        {item?.status == "Pending" && item?.feeReminders?.length != 0 ? (
          <p
            style={{
              fontSize: "9px",
              fontWeight: "bold",
              backgroundColor: "#FFF4D5",
              color: "#FFA931",
              width: "150px",
              textAlign: "center",
              marginTop: "-30px",
              marginLeft: "-20px",
              borderRadius: "7px 0 0 0",
              padding: "1px",
            }}
          >
            <BellOutlined />{" "}
            {item?.feeReminders?.length != 0
              ? `REMINDER SENT ${item?.feeReminders?.length}`
              : null}
          </p>
        ) : null}
        <div
          className="flex_row_center"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            Request For{" "}
            <span style={{ fontWeight: "600" }}>
              <Typography.Text
                ellipsis={{ tooltip: true }}
                style={{ width: "100px" }}
              >
                {item?.note}
              </Typography.Text>
            </span>
          </div>
          {(item?.payment_type == "online" && item?.status == "Refunded") || item?.status == 'Cancelled' ? null :
            <Popover
              placement="leftTop"
              //   title={text}
              content={
                item?.status == "Not Requested"
                  ? contentNotRequested
                  : (item?.status == "Pending" || item?.status == 'Overdue')
                    ? contentPending
                    : content
              }
            // trigger="hover"
            >
              {item?.status !== "Cancelled" ?
              <MoreOutlined style={{ fontWeight: "800", fontSize: "24px" }} /> : null}
            </Popover>
          }
        </div>
        <div
          style={{
            fontWeight: "600",
            fontSize: "24px",
            fontFamily: "roboto",
            margin: "5px",
            marginLeft: "0",
            width: "80%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginBottom: "10px",
          }}
        >
          ₹{amount.toFixed(2) && numberWithCommas(amount.toFixed(2))}
        </div>
        {/* {item?.status == "Paid" ? (
          <div className="flex_row_center" style={{ marginBottom: "10px" }}>
            {item?.payment_type != "online" ? null : (
              <div style={{ marginLeft: "40px" }}>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "10px",
                    fontWeight: "600",
                    color: "#AEAEAE",
                  }}
                >
                  INVOICE NUMBER
                </p>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#28DF99",
                  }}
                >
                  {item?.paymentId}
                </p>
              </div>
            )}
          </div>
        ) : null} */}
        {/* {instaCard ? null : ( */}
        {/* {status == "pending" ? ( */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "-15px",
            // justifyContent: "space-between",
          }}
        >
          <div>
            <p
              style={{
                marginBottom: "0",
                fontSize: "10px",
                fontWeight: "600",
                color: "#AEAEAE",
              }}
            >
              ISSUE DATE
            </p>
            <p
              style={{
                marginBottom: "0",
                fontSize: "12px",
                fontWeight: "600",
                color: "#AEAEAE",
              }}
            >
              {item?.status == 'Not Requested' ?
                "-" :
                moment(issueDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
            </p>
          </div>
          <div
            style={{
              marginLeft: "50px",
            }}
          >
            {item?.status == "Overdue" ? (
              <p
                style={{
                  marginBottom: "0",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "red",
                }}
              >
                <WarningFilled
                  style={{
                    color: "red",
                    marginRight: "5px",
                    fontSize: "13px",
                  }}
                />{" "}
                OVERDUE
              </p>
            ) : (
              <p
                style={{
                  marginBottom: "0",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#AEAEAE",
                }}
              >
                DUE DATE
              </p>
            )}

            <p
              style={{
                marginBottom: "0",
                fontSize: "12px",
                fontWeight: "600",
                color: "#AEAEAE",
              }}
            >
              {dueDate
                ? moment(dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                : "-"}
              {/* <BellOutlined
                onClick={(e) => {
                  setShowSendReminder(true);
                  setFeeDetails("selectedTransaction", item);
                  setFeeDetails("selectedSingleTransaction", item);
                  e.stopPropagation();
                }}
                style={{
                  color: "#FFA931",
                  marginLeft: "5px",
                  backgroundColor: "#FFF4D5",
                  fontSize: "13px",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  paddingTop: "2px",
                }}
              /> */}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "15px",
            marginBottom: "-10px",
            // justifyContent: "space-between",
          }}
        >
          {item?.status !== "Not Requested" ? (
            <div>
              <p
                style={{
                  marginBottom: "0",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#AEAEAE",
                }}
              >
                STATUS
              </p>
              <p
                style={{
                  marginBottom: "0",
                  fontSize: "12px",
                  fontWeight: "600",
                  color:
                    item?.status == "Paid"
                      ? "#28DF99"
                      : item?.status == "Pending"
                        ? "#FFA931"
                        : item?.status
                          ? "#FF414D"
                          : "#AEAEAE",
                }}
              >
                {console.log("AA-status",item?.status)}
                {item?.status}

                {item?.status == "Pending" ? (
                  <BellOutlined
                    onClick={(e) => {
                      setShowSendReminder(true);
                      setFeeDetails("selectedTransaction", item);
                      setFeeDetails("selectedSingleTransaction", item);
                      e.stopPropagation();
                    }}
                    style={{
                      color: "#FFA931",
                      marginLeft: "5px",
                      backgroundColor: "#FFF4D5",
                      fontSize: "13px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      paddingTop: "2px",
                    }}
                  />
                ) : null}
              </p>
            </div>
          ) : null}
          {item?.status == "Paid" && item?.payment_type == "offline" ? (
            <div
              style={{
                marginLeft: "80px",
              }}
            >
              {false ? (
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "10px",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  <WarningFilled
                    style={{
                      color: "red",
                      marginRight: "5px",
                      fontSize: "13px",
                    }}
                  />{" "}
                  OVERDUE
                </p>
              ) : (
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "10px",
                    fontWeight: "600",
                    color: "#AEAEAE",
                  }}
                >
                  MODE OF PAYMENT
                </p>
              )}

              <p
                style={{
                  marginBottom: "0",
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#AEAEAE",
                }}
              >
                {/* {dueDate
                ? moment(dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                : null} */}
                {item?.payment_method}
              </p>
            </div>
          ) : null}
        </div>
        {item?.status == "Not Requested" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={item?.kycStatus != 'Approved'}
              onClick={(e) => {
                // setFeeDetails("selectedTransaction", item);

                setShowRequestModal(true);
                e.stopPropagation();
              }}
              style={{
                backgroundColor: "#594099",
                color: "white",
                borderRadius: "20px",
                fontWeight: "600",
                fontSize: "11px",
                height: "27px",
                marginBottom: "-5px",
              }}
            >
              <SwapOutlined style={{ marginRight: "2px" }} /> REQUEST
            </Button>
          </div>
        ) : null}
        {/* ) : null} */}
        {/* )} */}
      </div>

      <PageDataLoader visible={pageLoader} />
      {showTansDtls && (
        <ViewTransactionDetailsModal
          isModalVisible={showTansDtls}
          setShowTansDtls={setShowTansDtls}
          item={item}
        />
      )}
      <ConfirmationModal
        userApiCall={true}
        setPageLoader={setPageLoader}
        setShowConfirmationModal={setShowConfirmationModal}
        isModalVisible={showConfirmationModal}
        deleteApiCall={true}
      />
      {showAddDetailsModal && (
        <AddDetailsModal
          isModalVisible={showAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          isAddOrGenModal={isAddOrGenModal}
          isAdditionModal={isAdditionModal}
          handleOk={() => {
            setShowAddDetailsModal(false);
            // if (isAddOrGenModal == true) {
            setShowOfflinePayReqModal(true);
            setIsAddOrGenModal(false);
            // } else {
            //   setShowGeneratePayReq(true);
            //   setIsAddOrGenModal(false);
            // }
          }}
          handleCancel={() => {
            setShowAddDetailsModal(false);
            // if (isAddOrGenModal == true) {
            setShowOfflinePayReqModal(true);
            setIsAddOrGenModal(false);
            // } else {
            //   setShowGeneratePayReq(true);
            //   setIsAddOrGenModal(false);
            // }
          }}
          setShowGeneratePayReq={setShowGeneratePayReq}
        />
      )}
      {showOfflinePayReqModal && (
        <OfflinePayReq
          userApiCall={true}
          setPageLoader={setPageLoader}
          item={item}
          isModalVisible={showOfflinePayReqModal}
          setIsModalVisible={setShowOfflinePayReqModal}
          setShowAddDetailsModal={setShowAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          setIsAdditionModal={setIsAdditionModal}
        />
      )}
      <SendReminderModal
        // sendReminder={sendReminder}
        userApiCall={true}
        item={item}
        setPageLoader={setPageLoader}
        isModalVisible={showSendReminder}
        setShowHistory={setShowHistory}
        showHistory={showHistory}
        setShowSendReminder={setShowSendReminder}
      />
      <PageDataLoader visible={pageLoader} />
      {showRequestModal && (
        <PaymentRequestModal
          item={item}
          isModalVisible={showRequestModal}
          setShowRequestModal={setShowRequestModal}
        />
      )}
      {showEditOffline && (
        <EditOfflinePayment
          showEditOffline={showEditOffline}
          setShowEditOffline={setShowEditOffline}
          setShowEditSA={setShowEditSA}
          setIsAdditionModal={setIsAdditionModal}
        />
      )}
      {showEditSA && (
        <EidtScholerShipAddModal
          setShowEditSA={setShowEditSA}
          ShowEditSA={showEditSA}
          setShowEditOffline={setShowEditOffline}
          isAdditionModal={isAdditionModal}
          setIsAdditionModal={setIsAdditionModal}
        />
      )}
    </div>
  );
}

// export default InstallmentCard;
const mapStateToProps = (state) => {
  const { feeMangementData } = state.feeManagement;
  return {
    feeMangementData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  refundedApi: (callback) => dispatch(refundedApi(callback)),
  setInvoiceFile: (invoiceFile) => dispatch(setInvoiceFile(invoiceFile)),
  sendReminder: (callback, successCallBack) =>
    dispatch(sendReminder(callback, successCallBack)),
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeCourseCard);
