import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import Tabs from 'antd/lib/tabs';
import PageHeader from 'antd/lib/page-header';
import Modal from 'antd/lib/modal';
import Pagination from 'antd/lib/pagination';
import Typography from 'antd/lib/typography'
import Empty from 'antd/lib/empty';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import 'antd/lib/typography/style/index';
import {
  EditOutlined,  LoadingOutlined, EllipsisOutlined,
  DeleteOutlined, FilterOutlined, CloseCircleOutlined, ExclamationCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdPublish } from "react-icons/md";
import FilterModal from './modals/filterModal';
import AssignmentUpdateModal from './modals/updateAssignment';
import moment from 'moment';
import { getAssignment, setCurrentAssignment, handleFilterParams,
  toggleAssignmentModal, updateSelectedTab,
  handleAssignmentInput, updateAssignmentStatus } from 'actions/assignment';
import { searchSubject } from 'actions/classRoom';
import { fetchClasslist } from 'actions/calendar'
import './styles.css';
import { Color } from 'services';
import { Col, Row } from 'antd';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;


class Assignment extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      filterModal: false,
    };
    this.baseState = this.state;
  }
  resetState = () => {
    this.props.handleInput([],'selectedClasses');
    this.props.handleInput('','selectedSubject');
    this.props.handleInput(-1,'selectedSubjectId');
    this.setState(this.baseState);
  }

  componentDidMount() {
    this.onApply();
  }

  async handleSubjectSearch (query){
    const response = await this.props.searchSubject(query);
    this.props.handleFilterParams(-1, 'selectedStd')
    return response;
  }


  onApply(){
    this.props.handleFilterParams(1, 'currentPage');
    const { status, standard, teacher, subject, publishDate, dueDate } = this.props.filterParams
    const filter = {
      page: 1,
      status,
      standard, teacher, subject, publishDate, dueDate
    }
    this.props.getAssignment(filter);
    this.setState({
      filterModal: false
    })
  }

  handlePageChange(page){
    this.props.handleFilterParams(page, 'currentPage');
    const { status, standard, teacher, subject, publishDate, dueDate } = this.props.filterParams
    const filter = {
      page, status, standard, teacher, subject, publishDate, dueDate
    }
    this.props.getAssignment(filter);
  }

  onTabChange(tab){
    this.props.handleFilterParams(1, 'currentPage');
    this.props.handleFilterParams(tab, 'status');
    const { standard, teacher, subject, publishDate, dueDate } = this.props.filterParams
    const filter = {
      page: 1,
      status: tab,
      standard, teacher, subject, publishDate, dueDate
    }
    this.props.getAssignment(filter);
  }

  showConfirm(data, action, self) {
    this.setState({currentAssignmentId: data.id})
    const icon = action === "Delete" ? <CloseCircleOutlined style={{color: Color.red}}/> : <ExclamationCircleOutlined />
    return confirm({
      title: `Do you want to ${action} this assignment?`, //${text}
      icon: icon,
      content: 'Please click OK to confirm',
      onOk() {self.handleConfirmClick(action, data)},
      onCancel() {},
    })
  }

  async handleConfirmClick(action, data){
    action = action === "Delete" ? "Deleted" : action === "Publish" ? "Published" : null
    await this.props.updateAssignmentStatus(data, action, null)
    this.onApply();
  }

  assignmentChange = (data, history) => {
    const {access} =  this.props.user
    return (
    <Menu className='assignmentddlmenu'>
    {access.includes('assignment-dataoperator') ?
      <Menu.Item key="0">
        <div onClick={() => this.viewAssignment(data, history)}> {/*this.editAssignment(data)}>*/}
          <EditOutlined /> Edit
        </div>
      </Menu.Item> :
      access.includes('assignment-teacher') ?
      <Menu.Item key="3">
        <div onClick={() => {
            this.viewAssignment(data)
          }}>
          <EyeOutlined /> Details
        </div>
      </Menu.Item> : null}
      {this.props.filterParams.status === "Draft" && <Menu.Item key="1">
        <div onClick={() => {
            this.showConfirm(data, "Delete", this)
          }}>
          <DeleteOutlined /> Delete
        </div>
      </Menu.Item>}
      {this.props.filterParams.status === "Draft" && access.includes('assignment-teacher') &&
      <Menu.Item key="2">
        <div onClick={() => {
            this.showConfirm(data, "Publish", this)
          }}>
          <MdPublish /> Publish
        </div>
      </Menu.Item>}
      <Menu.Item key="4">
        <div onClick={() => {
            this.handleCloneClick(data)
          }}>
          <FaRegClone /> Clone
        </div>
      </Menu.Item>
    </Menu>
  )}
  
  handleCloneClick(data){
    const {handleAssignmentInput, toggleAssignmentModal, fetchClasslist } = this.props;
    handleAssignmentInput(data.id,'cloneAssignmentId');
    handleAssignmentInput(data.sub,'selectedSubject');
    handleAssignmentInput(data.subjectId,'subjectId');
    handleAssignmentInput(data.author.name,'selectedTeacher');
    handleAssignmentInput(data.teacherId,'teacherId');
    handleAssignmentInput(data.chapterNames,'chapterNames');
    handleAssignmentInput(data.chapterIds,'chapterIds');
    handleAssignmentInput(data.gradingType,'gradingType');
    toggleAssignmentModal(true);
    fetchClasslist(data.subjectId)
  }

  async viewAssignment(data){
    const { user, setCurrentAssignment, history } = this.props;
    // let view = null;
    if(user.access.includes('assignment-dataoperator')){
      history.push(`/assignment/${data.id}`);
    }else if(user.access.includes('assignment-teacher')){
      history.push(`/assignment/submissions/${data.id}`);
      // view = 'assignment-teacher';
    }
    await setCurrentAssignment(data);
  }

  assignmentListItem = (data, status) => {
    const { statusLoader, updateSelectedTab } = this.props;
    const {access} =  this.props.user
    if(data.status === status) {
      return (
        <div key={data.id.toString()} className='assignmentItem m-b-10 r-jsb'
        style={{backgroundColor: '#FFF'}}>
          <div className='r-ac flex-1'>
            {statusLoader && this.state.currentAssignmentId === data.id?  <Avatar size={40}><LoadingOutlined /></Avatar>:
            <div className='assignmentIcon m-r-10' style={{border: data.markedForReview ? '2px solid red' : false}} />
            }
            <div>
              <div className='text-sm bold firstCapital cursor-pointer' style={{color: Color.primary}} onClick={() => this.viewAssignment(data)}>
                {data.title}
              </div>
              <div className='text-xs dark-grey'>
                 {data.sub} | {data.author ? data.author.name : ""}
              </div>
            </div>
          </div>
          <div className='r-c-se flex-3'>
            <div className='text-center'>
              <div className='bold color-purple text-xs'>
                <div style={{whiteSpace: 'nowrap', width: '10vw', overflow:'hidden', textOverflow: 'ellipsis'}}>
                 {data.std.map((std, index, array) => (<>{std} {index === array.length - 1 ? '' : '|'} </>))}
                </div>
              </div>
              <div className='dark-grey text-xs'>
                Standards
              </div>
            </div>
            {status === "Published" ? <div className='text-center'>
              <div className='bold color-purple text-xs'>
                {data.publishDate ? moment(data.publishDate).format('DD MMM YYYY') : 'NA'}
              </div>
              <div className='dark-grey text-xs'>
                Published Date
              </div>
            </div> : null}
            <div className='text-center'>
                <div className='bold color-purple text-xs'>
                  {moment(data.deadlineDate).format('DD MMM YYYY')}
                </div>
                <div className='dark-grey text-xs'>
                  Due Date
                </div>
            </div>
            <div className='text-center'>
              <div className='bold color-purple text-xs'>
                {moment(data.resultDueDate).format('DD MMM YYYY')}
              </div>
              <div className='dark-grey text-xs'>
                Result Date
              </div>
            </div>
            {status === "Published" ?
            <div className='text-center'>
            <div className='bold text-md'>
              {access.includes('assignment-dataoperator') ?
              <div className='bold color-purple text-xs'>
                {data.submitted}
              </div>
              :
              <Button type="link"
                onClick={() => {this.viewAssignment(data); updateSelectedTab("Submission")}}
                style={{fontSize: "1em", padding: 0}}
              >{data.submitted}</Button>}
            </div>
            <div className='dark-grey text-xs'>Submitted</div>
            </div>
            : null}
            <div className='text-center'>
              <div className='color-orange bold text-md'>
              <Dropdown
                trigger={['click', 'hover']}
                onClick={e => e.stopPropagation()}
                overlay={() => this.assignmentChange(data, this.props.history)}
              >
                <EllipsisOutlined
                  className='m-l-10 text-sm color-purple cursor-pointer'
              />
              </Dropdown>
              </div>
              <div className='dark-grey text-xs'>
                Actions
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  clearFilterParams(){
    this.props.handleFilterParams(-1, 'selectedStd');
    this.props.handleFilterParams(-1, 'standard');
    this.props.handleFilterParams('', 'subjectName');
    this.props.handleFilterParams(null, 'subject');
    this.props.handleFilterParams(null, 'teacher');
    this.props.handleFilterParams('', 'teacherName');
    this.props.handleFilterParams(null, 'publishDate');
    this.props.handleFilterParams(null, 'dueDate');
    this.props.handleFilterParams([], 'sections');
    this.props.handleFilterParams(-1, 'selectedStd');

  }

  componentWillUnmount(){
    this.clearFilterParams();
  }

  render() {
    const { history,  assignmentList, assignmentLoader, pageMeta, filterParams, toggleAssignmentModal
    } = this.props;

    return (
      <div style={{height: '100%'}}>
          <Row style={{backgroundColor:'#F9F9F9', padding: 10, borderRadius: 10}} className="m-b-20 r-jsb">
              <Col span={18} className="display-flex">
                <Title style={{color: Color.darkGrey, margin:0}} level={3}>Assignments</Title>
                <Button
                  onClick={() => toggleAssignmentModal(true)}
                  style={{borderRadius: 30}}
                  type='primary'
                  className='assignmentCreateBtn m-l-10'
                >
                  + Create
                </Button>
              </Col>
              <Col span={2} offset={4}>
                <Button
                  type='link'
                  icon={<FilterOutlined style={{fontSize: 20}}/>}
                  onClick={() => this.setState({
                    filterModal: !this.state.filterModal
                  })}
                  style={{fontSize: 14}}
                  className='assignmentCreateBtn m-l-10'
                />
              </Col>
          </Row>

        <div className="b-b-1 m-t-10 flex-1 text-sm" style={{height: 'calc(100% - 80px)'}}>

            <div style={{height:'100%' }}>

                <Tabs style={{height: '100%', overflow:'scroll'}} onChange={tab => this.onTabChange(tab)} defaultActiveKey={filterParams.status} activeKey={filterParams.status}>
                  <TabPane tab={<div><FaRegEdit /> Draft</div>} key="Draft">
                  {assignmentLoader ?
                    <div className='r-c-c-c' style={{height: '70vh', overflowY: 'auto', backgroundColor: Color.lightGrey}}>
                      <LoadingOutlined style={{color: '#5843BE', fontSize: 100, opacity: 0.5}} />
                    </div> :
                    assignmentList.length ?
                    <div style={{height: '70vh', overflowY: 'auto', backgroundColor: Color.lightGrey}}>
                      {assignmentList.map((data, index) => this.assignmentListItem(data, 'Draft'))}
                    </div>
                    :
                    <div className='r-c-c-c' style={{height: '70vh', overflowY: 'auto', backgroundColor: Color.lightGrey}}>
                      <Empty description={"No assignments found"}/> </div>}
                  </TabPane>
                  <TabPane tab={<div><MdPublish /> Published</div>} key="Published">
                  {assignmentLoader ?
                    <div className='r-c-c-c' style={{height: '70vh', overflowY: 'auto', backgroundColor: Color.lightGrey}}>
                      <LoadingOutlined style={{color: '#5843BE', fontSize: 100}} />
                    </div> :
                    assignmentList.length ?
                      <div style={{height: '70vh', overflowY: 'auto', backgroundColor: Color.lightGrey}}>
                        {assignmentList.map((data, index) => this.assignmentListItem(data, 'Published'))}
                      </div>
                    :
                    <div className='r-c-c-c'  style={{height: '70vh', overflowY: 'auto', backgroundColor: Color.lightGrey}}>
                      <Empty description={"No assignments found"}/>
                    </div>}


                  </TabPane>
                </Tabs>
                <div style={{textAlign: 'right'}}>
                  <Pagination
                  size="small"
                  current={filterParams.currentPage}
                  onChange={(page) => this.handlePageChange(page)}
                  pageSize={20}
                  total={pageMeta ? pageMeta.totalItems : 0}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  showSizeChanger={false}
                  />
                </div>


            </div>
        </div>
        <FilterModal
        filterModal={this.state.filterModal}
        onCancel={() => this.setState({
            filterModal: false
            })}
        onApply={() => this.onApply()}
        subjectSearch={(val) => this.handleSubjectSearch(val)}
        clearFilterParams={() => this.clearFilterParams()}
         />
        <AssignmentUpdateModal history={history} />
    </div>
  );
}
}

const mapStateToProps = state => {
  const { assignmentList, currentAssignment, assignmentLoader, currentAssignmentTasks, assignmentTaskLoader, pageMeta, filterParams,
    statusLoader
  } = state.assignment;
  const { classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses, teachers,
    subClasses, subClassesLoader } = state.calendar;
  const { searchLoader, subjectsFound } = state.classRoom;
  const { user } = state.session;

  return { classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses,
    user, teachers, subClasses, subClassesLoader, searchLoader, subjectsFound, assignmentList, currentAssignment, assignmentLoader,
    assignmentTaskLoader, currentAssignmentTasks, pageMeta, filterParams, statusLoader };
};

const mapDispatchToProps = dispatch => ({
  getAssignment: (filter) => dispatch(getAssignment(filter)),
  setCurrentAssignment: (value) => dispatch(setCurrentAssignment(value)),
  handleFilterParams: (val, key) => dispatch(handleFilterParams(val, key)),
  toggleAssignmentModal: (state) => dispatch(toggleAssignmentModal(state)),
  updateAssignmentStatus: (params, status, history) => dispatch(updateAssignmentStatus(params, status, history)),
  searchSubject: (query) => dispatch(searchSubject(query)),
  updateSelectedTab: key => dispatch(updateSelectedTab(key)),
  handleAssignmentInput: (val, key) => dispatch(handleAssignmentInput(val, key)),
  fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
