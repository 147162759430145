import { Image, Typography } from 'antd'
import React, { useState } from 'react'
import { setScheduleTimeTable } from 'actions/schedule'
import { connect } from 'react-redux'
import { MdCastConnected } from 'react-icons/md'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import Conference  from "Assets/schedule/confrence.svg"
import Broadcast  from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
const { Text } = Typography
const TimetableCard = ({ items, setVisibleEditScheduleTime, timetableItem, setScheduleTimeTable, access }) => {
    const [modeType, setModeType] = useState(items.mode == "Zoom 40" ? "1px solid #1089FF" :
        items.mode == "Conference" ? "1px solid #594099" : items.mode == "Broadcast" ? "1px solid #FFA931" : "1px solid #28DF99")

    const handleTimetable = () => {
        setScheduleTimeTable(items)
        setVisibleEditScheduleTime(true)

    }

    return (
        <div className={access ? "timetableCard-main cursor-pointer" : "timetableCard-main"} style={{ border: `${modeType}` }} onClick={() => handleTimetable()}>
            <div className="timetableCard-details">
                {items.mode == "Zoom 40" ?
                    <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', marginBottom: '3px', padding: '2px' }}>
                        <IoMdVideocam style={{ color: '#1089FF' }} />
                        <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                    </div>
                    : <div>{items.mode == "Broadcast" ?
                        <div style={{ width: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', marginBottom: '3px', padding: '3px 7px' }}>
                            <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "16px", width: "16px" }} />
                            <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                        </div>
                        :
                        <div>{items.mode == "Conference" ?
                            <div style={{ width: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', marginBottom: '3px', padding: '3px 7px' }}>
                                <img src={Conference} style={{ color: '#594099', height: "16px", width: "16px" }} />
                                <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                            </div>
                            :
                            <div style={{ width: '80px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', marginBottom: '3px', padding: '3px 7px' }}>
                                <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "16px", width: "16px" }} />
                                <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                            </div>

                        }
                        </div>}
                    </div>
                }

                <Typography.Text style={{ width: '80%', color: '#191919', fontFamily: 'roboto', fontWeight: 'bold', fontSize: '14px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} ellipsis={{ tooltip: true }}>
                    {items?.subject?.name}
                </Typography.Text>
                {/* <Text style={{ width: '80%', color: '#191919', fontFamily: 'roboto', fontWeight: 'bold', fontSize: '14px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{items?.subject?.name}</Text> */}
                <Typography.Text style={{ width: '60%', color: '#636363', fontFamily: 'roboto', fontSize: '10px', overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} ellipsis={{ tooltip: true }}>
                    {items?.teacher?.name}
                </Typography.Text>
                {/* <Text style={{ width: '60%', color: '#636363', fontFamily: 'roboto', fontSize: '10px',overflow:"hidden",textOverflow:'ellipsis',whiteSpace:'nowrap' }}>{items?.teacher?.name}</Text> */}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const {
        timetableItem,
        access

    } = state.schedule;
    return {
        timetableItem,
        access
    };
};

const mapDispatchToProps = (dispatch) => ({
    setScheduleTimeTable: (item) => dispatch(setScheduleTimeTable(item))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(TimetableCard);