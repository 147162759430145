import React from 'react'
import { useState, useRef, useEffect } from 'react';
import { Modal, Input, AutoComplete, Select } from 'antd';
import {
  SubHeading,
} from "components/Typography";
import { updatepdf, subjectFilter, fetchAdminLibraryData, updateSetType, fetchAdminChapterFilter, fetchClasslist, postClone, fetchFilterTopic, fetchLibraryData, postCreateTopic, updateFilterTopic, updateAdminChapterInfo, updateTitle } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import { Button } from 'components/Button';


function CloneInformation({
  ismodelvisible,
  setmodevisible,
  updatepdf,
  subjectFilter, subjects,
  chapterstofetch,
  id, types, selectedSubject, selectedStd, chapterName, chapterId, fetchAdminLibraryData,
  updateSetType, fetchAdminChapterFilter, getChaptersList, fetchClasslist, getStdList, title, postClone, uploadContent
  , topicList, selectedChapter, fetchFilterTopic, selectedTopic, fetchLibraryData, contentPage, postCreateTopic,
  updateFilterTopic, updateAdminChapterInfo, updateTitle,changedTitle
}) {
  console.log("id2", topicList);
  var i = 0;
  const [subjectname, setSubjectName] = useState(null)
  const [inputvalue, setinputvalue] = useState('')
  const [chaptersarray, setchapterarray] = useState(getChaptersList)
  const [pdf, setpdf] = useState(null)
  const [chapter, setchapter] = useState('')
  const [classid, setclassid] = useState(null)
  const [subjectid, setsubjectid] = useState(null)
  const [chapterid, setchapterid] = useState(null)
  const [options, setOptions] = useState([]);
  const [searchChapter, setSearchChapter] = useState("")
  const [optionsChapter, setOptionsChapter] = useState([]);
  const [chapterLoader, setChapterLoader] = useState(false)
  const [subjectLoder, setSubjectLoader] = useState(false)
  const [className, setClassName] = useState(null)
  const [loader, setLoader] = useState(false)
  const [topicId, setTopicId] = useState(null)
  const [topicName, setTopicName] = useState(null)
  const [classLoader, setClassLoader] = useState(false)
  const [topicLoader, setTopicLoader] = useState(false)
  let [topicListToShow, setTopicListToShow] = useState([])
  const [createLoader, setCreateLoader] = useState(false)
  const inputchanged = (e) => {
    console.log(e, "e")
    updateTitle(e.target.value)
    setinputvalue(e.target.value)
  }
  const filteroptions = (val) => {
    const dummyArray = chapterstofetch.filter((element) => {
      return (element.name).toLowerCase().includes(val.toString().toLowerCase())
    }
    )

    setchapterarray(dummyArray)
  }

  const searchResult = (query) =>
    query.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {item.name}
            </span>
          </div>
        ),
      };
    });


  useEffect(() => {
    setOptions(subjects ? [...searchResult(subjects)] : []);
  }, [subjects])

  useEffect(() => {
    setOptionsChapter(getChaptersList ? [...searchResult(getChaptersList)] : []);
  }, [getChaptersList])

  useEffect(() => {

    if (selectedSubject && Object.keys(selectedSubject).length !== 0) {
      console.log("selectedSubject", selectedSubject);
      setsubjectid(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId)
      setSubjectName(selectedSubject.subjects_name ? selectedSubject.subjects_name :selectedSubject.subjectname)
      // fetchClasslist(selectedSubject.subjects_id)
    }
    if (selectedStd && Object.keys(selectedStd).length !== 0) {
      console.log("selectedSubject", selectedStd);

      setclassid(selectedStd.std_id ? selectedStd.std_id : selectedStd.classid)
      setClassName(selectedStd.standard ? selectedStd.standard + '-' + selectedStd.std_section : selectedStd?.className)
    }
    if (selectedChapter && Object.keys(selectedChapter).length != 0 && selectedSubject && selectedStd) {
      setchapterid(selectedChapter.chapterId ? selectedChapter.chapterId : selectedChapter?.chapterid)
      setchapter(selectedChapter.chapterName)
      setSearchChapter(selectedChapter.chapterName)
      console.log("selectedChapter", selectedChapter);
      console.log("selectedChapter1", selectedSubject);
      console.log("selectedChapter2", selectedStd);

      fetchAdminChapterFilter('', selectedStd.std_id ? selectedStd.std_id : selectedStd.classid, selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId)
      fetchClasslist(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId, selectedChapter.chapterName)
      
      fetchFilterTopic(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId, selectedStd.std_id ? selectedStd.std_id : selectedStd.classid , selectedChapter.chapterId ? selectedChapter.chapterId : selectedChapter?.chapterid )
      // fetchFilterTopic(selectedSubject.subjects_id , selectedStd.std_id , selectedChapter.chapterId )


    }

    if (selectedTopic && Object.keys(selectedTopic).length != 0) {
      setTopicId(selectedTopic.topicId)
      setTopicName(selectedTopic.topicName)
    }
    console.log("title",title,inputvalue);
    if (title) {
      setinputvalue(title)
      updateTitle(title)
    }
    if (uploadContent && Object.keys(uploadContent).length !== 0) {
      console.log("uploadContent", uploadContent);
      setsubjectid(uploadContent.subjectId)
      fetchClasslist(uploadContent.subjectId)
      setSubjectName(uploadContent.subjectname)
      setclassid(uploadContent.classid)
      setClassName(uploadContent.className)
      setchapterid(uploadContent.chapterid)
      setchapter(uploadContent.chapterName)
      setSearchChapter(uploadContent.chapterName)

      setTopicId(uploadContent.topicId)
      setTopicName(uploadContent.topicName)

    }
  }, [])

  useEffect(() => {
    if (topicList?.length != 0) {
      setTopicListToShow(topicList)
    }
    else {
      setTopicListToShow([])
    }
  }, [topicList])

  const handleSearch = (value) => {
    if (!value) {
      setSubjectName('')
      setsubjectid(null)
    }
    else {
      setSubjectName(value)
      setSubjectLoader(true)
    }
    subjectFilter(value, () => setSubjectLoader(false))

  };

  const handleSearchChapter = (value) => {
    setTopicId(null)
    setTopicName(null)
    if (!value) {
      setSearchChapter('')
    }
    else {
      setSearchChapter(value)
      setChapterLoader(true)
    }
    fetchAdminChapterFilter(value, classid, subjectid, () => setChapterLoader(false))

  };

  const onSelect = (value, obj) => {
    setSubjectName(obj?.label?.props?.children?.props?.children)
    setsubjectid(value)
    fetchClasslist(value)
    fetchAdminChapterFilter('', classid, value)
  };
  const onSelectChapter = (value, obj) => {
    updateFilterTopic([])
    setTopicId(null)
    setTopicName(null)
    setSearchChapter(obj?.label?.props?.children?.props?.children)
    setchapter(obj?.label?.props?.children?.props?.children)
    setchapterid(value)
    setTopicLoader(true)
    fetchFilterTopic(subjectid, classid, value, () => setTopicLoader(false))
  };

  const handleChangeChapter = (value, obj) => {
    setchapterid(null)
    setchapter('')
    setSearchChapter('')
    setTopicId(null)
    setTopicName(null)
    updateFilterTopic([])
    if (value) {
      setSearchChapter(obj?.label?.props?.children?.props?.children)
      setchapter(obj?.label?.props?.children?.props?.children)
    }
  }


  const successhandler = (e) => {
    e.stopPropagation()
    console.log("abcdef", classid, subjectid, topicId, chapterid);

    if (subjectid && chapterid && inputvalue && classid && chapter) {
      // var objectToUpload = {
      //   id: id,
      //   subjectId: subjectid,
      //   chapterId: parseInt(chapterid),
      //   title: inputvalue,
      //   standardId: parseInt(classid),
      //   type: types,
      //   chapterName: chapter

      // }
      updateSetType(types)
      console.log("abcdef", classid, id, topicId, chapterid);
      setLoader(true)

      postClone(classid, id, topicId, chapterid, () => setLoader(false), successCallback)

    }
  }

  const successCallback = () => {
    setLoader(false)
    setpdf(null)
    setinputvalue('')
    if (contentPage) {
      fetchLibraryData()
    }
    setmodevisible(false)
  }


  const handleCancel = (e) => {
    e.stopPropagation()
    updatepdf(null)
    setinputvalue('')
    setmodevisible(false);
  };

  const onSelectTopic = (value, obj) => {
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      let object = {
        chapterId: chapterid,
        topicName: obj?.children[1]
      }
      setTopicName(null)
      postCreateTopic(object, () => setCreateLoader(false), successCallbackTopic)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
  }

  const successCallbackTopic = () => {
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleChangeTopic = (value, obj) => {
    // console.log(value, obj)
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      setTopicName(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleSearchTopic = (value) => {
    // console.log(value)
    if (value && value.trim() != '') {
      setTopicName(value)
      let topic;
      topic = topicList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
      setTopicListToShow(topic)
      setTopicId(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
      setTopicListToShow(topicList)
    }
  }





  return (
    <Modal className='rounded-border' width='60%' visible={ismodelvisible} footer={null} onCancel={handleCancel} maskClosable={false}>
      <div onClick={(e) => e.stopPropagation()}>
        <div className='lecturenotestitle' style={{ width: '100%', textAlign: 'center' }}>
          <p>Clone Information</p>
        </div>
        <div className='lecturnotesdescription' style={{ width: '100%', textAlign: 'center' }}>
        </div>
        <div style={{ display: 'flex', height: '400px', justifyContent: 'space-between' }}>
          <div className='uploadsearchbox' style={{ width: '100%' }}>
            <div className='full-width'>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Subject</p>

              <AutoComplete
                allowClear={true}
                dropdownMatchSelectWidth={252}
                className='autocompletetoupload'
                options={options}
                value={subjectname}
                onSelect={onSelect}
                onSearch={handleSearch}
                disabled={true}
                loading={subjectLoder}
              >
                <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Select subject" />
              </AutoComplete>
            </div>
            <div className='udloadFilesDropdown' style={{ width: '100%' }}>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Class</p>

              <Select
                defaultActiveFirstOption={false}
                notFoundContent={null}
                size="middle"
                showSearch
                value={className}
                style={{
                  width: "100%",
                  backgroundColor: "#FAFAFA",
                }}
                bordered={false}
                placeholder={
                  <SubHeading color="#AEAEAE">
                    Search Class
                  </SubHeading>
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children[0].indexOf(input) >= 0
                }
                onChange={(value, obj) => {
                  if (value != selectedStd?.std_id || (Object.keys(uploadContent).length != 0 && value != uploadContent?.classid)) {
                    setclassid(value)
                    setClassName(obj?.children[0] + obj?.children[1] + obj?.children[2])
                    updateAdminChapterInfo([])
                    setchapterid(null)
                    setchapter('')
                    setSearchChapter('')
                    setTopicId(null)
                    setTopicName(null)
                    updateFilterTopic([])
                    // fetchFilterTopic(subjectid, value, chapterid)
                    fetchAdminChapterFilter('', value, subjectid)
                    setchapterarray(getChaptersList)
                  }
                }}
              >
                {
                  getStdList.map((item, index) => {
                    if (item.id != selectedStd?.std_id || (Object.keys(uploadContent).length != 0 && item.id != uploadContent?.classid)) {
                      return (
                        <Select.Option key={index} value={item.id}>
                          {item.std} - {item.section}
                        </Select.Option>
                      )
                    }
                  })
                }
              </Select>
            </div>
            <div style={{ width: '100%' }}><p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Chapter</p>
              <div style={{ width: '100%' }}>
                <AutoComplete
                  allowClear={true}
                  dropdownMatchSelectWidth={252}
                  className='autocompletetoupload'
                  options={optionsChapter}
                  value={searchChapter}
                  onChange={handleChangeChapter}
                  onSelect={onSelectChapter}
                  onSearch={handleSearchChapter}
                  // disabled={true}
                  loading={chapterLoader}
                >
                  <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Search Chapter" />
                </AutoComplete>
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Topic</p>
              <Select
                allowClear
                defaultActiveFirstOption={false}
                notFoundContent={null}
                size="middle"
                showSearch
                style={{
                  width: "100%",
                  backgroundColor: "#FAFAFA",
                }}
                bordered={false}
                placeholder={
                  <div color="#AEAEAE">
                    Select Topic
                  </div>
                }
                disabled={chapterid ? false : true}
                loading={topicLoader}
                value={topicName}
                // optionFilterProp="children"
                // filterOption={(input, option) =>
                //   option.children[0].indexOf(input) >= 0
                // }
                filterOption={false}
                onChange={handleChangeTopic}
                onSelect={onSelectTopic}
                onSearch={handleSearchTopic}
              >
                {topicListToShow?.length != 0 ?
                  topicListToShow?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))
                  :
                  topicName ?
                    <Select.Option value={-1}>Create {topicName}</Select.Option>
                    : null
                }

              </Select>

            </div>

            <div style={{ width: '100%' }}> <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Title</p>
              <div className='uploadfilesinput'>
                <Input size='middle' bordered={false} onChange={inputchanged} value={inputvalue} placeholder='Enter title' style={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box', borderRadius: '4px',
                  opacity: '1'
                }}></Input></div>
            </div>

          </div>
        </div>

        <div style={{ width: '100%', marginTop: '3%' }}>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button style={{ width: "122px", marginRight: "5px" }} type="default" onClick={handleCancel}>CANCEL</Button>
            <Button loading={loader} style={{ marginLeft: "5px", width: "122px" }} type="primary" onClick={successhandler} 
            // disabled={(/* (classid == selectedStd?.std_id || (Object.keys(uploadContent).length != 0 && classid != uploadContent?.classid)) && */ topicId && chapterid ? false : true)}
            >PUBLISH</Button>
            {console.log("AA-classid", classid, selectedStd?.std_id, topicId, chapterid, uploadContent)}

          </div>
        </div>
      </div>

    </Modal>
  )
}
const mapStateToProps = (state) => {
  const {
    subjects,
    chapterstofetch,
    selectedSubject,
    selectedStd,
    getChaptersList,
    getStdList,
    uploadContent,
    topicList,
    selectedChapter,
    selectedTopic,
    changedTitle

  } = state.digitalLibrary;
  return {
    subjects,
    chapterstofetch,
    selectedSubject,
    selectedStd,
    getChaptersList,
    getStdList,
    uploadContent,
    topicList,
    selectedChapter,
    selectedTopic,
    changedTitle

  };
};

const mapDispatchToProps = (dispatch) => ({
  updatepdf: (data) => dispatch(updatepdf(data)),
  subjectFilter: (data, callback) => dispatch(subjectFilter(data, callback)),
  fetchAdminLibraryData: () => dispatch(fetchAdminLibraryData()),
  updateSetType: (type) => dispatch(updateSetType(type)),
  fetchAdminChapterFilter: (value, classid, subjectid, callback) => dispatch(fetchAdminChapterFilter(value, classid, subjectid, callback)),
  fetchClasslist: (subid, chapterName) => dispatch(fetchClasslist(subid, chapterName)),
  postClone: (classid, id, topicId, chapterId, callback, successCallback) => dispatch(postClone(classid, id, topicId, chapterId, callback, successCallback)),
  fetchFilterTopic: (subId, stdId, chapterId, callback) => dispatch(fetchFilterTopic(subId, stdId, chapterId, callback)),
  fetchLibraryData: () => dispatch(fetchLibraryData()),
  postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
  updateFilterTopic: (data) => dispatch(updateFilterTopic(data)),
  updateAdminChapterInfo: (data) => dispatch(updateAdminChapterInfo(data)),
  updateTitle: (val) => dispatch(updateTitle(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(CloneInformation);
