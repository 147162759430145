import { Checkbox, Modal } from 'antd';
import React, { useEffect } from 'react';
import {Button} from  'components/Button';
import { useState } from 'react';


const PermanentlyDeleteConfirm = ({visible, onCancel, onConfirm,agreeToDelete,setAgreeToDelete,setTranseferDataValue}) => {
    const [deleteLoader, setDeleteLoader] = useState(false)

    const handleDelete = () => {
        setDeleteLoader(true);
        setTranseferDataValue(true)
        onConfirm(() => setDeleteLoader(false))
    }
    return (
        <Modal
            visible={visible}
            footer={false}
            className="modal-round-corner"
            closable={false}
        >
            <div className='r-c-c'>
                <div style={{width: "90%"}}>
                <div className="m-t-40 text-center" style={{color: "#FF414D", fontSize: 20, fontWeight: 500}}>You are about to permanently delete data</div>
                <div className="m-t-20 text-center" style={{color: "#636363", fontSize: 16}}>
                    The data will be permanently deleted from the database. You cannot undo this action.
                </div>
                <div className="m-t-20 display-flex" style={{color: "#636363", fontSize: 16}}>
                    <div>
                        <Checkbox  checked={agreeToDelete} onChange={e => setAgreeToDelete(e.target.checked)}/> 
                   </div> &nbsp;
                   <div>I understand that deleting data will remove it permanently from the system.</div>
                   
                </div>
                </div>
                
            </div>
            <div className='m-t-50 r-c-c' >
                <div className="r-jsb" style={{width: "60%"}}>
                    <Button type="transparent" style={{color: "#FF414D"}} loading={deleteLoader} disabled={!agreeToDelete} onClick={handleDelete}>DELETE</Button>
                    <Button type="primary" onClick={() => {onCancel(); setAgreeToDelete(false)}}>CANCEL</Button>
                </div>
            </div>
        </Modal>
    );
}

export default PermanentlyDeleteConfirm;
