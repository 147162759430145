import { Button, Popover } from 'antd'
import React from 'react'

function CareCards({unreadMsgCount,count,heading,discription, phoneNos}) {
    unreadMsgCount= unreadMsgCount>10?"10+":unreadMsgCount;
    return (
        <div>

            {count == true && unreadMsgCount!=undefined && unreadMsgCount!=0  ?
                <h1 style={{ fontSize: "18px", color: "#191919", fontFamily: "roboto", fontWeight: "bold" }}>{heading}<span style={{ marginLeft: "15px",width:"30px",height:"30px",borderRadius:"50%", color: "white", fontSize: "10px", background: "#FFA931", padding: "5px", borderRadius: "20px" }}>{unreadMsgCount}</span></h1>
                :
                <h1 style={{ fontSize: "18px", color: "#191919", fontFamily: "roboto", fontWeight: "bold", marginTop: 10 }}>{heading}</h1>
            }
            <h1 style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>{discription} 
                {phoneNos? 
                    <Popover 
                        placement="bottom"
                        content={
                            <div style={{ maxHeight:"400px",overflowY:"auto"}}>
                            {phoneNos?.map((phone, index) => 
                                <div className="m-b-10">
                                    <div className="dark-grey text-xs bold-600">
                                        NUMBER {index + 1}
                                    </div>
                                    <div className="text-xs bold-700">
                                        {phone}
                                    </div>
                                </div>)}
                            </div>
                            
                        }
                    >
                        <Button type="link" className="text-xs bold-600">View Number(s)</Button> 
                    </Popover>
                    : 
                    phoneNos}
            
            </h1>
        </div>
    )
}

export default CareCards
