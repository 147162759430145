import { postTransferStudents } from 'actions/classRoom';
import { Modal, Select } from 'antd';
import { Button } from 'components/Button';
import { ModalSubTitle } from 'components/Typography';
import React, { useState } from 'react'
import { connect } from 'react-redux';

const {option} = Select

function TransferStudentModal({isTransferStudentVisible, setTransferStudentVisible, classes, selectedSection, postTransferStudents, togglePageLoader, getSubjStudentsData, selectedStudents, setSelectedStudents}) {
  const [selectedStd, setSelectedStd] = useState(null)
  const [selectedSec, setSelectedSec] = useState(null)
  const [transferLoader, serTransferLoader] = useState(false)
  const [targetStandard, setTargetStandard] = useState(null)

  const handleCancel = () => {
    setTransferStudentVisible(false)
  }
  const handleTransferClick = () => {
    const params = {
      targetStandard : selectedSec,
      students :selectedStudents
    }
    serTransferLoader(true);
    postTransferStudents(
      params,
      ()=>{serTransferLoader(false)},
      ()=>{
        setTransferStudentVisible(false);
        setSelectedStudents([])
        togglePageLoader(true);
        getSubjStudentsData(()=>togglePageLoader(false))
      }
      )
  }
  return (
    <div>
    <Modal
      centered
      className="modal-round-corner"
      visible={isTransferStudentVisible}
      onCancel={handleCancel}
      onOk={handleCancel}
      footer={null}>
      <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Transfer student(s)"}</div>
      <div style={{ padding: 20 }}>
        <ModalSubTitle>Class<span style={{ color: "red" }}> *</span></ModalSubTitle>
        <Select
          placeholder="Select class"
          style={{ width: "100%", marginBottom: 10 }}
          value={selectedStd}
          onSelect={(value) => {setSelectedStd(value); setSelectedSec(null);console.log("value123", value)}}>
          {Object.keys(classes).map((item, index) => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
        </Select>
        <ModalSubTitle>Section<span style={{ color: "red" }}> *</span></ModalSubTitle>
        <Select
          placeholder="Select section"
          style={{ width: "100%", marginBottom: 10 }}
          value={selectedSec}
          onSelect={(value) => {setSelectedSec(value)}} >
          { selectedStd && classes[selectedStd].filter(item => item.id !== selectedSection.id).map((item, index) => (
                    <Option key={item.id} value={item.id}>{item.section}</Option>
                  ))}
        </Select>
        <div className='r-c-c m-t-40'>
          <Button
            loading={transferLoader}
            disabled={!selectedSec || !selectedStd}
            type="primary"
            onClick={handleTransferClick}
          >TRANSFER</Button>
        </div>
      </div>
    </Modal>
  </div>
  )
}
const mapStateToProps = state => {
  const {classes, selectedSection} = state.classRoom;
  return {
    classes, selectedSection
  };
};

const mapDispatchToProps = dispatch => ({
  postTransferStudents:(params, callBack, successCallBack) => dispatch(postTransferStudents(params, callBack, successCallBack))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferStudentModal);