import { Button, Modal } from 'antd'
import React from 'react'
import ic1 from "../../Assets/aulasMarket/ic-faq.svg"
import ic2 from "../../Assets/aulasMarket/ic-call-us.svg"
import ic3 from "../../Assets/aulasMarket/ic-chat.svg"


function TestModal({ visible, onConfirm, onCancel }) {

    return (
        <div>
            <Modal open={visible} footer={null} bodyStyle={{ height: "800px" }} width={"700px"} onCancel={onCancel} style={{top:"10px"}} className="modal-round-corner" onOk={onConfirm}>
                <div style={{ margin: "30px 20px" }}>
                    <div className='r-c-sa'>
                        <img src={ic1} />
                        <img src={ic2} />
                        <img src={ic3} />
                    </div>
                    <div className='r-c-sa m-t-15'>
                        <span className='font-bold' style={{ fontSize: "18px" }}>44<span style={{ fontSize: "14px", color: "#636363", marginLeft: 10 }}>Questions</span></span>
                        <span className='font-bold' style={{ fontSize: "18px" }}>00 : 45 : 20</span>
                        <span className='font-bold' style={{ fontSize: "18px" }}>100<span style={{ fontSize: "14px", color: "#636363", marginLeft: 10 }}>Marks</span></span>
                    </div>
                    <div className='instruction' >
                        <div style={{ padding: "20px 20px", marginTop:"40px" }}>
                            <span style={{}}>
                                Questions can be of three types:
                            </span>
                            <div>
                                <strong>1. Numeric:</strong> The question will have a numeric answer. You can only enter a numeric value as the answer.
                            </div>
                            <div>
                                <strong>2. Subjective:</strong>  The question will have a descriptive answer. You can only upload a jpg/png format image of the solution. Please ensure that the quality of the image is good.
                            </div>
                            <div>
                                <strong> 3. Objective: </strong> The question will have multiple choices (maximum 4) out of which you have to pick which one is correct.
                            </div>

                        </div>
                    </div>
                    <div className='m-t-10'>
                        <span style={{ color: "#AEAEAE" }}>Subject (s)</span>
                        <ul>
                            <li>Mathematics</li>
                            <li>Physics</li>
                            <li>Chemistry</li>
                        </ul>
                    </div>
                    <div className='m-t-10'>
                        <span style={{ color: "#AEAEAE" }}>Instructions</span>
                        <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui gravida sit nullam ullamcorper ipsum, nisl leo erat. At sed sollicitudin morbi congue. Porta metus tellus vulputate tortor dis donec.
                        </div>
                    </div>
                    <div className='m-t-10'>
                        <span style={{ color: "#AEAEAE" }}>Examiner</span>
                        <div>
                        Robert E. Gracia
                        </div>
                    </div>
                    <div className='r-c-c m-t-30'>
                        <Button className="startBtn" style={{}} >START TEST</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TestModal
