import React, {useState} from "react";
import { Table ,Tag, Typography ,} from "antd";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import "../style.css"
import TeachersAttendanceDetailsModal from "../Modals/teacherAtndanceDetailsModal";
import { connect } from "react-redux";
import { attendanceDetailsModalData, storeOccurenceId } from "actions/attendanceNew";
import { SubIcon } from 'components/subIcon';
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { RiWirelessChargingLine } from 'react-icons/ri'
import moment from "moment";


export const ClassMode = ({mode}) =>{
  return (
    <>
    <Tag style={{
                color:`${mode==='Conference'?' #594099':
                mode==='Zoom 40'?' #1089FF':mode==='Broadcast'?'#FFA931':mode==='Offline'?' #28DF99':null }`,
                backgroundColor: `${mode==='Conference'?' #F6F4FE':
                mode==='Zoom 40'?' #E7F3FF':mode==='Broadcast'?'#FFF4D5':mode==='Offline'?' #E9FCF5':null}`,
                borderColor:  `${mode==='Conference'?' #F6F4FE':
                mode==='Zoom 40'?' #E7F3FF':mode==='Broadcast'?'#FFF4D5':mode==='Offline'?' #E9FCF5':null}`,
                fontSize: "8px",
                fontWeight: "bold",
              }}>
               <div style={{display:"flex"}}>
                <div>{mode==='Conference'? <RiWirelessChargingLine style={{color:"#594099"}} />:
                  mode==='Zoom 40'?<IoMdVideocam />:mode==='Broadcast'? <MdCastConnected style={{color:"#FFA931"}} />:mode==='Offline'?<IoMdChatbubbles style={{color:"#28DF99"}} />:null}
                </div>
                <div style={{marginLeft:"3px",marginTop:"-1px"}}>{mode==='Conference'?'CONFERENCE':
                mode==='Zoom 40'?'ZOOM 40':mode==='Broadcast'?'BROADCAST':mode==='Offline'?'OFFLINE':null}</div>
              </div>
          </Tag>  
    </>
  )
}

const TableCard=({insideClassesItem,showModal,attendanceDetailsModalData, storeOccurenceId})=>{
    return(
      <div style={{
        marginLeft: "10px",
        borderRadius: "6px",
        borderColor:  `${insideClassesItem?.attendanceStatus==="Present"?"#28DF99":"#FF414D"}`,
        alignItems: "center",
        border: `${insideClassesItem?.attendanceStatus==="Present"?"#28DF99":"#FF414D"} solid 1px`,
        minWidth: 170,
        width: 200,
        height: 90,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
      }}
      onClick={()=>{
        showModal();
        storeOccurenceId(insideClassesItem?.occuranceId)
        attendanceDetailsModalData(insideClassesItem)}}
      >
        <ClassMode  mode={insideClassesItem?.mode}/>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className='flex-column' style={{ width: 140, maxWidth: 140}}>
                  <div title={insideClassesItem?.subject} className="text-overflow-ellipsis" style={{fontWeight:"bold",color:"#191919",fontSize:"15px",width:"130px", padding:5,marginLeft:"5px"}}>
                    {insideClassesItem?.subject}
                  </div>
                  <div style={{fontSize:"10px",color:"#636363",marginLeft:"10px",fontWeight:500}}>
                    {/* {insideClassesItem?.startTime}-{insideClassesItem?.endTime} */}
                    {(moment(insideClassesItem?.startTime,'hh:mm:ss').format('h:mm a'))}-{(moment(insideClassesItem?.endTime,'hh:mm:ss').format('h:mm a'))}
                    </div>
                </div>
              <div className='flex-column'>
          <div style={{color:`${insideClassesItem?.attendanceStatus==="Present"?"#28DF99":"#FF414D"}`, marginLeft: 10}}>
            {insideClassesItem?.attendanceStatus==="Present" ? <CheckCircleFilled/> : <CloseCircleFilled/>}   
          </div>
        <div style={{fontSize:"10px",color:`${insideClassesItem?.attendanceStatus==="Present"?"#28DF99":"#FF414D"}`,fontWeight:"bold",marginRight:20}}>
          {insideClassesItem?.attendanceStatus==="Present"?"PRESENT":"ABSENT"}</div> 
        </div>
       </div>
      
      </div>
      
    )
  }

  const DateAndClassesCard = ({
    setIsModalVisible,
    isModalVisible,
    showModal,
    attendanceDetailsModalData,
    date,
    day,
    classesItem,
    index, storeOccurenceId}) => {
    return(
      <div style={{display:"flex",flexDirection:"row"}}>
        <div 
        style={{
          height:"120px",
          background:`${index%2===0?"#FFFFFF":"#FAFAFA"}`,
          width:"100%"}}>
          <div style={{display:"flex"}}>
        <div style={{marginTop:"40px",width:"50px",textAlign:"center"}}>
        <div style={{marginLeft:"45px",fontWeight:"bold",fontFamily:"roboto",fontSize:"15px"}}> {date}</div>
        <div style={{fontSize:"13px",color:"#636363",fontFamily:"roboto",marginLeft:"40px"}}>{day}</div>
        </div>
        <div className='p-5 m-l-40 container-scrollbar' style={{ display: "flex", overflowX: "scroll",borderLeft:"1px solid #e0e0e0",height:"120px",width:"100%"}}>
        {classesItem?.classes?.map((item) => (
             <div style={{marginTop:"10px"}}>
             <TableCard 
              insideClassesItem={item}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              showModal={showModal}
              attendanceDetailsModalData={attendanceDetailsModalData}
              storeOccurenceId={storeOccurenceId}
               />
               </div>
           ))}
        </div>
        </div>
        </div>
      </div>
    )
  }
  function TableComponent({leavesTabDataContainer,attendanceDetailsModalData,attendanceDetailsContainer, storeOccurenceId}) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal=()=>{
      setIsModalVisible(true)
    }
      return(
        <div style={{marginTop:"20px",width:"100%",border: "1px solid #E6E6E6",borderRadius:"4px",background:"#FFFFFF",height:"60%"}}>
          <div style={{height:"50px",background: "#F6F4FE",borderRadius:" 5px 5px 0px 0px"}}>
            <div style={{display:"flex",fontSize:"14px",color:"#636363",fontFamily:"roboto",fontWeight:"bold",marginLeft:"30px"}}>
          <div style={{marginTop:"14px",width:"50px"}}>DATE</div>
          <div style={{marginTop:"14px",width:"80%",marginLeft:"30px"}}>CLASS(ES)</div>
          </div>
          </div>
          <div>
            {leavesTabDataContainer?.attendanceTab?.attendanceDetailsByClass?.map((items,index)=>(
               <DateAndClassesCard
               classesItem={items}
               date={items?.date?.date}
               day={items?.date?.day}
               index={index}
               leavesTabDataContainer={leavesTabDataContainer}
               isModalVisible={isModalVisible}
               setIsModalVisible={setIsModalVisible}
               showModal={showModal}
               attendanceDetailsModalData={attendanceDetailsModalData}
               storeOccurenceId={storeOccurenceId}
               />
            ))}
          </div>
          <div>
          {isModalVisible && <TeachersAttendanceDetailsModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                
                />
          }
          </div>
            
        </div>
      )
}

const mapStateToProps = (state) => {
  const {attendanceDetailsContainer} = state.attendanceNew;
  return {attendanceDetailsContainer};
};

const mapDispatchToProps = (dispatch) => ({
  // getStudentOverview:()=>dispatch(getStudentOverview()),
  attendanceDetailsModalData:(val)=>dispatch(attendanceDetailsModalData(val)),
  storeOccurenceId:(val)=>dispatch(storeOccurenceId(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
