import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  fetchDashBoardData,
  updateStudentTestFilter,
  setSelectedSub,
  setSelectedTest,
  setDuration,
  updateTestType,
  UpdateStudentTestData,
  fetchHomePageData,
  setFilter,
} from "actions/studentPracticeTest";
import { connect } from 'react-redux';
import CombinedTestModal from './modals/combinedTestModal';
import PageDataLoader from 'components/pageDataLoader';
const chapterTestImg = require("../../Assets/studentPracticeTest/ic-chapter-test.svg").default
const fullTestImg = require("../../Assets/studentPracticeTest/ic-full-test.svg").default
const partTestImg = require("../../Assets/studentPracticeTest/ic-part-test.svg").default
const previousTestImg = require("../../Assets/studentPracticeTest/ic-previous-test.svg").default


export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}

const OverviewCards = (props) => {
  return(
      
    <>
<div className='r-afs flex-wrap full-height m-t-5' style={{overflow:"auto"}}>  
  <div style={{
      width: 280,
      
      backgroundColor: "#FFFFFF", 
      border: "1px solid #E6E6E6", 
      borderRadius: 10,
      height:120
      }}
      className="m-r-15 r-c-fs p-10 customCardShadow shadow-box hover-shadow">
      <img src={props.img} style={{width:"50px", height:"50px"}}/> 
      <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-20">
          <div style={{font: "Roboto", textAlign: "left", fontSize: 18, color: "#191919", fontWeight:"bold"}}>
                  {props.num}
          </div>
          <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12}}>
              {props.cate}
          </div>
          {
            props.lastweek==undefined||props.lastweek==0?
            null:
            props.lastweek<0?
            <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 , fontWeight:'700', color:"#FF414D"}}>
             {props.lastweek} from last week
            </div>
            :props.lastweek>0?
            <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 ,fontWeight:'700', color:"green"}}>
            + {props.lastweek} from last week
            </div>
            :null
          }

      </div>
  </div>
</div>
</>
  )
}

const SyallabusCards = (props) => {
  return(
    <>
<div className='cursor-pointer r-afs flex-wrap full-height m-t-5' style={{overflow:"auto"}} onClick={props.onClick(props.cate)}>
  <div style={{
      width: 280,
      backgroundColor: "#FFFFFF", 
      border: "1px solid #E6E6E6", 
      borderRadius: 10,
      height:200
      }}
      className="m-r-15 r-c-c-c p-10 customCardShadow cursor-pointer shadow-box hover-shadow">
                 <div className=' r-c-c-c'>
                  <img src={props.img} style={{width:"70px", height:"70px"}}/>
                    <div style={{font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight:"500"}}>
                      {props.cate}
                    </div>
                    </div>
                         <div className='flex p-2' style={{font: "Roboto bold", textAlign: "left", fontSize: 12, color:"#636363", justifyContent:"flex-wrap" }}>
                          {props.cont}
                         </div>
                 
  </div>
</div>
</>
  )
}

const RecentlyPublishedCards  = ({data, onClick}) => {
    return(
      <>
  <div className='cursor-pointer r-afs flex-wrap full-height m-t-5' style={{overflow:"hidden"}} onClick={onClick(data)}>
  <div style={{
      width: 350,
      backgroundColor: "#FFFFFF", 
      border: "1px solid #E6E6E6", 
      borderRadius: 10,
      height:90
      }}
      className="m-r-15 r-c-fs p-10 customCardShadow cursor-pointer shadow-box hover-shadow">
      <img src={chapterTestImg} style={{width:"50px", height:"50px"}}/> 
      <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-10">
          <div className='m-t-5' style={{font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight:"bold"}}>
          {data.title}
          </div>
          <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12}}>
           <div  style={{color:"#636363", fontWeight:'bold',width:'250px',overflowWrap:'break-word',wordWrap:'break-word',wordBreak:'break-word'}}> {data.subjects?.map(item=>item.name)?.join('•')}</div>  
           <text style={{fontSize:"9px",opacity:"80%"}}>{data.totalQuestions}Qs {" • "} {data.durationInMinutes}min {" • "} {data.totalPoints}marks</text>
          </div>
      </div>
  </div>
</div>
</>
  )
}

const Homepage = ({
  fetchDashBoardData,
  ongoingTest,
  publishedTests,
  scheduledTests,
  totalTestsCount,
  missedTestsCount,
  subjects,
  syllabuses,
  studentTestFilterParams,
  updateStudentTestFilter,
  setSelectedSub,
  setSelectedTest,
  setDuration,
  timeLeft,
  updateTestType,
  studentTestData,
  UpdateStudentTestData,
  fetchHomePageData,
  homePageData,
  setFilter,
  loaders
}) => {
  useEffect(()=>{
    fetchHomePageData(()=>{});
  }, []);
  const [isModalVisible,setIsModalVisible] = useState(false)
  const history = useHistory();
  const clickHandle = (type)=>{
    return () =>{
      UpdateStudentTestData("selectedTestCard", {
        type,
      });
      setFilter('selectedSyllabus', type)
      setFilter('subjectSearch', null);
      if(type=='Chapter Test'){
      history.push('/student-practise-subjects')
      }else if (type=='Part Test'){
        history.push('/student-practise-subjects')
      }else if(type=='Previous Year Paper'){
        history.push('/student-practise-subjects')
      }else{
        history.push('/student-practise-subjects')
      }
    }
  }
  const startTestHandle = (data)=>{
      
    return ()=>{
      setSelectedTest(data)
      setIsModalVisible(true)
    }
  }
  const handleCancel = ()=>{
    setIsModalVisible(false);
  }
  const OveviewData=[
    {number:homePageData?.overview?.chapterTestCount, category:'Chapter Test', image:chapterTestImg, lastweek:homePageData?.overview?.incrementFromLastWeek?.chapter, content:"To provide a formative assessment of chapter-wise expertise"},
    {number:homePageData?.overview?.partTestCount, category:'Part Test', image:partTestImg, lastweek:homePageData?.overview?.incrementFromLastWeek?.part, content:"To provide a formative assessment of a group of chapters" },
    {number:homePageData?.overview?.fullTestCount, category:'Full Test', image:fullTestImg, lastweek:homePageData?.overview?.incrementFromLastWeek?.full, content:"To simulate an actual exam with full syllabus"},
    {number:homePageData?.overview?.previousYearPaperCount, category:'Previous Year Paper', image:previousTestImg, lastweek:homePageData?.overview?.incrementFromLastWeek?.previousYear, content:"Past years papers to assess your readiness"},
  ]
  return (
    <div className='m-l-10'>
         <div style={{color:"#191919",fontWeight:"bold", fontStyle:"roboto", fontSize: "22px"}}>Practice Test</div>

        <div className='m-t-30 m-b-20' style={{color:"#191919",fontWeight:"bold",  fontStyle:"roboto", fontSize: "16px"}}>Overview</div>
                <div style={{display:"flex",flexWrap:'wrap'}}>
                {OveviewData?.map((item)=>(
                <OverviewCards
                num={item.number}
                cate={item.category}
                img={item.image}
                lastweek={item.lastweek}
                />))
                }
                </div>  
        <div>
          {
            homePageData?.published?.length?
            <>
            <div className='m-t-30 m-b-20' style={{color:"#191919",fontWeight:"bold",  fontStyle:"roboto", fontSize: "16px"}}>Recently published</div>
                  <div  className='display-flex' style={{flexWrap:'wrap'}}>    
                  {homePageData.published?.map((item)=>(
                          <RecentlyPublishedCards
                          data={item}
                          onClick={startTestHandle}
                          />
                      ))}
              </div>
              </>
              :null
          }

         </div>        

    <div className='m-t-30 m-b-20' style={{color:"#191919",fontWeight:"bold",  fontStyle:"roboto", fontSize: "16px"}}>Syllabus</div>    
            <div className='m-l-0' style={{display:"flex",flexWrap:'wrap'}}>
                {OveviewData?.map((item)=>(
                <SyallabusCards
                cate={item.category}
                img={item.image}
                cont={item.content}
                onClick={clickHandle}
                />  
                ))
                }
                </div>
    {isModalVisible && <CombinedTestModal isModalVisible={isModalVisible} handleCancel={handleCancel}/>}
    <PageDataLoader visible={loaders?.homePageLoader}/>
    </div>
  )
}
const mapStateToProps = (state) => {
  const {
    ongoingTest,
    publishedTests,
    scheduledTests,
    totalTestsCount,
    missedTestsCount,
    subjects,
    syllabuses,
    studentTestFilterParams,
    timeLeft,
    studentTestData,
    homePageData,
    loaders
  } = state.studentPracticeTest;
  return {
    ongoingTest,
    publishedTests,
    scheduledTests,
    totalTestsCount,
    missedTestsCount,
    subjects,
    syllabuses,
    studentTestFilterParams,
    timeLeft,
    studentTestData,
    homePageData,
    loaders
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashBoardData: (callback, startDate, endDate) =>
    dispatch(fetchDashBoardData(callback, startDate, endDate)),
  updateStudentTestFilter: (val, key) =>
    dispatch(updateStudentTestFilter(val, key)),
  setSelectedSub: (data) => dispatch(setSelectedSub(data)),
  setSelectedTest: (data) => dispatch(setSelectedTest(data)),
  setDuration: (duration) => dispatch(setDuration(duration)),
  updateTestType: (newType) => dispatch(updateTestType(newType)),
  UpdateStudentTestData: (key, val) =>
    dispatch(UpdateStudentTestData(key, val)),
    fetchHomePageData: (callback) => dispatch(fetchHomePageData(callback)),
    setFilter:(key,val)=>dispatch(setFilter(key,val))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);

