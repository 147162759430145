import { ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createTestModal, fetchStandardsPageData, updateFilter, updateListViewType } from 'actions/practiseTest';
import { Button, Dropdown } from 'antd';
import PageDataLoader from 'components/pageDataLoader';
import CustomMenu from 'pages/practiseTest/localComponents/customMenu';
import React, { useEffect } from 'react'
import { MdMoreVert } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TestCard from "./localComponents/practiseTestCards"
import CreateTestModal from 'pages/practiseTest/modals/createTestModal';
import ConfirmationModal from './modals/confirmationModal';
import TestPublishModal from './localComponents/testPublishModal';



const chapterTestImg = require("../../Assets/Practise Test/ic-chapter-test.svg").default
const fullTestImg = require("../../Assets/Practise Test/ic-full-test.svg").default
const partTestImg = require("../../Assets/Practise Test/ic-part-test.svg").default
const previousTestImg = require("../../Assets/Practise Test/ic-previous-test.svg").default

const PageHeader = ({ goBack, onSearch, searchVal, syllabus, handleCreateClick}) => {
  return (
    <div className="resultPageHeader full-width" style={{marginBottom: 80}}>
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
        <div className="text-md bold-700 m-l-20"> 
          <div>
            {syllabus}
          </div>
        </div>
      </div>
       <div className="testfiltersgroup m-r-50">
          {/* <SearchBox  loading={false} onChange={onSearch}  value={searchVal} className="m-r-10" placeholder="Search by test name" style={{width:230}}/> */}
          <Button onClick={handleCreateClick} className="purpleBtnWrap radius-100 purpleButtonHoverEffect">
          <PlusCircleOutlined />
          CREATE TEST
          </Button>
      </div> 
    </div>
  );
};

const OverviewCard = ({chaptersNo, testType, icon, lastweek}) => {
  return(
    <>
      <div className='cursor-pointer r-afs flex-wrap full-height m-t-5 relative' style={{overflow:"auto"}}>
      <div style={{
          width: 280,
          backgroundColor: "#FFFFFF", 
          border: "1px solid #E6E6E6", 
          borderRadius: 10,
          height:100
      }}
        className="m-l-10 r-c-fs p-10 customCardShadow cursor-pointer shadow-box hover-shadow">
        <img className='m-l-10' src={icon} style={{width:"50px", height:"50px"}}/> 
        <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-10">
            <div style={{font: "Roboto", textAlign: "left", fontSize: 20, color: "#191919", fontWeight:"750"}}>
              {chaptersNo}
            </div>
            <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12, color: "#636363"}}>
              { testType }
            </div>
            <div className='bold-700' style={{font: "Roboto", textAlign: "left", fontSize: 10 , color:"#FF414D"}}>
            {
            lastweek == undefined || lastweek==0?
            null
            :
            lastweek<0?
              <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 , fontWeight:'700', color:"#FF414D"}}>
            {lastweek} from last week
            </div>
            :
            lastweek > 0 ?
              <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 ,fontWeight:'700', color:"green"}}>
            + {lastweek} from last week
            </div>
            :
            null
          }            
        </div>
      </div>
    </div>
</div>
    </>
  )
}

const PublishedCard = ({title, standardName,subjectName, totalQues, totalTime, totalPoints,  imgval})=>{
  return(
  <>
  <div className='readyTo_Publish m-l-10 display-flex full-width m-b-10 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
              width: 400,
              backgroundColor: "#FFFFFF", 
              border: "1px solid #E6E6E6", 
              borderRadius: 10,
              height:120
  }}>
      <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={imgval}/>
      <div className='m-t-5' style={{display: "flex", flexDirection: "column"}}>
  <div className='bold-800 m-t-20 m-l-10'>{title}</div>
      <div className='text-xs m-l-10 m-t-5'>{subjectName}  </div>
          <div className='text-xs m-l-10 m-t-5'>{totalQues}Qs {"•"} {totalTime} mins {"•"} {totalPoints} marks</div>
              {/* <div className='r-jsb text-xs m-l-10 m-t-5'>
              <div className='flex-column text-xs'>
                  <div style={{color: '#AEAEAE'}}> CREATED ON </div>
              <div style={{fontWeight: "bold"}} className='bold-300r'>12/03/2022 {"•"} 06:00 PM</div>
  </div>
  <Button className='backBlue white radius-100 text-xmd' style={{width: 80, height: 27}}>
      <div>Publish</div>
      </Button>
  </div> */}
          <Dropdown overlay={<CustomMenu type={true?"publishedResult":"notPublishedResult"} 
        // test={test} 
        // handleButtonClick={handleButtonClick}
                            // isCModalVisible={isCModalVisible}
                            //  createTestModalBool={createTestModalBool}
                            // generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                            // editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                            />}
                                        placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                            </Dropdown>
  </div>
  </div>
  </>
)
}

const SyllabusCard = ({icon,testType, testDesc, history, updateFilter}) => {
return(
  <div onClick={()=>{
    updateFilter('selectedSyllabus', testType)
    updateFilter('subjectSearch', null);
    history.push("practise-test-subject-page")}} className='readyTo_Publish m-l-10 display-flex full-width m-b-10 customCardShadow cursor-pointer shadow-box hover-shadow' 
          style={{
          width: 335,
          backgroundColor: "#FFFFFF", 
          border: "1px solid #E6E6E6", 
          borderRadius: 10,
          height:235}}>
        <div className=' r-c-c-c' style={{display: "flex", flexDirection: "column"}}>      
          <img className='m-b-30' src={icon}/>
          <div className='bold-800'>{testType}</div>
          <div className='r-jsb m-l-20 m-r-20' style={{color: '#636363'}}>{testDesc}</div>
        </div>
  </div>
)
}



const PracSyllabusPage = ({selectedClassroom, updateFilter, fetchStandardsPageData, standardsMeta, 
  filter, loaders, createTestModal, updateListViewType, createTestModalBool, user, isCModalVisible, cModalType, reloadToggler}) => {
  
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }

  useEffect(() => {
    fetchStandardsPageData()
  }, [reloadToggler])
  
  const handleCreateClick = () =>{
    updateListViewType('create');
    createTestModal('test-create', user, 'class');
  }


  return (
    <div style={{ overflowY:"scroll"}}>
        <PageHeader 
                  syllabus={selectedClassroom.name}
                  goBack={goBack}
                  handleCreateClick={handleCreateClick}
                  />
        <div  className="bold-800 text-xmd m-t-50 m-l-10">Overview</div>
          <div className='display-flex flex-wrap'>
            <OverviewCard 
                        chaptersNo={standardsMeta?.overview?.chapterTestCount}
                        testType={"Chapter Test"}
                        icon={chapterTestImg}
                        lastweek={standardsMeta?.overview?.incrementFromLastWeek?.chapter}
                        />
            <OverviewCard 
                        chaptersNo={standardsMeta?.overview?.partTestCount}
                        testType={"Part Test"}
                        lastweek={standardsMeta?.overview?.incrementFromLastWeek?.part}
                        icon={partTestImg}
                        />
            <OverviewCard 
                        chaptersNo={standardsMeta?.overview?.fullTestCount}
                        testType={"Full Test"}
                        lastweek={standardsMeta?.overview?.incrementFromLastWeek?.full}
                        icon={fullTestImg}
                        />
            <OverviewCard 
                        chaptersNo={standardsMeta?.overview?.previousYearPaperCount}
                        testType={"Previous Year Papers"}
                        lastweek={standardsMeta?.overview?.incrementFromLastWeek?.previousYear}
                        icon={previousTestImg}
                        />
          </div>
          {
            standardsMeta?.recentlyPublished?.length?
            <>
            <div  className="bold-800 text-xmd m-t-50 m-l-10">Recently Published</div>
            <div className='display-flex flex-wrap m-t-10'>
            {standardsMeta?.recentlyPublished?.map((item)=>(
                <TestCard type={'published'} page={"syllabus"} test={item} imgval={chapterTestImg}/>
                ))}
            </div>
            </>
            :null
          }
            <div  className="bold-800 text-xmd m-t-50 m-l-10 m-b-10">Syllabus</div>
              <div className='display-flex flex-wrap'>
              <SyllabusCard 
                            icon={chapterTestImg}
                            testType={"Chapter Test"}
                            testDesc={" To provide a formative assessment of chapter-wise expertise "}
                            history={history}
                            updateFilter={updateFilter}
                            filter={filter}
                            />
                <SyllabusCard 
                            icon={partTestImg}
                            testType={"Part Test"}
                            testDesc={" To provide a formative assessment of a group of chapters "}
                            history={history}
                            updateFilter={updateFilter}
                            filter={filter}
                           />
                <SyllabusCard 
                            icon={fullTestImg}
                            testType={"Full Test"}
                            testDesc={"To simulate an actual exam with full syllabus "}
                            history={history}
                            updateFilter={updateFilter}
                            filter={filter}
                            />
                <SyllabusCard 
                            icon={previousTestImg}
                            testType={"Previous Year Papers"}
                            testDesc={" Past years papers to assess your readiness "}
                            history={history}
                            updateFilter={updateFilter}
                            filter={filter}
                            />
              </div>
              <PageDataLoader visible={loaders?.syllabusPageLoader}/>
          {createTestModalBool && <CreateTestModal history={history} />}
          <TestPublishModal history={history} />
           <ConfirmationModal
              isCModalVisible={isCModalVisible}
              cModalType={cModalType}
            />

    </div>
  )
}

const mapStateToProps = state =>{
  const {selectedClassroom, standardsMeta, filter, loaders, createTestModalBool, isCModalVisible, cModalType, reloadToggler}=state.practiseTest
  const {user} = state.session;
  return {selectedClassroom, standardsMeta, filter, loaders, createTestModalBool, user, isCModalVisible, cModalType, reloadToggler}
}

const mapDispatchToProps = dispatch=> ({
  // fetchHomePageData: (callback)=> dispatch(fetchHomePageData(callback)),
  // fetchStandards: (callback)=> dispatch(fetchStandards(callback)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  // setSelectedClassroom: (val) => dispatch(setSelectedClassroom(val))
  fetchStandardsPageData:(callback)=> dispatch(fetchStandardsPageData(callback)),
  updateListViewType: (data)=>dispatch(updateListViewType(data)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),

})


export default connect(mapStateToProps, mapDispatchToProps)(PracSyllabusPage)