import React from 'react'
import { fetchFilterTopic, fetchLibraryData, postCreateTopic, updateAdminChapterInfo, updateCurrentStd, updateCurrentSub, updateFilterTopic, updateSelectedChapter, updateSelectedTopic, updateStdList, uploadEdit } from 'actions/digitalLibrary';

import { useHistory } from "react-router";
import { useState, useEffect } from 'react';
import { Modal, Input, AutoComplete, Select } from 'antd';
import {
  SubHeading,
} from "components/Typography";
import { updatepdf, subjectFilter, fetchAdminLibraryData, updateSetType, fetchAdminChapterFilter, fetchClasslist } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import { Button } from 'components/Button';


function EditInformation({
  ismodelvisible,
  setmodevisible,
  updatepdf,
  subjectFilter, subjects,
  chapterstofetch,
  id, uploadEdit, types, selectedSubject, selectedStd, chapterName, chapterId, fetchAdminLibraryData,
  updateSetType, fetchAdminChapterFilter, getChaptersList, fetchClasslist, getStdList, title, editObject, updateStdList,
  fetchFilterTopic, selectedTopic, topicList, selectedChapter, fetchLibraryData, contentPage, postCreateTopic,
  updateAdminChapterInfo, updateFilterTopic, uploadContent, updateSelectedTopic, updateSelectedChapter, updateCurrentStd, updateCurrentSub
}) {
  var i = 0;
  const [subjectname, setSubjectName] = useState(null)
  const [inputvalue, setinputvalue] = useState('')
  const [chaptersarray, setchapterarray] = useState(getChaptersList)
  const [pdf, setpdf] = useState(null)
  const [chapter, setchapter] = useState('')
  const [classid, setclassid] = useState(uploadContent?.classid || null)
  const [subjectid, setsubjectid] = useState(uploadContent?.subjectId || null)
  const [chapterid, setchapterid] = useState(uploadContent?.chapterid || null)
  const [options, setOptions] = useState([]);
  const [searchChapter, setSearchChapter] = useState("")
  const [optionsChapter, setOptionsChapter] = useState([]);
  const [chapterLoader, setChapterLoader] = useState(false)
  const [subjectLoder, setSubjectLoader] = useState(false)
  const [className, setClassName] = useState(null)
  const [loader, setLoader] = useState(false)
  const [topicId, setTopicId] = useState(null)
  const [topicName, setTopicName] = useState(null)
  const [classLoader, setClassLoader] = useState(false)
  const [topicLoader, setTopicLoader] = useState(false)
  let [topicListToShow, setTopicListToShow] = useState([])
  const [createLoader, setCreateLoader] = useState(false)

  const inputchanged = (e) => {

    setinputvalue(e.target.value)
  }
  const filteroptions = (val) => {
    const dummyArray = chapterstofetch.filter((element) => {
      return (element.name).toLowerCase().includes(val.toString().toLowerCase())
    }
    )

    setchapterarray(dummyArray)
  }

  const searchResult = (query) =>
    query.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {item.name}
            </span>
          </div>
        ),
      };
    });

  useEffect(() => {
    subjectFilter('')
  }, [])

  useEffect(() => {
    setOptions(subjects ? [...searchResult(subjects)] : []);
  }, [subjects])

  useEffect(() => {
    setOptionsChapter(getChaptersList ? [...searchResult(getChaptersList)] : []);
  }, [getChaptersList])

  { console.log("AA-dataaa", title) }
  useEffect(() => {
    setinputvalue(title)
    if (selectedSubject && Object.keys(selectedSubject).length !== 0) {
      setsubjectid(selectedSubject.subjects_id)
      setSubjectName(selectedSubject.subjects_name ? selectedSubject.subjects_name : selectedSubject.subjectname)
      fetchClasslist(selectedSubject.subjects_id)
    }
    if (selectedStd && Object.keys(selectedStd).length !== 0) {
      setclassid(selectedStd.std_id)
      setClassName(selectedStd.standard ? selectedStd.standard + '-' + selectedStd.std_section : selectedStd?.className)
    }
    if (selectedChapter && Object.keys(selectedChapter).length != 0 && selectedSubject && selectedStd) {
      setchapterid(selectedChapter.chapterId)
      setchapter(selectedChapter.chapterName)
      setSearchChapter(selectedChapter.chapterName)
      // fetchAdminChapterFilter('', selectedStd.std_id, selectedSubject.subjects_id)
      fetchAdminChapterFilter('', selectedStd.std_id ? selectedStd.std_id : selectedStd.classid, selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId)


      { console.log("AA-dataaa11", selectedSubject) }
      { console.log("AA-dataaa12", selectedStd) }
      { console.log("AA-dataaa13",selectedChapter) }

      fetchFilterTopic(selectedSubject.subjects_id ? selectedSubject.subjects_id : selectedSubject?.subjectId, selectedStd.std_id ? selectedStd.std_id : selectedStd.classid  , selectedChapter.chapterId ? selectedChapter.chapterId : selectedChapter?.chapterid)
    }

    if (selectedTopic && Object.keys(selectedTopic)?.length != 0) {
      setTopicId(selectedTopic?.topicId)
      setTopicName(selectedTopic?.topicName)
    }
    if (title) {
      setinputvalue(title)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(editObject).length != 0) {
      if (editObject?.updateDetails?.subjectname && editObject?.updateDetails?.subjectId) {
        setsubjectid(editObject?.updateDetails?.subjectId)
        setSubjectName(editObject?.updateDetails?.subjectname)
        fetchClasslist(editObject?.updateDetails?.subjectId, '')
      }

      if (editObject?.updateDetails?.classid && editObject?.updateDetails?.className) {
        setclassid(editObject?.updateDetails?.classid)
        setClassName(editObject?.updateDetails?.className)
      }
      if (editObject?.updateDetails?.chapterid && editObject?.updateDetails?.chapterName) {
        console.log("editObject", editObject);
        setchapterid(editObject?.updateDetails?.chapterid)
        setchapter(editObject?.updateDetails?.chapterName)
        setSearchChapter(editObject?.updateDetails?.chapterName)
        fetchAdminChapterFilter('', editObject?.updateDetails?.classid, editObject?.updateDetails?.subjectId)
        fetchFilterTopic(editObject?.updateDetails?.subjectId, editObject?.updateDetails?.classid, editObject?.updateDetails?.chapterid)
        updateSelectedTopic(editObject?.updateDetails)
        updateSelectedChapter(editObject?.updateDetails)
        updateCurrentStd(editObject?.updateDetails)
        updateCurrentSub(editObject?.updateDetails)

      }
     
      if (editObject?.updateDetails?.topicId && editObject?.updateDetails?.topicName) {
        setTopicId(editObject?.updateDetails?.topicId)
        setTopicName(editObject?.updateDetails?.topicName)
        updateSelectedTopic(editObject?.updateDetails)
      }
      if (editObject?.id) {
        id = editObject?.id
      }
    }
  }, [])

  useEffect(() => {
    if (topicList?.length != 0) {
      setTopicListToShow(topicList)
    }
    else {
      setTopicListToShow([])
    }
  }, [topicList])

  const handleSearch = (value) => {
    if (!value) {
      setSubjectName('')
      setsubjectid(null)
    }
    else {
      setSubjectName(value)
      setSubjectLoader(true)
    }
    subjectFilter(value, () => setSubjectLoader(false))

  };

  const handleChangeSub = (value, obj) => {
    setsubjectid(null)
    setclassid(null)
    setClassName(null)
    setchapterid(null)
    setSearchChapter('')
    setTopicId(null)
    setTopicName(null)
    updateStdList([])
    if (value) {
      setSubjectName(obj?.label?.props?.children?.props?.children)
    }
  }

  const handleSearchChapter = (value) => {
    setTopicId(null)
    setTopicName(null)
    if (!value) {
      setSearchChapter('')
    }
    else {
      setSearchChapter(value)
      setChapterLoader(true)
    }
    fetchAdminChapterFilter(value, classid, subjectid, () => setChapterLoader(false))

  };

  const onSelect = (value, obj) => {
    setSubjectName(obj?.label?.props?.children?.props?.children)
    setsubjectid(value)
    setclassid(null)
    setClassName('')
    setchapterid(null)
    setchapter('')
    setSearchChapter('')
    if (value == selectedSubject?.subjects_id) {
      setclassid(selectedStd?.std_id)
      setClassName(selectedStd?.standard + '-' + selectedStd?.std_section)
      setchapterid(chapterId)
      setchapter(chapterName)
      setSearchChapter(chapterName)

    }
    setClassLoader(true)
    fetchClasslist(value, '', () => setClassLoader(false))
    fetchAdminChapterFilter('', classid, value)

  };
  const onSelectChapter = (value, obj) => {
    setchapterid(null);
    setchapter('');
    setSearchChapter('');
    setTopicId(null);
    setTopicName(null);
    updateFilterTopic([]);
    setSearchChapter(obj?.label?.props?.children?.props?.children);
    setchapter(obj?.label?.props?.children?.props?.children);
    setchapterid(value);
    setTopicLoader(true);
    if (value) {
      fetchFilterTopic(subjectid, classid, value, () => setTopicLoader(false));
    }
  };

  const onSelectTopic = (value, obj) => {
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      let object = {
        chapterId: chapterid,
        topicName: obj?.children[1]
      }
      setTopicName(null);
      postCreateTopic(object, () => setCreateLoader(false), successCallbackTopic);
    }
    else {
      setTopicId(null);
      setTopicName(null);
    }
  }

  const successCallbackTopic = () => {
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleChangeTopic = (value, obj) => {
    console.log(value, obj)
    if (value != -1) {
      setTopicId(value)
      setTopicName(obj?.children)
    }
    else if (value == -1) {
      setTopicName(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
    }
    fetchFilterTopic(subjectid, classid, chapterid, () => setTopicLoader(false))
  }

  const handleSearchTopic = (value) => {
    if (value && value.trim() != '') {
      setTopicName(value)
      let topic;
      topic = topicList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
      setTopicListToShow(topic)
      setTopicId(null)
    }
    else {
      setTopicId(null)
      setTopicName(null)
      setTopicListToShow(topicList)
    }
  }

  const successhandler = (e) => {
    e.stopPropagation()
    if (subjectid && chapterid && inputvalue && classid && chapter && topicId) {
      var objectToUpload = {
        id: id,
        subjectId: subjectid,
        chapterId: parseInt(chapterid),
        title: inputvalue,
        standardId: parseInt(classid),
        type: types,
        chapterName: chapter,
        topicId: topicId
      }
      updateSetType(types)
      setLoader(true)
      uploadEdit(objectToUpload, () => setLoader(false), successCallback)
    }
  }
  const history = useHistory();

  const successCallback = () => {
    setLoader(false)
    setpdf(null)
    setinputvalue('')
    // fetchAdminLibraryData()
    if (contentPage) {
      fetchLibraryData()
    }
    setmodevisible(false)
    history.push('/digital-library/content')

  }


  const handleCancel = (e) => {
    e.stopPropagation()
    updatepdf(null)
    setinputvalue('')
    setmodevisible(false);

  };

  console.log({ subjectname })

  return (
    <Modal className='rounded-border' width='60%' visible={ismodelvisible} footer={null} onCancel={handleCancel} maskClosable={false}>
      <div onClick={(e) => e.stopPropagation()}>
        <div className='lecturenotestitle' style={{ width: '100%', textAlign: 'center' }}>
          <p>Edit Information</p>
        </div>
        <div className='lecturnotesdescription' style={{ width: '100%', textAlign: 'center' }}>
        </div>
        <div style={{ display: 'flex', height: '400px', justifyContent: 'space-between' }}>
          <div className='uploadsearchbox' style={{ width: '100%' }}>
            <div className='full-width'>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Subject</p>
              <AutoComplete
                allowClear={true}
                dropdownMatchSelectWidth={252}
                className='autocompletetoupload'
                options={options}
                value={subjectname}
                onSelect={onSelect}
                onSearch={handleSearch}
                onChange={handleChangeSub}
                loading={subjectLoder}
                style={{
                  width: "100%",
                }}
              >
                <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Select subject" />
              </AutoComplete>
            </div>
            <div className='udloadFilesDropdown' style={{ width: '100%' }}>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Class</p>
              <Select
                defaultActiveFirstOption={false}
                notFoundContent={null}
                size="middle"
                showSearch
                value={className !== '' ? className : null}
                style={{
                  width: "100%",
                  backgroundColor: "#FAFAFA",
                }}
                bordered={false}
                disabled={subjectid ? false : true}
                placeholder={
                  <SubHeading color="#AEAEAE">
                    Search Class
                  </SubHeading>
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children[0].indexOf(input) >= 0
                }
                onChange={(value, obj) => {
                  setclassid(value)
                  setClassName(obj?.children[0] + obj?.children[1] + obj?.children[2])
                  fetchAdminChapterFilter('', value, subjectid, () => setChapterLoader(false))
                  setchapterarray([...getChaptersList])
                  updateAdminChapterInfo([])
                  setchapterid(null)
                  setchapter('')
                  setSearchChapter('')
                  setTopicId(null)
                  setTopicName(null)
                }}
              >
                {
                  getStdList.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.std} - {item.section}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            <div style={{ width: '100%' }}><p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Chapter</p>
              <div style={{ width: '100%' }}>
                <AutoComplete
                  allowClear
                  // onKeyDown={(e) => e.preventDefault()}
                  dropdownMatchSelectWidth={252}
                  className='autocompletetoupload'
                  options={optionsChapter}
                  value={searchChapter}
                  onChange={onSelectChapter}
                  onSearch={handleSearchChapter}
                  loading={chapterLoader}
                  disabled={(subjectid && classid) ? false : true}
                  style={{
                    width: "100%",
                  }}
                >
                  <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder="Search Chapter" />
                </AutoComplete>
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Topic</p>
              <Select
                allowClear
                defaultActiveFirstOption={false}
                notFoundContent={null}
                size="middle"
                showSearch
                style={{
                  width: "100%",
                  backgroundColor: "#FAFAFA",
                }}
                bordered={false}
                placeholder={
                  <div color="#AEAEAE">
                    Select Topic
                  </div>
                }
                disabled={chapterid ? false : true}
                loading={topicLoader}
                value={topicName}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children[0].indexOf(input) >= 0
                }
                // onChange={handleChangeTopic}
                onChange={handleChangeTopic}
                onSelect={onSelectTopic}
                onSearch={handleSearchTopic}
              >
                {topicListToShow?.length != 0 ?
                  topicListToShow?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))
                  :
                  topicName ?
                    <Select.Option value={-1}>Create {topicName}</Select.Option>
                    : null
                }

              </Select>

            </div>

            <div style={{ width: '100%' }}> <p id='Searchboxtitle' style={{ marginBottom: '1px', fontSize: '12px', color: '#191919', font: 'normal normal bold 12px/16px Roboto' }}>Title</p>
              <div className='uploadfilesinput'>
                <Input size='middle' bordered={false} onChange={inputchanged} value={inputvalue} placeholder='Enter title' style={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box', borderRadius: '4px',
                  opacity: '1'
                }}></Input></div>
            </div>

          </div>
        </div>

        <div style={{ width: '100%', marginTop: '3%' }}>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button style={{ width: "122px", marginRight: "5px" }} type="default" onClick={handleCancel}>CANCEL</Button>
            <Button loading={loader} style={{ marginLeft: "5px", width: "122px" }} type="primary" onClick={successhandler} disabled={(subjectid && chapterid && inputvalue && classid && chapter && topicId) ? false : true}>PUBLISH</Button>

          </div>


        </div>
      </div>

    </Modal>
  )
}
const mapStateToProps = (state) => {
  const {
    subjects,
    chapterstofetch,
    selectedSubject,
    selectedStd,
    getChaptersList,
    getStdList,
    editObject,
    topicList,
    selectedTopic,
    selectedChapter,
    uploadContent
  } = state.digitalLibrary;
  return {
    subjects,
    chapterstofetch,
    selectedSubject,
    selectedStd,
    getChaptersList,
    getStdList,
    editObject,
    topicList,
    selectedTopic,
    selectedChapter,
    uploadContent
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatepdf: (data) => dispatch(updatepdf(data)),
  subjectFilter: (data, callback) => dispatch(subjectFilter(data, callback)),
  uploadEdit: (data, callback, successCallback) => dispatch(uploadEdit(data, callback, successCallback)),
  fetchAdminLibraryData: () => dispatch(fetchAdminLibraryData()),
  updateSetType: (type) => dispatch(updateSetType(type)),
  fetchAdminChapterFilter: (value, classid, subjectid, callback) => dispatch(fetchAdminChapterFilter(value, classid, subjectid, callback)),
  fetchClasslist: (subid, chapterName) => dispatch(fetchClasslist(subid, chapterName)),
  updateStdList: (std) => dispatch(updateStdList(std)),
  fetchFilterTopic: (subId, stdId, chapterId, callback) => dispatch(fetchFilterTopic(subId, stdId, chapterId, callback)),
  fetchLibraryData: () => dispatch(fetchLibraryData()),
  postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
  updateAdminChapterInfo: (data) => dispatch(updateAdminChapterInfo(data)),
  updateFilterTopic: (data) => dispatch(updateFilterTopic(data)),
  updateSelectedTopic: (object) => dispatch(updateSelectedTopic(object)),
  updateSelectedChapter: (object) => dispatch(updateSelectedChapter(object)),
  updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),
  updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),






});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(EditInformation);
