import React, { useState } from 'react'
import "./loginPage.css"
import { connect } from 'react-redux';
import LoginRegisterForm from './loginRegisterForm';
import LoginSignInForm from './loginSignInForm';
import { setShowRegisterForm } from 'actions/loginNew';

function FormComponent({ showRegisterForm, setShowRegisterForm, isLoginModal, isBottomSheet }) {
    const [showVerifyOtpForm, setShowVerifyOtpForm] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userRegister, setUserRegister] = useState({
        role: "",
        name: "",
    });
    return (
        <div className={isBottomSheet ? 'bottom-sheet-new-login-form-parent' : 'new-login-form-parent'}>
            {showRegisterForm ?
                <LoginRegisterForm
                    setShowVerifyOtpForm={setShowVerifyOtpForm}
                    userRegister={userRegister}
                    setUserRegister={setUserRegister} 
                    isLoginModal={isLoginModal}
                    phoneNumber={phoneNumber}/>
                :
                <LoginSignInForm
                    setShowVerifyOtpForm={setShowVerifyOtpForm}
                    showVerifyOtpForm={showVerifyOtpForm}
                    setShowRegisterForm={setShowRegisterForm}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    userRegister={userRegister}
                    setUserRegister={setUserRegister} 
                    isLoginModal={isLoginModal}/>}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { showRegisterForm } = state.loginNew;
    return {
        showRegisterForm,
    }
}

const mapDispatchToProps = (dispatch) => ({
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);