const Styles = {
  toolbarContainer: {
    position: 'absolute',
    bottom: 5,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // width: 50,
    padding: '10px',
    height: 50,
    borderRadius: 100,
    boxShadow: '1px 3px 10px #d4d3d3',
    float: 'none',
    margin: '0 auto',
    justifyContent: 'center',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  toolbarBtn: {
    padding: 10,
  },
  colorPickerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  colorPickerBtn: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: 'green',
  }
};

export default Styles;
