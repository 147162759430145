import { DownCircleOutlined, EditOutlined, FileOutlined, FilePdfOutlined, MinusCircleOutlined, PlusCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react'
import { MdKeyboardBackspace } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { PDFDocument } from 'pdf-lib';
import { connect } from 'react-redux';
import { deleteQuiz, getEditQuizData, getSpecificSMData, postCreateSMCourseContent, storeQuizData } from 'actions/courseManagement';
import quiz from '../../../Assets/aulasMarket/ic-practice-quiz.svg'
import { Notification } from 'services';



const newResourcesCreate = {
    backgroundColor: "#F6F4FE",
    padding: "10px",
    boxShadow: "4px 4px 16px #E6E6E6",
    borderRadius: "7px",
    width: "100%"
}
const expandedCard = {
    backgroundColor: "#FFFF",
    padding: "10px",
    boxShadow: "4px 4px 16px #E6E6E6",
    borderRight: '1px solid #E6E6E6',
    borderLeft: '1px solid #E6E6E6',
    borderBottom: '1px solid #E6E6E6',
    height: "fit-content",
    // borderRadius: "7px",
    width: "100%"
}

const newAddtopicCard = {
    backgroundColor: "#F6F4FE",
    padding: "15px",
    boxShadow: "4px 4px 16px #E6E6E6",
    borderRadius: "7px",
    width: "100%"
}


const StudyMaterialCourseComponent = ({ storeQuizData, deleteQuiz, resources, setResources, postCreateSMCourseContent, specificCourseDataContainer, isEdit, setIsEdit, getSpecificSMData,getEditQuizData , togglePageLoader}) => {
    const history = useHistory()
    const location = useLocation()

    console.log("resources23", resources, location);
    const [isExapand, setIsExpand] = useState(false)
    const [SMfilesArray, setSMfilesArray] = useState([]);
    const [SMfileIndex, setSMfileIndex] = useState(0);
    const [studyMaterialsTopics, setStudyMaterialsTopics] = useState([
        {
            name: "",
            file: null,
            fileIndex: 0,
            preview: false,
            type: "notes",
            quizId: null
        }
    ]);

    const handleStudyMaterialUpload = (info, topicIndex) => {
        const isLt10MB = info.file.originFileObj.size / 1024 / 1024 < 10;
        if (!isLt10MB && info.file.originFileObj.type == "application/pdf") {
            Notification.error('error', "File must be smaller than 10MB!");
        } else if (info.file.originFileObj.type != "application/pdf") {
            Notification.error('error', "File extension not supported");
        } else {
            info.file.originFileObj?.arrayBuffer().then((data) => {
                PDFDocument.load(data).then((doc) => {
                    const pageCount = doc.getPageCount();
                    const updatedStudyMaterialFile = [...studyMaterialsTopics];
                    updatedStudyMaterialFile[topicIndex].pageCount = pageCount
                    setStudyMaterialsTopics(updatedStudyMaterialFile);
                }).catch((err) => {
                });
            })
            const updatedSMfilesArray = [...SMfilesArray];
            if (!updatedSMfilesArray[topicIndex]) {
                updatedSMfilesArray[topicIndex] = [];
            }
            updatedSMfilesArray[topicIndex].push(info.file.originFileObj);
            setSMfilesArray(updatedSMfilesArray);
            setSMfileIndex(SMfileIndex + 1)
            const updatedStudyMaterialFile = [...studyMaterialsTopics];
            updatedStudyMaterialFile[topicIndex].file = info.file.originFileObj;
            setStudyMaterialsTopics(updatedStudyMaterialFile);
        }
    }

    useEffect(() => {
        if (location?.state?.fromAddQuizPage) {
            const lastItem = location.state.retrieveQuizUpdatedTopics.slice(-1)[0];
            if (lastItem.quizId === null) {
                location.state.retrieveQuizUpdatedTopics.pop();
            }
            setSMfileIndex(location?.state?.SMfileIndex)
            setSMfilesArray([...location?.state?.SMfilesArray])
            setStudyMaterialsTopics(location?.state?.retrieveQuizUpdatedTopics)
            setResources(true)

        }
    }, [location?.state?.fromAddQuizPage,])

    const handleRemoveSMTopicField = (topicIndex, fromQuiz) => {
        if (fromQuiz) {
            const updatedStudyMaterialsTopics = [...studyMaterialsTopics];
            deleteQuiz(updatedStudyMaterialsTopics[topicIndex]?.quizId)
            delete updatedStudyMaterialsTopics[topicIndex]
            setStudyMaterialsTopics(updatedStudyMaterialsTopics);
        } else {
            const updatedStudyMaterialsTopics = [...studyMaterialsTopics];
            updatedStudyMaterialsTopics[topicIndex].file = null;
            delete updatedStudyMaterialsTopics[topicIndex].fileUrl;
            updatedStudyMaterialsTopics[topicIndex].pageCount = 0;
            updatedStudyMaterialsTopics[topicIndex].name = '';
            setStudyMaterialsTopics(updatedStudyMaterialsTopics);
            const updatedSMfilesArray = [...SMfilesArray];
            updatedSMfilesArray[topicIndex] = null;
            setSMfilesArray(updatedSMfilesArray);
        }
    }

    const addStudyMaterialsTopic = () => {
        if ((studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.file != null) || (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.quizId != null)) {
            setStudyMaterialsTopics([...studyMaterialsTopics, { name: '', file: null, fileIndex: SMfileIndex, preview: false, type: "notes" }]);
        }
    };

    const disabled =  studyMaterialsTopics.some(item => item.name && !item.quizId && !item.file);

    const handleSave = () => {
        if(disabled) return;
        postCreateSMCourseContent(
            studyMaterialsTopics,
            SMfilesArray,
            () => { },
        
            () => { history.push("/course-details"), setResources(false) /* getSpecificSMData(() => togglePageLoader(false)) */ }
        )
        
    }

    useEffect(() => {
        if (isEdit) {
            const topicsTemp = specificCourseDataContainer?.topics?.map((topic, index) => {
                const newTopic = {
                    name: topic?.name,
                    file: topic?.file,
                    fileIndex: !topic?.quizId ? index : null,
                    preview: topic?.preview,
                    id: topic?.id,
                    pageCount: topic?.pageCount,
                    fileUrl: topic?.file?.url,
                    type: topic?.type,
                    quizId: topic?.quizId
                };
                return newTopic;
            });
            setStudyMaterialsTopics(specificCourseDataContainer?.topics?.length > 0 ? topicsTemp : [
                {
                    name: "",
                    file: null,
                    fileIndex: 0,
                    preview: false
                }
            ]);
        }

    }, [specificCourseDataContainer?.course?.id])

    return (
        <div>
            <div style={newResourcesCreate}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: 10, paddingLeft: 10 }}>

                        <MdKeyboardBackspace
                            className="text-mdl cursor-pointer"
                            label="Back"
                            onClick={() => (setResources(false))}
                        />
                        <div style={{ fontSize: "18px", color: "#191919", marginLeft: "10px", fontWeight: "bold" }}>Resources</div>
                    </div>
                    <div style={{ display: "flex", gap: 20 }}>
                        <div style={{ color: disabled ? '#AEAEAE':'#1089FF', fontSize: "15px", fontWeight: 500,cursor: disabled ? "not-allowed" :"pointer" }} onClick={handleSave}>SAVE</div>
                        <div onClick={() => { setIsExpand(!isExapand) }}> {!isExapand ? <DownCircleOutlined style={{ fontSize: "18px", color: "#594099", marginTop: "3px" ,cursor:"pointer"}} /> : <UpCircleOutlined style={{ fontSize: "18px", color: "#594099", marginTop: "3px" }} />}</div>
                    </div>
                </div>

            </div>
            {!isExapand ?

                <div style={expandedCard}>
                    <div style={{ ...newAddtopicCard }}>
                        <div>
                            {studyMaterialsTopics?.map((topic, topicIndex) => (
                                <div>
                                    {topic?.quizId ?
                                        <div className='m-b-20' style={{ width: '100%', height: 'fit-content' }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div style={{ height: 50, width: "100%", padding: "5px 10px", display: "flex", alignItems: "center" }}>
                                                    <img src={quiz} style={{ width: "20px", height: "20px", marginRight: 10 }} />
                                                    <div style={{ color: "#636363", fontWeight: "bold" }}>{topic?.name}</div>
                                                </div>
                                                <EditOutlined
                                                    onClick={() => {
                                                        storeQuizData([])
                                                        getEditQuizData(topic?.quizId, () => { })
                                                        history.push({
                                                            pathname: "/course/add-quiz",
                                                            state: {
                                                                key: {
                                                                    fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                                    searchedValue: location?.state?.key?.searchedValue,
                                                                    fromSubcategory: location?.state?.key?.fromSubcategory,
                                                                    fromCourseCard: location?.state?.key?.fromCourseCard,
                                                                    fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                                    fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                                                    isEdit: location?.state?.key?.isEdit,
                                                                },
                                                                fromStudyMaterial:
                                                                {
                                                                    studyMaterialsTopics: studyMaterialsTopics,
                                                                    editTrue: true,
                                                                    SMfilesArray: [...SMfilesArray],
                                                                    SMfileIndex: SMfileIndex,
                                                                }
                                                            }
                                                        })
                                                        storeQuizData([])
                                                    }}
                                                    className='m-t-5'
                                                    style={{ color: '#1089FF', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                />
                                                <MinusCircleOutlined
                                                    className='m-t-5'
                                                    style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                    onClick={() => handleRemoveSMTopicField(topicIndex, true)}
                                                />
                                            </div>
                                            <div style={{ borderBottom: "1px solid #636363" }}></div>

                                        </div> :
                                        (<>
                                            <div className='m-b-5' style={{ fontSize: "12px", color: "#8C8C8C", fontWeight: 500 }}>Enter resource name</div>

                                            <Input
                                                placeholder='Enter resource name'
                                                bordered={null}
                                                value={topic?.name}
                                                onChange={(e) => {
                                                    const updatedTopics = [...studyMaterialsTopics];
                                                    updatedTopics[topicIndex].name = e.target.value;
                                                    setStudyMaterialsTopics(updatedTopics);
                                                }}
                                            />
                                            <div style={{ borderBottom: "1px solid #636363", marginTop: 10 }}></div>

                                            <div>
                                              
                                                {studyMaterialsTopics?.[topicIndex]?.file ?
                                                    <>

                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <div style={{ height: 50, width: "100%", padding: "5px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <div style={{ display: "flex" }}>
                                                                    <FilePdfOutlined style={{ color: "#FF414D", marginRight: 10, fontSize: 20 }} />
                                                                    <div style={{ color: "#636363" }}>{studyMaterialsTopics?.[topicIndex]?.file?.name}</div>
                                                                </div>
                                                                <div style={{ color: "#AEAEAE" }}>{studyMaterialsTopics?.[topicIndex]?.pageCount} page(s)</div>
                                                            </div>

                                                            <MinusCircleOutlined
                                                                className='m-t-5'
                                                                style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                                                onClick={() => handleRemoveSMTopicField(topicIndex, false)}
                                                            />
                                                        </div>
                                                        <div style={{ borderBottom: "1px solid #636363", marginBottom: 10 }}></div>
                                                    </>
                                                    :

                                                    <Dragger
                                                        multiple={true}
                                                        showUploadList={false}
                                                        accept='.pdf'
                                                        onChange={(e) => handleStudyMaterialUpload(e, topicIndex)}
                                                        style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", margin: "10px 0px" }}
                                                    >
                                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: "10px" }}> <FileOutlined style={{ color: "#1089FF", fontSize: "18px", marginTop: "3px" }} /> <span style={{ color: "#636363", fontSize: 14, fontWeight: 600, marginLeft: "10px", marginTop: 3 }}> Drag and drop or </span><span style={{ color: "#1089FF", margin: "0px 5px", marginTop: 3 }}>Choose file</span> <span style={{ marginTop: 3 }}>to Upload</span></div>
                                                        <div style={{ marginRight: 30, display: "flex", justifyContent: "flex-end", marginTop: -23 }}>Supported file: Pdf</div>
                                                    </Dragger>}
                                            </div>
                                        </>)}

                                </div>
                            ))}
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ fontSize: 15, color: "#1089FF", fontWeight: "bold", cursor: (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.file != null) || (studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.name !== '' && studyMaterialsTopics[studyMaterialsTopics?.length - 1]?.quizId != null) ? "pointer" : "not-allowed", marginRight: 10 }} onClick={addStudyMaterialsTopic}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD TOPIC</span></div>
                            <div style={{ fontSize: 15, color: "#594099", fontWeight: "bold", cursor: "pointer", marginLeft: 10 }}
                                onClick={() => {
                                    storeQuizData([])
                                    history.push({
                                        pathname: "/course/add-quiz",
                                        state: {
                                            key: {
                                                fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                                searchedValue: location?.state?.key?.searchedValue,
                                                fromSubcategory: location?.state?.key?.fromSubcategory,
                                                fromCourseCard: location?.state?.key?.fromCourseCard,
                                                fromDashboardSearch: location?.state?.key?.fromDashboard,
                                                fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                                isEdit: location?.state?.key?.isEdit,

                                            },
                                            fromStudyMaterial:
                                            {
                                                SMfilesArray: [...SMfilesArray],
                                                SMfileIndex: SMfileIndex,
                                                studyMaterialsTopics: [
                                                    ...studyMaterialsTopics,
                                                    {
                                                        type: "quiz",
                                                        quizId: null
                                                    }
                                                ],
                                            }
                                        }
                                    })
                                }}><PlusCircleOutlined /><span style={{ margin: "0px 10px" }}>ADD QUIZ</span></div>
                        </div>
                    </div>


                </div> : (<></>)
            }
        </div >

    )

}


const mapStateToProps = (state) => {
    const { courseTypeName, specificCourseDataContainer } = state.courseManagement;
    return { courseTypeName, specificCourseDataContainer };
};
const mapDispatchToProps = (dispatch) => ({
    storeQuizData: (val) => dispatch(storeQuizData(val)),
    deleteQuiz: (id) => dispatch(deleteQuiz(id)),
    postCreateSMCourseContent: (studyMaterialsTopics, SMfilesArray, callBack, successCallBack) => dispatch(postCreateSMCourseContent(studyMaterialsTopics, SMfilesArray, callBack, successCallBack)),
    getSpecificSMData: (callback) => dispatch(getSpecificSMData(callback)),
    getEditQuizData: (id, callback) => dispatch(getEditQuizData(id, callback)),





});

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterialCourseComponent)

