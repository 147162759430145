import React, { useEffect, useState } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { newCourseCreationCardStyle } from './courseCreationPage'
import AnimatedTextField from 'components/AnimatedTextField'
import { connect } from 'react-redux'
import { setCreateCourseObj } from 'actions/courseManagement'
import { CheckCircleOutlined } from '@ant-design/icons'

function CoursePreview({ isExpanded, setIsExpanded, setCreateCourseObj, courseCreateObj, location, clearValues, expandedSection }) {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const [previewInfoFilledCount, setPreviewInfoFilledCount] = useState(0)
    const currentCourseObj = {
        previewLink: courseCreateObj?.previewLink
    };
    useEffect(() => {
        let count = 0;
        for (const item in currentCourseObj) {
            if (currentCourseObj[item] != null) {
                count++;
            }
        }
        setPreviewInfoFilledCount(count)
    }, [isExpanded, courseCreateObj]);

    useEffect(() => {
        return (() => {
            if (location?.state?.key?.isEdit != 'isFromEdit') {
                clearValues()
            }
        })
    }, [])

    const [videoInfo, setVideoInfo] = useState(null);
    const youtubeLink = courseCreateObj?.previewLink;
    const parts = youtubeLink?.split('v=');
    const videoId = parts?.[1];

    useEffect(() => {
        const fetchVideoInfo = async () => {
            try {
                const response = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`);
                const data = await response.json();
                setVideoInfo({
                    thumbnailUrl: data?.thumbnail_url,
                    title: data?.title
                });
            } catch (error) {
                console.error('Error fetching video info:', error);
            }
        };
        fetchVideoInfo();
    }, [videoId]);

    return (
        <div className='m-t-20' style={{ ...newCourseCreationCardStyle, marginBottom: 2 }}>
            <div className='r-c-sb'>
                <div style={{ color: "#696969", fontSize: 18, fontWeight: 700 }}>Course preview</div>
                <div className='r-ac'>
                    {!isExpanded && previewInfoFilledCount > 0 ? <div className='m-r-10' style={{ color: "#696969" }}>{previewInfoFilledCount} {previewInfoFilledCount == 1 ? "field" : "fields"}</div> : <></>}
                    {isExpanded ?
                        <FiChevronDown style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} /> :
                        <FiChevronRight style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} />
                    }
                </div>
            </div>
            {!expandedSection && courseCreateObj?.previewLink && videoId ?
                <div className='r-ac m-t-10'>
                    {videoInfo?.thumbnailUrl ? <img src={videoInfo?.thumbnailUrl} style={{ width: 100, height: 70, borderRadius: 8 }} /> : null}
                    <div style={{ marginLeft: videoInfo?.thumbnailUrl ? 10 : 0 }}>
                        {videoInfo?.title ? <div style={{ color: "#191919" }}>{videoInfo?.title}</div> : null}
                        <div style={{ color: "#AEAEAE", fontSize: 12 }}>{courseCreateObj?.previewLink}</div>
                    </div>
                </div>
                : <></>}
            {isExpanded ?
                <div className='r-c-sb' style={{ width: "100%" }}>
                    <AnimatedTextField
                        label={"Enter YouTube link"}
                        isMandatory={false}
                        inputId={"-1000"}
                        value={courseCreateObj?.previewType == "link" ? courseCreateObj?.previewLink : null}
                        handleInputChange={(e) => {
                            if (e.target.value) {
                                setCreateCourseObj("previewLink", e.target.value)
                                setCreateCourseObj("previewType", "link")
                            } else {
                                setCreateCourseObj("previewLink", null)
                                setCreateCourseObj("previewType", null)
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === " ") {
                                e.stopPropagation(); // Stop event propagation
                            }
                        }}
                        style={{ marginTop: 30, width: "95%" }}
                    />
                    {courseCreateObj?.previewLink && courseCreateObj?.previewLink === null || courseCreateObj?.previewLink === "" || courseCreateObj?.previewLink?.match(regExp) ?
                        <img src={require("../../Assets/courseManagementV4/green-check-icon.svg").default} style={{ width: 20, marginTop: 20 }} />
                        :
                        <img src={require("../../Assets/courseManagementV4/black-check-icon.svg").default} style={{ width: 20, marginTop: 20 }} />
                    }
                </div>
                : <></>}
        </div>
    )
}
const mapStateToProps = (state) => {
    const { courseCreateObj } = state.courseManagement
    return {
        courseCreateObj
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CoursePreview);