import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";


import { Row, Col, Collapse } from "antd";
import { CaretRightOutlined, FilterOutlined, EditOutlined } from "@ant-design/icons";
import { SquareSubIcon } from "components/subIcon";

import ChapterRow from "./chapterRow";
import DropDownCheckBox from "./dropDownCheckBox";
import '../../design/layout.css';
const NoData = require("../../Assets/ill-no-data.svg").default



const { Panel } = Collapse;

const NoDataCard = () => {
	return (
		<div className='r-c-c-c'>
		<img src={NoData} />
		<div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "18px" }}>No Chapters Found</div>
		</div>
	)
}


function SubjectRow({ subject, subIdx, std, section, user, setIsEditSubjectModalVisible, setShowEditChapters }) {

	const location = useLocation();

	const [isExpended, setExpended] = useState(false);
	const [isActive, setActive] = useState(false);


	const progressFilterArray = [
		{
			label: 'Completed',
			id: 'Completed'
		},
		{
			label: 'Pending',
			id: 'Pending'
		},
		{
			label: 'Not Started',
			id: 'Not Started'
		},
	]
	const [progressFilter, setProgressFilter] = useState(['Pending', 'Completed', 'Not Started'])

	const onProgressFilterSelect = (item) => {
		if (progressFilter.includes(item.id))
			setProgressFilter(prev => prev.filter(el => el != item.id))
		else setProgressFilter(prev => [...prev, item.id])
	}

	const [chaptersArray, setChaptersArray] = useState([]);

	useEffect(() => {
		setChaptersArray(subject?.chapters?.filter(chapter => {
			if (!progressFilter.includes('Completed') && chapter.progress == 100) return false
			if (!progressFilter.includes('Pending') && chapter.progress < 100 && chapter.progress > 0) return false
			if (!progressFilter.includes('Not Started') && chapter.progress == 0) return false
			return true
		}))
	}, [progressFilter, subject]);

	const header = (
		<Row style={{ alignItems: 'center', marginLeft: 10}}>
			<div style={{marginRight: 20}}>
				<SquareSubIcon name={subject.name} icon={subject.icon} size={24} />
			</div>

			<div 
				style={{
					color: "#636363", 
					fontSize: "1.2em",
					// fontWeight: '500',
					marginRight: 20,
					width: '72%'
				}}
			>{subject.name}</div>

			{(user?.role?.[0] == 'Teacher' && location.pathname == '/syllabus') && 
			<div style={{
					color: "#636363",
					right: 190,
					position: 'absolute'
				}}>
				{std} - {section}
			</div>}

			<div style={{
					color: "#636363",
					right: ((user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) && location.pathname != '/syllabus') ? 200 : 60,
					position: 'absolute'
				}}
			>
					{subject?.chapters.length} Chapters
			</div>

			{((user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin"))
				&& location.pathname != '/syllabus') &&
				<div className='cursor-pointer' 
					onClick={(e) => { 
						e.stopPropagation(); 
						setIsEditSubjectModalVisible(true)
						setShowEditChapters(subject);
					}} 
					style={{ 
						color: "#1089FF", 
						fontSize: 16, 
						fontWeight: "bold",
						right: 90,
						position: 'absolute' 
					}}><EditOutlined /> EDIT</div>
			}
		</Row>
	)

	return (
		<Collapse
		className="batch-collapse"
			// bordered={false}
			// defaultActiveKey={["0"]}
			expandIcon={({ isActive }) => (
				<CaretRightOutlined rotate={isActive ? 90 : 0} style={{ marginTop: 18 }} />
			)}
			style={{
				marginBottom: 20,
				backgroundColor: isActive ? '#F6F4FE' : 'white',
				borderRadius: 10,

			}}
			onChange={() => {
				if(isActive) setExpended(false)
				setActive(prev => !prev)
			}}
			key={subIdx}
		>
			<Panel
				header={header}
				key={subIdx}
			>
				{subject?.chapters?.length ?
					<Row
						style={{
							fontWeight: 600,
							color: "#636363",
							minHeight: 40
						}}
						className="r-ac"
					>
						<Col span={2}>Chapter</Col>
						<Col span={7}>Name</Col>
						<Col span={(user?.role?.[0] == 'Teacher' &&
							location.pathname == '/syllabus') ? 13 : 9}
							className="r-c-sb"
							style={{
								paddingRight: (user?.role?.[0] == 'Teacher' &&
								location.pathname == '/syllabus') ? 65 : 35
							}}
						>Progress
							<DropDownCheckBox
								placeholder={<FilterOutlined style={{ cursor: 'pointer' }} />}
								DATA={progressFilterArray}
								checkedArray={progressFilter}
								onChange={onProgressFilterSelect}
							/>
						</Col>
						
						{(user?.role?.[0] != 'Teacher' ||
							location.pathname != '/syllabus') &&
							<Col offset={1} span={3} >Updated by</Col> 
						}
						<Col span={2}>Related Content</Col>
					</Row> :
				<NoDataCard/>}

				{chaptersArray?.map((chapter, chapIdx) => {
					if (isExpended || chapIdx < 10)
						return <ChapterRow
							chapter={chapter}
							chapIdx={subject?.chapters?.indexOf(chapter)}
							std={std}
							section={section}
							subjectName={subject?.name}
						/>

					if (chapIdx == 10)
						return <div
							className=" bold-600 text-center blue cursor-pointer"
							onClick={() => { setExpended(true) }}
							style={{ fontSize: 13, marginTop: 10 }}
						> VIEW ALL </div>
				})}
			</Panel>
		</Collapse>
	)
}


const mapStateToProps = (state) => {
	const { user } = state.session;
	return { user }
};


const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectRow);


/*
24 --> antd row
chapter 	name 	progress 	updated  	related
2			8			8			4			2

2 			8			12			0 			2
*/