import { Col, Row, Select } from 'antd';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react';
import FeeSummary from './feeSummary'
import PaymentRequestsCard from './paymentRequests'
import { Button } from 'components/Button';
import { ArrowRightOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { getWalletDashBoardData, getWalletFeeOverViewData, setFromPaymentHistory, storePaymentHistory, storePaymentReqModalData, storeUserKYcData } from 'actions/aulasWallet';
import PageDataLoader from 'components/pageDataLoader';
import "./styles.css"
import EmailIdModal from './modal/emailIdModal';
import { setSpecificUserId } from 'actions/userManagement';
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;


const bodyStyle = {
    background: "#FAFAFA",
    margin: -20,
    padding: 20,
    overflow: "hidden",
};

const Info = () => {
    return (
        <div style={{ color: "#636363", fontSize: 11, display: "flex", padding: 10 }}>
            <InfoCircleOutlined style={{ marginTop: 3 }} />
            <div style={{ marginLeft: 5 }}>Individual below 18 years of age should submit legal guardian’s KYC</div>
        </div>
    )
}
const KycVerificationCard = ({ status, percentage, history }) => {
    return (
        <>
            {(status == 'Approved') ?
                <div style={{ backgroundColor: "#E7F3FF", height: "100%", padding: 20 }}>
                    <img src={require("Assets/aulasWallet/ill-kyc-verification.svg").default} alt='' style={{ width: "43%", height: "43%" }} />
                    <div className='r-c-sb m-t-20'>
                        <div>
                            <div className='font' style={{ color: "#1089FF" }}>
                                <span style={{ fontSize: 22, color: "#191919" }}>KYC Verified</span>
                                <img className='m-l-10 m-b-5' src={require("Assets/aulasWallet/Path 54352.svg").default} alt='' />
                            </div>
                            <div className='font m-t-5' style={{ color: "#636363", fontSize: 16 }}>View KYC details and documents</div>
                        </div>
                        <ArrowRightOutlined style={{ fontSize: 24, cursor: "pointer", color: "#AEAEAE" }} onClick={() => history.push('/kyc-details')} />
                    </div>
                </div>
                :
                (status == "Submitted" && percentage == 100) ?
                    <div style={{ backgroundColor: "#E7F3FF", height: "100%", padding: 20 }}>
                        <img src={require("Assets/aulasWallet/ill-kyc-verification.svg").default} alt='' style={{ width: "38%", height: "38%" }} />
                        <div className='m-t-10' style={{ fontSize: 22, color: "#191919", fontWeight: "bold" }}>KYC details submitted!</div>
                        <div className='font m-t-5' style={{ color: "#636363", fontSize: 16 }}>Your KYC details have been received. We will notify you once it’s approved.</div>
                    </div>
                    :
                    (status == "Rejected" || (status == "Pending" && percentage == 0) || status == null) ?
                        <>
                            <div style={{ backgroundColor: "#E7F3FF", padding: 20 }}>
                                <div className='r-jsb'>
                                    <img className='m-t-10' src={require("Assets/aulasWallet/ill-kyc-verification.svg").default} alt='' style={{ width: "35%", height: "35%" }} />
                                    <div className='m-l-20'>
                                        <div className='font' style={{ color: status == 'Rejected' ? "red" : "#191919", fontSize: 22 }}>
                                            {status == 'Rejected' ?
                                                <span>
                                                    <img src={require("Assets/aulasWallet/warningFilled.svg").default} alt='' style={{ paddingRight: 5, height: 20, marginTop: -3 }} />
                                                    KYC Rejected
                                                </span>
                                                :
                                                "KYC Verification"
                                            }
                                        </div>
                                        <div style={{ color: "#636363", fontSize: 14 }}>{status == 'Rejected' ? "Some error/inconsistency has been noted in the submitted details/documents. Please review the form and submit it again." : "Get your KYC done to access the complete power of aulas Wallet"}</div>
                                    </div>
                                </div>
                                <Button
                                    type='primary'
                                    style={{
                                        width: "100%",
                                        height: 35,
                                        marginTop: 20
                                    }}
                                    onClick={() => history.push("/complete-kyc")}>
                                    {status == 'Rejected' ? "VIEW FORM" : "CONTINUE"}
                                </Button>
                            </div>
                            <Info />
                        </>
                        :
                        (status == "Pending" && percentage > 0) ?
                            <>
                                <div style={{ backgroundColor: "#E7F3FF", padding: 20 }}>
                                    <div className='r-jsb'>
                                        <img className='m-t-10' src={require("Assets/aulasWallet/ill-kyc-verification.svg").default} alt='' style={{ width: "37%", height: "37%" }} />
                                        <div className='m-l-20 m-t-20'>
                                            <div className='font' style={{ color: "#191919", fontSize: 22 }}>KYC Verification</div>
                                            <div style={{ color: "#636363", fontSize: 14 }}>Get your KYC done to access the complete power of aulas Wallet</div>
                                            {percentage > 0 ? <div style={{ color: "#FFA931", fontSize: 14, fontWeight: "bold" }}>{percentage} % completed</div> : <></>}
                                        </div>
                                    </div>
                                    <Button
                                        type='primary'
                                        style={{
                                            width: "100%",
                                            height: 35,
                                            marginTop: 20
                                        }}
                                        onClick={() => history.push("/complete-kyc")}>
                                        CONTINUE
                                    </Button>
                                </div>
                                <Info />
                            </>
                            :
                            <></>
            }
        </>
    )
}
const PaymentHistoryCard = ({ history, setFromPaymentHistory, storePaymentHistory }) => {
    return (
        <div style={{ padding: 20 }}>
            <img src={require("Assets/aulasWallet/ic-fee.svg").default} alt='' style={{ width: "23%", height: "23%" }} />
            <div className='m-t-10 font' style={{ color: "#191919", fontSize: 22 }}>Transaction History</div>
            <div className='font m-t-5' style={{ color: "#636363", fontSize: 14 }}>Review all payments that have been recorded on your account.</div>
            <div className='font cursor-pointer m-t-10' style={{ color: "#1089FF", fontSize: 18 }} onClick={() => { setFromPaymentHistory(true); storePaymentHistory([]); history.push('/payment-history') }}>SEE ALL TRANSACTION HISTORY</div>
        </div>
    )
}
function AulasWalletDashBoard({ userKycStatus, getWalletDashBoardData, walletDashboardData, storeUserKYcData, setFromPaymentHistory, storePaymentHistory, getWalletFeeOverViewData, storePaymentReqModalData, setSpecificUserId, user }) {
    const history = useHistory();
    const [pageLoader, togglePageLoader] = useState(false);
    const [isEmailIdModalVisible, setIsEmailIdModalVisible] = useState(false);

    useEffect(() => {
        storeUserKYcData({})
        setSpecificUserId(user?.id)
        togglePageLoader(true);
        getWalletDashBoardData(() => togglePageLoader(false))
        getWalletFeeOverViewData(false, () => togglePageLoader(false))
    }, [])
    return (
        <div>
            <div style={{ marginTop: -10, height: 50 }}>
                <PageHeader title='aulas Wallet' />
            </div>
            <div style={bodyStyle}>
                <Row style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Col style={{width:"68%"}}>
                        <Row>
                            <Col span={24} className='container boxShadow118 border-16 m-t-20' style={{ height: "fit-content", minWidth: 400, overflow: 'hidden', padding: 20, width: "100%" }} >
                                <FeeSummary
                                    togglePageLoader={togglePageLoader}
                                    getWalletFeeOverViewData={getWalletFeeOverViewData} />
                            </Col>
                            <Col span={24} className='m-t-20'>
                                <Row style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Col className='boxShadow118 border-16' style={{ width: "49%", height: "fit-content" }}>
                                        <KycVerificationCard
                                            status={walletDashboardData?.kycstatus}
                                            percentage={walletDashboardData?.percentage}
                                            history={history}
                                        />
                                    </Col>
                                    <Col className='boxShadow118 border-16' style={{ width: "48%", height: "fit-content" }}>
                                        <PaymentHistoryCard
                                            history={history}
                                            setFromPaymentHistory={setFromPaymentHistory}
                                            storePaymentHistory={storePaymentHistory} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='container boxShadow118 border-16 m-t-20' style={{ height: "708px",/*  minWidth: 390, */ width:"30%" }} >
                        <div className='r-c-sb font' style={{ fontSize: "16px",padding:20 }}>
                            <div style={{ color: "#191919" }}>Payment Requests<span style={{ color: "#aeaeae" }}> ({walletDashboardData?.totalLinksPending?.linkcount})</span></div>
                            <div style={{ color: "#1089FF", cursor: "pointer" }} onClick={() => { setFromPaymentHistory(false); storePaymentHistory([]); history.push({ pathname: '/payment-request', state: { key: "fromViewAll" } }) }}>VIEW ALL</div>
                        </div>
                        <div className='scrollContainer' style={{ height: "635px", overflowY: "scroll"}}>
                            {walletDashboardData?.pendingTransactions?.map((item, index) => (
                                <div style={{ display: index <= 3 ? 'view' : "none" }}>
                                    <PaymentRequestsCard
                                        item={item}
                                        setIsEmailIdModalVisible={setIsEmailIdModalVisible}
                                        storePaymentReqModalData={storePaymentReqModalData} />
                                </div>
                            ))}
                            {
                                walletDashboardData?.totalLinksPending?.linkcount == 0 ? (
                                    <div className="r-c-c-c" style={{ width: "100%", height: "65vh" }}>
                                        <div
                                        >
                                            <img src={illNoData} />
                                        </div>
                                        <div>
                                            <h4>
                                                <b>No data!</b>
                                            </h4>
                                        </div>
                                        <div>
                                            <h5>There are no data to show yet. Please check back later</h5>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            {isEmailIdModalVisible &&
                <EmailIdModal
                    isEmailIdModalVisible={isEmailIdModalVisible}
                    setIsEmailIdModalVisible={setIsEmailIdModalVisible}
                />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = state => {
    const { userKycStatus, walletDashboardData } = state.aulasWallet;
    const { user } = state.session
    return { userKycStatus, walletDashboardData, user };
};

const mapDispatchToProps = dispatch => ({
    getWalletDashBoardData: (callBack) => dispatch(getWalletDashBoardData(callBack)),
    getWalletFeeOverViewData: (skipGraph, callback) => dispatch(getWalletFeeOverViewData(skipGraph, callback)),
    storeUserKYcData: (val) => dispatch(storeUserKYcData(val)),
    setFromPaymentHistory: (val) => dispatch(setFromPaymentHistory(val)),
    storePaymentHistory: (val) => dispatch(storePaymentHistory(val)),
    storePaymentReqModalData: (val) => dispatch(storePaymentReqModalData(val)),
    setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AulasWalletDashBoard);
// export default AulasWalletDashBoard