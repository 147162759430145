import { setCourseDetails } from "actions/userManagement";
import { Button, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { connect } from "react-redux";

function EidtScholerShipAddModal({
  setShowEditSA,
  ShowEditSA,
  setShowEditOffline,
  studentCourseDetails,
  isAdditionModal,
  setIsAdditionModal,
  setCourseDetails,
}) {
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  const [amountOrPercentage, setAmountOrPercentage] = useState(null);
  const [btnLoader, setBtnLoade] = useState(false);
  return (
    <div>
      <Modal
        className="scholorshipAndAdditionModalStyles"
        title="Basic Modal"
        visible={ShowEditSA}
        closable
        // onOk={handleOk}
        onCancel={()=> setShowEditSA(false)}
      >
        <div>
          <div
            className="flex_column_center"
            style={{
              fontWeight: "600",
              fontSize: "20px",
              fontFamily: "roboto",
              justifyContent: "center",
              marginBottom: "35px",
            }}
          >
            {isAdditionModal ? "Addition" : "Scholarship"} details
          </div>
          <div style={{ width: "100%" }}>
            <p className="dropDownLabelBasicStyle">
              {isAdditionModal ? "Addition" : "Scholarship"} name{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <Input
              // placeholder="Select  Class..."
              defaultValue={null}
              style={{ width: "100%" }}
              value={name}
              onChange={(e) => {
                // setCourseDetails()
                // setName(e.target.value);
                if (e.target.value == " ") {
                  setName(null);
                } else {
                  setName(e.target.value);
                }
              }}
            ></Input>
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <p className="dropDownLabelBasicStyle">
              {isAdditionModal ? "Addition" : "Scholarship"} details
            </p>
            <TextArea
              // placeholder="Select  Class..."
              defaultValue={null}
              style={{ width: "100%", height: "100px" }}
              // value={classId}
              value={desc}
              onChange={(e) => {
                // setDesc(e.target.value);
                if (e.target.value == " ") {
                  setDesc(null);
                } else {
                  setDesc(e.target.value);
                }
              }}
            ></TextArea>
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <p className="dropDownLabelBasicStyle">
              {isAdditionModal ? "Amount" : "Amount"}{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <Input
              prefix="₹"
              type={"number"}
              // placeholder="Select  Class..."
              defaultValue={null}
              style={{ width: "100%" }}
              // value={classId}
              value={amountOrPercentage}
              onChange={(e) => {
                // if (e.target.value?.indexOf("e") != -1) {
                // setAmountOrPercentage(amountOrPercentage);
                // } else {
                setAmountOrPercentage(e.target.value);
                // }
              }}
            ></Input>
          </div>
          {/* </div> */}
        </div>
        <div
          className="flex_row_center"
          style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <Button
            onClick={() => {
              setShowEditSA(false);
              setShowEditOffline(true);
            }}
          >
            CANCEL
          </Button>
          <Button
            loading={btnLoader}
            disabled={
              name == null ||
              name == "" ||
              amountOrPercentage == null ||
              amountOrPercentage == ""
            }
            onClick={() => {
              setShowEditOffline(true);
              setShowEditSA(false);

              //   setBtnLoade(true);
              setCourseDetails("method", "add");
              setCourseDetails("addedNotSaved", true);

              if (isAdditionModal) {
                let temp = studentCourseDetails?.additions
                  ? studentCourseDetails?.additions
                  : [];
                temp = [
                  {
                    name: name,
                    amount: amountOrPercentage,
                    details: desc,
                  },
                ];
                setCourseDetails("additions", temp);
                setCourseDetails("type", "addition");
                // updateScholarshipOrAddition(
                //   () => {
                //     setBtnLoade(false);
                //   },
                //   () => {
                //     // handleOk();
                //   }
                // );
              } else {
                let temp = studentCourseDetails?.scholarships
                  ? studentCourseDetails?.scholarships
                  : [];
                temp.push({
                  name: name,
                  amount: amountOrPercentage,
                  details: desc,
                });
                setCourseDetails("scholarships", temp);
                setCourseDetails("type", "scholarship");

                // updateScholarshipOrAddition(
                //   () => {
                //     setBtnLoade(false);
                //   },
                //   () => {
                //     // handleOk();
                //   }
                // );
              }
            }}
            style={{ backgroundColor: "#594099", color: "white" }}
          >
            SAVE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

// export default EidtScholerShipAddModal;
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  //   updateScholarshipOrAddition: (callback, successCallBack) =>
  //     dispatch(updateScholarshipOrAddition(callback, successCallBack)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EidtScholerShipAddModal);
