import { DatePicker, Dropdown, Menu, Modal, Select } from 'antd'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import PieCharts from './pieCharts';
import { CaretLeftOutlined, CaretRightOutlined, DownOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import SwitchComponent from './switchComponent';
import GroupedBarPlot from './groupedBarPlot';
import moment from 'moment';

function BarPlotModal({ pieChartModal, setPieChartModal, colors, courseData, stdColors, studentData, graphData, courseType, isChecked, setIsMonth, setIsChecked, setIsYear, isMonth, isYear, setCourseType, data, currentPie, setCurrentPie, courseTypeLength, courseDashboardData }) {
    const handleCancel = () => {
        setPieChartModal(false);
        setIsGraphFilterClicked(false)
    };

    const [isGraphFilterClicked, setIsGraphFilterClicked] = useState(false)

    useEffect(() => {
        setCurrentPie(1)
    }, [])
    const handleNext = () => {
        setCurrentPie((prevPie) => (prevPie % 2) + 1);
    };

    const handlePrev = () => {
        setCurrentPie((prevPie) => (prevPie === 1 ? 2 : prevPie - 1));
    };
    let graphFilterArray = [
        { value: "liveCourse", label: "Live course" },
        { value: "videoResource", label: "Video course" },
        { value: "studyMaterial", label: "Study material" },
        { value: "testSeries", label: "Online test series" },
    ]

    graphFilterArray = graphFilterArray?.filter((filter) =>
        courseDashboardData?.overview?.some((item) => item?.overviewType === filter?.value)
    );

    const graphMenu = (
        <Menu className='different-bgc-dropdown' style={{ borderRadius: "16px 16px 8px 8px", padding: "10px", width: "fit-content", boxShadow: "-4px 4px 16px #00000029" }}>
            {graphFilterArray?.map((item) => (
                <div style={{ fontSize: 15, cursor: "pointer", backgroundColor: courseType == item?.value ? "#D6C8F5" : "#FFFFFF", color: courseType == item?.value ? "#191919" : "#636363", fontWeight: courseType == item?.value ? 600 : "normal", borderRadius: 4, marginTop: 5, padding: "2px 10px 2px 10px" }} onClick={() => { setCourseType(item?.value) }}>{item?.label}</div>
            ))}
        </Menu>
    )
    return (
        <div onClick={(e) => { e.stopPropagation(); setIsGraphFilterClicked(false) }}>
            <Modal width={800} open={pieChartModal} onOk={handleCancel} onCancel={handleCancel} footer={null} bodyStyle={{ padding: 40 }} className="modal-round-corner">
                <center style={{ fontSize: 20, fontWeight: 650 }}> Course types</center>
                <div >
                    <div style={{ width: "100%", display: `${currentPie == 1 ? '' : "flex"}`, justifyContent: "space-around", alignItems: "center" }}>
                        {currentPie === 1 && (<>
                            <div style={{ marginTop: "20px" }}>
                                <div >
                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        <div style={{ marginLeft: "-20px" }}>
                                            {courseTypeLength == 1 ?
                                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>{"Live course"}</div>
                                                :
                                                <Dropdown 
                                                        overlay={isGraphFilterClicked ? graphMenu : <></>} 
                                                        trigger={['hover']} 
                                                        onVisibleChange={(visible) => setIsGraphFilterClicked(visible)} // Handle visibility on hover
                                                    >
                                                        <div style={{ display: "flex", marginLeft: 10, cursor:'pointer' }} onClick={(e) => e.stopPropagation()}>
                                                            <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>
                                                                {courseType === "liveCourse" 
                                                                    ? "Live course" 
                                                                    : courseType === "videoResource" 
                                                                    ? "Video course" 
                                                                    : courseType === "studyMaterial" 
                                                                    ? "Study material" 
                                                                    : courseType === "testSeries" 
                                                                    ? "Online test series" 
                                                                    : null}
                                                            </div>
                                                            <DownOutlined 
                                                                style={{ color: "#AEAEAE", fontSize: 14, marginLeft: 10, marginTop: 10 }}
                                                            />
                                                        </div>
                                                    </Dropdown>
                                            }
                                        </div>
                                        <div style={{ display: "flex", gap: 30 }}>
                                            {!isChecked ?
                                                <DatePicker
                                                    allowClear={false}
                                                    picker="month"
                                                    format={'MMM'}
                                                    value={moment(isMonth, 'MMM')}
                                                    style={{ borderRadius: "50px", width: "80px", height: "25px" }}
                                                    onChange={(date, dateString) => {
                                                        setIsMonth(moment(date).format('MMM'))
                                                    }} /> :
                                                <DatePicker
                                                    allowClear={false}

                                                    picker="year"
                                                    value={moment(isYear)}
                                                    style={{ borderRadius: "50px", width: "80px", height: "25px" }}
                                                    onChange={(date, dateString) => {
                                                        setIsYear(moment(date).format('YYYY'))

                                                    }}
                                                />}
                                            <SwitchComponent
                                                setIsChecked={setIsChecked}
                                                isChecked={isChecked} />


                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginTop: "20px", width: "100%", display: "flex" }}>
                                    <div style={{ width: "90%" }}>
                                        <GroupedBarPlot data={graphData?.length != 0 ? graphData : []} value1={'modal'} />
                                    </div>
                                    {currentPie == 2 || courseTypeLength == 1 ? null :
                                        <div style={{ width: "10%" }}><RightCircleFilled style={{ fontSize: "25px", marginTop: `${currentPie == 1 ? '150px' : "-40px"}`, position: "absolute", marginLeft: "120px", color: "#fff" }} onClick={() => { handleNext(); setIsGraphFilterClicked(false) }} /></div>}</div>

                                <div style={{ position: "absolute", marginTop: "10px" }}>
                                    <div style={{ display: "flex", gap: 10 }}>
                                        <div style={{ height: "13px", width: "13px", backgroundColor: "#594099" }}></div>
                                        <div style={{ color: "#AEAEAE", fontSize: "11px", marginTop: "-3px", fontWeight: 500 }}> Revenue (₹)</div>
                                    </div>
                                    {/* <div style={{ display: "flex", gap: 10, marginTop: "3px" }}>
                                        <div style={{ height: "12px", width: "12px", backgroundColor: "#DFDEF5" }}></div>
                                        <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}> Popularity</div>
                                    </div> */}
                                </div>

                            </div>

                        </>)}


                        {currentPie === 2 &&
                            (<>
                                {currentPie == 1 ? null :
                                    <div><LeftCircleFilled style={{ fontSize: "25px", marginTop: "60px", position: "absolute", marginLeft: "-80px", color: "#ffff" }} onClick={() => { handlePrev(); setIsGraphFilterClicked(false) }} /></div>}
                                <div style={{ width: "50%" }}>
                                    <div className='r-c-c-c'>
                                        <div style={{ width: "300px", height: "250px" }}>
                                            <PieCharts color={colors} data={courseData?.length != 0 ? courseData : []} />
                                        </div>
                                    </div>
                                    <div style={{ fontSize: "14px", fontWeight: 500, color: "#191919", paddingLeft: "20px" }}>Course distribution</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: '90%',marginLeft:"20px" }}>
                                        {courseData?.map((item) => {
                                            return (
                                                <div style={{ display: "flex", gap: 5, marginRight: "10px", marginTop: "5px", width: "45%" }}>
                                                    {item?.type == undefined ? null : (<>
                                                        <div style={{ height: "12px", width: "12px", backgroundColor: `${item?.type == 'liveCourse' ? "#B59009" : item?.type == 'videoResource' ? "#DAAB00" : item?.type == 'testSeries' ? "#E9CC64" : item?.type == 'studyMaterial' ? "#F9E7A3" : null}` }}></div>
                                                        <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}>{item?.type == 'liveCourse' ? (<><span>Live courses</span> ({item?.count})</>) : item?.type == 'videoResource' ? (<><span>Video courses</span> ({item?.count})</>) : item?.type == 'testSeries' ? (<><span>Test series</span> ({item?.count})</>) : item?.type == 'studyMaterial' ? (<><span >Study materials</span> ({item?.count})</>) : null}</div></>)}

                                                </div>

                                            )
                                        })}</div>
                                </div>

                                <div style={{ width: "50%" }}>
                                    <div className='r-c-c-c'>
                                        <div style={{ width: "300px", height: "250px" }}>
                                            <PieCharts color={stdColors} data={studentData?.length != 0 ? studentData : []} />
                                        </div>
                                    </div>
                                    <div style={{ fontSize: "14px", fontWeight: 500, color: "#191919", paddingLeft: "20px" }}>Students distribution</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: '90%',marginLeft:"20px" }}>
                                        {studentData?.map((item) => {
                                            return (
                                                <div style={{ display: "flex", gap: 5, marginRight: "10px", marginTop: "5px", width: "45%" }}>
                                                    {item?.type == undefined ? null : (<>
                                                        <div style={{ height: "12px", width: "12px", backgroundColor: `${item?.type == 'liveCourse' ? "#594099" : item?.type == 'videoResource' ? "#967DD5" : item?.type == 'testSeries' ? "#C3B1EC" : item?.type == 'studyMaterial' ? "#DED5F5" : null}` }}></div>
                                                        <div style={{ color: "#AEAEAE", fontSize: "10px", marginTop: "-3px", fontWeight: 500 }}>{item?.type == 'liveCourse' ? (<><span>Live courses</span> ({item?.count})</>) : item?.type == 'videoResource' ? (<><span>Video courses</span> ({item?.count})</>) : item?.type == 'testSeries' ? (<><span>Test series</span> ({item?.count})</>) : item?.type == 'studyMaterial' ? (<><span >Study materials</span> ({item?.count})</>) : null}</div></>)}
                                                </div>
                                            )
                                        })}</div>
                                </div>
                                {/*  */}
                            </>)}

                    </div>
                </div>


                <div className='r-c-sa m-t-50'><Button onClick={handleCancel} type="secondary">CLOSE</Button></div>
            </Modal>
        </div>
    )
}

export default BarPlotModal