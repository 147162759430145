import { Utils } from "services";
import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  GiftedChat,
  InputToolbar,
  Composer,
  Bubble,
  Send,
  Time,
  message,
} from "react-web-gifted-chat";
import Chat from "./chat";
import { connect } from "react-redux";
import {
  addChat,
  getChatMasterAdmin,
  postCareChat,
  sentCareImag,
  sentCareMsg,
  setDate,
  setName,
  setNewChats,
  updateChats,
} from "actions/adminCare";
import { IMG_URL } from "../../env.json";
import {
  getCareChat,
  getConversationOpenedClosedUsersFunction,
  postCareSendImg,
  closeCoversation,
  setCloseOrOpenConversation,
  loadEarlierMsg,
  postCareUserChat,
  setRole,
  setBatch,
} from "actions/adminCare";
import EmptyChatScreen from "./emptyChatScreen";
import PageDataLoader from "components/pageDataLoader";
import PreviewModal from "./previewModal";
import image1 from "../../Assets/care/Icon material-send.svg";
import image2 from "../../Assets/care/CheckCircle (2).svg";
import image3 from "../../Assets/care/Icon awesome-image.svg";
import image4 from "../../Assets/care/Group 9552.svg";
import { v4 as uuidv4 } from 'uuid';

import {
  CheckCircleOutlined,
  FileImageFilled,
  RedoOutlined,
} from "@ant-design/icons";
import { stringify } from "uuid";
var chat;

function parseData(data) {
  if (!data) return {};
  if (typeof data === "object") return data;
  if (typeof data === "string") return JSON.parse(data);

  return {};
}

export const handler = (msg, type, targetId, oldChats, user) => {
  console.log('in handler', msg, type, targetId)
  // myChat(msg);
  console.log('msg', msg)
  console.log('in handler')
  chat.sendmsg({
    message: msg,
    createdAt: new Date(),
    oldChats: oldChats,
    targetId: targetId,
    user: user,
    type: type

  }
    , type, targetId.toString());
  // console.log(chat);
};

function ChatScreen({
  isAulasChat,

  getConversationOpenedClosedUsersFunction,
  currentPage,
  batch,
  role,
  date,
  name,
  user,
  chats,
  setDate,
  setName,
  getCareChat,
  addChat,
  postCareSendImg,
  postCareChat,
  postCareUserChat,
  setCloseOrOpenConversation,
  isClosedOrOpened,
  specificStudentId,
  studentChat,
  pageLoader,
  setRole,
  setBatch,
  disableLoadEarlier,
  togglePageLoader,
  sentCareMsg,
  chatWithAulas,
  getChatMasterAdmin,
  sentCareImag,
  widthForCard,
  admin,
  setNewChats,
  new_Chats,
  openCloseConversationListWithChat,
  updateChats
}) {
  { console.log("AA-chats", openCloseConversationListWithChat) }

  var curPage = currentPage;
  const [fileInputValue, setfileInputValue] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loadEarlier, toggleLoadEarlier] = useState(false);
  const inputFile = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [new_chats, setNew_chats] = useState([]);
  const [userStatus, setUserStatus] = useState("opened");
  const [msg, setmsg] = useState("");
  const [socketMsg, setSocketMsg] = useState([])

  useEffect(() => {
    console.log('temp in useeffect', new_Chats)
    if (new_Chats?.length) {
      setSocketMsg([...new_Chats])
    }
  }, [new_Chats])


  const getMasterChats = (e, temp) => {
    console.log('master chat')
    if (e.data.data?.sendByMaster && e.data.data.destinationUserId == user.id) {
      setNewChats([...temp])
      updateChats([...temp])
    }
  }

  const callback = (e) => {
    console.log(e);
    switch (e.action) {
      case "rcvChat":
        console.log('socket get chat', e.data)
        let temp = e.data.data.oldChats && e.data.data.oldChats?.length != 0 ? [...e?.data?.data?.oldChats] : [];

        let userObj = {
          id: e.data.user?.id,
          name: e.data.user?.name,
          // avatar:`${IMG_URL}/${(e.data.user?.image)}` modified after naster admin req
          avatar: `${IMG_URL}/${(e.data.user?.image)}`
        }

        if (e.data.data.sendByMaster) {
          userObj['avatar'] = `${IMG_URL}/${(e.data.data.user?.image)}`
        }


        let obj = {
          id: uuidv4(),
          createdAt: e.data.data.createdAt,
          userId: userObj?.id,
          user: userObj
        }
        if (e.data.type == 'Image') {
          obj['image'] = `${IMG_URL}/${(e.data.data.message)}`;
        } else {
          obj['text'] = e.data.data.message;
        }

        temp.unshift(obj);
        console.log('temp', temp)

        if (studentChat == true) {
          if (chatWithAulas === true) {
            console.log('master 1')
            // if(e.data.data?.sendByMaster)
            // setNewChats([...temp])
            getMasterChats(e, temp)

          } else {
            console.log('master 2')

            if (userObj?.id != user?.id) {
              setNewChats([...temp])
              updateChats([...temp])

            }
          }
        } else {
          console.log('master 3')

          if (userObj?.id != user?.id && userObj?.id == specificStudentId) {
            setNewChats([...temp])
            updateChats([...temp])

          }
        }

        console.log('sock', temp)

        // newChatRcv(e.data);
        break;
      case "connected":
        chat.listen();
        break;
      case "joinRcv":
        // connected
        console.log('joinRcv connected')
        break;
      case "pong":
        // connected
        console.log('pong')
        break;
      case "privateChatRcv":
        break;

      // console.log("userList", e.data);
    }
  };

  const socketConnection = () => {
    //     console.log('connection params',user,
    //     user?.id.toString(),
    //     user?.name,
    //     (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes('InstituteSupport') || user?.privilage?.includes('Admin'))? user?.orgId.toString():user?.id.toString(),
    // )
    chat = new Chat({
      user: user,
      userId: user?.id?.toString(),
      userName: user?.name,
      orgId: (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes('InstituteSupport') || user?.privilage?.includes('Admin')) ? user?.orgId?.toString() : user?.id?.toString(),


      callback,
    });

    chat.initsocket();

    // console.log(chat);
  };



  useEffect(() => {
    setNewChats([])
    socketConnection()
    return () => {
      chat.close();
      setNewChats([])

    }
  }, [specificStudentId])
  useEffect(() => {
    for (let i = 0; i < openCloseConversationListWithChat?.onlyClose?.length; i++) {
      if (openCloseConversationListWithChat?.onlyClose?.[i]?.userId == specificStudentId) {
        setUserStatus("closed")
        console.log("closed")
      }
    }
    for (let i = 0; i < openCloseConversationListWithChat?.onlyOpen?.length; i++) {
      if (openCloseConversationListWithChat?.onlyOpen?.[i]?.userId == specificStudentId) {
        setUserStatus("opened")
        console.log("opened")
      }
    }
  }, [specificStudentId, openCloseConversationListWithChat])
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    sendfile(imageUrl);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onChangeFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // var blob=new Blob([file])

    if (file != undefined) {
      const imgUrl = URL.createObjectURL(file);
      setfileInputValue(file);
      setImageUrl(imgUrl);

      showModal();
    }
  };
  const handleClose = () => {
    postCareChat(
      specificStudentId,
      "Closed",
      "Line",
      1,
      batch,
      role,
      date,
      name
    );
    setCloseOrOpenConversation(false);
  };

  const handleOpen = () => {
    setCloseOrOpenConversation(true);
  };

  const handleImageSend = () => {
    if (imageUrl != "") {
      const obj = {
        image: imageUrl,
        createdAt: new Date(),
        user,
      };
      // sendfile(obj.image);

      if (studentChat == true) {
        if (chatWithAulas === true) {
          // console.log("chats after image")
        } else {
          getCareChat();
        }
      } else {
        getConversationOpenedClosedUsersFunction(
          curPage,
          batch,
          role,
          date,
          name,
          specificStudentId
        );
      }

      setImageUrl("");
    }
  };

  useEffect(() => {
    togglePageLoader(true);
    if (studentChat == true) {
      // console.log("studentssss")
      if (chatWithAulas === true) {
        // console.log('inisial chats')
        getChatMasterAdmin(() => togglePageLoader(false));
      } else {
        getCareChat(1, () => togglePageLoader(false));
      }
    } else {
      getConversationOpenedClosedUsersFunction(
        curPage,
        batch,
        role,
        date,
        name,
        specificStudentId,
        () => togglePageLoader(false)
      );
    }

    return () => { };
  }, [role, batch, specificStudentId]);

  const onSend = useCallback((messages = []) => {


    if (messages[0].text !== "") {
      addChat(
        Utils.sendMessage(
          messages[0].text,
          messages[0].user,
          {},
          messages[0].createdAt
        )
      );
      if (studentChat == true) {
        if (chatWithAulas === true) {
          // console.log('postcareChat')
          handler(messages[0].text, 'text', '1234', new_Chats, user)
          sentCareMsg(messages[0].text);

        } else {
          handler(messages[0].text, 'text', user?.orgId, new_Chats, user)
          postCareUserChat(messages[0].text, "Text");
        }
      } else {
        handler(messages[0].text, 'text', specificStudentId, new_Chats, user)
        postCareChat(
          specificStudentId,
          messages[0].text,
          "Text",
          1,
          batch,
          role,
          date,
          name
        );
      }
    }
  });

  function sendfile(image) {
    if (studentChat == true) {
      if (chatWithAulas == true) {
        // console.log("image send")
        // handler(fileInputValue,"Image",'1234')
        sentCareImag(fileInputValue, (url) => { handler(url, "Image", '1234', new_Chats, user) });
      } else {
        // handler(fileInputValue,"Image",user?.orgId)
        postCareSendImg(fileInputValue, parseInt(user.id), 0, (url) => { handler(url, "Image", user?.orgId, new_Chats, user) });
      }
    } else {
      // handler(fileInputValue,"Image",specificStudentId)
      //   postCareSendImg(
      //   fileInputValue,
      //   parseInt(specificStudentId),
      //   1,
      //   1,
      //   batch,
      //   role,
      //   date,
      //   name
      // );
      postCareSendImg(
        fileInputValue,
        parseInt(specificStudentId),
        1,
        (url) => { handler(url, "Image", specificStudentId, new_Chats, user) }
      );
    }
    addChat(Utils.sendMessage("", user, { image }, new Date()));

    setfileInputValue("");
  }
  function renderBubble(props) {
    return (
      <Bubble
        {...props}
        selectable={true}
        wrapperStyle={{
          right: {
            backgroundColor: "white",
            padding: 6,
            borderRadius: 8,
            elevation: 2,
            borderColor: "#E6E6E6",
            borderWidth: 1,
          },
          left: {
            backgroundColor: "white",
            padding: 6,
            borderRadius: 8,
            elevation: 2,
            borderColor: "#E6E6E6",
            borderWidth: 1,
          },
        }}
        linkStyle={{
          right: { color: "blue" },
          left: { color: "blue" },
        }}
        textStyle={{
          right: {
            color: "#191919",
            fontSize: 14,
            lineHeight: 22,
            fontFamily: "Roboto",
          },
          left: {
            color: "#191919",
            fontSize: 14,
            lineHeight: 22,
            fontFamily: "Roboto",
          },
        }}
      />
    );
  }

  function renderTime(props) {
    return (
      <Time
        {...props}
        textStyle={{
          right: {
            color: "grey",
          },
          left: {
            color: "grey",
          },
        }}
      />
    );
  }

  function renderSend(props) {
    return (
      <Send
        {...props}
        alwaysShowSend
        containerStyle={{
          width: "10%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 10, marginTop: "10px", marginRight: 10 }}>
          <img
            style={{ width: "30px" }}
            onClick={handleImageSend}
            src={image1}
          />
        </div>
      </Send>
    );
  }

  const onLoadEarlier = () => {
    toggleLoadEarlier(true);
    curPage = curPage + 1;
    if (studentChat == true) {
      if (chatWithAulas === true) {
        getChatMasterAdmin(() => toggleLoadEarlier(false), curPage);
      } else {
        getCareChat(curPage, () => toggleLoadEarlier(false));
      }
    } else {
      getConversationOpenedClosedUsersFunction(
        curPage,
        batch,
        role,
        date,
        name,
        specificStudentId,
        () => toggleLoadEarlier(false)
      );
    }
  };

  function renderInputToolbar(props) {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          borderColor: "#E8E8E8",
          borderWidth: 1,
          bordertop: "none",
          borderBottomWidth: "0px",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          minHeight: "100",
          maxHeight: "500",
          height: "fit-content",
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          position: "absolute",
          bottom: "10px",
        }}
      ></InputToolbar>
    );
  }

  function renderSystemMessage() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "row",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        <div
          style={{
            width: "30%",
            borderBottom: "solid",
            marginLeft: "5%",
            borderColor: "#594099",
            marginRight: "5px",
          }}
        ></div>
        <img style={{ marginLeft: "1px" }} src={image2} />
        <p
          style={{
            fontSize: 12,
            fontFamily: "Roboto",
            fontWeight: "bold",
            color: "#594099",
            marginLeft: "10px",
          }}
        >
          CONVERSATION CLOSED
        </p>
        <div
          style={{
            width: "30%",
            borderBottom: "solid",
            marginRight: "5%",
            borderColor: "#594099",
          }}
        ></div>
      </div>
    );
  }
  let string = "Type a message...";
  if (studentChat == true) {
    string = "Type your question here...";
  }
  // let chatUser;
  useEffect(() => {
    setNewChats([])

    const newchats = chats?.map((item, index) => {
      const chatItem = { ...item };
      { console.log(chatItem, "chat detail") }

      if (item?.type == "Text") {
        chatItem["text"] = item?.message;
      }
      if (item?.type == "Image") {
        chatItem["image"] = `${IMG_URL}/${item?.message}`;
      }
      if (item?.type == "Line") {
        chatItem["system"] = "true";
        closeCoversation(true);
      }

      if (chatWithAulas === true) {
        let myObjStr = JSON.stringify(item.user);
        chatItem["user"] = {
          name: parseData(item.user).name,
          id: parseData(item.user).id,
          avatar: `${IMG_URL}/${parseData(item.user).avatar}`,
        };
      } else {
        chatItem["user"] = {
          name: parseData(item.user).name,
          id: parseData(item.user).id,
          avatar: `${IMG_URL}/${parseData(item.user).image}`,
        };
      }

      chatItem["key"] = index;
      return chatItem;
    });
    setNewChats(newchats);
    return () => {
      setNewChats([])
    }

  }, [chats]);
  const chatBox = (
    <>
      <GiftedChat
        renderChatEmpty={EmptyChatScreen}
        // bottomOffset={200}
        alignTop={true}
        onLongPress={() => { }}
        placeholder={string}
        selectable={true}
        renderInputToolbar={renderInputToolbar}
        renderBubble={renderBubble}
        showUserAvatar={true}
        renderSend={renderSend}
        renderTime={renderTime}
        onLoadEarlier={onLoadEarlier}
        isLoadingEarlier={loadEarlier}
        renderSystemMessage={renderSystemMessage}
        // renderUsernameOnMessage={!studentChat}
        loadEarlier={!disableLoadEarlier}
        messages={pageLoader ? [] : new_Chats}
        onSend={(messages) => onSend(messages)}
        renderComposer={(props1) => (
          <Composer
            {...props1}
            textInputStyle={{
              color: "grey",
              width: "90%",
              height: "40px",
              minHeight: "100",
            }}
          />
        )}
        user={user}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          border: "#E8E8E8 solid 1px",
          borderTop: "none",
          // marginTop: 20,
          backgroundColor: "#FAFAFA",
          height: "8%"
        }}
      >
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={(e) => {
            onChangeFile(e);
          }}
          value={""}
          style={{ display: "none", }}
          accept="image/*"
        />
        <div
          onClick={onButtonClick}
          className="imgUploader"
          style={{ marginLeft: "3%", }}
        >
          <FileImageFilled style={{ fontSize: "25px", color: "#AEAEAE" }} />
        </div>
        <div className="problemStatus" style={{ marginRight: "2%" }}>
          {studentChat != true ? (
            <>
              {userStatus === "opened" ? <>

                <CheckCircleOutlined
                  style={{
                    fontSize: "25px",
                    color: "#1089ff",
                    marginLeft: "30%",
                    marginTop:"5px"
                  }}
                />
                <p onClick={handleClose} className="toggleBtnClass" style={{ color: "#1089ff" }}>
                  CLOSE CONVERSATION
                </p>
              </> :
                <>

                  <CheckCircleOutlined
                    style={{
                      fontSize: "25px",
                      color: "grey",
                      marginLeft: "30%",
                      cursor: "default",
                      marginTop:"5px"

                    }}
                  />
                  <p className="toggleBtnClass" style={{ color: "grey", cursor: "default", }}>
                    CLOSE CONVERSATION
                  </p>
                </>}
            </>

          ) : (
            <div style={{ height: "50px" }}></div>
          )}
        </div>
        <PreviewModal
          sendfile={sendfile}
          image={imageUrl}
          isModalVisible={isModalVisible}
          showModal={showModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
    </>
  )

  return (
    <>
      {console.log("newChats", new_Chats)}

      {new_Chats?.length === 0 ? (
        <div
          style={{
            position: "absolute",
            margin: "auto",
            width: widthForCard ? "50%" : "80%",
          }}
        >
          <EmptyChatScreen adimin={admin} />
        </div>
      ) :
        null

      }
      {

        user?.privilage?.includes("SuperAdmin") ? (
          <>


            {isAulasChat
              ? chatBox
              : specificStudentId && chatBox
            }
            {/* {specificStudentId && (
              <>
                <GiftedChat
                  renderChatEmpty={EmptyChatScreen}
                  // bottomOffset={200}
                  alignTop={true}
                  onLongPress={() => { }}
                  placeholder={string}
                  selectable={true}
                  renderInputToolbar={renderInputToolbar}
                  renderBubble={renderBubble}
                  showUserAvatar={true}
                  renderSend={renderSend}
                  renderTime={renderTime}
                  onLoadEarlier={onLoadEarlier}
                  isLoadingEarlier={loadEarlier}
                  renderSystemMessage={renderSystemMessage}
                  // renderUsernameOnMessage={!studentChat}
                  loadEarlier={!disableLoadEarlier}
                  messages={pageLoader ? [] : new_Chats}
                  onSend={(messages) => onSend(messages)}
                  renderComposer={(props1) => (
                    <Composer
                      {...props1}
                      textInputStyle={{
                        color: "grey",
                        width: "90%",
                        height: "fit-content",
                        minHeight: "100",
                      }}
                    />
                  )}
                  user={user}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    border: "#E8E8E8 solid 1px",
                    borderTop: "none",
                    marginTop: 20,
                    backgroundColor: "#FAFAFA",
                    height: "5%"
                  }}
                >
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    onChange={(e) => {
                      onChangeFile(e);
                    }}
                    value={""}
                    style={{ display: "none", }}
                    accept="image/*"
                  />
                  <div
                    onClick={onButtonClick}
                    className="imgUploader"
                    style={{ marginLeft: "3%", }}
                  >
                    <FileImageFilled style={{ fontSize: "25px", color: "#AEAEAE" }} />
                  </div>
                  <div className="problemStatus" style={{ marginRight: "2%" }}>
                    {studentChat != true ? (
                      <>
                        {userStatus === "opened" ? <>

                          <CheckCircleOutlined
                            style={{
                              fontSize: "25px",
                              color: "#1089ff",
                              marginLeft: "30%",
                            }}
                          />
                          <p onClick={handleClose} className="toggleBtnClass" style={{ color: "#1089ff" }}>
                            CLOSE CONVERSATION
                          </p>
                        </> :
                          <>

                            <CheckCircleOutlined
                              style={{
                                fontSize: "25px",
                                color: "grey",
                                marginLeft: "30%",
                                cursor: "default"
                              }}
                            />
                            <p className="toggleBtnClass" style={{ color: "grey", cursor: "default" }}>
                              CLOSE CONVERSATION
                            </p>
                          </>}
                      </>

                    ) : (
                      <div style={{ height: "50px" }}></div>
                    )}
                  </div>
                  <PreviewModal
                    sendfile={sendfile}
                    image={imageUrl}
                    isModalVisible={isModalVisible}
                    showModal={showModal}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                  />
                </div>
              </>

            )} */}


          </>

        ) : (
          <>
            <GiftedChat
              renderChatEmpty={EmptyChatScreen}
              // bottomOffset={200}
              alignTop={true}
              onLongPress={() => { }}
              placeholder={string}
              selectable={true}
              renderInputToolbar={renderInputToolbar}
              renderBubble={renderBubble}
              showUserAvatar={true}
              renderSend={renderSend}
              renderTime={renderTime}
              onLoadEarlier={onLoadEarlier}
              isLoadingEarlier={loadEarlier}
              renderSystemMessage={renderSystemMessage}
              // renderUsernameOnMessage={!studentChat}
              loadEarlier={!disableLoadEarlier}
              messages={pageLoader ? [] : new_Chats}
              onSend={(messages) => onSend(messages)}
              renderComposer={(props1) => (
                <Composer
                  {...props1}
                  textInputStyle={{
                    color: "grey",
                    width: "90%",
                    height: "fit-content",
                    minHeight: "100",
                  }}
                />
              )}
              user={user}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                marginLeft: "5%",
                marginRight: "5%",
                border: "#E8E8E8 solid 1px",
                borderTop: "none",
                // marginTop: 20,
                backgroundColor: "#FAFAFA",
                height: "5%"
              }}
            >
              <input
                type="file"
                id="file"
                ref={inputFile}
                onChange={(e) => {
                  onChangeFile(e);
                }}
                value={""}
                style={{ display: "none", }}
                accept="image/*"
              />
              <div
                onClick={onButtonClick}
                className="imgUploader"
                style={{ marginLeft: "3%", }}
              >
                <FileImageFilled style={{ fontSize: "25px", color: "#AEAEAE" }} />
              </div>
              <div className="problemStatus" style={{ marginRight: "2%" }}>
                {studentChat != true ? (
                  <>
                    {userStatus === "opened" ? <>

                      <CheckCircleOutlined
                        style={{
                          fontSize: "25px",
                          color: "#1089ff",
                          marginLeft: "30%",
                        }}
                      />
                      <p onClick={handleClose} className="toggleBtnClass" style={{ color: "#1089ff" }}>
                        CLOSE CONVERSATION
                      </p>
                    </> :
                      <>

                        <CheckCircleOutlined
                          style={{
                            fontSize: "25px",
                            color: "grey",
                            marginLeft: "30%",
                            cursor: "default"
                          }}
                        />
                        <p className="toggleBtnClass" style={{ color: "grey", cursor: "default" }}>
                          CLOSE CONVERSATION
                        </p>
                      </>}
                  </>

                ) : (
                  <div style={{ height: "50px" }}></div>
                )}
              </div>
              <PreviewModal
                sendfile={sendfile}
                image={imageUrl}
                isModalVisible={isModalVisible}
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
              />
            </div>
          </>
        )
        // <>
        //   <GiftedChat
        //     renderChatEmpty={EmptyChatScreen}
        //     // bottomOffset={200}
        //     alignTop={true}
        //     onLongPress={() => { }}
        //     placeholder={string}
        //     selectable={true}
        //     renderInputToolbar={renderInputToolbar}
        //     renderBubble={renderBubble}
        //     showUserAvatar={true}
        //     renderSend={renderSend}
        //     renderTime={renderTime}
        //     onLoadEarlier={onLoadEarlier}
        //     isLoadingEarlier={loadEarlier}
        //     renderSystemMessage={renderSystemMessage}
        //     // renderUsernameOnMessage={!studentChat}
        //     loadEarlier={!disableLoadEarlier}
        //     messages={pageLoader ? [] : new_Chats}
        //     onSend={(messages) => onSend(messages)}
        //     renderComposer={(props1) => (
        //       <Composer
        //         {...props1}
        //         textInputStyle={{
        //           color: "grey",
        //           width: "90%",
        //           height: "fit-content",
        //           minHeight: "100",
        //         }}
        //       />
        //     )}
        //     user={user}
        //   />
        //   <div
        //     style={{
        //       display: "flex",
        //       flexDirection: "row",
        //       justifyContent: "space-between",
        //       alignItems: "center",
        //       width: "90%",
        //       marginLeft: "5%",
        //       marginRight: "5%",
        //       border: "#E8E8E8 solid 1px",
        //       borderTop: "none",
        //       marginTop: 20,
        //       backgroundColor: "#FAFAFA",
        //       height: "5%"
        //     }}
        //   >
        //     <input
        //       type="file"
        //       id="file"
        //       ref={inputFile}
        //       onChange={(e) => {
        //         onChangeFile(e);
        //       }}
        //       value={""}
        //       style={{ display: "none", }}
        //       accept="image/*"
        //     />
        //     <div
        //       onClick={onButtonClick}
        //       className="imgUploader"
        //       style={{ marginLeft: "3%", }}
        //     >
        //       <FileImageFilled style={{ fontSize: "25px", color: "#AEAEAE" }} />
        //     </div>
        //     <div className="problemStatus" style={{ marginRight: "2%" }}>
        //       {studentChat != true ? (
        //         <>
        //           {userStatus === "opened" ? <>

        //             <CheckCircleOutlined
        //               style={{
        //                 fontSize: "25px",
        //                 color: "#1089ff",
        //                 marginLeft: "30%",
        //               }}
        //             />
        //             <p onClick={handleClose} className="toggleBtnClass" style={{ color: "#1089ff" }}>
        //               CLOSE CONVERSATION
        //             </p>
        //           </> :
        //             <>

        //               <CheckCircleOutlined
        //                 style={{
        //                   fontSize: "25px",
        //                   color: "grey",
        //                   marginLeft: "30%",
        //                   cursor: "default"
        //                 }}
        //               />
        //               <p className="toggleBtnClass" style={{ color: "grey", cursor: "default" }}>
        //                 CLOSE CONVERSATION
        //               </p>
        //             </>}
        //         </>

        //       ) : (
        //         <div style={{ height: "50px" }}></div>
        //       )}
        //     </div>
        //     <PreviewModal
        //       sendfile={sendfile}
        //       image={imageUrl}
        //       isModalVisible={isModalVisible}
        //       showModal={showModal}
        //       handleOk={handleOk}
        //       handleCancel={handleCancel}
        //     />
        //   </div>
        // </> : (<EmptyChatScreen adimin={admin} />)
      }


      <PageDataLoader visible={pageLoader} />
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    chats,
    currentPage,
    openCloseConversationListWithChat,
    batch,
    role,
    date,
    name,
    isClosedOrOpened,
    specificStudentId,
    disableLoadEarlier,
    chatWithAulas,
    new_Chats,
  } = state.adminCare;
  const { user } = state.session;
  return {
    chats,
    user,
    currentPage,
    openCloseConversationListWithChat,
    batch,
    role,
    date,
    name,
    isClosedOrOpened,
    specificStudentId,
    disableLoadEarlier,
    chatWithAulas,
    new_Chats,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addChat: (m) => dispatch(addChat(m)),
  postCareChat: (
    specificStudentId,
    message,
    type,
    page,
    batch,
    role,
    date,
    name,
    userId
  ) =>
    dispatch(
      postCareChat(
        specificStudentId,
        message,
        type,
        page,
        batch,
        role,
        date,
        name,
        userId
      )
    ),
  postCareSendImg: (file, specificStudentId, adminId, callback) =>
    dispatch(postCareSendImg(file, specificStudentId, adminId, callback)),
  postCareUserChat: (message, type) =>
    dispatch(postCareUserChat(message, type)),
  getCareChat: (page, callback) => dispatch(getCareChat(page, callback)),
  closeCoversation: () => dispatch(closeCoversation()),
  setCloseOrOpenConversation: (m) => dispatch(setCloseOrOpenConversation(m)),
  loadEarlierMsg: (m) => dispatch(loadEarlierMsg(m)),
  setName: (m) => dispatch(setName(m)),
  setDate: (m) => dispatch(setDate(m)),
  setRole: (m) => dispatch(setRole(m)),
  setBatch: (m) => dispatch(setBatch(m)),
  sentCareMsg: (m) => dispatch(sentCareMsg(m)),
  getChatMasterAdmin: (callback, curPage) =>
    dispatch(getChatMasterAdmin(callback, curPage)),
  sentCareImag: (file, callback) => dispatch(sentCareImag(file, callback)),
  getConversationOpenedClosedUsersFunction: (
    curPage,
    batch,
    role,
    date,
    name,
    specificStudentId,
    callback,

  ) =>
    dispatch(
      getConversationOpenedClosedUsersFunction(
        curPage,
        batch,
        role,
        date,
        name,
        specificStudentId,
        callback
      )
    ),
  setNewChats: (chats) => dispatch(setNewChats(chats)),
  updateChats: (chats) => dispatch(updateChats(chats))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen);
