import React, { useEffect, useState } from 'react';
import "./courseDetailsStyles.css";
import { LinesCarousel } from './lineCarousel';
import { Row } from 'antd';
import "../../pages/studentMarketPlace/coursePageCompo/prerequest.css"

function CourseDetailsIncludes({ singleCourseDetailsDataContainer, fromMyCourcesPage }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const chunkSize = 4;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalChunks);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const details = singleCourseDetailsDataContainer?.details || [];
    const totalChunks = Math.ceil(details.length / chunkSize);
    const startIdx = currentIndex * chunkSize;
    const endIdx = Math.min(startIdx + chunkSize, details.length);
    const currentChunk = details.slice(startIdx, endIdx);
    return (
        <div style={{ marginTop: 50 }}>
            <div className={`${fromMyCourcesPage ? 'bodyStyle' : 'course-details-includes-parent'}`} >
                <div style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", padding: 20 }}>
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 20 }}>This course includes</div>
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                        {singleCourseDetailsDataContainer?.details?.map((item, index) => (
                            <div className='r-c-fs' style={{ alignItems: "baseline", width: singleCourseDetailsDataContainer?.details?.length == 1 ? "100%" : "49%" }}>
                                <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                <div key={index} className='pre-list' style={{ flexBasis: "100%" }}>{item?.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='course-details-includes-mobile'>
                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18, marginBottom: 20 }}>This course includes</div>
                <LinesCarousel>
                    <Row style={{ height: "fit-content" }}>
                        <div style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", padding: 15 }}>
                            <ul>
                                <li>
                                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                        {currentChunk.map((item) => (
                                            <div key={item?.id} style={{ color: "#636363", fontSize: 16 }} className='m-t-5'>
                                                {item?.name}
                                            </div>
                                        ))}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Row>
                    {totalChunks > 1 && (
                        <Row className='arrow-button'>
                            <div className='lines'>
                                {Array(totalChunks)?.fill()?.map((_, index) => (
                                    <div
                                        key={index}
                                        className={currentIndex === index ? 'blueline' : 'greyline'}
                                        onClick={() => setCurrentIndex(index)}
                                    ></div>
                                ))}
                            </div>
                        </Row>
                    )}
                </LinesCarousel>
            </div>
        </div>
    )
}

export default CourseDetailsIncludes