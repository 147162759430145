import {
  addCourses,
  deleteCourse,
  getAllCourses,
  setCourseBillingPlan,
  setCourseDuration,
  setCourseName,
  setCoursePrice,
  setCourseRef,
  setInstallments,
  setIsFromDetailsPage,
} from "actions/course";
import { Input, Select, Modal, Col } from "antd";
import { ceil, parseInt } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Date_Picker from "./datePicker";
import image from "../../Assets/ProfileAssets/Group 1.svg";
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import PageDataLoader from "components/pageDataLoader";
import StartDate_Picker from "./courseStartDate";
import moment from "moment";
import CourseDeleteConfirmModal from "./courseDeleteConfirmModal";
import SaveDetailsModal from "./saveDetailsModal";
import {Button} from 'components/Button'
import PageHeader from 'components/pageHeader'
import './style.css'
import Notification from "services/Notification";


function CourseDetailsPage({
  isModalVisible,
  handleOk,
  handleCancel,
  setIsModalVisible,
  courseName,
  coursePrice,
  courseDuration,
  courseRef,
  courseBillingPlan,
  setCourseName,
  setCoursePrice,
  setCourseDuration,
  setCourseRef,
  setCourseBillingPlan,
  setInstallments,
  installments,
  //   isEditModalVisible,
  //   setIsEditModalVisible,
  specificCourseDetails,
  addCourses,
  deleteCourse,
  //   setPageLoader,
  getAllCourses,
  courses,
  setIsFromDetailsPage
}) {
  const [addButtonLdr, setAddButtonLdr] = useState(false);
  const [deleteButtonLdr, setDeleteButtonLdr] = useState(false);
  const [counter, setCounter] = useState(0);
  const [instCust, setInstCust] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(true);
  const [courseDescription, setCourseDescription] = useState("");
  const [pageLoader, setPageLoader] = useState(false);
  const [forCustomize, setForCustomize] = useState(false);
  const [forInstallmentReset, setForInstallmentReset] = useState(false);
  const [forDueDate, setForDueDate] = useState(false);
  const [courseStartDate, setCourseStartDate] = useState(null);
  const [courseEndDate, setCourseEndDate] = useState(null);
  const [newCourseEndDate, setNewCourseEndDate] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isNotSaved, setIsNotSave] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);
  const [isInstalment, setIsInstalment] = useState(
    specificCourseDetails?.showInstallments
  );

  const {TextArea} = Input
  const history = useHistory();
  useEffect(() => {
    if (newCourseEndDate < courseStartDate) {
      setNewCourseEndDate(null);
    }
    let temp = count;
    setCount(temp + 1);

    if (count > 0) {
      if (
        specificCourseDetails?.endDate != newCourseEndDate ||
        specificCourseDetails?.startDate != courseStartDate
      ) {
        setIsNotSave(true);
      } else {
        setIsNotSave(false);
      }
    }

  }, [newCourseEndDate, courseStartDate]);
  useEffect(() => {
    const returnaDurationType = (val) => {
      if (val == "1") {
        return "days";
      } else if (val == "30") {
        return "months";
      } else if (val == "360") {
        return "years";
      }
    };
    let temp = moment(courseStartDate)
      .add(courseDuration, returnaDurationType(courseRef))
      .subtract(1, "days")
      .format("DD-MM-YYYY");
    setCourseEndDate(temp !== NaN && temp != "Invalid date" ? temp : "");
    if (temp !== NaN && temp != "Invalid date") {
      if (count > 0) {
        setNewCourseEndDate(moment(temp, "DD-MM-YYYY").format("YYYY-MM-DD"));
      }
    }
  }, [courseStartDate, courseDuration, courseRef]);
  useEffect(() => {
    let temp = 0;
    for (let i = 0; i < installments?.length; i++) {
      if (
        installments &&
        installments[i]?.installment &&
        installments[i]?.installment !== undefined
      ) {
        temp++;
      }
    }

    if (installments?.length == "0") {
      setCounter(0);
    } else {
      setCounter(temp);
    }
  }, [installments]);
  const returnDays = (val) => {
    if (val == "Day(s)") {
      return "1";
    } else if (val == "Monthly" || val == "month" || val == "Month(s)") {
      return "30";
    } else if (val == "Quarterly") {
      return "90";
    } else if (val == "Half Yearly") {
      return "180";
    } else if (val == "Annually" || val == "Year(s)") {
      return "360";
    } else if (val == "Yearly") {
      return "360";
    } else if (val == "One time") {
      return "One time";
    } else if (val == "Customize") {
      return "Customize";
    }
  };

  useEffect(() => {
    if (isEditModalVisible) {
      setCourseName(specificCourseDetails?.courseName);
      setCoursePrice(specificCourseDetails?.amount);
      setCourseRef(returnDays(specificCourseDetails?.durationType));
      setCourseDuration(specificCourseDetails?.duration);
      setCourseBillingPlan(returnDays(specificCourseDetails?.billingPlan));
      setInstallments(
        true ? null : JSON.parse(specificCourseDetails?.installmentDetails)
      );
      setCourseStartDate(specificCourseDetails?.startDate);
      setNewCourseEndDate(specificCourseDetails?.endDate);
    } else {
      setCourseName(null);
      setCoursePrice(null);
      setCourseRef(null);
      setCourseDuration(null);
      setCourseBillingPlan(null);
    }
  }, []);
 
  const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  };
  const insValidNumber = (e) => {
    let pattern1 = /[^\d.]+/;
    let pattern2 = /^[^.]+(\.{0}[^.]*|\.{1}[^.]{0,2})$/;
    return (
      e.target.value === "" ||
      (pattern2.test(e.target.value) && !pattern1.test(e.target.value))
    );
  };
  useEffect(() => {
    clacInstallments();
  }, [
    coursePrice,
    courseRef,
    courseBillingPlan,
    courseDuration,
    isEditModalVisible,
  ]);
  const clacInstallments = () => {
    let inst = [];
    if (coursePrice == "") {
      inst = [];
    } else if (
      courseBillingPlan !== "One time" &&
      courseBillingPlan !== "Customize"
    ) {
      let daysToYear = 0;
      if (courseRef == "1" && courseDuration >= "360") {
        daysToYear = parseInt(parseInt(courseDuration) % 365);
      }
      let noOfInst = daysToYear
        ? (parseInt(courseDuration) * parseInt(courseRef)) /
        parseInt(courseBillingPlan)
        : Math.floor(
          (parseInt(courseDuration) / 365) * 360 * parseInt(courseRef)
        ) / parseInt(courseBillingPlan);
      if (parseInt(courseDuration) % 360 == 0 && courseRef == "1") {
        noOfInst = noOfInst;
      }
      for (let i = 0; i < ceil(noOfInst); i++) {
        var numb = parseInt(coursePrice) / ceil(noOfInst);
        numb = numb.toFixed(2);
        inst[i] = {
          installment:
            JSON.parse(specificCourseDetails?.installmentDetails)[i]
              ?.installment && !forInstallmentReset
              ? JSON.parse(specificCourseDetails?.installmentDetails)[i]
                ?.installment
              : numb,
          dueDate:
            (JSON.parse(specificCourseDetails?.installmentDetails)[i]
              ?.dueDate &&
              !forInstallmentReset) ||
              !forDueDate
              ? JSON.parse(specificCourseDetails?.installmentDetails)[i]
                ?.dueDate
              : null,
        };
      }
    } else if (courseBillingPlan == "One time") {
      inst[0] = {
        installment: forInstallmentReset
          ? parseInt(coursePrice).toFixed(2)
          : JSON.parse(specificCourseDetails?.installmentDetails)[0]
            ?.installment,
        dueDate: JSON.parse(specificCourseDetails?.installmentDetails)[0]
          ?.dueDate
          ? JSON.parse(specificCourseDetails?.installmentDetails)[0]?.dueDate
          : null,
      };
    } else if (courseBillingPlan == "Customize") {
      inst = isEditModalVisible
        ? JSON.parse(specificCourseDetails?.installmentDetails)
        : [];
      setInstallments(inst);
      setInstCust([...inst]);
    }
    if (courseBillingPlan == "Customize") {
      if (forCustomize && coursePrice && coursePrice != '') {
        setInstallments([
          {
            dueDate: null,
            installment: "",
          },
        ]);
        setInstCust([
          {
            dueDate: null,
            installment: "",
          },
        ]);
      }
    } else {
      setInstallments([...inst]);
    }
  };
  const AddCustomiseInstallMent = () => {
    instCust[instCust?.length] = {
      installment: "",
    };

    setInstCust(instCust);
    setInstallments([...instCust]);
  };
  useEffect(() => {
    setCourseDescription(specificCourseDetails?.courseDescription);
  }, []);

  const editConfirm = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to save the changes you made?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk() {
        setIsNotSave(false);
        addCourses(
          () => setAddButtonLdr(false),
          () => {
            setPageLoader(true);
            setIsFromDetailsPage(true);
            history.push("/course");
          },
          isEditModalVisible,
          courseDescription,
          courseStartDate,
          newCourseEndDate
        );
      },
      onCancel() {
        setIsFromDetailsPage(true);
        history.goBack();
        setIsNotSave(false);
      },
    });
  };
  const parsedInstallments = JSON.parse(specificCourseDetails?.installmentDetails)
  let disable=true;
  for(let i=0;i<parsedInstallments?.length;i++){
    for(let j=0;j<installments?.length;j++){
      if(parsedInstallments[i].installment == installments[j]?.installment){
        disable = true;
      }else{
        disable = false;
      }      
    }
  }
  let installmentHandler = true;
  let sum = 0;
  for(let i=0;i<installments?.length;i++){
    sum = sum + parseInt(installments[i]?.installment);
    if(sum==coursePrice){
      installmentHandler = true;
    }else{
      installmentHandler = false;
    }
  }
  const actions = [
    <Button 
      type='primary' 
      loading={addButtonLdr}
      disabled={(specificCourseDetails?.courseName == courseName && specificCourseDetails?.courseDescription == courseDescription && returnDays(specificCourseDetails?.durationType) == courseRef && specificCourseDetails?.startDate == courseStartDate && specificCourseDetails?.endDate == newCourseEndDate && specificCourseDetails?.amount == coursePrice && returnDays(specificCourseDetails?.billingPlan) == courseBillingPlan && disable && !installmentHandler) || courseName =="" || courseDuration=='' || courseRef=="" || ((parseInt(courseDuration) > 60 && parseInt(courseRef) == 30 && courseDuration != '' && courseRef != '' ) || (parseInt(courseDuration) > 5 && parseInt(courseRef) == 360 && courseDuration != '' && courseRef != '') || (parseInt(courseDuration) > 1825 && parseInt(courseRef) == 1 && courseDuration != '' && courseRef != ''))}
      // disabled={courseName == "" || courseName == undefined || courseRef == "" || courseRef == undefined || courseDuration == "" || courseDuration == undefined || counter !== installments?.length || ((parseInt(courseDuration) > 60 && parseInt(courseRef) == 30 && courseDuration != '' && courseRef != '' ) || (parseInt(courseDuration) > 5 && parseInt(courseRef) == 360 && courseDuration != '' && courseRef != '') || (parseInt(courseDuration) > 1825 && parseInt(courseRef) == 1 && courseDuration != '' && courseRef != ''))} 
      onClick={()=>{
        setAddButtonLdr(true);
        addCourses(
          () => setAddButtonLdr(false),
          () => {
            setPageLoader(true);
            setIsNotSave(false);
            history.push("/course");
          },
          isEditModalVisible,
          courseDescription,
          courseStartDate,
          newCourseEndDate
        );
      }}>{isEditModalVisible ? "UPDATE" : "ADD"}</Button>
  ]

  const handleBackPress = () => {
      if (isNotSaved) {
      editConfirm();
    } else {
      setIsFromDetailsPage(true)
      history.goBack();
    }
  }

  const nameInputChange = (e) => {
    if (e.target.value != specificCourseDetails?.courseName) {
      setIsNotSave(true);
    } else {
      setIsNotSave(false);
    }
    setCourseName(
      e.target.value == " " ? null : e.target.value
    );
  }

  const descriptionOnChange = (e) => {
    if (e.target.value != specificCourseDetails?.courseDescription) {
      setIsNotSave(true);
    } else {
      setIsNotSave(false);
    }
    setCourseDescription(
      e.target.value == " " ? null : e.target.value
    );
  }

  const durationPlanChange = (e) => {
    setIsNotSave(true);
    setCourseBillingPlan(null);
    setCourseRef(e);
  }

  const durationOnChange = (e) => {
    setIsNotSave(true);
    if (
      e.target.value != " " &&
      e.target.value != "0" &&
      /^[\d]*$/.test(e.target.value)
    ) {
      setCourseBillingPlan(null);
    }
    setCourseDuration(
      e.target.value != " " &&
        e.target.value != "0" &&
        /^[\d]*$/.test(e.target.value)
        ? e.target.value
        : courseDuration
          ? courseDuration
          : null
    );
  }

  const totalFeeAmountOnChange = (e) => {
    if (e.target.value != specificCourseDetails?.amount) {
      setIsNotSave(true);
    } else {
      setIsNotSave(false);
    }
    if (e.target.value == "") {
      setCourseBillingPlan(null);
    }
    if (
      e.target.value != " " &&
      e.target.value != "0" &&
      /^[\d]*$/.test(e.target.value)
    ) {
      setForInstallmentReset(true);
      if (forInstallmentReset) {
      } else {
        setForDueDate(false);
      }
    }
    setCoursePrice(
      e.target.value != " " &&
        e.target.value != "0" &&
        /^[\d]*$/.test(e.target.value)
        ? e.target.value
        : coursePrice
          ? coursePrice
          : null
    );
  }

  useEffect(()=>{
    if(coursePrice == null){
      if(courseBillingPlan != ''){
        setInstallments([])
      }else{
        setInstallments([])
      }     
    }
  },[coursePrice,courseBillingPlan])

  return (
    <div style={{overflow: 'auto'}}>
      <div>
        <PageHeader title={specificCourseDetails?.courseName} actions={actions} onBackPress={()=>{handleBackPress()}}/>
      </div>
      <div className="m-t-50 p-15">
        <div style={{ fontSize: "18px", fontFamily: "roboto", fontWeight: "bold", marginLeft: "10px"}}>Course details</div>
        <div className="p-10">
          <div style={{color: '#636363', fontWeight: 600}}>Name</div>
          <Input onChange={(e) => {nameInputChange(e)}} value={courseName} placeholder="Enter Name" style={{borderRadius: 5, border: "#E6E6E6 solid 1px"}}/>

          <div style={{color: '#636363', fontWeight: 600, marginTop: 20}}>Description</div>
          <TextArea rows={6} onChange={(e)=>{descriptionOnChange(e)}} placeholder='Enter description' value={courseDescription}/>


          <div style={{color: '#636363', fontWeight: 600, marginTop: 20}}>Duration</div>
          <div className="r-jsb" style={{width: '100%'}}>
            <Input placeholder="Enter Duration" value={courseDuration} 
            onChange={(e) => {
              if((parseInt(e.target.value) > 60 && parseInt(courseRef) == 30 && courseRef != '' && e.target.value != '') || (parseInt(e.target.value) > 5 && parseInt(courseRef) == 360 && courseRef != '' && e.target.value != '') || (parseInt(e.target.value) > 1825 && parseInt(courseRef) == 1 && courseRef != '' && e.target.value != '')){
                Notification.error("Error", 'Duration should not be greater than 5 years');
              }
              durationOnChange(e);
              }} type="text" style={{ width: "48%", border: "#E6E6E6 solid 1px", borderRadius: "5px", color: "#000000", marginRight: "5px" }}/>              
            <Select style={{width: '48%', verticalAlign: 'middle'}} placeholder="Select plan..." defaultValue={null} value={courseRef} 
            onChange={(e) => {
              if((parseInt(courseDuration) > 60 && courseDuration != '' && e == '30' && e != '') || (parseInt(courseDuration) > 5 && e == '360' && courseDuration != '' && e != '' ) || (parseInt(courseDuration) > 1825 && e == '1' && courseDuration != '' && e != '')){
                Notification.error("Error", 'Duration should not be greater than 5 years');
              }
              durationPlanChange(e)}}>
              <Option value="1">Day(s)</Option>
              <Option value="30">Month(s)</Option>
              <Option value="360">Year(s)</Option>
          </Select>
          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
            <div style={{ width: '48%'}}>
              <Col style={{width: '100%'}}>
                <div>Start date</div>
                <div style={{width: '100%'}}>
                  <StartDate_Picker value={courseStartDate} setCourseStartDate={setCourseStartDate} />
                </div>
              </Col>
            </div>

            <div style={{ width: '48%'}}>
              <Col style={{width: '100%'}}>
                <div>End date</div>
                  <div style={{width: '100%'}}>
                    <StartDate_Picker disabled={true} value={newCourseEndDate} setCourseStartDate={setNewCourseEndDate} />
                  </div>
              </Col>
            </div>
          </div>

          <div style={{color: '#636363', fontWeight: 600, marginTop: 20}}>Total fee amount</div>
          <Input onChange={(e) => {totalFeeAmountOnChange(e)}} value={coursePrice} placeholder="Enter total fee amount" style={{borderRadius: 5, border: "#E6E6E6 solid 1px", width: '48%'}}/>

        {/* Horizontal line */}
        <div className="r-jsb m-t-30">
          <div style={{width: '100%', border: '1px solid #E6E6E6'}}></div>
        </div>

          <div className="m-t-20" style={{ fontSize: "18px", fontFamily: "roboto", fontWeight: "bold"}}>Course fee plan</div>
          <div style={{color: '#636363', fontWeight: 600, marginTop: 20}}>Fee payment plan</div>
          <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              placeholder="Select Billing plan..."
              defaultValue={null}
              style={{ width: "48%", marginTop: 20 }}
              // value={isInstalment ? courseBillingPlan : null}
              value={courseBillingPlan}
              onChange={(e) => {
                setIsNotSave(true);
                if (
                  e == "30" ||
                  e == "90" ||
                  e == "180" ||
                  e == "360" ||
                  e == "One time"
                ) {
                  setForInstallmentReset(true);
                  setForDueDate(true);
                }
                if (e == "Customize") {
                  setForCustomize(true);
                } else {
                  setForCustomize(false);
                }
                setCourseBillingPlan(e);
                setInstallments([]);
                setInstCust([]);
                if (coursePrice && coursePrice != '') {
                  AddCustomiseInstallMent();
                }
                setIsInstalment(true);
              }}
            >
              {60 <= parseInt(courseDuration) * parseInt(courseRef) ? (
                <Option value="30">Monthly</Option>
              ) : null}
              {180 <= parseInt(courseDuration) * parseInt(courseRef) ? (
                <Option value="90">Quarterly</Option>
              ) : null}
              {(
                365 <= parseInt(courseDuration) && courseRef == "1"
                  ? 365 <= parseInt(courseDuration) && courseRef == "1"
                  : 360 <= parseInt(courseDuration) * parseInt(courseRef) &&
                  courseRef != "1"
              ) ? (
                <Option value="180">Half Yearly</Option>
              ) : null}
              {(
                730 <= parseInt(courseDuration) && courseRef == "1"
                  ? 730 <= parseInt(courseDuration) && courseRef == "1"
                  : 720 <= parseInt(courseDuration) * parseInt(courseRef) &&
                  courseRef != "1"
              ) ? (
                <Option value="360">Annually</Option>
              ) : null}
              <Option value="One time">One time</Option>
              <Option value="Customize">Customize</Option>
            </Select>

            <div style={{ width: '48%', marginTop: 20, display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
            {coursePrice >= 0 &&
              courseDuration >= 0 &&
              isInstalment &&
              installments?.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                  }}
                >
                  <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', width: '94%' }}>
                    <div className="flex-column" style={{ width: '90%'}}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                      }}
                    >
                      Installment {index + 1}
                    </p>
                    <Input
                      prefix="₹"
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        color: "#000000",
                        marginRight: "5px",
                      }}
                      placeholder="Enter installment amount"
                      value={item?.installment}
                      onChange={(e) => {
                        setIsNotSave(true);
                        instCust[index] = { installment: e.target.value };
                        installments[index]["installment"] = insValidNumber(e)
                          ? e.target.value
                          : installments[index]?.installment;
                        setInstallments([...installments]);
                        setInstCust(installments);
                      }}
                    />
                    </div>
                    {(installments?.length) - 1 == index ? 
                    (<>
                    {installments?.length >= 1 && (
                      <div style={{marginTop:"30px"}}
                      onClick={()=>{
                        console.log("AA-installment",installments);
                        installments.splice(index ,1);
                        setInstallments([...installments])
                        setInstCust([...installments]);
                      }}
                      ><MinusCircleOutlined style={{color:"red",cursor:"pointer", fontSize: 20}} /></div>
                    )}
                    
                    </>)
                  :null 
                  }
                  </div>
                </div>
              ))}
            </div>

            {courseBillingPlan == "Customize" && !installmentHandler && isEditModalVisible ? (
              <div
                // className="r-c-c"
                style={{
                  cursor: "pointer",
                  color: "#1089FF",
                  fontWeight: "600",
                  fontFamily: "roboto",
                  marginLeft: 400,
                  width: 200

                }}
                onClick={() => {
                  if (coursePrice && coursePrice != '') {
                    if (installments.length == 0) {
                      AddCustomiseInstallMent();
                    } else if (
                      installments[installments.length - 1].installment
                    ) {
                      AddCustomiseInstallMent();
                    }
                  }
                }}
              >
                +ADD INSTALLMENT
              </div>
            ) : null}

            <div className="r-jsb m-t-30">
              <div style={{width: '100%', border: '1px solid #E6E6E6'}}></div>
            </div>

            <div className="r-c-sb">
            <div className="flex-column">
              <div style={{color: '#636363', fontWeight: 600, marginTop: 20}}>Actions</div>
              <div className="m-t-20" style={{ fontSize: "18px", fontFamily: "roboto", fontWeight: "bold"}}>Delete</div>
              <div style={{color: '#636363', fontWeight: 600}}>Delete this course and all it's content forever. You can't undo this!</div>
            </div>
            {isEditModalVisible ? (
              <Button loading={deleteButtonLdr} onClick={() => {setShowDeleteConfirmModal(true); console.log('hey i\'m here');}} type='transparent' style={{color: '#FF414D', fontWeight: 700, marginTop: 70, fontSize: 16}}>DELETE</Button>
            ) : null}
            </div>

          </div>
        </div>
        <CourseDeleteConfirmModal
            isModalVisible={showDeleteConfirmModal}
            setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            deleteButtonLdr={deleteButtonLdr}
            handleOk={() => {
              setDeleteButtonLdr(true);
              deleteCourse(
                () => setDeleteButtonLdr(false),
                () => {
                  setIsFromDetailsPage(true)
                  history.goBack();
                  setPageLoader(true);
                  getAllCourses(() => setPageLoader(false));
                }
              );
            }}
          />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {courseName, coursePrice, courseDuration, courseRef, courseBillingPlan, installments, specificCourseDetails, courses } = state.course;
  return {courseName, coursePrice, courseDuration, courseRef, courseBillingPlan, installments, specificCourseDetails, courses};
};

const mapDispatchToProps = (dispatch) => ({
  setCourseName: (val) => dispatch(setCourseName(val)),
  setCoursePrice: (val) => dispatch(setCoursePrice(val)),
  setCourseDuration: (val) => dispatch(setCourseDuration(val)),
  setCourseRef: (val) => dispatch(setCourseRef(val)),
  setCourseBillingPlan: (val) => dispatch(setCourseBillingPlan(val)),
  setInstallments: (val) => dispatch(setInstallments(val)),
  addCourses: (
    callback,
    successCallBack,
    val,
    courseDescription,
    startDate,
    endDate
  ) =>
    dispatch(
      addCourses(
        callback,
        successCallBack,
        val,
        courseDescription,
        startDate,
        endDate
      )
    ),
  deleteCourse: (callback, successCallBack) => dispatch(deleteCourse(callback, successCallBack)),
  getAllCourses: (callback, page, id) => dispatch(getAllCourses(callback, page, id)),
  setIsFromDetailsPage:(val) => dispatch(setIsFromDetailsPage(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsPage);