import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Divider, Dropdown, Menu, Popover, Row, Tag, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import PageHeader from 'components/pageHeader';
import { Heading, SubHeading } from 'components/Typography';
import { Button } from 'components/Button';
import { SubIcon } from 'components/subIcon';
import moment from 'moment';
import WeeklyAttendanceGraph from 'pages/attendanceNew/localComponents/weeklyAttendanceGraph'
import { CaretLeftOutlined, CaretRightOutlined, DeleteFilled, EditFilled, MoreOutlined, SettingFilled } from '@ant-design/icons';
import { MdMoreVert } from 'react-icons/md';
import { getAttendanceDashBoardData, getLocation, getRemainderSetting, markAttendance, monthlySummary, setFilterValues, setLocation, setMarkAttendance, storeMonthlySelectedMonth, studentEndDate, studentStartDate, setLoader } from 'actions/attendanceNew';
import { useHistory } from 'react-router';
import PageDataLoader from 'components/pageDataLoader';
import DoughnuttCard from 'pages/attendance_user/localcomponents/doughnuttCard';
import Calendar from 'react-calendar';
import { getStudentSummary } from 'actions/attendanceNew';
import { Notification } from 'services';
import ReminderModal from './studentModals/reminderModal';
import { setOthersSelectedTab } from 'actions/profileNew';
import RequestAttendanceModel from 'pages/attendance_user/localcomponents/requestAttendanceModel';
import { setSpecificUserId } from 'actions/userManagement';
import { showMenu } from 'actions/layout';

// import NoDataCard from 'pages/digitalLibrary/components/noDataCard';



// Icons.....
const warningIcon = require("../../Assets/ic-warning.svg").default
const holidaysIcon = require("../../Assets/settingss/ic-holidays.svg").default;
const absentIcon = require("../../Assets/user/ic-absent.svg").default;
const overallPercentageIcon = require("../../Assets/user/ic-overall.svg").default;
const presentIcon = require("../../Assets/user/ic-present.svg").default;
const avgWorkingHoursIcon = require("../../Assets/user/ic-avg-working-hrs.svg").default;
const totalWorkingHoursIcon = require("../../Assets/user/ic-working-hrs.svg").default;
const clockIcon = require('../../Assets/clock.svg').default;
const geofenceIcon = require('../../Assets/geofence.svg').default;
const timerIcon = require('../../Assets/Group 11708.svg').default;
const halfDayIcon = require('../../Assets/Attendance/Group 13469.svg').default;
const timerInsideClock = require('../../Assets/Icon material-timer.svg').default;
const E6E6E6timer = require('../../Assets/ic-timer.svg').default;

const { RangePicker } = DatePicker


const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 30px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "auto",
};

export function NodataCard({
  containerheight = "100%",
  imgwidth = "50%",
  imgheight = "50%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}

const Cards = ({ data, style, index, response }) => {
  return (
    <Col style={{ display: 'flex', justifyContent: index % 2 && 'center' }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: data.span }} xl={{ span: data.span }} >
      <div style={{ border: '1px solid #E6E6E6', borderRadius: 8, ...style, width: "98%", marginBottom: 20, backgroundColor: '#FFFFFF' }}>
        <div className='p-15 r-jsb'>
          <div style={{ width: 230 }}>
            <Heading >{data.title}</Heading>
            <div style={{ marginTop: -10 }}>{typeof data.subTitle === 'function' && data.subTitle(response)} </div>

          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "-48px", marginRight: "20px" }}>
          {data.actions}
        </div>
        <div style={{ marginTop: "10px" }}>
          {data.body(response)}
        </div>

      </div>
    </Col>
  )
}

const SummaryCard = ({ data, index }) => {
  return (
    <div style={{ width: 220, height: 120, marginTop: 10 }}>
      <div className='display-flex m-t-10 m-l-20'>
        <img src={data.icon} style={{ height: 60 }}></img>
        <div className='flex-column m-l-10'>
          <div className='color-black bold-600 text-md'>{data.data}</div>
          <div className='simgrey bold-700 text-xs'>{data.label}</div>
        </div>
      </div>
    </div>
  )
}

const AttendanceToday = ({ response, getLocation, setLocation }) => {

  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [timer, setTimer] = useState();
  const [toggler, setToggler] = useState(true);
  const [selectedTime, setSelectedTime] = useState(moment());
  const [showTime, setShowTime] = useState(false);
  const [clockLoader, setClockLoader] = useState(false);
  const [clockLdr, setClockLdr] = useState(false);
  const [clockIn, setClockIn] = useState(true)

  useEffect(() => {
    const myTimer = () => {
      var diff = moment().diff(showTime ? selectedTime : moment(), 's');
      setTimer(diff)
      let mins = diff / 60;

      setMinutes(mins % 60 ? mins % 60 : '00');
      setHours((mins / 60 ? mins / 60 : '00'))
      setToggler(!toggler)
      if (moment().format('HH:mm:ss') == '00:00:00') {
        setSelectedTime(moment());
        setShowTime(false)
      }
    }
    setTimeout(myTimer, 1000);
  }, [toggler])

  function Location(callback, successCallBack) {
    navigator.geolocation.getCurrentPosition(function (position) {
      getLocation(`${position.coords.latitude}`, `${position.coords.longitude}`);
      setLocation(() => { callback() }, () => { successCallBack() });
    }, function error(err) {
      Notification.error('error', 'Location access denied');
      return true;
    })
  }
  const clockinHandler = () => {
    setClockLdr(true)
    Location(() => {
      if (clockIn) {
        setSelectedTime(moment());
        setShowTime(true)
      }
      setClockIn(false);
    }, () => {
      setClockLdr(false)
    });
  }

  useEffect(() => {
    setSelectedTime(moment(response?.attendanceToday?.startTime, "HH:mm:ss"))
    setShowTime(true)
    setClockIn(response?.attendanceToday?.startTime ? false : true);
  }, [response?.attendanceToday?.startTime])

  return (
    <div className='m-l-20 r-jsb' style={{ width: '100%' }}>
      <div className='flex-column'>
        <div style={{ color: '#AEAEAE', fontWeight: 600, marginBottom: 5 }}>CLOCK IN</div>
        <div style={{ display: "flex" }}>
          <img style={{ height: 15, marginTop: "4px", marginLeft: "10px" }} src={clockIcon} />
          <div style={{ marginLeft: "10px" }}>{moment(response?.attendanceTodayWeb?.startTime, 'h:mm:ss').format("h:mm") != 'Invalid date' ? moment(response?.attendanceTodayWeb?.startTime, 'h:mm:ss').format("h:mm") : 'NA'}</div>
        </div>
        <div style={{ display: "flex" }}>
          <img style={{ height: 15, marginTop: "5px", marginLeft: "10px" }} src={geofenceIcon} />
          <div style={{ marginLeft: "10px" }}>{response?.attendanceTodayWeb?.geoFenceClockInTitle != null ? response?.attendanceTodayWeb?.geoFenceClockInTitle : 'NA'}</div>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <Typography.Text ellipsis={{ tooltip: true }}>
            <div style={{ width: "150px", fontFamily: 'roboto', color: '#636363', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{response?.attendanceTodayWeb?.AllocatedGeoFences[0]?.geoFenceAddress}</div>
          </Typography.Text>
        </div>

        <div className='m-t-10'>
          <div style={{ color: '#AEAEAE', fontWeight: 600, marginBottom: 5 }} >CLOCK OUT</div>
          <div style={{ display: "flex" }}>
            <img style={{ height: 15, marginTop: "4px", marginLeft: "10px" }} src={clockIcon} />
            <div style={{ marginLeft: "10px" }}>{moment(response?.attendanceTodayWeb?.endTime, 'h:mm:ss').format('h:mm') != 'Invalid date' ? moment(response?.attendanceTodayWeb?.endTime, 'h:mm:ss').format('h:mm') : 'NA'}</div>
          </div>
          <div style={{ display: "flex" }}>
            <img style={{ height: 15, marginTop: "5px", marginLeft: "10px" }} src={geofenceIcon} />
            <div style={{ marginLeft: "10px" }}>{response?.attendanceTodayWeb?.geoFenceClockOutTitle != null ? response?.attendanceTodayWeb?.geoFenceClockOutTitle : 'NA'}</div>
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-50px" }}>
        <img style={{ height: 220, marginTop: -22 }} src={timerIcon} />
        <center><img style={{ height: "30px", width: "30px", marginTop: -310 }} src={timerInsideClock} /></center>
        <div style={{ marginTop: "-130px", color: "white", fontSize: 18, fontWeight: 700, textAlign: "center" }}>
          {`${parseInt(hours)}`.padStart(2, '0')}:{`${parseInt(minutes)}`.padStart(2, '0')}:{`${parseInt(timer % 60 && timer % 60 > -1 ? timer % 60 : '00')}`.padStart(2, '0')}</div>
        <center style={{ color: "white", fontSize: 15 }}>Working hours</center>
        <Button loading={clockLdr} style={{ backgroundColor: '#28DF99', color: '#FFFFFF', marginLeft: 40, marginTop: 40 }}
          onClick={() => { clockinHandler(), console.log("AA=clockLdr", clockLdr); }}>{clockIn ? "CLOCK ME IN" : "CLOCK ME OUT"}</Button>
      </div>

    </div>
  )
}

const HolidayCalendar = ({ calendarData, leftClick, rightClick, date, user, setSpecificUserId }) => {

  const history = useHistory();

  const CustomNavigator = () => {
    return (
      <>
        <div className='r-jsb' style={{ width: '95%' }}>
          <div className='display-flex' style={{ height: 18, width: '40%', marginLeft: 10, alignItems: 'center', justifyContent: 'space-between' }}>
            <CaretLeftOutlined style={{ color: '#AEAEAE' }} onClick={leftClick} />
            <div style={{ color: '#636363', flexSize: 10 }}>{moment(date).format('MMMM, YYYY')}</div>
            <CaretRightOutlined style={{ color: '#AEAEAE' }} onClick={rightClick} />
          </div>
        </div>
      </>
    )
  }

  const tileClassName = ({ date, view }) => {
    if (view === 'month' && date.toDateString() === new Date().toDateString()) {
      return 'custom-today-class'; //removes today's hightlighter! 
    }
    return; // return null for other dates
  };

  const OnHolidayHover = ({ e }) => {
    let day = moment(e.date).format('DD-MM-YYYY');
    const isDayInCalendarData = calendarData?.some(item => item.date === day);
    console.log("AA-e123", e, calendarData, isDayInCalendarData);

    if (isDayInCalendarData) {
      return (<Tooltip title={calendarData.filter(item => item.date === day)?.map(item => item.holidayName).join(' ')}>
        <div style={{ backgroundColor: "#ff414d", height: 30, width: 30, borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", color: "white", lineHeight: 0.2 }}>{moment(e.date).format('DD')}</div>
      </Tooltip>)
    } else {
      return <div>{moment(e.date).format('D')}{console.log("AAA-e.date", e.date)}</div>
    }
  }

  return (
    // <>
    <div style={{ height: '50vh', width: '100%', margin: '10px 3px' }}>
      {/* <div style={{color: '#191919', flexSize: 14, margin: "15px 0 0 10px"}}>Holidays</div> */}
      <CustomNavigator />
      <Calendar
        className="attendance-home"
        tileContent={(e) => <OnHolidayHover e={e} />}
        showNavigation={false}
        defaultView='month'
        calendarType={'ISO 8601'}
        activeStartDate={date}
        showNeighboringMonth={false}
        value={null}
        tileClassName={tileClassName}
      />
    </div>
    // </>
  )
}

const AttendanceRequests = ({ data }) => {

  const menu = (
    <div onClick={(e) => { e.stopPropagation() }} style={{}}>
      <Menu style={{ width: "100%" }}>
        <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => { }}>
          <EditFilled />
          Edit
        </Menu.Item>
        <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => { }}>
          <DeleteFilled />
          Delete
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <>
      {data?.upcomingLeaveOrRequest?.length ? data?.upcomingLeaveOrRequest?.map((item, index) => {
        var hours = Math.floor(item?.totalHours);
        var minutes = Math.round((item?.totalHours - hours) * 60);
        var formattedTotalHours = hours + ":" + (minutes < 10 ? "0" : "") + minutes;
        return (
          <div className='p-20'>
            <div className='' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ width: 100 }}>{moment(item?.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
              <div style={{ width: 170 }}>{moment(item?.clockIn, 'hh:mm:ss').format('hh:mm a')} - {moment(item?.clockOut, 'hh:mm:ss').format('hh:mm a')}{console.log("AA-dataaaa", item?.totalHours, item?.startDate, moment(item?.clockIn) - item?.clockOut)}</div>
              <div style={{ width: 50 }}>{formattedTotalHours}hrs</div>
              <div style={{
                height: "14px",
                width: 50,
                marginTop: '5px',
                border: `${item?.status === "Pending" ? "1px solid #FFA931" : item?.status === "Approved" ? "1px solid #28DF99" : item?.status === "Rejected" ? "1px solid #FF414D" : null}`,
                borderRadius: " 3px",
                background: `${item?.status === "Pending" ? "#FFF4D5" : item?.status === "Approved" ? "#E9FCF5" : item?.status === "Rejected" ? "#FFE4D9" : null}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <div style={{
                  font: "normal normal bold 8px/10px Roboto",
                  color: `${item?.status === "Pending" ? "#FFA931" : item?.status === "Approved" ? "#28DF99" : item?.status === "Rejected" ? "#FF414D" : null}`
                }}>{item?.status === "Pending" ? "PENDING" : item?.status === "Approved" ? "APPROVED" : item?.status === "Rejected" ? "REJECTED" : null}</div>
              </div>
              <div>
                <Popover placement="bottomRight" content={menu}>
                  <MoreOutlined style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation() }} />
                </Popover>
              </div>
            </div>
          </div>
        )
      }) : <div><NodataCard /></div>}
    </>
  )
}

const GeofenceCard = ({ title, subtitle, totalDuration, geoFenceType, address, geoEnd, geoStart }) => {
  const start = moment.utc(geoStart);
  const end = moment.utc(geoEnd);
  const diffInDays = end.diff(start, 'days');
  console.log('dateeeeee', diffInDays);


  return (
    <div>
      <div style={{ width: 300, height: 130, border: '1px solid #E6E6E6', borderRadius: 8, backgroundColor: '#FFFFFF', padding: 10 }}>
        <div style={{ color: '#191919', fontSize: 16, fontWeight: 600 }}>{title}</div>
        <div style={{ color: '#636363', wordWrap: 'break-word', width: 270, marginTop: 5 }}>{subtitle}</div>
        <Typography.Text ellipsis={{ tooltip: true }}>
          <div style={{ width: "250px", fontFamily: 'roboto', color: '#636363', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{address != '' ? address : 'NA'}</div>
        </Typography.Text>
        <div className='display-flex m-t-5'>
          <img src={E6E6E6timer} style={{ height: 20, fill: '#E6E6E6' }} />
          <div style={{ color: '#AEAEAE', marginLeft: 10 }}>{geoFenceType == 'Permanent' ? 'All time' : `${moment.utc(geoStart).local().format('DD-MM-YYYY')} - ${moment.utc(geoEnd).local().format('DD-MM-YYYY')} • ${diffInDays} Days`}  </div>
        </div>
      </div>
    </div>
  )
}

const summary = (response) => [
  {
    icon: presentIcon,
    data: response?.attendanceOverview?.PresentCount,
    label: 'Present day(s)',
  },
  {
    icon: absentIcon,
    data: response?.attendanceOverview?.AbsentCount,
    label: 'Absent day(s)',
  },
  {
    icon: halfDayIcon,
    data: response?.attendanceOverview?.HalfPresentCount,
    label: 'Half day',
  },
  {
    icon: overallPercentageIcon,
    data: `${response?.attendanceOverview?.overallPercentage}%`,
    label: 'Overall percentage',
  },
  {
    icon: totalWorkingHoursIcon,
    data: response?.attendanceOverview?.totalWorkingHoursTillDate,
    label: 'Total working hours',
  },
  {
    icon: avgWorkingHoursIcon,
    data: response?.attendanceOverview?.averageWorkingHoursTillDate,
    label: 'Average working hours',
  },
]

const monthlySummaryData = (response) => [
  {
    type: 'Present',
    value: response?.monthlyAttendance?.presentDays,
  },
  {
    type: 'Absent',
    value: response?.monthlyAttendance?.absentDays,
  },
  {
    type: 'Half Day',
    value: response?.monthlyAttendance?.halfDays,
  }
];

const monthlySummaryPercentage = (response) => response?.monthlyAttendance?.averagePercentage;
const disabledYearAndMonth = current => {
  // Disable years before 2022 1st January
  if (current && current < moment("2022-01-01", "YYYY-MM-DD")) {
    return true;
  }
  // Disable months after current month in current year
  if (current && current > moment().endOf('month')) {
    return true;
  }
  return false;
};

const cardsData = (history, getAttendanceDashBoardData, getLocation, setLocation, filterValues, setFilterValues, storeMonthlySelectedMonth, storeMonthlySummaryMonth, setOthersSelectedTab, user, setIsModalVisible, calendarData, handleCalendarLeftClick, handleCalendarRightClick, date, setSpecificUserId, setLoader, setMarkAttendance) => [

  {
    title: 'Attendance overview',
    span: 16,
    subTitle: (response) => {
      return <div style={{ color: '#636363', fontWeight: 600 }}>{response?.since == 'Invalid date' ? <></> : `since ${moment(response?.since != 'Invalid date' ? response?.since : <></>).format('DD/MM/YYYY')}`}</div>
    },
    actions: (
      <Button type='link' style={{ fontSize: 12, cursor: "pointer" }} onClick={() => { setOthersSelectedTab('Attendance'), history.push({ pathname: '/user-profile/others' }) }}>VIEW PROFILE</Button>
    ),
    body: (response) => {
      return (
        <Row>
          {summary(response)?.map((data, index) => { return <SummaryCard data={data} index={index} /> })}
        </Row>
      )
    }
  },
  {
    title: 'Your attendance today',
    span: 8,
    subTitle: (response) => {
      return <div style={{ color: '#636363', fontWeight: 600, fontSize: 11 }}>{moment().format('DD/MM/YYYY')} • {moment().format('dddd')}</div>
    },
    actions: (
      <Button type='link' style={{ fontSize: 11, marginTop: 10 }} onClick={() => {
        setIsModalVisible(true), setLoader('modalClockLoader', false)
        setMarkAttendance('startDate', null)
        setMarkAttendance('clockIn', null)
        setMarkAttendance('clockOut', null)
        setMarkAttendance('reason', null)
        setMarkAttendance('totalhours', null)
      }}

      >FORGOT TO CLOCK IN/OUT?</Button>
    ),
    body: (response) => (
      <div style={{ marginRight: "30px" }}><AttendanceToday response={response} getLocation={getLocation} setLocation={setLocation} user={user} /></div>
    )
  },
  {
    title: 'Weekly summary',
    span: 16,
    subTitle: (response) => {
      return <div style={{ color: '#636363', fontWeight: 600 }}><div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <div style={{ fontSize: "9px", color: "#636363" }}>
            <CaretLeftOutlined
              onClick={() => {
                setFilterValues('startDate', moment(filterValues?.startDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'))
                setFilterValues('endDate', moment(filterValues?.endDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'))
                // togglePageLoader(true)
                getAttendanceDashBoardData(() => { /* togglePageLoader(false) */ })
              }}
            />
          </div>
          <div
            style={{
              marginLeft: "5px",
              fontSize: "9px",
              color: "#636363",
            }}
          >
            {/* 11/07/2021 - 17/07/2021 */}
            {filterValues?.startDate ? filterValues?.startDate : null} {""}{" "}{"-"}{" "}{""}  {filterValues?.endDate ? filterValues?.endDate : null}

          </div>

          {!(filterValues?.startDate === moment().startOf('week').format("YYYY-MM-DD")
            &&
            filterValues?.endDate === moment().endOf('week').format("YYYY-MM-DD"))
            ?
            <div style={{ fontSize: "9px", color: "#636363" }}>

              <CaretRightOutlined
                onClick={() => {
                  setFilterValues('startDate', moment(filterValues?.startDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'))
                  setFilterValues('endDate', moment(filterValues?.endDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'))
                  // togglePageLoader(true)
                  getAttendanceDashBoardData(() => { /* togglePageLoader(false) */ })
                }}
              />
            </div> : null}
        </div>
      </div></div>

    },

    actions: (

      <RangePicker
        onKeyDown={(e) => { e.preventDefault() }}
        allowClear={false}
        value={[((moment(filterValues?.startDate))), (moment(filterValues?.endDate))]}
        onChange={(date, dateString) => {
          setFilterValues('startDate', dateString[0])
          setFilterValues('endDate', dateString[1])
          getAttendanceDashBoardData(() => { })
        }}
      />

    ),
    body: (response) => (
      <div style={{ height: 280, padding: 5 }}>
        <WeeklyAttendanceGraph
          data={response?.weeklyAttendance?.length && response?.weeklyAttendance?.length != 0 ? response?.weeklyAttendance : []}
          color={"#28DF99"}
        />
      </div>
    )
  },
  {
    title: 'Monthly summary',
    span: 8,
    subTitle: (response) => {
      return <div style={{ color: '#636363', fontWeight: 600 }}>
        {storeMonthlySummaryMonth}
      </div>
    },
    actions: (
      <DatePicker picker={'month'}
        onKeyDown={(e) => { e.preventDefault() }}
        allowClear={false}
        value={moment(storeMonthlySummaryMonth, 'MMM-YY')}
        disabledDate={disabledYearAndMonth}
        onChange={(date, dateString) => {
          storeMonthlySelectedMonth(dateString);
          getAttendanceDashBoardData(() => { })
        }}
        format={"MMM-YY"}
      />
    ),
    body: (response) => (
      <div style={{ marginTop: "20px", marginTop: "30px" }}><DoughnuttCard data={monthlySummaryData(response)} percentage={monthlySummaryPercentage(response)} /></div>
    )
  },
  {
    title: 'Holidays',
    span: 10,
    actions: (
      <Button type='link' style={{ fontSize: 12 }} onClick={() => { setOthersSelectedTab('Holidays'), history.push({ pathname: '/user-profile/others' }) }}>VIEW CALENDAR</Button>
    ),
    body: (response) => (
      <div style={{ marginTop: "-12px", marginLeft: "30px" }}>
        {<HolidayCalendar calendarData={calendarData} leftClick={() => handleCalendarLeftClick()} rightClick={() => handleCalendarRightClick()} date={date} user={user} setSpecificUserId={setSpecificUserId} setOthersSelectedTab={setOthersSelectedTab} />}
      </div>
    )
  },
  {
    title: 'Attendance request(s)',
    span: 14,
    actions: (
      <Button type='link' style={{ fontSize: 12 }} onClick={() => { setOthersSelectedTab('Attendance Request(s)'); history.push({ pathname: '/user-profile/others' }) }}>VIEW ALL</Button>
    ),
    body: (response) => (
      <AttendanceRequests data={response} />
    )
  },
]

const MyAttendanceStudent = ({ getAttendanceDashBoardData, attendanceDataContainer, getStudentSummary, studentMonthlySummary, monthlySummary, studentStartDate, studentEndDate, studentattStartDate, studentattEndDate, getLocation, setLocation, getRemainderSetting, storeRemainderData, filterValues, setFilterValues, storeMonthlySelectedMonth, storeMonthlySummaryMonth, setOthersSelectedTab, user, setMarkAttendance, markAttendance, setSpecificUserId, setLoader, loaders, showMenu }) => {
  let holidayArr = [];

  const history = useHistory();
  const currentDate = new Date();

  const [sum, setSum] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSettingModal, setIsSettingModal] = useState(false)
  const [calendarData, setCalendarData] = useState([]);
  const [date, setDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));

  const handleCalendarLeftClick = () => {
    setDate(new Date(moment(date).subtract(1, 'months')));
  }

  const handleCalendarRightClick = () => {
    setDate(new Date(moment(date).add(1, 'months')));
  }

  useEffect(() => {
    showMenu(true)
    getAttendanceDashBoardData(() => { });
  }, []);

  useEffect(() => {
    setFilterValues('startDate', (moment().startOf('week').format("YYYY-MM-DD")));
    setFilterValues('endDate', (moment().endOf('week').format("YYYY-MM-DD")));
    storeMonthlySelectedMonth(moment().format("MMMM"));
    attendanceDataContainer?.holidays?.forEach((items) => {
      items?.data?.map((item) => {
        holidayArr.push({
          holidayName: item?.title,
          date: moment(item?.date).format('DD-MM-YYYY')
        })
      })
      setCalendarData(holidayArr)
    })
  }, [])

  useEffect(() => {
    let valOfDuration = attendanceDataContainer?.weeklyAttendance?.map((item) => item?.durationInMinutes);
    if (valOfDuration && valOfDuration?.length != 0 && Math?.max(...valOfDuration) > 0) {
      setSum(1);
    } else {
      setSum(0);
    }
  }, [attendanceDataContainer?.weeklyAttendance]);

  const data = cardsData(history, getAttendanceDashBoardData, getLocation, setLocation, filterValues, setFilterValues, storeMonthlySelectedMonth, storeMonthlySummaryMonth, setOthersSelectedTab, user, setIsModalVisible, calendarData, handleCalendarLeftClick, handleCalendarRightClick, date, setSpecificUserId, setLoader, setMarkAttendance, setMarkAttendance);

  const actions = [
    <Button icon={<SettingFilled />} onClick={() => { getRemainderSetting(), setIsSettingModal(true) }}>SETTING</Button>
  ];

  return (
    <div style={bodyStyle}>
      <PageHeader title='Attendance' actions={actions} />
      <Row className='r-jsb m-l-10 m-t-60'>
        {data?.map((data, index) => <Cards style={{ height: 360 }} data={data} index={index} response={attendanceDataContainer} filterValues={filterValues} />)}
      </Row>
      <Heading>Assigned geo-fence location(s)</Heading>
      <div style={{ display: 'flex' }}>
        {attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences != '' ? attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences?.map((Geofence) => {
          return <div style={{ marginLeft: "15px" }}><GeofenceCard title={Geofence?.geoFenceTitle} subtitle={Geofence?.geoFenceTitle} totalDuration={null} geoFenceType={Geofence?.geoFenceType} address={Geofence?.geoFenceAddress}
            geoEnd={Geofence?.geoEnd} geoStart={Geofence?.geoStart} /></div>
        })
          :
          (
            <div style={{ marginTop: "10px", marginLeft: "200px" }}>
              <NodataCard />
            </div>
          )
        }
      </div>
      <ReminderModal
        isModalVisible={isSettingModal}
        setIsModalVisible={setIsSettingModal}
        storeRemainderData={storeRemainderData}
      />
      <RequestAttendanceModel user={user} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} setMarkAttendance={setMarkAttendance} markAttendance={markAttendance} setLoader={setLoader} loaders={loaders} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { user } = state.session;
  const { attendanceDataContainer, studentMonthlySummary, studentattStartDate, studentattEndDate, storeRemainderData, filterValues, storeMonthlySummaryMonth, loaders } = state.attendanceNew;
  return { attendanceDataContainer, studentMonthlySummary, studentattStartDate, studentattEndDate, storeRemainderData, filterValues, storeMonthlySummaryMonth, user, loaders };
};

const mapDispatchToProps = (dispatch) => ({
  getAttendanceDashBoardData: (callBack) => dispatch(getAttendanceDashBoardData(callBack)),
  getStudentSummary: (callBack) => dispatch(getStudentSummary(callBack)),
  monthlySummary: (val) => dispatch(monthlySummary(val)),
  studentStartDate: (val) => dispatch(studentStartDate(val)),
  studentEndDate: (val) => dispatch(studentEndDate(val)),
  getLocation: (val1, val2) => (dispatch(getLocation(val1, val2))),
  setLocation: (callback, successCallBack) => (dispatch(setLocation(callback, successCallBack))),
  getRemainderSetting: () => (dispatch(getRemainderSetting())),
  setFilterValues: (key, val) => dispatch(setFilterValues(key, val)),
  storeMonthlySelectedMonth: (val) => dispatch(storeMonthlySelectedMonth(val)),
  setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
  setMarkAttendance: (key, val) => dispatch(setMarkAttendance(key, val)),
  markAttendance: (callBack) => dispatch(markAttendance(callBack)),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  showMenu: (bool) => dispatch(showMenu(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAttendanceStudent);
