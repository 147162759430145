import { getRecordings, setCompletedSubName } from 'actions/broadcast'
import { fetchScheduleFilterTeacherandStudent, fetchScheduleStudent, fetchStudentStandards, setCardData, setJoinLoader } from 'actions/schedule'
import { DatePicker, Input, Image, Typography, Dropdown, Select } from 'antd'
import PageDataLoader from 'components/pageDataLoader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import emptyImage from '../../Assets/schedule/Group 10647.svg'
import CancelledClassTandS from './cancelledClassTandS'
import CompletedClassTandS from './completedClassTandS'
import OnGoingClassTandS from './onGoingClassTandS'
import ScheduleClassTandS from './scheduleClassTandS'
import RecordingsModal from 'pages/broadcast/localComponent/recordingsModal'
import moment from 'moment'
import PageHeader from 'components/pageHeader'
import { showMenu } from 'actions/layout'

const { Search } = Input
const { Text } = Typography
const data = true

const DropdownCheckbox = ({ selectedStandards, setSelectedStandards, studentStandardFilters, fetchScheduleFilterTeacherandStudent }) => {
    const handleStandardChange = (value) => {
        fetchScheduleFilterTeacherandStudent(null, null, value)
        setSelectedStandards(value);
    };

    return (
        <div style={{ width: '80%' }}>
            <Select
                style={{ width: '100%' }}
                placeholder="Select standards"
                onChange={handleStandardChange}
                value={selectedStandards}
            >
                {studentStandardFilters?.studentStandards?.map((standard) => (
                    <Option key={standard.standardId} value={standard.standardId}>
                        {`${standard.std} - ${standard?.section}`}
                    </Option>
                ))}
            </Select>
        </div>
    );
};


const ScheduleStudent = ({ fetchScheduleStudent, scheduleList, fetchScheduleFilterTeacherandStudent, setCompletedSubName, getRecordings, setJoinLoader, fetchStudentStandards, studentStandardFilters, setCardData, showMenu }) => {

    const initialSelectedStandard = studentStandardFilters?.studentStandards?.length > 0 ? studentStandardFilters?.studentStandards[0]?.standardId : null;

    const [pageLoader, togglePageLoader] = useState(false);
    const [isRecordingsModalVisible, setRecordingsModalVisibility] = useState(false);
    const [date, setDate] = useState('')
    const [search, setSearch] = useState('')
    const [selectedStandards, setSelectedStandards] = useState(initialSelectedStandard);

    useEffect(() => {
        showMenu(true)
        togglePageLoader(true)
        setJoinLoader(false);
        fetchScheduleStudent(() => togglePageLoader(false))
        setDate(moment().format('DD/MM/YYYY'))
        fetchStudentStandards()
    }, [])

    const onChangeDate = (date, dateString) => {
        setDate(dateString)
        fetchScheduleFilterTeacherandStudent(dateString, search)
    }
    const onSearchSubject = (value) => {
        setSearch(value)
        fetchScheduleFilterTeacherandStudent(date, value)
    }
    const handleReplay = (streamId, subject, completedItem) => {
        setCardData(completedItem)
        setCompletedSubName(subject.name);
        getRecordings(streamId, () => {
            setRecordingsModalVisibility(true);
        });
    };

    const actions = [
        <>
            <DropdownCheckbox selectedStandards={selectedStandards} setSelectedStandards={setSelectedStandards} studentStandardFilters={studentStandardFilters} fetchScheduleFilterTeacherandStudent={fetchScheduleFilterTeacherandStudent} />
            <DatePicker style={{ width: '50%', marginLeft: 10 }} onChange={onChangeDate} showToday={true} value={date ? moment(date, 'DD/MM/YYYY') : moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY')} format={'DD/MM/YYYY'} />
            <Input.Search size={"middle"} type="test" placeholder="Search by Subject" style={{ border: "0px", fontSize: "1em", marginLeft: '30px' }} allowClear onSearch={onSearchSubject} />
        </>
    ]


    return (
        <div>
            <div className="ScheduleTeacherHeaders">
                <div style={{ width: '60%' }}>
                    <PageHeader title={'Schedule'} actions={actions} />
                </div>
            </div>
            {scheduleList?.ongoing?.length === 0 && scheduleList?.scheduled?.length === 0 && scheduleList?.completed?.length === 0 && scheduleList?.cancelled?.length === 0 ?
                <div className="ScheduleTeacherContentMain">
                    <div className="ScheduleTeacherContentEmpty">
                        <Image src={emptyImage} preview={false} />
                        <Text style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'roboto' }}>No live classes scheduled!</Text>
                        <Text style={{ color: "#636363", fontSize: '14px', fontFamily: 'roboto', textAlign: 'center', marginBottom: '8px' }}>There are no live classes scheduled yet.</Text>
                    </div>
                </div>
                :
                <div>
                    {scheduleList?.ongoing?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Ongoing live classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.ongoing?.map((item) => (<OnGoingClassTandS onGoingItem={item} />))}
                            </div>
                        </div>
                        : null}
                    {scheduleList?.scheduled?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Schedule classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.scheduled?.map((item) => (<ScheduleClassTandS scheduleItem={item} />))}
                            </div>
                        </div>
                        : null}
                    {scheduleList?.completed?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Completed classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.completed?.map((item) => (<CompletedClassTandS completedItem={item} handleReplay={handleReplay} />))}
                            </div>
                        </div>
                        : null}
                    {scheduleList?.cancelled?.length != 0 ?
                        <div style={{ marginTop: '16px' }}>
                            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>Cancelled classes</Text>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {scheduleList?.cancelled?.map((item) => (<CancelledClassTandS cancelledItem={item} />))}
                            </div>
                        </div>
                        : null}
                </div>


            }
            <PageDataLoader visible={pageLoader} />
            <RecordingsModal isRecordingsModalVisible={isRecordingsModalVisible} setRecordingsModalVisibility={setRecordingsModalVisibility} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { scheduleList, studentStandardFilters } = state.schedule;
    return { scheduleList, studentStandardFilters };
};

const mapDispatchToProps = (dispatch) => ({
    fetchScheduleStudent: (callback) => dispatch(fetchScheduleStudent(callback)),
    fetchScheduleFilterTeacherandStudent: (date, search, classIds) => dispatch(fetchScheduleFilterTeacherandStudent(date, search, classIds)),
    getRecordings: (streamId, callback) => dispatch(getRecordings(streamId, callback)),
    setCompletedSubName: (name) => dispatch(setCompletedSubName(name)),
    setJoinLoader: (bool) => dispatch(setJoinLoader(bool)),
    fetchStudentStandards: () => dispatch(fetchStudentStandards()),
    setCardData: (val) => dispatch(setCardData(val)),
    showMenu: (bool) => dispatch(showMenu(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleStudent);
