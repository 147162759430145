import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { saveSupportDetails, setGsSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab } from 'actions/profileNew';
import { Col, Row } from 'antd';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Notification } from 'services';
// import { ProfileInput } from './detailFields';
import ProfileInput from './detailFields'
import SaveConfirmation from './saveConfirmation';
import { easySwitch } from './subComponents';

const SupportSettings = ({supportPhones, saveSupportDetails, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab}) => {
    useEffect(() => {
        updatePhoneList([...supportPhones])
    }, [supportPhones]);
    const [phoneList, updatePhoneList] = useState([]);
    const [isSaveHidden, setIsSaveHidden] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        setIsSaveHidden(
            JSON.stringify(supportPhones) === JSON.stringify(phoneList) 
        )
    }, [phoneList])
    
    const history = useHistory();

    const handleAddField = () => {
        phoneList.push('');
        updatePhoneList([...phoneList])
    }

    const handleChange = (e, index) => {
        phoneList[index] = e;
        updatePhoneList([...phoneList])
    }

    const handleSaveConfirm = (callback, buttonCallback) => {
        const params = {supportPhones: phoneList};
        delete params.basicprofile;
        saveSupportDetails(params, callback, buttonCallback)
    }

    const handleSaveClick = () => {
        if(!phoneList[phoneList?.length - 1] || phoneList[phoneList?.length - 1] === ""){
            Notification.error('error',"Phone number should not be empty!")
        }else if((new Set(phoneList)).size !== phoneList.length){
            Notification.error('error',"Phone numbers dupicated!")
        }else{
            setShowConfirmModal(true)
        }
    } 

    const removeNumber = (index) => {
        phoneList.splice(index, 1);
        updatePhoneList([...phoneList])
    }


    const handleBackPress = () => {
        if(["/general-settings","/view-kyc", "/subscriptions", "/support-settings", "/user-profile/others"].includes(history.location?.state?.from)){
            history.push('/account-settings')
        }else{
            history.goBack()
        }
      }

    return (
        <div style={{height: "100%"}}>
           <PageHeader 
                title="aulas Care Support"
                onBackPress={handleBackPress}
                actions={[
                    isSaveHidden ? null : 
                        <Button type="link" style={{fontSize: 14}} 
                            onClick={() => handleSaveClick()}
                            disabled={phoneList.filter(item => !item || item === "").length > 0}
                        >SAVE</Button>
                ]}
                easySwitch={easySwitch(history, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab)}
            />
            <div className='r-c-c' >
                <div className=' m-t-100 m-l-10' style={{width: "80%"}}>
                    <div style={{fontSize: 18, fontWeight: 600}} className="m-t-20">
                        Support details
                    </div>
                    <div style={{color: "#636363"}}>
                        Support details for students to contact for inquires
                    </div>
                </div>
            </div>
            <div className='r-c-c m-t-40'>
            <Row style={{width: "80%"}} gutter={20}>
                {   phoneList?.map((phone, index) => (
                    <Col key={index} sm={{span: 24}} md={{span: 12}}>
                            <div className='r-c-c'>
                                <ProfileInput 
                                    label={"Phone number " + (index+1)}
                                    type="phone"
                                    value={phone}
                                    disabled={user.privilage.includes("SuperAdmin") ? false : true}
                                    onChange={e=> handleChange(e, index)}
                                />
                                {user.privilage.includes("SuperAdmin") ?
                                <CloseCircleOutlined className="m-l-10 cursor-pointer" style={{color: 'red'}} onClick={() => removeNumber(index)}/> : 
                                null}
                            </div>
                    </Col>
                ))
                }
            </Row>
            </div>
            {user.privilage.includes("SuperAdmin") ? 
            <div className='r-c-c m-t-100'>
                <Button type="link" 
                    style={{fontSize: 14}} 
                    onClick={() => handleAddField()} 
                    icon={<PlusCircleOutlined />}
                    disabled={phoneList?.length!==0 && (!phoneList[phoneList?.length - 1] || phoneList[phoneList?.length - 1] === "")}
                >
                    ADD MORE
                </Button>
            </div> : null}
            <SaveConfirmation 
                handleOk={(callback, buttonCallback) => handleSaveConfirm(callback, buttonCallback)}
                saveConfirmationModal={showConfirmModal}
                onCancel={() => setShowConfirmModal(false)}
            />
        </div>
    );
}

const mapStateToProps = (state) => { 
    const {user} = state.session;
    const {supportPhones} = state.profileNew;
    return {
        user,
        supportPhones
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveSupportDetails: (params, callback, buttonCallback) => dispatch(saveSupportDetails(params, callback, buttonCallback)),
    setGsSelectedTab: tab => dispatch(setGsSelectedTab(tab)),
    setSubscriptionSelectedTab: tab => dispatch(setSubscriptionSelectedTab(tab)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportSettings);