import React, { useState, useEffect } from 'react';
import { AutoComplete, Modal } from 'antd';
import { Button } from 'components/Button';

const TransferModal = ({ transferModal, setTransferModal, searchedTeachers, searchUsers, transferData, userToTransferData, setTemp, temp, dataType }) => {
        const [options, setOptions] = useState([]);
        const [search, setSearch] = useState('');
        const [targetTeacherId, setTargetTeacherId] = useState(null);
        const [buttonLoader, setButtonLoader] = useState(false);

        useEffect(() => {
                const params = {
                        name: search,
                        role: "Teacher",
                        includeSuperAdmin: 1,
                };
                if(dataType == 'video' || dataType == 'lecture-notes' || dataType == 'question-bank')
                {
                        params.role = "Data Operator"
                        params['includeAdmin'] = 1
                }

                searchUsers(params, () => { })
        }, [])

        useEffect(() => {
                setOptions(searchedTeachers ?? []);
        }, [searchedTeachers])

        const handleTransferClick = () => {
                let params;
                if (dataType == 'schedule') {
                        params = {
                                sourceUserId: userToTransferData.id,
                                scheduleTargetUserId: targetTeacherId,
                                scheduleIds: temp,
                        }
                }
                else if (dataType == 'liveTest') {
                        params = {
                                sourceUserId: userToTransferData.id,
                                liveTestTargetUserId: targetTeacherId,
                                liveTestIds: temp,
                        }
                }
                else if (dataType == 'practiceTest') {
                        params = {
                                sourceUserId: userToTransferData.id,
                                practiceTestTargetUserId: targetTeacherId,
                                practiceTestIds: temp,
                        }
                }
                else if (dataType == 'video' || dataType == 'lecture-notes' || dataType == 'question-bank') {
                        params = {
                                sourceUserId: userToTransferData.id,
                                digitalLibraryTargetUserId: targetTeacherId,
                                digitalResourceIds: temp,
                        }
                }

                const successCallback = () => {
                        setTransferModal(false)
                        setTargetTeacherId(null);
                        setTemp([]);
                }

                setButtonLoader(true);
                transferData(params, () => setButtonLoader(false), () => successCallback())
        };

        const handleCancel = () => {
                setTransferModal(false);
        };

        const onSelect = (value, obj) => {
                if (value) {
                        setTargetTeacherId(obj?.id)
                }
        };

        const onKeyDown = (e) => {
                if (e.keyCode === 8) {
                        console.log('delete');
                        setTargetTeacherId(null);
                        setTemp([]);
                        setSearch('')
                }
        };

        console.log({ targetTeacherId })
        console.log({ search })

        return (
                <>
                        <Modal
                                centered
                                closable
                                footer={false}
                                open={transferModal}
                                onCancel={handleCancel}
                                className="modal-round-corner"
                        >
                                <div style={{ width: "100%" }}>
                                        <div style={{ textAlign: "center", fontSize: "17px", fontWeight: "bold", marginTop: "40px" }}>Transfer data</div>
                                        <div style={{ textAlign: "center", fontSize: "13px", color: '#636363', marginTop: "8px" }}>Transfer ownership of selected data to another user</div>
                                        <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold", marginTop: "30px", marginLeft: "20px" }}>User name</div>
                                        <div className='r-c-c p-20'>
                                                <AutoComplete
                                                        allowClear={true}
                                                        options={options}
                                                        onKeyDown={(e) => onKeyDown(e)}
                                                        style={{
                                                                width: '100%',
                                                                marginTop: -20
                                                        }}
                                                        onSelect={onSelect}
                                                        onSearch={(text) => setSearch(text)}
                                                />
                                        </div>

                                </div>
                                <div style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}>
                                        <Button disabled={targetTeacherId == null && search == '' ? true : false} loading={buttonLoader} type="primary" onClick={() => handleTransferClick(dataType)}>
                                                CONFIRM TRANSFER
                                        </Button>
                                </div>
                        </Modal>
                </>
        );
};

export default TransferModal;