import { fetchMarkingSchemeData, postCreateMarkingScheme, storeMarkedSchemeCardId, storeMarkingSchemeDesc, storeMarkingSchemeObjective, storeMarkingSchemeSubjective, storeMarkingSchemeTitle } from 'actions/testAdmin';
import { Button, Checkbox, Input, Modal, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "./modal.css";
import { fetchMarkingSchemeDataTS } from 'actions/marketTest';

const handleKeyDown = (e) => {
    if(e.key === '+' || e.key === '-'){
        e.preventDefault();
    }
};

const ObjectiveTypeRow = ({ objectiveTypeContainer, objectiveRowIndex }) => {
    const [visibleMCQ, setVisibleMCQ] = useState(false);
    const [visibleCheck, setVisibleCheck] = useState(null);
    const [addMultipleCount, setAddMultilpleCount] = useState(1);
    const [maxOptions, setMaxOptions] = useState(null)
    const [schemaState, setSchemaState] = useState([]);

    const handleChange = (value) => {
        setVisibleCheck(value)
        setMaxOptions(null)
        if (!(value == "MSQ" || value == "CMSQ")) {
          setVisibleMCQ(false)
        }
    }

    const checkboxChange = (e) => {
        if (e.target.checked == true) {
            setVisibleMCQ(true)
        } else {
            setVisibleMCQ(false);
            setMaxOptions(null)
        }
    };

    const optionOnChange = (value) => {
        setMaxOptions(value)
        const conditions = [];
        if (value) {
            for (let correctOptionCount = parseInt(value); correctOptionCount > 0; correctOptionCount--) {
                for (let selected = correctOptionCount; selected > 0; selected--) {
                    conditions.push({
                        correctOptionCount,
                        selected,
                        positiveMarks: null
                    })
                }
            }
        }
        setSchemaState(conditions)
    }

    const onFillObjective = (index, key, value) => {
        if (objectiveTypeContainer[index]) {
            objectiveTypeContainer[index][`${key}`] = value
        } else {
            objectiveTypeContainer?.push(
                {
                    index,
                    [`${key}`]: value
                }
            )
        }
        if (key === "type") {
            if (!(objectiveTypeContainer[index]?.type === "MSQ" || objectiveTypeContainer[index]?.type === "MSQ")) {
              objectiveTypeContainer[index].partialMarking = false;
              objectiveTypeContainer[index].partialMarkingSchema = {};
            }
          }
        if (key === 'noOfOptions' || key === 'schema') {
            let { partialMarkingSchema } = objectiveTypeContainer[index]
            if (!partialMarkingSchema) objectiveTypeContainer[index].partialMarkingSchema = {};
            objectiveTypeContainer[index].partialMarkingSchema[`${key}`] = value;
            delete objectiveTypeContainer[index][`${key}`]
        }
        return objectiveTypeContainer;
    }

    return (
        <>
            <div style={{ display: "flex"/* , justifyContent: 'space-between' */, marginBottom: 30 }}>
                <div style={{ width: '20em' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Question type</div>
                        {(visibleCheck === "MSQ" || visibleCheck == "CMSQ") ?
                            (<div style={{ marginLeft: 5 }}>
                                <Checkbox checked={visibleMCQ}
                                    onChange={(e) => { checkboxChange(e); onFillObjective(objectiveRowIndex, "partialMarking", e.target.checked) }}>Partial marking awarded?</Checkbox>
                            </div>)
                            :
                            null
                        }
                    </div>
                    <Select
                        style={{ width: '100%', marginTop: 5 }}
                        onChange={(value) => { handleChange(value); onFillObjective(objectiveRowIndex, "type", value) }}
                        value={visibleCheck}
                        placeholder="Select Question Type">
                        <Option value="MCQ">Multiple choice - One correct answer</Option>
                        <Option value="MSQ">Multiple choice - Multiple correct answer</Option>
                        <Option value="Numeric">Numeric type questions</Option>
                        <Option value="CMCQ">Comprehensive - One correct answer</Option>
                        <Option value="CMSQ">Comprehensive - Multiple correct answer</Option>
                        <Option value="CNum">Comprehensive - Numeric type</Option>
                        <Option value="CTF">Comprehensive - True/False</Option>
                        <Option value="T/F">True/False</Option>
                    </Select>
                </div>
                {visibleMCQ ?
                    <>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ marginLeft: 45 }}>
                                <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Maximum of options</div>
                                <Select
                                    style={{ width: '19.5em', marginTop: 7 }}
                                    placeholder="Select"
                                    onChange={(value) => { optionOnChange(value); onFillObjective(objectiveRowIndex, "noOfOptions", value) }}>
                                    {/* <Option value="1">1</Option> */}
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                </Select>
                            </div>
                            {maxOptions ?
                                <div>
                                    <div style={{ marginTop: 30 }}>
                                        {
                                            schemaState.map((el, index) => {
                                                const { correctOptionCount, selected } = el;
                                                return <MutlipleChoiceTypeRow correctOptionCount={correctOptionCount} selected={selected} MultipleRowIndex={index} objectiveRowIndex={objectiveRowIndex} schemaState={schemaState} setSchemaState={setSchemaState} onFillObjective={onFillObjective}/>
                                            })
                                        }
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                        <div style={{ maxWidth: '12em' }}>
                                            <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>-ve mark</div>
                                            <Input
                                                onKeyDown={handleKeyDown}
                                                type="number"
                                                style={{ borderColor: "#FF414D", backgroundColor: "#FFE4D9", marginTop: 5 }}
                                                placeholder='Enter marks'
                                                onChange={(e) => { onFillObjective(objectiveRowIndex, "negativeMarks", parseInt(e.target.value)) }}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </>


                    :
                    <>
                        <div style={{ width: '20em', marginLeft: 50 }}>
                            <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>+ve mark</div>
                            <Input
                                onKeyDown={handleKeyDown}
                                type="number"
                                style={{ borderColor: "#28DF99", backgroundColor: "#E9FCF5", marginTop: 7 }}
                                placeholder='Enter marks'
                                onChange={(e) => { onFillObjective(objectiveRowIndex, "positiveMarks", parseFloat(e.target.value)) }}
                            />
                        </div>
                        <div style={{ width: '20em', marginLeft: 50 }}>
                            <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>-ve mark</div>
                            <Input
                                onKeyDown={handleKeyDown}
                                type="number"
                                style={{ borderColor: "#FF414D", backgroundColor: "#FFE4D9", marginTop: 7 }}
                                placeholder='Enter marks'
                                onChange={(e) => { onFillObjective(objectiveRowIndex, "negativeMarks", parseFloat(e.target.value)) }}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

function MutlipleChoiceTypeRow({ correctOptionCount, selected, objectiveRowIndex, MultipleRowIndex, schemaState, setSchemaState, onFillObjective }) {
    const [posMarks,setPosMarks] = useState(null)

    const onFillMultipleRow = (mainIndex, index, key, value) => {
        const schema = [...schemaState];
        if (schema[index]) {
            schema[index][`${key}`] = value
        } else {
            schema.push({
                [`${key}`]: value
            })
        }
        setSchemaState(schema);
        onFillObjective(mainIndex, "schema", schema)
    }
    return (
        <>
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
                    <div style={{ maxWidth: '12em', marginLeft: 50 }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", whiteSpace: 'nowrap' }}>Number of Correct Options</div>
                        <Input
                            type="number"
                            style={{ marginTop: 7 }}
                            placeholder='Enter marks'
                            value={correctOptionCount}
                            readOnly
                        />
                    </div>
                    <div style={{ maxWidth: '12em', marginLeft: 50 }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", whiteSpace: 'nowrap' }}>Number of Selected</div>
                        <Input
                            type="number"
                            style={{ marginTop: 7 }}
                            placeholder='Enter marks'
                            value={selected}
                            readOnly
                        />
                    </div>
                    <div style={{ maxWidth: '12em', marginLeft: 50 }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", whiteSpace: 'nowrap' }}>+ve mark</div>
                        <Input
                            onKeyDown={handleKeyDown}
                            type="number"
                            style={{ borderColor: "#28DF99", backgroundColor: "#E9FCF5", marginTop: 7 }}
                            placeholder='Enter marks'
                            // value={posMarks}
                            value={schemaState[MultipleRowIndex].positiveMarks}
                            onChange={(e) => {
                                setPosMarks(e.target.value)
                                onFillMultipleRow(objectiveRowIndex, MultipleRowIndex, "positiveMarks", parseInt(e.target.value))
                            }} />
                           
                    </div>
                </div>
            </div>
        </>
    )
}


const SubjectiveTypeRow = ({subjectiveTypeContainer, index }) => {

    function onFillSubjective(index, key, val) {
        if (subjectiveTypeContainer[index]) {
            //Update
            subjectiveTypeContainer[index][`${key}`] = val;
        } else {
            //Create
            subjectiveTypeContainer.push({
                index,
                [`${key}`]: val,
            });
        }
        return subjectiveTypeContainer;
    }

    return (
        <>
            <div style={{ display: "flex", marginBottom: 30 }}>
                <div style={{ width: '20em' }}>
                    <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Question type</div>
                    <Input placeholder='Subjective type questions' disabled style={{ marginTop: 5 }} />
                </div>
                <div style={{ width: '20em', marginLeft: 50 }}>
                    <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>+ve mark</div>
                    <Input 
                        style={{ borderColor: "#28DF99", backgroundColor: "#E9FCF5", marginTop: 5 }}
                        onKeyDown={handleKeyDown}
                        disabled={true}
                        type="number"
                        placeholder='Enter marks'
                        onChange={(e)=>{
                            onFillSubjective(index, "positiveMarks", parseFloat(e.target.value)); 
                            onFillSubjective(index, "type", "Subjective")
                        }
                    }                        
                    />
                </div>
            </div>
        </>
    )
}


function CreateMarkingScheme({ 
    isCreateMarkingSchemeVisible, setIsCreateMarkingSchemeVisible, storeMarkingSchemeTitle, 
    storeMarkingSchemeDesc, objectiveTypeContainer, subjectiveTypeContainer, 
    postCreateMarkingScheme, togglePageLoader, getMarkingSchemeData, storeMarkingSchemeObjective, 
    storeMarkingSchemeSubjective, isFromCreateNewButton, fetchMarkingSchemeData, mainIndex, 
    onFillSubjectiveSection, isFromSectionMarkingScheme, onFillSection, childIndex, storeMarkedSchemeCardId,
    markingSchemeTitle, searchedData, fromTestSeries,fetchMarkingSchemeDataTS}) {

    const [addCount, setAddCount] = useState(1);
    const [addSubjectiveCount, setAddSubjectiveCount] = useState(1);
    const [saveLoader,setSaveLoader] = useState(false);


    const handleOk = () => {
        setIsCreateMarkingSchemeVisible(false);
    };
    const handleCancel = () => {
        setIsCreateMarkingSchemeVisible(false);
        storeMarkingSchemeTitle(null);
        storeMarkingSchemeDesc(null);
        storeMarkingSchemeSubjective([]);
        storeMarkingSchemeObjective([]);
    };

    useEffect(() => {
        storeMarkingSchemeObjective([]);
        storeMarkingSchemeSubjective([]);
    }, [])
    

    return (
        <Modal
            centered
            className="modals-round-corner"
            visible={isCreateMarkingSchemeVisible}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={null}
            width={1000}
        >
            <div style={{ fontSize: "22px", color: "#191919", fontWeight: "bold", marginTop: "25px", display: "flex", justifyContent: "center", }}>
                Create Marking Scheme
            </div>
            <div
                style={{
                    overflowY: "auto",
                    padding: "10px",
                    height: "450px",
                    marginBottom: "15px",
                }}>
                <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold" }}>Title</div>
                <Input placeholder='Enter title' style={{ marginTop: 5 }} onChange={(e) => { storeMarkingSchemeTitle(e.target.value) }} />
                <div style={{ display: "flex", marginTop: 20, justifyContent: "space-between" }}>
                    <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold" }}>Description</div>
                    <div style={{ color: "#636363", fontSize: 14, fontWeight: "normal" }}>Optional</div>
                </div>
                <Input placeholder='Enter description' style={{ marginTop: 5 }} onChange={(e) => { storeMarkingSchemeDesc(e.target.value) }} />
                <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold", marginTop: 20 }}>Objective question type</div>
                <div style={{ marginTop: 20 }}>
                    {Array(addCount).fill().map((item, index) => {
                        return (
                            <ObjectiveTypeRow
                                objectiveTypeContainer={objectiveTypeContainer}
                                objectiveRowIndex={index}
                            />
                        )
                    })
                    }
                    {addCount < 10 ?
                        (
                            <div style={{ display: "flex", justifyContent: "center", marginTop: -15 }}>
                                <Button style={{
                                    color: "#1089FF",
                                    fontWeight: "bold",
                                    justifyContent: "center",
                                    display: "flex",
                                    cursor: "pointer",
                                    border: "none"
                                }}
                                    onClick={() => {
                                        setAddCount(addCount + 1);
                                    }} >+ ADD MORE</Button>
                            </div>
                        )
                        : <></>}
                </div>
                <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold", marginTop: 20 }}>Subjective question type</div>
                <div style={{ marginTop: 20 }}>
                    {Array(addSubjectiveCount)?.fill()?.map((item, index) =>
                        <SubjectiveTypeRow
                            subjectiveTypeContainer={subjectiveTypeContainer}
                            index={index}
                        />
                    )}
                </div>
            </div>
            <div style={{ display: "flex", marginBottom: "30px", justifyContent: "center" }}>
                <Button style={{
                    borderRadius: "19px",
                    width: "9vw",
                    height: "40px",
                    fontWeight: "500",
                    border: "1px solid #594099 ",
                    color: "#594099"
                }}
                    onClick={() => { handleCancel() }}>CANCEL</Button>
                    {console.log("AA-markingSchemeTitle", typeof markingSchemeTitle,markingSchemeTitle != '')}
                <Button
                    disabled={(markingSchemeTitle !=  null && markingSchemeTitle != '') ? false : true}
                    loading={saveLoader}
                    style={{
                        borderRadius: "19px",
                        width: "9vw",
                        height: "40px",
                        fontWeight: "500",
                        border: "1px solid #E6E6E6 ",
                        backgroundColor: "#594099",
                        color: "#FFFFFF",
                        marginLeft: "30px",
                        opacity : ((markingSchemeTitle != null && markingSchemeTitle != '' ) ? 1 : 0.5)
                    }}
                    onClick={() => {
                        storeMarkedSchemeCardId(null)
                        setSaveLoader(true);
                        {
                            isFromCreateNewButton ?
                                postCreateMarkingScheme(
                                    () => {
                                        setSaveLoader(false);
                                    },
                                    (response) => {
                                        setIsCreateMarkingSchemeVisible(false);
                                        if(fromTestSeries) {
                                            fetchMarkingSchemeDataTS()
                                            onFillSubjectiveSection(response?.id, response?.title)
                                        }
                                        else {
                                            fetchMarkingSchemeData();
                                            onFillSubjectiveSection(mainIndex, "markingSchemeId", {id:response?.id,title:response?.title})
                                        }
                                        // onFillSubjectiveSection(mainIndex, "markingSchemeId", response?.id)
                                    }
                                )
                                :
                                isFromSectionMarkingScheme ?
                                    postCreateMarkingScheme(
                                        () => {
                                            setSaveLoader(false);
                                        },
                                        (response) => {
                                            setIsCreateMarkingSchemeVisible(false);
                                            fetchMarkingSchemeData();
                                            onFillSection(mainIndex, childIndex, "markingSchemeId", {id:response?.id,title:response?.title})
                                            // onFillSection(mainIndex, childIndex, "markingSchemeId", response?.id)
                                        }
                                    )
                                    :
                                    postCreateMarkingScheme(
                                        () => {
                                            setSaveLoader(false);
                                        },
                                        () => {
                                            setIsCreateMarkingSchemeVisible(false)
                                            togglePageLoader(true);
                                            getMarkingSchemeData(searchedData, () => togglePageLoader(false))
                                        })
                        }
                    }}
                >CREATE</Button>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const {markingSchemeTitle, markingSchemeDescription, objectiveTypeContainer, subjectiveTypeContainer, isFromCreateNewButton, isFromSectionMarkingScheme, searchedData} = state.testAdmin;
    return{
        markingSchemeTitle, markingSchemeDescription, objectiveTypeContainer, subjectiveTypeContainer, isFromCreateNewButton, isFromSectionMarkingScheme, searchedData
    }
}

const mapDispatchToProps = (dispatch) => ({
    storeMarkingSchemeTitle:(val) => dispatch(storeMarkingSchemeTitle(val)),
    storeMarkingSchemeDesc:(val) => dispatch(storeMarkingSchemeDesc(val)),
    postCreateMarkingScheme:(callBack,successCallBack) => dispatch(postCreateMarkingScheme(callBack,successCallBack)),
    fetchMarkingSchemeDataTS:() => dispatch(fetchMarkingSchemeDataTS()),
    storeMarkingSchemeObjective:(val) => dispatch(storeMarkingSchemeObjective(val)),
    storeMarkingSchemeSubjective:(val) => dispatch(storeMarkingSchemeSubjective(val)),
    fetchMarkingSchemeData:() => dispatch (fetchMarkingSchemeData()),
    storeMarkedSchemeCardId:(val) => dispatch(storeMarkedSchemeCardId(val))


})

export default connect(mapStateToProps,mapDispatchToProps)(CreateMarkingScheme)

// export default CreateMarkingScheme