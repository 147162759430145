import React, { useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import './careStyles.css';
import UserProfileCards from './userProfileCards';
import CareNavbar from './careNavbar';
import ChatScreen from './functionalGiftedWebChat';
import { connect } from 'react-redux';
import { IMG_URL } from '../../env.json';
import moment from "moment";
import {
  getConversationOpenedClosedUsersFunction,
  setCurrentPageNo,
  setSpecificStudentUseId,
  setRole,
  setBatch,
  setName,
  setDate,
  setMonthOrWeek,
  setAulasCareChat
} from 'actions/adminCare';


function CareAdminChatPage({ openCloseConversationListWithChat, setAulasCareChat, setMonthOrWeek, setCurrentPageNo, curPage, batch, role, setBatch, setName, setRole, setDate, date, name, getConversationOpenedClosedUsersFunction, specificStudentId, setSpecificStudentUseId }) {
  const inputFile = useRef(null)
  const history = useHistory();

  const handleViewChatsOnClick = (userId) => {
    setCurrentPageNo(1);
    setSpecificStudentUseId(userId)
    togglePageLoader(true)
    getConversationOpenedClosedUsersFunction(curPage, batch, role, date, name, userId, () => togglePageLoader(false))
  }


  const [pageLoader, togglePageLoader] = useState(false)

  const onChangeFile = (e) => {

    e.preventDefault();
    var file = e.target.files[0];

  }

  function goBack() {
    history.goBack()
    setName('');
    setDate(moment().format('YYYY-MM-DD'));
    setRole(null);
    setBatch(null);
    setMonthOrWeek('week')
    // setMonthOrWeek('month')
    setAulasCareChat(false)
  }

  return (

    <div className="" style={{ height: "100%", width: "103%", margin: "-20px", marginRight: "-20px", padding: "-20px" }}>


      <input type='file' id='file' ref={inputFile} onChange={(e) => { onChangeFile(e) }} style={{ display: 'none' }} />

      <div style={{ display: "flex", height: "15%", flexDirection: "row", justifyContent: "space-between", borderBottom: "#E6E6E6 solid 1px", padding: "10px" }}>
        <PageHeader
          title={<div className="text-md" style={{ fontFamily: "roboto", fontWeight: "bold" }}> Conversation</div>}
          onBack={() => goBack()}
          backIcon={<ArrowLeftOutlined />}
        />
        <CareNavbar togglePageLoader={togglePageLoader} chatNameSearch={true} chat={true} />
      </div>

      <div style={{ display: "flex", flexDirection: "row", height: "85%" }}>

        <div style={{ width: "40%", overflowY: "scroll", overflowX: "hidden", paddingRight: "1px" }}>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <div style={{ width: "40%", height: "1px", backgroundColor: "#E6E6E6" }}></div>


            <p style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "roboto", fontWeight: "bold", marginTop: "15px", margin: "10px" }}>OPEN ({openCloseConversationListWithChat?.onlyOpen?.length ? openCloseConversationListWithChat?.onlyOpen?.length : <span>0</span>})</p>

            <div style={{ width: "40%", height: "1px", backgroundColor: "#E6E6E6" }}></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", marginBottom: "0PX" }}>

            {openCloseConversationListWithChat?.onlyOpen?.length ? openCloseConversationListWithChat?.onlyOpen?.map((item) =>

              < div className="userCardForChat " key={item.id} onClick={() => handleViewChatsOnClick(item.userId)} style={{ borderBottom: "#E6E6E6 solid 1px", backgroundColor: item.userId == specificStudentId ? "#F0F0F0" : 'transparent' }}>
                <UserProfileCards imgUrl={`${IMG_URL}/${item.user_image}`} userName={item.user_name} role={item.user_role} message={item.message} createdAt={item.createdAt} type={item.type} chat={true} selectedId={item.userId} unreadCount={item.unreadMessage} />

              </div>

            ) : null}
          </div>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <div style={{ width: "40%", height: "1px", backgroundColor: "#E6E6E6" }}></div>


            <p style={{ color: "#AEAEAE", fontSize: "14px", fontFamily: "roboto", fontWeight: "bold", marginTop: "15px", width: "20%", margin: "10px" }}>CLOSED ({openCloseConversationListWithChat?.onlyClose?.length ? openCloseConversationListWithChat?.onlyClose?.length : <span>0</span>})</p>

            <div style={{ width: "40%", height: "1px", backgroundColor: "#E6E6E6" }}></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>

            {openCloseConversationListWithChat?.onlyClose?.length ? openCloseConversationListWithChat?.onlyClose?.map((item) =>
              < div className="userCardForChat" key={item.id} onClick={() => handleViewChatsOnClick(item.userId)} style={{ borderBottom: "#E6E6E6 solid 1px", backgroundColor: item.userId == specificStudentId ? "#F0F0F0" : 'transparent' }}>
                <UserProfileCards imgUrl={`${IMG_URL}/${item.user_image}`} userName={item.user_name} role={item.user_role} message={item.message} createdAt={item.chat_createdAt} studentChat='false' type={item.type} chat={true} unreadCount={item.unreadMessage} />

              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "70%", borderLeft: "#E6E6E6 solid 1px" }}>
          <div>

          </div>
          <div style={{ width: "100%", height: "100%" }}>

            <ChatScreen widthForCard={true} pageLoader={pageLoader} togglePageLoader={togglePageLoader} admin={true} isAulasChat={false} />
          </div>

        </div>
      </div>


    </div>)
}

// export default CareAdminChatPage;

const mapStateToProps = state => {
  const {
    chats,
    currentPage,
    batch,
    role,
    date,
    name,
    specificStudentId,
    openCloseConversationListWithChat,
  } = state.adminCare;
  const {
    user,
  } = state.session;

  return {
    chats,
    user,
    batch,
    role,
    date,
    name,
    specificStudentId,
    currentPage,
    openCloseConversationListWithChat,
  };
};

const mapDispatchToProps = dispatch => ({
  getConversationOpenedClosedUsersFunction: (curPage, batch, role, date, name, specificStudentId, callback) => dispatch(getConversationOpenedClosedUsersFunction(curPage, batch, role, date, name, specificStudentId, callback)),
  setSpecificStudentUseId: (userId) => dispatch(setSpecificStudentUseId(userId)),
  setCurrentPageNo: (m) => dispatch(setCurrentPageNo(m)),
  setName: (m) => dispatch(setName(m)),
  setDate: (m) => dispatch(setDate(m)),
  setRole: (m) => dispatch(setRole(m)),
  setBatch: (m) => dispatch(setBatch(m)),
  setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  setAulasCareChat: (m) => dispatch(setAulasCareChat(m))


});

export default connect(mapStateToProps, mapDispatchToProps)(CareAdminChatPage);