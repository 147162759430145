import { getMarkingSchemeCardDetails, postCreateMarkingScheme, storeMarkingSchemeDesc, storeMarkingSchemeObjective, storeMarkingSchemeSubjective, storeMarkingSchemeTitle, fromCreateNewButton, setFromSectionMarkingScheme, getMarkingSchemeCardDetailsTS } from 'actions/testAdmin';
import { Button, Checkbox, Input, Modal, Select } from 'antd'
import PageDataLoader from 'components/pageDataLoader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ConfirmDeleteModal from './confirmDeleteModal';

const handleKeyDown = (e) => {
    if(e.key === '+' || e.key === '-'){
        e.preventDefault();
    }
};

const ObjectiveTypeRow = ({ objectiveTypeContainer, objectiveRowIndex, isTrue, setIsTrue, isFromCreateNewButton, isPreDefined, isEditClicked, FromMarkingScheme}) => {
    const [visibleMCQ, setVisibleMCQ] = useState(false);
    const [visibleCheck, setVisibleCheck] = useState(null);
    const [addMultipleCount, setAddMultilpleCount] = useState(1);
    const [maxOptions, setMaxOptions] = useState(null)
    const [schemaState, setSchemaState] = useState([]);
    const [reload,setReload] = useState(false);
    const [store, setstore] = useState(0)

    useEffect(()=>{
    },[reload])

    useEffect(() => {
        if (objectiveTypeContainer[objectiveRowIndex]?.partialMarking == true) {
          setVisibleMCQ(true);
          setMaxOptions(objectiveTypeContainer?.[objectiveRowIndex]?.partialMarkingSchema?.noOfOptions);
          if (isTrue) {
            setstore(objectiveTypeContainer?.[objectiveRowIndex]?.partialMarkingSchema?.noOfOptions)
            setSchemaState(objectiveTypeContainer[objectiveRowIndex]?.partialMarkingSchema?.schema)
          }
          setIsTrue(false);
        }
      }, [objectiveRowIndex, JSON.stringify(objectiveTypeContainer)])

      useEffect(() => {
        setSchemaState(objectiveTypeContainer[objectiveRowIndex]?.partialMarkingSchema?.schema)
      }, []);

    const handleChange = (value) => {
        if (!(value == "MSQ" || value == "CMSQ")) {
            setVisibleMCQ(false);
          }
        setVisibleCheck(value);
        setMaxOptions(null)
    }

    const checkboxChange = (e) => {
        if (e.target.checked == true) {
            setVisibleMCQ(true)
        } else {
            setVisibleMCQ(false);
            setMaxOptions(null)
        }
    };

    const optionOnChange = (value) => {
        setMaxOptions(value)
        const conditions = [];
        if (value) {
            for (let correctOptionCount = parseInt(value); correctOptionCount > 0; correctOptionCount--) {
                for (let selected = correctOptionCount; selected > 0; selected--) {
                    conditions.push({
                        correctOptionCount,
                        selected
                    })
                }
            }
        }
        setSchemaState(conditions)
    }

    const onFillObjective = (index, key, value) => {
        if(key =='negativeMarks' || key =='positiveMarks'){
            if(isNaN(value)){
                value = null;
            }
          }
        if (objectiveTypeContainer[index]) {
            objectiveTypeContainer[index][`${key}`] = value
        } else {
            objectiveTypeContainer?.push(
                {
                    index,
                    [`${key}`]: value
                }
            )
        }
        if (key === "type") {
            if (!(objectiveTypeContainer[index]?.type === "MSQ" || objectiveTypeContainer[index]?.type === "MSQ")) {
              objectiveTypeContainer[index].partialMarking = false;
              objectiveTypeContainer[index].partialMarkingSchema = {};
            }
          }
        if (key === 'noOfOptions' || key === 'schema') {
            let { partialMarkingSchema } = objectiveTypeContainer[index]
            if (!partialMarkingSchema) objectiveTypeContainer[index].partialMarkingSchema = {};
            objectiveTypeContainer[index].partialMarkingSchema[`${key}`] = value;
            delete objectiveTypeContainer[index][`${key}`]
        }
        setReload(!reload)
        return objectiveTypeContainer;
    }
    return (
        <>
            <div style={{ display: "flex",marginBottom: 30 }}>
                <div style={{ width: '20em' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Question type</div>
                        {(visibleCheck === "MSQ" || visibleCheck == "CMSQ" || objectiveTypeContainer?.[objectiveRowIndex]?.type == "MSQ" || objectiveTypeContainer?.[objectiveRowIndex]?.type == "CMSQ") ?
                            (<div style={{ marginLeft: 5 }}>
                                <Checkbox 
                                    disabled={FromMarkingScheme?false:!isEditClicked}
                                    checked={visibleMCQ}
                                    onChange={(e) => {isPreDefined ? null : checkboxChange(e); onFillObjective(objectiveRowIndex, "partialMarking", e.target.checked) }}
                                    >Partial marking awarded?</Checkbox>
                            </div>)
                            :
                            null
                        }
                    </div>
                    <Select
                        disabled={FromMarkingScheme?false:!isEditClicked}
                        style={{ width: '100%', marginTop: 5 }}
                        onChange={(value) => {
                            if(!isPreDefined){
                                handleChange(value); onFillObjective(objectiveRowIndex, "type", value)
                            }}}
                        value={objectiveTypeContainer?.[objectiveRowIndex]?.type}
                        placeholder="Select Question Type">
                        <Option value="MCQ">Multiple choice - One correct answer</Option>
                        <Option value="MSQ">Multiple choice - Multiple correct answer</Option>
                        <Option value="Numeric">Numeric type questions</Option>
                        <Option value="CMCQ">Comprehensive - One correct answer</Option>
                        <Option value="CMSQ">Comprehensive - Multiple correct answer</Option>
                        <Option value="CNum">Comprehensive - Numeric type</Option>
                        <Option value="CTF">Comprehensive - True/False</Option>
                        <Option value="T/F">True/False</Option>
                    </Select>
                </div>
                {visibleMCQ ?
                    <>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ marginLeft: 45 }}>
                                <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Maximum of options</div>
                                <Select
                                    disabled={FromMarkingScheme?false:!isEditClicked}
                                    placeholder="Select"
                                    style={{ width: '19.5em', marginTop: 7 }}
                                    value={maxOptions == null ? null : objectiveTypeContainer?.[objectiveRowIndex]?.partialMarkingSchema?.noOfOptions}
                                    onChange={(value) => {
                                        if(!isPreDefined){
                                            optionOnChange(value); onFillObjective(objectiveRowIndex, "noOfOptions", value)
                                        }}}
                                    >
                                    {/* <Option value="1">1</Option> */}
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                </Select>
                            </div>
                            {maxOptions ?
                                <div>
                                    <div style={{ marginTop: 30 }}>
                                        {
                                            schemaState.map((el, index) => {
                                                const { correctOptionCount, selected } = el;
                                                return <MutlipleChoiceTypeRow 
                                                correctOptionCount={correctOptionCount} 
                                                selected={selected} 
                                                MultipleRowIndex={index} 
                                                objectiveRowIndex={objectiveRowIndex} 
                                                schemaState={schemaState} 
                                                setSchemaState={setSchemaState} 
                                                onFillObjective={onFillObjective}
                                                objectiveTypeContainer={objectiveTypeContainer}
                                                maxOptions={maxOptions}
                                                store={store}
                                                isEditClicked={isEditClicked}
                                                FromMarkingScheme={FromMarkingScheme}
                                                isPreDefined={isPreDefined}
                                                />
                                            })
                                        }
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                        <div style={{ maxWidth: '12em' }}>
                                            <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>-ve mark</div>
                                            <Input
                                                onKeyDown={handleKeyDown}
                                                type="number"
                                                disabled={FromMarkingScheme?false:!isEditClicked}
                                                style={{ borderColor: "#FF414D", backgroundColor: "#FFE4D9", marginTop: 5 }}
                                                placeholder='Enter marks'
                                                readOnly={isPreDefined}
                                                value={objectiveTypeContainer?.[objectiveRowIndex]?.negativeMarks}
                                                onChange={(e) => {onFillObjective(objectiveRowIndex, "negativeMarks", parseInt(e.target.value)) }}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div style={{ width: '20em', marginLeft: 50 }}>
                            <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>+ve mark</div>
                            <Input
                                onKeyDown={handleKeyDown}
                                disabled={FromMarkingScheme?false:!isEditClicked}
                                type="number"
                                style={{ borderColor: "#28DF99", backgroundColor: "#E9FCF5", marginTop: 7 }}
                                placeholder='Enter marks'
                                readOnly={isPreDefined}
                                onChange={(e) => {onFillObjective(objectiveRowIndex, "positiveMarks", parseFloat(e.target.value)) }}
                                value={objectiveTypeContainer?.[objectiveRowIndex]?.positiveMarks}
                            />
                        </div>
                        <div style={{ width: '20em', marginLeft: 50 }}>
                            <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>-ve mark</div>
                            <Input
                                onKeyDown={handleKeyDown}
                                disabled={FromMarkingScheme?false:!isEditClicked}
                                type="number"
                                style={{ borderColor: "#FF414D", backgroundColor: "#FFE4D9", marginTop: 7 }}
                                placeholder='Enter marks'
                                readOnly={isPreDefined}
                                onChange={(e) => {onFillObjective(objectiveRowIndex, "negativeMarks", parseFloat(e.target.value)) }}
                                value={objectiveTypeContainer?.[objectiveRowIndex]?.negativeMarks}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

function MutlipleChoiceTypeRow({ correctOptionCount, selected, objectiveRowIndex, MultipleRowIndex, schemaState, setSchemaState, onFillObjective, objectiveTypeContainer, maxOptions, store, isEditClicked, FromMarkingScheme, isPreDefined }) {
    const [reload, setReload] = useState(true)

    useEffect(() => {
    }, [reload])

    const schema = [...schemaState];

    const onFillMultipleRow = (mainIndex, index, key, value) => {
        if (schema[index]) {
            schema[index][`${key}`] = value
        } else {
            schema.push({
                [`${key}`]: value
            })
        }
        setSchemaState(schema);
        onFillObjective(mainIndex, "schema", schema);
        setReload(!reload)
    }

    return (
        <>
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
                    <div style={{ maxWidth: '12em', marginLeft: 50 }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", whiteSpace: 'nowrap' }}>Number of Correct Options</div>
                        <Input
                            disabled={FromMarkingScheme?false:!isEditClicked}
                            type="number"
                            style={{ marginTop: 7 }}
                            placeholder='Enter marks'
                            value={correctOptionCount}
                            readOnly
                        />
                    </div>
                    <div style={{ maxWidth: '12em', marginLeft: 50 }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", whiteSpace: 'nowrap' }}>Number of Selected</div>
                        <Input
                            disabled={FromMarkingScheme?false:!isEditClicked}
                            type="number"
                            style={{ marginTop: 7 }}
                            placeholder='Enter marks'
                            value={selected}
                            readOnly
                        />
                    </div>
                    <div style={{ maxWidth: '12em', marginLeft: 50 }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", whiteSpace: 'nowrap' }}>+ve mark</div>
                        <Input
                            onKeyDown={handleKeyDown}
                            disabled={FromMarkingScheme?false:!isEditClicked}
                            type="number"
                            style={{ borderColor: "#28DF99", backgroundColor: "#E9FCF5", marginTop: 7 }}
                            placeholder='Enter marks'
                            readOnly={isPreDefined}
                            value={store === maxOptions ? objectiveTypeContainer?.[objectiveRowIndex]?.partialMarkingSchema?.schema?.[MultipleRowIndex]?.positiveMarks : schemaState[MultipleRowIndex].positiveMarks}
                            // value={objectiveTypeContainer?.[objectiveRowIndex]?.partialMarkingSchema?.schema?.[MultipleRowIndex]?.positiveMarks}
                            onChange={(e) => {onFillMultipleRow(objectiveRowIndex, MultipleRowIndex, "positiveMarks", parseFloat(e.target.value))}} />              
                    </div>
                </div>
            </div>
        </>
    )
}

const SubjectiveTypeRow = ({subjectiveTypeContainer, index, isEditClicked, FromMarkingScheme, isPreDefined }) => {
  const [reload,setReload] = useState(false);

  useEffect(()=>{
},[reload])

    function onFillSubjective(index, key, val) {
        if (subjectiveTypeContainer[index]) {
            //Update
            subjectiveTypeContainer[index][`${key}`] = val;
        } else {
            //Create
            subjectiveTypeContainer.push({
                index,
                [`${key}`]: val,
            });
        }
        setReload(!reload);
        return subjectiveTypeContainer;
    }
    return (
        <>
            <div style={{ display: "flex", marginBottom: 30 }}>
                <div style={{ width: '20em' }}>
                    <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Question type</div>
                    <Input placeholder='Subjective type questions' disabled style={{ marginTop: 5 }} />
                </div>
                <div style={{ width: '20em', marginLeft: 50 }}>
                    <div style={{ fontSize: "12px", color: "#636363", fontWeight: "bold" }}>+ve mark</div>
                    <Input 
                        onKeyDown={handleKeyDown}
                        style={{ borderColor: "#28DF99", backgroundColor: "#E9FCF5", marginTop: 5 }}
                        disabled={FromMarkingScheme?false:!isEditClicked}
                        type="number"
                        placeholder='Enter marks'
                        readOnly={isPreDefined}
                        onChange={(e) => {onFillSubjective(index, "positiveMarks", parseFloat(e.target.value)); onFillSubjective(index, "type", "Subjective") }}
                        value={subjectiveTypeContainer?.[index]?.positiveMarks}
                    />
                </div>
            </div>
        </>
    )
}

const ButtonDiv = ({
    setIsDeleteModalVisible, setIsEditModalVisisble, postCreateMarkingScheme, togglePageLoader,
    getMarkingSchemeData, isDeleteModalVisible, deleteMarkingScheme, handleCancel, searchedData,
    isFromCreateNewButton, isFromSectionMarkingScheme, fromCreateNewButton, setFromSectionMarkingScheme,
    setEditClicked, isEditClicked, mainIndex, onFillSubjectiveSection, onFillSection, childIndex, FromMarkingScheme, isPreDefined }) => {
    const [saveLoader, setSaveLoader] = useState(false);
    return (
        <>
            {FromMarkingScheme && !isPreDefined ?
                <>
                    <div style={{ display: "flex", padding: 10, justifyContent: "space-between", margin: "30px 0px 30px 0px" }}>
                        <Button style={{
                            borderRadius: "19px",
                            width: "9vw",
                            height: "40px",
                            fontWeight: "500",
                            border: "1px solid #636363 ",
                            color: "#636363"
                        }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsDeleteModalVisible(true);
                                setIsEditModalVisisble(false);
                            }}
                        >DELETE</Button>
                        <ConfirmDeleteModal
                            isDeleteModalVisible={isDeleteModalVisible}
                            setIsDeleteModalVisible={setIsDeleteModalVisible}
                            setIsEditModalVisisble={setIsEditModalVisisble}
                            deleteMarkingScheme={deleteMarkingScheme}
                            togglePageLoader={togglePageLoader}
                            getMarkingSchemeData={getMarkingSchemeData}
                        />

                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <Button style={{
                                borderRadius: "19px",
                                width: "9vw",
                                height: "40px",
                                fontWeight: "500",
                                border: "1px solid #636363 ",
                                color: "#636363"
                            }}
                                onClick={() => { setIsEditModalVisisble(false) }}>CANCEL</Button>
                            <Button
                                loading={saveLoader}
                                style={{
                                    borderRadius: "19px",
                                    width: "9vw",
                                    height: "40px",
                                    fontWeight: "500",
                                    border: "1px solid #E6E6E6 ",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",
                                    marginLeft: "30px",
                                }}
                                onClick={() => {
                                    setSaveLoader(true);
                                    postCreateMarkingScheme(
                                        () => {
                                            setSaveLoader(false);
                                        },
                                        () => {
                                            setIsEditModalVisisble(false)
                                            togglePageLoader(true);
                                            getMarkingSchemeData(searchedData, () => togglePageLoader(false))
                                        })
                                }}
                            >SAVE</Button>
                        </div>
                    </div>
                </>
                :
                <>
                    {FromMarkingScheme && isPreDefined ?
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{
                                borderRadius: "19px",
                                width: "9vw",
                                height: "40px",
                                fontWeight: "500",
                                border: "1px solid #636363 ",
                                color: "#636363"
                            }}
                                onClick={() => {
                                    setIsEditModalVisisble(false);
                                    setFromSectionMarkingScheme(false);
                                    fromCreateNewButton(false);
                                }}>CANCEL</Button>
                        </div>
                        :
                        null}
                    {(isFromCreateNewButton || isFromSectionMarkingScheme) && !isEditClicked ?
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{
                                borderRadius: "19px",
                                width: "9vw",
                                height: "40px",
                                fontWeight: "500",
                                border: "1px solid #636363 ",
                                color: "#636363"
                            }} onClick={() => { setIsEditModalVisisble(false) }}>BACK</Button>
                            <Button
                                style={{
                                    borderRadius: "19px",
                                    width: "9vw",
                                    height: "40px",
                                    fontWeight: "500",
                                    border: "1px solid #E6E6E6 ",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",
                                    marginLeft: "30px",
                                }}
                                onClick={() => {
                                    // setFromSectionMarkingScheme(false);
                                    // fromCreateNewButton(false);
                                    setEditClicked(true)
                                }}
                            >EDIT</Button>
                        </div> :
                        null
                    }
                    {isEditClicked ?
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{
                                borderRadius: "19px",
                                width: "9vw",
                                height: "40px",
                                fontWeight: "500",
                                border: "1px solid #636363 ",
                                color: "#636363"
                            }}
                                onClick={() => {
                                    setIsEditModalVisisble(false);
                                    setFromSectionMarkingScheme(false);
                                    fromCreateNewButton(false);
                                }}>CANCEL</Button>
                            <Button
                                loading={saveLoader}
                                style={{
                                    borderRadius: "19px",
                                    width: "9vw",
                                    height: "40px",
                                    fontWeight: "500",
                                    border: "1px solid #E6E6E6 ",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",
                                    marginLeft: "30px",
                                }}
                                onClick={() => {
                                    setSaveLoader(true);
                                    {
                                        isFromCreateNewButton ?
                                            postCreateMarkingScheme(
                                                () => {
                                                    setSaveLoader(false);
                                                },
                                                (response) => {
                                                    setIsEditModalVisisble(false);
                                                    setFromSectionMarkingScheme(false);
                                                    fromCreateNewButton(false);
                                                    onFillSubjectiveSection(mainIndex, "markingSchemeId", { id: response?.id, title: response?.title });
                                                    // onFillSubjectiveSection(mainIndex, "markingSchemeId", response?.id);
                                                }
                                            ) :
                                            isFromSectionMarkingScheme ?
                                                postCreateMarkingScheme(
                                                    () => {
                                                        setSaveLoader(false);
                                                    },
                                                    (response) => {
                                                        setIsEditModalVisisble(false);
                                                        setFromSectionMarkingScheme(false);
                                                        fromCreateNewButton(false);
                                                        onFillSection(mainIndex, childIndex, "markingSchemeId", { id: response?.id, title: response?.title })
                                                        // onFillSection(mainIndex, childIndex, "markingSchemeId", response?.id)

                                                    }
                                                )
                                                :
                                                null
                                    }
                                }}
                            >SAVE</Button>
                        </div>
                        :
                        null
                    }
                </>
            }
        </>
    )
}

function EditMarkingSchemeModal({
    isEditModalVisible, 
    setIsEditModalVisisble, 
    markingSchemeTitle, 
    storeMarkingSchemeTitle,
    markingSchemeDescription,
    storeMarkingSchemeDesc,
    objectiveTypeContainer,
    getMarkingSchemeCardDetails,
    subjectiveTypeContainer,
    getMarkingSchemeData,
    postCreateMarkingScheme,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    deleteMarkingScheme,
    storeMarkingSchemeObjective,
    storeMarkingSchemeSubjective,
    searchedData,
    isFromCreateNewButton,
    isFromSectionMarkingScheme,
    fromCreateNewButton,
    setFromSectionMarkingScheme,
    mainIndex, 
    onFillSubjectiveSection, 
    onFillSection, 
    childIndex,
    FromMarkingScheme,
    isPreDefined,
    markedSchemeCardDetails,
    fromTestSeries,
    TSdata,
    getMarkingSchemeCardDetailsTS
    }) {
    const [addCount, setAddCount] = useState(0);
    const [addSubjectiveCount, setAddSubjectiveCount] = useState(subjectiveTypeContainer?.length ? subjectiveTypeContainer?.length : 1);
    const [reload,setReload] =useState(false);
    const [subjective,setSubjective] = useState(subjectiveTypeContainer);
    const [objective,setObjective] = useState(objectiveTypeContainer);
    const [pageLoader, togglePageLoader] = useState(false);
    const [isTrue, setIsTrue] = useState(false);
    const [isEditClicked,setEditClicked] = useState(false)
    const modalLoader = true;

    useEffect(() => {
        setIsTrue(true)
        storeMarkingSchemeObjective([])
        togglePageLoader(true)
        if(fromTestSeries) {
            getMarkingSchemeCardDetailsTS(
                () => {
                    setTimeout(()=>{
                        setReload(!reload)
                        togglePageLoader(false)
                    },1000)
                },
                TSdata
            )
        }
        else 
            getMarkingSchemeCardDetails(
            () => {
            setTimeout(()=>{
                setReload(!reload)
                togglePageLoader(false)
            },1000)
            },
            )
        return () => {
            setSubjective([]);
            setObjective([]);
        }
      }, [])
    useEffect(()=>{
    setAddCount(objectiveTypeContainer?.length ? objectiveTypeContainer?.length : 1);
    },[reload])
    useEffect(() => {
    setSubjective(subjectiveTypeContainer)
    setObjective(objectiveTypeContainer)
    }, [reload])

    const handleOk = () => {
        setIsEditModalVisisble(false);
    };

     const handleCancel = () => {
        setIsEditModalVisisble(false);
        storeMarkingSchemeTitle(null);
        storeMarkingSchemeDesc(null);
        storeMarkingSchemeSubjective([]);
        storeMarkingSchemeObjective([]);
    };
  return (
    <Modal
            centered
            className="modals-round-corner"
            visible={isEditModalVisible}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={null}
            width={1000}
        >
            <div style={{ fontSize: "22px", color: "#191919", fontWeight: "bold", marginTop: "25px", display: "flex", justifyContent: "center", }}>
              {FromMarkingScheme && !isPreDefined ? "Edit Marking Scheme" : !isEditClicked || isPreDefined ? "Preview" : "Edit Marking Scheme" }  
            </div>
            <div
                style={{
                    overflowY: "auto",
                    padding: "10px",
                    height: "450px",
                    marginBottom: "15px",
                }}>
                <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold" }}>Title</div>
                <Input
                disabled={FromMarkingScheme?false:!isEditClicked}
                value={markingSchemeTitle}
                style={{ marginTop: 5 }}
                readOnly={isPreDefined}
                onChange={(e)=>{storeMarkingSchemeTitle(e.target.value)}}
                />
                <div style={{ display: "flex", marginTop: 20, justifyContent: "space-between" }}>
                    <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold" }}>Description</div>
                    <div style={{ color: "#636363", fontSize: 14, fontWeight: "normal" }}>Optional</div>
                </div>
                <Input
                disabled={FromMarkingScheme?false:!isEditClicked}
                placeholder='Enter description' 
                style={{ marginTop: 5 }}
                value={markingSchemeDescription}
                readOnly={isPreDefined}
                onChange={(e)=>{storeMarkingSchemeDesc(e.target.value)}}
                />
                <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold", marginTop: 20 }}>Objective question type</div>
                <div style={{ marginTop: 20 }}>
                    {Array(addCount).fill().map((item, index) => {
                        return (
                            <ObjectiveTypeRow
                                objectiveTypeContainer={objectiveTypeContainer}
                                objectiveRowIndex={index}
                                isTrue={isTrue}
                                setIsTrue={setIsTrue}
                                isFromCreateNewButton={isFromCreateNewButton}
                                isFromSectionMarkingScheme={isFromSectionMarkingScheme}
                                isEditClicked={isEditClicked}
                                FromMarkingScheme={FromMarkingScheme}
                                isPreDefined={isPreDefined}
                            />
                        )
                    })
                    }
                    {addCount < 10 && !isPreDefined  ?
                        (
                            <div style={{ display: "flex", justifyContent: "center", marginTop: -15 }}>
                                <Button
                                disabled={FromMarkingScheme?false:!isEditClicked} 
                                style={{
                                    color: "#1089FF",
                                    fontWeight: "bold",
                                    justifyContent: "center",
                                    display: "flex",
                                    cursor: FromMarkingScheme? "pointer": !isEditClicked ? "not-allowed": "pointer",
                                    border: "none"
                                }}
                                    onClick={() => {setAddCount(addCount + 1)}}>
                                        + ADD MORE</Button>
                            </div>
                        )
                        : <></>}
                </div>
                <div style={{ color: "#636363", fontSize: 14, fontWeight: "bold", marginTop: 20 }}>Subjective question type</div>
                <div style={{ marginTop: 20 }}>
                    {Array(addSubjectiveCount)?.fill()?.map((item, index) =>
                        <SubjectiveTypeRow
                            subjectiveTypeContainer={subjective}
                            index={index}
                            isFromCreateNewButton={isFromCreateNewButton}
                            isFromSectionMarkingScheme={isFromSectionMarkingScheme}
                            isEditClicked={isEditClicked}
                            FromMarkingScheme={FromMarkingScheme}
                            isPreDefined={isPreDefined}
                        />
                    )}
                </div>
            </div>
            <ButtonDiv
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            setIsEditModalVisisble={setIsEditModalVisisble}
            postCreateMarkingScheme={postCreateMarkingScheme}
            togglePageLoader={togglePageLoader}
            getMarkingSchemeData={getMarkingSchemeData}
            isDeleteModalVisible={isDeleteModalVisible}
            deleteMarkingScheme={deleteMarkingScheme}
            handleCancel={handleCancel}
            searchedData={searchedData}
            isFromCreateNewButton={isFromCreateNewButton}
            isFromSectionMarkingScheme={isFromSectionMarkingScheme}
            fromCreateNewButton={fromCreateNewButton}
            setFromSectionMarkingScheme={setFromSectionMarkingScheme}
            isEditClicked={isEditClicked}
            setEditClicked={setEditClicked}
            mainIndex={mainIndex} 
            onFillSubjectiveSection={onFillSubjectiveSection} 
            onFillSection={onFillSection} 
            childIndex={childIndex}
            FromMarkingScheme={FromMarkingScheme}
            isPreDefined={isPreDefined}
            />
          <div>
              <PageDataLoader visible={pageLoader} modalLoader={modalLoader}/>
          </div>           
        </Modal>
  )
}

const mapStateToProps = (state) => {
    const {markingSchemeTitle, markingSchemeDescription, objectiveTypeContainer, subjectiveTypeContainer, searchedData, isFromCreateNewButton, isFromSectionMarkingScheme} = state.testAdmin;
    return{
        markingSchemeTitle, markingSchemeDescription, objectiveTypeContainer, subjectiveTypeContainer, searchedData, isFromCreateNewButton, isFromSectionMarkingScheme
    }
}

const mapDispatchToProps = (dispatch) => ({
    storeMarkingSchemeTitle:(val) => dispatch(storeMarkingSchemeTitle(val)),
    storeMarkingSchemeDesc:(val) => dispatch(storeMarkingSchemeDesc(val)),
    storeMarkingSchemeObjective:(val) => dispatch(storeMarkingSchemeObjective(val)),
    storeMarkingSchemeSubjective:(val) => dispatch(storeMarkingSchemeSubjective(val)),
    getMarkingSchemeCardDetails:(callBack) => dispatch(getMarkingSchemeCardDetails(callBack)),
    getMarkingSchemeCardDetailsTS:(callBack, TSdata) => dispatch(getMarkingSchemeCardDetailsTS(callBack, TSdata)),
    postCreateMarkingScheme:(callBack,successCallBack) => dispatch(postCreateMarkingScheme(callBack,successCallBack)),
    fromCreateNewButton:(bool) => dispatch(fromCreateNewButton(bool)),
    setFromSectionMarkingScheme:(val) => dispatch(setFromSectionMarkingScheme(val)),
    markedSchemeCardDetails:(val) => dispatch(markedSchemeCardDetails(val))
})

export default connect(mapStateToProps,mapDispatchToProps)(EditMarkingSchemeModal)
// export default EditMarkingSchemeModal