import React, { useEffect, useState } from "react";
import "../style.css";
// import "antd/dist/antd.css";
import { Modal, Button, Input, DatePicker, Space, Select, Checkbox, } from "antd";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { createLeaves, storeCreateLeaveModalData, setCreateLoader, storeStartDate, } from "actions/attendanceNew";
import moment from "moment";
import { includes } from "lodash";

const CreateLeaveModal = ({ isCreateModalVisible, setIsCreateModalVisible, createLeaves, storeCreateLeaveModalData, loaders, createLoader, createLeaveObj, setAllLeaves }) => {
  const [leaveStartDate, setLeaveStartDate] = useState("");

  useEffect(() => {
    storeCreateLeaveModalData(null)
    storeCreateLeaveModalData('startDate', '')
    storeCreateLeaveModalData('endDate', '')
    storeCreateLeaveModalData('carryForward', false)
    storeCreateLeaveModalData('halfDayApplicable', false)
    storeCreateLeaveModalData('leaveName', '')
    storeCreateLeaveModalData('noOfDays', '')
    storeCreateLeaveModalData('backDays', false)
    storeCreateLeaveModalData('totalBackDays', '')
  }, [])



  function disabledEndDate(current) {
    return current && (current > moment().add(366, "days") || current < moment(createLeaveObj.startDate))
}

  const history = useHistory();
  const handleCancel = () => {
    setIsCreateModalVisible(false);
    storeCreateLeaveModalData('startDate', '')
    storeCreateLeaveModalData('endDate', '')
    storeCreateLeaveModalData('carryForward', false)
    storeCreateLeaveModalData('halfDayApplicable', false)
    storeCreateLeaveModalData('leaveName', '')
    storeCreateLeaveModalData('noOfDays', '')
    storeCreateLeaveModalData('backDays', false)
    storeCreateLeaveModalData('totalBackDays', '')
  };

  function onlyDigits(s) {
    for (let i = s.length - 1; i >= 0; i--) {
      const d = s.charCodeAt(i);
      if (d < 48 || d > 57) return false
    }
    return true
  }


  const handleCreate = (e) => {
    history.push("leave-graph");
    setIsCreateModalVisible();
    storeCreateLeaveModalData('startDate', '')
    storeCreateLeaveModalData('endDate', '')
    storeCreateLeaveModalData('carryForward', false)
    storeCreateLeaveModalData('halfDayApplicable', false)
    storeCreateLeaveModalData('leaveName', '')
    storeCreateLeaveModalData('noOfDays', '')
    storeCreateLeaveModalData('backDays', false)
    storeCreateLeaveModalData('totalBackDays', '')
  };

  let filteredArray = setAllLeaves?.leaveList.filter(myFilter)
  function myFilter(x) {
    let leaveListLeaveType = x.value;
    let checkLeaves = [];
    for (let i = 0; i < setAllLeaves?.leaves.length; i++) {
      checkLeaves.push(setAllLeaves?.leaves[i].leaveType);
    }
    return !checkLeaves.includes(leaveListLeaveType)
  }
  
  return (
    <>
      <div>
        <Modal
          centered
          className="modal-round-corner"
          visible={isCreateModalVisible}
          onCancel={handleCancel}
          onOk={handleCreate}
          footer={null}
        >
          <div
            style={{
              marginTop: "20px",
              fontSize: "22px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Create Leave Type
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Leave name
              </h6>
              <Select
                placeholder="Select leave type"
                style={{ width: "100%" }}
                onChange={(value) => storeCreateLeaveModalData('leaveName', value)}>
                {filteredArray?.map((item) => (
                  <Option value={item.value}>{item?.value}</Option>
                ))}
              </Select>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Number of days
              </h6>
              <Input
                style={{ width: "100%" }}
                placeholder="Enter number of days"
                value={createLeaveObj?.noOfDays == "NaN" || createLeaveObj?.noOfDays == NaN ? null : createLeaveObj?.noOfDays}
                onChange={(e) => {
                  e.target.value = e.target.value < 366 ? parseInt(e?.target?.value) : 366;
                  console.log("first", e.target.value)
                  storeCreateLeaveModalData('noOfDays', e.target.value)
                }}
              />
            </div>
          </div>

          {

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginTop: "20px" }}>
                <h6
                  style={{
                    fontSize: "14px",
                    color: "#636363",
                    fontWeight: "bold",
                  }}
                >
                  Start Date
                </h6>
                <Space>
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                    // disabledDate={disabledDate}
                    onChange={(e) => {
                      setLeaveStartDate(e.format('YYYY-MM-DD'))
                      storeCreateLeaveModalData('startDate', e.format('YYYY-MM-DD'));
                    }}
                    style={{
                      fontSize: "16px",
                      color: "#AEAEAE",
                      width: "220px",
                    }}
                    placeholder="Select start date"
                  />
                </Space>
              </div>
              <div style={{ marginTop: "20px" }}>
                <h6
                  style={{
                    fontSize: "14px",
                    color: "#636363",
                    fontWeight: "bold",
                  }}
                >
                  End Date
                </h6>
                <Space>
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                    disabledDate={disabledEndDate}
                    onChange={(e) => storeCreateLeaveModalData('endDate', e.format('YYYY-MM-DD'))}
                    style={{
                      fontSize: "16px",
                      color: "#AEAEAE",
                      width: "220px",
                    }}
                    placeholder="Select end date"
                  />
                </Space>
              </div>
            </div>

          }
          <div style={{
            fontSize: "14px",
            color: "#636363",
            fontWeight: "bold",
            marginTop: "20px"
          }}>Properties
          </div>
          <div style={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <Checkbox
                onChange={(e) => storeCreateLeaveModalData('carryForward', e.target.checked)} />
              <div style={{ marginLeft: "15px", color: "#191919", font: "normal normal normal 14px/21px Roboto" }}>
                This leave type can be carried forward in the next academic year.</div>
            </div>
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Checkbox
                onChange={(e) => storeCreateLeaveModalData('halfDayApplicable', e.target.checked)} />
              <div style={{ marginLeft: "15px", color: "#191919", font: "normal normal normal 14px/21px Roboto" }}>
                Half day leave can be applied for this leave type.</div>
            </div>
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Checkbox
                onChange={(e) => storeCreateLeaveModalData('backDays', e.target.checked)} checked={createLeaveObj?.backDays} />
              <div style={{ marginLeft: "15px", color: "#191919", font: "normal normal normal 14px/21px Roboto" }}>
                Allow staffs to apply for back date(s).</div>
            </div>
            {createLeaveObj?.backDays ? 
            <div className="m-l-30">
              <div style={{color: '#636363', fontSize: 12, fontWeight: 600, marginTop: 5}}>Number of back date(s).</div>
              <Input placeholder="Enter number of.." value={createLeaveObj?.totalBackDays} bordered allowClear style={{width: '50%'}} suffix={"day(s)"} onChange={(e)=>{
                if(onlyDigits(e.target.value)){
                  storeCreateLeaveModalData('totalBackDays', e.target.value)
                }
              }}/>
            </div>
            : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #636363 ",
                  color: "#636363",
                }}
                onClick={() => { handleCancel() }}
              >
                CANCEL
              </Button>
            </div>
            <div>
              <Button
                loading={createLoader}
                disabled={createLeaveObj?.noOfDays !== NaN && createLeaveObj?.noOfDays !== "NaN" && createLeaveObj?.leaveName!==''&&createLeaveObj?.startDate!==''&&createLeaveObj?.endDate!==''&&createLeaveObj?.noOfDays!==''&&(!createLeaveObj.backDays||(createLeaveObj.backDays&&createLeaveObj.totalBackDays!=undefined&&createLeaveObj?.totalBackDays?.trim()!=''&&parseInt(createLeaveObj?.totalBackDays?.trim())!=0)) ? false : true}
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#594099",
                  color: "#FFFFFF",
                  opacity: (createLeaveObj?.noOfDays !== NaN && createLeaveObj?.noOfDays !== "NaN" &&createLeaveObj?.leaveName!==''&&createLeaveObj?.startDate!==''&&createLeaveObj?.endDate!==''&&createLeaveObj?.noOfDays!==''&&(!createLeaveObj.backDays||(createLeaveObj.backDays&&createLeaveObj.totalBackDays!=undefined&&createLeaveObj?.totalBackDays?.trim()!=''&&parseInt(createLeaveObj?.totalBackDays?.trim())!=0))
                  ) ? 1 : 0.5
                }} onClick={() => {
                  createLeaves(
                    () => {
                      handleCreate()
                    }
                  )

                }}>
                CREATE
              </Button>
            </div>
          </div>
        </Modal>
        <pageDataLoader visible={loaders?.leaveGraphLoader} />
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  const { loaders, createLoader, createLeaveObj, setAllLeaves } = state.attendanceNew;
  return { createLoader, loaders, createLeaveObj, setAllLeaves };
};

const mapDispatchToProps = (dispatch) => ({
  createLeaves: (callBack) => dispatch(createLeaves(callBack)),
  storeCreateLeaveModalData: (key, val) => dispatch(storeCreateLeaveModalData(key, val)),
  setCreateLoader: (val) => dispatch(setCreateLoader(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLeaveModal);

