import { Col, DatePicker, Modal, Radio, Row } from 'antd';
import { Heading } from 'components/Typography';
import React, { useState } from 'react';
import {Button} from 'components/Button';
import moment from 'moment';

const rangeOptions = [
    {
        label: "Last week",
        value: "lastweek"
    },
    {
        label: "Last month",
        value: "lastmonth"
    },
    {
        label: "Last 3 months",
        value: "lastthreemonths"
    },
    {
        label: "Last 6 months",
        value: "lastsixmonths"
    },
    {
        label: "Year to date",
        value: "yeartodate"
    },
    {
        label: "Specific date range",
        value: "specificDateRange"
    }
]

const DateRangeModal = ({ visible, onClose, storeSelectedRange, selectedRangeContainer, storeStartDate, storeEndDate, startDateContainer, usersDownloadReport, setSpecificUserId, user, endDateContainer }) => {

    const [isDownloadBtnLoading, setIsDownloadBtnLoading] = useState(false);

    console.log({startDateContainer});
    console.log({endDateContainer});

    function disabledstartDate(current) {
        return current >= moment().endOf("day") || current >=(startDateContainer,'DD-MM-YYYY');
    };
    
    function disabledDate(current) {
       
        return current <= moment(startDateContainer).startOf('day')

    }   

    const successCallback = () => {
        setIsDownloadBtnLoading(false);
        storeStartDate('');
        storeEndDate('');
        storeSelectedRange(null)
        onClose();
    }

    const handleDownloadButton = () => {
        setIsDownloadBtnLoading(true);
        if(window.location.pathname === '/user-profile/others') setSpecificUserId(user?.id);
        usersDownloadReport(() => setIsDownloadBtnLoading(false), () => successCallback());
    };

    const setStartDate = (dateString) => {
        const formattedDate = moment(dateString).format('YYYY-MM-DD');
        storeStartDate(formattedDate);
    };

    const setEndDate = (dateString) => {
        const formattedDate = moment(dateString).format('YYYY-MM-DD');
        storeEndDate(formattedDate);
    };

    function onKeyDown(event) {
        event.preventDefault();
    }

    return (
        <Modal
            open={visible}
            footer={false}
            className="modal-round-corner"
            onCancel={onClose}
        >
            <div className='r-c-c m-t-20'>
                <Heading>Select range</Heading>
            </div>
            <div className='m-l-100'>
                {rangeOptions?.map((type, index) => 
                    <div style={{padding: "10px 0", fontSize: 16}}>
                        <Radio onClick={() => storeSelectedRange(type?.value)} checked={selectedRangeContainer === type?.value} value={type?.value}>{type?.label}</Radio>
                    </div>
                )}
                {selectedRangeContainer === "specificDateRange" ? 
                <Row className='p-20 m-t-20' style={{paddingTop: 0}}>
                    <Col span={12}>
                        <div>Start date</div>
                        <DatePicker onKeyDown={onKeyDown} allowClear={false} disabledDate={disabledstartDate} style={{width: "90%"}} onChange={(momentObj, dateString)=>{setStartDate(dateString)}}/>
                    </Col>
                    <Col span={12}>
                        <div>End date</div>
                        <DatePicker onKeyDown={onKeyDown} allowClear={false} disabled={startDateContainer === '' || startDateContainer === null ? true : false} disabledDate={disabledDate} style={{width: "90%"}} onChange={(momentObj, dateString)=>{setEndDate(dateString)}} />
                    </Col>
                </Row>  : null}
            </div>
            <div className='r-c-c m-t-40'>
                <Button disabled={ (selectedRangeContainer === 'specificDateRange' && (endDateContainer == null || endDateContainer == '')) || !selectedRangeContainer ? true : false} type="primary" onClick={handleDownloadButton} loading={isDownloadBtnLoading}>DOWNLOAD</Button>
            </div>

        </Modal>
    );
}

export default DateRangeModal;
