export const UPDATE_LOCAL_MEDIA = 'UPDATE_LOCAL_MEDIA';
export const UPDATE_REMOTE_MEDIA = 'UPDATE_REMOTE_MEDIA';
export const UPDATE_ACTIVE_SPEAKER = 'UPDATE_ACTIVE_SPEAKER';
export const UPDATE_BRIGOSHA_MEDIA = 'UPDATE_BRIGOSHA_MEDIA';
export const UPDATE_CONNECTION_STATE = 'UPDATE_CONNECTION_STATE';

export const updateLocalMedia = (media) => ({
  type: UPDATE_LOCAL_MEDIA,
  media
});

export const updateRemoteMedia = (media) => ({
  type: UPDATE_REMOTE_MEDIA,
  media
});

export const updateBrigoshaMedia = (media) => ({
  type: UPDATE_BRIGOSHA_MEDIA,
  media
});

export const updateActiveSpeaker = (name) => ({
  type: UPDATE_ACTIVE_SPEAKER,
  name
});

export const updateConnectionState = (value) => ({
  type: UPDATE_CONNECTION_STATE,
  value
});
