import { SET_SIDEMENU_DETAILS } from "../actions/sideMenu";
const initialState = {
  sideMenuData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEMENU_DETAILS: {
      let newSideMenuData = { ...state.sideMenuData };

      newSideMenuData[action.key] = action.res;

      return {
        ...state,

        sideMenuData: newSideMenuData,
      };
    }

    default:
      return state;
  }
};

export default reducer;
