import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/modal/style/index";
import { Color } from "services";
import ReactHtmlParser from "react-html-parser";
import { submitAssignment, setFinishMode } from "actions/assignment";
import { connect } from "react-redux";
import { AssignmentTopBar } from "components";
import "./styles.css";
import "design/layout.css";
import "design/assignment.css";
import TeX from "@matejmazur/react-katex";
import { IMG_URL } from "env.json";

const QBox = ({
  smallBox,
  statement,
  orgId,
  showImageModal,
  hideImageModal,
  type,
  currentIndex,
  selectedKey,
  positiveMarks,
  files,
  totalQuestions,
  qNo,
  resultsPublished,
  imageModal,
  correctAnswer,
  comment,
  isCorrect,
  assignmentStatus
}) => {
  const myComponent = node => {
    return (
      <div>
        <img
          alt=""
          src={node.attribs.src}
          style={{
            width: "100%",
            maxWidth: 100,
            height: 100,
            borderRadius: 10
          }}
        />
      </div>
    );
  };
  return (
    <div
      style={{
        height: smallBox ? "auto" : "calc(100vh - 122px)",
        margin: smallBox ? 10 : 0
      }}
      className="testSnippet NutinFont"
    >
      <Row
        style={{
          zIndex: !smallBox ? 1 : 0
        }}
        className="snippetTopBar orange-yellow p-10"
        justify="space-between"
      >
        <Col xs={8}>
          {positiveMarks == null ? (
            <div></div>
          ) : (
            <div>
              <span className="lowgrey">Marks: </span>
              {positiveMarks}
            </div>
          )}
        </Col>
        <Col xs={8} className="r-c-c">
          {qNo}/<span className="lowgrey">{totalQuestions}</span>
        </Col>
        <Col xs={8} className="r-c-fe" />
      </Row>
      <div className="mainQBox">
        <div className="flex-wrap p-l-10 p-r-10 p-t-10 color-black text-md bold-600">
          {ReactHtmlParser(statement, {
            transform: node => {
              if (node.type === "tag" && node.name === "img") {
                return myComponent(node);
              }
              if (
                node.type === "tag" &&
                node.name === "span" &&
                node.attribs["data-value"]
              ) {
                return <TeX math={`${node.attribs["data-value"]}`} />;
              }
            }
          })}
        </div>
        <div className="p-l-15 p-r-10 p-t-10 p-b-15 color-black text-sm">
          {type == "MCQ" && selectedKey && selectedKey.value != "" ? (
            <Row
              justify="space-between"
              style={{
                borderColor:
                  !resultsPublished || assignmentStatus != "Graded"
                    ? Color.dodgeBlue
                    : selectedKey.correct
                    ? Color.seaGreen
                    : Color.wrong,
                borderWidth: 2,
                display: "inline-flex"
              }}
              className="m-t-10 p-5 p-l-10 color-black text-xs optionsStyle"
            >
              <Col xs={20} style={{ minWidth: "30vw" }}>
                ({String.fromCharCode(65 + selectedKey.id)}){" "}
                {ReactHtmlParser(selectedKey.value, {
                  transform: node => {
                    if (node.type === "tag" && node.name === "img") {
                      return myComponent(node);
                    }
                    if (
                      node.type === "tag" &&
                      node.name === "span" &&
                      node.attribs["data-value"]
                    ) {
                      return <TeX math={`${node.attribs["data-value"]}`} />;
                    }
                  }
                })}
              </Col>
              <Col xs={3}>
                {assignmentStatus == "Graded" &&
                resultsPublished &&
                selectedKey.correct ? (
                  <i
                    className="icon-ic-submitted text-sm bold-bolder m-l-10 m-r-5"
                    style={{ color: Color.seaGreen }}
                  />
                ) : assignmentStatus == "Graded" &&
                  resultsPublished &&
                  !selectedKey.correct ? (
                  <i
                    className="icon-ic-close text-sm bold-bolder m-l-10 m-r-5"
                    style={{
                      color: Color.wrong,
                      backgroundColor: "rgb(255, 65, 77, 0.3)",
                      borderRadius: 10
                    }}
                  />
                ) : null}
              </Col>
              <Col xs={1} />
            </Row>
          ) : (
            <>
              {type == "MCQ" ? (
                <div
                  style={{
                    minWidth: "40vw",
                    borderColor:
                      !resultsPublished || assignmentStatus != "Graded"
                        ? Color.dodgeBlue
                        : Color.wrong,
                    borderWidth: 2,
                    display: "table"
                  }}
                  className="m-t-10 p-5 p-l-10 color-black text-xs optionsStyle"
                >
                  {" "}
                  NOT ANSWERED{" "}
                  {assignmentStatus == "Graded" && resultsPublished && (
                    <i
                      className="icon-ic-close text-sm bold-bolder m-l-10 m-r-5"
                      style={{
                        color: Color.wrong,
                        backgroundColor: "rgb(255, 65, 77, 0.3)",
                        borderRadius: 10
                      }}
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
          {type == "MCQ" &&
          !selectedKey.correct &&
          correctAnswer &&
          correctAnswer.value != "" ? (
            <Row
              justify="space-between"
              style={{
                display: "inline-flex",
                wordWrap: "break-word",
                borderColor: Color.seaGreen,
                borderWidth: 2
              }}
              className="m-t-10 p-5 p-l-10 color-black text-xs optionsStyle"
            >
              <Col
                xs={20}
                style={{
                  minWidth: "30vw"
                }}
              >
                ({String.fromCharCode(65 + correctAnswer.id)})
                {ReactHtmlParser(correctAnswer.value, {
                  transform: node => {
                    if (node.type === "tag" && node.name === "img") {
                      return myComponent(node);
                    }
                    if (
                      node.type === "tag" &&
                      node.name === "span" &&
                      node.attribs["data-value"]
                    ) {
                      return <TeX math={`${node.attribs["data-value"]}`} />;
                    }
                  }
                })}
              </Col>
              <Col xs={3}>
                <i
                  className="icon-ic-submitted text-sm bold-bolder m-r-10"
                  style={{ color: Color.seaGreen }}
                />
              </Col>
              <Col xs={1} />
            </Row>
          ) : null}
          {type === "Subjective" ? (
            <div style={{ width: "100%" }}>
              <Row
                justify="space-between"
                style={{
                  minWidth: "43vw",
                  display: "inline-flex",
                  wordWrap: "break-word",
                  borderColor:
                    !resultsPublished || assignmentStatus != "Graded"
                      ? Color.dodgeBlue
                      : selectedKey == correctAnswer
                      ? Color.seaGreen
                      : Color.wrong,
                  borderWidth: 2
                }}
                className="m-t-10 p-5 p-l-10 color-black text-xs optionsStyle"
              >
                <Col xs={20}>
                  {selectedKey === null || selectedKey === ""
                    ? "NOT ANSWERED"
                    : ReactHtmlParser(selectedKey, {
                        transform: node => {
                          if (node.type === "tag" && node.name === "img") {
                            return myComponent(node);
                          }
                          if (
                            node.type === "tag" &&
                            node.name === "span" &&
                            node.attribs["data-value"]
                          ) {
                            return (
                              <TeX math={`${node.attribs["data-value"]}`} />
                            );
                          }
                        }
                      })}
                </Col>
                <Col xs={4}>
                  {assignmentStatus == "Graded" &&
                  resultsPublished &&
                  selectedKey === correctAnswer ? (
                    <i
                      className="icon-ic-submitted text-sm bold-bolder"
                      style={{ color: Color.seaGreen }}
                    />
                  ) : assignmentStatus == "Graded" &&
                    resultsPublished &&
                    selectedKey !== correctAnswer ? (
                    <i
                      className="icon-ic-close text-sm bold-bolder"
                      style={{
                        color: Color.wrong,
                        backgroundColor: "rgb(255, 65, 77, 0.3)",
                        borderRadius: 10
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
            </div>
          ) : null}
          {type === "Subjective" &&
          assignmentStatus == "Graded" &&
          resultsPublished &&
          correctAnswer != selectedKey ? (
            <Row
              style={{
                borderColor: Color.seaGreen,
                borderWidth: 2,
                display: "inline-flex"
              }}
              className="m-t-10 p-5 p-l-10 color-black text-xs optionsStyle"
            >
              <Col
                xs={20}
                style={{
                  minWidth: "30vw"
                }}
              >
                {ReactHtmlParser(correctAnswer, {
                  transform: node => {
                    if (node.type === "tag" && node.name === "img") {
                      return myComponent(node);
                    }
                    if (
                      node.type === "tag" &&
                      node.name === "span" &&
                      node.attribs["data-value"]
                    ) {
                      return <TeX math={`${node.attribs["data-value"]}`} />;
                    }
                  }
                })}
              </Col>
              <Col xs={3}>
                <i
                  className="icon-ic-submitted text-sm bold-bolder m-r-10"
                  style={{ color: Color.seaGreen }}
                />
              </Col>
              <Col xs={1} />
            </Row>
          ) : null}
          {type === "Image" ? (
            <div
              className="p-10 inline-flex optionsStyle"
              style={{
                borderWidth: 2,
                borderColor:
                  !resultsPublished || assignmentStatus != "Graded"
                    ? Color.dodgeBlue
                    : isCorrect
                    ? Color.seaGreen
                    : Color.wrong
              }}
            >
              {files && files.length ? (
                files.map((data, index) => (
                  <div className="relative inline-flex p-r-10">
                    <div onClick={() => showImageModal(index + 1, files)}>
                      <img
                        src={`${IMG_URL}/${data}`}
                        alt="avatar"
                        className="studentUploadImg"
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    minWidth: "40vw"
                  }}
                  className="color-black text-xs"
                >
                  {" "}
                  NOT ANSWERED
                </div>
              )}
              {assignmentStatus == "Graded" &&
                resultsPublished &&
                isCorrect && (
                  <i
                    className="icon-ic-submitted text-sm bold-bolder m-l-10 m-r-5"
                    style={{ color: Color.seaGreen, height: 20 }}
                  />
                )}
              {assignmentStatus == "Graded" && resultsPublished && !isCorrect && (
                <i
                  className="icon-ic-close text-sm bold-bolder m-l-10 m-r-5"
                  style={{
                    color: Color.wrong,
                    backgroundColor: "rgb(255, 65, 77, 0.3)",
                    borderRadius: 10,
                    height: 20
                  }}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>

      {comment && (
        <div style={{ margin: 20 }}>
          <span>Teacher's Remark:</span>
          <div
            style={{
              padding: 20,
              marginTop: 5,
              backgroundColor: "#f8f8f8",
              borderRadius: 50
            }}
          >
            {comment}
          </div>
        </div>
      )}
    </div>
  );
};

class WebQuestions extends React.Component {
  state = {
    listMode: false,
    autoSave: false,
    imageModal: false,
    imageChange: false,
    imageIndex: 0,
    currentIndex: 0,
    submittedModal: false,
    files: []
  };
  componentDidMount() {}

  hideImageModal() {
    this.setState({
      imageModal: false,
      files: []
    });
  }

  render() {
    const {
      history,
      user,
      studentCurrentAssignment,
      studentCurrentSubmitData
    } = this.props;
    const totalQuestions = studentCurrentSubmitData.questions.length;
    const { listMode, imageModal, files } = this.state;
    return (
      <div className="greyBackTest" style={{ minHeight: "100%" }}>
        <AssignmentTopBar
          backPress={() => history.push("/web-assignment")}
          title={studentCurrentAssignment.sub}
          changeMode={() => this.setState({ listMode: !listMode })}
        />
        {studentCurrentSubmitData.questions &&
        studentCurrentSubmitData.questions.length
          ? studentCurrentSubmitData.questions.map((data, index) => (
              <QBox
                files={data.filename}
                showImageModal={(index, files) =>
                  this.setState({ imageModal: index, files })
                }
                smallBox={true}
                assignmentStatus={studentCurrentAssignment.assignmentStatus}
                resultsPublished={studentCurrentAssignment.resultsPublished}
                totalQuestions={totalQuestions}
                statement={data.question}
                type={data.type}
                selectedKey={data.answer || ""}
                correctAnswer={data.correctAnswer || ""}
                positiveMarks={
                  studentCurrentSubmitData.gradedType == "Ungraded"
                    ? null
                    : `${
                        data.marksObtained == null ? "-- " : data.marksObtained
                      }/${data.marks}`
                }
                qNo={index + 1}
                orgId={user.orgId}
                comment={data.comment}
                imageModal={imageModal}
                isCorrect={data.correct}
              />
            ))
          : null}
        {files.length > 0 && (
          <Modal
            className="autoSaveModal NutinFont"
            centered
            visible={imageModal}
            bodyStyle={{
              padding: 0,
              borderRadius: 15,
              textAlign: "center"
            }}
            closable={false}
            footer={null}
          >
            <div className="r-c-fe" onClick={() => this.hideImageModal()}>
              <i className="icon-ic-close text-lg" />
            </div>
            <img
              src={`${IMG_URL}/${files[imageModal - 1]}`}
              alt="avatar"
              style={{ maxWidth: "100%" }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    assignmentLoader,
    studentCurrentAssignment,
    submitModal,
    studentCurrentSubmitData
  } = state.assignment;
  const { user } = state.session;
  return {
    user,
    assignmentLoader,
    studentCurrentAssignment,
    submitModal,
    studentCurrentSubmitData
  };
};

const mapDispatchToProps = dispatch => ({
  submitAssignment: () => dispatch(submitAssignment()),
  setFinishMode: value => dispatch(setFinishMode(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WebQuestions);
