import {
  Title,
  Heading,
  SubHeading,
  SmallHeading,
  GraphHeading,
} from "components/Typography";
import { Divider } from "components/Utility";
import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { ArrowRightOutlined, SettingFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import "./styles.css";

//assets
import linkSvg from "Assets/fee/link.svg";
import checkSvg from "Assets/fee/check.svg";
import pendingSvg from "Assets/fee/pending.svg";
import overdueSvg from "Assets/fee/overdue.svg";
import linkBigSvg from "Assets/fee/link_big.svg";
import studentsSvg from "Assets/fee/students.svg";
import settlementSvg from "Assets/fee/double_check.svg";
import refundSvg from "Assets/fee/refund.svg";
import cancelSvg from "Assets/fee/cancel.svg";

import Card from "../../components/card";
import UserDetailModal from "./userDetailModal";
import GenerateLinkModal from "./generateLinkModal";
import Table from "./table";

import {
  toggleGenerateLinkModal,
  getFeeData,
  updateTableName,
} from "actions/fees";

import LoadingModal from "components/LoadingModal";
import KycPending from "pages/kyc/kycPending";
import ApprovalPending from "pages/kyc/approvalPending";
import SettingsModal from "./settingsModal";

const DetailButton = ({ onClick }) => (
  <Row
    align="middle"
    justify="space-between"
    className="m-t-10 cursor-pointer"
    onClick={onClick}
  >
    <SmallHeading bold color="#1089FF">
      View details
    </SmallHeading>
    <ArrowRightOutlined style={{ color: "#1089FF" }} />
  </Row>
);

const Fee = ({
  updateTableName,
  totalLinksRequested,
  totalLinksPending,
  totalLinksPaid,
  totalLinksSettled,
  totalLinksCancelled,
  totalLinksRefunded,
  totalLinksOverdue,
  toggleGenerateLinkModal,
  showUserDetailModal,
  history,
  getFeeData,
  showGenerateLinkModal,
  recentTransactions,
  feeLoader,
}) => {
  useEffect(() => {
    getFeeData(history);
    updateTableName("All transactions");
  }, []);

  const [showSettingsModal, updateSettingsModalVisible] = useState(false);

  return (
    <>
      <Row>
        <Col xs={24}>
          <div className="r-jsb">
            <Title bold>Fee Management</Title>
            <SettingFilled
              className="m-t-5 text-md m-r-10 cursor-pointer"
              onClick={() => updateSettingsModalVisible(true)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <GraphHeading>Overview</GraphHeading>
      </Row>
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          backgroundColor: "#f9f9f9",
          padding: 15,
          borderRadius: 7,
        }}
      >
        <Card>
          <Row justify="end">
            <img src={linkSvg} style={{ width: 30, maxWidth: "100%" }} alt="" />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksRequested.amount?.toLocaleString(
            "en-IN"
          )}`}</Heading>
          <SubHeading>{`${totalLinksRequested.count} requested`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Requests generated");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={checkSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksPaid.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksPaid.count} paid`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Paid");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={pendingSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksPending.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksPending.count} pending`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Payments Pending");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={overdueSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksOverdue.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksOverdue.count} overdue`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Overdue");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={settlementSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksSettled.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksSettled.count} settled`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Payments settled");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={refundSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksRefunded.amount?.toLocaleString("en-IN")}`}</Heading>
          <SubHeading>{`${totalLinksRefunded.count} refunded`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Payments refunded");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>

        <Card>
          <Row justify="end">
            <img
              src={cancelSvg}
              style={{ width: 30, maxWidth: "100%" }}
              alt=""
            />
          </Row>
          <Heading
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpaces: "nowrap",
            }}
            bold
          >{`₹ ${totalLinksCancelled.amount?.toLocaleString(
            "en-IN"
          )}`}</Heading>
          <SubHeading>{`${totalLinksCancelled.count} cancelled`}</SubHeading>
          <Divider className="m-t-10" />
          <DetailButton
            onClick={() => {
              updateTableName("Payments cancelled");
              history.push("/student-fees/transactions");
            }}
          />
        </Card>
      </div>
      <Row className="m-t-50">
        <Col xs={16}>
          <Row justify="space-between">
            <Col flex="auto">
              <Heading bold>Recent Transactions</Heading>
            </Col>
            <Col>
              <Row align="middle" className="cursor-pointer">
                <SubHeading
                  bold
                  color="#1089FF"
                  onClick={() => {
                    updateTableName("All transactions");
                    history.push("/student-fees/transactions");
                  }}
                >
                  View all
                </SubHeading>
                <ArrowRightOutlined
                  style={{ color: "#1089FF", marginLeft: 5 }}
                />
              </Row>
            </Col>
          </Row>
          <Row className="m-t-10">
            <Col flex="auto">
              <Table dataSource={recentTransactions} pagination={false} />
            </Col>
          </Row>
        </Col>
        <Col xs={8} className="m-t-30">
          <Card>
            <Row
              align="middle"
              className="p-15 cursor-pointer"
              onClick={() => toggleGenerateLinkModal(true)}
            >
              <Col flex="auto">
                <img
                  src={linkBigSvg}
                  style={{ width: 40, maxWidth: "100%" }}
                  alt=""
                />
                <SubHeading
                  bold
                  className="m-l-10"
                  style={{ display: "inline-block" }}
                >
                  Generate Requests
                </SubHeading>
              </Col>
              <ArrowRightOutlined style={{ color: "#1089FF" }} />
            </Row>
            <Divider />
            <Row
              align="middle"
              className="p-15 cursor-pointer"
              onClick={() => history.push("student-fees/all")}
            >
              <Col flex="auto">
                <img
                  src={studentsSvg}
                  style={{ width: 40, maxWidth: "100%" }}
                  alt=""
                />
                <>
                  <SubHeading
                    bold
                    className="m-l-10"
                    style={{ display: "inline-block" }}
                  >
                    View All Students
                  </SubHeading>
                </>
              </Col>
              <ArrowRightOutlined style={{ color: "#1089FF" }} />
            </Row>
          </Card>
        </Col>
      </Row>
      <UserDetailModal visible={showUserDetailModal} />
      <GenerateLinkModal visible={showGenerateLinkModal} />
      <KycPending />
      <ApprovalPending />
      <LoadingModal visible={feeLoader} />
      <SettingsModal
        visible={showSettingsModal}
        onCancel={() => updateSettingsModalVisible(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    showUserDetailModal,
    showGenerateLinkModal,
    totalLinksRequested,
    totalLinksPending,
    totalLinksPaid,
    totalLinksSettled,
    totalLinksRefunded,
    totalLinksOverdue,
    totalLinksCancelled,
    recentTransactions,
    feeLoader,
  } = state.fees;
  return {
    feeLoader,
    recentTransactions,
    totalLinksRequested,
    totalLinksSettled,
    totalLinksRefunded,
    totalLinksPending,
    totalLinksPaid,
    totalLinksOverdue,
    totalLinksCancelled,
    showGenerateLinkModal,
    showUserDetailModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeeData: (history) => dispatch(getFeeData(history)),
  updateTableName: (name) => dispatch(updateTableName(name)),
  toggleGenerateLinkModal: (value) => dispatch(toggleGenerateLinkModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fee);