import { deleteFromCart, deleteRemoveSaveForLater, getCartCount, setRenderPage } from 'actions/studentMarketPlace';
import { Modal } from 'antd'
import { Button } from 'components/Button';
import React from 'react'
import { connect } from 'react-redux';

function RemoveModal({ isRemoveModalVisible,setRenderPage,renderPageLoading,deleteFromCart,deleteRemoveSaveForLater, setRemoveModalVisible, removeId,cartRemoveParam, getCartCount, user }) {
    const handleRemoveOk = () => {
        if (removeId) {
            deleteRemoveSaveForLater(removeId, () => {
                setRemoveModalVisible(false);
                setRenderPage(!renderPageLoading);
            })
        } else {
            deleteFromCart(cartRemoveParam, () => {
                setRemoveModalVisible(false);
                getCartCount()
                setRenderPage(!renderPageLoading);
            })
        }
    }

    const handleRemove = () => {
        setRemoveModalVisible(false);
    }
    return (
        <div>
            <Modal bodyStyle={{ borderRadius: "8px",  }}
                centered
                className="modal-round-corner"
                open={isRemoveModalVisible}
                onCancel={handleRemove}
                onOk={handleRemoveOk}
                header={null}
                footer={null}
                closeIcon={null}
            >
                <div className='r-c-c' style={{ fontSize: "18px", fontWeight: 600 }}>Confirm</div>
                <div className='r-c-c m-t-10' style={{ color: "#636363", fontSize: 14 }}>Are you sure you want to remove this course?</div>
                <div className='r-c-sa p-30 m-t-10' style={{ color: "#636363" }}>
                    <div className='' style={{ color: "#FF414D", cursor: "pointer", fontWeight: 600, fontSize: 16 }} onClick={handleRemoveOk}>REMOVE</div>
                    <Button type="primary" onClick={handleRemove} style={{}}>CANCEL</Button>
                </div>
            </Modal>
        </div >
    )
}

const mapStateToProps = (state) => {
    const { renderPageLoading } = state.studentMarketPlace
    const { user } = state.session
    return { renderPageLoading, user }
};

const mapDispatchToProps = (dispatch) => ({
    deleteRemoveSaveForLater: (id, callback) => dispatch(deleteRemoveSaveForLater(id, callback)),
    deleteFromCart: (param, callback) => dispatch(deleteFromCart(param, callback)),
    setRenderPage: (val) => dispatch(setRenderPage(val)),
    getCartCount: () => dispatch(getCartCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveModal)
