import React from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import { submitModalFun } from "actions/mobileTest";
import { timerAlertModalFun, studentTestUpdateAns, postStudentTest } from "actions/mobileTest";
import { Button } from 'antd';
import HyperModal from "react-hyper-modal";
import { MdTimer } from "react-icons/md";
import "antd/lib/modal/style/index";

class TimerAlertModal extends React.Component{

  componentDidMount(){

  }

  render(){
    const {history,  studentTestDurationTimer, studentTestDurationInSec, timerAlertModalBool,
      studentTestDurationInSecTotal, studentTestStartTimeInSec } = this.props;

    if(this.props.studentTestDurationInSec === parseInt(20/100*studentTestStartTimeInSec)){
      this.props.timerAlertModalFun(true);
    }
    return(
      <Modal
        className="autoSaveModal NutinFont"
        centered
        visible={timerAlertModalBool}
        bodyStyle={{
          padding: 0,
          borderRadius: 15,
          textAlign: "center",
          margin:'-10px'
        }}
        closable={false}
        width={1000}
        style={{ maxWidth: "calc(100vw - 16px)" }}
        footer={null}
      >
        <div className='p-10 text-center'>

          <div className="text-sm m-t-20 color-black bold-700">Time remaining</div>
          <div className="r-c-c m-t-20">
            <div className="r-ac">
              <MdTimer className="text-md seaGreen"/>
              <div className='higrey text-md bold-600 m-l-5'>{studentTestDurationTimer}</div>
            </div>
          </div>
          <div className="higrey text-xmd m-b-20 m-t-20">
            The time is almost over. It’s time to hurry!
          </div>
          <Button onClick={() => this.props.timerAlertModalFun(false)}
            className='mobileTestGreenBtnWrap m-10'
          >
            CLOSE
          </Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { studentTestDurationTimer, studentTestDurationInSec, timerAlertModalBool, studentTestDurationInSecTotal,
  studentTestStartTimeInSec  } = state.mobileTest;
  return {
    studentTestDurationTimer, studentTestDurationInSec, timerAlertModalBool, studentTestDurationInSecTotal,
    studentTestStartTimeInSec
  };
};

const mapDispatchToProps = dispatch => ({
  timerAlertModalFun: (bool) => dispatch(timerAlertModalFun(bool)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  postStudentTest: (params, type, callback) => dispatch(postStudentTest(params, type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerAlertModal);
