import React from "react";
import Carousel from "antd/lib/carousel";
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import "antd/lib/carousel/style/index";
import "antd/lib/upload/style/index";
import "antd/lib/message/style/index";
import "antd/lib/modal/style/index";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/button/style/index";
import { Divider, Input, Progress } from "antd";
import { MdNotifications, MdTimer } from "react-icons/md";
import {
  LoadingOutlined,
  CloseOutlined,
  SyncOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { BsFillPauseFill, BsPlayFill } from "react-icons/bs";
import { FiImage, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaBookmark, FaRegBookmark, FaTrashAlt } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Api, Color, Utils } from "services";
import HyperModal from "react-hyper-modal";
import Lottie from "react-lottie";
import animationData from "Assets/lottie/doc.json";
import animationCheck from "Assets/lottie/check.json";
import moment from "moment";
import Compressor from "compressorjs";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  submitModalFun,
  reviewModalFun,
  leaveModalFun,
  timerOverModalFun,
  studentTestCollectQuesIndex,
  studentTestcollectMcq,
  studentTestcollectBookmark,
  postStudentTest,
  studentTestUpdateAns,
  studentTestcollectImage,
  deleteQuesImgModalFun,
  confromDeleteQuesImgModalFun,
  getStudentTestDurationTimer,
  getStudentTestDurationDecrement,
  imageZoomModalFun,
  getStudentTestDetail,
  getStudentTestCurrentTime,
  setImgsofReviewQuesiton,
} from "actions/mobileTest";
import { connect } from "react-redux";
import MathJax from "react-mathjax-preview";
import { DetailCard, TestTopBar, MobileButton } from "components";
import SubmitModal from "./submitModal";
import ReviewModal from "./reviewModal";
import LeaveModal from "./leaveModal";
import TimerOverModal from "./timerOverModal";
import TimerAlertModal from "./timerAlertModal";
import ImageZoomModal from "./imageZoomModal";
import TeX from "@matejmazur/react-katex";
import "./styles.css";
import "design/layout.css";
import "design/assignment.css";

class MobileTestQuestions extends React.Component {
  constructor() {
    super();
    this.myinterval = 0;
    this.state = {
      listMode: false,
      questionStats: {
        Skipped: 0,
        AttemptedAndBookmarked: 0,
        Attempted: 0,
        Bookmarked: 0,
        Unattempted: 0,
      },
      QnOimg: "",
      callSubmitOnce: false,
    };
  }

  componentDidMount() {
    this.props.studentTestUpdateAns();
    this.props.getStudentTestDurationTimer(
      Utils.secondsToHms(this.props.studentTestDurationInSec)
    );
    Utils.startDuation(this.props.studentTestDurationInSec, (res) => {
      this.props.getStudentTestDurationDecrement(res);
      this.props.getStudentTestDurationTimer(Utils.secondsToHms(res));
    });
    document.addEventListener("message", this.handleEvent);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.studentTestDurationInSec === 0 &&
      !this.state.callSubmitOnce
    ) {
      this.props.getStudentTestDurationDecrement(-1);
      this.hitActionButton(
        this.props.studentTestQuesIndex + 1,
        "submit",
        "timeup"
      );
    }
  }

  handleEvent = (reactNativeMessage) => {
    if (reactNativeMessage.data.rnMessage == "no") {
      this.props.getStudentTestCurrentTime(
        this.props.studentTestLiveId,
        (response) => {
          Utils.stopDuration();
          if (this.props.studentTestDurationInSec > 0) {
            this.props.getStudentTestDurationTimer(
              Utils.secondsToHms(this.props.studentTestDurationInSec)
            );
            Utils.startDuation(this.props.studentTestDurationInSec, (res) => {
              this.props.getStudentTestDurationDecrement(res);
              this.props.getStudentTestDurationTimer(Utils.secondsToHms(res));
            });
          } else {
            this.props.getStudentTestDurationTimer(Utils.secondsToHms(0));
            this.props.getStudentTestDurationDecrement(0);
          }
        }
      );
    }
  };

  componentWillUnmount() {
    document.removeEventListener("message", this.handleEvent);
  }

  beforeUpload(file, index) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
      return false;
    } else {
      const aa = URL.createObjectURL(file);
      if (isJpgOrPng) {
        const urlPath = URL.createObjectURL(file);
        new Compressor(file, {
          convertSize: 1000,
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            const blobFile = new File(
              [compressedResult],
              compressedResult.name,
              { lastModified: new Date().getTime() }
            );
            this.uploadAndSetImages(blobFile, urlPath, index);
          },
          error: (err) => {
            message.error(err.message);
          },
        });
      }
      return false;
    }
  }
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  uploadAndSetImages(imgObj, urlPath, index) {
    this.props.studentTestcollectImage(imgObj, urlPath);
    this.props.studentTestUpdateAns();
  }

  hitActionButton = (quesIndex, jump, timerOver) => {
    const {
      studentTestAnsObj,
      studentTestQuesArr,
      studentTestQuesIndex,
      testUploadedBase64Arr,
      studentTestDurationInSecTotal,
      testUploadedImgdeleteArr,
      studentTestDurationInSec,
      testUploadedImgArrNew,
    } = this.props;
    // if(quesIndex < 0 || (quesIndex >= studentTestQuesArr.length)) return;
    if (quesIndex < 0) return;
    this.props.studentTestUpdateAns();
    var params;
    if (studentTestQuesArr[studentTestQuesIndex].type === "Image") {
      let imgObjArr = [];
      testUploadedBase64Arr.map((key, index) => {
        testUploadedImgArrNew.map((key1, index1) => {
          if (key1.localImg === key.localImg) {
            if (key.apiImg === "") {
              imgObjArr.push(key);
            }
          }
        });
      });

      let duplicateObj = {};
      for (let i = 0, len = imgObjArr.length; i < len; i++) {
        duplicateObj[imgObjArr[i]["localImg"]] = imgObjArr[i];
      }
      imgObjArr = new Array();
      for (let key in duplicateObj) {
        imgObjArr.push(duplicateObj[key].localImgObj);
      }

      params = {
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration:
          jump === "submit"
            ? studentTestDurationInSecTotal - studentTestDurationInSec
            : studentTestAnsObj.duration,
        submitStatus: studentTestAnsObj.submitStatus,
        files: imgObjArr,
        deletedFiles: JSON.stringify(testUploadedImgdeleteArr),
        testStatus: jump === "submit" ? "Submitted" : "Draft",
      };
    } else {
      params = {
        answer: studentTestAnsObj.answer,
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration:
          jump === "submit"
            ? studentTestDurationInSecTotal - studentTestDurationInSec
            : studentTestAnsObj.duration,
        submitStatus: studentTestAnsObj.submitStatus,
        testStatus: jump === "submit" ? "Submitted" : "Draft",
      };
    }

    console.log("params+++++++++++", params);
    const type =
      studentTestQuesArr[studentTestQuesIndex].type === "Image"
        ? "image"
        : "mcq";
    if (jump === "submit") {
      if (timerOver === "timeup") {
        this.props.reviewModalFun(false);
        this.props.postStudentTest(params, type, (response) => {
          this.props.timerOverModalFun(true);
          this.setState({ callSubmitOnce: true });
        });
      } else {
        let { questionStats } = this.state;
        questionStats.Skipped = studentTestQuesArr.filter(
          (item) => item.submitStatus === "Unanswered"
        ).length;
        questionStats.AttemptedAndBookmarked = studentTestQuesArr.filter(
          (item) => item.submitStatus === "AttemptedAndBookmarked"
        ).length;
        questionStats.Attempted = studentTestQuesArr.filter(
          (item) => item.submitStatus === "Attempted"
        ).length;
        questionStats.Bookmarked = studentTestQuesArr.filter(
          (item) => item.submitStatus === "Bookmarked"
        ).length;
        questionStats.Unattempted = studentTestQuesArr.filter(
          (item) => item.submitStatus === "NotVisited"
        ).length;
        this.setState({ questionStats });
        this.props.reviewModalFun(true);
      }
    } else {
      this.props.postStudentTest(params, type, (response) => {
        this.props.studentTestCollectQuesIndex(quesIndex);
        if (jump === "grid") {
          this.setState({ listMode: !this.state.listMode });
        }
      });
    }
  };

  htmlParser = (text, alert) => {
    return ReactHtmlParser(text, {
      transform: (node, index) => {
        if (
          node.type === "tag" &&
          node.name === "span" &&
          node.attribs["data-value"]
        ) {
          return <TeX math={`${node.attribs["data-value"]}`} />;
        }
        if (node.type === "tag" && node.name === "img") {
          return (
            <div>
              <img
                onClick={
                  alert === "donotzoom"
                    ? () => {}
                    : () => {
                        this.props.imageZoomModalFun(true, false);
                        this.setState({ QnOimg: node.attribs.src });
                      }
                }
                src={node.attribs.src}
                style={{
                  maxHeight: "40vh",
                  maxWidth: "40vw",
                  backgroundColor: "#f8f8f6",
                }}
              />
            </div>
          );
        }
      },
    });
  };

  render() {
    const {
      history,
      studentTestDetialData,
      studentSubjectHeader,
      studentTestQuesArr,
      user,
      studentTestQuesIndex,
      studentTestAnsObj,
      testUploadedBase64Arr,
      studentTestPostQuesLoader,
      reviewModalBool,
      studentTestDurationInSec,
      studentTestDurationTimer,
      studentTestDetailLoader,
      studentTestCurrentTimeLoader,
      studentTestPostSubmitLoader,
      testProgress,
    } = this.props;
    const { listMode } = this.state;
    const quesType = studentTestQuesArr[studentTestQuesIndex].type;

    return (
      <div className="greyBackTest" style={{ height: "100%" }}>
        <TestTopBar
          backPress={() => {
            this.props.leaveModalFun(true);
          }}
          title={studentSubjectHeader.replace(/,/g, ", ")}
          isStarted
          changeMode={() => {
            this.setState({ listMode: !listMode });
            this.props.studentTestUpdateAns();
          }}
          listMode={listMode}
          percent={(testProgress / studentTestQuesArr.length) * 100}
          precentshow={true}
        />
        <div className="m-l-10 m-r-10 m-t-10 r-c-sb">
          <div>
            {listMode
              ? "All Questions"
              : quesType === "Subjective"
              ? "Numeric"
              : quesType === "Image"
              ? "Subjective"
              : quesType}
          </div>
          <div className="r-ac">
            <MdTimer className="text-md seaGreen" />
            {/*<div className='higrey m-l-5'>{studentTestQuesArr[studentTestQuesIndex].duration || '00:00:00'}</div>*/}
            <div className="higrey m-l-5">{studentTestDurationTimer}</div>
          </div>
        </div>

        {listMode ? (
          studentTestQuesArr.length ? (
            <div className="">
              {studentTestQuesArr.map((data, index) => (
                <div
                  className="test-borders m-10 p-10 m-b-20"
                  onClick={() => this.hitActionButton(index, "grid")}
                >
                  <div>
                    <div className="r-c-sb">
                      <div className="r-ac">
                        <div className="lowgrey text-xmd">Marks:</div>
                        <div className="seaGreen text-sm m-l-2">
                          +{data.positiveMarks}
                          {data.negativeMarks === null ||
                          data.negativeMarks === 0 ? null : (
                            <span>
                              <span className="m-l-5 m-r-5 lowgrey">|</span>
                              <span style={{ color: Color.red }}>
                                -{data.negativeMarks || ""}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="r-ac">
                        <div className="orange-yellow text-xmd">
                          {index + 1}
                        </div>
                        <div className="lowgrey text-xmd">
                          /{studentTestQuesArr.length}
                        </div>
                      </div>
                      <div className="r-ac">
                        {data.submitStatus === "Bookmarked" ||
                        data.submitStatus === "AttemptedAndBookmarked" ? (
                          <FaBookmark className="orange-yellow text-sm" />
                        ) : (
                          <FaRegBookmark className="orange-yellow text-sm" />
                        )}
                        <div className="lowgrey m-l-2 text-xmd">Bookmark</div>
                      </div>
                    </div>
                    <Divider style={{ margin: "15px 0px" }} />
                    <div className="text-md bold m-b-10 color-black">
                      {this.htmlParser(data.statement, "donotzoom")}
                    </div>

                    <div>
                      {data.submitStatus === "Unanswered" ||
                      data.submitStatus === "NotVisited" ||
                      data.submitStatus === "Bookmarked" ? (
                        <div></div>
                      ) : data.type === "MCQ" ? (
                        data.options.map((key, index) =>
                          key.correct ? (
                            <div
                              className="r-ac p-10"
                              style={{
                                background: "#EAFCF5",
                                borderRadius: "0px 0px 9px 9px",
                                margin: "-10px",
                              }}
                            >
                              <div
                                className="test-small-avtar r-c-c color-black"
                                style={{
                                  background: Color.seaGreen,
                                  color: "#fff",
                                  alignSelf: " flex-start",
                                }}
                              >
                                {key.id === 0
                                  ? "A"
                                  : key.id === 1
                                  ? "B"
                                  : key.id === 2
                                  ? "C"
                                  : "D"}
                              </div>
                              <div className="text-sm color-black m-l-10">
                                {this.htmlParser(key.value)}
                              </div>
                            </div>
                          ) : null
                        )
                      ) : data.type === "Subjective" ? (
                        data.studentAnswer !== null ? (
                          <div
                            className="r-ac p-10"
                            style={{
                              background: "#EAFCF5",
                              borderRadius: "0px 0px 9px 9px",
                              margin: "-10px",
                            }}
                          >
                            <div className="text-sm color-black m-l-10">
                              {data.studentAnswer}
                            </div>
                          </div>
                        ) : null
                      ) : data.studentFiles !== null ? (
                        <div
                          className="r-ac p-10 display-flex flex-wrap"
                          style={{
                            background: "#EAFCF5",
                            borderRadius: "0px 0px 9px 9px",
                            margin: "-10px",
                          }}
                        >
                          {data.studentFiles.map((key, index) => (
                            <img
                              alt=""
                              onClick={() => {
                                // this.setState({QnOimg: ''});
                                // this.props.setImgsofReviewQuesiton(data.studentFiles);
                                // this.props.imageZoomModalFun(true, index);
                              }}
                              src={
                                key.apiImg !== ""
                                  ? `https://s3.ap-south-1.amazonaws.com/prod.files.classroom.digital-0949/${key.apiImg}`
                                  : key.localImg
                              }
                              style={{
                                width: "90px",
                                height: "115px",
                                objectFit: "cover",
                                backgroundColor: "#f8f8f6",
                              }}
                              className="radius-10 m-r-10 m-b-10"
                            />
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
              <div className="r-c-c p-t-20 p-b-20 test-student-bottom-sticky">
                <Button
                  onClick={() =>
                    this.hitActionButton(studentTestQuesIndex + 1, "submit")
                  }
                  className="mobileTestGreenBtnWrap"
                >
                  SUBMIT <ArrowRightOutlined />
                </Button>
              </div>
            </div>
          ) : null
        ) : (
          <div className="test-borders m-10 p-10 test-ques-screen-wrap">
            <div>
              <div className="r-c-sb">
                <div className="r-ac">
                  <div className="lowgrey text-xmd">Marks: </div>
                  <div className="seaGreen text-sm m-l-2">
                    +{studentTestQuesArr[studentTestQuesIndex].positiveMarks}
                    {studentTestQuesArr[studentTestQuesIndex].negativeMarks ===
                      null ||
                    studentTestQuesArr[studentTestQuesIndex].negativeMarks ===
                      0 ? null : (
                      <span>
                        <span className="m-l-5 m-r-5 lowgrey">|</span>
                        <span style={{ color: Color.red }}>
                          -
                          {studentTestQuesArr[studentTestQuesIndex]
                            .negativeMarks || ""}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="r-ac">
                  <div className="orange-yellow text-xmd">
                    {studentTestQuesIndex + 1}
                  </div>
                  <div className="lowgrey text-xmd">
                    /{studentTestQuesArr.length}
                  </div>
                </div>
                <div
                  className="r-ac cursor-pointer"
                  onClick={() => {
                    this.props.studentTestcollectBookmark();
                    this.props.studentTestUpdateAns();
                  }}
                >
                  {studentTestQuesArr[studentTestQuesIndex].submitStatus ===
                    "Bookmarked" ||
                  studentTestQuesArr[studentTestQuesIndex].submitStatus ===
                    "AttemptedAndBookmarked" ? (
                    <FaBookmark className="orange-yellow text-sm" />
                  ) : (
                    <FaRegBookmark className="orange-yellow text-sm" />
                  )}

                  <div className="lowgrey m-l-2 text-xmd">Bookmark</div>
                </div>
              </div>
              <Divider style={{ margin: "15px 0px" }} />
              <div className="text-md bold m-b-10 color-black">
                {this.htmlParser(
                  studentTestQuesArr[studentTestQuesIndex].statement,
                  "zoom"
                )}
              </div>
            </div>
            {studentTestQuesArr[studentTestQuesIndex].type === "MCQ" ? (
              <div className="m-t-10">
                {studentTestQuesArr[studentTestQuesIndex].options.map(
                  (data, index) => (
                    <div
                      onClick={() => {
                        this.props.studentTestcollectMcq(data.id);
                        this.props.studentTestUpdateAns();
                      }}
                      className="r-ac p-t-10 p-b-10 cursor-pointer"
                      style={{
                        borderTop: "1px solid #EDEDED",
                        background: data.correct ? "#EAFCF5" : "#fff",
                      }}
                    >
                      <div
                        className="test-small-avtar r-c-c color-black"
                        style={{
                          background: data.correct ? Color.seaGreen : "#fff",
                          color: data.correct ? "#fff" : Color.black,
                          alignSelf: " flex-start",
                        }}
                      >
                        {index === 0
                          ? "A"
                          : index === 1
                          ? "B"
                          : index === 2
                          ? "C"
                          : "D"}
                      </div>
                      <div className="text-sm color-black m-l-10">
                        {this.htmlParser(data.value, "zoom")}
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : studentTestQuesArr[studentTestQuesIndex].type ===
              "Subjective" ? (
              <div className="">
                <div className="lowgrey text-sm m-b-5">Your answer:</div>
                <Input
                  defaultValue={studentTestAnsObj.answer}
                  style={{ width: "100%" }}
                  value={studentTestAnsObj.answer}
                  className="test-borders p-10 color-black"
                  onChange={(e) => {
                    studentTestAnsObj.answer = e.target.value;
                    // this.props.studentTestcollectSubjective(e.target.value);
                    this.props.studentTestUpdateAns();
                  }}
                />
              </div>
            ) : (
              <div>
                {testUploadedBase64Arr.length === 5 ? (
                  <div className="lowgrey text-sm m-b-5">Your answers:</div>
                ) : (
                  <Upload
                    name="statementImage"
                    disabled={testUploadedBase64Arr.length === 5 ? true : false}
                    listType="picture"
                    className="cursor-pointer"
                    showUploadList={false}
                    beforeUpload={(file) => {
                      this.beforeUpload(file);
                    }}
                  >
                    <div className="lowgrey text-sm m-b-5">Your answers:</div>
                    <div className="a2grey studentAssignmentUp">
                      <FiImage className="text-md" />
                      <AiOutlinePlusCircle className="text-xs smallPlus" />
                    </div>
                  </Upload>
                )}

                {testUploadedBase64Arr.length ? (
                  <div className="display-flex flex-wrap m-t-10">
                    {testUploadedBase64Arr.map((data, index) => (
                      <div className="relative" key={index}>
                        <img
                          onClick={() => {
                            this.setState({ QnOimg: "" });
                            this.props.imageZoomModalFun(true, index);
                          }}
                          src={
                            data.apiImg !== ""
                              ? `https://s3.ap-south-1.amazonaws.com/prod.files.classroom.digital-0949/${data.apiImg}`
                              : data.localImg
                          }
                          style={{
                            width: "90px",
                            height: "115px",
                            objectFit: "cover",
                            backgroundColor: "#f8f8f6",
                          }}
                          className="radius-10 m-r-10 m-b-10"
                        />
                        <div
                          onClick={() =>
                            this.props.deleteQuesImgModalFun(true, index)
                          }
                          className="text-md absolute"
                          style={{
                            backgroundColor: "#ec303038",
                            borderRadius: "10px 10px 0px 0px",
                            right: "10px",
                            bottom: "10px",
                            padding: "3px",
                          }}
                        >
                          <FaTrashAlt
                            className="text-md red"
                            style={{ fontSize: "16px" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
        {listMode ? null : (
          <div className="display-flex test-ques-footer">
            {studentTestQuesIndex === 0 ? null : (
              <div
                onClick={() =>
                  this.hitActionButton(studentTestQuesIndex - 1, "list")
                }
                className="r-c-c flex-1 cursor-pointer"
                style={{ height: "50px" }}
              >
                <FiChevronLeft className="lowgrey bold" />
                <div className="m-l-10 lowgrey bold">PREVIOUS</div>
              </div>
            )}
            {studentTestQuesIndex === 0 ? null : (
              <Divider type="vertical" style={{ height: "auto" }} />
            )}
            {studentTestQuesArr.length === studentTestQuesIndex + 1 ? (
              <div
                onClick={() =>
                  this.hitActionButton(studentTestQuesIndex + 1, "submit")
                }
                className="r-c-c flex-1 cursor-pointer"
                style={{ height: "50px" }}
              >
                <div className="seaGreen bold">SUBMIT</div>
                <FiChevronRight className="seaGreen bold m-l-10 " />
              </div>
            ) : (studentTestAnsObj.answer === null &&
                studentTestQuesArr[studentTestQuesIndex].type === "MCQ") ||
              (studentTestAnsObj.answer === null &&
                studentTestQuesArr[studentTestQuesIndex].type ===
                  "Subjective") ||
              (!testUploadedBase64Arr.length &&
                studentTestQuesArr[studentTestQuesIndex].type === "Image") ? (
              <div
                onClick={() =>
                  this.hitActionButton(studentTestQuesIndex + 1, "list")
                }
                className="r-c-c flex-1 cursor-pointer"
                style={{ height: "50px" }}
              >
                <div className="lowgrey bold">SKIP</div>
                <FiChevronRight className="lowgrey bold m-l-10 " />
              </div>
            ) : (
              <div
                onClick={() =>
                  this.hitActionButton(studentTestQuesIndex + 1, "list")
                }
                className="r-c-c flex-1 cursor-pointer"
                style={{ height: "50px" }}
              >
                <div className="lowgrey bold">NEXT</div>
                <FiChevronRight className="lowgrey bold m-l-10 " />
              </div>
            )}
          </div>
        )}
        <SubmitModal history={history} />
        <ReviewModal history={history} data={this.state.questionStats} />
        <LeaveModal history={history} />
        <TimerOverModal history={history} />
        <TimerAlertModal />
        <ImageZoomModal QnOimg={this.state.QnOimg} />

        <HyperModal
          isOpen={this.props.deleteQuesImgModalBool}
          unmountOnClose
          requestClose={() => this.props.deleteQuesImgModalFun(false, false)}
          position={{ alignItems: "flex-end", justifyContent: "center" }}
          renderCloseIcon={() => null}
        >
          <div className="full-width r-c-c p-t-10">
            <div className="topNotch inline-flex" />
          </div>
          <div className="p-10 text-center">
            <div className="text-sm m-t-20 color-black bold-700 m-b-20">
              Do you want to Delete Image?
            </div>
            <Button
              onClick={() => {
                this.props.confromDeleteQuesImgModalFun();
                this.props.deleteQuesImgModalFun(false, false);
                this.props.studentTestUpdateAns();
              }}
              className="mobileTestGreenBtnWrap m-10"
            >
              Delete
            </Button>
          </div>
        </HyperModal>
        <Modal
          visible={studentTestPostQuesLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img
              src={require("Assets/dots-gif.gif").default}
              style={{ maxWidth: "100%" }}
              width={150}
              alt=""
            />
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">Loading Question</div> */}
          </div>
        </Modal>
        <Modal
          visible={studentTestPostSubmitLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img
              src={require("Assets/dots-gif.gif").default}
              style={{ maxWidth: "100%" }}
              width={150}
              alt=""
            />
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">{studentTestDurationInSec === 0 ? 'Time Over. ' : ''} Saving test answers</div> */}
          </div>
        </Modal>
        <Modal
          visible={studentTestCurrentTimeLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img
              src={require("Assets/dots-gif.gif").default}
              style={{ maxWidth: "100%" }}
              width={150}
              alt=""
            />
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">Updating Tests</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    studentTestDetialData,
    studentSubjectHeader,
    studentTestQuesArr,
    studentTestQuesIndex,
    studentTestAnsObj,
    testUploadedBase64Arr,
    deleteQuesImgModalBool,
    studentTestPostQuesLoader,
    testUploadedImgdeleteArr,
    reviewModalBool,
    studentTestDurationInSec,
    studentTestDurationTimer,
    studentTestType,
    studentTestDurationInSecTotal,
    testUploadedImgArrNew,
    studentTestLiveId,
    studentTestDetailLoader,
    studentTestCurrentTimeLoader,
    studentTestPostSubmitLoader,
    testProgress,
  } = state.mobileTest;
  const { user } = state.session;
  return {
    user,
    studentTestDetialData,
    studentSubjectHeader,
    studentTestQuesArr,
    studentTestQuesIndex,
    studentTestAnsObj,
    testUploadedBase64Arr,
    deleteQuesImgModalBool,
    studentTestPostQuesLoader,
    testUploadedImgdeleteArr,
    reviewModalBool,
    studentTestDurationInSec,
    studentTestDurationTimer,
    studentTestType,
    studentTestDurationInSecTotal,
    testUploadedImgArrNew,
    studentTestLiveId,
    studentTestDetailLoader,
    studentTestCurrentTimeLoader,
    studentTestPostSubmitLoader,
    testProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitModalFun: (bool) => dispatch(submitModalFun(bool)),
  reviewModalFun: (bool) => dispatch(reviewModalFun(bool)),
  leaveModalFun: (bool) => dispatch(leaveModalFun(bool)),
  imageZoomModalFun: (bool, index) => dispatch(imageZoomModalFun(bool, index)),
  timerOverModalFun: (bool) => dispatch(timerOverModalFun(bool)),
  deleteQuesImgModalFun: (bool, index) =>
    dispatch(deleteQuesImgModalFun(bool, index)),
  studentTestCollectQuesIndex: (index) =>
    dispatch(studentTestCollectQuesIndex(index)),
  studentTestcollectMcq: (id) => dispatch(studentTestcollectMcq(id)),
  studentTestcollectBookmark: () => dispatch(studentTestcollectBookmark()),
  postStudentTest: (params, type, callback) =>
    dispatch(postStudentTest(params, type, callback)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  confromDeleteQuesImgModalFun: () => dispatch(confromDeleteQuesImgModalFun()),
  studentTestcollectImage: (imgObj, urlPath) =>
    dispatch(studentTestcollectImage(imgObj, urlPath)),
  getStudentTestDurationTimer: (time) =>
    dispatch(getStudentTestDurationTimer(time)),
  getStudentTestDurationDecrement: (time) =>
    dispatch(getStudentTestDurationDecrement(time)),
  getStudentTestDetail: (id, tabname, callback) =>
    dispatch(getStudentTestDetail(id, tabname, callback)),
  getStudentTestCurrentTime: (id, callback) =>
    dispatch(getStudentTestCurrentTime(id, callback)),
  setImgsofReviewQuesiton: (arr) => dispatch(setImgsofReviewQuesiton(arr)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileTestQuestions);
