import { Button, Input, Modal, Switch } from "antd";
import { postUnAssignStudent } from 'actions/courseManagement';
import React, { useState } from "react";
import "./styles.css";
import StartDate_Picker from "../courseModule/courseStartDate";
import {
  assignCourseToStudent,
  getStudentsDetailsById,
  setCourseDetails,
} from "actions/userManagement";
import { connect } from "react-redux";

function CourseDetailsModal({
  setShowCourseDetailsModal,
  isModalVisible,
  item,
  assignCourseToStudent,
  getStudentsDetailsById,
  postUnAssignStudent
}) {
  const [courseStartDate, setCourseStartDate] = useState(item?.startDate);
  const [deactive, setDeactive] = useState(!item?.isActive);
  const [confirm, setConfirm] = useState(false);
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [renderEndDate, setRenderEndDate] = useState(false);


  const unAssignParam={
    resourceType:item?.courseType,
    resourceId: item?.courseId,
    userIds: [item?.userId]
}
  
  

  return (
    <div>
      <Modal
        className="courseDetailsModalStyle"
        title="Basic Modal"
        visible={isModalVisible}
        closable={false}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        {confirm == false ? (
          <div>
            <div
              style={{
                textAlign: "center",
                fontFamily: "roboto",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Course details
            </div>
            <div style={{ marginTop: "20px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Course name
                {/* (Inclusive of GST/Taxes) */}
              </p>
              <Input value={item?.courseName} readOnly></Input>
            </div>

            {item?.courseType == 'course' ? 
            <div style={{ marginTop: "20px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Start date
              {/* (Inclusive of GST/Taxes) */}
            </p>
            <StartDate_Picker
              value={courseStartDate}
              setCourseStartDate={setCourseStartDate}
              setRenderEndDate={setRenderEndDate}
            />
          </div>
            : <></>}
            
            <div style={{ marginTop: "30px" }}>
              {item?.courseType == 'course' ? 
              <>
               {/* <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Action
         
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontFamily: "roboto" }}>
                  <div style={{ fontWeight: "600" }}>Deactivate</div>
                  <div style={{ color: "#636363" }}>
                    Pause an active course. Activate it again anytime.
                  </div>
                </div>
                <div>
                  <Switch
                    checked={deactive}
                    onChange={(e) => {
                      setDeactive(e);
                    }}
                  />
                </div>
              </div> */}
              </>
              : <></>}
             
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "50px",
                }}
              >
                <Button
                  onClick={() => {
                    setConfirm(true);
                  }}
                  style={{
                    fontWeight: "600",
                    color: "#FF414D",
                    border: "1px solid #FF414D",
                    fontSize: "13px",
                  }}
                >
                  DELETE
                </Button>
                <div>
                  <Button
                    onClick={() => {
                      setShowCourseDetailsModal(false);
                    }}
                    style={{
                      fontWeight: "650",
                      color: "#636363",
                      border: "1px solid #636363",
                      fontSize: "13px",
                    }}
                  >
                    CANCEL
                  </Button>
                  {item?.courseType == 'course' ?
                   <Button
                   loading={saveBtnLoader}
                   onClick={() => {
                     setSaveBtnLoader(true);
                     assignCourseToStudent(
                       () => {
                         setSaveBtnLoader(false);
                       },
                       () => {
                         getStudentsDetailsById();
                         setShowCourseDetailsModal(false);
                       },
                       true,
                       deactive,
                       courseStartDate
                     );
                   }}
                   style={{
                     fontWeight: "650",
                     color: "white",
                     //   border: "1px solid #FF414D",
                     fontSize: "13px",
                     backgroundColor: "#594099",
                   }}
                 >
                   SAVE
                 </Button>
                  :
                  <></>
                  }
                 
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                textAlign: "center",
                fontFamily: "roboto",
                marginTop: "20px",
              }}
            >
              <div style={{ fontWeight: "600", fontSize: "18px" }}>Confirm</div>
              <div style={{ color: "#636363" }}>
                Are you sure you want to delete?
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "-20px",
              }}
            >
              <Button
                onClick={() => {
                  setConfirm(false);
                }}
                style={{ fontWeight: "550", color: "#636363" }}
              >
                NO
              </Button>
              <Button
                loading={saveBtnLoader}
                onClick={() => {
                  setSaveBtnLoader(true);
                  // assignCourseToStudent(
                  //   () => {
                  //     setSaveBtnLoader(false);
                  //   },
                  //   () => {
                  //     getStudentsDetailsById();
                  //     setShowCourseDetailsModal(false);
                  //   },
                  //   true,
                  //   deactive,
                  //   courseStartDate,
                  //   true
                  // );
                  postUnAssignStudent(unAssignParam,() => {setSaveBtnLoader(false)},()=>{
                    getStudentsDetailsById(); setShowCourseDetailsModal(false);
                    setShowCourseDetailsModal(false);
                  },
                  )
                }}
                style={{
                  fontWeight: "550",
                  color: "white",
                  backgroundColor: "#594099",
                }}
              >
                YES
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

// export default CourseDetailsModal;
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  assignCourseToStudent: (
    callback,
    successCallback,
    edit,
    deactive,
    startDate,
    deleteCourse
  ) =>
    dispatch(
      assignCourseToStudent(
        callback,
        successCallback,
        edit,
        deactive,
        startDate,
        deleteCourse
      )
    ),
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  getStudentsDetailsById: (callback) =>
    dispatch(getStudentsDetailsById(callback)),
  postUnAssignStudent:(unAssignParam,callBack, succesCallback) => dispatch(postUnAssignStudent(unAssignParam,callBack, succesCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsModal);
