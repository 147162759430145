// import { Calendar } from 'antd';
import React from 'react'
import { DatePicker, Space } from 'antd';
import { setEndDate, setStartDate, barGraphPlotFunction } from 'actions/adminCare';
import { connect } from 'react-redux';
import { setEndDateLib, setStartDateLib } from 'actions/digitalLibrary';
import moment from 'moment';
 

const { RangePicker } = DatePicker;


const CalenderPopup = ( {setStartDate,setStartDateLib,setEndDateLib,setEndDate,barGraphPlotFunction,togglePageLoader,digitalLib,startDate,endDate}) =>{
   
    function onChange(dates, dateStrings) {
      if(digitalLib==true)
      {
        setStartDateLib(dateStrings[0].split(' ')[0]);
        setEndDateLib(dateStrings[1].split(' ')[0]);
        
        
      }else{
        togglePageLoader(true)
        setStartDate(dateStrings[0].split(' ')[0]);
        setEndDate(dateStrings[1].split(' ')[0]);
        barGraphPlotFunction(() =>togglePageLoader(false))
      }
    }
      

    return(
        
<div className="rangePicker" style={{width:'300px'}} >
        <Space  direction="vertical" mode={'month'}   size={12}  style={{width:'300px'}}>
       
        <RangePicker value={[startDate? moment(startDate):null,endDate? moment(endDate):null]} dropdownClassName={'rangePickerB'} placeholder={['Start Date','End Date']} onChange={onChange}  renderExtraFooter={() => 'extra footer'}  />
        
      </Space>

    </div>
  )
}


const mapStateToProps = (state) => {
 
  const { user,
    startDate,
    endDate } = state.adminCare;
  return {
    startDate,
    endDate
  };
};
const mapDispatchToProps = dispatch => ({
  
  setStartDate:(m)=>dispatch(setStartDate(m)),
  setEndDate:(m)=>dispatch(setEndDate(m)),
  barGraphPlotFunction:(callback)=>dispatch(barGraphPlotFunction(callback)),
  setEndDateLib:(endDate)=>dispatch(setEndDateLib(endDate)),
  setStartDateLib:(startDate)=>dispatch(setStartDateLib(startDate)),

});


export default connect(mapStateToProps, mapDispatchToProps)(CalenderPopup);



