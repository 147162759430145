import React from "react";
import image from "Assets/course/courseCardImage.svg";
import './style.css'
import { Progress, Typography } from "antd";
import moment from "moment";

function CourceCard({ course, duration, plan, endDate, startDate, progress }) {
  return (
    <div
      className="courseCardB"
    >
      <div style={{ display: "flex", marginBottom: 20, marginTop: -5 }} >
        <img
          width="22%"
          src={image}
          alt=""
        />
        <div className="m-t-10" style={{ marginLeft: 20 }}>
          <Typography.Text
            ellipsis={{ tooltip: true }}
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "3px",
              color: "#191919",
              width: "170px",
            }}
          >
            {course}
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              //   justifyContent: "space-between",
            }}
          >
            <p
              style={{
                color: "#636363",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
            >
              {duration}
            </p>
            {plan ? (
              <p style={{ marginLeft: "2px", marginRight: "2px" }}>•</p>
            ) : null}
            <p style={{ color: "#636363", fontSize: "14px", fontFamily: "Roboto" }}>
              {plan}
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ color: "#636363", fontSize: "11px", fontWeight: "bold", marginTop: "-13px" }}>Completed</div>
        <div style={{ color: "#636363", fontSize: "11px", fontWeight: "bold", marginTop: "-13px" }}>{Math.round(progress === undefined ? null : progress)}%</div>
      </div>

      <div>
        <Progress
          className='student-test-progressbar'
          style={{ width: "100%", left: '0px', right: '50px' }}
          trailColor=''
          strokeColor='#594099'
          percent={progress}
          size={'default'}
          status="active"
          showInfo={false} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>

        <div style={{ color: "#AEAEAE", fontSize: "11px", fontWeight: "bold" }}>{startDate === null ? null : (moment(startDate).format('DD-MM-YYYY'))}</div>
        <div style={{ color: "#AEAEAE", fontSize: "11px", fontWeight: "bold" }}>{endDate === null ? null : moment(endDate).format('DD-MM-YYYY')}</div>
      </div>


    </div>
  );
}

export default CourceCard;
