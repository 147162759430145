import React, {useState} from 'react'
import { Button, Modal } from "antd"
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { setImportMeta } from 'actions/testAdmin';
import { setFromClassroom } from 'actions/classRoom';

//images
const blueImage = require("../../../Assets/test/Mask Group 48.svg").default;
const pinkImage = require("../../../Assets/test/Mask Group 50.svg").default;


const CreateYourOwn = ({ setSelectedType, selectedType, }) => {

    const handleCardClick = () => {
        setSelectedType('createYourOwn')
    }

    return(
        <>
            <div className='flex-column relative cursor-pointer' onClick={handleCardClick}>
            <div className='text-right absolute' style={{ zIndex: 2, padding: 5,}}>
                {selectedType == 'createYourOwn' ? <MdRadioButtonChecked/> : <MdRadioButtonUnchecked/>}
            </div>
                <img src={blueImage} alt='blueimage' style={{width: 230, zIndex: 1,borderRadius:2}}></img>
                    <div style={{border: '1px solid #E6E6E6', width: '230px', height: 90, padding: 15, borderRadius: 4}}>
                        <div className='text-xmd bold-700' style={{color: '#191919'}}>{"Create your own"}</div>
                        <div className='text-xs bold-600' style={{color: '#636363'}}>{"Got your own questions? Start with a clean slate."}</div>
                    </div>
            </div>
        </>
    )
}

const Import = ({ setSelectedType, selectedType, setImportMeta }) => {

    const handleCardClick = () => {
        setSelectedType('Import')
        setImportMeta("selectedQBType","sample question papers");
        setImportMeta('selectedTypeId', 1);
    }

    return(
        <>
            <div className='flex-column position-relative cursor-pointer' onClick={handleCardClick}>
            <div className='text-right absolute' style={{zIndex: 2, padding: 5}}>
                {selectedType == 'Import' ? <MdRadioButtonChecked/> : <MdRadioButtonUnchecked/>}
            </div>
                <img src={pinkImage} alt='blueimage' style={{width: 230, zIndex: 1,borderRadius:2}}></img>
                    <div style={{border: '1px solid #E6E6E6', width: '230px', height: 90, padding: 15, borderRadius: 4}}>
                        <div className='text-xmd bold-700' style={{color: '#191919'}}>{"Import"}</div>
                        <div className='text-xs bold-600' style={{color: '#636363'}}>{"Import sample question papers from our collection."}</div>
                    </div>
            </div>
        </>
    )
}

const CreateTestType = ({ isModalOpen,closeModal, setImportMeta, fromClassroom, setFromClassroom }) => {

    //HOISTINGS
    const history = useHistory();

    //USESTATES
    const [selectedType, setSelectedType] = useState('')
    // HANDLERS
    const handleNextClick = () => {
        if(selectedType == 'createYourOwn'){
            setFromClassroom(false)
            if(fromClassroom){
                setFromClassroom(true)
                history.push({ pathname: '/create-test', state: { key: "fromClassroom" } });
            }else{
                history.push('/create-test')
            }
        }else{
            setFromClassroom(false)
            if(fromClassroom){
                setFromClassroom(true)
                history.push({ pathname: '/test-import-classes', state: { key: "fromClassroom" } });
            }else{
                history.push('/test-import-classes')
            }
        }
    }



  return (
    <div>
        <Modal 
            centered
            visible={isModalOpen}
            footer={null}
            className='modal-round-corner'
            style={{padding:0, width: '100%'}}
            onCancel={closeModal}
            onOk={null}
            closable={true}
        >
            <div style={{textAlign:"center",fontSize:"21px",color:"#191919",fontWeight:"bold"}}>Choose</div>
            <>
                <div className='r-jsb' style={{width: '100%',marginTop:"20px"}}>
                    <CreateYourOwn setSelectedType={setSelectedType} selectedType={selectedType}/>
                    <Import setSelectedType={setSelectedType} selectedType={selectedType} setImportMeta = {setImportMeta}/>
                </div>
                <center className='r-jsb m-t-20' style={{width: '60%', display: 'flex', justifyContent: 'space-between', marginLeft: 95}}>
                    <Button className='radius-100' style={{width: 120}} onClick={()=>{closeModal()}} >CANCEL</Button>
                    <Button className='purpleBtnWrap radius-100 purpleButtonHoverEffect' onClick={handleNextClick} disabled={selectedType===""?true:false}>NEXT</Button>
                </center>
            </>
        </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {  
    };
  }
  
  const mapDispatchToProps = (dispatch) =>  ({
    setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  setFromClassroom:(val) => dispatch(setFromClassroom(val))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateTestType)

// export default CreateTestType