import React from 'react';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import { } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { CloseCircleOutlined } from '@ant-design/icons';



function ImageViewerModal({ isPreviewModalVisible,
  image, setIsPreviewModalVisible
}) {
  const onClose = () => {
    setIsPreviewModalVisible(false);
    console.log('close')
  }
  return (
    <Modal
      wrapClassName="imageViewerModal"
      width={'90%'}
      height={'90%'}
      centered={true}
      footer={null}
      visible={isPreviewModalVisible}
      maskClosable={true}
      closeIcon={<CloseCircleOutlined style={{ fontSize: 20 }} />}
      onCancel={onClose}>

      <div style={{ width: "100%", height: "100%" }}>
        <img style={{ height: "100%", width: "100%" }} src={`${image}`} alt="" />
      </div>

    </Modal>
  );
}


const mapStateToProps = state => {
  const { selectedTestId, selectedTest, submittedTestsId } = state.testAdmin;
  return { selectedTestId, selectedTest, submittedTestsId };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewerModal);
