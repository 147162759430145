import { ArrowLeftOutlined } from '@ant-design/icons'
import { Input, Typography, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import TopicCard from '../components/topicCard'
import emptyimage from "../assets/emty-tabs-subject-details/ill-es-sbj.png"
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchTopicWiseList, getTopicWiseList, updateSelectedChapter, updateCurrentSub, updateCurrentStd, postReorderTopics } from 'actions/digitalLibrary'
import PageDataLoader from 'components/pageDataLoader'
import CreateTopic from '../modals/createTopic'
import { InputSearch } from 'components/customInputs'
import PageHeader from 'components/pageHeader'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button } from 'components/Button'

const TopicWisePage = ({ selectedChapter, updateSelectedChapter, updateCurrentSub, fetchTopicWiseList, topicWiseList, user, getTopicWiseList, updateCurrentStd, postReorderTopics }) => {
    const history = useHistory();
    const location = useLocation();
    console.log({topicWiseList})

    const [pageLoader, tooglePageLoader] = useState(false);
    const [isCreateTopicModalVisible, setCreateTopicModalVisible] = useState(false)
    const [search, setSearch] = useState('')
    const [isSearchClear,setIsSearchClear] = useState('');
    const [isEditOrderClicked, setIsEditOrderClicked] = useState(false);
    const [topicsData, setTopicsData] = useState([]);
    const [reorderSaveLoader, setReorderSaveLoader] = useState(false)

    const handleHistory = () => {
        updateSelectedChapter({})
        history.goBack()
    }

    const onSearchTopic = (value) => {
        setIsSearchClear(value)
        setSearch(value)
        tooglePageLoader(true)
        fetchTopicWiseList(value, () => tooglePageLoader(false))
    }

    const handleCreateTopic = () => {
        setCreateTopicModalVisible(true)
    }

    useEffect(() => {
        setSearch({})
        getTopicWiseList([])
        tooglePageLoader(true)
        if(location?.state?.syllabus){
            let data = location?.state?.syllabus
            updateSelectedChapter({
                chapterId: data?.chapterId,
                chapterName: data?.chapterName
            })
            updateCurrentSub({
                id: data?.id,
                subjects_id: data?.subjects_id,
                subjects_name: data?.subjects_name,
                standardId: data?.std_id
            })
            updateCurrentStd({
                std_id: data?.std_id,
                standard: data?.standard,
                std_section: data?.std_section
            })
        }
        fetchTopicWiseList('', () => tooglePageLoader(false), (response) => { setTopicsData(response) })
    }, [])

    const reorderSaveDisable = topicWiseList?.every((topic, index) => topic?.topicName === topicsData[index]?.topicName);

    const actions = [
        (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) && topicsData?.length > 1 && !isEditOrderClicked && <Button type="primary" style={{ marginRight: 10, backgroundColor: "#FFFFFF", color: "#594099" }} onClick={() => { setIsEditOrderClicked(true) }}>REORDER</Button>,
        isEditOrderClicked && <Button style={{ marginRight: 20 }} onClick={() => { setIsEditOrderClicked(false); setTopicsData([...topicWiseList]) }}>CANCEL</Button>,
        isEditOrderClicked && <Button disabled={reorderSaveDisable} type="primary" loading={reorderSaveLoader} onClick={() => {
            setReorderSaveLoader(true);
            postReorderTopics(topicsData, () => { setReorderSaveLoader(false) }, () => { tooglePageLoader(true); fetchTopicWiseList('', () => tooglePageLoader(false)); setIsEditOrderClicked(false); })
        }}>SAVE</Button>,
        !isEditOrderClicked && ((user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) && (topicWiseList?.length != 0)) ?
            <Button className='btn-primary-upload' type="primary" style={{ marginRight: 10 }} onClick={() => handleCreateTopic()} icon={"+"}>CREATE A TOPIC</Button>
            : null
        ,
        !isEditOrderClicked && <InputSearch value={isSearchClear} placeholder="Search by topics" allowClear onInputChange={onSearchTopic} onClear={() => { onSearchTopic('') }} />
    ]

    const handleBackPress = () => {
        history.goBack();
    }

    return (
        <div>
            <div className="r-c-sb" style={{ height: "50px" }}>
                    <PageHeader onBackPress={()=>handleBackPress()} title={selectedChapter?.chapterName} actions={actions} maxWidth={800}/>
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: 'flex-end',
                        alignItems: "center",
                    }}>
                </div>
            </div>
            {console.log("topicWiseList",topicWiseList)}
            {topicsData?.length != 0 ?
                <DndProvider backend={HTML5Backend}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: '22px' }}>
                        {topicsData?.map((item, index) => (
                            <TopicCard topicItem={item} key={item.id} index={index} isEditOrderClicked={isEditOrderClicked} tooglePageLoader={tooglePageLoader} setTopicsData={setTopicsData}
                                moveTopic={(dragIndex, hoverIndex) => {
                                    const updatedTopics = [...topicsData];
                                    const draggedTopic = updatedTopics[dragIndex];
                                    updatedTopics.splice(dragIndex, 1);
                                    updatedTopics.splice(hoverIndex, 0, draggedTopic);
                                    setTopicsData(updatedTopics);
                                }} />
                        ))}
                    </div>
                </DndProvider>
                :
                <div>
                    <div className="all-cont">
                        <div className="img-tex-btn">
                            <img className="img-emp-cl" src={emptyimage} />
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                                <div>
                                    <p className="p-emp">No topic(s) available!</p>
                                    <p className="p-c-emp">There are no topic(s) created yet.</p>
                                </div>
                                {(
                                    user.access.includes("digital-library-admin") ||
                                    user.access.includes("digital-library-superadmin") ||
                                    user.access.includes("digital-library-dataoperator")
                                ) ?
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <Button className='btn-primary-upload' type="primary" onClick={() => handleCreateTopic()} icon={"+"}>Create a topic</Button>
                                    </div> : null}

                            </div>
                            {/* {(isCreateTopicModalVisible && <CreateTopic setIsSearchClear={setIsSearchClear} isModalVisible={isCreateTopicModalVisible} setIsModalVisible={setCreateTopicModalVisible} />)} */}
                        </div>
                    </div>
                </div>}

            {(isCreateTopicModalVisible && <CreateTopic setIsSearchClear={setIsSearchClear}isModalVisible={isCreateTopicModalVisible} setIsModalVisible={setCreateTopicModalVisible} onSearchTopic={onSearchTopic} setSearch={setSearch} tooglePageLoader={tooglePageLoader} setTopicsData={setTopicsData}/>)}
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const {
        selectedSubject,
        selectedChapter,
        topicWiseList
    } = state.digitalLibrary;
    const { user } = state.session
    return {
        selectedSubject,
        user,
        selectedChapter,
        topicWiseList
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSelectedChapter: (object) => dispatch(updateSelectedChapter(object)),
    updateCurrentSub: (object) => dispatch(updateCurrentSub(object)),
    fetchTopicWiseList: (search, callback, successCallBack) => dispatch(fetchTopicWiseList(search, callback, successCallBack)),
    getTopicWiseList: (data) => dispatch(getTopicWiseList(data)),
    updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),
    postReorderTopics: (topics, callBack, successCallBack) => dispatch(postReorderTopics(topics, callBack, successCallBack))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(TopicWisePage);