import React, { useState } from "react";
import {
  Modal,
  Button,
  Select,
  DatePicker,
  Input,
  Tag,
  message,
  Radio,
  Tooltip,
} from "antd";
import {
  Title,
  Heading,
  SubHeading,
  SmallHeading,
} from "components/Typography";
import {
  CloseCircleOutlined,
  SearchOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import {
  generateClassLink,
  generateLink,
  getFeeUser,
  toggleGenerateLinkModal,
  updateFeeUsers,
  updateSelectedFeeStudents,
} from "actions/fees";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import moment from "moment";
import { IS_PROD } from "env.json";
import { Color } from "services";

const { Option } = Select;
const { TextArea } = Input;

let searchTimer = false;

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
};

export const rateCard = (
  <div style={{ width: 200, color: "white", fontSize: 10, fontWeight: 500 }}>
    <Row style={{ padding: 5 }}>
      <Col span={17}>UPI/Rupay Card</Col>
      <Col span={7}>0</Col>
    </Row>
    <Row style={{ padding: 5 }}>
      <Col span={17}>Credit Card/Debit Card</Col>
      <Col span={7}>1.8 %</Col>
    </Row>
    {/* <Row style={{padding: 5}}>
      <Col span={17}>
       Net Banking
      </Col>
      <Col span={7}>
        X % - Y %
      </Col>
    </Row> */}
  </div>
);

const GenerateLink = ({
  kind,
  feeUsers,
  visible,
  toggleGenerateLinkModal,
  standards,
  feeSearchLoader,
  getFeeUser,
  generateLink,
  feeButtonLoader,
  generateClassLink,
  updateFeeUsers,
  selectedFeeStudents,
  updateSelectedFeeStudents,
  gstValid,
  transactionFeePayer,
}) => {
  const [type, setType] = useState("individual");
  const [studentClass, setStudentClass] = useState();
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState();
  const [dueDate, setDueDate] = useState();
  const [feeUser, setFeeUser] = useState();

  const handleSearch = (value) => {
    if (!value) return;
    clearTimeout(searchTimer);
    setTimeout(() => getFeeUser(studentClass, value), 800);
  };

  const removeStudent = (id) => {
    const idIndex = selectedFeeStudents.ids.indexOf(id);
    selectedFeeStudents.ids.splice(idIndex, 1);
    selectedFeeStudents.rows.forEach((item, index) => {
      if (item.id == id) {
        selectedFeeStudents.rows.splice(index, 1);
      }
    });
    updateSelectedFeeStudents(
      selectedFeeStudents.ids,
      selectedFeeStudents.rows
    );
  };

  const reset = () => {
    setType("individual");
    setStudentClass();
    setAmount("");
    setDescription("");
    setDueDate({ val: null, string: "" });
    setFeeUser();
  };

  return (
    <Modal
      className="generateLinkModal"
      afterClose={reset}
      onCancel={() => toggleGenerateLinkModal(false)}
      closeIcon={<CloseCircleOutlined style={{ fontSize: 22 }} />}
      title={null}
      visible={visible}
      width={700}
      footer={null}
      centered
    >
      <>
        <Row>
          <Col>
            <Title bold color="#594099">
              Generate Payment Request
            </Title>
            {/* <Heading color="#8C8C8C">
              Generate any payment link for students
            </Heading> */}
          </Col>
        </Row>
        {kind == "students" ? (
          <>
            {selectedFeeStudents?.rows?.length == 1 ? (
              <Row>
                <Col xs={24} sm={11}>
                  <SubHeading bold>{"Student's Name"}</SubHeading>
                  <Input
                    disabled
                    value={selectedFeeStudents?.rows[0].name}
                    size="large"
                    bordered={false}
                    style={{
                      color: "black",
                      marginTop: 10,
                      backgroundColor: "#FAFAFA",
                    }}
                  />
                </Col>
                <Col xs={2}></Col>
                {selectedFeeStudents?.rows[0].email && (
                  <Col xs={24} sm={11}>
                    <SubHeading bold>{"Email ID"}</SubHeading>
                    <Input
                      disabled
                      value={selectedFeeStudents?.rows[0].email}
                      size="large"
                      bordered={false}
                      style={{
                        color: "black",
                        marginTop: 10,
                        backgroundColor: "#FAFAFA",
                      }}
                    />
                  </Col>
                )}
              </Row>
            ) : (
              <>
                <SubHeading bold>{"Student's Name"}</SubHeading>
                <Row className="m-t-10">
                  {selectedFeeStudents?.rows?.map((student) => (
                    <Tag
                      key={student.id}
                      closable
                      style={{ padding: 5, borderRadius: 7 }}
                      onClose={() => removeStudent(student.id)}
                    >
                      <SubHeading bold style={{ display: "inline-block" }}>
                        {student.name}
                      </SubHeading>
                    </Tag>
                  ))}
                </Row>
              </>
            )}
          </>
        ) : (
          <>
            <Row>
              <SubHeading bold>Generate Payment Request For ?</SubHeading>
            </Row>
            <Row className="m-t-10">
              <Col>
                <Button
                  ghost={type == "class"}
                  type="primary"
                  shape="round"
                  size={"large"}
                  onClick={() => setType("individual")}
                >
                  Individual
                </Button>
                <Button
                  ghost={type == "individual"}
                  type="primary"
                  shape="round"
                  size={"large"}
                  onClick={() => setType("class")}
                  style={{ marginLeft: 20 }}
                >
                  Class
                </Button>
              </Col>
            </Row>
            <Row className="m-t-30">
              <Col flex="auto">
                <SubHeading bold>Class</SubHeading>
              </Col>
            </Row>
            <Row className="m-t-10">
              <Col flex="auto">
                <Select
                  size="large"
                  style={{
                    width: "100%",
                    backgroundColor: "#FAFAFA",
                  }}
                  bordered={false}
                  placeholder={
                    <SubHeading color="#AEAEAE">Select class</SubHeading>
                  }
                  value={studentClass}
                  onSelect={(value) => {
                    setStudentClass(value);
                    setFeeUser(null);
                  }}
                  allowClear
                  onClear={() => setStudentClass(null)}
                >
                  <Option key={"-1"}>Unassigned</Option>
                  {standards.map((standard) => (
                    <Option
                      key={standard.id}
                    >{`${standard?.std} - ${standard?.section}`}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <>
              {type == "individual" && (
                <>
                  <Row className="m-t-30">
                    <Col flex="auto">
                      <SubHeading bold>{"Student's name/mobile"}</SubHeading>
                    </Col>
                  </Row>
                  <Row className="m-t-10">
                    <Col flex="auto">
                      <Select
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        notFoundContent={null}
                        disabled={!studentClass}
                        size="large"
                        showSearch
                        onFocus={() => updateFeeUsers([])}
                        allowClear
                        onClear={() => updateFeeUsers([])}
                        suffixIcon={
                          feeSearchLoader ? (
                            <LoadingOutlined />
                          ) : (
                            <SearchOutlined
                              style={{ fontSize: 16, color: "#1089FF" }}
                            />
                          )
                        }
                        style={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                        }}
                        bordered={false}
                        placeholder={
                          <SubHeading color="#AEAEAE">
                            Search name/mobile
                          </SubHeading>
                        }
                        value={feeUser}
                        onSearch={(value) => handleSearch(value)}
                        onChange={(value) => {
                          setFeeUser(value);
                        }}
                      >
                        {feeUsers &&
                          feeUsers.map((user) => (
                            <Option key={user.id} value={user.id}>
                              {user.name}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </>
        )}

        <Row className="m-t-30">
          <Col flex="auto">
            <SubHeading bold>
              Details<small>(max 80 characters)</small>
            </SubHeading>
          </Col>
        </Row>
        <Row className="m-t-10">
          <Col flex="auto" className="">
            <TextArea
              value={description}
              size="large"
              onChange={(e) => setDescription(e.target.value)}
              bordered={false}
              style={{
                width: "100%",
                backgroundColor: "#FAFAFA",
              }}
              placeholder="Enter description"
              autoSize={{ minRows: 2, maxRows: 2 }}
              maxLength={80}
            />
          </Col>
        </Row>
        <Row className="m-t-10">
          <Col xs={11}>
            <DatePicker
              value={dueDate?.val}
              size="large"
              className="fee-date-picker"
              style={{ width: "100%" }}
              onChange={(val, string) => setDueDate({ val, string })}
              placeholder={"Set due date"}
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
            ></DatePicker>
          </Col>
          <Col xs={2}></Col>
          <Col xs={11}>
            <Input
              type="number"
              size="large"
              placeholder={"Enter amount"}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              bordered={false}
              prefix="₹"
              value={amount}
              style={{
                width: "100%",
                backgroundColor: "#FAFAFA",
              }}
            />
          </Col>
        </Row>
        <Row className="m-t-30">
          <Col flex="auto">
            <SubHeading bold>
              Processing Fee
              <Tooltip
                className="m-l-5"
                trigger="click"
                placement="right"
                title={rateCard}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </SubHeading>
          </Col>
        </Row>
        <Row className="m-t-10">
          <Col xs={11}>
            <div className="dark-grey">
              Processing fee payable by {transactionFeePayer}
            </div>
          </Col>
        </Row>
        {gstValid ? (
          <>
            <Row className="m-t-30">
              <Col flex="auto">
                <SubHeading bold>Amount Details </SubHeading>
              </Col>
            </Row>
            <Row className="">
              <div
                style={{
                  margin: 10,
                  backgroundColor: Color.lightGrey,
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                <Row style={{ margin: 10 }}>
                  <Col span={19}>Item Total</Col>
                  <Col span={5}>
                    {amount ? <> ₹ {(amount * 1).toFixed(2)} </> : ""}
                  </Col>
                </Row>
                <Row style={{ margin: 10 }}>
                  <Col span={19}>GST (18%)</Col>
                  <Col span={5}>
                    {amount ? <> ₹ {(amount * 0.18).toFixed(2)} </> : ""}
                  </Col>
                </Row>
                <Row
                  style={{
                    margin: 10,
                    borderTop: `1px solid ${Color.darkGrey}`,
                  }}
                >
                  <Col span={19} style={{ marginTop: 10 }}>
                    Total Payable (excluding processing fee)
                  </Col>
                  <Col span={5} style={{ marginTop: 10 }}>
                    {amount ? <> ₹ {(amount * 1.18).toFixed(2)} </> : ""}
                  </Col>
                </Row>
              </div>
            </Row>
          </>
        ) : null}
        <Row justify="center" className="m-t-30">
          <Button
            disabled={
              (kind != "students" &&
                (!studentClass || (type == "individual" && !feeUser))) ||
              !amount ||
              !dueDate?.string ||
              !description ||
              !description.trim().length
            }
            loading={feeButtonLoader}
            type="primary"
            shape="round"
            size={"large"}
            onClick={() => {
              if (amount < 1) {
                return message.error("Amount cannot be less than ₹1");
              }
              const details = {
                amount,
                dueDate: dueDate?.string,
                note: description,
                gst: (amount * 0.18).toFixed(2),
              };
              if (kind == "students") {
                return generateLink(selectedFeeStudents?.ids, details);
              }
              if (type == "class") {
                generateClassLink(parseInt(studentClass), details);
              } else {
                generateLink([feeUser], details);
              }
            }}
          >
            Send
          </Button>
        </Row>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    standards,
    feeSearchLoader,
    feeUsers,
    feeButtonLoader,
    selectedFeeStudents,
    gstValid,
    transactionFeePayer,
  } = state.fees;
  return {
    selectedFeeStudents,
    feeButtonLoader,
    feeUsers,
    feeSearchLoader,
    standards,
    gstValid,
    transactionFeePayer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSelectedFeeStudents: (ids, rows) =>
    dispatch(updateSelectedFeeStudents(ids, rows)),
  updateFeeUsers: (users) => dispatch(updateFeeUsers(users)),
  getFeeUser: (standardId, query) => dispatch(getFeeUser(standardId, query)),
  generateLink: (users, details) => dispatch(generateLink(users, details)),
  generateClassLink: (standardId, details) =>
    dispatch(generateClassLink(standardId, details)),
  toggleGenerateLinkModal: (value) => dispatch(toggleGenerateLinkModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateLink);
