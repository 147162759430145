import React from 'react';
import Upload from 'antd/lib/upload';
import 'antd/lib/upload/style/index';
import { LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import {Api} from '../services';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class Dashboard extends React.Component {
  state = {
    loading: false,
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    const params = {
      data: JSON.stringify(["world","hello","office"]),
    };

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      params.image = info.file.originFileObj;
      console.log('params', params);
      Api.post('/user/test').upload(params, (percent) => {

      });
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}

export default Dashboard;
