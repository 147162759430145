import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import {Spin} from 'antd'

function Loader({style}) {


  return (
    <div className="full-height full-width r-c-c">
        <LoadingOutlined style={style} spin/>
    </div>
  )
}

export default Loader;