import { addStudents, searchStudent } from 'actions/classRoom';
import { AutoComplete, Input, Modal, Tag } from 'antd';
import { Button } from 'components/Button';
import { ModalSubTitle } from 'components/Typography';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Color } from 'services';

function AddStudentModal({ isAddStudentModalVisible, setAddStudentModalVisible, standardId, togglePageLoader, getSubjStudentsData, searchStudent, addStudents, getStudentsListLoader }) {
  const [searchName, setSearchName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [unassignedStudentList, setUnassignedStudentList] = useState([]);
  const [selectedStudents, setSelectedStudent] = useState([]);
  const [addStudentLoader, setAddStudentLoader] = useState(false)
  const unAssignedBool = true;
  const handleCancel = () => {
    setAddStudentModalVisible(false)
  }
  useEffect(() => {
    searchStudent('', (students) => updateStudentList(students), unAssignedBool)
  }, [])

  useEffect(() => {
    const updatedUnassignedList = unassignedStudentList?.filter(item =>
      !selectedStudents?.some(student => student?.id === item?.id)
    );
    setUnassignedStudentList(updatedUnassignedList);
  }, [selectedStudents, studentList]);

  const handleStudentSearch = (query) => {
    if (query?.trim().length > 0 || query?.trim().length === 0) {
      setLoading(true)
      searchStudent(query, (students) => updateStudentList(students), unAssignedBool)
    }
  }
  const updateStudentList = (students) => {
    // setUnassignedStudentList(students?.filter((item) => !item?.disabledBool))
    setStudentList(students?.map(item => Object.assign(item, { key: item.id, label: item.value, value: item.id })))
    setLoading(false)
  }
  const handleStudentSelect = (value, obj) => {
    if (!selectedStudents?.map(student => student.id).includes(obj.id)) {
      selectedStudents.push(obj);
      setSelectedStudent([...selectedStudents]);
    }
    setSearchName('');
    searchStudent("", (students) => updateStudentList(students), unAssignedBool)
  }
  const removeSelectedStudents = (currStudent) => {
    for (let i = 0; i < selectedStudents?.length; i++) {
      if (currStudent.id == selectedStudents[i].id) {
        return false
      }
    }
    return true
  }
  const removeUnassignedStudents = (id) => {
    const updatedClassDetails = unassignedStudentList.filter(item => item.id !== id);
    setUnassignedStudentList(updatedClassDetails);
  }
  const removeStudent = (index) => {
    selectedStudents.splice(index, 1);
    setSelectedStudent([...selectedStudents])
    unassignedStudentList?.splice(index, 1)
    setUnassignedStudentList([...unassignedStudentList])
    searchStudent('', (students) => updateStudentList(students), unAssignedBool)
  }
  const handleAddClick = () => {
    setAddStudentLoader(true)
    const students = selectedStudents?.map(student => student.id)
    addStudents(
      standardId,
      students,
      () => setAddStudentLoader(false),
      () => {
        setAddStudentModalVisible(false);
        togglePageLoader(true);
        getSubjStudentsData(() => togglePageLoader(false))
      });
  }
  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={isAddStudentModalVisible}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}>
        <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Add Students"}</div>
        <div style={{ padding: 20 }}>
          <ModalSubTitle>Student’s name</ModalSubTitle>
          <AutoComplete
            notFoundContent={'No Students Found'}
            value={searchName}
            onChange={e => setSearchName(e)}
            options={studentList ? studentList?.filter(removeSelectedStudents) : []}
            style={{ border: 'none', width: '100%', color: Color.primary, fontSize: 16, fontWeight: 600 }}
            onSelect={(value, obj) => handleStudentSelect(value, obj)}
            onSearch={(query) => handleStudentSearch(query)}
          >
            <Input.Search style={{ border: 'none', borderRadius: 5, height: 32 }} placeholder="Search by name"
              loading={getStudentsListLoader} allowClear />
          </AutoComplete>
          <div className="flex-wrap m-t-10" style={{ height: 100, overflowY: "scroll" }}>
            {selectedStudents?.map((student, index) =>
              <Tag
                key={student.id}
                style={{
                  backgroundColor: '#F6F4FE',
                  color: '#594099',
                  padding: 5,
                  borderRadius: 5,
                  marginBottom: 20
                }}
                closable
                onClose={() => removeStudent(index)}
              >
                {student.label}
              </Tag>
            )}
          </div>
          <div style={{ width: "100%", height: "fit-content", padding: 10, border: "1px solid #E6E6E6", borderRadius: "4px", backgroundColor: "#FAFAFA" }}>
            <ModalSubTitle>Suggestions from Unassigned</ModalSubTitle>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {unassignedStudentList?.map((item, index) => {
                if (!selectedStudents.some(student => student.id === item.id)) {
                  return (
                    <div
                      key={item.id}
                      style={{ color: "#AEAEAE", marginRight: 30, cursor: "pointer", display: index <= 4 ? "block" : "none" }}
                      onClick={() => { handleStudentSelect("ghjk", item); removeUnassignedStudents(item.id) }}
                    >
                      {item.label}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
        <div className='r-c-c m-t-40'>
          <Button
            type="primary"
            disabled={!selectedStudents.length}
            onClick={handleAddClick}
            loading={addStudentLoader}
          >ADD</Button>
        </div>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => {
  const { getStudentsListLoader } = state.classRoom;
  return {
    getStudentsListLoader
  };
};

const mapDispatchToProps = dispatch => ({
  searchStudent: (query, callback, unAssignedBool) => dispatch(searchStudent(query, callback, unAssignedBool)),
  addStudents: (standardId, students, callback, successCb) => dispatch(addStudents(standardId, students, callback, successCb))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStudentModal)
// export default AddStudentModal