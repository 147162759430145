import React from "react";
import "antd/dist/antd.less";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
// import logger from "redux-logger";
import thunk from "redux-thunk";
import Root from "./root/root";
import { sessionService } from "redux-react-session";
import "./App.css";
import { Api } from "./services";
import { saveToLocalStorage, loadFromLocalStorage } from "./store";
// import ReactQuill, {Quill} from "react-quill";
import katex from "katex";
import "katex/dist/katex.min.css";
import "react-multi-carousel/lib/styles.css";
import logger from "redux-logger";
import firebaseApp from "pages/schedule/firebase";
import { getMessaging, getToken,onMessage } from "firebase/messaging"
import { setDownloadMeta } from "actions/broadcast";

window.katex = katex;

const persistedState = loadFromLocalStorage();
export const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk, logger)
  // applyMiddleware(thunk)
);
// add , logger
store.subscribe(() => saveToLocalStorage(store.getState()));
sessionService.initSessionService(store).then((data) => {
  const { authenticated, user } = store.getState()["session"];
  if (authenticated) {
    console.log("Authenticated", authenticated);
    const token = user.accessToken;
    Api.header(token);
  }
});
window.store = store;

class App extends React.Component {
  onUnload(){
    store.dispatch(setDownloadMeta('clear'));
  }
  componentDidMount() {
    store.dispatch(setDownloadMeta('clear'));
    window.addEventListener("beforeunload", this.onUnload);
    document.title = "aulas";
    // const script = document.createElement("script");
    // script.src = "https://cdn.quilljs.com/1.3.6/quill.js";
    // script.async = true;
    // document.body.appendChild(script);
    // const messaging = firebaseApp.messaging()
    // messaging
    // .requestPermission()
    // .then(()=>{
    //   return messaging.getToken()
    // })
    // .then((token)=>{
    //   console.log("token..",token)
    // })
    // .catch(error=>{
    //   console.log("error",error)
    // })


    const messaging = getMessaging();
    // messaging
    // .requestPermission()
    // .then(() => {
    //   console.log("Permission Granted")
    // })
    // .catch((err) => {
    //   console.log("Error",err)
    // });
    getToken(messaging, { vapidKey: 'BF4b1LNVWNOCQMS0Zw7_LsB_VecHFAKNi_2bFABzH00yV7jRrd2ZGnigPBfJ_MFsHkc-lDdemOfNR0v30f8Wfto' })
    .then((currentToken) => {  if (currentToken)
       { 
        } 
        else { 
          console.log('No registration token available. Request permission to generate one.');  }})
          .catch((err) => {  console.log('An error occurred while retrieving token. ', err);  });
    
    onMessage(messaging, (payload) => {  
      console.log('Message received. ', payload); 
    });

  }
  componentWillUnmount(){
    window.removeEventListener("beforeunload", this.onUnload);
  }
  render() {
    return (
      <Provider store={store}>
        <Root />
      </Provider>
    );
  }
}

export default App;
