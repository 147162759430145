import { CopyOutlined } from '@ant-design/icons';
import { setOffersBarVisible } from 'actions/studentMarketPlace';
import moment from 'moment';
import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import { connect } from 'react-redux';
import './userHomeStyles.css';

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};

function OffersCarousel({ offersCarouselData, setOffersBarVisible }) {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopyCode = (code, index) => {
    const tempInput = document.createElement('input');
    tempInput.value = code;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setCopiedIndex(index);
  };

  const WebOffersCarousel = ({ item, index }) => {
    const isCopied = copiedIndex === index;

    return (
      <div className='top-promo-parent' style={{ backgroundColor: '#FFEF00' }}>
        <div style={{marginLeft:-90}}>
        <center className='top-promo-child1' style={{ color: '#191919', marginRight: 40,marginTop:4 }}>
            <span style={{fontSize:"14px"}}>Use the promo code </span>
            <span style={{ fontWeight: 'bold',fontSize:"14px" }}>
              {item?.code}
            </span>
            {isCopied ? (
              <span className='text-16' style={{fontSize:"14px"}}> Copied!</span>
            ) : (
              <CopyOutlined
                style={{ fontSize: 18, marginLeft: 5, cursor: 'pointer' }}
                onClick={() => {
                  handleCopyCode(item?.code, index);
                }}
              />
            )}
            <span className='text-16'> to get an instant discount</span>
            <span style={{ fontWeight: 'bold',fontSize:"14px",marginLeft:4  }}>{item?.discountType === 'percent' ? `${item?.discount}%` : `₹${item?.discount?.toLocaleString()}`}</span>
          {item?.discountType === 'percent' && parseFloat(item.maxDiscount) > 0 ?
            <span style={{ fontWeight: 600,fontSize:"14px"}}>
              {` (max discount: ₹${parseFloat(item?.maxDiscount)?.toLocaleString()})`}
            </span>
            : null
          }
          <span>
          {item?.endDate ?
           <span style={{  color: '#191919', padding: '3px 5px', fontSize:"14px",  }}>
            offer Valid until {moment(item?.endDate).format('DD-MM-YYYY')}
           </span>
         :
         <></>
       }
          </span>
          
           
          </center>
          

        </div>
       
        {/* <div className='avail-discount-font' style={{ color: '#191919' }}>
          <span style={{ fontWeight: 600,fontSize:"14px" }}>Avail discount of </span>
          <span style={{ fontWeight: 'bold',fontSize:"14px" }}>{item?.discountType === 'percent' ? `${item?.discount}%` : `₹${item?.discount?.toLocaleString()}`}</span>
          {item?.discountType === 'percent' && parseFloat(item.maxDiscount) > 0 ?
            <span style={{ fontWeight: 600,fontSize:"14px" }}>
              {` (max discount: ₹${parseFloat(item?.maxDiscount)?.toLocaleString()})`}
            </span>
            : null
          }
        </div>
        <div className='top-promo-child m-t-5 m-l-5'>
          <center className='top-promo-child1' style={{ color: '#191919', marginRight: 40 }}>
            <span style={{fontSize:"14px"}}>Use the promotional offer </span>
            <span style={{ fontWeight: 'bold',fontSize:"14px" }}>
              {item?.code}
            </span>
            {isCopied ? (
              <span className='text-16' style={{fontSize:"14px"}}> Copied!</span>
            ) : (
              <CopyOutlined
                style={{ fontSize: 18, marginLeft: 5, cursor: 'pointer' }}
                onClick={() => {
                  handleCopyCode(item?.code, index);
                }}
              />
            )}
            <span className='text-16'> to get an instant discount</span>
          </center>
          {item?.endDate ?
            <center>
              <div style={{ backgroundColor: '#FFFFFF', color: '#191919', padding: '3px 5px', fontSize:"14px", width: 'fit-content' }}>
                Valid until {moment(item?.endDate).format('DD-MM-YYYY')}
              </div>
            </center>
            :
            <></>
          }
        </div> */}
       
      </div>
    );
  };

  return (
    <div className='marketplace-top-row '>
      {offersCarouselData?.length ? (
        <Carousel
          responsive={responsive}
          infinite
          arrows={false}
          showDots={false}
          autoPlay={true}
          autoPlaySpeed={5000}
          pauseOnHover={false}
          renderButtonGroupOutside={false}
        >
          {offersCarouselData?.map((item, index) => {
            return <WebOffersCarousel key={index} item={item} index={index} />;
          })}
        </Carousel>
      ) : (
        <></>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { offersCarouselData, isOffersBarVisible } = state.studentMarketPlace;
  return { offersCarouselData, isOffersBarVisible };
};

const mapDispatchToProps = (dispatch) => ({
  setOffersBarVisible: (val) => dispatch(setOffersBarVisible(!!val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersCarousel);
