import React, { useEffect, useState } from "react";
import '../style.css'
// import "antd/dist/antd.css";
import { Modal, Button, Input, DatePicker, Space } from "antd";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { createLeaves, storeCreateLeaveModalData } from "actions/attendanceNew";
import moment from "moment";

const LeaveModal = ({ isModalVisible, setIsModalVisible, createLeave, storeCreateLeaveModalData, createLeaves }) => {
  const[leaveStartDate, setLeaveStartDate]=useState("");
  
  const history = useHistory();
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = (e) => {
    history.push("leave-graph");
    setIsModalVisible();
    createLeaves()
  };

  function disabledDate(current) {
    return current && current < moment().startOf('day');

  }

  return (
    <>
      <div>
        <Modal
          centered
          className="modal-round-corner"
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleCreate}
          footer={null}
        >
          <div
            style={{
              marginTop: "30px",
              fontSize: "24px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Create Leave Type
            {/* {getLeaves} */}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Leave name
              </h6>
              <Input
              onChange={(e) => storeCreateLeaveModalData('leaveName', e.target.value)}
                style={{
                  fontSize: "16px",
                  color: "#191919",
                }}
                placeholder="Enter leave name"
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Number of days
              </h6>
              <Input
              onChange={(e) => storeCreateLeaveModalData('noOfDays', e.target.value)}
                style={{
                  fontSize: "16px",
                  color: "#191919",
                }}
                placeholder="Number of days"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Start Date
              </h6>
              <Space>
                <DatePicker
                  format={'DD-MM-YYYY'}
                 disabledDate={disabledDate}
                 onChange={(e) => {
                setLeaveStartDate(e.format('YYYY-MM-DD'))
                storeCreateLeaveModalData('startDate', e.format('YYYY-MM-DD')) }}
              style={{
                    fontSize: "16px",
                    color: "#AEAEAE",
                    width: "220px",
                  }}
                  placeholder="Select start date"
                />
              </Space>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                End Date
              </h6>
              <Space>
                <DatePicker
                format={'DD-MM-YYYY'}
                disabledDate={disabledDate}
                onChange={(e) => storeCreateLeaveModalData('endDate', e.format('YYYY-MM-DD')) }
                disabled={leaveStartDate===""?true:false}
              
                  style={{
                    fontSize: "16px",
                    color: "#AEAEAE",
                    width: "220px",
                  }}
                  placeholder="Select end date"
                />
              </Space>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #636363 ",
                  color: "#636363",
                }}
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#594099",
                  color: "#FFFFFF",
                }}
                onClick={handleCreate}
                // createLeaves={createLeaves}
              >
                CREATE
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  const {createLeave} = state.attendanceNew;
  return {createLeave};
};

const mapDispatchToProps = (dispatch) => ({
  createLeaves:() => dispatch(createLeaves()),
  storeCreateLeaveModalData: (key,val)=> dispatch(storeCreateLeaveModalData(key,val))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveModal);

