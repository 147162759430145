import React, { useState } from 'react'
import "./studyMaterialContent.css"
import LockIcon from "../../../Assets/aulasMarket/icons8-lock.svg";
import { connect } from 'react-redux';
import { getAttemptQuizData, setParamAttemptQuiz, setPdfViewUrl, setQuizFlowCount } from 'actions/studentMarketPlace';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import pdfIcon from "../../../Assets/aulasMarket/icon-pdf.png"
import quizIcon from "../../../Assets/aulasMarket/ic-practice-quiz.svg"


function StudyMaterialContent({ singleCourseDetailsDataContainer, getAttemptQuizData, setParamAttemptQuiz, setQuizFlowCount, setPdfViewUrl, fromStudyMaterialPage }) {
    const [showMore, setShowMore] = useState(false);
    const contentToShow = showMore ? singleCourseDetailsDataContainer?.length : 10;
    const history = useHistory();

    const handleStudyContentName = (item) => {
        console.log("whatsss", item);
        if (item?.type === "quiz") {
            setParamAttemptQuiz({
                id: item?.quizId,
                itemId: item?.studyMaterialId,  
                type: "studyMaterial"
            })
        getAttemptQuizData(item?.quizId, item?.studyMaterialId, "studyMaterial", (res) => {
            console.log("responseeee", res, res?.questions);
        })

        setQuizFlowCount(1);
    } else {
        setPdfViewUrl(item);
    { fromStudyMaterialPage ? history.push("/pdf-view") : history.push("/pdf") }
}
    }
return (
    <div className={fromStudyMaterialPage ? 'study-course' : 'study-content-parent m-t-20'}>
        <div className='study-content-header'>Resources</div>
        <div className='m-t-20'>
            {singleCourseDetailsDataContainer?.slice(0, contentToShow).map((item, index) => (
                <div className='study-chapter-parent'>
                    <div className='study-chapter-list'>
                        <div className='r-c-fs' style={{}} >
                            <div style={{ marginRight: 20 }}>{index + 1}</div>
                            <div className='study-chapter-names' style={{ cursor: item.preview || fromStudyMaterialPage ? 'pointer' : 'default' }} onClick={() => { item?.preview || fromStudyMaterialPage ? handleStudyContentName(item) : null }} ><span><img style={{width:30,height:30}} src={item?.type =="notes"?pdfIcon:quizIcon} /></span> {item?.name} <img src={item?.preview || fromStudyMaterialPage ? "" : LockIcon} style={{ marginLeft: 10 }} /></div></div>
                        <div className='r-c-fe' style={{ color: "#AEAEAE", fontWeight: "normal" }}>{item?.type == "quiz" ? <></> : <>{item?.pageCount} Pages</>}</div>
                    </div>
                </div>
            ))}
            {singleCourseDetailsDataContainer?.length > 10 ?
                <div className='study-show-more cursor-pointer' onClick={() => setShowMore(!showMore)} >{showMore ? "View Less" : "View more"}</div>
                : (<></>)}
        </div>
    </div>
)
}

const mapStateToProps = (state) => {
    const { pdfViewFile } = state.studentMarketPlace
    return {
        pdfViewFile
    }
};

const mapDispatchToProps = (dispatch) => ({
    setPdfViewUrl: (val) => dispatch(setPdfViewUrl(val)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
    getAttemptQuizData: (id, itemId, type, callback) => dispatch(getAttemptQuizData(id, itemId, type, callback)),
    setParamAttemptQuiz: (val) => dispatch(setParamAttemptQuiz(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterialContent)