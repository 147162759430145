import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal, Radio, Select, TimePicker, Typography, DatePicker } from 'antd';
import ic_file_eadd from "../../../Assets/images/ic_file_eadd.svg"
import { CalendarOutlined, FieldTimeOutlined, InboxOutlined, LockFilled, LockOutlined } from '@ant-design/icons';
import { setAnnouncementData, postAnnouncementData, setAnnounceKey, setDraftPage, setSchedulePage, storeAnnouncePage } from 'actions/announcement';
import { message, Upload } from 'antd';
import { Notification } from "services";

import { connect } from 'react-redux';

// import DatePicker from 'pages/care/datePicker';
import moment from 'moment';
// import { setKey } from 'actions/digitalLibrary';
const { Text } = Typography
const { Option } = Select

const { Dragger } = Upload;
const calenderIcon = <CalendarOutlined style={{ color: "#1089FF" }} />
const timeIcon = <FieldTimeOutlined style={{ color: "#1089FF" }} />


const CreateAnnouncementModal = ({ fetchAnnouncement, SetKey, standardList, isAnnouncement, setIsAnnouncement, close, storeAnnouncementData, setAnnouncementData, postAnnouncementData, key, setAnnounceKey, togglePageLoader, storeKey, setDraftPage, setSchedulePage, storeAnnouncePage }) => {
    console.log("AA-storeAnnouncementData", storeKey);
    const [isSelectValue, setIsSelectValue] = useState('')
    const [classListShow, setClassListShow] = useState(true)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [time, setTime] = useState(moment().format('hh:mm '))
    const [file, setfile] = useState([])
    const [check, setCheck] = useState(false)
    const [isDraftLoader, setIsDraftLoader] = useState(false)
    const [isAnnouncementLoader, setIsAnnouncementLoader] = useState(false)
    const [scheduleLoader, setSchedulerLoader] = useState(false)

    const [std, setStd] = useState([])
    const [type, setType] = useState("")


    const [image, setImage] = useState(null)
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [isDisable, setIsDisable] = useState(false)

    useEffect(() => { setDate(''), setTime('') }, [])

    const showModal = () => {
        setIsAnnouncement(true);
    };

    const handleCancel = () => {
        // setDescriptionLength(null)
        setAnnouncementData('')
        // setDescription('')
        // setTarget('')
        setIsSelectValue('')
        setDate('')
        setTime('')
        setIsAnnouncement(false);


    };
    function handleRemove(info) {
        let temp = []
        for (let i = 0; i < file.length; i++) {
            if (file[i].uid !== info) {
                temp.push(file[i])
            }
        }
        setfile([...temp]);

    }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const fileSizeLimit = 10 * 1024 * 1024

    const props = {
        name: 'file',
        multiple: false,
        action: "https://testprod.aulas.in/api/mock-api/mock-upload",
        onPreview() { handlePreview },
        onChange(info) {
            console.log("AAA-fileSizeLimit", fileSizeLimit, info);

            const { status } = info.file;

            if (info.file.status === 'uploading') {
                return
            }
            if (info.file.status === "done" || info.file.status === "error") {
                if (info.file.size > fileSizeLimit) {
                    message.error('File size exceeds the limit of 10MB.');
                    return;
                } else {
                    getBase64(info.file.originFileObj, (imageUrl) => {
                        message.success(`${info.file.name} file uploaded successfully.`);
                        setfile([...file, info.file.originFileObj])
                    })
                }

            }
        },
        onDrop(e) {
        },
        onRemove(info, index) {
            handleRemove(info.uid);
        }

    };
    useEffect(() => {

        if (std[0] != undefined || std[0] != -1) {
            console.log("AAA-std1", std?.length, standardList.length);
            if (std?.length == standardList.length) {
                setClassListShow(false)
                setStd([-1])
            }
        }

    }, [std?.length])


    const handleClass = (e, obj) => {
        console.log("AA-e", e);
        // setSelectClass(true)
        if (e.includes(-1)) {
            setClassListShow(false)
            setStd([-1])
        }
        else if (!e.length == 0) {
            setStd(e)
        }
        else {
            setStd(e)
            setClassListShow(true)
        }
    }
    const onSelect = (value, obj) => {
        if (value == -1) {
            setStd([-1])
        }
    }
    const onChangeCheckBox = (e) => {
        setCheck(e.target.checked)
    }
    const handleDate = (date, dateString) => {
        setDate(dateString)
        setIsDisable(true)
    }

    const handleTime = (time, timeString) => {
        setTime(timeString)
    }
    const disabledStartTimeHours = () => {
        const hours = [];
        if (moment().format('YYYY-MM-DD') === date) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }

        }
        return hours;
    };
    const disabledStartTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour() && moment().format('YYYY-MM-DD') === date) {
            for (var i = 0; i <= (moment().minute() + 1); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }
    return (
        <>

            <Modal
                // className="viewAnnouncement"
                className="modal-round-corner"
                visible={isAnnouncement}
                footer={null}
                //centered={true}
                width={"50%"}
                closable={true}
                // bodyStyle={{ height: "500px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Create an announcement</div>
                <div style={{ fontFamily: 'roboto', color: '#636363', textAlign: "center" }}>Kindly fill in the information below </div>
                <div>Title</div>
                <div>
                    <Input
                        value={storeAnnouncementData}
                        placeholder='Enter title'
                        onChange={(e) => {
                            setAnnouncementData(e.target.value)
                        }}
                    />
                </div>
                <div style={{ marginTop: "20px", }}>
                    <div>Attachment(s)</div>
                    <Dragger
                        {...props}
                        // disabled={file?.length != []}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xsl,.pdf,.doc,.docx,.jpg,.jpeg,.png" listType="picture" style={{ borderColor: "#1089FF" }}>
                        <div className="ant-upload-drag-icon">
                            <img src={ic_file_eadd} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="ant-upload-text" style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "bold" }}>Drop file here or</div>
                            <div className="ant-upload-hint" style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", marginLeft: "5px" }}>
                                Click to Upload
                            </div>
                        </div>
                    </Dragger>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                        <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Target</Text>
                        {console.log("AA-isSelectValue", typeof isSelectValue)}
                        <Select
                            getPopupContainer={(trigger) => trigger.parentElement}
                            size='middle'
                            placeholder={'Select Target'}
                            value={isSelectValue !== '' ? isSelectValue : null}
                            onChange={(e) => {
                                setIsSelectValue(e)
                            }}
                        >
                            <Option value={'All'}>All</Option>
                            <Option value={'Teacher'}>Teacher</Option>
                            <Option value={'Student'} >Student</Option>
                            <Option value={'Data Operator'}>Data Operator</Option>
                            <Option value={'Operation'}>Operation</Option>
                        </Select>

                    </div>

                    {isSelectValue == "Student" ?

                        < div style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                            <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }}>Class(es)</Text>

                            <Select
                                getPopupContainer={(trigger) => trigger.parentElement}
                                placeholder='Select Classes'
                                mode='multiple'
                                // tagRender={tagRender}
                                filterOption={true}
                                optionFilterProp='children'
                                onChange={handleClass}
                                onSelect={onSelect}
                                value={std}
                            >
                                <Option value={-1}>All</Option>
                                {classListShow && standardList.length != 0 ? standardList.map((item) => (
                                    <Option value={item.id}>{item.std + "-" + item.section}</Option>
                                )) : null}
                            </Select>
                        </div> : null}

                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Checkbox style={{ padding: '5px' }} checked={check} onChange={(e) => { onChangeCheckBox(e) }}>Schedule for Later  </Checkbox>

                    </div>
                    {true ?
                        <div className='m-t-5' style={{ padding: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }} >Date</Text>
                                <DatePicker
                                    inputReadOnly={true}
                                    suffixIcon={calenderIcon}
                                    size='middle'
                                    disabled={check === false}
                                    onChange={handleDate}
                                    //  format={'DD-MM-YYYY'} 
                                    defaultValue={date === '' ? moment(date, 'YYYY-MM-DD') : null}
                                    disabledDate={disabledDate}


                                ></DatePicker>
                            </div>
                            <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                <Text style={{ fontFamily: 'roboto', fontWeight: 'bold' }} >Time</Text>
                                <TimePicker
                                    size='middle'
                                    inputReadOnly={true}
                                    suffixIcon={timeIcon}
                                    disabled={check === false}
                                    onChange={handleTime}
                                    // format={'HH:mm'} 
                                    defaultValue={time === '' ? moment(time, 'HH:mm') : null}
                                    disabledHours={disabledStartTimeHours}
                                    disabledMinutes={disabledStartTimeMinutes}

                                ></TimePicker>
                            </div>
                        </div>
                        : null}
                </div>


                {console.log("AA-std", std)}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "40px",
                    }}
                >
                    <div>
                        {console.log("AAfile-file", isSelectValue)}
                        <Button
                            loading={isDraftLoader}
                            disabled={(isSelectValue == '' || storeAnnouncementData == undefined || storeAnnouncementData?.trim()?.length == 0 || file?.length == 0)}
                            style={{
                                borderRadius: "19px",
                                width: "150px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #636363 ",
                                color: "#636363",
                            }}

                            onClick={() => {
                                setIsDraftLoader(true)
                                postAnnouncementData(isSelectValue, std, "DRAFT", date, time, file,
                                    () => {
                                        setIsDraftLoader(false)
                                    },
                                    () => {
                                        setAnnounceKey('1')
                                        setDraftPage(1)
                                        setIsAnnouncement(false);
                                        togglePageLoader(true)
                                        fetchAnnouncement('', '', () => togglePageLoader(false))
                                    })
                            }}
                        >
                            SAVE AS DRAFT
                        </Button>
                    </div>
                    {console.log("AAA-std", std, isSelectValue)}
                    {check !== true ?
                        <div>
                            <Button
                                loading={isAnnouncementLoader}
                                disabled={isSelectValue == '' || storeAnnouncementData == undefined || storeAnnouncementData?.trim().length == 0 || file?.length == 0}
                                style={{
                                    borderRadius: "19px",
                                    width: "150px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",

                                }}

                                onClick={() => {
                                    setIsAnnouncementLoader(true)

                                    postAnnouncementData(isSelectValue, std, "ANNOUNCENOW", date, time, file,
                                        () => {
                                            setIsAnnouncementLoader(false)


                                        },

                                        () => {
                                            setAnnounceKey('3')

                                            storeAnnouncePage(1)
                                            setIsAnnouncement(false);

                                            togglePageLoader(true)
                                            fetchAnnouncement('', '', () => togglePageLoader(false))
                                        })

                                }}
                            >
                                ANNOUNCE NOW
                            </Button>

                        </div> :
                        <div>
                            {console.log("SS-time", time)}
                            <Button
                                loading={scheduleLoader}
                                disabled={isSelectValue == '' || time == '' || date == '' || storeAnnouncementData == undefined || storeAnnouncementData?.trim().length == 0 || file?.length == 0 || time === '' || date === ""}
                                style={{
                                    borderRadius: "19px",
                                    width: "150px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",

                                }}
                                onClick={() => {
                                    setSchedulerLoader(true)
                                    postAnnouncementData(isSelectValue, std, "SCHEDULE", date, time, file,
                                        () => {

                                            // setIsAnnouncement(false);
                                            setSchedulerLoader(false)


                                        },
                                        () => {
                                            setAnnounceKey('2')

                                            setSchedulePage(1)
                                            setIsAnnouncement(false);
                                            togglePageLoader(true)
                                            fetchAnnouncement('', '', () => togglePageLoader(false))
                                        }
                                    )
                                }}

                            >
                                SCHEDULE
                            </Button>

                        </div>}

                </div>

            </Modal>
        </>
    );
};
const mapStateToProps = (state) => {
    const {
        storeAnnouncementData, standardList, storeKey } = state.announcement;
    return {
        storeAnnouncementData, standardList, storeKey
    };
};

const mapDispatchToProps = (dispatch) => ({

    setAnnouncementData: (res) => dispatch(setAnnouncementData(res)),
    postAnnouncementData: (isSelectValue, std, type, date, time, file, callback, successCallback) => dispatch(postAnnouncementData(isSelectValue, std, type, date, time, file, callback, successCallback)),
    // fetchAnnouncement: (search, sort, callback) => dispatch(fetchAnnouncement(search, sort, callback)),
    setAnnounceKey: (val) => dispatch(setAnnounceKey(val)),
    setDraftPage: (res) => dispatch(setDraftPage(res)),
    setSchedulePage: (res) => dispatch(setSchedulePage(res)),
    storeAnnouncePage: (res) => dispatch(storeAnnouncePage(res)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(CreateAnnouncementModal);