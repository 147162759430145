import React, { useState } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";

function DeleteModal({
  isModalVisible,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,
  type,
  name,
  role,
  loading,
}) {
  return (
    <div>
      <Modal
        confirmLoading={loading}
        maskClosable={false}
        className="deleteUserModal"
        okText={"Yes"}
        
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          {type !== "aproveOrReject"
            ? "Confirm Deletion!"
            : `Confirm ${
                userRequestStatus == "approved" ? "Approval!" : "Rejection!"
              }`}
        </h2>
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#636363",
          }}
        >
          {type !== "aproveOrReject"
            ? `Are you sure you want to delete ${name}?`
            : `Are you sure you want to ${
                userRequestStatus == "approved" ? "approve" : "reject"
              } ${name}?`}
        </p>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
