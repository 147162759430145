import React, { useState } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import {
	deleteCourseWithId,
	getStudentsDetailsById,
} from "actions/userManagement";
import { useHistory } from "react-router";
import { Button } from "components/Button";
import { storeSelectedCourseId } from "actions/course";
import { deleteIndividualTest, deleteSubjectTestSeries, getOnlineTestSeriesCourseDetails } from "actions/courseManagement";
import { getCurriculum } from "actions/curriculum";

function CourseDeleteConfirmModal({
	isConfirmDeleteModalVisible,
	setConfirmDeleteModalVisible,
	deleteCourse,
	togglePageLoader,
	isFromSubjects,
	showEditChapters,
	deleteSubject,
	getSpecificCourseData,
	setIsModalVisible,
	setCreateSubjectModalVisible,
	backToClassroom,
	storeSelectedCourseId,
	courseTypeName,
	getOnlineTestSeriesCourseDetails,
	deleteSubjectTestSeries,
	fromTestSeries,
	deleteIndividualTest,
	
}) {
	const handleCancel = () => {
		setConfirmDeleteModalVisible(false);
	};
	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
	const history = useHistory();
	return (
		<>
			<Modal
				className="modal-round-corner"
				centered
				visible={isConfirmDeleteModalVisible}
				onOk={handleCancel}
				onCancel={handleCancel}
				header={null}
				footer={null}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "20px",
					}}
				>
					<div
						style={{
							fontSize: "20px",
							color: isFromSubjects ? "#191919" : "#FF414D",
							fontWeight: "bold",
						}}
					>
						{isFromSubjects ? "Confirm" : "Confirm delete"}
					</div>
					<div
						style={{
							fontSize: "16px",
							color: "#636363",
							fontFamily: "roboto",
							marginTop: "10px",
							textAlign: "center",
						}}
					>
						{isFromSubjects
							? "Are you sure you want to delete? "
							: "Once you delete a course and all it’s details, you can’t undo it!"}
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-evenly",
						marginTop: "50px",
						marginBottom: 20,
					}}
				>
					{isFromSubjects ? (
						<>
							<Button
								onClick={() => {
									if(!fromTestSeries) setCreateSubjectModalVisible(true);
									handleCancel();
								}}
							>
								NO
							</Button>
							<Button
								loading={deleteBtnLoader}
								type="primary"
								onClick={() => {
									setDeleteBtnLoader(true);

									if (courseTypeName == "onlineTestSeries") {
										if (fromTestSeries)
											deleteIndividualTest(
													() => setDeleteBtnLoader(false),
													() => {
														setConfirmDeleteModalVisible(false),
															togglePageLoader(true);
														getOnlineTestSeriesCourseDetails(() => togglePageLoader(false));
													}
												);
										else
											deleteSubjectTestSeries(
												showEditChapters?.subjectId,
												() => setDeleteBtnLoader(false),
												() => {
													setConfirmDeleteModalVisible(false),
														togglePageLoader(true);
													getOnlineTestSeriesCourseDetails(() => togglePageLoader(false));
												}
											);
									}
									else{
										deleteSubject(
											showEditChapters?.id,
											() => setDeleteBtnLoader(false),
											() => {
						
												// getCurriculum({params}, null, () => togglePageLoader(false),  null);
												getSpecificCourseData(() => togglePageLoader(false));
												setConfirmDeleteModalVisible(false);

											}

											
										);
					
									}
										
								}}
							>
								YES
							</Button>
						</>
					) : (
						<>
							<Button
								loading={deleteBtnLoader}
								style={{
									color: "red",
									fontWeight: "bold",
									fontFamily: "roboto",
									border: "none",
								}}
								onClick={() => {
									setDeleteBtnLoader(true);
									deleteCourse(
										() => setDeleteBtnLoader(false),
										() => {
											setConfirmDeleteModalVisible(false);
											if (backToClassroom) {
												storeSelectedCourseId(null);
												history.push({
													pathname: "/course",
													state: { key: "fromClassroom" },
												});
											} else {
												history.push("/course");
											}
										}
									);
								}}
							>
								DELETE
							</Button>
							<Button
								type="primary"
								onClick={() => {
									setIsModalVisible(true);
									handleCancel();
								}}
							>
								CANCEL
							</Button>
						</>
					)}
				</div>
			</Modal>
		</>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => ({
	storeSelectedCourseId: (val) => dispatch(storeSelectedCourseId(val)),
	getOnlineTestSeriesCourseDetails: (callBack) => dispatch(getOnlineTestSeriesCourseDetails(callBack)),
	deleteSubjectTestSeries: (subjectId, callback, successCallBack) => dispatch(deleteSubjectTestSeries(subjectId, callback, successCallBack)),
	deleteIndividualTest: (callback, successCallBack) => dispatch(deleteIndividualTest(callback, successCallBack)),
	getCurriculum: (params, location, pageLoaderCallback, callback, selectedStandard) => dispatch(getCurriculum(params, location, pageLoaderCallback, callback, selectedStandard)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CourseDeleteConfirmModal);

// export default CourseDeleteConfirmModal;
