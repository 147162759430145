import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import {Radio} from "antd"
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { showTextEditor, updateSubWithChapters, addMarksForQues, chooseCorrectAnswer, storeTestQuesType,
  postQuestionCreate, clearGenerateQuesFields, getEachQuesData, deleteQuesFromServer,
  showGenerateQuesListView,
  unsetCreateTestModalBool,
  updateNumericAnswer,
  renderEditorContent,
  updatePostParams} from 'actions/practiseTest';
import Notification from "services/Notification";
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdFormatListBulleted, MdCloudDownload, MdMoreVert } from "react-icons/md";
import { AiOutlineDownload, AiOutlinePlus, AiOutlineMore, AiFillCaretDown, AiOutlineMinus, AiOutlinePlusCircle,
  AiFillDelete, AiFillCopy} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { ExclamationCircleOutlined, LeftOutlined, LoadingOutlined, MessageOutlined, RightOutlined,ClearOutlined } from '@ant-design/icons';
import './styles.css';
import { Color, Utils } from 'services';
import ReactHtmlParser from 'react-html-parser';
import { Col, Row , Typography, Select, Menu, Dropdown, Input} from 'antd';
import TopNavigationColorHeader from './localComponents/topNavigationColorHeader';
import TextEditorModal from './localComponents/textEditorModal';
import TestPublishModal from './localComponents/testPublishModal';
import CreateTestModal from './modals/createTestModal';
import { Tooltip } from 'antd';
import TeX from '@matejmazur/react-katex';
import ImportQuestionModal from './modals/importQuestionModal';


const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const TestDetails = ({value, text}) => {
  return(
    <div className='r-c-c-c m-r-50'>
      <div className='text-xmd bold-600'>{value}</div>
      <div className='text-xs'>{text}</div>
    </div>
  )
}

const CustomeDropdown = ({defaultvalue, placeholder, onchange, arrdata, disable=false}) => {
  return(
      <Select
        className='test-custom-select'
        // placeholder={placeholder}
        disabled={disable}
        value={defaultvalue}
        style={{ width: 200 }}
        onChange={onchange}
        suffixIcon={<AiFillCaretDown className='text-sm a2grey'/>}
      >
        {arrdata?.map((item, index) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
  )
}

const Row1 = ({history, generateQuesObj, generateQuesArr, showGenerateQuesListView, showTextEditor,
                generateCommentText, userType, grabAllfield, isEmptyHtml, editGenerateQueId, 
                openImportModal, user, selectedTest, selectedCardType}) => {
  let queCount = (Object.keys(generateQuesArr)?.length && generateQuesArr.subjects?.length)
                 ? generateQuesArr.subjects?.map((item) => item.questions?.length)?.reduce((a,b) => a+b) : 0;
  let totalPoints =  Object.keys(generateQuesArr)?.length ?  generateQuesArr.totalPoints : 0;
  return(
    <div className='r-c-sb p-20'>
      <div className='r-ac'>
        <TestDetails value={queCount} text={queCount===1?'Question':'Questions'} />
        <TestDetails value={`${generateQuesObj.durationInMinutes} mins`} text={`Duration`} />
        <TestDetails value={totalPoints} text={`Marks`} />
      </div>
      <div className='r-ac'>
          {userType=="teacher"&& user.id===generateQuesObj.teacherId&& user.id===generateQuesObj.authorId?
            null
            :
            editGenerateQueId!=undefined && editGenerateQueId!==''?
            userType=="teacher"|| (selectedTest.status==='Review'  && userType==='admin' && user.id===selectedTest.teacherId&&user.id!==selectedTest.authorId) 
            ?
              (isEmptyHtml(generateCommentText))
              ?
              <div onClick={() => {showTextEditor({key:'comment', index:0, tags: generateCommentText})
                                  grabAllfield("savecomment")}} className='r-c-c-c m-r-20 cursor-pointer'>
                        <MessageOutlined  className='text-md color-black'/>
                        <div className='text-xs text-center color-black'>Comment</div>
              </div>
              :
              <div onClick={() => {showTextEditor({key:'comment', index:0, tags: generateCommentText})
                                  grabAllfield("savecomment")}} className='r-c-c-c m-r-20 cursor-pointer'>
                        <MessageOutlined  className='text-md' style={{color:"#FFA931"}}/>
                        <div className='text-xs text-center' style={{color:"#FFA931"}}>Comment</div>
              </div>
            :
              (isEmptyHtml(generateCommentText))
              ?
              null
              :
              <div onClick={() => {showTextEditor({key:'comment', index:0, tags: generateCommentText})
                                    grabAllfield("savecomment")}} className='r-c-c-c m-r-20 cursor-pointer'>
                        <MessageOutlined  className='text-md' style={{color:"#FFA931"}}/>
                        <div className='text-xs text-center' style={{color:"#FFA931"}}>Comment</div>
              </div>
              :
              null
          }
        <div onClick={() => {showGenerateQuesListView(()=>{}, "listview"); history.push('practise-test-create-list-view')}} className='r-c-c-c m-r-20 cursor-pointer'>
          <MdFormatListBulleted className='text-md color-black' />
          <div className='text-xs text-center color-black'>List View</div>
        </div>
        <div  className='r-c-c-c cursor-pointer' onClick={openImportModal}>
          <AiOutlineDownload className='text-md'/>
          <div className='text-xs text-center' >Import</div>
        </div>
      </div>
    </div>
  )
}



const Row2 = ({generateQuesObj, generateQuesSubChapters, updateSubWithChapters, clearGenerateQuesFields,
  deleteQuesFromServer, editGenerateQueId}) => {
  const menu = (
      <Menu>
        {editGenerateQueId === ''?null:
            <Menu.Item key="0" className="m-b-10">
            <div className='r-ac cursor-pointer p-l-20 p-r-20'
              onClick={editGenerateQueId === '' ? () => {Notification.error("error", "Cannot delete questions that are not saved")} : () => deleteQuesFromServer()}>
              <AiFillDelete  className='text-md'/>
              <div className='text-xs color-black m-l-10'>Delete</div>
            </div>
          </Menu.Item>
        }
        <Menu.Item key="1">
          <div className='r-ac cursor-pointer p-l-20 p-r-20' onClick={()=>{clearGenerateQuesFields('Clear');}}>
            <ClearOutlined  className='text-md'/>
            <div className='text-xs color-black m-l-10'>Clear</div>
          </div>
        </Menu.Item>
      </Menu>
    );
  return(
    <div className='r-c-sb p-20'>
      <div className='r-ac' style={{maxWidth: "100%", overflowX:"auto"}}>
        {generateQuesObj.subjectNames?.split(',')?.map((item, index) => (
          <Button onClick={() => {
            updateSubWithChapters(item);
            clearGenerateQuesFields();
          }}
            className={item === generateQuesSubChapters.name ? 'purpleBtnWrap m-r-20 text-xs' : 'blankGreyBtnWrap m-r-20 text-xs'}>
            {item}
          </Button>
        ))}
      </div>
      <Dropdown overlay={menu}  placement="bottomRight" arrow>
        <MdMoreVert className='text-lg a2grey cursor-pointer'/>
      </Dropdown>
    </div>
  )
}

const Row3 = ({generateQuesObj, generateQuesSubChapters, addMarksForQues, quesPositiveMark, quesNegativeMark,
  storeTestQuesType, generateQuesSelectedTestType, generateQuesSelectedChapter}) => {
  return(
    <div className='p-l-20 p-r-20 r-c-sb'>
      <div className='r-ac'>
        <div className='m-r-30'>
          <div className='text-xmd bold-600 color-black m-b-10'>QuestionType</div>
            <CustomeDropdown
              arrdata={[{id:0, name:'Multiple Choice Questions'}, {id:1, name:'Numeric Type Questions'}]}
              defaultvalue={Object.keys(generateQuesSelectedTestType)?.length ? generateQuesSelectedTestType.children : 'Select Question Type'}
              onchange={(key, value) => storeTestQuesType(value, 'quesType')} />
        </div>
        <div className='m-r-10'>
          <div className='text-xmd bold-600 color-black m-b-10'>Chapter</div>
          <CustomeDropdown
            arrdata={generateQuesSubChapters.chapters}
            disable={generateQuesObj.syllabus==='Chapter Test'?true:false}
            defaultvalue={generateQuesObj.syllabus==='Chapter Test'?generateQuesSubChapters.chapters&&generateQuesSubChapters.chapters?.length>0&&generateQuesSubChapters?.chapters[0]?.name:Object.keys(generateQuesSelectedChapter)?.length ? generateQuesSelectedChapter.children : 'Select Chapter'}
            onchange={(key, value) => storeTestQuesType(value, 'quesChapter')} />
        </div>
      </div>
      <div>
        <div className='r-ac m-b-10'>
          <div className='text-xmd bold-600 color-black m-r-5'>Marks</div>
            <Tooltip placement="right" trigger="hover" title={
              <div>
                <div className='r-ac'>
                  <div className='radius-100' style={{width:10, height:10, background:'#6BDB9E'}}></div>
                  <div className='text-xmd color-black m-l-10'>Positive Marks</div>
                </div>
                <div className='r-ac'>
                  <div className='radius-100' style={{width:10, height:10, background:'#ec5254'}}></div>
                  <div className='text-xmd color-black m-l-10'>Negative Marks</div>
                </div>
              </div>
  
   
  
            } overlayStyle={{borderRadius:"15px"}} overlayInnerStyle={{borderRadius:"15px", padding:"10px 20px", background:'#fff'}}>
                <ExclamationCircleOutlined style={{color:"#1089FF"}} className='text-xs cursor-pointer'/>
            </Tooltip>
        </div>
        <div className='r-ac'>
            <div className='r-c-c test-count-seq-box'>
              <AiOutlinePlus onClick={() => addMarksForQues('increment', 'positive')} className='cursor-pointer'/>
              <div className='text-xmd m-l-5 m-r-5'>{quesPositiveMark}</div>
              <AiOutlineMinus onClick={() => addMarksForQues('decrement', 'positive')} className='cursor-pointer'/>
            </div>
            <div className='r-c-c test-count-seq-box m-l-10' style={{background:'#FFE4D9', borderColor:'#EC5254'}}>
              <AiOutlinePlus onClick={() => addMarksForQues('increment', 'negative')} className='cursor-pointer'/>
              <div className='text-xmd m-l-5 m-r-5'>{quesNegativeMark}</div>
              <AiOutlineMinus onClick={() => addMarksForQues('decrement', 'negative')} className='cursor-pointer'/>
            </div>
        </div>
      </div>
    </div>
  )
}

const Row4 = ({showTextEditor, generateQuesText}) => {
  return(
    <div className='p-20'>
      <div className='text-xmd bold-600 color-black m-b-5'>Question</div>
      <div onClick={() => showTextEditor({key:'question', index:0, tags:generateQuesText})} 
            className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
            style={{overflowX:"hidden"}}>
            {ReactHtmlParser(generateQuesText,
              { transform: (node) => {
              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                  return <TeX math={`${node.attribs['data-value']}`} />;
              }
              if (node.type === 'tag' && node.name === 'img') {
                  return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
              }
              }})
          }
      </div>
    </div>
  )
}

const Row5 = ({showTextEditor, generateQuesOptions, chooseCorrectAnswer}) => {
  return(
    <div className='p-l-20 p-r-20'>
      <div className='text-xmd bold-600 color-black m-b-5'>Options</div>
      {generateQuesOptions?.map((item, index) => (
        <div className='r-ac m-b-10' onClick={() => showTextEditor({key:'options', index:index, tags:item.value})} key={index}>
          <div
            style={{background:item.correct ? '#28df99' : '', color:item.correct ? '#fff' : '', borderColor: item.correct ? '#28df99' : ''}}
            className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
            {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'}
          </div>
          <div
            style={{background:item.correct ? '#E9FCF5' : '', width:"100%", overflowX:"hidden"}} 
            className='cursor-pointer test-ques-window p-10 r-c-sb'
            >
            <div className='resize-editor-img'>
                          {ReactHtmlParser(item.value,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
            </div>
            <div onClick={(e) => {
              e.stopPropagation();
              chooseCorrectAnswer(index);
            }} keys={index.toString()} className='r-ac m-l-5'>
              {item.correct
              ?
                <RiCheckboxCircleLine className='text-sm seaGreen' />
              :
                <RiCheckboxBlankCircleLine className='text-sm a2grey' />
              }
              <div className='m-l-5 text-xs a2grey'>Correct answer</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const RowNumeric = ({showTextEditor, generateQuesNumericText, updateNumericAnswer, renderEditorContent, numericAnswer}) => {
  // const isValidNumeric = (val) => {
  //   let pattern1 = /[^\d.-]+/;
  //   let pattern2 = /^[^.]+(\.{0}[^.]*|\.{1}[^.]{0,})$/;
  //   let pattern3 = /[^-]+[-]+/;
  //   let pattern4 = /[-]{2,}/
  //   return val==="" || (pattern2.test(val) && !pattern1.test(val) && !pattern3.test(val) && !pattern4.test(val));
  // }
  return(
    <div className='p-20 '>
      <div className='text-xmd bold-600 color-black m-b-5'>Answer</div>
      <Input
          placeholder="Type the answer here..."
          value={numericAnswer}
          onChange={(e)=>{
            // if (isValidNumeric(e.target.value)){
              updateNumericAnswer(e.target.value)
            // }
          }}
          type={'number'}
          style={{borderRadius:5}}
        />
    </div>
  )
}
const Row6 = ({showTextEditor, generateSolutionText}) => {
  return(
    <div className='p-20 p-b-50'>
      <div className='text-xmd bold-600 color-black m-b-5'>Solution</div>
      <div onClick={() => showTextEditor({key:'solution', index:0, tags:generateSolutionText})} 
           className='cursor-pointer test-ques-window resize-editor-img p-10'
           style={{width:"100%", overflowX:"hidden"}}>
                  {ReactHtmlParser(generateSolutionText,
                        { transform: (node) => {
                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                            return <TeX math={`${node.attribs['data-value']}`} />;
                        }
                        if (node.type === 'tag' && node.name === 'img') {
                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                        }
                        }})
                    }
      </div>
    </div>
  )
}

const QueGridSide = ({generateQuesObj, generateQuesArr, updateSubWithChapters, clearGenerateQuesFields,
  generateQuesSubChapters, getEachQuesData, editGenerateQueId, getEachQuesDataBool, updateNumericAnswer, selectedCardType}) => {

  return(
    <div>
      {generateQuesArr && Object.keys(generateQuesArr)?.length ?
        generateQuesArr.subjects?.map((item) => (
        <div keys={item.subjId}>
          <div className='text-xs bold-600 color-black m-b-10'>{item.subName}</div>
          <div className='r-ac flex-wrap' style={{marginLeft:-10}}>
            {item.questions?.map((quee, index) => (
              <div
                key={index}
                onClick={() => {
                  updateNumericAnswer('');
                  getEachQuesData(quee.id);
                }}
                style={{borderColor:quee.id === editGenerateQueId ? '#636363' : item.comments!=null?item.comments.slice()[index]==true?'orange':'':'', 
                        borderWidth: quee.id === editGenerateQueId ? 2 : 1,
                      color: item.comments!=null?item.comments.slice()[index]==true?'orange':'black':'black'}}
                className='r-c-c radius-100 test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
                {(getEachQuesDataBool && quee.id=== editGenerateQueId) ? <LoadingOutlined /> : index+1}
              </div>
            ))}
            <div
              onClick={() => {
                updateSubWithChapters(item.subName);
                clearGenerateQuesFields();
              }}
              style={{borderColor:item.subName === generateQuesSubChapters.name ? '#636363' : '', borderWidth: item.subName === generateQuesSubChapters.name ? 2 : 1}}
              className='r-c-c radius-100 a2grey text-sm test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
              <AiOutlinePlus style={{color:item.subName === generateQuesSubChapters.name ? '#191919' : ''}}/>
            </div>
          </div>
        </div>
      ))
      : null
      }

    </div>
  )
}





function PractiseTestGenerateQuestions ({history, generateQuesObj, showTextEditor, generateQuesSubChapters,
  updateSubWithChapters, addMarksForQues,  quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, chooseCorrectAnswer, generateSolutionText, storeTestQuesType,
  generateQuesSelectedTestType, generateQuesSelectedChapter, postQuestionCreate, generateQuesRemovedImgs,
  generateQuesArr, clearGenerateQuesFields, getEachQuesData, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, deleteQuesFromServer, postQuestionCreateBool, showGenerateQuesListView, unsetCreateTestModalBool,
  generateCommentText, userType, updateNumericAnswer, renderEditorContent, numericAnswer, updatePostParams, selectedCardType, 
  user, selectedTest, textEditorBool, createTestModalBool}) {

 
    const [isImportModalVisible, setImportModalVisible] = useState(false);
    const closeImportModal = () =>{
      setImportModalVisible(false);
    }
    const openImportModal = () => {
      setImportModalVisible(true);
    }
    const [showEditorModal, setShowEditorModal] = useState(false);
    const isEmptyHtml = (content) => {
      let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
      return pattern.test(content);
    }
    const isAnyOptionEmpty = (index) => isEmptyHtml(generateQuesOptions[index].value);
    const grabAllfield = (type) => {
      let obj = generateQuesOptions?.filter((item, index) => item.correct === true);
      let checkQuesType = generateQuesSelectedTestType.children === 'Multiple Choice Questions';

      if (type=="savenext"){
      if(!Object.keys(generateQuesSelectedTestType)?.length){
        return  Notification.error("Error", 'Please select question type');
      }else if(!Object.keys(generateQuesSelectedChapter)?.length && generateQuesObj.syllabus!=='Chapter Test'){
        return  Notification.error("Error", 'Please select any chapter');
      }else if(quesPositiveMark === 0){
        return  Notification.error("Error", 'Please add marks for the question');
      }else if(isEmptyHtml(generateQuesText)){
        return  Notification.error("Error", 'Please add question');
      }else if(([0,1,2,3].some(isAnyOptionEmpty)) && (generateQuesSelectedTestType.children === 'Multiple Choice Questions')){
        return  Notification.error("Error", 'Please add options');
      }else if(!obj?.length && (generateQuesSelectedTestType.children === 'Multiple Choice Questions')){
        return  Notification.error("Error", 'Please select any correct option');
      }
      else if ((generateQuesSelectedTestType.value!=0)&&((numericAnswer==''))){
        return  Notification.error("Error", 'Please add a valid answer');
      }}
      let params = {
        statement: generateQuesText,
        options: checkQuesType ? generateQuesOptions : [],
        answer: checkQuesType ? obj[0].id : numericAnswer,
        type: generateQuesSelectedTestType.value === 0 ? 'MCQ' : 'Numeric',
        positiveMarks: quesPositiveMark,
        negativeMarks: quesNegativeMark,
        deleteImages: [],
        testId: generateQuesObj.id,
        chapterId: generateQuesObj.syllabus==='Chapter Test'?generateQuesSubChapters?.chapters[0]?.id:generateQuesSelectedChapter.value,
        solution: generateSolutionText,
      }
      if(editGenerateQueId !== ''){
        params.id = editGenerateQueId;
      }
      if((generateCommentText != "") && (userType == "teacher")){
        params.remark = generateCommentText;
        params.markedForReview = true;
      }
      updatePostParams(params)
      if (type=="savenext"){
      postQuestionCreate(params);}
    }
    return (
      <div style={{height: '100%', margin:'-20px'}}>
        <TopNavigationColorHeader  history={history} type="beforeTest"/>
        <div className='display-flex' style={{height:'calc(100% - 20px)'}}>
          <div className='position-relative' style={{width:"80%"}}>
            <div className='overflow-scroll' style={{height:'calc(100% - 20px)'}}>
              <Row1
                history={history}
                generateQuesObj={generateQuesObj}
                showGenerateQuesListView={showGenerateQuesListView}
                generateQuesArr={generateQuesArr}
                showTextEditor={showTextEditor}
                generateCommentText={generateCommentText}
                userType={userType}
                grabAllfield={grabAllfield}
                editGenerateQueId={editGenerateQueId}
                isEmptyHtml={isEmptyHtml}
                openImportModal={openImportModal}
                user={user}
                selectedCardType={selectedCardType}
                selectedTest={selectedTest}
                />
                
              <div className='horizontal-divider'></div>
              <div className="full-height" style={{backgroundColor:"#fafafa"}}>
              <Row2
                generateQuesObj={generateQuesObj}
                deleteQuesFromServer={deleteQuesFromServer}
                editGenerateQueId={editGenerateQueId}
                clearGenerateQuesFields={clearGenerateQuesFields}
                generateQuesSubChapters={generateQuesSubChapters}
                updateSubWithChapters={updateSubWithChapters}/>
              <Row3
                generateQuesObj={generateQuesObj}
                generateQuesSubChapters={generateQuesSubChapters}
                quesPositiveMark={quesPositiveMark}
                quesNegativeMark={quesNegativeMark}
                storeTestQuesType={storeTestQuesType}
                generateQuesSelectedTestType={generateQuesSelectedTestType}
                generateQuesSelectedChapter={generateQuesSelectedChapter}
                addMarksForQues={addMarksForQues}/>
              <Row4 showTextEditor={showTextEditor} generateQuesText={generateQuesText}/>
              {generateQuesObj.testType === 'Subjective'
              ?
                null
              :
                Object.keys(generateQuesSelectedTestType)?.length
                ?
                  generateQuesSelectedTestType.children === 'Multiple Choice Questions'
                  ?
                    <Row5
                      showTextEditor={showTextEditor}
                      chooseCorrectAnswer={chooseCorrectAnswer}
                      generateQuesOptions={generateQuesOptions}/>
                  :
                    <RowNumeric showTextEditor={showTextEditor} 
                                generateQuesNumericText={generateQuesNumericText}
                                updateNumericAnswer={updateNumericAnswer}
                                renderEditorContent={renderEditorContent}
                                numericAnswer={numericAnswer}
                                />
                :
                  null
                }

              <Row6 showTextEditor={showTextEditor} generateSolutionText={generateSolutionText}/>
              </div>
            </div>
            {
              userType=='admin'||generateQuesObj.status!=='Published' || userType=='teacher'
              ?
              <div className='p-20 test-save-sticky-footer r-c-c'>
              {/* {<div className='text-xmd bold-700 text-center r-c-fs' style={{color:"#636363", width:"33%"}}> <div className="cursor-pointer">{postQuestionCreateBool?<LoadingOutlined className="m-r-5"/>:<LeftOutlined className="m-r-5"/>} PREVIOUS</div></div>} */}
             {generateQuesObj.status!="Published"? <div className='text-xmd bold-600 blue text-center r-c-c' style={{width:"33%"}}> <div className="cursor-pointer" onClick={postQuestionCreateBool ? () => {} : ()=>{grabAllfield('savenext')}}>{postQuestionCreateBool ? <LoadingOutlined className='m-r-10' style={{color:'#1089ff'}}/> : null} SAVE & NEXT </div></div>:null}
              {/* {editGenerateQueId===''?null:<div className='text-xmd bold-700 text-center cursor-pointer r-c-fe' style={{color:"#636363", width:"33%"}}> <div className="cursor-pointer">NEXT {postQuestionCreateBool?<LoadingOutlined className="m-l-5"/>:<RightOutlined className="m-l-5"/>} </div></div>} */}
            </div>
            :
            null
            }
          </div>
          <div className='vertical-divider'></div>
          <div className='p-20 overflow-scroll' style={{width:"20%"}}>
            <div className='text-xs a2grey bold-600 m-b-20'>ALL QUESTIONS</div>
            <QueGridSide
              generateQuesObj={generateQuesObj}
              generateQuesArr={generateQuesArr}
              updateSubWithChapters={updateSubWithChapters}
              clearGenerateQuesFields={clearGenerateQuesFields}
              generateQuesSubChapters={generateQuesSubChapters}
              getEachQuesData={getEachQuesData}
              editGenerateQueId={editGenerateQueId}
              getEachQuesDataBool={getEachQuesDataBool}
              updateNumericAnswer={updateNumericAnswer}
              selectedCardType={selectedCardType}
               />
          </div>
        </div>
        {
          textEditorBool && (
        <TextEditorModal visible={showEditorModal} closeModal={() => setShowEditorModal(false)}/>
        )}
        <TestPublishModal history={history}/>
        {
          createTestModalBool && (
            <CreateTestModal history={history} />
          )
        }
        <ImportQuestionModal visible={isImportModalVisible} onCancel={closeImportModal}/>
      </div>
  );
}


const mapStateToProps = state => {
  const {generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
  generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, postQuestionCreateBool, generateCommentText, userType, numericAnswer, selectedCardType, selectedTest, textEditorBool, createTestModalBool} = state.practiseTest;
  const {user} = state.session;
  return {generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
  generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, postQuestionCreateBool, generateCommentText, userType, numericAnswer, selectedCardType, user, selectedTest, textEditorBool, createTestModalBool};
};

const mapDispatchToProps = dispatch => ({
  showTextEditor: (name) => dispatch(showTextEditor(name)),
  updateSubWithChapters: (name) => dispatch(updateSubWithChapters(name)),
  addMarksForQues: (operation, name) => dispatch(addMarksForQues(operation, name)),
  chooseCorrectAnswer: (index) => dispatch(chooseCorrectAnswer(index)),
  storeTestQuesType: (obj, name) => dispatch(storeTestQuesType(obj, name)),
  postQuestionCreate: (params) => dispatch(postQuestionCreate(params)),
  clearGenerateQuesFields: (val) => dispatch(clearGenerateQuesFields(val)),
  getEachQuesData: (id) => dispatch(getEachQuesData(id)),
  deleteQuesFromServer: () => dispatch(deleteQuesFromServer()),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
  unsetCreateTestModalBool: ()=>dispatch(unsetCreateTestModalBool()),
  updateNumericAnswer: (newAnswer)=>dispatch(updateNumericAnswer(newAnswer)),
  renderEditorContent: (name, value) => dispatch(renderEditorContent(name, value)),
  updatePostParams: (newParams)=>dispatch(updatePostParams(newParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(PractiseTestGenerateQuestions);
