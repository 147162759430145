import Axios from "axios";
import fileDownload from "js-file-download";
import { Api } from "../services";
import { getStudentsDetailsById } from "./userManagement";

export const SET_PAYREQUEST_STUDENT = "SET_PAYREQUEST_STUDENT";
export const SET_FEE_DETAILS = "SET_FEE_DETAILS";
export const SET_All_ACTIVITIES = " SET_All_ACTIVITIES";
export const SET_OVERVIEW_FILTER = "SET_OVERVIEW_FILTER";
export const SET_SELECTED_STD = "SET_SELECTED_STD";
export const SET_INVOICE_FILE = "SET_INVOICE_FILE";
export const SET_STUDENT_NAME = "SET_STUDENT_NAME"
export const SET_WHICH_VIEWALL_CLICKED = 'SET_WHICH_VIEWALL_CLICKED'
export const STORE_STUDENTS_BY_COURSE = 'STORE_STUDENTS_BY_COURSE'
export const STORE_VIEWALL_DATA = 'STORE_VIEWALL_DATA'
export const STORE_COURSEDETAILS_FEE = 'STORE_COURSEDETAILS_FEE'
export const STORE_RESOURCE_DATA = 'STORE_RESOURCE_DATA'
export const STORE_STANDARD_DATA = 'STORE_STANDARD_DATA'
export const RECENT_ACTIVITES_VA_FROM_WHERE = 'RECENT_ACTIVITES_VA_FROM_WHERE'
export const STORE_ALL_STUDENTS_BY_COURSE_DETAILS = 'STORE_ALL_STUDENTS_BY_COURSE_DETAILS'
export const STORE_GRAPH_DATA = 'STORE_GRAPH_DATA'
export const STORE_FILTER_DETAILS = 'STORE_FILTER_DETAILS'

export const setPayRequestStd = () => { };
// export const setFeeDetails = (key, res) => {
//   type: SET_PAYREQUEST_STUDENT, key, res
// };
export const setFeeDetails = (key, res) => ({
  type: SET_FEE_DETAILS,
  res,
  key,
});

export const setAllActivitiesFilter = (key, res) => ({
  type: SET_All_ACTIVITIES,
  res,
  key,
});

export const setOverviewFilter = (key, res) => ({
  type: SET_OVERVIEW_FILTER,
  res,
  key,
});

export const setSelectedStd = (data) => ({
  type: SET_SELECTED_STD,
  data,
});

export const setInvoiceFile = (file) => ({
  type: SET_INVOICE_FILE,
  file,
});

export const setStudentName = (val) => ({
  type: SET_STUDENT_NAME,
  val
})

export const setWhichViewAllClicked = val => ({
  type: SET_WHICH_VIEWALL_CLICKED,
  val
})

export const storeStudentsByCourse = res => ({
  type: STORE_STUDENTS_BY_COURSE,
  res
})

export const storeViewAllData = (key, val) => ({
  type: STORE_VIEWALL_DATA,
  key, val
})

export const storeCourseDetailsFee = res => ({
  type: STORE_COURSEDETAILS_FEE,
  res
})

export const storeResourceData = res => ({
  type: STORE_RESOURCE_DATA,
  res
})

export const storeStandardData = res => ({
  type: STORE_STANDARD_DATA,
  res
})

export const recentActivitiesVAFromWhere = val => ({
  type: RECENT_ACTIVITES_VA_FROM_WHERE,
  val
})

export const storeAllStudentByCourseDetails = (key, val) => ({
  type: STORE_ALL_STUDENTS_BY_COURSE_DETAILS,
  key, val
});

export const storeGraphDetails = res => ({
  type: STORE_GRAPH_DATA,
  res
})

export const storeFilterDetails = (key, val) => ({
  type: STORE_FILTER_DETAILS,
  key, val
})

export const getFeeOverView = (callback = null, page, id) =>
  (dispatch, getState) => {
    const { overviewFilter } = getState().feeManagement;
    return Api.get("/feev2").params(overviewFilter).send((response) => {
      if (callback) {
        callback();
      }

      if (response !== undefined) {
        dispatch(setFeeDetails("dashBoardDetails", response));
      }
    });
  };

export const fetchStandards = (callback) => (dispatch, getState) => {

  const url = "/feev2/byStandards";

  return Api.get(url).send((response) => {
    if (callback) {
      callback();
    }
    if (response !== undefined) {
      dispatch(setFeeDetails("standards", response));
    }
  });
};

export const getAllCLasses = (callback = null, page, id) =>
  (dispatch, getState) => {
    const { search } = getState().course;

    const params = {
      search: search,
      page,
    };

    return Api.get("/feev2/byStandards")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(setFeeDetails("allClasses", response));
          console.log("responser", response);
        }
      });
  };

export const getUserOfClass = (callback = null) =>
  (dispatch, getState) => {
    const { search } = getState().course;
    const { feeMangementData, studentName } = getState().feeManagement;
    console.log("callnack", callback);
    const params = {
      search: studentName,
      standardId: feeMangementData?.selectedClassId,
    };

    return Api.get("/feev2/getUser")
      .params(params)

      .send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(setFeeDetails("allStudents", response));
        }
      });
  };
export const getTransactionsOfStudent = (callback = null, studentDesc) =>
  (dispatch, getState) => {
    const { search } = getState().course;
    const { feeMangementData } = getState().feeManagement;
    console.log("callnack", callback);
    const params = {
      search: studentDesc,
    };
    // standardId: feeMangementData?.selectedClassId,

    return Api.get(
      `/feev2/transactions-by-user/${feeMangementData?.selectedClassId}/${feeMangementData?.selectedStudentId[0]}`
    )
      .params(params)

      .send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          dispatch(setFeeDetails("allTransactionsOfStudent", response));
          // dispatch(setFeeDetails("allClasses", response));
          console.log("responser", response);
        }
      });
  };

export const updateSettings =
  (val, invoiceType, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
      const params = {
        transactionPaidBy: val == 1 ? "student" : "institute",
        invoiceType: invoiceType
      };

      return Api.post("/feev2/updateSettings", params).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          // dispatch(setFeeDetails("allClasses", response));
          console.log("responser", response);
          successCallBack();
        }
      });
    };

export const generatePayRequest =
  (val, sendInvoice, callback = null, successCallBack = null, payType = null) =>
    (dispatch, getState) => {
      const { feeMangementData, resourceData } = getState().feeManagement;

      // standardId: feeMangementData?.selectedClassId,
      const params = {
        amount: feeMangementData?.finalFeeRequested,
        note: payType
          ? feeMangementData?.selectedStudentDesc
          : feeMangementData?.generatePayDescription,
        paymentType: payType ? "offline" : "online",
        paymentDate: payType ? feeMangementData?.paidDate : null,
        paymentMethod: payType ? feeMangementData?.modeOfPay : null,
        discountDetails: feeMangementData?.generatePayDiscount
          ? JSON.stringify(feeMangementData?.generatePayDiscount)
          : null,
        additionDetails: feeMangementData?.generatePayAdditions
          ? JSON.stringify(feeMangementData?.generatePayAdditions)
          : null,
        sendInvoice
      };
      // if (feeMangementData?.modeOfPay == "Cheque") {
      params["chequeNo"] = feeMangementData?.generateOfflinePayCheckNumber;
      // }
      if (payType) {
        params["dueDate"] = "";
      } else {
        params["dueDate"] = feeMangementData?.generatePayDate;
      }
      if (val == 1) {
        params["userIds"] = feeMangementData?.selectedStudentId;
        params["standardId"] = feeMangementData?.selectedClassId == 'unassigned' ? -1 : feeMangementData?.selectedClassId;
        if (window.location.pathname == '/fee-management' || window.location.pathname == '/fee-management/all-transactions') {
          // send nothing
        } else {
          params['courseId'] = resourceData?.id
        }

        return Api.post("/feev2", params).send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            // dispatch(setFeeDetails("allClasses", response));
            console.log("responser", response);
            successCallBack();
          }
        });
      } else {
        params["standardId"] = feeMangementData?.selectedClassId == 'unassigned' ? -1 : feeMangementData?.selectedClassId;
        return Api.post("/feev2/standard", params).send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            // dispatch(setFeeDetails("allClasses", response));
            console.log("responser", response);
            successCallBack();
          }
        });
      }
    };
export const fetchTransaction = (callback) => (dispatch, getState) => {
  const { allActivitiesFilter, recentActivitiesVAfromWhere } = getState().feeManagement;
  console.log({ allActivitiesFilter })

  const url = recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? '/feev2/resource-transactions' : "/feev2/allTransactions";
  return Api.get(url).params(allActivitiesFilter).send((response) => {
    if (callback) {
      callback();
    }

    if (response !== undefined) {
      console.log(response, 'here response')
      if (recentActivitiesVAfromWhere == 'fromInsideLiveCourses') {
        dispatch(setFeeDetails('allActiviies', response))
      } else {
        dispatch(setFeeDetails("allActiviies", response.response));
      }
    }
  });
};

export const refundedApi =
  (callback, successCallBack = null) =>
    (dispatch, getState) => {
      const { allActivitiesFilter, feeMangementData } = getState().feeManagement;
      const url = "/refund/req";
      const param = {
        paymentId: feeMangementData?.selectedTransaction?.paymentId,

        receipt: feeMangementData?.selectedTransaction?.receipt,
        note: `refund ${feeMangementData?.selectedTransaction?.note
          ? feeMangementData?.selectedTransaction?.note
          : ""
          }`,
      };
      // note: "Fee Refund",
      return Api.post(url, param)
        .params(param)
        .send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            dispatch(getStudentsDetailsById());
            dispatch(getFeeOverView());
            // dispatch(setFeeDetails("allActiviies", response.response));
            // console.log("responser", response);
          }
        });
    };

export const cancelTransaction =
  (callback, successCallBack = null) =>
    (dispatch, getState) => {
      const { allActivitiesFilter, feeMangementData } = getState().feeManagement;
      const url = `/feev2/cancel-request/${feeMangementData?.selectedTransaction?.id}`;

      // note: "Fee Refund",
      return Api.patch(url).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(setFeeDetails("allActiviies", response.response));
          // console.log("responser", response);
        }
      });
    };

export const fetchDownloadReport =
  (object, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
      const { feeMangementData } = getState().feeManagement;
      return Api.get("/feev2/download-report").params(object).send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          let fileName = response.path.split('/')[response.path.split('/').length - 1];
          Axios.get(response.path, {
            responseType: "blob",
          }).then((res) => {
            if (successCallBack) {
              successCallBack();
            }
            fileDownload(res.data, fileName);
          });
        }
      });
    };

export const postOfflineTransaction =
  (sendInvoice, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
      const { feeMangementData } = getState().feeManagement;
      let object = {
        transactionId: feeMangementData?.selectedStudentDescId,
        paymentMethod: feeMangementData?.modeOfPay,
        chequeNo: feeMangementData?.generateOfflinePayCheckNumber,
        paymentDate: feeMangementData?.paidDate,
        sendInvoice

      };
      return Api.post("/feev2/update-offline-transactions")
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            if (window.location.pathname == '/fee-management' || window.location.pathname == '/fee-management/all-transactions') {
              // DO NOTHING;
            } else {
              dispatch(getStudentsDetailsById())
            }
          }
        });
    };

export const postSendInvoice =
  (id) =>
    (dispatch, getState) => {
      const { feeMangementData } = getState().feeManagement;
      let object = {
        transactionId: id,
      };
      return Api.get("/feev2/send-invoice")
        .params(object)
        .send((response) => {
          if (response !== undefined) {

            // dispatch(setFeeDetails("allClasses", response));
            // dispatch(getStudentsDetailsById())
          }
        });
    };



export const deleteTransaction =
  (transactionPage, storeId, callback, successCallBack = null) =>
    (dispatch, getState) => {
      const { allActivitiesFilter, feeMangementData } = getState().feeManagement;
      const { storeTransactionData } = getState().profileNew

      const url = `/feev2/delete-request/${transactionPage ? storeId?.id : feeMangementData?.selectedTransaction?.id}`;
      // console.log("AA-transection",storeId?.id);

      // note: "Fee Refund",
      return Api.patch(url).send((response) => {
        if (callback) {
          callback();
        }

        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(setFeeDetails("allActiviies", response.response));
          // console.log("responser", response);
        }
      });
    };

export const fetchZipDownloadReport =
  (callback = null, successCallBack = null) =>
    (dispatch, getState) => {
      const { feeMangementData, overviewFilter } = getState().feeManagement;
      return (
        Api.get(`/feev2/download-invoices/${overviewFilter?.userId}`)
          // .params(object)
          .send((response) => {
            if (callback) {
              callback();
            }
            // console.log("response", response);
            if (response !== undefined && response?.show?.type != "error") {
              // console.log("responser", response?.url);
              Axios.get(response.url, {
                responseType: "blob",
              }).then((res) => {
                if (successCallBack) {
                  successCallBack();
                }
                fileDownload(res.data, response?.name);
              });
            }
          })
      );
    };

export const sendReminder = (callback, successCallBack = null, transId = null) => (dispatch, getState) => {
  const { allActivitiesFilter, feeMangementData } =
    getState().feeManagement;
  let specificUserId = null;
  if (window.location.pathname === '/user-management/profile') {
    specificUserId = getState().userManagementV2.specificUserId;
  } else {
    specificUserId = getState().feeManagement?.overviewFilter?.id;
  }
  const params = {
    userId: specificUserId,
    transactionId: feeMangementData?.selectedTransaction?.id,
    transactionId: transId ? transId : undefined
  };
  const url = "/feev2/send-fee-reminder";

  // note: "Fee Refund",
  return Api.get(url)
    .params(params)
    .send((response) => {
      if (callback) {
        callback();
      }

      if (response !== undefined) {
        if (successCallBack) {
          successCallBack();
        }
        // dispatch(setFeeDetails("allActiviies", response.response));
        // console.log("responser", response);
      }
    });
};

export const getAllSettlements =
  (page = 1, callback, successCallBack = null) =>
    (dispatch, getState) => {
      const { allActivitiesFilter, overviewFilter, feeMangementData } =
        getState().feeManagement;
      const params = {
        page: page,
        limit: 20,
      };
      const url = "/feev2/get-all-settlements";

      // note: "Fee Refund",
      return Api.get(url)
        .params(params)
        .send((response) => {
          if (callback) {
            callback();
          }

          if (response !== undefined) {
            if (successCallBack) {
              successCallBack();
            }
            dispatch(setFeeDetails("allSettlements", response));
            // console.log("responser", response);
          }
        });
    };

// export const setStudentName = (val) => ({
//   type:SET_STUDENT_NAME,
//   val
// })




export const fetchStudentsByCourse = (params, callBack) => {
  return (dispatch, getState) => {
    const url = `/course/resources`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeStudentsByCourse(response))
        }
        callBack();
      })
    );
  };
};

export const fetchCourseVA = (params, callBack) => {
  params.published = Array.isArray(params?.published) ? params?.published[0] : params?.published;

  return (dispatch, getState) => {
    const url = `/course/filter-course`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeViewAllData('Live Courses', response))
        }
        callBack();
      })
    );
  };
};

export const fetchVideoResourcesVA = (params, callBack) => {
  return (dispatch, getState) => {
    const url = `/course/filter-video-resource`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeViewAllData('Video Resources', response))
        }
        callBack();
      })
    );
  };
};

export const fetchTestSeriesVA = (params, callBack) => {
  return (dispatch, getState) => {
    const url = `/testSeries/filter-test-series`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeViewAllData('Online Test Series', response))
        }
        callBack();
      })
    );
  };
};

export const fetchStudyMaterialsVA = (params, callBack) => {
  return (dispatch, getState) => {
    const url = `/course/filter-study-material`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeViewAllData('Study Materials', response))
        }
        callBack();
      })
    );
  };
};

export const fetchCourseDetails = (params, callBack) => {
  return (dispatch, getState) => {
    const url = `/feev2/resource-fee-details`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeCourseDetailsFee(response))
        }
        callBack();
      })
    );
  };
};

export const fetchGraphData = (params, callBack) => {
  return (dispatch, getState) => {
    const url = `/feev2/resource-transaction-summary`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(storeGraphDetails(response))
        }
        callBack();
      })
    );
  };
};


