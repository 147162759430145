import React, { useState, useEffect, useRef } from 'react'
import { Modal, Table, Tooltip, Typography, message } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { Button } from 'components/Button'
import { Heading } from 'components/Typography'
import { Notification } from 'services'
import { fetchExcelData, storeExcelUploadDetails, uploadBulkUsers, deleteExcelOnComplete } from 'actions/userManagementV2'
import { connect } from 'react-redux'
import { CheckCircleOutlined, ClockCircleOutlined, WarningOutlined } from '@ant-design/icons'

const importIcon = require('../../../Assets/userManagementV2/importIcon.png').default;
const xlsIcon = require('../../../Assets/userManagementV2/xlsIcon.png').default;

const ImportUser = ({ open, onCancel, storeExcelUploadDetails, excelFile, uploadBulkUsers, fetchExcelData, pendingUsers, deleteExcelOnComplete }) => {

        const [documentUploadLoader, updateDocUpdateLoader] = useState(false);
        const [currentView, setCurrentView] = useState(1)

        useEffect(() => { setCurrentView(1); storeExcelUploadDetails(null) }, []);

        function useInterval(callback, delay) {
                const savedCallback = useRef();

                // Remember the latest callback.
                useEffect(() => {
                        savedCallback.current = callback;
                }, [callback]);

                // Set up the interval.
                useEffect(() => {
                        function tick() {
                                savedCallback.current();
                        }
                        if (delay !== null) {
                                let id = setInterval(tick, delay);
                                return () => clearInterval(id);
                        }
                }, [delay]);
        };

        const callFetchExcelData = async () => {
                if (currentView == 2 && pendingUsers?.some(el => el.status === 'Pending')) {
                        await fetchExcelData(() => { })
                };
        };

        useInterval(() => {
                callFetchExcelData();
        }, 2000);

        const handleUploadDocument = (file) => {
                storeExcelUploadDetails(file);
        }

        const uploadingProgress = () => {
                const hide = message.loading('Upload in progress..', 0);
                // Dismiss manually and asynchronously
                setTimeout(hide, 2500);
        };

        const handleUploadChange = (info) => {
                const { status } = info.file;
                if (status !== 'uploading') { }
                if (status === 'done' || status === 'error') {
                        uploadingProgress()
                        handleUploadDocument(info.file.originFileObj)
                        setTimeout(() => {
                                message.success(`${info.file.name} file uploaded successfully.`);
                        }, 2000)
                } else if (status === 'error') {
                        Notification.error('error', `${info.file.name} file upload failed.`);
                }
        }

        const props = {
                name: 'file',
                showUploadList: false,
                multiple: true,
                onChange: (info) => handleUploadChange(info),
                onDrop(e) {
                },
                beforeUpload: (file) => {
                        const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        const isLt5M = file.size / 1024 / 1024 < 5;
                        if (!isExcel) {
                                Notification.error('error', "You can only upload xls and xlsx file!");
                        } else if (!isLt5M) {
                                Notification.error('error', "Image must be smaller than 5MB!");
                        }
                        return isExcel && isLt5M;
                }
        };

        function formatBytes(bytes, decimals = 2) {
                if (!+bytes) return '0 Bytes'

                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

                const i = Math.floor(Math.log(bytes) / Math.log(k))

                return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        }

        const handleAddClick = () => {
                updateDocUpdateLoader(true);
                if (excelFile) {
                        uploadBulkUsers(() => {
                                updateDocUpdateLoader(false);
                                setCurrentView(2);
                        }, () => {
                                fetchExcelData(() => { });
                        });
                } else {
                        updateDocUpdateLoader(false);
                }
                if (currentView == 2) {
                        deleteExcelOnComplete();
                        storeExcelUploadDetails(null);
                        onCancel();
                }
        };

        const columns = [
                {
                        title: '#', dataIndex: 'slno', key: 'slno', render: (text, record, idx) => {
                                return (

                                        <div className="roboto bold-600">{idx + 1}</div>
                                )
                        }
                },
                {
                        title: 'Role', dataIndex: 'role', key: 'role', render: (text, record, idx) => {
                                return (
                                        <div className="roboto bold-600">{record?.role?.map(item => item)}</div>
                                )
                        }
                },
                {
                        title: 'Roll No', dataIndex: 'role', key: 'role', render: (text, record, idx) => {
                                return (
                                        <div className="roboto bold-600" >{record?.roll}</div>
                                )
                        }
                },
                {
                        title: 'Standard', dataIndex: 'role', key: 'role', render: (text, record, idx) => {
                                return (
                                        <div className="roboto bold-600" >{record?.standard}</div>
                                )
                        }
                },
                {
                        title: 'Name', dataIndex: 'name', key: 'name', render: (text, record) => {
                                return (
                                        <>
                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%'}} className="color-black bold-600 text-xmd roboto">{text}</Typography.Text>
                                        </>
                                )
                        }
                },
                {
                        title: 'CONTACT', dataIndex: 'phoneNo', key: 'phoneNo', render: (text, record) => {
                                return (
                                        <div>
                                        <div className="simgrey bold-600 text-xmd roboto">{record?.phone}</div>
                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 100 }} className="simgrey bold-600 text-xmd roboto">{record?.email}</Typography.Text>
                                        </div>
                                )
                        }
                },
               
                {
                        title: 'COURSE', dataIndex: 'course', key: 'course', render: (text, record) => {
                                return (
                                        <div className="simgrey bold-600 text-xmd roboto">{record?.course}</div>
                                )
                        }
                },
                {
                        title: 'PRIVILEGES', dataIndex: 'privileges', key: 'privileges', render: (text, record) => {
                                return (
                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 150 }} className="simgrey bold-600 text-xs roboto">{record?.priviliege?.join(', ', " ")}</Typography.Text>
                                )
                        }
                },
                {
                        title: 'STATUS', dataIndex: 'status', key: 'status', render: (text, record) => {
                                return (
                                        <center className="simgrey bold-600 text-xmd roboto" style={{ fontFamily: 'roboto' }}>
                                                {record?.status == "Fail" ?
                                                        <div style={{ color: '#FF414D', cursor: 'pointer', fontFamily: 'roboto' }}>
                                                                <Tooltip placement="right" getPopupContainer={(trigger) => trigger.parentElement} title={record?.failMessage}>
                                                                        <WarningOutlined /> {record?.status?.toUpperCase()}
                                                                </Tooltip>
                                                        </div>
                                                        : record?.status == "Pending" ?
                                                                <div style={{ color: '#FFA931', fontFamily: 'roboto' }}>
                                                                        <ClockCircleOutlined /> {record?.status?.toUpperCase()}
                                                                </div>
                                                                : record?.status == 'Success' ?
                                                                        <div style={{ color: '#28DF99', fontFamily: 'roboto' }}>
                                                                                <CheckCircleOutlined /> {record?.status?.toUpperCase()}
                                                                        </div>
                                                                        : null}
                                        </center>
                                )
                        }
                },
        ];

        return (
                <Modal
                        centered
                        closable
                        open={open}
                        footer={null}
                        className='modal-round-corner'
                        onCancel={onCancel}
                        width={currentView == 2 ? 1000 : 800}
                        style={{ marginTop: -90, height: 500 }}
                >
                        <div className='p-20'>
                                <center><Heading>Import usersdds</Heading></center>
                        </div>
                        <center className="m-t-5 p-10">


                                {currentView == 1 ? excelFile ? (
                                        <Dragger {...props} style={{ borderColor: "#0086FF", backgroundColor: '#E7F3FF' }}>
                                                <>
                                                        <p className="ant-upload-drag-icon">
                                                                <img style={{ height: 40, width: 40 }} src={xlsIcon} />
                                                        </p>
                                                        <div>{excelFile?.name}</div>
                                                        <div>{formatBytes(excelFile?.size)}</div>
                                                </>
                                        </Dragger>
                                ) : (
                                        <>
                                                <Dragger {...props} style={{ borderColor: "#0086FF", backgroundColor: '#E7F3FF' }}>

                                                        <p className="ant-upload-drag-icon">
                                                                <img src={importIcon} />
                                                        </p>
                                                        <p className="ant-upload-text">{excelFile?.name}</p>
                                                        <p className="ant-upload-text">Drag and drop your files here</p>
                                                        <div className="bold-700 text-xmd m-t-10">
                                                                OR
                                                        </div>
                                                        <center>
                                                                <Button type='link' style={{ fontSize: 16 }} className='m-t-20'>BROWSE FILES</Button>
                                                                <p className="ant-upload-hint">
                                                                        Support files include xls and xlsx.
                                                                </p>
                                                        </center>
                                                </Dragger>
                                        </>

                                ) : null}

                                {currentView == 2 ?
                                        <>
                                                <div className="m-t-20">
                                                        <Table pagination={false} columns={columns} dataSource={pendingUsers}></Table>
                                                </div>
                                        </>
                                        : null
                                }

                                <Button disabled={excelFile || currentView == 2 && pendingUsers?.some(el => el?.status === 'Pending') ? false : true} loading={documentUploadLoader} onClick={() => handleAddClick()} type='primary' style={{ marginTop: 20 }}>{currentView == 2 ? 'DONE' : 'ADD'}</Button>
                        </center>
                </Modal>
        )
}

const mapStateToProps = (state) => {
        const { tab, classList, storeAddUserDetails, privilages, excelData, ChooseUploadType, excelFile, pendingUsers } = state.userManagement;
        const { user } = state.session;
        return { tab, classList, storeAddUserDetails, privilages, user, excelData, ChooseUploadType, excelFile, pendingUsers };
};

const mapDispatchToProps = (dispatch) => ({
        storeExcelUploadDetails: (res) => dispatch(storeExcelUploadDetails(res)),
        uploadBulkUsers: (callback, successCallBack) => dispatch(uploadBulkUsers(callback, successCallBack)),
        fetchExcelData: (callback) => dispatch(fetchExcelData(callback)),
        deleteExcelOnComplete: () => dispatch(deleteExcelOnComplete()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ImportUser)