import {
    GET_DASHBOARD_DATA,
    GET_INFO_DATA,
    GET_OVERVIEW_DATA
} from '../actions/dashBoard'

const initialState = {
    dashBoardOverviewData:[],
    storeInfoBoardData:[]
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashBoardOverviewData: action.res,
            }
            
        case GET_INFO_DATA:
            return{
                ...state,
                storeInfoBoardData:action.res
            }

        case GET_OVERVIEW_DATA:
            return{
                ...state, 
                overviewData: action.res
            }

        default:
            return state;
    }
}
export default reducer;
