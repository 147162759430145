

import { CaretDownOutlined, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, MinusOutlined, MoreOutlined, PlusCircleOutlined, PlusOutlined, ShakeOutlined } from '@ant-design/icons';
import { deleteQuestion, postAddQuiz, storeQuizData, postQuizName, } from 'actions/courseManagement';
import { Input, Popover, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from 'components/Button';
import PageHeader from 'components/pageHeader';
import { options } from 'less';
import React, { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { MdFormatListBulleted } from 'react-icons/md';
import { connect } from 'react-redux';
import CreateQuizModal from './createQuizModal';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { disabledUntil } from '@sentry/utils';
import eraser from '../../Assets/aulasMarket/eraser.svg'
import TextEditorModal from 'pages/marketTest/textEditorModal';
import ReactHtmlParser from "react-html-parser";
import TeX from "@matejmazur/react-katex";




const TestDetails = ({ value, text }) => {
    return (
        <div className='r-c-c-c m-r-50'>
            <div className='text-xmd bold-600'>{value}</div>
            <div className='text-xs'>{text}</div>
        </div>
    )
}
export const HtmlComponent = ({ item, toggleImageModal, setCurrentImg }) => {
    return ReactHtmlParser(item, {
        transform: (node) => {
            if (
                node.type === "tag" &&
                node.name === "span" &&
                node.attribs["data-value"]
            ) {
                return <TeX math={`${node.attribs["data-value"]}`} />;
            }
            if (node.type === "tag" && node.name === "img") {
                return (
                    <img
                        alt="question"
                        style={{ maxHeight: "160px", maxWidth: "160px" }}
                        src={node.attribs.src}
                        className="m-t-10 m-r-10 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleImageModal(true);
                            setCurrentImg(node.attribs.src);
                        }}
                    />
                );
            }
        },
    });
};


function AddQuiz({ postAddQuiz, deleteQuestion, storeQuizData, postQuizName, courseTypeName, quizDataContainer, storeFormulaData }) {

    const location = useLocation()
    const history = useHistory();
    const [currentNumber, setCurrentNumber] = useState(0);
    const [reload, setReload] = useState(false)
    const [isMarks, setIsMarks] = useState(false)
    const [valueTf, setValueTf] = useState('')
    const [editModal, toggleEditModal] = useState(false)
    const [sectionData, setSectionData] = useState(location?.state?.fromVideoResourceSection?.updatedSections)
    const [storeSectionIndex, setStoreSectionIndex] = useState(null)
    const [storeTopicIndex, setStoreTopicIndex] = useState(null)
    const [addQuiz, setAddQuiz] = useState('Quiz')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disable, setDisable] = useState(false);
    const [totalMarks, setTotalMarks] = useState(0);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isSaveAndNextClicked, setIsSaveAndNextClicked] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [smTopicsData, setSMTopicsData] = useState(location?.state?.fromStudyMaterial?.studyMaterialsTopics)
    const [textEditorVisible, toggleTextEditor] = useState(false);
    const [editorCallback, setEditorCallback] = useState(() => { });
    const [storeId, setStoreId] = useState('')
    const [textEditorValue, setEditorValue] = useState('');

    useEffect(() => {
        for (let i = 0; i < sectionData?.length; i++) {
            setStoreSectionIndex(i)
            for (let j = 0; j < sectionData[i]?.topics?.length; j++) {
                setStoreTopicIndex(j)
            }
        }
    }, [])
    const [quizArray, setQuizArray] = useState([{
        type: null,
        positiveMarks: 0,
        questionContent: '',
        options: []
    }])

    let questionContent = {}
    questionContent['statement'] = quizArray[0]?.questionContent
    questionContent['options'] = quizArray[0]?.options


    useEffect(() => {
        checkSaveButtonConditions();
    }, [quizArray, currentNumber, isSaveAndNextClicked]);

    useEffect(() => {
        if (location?.state?.fromVideoResourceSection?.editTrue || location?.state?.fromStudyMaterial?.editTrue) {
            const editQuiz = (quizDataContainer?.questions || [])?.map((item) => (
                {
                    id: item?.id,
                    positiveMarks: item?.positiveMarks,
                    questionId: item?.quizId,
                    type: item?.type,

                    questionContent: item?.questionContent?.statement,
                    options: item?.type == 'T/F' ? [
                        {
                            option: 'A',
                            value: 'True',
                            correct: item?.answerContent?.answer,
                        },
                        {
                            option: 'B',
                            value: 'False',
                            correct: !item?.answerContent?.answer,
                        }
                    ] : item?.questionContent?.options.map((item) => ({
                        id: item?.id,
                        value: item?.value,
                        option: item?.option,
                        correct: item?.correct,
                        quizId: item?.quizId
                    }))


                }

            ));
            setTotalMarks(quizDataContainer?.totalMarks)
            setAddQuiz(quizDataContainer?.name)
            setQuizArray(editQuiz)
        }
    }, [location?.state?.fromVideoResourceSection?.editTrue, location?.state?.fromStudyMaterial?.editTrue, quizDataContainer?.id])
    const checkSaveButtonConditions = () => {
        const isAtLeastOneQuestionAdded =
            quizArray?.some(
                (question) =>
                    question?.questionContent?.trim() !== '' &&
                    question?.options?.some((option) => option?.correct && option?.value?.trim() !== '') &&
                    question?.positiveMarks > 0
            ) && isSaveAndNextClicked;
        setIsSaveButtonDisabled(!isAtLeastOneQuestionAdded);
    };
    const handleQuestionType = (index, value) => {
        let temp = quizArray;

        temp[index].type = value
        if (value == 'MCQ' || value == 'MSQ') {
            setDisable(false)
            temp[index].options = [{
                option: 'A',
                id: 0,
                value: null,
                correct: false,

            },
            {
                option: 'B',
                id: 1,
                value: null,
                correct: false,
            },
            {
                option: 'C',
                id: 2,
                value: null,
                correct: false,
            },
            {
                option: 'D',
                id: 3,
                value: null,
                correct: false,
            }
            ]

        } else if (value == 'T/F') {
            console.log('value', value);
            setDisable(true)
            temp[index].options = [
                {
                    option: 'A',
                    value: 'True',
                    correct: false,
                },
                {
                    option: 'B',
                    value: 'False',
                    correct: false,
                }
            ]

        }
        setQuizArray(temp)
        setReload(!reload)

    }

    const updateTotalMarks = () => {
        let total = 0;
        quizArray.forEach((question) => {
            total += question.positiveMarks;
        });
        setTotalMarks(total);
    };

    const handleQuestionChange = (index, value) => {
        let temp = quizArray;
        temp[index].questionContent = value
        setQuizArray(temp)
        setReload(!reload)
    }
    const handleSaveNext = () => {
        let temp = quizArray;
        if (temp?.length == currentNumber + 1 && temp?.length < 10) {
            temp.push(
                {
                    type: null,
                    positiveMarks: 0,
                    questionContent: '',
                    options: []
                }
            )
            setQuizArray(temp)
            setCurrentNumber(currentNumber + 1)
            setReload(!reload)
        } else if (temp?.length < 4) {
            setCurrentNumber(currentNumber + 1)
            setReload(!reload)
        }

        updateTotalMarks();
        setIsSaveAndNextClicked(true);
    }



    const handleOptionChange = (questionIndex, option, value) => {
        let temp = [...quizArray];
        temp[questionIndex].options.forEach((item) => {
            if (item.option === option) {
                item.value = value;
            }
        });
        setQuizArray(temp);
        setReload(!reload);
    }


    const handleClearQuestion = (questionIndex) => {
        let temp = [...quizArray];
        temp.splice(questionIndex, 1);
        setQuizArray(temp);
        setCurrentNumber(Math.max(0, questionIndex - 1));
        let total = 0;
        temp.forEach((question) => {
            total += question.positiveMarks;
        });
        setTotalMarks(total);

        setReload(!reload);
    };

    const handleToggleCorrect = (questionIndex, option, value) => {
        let temp = [...quizArray];
        const questionType = temp[questionIndex].type;
        if (questionType === 'MCQ') {
            setValueTf('')

            temp[questionIndex].options.forEach((item) => {
                if (item.option === option) {
                    item.correct = !item.correct;
                } else {
                    item.correct = false;
                }
            });
        }
        else if (questionType === 'T/F') {
            setValueTf(value)
            let temp = [...quizArray];
            temp[questionIndex].options.forEach((item) => {
                if (item.option === option) {
                    item.correct = true;
                } else {
                    item.correct = false;
                }
            });
            setQuizArray(temp);
        }
        else if (questionType === 'MSQ') {
            setValueTf('')

            const selectedOptions = temp[questionIndex].options.filter((item) => item.correct);

            if (selectedOptions.length < 4 || temp[questionIndex].options.find((item) => item.option === option).correct) {
                temp[questionIndex].options.forEach((item) => {
                    if (item.option === option) {
                        item.correct = !item.correct;
                    }
                });
            } else {
                temp[questionIndex].options.forEach((item) => {
                    if (item.option === option) {
                        item.correct = false;
                    }
                });
            }
        }

        setQuizArray(temp);
        setReload(!reload);
    };

    const handleRemoveOption = (questionIndex, option) => {
        let temp = [...quizArray];
        temp[questionIndex].options = temp[questionIndex].options.filter((item) => item.option !== option);
        setQuizArray(temp);
        setReload(!reload);
    };
    console.log("addQuiz12", addQuiz);
    console.log("location", location);
    const actions = [
        <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={() => {
               
                if (location?.state?.fromVideoResourceSection) {
                    postQuizName(addQuiz, () => { }, () => {

                        const updateTopics = sectionData;
                        if (location?.state?.fromVideoResourceSection?.editTrue) {
                            let newTopicIndex = location?.state?.fromVideoResourceSection.topicIndex;
                            updateTopics[storeSectionIndex].topics[newTopicIndex].name = addQuiz;
                        } else {
                            updateTopics[storeSectionIndex].topics[storeTopicIndex].name = addQuiz;
                        }
                        setSectionData(updateTopics);
    
                    });
                }else{
                    postQuizName(addQuiz, () => { }, () => {

                        const updateSmTopics = smTopicsData
                        if (location?.state?.fromStudyMaterial?.editTrue) {
    
                            let smTopicIndex = location?.state?.fromStudyMaterial.topicIndex
                            console.log("inside if", updateSmTopics, smTopicIndex);
                            updateSmTopics[smTopicIndex].name = addQuiz;
                        } else {
                            updateSmTopics[location?.state?.fromStudyMaterial?.studyMaterialsTopics?.length - 1].name = addQuiz;
                        }
                        setSMTopicsData(updateSmTopics)
    
    
                    });
                }
                
            }}
            disabled={isSaveButtonDisabled}  // Apply disabled state to the button
        >
            SAVE
        </Button>
    ];
    { console.log("sectionData12", sectionData) }

    const data = (
        <div style={{ width: "80px", padding: '0px' }}>
            <div
                style={{
                    color: selectedOption === 'clear' ? '#636363' : '#AEAEAE',
                    fontSize: "16px",
                    cursor: "pointer",
                    padding: '0px',
                    // background: selectedOption === 'clear' ? '#F6F4FE' : '#fff'
                }}
                onClick={() => {
                    setSelectedOption('clear');
                    handleClearQuestion(currentNumber);
                }}
            >
                <h style={{ marginRight: "10px" }}><img src={eraser} style={{ width: "20px" }} /></h>Clear
            </div>
            <div
                style={{
                    color: selectedOption === 'delete' ? '#636363' : '#AEAEAE',
                    fontSize: "16px",
                    cursor: "pointer",
                    marginTop: "3px"
                    // background: selectedOption === 'delete' ? '#F6F4FE' : '#fff'
                }}
                onClick={() => {
                    setSelectedOption('delete');
                    deleteQuestion(storeId, () => { }, () => { handleClearQuestion(currentNumber) });
                }}
            >
                <h style={{ marginRight: "14px" }}><DeleteFilled /></h>Delete
            </div>
        </div>
    );
    //     const questionContentArr = quizArray[currentNumber]?.questionContent;
    // const strippedContent = questionContentArr ? questionContentArr.replace(/<\/?[^>]+(>|$)/g, "") : "";
    console.log("sectionData12", sectionData);

    return (
        <div>
            <div>
                <PageHeader title={addQuiz} editIcon={true} editOnClick={() => { setIsModalOpen(true) }}
                    onBackPress={() => {
                        console.log("sectionData123", sectionData);
                        history.push(
                            courseTypeName == "studyMaterials" ?
                                {
                                    pathname: "/course-details" ,
                                    state: {
                                        key: {
                                            isEdit: location?.state?.key?.isEdit,
                                            fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                            searchedValue: location?.state?.key?.searchedValue,
                                            fromSubcategory: location?.state?.key?.fromSubcategory,
                                            fromCourseCard: location?.state?.key?.fromCourseCard,
                                            fromDashboardSearch: location?.state?.key?.fromDashboard,
                                            fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                            resources:true,
                                        },
                                        fromAddQuizPage: true,
                                        liveCourseLearningFields: location?.state?.fromStudyMaterial?.liveCourseLearningFields,
                                        retrieveQuizUpdatedTopics: smTopicsData,
                                        SMfilesArray: location?.state?.fromStudyMaterial?.SMfilesArray,
                                        SMfileIndex: location?.state?.fromStudyMaterial?.SMfileIndex,
                                    }
                                }
                                :
                                {
                                    pathname: "/course-details",
                                    state: {
                                        key: {
                                            isEdit: location?.state?.fromVideoResourceSection?.isEdit,
                                            fromSearchResult: location?.state?.key?.isSearchedSuggestionClicked,
                                            searchedValue: location?.state?.key?.searchedValue,
                                            fromSubcategory: location?.state?.key?.fromSubcategory,
                                            fromCourseCard: location?.state?.key?.fromCourseCard,
                                            fromDashboardSearch: location?.state?.key?.fromDashboard,
                                            fromCourseDetails: location?.state?.key?.fromCourseDetails,
                                            videoResources:true
                                        },
                                        fromAddQuizPage: true,
                                        retrieveQuizUpdatedTopics: sectionData,

                                    }


                                })


                    }}
                    actions={actions} />

            </div>
            <div style={{ width: "100%", display: "flex", marginTop: "70px" }}>
                <div style={{ width: "74%", }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex" }}>
                            <TestDetails value={quizArray?.length} text={quizArray?.length === 1 ? 'Question' : 'Questions'} />
                            <TestDetails value={totalMarks} text={`Marks`} />
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* <div className='r-c-c-c m-r-20 cursor-pointer' >
                                <MdFormatListBulleted />
                                <div >List View</div>
                            </div> */}
                            {/* <div className='r-c-c-c m-r-20 cursor-pointer'>
                                <AiOutlineDownload />
                                <div >Import</div>
                            </div> */}
                        </div>
                    </div>
                    <div style={{ height: "75vh", marginTop: "20px", padding: 0 }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    <div style={{ color: "#636363", fontSize: "14px", fontWeight: 700 }}>Question type </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <Select
                                            style={{ width: "200px" }}
                                            placeholder={'Select question type'}
                                            value={quizArray[currentNumber]?.type}
                                            onChange={(e) => { handleQuestionType(currentNumber, e) }}
                                        >
                                            <Option disabled value='Multiple choice' style={{ color: "black" }}><CaretDownOutlined />Multiple choice</Option>
                                            <Option value='MCQ' style={{ marginLeft: '30px' }}>One correct answer</Option>
                                            <Option value='MSQ' style={{ marginLeft: '30px' }}>Multiple correct answer</Option>
                                            <Option value='T/F'>True / False</Option>
                                        </Select>
                                    </div>
                                </div>

                            </div>
                            <div style={{ display: "flex" }}>
                                <div className='r-c-c-c m-r-20 cursor-pointer' >
                                    <div style={{ color: "#636363", fontSize: "14px", fontWeight: 700 }}>Marks</div>
                                    <div style={{ height: "35px", width: "70px", border: "1px solid #6BDB9E", backgroundColor: "#E9FCF5", marginTop: "5px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ marginLeft: "5px", marginTop: "5px" }} onClick={() => {
                                                let temp = quizArray;
                                                temp[currentNumber].positiveMarks = temp[currentNumber].positiveMarks + 1
                                                setQuizArray(temp)
                                                setReload(!reload)
                                                updateTotalMarks();

                                            }}><PlusOutlined /></div> <div style={{ textAlign: "center", marginTop: "5px" }} >{quizArray[currentNumber]?.positiveMarks}</div><div style={{ marginRight: "5px", marginTop: "5px" }} onClick={() => {
                                                let temp = [...quizArray];
                                                if (temp[currentNumber].positiveMarks > 0) {
                                                    temp[currentNumber].positiveMarks = temp[currentNumber].positiveMarks - 1;
                                                }
                                                setQuizArray(temp);
                                                setReload(!reload);
                                                updateTotalMarks();

                                            }}><MinusOutlined /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='r-c-c-c m-r-40 cursor-pointer m-t-20'>
                                    <Popover
                                        placement="bottomRight"
                                        content={data}
                                        style={{ padding: 0 }}
                                    >
                                        {currentNumber > 0 ?
                                            (<>  {quizArray[currentNumber]?.id ?
                                                (<MoreOutlined style={{ fontSize: "24px" }} />) : null}</>)
                                            : null}
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        {quizArray[currentNumber]?.type != null ?
                            (
                                <div style={{ overflowY: "scroll", height: "500px" }}>
                                    <div className='m-t-20'>Question {""}{currentNumber + 1}</div>
                                    <div style={{ width: "95%", marginTop: "8px" }}>
                                        <div style={{ border: "1px solid #636363 ", borderRadius: '4px', width: "100%", padding: 20, height: 'fit-content',cursor:"pointer" }}
                                            onClick={() => {
                                                setEditorValue(quizArray[currentNumber]?.questionContent)
                                                toggleTextEditor(true),
                                                    setEditorCallback((prev) => (editorData) => {
                                                        handleQuestionChange(currentNumber, editorData)
                                                        toggleTextEditor(false)
                                                    })
                                            }}>

                                            <HtmlComponent
                                                item={quizArray[currentNumber]?.questionContent}
                                                toggleImageModal={() => { }}
                                                setCurrentImg={() => { }}
                                            />
                                        </div>
                                    </div>
                                    <div className='m-t-20'>Options</div>

                                    {quizArray[currentNumber]?.options?.map((item, index) => {
                                        console.log("item345", item?.value);
                                        return (
                                            <div className='m-t-20' style={{ width: "100%" }} key={index}>
                                                <div style={{ display: "flex" }}>
                                                    <div className='m-r-20' style={{ borderRadius: "100%", border: item.correct ? "2px solid #28DF99 " : "2px solid #636363", height: "35px", width: "35px", backgroundColor: item.correct ? "#28DF99" : "white" }}>
                                                        <div style={{ textAlign: "center", marginTop: "4px",cursor:"pointer" }}
                                                            onClick={() => {
                                                                handleToggleCorrect(currentNumber, item.option, item?.value)
                                                            }}>
                                                            {item?.option}
                                                        </div>
                                                    </div>
                                                    <div style={{ position: 'relative', width: '84%' }}>
                                                        <div
                                                            contentEditable={!disable}
                                                            className="custom-input"
                                                            role="textbox"
                                                            tabIndex="0"
                                                            style={{
                                                                width: '100%',
                                                                height: 'fit-content',
                                                                minHeight: 40,
                                                                backgroundColor: item.correct ? '#E9FCF5' : 'white',
                                                                color: '#191919',
                                                                padding: '8px',
                                                                border: `1px solid ${item.correct ? '#28DF99' : '#636363'}`,
                                                                borderRadius: '4px',
                                                                outline: 'none',
                                                                cursor: disable ? 'not-allowed' : 'pointer',
                                                                userSelect: 'text',
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                alignItems: 'center',
                                                                fontSize: '14px',
                                                                lineHeight: '1.5',
                                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                                resize: 'none',
                                                                margin: '0',
                                                            }}
                                                            onClick={(e) => {
                                                                setEditorValue(item?.value)
                                                                if (disable === true) {
                                                                    toggleTextEditor(false)
                                                                } else {
                                                                    toggleTextEditor(true),
                                                                        setEditorCallback((prev) => (editorData) => {
                                                                            console.log('abcdefg', editorData);
                                                                            handleOptionChange(currentNumber, item.option, editorData)
                                                                            toggleTextEditor(false)
                                                                        })
                                                                }
                                                            }}
                                                        >
                                                            <div style={{ width: "580px" }}>
                                                                <HtmlComponent
                                                                    item={item?.value}
                                                                    toggleImageModal={() => { }}
                                                                    setCurrentImg={() => { }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                right: '8px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                            }}
                                                        >
                                                            <div
                                                                style={{ display: 'flex', cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleToggleCorrect(currentNumber, item.option, item?.value);
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderRadius: '50%',
                                                                        border: `2px solid ${item.correct ? '#28DF99' : '#636363'}`,
                                                                        height: '20px',
                                                                        width: '20px',
                                                                        marginTop: '6px',
                                                                        color: item.correct ? '#28DF99' : 'white',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        color: item.correct ? '#28DF99' : 'white',
                                                                    }}
                                                                >
                                                                    {item.correct && (
                                                                        <CheckOutlined style={{ color: '#28DF99', height: 5, width: 7, marginBottom: 7, marginRight: 7 }} />
                                                                    )}
                                                                </div>
                                                                <h style={{ marginLeft: '10px', color: '#AEAEAE', marginTop: "3px" }}>Correct answer</h>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {quizArray[currentNumber]?.options?.length > 2 == false || disable === true ? null : (
                                                        <div className='m-l-20' style={{ marginTop: "3px", borderRadius: "100%", border: "2px solid #636363", height: "35px", width: "35px" }}>
                                                            <div style={{ textAlign: "center", marginTop: "4px" }} onClick={() => {

                                                                handleRemoveOption(currentNumber, item.option)
                                                            }}><CloseOutlined /></div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>


                                        )
                                    })}
                                </div>
                            ) : null}

                    </div>
                    <div
                        style={{ textAlign: "center", color: "#1089FF", fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                            postAddQuiz(quizArray[currentNumber], questionContent, valueTf, () => { }, (response) => {
                                handleSaveNext()
                                if (location?.state?.fromStudyMaterial) {
                                    quizArray[currentNumber].id = response?.quiz?.questionId
                                    const updateTopics = smTopicsData;
                                    if (!location?.state?.fromStudyMaterial?.editTrue) {
                                        updateTopics[location?.state?.fromStudyMaterial?.studyMaterialsTopics?.length - 1].quizId = response?.quiz?.id;
                                        updateTopics[location?.state?.fromStudyMaterial?.studyMaterialsTopics?.length - 1].name = addQuiz;
                                    }

                                    setSMTopicsData(updateTopics);
                                } else {
                                    quizArray[currentNumber].id = response?.quiz?.questionId
                                    const updateTopics = sectionData;
                                    if (!location?.state?.fromVideoResourceSection?.editTrue) {
                                        updateTopics[storeSectionIndex].topics[storeTopicIndex].quizId = response?.quiz?.id;
                                        updateTopics[storeSectionIndex].topics[storeTopicIndex].name = addQuiz;
                                    }
                                    setSectionData(updateTopics);
                                }
                            },)
                        }}
                    >{quizArray?.length < 10 ? 'SAVE & NEXT' : 'SAVE'}</div>
                    {console.log('sectionData12345', sectionData)}
                </div>
                <div style={{ width: "26%", borderLeft: "2px solid #FAFAFA", }}>
                    <div className='m-l-20' style={{ display: "flex" }}>
                        <div style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }}>All question</div>
                        <div className='m-l-10' style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }}>(Max 10 questions)</div>
                    </div>


                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {quizArray?.map((item, index) => {
                            return (
                                <>
                                    {console.log("item", item)}
                                    <div className='m-l-20 m-t-20' style={{ borderRadius: "100%", border: "2px solid #636363", height: "35px", width: "35px", cursor: "pointer" }}>
                                        <div style={{ textAlign: "center", marginTop: "4px" }} onClick={() => { setStoreId(item?.quizId); setCurrentNumber(index) }}>{index + 1}</div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <TextEditorModal
                        visible={textEditorVisible}
                        value={textEditorValue}
                        onCancel={() => toggleTextEditor(false)}
                        onSave={(editorData) => { editorCallback(editorData) }}


                    />

                </div>
            </div>
            <CreateQuizModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                addQuiz={addQuiz}
                setAddQuiz={setAddQuiz}
                sectionData={sectionData}
                setSectionData={setSectionData}
                storeTopicIndex={storeTopicIndex}
                storeSectionIndex={storeSectionIndex}
                quizDataContainer={quizDataContainer?.name}
                id={quizDataContainer?.id}
                editTrue={location?.state?.fromVideoResourceSection?.editTrue
                    // || location?.state?.fromStudyMaterial?.editTrue
                }
            />
        </div>
    )

}

const mapStateToProps = (state) => {
    const { courseTypeName, quizDataContainer, storeFormulaData } = state.courseManagement;
    return {
        courseTypeName, quizDataContainer, storeFormulaData
    };
};

const mapDispatchToProps = (dispatch) => ({
    postAddQuiz: (quizArray, questionContent, valueTf, callBack, succesCallback, index) => dispatch(postAddQuiz(quizArray, questionContent, valueTf, callBack, succesCallback, index)),
    deleteQuestion: (storeId, callBack, successCallBack) => dispatch(deleteQuestion(storeId, callBack, successCallBack)),
    storeQuizData: (val) => dispatch(storeQuizData(val)),
    postQuizName: (addQuiz, callBack, successCallBack) => dispatch(postQuizName(addQuiz, callBack, successCallBack)),

});
export default connect(mapStateToProps, mapDispatchToProps)(AddQuiz);