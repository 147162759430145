import React, { useState } from 'react';

const SwitchComponent = ({ isChecked, setIsChecked }) => {
    const toggleSwitch = () => {
        setIsChecked(prevState => !prevState);
    };

    const currentPath = window.location.pathname;

    return (
        <div>
            <div style={{ height: "25px", width: "70px", border: "1px solid #BDB6D5", borderRadius: "50px", cursor: "pointer" }}>
                {!isChecked ?
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }} onClick={() => {
                        toggleSwitch()
                    }}>
                        <div style={{ height: "15px", width: "15px", borderRadius: "100%", backgroundColor: "#9876E6", marginTop: "2px" }}></div>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: 500, marginLeft: "5px" }}>Month</div>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }} onClick={() => { toggleSwitch() 
                     }}>
                        <div style={{ fontSize: "12px", color: "#636363", fontWeight: 500, marginRight: "5px" }}>Year</div>
                        <div style={{ height: "15px", width: "15px", borderRadius: "100%", backgroundColor: "#9876E6", marginTop: "2px", marginLeft: "5px", marginRight:-10 }}></div>
                    </div>}
            </div>
        </div>
    );
};

export default SwitchComponent;