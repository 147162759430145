// import { enableEslintTypescript } from 'customize-cra';
import moment from 'moment';
import { Api } from '../services';
import { UPDATE_PROFILE } from './profile';
import Notification from "services/Notification";


export const POST_TEST_CREATE = 'POST_TEST_CREATE';
export const UPDATE_PIECHART_DATA = 'UPDATE_PIECHART_DATA'
export const UPDATE_CLASSCARD_DATA = 'UPDATE_CLASSCARD_DATA'
export const SET_SELECTED_CLASSROOM = 'SET_SELECTED_CLASSROOM'
export const UPDATE_RESULT_TO_PUBLISH = 'UPDATE_RESULT_TO_PUBLISH'
export const UPDATE_SUBMITTED_TESTS = 'UPDATE_SUBMITTED_TESTS'
export const UPDATE_SUBMITTED_TESTS_DETAILS = 'UPDATE_SUBMITTED_TESTS_DETAILS'
export const UPDATE_EVALUATED_MARK = 'UPDATE_EVALUATED_MARK'
export const UPDATE_EVALUATED_COMMENT = 'UPDATE_EVALUATED_COMMENT'
export const UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID = 'UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID'
export const UPDATE_EVALUATION_QUESTION_DATA = 'UPDATE_EVALUATION_QUESTION_DATA'
export const UPDATE_QUESTION_GRIDS_ARRAY = 'UPDATE_QUESTION_GRIDS_ARRAY'
export const UPDATE_EVALUATION_QUESTION_ID = 'UPDATE_EVALUATION_QUESTION_ID'
export const UPDATE_CURRENT_QUESTION_INDEX = 'UPDATE_CURRENT_QUESTION_INDEX'
export const UPDATE_SUBMITTED_TESTS_ID = 'UPDATE_SUBMITTED_TESTS_ID'
export const UPDATE_SUBMITTED_TESTS_SUBMITTED_ID = 'UPDATE_SUBMITTED_TESTS_SUBMITTED_ID'
export const UPDATE_POST_EVALUATION_OBJECT = 'UPDATE_POST_EVALUATION_OBJECT'
export const UPDATE_SUBMITTED_TESTS_USERS = 'UPDATE_SUBMITTED_TESTS_USERS'
export const UPDATE_PUBLISHED_RESULT = 'UPDATE_PUBLISHED_RESULT'
export const UPDATE_TEST_TO_PUBLISH = 'UPDATE_TEST_TO_PUBLISH'
export const  UPDATE_ONGOING_TEST = 'UPDATE_ONGOING_TEST'
export const UPDATE_DRAFTS = 'UPDATE_DRAFTS'
export const UPDATE_BARCHART_DATA = 'UPDATE_BARCHART_DATA'
export const SET_SELECTED_CLASSROOM_ID = 'SET_SELECTED_CLASSROOM_ID'
export const UPDATE_SUBJECTS_BY_CLASS = 'UPDATE_SUBJECTS_BY_CLASS'
export const SET_SELECTED_SUBJECT = 'SET_SELECTED_SUBJECT'
export const UPDATE_STANDARDWISE_BARCHART_DATA = 'UPDATE_STANDARDWISE_BARCHART_DATA'
export const UPDATE_TODAY_ACTIVITY = 'UPDATE_TODAY_ACTIVITY'
export const UPDATE_UPCOMING_TESTS = 'UPDATE_UPCOMING_TESTS'
export const UPDATE_NO_DATA = 'UPDATE_NO_DATA'
export const UPDATE_STANDARDS_LIST = 'UPDATE_STANDARDS_LIST'
export const GET_TEST_NAME_ARR = 'GET_TEST_NAME_ARR'
export const UPDATE_STUDENT_RESULT = 'UPDATE_STUDENT_RESULT'
export const UPDATE_SELECTED_TEST = 'UPDATE_SELECTED_TEST'
export const UPDATE_SELECTED_STUDENT = 'UPDATE_SELECTED_STUDENT'
export const GET_RESULTS_LOADER = 'GET_RESULTS_LOADER'
export const UPDATE_CANCELLED_TESTS = 'UPDATE_CANCELLED_TESTS'
export const UPDATE_SELECTED_TEST_ID = 'UPDATE_SELECTED_TEST_ID'
export const SET_EDIT_MODAL_VISIBLE = 'SET_EDIT_MODAL_VISIBLE'
export const SET_C_MODAL_VISIBLE = 'SET_C_MODAL_VISIBLE'
export const SET_C_MODAL_TYPE = 'SET_C_MODAL_TYPE'
export const TOGGLE_RELOADER_FLAG = 'TOGGLE_RELOADER_FLAG'
export const GET_INDEX_OF_TEST = 'GET_INDEX_OF_TEST'
export const CLEAR_TEST_CREATE_OBJ = 'CLEAR_TEST_CREATE_OBJ'
export const STORE_GENERATE_QUESTIONS_OBJ = 'STORE_GENERATE_QUESTIONS_OBJ'
export const SHOW_TEXT_EDITOR = 'SHOW_TEXT_EDITOR'
export const UPDATE_SUB_WITH_CHAPTERS = 'UPDATE_SUB_WITH_CHAPTERS'
export const ADD_MARKS_FOR_QUES = 'ADD_MARKS_FOR_QUES'
export const RENDER_EDITOR_CONTENT = 'RENDER_EDITOR_CONTENT'
export const CHOOSE_CORRECT_ANSWER = 'CHOOSE_CORRECT_ANSWER'
export const STORE_TEST_QUES_TYPE = 'STORE_TEST_QUES_TYPE'
export const POST_QUESTION_CREATE = 'POST_QUESTION_CREATE'
export const UPDATE_LIST_OF_QUESTIONS = 'UPDATE_LIST_OF_QUESTIONS'
export const STORE_EDITOR_REMOVED_IMGS = 'STORE_EDITOR_REMOVED_IMGS'
export const STORE_EDITOR_ADDED_IMGS = 'STORE_EDITOR_ADDED_IMGS'
export const DELETE_QUILL_IMGS = 'DELETE_QUILL_IMGS'
export const CLEAR_GENERATE_QUES_FIELDS = 'CLEAR_GENERATE_QUES_FIELDS'
export const RESET_GENERATE_QUES_FIELDS = 'RESET_GENERATE_QUES_FIELDS'
export const STORE_EDIT_GENERATE_QUE_ID = 'STORE_EDIT_GENERATE_QUE_ID'
export const GET_EACT_QUES_DATA_LOADER = 'GET_EACT_QUES_DATA_LOADER'
export const GENERATE_QUES_LIST_VIEW_LOADER = 'GENERATE_QUES_LIST_VIEW_LOADER'
export const STORE_GENERATE_QUES_LIST_VIEW = 'STORE_GENERATE_QUES_LIST_VIEW'
export const CHANGE_TEST_STATUS_LOADER = 'CHANGE_TEST_STATUS_LOADER'
export const CHANGE_TEST_STATUS_MODAL = 'CHANGE_TEST_STATUS_MODAL'
export const CREATE_TEST_MODAL = 'CREATE_TEST_MODAL'
export const UNSET_CREATE_TEST_MODAL_BOOL = 'UNSET_CREATE_TEST_MODAL_BOOL'
export const UPDATE_LISTVIEW_TYPE = 'UPDATE_LISTVIEW_TYPE'
export const UPDATE_FEEDBACK_DATA = 'UPDATE_FEEDBACK_DATA'
export const UPDATE_READY_TO_PUBLISH = 'UPDATE_READY_TO_PUBLISH'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_FEEDBACK_FROM_TEACHER = 'UPDATE_FEEDBACK_FROM_TEACHER'
export const UPDATE_DATA_OPERATOR_STATS = 'UPDATE_DATA_OPERATOR_STATS'
export const TESTING = 'TESTING'
export const UPDATE_TESTS_TO_REVIEW = 'UPDATE_TESTS_TO_REVIEW'
export const EDIT_DETAILS_LOADER = 'EDIT_DETAILS_LOADER'
export const CLONE_LOADER = 'CLONE_LOADER'
export const UPDATE_RESULTS_DATA = 'UPDATE_RESULTS_DATA'
export const UPDATE_SELECTED_CARD_TYPE = 'UPDATE_SELECTED_CARD_TYPE'
export const UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS = 'UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS'
export const UPDATE_TOTAL_MARKS_OBTAINED = 'UPDATE_TOTAL_MARKS_OBTAINED'
export const UPDATE_PALETTE_DATA = 'UPDATE_PALETTE_DATA'
export const UPDATE_NUMERIC_ANSWER = 'UPDATE_NUMERIC_ANSWER'
export const UPDATE_POST_PARAMS2 = 'UPDATE_POST_PARAMS2'
export const SET_OPEN_TAB = 'SET_OPEN_TAB'
export const UPDATE_STD_FOR_CREATING_TEST = 'UPDATE_STD_FOR_CREATING_TEST'
export const SET_DASHBOARD_META = 'SET_DASHBOARD_META'
export const UPDATE_CHAPTER_FOR_CREATING_TEST = 'UPDATE_CHAPTER_FOR_CREATING_TEST'
export const REMOVE_SELECTED_CHAPTER = 'REMOVE_SELECTED_CHAPTER'
export const UPDATE_SYLLABUS = 'UPDATE_SYLLABUS'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const SET_CHAPTERS_LIST = 'SET_CHAPTERS_LIST'
export const SET_LOADER = 'SET_LOADER'
export const SET_HOMEPAGE_DATA = 'SET_HOMEPAGE_DATA'
export const SET_STDS_DATA = 'SET_STDS_DATA'
export const UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST = 'UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST'


const updateTestToPublish = data =>(
  {
    type: UPDATE_TEST_TO_PUBLISH,
    data
  }
)
const updateResultToPublish = data =>(
  {
    type: UPDATE_RESULT_TO_PUBLISH,
    data
  }
)
const updateSubmittedTests = data =>(
  {
    type: UPDATE_SUBMITTED_TESTS,
    data
  }
)
const updatePublishedResult = data =>(
  {
    type: UPDATE_PUBLISHED_RESULT,
    data
  }
)
const updateSubmittedTestsDetails = value =>(
  {
    type: UPDATE_SUBMITTED_TESTS_DETAILS,
    value
  }
)
const updateSubmittedTestsByStudentId = value =>(
  {
    type: UPDATE_SUBMITTED_TESTS_BY_STUDENT_ID,
    value
  }
)
export const updateEvaluationQuestionData = value =>(
  {
    type: UPDATE_EVALUATION_QUESTION_DATA,
    value
  }
)
const updateQuestionGridsArray = value =>(
  {
    type: UPDATE_QUESTION_GRIDS_ARRAY,
    value
  }
)
export const updateEvaluationQuestionId = value =>(
  {
    type: UPDATE_EVALUATION_QUESTION_ID,
    value
  }
)
const updateSubmittedTestsUsers = data =>(
  {
    type: UPDATE_SUBMITTED_TESTS_USERS,
    data
  }
)
//Mark given by Admin/Teacher
export const updateEvaluatedMark = value =>(
  {
    type: UPDATE_EVALUATED_MARK,
    value
  }
)
//comment given by Admin/Teacher
export const updateEvaluatedComment = value =>(
  {
    type: UPDATE_EVALUATED_COMMENT,
    value
  }
)

export const STORE_CREATE_TEST_MODAL_DATA_PRACTICE = 'STORE_CREATE_TEST_MODAL_DATA_PRACTICE';
export const GET_EXAMINER_NAME = 'GET_EXAMINER_NAME';
export const GET_EXAMINER_LOADER = 'GET_EXAMINER_LOADER';
export const GET_SUBJECTS_LOADER = 'GET_SUBJECTS_LOADER';
export const GET_EVALUATION_PAGE_LOADER = 'GET_EVALUATION_PAGE_LOADER';
export const GET_SUBJECTS_NAME = 'GET_SUBJECTS_NAME';
export const REMOVE_SELECTED_SEARCHED_SUBJS = 'REMOVE_SELECTED_SEARCHED_SUBJS';
export const UPDATE_ONE_STUDENT_RESULT = 'UPDATE_ONE_STUDENT_RESULT'
export const SET_IMPORT_META = 'SET_IMPORT_META'

export const storeCreateTestModalData = (key, value) => {
  return {
  type: STORE_CREATE_TEST_MODAL_DATA_PRACTICE,
  key,
  value,
}
};

export const universalLoader = (name, varname, bool) => ({
  type: name,
  varname,
  bool,
});

export const updateCurrentQuestionIndex = value =>({
    type: UPDATE_CURRENT_QUESTION_INDEX,
    value
})

export const updateSubmittedTestsId = value =>({
  type: UPDATE_SUBMITTED_TESTS_ID,
  value
})

export const updateSubmittedTestsStudentId = value =>({
  type: UPDATE_SUBMITTED_TESTS_SUBMITTED_ID,
  value
})

export const updatePostEvaluationObject = () =>({
  type: UPDATE_POST_EVALUATION_OBJECT,
})

export const setSelectedClassroom = newSelection =>({
  type: SET_SELECTED_CLASSROOM,
  newSelection
})

export const setSelectedClassroomId = data=>({
  type: SET_SELECTED_CLASSROOM_ID,
  data
})
const updatePiechartData = pieData => ({
  type: UPDATE_PIECHART_DATA,
  pieData
})

const updateClassCardData = classTestInfo=>({
  type: UPDATE_CLASSCARD_DATA,
  classTestInfo
})


const updateStandards = (data) => ({
  type: UPDATE_STANDARDS_LIST,
  data
})

export const testing = (data) => ({
  type: TESTING,
  data
});

export const setChaptersList = (res) => ({
  type: SET_CHAPTERS_LIST,
  res
})

const setHomePageData = res =>(
  {
    type: SET_HOMEPAGE_DATA,
    res
  }
)

export const fetchPracticeTestDashboard = (callback)=>{
  
  return (dispatch, getState)=>{
      
      dispatch(setLoader('indexLoader', true));
      return Api.get(`/practice-test`).send((response)=>{
          if (response!==undefined){
                console.log("first", response)
                dispatch(setHomePageData(response))
          dispatch(setLoader('indexLoader', false));
          }
          callback(false);
      })
  }
}

const handleClassCardData = (dispatch, subjects) =>{
  const subjectLength = subjects?.length??0;
  const classdata = {}
  let currentStandard = "";
  let newData = {}
  for (let i=0; i<subjectLength; i++){
    currentStandard = subjects[i].standard_std;
    newData = {
      id: subjects[i].standard_id,
      section: subjects[i].standard_section,
      stream: subjects[i].standard_stream,
      chapterTestCount: subjects[i].chapterTestCount,
      partTestCount: subjects[i].partTestCount,
      fullTestCount: subjects[i].fullTestCount,
      previousYearPaper: subjects[i].previousYearPaperCount,
      }
    if (currentStandard in classdata){
      classdata[currentStandard].push(newData)
    }
    else{
      classdata[currentStandard] = [newData];
  }
}
  dispatch(updateClassCardData(classdata))
}


export const fetchStandards = (callback)=>{
  return (dispatch, getState)=>{
    const {filter} = getState().practiseTest;
    let params = {search: filter.indexSearch===''?null:filter.indexSearch}
      dispatch(setLoader('indexSearchLoader', true));
      return Api.get(`/practice-test/standards`).params(params).send((response)=>{
          if (response!==undefined){
            handleClassCardData(dispatch, response);
            // if (response.standards){
            //   const stds = response.standards?.map((item)=>({id:item.id, std:item.standard_std, section:item.standard_section}))
            //   dispatch(updateStdForCreatingTest(stds));
            // }
            if (filter.indexSearch===''||filter.indexSearch==undefined){
              dispatch(updateStandards(response));}
            }
          
            dispatch(setLoader('indexSearchLoader', false));
            callback(false);}
      )
      }
  }



export const fetchTests = (type, callback, startDate="", endDate="", searchText="", standardId='', subjectId='')=>{
  return (dispatch, getState)=>{
    // console.log("from fetch test to publish", startDate, endDate, searchText);
    dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', true));
    const {selectedClassroom} = getState().practiseTest;
    let declared='';
    if (type=="test-result-published"){
      declared=true;
    }
    return Api.get(`/testv2/${type}?startDate=${startDate}&endDate=${endDate}&name=${searchText}&declared=${declared}&standardId=${standardId}&subjectId=${subjectId}`).send((response)=>{
      if (response!==undefined){
        if (type=="test-publish"){
          dispatch(updateTestToPublish(response.testToPublish));
        }
        else if (type=="test-result-published"){
          dispatch(updatePublishedResult(response.tests))
        }
        else if (type=="test-result-publish"){
          dispatch(updateResultsData(response))
        }
        dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', false));
        callback(false);
      }
    })
}
}


export const fetchTestsByStage = (type, callback)=>{
  return (dispatch, getState)=>{
    // console.log("from fetch test to publish", startDate, endDate, searchText);
    dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', true));
    const {selectedClassroom, filter, selectedSubject} = getState().practiseTest;
    const params = {

    }
    if (filter.isTabPage){
      dispatch(setLoader('tabPageLoader', true));
    }else if(filter.isVA){
      dispatch(setLoader('vaPageLoader', true));
    }
    if (filter.isVA){
      params.search = filter.VAsearch,
      params.syllabus = filter.VAsyllabus,
      params.standardIds = filter.VAclasses,
      params.page = filter.VApage
      params.date = filter.isVAdatefiltered?filter.vaDate:null
    }else if(filter.isTabPage){
      params.page = filter.tabPage,
      params.search = filter.tabSearch,
      params.standardIds = [selectedClassroom?.id];
      params.syllabus = [filter.selectedSyllabus==='Previous Year Papers'?'Previous Year Paper':filter.selectedSyllabus];
      params.subjectId = selectedSubject.isCombined?null:selectedSubject.subjectId;
      params.chapterId = filter.selectedSyllabus==='Chapter Test'?filter.selectedChapter.chapterId:null;
      params.combined = selectedSubject.isCombined?true:null;
      params.testDate = (type==="results-published"||type==="results-to-publish")?null:filter.isTAdatefiltered?filter.taDate:null
      params.resultDate = (type==="results-published"||type==="results-to-publish")?filter.isTAdatefiltered?filter.taDate:null:null
      params.testType = filter.testTypeSelector
    }
    return Api.get(`/practice-test/tests-by-stage?stage=${type}`).params(params).send((response)=>{
      if (response!==undefined){
        if (type=="ready"){
          dispatch(updateTestToPublish(response));
        }
        else if (type=="published"){
          dispatch(updatePublishedResult(response))
        }
        else if (type==='draft'){
          dispatch(updateDrafts(response))
        }
        else if (type==="feedback"){
          dispatch(updateFeedbackFromTeacher(response))
        }else if (type==='review'){
          dispatch(updateTestsToReview(response))
        }
        dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', false));
        dispatch(setLoader('tabPageLoader', false));
        dispatch(setLoader('vaPageLoader', false));
        callback(false);
      }
    })}
}

const updateBarchartData = (data)=>({
  type: UPDATE_BARCHART_DATA,
  data
})

export const fetchPublishedResult = ()=>{
  return (dispatch, getState)=>{
    const params = {

    }
    return Api.get(`/testv2/test-result-published`).send((response, error)=>{
      // console.log("fetched Publised Result")
      if (response!==undefined){
        // console.log("fetched Publised Result", response.tests)
        dispatch(updatePublishedResult(response.tests))
      }
    })
  }
}

const updateStandardWiseBarchartData = (data)=>({
  type: UPDATE_STANDARDWISE_BARCHART_DATA,
  data
})

const monthToVal = {"Jan":1, "Feb":2, "Mar": 3, "Apr":4, "May":5, "Jun":6, "Jul":7, "Aug":8,
                    "Sep": 9, "Oct": 10, "Nov": 11, "Dec": 12}

const updateNodata = (data)=>({
  type: UPDATE_NO_DATA,
  data
})

const compare = (a, b) => {
  if ( monthToVal[a.date] < monthToVal[b.date]){ 
    return -1;
  }
  if (monthToVal[a.date] >  monthToVal[b.date]){
    return 1;
  }
  return 0;
}

const keynames = {cancelledCount:"Cancelled count", scheduledCount:"Scheduled count", heldCount:"Held count", draftCount:"Draft count", publishedCount:"Published count", chapterTests:"Chapter test", fullTests:"Full test", partTests:"Part test"}
const handleBarchartData = (dispatch, data, filter, userType)=>{
  const reformatted_data = []
  let max_val = 0
  if (userType!="dataOperator"){
    for (let i=0; i<data?.length; i++){
      for (const key in data[i]){
        let temp = {}
        if ((key=="chapterTests")||(key=="fullTests")||(key=="partTests")){
          temp['date'] = filter=="month"?data[i]['unit'].slice(0, 3):data[i]['unit']
          temp['name'] = key==='publishedCount'?keynames["scheduledCount"]:keynames[key]
          temp['totalCount'] = parseInt(data[i][key])
          max_val = Math.max(parseInt(data[i][key]), max_val)
          reformatted_data.push(temp)
        }
      }
    }
  }
  else{
    for (let i=0; i<data?.length; i++){
      for (const key in data[i]){
        let temp = {}
        if ((key=="draftCount")||(key=="publishedCount")){
          temp['date'] = filter=="month"?data[i]['unit'].slice(0, 3):data[i]['unit']
          temp['name'] = keynames[key];
          temp['totalCount'] = parseInt(data[i][key])
          max_val = Math.max(parseInt(data[i][key]), max_val)
          reformatted_data.push(temp)
        }
      }
    }
  }
  if (max_val==0){
    dispatch(updateNodata(true))
  }
  else if (max_val>0){
    dispatch(updateNodata(false))
  }
  filter=="month"&&reformatted_data.sort(compare);
  return reformatted_data;
}


export const fetchBarChartData = (type, callback)=>{
  return (dispatch, getState)=>{
    dispatch(setLoader('graphLoader', true));
    const {userType, filter, selectedClassroom} = getState().practiseTest;

  let filter1='week'
  const params = {};
    if (type==='overall'){
      
    }else{
      params.standardId = selectedClassroom.id
    }
    if (filter.selectedGraphFilter==='week'){
      params.startDate = filter.graphStartDate;
      params.endDate = filter.graphEndDate
    }else{
      filter1='month'
      params.year = filter.graphYear
    }
    return Api.get(`/testv2/homepage-graph`).params(params).send((response)=>{
      if (response!==undefined){
          let reformatted_data = handleBarchartData(dispatch, response, filter1, userType);
          if (type==='overall'){
            dispatch(updateBarchartData(reformatted_data));

          }else{
            dispatch(updateStandardWiseBarchartData(reformatted_data))
          }
          callback(false);
          dispatch(setLoader('graphLoader', false));
      }
    })}}


const clearTestCreateObj = () => ({
  type: CLEAR_TEST_CREATE_OBJ,
})
export const storeGenerateQuestionsObj = (res) => ({
  type: STORE_GENERATE_QUESTIONS_OBJ,
  res,
})

export const postTestCreate = (callback) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', true));
    const { testCreateObj, createTestModalName, generateQuesObj, listViewType } = getState().practiseTest;
    const params = {
      index: testCreateObj.tIndex,
      instructions: testCreateObj.instruction,
      // selectedTeacher: testCreateObj.teacherId,
      standardId: testCreateObj.standardId[0],
      subjectIds: testCreateObj.subjectId,
      // subjectNames: testCreateObj.subjectName,
      teacherId: testCreateObj.teacherId,
      // teacherName: testCreateObj.teacherName,
      title: testCreateObj.title,
      syllabus: testCreateObj.syllabus==='Chapter'?'Chapter Test':testCreateObj.syllabus==='Full'?'Full Test':testCreateObj.syllabus==='Part'?'Part Test':testCreateObj.syllabus==="Previous Year Paper"?'Previous Year Paper':'',
      chosenChapterIds: testCreateObj.chapterList?.map((item)=>item.id),
      durationInMinutes: testCreateObj.hours*60 + testCreateObj.minutes 
    }
    if(createTestModalName === 'test-edit'){
      params.id = generateQuesObj.id
    }
    else if (createTestModalName == 'test-clone'){
      params.cloneTestId = generateQuesObj.id
    }
    return Api.post(`/practice-test`).params(params).send(response => {
      dispatch(universalLoader('POST_TEST_CREATE', 'universalLoaderBool', false));
      if(response != undefined && response.show.type !== 'error'){
        if(createTestModalName === 'test-create'){
          dispatch(clearGenerateQuesFields());
        }
        dispatch(clearTestCreateObj());
        dispatch(storeGenerateQuestionsObj(response));
        dispatch(updateListOfQuestions(injectQuestionIndex(response)));
        callback(response);
      }
    });
  }
}

export const getSubjectsName = (res) => ({
  type: GET_SUBJECTS_NAME,
  res,
});
export const getSubjects = (text, standardId) => {
  return (dispatch, getState) => {
    dispatch(setLoader('modalSubjectLoader', true));
    const { testCreateObj } = getState().practiseTest;
    return Api.get(`/testv2/subject/autocomplete?name=${text}&standardId=${standardId}`).send(response => {
      if(response != undefined){
        dispatch(getSubjectsName(response));
        dispatch(setLoader('modalSubjectLoader', false));
      }
    });
  }
}

export const removeSelectedSearchSubjs = (index, text) => ({
  type: REMOVE_SELECTED_SEARCHED_SUBJS,
  index,
  text
});
export const getExaminerName = (res) => ({
  type: GET_EXAMINER_NAME,
  res,
});
export const getExaminer = (text) => {
  return (dispatch) => {
    dispatch(universalLoader('GET_EXAMINER_LOADER', 'getExaminerBool', true));
    return Api.get(`/user/autocomplete?role=Teacher&name=${text}`).send(response => {
      dispatch(universalLoader('GET_EXAMINER_LOADER', 'getExaminerBool', false));
      // console.log('response getExaminer+++', response);
      if(response != undefined){
        dispatch(getExaminerName(response));
      }
    });
  }
}

const updateOngoingTest = ongoingTests=>({
  type: UPDATE_ONGOING_TEST,
  ongoingTests
})


const updateDrafts = drafts=>({
  type: UPDATE_DRAFTS,
  drafts
})


const updateSubjectsByClass = data=>({
  type: UPDATE_SUBJECTS_BY_CLASS,
  data
})

export const fetchClassSubjects = (standardId=1, callback)=>{
  return (dispatch)=>{
    return new Promise((resolve)=>{
       Api.get(`/testv2/test-standard?standardId=${standardId}`).send((response)=>{
        if (response!==undefined){
          resolve();
          dispatch(updateSyllabus(response.syllabuses));
          dispatch(updateSubjectsByClass(response.subjects?response.subjects:[]));
          dispatch(updateTodayActivities(response));
          callback(false);
          dispatch(setLoader('syllabusPageLoader', false));
        }
      })
    })
  }
}

export const setSelectedSubject = data=>({
  type: SET_SELECTED_SUBJECT,
  data
})

export const updateTodayActivities = data=>({
  type: UPDATE_TODAY_ACTIVITY,
  data
})


export const fetchTestSubmitDetails = (callback) => {
  return (dispatch, getState)=>{
    const submittedTestsId = getState().practiseTest.submittedTestsId
    return Api.get(`/testv2/test-submit-details?testId=${submittedTestsId}`).send((response, error)=>{
      // console.log("Response ",response )
      if (response !== undefined){
        dispatch(updateSubmittedTestsDetails(response.test))
        dispatch(updateSubmittedTestsUsers(response.users))
        dispatch(updateSubmittedTestsAbsentStudents(response.absentStudents))
      }
      callback(false)
    })
  }
}

const reformatted_questions = (subjects) => {
  let c = 0
  for (let subject of subjects){
    for (let question of subject.questions){
      c+=1
      question.index = c
    }
  }
  return [c, subjects]
}
//Fetching questions array for Question grid
export const fetchSubmittedTestQuestionsForQueGrid = (callback, type) => {
  return (dispatch, getState)=>{
    dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
    const {submittedTestsId, submittedTestsStudentsId} = getState().practiseTest
    return Api.get(`/test/student/submit-test?testId=${submittedTestsId}&studentId=${submittedTestsStudentsId}&question=0`).send((response, error)=>{
      //dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
      if (response !== undefined){
        //console.log('See inside response')
        let [questionCount, subjects] = reformatted_questions(response.subjects);
        if (type==='fromResult'){
          dispatch(updateEvaluationQuestionId(response.subjects?.length && response.subjects[0].questions?.length ? response.subjects[0].questions[0] : 0))
        }
        dispatch(updateSubmittedTestsByStudentId({...response, questionCount:questionCount}))
        dispatch(updateTotalMarksObtained(parseFloat(response.totalMarksObatined!=null?response.totalMarksObatined:'0')));
        dispatch(updateQuestionGridsArray(subjects));
        callback(false)
      }
    })
  }
}

//Save entire evaluated test
export const saveEntireEvaluatedTest = (callback) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
    const { submittedTestsStudentsId,
            submittedTestsId,
            submittedTestsByStudentId,
            totalMarksObtained
          } = getState().practiseTest


    const params = {
        testId : submittedTestsId,
        totalMarks: totalMarksObtained,
        studentId: submittedTestsStudentsId,
        // totalMarksObatined: 50
    }



    return Api.post(`/test/review-test`).params(params).send(response => {
      dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
      if(response != undefined){
        callback(false)
      }
    });
  }
}

//Save evaluation of Admin/Teacher
export const saveEvaluation = (callback, answerstatus) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
    const { evaluationQuestionId,
            evaluatedMark,
            evaluatedComment,
            submittedTestsStudentsId,
            submittedTestsId,
            } = getState().practiseTest


    const params = {
      testQuestionId: evaluationQuestionId.id,
      score: (evaluatedMark==null||evaluatedMark=="None")?0:evaluatedMark,
      comment: evaluatedComment,
      status: answerstatus,
      studentId: submittedTestsStudentsId,
      testId : submittedTestsId,
    }

    return Api.post(`/test/review-test`).params(params).send(response => {
      // console.log('response postQuestionCreate', response);
      if(response != undefined){
        callback(false)
      }
    });
  }
}

const getObj = (index, subjects) => {
  for (let subject of subjects){
    for (let question of subject.questions){
      if (index===question.index){
        return {"id": question.id,
        "answerStatus": question.answerStatus,
        "submitStatus": question.submitStatus}
      }
    }
  }
}

export const increaseQuestionId = (callback) => {
  return (dispatch, getState)=>{
    const {evaluationQuestionId, evaluationPageQuestionGrid, currentQuestionIndex} = getState().practiseTest;
    const obj = getObj(currentQuestionIndex+1, evaluationPageQuestionGrid)

    dispatch(updateEvaluationQuestionId(obj))
    // dispatch(updatePostEvaluationObject())
    callback(true)
  }
}

//Fetching question by it's id for evaluation page
export const fetchSubmittedTestQuestionUsingQuestionId = (callback) => {
  return (dispatch, getState)=>{
    dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
    const {submittedTestsId, submittedTestsStudentsId, evaluationQuestionId, currentQuestionIndex} = getState().practiseTest
    // console.log("See currentQuestionIndex", currentQuestionIndex)
    return Api.get(`/testv2/get-student-answer-by-id?testId=${submittedTestsId}&studentId=${submittedTestsStudentsId}&index=${currentQuestionIndex+1}`).send((response, error)=>{
      dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
      if (response !== undefined){
        dispatch(updateEvaluationQuestionId(response));
        dispatch(updateEvaluationQuestionData(response))
        dispatch(updateEvaluatedMark(parseFloat(response.marksObtained!=null?response.marksObtained:0)))
        dispatch(updateEvaluatedComment(response.comment==null?'':response.comment))
        callback(false)
      }
    })
  }
}


//Fetching questions array for list view
export const fetchSubmittedTestByStudentId = (callback) => {
  return (dispatch, getState)=>{
    dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', true));
    const {submittedTestsId, submittedTestsStudentsId,} = getState().practiseTest
    return Api.get(`/test/student/submit-test?testId=${submittedTestsId}&studentId=${submittedTestsStudentsId}&question=${1}`).send((response, error)=>{
      dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
      if (response !== undefined){
        let [questionCount, subjects] = reformatted_questions(response.subjects);
        dispatch(updateSubmittedTestsByStudentId({...response, subjects:subjects, questionCount:questionCount}));
        callback(false)
      }
    })
  }
}

export const fetchTodayTest = (type, callback, searchText)=>{

  return (dispatch, getState)=>{
    const {filter} = getState().practiseTest;
    const params = {
      name: filter.TAsearch,
      syllabus: filter.TAsyllabus,
      standardIds: filter.TAclasses,
      page: filter.TApage
    }
    dispatch(setLoader('taPageLoader', true));
    dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', true));
    return Api.get(`/testv2/test-filter-today?filter=${type}`).params(params).send((response)=>{
      if (response!==undefined){
        // console.log("fetched today test", type, response.tests);
        if (type=='Cancelled'){
          dispatch(updateCancelledTest(response.tests))
        }
        else if (type=='Ongoing'){
          dispatch(updateOngoingTest(response.tests))
        }
        else if (type=='Upcoming'){
          dispatch(updateUpcomingTests(response.tests))
        }
        else if (type=="Submitted"){
          dispatch(updateSubmittedTests(response.tests))
        }
        // console.log("exiting from something");
        callback(false);
        dispatch(universalLoader('GET_RESULTS_LOADER', 'getResultsBool', false));
        dispatch(setLoader('taPageLoader', false));
      }
      callback(false);
    })
  }
}
const updateUpcomingTests = data=>({
  type: UPDATE_UPCOMING_TESTS,
  data
})
// const updateSubmittedTests = data=>({
//   type: UPDATE_SUBMITTED_TESTS,
//   data
// })

const getTestNames = (data) => ({
  type: GET_TEST_NAME_ARR,
  data,
});

const updateStudentResults = (data) =>({
  type: UPDATE_STUDENT_RESULT,
  data
})

export const fetchStudentResults = (testId=1, callback)=>{
  return (dispatch)=>{
    // testId=2;
    return Api.get(`/testv2/test-submit-details?testId=${testId}`).send((response)=>{
      if (response!==undefined){
        dispatch(updateStudentResults(response))
        callback(false);
      }
    })
  }
}

const updateOneStudentResult = (data) => ({
  type: UPDATE_ONE_STUDENT_RESULT,
  data
})
export const fetchOneStudentResult = (testId=null, studentId=null,callback)=>{
  return (dispatch)=>{
    return Api.get(`/testv2/get-each-student-result?testId=${testId}&studentId=${studentId}`).send((response)=>{
      if (response!==undefined){
        // console.log("fetched one student result", response)
        dispatch(updateOneStudentResult(response))
        callback(false);
      }
    })
  }
}


export const updateSelectedTest = (data) => ({
  type: UPDATE_SELECTED_TEST,
  data
})

export const updateSelectedTestId = (data) => ({
  type: UPDATE_SELECTED_TEST_ID,
  data
})

export const updateSelectedStudent = (data) => ({
  type: UPDATE_SELECTED_STUDENT,
  data
})

export const updateCancelledTest = (data) => ({
  type: UPDATE_CANCELLED_TESTS,
  data
})



export const actOnSelectedTest = (testid, type, callback)=>{
  return ()=>{
    let status = "";
    if (type=="Delete"){
      status = "Deleted"
    }
    else if (type=="Publish"){
      status="Published"
    }else if(type=="Cancel"){
      status = "Cancelled"
    }
    else if ((type=="Unpublish")||type=="Draft"){
      status="Draft"
    }
    const params = {
      status: status
    }
    // console.log("the test id is", testid, "the status is", status)
    return Api.post(`/test/editstatus/${testid}`).params(params).send((response, error) => {
      if (response!==undefined){
        // console.log("the response is", response);
        // actOnSelectedTest1(type, callback);
      }
      callback(false);
    })}}
export const getIndexOfTest = () => {
  return (dispatch, getState) => {
    dispatch(universalLoader('GET_INDEX_OF_TEST', 'getIndexOfTestBool', true));
    const { testCreateObj } = getState().practiseTest;
    const standardId = testCreateObj.standardId;
    const subIds = testCreateObj.subjectId;
    let syllabus = testCreateObj.syllabus === 'Chapter'? 'Chapter Test':testCreateObj.syllabus==='Part'?'Part Test':testCreateObj.syllabus==='Full'?'Full Test':testCreateObj.syllabus==='Previous Year Paper'?'Previous Year Paper':'';
    return Api.get(`/testv2/get-index?subjectIds=[${subIds}]&standardId=${standardId}&syllabus=${syllabus}&practiceTest=true`).send(response => {
      dispatch(universalLoader('GET_INDEX_OF_TEST', 'getIndexOfTestBool', false));
      // console.log('response getExaminer+++', response);
      if(response != undefined){
        dispatch(storeCreateTestModalData('tIndex', response.index));
      }
    });
  }
}

export const showTextEditor = (name) => ({
  type: SHOW_TEXT_EDITOR,
  name,
});
export const updateSubWithChapters = (name) => ({
  type: UPDATE_SUB_WITH_CHAPTERS,
  name,
});
export const addMarksForQues = (operation, name) => ({
  type: ADD_MARKS_FOR_QUES,
  operation,
  name,
});
export const renderEditorContent = (name, value) => ({
  type: RENDER_EDITOR_CONTENT,
  name,
  value,
});
export const chooseCorrectAnswer = (index) => ({
  type: CHOOSE_CORRECT_ANSWER,
  index,
});
export const storeTestQuesType = (obj, name) => ({
  type: STORE_TEST_QUES_TYPE,
  obj,
  name,
});
export const updateListOfQuestions = (res) => ({
  type: UPDATE_LIST_OF_QUESTIONS,
  res,
});
export const storeEditorRemovedImgs = (arr) => ({
  type: STORE_EDITOR_REMOVED_IMGS,
  arr,
});
export const storeEditoraddedImgs = (arr) => ({
  type: STORE_EDITOR_ADDED_IMGS,
  arr,
});

export const clearGenerateQuesFields = () => ({
  type: CLEAR_GENERATE_QUES_FIELDS,
});

const reformat_response = (response) => {
  for (let i=0; i<response.subjects?.length; i++){
    response.subjects[i].comments = [];
    for (let j=0; j<response.subjects[i].mix?.length; j++){
        response.subjects[i].comments.push(response.subjects[i].mix[j].remark)
    }
    response.subjects[i].comments;
  }
  return response;
}
export const postQuestionCreate = (params, callback) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('POST_QUESTION_CREATE', 'postQuestionCreateBool', true));
    const { testCreateObj, newAnswer } = getState().practiseTest;
    return Api.post(`/practice-test/question`).params(params).send(response => {
      dispatch(universalLoader('POST_QUESTION_CREATE', 'postQuestionCreateBool', false));
      // console.log('response postQuestionCreate', response);
      if(response != undefined){
        let reformated_response = reformat_response({...response});
        dispatch(updateListOfQuestions(reformat_response(injectQuestionIndex(reformated_response))));
        dispatch(updateNumericAnswer(''));
        dispatch(clearGenerateQuesFields());
      }
    });
  }
}

export const deleteQuillImgs = (callback) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('DELETE_QUILL_IMGS', 'deleteQuillImgsBool', true));
    const { generateQuesRemovedImgs } = getState().practiseTest;

    return Api.delete(`/test/delete-image`).params({deletedFiles:generateQuesRemovedImgs}).send(response => {
      dispatch(universalLoader('DELETE_QUILL_IMGS', 'deleteQuillImgsBool', false));
      // console.log('response deleteQuillImgs', response);
      if(response != undefined){
        callback();
      }
    });
  }
}

export const deleteQuesFromServer = () => {
  return (dispatch, getState) => {
    const { editGenerateQueId } = getState().practiseTest;
    return Api.delete(`/test/test-question/${editGenerateQueId}`).params({'practiceTest':true}).send(response => {
      // console.log('response deleteQuesFromServer', response);
      if(response != undefined){
        dispatch(updateListOfQuestions(injectQuestionIndex(response)));
        dispatch(clearGenerateQuesFields());
      }
    });
  }
}

export const resetGenerateQuesFields = (res) => ({
  type: RESET_GENERATE_QUES_FIELDS,
  res,
});
export const storeEditGenerateQueId = (id) => ({
  type: STORE_EDIT_GENERATE_QUE_ID,
  id,
});

export const getEachQuesData = (id) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('GET_EACT_QUES_DATA_LOADER', 'getEachQuesDataBool', true));
    dispatch(storeEditGenerateQueId(id));
    return Api.get(`/practice-test/question-by-id/${id}`).send(response => {
      dispatch(universalLoader('GET_EACT_QUES_DATA_LOADER', 'getEachQuesDataBool', false));
      // console.log('response getEachQuesData', response);
      if(response != undefined){
        dispatch(resetGenerateQuesFields(response))
      }
    });
  }
}

export const publishResult = (testid, callback)=>{
  return ()=>{
    // console.log("the test id is", testid,)
    return Api.patch(`/test/status/${testid}`).params({status: 'Published'}).send((response) => {
      if (response!=undefined){
        // console.log("the response is", response);
        // actOnSelectedTest1(type, callback);
        callback(false);
      }
    })}}
  
export const PublishTestResult = (testid, callback)=>{
    return new Promise((resolve)=>{ 
      Api.patch(`/testv2/declare-result/${testid}`).send((response) => {
      if (typeof(response)!=="undefined"){
        resolve();
        callback(response);
      }
    })})
}
    
export const storeGenerateQuesListView = (res) => ({
  type: STORE_GENERATE_QUES_LIST_VIEW,
  res,
});

export const getTestClassList = (subIds, chapterIds=[]) => {
  return (dispatch, getState) => {
    dispatch(setLoader('modalClassLoader', true));
    const params = {
      subjectIdList: subIds,
      chapterIdList: chapterIds
    }
      return Api.get(`/testv2/standard/autocomplete`).params(params).send(response => {
        // dispatch(universalLoader('POST_QUESTION_CREATE', 'postQuestionCreateBool', false));
        if(response != undefined){
          dispatch(updateStdForCreatingTest(response));
          dispatch(setLoader('modalClassLoader', false));
        }
      });
  }
}

const groupChapters = (chapters) => {
  let groupedChapters = {};
  for (let chapter of chapters){
    if (chapter.subjectId in groupedChapters){
      groupedChapters[chapter.subjectId].chapters.push({...chapter});
    }else{
      groupedChapters[chapter.subjectId] = {subjectId:chapter.subjectId, 
        subjectName: chapter.subjectName, chapters: [{...chapter}]}
    }
  }
  return groupedChapters
}
export const getTestChapterList = (search=null, subjectId=null, iscreate=true, stdid) => {
  return (dispatch, getState) => {
    dispatch(setLoader('modalChapterLoader', true));
    const {testCreateObj} = getState().practiseTest;
    const params = {
      standardId: iscreate==undefined||iscreate===true?testCreateObj.standardId:stdid,
      subjectIds: subjectId?subjectId:testCreateObj.subjectId,
      name: search
    };
    return Api.get(`/testv2/chapter/autocomplete`).params(params).send(response=>{
      if (response!=undefined){
      dispatch(updateChapterForCreatingTest(response));
      dispatch(updateGroupedChapterForCreatingTest(response, subjectId!=undefined&&iscreate?true:false, subjectId))
      }
      dispatch(setLoader('modalChapterLoader', false));
    });
  }
}
const injectQuestionIndex = (response) => {
  let index = 1
  for (const subject of response.subjects){
    const newquestions = [];
    for (let question of subject.questions){
      newquestions.push({id:question, index:index})
      index+=1
    }
    subject.questions = newquestions
  }
  return response
}
export const showGenerateQuesListView = (callback, page_source) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('GENERATE_QUES_LIST_VIEW_LOADER', 'generateQuesListViewBool', true));
    const { generateQuesObj, selectedTest } = getState().practiseTest;
    let id = "";
    let ques = "-1";
    if (page_source == "listview") {
      id = generateQuesObj.id;
      ques = "-1";
    }else if(page_source==="navigation"){
      id = generateQuesObj.id;
      ques = 0;
    }else if (page_source==='import'){
      id = generateQuesObj.id;
      ques = 0;
    }
    else{
      id = selectedTest.id?selectedTest.id:selectedTest.testId?selectedTest.testId:generateQuesObj.id;
      ques = 0;
    }
    return Api.get(`/practice-test/test-details/${id}?questions=${ques}`).send(response => {
      dispatch(universalLoader('GENERATE_QUES_LIST_VIEW_LOADER', 'generateQuesListViewBool', false));
      if(response != undefined){
        dispatch(storeGenerateQuesListView(response));
        if (page_source!="listview"){
          dispatch(getTestChapterList(null, response.subjects?.map(item=>''+item.subjId), false, response.standardId));
          dispatch(storeGenerateQuestionsObj(response));
          dispatch(updateListOfQuestions(injectQuestionIndex(response)));
          dispatch(clearGenerateQuesFields())
        }
        if (page_source==='tabviewlive'){
          callback(response);
        }
        else{
          callback(response.subjectIds.split(','), response.chapters);
        }
      }
    });
  }
}

export const sendForReview = (testid, callback)=>{
  return ()=>{
    const params = {
        "markedForReview": 1
    }
    return Api.patch(`/test/test-markforreview/${testid}`).params(params).send((response, error) => {
      if (response!==undefined){
        // actOnSelectedTest1(type, callback);
      }
      callback(false);
    });
  }
}

export const updateGroupedChapterForCreatingTest = (chapterList, isSearch, subjectId) => ({
  type: UPDATE_GROUPED_CHAPTER_FOR_CREATING_TEST,
  chapterList,
  isSearch,
  subjectId
})

export const setIsEditModalVisible = (bool)=>({
  type: SET_EDIT_MODAL_VISIBLE,
  data: bool
})

export const setIsCModalVisible = (bool)=>({
  type: SET_C_MODAL_VISIBLE,
  data: bool
})

export const setCModalType = (data)=>({
  type: SET_C_MODAL_TYPE,
  data
})

export const toggleReloaderFlag = ()=>({
  type: TOGGLE_RELOADER_FLAG
})
export const createTestModal = (name, user, source) => ({
  type: CREATE_TEST_MODAL,
  name,
  user,
  source
});

export const changeTestStatusModal = (name) => ({
  type: CHANGE_TEST_STATUS_MODAL,
  name,
});
export const changeTestStatus = (history, quesScreenBool) => {
  return (dispatch, getState) => {
    dispatch(universalLoader('CHANGE_TEST_STATUS_LOADER', 'changeTestStatusBool', true));
    const { generateQuesObj, modalActiveName, selectedTestId } = getState().practiseTest;
    const params = {
      status:modalActiveName=="MarkReady"?"Ready":modalActiveName=="SendReview"?"Review":modalActiveName,
      practiceTest:true
    }
    return Api.patch(`/test/status/${quesScreenBool?generateQuesObj.id:selectedTestId}`).params(params).send(response => {
      dispatch(universalLoader('CHANGE_TEST_STATUS_LOADER', 'changeTestStatusBool', false));
      if(response != undefined && response.show.type !== 'error'){
        if ((modalActiveName=="MarkReady")||(modalActiveName=="SendReview"||modalActiveName=="Deleted")){

        }
        else{
          if (quesScreenBool){
            history.push('practise-test');
          }else{
            history.push(window.location.pathname);
          }
        }
        dispatch(changeTestStatusModal(''));
        dispatch(clearGenerateQuesFields(''));
        dispatch(toggleReloaderFlag());
      }
    });
  }
}

export const unsetCreateTestModalBool = ()=>({
  type: UNSET_CREATE_TEST_MODAL_BOOL
})

export const updateListViewType = (data)=>({
  type: UPDATE_LISTVIEW_TYPE,
  data
})

export const updateFeedbackData = (res)=>({
  type: UPDATE_FEEDBACK_DATA,
  res
})

export const updateReadyToPublish = (data)=>({
  type: UPDATE_READY_TO_PUBLISH,
  data
})

export const updateRole = ()=>{
  return (dispatch, getState)=>{
    const { user } = getState().session;
    dispatch(updateRoleHelper(user));
  }
}

export const updateRoleHelper = (user)=>({
  type: UPDATE_ROLE,
  user
})

export const updateFeedbackFromTeacher=(data)=>({
  type: UPDATE_FEEDBACK_FROM_TEACHER,
  data
})

export const updateOperatorStats = (data)=>({
  type: UPDATE_DATA_OPERATOR_STATS,
  data
})

export const updateTestsToReview = (data)=>({
  type: UPDATE_TESTS_TO_REVIEW,
  data
})

export const updateResultsData = (data)=>({
  type: UPDATE_RESULTS_DATA,
  data
})

export const updateSelectedCardType = (data)=>({
  type: UPDATE_SELECTED_CARD_TYPE,
  data
})

export const updateSubmittedTestsAbsentStudents = (data) =>({
  type: UPDATE_SUBMITTEDTEST_ABSENTSTUDENTS,
  data
})

export const updateTotalMarksObtained = (marks) => ({
  type: UPDATE_TOTAL_MARKS_OBTAINED,
  marks
})

export const updatePalette = (qIndex, newType) => ({
  type: UPDATE_PALETTE_DATA,
  qIndex,
  newType
})

export const updateNumericAnswer = (newAnswer) => ({
  type: UPDATE_NUMERIC_ANSWER,
  newAnswer
})

export const updatePostParams = (newParams) => ({
  type: UPDATE_POST_PARAMS2,
  newParams
})

export const setOpenTab = (tabName) => ({
  type: SET_OPEN_TAB,
  tabName
})

export const updateStdForCreatingTest = (classes) => ({
  type: UPDATE_STD_FOR_CREATING_TEST,
  classes
})

export const updateChapterForCreatingTest = (chapters) => ({
  type: UPDATE_CHAPTER_FOR_CREATING_TEST,
  chapters
})

export const postComment = (params, callback) => {
  return (dispatch, getState) => {
    return Api.post(`/practice-test/question/comment`).params(params).send(response => {
      // dispatch(universalLoader('GET_EVALUATION_PAGE_LOADER', 'getEvaluationPageBool', false));
      if(response != undefined){
        console.log("prime res", response);
        let reformated_response = reformat_response({...response});
        dispatch(updateListOfQuestions(reformat_response(injectQuestionIndex(reformated_response))));
        callback(response);
      }
    });
  }
}

export const setDashboardMeta = (val) => ({
  type: SET_DASHBOARD_META,
  val
})

export const removeSelectedChapter = (id) => ({
  type: REMOVE_SELECTED_CHAPTER,
  id
});

export const updateSyllabus = (res) => ({
  type: UPDATE_SYLLABUS,
  res
})

export const updateFilter = (key, val) => ({
  type: UPDATE_FILTER,
  key,
  val
})

const handleSubjects = (response, syllabus) => {
  let temp = [];
  for (const sub of response.subjects){
    temp.push({...sub, isCombined:false});
  }
  if (syllabus!=='Chapter Test'){
    temp.push({
      subjectName:"Combined Test(s)",
      testCount:response.combinedCount,
      icon: response.combinedIcon,
      isCombined: true
    })
  }
  return temp;
}

export const fetchSyllabusSubjects = () => {
  return (dispatch, getState)=>{
    dispatch(setLoader('subjectPageLoader', true));
    const {selectedClassroom, filter} = getState().practiseTest;
    const params = {
      standardId: selectedClassroom.id,
      syllabus:filter.selectedSyllabus==='Previous Year Papers'?'Previous Year Paper':filter.selectedSyllabus,
      search: filter.subjectSearch
    }
     return Api.get(`/practice-test/subjects-in-syllabus`).params(params).send(response=>{
       if (response!=undefined){
        const subjects = handleSubjects(response, filter.selectedSyllabus);
        dispatch(updateSubjectsByClass(subjects));
        dispatch(setLoader('subjectPageLoader', false));
        console.log("response", response);
       }
    });
  }
}

export const fetchSubjectChapters = () => {
  return (dispatch, getState)=>{
    dispatch(setLoader('chapterPageLoader', true));
    const {selectedClassroom, filter, selectedSubject} = getState().practiseTest;
    const params = {
      standardId: selectedClassroom.id,
      subjectId:selectedSubject.subjectId,
      search: filter.chapterSearch
    }
     return Api.get(`/practice-test/chapter-test-chapters`).params(params).send(response=>{
       if (response!=undefined){
        // const subjects = handleSubjects(response, filter.selectedSyllabus);
        // dispatch(updateSubjectsByClass(subjects));
        dispatch(setChaptersList(response));
        dispatch(setLoader('chapterPageLoader', false));
        console.log("response", response);
       }
    });
  }
}

export const setLoader = (key, val) => ({
  type: SET_LOADER,
  key,
  val
})

export const setImportMeta = (key, value) => ({
  type: SET_IMPORT_META,
  key,
  value
})


export const fetchClassByCurrData = (callback) => {
  return (dispatch, getState) => {
    dispatch(setLoader('importClassLoader', true));
    return Api.get('/question-bankv2').send((response, error)=>{
      if (response!=undefined){
        console.log("response class data", response);
        dispatch(setImportMeta('setImportClassroom', response));
        dispatch(setLoader('importClassLoader', false));
        // callback(false);
      }
    })
  };
}


export const fetchSampleQuestionPaper = (callback)=>{
  return (dispatch, getState)=>{
    dispatch(setLoader('sampleGroupLoader', true));
    const {importMeta} = getState().practiseTest;    
    const params = {
      curriculumId: importMeta.selectedStandardObj?.curriculumId,
      standardId: importMeta.selectedStandardObj?.id,
      questionPaperType: 'sample question paper',
      questionPaperName: ''
    }
    return Api.get(`/question-bankv2/question-paper`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response sample question papers", response)
        dispatch(setImportMeta('setImportSampleQuestionPapers', response));
        dispatch(setLoader('sampleGroupLoader', false));
      }
    })
  }
}

export const fetchSampleQuestionPaperQuestions = (callback)=>{
  return (dispatch, getState)=>{
    dispatch(setLoader('sampleQuesLoader', true));
    const {importMeta} = getState().practiseTest;
    // const params = {
      // standardId: importMeta.selectedClassData.standardId,
      // questionPaperType: 'sample question paper',
      // questionPaperName: ''
    // }

    
    const params = {
      questionPaperId: importMeta.sampleQuestionPaperObj.questionPaperId,
      testImport: true
    }
    return Api.get(`/question-bankv2/questions`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response sample question papers", response)
        // dispatch(updateTopicCardData(response))
        dispatch(setImportMeta('setSampleQuestionTree', response));
        callback();
        dispatch(setLoader('sampleQuesLoader', false));
      }
    })
  }
}

export const importSampleQuestionPaper = (callback) =>{
  return (dispatch, getState)=>{
    dispatch(setLoader('importingSampleQuesLoader', true));
    const {importMeta, generateQuesObj} = getState().practiseTest;
    // const params = {
      // standardId: importMeta.selectedClassData.standardId,
      // questionPaperType: 'sample question paper',
      // questionPaperName: ''
    // }

    
    const params = {
      questionIds: importMeta.sampleIndividualBool?importMeta.selectedSampleQuestions:[],
      questionPaperIds: importMeta.sampleGroupBool?importMeta.selectedSampleQuestionPapers:[],
      testId: generateQuesObj?.id,
      practiceTest: true
    }
    return Api.post(`/question-bankv2/import/test/question-paper`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response sample question papers", response);
        
        // dispatch(updateTopicCardData(response))
      // dispatch(setImportMeta('setSampleQuestionTree', response));
        // dispatch(setLoader('topicPageLoader', false));
        callback(response);
        dispatch(setLoader('importingSampleQuesLoader', false));
      }
    })
  }
}

export const fetchSubjectCardData = (callback) => {
  return (dispatch, getState)=>{
    dispatch(setLoader('importSubjectList', true));
    const {importMeta} = getState().practiseTest;
    const params = {
      curriculumId: importMeta.selectedStandardObj?.curriculumId,
      standardId: importMeta.selectedStandardObj?.id,
      questionPaperType: 'subject-wise question paper'
    }
    return Api.get(`/question-bankv2/question-paper`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response subjects", response);
        dispatch(setImportMeta('subjectCardData', response));
        callback(false);
        dispatch(setLoader('importSubjectList', false));
      }
    })
  }
}


export const fetchChapterCardData = (callback)=>{
  return (dispatch, getState)=>{
    dispatch(setLoader('importChapter', true));
    const {importMeta} = getState().practiseTest;
    const params = {
      curriculumId: importMeta.selectedStandardObj?.curriculumId,
      standardId: importMeta.selectedStandardObj?.id,
      subjectId: importMeta.selectedSubjectObj.subId
    }
    return Api.get(`/question-bankv2/chapters`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response chapters", response)
        dispatch(setImportMeta('chapterCardData', response));
        dispatch(setLoader('importChapter', false));
        // callback(false);
      }
    })
  }
}


export const fetchTopicList = (callback)=>{
  return (dispatch, getState)=>{
    dispatch(setLoader('importTopics', true));
    const {importMeta} = getState().practiseTest;
    const params = {
      curriculumId: importMeta?.selectedStandardObj?.curriculumId,
      standardId: importMeta?.selectedStandardObj?.id,
      subjectId: importMeta?.selectedSubjectObj?.subId,
      chapterId: importMeta?.selectedChapterObj?.chapterId
    }
    return Api.get(`/question-bankv2/topics`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response chapters", response);
        dispatch(setImportMeta('topicsList', response));
        dispatch(setImportMeta('setSelectionTree', response))
        callback(response);
        dispatch(setLoader('importTopics', false));
        dispatch(setLoader('importSubjectWiseQuestions', false));
      }
    })
  }
}


export const fetchSubjectWiseQuestions = (callback) =>{
  return (dispatch, getState)=>{
    dispatch(setLoader('importSubjectWiseQuestions', true));
    const {importMeta, generateQuesObj} = getState().practiseTest;
    const params = {
      topicId:importMeta.selectedTopic?.topicId,
      testImport: true
    }
    return Api.get(`/question-bankv2/questions`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response subjective question papers", response);
        dispatch(setImportMeta('topicwiseQuestionsList', response));
        dispatch(setLoader('importSubjectWiseQuestions', false));
      }
    })
  }
}


export const setStdsData = (res) => ({
  type: SET_STDS_DATA,
  res
})

export const fetchStandardsPageData = (callback) =>{
  return (dispatch, getState)=>{
    dispatch(setLoader('syllabusPageLoader', true));
    const { selectedClassroom} = getState().practiseTest;
    
    return Api.get(`/practice-test/standards/${selectedClassroom.id}`).send((response, error)=>{

      dispatch(setLoader('syllabusPageLoader', false));
      if (response!=undefined){
        console.log("response subjective question papers", response);
        dispatch(setStdsData(response))
      }
    })
  }
}
export const importSubjectWiseQuestionPaper = (callback) =>{
  return (dispatch, getState)=>{
    dispatch(setLoader('importingSubQuestions', true));
    const {importMeta, generateQuesObj} = getState().practiseTest; 
    let questions = [];
    for (let topic of importMeta.selectionTree){
      for (let questionId of topic.selectedQuestions){
        questions.push({questionId:questionId, 
                        positiveMarks:importMeta.marksList[questionId].positiveMark,
                        negativeMarks:importMeta.marksList[questionId].negativeMark
                      })
      }
    }   
    const params = {
      questions: questions,
      testId: generateQuesObj.id,
      practiceTest: true
    }
    return Api.post(`/question-bankv2/import/test/subjectwise`).params(params).send((response, error)=>{
      if (response!=undefined){
        console.log("response sample question papers", response);
        
        // dispatch(updateTopicCardData(response))
      // dispatch(setImportMeta('setSampleQuestionTree', response));
        // dispatch(setLoader('topicPageLoader', false));
        callback(response);
      }
    })
  }
}

