import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'

function StudentPdfView({ pdfViewFile }) {
    console.log("pfdurl", pdfViewFile)
    const history = useHistory();
    return (
        <div>
            <div className=''>
                <div className=''>
                    <ArrowLeftOutlined onClick={() => history.push({
                        pathname: "/resource-details",
                        state: {
                                id: pdfViewFile?.studyMaterialId,
                                resourceType: "studyMaterial"
                        }
                    })
                    } />{pdfViewFile?.name}
                </div>
                <iframe
                    width="100%"
                    style={{ height: "90vh" }}
                    // height="100%"
                    src={`${Api.proDlS3Url}${pdfViewFile?.fileUrl}#toolbar=0`}
                    frameborder="0"
                ></iframe>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { pdfViewFile } = state.studentMarketPlace
    return {
        pdfViewFile
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(StudentPdfView)

