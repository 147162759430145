import React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import {
  UndoOutlined,
  RedoOutlined,
  ClearOutlined,
  CloseOutlined,
  EditOutlined,
  HighlightOutlined
} from "@ant-design/icons";
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import Popover from 'antd/lib/popover';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/popover/style/index';
import Styles from "./style";

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.getPath = this.getPath.bind(this);
    this.loadPath = this.loadPath.bind(this);
    // this.handlePenClick = this.handlePenClick.bind(this);
    // this.handleUndoClick = this.handleUndoClick.bind(this);
    // this.openColorPicker = this.openColorPicker.bind(this);
    // this.handleRedoClick = this.handleRedoClick.bind(this);
    // this.handleClearClick = this.handleClearClick.bind(this);
    // this.handleEraserClick = this.handleEraserClick.bind(this);
    this.handlePathUpdate = this.handlePathUpdate.bind(this);

    this.state = {
      pen: true,
      color: "green",
      showColorPicker: false,
      path: false,
      width: 2
    };
  }

  content = (
    <div style={Styles.colorPickerWrapper}>
      <button
        style={{ ...Styles.colorPickerBtn, backgroundColor: "red" }}
        onClick={() => this.selectColor("red")}
      />
      <button
        style={{ ...Styles.colorPickerBtn, backgroundColor: "blue" }}
        onClick={() => this.selectColor("blue")}
      />
      <button
        style={{ ...Styles.colorPickerBtn, backgroundColor: "green" }}
        onClick={() => this.selectColor("green")}
      />
      <button
        style={{ ...Styles.colorPickerBtn, backgroundColor: "black" }}
        onClick={() => this.selectColor("black")}
      />
    </div>
  );

  componentDidUpdate(prevProps) {
    const { switchCanvas, pathData, showToolBar, prevPages } = this.props;
    if (!showToolBar) {
      // if (prevProps.isReconnect !== isReconnect && isReconnect) {
      //   const isSamePage = prevProps.switchCanvas === switchCanvas;
      //   return this.loadCanvasPaths(isSamePage);
      // }
      if (prevProps.pathData !== pathData && pathData && this.canvas) {
        return this.loadPath(pathData);
      }
      if (
        prevProps.switchCanvas !== switchCanvas ||
        prevProps.prevPages !== prevPages
      ) {
        this.loadCanvasPaths();
      }
    }
  }

  loadCanvasPaths() {
    const { prevPages, switchCanvas } = this.props;
    if (typeof prevPages === "undefined") return;
    this.handleClearClick();
    console.log("prevPages", prevPages, switchCanvas);
    if (prevPages[switchCanvas].path) {
      for (let i = 0; i < prevPages[switchCanvas].path.length; i += 1) {
        this.loadPath(prevPages[switchCanvas].path[i]);
      }
    }
  }

  selectColor(color) {
    this.setState({
      color,
      showColorPicker: false
    });
  }

  handleUndoClick() {
    this.canvas.current.undo();
  }

  handleRedoClick() {
    this.canvas.current.redo();
  }

  handleClearClick() {
    this.canvas.clearCanvas();
  }

  handleEraserClick() {
    this.setState({ pen: false });
    this.canvas.current.eraseMode(true);
  }

  getPath() {
    this.canvas.current
      .exportPaths()
      .then(data => {
        this.setState({ path: data });
      })
      .catch(err => {});
  }

  handlePathUpdate(data) {
    // this.props.onUpdate(data[0]);
  }

  loadPath(path) {
    let element = path;
    let mainArr = [];
    let paths = [];
    let mainObj = {};
    // const sourceRatio = element.size.width / element.size.height;
    // const currentRatio =
    //   this.sketchContainer.clientWidth / this.sketchContainer.clientHeight;

    const widthRatio = this.sketchContainer.clientWidth / element.size.width;
    const heightRatio = this.sketchContainer.clientHeight / element.size.height;

    element.path.data.forEach((item, index) => {
      const items = item.split(",");
      let obj = { x: "", y: "" };
      // obj.x = parseFloat(items[0]);
      // obj.y = parseFloat(items[1]);
      obj.x = parseFloat(items[0]) * widthRatio;
      obj.y = parseFloat(items[1]) * heightRatio;
      // obj.x = (parseFloat(items[0]) / sourceRatio) * currentRatio * 0.6;
      // obj.y = (parseFloat(items[1]) / sourceRatio) * currentRatio * 0.6;

      paths.push(obj);
    });
    mainObj.paths = paths;
    mainObj.strokeWidth = element.path.width;
    if (element.path.color === "#00000000") {
      mainObj.strokeColor = "#ffffffff";
    } else {
      mainObj.strokeColor = element.path.color;
    }
    mainObj.drawMode = true;
    mainArr.push(mainObj);
    this.canvas.loadPaths(mainArr);
  }

  handleRef(ref) {
    if (ref !== null) {
      this.canvas = ref;
      const { onCanvasRef } = this.props;
      onCanvasRef(ref);
    }
  }

  handleDivRef(divRef) {
    if (divRef !== null) {
      this.sketchContainer = divRef;
    }
  }

  render() {
    const {
      width,
      height,
      style,
      background,
      showToolBar = false
    } = this.props;

    return (
      <div
        ref={divElement => {
          this.handleDivRef(divElement);
        }}
      >
        {showToolBar ? (
          <div style={Styles.toolbarContainer}>
            <div style={Styles.toolbarBtn}>
              <Tooltip placement="left" title="Undo">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<UndoOutlined />}
                  onClick={this.handleUndoClick}
                />
              </Tooltip>
            </div>
            <div style={Styles.toolbarBtn}>
              <Tooltip placement="left" title="Redo">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<RedoOutlined />}
                  onClick={this.loadPath}
                />
              </Tooltip>
            </div>
            <div style={Styles.toolbarBtn}>
              <Tooltip placement="left" title="Clear">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={this.handleClearClick}
                />
              </Tooltip>
            </div>
            <div style={Styles.toolbarBtn}>
              <Tooltip placement="left" title="Erase">
                {this.state.pen ? (
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<ClearOutlined />}
                    onClick={this.handleEraserClick}
                  />
                ) : (
                  <Button
                    type="dashed"
                    shape="circle"
                    icon={<ClearOutlined />}
                    onClick={this.handleEraserClick}
                  />
                )}
              </Tooltip>
            </div>
            <div style={Styles.toolbarBtn}>
              <Tooltip placement="left" title="Pen">
                {this.state.pen ? (
                  <Button
                    type="dashed"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={this.handlePenClick}
                  />
                ) : (
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={this.handlePenClick}
                  />
                )}
              </Tooltip>
            </div>
            <div style={Styles.toolbarBtn}>
              <Tooltip placement="left" title="Colour">
                <Popover
                  placement="bottom"
                  content={this.content}
                  title="Choose a colour"
                  trigger="click"
                  visible={this.state.showColorPicker}
                >
                  <Button
                    style={{
                      backgroundColor: this.state.color,
                      color: "white"
                    }}
                    shape="circle"
                    icon={<HighlightOutlined />}
                    onClick={this.openColorPicker}
                  />
                </Popover>
              </Tooltip>
            </div>
          </div>
        ) : null}
        <ReactSketchCanvas
          ref={ref => this.handleRef(ref)}
          background={background}
          style={{ ...style, position: "relative" }}
          width={width}
          height={height}
          strokeWidth={this.state.width}
          strokeColor={this.state.color}
          eraserWidth={10}
          allowOnlyPointerType={showToolBar ? "all" : null}
          onUpdate={data => this.handlePathUpdate(data)}
        />
      </div>
    );
  }
}

export default Canvas;
