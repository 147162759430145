import React, { useState } from 'react';
import "./styles.css";
import courseOnlineIcon from "../../../../Assets/courseManagementV4/courseOnlineIcon.svg";
import courseOfflineIcon from "../../../../Assets/courseManagementV4/courseOfflineIcon.svg";
import courseHybridIcon from "../../../../Assets/courseManagementV4/courseHybridIcon.svg";
import { Information, SmallInfo, Title } from 'components/Typography';
import { Color } from 'services';

function OverviewList({ item, index , subTitle}) {
    const icon = {
        online: courseOnlineIcon,
        offline: courseOfflineIcon,
        hybrid: courseHybridIcon
    }

    const text = {
        online: "online",
        offline: "offline",
        hybrid: "hybrid"
    }

    return (
        <div className='display-flex' style={{ alignItems: "center", width: "100%", marginTop: 10, marginBottom: index === 2 ? 0 : 5 }}>
            <div><img src={icon[item?.mode]} style={{ height: "1.25rem", width: "1.25rem" }} /></div>
            <div className='m-l-10' >
                <SmallInfo color={Color.darkGrey}>
                    {item?.studentCount} {subTitle == 'Live Courses' ? 'enrolled' : 'purchased'}&nbsp;
                    {item?.count} {text[item?.mode]} courses
                </SmallInfo>
            </div>
        </div>
    );
}

function CourseOverviewCard({ count, subTitle, imageSources, setCourseTypeName, item, history, index }) {

    const [isHovered, setIsHovered] = useState(false);
    const reorderedModeCountList = [
        item?.modeCountList?.find(item => item?.mode === 'online'),
        item?.modeCountList?.find(item => item?.mode === 'offline'),
        item?.modeCountList?.find(item => item?.mode === 'hybrid')
    ];
    return (
        <div
            className={`course-Overview-card-Parent cursor-pointer course-overview-card-zoom ${index === 0 && isHovered ? 'single-course-card-hover' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                setCourseTypeName(item?.overviewType === "liveCourse" ? "liveCourses" : item?.overviewType === "videoResource" ? "videoResources" : item?.overviewType === "studyMaterial" ? "studyMaterials" : item?.overviewType === "testSeries" ? "onlineTestSeries" : null);
                history.push(item?.overviewType === "liveCourse" ? "/live-courses-data" : item?.overviewType === "videoResource" ? "/video-resources-data" : item?.overviewType === "testSeries" ? "/online-test-series-details" : item?.overviewType === "studyMaterial" ? "/study-materials-data" : "/live-courses-data")
            }}
        >
            <div className={`course-overview-card`}>
                <div className='courseOverviewInfo'>
                    <div style={{marginTop:5}}>
                        <Title>{count}</Title>
                        <div className='r-ac' style={{ width:'105%',}}>
                            <Information>
                                {subTitle.toLowerCase()}
                            </Information>
                            <Information className="m-l-5" style={{ marginTop: index === 0 ? 2 : "", display:'flex', justifyContent:'flex-end' }}>
                                (<span style={{marginRight:3}}>{item?.studentCount || 0}</span> {subTitle == 'Live Courses' ? "enrolled" : "purchased"})
                            </Information>
                        </div>
                    </div>
                    <div style={{height:'fit-content'}}> <img className='overview-card-img-size' src={imageSources} alt={"images"} /></div>
                </div>
                {index === 0 &&
                    <div className='course-overview-list'>
                        {reorderedModeCountList?.map((item, listIndex) => (
                            <OverviewList item={item} index={listIndex} subTitle={subTitle} />
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

export default CourseOverviewCard;