import moment from 'moment';
import {
  USER_MODAL, STUDENT_MODAL, UPDATE_USERS,
  TOGGLE_USERS_LOADER, TERMINATE_USERS_MODAL, 
  UPDATE_USER_INPUT,UPDATE_ALL_USER_INPUT,TOGGLE_SEARCH_LOADER,
  SET_FILTER_PARAMS, RESET_FILTER_PARAMS, UPDATE_PENDING_USER_LOADER,
  UPDATE_PENDING_USERS, TOGGLE_USER_DETAIL_MODAL, CHANGE_STATUS_LOADER,
  TOGGLE_PAYMENT_MODAL, UPDATE_POST_LINK_PENDING_USER_PARAMS,
  CLEAR_POST_LINK_PENDING_USER_PARAMS, UPDATE_PENDING_USER_FILTER_PARAMS,
  CLEAR_PENDING_USER_FILTER_PARAMS, TOGGLE_PERMANENT_ADDRESS_CHECKBOX,
  UPDATE_AUTO_APPROVAL_LOADER, UPDATE_AUTO_APPROVAL
} from '../actions/user';

const initialState = {
  userModalBool: false,
  studentModalBool: false,
  studentStandards: [],
  usersList: [],
  usersData: [],
  userAddLoader: false,
  positionCurrent: '',
  userSingle: {},
  StudentSingle: {},
  pageMeta: {
    currentPage: 1,
    itemCount: 0,
    // itemsPerPage: ,
    totalItems: 0,
    totalPages: 0,
  },
  filterParams: {
    role: "Teacher",
    page: 1,
    search: null,
    stdId: null
  },
  postLinkParams: {
    userId: null,
    amount: null,
    dueDate: moment().format("DD-MM-YYYY"),
    note: null
  },
  allowSuperAdminCreation: false,
  pendingUsersLoader: false,
  pendingUsersList: [],
  pendingUsersPageMeta: {},
  detailModalVisible: false,
  detailModalData: {},
  toggleStatusLoader: false,
  paymentModal: false,
  filterPendingUserParams: {
    search: null,
    page: 1,
    status: "Pending"
  },
  permanentAddressChkboxBool: false,
  pendingReqCount: 0,
  autoApproval: false,
  autoApprovalLoader: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTO_APPROVAL_LOADER: 
      return {
        ...state,
        autoApprovalLoader: action.bool
      }
    case UPDATE_AUTO_APPROVAL: 
      return {
        ...state,
        autoApproval: action.bool
      }
    case TOGGLE_PERMANENT_ADDRESS_CHECKBOX:
      return {
        ...state,
        permanentAddressChkboxBool: action.bool
      }
    case CLEAR_PENDING_USER_FILTER_PARAMS: 
      return {
        ...state,
        filterPendingUserParams: {...initialState.filterPendingUserParams}
      }
    case UPDATE_PENDING_USER_FILTER_PARAMS: 
      return {
        ...state,
        filterPendingUserParams: {...state.filterPendingUserParams, ...action.filterParams}
      }
    case CLEAR_POST_LINK_PENDING_USER_PARAMS: 
      return {
        ...state,
        postLinkParams: {...initialState.postLinkParams}
      }
    case UPDATE_POST_LINK_PENDING_USER_PARAMS: 
      return {
        ...state,
        postLinkParams: {...state.postLinkParams, ...action.postLinkParams}
      }
    case TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: action.value,
        detailModalData: action.data
      }
    case CHANGE_STATUS_LOADER: 
      return {
        ...state,
        toggleStatusLoader: action.bool
      }
    case TOGGLE_USER_DETAIL_MODAL:
      return {
        ...state,
        detailModalVisible: action.value,
        detailModalData: action.data,
      };
    case UPDATE_PENDING_USERS: 
      const pendingUsers = action.data.items.map((user, index) => {
        user.key = index
        return user
      })
      return {
        ...state,
        pendingUsersList: [...pendingUsers],
        pendingUsersPageMeta: {...action.data.meta}
      }
    case UPDATE_PENDING_USER_LOADER:
      return {
        ...state,
        pendingUsersLoader: action.bool
      }
    case RESET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {...initialState.filterParams},
      }
    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {...state.filterParams, ...action.filterParams},
      }
    case UPDATE_USER_INPUT:
      let obj = state.StudentSingle;
      obj[action.key] = action.value;
      return {
        ...state,
        StudentSingle: {...state.StudentSingle, ...action.StudentSingle},
      }
    case UPDATE_ALL_USER_INPUT:
        let object = state.userSingle;
        // object[action.key] = action.value;
        object['role'] = object['role'] === 'undefined' ? '': object['role'];
        return {
          ...state,
          userSingle: {...object, ...action.userSingle},
        }
    case TOGGLE_USERS_LOADER:
      return {
        ...state,
        userAddLoader: action.value,
      };
    case TOGGLE_SEARCH_LOADER:
        return {
          ...state,
          searchLoader: action.value,
        }
    case TERMINATE_USERS_MODAL:
      return {
        ...state,
        userModalBool: false,
        studentModalBool: false,
        StudentSingle: {}
      };

    case USER_MODAL:
      let feeAccess = false, supportAccess = false;
      if(action.singleUser?.role?.includes("Fee Manager")){
        feeAccess = true;
      }
      if(action.singleUser?.role?.includes("Institute Support")){
        supportAccess = true;
      }
      return {
        ...state,
        userModalBool: action.userState,
        userSingle: {...action.singleUser, feeAccess, supportAccess},
      };
    case STUDENT_MODAL:
      return {
        ...state,
        studentModalBool: action.studentState,
        StudentSingle: action.singleStudent,
      };
    case UPDATE_USERS:
      return {
        ...state,
        studentStandards: action.users.standards,
        usersList: action.users.userRoleEnum,
        pageMeta: action.users.pageMeta,
        allowSuperAdminCreation: action.users.createSuperAdmin,
        // usersData: [{Admin:action.users.admin}, {DataLogger:action.users.dataLogger}, {Planner:action.users.planner}, {Student:action.users.student}, {SuperAdmin:action.users.superAdmin}, {Teacher:action.users.teacher}]
        usersData: {...action.users, users: [...action.users.users]},
        positionCurrent: action.users.role,
        pendingReqCount: action.users.pendingReqCount,
        autoApproval: action.users?.settings?.studentAutoApprove
      };
    default:
      return state;
  }
};

export default reducer;
