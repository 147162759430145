import { fetchStandards, storeResourceData, storeStandardData } from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PageHeader from "components/pageHeader";
import BackToTop from "components/backToTop";
import { fetchcourseStandards, setSelectedCourse, setSelectedStandard, getUsers, setSpecificUserId, setUserDetails, setRole, setUserids, setStudentWithoutData } from "actions/userManagementV2";
import { Button } from "components/Button";
import UserDeleteModal from '../userManagementV2/modals/userDelete'
import PermanentDeleteModal from '../userManagementV2/modals/parmanentDeleteModal'
import { InputSearch } from "components/customInputs";
import { IMG_URL } from "../../env.json";
import { setOthersSelectedTab } from "actions/profileNew";
import { Table, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
const defaultUser = require('Assets/userManagementV2/ic-students.svg').default;

const UnassignedCard = ({ studentsDataWRTCourseType, history, setSelectedStandard, setSelectedCourse, setUserids, storeResourceData, resourceData }) => {

  const handleUnassignedClick = () => {
    let obj = { courseName: '', section: '', std: 'Un-Assigned', standard_id: -1 }
    setSelectedStandard(obj)
    history.push('/user/student')
    setSelectedCourse('Live Courses');
    setUserids(null);
    storeResourceData(resourceData)
  }

  return (
    <div className='cursor-pointer CardShadow' onClick={() => handleUnassignedClick()} style={{ border: '1px solid #E6E6E6', height: 100, width: "22%", borderRadius: 8, marginTop: 10, backgroundColor: 'white', padding: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className='display-flex'>
        <img src={defaultUser} style={{ width: 50 }} />
        <div className='flex-column p-10 m-l-15'>
          <div style={{ color: '#191919', fontWeight: 600, fontSize: '1.5vw' }}>{studentsDataWRTCourseType?.unassigned_student_with_course}</div>
          <div style={{ color: '#636363', fontWeight: 600, fontSize: '0.8vw' }}>{'students'}</div>
        </div>
      </div>
      <div><RightOutlined /></div>
    </div>
  );
}

const AllStudentsUmV2 = ({ fetchcourseStandards, resourceData, courseStandards, storeStandardData, selectedCourse, viewAllIs, studentsDataWRTCourseType, setSelectedStandard, setSelectedCourse, getUsers, usersList, setSpecificUserId, setUserDetails, setOthersSelectedTab, user, setRole, setUserids, setStudentWithoutData, storeResourceData }) => {
  let fromUserManagement = true
  const history = useHistory();
  const [pageLoader, togglePageLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [parmanentDeleteModal, setPermanentDeletModal] = useState(false)
  const [userDeleteModalBool, setUserDeleteModalOpen] = useState(false);

  let params = {
    search: search == '' ? search : search,
    userType: 'Student',
    courseIds: [resourceData?.id]
  }

  useEffect(() => {
    togglePageLoader(true);
    fetchcourseStandards({ courseId: resourceData?.id }, () => togglePageLoader(false));
  }, []);

  useEffect(() => {
    togglePageLoader(true)
    getUsers(params, () => togglePageLoader(false))
  }, [search]);

  const columns = (user) => {
    const renderName = (value, record) => (
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 0 }}>
        <img src={`${IMG_URL}/${record?.image ? record?.image : 'default.png'}`} alt="" style={{ width: '20px', marginRight: '10px' }} />
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontWeight: 600 }}>
          <Typography.Text ellipsis={{ tooltip: true }}>{value}</Typography.Text>
        </div>
      </div>
    );

    const baseColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render: renderName
      },
      {
        title: 'Class Details',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render: (value, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div style={{ width: 180 }}>
              <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                {record?.classDetails ? record?.classDetails : 'NA'}
              </Typography.Text>
            </div>
          </div>
        )
      },
      {
        title: 'Courses',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render: (value, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div style={{ width: 220 }}>
              <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                {record?.courses ? record?.courses : 'NA'}
              </Typography.Text>
            </div>
          </div>
        )
      },
      {
        title: 'Action',
        dataIndex: 'status',
        key: 'status',
        render: (value, record) => (
          <div className='' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) && (
              <div style={{ width: 380, display: 'flex', justifyContent: 'flex-start' }}>
                <Button onClick={(e) => openDeleteModal(e, record)} type='transparent' style={{ color: '#636363',  padding: 0 }}>SUSPEND</Button>
                <Button onClick={(e) => openPermanentDeleteModal(e, record)} type='transparent' style={{ color: '#FF414D' }}>DELETE</Button>
              </div>
            )}
          </div>
        )
      }
    ];

    return baseColumns;
  };

  const onRow = (record, rowIndex) => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        history.push({
          pathname: '/user-management/profile',
          state: {
            key: fromUserManagement
          }
        })
        setSpecificUserId(record?.id);
        setUserDetails(record);
        setOthersSelectedTab('Basic Profile');
      }
    }
  }

  const setIdToDelete = (id) => {
    selectedRowKeys.push(id);
    setSelectedRowKeys([...selectedRowKeys]);
  };


  const openDeleteModal = (e, record) => {
    setUserDeleteModalOpen(true);
    e.stopPropagation();
    setIdToDelete(record?.id);
  }

  const openPermanentDeleteModal = (e, record) => {
    setPermanentDeletModal(true);
    e.stopPropagation();
    setIdToDelete(record?.id);
  }

  const onDeleteModalCancel = () => {
    setUserDeleteModalOpen(false)
  };
  const onPermanentDeleteModalCancel = () => {
    setPermanentDeletModal(false)
  };

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selections) => {
      setSelectedRowKeys([...selectedRowKeys]);
    },
  };

  const handleBackPress = () => {
    setSelectedCourse('Live Courses');
    history.goBack();
  }

  const handleStandardClick = (item) => {
    setSelectedStandard(item);
    history.push({ pathname: '/user/student', state: { key: "fromStandardClick" } });
    // history.push('/fee-management/course-details');
  }

  const searchViewActions = [
    <InputSearch style={{ borderRadius: 32 }} value={search} placeholder='Search by name/mobile' allowClear onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} />
  ]

  const actions = [
    <InputSearch style={{ borderRadius: 32 }} value={search} placeholder='Search by name/mobile' allowClear onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} />
  ]

  console.log({ studentsDataWRTCourseType });

  return (
    <div style={{ fontFamily: "roboto" }}>
      {search != '' ? (
        <>
          <PageHeader title='Search Result' actions={searchViewActions} onBackPress={() => { setSelectedCourse('Live Courses'); history.goBack() }} />
          {pageLoader ? null :
            <div className='m-t-70'>
              <Table
                style={{ width: "100vw" }}
                rowKey={record => record?.id}
                onRow={onRow}
                rowSelection={rowSelection}
                dataSource={usersList?.items || []}
                columns={columns(user)}
              />
            </div>
          }
        </>
      ) : (
        <>
          <div style={{ marginTop: -10, height: 50 }}>
            <PageHeader actions={actions} title={resourceData?.name} onBackPress={() => handleBackPress()} />
          </div>

          {viewAllIs == 'course' || viewAllIs == 'videoResource' ?
            <div style={{ marginTop: "30px" }}>
              <div className="m-t-10 bold-600">Students without classes ({courseStandards?.unassigned_student_with_course})</div>
              <UnassignedCard studentsDataWRTCourseType={courseStandards} history={history} setSelectedStandard={setSelectedStandard} setSelectedCourse={setSelectedCourse} setUserids={setUserids} storeResourceData={storeResourceData} resourceData={resourceData} />
            </div>
            :
            <></>
          }

          {pageLoader ? null : (
            <div>
              <h2 className="m-t-40 bold-600" style={{ fontSize: "16px" }}>
                All Classes
              </h2>
              {courseStandards?.standardsArray && courseStandards?.standardsArray?.length !== 0 ? (
                courseStandards?.standardsArray?.map((item, idx) => (
                  <div key={idx} style={{ marginTop: 10 }}>
                    <h2 className="m-t-10 bold-600" style={{ fontSize: "14px" }}>
                      {item.std}
                    </h2>
                    <div className="r-c-fs flex-wrap">
                      {courseStandards?.standardsArray[idx]?.standards?.map((standard) => (
                        <div key={standard.id} className='' style={{ padding: 5 }}>
                          <div onClick={() => handleStandardClick(standard)} className='CardShadow cursor-pointer' style={{ border: '1px solid #E6E6E6', borderRadius: 8, height: '22vh', width: '10vw' }}>
                            <center className='flex-column m-t-10'>
                              <center>
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#594099', fontSize: '2.5rem', fontFamily: 'PT serif', fontWeight: 600, width: 100 }}>{standard?.section}</Typography.Text>
                              </center>
                              <center>
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 100 }} className='simGrey bold-500'>{standard?.courseName ?? 'NA'}</Typography.Text>
                              </center>
                              <div style={{ width: '80%', border: '1px solid #E6E6E6', marginLeft: 15, marginTop: 5 }}></div>
                              <div style={{ color: '#28DF99' }}>{standard?.studentCount}</div>
                              <div className='bold-500'>{standard?.studentCount > 1 ? 'Students' : 'Student'}</div>
                            </center>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', flexDirection: 'column' }}>
                  <img style={{ width: 300 }} src={require("../../Assets/ill-no-data.svg").default} alt="No Data" />
                  <div style={{ fontWeight: 600, fontSize: '1rem', color: 'black' }}>
                    No Classes, try again later.
                  </div>
                </div>
              )}

              {!courseStandards?.studentIdsWithoutCourseStandard?.length ? <></>
                :
                <div className="m-t-30">
                  <div className="bold-600">Students with course / batch mismatch</div>
                  <div className='cursor-pointer CardShadow' onClick={() => {
                    setRole('userRole', 'Students')
                    setUserids(courseStandards?.studentIdsWithoutCourseStandard)
                    setSelectedCourse('Students with course / batch mismatch')
                    history.push('/user/student')
                    setStudentWithoutData(undefined)
                    setSelectedStandard('')
                  }} style={{ border: '1px solid #E6E6E6', height: 100, width: "22%", borderRadius: 8, marginTop: 10, backgroundColor: 'white', padding: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='display-flex'>
                      <img src={defaultUser} style={{ width: 50 }} />
                      <div className='flex-column p-10 m-l-15'>
                        <div style={{ color: '#191919', fontWeight: 600, fontSize: '1.5vw' }}>{courseStandards?.studentIdsWithoutCourseStandard?.length}</div>
                        <div style={{ color: '#636363', fontWeight: 600, fontSize: '0.8vw' }}>{'students'}</div>
                      </div>
                    </div>
                    <div><RightOutlined /></div>
                  </div>
                </div>
              }
            </div>
          )}
        </>
      )}
      {userDeleteModalBool && <UserDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={userDeleteModalBool} onCancel={() => onDeleteModalCancel()} />}
      {parmanentDeleteModal && <PermanentDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={parmanentDeleteModal} onCancel={() => onPermanentDeleteModalCancel()} />}
      <PageDataLoader visible={pageLoader} />
      <BackToTop />
    </div>
  );
};
const mapStateToProps = (state) => {
  const { user } = state.session;

  const { feeMangementData, courseDetailsOfResources, resourceData, viewAllIs } = state.feeManagement;
  const { usersList, courseStandards, selectedCourse, studentsDataWRTCourseType } = state.userManagementV2;
  return {
    feeMangementData, courseDetailsOfResources, courseStandards, resourceData, selectedCourse, viewAllIs, usersList, user, studentsDataWRTCourseType
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchStandards: (callback) => dispatch(fetchStandards(callback)),
  fetchcourseStandards: (params, callback) => dispatch(fetchcourseStandards(params, callback)),
  storeStandardData: res => dispatch(storeStandardData(res)),
  getUsers: (params, callback) => dispatch(getUsers(params, callback)),
  setSpecificUserId: id => dispatch(setSpecificUserId(id)),
  setUserDetails: res => dispatch(setUserDetails(res)),
  setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
  setSelectedStandard: res => dispatch(setSelectedStandard(res)),
  setSelectedCourse: res => dispatch(setSelectedCourse(res)),
  setRole: (key, val) => dispatch(setRole(key, val)),
  setUserids: userIds => dispatch(setUserids(userIds)),
  setStudentWithoutData: res => dispatch(setStudentWithoutData(res)),
  storeResourceData: res => dispatch(storeResourceData(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllStudentsUmV2);