import { postUnAssignStudent } from 'actions/courseManagement';
import { deleteUsersPermanent } from 'actions/userManagementV2';
import { Modal } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react'
import { connect } from 'react-redux';

function ConfirmDeleteStudentModal({ showConfirmDeleteModal, setConfirmDeleteModal, allStudentsData, selectedSection, fomAssign, setFromAssign, selectedStudents, deleteUsersPermanent,getCourseStudentsData,postUnAssignStudent,allStudentsEnrolledData,courseTypeName,courseName,courseId }) {
    const [loader,setLoader]= useState(false)
    const handleCancel = () => {
        setConfirmDeleteModal(false)
        setFromAssign(false)
    }
    const params = { userIds: selectedStudents}
    const unAssignParam={
            resourceType:courseTypeName == 'videoResources' ? 'videoResource' : 'studyMaterial',
            resourceId: courseId,
            userIds: selectedStudents
    }
console.log("params",courseName,courseId);
    let selectedStudentsName = allStudentsEnrolledData?.students?.items
        .filter((item) => selectedStudents?.includes(item?.id))
        .map((item) => item?.name)?.join(", ")
        .replace(/, ([^,]*)$/, ' and $1')
    return (
        <>
            <Modal
                className="modal-round-corner"
                centered
                visible={showConfirmDeleteModal}
                onOk={handleCancel}
                onCancel={handleCancel}
                header={null}
                footer={null}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Confirm</div>
                    <div
                        style={{
                            fontSize: "16px",
                            color: "#636363",
                            fontFamily: "roboto",
                            marginTop: "10px",
                            textAlign: 'center'
                        }}
                    >
                        {!fomAssign ?
                            <p>Are you sure you want to delete <span style={{ fontWeight: "bold" }}>{''}</span>?</p> :
                            <p>Are you sure you want to unassign <span style={{ fontWeight: "bold" }}>{selectedStudentsName}</span> from <span style={{ fontWeight: "bold" }}>{courseName}</span>?</p>
                        }
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "50px",
                        // marginBottom: 20
                    }}
                >
                    <Button
                    loading={loader}
                        type='link'
                        style={{ color: '#FF414D', fontWeight: "bold", fontFamily: "roboto", border: "none", fontSize: "16px" }}
                        onClick={() => {
                            if(!fomAssign){
                                setLoader(true)
                                deleteUsersPermanent(params, () => {setLoader(false), getCourseStudentsData(()=>{}),setConfirmDeleteModal(false)
                                setFromAssign(false)})
                            }else{
                                setLoader(true)
                                postUnAssignStudent(unAssignParam,()=>{setLoader(false)},()=>{getCourseStudentsData(()=>{}),setConfirmDeleteModal(false)
                                setFromAssign(false)})
                            }


                        }}
                    >
                        {!fomAssign ? 'DELETE' : 'UNASSIGN'}
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => {
                            handleCancel()
                        }}>
                        CANCEL
                    </Button>
                </div>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => {
    const { allStudentsData } = state.classRoom;
    return {
        allStudentsData
    };
};

const mapDispatchToProps = (dispatch) => ({
    deleteUsersPermanent: (params, callback) => dispatch(deleteUsersPermanent(params, callback)),
    postUnAssignStudent:(unAssignParam,callBack, succesCallback) => dispatch(postUnAssignStudent(unAssignParam,callBack, succesCallback))

});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteStudentModal);
// export default ConfirmDeleteModal