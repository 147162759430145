import { Api, Utils } from "services"
import { sessionService } from 'redux-react-session';
import { handleResendOtpTimer } from "./loginNew";
export const STORE_USER_MARKET_PLACE_HOME_DATA = "STORE_USER_MARKET_PLACE_HOME_DATA";
export const STORE_OFFERS_CAROUSEL_DATA = "STORE_OFFERS_CAROUSEL_DATA";
export const SET_MY_COURSE_HOME = "SET_MY_COURSE_HOME";
export const SET_MY_COURSE_HOME_FOR_TEACHER = "SET_MY_COURSE_HOME_FOR_TEACHER"
export const SET_VIDEO_RESOURCES = "SET_VIDEO_RESOURCES";
export const SET_MYQUIZ_DATA = "SET_MYQUIZ_DATA";
export const SET_QUIZ_RESULTS = "SET_QUIZ_RESULTS";
export const SET_QUIZ_SOLUTION = "SET_QUIZ_SOLUTION";
export const SET_QUIZ_FLOW_COUNT = "SET_QUIZ_FLOW_COUNT";
export const SET_PDF_URL = "SET_PDF_URL";
export const SET_QA_DATA = "SET_QA_DATA";
export const SET_STUDY_MATERIAL = "SET_STUDY_MATERIAL";
export const SET_SIDEBAR_STUDENT_HOME = "SET_SIDEBAR_STUDENT_HOME";
export const SET_VIDEO_COMPLETION_STATUS = "SET_VIDEO_COMPLETION_STATUS";
export const FETCH_PROMO_CODES = "FETCH_PROMO_CODES";
export const FETCH_CART_DETAILS = 'FETCH_CART_DETAILS';
export const STORE_PAYMENT_DETAILS_NEEDED = 'STORE_PAYMENT_DETAILS_NEEDED';
export const POST_PAYMENT_DETAILS = 'POST_PAYMENT_DETAILS';
export const STORE_SINGLE_BUYNOW = 'STORE_SINGLE_BUYNOW'
export const SET_CART_COUNT = "SET_CART_COUNT";
export const SET_COUPON = 'SET_COUPON';
export const SET_PROMO_PRICE = 'SET_PROMO_PRICE';
export const SET_RENDER_PAGE_LOADER = 'SET_RENDER_PAGE_LOADER';
export const STORE_USER_COURSE_DETAILS = "STORE_USER_COURSE_DETAILS"
export const STORE_USER_COURSE_REVIEWS = "STORE_USER_COURSE_REVIEWS"
export const SET_ALL_COURSES_DATA = "SET_ALL_COURSES_DATA"
export const SET_EXPLORE_COURSE_DATA = "SET_EXPLORE_COURSE_DATA"
export const SET_RESOURCES_TYPE = 'SET_RESOURCES_TYPE'
export const SET_PARENT_LINK = "SET_PARENT_LINK"
export const SET_QUIZ_PARAM_ITEM = "SET_QUIZ_PARAM_ITEM";
export const STORE_MARKET_SETTINGS_DATA = "STORE_MARKET_SETTINGS_DATA"
export const SET_QUIZ_SAVED_DATA = "SET_QUIZ_SAVED_DATA"
export const SET_QUIZ_ATTEMPTED = "SET_QUIZ_ATTEMPTED"
export const STORE_STANDARDS_STUDENTS = "STORE_STANDARDS_STUDENTS";
export const STORE_CART_COUNT_DATA = "STORE_CART_COUNT_DATA";
export const SET_IS_LOGOUT_CLICKED = "SET_IS_LOGOUT_CLICKED";
export const SET_IS_MY_ACCOUNT_CLICKED = "SET_IS_MY_ACCOUNT_CLICKED"

export const SET_COLLAPSE_CHAPTER_LIST = "SET_COLLAPSE_CHAPTER_LIST";
export const SET_PROMO_CODE = "SET_PROMO_CODE"
export const SET_VALUE_FOR_CHECKOUT = 'SET_VALUE_FOR_CHECKOUT'
export const SET_FROM_TEST_SERIES = "SET_FROM_TEST_SERIES"
export const SET_SELECTED_COUPON_CODE = 'SET_SELECTED_COUPON_CODE'
export const SET_COUPON_LIST = 'SET_COUPON_LIST'
export const STORE_COURSE_ID_AND_TYPE = "STORE_COURSE_ID_AND_TYPE"
export const SET_DOWNLOAD_BAR_VISIBLE = "SET_DOWNLOAD_BAR_VISIBLE";
export const SET_IS_OTP_VERIFIED = "SET_IS_OTP_VERIFIED";
export const SET_IS_EMAIL_OTP_VERIFIED = "SET_IS_EMAIL_OTP_VERIFIED";
export const SET_SHOW_CONTACT_BAR = "SET_SHOW_CONTACT_BAR";
export const STORE_ENQUIRY_FORM_COURSE_LIST_DATA = "STORE_ENQUIRY_FORM_COURSE_LIST_DATA";
export const STORE_SELECTED_TERM = "STORE_SELECTED_TERM";
export const STORE_TERM_LIST = "STORE_TERM_LIST";
export const STORE_ACTIVE_TERM_INDEX = "STORE_ACTIVE_TERM_INDEX";
export const STORE_PAY_TERM = "STORE_PAY_TERM";
export const SET_SELECTED_SUGGESTION = "SET_SELECTED_SUGGESTION";
export const SET_OFFERS_BAR_VISIBLE = 'SET_OFFERS_BAR_VISIBLE';
export const STORE_COURSE_DATA='STORE_COURSE_DATA'
export const SET_DEVICE_SPECIFIC_NAME='SET_DEVICE_SPECIFIC_NAME'

export const setStorePayTerm = (val) => ({
    type: "STORE_PAY_TERM",
    val,
})

export const setTermList = (val) => ({
    type: "STORE_TERM_LIST",
    val,
})
export const setActiveTermIndex = (val) => ({
    type: "STORE_ACTIVE_TERM_INDEX",
    val,
})
export const setSelectedTerm = (val) => ({
    type: "STORE_SELECTED_TERM",
    val,
})

export const setSelectedCouponCode = (val) => ({
    type: 'SET_SELECTED_COUPON_CODE',
    val,
})

export const setCouponList = (val) => ({
    type: SET_COUPON_LIST,
    val,
})

export const storeStudentStandard = (val) => ({
    type: "STORE_STANDARDS_STUDENTS",
    val,
})


export const setQuizAttempted = (val) => ({
    type: SET_QUIZ_ATTEMPTED,
    val,
})

export const setQuizSavedData = (val) => ({
    type: SET_QUIZ_SAVED_DATA,
    val,
})

export const setParamAttemptQuiz = (val) => ({
    type: SET_QUIZ_PARAM_ITEM,
    val,

})

export const setRenderPage = val => ({
    type: SET_RENDER_PAGE_LOADER,
    val,
})


export const setStorePromoPrice = res => ({
    type: SET_PROMO_PRICE,
    res
})

export const setCouponInCart = res => ({
    type: SET_COUPON,
    res
})

export const storeSingleBuyNow = res => ({
    type: STORE_SINGLE_BUYNOW,
    res
})

export const postPaymentDetails = res => ({
    type: POST_PAYMENT_DETAILS,
    res
})

export const storeDetailsNeededForPayment = res => ({
    type: STORE_PAYMENT_DETAILS_NEEDED,
    res
})

export const storeOrderDetails = res => ({
    type: FETCH_CART_DETAILS,
    res
})

export const storePromoCodesData = res => ({
    type: FETCH_PROMO_CODES,
    res
})

export const setCartCount = (val) => ({
    type: SET_CART_COUNT,
    val,
})

export const setVideoCompletionStatus = (val) => ({
    type: SET_VIDEO_COMPLETION_STATUS,
    val,
})

export const setMyCoursePage = (val) => ({
    type: SET_MY_COURSE_HOME,
    val,
})

export const setMyCoursePageForTeacher = (val) => ({
    type: SET_MY_COURSE_HOME_FOR_TEACHER,
    val,
})

export const storeUserMarketPlaceHomeData = (val) => ({
    type: STORE_USER_MARKET_PLACE_HOME_DATA,
    val
})

export const storeOffersCarouselData = (val) => ({
    type: STORE_OFFERS_CAROUSEL_DATA,
    val
})

export const setMyVideoResources = (val) => ({
    type: SET_VIDEO_RESOURCES,
    val,
})

export const setCourseQuizData = (val) => ({
    type: SET_MYQUIZ_DATA,
    val,
})

export const setQuizResults = (val) => ({
    type: SET_QUIZ_RESULTS,
    val,
});

export const setQuizSolution = (val) => ({
    type: SET_QUIZ_SOLUTION,
    val,
})

export const setQuizFlowCount = (val) => ({
    type: SET_QUIZ_FLOW_COUNT,
    val,
})

export const setPdfViewUrl = (val) => ({
    type: SET_PDF_URL,
    val,
})

export const setQaData = (val) => ({
    type: SET_QA_DATA,
    val,
});

export const setStudyMaterialData = (val) => ({
    type: SET_STUDY_MATERIAL,
    val,
})
export const setSideBarStudentHome = (val) => ({
    type: SET_SIDEBAR_STUDENT_HOME,
    val,
})

export const storeUserCourseDetails = (val) => ({
    type: STORE_USER_COURSE_DETAILS,
    val
})

export const storeUserCourseReviews = (val) => ({
    type: STORE_USER_COURSE_REVIEWS,
    val
})

export const setAllCourseData = (val) => ({
    type: SET_ALL_COURSES_DATA,
    val,
})
export const setExploreCourseData = (val) => ({
    type: SET_EXPLORE_COURSE_DATA,
    val,
})

export const setResourceType = (val) => ({
    type: SET_RESOURCES_TYPE,
    val
})
export const setParentLink = (val) => ({
    type: SET_PARENT_LINK,
    val,
})

export const storeMarketSettingsData = (val) => ({
    type: STORE_MARKET_SETTINGS_DATA,
    val
})

export const storeCartCountData = (val) => ({
    type: STORE_CART_COUNT_DATA,
    val
})

export const setIsLogoutClicked = (val) => ({
    type: SET_IS_LOGOUT_CLICKED,
    val
})

export const setIsMyAccountClicked = (val) => ({
    type: SET_IS_MY_ACCOUNT_CLICKED,
    val
})

export const setPromoCode = (val) => ({
    type: SET_PROMO_CODE,
    val
})

export const setIsDownloadBarVisible = (val) => ({
    type: SET_DOWNLOAD_BAR_VISIBLE,
    val
})

export const setShowContactBar = (val) => ({
    type: SET_SHOW_CONTACT_BAR,
    val
})

export const setIsOtpVerified = (val) => ({
    type: SET_IS_OTP_VERIFIED,
    val
})


export const setIsEmailOtpVerified = (val) => ({
    type: SET_IS_EMAIL_OTP_VERIFIED,
    val
})

export const storeEnquiryFormCourseListData = (val) => ({
    type: STORE_ENQUIRY_FORM_COURSE_LIST_DATA,
    val
})

export const setSelectedSuggestion = (val) => ({
    type:SET_SELECTED_SUGGESTION,
    val
})

export const setOffersBarVisible = (val) => ({
    type: SET_OFFERS_BAR_VISIBLE,
    val
})

export const postGenerateEnquiryOtp = (phone, callback = null, successCallBack) => (
    dispatch,
    getState,
) => {
    Utils.stopDuration();
    return Api.post('/market/generate-enquiry-otp').params({ phoneNo: phone })
        .send((response, error) => {
            if (callback) {
                callback()
            }
            if (typeof response !== 'undefined') {
                if (successCallBack) {
                    successCallBack()
                }
                dispatch(handleResendOtpTimer(true, 30));
                Utils.startDuation(30, (callback) => {
                    if (callback === 0) {
                        dispatch(handleResendOtpTimer(false, ''));
                    } else {
                        dispatch(handleResendOtpTimer(true, callback))
                    }
                })
            }
        })
};
export const postVerifyEnquiryOtp = (otp, phone, callBack = null, succesCallback = null) =>
    (dispatch, getState) => {
        return Api.post('/market/verify-enquiry-otp')
            .params({
                phoneNo: phone,
                otp,
            })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type === 'success') {
                    if (succesCallback) {
                        succesCallback(response)
                    }
                    // dispatch(setIsOtpVerified(true))
                    Utils.stopDuration();
                }
            });
    };
export const postUpdateEnquiryDetails = (params, callback = null, successCallBack = null, errorCallBack = null) => (
    dispatch, getState) => {
        if (params?.phone) {
            params.phone = params.phone.replace("++", "+"); 
        }
   
    return Api.post('/market/update-customized-enquiry').params(params).send((response, error) => {
        if (callback) {
            callback()
        }
        if (typeof response !== 'undefined' && response?.show?.type === 'success') {
            if (successCallBack) {
                successCallBack()
            }
        }
        if (response?.show?.type == "error") {
            if (errorCallBack) {
                errorCallBack(response)
            }
        }
    });
};

export const postGenerateEmailEnquiryOtp = (email, callback = null, successCallBack) => (
    dispatch,
    getState,
) => {
    Utils.stopDuration();
    return Api.post('/market/generate-email-otp').params({ email: email })
        .send((response, error) => {
            if (callback) {
                callback()
            }
            if (typeof response !== 'undefined') {
                if (successCallBack) {
                    successCallBack()
                }
                dispatch(handleResendOtpTimer(true, 30));
                Utils.startDuation(30, (callback) => {
                    if (callback === 0) {
                        dispatch(handleResendOtpTimer(false, ''));
                    } else {
                        dispatch(handleResendOtpTimer(true, callback))
                    }
                })
            }
        })
};

export const postVerifyEmailEnquiryOtp = (otp, email, callBack = null, succesCallback = null) =>
    (dispatch, getState) => {
        return Api.post('/market/verify-email-otp')
            .params({
                email: email,
                otp,
            })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type === 'success') {
                    if (succesCallback) {
                        succesCallback(response)
                    }
                    // dispatch(setIsEmailOtpVerified(true))
                    Utils.stopDuration();
                }
            });
    };
export const getEnquiryFormCourseListData = () => {
    return (dispatch, getState) => {
        const params = {
            fromCourse: true,
            names: true,
            emptySearch: true,
            published: true
        }
        return Api.get(`/course/autocomplete-market-resources`).params(params).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(storeEnquiryFormCourseListData(response))
                }
            }
        })
    }
}

export const checkLiveCourse = (param) => {
    console.log("parammmm", param)
    const userData = {

    }
    return (dispatch, getState) => {
        return Api.get(`/user/account-details-by-id/${param?.userId}`).params().send((response) => {
            if (response) {
                console.log("objectresss", response?.user)
                sessionService.saveSession({}).then(() => {
                    const userData = {
                        ...response.user,
                        accessToken: param?.userAccessToken,
                    };
                    sessionService.saveUser(userData).then(() => {
                    }).catch(err => console.error(err));
                })
            }

        })
    }
}
export const setValueForCheckout = (val) => ({
    type: SET_VALUE_FOR_CHECKOUT,
    val
})
export const getCouponList = (param) => {
    return (dispatch, getState) => {
        return Api.get(`/market/applicable-offers`).params(param).send((response) => {
            if (response) {
                if(param.ignoreMin){
                    delete response.minOffers;
                }
                dispatch(setCouponList(response))
            }

        })
    }
};

export const setFromTestSeries = (val) => ({
    type: SET_FROM_TEST_SERIES,
    val
})


export const getCheckPayment = (param, callback) => {
    return (dispatch, getState) => {
        return Api.get(`/market/v2/check-payment`).params(param).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {

            }
        })
    }
};

export const getCheckOutItem = (itemId, itemType, callback) => {
    return (dispatch, getState) => {
        return Api.get(`/market/v2/checkout-item`).params({ itemId, itemType }).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSingleBuyNow(response))
            }
        })
    }
};

export const getUserMarketPlaceHomeData = (noCourse, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/market/market-info`).params({ noCourse }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeUserMarketPlaceHomeData(response))
            }
        })
    }
}

export const impressionEngagementIncrement = (params, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/market/v2/impressionAndEngagement`).params(params ).send((response) => {
            if (callBack) {
                callBack()
            }
            
        })
    }
}
export const getCartCount = () => {
    return (dispatch, getState) => {
        return Api.get(`/market/cart-count`).params().send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeCartCountData(response?.count))
            }
        })
    }
}

export const fetchPromoCodes = (callBack = null) => {
    return (dispatch, getState) => {
        const url = `/market/offers`;
        return (
            Api.get(url).send((response) => {
                if (response !== undefined) {
                    dispatch(storePromoCodesData(response))
                }
                callBack();
            })
        );
    };
};
export const payNowCart = (params, callBack, secondCallBack = null, promo = null) => {
    return (dispatch, getState) => {
        const code = promo || '';
        // const url = `/market/cart-order`;
        const url = `/market/cart-order`;
        return (
            Api.post(url).params(params).send((response) => {
                if(secondCallBack){
                    secondCallBack()
                }
                if (response !== undefined) {
                    callBack(response.body);
                }
                response.promo = code || '';
                dispatch(storeDetailsNeededForPayment(response))
            })
        );
    };
};
export const applyPromo = (params, callback, succesCallback, errorCallback = null) => {
    return (dispatch, getState) => {
        const url = `/market/apply-promo`;
        if(params){
            params.hideInvalid = true;
        }
        return Api.post(url).params(params).send((response, error) => {
            if (callback && typeof callback === 'function') {
                callback(response);
                if(errorCallback && error){
                    errorCallback(error);
                }
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                dispatch(setStorePromoPrice(response))
                if (succesCallback) {
                    succesCallback(response)
                }
            }
        });
    }
}
// export const applyPromoCoursePage = (params, callback) => {
//     return (dispatch, getState) => {
//         const url = `/market/v2/apply-promo-item`;
//         return Api.post(url).params(params).send((response, error) => {
//             if (callback && typeof callback === 'function') {
//                 callback(response);
//                 dispatch(setStorePromoPrice(response?.item))
//             }
//             if (typeof response != "undefined" && response.show.type === "success") {
//                 dispatch(setStorePromoPrice(response?.item))
//             }
//         });
//     }
// }
export const applyPromoCheckout = (params, callback, succesCallback, errorCallBack = null) => {
    return (dispatch, getState) => {
        const url = `/market/v2/apply-promo-item`;
        if(params){
            params.hideInvalid = true;
        }
        return Api.post(url).params(params).send((response, error) => {
            if (callback && typeof callback === 'function') {
                callback(response);
                dispatch(setStorePromoPrice(response?.item))
                console.log('response2', response?.item);
            }
            if(errorCallBack && error){
                errorCallBack(error);
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                dispatch(setStorePromoPrice(response?.item))
                if (succesCallback) {
                    succesCallback(response)
                }
            }
        });
    }
}

export const postCartPayment = (params, callback) => {
    console.log("paramsRedirect", params);
    return (dispatch, getState) => {
        const { user } = getState().session;
        const {storeDeviceName} = getState().studentMarketPlace
        const isLiveParam = {
            userId: user?.id,
            userAccessToken: user?.accessToken,
            os:storeDeviceName
        }

        
        const url = `/market/process-payment`;
        return Api.post(url).params(params).send((response, error) => {
            console.log("response1235", response, error);

            if (callback && typeof callback === 'function') {
                callback(response, error);
            }
            console.log("response1234", response);
            if (typeof response != "undefined" && response.show.type === "success") {
                if (params?.type == "course") {
                    dispatch(checkLiveCourse(isLiveParam));
                }
                dispatch(postPaymentDetails(response))
            }
        });
    };
};

export const getCartDetails = (callback) => {
    return (dispatch, getState) => {
        return Api.get(`/market/cart`).params().send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const postAddToCartSave = (id, itemType, callback) => {
    return (dispatch, getState) => {
        return Api.post(`/market/cart/add-item`).params({ id, itemType }).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const deleteRemoveSaveForLater = (param, callback) => {
    return (dispatch, getState) => {
        return Api.delete(`/market/saved/remove-item`).params(param).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const getSaveForLater = (callback) => {
    return (dispatch, getState) => {
        return Api.get(`/market/saved`).params().send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const postUpdateVideoProgress = (param, callback) => {
    return (dispatch, getState) => {
        return Api.post(`/course/update-video-progress`).params(param).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const getStudyMaterialData = (id, callback) => {
    return (dispatch, getState) => {
        return Api.get(`/course/my-material`).params({ id }).send((response) => {
            if (callback && typeof callback === 'function') {
                callback();
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setStudyMaterialData(response))
            }
        })
    }
}

export const getQuizResultsAttempted = (id, itemId, type, callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/quiz-result`).params({ id, itemId, type }).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setQuizResults(response?.result))
                dispatch(setQuizFlowCount(2));
            } else {
                dispatch(setQuizFlowCount(0));
            }
        })
    }
}

export const getVideoResourceSearch = (param, callback) => {
    return (dispatch, getState) => {
        console.log({ param })
        return Api.get(`/course/get-question-answer`).params(param).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setQaData(response?.items));
                // dispatch(setQaData((prev) => [...new Set([...prev, ...response?.items])]));
            }
        })
    }
}

export const postSaveForLater = (id, itemType, callback = null) => {
    return (dispatch, getState) => {
        return Api.post(`/market/saved/add-item`).params({ id, itemType }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const deleteSaveForLater = (id, itemType, callback = null) => {
    return (dispatch, getState) => {
        return Api.delete(`/market/saved/remove-item`).params({ id, itemType }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}


export const postAddRating = (id, type, ratingValue, ratingExplanation, callback, succesCallback = null) => {
    let param = {
        id: id,
        type: type,
        ratingValue: ratingValue,
        ratingExplanation: ratingExplanation,
    }
    return (dispatch, getState) => {
        return Api.post(`/course/add-rating`).params(param).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (succesCallback) {
                    succesCallback()
                }
            }
        })
    }
}


export const patchLikeVideoResources = (id, videoResourceId, unlike, callback) => {
    return (dispatch, getState) => {
        return Api.patch(`/course/like-question-answer`).params({ id, videoResourceId, unlike }).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}


export const postQaRequest = (param, callback = null) => {
    return (dispatch, getState) => {
        return Api.post(`/course/update-question-answer`).params(param).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}


export const getQuizSolutions = (paramAttemptQuiz, callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/quiz-solution`).params(paramAttemptQuiz).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setQuizSolution(response))
                dispatch(setQuizFlowCount(3));
            } else {
                dispatch(setQuizFlowCount(0));
            }
        })
    }
}


export const postSubmitQuizData = (params, paramAttemptQuiz, callback = null) => {
    return (dispatch, getState) => {
        return Api.post(`/course/submit-quiz`).params({ ...paramAttemptQuiz, questions: params }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setQuizResults(response?.result))
                dispatch(setQuizAttempted(true));
                dispatch(setQuizFlowCount(2));
            } else {
                dispatch(setQuizFlowCount(0));
            }
        })
    }
}


export const getAttemptQuizData = (id, itemId, type, callback) => {
    return (dispatch, getState) => {
        return Api.post(`/course/attempt-quiz`).params({ id, itemId, type }).send((response, error) => {
            if (callback) {
                callback()
            }
            console.log("resppp1", response)
            if (response !== undefined && response?.show?.type != 'error') {
                console.log("resppp", response)
                dispatch(setCourseQuizData(response))
                dispatch(setQuizFlowCount(1))
            }

        })
    }
}
export const getVideoResourcesData = (id, callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/my-resource`).params({ id }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setMyVideoResources(response))
                dispatch(setQaData(response?.qa))
            }
        })
    }
}
export const deleteQuestionText = (id, callback = null) => {
    return (dispatch, getState) => {
        return Api.delete(`/course/delete-question-answer`).params({ id }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {

            }
        })
    }
}


export const getCourseDashBoardData = (filterParam, callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/my-resources`).params(filterParam).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setMyCoursePage(response))
            }
        })
    }
}

export const getCourseDashBoardDataForTeacher = (filterParam, callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/teacher-video-resource-list`).params(filterParam).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setMyCoursePageForTeacher(response))
            }
        })
    }
}

export const getOffersCarouselData = () => {
    return (dispatch, getState) => {
        return Api.get(`/market/offers`).params().send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeOffersCarouselData(response))
            }
        })
    }
}

export const postHomeReachUsForm = (reachUsParams, callback = null, successCallback = null) => {
    return (dispatch, getState) => {
        return Api.post(`/market/enquiry`).params(reachUsParams).send((response, error) => {
            if (callback) {
                callback()
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (successCallback) {
                    successCallback()
                }
            }
        });
    }
}

export const deleteFromCart = (param, callback = null) => {
    return (dispatch, getState) => {
        return Api.delete(`/market/cart/remove-item`).params(param).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const postAddSaveForLater = (id, itemType, callback) => {
    return (dispatch, getState) => {
        return Api.post(`/market/saved/add-item`).params({ id, itemType }).send((response) => {
            if (callback && typeof callback === 'function') {
                callback(response);
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const postAddSaveItem = (id, itemType, callback = null) => {
    return (dispatch, getState) => {
        const params = {
            id, itemType
        }
        return Api.post(`/market/saved/add-item`).params(params).send((response, error) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callback) {
                    callback(response);
                }
            }
        });
    }
}
export const removeSaveItem = (id, itemType, callback = null) => {
    return (dispatch, getState) => {
        const params = {
            itemId: id,
            itemType
        }
        return Api.delete(`/market/saved/remove-item`).params(params).send((response, error) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callback) {
                    callback(response);
                }
            }
        });
    }
}
export const postAddCartItem = (id, itemType, callback = null) => {
    return (dispatch, getState) => {
        const params = {
            id, itemType
        }
        return Api.post(`/market/cart/add-item`).params(params).send((response, error) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callback) {
                    callback(response);
                }
            }
        });
    }
}

export const postCourseEnrollNow = (id, itemType, callback = null) => {
    return (dispatch, getState) => {
        const { user } = getState().session;
        const {storeDeviceName} = getState().studentMarketPlace
        const params = {
            id,
            type: itemType,
            os:storeDeviceName
        }

        const isLiveParam = {
            userId: user?.id,
            userAccessToken: user?.accessToken
        }
        return Api.post(`/market/enroll-user`).params(params).send((response, error) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (itemType == "course") {
                    // dispatch(checkLiveCourse(isLiveParam));
                }
                if (callback) {
                    callback(response);
                }
            }
        });
    }
}

export const getLiveCourseDetails = (id, callBack = null) => {
    return (dispatch, getState) => {
        const { isFromAdminPreview } = getState().courseManagement;
        return Api.get(`/market/courseDetails`).params({ id, preview: isFromAdminPreview ? true : false }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeUserCourseDetails(response))
            }
        })
    }
}

export const getVideoResourceDetails = (id, callBack = null) => {
    return (dispatch, getState) => {
        const { isFromAdminPreview } = getState().courseManagement;
        return Api.get(`/market/vResourceDetails`).params({ id, preview: isFromAdminPreview ? true : false}).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeUserCourseDetails(response))
            }
        })
    }
}
export const getStudyMaterialDetails = (id, callBack = null) => {
    return (dispatch, getState) => {
        const { isFromAdminPreview } = getState().courseManagement;
        return Api.get(`/market/studyMaterialDetails`).params({ id, preview: isFromAdminPreview ? true : false }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeUserCourseDetails(response))
            }
        })
    }
}

export const getTestSeriesDetails = (id, callBack = null) => {
    return (dispatch, getState) => {
        const { isFromAdminPreview } = getState().courseManagement;
        return Api.get(`/market/getTestSeriesDetails`).params({ id, preview: isFromAdminPreview ? true : false }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeUserCourseDetails(response))
            }
        })
    }
}


export const postUsersAddRating = (params, callback = null) => {
    return (dispatch, getState) => {
        const param = {
            id: params?.id,
            type: params?.resourceType,
            ratingValue: params?.ratingValue,
            ratingExplanation: params?.ratingExplanation
        }
        return Api.post(`/course/add-rating`).params(param).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
            }
        })
    }
}

export const getUserCourseAutocompleteSearch = (search, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/market/autocomplete-resources`).params({ search }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (response !== undefined && response?.show?.type != 'error') {
                    if (callBack) {
                        callBack(response)
                    }
                }
            }
        })
    }
}
export const getCourseDetails = (token, id, callback) => {
    return (dispatch, getState) => {
        let params = {
            token: token,
            id: id
        }
        const url = `/course/my-course`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                console.log("sssuuuuuu", response?.studentCourseStandards)
                callback();
                dispatch(storeUserCourseDetails(response))
                console.log("sssuuuuuu2", response?.studentCourseStandards)

            };
        });
    };
};
export const getCourseReviews = (itemType, id, page, callback) => {
    return (dispatch, getState) => {
        let params = {
            itemType: itemType,
            id: id,
            page:page,
            limit:10
        }
        const url = `/market/course-reviews`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(storeUserCourseReviews(response))

            };
        });
    };
};

export const getAllCoursesData = (token, fromHomePage, cId, callback, search) => {
    return (dispatch, getState) => {
        console.log(cId, 'cId', fromHomePage);
        let params
        if (cId == '') {
            params = {
                token: token,
            }
        } else if (fromHomePage == 'pid') {
            
            params = {
                pId: cId
            }
        }
        else {
            params = {
                token: token,
                cId: cId
            }
        }

        const url = `/market/resourcesInfo`;
        return Api.get(url).params({ ...params, search }).send((response) => {
            if (response) {
                callback();
                dispatch(setAllCourseData(response))
            };
        });
    };
};

export const getAllStudyMaterials = (filtersData, type, categoryId, search, callback) => {
    return (dispatch, getState) => {
        let params
        if (categoryId == '') {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                search: search
            }
        } else if (type == 'pid') {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                pId: categoryId,
                search: search
            }
        }
        else {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                cId: categoryId,
                search: search
            }
        }
        const url = `/market/studyMaterialList`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};


export const getAllTestSeries = (filtersData, type, categoryId, search, callback) => {
    return (dispatch, getState) => {
        let params
        if (categoryId == '') {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                search: search
            }
        } else if (type == 'pid') {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                pId: categoryId,
                search: search
            }
        }
        else {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                cId: categoryId,
                search: search
            }
        }

        const url = `/market/test-series-list`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};

export const AllCoursesData = (token, cId, callback, loader) => {
    if (loader)
        loader(true)
    Api.get("/market/resourcesInfo")
        .params({ cId })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const getCourseList = (filter, type, categoryId, totalPage, search, callback) => {
    return (dispatch, getState) => {
        console.log("filter1", categoryId);
        let params
        if (categoryId == '') {
            params = {
                period: filter?.period,
                feeType: filter?.free,
                mode: filter?.mode,
                page: 1,
                limit: totalPage,
                search: search
            }
        } else if (type == 'pid') {
            params = {
                period: filter?.period,
                feeType: filter?.free,
                mode: filter?.mode,
                pId: categoryId,
                page: 1,
                limit: totalPage,
                search: search
            }
        }
        else {
            params = {
                period: filter?.period,
                feeType: filter?.free,
                mode: filter?.mode,
                cId: categoryId,
                page: 1,
                limit: totalPage,
                search: search
            }
        }

        console.log("params", params);
        const url = `/market/courseList`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};

export const getVideoResourcesList = (filtersData, type, categoryId, totalPage, search, callback) => {
    return (dispatch, getState) => {
        let params
        if (categoryId == '') {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                page: 1,
                limit: totalPage,
                search: search
            }

        } else if (type == 'pid') {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                pId: categoryId,
                page: 1,
                limit: totalPage,
                search: search
            }
        }
        else {
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                cId: categoryId,
                page: 1,
                limit: totalPage,
                search: search
            }
        }

        const url = `/market/videoResourceList`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};
export const CourseListApi = (token, filter, callback, loader, page, totalPage) => {
    if (loader) {
        loader(true)
    }
    console.log("insideee", filter);
    let param = null;
    if (filter === "") {
        param = {

            periodType: null,
            feeType: null,
            mode: null,
            page: page,
            limit: totalPage,
        }
    } else {
        param = {
            periodType: null,
            feeType: null,
            mode: null,
            page: page,
            limit: totalPage,
        }
        if (filter === "ongoing" || filter === "upcoming") {
            param.periodType = filter;
        }
        else if (filter === "online" || filter === "offline" || filter === "hybrid") {
            param.mode = filter;
        }
        else if (filter === "free") {
            param.feeType = filter;
        }
        return (
            Api.get("")
                .params(param)
                .send((response) => {
                    if (response) {
                        callback(response)
                    }
                    if (loader) {
                        loader(false)
                    }
                    Api.header(token);
                })
        )
    }
}

export const getMarketSettingsData = (all, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/market/market-setting`).params(all ? { all: true } : null).send((response) => {
            if (callBack) {
                callBack(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeMarketSettingsData(response))
            }
        })
    }
}

export const getSubjectsDataCourseContent = (id, callback) => {
    return (dispatch, getState) => {
        return Api.get(`/subjects/get-standard-subjects`).params({ id }).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {

            }
        })
    }
}
export const getChapterDataCourseContent = (param, callback) => {
    return (dispatch, getState) => {
        return Api.get(`/subjects/getChapters`).params(param).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setCollapseChaptersList(response));
            }
        })
    }
}

export const storeCourseIdAndType = (key, val) => ({
    type: STORE_COURSE_ID_AND_TYPE,
    key,
    val
})

export const setCourseData = (val) => ({
    type: STORE_COURSE_DATA,
    val
})

export const getUserMarketPlaceCoursesData = (requestsParams, callBack = null) => {
    return (dispatch, getState) => {
        console.log("noCourse",requestsParams);
        return Api.get(`/market/courses`).params(requestsParams).send((response) => {
            console.log("respobsesss",response);
            if (callBack) {
                callBack(response)

            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setCourseData(response))
            }
        })
    }
}

export const setSpecificDeviceName = (val) => ({
    type: SET_DEVICE_SPECIFIC_NAME,
    val
})