import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  getStudentsBySecId,
  setName,
  setSpecificUserId,
  setUserName,
} from "actions/userManagement";
import { Col, Pagination, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import UserCard from "./userCard";
import { IMG_URL } from "../../env.json";
import PageDataLoader from "components/pageDataLoader";
import { setOverviewFilter } from "actions/feeManagement";
import PageHeader from "components/pageHeader";
import { InputSearch} from 'components/customInputs';


const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;


function AllStudents({
  setUserName,
  getStudentsBySecId,
  studentsListByStd,
  sectionDetails,
  setName,
  setSpecificUserId,
  setOverviewFilter,
}) {
  const [pageLoader, setPageLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setPageLoader(true);
    setName(null);
    getStudentsBySecId(() => setPageLoader(false));
  }, []);

  const handleBackPress = () => {
    history.goBack();
  }

  const handleSearch = (e) => {
    console.log(e, 'val');
    setName(e);
    setPageLoader(true);
    getStudentsBySecId(() => setPageLoader(false));
  };

  const handleViewDetails = (item) => {
    setSpecificUserId( item?.user?.id);
    setOverviewFilter("userId", item?.user?.id);

    history.push("/user-management/students/details");
  };

  const showMoreUsers = (e) => {
    setPageLoader(true);
    getStudentsBySecId(() => setPageLoader(false), e);
  };

  const actions = [
    <InputSearch placeholder="Search By Name / Phone No." onClear={()=> handleSearch('')} onInputChange={(e) => handleSearch(e)} allowClear/>
  ]

  return (
    <div>
      <div style={{marginLeft: -20}}>
        <PageHeader
          title={ `${sectionDetails?.std}: ${sectionDetails?.section} - ${" "} ${sectionDetails?.courseName} (${studentsListByStd?.response?.meta?.totalItems})` }
          actions={actions}
          onBackPress={()=>handleBackPress()}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "85vh",
          marginTop: 80
        }}
      >
        <Row style={{flexWrap: "wrap", gap: 32}}>
          {studentsListByStd?.response?.items?.length !== 0 &&
            studentsListByStd?.response?.items?.map((item) => (
              
              <Col 
                  style={{ cursor: "pointer", width: "22%", minWidth: 250}}
                  onClick={() => {handleViewDetails(item);}}
                >
                <UserCard
                  rollNo={true}
                  rollNumVal={item?.rollNo}
                  name={item?.user?.name}
                  role={item?.user?.role[0]}
                  avatar={`${IMG_URL}/${
                    item?.user?.image ? item?.user?.image : "default.png"
                  }`}
                />
              </Col>
            ))}
          {studentsListByStd?.response?.items?.length == 0 ? (
            <div
              className="r-c-c-c"
              style={{ width: "100%", height: "60vh", marginTop: "-50px" }}
            >
              <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
              >
                <img src={illNoData} />
              </div>
              <div>
                <h4>
                  <b>No data!</b>
                </h4>
              </div>
              <div>
                <h5>There are no data to show yet. Please check back later</h5>
              </div>
            </div>
          ) : null}
        </Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            size='small'
            defaultCurrent={1}
            current={studentsListByStd?.response?.meta?.currentPage}
            total={studentsListByStd?.response?.meta?.totalPages * 10}
            onChange={showMoreUsers}
          />
        </div>
      </div>
      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

// export default AllStudents;
const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    dashboardData,
    sectionDetails,
    dashBoardDataByRole,
    studentsListByStd,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashBoardDataByRole,
    dashboardData,
    sectionDetails,
    studentsListByStd,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserName: (name) => dispatch(setUserName(name)),
  getStudentsBySecId: (callback, page) =>
    dispatch(getStudentsBySecId(callback, page)),
  setName: (search) => dispatch(setName(search)),
  setSpecificUserId: (id) => dispatch(setSpecificUserId(id)),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllStudents);
