import React , {useEffect, useState} from 'react';
import { Button, DatePicker, Modal, Radio} from 'antd';
import moment from 'moment';
import { CheckCircleFilled, LineHeightOutlined } from '@ant-design/icons';
import { setSpecificUserId } from 'actions/userManagement';
/* import './AttendanceReport.css' */

const DownloadReport = ({
  isModelVisible,
  setisModelVisible,
  usersDownloadReport,
  storeSelectedRange,
  storeStartDate,
  storeEndDate,
  startDateContainer,
  selectedRangeContainer,
  setSpecificUserId,
  user
}) => {

  const [value, setValue] = useState(0);
  const [isDownloadBtnLoading,setIsDownloadBtnLoading]=useState(false)

  const handleCancel = (e) => {
    e.stopPropagation()
    setValue('')
    setisModelVisible(false);
  };

  const onRadioChange = (e) => {
    setValue(e.target.value);
  };

  const RangeStyle = {
    font: "normal normal normal 15px/21px Roboto",
    color:"#191919",
    margin:"5px",
  }

  function disabledDate(current) {
    console.log("current",current);
    return  current >= moment().endOf('day') || current <= moment(startDateContainer);
  }

  function disabledstartDate(current) {
    return current >= moment().endOf('day')
}

  useEffect(() => {
    storeStartDate(null);
    storeEndDate(null);
  }, []);



  return (
    <div>
      <Modal 
        style={{height: '600px'}}
        centered   
        className="modal-round-corner"
        visible={isModelVisible}
        onCancel={handleCancel}
        header={null}
        footer={null}>
          {console.log("burijhiu",selectedRangeContainer===null)}
        <div style={{display:"flex", justifyContent:"center", color:"#191919", fontSize:"22px",fontWeight:700}}>Select range</div>
          <div style={{ color:"#191919", fontSize:"14px",fontWeight:600,padding:"10px"}}>
              <Radio.Group onChange={onRadioChange} value={value} style={{display:"flex",flexDirection:"column", alignItems:"flex-start"}}>
                <Radio 
                onClick={()=>{storeSelectedRange("lastweek"),console.log(selectedRangeContainer===null)}}
                value={1} style={{marginTop:"20px"}}>
                    <div style={RangeStyle}>Last week</div></Radio>
                <Radio
                onClick={()=>{storeSelectedRange("lastmonth")}}
                value={2}>
                    <div style={RangeStyle}>Last month</div></Radio>
                <Radio 
                onClick={()=>{storeSelectedRange("lastthreemonths")}}
                value={3}>
                    <div style={RangeStyle}>Last 3 month</div></Radio> 
                <Radio 
                onClick={()=>{storeSelectedRange("lastsixmonths")}}
                value={4}>
                    <div style={RangeStyle}>Last 6 month</div></Radio> 
                <Radio 
                onClick={()=>{storeSelectedRange("yeartodate")}}
                value={5}>
                    <div style={RangeStyle}>Year to date</div> </Radio>
                <Radio onClick={()=>{
                  setValue(6);
                  storeSelectedRange("specificDateRange");
                  }} value={6}>
                    <div style={RangeStyle}
                    >Specific date range</div></Radio> 
              </Radio.Group>
          </div>

          {value === 6 
          ? 
          <div className='flex-row' style={{justifyContent:"space-around",marginTop:"10px"}}>
            <div>
              <div style={{color:"#636363", fontSize:"13px"}}>Start date</div>
              <div><DatePicker
              allowClear={false} 
              style={{width:"200px"}} 
              onChange={(e) => storeStartDate(e.format('YYYY-MM-DD'))}
              disabledDate={disabledstartDate}/></div>
            </div>
          <div>
          <div style={{color:"#636363", fontSize:"13px"}}>End date</div>
            <div><DatePicker
            allowClear={false}
            disabled={startDateContainer===null?true:false}  
            style={{width:"200px"}} 
            onChange={(e) => storeEndDate(e.format('YYYY-MM-DD'))}
            disabledDate={disabledDate}/>
            </div>
          </div>
          </div>
            : null }

          <div  style={{display:"flex",justifyContent:"center", marginTop:"200px"}}>
            <Button
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#FFFFFF",
                border: "1px solid #636363 ",
                color: "#636363",
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
            <Button
            disabled={selectedRangeContainer === null ? true : false} 
            loading={isDownloadBtnLoading}
            className='m-l-30'
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#594099",
                border: "1px solid #636363 ",
                color: "#FFFFFF",
                opacity: selectedRangeContainer === null ? 0.5 : 1
              }}
              onClick={()=>{
                setIsDownloadBtnLoading(true)
                if(window.location.pathname == '/profile'){
                  setSpecificUserId(user.id)
                }
                usersDownloadReport(
                  ()=>{
                  setIsDownloadBtnLoading(false);
                  setValue('');
                  storeSelectedRange('');},
                  ()=>{
                    setisModelVisible(false)
                  }
                )
              }}
            >
            DOWNLOAD
            </Button>
          </div>
      </Modal>
  </div>
)
}

export default DownloadReport 