import React from 'react';
import {connect} from 'react-redux';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import 'antd/lib/layout/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { setPassword } from '../../actions/login';
import './styles.css';


class UserPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      password: "",
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }

  setPassword(history){
    let user = {email : this.state.email, password : this.state.password}
    this.props.dispatch(setPassword(user, history));
    this.setState({
      email: "",
      password: "",
    })
  }


  render() {
    const { history, loginLoader } = this.props;

    return (
      <Layout style={{height: '100vh', padding:'60px'}}>
        <div className='r-c-c-c'>
          <div className='login-wrap'>
            <div className='text-md bold-600 color-black m-b-30'>Set a User Password</div>
            <div className='m-b-20'>
              <Input
                onChange={(em) => this.setState({email: em.target.value})}
                style={{borderRadius:'5px'}}
                prefix={<UserOutlined style={{color:'#787b8370'}} />}
                className='globalInput'
                size="large"
                type="email"
                id="email"
                value={this.state.email}
                placeholder="Email"
              />
            </div>
            <div className='m-b-10'>
              <Input.Password
                 onChange={(pass) => this.setState({password: pass.target.value})}
                 style={{borderRadius:'5px'}}
                 prefix={<LockOutlined style={{color:'#787b8370'}} />}
                 className='globalInput'
                 size="large"
                 value={this.state.password}
                 placeholder="Password"
              />
            </div>
            <div className='r-c-c m-t-50 m-b-30'>
              <Button
                className='login-btn'
                onClick={() => this.setPassword(history)}
                loading={loginLoader}
                type="primary"
                key="submit"
              >
                Set Password
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const {
    loginLoader
  } = state.login;
  return {
    loginLoader
  };
};

export default connect(mapStateToProps)(UserPassword);
