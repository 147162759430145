import { ClockCircleOutlined, CloseOutlined, LockOutlined, ProfileOutlined, QuestionCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import HtmlParser from 'react-html-parser';
import { connect } from 'react-redux'
import { Button } from 'components/Button';
import { downloadReportTestSeries, setIndividualTestId, setOwnedTestSeries } from 'actions/marketTest';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment';
import { setSelectedTest } from 'actions/studentTest';



const IndividualTests = ({ singleCourseDetailsDataContainer, setIndividualTestId, fromTestSeriesPage, setSelectedTest ,downloadReportTestSeries,setOwnedTestSeries}) => {

    const history = useHistory();
    let currentDate = new Date();
    let currentDateString = currentDate.toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
    let currentTimeString = currentDate.toLocaleTimeString('en-US', { hour12: false });
     const [isLoading,setIsLoading] = useState(false)
 
    console.log('herePrint', singleCourseDetailsDataContainer?.testSeriesDetails?.id    );
    return (
        <div style={{ width: '100%', height: 'fit-content', padding: fromTestSeriesPage ? 0 : 60 }}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <div className='course-content-header' style={{ paddingLeft: fromTestSeriesPage ? 0 : 60 }}> {singleCourseDetailsDataContainer?.testData?.length > 1 ? 'Tests':'Test'}</div>
            
            <div ><Button loading={isLoading} type='link' style={{color:"#1089FF",fontSize:"17px",fontWeight:600 ,cursor:"pointer"}} onClick={()=>{
                setIsLoading(true)
                downloadReportTestSeries(singleCourseDetailsDataContainer?.testSeriesDetails?.id,()=>{},()=>{setIsLoading(false)})
            }}>Download test-wise syllabus</Button></div>
            
            
            </div>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between', paddingLeft: fromTestSeriesPage ? 0 : 55, paddingRight: fromTestSeriesPage ? 15 : 55, paddingTop: 45 }}>
                {singleCourseDetailsDataContainer?.testData?.map((item) => {
                    let itemSTime = moment(`${item?.publishDate} ${item?.startTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
                    let itemETime = moment(`${item?.publishDate} ${item?.startTime}`, 'YYYY-MM-DD HH:mm').add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss')

                    return (

                        <div style={{ backgroundColor: "#FFFFFF", width: '48%', height: 'fit-content', marginBottom: 40, paddingBottom: 20, borderRadius: 10, border: '1px solid #E6E6E6' }}

                        // onClick={() => {
                        //     setIndividualTestId(item.id)
                        //     history.push("/ongoingTest/questions")
                        // }}
                        // /ongoingTest/questions
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <div style={{ marginLeft: 20, width: '40%' }}>
                                    <div style={{
                                        fontWeight: 'bold',
                                        marginTop: 20,
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer',
                                    }}
                                        title={item?.title}
                                    >{item?.title}</div>
                                    <div style={{ color: '#636363', marginTop: 20 }}> <QuestionCircleOutlined /> <h style={{ marginLeft: 5 }}>{item?.totalQuestions} Questions</h></div>
                                    <div style={{ color: '#636363', marginTop: 5 }}> <ProfileOutlined /><h style={{ marginLeft: 9 }}>{item?.totalMarks} Marks</h></div>
                                    <div style={{ color: '#636363', marginTop: 5 }}><ClockCircleOutlined /><h style={{ marginLeft: 9 }}>{item?.duration} Minutes</h></div>
                                </div>

                                <div style={{ paddingRight: '12%' }}>
                                    <div style={{ marginTop: '90px' }}>
                                        {console.log("test", item)}
                                        {(item?.publishDate && itemSTime > moment().format('YYYY-MM-DD HH:mm:ss'))
                                            ? <div style={{ fontSize: 12, color: '#594099' }}>Publishing on {moment(item?.publishDate).format('DD-MM-YYYY')},{moment(item?.startTime, 'HH:mm').format('hh:mm A')}</div>
                                            :
                                            // (item?.publishDate === currentDateString && item?.startTime > currentTimeString && moment(item?.startTime, 'HH:mm').add(item?.duration, 'minutes').format('HH') < currentTimeString)
                                            //  ?
                                            //  <><Button onClick={() => {
                                            //     setIndividualTestId(item.id)
                                            //     if (!item?.endTime) {
                                            //         if (!item?.publishDate) {
                                            //             setSelectedTest({ ...item, endTime: moment().add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                            //         }
                                            //         else {
                                            //             setSelectedTest({ ...item, endTime: moment(`${publishDate} ${startTime}`, 'YYYY-MM-DD HH:mm').add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                            //         }
                                            //     }
                                            //     else {
                                            //         setSelectedTest({ ...item, endTime: moment(item?.endTime).format('YYYY-MM-DD HH:mm:ss') })
                                            //     }
                                            //     console.log("test12345", item)
                                            //     history.push("/ongoingTest/questions")
                                            // }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>ATTEMPT</Button></>
                                            //   :
                                            item?.submitted == "Graded"
                                                ? !(item?.publishDate) ?
                                                    <div style={{ display: 'flex', alignContent: 'center' }}>

                                                        <div onClick={() => {
                                                            setIndividualTestId(item.id)
                                                            setSelectedTest(item)
                                                            history.push("/testSeries/result")
                                                        }} style={{ fontSize: 13, color: '#1089FF', fontWeight: 'bold', marginTop: '15px', marginRight: '10px' ,cursor:"pointer"}}>View Results</div>

                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            <Button onClick={() => {
                                                                setOwnedTestSeries(singleCourseDetailsDataContainer?.testSeriesDetails?.owned)
                                                                setIndividualTestId(item.id)
                                                                setSelectedTest({ ...item, endTime: moment().add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                                history.push("/ongoingTest/questions")
                                                            }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>RE-ATTEMPT</Button>

                                                            {/* Add another button here if needed */}
                                                        </div>

                                                    </div>
                                                    :
                                                    <div onClick={() => {
                                                        setIndividualTestId(item.id)
                                                        history.push("/testSeries/result")
                                                    }} style={{ fontSize: 13, color: '#1089FF', fontWeight: 'bold', marginTop: '15px', marginRight: '10px' ,cursor:'pointer'}}>View Results</div>
                                                :
                                                item?.submitted == "Submitted" ? <div style={{ fontSize: 12, color: '#594099' }}>Result will be available soon</div>
                                                    : item?.submitted == "Draft" ? <><Button onClick={() => {
                                                        setIndividualTestId(item.id)
                                                        if (!item?.endTime) {
                                                            if (!item?.publishDate) {
                                                                setSelectedTest({ ...item, endTime: moment().add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                            }
                                                            else {
                                                                setSelectedTest({ ...item, endTime: moment(`${item?.publishDate} ${item?.startTime}`, 'YYYY-MM-DD HH:mm').add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                            }
                                                        }
                                                        else {
                                                            setSelectedTest({ ...item, endTime: moment(item?.endTime).format('YYYY-MM-DD HH:mm:ss') })
                                                        }
                                                        console.log("test12345", item)
                                                        history.push("/ongoingTest/questions")
                                                    }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>ATTEMPT</Button></>
                                                        :
                                                        (item?.publishDate != null && itemETime < moment().format('YYYY-MM-DD HH:mm:ss')) ?
                                                            <div style={{ fontSize: 12, color: '#594099' }}>Test is Unavailable</div>
                                                            : (item?.publishDate != null && itemETime > moment().format('YYYY-MM-DD HH:mm:ss'))
                                                                ? <><Button onClick={
                                                                    (singleCourseDetailsDataContainer?.testSeriesDetails?.owned == 1) ?
                                                                        () => {
                                                                            setIndividualTestId(item.id)
                                                                            if (!item?.endTime) {
                                                                                if (!item?.publishDate) {
                                                                                    setSelectedTest({ ...item, endTime: moment().add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                                                }
                                                                                else {
                                                                                    setSelectedTest({ ...item, endTime: moment(`${item?.publishDate} ${item?.startTime}`, 'YYYY-MM-DD HH:mm').add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                                                }
                                                                            }
                                                                            else {
                                                                                setSelectedTest({ ...item, endTime: moment(item?.endTime).format('YYYY-MM-DD HH:mm:ss') })
                                                                            }
                                                                            console.log("test12345", item)
                                                                            history.push("/ongoingTest/questions")
                                                                        } : () => { }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>
                                                                    ATTEMPT
                                                                    {(singleCourseDetailsDataContainer?.testSeriesDetails?.owned == 1) ? null : <LockOutlined style={{ marginLeft: 5 }} />}
                                                                </Button></>
                                                                : (item?.freePreview == 1 || singleCourseDetailsDataContainer?.testSeriesDetails?.owned == 1) ?
                                                                    <><Button onClick={() => {
                                                                        setIndividualTestId(item.id)
                                                                        if (!item?.endTime) {
                                                                            if (!item?.publishDate) {
                                                                                setSelectedTest({ ...item, endTime: moment().add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                                            }
                                                                            else {
                                                                                setSelectedTest({ ...item, endTime: moment(`${publishDate} ${startTime}`, 'YYYY-MM-DD HH:mm').add(item?.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss') })
                                                                            }
                                                                        }
                                                                        else {
                                                                            setSelectedTest({ ...item, endTime: moment(item?.endTime).format('YYYY-MM-DD HH:mm:ss') })
                                                                        }
                                                                        history.push("/ongoingTest/questions")
                                                                    }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>ATTEMPT</Button></>
                                                                    : <><Button onClick={() => { }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>ATTEMPT <LockOutlined style={{ marginLeft: 5 }} /></Button></>
                                        }

                                        {/* 
                                        {item?.submitted == "Submitted" ?
                                            <><Button onClick={() => {
                                                setIndividualTestId(item.id)
                                                history.push("/testSeries/result")
                                            }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>VIEW RESULTS</Button></>

                                            :

                                            item.freePreview == 1 ? (
                                                <Button onClick={() => {
                                                    setIndividualTestId(item.id)
                                                    history.push("/ongoingTest/questions")
                                                }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>ATTEMPT</Button>
                                            ) : (
                                                <Button onClick={() => { }} style={{ marginBottom: 15, marginTop: 10, backgroundColor: "#FFFFFF", fontSize: 13, color: '#594099', fontWeight: 'bold' }}>ATTEMPT <LockOutlined style={{ marginLeft: 5 }} /></Button>


                                            )} */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    )

                })}
            </div>
        </div >


    )
}

const mapStateToProps = (state) => {

    return {

    };
};

const mapDispatchToProps = (dispatch) => ({
    setIndividualTestId: (data) => dispatch(setIndividualTestId(data)),
    setOwnedTestSeries:(data)=>dispatch(setOwnedTestSeries(data)),
    setSelectedTest: (data) => dispatch(setSelectedTest(data)),
    downloadReportTestSeries:(id,callback , successCallBack) => dispatch(downloadReportTestSeries(id,callback , successCallBack))


});


export default connect(mapStateToProps, mapDispatchToProps)(IndividualTests);