import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

const AttendanceByTimeSheetGraph = ({attendanceOverview}) => {
    const data =attendanceOverview?.attendanceByTimesheet?.attendanceDetails ? attendanceOverview?.attendanceByTimesheet?.attendanceDetails?.map((item)=>({
        'unit':item.unit,'durationInMinutes':item.durationInMinutes/60 ,'clockIn':item?.clockIn,'clockOut':item?.clockOut,'workingHrs':item?.workingHours
    })):[]
    const config = {
        data,
        xField: "unit",
        yField: "durationInMinutes",
        color: "#28DF99", 
        tooltip:
        {customContent:(val ,item)=>{
          return(
            <div>
              <div style=
              {{
                height:"50px",
                minWidth:"200px",
              }}>
                <div style={{ width: '220px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around',marginTop:"20px" }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ fontSize: '11px',fontFamily:"Roboto" }}>CLOCK IN</div>
                      <div className='text-heading' style={{ fontSize: '14px',fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.clockIn!==null?item[0]?.data?.clockIn:"0:00"}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column',marginLeft:"10px" }}>
                      <div style={{ fontSize: '11px',fontFamily:"Roboto"}}>CLOCK OUT</div>
                      <div className='text-heading' style={{ fontSize: '14px',fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.clockOut!==null?item[0]?.data?.clockOut:"0:00"}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column',marginLeft:"10px" }}>
                      <div style={{ fontSize: '11px',fontFamily:"Roboto"}}>WORKING HRS</div>
                      <div className='text-heading' style={{ fontSize: '14px',fontFamily:"Roboto",fontWeight:"bold",marginTop:"10px"}}>{item[0]?.data?.workingHrs!==null?item[0]?.data?.workingHrs:"0:00"}</div>
                  </div>
                 </div>
    
                  
              </div>
            </div>
          )
    
        }},   
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
      
        meta: {
          type: {
            alias: "Attendance",
          },
        },
        slider: {
          start: 0.1,
          end: 0.5,
        },
    
        minColumnWidth: 20,
        maxColumnWidth: 40,
      };
  return <Column {...config} />;
};


export default AttendanceByTimeSheetGraph;

