import { Col, Modal, Row } from 'antd';
import { Heading } from 'components/Typography';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { ProfileInput } from './detailFields';
import ProfileInput from './detailFields'
import {Button} from 'components/Button';
import moment from 'moment';
import { editLeaveInProfile } from 'actions/profileNew';

const EditLeaveModal = ({ visible, onClose, leaveDetails, user, editLeaveInProfile, fromWhere, userRowDetails }) => {
    const [loader, setLoader] = useState(false);
    const [availableLeaves, setAvailableLeaves] = useState([]);

    console.log(fromWhere, userRowDetails)

    useEffect(() => {
        const temp = leaveDetails?.leaveTypesForUser?.map(item => ({...item})) || []
        setAvailableLeaves(temp);
        return () => {
            setAvailableLeaves([])
        }
    }, []);

    const handleOnChange = (index, key, value) => {
        availableLeaves[index][key] = parseInt(value);
        setAvailableLeaves([...availableLeaves]);
        
    }

    const handleUpdate = () => {
        const params = {
            userId: fromWhere == 'userManagementProfile' ? userRowDetails?.id : user.id,
            leaveTypes: availableLeaves?.map((leave) => ({
                leaveType: leave?.leaveType,
                noOfLeaves: leave?.remaining,
                availed: leave?.availed
            }))
        }
        setLoader(true);
        editLeaveInProfile(params, () => setLoader(false), () => onClose(), ()=>{})
    }

    return (
        <Modal
            open={visible}
            footer={false}
            className="modal-round-corner"
            onCancel={onClose}
            destroyOnClose={true}
        >
            <div className='r-c-c m-t-20'>
                <Heading>Edit leave(s)</Heading>
            </div>
            <div style={{height: "60vh", overflowY: 'auto', padding: 10}}>
            {availableLeaves?.map((leave, index) => 
                <div key={index}>
                    <div style={{fontSize: 16, fontWeight: 500}} className="m-b-10">{leave.leaveType}</div>
                    <Row>
                            <Col span={12}>
                                <div style={{width: "95%"}}>
                                    <ProfileInput
                                        label={"Start Date"}
                                        type={"date"}
                                        value={leave.startDate}
                                        onChange={value => {handleOnChange(index, 'startDate', value)}}
                                        placeholder={"Select start date"}
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div style={{width: "95%"}}>
                                    <ProfileInput
                                        label={"End Date"}
                                        type={"date"}
                                        value={leave?.endDate}
                                        onChange={value => {handleOnChange(index, 'endDate', value)}}
                                        placeholder={"Select end date"}
                                        disabled
                                    />
                                </div>
                            </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <div style={{width: "95%"}}>
                                <ProfileInput
                                    label={"Balance"}
                                    type={"number"}
                                    value={leave?.remaining ? leave?.remaining : null}
                                    onChange={e => {handleOnChange(index, 'remaining', e.target.value)}}
                                    placeholder={""}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            
                            <div style={{width: "95%"}}>
                                <ProfileInput
                                    label={"Availed"}
                                    type={"number"}
                                    value={leave?.availed }
                                    onChange={e => {handleOnChange(index, 'availed', e.target.value)}}
                                    placeholder={"Enter number"}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{width: "95%"}}>
                                <ProfileInput
                                    disabled
                                    label={"Total"}
                                    type={"number"}
                                    value={leave?.total ? leave?.total : null}
                                    onChange={e => {handleOnChange(index, 'total', e.target.value)}}
                                    placeholder={""}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
            </div>
            
            <div className='r-c-c m-t-20'>
                <Button type="primary" onClick={() => handleUpdate()} loading={loader}>
                    UPDATE
                </Button>
            </div>
            
        </Modal>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session;
    const { leaveDetails } = state.profileNew;
    const {userRowDetails} = state.userManagementV2
    return {
        leaveDetails, user, userRowDetails
    }
}

const mapDispatchToProps = (dispatch) => ({
    editLeaveInProfile: (params, callback, successCallBack, pagecallback) => dispatch(editLeaveInProfile(params, callback, successCallBack, pagecallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditLeaveModal);