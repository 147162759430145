import React, { useState } from 'react';
import image from "Assets/user-management/Icon awesome-user-alt.svg";
import { Col, Pagination, Row, Select, Typography } from 'antd';
import { IMG_URL } from "../../env.json";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function SearchUserCard ({avatar,name,cell,flag,section,std,rollNo}) {
    return(
        <div
          className="userCardStyle " style={{borderRadius: 16,  width: "100%"}}>
          <div style={{  }}>
              {avatar?.substring(avatar.length - 11, avatar.length) !==
                  "default.png" ? (
                  <img
                    width="55px"
                    height="55px"
                    style={{ borderRadius: "50%" }}
                    src={avatar}
                  ></img>
              ) : (
                  <img
                      width="55px"
                      height="55px"
                      style={{
                          borderRadius: "50%",
                          backgroundColor: "#F6F4FE",
                          padding: "15px",
                      }}
                      src={image}
                  ></img>
              )}
          </div>
          <div style={{ marginLeft: "10px", marginTop: "5px" }}>
            <Typography.Text
            ellipsis={{tooltip: true}}
                style={{
                marginBottom: "0",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100px",
                textOverflow: "ellipsis",
                fontWeight: 600,
                fontFamily: 'roboto'
                // border: '1px solid red'
                }}
            >
                {name}
            </Typography.Text>
                <p
                    style={{
                        color: "#AEAEAE",
                        marginBottom: "0",
                        overflow: "hidden",
                        width: "130px",
                        textOverflow: "ellipsis",
                    }}
                    >
                    {cell}
                </p>
              {flag == "unAssigned" ?
                  <p
                      style={{
                          color: "#AEAEAE",
                          marginBottom: "0",
                          overflow: "hidden",
                          width: "130px",
                          textOverflow: "ellipsis",
                      }}
                  >
                      Unassigned
                  </p>
                  :
                  <div style={{ display: "flex",justifyContent:"space-between"}}>
                      <Typography.Text
                      className="text-overflow-ellipsis"
                          style={{
                              color: "#AEAEAE",
                              marginBottom: "0",
                              width:"60%"
                          }}
                          ellipsis={{tooltip:true}}
                      >
                          {std}{" "} - {" "}{section}
                      </Typography.Text>
                      {/* <Typography.Text
                      className="text-overflow-ellipsis"
                          style={{
                              color: "#AEAEAE",
                              marginBottom: "0",
                              width:"20%"
                          }}
                          ellipsis={{tooltip:true}}
                      >
                          Roll No : {" "}{rollNo}
                      </Typography.Text> */}
                  </div>
              }
          </div>

      </div>
    )
}
     
function SearchedResultCard({storeSearchResponse,setPage,setPageLoader,getSearchedStudents,setLimit,handleViewDetails}) {

    const handlePageination = (value) => {
        setPage(value);
        setPageLoader(true);
        getSearchedStudents(() => setPageLoader(false));
      };

    const onPageSizeChange = (value) => {
        setPage(1)
        setLimit(value);
        setPageLoader(true);
        getSearchedStudents(() => setPageLoader(false));
    }
  return (
    <div>
    <h2>
      Search Result {" "}
      <span style={{ color: "grey" }}>
        ({storeSearchResponse?.allStudentsCount})
      </span>
    </h2>
    <Row style={{flexWrap: "wrap"/* , justifyContent: "space-between" */ }}>
      {storeSearchResponse?.allStudents?.length !== 0 &&
        storeSearchResponse?.allStudents?.map((item) => (
        <Col 
            style={{ cursor: "pointer", width: "22%", minWidth: 250, marginRight: 23}}
            onClick={() => handleViewDetails(item)}
          >
            <SearchUserCard
              phoneNo={true}
              cell={item?.phone}
              name={item?.name}
              flag={item?.flag}
              section={item?.section}
              standardId={item?.standardId}
              rollNo={item?.rollNo}
              std={item?.std}
              avatar={`${IMG_URL}/${item?.image ? item?.image : "default.png"
                }`}
            />
          </Col>
        ))}
    </Row>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <div>
                  <Select
                      defaultValue={"25/Page"}
                      style={{ width: 100, marginLeft: 10 }}
                      onChange={onPageSizeChange}
                  >
                      <Option value={25}>25/Page</Option>
                      <Option value={50}>50/Page</Option>
                      <Option value={100}>100/Page</Option>
                  </Select>
              </div>
              <div>
                  <Pagination
                    size='small'
                    current={storeSearchResponse?.meta?.currentPage}
                    defaultCurrent={1}
                    total={storeSearchResponse?.meta?.totalPages * 10}
                    onChange={handlePageination}
                  />
              </div>

          </div>
  </div>
  )
}

export default SearchedResultCard