import React from 'react';
// import Styles from './style';
import Drawer from 'antd/lib/drawer';
import 'antd/lib/drawer/style/index';
import { Color } from '../../services';
import { TiArrowLeft  } from 'react-icons/ti';
import { MdInsertComment  } from 'react-icons/md';
import { IoIosHand, IoIosPeople  } from 'react-icons/io';
import './styles.css';
import '../../design/layout.css';

// const UiButtons = props => (
//   <div className='controlButtonBox'>
//     {props.children}
//   </div>
// );

const LiveModals = ({showModal, hideModal, teacherName, subject, children}) => {
  return (
    <Drawer
      width={419}
      title={
        <div style={{display: 'flex'}}
    >
          <div onClick={hideModal}>
            <TiArrowLeft className='backButton' style={{margin: '10px 20px 0px 0px'}} />
          </div>
          <div>
            <div className='color-purple text-sm'>
              {teacherName}
            </div>
            <div className='a2grey text-xs' style={{paddingLeft: 3}}>
              {subject}
            </div>
          </div>
          <div className='typiIconHolder'>
            {showModal === 'chat' ?
              <MdInsertComment
                style={{
                  color: Color.semiGrey,
                  fontSize: 25
                }}
              />
            :  <>
              { showModal === 'hand' ?
                <IoIosHand
                  style={{
                    color: Color.semiGrey,
                    fontSize: 25
                  }}
                />
              :
              <IoIosPeople
                style={{
                  color: Color.semiGrey,
                  fontSize: 25
                }}
              />
              }
              </>
            }
          </div>
        </div>
      }
      placement="right"
      closable={false}
      onClose={hideModal}
      visible={showModal}
    >

      {children}
    </Drawer>
  )
};

export default LiveModals;
