import { Modal } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import activeRadioIc from "../../Assets/aulasMarket/select-radio-active.svg"
import emptyRadio from "../../Assets/aulasMarket/select-radio-empty.svg"
import { connect } from 'react-redux';
import { applyPromo, applyPromoCheckout, setCouponInCart, setSelectedCouponCode } from 'actions/studentMarketPlace';
import moment from 'moment';
import { InvalidCouponType } from './constants/studentMarketPlace';

function CouponsModal({ isCouponModal, singleCourseDetailsDataContainer, setInvalidCouponType, setParams, setSelectedCouponCode, selectedCouponCode, singleBuyNowDetails, applyPromoCheckout, applyPromo, setIsCouponModal, couponList, setCouponInCart, from, invalidCoupon }) {
    const handleCancel = () => {
        setIsCouponModal(false);
        setSelectedCouponCode(null)
    }

    console.log("singleBuyNowDetails", singleBuyNowDetails?.item?.[0]?.id, singleCourseDetailsDataContainer, from)
    // const [selectedCouponCode, setSelectedCouponCode] = useState(null);
    // const [couponCode, setCouponCode] =useState(null);
    const CouponDesign = ({ item }) => {
        const dateString = item?.endDate;
        const dateObject = new Date(dateString);
        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObject.getUTCFullYear();

        const formattedDate = `${day}-${month}-${year}`;

        const newdate = moment(item?.endDate).format('DD-MM-YYYY')

        console.log("newdate", newdate);
        const handleRadioClick = (code) => {
            // setSelectedCouponCode(code);
            if (selectedCouponCode === code) {
                setSelectedCouponCode(null);
            } else {
                setSelectedCouponCode(code);
            }
        };
        console.log("selectedCouponCode", selectedCouponCode);

        return (
            <div className='scroll-bar-universal' style={{ width: "100%", height: "120px", marginBottom: "10px", borderRadius: "8px", backgroundColor: "#FAFAFA", fontFamily: "Roboto" }}>
                <div className='r-c-sb' style={{ padding: 20, alignItems: "flex-start" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{item?.code}
                        <div style={{ color: "#636363", fontSize: "12px", fontWeight: "normal" }}>
                            Use this promotional offer to get an instant discount of <span style={{ color: "#00A566", fontWeight: "bold" }}>
                                {item?.discountType == "amount" ? `₹${item?.discount}` : `${item?.discount}%${item?.maxDiscount > 0 ? ' up to ₹' + item.maxDiscount.toString() : ''}`}
                                {parseFloat(item?.minPurchase) > 0 ? ' for minimum purchase of ₹' + item.minPurchase.toString() : ''}.
                            </span>  {item?.endDate !== null ? `Valid until ${newdate}` : <></>}</div>
                    </div>
                    {!item?.disabled ? <div className='cursor-pointer' style={{ position: "relative", transform: "translate(5px, -10px)" }} onClick={() => handleRadioClick(item?.code)}>   <img src={selectedCouponCode === item?.code ? activeRadioIc : emptyRadio} alt="Radio Icon" /></div> : null}
                </div>
            </div>
        )
    }
    const handleApplyClick = () => {
        let paramsOffer = {
            code: selectedCouponCode,
            type: 'offer'
        };
        let params = {
            code: selectedCouponCode,
            type: 'offer',
            id: singleBuyNowDetails?.item?.[0]?.id || null,
            itemType: singleBuyNowDetails?.item?.[0]?.resourceType || null,
        };
        let paramsCoursePage = {
            code: selectedCouponCode,
            type: 'offer',
            id: singleCourseDetailsDataContainer?.id || null,
            itemType: singleCourseDetailsDataContainer?.resourceType || null,
        };
        if (selectedCouponCode && from !== "checkout" && from !== "coursePage") {
            applyPromo(paramsOffer, (res) => {
                // setParams((prev) => ({
                //     ...prev,
                //     amount: (res?.cart?.amount - res?.cart?.discount).toFixed(2),
                //     code: selectedCouponCode
                // }))
                if (res) { setInvalidCouponType(false) } else { /*setInvalidCoupon(true)*/ }
                setIsCouponModal(false);
            }, () => {
                setCouponInCart(true);
            });
        } else if (selectedCouponCode && from === "checkout") {
            applyPromoCheckout(params, (res) => {
                if (res) { setInvalidCouponType(null) }
                setIsCouponModal(false);
            }, () => {
                setCouponInCart(true);
            }, (err) => {
                if (err.invalid) {
                    setInvalidCouponType(InvalidCouponType.invalid);
                } else if (err.expired) {
                    setInvalidCouponType(InvalidCouponType.expired);
                } else if (err.minPurchase) {
                    setInvalidCouponType(InvalidCouponType.minPurchase);
                } else if (err.usageLimit) {
                    setInvalidCouponType(InvalidCouponType.usageLimit);
                }
            });
        } else if (selectedCouponCode && from === "coursePage") {
            applyPromoCheckout(paramsCoursePage, (res) => {
                if (res) { setInvalidCouponType(null) }
                setIsCouponModal(false);
            }, () => {
                setCouponInCart(true);
            }, (err) => {
                if (err.invalid) {
                    setInvalidCouponType(InvalidCouponType.invalid);
                } else if (err.expired) {
                    setInvalidCouponType(InvalidCouponType.expired);
                } else if (err.minPurchase) {
                    setInvalidCouponType(InvalidCouponType.minPurchase);
                } else if (err.usageLimit) {
                    setInvalidCouponType(InvalidCouponType.usageLimit);
                }
            });
        }
    };
    const scrollRef = useRef(null);

    useEffect(() => {
        // Scroll to the top when couponList changes
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [isCouponModal]);
    console.log("couponList", selectedCouponCode, scrollRef)
    const disabledStyle = !selectedCouponCode ? { opacity: 0.5, cursor: 'not-allowed' } : {};
    return (
        <div>
            <Modal
                bodyStyle={{}}
                centered
                className="modal-round-corner scroll-bar-universal"
                open={isCouponModal}
                onCancel={handleCancel}
                header={null}
                footer={null}
                width={360}
            >
                <div className='scroll-bar-universal m-t-20' ref={scrollRef} style={{ height: couponList?.items?.length > 4 ? "55dvh" : "fit-content", overflowY: 'auto' }}>
                    {couponList?.items?.map((item, index) => (
                        <div>
                            <CouponDesign item={item} />
                        </div>
                    ))}
                </div>
                <div className='r-c-sb cursor-pointer'>
                    <div onClick={() => { setIsCouponModal(false); setSelectedCouponCode(null); }} style={{ color: "#FF414D", fontWeight: "bold", fontSize: "15px" }}>CANCEL</div>
                    <div onClick={handleApplyClick} style={{ ...disabledStyle, color: "#594099", fontWeight: "bold", fontSize: "15px" }} >APPLY</div>
                </div>
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { couponList, singleBuyNowDetails, selectedCouponCode } = state.studentMarketPlace
    return { couponList, singleBuyNowDetails, selectedCouponCode }
};

const mapDispatchToProps = (dispatch) => ({
    applyPromo: (params, callback, succesCallback) => dispatch(applyPromo(params, callback, succesCallback)),
    applyPromoCheckout: (params, callback, succesCallback, errorCallback = null) => dispatch(applyPromoCheckout(params, callback, succesCallback, errorCallback)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),
    setSelectedCouponCode: (val) => dispatch(setSelectedCouponCode(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponsModal)
