import { getUserProfileDetails, setGsSelectedTab, setKycSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab } from 'actions/profileNew';
import { Col, Divider, Image, Row } from 'antd';
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import React, { useEffect, useState } from 'react';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { IMG_URL } from "../../env.json";
import { IndividualDetails } from './subComponents';

const GeneralIcon = require('Assets/ProfileAssets/ic-general-settings.svg').default;
const SubscriptionIcon = require('Assets/ProfileAssets/ic-subscriptions.svg').default;
const KycIcon = require('Assets/ProfileAssets/ic-kyc-details.svg').default;
const CareIcon = require('Assets/ProfileAssets/ic-care-support.svg').default;
const SummaryIcon = require('Assets/ProfileAssets/ic-summary.svg').default

export const splitText = (text) => {
    console.log("splitText", text.split(/(?=[A-Z])/).join(' '));
    return text.split(/(?=[A-Z])/).join(' ');
}

const profileDetails = (userDetails, instituteDetails, user) => [
    user?.role?.includes("Student") ?
        {
            type: "Your Profile",
            image: userDetails?.image,
            buttonType: "EDIT",
            fields: [
                { label: 'Name', value: userDetails?.name },
                { label: 'Mobile number', value: userDetails?.phone },
                { label: 'Email ID', value: userDetails?.email || "NA" },
                { label: 'Gender', value: userDetails?.basicprofile?.gender },
                { label: 'Blood Group', value: userDetails?.basicprofile?.bloodGroup || "NA" },
                { label: 'Date of birth', value: userDetails?.basicprofile?.dob || "NA" },


            ]

        }
        :
        {
            type: "Your Profile",
            image: userDetails?.image,
            buttonType: "EDIT",
            fields: [
                { label: 'Name', value: userDetails?.name },
                { label: 'Email ID', value: userDetails?.email },
                user?.privilage?.includes("SuperAdmin") ? { label: 'Designation', value: instituteDetails?.superAdminDesignation } :
                    { label: 'Gender', value: userDetails?.basicprofile?.gender },
                { label: 'User type', value: userDetails?.role },
                { label: 'Mobile number', value: userDetails?.phone },
                userDetails?.privilage?.length ? {
                    label: 'Privilege(s)', value: userDetails?.privilage.map((item, index) => `${index !== 0 ? ' | ' : ""}${splitText(item)} `
                    )
                } : undefined

            ]

        },
    {
        type: "Your Institute",
        image: instituteDetails?.logo,
        buttonType: user?.privilage?.length ? user?.privilage?.includes("SuperAdmin") ? "EDIT" : "VIEW MORE" : null,
        fields: [
            { label: 'Name', value: instituteDetails?.instituteName },
            { label: 'Unique ID', value: instituteDetails?.instituteId },
            { label: 'Business type', value: instituteDetails?.instituteType },
            { label: 'Domain URL', value: instituteDetails?.url },
            { label: 'Website', value: instituteDetails?.website },
        ]

    }
]

const gsButtons = (setGsSelectedTab, user) => {
    const history = useHistory()

    const navigateToSettings = (tab) => {
        history.push("/general-settings");
        setGsSelectedTab(tab)
    }
    const gsOptions = [];
    if (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin")) {
        gsOptions.push({
            label: "User management",
            action: () => navigateToSettings("User management")
        })
    }
    if (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("FeeManager")) {
        gsOptions.push({
            label: "Fee management",
            action: () => navigateToSettings("Fee management")
        })
    }
    if (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("LeaveManager")) {
        gsOptions.push({
            label: "Attendance",
            action: () => navigateToSettings("Attendance")
        })
    }
    gsOptions.push({
        label: "Attendance reminder",
        action: () => navigateToSettings("Attendance reminder")
    })
    if (user?.privilage?.includes("SuperAdmin")){
        gsOptions.push({
            label: "Marketplace",
            action: () => navigateToSettings("Marketplace")
        })
    }

    return gsOptions
}

const subsButtons = (setSubscriptionSelectedTab, user) => {
    const history = useHistory()

    const navigateToSettings = (tab) => {
        history.push("/subscriptions");
        setSubscriptionSelectedTab(tab)
    }
    return ([
        user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") ?
            {
                label: "aulas Plan",
                action: () => navigateToSettings("aulas Plan")
            } : undefined,
        user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") || user?.role?.includes("Data Operator") ?
            {
                label: "Storage",
                action: () => navigateToSettings("Storage")
            } : undefined,
        user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") || user?.privilage?.includes("Planner") ?
            {
                label: "Broadcast",
                action: () => navigateToSettings("Broadcast")
            } : null,
    ])
}
const kycButtons = (setKycSelectedTab) => {
    const history = useHistory();

    const navigateToKyc = (tab) => {
        history.push("/view-kyc");
        setKycSelectedTab(tab)
    }
    return [
        {
            label: "Contact information",
            action: () => navigateToKyc("Contact information")
        },
        {
            label: "Business details",
            action: () => navigateToKyc("Institute details")
        },
        {
            label: "Bank account",
            action: () => navigateToKyc("Bank account")
        },
        {
            label: "Documents",
            action: () => navigateToKyc("Documents")
        },
        {
            label: "Agreement",
            action: () => navigateToKyc("Agreement")
        },
    ]
}

const othersButtons = (setOthersSelectedTab, user) => {
    const history = useHistory();

    const navigateToOthers = (tab) => {
        history.push("/user-profile/others");
        setOthersSelectedTab(tab)
    }

    return [
        user.role.includes("Student") ?
            {
                label: "Classroom & course(s)",
                action: () => navigateToOthers("Classroom & course(s)")
            } : undefined,
        user?.role?.includes("Student") ?
            {
                label: "Fee details",
                action: () => navigateToOthers("Fee details")
            } : undefined,
        {
            label: "Attendance",
            action: () => navigateToOthers("Attendance")
        },
        {
            label: "Attendance Request(s)",
            action: () => navigateToOthers("Attendance Request(s)")
        },
        !user?.role?.includes("Student") ?
            {
                label: "Leaves",
                action: () => navigateToOthers("Leaves")
            } : undefined,
        {
            label: "Holidays",
            action: () => navigateToOthers("Holidays")
        },
        user?.role?.includes("Student") ?
            {
                label: "Activities",
                action: () => navigateToOthers("Activities")
            } : undefined,
    ]

}

const SettingCard = ({ type, index, noOfCards }) => {
    if (type) {
        return (
            <Col sm={{ span: 12 }} md={{ span: 12 }}
                lg={{
                    span: noOfCards === 4 ? 6 : noOfCards === 3 ? 8 : 12
                }} key={index}>
                <div style={{ width: "90%", height: 350, border: '1px solid #E6E6E6', boderRadius: 4, background: 'white' }} className="m-t-10">
                    <div className='r-ac m-t-30 m-l-20' style={{ width: "100%" }}>
                        <img src={type.icon} alt="" />
                        <div style={{ fontSize: 16, fontWeight: 600, fontFamily: 'roboto', marginLeft: 10, width: "65%", overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                            {type.title}
                        </div>
                    </div>
                    <div className='r-c-c m-t-20'>
                        <div style={{ width: "90%", borderBottom: "1px solid #E6E6E6" }} />
                    </div>
                    <div style={{ padding: 20, height: "75%" }} >
                        {type.body}
                    </div>
                </div>
            </Col>
        )
    } else {
        return null
    }
}

const settingTypes = (kycStatus, supportPhone, history, setGsSelectedTab, setKycSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab, user) => [
    {
        title: "General settings",
        icon: GeneralIcon,
        body: gsButtons(setGsSelectedTab, user)?.map((item, index) => (
            <div key={index} style={{ marginBottom: 18 }}>
                <Button type="link" onClick={item.action} style={{ fontSize: 14, fontWeight: 400 }}>{item.label}</Button>
            </div>
        ))
    },
    user?.access?.includes("subscription-settings") ?
        {
            title: "Subscriptions/ Plans",
            icon: SubscriptionIcon,
            body: subsButtons(setSubscriptionSelectedTab, user)?.map((item, index) => item && (
                <div key={index} style={{ marginBottom: 18 }}>
                    <Button type="link" onClick={item.action} style={{ fontSize: 14, fontWeight: 400 }}>{item.label}</Button>
                </div>
            ))
        } : undefined,
    user?.access?.includes("fees") ? {
        title: "KYC details",
        icon: KycIcon,
        body: kycStatus !== "Approved" ? (
            <div style={{ height: "100%" }} className="r-c-jsb">
                <div className="p-10" style={{ textAlign: 'justify' }}>Activate your account to start accepting payments from customers.</div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
                    <Button type="link" style={{ fontSize: 14 }} onClick={() => history.push('kyc-form')}>ACTIVATE</Button>
                </div>
            </div>
        ) :
            kycButtons(setKycSelectedTab)?.map((item, index) => (
                <div key={index} style={{ marginBottom: 18 }}>
                    <Button type="link" onClick={item?.action} style={{ fontSize: 14, fontWeight: 400 }}>{item?.label}</Button>
                </div>
            ))
    } : undefined,
    user?.access?.includes("support-settings") ?
        {
            title: "aulas care support",
            icon: CareIcon,
            body: supportPhone?.length ?
                <div style={{ height: "100%" }} className="r-c-jsb">
                    <div className="p-10 scroll-bar-universal" style={{ textAlign: 'left', width: "100%", height: "84%", overflowX: 'hidden' }}>
                        {supportPhone.map((number, index) =>
                            <div key={index} style={{ marginBottom: 20 }}>
                                <div style={{ fontSize: 12, fontWeight: 500, }}>{"Phone number " + (index + 1)}</div>
                                <div style={{ fontSize: 16 }}>{number}</div>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
                        <Button type="link" style={{ fontSize: 14 }} onClick={() => history.push('/support-settings')}>{user.privilage.includes("SuperAdmin") ? "EDIT" : "VIEW ALL"}</Button>
                    </div>
                </div>
                : (
                    <div style={{ height: "100%" }} className="r-c-jsb">
                        <div className="p-10" style={{ textAlign: 'justify' }}>Add contact details for students to contact for inquires.</div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
                            <Button type="link" style={{ fontSize: 14 }} onClick={() => history.push('/support-settings')}>ADD CONTACTS</Button>
                        </div>
                    </div>
                )
        } : undefined,

    user.role.includes("Student") ?
        {
            title: "Summary",
            icon: SummaryIcon,
            body: othersButtons(setOthersSelectedTab, user)?.map((item, index) => item && (
                <div key={index} style={{ marginBottom: 10 }}>
                    <Button type="link" onClick={item.action} style={{ fontSize: 14, fontWeight: 400 }}>{item.label}</Button>
                </div>
            ))
        } : undefined,
]

const otherTypes = (setOthersSelectedTab, user) => [
    {
        title: "Summary",
        icon: SummaryIcon,
        body: othersButtons(setOthersSelectedTab, user)?.map((item, index) => item && (
            <div key={index} style={{ marginBottom: 18 }}>
                <Button type="link" onClick={item.action} style={{ fontSize: 14, fontWeight: 400 }}>{item.label}</Button>
            </div>
        ))
    }

]

const ProfileCards = ({ details, index, history, user }) => {
    const handleEditClick = () => {
        if (index === 0) {
            history.push("/user-profile")
        } else {
            if (details.buttonType === "EDIT") {
                history.push("/institute-details")
            } else {
                history.push("/view-institute-details")
            }
        }
    }
    return (
        <Col key={index} md={{ span: 24 }} lg={{ span: 12 }} className='m-t-10' >
            <div className='r-jsb' style={{ width: "95%", marginBottom: 16 }}>
                <div style={{ fontFamily: 'roboto', fontSize: 18, fontWeight: 500 }}>{details.type}</div>
                {details.buttonType && <div >
                    <Button type="link" style={{ fontSize: 16 }} onClick={() => handleEditClick()}
                        icon={details.buttonType === "EDIT" ? <AiFillEdit /> : <AiFillEye />}>
                        {details.buttonType}
                    </Button>
                </div>}
            </div>
            <div style={{ width: "95%", border: '1px solid #E6E6E6', boderRadius: 4, background: 'white' }}>
                <Row>
                    <Col span={6} style={{ height: 235 }}>
                        <div className='display-flex' style={{ justifyContent: 'center', height: "100%", width: "100%" }}>
                            <Image src={`${IMG_URL}/${details.image}`} height={"60%"} width={"70%"}
                                style={{ borderRadius: "20vh", marginTop: 30, marginLeft: 0 }} />
                        </div>
                    </Col>
                    <Col span={18} style={{ height: 235 }}>
                        <Row style={{ marginTop: 8 }}>
                            {details?.fields?.map((data, index) => <IndividualDetails data={data} index={index} />)}
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

const AccountDetails = ({
    user, getUserProfileDetails, supportPhones, userDetails, instituteDetails, setGsSelectedTab,
    setKycSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab
}) => {
    const history = useHistory()
    const [pageLoader, setPageLoader] = useState(false);
    const [noOfCards, setNoOfCard] = useState(1)
    useEffect(() => {
        setPageLoader(true);
        setTimeout(getUserProfileDetails(() => setPageLoader(false)), 1000)
    }, []);
    useEffect(() => {
        let noOfCards = 1;
        if (user.access.includes("subscription-settings")) {
            noOfCards++;
        }
        if (user.access.includes("fees")) {
            noOfCards++;
        }
        if (user.access.includes("support-settings")) {
            noOfCards++;
        }
        setNoOfCard(noOfCards)
    }, [user])
    return (
        <div style={{ height: "fit-content" }}>
            <Row style={{ justifyContent: 'space-between' }}>
                {profileDetails(userDetails, instituteDetails, user)?.map((details, index) => <ProfileCards details={details} index={index} history={history} user={user} />)}
            </Row>
            <div className='r-ac m-t-30' style={{ fontFamily: 'roboto', fontSize: 18, fontWeight: 500 }}>Settings {user.role.includes("Student") ? "& Others" : null}</div>
            <Row style={{ justifyContent: 'space-between', width: noOfCards === 4 ? "100%" : noOfCards === 3 ? "75%" : "50%" }} className="m-t-10">
                {settingTypes(instituteDetails?.kycStatus, supportPhones, history, setGsSelectedTab, setKycSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab, user)?.map((type, index) => <SettingCard type={type} index={index} noOfCards={noOfCards} />)}
            </Row>
            {!user?.role?.includes("Student") ?
                <>
                    <div className='r-ac m-t-30' style={{ fontFamily: 'roboto', fontSize: 18, fontWeight: 500 }}>Others</div>
                    <Row style={{ width: "50%" }}>
                        {otherTypes(setOthersSelectedTab, user)?.map((type, index) => <SettingCard type={type} index={index} noOfCards={1} />)}
                    </Row>
                </> : null}
            <Row className='r-ac m-t-30'>
                <Col span={5}>
                    <div className='r-jsb'>
                        <Button type="transparent" style={{ padding: 0, width: 'fit-content', color: "#636363" }} onClick={() => history.push('/about-aulas')}>About aulas</Button>
                        <span className='m-l-10 m-r-10 text-md' style={{ color: "#636363", marginTop: -4 }}>•</span>
                        <Button type="transparent" style={{ padding: 0, width: 'fit-content', color: "#636363" }} onClick={() => history.push('/privacy-policy')}>Privacy policy</Button>
                        <span className='m-l-10 m-r-10 text-md' style={{ color: "#636363", marginTop: -4 }}>•</span>
                        <Button type="transparent" style={{ padding: 0, width: 'fit-content', color: "#636363" }} onClick={() => history.push('/tnc')}>Terms and conditions</Button>
                    </div>
                </Col>
            </Row>
            <PageDataLoader visible={pageLoader} />
        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { userDetails, instituteDetails, supportPhones } = state.profileNew;
    return {
        user, userDetails, instituteDetails, supportPhones
    }
}

const mapDispatchToProps = (dispatch) => ({
    getUserProfileDetails: (callback) => dispatch(getUserProfileDetails(callback)),
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    setKycSelectedTab: (tab) => dispatch(setKycSelectedTab(tab)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    setSubscriptionSelectedTab: (tab) => dispatch(setSubscriptionSelectedTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
