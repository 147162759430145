import { ArrowLeftOutlined, FilePdfOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import TitleAndDescription from './titleAndDescription';
import { connect } from 'react-redux';
import { getUserKycDetails } from 'actions/aulasWallet';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
import { Api } from 'services';
import ImgViewModal from 'pages/kyc/modals/imgView';

const bodyStyle = {
    background: "#FAFAFA",
    margin: -20,
    padding: 20,
    overflow: "auto",
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
};

function KycDetails({ getUserKycDetails, userKycDataContainer }) {
    const history = useHistory();
    const [pageLoader, togglePageLoader] = useState(false);
    useEffect(() => {
        togglePageLoader(true)
        getUserKycDetails(() => togglePageLoader(false));
    }, [])
    const addressData = userKycDataContainer?.address;
    const parsedData = addressData != undefined || addressData != null ? JSON.parse(addressData) : ""
    const DocumentComponent = ({ extension, documentSize, docType }) => {
        const [showImageModal, updateShowImgModal] = useState(false);
        const handleDocClick = (docType) => {
            updateShowImgModal(true);
        }
        return (
            <>
                <div style={{ border: "1px dashed #E6E6E6", backgroundColor: "#FAFAFA", height: "fit-content", padding: 10, marginTop: 10, width: "90%" }}>
                    <div className='r-c-c'>
                        <img src={require("../../Assets/aulasWallet/fileImage.svg").default} style={{ color: "#1089ff", height: 25, width: 25 }} />
                        <div className='r-c-sb m-l-10' style={{ width: "100%" }}>
                            <div>
                                <div style={{ color: "#191919", fontSize: "13px", fontWeight: "bold" }}>Document.{extension}</div>
                                <div style={{ color: "#AEAEAE", fontSize: "13px", fontWeight: "normal" }}>{documentSize} KB</div>
                            </div>
                            <div className='display-flex'>
                                {extension === "png" || extension === "jpg" ?
                                    <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold", cursor: "pointer" }} onClick={() => { handleDocClick() }}>VIEW</div>
                                    :
                                    <a href={`${Api._s3_url}${docType}`} target="blank">
                                        <div style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold", cursor: "pointer" }}>VIEW</div>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                {showImageModal && <ImgViewModal visible={showImageModal} closeModal={() => updateShowImgModal(false)} fileName={docType} />}
            </>
        )
    }
    return (
        <div style={bodyStyle} className='r-c-c'>
            <div style={{ width: "80%", backgroundColor: "#FFFFFF", height: "calc(100% + 40px)", }}>
                <div style={{ fontWeight: "700", display: "flex", flexDirection: "row", margin: "20px 0px 20px 40px" }}>
                    <ArrowLeftOutlined
                        style={{ marginTop: "10px", fontSize: "19px" }}
                        onClick={() => {
                            history.goBack();
                        }} />
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>KYC Details</div>
                </div>
                <div style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
                <div style={{ padding: "20px 40px 20px 40px" }}>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "28vw" }}>
                            <TitleAndDescription
                                title={"Name"}
                                description={userKycDataContainer?.name}
                            />
                        </div>
                        <TitleAndDescription
                            title={"Mobile number"}
                            description={userKycDataContainer?.phone}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "28vw" }}>
                            <TitleAndDescription
                                title={"Email ID"}
                                description={userKycDataContainer?.email}
                            />
                        </div>
                        <TitleAndDescription
                            title={"Gender"}
                            description={userKycDataContainer?.gender}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "28vw" }}>
                            <TitleAndDescription
                                title={"Date of birth"}
                                description={moment(userKycDataContainer?.dob, "YYYY-MM-DD").format("DD/MM/YYYY")}
                            />
                        </div>
                        <TitleAndDescription
                            title={"Relationship"}
                            description={userKycDataContainer?.relation}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "28vw" }}>
                            <TitleAndDescription
                                title={"House No./Locality/Area"}
                                description={parsedData?.locality}
                            />
                        </div>
                        <TitleAndDescription
                            title={"PIN/ZIP Code"}
                            description={parsedData?.pincode}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "28vw" }}>
                            <TitleAndDescription
                                title={"District"}
                                description={parsedData?.city}
                            />
                        </div>
                        <TitleAndDescription
                            title={"State"}
                            description={parsedData?.state}
                        />
                    </div>
                    <div className='m-t-20 m-b-15'>
                        <TitleAndDescription
                            title={"Country"}
                            description={parsedData?.country}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "28vw" }}>
                            <TitleAndDescription
                                title={"Address Proof"}
                                description={userKycDataContainer?.addressType}
                            />
                        </div>
                        <TitleAndDescription
                            title={userKycDataContainer?.addressType + " Number"}
                            description={userKycDataContainer?.documentNo}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "32vw" }}>
                            <div className='font' style={{ color: "#636363", fontSize: 12 }}>{userKycDataContainer?.addressType + " Front"}</div>
                            <DocumentComponent
                                extension={userKycDataContainer?.documentFrontExtension}
                                documentSize={userKycDataContainer?.fileDetails?.documentFront?.sizeInKB}
                                docType={userKycDataContainer?.documentFront} />
                        </div>
                        <div style={{ width: "28vw" }}>
                            <div className='font' style={{ color: "#636363", fontSize: 12 }}>{userKycDataContainer?.addressType + " Back"}</div>
                            <DocumentComponent
                                extension={userKycDataContainer?.documentBackExtension}
                                documentSize={userKycDataContainer?.fileDetails?.documentBack?.sizeInKB}
                                docType={userKycDataContainer?.documentBack} />
                        </div>
                    </div>
                    <div className='m-t-20 m-b-15'>
                        <TitleAndDescription
                            title={"PAN Number"}
                            description={userKycDataContainer?.panNo}
                        />
                    </div>
                    <div className='display-flex m-b-15'>
                        <div style={{ width: "32vw" }}>
                            <div className='font' style={{ color: "#636363", fontSize: 12 }}>{"PAN card Front"}</div>
                            <DocumentComponent
                                extension={userKycDataContainer?.panFrontExtension}
                                documentSize={userKycDataContainer?.fileDetails?.panFront?.sizeInKB}
                                docType={userKycDataContainer?.panFront} />
                        </div>
                        <div style={{ width: "28vw" }}>
                            <div className='font' style={{ color: "#636363", fontSize: 12 }}>{"PAN Card Back"}</div>
                            <DocumentComponent
                                extension={userKycDataContainer?.panBackExtension}
                                documentSize={userKycDataContainer?.fileDetails?.panBack?.sizeInKB}
                                docType={userKycDataContainer?.panBack} />
                        </div>
                    </div>
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = state => {
    const { userKycDataContainer } = state.aulasWallet;
    return { userKycDataContainer };
};

const mapDispatchToProps = dispatch => ({
    getUserKycDetails: (callBack) => dispatch(getUserKycDetails(callBack))
});


export default connect(mapStateToProps, mapDispatchToProps)(KycDetails);

// export default KycDetails