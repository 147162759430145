import { Col, Popover, Progress, Row, Table, Tabs, Tooltip } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import PageHeader from 'components/pageHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'components/Button';
import FeeBreakupModal from './feeBreakupModal';
import { connect } from 'react-redux';
import { deleteZipFile, downloadInvoices, getAttendanceOverview, getCfrt, getFeeDetails, setGsSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab } from 'actions/profileNew';
import { BellOutlined, CloudDownloadOutlined, DownloadOutlined, StopOutlined } from '@ant-design/icons';
import { easySwitch, IndividualDetails } from './subComponents';
import TransactionDetailsModal from './transactionDetailsModal';
import moment from 'moment';
import PageDataLoader from 'components/pageDataLoader';
import { IMG_URL } from "../../env.json";
import { generatePaymentRequest } from './paymentGateway';
import AttendanceSummary from './attendanceSummary';
import AttendanceRequests from './attendanceRequests';
import LeaveSummary from './leaveSummary';
import Holidays from './holidays';
import Activities from './activities'
import { setQuickAccessItem } from 'actions/userManagementV2';

const courseIcon = require('Assets/ic-course.svg').default;


const columns = (getCfrt, cfrToken, user, userDetails) => {
    return [
        {
            title: 'No',
            dataIndex: 'paymentId',
            key: 'paymentId',
            render: (id, record, index) => index + 1
        },
        {
            title: 'Request Details',
            dataIndex: 'name',
            key: 'name',
            width: '20%'
        },
        {
            title: 'Amount',
            dataIndex: 'amountPayable',
            key: 'amountPayable',
            render: (value, record) => <div>₹ {record ? (record?.amountPayable).toFixed(2) : 0}</div>
        },
        {
            title: 'Issue Date',
            dataIndex: 'issueDate',
            key: 'issueDate',
            render: (value, record) => <div>{record?.status !== 'Not Requested' ? moment(record?.issueDate, 'DD-MM-YYYY').format('DD-MM-YYYY') : null}{console.log({ record })}</div>
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value, record) => {
                if (value === "PAID ONLINE" || value === "PAID OFFLINE") {
                    return <div style={{ color: "#28DF99" }}>{value}</div>
                } else if (value === "PENDING") {
                    return <div style={{ color: "#FFA931" }}>{value}&nbsp;
                        {record.feeReminders?.length ? <Popover placement='right'
                            title={<div style={{ fontSize: 18, fontWeight: 500 }}><BellOutlined /> &nbsp;Reminders ({record.feeReminders?.length})</div>}
                            content={<div>
                                {record.feeReminders.map((item, index) =>
                                    <div>
                                        <div style={{ fontSize: 16, fontWeight: 500 }}>{moment(item.date).format("DD-MM-YYYY, hh:mm A")}</div>
                                        <div style={{ color: "#636363" }}>{item.reminderName} • {item.reminderPrivilege}</div>
                                    </div>
                                )}
                            </div>}
                        >
                            <BellOutlined />
                        </Popover> : null}
                    </div>
                } else if (value === "OVERDUE") {
                    return <div style={{ color: "#FF414D" }}>{value}&nbsp;
                        {record.feeReminders?.length ? <Popover placement='right'
                            title={<div style={{ fontSize: 18, fontWeight: 500 }}><BellOutlined /> &nbsp;Reminders ({record.feeReminders?.length})</div>}
                            content={<div>
                                {record.feeReminders.map((item, index) =>
                                    <div>
                                        <div style={{ fontSize: 16, fontWeight: 500 }}>{moment(item.date).format("DD-MM-YYYY, hh:mm A")}</div>
                                        <div style={{ color: "#636363" }}>{item.reminderName} • {item.reminderPrivilege}</div>
                                    </div>
                                )}
                            </div>}
                        >
                            <BellOutlined />
                        </Popover> : null}</div>
                } else if (value === "REFUNDED") {
                    return <div style={{ color: "#FF414D" }}>{value}</div>
                } else {
                    return null;
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'status',
            key: "status",
            render: (value, record) => {
                const generateOrderId = user?.orgId + user?.id + record?.transactionId + Math.floor(Math.random() * 100);
                console.log({ record });
                let params = {
                    id: record?.transactionId || null,
                    orderAmount: record?.amountPayable.toString() || null,
                    orderCurrency: 'INR',
                    orderId: generateOrderId.toString() || null,
                    customerDetails: {
                        customer_id: user?.id?.toString() || null,
                        customer_email: user?.email || null,
                        customer_phone: user?.phone?.toString() || null
                    }
                };

                if (value === "PAID ONLINE" || value === "PAID OFFLINE") {
                    return <Button type="transparent" style={{ color: "#1089FF" }} onClick={e => e.stopPropagation()}>
                        <a style={{ fontSize: 12, fontWeight: 600 }} href={IMG_URL + '/' + record?.invoice} target="_blank"><CloudDownloadOutlined /></a>
                    </Button>
                } else if (value === "PENDING") {
                    // return null
                    return <Button type="transparent" style={{ color: "#1089FF" }} onClick={(e) => { e.stopPropagation(); getCfrt(params, (response) => generatePaymentRequest(response, record, userDetails)) }}>PAY NOW</Button>
                } else if (value === "OVERDUE") {
                    // return null
                    return <Button type="transparent" style={{ color: "#1089FF" }} onClick={(e) => { e.stopPropagation(); getCfrt(params, (response) => generatePaymentRequest(response, record, userDetails)) }}>PAY NOW</Button>
                } else {
                    return null;
                }
            }
        },
    ]
}



const tabs = (setBreakupModalVisible, studentCourseDetails, studentFeeDetails, rowSelection, onRow,setQuickAccessItem,
    handleDownload, downloadLoader, downloadVisible, user, setPageLoader, othersSelectedTab, getCfrt, cfrToken, userDetails) => {
    const classDetails = [
        studentCourseDetails?.studentClassDetails?.map(item => {
            return [
                { label: "Class", value: item?.std || "NA" },
                { label: "Section", value: item?.section || "NA" },
                { label: "Roll Number", value: item?.rollNo || "NA" },
            ]
        })
    ]

    const othersTabs = {}
    if (user?.role?.includes("Student")) {
        Object.assign(othersTabs, {
            "Classroom & course(s)": {
                body: (
                    <div>
                        <div style={{ fontSize: 16, fontWeight: 500 }}>Classroom</div>
                        {classDetails?.map((classDetail) => {
                            console.log({ classDetail })
                            return (
                                <Row>
                                    {classDetail?.map((items, index) => {
                                        console.log({ items })
                                        return items?.map((item) => {
                                            return <IndividualDetails span={8} data={item} />
                                        })
                                    })}
                                </Row>
                            )
                        })}

                        <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20 }} />
                        <div className="m-t-30" style={{ fontSize: 16, fontWeight: 500 }}>Course(s)</div>
                        <div>

                            {studentCourseDetails?.userAssignedCourses?.map((course, index) =>
                            (
                                <div key={index}>
                                    <div className="r-jsb m-t-20" style={{ alignItems: "center" }}>
                                        <div className="display-flex" style={{ alignItems: "center" }}>
                                            <img src={courseIcon} width={64} height={64} alt="" />
                                            <div className='m-l-20'>
                                                <div style={{ fontSize: 16, fontWeight: 500 }}>{course.courseName}</div>
                                                {!course.startDate && !course.endDate ? null :
                                                    <div style={{ color: "#636363" }}>{course.startDate ? moment(course.startDate).format("DD/MM/YYYY") : "NA"} - {course.endDate ? moment(course.endDate).format("DD/MM/YYYY") : "NA"}</div>
                                                }
                                                {/* {course.inactive ? <div style={{ fontSize: 12, color: "#FF414D", fontWeight: 600 }}><StopOutlined /> Inactive</div> : null} */}
                                            </div>
                                        </div>
                                        {course.progress ?
                                            <div style={{ width: "15vw" }} >
                                                <Progress percent={course.progress} showInfo={false} />
                                                <div className='r-jsb' style={{ color: "#636363", fontSize: 12 }}>
                                                    <div>Completed</div>
                                                    <div>{course.progress}%</div>
                                                </div>
                                            </div> : null}
                                    </div>
                                    <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20 }} />
                                </div>
                            )
                            )}
                        </div>

                    </div>
                )
            },
            "Fee details": {
                body: (
                    <div>
                        <div style={{ fontSize: 16, fontWeight: 500 }}>Overview</div>
                        <Row >
                            <Col sm={{ span: 24 }} md={{ span: 12 }}>
                                <div className='m-t-20'>
                                    <div style={{ fontSize: 16, color: "#28DF99", fontWeight: 500 }}>
                                        ₹ {studentFeeDetails?.amountPaid}
                                    </div>
                                    <div style={{ color: "#636363", fontSize: 12, fontWeight: 500 }}>
                                        Paid
                                    </div>
                                </div>
                                <div className='m-t-20'>
                                    <div style={{ fontSize: 16, color: "#FF414D", fontWeight: 500 }}>
                                        ₹ {studentFeeDetails?.balanceAmount}
                                    </div>
                                    <div style={{ color: "#636363", fontSize: 12, fontWeight: 500 }}>
                                        Balance
                                    </div>
                                </div>

                            </Col>
                            <Col sm={{ span: 0 }} md={{ span: 1 }}>
                                <div span={0} style={{ width: 0, height: "80%", borderRight: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20 }} />
                            </Col>
                            <Col sm={{ span: 24 }} md={{ span: 11 }}>
                                <Row>
                                    <Col span={12}>
                                        <div className='m-t-20'>
                                            <div style={{ color: "#636363", fontSize: 12, fontWeight: 500 }}>
                                                Total payable
                                            </div>
                                            <div style={{ fontSize: 16, fontWeight: 500 }}>
                                                ₹ {studentFeeDetails?.totalPayable}
                                            </div>

                                        </div>
                                        <div className='m-t-20'>
                                            <div style={{ color: "#636363", fontSize: 12, fontWeight: 500 }}>
                                                Payment plan
                                            </div>
                                            <div style={{ fontSize: 16, fontWeight: 500 }}>
                                                {studentFeeDetails?.feePlan}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='m-t-40 r-c-fe'>
                                            <Button type="link" style={{ fontSize: 14 }} onClick={() => setBreakupModalVisible(true)}>
                                                VIEW BREAKUPS
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className='r-jsb m-t-40' style={{ alignItems: "center" }}>
                            <div style={{ fontSize: 16, fontWeight: 500 }}>Installment(s)</div>
                            {downloadVisible ? <div>
                                <Button type="primary" icon={<CloudDownloadOutlined />} loading={downloadLoader} onClick={handleDownload}>Download</Button>
                            </div> : null}
                        </div>
                        <Table
                            className="m-t-10"
                            style={{ width: "100vw" }}
                            dataSource={studentFeeDetails?.installmentData ? studentFeeDetails?.installmentData : []}
                            columns={columns(getCfrt, cfrToken, user, userDetails)}
                            rowSelection={rowSelection}
                            onRow={onRow}
                            rowKey={e => e.transactionId}
                        />
                    </div>

                )
            }
        })
    }
    Object.assign(othersTabs, {
        "Attendance": {
            body: (
                <AttendanceSummary setPageLoader={setPageLoader} />
            )
        },
        "Attendance Request(s)": {
            body: (
                <AttendanceRequests setPageLoader={setPageLoader} setQuickAccessItem={setQuickAccessItem}/>
            )
        },
    })
    if (!user?.role?.includes("Student")) {
        Object.assign(othersTabs, {
            "Leaves": {
                body: (
                    <LeaveSummary setPageLoader={setPageLoader} setQuickAccessItem={setQuickAccessItem} />
                )
            },
        })
    }
    Object.assign(othersTabs, {
        "Holidays": {
            body: (
                <Holidays setPageLoader={setPageLoader} />
            )
        }
    })

    Object.assign(othersTabs, {
        "Activities": {
            body: (
                <Activities setPageLoader={setPageLoader} />
            )
        }
    })
    return othersTabs
}

const TabsComponent = ({ setBreakupModalVisible, setOthersSelectedTab, othersSelectedTab,setQuickAccessItem,
    studentCourseDetails, studentFeeDetails, rowSelection, onRow, handleDownload, downloadLoader,
    downloadVisible, user, setPageLoader, getCfrt, cfrToken, userDetails }) => {

    return (
        <Tabs onChange={key => setOthersSelectedTab(key)} activeKey={othersSelectedTab}>
            {Object.keys(tabs(setBreakupModalVisible, studentCourseDetails, studentFeeDetails, rowSelection, onRow,
                handleDownload, downloadLoader, downloadVisible, user, setPageLoader, othersSelectedTab, getCfrt, cfrToken, userDetails)).map((tab, index) => (
                    <TabPane tab={tab} key={tab}>
                        {tabs(setBreakupModalVisible, studentCourseDetails, studentFeeDetails, rowSelection, onRow,setQuickAccessItem,
                            handleDownload, downloadLoader, downloadVisible, user, setPageLoader, othersSelectedTab, getCfrt, cfrToken, userDetails)[tab].body}
                    </TabPane>
                )
                )}
        </Tabs>
    )
}

const Others = ({ setOthersSelectedTab, othersSelectedTab, user, studentCourseDetails, getFeeDetails,setQuickAccessItem,quickAccessItem,
    studentFeeDetails, downloadInvoices, deleteZipFile, setGsSelectedTab, setSubscriptionSelectedTab, getCfrt, cfrToken, userDetails }) => {

    const [breakupModalVisible, setBreakupModalVisible] = useState(false);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [selectedInstallment, setSelectedInstallment] = useState({})
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false);

    const location = useLocation();


    console.log({ userDetails })

    //this useEffect will run after a particular profileTab useEffect
    //dont set pageloader here
    useEffect(() => {
        switch (othersSelectedTab) {
            case "Fee details":
                setPageLoader(true)
                getFeeDetails(user?.id, () => setPageLoader(false), '')
        }
        
        if(quickAccessItem == 'markAttendance'){
            setOthersSelectedTab('Attendance Request(s)')
        }

        if(quickAccessItem == 'applyLeave'){
            setOthersSelectedTab('Leaves')
        }

    }, [othersSelectedTab]);

    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys, selections, key) => {
            setSelectedPayments([...selections.map(item => item.paymentId)])
            setSelectedRowKeys([...selectedRowKeys])
        },
        getCheckboxProps: (record) =>
        ({
            disabled: record.status === "PENDING" || record.status === "OVERDUE" || record.status === "Not Requested", // Column configuration not to be checked    
        }),
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setDetailsModalVisible(true);
                setSelectedInstallment(record)
            }
        }
    }

    const handleDownload = () => {
        const params = {
            type: "Student Fee",
            invoiceKeys: selectedPayments
        }
        setDownloadLoader(true),
            downloadInvoices(params, () => { setDownloadLoader(false) }, (val) => { deleteZipFile(val) })
    }

    const history = useHistory();

    const handleBackPress = () => {
        if (["/general-settings", "/view-kyc", "/subscriptions", "/support-settings", "/user-profile/others"].includes(history.location?.state?.from)) {
            history.push('/account-settings')
        } else {
            history.goBack()
        }
    }
    return (
        <div style={{ height: "100%" }}>
            <PageHeader title="Summary" onBackPress={() => history.goBack()} easySwitch={user?.role?.includes('Student') ? easySwitch(history, user, setGsSelectedTab, setSubscriptionSelectedTab) : null} />

            <div className='r-c-c'>
                <div style={{ width: "80%" }} className="m-t-50">
                    <TabsComponent
                        setBreakupModalVisible={setBreakupModalVisible}
                        setOthersSelectedTab={setOthersSelectedTab}
                        othersSelectedTab={othersSelectedTab}
                        studentCourseDetails={studentCourseDetails}
                        studentFeeDetails={studentFeeDetails}
                        rowSelection={rowSelection}
                        onRow={onRow}
                        handleDownload={handleDownload}
                        downloadLoader={downloadLoader}
                        downloadVisible={selectedPayments.length > 1}
                        user={user}
                        setPageLoader={setPageLoader}
                        getCfrt={getCfrt}
                        cfrToken={cfrToken}
                        userDetails={userDetails}
                        setQuickAccessItem={setQuickAccessItem}
                    />
                </div>
            </div>
            <FeeBreakupModal
                visible={breakupModalVisible}
                handleClose={() => setBreakupModalVisible(false)}
            />
            <TransactionDetailsModal
                handleClose={() => setDetailsModalVisible(false)}
                visible={detailsModalVisible}
                data={selectedInstallment}
            />
            <PageDataLoader visible={pageLoader} />

        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const {quickAccessItem} = state.userManagementV2
    const { othersSelectedTab, studentCourseDetails, studentFeeDetails, cfrToken, userDetails } = state.profileNew;
    return {
        user, othersSelectedTab, studentCourseDetails, studentFeeDetails, cfrToken, userDetails, quickAccessItem
    }
}

const mapDispatchToProps = (dispatch) => ({
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    getFeeDetails: (selectedUserId, callback, fromWhere) => dispatch(getFeeDetails(selectedUserId, callback, fromWhere)),
    downloadInvoices: (params, callback, successCallback) => dispatch(downloadInvoices(params, callback, successCallback)),
    deleteZipFile: (params, callback) => dispatch(deleteZipFile(params, callback)),
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    setSubscriptionSelectedTab: (tab) => dispatch(setSubscriptionSelectedTab(tab)),
    getCfrt: (params, callback) => dispatch(getCfrt(params, callback)),
    setQuickAccessItem: (res) => dispatch(setQuickAccessItem(res)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Others);
