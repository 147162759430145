import React, {useMemo, useRef, useState } from 'react';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { Popover } from 'antd';
import ReactHlsPlayer from './hls-player';
import { Utils } from 'services';
const hlsConfig = {
    xhrSetup: function(xhr, url) {
      // Set cache-control headers to prevent caching
      xhr.setRequestHeader('Cache-Control', 'no-cache');
      xhr.setRequestHeader('Pragma', 'no-cache');
      xhr.setRequestHeader('Expires', '0');
    }
  };
  let t2 = null;
const availableQualities = ['360', '480', '720']
const VideoPlayer = (props) => {
    
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [currentQuality, setCurrentQuality] = useState('480');
  const [ispopovervisible, setpopovervisible] = useState(false);
  const [showControls,setShowControls] = useState(false);
  const [isLive, setLive] = useState(false);



  const makeFullScreen = () => {
    if (!containerRef||!containerRef.current) return
    let div = containerRef.current
    if (div.requestFullscreen) 
        div.requestFullscreen();
    else if (div.webkitRequestFullscreen) 
        div.webkitRequestFullscreen();
    else if (div.msRequestFullScreen) 
      div.msRequestFullScreen();
  }

  const onScreenToggle = (e) => {
    e.stopPropagation()
    setFullScreen(!isFullScreen)
    if (!isFullScreen) {
        makeFullScreen()
    } else {
        document.exitFullscreen();
    }
  }
  const goToLive = () => {
    if (isLive) return
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.duration-2;
      videoRef.current.play();
    }
};
  const streamUrl = useMemo(()=>{
    return `https://broadcast.aulas.in/${Utils.getBroadcastUrls('server')}/videos/${props.streamId}/${currentQuality}/index.m3u8`
  }, [props.streamId, currentQuality]);

  return (
    <div style={{position:"relative"}} ref={containerRef}>
      <ReactHlsPlayer
        autoPlay={true} 
        style={{...props.style, width:isFullScreen?'100vw':(props.style && props.style.width) ? props.style.width: '100%', height:isFullScreen?'100vh':(props.style && props.style.height) ? props.style.height: '100%'}} 
        controls={false} 
        playerRef={videoRef}
        hlsConfig={hlsConfig}
        liveCheckCallback={(bool)=>{
          setLive(bool)
        }}
        src={streamUrl}
        onEndCallback={()=>{
          console.log("72 videi has ended")
        }}
        onClick={()=>{ setShowControls(!showControls)}}/>
              {
                showControls?
              <div className="r-c-fe" style={{position:"absolute", bottom:15, height:40, width:"100%", background:"rgba(0, 0, 0, 0.5)"}}>
                <div className='r-c-c' style={{color:"white", cursor:"pointer"}} onClick={goToLive}>
                  <div style={{backgroundColor:isLive?"#c00":"lightgray", height:8, width:8, borderRadius:8, marginRight:5, opacity:1}}>

                  </div>
                  <div>
                  Live
                  </div>
                </div>
                <Popover getPopupContainer={() => containerRef.current} open={ispopovervisible} color={"#3e3e3e"} content={
                          <div style={{padding:5, paddingLeft:10, paddingRight:10, display:"flex", flexDirection:"column", alignItems:"center", backgroundColor:"none"}}>
                              {
                                availableQualities.map((item)=>(
                                  <div style={{fontWeight:"500", color:item===currentQuality?"black":"white", backgroundColor:item===currentQuality?"white":null, padding:5, borderRadius:5, cursor:"pointer"}} onClick={()=>{setCurrentQuality(item); setpopovervisible(false)}}>{item}p</div>
                                ))
                              }
                          </div>
                } title={null} trigger="click" onOpenChange={setpopovervisible} style={{zIndex:10}}>
                  <div className="m-l-5" style={{padding:10, borderRadius:10, padding:5, cursor:"pointer"}} onClick={(e)=>{
                    e.stopPropagation();
                    setpopovervisible(!ispopovervisible)}}>
                    <div style={{color:"white", fontWeight:"700"}}>{currentQuality}p</div>
                  </div>
                </Popover>
                <div style={{color:"white", opacity:1, marginLeft:10}} className='m-r-20 r-c-c cursor-pointer' onClick={onScreenToggle}><AiOutlineFullscreen style={{color:"white", fontSize:25, opacity:1}}/></div>
              </div>
                :null
                }
                </div>
  );
};

export default VideoPlayer;