import { GenerateKycOtp, VerifyKycOtp } from "actions/aulasWallet";
import { Select, DatePicker, Button } from "antd";
import { Input } from "components/customInputs";
import { options } from "less";
import moment from "moment";
import VerifyOtpModal from "pages/studentAulasWallet/modal/verifyOtpModal";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import './styles.css'

const LabelDiv =  styled.div`
    color: #636363;
    margin-bottom: 7px;
    font-weight: 600;
    font-family: roboto;
    font-size: 12px;
`;

const onKeyDown = (e) => {
    e.preventDefault();
}

const ProfileInput = ({label, type, disabled = false, required, value, onChange, options, placeholder, error=null, onFocusChange, disabledDate, GenerateKycOtp, VerifyKycOtp, resendOtpTimer, timer, isNumberVerified}) => {
    const [verfiyOtpModal, setVerfiyOtpModal] = useState(false);
    const sendOtpForRegistration = (phone) => {
        const phoneNumber = phone?.replace(/-/g, "").replace(/ /g, "");
        GenerateKycOtp(phoneNumber,()=>{});
      }    
    return (
        <div style={{width: "100%"}}>
            <LabelDiv>
                <div className="r-jsb">
                    <div style={{color: "636363"}}>{label}{required && <span style={{ color: "red" }}> *</span>}</div>
                    
                    {error && <div
                    style={{
                        color: "red",
                        marginLeft: "30%",
                    }}
                    >
                    {error}
                </div>}
                </div>
            </LabelDiv>
            {type === "text" ||type === "number" ? 
                <Input 
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        border: "#E6E6E6 solid 1px",
                        borderRadius: "5px",
                        height: "40px"
                    }}
                    // placeholder={(value!=="") ? value : placeholder}
                    placeholder={type === "number" ? ((typeof value === "number" && !isNaN(value)) ? value : placeholder) : (value  ? value : placeholder)}
                    value={value}
                    onChange={(e) => {if(e.target.value.charAt(0) !== ' ')onChange(e)}}
                    type={type}
                    disabled={disabled}
                    onBlur={onFocusChange ? (e) => onFocusChange(e) : () => {}}
                /> : type == "select" ?
                <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder={placeholder}
                    className="profileInputSelector"
                    defaultValue={null}
                    style={{ width: "100%", height: "40px", marginBottom: 20}}
                    value={value}
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                >
                    {options?.map((option, index) => <Option key={index} value={option.value ? option.value : option}>
                        {option.label ? option.label : option}
                    </Option>)}
                </Select>
                : type == "phone" ? 
                    <PhoneInput
                        className="PhoneInputBa"
                        defaultCountry="IN"
                        placeholder={placeholder}
                        height={"40px"}
                        value={value}
                        onChange={e => onChange(e)}
                        style={{ marginBottom: 20 }}
                        disabled={disabled}
                        maxLength={value?.startsWith('+91') && value?.length == 13 ? 11 : ""}
                        addInternationalOption={true}
                    />
                : type == 'phoneInputWithSendOtp' ?
                    <div className='r-c-sb'>
                        <PhoneInput
                            style={{ width: "100%", height: 40, marginBottom: 20 }}
                            defaultCountry="IN"
                            placeholder={placeholder}
                            value={value}
                            disabled={disabled}
                            onChange={e => onChange(e)}
                            maxLength={value?.startsWith('+91') && value?.length == 13 ? 11 : ""}
                            addInternationalOption={true}
                        />
                        {
                            isNumberVerified
                                ? <Button
                                    type="text"
                                    className="bold-bold"
                                    style={{
                                        color: "#28DF99",
                                        backgroundColor: 'transparent',
                                        marginTop: -20,
                                        marginLeft: -120,
                                    }} >
                                    Verified
                                </Button>
                                : <Button onClick={() => {
                                    sendOtpForRegistration(value);
                                    setVerfiyOtpModal(true);
                                }} type="text" className="bold-bold"
                                    style={{
                                        color: "#1089FF",
                                        backgroundColor: 'transparent',
                                        height: '20px',
                                        marginTop: -30,
                                        marginLeft: -120,
                                        opacity : (value?.length > 8 && value?.length <=17) ? 1 : 0.5
                                    }}
                                    disabled = {(value?.length > 8 && value?.length <=17) ? false : true}>
                                    SEND OTP
                                </Button>
                        }
                    </div> 
                : type == "date" ? 
                    <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        allowClear={false}
                        className="profileDatepicker"
                        // disabledDate={(current) => {return current && current >= moment().subtract(1, 'days');}}
                        placeholder={placeholder}
                        value={value && value !== "0000-00-00" ? moment(value) : null}
                        onChange={e => onChange(moment(e))}
                        style={{marginBottom: 20, width: "100%", height: 40}}
                        disabled={disabled}
                        format={"DD-MM-YYYY"}
                        onKeyDown={(e) => onKeyDown(e)}
                        disabledDate={disabledDate}
                    />
                : null
            }
            {verfiyOtpModal &&
            <VerifyOtpModal
                verfiyOtpModal={verfiyOtpModal}
                setVerfiyOtpModal={setVerfiyOtpModal}
                phoneNumber={value}
                VerifyKycOtp={VerifyKycOtp}
                GenerateKycOtp={GenerateKycOtp}
                resendOtpTimer={resendOtpTimer}
                timer={timer}/>
            }
        </div>
    )
}
const mapStateToProps = state => {
    const { resendOtpTimer, timer } = state.loginNew;
    const { isNumberVerified } = state.aulasWallet;
    return { resendOtpTimer, timer, isNumberVerified };
};

const mapDispatchToProps = dispatch => ({
    GenerateKycOtp: (phoneNumber, callBack) => dispatch(GenerateKycOtp(phoneNumber, callBack)),
    VerifyKycOtp: (phoneNumber, otp, callBack, successCallBack) => dispatch(VerifyKycOtp(phoneNumber, otp, callBack, successCallBack)),
    verifiedOtpForRegistrationAction: (bool) =>dispatch(verifiedOtpForRegistrationAction(bool))
});


export default connect(mapStateToProps, mapDispatchToProps)(ProfileInput);

export {LabelDiv}
