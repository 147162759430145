import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { Api } from 'services';

const SubjectGraph = ({ data }) => {

  const config = {
    data,
    xField: "key",
    yField: "presentPercentage",
    tooltip:
    {
      customContent: (val, item) => {
        return (
          <div style={{ marginLeft: "-20px", marginRight: "-20px", alignItems: "center" }}>
            <div
              style={{
                height: "120px",
                minWidth: "200px"
              }}>
              <div style={{ display: "flex", marginTop: "8px", width: "190px", marginLeft: "20px" }}>
                <div>
                  {
                    item[0]?.data?.icon != null ?
                      <img style={{ width: 40, height: 40 }} src={`${Api._s3_url}${item[0]?.data?.icon}`} /> :
                      <div style={{ marginTop: 20, marginLeft: 15, height: 20, width: 30, fontSize: 30 }}>
                        {item[0]?.data?.type?.charAt(0)}
                      </div>
                  }
                  {/* {console.log('dd', item[0])} */}
                </div>
                <div>
                  <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", marginTop: "10px", width: "150px", marginLeft: "8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item[0]?.data?.type}</div>
                  <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", marginTop: "5px", width: "150px", marginLeft: "8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item[0]?.data?.name}</div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px", width: "150px", marginLeft: "20px" }}>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto" }}>Total Classes   </div>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", fontWeight: "bold" }}>{item[0]?.data?.total}</div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px", width: "150px", marginLeft: "20px" }}>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto" }}>Present  </div>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", fontWeight: "bold" }}>{item[0]?.data?.Present} </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px", width: "150px", marginLeft: "20px" }}>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto" }}>Absent  </div>
                <div style={{ color: "#000000", fontSize: "11px", fontFamily: "Roboto", fontWeight: "bold" }}>{item[0]?.data?.Absent}</div>
              </div>
            </div>

          </div>
        )
      }
    },
    color: "#28DF99",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      },
    },
    slider: {
      start: 0.1,
      end: 0.1,
    },

    meta: {
      type: {
        alias: "Attendance",
      },
      // sales: {
      //   alias: "Attendance",
      // },
    },
    minColumnWidth: 35,
    maxColumnWidth: 30,
  };
  return <Column {...config} />;
};
export default SubjectGraph
