import { postCartPayment, setPromoCode, setValueForCheckout } from 'actions/studentMarketPlace';
import PageDataLoader from 'components/pageDataLoader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DownloadAppModal from '../downloadAppModal';

const RedirectPage = ({ isPromocode, postCartPayment, paymentOrderDetails, setValueForCheckout, storeCheckOutValue, singleBuyNowDetails, setPromoCode, userMarketPlaceHomeData
}) => {
    const [pageLoader, setPageLoader] = useState(false)
    const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)

    const history = useHistory()
    let paramProcess = {
        promo: isPromocode || paymentOrderDetails?.promo,
        orderId: paymentOrderDetails?.body?.order_id
    }
    let checkOutParams = {
        promo: isPromocode || paymentOrderDetails?.promo,
        orderId: paymentOrderDetails?.body?.order_id,
        type: singleBuyNowDetails?.item && singleBuyNowDetails.item.length > 0 ? singleBuyNowDetails.item[0].resourceType : undefined,
        id: singleBuyNowDetails?.item && singleBuyNowDetails.item.length > 0 ? singleBuyNowDetails.item[0].id : undefined,
        checkout: true
    };
    
    console.log("checkOutParams", checkOutParams, singleBuyNowDetails);
    useEffect(() => {
        setPageLoader(true)
        if (storeCheckOutValue == true) {
            postCartPayment(checkOutParams, (res, error) => {
                console.log("response123", res, paramProcess, error);
                if (res == undefined && res?.show?.type != 'success') {
                    history.push("/user-checkout")
                    setValueForCheckout(false)
                }
                else {
                    setPromoCode('');
                    history.push("/")
                    setShowDownloadAppModal(true)
                }
                setPageLoader(false)
            }
            )

        }else{
            postCartPayment(paramProcess, (res, error) => {
                console.log("response123", res, paramProcess, error);
                if (res == undefined && res?.show?.type != 'success') {
                    history.push("/cart")
                }
                else {
                    setPromoCode('');
                    history.push("/")
                    setShowDownloadAppModal(true)
                }
                setPageLoader(false)
            }
            )
        }
       
    }, [])
    return (
        <div>
            <PageDataLoader visible={pageLoader} />
            {showDownloadAppModal &&
            <DownloadAppModal
              showDownloadAppModal={showDownloadAppModal}
              setShowDownloadAppModal={setShowDownloadAppModal}
              userMarketPlaceHomeData={userMarketPlaceHomeData}
            />}
        </div>
    )


}
const mapStateToProps = (state) => {
    const { isPromocode, paymentOrderDetails, storeCheckOutValue, singleBuyNowDetails, userMarketPlaceHomeData
    } = state.studentMarketPlace
    return {
        isPromocode, paymentOrderDetails, storeCheckOutValue, singleBuyNowDetails, userMarketPlaceHomeData


    }
};

const mapDispatchToProps = (dispatch) => ({
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),
    setValueForCheckout: (val) => dispatch(setValueForCheckout(val)),
    setPromoCode: (val) => dispatch(setPromoCode(val)),


});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage)

