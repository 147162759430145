import React from 'react';
import {ChatBox} from '../../components';
import { connect } from "react-redux";
import '../../design/layout.css';

class ParticipantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: true
    };
  }

  setTab = (val) => this.setState({tab: val})
  render() {
    const {tab} = this.state;
    const {onlineUsers, offlineUsers} = this.props;
    return (
      <div>
        <div className='onlineOffline r-c-sb'>
          <div
            onClick={() => this.setTab(true)}
            className='text-xs flex-1 p-10 r-c-c cursor-pointer'
            style={{zIndex: 1}}
          >
            <div className='backgreen p-5 radius-100 m-r-5' />
            <div style={{color: tab ? 'white' : 'black'}}>
              Online
            </div>
          </div>
          <div
            onClick={() => this.setTab(false)}
            className='text-xs flex-1 p-10 r-c-c cursor-pointer'
            style={{zIndex: 1}}
          >
            <div className='backred p-5 radius-100 m-r-5' />
            <div style={{color: tab ? 'black' : 'white'}}>
              Offline
            </div>
          </div>
          <div className='movingTab' style={{right: tab ? '50%': 0}}/>
        </div>
        {tab ?
          <>
            {onlineUsers.length ?
              onlineUsers.map((data) => (
                <ChatBox name={data.name} rollNo={data.student ? data.student.rollNo : 'N/A'} />
              ))
              : null }
          </>
          :
          <>
          {offlineUsers.length ?
            offlineUsers.map((data) => (
              <ChatBox name={data.name} offLine rollNo={data.student ? data.student.rollNo : 'N/A'} />
            ))
            : null }
          </>
        }
      </div>
    )
  }
}



const mapStateToProps = state => {
  const {
    offlineUsers,
    onlineUsers
  } = state.liveClass;

  return {
    offlineUsers,
    onlineUsers,
  };
};

export default connect(mapStateToProps)(ParticipantList);
