import React, { useState, useEffect } from 'react';
import { Tabs, Button, message } from 'antd';
import './styles.css'
import { connect } from 'react-redux';
import { Notification } from 'services';
import { getOrgDetails, uploadDocument, postKycDetails, fetchPinCodeDetails } from 'actions/kyc';
import PageDataLoader from 'components/pageDataLoader';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router'
import SubmitSuccess from './modals/submitSuccess'
import ImgViewModal from './modals/imgView';
import EditCommentModal from './modals/editComment';
import { documentDetailsPvt, documentDetailsProp, documentDetailsPartner, documentDetailsNtRegistered, } from './documentForm';
import { contactInfo } from './contactForm';
import { businessInfo } from './businessInfoForm';
import { bankDetails } from './bankDetailsForm';
import { agreement } from './agreement';
import ConfirmSubmitModal from './modals/confirmSubmitModal';
import { postMobileNumberForRegistration, postVerifyOtpForRegistration, setVerfiyOtpModal, verifiedOtpForRegistrationAction } from 'actions/login';

const ifsc = require('ifsc');
const { TabPane } = Tabs;
let params = {};
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const actionButtons = (selectedTab, changeFormPage, kycDetails, handleSaveClick, disableSave, disableSave2, submitLoader, verifiedOtpforRegistration) => {
    return (
        <div className={selectedTab !== 'contactInfo' ? "r-jsb" : "r-c-c"} style={{ width: 300 }} >
            {selectedTab !== '1' ? <Button shape="round" style={{ width: 140 }} onClick={() => changeFormPage((parseInt(selectedTab) - 1).toString())}>
                Previous
            </Button> : null}
            <Button type="primary" shape="round" style={{ width: 150 }}
                disabled={
                    (selectedTab === '1' && (kycDetails.contactName === "" || kycDetails.contactPhone === "" || kycDetails.contactEmail === "" || verifiedOtpforRegistration===false)) ||
                    (selectedTab === '2' && disableSave) ||
                    (selectedTab === '3' && (kycDetails.beneficiaryName === "" || kycDetails.ifscValid !== true ||
                        kycDetails.accNoMatch !== true || !kycDetails.cancelled_cheque || kycDetails.cancelled_cheque_status === "rejected")) ||
                    // (selectedTab === '4' && disableSave2) ||
                    (selectedTab === '5' && (!kycDetails.agreementAccepted || kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"))

                }
                loading={selectedTab === '5' && submitLoader}
                onClick={() => handleSaveClick()}>
                {selectedTab === '5' ? "Submit" : "Next"}
            </Button>
        </div>
    )
}


function KycForm({
    getOrgDetails, fetchPinCodeDetails,
    uploadDocument, postKycDetails,organisationDetails,
    verifiedOtpforRegistration, setVerfiyOtpModal, 
    postMobileNumberForRegistration, verfiyOtpModal, 
    otpKey, verifyOtpLoader, postVerifyOtpForRegistration, 
    resendOtpTimer, sendOtpLoader, timer, verifiedOtpForRegistrationAction
}) {
    useEffect(() => {
        updateOrgDetailsLoader(true)
        updateBlockRedirect(true)
        // document.addEventListener('contextmenu', contextMenuDisable);


        getOrgDetails(loadOrgCallback, history);
        return () => {
            updateBlockRedirect(false);
            // document.removeEventListener('contextmenu', contextMenuDisable);
        };
    }, []);

    const contextMenuDisable = event => event.preventDefault();

    const validateData = (value, label) => {
        if (label === "institute-pan") {
            if (value.length != 10) {
                handleUpdateDetails(false, 'institutePanValid')
            } else {
                handleUpdateDetails(true, 'institutePanValid')
            }
        } else if (label === "personal-pan") {
            if (value.length != 10) {
                handleUpdateDetails(false, 'personalPanValid')
            } else {
                handleUpdateDetails(true, 'personalPanValid')
            }

        } else if (label === "gst") {
            if (value.length != 15) {
                handleUpdateDetails(false, 'gstValid')
            } else {
                handleUpdateDetails(true, 'gstValid')
            }
        } else if (label === "cin") {
            if (value.length != 21) {
                handleUpdateDetails(false, 'cinValid')
            } else {
                handleUpdateDetails(true, 'cinValid')
            }
        } else if (label === "llpin") {
            if (value.length != 7) {
                handleUpdateDetails(false, 'llpinValid')
            } else {
                handleUpdateDetails(true, 'llpinValid')
            }
        } else if (label === "pin") {
            if (value.length != 6) {
                handleUpdateDetails(false, 'pincodeValid')
            } else {
                handleUpdateDetails(true, 'pincodeValid')
            }
        } else if (label === 'adhaar') {
            if (value.length != 12) {
                handleUpdateDetails(false, 'adhaarValid')
            } else {
                handleUpdateDetails(true, 'adhaarValid')
            }
        }

    }

    const history = useHistory();

    const loadOrgCallback = async (data = null) => {
        updateOrgDetailsLoader(false);
        let ifscValid = data?.ifsc ? ifsc.validate(data?.ifsc) : false, branch, bankName;
        if (ifscValid) {
            await ifsc.fetchDetails(data?.ifsc).then(async function (res) {
                bankName = res.BANK;
                branch = res.BRANCH;
            });
        }

        window.onbeforeunload = function () {
            if (data?.kycStatus === "Pending" || data?.kycStatus === "Rejected") {
                return true
            }
            else return
        }

        if(data?.kycStatus == 'Approved'){
            setIsEnabled(false)
        }

        let propDocType = data ? data.business_proof_type === "MSME/Udyam/Udyog Certificate" ? 'msme' :
            data.business_proof_type === "GST Certificate" ? 'gst' :
                data.business_proof_type === "Shop Establishment Act Certificate" ? 'shop_establishment' : null : null;

        let addrDocType = data ? data.proof_of_address_type !== "Voter ID" ? data.proof_of_address_type?.toLowerCase() : "voter" : null;
        if (data) {
            updateKycDeails({
                ...kycDetails,
                contactName: data?.contactName,
                contactPhone: data?.contactPhone,
                contactEmail: data?.contactEmail,
                noOfStudents: data?.strength,
                name: data?.name,
                website: data?.website,
                businessPanNo: data?.institute_pan ? data?.institute_pan.toUpperCase() : '',
                orgType: data?.orgType,
                gstNo: data?.gstin ? data?.gstin.toUpperCase() : '',
                gstAvailable: data?.gstin && data?.gstin !== "" ? true : false,
                hsn_sac: data?.hsn_sac,
                pincode: data?.pincode,
                city: data?.city,
                state: data?.state,
                locality: data?.locality,
                coutry: data?.coutry,
                panOwnerName: data?.pan_owner,
                cin: data?.cin ? data?.cin.toUpperCase() : '',
                personalPanNo: data?.pan ? data?.pan.toUpperCase() : '',
                beneficiaryName: data?.account_name,
                accNo: data?.account_no,
                ifsc: data?.ifsc ? data?.ifsc.toUpperCase() : '',
                accNoHidden: data?.account_no,
                llpin: data?.llpin ? data?.llpin.toUpperCase() : '',
                institutePanValid: data?.institute_pan?.length === 10,
                personalPanValid: data?.pan?.length === 10,
                gstValid: data?.gstin?.length === 15,
                cinValid: data?.cin?.length === 21,
                llpinValid: data?.llpin?.length === 7,
                pincodeValid: data?.pincode?.length === 6,
                adhaarValid: data?.aadhaar?.length === 12,
                ifscValid,
                bankName,
                branch,
                accNoMatch: true,
                proofOfAddrType: data?.proof_of_address_type,
                adhaarNo: data?.aadhaar ? data?.aadhaar.toUpperCase() : '',
                proprietorshipProof: data?.business_proof_type,
                kycStatus: data?.kycStatus || "Pending",
                pan_card: data?.pan_card,
                [propDocType]: data ? data[propDocType] : null,
                [addrDocType + '_front']: data ? data[addrDocType + '_front'] : null,
                [addrDocType + '_back']: data ? data[addrDocType + '_back'] : null,
                institute_registration_proof: data?.institute_registration_proof,
                form_12a: data?.form_12a,
                form_80g: data?.form_80g,
                pan_card_status: data?.pan_card_status,
                [propDocType + '_status']: data ? data[propDocType + '_status'] : null,
                [addrDocType + '_front_status']: data ? data[addrDocType + '_front_status'] : null,
                [addrDocType + '_back_status']: data ? data[addrDocType + '_back_status'] : null,
                institute_registration_proof_status: data?.institute_registration_proof_status,
                form_12a_status: data?.form_12a_status,
                form_80g_status: data?.form_80g_status,
                pan_card_type: data?.pan_card_type,
                [propDocType + '_type']: data ? data[propDocType + '_type'] : null,
                [addrDocType + '_front_type']: data ? data[addrDocType + '_front_type'] : null,
                [addrDocType + '_back_type']: data ? data[addrDocType + '_back_type'] : null,
                institute_registration_proof_type: data?.institute_registration_proof_type,
                form_12a_type: data?.form_12a_type,
                form_80g_type: data?.form_80g_type,
                pan_card_comments: data?.pan_card_comments,
                [propDocType + '_comments']: data ? data[propDocType + '_comments'] : null,
                [addrDocType + '_front_comments']: data ? data[addrDocType + '_front_comments'] : null,
                [addrDocType + '_back_comments']: data ? data[addrDocType + '_back_comments'] : null,
                institute_registration_proof_comments: data?.institute_registration_proof_comments,
                form_12a_comments: data?.form_12a_comments,
                form_80g_comments: data?.form_80g_comments,
                agreementAccepted: data?.kycStatus !== "Pending",
                cancelled_cheque: data?.cancelled_cheque,
                addressProofIsAadhaar: data?.proof_of_address_type === "Aadhaar",
                aadhaar_front: data?.aadhaar_front,
                aadhaar_back: data?.aadhaar_back,
                superAdminName: data?.superAdminName,
                superAdminDesignation: data?.superAdminDesignation,
                phone: data?.phone,
                email: data?.email,

            })
        }
    }

    const [blockRedirect, updateBlockRedirect] = useState(false);

    const [fileName, updateFileName] = useState('');

    const [showImageModal, updateShowImgModal] = useState(false);

    const [orgDetailsLoader, updateOrgDetailsLoader] = useState(false);

    const [documentUploadLoader, updateDocUpdateLoader] = useState(false);

    const [submitLoader, toggleSubmitLoader] = useState(false)

    const [uploadDocType, updateUploadDocType] = useState('')

    const [formPage, changeFormPage] = useState("1");

    const [showSubmitSuccessModal, toggleSubmitSuccesModal] = useState(false)

    const [selectedDocType, updateDocType] = useState('');

    const [showEditCommentModal, updateShowEditCommentModal] = useState(false);

    const [kycDetails, updateKycDeails] = useState({
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        name: '',
        website: '',
        orgType: undefined,
        noOfStudents: undefined,
        businessPanNo: '',
        gstAvailable: undefined,
        gstNo: '',
        hsn_sac: '',
        locality: '',
        city: '',
        state: '',
        pincode: '',
        country: 'India',
        beneficiaryName: '',
        ifsc: '',
        ifscValid: undefined,
        accNoHidden: '',
        accNo: '',
        bankName: '',
        branch: '',
        accNoMatch: undefined,
        adhaarNo: '',
        addressFront: undefined,
        addressBack: undefined,
        panCard: undefined,
        businessReg: undefined,
        proofOfAddrType: undefined,
        cin: '',
        personalPanNo: '',
        panOwnerName: '',
        llpin: '',
        institutePanValid: undefined,
        personalPanValid: undefined,
        gstValid: undefined,
        cinValid: undefined,
        llpinValid: undefined,
        pincodeValid: undefined,
        adhaarValid: undefined,
        form12a: undefined,
        form80g: undefined,
        kycStatus: undefined,
        cancelled_cheque: undefined,
        addressProofIsAadhaar: false

    })

    const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false)
    const handleDocClick = (docType) => {
        updateFileName(kycDetails[docType]);
        updateShowImgModal(true)
    }

    // useEffect(() => {
    //     if(formPage === "3"){
    //         console.log("Added");
    //         document.removeEventListener('contextmenu', contextMenuDisable);
    //         setTimeout(() => document.addEventListener('contextmenu', contextMenuDisable), 1000)

    //     }else{
    //         console.log("Removed");

    //         document.removeEventListener('contextmenu', contextMenuDisable);
    //     }

    // }, [formPage]);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isJpgOrPng) {
            Notification.error('error', "You can only upload JPG/PNG/PDF file!");
        } else if (!isLt5M) {
            Notification.error('error', "Image must be smaller than 5MB!");
        }
        return isJpgOrPng && isLt5M;
    }

    const handleUploadChange = (info, key) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            //   console.log(info.file, info.fileList);
        }
        if (status === 'done' || status === 'error') {
            uploadingProgress()
            handleUploadDocument(info.file.originFileObj, key)
            // Notification.success('success', `${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    }

    const uploadingProgress = () => {
        const hide = message.loading('Upload in progress..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };

    const handleUploadDocument = (file, key) => {
        updateDocUpdateLoader(true);
        updateUploadDocType(key);
        console.log({ file, key });
        uploadDocument(file, key, uploadCallback, (file, type) => updateKycDeails({
            ...kycDetails,
            [key]: file, [key + '_type']: type,
            [key + '_comment']: "", [key + '_status']: undefined
        }));

    }
    const uploadCallback = () => {
        updateDocUpdateLoader(false);
        updateUploadDocType('');
    }
    // const  handleFileDrop = (e) => {
    //     console.log('Dropped files', e.dataTransfer.files);
    // }

    const handleUpdateDetails = (val, key) => {
        { console.log("kycDetails", kycDetails) }
        updateKycDeails({ ...kycDetails, [key]: val })
    }

    const handleSaveClick = () => {
        if (formPage === "1") {
            if (!validateEmail(kycDetails.contactEmail)) {
                return Notification.error('error', "Enter a valid email!")
            }
        }
        if (formPage === "2") {
            if (kycDetails.personalPanNo.length < 10) {
                return Notification.error('error', "Enter a valid pan number!")
            }
            if (kycDetails.orgType === "Private Limited") {
                if (kycDetails.businessPanNo.length < 10) {
                    return Notification.error('error', "Enter a valid pan number!")
                }
            } else if (kycDetails.pincode.length !== 6) {
                return Notification.error('error', "Enter a valid PIN Code!")
            }
        }
        if (formPage === "5") {
            onSubmit()
        } else {
            changeFormPage((parseInt(formPage) + 1).toString())
        }
    }


    const onSubmit = () => {
        // toggleSubmitLoader(true)
        setIsSubmitModalVisible(true)


        if (kycDetails.orgType === "Private Limited" || kycDetails.orgType === "LLP" || kycDetails.orgType === "Trust" || kycDetails.orgType === "Society") {
            params = {
                contactName: kycDetails.contactName,
                contactPhone: kycDetails.contactPhone,
                contactEmail: kycDetails.contactEmail,
                name: kycDetails.name,
                website: kycDetails.website,
                orgType: kycDetails.orgType,//undefined,
                strength: kycDetails.noOfStudents,
                institute_pan: kycDetails.businessPanNo,
                gstin: kycDetails.gstNo,
                address: JSON.stringify({
                    locality: kycDetails.locality,
                    city: kycDetails.city,
                    state: kycDetails.state,
                    pincode: kycDetails.pincode
                }),
                hsn_sac: kycDetails.hsn_sac,
                country: kycDetails.country,
                account_name: kycDetails.beneficiaryName,
                ifsc: kycDetails.ifsc,
                account_no: kycDetails.accNo,
                proof_of_address_type: kycDetails.proofOfAddrType,
                cin: kycDetails.cin,
                llpin: kycDetails.llpin,
                pan: kycDetails.personalPanNo,
                pan_owner: kycDetails.panOwnerName,
            }

            // postKycDetails(params, () => toggleSubmitLoader(false), () => toggleSubmitSuccesModal(true))
        } else if (kycDetails.orgType === "Proprietorship" || kycDetails.orgType === "Partnership" ||
            kycDetails.orgType === "Not Registered") {
            params = {
                contactName: kycDetails.contactName,
                contactPhone: kycDetails.contactPhone,
                contactEmail: kycDetails.contactEmail,
                name: kycDetails.name,
                website: kycDetails.website,
                orgType: kycDetails.orgType,
                strength: kycDetails.noOfStudents,
                gstin: kycDetails.gstNo,
                address: JSON.stringify({
                    locality: kycDetails.locality,
                    city: kycDetails.city,
                    state: kycDetails.state,
                    pincode: kycDetails.pincode
                }),
                hsn_sac: kycDetails.hsn_sac,
                country: kycDetails.country,
                account_name: kycDetails.beneficiaryName,
                ifsc: kycDetails.ifsc,
                account_no: kycDetails.accNo,
                proof_of_address_type: kycDetails.proofOfAddrType,
                cin: kycDetails.cin,
                pan: kycDetails.personalPanNo,
                pan_owner: kycDetails.panOwnerName,
                aadhaar: kycDetails.adhaarNo,
                business_proof_type: kycDetails.proprietorshipProof,
            }

            // postKycDetails(params, () => toggleSubmitLoader(false), () => toggleSubmitSuccesModal(true))
        }

    }

    const handleEditCommentClick = (docType) => {
        updateDocType(docType);
        updateShowEditCommentModal(true)
    }

    const changeAddressProofType = (type) => {
        if (type !== kycDetails.proofOfAddrType)
            updateKycDeails({ ...kycDetails, proofOfAddrType: type, addressFront: false, addressBack: false })
    }

    const changeProprietoshipProofType = (type) => {
        if (type !== kycDetails.proprietorshipProof)
            updateKycDeails({ ...kycDetails, proprietorshipProof: type, businessReg: false })
    }

    const handleIfscUpdate = async (e) => {
        if (ifsc.validate(kycDetails.ifsc)) {
            await ifsc.fetchDetails(kycDetails.ifsc).then(async function (res) {
                await setTimeout(() => {
                    updateKycDeails({ ...kycDetails, bankName: res.BANK, branch: res.BRANCH, ifscValid: true })
                    // handleUpdateDetails(true, 'ifscValid')

                }, 50)
            });
        } else {
            updateKycDeails({ ...kycDetails, bankName: '', branch: '', ifscValid: false })
            // handleUpdateDetails(false, 'ifscValid')
        }
        // console.log(kycDetails.bankName, kycDetails.branch);
    }

    const handlePinUpdate = e => {
        fetchPinCodeDetails(e.target.value, pinUpdateCallback)
    }

    const pinUpdateCallback = (response) => {
        if (response?.Status === "Success") {
            const details = response.PostOffice[0]
            updateKycDeails({ ...kycDetails, city: details.District, state: details.State, pincodeValid: true })
        } else {
            updateKycDeails({ ...kycDetails, city: '', state: '', pincodeValid: false })
        }
    }


    let disableSave = true;
    if (kycDetails.orgType === "Private Limited") {
        disableSave = (kycDetails.name === "" ||
            !kycDetails.institutePanValid || !kycDetails.cinValid ||
            !kycDetails.personalPanValid || kycDetails.panOwnerName === "" ||
            kycDetails.locality === "" || kycDetails.city === "" ||
            kycDetails.state === "" || !kycDetails.pincodeValid
            || kycDetails.country === "" || (kycDetails.gstAvailable && (!kycDetails.gstValid)))
    } else if (kycDetails.orgType === "Proprietorship" || kycDetails.orgType === "Partnership") {
        disableSave = (kycDetails.name === "" ||
            !kycDetails.personalPanValid || kycDetails.panOwnerName === "" ||
            kycDetails.locality === "" || kycDetails.city === "" ||
            kycDetails.state === "" || !kycDetails.pincodeValid
            || kycDetails.country === "" || (kycDetails.gstAvailable && (!kycDetails.gstValid)))
    } else if (kycDetails.orgType === "LLP") {
        disableSave = (kycDetails.name === "" ||
            !kycDetails.institutePanValid || !kycDetails.llpinValid ||
            !kycDetails.personalPanValid || kycDetails.panOwnerName === "" ||
            kycDetails.locality === "" || kycDetails.city === "" ||
            kycDetails.state === "" || !kycDetails.pincodeValid
            || kycDetails.country === "" || (kycDetails.gstAvailable && (!kycDetails.gstValid)))
    } else if (kycDetails.orgType === "Trust" || kycDetails.orgType === "Society") {
        disableSave = (kycDetails.name === "" ||
            !kycDetails.institutePanValid ||
            !kycDetails.personalPanValid || kycDetails.panOwnerName === "" ||
            kycDetails.locality === "" || kycDetails.city === "" ||
            kycDetails.state === "" || !kycDetails.pincodeValid
            || kycDetails.country === "" || (kycDetails.gstAvailable && (!kycDetails.gstValid)))
    } else if (kycDetails.orgType === "Not Registered") {
        disableSave = (kycDetails.name === "" ||
            !kycDetails.personalPanValid || kycDetails.panOwnerName === "" ||
            kycDetails.locality === "" || kycDetails.city === "" ||
            kycDetails.state === "" || !kycDetails.pincodeValid
            || kycDetails.country === "")
    }

    let disableSave2 = true;
    let propDocType = kycDetails.proprietorshipProof === "MSME/Udyam/Udyog Certificate" ? 'msme' :
        kycDetails.proprietorshipProof === "GST Certificate" ? 'gst' :
            kycDetails.proprietorshipProof === "Shop Establishment Act Certificate" ? 'shop_establishment' : null;

    let addrDocType = kycDetails.proofOfAddrType !== "Voter ID" ? kycDetails.proofOfAddrType?.toLowerCase() : "voter";

    if (kycDetails.orgType === "Private Limited" || kycDetails.orgType === "LLP") {
        disableSave2 = (kycDetails.proofOfAddrType && (!kycDetails[addrDocType + '_front'] || !kycDetails[addrDocType + '_back']) || !kycDetails.pan_card || !kycDetails.institute_registration_proof ||
            (kycDetails.proofOfAddrType && (kycDetails[addrDocType + '_front_status'] === "rejected" || kycDetails[addrDocType + '_back_status'] === "rejected")) || kycDetails.pan_card_status === "rejected"
            || kycDetails.institute_registration_proof_status === "rejected"
        )
    } else if (kycDetails.orgType === "Proprietorship") {
        disableSave2 = (!kycDetails.adhaarValid || !kycDetails[addrDocType + '_front'] ||
            !kycDetails[addrDocType + '_back'] || !kycDetails.pan_card || (propDocType && !kycDetails[propDocType])
            || !kycDetails.aadhaar_front || kycDetails.aadhaar_front_status === "rejected" || !kycDetails.aadhaar_back || kycDetails.aadhaar_front_back === "rejected" ||
            (propDocType === "gst" && !kycDetails.gstValid) ||
            (kycDetails[addrDocType + '_front_status'] === "rejected" ||
                kycDetails[addrDocType + '_back_status'] === "rejected")) || kycDetails.pan_card_status === "rejected"
            || (propDocType && kycDetails[propDocType + '_status'] === "rejected")
    } else if (kycDetails.orgType === "Partnership") {
        disableSave2 = (!kycDetails.adhaarValid || !kycDetails[addrDocType + '_front'] ||
            !kycDetails.aadhaar_front || kycDetails.aadhaar_front_status === "rejected" || !kycDetails.aadhaar_back || kycDetails.aadhaar_front_back === "rejected" ||
            !kycDetails[addrDocType + '_back'] || !kycDetails.pan_card || !kycDetails.institute_registration_proof ||
            kycDetails[addrDocType + '_front_status'] === "rejected" || kycDetails[addrDocType + '_back_status'] === "rejected" || kycDetails.pan_card_status === "rejected" || kycDetails.institute_registration_proof_status === "rejected")
    } else if (kycDetails.orgType === "Trust" || kycDetails.orgType === "Society") {
        disableSave2 = (kycDetails.proofOfAddrType && (!kycDetails[addrDocType + '_front'] ||
            !kycDetails[addrDocType + '_back']) || !kycDetails.pan_card || !kycDetails.institute_registration_proof ||
            (kycDetails.proofOfAddrType && (kycDetails[addrDocType + '_front_status'] === "rejected" || kycDetails[addrDocType + '_back_status'] === "rejected")) ||
            kycDetails.pan_card_status === "rejected" || !kycDetails.institute_registration_proof_status === "rejected")
    } else if (kycDetails.orgType === "Not Registered") {
        disableSave2 = (!kycDetails.adhaarValid || !kycDetails[addrDocType + '_front'] ||
            !kycDetails[addrDocType + '_back'] || !kycDetails.aadhaar_front || kycDetails.aadhaar_front_status === "rejected" || !kycDetails.aadhaar_back || kycDetails.aadhaar_front_back === "rejected" ||
            (kycDetails[addrDocType + '_front_status'] === "rejected" || kycDetails[addrDocType + '_back_status'] === "rejected"))
    }

    const compareAcNo = () => {
        if (kycDetails.accNoHidden === kycDetails.accNo) {
            handleUpdateDetails(true, 'accNoMatch')
        } else {
            handleUpdateDetails(false, 'accNoMatch')
        }
    }

    const changeGstAvailability = (e) => {
        if (e.target.value) {
            handleUpdateDetails(true, 'gstAvailable')
        } else {
            updateKycDeails({ ...kycDetails, gstAvailable: false, gstNo: '' })
        }
    }

    const updateAddressProofTypeIsAadhaar = (e) => {
        if (e.target.value) {
            updateKycDeails({ ...kycDetails, addressProofIsAadhaar: true, proofOfAddrType: 'Aadhaar' })
        } else {
            updateKycDeails({ ...kycDetails, addressProofIsAadhaar: false, proofOfAddrType: '' })
        }
    }

    const [isEnabled,setIsEnabled] = useState(true)
    const [activeTab,setActiveTab] = useState(1)

    const tabChangeHandler = (key) =>{
        if(organisationDetails?.kycStatus == 'Approved'){
            setIsEnabled(false)
            setActiveTab(key)
        }
    }

    return (
        <>
            {/* <Prompt
                when={false}
                message="Are you sure you want to leave?"
                /> */}

            <div style={{ height: "100%" }}>
                <div className='r-c-sb'>
                    <div className='text-mdl bold-700'>KYC Form </div>
                </div>
                <Tabs className="kycTab" activeKey={isEnabled?`${formPage}`:`${activeTab}`} onChange={(key) => {tabChangeHandler(key)}} defaultActiveKey="1">
                    <TabPane tab={<span className="bold-700">CONTACT INFORMATION</span>} disabled={isEnabled} key={1}>
                        <div className="r-c-jsb" style={{ height: "80vh" }}>
                            <div style={{ width: '100%', height: '95%', overflowY: 'auto' }}>
                                {contactInfo(kycDetails, handleUpdateDetails,verifiedOtpforRegistration,setVerfiyOtpModal,postMobileNumberForRegistration,verfiyOtpModal,otpKey,verifyOtpLoader,postVerifyOtpForRegistration,resendOtpTimer,sendOtpLoader,timer,verifiedOtpForRegistrationAction,organisationDetails)}
                            </div>
                            {isEnabled?actionButtons(formPage, changeFormPage, kycDetails, handleSaveClick, disableSave, disableSave2,verifiedOtpforRegistration):null}
                        </div>
                    </TabPane>
                    <TabPane tab={<span className="bold-700">BUSINESS DETAILS</span>} disabled={isEnabled} key={2}>
                        <div className="r-c-jsb" style={{ height: "80vh" }}>
                            <div style={{ width: '100%', height: '95%', overflowY: 'auto' }}>
                                {businessInfo(kycDetails, handleUpdateDetails, validateData, handlePinUpdate, changeGstAvailability)}
                            </div>
                            {isEnabled?actionButtons(formPage, changeFormPage, kycDetails, handleSaveClick, disableSave, disableSave2):null}
                        </div>
                    </TabPane>
                    <TabPane tab={<span className="bold-700">BANK ACCOUNT</span>} disabled={isEnabled}  key={3}>
                        <div className="r-c-jsb" style={{ height: "80vh" }}>
                            <div style={{ width: '100%', height: '95%', overflowY: 'auto' }}>
                                {bankDetails(kycDetails, handleUpdateDetails, handleIfscUpdate, compareAcNo, handleUploadChange, beforeUpload, handleDocClick, handleEditCommentClick)}
                            </div>
                            {isEnabled?actionButtons(formPage, changeFormPage, kycDetails, handleSaveClick, disableSave, disableSave2):null}
                        </div>
                    </TabPane>
                    <TabPane tab={<span className="bold-700">DOCUMENTS VERIFICATION</span>} disabled={isEnabled}  key={4}>
                        <div className="r-c-jsb" style={{ height: "80vh" }}>
                            <div style={{ width: '100%', height: '95%', overflowY: 'auto' }}>
                                <div className="text-xs m-t-10 dark-grey">You can upload JPG/PNG/PDF  files of size not exceeding 5MB.</div>
                                {kycDetails.orgType === "Private Limited" || kycDetails.orgType === "LLP" ||
                                    kycDetails.orgType === "Trust" || kycDetails.orgType === "Society"
                                    ?
                                    documentDetailsPvt(handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, beforeUpload, handleUploadChange, changeAddressProofType, updateAddressProofTypeIsAadhaar)
                                    : kycDetails.orgType === "Proprietorship" ?
                                        documentDetailsProp(handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, beforeUpload, handleUploadChange, validateData,
                                            changeAddressProofType, changeProprietoshipProofType, updateAddressProofTypeIsAadhaar)
                                        : kycDetails.orgType === "Partnership" ?
                                            documentDetailsPartner(handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, beforeUpload, handleUploadChange, validateData, changeAddressProofType, updateAddressProofTypeIsAadhaar)
                                            :
                                            documentDetailsNtRegistered(handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, beforeUpload, handleUploadChange, validateData, changeAddressProofType, updateAddressProofTypeIsAadhaar)
                                }
                            </div>
                            {isEnabled?actionButtons(formPage, changeFormPage, kycDetails, handleSaveClick, disableSave, disableSave2, submitLoader):null}
                        </div>
                    </TabPane>
                    <TabPane tab={<span className="bold-700">AGREEMENT</span>} disabled={isEnabled} key={5}>
                        <div className="r-c-jsb" style={{ height: "80vh" }}>
                            <div style={{ width: '100%', height: '95%', overflowY: 'auto' }}>
                                {agreement(kycDetails, handleUpdateDetails)}
                            </div>
                            {isEnabled?actionButtons(formPage, changeFormPage, kycDetails, handleSaveClick, disableSave, disableSave2):null}
                        </div>
                    </TabPane>

                </Tabs>

                {
                    isSubmitModalVisible ?
                        <ConfirmSubmitModal isSubmitModalVisible={isSubmitModalVisible} setIsSubmitModalVisible={setIsSubmitModalVisible} postKycDetails={postKycDetails} params={params} toggleSubmitSuccesModal={toggleSubmitSuccesModal} />
                        :
                        <></>
                }
                <PageDataLoader visible={orgDetailsLoader} />
                {
                    showSubmitSuccessModal ?
                        <SubmitSuccess visible={showSubmitSuccessModal} onClose={() => toggleSubmitSuccesModal(false)} history={history} />
                        :
                        <></>
                }
                {showImageModal && <ImgViewModal visible={showImageModal} closeModal={() => updateShowImgModal(false)} fileName={fileName} />}
                {showEditCommentModal && <EditCommentModal visible={showEditCommentModal} closeModal={() => updateShowEditCommentModal(false)} documentType={selectedDocType} comment={kycDetails[selectedDocType + '_comments']} />}


            </div>
        </>

    )
}

const mapStateToProps = state => {
    const {
        organisationDetails
    } = state.kyc;
    const {verifiedOtpforRegistration,verfiyOtpModal,otpKey,verifyOtpLoader,resendOtpTimer,sendOtpLoader,timer} = state.login
    return {
        organisationDetails,verifiedOtpforRegistration,verfiyOtpModal,otpKey,verifyOtpLoader,resendOtpTimer,sendOtpLoader,timer
    };
};

const mapDispatchToProps = dispatch => ({
    getOrgDetails: (callback, history) => dispatch(getOrgDetails(callback, history)),
    uploadDocument: (file, key, callback, successCallback) => dispatch(uploadDocument(file, key, callback, successCallback)),
    postKycDetails: (params, callback, successCallBack) => dispatch(postKycDetails(params, callback, successCallBack)),
    fetchPinCodeDetails: (pin, callback) => dispatch(fetchPinCodeDetails(pin, callback)),
    setVerfiyOtpModal: (bool) => dispatch(setVerfiyOtpModal(bool)),
    postMobileNumberForRegistration: (phone, history) => dispatch(postMobileNumberForRegistration(phone, history)),
    postVerifyOtpForRegistration: (otp, key) =>dispatch(postVerifyOtpForRegistration(otp, key)),
    verifiedOtpForRegistrationAction: (bool) =>dispatch(verifiedOtpForRegistrationAction(bool))
});


export default connect(mapStateToProps, mapDispatchToProps)(KycForm);