import { Modal } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react'
import { connect } from 'react-redux';

function ConfirmDeleteModal({ showConfirmDeleteModal, setConfirmDeleteModal, handleDeleteSection, index, className, sectionName, fromAllStudents, selectedStudents, removeStudents, togglePageLoader, getSubjStudentsData, selectedSection, setSelectedStudents, isConfirmVisible, onEditClassSave, confirmLoader, allStudentsData }) {
    const handleCancel = () => {
        setConfirmDeleteModal(false)
    }
    const [unassignLoader, setUnassignLoader] = useState(false);
    let selectedStudentsName = allStudentsData
    .filter((item) => selectedStudents?.includes(item?.id))
    .map((item) => item?.name)?.join(", ") 
    .replace(/, ([^,]*)$/, ' and $1')
    return (
        <>
            <Modal
                className="modal-round-corner"
                centered
                visible={showConfirmDeleteModal}
                onOk={handleCancel}
                onCancel={handleCancel}
                header={null}
                footer={null}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Confirm</div>
                    <div
                        style={{
                            fontSize: "16px",
                            color: "#636363",
                            fontFamily: "roboto",
                            marginTop: "10px",
                            textAlign: 'center'
                        }}
                    >
                        {
                            fromAllStudents
                                ? <p>Are you sure you want to unassign <span style={{ fontWeight: "bold" }}>{selectedStudentsName}</span> from <span style={{ fontWeight: "bold" }}>{`${selectedSection?.std} : ${selectedSection?.section}`}</span>?</p>
                                :
                                isConfirmVisible ?
                                    <p>There are tests, schedules and DL contents added in the class. The old curriculum data will be deleted if you choose to import the new curriculum. Are you sure you want to proceed?</p>
                                    :
                                    <p>Are you sure you want to delete <span style={{ fontWeight: "bold" }}>{`${className} : ${sectionName}`}</span>?</p>
                        }
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "50px",
                        marginBottom: 20
                    }}
                >
                    <Button
                        loading={unassignLoader}
                        style={{ color: isConfirmVisible ? "#636363" : "red", fontWeight: "bold", fontFamily: "roboto", border: "none" }}
                        onClick={() => {
                            if (isConfirmVisible) {
                                setConfirmDeleteModal(false);
                            }
                            else if (fromAllStudents) {
                                setUnassignLoader(true);
                                removeStudents(
                                    selectedStudents,
                                    () => setUnassignLoader(false),
                                    () => {
                                        setConfirmDeleteModal(false);
                                        setSelectedStudents([])
                                        togglePageLoader(true);
                                        getSubjStudentsData(() => togglePageLoader(false))
                                    })
                            } else {
                                handleDeleteSection(index)
                            }
                        }}
                    >
                        {fromAllStudents ? "UNASSIGN" : isConfirmVisible ? "CANCEL" : "DELETE"}
                    </Button>
                    <Button
                        loading={isConfirmVisible ? confirmLoader : null}
                        type='primary'
                        onClick={() => {
                            if (isConfirmVisible) {
                                onEditClassSave()
                            } else {
                                handleCancel()
                            }
                        }}>
                        {isConfirmVisible ? "YES" : "CANCEL"}
                    </Button>
                </div>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => {
	const { allStudentsData } = state.classRoom;
	return {
		allStudentsData
	};
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal);
// export default ConfirmDeleteModal