import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import './modal.css';

const ExitModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="exitModal">
      {/* <Button type="primary" onClick={showModal}>
        EXIT
      </Button> */}
      <Modal className="exitModal" visible={isModalVisible} cancelText={"LEAVE"} okText={"RESUME"} onOk={handleOk} onCancel={handleCancel}>
         
         <h2 className="exit_h1">Do you want to exit the test?</h2>
         <p className="exit_p">The test will remain paused and you can resume the test later.</p>

         
      </Modal>
    </div>
  );
};

export default ExitModal
