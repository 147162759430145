
import Modal from 'antd/lib/modal/Modal'
import React from 'react'

function ViewImgModal({
    visible, imgPath, onCancel
}) {
    return (
        <Modal
            visible={visible}
            footer={false}
            onCancel={() => onCancel()}
            width="fit-content"
        >
            <img src={imgPath} alt="" style={{maxHeight: "500px", maxWidth: "1000px"}} />
        </Modal>
    )
}

export default ViewImgModal