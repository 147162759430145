import { CloseOutlined } from "@ant-design/icons";
import { dismissPreferredCourse } from "actions/userManagement";
import { Button, Typography } from "antd";
import PageDataLoader from "components/pageDataLoader";
import React, { useState } from "react";
import { connect } from "react-redux";
import courseIcon from "../../Assets/course/ic-course (2).svg";

function PreferedCourseCard({
  item,
  setViewAddCourseModal,
  setCourseDetails,
  dismissPreferredCourse,
}) {
  const [pageLoader, setPageLoader] = useState(false);
  return (
    <div
      className="box-shadow flex_row_center"
      style={{
        width: "330px",
        padding: "20px",
        margin: "20px",
        height: "100px",
        // cursor: "pointer",
      }}
    >
      <div style={{ marginLeft: "0px" }}>
        <img width="50px" src={courseIcon} alt="" />
      </div>
      <div style={{ width: "150px", marginLeft: "15px" }}>
        <Typography.Text
          ellipsis={{ tooltip: true }}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            marginTop: "0px",
            width: "80px",
          }}
        >
          {item?.preferredCourseName}
          {/* Notice */}
        </Typography.Text>
        <div
          style={{
            color: "#636363",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          preferred course
        </div>
      </div>
      <div style={{ marginRight: "-100px", marginLeft: "20px" }}>
        <Button
          onClick={() => {
            setViewAddCourseModal(true);
            setCourseDetails("preferredPop", true);
            setCourseDetails("dismissCard", true);
          }}
          style={{
            color: "white",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "20px",
            height: "27px",
            backgroundColor: "#594099",
            paddingTop: "2px",
          }}
        >
          ADD
        </Button>
      </div>
      <div
        // onClick={() => {
        //   setPageLoader(true);
        //   dismissPreferredCourse(() => {
        //     setPageLoader(false);
        //   });
        // }}
        style={{
          color: "red",
          marginTop: "-80px",
          marginLeft: "100px",
          marginRight: "-30px",
          cursor: "pointer",
        }}
      >
        <CloseOutlined
          onClick={() => {
            setPageLoader(true);
            dismissPreferredCourse(() => {
              setPageLoader(false);
            });
          }}
        />
      </div>
      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

// export default PreferedCourseCard;
const mapStateToProps = (state) => {
  const { studentCourseDetails, userDetailsById } = state.userManagement;
  return {
    studentCourseDetails,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dismissPreferredCourse: (callback) =>
    dispatch(dismissPreferredCourse(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreferedCourseCard);
