import React, { useState } from 'react'
import { RadialBar, Pie } from '@ant-design/charts';
import { useEffect, useRef } from 'react'

// const LeaveTypeGraph = ({ storeAttendanceLeaveOverview }) => {
// console.log("AA-graph", storeAttendanceLeaveOverview?.leaveTypeSummary);
// const LeaveColors = {
//     Sick: "#FFA931",
//     Marriage: "#99DED9",
//     Paternity: "#28DF99",
//     Maternity: "#B2DFFF",
//     Casual: "#FEA5DD",
//     Privilege: "#9876E6",

// }
// const [colorArray, setColorArray] = useState([]);
// const [loaded, setLoaded] = useState(false);

// useEffect(() => {

//     let temp = []
//     const data = storeAttendanceLeaveOverview?.leaveTypes?.forEach(element => {
//         temp.push(LeaveColors[`${element.val}`])
//     });
//     setColorArray(temp);
//     setLoaded(true);
// }, [])

// {
// const data = [
//     {
//         type: 'Teachers',
//         value: 70

//     },
//     {
//         type: 'Data Operators',
//         value: 20
//     },
//     {
//         type: 'Operations',
//         value: 10,
//     }
// ];
// const actualvals = [4, 2, 10]
// const sum = actualvals[0]+actualvals[1]+actualvals[2]
// const vals = actualvals.map((val)=>(Math.floor(val*100/sum)))
// console.log("cals vals", vals)
// const data = [
//   {
//     name: 'Chapter test',
//     star: vals[0],
//     bgcolor:'#9464F9'
//   },
//   {
//     name: 'Part test',
//     star: vals[1],
//     bgcolor:'#99DED9'

//   },
//   {
//     name: 'Full test',
//     star: vals[2],
//     bgcolor:'#589DFF'

//   }
// ];
// const config = {
//   data,
//   xField: 'name',
//   yField: 'star',
//   maxAngle: 350,
//   //最大旋转角度,
//   radius: 0.8,
//   innerRadius: 0.2,
//   maxBarWidth: 5,
//   tooltip: {
//     formatter: (datum) => {
//       return {
//         name: datum.name,
//         value: datum.star,
//       };
//     },
//   },
//   colorField: 'bgcolor',
//   color: ['#9464F9', '#99DED9', '#589DFF'],
//   barBackground: ['#9464F9', '#99DED9', '#589DFF'],
//   barStyle: {
//     lineCap: 'round',
//   },
//   // annotations: [
//   //   {
//   //     type: 'html',
//   //     position: ['50%', '50%'],
//   //     html: (container, view) => {
//   //       const coord = view.getCoordinate();
//   //       const w = coord.polarRadius * coord.innerRadius * 1.15;
//   //       return `<div></div>`;
//   //     },
//   //   },
//   // ],
// };
// const config = {
//     appendPadding: 10,
//     // data,
//     angleField: 'value',
//     colorField: 'type',
//     color: colorArray,
//     radius: 0.8,
//     autoFit: true,
//     legend: false,
//     innerRadius: 0.68,
//     label: {
//         type: 'inner',
//         offset: '-50%',
//         content: "",
//         style: {
//             fill: '#fff',
//             fontSize: 14,
//             textAlign: 'center',
//         },
//     },
//     statistic: {
//         title: false,
//         content: {
//             style: {
//                 whiteSpace: 'pre-wrap',
//                 overflow: 'hidden',
//                 textOverflow: 'ellipsis',
//                 fontSize: 8
//             },
//             customHtml: function customHtml() {
//                 return (
//                     <div>
//                         <div style={{ fontSize: "20px", fontWeight: "700" }}>{/* {attendanceDataContainer?.monthlyAttendance?.averagePercentage} */}20%</div>
//                         <div style={{ fontSize: "10px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto", color: "grey" }}>Average</div>
//                     </div>
//                 )
//             }
//         },
//     },
//     annotations: [
//     ],
// };


//     return (
//         <>
//             <div
//                 style={{
//                     //  width:"20vw",
//                     width: "280px",
//                     // position: "absolute",
//                     // top: -50,
//                     // marginLeft: 50
//                 }}>
//                 {/* <RadialBar {...config} />; */}
//                 {loaded && <Pie {...config} />}
//             </div>
//             {/* <div className="r-jsb absolute" style={{ bottom: 10, marginLeft: 120 }}>
//                 {tags?.map((tag, index) =>
//                     <div className="r-c-c m-r-15" key={index}>
//                         <div style={{
//                             backgroundColor: tagcolor[index],
//                             width: "15px",
//                             height: "15px",
//                             borderRadius: "3px",
//                             marginRight: "5px"
//                         }}>

//                         </div>
//                         <div style={{ fontSize: 12 }}>
//                             {tag}
//                         </div>
//                     </div>
//                 )
//                 }
//             </div> */}
//         </>

//     )
// }





const LeaveTypeGraph = ({ storeAttendanceLeaveOverview }) => {
    const color = {
        Sick: "#FFA931",
        Marriage: "#99DED9",
        Paternity: "#28DF99",
        Maternity: "#B2DFFF",
        Casual: "#FEA5DD",
        Privilege: "#9876E6",
    }
    let colorArray = ["#FFA931", "#BBDEFC","#28DF99", "#B2DFFF","#FEA5DD","#9876E6"];
    const data = [
        {
            type: 'Sick',
            value: storeAttendanceLeaveOverview?.leaveTypeSummary?.Sick ? storeAttendanceLeaveOverview?.leaveTypeSummary?.Sick : 0,
            color: "#FFA931"
        },
        {
            type: 'Marriage',
            value: storeAttendanceLeaveOverview?.leaveTypeSummary?.Marriage ? storeAttendanceLeaveOverview?.leaveTypeSummary?.Marriage : 0,
            color: "#99DED9"
        },
        {
            type: 'Paternity',
            value: storeAttendanceLeaveOverview?.leaveTypeSummary?.Paternity ? storeAttendanceLeaveOverview?.leaveTypeSummary?.Paternity : 0,
            color: "#28DF99"
        },
        {
            type: 'Maternity',
            value: storeAttendanceLeaveOverview?.leaveTypeSummary?.Maternity ? storeAttendanceLeaveOverview?.leaveTypeSummary?.Maternity : 0,
            color: "#B2DFFF"
        },
        {
            type: 'Casual',
            value: storeAttendanceLeaveOverview?.leaveTypeSummary?.Casual ? storeAttendanceLeaveOverview?.leaveTypeSummary?.Casual : 0,
            color: "#FEA5DD"
        },
        {
            type: 'Privilege',
            value: storeAttendanceLeaveOverview?.leaveTypeSummary?.Privilege ? storeAttendanceLeaveOverview?.leaveTypeSummary?.Privilege : 0,
            color: "#9876E6"
        }
    ]
    console.log(data, "tempdata")
    const config = {
        // height:"100%",
     
        appendPadding: 10,
        data,
        angleField: "value",
        colorField: "type",
        // color:["#FFA931", "#FEA5DD", "#9876E6", "#99DED9", "#28DF99", "#FFA931"] ,
        color: colorArray,
        radius: 0.7,
        innerRadius: 0.7,
        label: {
            type: "inner",
            offset: "-50%",
            content: "",
            style: {
                textAlign: "center",
                fontSize: 8,
            },
        },
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                customHtml: function customHtml() {
                    return (
                        <div>
                                <div style={{ fontSize: "20px", fontWeight: "700" }}>{storeAttendanceLeaveOverview?.leaveTypeSummary?.totalLeaves}</div>
                                {/* <div style={{ marginTop: "5px", fontSize: "14px", color: "#636363", fontWeight: "300", marginLeft: "10px" }}></div> */}
                                <div style={{ fontSize: "10px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto", color: "grey" }}>Total leave taken</div>

                        </div>)
                }
                // content: `${data?.map((item)=>item.value)?.reduce((a, b) => a + b, 0)} days Total leave`,

            },
        },
    };

    return <Pie {...config} />;
};



/* 
color:`${item.leaveType==="Sick"?"#FFA931":
item.leaveType==="Marriage"?"#99DED9":
item.leaveType==="Paternity"?"#28DF99":
item.leaveType==="Maternity"?"#B2DFFF":
item.leaveType==="Casual"?"#FEA5DD":
item.leaveType==="Privilege"?"#9876E6":null}`  */



export default LeaveTypeGraph