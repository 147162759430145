import { Avatar, Image, Progress, Typography, Menu, Dropdown, Tooltip } from 'antd'
import React, { useState } from 'react'
import { ClockCircleFilled, InfoCircleOutlined, LinkOutlined, UserOutlined } from '@ant-design/icons'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { Api, Color } from 'services'
import moment from 'moment'
import EditFormModal from './modals/editForm'
import EditOccurrance from './modals/editOccurrance'
import DelConfirmModal from './modals/deleteConfirmModal'
import { connect } from 'react-redux'
import { SquareSubIcon } from 'components/subIcon'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { FaGraduationCap } from 'react-icons/fa'
import Conference  from "Assets/schedule/confrence.svg"
import Broadcast  from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import { EventRegister } from 'react-native-event-listeners'
import { EmbedModal, regexMeet } from './onGoingClassCard'
import { postEmbedLink } from 'actions/broadcast'
import OtherColor from "Assets/schedule/other_color.svg"
import { useHistory } from 'react-router-dom'
import { setLiveStreamUrl } from 'actions/schedule'
import UserAvatar from 'components/userAvatar'


const { Text } = Typography
const ScheduleClassCard = ({
  scheduleItem, index, togglePageLoader, scheduleId, access, delOrCancel, postEmbedLink, setLiveStreamUrl
}) => {
  const history = useHistory();
  const [isVisibleEditSchedule, setVisibleEditSchedule] = useState(false)
  const [isVisibleEditOccurance, setVisibleEditOccurance] = useState(false)
  const [isDeleteModal, toogleDeleteModal] = useState(false)
  const [embedModalVisible, setEmbedModalVisible] = useState(false);
  const [embedding, setEmbedding] = useState(false);
  const menu = (
    <Menu style={{ width: "150px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
      <Menu.Item key="1" onClick={() => setVisibleEditOccurance(true)}>
        Edit occurrence
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setVisibleEditSchedule(true)}>
        Edit schedule
      </Menu.Item>
      {
        scheduleItem.mode === 'Others' ?
        <>
        <Menu.Item key="3" onClick={()=>{handleJoin()}}>
          {scheduleItem.sUrl!=undefined?'Replace link':'Add link'}      
        </Menu.Item>
        {scheduleItem?.sUrl != undefined ?
        <Menu.Item key="4" onClick={()=>{playStream()}}>
            Play stream
        </Menu.Item>
        :null}
        </>
        :null
      }
    </Menu>

  );
  const playStream = () => {
    if (scheduleItem.sUrl.match(regexMeet)){
      window.open(scheduleItem.sUrl, '_blank');
    }else{
      setLiveStreamUrl(scheduleItem?.sUrl)
      history.push('/liveplayer')
    }
  }

  const handleJoin = () => {
    setEmbedModalVisible(true);
  }

  const onEmbed = async(link) => {
    setEmbedding(true);
    await postEmbedLink({id:scheduleItem.id, sUrl: link})
    setEmbedding(false);
    setEmbedModalVisible(false);
    EventRegister.emit('apicall:scheduleHome');
  }

  return (
    <div className="scheduleclass-main">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="scheduleclass-img-text">
          <div>
            <SquareSubIcon icon={scheduleItem?.subject?.icon ? scheduleItem?.subject?.icon : ''} name={scheduleItem?.subject?.name ? scheduleItem?.subject?.name : ''} size={40} />
          </div>
          <div style={{ width: "200px", marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
            {scheduleItem.mode == "Broadcast" ?
              <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '3px 7px' }}>
                <img src={Broadcast} style={{ color: '#FFA931', textAlign: 'center', height: "16px", width: "16px" }} />
                <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
              </div>
              :
              scheduleItem.mode == "Conference" ?
                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '3px 7px' }}>
                  <img src={Conference} style={{ color: '#594099', height: "16px", width: "16px" }} />
                  <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                </div>
                :
                scheduleItem.mode == 'Others' ?
                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                    <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                    <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                </div>
                :
                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '3px 7px' }}>
                  <img src={Offline} style={{ color: '#28DF99', textAlign: 'center', height: "16px", width: "16px" }} />
                  <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                </div>
            }
            <Tooltip title={scheduleItem?.subject?.name ? scheduleItem?.subject?.name : null}>
              <Text style={{ fontWeight: 'bold', fontSize: '15px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{scheduleItem?.subject?.name}</Text>
            </Tooltip>
            <div style={{ width: '100%',  display:"flex", flexDirection:"row",alignItems:"center",  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <Tooltip title={scheduleItem?.standards.length != 0 ? scheduleItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
              </Tooltip>
              {scheduleItem?.standards.length != 0 ? scheduleItem?.standards?.map((item, index, array) => (
                <Text style={{ fontSize: '80%', fontFamily: 'roboto' }} key={index}>{item?.std}-{item?.section}{index === array.length - 1 ? '' : '|'}</Text>
              )) : null}
            </div>

          </div>
        </div>
        {access == true ?
          <div style={{ width: '18px' }}>
            <Dropdown overlay={menu} disabled={false} placement="bottomRight" >
              <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
            </Dropdown>
          </div> : null}
        {(isVisibleEditSchedule && <EditFormModal isModalVisible={isVisibleEditSchedule} setModalVisible={setVisibleEditSchedule} editData={scheduleItem} toogleDeleteModal={toogleDeleteModal} togglePageLoader={togglePageLoader} />)}
        {(isVisibleEditOccurance && <EditOccurrance isModalVisible={isVisibleEditOccurance} setModalVisible={setVisibleEditOccurance} editData={scheduleItem} togglePageLoader={togglePageLoader} toogleDeleteModal={toogleDeleteModal} />)}
        {(isDeleteModal && <DelConfirmModal isModalVisible={isDeleteModal} setModalVisible={toogleDeleteModal} id={scheduleId} delOrCancel={delOrCancel} />)}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <div style={{display:"flex", flexDirection:"row", alignItems:"center",  width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <UserAvatar size="small" shape="circle" name={scheduleItem?.teacher?.name} img={scheduleItem?.teacher?.image == "default.png" ? "" : `${Api._s3_url}${scheduleItem?.teacher?.image}`} />
          <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{scheduleItem?.teacher?.name}</Text>
        </div>
        <div >
          <ClockCircleFilled style={{ fontSize: '11px', color: '#594099' }} />
          <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(scheduleItem?.startTime, 'HH:mm:ss').format("hh:mm A")}-${moment(scheduleItem?.endTime, 'HH:mm:ss').format("hh:mm A")}`}</Text>
        </div>
      </div>
      {embedModalVisible ? <EmbedModal adding={scheduleItem.sUrl==undefined} embedding={embedding} visible={embedModalVisible} onClose={()=>{setEmbedModalVisible(false)}} onOk={onEmbed}/>:null}
    </div>
  )
}



const mapStateToProps = (state) => {
  const {
    scheduleId,
    access,
    delOrCancel
  } = state.schedule;
  return {
    scheduleId,
    access,
    delOrCancel
  };
};

const mapDispatchToProps = (dispatch) => ({
  postEmbedLink: (params, c, sc) => dispatch(postEmbedLink(params, c, sc)),
  setLiveStreamUrl: (url) => dispatch(setLiveStreamUrl(url))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(ScheduleClassCard);