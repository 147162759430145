import { Button, Modal } from 'antd';
import React from 'react'
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {showApprovalPendingModal} from 'actions/kyc';
import './styles.css';

function ApprovalPending({showApprovalPendingModal, kycApprovalPendingModal}) {
    const history = useHistory();
    return (
        <Modal
            // onCancel={() => showKycPendingModal(false)}
            closable={false}
            footer={false}
            visible={kycApprovalPendingModal}
            className="kycPendingModal"
        >
            <div className="r-c-c-c" style={{height: "100%"}}>
                <div>
                    <img src={require('Assets/kyc.png').default} height={250} alt="" />
                </div>
                <div className="m-t-20 text-md color-purple bold-700">
                    KYC Details Submitted
                </div>
                <div className="m-t-20" style={{width: '70%', textAlign: 'center'}}>
                    <div>Your KYC approval is under process and shall be approved within 24-48 hrs.
                    You will be able to collect fees from students after the approval is done.</div>
                    <div>Please contact aulas care for any other assistance.</div>
                </div>
                <div className="m-t-20 bold-600">
                    <Button type="primary" shape="round" style={{width: 160, fontWeight: 600}}
                        onClick={() => {showApprovalPendingModal(false)}}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = state => {
    const {
        kycApprovalPendingModal
    } = state.kyc;
    return {
        kycApprovalPendingModal
    };
  };

  const mapDispatchToProps = dispatch => ({
    showApprovalPendingModal: bool => dispatch(showApprovalPendingModal(bool))
  });


export default connect(mapStateToProps, mapDispatchToProps)(ApprovalPending);
