import React, { useState } from 'react';
import './loginPage.css';
import { Font15, Font17, Font21, Heading } from 'components/responsiveTypography';
import { Button } from 'components/Button';
import AnimatedTextField from 'components/AnimatedTextField';
import { connect } from 'react-redux';
import { postGenerateRegistrationOtp, setShowRegisterForm } from 'actions/loginNew';

const roleArray = [
    { label: "Student", value: "student" },
    { label: "Staff", value: "staff" }
];

function LoginRegisterForm({ setShowRegisterForm, postGenerateRegistrationOtp, setShowVerifyOtpForm, userRegister, setUserRegister, isLoginModal, phoneNumber }) {

    const [submitLoader, setSubmitLoader] = useState(false);

    const [selectedRole, setSelectedRole] = useState(null);

    const handleRoleClick = (value) => {
        setSelectedRole(value);
    };

    const handleNameInputChange = (e) => {
        let value = e.target.value
        value = value.replace(/\s\s+/g, '');
        setUserRegister({ ...userRegister, name: value })
    };

    const handleRegisterFormSubmit = () => {
        setSubmitLoader(true)
        postGenerateRegistrationOtp(
            () => { setSubmitLoader(false) },
            () => { setShowRegisterForm(false), setShowVerifyOtpForm(true) }
        )
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && userRegister?.name) {
            handleRegisterFormSubmit();
        }
    };

    return (
        <div className={isLoginModal ? 'login-modal-form-width' : 'form-width'}>
            <div className='m-b-20'>
                <Font21 bold color='#191919'>New student? Register now!</Font21>
            </div>
            <div className='m-b-40'><Font15 color='#636363'>You are registering with {phoneNumber} <span className='cursor-pointer' style={{ color: "#1089FF", fontWeight: 700 }} onClick={() => { setShowRegisterForm(false); setUserRegister({ name: "" }); setShowVerifyOtpForm(false) }}>EDIT</span></Font15></div>
            {/* <div className='m-b-20'>
                <Font17 color="#636363">You are registering as </Font17>
            </div>
            <div className='r-c-sb m-b-40' style={{ width: "100%", gap: 20 }}>
                {roleArray?.map((item) => (
                    <div
                        key={item.value}
                        onClick={() => handleRoleClick(item.value)}
                        className='r-c-c'
                        style={{
                            color: selectedRole === item.value ? "#191919" : "#636363",
                            border: selectedRole === item.value ? "1px solid #BBB8E8" : "1px solid #AEAEAE",
                            borderRadius: 100,
                            fontSize: 17,
                            padding: "4px 32px",
                            width: "50%",
                            height: "fit-content",
                            backgroundColor: selectedRole === item.value ? "#BBB8E8" : "transparent",
                            cursor: 'pointer'
                        }}
                    >
                        {item?.label}
                    </div>
                ))}
            </div> */}
            <div style={{ marginBottom: 120 }}>
                <AnimatedTextField
                    label="Name"
                    value={userRegister?.name}
                    handleInputChange={handleNameInputChange}
                    isMandatory={false}
                    inputId="name"
                    onKeyDown={handleKeyDown} // Add the keydown handler
                />
            </div>
            <Button
                type="primary"
                style={{ width: "100%" }}
                disabled={!userRegister?.name}
                onClick={handleRegisterFormSubmit}
                loading={submitLoader}
            >
                GENERATE OTP
            </Button>
        </div>
    );
}
const mapStateToProps = (state) => {
    const { } = state.loginNew;
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
    postGenerateRegistrationOtp: (callback, successCallBack) => dispatch(postGenerateRegistrationOtp(callback, successCallBack))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegisterForm);
