import React, { Component } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import { Color } from '../../services';


class StudentRedirect extends Component {
    render () {
        return (
            <div>
                <Row justify='center' style={{textAlign: 'center', fontWeight: 'bold', fontSize: 22, color: Color.primary}}>
                Hello Students!!
                </Row>
                <Row justify='center' style={{textAlign: 'center',fontSize: 18}}>
                <br /> Welcome to Vectors Digital Classroom.
                <br /> Download our mobile app to get the live classroom experience.
                <br /> Follow the links below to download the app on your smartphones or tablets.
                </Row>
                <Row justify='center' style={{textAlign: 'center', marginTop:100}}>

                <Col span={12}>
                    <a href="https://play.google.com/store/apps/details?id=com.vectorseducation.classroom">
                        <img src={require("../../Assets/playstore.png").default} width={250} alt="Android" />
                    </a>
                </Col>
               </Row>
            </div>

        )
    }
}
export default StudentRedirect;
