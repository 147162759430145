import React, { useEffect, useState } from 'react'
import { Modal, Select, Input, Row, Col } from 'antd'
import { Button } from 'components/Button'
import { connect } from 'react-redux'
import { Heading } from 'components/Typography'
import { fetchStandards, setCourseDetails } from 'actions/userManagement'
import { assignStudentToStd, fetchStudentRollNo, storeStudentRollNo } from 'actions/userManagementV2'
import {getStudentsDetailsById} from 'actions/userManagement'
import { Notification } from 'services'

const AddAClass = ({ open, onCancel, feeMangementData, tab, dashBoardRole, dashboardData, classList, studentCourseDetails, installments, userDetailsById, nextRollNo, UpdateRollNo, user, updateVisible, fetchStandards, standards, setCourseDetails, fetchStudentRollNo, studentRollNo, assignStudentToStd, storeStudentRollNo, getStudentsDetailsById }) => {

        const [filteredSections, setFilteredSections] = useState([]);
        const [markLoader, setMarkLoader] = useState(false);

        const markDisabler = studentCourseDetails?.class == '' || studentCourseDetails?.class == null || studentCourseDetails?.class == undefined || studentCourseDetails?.section == '' || studentCourseDetails?.section == null || studentCourseDetails?.section == undefined;

        useEffect(() => {
                let temp = userDetailsById?.student?.standards?.filter((item) => {
                        if (item?.std == studentCourseDetails?.class) {
                                return item;
                        }
                });

                if (temp != []) {
                        setFilteredSections(temp);
                }

        }, [studentCourseDetails?.class]);

        const handleMark = () => {

                const successCallBack = () => {
                        setCourseDetails('class', null) 
                        setCourseDetails('section', null) 
                        setCourseDetails('sectionId', null) 
                        storeStudentRollNo(null);
                        onCancel();
                        getStudentsDetailsById(() => {}, userDetailsById?.id)
                };

                setMarkLoader(true);
                assignStudentToStd(() => {setMarkLoader(false)}, () => successCallBack())
        }

        return (
                <Modal
                        centered
                        closable
                        open={open}
                        footer={null}
                        className='modal-round-corner'
                        onCancel={onCancel}
                >
                        <center>
                                <Heading>Add a class</Heading>

                                <Col style={{ padding: 20 }}>
                                        <div className='simGrey bold-600' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 43 }}>Class</div>
                                        <Select
                                                placeholder='Select a class'
                                                style={{ width: '80%' }}
                                                value={studentCourseDetails?.class ? studentCourseDetails?.class : null}
                                                onChange={(e) => {
                                                        setCourseDetails("class", e);
                                                        setCourseDetails("section", null);
                                                        setCourseDetails("sectionId", null);
                                                }}>
                                                {userDetailsById?.student?.standards?.map((item, index) => (
                                                        <Option value={item?.std}>{item?.std}</Option>
                                                ))}
                                        </Select>

                                        <div className='simGrey bold-600' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 43, marginTop: 20 }}>Section</div>
                                        <Select
                                                placeholder='Select a section'
                                                style={{ width: '80%' }}
                                                value={studentCourseDetails?.section !== -1 ? studentCourseDetails?.section : null}
                                                disabled={!(user?.privilage.includes("SuperAdmin") || user?.privilage.includes("Admin"))}
                                                onChange={(e) => {
                                                        setCourseDetails("section", e);
                                                        setCourseDetails("sectionId", e);
                                                        // setRollNoLoader(true);
                                                        fetchStudentRollNo(e)
                                                }}
                                        >
                                                {filteredSections?.[0]?.sectionNameArray?.map((item, index) => (
                                                        <Option value={filteredSections?.[0]?.sectionIdArray[index]}>
                                                                {item}
                                                        </Option>
                                                ))}
                                        </Select>

                                        <div className='simGrey bold-600' style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 43, marginTop: 20 }}>Roll number</div>
                                        <Row style={{ justifyContent: 'space-between', width: '80%' }}>
                                                <Input
                                                        style={{ width: '100%' }}
                                                        value={studentRollNo?.rollNo ?? null}
                                                        disabled
                                                        placeholder='Roll no will auto-populate'
                                                ></Input>
                                        </Row>
                                        <Button onClick={() => handleMark()} loading={markLoader} disabled={markDisabler} type='primary' style={{ marginTop: 40 }}>MARK</Button>
                                </Col>
                        </center>
                </Modal>
        )
}


const mapStateToProps = (state) => {
        const { tab, dashBoardRole, dashboardData, classList, studentCourseDetails, userDetailsById, nextRollNo, UpdateRollNo, standards } = state.userManagement;
        const { user } = state.session;
        const { installments } = state.course;
        const { feeMangementData, } = state.feeManagement;
        const { studentRollNo } = state.userManagementV2

        return { feeMangementData, tab, dashBoardRole, dashboardData, classList, studentCourseDetails, installments, userDetailsById, nextRollNo, UpdateRollNo, user, standards, studentRollNo };
};

const mapDispatchToProps = (dispatch) => ({
        setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
        setInstallments: (val) => dispatch(setInstallments(val)),
        setCoursePrice: (val) => dispatch(setCoursePrice(val)),
        updateScholarshipOrAddition: (callback) => dispatch(updateScholarshipOrAddition(callback)),
        unassignStudent: (callback) => dispatch(unassignStudent(callback)),
        setFeeDetails: (res) => dispatch(setFeeDetails(res)),
        setRollNumber: (res) => dispatch(setRollNumber(res)),
        UpdateRollNumber: () => dispatch(UpdateRollNumber()),
        fetchStandards: res => dispatch(fetchStandards(res)),
        fetchStudentRollNo: params => dispatch(fetchStudentRollNo(params)),
        assignStudentToStd: (callback, successCallback) => dispatch(assignStudentToStd(callback, successCallback)),
        storeStudentRollNo: rollNo => dispatch(storeStudentRollNo(rollNo)),
        getStudentsDetailsById: (callback, id) => dispatch(getStudentsDetailsById(callback, id))

});


export default connect(mapStateToProps, mapDispatchToProps)(AddAClass)