import React, { useEffect, useState } from "react";
import '../style.css'
// import "antd/dist/antd.css";
import { Modal, Button, Input, DatePicker, Space, Select } from "antd";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { createLeaves, storeCreateLeaveModalData, editLeaveBalance, getLeaveOverViewData } from "actions/attendanceNew";
import moment from "moment";

const EditTeacherModal = ({ setPageLoader, isEditTeacherModalVisible, setIsEditTeacherModalVisible, item, userIds, editLeaveBalance, getLeaveOverViewData, allData }) => {

    const [selectedLeave, setSelectedLeave] = useState(0);
    const [balance, setBalance] = useState('');
    const [availed, setAvailed] = useState('');
    const [total, setTotal] = useState('');
    const [selectedLeaveName, setSelectedLeaveName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [requestBtnLoader, setRequestBtnLoader] = useState(false)
    const [loaded, setLoaded] = useState(false);
    const [isAvailed,setIsAvailed] = useState(true)
    const [isBalance,setIsBalance] = useState(true)



    const history = useHistory();
    const handleCancel = () => {
        setIsEditTeacherModalVisible(false);
        if (userIds?.length > 1) {
            let filtredData = allData.filter((item) => userIds.includes(item.id));
            let tbal = filtredData[0]?.leaves[selectedLeave]?.balance;
            let tavail = filtredData[0]?.leaves[selectedLeave]?.availed;
            let tbalSame = true;
            let tavailSame = true;
            for (let k = 0; k < filtredData?.length; k++) {
                if (filtredData[k]?.leaves[selectedLeave]?.balance != tbal) {
                    tbalSame = false;
                }
                if (filtredData[k]?.leaves[selectedLeave]?.availed != tavail) {
                    tavailSame = false;
                }
                if (!tbalSame && !tavailSame) {
                    break;
                }
            }

            console.log("CCC", tbalSame);
            console.log("CCC", tavailSame);
            setBalance(tbalSame ? tbal : null);
            setAvailed(tavailSame ? tavail : null);
            // setBalance(null);
            // setAvailed(null);
        } else {
            setBalance(item[selectedLeave]?.balance);
            setAvailed(item[selectedLeave]?.availed);
            setTotal(item[selectedLeave]?.total);
        }
    };

    const handleCreate = (e) => {

        setIsEditTeacherModalVisible(false);


    };
    console.log("item", item)
    useEffect(() => {
        console.log("triggered1")
        if (item) {
            setSelectedLeave(0);
            setSelectedLeaveName(item[0]?.name)
            setStartDate(moment(item[0]?.startDate));
            setEndDate(moment(item[0]?.endDate));
            if (balance == '' && availed == '') {
                let filtredData = allData?.filter((item) => userIds.includes(item.id));
                let tbal = filtredData[0]?.leaves[0]?.balance;
                let tavail = filtredData[0]?.leaves[0]?.availed;
                let tbalSame = true;
                let tavailSame = true;
                for (let k = 0; k < filtredData?.length; k++) {
                    if (filtredData[k]?.leaves[0]?.balance != tbal) {
                        tbalSame = false;
                    }
                    if (filtredData[k]?.leaves[0]?.availed != tavail) {
                        tavailSame = false;
                    }
                }
                setBalance(tbalSame ? tbal : null);
                setAvailed(tavailSame ? tavail : null);
            }
            setLoaded(true);

        }
    }, [JSON.stringify(item), JSON.stringify(userIds)])

    useEffect(() => {
        console.log("triggered2")
        if (loaded) {
            setSelectedLeaveName(item[selectedLeave]?.name)
            setStartDate(moment(item[selectedLeave]?.startDate));
            setEndDate(moment(item[selectedLeave]?.endDate));
            if (userIds?.length > 1) {
                let filtredData = allData.filter((item) => userIds.includes(item.id));
                let tbal = filtredData[0]?.leaves[selectedLeave]?.balance;
                let tavail = filtredData[0]?.leaves[selectedLeave]?.availed;
                let tbalSame = true;
                let tavailSame = true;
                for (let k = 0; k < filtredData?.length; k++) {
                    if (filtredData[k]?.leaves[selectedLeave]?.balance != tbal) {
                        tbalSame = false;
                    }
                    if (filtredData[k]?.leaves[selectedLeave]?.availed != tavail) {
                        tavailSame = false;
                    }
                    if (!tbalSame && !tavailSame) {
                        break;
                    }
                }

                console.log("CCC", tbalSame);
                console.log("CCC", tavailSame);
                setBalance(tbalSame ? tbal : null);
                setAvailed(tavailSame ? tavail : null);
                // setBalance(null);
                // setAvailed(null);
            } else {
                setBalance(item[selectedLeave]?.balance);
                setAvailed(item[selectedLeave]?.availed);
                setTotal(item[selectedLeave]?.total);
            }
        }
    }, [selectedLeave, JSON.stringify(item), JSON.stringify(userIds),])

    useEffect(() => {
        console.log("triggered3")
        let tmpbalance = balance?.length == 0 || balance == null ? 0 : isNaN(balance) ? 0 : balance;
        let tmpavailed = availed?.length == 0 || availed == null ? 0 : isNaN(availed) ? 0 : availed;
        setTotal(parseFloat(tmpbalance) + parseFloat(tmpavailed));
    }, [balance, availed, JSON.stringify(item), JSON.stringify(userIds),])

    return (
        <>
            <div>
                <Modal
                    centered
                    className="modal-round-corner"
                    visible={isEditTeacherModalVisible}
                    onCancel={handleCancel}
                    onOk={handleCreate}
                    footer={null}
                >
                    <div
                        style={{
                            marginTop: "30px",
                            fontSize: "24px",
                            color: "#191919",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit Leave
                        {/* {getLeaves} */}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "10px",
                            flexDirection: "column",
                        }}
                    >
                        <div style={{ marginTop: "20px" }}>
                            <h6
                                style={{
                                    fontSize: "14px",
                                    color: "#636363",
                                    fontWeight: "bold",
                                }}
                            >
                                Leave name
                            </h6>
                            <Select
                                placeholder="Select leave type"
                                style={{ width: "100%" }}
                                value={selectedLeave}
                                onChange={(e) => {
                                    setSelectedLeave(e)
                                }}
                            >
                                {item?.map((leave, index) => <Option value={index}>{leave?.name}</Option>)}
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ marginTop: "20px" }}>
                            <h6
                                style={{
                                    fontSize: "14px",
                                    color: "#636363",
                                    fontWeight: "bold",
                                }}
                            >
                                Start Date
                            </h6>
                            <Space>
                                <DatePicker
                                    format={'DD-MM-YYYY'}
                                    allowClear={false}
                                    open={false}
                                    disabled={true}
                                    style={{
                                        fontSize: "16px",
                                        color: "#AEAEAE",
                                        width: "220px",
                                    }}
                                    placeholder="Select start date"
                                    value={startDate}
                                />
                            </Space>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <h6
                                style={{
                                    fontSize: "14px",
                                    color: "#636363",
                                    fontWeight: "bold",
                                }}
                            >
                                End Date
                            </h6>
                            <Space>
                                <DatePicker
                                    format={'DD-MM-YYYY'}
                                    allowClear={false}
                                    open={false}
                                    disabled={true}
                                    style={{
                                        fontSize: "16px",
                                        color: "#AEAEAE",
                                        width: "220px",
                                    }}
                                    value={endDate}
                                    placeholder="Select end date"
                                />
                            </Space>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "25px" }}>
                        <div style={{ fontSize: "13px", color: "#191919", }}>Balance</div>
                        <div><Input value={balance} onChange={(e) => {
                            if (isNaN) {
                                setBalance(e.target.value)

                            } else {
                                setBalance(parseFloat(e.target.value))
                            }
                        }} /></div>
                    </div>
                    {userIds?.length == 1 && (
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "25px" }}>
                            <div style={{ fontSize: "13px", color: "#191919", }}>Availed</div>
                            <div><Input type={Number} value={availed} onChange={(e) => {
                                if (isNaN) {
                                    setAvailed(e.target.value)
                                } else {
                                    setAvailed(parseFloat(e.target.value))
                                }
                            }} /></div>
                        </div>
                    )}

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "25px" }}>
                        <div style={{ fontSize: "13px", color: "#191919", }}>Total</div>
                        <div><Input type={Number} readOnly={true} value={total} /></div>

                    </div>


                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: "50px",
                        }}
                    >
                        <div>
                            <Button
                                style={{
                                    borderRadius: "19px",
                                    width: "150px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #636363 ",
                                    color: "#636363",
                                }}
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                        </div>

                        <div>
                            {console.log("value",isAvailed,isBalance)}
                            <Button
                                disabled={availed === '' ||  balance === '' ? true : false }
                                loading={requestBtnLoader}
                                style={{
                                    borderRadius: "19px",
                                    width: "150px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",
                                }}
                                onClick={() => {
                                    setRequestBtnLoader(true);
                                    editLeaveBalance(userIds, balance, availed, selectedLeaveName,
                                        () => { setRequestBtnLoader(false) },
                                        () => {
                                            setIsEditTeacherModalVisible(false);
                                            setPageLoader(true)
                                            getLeaveOverViewData(() => { setPageLoader(false) })
                                        })

                                }}
                            // createLeaves={createLeaves}
                            >
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    const { } = state.attendanceNew;
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    createLeaves: () => dispatch(createLeaves()),
    storeCreateLeaveModalData: (key, val) => dispatch(storeCreateLeaveModalData(key, val)),
    editLeaveBalance: (userIds, balance, availed, selectedLeaveName, callBack, successCallBack) => dispatch(editLeaveBalance(userIds, balance, availed, selectedLeaveName, callBack, successCallBack)),
    getLeaveOverViewData: (val) => dispatch(getLeaveOverViewData(val)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeacherModal);

