import React from "react";
import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import 'antd/lib/typography/style/index';
import 'antd/lib/tooltip/style/index';
import "design/layout.css";
import { IoIosHand } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import "./styles.css";
import { VideoOffIcon, VideoIcon, AudioIcon, AudioOffIcon } from "icons";

const { Text } = Typography;

const UiButtons = props => (
  <div
    onClick={() => props.onClick()}
    className="controlButtonBox"
    style={{ backgroundColor: props.disable ? "#FF336B" : "white" }}
  >
    <Tooltip title={props.title}>{props.children}</Tooltip>
  </div>
);

const VideoControlBox = ({
  inQ,
  audioClick,
  isVideo,
  isAudio,
  isMyHandRaise,
  onPressAudio,
  outPressAudio,
  onPressHand,
  onPressVideo,
  onPressEnd,
  activeSpeaker,
  activeClass,
  onCancelHandRaise
}) => {
  const btnDown = event => {
    onPressAudio();
  };
  const btnUp = event => {
    outPressAudio();
  };
  const cancelHandRaise = () => {
    onCancelHandRaise();
  };
  return (
    <div className="vidControllerBox">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center"
        }}
      >
        <Text className="text-md white">{activeClass.teacher.name}</Text>
        <Text className="text-xmd white">{activeClass.subject.name}</Text>
      </div>
      <div className="r-c-c" style={{ flex: 1 }}>
        <UiButtons title="End Call" onClick={() => onPressEnd()}>
          <MdPhone
            style={{
              transform: "rotate(135deg)",
              fontSize: 25,
              color: "#F64A3F"
            }}
          />
        </UiButtons>
        {isMyHandRaise ? (
          <UiButtons
            onClick={() => onPressVideo()}
            title={isVideo ? "camera off" : "camera on"}
          >
            {isVideo ? (
              <VideoIcon style={{ color: "#5843be" }} />
            ) : (
              <VideoOffIcon style={{ color: "#5843be" }} />
            )}
          </UiButtons>
        ) : null}
        {isMyHandRaise ? (
          <div className="relative r-c-c controlButtonBox">
            <Tooltip title={isAudio ? "mic off" : "mic on"}>
              {isAudio ? (
                <div onClick={outPressAudio}>
                  <AudioIcon style={{ color: "#5843be" }} />
                </div>
              ) : (
                <div onClick={onPressAudio}>
                  <AudioOffIcon style={{ color: "#5843be" }} />
                </div>
              )}
            </Tooltip>
          </div>
        ) : (
          <div className="relative r-c-c">
            <div
              className="controlButtonBox"
              onMouseDown={btnDown}
              onMouseUp={btnUp}
            >
              <Tooltip title="Push to Talk">
                {isAudio ? (
                  <AudioIcon style={{ color: "#5843be" }} />
                ) : (
                  <AudioOffIcon style={{ color: "#5843be" }} />
                )}
              </Tooltip>
            </div>
            {isAudio && !isMyHandRaise ? (
              <div className="backred absolute radius-100 Blink" />
            ) : null}
          </div>
        )}
        {isMyHandRaise ? null : (
          <UiButtons
            disable={inQ}
            onClick={() => {
              if (!inQ) {
                onPressHand();
              } else {
                cancelHandRaise();
              }
            }}
            title={inQ ? "Cancel Raise Hand" : "Raise Hand"}
          >
            <IoIosHand
              style={{ color: inQ ? "white" : "#5843be", fontSize: 24 }}
            />
          </UiButtons>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center"
        }}
      >
        <Text className="text-xs white">
          {activeSpeaker.userName
            ? activeSpeaker.userName === "Me"
              ? "You are speaking"
              : `${activeSpeaker.userName} is speaking`
            : ""}
        </Text>
      </div>
    </div>
  );
};

export default VideoControlBox;
