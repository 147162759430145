import React from 'react';
import {connect} from 'react-redux';
import Descriptions from 'antd/lib/descriptions';
import Tabs from 'antd/lib/tabs';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Avatar from 'antd/lib/avatar';
import Popover from 'antd/lib/popover';
import Progress from 'antd/lib/progress';
import Typography from 'antd/lib/typography';
import Empty from 'antd/lib/empty';
import Button from 'antd/lib/button';
import 'antd/lib/descriptions/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/popover/style/index';
import 'antd/lib/progress/style/index';
import 'antd/lib/typography/style/index';
import 'antd/lib/empty/style/index';
import 'antd/lib/button/style/index';
import { PhoneOutlined } from '@ant-design/icons';
import { Color } from '../../../services';
import '../styles.css';
import Styles from './styles'
import { showChapterScreen } from '../../../actions/attendance-old'

const { Title,Text } = Typography;
const { TabPane } = Tabs;


class TeacherSubjectView extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      selectedStudentTab: "1",
      selectedScheduleIndex: -1,
      present: [],
      absent: [],
      visible: -1
    }
  }
  handleScheduleSelected(index){
    this.setState({
      selectedScheduleIndex: index,
      present: this.props.singleClassSubjectDetail.subjectAttendance[index].present,
      absent: this.props.singleClassSubjectDetail.subjectAttendance[index].absent,
    })

  }

  handleVisibleChange(index){
    const visible = this.state.visible;
    if(visible !== -1){
      this.setState({ visible: -1 });
    }else{
      this.setState({ visible: index });
    }

  };

  selectStudentTab(tab){
    this.setState({selectedStudentTab: tab})
  }

  render() {
    const { selectedSubject, singleClassSubjectDetail, teacherName } = this.props;
    return (
        <Row style={{height:'100%'}}>
            <Col  style={{height:'90%'}} xs={{span:18}} sm={{span:18}} md={{span:18}} lg={{span:18}} xl={{span:18}} xxl={{span:19}} >
            <Title style={{fontSize: 16}}>{selectedSubject ? selectedSubject.name : ''}</Title>

          <Descriptions size="small" column={3}>
            <Descriptions.Item label="Teacher">{teacherName}</Descriptions.Item>
            <Descriptions.Item label="Total Classes">{singleClassSubjectDetail && singleClassSubjectDetail.subjectAttendance ? singleClassSubjectDetail.subjectAttendance.length : 0}</Descriptions.Item>
            <Descriptions.Item label="Duration">{singleClassSubjectDetail ? singleClassSubjectDetail.totalDuration : 0}mins</Descriptions.Item>
          </Descriptions>
        <div style={{height:'90%', overflowY: 'auto'}}>
        {singleClassSubjectDetail && singleClassSubjectDetail.subjectAttendance ? singleClassSubjectDetail.subjectAttendance.map((schedule, index) => (
          <Row key={index} onClick={() => this.handleScheduleSelected(index)} style={{...Styles.singleScheduleBox, backgroundColor: this.state.selectedScheduleIndex === index ? Color.lightGrey : 'transparent'}}>
            <Col xs={{span:1}} sm={{span:1}} md={{span:1}} lg={{span:1}} style={{marginLeft: 5, marginRight: 8, verticalAlign: 'middle', textAlign: 'center'}} >
              <div style={{fontSize: 16}}>{schedule.month}</div>
              <div style={{fontSize: 14}}>{schedule.date}</div>
            </Col>
            <Col  xs={{span: 22}} sm={{span: 22}} md={{span: 22}} lg={{span: 22}} style={{verticalAlign: 'middle'}} >
              <Row className='r-c-sb m-b-20 cursor-pointer'>
                <Col  xs={{span: 18}} sm={{span: 18}} md={{span: 18}} lg={{span: 18}}
                style={{fontSize: 'medium', width:200, overflow:'hidden',textOverflow:'ellipsis',
                whiteSpace:'nowrap', paddingBottom: 0}}>{schedule.time} • {schedule.duration}mins</Col>
                <Col  xs={{span: 6}} sm={{span: 6}} md={{span: 6}} lg={{span: 6}}
                style={{fontSize: 'large', paddingBottom: 0, color: Color.gray, textAlign:'right'}}>{schedule.present.length}/{schedule.strength}</Col>

              </Row>
              <Row >
                <Progress percent={schedule.present.length/schedule.strength*100} style={{marginTop: -20}} showInfo={false} strokeColor="#61BB45" trailColor="#FF7D5C"/>
              </Row>
            </Col>
          </Row>
        )) : <Empty description="No schedules to display"/>}
        </div>
          </Col>
          <Col style={{height:'75%'}} xs={{span:6}} sm={{span:6}} md={{span:6}} lg={{span:6}} xl={{span:6}} xxl={{span:5}} >
          <div style={{height:'75%', marginTop:30, backgroundColor: '#f7f5f5', borderRadius: 10 }}>
          {this.state.selectedScheduleIndex !== -1 ?
            <Tabs className='presentTab' onChange={tab => this.selectStudentTab(tab)} defaultActiveKey="1" type="line">
              <TabPane style={{maxHeight:'400px', overflowY: 'auto'}} tab="Present" key="1">
              {this.state.present.length>0 ?
                this.state.present.map((student, index) => (
                  <div key={index} style={{display: 'flex', padding: 10}}>
                    <div style={{flex: 4}}>
                      <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                          <Avatar src={student.image} size={24} style={{verticalAlign: 'middle', fontSize: 'small', marginRight: 10}} />
                        </div>
                        <div style={{flex: 9, width: '100%'}}>
                          <div style={{display: 'flex'}}>
                            <div style={{maxWidth:'80%', whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis'}}>{student.name}</div>
                            <Popover
                              content={<> <PhoneOutlined rotate={90} style={{color: Color.darkGrey}}/> {student.phone} </>}
                              title="Contact"
                              trigger="click"
                              visible={this.state.visible === index && this.state.selectedStudentTab === "1"}
                              onVisibleChange={() => this.handleVisibleChange(index)}
                            >
                              <Button size="small" icon={<PhoneOutlined style={{color: Color.darkGrey}}/>} style={{backgroundColor: 'transparent', border:"0px"}}/>
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{flex: 1}}>
                      <Text style={{color:Color.primary, fontSize:12, textAlign: 'right'}}>{student.duration}mins</Text>
                    </div>
                  </div>
                ))
                 : <Empty description="No students"/> }

              </TabPane>
              <TabPane style={{height:'400px', overflowY: 'auto'}} tab="Absent" key="2">
              {this.state.absent.length>0 ?
                this.state.absent.map((student, index) => (
                  <div key={index} style={{padding: 10}}>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: 1}}>
                        <Avatar src={student.image} size={24} style={{verticalAlign: 'middle', fontSize: 'small', marginRight: 10}} />
                      </div>
                      <div style={{flex: 8, width: '100%'}}>
                        <div style={{display: 'flex'}}>
                          <div style={{maxWidth:'80%', whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis'}}>{student.name}</div>
                          <Popover
                            content={<> <PhoneOutlined rotate={90} style={{color: Color.darkGrey}}/> {student.phone} </>}
                            title="Contact"
                            trigger="click"
                            visible={this.state.visible === index && this.state.selectedStudentTab === "2"}
                            onVisibleChange={() => this.handleVisibleChange(index)}
                          >
                            <Button size="small" icon={<PhoneOutlined style={{color: Color.darkGrey}}/>} style={{backgroundColor: 'transparent', border:"0px"}}/>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                 : <Empty description="No students"/> }
              </TabPane>
            </Tabs>
           : <Empty description="Select a schedule to view students' list" />}


          {/*<Empty
          style={{border: '1px solid lightgrey', borderRadius: 10, verticalAlign: 'middle'}}
          description="Select a date to view students' list"
          />*/}
          </div>
          </Col>
        </Row>
      )
  }
}

const mapStateToProps = state => {
  const { singleClassSubjectDetail, selectedSubject } = state.attendance
  return { singleClassSubjectDetail, selectedSubject }
};

const mapDispatchToProps = dispatch => ({
  showChapterScreen: (bool, obj) => dispatch(showChapterScreen(bool, obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSubjectView);
