import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Select, Input, Tooltip, Button } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getChapterWiseTest, UpdateStudentTestData } from "actions/studentTest";
import PageDataLoader from "components/pageDataLoader";
import { NodataCard } from "pages/Test";
import { fetchStudentStandards } from "actions/schedule";
const bookGreen = require("../../Assets/test/bookGreen.svg").default;
const bookRed = require("../../Assets/test/bookRed.svg").default;
const bookBlue = require("../../Assets/test/bookBlue.svg").default;
const bookPurple = require("../../Assets/test/bookPurple.svg").default;
const chapterTest = require("../../Assets/test/chapterTest.svg").default;
const fullTest = require("../../Assets/test/fullTest.svg").default;
const MathTest = require("../../Assets/test/sbj-mathematics.svg").default;
const GeoTest = require("../../Assets/test/sbj-geography.svg").default;
const ChemTest = require("../../Assets/test/sbj-chemistry.svg").default;
const MockTest = require("../../Assets/test/question-bank.svg").default;
const ICChapterTest = require("../../Assets/test/ic-chapter-test.svg").default;
let timer = false

const SearchBox = ({ loading, UpdateStudentTestData }) => {
  return (
    <Input
      placeholder="Search by chapter name"
      className="m-l-20"
      allowClear={true}
      prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
      style={{ backgroundColor: "#FFF", borderRadius: 5, width: 250 }}
      onChange={(e) => {
        UpdateStudentTestData('search', e.target.value)
      }}
      bordered={true}
    />
  );
};

const DropdownCheckbox = ({ selectedStandards, setSelectedStandards, studentStandardFilters, getChapterWiseTest }) => {
  const handleStandardChange = (value) => {
    getChapterWiseTest(value, () => { })
    setSelectedStandards(value);
  };

  return (
    <div style={{ width: '100%', marginLeft: 20 }}>
      <Select
        style={{ width: '100%' }}
        placeholder="Select standards"
        onChange={handleStandardChange}
        value={selectedStandards}
      >
        {studentStandardFilters?.studentStandards?.map((standard) => (
          <Option key={standard.standardId} value={standard.standardId}>
            {`${standard.std} - ${standard?.section}`}
          </Option>
        ))}
      </Select>
    </div>
  );
};


const PageHeader = ({ title, back, UpdateStudentTestData, studentStandardFilters, getChapterWiseTest, setSelectedStandards, selectedStandards }) => {
  return (
    <div className="resultPageHeader full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined onClick={back} style={{ fontSize: 18 }} />
        <div className="text-md bold-700 m-l-20"> {title} </div>
      </div>
      <div className="testfiltersgroup" style={{ paddingRight: "10px", width: '40%', display: 'flex', justifyContent: 'space-between' }}>
        <SearchBox
          UpdateStudentTestData={UpdateStudentTestData}
          loading={false} />
        <DropdownCheckbox
          selectedStandards={selectedStandards}
          setSelectedStandards={setSelectedStandards}
          studentStandardFilters={studentStandardFilters}
          getChapterWiseTest={getChapterWiseTest}
        />
      </div>
    </div>
  );
};

const InsideChapterTestMod = ({ getChapterWiseTest, studentTestData, UpdateStudentTestData, studentStandardFilters, fetchStudentStandards }) => {

  const initialSelectedStandard = studentStandardFilters?.studentStandards?.length > 0 ? studentStandardFilters?.studentStandards[0]?.standardId : null;

  const [pageLoader, setPageLoader] = useState(false)
  const [selectedStandards, setSelectedStandards] = useState(initialSelectedStandard);


  useEffect(() => {
    UpdateStudentTestData('search', '')
    setPageLoader(true)
    getChapterWiseTest(null, () => { setPageLoader(false) })
    fetchStudentStandards();
  }, []);


  useEffect(() => {

    clearTimeout(timer);
    timer = setTimeout(() => {
      setPageLoader(true)
      getChapterWiseTest(null, () => { setPageLoader(false) });
    }, 1000);
  }
    , [studentTestData?.search]);

  const IconAndVal = ({ val, iconUrl }) => {
    return (
      <div>
        <img
          style={{
            width: "18px",
            height: "18px",
          }}
          src={iconUrl}
          alt=""
        />
        <span style={{ marginLeft: "5px" }}>{val}</span>
      </div>
    );
  };


  const history = useHistory();
  const PerSubChapterCard = ({ chapnumber, content, item, UpdateStudentTestData }) => {
    const navigateToChapter = () => {
      history.push("student-test/subject");
      UpdateStudentTestData('selectedChapter', item)
    }

    return (
      <>
        <div
          className="cursor-pointer shadow-box hover-shadow customCardShadow "
          style={{
            height: "125px",
            width: "300px",
            borderRadius: "10px",
            border: "1px solid #E6E6E6",
            margin: "10px 10px 10px 0",
            padding: "15px",
          }}
          onClick={navigateToChapter}
        >
          <div className="display-flex" style={{ fontSize: "15px" }}>
            <div
              style={{
                color: "#636363",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Chapter {chapnumber}
            </div>
          </div>
          <div
            className="bold-600 "
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "50px",
              whiteSpace: "nowrap",
              fontSize: "14px",
              paddingTop: "10px",
            }}
          >
            {content
              ? `${content}`
              : `lorem ipsumada sdasdadj asdsagd jhsgdj hgsdh sagasdsjkadas sagdsagd sadsad sadsadsa dasdsadsa dsadsad sadsad sadsada dsadsadsa dsa dsa d sad sad sa dsa d`}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0px",
            }}
          >

            <div style={{ width: "50%", alignSelf: "start" }}>
              <Tooltip placement="right" trigger="hover" title={<span>Subjective test(s)</span>} overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "20px", width: 350 }}>
                <IconAndVal iconUrl={bookGreen} val={item?.objectiveCount} />
              </Tooltip>
            </div>
            <div style={{ width: "50%", alignSelf: "start" }}>
              <Tooltip placement="right" trigger="hover" title={<span>Objective test(s)</span>} overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "20px", width: 350 }}>
                <IconAndVal iconUrl={bookBlue} val={item?.subjectiveCount} />
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageHeader
        UpdateStudentTestData={UpdateStudentTestData}
        title={studentTestData?.selectedChapterTestCard?.subjectName}
        back={() => history.goBack()}
        getChapterWiseTest={getChapterWiseTest}
        studentStandardFilters={studentStandardFilters}
        setSelectedStandards={setSelectedStandards}
        selectedStandards={selectedStandards}
      />
      <div style={{ display: "flex", margin: "20px 0px", flexWrap: "wrap", }}>
        {studentTestData?.chapterWiseTestData?.map((item, index) => (
          <PerSubChapterCard
            UpdateStudentTestData={UpdateStudentTestData}
            chapnumber={index + 1}
            content={item.chapterName}
            item={item}
          />
        ))}
        {
          studentTestData?.chapterWiseTestData?.length === 0 ?
            <div style={{ width: "100vw", height: "80vh" }}>
              <NodataCard />
            </div>
            : null
        }
      </div>
      <PageDataLoader visible={pageLoader} />
    </>
  );
};

// export default InsideChapterTestMod;
const mapStateToProps = (state) => {
  const { studentTestData } = state.studentTest;
  const { user } = state.session;
  const { studentStandardFilters } = state.schedule;
  return {
    studentTestData,
    user,
    studentStandardFilters
  };
};

const mapDispatchToProps = (dispatch) => ({
  getChapterWiseTest: (standardId, callback) => dispatch(getChapterWiseTest(standardId, callback)),
  UpdateStudentTestData: (key, val) => dispatch(UpdateStudentTestData(key, val)),
  fetchStudentStandards: () => dispatch(fetchStudentStandards())

});

export default connect(mapStateToProps, mapDispatchToProps)(InsideChapterTestMod);