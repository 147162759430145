import React, { useEffect, useState } from 'react'
import img1 from "../../Assets/aulasMarket/related1.svg"
import { AutoComplete, Modal } from 'antd';
import { Button } from 'components/Button';
import { getThumnailsData } from 'actions/courseManagement';
import { connect } from 'react-redux';
import { Api } from 'services';
import { NodataCard } from 'pages/Test';
import { NoDataFound } from 'components';

function AddThumbnail({ getThumnailsData, storeThumbnails, setCreateCourseObj, defualThumbnail, setDefaultThumbnail }) {
    const [openViewAll, setViewAll] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [search, setSearch] = useState('')

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const isAddButtonDisabled = selectedImageIndex === null;
    const handleOnAdd = () => {
        setViewAll(false);
        setSearch('')
    }
    useEffect(() => {
        getThumnailsData(search, () => { })
    }, [search])


    return (
        <div>
            <div className='r-c-sb m-t-10' style={{ color: "#636363", fontSize: 12, width: "100%" }}>
                <div style={{ width: "60%" }}>Don't have a thumbnail? Don't worry! Feel free to select a suggested thumbnail below...</div>
                <div className='' onClick={() => { setViewAll(true); getThumnailsData('', () => { }) }} style={{ cursor: "pointer", color: "#1089FF", fontWeight: "bold" }}>VIEW ALL/SEARCH</div>
            </div>
            <div className='scroll-bar-universal' style={{ display: "flex", width: "100%", height: "100px", marginTop: 5, gap: 10, overflowY: "hidden" }}>
                {storeThumbnails?.slice(0, 10)?.map((item, index) => (
                    <div key={index} className='r-c-c' style={{ width: "100%", height: "100px", marginBottom: 10, opacity: selectedImageIndex !== null && selectedImageIndex !== index ? 0.4 : 1 }}>
                        <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: "130px", cursor: "pointer", borderRadius: 10 }} onClick={() => { handleImageClick(index); setCreateCourseObj('image', item?.image), setDefaultThumbnail(item?.image) }} />
                    </div>
                ))}
            </div>
            <Modal
                open={openViewAll}
                onCancel={() => { setViewAll(false); setSelectedImageIndex(null); setSearch('') }}
                onOk={handleOnAdd}
                footer={null}
                bodyStyle={{ marginTop: "-40px" }}
                width={800}
                className="modal-round-corner">
                <div style={{ padding: "10px 40px" }}>
                    <center style={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Roboto" }}>Select course thumbnail</center>
                    <div style={{ color: "#636363", fontWeight: 600 }}>Search thumbnail</div>
                    <div className='m-b-10' style={{ width: "100%", marginTop: 5 }}><AutoComplete allowClear placeholder={"Search thumbnail"} value={search} style={{ width: "100%" }} showSearch={true} onChange={(value) => { setSearch(value) }} /></div>
                    {storeThumbnails?.length != 0 ? (<>
                        <div className='scroll-bar-universal' style={{ height: 400 }}>
                            <div style={{ display: 'grid', gridTemplateColumns: `repeat(4, 1fr)` }}>
                                {storeThumbnails?.map((item, index) => (
                                    <div key={index} className='r-c-c' style={{ width: "100%", height: "100px", marginBottom: 10, opacity: selectedImageIndex !== null && selectedImageIndex !== index ? 0.4 : 1 }}>
                                        <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: "130px", cursor: "pointer", borderRadius: 10 }} onClick={() => { handleImageClick(index), setDefaultThumbnail(item?.image) }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>) : (<div style={{ marginTop: "20px" }}><NodataCard /></div>)}
                    <div className='r-c-c m-t-15' >
                        <Button disabled={isAddButtonDisabled} type="primary" onClick={() => { setCreateCourseObj('image', defualThumbnail) }}>Add</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { storeThumbnails } = state.courseManagement
    const { user } = state.session
    return { storeThumbnails }
};

const mapDispatchToProps = (dispatch) => ({
    getThumnailsData: (search, callback) => dispatch(getThumnailsData(search, callback)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AddThumbnail)