import { DownloadOutlined } from '@ant-design/icons'
import { Avatar, Col, DatePicker, Row, Typography, Select, Checkbox } from 'antd'
import { Button } from 'components/Button'
import { Heading, Title } from 'components/Typography'
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { IMG_URL } from "../../env.json";
import { InputSearch } from 'components/customInputs'
import DemoColumn from './antGraph'
import { connect } from 'react-redux'
import { fetchCourseDetails, getFeeOverView, recentActivitiesVAFromWhere, setAllActivitiesFilter, setOverviewFilter, setWhichViewAllClicked, fetchDownloadReport, fetchGraphData, setFeeDetails } from 'actions/feeManagement'
import PageDataLoader from 'components/pageDataLoader'
import moment from 'moment'
import { setOthersSelectedTab } from 'actions/profileNew'
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2'
import SelectRangeModal from './selectRangeModal'
import { setUserDetailsById } from 'actions/userManagement'
import GeneratePayRequest from './generatePayRequest'
import OverViewGraph from './overViewGraph'
import CourseFmGraph from './courseFmGraph'
import AddDetailsModal from './addDetailsModal'

const { RangePicker } = DatePicker;
const durationType = [{ label: 'Week', value: 'week' }, { label: 'Months', value: 'month' }];

const paymentDetails = (item) => [
    { paymentCount: item?.pendingTransactionsDetails?.count, paymentStatus: 'pending', amount: item?.pendingTransactionsDetails?.amount, paymentToday: item?.pendingTransactionsDetails?.requestedToday, color: '#FFA931' },
    { paymentCount: item?.allTransactionsDetails?.count, paymentStatus: 'requested', amount: item?.allTransactionsDetails?.amount, paymentToday: item?.allTransactionsDetails?.requestedToday, color: '#594099' },
    { paymentCount: item?.overdueTransactionsDetails?.count, paymentStatus: 'overdue', amount: item?.overdueTransactionsDetails?.amount, paymentToday: item?.overdueTransactionsDetails?.requestedToday, color: '#FF414D' },
    { paymentCount: item?.cancelledTransactionsDetails?.count, paymentStatus: 'cancelled', amount: item?.cancelledTransactionsDetails?.amount, paymentToday: item?.cancelledTransactionsDetails?.requestedToday, color: '#636363' },
    { paymentCount: item?.refundedTransactionsDetails?.count, paymentStatus: 'refunded', amount: item?.refundedTransactionsDetails?.amount, paymentToday: item?.refundedTransactionsDetails?.requestedToday, color: '#1089FF' },
]

const CourseDetailsFM = ({ history, fetchCourseDetails, resourceData, viewAllIs, courseDetailsOfResources, standardData, recentActivitiesVAFromWhere, setAllActivitiesFilter, setUserDetails, setOthersSelectedTab, setWhichViewAllClicked, fetchGraphData, insideGraphData, selectedCourse, setUserDetailsById, setSpecificUserId, feeMangementData, setFeeDetails, fetchDownloadReport, overviewFilter }) => {

    let title = resourceData?.name || resourceData?.courseName;
    const bodyStyle = { backgroundColor: "#FAFAFA", width: " calc(100% + 30px)", height: "calc(100% + 40px)", margin: "-20px", padding: "20px", overflow: "auto" };

    let [sum, setSum] = useState(0);
    let [graphData, setGraphData] = useState([]);
    let [graphType, setGraphType] = useState('week');
    const [search, setSearch] = useState('');
    const [pageLoader, togglePageLoader] = useState(false);
    const [isDownloadReportModalOpen, toggleDownloadReportModal] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(7, 'days').format('YYYY-MM-DD'));
    const [year, setYear] = useState(moment().year());
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);
    const [showGeneratePayReq, setShowGeneratePayReq] = useState(false);
    const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
    const [selectedBtn, setSelectedBtn] = useState(1);
    const [isAdditionModal, setIsAdditionModal] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
    const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);

    // useEffect(() => {
    //     graphData = [];
    //     sum = 0;
    //     if (courseDetailsOfResources?.graph?.summaryGraph?.length != 0) {
    //         courseDetailsOfResources?.graph?.summaryGraph?.map(
    //             (item) => (
    //                 (sum = sum + parseFloat(item?.totalSumRequested)),
    //                 graphData.push({
    //                     type: "Payment Requested",
    //                     short_date: item?.short_date,
    //                     Payment_Requested: parseFloat(item?.amount),
    //                     totalCountequested: item?.count
    //                 })
    //             )
    //         );
    //     }

    //     if (insideGraphData?.summaryGraph?.length != 0) {
    //         insideGraphData?.summaryGraph?.map(
    //             (item) => (
    //                 (sum = sum + parseFloat(item?.totalSumRequested)),
    //                 graphData.push({
    //                     type: "Payment Requested",
    //                     short_date: item?.short_date,
    //                     Payment_Requested: parseFloat(item?.amount),
    //                     totalCountequested: item?.count
    //                 })
    //             )
    //         )
    //     }

    //     setSum(sum);
    //     setGraphData([...graphData]);
    // }, [courseDetailsOfResources?.graph?.summaryGraph, startDate, endDate, graphType, insideGraphData?.summaryGraph?.length]);

    useEffect(() => {

        setFeeDetails("selectedStudentId", []);

        let params = {
            resourceId: resourceData?.id,
            resourceType: viewAllIs,
            disablePagination: true,
            search: search,
            standardId: viewAllIs == 'course' ? standardData?.standard_id : undefined
        };

        togglePageLoader(true)
        fetchCourseDetails(params, () => togglePageLoader(false));
    }, [search])

    const successcallback = () => {
        let params = {
            resourceId: resourceData?.id,
            resourceType: viewAllIs,
            disablePagination: true,
            search: search,
            standardId: viewAllIs == 'course' ? standardData?.standard_id : undefined
        };

        togglePageLoader(true)
        fetchCourseDetails(params, () => { togglePageLoader(false); setFeeDetails("selectedStudentId", []); setFeeDetails("setStudentsList", []); setSelectedStudentIds([]) });
    }

    const actions = [
        <Button onClick={() => { toggleDownloadReportModal(true) }} type='secondary' style={{ border: '1px solid #1089FF', color: '#1089FF' }} icon={<DownloadOutlined style={{ color: '#1089FF', fontSize: 14 }} />}>DOWNLOAD REPORT</Button>
    ]

    const handleCheckboxChange = (e, name, id) => {
        // Check if the studentId is already in the selectedStudentIds array
        if (selectedStudentIds.includes(id)) {
            setSelectedStudentIds(selectedStudentIds.filter(selectedId => selectedId !== id));
        } else {
            setSelectedStudentIds([...selectedStudentIds, id]);
        }
        let temp = feeMangementData?.setStudentsList || [];
        if (e.target.checked) {
            let obj = {
                userId: id,
                name: name,
            };
            temp.push(obj);
        }

        if (!e.target.checked) {
            temp = temp.filter((item) => item.userId !== id);
        }
        setFeeDetails("setStudentsList", temp);

        let temp2 = temp.map((item) => item.userId);
        setFeeDetails("selectedStudentId", temp2);
    };

    const handleGenerateClick = () => {
        setShowGeneratePayReq(true)
    };

    const handleClearAllClick = () => {
        setSelectedStudentIds([]);
        setFeeDetails("setStudentsList", []);
    };

    const displayDateAndTime = (item) => {
        if (item.status == 'Paid' || item.status == 'Refunded') {
            if (item.payment_type == 'online') {
                return moment(item.paymentDate).format("DD/MM/YYYY • hh:mm A");
            } else {
                return moment(item.paymentDate).format("DD/MM/YYYY");
            }
        } else {
            return moment(item.date).format("DD/MM/YYYY • hh:mm A");
        }
    }

    const handleStudentsClick = (item) => {
        setSpecificUserId(item?.id);
        setUserDetailsById(item);
        setUserDetails(item);
        setOverviewFilter("userId", item?.id);
        // if (viewAllIs == 'course') {
        //     setOthersSelectedTab('Fee details');
        // } else (
        //     setOthersSelectedTab('Transactions')
        // )
         setOthersSelectedTab('Basic Profile');
            history.push({
                pathname: '/user-management/profile',
                state: {
                    key: true
                }
            })
    
    }

    return (
        <div style={bodyStyle}>
            <PageHeader title={title} onBackPress={() => { setWhichViewAllClicked(viewAllIs); history.goBack() }} actions={actions} />
            <Row className='r-jsb' style={{ width: '100%', marginTop: 50 }}>
                <div className='CardShadow' style={{ border: '1px solid #E6E6E6', backgroundColor: 'white', width: '64%', height: '60vh', borderRadius: 8, padding: 20 }}>
                    <Row className='r-jsb' style={{ width: '100%' }}>
                        <Col style={{ width: 'fit-content' }}>
                            <Heading>Overview</Heading>
                            {overviewFilter?.type == 'week' ?
                                <div className='bold-600'>{moment(overviewFilter?.startDate).format('DD/MM/YYYY')} - {moment(overviewFilter?.endDate).format('DD/MM/YYYY')}</div>
                                :
                                <div className='bold-600'>{overviewFilter?.year}</div>
                            }
                        </Col>
                    </Row>

                    <Row className='m-t-20 r-jsb' style={{ marginTop: -70 }}>
                        <div style={{ color: '#28DF99', display: 'flex', width: '22%', flexDirection: 'column', marginTop: '20%' }}>
                            <div className='display-flex'>
                                <div className='text-lg'>•</div>
                                <div className='text-xmd' style={{ marginTop: 12 }}><span className='bold-600'>{insideGraphData?.paidTransactionsDetails?.count}</span> payment collected</div>
                            </div>
                            <div className='text-md bold-700 color-black'>₹ {insideGraphData?.paidTransactionsDetails?.amount}</div>
                        </div>
                        <div style={{ height: '45vh', width: '35vw' }}>
                            <CourseFmGraph />
                        </div>
                    </Row>

                    {viewAllIs == 'course' &&
                        <div style={{ marginTop: -40, borderTop: '1px solid #AEAEAE', width: '100%' }}>
                            <div style={{ width: '100%', marginTop: 10, overflow: 'auto', display: 'flex', height: 115 }}>
                                {paymentDetails(insideGraphData)?.map((item, index) => (
                                    <div style={{ width: 300 }}>
                                        <Row key={index} style={{ width: 200, borderRight: '1px solid #ccc', height: '100%', marginRight: 10 }}>
                                            <Col>
                                                <Row style={{ fontSize: '1em', color: item?.color }}>
                                                    <div style={{ marginRight: '8px', fontSize: 20 }}>•</div>
                                                    <div style={{ fontWeight: 'bold', marginTop: 5, fontSize: 14 }}>Payment {item?.paymentStatus}</div>
                                                </Row>
                                                <Row style={{ marginTop: '5px', fontSize: '1.5em' }}>
                                                    <div className='bold-600'>₹ {item?.amount}</div>
                                                </Row>
                                                <Row className='lowgrey' style={{ marginTop: '5px', fontSize: '1em' }}>
                                                    <div>{item?.paymentToday}</div>
                                                    <div style={{ marginLeft: '8px' }}>Payment {item?.paymentStatus} today</div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>

                <div className='CardShadow' style={{ border: '1px solid #E6E6E6', backgroundColor: 'white', width: '35%', height: '60vh', borderRadius: 8, padding: 20 }}>
                    <Row className='r-jsb' style={{ width: '100%' }}>
                        <Heading>Recent Activities</Heading>
                        <Button onClick={() => {
                            recentActivitiesVAFromWhere('fromInsideLiveCourses');
                            setAllActivitiesFilter('resourceId', resourceData?.id);
                            setAllActivitiesFilter('resourceType', viewAllIs);
                            setAllActivitiesFilter('standardId', viewAllIs == 'course' ? [standardData?.standard_id] : undefined);
                            history.push('/fee-management/all-activities')
                        }} type='link' style={{ fontWeight: 600, fontSize: 14 }}>VIEW ALL</Button>
                    </Row>
                    {courseDetailsOfResources?.recentTransactions?.items?.length === 0 ? (
                        <center className='r-c-c-c' style={{ height: '100%' }}>
                            <img src={require('../../Assets/test/no-subjects.svg').default} style={{ width: 250 }} />
                            <div className='bold-500 m-t-10' style={{}}>No recent transactions.</div>
                        </center>
                    ) : (
                        courseDetailsOfResources?.recentTransactions?.items?.map((item) => (
                            <Col key={item?.transactionId} style={{ width: '100%', cursor: 'pointer', marginTop: 15 }}>
                                {viewAllIs !== 'course' ?
                                    <div className='r-jsb' style={{ width: '100%' }}>
                                        <div>
                                            <Avatar size={50} src={`${IMG_URL}/${item?.userImage}`} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '65%' }}>
                                            <div style={{ width: 180 }}>
                                                <Typography.Text style={{ width: '100%' }} ellipsis={{ tooltip: true }} className='color-black bold-600 text-xmd'>
                                                    {item?.username}
                                                </Typography.Text>
                                            </div>
                                            <div className='color-black bold-600 text-xmd'>
                                                {moment.utc(item?.paymentDate).local().format("DD/MM/YYYY • hh:mm A")}
                                            </div>
                                        </div>
                                        <div className='color-black bold-600 text-smd' style={{ width: 110, display: 'flex', justifyContent: 'flex-end' }}>₹ {item?.amountPayable}</div>
                                    </div>
                                    :
                                    <div className='r-jsb' style={{ width: '100%' }}>
                                        <div>
                                            <Avatar size={50} src={`${IMG_URL}/${item?.userImage}`} />
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '65%' }}>
                                            <div style={{ width: 180 }}>
                                                <Typography.Text style={{ width: '100%' }} ellipsis={{ tooltip: true }} className='color-black bold-600 text-xmd'>
                                                    {item?.username}
                                                </Typography.Text>
                                            </div>
                                            <div className='simgrey bold-600 text-xmd'>
                                                ₹ {item?.amountPayable}
                                            </div>
                                            <div className='lowgrey bold-500 text-xs'>
                                                {/* {moment.utc(item?.date).subtract(5.5, 'hours').local().format("DD/MM/YYYY • hh:mm A")} */}
                                                {displayDateAndTime(item)}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    border: `1px solid ${item?.status == "Overdue"
                                                        ? "#FF414D"
                                                        : item?.status == "Paid"
                                                            ? "#28DF99"
                                                            : item?.status == "Refunded"
                                                                ? "#1089FF"
                                                                : item?.status == "Cancelled"
                                                                    ? "#1089FF"
                                                                    : "#FFA931"
                                                        }`,
                                                    borderRadius: "7px",
                                                    textAlign: "center",
                                                    fontSize: "10px",
                                                    padding: "5px",
                                                    fontWeight: "700",
                                                    width: "100px",
                                                    color: `${item?.status == "Overdue"
                                                        ? "#FF414D"
                                                        : item?.status == "Paid"
                                                            ? "#28DF99"
                                                            : item?.status == "Refunded"
                                                                ? "#1089FF"
                                                                : item?.status == "Cancelled"
                                                                    ? "#1089FF"
                                                                    : "#FFA931"
                                                        }`,
                                                    backgroundColor: `${item?.status == "Overdue"
                                                        ? "#FFECED"
                                                        : item?.status == "Paid"
                                                            ? "#EAFCF5"
                                                            : item?.status == "Refunded"
                                                                ? "#E7F3FF"
                                                                : item?.status == "Cancelled"
                                                                    ? "#E7F3FF"
                                                                    : "#FFF6EA"
                                                        }`,
                                                }}
                                            >
                                                {item?.status}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Col>
                        ))
                    )}
                </div>
            </Row >
            <Col className='m-t-30'>
                <Row className='r-jsb' style={{ width: '100%' }}>
                    <Heading>All students</Heading>
                    <div className='r-jsb' style={{ width: selectedStudentIds?.length >= 1 ? '40%' : undefined }}>
                        {viewAllIs == 'course' && selectedStudentIds?.length >= 1 &&
                            <>
                                <Button onClick={handleClearAllClick} type='secondary' style={{ border: '1px solid #FF414D', color: '#FF414D', boxShadow: 'none' }}>CLEAR ALL</Button>
                                <Button onClick={handleGenerateClick} type='primary' style={{}}>GENERATE</Button>
                            </>
                        }
                        <InputSearch value={search} style={{ borderRadius: 32, padding: 5, justifyContent: 'flex-end' }} placeholder='Search' onEnter={(e) => setSearch(e)} allowClear />
                    </div>
                </Row>
                <div className='m-t-10 display-flex flex-wrap' style={{ gap: 10 }} >
                    {courseDetailsOfResources?.students?.length === 0 ? (
                        <center className='r-c-c-c full-width'>
                            <img src={require('../../Assets/test/no-subjects.svg').default} style={{ width: 250 }} />
                            <div className='bold-500 m-t-10' style={{ height: '100%' }}>Sorry, No student with that name!</div>
                        </center>
                    ) : (
                        courseDetailsOfResources?.students?.map((item) => (
                            <div onClick={() => { handleStudentsClick(item) }} key={item?.id} className='CardShadow' style={{ width: '17vw', height: viewAllIs == 'course' ? '13vh' : '12vh', border: '1px solid #E6E6E6', backgroundColor: 'white', cursor: 'pointer', borderRadius: 8 }}>
                                <Row className='p-20' style={{ position: 'relative' }}>
                                    {viewAllIs == 'course' &&
                                        <div style={{ position: 'absolute', top: 8, right: 10, }} onClick={(e) => { e.stopPropagation() }}><Checkbox checked={selectedStudentIds?.includes(item?.id)} onChange={(e) => { handleCheckboxChange(e, item?.name, item?.id) }} /></div>
                                    }
                                    <Avatar src={`${IMG_URL}/${item?.image}`} size={50} style={{ marginTop: 5 }} />
                                    <Col style={{ marginTop: -5, marginLeft: 5, width: '70%' }}>
                                        <div style={{ width: 130, marginTop: 5 }}>
                                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 15, width: '100%' }} className='color-black bold-600 m-t-5 m-l-5'>{item?.name}</Typography.Text>
                                        </div>
                                        {viewAllIs == 'course' ?
                                            <div className='m-l-5 text-xs'>
                                                <div className='simgrey bold-500'>Roll No. <span>{item?.rollNo}</span></div>
                                                <div className='simgrey bold-500'>{item?.transactionCount} Transactions</div>
                                            </div>
                                            :
                                            <div style={{ fontSize: 12 }} className='simgrey bold-600 m-l-5'>{item?.phone}</div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        ))
                    )}
                </div>
            </Col>
            {isDownloadReportModalOpen && <SelectRangeModal isModalVisible={isDownloadReportModalOpen} setShowSelectRangeModal={toggleDownloadReportModal} kycStatus={'Approved'} fromCourseDetails={true} />}
            {showGeneratePayReq && <GeneratePayRequest
                isModalVisible={showGeneratePayReq}
                setIsModalVisible={setShowGeneratePayReq}
                setShowAddDetailsModal={setShowAddDetailsModal}
                showStudents={true}
                setSelectedBtn={setSelectedBtn}
                setIsAdditionModal={setIsAdditionModal}
                selectedBtn={selectedBtn}
                showReasonModal={showReasonModal}
                setShowReasonModal={setShowReasonModal}
                successcallback={successcallback}
            />
            }
            {showAddDetailsModal && (
                <AddDetailsModal
                    isModalVisible={showAddDetailsModal}
                    setIsAddOrGenModal={setIsAddOrGenModal}
                    isAddOrGenModal={isAddOrGenModal}
                    isAdditionModal={isAdditionModal}
                    handleOk={() => {
                        setShowAddDetailsModal(false);
                        if (isAddOrGenModal == true) {
                            setShowOfflinePayReqModal(true);
                            setIsAddOrGenModal(false);
                        } else {
                            setShowGeneratePayReq(true);
                            setIsAddOrGenModal(false);
                        }
                    }}
                    handleCancel={() => {
                        setShowAddDetailsModal(false);
                        if (isAddOrGenModal == true) {
                            setShowOfflinePayReqModal(true);
                            setIsAddOrGenModal(false);
                        } else {
                            setShowGeneratePayReq(true);
                            setIsAddOrGenModal(false);
                        }
                    }}
                    setShowGeneratePayReq={setShowGeneratePayReq}
                />
            )}
            <PageDataLoader visible={pageLoader} />
        </div >
    )
}

const mapStateToProps = (state) => {
    const { selectedStd, feeMangementData, overviewFilter, viewAllIs, resourceData, courseDetailsOfResources, standardData, insideGraphData } = state.feeManagement;
    const { selectedCourse } = state.userManagementV2;
    return {
        selectedStd,
        feeMangementData,
        overviewFilter,
        viewAllIs,
        resourceData,
        courseDetailsOfResources,
        standardData,
        insideGraphData,
        selectedCourse
    };
};

const mapDispatchToProps = (dispatch) => ({
    setOverviewFilter: (key, res) => dispatch(setOverviewFilter(key, res)),
    getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
    fetchCourseDetails: (params, callback) => dispatch(fetchCourseDetails(params, callback)),
    recentActivitiesVAFromWhere: val => dispatch(recentActivitiesVAFromWhere(val)),
    setAllActivitiesFilter: (key, val) => dispatch(setAllActivitiesFilter(key, val)),
    setOthersSelectedTab: tab => dispatch(setOthersSelectedTab(tab)),
    setWhichViewAllClicked: val => dispatch(setWhichViewAllClicked(val)),
    setUserDetails: res => dispatch(setUserDetails(res)),
    fetchDownloadReport: (object, callback, successCallBack) => dispatch(fetchDownloadReport(object, callback, successCallBack)),
    fetchGraphData: (params, callback) => dispatch(fetchGraphData(params, callback)),
    setUserDetailsById: res => dispatch(setUserDetailsById(res)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsFM);