import React, { useState, useEffect } from "react";
import { Image, Menu, Dropdown, message, Modal, Typography } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { MdMoreVert } from "react-icons/md";

import {
  fetchTeacherData,
  fetchLibraryData,
  fetchAdminChapterFilter,
  deleteNotes,
  currentFileToShow,
  uploadContent,
  currentDocument,
  currentTypes,
  updateSetType,
  fetchTeacherLibraryData,
  postEngagementHistory,
  updateDocumentInfo,
  setKey,
  IsHSL,
} from "actions/digitalLibrary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import EditInformation from "../modals/editInformation";
import CloneInformation from "../modals/cloneInformation";
import DeleteConfirm from "../modals/deleteConfirm";
import ReactPlayer from "react-player";
import { Api } from "services";
import videoThumbImg from 'Assets/digitalLibrary/videoThumbnail.png'
import Axios from 'axios'
import fileDownload from 'js-file-download'

function LectureNotes({
  chapterName,
  title,
  file,
  id,
  filesSize,
  deleteNotes,
  types,
  img,
  currentFileToShow,
  uploadContent,
  currentDocument,
  currentTypes,
  updateSetType,
  chapterId,
  user,
  postEngagementHistory,
  index,
  adminLibraryList,
  updateDocumentInfo,
  setKey,
  fetchLibraryData,
  item,
  link,
  IsHSL
}) {
  console.log("item", item);
  const history = useHistory();
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const handleNotes = (id) => {
    updateDocumentInfo(item);
    // uploadContent({ filePath: file })

    if (types != "video") {
      currentFileToShow("pdf");
      uploadContent({ filePath: file });
    } else {
      IsHSL(true)
      if (item?.link) {
        currentFileToShow('link')
      } else {
        currentFileToShow("video");

      }
      uploadContent({ filePath: file ? file : link });
    }

    currentDocument(id);
    currentTypes(types);

    if (
      user.access.includes("digital-library-teacher") ||
      user.access.includes("digital-library-student")
    ) {
      let arr = [];
      arr[0] = id;
      postEngagementHistory({ libraryIds: arr, type: "engagement" });
    }
    history.push("/digital-library/view-content");
  };

  const handleDeletenotes = (e) => {
    e.domEvent.stopPropagation();
    setIsDeleteModal(true);
  };
  const [isVisibl, setIsModalVisibl] = useState(false);
  const [modal, setmodal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);

  const handleEdit = (e) => {
    e.domEvent.stopPropagation();
    setIsModalVisibl(true);
  };

  const handleClone = (e) => {
    e.domEvent.stopPropagation();
    setCloneModal(true);
  };

  const handleDownload = (file, name, type) => {
    console.log("name", name)
    Axios
        .get(`${Api.dlS3Url}${file}`, {
            responseType: "blob",
        })
        .then((res) => {
            if (res.data) {
                fileDownload(res.data, `${name}.${type == "video" ? "video" : "pdf"}`);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

  const menu = (
    <Menu
      style={{
        width: "100px",
        background: "#FFFFFF",
        boxShadow: "8px 8px 18px #302A5F14",
        borderRadius: "8px",
      }}
    >
      <Menu.Item key="1" onClick={(e) => handleEdit(e)} icon={<EditOutlined />}>
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => handleClone(e)}
        icon={<CopyOutlined />}
      >
        Clone
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<DeleteOutlined />}
        onClick={(e) => handleDeletenotes(e)}
      >
        Delete
      </Menu.Item>
      {(item?.status == 'Published' && item?.file && (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin'))) &&
      <Menu.Item
        key="4"
        icon={<DownloadOutlined />}
        onClick={(e) => {e.domEvent.stopPropagation();handleDownload(item?.file, item?.title, item?.type)}}
      >
        Download
      </Menu.Item>}
    </Menu>
  );
  return (
    <div>
      {types != "video" ? (
        <div
          className="lecturenotesmain-1 cursor-pointer"
          style={{ height: "150px",marginRight:"20px" }}
          onClick={() => handleNotes(id)}
        >
          <div className="lecturenotefirst">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ marginRight: "170px" }}
                className="image"
                src={img}
              />
              {user.access.includes("digital-library-admin") ||
                user.access.includes("digital-library-superadmin") ||
                user.access.includes("digital-library-dataoperator") ? (
                <div>
                  <Dropdown
                    overlay={menu}
                    disabled={false}
                    placement="bottomRight"
                  >
                    <MdMoreVert
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#AEAEAE",
                      }}
                      onClick={(e) => e.stopPropagation(e)}
                    />
                  </Dropdown>
                  {isVisibl && (
                    <EditInformation
                      ismodelvisible={isVisibl}
                      setmodevisible={setIsModalVisibl}
                      id={id}
                      setmodal={setmodal}
                      types={types}
                      title={title}
                      contentPage={true}
                    />
                  )}
                  {cloneModal && (
                    <CloneInformation
                      ismodelvisible={cloneModal}
                      setmodevisible={setCloneModal}
                      id={id}
                      setmodal={setmodal}
                      types={types}
                      title={title}
                      contentPage={true}
                    />
                  )}
                  {isDeleteModal && (
                    <DeleteConfirm
                      isModalVisible={isDeleteModal}
                      setModalVisible={setIsDeleteModal}
                      id={id}
                      notes={true}
                      contentPage={true}
                    />
                  )}
                </div>
              ) : null}
            </div>

            <div>
              {/* <p className="lecturnotesdescription">{title}</p> */}
              <Typography.Text style={{ width: "130px", marginTop: "20px" }} ellipsis={{ tooltip: true }} className="lecturnotesdescription">{title}</Typography.Text>

              {/* <p className='lecturenotessize'>{(filesSize/ 1024 / 1024).toFixed(2).toString()+' MB'}</p> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="videocomponentmain" style={{ cursor: item?.status == 'Published' ? 'pointer' : 'not-allowed' ,marginRight:"20px"}} onClick={item?.status == 'Published' ? () => handleNotes(id) : ''}>
          {/* <ReactPlayer
            width={"90%"}
            style={{ margin: "10px" }}
            height={"160px"}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
            onContextMenu={e => e.preventDefault()}
            url={file ? `${Api.hlsUrl}${file}` : link}
            controls={item?.status == 'Published' ? true : false}
            // playing={false}
            light={videoThumbImg}
          /> */}
          <Image
            src={videoThumbImg}
            preview={false}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "8px",
              padding: "10px",
              alignItems: "center",
              
            }}

          >
            <div style={{ width: "90%" }}>
              {/* <p className="lecturnotesdescription">{title}</p> */}
              <Typography.Text style={{ width: "130px" }} ellipsis={{ tooltip: true }} className="lecturnotesdescription">{title}</Typography.Text>
              <p className='lecturenotessize'>{item?.status == 'Published' ? '' : 'Processing..'}</p>
            </div>
            {user.access.includes("digital-library-admin") ||
              user.access.includes("digital-library-superadmin") ||
              user.access.includes("digital-library-dataoperator") ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "10%",
                }}
              >
                <Dropdown
                  disabled={false}
                  overlay={menu}
                  placement="bottomRight"
                >
                  <MdMoreVert
                    style={{ width: "24px", height: "24px", color: "#AEAEAE" }}
                    onClick={(e) => e.stopPropagation(e)}
                  />
                </Dropdown>
                {isVisibl && (
                  <EditInformation
                    ismodelvisible={isVisibl}
                    setmodevisible={setIsModalVisibl}
                    id={id}
                    setmodal={setmodal}
                    types={types}
                    title={title}
                    contentPage={true}
                  />
                )}

                {cloneModal && (
                  <CloneInformation
                    ismodelvisible={cloneModal}
                    setmodevisible={setCloneModal}
                    id={id}
                    setmodal={setmodal}
                    types={types}
                    title={title}
                    contentPage={true}
                  />
                )}
                {isDeleteModal && (
                  <DeleteConfirm
                    isModalVisible={isDeleteModal}
                    setModalVisible={setIsDeleteModal}
                    id={id}
                    notes={true}
                    contentPage={true}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      )
      }
    </div >
  );
}

const mapStateToProps = (state) => {
  const {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    icons,
  } = state.digitalLibrary;
  const { user } = state.session;
  return {
    classLibraryList,
    classSubjectList,
    teacherMangementList,
    adminLibraryList,
    selectedSubject,
    getChaptersList,
    selectedStd,
    icons,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeacherData: () => dispatch(fetchTeacherData()),
  fetchLibraryData: (callback) => dispatch(fetchLibraryData(callback)),
  fetchAdminChapterFilter: () => dispatch(fetchAdminChapterFilter()),
  deleteNotes: (id, fetch) => dispatch(deleteNotes(id, fetch)),
  currentFileToShow: (data) => dispatch(currentFileToShow(data)),
  uploadContent: (object) => dispatch(uploadContent(object)),
  currentDocument: (id) => dispatch(currentDocument(id)),
  currentTypes: (type) => dispatch(currentTypes(type)),
  updateSetType: (type) => dispatch(updateSetType(type)),
  fetchTeacherLibraryData: () => dispatch(fetchTeacherLibraryData()),
  postEngagementHistory: (object) => dispatch(postEngagementHistory(object)),
  updateDocumentInfo: (data) => dispatch(updateDocumentInfo(data)),
  setKey: (key) => dispatch(setKey(key)),
  IsHSL: (bool) => dispatch(IsHSL(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(LectureNotes);
