import React, {useEffect} from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Carousel from "antd/lib/carousel";
import Avatar from "antd/lib/avatar";
import Tabs from "antd/lib/tabs";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import { Color } from '../../services';
import Upload from 'antd/lib/upload';
import 'antd/lib/upload/style/index';
import message from 'antd/lib/message';
import { Divider } from 'antd';
import { connect } from "react-redux";
import carouselData from "./carouselData";
import { Menu, Dropdown, notification} from 'antd';
import { MobileOutlined, MailOutlined, CaretDownOutlined, LoadingOutlined, CameraOutlined, ConsoleSqlOutlined} from "@ant-design/icons";
import ReactTelephoneInput from "react-telephone-input";
import {Title, Heading, SubHeading, SmallHeading } from "components/Typography";
import Card from "components/card";
import { logIn, sendForgotLink, postMobileNumber, postVerifyOtp, setPallete, checkToken, setEmail, updatePassword,
          getOrgDetails,
          getOrgsStandardList, setSuperPallete, setVerfiyOtpModal, updateRegisterDetails,
          clearRegisterDetails, postRegisterDetails, postMobileNumberForRegistration, postVerifyOtpForRegistration,
          verifiedOtpForRegistrationAction,
          getDistrictAndState,} from "../../actions/login";
import { IMG_URL } from "env.json";
import Modal from 'antd/lib/modal/Modal'
import PhoneInput from "react-phone-number-input";

import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/carousel/style/index";
import "antd/lib/avatar/style/index";
import "antd/lib/tabs/style/index";
import "antd/lib/input/style/index";
import "antd/lib/button/style/index";
import "react-telephone-input/css/default.css";
import { fetchPinCodeDetails } from "actions/userManagement";

// const logo = require("Assets/logo.png").default;
const appStore = require("Assets/applestore.png").default;
const playStore = require("Assets/playstore.png").default;

const { TabPane } = Tabs;

const Header = ({instLogo}) => (
  <Row className='aulas-login__header-wrap'>
    <Col xs={7} sm={5} md={3} lg={2} style={{display:'flex'}}>
      <img src={instLogo} alt={'logo'} style={{ width:'45%' }} />
    </Col>
  </Row>
);

const TermsPolicy = () => (
  <div className="m-t-10 text-center">
    <span className='text-xs dark-grey'>By logging in you accept the</span>
    <a className='text-xs blue m-l-5 m-r-5 bold-600' href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer">
      Terms & Conditions
    </a>
    <span className='text-xs dark-grey'>and</span>
    <a className='text-xs blue m-l-5 m-r-5 bold-600' href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
  </div>
);

let roleArr = ['Teacher', 'Student', 'Data Operator', 'Operation']


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      country: "",
      phone: "",
      otp: "",
      agreementChecked: true,
      imgLoading: false,
      imageAdd: "",
    };
  }

  componentDidMount() {
    this.props.setSuperPallete('1', 'login');
    this.props.setPallete('1', 'email');
    //document.addEventListener("keydown", this.keyPress);
    this.props.verifiedOtpForRegistrationAction(false);
    this.props.clearRegisterDetails();
    this.props.getOrgDetails(() => {});
    this.props.getOrgsStandardList(() => {});
    if(this.props.orgStandards?.length === 0) {
      this.props.updateRegisterDetails('Unassigned', 'preferredClass');
    }
  }

  componentWillUnmount() {
    this.setState({
      password: "",
      country: "",
      phone: "",
      otp: "",
    });
    this.props.setSuperPallete('1', 'login');
    this.props.setPallete('1', 'email');
    this.props.setEmail("");
    document.removeEventListener("keydown", this.keyPress);
  }

  keyPress = (e) => {
    const { history, palleteKey, palletTab } = this.props;
    const { phone } = this.state;
    // on enter
    if (e.keyCode === 13 || e.keyCode === "13") {
      if (palletTab === 'email') {
        if(palleteKey == 1){
          this.pageLogin(history);
        }else{
          this.forgotSend(history);
        }
      }
      if (palletTab === 'mobile') {
        if(palleteKey == 1){
          this.sendOtp(phone, history);
        }else{
          this.mobileLogin(history);
        }
      }
      // if (palleteKey === 3 || palleteKey === "3") {
      //   this.forgotSend(history);
      // }
      // if (palleteKey === 4 || palleteKey === "4") {
      //   this.mobileLogin(history);
      // }
    }
  };

  pageLogin(history) {
    let user = {
      email: this.props.email,
      password: this.state.password,
      isWebLogin: true,
    };
    this.props.logIn(user, history);
  }

  checkFields = (userRegister) => {
    if (userRegister?.name.trim() === '' ||
        !(userRegister?.phone.length > 8 && userRegister?.phone.length <=17) ||
        userRegister?.role === '' ||
        userRegister?.address.state.trim() === '' ||
        userRegister?.address.city.trim() === '' ||
        userRegister?.address.pincode === '' ||
        userRegister?.address.pincode.length !== 6 ||
        userRegister?.role === 'Student' ? userRegister?.preferredClass === '' ? true : false : false
    )  {
      return true
    } else {
      return false
    }
  }

  openNotification = () => {
    notification.open({
      message: 'Verification !',
      description:
        'Please verify mobile number first!',
      style: {
        fontWeight : 'bold',
        width: 300,
      },
    });
  };

  openNotificationOfRequired = () => {
    notification.open({
      message: 'Required!',
      description:
        'Please fill all required fields.',
      style: {
        fontWeight : 'bold',
        width: 300,
      },
    });
  };

  forgotPress() {
    this.props.setPallete('2', 'email');
  }
  forgotSend(history) {
    this.props.sendForgotLink(this.props.email, history);
  }
  sendOtp(phone, history) {
    const number = phone.replace(/-/g, "").replace(/ /g, "");
    this.props.postMobileNumber(number, history);
  }
  sendOtpForRegistration(phone, history) {
    const number = phone.replace(/-/g, "").replace(/ /g, "");
    this.props.postMobileNumberForRegistration(number, history);
  }
  handleInputChange(telNumber) {
    this.setState({
      phone: telNumber,
    });
  }
  handleMobileInputForRegistration(telNumber) {
    this.props.updateRegisterDetails(telNumber, 'phone');
  }
  mobileLogin(history) {
    const number = this.state.phone.replace(/-/g, "").replace(/ /g, "");
    this.props.postVerifyOtp(number, this.state.otp, history);
    this.setState({ otp: "" });
  }

  verifyOtpForRegistration(history) {
    const number = this.state.phone.replace(/-/g, "").replace(/ /g, "");
    this.props.postVerifyOtpForRegistration(number, this.state.otp, history);
    this.setState({ otp: "" });
  }

  superPalleteTabSwitch(key){
    //this.setsup({phone:''})
    if(key == 1){
      this.props.setSuperPallete('1', 'login'); 
      this.props.setPallete('1', 'email');
    }else{
      this.props.setSuperPallete('1', 'register')
    }
  }

  loginTabSwitch(key){
    this.setState({phone:''})
    if(key == 1){
      this.props.setPallete('1', 'email');
    }else{
      this.props.setPallete('1', 'mobile')
    }
  }

  handleRoleMenuItem = (role) => {
    this.props.updateRegisterDetails(role, 'role');
  };

  handleCourseMenuItem = (preferredCourse, preferredCourseId) => {
    this.props.updateRegisterDetails(preferredCourse, 'preferredClass');
    this.props.updateRegisterDetails(preferredCourseId, 'preferredCourseId');
  };

  pinUpdateCallback = (response) => {
    if(response?.Status === "Success"){
    this.props.updateRegisterDetails(response?.PostOffice[0]?.District,'city');
    this.props.updateRegisterDetails(response?.PostOffice[0]?.State,'state');
    }else{}
  }

  roleMenu = () => {
    return (
      <Menu selectedKeys={[this.props.userRegister?.role === "" ? "Select Role" : `${this.props.userRegister?.role}`]}>
          {roleArr.map((role, index) => (
            <Menu.Item key={role} onClick={() => this.handleRoleMenuItem(role)}>
              {role}
            </Menu.Item>
        ))}
      </Menu>
  )}

  courseMenu = () => {
    return (
      <Menu style={{ padding: 5, maxHeight: 105, overflow: "auto" }} selectedKeys={[this.props.userRegister?.preferredClass === "" ? "Select Course" : `${this.props.userRegister?.preferredClass}`]}>
          {this.props.orgStandards.map((item, index) => (
            <Menu.Item key={item?.courseName} onClick={() => this.handleCourseMenuItem(item?.courseName, item?.id)}>
              {item?.courseName}
            </Menu.Item>
        ))}
      </Menu>
  )}

  uploadImage = info => {
    if (info.file.status === "uploading") {
      this.setState({ imgLoading: true });
      return;
    }
    if (info.file) {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => {
          this.props.updateRegisterDetails(info.file.originFileObj, 'image'),
          this.setState({
            imageUrl,
            imgLoading: false,
            imageAdd: info.file.originFileObj,
            allowEdit: true
          })
        }
      );
    }
  };
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  render(){
    const {history, orgData, palleteKey, palletTab,
           superPalletKey, superPalletTab, verfiyOtpModal, userRegister, verifiedOtpforRegistration,
           setVerfiyOtpModal, orgStandards} = this.props;

    const uploadButton = (
      <div>
        {this.state.imgLoading ? <LoadingOutlined /> : /* (userRegister?.name ? <img src={`${userRegister?.image}`} alt="" style={{ width: '100%' }} /> : */ <CameraOutlined style={{color:Color.primary, fontSize:'2em'}} />}
      </div>
    );

    const { imageUrl } = this.state;

    return(
      <>
      <div className='aulas-login__body-wrap'>
        {/* <Header instLogo={orgData ? `${IMG_URL}/${orgData?.logo}` : require("Assets/noinstitute.png").default} ></Header> */}
        <Row justify="space-around" align="middle" className='flex-1'>
          <Col xs={0} sm={10} md={14} style={{  }}>
            <Carousel autoplay style={{}} dots={false}>
              {carouselData.map((data, index) => (
                <div className="text-center" key={index}>
                  <div className="r-c-c-c">
                    <Col xs={12}>
                      <img src={data.image} alt={data.title} style={{ maxWidth: "100%" }} />
                    </Col>
                  </div>
                  <div style={{}}>
                    <Col flex="auto">
                      <Heading bold>{data.title}</Heading>
                      <SubHeading color="#636363">{data.description}</SubHeading>
                    </Col>
                  </div>
                </div>
              ))}
            </Carousel>
          </Col>
          <Col xs={24} sm={12} md={9} style={{}} offset={1}>
            <div style={{width: "95%"}}>
            <Row justify="center">
                <Col flex="auto" align="center">
                  <img className="institute-logo" src={orgData ? `${IMG_URL}/${orgData.logo}` : require("Assets/noinstitute.png").default} width={80} />
                  {orgData?<Heading bold style={{ margin: 0, marginTop: 5 }}>{orgData.title} </Heading>: null}
                  {orgData?<SubHeading color="#636363" style = {{marginTop: 8}}>Institute ID : <span style = {{color: '#191919', fontWeight :'bold'}}>{orgData.instituteId}</span></SubHeading>: null}
                </Col>
            </Row>
            
            <div style = {{boxShadow : '4px 4px 18px #302A5F14',
                           borderBottomLeftRadius: 15,
                           borderBottomRightRadius: 15,}}>
              <Tabs defaultActiveKey="1" onChange={(key) => this.superPalleteTabSwitch(key)} className="getting-stated-options-tabs m-t-40">
                  <TabPane tab={<><span>SIGN IN</span></>} key="1"></TabPane>
                  <TabPane tab={<><span>REGISTER</span></>} key="2"></TabPane>
              </Tabs>
              {
                (superPalletKey == 1 && superPalletTab === 'login')
                ? <Card style={{ width: "100%",
                                paddingLeft: 50,
                                paddingRight: 50,
                                marginTop:-20,
                                marginLeft: 0,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,}}>
                  {/* <Row justify="center" className="m-t-20">
                    <Heading>Sign in to your account</Heading>
                  </Row> */}
                  <Tabs defaultActiveKey="1" onChange={(key) => this.loginTabSwitch(key)} className="m-t-20 login-tabs">
                    <TabPane tab={<><span style = {{marginRight:10}}>Email</span> <MailOutlined /></> } key="1">
                      <>
                        {(palleteKey == 1 && palletTab === 'email')
                        ?
                          <div>
                            <div className="m-t-5">
                              <SmallHeading bold color="#191919">Email ID  <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                              <Input placeholder="Enter email ID" className="m-t-5 aulas-login__input"
                                value={this.props.email} onChange={(em) => this.props.setEmail(em.target.value)}
                              />
                            </div>
                            <div className="m-t-20">
                              <SmallHeading bold color="#191919">Password <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                              <Input.Password className="m-t-5 aulas-login__input" placeholder="Enter password"
                                value={this.state.password} onChange={(pass) => this.setState({ password: pass.target.value })}
                              />
                            </div>
                            <div className="m-t-5 text-right">
                              <Button onClick={() => this.forgotPress()} type="text" className="bold-bold" style={{color:"#1089FF"}} >
                                Forgot Password ?
                              </Button>
                            </div>
                            <div className="m-t-30 m-b-10">
                              <Button block type="primary" shape="round" className="r-c-c bold-bold" style={{ padding: 20 }}
                                onClick={() => this.pageLogin(history)} loading={this.props.loginLoader}
                                onKeyDown={(e) => this.keyPress(e, history)}
                                >
                                Login
                              </Button>
                            </div>
                            <TermsPolicy />
                          </div>
                        : (palleteKey == 2 && palletTab === 'email')
                          ?
                          <div className="m-t-5">
                            <SmallHeading bold color="#636363">Email ID</SmallHeading>
                            <Input placeholder="Enter email ID" className="m-t-5 aulas-login__input"
                              value={this.props.email} onChange={(em) => this.props.setEmail(em.target.value)} />
                            <div className="m-t-30 m-b-10">
                              <Button block type="primary" shape="round" className="r-c-c bold-bold" style={{ padding: 20 }}
                                onClick={() => this.forgotSend(history)} loading={this.props.resetLoader}
                                // disabled={this.props.resetLoader}
                                onKeyDown={(e) => this.keyPress(e, history)}
                                >
                                Send
                              </Button>
                            </div>
                            <div className="text-center a2grey cursor-pointer m-t-20 text-xmd"
                              onClick={() => this.props.setPallete('1', 'email')}>
                              Go Back
                            </div>
                            <TermsPolicy />
                          </div>
                          : null
                        }
                      </>
                    </TabPane>
                    <TabPane tab={ <><span style = {{marginRight : 10}}>Mobile</span> <MobileOutlined /></> } key="2">
                      {(palleteKey == 1 && palletTab === 'mobile')
                      ?
                        <div className="m-t-10">
                          <SmallHeading bold color="#191919">Mobile Number  <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                          <ReactTelephoneInput className="m-t-5 p-5 aulas-login__input-telephone"
                            defaultCountry="in"
                            flagsImagePath={require("./flags.png").default}
                            onChange={(telNumber, selectedCountry) =>
                              this.handleInputChange(telNumber, selectedCountry)
                            }
                          />
                          <div className='m-b-10' style = {{marginTop: 145}}>
                            <Button block type="primary" shape="round" className="r-c-c bold-bold" style={{ padding: 20 }}
                              onClick={() => this.sendOtp(this.state.phone, history)}
                              disabled={this.props.sendOtpLoader || !this.state.agreementChecked}
                              loading={this.props.sendOtpLoader}
                              onKeyDown={(e) => this.keyPress(e, history)}
                              >
                              Send OTP
                            </Button>
                          </div>
                          <TermsPolicy />
                        </div>
                      :
                        <div className="m-t-50">
                          <div style={{width:350, margin:'10px auto 20px'}} className="tabsHolder color-black text-center">
                            {"Enter OTP sent to " + this.state.phone}
                          </div>
                          <div style={{ position: "relative" }} className="r-c-c">
                            <div className="r-c-c">
                              <div className="otpInput">{this.state.otp[0]}</div>
                              <div className="otpInput">{this.state.otp[1]}</div>
                              <div className="otpInput">{this.state.otp[2]}</div>
                              <div className="otpInput">{this.state.otp[3]}</div>
                              <div className="otpInput">{this.state.otp[4]}</div>
                              <div className="otpInput">{this.state.otp[5]}</div>
                            </div>
                            <input type="number" className='aulas-login__input-verify-otp'
                              value={this.state.otp}
                              onChange={(em) => this.setState({ otp: em.target.value.substring(0, 6) })}
                            />
                          </div>
                          <Button block type="primary" shape="round" className="r-c-c bold-bold m-t-20" style={{ padding: 20 }}
                            onClick={() => this.mobileLogin(history)} loading={this.props.verifyOtpLoader}
                            // disabled={this.props.verifyOtpLoader}
                            >
                            Verify OTP
                          </Button>
                          <Button block type="text" shape="round" className="r-c-c bold-bold m-t-20" style={{color:"#5843be"}}
                            onClick={() => this.sendOtp(this.state.phone, history)} loading={this.props.sendOtpLoader}
                            disabled={this.props.verifyOtpLoader || this.props.resendOtpTimer} >
                            Resend OTP {this.props.timer == '' ? '' : `in ${this.props.timer}`}
                          </Button>
                          <div className="text-center a2grey cursor-pointer m-t-20 text-xmd"
                            onClick={() => {this.props.setPallete('1', 'mobile'); this.setState({phone:''})}}>
                            Go Back
                          </div>
                          <TermsPolicy />
                        </div>
                      }
                    </TabPane>
                  </Tabs>
                </Card> : 

                <Card style={{ width: "100%",
                              paddingLeft: 50,
                              paddingRight: 50,
                              marginTop:-20,
                              marginLeft: 0,
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                }}>
                  <div className="m-t-5 text-center">
                     <Heading bold>Registration Form</Heading>
                     <SubHeading color="#636363">Kindly fill the form below to register.</SubHeading>
                  </div>
                  <div style = {{overflowY: 'scroll', height : 240}}>
                    <Row justify="center" className = 'm-t-20'>
                      <Col flex="auto" align="center">
                       <div className="r-sb-c">
                          { <Upload
                              name="avatar"
                              listType="picture-card"
                              className="administratorUploader cursor-pointer"
                              showUploadList={false}
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              beforeUpload={this.beforeUpload}
                              onChange={this.uploadImage}
                            >
                              {imageUrl ? (
                                <Avatar
                                  src={imageUrl}
                                  alt="avatar"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>}
                            <div
                              className="bold-600 text-sm m-l-10 m-t-10"
                              style={{ color: Color.darkGrey }}
                            >
                              {userRegister?.image && userRegister.image != "default.png" ? "Change Photo" : "Upload Photo"}
                            </div>
                        </div>
                        {/* <div className='bold-600 text-sm m-r-10 m-t-10' style={{color:Color.darkGrey}}>{imageUrl ? 'Change Photo'  :'Upload Photo'}</div> */}
                      </Col>
                    </Row>
                    <SmallHeading bold color="#191919" className="m-t-20">Role <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                    <Dropdown overlay={this.roleMenu} trigger={["click"]} className = 'm-t-10'>
                      <div className="cursor-pointer r-c-sb"
                          style = {{backgroundColor : '#FAFAFA', color : userRegister?.role !== '' ? '#191919':'#AEAEAE', height : '40px'}}>
                        <span bold className = 'p-l-20'>{userRegister?.role == '' ? 'Select Role' : userRegister?.role}</span>
                        <CaretDownOutlined style={{ fontSize: 15, marginRight : 20}} />
                      </div>
                    </Dropdown>
                    <div className="m-t-20">
                            <SmallHeading bold color="#191919">Name <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                            <Input placeholder="Enter Name" className="m-t-10 p-l-15 aulas-login__input"
                              value = {userRegister?.name}
                              onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s\s+/g, '');
                                this.props.updateRegisterDetails(value, 'name')
                              }}
                            />
                    </div>
                    <div className="m-t-20">
                        <SmallHeading bold color="#191919">Mobile Number <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                        <div className = 'r-c-sb'>
                          <PhoneInput
                          addInternationalOption={true}
                            className = "p-10"
                            style={{ 
                                     width: "100%",
                                     height : 35,
                                     marginLeft : 5,
                                     marginTop : 25,
                                     marginBottom : 15}}
                            defaultCountry="IN"
                            placeholder="Enter Mobile Number"
                            value={userRegister?.phone}
                            onCountryChange={e => console.log("code", e)}
                            onChange={e => {
                              if (e != undefined) {
                                this.handleInputChange(e)
                                this.handleMobileInputForRegistration(e)
                              } else {
                                this.handleInputChange('')
                                this.handleMobileInputForRegistration('')
                              } 
                              this.props.verifiedOtpForRegistrationAction(false);
                            }}
                          />
                          {
                            verifiedOtpforRegistration
                            ? <Button onClick={() => {
                              //Nothing
                              }} type="text" className="bold-bold"
                                    style={{color:"#28DF99",
                                            backgroundColor : 'transparent',
                                            height : '20px',
                                            marginTop : 0,
                                            marginLeft : -120,
                                            }} >
                              Verified
                            </Button>    
                            :  <Button onClick={() => {
                                this.sendOtpForRegistration(this.state.phone, history);
                                setVerfiyOtpModal(true);
                              }} type="text" className="bold-bold"
                                 disabled = {(userRegister?.phone.length > 8 && userRegister?.phone.length <=17) ? false : true}
                                 style={{color:"#1089FF",
                                        backgroundColor : 'transparent',
                                        height : '20px',
                                        marginTop : 0,
                                        marginLeft : -120,
                                        opacity : (userRegister?.phone.length > 8 && userRegister?.phone.length <=17) ? 1.0 : 0.5,
                                        }} >
                                SEND OTP
                              </Button>    
                          }
                         
                        </div>      
                      </div>
                      {/* <div className="m-t-20">
                        <div className = 'r-c-sb'>
                          <SmallHeading bold color="#191919">Email ID</SmallHeading>
                          <SmallHeading bold color="#AEAEAE">Optional</SmallHeading>
                        </div>
                        <Input placeholder="Enter Email ID" className="m-t-10 p-l-15 aulas-login__input"
                          value={userRegister?.email} onChange={(em) => this.props.updateRegisterDetails(em.target.value, 'email')}
                        />
                      </div> */}
                      {/* <div className="m-t-20">
                          <SmallHeading bold color="#191919">PIN / ZIP code <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                          <Input placeholder="Enter PIN / ZIP Code" className="m-t-10 p-l-15 aulas-login__input" type = 'number'
                            value={userRegister?.address.pincode}
                            onChange={(em) => {
                              if(em.target.value.length < 7 && !em.target.value.match(/[^\w]/gi)){
                              let value = em.target.value.trim(); 
                              this.props.updateRegisterDetails(value, 'pincode')                           
                              if(em.target.value.length === 6 && Number(em.target.value)){
                                this.props.fetchPinCodeDetails(value, this.pinUpdateCallback);
                              }else{
                                this.props.updateRegisterDetails('', 'city')
                                this.props.updateRegisterDetails('', 'state')
                              }
                            }
                              // if (em.target.value.length <= 6 && Number(em.target.value)) {
                                
                              //   value = value.replace(/[A-Za-z ]/ig, '')
                              //   this.props.updateRegisterDetails(value, 'pincode')
                              //   this.props.fetchPinCodeDetails(em.target.value.trim(), this.props.pinUpdateCallback);
                              // }                              
                            }}
                            //onChange={(em) => em.target.value.length < 7 && !(em.target.value.match(/^[^0-9]/)) ? this.props.updateRegisterDetails(em.target.value, 'pincode') : ''}
                          />
                      </div> */}
                      {/* <div className="m-t-20">
                          <SmallHeading bold color="#191919">District / City <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                          <Input placeholder="Enter City" className="m-t-10 p-l-15 aulas-login__input"
                            value={userRegister?.address.city}
                            onChange={(em) => {
                              let value = em.target.value
                              value = value.replace(/\s\s+/g, '');
                              value = value.replace(/[^A-Za-z ]/ig, '')
                              this.props.updateRegisterDetails(value, 'city')
                            }}
                          />
                      </div> */}
                      {/* <div className="m-t-20">
                          <SmallHeading bold color="#191919">State <span style={{color:Color.red}}>{'*'}</span></SmallHeading>
                          <Input placeholder="Enter State" className="m-t-10 p-l-15 aulas-login__input"
                            value={userRegister?.address.state}
                            onChange={(em) => {
                              let value = em.target.value
                              value = value.replace(/\s\s+/g, '');
                              value = value.replace(/[^A-Za-z ]/ig, '')
                              this.props.updateRegisterDetails(value, 'state')
                            }}
                          />
                      </div> */}
                      {
                        userRegister?.role == 'Student' ?
                        orgStandards?.length > 0 ?
                          <div className="m-t-20">
                            <SmallHeading bold color="#191919" className="m-t-20">Which course are you applying for ?</SmallHeading>
                            <Dropdown overlay={this.courseMenu} trigger={["click"]} className = 'm-t-10'>
                              <div className="cursor-pointer r-c-sb"
                                  style = {{backgroundColor : '#FAFAFA', color : userRegister?.preferredClass !== '' ? '#191919':'#AEAEAE', height : '40px'}}>
                                <span bold className = 'p-l-20'>{userRegister?.preferredClass == '' ? 'Select Course ' : userRegister?.preferredClass}</span>
                                <CaretDownOutlined style={{ fontSize: 15, marginRight : 20}} />
                              </div>
                            </Dropdown>
                          </div> :
                         <></> : //Nothing to show if there is no course in the org coming from api.
                         ''
                      }
                  </div>    
                  <div className="m-t-30 m-b-10">
                      <Button block type="primary" shape="round" className="r-c-c bold-bold" 
                      disabled={userRegister?.role==='' || userRegister?.name==='' || userRegister?.phone==='' || verifiedOtpforRegistration==false}
                        onClick={() => {
                          if (verifiedOtpforRegistration) {
                            this.props.postRegisterDetails(() => {
                              this.setState({
                                imageAdd: "",
                                imageUrl: "",
                              });
                            }, history);
                        } else {
                          this.openNotification('topRight');
                        }
                          // if (this.checkFields(userRegister)) {
                          //   this.openNotificationOfRequired('topRight');
                          // } else if (verifiedOtpforRegistration) {
                          //     this.props.postRegisterDetails(() => {
                          //       this.setState({
                          //         imageAdd: "",
                          //         imageUrl: "",
                          //       });
                          //     }, history);
                          // } else {
                          //   this.openNotification('topRight');
                          // }
                          
                        }} loading={this.props.loginLoader}
                        onKeyDown={(e) => this.keyPress(e, history)}
                        style={{ padding: 20,
                                /*  opacity : this.checkFields(userRegister) ? 0.5 : 1 */ }}
                        >
                        REGISTER
                      </Button>
                  </div>   
                </Card>
              }
            </div>
            </div>
          </Col>
        </Row>

        <Row className='aulas-login__footer-wrap'>
          <Col xs={12}>
            <div className='r-c-c flex-wrap p-10'>
              <div className='r-ac p-t-10 p-b-10'>
                <img src={require("Assets/purple-heart.svg").default} alt="aulas App Store" style={{ maxWidth: "15px" }} />
                <div className='m-l-5 blue'>aulas care</div>
              </div>
              <a href='https://www.aulas.in/' target='_blank()' className='m-l-20 p-t-10 p-b-10 blue'>www.aulas.in</a>
              <div className='m-l-20 p-t-10 p-b-10'>Login using aulas app</div>
              <a href='https://apps.apple.com/in/app/aulas/id1564724183' target='_blank()' className='cursor-pointer m-l-20 p-t-10 p-b-10'>
                <img src={appStore} alt="aulas App Store" style={{ maxWidth: "80px" }} />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.classroom.aulas' target='_blank()' className='cursor-pointer m-l-20 p-t-10 p-b-10'>
                <img src={playStore} alt="aulas Play Store" style={{ maxWidth: "80px" }} />
              </a>
            </div>
          </Col>
          <Col xs={1} className='full-height r-c-c'><Divider type="vertical" style={{height:'100%'}} /></Col>
          <Col xs={11}>
            <div className='r-c-c p-10 flex-wrap'>
              <a href={`${orgData ? orgData.website : ''}`} target='_blank()' className='blue p-t-10 p-b-10'>{orgData ? orgData.website : ''}</a>
              {orgData && (orgData.playstoreLink === '' || orgData.playstoreLink === null)
                ?
                  null
                :
                <div className='r-ac'>
                  <div className='m-l-20 p-t-10 p-b-10'>Download {orgData ? orgData.title : ''} app</div>
                  <a href={orgData ? orgData.playstoreLink : '/'} target='_blank()' className='cursor-pointer m-l-20 p-t-10 p-b-10'>
                    <img src={playStore} alt="aulas Play Store" style={{ maxWidth: "80px" }} />
                  </a>
                </div>
              }

            </div>
          </Col>
        </Row>
      </div>

      <Modal
        centered
        closable={true}
        footer = {null}
        transitionName=""
        maskClosable={false}
        visible={verfiyOtpModal}
        className="verifyOtpModal"
        onCancel={() => { 
          this.setState({ otp: ""})
          setVerfiyOtpModal(false);
        }}  
        >
          <Heading bold className = 'r-c-c m-t-10'>Verify Your Number</Heading>
          <SubHeading color="#636363" className = 'r-c-c m-t-10'>Please enter the verification code we’ve sent you on</SubHeading>

          <div className = 'r-c-c m-t-5'>
            <SubHeading bold color="#636363">{this.state.phone}</SubHeading>
            <Button onClick={() => {
                      this.setState({ otp: ""})
                      setVerfiyOtpModal(false);
                    }} type="text" className="bold-bold"
                        style = {{color : '#1089FF'}} >
                      Edit
            </Button>
          </div>

          <div style={{ position: "relative" }} className="r-c-c m-t-100">
            <div className="r-c-c"  style={{fontWeight:700}}>
              <div className="otpInputNew">{this.state.otp[0]}</div>
              <div className="otpInputNew">{this.state.otp[1]}</div>
              <div className="otpInputNew">{this.state.otp[2]}</div>
              <div className="otpInputNew">{this.state.otp[3]}</div>
              <div className="otpInputNew">{this.state.otp[4]}</div>
              <div className="otpInputNew">{this.state.otp[5]}</div>
            </div>
            <input type="number" className='aulas-login__input-verify-otp'
              value={this.state.otp}
              onChange={(em) => this.setState({ otp: em.target.value.substring(0, 6) })}
            />
          </div>

          <div className="m-b-30" style = {{marginTop : 50, marginLeft : 130, marginRight : 130}}>
            <Button block type="primary" shape="round" className="r-c-c bold-bold" style={{ padding: 20,}}
              onClick={() => this.verifyOtpForRegistration(history)} loading={this.props.verifyOtpLoader}
              >
              VERIFY
            </Button>
          </div>

          <div className = 'r-c-c' style = {{marginLeft : 40}}>
            <SubHeading color="#636363">Didn’t receive a code?</SubHeading>
            <Button block type="text"
                    className="bold-bold"
                    style={{color : !this.props.resendOtpTimer ? '#1089FF' : '#28DF99', width: 200, marginLeft : -30}}
                    onClick={() => {
                      this.sendOtpForRegistration(this.state.phone, history);
                    }}
                    loading={this.props.sendOtpLoader}
                    disabled={this.props.verifyOtpLoader || this.props.resendOtpTimer}
             >
                    Resend OTP {this.props.timer == '' ? '' : `in ${this.props.timer}`}
            </Button>
            {/* <Button onClick={() => {
                      this.sendOtpForRegistration(this.state.phone, history);
                    }} type="text" className="bold-bold"
                        style = {{color : '#1089FF'}} >
                      Resend OTP
            </Button> */}
          </div>
        </Modal>
    </>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    loginLoader,
    palleteKey,
    sendOtpLoader,
    resetLoader,
    resendOtpTimer,
    verifyOtpLoader,
    timer,
    email,
    orgData,
    palletTab,
    superPalletKey,
    superPalletTab,
    verfiyOtpModal,
    userRegister,
    verifiedOtpforRegistration,
    orgStandards,
  } = state.login;
  return {
    palleteKey,
    loginLoader,
    sendOtpLoader,
    resetLoader,
    resendOtpTimer,
    verifyOtpLoader,
    timer,
    email,
    orgData,
    palletTab,
    superPalletKey,
    superPalletTab,
    verfiyOtpModal,
    userRegister,
    verifiedOtpforRegistration,
    orgStandards,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postVerifyOtp: (number, otp, history) =>
    dispatch(postVerifyOtp(number, otp, history)),
  postVerifyOtpForRegistration: (number, otp, history) =>
    dispatch(postVerifyOtpForRegistration(number, otp, history)),
    
  logIn: (params, history) => dispatch(logIn(params, history)),
  setSuperPallete: (num, tab) => dispatch(setSuperPallete(num, tab)),
  setVerfiyOtpModal: (bool) => dispatch(setVerfiyOtpModal(bool)),
  setPallete: (num, tab) => dispatch(setPallete(num, tab)),
  // setName: (text) => dispatch(setName(text)),
  setEmail: (num) => dispatch(setEmail(num)),
  sendForgotLink: (email, history) => dispatch(sendForgotLink(email, history)),
  postMobileNumber: (phone, history) =>
    dispatch(postMobileNumber(phone, history)),
  postMobileNumberForRegistration: (phone, history) =>
    dispatch(postMobileNumberForRegistration(phone, history)),
  checkToken: (userId, token, source) => dispatch(checkToken(userId, token, source)),
  updatePassword: (userId, password, history) =>
    dispatch(updatePassword(userId, password, history)),
  getOrgDetails: (callback) => dispatch(getOrgDetails(callback)),
  getOrgsStandardList: (callback) => dispatch(getOrgsStandardList(callback)),
  updateRegisterDetails: (data, key) =>
    dispatch(updateRegisterDetails(data, key)),
  clearRegisterDetails: () =>
    dispatch(clearRegisterDetails()),
  postRegisterDetails: (callback, history) =>
    dispatch(postRegisterDetails(callback, history)),
  verifiedOtpForRegistrationAction: (bool) =>
    dispatch(verifiedOtpForRegistrationAction(bool)),
    fetchPinCodeDetails: (pin, callback) =>
    dispatch(fetchPinCodeDetails(pin, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
