import getStroke from "perfect-freehand";
export function distance(x1, y1, x2, y2){
  let a = x1 - x2;
  let b = y1 - y2;
  return Math.sqrt( a*a + b*b );
}

// const worker = new Worker(new URL('./worker.js', import.meta.url))

function isPointInLineRectangle(x, y, x0, y0, x1, y1, h, delta = 10) {
    // Calculate vector from left to right midpoint
    const dx = x1 - x0;
    const dy = y1 - y0;

    // Calculate the length of the vector from left to right midpoint
    const length = Math.sqrt(dx * dx + dy * dy);

    // Normalize the vector to get the unit vector in the direction from left to right midpoint
    const ux = dx / length;
    const uy = dy / length;

    // Calculate the perpendicular vector to the unit vector
    const perpX = -uy;
    const perpY = ux;

    // Calculate expanded half-height
    const expandedHalfHeight = (h / 2) + delta;

    // Calculate the expanded corners of the rectangle
    const corner1X = x0 + expandedHalfHeight * perpX;
    const corner1Y = y0 + expandedHalfHeight * perpY;
    const corner2X = x0 - expandedHalfHeight * perpX;
    const corner2Y = y0 - expandedHalfHeight * perpY;
    const corner3X = x1 + expandedHalfHeight * perpX;
    const corner3Y = y1 + expandedHalfHeight * perpY;
    const corner4X = x1 - expandedHalfHeight * perpX;
    const corner4Y = y1 - expandedHalfHeight * perpY;

    // Function to check if point is on the left side of a line
    function isLeft(px, py, ax, ay, bx, by) {
        return ((bx - ax) * (py - ay) - (by - ay) * (px - ax)) > 0;
    }

    // Check if the point is inside the expanded rectangle
    const isInside = isLeft(x, y, corner1X, corner1Y, corner2X, corner2Y) &&
                     isLeft(x, y, corner2X, corner2Y, corner4X, corner4Y) &&
                     isLeft(x, y, corner4X, corner4Y, corner3X, corner3Y) &&
                     isLeft(x, y, corner3X, corner3Y, corner1X, corner1Y);

    return isInside;
}


export function drawPath({context, currentPathContainer}){
    if (!currentPathContainer || !currentPathContainer.path) return
    if (!currentPathContainer || (typeof currentPathContainer==='object' && Object.keys(currentPathContainer).length === 0)) return
    context.globalCompositeOperation=currentPathContainer.mode==='pen'? "source-over":"destination-out";
    context.lineWidth = currentPathContainer.width
    context.strokeStyle = currentPathContainer.color
    context.fillStyle = currentPathContainer.color
    context.lineCap = 'round';
    context.lineJoin = 'round';
    // worker.postMessage(currentPathContainer);
    // worker.onmessage = function(e) {
    //   const path = e.data;
    //   context.fill(new Path2D(path));
    // };  
    // const strokePoints = getStroke(currentPathContainer.path, {size: currentPathContainer.width, thinning:0, smoothing:0, streamline:0});
    // const stroke = getSvgPathFromStroke(strokePoints);
    // context.fill(new Path2D(stroke));
    bezierCurve(context, currentPathContainer.path, currentPathContainer.width)
}


export function clearStroke({currentPathContainer, context}){
  let width = currentPathContainer.width*1.05
  context.globalCompositeOperation="destination-out";
  context.lineWidth = width
  context.strokeStyle = 'white'
  context.fillStyle = 'white'
  const strokePoints = getStroke(currentPathContainer.path.slice(0, currentPathContainer.path.length-1), {size: width});
  const stroke = getSvgPathFromStroke(strokePoints);
  context.fill(new Path2D(stroke));
  context.globalCompositeOperation="source-over";
}

export function getShapeObject(x1, y1, x2, y2, shapeType){
  switch(shapeType){
      case 'rect':
          return {
              x: x2>x1?x1:x2,
              y: y2>y1?y1:y2,
              width: Math.abs(x2-x1),
              height: Math.abs(y2 - y1),
              shapeType
          }
      case 'circle':
          return {
              x: x1,
              y: y1,
              radius: distance(x1, y1, x2, y2),
              shapeType
          }
      case 'line':
          return {x:x1, y:y1, x1:x2, y1:y2, shapeType}
      default: 
          return {x1, y1, x2, y2, shapeType}
  }
}

export function drawNewShape(context, shape){
  context.save();
  switch(shape.shapeType){
    case 'rect':
      context.lineWidth = shape.borderWidth
      context.strokeStyle = shape.strokeColor
      context.strokeRect(shape.x, shape.y, shape.width, shape.height)
      break;
    case 'centeredrect':
      let x1 = shape.x-shape.width/2
      let y1 = shape.y-shape.height/2
      context.fillRect(x1, y1, shape.width, shape.height)
      break;
    case 'circle':
      context.lineWidth = shape.borderWidth
      context.strokeStyle = shape.strokeColor
      context.beginPath();
      context.arc(shape.x, shape.y, shape.radius, 0, 2 * Math.PI);
      context.stroke();
      break;
    case 'filledCircle':
      context.lineWidth = shape.borderWidth
      context.fillStyle = shape.strokeColor
      context.beginPath();
      context.arc(shape.x, shape.y, shape.radius, 0, 2 * Math.PI);
      context.fill();
      break;
    case 'line':
      context.lineWidth = shape.borderWidth
      context.strokeStyle = shape.strokeColor
      context.beginPath();
      context.moveTo(shape.x, shape.y)
      context.lineTo(shape.x1, shape.y1)
      context.stroke();
      break;
    default:
      break;
  }
  context.restore();
}

export function ClearCanvas(ref){
const canvas = ref.current ? ref.current : ref
if (!canvas) return

const ctxo = canvas.getContext('2d')
if (!ctxo) return

ctxo.clearRect(0, 0, canvas.width, canvas.height)
}

export const loadImage = url => {
return new Promise((resolve, reject) => {
  const img = new Image();
  if (!url.includes(window.location.hostname)){
    img.crossOrigin = "anonymous";
  }
  img.onload = () => resolve(img);
  img.onerror = () => reject(new Error(`load ${url} fail`));
  img.src = url;
});
};

export const getBordersCoOrdinates = ({x, y, width, height, radius, shapeType, x1, y1}) => {
  switch(shapeType){
    case 'rect':
      return {
        x: x-1,
        y: y-1,
        width: width+1,
        height: height+1
      }
    case 'circle':
      return {
        x: x-radius-1,
        y: y-radius-1,
        width: 2*radius+1,
        height: 2*radius+1
      }
    case 'line':
      return {
        x, y, x1, y1
      }
    default:
      return {
        x: x-1,
        y: y-1,
        width: width+1,
        height: height+1
      }
  }
}

export const getAnchorCoOrdinates = ({borderShape, anchorBluePrint, shapeType}) => {
const topleftShape = {
  ...anchorBluePrint,
  x: borderShape.x,
  y: borderShape.y,
}
const topRightShape = {
  ...anchorBluePrint,
  x: borderShape.x+borderShape.width,
  y: borderShape.y
}
const bottomRightShape = {
  ...anchorBluePrint,
  x: borderShape.x+borderShape.width,
  y: borderShape.y+borderShape.height
}
const bottomLeftShape = {
  ...anchorBluePrint,
  x: borderShape.x,
  y: borderShape.y+borderShape.height
}
const topMid = {
  ...anchorBluePrint,
  x: borderShape.x+borderShape.width/2,
  y: borderShape.y
}
const bottomMid = {
  ...anchorBluePrint,
  x: borderShape.x+borderShape.width/2,
  y: borderShape.y+borderShape.height
}
const leftMid = {
  ...anchorBluePrint,
  x: borderShape.x,
  y: borderShape.y+borderShape.height/2
}
const rightMid = {
  ...anchorBluePrint,
  x: borderShape.x+borderShape.width,
  y: borderShape.y+borderShape.height/2
}
const lineLeft = {
  ...anchorBluePrint,
  x: borderShape.x,
  y: borderShape.y
}

const lineRight = {
  ...anchorBluePrint,
  x: borderShape.x1,
  y: borderShape.y1
}

switch(shapeType){
  case 'rect':
    return [topleftShape, topRightShape, bottomRightShape, bottomLeftShape, topMid, bottomMid, leftMid, rightMid]
  case 'circle':
    return [topleftShape, topRightShape, bottomRightShape, bottomLeftShape]
  case 'line':
    return [lineLeft, lineRight]
  default:
    return [topleftShape, topRightShape, bottomRightShape, bottomLeftShape, topMid, bottomMid, leftMid, rightMid]
}
}

const getNewShapeCoOrdinates = ({x, y, width, height, current_x, current_y, anchorPoint, shapeType, radius}) => {
let newWidth = 0;
let newHeight = 0;
let newX = 0;
let newY = 0;
let newRadius = 0;
let minimumRadius = 5;
switch(anchorPoint){
  case 0:
    //topleft
    switch(shapeType){
      case 'rect':
        newX = x
        newY = y
        newWidth = width+current_x-x;
        newHeight = height+current_y-y;
        break;
      case 'circle':
        let a = current_x-radius;
        let b = current_y-radius;
        let dx = Math.max(a-x, b-y)/(Math.sqrt(2))
        let dy = dx
        newX = radius+(dx)>minimumRadius?current_x-dx:current_x;
        newY = radius+(dx)>minimumRadius?current_y-dy:current_y;
        newRadius = radius+(dx)>minimumRadius?radius+dx:radius
        break
      default:
        newX = x
        newY = y
        newWidth = width+current_x-x;
        newHeight = height+current_y-y;
    }
    break;
  case 1:
    //topright
    switch(shapeType){
      case 'rect':
        newX = current_x
        newY = y
        newWidth = x-current_x;
        newHeight = height+current_y-y;
        break;
      case 'circle':
        let a = current_x+radius;
        let b = current_y-radius;
        let dx = Math.max(x-a, b-y)/(Math.sqrt(2))
        let dy = dx
        newX = radius+(dx)>minimumRadius?current_x+dx:current_x;
        newY = radius+(dx)>minimumRadius?current_y-dy:current_y;
        newRadius = radius+(dx)>minimumRadius?radius+dx:radius
        break;
      default:
        newX = current_x
        newY = y
        newWidth = x-current_x;
        newHeight = height+current_y-y;
    }

    break;
  case 2:
    //bottomright
    switch(shapeType){
      case 'rect':
        newX = current_x
        newY = current_y
        newWidth = x-current_x;
        newHeight = y-current_y;
        break;
      case 'circle':
        let a = current_x+radius;
        let b = current_y+radius;
        let dx = Math.max(x-a, y-b)/(Math.sqrt(2))
        let dy = dx
        newX = radius+(dx)>minimumRadius?current_x+dx:current_x;
        newY = radius+(dx)>minimumRadius?current_y+dy:current_y;
        newRadius = radius+(dx)>minimumRadius?radius+dx:radius
        break;
      default:
        newX = current_x
        newY = current_y
        newWidth = x-current_x;
        newHeight = y-current_y;
    }

    break;
  case 3:
    //bottomleft
    switch(shapeType){
      case 'rect':
        newX = x
        newY = current_y
        newWidth = width+current_x-x;
        newHeight = y-current_y;
        break;
      case 'circle':
        let a = current_x-radius;
        let b = current_y+radius;
        let dx =  Math.max(a-x, y-b)/(Math.sqrt(2))
        let dy = dx
        newX = radius+(dx)>minimumRadius?current_x-dx:current_x;
        newY = radius+(dx)>minimumRadius?current_y+dy:current_y;
        newRadius = radius+(dx)>minimumRadius?radius+dx:radius
        break;
      default:
        newX = x
        newY = current_y
        newWidth = width+current_x-x;
        newHeight = y-current_y;
    }

    break;
  case 4:
    //topmid
    newX = current_x
    newY = y
    newWidth = width;
    newHeight = height+current_y-y;
    break;
  case 5:
    //bottommid
    newX = current_x
    newY = current_y
    newWidth = width;
    newHeight = y-current_y;
    break;
  case 6:
    //leftmid
    newX = x
    newY = current_y
    newWidth = width+current_x-x;
    newHeight = height;
    break;
  case 7:
    //rightmid
    newX = current_x
    newY = current_y
    newWidth = x-current_x;
    newHeight = height;
    break;
  default:
    break;
}
let shapeObject = getShapeObject(newX, newY, newWidth+newX, newHeight+newY, 'rect')
return [shapeObject.x, shapeObject.y, shapeObject.width, shapeObject.height, newRadius]
}

export class DraggableShape{
  constructor({x, y, width, height, isSelected=false, index, shapeType, timestamp=Date.now(), borderWidth=1, strokeColor='black', radius=0, x1=0, y1=0}){
    this.index = index;
    this.isSelected = isSelected;
    this.mode = 'shape'
    this.anchorBluePrint = {x:0, y:0, height:15, width:15, radius:7, shapeType:'filledCircle', isSelected:true}
    this.x1 = x1
    this.y1 = y1
    this.borderWidth = borderWidth
    this.dragToExtendMode = false;
    this.shapeType = shapeType
    this.timestamp = timestamp
    this.strokeColor = strokeColor
    this.updateShapeCoOrdinates(x, y, width, height, radius, x1, y1);
  }
  drawShape({context, drawBorders=true}){
      // context.drawImage(this.image, this.x, this.y, this.width, this.height);
      drawNewShape(context, this);
      if (this.isSelected && drawBorders){
        this.drawDraggableBorders({context});
      }
  }
  drawDraggableBorders({context}){
    context.save();
    context.lineWidth = 2
    context.globalCompositeOperation="source-over";
    context.strokeStyle = '#b2ccfe'
    if (this.shapeType!='line'){
      context.strokeRect(this.borderShape.x, this.borderShape.y, this.borderShape.width, this.borderShape.height)
    }else{
      drawNewShape(context, {...this, strokeColor:'#b2ccfe', borderWidth:2})
    }
    this.drawAnchorPoints({context});
    context.restore();
    // this.drawControls({context});
  }
  drawAnchorPoints({context}){
    context.fillStyle = '#b2ccfe'
    this.anchorPoints.forEach((anchorPoint)=>{
      drawNewShape(context, anchorPoint)
    })
  }
  isDeleteTouched(x, y){
    let sx = this.x+this.width-15
    let sy = this.y-35
    let ex = this.x+this.width+5
    let ey = this.y-15
    return x>sx && x<(ex) && y>sy && y<(ey)
  }
  drawControls({context}){
    context.fillRect(this.x+this.width-15, this.y-35, 20, 20)
    context.font = "20px sans-serif";
    context.fillStyle = "white";
    context.fillText("x", this.x+this.width-10, this.y-20);
  }
  updateShapeCoOrdinates(x, y, width, height, radius, x1, y1){
    this.x = x;
    this.y = y;
    this.x1 = x1;
    this.y1 = y1;
    this.width = width;
    this.height = height;
    this.radius = radius;
    this.tx = this.shapeType==='circle'?x-radius:x
    this.ty = this.shapeType==='circle'?y-radius:y
    this.borderShape = getBordersCoOrdinates(this); 
    this.anchorPoints = getAnchorCoOrdinates(this);
  }
  getShapeCoOrdinates(){
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      radius: this.radius
    }
  }
  moveShapeCentre({context, x, y, movementX, movementY}){
    switch(this.shapeType){
      case 'rect':
        this.x = x-this.width/2
        this.y = y-this.height/2
        break;
      case 'circle':
        this.x = x
        this.y = y
        break;
      case 'line':
        this.x = this.x+movementX
        this.x1 = this.x1+movementX
        this.y = this.y+movementY
        this.y1 = this.y1+movementY
    }

    this.updateShapeCoOrdinates(this.x, this.y, this.width, this.height, this.radius, this.x1, this.y1)
    this.drawShape({context})
  }
  isWithinBoundaries(x, y){
    switch(this.shapeType){
      case 'rect':
        return x>this.x && x<(this.x+this.width) && y>this.y && y<(this.y+this.height)
      case 'circle':
        if (this.isSelected){
          return x>(this.x-this.radius) && x<(this.x+this.radius) && y>(this.y-this.radius) && y<(this.y+this.radius)
        }else{
          return distance(x, y, this.x, this.y)<this.radius
        }
      case "filledCircle":
        return x>(this.x-this.radius) && x<(this.x+this.radius) && y>(this.y-this.radius) && y<(this.y+this.radius)
      case 'line':
        return isPointInLineRectangle(x, y, this.x, this.y, this.x1, this.y1, this.borderWidth)
    }
  }
  isTouchingBorder(x1, y1){
    const borderWidth = this.borderWidth;
    const delta = 10;
    switch(this.shapeType){
      case 'rect':
        const left = this.x;
        const right = this.x + this.width;
        const top = this.y;
        const bottom = this.y + this.height;

        const isOnLeftBorder = x1 >= left-delta && x1 <= left + borderWidth+delta && y1 >= top-delta && y1 <= bottom+delta;
        const isOnRightBorder = x1 >= right - borderWidth-delta && x1 <= right+delta && y1 >= top-delta && y1 <= bottom+delta;
        const isOnTopBorder = y1 >= top-delta && y1 <= top + borderWidth+delta && x1 >= left-delta && x1 <= right+delta;
        const isOnBottomBorder = y1 >= bottom - borderWidth-delta && y1 <= bottom+delta && x1 >= left-delta && x1 <= right+delta;

        return isOnLeftBorder || isOnRightBorder || isOnTopBorder || isOnBottomBorder;
      case 'circle':
        const distance = Math.sqrt((x1 - this.x) ** 2 + (y1 - this.y) ** 2);
        return distance >= this.radius - borderWidth-delta && distance <= this.radius + borderWidth + delta;
      case 'line':
        // const lineLength = Math.sqrt((this.x1 - this.x) ** 2 + (this.y1 - this.y) ** 2);
        // const distanceToPoint = Math.abs((this.y1 - this.y) * x1 - (this.x1 - this.x) * y1 + this.x1 * this.y - this.y1 * this.x) / lineLength;

        // // Check if the point is near the line within borderWidth
        // if (distanceToPoint > borderWidth) return false;

        // // Check if the point is within the segment bounds
        // const isWithinXBounds = (x1 >= Math.min(this.x, this.x1) && x1 <= Math.max(this.x, this.x1));
        // const isWithinYBounds = (y1 >= Math.min(this.y, this.y1) && y1 <= Math.max(this.y, this.y1));
        // return isWithinXBounds && isWithinYBounds;
        return isPointInLineRectangle(x1, y1, this.x, this.y, this.x1, this.y1, this.borderWidth)
    }
  }
  isTouchingAnchorPoints(x, y){
    let index = 0;
    for (let anchorPoint of this.anchorPoints){
      if (x>(anchorPoint.x-this.anchorBluePrint.width) && x<(anchorPoint.x+2*anchorPoint.width) && y>(anchorPoint.y-anchorPoint.height) && y<(anchorPoint.y+2*anchorPoint.height)){
        return index
      }
      index+=1
    }
    return -1
  }
  isWithinExtendedBoundaries(x, y){
    let [sx, sy, ex, ey] = [0, 0, 0, 0];
    switch(this.shapeType){
      case 'rect':
        sx = this.x - this.anchorBluePrint.width
        sy = this.y - this.anchorBluePrint.height
        ex = this.x+this.width+this.anchorBluePrint.width
        ey = this.y+this.height+this.anchorBluePrint.height
        break
      case 'circle':
        sx = this.x-this.radius - this.anchorBluePrint.width
        sy = this.y-this.radius - this.anchorBluePrint.height
        ex = this.x+this.radius+this.anchorBluePrint.width
        ey = this.y+this.radius+this.anchorBluePrint.height
        break
      case 'line':
        return isPointInLineRectangle(x, y, this.x, this.y, this.x1, this.y1, this.borderWidth) || this.isWithinBoundaries.bind(this.anchorPoints[0])(x, y) || this.isWithinBoundaries.bind(this.anchorPoints[1])(x, y)
    }

    return x>sx && x<(ex) && y>sy && y<(ey)
  }
  extendShape({context, x, y, anchorPoint}){
      if (this.shapeType==='line'){
        if (anchorPoint===0){
          this.updateShapeCoOrdinates(x, y, 0, 0, 0, this.x1, this.y1)
        }else if(anchorPoint===1){
          this.updateShapeCoOrdinates(this.x, this.y, 0, 0, 0, x, y)
        }
      }else{
        const [newX, newY, newWidth, newHeight, newRadius] = getNewShapeCoOrdinates({current_x:this.x, current_y:this.y, width:this.width, height:this.height, x, y, anchorPoint, radius:this.radius, shapeType:this.shapeType, x1:this.x1, y1:this.y1})
        this.updateShapeCoOrdinates(newX, newY, newWidth, newHeight, newRadius)
      }
      this.drawShape({context})
  }
}
export class DraggableImage{
  constructor({image, x, y, width, height, isSelected, index, url, timestamp=Date.now(), localurl, isPDFImage=false}){
      this.image = image;
      this.index = index;
      this.shapeType = 'image';
      this.url = url;
      this.localurl = localurl
      this.isSelected = isSelected;
      this.dragToExtendMode = false;
      this.timestamp = timestamp;
      this.anchorBluePrint = {x:0, y:0, height:15, width:15}
      this.isPDFImage = isPDFImage;
      this.updateImageCoOrdinates(x, y, width, height);
  }
  drawShape({context, drawBorders=true}){
      context.drawImage(this.image, this.x, this.y, this.width, this.height);
      if (this.isSelected && drawBorders){
        this.drawDraggableBorders({context});
      }
      if (this.isPDFImage){
        this.drawNonDraggableBorders({context})
      }
  }
  drawDraggableBorders({context}){
    context.lineWidth = 2
    context.globalCompositeOperation="source-over";
    context.strokeStyle = '#b2ccfe'
    context.strokeRect(this.borderShape.x, this.borderShape.y, this.borderShape.width, this.borderShape.height)
    this.drawAnchorPoints({context});
    // this.drawControls({context});
  }
  drawNonDraggableBorders({context}){
    context.lineWidth = 2
    context.globalCompositeOperation="source-over";
    context.strokeStyle = 'rgba(174, 174, 174, 0.5)'
    context.strokeRect(this.borderShape.x, this.borderShape.y, this.borderShape.width, this.borderShape.height)
  }
  drawAnchorPoints({context}){
    context.fillStyle = '#b2ccfe'
    this.anchorPoints.forEach((anchorPoint)=>{
      drawNewShape(context, {...anchorPoint, shapeType:'centeredrect'})
    })
  }
  isDeleteTouched(x, y){
    if (this.isPDFImage){
      return false
    }
    let sx = this.x+this.width-15
    let sy = this.y-35
    let ex = this.x+this.width+5
    let ey = this.y-15
    return x>sx && x<(ex) && y>sy && y<(ey)
  }
  drawControls({context}){
    context.fillRect(this.x+this.width-15, this.y-35, 20, 20)
    context.font = "20px sans-serif";
    context.fillStyle = "white";
    context.fillText("x", this.x+this.width-10, this.y-20);
  }
  updateImageCoOrdinates(x, y, width, height){
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.tx = x
    this.ty = y
    this.borderShape = getBordersCoOrdinates({x:this.x, y:this.y, width: this.width, height: this.height}) 
    this.anchorPoints = getAnchorCoOrdinates({anchorBluePrint: this.anchorBluePrint, borderShape: this.borderShape})
  }
  getShapeCoOrdinates(){
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height
    }
  }
  moveShapeCentre({context, x, y}){
    this.x = x-this.width/2
    this.y = y-this.height/2
    this.updateImageCoOrdinates(x-this.width/2, y-this.height/2, this.width, this.height)
    this.drawShape({context})
  }
  isWithinBoundaries(x, y){
    if (this.isPDFImage){
      return false
    }
    return x>this.x && x<(this.x+this.width) && y>this.y && y<(this.y+this.height)
  }
  isTouchingBorder(x1, y1){
    if (this.isPDFImage){
      return false
    }
    const borderWidth = this.borderWidth;
    const delta = 10;
    const left = this.x;
    const right = this.x + this.width;
    const top = this.y;
    const bottom = this.y + this.height;

    const isOnLeftBorder = x1 >= left-delta && x1 <= left + borderWidth+delta && y1 >= top-delta && y1 <= bottom+delta;
    const isOnRightBorder = x1 >= right - borderWidth-delta && x1 <= right+delta && y1 >= top-delta && y1 <= bottom+delta;
    const isOnTopBorder = y1 >= top-delta && y1 <= top + borderWidth+delta && x1 >= left-delta && x1 <= right+delta;
    const isOnBottomBorder = y1 >= bottom - borderWidth-delta && y1 <= bottom+delta && x1 >= left-delta && x1 <= right+delta;

    return isOnLeftBorder || isOnRightBorder || isOnTopBorder || isOnBottomBorder;
  }
  isTouchingAnchorPoints(x, y){
    if (this.isPDFImage){
      return false
    }
    let index = 0;
    for (let anchorPoint of this.anchorPoints){
      if (x>(anchorPoint.x-this.anchorBluePrint.width) && x<(anchorPoint.x+2*anchorPoint.width) && y>(anchorPoint.y-anchorPoint.height) && y<(anchorPoint.y+2*anchorPoint.height)){
        return index
      }
      index+=1
    }
    return -1
  }
  isWithinExtendedBoundaries(x, y){
    if (this.isPDFImage){
      return false
    }
    let sx = this.x - this.anchorBluePrint.width
    let sy = this.y - this.anchorBluePrint.height
    let ex = this.x+this.width+this.anchorBluePrint.width
    let ey = this.y+this.height+this.anchorBluePrint.height
    return x>sx && x<(ex) && y>sy && y<(ey)
  }
  extendShape({context, x, y, anchorPoint}){
      const [newX, newY, newWidth, newHeight] = getNewShapeCoOrdinates({current_x:this.x, current_y:this.y, width:this.width, height:this.height, x, y, anchorPoint})
      this.updateImageCoOrdinates(newX, newY, newWidth, newHeight)
      this.drawShape({context})
  }
}


function gradient(a, b) { 
  return (b.y-a.y)/(b.x-a.x); 
} 


export function bezierCurve(ctx, points, width) { 
  if (!points || points.length === 0) return;

  const midPoint = (p1, p2) => ({
      x: (p1.x + p2.x) * 0.5,
      y: (p1.y + p2.y) * 0.5
  });

  if (points.length >= 3) {
      let a, b, c, prevMid, currentMid;

      a = points[0];
      b = points[1];
      prevMid = midPoint(a, b);

      ctx.beginPath();
      ctx.moveTo(prevMid.x, prevMid.y);

      for (let i = 2; i < points.length; i++) {
          c = points[i];
          currentMid = midPoint(b, c);

          ctx.quadraticCurveTo(b.x, b.y, currentMid.x, currentMid.y);

          a = b;
          b = c;
          prevMid = currentMid;
      }

      ctx.stroke();
  } else if (points.length === 2) {
      const a = points[0];
      const b = points[1];
      const mid = midPoint(a, b);

      ctx.beginPath();
      ctx.moveTo(a.x, a.y);
      ctx.lineTo(mid.x, mid.y);
      ctx.stroke();
  } else if (points.length === 1) {
      const a = points[0];

      ctx.beginPath();
      ctx.moveTo(a.x, a.y);
      ctx.lineTo(a.x, a.y);
      ctx.stroke();
  }
} 


export const getSvgPathFromStroke = (stroke) => {
  if (!stroke.length) return "";

  const d = [];
  d.push("M", stroke[0][0].toFixed(2), stroke[0][1].toFixed(2), "Q");

  for (let i = 0; i < stroke.length - 1; i++) {
    const [x0, y0] = stroke[i];
    const [x1, y1] = stroke[i + 1];
    d.push(x0.toFixed(2), y0.toFixed(2), ((x0 + x1) / 2).toFixed(2), ((y0 + y1) / 2).toFixed(2));
  }

  // Closing the path
  const [xLast, yLast] = stroke[stroke.length - 1];
  d.push(xLast.toFixed(2), yLast.toFixed(2), stroke[0][0].toFixed(2), stroke[0][1].toFixed(2), "Z");

  return d.join(" ");
};

export const colorList1 =[
	'black',
	'rgb(147, 152, 176)',
  '#ecf0f1',
	'rgb(229, 153, 247)',
	'rgb(174, 62, 201)',
];
export const colorList2 = [
	'rgba(255, 255, 0, 0.5)',
	'rgb(9, 146, 104)',
	'rgb(64, 192, 87)',
  '#badc58',
  '#77d9e3',
];

export const colorList3 =[
	'rgb(247, 103, 7)',
  'rgb(255, 135, 135)',
	'rgb(224, 49, 49)',
  'rgb(79, 114, 252)',
	'rgb(77, 171, 247)',
];

export const computePointInCanvas = (e, panOffset, scaleOffset, scale) => {
  let [x, y] = [0, 0]
  if (e.type==='touchmove'||e.type==='touchstart'||e.type==='touchcancel'||e.type==='touchend'){
    x = (e.touches[0]? e.touches[0].clientX :e._touches[0].clientX)
    y = (e.touches[0]? e.touches[0].clientY :e._touches[0].clientY)
    const clientX = ((x - panOffset.x * scale  + scaleOffset.x) /scale);
    const clientY = ((y - panOffset.y * scale  + scaleOffset.y) /scale) ;
    return { x: clientX, y: clientY};
  }else{
    const clientX = (e.clientX - panOffset.x * scale + scaleOffset.x)/scale;
    const clientY = (e.clientY - panOffset.y * scale + scaleOffset.y)/scale;

    return { x:clientX, y:clientY, deltaX:e.deltaX, deltaY:e.deltaY, movementX:e.movementX, movementY:e.movementY  }
  }
}
