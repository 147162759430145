import React, { Component } from "react";
import {
  GiftedChat,
  InputToolbar,
  Send,
  Composer,
  Bubble
} from "react-web-gifted-chat";
import { FiSend } from "react-icons/fi";
import { BsPaperclip } from "react-icons/bs";
import Button from 'antd/lib/button';
import Avatar from 'antd/lib/avatar';
import Upload from 'antd/lib/upload';
import message from 'antd/lib/message';
import 'antd/lib/button/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/upload/style/index';
import 'antd/lib/message/style/index';
import { Color } from "services";
import "./styles.css";

class Chatting extends Component {
  constructor(props) {
    super(props);
    this.renderActions = this.renderActions.bind(this);
    this.renderChatAvatar = this.renderChatAvatar.bind(this);
    this.state = {
      uploading: false,
      percent: 0
    };
  }

  renderLoading() {
    return <div>Loading...</div>;
  }

  renderChatAvatar(e) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {e.currentMessage.user.avatar ? (
          <Avatar size={35} src={e.currentMessage.user.avatar} />
        ) : (
          // <Tooltip placement="top" title={<span>{e.currentMessage.user.name}</span>}>
          <Avatar size={35}>{e.currentMessage.user.name[0]}</Avatar>
        )
        // </Tooltip>
        }
        {/*<span style={{marginTop: 4, fontSize: 10, color: Color.darkGrey}}>{e.currentMessage.user.name}</span>*/}
      </div>
    );
  }

  imageBeforeUpload = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    const reader = new FileReader();
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    } else {
      reader.addEventListener("load", () => {
        this.props.onImgUploaded(file);
      });
      reader.readAsDataURL(file);
    }
    return false;
    // return isJpgOrPng && isLt2M;
  };

  renderActions = (e) => {
    const {
      imgUpLoading
    } = this.props;
    return (
      <div
        style={{
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          backgroundColor: Color.midGrey,
          padding: "2px 0px"
        }}
      >
        <Upload
          accept=".jpg,.png"
          beforeUpload={this.imageBeforeUpload}
          showUploadList={false}
        >
          <Button
            loading={imgUpLoading}
            shape="circle"
            type="link"
            size="large"
            style={{backgroundColor: Color.midGrey}}
            icon={<BsPaperclip style={{ color: Color.darkGrey }} />}
          />
        </Upload>
      </div>
    );
  }

  renderComposer = e => <Composer {...e}></Composer>;
  renderBubble = props => (
    <Bubble
      {...props}
      containerStyle={{
        left: {
          backgroundColor: 'white'
        },
        right: {
          backgroundColor: 'white'
        }
      }}
      wrapperStyle={{
        left: {
          backgroundColor: Color.midGrey
        },
        right: {
          backgroundColor: Color.purple
        }
      }}
      bottomContainerStyle={{
        left: {
          backgroundColor: Color.midGrey,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        },
        right: {
          backgroundColor: Color.purple,
          borderBottomLeftRadius: 15,
        }
      }}
    >
    </Bubble>
  );

  renderInputToolbar = inputProps => (
    <InputToolbar
      containerStyle={{
        borderTopWidth: "0px",
        borderRadius: 10,
        backgroundColor: Color.midGrey,
        boxShadow: "rgb(221, 221, 221) -0.5px 1.5px 5px 2px"
      }}
      {...inputProps}
    />
  );

  render() {
    const { messages, onSend, user, loadIndicator } = this.props;
    console.log('broadcast masgs',messages)
    return (
      <div className="chatContainer">
        <GiftedChat
          textInputStyle={{
            paddingTop: 10,
            paddingBottom: 0,
            display: "flex",
            alignItems: "center",
            marginLeft: 0,
            backgroundColor: Color.midGrey
          }}
          renderBubble={(props) => this.renderBubble(props)}
          isLoadingEarlier={loadIndicator}
          loadEarlier
          onLoadEarlier={() => this.props.onLoadEarlier()}
          user={user}
          messages={messages}
          renderSend={e => (
            <Send {...e} alwaysShowSend>
              <div className="sendButton">
                <FiSend className="text-md white"></FiSend>
              </div>
            </Send>
          )}
          onSend={onSend}
          renderAvatar={e => this.renderChatAvatar(e)}
          renderActions={e => this.renderActions(e)}
          renderComposer={e => this.renderComposer(e)}
          renderInputToolbar={e => this.renderInputToolbar(e)}
          renderUsernameOnMessage={true}
          renderMessageText={text => {
            return (
              <div>
                {text.position === "right" ? (
                  <div style={{ padding: "5px 10px", color: "#fff"}}>
                    {text.currentMessage.text}
                  </div>
                ) : (
                  <div style={{ padding: "5px 10px"}}>
                    {text.currentMessage.text}
                  </div>
                )}
              </div>
            );
          }}
        />
    {console.log('broadcast masgs',messages)}

      </div>
    );
  }
}
export default Chatting;
