import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import { Button } from 'components/Button';
import { Dropdown, Menu, Rate, Progress, Modal, Tooltip, Col, Collapse, Divider, Row, AutoComplete, Input } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
const { Panel } = Collapse;
import videoGrey from "../../../Assets/aulasMarket/previewLightColor.png"
import VideoColor from "../../../Assets/aulasMarket/previewDarkColor.png"
import pdfIcon from "../../../Assets/aulasMarket/icon-pdf.png"
import quizIcon from "../../../Assets/aulasMarket/colored-quiz-icon.svg"
import LikedOutlined from "../../../Assets/aulasMarket/likeOutlined.svg"
import LikedColored from "../../../Assets/aulasMarket/likeColored.svg"
import { Api } from 'services'
import noImage from "../../../Assets/aulasMarket/no-image.svg"
import { deleteQuestionText, getAttemptQuizData, getQuizResultsAttempted, getVideoResourceSearch, getVideoResourcesData, patchLikeVideoResources, postQaRequest, postUpdateVideoProgress, setMyCourseHome, setParamAttemptQuiz, setPdfViewUrl, setQuizAttempted, setQuizFlowCount, setVideoCompletionStatus } from 'actions/studentMarketPlace'
import QuizDesign from './quizDesign'
import VideoPlayer from './videoPlayer'
import RelatedCoursesCompo from './relatedCoursesCompo'
import CourseResults from './courseResults'
import ViewQuizSolutions from './viewQuizSolutions'
import PageHeader from 'components/pageHeader';
import { CaretDownOutlined, CaretUpOutlined, CheckCircleFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import VideoPlayerContent from './videoContent';
import RemoveModalQA from './removeQaModal';


function VideoResource({ myVideoResources, setQuizAttempted, quizAttempted, setParamAttemptQuiz, paramAttemptQuiz, deleteQuestionText, user, setVideoCompletionStatus, getAttemptQuizData, videoCompletionStatus, getQuizResultsAttempted, questionAnswerData, setPdfViewUrl, getVideoResourceSearch, setQuizFlowCount, patchLikeVideoResources, quizFlowCount, getVideoResourcesData, postQaRequest }) {
    const history = useHistory();
    const { state } = useLocation();
    const itemIdVideoResource = state?.id;
    const [quizTopicsData, setQuizTopicsData] = useState(null);
    const [pageLoader, togglePageLoader] = useState(false);
    const [renderPage, setRenderPage] = useState(0);
    const [searchQa, setSearchQa] = useState({
        id: itemIdVideoResource,
        search: null,
    });

    const [videoData, setVideoData] = useState();

    const [videoUrl, setVideoUrl] = useState();
    const [videoTypeLink,setVideoTypeLink] = useState()
    const inputRef = useRef(null);
    const [topicId, setTopicId] = useState(null);
    const [search, setSearch] = useState();
    const [param, setParam] = useState({
        type: "question",
        // videoResourceId: myVideoResources?.videoResource?.id,
        question: '',
    })
    
    const [visitedItems, setVisitedItems] = useState([]);
    const [attemptedQuizzes, setAttemptedQuizzes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);



    console.log("users", user,questionAnswerData)

    useEffect(() => {
        {
            renderPage ? <></> :
                togglePageLoader(true);
        }
        getVideoResourcesData(itemIdVideoResource, () => { togglePageLoader(false);})
        // setQuizFlowCount(0);
        if(searchQa.search){
            setSearchQa({search:''});
        }
        setQuizAttempted(false);
    }, [renderPage])

    const clearInput = () => {
        setSearch('');
        inputRef?.current?.focus();
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };



    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        setParam((prevParam) => ({
            ...prevParam,
            type: "question",
            videoResourceId: myVideoResources?.videoResource?.id,
            question: value,
        }));
    };

    const handlePost = () => {
        postQaRequest(param, () => {
            togglePageLoader(false);
            clearInput();
            setRenderPage(renderPage + 1);
        });
    }

    const itemsToShow = questionAnswerData?.length;

    const toggleViewAll = () => {
        history.push({
            pathname: "/Q&A",
            state: { id: itemIdVideoResource }
        });
    };

    const PanelList = ({ data, key }) => {
        console.log("dataaassssss", data)
        setQuizTopicsData(data);
        setPdfViewUrl(data?.file)
        const handleQuizFlow = (id) => {
            setVisitedItems(prevVisitedItems => [...prevVisitedItems, id]);
            setParamAttemptQuiz({
                id: id,
                itemId: itemIdVideoResource,
                type: "videoResource"
            });

            if (data?.attempted || quizAttempted) {
                togglePageLoader(true)
                getQuizResultsAttempted(id, itemIdVideoResource, "videoResource", () => { togglePageLoader(false) })
            } else if (!data?.attempted) {
                setQuizFlowCount(1)
            }
        }
        const handlePdfView = (dataFile, id) => {
            setVisitedItems(prevVisitedItems => [...prevVisitedItems, id]);
            setPdfViewUrl(dataFile)

            history.push({
                pathname: "/pdf-view",
                state: {
                    id: id,
                    progress: 100,
                    videoResourceId: itemIdVideoResource,
                    fromVideoResource: true,
                }
            })

        }
        const handleVideoNameClick = (videoFile, topicId, data) => {
            // if (data?.progress !== 100) {
            //     // If the progress is not 100%, prevent navigating to the next item
            //     return;
            // }

            setVisitedItems(prevVisitedItems => [...prevVisitedItems, topicId]);
            setVideoData(data);
            setVideoUrl(videoFile);
            setVideoTypeLink(data?.videoType)
            setQuizFlowCount(0);
            setTopicId(topicId);
        };

        return (
            <div style={{ padding: "8px 5px" }}>
                {data?.type === "video" ? <>
                    <div className='r-c-sb cursor-pointer' onClick={() => { handleVideoNameClick(data?.file?.url, data?.id, data) }} style={{ /* opacity: visitedItems.includes(data?.id) ? 1 : 0.5, */ /* cursor: visitedItems?.includes(data?.id) ? 'pointer' : 'not-allowed' */ cursor: "pointer" }}>
                        {/* <img style={{ width: "30px" }} src={data ? VideoColor : videoGrey} /> */}
                        <img style={{ width: "30px", height: 30 }} src={VideoColor} />
                        <div className='r-c-c-fs' style={{ width: "100%", padding: "2px 10px" }}>
                            <span className='r-c-fs' style={{
                                fontWeight: 600, fontSize: "12px"
                            }}>{data?.name}
                            </span>
                            <Progress
                                className='student-test-progressbar'
                                style={{ width: "100%", left: '0px', right: '50px' }}
                                trailColor=''
                                strokeColor={'#594099'}
                                percent={data?.progress}
                                size={'default'}
                                status="active"
                                format={(percent) => (percent === 100 ? <span style={{ color: '#636363', fontSize: 12 }}>100%</span> : <span style={{ color: '#636363', fontSize: 12 }}>{percent}%</span>)}
                            />
                        </div>
                        {data?.videoType == 'link' ? (<></>) :
                            <div style={{ fontSize: "10px", fontWeight: 600 }}>{data?.duration}</div>}
                    </div>
                </> : null}

                {data?.quiz?.name ? (
                    <div className={`cursor-pointer r-c-fs ${visitedItems.includes(data.id) ? '' : 'disabled-cursor'}`} style={{ width: "100%", padding: "2px 0px" }}
                        onClick={() => handleQuizFlow(data?.quiz?.id)}
                    >
                        <div>
                            <img style={{ width: "30px", marginLeft: "2px" }} src={quizIcon} />
                        </div>
                        <span className='' style={{
                            cursor: "pointer", fontWeight: 600, marginLeft: "10px", fontSize: "12px"
                        }}
                        >{data?.quiz?.name}
                        </span>
                    </div>
                ) : null}


                <div className='r-c-sb'>
                    {data?.type === "notes" ? (
                        <div className={`cursor-pointer ${visitedItems.includes(data.id) ? '' : 'disabled-cursor'}`} onClick={() => handlePdfView(data?.file, data?.id)}><img style={{ width: "30px", marginLeft: "0px" }} src={pdfIcon} />
                            <span className='' style={{
                                cursor: "pointer", fontWeight: 600, marginLeft: "10px", fontSize: "12px"
                            }}
                            >{data?.name}{" "} {data?.progress == 100 ? <CheckCircleFilled style={{ color: "#28DF99" }} /> : null}
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }

    const Review = ({ rList }) => {
        const [likeInfo, setLikeInfo] = useState({
            itemLiked: +rList?.liked,
            itemLikeCount: rList?.likeCount,
        });
        const [editText, setEditText] = useState(false);
        const [reText, setReText] = useState('');
        const [paramText, setParamText] = useState({
            type: "question",
            videoResourceId: myVideoResources?.videoResource?.id,
            id: rList?.id,
            question: '',
        })
        const [answer, setAnswer] = useState('');
        const [answerText, setAnswerText] = useState({
            type: "answer",
            videoResourceId: myVideoResources?.videoResource?.id,
            id: rList?.id,
            answer: '',
        })

        const [isInputTyped, setIsInputTyped] = useState(false);
        const [isEditing, setIsEditing] = useState(false);
        const [deleteModalVisible, setDeleteModalVisible] = useState(false);

        const toggleDeleteModal = () => {
            setDeleteModalVisible(!deleteModalVisible);
        };

          console.log("answer2",answer);

        const handleCancel = () => {
            setAnswer('');
            setIsInputTyped(false)
        };

        const handleLike = (id) => {
            let newLiked = !likeInfo?.itemLiked;
            patchLikeVideoResources(id, myVideoResources?.videoResource?.id, !newLiked, (res) => {
                if (res) {
                    setLikeInfo((prev) => ({
                        ...prev,
                        itemLiked: newLiked,
                        itemLikeCount: res?.likeCount,
                    }));
                }
            });
        };


        const DateDisplay = ({ date }) => {
            const backendDate = new Date(date);
            const timeDifference = Date.now() - backendDate.getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
            const hoursDifference = Math.floor(timeDifference / (1000 * 3600));
            const minutesDifference = Math.floor(timeDifference / (1000 * 60));
            if (daysDifference > 0) {
                return <div>{daysDifference} {daysDifference === 1 ? 'day' : 'days'} ago</div>;
            } else if (hoursDifference > 0) {
                return <div>{hoursDifference} {hoursDifference === 1 ? 'hour' : 'hours'} ago</div>;
            } else if (minutesDifference > 0) {
                return <div>{minutesDifference} {minutesDifference === 1 ? 'minute' : 'minutes'} ago</div>;
            } else {
                return <div>Now</div>;
            }
        };

        const handleEditQuestion = () => {
            setReText(rList?.question)
            setEditText(true);
        }
        const handleReText = (val) => {
            const updatedValue = val.target.value;
            setReText(updatedValue);
            setParamText((prevParam) => ({
                ...prevParam,
                type: "question",
                videoResourceId: myVideoResources?.videoResource?.id,
                question: updatedValue,
            }));
        };

        useEffect(() => {
            setAnswer(rList?.answer)
        }, [rList?.answer])

        const handleInputChange = (e) => {
            const inputValue = e.target.value;
            setAnswer(e.target.value);
            setIsInputTyped(!!inputValue.trim())
            setAnswerText((prevParam) => ({
                ...prevParam,
                type: "answer",
                videoResourceId: myVideoResources?.videoResource?.id,
                answer: inputValue,
            }));
        };


        const handleTextPost = () => {
            postQaRequest(paramText, () => {
                clearInput();
                setRenderPage(renderPage + 1);
            });
        }

        const handleAnswerPost = () => {
            postQaRequest(answerText, () => {
                clearInput();
                setRenderPage(renderPage + 1);
            });
        }
        const handleDeleteQuestion = (id) => {
            deleteQuestionText(id, () => {
                setRenderPage(renderPage + 1);
            });
        }

        const handleEdit = (existingAnswer) => {
            setAnswer(existingAnswer);
        };


        return (
            <>
                <div className='m-t-30' style={{ display: "flex", width: "100%", }}>
                    <div style={{
                        position: " relative",
                    }}>
                        <img style={{ borderRadius: "100%", width: "50px", height: "50px" }} src={`${Api._s3_url}${rList?.image}`} />
                    </div>
                    <div className='m-l-10' style={{ width: "100%" }}>
                        <span className='font-bold' style={{ marginBottom: "20px", fontSize: "14px" }} >
                            {editText ?
                                <div>
                                    <div className='m-t-0'><input ref={inputRef} value={reText} onChange={(e) => handleReText(e)} setSearch={setSearch} style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "16px" }} /></div>
                                    <div className='r-c-fe font-bold m-t-10'>
                                        <div className='m-r-20 cursor-pointer' onClick={() => handleTextPost()} style={{ color: "#594099" }}>POST</div>
                                        <div onClick={() => { setEditText(false) }} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                                    </div>
                                </div>
                                : <>
                                    {rList?.question}
                                    {user?.id == rList?.userId ?
                                        <EditOutlined onClick={() => handleEditQuestion()} style={{ color: "#594099", cursor: "pointer", marginLeft: 10 }} />
                                        : <></>}
                                </>}
                        </span>

                        {user?.role?.includes('Teacher') ? (
                            isEditing ? (
                                <>

                                    <Input
                                        placeholder="Edit your answer"
                                        value={answer}
                                        onChange={(e) => { handleInputChange(e) }}
                                        style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "16px" }}
                                    />

                                    {isInputTyped || answer ? (
                                        <div className='r-c-fe font-bold m-t-10'>
                                            <div className='m-r-20 cursor-pointer' onClick={() => { handleAnswerPost() }} style={{ color: "#594099" }}>POST</div>
                                            <div onClick={() => { handleCancel() }} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                                        </div>
                                    ) : <></>}
                                </>
                            ) : (
                                rList?.answer ? (
                                    <div style={{ fontSize: "14px", paddingTop: "5px", fontSize: "13px" }}>
                                        {rList?.answer}
                                        <span onClick={() => { setIsEditing(true) }}>
                                            <EditOutlined style={{ color: "#594099", cursor: "pointer", marginLeft: 10 }} />
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <Input
                                            placeholder="Post an answer"
                                            value={answer}
                                            onChange={handleInputChange}
                                            style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "16px" }}
                                        />
                                        {isInputTyped ? (
                                            <div className='r-c-fe font-bold m-t-10'>
                                                <div className='m-r-20 cursor-pointer' onClick={() => handleAnswerPost()} style={{ color: "#594099" }}>POST</div>
                                                <div onClick={() => handleCancel()} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                                            </div>
                                        ) : <></>}
                                    </>
                                )
                            )
                        ) : (
                            <div style={{ fontSize: "14px", paddingTop: "5px", fontSize: "13px" }}>{rList?.answer}</div>
                        )}



                        <div className='font-bold' style={{ fontSize: "12px", display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            {rList?.answer != null ? <div key={rList?.id} className='r-c-fs'>

                                {likeInfo?.itemLikeCount}&nbsp;likes

                                {user?.role?.includes('Teacher') ? <></> : <div style={{ display: 'flex', marginLeft: '10px' }} onClick={() => handleLike(rList?.id)}>
                                    <img
                                        src={likeInfo?.itemLiked ? LikedColored : LikedOutlined}
                                        style={{ width: '20px', cursor: 'pointer' }}
                                        alt={likeInfo?.itemLiked ? 'Liked' : 'Not Liked'}
                                    />
                                </div>}

                            </div> : <>
                                {user?.id == rList?.userId ?
                                    <span onClick={() => handleDeleteQuestion(rList?.id)} style={{ color: "#FF414D", fontSize: 12, fontWeight: 'bold', width: "100%", cursor: "pointer" }}>DELETE QUESTION</span>
                                    : <></>}
                            </>}

                            {user?.role?.includes('Teacher') ?
                                // <span onClick={() => toggleDeleteModal} style={{ color: "#FF414D", fontSize: 12, fontWeight: 'bold', width: "100%", cursor: "pointer", marginLeft: 10 }}>DELETE QUESTION</span> : <></>}
                                <span onClick={() => { handleDeleteQuestion(rList?.id)/* ,setIsModalOpen(true) */ }} style={{ color: "#FF414D", fontSize: 12, fontWeight: 'bold', width: "100%", cursor: "pointer", marginLeft: 10 }}>DELETE QUESTION</span> : <></>}

                            {console.log('rList', rList)}
                            {user?.role?.includes('Teacher') ?
                                <div className='r-c-fe' style={{ fontWeight: "lighter", width: "100%" }}>{rList?.username}
                                    <div style={{
                                        width: "4px", height: "4px", borderRadius: "50%",
                                        display: "inline-block",
                                        margin: "0 10px",
                                        backgroundColor: "black",
                                        opacity: 0.4
                                    }}>
                                    </div>
                                    <div><DateDisplay date={rList?.createdAt} /></div>
                                </div>
                                :
                                <div className='r-c-fe' style={{ fontWeight: "lighter", width: "100%" }}>{rList?.username}
                                    <div style={{
                                        width: "4px", height: "4px", borderRadius: "50%",
                                        display: "inline-block",
                                        margin: "0 10px",
                                        backgroundColor: "black",
                                        opacity: 0.4
                                    }}>
                                    </div>
                                    <div><DateDisplay date={rList?.createdAt} /></div>
                                </div>
                            }

                        </div>

                    </div>

                </div>
            </>
        )
    }

   

    const handleSearchQA = (value) => {
        console.log("auttoooo",value)
        const paramSearch={
            id: myVideoResources?.videoResource?.id,
            search: value,
            privileged: user?.role?.includes('Teacher') ? true : false
        }
        setSearchQa(prevState => ({
            ...prevState,
            id: myVideoResources?.videoResource?.id,
            search: value,
            privileged: user?.role?.includes('Teacher') ? true : false
        }));
        getVideoResourceSearch(paramSearch, () => {
            togglePageLoader(false);
        });
        
    }
    const customExpandIcon = ({ isActive }) => (
        <span>{isActive ? <CaretUpOutlined /> : <CaretDownOutlined />}</span>
    );
    return (
        <div className='parent'>
            <div className='parent-compo'>
                <div className='container-1'>
                    <PageHeader title={"Video Courses"} onBackPress={() => { history.goBack(); }} />
                </div>
                <div className='container-3-videoResource'>
                    <>
                        <div className='r-c-sb font-bold text-sm'>
                            <div>{myVideoResources?.videoResource?.name}</div>
                            <div>{myVideoResources?.videoResource?.progress}% Completed</div>
                        </div>
                        {console.log("topicc", quizTopicsData)}
                        <div className='r-c-sb video-parent m-t-10' style={{height:"60vh"}}>
                            {quizFlowCount === 0 ?
                                // <VideoPlayerContent />
                              
                                <VideoPlayer videoUrl={videoUrl} itemIdVideoResource={itemIdVideoResource} videoData={myVideoResources?.videoResource} topicId={topicId} videoDuration={videoData} videoTypeLink={videoTypeLink}/>
                          
                                : (quizFlowCount == 1 ? <QuizDesign quizItems={quizTopicsData} itemIdVideoResource={itemIdVideoResource} /> :
                                    (quizFlowCount == 2 ? <CourseResults /> : (quizFlowCount === 3 ? <ViewQuizSolutions quizItems={quizTopicsData} /> : null)))
                            }
                            <div className='video-resource scroll-bar-universal' style={{}}>
                                {myVideoResources?.videoResource?.sections?.map((i) => {
                                    return (
                                        <Collapse accordion={false}
                                            expandIconPosition="end"
                                            expandIcon={customExpandIcon}
                                            style={{}}

                                        >
                                            {i?.topics?.length > 0 ? (
                                                <Panel header={<div className='r-c-fs m-t-10'>{i?.name}</div>} key="1">
                                                    {i?.topics?.map((child, key) => (
                                                        <div className='panel-list' key={key}>
                                                            <PanelList data={child} key={key} allData={i} />
                                                        </div>
                                                    ))}
                                                </Panel>
                                            ) : <></>}
                                        </Collapse>
                                    )
                                })}
                            </div>
                        </div >
                        <div className='r-c-sb m-t-20'>
                            <div className='font-bold text-sm'>Q&A</div>
                            <div style={{ width: "300px" }}>
                                <AutoComplete className='round-bordered-search' suffixIcon onSearch={handleSearchQA} showSearch={true} style={{ width: "300px", marginRight: 20, height: "35px" }}
                                    placeholder={<span><SearchOutlined /> Search Q&A</span>}
                                    allowClear
                                    value={searchQa?.search}
                                />  
                            </div>
                        </div>
                        <div>
                            {user?.role?.includes('Teacher') ? <></> : <div className='m-t-20'>
                                <Input ref={inputRef} value={search} placeholder={"Couldn't find your question, post it now!"} onChange={(e) => { handleSearch(e), console.log("eeeee", e.target.value) }} style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "16px" }} />
                            </div>}

                            <div className='r-c-fe font-bold m-t-10'>
                                {search ? <>
                                    <div className='m-r-20 cursor-pointer' onClick={() => handlePost()} style={{ color: "#594099" }}>POST</div>
                                    <div onClick={() => {clearInput()}} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                                </>
                                    : <></>}
                            </div>
                        </div>
                        <div className='' style={{ marginBottom: "40px" }}>
                            {Array.isArray(questionAnswerData) ? questionAnswerData.slice(0, itemsToShow).map((item, index) => (
                                <Review key={item?.id} rList={item} />
                            )) : null}


                            {questionAnswerData?.length < 6 ? null : (
                                <div className='r-c-c font-bold m-t-10 cursor-pointer' style={{ color: "#2680EB" }} onClick={() => toggleViewAll()}>
                                    View All
                                </div>
                            )}
                        </div>
                        {user.role.includes('Teacher') ? <></> : <div style={{}} >
                            {myVideoResources?.related?.length > 0 ?
                                <div className='text-sm font-bold'>Related Courses</div>
                                : null}
                            <div className='rela-parent' >
                                {myVideoResources?.related?.map((item, index) => (
                                    <RelatedCoursesCompo
                                        key={index}
                                        index={index}
                                        course={item}
                                        itemType="videoResource"
                                    />
                                ))}
                            </div>
                        </div>}

                    </>
                </div>
            </div>
            {/* <RemoveModalQA 
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}/> */}
            <PageDataLoader visible={pageLoader} />

        </div>
    )
}
const mapStateToProps = (state) => {
    const { myVideoResources, quizFlowCount, questionAnswerData, videoCompletionStatus, paramAttemptQuiz, quizAttempted } = state?.studentMarketPlace
    const { user } = state.session
    return {
        myVideoResources, quizFlowCount, questionAnswerData, videoCompletionStatus, user, paramAttemptQuiz, quizAttempted
    }
};

const mapDispatchToProps = (dispatch) => ({
    getVideoResourcesData: (id, callback) => dispatch(getVideoResourcesData(id, callback)),
    postQaRequest: (param, callback) => dispatch(postQaRequest(param, callback)),
    patchLikeVideoResources: (id, videoResourceId, unlike, callback) => dispatch(patchLikeVideoResources(id, videoResourceId, unlike, callback)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
    getVideoResourceSearch: (param, callback) => dispatch(getVideoResourceSearch(param, callback)),
    setPdfViewUrl: (val) => dispatch(setPdfViewUrl(val)),
    getQuizResultsAttempted: (id, itemId, type, callback) => dispatch(getQuizResultsAttempted(id, itemId, type, callback)),
    setVideoCompletionStatus: (val) => dispatch(setVideoCompletionStatus(val)),
    getAttemptQuizData: (id, itemId, type, callback) => dispatch(getAttemptQuizData(id, itemId, type, callback)),
    deleteQuestionText: (id, callback) => dispatch(deleteQuestionText(id, callback)),
    setParamAttemptQuiz: (val) => dispatch(setParamAttemptQuiz(val)),
    setQuizAttempted: (val) => dispatch(setQuizAttempted(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoResource)
