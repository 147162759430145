import { editSuperAdmin } from 'actions/profile';
import { Modal } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const SaveConfirmation = ({ saveConfirmationModal, onCancel, handleOk,
  fileInputValue, setSaveConfirmationModal }) => {
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);

  const history = useHistory();

  return (
    <Modal
      className="modal-round-corner"
      centered
      visible={saveConfirmationModal}
      onOk={handleOk}
      onCancel={onCancel}
      header={null}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <div
          style={{ fontSize: "24px", color: "#594099", fontWeight: "bold" }}
        >
          Save change(s)
        </div>
        <div
          style={{
            fontSize: "16px",
            color: "#636363",
            fontWeight: "bold",
            marginTop: "10px",
            textAlign: 'center'
          }}
        >
          Do you want to save the change(s) made?
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "30px",
        }}
      >
        <div>
          <Button
            type='primary'
            onClick={onCancel}
            style={{
              borderRadius: "19px",
              width: "120px",
              height: "35px",
              border: "1px solid  #636363",
              backgroundColor: "#FFFFFF",
              color: "#594099",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                color: "#636363",
                fontWeight: "bold",
              }}

            >
              NO
            </div>
          </Button>
        </div>
        <div>
          <Button
            type='primary'
            loading={saveButtonLoader}
            style={{
              borderRadius: "19px",
              width: "120px",
              height: "35px",
              border: "1px solid #594099 ",
              backgroundColor: "#594099",
              fontSize: "16px",
              color: "#FFFFFF",
              fontWeight: "bold",
            }}
            onClick={() => {
              setSaveButtonLoader(true);
              handleOk(onCancel, () => setSaveButtonLoader(false));
            }}
          >
            YES
          </Button>
        </div>
      </div>
    </Modal>
  );
}


const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(SaveConfirmation);
