import moment from "moment";
import { designations } from "./userProfile"
import { useState } from "react";

export const validateEmail = (emailId) => {
    if (!emailId || emailId === "") {
        return false
    }
    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailId?.match(email)) {
        return false
    } else {
        return true
    }
}

export const superAdminProfileDetails = (userProfileDetails, handleInputChange, user, validateData, handleAddrDuplicate, userRowDetails, onCheckChange, privilages) => {
    const isStudent = userProfileDetails?.role?.includes('Student');
    function disabledDate(current) {
        // For students, disable future dates
        if (isStudent) {
            return current && current > moment();
        }
    
        // For others, allow only dates before 14 years from today
        return current && current >= moment().subtract(14, "years");
    }

    

let sameAsCa = false;
const [dummySameAsCa, setDummySameAsCa] = useState(false)
const permanentAddress = userProfileDetails?.user?.basicprofile?.permanentAddress;

if (typeof permanentAddress === "string" && permanentAddress.trim() !== "") {
  try {
    const parsedAddress = JSON.parse(permanentAddress);
    sameAsCa = parsedAddress?.sameAsCa ?? false; 
  } catch (error) {
    console.error("Error parsing permanentAddress:", error);
    sameAsCa = false;
  }
} else if (typeof permanentAddress === "object") {
  sameAsCa = permanentAddress?.sameAsCa ?? false;
}


    return [
        {
            heading: "Basic information",
            fields: [
                {
                    label: "Name", type: "text", value:
                        userProfileDetails?.name, required: true, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "name"),
                    placeholder: "Enter name..."
                },
                isStudent ? null : userRowDetails?.role == 'Student' || userRowDetails?.role == 'Data Operator' || userRowDetails?.role == 'Teacher' || userRowDetails?.role == 'Operation' ? null :
                    {
                        label: "Designation", type: "select",
                        value: userProfileDetails?.designation, required: true, disabled: false,
                        onChange: e => handleInputChange(e, "designation"),
                        options: designations,
                        placeholder: "Select Designation..."
                    },
                {
                    label: "Mobile Number", type: "phone", value: userProfileDetails?.phone, required: user?.privilage?.includes("SuperAdmin") ? true : false,
                    placeholder: "Enter mobile no...",
                    // disabled: true,
                    onChange: e => handleInputChange(e, "phone"),
                },
                {
                    label: "Email ID", type: "text", value: userProfileDetails?.email || '', /* required: user?.privilage?.includes("SuperAdmin") ? true : false */
                    // disabled: true,
                    onChange: e => handleInputChange(e.target.value, "email"),
                    placeholder: "Enter email id...",
                    error: validateEmail(userProfileDetails?.email) ? "Enter valid email id" : null
                },
                // {label: "Access ID", type: "text", 
                //     value: userProfileDetails?.rfid, placeholder: "Enter rfid/bionmetric number...", 
                //     required:false, disabled:false,
                //     onChange:e =>handleInputChange(e.target.value, "rfid"),
                // },
                {
                    label: "Gender", type: "select", value: userProfileDetails?.basicprofile?.gender, required: false, disabled: false,
                    options: ["Male", "Female", "Others"],
                    placeholder: "Select gender...",
                    onChange: e => handleInputChange(e, "basicprofile", "gender"),
                },
                {
                    label: "Blood group", type: "select", value: userProfileDetails?.basicprofile?.bloodGroup === "" || userProfileDetails?.basicprofile?.bloodGroup === "NA" ? undefined : userProfileDetails?.basicprofile?.bloodGroup, required: false, disabled: false,
                    onChange: e => handleInputChange(e, "basicprofile", "bloodGroup"),
                    options: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
                    placeholder: "Select blood group...",
                },
                {
                    label: "Date of birth", type: "date", value: userProfileDetails?.basicprofile?.dob, required: false, disabled: false,
                    onChange: e => handleInputChange(e, "basicprofile", "dob"),
                    placeholder: "Enter dob...",
                    disabledDate: disabledDate
                },
                {
                    label: "User type", type: "text", value: userProfileDetails?.role, required: true, disabled: true,
                    placeholder: "Select role...",
                },
                userRowDetails?.role == 'Student' || isStudent ? null : { label: 'Privilege(s)', type: 'Checkbox', value: privilages, /* required: true, */ disabled: false, onChange: (e) => onCheckChange(e) },
            ]
        },
        {
            heading: "Current address",
            fields: [
                {
                    label: "House no./locality/area", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.h_no || "", required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'h_no')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa || sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'h_no')
                        }
                    },
                    placeholder: "Enter house no./locality/area..."
                },
                {
                    label: "Pincode", type: "number", value: userProfileDetails?.basicprofile?.currentAddress.pincode || "", required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'pincode')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa || sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'pincode')
                        }
                    },
                    placeholder: "Enter pincode...",
                    onFocusChange: (e) => validateData(e.target.value, "basicprofile", "currentAddress", 'pincode'),
                    error: userProfileDetails?.basicprofile?.currentAddress?.pincodeValid ? "Enter valid pincode" : ""
                },
                {
                    label: "State", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.state || "", required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'state')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa || sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'state')
                        }
                    },
                    placeholder: "Enter state..."
                },
                {
                    label: "District", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.city || "", required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'city')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa || sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'city')
                        }
                    },
                    placeholder: "Enter district/city..."
                },
                {
                    label: "Country", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.country || "", required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'country')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa || sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'country')
                        }
                    },
                    placeholder: "Enter country..."
                },
            ]
        },
        {
            heading: "Permanent address",
            checkbox: {

                text: "Same as current address",
                value: dummySameAsCa ? false : sameAsCa,
                action: (e) => {handleAddrDuplicate(e.target.checked,userProfileDetails); if(dummySameAsCa) setDummySameAsCa(false) }
            },
            fields: [
                {
                    label: "House no./locality/area", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.h_no || "", required: false, disabled: false,
                    onChange: (e) =>{ handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'h_no'); 
                        if(sameAsCa ) setDummySameAsCa(true)
                    },
                    placeholder: "Enter house no./locality/area..."
                },
                {
                    label: "Pincode", type: "number", value: userProfileDetails?.basicprofile?.permanentAddress.pincode || "", required: false, disabled: false,
                    onChange: (e) => {handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'pincode');  if(sameAsCa ) setDummySameAsCa(true)},
                    onFocusChange: (e) => validateData(e.target.value, "basicprofile", "permanentAddress", 'pincode'),
                    placeholder: "Enter pincode...",
                    error: userProfileDetails?.basicprofile?.permanentAddress?.pincodeValid ? "Enter valid pincode" : ""
                },
                {
                    label: "State", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.state || "", required: false, disabled: false,
                    onChange: (e) => {handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'state');  if(sameAsCa ) setDummySameAsCa(true)},
                    placeholder: "Enter state..."
                },
                {
                    label: "District", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.city || "", required: false, disabled: false,
                    onChange: (e) => {handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'city');  if(sameAsCa ) setDummySameAsCa(true)},
                    placeholder: "Enter district/city..."
                },
                {
                    label: "Country", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.country || "", required: false, disabled: false,
                    onChange: (e) => {handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'country');  if(sameAsCa ) setDummySameAsCa(true)},
                    placeholder: "Enter country..."
                },
            ]
        },
        {
            heading: "Emergency contact",
            fields: [
                {
                    label: "Name", type: "text", value: userProfileDetails?.basicprofile?.emergencyContactName === 'NA' || userProfileDetails?.basicprofile?.emergencyContactName === null ? '' : userProfileDetails?.basicprofile?.emergencyContactName, required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "emergencyContactName"),
                    placeholder: "Enter name..."
                },
                {
                    label: "Mobile Number", type: "phone", value: userProfileDetails?.basicprofile?.emergencyPhone, required: false, disabled: false,
                    onChange: e => handleInputChange(e, "basicprofile", "emergencyPhone"),
                    placeholder: "Enter mobile no..."
                },
                {
                    label: "Relationship", type: "select", value:((userProfileDetails?.basicprofile?.emergencyContactName == 'NA' || userProfileDetails?.basicprofile?.emergencyContactName == '') || (userProfileDetails?.basicprofile?.emergencyPhone == 'NA' || userProfileDetails?.basicprofile?.emergencyPhone == undefined || userProfileDetails?.basicprofile?.emergencyPhone == '' || userProfileDetails?.basicprofile?.emergencyPhone == null) ? null : userProfileDetails?.basicprofile?.relationship) , required: false, disabled: ((userProfileDetails?.basicprofile?.emergencyContactName == 'NA' || userProfileDetails?.basicprofile?.emergencyContactName == '') || (userProfileDetails?.basicprofile?.emergencyPhone == 'NA' || userProfileDetails?.basicprofile?.emergencyPhone == undefined || userProfileDetails?.basicprofile?.emergencyPhone == '' || userProfileDetails?.basicprofile?.emergencyPhone == null)  ) ,
                    onChange: (value) => handleInputChange(value, "basicprofile", "relationship"),
                    options: ["Father", "Mother", "Uncle", "Aunt", "Brother", "Sister", "Others"],
                    placeholder: "Select relation..."
                },

            ]
        },

    ]
    
}



export const otherUserProfileDetails = (userProfileDetails, handleInputChange, user, validateData, handleAddrDuplicate) => {

    function disabledDate(current) {
        // Can not select days before today and today
        return !(
            current &&
            current < moment().subtract(user?.role?.includes('Student') ? 6 : 14, "years")
        );
    }

    let sameAsCa = false;
const permanentAddress = userProfileDetails?.user?.basicprofile?.permanentAddress;

if (typeof permanentAddress === "string" && permanentAddress.trim() !== "") {
  try {
    const parsedAddress = JSON.parse(permanentAddress);
    sameAsCa = parsedAddress?.sameAsCa ?? false; 
  } catch (error) {
    console.error("Error parsing permanentAddress:", error);
    sameAsCa = false;
  }
} else if (typeof permanentAddress === "object") {
  sameAsCa = permanentAddress?.sameAsCa ?? false;
}

    return [
        {
            heading: "Basic information",
            fields: [
                {
                    label: "Name", type: "text", value:
                        userProfileDetails?.name, required: true, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "name"),
                    placeholder: "Enter name..."
                },
                {
                    label: "User type", type: "text", value: userProfileDetails?.role, required: true, disabled: true,
                    placeholder: "Select role...",
                },
                {
                    label: "Mobile Number", type: "phone", value: userProfileDetails?.phone, required: user?.privilage?.includes("SuperAdmin") ? true : false,
                    placeholder: "Enter mobile no...",
                    disabled: true,
                    onChange: e => handleInputChange(e, "phone"),
                },
                {
                    label: "Email ID", type: "text", value: userProfileDetails?.email || '', /* required: user?.privilage?.includes("SuperAdmin") ? true : false, */
                    onChange: e => handleInputChange(e.target.value, "email"),
                    placeholder: "Enter email id...",
                    disabled: true,
                    error: false ? "Enter valid email id" : null
                },
                {
                    label: "Blood group", type: "select", value: userProfileDetails?.basicprofile?.bloodGroup === "" || userProfileDetails?.basicprofile?.bloodGroup === "NA" ? null : userProfileDetails?.basicprofile?.bloodGroup, required: false, disabled: false,
                    onChange: e => handleInputChange(e, "basicprofile", "bloodGroup"),
                    options: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
                    placeholder: "Select blood group...",
                    space: 0.5
                },
                {
                    label: "Gender", type: "select", value: userProfileDetails?.basicprofile?.gender, required: false, disabled: false,
                    options: ["Male", "Female", "Others"],
                    placeholder: "Select gender...",
                    onChange: e => handleInputChange(e, "basicprofile", "gender"),
                    space: 0.5
                },

                {
                    label: "Date of birth", type: "date", value: userProfileDetails?.basicprofile?.dob, required: false, disabled: false,
                    onChange: e => handleInputChange(e, "basicprofile", "dob"),
                    placeholder: "Enter dob...",
                    disabledDate: disabledDate
                },
                userProfileDetails?.privilage?.length ? { label: "Privilege(s)", type: "bullet", value: userProfileDetails?.privilage, /* required: true, */ disabled: true, onChange: {} } : undefined,
            ]
        },
        {
            heading: "Current address",
            fields: [
                {
                    label: "House no./locality/area", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.h_no || '', required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'h_no')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'h_no')
                        }
                    },
                    placeholder: "Enter house no./locality/area..."
                },
                {
                    label: "Pincode", type: "number", value: userProfileDetails?.basicprofile?.currentAddress.pincode || '', required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'pincode')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'pincode')
                        }
                    },
                    placeholder: "Enter pincode...",
                    onFocusChange: (e) => validateData(e.target.value, "basicprofile", "currentAddress", 'pincode'),
                    error: userProfileDetails?.basicprofile?.currentAddress?.pincodeValid ? "Enter valid pincode" : ""
                },
                {
                    label: "State", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.state || '', required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'state')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'state')
                        }
                    },
                    placeholder: "Enter state..."
                },
                {
                    label: "District", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.city || '', required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'city')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'city')
                        }
                    },
                    placeholder: "Enter district/city..."
                },
                {
                    label: "Country", type: "text", value: userProfileDetails?.basicprofile?.currentAddress.country || '', required: false, disabled: false,
                    onChange: e => {
                        handleInputChange(e.target.value, "basicprofile", "currentAddress", 'country')
                        if (userProfileDetails?.basicprofile?.permanentAddress?.sameAsCa) {
                            handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'country')
                        }
                    },
                    placeholder: "Enter country..."
                },
            ]
        },
        {
            heading: "Permanent address",
            checkbox: {
                text: "Same as current address",
                value:sameAsCa,
                action: (e) => handleAddrDuplicate(e.target.checked,userProfileDetails)
            },
            fields: [
                {
                    label: "House no./locality/area", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.h_no || '', required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'h_no'),
                    placeholder: "Enter house no./locality/area..."
                },
                {
                    label: "Pincode", type: "number", value: userProfileDetails?.basicprofile?.permanentAddress.pincode || '', required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'pincode'),
                    onFocusChange: (e) => validateData(e.target.value, "basicprofile", "permanentAddress", 'pincode'),
                    placeholder: "Enter pincode...",
                    error: userProfileDetails?.basicprofile?.permanentAddress?.pincodeValid ? "Enter valid pincode" : ""
                },
                {
                    label: "State", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.state || '', required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'state'),
                    placeholder: "Enter state..."
                },
                {
                    label: "District", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.city || '', required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'city'),
                    placeholder: "Enter district/city..."
                },
                {
                    label: "Country", type: "text", value: userProfileDetails?.basicprofile?.permanentAddress.country || '', required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "permanentAddress", 'country'),
                    placeholder: "Enter country..."
                },
            ]
        },
        {
            heading: "Emergency contact",
            fields: [
                {
                    label: "Name", type: "text", value: userProfileDetails?.basicprofile?.emergencyContactName === 'NA' || userProfileDetails?.basicprofile?.emergencyContactName === null ? '' : userProfileDetails?.basicprofile?.emergencyContactName, required: false, disabled: false,
                    onChange: e => handleInputChange(e.target.value, "basicprofile", "emergencyContactName"),
                    placeholder: "Enter name..."
                },
                {
                    label: "Mobile Number", type: "phone", value: userProfileDetails?.basicprofile?.emergencyPhone, required: false, disabled: false,
                    onChange: e => handleInputChange(e, "basicprofile", "emergencyPhone"),
                    placeholder: "Enter mobile no..."
                },
                {
                    label: "Relationship", type: "select", value: userProfileDetails?.basicprofile?.relationship, required: false, disabled: false,
                    onChange: (value) => handleInputChange(value, "basicprofile", "relationship"),
                    options: ["Father", "Mother", "Uncle", "Aunt", "Brother", "Sister", "Others"],
                    placeholder: "Select relation..."
                },

            ]
        },

    ]
}