import React, { useEffect, useState } from "react";
import { Title, SmallHeading, SubHeading } from "components/Typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { connect } from "react-redux";
import Table from "./table";
import { getTransactions, toggleFeeSearchLoader } from "actions/fees";
import { Button, Input } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserDetailModal from "./userDetailModal";
import LoadingModal from "components/LoadingModal";

const Transactions = ({
  feeLoader,
  tableName,
  allTransactions,
  getTransactions,
  history,
  feeSearchLoader,
  toggleFeeSearchLoader,
  showUserDetailModal,
}) => {
  const [query, setQuery] = useState("");
  useEffect(() => {
    getTransactions();
  }, []);
  return (
    <>
      <Row align="middle" justify="space-between">
        <Col flex="auto">
          <Row align="middle">
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => history.goBack()}
            ></Button>
            <Title
              className="m-0 m-l-20"
              bold
            >{`${tableName} (${allTransactions?.length})`}</Title>
          </Row>
        </Col>
        <Col xs={6}>
          <Input.Search
            placeholder="Student Name / Phone No...."
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value == "") {
                getTransactions("");
                toggleFeeSearchLoader(true);
              }
            }}
            value={query}
            onSearch={() => {
              getTransactions(query);
              toggleFeeSearchLoader(true);
            }}
            allowClear
            loading={feeSearchLoader}
          />
        </Col>
      </Row>
      <Row className="m-t-20">
        <Col flex="auto">
          <Table dataSource={allTransactions} pagination={false} />
        </Col>
      </Row>
      <UserDetailModal visible={showUserDetailModal} />
      <LoadingModal visible={feeLoader} />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    allTransactions,
    tableName,
    feeSearchLoader,
    showUserDetailModal,
    feeLoader,
  } = state.fees;
  return {
    feeLoader,
    showUserDetailModal,
    feeSearchLoader,
    tableName,
    allTransactions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleFeeSearchLoader: (value) => dispatch(toggleFeeSearchLoader(value)),
  getTransactions: (query) => dispatch(getTransactions(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
