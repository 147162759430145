import { loadImage as loadImageUtil } from "./utils";

class ImageManager {
    constructor(){
        this.imageCache = new Map();
        this.loadImage = this.loadImage.bind(this);
        this.isImageLoaded = this.isImageLoaded.bind(this);
        this.loadAllImages = this.loadAllImages.bind(this);
    }
    // checks if the image is loaded
    isImagePresent(imgurl){
        return this.imageCache.has(imgurl);
    }
    // returns the loaded image
    getImage(imgurl){
        return this.imageCache.get(imgurl);
    }
    //stores the loaded image
    storeImage(imgurl, img){
        this.imageCache.set(imgurl, img);
    }
    // loads an image
    loadImage(imgurl){
        return new Promise((resolve,  reject)=>{
            loadImageUtil(imgurl).then((img)=>{
                this.storeImage(imgurl, img);
                resolve(img);
            }).catch((e)=>{
                reject(e);
            })
        })
    }
    // resolves only if all the images are loaded
    loadAllImages(images){
        return new Promise((resolve, reject)=>{
            if (images && Array.isArray(images) && images.length){
                const PromiseIterable = images?.map((item)=>this.isImageLoaded(item))
                Promise.allSettled(PromiseIterable).then((values)=>{
                    resolve();
                  }).catch((e)=>{
                    reject(e)
                  })
            }else{
                reject(new Error('invalid argument for images parameter'));
            }
        });
    }
    // resolves if an image is loaded if not it resolves after the image is loaded
    isImageLoaded(imgurl) {
        return new Promise((resolve, reject)=>{
          if (this.isImagePresent(imgurl)){
            resolve();
          }else{
            this.loadImage(imgurl).then(img => {
              resolve();
            }).catch((e)=>{
              reject();
            }); 
          }
        })
      }
    // generates an imgURL given a canvas
    generateImageFromCanvas(canvas){
      return new Promise((resolve, reject)=>{
        canvas.toBlob((blob)=>{
          if (blob) {
            const imgURL = URL.createObjectURL(blob);
            resolve(imgURL)
          } else {
            reject(false);
          }
        })
      })
    }
    async renderImageOnCanvas(img, canvas){
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
    
      return new Promise((resolve, reject)=>{
          canvas.toBlob((blob)=>{
            if (blob) {
              const imgURL = URL.createObjectURL(blob);
              resolve(imgURL)
            } else {
              reject();
            }
          })
      })
    }
    async renderEmptyFrameOnCanvas(canvas){
      canvas.width = 100;
      canvas.height = 100;
      const ctx = canvas.getContext('2d');
      ctx.strokeRect(0, 0, 100, 100);

      return new Promise((resolve, reject)=>{
          canvas.toBlob((blob)=>{
            if (blob) {
              const imgURL = URL.createObjectURL(blob);
              resolve(imgURL)
            } else {
              reject();
            }
          })
      })
    }
}

export default new ImageManager();

