import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Input } from "antd";
import React, { useEffect, useState } from "react";

function LocationModal({
  deletePicLoader,
  handleOk,
  onCancel,
  onRemove,
  onChange,
  disabled,
  name,
  locationData,
  onAdd
}) {
     const [address, setAddress] = useState(name);
     const [location, setLocation] = useState(locationData);
     useEffect(()=>{
        setLocation(locationData)
     }, [locationData])
     useEffect(()=>{
        setAddress(name)
     }, [name])
  return (
    <div className='r-c-c'>
            <div>
                    <span className="m-b-5 m-r-5">Address Name<span style={{ color: 'red' }}>*</span></span>
                    <Input
                        placeholder='Enter name'
                        style={{ width: '95%' }}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
            </div>
            <div>
                    <span className="m-b-5 m-r-5">Location<span style={{ color: 'red' }}>*</span></span>
                    <Input
                        placeholder='Enter address'
                        style={{ width: '95%', }}
                        value={location}
                        onChange={(e)=>{setLocation(e.target.value)}}
                    />
            </div>
            {/* <center>
                <Button loading={false} onClick={()=>{onAdd(address, location)}} disabled={address==undefined||location==undefined||address?.trim()==''||location?.trim()==''} type='link' style={{ opacity:address==undefined||location==undefined||address?.trim()==''||location?.trim()==''?0.4:1, fontSize: 14, marginTop: 20, color: '#1089FF',fontWeight:"bold" }}>ADD</Button>
            </center> */}
    </div>
  );
}

export default LocationModal;
