import { Typography } from 'antd'
import { SubIcon } from 'components/subIcon'
import moment from 'moment'
import React from 'react'
import { Api } from 'services'
const LabOffline = require("../../../../Assets/scheduleAttendance/lab-offline.svg").default
const Hat = require("../../../../Assets/scheduleAttendance/Group 11910.svg").default
const Clock = require("../../../../Assets/scheduleAttendance/Group 12077.svg").default

const SubjectCardDisplay = ({ data }) => {
  let startTime = moment(data?.startTime, ["h:mm A"]).format('HH:mm A')
  let endTime = moment(data?.endTime, ["h:mm A"]).format('HH:mm A')
  return (
    <div className='attendance-subjectCard'>
      <div style={{ paddingLeft: '15px' }}>
        <div style={{ display: 'flex' }}>
          {data?.subject?.icon ?
            <img style={{ height: '60px', width: '60px' }} src={`${Api._s3_url}${data?.subject?.icon}`}></img>
            :
            <SubIcon name={data?.subject?.name} size={60} />
          }
          <div style={{ padding: '0px 20px' }}>
            <div><img src={LabOffline}></img></div>
            <div style={{ width: 200 }}>
              <Typography.Text ellipsis={{ tooltip: true }} style={{ fontWeight: 'bold', paddingTop: '5px', width: '100%' }}>{data?.subject?.name}</Typography.Text>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px', fontSize: '13px' }}>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ flex: 1 }}>
            <span><img src={Hat} alt="Hat Icon" /></span> {data?.standards?.length ? data?.standards[0] : null}
          </Typography.Text>
          <Typography.Text ellipsis={{ tooltip: true }}>
            <span><img src={Clock} alt="Clock Icon" /></span> {startTime} - {endTime}
          </Typography.Text>
        </div>
        <div style={{ paddingTop: '10px' }}>
          <div style={{ display: 'flex' }}><img style={{ height: '20px', width: '20px', borderRadius: '50%' }} src={`${Api._s3_url}${data?.teacher?.image}`} /><div style={{ paddingLeft: '8px', fontSize: '13px' }}>{data?.teacher?.name}</div></div>
        </div>
      </div>
    </div>
  )
}

export default SubjectCardDisplay