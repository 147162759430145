import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import { Radio, TreeSelect } from "antd"
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import {
  showTextEditor, updateSubWithChapters, addMarksForQues, chooseCorrectAnswer, storeTestQuesType,
  postQuestionCreate, clearGenerateQuesFields, getEachQuesData, deleteQuesFromServer,
  showGenerateQuesListView,
  unsetCreateTestModalBool,
  updateNumericAnswer,
  renderEditorContent,
  updatePostParams,
  setSelectedSectionId,
  chooseBoolAnswer,
  updateQuestionType,
  storeQuestionIndex,
  storeSectionIndex,
  createAdditionalQuestion,
  storeSchemeId,
  getMarkingSchemeMarks,
  setImportMeta,
  modifyOptions,
  setLoader,
  setStartTimeTest
} from 'actions/testAdmin';
import Notification from "services/Notification";
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdFormatListBulleted, MdCloudDownload, MdMoreVert } from "react-icons/md";
import {
  AiOutlineDownload, AiOutlinePlus, AiOutlineMore, AiFillCaretDown, AiOutlineMinus, AiOutlinePlusCircle,
  AiFillDelete, AiFillCopy
} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { ExclamationCircleOutlined, LeftOutlined, LoadingOutlined, MessageOutlined, RightOutlined, ClearOutlined } from '@ant-design/icons';
import './styles.css';
import { Color, Utils } from 'services';
import ReactHtmlParser from 'react-html-parser';
import { Col, Row, Typography, Select, Menu, Dropdown, Input } from 'antd';
import TopNavigationColorHeader from './localComponent/topNavigationColorHeader';
import TextEditorModal from './localComponent/textEditorModal';
import TestPublishModal from './localComponent/testPublishModal';
import CreateTestModal from './modals/createTestModal';
import QueGridSide from './localComponent/queGridSide';
import { Tooltip } from 'antd';
import TeX from '@matejmazur/react-katex';
import ImportQuestionModal from './modals/importQuestionModal';
import DeleteQuesModal from './modals/deleteQuesModal';
import notification from 'pages/notification/notification';
import QuesAnsContainer from './quesAnsContainer';
import { useLocation } from 'react-router';
import { options } from 'less';
import Loader from './localComponent/loader';
import ImageViewer from 'components/ImageViewer';
import moment from 'moment';


const arrowDown = require('../../Assets/test-new/ic-chevron-down.svg').default;
const arrowUp = require('../../Assets/test-new/ic-chevron-up.svg').default;

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const TestDetails = ({ value, text }) => {
  return (
    <div className='r-c-c-c m-r-50'>
      <div className='text-xmd bold-600'>{value}</div>
      <div className='text-xs'>{text}</div>
    </div>
  )
}

const CustomeDropdown = ({ defaultvalue, placeholder, onchange, arrdata, disable = false, treeExpandAction }) => {
  return (

    <TreeSelect
      className='test-custom-select'
      disabled={disable}
      style={{ width: 200 }}
      value={defaultvalue}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={arrdata}
      placeholder={placeholder}
      treeDefaultExpandAll
      onChange={onchange}
      treeExpandAction={treeExpandAction}
    />
  )
}

const Row1 = ({ history, generateQuesObj, generateQuesArr, showGenerateQuesListView, showTextEditor,
  generateCommentText, userType, grabAllfield, isEmptyHtml, editGenerateQueId, openImportModal, user, selectedTest, setImportMeta }) => {
  let queCount = 0;
  for (const subject of generateQuesArr?.subjects) {
    if (subject.includeSectionFlag) {
      for (const section of subject?.sections) {
        queCount += section?.questions?.length ?? 0;
      }
    } else {
      queCount += subject?.questions?.length ?? 0;
    }
  }

  let totalPoints = Object.keys(generateQuesArr)?.length ? generateQuesArr?.totalPoints : 0;
  console.log('queCount', queCount)
  return (
    <div className='r-c-sb p-20'>
      <div className='r-ac'>
        <TestDetails value={queCount} text={queCount === 1 ? 'Question' : 'Questions'} />
        <TestDetails value={`${generateQuesObj.duration / 60} mins`} text={`Duration`} />
        <TestDetails value={totalPoints} text={`Marks`} />
      </div>
      <div className='r-ac'>
        {
          editGenerateQueId != undefined && editGenerateQueId !== '' ?
             (selectedTest.status === 'Review' && userType === 'admin' && user.id === selectedTest.teacherId && user.id !== selectedTest.authorId)
              ?
              (isEmptyHtml(generateCommentText))
                ?
                <div onClick={() => {
                  showTextEditor({ key: 'comment', index: 0, tags: generateCommentText })
                  grabAllfield("savecomment")
                }} className='r-c-c-c m-r-20 cursor-pointer'>
                  <MessageOutlined className='text-md color-black' />
                  <div className='text-xs text-center color-black'>Comment</div>
                </div>
                :
                <div onClick={() => {
                  showTextEditor({ key: 'comment', index: 0, tags: generateCommentText })
                  grabAllfield("savecomment")
                }} className='r-c-c-c m-r-20 cursor-pointer'>
                  <MessageOutlined className='text-md' style={{ color: "#FFA931" }} />
                  <div className='text-xs text-center' style={{ color: "#FFA931" }}>Comment</div>
                </div>
              :
              (isEmptyHtml(generateCommentText))
                ?
                null
                :
                <div onClick={() => {
                  showTextEditor({ key: 'comment', index: 0, tags: generateCommentText })
                  grabAllfield("savecomment")
                }} className='r-c-c-c m-r-20 cursor-pointer'>
                  <MessageOutlined className='text-md' style={{ color: "#FFA931" }} />
                  <div className='text-xs text-center' style={{ color: "#FFA931" }}>Comment</div>
                </div>
            :
            null
        }
        <div onClick={() => { showGenerateQuesListView(() => { }, "listview"); history.push('test-generate-questions-list') }} className='r-c-c-c m-r-20 cursor-pointer'>
          <MdFormatListBulleted className='text-md color-black' />
          <div className='text-xs text-center color-black'>List View</div>
        </div>
        <div className='r-c-c-c cursor-pointer' /* onClick={openImportModal} */
          onClick={() => { setImportMeta("selectedQBType", "subject-wise question papers"); setImportMeta('selectedTypeId', 2); history.push("/test-import-classes") }}>
          <AiOutlineDownload className='text-md' />
          <div className='text-xs text-center' >Import</div>
        </div>
      </div>
    </div>
  )
}

const RowSub = ({ generateQuesObj, generateQuesSubChapters, updateSubWithChapters, clearGenerateQuesFields,
  deleteQuesFromServer, editGenerateQueId, setDeleteModalVisible, setDeleteLoader, setSelectedSectionId, storeSchemeId, storeSubjectSection }) => {
  return (
    <div className='r-ac' style={{ maxWidth: "100%", overflowX: "auto", display: 'flex' }}>
      {generateQuesObj?.subjects?.map((item) => (
        <div key={item?.subjId}
          onClick={() => {
            console.log('itemSub', item)
            if (generateQuesObj?.subjects && generateQuesObj?.subjects?.length > 0) {
              clearGenerateQuesFields();
              updateSubWithChapters(item?.subName);
              setSelectedSectionId(item?.sections?.length > 0 ? item?.sections[0].sectionId : null)
              if (item?.includeSectionFlag === 0) {
                storeSchemeId(item?.schemeId)
              }
            }
          }}
          style={{ cursor: 'pointer', width: '100%', textAlign: 'center', backgroundColor: (item.subName === generateQuesSubChapters.name) ? '#F6F4FE' : '#FFFFFF', color: item.subName === generateQuesSubChapters.name ? '#191919' : '#AEAEAE', height: '30px', fontWeight: '500', padding: '5px' }}>
          {item.subName}
        </div>
      ))}
    </div>
  )
}



const Row2 = ({ generateQuesObj, generateQuesSubChapters, updateSubWithChapters, clearGenerateQuesFields,
  deleteQuesFromServer, editGenerateQueId, setDeleteModalVisible, setDeleteLoader, selectedSectionId, setSelectedSectionId, storeSectionIndex, storeSchemeId }) => {
  const menu = (
    <Menu>
      {editGenerateQueId == '' || editGenerateQueId === null || editGenerateQueId === undefined ? null :
        <Menu.Item key="0" className="m-b-10">
          <div className='r-ac cursor-pointer p-l-20 p-r-20'
            // onClick={editGenerateQueId === '' ? () => {Notification.error("error", "Cannot delete questions that are not saved")} : () => deleteQuesFromServer()}
            onClick={() => { setDeleteModalVisible(true); setDeleteLoader(false) }}
          >
            <AiFillDelete className='text-md' />
            <div className='text-xs color-black m-l-10'>Delete</div>
          </div>
        </Menu.Item>
      }
      <Menu.Item key="1">
        <div className='r-ac cursor-pointer p-l-20 p-r-20' onClick={() => { clearGenerateQuesFields('Clear'); }}>
          <ClearOutlined className='text-md' />
          <div className='text-xs color-black m-l-10'>Clear</div>
        </div>
      </Menu.Item>
    </Menu>
  );


  const subject = generateQuesObj.subjects.find((subject) => (generateQuesSubChapters.id === subject.subjId));
  const sections = subject?.includeSectionFlag
    ? (
      subject?.sections?.map((sec, index) => (
        <Button key={sec?.sectionId} onClick={() => {
          if (generateQuesObj?.subjects && generateQuesObj?.subjects?.length) {
            clearGenerateQuesFields();
            updateSubWithChapters(subject?.subName);
            setSelectedSectionId(sec?.sectionId)
            storeSectionIndex(index)
            storeSchemeId(sec?.schemeId)
          }
        }}
          className={sec.sectionId === selectedSectionId ? 'purpleBtnWrap m-r-20 text-xs' : 'blankGreyBtnWrap m-r-20 text-xs'}
          style={{ cursor: (generateQuesObj.subjects && generateQuesObj.subjects?.length > 1) ? 'pointer' : 'auto' }}>
          {sec.sectionName}
        </Button>
      )
      )) : null
  return (
    <div className='r-c-sb p-20'>
      <div className='r-ac' style={{ maxWidth: "100%", overflowX: "auto" }}>
        {sections}
      </div>
      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <MdMoreVert className='text-lg a2grey cursor-pointer' />
      </Dropdown>
    </div>
  )
}

const Row3 = ({ generateQuesObj, generateQuesSubChapters, addMarksForQues, quesPositiveMark, quesNegativeMark, setLoader, loaders,
  storeTestQuesType, generateQuesSelectedTestType, generateQuesSelectedChapter, updateQuestionType, getMarkingSchemeMarks, selectedSectionId, schemeId, compQuestionIndicator }) => {

  const chapHandler = (value) => {
    let chapData = generateQuesSubChapters?.chapters?.filter(chap => chap.value === value)
    console.log('chapDara', chapData)
    storeTestQuesType({ key: chapData[0].id, value: chapData[0].id, children: chapData[0].value }, chapData[0].id, 'quesChapter')
    console.log('testing', chapData)
  }

  const questionTypeList = [{
    title: 'Multiple Choice',
    value: '0-0',
    selectable: false,
    children: [
      {
        title: 'One Correct Answer',
        value: 'MCQ',
        selectable: true,
      }, {
        title: 'Multiple Correct Answer',
        value: 'MSQ',
        selectable: true,
      }
    ],
  },
  {
    title: 'Numeric',
    value: 'Numeric',
  },
  {
    title: 'Comprehensive',
    value: '0-2',
    selectable: false,
    children: [
      {
        title: 'Comp-One Correct Answer',
        value: 'CMCQ',
        selectable: true,
      }, {
        title: 'Comp-Multiple Correct Answer',
        value: 'CMSQ',
        selectable: true,
      }, {
        title: 'Comp-Numeric',
        value: 'CNumeric',
        selectable: true,
      }, {
        title: 'Comp-True/False',
        value: 'CTF',
        selectable: true,
      }
    ]
  }, {
    title: 'True/False',
    value: 'T/F',
    selectable: true,
  }]

  let modifiedQuestionList = []

  generateQuesObj?.subjects?.map((item) => {
    if (item.subName === generateQuesSubChapters.name) {
      let typeOfQuestionArr;
      if (item?.includeSectionFlag === 0) {
        typeOfQuestionArr = item.typeOfQuestion
      } else {
        typeOfQuestionArr = item.sections.find(section => section.sectionId === selectedSectionId)?.typeOfQuestion;
      }
      // if(item?.includeSectionFlag === 0){
      console.log('arrr', typeOfQuestionArr)
      if (typeOfQuestionArr?.length > 0) {
        questionTypeList.forEach((el) => {
          let type;
          const children = [];
          if (el.children) {
            el.children.forEach(baby => {
              if (typeOfQuestionArr.includes(baby.value)) {
                children.push(baby);
              }
            })
            if (children?.length > 0) {
              type = { ...el, children }
            }
          } else {
            if (typeOfQuestionArr.includes(el.value)) {
              type = el;
            }
          }
          if (type) {
            modifiedQuestionList.push(type);
          }
        })
      }
    }
  })

  console.log('moddididid', modifiedQuestionList)
  console.log('schemeId', schemeId)
  
  return (
    <div className='p-l-20 p-r-20 r-c-sb'>
      <div className='r-ac'>
        <div className='m-r-30'>
          <div className='text-xmd bold-600 color-black m-b-10' style={{ color: '#636363' }}>QuestionType</div>
          {generateQuesObj?.testType === 'Objective'
            ?
            <CustomeDropdown
              arrdata={(schemeId > 0 && (modifiedQuestionList !== null && modifiedQuestionList !== undefined)) ? modifiedQuestionList : questionTypeList}
              defaultvalue={Object.keys(generateQuesSelectedTestType)?.length ? generateQuesSelectedTestType.children : 'Select Question Type'}
              onchange={((value) => {
                storeTestQuesType({ 'key': 0, 'value': 0, 'children': value }, null, 'quesType')
                setLoader('updateQuestionLoader', true)
                setTimeout(() => {
                  if (schemeId !== null && schemeId > 0) {
                    getMarkingSchemeMarks(value, (res) => (updateQuestionType(value, res)))
                    setLoader('updateQuestionLoader', false)
                  } else {
                    updateQuestionType(value)
                    setLoader('updateQuestionLoader', false)
                  }
                }, 1500)
              })}
              placeholder={'Select Question Type'}
            />
            :
            <CustomeDropdown
              arrdata={[]}
              disable={true}
              defaultvalue={'Subjective type'}
              onchange={() => { }} />
          }
        </div>
        <div className='m-r-10'>
          <div className='text-xmd bold-600 color-black m-b-10' style={{ color: '#636363' }}>Chapter</div>
          <CustomeDropdown
            arrdata={generateQuesSubChapters?.chapters ? generateQuesSubChapters?.chapters : []}
            disable={generateQuesObj?.syllabus === 'Chapter Test' || !Object.keys(generateQuesSelectedTestType)?.length || loaders?.updateQuestionLoader ? true : false}
            defaultvalue={Object.keys(generateQuesSelectedChapter)?.length ? generateQuesSelectedChapter.children ? generateQuesSelectedChapter.children : generateQuesSelectedChapter.value : 'Select Chapter'}
            onchange={(value) => chapHandler(value)} />
        </div>
      </div>
      {compQuestionIndicator
        ?
        null
        :
        <div>
          <div className='r-ac m-b-10'>
            <div className='text-xmd bold-600 color-black m-r-5' style={{ color: '#636363' }}>Marks</div>
            {
              generateQuesObj.testType == "Objective"
                ?
                <Tooltip placement="right" trigger="hover" title={
                  <div>
                    <div className='r-ac'>
                      <div className='radius-100' style={{ width: 10, height: 10, background: '#6BDB9E' }}></div>
                      <div className='text-xmd color-black m-l-10'>Positive Marks</div>
                    </div>
                    <div className='r-ac'>
                      <div className='radius-100' style={{ width: 10, height: 10, background: '#ec5254' }}></div>
                      <div className='text-xmd color-black m-l-10'>Negative Marks</div>
                    </div>
                  </div>
                } overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px", background: '#fff' }}>
                  <ExclamationCircleOutlined style={{ color: "#1089FF" }} className='text-xs cursor-pointer' />
                </Tooltip>
                :
                null
            }
          </div>
          <div className='r-ac'>
            <div className='r-c-c test-count-seq-box'>
              <AiOutlinePlus onClick={() => schemeId > 0 ? null : addMarksForQues('increment', 'positive')} className='cursor-pointer' />
              <div className='text-xmd m-l-5 m-r-5'>{quesPositiveMark}</div>
              <AiOutlineMinus onClick={() => schemeId > 0 ? null : addMarksForQues('decrement', 'positive')} className='cursor-pointer' />
            </div>
            {generateQuesObj.testType === 'Objective'
              ?
              <div className='r-c-c test-count-seq-box m-l-10' style={{ background: '#FFE4D9', borderColor: '#EC5254' }}>
                <AiOutlinePlus onClick={() => schemeId > 0 ? null : addMarksForQues('increment', 'negative')} className='cursor-pointer' />
                <div className='text-xmd m-l-5 m-r-5'>{quesNegativeMark}</div>
                <AiOutlineMinus onClick={() => schemeId > 0 ? null : addMarksForQues('decrement', 'negative')} className='cursor-pointer' />
              </div>
              :
              null
            }
          </div>
        </div>
      }
    </div>
  )
}

const RowParagraph = ({ showTextEditor, generateParagraphText, QuestionIndex, paragraph, setImgUrl, setImageViewerVisibility }) => {
  return (
    <div className='p-20'>
      <div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {' '} {QuestionIndex}</div>
      <div onClick={() => showTextEditor({ key: 'paragraph', index: 0, tags: paragraph }, null, 'Comp')}
        className='cursor-pointer test-ques-window resize-editor-img p-10'
        style={{ width: "100%", overflowX: "hidden" }}>
        {!generateParagraphText || generateParagraphText === "" || generateParagraphText === '<p><br></p>' ? <div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Paragraph</div>
          : ReactHtmlParser(paragraph,
            {
              transform: (node) => {
                if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                  return <TeX math={`${node.attribs['data-value']}`} />;
                }
                if (node.type === 'tag' && node.name === 'img') {
                  return (<img className='imgDiv' onClick={(e) => {
                    e.stopPropagation()
                    setImgUrl(`${node.attribs.src}`)
                    setImageViewerVisibility(true);
                  }} style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />);
                }
              }
            })
        }
      </div>
    </div>
  )
}

function TestGenerateQuestions({ history, generateQuesObj, showTextEditor, generateQuesSubChapters,
  updateSubWithChapters, addMarksForQues, quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, chooseCorrectAnswer, generateSolutionText, storeTestQuesType,
  generateQuesSelectedTestType, generateQuesSelectedChapter, postQuestionCreate, generateQuesRemovedImgs,
  generateQuesArr, clearGenerateQuesFields, getEachQuesData, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, deleteQuesFromServer, postQuestionCreateBool, showGenerateQuesListView, unsetCreateTestModalBool,
  generateCommentText, userType, updateNumericAnswer, renderEditorContent, numericAnswer, updatePostParams, selectedCardType,
  user, selectedTest, textEditorBool, selectedSectionId, setSelectedSectionId, questionContent, answerContent, chooseBoolAnswer,
  orQuestionIndicator, compQuestionIndicator, updateQuestionType, generateParagraphText, storeQuestionIndex, questionIndexStore,
  storeSectionIndex, sectionIndexStore, createAdditionalQuestion, storeSchemeId, getMarkingSchemeMarks, schemeId, storeSubjectSection,
  setImportMeta, numberOfOptions, modifyOptions, setLoader, loaders ,setStartTimeTest,savetestStartTime}) {

  //   useEffect(() => {
  //     if ( (user?.role?.includes("Data Operator") || user?.role?.includes("Teacher"))) {
  //       const interval = setInterval(() => {
  //         redirectToCorrectPage();
  //       }, 1000);
  
  //       return () => clearInterval(interval);
  //     }
  //   }, []);

  // const redirectToCorrectPage = () => {
  //   const currentTime = moment();
  //   const startTime = moment(savetestStartTime, 'hh:mm a');
  //   const timeDifference = currentTime.diff(startTime, 'minutes');
  //   if (timeDifference >= 15) {
  //     history.push('/admin-test-view');
  //   } else {
  //     history.push('/admin-test');
  //   }
  // };



  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isImportModalVisible, setImportModalVisible] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const closeImportModal = () => {
    setImportModalVisible(false);
  }
  const openImportModal = () => {
    setImportModalVisible(true);
  }

  const handleDeleteOk = () => {
    setDeleteLoader(true)
    if (editGenerateQueId === '') {
      notification.error("error", "Cannot delete questions that are not saved")
    } else {
      deleteQuesFromServer(() => { setDeleteModalVisible(false) })
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const [showEditorModal, setShowEditorModal] = useState(false);
  const isEmptyHtml = (content) => {
    let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
    return pattern.test(content);
  }
  const isAnyOptionEmpty = (index) => isEmptyHtml(questionContent?.options ? questionContent?.options[index]?.value : null);
  const grabAllfield = (type) => {
    let marksValidation = []
    let paragraphValidation = false
    let questionValidation = []
    let correctOptionsValidation = []
    let optionValueCheck = []
    let trueFalseValidation = []
    let numericAnswerCheck = []
    //Comprehensive Check//
    if (questionContent?.subQuestions !== null && questionContent?.subQuestions !== undefined) {
      questionContent?.subQuestions.map((item, index) => {
        //Marks Check//
        if (item?.positiveMarks > 0) {
          marksValidation[index] = true
        } else {
          marksValidation[index] = false
        }
        //Question Check//
        if (item?.questionContent?.statement == null || item?.questionContent?.statement == "" || item?.questionContent?.statement == '<p><br></p>' || item?.questionContent?.statement == undefined) {
          questionValidation[index] = false
        } else {
          questionValidation[index] = true
        }
        //Correct Options Check//
        if (item?.type === 'CMCQ' || item?.type === 'CMSQ' || item?.type === 'MCQ' || item?.type === 'MSQ') {
          if (item?.questionContent?.options?.find((item) => item.correct === true)) {
            correctOptionsValidation[index] = true
          } else {
            correctOptionsValidation[index] = false
          }
        }

        //Options Value Check//
        if (item?.type === 'CMCQ' || item?.type === 'CMSQ' || item?.type === 'MCQ' || item?.type === 'MSQ') {
          if (item?.questionContent?.options?.find((item) => (item.value == null || item.value == '' || item.value == '<p><br></p>' || item.value == undefined))) {
            optionValueCheck[index] = false
          } else {
            optionValueCheck[index] = true
          }
        }


        //True/False Check
        if (item?.type === 'CTF' || item?.type === 'T/F') {
          if (answerContent[index].answer !== null) {
            trueFalseValidation[index] = true
          } else {
            trueFalseValidation[index] = false
          }
        }

        //Numeric Check//
        if (item?.type === 'CNumeric' || item?.type === 'Numeric') {
          if (answerContent[index].answer !== null) {
            numericAnswerCheck[index] = true
          } else {
            numericAnswerCheck[index] = false
          }
        }

      })



      //Paragraph Check//
      if (questionContent?.paragraph == null || questionContent?.paragraph == "" || questionContent?.paragraph == '<p><br></p>') {
        paragraphValidation = false
      } else {
        paragraphValidation = true
      }

    }

    //OrQuestion Check//
    if (questionContent?.orQuestions !== null && questionContent?.orQuestions !== undefined) {
      questionContent?.orQuestions.map((item, index) => {
        //Question Check//
        if (item?.questionContent?.statement == null || item?.questionContent?.statement == "" || item?.questionContent?.statement == '<p><br></p>' || item?.questionContent?.statement == undefined) {
          questionValidation[index] = false
        } else {
          questionValidation[index] = true
        }
        //Correct Options Check//
        if (item?.questionContent?.options !== null || item?.questionContent?.options !== undefined) {
          if (item?.questionContent?.options?.find((item) => item.correct === true)) {
            correctOptionsValidation[index] = true
          } else {
            correctOptionsValidation[index] = false
          }
        }

        //Options Value Check//
        if (item?.type === 'CMCQ' || item?.type === 'CMSQ' || item?.type === 'MCQ' || item?.type === 'MSQ') {
          if (item?.questionContent?.options?.find((item) => (item.value == null || item.value == '' || item.value == '<p><br></p>' || item.value == undefined))) {
            optionValueCheck[index] = false
          } else {
            optionValueCheck[index] = true
          }
        }


        //True/False Check
        if (item?.type === 'CTF' || item?.type === 'T/F') {
          if (answerContent[index].answer !== null) {
            trueFalseValidation[index] = true
          } else {
            trueFalseValidation[index] = false
          }
        }

      })
    }

    let checkTestType = generateQuesObj.testType === 'Subjective';
    let checkQuesType = generateQuesSelectedTestType.children === 'Multiple Choice Questions';
    console.log('111111markssss', marksValidation)
    console.log('111111quesssss', questionValidation)
    console.log('111111t/f', trueFalseValidation)
    console.log('111111MCQQQQ', correctOptionsValidation)
    console.log('11111Optionvalue', optionValueCheck)
    console.log('11111Para', paragraphValidation)
    if (type == "savenext") {
      if (!Object.keys(generateQuesSelectedTestType)?.length && !checkTestType) {
        return Notification.error("Error", 'Please select question type');
      } else if (!Object.keys(generateQuesSelectedChapter)?.length && (generateQuesObj.syllabus !== 'Chapter Test' || generateQuesObj.syllabus !== 'Full Test')) {
        return Notification.error("Error", 'Please select any chapter');
      }
      //OrQuestion//
      else if (questionContent?.orQuestions !== null && questionContent?.orQuestions !== undefined) {
        if (!questionValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please add question');
        }
        if ((generateQuesSelectedTestType?.children === 'MCQ' || generateQuesSelectedTestType?.children === 'MSQ') && !optionValueCheck?.every((item) => item === true)) {
          return Notification.error("Error", 'Option value cannot be empty');
        }
        if ((generateQuesSelectedTestType?.children === 'MCQ' || generateQuesSelectedTestType?.children === 'MSQ') && !correctOptionsValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please select any correct option');
        }
        if ((generateQuesSelectedTestType?.children === 'T/F') && !trueFalseValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please select either True or False');
        }
        if ((generateQuesSelectedTestType?.children === 'CNumeric' || generateQuesSelectedTestType?.children === 'Numeric') && !numericAnswerCheck?.every((item) => item === true)) {
          return Notification.error("Error", 'Please enter valid numeric answer');
        }
      }
      //Comprehensive//
      else if (questionContent?.subQuestions !== null && questionContent?.subQuestions !== undefined) {
        if (!marksValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please add marks for the question');
        }
        if (paragraphValidation === false) {
          return Notification.error("Error", 'Please add paragraph');
        }
        if (!questionValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please add question');
        }
        if ((generateQuesSelectedTestType?.children === 'MCQ' || generateQuesSelectedTestType?.children === 'CMCQ' || generateQuesSelectedTestType?.children === 'MSQ' || generateQuesSelectedTestType?.children === 'CMSQ') && !optionValueCheck?.every((item) => item === true)) {
          return Notification.error("Error", 'Option value cannot be empty');
        }
        if ((generateQuesSelectedTestType?.children === 'MCQ' || generateQuesSelectedTestType?.children === 'CMCQ' || generateQuesSelectedTestType?.children === 'MSQ' || generateQuesSelectedTestType?.children === 'CMSQ') && !correctOptionsValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please select any correct option');
        }
        if ((generateQuesSelectedTestType?.children === 'T/F' || generateQuesSelectedTestType?.children === 'CTF') && !trueFalseValidation?.every((item) => item === true)) {
          return Notification.error("Error", 'Please select either True or False');
        }
        if ((generateQuesSelectedTestType?.children === 'CNumeric' || generateQuesSelectedTestType?.children === 'Numeric') && !numericAnswerCheck?.every((item) => item === true)) {
          return Notification.error("Error", 'Please enter valid numeric answer');
        }
      } else if (quesPositiveMark === 0) {
        return Notification.error("Error", 'Please add marks for the question');
      } else if (isEmptyHtml(generateQuesText)) {
        return Notification.error("Error", 'Please add question');
      } else if ((((Number(numberOfOptions) > 0 ? Array(Number(numberOfOptions)).fill(null).map(((u, i) => i)) : [0, 1, 2, 3]).some(isAnyOptionEmpty)) && (generateQuesSelectedTestType.children === 'MCQ')) || (((Number(numberOfOptions) > 0 ? Array(Number(numberOfOptions)).fill(null).map(((u, i) => i)) : [0, 1, 2, 3]).some(isAnyOptionEmpty)) && (generateQuesSelectedTestType.children === 'MSQ'))) {
        return Notification.error("Error", 'Please add options');
      } else if (((generateQuesSelectedTestType.children === 'MCQ' || generateQuesSelectedTestType.children === 'MSQ')) && (questionContent?.options?.find((item) => (item.value == null || item.value == '' || item.value == '<p><br></p>' || item.value == undefined)))) {
        return Notification.error("Error", 'Option value cannot be empty');
      } else if (((generateQuesSelectedTestType.children === 'MCQ' || generateQuesSelectedTestType.children === 'MSQ')) && (!questionContent?.options?.find((item) => item.correct === true))) {
        return Notification.error("Error", 'Please select any correct option');
      } else if ((generateQuesSelectedTestType.children == 'Numeric') && (answerContent?.answer === null || answerContent?.answer === undefined || answerContent?.answer === '')) {
        return Notification.error("Error", 'Please enter valid numeric answer');
      }
      else if ((generateQuesSelectedTestType?.children == 'T/F') && ((answerContent?.answer === null))) {
        return Notification.error("Error", 'Please select either True or False');
      }
    }

    let params = {
      questionContent: questionContent,
      answerContent: answerContent,
      type: orQuestionIndicator ? 'OrQuestions' : compQuestionIndicator ? 'Comprehensive' : generateQuesSelectedTestType?.children === 'Subjective' ? 'Subjective' : generateQuesSelectedTestType?.children,
      positiveMarks: quesPositiveMark,
      negativeMarks: quesNegativeMark,
      testId: generateQuesObj?.id,
      chapterId: generateQuesSelectedChapter.key ? generateQuesSelectedChapter.key : generateQuesSelectedChapter.id,
      solution: (orQuestionIndicator || compQuestionIndicator) ? null : generateSolutionText,
      sectionId: selectedSectionId,
    }
    if (editGenerateQueId !== '') {
      params.id = editGenerateQueId;
    }
    if ((generateCommentText != "") && (userType == "teacher")) {
      params.remark = generateCommentText;
      params.markedForReview = true;
    }
    updatePostParams(params)
    if (type == "savenext") {
      postQuestionCreate(params);
    }
  }

  useEffect(() => {
    document.getElementById("root-1-conditional-height-for-formula").style.overflowY = "hidden";
    return () => {
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY = "auto";
    }


  }, []);

  let addQuestionHandler = () => {
    if (compQuestionIndicator) {
      createAdditionalQuestion('COMP', generateQuesSelectedTestType.children)
    } else {
      createAdditionalQuestion('OR', generateQuesSelectedTestType.children)
    }
  }

  const location = useLocation();
  return (
    <div style={{ height: '100%', margin: '-20px' }}>
      <TopNavigationColorHeader history={history} fromCreateTest={location.state} location={location} type="beforeTest" />
      <div className='display-flex' style={{ height: 'calc(100% - 20px)' }}>
        <div className='position-relative' style={{ width: "80%", paddingBottom: 50 }}>
          <div style={{ height: 'calc(100% - 20px)', overflowX: "hidden", overflowY: "scroll" }}>
            <Row1
              history={history}
              generateQuesObj={generateQuesObj}
              showGenerateQuesListView={showGenerateQuesListView}
              generateQuesArr={generateQuesArr}
              showTextEditor={showTextEditor}
              generateCommentText={generateCommentText}
              userType={userType}
              grabAllfield={grabAllfield}
              editGenerateQueId={editGenerateQueId}
              isEmptyHtml={isEmptyHtml}
              openImportModal={openImportModal}
              user={user}
              selectedCardType={selectedCardType}
              selectedTest={selectedTest}
              setImportMeta={setImportMeta}
            />

            <div className='horizontal-divider'></div>
            <div className="full-height" style={{ backgroundColor: "#fafafa", height: 'fit-content' }}>
              <RowSub
                generateQuesObj={generateQuesObj}
                deleteQuesFromServer={deleteQuesFromServer}
                editGenerateQueId={editGenerateQueId}
                clearGenerateQuesFields={clearGenerateQuesFields}
                generateQuesSubChapters={generateQuesSubChapters}
                updateSubWithChapters={updateSubWithChapters}
                setDeleteModalVisible={setDeleteModalVisible}
                setDeleteLoader={setDeleteLoader}
                setSelectedSectionId={setSelectedSectionId}
                storeSchemeId={storeSchemeId}
                storeSubjectSection={storeSubjectSection}

              />
              <Row2
                generateQuesObj={generateQuesObj}
                deleteQuesFromServer={deleteQuesFromServer}
                editGenerateQueId={editGenerateQueId}
                clearGenerateQuesFields={clearGenerateQuesFields}
                generateQuesSubChapters={generateQuesSubChapters}
                updateSubWithChapters={updateSubWithChapters}
                setDeleteModalVisible={setDeleteModalVisible}
                setDeleteLoader={setDeleteLoader}
                setSelectedSectionId={setSelectedSectionId}
                selectedSectionId={selectedSectionId}
                storeSectionIndex={storeSectionIndex}
                storeSchemeId={storeSchemeId}
                schemeId={schemeId}
                storeSubjectSection={storeSubjectSection}
                questionIndexStore={questionIndexStore}

              />
              <Row3
                generateQuesObj={generateQuesObj}
                generateQuesSubChapters={generateQuesSubChapters}
                quesPositiveMark={quesPositiveMark}
                quesNegativeMark={quesNegativeMark}
                storeTestQuesType={storeTestQuesType}
                generateQuesSelectedTestType={generateQuesSelectedTestType}
                generateQuesSelectedChapter={generateQuesSelectedChapter}
                addMarksForQues={addMarksForQues}
                updateQuestionType={updateQuestionType}
                getMarkingSchemeMarks={getMarkingSchemeMarks}
                selectedSectionId={selectedSectionId}
                schemeId={schemeId}
                compQuestionIndicator={compQuestionIndicator}
                setLoader={setLoader}
                loaders={loaders} />

              {compQuestionIndicator ? <RowParagraph showTextEditor={showTextEditor} generateParagraphText={generateParagraphText} QuestionIndex={questionIndexStore} paragraph={questionContent?.paragraph} setImageViewerVisibility={setImageViewerVisibility} setImgUrl={setImgUrl} /> : null}
              {orQuestionIndicator ?
                questionContent?.orQuestions?.map((item, index) => (
                  <>
                    <QuesAnsContainer
                      showTextEditor={showTextEditor}
                      generateQuesText={generateQuesText}
                      editGenerateQueId={editGenerateQueId}
                      generateQuesArr={generateQuesArr}
                      chooseCorrectAnswer={chooseCorrectAnswer}
                      generateQuesOptions={generateQuesOptions}
                      generateQuesNumericText={generateQuesNumericText}
                      updateNumericAnswer={updateNumericAnswer}
                      renderEditorContent={renderEditorContent}
                      numericAnswer={numericAnswer}
                      generateQuesObj={generateQuesObj}
                      generateQuesSelectedTestType={generateQuesSelectedTestType}
                      questionContent={item.questionContent}
                      answerContent={answerContent[index]}
                      chooseBoolAnswer={chooseBoolAnswer}
                      orIndex={index}
                      sectionIndexStore={sectionIndexStore}
                      storeQuestionIndex={storeQuestionIndex}
                      solutionContent={item?.solution}
                      questionIndexStore={questionIndexStore}
                      modifyOptions={modifyOptions}
                      setImageViewerVisibility={setImageViewerVisibility}
                      setImgUrl={setImgUrl}
                      orQuestionIndexChar={item?.orQuestionIndexChar}
                    />
                    {index != questionContent?.orQuestions.length - 1 ?
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
                        <span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
                        <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
                      </div>
                      : null
                    }
                  </>
                ))
                : compQuestionIndicator ?
                  questionContent?.subQuestions?.map((item, index) => (
                    console.log('itemmmm', item),
                    <QuesAnsContainer
                      showTextEditor={showTextEditor}
                      generateQuesText={generateQuesText}
                      editGenerateQueId={editGenerateQueId}
                      generateQuesArr={generateQuesArr}
                      chooseCorrectAnswer={chooseCorrectAnswer}
                      generateQuesOptions={generateQuesOptions}
                      generateQuesNumericText={generateQuesNumericText}
                      updateNumericAnswer={updateNumericAnswer}
                      renderEditorContent={renderEditorContent}
                      numericAnswer={numericAnswer}
                      generateQuesObj={generateQuesObj}
                      generateQuesSelectedTestType={generateQuesSelectedTestType}
                      questionContent={item.questionContent}
                      answerContent={answerContent[index]}
                      chooseBoolAnswer={chooseBoolAnswer}
                      paragraphText={questionContent?.paragraph}
                      compIndex={index}
                      sectionIndexStore={sectionIndexStore}
                      storeQuestionIndex={storeQuestionIndex}
                      solutionContent={item?.solution}
                      compQuestionIndicator={compQuestionIndicator}
                      questionIndexStore={questionIndexStore}
                      addMarksForQues={addMarksForQues}
                      positiveMark={item?.positiveMarks}
                      negativeMark={item?.negativeMarks}
                      schemeId={schemeId}
                      modifyOptions={modifyOptions}
                      setImageViewerVisibility={setImageViewerVisibility}
                      setImgUrl={setImgUrl}
                    />
                  )) :
                  <QuesAnsContainer
                    showTextEditor={showTextEditor}
                    generateQuesText={generateQuesText}
                    editGenerateQueId={editGenerateQueId}
                    generateQuesArr={generateQuesArr}
                    chooseCorrectAnswer={chooseCorrectAnswer}
                    generateQuesOptions={generateQuesOptions}
                    generateQuesNumericText={generateQuesNumericText}
                    updateNumericAnswer={updateNumericAnswer}
                    renderEditorContent={renderEditorContent}
                    numericAnswer={numericAnswer}
                    generateQuesObj={generateQuesObj}
                    generateQuesSelectedTestType={generateQuesSelectedTestType}
                    questionContent={questionContent}
                    answerContent={answerContent}
                    chooseBoolAnswer={chooseBoolAnswer}
                    sectionIndexStore={sectionIndexStore}
                    storeQuestionIndex={storeQuestionIndex}
                    solutionContent={generateSolutionText}
                    questionIndexStore={questionIndexStore}
                    modifyOptions={modifyOptions}
                    setImageViewerVisibility={setImageViewerVisibility}
                    setImgUrl={setImgUrl}

                  />}

              {generateQuesObj?.testType !== 'Subjective'
                ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='text-xmd bold-600 blue text-center r-c-c' style={{ cursor: 'pointer', width: "33%", color: '#1089ff' }} onClick={addQuestionHandler}>{compQuestionIndicator ? 'ADD QUESTION' : 'ADD OR QUESTION'}</div>
                </div>
                :
                null}
            </div>
          </div>
          {console.log("AA-userType",userType)}
          {
            userType == 'admin' || generateQuesObj.status !== 'Published' || userType=='teacher' 
              ?
              <div className='p-20 test-save-sticky-footer r-c-c'>
                <div className='text-xmd bold-600 blue text-center r-c-c' style={{ width: "33%" }}> <div className="cursor-pointer" onClick={postQuestionCreateBool ? () => { } : () => { grabAllfield('savenext') }}>{postQuestionCreateBool ? <LoadingOutlined className='m-r-10' style={{ color: '#1089ff' }} /> : null} SAVE & NEXT </div></div>
              </div>
              :
              null
          }
          {
            isImageViewerVisible && (
              <ImageViewer isPreviewModalVisible={isImageViewerVisible}
                image={imgUrl} setIsPreviewModalVisible={setImageViewerVisibility} />
            )
          }
        </div>
        <div className='vertical-divider'></div>
        <div className='p-20 overflow-scroll' style={{ width: "20%" }}>
          <div className='text-xs a2grey bold-600 m-b-20'>ALL QUESTIONS</div>
          <QueGridSide
            generateQuesObj={generateQuesObj}
            generateQuesArr={generateQuesArr}
            updateSubWithChapters={updateSubWithChapters}
            clearGenerateQuesFields={clearGenerateQuesFields}
            generateQuesSubChapters={generateQuesSubChapters}
            getEachQuesData={getEachQuesData}
            editGenerateQueId={editGenerateQueId}
            getEachQuesDataBool={getEachQuesDataBool}
            updateNumericAnswer={updateNumericAnswer}
            selectedCardType={selectedCardType}
            selectedSectionId={selectedSectionId}
            setSelectedSectionId={setSelectedSectionId}
            storeSectionIndex={storeSectionIndex}
            storeSchemeId={storeSchemeId}
            getMarkingSchemeMarks={getMarkingSchemeMarks}
            schemeId={schemeId}
          />
        </div>
      </div>
      {
        textEditorBool && (
          <TextEditorModal visible={showEditorModal} closeModal={() => setShowEditorModal(false)} />
        )
      }

      <TestPublishModal history={history} />
      <CreateTestModal history={history} />
      <ImportQuestionModal visible={isImportModalVisible} onCancel={closeImportModal} />
      <DeleteQuesModal handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} visible={deleteModalVisible} loader={deleteLoader} />
    </div>
  );
}


const mapStateToProps = state => {
  const { generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
    generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
    generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId, numberOfOptions,
    getEachQuesDataBool, postQuestionCreateBool, generateCommentText, userType, numericAnswer, loaders,
    selectedCardType, selectedTest, textEditorBool, selectedSectionId, questionContent, answerContent, orQuestionIndicator, compQuestionIndicator, generateParagraphText, questionIndexStore, sectionIndexStore, schemeId, storeSubjectSection,savetestStartTime } = state.testAdmin;
  const { user } = state.session;
  return {
    generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
    generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
    generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId, numberOfOptions, loaders,
    getEachQuesDataBool, postQuestionCreateBool, generateCommentText, userType, numericAnswer, selectedCardType, user, selectedTest, textEditorBool, selectedSectionId, questionContent, answerContent, orQuestionIndicator, compQuestionIndicator, generateParagraphText, questionIndexStore, sectionIndexStore, schemeId, storeSubjectSection,savetestStartTime
  };
};

const mapDispatchToProps = dispatch => ({
  showTextEditor: (name, index, val) => dispatch(showTextEditor(name, index, val)),
  updateSubWithChapters: (name) => dispatch(updateSubWithChapters(name)),
  addMarksForQues: (operation, name, index) => dispatch(addMarksForQues(operation, name, index)),
  chooseCorrectAnswer: (val, index, identifier) => dispatch(chooseCorrectAnswer(val, index, identifier)),
  storeTestQuesType: (obj, id, name) => dispatch(storeTestQuesType(obj, id, name)),
  postQuestionCreate: (params) => dispatch(postQuestionCreate(params)),
  clearGenerateQuesFields: (val) => dispatch(clearGenerateQuesFields(val)),
  getEachQuesData: (id) => dispatch(getEachQuesData(id)),
  deleteQuesFromServer: (callback) => dispatch(deleteQuesFromServer(callback)),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
  unsetCreateTestModalBool: () => dispatch(unsetCreateTestModalBool()),
  updateNumericAnswer: (newAnswer, index) => dispatch(updateNumericAnswer(newAnswer, index)),
  renderEditorContent: (name, value) => dispatch(renderEditorContent(name, value)),
  updatePostParams: (newParams) => dispatch(updatePostParams(newParams)),
  setSelectedSectionId: (val) => dispatch(setSelectedSectionId(val)),
  chooseBoolAnswer: (val, bool, index) => dispatch(chooseBoolAnswer(val, bool, index)),
  updateQuestionType: (value, options) => dispatch(updateQuestionType(value, options)),
  storeQuestionIndex: (value) => dispatch(storeQuestionIndex(value)),
  storeSectionIndex: (value) => dispatch(storeSectionIndex(value)),
  createAdditionalQuestion: (identifier, value) => dispatch(createAdditionalQuestion(identifier, value)),
  storeSchemeId: (value) => dispatch(storeSchemeId(value)),
  getMarkingSchemeMarks: (type, callback) => dispatch(getMarkingSchemeMarks(type, callback)),
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  modifyOptions: (index, operation, qIndexType, qType) => dispatch(modifyOptions(index, operation, qIndexType, qType)),
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  setStartTimeTest:(val)=>dispatch(setStartTimeTest(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(TestGenerateQuestions);