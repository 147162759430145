import React, { useEffect } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { setEditDateOfBirth } from "actions/userManagement";
// import { setInstallments } from "actions/course";

function Date_Picker({
  setInstallments,
  installments,
  index,
  value,
  setDate,
  keyName,
  setInstCust,
  price,
  prevDisable,
  feeMangementData,
  disableDate = null,
}) {
  function onChange(date, dateString) {
    // console.log("start date", dateString, keyName);
    if (
      moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setDate(keyName, moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
      if (keyName == "startDate") {
        setDate("endDate", null);
      }
    } else {
    }
  }
  function disabledDate(current) {
    // console.log(current);
    return !(current && current > moment().startOf("day"));
  }
  const disabledDate1 = (current) => {
    return current && current < moment(disableDate, "YYYY-MM-DD");
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={50}>
      <DatePicker
        allowClear={false}
        className="datePicker"
        onChange={onChange}
        value={
          value !== null
            ? moment(
                moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                "DD-MM-YYYY"
              )
            : ""
        }
        placeholder={["Select a date"]}
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={prevDisable ? (e) => disabledDate(e) : disabledDate1}
      />
    </Space>
  );
}

const mapStateToProps = (state) => {
  const { feeMangementData } = state.feeManagement;
  return { feeMangementData };
};

const mapDispatchToProps = (dispatch) => ({
  setEditDateOfBirth: (date) => dispatch(setEditDateOfBirth(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Date_Picker);
