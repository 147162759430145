import { CloseOutlined, PlusCircleTwoTone, ZoomOutOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Checkbox, DatePicker, Input, Modal, Space, Tag, Typography } from 'antd'
import Search from 'antd/lib/input/Search';
import SearchBox from 'pages/Test/localComponent/searchBox';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { 
  getTeachersGeofenceLocations,
  searchFilter,
  allocateTeachersGeofence,
  storeAllocatedType,
  storeStartDate,
  storeEndDate} from 'actions/attendanceNew';
import { Notification } from 'services';

const image=require("./Icon material-search.svg").default;

function AssignLocModal({
  isModalVisible,
  setIsModalVisible,
  getTeachersGeofenceLocations,
  teachersGeofenceLocDataContainer,
  allocateTeachersGeofence,
  storeAllocatedType,
  storeStartDate,
  storeEndDate,
  checkedUserIds,
  allocatedTypeContainer,
  startDateContainer,
  endDateContainer,
  getTeachersGeofenceData,
  togglePageLoader,
  unCheckArray,
  specificUserId,
  fromOverViewAssign,
  disableTemporary
  }) {
  
  const [options,setOptions]=useState([teachersGeofenceLocDataContainer])
  const [searchedLoc,setSearchedLoc]=useState([])
  const [searchedLocId,setSearchedLocId]=useState([])
  const [checkedArray,setCheckedArray]=useState([])
  const [type,setType]=useState('')
  const [isAssignButtonLoader,setIsAssignButtonLoader]=useState(false)
  const [locationName,setLocationName] = useState("")

  useEffect(() => {
    getTeachersGeofenceLocations()
  }, [])

  useEffect(() => {
    storeStartDate(null);
    storeEndDate(null);
    setType("Permanent")
    storeAllocatedType("Permanent")
  }, []);

  useEffect(() => {
    let temp=[];
    for(let i=0;i<teachersGeofenceLocDataContainer?.length;i++){
      temp.push(
        {
          value:teachersGeofenceLocDataContainer?.[i].title,
          key:teachersGeofenceLocDataContainer?.[i].id
        }
      )
    }
    setOptions([...temp])
    console.log("temp",temp)
  }, [teachersGeofenceLocDataContainer])
  
  const onSelect =(value,obj) =>{
    console.log("obj",obj)
    setLocationName("");
    if(searchedLocId?.includes(obj?.key)){}
    else{
    setSearchedLoc(oldArray => [...oldArray, value]);
    let tempkey=searchedLocId;
      if(tempkey.includes(obj?.key)){
        tempkey.splice(tempkey.indexOf(obj.key),1)
      }else{
        tempkey.push(obj.key)
      }
    setSearchedLocId([...tempkey])
    }
  }

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current < moment().startOf('day');
  // }
  function disabledDate(current) {
    return current < moment().startOf('day')
  }

  function disabledEndDate(current) {
    return current < moment(startDateContainer);
  }

  const deleteLocation = (id) => {
    let temp=searchedLoc;
    console.log("1",id)
    temp.splice(id,1)
    console.log("2",searchedLoc)
    setSearchedLoc([...temp])
    let tempId=searchedLocId;
    tempId.splice(id,1);
    setSearchedLocId([...tempId]);
  };

  const OnChangePermanent = (id) => {
    if(type==''){
      id==0? setType("Permanent"): setType("Temporary")
    }else if (type === "Permanent") {
        setType('Temporary')
    } else if (type !== "Permanent") {
        setType("Permanent")
    }
}
console.log("type",type)


  const onSearch = (e) => {
    searchFilter(e);
    console.log(e);
    getTeachersGeofenceLocations(e);
    //  console.log("response",response)
} 

    const handleOk = (e) => {
      e.stopPropagation();
      setIsAssignButtonLoader(true);
      {console.log("checked",fromOverViewAssign,specificUserId)}
      {fromOverViewAssign ?
        allocateTeachersGeofence([specificUserId],searchedLocId,
          ()=>{
          setIsAssignButtonLoader(false)},
          ()=>{
            setIsModalVisible(false);
            togglePageLoader(true)
            getTeachersGeofenceData(()=>{
              togglePageLoader(false)
            });
            unCheckArray([])
          }
        )
      :
      allocateTeachersGeofence(checkedUserIds,searchedLocId,
        ()=>{
        setIsAssignButtonLoader(false)},
        ()=>{
          setIsModalVisible(false);
          togglePageLoader(true)
          getTeachersGeofenceData(()=>{
            togglePageLoader(false)
          });
          unCheckArray([])
        }
      )
      }
};  
    
    const handleCancel = () => {
        setIsModalVisible(false);
      };
  return (
    <div>
      <Modal className='modal-round-corner'
        centered={true}
        footer={null}
        header={null}
        visible={isModalVisible}
        // onOk={handleOk} 
        onCancel={handleCancel}>
        <div style={{ height: "450px" }}>
          <div style={{
            marginTop: "30px",
            fontSize: "24px",
            color: "#191919",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}>Assign Geo fence</div>
          <div style={{ fontSize: "13px", color: "#636363", marginLeft: "10px", marginTop: "20px", fontWeight: "bold" }}>Locations</div>
          <div style={{ display: "flex", marginLeft: "10px", marginTop: "5px" }}>
            <AutoComplete style={{ width: 485 }}
              // disabled={searchedLoc?.length < 5 ? false : true}
              options={options}
              onSearch={(e) => {
                console.log("searching", e);
                setLocationName(e)
              }}
              onSelect={onSelect}
              value={locationName}
              placeholder="Select a location"
              filterOption={options.filter((inputValue, option) => {
                option?.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) > -1
              })} />
            {/* <div style={{ width: "40px", height: "32px", backgroundColor: "#594099" }}
            // onClick={onSearched}
            >
              <img style={{ width: "15px", height: "15px", marginLeft: "13px", marginTop: "6px", }} src={image}></img></div> */}
          </div>

          <div className='flex-wrap' style={{ display: "flex" }}>
            {searchedLoc.map((value, index) => {
              return (
                <div className="text-overflow-ellipsis" style={{
                  borderRadius: "4px",
                  width: "fit-content",
                  height: "35px",
                  fontWeight: "500",
                  border: "1px solid #594099 ",
                  color: "#594099",
                  marginLeft: "10px",
                  display: "flex",
                  marginTop: "10px"
                }}>
                  <Typography.Text style={{ marginLeft: "10px", marginTop: "5px", width: "80px" }} ellipsis={{ tooltip: true }}>{value}</Typography.Text>
                  <CloseOutlined style={{ marginTop: "9px", marginLeft: "20px", marginRight: "10px" }}
                    onClick={() => { deleteLocation(index) }} />
                </div>)
            })}
          </div>


          <div style={{ fontSize: "13px", color: "#636363", margin: "10px", fontWeight: "bold" }}>Duration</div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <Checkbox onChange={() => { OnChangePermanent(0) }} onClick={() => { storeAllocatedType("Permanent") }} checked={type === "Permanent"} />
              <div style={{ fontSize: "14px", marginLeft: "20px", color: "#191919", font: " normal normal normal 16px/21px Roboto" }}>Permanent</div>
            </div>
            <div style={{ display: "flex", marginLeft: "160px" }}>
              <Checkbox
                disabled={disableTemporary.some(item => item.location)}
                onChange={() => { OnChangePermanent(1) }}
                checked={type === "Temporary"}
                onClick={() => { storeAllocatedType("Temporary") }} />
              <div style={{ fontSize: "14px", marginLeft: "20px", color: "#191919", font: " normal normal normal 16px/21px Roboto" }}>Temporary</div>
            </div>
          </div>
          {disableTemporary.some(item => item.location) ? <div style={{marginTop:10, fontSize:12, marginLeft:10, color:"red"}}>Since permanent location is global, you are not allowed to assign temporary location.</div> : <></> }
          {type === "Temporary" ?/* checkedArray?.length>0? */
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", }}>
              <div style={{ marginLeft: "10px" }}>
                <div
                  style={{ fontSize: "13px", color: "#636363", fontWeight: "bold" }}
                >
                  Start Date
                </div>
                <Space>
                  <DatePicker
                    allowClear={false}
                    onChange={(e) => { storeStartDate(e.format('YYYY-MM-DD')) }}
                    style={{
                      fontSize: "16px",
                      color: "#AEAEAE",
                      width: "220px",
                      marginTop: "10px"
                    }}
                    disabledDate={disabledDate}
                    placeholder="Select start date"
                  />
                </Space>
              </div>
              <div style={{ marginRight: "10px" }}>
                <div
                  style={{ fontSize: "13px", color: "#636363", fontWeight: "bold" }}
                >
                  End Date
                </div>
                <Space>
                  <DatePicker
                    allowClear={false}
                    disabledDate={disabledEndDate}
                    onClick={() => console.log("ABCD", startDateContainer === null)}
                    onChange={(e) => { storeEndDate(e.format('YYYY-MM-DD')), console.log("ABCD", startDateContainer === null) }}
                    style={{
                      fontSize: "16px",
                      color: "#AEAEAE",
                      width: "220px",
                      marginTop: "10px"
                    }}
                    placeholder="Select end date"
                    disabled={startDateContainer === null ? true : false}
                  />
                </Space>
              </div>
            </div> : null}
        </div>
        <div style={{ display: "flex", marginLeft: "130px", marginBottom: "30px" }}>
          <Button style={{
            borderRadius: "19px",
            width: "100px",
            height: "40px",
            fontWeight: "500",
            border: "1px solid #594099 ",
            color: "#594099"
          }} onClick={handleCancel}>CANCEL</Button>
          <Button
            loading={isAssignButtonLoader}
            style={{
              borderRadius: "19px",
              width: "100px",
              height: "40px",
              fontWeight: "500",
              border: "1px solid #E6E6E6 ",
              backgroundColor: "#594099",
              color: "#FFFFFF",
              marginLeft: "30px",
              opacity: (searchedLoc?.length > 0 && type == "Permanent") ? 1 : (searchedLoc?.length > 0 && type == "Temporary" && startDateContainer !== null && endDateContainer !== null) ? 1 : 0.5
            }}
            onClick={(e) => { handleOk(e) }}
            disabled={(searchedLoc?.length > 0 && type == "Permanent") ? false : (searchedLoc?.length > 0 && type == "Temporary" && startDateContainer !== null && endDateContainer !== null) ? false : true}
          >ASSIGN</Button>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {teachersGeofenceLocDataContainer,allocatedTypeContainer,startDateContainer,endDateContainer} =
    state.attendanceNew;
    const {specificUserId} = state.userManagement
  return {
    teachersGeofenceLocDataContainer,allocatedTypeContainer,startDateContainer,endDateContainer,specificUserId
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTeachersGeofenceLocations:(search)=>(dispatch(getTeachersGeofenceLocations(search))),
  searchFilter:(value)=>dispatch(searchFilter(value)),
  allocateTeachersGeofence:(checkedUserIds,searchedLocId,callback,successCallBack)=>dispatch(allocateTeachersGeofence(checkedUserIds,searchedLocId,callback,successCallBack)),
  storeAllocatedType:(val)=>dispatch(storeAllocatedType(val)),
  storeStartDate:(val)=>dispatch(storeStartDate(val)),
  storeEndDate:(val)=>dispatch(storeEndDate(val))
  });

export default connect(mapStateToProps, mapDispatchToProps)(AssignLocModal);

// export default AssignLocModal