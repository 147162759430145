import React, { useState } from 'react'
import TopBar from './topBar'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, Collapse, Divider, Row } from 'antd'
import Footer from './footer';
import chemistry from "../../Assets/aulasMarket/sbj-chemistry.svg"
import physics from "../../Assets/aulasMarket/sbj-physics.svg"
import computer from "../../Assets/aulasMarket/sbj-computerscience.svg"
import english from "../../Assets/aulasMarket/sgj-english.svg"
import botany from "../../Assets/aulasMarket/sbj-botany 1.svg"
import zoology from "../../Assets/aulasMarket/sbj-zoology.svg"
import arrowDown from "../../Assets/aulasMarket/ic-arrow-head-down.svg"
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
const { Panel } = Collapse;

function CourseList({ coursePageData, test }) {
    const [isExpended, setExpended] = useState(false);
    const [isActive, setActive] = useState(false);

    const panelContent = [
        {
            title: "Introduction",
            duration: "06:30",
            active: false,
            img: chemistry,
            id: 1,
        },
        {
            title: "About us",
            duration: "05:30",
            active: true,
            img: physics,
            id: 2,
        },
        {
            title: "Hello world",
            duration: "09:30",
            active: true,
            img: english,
            id: 3,
        },
        {
            title: "AWS account",
            duration: "04:30",
            active: false,
            img: computer,
            id: 4,
        },
        {
            title: "Weloome account",
            duration: "09:30",
            active: true,
            img: botany,
            id: 5,
        },
    ]
    const PanelContent = ({ data }) => {
        const [showAll, setShowAll] = useState(false);
        const toggleShowAll = () => {
            setShowAll(!showAll);
        };
        const itemsToShow = showAll ? data?.courseChapter?.length : 10;

        return (
            <>
                <div className="" style={{ color: "#636363" }}>
                    <Row
                        style={{
                            fontWeight: 600,
                            color: "#636363",
                            minHeight: 40,
                            padding: 10
                        }}
                        className="r-ac"
                    >
                        <Col offset={1} span={4}>Chapter</Col>
                        <Col span={7}>Name</Col>
                    </Row>
                </div>

                {
                    data?.courseChapter?.slice(0, itemsToShow).map((content, index) => (
                        <div className='' style={{ color: "#636363" }}>
                            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                            <Row style={{
                                fontWeight: 600,
                                color: "#636363",
                                minHeight: 40,
                                padding: 10
                            }}
                                className="r-ac">
                                <Col offset={1} span={4}> {index + 1}</Col>
                                <Col span={7}>{content?.name}</Col>
                            </Row>
                        </div>

                    ))
                }
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                <div className='r-c-c font-bold' style={{ color: "#1089FF", cursor: "pointer", height: "3em" }} >
                    {data?.courseChapter?.length > 10 && (
                        <div style={{ cursor: "pointer" }} onClick={toggleShowAll}>
                            {showAll ? 'VIEW LESS' : 'VIEW ALL'}
                        </div>
                    )}
                </div>
            </>

        );
    };

    const header = (val) => {
        console.log("naaaaa", val?.courseChapter?.length)
        return (
            <Row className="r-c-sb" style={{ padding: "10px" }}>
                <span style={{ fontSize: 16, fontWeight: "bold", color: "#636363" }}>
                    <img style={{ width: 45, height: 35 }} src={physics} /> {val?.name}
                </span>
                <span className='r-c-c' style={{ fontSize: 16, color: "#AEAEAE" }}>
                   {val?.courseChapter?.length} Chapters
                </span>
            </Row>
        )
    }

    console.log("ccccccccc", coursePageData?.course);
    return (
        <>
            {test ? null :
                <div className="top-bar">
                    <TopBar />
                </div>
            }

            <div style={{ backgroundColor: "#FAFAFA" }}>
                <div className={test ? null : 'main-container-parent'}>
                    <div className={test ? null : 'main-container'}>
                        {test ? null :
                            <>
                                <div className='r-c-fs m-t-30'>
                                    <Link to="/course-page">
                                        <img src={require("../../Assets/aulasMarket/ic-back-arrow (1).svg").default} />
                                    </Link>
                                    <div className='text-sm font-bold m-l-10'> Course Content</div>
                                </div>

                            </>
                        }
                        <div className='collapse-list'>
                            <div className='content-collapse' style={{}} >
                                {coursePageData?.course?.subjects?.map((section, index) => (
                                    <Collapse accordion={false}
                                        expandIcon={({ isActive }) => (
                                            <CaretRightOutlined rotate={isActive ? 90 : 270} style={{}} />
                                        )}
                                        style={{
                                            marginBottom: 20,
                                            // backgroundColor: isActive ? '#F6F4FE' : 'white',
                                            borderRadius: 10,
                                        }}
                                        onChange={() => {
                                            if (isActive) setExpended(false)
                                            setActive(prev => !prev)
                                        }}
                                        key={section?.id}
                                    >
                                        <Panel
                                            header={header(section)}
                                            key={section?.id} // Use a unique key for each panel
                                            style={{}}
                                        >
                                            <PanelContent data={section} />
                                        </Panel>
                                    </Collapse>
                                ))}

                            </div>
                        </div>

                    </div>
                    {test ? null :
                        <Footer />
                    }
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { coursePageData } = state.aulasMarket;
    console.log("pppp", state.aulasMarket);
    return {
        coursePageData,
    }
};


export default connect(mapStateToProps)(CourseList);


