import { ArrowLeftOutlined, ExclamationCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import { connect } from 'react-redux';
import { fetchSubjectWiseQuestions, fetchTopicList, importSubjectWiseQuestionPaper, setImportMeta, setLoader, showGenerateQuesListView } from 'actions/testAdmin';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from 'react-icons/ri';
import Loader from './localComponent/loader';
import { NodataCard } from './index';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Notification } from 'services';
import PageDataLoader from 'components/pageDataLoader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { importSelectedQ } from 'actions/marketTest';


const PageHeader = ({ goBack, selectedQBtype, selectedClassname, selectedSubjectName, count, onImport}) => {
  return (
      <div className="r-jsb full-width">
        <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
          <div className="text-md bold-700 m-l-20"> 
            <div>
              {selectedSubjectName}
            </div>
            <div style={{color:"#AEAEAE", fontSize:12}}>
            {selectedQBtype} / {selectedClassname}
            </div>
          </div>
        </div>
        <div className="">
          <Button onClick={onImport} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
            IMPORT ({count})
          </Button>
        </div>
      </div>
    );
  };


const TopicSection = ({topicCount, topicsList, onTopicClick, selectedTopic}) => {

    const TopicCard = ({topicData, isSelected, index}) => {
        return (
            <div className="p-t-10 p-l-20 p-r-20 cursor-pointer relative importTopicCard" 
            style={{backgroundColor:isSelected?"#F6F4FE":""}}
            onClick={onTopicClick(topicData, index)}
            >
                <div style={{borderBottom:"1px solid #E6E6E6",}} className="display-flex p-b-10">
                  <div className="full-height" style={{width:topicData?.selectedQuestions?.length>0?"90%":"100%"}}>
                    <div style={{color:"#191919", fontSize:13, fontWeight:isSelected?"800":"500"}} className="m-b-5">{topicData.topicName}</div>
                    <div style={{fontSize:11, color:"#636363"}}>{topicData.noOfQuestions} Question(s) </div>
                  </div>
                  {
                    topicData.selectedQuestions?.length>0?
                    <div className="r-c-c full-height absolute" style={{right:10, top:"1%"}}>
                      <div style={{height:25, width:25, borderRadius:13, backgroundColor:"#FFA931",fontSize:12, color:"#FFFFFF"}} className="r-c-c">{topicData?.selectedQuestions?.length}</div>
                    </div>
                    :null
                  }
                </div>
            </div>
        )
    }

    return (
        <div style={{width:"50%", maxWidth:400}} className="full-height">
            <div style={{fontSize:16, color:"#191919", fontWeight:"700"}} className="m-b-10"> Topics {topicCount}</div>
            <div style={{backgroundColor:"#FFFFFF", border:"1px solid #E6E6E6", height:"80vh", overflowY:"auto", borderRadius:5}} className="">
              {
                topicsList?.map((topicData, index)=>(
                  <TopicCard  topicData={topicData} isSelected={selectedTopic.topicId===topicData.topicId} index={index}/>
                ))
              }
            </div>
        </div>
    )
}

const QuestionSection = ({QuestionsList, loading, selectedQuestionsId, onQuestionSelection, onSelectAll, isAllSelected, setImportMeta, marksList, schemeId}) => {
    const QuestionSectionHeader = () => {
      if (QuestionsList?.length>0){
        return (
          <div className="display-flex">
              <Checkbox 
                onChange={onSelectAll}
                checked={isAllSelected}
              // defaultValue={importMeta?.sampleGroupAllSelectBool}
              >

              </Checkbox>
              <div className="m-l-15">Select all</div>
          </div>
        )
      }
      return null
    }
    const MarksSection = ({type, questionId, marksIndex, subQuestionLength}) => {
      return(
        <div style={{width:150}}>
        <div className='r-ac m-b-10'>
          <div className='text-xmd bold-600 color-black m-r-5'>Marks</div>
          {
            type=="Objective"
            ?
            <Tooltip placement="right" trigger="hover" title={
              <div>
                <div className='r-ac'>
                  <div className='radius-100' style={{width:10, height:10, background:'#6BDB9E'}}></div>
                  <div className='text-xmd color-black m-l-10'>Positive Marks</div>
                </div>
                <div className='r-ac'>
                  <div className='radius-100' style={{width:10, height:10, background:'#ec5254'}}></div>
                  <div className='text-xmd color-black m-l-10'>Negative Marks</div>
                </div>
              </div> 
            } overlayStyle={{borderRadius:"15px"}} overlayInnerStyle={{borderRadius:"15px", padding:"10px 20px", background:'#fff'}}>
                <ExclamationCircleOutlined style={{color:"#1089FF"}} className='text-xs cursor-pointer'/>
            </Tooltip>
            :
            null
          }
        </div>
        <div className='r-ac'>
            <div className='r-c-c test-count-seq-box'>
              <AiOutlinePlus onClick={() => {
                setImportMeta('changeMark', {markType:'positive', operation:'increment', questionId: questionId, type, marksIndex, subQuestionLength});
                }} className='cursor-pointer'/>
              <div className='text-xmd m-l-5 m-r-5'>{type === 'Comprehensive' ? marksList[questionId]["subQuestionMarks"][marksIndex]?.positiveMark : marksList? marksList[questionId]?.positiveMark:0}</div>
              <AiOutlineMinus onClick={() => {
                setImportMeta('changeMark', {markType:'positive', operation:'decrement', questionId: questionId, type, marksIndex, subQuestionLength});
                }} className='cursor-pointer'/>
            </div>
          {type === 'Objective'
          ?
            <div className='r-c-c test-count-seq-box m-l-10' style={{background:'#FFE4D9', borderColor:'#EC5254'}}>
              <AiOutlinePlus onClick={() => {
                setImportMeta('changeMark', {markType:'negative', operation:'increment', questionId: questionId});
                }} className='cursor-pointer'/>
              <div className='text-xmd m-l-5 m-r-5'>{marksList? marksList[questionId]?.negativeMark:0}</div>
              <AiOutlineMinus onClick={() => {
                setImportMeta('changeMark', {markType:'negative', operation:'decrement', questionId: questionId});
                }} className='cursor-pointer'/>
            </div>
          :
            null
          }
        </div>
      </div>
      )
    }

    const QuesAnsView = ({ questionData, indexMain, questionSelected }) => {
      console.log('quesData',questionData)

      const QuestionContainer = ({ questionData, indexMain, comprehensiveData, orQuestionsData, compIndex }) => {
          console.log('indexMain', indexMain)
          console.log('questionData', questionData)
          console.log('comprehensiveData', comprehensiveData)
          console.log('orQuestionsData', orQuestionsData)
          if (questionData?.type === 'Comprehensive') {
              return (
                  <div>
                      <div className='r-jsb m-t-20'>
                          <div className='display-flex flex-1'>
                              <div className='text-xmd bold-600 color-black m-r-5'>{indexMain + 1}.</div>
                              <div className='text-xmd color-black resize-editor-img'>
                                  {ReactHtmlParser(questionData?.questionContent?.paragraph,
                                      {
                                          transform: (node) => {
                                              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                  return <TeX math={`${node.attribs['data-value']}`} />;
                                              }
                                              if (node.type === 'tag' && node.name === 'img') {
                                                  return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                              }
                                          }
                                      })
                                  }
                              </div>
                          </div>
                          {/* <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div> */}
                      </div>
                      <div className='display-flex flex-1'>
                          <div className='text-xmd bold-600 color-black m-r-5'>{indexMain + 1}{compIndex !== null && compIndex !== undefined ? `(${String.fromCharCode('a'.charCodeAt() + compIndex)}).` : null}</div>
                          <div className='text-xmd color-black resize-editor-img'>
                              {ReactHtmlParser(comprehensiveData?.questionContent?.statement,
                                  {
                                      transform: (node) => {
                                          if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                              return <TeX math={`${node.attribs['data-value']}`} />;
                                          }
                                          if (node.type === 'tag' && node.name === 'img') {
                                              return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                          }
                                      }
                                  })
                              }
                          </div>
                      </div>
                  </div>
              )
          } else if (questionData?.type === 'OrQuestions') {
              return (
                  <div className='r-jsb m-t-20'>
                      <div className='display-flex flex-1'>
                          <div className='text-xmd bold-600 color-black m-r-5'>{indexMain + 1}.</div>
                          <div className='text-xmd color-black resize-editor-img'>
                              {ReactHtmlParser(orQuestionsData?.questionContent?.statement,
                                  {
                                      transform: (node) => {
                                          if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                              return <TeX math={`${node.attribs['data-value']}`} />;
                                          }
                                          if (node.type === 'tag' && node.name === 'img') {
                                              return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                          }
                                      }
                                  })
                              }
                          </div>
                      </div>
                      {/* <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div> */}
                  </div>
              )

          } else {
              return (
                  <div className='r-jsb m-t-20'>
                      <div className='display-flex flex-1'>
                          <div className='text-xmd bold-600 color-black m-r-5'>{indexMain + 1}.</div>
                          <div className='text-xmd color-black resize-editor-img'>
                              {ReactHtmlParser(questionData?.questionContent?.statement,
                                  {
                                      transform: (node) => {
                                          if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                              return <TeX math={`${node.attribs['data-value']}`} />;
                                          }
                                          if (node.type === 'tag' && node.name === 'img') {
                                              return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                          }
                                      }
                                  })
                              }
                          </div>
                      </div>
                      {/* {questionData.type === 'Subjective'
                          ?
                          <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks}</div>
                          :
                          <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div>
                      } */}
                  </div>

              )
          }
      }

      const AnswerContainer = ({ answerData, questionData, identifier, answerIndex }) => {

          if (answerData?.type === 'MCQ' || answerData?.type === 'CMCQ' || answerData?.type === 'CMSQ' || answerData?.type === 'MSQ' || questionData?.type === 'MCQ' || questionData?.type === 'MSQ') {
              return (
                  <div className='m-t-20' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                      {((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.questionContent?.options : questionData?.questionContent?.options)?.map((opt, i) => (
                          <div keys={opt.id} className='test-ques-window r-ac m-b-10 p-10' style={{ background: opt.correct ? '#E9FCF5' : '', width: '49%' }}>
                              <div
                                  style={{ background: opt.correct ? '#28df99' : '', color: opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : '' }}
                                  className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                  {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                              </div>
                              <div
                                  // style={{ background: opt.correct ? '#E9FCF5' : '' }}
                                  className='r-c-sb flex-1'>
                                  <div className='flex-1 resize-editor-img'>
                                      <div>
                                          {ReactHtmlParser(opt.value,
                                              {
                                                  transform: (node) => {
                                                      if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                          return <TeX math={`${node.attribs['data-value']}`} />;
                                                      }
                                                      if (node.type === 'tag' && node.name === 'img') {
                                                          return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                                      }
                                                  }
                                              })
                                          }
                                      </div>
                                  </div>
                                  <div className='r-ac m-l-5'>
                                      {opt.correct
                                          ?
                                          <RiCheckboxCircleLine className='text-sm seaGreen' />
                                          :
                                          <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                      }
                                      <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                                  </div>
                              </div>
                          </div>
                      ))}
                  </div>
              )

          } else if (answerData?.type === 'Numeric' || answerData?.type === 'CNumeric' || questionData?.type === 'Numeric') {
              console.log('answerDataNUmeric', answerData)
              return (
                  <div className='m-t-20'>
                      <div className='text-xmd bold-600 color-black'>Answer</div>
                      <div className='test-ques-window resize-editor-img p-10'>
                          {ReactHtmlParser(((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer))}
                      </div>
                  </div>

              )

          }
          else if (answerData?.type === 'T/F' || answerData?.type === 'CTF' || questionData?.type === 'T/F') {

              return (
                  <div className='m-t-20'>
                      <div className='text-xmd bold-600 color-black m-b-5'>Options</div>
                      <div style={{ display: 'flex' }}>
                          <div className='test-ques-window r-ac m-r-10 p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#E9FCF5' : '', }}>
                              <div
                                  style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '' }}
                                  className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                  {'A'}
                              </div>
                              <div
                                  style={{ width: "90%", overflowX: "hidden" }}
                                  className='cursor-pointer p-10 r-c-sb'
                              ><div>
                                      True
                                  </div>
                                  <div className='r-ac m-l-5'>
                                      {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true
                                          ?
                                          <RiCheckboxCircleLine className='text-sm seaGreen' />
                                          :
                                          <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                      }
                                      <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                                  </div>
                              </div>
                          </div>
                          <div className='test-ques-window r-ac p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#E9FCF5' : '', }}>
                              <div
                                  style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '' }}
                                  className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                  {'B'}
                              </div>
                              <div
                                  style={{ width: "100%", overflowX: "hidden" }}
                                  className='cursor-pointer p-10 r-c-sb'
                              >
                                  <div>
                                      False
                                  </div>
                                  <div className='r-ac m-l-5'>
                                      {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false
                                          ?
                                          <RiCheckboxCircleLine className='text-sm seaGreen' />
                                          :
                                          <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                      }
                                      <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              )

          } else {
              return null
          }
      }

      const SolutionContainer = ({ questionData, identifier, answerData }) => {

          return (
              <div className='m-t-20'>
                  <div className='text-xmd bold-600 color-black'>Solution</div>
                  <div className='test-ques-window resize-editor-img p-10'>
                      {ReactHtmlParser((questionData?.solution),
                          {
                              transform: (node) => {
                                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                      return <TeX math={`${node.attribs['data-value']}`} />;
                                  }
                                  if (node.type === 'tag' && node.name === 'img') {
                                      return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                  }
                              }
                          })
                      }
                  </div>
              </div>
          )

      }
      if (questionData?.type === 'Comprehensive') {
          return (
            <>
              <div style={{ backgroundColor: "#FFFFFF", padding: '20px 20px' }} className="p-10 full-width customCardShadow shadow-box hover-shadow relative">
                {questionData?.questionContent?.subQuestions?.map((item, index) => {
                  return (
                    <>
                      <QuestionContainer questionData={questionData} indexMain={indexMain} compIndex={index} comprehensiveData={item} />
                      <AnswerContainer answerData={item} questionData={questionData} identifier={'Comp'} answerIndex={index} />
                      <SolutionContainer questionData={questionData} identifier={'Comp'} />
                      {
                        questionSelected && (schemeId === -1 || schemeId == undefined || schemeId ==null)  ?
                          <MarksSection type={questionData.type} questionId={questionData.id} marksIndex={index} subQuestionLength = {questionData?.questionContent?.subQuestions?.length}/>
                          : false
                      }
                    </>

                  )
                })}
              </div>
            </>
          )
      }
      else if (questionData?.type === 'OrQuestions') {
          return (
            <>
              {questionData?.questionContent?.orQuestions?.map((item, index) => (
                  <>
                      <div style={{backgroundColor:"#FFFFFF", padding: '20px 20px'}} className="p-10 full-width customCardShadow shadow-box hover-shadow relative">
                          <QuestionContainer questionData={questionData} indexMain={indexMain} orQuestionsData={item} />
                          <AnswerContainer answerData={item} questionData={questionData} identifier={'OrQues'} answerIndex={index} />
                          <SolutionContainer questionData={questionData} identifier={'OrQues'} />
                          {index != questionData?.questionContent?.orQuestions?.length - 1 ?
                          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                              <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
                              <span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
                              <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
                          </div>
                          : null
                      }
                          {
                questionSelected && (schemeId === -1 || schemeId == undefined || schemeId ==null) ?
                <MarksSection type={questionData.type} questionId={questionData.id}/>
                :false
              }
                      </div>
                  </>
              ))}
              </>

          )
      } else {

          return (
              <div style={{backgroundColor:"#FFFFFF", padding: '20px 20px'}} className="p-10 full-width customCardShadow shadow-box hover-shadow relative" >
                  <QuestionContainer questionData={questionData} indexMain={indexMain} />
                  <AnswerContainer questionData={questionData} />
                  <SolutionContainer questionData={questionData} />
                  {
                questionSelected && (schemeId === -1 || schemeId == undefined || schemeId ==null) ?
                <MarksSection type={questionData.type} questionId={questionData.id}/>
                :false
              }
              </div>
          )
      }


  }

    const QuestionCard = ({data, index,questionSelected}) => {
      return (
        <div style={{backgroundColor:"#FFFFFF"}} className="p-10 full-width customCardShadow shadow-box hover-shadow relative">
                          <div className="text-xs absolute" style={{color:"gray", right:10}}>Difficulty: {data.difficulty}</div>
                          <div className='r-jsb m-t-20'>
                            <div className='display-flex flex-1'>
                              <div className='text-xmd bold-600 color-black m-r-5'>{index+1}.</div>
                              <div className='text-xmd color-black resize-editor-img'>
                                    {ReactHtmlParser(data.statement,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                              </div>
                            </div>
                          </div>
                          {data.type === 'Objective'
                          ?
                            <div className='m-t-10' style={{marginLeft:-20}}>
                              {data.options?.map((opt, i) => (
                                <div keys={opt.id} className='r-ac m-b-10 m-l-20' >
                                  <div
                                    style={{background:opt.correct ? '#28df99' : '', color:opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : ''}}
                                    className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                    {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                                  </div>
                                  <div
                                    style={{background:opt.correct ? '#E9FCF5' : ''}}
                                    className='test-ques-window p-10 r-c-sb flex-1'>
                                    <div className='flex-1 resize-editor-img'>
                                    {ReactHtmlParser(opt.value,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                                    </div>
                                    <div className='r-ac m-l-5'>
                                      {opt.correct
                                      ?
                                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                                      :
                                        <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                      }
                                      <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          : data.type === 'Numeric'
                            ?
                              <div className='m-t-10'>
                                <div className='text-xmd bold-600 color-black'>Answer</div>
                                <div className='test-ques-window resize-editor-img p-10' style={{border:"none"}}>
                                  {ReactHtmlParser(data.answer)}
                                </div>
                              </div>
                            : null
                          }
                          {(data.solution !== '' && data.solution !== '<p><br></p>')
                          ?
                            <div className='m-t-20'>
                              <div className='text-xmd bold-600 m-b-10' style={{color:"#594099"}}> <FileTextOutlined /> {'   '}Solution</div>
                              <div className='test-ques-window resize-editor-img p-10' style={{border:"none"}}>
                                    {ReactHtmlParser(data.solution,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                              </div>
                            </div>
                          :
                            null
                          }
                              {
                                questionSelected && (schemeId === -1 || schemeId == undefined || schemeId ==null) ?
                                <MarksSection type={data.type} questionId={data.id}/>
                                :false
                              }
        </div>
      )
    }
    const QuestionSelection= ({}) => {
      console.log('quesList',QuestionsList)
      return (
        <>
        {QuestionsList?.length>0?
        <Checkbox.Group className="flex-column full-width sub-ques-import" 
        onChange={onQuestionSelection} value={selectedQuestionsId}
        >
        {QuestionsList?.map((item, index) => (
                <Checkbox className="black p-10 m-l-0 full-width" value={item?.id}>
                    {/* <QuestionCard data={item} index={index} questionSelected={selectedQuestionsId?.includes(item.id)}/> */}
                    <QuesAnsView questionData={item} indexMain={index} questionSelected={selectedQuestionsId?.includes(item.id)}/>
                </Checkbox>
              ))
        }
        </Checkbox.Group>
        :<NodataCard imgheight="60%" imgwidth="60%"/>
        }
      </>
      )
    }
    return (
      <>
        {
          loading?
          <Loader style={{fontSize:40}}/>
          :        
          <div style={{width:"100%", overflowY:"auto"}} className="full-height m-l-20 ques-import">
            <QuestionSectionHeader/>
            <QuestionSelection/>
          </div>
        }
        </>
    )
}


function SubQuestionImportPage({importSelectedQ, setImportMeta, importMeta, fetchSubjectWiseQuestions, fetchTopicList, 
  loaders, importSubjectWiseQuestionPaper, showGenerateQuesListView, setLoader, schemeId}) {
    const [toggler, invertToggler] = useState(false);
    useEffect(()=>{
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY= "hidden";
      setLoader('importingSubQuestions', false);
      fetchTopicList((response)=>{
          if (response.length>0){
            setImportMeta('selectedTopic', response[0]);
            setImportMeta('topicIndex', 0);
            fetchSubjectWiseQuestions();
          }
      });
      return ()=>{
        document.getElementById("root-1-conditional-height-for-formula").style.overflowY= "auto";
      }
    }, []);
    useEffect(()=>{
      if (importMeta.selectionTree?.length>0){
       fetchSubjectWiseQuestions();
    }
    }, [toggler]);
    useEffect(()=>{

    }, [])
    const history = useHistory();
    const location = useLocation();

    const goBack = () =>{
        history.goBack();
    }
    const onTopicClick = (topicData, index) => {
      return ()=>{
        setImportMeta('topicIndex', index);
        setImportMeta('selectedTopic', topicData);
        invertToggler(!toggler);
      }
    }
    const onQuestionSelection = (selections) => {
      if (selections?.length===importMeta?.topicwiseQuestionsList?.length){
        setImportMeta('setAllSelectBool' , {
          topicId:importMeta.selectedTopic?.topicId,
          bool:true});
      }else{
        setImportMeta('setAllSelectBool' , {
          topicId:importMeta.selectedTopic?.topicId,
          bool:false});
      }
      setImportMeta('setSelectionTreeQuestionSelection', {
        topicId: importMeta.selectedTopic.topicId,
        selections: selections
      });
    }
    const onSelectAll = (e) => {
      const allSelection = importMeta.topicwiseQuestionsList?.map((item)=>item.id);
      if (e.target.checked===true){
        setImportMeta('setAllSelectBool' , {
          topicId:importMeta.selectedTopic.topicId,
          bool:true});
        setImportMeta('setSelectionTreeQuestionSelection', {
          topicId: importMeta.selectedTopic.topicId,
          selections: allSelection
        });
      }else{
        setImportMeta('setAllSelectBool' , {
          topicId:importMeta.selectedTopic.topicId,
          bool:false});
          setImportMeta('setSelectionTreeQuestionSelection', {
            topicId: importMeta.selectedTopic.topicId,
            selections: []
          });
      }
    }
    const getCount = () => {
      let c = 0
      importMeta?.selectionTree?.forEach((item)=>{
        c+=item.selectedQuestions?.length}
      )
      return c
    }
    const onImport = () => {
      if (!importMeta || !importMeta.selectionTree){
        Notification.error("Error", "Please select question(s) for importing");
      }else{
        let c = 0;
        for (let topic of importMeta.selectionTree){
          c += topic.selectedQuestions?.length
        }
        if (c===0){
          Notification.error("Error", "Please select question(s) for importing");
          return
        }
        // for (let topic of importMeta.selectionTree){
        //   for (let question of topic.selectedQuestions){
        //     if (importMeta.marksList[question]?.positiveMark<=0){
        //       Notification.error("Error", "Please add marks for all question  before importing");
        //       return
        //     }
        //   }
        // }
        if(location?.state?.testSeriesData) 
          importSelectedQ(
            () => setLoader('importingSubQuestions', false),
            () => history.push("/testSeries/addQuestions")
          )
        else
          importSubjectWiseQuestionPaper((response)=>{
            if (response?.show?.type==='success'){
              showGenerateQuesListView(()=>{
                history.push('admin-test');
                setLoader('importingSubQuestions', false);
              },'import');
            }else{
              setLoader('importingSubQuestions', false);
            }
          });
      }
    }
  return (
    <div style={{padding:15, margin:-20, height:"98vh", width:"103%", overflow:"hidden", backgroundColor:"#FAFAFA", overflow:"hidden"}}>
        <PageHeader selectedClassname={`${importMeta?.selectedStandardObj?.name} - ${importMeta?.selectedStandardObj?.stream===''?"NA":importMeta?.selectedStandardObj?.stream}`} 
                    selectedQBtype={'Subject-wise Question Papers'} 
                    selectedSubjectName={`${importMeta?.selectedSubjectObj?.subName}`} 
                    goBack={goBack} 
                    count={getCount()}
                    onImport={onImport}/>
        <div style={{padding:20}} className="full-height full-width display-flex">
          {
            loaders?.importTopics?
              <Loader style={{fontSize:40}}/>
            :
            <>
            <TopicSection topicsList={importMeta?.selectionTree} 
                          onTopicClick={onTopicClick} 
                          selectedTopic={importMeta?.selectedTopic}/>

            <QuestionSection QuestionsList={importMeta?.topicwiseQuestionsList}
                             onSelectAll={onSelectAll} 
                             loading={loaders?.importSubjectWiseQuestions} 
                             onQuestionSelection={onQuestionSelection}
                             selectedQuestionsId={importMeta.topicIndex!=undefined?importMeta.selectionTree[importMeta.topicIndex]?.selectedQuestions:[]}
                             setImportMeta={setImportMeta}
                             marksList={importMeta.marksList}
                             isAllSelected={(importMeta.topicIndex!=undefined)?importMeta?.selectionTree[importMeta.topicIndex]?.selectAllBool:false}
                             schemeId={schemeId}
                            />
            </>
          }
        </div>
        <PageDataLoader visible={loaders?.importingSubQuestions}/>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta,
    schemeId
  } = state.testAdmin;
  return {  
    loaders,
    importMeta,
    schemeId
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchSubjectWiseQuestions: (callback) => dispatch(fetchSubjectWiseQuestions(callback)),
  fetchTopicList: (callback) => dispatch(fetchTopicList(callback)),
  importSubjectWiseQuestionPaper: (callback) => dispatch(importSubjectWiseQuestionPaper(callback)),
  showGenerateQuesListView: (callback, page_source) => dispatch(showGenerateQuesListView(callback, page_source)),
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  importSelectedQ: (loaderCallback, successCallBack) => dispatch(importSelectedQ(loaderCallback, successCallBack)),
})


export default connect(mapStateToProps, mapDispatchToProps)(SubQuestionImportPage)