import React, { useEffect, useState } from "react";
import { Button, DatePicker, Modal, Radio } from "antd";
import moment from "moment";
import { CheckCircleFilled, LineHeightOutlined } from "@ant-design/icons";

const TeachersDownloadReportModal = ({
  isModelVisible,
  setisModelVisible,
  usersDownloadReport,
  storeSelectedRange,
  storeStartDate,
  storeEndDate,
  startDateContainer,
  endDateContainer,
  selectedRangeContainer,
  fromMarketPlaceCRM,
  tab,
  postCrmDownloadReport
}) => {
  const [value, setValue] = useState(0);
  const [isDownloadBtnLoading, setIsDownloadBtnLoading] = useState(false);
  const handleCancel = (e) => {
    e.stopPropagation();
    setisModelVisible(false);
  };

  const onRadioChange = (e) => {
    setValue(e.target.value);
  };

  const RangeStyle = {
    font: "normal normal normal 15px/21px Roboto",
    color: "#191919",
    margin: "5px",
  };

  function disabledDate(current) {
    console.log("current", current);
    return (
      current >= moment().endOf("day") || current <= moment(startDateContainer)
    );
  }

  function disabledstartDate(current) {
    return current >= moment().endOf("day");
  }
  useEffect(() => {
    storeStartDate(null);
    storeEndDate(null);
    storeSelectedRange(null);
    console.log("selected", value === 6, value);
  }, []);

  return (
    <div>
      <Modal
        style={{ height: "600px" }}
        centered
        className="modal-round-corner"
        visible={isModelVisible}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
        {console.log("burijhiu", selectedRangeContainer === null)}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#191919",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          Select range
        </div>
        <div
          style={{
            color: "#191919",
            fontSize: "14px",
            fontWeight: 600,
            padding: "10px",
          }}
        >
          <Radio.Group
            onChange={onRadioChange}
            value={value}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Radio
              onClick={() => {
                storeSelectedRange("lastweek");
                {
                  console.log(
                    "1",
                    selectedRangeContainer === "specificDateRange"
                  );
                }
              }}
              value={1}
              style={{ marginTop: "20px" }}
            >
              <div style={RangeStyle}>Last week</div>
            </Radio>
            <Radio
              onClick={() => {
                storeSelectedRange("lastmonth");
                {
                  console.log(
                    "2",
                    selectedRangeContainer === "specificDateRange"
                  );
                }
              }}
              value={2}
            >
              <div style={RangeStyle}>Last month</div>
            </Radio>
            <Radio
              onClick={() => {
                storeSelectedRange("lastthreemonths");
                {
                  console.log(
                    "3",
                    selectedRangeContainer === "specificDateRange"
                  );
                }
              }}
              value={3}
            >
              <div style={RangeStyle}>Last 3 month</div>
            </Radio>
            <Radio
              onClick={() => {
                storeSelectedRange("lastsixmonths");
                {
                  console.log(
                    "4",
                    selectedRangeContainer === "specificDateRange"
                  );
                }
              }}
              value={4}
            >
              <div style={RangeStyle}>Last 6 month</div>
            </Radio>
            <Radio
              onClick={() => {
                storeSelectedRange("yeartodate");
                {
                  console.log(
                    "5",
                    selectedRangeContainer === "specificDateRange"
                  );
                }
              }}
              value={5}
            >
              <div style={RangeStyle}>Year to date</div>{" "}
            </Radio>
            <Radio
              onClick={() => {
                setValue(6);
                storeSelectedRange("specificDateRange");
                {
                  console.log("6", value === 6, value);
                }
              }}
              value={6}
            >
              <div style={RangeStyle}>Specific date range</div>
            </Radio>
          </Radio.Group>
        </div>

        {value === 6 ? (
          <div
            className="flex-row"
            style={{ justifyContent: "space-around", marginTop: "10px" }}
          >
            <div>
              <div style={{ color: "#636363", fontSize: "13px" }}>
                Start date
              </div>
              <div>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                  style={{ width: "200px" }}
                  onChange={(e) => storeStartDate(e.format("YYYY-MM-DD"))}
                  disabledDate={disabledstartDate}
                />
              </div>
            </div>
            <div>
              <div style={{ color: "#636363", fontSize: "13px" }}>End date</div>
              <div>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  allowClear={false}
                  disabled={startDateContainer === null ? true : false}
                  style={{ width: "200px" }}
                  onChange={(e) => storeEndDate(e.format("YYYY-MM-DD"))}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
          }}
        >
          <Button
            style={{
              borderRadius: "19px",
              width: "150px",
              height: "40px",
              fontWeight: "500",
              backgroundColor: "#FFFFFF",
              border: "1px solid #636363 ",
              color: "#636363",
            }}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            disabled={
              value === 6
                ? value === 0 ||
                  startDateContainer === null ||
                  endDateContainer === null
                  ? true
                  : false
                : value === 0
                  ? true
                  : false
            }
            loading={isDownloadBtnLoading}
            className="m-l-30"
            style={{
              borderRadius: "19px",
              width: "150px",
              height: "40px",
              fontWeight: "500",
              backgroundColor: "#594099",
              border: "1px solid #636363 ",
              color: "#FFFFFF",
              opacity:
                value === 6
                  ? value === 0 ||
                    startDateContainer === null ||
                    endDateContainer === null
                    ? 0.5
                    : 1
                  : value === 0
                    ? 0.5
                    : 1,
            }}
            onClick={() => {
              setIsDownloadBtnLoading(true);
              if (fromMarketPlaceCRM) {
                postCrmDownloadReport(tab, () => { setIsDownloadBtnLoading(false);setisModelVisible(false) })
              } else {
                usersDownloadReport(() => { setIsDownloadBtnLoading(false) }, () => { setisModelVisible(false) });
              }
            }}
          >
            DOWNLOAD
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TeachersDownloadReportModal;
