import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { getSubscriptionPlan, storeSubscription } from '../../actions/profile'
import { getStudentFaqs, getAdminFaqs, setAulasCareChat, getChatUnreadCountForInst } from 'actions/adminCare';
import { connect } from 'react-redux';
import PlanDetailModal from './planDetailModal';
import { Button, Tooltip } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons';






function AvailableSubscriptionPackages({ getSubscriptionPlan, broadSubscriptionPlans, storeSubscriptionPlan, storeSubscription, setAulasCareChat }) {


    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        getSubscriptionPlan()
    }, [])

    function numberWithCommas(x) {
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
    }
    // const handleViewAulasChatClick = () => {
    //     setAulasCareChat(true)
    //     history.push('/care/chat')
    // }

    const history = useHistory();
    const location = useLocation();
    return (
        <div style={{ backgroundColor: "#FAFAFA", margin: -20, width: "calc(100%+40px)", padding: 20, flex: 1 }}>
            {console.log("broadSubscriptionPlans", broadSubscriptionPlans)}
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#191919" }}>
                <div style={{ padding: 5, cursor: "pointer" }} onClick={() => {
                     history.goBack()
                    // if(location?.state?.key === 1){
                    //     history.goBack()
                    // }else{
                    //     history.push({
                    //         pathname: '/profile',
                    //         state: {
                    //             key: 3
                    //         }
                    //     });
                    // }
                }}>
                    <ArrowLeftOutlined style={{ fontSize: 20 }} />
                </div>
                <div className="m-l-10" style={{ fontSize: 20, fontWeight: '700' }}>
                    Broadcast Subscription
                </div>
            </div>
            <div style={{ textAlign: "center" }} className="m-t-10 bold-600">
                Subscribe to any plan for Broadcast
            </div>
            <div style={{ color: "#636363", fontSize: 12, textAlign: "center" }}>
                Select a plan to facilitate virtual learning with flawless video and audio, instant screen sharing, instant messaging and digital white board.
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                {
                    broadSubscriptionPlans?.length != 0 ? broadSubscriptionPlans?.map((item) => {
                        console.log("item", item);
                        return (
                            <div className="m-l-20 m-t-20 p-20" style={{ border: "1px solid #E6E6E6", borderRadius: 10, width: 320, height: 200, backgroundColor: "white", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <div style={{ fontWeight: '700', fontSize: 35, fontFamily: "PT-Serif" }}>{item?.userHours}</div>
                                            <div style={{ fontSize: 14, color: "#636363", fontWeight: 500 }}>Duration</div>
                                        </div>
                                        <div style={{ fontSize: 18, fontFamily: "Roboto", marginBottom: "15px", color: '#191919', fontFamily: 'PT-Serif' }} className="m-l-10">User Hours</div>
                                    </div>
                                    <div className='display-flex flex-column'>
                                        <div style={{ color: "#1089FF", fontWeight: '700', fontSize: "24px" }}>
                                            ₹ {item?.price && numberWithCommas(item?.price)}
                                        </div>
                                        <div style={{ color: '#636363', fontSize: 14, fontWeight: 500 }}>Price</div>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: 10, paddingRight: 10, }} className="r-c-c">
                                    <div style={{ borderRadius: 15, width: "100%", height: 30, textAlign: "center", backgroundColor: "#594099", color: "#594099", cursor: "pointer" }}
                                        onClick={() => { setIsModalOpen(true); storeSubscription(item) }}
                                    >
                                        <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "15px", marginTop: "5px", color: "white" }}>  BUY</div>
                                        {/* {!item?.current ?
                                            <div>{item?.current ? <span><CheckCircleOutlined style={{ marginRight: '5px' }} />Current Plan</span> : 'SUBSCRIBE'}</div> :
                                            <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "15px", marginTop: "8px" }} ><CheckCircleOutlined style={{ marginRight: '5px' }} />Current Plan</div>} */}

                                        {/* <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "15px", marginTop: "5px" }}>SUBSCRIBE</div>
                                         */}
                                    </div>

                                </div>
                            </div>
                        )
                    })
                        : null}
                {/* <div className="m-l-20 m-t-20 p-20" style={{ border: "1px solid #E6E6E6", borderRadius: 10, width: 320, height: 200, backgroundColor: "white", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "45px", fontWeight: "bold" }}>Customize    </div>
                    <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "11px", fontWeight: "bold", color: "#636363", marginBottom: "40px" }}>Reach out to get a quote</div>
                    <div style={{ borderRadius: 15, width: "100%", height: 30, textAlign: "center", backgroundColor: "#594099", color: "#594099", cursor: "pointer" }}>
                        <div style={{ textAlign: "center", fontFamily: "Roboto", fontSize: "15px", marginTop: "5px", color: "white" }} onClick={() => { handleViewAulasChatClick() }}>CONTACT US</div>
                    </div>

                </div> */}
            </div>

            <div>
                {isModalOpen &&
                    <PlanDetailModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        storeSubscription={storeSubscription}
                        storeSubscriptionPlan={storeSubscriptionPlan}
                    />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {

    const { broadSubscriptionPlans, storeSubscriptionPlan } = state.profile;
    return { broadSubscriptionPlans, storeSubscriptionPlan };
};

const mapDispatchToProps = (dispatch) => ({
    getSubscriptionPlan: () => dispatch(getSubscriptionPlan()),
    storeSubscription: (res) => dispatch(storeSubscription(res)),
    setAulasCareChat: (m) => dispatch(setAulasCareChat(m)),



});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableSubscriptionPackages);

