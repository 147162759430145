
import React, { useEffect, useState } from 'react'
import {
  ArrowLeftOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { Col, DatePicker, Select, Row } from 'antd';
import SubjectGraph from './localComponent/subjectGraph';
import StudentDonutGraph from './localComponent/studentDonutCard';
import StudentAttendanceBarGraph from './localComponent/studentAttendanceGraphDetail';
import { getStudentSummary, studentStartDate, studentEndDate, monthlySummary, subjectSummary } from 'actions/attendanceNew';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';

import PageDataLoader from 'components/pageDataLoader';


const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD'

const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 30px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "auto",
};


const PageHeader = ({ goBack }) => {
  const history = useHistory()
  return (

    <div className="resultPageHeader full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined
          onClick={history.goBack}
          style={{ fontSize: 18 }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}


const WeeklyCard = ({ togglePageLoader, summaryOfStudent, studentattStartDate, studentattEndDate, studentStartDate, studentEndDate, getStudentSummary }) => {
  let [sum, setSum] = useState(0);
  useEffect(() => {
    // console.log("asdf",studentDashboardData?.attendanceByClass)
    setSum(0)
    let temp = 0;
    if (summaryOfStudent?.weeklySummary?.length != 0) {
      // item?.absent ? item ?. absent : 0  || item?.present ? item ?. present : 0
      summaryOfStudent?.weeklySummary?.forEach(
        (item) => (

          (temp = temp + parseFloat(item?.total))
        )
      );
    }
    setSum(temp);
  }, [summaryOfStudent?.weeklySummary]);
  const [graph, setGraph] = useState([])
  useEffect(() => {
    studentStartDate(moment().startOf('week').format('YYYY-MM-DD'))
    studentEndDate(moment().endOf('week').format('YYYY-MM-DD'))
  }, [])

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < summaryOfStudent?.weeklySummary?.length; i++) {
      let obj = {}
      obj['date'] = moment(summaryOfStudent?.weeklySummary[i].date).format('MM-DD')
      obj['range'] = summaryOfStudent?.weeklySummary[i].presentHours,
        obj['present'] = summaryOfStudent?.weeklySummary[i].present,
        obj['total'] = summaryOfStudent?.weeklySummary[i].total,
        obj['name'] = 'Present'
      temp.push(obj);
      console.log("temp", summaryOfStudent?.weeklySummary[i].presentHours)


      let obj1 = {}
      obj1['date'] = moment(summaryOfStudent?.weeklySummary[i].date).format('MM-DD')
      obj1['range'] = summaryOfStudent?.weeklySummary[i].absentHours,
        obj1['absent'] = summaryOfStudent?.weeklySummary[i].absent,
        obj1['total'] = summaryOfStudent?.weeklySummary[i].total,
        obj1['name'] = 'Absent'
      temp.push(obj1);
    }
    setGraph([...temp])

  }, [summaryOfStudent?.weeklySummary])



  return (
    <div
      style={{
        width: "100%",
        height: "340px",
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
      }}
    >
      <div
        style={{
          color: "#191919",
          fontWeight: "bold",
          fontSize: "15px",
          padding: 10,
          marginLeft: 10,
        }}
      >
        Weekly Summary
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", marginLeft: "20px", marginTop: "-10px" }}>
          <div style={{ fontSize: "9px", color: "#636363" }}>
            <CaretLeftOutlined
              onClick={() => {
                studentStartDate(moment(studentattStartDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'))
                studentEndDate(moment(studentattEndDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'))
                togglePageLoader(true)
                getStudentSummary(() => {
                  togglePageLoader(false)
                })
              }}
            />
          </div>
          <div
            style={{
              marginLeft: "5px",
              fontSize: "9px",
              color: "#636363",
            }}
          >
            {/* 11/07/2021 - 17/07/2021 */}
            {studentattStartDate ? studentattStartDate : null} {""}{" "}{"-"}{" "}{""}  {studentattEndDate ? studentattEndDate : null}
            {console.log("date", studentattStartDate, studentattEndDate)}
          </div>
          {console.log("moment", studentattStartDate, studentattEndDate, (studentattStartDate === moment().startOf('week').format("YYYY-MM-DD") &&
            studentattEndDate === moment().endOf('week').format("YYYY-MM-DD")))}
          {!(studentattStartDate === moment().startOf('week').format("YYYY-MM-DD") &&
            studentattEndDate === moment().endOf('week').format("YYYY-MM-DD")) ?
            <div style={{ fontSize: "9px", color: "#636363" }}>

              <CaretRightOutlined
                onClick={() => {
                  studentStartDate(moment(studentattStartDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'))
                  studentEndDate(moment(studentattEndDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'))
                  togglePageLoader(true)
                  getStudentSummary(() => {
                    togglePageLoader(false)
                  })
                }}
              />
            </div> : null}
        </div>

        <div style={{ width: "50%", marginTop: "-20px" }}>
          <RangePicker
            allowClear={false}
            value={[studentattStartDate ? moment(studentattStartDate) : null, studentattEndDate ? moment(studentattEndDate) : null]}

            // value={[studentattEndDate,studentattEndDate]}
            onChange={(value1, value) => {
              studentStartDate(value[0])
              studentEndDate(value[1])


              togglePageLoader(true)
              getStudentSummary(() => {
                togglePageLoader(false)
              })
            }}
            format={dateFormat}
          />
          {console.log("s", [studentattStartDate ? moment(studentattStartDate) : null, studentattEndDate ? moment(studentattEndDate) : null])}
        </div>
      </div>

      <div style={{ width: "90%", height: "80%", marginTop: 50, marginLeft: 20 }}>
        {sum === 0 ? (

          <div style={{ marginTop: "10px" }}>
            <NodataCard />
          </div>
        ) :
          (
            <div
              style={{ width: "95%", height: "80%", marginTop: 50, marginLeft: 20 }}
            >
              <StudentAttendanceBarGraph data={graph?.length != 0 ? graph : []} />
            </div>
          )
        }
      </div>

    </div>
  );
};



const DonutGraphCard = ({ togglePageLoader, studentMonthlySummary, monthlySummary, summaryOfStudent, getStudentSummary }) => {
  useEffect(() => {
    monthlySummary(moment().format('MMMM'))
  }, [])

  return (
    <div
      style={{
        width: "96%",
        height: "340px",
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ marginTop: "30px" }}>
          <div style={{ fontSize: "15px", color: "#191919", fontWeight: "bold" }}>
            Monthly summary
          </div>
          <div style={{ display: "flex", }}>
            <div style={{ marginLeft: "5px", fontSize: "9px", color: "#636363" }}>
              <CaretLeftOutlined
                onClick={() => {
                  monthlySummary(moment(studentMonthlySummary, 'MMMM').subtract(1, 'months').format('MMMM'))
                  // monthlySummary(moment(studentMonthlySummary, 'MMM' ).add(1, 'month').format('MMM'))
                  // monthlySummary(moment(studentMonthlySummary).add(1, 'months').calendar())
                  // console.log("daes",studentMonthlySummary,moment(studentMonthlySummary).subtract(1, 'months').calendar())
                  togglePageLoader(true)
                  getStudentSummary(() => {
                    togglePageLoader(false)
                  })
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "5px",
                fontSize: "9px",
                color: "#636363",
              }}
            >
              {studentMonthlySummary}
            </div>
            <div style={{ marginLeft: "5px", fontSize: "9px", color: "#636363" }}>
              <CaretRightOutlined
                onClick={() => {
                  monthlySummary(moment(studentMonthlySummary, 'MMMM').add(1, 'months').format('MMMM'))
                  togglePageLoader(true)
                  getStudentSummary(() => {
                    togglePageLoader(false)
                  })
                  // console.log("daes",studentMonthlySummary,moment(studentMonthlySummary,'MMMM').add(1, 'months').format('MMMM'))
                }}

              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "35px" }}>
          <Select style={{ width: "90px", fontSize: "11px", fontWeight: "bold" }}
            value={studentMonthlySummary}
            onChange={(value) => {
              monthlySummary(value)
              togglePageLoader(true)
              getStudentSummary(() => {
                togglePageLoader(false)
              })
            }
            }
          >
            <Option value="January">January</Option>
            <Option value="February">February</Option>
            <Option value="March">March</Option>
            <Option value="April">April</Option>
            <Option value="May">May</Option>
            <Option value="June">June</Option>
            <Option value="July">July</Option>
            <Option value="August">August</Option>
            <Option value="September">September</Option>
            <Option value="October">October</Option>
            <Option value="November">November</Option>
            <Option value="December">December</Option>
          </Select>
        </div>
      </div>
      {summaryOfStudent?.monthlySummary?.presentPercentage === 0 && summaryOfStudent?.monthlySummary?.absentPercentage === 0 ?
        <NodataCard />
        :
        <div style={{ height: "290px" }}>
          <StudentDonutGraph
            summaryOfStudent={summaryOfStudent}
          />
        </div>

      }


      {/* <Tooltip>
            <Progress percent={80}  success={{ percent: 40,strokeColor:"#28DF99" }} type="circle" strokeWidth={10} width={200}/>
             </Tooltip> */}

    </div>
  );
};



const SubjectGraphCard = ({ togglePageLoader, studentSubjectSummary, subjectSummary, getStudentSummary, summaryOfStudent }) => {
  let [sum, setSum] = useState(0);
  useEffect(() => {
    // console.log("asdf",studentDashboardData?.attendanceByClass)
    setSum(0)
    let temp = 0;
    if (summaryOfStudent?.subjectSummary?.length != 0) {
      summaryOfStudent?.subjectSummary?.forEach(
        (item) => (
          (temp = temp + parseFloat(item?.present ? item?.present : 0))
        )
      );
    }
    setSum(temp);
  }, [summaryOfStudent?.subjectSummary]);

  useEffect(() => {
    studentStartDate(moment().startOf('week').format('YYYY-MM-DD'))
    studentEndDate(moment().endOf('week').format('YYYY-MM-DD'))


  }, [])
  useEffect(() => {
    subjectSummary(moment().format('MMMM'))

  }, [])






  const data = summaryOfStudent?.subjectSummary?.map((item) => ({

    "type": item.subjectName,
    "key": item.key,
    "Present": item?.present,
    "icon": item.icon,
    "total": item.total,
    "Absent": item.absent,
    "name": item.teacherName,
    "presentPercentage": item.presentPercentage
  }))

  return (
    <div
      style={{
        width: "100%",
        height: "370px",
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",

      }}
    >
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
        <div>
          <div
            style={{
              color: "#191919",
              fontWeight: "bold",
              fontSize: "15px",
              padding: 10,
              marginLeft: 20,
            }}
          >
            Subject summary
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", marginLeft: 40, marginTop: "-10px" }}>
              <div style={{ marginLeft: "5px", fontSize: "9px", color: "#636363" }}>
                <CaretLeftOutlined
                  onClick={() => {
                    subjectSummary(moment(studentSubjectSummary, 'MMMM').subtract(1, 'months').format('MMMM'))
                    togglePageLoader(true)
                    getStudentSummary(() => {
                      togglePageLoader(false)
                    })
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "9px",
                  color: "#636363",
                }}
              >
                {studentSubjectSummary}
              </div>
              <div style={{ marginLeft: "5px", fontSize: "9px", color: "#636363" }}>
                <CaretRightOutlined
                  onClick={() => {
                    subjectSummary(moment(studentSubjectSummary, 'MMMM').add(1, 'months').format('MMMM'))
                    togglePageLoader(true)
                    getStudentSummary(() => {
                      togglePageLoader(false)
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginRight: "30px", marginTop: "20px" }}>
          <Select style={{ width: "150px", fontSize: "14px", fontWeight: "bold" }}
            value={studentSubjectSummary}
            onChange={(item) => {
              subjectSummary(item)
              togglePageLoader(true)
              getStudentSummary(() => {
                togglePageLoader(false)
              })
            }}
          >
            <Option value="January">January</Option>
            <Option value="February">February</Option>
            <Option value="March">March</Option>
            <Option value="April">April</Option>
            <Option value="May">May</Option>
            <Option value="June">June</Option>
            <Option value="July">July</Option>
            <Option value="August">August</Option>
            <Option value="September">September</Option>
            <Option value="October">October</Option>
            <Option value="November">November</Option>
            <Option value="December">December</Option>
          </Select>
        </div>

      </div>
      {data && data?.length != 0 ?
        <div
          style={{ width: "95%", height: "80%", marginTop: 50, marginLeft: 20 }}
        >
          {sum === 0 ? (
            <div style={{ marginTop: "10px" }}>
              <NodataCard />
            </div>
          ) :
            (
              <div style={{ width: "95%", height: "80%", marginTop: 50, marginLeft: 20 }}>
                <SubjectGraph data={data?.length != 0 ? data : []} />
              </div>
            )
          }
          {/* <SubjectGraph  data={data?.length!=0 ? data : [] }/> */}
        </div> : <div style={{ marginTop: "30px" }}>
              <NodataCard />
            </div>}
      {/* {sum && sum.length === 0 ? <NodataCard/> : null} */}
    </div>
  );
};
function StudentAnalyticGraph({ subjectSummary, studentSubjectSummary, monthlySummary, getStudentSummary, summaryOfStudent, studentStartDate, studentattEndDate, studentEndDate, studentattStartDate, studentMonthlySummary }) {
  const [pageLoader, togglePageLoader] = useState(false)

  useEffect(() => {
    togglePageLoader(true)
    getStudentSummary(() => {
      togglePageLoader(false)
    })
  }, [])

  return (
    <div style={bodyStyle}>
      <div><PageHeader /></div>

      <Row style={{ marginTop: "20px" }}>
        <Col md={{ span: 24 }} xl={{ span: 8 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <DonutGraphCard
            summaryOfStudent={summaryOfStudent}
            monthlySummary={monthlySummary}
            studentMonthlySummary={studentMonthlySummary}
            getStudentSummary={getStudentSummary}
            togglePageLoader={togglePageLoader}
          />
        </Col>
        <Col md={{ span: 24 }} xl={{ span: 16 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <WeeklyCard
            summaryOfStudent={summaryOfStudent}
            studentEndDate={studentEndDate}
            studentStartDate={studentStartDate}
            studentattEndDate={studentattEndDate}
            studentattStartDate={studentattStartDate}
            getStudentSummary={getStudentSummary}
            togglePageLoader={togglePageLoader}

          />
        </Col>
      </Row>

      <div style={{ marginTop: "20px" }}>
        <SubjectGraphCard
          studentSubjectSummary={studentSubjectSummary}
          subjectSummary={subjectSummary}
          getStudentSummary={getStudentSummary}
          summaryOfStudent={summaryOfStudent}
          togglePageLoader={togglePageLoader}
        />
      </div>
      <PageDataLoader visible={pageLoader} />

    </div>

  )
}

const mapStateToProps = (state) => {
  const { summaryOfStudent, studentattStartDate, studentattEndDate, studentMonthlySummary, studentSubjectSummary } = state.attendanceNew;
  return { summaryOfStudent, studentattStartDate, studentattEndDate, studentMonthlySummary, studentSubjectSummary };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentSummary: (callBack) => dispatch(getStudentSummary(callBack)),
  studentEndDate: (val) => dispatch(studentEndDate(val)),
  studentStartDate: (val) => dispatch(studentStartDate(val)),
  monthlySummary: (val) => dispatch(monthlySummary(val)),
  subjectSummary: (val) => dispatch(subjectSummary(val))

});

export default connect(mapStateToProps, mapDispatchToProps)(StudentAnalyticGraph);

