import React from "react";
import { connect } from "react-redux";
import { leaveModalFun, studentTestUpdateAns, postStudentTest } from "actions/mobileTest";
import { Button } from 'antd';
import HyperModal from "react-hyper-modal";
import { MdTimer } from "react-icons/md";
import "antd/lib/modal/style/index";
import { Utils } from "services";

class LeaveModal extends React.Component{

  hitActionButton = (quesIndex, jump, history) => {
    const {studentTestAnsObj, studentTestQuesArr, studentTestQuesIndex, testUploadedBase64Arr, testUploadedImgArrNew, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal} = this.props;
    this.props.studentTestUpdateAns();
    var params;
    if(studentTestQuesArr[studentTestQuesIndex].type === 'Image'){
        let imgObjArr = [];
        testUploadedBase64Arr.map((key, index) => {
          testUploadedImgArrNew.map((key1, index1) => {
            if(key1.localImg === key.localImg){
              if(key.apiImg === ''){
                imgObjArr.push(key)
              }
            }
          })
        })
        
        let duplicateObj = {};
        for (let i=0, len=imgObjArr.length; i < len; i++ ){
            duplicateObj[imgObjArr[i]['localImg']] = imgObjArr[i];
        }
        imgObjArr = new Array();
        for (let key in duplicateObj ){
          imgObjArr.push(duplicateObj[key].localImgObj);
        }
        
       params = {
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        files: imgObjArr,
        deletedFiles: JSON.stringify(testUploadedImgdeleteArr),
        testStatus:'Draft',
      }
    }else{
       params = {
        answer: studentTestAnsObj.answer,
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        testStatus:'Draft',
      }
    }
    console.log('params+++++++++', params);
    const type = studentTestQuesArr[studentTestQuesIndex].type === 'Image' ? 'image' : 'mcq';
    this.props.postStudentTest(params, type, (response) => {
        this.props.leaveModalFun(false);
        history.push('/web-test');
        // clearInterval(this.props.timer);
        Utils.stopDuration();
    });
  }

  render(){
    const {history, studentTestQuesIndex} = this.props;

    return(
      <HyperModal
        isOpen={this.props.leaveModalBool}
        unmountOnClose
        requestClose={() => this.props.leaveModalFun(false)}
        position={{alignItems: "flex-end", justifyContent: "center"}}
        renderCloseIcon={() => null}
      >
        <div className="full-width r-c-c p-t-10">
          <div className="topNotch inline-flex" />
        </div>
        <div className='p-10 text-center'>
          <div className="r-c-c m-t-10">
            <div className="r-ac">
              <MdTimer className="text-md seaGreen"/>
              <div className='higrey m-l-5'>{this.props.studentTestDurationTimer}</div>
            </div>
          </div>
          <div className="text-sm m-t-20 color-black bold-700">Do you want to leave the test?</div>
          <div className="higrey text-xmd m-b-20">
            You may but the clock will keep ticking.
          </div>
          <Button
            onClick={() => this.hitActionButton(studentTestQuesIndex+1, 'submit', history)}
            className='mobileTestGreenBtnWrap m-10'
            >
            LEAVE TEST
          </Button>
        </div>

      </HyperModal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { leaveModalBool, studentTestDurationTimer, studentTestQuesArr, studentTestAnsObj, studentTestQuesIndex,
    testUploadedBase64Arr, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal, 
    testUploadedImgArrNew } = state.mobileTest;
  return {
    leaveModalBool, studentTestDurationTimer, studentTestQuesArr, studentTestAnsObj, studentTestQuesIndex,
      testUploadedBase64Arr, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal, 
      testUploadedImgArrNew
  };
};

const mapDispatchToProps = dispatch => ({
  leaveModalFun: (bool) => dispatch(leaveModalFun(bool)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  postStudentTest: (params, type, callback) => dispatch(postStudentTest(params, type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveModal);
