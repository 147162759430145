import React, { useEffect } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from 'pages/Test/localComponent/searchBox';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { NodataCard } from './homepage'
import { fetchChapterInSyllabusData, setFilter } from 'actions/studentPracticeTest';
import Loader from 'pages/Test/localComponent/loader';
import { Tooltip } from 'antd';
const icon = require("../../Assets/studentPracticeTest/ic-practice-test-g.svg").default;

const ChaptersCard = ({ data, index, history, setFilter }) => {
  return (
    <>
      <div className='cursor-pointer m-l-10 r-afs flex-wrap full-height m-t-20' style={{ overflow: "auto" }} onClick={() => {
        setFilter('tabSearch', null);
        setFilter('selectedChapter', data);
        history.push('/student-practice-tabs')
      }}>
        <div style={{
          width: 330,
          backgroundColor: "#FFFFFF",
          border: "1px solid #E6E6E6",
          borderRadius: 10,
          height: 170
        }}>
          <div className='m-l-20 m-t-10 p-b-30'>
            <div >Chapter {"  "}
              {data.chapterNo}
            </div>
            <div className='m-t-10 bold-700 text-overflow-ellipsis'>
              {data.chapterName}
            </div>
          </div>
          <div className='m-l-20 m-t-20' style={{ display: "flex" }}>
            <Tooltip title='Test Count'>
              <img src={icon} />
            </Tooltip>
            <div className='m-l-5'>{data.testCount}</div>
          </div>
        </div>
      </div>
    </>

  )
}
let timer = false;
const PracticeChapterPage = ({ ChapterInSyllabusData, fetchChapterInSyllabusData, SubjectInSyllabus, filter, setFilter, loaders }) => {
  useEffect(() => {
    fetchChapterInSyllabusData(() => { });
  }, []);
  const onSearch = (e) => {
    setFilter('chapterSearch', e.target.value);
  }
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchChapterInSyllabusData(() => { });
    }, 1000);
  }, [filter?.chapterSearch]);
  const history = useHistory();
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }} >
          <div style={{ fontWeight: "800", fontSize: "20px", color: "#191919" }} className="m-b-20"><ArrowLeftOutlined
            className="cursor-pointer m-r-20"
            onClick={() => {
              history.goBack();
            }}
          /> </div>

          <div style={{ fontStyle: "roboto", fontSize: "21px", color: "#191919", fontWeight: "bold" }}>{filter?.selectedSubject?.subjectName}
            <div style={{ color: "#636363", fontSize: "12px" }}>{filter?.selectedSyllabus}</div>
          </div>

        </div>
        <div >
          <SearchBox loading={false}
            onChange={onSearch}
            placeholder="Search by chapter name"
            style={{ width: 350, marginRight: 75 }}
            value={filter?.chapterSearch} />
        </div>

      </div>
      {
        loaders?.chapterPageLoader ?
          <Loader style={{ fontSize: 40 }} />
          :
          Array.isArray(ChapterInSyllabusData) ?
            <div style={{ display: "flex" }} className="flex-wrap">
              {ChapterInSyllabusData?.map((item, index) => (
                <ChaptersCard data={item} index={index} history={history} setFilter={setFilter} />
              ))}
            </div>
            : null
      }
      {ChapterInSyllabusData && Array.isArray(ChapterInSyllabusData) && ChapterInSyllabusData.length ? null : <NodataCard />}
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    ChapterInSyllabusData, SubjectInSyllabus, filter, loaders
  } = state.studentPracticeTest;
  return {
    ChapterInSyllabusData,
    SubjectInSyllabus, filter, loaders
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchChapterInSyllabusData: (callback) => dispatch(fetchChapterInSyllabusData(callback)),
  setFilter: (key, val) => dispatch(setFilter(key, val))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeChapterPage);
