import moment from "moment";
import { store } from "App";

export class TestTimer {
  timer = null;

  static startTimer = (
    ongoingTest,
    setDuration,
    updateCurrentDuration = null,
    handleAutoSubmit = null
  ) => {
    if (ongoingTest) {
      this.timer = setInterval(
        () =>
          this.decrementSeconds(
            ongoingTest,
            setDuration,
            updateCurrentDuration,
            handleAutoSubmit
          ),
        1000
      );
    }
  };

  static decrementSeconds = (
    ongoingTest,
    setDuration,
    updateCurrentDuration,
    handleAutoSubmit
  ) => {
    const now = moment();
    let durationSeconds = moment
      .duration(moment(ongoingTest.endTime, "YYYY-MM-DD HH:mm:ss").diff(now))
      .asSeconds();
      console.log('ontime',durationSeconds,ongoingTest.endTime);
    if (Math.round(durationSeconds - 1) <= 0) {
      this.clearTimer(setDuration);
      if (handleAutoSubmit) {
        handleAutoSubmit();
        clearInterval(this.timer);
      }
    }

    // durationSeconds = durationSeconds - 1
    setDuration(durationSeconds);
    const seconds = store.getState().studentTest.currentDuration;
    if (updateCurrentDuration) {
      updateCurrentDuration(seconds + 1);
    }
  };

  static clearTimer = (setDuration) => {
    setDuration(null);
    clearInterval(this.timer);
  };
}
