import React from 'react';
import { FormulaEditor } from '../../../components';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import InputNumber from 'antd/lib/input-number';
import 'antd/lib/modal/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/radio/style/index';
import 'antd/lib/input-number/style/index';
import { Color, Notification } from '../../../services';
import "react-quill/dist/quill.snow.css";
import { EditOutlined,  CloseOutlined} from '@ant-design/icons';
import { IoMdAddCircle } from 'react-icons/io';
import { FiAlignLeft, FiChevronDown } from "react-icons/fi";
import { FaRegDotCircle } from "react-icons/fa";
import { createNewAssignmentTask, patchAssignmentTask, toggleQuestionModal,
  setCurrentQuestion, setCurrentOptions, removeCurrentQuestion, setCurrentStatement, setCurrentMarks, setAnswerType, setCurrentAnswer, setCurrentImage,
  toggleCorrectOption, setOptionImage, deleteImages, displayOptionModal, setOptionValue,
  displayOptionIndexRead } from '../../../actions/assignment';
import '../styles.css';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';

// import Quill from '../../../Assets/js/quill.js'

const answerTypeList = [
  {
    id: 0,
    key: '0',
    icon: <span style={{
      border: '1px solid #5448B7',
      borderRadius: 3,
      padding: 1,
      fontSize: 10,
      color: '#5448B7'}}>
      01
    </span>,
    text: 'Numeric',
    send: 'Subjective',
  },
  {
    id: 1,
    key: '1',
    icon: <FaRegDotCircle/>,
    text: 'Objective',
    send: 'MCQ',
  },
  {
    id: 2,
    key: '2',
    icon: <FiAlignLeft />,
    text: 'Subjective',
    send: 'Image',
  },
];

class AssignmentAdd extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      imageAdd: false,
      imgLoading: false,
      editorModal: false,
      editIndex: -1,
      editorValue: ""
    }
  }

  componentDidMount(){
    FormulaEditor.uploadUrl = '/assignment/upload-image';
  }

  addAssignmentTask() {
    const { createNewAssignmentTask, currentAssignment, currentQuestionData, setCurrentQuestion, patchAssignmentTask, 
       assignmentTaskOptions, assignmentTaskParams, answerTypeId, deletedImages, questionImagesList,
     oldImageList, optionsImagesList } = this.props;

    let params = {
      statement: assignmentTaskParams.statement,
      answer: assignmentTaskParams.answer,
      type: answerTypeList[answerTypeId].send,
      positiveMarks: currentAssignment.gradingType === "Graded" ? assignmentTaskParams.positiveMarks : 0,
      negativeMarks: assignmentTaskParams.negativeMarks,
      details: '',
      remarks: '',
      assignmentId: currentAssignment.id,
      markedForReview: false,
      deleteImages: [...deletedImages],
      info: {}
    }

    if(currentAssignment.gradingType === "Graded" && !assignmentTaskParams.positiveMarks){
      return Notification.error('Error', 'This is a graded assignment. Please add the marks');
    }

    if(currentAssignment.gradingType === "Graded" && assignmentTaskParams.positiveMarks%1 !== 0 ){
      return Notification.error('Error', 'Grade should not be an integer value');
    }

    if (answerTypeId === 1) {
      if (assignmentTaskOptions.length <  2) {
        return Notification.error('Error', 'Atleast two options are required for an objective type question');
      } else {
        for(const item of assignmentTaskOptions){
          if(item.value === "" || item.value === "<p><br></p>"){ 
            return Notification.error('Error', 'All or some options are blank. Either add an image or a statement or remove the option');
          }
        }
        params["options"] = assignmentTaskOptions;
      }

    }

    if ((answerTypeId === 0 || answerTypeId === 1) && assignmentTaskParams.answer === '') {
      return Notification.error('Error', 'Provide the correct answer');
    }

    if(currentQuestionData && currentQuestionData.id) {
      Object.assign(params, {oldMarks: assignmentTaskParams.oldMarks})
      patchAssignmentTask(params);
    } else {
      createNewAssignmentTask(params, this.props.view);
      setCurrentQuestion({type: answerTypeList[answerTypeId].send, options: assignmentTaskOptions, answer: ""});
    }
    questionImagesList.splice(0, questionImagesList.length )
    deletedImages.splice(0, deleteImages.length)
    oldImageList.splice(0, oldImageList.length)
    for(const i in [0,1,2,3]){
      if(optionsImagesList[i].length)
        optionsImagesList[i].splice(0, optionsImagesList[i].length)
    }
  };

  addOption = () => {
    const {
      assignmentTaskOptions,
      displayOptionIndexRead
    } = this.props;

    assignmentTaskOptions.push({
      id: assignmentTaskOptions.length,
      value: '',
    });
    this.openEditor(assignmentTaskOptions.length-1);
    // this.props.displayOptionModal(true);
    displayOptionIndexRead(assignmentTaskOptions.length);
  };

  removeOption = (id) => {
    let { assignmentTaskOptions, setCurrentOptions, setOptionImage, optionsImagesList, deletedImages, assignmentTaskParams, setCurrentAnswer } = this.props;

    const size = assignmentTaskOptions.length;
    optionsImagesList[id].forEach(item => deletedImages.push(item));
    if(String.fromCharCode(97 + id) === assignmentTaskParams.answer){
      setCurrentAnswer("")
    }

    for (let i = id; i < size - 1; i++) {
      assignmentTaskOptions[i] = assignmentTaskOptions[i + 1];
      optionsImagesList[i] = optionsImagesList[i+1]
      assignmentTaskOptions[i].id = i;
    }
    for(let j = size - 1; j <4; j++){
      optionsImagesList[j] = []
    }
    assignmentTaskOptions.splice(size - 1, 1);

    // for(let i = id; i < 3; i++){
    // }
    setOptionImage(id, null, null)
    setCurrentOptions(assignmentTaskOptions);
  };

  answerTypes = () => (
    <Menu className='assignmentddlmenu'>
      {answerTypeList.map((data, index) =>
        <Menu.Item key={index.toString()}>
          <div onClick={() => {this.props.setAnswerType(data.send, data.id); this.props.setCurrentAnswer("");}} className='color-purple'>
            {data.icon} {data.text}
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  async setCorrectOption(index){
    const { assignmentTaskOptions, toggleCorrectOption, setCurrentAnswer  } = this.props;
    await toggleCorrectOption(index)
    setCurrentAnswer(String.fromCharCode(97 + index));
    setCurrentOptions(assignmentTaskOptions);
  }


  clearImages(){
    const { questionImagesList, optionsImagesList, deleteImages, assignmentTaskParams, oldImageList,
    deletedImages } =  this.props;
    let deletedList = [];
    if(assignmentTaskParams.id){ //Edit question case
      deletedImages.forEach(img => {
        if(!oldImageList.includes(img)){
          deletedList.push(img)
        }
      })
    }else{ //Add Question case
      deletedList = [...deletedImages];
      questionImagesList.forEach(img => {
        if(!deletedImages.includes(img)){
          deletedList.push(img)
        }
      })
      for(const i in [0,1,2,3]){
        optionsImagesList[i].forEach(img => {
          if(!deletedImages.includes(img)){
            deletedList.push(img)
          }
      })
      }
    }
    if(deletedList.length){
     deleteImages(deletedList);
    }
    questionImagesList.splice(0, questionImagesList.length )
    deletedImages.splice(0, deleteImages.length)
    oldImageList.splice(0, oldImageList.length)
    for(const i in [0,1,2,3]){
      if(optionsImagesList[i].length)
        optionsImagesList[i].splice(0, optionsImagesList[i].length)
    }
  }


   resetEditor = async () => (
    await this.setState({
      editIndex: -1,
      editorValue: "",
      editorModal: false
    })
  )

  openEditor = (index) => {
    this.setState({
      editorModal: true, 
      editIndex: index})
  }


  editorModal = () => {
    const {deleteImages, deletedImages, assignmentTaskParams, setCurrentStatement, setOptionValue,
    questionImagesList, optionsImagesList, assignmentTaskOptions, user} = this.props;
    const { editorModal, editIndex } =  this.state;
    let id = editIndex === -1 ? "statement" : editIndex.toString();
    const onChange = (editorValue) => {
      if(editIndex === -1){
        setCurrentStatement(editorValue);
      }
      else {
        id = editIndex.toString();
        setOptionValue(editorValue, editIndex)
        setCurrentOptions(assignmentTaskOptions);
      }
    }
    const data = editIndex === -1 ? assignmentTaskParams.statement :
                assignmentTaskOptions[editIndex] ? assignmentTaskOptions[editIndex].value : ''


    return (
      
        editorModal ? 
        <Modal
          visible={editorModal}
          width={"50%"}
          title="Editor"
          closable={false}
          footer={false}
        >
          <FormulaEditor
              id={id}
              data={data}
              disableOptAdd={assignmentTaskOptions?.length>=4}
              imagesList={editIndex === -1 ? questionImagesList : optionsImagesList[editIndex] || []}
              deletedImages={deletedImages}
              deleteImages={(imgList, index) => deleteImages(imgList, index)}
              resetEditor={() => this.resetEditor()}
              onChange={(value) => onChange(value)}
              clearImages={() => this.clearImages()}
              imgPath={"/uploads/assignmentQuestion/"}
              user={user}
              addOption={this.addOption}

          />
        </Modal> : null

      
    
  )}


  render() {
    const {  showQuestionModal, toggleQuestionModal, currentAssignment, assignmentTaskLoader, assignmentTaskParams,
      answerTypeId, assignmentTaskOptions, removeCurrentQuestion, setCurrentStatement, setCurrentImage,
      setCurrentAnswer, setCurrentMarks,
     } = this.props;
    // const uploadButton = (
    //   <div>
    //     {this.state.imgLoading ? <LoadingOutlined /> :
    //       <MdImage style={{color:Color.primary, fontSize:'1.6em'}} />
    //     }
    //   </div>
    // );
     let saveButtonDisable = true;
     if(assignmentTaskParams.statement === ''){
      saveButtonDisable = true
     }else if(assignmentTaskParams.statement == "<p><br></p>"){
      saveButtonDisable = true
     }else{
      saveButtonDisable = false
     }


    return (
      <Modal
        centered
        width={'50%'}
        maskClosable={false}
        // style={{}}
        footer={[
          <div className='r-c-fe'>
            {/*<Button
              className='radius-10 m-r-10'
              type="default"
              onClick={() => {
                setCurrentStatement('');
                removeCurrentQuestion()
                toggleQuestionModal(false)
                setCurrentImage(null, null)
                this.clearImages()
              }}
              >
              Cancel
            </Button>*/}
            {assignmentTaskParams.id ? null :
             <Button
              className='radius-10 m-r-10'
              type="ghost"
              key="cancel"
              loading={assignmentTaskLoader}
              onClick={() => {
                setCurrentStatement('');
                removeCurrentQuestion()
                toggleQuestionModal(false)
                setCurrentImage(null, null)
                this.clearImages()
              }}
            >
             Cancel
            </Button>}
            <Button
              disabled={saveButtonDisable}
              key="save"
              className='radius-10 m-r-10'
              type="primary"
              loading={assignmentTaskLoader}
              onClick={() => this.addAssignmentTask()}
            >
              Save & Next
            </Button>
           
          </div>
        ]}
        // onCancel={() => {
        //   setCurrentStatement('');
        //   removeCurrentQuestion()
        //   toggleQuestionModal(false)
        //   setCurrentImage(null, null)
        //   this.clearImages()
        // }}
        closable={false}
        // closable={assignmentTaskParams.id ? false : true}
        visible={showQuestionModal}
        style={{
          height:'100%',
          overflowY: 'auto',
        }}
        className='radius-10 qmodal'
        title={<div className='color-purple'>
          {assignmentTaskParams.id ? 'Edit Question' : 'Add Question'}
        </div>}
      >
        <div>
          <div className='r-jsb'>
            <div style={{flex: 1}} className='color-greenblack'>
              <div className='p-5 questionLightGreyBox'>
                <div style={{display: 'flex'}}>
                  <div className='color-purple text-xmd'>
                    Statement <span style={{color: Color.red}}>*</span>
                  </div>
                  <div className=' text-xmd color-purple cursor-pointer'>
                    <EditOutlined onClick={() => this.openEditor(-1)}/>
                  </div>
                </div>

                <div className="quest">{ ReactHtmlParser(assignmentTaskParams.statement, { transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }

                    if (node.type === 'tag' && node.name === 'img') {
                      return <img style={{maxHeight: '40vh', maxWidth: '40vw'}} src={node.attribs.src} alt="" />;
                    }
                  }}) }</div>
              </div>
              {(assignmentTaskParams.type !== 'MCQ' && answerTypeId !== 1) ?
                <div className='p-5 questionLightGreyBox'>
                  <div className='color-purple text-xmd'>
                    Answer {answerTypeId === 2 ?  '(optional)' : <span style={{color: Color.red}}>*</span>}
                  </div>
                    <input
                      className='modalTextbox'
                      value={assignmentTaskParams.answer}
                      type={answerTypeId ? "text" : "number"}
                      onChange={(e) => setCurrentAnswer(e.target.value)}
                    />
                </div>
                : null
              }
            </div>
            <div>
            {
              currentAssignment.gradingType === "Graded" ?
              <>
              <div style={{margin: '0px 0px 0px 10px'}} className='p-5 questionLightGreyBox'>
                <div  className='color-purple text-xmd'>
                  Marks
                </div>
              </div>
              <InputNumber type="number" style={{margin: '10px 20px 10px 10px', borderRadius: 10}} min={0} value={assignmentTaskParams.positiveMarks} onChange={(value) =>
                {setCurrentMarks(value)}} />
              </>
            : null}
              <div style={{margin: '0px 0px 10px 10px'}} className='p-5 questionLightGreyBox'>
                <div className='color-purple text-xmd'>
                  Answer Type
                </div>
              </div>
              <Dropdown
                className='m-l-10 p-l-10 p-r-10 cursor-pointer'
                overlay={this.answerTypes}
                trigger={['click']}
              >
                <div
                  style={{
                    backgroundColor: '#F9F9F9',
                    width: 170,
                    alignSelf: 'baseline'
                  }}
                  className='questionLightGreyBox color-purple r-c-se p-t-5 p-b-5 text-xmd'
                >
                  {answerTypeList[answerTypeId].icon}
                  {answerTypeList[answerTypeId].text}
                  <FiChevronDown color='#5448B7'/>
                </div>
              </Dropdown>
            </div>
          </div>
          {answerTypeId === 1 ?
            <div className='r-jsb' style={{marginTop: 30}}>
              <Button
                icon={<IoMdAddCircle color='#FFFFFF' size='1.2em' style={{margin: 5}}/>}
                type='primary'
                onClick={() => {
                  this.addOption();
                }}
                className='text-xmd r-c-c radius-10 m-t-5 m-b-5'
                disabled={assignmentTaskOptions && assignmentTaskOptions.length>=4 }
              >
               Add Option
              </Button>
              <div style={{fontSize: 12, color: Color.red, display: 'flex', alignItems: 'flex-end'}}>
              *Mark the correct option
              </div>
            </div>
            : null
          }
          <div>
            {answerTypeId === 1 && assignmentTaskOptions && assignmentTaskOptions.length ?
              assignmentTaskOptions.map((data, index) =>
                <div key={index.toString()} style={{padding: 0}} className='assignmentItem m-t-10'>
                  <div
                    className='r-jsb'
                    style={{
                      borderBottom: this.state[`file${index + 1}`] ? "1px solid #E4E4E4" : "0px solid"
                    }}
                  >
                    <div
                      className='color-purple text-xmd p-5'>
                      {String.fromCharCode(65 + index)} :
                    </div>
                    <div className='flex-1 color-greenblack'>
                      <div className='color-purple text-xmd m-t-5'>
                        Option: <EditOutlined onClick={() => this.openEditor(index)}/>
                        { assignmentTaskOptions[index] ?
                          ReactHtmlParser(assignmentTaskOptions[index].value, { transform: (node) => {
                              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                return <TeX math={`${node.attribs['data-value']}`} />;
                              }
                              // if (node.type === 'tag' && node.name === 'math') {
                              //   return <MathJax math={String.raw`${assignmentTaskOptions[index].value}`} />;
                              // }
                              if (node.type === 'tag' && node.name === 'img') {
                                return <img style={{maxWidth: '40vw',  maxHeight: '40vh'}} src={node.attribs.src} alt="" />;
                              }
                            }}) : '' }

                          {/*<div id={`quill-editor${index.toString()}`} />*/}
                        {/*<CustomCKEditor
                          toolbar={true}
                          disable={false}
                          ckdata={data.value}
                          onChange={(value) => {
                             assignmentTaskOptions[index].value = value;
                             setCurrentOptions(assignmentTaskOptions);
                           }}
                          imagesList={optionsImagesList[index]}
                          user={user}
                          deletedImages={deletedImages}
                          deleteImages={(imgList, index) => deleteImages(imgList, index)}
                         />*/}
                      </div>
                    </div>
                    <Radio
                      style={{padding: 5,}}
                      key={index.toString()}
                      value={index}
                      checked={data ? data.correct : false}
                      onClick={(e) => this.setCorrectOption(index)}
                    />
                    <div
                      onClick={() => this.removeOption(index)}
                      className='text-xmd text-center p-5 color-purple'
                    >
                      <CloseOutlined style={{fontSize: '1.3em', cursor: 'pointer'}} />
                    </div>
                  </div>
                </div>
              )
            : null}
          </div>
        </div>
        {this.editorModal()}
      </Modal>

      /*<Modal
      visible={displayOptionModalBool}
      width={"70%"}
      title="Editor"
      closable={false}
      footer={
        <div>
          <Button type="primary" onClick={() => this.props.displayOptionModal(false)}>Done</Button>
        </div>
      }
      >

      <FormulaEditor
          id={`option${displayOptionIndex}`}
          optText={quillEditorText['option'+displayOptionIndex]}
          // data={info.data}
          onChange={(value) => {
            // this.setState({info: {...this.state.info, data: value}})
            // setCurrentQuestion(value)
          }}
          // imagesList={optionsImagesList[index]}
          user={user}
          deletedImages={deletedImages}
          imagesList={questionImagesList }
          // deleteImages={(imgList, index) => deleteImages(imgList, index)}
       />
      </Modal>*/
    )
  }
}

const mapStateToProps = state => {
  const {
    currentAssignment, showQuestionModal, currentAssignmentTasks, assignmentTaskLoader, assignmentTaskParams, answerTypeId, assignmentTaskOptions,
    currentQuestionData, assignmentPageTaskDetails, imageUrl, optionsImage, ckeditorStatement, quillEditorText,
    ckeditorOption, questionImagesList, optionsImagesList, deletedImages, oldImageList, displayOptionModalBool,
    displayOptionIndex, } = state.assignment;
  const { orgId } = state.session.user
  const { user } = state.session;
  return { currentAssignment, currentQuestionData, assignmentTaskLoader, showQuestionModal, currentAssignmentTasks, assignmentTaskParams,
    answerTypeId, assignmentTaskOptions, assignmentPageTaskDetails, orgId, imageUrl,optionsImage, quillEditorText,
    ckeditorStatement, ckeditorOption, questionImagesList, optionsImagesList, user, deletedImages, oldImageList, displayOptionModalBool,
     displayOptionIndex,};
};

const mapDispatchToProps = dispatch => ({
  createNewAssignmentTask: (params, view) => dispatch(createNewAssignmentTask(params, view)),
  patchAssignmentTask: (params) => dispatch(patchAssignmentTask(params)),
  toggleQuestionModal: (state) => dispatch(toggleQuestionModal(state)),
  setCurrentQuestion: (data) => dispatch(setCurrentQuestion(data)),
  removeCurrentQuestion: () => dispatch(removeCurrentQuestion()),
  setCurrentStatement: (value) => dispatch(setCurrentStatement(value)),
  setCurrentImage: (value, image) => dispatch(setCurrentImage(value, image)),
  setCurrentMarks: (value) => dispatch(setCurrentMarks(value)),
  setCurrentAnswer: (value) => dispatch(setCurrentAnswer(value)),
  setAnswerType: (value, id) => dispatch(setAnswerType(value, id)),
  setCurrentOptions: (value, index) => dispatch(setCurrentOptions(value, index)),
  toggleCorrectOption: (index) => dispatch(toggleCorrectOption(index)),
  setOptionImage: (index, value, image) => dispatch(setOptionImage(index, value, image)),
  deleteImages: (imgList, index) => dispatch(deleteImages(imgList, index)),
  displayOptionModal: (bool) => dispatch(displayOptionModal(bool)),
  displayOptionIndexRead: (index) => dispatch(displayOptionIndexRead(index)),
  setOptionValue: (value, index) => dispatch(setOptionValue(value, index))

});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentAdd);
