import React, { useState } from "react";
import RequestUserCard from "./requestUserCard";
import image from "Assets/user-management/Group 11607.svg";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { getStats } from "actions/userManagement";
import image2 from "Assets/user-management/add-students.svg";
import { IMG_URL } from "../../env.json";
import { GraphHeading } from "components/Typography";
import { Button } from "antd";

function ApprovalPanel({dashboardData, setIsApprovalVisible, fieldDisabledForFee}) {
  const [toggleApprovalMode, setToggleApprovalModal] = useState(dashboardData.studentAutoApproved);

  const history = useHistory();

  const handleApprovalClick = () => {
    setToggleApprovalModal(false);
    setIsApprovalVisible(true);
  };

  const handleAutoApprovalClick = () => {
    setToggleApprovalModal(true);
    setIsApprovalVisible(true);
  };
  return (
    <div
      style={{
        boxShadow: "1px 1px 8px #c7cece",
        minWidth: "90%",
        margin: "10px",
        minHeight: "94%",
        padding: "10px",
        borderRadius: "16px",
        minWidth: 400
      }}
    >
      {/* {!dashboardData.studentAutoApproved ? ( */}
      <div style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <GraphHeading className="m-l-5">
            Registration Request(s) ({dashboardData?.totalRegistrationrequest})
          </GraphHeading>
          <Button type="link"
            onClick={() => {
              if (fieldDisabledForFee) {
              } else {
                history.push("/user-management/all-requests");
              }
            }}
            style={{
              color: "#1089FF",
              fontWeight: 600,
              cursor: fieldDisabledForFee ? 'not-allowed' : "pointer",
            }}
          >
            VIEW ALL
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }} className="m-l-5">
          <p style={{ color: "#636363", fontSize: "13px" }}>
            {dashboardData?.studentAutoApproved == false ? "Manual approval" : "Auto approval"}
            <span
              onClick={() => {
                if (fieldDisabledForFee) {
                } else {
                  handleApprovalClick();
                }
              }}
              style={{
                color: "#1089FF",
                fontSize: "13px",
                fontWeight: "500",
                marginLeft: "20px",
                cursor: fieldDisabledForFee ? 'not-allowed' : "pointer",
              }}
            >
              CHANGE SETTING
            </span>
          </p>
        </div>

        <div style={{ margin: "0 -10px" }}>
          {dashboardData &&
            dashboardData?.registrationRequest?.length !== 0 &&
            dashboardData?.registrationRequest?.map((item) => (
              <RequestUserCard
                fieldDisabledForFee={fieldDisabledForFee}
                id={item?.id}
                name={item.name}
                role={item.role[0]}
                createdAt={item.createdAt}
                avatar={`${IMG_URL}/${
                  item?.details?.image ? item?.details?.image : "default.png"
                }`}
                item={item}
              />
            ))}
        </div>
        {dashboardData && dashboardData?.registrationRequest?.length === 0 ? (
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <img style={{ width: "150px", height: "150px" }} src={image2}></img>
            <h3>No Registration Request Found</h3>
          </div>
        ) : null}
      </div>
    </div>
  );
}

// export default ApprovalPanel

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, dashboardData } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  getStats: (m) => dispatch(getStats(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalPanel);
