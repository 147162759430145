import { Select } from 'antd';
import React, { useState } from 'react';
import "./animatedSelectStyles.css";

function AnimatedCustomSelect({ label, options, required, handleSelectChange, isSelected, value, handleSelectOnSearch, handleSelectOnSelect, showSearch, allowClear, disabled, placeholder, style, source, autoFocus, onClear,onKeyDown , fromTagSelect }) {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(isSelected);
    };

    return (
        <div className={`custom-select-container ${isFocused || autoFocus || value ? 'focused' : ''}`} style={style}>
            <Select
                className='custom-select no-border-select'
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={{ width: "100%",cursor:disabled ? 'not-allowed': 'pointer', }}
                onChange={handleSelectChange}
                value={fromTagSelect ? null : value ? value : undefined}
                onSearch={handleSelectOnSearch}
                onSelect={handleSelectOnSelect}
                showSearch={showSearch ? true : false}
                allowClear={allowClear}
                onClear={onClear}
                disabled={disabled}
                placeholder = {value ? undefined :placeholder ? placeholder : ""}
                onKeyDown={onKeyDown}
           

            >
                {Array.isArray(options) && options.map(option => (
                    <Select.Option key={option?.id} id={option?.id} value={ source == 'CRM' ? option?.id : option?.label ? option?.label : option?.name ? option?.name : option?.id}>
                        {option?.label ? option?.label : option?.value || option?.name}
                    </Select.Option>
                ))}
                {value && !Array.isArray(options) && (
                    <Select.Option key={-1} value={value}>Create {value}</Select.Option>
                )}
            </Select>
            <label className={`custom-select-label`}>
                {label}{required && <span style={{ color: 'red' }}> *</span>}
            </label>
        </div>
    );
}

export default AnimatedCustomSelect;