import React from 'react';
import {connect} from 'react-redux';
import Divider from 'antd/lib/divider';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import 'antd/lib/divider/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import { Color } from '../../../services';
import { Api } from '../../../services';
import {toggleDetailModal, rejectRequest, approveRequest} from '../../../actions/notification';
import '../styles.css';
import Avatar from 'antd/lib/avatar/avatar';
import * as moment from 'moment';

function IsJsonString(str) {
  if (/^[\],:{}\s]*$/.test(str.replace(/\\["\\\/bfnrtu]/g, '@').
  replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
  replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

  return true

  }else{

    return false

  }
}

class UserModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: 0,
    }
  }

  pageRejectRequest(data){
    this.props.dispatch(rejectRequest(data.id))
  }

  pageApproveRequest(data){
    this.props.dispatch(approveRequest(data.id))
  }



  render() {
    const {detailModalVisible, detailModalData, approveLoader, rejectLoader, orgId} = this.props;
    return (
      <div>

        <Modal
          visible={detailModalVisible}
          centered
          width={800}
          closable={true}
          onCancel={() => this.props.dispatch(toggleDetailModal(false, {}))}
          footer={[
              <div className='r-c-c'>
                {detailModalData.status === 'Pending'
                ?
                  <div className='r-c-c'>
                    <Button loading={approveLoader ? true : false} className='mod-notifyApprove-btn' style={{background:Color.green}} key="back" onClick={() => this.pageApproveRequest(detailModalData)}>
                      Approve
                    </Button>
                    <Button loading={rejectLoader ? true : false} className='mod-notifyApprove-btn' style={{background:Color.red}} key="submit" onClick={() => this.pageRejectRequest(detailModalData)}>
                      Reject
                    </Button>
                  </div>
                :
                  <Button className='mod-notifyApprove-btn' style={{background:Color.darkGrey}} key="submit" loading={false} onClick={() => this.props.dispatch(toggleDetailModal(false, {}))}>
                    Close
                  </Button>
                }

              </div>,
          ]}
        >
            <div>
              <div className='r-c-c m-b-40'>
                <Avatar src={`${Api._s3_url}${detailModalData.details && detailModalData.details.image}`}  style={{width:100, height:100, borderRadius:50}}/>
              </div>
              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Name</div>
                  <div className='bold-600' style={{color:Color.black}}>{detailModalData.name}</div>
                </div>
                <div className='flex-1 r-c-sb'>
                  <div>
                    <div className='' style={{color:Color.darkGrey}}>Gender</div>
                    <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.gender : null}</div>
                  </div>
                  <div>
                    <div className='' style={{color:Color.darkGrey}}>Blood Group</div>
                    <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.bloodGroup : null}</div>
                  </div>
                  <div>
                    <div className='' style={{color:Color.darkGrey}}>Date of Birth</div>
                    <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? moment(detailModalData.details.dob).format("DD-MM-YYYY") : null}</div>
                  </div>
                </div>
              </div>
              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Email</div>
                  <div className='bold-600' style={{color:Color.black}}>{detailModalData.email}</div>
                </div>
                <div className='flex-1 r-c-sb'>
                  <div>
                    <div className='' style={{color:Color.darkGrey}}>Phone</div>
                    <div className='bold-600' style={{color:Color.black}}>{detailModalData.phone}</div>
                  </div>
                </div>
              </div>
              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Role</div>
                  <div className='bold-600' style={{color:Color.black}}>{detailModalData.role ? detailModalData.role.split(',')[0] : null}</div>
                </div>
                {detailModalData.role && !detailModalData.role.includes("Student") ?
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Permission</div>
                  <div className='bold-600' style={{color:Color.black}}>{detailModalData.role ? 
                  detailModalData.role.split(',')[1] || "None" : null}</div>
                </div> : null}
              </div>

              {detailModalData.role && detailModalData.role.includes("Student")
              ?
                <div>
                  <Divider style={{margin:'10px 0px'}}/>
                  <div className='r-c-sb'>
                    <div className='flex-1'>
                      <div className='' style={{color:Color.darkGrey}}>Class</div>
                      <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? (detailModalData.details.standard ) : null}</div>
                    </div>
                    <div className='flex-1 r-c-sb'>
                      <div>
                        <div className='' style={{color:Color.darkGrey}}>Batch</div>
                        <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.stream : null}</div>
                      </div>
                    </div>
                  </div>
                  <Divider style={{margin:'10px 0px'}}/>
                  <div className='r-c-sb'>
                    <div className='flex-1'>
                      <div className='' style={{color:Color.darkGrey}}>Father Name</div>
                      <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.fatherName : null}</div>
                    </div>
                    <div className='flex-1 r-c-sb'>
                      <div>
                        <div className='' style={{color:Color.darkGrey}}>Father Occupation</div>
                        <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.fatherOccupation : null}</div>
                      </div>
                    </div>
                  </div>
                  <Divider style={{margin:'10px 0px'}}/>
                  <div className='r-c-sb'>
                    <div className='flex-1'>
                      <div className='' style={{color:Color.darkGrey}}>Mother Name</div>
                      <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.motherName : null}</div>
                    </div>
                    <div className='flex-1 r-c-sb'>
                      <div>
                        <div className='' style={{color:Color.darkGrey}}>Mother Occupation</div>
                        <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.motherOccupation : null}</div>
                      </div>
                    </div>
                  </div>
                </div>
              :
                null
              }

              <Divider style={{margin:'10px 0px'}}/>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Current Address</div> 
                  <div className='bold-600' style={{color:Color.black}}>{detailModalData.details && IsJsonString(detailModalData.details.currentAddress)  ? Object.values(JSON.parse(detailModalData.details.currentAddress)).join(' ') : null}</div>
                </div>
                <div className='flex-1 r-c-sb'>
                  <div>
                    <div className='' style={{color:Color.darkGrey}}>Permanent Address</div>
                    <div className='bold-600' style={{color:Color.black}}>{detailModalData.details && IsJsonString(detailModalData.details.permanentAddress) ? Object.values(JSON.parse(detailModalData.details.permanentAddress)).join(' ') : null}</div>
                  </div>
                </div>
              </div>
              <Divider style={{margin:'10px 0px'}}/>
              <div className='m-b-5 bold-600' style={{color:Color.primary}}>Emergency Contact</div>
              <div className='r-c-sb'>
                <div className='flex-1'>
                  <div className='' style={{color:Color.darkGrey}}>Name</div>
                  <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.emergencyContactName : null}</div>
                </div>
                <div className='flex-1 r-c-sb'>
                  <div>
                    <div className='' style={{color:Color.darkGrey}}>Phone Number</div>
                    <div className='bold-600' style={{color:Color.black}}>{detailModalData.details ? detailModalData.details.emergencyPhone : null}</div>
                  </div>
                </div>
              </div>
            </div>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    detailModalVisible, detailModalData, approveLoader, rejectLoader
  } = state.notification;
  const {orgId} = state.session.user
  return {
    detailModalVisible, detailModalData, approveLoader, rejectLoader, orgId
  };
};

export default connect(mapStateToProps)(UserModal);
