import React from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { setEditDateOfBirth } from "actions/userManagement";

function Date_Picker({ setEditDateOfBirth, editDateOfBirth, dashBoardRole ,disabled}) {
  function onChange(date, dateString) {
    // console.log(dateString);
    if (
      moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setEditDateOfBirth(moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
    } else {
      setEditDateOfBirth(null);
    }
  }
  function disabledDate(current) {
    // Can not select days before today and today
    return !(
      // current &&
      current < moment().subtract(dashBoardRole == "Student" ? 0 : 14, "years")
    //  current && (current < moment().endOf('day'))
    );
  }
  return (
    <Space direction="vertical" style={{ width: "50%" }} size={50}>
      <DatePicker
        className="datePicker"
        onChange={onChange}
        value={
          editDateOfBirth
            ? moment(
                moment(editDateOfBirth, "YYYY-MM-DD").format("DD-MM-YYYY"),
                "DD-MM-YYYY"
              )
            : ""
        }
        placeholder={["Select a date"]}
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
        disabled={disabled}
        getPopupContainer={(triggerNode) => {
          return triggerNode.parentNode;
      }}
      />
    </Space>
  );
}

// export default Date_Picker1
const mapStateToProps = (state) => {
  const { editDateOfBirth, dashBoardRole } = state.userManagement;
  return {
    editDateOfBirth,
    dashBoardRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setEditDateOfBirth: (date) => dispatch(setEditDateOfBirth(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Date_Picker);
