import { ArrowLeftOutlined } from "@ant-design/icons";
import { PageHeader } from "antd";
import React from "react";
import OverViewGraph from "./overViewGraph";
import InstallmentCard from "./instalmentCard";
import { useHistory } from "react-router";

function StudentsProfile() {
  const history = useHistory();
  const goBack = (history) => {
    history.goBack();
  };
  return (
    <div>
      <div
        style={{
          marginTop: "-20px",
          marginLeft: "-20px",
          fontFamily: "roboto",
        }}
      >
        <PageHeader
          title={
            <div
              className="text-md"
              style={{ fontWeight: "bold", height: "45px", marginTop: "10px" }}
            ></div>
          }
          onBack={() => goBack(history)}
          backIcon={<ArrowLeftOutlined />}
        />
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="box-shadow"
          style={{
            width: "40%",
            height: "80vh",
            paddingTop: "30px",
            border: "1px solid #302A5F14",
          }}
        >
          <div className="normalHeadingB">Basic information</div>
          <div className="normalHeadingB">Course and fee</div>
          <div className="highliteNormalHeadingB">Transactions</div>
        </div>

        <div style={{ marginLeft: "10px", height: "80vh", overflowY: "auto" }}>
          <div
            className=" div_style box-shadow"
            style={{
              height: "450px",
              padding: "20px",
              // border: "1px solid #E6E6E6",
              borderRadius: "15px",
              marginTop: "10px",
            }}
          >
            <div className="r-jc">
              <div style={{ width: "25%" }}>
                <div>
                  <div className="font_roboto bold-600">overview</div>
                  <div
                    className="font_roboto"
                    style={{ fontSize: "12px", color: "#636363" }}
                  >
                    11/09/2021 - 17/09/2021
                  </div>
                </div>
                <div style={{ marginTop: "60px" }}>
                  <div className="r-ac">
                    <div className="dot" style={{ marginRight: "3px" }}></div>
                    <div style={{ color: "#594099" }}>
                      <span className="bold-700"> 124</span> payment requested
                    </div>
                  </div>
                  <div className="bold-700" style={{ fontSize: "20px" }}>
                    ₹ 12,000
                  </div>
                  <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                    24 payment requested today
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "80%",
                  marginRight: "-15px",
                  marginTop: "-10px",
                }}
              >
                <OverViewGraph />
              </div>
            </div>
            <div
              className="r-c-sb"
              style={{
                borderTop: "1px solid #E6E6E6",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#FFA931" }}
                  ></div>
                  <div
                    style={{
                      color: "#FFA931",
                      fontSize: "10px",
                      fontWeight: "550",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      124
                    </span>{" "}
                    payment pending
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹ 12,000
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  24 payment requested today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#28DF99" }}
                  ></div>
                  <div
                    style={{
                      color: "#28DF99",
                      fontSize: "10px",
                      fontWeight: "550",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      124
                    </span>{" "}
                    payment made
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹ 12,000
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  24 payment requested today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#FF414D" }}
                  ></div>
                  <div
                    style={{
                      color: "#FF414D",
                      fontSize: "10px",
                      fontWeight: "550",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      124
                    </span>{" "}
                    payment overdue
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹ 12,000
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  24 payment requested today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#636363" }}
                  ></div>
                  <div
                    style={{
                      color: "#636363",
                      fontSize: "10px",
                      fontWeight: "550",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span
                      className="bold-700"
                      style={{
                        fontSize: "12.5px",
                      }}
                    >
                      {" "}
                      124
                    </span>{" "}
                    payment cancelled
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹ 12,000
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  24 payment requested today
                </div>
              </div>
              <div
                style={{
                  // borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#1089FF" }}
                  ></div>
                  <div
                    style={{
                      color: "#1089FF",
                      fontSize: "10px",
                      fontWeight: "550",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12px" }}>
                      {" "}
                      124
                    </span>{" "}
                    payment refunded
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹ 12,000
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  24 payment requested today
                </div>
              </div>
            </div>
          </div>
          <div
            className="box-shadow"
            style={{ padding: "20px", marginTop: "25px" }}
          >
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>
              All transactions (12)
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {[true, true, true, true].map((item, index) => (
                <InstallmentCard
                  amount={100.0}
                  dueDate="21/01/2022"
                  issueDate="20/08/2023"
                  status="pending"
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsProfile;
