import { Dropdown, Input, Menu, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GeoFenceForm from './localComponents/geoFenceForm';
import LocationCard from './localComponents/locationCard';
import AssignGeoFenceModal from './Modals/assignGeoFenceModal';
import { connect } from 'react-redux';
import { fetchGeoFenceLocationList, updateGeometryLocation, updateRadius, updateSearchLocation, locationCardData, setLocationCardTitle, setBackToPrivilegedView, searchFilter, getTeachersGeofenceData, setTabs, setFromUsersGeofence } from 'actions/attendanceNew';
import ConfirmDeleteModal from './Modals/confirmDeleteModal';
import GeoFenceSuccessModal from './Modals/geoFenceSuccessModal';
import { ArrowLeftOutlined, CaretDownOutlined, CloseOutlined, PlusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import PageDataLoader from 'components/pageDataLoader';
import TeachersGeoFence from './teachersGeoFence';
import Geocode from "react-geocode";
import PlacesAutocomplete from 'react-places-autocomplete';
import { Color } from 'services';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import PageHeader from 'components/pageHeader';
import { Heading } from 'components/Typography';
import { Button } from 'components/Button';
import location from 'Assets/Attendance/loc.svg'

var axios = require('axios');
let map;
let service;
let infowindow;
let placeMap;
let markerMap

export const AutoComplete = ({ value, searchRender, buttonHandler, setSearchVal, isEdit, allowClearHandler, enterHandler }) => {
    return (
        <PlacesAutocomplete
            value={value}
            onChange={searchRender}
            onSelect={buttonHandler}
            searchOptions={{
                componentRestrictions: { country: "in" },
                fields: ["geometry", "icon", "formatted_address", "name"],
            }

            }
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <div style={{ border: '1px solid #d9d9d9', borderRadius: '2px', width: '100%', display: 'flex' }}>
                        <span><SearchOutlined style={{ height: '14px', width: '14px', margin: '5px', marginLeft: '10px', color: '#AEAEAE' }} /></span>
                        <input
                            {...getInputProps({
                                placeholder: 'Search',
                                className: 'location-search-input',
                                style: { border: 'none', outline: 'none', width: "100%" }
                            })}
                        />

                        {value == '' ? null : <CloseOutlined style={{ height: '14px', width: '14px', margin: '5px', cursor: 'pointer', color: '#AEAEAE' }} onClick={allowClearHandler} />}
                    </div>
                    <div className="autocomplete-dropdown-container" style={{ position: 'absolute', zIndex: 1, border: `1px solid ${Color.midGrey}` }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style: { ...style, padding: 5 }
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>

    );
};

const LocationTab = ({ geoFenceLocationList, isModalVisible, setIsEdit, setVisibleDelete, visibleGeoFenceForm, handleSearchLoaction, isEdit, render, searchLocation, radius, searchLoc, handleClickFence, formRef, setVisibleGeoFenceForm, handleAssign, setIsModalVisible, locationCardData, setIsGeoFenceModal }) => {

    const [searchVal, setSearchVal] = useState('')
    const [latitude, setLatitude] = useState(12.912877221923127)
    const [longi, setLongi] = useState(77.64481588526345)
    const [map, setMap] = React.useState(null)

    let searchRender = (e) => {
        setSearchVal(e)
    }

    let buttonHandler = (e) => {
        extractData(e)
        handleSearchLoaction(e)
        searchRender(e)
    }

    let enterHandler = (e) => {
        if (e.key === 'Enter') {
            extractData(searchVal)
            handleSearchLoaction(searchVal)
            console.log('Color has been suggested by Bikash Singha from Silchar')
        }

    }

    let allowClearHandler = () => {
        setSearchVal('')
    }

    const containerStyle = {
        width: "100%",
        height: "70vh"
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANNuGsbyjTzqrtYc65aRapQ1fkfaVh0aI",
        libraries: ["places"]
    })

    const createMarkers = () => {
        {
            if (latitude && longi) {
                return <Marker
                    position={{ lat: latitude, lng: longi }}
                />
            } else { return null }
        }


    }

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const extractData = (searchVal) => {
        Geocode.setApiKey("AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ");
        Geocode.setLanguage("en");


        Geocode.fromAddress(searchVal).then(
            (response) => {
                const { lat, lng } = response?.results[0]?.geometry?.location;
                setLatitude(lat)
                setLongi(lng)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', height: '85vh' }}>
            <div style={{ width: '35%', display: 'flex', flexDirection: 'column', height: "85vh" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: "flex" }}>
                        <Heading>All Locations</Heading>
                        <div style={{ fontWeight: "Regular", fontSize: "22px", color: "#AEAEAE", marginLeft: "5px" }}>
                            ({geoFenceLocationList?.length + 1})
                        </div>
                    </div>
                    {/* <Button type='default' style={{ borderRadius: '16px', color: '#1089FF', fontWeight: "bold", borderColor: "#1089FF" }} onClick={() => handleAssign()}>Assign</Button> */}
                </div>
                {/* {(isModalVisible && <AssignGeoFenceModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} success={() => {
                    setIsGeoFenceModal(true)
                }} />)} */}
                <div style={{ marginTop: '20px', padding: '5px', height: '85vh', overflowY: 'auto' }}>
                    <div>
                        <div style={{ width: '100%', height: '85px', border: '1px solid #E6E6E6', borderRadius: '6px', padding: '10px' }}>
                            <div className='r-ac'>
                                <img src={location} />
                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 14 }}>
                                    Global
                                </div>
                                <div className='text-xs' style={{ color: '#AEAEAE', marginLeft: '3px' }}>
                                    (Default)
                                </div>
                            </div>
                        </div>
                        {geoFenceLocationList?.length != 0 ? geoFenceLocationList?.map((item) => (
                            <LocationCard locationItem={item} setIsEdit={setIsEdit} isDashboard={false} setVisibleDelete={setVisibleDelete} locationCardData={locationCardData} setLocationCardTitle={setLocationCardTitle} isEdit={isEdit} setLatitude={setLatitude} setLongi={setLongi} />
                        )) : null}
                        {visibleGeoFenceForm ?
                            <GeoFenceForm ref={formRef} setVisibleGeoFenceForm={setVisibleGeoFenceForm} setIsEdit={setIsEdit} lat={latitude} long={longi} /> : null}
                    </div>
                </div>
            </div>
            <div style={{ width: '60%', display: 'flex', flexDirection: 'column', height: "85vh" }}>
                <div>
                    <div style={{ width: '100%' }}>
                        <AutoComplete value={searchVal} searchRender={searchRender} buttonHandler={buttonHandler} setSearchVal={setSearchVal} isEdit={isEdit} allowClearHandler={allowClearHandler} enterHandler={enterHandler} />
                        <div style={{ marginTop: 20 }}>
                            {isLoaded ? <GoogleMap
                                mapContainerStyle={containerStyle}
                                onLoad={onLoad}
                                center={{ lat: latitude, lng: longi }}
                                defaultZoom={15}
                                zoom={13}
                            >
                                {createMarkers()}

                            </GoogleMap> : null}
                        </div>
                    </div>

                    {searchLoc && !isEdit && searchLoc != '' ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
                            <Button type='primary' style={{ width: '180px', borderRadius: '16px' }} onClick={() => { handleClickFence() }}>+ Add New Fence</Button>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}

const AttendanceGeoFence = ({
    fetchGeoFenceLocationList,
    geoFenceLocationList,
    searchLocation,
    updateSearchLocation,
    updateGeometryLocation,
    radius,
    locationCardData,
    locationCardTitle,
    setLocationCardTitle,
    setBackToPrivilegedView,
    fromUsersGeoFence,
    setTabs,
    setFromUsersGeofence,
    tab
}) => {
    const history = useHistory();
    const [searchLoc, setSearch] = useState('')
    const [visibleGeoFenceForm, setVisibleGeoFenceForm] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [pageLoader, togglePageLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [isGeoFenceModal, setIsGeoFenceModal] = useState(false);
    // const [searchAddress, setSearchAddress] = useState('')

    const formRef = useRef();



    const handleBackPress = () => {
        updateSearchLocation('')
        history.push('/attendance-dashboard');
        setBackToPrivilegedView(true)
    }
    const render = (status) => {
        return <div>{console.log('status', status)}</div>;
    };

    const handleSearchLoaction = (val) => {
        console.log("handleSearchLoaction", val);
        updateSearchLocation(val)
    }

    const handleClickFence = () => {
        console.log('form', formRef)
        formRef?.current?.scrollIntoView({ behavior: 'smooth' })
        setVisibleGeoFenceForm(true)
    }

    const handleAssign = () => {
        setIsModalVisible(true)
    }

    useEffect(() => {
        if (searchLocation) {
            setSearch(searchLocation)
        }
        else {
            setSearch('')
        }
        console.log('2')

    }, [searchLocation])


    useEffect(() => {
        updateSearchLocation('')
        updateGeometryLocation({})
        togglePageLoader(true)
        fetchGeoFenceLocationList(() => togglePageLoader(false))
        console.log('3')
        if (fromUsersGeoFence == true) {
            setTabs(2);
            setFromUsersGeofence(false)
        } else {
            setTabs(1)
        }

    }, [])


    function callback(key) {
        console.log('5')
        setTabs(key)
    }

    const easySwitch = [
        { label: 'Leaves', action: () => { history.push('/leave-graph') } },
        { label: 'Geo Fence', action: () => { history.push('/attendance-geofence') } },
        { label: 'Holidays', action: () => { history.push('/holiday-page') } },
        { label: 'Attendance Requirement', action: () => { history.push('/attendance-requirements') } }
    ];

    const actions = [
        <Button icon={<PlusCircleOutlined />} type='link' style={{ color: '#1089FF', fontWeight: "bold", fontSize: "14px" }} onClick={() => handleAssign()}>ASSIGN</Button>
        // <Button type='default' style={{ borderRadius: '16px', color: '#1089FF', fontWeight: "bold", borderColor: "#1089FF" }} onClick={() => handleAssign()}>Assign</Button>
    ]

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginTop: -10, height: 50 }}>
                    <PageHeader title='Geo Fence' onBackPress={() => handleBackPress()} easySwitch={easySwitch} actions={actions} />
                </div>

                <div style={{
                    fontWeight: 550,
                    marginTop: "20px",
                }}>
                    <LocationTab geoFenceLocationList={geoFenceLocationList} isModalVisible={isModalVisible}
                        setIsEdit={setIsEdit} setVisibleDelete={setVisibleDelete} visibleGeoFenceForm={visibleGeoFenceForm}
                        handleSearchLoaction={handleSearchLoaction} isEdit={isEdit} render={render} searchLocation={searchLocation} radius={radius}
                        searchLoc={searchLoc} handleClickFence={handleClickFence} formRef={formRef} setVisibleGeoFenceForm={setVisibleGeoFenceForm}
                        handleAssign={handleAssign} setIsModalVisible={setIsModalVisible} locationCardData={locationCardData} setIsGeoFenceModal={setIsGeoFenceModal} />
                    {/* <Tabs activeKey={`${tab}`} onChange={callback}>
                        <Tabs.TabPane tab="LOCATION(S)" key="1">
                            <LocationTab geoFenceLocationList={geoFenceLocationList} isModalVisible={isModalVisible}
                                setIsEdit={setIsEdit} setVisibleDelete={setVisibleDelete} visibleGeoFenceForm={visibleGeoFenceForm}
                                handleSearchLoaction={handleSearchLoaction} isEdit={isEdit} render={render} searchLocation={searchLocation} radius={radius}
                                searchLoc={searchLoc} handleClickFence={handleClickFence} formRef={formRef} setVisibleGeoFenceForm={setVisibleGeoFenceForm}
                                handleAssign={handleAssign} setIsModalVisible={setIsModalVisible} locationCardData={locationCardData} setIsGeoFenceModal={setIsGeoFenceModal} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="ASSIGNED LOCATION(S)" key="2" >
                            <TeachersGeoFence />
                        </Tabs.TabPane>
                    </Tabs> */}
                </div>

            </div>
            <ConfirmDeleteModal setIsModalVisible={setVisibleDelete} isModalVisible={visibleDelete} title={locationCardTitle} />
            {isGeoFenceModal &&
                <GeoFenceSuccessModal
                    close={() => { setIsGeoFenceModal(false) }}
                />}

            {(isModalVisible && <AssignGeoFenceModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} success={() => {
                setIsGeoFenceModal(true)
            }} />)}
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = state => {
    const {
        geoFenceLocationList,
        searchLocation,
        radius,
        locationCardId,
        locationCardTitle,
        teachersGeofenceDataContainer,
        usersNameContainer,
        fromUsersGeoFence,
        tab
    } = state.attendanceNew

    return {
        geoFenceLocationList,
        searchLocation,
        radius,
        locationCardId,
        locationCardTitle,
        teachersGeofenceDataContainer,
        usersNameContainer,
        fromUsersGeoFence,
        tab
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchGeoFenceLocationList: (callback) => dispatch(fetchGeoFenceLocationList(callback)),
    updateSearchLocation: (search) => dispatch(updateSearchLocation(search)),
    updateRadius: (data) => dispatch(updateRadius(data)),
    updateGeometryLocation: (object) => dispatch(updateGeometryLocation(object)),
    locationCardData: (value) => dispatch(locationCardData(value)),
    setLocationCardTitle: (value) => dispatch(setLocationCardTitle(value)),
    setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
    searchFilter: (value) => dispatch(searchFilter(value)),
    getTeachersGeofenceData: (callback) => (dispatch(getTeachersGeofenceData(callback))),
    setTabs: (val) => (dispatch(setTabs(val))),
    setFromUsersGeofence: (bool) => dispatch(setFromUsersGeofence(bool))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps

)(AttendanceGeoFence);