import { Dropdown, Input, Menu } from 'antd';
import chevronDown from 'Assets/aulasMarket/ic-chevron-down.svg';
import AnimatedTextField from 'components/AnimatedTextField';
import React from 'react';
import '../styles.css';

function CreateOfferMandatoryInfo({ offersObj, setOffersObjKey, inputStyle, cardClassName, isDiscountDropDownOpen, setIsDiscountDropDownOpen }) {
    const menuItems = [
        { value: "percent", label: "Percentage" },
        { value: "amount", label: "Amount" },
    ]
    const menu = (
        <Menu style={{ width: "120px" }}>
            {menuItems?.map((item, index) => (
                <div className={`offers-dropdown-item${item.value == offersObj.discountType ? ' selected-offers-dropdown-item' : ''}`} key={item.value} style={{ fontSize: 15, cursor: "pointer", borderRadius: 4, marginTop: index !== 0 ? 5 : 0, padding: "2px 10px 2px 10px" }} onClick={() => {
                    setOffersObjKey('discountType', item.value);
                    setOffersObjKey('maxDiscount', 0);
                    setOffersObjKey('discount', null);
                }}>{item?.label}</div>
            ))}
        </Menu>
    )
    return (
        <div className={cardClassName} style={{ zIndex: 100, marginTop: 10 }}>
            <div style={{ color: "#636363", fontSize: 18, fontWeight: 700 }}>Mandatory information</div>
            <AnimatedTextField
                label={"Title"}
                isMandatory={true}
                inputId={"-1000"}
                value={offersObj?.title || ''}
                handleInputChange={(e) => { setOffersObjKey("title", e.target.value) }}
                style={{ marginTop: 20 }}
                inputstyle={inputStyle}
            />
            <AnimatedTextField
                label={"Description"}
                isMandatory={false}
                inputId={"-1001"}
                value={offersObj?.details || ''}
                handleInputChange={(e) => { setOffersObjKey("details", e.target.value) }}
                style={{ marginTop: 30 }}
                inputstyle={inputStyle}
            />
            <div className='r-c-fs' style={{ gap: "2rem", marginTop: "1rem" }}>
                <div style={{ width: "50%" }}>
                    <div className='input-text-headings'><span>Discount</span><span style={{ color: "red" }}> *</span></div>
                    <div className='r-c-sb' style={{ gap: "20%" }}>
                        <Dropdown bordered={false} overlay={menu} trigger={['click']} open={isDiscountDropDownOpen}>
                            <div style={{ display: "flex" }} onClick={(e) => { e.stopPropagation(); setIsDiscountDropDownOpen(!isDiscountDropDownOpen); }}>
                                <div className='r-c-sb' style={{ backgroundColor: "#FFFFFF", width: 85, color: "#AEAEAE", borderRadius: 32, cursor: "pointer" }}>
                                    <div style={{ color: "#636363", fontWeight: 400, paddingRight: "5rem", width: "70%" }}>{offersObj?.discountType == "percent" ? "Percentage" : "Amount"}</div>
                                    <img style={{ width: 15, height: 15, cursor: "pointer" }} src={chevronDown} />
                                </div>
                            </div>
                        </Dropdown>
                        <div className='r-c-fs'>
                            <div style={{ color: "#636363", fontWeight: 400, marginTop: "3px" }}>|</div>
                            <Input
                                type="number" bordered={null} placeholder={`${offersObj?.discountType == 'percent' ? 'Enter %' : 'Enter Amount'}`} value={offersObj?.discount}
                                onBeforeInput={(e) => {
                                    if (!(Number(e.data) >= 0 && Number(e.data) <= 9)) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    let enteredValue = parseInt(e.target.value) || 0;
                                    enteredValue = enteredValue < 0 ? 0 : enteredValue;
                                    const limitedValue = offersObj?.discountType == 'percent' ? (enteredValue > 100 ? 100 : enteredValue) : enteredValue;
                                    setOffersObjKey("discount", limitedValue);
                                }}
                                style={{ textAlign: offersObj?.discountType == 'percent' ? 'left' : 'right' }}
                                prefix={!offersObj?.discountType || offersObj.discountType == 'amount' ? "₹" : null}
                                suffix={offersObj?.discountType == 'percent' ? "%" : null}
                            />
                        </div>
                    </div>
                    <div className='save-border-line-offers'></div>
                </div>
                {offersObj?.discountType == 'percent'
                    ? <AnimatedTextField
                        label={"Maximum discount amount"}
                        isMandatory={false}
                        inputId={"-1005"}
                        value={offersObj?.maxDiscount || null}
                        handleInputChange={(e) => {
                            let enteredValue = parseInt(e.target.value) || 0;
                            enteredValue = enteredValue < 0 ? 0 : enteredValue;
                            setOffersObjKey("maxDiscount", enteredValue);
                        }}
                        style={{ width: "50%" }}
                        inputstyle={inputStyle}
                        type="number"
                        prefix="₹"
                    />
                    : null
                }
            </div>
        </div>
    );
}

export default CreateOfferMandatoryInfo;

