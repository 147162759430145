import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect } from 'react'
import SearchBox from './localComponents/searchBox';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSyllabusSubjects, setSelectedSubject, updateFilter, setOpenTab, updateListViewType, createTestModal } from 'actions/practiseTest';
import { SubIcon } from 'components/subIcon';
import PageDataLoader from 'components/pageDataLoader';
import Loader from './localComponents/loader';
import CreateTestModal from 'pages/practiseTest/modals/createTestModal';
import { InputSearch } from 'components/customInputs';


const bodyStyle = {backgroundColor:"#FAFAFA", padding:20, margin: -20, overflowY: 'scroll', height: '105%'}

const PageHeader = ({ goBack, onSearch, searchVal, selectedPaper, selectedTest, syllabusType, classname, filter,onCreateClick}) => {
    return (
      <div className="resultPageHeader full-width">
        <div className="r-c-fs m-r-30" >
          <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
          <div className="text-md bold-700 m-l-20"> 
            <div>
              {syllabusType}
            </div>
            <div style={{color:"#AEAEAE", fontSize:12}}>
            {classname}
            </div>
          </div>
        </div>
        <div className="testfiltersgroup m-r-50">
        <InputSearch allowClear onClear={()=>{onSearch('')}} loading={false} onInputChange={onSearch} className="m-r-40" placeholder="Search by subject name" style={{width:270}}/>
            <Button onClick={onCreateClick} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
            + CREATE TEST
          </Button> 
        </div>
      </div>
    );
  };


const SubjectCard = ({updateFilter, setSelectedSubject, setOpenTab, history,filter, data}) => {

  return(
    <>
    <div onClick={()=>{
              setSelectedSubject(data)
              console.log("first", filter?.selectedSyllabus)
      if (filter?.selectedSyllabus==='Chapter Test'){
        updateFilter('chapterSearch', null);
        history.push("practise-test-chapter-page")
      }else{
        updateFilter('tabSearch', null);
        updateFilter('isTAdatefiltered', false);
        updateFilter('taDate', null);
        updateFilter('isVA', false);
        updateFilter('isTabPage', true);
        updateFilter('testTypeSelector', null);
        setOpenTab('draft');
        history.push('practise-test-tabs-page')
      }}} className='m-t-30 m-l-20 customCardShadow cursor-pointer shadow-box hover-shadow' 
    style={{
      height:216,
      width: 226,
      borderRadius: 10,
      backgroundColor:"#FFFFFF",
      border: "1px solid #E6E6E6",}}>
    <div className='r-c-c-c m-t-30'>
    <SubIcon name={data.subjectName} size={80} icon={data.icon}/>
    <div className='m-t-30 bold-700' style={{fontSize: 18}}>{data.subjectName}</div>
    <div className='m-t-5' style={{fontSize: 12, fontWeight: 500}}>{data.testCount}</div>
    </div>
    </div>
    </>
  )
}
   
function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, /* marginBottom: "20px" */ }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}
      
let timer=false;

const PracSubjectPage = ({selectedClassroom, updateFilter, filter, fetchSyllabusSubjects, subjectsByClass, 
  setSelectedSubject, setOpenTab, loaders, createTestModal, updateListViewType, createTestModalBool, user}) => {


  useEffect(() => {
    fetchSyllabusSubjects()
  }, [])
  
  const onSearch = (e) => {
    updateFilter('subjectSearch', e);
} 

useEffect(()=>{
  clearTimeout(timer);
  timer = setTimeout(()=>{
    fetchSyllabusSubjects(()=>{});
  }, 1000);
}, [filter?.subjectSearch]);

  const history = useHistory();
  const goBack = () => {
    console.log("first")
    history.goBack();
  }

  const onCreateClick = () => {
    updateListViewType('create');
    createTestModal('test-create', user, 'syllabus');
  }



  return (
    <div style={bodyStyle}>
        <PageHeader
            classname={`${selectedClassroom.name}`} syllabusType={filter?.selectedSyllabus}
            goBack={goBack}
            onSearch={onSearch}
            onCreateClick={onCreateClick}
            />
    <div>
        {loaders?.subjectPageLoader?
          <Loader style={{fontSize:24}}/>
          :
          <>
          {subjectsByClass && subjectsByClass.length?
        <div className='display-flex flex-wrap'>
            {subjectsByClass?.map((item)=>(
         <SubjectCard
              history={history}
              updateFilter={updateFilter}
              icon={item.icon}
              key={item.key}
              subjectName={item.subjectName}
              noOfChapters={item.testCount}
              data={item}
              setSelectedSubject={setSelectedSubject}
              filter={filter}
              setOpenTab={setOpenTab}
            />
            ))}
        </div> : <NodataCard/>
  }
            </> 
}        
    </div>
    {createTestModalBool && <CreateTestModal history={history} />}
        {/* <PageDataLoader visible={loaders?.subjectPageLoader}/> */}
    </div>
  )
}

const mapStateToProps = state =>{
  const {selectedClassroom, filter, subjectsByClass, loaders, createTestModalBool}=state.practiseTest
  const {user} = state.session;
  return {selectedClassroom, filter, subjectsByClass, loaders, user, createTestModalBool}
}

const mapDispatchToProps = dispatch=> ({
  fetchSyllabusSubjects: () => dispatch(fetchSyllabusSubjects()),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  setSelectedSubject:(data)=>dispatch(setSelectedSubject(data)),
  setOpenTab: (tab) => dispatch(setOpenTab(tab)),
  updateListViewType: (data)=>dispatch(updateListViewType(data)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
})


export default connect(mapStateToProps, mapDispatchToProps)(PracSubjectPage)