import React, { useState } from 'react';
import "../styles.css";
import courseOnlineIcon from "../../../Assets/courseManagementV4/courseOnlineIcon.svg";
import courseOfflineIcon from "../../../Assets/courseManagementV4/courseOfflineIcon.svg";
import courseHybridIcon from "../../../Assets/courseManagementV4/courseHybridIcon.svg";
import { Information, SmallInfo, Title } from 'components/Typography';
import { Color } from 'services';

function OverviewList({ item, index }) {
    const icon = {
        online: courseOnlineIcon,
        offline: courseOfflineIcon,
        hybrid: courseHybridIcon
    }

    const text = {
        online: "online",
        offline: "offline",
        hybrid: "hybrid"
    }

    return (
        <div className='display-flex' style={{ alignItems: "center", width: "100%", marginTop: 10, marginBottom: index === 2 ? 0 : 5 }}>
            <div><img src={icon[item?.mode]} style={{ height: "1.25rem", width: "1.25rem" }} /></div>
            <div className='m-l-10' >
                <SmallInfo color={Color.darkGrey}>
                    {item?.studentCount} students&nbsp;
                    {item?.count} {text[item?.mode]} courses
                </SmallInfo>
            </div>
        </div>
    );
}

function CourseOverviewCard({ count, subTitle, imageSources, setCourseTypeName, item, history, index }) {
    const [isHovered, setIsHovered] = useState(false);
    const reorderedModeCountList = [
        item?.modeCountList?.find(item => item?.mode === 'online'),
        item?.modeCountList?.find(item => item?.mode === 'offline'),
        item?.modeCountList?.find(item => item?.mode === 'hybrid')
    ];
    return (
        <div
            className={`courseOverviewParent cursor-pointer course-overview-zoom ${index === 0 && isHovered ? 'first-card-hover' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                setCourseTypeName(item?.overviewType === "liveCourse" ? "liveCourses" : item?.overviewType === "videoResource" ? "videoResources" : item?.overviewType === "studyMaterial" ? "studyMaterials" : item?.overviewType === "testSeries" ? "onlineTestSeries" : null);
                history.push(item?.overviewType === "liveCourse" ? "/live-courses-data" : item?.overviewType === "videoResource" ? "/video-resources-data" : item?.overviewType === "testSeries" ? "/online-test-series-details" : item?.overviewType === "studyMaterial" ? "/study-materials-data" : "/live-courses-data")
            }}
        >
            <div className={`courseOverviewCard`}>
                <div className='overviewCourseinfo'>
                    <div>
                        <Title>{count}</Title>
                        <div className='r-ac'>
                            <Information>
                                {subTitle.toLowerCase()}
                            </Information>
                            <Information className="m-l-5" style={{ marginTop: index === 0 ? 2 : "" }}>
                                (<span>{item?.studentCount || 0}</span> {item?.studentCount > 1 ? "students" : "student"})
                            </Information>
                        </div>
                    </div>
                    <div className="card-img-parent"> <img className='card-img' src={imageSources} alt={"images"} /></div>
                </div>
                {index === 0 &&
                    <div className='courseOverviewList'>
                        {reorderedModeCountList?.map((item, listIndex) => (
                            <OverviewList item={item} index={listIndex} />
                        ))}
                    </div>
                }
                {/* {isHovered && <div className='courseOverviewHoveredText m-t-2' style={{ top: index == 0 ? "86%" : "70%", fontSize: 13 }}>VIEW</div>} */}
            </div>
        </div>
    );
}

export default CourseOverviewCard;