import { CaretDownOutlined, CaretUpOutlined, MenuOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'
import { setIsFromAdminPreview } from 'actions/courseManagement'
import { showMenu } from 'actions/layout'
import { logout } from 'actions/login'
import { getCartCount, getUserCourseAutocompleteSearch, getUserMarketPlaceCoursesData, getUserMarketPlaceHomeData, setIsDownloadBarVisible, setIsLogoutClicked, setIsMyAccountClicked, setParentLink, setSelectedSuggestion, setShowContactBar, setSideBarStudentHome, storeCartCountData } from 'actions/studentMarketPlace'
import { AutoComplete, Dropdown, Menu, Tooltip, Typography } from 'antd'
import { Button } from 'components/Button'
import PageDataLoader from 'components/pageDataLoader'
import LogoutConfirm from 'layout/logoutConfirm'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'
import { IMG_URL } from "../../env.json"
import ContactUsTopBar from './contactUsTopBar'
import DownloadAppTopBar from './downloadAppTopBar'
import MobileDiscountOffersCarousel from './mobileDiscountOffersCarousel'
import OffersCarousel from './offersCarousel'
import './userHomeStyles.css'
import UserAvatar from 'components/userAvatar'
import DownloadAppModal from './downloadAppModal'
import LoginModal from "pages/login-new/loginModal"
import { setRegisterSuccessModal, setShowBottomSheet, setShowDownloadAppModal, setShowLoginModal } from 'actions/loginNew'
import SuccessModal from 'pages/login-new/successModal'

function TopBar({ userMarketPlaceHomeData, user, logout, setSideBarStudentHome, setParentLink, getUserCourseAutocompleteSearch, showMenu, cartCountData, getUserMarketPlaceHomeData, setIsLogoutClicked,
    isLogoutClicked, storeCartCountData, getCartCount, setIsMyAccountClicked, setIsCategoryClicked, isCategoryClicked, setIsProfileClicked, isProfileClicked, setIsDownloadBarVisible, isDownloadBarVisible, autocompleteSearched,
    showContactBar, setShowContactBar, setSelectedSuggestion, selectedSuggestion, isFromAdminPreview, setIsFromAdminPreview, isOffersBarVisible, offersCarouselData, couponList, setCategories, SetHidBanner, setCategoryId, setPage,
    setCourses, userSearchData, getUserMarketPlaceCoursesData, setHasMore, page, showLoginModal, setShowLoginModal, setRegisterSuccessModal, showRegisterSuccessModal, showDownloadAppModal, setShowDownloadAppModal, setShowBottomSheet }) {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const history = useHistory();
    const [confirmLogoutVisible, setConfirmLogoutVisible] = useState(false);
    const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null)
    const [searchLoader, setSearchLoader] = useState(null);
    const [userSearchDataContainer, setUserSearchData] = useState(null);
    const [pageLoader, togglePageLoader] = useState(false);

    useEffect(() => {
        getUserMarketPlaceHomeData(true, () => { })
    }, [])

    const handleLogout = () => {
        const reason = {
            type: 'Manual'
        }
        logout(history, reason, true);
        setConfirmLogoutVisible(false);
        setIsLogoutClicked(true);
        history.push("/")
        togglePageLoader(true);
        if (isLogoutClicked) {
            storeCartCountData(0)
        } else {
            getCartCount()
        }

        getUserMarketPlaceHomeData(undefined, () => {
            togglePageLoader(false); storeCartCountData(0)

        })
        setCourses([])
        const requestsParams = {
            page: 1,
            courseType: 'course',
            limit: 3,
        };
        getUserMarketPlaceCoursesData(requestsParams, (data) => {


            if (data?.items && data?.items?.length > 0) {
                setCourses((prevCourses) => [...prevCourses, ...data?.items]);
            }
            if (data?.items?.length < requestsParams?.limit) {
                setHasMore(false);
            }
        });
    };

    const handleChangeCategory = (cId, category, subCategory) => {
        setParentLink({ parentName: category, childName: subCategory });
        history.push({
            pathname: '/all-popular-courses',
            state: {
                categoryId: cId,
            }
        })
    }

    useEffect(() => {
        const handleResize = () => {
            setIsCategoryClicked(false); setIsProfileClicked(false)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const filterSubCategoryMenu = (category, index) => (
        <Menu className='scroll-bar-universal' style={{ height: "fit-content", backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 230, borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", margin: index == 0 ? "40px 0px 0px -2px" : "0px 0px 0px -2px" }}>
            {category?.children && category?.children?.map((subcategory) => (
                <div className='dropmenuBp' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 45 }} onClick={() => { handleChangeCategory(subcategory?.id, category?.name, subcategory?.name); setAutocompleteSearchedValue(null); setSelectedSuggestion(null) }}>
                    <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: 210, fontWeight: 550 }} >{subcategory?.name}</Typography.Text>
                </div>
            ))}
        </Menu>
    )
    const userCategoryMenu = (
        <Menu className='scroll-bar-universal' style={{ height: userMarketPlaceHomeData?.categories?.length > 5 ? 280 : "fit-content", backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 230, borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop: -3 }}>
            {userMarketPlaceHomeData?.categories && userMarketPlaceHomeData?.categories?.map((category, index) => (
                <Dropdown overlay={filterSubCategoryMenu(category, index)} placement='right' trigger={['hover']}>
                    <div className='dropmenuBp' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 45 }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: 210, fontWeight: 550 }}>{category?.name}</Typography.Text>
                        <RightOutlined style={{ color: "#AEAEAE" }} />
                    </div>
                </Dropdown>
            ))}
        </Menu>
    )



    const handleAutocompleteSearch = (value) => {
        setIsCategoryClicked(false); setIsProfileClicked(false)
        setAutocompleteSearchedValue(value);
        setSearchLoader(true);
        getUserCourseAutocompleteSearch(value, (response) => { setUserSearchData(response); setSearchLoader(false) })
    }

    const renderTitle = (title) => (
        <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
    )

    const searchOptions = [
        {
            label: userSearchDataContainer?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
            options: userSearchDataContainer?.suggestions?.length ? userSearchDataContainer?.suggestions?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => {
                        history.push({ pathname: "/all-popular-courses", state: { fromSearch: true, /* selectedSuggestion: item */ } });
                        setSelectedSuggestion(item);
                    }}>
                        <SearchOutlined /><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
        {
            label: userSearchDataContainer?.resources?.length != 0 ? renderTitle("Courses") : null,
            options: userSearchDataContainer?.resources?.length ? userSearchDataContainer?.resources?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => {
                        history.push({ pathname: "/all-popular-courses", state: { fromSearch: true, /* selectedSuggestion: item?.name  */ } });
                        setSelectedSuggestion(item?.name)
                    }}>
                        <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 40, height: 30 }} />
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item?.name}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
    ];
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth > 800) {
                const currentScrollPos = window.pageYOffset;
                setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
                setPrevScrollPos(currentScrollPos);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        if (window.performance.navigation.type === 0) {
            setIsDownloadBarVisible(true)
        } else {
            setIsDownloadBarVisible(false)
        }
    }, [])

    useEffect(() => {
        if (userMarketPlaceHomeData?.enquiryForm) {
            if (window.location.pathname == "/cart" || window.location.pathname == "/user-checkout" || (user?.accessToken && !user?.role?.includes("Student"))) {
                setShowContactBar(false)
            } else {
                setShowContactBar(true)
            }
        } else {
            setShowContactBar(false)
        }
    }, [userMarketPlaceHomeData])

    const handleProfileClick = (e) => {
        e.stopPropagation();
        setIsProfileClicked(!isProfileClicked);
    };

    return (
        <div>
            <div className={`${window.innerWidth > 800 ? `top-bar123 ${visible ? 'visible' : 'hidden'}` : ''}`} style={{ animation: visible ? "header 1.3s ease" : "" }}>
                {isDownloadBarVisible ? <DownloadAppTopBar orgName={userMarketPlaceHomeData?.orgName} pSLink={userMarketPlaceHomeData?.pSLink} aSLink={userMarketPlaceHomeData?.aSLink} setIsDownloadBarVisible={setIsDownloadBarVisible} /> : null}
                {/* {showContactBar ? <ContactUsTopBar /> : <></>} */}
                <div className='top-bar-parent r-c-c' >
                    <div style={{ display: "flex", cursor: 'pointer' }} onClick={() => {
                        const requestsParams = {
                            page: page,
                            courseType: 'course',
                            limit: 3,
                        };
                        getUserMarketPlaceCoursesData(requestsParams, (data) => {
                            if (data?.items && data?.items?.length > 0) {
                                setCourses((prevCourses) => [...prevCourses, ...data?.items]);
                            }
                            if (data?.items?.length < requestsParams?.limit) {
                                setHasMore(false);
                            }
                        });
                        if (isFromAdminPreview) { }
                        else {
                            history.push('/'); setIsCategoryClicked(false); setIsProfileClicked(false)
                            setCategories([])
                            SetHidBanner(false)
                            setCategoryId(null)
                            setPage(1)
                            setCourses([])
                            userSearchData([])
                            autocompleteSearched(null)
                            setSelectedSuggestion(null)
                        }
                    }}>
                        <img className='vectorIcon' style={{ marginRight: 10 }} src={`${Api._s3_url}${userMarketPlaceHomeData?.orgLogo}`} alt="logo" />
                        <div className='institute-title' style={{ color: "#594099", fontWeight: "bold" }}>{userMarketPlaceHomeData?.orgName}</div>
                    </div>
                    {/* <div className='topbar-mobile-disable'>
                        {userMarketPlaceHomeData?.categories?.length > 0 ?
                            <Dropdown overlay={userCategoryMenu} trigger={['hover']} open={isCategoryClicked}>
                                <div style={{ color: "#191919", fontWeight: 600, fontSize: 18, marginRight: 30, cursor: "pointer" }} onClick={(e) => {
                                    if (isFromAdminPreview) { }
                                    else {
                                        e.stopPropagation(); setIsCategoryClicked(!isCategoryClicked); setIsProfileClicked(false)
                                    }
                                }}>Categories{isCategoryClicked ? <CaretUpOutlined style={{ color: "#AEAEAE", marginLeft: 10 }} /> : <CaretDownOutlined style={{ color: "#AEAEAE", marginLeft: 10 }} />}</div>
                            </Dropdown> : <div></div>}
                        <AutoComplete
                            allowClear
                            popupClassName="certain-category-search-dropdown input-field"
                            style={{ marginRight: 20 }}
                            options={(userSearchDataContainer?.suggestions?.length > 0 || userSearchDataContainer?.resources?.length) ? searchOptions : []}
                            onSearch={handleAutocompleteSearch}
                            onClear={() => {
                                setSelectedSuggestion(null);
                                setAutocompleteSearchedValue(null);
                                // history.push("/home")
                            }}
                            defaultValue={autocompleteSearchedValue ? autocompleteSearchedValue : selectedSuggestion ? selectedSuggestion : null}
                            placeholder={<span><SearchOutlined /> Search</span>}
                            loading={searchLoader}
                            className='round-bordered-search-course search-width input-field'
                            disabled={isFromAdminPreview ? true : false}
                        >
                        </AutoComplete>
                    </div> */}
                    {isFromAdminPreview ?
                        <Button onClick={() => { setIsFromAdminPreview(false); history.push("/course-details") }}>EXIT PREVIEW</Button>
                        :
                        <div className='topbar-mobile-disable'>
                            {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
                                <div className='m-r-20' style={{ position: "relative", cursor: "pointer" }} onClick={() => {
                                    setIsCategoryClicked(false); setIsProfileClicked(false)
                                    if (user?.accessToken) {
                                        history.push("/cart")
                                    } else {
                                        // history.push("/login")
                                        setShowLoginModal(true)
                                    }
                                }}>
                                    <img className='cartIcon' style={{ marginTop: 8 }} src={require("../../Assets/aulasMarket/cartNew.svg").default} />
                                    {cartCountData > 0 ? <div style={{ backgroundColor: "#FFA931", color: "#FFFFFF", fontSize: 10, fontWeight: "bold", padding: "0px 5px", borderRadius: 5, height: "fit-content", position: "absolute", top: -2, left: 20 }}>{cartCountData}</div> : <></>}
                                </div>
                            </> : <></>}
                            <div style={{ cursor: "pointer", height: "45px", width: '100%', border: '1px solid #916DB9', padding: '7px 20px 20px 20px', borderRadius: 12 }} onClick={handleProfileClick}>
                                <div style={{ position: 'relative', display: "flex", cursor: "pointer", gap: 10 }}>
                                    <div style={{ height: 20, marginTop: -1 }}>
                                        {console.log("user?.image", user?.image)}

                                        <UserAvatar className={'profileIcon'} name={user?.name} img={user?.image == 'default.png' ? '' : user?.image ? `${IMG_URL}/${user?.image}` : `${IMG_URL}/${'default.png'}`} marketPlace={true} />

                                        {/* //  img={user?.image ? `${IMG_URL}/${user?.image}` : `${IMG_URL}/${'default.png'}`} */}
                                    </div>
                                    <div style={{ color: '#191919', fontWeight: 400, fontSize: 17 }}>
                                        {user?.name ? user?.name : "Guest"}
                                    </div>

                                    {isProfileClicked && (
                                        <div style={{
                                            background: `
            linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)),
            linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)
          `,
                                            position: 'absolute',
                                            top: '100%',    // Aligns the dropdown just below the user avatar div
                                            right: -20,       // Aligns the dropdown to the right end of the parent div
                                            backgroundColor: 'white',
                                            boxShadow: "4px 4px 16px 8px #00000014",
                                            borderRadius: 12,
                                            width: 260,
                                            padding: 20,
                                            marginTop: 12,
                                            zIndex: 1000
                                        }}>
                                            <center style={{ marginTop: 2 }}>
                                                <UserAvatar size={50} name={user?.name} img={user?.image == 'default.png' ? '' : user?.image ? `${IMG_URL}/${user?.image}` : `${IMG_URL}/${'default.png'}`} marketPlace={true} />
                                            </center>
                                            <center style={{ marginTop: 10, color: "#191919", fontWeight: "bold", fontSize: 15 }}>
                                                {user?.name ? user?.name : "Guest"}
                                            </center>
                                            <center style={{ marginTop: 1, fontSize: 15, fontWeight: 400 }}>
                                                {user?.role?.[0]}
                                            </center>

                                            <center className='m-t-20'>
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        width: "100%",
                                                        padding: "10px 10px",
                                                        borderRadius: 8,
                                                        fontWeight: 700,
                                                        fontSize: 15,
                                                        height: 40,
                                                    }}
                                                    onClick={() => {
                                                        // if (user?.accessToken && user?.role?.includes("Student") && !user?.student?.liveCourse) {
                                                        //   history.push("/courses-list");
                                                        // } else if (user?.accessToken && user?.role?.includes("Student") && user?.student?.liveCourse) {
                                                        //   history.push("/dashboard-student");
                                                        // } else if (user?.accessToken && !user?.role?.includes("Student")) {
                                                        //   history.push("/schedule");
                                                        // } else {
                                                        //   history.push("/login");
                                                        // }
                                                        if (user?.accessToken && user?.role?.includes("Student")) {
                                                            setShowDownloadAppModal(true)
                                                        } else if (user?.accessToken && !user?.role?.includes("Student")) {
                                                            history.push("/schedule");
                                                        } else {
                                                            // history.push("/login");
                                                            setShowLoginModal(true)
                                                        }
                                                    }}
                                                >
                                                    MY ACCOUNT
                                                </Button>
                                            </center>

                                            {user?.accessToken && (
                                                <center className='m-t-20'>
                                                    <Button
                                                        style={{
                                                            width: "100%",
                                                            padding: "10px 10px",
                                                            borderRadius: 8,
                                                            border: "1px solid #FF414D",
                                                            color: '#FF414D',
                                                            fontWeight: 700,
                                                            fontSize: 15,
                                                            height: 40,
                                                        }}
                                                        onClick={() => { setConfirmLogoutVisible(true); setIsCategoryClicked(false); setIsProfileClicked(false) }}


                                                    >
                                                        LOGOUT
                                                    </Button>
                                                </center>
                                            )}
                                        </div>
                                    )}
                                </div>

                            </div>


                        </div>
                    }
                </div>
                {isOffersBarVisible && offersCarouselData?.length > 0 ?
                    <>
                        <OffersCarousel />
                        <MobileDiscountOffersCarousel />
                    </>
                    :
                    <></>
                }
                {couponList?.minOffers?.[0] ?
                    <div className='applicable-offer-header'>
                        Add items worth ₹{couponList?.minOffers?.[0]?.dif?.toLocaleString() || ''} to get {couponList?.minOffers?.[0]?.discountType == 'percent' ? `${couponList?.minOffers?.[0]?.discount}%` : `₹${couponList?.minOffers?.[0]?.discount}`} off above ₹{couponList?.minOffers?.[0]?.minPurchase?.toLocaleString()}
                    </div> : null
                }
            </div>
            <div className='top-bar-parent-mobile'>
                <div style={{ display: "flex" }} onClick={() => {
                    if (isFromAdminPreview) { }
                    else { history.push('/') }
                }}>
                    <img className='vectorIcon' style={{ marginRight: 10 }} src={`${Api._s3_url}${userMarketPlaceHomeData?.orgLogo}`} alt="logo" />
                    <div className='institute-title' style={{ color: "#594099", fontWeight: "bold" }}>{userMarketPlaceHomeData?.orgName}</div>
                </div>
                <div className='search-menu-mobile'>
                    <center style={{ marginRight: 20, marginTop: -3 }} onClick={() => {
                        if (user?.accessToken) {

                        } else {
                            setShowBottomSheet(true)
                        }
                    }}>
                        <UserAvatar className={'profileIcon'} name={user?.name} img={user?.image == 'default.png' ? '' : user?.image ? `${IMG_URL}/${user?.image}` : `${IMG_URL}/${'default.png'}`} marketPlace={true} />
                    </center>
                    {/* <SearchOutlined style={{ color: "#AEAEAE", fontSize: 22, marginRight: 20 }} onClick={() => { if (isFromAdminPreview) { } else { history.push("/mobile-search-page") } }} /> */}
                    <MenuOutlined style={{ color: "#AEAEAE", fontSize: 22 }} onClick={() => { if (isFromAdminPreview) { } else { setSideBarStudentHome(true) } }} />
                </div>
            </div>
            <LogoutConfirm
                visible={confirmLogoutVisible}
                onCancel={() => setConfirmLogoutVisible(false)}
                onConfirm={() => handleLogout(history)}
            />
            {showDownloadAppModal &&
                <DownloadAppModal
                    showDownloadAppModal={showDownloadAppModal}
                    setShowDownloadAppModal={setShowDownloadAppModal}
                    userMarketPlaceHomeData={userMarketPlaceHomeData}
                />}
            {showLoginModal &&
                <LoginModal
                    showLoginModal={showLoginModal}
                    setShowLoginModal={setShowLoginModal} />}
            {showRegisterSuccessModal &&
                <SuccessModal
                    showRegisterSuccessModal={showRegisterSuccessModal}
                    setRegisterSuccessModal={setRegisterSuccessModal}
                />}
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData, cartCountData, isLogoutClicked, isDownloadBarVisible, showContactBar, selectedSuggestion } = state.studentMarketPlace;
    const { user } = state.session;
    const { isFromAdminPreview } = state.courseManagement;
    const { showLoginModal, showRegisterSuccessModal, showDownloadAppModal } = state.loginNew
    return { userMarketPlaceHomeData, user, cartCountData, isLogoutClicked, isDownloadBarVisible, showContactBar, selectedSuggestion, isFromAdminPreview, showLoginModal, showRegisterSuccessModal, showDownloadAppModal };
};

const mapDispatchToProps = (dispatch) => ({
    logout: (history, reason) => dispatch(logout(history, reason)),
    setSideBarStudentHome: (val) => dispatch(setSideBarStudentHome(val)),
    setParentLink: (item) => dispatch(setParentLink(item)),
    getUserCourseAutocompleteSearch: (search, callBack) => dispatch(getUserCourseAutocompleteSearch(search, callBack)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    getUserMarketPlaceHomeData: (noCourse, callBack) => dispatch(getUserMarketPlaceHomeData(noCourse, callBack)),
    setIsLogoutClicked: (val) => dispatch(setIsLogoutClicked(val)),
    storeCartCountData: (val) => dispatch(storeCartCountData(val)),
    getCartCount: () => dispatch(getCartCount()),
    setIsMyAccountClicked: (val) => dispatch(setIsMyAccountClicked(val)),
    setIsDownloadBarVisible: (val) => dispatch(setIsDownloadBarVisible(val)),
    setShowContactBar: (val) => dispatch(setShowContactBar(val)),
    setSelectedSuggestion: (val) => dispatch(setSelectedSuggestion(val)),
    setIsFromAdminPreview: (val) => dispatch(setIsFromAdminPreview(val)),
    getUserMarketPlaceCoursesData: (requestsParams, callBack) => dispatch(getUserMarketPlaceCoursesData(requestsParams, callBack)),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
    setRegisterSuccessModal: (val) => dispatch(setRegisterSuccessModal(val)),
    setShowDownloadAppModal: (val) => dispatch(setShowDownloadAppModal(val)),
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);