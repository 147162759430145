import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import {
  showTextEditor, updateSubWithChapters, addMarksForQues, chooseCorrectAnswer, storeTestQuesType,
  postQuestionCreate, clearGenerateQuesFields, getEachQuesData, deleteQuesFromServer,
  showGenerateQuesListView,
  unsetCreateTestModalBool,
  updateNumericAnswer,
  renderEditorContent,
  updatePostParams,
  setSelectedSectionId,
  chooseBoolAnswer,
  updateQuestionType,
  storeQuestionIndex,
  storeSectionIndex,
  createAdditionalQuestion,
  storeSchemeId,
  getMarkingSchemeMarks
} from 'actions/testAdmin';
import Notification from "services/Notification";
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdFormatListBulleted, MdCloudDownload, MdMoreVert } from "react-icons/md";
import {
  AiOutlineDownload, AiOutlinePlus, AiOutlineMore, AiFillCaretDown, AiOutlineMinus, AiOutlinePlusCircle,
  AiFillDelete, AiFillCopy
} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { ExclamationCircleOutlined, LoadingOutlined, } from '@ant-design/icons';
import './styles.css';
import { Color } from 'services';
import ReactHtmlParser from 'react-html-parser';
import { Col, Row, Typography, Select, Menu, Dropdown } from 'antd';
import TopNavigationColorHeader from './localComponent/topNavigationColorHeader';
import TextEditorModal from './localComponent/textEditorModal';
import TestPublishModal from './localComponent/testPublishModal';
import CreateTestModal from './modals/createTestModal';
import { Tooltip } from 'antd';
import TeX from '@matejmazur/react-katex';
import QuesAnsContainer from './quesAnsContainer';
import QueGridSide from './localComponent/queGridSide';

const Up = require("../../Assets/test-new/ic-chevron-up.svg").default;
const Down = require("../../Assets/test-new/ic-chevron-down.svg").default;


const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const TestDetails = ({ value, text }) => {
  return (
    <div className='r-c-c-c m-r-50'>
      <div className='text-xmd bold-600'>{value}</div>
      <div className='text-xs'>{text}</div>
    </div>
  )
}

const CustomeDropdown = ({ defaultvalue, placeholder, onchange, arrdata, disable = false }) => {
  return (
    <Select
      className='test-custom-select'
      // placeholder={placeholder}
      disabled={disable}
      value={defaultvalue}
      style={{ width: 200 }}
      onChange={onchange}
      suffixIcon={<AiFillCaretDown className='text-sm a2grey' />}
    >
      {arrdata?.map((item, index) => (
        <Option key={item.id} value={item.id}>{item.name}</Option>
      ))}
    </Select>
  )
}

const Row1 = ({ history, generateQuesObj, generateQuesArr, showGenerateQuesListView }) => {
  let queCount = 0;
  for (const subject of generateQuesArr?.subjects) {
    if (subject.includeSectionFlag) {
      for (const section of subject?.sections) {
        queCount += section?.questions?.length ?? 0;
      }
    } else {
      queCount += subject?.questions?.length ?? 0;
    }
  }
  let totalPoints = Object.keys(generateQuesArr)?.length ? generateQuesArr.totalPoints : 0;
  return (
    <div className='r-c-sb p-20'>
      <div className='r-ac'>
        <TestDetails value={queCount} text={`Questions`} />
        <TestDetails value={`${generateQuesObj.duration / 60} mins`} text={`Duration`} />
        <TestDetails value={totalPoints} text={`Marks`} />
      </div>
      <div className='r-ac'>
        <div onClick={() => { showGenerateQuesListView(() => { }, "listview"); history.push('test-generate-questions-list') }} className='r-c-c-c m-r-20 cursor-pointer'>
          <MdFormatListBulleted className='text-md color-black' />
          <div className='text-xs text-center color-black'>List View</div>
        </div>
        <div className='r-c-c-c cursor-pointer'>
          <AiOutlineDownload className='text-md a2grey' />
          <div className='text-xs text-center a2grey'>Import</div>
        </div>
      </div>
    </div>
  )
}

const RowSub = ({ generateQuesObj, generateQuesSubChapters, updateSubWithChapters, clearGenerateQuesFields, getEachQuesData,
  deleteQuesFromServer, editGenerateQueId, setDeleteModalVisible, setDeleteLoader, setSelectedSectionId, storeSchemeId, storeSubjectSection }) => {
  return (
    <div className='r-ac' style={{ maxWidth: "100%", overflowX: "auto", display: 'flex' }}>
      {generateQuesObj?.subjects?.map((item) => (
        <div key={item?.subjId}
          onClick={() => {
            console.log('itemSub', item)
            if (generateQuesObj?.subjects && generateQuesObj?.subjects?.length > 0) {
              updateSubWithChapters(item?.subName);
              setSelectedSectionId(item?.sections?.length > 0 ? item?.sections[0].sectionId : null)
              getEachQuesData(item?.sections?.length > 0 ? item?.sections[0]?.questions[0].id : item?.questions[0].id)
              // clearGenerateQuesFields();
              if (item?.includeSectionFlag === 0) {
                storeSchemeId(item?.schemeId)
              }
            }
          }}
          // className={item === generateQuesSubChapters.name ? 'purpleBtnWrap ' : 'blankGreyBtnWrap  '}
          // style={{ cursor: (generateQuesObj.subjects && generateQuesObj.subjects?.length > 0) ? 'pointer' : 'auto', width: '100%', textAlign: 'center', backgroundColor: item.subName === generateQuesSubChapters.name ? '#F6F4FE' : '#FFFFFF', color: item.subName === generateQuesSubChapters.name ? '#191919' : '#AEAEAE', height: '30px', fontWeight: '500', padding: '5px' }}>
          style={{ cursor: 'pointer', width: '100%', textAlign: 'center', backgroundColor: (item.subName === generateQuesSubChapters.name) ? '#F6F4FE' : '#FFFFFF', color: item.subName === generateQuesSubChapters.name ? '#191919' : '#AEAEAE', height: '30px', fontWeight: '500', padding: '5px' }}>
          {item.subName}
        </div>
      ))}
    </div>
  )
}


// const Row2 = ({generateQuesObj, generateQuesSubChapters, getEachQuesData }) => {

//   return(
//     <div className='r-c-sb p-20'>
//       <div className='r-ac'>
//         {generateQuesObj?.subjectNames?.map((item, index) => (
//           <Button  className={item === generateQuesSubChapters.name ? 'purpleBtnWrap m-r-20 text-xs' : 'blankGreyBtnWrap m-r-20 text-xs'}
//                     onClick={()=>{
//                       if (generateQuesObj && generateQuesObj.subjects?.length>0 && generateQuesObj?.subjects[0]?.questions?.length>0){
//                         getEachQuesData(generateQuesObj?.subjects[index]?.questions[0]);
//                       }}}>
//             {item}
//           </Button>
//         ))}
//       </div>
//     </div>
//   )
// }

const Row2 = ({ generateQuesObj, generateQuesSubChapters, updateSubWithChapters, clearGenerateQuesFields, getEachQuesData,
  deleteQuesFromServer, editGenerateQueId, setDeleteModalVisible, setDeleteLoader, selectedSectionId, setSelectedSectionId, storeSectionIndex, storeSchemeId }) => {
  // const menu = (
  //   <Menu>
  //     {editGenerateQueId === '' ? null :
  //       <Menu.Item key="0" className="m-b-10">
  //         <div className='r-ac cursor-pointer p-l-20 p-r-20'
  //           // onClick={editGenerateQueId === '' ? () => {Notification.error("error", "Cannot delete questions that are not saved")} : () => deleteQuesFromServer()}
  //           onClick={() => { setDeleteModalVisible(true); setDeleteLoader(false) }}
  //         >
  //           <AiFillDelete className='text-md' />
  //           <div className='text-xs color-black m-l-10'>Delete</div>
  //         </div>
  //       </Menu.Item>
  //     }
  //     <Menu.Item key="1">
  //       <div className='r-ac cursor-pointer p-l-20 p-r-20' onClick={() => { clearGenerateQuesFields(); }}>
  //         <ClearOutlined className='text-md' />
  //         <div className='text-xs color-black m-l-10'>Clear</div>
  //       </div>
  //     </Menu.Item>
  //   </Menu>
  // );


  const subject = generateQuesObj.subjects.find((subject) => (generateQuesSubChapters.id === subject.subjId));
  const sections = subject?.includeSectionFlag
    ? (
      subject?.sections?.map((sec, index) => (
        <Button key={sec.sectionId} onClick={() => {
          console.log('sec1111', sec)
          if (generateQuesObj?.subjects && generateQuesObj?.subjects?.length) {
            clearGenerateQuesFields();
            updateSubWithChapters(subject?.subName);
            setSelectedSectionId(sec?.sectionId)
            storeSectionIndex(index)
            storeSchemeId(sec?.schemeId)
            getEachQuesData(sec?.questions[0].id)
          }
        }}
          className={sec.sectionId === selectedSectionId ? 'purpleBtnWrap m-r-20 text-xs' : 'blankGreyBtnWrap m-r-20 text-xs'}
          style={{ cursor: (generateQuesObj.subjects && generateQuesObj.subjects?.length > 1) ? 'pointer' : 'auto' }}>
          {sec.sectionName}
        </Button>
      )
      )) : null
  return (
    <div className='r-c-sb p-20'>
      <div className='r-ac' style={{ maxWidth: "100%", overflowX: "auto" }}>
        {sections}
      </div>
      <div>
        <MdMoreVert className='text-lg a2grey cursor-pointer' />
      </div>
    </div>
  )
}

// const Row3 = ({ generateQuesObj, quesPositiveMark, quesNegativeMark,
//   generateQuesSelectedTestType, generateQuesSelectedChapter }) => {
//   return (
//     <div className='p-l-20 p-r-20 r-c-sb'>
//     </div>
//   )
// }

const Row3 = ({ generateQuesObj, generateQuesSubChapters, addMarksForQues, quesPositiveMark, quesNegativeMark,
  storeTestQuesType, generateQuesSelectedTestType, generateQuesSelectedChapter, updateQuestionType, getMarkingSchemeMarks, selectedSectionId, schemeId }) => {

  const removeSelectedChapter = (currChapter) => {
    if (currChapter?.value === generateQuesSelectedChapter?.value) {
      return false
    }
    return true
  }

  const chapHandler = (value) => {
    let chapData = generateQuesSubChapters?.chapters?.filter(chap => chap.value === value)
    console.log('chapDara', chapData)
    storeTestQuesType(chapData[0].value, chapData[0].id, 'quesChapter')
    console.log('testing', chapData)
  }

  const questionTypeList = [{
    title: 'Multiple Choice',
    value: '0-0',
    selectable: false,
    children: [
      {
        title: 'One Correct Answer',
        value: 'MCQ',
        selectable: true,
      }, {
        title: 'Multiple Correct Answer',
        value: 'MSQ',
        selectable: true,
      }
    ],
  },
  {
    title: 'Numeric',
    value: 'Numeric',
  },
  {
    title: 'Comprehensive',
    value: '0-2',
    selectable: false,
    children: [
      {
        title: 'Comp-One Correct Answer',
        value: 'CMCQ',
        selectable: true,
      }, {
        title: 'Comp-Multiple Correct Answer',
        value: 'CMSQ',
        selectable: true,
      }, {
        title: 'Comp-Numeric',
        value: 'CNumeric',
        selectable: true,
      }, {
        title: 'Comp-True/False',
        value: 'CTF',
        selectable: true,
      }
    ]
  }, {
    title: 'True/False',
    value: 'T/F',
    selectable: true,
  }]

  let modifiedQuestionList = []

  generateQuesObj?.subjects?.map((item) => {
    if (item.subName === generateQuesSubChapters.name) {
      let typeOfQuestionArr;
      if (item?.includeSectionFlag === 0) {
        typeOfQuestionArr = item.typeOfQuestion
      } else {
        typeOfQuestionArr = item.sections.find(section => section.id === selectedSectionId)?.typeOfQuestion;
      }
      // if(item?.includeSectionFlag === 0){
      console.log('arrr', typeOfQuestionArr)
      if (typeOfQuestionArr?.length > 0) {
        questionTypeList.forEach((el) => {
          let type;
          const children = [];
          if (el.children) {
            el.children.forEach(baby => {
              if (typeOfQuestionArr.includes(baby.value)) {
                children.push(baby);
              }
            })
            if (children?.length > 0) {
              type = { ...el, children }
            }
          } else {
            if (typeOfQuestionArr.includes(el.value)) {
              // modifiedQuestionList.push(el);
              type = el;
            }
          }
          if (type) {
            modifiedQuestionList.push(type);
          }
        })
      }
      // item.typeOfQuestion.map((name)=>{
      //   modifiedQuestionList = [questionTypeList.filter((value === name))]
      // })
      // }

    }
  })

  console.log('moddididid', modifiedQuestionList)
  console.log('schemeId', schemeId)
  return (
    <div className='p-l-20 p-r-20 r-c-sb'>
      <div className='r-ac'>
        <div className='m-r-30'>
          <div className='text-xmd bold-600 color-black m-b-10'>QuestionType</div>
          {generateQuesObj.testType === 'Objective'
            ?
            <CustomeDropdown
              disable={true}
              arrdata={modifiedQuestionList?.length > 0 ? modifiedQuestionList : questionTypeList}
              defaultvalue={Object.keys(generateQuesSelectedTestType)?.length ? generateQuesSelectedTestType.children : 'Select Question Type'}
              // onchange={(key, value) =>(value==='0-0' || value==='0-2')?null:storeTestQuesType(value, 'quesType')}
              onchange={((value) => {
                storeTestQuesType({ 'key': 0, 'value': 0, 'children': value }, null, 'quesType')
                updateQuestionType(value)
                if (schemeId && schemeId > 0) {
                  getMarkingSchemeMarks(value)
                }
              })}
              placeholder={'Select Question Type'}
            />
            :
            <CustomeDropdown
              arrdata={[]}
              disable={true}
              defaultvalue={'Subjective type'}
              onchange={() => { }} />
          }
        </div>
        <div className='m-r-10'>
          <div className='text-xmd bold-600 color-black m-b-10'>Chapter</div>
          <CustomeDropdown
            disable={true}
            // arrdata={generateQuesSubChapters?.chapters ? generateQuesSubChapters?.chapters?.filter(removeSelectedChapter) : []}
            arrdata={generateQuesSubChapters?.chapters ? generateQuesSubChapters?.chapters : []}
            // disable={generateQuesObj?.syllabus === 'Chapter Test' ? true : false}
            // defaultvalue={generateQuesObj.syllabus === 'Chapter Test' ? generateQuesSubChapters.chapter && generateQuesSubChapters.chapters?.length > 0 && generateQuesSubChapters?.chapters[0]?.name : Object.keys(generateQuesSelectedChapter)?.length ? generateQuesSelectedChapter.children : 'Select Chapter'}
            defaultvalue={Object.keys(generateQuesSelectedChapter)?.length ? generateQuesSelectedChapter.children ? generateQuesSelectedChapter.children : generateQuesSelectedChapter.value : 'Select Chapter'}
            // onchange={(key, value) => storeTestQuesType(value, 'quesChapter')} />
            onchange={(value) => chapHandler(value)} />
        </div>
      </div>
      <div>
        <div className='r-ac m-b-10'>
          <div className='text-xmd bold-600 color-black m-r-5'>Marks</div>
          {
            generateQuesObj.testType == "Objective"
              ?
              <Tooltip placement="right" trigger="hover" title={
                <div>
                  <div className='r-ac'>
                    <div className='radius-100' style={{ width: 10, height: 10, background: '#6BDB9E' }}></div>
                    <div className='text-xmd color-black m-l-10'>Positive Marks</div>
                  </div>
                  <div className='r-ac'>
                    <div className='radius-100' style={{ width: 10, height: 10, background: '#ec5254' }}></div>
                    <div className='text-xmd color-black m-l-10'>Negative Marks</div>
                  </div>
                </div>



              } overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px", background: '#fff' }}>
                <ExclamationCircleOutlined style={{ color: "#1089FF" }} className='text-xs cursor-pointer' />
              </Tooltip>
              :
              null
          }
        </div>
        <div className='r-ac'>
          <div className='r-c-c test-count-seq-box'>
            <AiOutlinePlus />
            <div className='text-xmd m-l-5 m-r-5'>{quesPositiveMark}</div>
            <AiOutlineMinus />
          </div>
          {generateQuesObj.testType === 'Objective'
            ?
            <div className='r-c-c test-count-seq-box m-l-10' style={{ background: '#FFE4D9', borderColor: '#EC5254' }}>
              <AiOutlinePlus />
              <div className='text-xmd m-l-5 m-r-5'>{quesNegativeMark}</div>
              <AiOutlineMinus />
            </div>
            :
            null
          }
        </div>
      </div>
    </div>
  )
}

// const Row4 = ({ generateQuesText, editGenerateQueId, generateQuesArr }) => {
//   let QuestionIndex = null;
//   if (editGenerateQueId == '' || editGenerateQueId == undefined) {

//   } else {
//     generateQuesArr?.subjects?.forEach(subject => {
//       subject?.questions.forEach((question) => {
//         if (question.id === editGenerateQueId) {
//           QuestionIndex = question.index
//         }
//       })
//     });
//   }
//   return (
//     <div className='p-20'>
//       <div className='text-xmd bold-600 color-black'>Question {' '} {QuestionIndex}</div>
//       <div className='test-ques-window resize-editor-img p-10' style={{ width: "100%", overflowX: "hidden" }}>
//         {ReactHtmlParser(generateQuesText,
//           {
//             transform: (node) => {
//               if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
//                 return <TeX math={`${node.attribs['data-value']}`} />;
//               }
//               if (node.type === 'tag' && node.name === 'img') {
//                 return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
//               }
//             }
//           })
//         }
//       </div>
//     </div>
//   )
// }

// const Row5 = ({ generateQuesOptions }) => {
//   return (
//     <div className='p-l-20 p-r-20'>
//       <div className='text-xmd bold-600 color-black'>Options</div>
//       {generateQuesOptions?.map((item, index) => (
//         <div className='r-ac m-b-10'>
//           <div
//             style={{ background: item.correct ? '#28df99' : '', color: item.correct ? '#fff' : '', borderColor: item.correct ? '#28df99' : '' }}
//             className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
//             {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'}
//           </div>
//           <div
//             style={{ background: item.correct ? '#E9FCF5' : '' }}
//             className='test-ques-window p-10 r-c-sb flex-1'>
//             <div className='resize-editor-img' style={{ width: "100%", overflowX: "hidden" }}>
//               {ReactHtmlParser(item.value,
//                 {
//                   transform: (node) => {
//                     if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
//                       return <TeX math={`${node.attribs['data-value']}`} />;
//                     }
//                     if (node.type === 'tag' && node.name === 'img') {
//                       return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
//                     }
//                   }
//                 })
//               }
//             </div>
//             <div keys={index.toString()} className='r-ac m-l-5'>
//               {item.correct
//                 ?
//                 <RiCheckboxCircleLine className='text-sm seaGreen' />
//                 :
//                 <RiCheckboxBlankCircleLine className='text-sm a2grey' />
//               }
//               <div className='m-l-5 text-xs a2grey'>Correct answer</div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }

// const RowNumeric = ({ generateQuesNumericText }) => {
//   return (
//     <div className='p-20 '>
//       <div className='text-xmd bold-600 color-black'>Answer</div>
//       <div className='test-ques-window resize-editor-img p-10'
//         style={{ width: "100%", overflowX: "hidden" }}>
//         {ReactHtmlParser(generateQuesNumericText)}
//       </div>
//     </div>
//   )
// }
// const Row6 = ({ generateSolutionText }) => {
//   return (
//     <div className='p-20 m-b-50'>
//       <div className='text-xmd bold-600 color-black'>Solution</div>
//       <div className='test-ques-window resize-editor-img p-10'
//         style={{ width: "100%", overflowX: "hidden" }}>
//         {ReactHtmlParser(generateSolutionText,
//           {
//             transform: (node) => {
//               if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
//                 return <TeX math={`${node.attribs['data-value']}`} />;
//               }
//               if (node.type === 'tag' && node.name === 'img') {
//                 return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
//               }
//             }
//           })
//         }
//       </div>
//     </div>
//   )
// }

const RowParagraph = ({ showTextEditor, generateParagraphText, QuestionIndex }) => {
  return (
    <div className='p-20'>
      <div className='text-xmd bold-600 color-black m-b-5'>Question {' '} {QuestionIndex}</div>
      <div
        className='cursor-pointer test-ques-window resize-editor-img p-10'
        style={{ width: "100%", overflowX: "hidden" }}>
        {ReactHtmlParser(generateParagraphText,
          {
            transform: (node) => {
              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                return <TeX math={`${node.attribs['data-value']}`} />;
              }
              if (node.type === 'tag' && node.name === 'img') {
                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
              }
            }
          })
        }
      </div>
    </div>
  )
}

const Row6 = ({ showTextEditor, generateSolutionText }) => {
  return (
    <div className='p-20 m-b-50'>
      <div className='text-xmd bold-600 color-black m-b-5'>Solution</div>
      <div
        className='cursor-pointer test-ques-window resize-editor-img p-10'
        style={{ width: "100%", overflowX: "hidden" }}>
        {ReactHtmlParser(generateSolutionText,
          {
            transform: (node) => {
              if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                return <TeX math={`${node.attribs['data-value']}`} />;
              }
              if (node.type === 'tag' && node.name === 'img') {
                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
              }
            }
          })
        }
      </div>
    </div>
  )
}


// const QueGridSide = ({ generateQuesObj, generateQuesArr, updateSubWithChapters, clearGenerateQuesFields,
//   generateQuesSubChapters, getEachQuesData, editGenerateQueId, getEachQuesDataBool }) => {
//   return (
//     <div>
//       {/*generateQuesObj.subjectNames.split(',').map((item, index) => (
//         <div className='text-xs bold-600 color-black m-b-10'>{item}</div>
//       ))*/}
//       {generateQuesArr && Object.keys(generateQuesArr)?.length ?
//         generateQuesArr.subjects?.map((item) => (
//           <div keys={item.subjId}>
//             <div className='text-xs bold-600 color-black m-b-10'>{item.subName}</div>
//             <div className='r-ac flex-wrap' style={{ marginLeft: -10 }}>
//               {item.questions?.map((quee, index) => (
//                 <div
//                   onClick={() => getEachQuesData(quee.id)}
//                   style={{ borderColor: quee.id === editGenerateQueId ? '#636363' : '', borderWidth: quee.id === editGenerateQueId ? 2 : 1 }}
//                   className='r-c-c radius-100 color-black test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
//                   {getEachQuesDataBool && quee.id === editGenerateQueId ? <LoadingOutlined /> : quee.index}
//                 </div>
//               ))}
//               {/*<div
//               onClick={() => {
//                 updateSubWithChapters(item.subName);
//                 // clearGenerateQuesFields();
//               }}
//               style={{borderColor:item.subName === generateQuesSubChapters.name ? '#636363' : '', borderWidth: item.subName === generateQuesSubChapters.name ? 2 : 1}}
//               className='r-c-c radius-100 a2grey text-sm test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
//               <AiOutlinePlus style={{color:item.subName === generateQuesSubChapters.name ? '#191919' : ''}}/>
//             </div>*/}
//             </div>
//           </div>
//         ))
//         : null
//       }

//     </div>
//   )
// }





function TestQuesNonEditablScreen({ history, generateQuesObj, showTextEditor, generateQuesSubChapters,
  updateSubWithChapters, addMarksForQues, quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, chooseCorrectAnswer, generateSolutionText, storeTestQuesType,
  generateQuesSelectedTestType, generateQuesSelectedChapter, postQuestionCreate, generateQuesRemovedImgs,
  generateQuesArr, clearGenerateQuesFields, getEachQuesData, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, deleteQuesFromServer, postQuestionCreateBool, showGenerateQuesListView, unsetCreateTestModalBool,
  generateCommentText, userType, updateNumericAnswer, renderEditorContent, numericAnswer, updatePostParams, selectedCardType,
  user, selectedTest, textEditorBool, selectedSectionId, setSelectedSectionId, questionContent, answerContent, chooseBoolAnswer, orQuestionIndicator, compQuestionIndicator, updateQuestionType, generateParagraphText, storeQuestionIndex, questionIndexStore, storeSectionIndex, sectionIndexStore, createAdditionalQuestion, storeSchemeId, getMarkingSchemeMarks, schemeId, storeSubjectSection }) {

  // useEffect(() => {
  //   return () => {
  //     unsetCreateTestModalBool()
  //   }
  // }, [])
  const [showEditorModal, setShowEditorModal] = useState(false)
  const grabAllfield = () => {
    let obj = generateQuesOptions?.filter((item, index) => item.correct === true);
    let checkTestType = generateQuesObj.testType === 'Subjective';
    let checkQuesType = generateQuesSelectedTestType.children === 'Multiple Choice Questions';

    if (!Object.keys(generateQuesSelectedTestType)?.length && !checkTestType) {
      return Notification.error("Error", 'Please select test type');
    } else if (!Object.keys(generateQuesSelectedChapter)?.length) {
      return Notification.error("Error", 'Please select any chapter');
    } else if (quesPositiveMark === 0 && quesNegativeMark === 0) {
      return Notification.error("Error", 'Please add marks for the question');
    } else if (generateQuesText === '') {
      return Notification.error("Error", 'Please add question');
    } else if ((generateQuesOptions[0].value === '' || generateQuesOptions[1].value === '' || generateQuesOptions[2].value === '' || generateQuesOptions[3].value === '') && (generateQuesSelectedTestType.children === 'Multiple Choice Questions')) {
      return Notification.error("Error", 'Please add options');
    } else if (!obj?.length && (generateQuesSelectedTestType.children === 'Multiple Choice Questions')) {
      return Notification.error("Error", 'Please select any correct option');
    } else if (generateQuesNumericText === '' && (generateQuesSelectedTestType.children === 'Numeric Type Questions')) {
      return Notification.error("Error", 'Please add answer');
    }

    let params = {
      statement: generateQuesText,
      options: checkTestType ? null : checkQuesType ? generateQuesOptions : [],
      answer: checkTestType ? ' ' : checkQuesType ? obj[0].id : generateQuesNumericText,
      type: checkTestType ? 'Subjective' : generateQuesSelectedTestType.value === 0 ? 'MCQ' : 'Numeric',
      positiveMarks: quesPositiveMark,
      negativeMarks: checkTestType ? null : quesNegativeMark,
      deleteImages: generateQuesRemovedImgs,
      testId: generateQuesObj.id,
      chapterId: generateQuesSelectedChapter.value,
      solution: generateSolutionText,
    }
    if (editGenerateQueId !== '') {
      params.id = editGenerateQueId;
    }
    postQuestionCreate(params);
  }

  useEffect(() => {
    document.getElementById("root-1-conditional-height-for-formula").style.overflowY = "hidden";
    return () => {
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY = "auto";
    }
  }, []);

  return (
    <div style={{ height: '100%', margin: '-20px' }}>
      <TopNavigationColorHeader history={history}  type="no-edit"/>
      <div className='display-flex' style={{ height: 'calc(100% - 20px)' }}>
        <div className='flex-1 position-relative'>
          <div className='overflow-scroll' style={{ height: 'calc(100% - 20px)' }}>
            <Row1
              history={history}
              generateQuesObj={generateQuesObj}
              showGenerateQuesListView={showGenerateQuesListView}
              generateQuesArr={generateQuesArr} />
            <div className='horizontal-divider'></div>
            <RowSub
              generateQuesObj={generateQuesObj}
              deleteQuesFromServer={deleteQuesFromServer}
              editGenerateQueId={editGenerateQueId}
              clearGenerateQuesFields={clearGenerateQuesFields}
              generateQuesSubChapters={generateQuesSubChapters}
              updateSubWithChapters={updateSubWithChapters}
              setSelectedSectionId={setSelectedSectionId}
              storeSchemeId={storeSchemeId}
              storeSubjectSection={storeSubjectSection}
              getEachQuesData={getEachQuesData}
            />
            {/* <Row2
                generateQuesObj={generateQuesObj}
                generateQuesSubChapters={generateQuesSubChapters}
                getEachQuesData = {getEachQuesData}
              /> */}
            <Row2
              generateQuesObj={generateQuesObj}
              deleteQuesFromServer={deleteQuesFromServer}
              editGenerateQueId={editGenerateQueId}
              clearGenerateQuesFields={clearGenerateQuesFields}
              generateQuesSubChapters={generateQuesSubChapters}
              updateSubWithChapters={updateSubWithChapters}
              // setDeleteModalVisible={setDeleteModalVisible}
              // setDeleteLoader={setDeleteLoader}
              setSelectedSectionId={setSelectedSectionId}
              selectedSectionId={selectedSectionId}
              storeSectionIndex={storeSectionIndex}
              storeSchemeId={storeSchemeId}
              schemeId={schemeId}
              storeSubjectSection={storeSubjectSection}
              getEachQuesData={getEachQuesData}

            />
            {/* <Row3
                generateQuesObj={generateQuesObj}
                quesPositiveMark={quesPositiveMark}
                quesNegativeMark={quesNegativeMark}
                generateQuesSelectedTestType={generateQuesSelectedTestType}
                generateQuesSelectedChapter={generateQuesSelectedChapter}
              /> */}

            <Row3
              generateQuesObj={generateQuesObj}
              generateQuesSubChapters={generateQuesSubChapters}
              quesPositiveMark={quesPositiveMark}
              quesNegativeMark={quesNegativeMark}
              storeTestQuesType={storeTestQuesType}
              generateQuesSelectedTestType={generateQuesSelectedTestType}
              generateQuesSelectedChapter={generateQuesSelectedChapter}
              addMarksForQues={addMarksForQues}
              updateQuestionType={updateQuestionType}
              getMarkingSchemeMarks={getMarkingSchemeMarks}
              selectedSectionId={selectedSectionId}
              schemeId={schemeId}
            />
            {/* <Row4 generateQuesText={generateQuesText}
              generateQuesArr={generateQuesArr}
              editGenerateQueId={editGenerateQueId} />
            {generateQuesObj.testType === 'Subjective'
              ?
              null
              :
              Object.keys(generateQuesSelectedTestType)?.length
                ?
                generateQuesSelectedTestType.children === 'Multiple Choice Questions'
                  ?
                  <Row5 generateQuesOptions={generateQuesOptions} />
                  :
                  <RowNumeric generateQuesNumericText={generateQuesNumericText} />
                :
                null
            } */}
            {compQuestionIndicator ?
              <RowParagraph showTextEditor={showTextEditor} generateParagraphText={generateParagraphText} QuestionIndex={questionIndexStore} />
              :
              null}
            {orQuestionIndicator ?
              questionContent?.orQuestions?.map((item, index) => (
                <QuesAnsContainer
                  showTextEditor={showTextEditor}
                  generateQuesText={generateQuesText}
                  editGenerateQueId={editGenerateQueId}
                  generateQuesArr={generateQuesArr}
                  chooseCorrectAnswer={chooseCorrectAnswer}
                  generateQuesOptions={generateQuesOptions}
                  generateQuesNumericText={generateQuesNumericText}
                  updateNumericAnswer={updateNumericAnswer}
                  renderEditorContent={renderEditorContent}
                  numericAnswer={numericAnswer}
                  generateQuesObj={generateQuesObj}
                  generateQuesSelectedTestType={generateQuesSelectedTestType}
                  questionContent={item.questionContent}
                  answerContent={answerContent[index]}
                  chooseBoolAnswer={chooseBoolAnswer}
                  orIndex={index}
                  sectionIndexStore={sectionIndexStore}
                  storeQuestionIndex={storeQuestionIndex}
                  nonEditable={true}
                />
              ))
              : compQuestionIndicator ?
                questionContent?.subQuestions?.map((item, index) => (
                  <QuesAnsContainer
                    showTextEditor={showTextEditor}
                    generateQuesText={generateQuesText}
                    editGenerateQueId={editGenerateQueId}
                    generateQuesArr={generateQuesArr}
                    chooseCorrectAnswer={chooseCorrectAnswer}
                    generateQuesOptions={generateQuesOptions}
                    generateQuesNumericText={generateQuesNumericText}
                    updateNumericAnswer={updateNumericAnswer}
                    renderEditorContent={renderEditorContent}
                    numericAnswer={numericAnswer}
                    generateQuesObj={generateQuesObj}
                    generateQuesSelectedTestType={generateQuesSelectedTestType}
                    questionContent={item.questionContent}
                    answerContent={answerContent[index]}
                    chooseBoolAnswer={chooseBoolAnswer}
                    paragraphText={questionContent?.paragraph}
                    compIndex={index}
                    sectionIndexStore={sectionIndexStore}
                    storeQuestionIndex={storeQuestionIndex}
                    nonEditable={true}
                  />
                )) :
                <QuesAnsContainer
                  showTextEditor={showTextEditor}
                  generateQuesText={generateQuesText}
                  editGenerateQueId={editGenerateQueId}
                  generateQuesArr={generateQuesArr}
                  chooseCorrectAnswer={chooseCorrectAnswer}
                  generateQuesOptions={generateQuesOptions}
                  generateQuesNumericText={generateQuesNumericText}
                  updateNumericAnswer={updateNumericAnswer}
                  renderEditorContent={renderEditorContent}
                  numericAnswer={numericAnswer}
                  generateQuesObj={generateQuesObj}
                  generateQuesSelectedTestType={generateQuesSelectedTestType}
                  questionContent={questionContent}
                  answerContent={answerContent}
                  chooseBoolAnswer={chooseBoolAnswer}
                  sectionIndexStore={sectionIndexStore}
                  storeQuestionIndex={storeQuestionIndex}
                  nonEditable={true}
                />}

            {/* <Row6 generateSolutionText={generateSolutionText} /> */}
            <Row6 showTextEditor={showTextEditor} generateSolutionText={generateSolutionText} />
          </div>
          {/* <div onClick={postQuestionCreateBool ? () => {} : grabAllfield} className='p-20 test-save-sticky-footer cursor-pointer r-c-c'>
              {postQuestionCreateBool ? <LoadingOutlined className='m-r-10' style={{color:'#1089ff'}}/> : null}
              <div className='text-xmd bold-600 blue text-center cursor-pointer'>SAVE & NEXT</div>
            </div> */}
        </div>
        <div className='vertical-divider'></div>
        <div className='p-20 overflow-scroll' style={{ width: 350 }}>
          <div className='text-xs a2grey bold-600 m-b-20'>ALL QUESTIONS</div>
          {/* <QueGridSide
            generateQuesObj={generateQuesObj}
            generateQuesArr={generateQuesArr}
            updateSubWithChapters={updateSubWithChapters}
            clearGenerateQuesFields={clearGenerateQuesFields}
            generateQuesSubChapters={generateQuesSubChapters}
            getEachQuesData={getEachQuesData}
            editGenerateQueId={editGenerateQueId}
            getEachQuesDataBool={getEachQuesDataBool}
          /> */}
          <QueGridSide
            generateQuesObj={generateQuesObj}
            generateQuesArr={generateQuesArr}
            updateSubWithChapters={updateSubWithChapters}
            clearGenerateQuesFields={clearGenerateQuesFields}
            generateQuesSubChapters={generateQuesSubChapters}
            getEachQuesData={getEachQuesData}
            editGenerateQueId={editGenerateQueId}
            getEachQuesDataBool={getEachQuesDataBool}
            updateNumericAnswer={updateNumericAnswer}
            selectedCardType={selectedCardType}
            selectedSectionId={selectedSectionId}
            setSelectedSectionId={setSelectedSectionId}
            storeSectionIndex={storeSectionIndex}
            storeSchemeId={storeSchemeId}
            nonEditable={true}
          />

        </div>
      </div>
      {
        textEditorBool && (
          <TextEditorModal visible={showEditorModal} closeModal={() => setShowEditorModal(false)} />
        )
      }
      <TestPublishModal history={history} />
      <CreateTestModal history={history} />
    </div>
  );
}

const mapStateToProps = state => {
  const { generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
    generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
    generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
    getEachQuesDataBool, postQuestionCreateBool, generateCommentText, userType, numericAnswer,
    selectedCardType, selectedTest, textEditorBool, selectedSectionId, questionContent, answerContent, orQuestionIndicator, compQuestionIndicator, generateParagraphText, questionIndexStore, sectionIndexStore, schemeId, storeSubjectSection } = state.testAdmin;
  const { user } = state.session;
  return {
    generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
    generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
    generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
    getEachQuesDataBool, postQuestionCreateBool, generateCommentText, userType, numericAnswer, selectedCardType, user, selectedTest, textEditorBool, selectedSectionId, questionContent, answerContent, orQuestionIndicator, compQuestionIndicator, generateParagraphText, questionIndexStore, sectionIndexStore, schemeId, storeSubjectSection
  };
};

const mapDispatchToProps = dispatch => ({
  showTextEditor: (name, index, val) => dispatch(showTextEditor(name, index, val)),
  updateSubWithChapters: (name) => dispatch(updateSubWithChapters(name)),
  addMarksForQues: (operation, name) => dispatch(addMarksForQues(operation, name)),
  chooseCorrectAnswer: (val, index, identifier) => dispatch(chooseCorrectAnswer(val, index, identifier)),
  storeTestQuesType: (obj, id, name) => dispatch(storeTestQuesType(obj, id, name)),
  postQuestionCreate: (params) => dispatch(postQuestionCreate(params)),
  clearGenerateQuesFields: (val) => dispatch(clearGenerateQuesFields(val)),
  getEachQuesData: (id) => dispatch(getEachQuesData(id)),
  deleteQuesFromServer: (callback) => dispatch(deleteQuesFromServer(callback)),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
  unsetCreateTestModalBool: () => dispatch(unsetCreateTestModalBool()),
  updateNumericAnswer: (newAnswer, index) => dispatch(updateNumericAnswer(newAnswer, index)),
  renderEditorContent: (name, value) => dispatch(renderEditorContent(name, value)),
  updatePostParams: (newParams) => dispatch(updatePostParams(newParams)),
  setSelectedSectionId: (val) => dispatch(setSelectedSectionId(val)),
  chooseBoolAnswer: (val, bool, index) => dispatch(chooseBoolAnswer(val, bool, index)),
  updateQuestionType: (value, options) => dispatch(updateQuestionType(value, options)),
  storeQuestionIndex: (value) => dispatch(storeQuestionIndex(value)),
  storeSectionIndex: (value) => dispatch(storeSectionIndex(value)),
  createAdditionalQuestion: (identifier, value) => dispatch(createAdditionalQuestion(identifier, value)),
  storeSchemeId: (value) => dispatch(storeSchemeId(value)),
  getMarkingSchemeMarks: (type, callback) => dispatch(getMarkingSchemeMarks(type, callback))
});


// const mapStateToProps = state => {
//   const { generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
//     generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
//     generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
//     getEachQuesDataBool, postQuestionCreateBool, textEditorBool } = state.testAdmin;
//   return {
//     generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
//     generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
//     generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
//     getEachQuesDataBool, postQuestionCreateBool, textEditorBool
//   };
// };

// const mapDispatchToProps = dispatch => ({
//   showTextEditor: (name) => dispatch(showTextEditor(name)),
//   updateSubWithChapters: (name) => dispatch(updateSubWithChapters(name)),
//   addMarksForQues: (operation, name) => dispatch(addMarksForQues(operation, name)),
//   chooseCorrectAnswer: (index) => dispatch(chooseCorrectAnswer(index)),
//   storeTestQuesType: (obj, name) => dispatch(storeTestQuesType(obj, name)),
//   postQuestionCreate: (params) => dispatch(postQuestionCreate(params)),
//   clearGenerateQuesFields: () => dispatch(clearGenerateQuesFields()),
//   getEachQuesData: (id) => dispatch(getEachQuesData(id)),
//   deleteQuesFromServer: () => dispatch(deleteQuesFromServer()),
//   showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
//   unsetCreateTestModalBool: () => dispatch(unsetCreateTestModalBool())
// });

export default connect(mapStateToProps, mapDispatchToProps)(TestQuesNonEditablScreen);
