import {io} from 'socket.io-client4'
import { v4 as uuidv4 } from 'uuid';

export const setUpChatSocket = () => {
    let chatSocket = socketClusterClient.create({
        hostname: 'socketclusterchat.aulas.in',
        secure: true,
        port: 443
      });;
    
    
      (async () => {
        (async() => {
          for await (let event of chatSocket.listener('connect')) {
            console.log('Socket is connected', chatSocket.id);
    
            // if(!firstConnect){
            //     rc.updateSocketId(chatSocket.id,userIdInput.value,roomIdInput.value);
            // }
          }
        })();
        (async() => {
          for await (let event of chatSocket.listener('error')){
            console.log('chatsocket is error',event);
          }
        })();
        (async() => {
          for await (let event of chatSocket.listener('disconnect')){
            console.log('chatsocket is disconnected');
          }
        })();
    
        
      })();
    
    
      chatSocket.request = function request(type, data = {}) {
        if (data?.data?.type!='Event'){
            console.log("chatSocket state", chatSocket.state);
            console.log('sending ',type,data);
        }
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await chatSocket.invoke(type, data)
                    if (res.message?.data?.type!='Event'){
                        console.log("res logs", res);
                    }
                    resolve(res)
                } catch (error) {
                    console.log("Invoke Error", error);
                    reject(error)
                }
                
            }
            )();
        })
    }
    return chatSocket
}

export const setUpRoomClientSocket = (path, history, socketCallback) => {
  const uniqueId = uuidv4();
  const socket = io("https://broadcast.aulas.in",{
    path: path,
    query: {
      customId: uniqueId // Optionally, you can pass this customId to the server
    }
})
  socket.request = function request(type, data = {}) {
      console.log('sending',type,data);
      return new Promise((resolve, reject) => {
          socket.emit(type, data, (data) => {
              if (data.error) {
                  reject(data.error)
              } else {
                  resolve(data)
              }
          })
      })
  }
  socket.on('muxError', function (link)  {


    socketCallback('muxError', link);
    
    // this.produce("audioType")
  })
  socket.on('reload', () => {
      // location = location
  })
  socket.on("connect", () => {
      // location = location
      socketCallback('connect')
      console.log("socket connected 765345")
  })
  socket.on("open", () => {
      // location = location
      console.log("socket open")
  })
  socket.on("disconnect", (reason) => {
    if (reason === "io server disconnect") {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect();
    }
    else if (reason === "io client disconnect"){
        console.log("socket disconnected by the client");
    }
    else if(reason === "ping timeout"){
        console.log("server doesn't send ping within the pinginterval + ping timeout range")
    }
    else if(reason === "transport close"){
        console.log("user lost the connection and tried to change the connection")
    }
    else{
        console.log("connection has encountered an error")
    }
    socketCallback('disconnect', reason);

  });
  return socket
}