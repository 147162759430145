import { ArrowLeftOutlined } from '@ant-design/icons'
import { getVideoResourcesData, postUpdateVideoProgress } from 'actions/studentMarketPlace'
import PageHeader from 'components/pageHeader'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'

function PdfViewMyCourse({ pdfViewFile,postUpdateVideoProgress,getVideoResourcesData }) {
    const location=useLocation()
    const history = useHistory();
    console.log("location",location);
    const param={
        id: location?.state?.id,
        videoResourceId:location?.state?.videoResourceId,
        progress: location?.state?.progress,
    }
    useEffect(()=>{
        if(location?.state?.fromVideoResource){
            postUpdateVideoProgress(param, (res) => {
                if (res) {
                    getVideoResourcesData(itemIdVideoResource, () => { togglePageLoader(false); })
                }
            });
        }
        

    },[])
    return (
        <div>
            <div className='parent-compo'>
                <div className='conatiner-1'>
                    <PageHeader title={pdfViewFile?.name} onBackPress={() => { history.goBack(); }} />
                </div>
                <div style={{ height: "90vh", width: "100%",marginTop:60 }}>
                    <iframe
                        width="100%"
                        height='100%'
                        src={`${Api.dlS3Url}${pdfViewFile?.file?.url ? pdfViewFile?.file?.url : pdfViewFile?.url}#toolbar=0`}
                        frameborder="0"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { pdfViewFile } = state.studentMarketPlace
    return {
        pdfViewFile
    }
};

const mapDispatchToProps = (dispatch) => ({
    postUpdateVideoProgress: (param, callback) => dispatch(postUpdateVideoProgress(param, callback)),
    getVideoResourcesData: (id, callback) => dispatch(getVideoResourcesData(id, callback)),


});

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewMyCourse)
