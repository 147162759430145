import { getCourseDetails } from 'actions/studentMarketPlace'
import PageDataLoader from 'components/pageDataLoader'
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CourseDetailsDescriptionSection from '../courseDetailsDescriptionSection'
import CourseDetailsLearnings from '../courseDetailsLearnings'
import CourseDetailsIncludes from '../courseDetailsIncludes'
import Prerequests from '../coursePageCompo/prerequests'
import CourseContent from '../coursePageCompo/courseContent'
import RelatedCourse from '../coursePageCompo/relatedCourse'
import CourseRatings from '../coursePageCompo/courseRatings'
import RelatedCoursesCompo from './relatedCoursesCompo'

const CourseDetailsPages = ({ getCourseDetails, user, singleCourseDetailsDataContainer }) => {
        const history = useHistory()
        const location = useLocation()
        const [pageLoader, togglePageLoader] = useState(false)
        console.log("location213", singleCourseDetailsDataContainer?.course?.courseName);
        useEffect(() => {
                togglePageLoader(true)
                getCourseDetails(user?.accessToken, location?.state?.fromMyCourcesPage?.id, () => { togglePageLoader(false) })
        }, [])
        return (
                <div className='parent' style={{}}>
                        <div className='parent-compo' style={{}}>
                                <div className='container-1' style={{}}>
                                        <PageHeader title={'Live courses'} onBackPress={() => { history.push('/courses-list') }} />
                                </div>
                                <div className='container-2-liveCourses'>

                                        <CourseDetailsDescriptionSection
                                                singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.course} fromMyCourcesPage={location?.state?.fromMyCourcesPage} />
                                        <div className='m-t-40'>
                                                {singleCourseDetailsDataContainer?.course?.learnings?.length > 0 ? <CourseDetailsLearnings singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.course} fromMyCourcesPage={location?.state?.fromMyCourcesPage} /> : null}
                                        </div>
                                        <div className='m-t-40'>
                                                {singleCourseDetailsDataContainer?.course?.details?.length > 0 ? <div style={{ marginTop: "20px" }}>
                                                        <CourseDetailsIncludes singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.course} fromMyCourcesPage={location?.state?.fromMyCourcesPage} /></div> : null}
                                        </div>
                                        <div className='m-t-40'>
                                                <CourseContent singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.course} fullData = {singleCourseDetailsDataContainer} fromMyCourcesPage={location?.state?.fromMyCourcesPage} />
                                        </div>
                                        <div className='m-t-40'>
                                                {singleCourseDetailsDataContainer?.course?.prerequisites?.length > 0 ? <Prerequests singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.course?.prerequisites} fromMyCourcesPage={location?.state?.fromMyCourcesPage} /> : null}
                                        </div>

                                        {singleCourseDetailsDataContainer?.related?.length > 0 ? 
                                        <div style={{ fontSize: "21px", fontWeight: "bold", marginTop: "40px" }}>Related Courses</div>
                                        :<></>}

                                        {singleCourseDetailsDataContainer?.related?.length > 0 && singleCourseDetailsDataContainer?.related?.map((item, index) => (
                                                <RelatedCoursesCompo
                                                        key={index}
                                                        index={index}
                                                        course={item}
                                                        itemType="course"
                                                />
                                        ))}

                                        <PageDataLoader visible={pageLoader} />
                                </div>
                        </div>
                </div>
        )
}

const mapStateToProps = (state) => {
        const { singleCourseDetailsDataContainer } = state.studentMarketPlace;
        const { user } = state.session
        return { singleCourseDetailsDataContainer, user };
};
const mapDispatchToProps = (dispatch) => ({
        getCourseDetails: (token, id, callback) => dispatch(getCourseDetails(token, id, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsPages)