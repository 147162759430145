import { getKycDetails, setGsSelectedTab, setKycSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab } from 'actions/profileNew';
import { Row, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import { IsJsonString } from 'pages/user/modal/studentModal';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { easySwitch, IndividualDetails } from './subComponents';


const kycDetailsFields = (kycDetails, handleViewAccNo, viewAccNo) => ({
    "Contact information": {
        fields: [
            { label: 'Name', value: kycDetails?.kyc?.contactName },
            { label: 'Mobile number', value: kycDetails?.kyc?.contactPhone },
            { label: 'Email ID', value: kycDetails?.kyc?.contactEmail },

        ]
    },
    "Institute details": {
        fields: [
            { label: 'Name', value: kycDetails?.kyc?.name ? kycDetails?.kyc?.name : 'NA' },
            { label: 'Website', value: kycDetails?.kyc?.website ? kycDetails?.kyc?.website : 'NA' },
            { label: 'Institute type', value: kycDetails?.kyc?.orgType ? kycDetails?.kyc?.orgType : 'NA' },
            { label: 'Number of students', value: kycDetails?.kyc?.strength ? kycDetails?.kyc?.strength : 'NA' }, // same till here. 
            (['Private Limited', 'Trust', 'Society', 'LLP']?.includes(kycDetails?.kyc?.orgType)) && { label: 'Business PAN', value: kycDetails?.kyc?.institute_pan ? kycDetails?.kyc?.institute_pan : 'NA' },
            (['Private Limited'].includes(kycDetails?.kyc?.orgType)) && { label: 'CIN', value: kycDetails?.kyc?.cin ? kycDetails?.kyc?.cin : 'NA' },
            (['LLP'].includes(kycDetails?.kyc?.orgType)) && { label: 'LLPIN', value: kycDetails?.kyc?.llpin ? kycDetails?.kyc?.llpin : 'NA' },
            { label: 'Authorised signatory PAN', value: kycDetails?.kyc?.pan ? kycDetails?.kyc?.pan : 'NA' },
            { label: 'PAN owner\'s name', value: kycDetails?.kyc?.pan_owner ? kycDetails?.kyc?.pan_owner : 'NA' },
            (['Private Limited', 'Propreitorship', 'Trust', 'Society', 'Partnership', 'LLP']?.includes(kycDetails?.kyc?.orgType)) && { label: 'GST no.', value: kycDetails?.kyc?.gstin ? kycDetails?.kyc?.gstin : "NA" },
            { label: 'HSN/SAC.', value: kycDetails?.kyc?.hsn_sac ? kycDetails?.kyc?.hsn_sac : 'NA' },
            (['Trust', 'Society', 'Not Registered'].includes(kycDetails?.kyc?.orgType) && {}),
            { label: 'Building No./Locality/Area', value: kycDetails?.kyc?.address && IsJsonString(kycDetails?.kyc?.address) ? JSON.parse(kycDetails?.kyc?.address).locality : "" },
            { label: 'Pincode', value: kycDetails?.kyc?.address && IsJsonString(kycDetails?.kyc?.address) ? JSON.parse(kycDetails?.kyc?.address).pincode : "" },
            { label: 'District', value: kycDetails?.kyc?.address && IsJsonString(kycDetails?.kyc?.address) ? JSON.parse(kycDetails?.kyc?.address).city : "" },
            { label: 'State', value: kycDetails?.kyc?.address && IsJsonString(kycDetails?.kyc?.address) ? JSON.parse(kycDetails?.kyc?.address).state : "" },
            { label: 'Country', value: kycDetails?.kyc?.country },
            {},
            { label: 'Unique ID', value: kycDetails?.kyc?.instituteId },
            { label: 'Domain URL', value: kycDetails?.kyc?.url },
        ]
    },
    "Bank account": {
        fields: [
            { label: 'Beneficiary Name', value: kycDetails?.kyc?.account_name },
            { label: 'IFSC', value: kycDetails?.kyc?.ifsc },
            { label: 'Bank', value: kycDetails?.kyc?.bank_name },
            { label: 'Branch', value: kycDetails?.kyc?.branch_name },
            { label: 'Account no', value: !viewAccNo ? kycDetails?.kyc?.account_no.split("").map(item => "•") : kycDetails?.kyc?.account_no, hidden: true, onView: () => handleViewAccNo(!viewAccNo) },
            { label: '', value: '' },
            { label: 'Cancelled cheque', value: kycDetails?.kyc?.cancelled_cheque, type: 'document' },
        ]
    },
    "Documents": {
        fields: [
            { label: kycDetails?.kyc?.proof_of_address_type + ' Front', value: kycDetails?.kyc ? kycDetails?.kyc[kycDetails?.kyc?.proof_of_address_type !== "Voter ID" ? kycDetails?.kyc?.proof_of_address_type?.toLowerCase() + '_front' : 'voter' + '_front'] : "", type: 'document' },
            { label: kycDetails?.kyc?.proof_of_address_type + ' Back', value: kycDetails?.kyc ? kycDetails?.kyc[kycDetails?.kyc?.proof_of_address_type !== "Voter ID" ? kycDetails?.kyc?.proof_of_address_type?.toLowerCase() + '_front' : 'voter' + '_back'] : "", type: 'document' },
            { label: 'Business registration proof', value: kycDetails?.kyc?.institute_registration_proof, type: 'document' },
            { label: 'Business PAN', value: kycDetails?.kyc?.pan_card, type: 'document' },
        ]
    }
})

const tabs = (kycDetails, handleViewAccNo, viewAccNo) => ({
    "Contact information": {
        body: (
            <Row>
                {kycDetailsFields(kycDetails)["Contact information"]?.fields?.map((data, index) => data && <IndividualDetails data={data} index={index} />)}
            </Row>
        )
    },
    "Institute details": {
        body: (
            <Row>
                {kycDetailsFields(kycDetails)["Institute details"]?.fields?.map((data, index) => data && <IndividualDetails data={data} index={index} />)}
            </Row>
        )
    },
    "Bank account": {
        body: (
            <Row>
                {kycDetailsFields(kycDetails, handleViewAccNo, viewAccNo)["Bank account"]?.fields?.map((data, index) => data && <IndividualDetails data={data} index={index} />)}
            </Row>
        )
    },
    "Documents": {
        body: (
            <Row>
                {kycDetailsFields(kycDetails)["Documents"]?.fields?.map((data, index) => data && <IndividualDetails data={data} index={index} />)}
            </Row>
        )
    },
    "Agreement": {
        body: (
            <Row>
                <iframe style={{ width: "100%", height: "80vh", border: 'none' }} frameborder="0" src={kycDetails?.kyc?.kycAgreementFile + "#toolbar=0&navpanes=0"} />
            </Row>
        )
    }
})

const TabsComponent = ({ handleTabSelect, selectedTab, kycDetails, handleViewAccNo, viewAccNo }) => {

    return (
        <Tabs onChange={handleTabSelect} activeKey={selectedTab}>
            {Object.keys(tabs(kycDetails, handleViewAccNo, viewAccNo)).map((tab, index) => (
                <TabPane tab={tab} key={tab}>
                    {tabs(kycDetails, handleViewAccNo, viewAccNo)[tab].body}
                </TabPane>
            )
            )}
        </Tabs>
    )
}


const ViewKYC = ({ setKycSelectedTab, kycSelectedTab, getKycDetails, kycDetails, user, setGsSelectedTab,
    setSubscriptionSelectedTab, setOthersSelectedTab }) => {
    const history = useHistory();
    const handleTabSelect = (key) => {
        setKycSelectedTab(key);
    }

    useEffect(() => {
        setPageLoader(true)
        getKycDetails(() => setPageLoader(false));
        return () => {
            setKycSelectedTab("Contact information")
        }
    }, []);

    const [pageLoader, setPageLoader] = useState(false);
    const [viewAccNo, handleViewAccNo] = useState(false);

    const handleBackPress = () => {
        if (["/general-settings", "/view-kyc", "/subscriptions", "/support-settings", "/user-profile/others"].includes(history.location?.state?.from)) {
            history.push('/account-settings')
        } else {
            history.goBack()
        }
    }

    return (
        <div div={{ height: "100%" }}>
            <PageHeader
                title="KYC Details"
                onBackPress={handleBackPress}
                actions={
                    false ? [<Button type="link" style={{ fontSize: 14 }}>EDIT</Button>] : []
                }
                easySwitch={easySwitch(history, user, setGsSelectedTab, setSubscriptionSelectedTab, setOthersSelectedTab)}
            />
            <div className='r-c-c'>
                <div style={{ width: "80%" }} className="m-t-50">
                    <TabsComponent viewAccNo={viewAccNo} handleViewAccNo={handleViewAccNo} handleTabSelect={handleTabSelect} selectedTab={kycSelectedTab} kycDetails={kycDetails} />
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    );
}


const mapStateToProps = (state) => {
    const { kycSelectedTab, kycDetails } = state.profileNew;
    const { user } = state.session;
    return {
        kycSelectedTab, kycDetails, user
    }
}

const mapDispatchToProps = (dispatch) => ({
    setKycSelectedTab: (tab) => dispatch(setKycSelectedTab(tab)),
    getKycDetails: (callback) => dispatch(getKycDetails(callback)),
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    setSubscriptionSelectedTab: (tab) => dispatch(setSubscriptionSelectedTab(tab)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewKYC);
