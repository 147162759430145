import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import {  } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { changeTestStatusModal, changeTestStatus } from 'actions/practiseTest';
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdPublish, MdKeyboardBackspace } from "react-icons/md";
import '../styles.css';
import { Color } from 'services';
import { Col, Row , Typography} from 'antd';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;


function TestPublishModal ({history, changeTestStatusModalBool, changeTestStatusModal, modalActiveName,
  changeTestStatus, changeTestStatusBool, generateQuesObj, selectedTest}) {
    return (
      <Modal
        centered
        className='modal-round-corner'
        style={{padding:0}}
        visible={changeTestStatusModalBool}
        onCancel={null}
        onOk={null}
        footer={null}
        closable={false}
      >
        {
        modalActiveName === 'Published'
        ?
          <div>
            <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Publish Test</div>
            <div className='text-xs text-center a2grey m-b-20'>Are you sure you want to publish this test?</div>
          </div>
        :
        modalActiveName == 'Ready'
        ?
        <div>
          <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Mark as ready</div>
          <div className='text-xs text-center a2grey m-b-20'>
            Are you sure you want to mark this test as ready for publishing?
          </div>
        </div>
        :
        modalActiveName=='MarkReady'
        ?
        <div>
          <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Mark as ready</div>
          <div className='text-xs text-center a2grey m-b-20'>
            Are you sure you want to mark this test as ready for publishing?
          </div>
        </div>
        :
        modalActiveName=='Feedback'
        ?
          <div>
            <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Send Feedback</div>
            <div className='text-xs text-center a2grey m-b-20'>
              Are you sure you want to send this Feedback?
            </div>
          </div>
        :
        modalActiveName=='SendReview'
        ?
        <div>
        <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Send for review</div>
        <div className='text-xs text-center a2grey m-b-20'>
          Are you sure you want to send the test for review to
          <sapn className='bold-600 color-black'> {selectedTest.teacherName}? </sapn>
        </div>
        </div>
        :
        modalActiveName=='Deleted'
        ?
          <div>
          <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Delete Test</div>
          <div className='text-xs text-center a2grey m-b-20'>
            Are you sure you want to delete this test ?
          </div>
          </div>
        :
        modalActiveName==='Draft'
        ?
        <div>
        <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Unpublish Test</div>
        <div className='text-xs text-center a2grey m-b-20'>
          Are you sure you want to unpublish this test?
        </div>
      </div>
        :
          <div>
            <div className='text-sm text-center bold-600 color-purple m-t-10 m-b-10'>Send for review</div>
            <div className='text-xs text-center a2grey m-b-20'>
              Are you sure you want to send the test for review to
              <sapn className='bold-600 color-black'> {generateQuesObj.selectedTeacher!=null?generateQuesObj.selectedTeacher:generateQuesObj.teacherName}? </sapn>
            </div>
          </div>
        }
        <div className='r-c-c m-t-50'>
          <Button onClick={() => changeTestStatusModal(modalActiveName)} className='blankGreyBtnWrap radius-100'>CANCEL</Button>
          <Button
            onClick={() => changeTestStatus(history, window.location.pathname==="/practiseTest-questionScreen")}
            loading={changeTestStatusBool}
            className='purpleBtnWrap radius-100 m-l-20'>YES</Button>
        </div>
      </Modal>
  );
}


const mapStateToProps = state => {
  const {changeTestStatusModalBool, modalActiveName, changeTestStatusBool, generateQuesObj,
          selectedTest} = state.practiseTest;
  return {changeTestStatusModalBool, modalActiveName, changeTestStatusBool, generateQuesObj,
        selectedTest}
};

const mapDispatchToProps = dispatch => ({
  changeTestStatusModal : (name) => dispatch(changeTestStatusModal(name)),
  changeTestStatus : (history, bool) => dispatch(changeTestStatus(history, bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestPublishModal);
