import {
    deleteCategories,
    deleteCourses,
    deleteStudyMaterials,
    deleteSubCategories,
    getCourseStats,
    deleteVideoResource,
    deleteOnlineTestSeries
} from "actions/courseManagement";
import { Checkbox, Modal } from "antd";
import { Button } from "components/Button";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function DeleteModal({
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    selectedCategoryCards,
    setSelectedCategoryCards,
    courseCategoryData,
    selectedSubCategoryCards,
    setSelectedSubCategoryCards,
    courseSubCategoriesData,
    isSubCategoryVisible,
    selectedPublishCards,
    setSelectedPublishCards,
    selectedUnpublishCards,
    setSelectedUnpublishCards,
    isCoursesCardsVisible,
    UnpublishCourseData,
    publishCourseData,
    deleteCourses,
    togglePageLoader,
    getTotalCoursesList,
    deleteSubCategories,
    getCoursesSubCategories,
    selectedCategory,
    deleteCategories,
    getCoursesCategories,
    fromCourseDetailsPage,
    specificCourseDataContainer,
    courseTypeName,
    deleteStudyMaterials,
    getCourseStats,
    getStudyMaterialsList,
    deleteVideoResource,
    deleteOnlineTestSeries,
    getTotalsVideoResource,
    isSearchedSuggestionClicked,
    searchSelectedCourseType,
    getSearchResultsData,
    fromCourseCard,
    fromSearchResult,
    searchedValue,
    fromDashboardSearch,
    getOnlineTestSeries,
    fromMarketPlaceMenu,
}) {
    const handleCancel = () => {
        setIsDeleteModalVisible(false);
        if (!fromCourseDetailsPage) {
            setSelectedCategoryCards([]);
            setSelectedSubCategoryCards([]);
            setSelectedPublishCards([]);
            setSelectedUnpublishCards([]);
        } else if(fromMarketPlaceMenu){
            setSelectedPublishCards([]);
            setSelectedUnpublishCards([]);
        }
    };
    const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const history = useHistory();

   
    let selectedCategoriesName = courseCategoryData
        ?.filter((item) => selectedCategoryCards?.includes(item?.id))
        ?.map((item) => item?.name)
        ?.join(", ")
        ?.replace(/, ([^,]*)$/, " and $1");
    let selectedSubCategoriesName = courseSubCategoriesData
        ?.filter((item) => selectedSubCategoryCards?.includes(item?.id))
        ?.map((item) => item?.name)
        ?.join(", ")
        ?.replace(/, ([^,]*)$/, " and $1");
    let selectedUnpublishCoursesName = UnpublishCourseData?.filter((item) =>
        selectedUnpublishCards?.includes(item?.id)
    )
        ?.map((item) => item?.name)
        ?.join(", ")
        ?.replace(/, ([^,]*)$/, " and $1");
    let selectedPublishCoursesName = publishCourseData
        ?.filter((item) => selectedPublishCards?.includes(item?.id))
        ?.map((item) => item?.name)
        ?.join(", ")
        ?.replace(/, ([^,]*)$/, " and $1");
    let searchSelectedCoursesName = UnpublishCourseData?.filter(
        (item) =>
            selectedUnpublishCards?.includes(item?.id) ||
            selectedPublishCards?.includes(item?.id)
    )
        ?.map((item) => item?.name)
        ?.join(", ")
        ?.replace(/, ([^,]*)$/, " and $1");
    console.log(
        "selectedUnpublishCards",
        selectedUnpublishCards,
        selectedPublishCards,
        "fromCourseDetailsPage",
        fromCourseDetailsPage,
        "isSearchedSuggestionClicked",
        isSearchedSuggestionClicked
    );
    console.log(
        "isCoursesCardsVisible",
        isCoursesCardsVisible,
        "fromCourseDetailsPage",
        fromCourseDetailsPage,
        "isSearchedSuggestionClicked",
        isSearchedSuggestionClicked
    );
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isDeleteModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}
        >
            <center style={{ color: "#FF414D", fontSize: 18, fontWeight: 600 }}>
                You are about to permanently delete{" "}
                {(isCoursesCardsVisible ||
                    fromCourseDetailsPage ||
                    isSearchedSuggestionClicked)
                    ? courseTypeName == "onlineTestSeries"
                        ? "Online Test Series"
                        : "course(s)"
                    : isSubCategoryVisible
                        ? "subcategories"
                        : "categories"}
            </center>
            <center
                style={{
                    color: "#636363",
                    marginTop: 10,
                    fontSize: 15,
                    padding: "0px 20px",
                }}
            >
                Are you sure you want to delete{" "}
                <span style={{ fontWeight: "bold" }}>
                    {isSearchedSuggestionClicked
                        ? searchSelectedCoursesName
                        : fromCourseDetailsPage
                            ? specificCourseDataContainer?.course?.courseName
                            : selectedPublishCoursesName
                                ? selectedPublishCoursesName
                                : selectedUnpublishCoursesName
                                    ? selectedUnpublishCoursesName
                                    : isSubCategoryVisible
                                        ? selectedSubCategoriesName
                                        : selectedCategoriesName}
                </span>{" "}
                from{" "}
                {isSearchedSuggestionClicked &&
                    searchSelectedCourseType == "searchCourse"
                    ? "Live Courses"
                    : isSearchedSuggestionClicked &&
                        searchSelectedCourseType == "searchVideo"
                        ? "Video Resources"
                        : isSearchedSuggestionClicked &&
                            searchSelectedCourseType == "searchStudyMaterial"
                            ? "study materials"
                            : courseTypeName == "liveCourses"
                                ? "Live Courses"
                                : courseTypeName == "videoResources"
                                    ? "Video Resources"
                                    : courseTypeName == "onlineTestSeries"
                                        ? "Online Test Series"
                                        : courseTypeName == "studyMaterials"
                                            ? "Study Materials"
                                            : "Live Courses"}
                ?
            </center>
            <div
                style={{
                    color: "#636363",
                    marginTop: 30,
                    fontSize: 15,
                    padding: "0px 40px",
                    display: "flex",
                }}
            >
                <Checkbox
                    style={{ marginRight: 10 }}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                    }}
                />
                <div>
                    I understand that deleting{" "}
                    {(isCoursesCardsVisible ||
                        fromCourseDetailsPage ||
                        isSearchedSuggestionClicked)
                        ? courseTypeName == "onlineTestSeries"
                            ? "online test series"
                            : "course(s)"
                        : isSubCategoryVisible
                            ? "subcategories"
                            : "categories"}{" "}
                    will remove it permanently from the system.
                </div>
            </div>
            <center>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "75%",
                        marginTop: 40,
                    }}
                >
                    <Button
                        disabled={!isChecked}
                        loading={deleteBtnLoader}
                        style={{
                            color: "#FF414D",
                            fontWeight: "bold",
                            fontFamily: "roboto",
                            border: "none",
                            fontSize: 15,
                        }}
                        onClick={() => {
                            setDeleteBtnLoader(true);
                            if (isSubCategoryVisible) {
                                deleteSubCategories(
                                    selectedSubCategoryCards,
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getCoursesSubCategories(selectedCategory?.id, () => {
                                            togglePageLoader(false);
                                        });
                                    }
                                );
                            } else if (
                                isSearchedSuggestionClicked &&
                                searchSelectedCourseType == "searchCourse"
                            ) {
                                
                                deleteCourses(
                                    [
                                        ...(selectedUnpublishCards?.length
                                            ? selectedUnpublishCards
                                            : []),
                                        ...(selectedPublishCards?.length
                                            ? selectedPublishCards
                                            : []),
                                    ],
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => {
                                            togglePageLoader(false);
                                        });
                                    }
                                );
                            } else if (
                                isSearchedSuggestionClicked &&
                                searchSelectedCourseType == "searchStudyMaterial"
                            ) {
                                deleteStudyMaterials(
                                    [
                                        ...(selectedUnpublishCards?.length
                                            ? selectedUnpublishCards
                                            : []),
                                        ...(selectedPublishCards?.length
                                            ? selectedPublishCards
                                            : []),
                                    ],
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => {
                                            togglePageLoader(false);
                                        });
                                    }
                                );
                            } else if (
                                isSearchedSuggestionClicked &&
                                searchSelectedCourseType == "searchTests"
                            ) {
                                deleteOnlineTestSeries(
                                    [
                                        ...(selectedUnpublishCards?.length
                                            ? selectedUnpublishCards
                                            : []),
                                        ...(selectedPublishCards?.length
                                            ? selectedPublishCards
                                            : []),
                                    ],
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => {
                                            togglePageLoader(false);
                                        });
                                    }
                                );
                            } else if (
                                isSearchedSuggestionClicked &&
                                searchSelectedCourseType == "searchVideo"
                            ) {
                                deleteVideoResource(
                                    [
                                        ...(selectedUnpublishCards?.length
                                            ? selectedUnpublishCards
                                            : []),
                                        ...(selectedPublishCards?.length
                                            ? selectedPublishCards
                                            : []),
                                    ],
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getSearchResultsData(() => {
                                            togglePageLoader(false);
                                        });
                                    }
                                );
                            } else if (
                                (isCoursesCardsVisible || fromCourseDetailsPage) &&
                                courseTypeName == "liveCourses"
                            ) {
                                console.log('heeree',selectedUnpublishCards, selectedPublishCards, specificCourseDataContainer?.id);

                                deleteCourses(
                                    fromCourseDetailsPage
                                        ? [courseTypeName == 'liveCourses' ? specificCourseDataContainer?.course?.id : courseTypeName == 'videoResources' ? specificCourseDataContainer?.id : courseTypeName == 'onlineTestSeries' ? specificCourseDataContainer?.testSeriesDetails?.id : specificCourseDataContainer?.id ]
                                        : selectedPublishCoursesName
                                            ? selectedPublishCards
                                            : selectedUnpublishCards,
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        if (fromCourseDetailsPage || fromMarketPlaceMenu) {
                                            history.push({
                                                pathname: fromMarketPlaceMenu ? "/market-place" : "/live-courses-data",
                                                state: {
                                                    key: {
                                                        fromCourseCard: fromCourseCard,
                                                        fromSearchResult: fromSearchResult,
                                                        searchedValue: searchedValue,
                                                        fromDashboardSearch: fromDashboardSearch,
                                                        fromMarketPlaceMenu: fromMarketPlaceMenu,
                                                    },
                                                },
                                            });
                                            getCourseStats();
                                        } else {
                                            togglePageLoader(true);
                                            getTotalCoursesList(() => {
                                                togglePageLoader(false);
                                            });
                                        }
                                    }
                                );
                            } else if (
                                (isCoursesCardsVisible || fromCourseDetailsPage) &&
                                courseTypeName == "studyMaterials"
                            ) {
                                deleteStudyMaterials(
                                    fromCourseDetailsPage
                                        ? [specificCourseDataContainer?.id]
                                        : selectedPublishCoursesName
                                            ? selectedPublishCards
                                            : selectedUnpublishCards,
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        if (fromCourseDetailsPage || fromMarketPlaceMenu) {
                                            history.push({
                                                pathname: fromMarketPlaceMenu ? "/market-place" : "/study-materials-data",
                                                state: {
                                                    key: {
                                                        fromCourseCard: fromCourseCard,
                                                        fromSearchResult: fromSearchResult,
                                                        searchedValue: searchedValue,
                                                        fromDashboardSearch: fromDashboardSearch,
                                                        fromMarketPlaceMenu: fromMarketPlaceMenu,
                                                    },
                                                },
                                            });
                                            getCourseStats();
                                        } else {
                                            togglePageLoader(true);
                                            getStudyMaterialsList(() => {
                                                togglePageLoader(false);
                                            });
                                        }
                                    }
                                );
                            } else if (
                                (isCoursesCardsVisible || fromCourseDetailsPage) &&
                                courseTypeName == "videoResources"
                            ) {
                                deleteVideoResource(
                                    fromCourseDetailsPage
                                        ? [specificCourseDataContainer?.id]
                                        : selectedPublishCoursesName
                                            ? selectedPublishCards
                                            : selectedUnpublishCards,
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        if (fromCourseDetailsPage || fromMarketPlaceMenu) {
                                            history.push({
                                                pathname: fromMarketPlaceMenu ? "/market-place" : "/video-resources-data",
                                                state: {
                                                    key: {
                                                        fromCourseCard: fromCourseCard,
                                                        fromSearchResult: fromSearchResult,
                                                        searchedValue: searchedValue,
                                                        fromDashboardSearch: fromDashboardSearch,
                                                        fromMarketPlaceMenu: fromMarketPlaceMenu,
                                                    },
                                                },
                                            });
                                            getCourseStats();
                                        } else {
                                            togglePageLoader(true);
                                            getTotalsVideoResource(() => {
                                                togglePageLoader(false);
                                            });
                                        }
                                    }
                                );
                            } else if (
                                (isCoursesCardsVisible || fromCourseDetailsPage) &&
                                courseTypeName == "onlineTestSeries"
                            ) {
                                deleteOnlineTestSeries(
                                    fromCourseDetailsPage
                                        ? [specificCourseDataContainer?.testSeriesDetails?.id]
                                        : selectedPublishCoursesName
                                            ? selectedPublishCards
                                            : selectedUnpublishCards,
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        if (fromCourseDetailsPage || fromMarketPlaceMenu) {
                                            history.push({
                                                pathname: fromMarketPlaceMenu ? "/market-place" : "/online-test-series-details",
                                                state: {
                                                    key: {
                                                        fromCourseCard: fromCourseCard,
                                                        fromSearchResult: fromSearchResult,
                                                        searchedValue: searchedValue,
                                                        fromDashboardSearch: fromDashboardSearch,
                                                        fromMarketPlaceMenu: fromMarketPlaceMenu
                                                    },
                                                },
                                            });
                                            getCourseStats();
                                        } else {
                                            togglePageLoader(true);
                                            getOnlineTestSeries(() => togglePageLoader(false))
                                        }
                                    }
                                );
                            } else {
                                deleteCategories(
                                    selectedCategoryCards,
                                    () => {
                                        setDeleteBtnLoader(false);
                                    },
                                    () => {
                                        handleCancel();
                                        togglePageLoader(true);
                                        getCoursesCategories(() => {
                                            togglePageLoader(false);
                                        });
                                    }
                                );
                            }
                        }}
                    >
                        DELETE
                    </Button>
                    <Button type="primary" onClick={handleCancel}>
                        CANCEL
                    </Button>
                </div>
            </center>
        </Modal>
    );
}
const mapStateToProps = (state) => {
    const { } = state.courseManagement;
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    deleteCourses: (ids, callBack, successCallBack) =>
        dispatch(deleteCourses(ids, callBack, successCallBack)),
    deleteSubCategories: (ids, callBack, successCallBack) =>
        dispatch(deleteSubCategories(ids, callBack, successCallBack)),
    deleteCategories: (ids, callBack, successCallBack) =>
        dispatch(deleteCategories(ids, callBack, successCallBack)),
    deleteStudyMaterials: (ids, callBack, successCallBack) =>
        dispatch(deleteStudyMaterials(ids, callBack, successCallBack)),
    deleteVideoResource: (ids, callBack, successCallBack) =>
        dispatch(deleteVideoResource(ids, callBack, successCallBack)),
    deleteOnlineTestSeries: (ids, callBack, successCallBack) =>
        dispatch(deleteOnlineTestSeries(ids, callBack, successCallBack)),

    getCourseStats: () => dispatch(getCourseStats()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
