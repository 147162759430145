import React from "react";
import { SmallHeading, SubHeading } from "components/Typography";
import { Table as AntTable, Tag } from "antd";
import { connect } from "react-redux";
import { toggleUserDetailModal, getUserTransactionDetails } from "actions/fees";

const Table = ({
  dataSource,
  pagination,
  toggleUserDetailModal,
  getUserTransactionDetails,
  tableName,
}) => {
  let filters = null;
  if (tableName == "All transactions" || tableName == "Links generated") {
    filters = [
      {
        text: "Pending",
        value: "Link Sent",
      },
      {
        text: "Overdue",
        value: "Overdue",
      },
      {
        text: "Refunded",
        value: "Refunded",
      },
      {
        text: "Settled",
        value: "Settled",
      },
      {
        text: "Paid",
        value: "Paid",
      },
      {
        text: "Cancelled",
        value: "Cancelled",
      },
    ];
  }

  const columns = [
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"STUDENT'S DETAILS"}
        </SmallHeading>
      ),
      dataIndex: "user",
      key: "user",
      // eslint-disable-next-line react/display-name
      render: (data) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            getUserTransactionDetails(data);
            toggleUserDetailModal(true);
          }}
        >
          <SubHeading bold>{data?.name}</SubHeading>
          <SmallHeading color="#8C8C8C">
            {data?.student?.standard?.std} - {data?.student?.standard?.section}
          </SmallHeading>
        </div>
      ),
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"DETAILS"}
        </SmallHeading>
      ),
      dataIndex: "note",
      key: "note",
      // eslint-disable-next-line react/display-name
      render: (data, record) => {
        return (
          <>
            <SmallHeading>{data}</SmallHeading>
            <SmallHeading>{`₹ ${record?.amount?.toLocaleString(
              "en-IN"
            )}`}</SmallHeading>
          </>
        );
      },
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"REQUEST GENERATED ON"}
        </SmallHeading>
      ),
      dataIndex: "date",
      key: "date",
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"DUE DATE"}
        </SmallHeading>
      ),
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: (
        <SmallHeading bold color="#8C8C8C">
          {"STATUS"}
        </SmallHeading>
      ),
      key: "status",
      dataIndex: "status",
      filters,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      // eslint-disable-next-line react/display-name
      render: (status) => {
        if (status == "Link Sent") {
          status = "Pending";
        }
        let color = "gold";
        if (status == "Paid") {
          color = "green";
        } else if (status == "Refunded") {
          color = "purple";
        } else if (status == "Settled") {
          color = "blue";
        } else if (status == "Overdue") {
          color = "red";
        } else if (status == "Cancelled") {
          color = "default";
        }
        return (
          <>
            <Tag color={color} key={status}>
              {status?.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
  ];
  return (
    <AntTable
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
    />
  );
};

const mapStateToProps = (state) => {
  const { tableName } = state.fees;
  return {
    tableName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserTransactionDetails: (user) =>
    dispatch(getUserTransactionDetails(user)),
  toggleUserDetailModal: (value) => dispatch(toggleUserDetailModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
