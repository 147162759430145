import PageHeader from 'components/pageHeader';
import React from 'react';
import { useHistory } from 'react-router';

const PrivacyPolicy = () => {
    const history = useHistory();
    return (
        <div style={{height: "100%"}}>
            <PageHeader title="Privacy Policy" onBackPress={() => history.goBack()}/>
            <div className='p-30 text-justify'>
                <div className='m-t-40'  style={{color: "#636363"}}>
                    This Privacy Policy shall, at all times be read and construed in consonance and along with the User Terms and Conditions (“the UT&C”).
                </div>
                <div  style={{color: "#636363"}}>
                    During the course of the User’s usage of the Platform, VEL may collect the following personal and non-personal information. The information may be collected from the Users as part of the voluntary registration process as well as during any on-line survey or interaction on the Platform, as may be required from time to time. The Platform shall / may collect the following Information from the Users:
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    I. Personal Information
                </div>
                <div className='m-t-20'  style={{color: "#636363"}}>
                    <ol>
                        <li type="i">Name;</li>
                        <li type="i">Phone number (mobile and/or residence and/or alternative);</li>
                        <li type="i">Gender;</li>
                        <li type="i">Date of birth;</li>
                        <li type="i">Address (official/residence/other);</li>
                        <li type="i">Email ID (primary/alternative);</li>
                        <li type="i">Identifiers like PAN/AADHAR/Voter ID/Institution ID;</li>
                        <li type="i">Educational background;</li>
                        <li type="i">Photograph;</li>
                        <li type="i">Internet protocol (IP) address; and</li>
                        <li type="i">Sensitive personal data such as passwords.</li>
                    </ol>
                </div>
                <div className='m-t-20' style={{fontSize: 16, fontWeight: 500}}>
                    II. Non Personal Information
                </div>
                <div className='m-t-20' style={{color: "#636363"}}>
                    <ol>
                        <li type="i">Details of internet or telecom service provider;</li>
                        <li type="i">Location;</li>
                        <li type="i">Type of internet browser being used; and</li>
                        <li type="i">Such other information that may be required to access and operate the Platform.</li>
                    </ol>
                </div>
                <div className='m-t-20' style={{color: "#636363"}}>
                    In addition to the above, the duration of use of the Platform by the User may also be logged and stored by the Platform.
                </div>
                <div className='m-t-20' style={{color: "#636363"}}>
                    Except for the password needed by the User to access the Platform, VEL does not collect any “Sensitive Personal Data or Information “, as defined under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (hereinafter referred to as “IT Rules, 2011”).
                </div>
                <div className='m-t-20' style={{color: "#636363"}}>
                    VEL follows a strict policy that:
                </div>
                <div className='m-t-20' style={{color: "#636363"}}>
                    <ol>
                        <li type="i">It shall not collect the User’s personal data unless such personal data is collected for a lawful purpose for which such collection of data is necessary;</li>
                        <li type="i">No information except the type mentioned in the Privacy Policy or the UT&C shall be collected;</li>
                        <li type="i">It shall not retain any personal data longer than such personal data is required or can be lawfully used;</li>
                        <li type="i">It has in place the security practices and procedures prescribed under the IT Rules, 2011;</li>
                        <li type="i">It complies with the IT rules, 2011 in respect of the collected Sensitive Personal Data or Information, especially Rule 6, Rule 7 and Rule 8.</li>
                    </ol>
                </div>
                
            </div>
        </div>
    );
}

export default PrivacyPolicy;
