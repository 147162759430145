import { postAddClass, postAddClassWithImport } from 'actions/classRoom';
import { Checkbox, Input, Modal, Select } from 'antd'
import AnimatedDatePicker from 'components/AnimatedDatePicker';
import AnimatedTextField from 'components/AnimatedTextField';
import { Button } from 'components/Button';
import { ModalSubTitle } from 'components/Typography';
import React, { useState } from 'react'
import { connect } from 'react-redux';
const { Option } = Select

function CreateClassModal({ createClassModalVisible, setCreateClassModalVisible, courses, postAddClass, setShowClassCreatedModal, postAddClassWithImport, fromCourseDetails, newCourseId, getAllClasses }) {
  const [addClassLoader, setAddClassLoader] = useState(false);
  const [className, setClassName] = useState(null);
  const [section, setSetion] = useState(null);
  const [courseName, setCourseName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isImportChecked, setIsImportChecked] = useState(false)

  const handleCancel = () => {
    setCreateClassModalVisible(false);
  };

  const convertDateToISO = (dateString) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split('/');

    // Return the ISO format as yyyy-mm-dd
    return `${year}-${month}-${day}`;
};
  const handleCreateClass = () => {
    let courseId = courses?.response?.items.filter((item) => {
      return item.courseName == courseName;
    })[0]?.id
    const params = {
      courseId: fromCourseDetails ? newCourseId : courseId ? courseId : null,
      courseName: fromCourseDetails ? null : courseName ? courseName : null,
      startDate:selectedDate ? convertDateToISO(selectedDate) : null,
      section,
      std: className,
      subjects: []
    }
    setAddClassLoader(true);
    postAddClass(
      params,
      () => { setAddClassLoader(false) },
      (response) => {
        if (isImportChecked) {
          const linkArray = [{
            standardId: response?.id,
            courseId: response?.courseId
          }]
          postAddClassWithImport(linkArray)
        }
        setCreateClassModalVisible(false);
        if (fromCourseDetails) {
          getAllClasses(newCourseId)
        } else {
          setShowClassCreatedModal(true);
        }
      })
  }

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
};

  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={createClassModalVisible}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}>
        <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Create a new batch"}</div>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          <AnimatedTextField
            label={"Batch name"}
            isMandatory={true}
            inputId={"-1000"}
            value={className}
            handleInputChange={(e) => setClassName(e.target.value)}
          />
          <div style={{ margin: "30px 0px 10px 0px" }}>
            <AnimatedDatePicker
              inputReadOnly={true}
              label={"Start date"}
              // isMandatory={true}
              // inputId={"-999"}
              value={selectedDate}
              handleDateChange={handleDateChange}
              allowClear={true}

            />
          </div>
          <div style={{ margin: "30px 0px 10px 0px" }}>
            <AnimatedTextField
              label={"Section"}
              isMandatory={true}
              inputId={"-999"}
              value={section}
              handleInputChange={(e) => {
                if (e.target.value.length > 3) {
                  return e.preventDefault();
                }
                setSetion(e.target.value)
              }}
            />
          </div>
          {fromCourseDetails ? <></> :
            <>
              <div style={{ display: 'flex', marginTop:30 }}><ModalSubTitle>Course</ModalSubTitle><span style={{ color: 'red' }}>&nbsp;*</span></div>
              <Select
                placeholder="Select a course"
                style={{ width: "100%", marginBottom: 10 }}
                value={courseName}
                onChange={(value) => setCourseName(value.trim())}>
                {courses?.response?.items?.map((item) => (
                  <Option value={item?.courseName}>{item?.courseName}</Option>
                ))}
              </Select>

            </>}
          <Checkbox style={{ color: "#191919", fontSize: 16 }} onChange={(e) => setIsImportChecked(e.target.checked)} disabled={fromCourseDetails ? false : !courseName} >Import predefined curriculum from {fromCourseDetails ? "" : "selected"} course</Checkbox>
          <div className='r-c-c m-t-30'>
            <Button
              loading={addClassLoader}
              disabled={!className || !section || (!fromCourseDetails && !courseName)}
              type="primary"
              onClick={handleCreateClass}
            >CREATE</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => {
  const { } = state.classRoom;
  const { courses } = state.course;
  const { newCourseId } = state.courseManagement;
  return {
    courses, newCourseId
  };
};

const mapDispatchToProps = dispatch => ({
  postAddClass: (params, callBack, successCallBack) => dispatch(postAddClass(params, callBack, successCallBack)),
  postAddClassWithImport: (linkArray) => dispatch(postAddClassWithImport(linkArray))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClassModal)

// export default CreateClassModal