import { Api, Utils } from '../services';
import { sessionService } from 'redux-react-session';
import { showMenu } from './layout';

export const TOGGLE_LOGIN_LOADER = 'TOGGLE_LOGIN_LOADER';
export const IS_RESET_DISABLED = 'IS_RESET_DISABLED';
export const TOGGLE_RESET_LOADER = 'TOGGLE_RESET_LOADER';
export const UPDATE_NOTIFICATION_BADGELIST = 'UPDATE_NOTIFICATION_BADGELIST';
export const MARK_AS_READ = 'MARK_AS_READ';
export const CHANGE_STATUS_IN_TRAY_IN_TRAY = 'CHANGE_STATUS_IN_TRAY_IN_TRAY';
export const LOGOUT = 'LOGOUT';
export const SEND_OTP_LOADER_TOGGLE = 'SEND_OTP_LOADER_TOGGLE';
export const OTP_PAGE_SHOW = 'OTP_PAGE_SHOW';
export const HANDLE_RESEND_OTP_TIMER = 'HANDLE_RESEND_OTP_TIMER';
export const VERIFY_OTP_LOADER_TOGGLE = 'VERIFY_OTP_LOADER_TOGGLE';
export const SET_PALLETE = 'SET_PALLETE';
export const SET_SUPER_PALLETE = 'SET_SUPER_PALLETE';
export const SET_EMAIL = 'SET_EMAIL';
export const UPDATE_ORG_DATA = 'UPDATE_ORG_DATA';
export const UPDATE_ORGS_STANDARD_LIST = 'UPDATE_ORGS_STANDARD_LIST';
export const REJECT_LOADER = 'REJECT_LOADER';  
export const APPROVE_LOADER = 'APPROVE_LOADER';
export const SET_VERIFY_OTP_MODAL = 'SET_VERIFY_OTP_MODAL';
export const UPDATE_REGISTER_DETAILS = 'UPDATE_REGISTER_DETAILS';
export const CLEAR_REGISTER_DETAILS = 'CLEAR_REGISTER_DETAILS';
export const VERIFIED_OTP_FOR_REGISTRATION = 'VERIFIED_OTP_FOR_REGISTRATION';
import loginSound from '../Assets/loginNew/success.mp3';

export const notificationBadgeList = () => {
  return (dispatch) => {
   const params = {
      
    }
    const url = `/notification/unread-notification-counts`
    return Api.get(url).params(params).send((response, error) => {
      dispatch(updateNotificationBadgeList(response));
    });
  }
};
export const updateNotificationBadgeList = (res) => ({
  type: UPDATE_NOTIFICATION_BADGELIST,
  res,
});


export const logIn = (params, history) => {
  return (dispatch) => {
    dispatch(toggleLoginLoader(true));
    return Api.post('/login/virtual-signin').params(params).send((response, error) => {
      dispatch(toggleLoginLoader(false));
      console.log('login res',response)
      if(typeof response != "undefined") {
        // if(!response.data.user.role.includes("SuperAdmin") && !response.data.user.role.includes("Admin")){
        //   history.push('/app-links');
        // }else{
        const token = response.data.accessToken;
        if(token) {
          dispatch(setEmail(''));
          dispatch(showMenu(true));
          response.data.user.accessToken = token;
          sessionService.saveSession({token}).then(() => {
            sessionService.saveUser(response.data.user).then(() => {

              if(response.data.user.access.includes("schedule-teacher")||response.data.user.access.includes("schedule-student")||response.data.user.access.includes("schedule-admin")||response.data.user.access.includes("schedule-superadmin")||response.data.user.access.includes("schedule-dataoperator")||response.data.user.access.includes("schedule-operation")){

                history.push('/schedule');
              } 
              else if(response.data.user.access.includes("assignment")){

                history.push('/assignment');
              } 
         
            }).catch(err => console.error(err));
          }).catch(err => console.error(err));
          Api.header(response.data.accessToken);
        }
      // }
    };

  });
};
};

export const setPassword = (user) => {
  return (dispatch) => {
    dispatch(toggleLoginLoader(true));
    return Api.post('/login/set-password').params(user).send((response, error) => {
      dispatch(toggleLoginLoader(false));
    })
  }
}

export const logout = (history,reason) => {
 
  return (dispatch,getState) => {
    const { user } = getState().session;
    const {marketSettingData} = getState().studentMarketPlace
    const params = {
      phone:user?.phone,
      reason:reason
    }
    if(!user){
      return
    }
    Api.post('/login/after-logout').params(params).send((response,error)=>{
      console.log("response",response);
    })
    Api._headers.Authorization = "";
    sessionService.deleteSession();
    sessionService.deleteUser();

    const successAudio = new Audio(loginSound);
    successAudio.play();
    if(marketSettingData?.active){
    history?.push("/home")
    }else{
      history?.push("/login")
    }
    dispatch(clearState());
  };
}


export const checkToken = (userId, token, source = null) => {
  return (dispatch) => {
    Api.get('/login/checkToken').params({userId, token, source}).send((response, error) => {
      if(response && response?.show?.type !== 'error') {
        dispatch(isResetDisabled(false));
      }
    });
  }
}

export const updatePassword = (userId, password, history) => {
  return (dispatch) => {
    dispatch(toggleResetLoader(true));
    Api.post('/login/change-password').params({userId, password}).send((response, error) => {
      dispatch(toggleResetLoader(false));
      if(response != undefined) {
        history.push('/login/reset/success');
        dispatch(isResetDisabled(true));
      }

    });
  }
}

export const sendForgotLink = (email, history) => {
  return (dispatch) => {
    dispatch(toggleResetLoader(true));
    Api.post('/login/forgot-password').params({email}).send((response, error) => {
      dispatch(toggleResetLoader(false));
      dispatch(setEmail(''));
      if(response && response.show.type === 'success'){
        dispatch(setPallete('1', 'email'));
        history.push('/login');
      }
    });
  }
}

export const postMobileNumber = (phone, history) => (dispatch, getState) => {
  // const {
  //   timer
  // } = getState().login;
  dispatch(sendOtpLoaderToggle(true));
  Utils.stopDuration();
  return Api.post('/login/generateOtp')
    .params({ phoneNo: phone, isWebLogin: true })
    .send((response, error) => {
      dispatch(sendOtpLoaderToggle(false));
      if (response != undefined) {
        if(response?.show?.type === 'success'){
          dispatch(setPallete('2', 'mobile'));
          dispatch(handleResendOtpTimer(true, 30));
          Utils.startDuation(30, (callback) => {
            if(callback === 0){
              dispatch(handleResendOtpTimer(false, ''));
            }else{
              dispatch(handleResendOtpTimer(true, callback))
            }
          })
        }   
      } else {
        dispatch(setPallete('1', 'mobile'));
      }
  });
};

export const postMobileNumberForRegistration = (phone, history) => (dispatch, getState) => {
  // const {
  //   timer
  // } = getState().login;
  dispatch(sendOtpLoaderToggle(true));
  Utils.stopDuration();
  return Api.post('/user/generateOtp')
    .params({ phoneNo: phone, isWebLogin: true })
    .send((response, error) => {
      dispatch(sendOtpLoaderToggle(false));
      if (response) {
        if(response.show.type === 'success')   dispatch(setPallete('2', 'mobile'));
        dispatch(handleResendOtpTimer(true, 30));
        Utils.startDuation(30, (callback) => {
          console.log('callback', callback);
          if(callback === 0){
            dispatch(handleResendOtpTimer(false, ''));
          }else{
            dispatch(handleResendOtpTimer(true, callback))
          }
        })
      } else {
        console.log(error);
      }
  });
};

export const handleResendOtpTimer = (bool, sec) => ({
  type: HANDLE_RESEND_OTP_TIMER,
  bool,
  sec,
});

export const sendOtpLoaderToggle = (bool) => ({
  type: SEND_OTP_LOADER_TOGGLE,
  bool,
});

export const verifyOtpMode = (bool) => ({
  type: OTP_PAGE_SHOW,
  bool,
});

export const setSuperPallete = (num, tab) => ({
  type: SET_SUPER_PALLETE,
  num,
  tab
});

export const setPallete = (num, tab) => ({
  type: SET_PALLETE,
  num,
  tab
});

export const setEmail = (email) => ({
  type: SET_EMAIL,
  email,
});


export const toggleLoginLoader = value => ({
  type: TOGGLE_LOGIN_LOADER,
  value,
});

export const toggleResetLoader = value => ({
  type: TOGGLE_RESET_LOADER,
  value,
});

export const isResetDisabled = value => ({
  type: IS_RESET_DISABLED,
  value,
});

export const markAsRead = (id, index) => {
  return (dispatch) => {
    const url = `/notification/${id}`
    dispatch(changeStatus(index, "Read"));
    return Api.patch(url).send((response, error) => {
    });
  }
};

export const changeStatus = (index, status) =>  ({
  type: CHANGE_STATUS_IN_TRAY_IN_TRAY,
  index,
  status
})

export const rejectRequest = (input, page = 1, index = null) => {
  return (dispatch) => {
    dispatch(rejectLoader(true));
    const url = `/notification/request/${input}`;
    const params = { "status": "Rejected" }
    return Api.patch(url).params(params).send((response, error) => {
        dispatch(rejectLoader(false));
        dispatch(changeStatus(index, "Rejected"))
    });
  }
};
export const approveRequest = (input, page = 1, index = null) => {
  return (dispatch) => {
    dispatch(approveLoader(true));
    const url = `/notification/request/${input}`;
    const params = { "status": "Approved" }
    return Api.patch(url).params(params).send((response, error) => {
        dispatch(approveLoader(false));
        dispatch(changeStatus(index, "Approved"))
    });
  }
};

export const rejectLoader = (bool) => ({
  type: REJECT_LOADER,
  bool,
});


export const approveLoader = (bool) => ({
  type: APPROVE_LOADER,
  bool,
});

export const clearState = () => ({
  type: LOGOUT,
})

export const postVerifyOtp = (phone, otp, history) => (dispatch, getState) => {
  dispatch(verifyOtpLoaderToggle(true));
    return Api.post('/login/verifyOtp')
      .params({ phoneNo: phone, otp: otp, isWebLogin: true })
      .send((response, error) => {
        dispatch(verifyOtpLoaderToggle(false));
        if (typeof response !== 'undefined') {
          const token = response.data.accessToken;
          if(token) {
            dispatch(setPallete('1'));
            dispatch(showMenu(true));
            response.data.user.accessToken = token;
            sessionService.saveSession({token}).then(() => {
            sessionService.saveUser(response.data.user).then(() => {
                // history.push('/today');
                if(response.data.user.access.includes("schedule-teacher")||response.data.user.access.includes("schedule-student")||response.data.user.access.includes("schedule-admin")||response.data.user.access.includes("schedule-superadmin")||response.data.user.access.includes("schedule-dataoperator")||response.data.user.access.includes("schedule-operation")){

                  history.push('/schedule');
                } 
                else if(response.data.user.access.includes("assignment")){
  
                  history.push('/assignment');
                } 
                
                // if(response.data.user.access.includes("calendar")) history.push('/schedule');
                // else if(response.data.user.access.includes("assignment")) history.push('/assignment');
                // else if(response.data.user.access.includes("calendar")) history.push('/calendar');
                // else if(response.data.user.access.includes("calendar-student")) history.push('/schedule');

            }).catch(err => console.error(err));
          }).catch(err => console.error(err));
          Api.header(response.data.accessToken);
        } else {
          console.log(error);
        }
      }
    })
};

export const verifiedOtpForRegistrationAction = (bool) => ({
  type: VERIFIED_OTP_FOR_REGISTRATION,
  bool,
});

export const postVerifyOtpForRegistration = (phone, otp) => (dispatch, getState) => {
  dispatch(verifyOtpLoaderToggle(true));
  return Api.post('/user/verifyOtp')
    .params({phoneNo: phone, otp})
    .send((response, error) => {
      dispatch(verifyOtpLoaderToggle(false));
      if (
        typeof response !== 'undefined' &&
        response?.show?.type == 'success'
      ) {
        dispatch(verifiedOtpForRegistrationAction(true))
        dispatch(setVerfiyOtpModal(false))
      }
      if (typeof response !== 'undefined' && response?.show?.type == 'error') {
        dispatch(verifiedOtpForRegistrationAction(false))
        console.log('Something went wrong while verifying otp')
      }
    });
};

export const getOrgDetails = (callback) => (dispatch, getState) => {
  return Api.get('/login')
    .send((response, error) => {
      if (typeof response !== 'undefined') {
        dispatch(updateOrgDetails(response));
        callback(response);
      } else {
        console.log(error);
      }
    })
};

//Getting all standards of an org.
export const getOrgsStandardList = (callback) => (dispatch, getState) => {
  return Api.get('/user/allCourses')
    .send((response, error) => {
      if (typeof response !== 'undefined') {
        if(response?.response?.items?.length > 0) {
          dispatch(updateRegisterDetails('', 'preferredClass'))
          dispatch(updateRegisterDetails(null, 'preferredCourseId'));
        }
        dispatch(updateOrgsStandardList(response?.response?.items));
        callback(response);
      } else {
        console.log('See error',error);
      }
    })
};


export const updateOrgDetails = (orgData) => ({
  type: UPDATE_ORG_DATA,
  orgData,
});

export const updateOrgsStandardList = (orgStandards) => ({
  type: UPDATE_ORGS_STANDARD_LIST,
  orgStandards,
});


export const verifyOtpLoaderToggle = (bool) => ({
  type: VERIFY_OTP_LOADER_TOGGLE,
  bool,
});

export const setVerfiyOtpModal = (bool) => ({
  type: SET_VERIFY_OTP_MODAL,
  bool,
});

export const updateRegisterDetails = (data, key) => ({
  type: UPDATE_REGISTER_DETAILS,
  data,
  key,
});

export const clearRegisterDetails = () => ({
  type: CLEAR_REGISTER_DETAILS,
});

export const postRegisterDetails = (callback, history) => (
  dispatch,
  getState,
) => {
  dispatch(toggleLoginLoader(true));// using login loader only
  const {userRegister} = getState().login;
  const param = userRegister
  console.log('See param.phone', param.phone)
  let formattedPhoneNumber = param.phone.replace(/-|\s/g,"");
  const params = {
    name: param.name,
    image: param.image,
    phone: formattedPhoneNumber,
    // currentAddress: JSON.stringify({
    //   city: param.address.city,
    //   state: param.address.state,
    //   pincode : param.address.pincode,
    // }),
    role: param.role,
    preferredCourse: param.preferredCourseId,
  };
  if(param.role !== 'Student') {
    delete params.preferredCourse
  }
  if (param.email !== '') {
    params.email = param.email.trim();
  }
  
  return Api.uploadAndPost('/user/register', params, (percent, response) => {
    console.log('See response', response);
    dispatch(toggleLoginLoader(false));
    if (response && response.data.show.type === 'success') {
      dispatch(clearRegisterDetails());
      callback();
      if (
        response.data.show.message ===
        'Details have been updated and sent for approval'
      ) {
        console.log('See Registered Successfully');
        //dispatch(sentStudentRegistrationApproval(true)); //Auto approval disabled
      } else {
        console.log('See Registration sent for approval Successfully');
        //dispatch(showRegisteredSuccessfully(true)); //Auto approval enabled
      }
    } else if (response === undefined || response.data.show.type === 'error') {
      console.log('See something went wrong.', response);
      dispatch(verifiedOtpForRegistrationAction(false));
    }
  });
};

export const updateUserAction = (user) => () => {
  sessionService.saveSession({token: user.accessToken}).then(() => {
    sessionService.saveUser(user).then(() => {

    }).catch(err => console.error(err));
  }).catch(err => console.error(err));
}

