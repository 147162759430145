import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { showTextEditor, updateSubWithChapters, addMarksForQues, chooseCorrectAnswer, storeTestQuesType,
  postQuestionCreate, clearGenerateQuesFields, getEachQuesData, deleteQuesFromServer,
  showGenerateQuesListView,
  unsetCreateTestModalBool} from 'actions/practiseTest';
import Notification from "services/Notification";
import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdFormatListBulleted, MdCloudDownload, MdMoreVert } from "react-icons/md";
import { AiOutlineDownload, AiOutlinePlus, AiOutlineMore, AiFillCaretDown, AiOutlineMinus, AiOutlinePlusCircle,
  AiFillDelete, AiFillCopy} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { ExclamationCircleOutlined, LoadingOutlined, } from '@ant-design/icons';
import './styles.css';
import { Color } from 'services';
import ReactHtmlParser from 'react-html-parser';
import { Col, Row , Typography, Select, Menu, Dropdown} from 'antd';
import TopNavigationColorHeader from './localComponents/topNavigationColorHeader';
import TextEditorModal from './localComponents/textEditorModal';
import TestPublishModal from './localComponents/testPublishModal';
import CreateTestModal from './modals/createTestModal';
import { Tooltip } from 'antd';
import TeX from '@matejmazur/react-katex';


const { TabPane } = Tabs;
const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const TestDetails = ({value, text}) => {
  return(
    <div className='r-c-c-c m-r-50'>
      <div className='text-xmd bold-600'>{value}</div>
      <div className='text-xs'>{text}</div>
    </div>
  )
}

const CustomeDropdown = ({defaultvalue, placeholder, onchange, arrdata, disable=false}) => {
  return(
      <Select
        className='test-custom-select'
        // placeholder={placeholder}
        disabled={disable}
        value={defaultvalue}
        style={{ width: 200 }}
        onChange={onchange}
        suffixIcon={<AiFillCaretDown className='text-sm a2grey'/>}
      >
        {arrdata?.map((item, index) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
  )
}

const Row1 = ({history, generateQuesObj, generateQuesArr, showGenerateQuesListView}) => {
  let queCount = Object.keys(generateQuesArr)?.length
                 ? generateQuesArr.subjects?.map((item) => item.questions?.length).reduce((a,b) => a+b) : 0;
  let totalPoints =  Object.keys(generateQuesArr)?.length ?  generateQuesArr.totalPoints : 0;
  return(
    <div className='r-c-sb p-20'>
      <div className='r-ac'>
        <TestDetails value={queCount} text={`Questions`} />
        <TestDetails value={`${generateQuesObj.durationInMinutes} mins`} text={`Duration`} />
        <TestDetails value={totalPoints} text={`Marks`} />
      </div>
      <div className='r-ac'>
        <div onClick={() => {showGenerateQuesListView(()=>{}, "listview"); history.push('practise-test-create-list-view')}} className='r-c-c-c m-r-20 cursor-pointer'>
          <MdFormatListBulleted className='text-md color-black' />
          <div className='text-xs text-center color-black'>List View</div>
        </div>
        {/* <div  className='r-c-c-c cursor-pointer'>
          <AiOutlineDownload className='text-md a2grey'/>
          <div className='text-xs text-center a2grey'>Import</div>
        </div> */}
      </div>
    </div>
  )
}




const Row2 = ({generateQuesObj, generateQuesSubChapters, getEachQuesData }) => {

  return(
    <div className='r-c-sb p-20'>
      <div className='r-ac'>
        {generateQuesObj.subjectNames?.split(',')?.map((item, index) => (
          <Button  className={item === generateQuesSubChapters.name ? 'purpleBtnWrap m-r-20 text-xs' : 'blankGreyBtnWrap m-r-20 text-xs'}
                    onClick={()=>{
                      if (generateQuesObj && generateQuesObj.subjects?.length>0 && generateQuesObj?.subjects[0]?.questions?.length>0){
                        getEachQuesData(generateQuesObj?.subjects[index]?.questions[0]);
                      }}}>
            {item}
          </Button>
        ))}
      </div>
    </div>
  )
}

const Row3 = ({generateQuesObj, quesPositiveMark, quesNegativeMark,
   generateQuesSelectedTestType, generateQuesSelectedChapter}) => {
  return(
    <div className='p-l-20 p-r-20 r-c-sb'>
    </div>
  )
}

const Row4 = ({generateQuesText, editGenerateQueId, generateQuesArr}) => {
  let QuestionIndex = null;
  if (editGenerateQueId==''||editGenerateQueId==undefined){

  }else{
    generateQuesArr?.subjects?.forEach(subject => {
      subject?.questions.forEach((question)=>{
        if (question.id===editGenerateQueId){
          QuestionIndex = question.index
        }
      })
    });
  }
  return(
    <div className='p-20'>
      <div className='text-xmd bold-600 color-black'>Question {' '} {QuestionIndex}</div>
      <div className='test-ques-window resize-editor-img p-10' style={{width:"100%", overflowX:"hidden"}}>
      {ReactHtmlParser(generateQuesText,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
      </div>
    </div>
  )
}

const Row5 = ({ generateQuesOptions}) => {
  return(
    <div className='p-l-20 p-r-20'>
      <div className='text-xmd bold-600 color-black'>Options</div>
      {generateQuesOptions?.map((item, index) => (
        <div className='r-ac m-b-10'>
          <div
            style={{background:item.correct ? '#28df99' : '', color:item.correct ? '#fff' : '', borderColor: item.correct ? '#28df99' : ''}}
            className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
            {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'}
          </div>
          <div
            style={{background:item.correct ? '#E9FCF5' : ''}}
            className='test-ques-window p-10 r-c-sb flex-1'>
            <div className='resize-editor-img' style={{width:"100%", overflowX:"hidden"}}>
                            {ReactHtmlParser(item.value,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
            </div>
            <div keys={index.toString()} className='r-ac m-l-5'>
              {item.correct
              ?
                <RiCheckboxCircleLine className='text-sm seaGreen' />
              :
                <RiCheckboxBlankCircleLine className='text-sm a2grey' />
              }
              <div className='m-l-5 text-xs a2grey'>Correct answer</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const RowNumeric = ({generateQuesNumericText}) => {
  return(
    <div className='p-20 '>
      <div className='text-xmd bold-600 color-black'>Answer</div>
      <div className='test-ques-window resize-editor-img p-10' 
           style={{width:"100%", overflowX:"hidden"}}>
        {ReactHtmlParser(generateQuesNumericText)}
      </div>
    </div>
  )
}
const Row6 = ({generateSolutionText}) => {
  return(
    <div className='p-20 m-b-50'>
      <div className='text-xmd bold-600 color-black'>Solution</div>
      <div className='test-ques-window resize-editor-img p-10' 
           style={{width:"100%", overflowX:"hidden"}}>
              {ReactHtmlParser(generateSolutionText,
          { transform: (node) => {
          if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
              return <TeX math={`${node.attribs['data-value']}`} />;
          }
          if (node.type === 'tag' && node.name === 'img') {
              return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
          }
          }})
      }
      </div>
    </div>
  )
}

const QueGridSide = ({generateQuesObj, generateQuesArr, updateSubWithChapters, clearGenerateQuesFields,
  generateQuesSubChapters, getEachQuesData, editGenerateQueId, getEachQuesDataBool}) => {
  return(
    <div>
      {/*generateQuesObj.subjectNames.split(',').map((item, index) => (
        <div className='text-xs bold-600 color-black m-b-10'>{item}</div>
      ))*/}
      {generateQuesArr && Object.keys(generateQuesArr)?.length ?
        generateQuesArr.subjects?.map((item) => (
        <div keys={item.subjId}>
          <div className='text-xs bold-600 color-black m-b-10'>{item.subName}</div>
          <div className='r-ac flex-wrap' style={{marginLeft:-10}}>
            {item.questions?.map((quee, index) => (
              <div
                onClick={() => getEachQuesData(quee.id)}
                style={{borderColor:quee.id === editGenerateQueId ? '#636363' : '', borderWidth: quee.id === editGenerateQueId ? 2 : 1}}
                className='r-c-c radius-100 color-black test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
                {getEachQuesDataBool && quee.id === editGenerateQueId ? <LoadingOutlined /> : quee.index}
              </div>
            ))}
            {/*<div
              onClick={() => {
                updateSubWithChapters(item.subName);
                // clearGenerateQuesFields();
              }}
              style={{borderColor:item.subName === generateQuesSubChapters.name ? '#636363' : '', borderWidth: item.subName === generateQuesSubChapters.name ? 2 : 1}}
              className='r-c-c radius-100 a2grey text-sm test-circle-avatar m-b-10 m-l-10 cursor-pointer'>
              <AiOutlinePlus style={{color:item.subName === generateQuesSubChapters.name ? '#191919' : ''}}/>
            </div>*/}
          </div>
        </div>
      ))
      : null
      }

    </div>
  )
}



function PracticeTestQuesNonEditablScreen ({history, generateQuesObj, showTextEditor, generateQuesSubChapters,
  updateSubWithChapters, addMarksForQues,  quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, chooseCorrectAnswer, generateSolutionText, storeTestQuesType,
  generateQuesSelectedTestType, generateQuesSelectedChapter, postQuestionCreate, generateQuesRemovedImgs,
  generateQuesArr, clearGenerateQuesFields, getEachQuesData, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, deleteQuesFromServer, postQuestionCreateBool, showGenerateQuesListView, 
  unsetCreateTestModalBool, textEditorBool}) {

    // useEffect(() => {
    //   return () => {
    //     unsetCreateTestModalBool()
    //   }
    // }, [])
    const [showEditorModal, setShowEditorModal] = useState(false)
    const grabAllfield = () => {
      let obj = generateQuesOptions?.filter((item, index) => item.correct === true);
      let checkTestType = generateQuesObj.testType === 'Subjective';
      let checkQuesType = generateQuesSelectedTestType.children === 'Multiple Choice Questions';

      if(!Object.keys(generateQuesSelectedTestType)?.length && !checkTestType){
        return  Notification.error("Error", 'Please select test type');
      }else if(!Object.keys(generateQuesSelectedChapter)?.length){
        return  Notification.error("Error", 'Please select any chapter');
      }else if(quesPositiveMark === 0 && quesNegativeMark === 0){
        return  Notification.error("Error", 'Please add marks for the question');
      }else if(generateQuesText === ''){
        return  Notification.error("Error", 'Please add question');
      }else if((generateQuesOptions[0].value === '' || generateQuesOptions[1].value === '' || generateQuesOptions[2].value === '' || generateQuesOptions[3].value === '') && (generateQuesSelectedTestType.children === 'Multiple Choice Questions')){
        return  Notification.error("Error", 'Please add options');
      }else if(!obj?.length && (generateQuesSelectedTestType.children === 'Multiple Choice Questions')){
        return  Notification.error("Error", 'Please select any correct option');
      }else if(generateQuesNumericText === '' && (generateQuesSelectedTestType.children === 'Numeric Type Questions')){
        return  Notification.error("Error", 'Please add answer');
      }

      let params = {
        statement: generateQuesText,
        options: checkTestType ? null : checkQuesType ? generateQuesOptions : [],
        answer: checkTestType ? ' ' : checkQuesType ? obj[0].id : generateQuesNumericText,
        type: checkTestType ? 'Subjective' : generateQuesSelectedTestType.value === 0 ? 'MCQ' : 'Numeric',
        positiveMarks: quesPositiveMark,
        negativeMarks: checkTestType ? null : quesNegativeMark,
        deleteImages: generateQuesRemovedImgs,
        testId: generateQuesObj.id,
        chapterId: generateQuesSelectedChapter.value,
        solution: generateSolutionText,
      }
      if(editGenerateQueId !== ''){
        params.id = editGenerateQueId;
      }
      postQuestionCreate(params);
    }
    return (
      <div style={{height: '100%', margin:'-20px'}}>
        <TopNavigationColorHeader  history={history} type="no-edit"/>
        <div className='display-flex' style={{height:'calc(100% - 20px)'}}>
          <div className='flex-1 position-relative'>
            <div className='overflow-scroll' style={{height:'calc(100% - 20px)'}}>
              <Row1
                history={history}
                generateQuesObj={generateQuesObj}
                showGenerateQuesListView={showGenerateQuesListView}
                generateQuesArr={generateQuesArr}/>
              <div className='horizontal-divider'></div>
              <Row2
                generateQuesObj={generateQuesObj}
                generateQuesSubChapters={generateQuesSubChapters}
                getEachQuesData = {getEachQuesData}
              />
              <Row3
                generateQuesObj={generateQuesObj}
                quesPositiveMark={quesPositiveMark}
                quesNegativeMark={quesNegativeMark}
                generateQuesSelectedTestType={generateQuesSelectedTestType}
                generateQuesSelectedChapter={generateQuesSelectedChapter}
              />
              <Row4 generateQuesText={generateQuesText}
                    generateQuesArr={generateQuesArr}
                    editGenerateQueId={editGenerateQueId}/>
              {generateQuesObj.testType === 'Subjective'
              ?
                null
              :
                Object.keys(generateQuesSelectedTestType)?.length
                ?
                  generateQuesSelectedTestType.children === 'Multiple Choice Questions'
                  ?
                    <Row5 generateQuesOptions={generateQuesOptions}/>
                  :
                    <RowNumeric generateQuesNumericText={generateQuesNumericText}/>
                :
                  null
                }

              <Row6 generateSolutionText={generateSolutionText}/>
            </div>
            {/* <div onClick={postQuestionCreateBool ? () => {} : grabAllfield} className='p-20 test-save-sticky-footer cursor-pointer r-c-c'>
              {postQuestionCreateBool ? <LoadingOutlined className='m-r-10' style={{color:'#1089ff'}}/> : null}
              <div className='text-xmd bold-600 blue text-center cursor-pointer'>SAVE & NEXT</div>
            </div> */}
          </div>
          <div className='vertical-divider'></div>
          <div className='p-20 overflow-scroll' style={{width:350}}>
            <div className='text-xs a2grey bold-600 m-b-20'>ALL QUESTIONS</div>
            <QueGridSide
              generateQuesObj={generateQuesObj}
              generateQuesArr={generateQuesArr}
              updateSubWithChapters={updateSubWithChapters}
              clearGenerateQuesFields={clearGenerateQuesFields}
              generateQuesSubChapters={generateQuesSubChapters}
              getEachQuesData={getEachQuesData}
              editGenerateQueId={editGenerateQueId}
              getEachQuesDataBool={getEachQuesDataBool}
               />
          </div>
        </div>
        {
          textEditorBool && (
            <TextEditorModal visible={showEditorModal} closeModal={() => setShowEditorModal(false)}/>
          )
        }
        <TestPublishModal history={history}/>
        <CreateTestModal history={history} />
      </div>
  );
}


const mapStateToProps = state => {
  const {generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
  generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, postQuestionCreateBool, textEditorBool} = state.practiseTest;
  return {generateQuesObj, generateQuesSubChapters, quesPositiveMark, quesNegativeMark, generateQuesText,
  generateQuesOptions, generateSolutionText, generateQuesSelectedTestType, generateQuesSelectedChapter,
  generateQuesRemovedImgs, generateQuesArr, generateQuesNumericText, editGenerateQueId,
  getEachQuesDataBool, postQuestionCreateBool, textEditorBool};
};

const mapDispatchToProps = dispatch => ({
  showTextEditor: (name) => dispatch(showTextEditor(name)),
  updateSubWithChapters: (name) => dispatch(updateSubWithChapters(name)),
  addMarksForQues: (operation, name) => dispatch(addMarksForQues(operation, name)),
  chooseCorrectAnswer: (index) => dispatch(chooseCorrectAnswer(index)),
  storeTestQuesType: (obj, name) => dispatch(storeTestQuesType(obj, name)),
  postQuestionCreate: (params) => dispatch(postQuestionCreate(params)),
  clearGenerateQuesFields: (val) => dispatch(clearGenerateQuesFields(val)),
  getEachQuesData: (id) => dispatch(getEachQuesData(id)),
  deleteQuesFromServer: () => dispatch(deleteQuesFromServer()),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type)),
  unsetCreateTestModalBool: ()=>dispatch(unsetCreateTestModalBool())
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeTestQuesNonEditablScreen);
