import React, { useState } from 'react';

const TouchableOpacity = ({ children, style, ...props }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);

  const combinedStyle = {
    ...style,
    cursor:"pointer",
    opacity: isPressed ? 0.5 : 1,
    transition: 'opacity 0.2s',
  };

  return (
    <div
      style={combinedStyle}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      {...props}
    >
      {children}
    </div>
  );
};

export default TouchableOpacity;
