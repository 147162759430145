class Chat {
    constructor({
      user,
      userId,
      userName,
      orgId,
  
      callback,
    }) {
      this.url = "wss://r40rw4xw55.execute-api.ap-south-1.amazonaws.com/dev";
      this.socket = null;
      this.userId = userId;
      this.orgId = orgId;
      this.user = user;
      this.userName = userName;
      this.callback = callback;
      this.rec = null;
      this.socketDisconenctAcc = true;
    }
  
    joinroom() {
      console.log(
        JSON.stringify({
          action: "joinRoom",
          data: {
            user: this.user,
            userId: this.userId,
            userName: this.userName,
            orgId: this.orgId,
            // platform: this.platform,
            // isTeacher: this.isTeacher,
            // healthCheckId: this.healthCheckId,
          },
        }),
        "json of join room"
      );
      this.socket.send(
        JSON.stringify({
          action: "joinRoom",
          data: {
            user: this.user,
            userId: this.userId,
            userName: this.userName,
            orgId: this.orgId,
            // platform: this.platform,
            // isTeacher: this.isTeacher,
            // healthCheckId: this.healthCheckId,
          },
        })
      );
    }
  
    request(event) {
      // If socket is not connected, return
      if (this.socket.readyState !== 1 && this.socket.readyState !== 0) {
        this.socket.close();
        return;
      }
      if (event.event != "ping") {
        // console.log("event", event);
      }
      try {
        this.socket.send(
          JSON.stringify({
            action: event.event,
            data: event.data,
          })
        );
      } catch (error) {
        // console.log("stringify error", error);
      }
    }
  
    listen() {
      let thiz = this;
      window.addEventListener("offline", (e) => {
        // thiz.rec = true;
        // thiz.timer2 = setTimeout(() => {
        //   console.log("closing connections beacause of offline");
        //   thiz.closeConnection();
        //   thiz.callback({
        //     command: "connectionInterrupted",
        //   });
        // }, 10000);
        // console.log("is_ofline");
      });
      window.addEventListener("online", (e) => {
        if (this.socketDisconenctAcc) {
          this.initsocket();
        }
        // console.log("is_online");
      });
    }
  
    sendmsg(e, type, destinationId) {
      this.socket.send(
        JSON.stringify({
          action: "sendChat",
          data: {
            type,
            data: e,
            userId: this.userId,
            orgId: this.orgId,
            user: this.user,
            destinationId,
          },
        })
      );
    }
  
    sendPing = () => {
      this.request({
        event: "ping",
        data: {},
      });
      // var jsCmd = {
      //   action: "onMessage",
      //   message: {
      //     event: "ping",
      //     data: {},
      //   },
      // };
      // this.socket.send(JSON.stringify(jsCmd));
    };
  
    manualCloseConnection() {
      this.socket.close();
      // console.log("manual close connection called");
    }
  
    close() {
      this.socketDisconenctAcc = false;
      this.socket.close();
    }
  
    initsocket() {
      this.socket = new WebSocket(this.url);
      this.socket.onopen = () => {
        console.log("open");
        let dummy = {
          event: "connected",
        };
        this.callback(dummy);
        console.log("wsopen");
        this.joinroom();
  
        setInterval(() => {
          this.sendPing();
        }, 3000);
      };
      this.socket.onmessage = (event) => {
        var obj = JSON.parse(event.data);
        if (obj.action != "pong");
        // console.log(obj);
        if (obj.message != "Internal server error" && obj.action != "pong")
          if (obj.action == "rcvChat") {
            // console.log(obj);
            // if (obj.action == "pong")
            this.callback(obj);
          }
          console.log('chat obj',obj);
          if (obj.action == "joinRcv") {
            // if (obj.action == "pong")
            console.log('connected')
            this.callback(obj);
          }
        // console.log(event);
      };
      this.socket.onclose = (event) => {
        console.log('closed');
        if (this.socketDisconenctAcc && navigator.onLine) {
          this.initsocket();
        }
      };
    }
  }
  export default Chat;