import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchSampleQuestionPaper, setImportMeta } from 'actions/testAdmin';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router'
import { Button } from 'components/Button'
import PageHeader from 'components/pageHeader'
import PageDataLoader from 'components/pageDataLoader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const NoData = require("../../Assets/ill-no-data.svg").default

const SampleQuestionPaper = ({ importMeta, fetchSampleQuestionPaper, setImportMeta }) => {
    const [pageLoader, togglePageLoader] = useState(false)
    useEffect(() => {
        togglePageLoader(true);
        fetchSampleQuestionPaper(() => { togglePageLoader(false) });
    }, []);
    const history = useHistory();
    const location = useLocation();
    const NoDataCard = () => {
        return (
            <>
                <div className='r-c-c-c' /* style={{marginTop:"200px"}} */>
                    <img src={NoData} />
                    <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</div>
                    <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no data to show yet. Please check back later.</div>
                </div>
            </>
        )
    }
    const actions = [
        <Button type="" onClick={() => { history.goBack() }} style={{ marginRight: 20 }}>CANCEL</Button>
    ]

    const QuestionPaperCard = ({name, id, noOfQuestion}) => {
        return(
            <div style={{
                width: 330,
                backgroundColor: "#FFFFFF",
                border: "1px solid #E6E6E6",
                borderRadius: 10,
                height: 110,
                padding: 10,
                margin: "0px 20px 20px 0px",
            }} onClick={() => {
                if(location?.state?.key){
                history.push({pathname:"/sample-list-view", state:{key:"fromClassroom"}});
                }
                else if(location?.state?.testSeriesData) {
                    history.push({pathname:'/sample-list-view', state: location?.state });
                  }
                else{
                    history.push("/sample-list-view");
                }
                setImportMeta("selectedPaperObj", {name, id})
                }}>
                <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
                    <img src={require("../../Assets/test/icdlquestionbank.svg").default} style={{ width: "55px", height: "55px", marginLeft: 5 }} />
                    <div style={{ display: "flex", flexDirection: "column", margin: "15 0 0 15" }} className="m-l-10">
                        <Typography.Text style={{ font: "Roboto", textAlign: "left", fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }} ellipsis={{ tooltip: true }}>
                            {name}
                        </Typography.Text>
                        <div style={{ textAlign: "left", fontSize: 12, color: "#AEAEAE" }}>
                            {noOfQuestion} Qs
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div style={{ background: "#FAFAFA", width: "calc(100%+50px)", height: "calc(100% + 40px)", margin: -20, padding: 20, overflow: "hidden" }}>
            <div style={{ marginBottom: 90 }}>
                <PageHeader
                    title={importMeta?.selectedStandardObj?.name}
                    subtitle='Sample Question Papers'
                    actions={actions}
                    onBackPress={() => { history.goBack() }} />
            </div>
            <div style={{overflowY: "scroll", height: "90%"}}>
            {importMeta?.sampleQuestionPaperList && importMeta?.sampleQuestionPaperList?.length != 0 ?
                <div className='cursor-pointer r-afs flex-wrap m-t-30'>
                    {importMeta?.sampleQuestionPaperList?.map((item, index) => {
                        return (
                            <>
                            <QuestionPaperCard
                            name={item?.questionPaperName}
                            id={item?.questionPaperId}
                            noOfQuestion={item?.noOfQuestion}/>
                            </>
                        )
                    })}
                </div>
                :
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '100px' }}>
                    <NoDataCard />
                </div>
            }
            </div>

            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
      importMeta
    } = state.testAdmin;
    return {  
      importMeta
    };
  }
  
  const mapDispatchToProps = (dispatch) =>  ({
    fetchSampleQuestionPaper: (callback) => dispatch(fetchSampleQuestionPaper(callback)),
    setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
    // fetchClassByCurrData: (callback) => dispatch(fetchClassByCurrData(callback))
  })
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(SampleQuestionPaper);

// export default SampleQuestionPaper