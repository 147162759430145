import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getAllClasses, storeSectionId, storeSelectedClassDetails, updateSelectedSection } from 'actions/classRoom';
import { Progress, Typography } from 'antd';
import BackToTop from 'components/backToTop';
import { Button } from 'components/Button';
import { InputSearch } from 'components/customInputs';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import { Heading } from 'components/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import CreateClassModal from './modals/createClass';
import { getAllCourses, storeSelectedCourseId } from 'actions/course';
import ClassCreatedModal from './modals/classCreated';
import EditClassModal from './modals/editClass';
import AddNewSectionModal from './modals/addNewSection'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { savePageCoordinates } from 'actions/layout';
import { setSelectedClassroom } from 'actions/testAdmin';
import { updateCurrentStd } from 'actions/digitalLibrary';
import { setFromCourseDetailsPage } from 'actions/courseManagement';
import { storeStandardData } from 'actions/feeManagement';
const overviewImg1 = require('../../Assets/classroom/classroomImg1.svg').default;
const overviewImg2 = require('../../Assets/classroom/classroomImg2.svg').default;
const overviewImg3 = require('../../Assets/classroom/classroomImg3.svg').default;
const overviewImg4 = require('../../Assets/classroom/classroomImg4.svg').default;

export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
  text = 'There is no data to show yet. Please check back later.'
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          {text}
        </p>
      </div>
    </div>
  );
}
export const SingleClass = ({ item, setShowEditClassModal, storeSelectedClassDetails, setIsAddSectionVisible, storeSectionId, storeSelectedCourseId, viewSectionDetails, user, setSelectedClassroom, updateCurrentStd, selectedStd, fromCourseDetails, setFromCourseDetailsPage, hasMarketingPrivilege,storeStandardData }) => {
  const history = useHistory();
  return (
    <div>
      <div className='r-ac m-t-10' style={{ display: "flex", justifyContent: "space-between" }}>
        <Heading><pre>{item[0]}</pre></Heading>
        {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ? <div className= { hasMarketingPrivilege ? '' :'' } onClick={() => { storeSelectedClassDetails(item[1]); setShowEditClassModal(true) }} style={{ color: "#1089FF", cursor:'pointer',fontSize: 14, fontWeight: "bold", marginRight: fromCourseDetails ? 10 : 0 }}><EditOutlined /> EDIT BATCH</div> : <></>}
      </div>
      <div className='r-ac flex-wrap'>
        {item[1]?.map((std, i) => (
          <div
          style={{cursor:hasMarketingPrivilege?'':'pointer',}}
           onClick={() => {
            storeStandardData({standard_id:std?.id, std:std?.std, section:std?.section, courseName:std?.courseName, studentCount:std?.students?.length})
            storeSectionId(std?.id);
            storeSelectedCourseId(std?.courseId);
            viewSectionDetails(std);
            setSelectedClassroom({ name: std, id: std?.id, classname: std?.std, section: std?.section });
            updateCurrentStd({
              std_id: std?.id,
              standard: std?.std,
              std_section: std?.section
            })
            if (fromCourseDetails) {
              setFromCourseDetailsPage(true)
            }
            if(!hasMarketingPrivilege){
              history.push('/classroom/classroom-details');
            }
          }} key={i} className=''>
            <ClassroomCard
              courseName={std?.courseName}
              studentsCount={std?.studentsCount}
              subCount={std?.subCount}
              progress={std?.progress}
              startDate={std?.startDate}
              endDate={std?.endDate}
              sectionName={std?.section}
              storeSelectedClassDetails={storeSelectedClassDetails}
              std={std}
              setShowEditClassModal={setShowEditClassModal}
              user={user}
              fromCourseDetails={fromCourseDetails}
              hasMarketingPrivilege={hasMarketingPrivilege}
            />
          </div>
        ))
        }
        {user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") ?
          <div
          className="r-c-c-c addSectionCardStyles"
          style={{color: "#1089FF", fontSize: 14, fontWeight: "bold", border: "1px dashed #1089FF", flexDirection:"row",height:90}}
          onClick={() => {
              storeSelectedClassDetails(item[1]);
              setIsAddSectionVisible(true);
          }}
      >
          <PlusCircleOutlined style={{ fontSize: 18, marginRight: 10 }} />
          <div>ADD A SECTION</div>
      </div>
       : <></>
        }
      </div>
    </div>
  )
}
function NoClassroomCard({
  containerheight = "100%",
  imgwidth = "30%",
  imgheight = "30%",
  setCreateClassModalVisible
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/classroom/ill-no-class.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No classroom available!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There are no classroom added yet
        </p>
        <Button type="primary" className="m-l-20" onClick={() => { setCreateClassModalVisible(true) }} style={{}} icon={<PlusCircleOutlined />}>CREATE NEW BATCH</Button>
      </div>
    </div>
  );
}
const ClassroomCard = ({ courseName, sectionName, studentsCount, subCount, progress, startDate, endDate, storeSelectedClassDetails, setShowEditClassModal, std, user, fromCourseDetails }) => {
  return (
    <>
      <div className={'newBatchCardStyle'}>
        {fromCourseDetails ?
          <div style={{ color: "#191919", fontWeight: 600, fontSize: 16 }}>{sectionName}</div>
          :
          <div style={{ display: "flex" }}>
            <div className='text-xlg r-c-c bold-600 font-pt-serif text-square-bg__grey' style={{ color: "#9876e6", fontSize: 24 }}>
              {sectionName}
            </div>
            <div style={{ marginLeft: "20px", }}>
              {courseName ? (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '160px', fontWeight: 700, fontSize: 16 }}>
                  {courseName}
                </Typography.Text>
              ) : (
                (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin"))
                  ? (
                    <div
                      className='cursor-pointer'
                      style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        storeSelectedClassDetails([std]);
                        setShowEditClassModal(true);
                      }}
                    >
                      <PlusCircleOutlined className='m-r-5' />
                      ADD A COURSE
                    </div>
                  )
                  : (
                    <></>
                  )
              )
              }

              <div><span style={{ color: "#594099", fontWeight: "bold" }}>{studentsCount} {" "}</span> <span style={{ color: "#636363" }}>students {" "}</span> <span style={{ color: "#594099", fontWeight: "bold" }}>{"•"}</span> <span style={{ color: "#594099", fontWeight: "bold" }}>{subCount} {" "}</span><span style={{ color: "#636363" }}>subjects</span></div>
            </div>
          </div>
        }
        {/* <div style={{ display: "flex", justifyContent: "space-between", color: "#636363", fontSize: "11px", fontWeight: "bold", marginTop: 15 }}>
          <div>Completed</div>
          <div>{Math.round(progress === undefined ? null : progress)}%</div>
        </div>
        <div>
          <Progress
            percent={progress}
            style={{ width: "100%" }}
            showInfo={false}
            strokeColor="#594099"
            trailColor="#F6F4FE"
          /></div>
        {fromCourseDetails ? <></> :
          <div style={{ display: "flex", justifyContent: "space-between", color: "#AEAEAE", fontSize: "11px", fontWeight: "bold" }}>
            <div>{startDate === undefined ? null : moment(startDate).format('DD-MM-YYYY')}</div>
            <div>{endDate === undefined ? null : moment(endDate).format('DD-MM-YYYY')}</div>
          </div>
        } */}
      </div>
    </>
  )
}

function ClassRoom({ getAllClasses, classFetchLoader, classesOverview, classes, getAllCourses, storeSelectedClassDetails, storeSectionId, storeSelectedCourseId, updateSelectedSection, savePageCoordinates, user, setSelectedClassroom, selectedStd, setFromCourseDetailsPage, storeStandardData }) {
  useEffect(() => {
    setFromCourseDetailsPage(false)
    getAllClasses();
    getAllCourses(null, null, true)
    return () => {
      if (!window.location.pathname.includes("/classroom")) {
        savePageCoordinates(0, 0)
      }
    };
  }, [])
  const [search, setSearch] = useState('');
  const [createClassModalVisible, setCreateClassModalVisible] = useState(false);
  const [showClassCreatedModal, setShowClassCreatedModal] = useState(false);
  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [isAddSectionVisible, setIsAddSectionVisible] = useState(false);
  const handleSearch = (e) => {
    setSearch(e);
  }
  const viewSectionDetails = (data) => {
    updateSelectedSection(data)
    savePageCoordinates(document.getElementById('mainBody').scrollLeft, document.getElementById('mainBody').scrollTop)
  }
  const actions = [
    (user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("Admin") ? <Button type="primary" className="m-l-20" onClick={() => { setCreateClassModalVisible(true) }} style={{}} icon={<PlusCircleOutlined />}>CREATE NEW CLASS</Button> : <></>)
  ];
  let isNodata = true;
  const history = useHistory();
  const location = useLocation()
  return (
    <div style={{ marginBottom: "30px" }}>
      <div style={{ marginTop: -10, height: 50 }}>
        <PageHeader title='Batches' actions={classes?.length != 0 ? actions : []} onBackPress={/* location?.state?.key === "fromCourseHome" ?  */() => { history.push("/courses") } /* : null */}/>
      </div>
      <Heading className='m-t-20'>Overview</Heading>
      <div className='r-ac flex-wrap' >
        {Object.entries(classesOverview)?.map((item, index) => (
          <div className='display-flex m-t-20' >
            <div>
              <img src={item[0] == "totalClasses" ? overviewImg1 : item[0] == "totalSections" ? overviewImg2 : item[0] == "avgSections" ? overviewImg3 : item[0] == "totalStudents" ? overviewImg1 : overviewImg4} style={{ width: 50, height: 50 }} />
            </div>
            <div className='m-l-10' style={{ width: "13vw" }}>
              <div className=' dark-grey' style={{ height: 20, fontSize: 14 }}>
                {item[0] == "totalClasses" ? "Total batches" : item[0] == "totalSections" ? "Total sections" : item[0] == "avgSections" ? "Avg sections in a batch" : item[0] == "totalStudents" ? "Total students" : "Avg students in a batch"}
              </div>
              <div className='text-md color-black bold-600 '>{item[1]}</div>
            </div>
            <div style={{ borderRight: index == 4 ? "" : "1px solid #E6E6E6", marginRight: 20 }}>
            </div>
          </div>
        ))}
        <div className='m-t-20' style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div>
      </div>
      {classes?.length != 0 ?
        <>
          <div className='r-jsb m-t-20'>
            <div><Heading >All Batches</Heading></div>
            <div style={{ width: "25%" }}>
              <InputSearch
                allowClear
                placeholder="Search Batches"
                onInputChange={(e) => { handleSearch(e) }}
                onClear={() => { handleSearch("") }}
              />
            </div>
          </div>
          {Object.entries(classes).map((item, index) => {
            if (item[0]?.toLowerCase().includes(search?.toLowerCase())) {
              isNodata = false;
              return (
                <>
                  <SingleClass
                    item={item}
                    setShowEditClassModal={setShowEditClassModal}
                    storeSelectedClassDetails={storeSelectedClassDetails}
                    setIsAddSectionVisible={setIsAddSectionVisible}
                    storeSectionId={storeSectionId}
                    storeSelectedCourseId={storeSelectedCourseId}
                    viewSectionDetails={viewSectionDetails}
                    user={user}
                    setSelectedClassroom={setSelectedClassroom}
                    updateCurrentStd={updateCurrentStd}
                    selectedStd={selectedStd}
                    setFromCourseDetailsPage={setFromCourseDetailsPage}
                    storeStandardData={storeStandardData}
                  />
                </>
              )
            }
            else return null;
          })}
          {isNodata ?
            <div className='overview-card' style={{ height: "50vh", width: "100%" }}>
              <NodataCard />
            </div>
            : null
          }
        </>
        :
        <div className='overview-card' style={{ height: "60vh", width: "100%" }}>
          <NoClassroomCard setCreateClassModalVisible={setCreateClassModalVisible} />
        </div>
      }
      {createClassModalVisible &&
        <CreateClassModal
          createClassModalVisible={createClassModalVisible}
          setCreateClassModalVisible={setCreateClassModalVisible}
          setShowClassCreatedModal={setShowClassCreatedModal} />
      }
      {showClassCreatedModal &&
        <ClassCreatedModal
          showClassCreatedModal={showClassCreatedModal}
          setShowClassCreatedModal={setShowClassCreatedModal} />
      }
      {showEditClassModal &&
        <EditClassModal
          showEditClassModal={showEditClassModal}
          setShowEditClassModal={setShowEditClassModal} />
      }
      {isAddSectionVisible &&
        <AddNewSectionModal
          isAddSectionVisible={isAddSectionVisible}
          setIsAddSectionVisible={setIsAddSectionVisible}
          setShowClassCreatedModal={setShowClassCreatedModal} />
      }
      <PageDataLoader visible={classFetchLoader} />
      <BackToTop />
    </div>
  )
}

const mapStateToProps = state => {
  const { classFetchLoader, classesOverview, classes } = state.classRoom;
  const { user } = state.session
  const { selectedStd } = state.digitalLibrary;
  return {
    classFetchLoader, classesOverview, classes, user, selectedStd
  };
};

const mapDispatchToProps = dispatch => ({
  getAllClasses: () => dispatch(getAllClasses()),
  getAllCourses: (callback, page, fromClassroom) => dispatch(getAllCourses(callback, page, fromClassroom)),
  storeSelectedClassDetails: (val) => dispatch(storeSelectedClassDetails(val)),
  storeSectionId: (val) => dispatch(storeSectionId(val)),
  storeSelectedCourseId: (val) => dispatch(storeSelectedCourseId(val)),
  updateSelectedSection: data => dispatch(updateSelectedSection(data)),
  savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y)),
  setSelectedClassroom: (selectedClass) => dispatch(setSelectedClassroom(selectedClass)),
  updateCurrentStd: (object) => dispatch(updateCurrentStd(object)),
  setFromCourseDetailsPage: (val) => dispatch(setFromCourseDetailsPage(val)),
  storeStandardData: res => dispatch(storeStandardData(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassRoom)

// export default ClassRoom