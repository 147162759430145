import { Api, Notification } from "../services";
import Axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA'
export const CREATE_LEAVE_LIST = "CREATE_LEAVE_LIST";
export const STORE_CREATE_LEAVE_MODAL_DATA = "STORE_CREATE_LEAVE_MODAL_DATA";
export const CLEAR_LEAVE_OBJ = "CLEAR_LEAVE_OBJ";
export const EDIT_LEAVE_LIST = "EDIT_LEAVE_LIST";
export const STORE_EDIT_LEAVE_MODAL_DATA = "STORE_EDIT_LEAVE_MODAL_DATA";
export const CLEAR_LEAVE_UPDATE = "CLEAR_LEAVE_UPDATE";
export const SET_LEAVE_LIST = "SET_LEAVE_LIST";
export const DELETE_LEAVE_LIST = "DELETE_LEAVE_LIST";
export const SET_DELETE_LOADER = "SET_DELETE_LOADER";
export const SET_LEAVE_PAGE_LOADER = "SET_LEAVE_PAGE_LOADER";
export const SET_CREATE_LOADER = "SET_CREATE_LOADER";
export const CLEAR_LEAVE_UPTDATE = "CLEAR_LEAVE_UPTDATE";
export const LEAVE_REQUEST_DATA = "LEAVE_REQUEST_DATA";
export const SEARCH_FILTER = "SEARCH_FILTER";
export const ROLE_FILTER = "ROLE_FILTER";
export const TYPE_FILTER = "TYPE_FILTER";
export const SET_CARD_ID = "SET_CARD_ID";
export const SET_ACTION_DATA = "SET_ACTION_DATA";
export const SET_STATUS_DATA = "SET_STATUS_DATA";
export const TEACHERS_GEOFENCE_DATA = "TEACHERS_GEOFENCE_DATA";
export const TEACHERS_GEOFENCE_LOC_DATA = "TEACHERS_GEOFENCE_LOC_DATA";
export const STORE_ALLOCATED_TYPE = "STORE_ALLOCATED_TYPE";
export const STORE_START_DATE = "STORE_START_DATE";
export const STORE_END_DATE = "STORE_END_DATE";
export const DELETE_CARD = " DELETE_CARD";
export const SET_HOLIDAY_LIST = "SET_HOLIDAY_LIST";
export const SET_HOLIDAY_LOCATION = "SET_HOLIDAY_LOCATION";
export const SET_HOLIDAY_OVERVIEW = "SET_HOLIDAY_OVERVIEW";
export const SET_CREATE_HOLIDAY = "SET_CREATE_HOLIDAY";
export const STORE_CREATE_HOLIDAY = "STORE_CREATE_HOLIDAY";
export const STORE_HOLIDAY_CHECKBOX = "STORE_HOLIDAY_CHECKBOX";
export const IMPORT_HOLIDAY = "IMPORT_HOLIDAY";
export const STORE_IMPORT_HOLIDAY = "STORE_IMPORT_HOLIDAY";
export const SAVE_HOLIDAY_LIST = "SAVE_HOLIDAY_LIST";
export const STORE_HOLIDAY_LIST = "STORE_HOLIDAY_LIST";
export const STORE_EDIT_HOLIDAY = "STORE_EDIT_HOLIDAY";
export const HOLIDAY_PAGE_LOADER = "HOLIDAY_PAGE_LOADER";
export const HOLIDAY_CREATE_LOADER = "HOLIDAY_CREATE_LOADER";
export const CLEAR_CREATE_HOLIDAY = "CLEAR_CREATE_HOLIDAY";
export const ALL_ATTENDANCE_REQUIREMENT = "ALL_ATTENDANCE_REQUIREMENT";
export const STORE_ATTENDANCE_HOURS_MINUTES = "STORE_ATTENDANCE_HOURS_MINUTES";
export const STORE_ATTENDANCE_TEACHER = "STORE_ATTENDANCE_TEACHER";
export const STORE_ATTENDANCE_DATA_OPERATOR = "STORE_ATTENDANCE_DATA_OPERATOR";
export const STORE_ATTENDANCE_OPERATION = "STORE_ATTENDANCE_OPERATION";
export const STORE_ATTENDANCE_MIN_REQUIREMENT =
  "STORE_ATTENDANCE_MIN_REQUIREMENT";
export const STORE_ATTENDANCE_MAX_REQUIREMENT_TEACHER =
  "STORE_ATTENDANCE_MAX_REQUIREMENT_TEACHER";
export const STORE_ATTENDANCE_MAX_REQUIREMENT_DATA_OPERATOR =
  "STORE_ATTENDANCE_MAX_REQUIREMENT_DATA_OPERATOR";
export const STORE_ATTENDANCE_MAX_OPERATION = "STORE_ATTENDANCE_MAX_OPERATION";
export const STORE_ATTENDANCE_MAX_STUDENT = "STORE_ATTENDANCE_MAX_STUDENT"
// export const STORE_ATTENDANCE_HOURS_MIN="STORE_ATTENDANCE_HOURS_MIN"
export const ATTENDANCE_REQUIREMENT_DETAILS = "ATTENDANCE_REQUIREMENT_DETAILS";
export const STORE_ALL_ATTENDANCE_DETAILS = "STORE_ALL_ATTENDANCE_DETAILS";
export const STORE_WORKING_DAYS = "STORE_WORKING_DAYS";
export const REQUIREMENT_PAGE_LOADER = "REQUIREMENT_PAGE_LOADER";
export const ATTENDANCE_REQUIREMENT_SAVE = "ATTENDANCE_REQUIREMENT_SAVE";
export const STORE_STAFF_LEAVE_DATE = "STORE_STAFF_LEAVE_DATE"
export const SET_LEAVE_OVERVIEW = "SET_LEAVE_OVERVIEW"
export const SET_ROLE_FILTER = "SET_ROLE_FILTER"
export const GET_REMAINDER_DATA = 'GET_REMAINDER_DATA'


//STUDENT DASHBOARD
export const SET_STUDENT_DASHBOARD_DATA = "SET_STUDENT_DASHBOARD_DATA";
export const SET_CLASS_DASHBOARD_DATA = "SET_CLASS_DASHBOARD_DATA";
export const FETCH_CLASS_CARD_ID = "FETCH_CLASS_CARD_ID";
export const SET_ALLSTUDENTS_VA = "SET_ALLSTUDENTS_VA";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const STUDENT_OVERVIEW = "STUDENT_OVERVIEW";
export const STORE_STUDENT_ID_DATA = "STORE_STUDENT_ID_DATA";
export const STUDENT_PAGE_LOADER = "STUDENT_PAGE_LOADER";
export const STUDENT_SUBJECTS = "STUDENT_SUBJECTS";

export const STORE_ATTENDANCE_PROFILE_DATA = "STORE_ATTENDANCE_PROFILE_DATA";
export const SET_LOCATION = "SET_LOCATION";
export const SET_MARK_ATTENDANCE = "SET_MARK_ATTENDANCE";
export const SET_LEAVE_REQUEST = "SET_LEAVE_REQUEST";
export const SELECTED_LEAVES = "SELECTED_LEAVES";
export const SELECTED_ATTENDANCE = "SELECTED_ATTENDANCE";
export const SET_LOADER = "SET_LOADER";
export const STORE_ATTENDANCE_DASH_BOARD_DATA =
  "STORE_ATTENDANCE_DASH_BOARD_DATA";
export const ATTENDANCE_TABLE_DATA = "ATTENDANCE_TABLE_DATA";
export const TAB = "TAB";
export const SET_EDIT_LEAVE = "SET_EDIT_LEAVE";
export const EDIT_LEAVE_CARD_ID = "EDIT_LEAVE_CARD_ID";
export const SET_LOCATION_CARD_TITLE = "SET_LOCATION_CARD_TITLE";
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const STORE_PROFILETAB_MONTH_SELECT = "STORE_PROFILETAB_MONTH_SELECT";

export const STORE_MONTH = "STORE_MONTH";
export const STORE_STUDENT_SUBJECTS = "STORE_STUDENT_SUBJECTS";

export const STUDENT_TODAYS_ATTENDNACE = "STUDENT_TODAYS_ATTENDNACE";
export const START_DATE_END_DATE = "START_DATE_END_DATE";
export const START_DATE = "START_DATE";
export const END_DATE = "END_DATE";
export const MONTH_OR_WEEK = "MONTH_OR_WEEK";
export const STORE_SELECTED_SUBJECT = "STORE_SELECTED_SUBJECT";
export const TEACHER_HALF_DAY_ATTENDANCE = "TEACHER_HALF_DAY_ATTENDANCE";
export const OPERATION_HALF_DAY = "OPERATION_HALF_DAY";
export const ATTENDANCE_STUDENT_OVERVIEW = "ATTENDANCE_STUDENT_OVERVIEW";

export const STORE_USERS_SUMMARY_GRAPH_DATA = "STORE_USERS_SUMMARY_GRAPH_DATA";
export const STORE_ATTENDANCE_DETAILS_MONTH = "STORE_ATTENDANCE_DETAILS_MONTH";
export const ATTENDANCE_DETAILS_MODAL_DATA = "ATTENDANCE_DETAILS_MODAL_DATA";
export const STORE_USER_SUMMARY_START_DATE = "STORE_USER_SUMMARY_START_DATE";
export const STORE_USER_SUMMARY_END_DATE = "STORE_USER_SUMMARY_END_DATE";
export const STORE_SUMMARY_TYPE = "STORE_SUMMARY_TYPE";
export const STORE_SUMMARY_YEAR = "STORE_SUMMARY_YEAR";
export const STORE_WORK_SUMMARY_TYPE = "STORE_WORK_SUMMARY_TYPE";
export const STORE_WORK_SUMMARY_YEAR = "STORE_WORK_SUMMARY_YEAR";
export const STORE_WORK_SUMMARY_START_DATE = "STORE_WORK_SUMMARY_START_DATE";
export const STORE_WORK_SUMMARY_END_DATE = "STORE_WORK_SUMMARY_END_DATE";
export const SET_BACK_TO_PRIVILEGED_VIEW = "SET_BACK_TO_PRIVILEGED_VIEW";
export const TRACK_SELECTED_RADIO = "TRACK_SELECTED_RADIO";
export const STORE_SELECTED_DROPDOWN_MONTH = "STORE_SELECTED_DROPDOWN_MONTH";
export const UNCHECKED_VALUE = "UNCHECKED_VALUE";
export const SET_CLICKED_ON_USER_CARD = "SET_CLICKED_ON_USER_CARD";
export const FETCH_MONTH_FOR_ATTDETAILS = "FETCH_MONTH_FOR_ATTDETAILS";
export const SET_BUTTON_DIV_VISIBLE = "SET_BUTTON_DIV_VISIBLE";
export const ID_SELECTED = 'ID_SELECTED';
export const SET_REQUESTED_INDEX = "SET_REQUESTED_INDEX";
export const REQUEST_VIEW_ALL_CLICKED = "REQUEST_VIEW_ALL_CLICKED";
export const STORE_MONTHLY_SELECTED_MONTH = "STORE_MONTHLY_SELECTED_MONTH";
export const STORE_REQUEST_VIEW_ALL_PAGE = "STORE_REQUEST_VIEW_ALL_PAGE";
export const STORE_OCC_ID = "STORE_OCC_ID";
export const STORE_STUD_STATS = "STORE_STUD_STATS";
export const SET_FROM_USERS_GEO_FENCE = "SET_FROM_USERS_GEO_FENCE";
export const STORE_EDITED_GEO_FENCE_DATA = "STORE_EDITED_GEO_FENCE_DATA";
export const STORE_GEO_FENCE_USERID = "STORE_GEO_FENCE_USERID";
export const FETCH_STANDARDS = "FETCH_STANDARDS";
export const STORE_SELECTED_CLASS = "STORE_SELECTED_CLASS";
export const STORE_USER_DETAILS = "STORE_USER_DETAILS";
export const STORE_NUMBER_OF_DAYS = "STORE_NUMBER_OF_DAYS";
export const SET_STORE_LEAVE_IDS = "SET_STORE_LEAVE_IDS"
export const STORE_SAME_MINIMUM_ATT = "STORE_SAME_MINIMUM_ATT"
export const SET_SAME_HALF_ATT = "SET_SAME_HALF_ATT"
export const SET_SAME_MAXIMUM_ATT = "SET_SAME_MAXIMUM_ATT"
export const GET_WEB_CLOCK_IN_DATA = "GET_WEB_CLOCK_IN_DATA"

//clock in clock out
export const SET_CLOCK_IN_START_DATE = "SET_CLOCK_IN_START_DATE"
export const SET_CLOCK_IN_END_DATE = "SET_CLOCK_IN_END_DATE,"
export const SET_CLOCK_IN_SWITCH = "SET_CLOCK_IN_SWITCH"

/* NEW ATTENDANCE V3 */
export const STUDENTS_LIST = 'STUDENTS_LIST'
export const LEAVE_OVERVIEW = 'LEAVE_OVERVIEW'

export const staffLeavesOverview = res => ({
  type: LEAVE_OVERVIEW,
  res
})

export const storeMonthlySelectedMonth = (val) => ({
  type: STORE_MONTHLY_SELECTED_MONTH,
  val
})

export const trackSelectedRadio = (val) => ({
  type: TRACK_SELECTED_RADIO,
  val,
});

export const updateFilter = (key, val) => ({
  type: UPDATE_FILTER,
  key,
  val,
});

export const setTabs = (val) => ({
  type: TAB,
  val,
});

export const fetchClassCardId = (id) => ({
  type: FETCH_CLASS_CARD_ID,
  id,
});

export const setStudentDashboardData = (data) => ({
  type: SET_STUDENT_DASHBOARD_DATA,
  data,
});

export const studentPageLoader = (bool) => ({
  type: STUDENT_PAGE_LOADER,
  bool,
});
export const setStartDate = (val) => ({
  type: START_DATE,
  val,
});
export const setEndDate = (val) => ({
  type: END_DATE,
  val,
});

export const setMonthOrWeek = (val) => ({
  type: MONTH_OR_WEEK,
  val,
});

export const storeSelectedSubject = (val) => ({
  type: STORE_SELECTED_SUBJECT,
  val,
});

export const studentDownloadReport = () => {
  return (dispatch, getState) => {
    const { storeSelectingMonth } = getState().attendanceNew;
    const { specificUserId } = getState().userManagement;
    return Api.post(
      `/attendancev2/download-attendance-by-occurance/${specificUserId}`
    )
      .params({ attendanceDetailsMonth: storeSelectingMonth })
      .send((response) => {
        if (response !== undefined) {

          let fileName = response.file.split('/')[response.file.split('/').length - 1];
          Axios.get(response.file, {
            responseType: "blob",
          }).then((res) => {

            fileDownload(res.data, fileName);

          });
        }
      });
  };
};

export const studentAttendanceDownloadReport = () => {
  return (dispatch, getState) => {
    const { studentSelectMonth } = getState().attendanceNew;
    const { user } = getState().session;
    // const {specificUserId}=getState().userManagement
    return Api.post(`/attendancev2/download-attendance-by-occurance/${user.id}`)
      .params({ attendanceDetailsMonth: studentSelectMonth })
      .send((response) => {
        if (response !== undefined) {
          let fileName = response.file.split('/')[response.file.split('/').length - 1];
          Axios.get(response.file, {
            responseType: "blob",
          }).then((res) => {
            fileDownload(res.data, fileName);
          });
        }
      });
  };
};

export const fetchStudentDashboard = ({ callback = null }) => {
  return (dispatch, getState) => {
    const { filter, startDate, endDate, storeSubject } =
      getState().attendanceNew;
    const { specificUserId } = getState().userManagement;
    let params = {
      search: filter?.indexSearch,
      startDate: startDate,
      endDate: endDate,
      standardId: storeSubject,
    };
    dispatch(studentPageLoader(true));
    // dispatch(setLoader('indexLoader', true));
    return Api.get(`/attendancev2/students-dashboard`)
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          console.log("first", response);
          dispatch(setStudentDashboardData(response));

          // dispatch(setLoader('indexLoader', false));
          dispatch(studentPageLoader(false));
        }
        // callback(false);
      });
  };
};

export const setClassDashboardData = (data) => ({
  type: SET_CLASS_DASHBOARD_DATA,
  data,
});

export const fetchClassDashboard = () => {
  return (dispatch, getState) => {
    const { storeClasscardId, startDate, endDate } = getState().attendanceNew;
    let params = { id: storeClasscardId , startDate, endDate};
    dispatch(studentPageLoader(true));
    // dispatch(setLoader('indexLoader', true));
    return Api.get(`/attendancev2/class-dashboard`)
      .params(params)
      .send((response) => {
        if (response !== undefined) {
          dispatch(setClassDashboardData(response));
          dispatch;
          // dispatch(setLoader('indexLoader', false));
          dispatch(studentPageLoader(false));
        }
        // callback(false);
      });
  };
};

export const setAllStudentsVA = (data) => ({
  type: SET_ALLSTUDENTS_VA,
  data,
});

export const fetchAllStudentsVA = () => {
  return (dispatch, getState) => {
    const { storeClasscardId, filter, pageNumber } = getState().attendanceNew;
    let params = {
      id: storeClasscardId,
      search: filter?.VAStudentsSearch,
      page: pageNumber
    };
    // dispatch(setLoader('indexLoader', true));
    dispatch(studentPageLoader(true));
    return Api.get(`/attendancev2/all-students-in-class`)
      .params(params)
      .send((response) => {
        if (response !== undefined) {
          console.log("first", response);
          dispatch(setAllStudentsVA(response));
          // dispatch
          // dispatch(setLoader('indexLoader', false));
          dispatch(studentPageLoader(false));
        }
        // callback(false);
      });
  };
};

export const STORE_USERS_DASH_BOARD_DATA = "STORE_USERS_DASH_BOARD_DATA";
export const STORE_PREVILEGED_VIEW_DATA = "STORE_PREVILEGED_VIEW_DATA";
export const STORE_USERS_NAME = "STORE_USERS_NAME";
export const STORE_LEAVES_TAB_DATA = "STORE_LEAVES_TAB_DATA";
export const STORE_REQUESTED_USER_ID = "STORE_REQUESTED_USER_ID";
export const STORE_TAB_DATA = "STORE_TAB_DATA";
export const STORE_SELECTED_MONTH = "STORE_SELECTED_MONTH";
export const STORE_SELECTED_RANGE = "STORE_SELECTED_RANGE";
export const UPDATE_GEO_FENCE_LIST = "UPDATE_GEO_FENCE_LIST";
export const UPDATE_GEOMETRY_LOCATION = "UPDATE_GEOMETRY_LOCATION";
export const UPDATE_RADIUS = "UPDATE_RADIUS";
export const UPDATE_SEARCH_LOCATION = "UPDATE_SEARCH_LOCATION";
export const UPDATE_LOCATION_LIST = "UPDATE_LOCATION_LIST";
export const UPDATE_ATTENDANCE_DATA = "UPDATE_ATTENDANCE_DATA";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";
export const LOCATION_CARD_DATA = "LOCATION_CARD_DATA";
export const DO_HALF_DAY_ATTENDANCE = "DO_HALF_DAY_ATTENDANCE";
export const STORE_SUMMARY_START_DATE = "STORE_SUMMARY_START_DATE";
export const STORE_SUMMARY_END_DATE = "STORE_SUMMARY_END_DATE";

export const SELECTED_MONTH = "SELECTED_MONTH";
export const STORE_SUBJECT_DETAIL_MODAL = "STORE_SUBJECT_DETAIL_MODAL";
export const STUDENT_SUMMARY_DETAIL = "STUDENT_SUMMARY_DETAIL";
export const STUDENT_START_DATE = "STUDENT_START_DATE";
export const STUDENT_END_DATE = "STUDENT_END_DATE";
export const MONTHLY_SUMMARY = "MONTHLY_SUMMARY";
export const SUBJECT_SUMMARY = "SUBJECT_SUMMARY";
export const CHECK_STORE = "CHECK_STORE";
export const SET_ATTENDNCE_OVERVIEW = "SET_ATTENDNCE_OVERVIEW"
export const HALF_DAY_STUDENT = "HALF_DAY_STUDENT"
export const FULL_DAY_STUDENT = 'FULL_DAY_STUDENT'
export const SET_APPROVAL_ID = 'SET_APPROVAL_ID'

export const editLeaveList = (res) => ({
  type: EDIT_LEAVE_LIST,
  res,
});

export const storeEditLeaveModalData = (key, value) => ({
  type: STORE_EDIT_LEAVE_MODAL_DATA,
  key,
  value,
});

export const clearEditLeaveObj = (key, value) => ({
  type: CLEAR_LEAVE_UPDATE,
  key,
  value,
});

export const createLeaveList = (res) => ({
  type: CREATE_LEAVE_LIST,
  res,
});

export const storeCreateLeaveModalData = (key, val) => ({
  type: STORE_CREATE_LEAVE_MODAL_DATA,
  key,
  val,
});

export const clearCreateLeaveObj = () => ({
  type: CLEAR_LEAVE_OBJ,
});

export const setAllLeaves = (res) => ({
  type: SET_LEAVE_LIST,
  res,
});

export const deleteLeaves = (value) => ({
  type: DELETE_LEAVE_LIST,
  value,
});

export const setDeleteLoader = (bool) => ({
  type: SET_DELETE_LOADER,
  bool,
});

export const setleavePageLoader = (bool) => ({
  type: SET_LEAVE_PAGE_LOADER,
  bool,
});

export const setCreateLoader = (bool) => ({
  type: SET_CREATE_LOADER,
  bool,
});

export const setHolidayList = (res) => ({
  type: SET_HOLIDAY_LIST,
  res,
});

export const setHolidayLocation = (res) => ({
  type: SET_HOLIDAY_LOCATION,
  res,
});

export const setHolidayOverview = (res) => ({
  type: SET_HOLIDAY_OVERVIEW,
  res,
});

export const setCreateHoliday = (res) => ({
  type: SET_CREATE_HOLIDAY,
  res,
});

export const storeCreateHoliday = (key, val) => ({
  type: STORE_CREATE_HOLIDAY,
  key,
  val,
});

export const storeholidayCheckBox = (val) => ({
  type: STORE_HOLIDAY_CHECKBOX,
  val,
});

export const importHoliday = (res) => ({
  type: IMPORT_HOLIDAY,
  res,
});

export const storeImportHoliday = (value) => ({
  type: STORE_IMPORT_HOLIDAY,
  value,
});

export const saveHolidayList = (res) => ({
  type: SAVE_HOLIDAY_LIST,
  res,
});

export const storeHolidayList = (value) => ({
  type: STORE_HOLIDAY_LIST,
  value,
});

export const editHoliday = (val) => ({
  type: STORE_EDIT_HOLIDAY,
  val,
});

export const holidayPageLoader = (bool) => ({
  type: HOLIDAY_PAGE_LOADER,
  bool,
});

export const holidayLoader = (bool) => ({
  type: HOLIDAY_CREATE_LOADER,
  bool,
});
export const allAttendanceRequirement = (res) => ({
  type: ALL_ATTENDANCE_REQUIREMENT,
  res,
});

export const storeAttendanceHoursMinutes = (value) => ({
  type: STORE_ATTENDANCE_HOURS_MINUTES,
  value,
});

export const teacherHoursMinutes = (value) => ({
  type: STORE_ATTENDANCE_TEACHER,
  value,
});
export const storeAttendancDo = (value) => ({
  type: STORE_ATTENDANCE_DATA_OPERATOR,
  value,
});

export const storeAttendanceOp = (value) => ({
  type: STORE_ATTENDANCE_OPERATION,
  value,
});
export const storeAttendanceMinRequirement = (value) => ({
  type: STORE_ATTENDANCE_MIN_REQUIREMENT,
  value,
});

export const storeAttendanceMaXRequirementTeacher = (value) => ({
  type: STORE_ATTENDANCE_MAX_REQUIREMENT_TEACHER,
  value,
});

export const storeAttendanceMaXRequirementDo = (value) => ({
  type: STORE_ATTENDANCE_MAX_REQUIREMENT_DATA_OPERATOR,
  value,
});

export const storeAttendanceMaXOperatiron = (value) => ({
  type: STORE_ATTENDANCE_MAX_OPERATION,
  value,
});

export const storeAttendanceMaXStudent = (value) => ({
  type: STORE_ATTENDANCE_MAX_STUDENT,
  value,
});

export const storeWorkingDays = (value) => ({
  type: STORE_WORKING_DAYS,
  value,
});

export const attendanceRequirementPageLoader = (bool) => ({
  type: REQUIREMENT_PAGE_LOADER,
  bool,
});

export const attendanRequirementSaveLoader = (bool) => ({
  type: ATTENDANCE_REQUIREMENT_SAVE,
  bool,
});

export const studentOverview = (res) => ({
  type: STUDENT_OVERVIEW,
  res,
});

export const storeStudentIdData = (val) => ({
  type: STORE_STUDENT_ID_DATA,
  val,
});

// export const studentleavePageLoader=(bool)=>({
//   type:SET_LEAVE_PAGE_LOADER,
//   bool
// })
export const storeMonth = (val) => ({
  type: STORE_MONTH,
  val,
});

export const storeStudentSubjects = (val) => ({
  type: STORE_STUDENT_SUBJECTS,
  val,
});
export const storeSubjectDetailModl = (val) => ({
  type: STORE_SUBJECT_DETAIL_MODAL,
  val,
});

export const teacherHalfdayAttendance = (val) => ({
  type: TEACHER_HALF_DAY_ATTENDANCE,
  val,
});

export const DoHalfdayAttendance = (val) => ({
  type: DO_HALF_DAY_ATTENDANCE,
  val,
});

export const opHalfDay = (val) => ({
  type: OPERATION_HALF_DAY,
  val,
});

export const halfDayStudent = (val) => ({
  type: HALF_DAY_STUDENT,
  val
})

export const fullDayStudent = (val) => ({
  type: FULL_DAY_STUDENT,
  val
})

export const storeSummaryStartDate = (val) => ({
  type: STORE_SUMMARY_START_DATE,
  val,
});
export const attendanceStudentOverview = (res) => ({
  type: ATTENDANCE_STUDENT_OVERVIEW,
  res,
});

export const selectMonth = (value) => ({
  type: SELECTED_MONTH,
  value,
});

export const studentSummaryDetail = (res) => ({
  type: STUDENT_SUMMARY_DETAIL,
  res,
});

export const studentStartDate = (val) => ({
  type: STUDENT_START_DATE,
  val,
});
export const studentEndDate = (val) => ({
  type: STUDENT_END_DATE,
  val,
});

export const monthlySummary = (val) => ({
  type: MONTHLY_SUMMARY,
  val,
});

export const subjectSummary = (val) => ({
  type: SUBJECT_SUMMARY,
  val,
});

export const getStudentSummary = (callBack = null) => {
  const url = `/attendancev2/student-summary`;
  return (dispatch, getState) => {
    const {
      studentMonthlySummary,
      studentattStartDate,
      studentattEndDate,
      studentSubjectSummary,
    } = getState().attendanceNew;
    const params = {
      monthlySummaryMonth: moment(studentMonthlySummary).format("MMMM,YYYY") ? moment(studentMonthlySummary).format("MMMM,YYYY") : moment().format("MMMM,YYYY"),
      startDate: studentattStartDate,
      endDate: studentattEndDate,
      subjectSummaryMonth: studentSubjectSummary,
    };
    // dispatch(setleavePageLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }
          resolve();
          if (response) {
            dispatch(studentSummaryDetail(response));
          }
          // dispatch(setleavePageLoader(false));
        });
    });
  };
};

export const getAttendanceStudentOverview = (callBack = null) => {
  const url = `/attendancev2/my-attendance`;
  return (dispatch, getState) => {
    const { studentSelectMonth, subjectDetailModal } = getState().attendanceNew;
    const params = {
      month: studentSelectMonth,
      subject: subjectDetailModal,
    };
    // console.log("first", createLeaveObj)

    // dispatch(setleavePageLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }
          resolve();
          if (response) {
            dispatch(attendanceStudentOverview(response));
          }
          // dispatch(setleavePageLoader(false));
        });
    });
  };
};

export const storeSummaryEndDate = (val) => ({
  type: STORE_SUMMARY_END_DATE,
  val,
});


export const getStudentOverview = () => {
  return (dispatch, getState) => {
    const { specificUserId } = getState().userManagement;
    const { storeSelectingMonth, storeSubjectDetails } =
      getState().attendanceNew;

    dispatch(studentPageLoader(true));
    console.log("view,", storeSelectingMonth);
    return Api.get(`/attendancev2/profile-tabs/${specificUserId}`)
      .params({
        attendanceDetailsMonth: storeSelectingMonth,
        subject: storeSubjectDetails,
      })

      .send((response, error) => {
        if (response) {
          console.log("res", response);
          // dispatch(storeMonth(response))
          dispatch(studentOverview(response));
        }
        dispatch(studentPageLoader(false));
      });
  };
};

export const attendanceRequirementDetail = () => {
  const url = `/attendancev2/get-attendance-setting`;
  return (dispatch, getState) => {
    const { } = getState().attendanceNew;
    // console.log("first", createLeaveObj)
    dispatch(attendanceRequirementPageLoader(true));

    return new Promise((resolve, reject) => {
      Api.get(url).send((response, error) => {
        resolve();
        if (response) {
          console.log("res", response);
          dispatch(allAttendanceRequirement(response));
        }
        dispatch(attendanceRequirementPageLoader(false));
      });
    });
  };
};

export const setAttendanceOverview = (res) => ({
  type: SET_ATTENDNCE_OVERVIEW,
  res
})
export const storeStaffLeave = (val) => ({
  type: STORE_STAFF_LEAVE_DATE,
  val
})
export const setLeaveOverView = (val) => ({
  type: SET_LEAVE_OVERVIEW,
  val
})
export const setRoleFilter = (val) => ({
  type: SET_ROLE_FILTER,
  val
})



export const getLeaveOverViewData = (callback = null) => {
  const url = `/attendancev2/users-leaves-list`;
  return (dispatch, getState) => {
    const { searchFilterContainer, pageNumber } = getState().attendanceNew;
    let { filterRole } = getState().attendanceNew;
    if (filterRole && filterRole?.includes('All')) {
      // filterRole?.splice(filterRole?.indexOf('All'));
      filterRole = filterRole.concat(['Teacher', 'Data Operator', 'Operation'])
    }
    filterRole = Array.from(new Set(filterRole));
    // let { filterRole } = getState().attendanceNew;
    // if (filterRole?.length == 1 && filterRole[0] == 'All') {
    //   filterRole = ['Teacher', 'Data Operator', 'Operation']
    // }
    const params = {
      search: searchFilterContainer,
      role: filterRole,
      page: pageNumber


    }
    // console.log("first", createLeaveObj)
    // dispatch(attendanceRequirementPageLoader(true));

    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send((response, error) => {
          if (callback) {
            callback();
          }
          resolve();
          if (response) {
            console.log("res", response);
            dispatch(setLeaveOverView(response));
            dispatch(staffLeavesOverview(response));
            // dispatch(storeAllLeaveData())
          }
          // dispatch(attendanceRequirementPageLoader(false));
        });
    });
  };
};


export const setGraphDate = (val) => ({
  type: SET_GRAPH_DATA,
  val
})

export const attendnaceLeaveOverview = (callback = null, selectedLeaveTypes) => {
  const url = `/attendancev2/leave-overview`;
  return (dispatch, getState) => {
    const { storeLeaveDate, storeGraphDate } = getState().attendanceNew;
    let { roleFilterContainer } = getState().attendanceNew;
    if (roleFilterContainer && roleFilterContainer?.includes('All')) {
      roleFilterContainer.splice(roleFilterContainer?.indexOf('All'));
      roleFilterContainer.splice(roleFilterContainer?.indexOf('Teacher'));
      roleFilterContainer.splice(roleFilterContainer?.indexOf('Data Operator'));
      roleFilterContainer.splice(roleFilterContainer?.indexOf('Operation'));
      roleFilterContainer = roleFilterContainer.concat(['Teacher', 'Data Operator', 'Operation'])
    }
    roleFilterContainer = Array.from(new Set(roleFilterContainer));
    const params = {
      leaveFilterDate: storeLeaveDate ? storeLeaveDate : moment().format('YYYY-MM-DD'),
      overViewDate: storeGraphDate ? storeGraphDate : moment().format('YYYY-MM-DD'),
      role: roleFilterContainer,
      leaveType: selectedLeaveTypes
    }
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send((response, error) => {
          if (callback) {
            callback();
          }
          resolve();
          if (response) {
            console.log("res", response);
            dispatch(setAttendanceOverview(response));
            // dispatch(storeAllLeaveData())
          }
          // dispatch(attendanceRequirementPageLoader(false));
        });
    });
  };
};

export const setApprovalId = (val) => ({
  type: SET_APPROVAL_ID,
  val
})

export const attendanceAllRequiredDetails = (callBack = null, successCallBack = null) => {
  const url = `/attendancev2/attendance-setting`;
  return (dispatch, getState) => {
    let { DoHalfDay, storeMaxStudentData, storeHalfDayStudent, operationHalfDay, storeWorkingDaysDetails, storeMaxTeacher, attendanceTeacherHrsMinutes, storeFullDayStudent, staffAvgWorkingHrs, teacherHalfDay, storeAttendanceDataOperator, storeAttendanceOperation, storeAttendanceMin, storeMaxDataOperater, storeMaxOperations, storeApprovalId } = getState().attendanceNew
    let params = {
      workingDays: storeWorkingDaysDetails,
      minAvgWorkingHrForStaff: (staffAvgWorkingHrs.hours ? parseInt(staffAvgWorkingHrs.hours) : 0) * 60 + (staffAvgWorkingHrs.minutes ? parseInt(staffAvgWorkingHrs.minutes) : 0),
      minWorkingHrForFullDayTeacher: parseInt(attendanceTeacherHrsMinutes.hour) * 60 + parseInt(attendanceTeacherHrsMinutes.minute),
      minWorkingHrForFullDayDO: parseInt(storeAttendanceDataOperator.doHour) * 60 + parseInt(storeAttendanceDataOperator.doMinute),
      minWorkingHrForFullDayOperation: parseInt(storeAttendanceOperation.opHour) * 60 + parseInt(storeAttendanceOperation.opMinute),
      minAttendancePercentageStudent: parseInt(storeAttendanceMin),
      requestCountTeacher: parseInt(storeMaxTeacher),
      requestCountDO: parseInt(storeMaxDataOperater),
      requestCountOperation: parseInt(storeMaxOperations),
      requestCountStudent: parseInt(storeMaxStudentData),

      minWorkingHrForHalfDayTeacher: parseInt(teacherHalfDay.tHour) * 60 + parseInt(teacherHalfDay.tMinute),
      minWorkingHrForHalfDayDO: parseInt(DoHalfDay.dHour) * 60 + parseInt(DoHalfDay.dMinute),
      minWorkingHrForHalfDayOperation: parseInt(operationHalfDay.oHour) * 60 + parseInt(operationHalfDay.oMinute),
      minWorkingHrForFullDayStudent: parseInt(storeFullDayStudent?.sHour) * 60 + parseInt(storeFullDayStudent?.sMinute),
      minWorkingHrForHalfDayStudent: parseInt(storeHalfDayStudent?.stHour) * 60 + parseInt(storeHalfDayStudent?.stMinute),
      approvalLevel: storeApprovalId
    }
    dispatch(attendanRequirementSaveLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url).params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }
          resolve();
          if (response != undefined && response?.show?.type == "success") {
            dispatch(attendanceRequirementDetail());
            if (successCallBack) {
              successCallBack();
            }
          }

          dispatch(attendanRequirementSaveLoader(false));
        });
    });
  };
};

export const holidayDetail = () => {
  const url = `/attendancev2/holiday/save-list`;
  return (dispatch, getState) => {
    const { importHolidayListNew, holidayCheckBox } = getState().attendanceNew;
    const params = {
      geoFenceId: importHolidayListNew,
      dates: holidayCheckBox,
    };
    dispatch(holidayLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send((response, error) => {
          resolve();
          if (response) {
            dispatch(getHolidayOverview());
          }
          dispatch(holidayLoader(false));
        });
    });
  };
};

export const importHolidayLoc = (callBack = null) => {
  const url = `/attendancev2/holiday/import`;
  return (dispatch, getState) => {
    const { importHolidayListNew, selectedImportRadio } =
      getState().attendanceNew;
    //console.log("first", importHolidayListNew)
    const params = {
      importFromLocation: selectedImportRadio,
      importToLocation: importHolidayListNew,
    };
    console.log("radio", selectedImportRadio);
    dispatch(holidayLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }
          resolve();
          if (response) {
            dispatch(getHolidayOverview());
          }
          dispatch(holidayLoader(false));
        });
    });
  };
};

export const createHoliday = (callBack = null) => {
  const url = `/attendancev2/holiday/create`;
  return (dispatch, getState) => {
    const { createHolidayList, importHolidayListNew, setAllHolidayList } = getState().attendanceNew;
    //console.log("value",createHolidayList)
    //console.log("first", createLeaveObj)
    const params = {
      title: createHolidayList?.title,
      date: createHolidayList?.date,
      id: createHolidayList?.id,
      geoFenceId: importHolidayListNew,

    };
    dispatch(holidayLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }

          resolve();
          if (response) {
            dispatch(getHolidayOverview());
          }
          dispatch(holidayLoader(false));
        });
    });
  };
};

export const getHolidayOverview = () => {
  const url = `/attendancev2/holiday`;
  return (dispatch, getState) => {
    const { importHolidayListNew, editHolidayValue, checkedValue } =
      getState().attendanceNew;
    // console.log("first", createLeaveObj)
    dispatch(holidayPageLoader(true));
    const params = {
      geoFenceId: importHolidayListNew,
      edit: editHolidayValue ? true : null,
    };
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(params)
        .send((response, error) => {
          resolve();
          if (response) {
            console.log("res", response);
            dispatch(setHolidayList(response.holidays));
            dispatch(setHolidayLocation(response.locations));
            if (!importHolidayListNew)
              dispatch(storeImportHoliday(response?.locations?.[0]?.id))
            dispatch(setHolidayOverview(response.overview));
            dispatch(editHoliday(response.edit));
            dispatch(uncheckedValue(response.edit));
            if (response?.overview?.total == 0) {
              dispatch(storeCheck([]));
            }
            const holidayList = []
            for (const month of response.holidays) {
              for (const holiday of month.data) {
                if (holiday.selected)
                  holidayList.push((moment(holiday?.date).format('YYYY-MM-DD')))
              }
            }
            dispatch(storeholidayCheckBox([...holidayList]))
            // if(response.edit)
            // dispatch(storeholidayCheckBox())
          }
          dispatch(holidayPageLoader(false));
        });
    });
  };
};

export const deleteLeave = (callBack = null) => {
  return (dispatch, getState) => {
    const { deleteCardId } = getState().attendanceNew;
    const url = `/attendancev2/delete-leave/${deleteCardId}`;
    dispatch(setDeleteLoader(true));
    return (
      Api.delete(url)
        // .params({search:search?search:''})
        .send((response) => {
          if (callBack) {
            callBack();
          }
          if (response !== undefined) {
            dispatch(getAllLeave(null, 1));
            console.log("response", response);
          }
          dispatch(setDeleteLoader(false));
        })
    );
  };
};

export const getAllLeave = (callBack = null, homepage = 0) => {
  const url = `/attendancev2/get-all-leaves`;
  return (dispatch, getState) => {
    const { user } = getState().session;
    const params = {
      homepage: homepage,
      userId: user?.id
    };

    dispatch(setleavePageLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url).params(params).send((response, error) => {
        if (callBack) {
          callBack();
        }
        resolve();
        if (response) {
          console.log(response);
          dispatch(setAllLeaves(response));
        }
        dispatch(setleavePageLoader(false));
      });
    });
  };
};
export const editLeave = (callBack = null) => {
  const url = "/attendancev2/update-leave";
  return (dispatch, getState) => {
    const { editLeaveObj } = getState().attendanceNew;

    const params = {
      leaveName: editLeaveObj?.leaveName,
      numberOfDays: parseInt(editLeaveObj?.noOfDays),
      startDate: editLeaveObj?.startDate,
      endDate: editLeaveObj?.endDate,
      id: editLeaveObj?.id,
      carryForward: editLeaveObj?.carryForward,
      halfDayApplicable: editLeaveObj?.halfDayApplicable,
    };



    dispatch(setCreateLoader(true));

    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }
          resolve();
          if (response) {
            dispatch(getAllLeave(null, 1));
          }
          dispatch(setCreateLoader(false));
        });
    });
  };
};

export const createLeaves = (callBack = null) => {
  const url = `/attendancev2/create-leave`;
  return (dispatch, getState) => {
    const { createLeaveObj } = getState().attendanceNew;
    // console.log("first", createLeaveObj)
    const params = {
      leaveName: createLeaveObj?.leaveName,
      numberOfDays: parseInt(createLeaveObj?.noOfDays),
      startDate: createLeaveObj?.startDate,
      endDate: createLeaveObj?.endDate,
      carryForward: createLeaveObj?.carryForward,
      halfDayApplicable: createLeaveObj?.halfDayApplicable,
      backDays: createLeaveObj?.totalBackDays ? parseInt(createLeaveObj?.totalBackDays) : 0
    };

    dispatch(setCreateLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send((response, error) => {
          if (callBack) {
            callBack();
          }
          resolve();
          if (response) {
            dispatch(getAllLeave(null, 1));
          }
          dispatch(setCreateLoader(false));
        });
    });
  };
};

export const getLeaveRequestData = (callback = null) => {
  return (dispatch, getState) => {
    const {
      roleFilterContainer,
      typeFilterContainer,
      statusDataContainer,
      searchFilterContainer,
      pageNumber
    } = getState().attendanceNew;
    { console.log("pageNumber1", pageNumber) }
    return Api.get("/attendancev2/request-list")
      .params({
        page: pageNumber,
        limit: 30,
        role: roleFilterContainer,
        search: searchFilterContainer,
        requestType: typeFilterContainer,
        status: statusDataContainer,
      })
      .send((response) => {
        if (callback) {
          callback();
        }
        console.log("ABCDE", typeFilterContainer);
        if (response !== undefined) {
          dispatch(leaveRequestData(response));
        }
      });
  };
};

export const getRequests = (params, callBack = null) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/request-list`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(leaveRequestData(response));
        }
        callBack();
      })
    );
  };
};


export const setLeaveIds = (val) => ({
  type: SET_STORE_LEAVE_IDS,
  val
})

export const requestedActionData = (
  callback = null,
  successCallBack = null,

) => {

  return (dispatch, getState) => {
    const { storeLeaveId, leavesTabDataContainer, actionDataContainer, cardId } = getState().attendanceNew;
    return Api.post("/attendancev2/request-action")
      .params({
        requestIds: storeLeaveId, action: actionDataContainer, commonId: cardId
      })
      .send((response) => {
        if (callback) {
          callback();
        }
        console.log("ABCD", callback);
        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          console.log("EFGH", successCallBack);
          // dispatch(getLeaveRequestData(callback));
        }
      });
  };
};

export const leaveRequestData = (response) => ({
  type: LEAVE_REQUEST_DATA,
  data: response,
});
export const searchFilter = (val) => ({
  type: SEARCH_FILTER,
  val,
});
export const roleFilter = (val) => ({
  type: ROLE_FILTER,
  val,
});

export const typeFilter = (val) => ({
  type: TYPE_FILTER,
  val,
});

export const setCardId = (id) => ({
  type: SET_CARD_ID,
  id,
});

export const setActionData = (val) => ({
  type: SET_ACTION_DATA,
  val,
});

export const setStatusData = (val) => ({
  type: SET_STATUS_DATA,
  val,
});

export const getTeachersGeofenceData = (callback = null) => {
  return (dispatch, getState) => {
    const { usersNameContainer, searchFilterContainer, } =
      getState().attendanceNew;
    let { selectedClassId, roleFilterContainer } = getState().attendanceNew;

    if (roleFilterContainer?.includes('All') || roleFilterContainer?.length == 0) {
      roleFilterContainer?.splice(roleFilterContainer.indexOf('All'))
      roleFilterContainer = roleFilterContainer.concat(['Teacher', 'Student', 'Data Operator', 'Operation'])
    }

    if (!roleFilterContainer?.includes('Student')) {
      selectedClassId = undefined;
    }
    roleFilterContainer = Array.from(new Set(roleFilterContainer))
    return Api.get(`/attendancev2/geo-fence-allocation-list`)
      .params({ role: usersNameContainer, name: searchFilterContainer, role: roleFilterContainer, stdId: selectedClassId })
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          dispatch(teachersGeofenceData(response));
        }
      });
  };
};

export const getTeachersGeofenceLocations = (search) => {
  return (dispatch, getState) => {
    const { } = getState().attendanceNew;
    return Api.get(`/attendancev2/autocomplete/geo-fence`)
      .params({ search: search ? search : "" })
      .send((response) => {
        if (response !== undefined) {
          dispatch(teachersGeofenceLocData(response));
        }
      });
  };
};

export const teachersGeofenceData = (val) => ({
  type: TEACHERS_GEOFENCE_DATA,
  val,
});

export const teachersGeofenceLocData = (val) => ({
  type: TEACHERS_GEOFENCE_LOC_DATA,
  val,
});

export const allocateTeachersGeofence = (
  checkedUserIds,
  searchedLocId,
  callback = null,
  successCallBack = null
) => {
  // console.log("checkingAPI",checkedArray)
  return (dispatch, getState) => {
    const { allocatedTypeContainer, startDateContainer, endDateContainer } =
      getState().attendanceNew;
    return Api.post("/attendancev2/geo-fence/allocate")
      .params({
        userIds: checkedUserIds,
        type: allocatedTypeContainer,
        startDate: startDateContainer,
        endDate: endDateContainer,
        geoFenceIds: searchedLocId,
      })
      .send((response) => {
        console.log("response", response)
        if (response?.showModal) {
          Notification.success("success", response?.message)
        }
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          // Notification.success("success", "Geo-fence allocated successfully");
          if (successCallBack) {
            successCallBack();
          }
          dispatch(getTeachersGeofenceLocations());
        }
      });
  };

};

export const storeAllocatedType = (val) => ({
  type: STORE_ALLOCATED_TYPE,
  val,
});

export const storeStartDate = (val) => ({
  type: STORE_START_DATE,
  val,
});

export const storeEndDate = (val) => ({
  type: STORE_END_DATE,
  val,
});

export const deallocateTeachersGeofence = (
  allocatedLoc,
  callback = null,
  successCallBack = null
) => {
  return (dispatch, getState) => {
    const { } = getState().attendanceNew;
    return Api.post("/attendancev2/geo-fence/de-allocate")
      .params({ geoFenceAllocationId: allocatedLoc[0] })
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          dispatch(getTeachersGeofenceLocations());
        }
      });
  };
};

export const deleteCard = (key, val) => ({
  type: DELETE_CARD,
  key,
  val,
});

export const storeUsersDashBoardData = (val) => ({
  type: STORE_USERS_DASH_BOARD_DATA,
  val,
});

export const getUsersDashBoardData = (callback = null) => {
  return (dispatch, getState) => {
    const {
      usersNameContainer,
      summaryTypeContainer,
      summaryYearContainer,
      summaryStartDateContainer,
      summaryEndDateContainer,
      workSummaryTypeContainer,
      workSummaryYearContainer,
      workSummaryStartDateCont,
      workSummaryEndDateCont,
    } = getState().attendanceNew;
    return Api.get(`/attendancev2/attendance-data-by-role`)
      .params({
        role: usersNameContainer,
        typeForSummary: summaryTypeContainer,
        yearForSummary: summaryYearContainer,
        startDateForSummary: summaryStartDateContainer,
        endDateForSummary: summaryEndDateContainer,
        typeForWorkingHrSummary: workSummaryTypeContainer,
        yearForWorkingHrSummary: workSummaryYearContainer,
        startDateForWorkingHrSummary: workSummaryStartDateCont,
        endDateForWorkingHrSummary: workSummaryEndDateCont,
      })
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          dispatch(storeUsersDashBoardData(response));
        }
      });
  };
};

export const getPrevilegedViewData = (callback = null) => {
  return (dispatch, getState) => {
    const { } = getState().attendanceNew;
    return Api.get(`/attendancev2/privilage-view`).params({}).send((response) => {
      if (callback) {
        callback();
      }
      if (response !== undefined) {
        dispatch(storePrevilegedViewData(response));
        dispatch(setIsButtonDivVisible("c1", false));
        dispatch(setIsButtonDivVisible("c2", false));
        dispatch(setIsButtonDivVisible("c3", false));
        dispatch(setIsButtonDivVisible("c4", false));
      }
    });
  };
};

export const storePrevilegedViewData = (val) => ({
  type: STORE_PREVILEGED_VIEW_DATA,
  val,
});

export const storeUsersName = (val) => ({
  type: STORE_USERS_NAME,
  val,
});

export const storeSelectedDropDownMonth = (key, val) => ({
  type: STORE_SELECTED_DROPDOWN_MONTH,
  key,
  val,
});

export const getLeavesTabData = (callback = null) => {
  return (dispatch, getState) => {
    const { specificUserId } = getState().userManagement;
    const { selectedMonthContainer, attendanceDetailsMonthContainer } = getState().attendanceNew;
    return Api.get(`/attendancev2/profile-tabs/${specificUserId}`)
      .params({
        month: selectedMonthContainer,
        attendanceDetailsMonth: attendanceDetailsMonthContainer,
      })
      .send((response) => {
        if (callback) {
          callback();
        }
        console.log("xyz", callback);
        if (response !== undefined) {
          dispatch(storeLeavesTabData(response));
        }
      });
  };
};

export const storeLeavesTabData = (val) => ({
  type: STORE_LEAVES_TAB_DATA,
  val,
});

export const storeRequestedUserId = (val) => ({
  type: STORE_REQUESTED_USER_ID,
  val,
});

export const storeTabData = (val) => ({
  type: STORE_TAB_DATA,
  val,
});

export const storeSelectedMonth = (val) => ({
  type: STORE_SELECTED_MONTH,
  val,
});

export const usersDownloadReport = (
  callback = null,
  successCallBack = null
) => {
  return (dispatch, getState) => {
    const { selectedRangeContainer, startDateContainer, endDateContainer } =
      getState().attendanceNew;
    const { specificUserId } = getState().userManagementV2;
    return Api.post("/attendancev2/download-attendance-report")
      .params({
        userId: specificUserId,
        filter: selectedRangeContainer,
        startDate: startDateContainer,
        endDate: endDateContainer,
      })
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          let fileName = response.file.split('/')[response.file.split('/').length - 1];

          Axios.get(response.file, {
            responseType: "blob",
          }).then((res) => {
            if (successCallBack) {
              successCallBack();
            }
            fileDownload(res.data, fileName);
          });
        }
      });
  };
};

export const storeSelectedRange = (val) => ({
  type: STORE_SELECTED_RANGE,
  val,
});

export const getAttendanceDashBoardData = (callBack = null) => {

  return (dispatch, getState) => {
    const { filterValues, storeMonthlySummaryMonth } = getState().attendanceNew;
    console.log("AA-filterValues?.startDate", typeof filterValues?.startDate);
    console.log('valll', filterValues, storeMonthlySummaryMonth);
    return Api.get(
      `/attendancev2/dashboard?startDate=${filterValues?.startDate != undefined ? filterValues?.startDate : moment().format('YYYY-MM-DD')}&endDate=${filterValues?.endDate != undefined ? filterValues?.endDate : moment().format('YYYY-MM-DD')}&month=${moment(storeMonthlySummaryMonth, 'MMMM').format('MMMM') != 'Invalid date' ? moment(storeMonthlySummaryMonth, 'MMMM').format('MMMM') : moment().format('MMMM')}&summaryYear=${moment(storeMonthlySummaryMonth, 'YYYY-MM').format('YYYY')}`
    )
      .params({})
      .send((response) => {
        if (callBack) {
          callBack();
        }
        console.log("callback", callBack);
        if (response !== undefined) {
          dispatch(storeAttendanceDashBoardData(response));
          // dispatch(storeSelectedMonth(selectedMonthContainer))
        }
      });
  };
};

export const storeAttendanceDashBoardData = (val) => ({
  type: STORE_ATTENDANCE_DASH_BOARD_DATA,
  val,
});

export const fetchProfileTabMonthSelect = (val) => ({
  type: STORE_PROFILETAB_MONTH_SELECT,
  val,
});

export const fetchMonthForAttDetails = (val) => ({
  type: FETCH_MONTH_FOR_ATTDETAILS,
  val,
});

export const getAttendanceProfileTabs = (callBack = null) => {
  return (dispatch, getState) => {
    const { selectedMonthContainer, attendanceDetailsMonthContainer } = getState().attendanceNew;
    console.log('logsss', selectedMonthContainer, attendanceDetailsMonthContainer)
    const { user } = getState().session;
    const params = {
      month: moment(selectedMonthContainer, 'YYYY-MM').format('MMMM'),
      attendanceByTimesheetYear: moment(selectedMonthContainer, 'YYYY-MM').format('YYYY'),
      attendanceDetailsMonth: moment(attendanceDetailsMonthContainer, 'YYYY-MM').format('MMMM'),
      attendanceByScheduleYear: moment(attendanceDetailsMonthContainer, 'YYYY-MM').format('YYYY')
    };
    console.log('valm', selectedMonthContainer)
    const userId = user.id;
    return Api.get(`/attendancev2/profile-tabs/${userId}`)
      .params(params)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined) {
          dispatch(storeAttendanceProfileTabsData(response));
        }
      });
  };
};

export const editTeacherLeaves = (params, callback = null, successCallBack = null) => {
  return (dispatch, getState) => {

    return Api.patch("/attendancev2/update-leaves-for-user").params(params).send((response) => {
      if (response !== undefined) {
        if (successCallBack) {
          successCallBack();
        }
      }
      callback();
    });
  };
};

export const editLeaveBalance = (userIds, balance, availed, selectedLeaveName, callback = null, successCallBack = null) => {
  let params = {
    userIds,
    balance,
    leaveType: selectedLeaveName,
  }
  if (availed) {
    Object.assign(params, { availed })
  }
  return (dispatch, getState) => {
    return Api.patch("/attendancev2/update-leave-balance")
      .params(params)
      .send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
          // dispatch(storeAttendanceProfileTabsData(response));
        }
      });
  };
};



// export const teacherMarlLeave = () => {
//   return (dispatch, getState) => {
//     const { specificUserId } = getState().userManagement;
//     const { user } = getState().session;

//     const userId = user.id;
//     return Api.patch("/attendancev2/mark-leave")
//       // .params({
//       //   userId: specificUserId,
//       //   leaveType: leaveType,
//       //   noOfLeaves: leaveBalance
//       // })
//       .send((response) => {
//         if (callback) {
//           callback();
//         }
//         if (response !== undefined) {
//           // if (successCallBack) {
//           //   successCallBack();
//           // }
//           // dispatch(storeAttendanceProfileTabsData(response));
//         }
//       });
//   };
// };



export const storeAttendanceProfileTabsData = (val) => ({
  type: STORE_ATTENDANCE_PROFILE_DATA,
  val,
});

export const setLocation = (callBack, successCallBack) => {
  return (dispatch, getState) => {
    const { attendanceLocation } = getState().attendanceNew;
    return Api.post("/attendancev2/clock-in-out")
      .params(attendanceLocation)
      .send((response) => {
        if (successCallBack) {
          successCallBack();
        }
        if (response !== undefined) {
          if (callBack) {
            callBack();
          }
          dispatch(getLocation(response));
          dispatch(getAttendanceDashBoardData());
        }
      });
  };
};

export const getLocation = (val1, val2) => ({
  type: SET_LOCATION,
  val1,
  val2,
});

export const setMarkAttendance = (key, val) => ({
  type: SET_MARK_ATTENDANCE,
  key,
  val,
});

export const markAttendance = (callBack = null, successCallback) => {
  return (dispatch, getState) => {
    const { markAttendanceObj } = getState().attendanceNew;
    dispatch(setLoader("modalClockLoader", true));
    return Api.post("/attendancev2/request").params(markAttendanceObj).send((response) => {
      if (response !== undefined) {
        successCallback()
        dispatch(setLoader("modalClockLoader", false));
        // dispatch(getAttendanceDashBoardData());
        // dispatch(getAttendanceProfileTabs());
      }
      callBack();
    });
  };
};

export const setLeaveRequest = (key, val) => ({
  type: SET_LEAVE_REQUEST,
  key,
  val,
});

export const setEditLeave = (key, val) => ({
  type: SET_EDIT_LEAVE,
  key,
  val,
});

export const selectEditLeaveCardId = (id) => ({
  type: EDIT_LEAVE_CARD_ID,
  id,
});

export const editLeaveModal = (callBack = null) => {
  return (dispatch, getState) => {
    const { editLeaveMeta, editLeaveCardID } = getState().attendanceNew;
    dispatch(setLoader("modalEditLeave", true));
    editLeaveMeta["id"] = editLeaveCardID;
    console.log("id", editLeaveCardID);
    return Api.post("/attendancev2/edit-leave")
      .params(editLeaveMeta)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined) {
          dispatch(setLoader("modalEditLeave", false));
        }
      });
  };
};

export const leaveRequest = (callBack = null, successCallBack = null, fromwhere) => {
  return (dispatch, getState) => {

    const { leaveRequestObj } = getState().attendanceNew;
    dispatch(setLoader("modalLeaveReq", true));
    return Api.post("/attendancev2/request")
      .params(leaveRequestObj)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response != undefined && response?.show?.type != 'error') {
          if (successCallBack) {
            successCallBack();
          }
          dispatch(setLoader("modalLeaveReq", false));
          { fromWhere == 'Attendance Home' ? null : dispatch(getAttendanceProfileTabs()) }
        }
      });
  };
};

export const postMarkLeave = (callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {
    const { specificUserId } = getState().userManagement;
    const { leaveRequestObj, storeNoOfDay } = getState().attendanceNew;
    return Api.post("/attendancev2/request")
      .params({
        requestType: leaveRequestObj?.requestType,
        leaveType: leaveRequestObj?.leaveType,
        startDate: leaveRequestObj?.startDate,
        endDate: leaveRequestObj?.endDate,
        noOfdays: storeNoOfDay,
        reason: leaveRequestObj?.reason,
        userId: specificUserId,
      })
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined) {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };
};

export const selectedLeaves = (val) => ({
  type: SELECTED_LEAVES,
  val,
});
export const selectedAttendance = (val) => ({
  type: SELECTED_ATTENDANCE,
  val,
});

export const setLoader = (key, val) => ({
  type: SET_LOADER,
  key,
  val,
});

export const fetchGeoFenceLocationList = (callback) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/geo-fence`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateGeoFenceList(response));
        }
      });
    });
  };
};

export const updateGeoFenceList = (data) => ({
  type: UPDATE_GEO_FENCE_LIST,
  data,
});

export const updateGeometryLocation = (object) => ({
  type: UPDATE_GEOMETRY_LOCATION,
  object,
});

export const updateRadius = (data) => ({
  type: UPDATE_RADIUS,
  data,
});

export const updateSearchLocation = (data) => ({
  type: UPDATE_SEARCH_LOCATION,
  data,
});

export const fetchGeoFenceAlloctionListByRole = (search, callback) => {
  return (dispatch, getState) => {
    const { roleType } = getState().attendanceNew;
    let role = "";
    if (roleType == "Teachers") {
      role = "Teacher";
    } else if (roleType == "Operations") {
      role = "Operation";
    } else {
      role = roleType;
    }

    const url = `/attendancev2/geo-fence-allocation-list?name=${search ? search : ""
      }&role=${role}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateAttendanceData("geoFenceAllocationList", response));
        }
      });
    });
  };
};

export const updateAttendanceData = (key, value) => ({
  type: UPDATE_ATTENDANCE_DATA,
  key,
  value,
});

export const fetchLocationList = (search, callback) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/autocomplete/geo-fence?search=${search ? search : ""
      }`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateLocationList(response));
        }
      });
    });
  };
};

export const updateLocationList = (data) => ({
  type: UPDATE_LOCATION_LIST,
  data,
});

export const fetchUserList = (search, callback) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/autocomplete/user?search=${search ? search : ""
      }`;
    return new Promise((resolve, reject) => {
      Api.get(url).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          dispatch(updateUserList(response));
        }
      });
    });
  };
};

export const updateUserList = (data) => ({
  type: UPDATE_USER_LIST,
  data,
});

export const postAllocateGeoLocation = (
  object,
  callback = null,
  successCallback = null
) => {
  return (dispatch, getState) => {
    console.log("first", object, callback, successCallback);
    const url = `/attendancev2/geo-fence/allocate`;
    return new Promise((resolve, reject) => {
      Api.post(url, object).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response != undefined) {
          if (successCallback && response.show.type == 'success') {
            successCallback(response);
          }
        }
      });
    });
  };
};

export const postGeoLocation = (object, callback, successCallback) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/geo-fence`;
    return new Promise((resolve, reject) => {
      Api.post(url, object).send(async (response, error) => {
        if (callback) {
          callback();
        }
        if (response) {
          console.log(response);
          successCallback();
        } else {
          callback();
        }
      });
    });
  };
};

export const locationCardData = (value) => ({
  type: LOCATION_CARD_DATA,
  value,
});

export const deleteLocationCard = (callBack = null) => {
  return (dispatch, getState) => {
    const { locationCardId } = getState().attendanceNew;
    const url = `/attendancev2/geo-fence?geoFenceId=${locationCardId}`;
    dispatch(setDeleteLoader(true));
    return (
      Api.delete(url)
        // .params({search:search?search:''})
        .send((response) => {
          if (callBack) {
            callBack();
          }
          if (response !== undefined) {
            dispatch(fetchGeoFenceLocationList());
            console.log("response", response);
          }
          dispatch(setDeleteLoader(false));
        })
    );
  };
};

export const deleteLeavesReqCard = (callBack = null) => {
  return (dispatch, getState) => {
    const { locationCardId } = getState().attendanceNew;
    const url = `/attendancev2/geo-fence?geoFenceId=${locationCardId}`;
    dispatch(setDeleteLoader(true));
    return (
      Api.delete(url)
        // .params({search:search?search:''})
        .send((response) => {
          if (callBack) {
            callBack();
          }
          if (response !== undefined) {
            dispatch(fetchGeoFenceLocationList());
            console.log("response", response);
          }
          dispatch(setDeleteLoader(false));
        })
    );
  };
};

export const attendanceByRole = (
  callBack = null,
  month = null,
  search = null
) => {
  return (dispatch, getState) => {
    // const { storeClasscardId, filter, pageNumber } = getState().attendanceNew;
    const { usersNameContainer, storeClasscardId } = getState().attendanceNew;
    const { isFromClassRoomDetails, selectedSection } = getState().classRoom
    const { user } = getState().session;
    const params = {
      standardId: isFromClassRoomDetails ? selectedSection?.id : storeClasscardId,
      role: isFromClassRoomDetails ? "Student" : usersNameContainer,
      month: month ? moment(month, 'YYYY-MM').format('MMMM') : moment().format("MMMM"),
      search: search ? search : null,
      year: month ? moment(month, 'YYYY-MM').format('YYYY') : moment().format("YYYY"),
    };
    const userRole = user.role;
    return Api.get(`/attendancev2/monthly-attendance-by-role`)
      .params(params)
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response !== undefined) {
          dispatch(AttendanceTableData(response));
        }
      });
  };
};

export const storeUserSummaryStartDate = (val) => ({
  type: STORE_USER_SUMMARY_START_DATE,
  val,
});

export const storeUserSummaryEndDate = (val) => ({
  type: STORE_USER_SUMMARY_END_DATE,
  val,
});

export const storeSummaryType = (val) => ({
  type: STORE_SUMMARY_TYPE,
  val,
});

export const storeSummaryYear = (val) => ({
  type: STORE_SUMMARY_YEAR,
  val,
});

export const storeWorkSummaryType = (val) => ({
  type: STORE_WORK_SUMMARY_TYPE,
  val,
});

export const storeWorkSummaryYear = (val) => ({
  type: STORE_WORK_SUMMARY_YEAR,
  val,
});

export const storeWorkSummaryStartDate = (val) => ({
  type: STORE_WORK_SUMMARY_START_DATE,
  val,
});
export const storeWorkSummaryEndDate = (val) => ({
  type: STORE_WORK_SUMMARY_END_DATE,
  val,
});

export const AttendanceTableData = (val) => ({
  type: ATTENDANCE_TABLE_DATA,
  val,
});

export const deleteLeaveReq = (callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {
    const { editLeaveCardID } = getState().attendanceNew;
    const url = `/attendancev2/delete-request?commonId=${editLeaveCardID}`;
    dispatch(setLoader("deleteEditLeave", true));
    return Api.delete(url).send((response) => {
      if (callBack) {
        callBack();
      }
      if (response !== undefined) {
        if (successCallBack) {
          successCallBack();
        }
      }
      dispatch(setLoader("deleteEditLeave", false));
    });
  };
};

export const setLocationCardTitle = (value) => ({
  type: SET_LOCATION_CARD_TITLE,
  value,
});

export const setFilterValues = (key, val) => ({
  type: SET_FILTER_VALUES,
  key,
  val,
});
export const storeUsersSummaryGraphData = (val) => ({
  type: STORE_USERS_SUMMARY_GRAPH_DATA,
  val,
});

export const storeAttendanceDetailsMonth = (val) => ({
  type: STORE_ATTENDANCE_DETAILS_MONTH,
  val,
});

export const attendanceDetailsDownloadReport = (
  callback = null,
  successCallBack = null
) => {
  return (dispatch, getState) => {
    const { attendanceDetailsMonthContainer } = getState().attendanceNew;
    const { specificUserId } = getState().userManagement;
    return Api.post(
      `/attendancev2/download-attendance-by-occurance/${specificUserId}`
    )
      .params({
        attendanceDetailsMonth: attendanceDetailsMonthContainer,
      })
      .send((response) => {
        if (response !== undefined) {
          let fileName = response.file.split('/')[response.file.split('/').length - 1];

          Axios.get(response.file, {
            responseType: "blob",
          }).then((res) => {
            fileDownload(res.data, fileName);
          });
        }
      });
  };
};

export const attendanceDetailsModalData = (val) => ({
  type: ATTENDANCE_DETAILS_MODAL_DATA,
  val,
});

export const setBackToPrivilegedView = (val) => ({
  type: SET_BACK_TO_PRIVILEGED_VIEW,
  val,
});

export const storeCheck = (val) => ({
  type: CHECK_STORE,
  val,
});

export const uncheckedValue = (val) => ({
  type: UNCHECKED_VALUE,
  val,
});

export const setClickedOnUserCard = (val) => ({
  type: SET_CLICKED_ON_USER_CARD,
  val,
});

export const setIsButtonDivVisible = (key, val) => ({
  type: SET_BUTTON_DIV_VISIBLE,
  key,
  val
});
export const idSelected = (val) => ({
  type: ID_SELECTED,
  val
});
export const setRequestedIndex = (val) => ({
  type: SET_REQUESTED_INDEX,
  val
})
export const requestViewAllClicked = (val) => ({
  type: REQUEST_VIEW_ALL_CLICKED,
  val
})
export const storeRequestViewAllPage = (val) => ({
  type: STORE_REQUEST_VIEW_ALL_PAGE,
  val
})

export const storeStudStat = (res) => ({
  type: STORE_STUD_STATS,
  res
})

export const FetchStudentAttStat = () => {
  return (dispatch, getState) => {
    const { OccurenceId } = getState().attendanceNew;
    let params = {
      occuranceId: OccurenceId
    }
    return Api.get(`/attendancev2/students-attendance-in-occurance`).params(params).send((response) => {
      if (response !== undefined) {
        console.log('ress', response)
        dispatch(storeStudStat(response))
      }
    });
  };
};

export const storeOccurenceId = (val) => ({
  type: STORE_OCC_ID,
  val
})

export const setFromUsersGeofence = (bool) => ({
  type: SET_FROM_USERS_GEO_FENCE,
  bool
})

export const storeEditedGeoFenceData = (val) => ({
  type: STORE_EDITED_GEO_FENCE_DATA,
  val
})

export const storeGeoFenceUserId = (val) => ({
  type: STORE_GEO_FENCE_USERID,
  val
})

export const postSaveEditedGeoFence = (callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {
    const { editedGeoFenceData, userIdData } = getState().attendanceNew;
    return Api.post("/attendancev2/geo-fence/edit-geo-allocation")
      .params({
        userId: userIdData.toString(),
        geoFences: editedGeoFenceData
      })
      .send((response) => {
        if (callBack) {
          callBack();
        }
        if (response != undefined && response?.show?.type != 'error') {
          if (successCallBack) {
            successCallBack();
          }
        }
      });
  };
};

export const getAllStudentStandards = () => {
  return (dispatch, getState) => {
    return Api.get(`/attendancev2/get-all-standards`)
      .params({})
      .send((response) => {
        if (response !== undefined) {
          dispatch(fetchStandards(response));
        }
      });
  };
};

export const fetchStandards = (val) => ({
  type: FETCH_STANDARDS,
  val
})

export const storeSelectedClass = (val) => ({
  type: STORE_SELECTED_CLASS,
  val
})

export const storeUserDetails = (val) => ({
  type: STORE_USER_DETAILS,
  val
})

export const storeNumberOfDays = (val) => ({
  type: STORE_NUMBER_OF_DAYS,
  val
})
export const setSameMinimumAtt = (res) => ({
  type: STORE_SAME_MINIMUM_ATT,
  res
})
export const setSameHalfAtt = (res) => ({
  type: SET_SAME_HALF_ATT,
  res
})
export const setSameMaximumAtt = (res) => ({
  type: SET_SAME_MAXIMUM_ATT,
  res
})

export const getCaluclatedDates = () => {
  return (dispatch, getState) => {
    const { user } = getState().session;
    const { leaveRequestObj } = getState().attendanceNew;
    console.log("AA-usersss", leaveRequestObj);
    return Api.get(`/attendancev2/calculate-dates`)
      .params({
        userId: user?.id,
        startDate: leaveRequestObj?.startDate,
        endDate: leaveRequestObj?.endDate
      })
      .send((response) => {
        if (response !== undefined) {
          console.log("AA-responsee", response);
          dispatch(storeNumberOfDays(response))
        }
      });
  };
};

export const getWebClockInData = (res) => ({
  type: GET_WEB_CLOCK_IN_DATA,
  res
})

export const getWebClockIn = (storeUserId) => {
  console.log("AA-storeUserId", storeUserId);
  return (dispatch, getState) => {
    return Api.get(`/attendancev2/user-webclockin-settings`)
      .params({
        userId: storeUserId,
      })
      .send((response) => {
        if (response !== undefined) {
          dispatch(getWebClockInData(response))
        }
      });
  };
};

export const getRemainderData = (res) => ({
  type: GET_REMAINDER_DATA,
  res
})

export const getRemainderSetting = (storeUserId) => {
  return (dispatch, getState) => {
    return Api.get(`/attendancev2/attendance-reminder-settings`)
      .send((response) => {
        if (response !== undefined) {
          dispatch(getRemainderData(response))
        }
      });
  };
};

export const postWebClockIn = (isSwitch, storeUserId, type, callBack) => {

  return (dispatch, getState) => {
    const { storeClockInStartDate, storeClockInEndDate, storeClockInSwitch } = getState().attendanceNew;

    return Api.post("/attendancev2/user-webclockin-settings").params({
      enabled: isSwitch,
      userId: storeUserId,
      settings: {
        duration: type,
        startDate: storeClockInStartDate ? moment(storeClockInStartDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        endDate: storeClockInEndDate ? moment(storeClockInEndDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
    })
      .send((response) => {
        if (callBack) {
          callBack()
        }
        if (response !== undefined) {
          console.log("response", response);
        }
      });
  };
};

export const setClockInStartDate = (res) => ({
  type: SET_CLOCK_IN_START_DATE,
  res
})
export const setClockInEndDate = (res) => ({
  type: SET_CLOCK_IN_END_DATE,
  res
})
export const setClockInSwitch = (res) => ({
  type: SET_CLOCK_IN_SWITCH,
  res
})

export const studentsList = res => ({
  type: STUDENTS_LIST,
  res
})


export const fetchStudents = (params, callBack = null) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/getAllDetailsTable`;
    return (
      Api.get(url).params(params).send((response) => {
        if (response !== undefined) {
          dispatch(studentsList(response));
        }
        callBack();
      })
    );
  };
};

export const deleteHolidayData = (holidayDeleteId, callBack) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/holiday?id=${holidayDeleteId}`;
    return (
      Api.delete(url).send((response) => {
        if (response !== undefined) {
          console.log("response", response);
        }
        callBack();
      })
    );
  };
};

export const editHolidayData = (params, callBack = null, successCallBack) => {
  return (dispatch, getState) => {

    return Api.patch("/attendancev2/holiday").params(params).send((response) => {
      if (response !== undefined && response?.show?.type == 'success') {
        if (successCallBack) {
          successCallBack();
        }
      }
      if (callBack) {
        callBack();
      }
    });
  }
};

export const postEditApprovers = (params, callback, successCallback) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/editApprovers`;
    const { user } = getState().session;
    return Api.post(url).params(params).send((response) => {
      if (response && response.show.type === "success") {
        successCallback();
      }
      callback();
    });
  }
};

export const postWebClockInDetails = (params, callback, successCallback) => {
  return (dispatch, getState) => {
    const url = `/attendancev2/user-webclockin-settings`;
    const { user } = getState().session;
    return Api.post(url).params(params).send((response) => {
      if (response && response.show.type === "success") {
        successCallback();
      }
      callback();
    });
  };
};

export const saveEditedGeoFence = (params, callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {

    return Api.post("/attendancev2/geo-fence/edit-geo-allocation").params(params).send((response) => {
      if (callBack) {
        callBack();
      }
      if (response != undefined && response?.show?.type != 'error') {
        if (successCallBack) {
          successCallBack();
        }
      }
    });
  };
};