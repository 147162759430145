import { Button, Modal } from 'antd';
import React, { useState } from 'react'
import courseImage from '../../../Assets/dashBoard/ill-courses.png'
import moment from 'moment';

const Attendancemodal = ({isAttendanceModal,setisAttendance,allData}) => {
console.log("AA-allData",allData);
  const showModal = () => {
    setisAttendance(true);
  };
  const handleOk = () => {
    setisAttendance(false);
  };
  const handleCancel = () => {
    setisAttendance(false);
  };
  return (
    <>
      <Modal 
      className="modal-round-corner"
      open={isAttendanceModal} 
      onOk={handleOk} 
      footer={null}
      onCancel={handleCancel}>
        <div style={{display:"flex",justifyContent:"center" ,fontSize:"21px",fontWeight:"bold",marginTop:"15px"}}>Request Details</div>
        <div style={{width:"100%",height:"150px",backgroundColor:"#FAFAFA",marginTop:"15px"}}>
            <div style={{display:"flex",marginTop:"20px"}}>
                <div style={{marginLeft:"20px",marginTop:"20px",fontSize:"13px",color:"#636363"}}>Date</div>
                <div style={{marginLeft:"60px",marginTop:"20px",fontSize:"13px"}}>: <h style={{marginLeft:"20px"}}>{moment(allData?.meta?.date).format('DD-MM-YYYY')}</h></div>
            </div>
            <div style={{display:"flex"}}>
                <div style={{marginLeft:"20px",marginTop:"5px",fontSize:"13px",color:"#636363"}}>time</div>
                <div style={{marginLeft:"60px",marginTop:"5px",fontSize:"13px"}}>: <h style={{marginLeft:"20px"}}>{moment(allData?.meta?.clockIn,'h:mm a').format('h:mm a')}{" "}{" "}:{" "}{" "}{moment(allData?.meta?.clockOut,'h:mm a').format('h:mm a')}</h></div>
            </div>
            <div style={{display:"flex"}}>
                <div style={{marginLeft:"20px",marginTop:"5px",fontSize:"13px",color:"#636363"}}>Total hours</div>
                <div style={{marginLeft:"20px",marginTop:"5px",fontSize:"13px"}}>: <h style={{marginLeft:"20px"}}>{allData?.meta?.totalHours }</h></div>
            </div>
            <div style={{display:"flex"}}>
                <div style={{marginLeft:"20px",marginTop:"5px",fontSize:"13px",color:"#636363"}}>Reason(s)</div>
                <div style={{marginLeft:"30px",marginTop:"5px",fontSize:"13px"}}>: <h style={{marginLeft:"20px"}}>{allData?.meta?.reason}</h></div>
            </div>
        </div>
        <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
        <Button 
            style={{
                borderRadius: "19px",
                width: "90px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#FFFFFF",
                color: "#636363",
                fontSize:"13px",
             
            }}
            onClick={handleCancel}
            >CLOSE</Button>
        </div>

      </Modal>
    </>
  );
};
export default Attendancemodal;