import { LoadingOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import Lottie from 'react-lottie';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import loaderJson from "../Assets/loader.json"


const facts = [
  "Tardigrades endure extreme conditions, even in space travel",
  "Pluto, smaller than Earth's moon, was once a planet",
  "Astronauts grow taller in space due to spine elongation",
  "Lobsters don't age, they grow stronger and fertile",
  "The Milky Way speeds at 1.3 million mph",
  "Sound travels fastest through solid materials",
  "Astronaut footprints on the Moon endure millions of years",
  "Neutron star matter is incredibly dense, weighing billions",
  "Grazing cows align with Earth's magnetic field",
  "Sun's energy reaches Earth in 8 minutes, 20 seconds",
  "Human eyes perceive up to 10 million colors",
  "Octopuses have three hearts and copper-based blood",
  "Mantis shrimp possess the animal kingdom's complex eyes",
  "A teaspoon of neutron star weighs 6 billion tons",
  "Chickens are the closest living relatives to dinosaurs",
  "Amazon rainforest oxygen impacts global weather patterns",
  "A gallon of water outweighs a gallon of helium"
]

function PageDataLoader({ visible = false, widthOfMenu, modalLoader, loader = false, showSideMenu }) {

  const rndInt = Math.floor(Math.random() * facts.length)

  const options = {
    loop: true,
    autoplay: true,
    animationData: loaderJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      style={
        visible
          ? {
            // marginLeft:`${modalLoader ? "-140px" : collapsed ? "70px" : "220px"}`,
            width: `calc(100vw - ${widthOfMenu})`,
            height: "100vh",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 50,
            backgroundColor: "rgba(255,255,255,0.60)"

          }
          : {
            display: "none",
          }
      }
      // className="blur-background r-c-fe"
    >
      <div className="r-c-c-c" style={{ marginLeft: showSideMenu ? widthOfMenu : 0 }}>
        {/* <div style={{ position: 'relative' }}>  */}
        {/* <div style={{height:"70%",width:"70%",backgroundColor:"#FFFFFF",borderRadius:"20px",boxShadow:'rgba(0, 0, 0, 0.1) 0px 0px 5px'}}> */}
        <div style={{height:"25%",width:"25%",backgroundColor:"transparent",borderRadius:"20px"}}>
          <Lottie
            className="player"
            options={options}
            height={'100%'}
            width={'100%'}
          />
          </div>
          {/* <div
            style={{
              position: 'absolute',  
              top: '42.5%',           
              left: '50%',          
              transform: 'translate(-50%, -50%)',  
              color: "#ffffff",
              fontSize: 17,
              fontWeight: 500,
              width:500,
              textAlign: 'center'
            }}
          >
           {facts[rndInt]}
          </div> */}
        {/* </div> */}
      </div>

      {/* <div
        className="r-c-c"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: 60,
          height: 60,
          borderRadius: 30,
          // position:"absolute",
          // left:0,
          // right:0,
          // top:0,
          // bottom:0
        }}
      >
        
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} />
      </div> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { widthOfMenu, showSideMenu } = state.layout;
  return {
    widthOfMenu,
    showSideMenu
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageDataLoader);