import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentIcon, updateSetType, currentTypes, fetchBroadcastSession, setStoreStreamId } from 'actions/digitalLibrary'
import { SquareSubIcon } from 'components/subIcon'
import { Checkbox, Image, Typography } from 'antd'
import lectureNotes from 'Assets/digitalLibrary/lectureNotes.svg'
import videos from 'Assets/digitalLibrary/videos.svg'
import questionBank from 'Assets/digitalLibrary/question-bank.svg'
// import Group_11644 from 'Assets/digitalLibrary/Group_11644.svg'
import Group_11644 from 'Assets/digitalLibrary/Group_11644.svg'
import Group_11638 from 'Assets/digitalLibrary/Group_11638.svg'
import Icon_awesome_calendar_day from 'Assets/digitalLibrary/Icon_awesome_calendar_day.svg'
import moment from 'moment'
import { getRecordings } from 'actions/broadcast'
import { setCardData } from 'actions/schedule'

const BroadCastCard = ({SetIsRecordingsModalVisible, isRecordingsModalVisible, subjectName, teacherName, date, startTime, endTime, image, fetchBroadcastSession, storeBroadcastRecordings, getRecordings, streamId, sections, storeCheckValue, setStoreStream, setStoreStreamId, recordingsData, setCardData, item }) => {
    const [sectionData, setSectionData] = useState(sections)
    const [storeSectionIndex, setStoreSectionIndex] = useState(null)
    const [storeTopicIndex, setStoreTopicIndex] = useState(null)
    useEffect(() => {
        for (let i = 0; i < sections?.length; i++) {
            setStoreSectionIndex(i)
            for (let j = 0; j < sections[i]?.topics?.length; j++) {
                setStoreTopicIndex(j)
            }
        }
    }, [])

    return (
        <div>

            <div className='broadcast-card cursor-pointer ' style={{ padding: "20px",marginRight:"15px" }} onClick={() => {
                setCardData(item)
                getRecordings(streamId, () => {
                    SetIsRecordingsModalVisible(true);
                })

            }}>
                <div style={{  display: "flex", gap: 20 }}>
                    <SquareSubIcon icon={image ? image : ''} name={subjectName ? subjectName : ''} size={40} />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "100px" }} className="text-heading-library">{subjectName}</Typography.Text>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: "12px", color: "#636363", fontWeight: "bold", width: "100px" }}>{teacherName}</Typography.Text>
                        </div>
                        <div style={{ width: "40px", marginTop: "10px" }}>
                            <Image preview={false} src={Group_11644} ></Image>
                        </div>
                    </div>
                </div>


                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {/* <div ><span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.notesCount ? item?.notesCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Notes</span> <span style={{ fontSize: "13px", color: "#AEAEAE" }}> | </span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.videoCount ? item?.videoCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Videos</span> <span style={{ fontSize: "13px", color: "#AEAEAE" }}> | </span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.quesBankCount ? item?.quesBankCount : 0}  </span> <span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Question banks</span></div> */}

                </div>
                {/* {storeCheckValue == true ?
                <div onChange={(e) => {
                    setStoreStreamId(streamId)

                    const updateTopics = [...sectionData];
                    updateTopics[storeSectionIndex].topics[storeTopicIndex].fileUrl = streamId;
                    updateTopics[storeSectionIndex].topics[storeTopicIndex].file = subjectName;

                    setSectionData(updateTopics);
                }}><Checkbox onClick={(e) => {
                    e.stopPropagation();
                    setStoreStream(e.target.checked)
                    getRecordings(streamId, () => {})
                }} /></div> : null} */}

                {/* <div className="all-comp" style={{ marginLeft: "-20px" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ marginTop: "5px", marginLeft: "10px" }} >
                        <Typography.Text ellipsis={{ tooltip: true }} style={{width:"100px"}} className="text-heading-library">{subjectName}</Typography.Text>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: "12px", color: "#636363", fontWeight: "bold",width:"100px" }}>{teacherName}</Typography.Text>

                    </div>
                    <div style={{ marginTop: "10px", width: "40px", marginTop: "20px" }}>
                        <Image preview={false} src={Group_11644} ></Image>
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: "15px", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                        <Image preview={false} style={{ width: "13px" }} src={Icon_awesome_calendar_day} />
                        <div style={{ fontSize: "10px", color: "#636363", fontWeight: "bold", marginLeft: "3px", marginTop: "6px" }}>{moment(date).format('DD-MM-YYYY')}</div>
                    </div>
                    <div style={{ display: "flex", marginRight: "20px" }} >
                        <Image preview={false} style={{ width: "20px" }} src={Group_11638} />
                        <div style={{ fontSize: "10px", color: "#636363", fontWeight: "bold", marginLeft: "3px", marginTop: "5px" }}>{moment(startTime, 'hh:mm:ss').format('hh:mm')}{" "}-{" "}{moment(endTime, 'hh:mm:ss').format('hh:mm')}</div>
                    </div>
                </div>




            </div> */}

            </div>
            <div style={{ display: "flex", marginTop: "5px", width: "100%", justifyContent: "space-between" ,paddingLeft:"15px",paddingRight:"35px"}}>
                <div style={{ display: "flex" }}>
                    <Image preview={false} style={{ width: "13px" }} src={Icon_awesome_calendar_day} />
                    <div style={{ fontSize: "10px", color: "#636363", fontWeight: "bold", marginLeft: "3px", marginTop: "6px" }}>{moment(date).format('DD-MM-YYYY')}</div>
                </div>
                <div style={{ display: "flex" }} >
                    {console.log("endTime",endTime)}
                    <Image preview={false} style={{ width: "20px" }} src={Group_11638} />
                    <div style={{ fontSize: "10px", color: "#636363", fontWeight: "bold", marginLeft: "3px", marginTop: "5px" }}>{moment(startTime, 'hh:mm:ss').format('hh:mm')}{" "}-{" "}{moment(endTime, 'hh:mm:ss').format('hh:mm')}</div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        addedTeacher,
        storeBroadcastRecordings,
        storeCheckValue

    } = state.digitalLibrary;
    const { user } = state.session
    return {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        addedTeacher,
        user,
        storeBroadcastRecordings,
        storeCheckValue

    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchClassLibraryData: () => dispatch(fetchClassLibraryData()),
    updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
    updateCurrentIcon: (images) => dispatch(updateCurrentIcon(images)),
    updateSetType: (type) => dispatch(updateSetType(type)),
    currentTypes: (type) => dispatch(currentTypes(type)),
    fetchBroadcastSession: () => dispatch(fetchBroadcastSession()),
    getRecordings: (streamId, callback) => dispatch(getRecordings(streamId, callback)),
    setStoreStreamId: (value) => dispatch(setStoreStreamId(value)),
    setCardData: (value) => dispatch(setCardData(value))


});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(BroadCastCard);