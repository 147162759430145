import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import dropDownIcon from "../../../Assets/aulasMarket/ic-arrow-head-down.svg"
import dropUpIcon from "../../../Assets/aulasMarket/ic-arrow-head-up.svg"
import { Button } from 'components/Button';
import { Dropdown, Menu, Rate, Progress, Modal, Tooltip, Col, Collapse, Divider, Row, AutoComplete } from 'antd'
import checkIcon from "../../../Assets/aulasMarket/ic-check-filled.svg";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import { Api } from 'services'
import noImage from "../../../Assets/aulasMarket/no-image.svg"
import NoData from "../../../Assets/aulasMarket/empty-courses.png";
import { getCourseDashBoardData, setMyCourseHome, setQuizFlowCount, getCourseDashBoardDataForTeacher } from 'actions/studentMarketPlace'
import AddRatingModal from 'pages/aulasMarket/addRatingModal'
import RelatedCoursesCompo from './relatedCoursesCompo'
import PageHeader from 'components/pageHeader';
import { SearchOutlined } from '@ant-design/icons';
import { showMenu } from 'actions/layout';
const defaultCourseImage = require('Assets/default_course-img.svg').default;


function CourseHome({ myCoursePage, myCoursePageForTeacher, showMenu, getCourseDashBoardData, setQuizFlowCount, user, getCourseDashBoardDataForTeacher }) {
    console.log('UD', user);
    const [pageLoader, togglePageLoader] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const history = useHistory();
    const [freeText, setFreeText] = useState(null);
    const [isRatingModalVisible, setIsRatingModalVisible] = useState(false);
    const [filterParam, setFilterParam] = useState({
        period: null,
        mode: null,
        free: null,
    })
    const classArr = [{ label: "All", value: null }, { label: "Ongoing", value: "ongoing" }, { label: "Upcoming", value: "upcoming" }, { label: "Completed", value: "completed" }]
    const typeArr = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Offline", value: "offline" }, { label: "Hybrid", value: "hybrid" }]
    const modeArr = [{ label: "All", value: null }, { label: "Free", value: "free" }]

    const [userRating, setUserRating] = useState({
        ratingValue: null,
        ratingExplanation: null,
        type: "",
        id: null,
    });
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {

        showMenu(true);
        togglePageLoader(true);
        if (user?.role?.includes('Teacher')) {
            getCourseDashBoardDataForTeacher('', () => { togglePageLoader(false) });
        } else {
            getCourseDashBoardData('', () => { togglePageLoader(false) });
        }
        setQuizFlowCount(0);
    }, [])

    useEffect(() => {
        // togglePageLoader(true);  
        if (user?.role?.includes('Teacher')) {
            getCourseDashBoardDataForTeacher(filterParam, () => { togglePageLoader(false) });
        } else {
            getCourseDashBoardData(filterParam, () => { togglePageLoader(false) });
        }

    }, [searchValue])

    const handleUserRating = (val, key, courseType, id) => {
        setUserRating({
            ...userRating,
            ratingValue: val,
            ratingExplanation: key,
            type: courseType,
            id: id,
        });
    }
    const handleFilter = (period, mode, free, search) => {
        setSearchValue(search)
        setFilterParam({
            period: period,
            mode: mode,
            free: free,
            search: search,
        })
    }

    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible);
    };

    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '', fontSize: 14, fontWeight: 600, marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }

    // const ClassFilter = () => {
    //     const handleClassToggle = (val) => {
    //         setFilterParam({
    //             ...filterParam,
    //             period: val,
    //         })
    //     }
    //     return (
    //         <div className='r-c-c-fs' >
    //             <FilterTitleName filterName="Classes" />
    //             <div style={{ display: "flex", flexWrap: "wrap", }}>
    //                 {classArr?.map((item) => (
    //                     <div style={{
    //                         borderRadius: "18px",
    //                         backgroundColor: filterParam?.period === item?.value ? "#1089FF" : '#FFFFFF',
    //                         color: filterParam?.period === item?.value ? '#FFFFFF' : "#636363",
    //                         border: filterParam?.period === item?.value ? 'none' : '1px solid #E6E6E6',
    //                         padding: "5px 10px",
    //                         marginRight: "10px",
    //                         marginBottom: "10px",
    //                         fontSize: 12,
    //                         cursor: "pointer",

    //                     }}
    //                         onClick={() => {
    //                             handleClassToggle(item?.value);
    //                         }}
    //                     >{item?.label}</div>
    //                 ))}
    //             </div>
    //         </div>
    //     )
    // }
    const TypeFilter = () => {
        const handleTypeToggle = (val) => {
            setFilterParam({
                ...filterParam,
                mode: val
            })
        }
        return (
            <div className=''>
                <FilterTitleName filterName="Type" />
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {typeArr?.map((item) => (
                        <div style={{
                            borderRadius: "18px",
                            backgroundColor: filterParam?.mode === item?.value ? "#1089FF" : '#FFFFFF',
                            color: filterParam?.mode === item?.value ? '#FFFFFF' : "#636363",
                            border: filterParam?.mode === item?.value ? 'none' : '1px solid #E6E6E6',
                            padding: "5px 10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            fontSize: 12,
                            cursor: "pointer",
                        }}
                            onClick={() => {
                                handleTypeToggle(item?.value);
                            }}
                        >{item?.label}</div>
                    ))}
                </div>
            </div>
        )
    }
    const ModeFilter = () => {
        const handleModeToggle = (val) => {
            setFreeText(val)
            setFilterParam({
                ...filterParam,
                free: val === "free" ? 1 : val,
            })
        }
        return (
            <div className=''>
                <FilterTitleName filterName="Mode" />
                <div style={{ display: "flex", flexWrap: "wrap", }}>
                    {modeArr?.map((item) => (
                        <div style={{
                            borderRadius: "18px",
                            backgroundColor: freeText === item?.value ? "#1089FF" : '#FFFFFF',
                            color: freeText === item?.value ? '#FFFFFF' : "#636363",
                            border: freeText === item?.value ? 'none' : '1px solid #E6E6E6',
                            padding: "5px 10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                            fontSize: 12,
                            cursor: "pointer",
                        }}
                            onClick={() => {
                                handleModeToggle(item?.value)
                            }}
                        >{item?.label}</div>
                    ))}
                </div>
            </div>
        )
    }

    const filterDropDownMenu = (
        <Menu visible={dropdownVisible} className='filterParent' style={{ width: "100%", padding: "20px", borderRadius: "20px" }}>
            {/* <ClassFilter /> */}
            <TypeFilter />
            <ModeFilter />
            <div style={{ borderBottom: '2px solid #E6E6E6', marginTop: "5px" }}></div>
            <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
                <div style={{ color: "#1089FF", fontWeight: 600, fontSize: "16px", marginTop: "5px", cursor: "pointer" }}
                    onClick={() => {

                        togglePageLoader(true);
                        getCourseDashBoardData('', () => {
                            setFilterParam(
                                {
                                    period: null,
                                    mode: null,
                                    free: 'All',
                                }
                            )
            setFreeText(null)

                            togglePageLoader(false);
                        });
                    }}
                >Reset</div>
                <Button style={{ color: "#fff", backgroundColor: '#1089FF', border: "none", borderRadius: "5px" }} onClick={() => {
                    togglePageLoader(true);
                    getCourseDashBoardData(filterParam, () => {
                        togglePageLoader(false);
                    });
                }}>Apply</Button>
            </div>
        </Menu>
    );

    const LiveCourse = ({ liveCourses, index }) => {
        const progressrRoundOffValue = liveCourses?.progress
        const handleCourseClick = (id) => {
            history.push({
                pathname: '/course-details-page',
                state: {
                    fromMyCourcesPage: {
                        id: id,
                        resourceType: liveCourses?.resourceType
                    }

                }
            })
        };
        return (
            <div className='live-course-parent my-course-card-zoom m-t-20' style={{}}>
                {console.log("liveCourses", liveCourses)}
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(liveCourses?.id)}>
                    <div className='popular-img-parent '>
                        {liveCourses?.mode == null ? <></> : (<>
                            <div className="card-mode-label" style={{ backgroundColor: liveCourses?.mode == "online" ? "#28DF99" : liveCourses?.mode == "hybrid" ? "#FFA931" : "#FF414D", }}>{liveCourses?.mode?.charAt(0)?.toUpperCase() + liveCourses?.mode?.slice(1)}</div>
                        </>)}
                        {liveCourses?.image !== null ?
                            <img className='popular-img' style={{}} src={`${Api.dlS3Url}${liveCourses?.image}`} alt={liveCourses?.name} />

                            :
                            <img className='popular-img' src={defaultCourseImage} />
                        }
                    </div>
                </div>
                <div className='popular-subTitle' style={{}}>
                    <div className='livecourse-title m-t-5 cursor-pointer' onClick={() => handleCourseClick(liveCourses?.id)} style={{ fontSize: "15px" }}>{liveCourses?.courseName}</div>
                    <div style={{ width: "100%" }}>
                        <div className='r-c-sb' style={{ width: "100%" }}>
                            {liveCourses?.progress !== null ?
                                <Progress
                                    className='student-test-progressbar'
                                    style={{ width: "100%", left: '0px', right: '50px' }}
                                    trailColor=''
                                    strokeColor={liveCourses?.progress === 100 ? "#28DF99" : "#594099"}
                                    percent={progressrRoundOffValue}
                                    size={'default'}
                                    status="active"
                                    showInfo={false} />
                                : null}
                            {liveCourses?.progress === 100 ?
                                <img src={checkIcon} style={{ width: 15, height: 15, marginLeft: "5px" }} />
                                : null}
                        </div>

                        {liveCourses?.progress === 0 ?
                            <div className='r-c-fs text-xs' style={{ color: "#AEAEAE" }}>Start course</div>
                            :
                            liveCourses?.progress === 100 || liveCourses?.progress === null ? null :
                                <div className='r-c-fe' style={{ color: "#AEAEAE", fontSize: "10px" }}>{progressrRoundOffValue}% Completed</div>
                        }

                    </div>
                    <div className='r-c-fs m-t-5' style={{ width: "100%" }}>
                        {liveCourses?.ratingCount || liveCourses?.ratingValue ?
                            <div className='display-flex' style={{ margin: "-2px 10px 0px 0px" }} >
                                <div style={{ margin: "-4px 5px 0px 0px" }}><img src={require("../../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{liveCourses?.ratingValue}</div>
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={liveCourses?.rating} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12 }}>({liveCourses?.ratingCount})</div>
                            </div>
                            : null}

                        <span className='r-c-fs font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 12 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(liveCourses?.userRating?.ratingValue, liveCourses?.userRating?.ratingExplanation, "course", liveCourses?.id)
                        }}>Add a Rating</span>
                    </div>
                </div>

            </div>
        )
    }
    const VideoResources = ({ course, index }) => {

        console.log('cooo', course);
        const progressrRoundOffValue = course?.progress
        const handleCourseClick = (id) => {
            history.push({
                pathname: "/video-resources",
                state: { id: id }
            });
        };
        const durationInSeconds = course?.duration ? calculateDurationInSeconds(course?.duration) : 0;
        const durationInHours = calculateHoursFromSeconds(durationInSeconds);
        function calculateDurationInSeconds(duration) {
            const [hours, minutes, seconds] = duration?.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        }

        // Function to calculate hours from total seconds
        function calculateHoursFromSeconds(seconds) {
            return Math.floor(seconds / 3600);
        }
        return (
            <div className='live-course-parent m-t-20 my-course-card-zoom' style={{}}>
                <div style={{ cursor: "pointer" }} onClick={() => handleCourseClick(course?.id)}>
                    <div className='popular-img-parent'>
                        {course?.image !== null ?
                            <img className='popular-img' style={{}} src={`${Api.dlS3Url}${course?.image}`} alt={course?.name} />
                            :
                            <img className='popular-img' src={defaultCourseImage} />
                        }
                    </div>
                </div>
                <div className='' style={{}}>
                    <div className='livecourse-title m-t-5 cursor-pointer' onClick={() => handleCourseClick(course?.id)} style={{ fontSize: "15px" }}>{course?.name}</div>
                    <div className='' style={{
                        color: "#636363",
                        fontSize: 12,
                        width: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        lineHeight: "20px",
                        cursor: "pointer",
                    }}>
                        {course?.duration} hours videos |  {course?.quizCount} quizzes |  {course?.notesCount} notes


                    </div>

                    {user?.role.includes('Teacher')
                        ? <></> :
                        <div style={{ width: "100%" }}>
                            <div className='r-c-sb' style={{ width: "100%" }}>
                                {course?.progress !== null ?
                                    <Progress
                                        className='student-test-progressbar'
                                        style={{ width: "100%", left: '0px', right: '50px' }}
                                        trailColor=''
                                        strokeColor={course?.progress === 100 ? "#28DF99" : "#594099"}
                                        percent={progressrRoundOffValue}
                                        size={'default'}
                                        status="active"
                                        showInfo={false} />
                                    : null}
                                {course?.progress === 100 ?
                                    <img src={checkIcon} style={{ width: 15, height: 15, marginLeft: "5px" }} />
                                    : null}
                            </div>

                            {course?.progress === 0 ?
                                <div className='r-c-fs text-xs' style={{ color: "#AEAEAE" }}>Start course</div>
                                :
                                course?.progress === 100 || course?.progress === null ? null :
                                    <div className='r-c-fe' style={{ color: "#AEAEAE", fontSize: "10px" }}>{progressrRoundOffValue}% Completed</div>
                            }

                        </div>
                    }

                    <div className='r-c-fs m-t-5' style={{ width: "100%" }}>

                        {course?.ratingCount || course?.ratingValue ?
                            <div className='display-flex' style={{ margin: "-2px 10px 0px 0px" }} >
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={1} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div style={{ margin: "-2px 5px 0px 0px" }}><img src={require("../../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{course?.ratingValue}</div>
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12, }}>({course?.ratingCount})</div>
                            </div>
                            : null}

                        {user?.role.includes('Teacher')
                            ? <></> : <div className='r-c-fs font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 12 }} onClick={() => {
                                setIsRatingModalVisible(true);
                                handleUserRating(course?.userRating?.ratingValue, course?.userRating?.ratingExplanation, "videoResource", course?.id)
                            }}>Add a Rating</div>}


                    </div>
                </div>
            </div>
        )
    }

    const TestSeries = ({ testData, id, flag }) => {
        const handleMaterial = (id) => {
            history.push({
                pathname: "/online-test-series",
                state: {
                    fromTestSeriesPage: {
                        id: id,
                        resourceType: "testSeries"
                    }

                }
            });
        }
        console.log("itttt", testData)
        return (
            <div className='card-design-parent' style={{}}>
                <div className='cd-p1' style={{}} >
                    <div className='card-design' style={{}}>
                        <div className='card-content-free' onClick={() => handleMaterial(testData?.id)} style={{ cursor: 'pointer' }}>
                            <div className='card-img' style={{}}>
                                <img style={{}} src={require("../../../Assets/aulasMarket/onlineTestIcon.svg").default} />
                            </div>
                            <div style={{ color: "#594099", fontSize: 12, marginLeft: 5, marginTop: "9px" }}>
                                {testData?.tCount ? testData?.tCount : 0} {testData?.tCount <= 1 ? "Test" : "Tests"}
                                {/* {testData?.q_count > 0 ? `, ${testData?.q_count} ${testData?.q_count <= 1 ? "quiz" : "quizzes"}` : ""} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='popular-subTitle'>
                    {/* <div className='m-l-10 cursor-pointer' >
                                {testData?.name}
                            </div> */}
                    <div className='m-t-5 livecourse-title' style={{}}>{testData?.name}</div>
                    <div style={{ width: "100%" }}>
                        <div className='r-c-sb' style={{ width: "100%" }}>
                            <Progress
                                className='student-test-progressbar'
                                style={{ width: "100%", left: '0px', right: '50px' }}
                                trailColor=''
                                strokeColor={testData?.progress === 100 ? "#28DF99" : "#594099"}
                                percent={testData?.progress}
                                size={'default'}
                                status="active"
                                showInfo={false} />
                            {testData?.progress === 100 ?
                                <img src={checkIcon} style={{ width: 20, marginLeft: 10 }} />
                                : null}
                        </div>

                        {testData?.progress === 0 || testData?.progress === undefined ?
                            <div className='r-c-fs text-xs' style={{ color: "#AEAEAE" }}>Start course</div>
                            :
                            testData?.progress === 100 ? null :
                                <div className='r-c-fe text-xs' style={{ color: "#AEAEAE" }}>{testData?.progress}% Completed</div>
                        }
                    </div>
                    <div className='r-c-fs m-t-5' style={{}}>
                        {testData?.ratingCount || testData?.ratingValue ?
                            <div className='display-flex' style={{ margin: "-2px 10px 0px 0px" }} >
                                <div style={{ margin: "-2px 5px 0px 0px" }}><img src={require("../../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{testData?.ratingValue}</div>
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={testData?.rating} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12 }}>({testData?.ratingCount})</div>
                            </div>
                            : null}
                        <div className='r-c-fs font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 12 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(testData?.userRating?.ratingValue, testData?.userRating?.ratingExplanation, "testSeries", testData?.id)

                        }}>Add a Rating</div>
                    </div>
                </div>

            </div>
        )
    }

    const StudySeries = ({ testData, id, flag }) => {
        console.log("studydata", testData)
        const handleMaterial = (id) => {
            history.push({
                pathname: "/study-material",
                state: {
                    fromStudyMaterialPage: {
                        id: id,
                        resourceType: testData?.resourceType
                    }

                }
            });
        }
        return (
            <div className='card-design-parent my-course-card-zoom' style={{ gap: "20px" }}>
                <div className='cd-p1' style={{}} >
                    <div className='card-design cursor-pointer' style={{}}>
                        <div className='card-content-free' onClick={() => handleMaterial(testData?.id)} style={{}}>
                            <div className='card-img ' style={{}}>
                                <img style={{}} src={require("../../../Assets/aulasMarket/studyMaterialIcon.svg").default} />
                            </div>
                            <div className='m-l-10' style={{ fontSize: 12, width: "65%" }}>
                                {/* {testData?.m_count} materials */}
                                <div style={{ color: "#594099", fontSize: 12, marginLeft: 5, marginTop: "9px" }}>
                                    {testData?.m_count} {testData?.m_count <= 1 ? "Material" : "Materials"}
                                    {testData?.q_count > 0 ? `, ${testData?.q_count} ${testData?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='popular-subTitle'>
                    <div className='m-t-5 livecourse-title cursor-pointer' onClick={() => handleMaterial(testData?.id)} style={{}}>{testData?.name}</div>
                    <div className='r-c-fs m-t-5' style={{ width: "100%" }}>
                        {testData?.ratingCount || testData?.ratingValue ?
                            <div className='display-flex' style={{ margin: "-2px 10px 0px 0px" }} >
                                <div style={{ margin: "-2px 5px 0px 0px" }}><img src={require("../../../Assets/aulasMarket/ic-star-fill.svg").default} /></div>
                                <div className='font-500' style={{ color: "#191919", fontSize: 12 }}>{testData?.ratingValue}</div>
                                {/* <div style={{ margin: "-3px 5px 0px 5px" }}><Rate disabled defaultValue={testData?.rating} style={{ color: "#FFA931", fontSize: "14px" }} /></div> */}
                                <div className='m-l-5' style={{ color: "#636363", fontSize: 12 }}>({testData?.ratingCount})</div>
                            </div>
                            : null}
                        <div className='r-c-fs font-bold cursor-pointer' style={{ color: "#1089FF", fontSize: 12 }} onClick={() => {
                            setIsRatingModalVisible(true);
                            handleUserRating(testData?.userRating?.ratingValue, testData?.userRating?.ratingExplanation, "studyMaterial", testData?.id)
                        }}>Add a Rating</div>
                    </div>
                </div>
            </div>
        )
    }

    const NoItems = () => {
        return (
            <div>
                <div className='r-c-c-c'>
                    <div className='r-c-c m-t-80'><img style={{ width: "70%", height: "100%" }} src={NoData} /></div>
                    <div className='font-bold text-sm m-t-30' style={{ color: "#191919" }}>No course available!</div>
                    <div className='' style={{ color: "#636363", fontSize: "12px" }}>You have not registered for any courses yet!</div>
                    <div className='m-t-30'>
                        {user?.role?.includes('Student') ?
                            <Button className='cursor-pointer' onClick={() => { history.push("/") }} style={{ fontWeight: "bold", color: "#594099", borderRadius: "100px" }}>EXPLORE COURSES</Button>
                            : <></>}
                    </div>
                </div>
            </div>
        )
    }

    const areAllArraysEmpty = myCoursePage && Object.keys(myCoursePage).every(key => myCoursePage[key]?.length === 0);

    const actions = [
        <div style={{}}>
            <AutoComplete showSearch={true} style={{ width: "300px", marginRight: 20, height: "35px" }} onSearch={(value) => handleFilter(null, null, null, value)}
                placeholder={<span><SearchOutlined /> Search courses</span>}
                allowClear
                className='round-bordered-search'
            />
        </div>,
        <div className='filterDesign' style={{ width: "150px", height: "35px", cursor: "pointer" }}>
            <Dropdown
                overlay={filterDropDownMenu}
                visible={dropdownVisible}
                onVisibleChange={handleDropdownVisibleChange}
                trigger={['click']}
            >
                <div className='r-c-sa' style={{ width: "100%" }}>
                    <div className='r-c-fs'>
                        <img style={{ width: "15px" }} src={require("../../../Assets/aulasMarket/filterIC.svg").default} />
                        <div className='mobile-hidden'>&nbsp; Filter</div>
                    </div>
                    <img style={{ width: "12px" }} className='mobile-hidden' src={dropdownVisible ? dropUpIcon : dropDownIcon} /> </div>
            </Dropdown>
        </div>

    ];
    return (
        <div className='parent' style={{}}>
            <div className='parent-compo' style={{}}>
                <div className='container-1' style={{}}>
                    <div className='font-bold' style={{ fontSize: "18px" }}>My Courses</div>
                    <PageHeader title={"My Courses"} actions={actions} />
                </div>

                <div className='container-2'>

                    {(user?.role?.includes('Student') && areAllArraysEmpty) || myCoursePageForTeacher?.items?.length == 0 ?
                        <NoItems />
                        :
                        (
                            <>
                                <div>
                                    {user?.role?.includes('Student') && myCoursePage?.courses && myCoursePage?.courses?.length > 0 ? (<>
                                        <div className=' font-bold' style={{ fontSize: "18px" }}>Live Courses</div>
                                        <div className='courses-container-p1'>
                                            {myCoursePage?.courses?.map((item, index) => (
                                                <LiveCourse
                                                    key={index}
                                                    index={index}
                                                    liveCourses={item}
                                                />
                                            ))}
                                        </div>
                                    </>) : null}
                                </div>
                                <div style={{}} >


                                    {(user?.role?.includes('Student') && myCoursePage?.videoResource && myCoursePage?.videoResource?.length > 0) ||
                                        (user?.role?.includes('Teacher') && myCoursePageForTeacher?.items?.length > 0) ? (
                                        <>
                                            {console.log('here')}
                                            <div className='text-sm font-bold'>Video Courses</div>
                                            <div className='courses-container-p1'>
                                                {(user?.role?.includes('Student') ? myCoursePage?.videoResource : myCoursePageForTeacher?.items)?.map((i) => (
                                                    <div key={i.id}>
                                                        <VideoResources course={i} />
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ) : null}

                                </div>
                                <div style={{}} >
                                    {user?.role?.includes('Student') && myCoursePage?.testSeries && myCoursePage?.testSeries?.length > 0 ? (<>
                                        <div className='text-sm font-bold'>Online Test Series</div>
                                        <div className='courses-container-p1 '>
                                            {myCoursePage?.testSeries?.map((item, index) => (
                                                <TestSeries
                                                    key={index}
                                                    index={index}
                                                    testData={item}
                                                />
                                            ))}
                                        </div>
                                    </>) : null}
                                </div>
                                <div style={{}} >
                                    {user?.role?.includes('Student') && myCoursePage?.studyMaterial && myCoursePage?.studyMaterial?.length > 0 ? (<>
                                        <div className='text-sm font-bold'>Study Materials</div>
                                        <div className='courses-container-p1 '>
                                            {myCoursePage?.studyMaterial?.map((item, index) => (
                                                <>
                                                    <StudySeries
                                                        key={index}
                                                        index={index}
                                                        testData={item}
                                                    />
                                                </>
                                            ))}
                                        </div>
                                    </>) : null}
                                </div>
                                <div style={{ marginTop: 50 }} >
                                    {user?.role?.includes('Student') && myCoursePage?.related && myCoursePage?.related?.length > 0 ? (<>
                                        <div className='text-sm font-bold'>Related Courses</div>
                                        <div className='rela-parent' >
                                            {myCoursePage?.related?.map((item, index) => (
                                                <RelatedCoursesCompo
                                                    key={index}
                                                    index={index}
                                                    course={item}
                                                    itemType={item?.resourceType}
                                                />
                                            ))}
                                        </div>
                                    </>) : null}
                                </div>
                            </>)
                    }
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
            {isRatingModalVisible &&
                <AddRatingModal
                    isRatingModalVisible={isRatingModalVisible}
                    setIsRatingModalVisible={setIsRatingModalVisible}
                    userRating={userRating}
                />}

        </div>

    )
}
const mapStateToProps = (state) => {
    const { myCoursePage, myCoursePageForTeacher } = state.studentMarketPlace
    const { user } = state.session
    return {
        myCoursePage, user, myCoursePageForTeacher
    }
};

const mapDispatchToProps = (dispatch) => ({
    getCourseDashBoardData: (filterParam, callback) => dispatch(getCourseDashBoardData(filterParam, callback)),
    getCourseDashBoardDataForTeacher: (filterParam, callback) => dispatch(getCourseDashBoardDataForTeacher(filterParam, callback)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
    showMenu: (val) => dispatch(showMenu(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseHome) 
