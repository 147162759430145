import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';

import { AiOutlineCloseCircle, AiOutlineCheckCircle, AiOutlineSearch} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { MdKeyboardBackspace } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { Color } from 'services';
import { Avatar, Input} from 'antd';
import './styles.css';
import { updateSubmittedTestsStudentId , fetchTestSubmitDetails, setIsCModalVisible, setCModalType, updateSelectedTestId, updateCurrentQuestionIndex, updateFilter} from 'actions/testAdmin';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import { SubIcon } from 'components/subIcon';
import ConfirmationModal from './modals/confirmationModal';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from './localComponent/searchBox';
import Loader from './localComponent/loader';
import { NodataCard } from '.';

const { Search } = Input;


const StudentRow = ({history, index, name, id, rollno, obtain, total, updateSubmittedTestsStudentId, updateCurrentQuestionIndex}) => {
  return(
    <div onClick={() => {
      updateSubmittedTestsStudentId(id)
      updateCurrentQuestionIndex(0);
      history.push('test-stud-submit-ques-screen')
    }} style={{width:'100%', minWidth:300, maxWidth:400}} className='r-c-sb p-20 m-l-20 m-b-20 hover-shadow shadow-box cursor-pointer'>
      <div className='r-ac'>
        <Avatar size={50} icon={<FaUserAlt />} className='color-purple' style={{background:'#F6F4FE'}} />
        <div className='m-l-15'>
          <div className='text-xmd bold-600 color-black'>{name}</div>
          <div className='text-xs a2grey'>Roll No. {rollno}</div>
        </div>
      </div>
      <div className='text-sm bold-600 color-black'>{obtain}/{total}</div>
    </div>
  )
}

const PageHeader = ({goBack, title, onSearch, searchVal}) => {
  return (
    <div className="full-width m-b-20 r-c-sb">
      <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{fontSize:18}}/>
          <div className="text-md bold-700 m-l-20"> {title} </div>
      </div>
      <div className="">
          <SearchBox  loading={false} 
                      onChange={onSearch}
                      placeholder="Search by student name"
                      style={{width:220, marginLeft:10}}
                      value={searchVal}/>
      </div>
    </div>
  )
}

let timer = false;
function SubmittedDetailScreen ({history,
                                 submittedTestsDetails,
                                 submittedTestsUsers,
                                 fetchTestSubmitDetails,
                                 updateSubmittedTestsStudentId, absentStudents,
                                setCModalType, setIsCModalVisible, isCModalVisible, 
                                cModalType, userType, updateSelectedTestId, 
                                updateCurrentQuestionIndex, updateFilter, filter, loaders}) {

    const [pageLoader, togglePageLoader] = useState(false);
    useEffect(() => {
      togglePageLoader(true);
      return () => {

      }
    }, [])

    if(!submittedTestsDetails && !submittedTestsUsers) {
      return(
        <PageDataLoader visible={pageLoader}/>
      )
    }
    useEffect(()=>{
      clearTimeout(timer);
      timer = setTimeout(()=>{
      fetchTestSubmitDetails((bool)=>togglePageLoader(bool));
      }, 1000);
    }, [filter?.studentSearch]);
    const goBack = () => {
      history.goBack();
    }
    const onSearch = (e) =>{
      updateFilter('studentSearch', e.target.value);
    }
    return (
      <div style={{height:'calc(100% + 40px)', margin:'-20px', backgroundColor:"#FAFAFA", padding:20}}>
        <PageHeader title={submittedTestsDetails.title} goBack={goBack} onSearch={onSearch} searchVal={filter?.studentSearch}/>
        <div className='display-flex' style={{height:'calc(100% - 30px)'}}>
          <div className='flex-1 overflow-scroll '>
            <div className='text-xs bold-600 a2grey p-l-20'>SUBMISSIONS </div>
            <div className='p-20 display-flex flex-wrap' style={{marginLeft:'-20px'}}>
              {
                loaders?.studentSearchLoader?
                <div className="full-width" style={{height:"80vh"}}>
                  <Loader style={{fontSize:30}}/>
                </div>
                :
                submittedTestsUsers?.length>0?
                submittedTestsUsers?.map((item, index) => (
                <StudentRow updateSubmittedTestsStudentId = {updateSubmittedTestsStudentId} history={history} id = {item.userId} name={item.username} 
                      rollno={item.rollNo} total={submittedTestsDetails.totalPoints} obtain={item.gradeObtained} index={index} updateCurrentQuestionIndex={updateCurrentQuestionIndex}/>
                ))
                :
                <div style={{flex:1,justifyContent:"center",alignItems:"center",width:"100%"}}>
                <NodataCard/>
                </div>
              }

            </div>
          </div>
          <div className='vertical-divider'></div>
          <div className='position-relative'>
            <div className='overflow-scroll' style={{width:400, height:'100%'}}>
              <div className='text-sm m-l-20 color-black bold-600'>Test details</div>
              <div className='p-20'>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>TITLE</div>
                  <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.title}</div>
                </div>
                <div className='r-ac m-b-20'>
                  <div className='m-r-50'>
                    <div className='text-xs bold-600 a2grey'>INDEX</div>
                    <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.index}</div>
                  </div>
                  <div className=''>
                    <div className='text-xs bold-600 a2grey'>CLASS</div>
                    <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.standardNames}</div>
                  </div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>SUBJECT(S)</div>
                  <div className='display-flex flex-wrap text-xmd bold-600 color-black '>
                    {submittedTestsDetails.subjectNames?.split(',')?.join(' • ')}
                    {/* {submittedTestsDetails.subjectNames?.split(',')?.map((item, index) => (
                      <div className='r-ac m-r-10' key={index}>
                        <div className='text-xmd bold-600 color-black '>{item}</div>
                        <div className='p-3 radius-100 m-l-10' style={{background: index === subjArr?.length-1 ? '#fff' : '#191919'}}></div>
                      </div>
                    ))} */}
                  </div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>EXAMINER</div>
                  <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.teacherName}</div>
                </div>
                <div className='r-ac m-b-20'>
                  <div className='m-r-50'>
                    <div className='text-xs bold-600 a2grey'>TEST HELD ON</div>
                    <div className='r-ac'>
                      <div className='text-xmd bold-600 color-black '>{submittedTestsDetails?.testDate?.replaceAll(',', '/')}</div>
                      <div className='p-3 radius-100 m-l-10 m-r-10' style={{background: '#191919'}}></div>
                      <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.startTime}</div>
                    </div>
                  </div>
                  <div className=''>
                    <div className='text-xs bold-600 a2grey'>SUBMISSIONS</div>
                    <div className='text-xmd bold-600 color-black '>{submittedTestsDetails?.submissionCount}/{submittedTestsDetails.studentCount}</div>
                  </div>
                </div>
                {submittedTestsDetails.testType!="Subjective"
                ?
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>RESULT PUBLISHED ON</div>
                  <div className='r-ac'>
                    <div className='text-xmd bold-600 color-black '>{submittedTestsDetails?.resultDate}</div>
                    <div className='p-3 radius-100 m-l-10 m-r-10' style={{background: '#191919'}}></div>
                    <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.resultTime}</div>
                  </div>
                </div>
                :
                null
                }
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey'>TEST TYPE</div>
                  <div className='text-xmd bold-600 color-black '>{submittedTestsDetails.testType}</div>
                </div>
                <div className='m-b-20'>
                  <div className='text-xs bold-600 a2grey m-b-10'>ABSENT STUDENT(S)</div>
                  {absentStudents?.map((item, index) => (
                    <div className='r-ac m-b-15' key={index}>
                      {/* {index === 1
                      ? */}
                        {/* <Avatar size={50} icon={<FaUserAlt />} className='color-purple' style={{background:'#F6F4FE'}} /> */}
                      {/* :
                        <img src={require('Assets/test/mathIcon.png')} className='radius-100' style={{width:50, height:50}} />
                      } */}
                      <SubIcon icon={item.image} name={item.name?item.name:" "} size={40}/>
                      <div className='m-l-15'>
                        <div className='text-xmd bold-600 color-black'>{item.name}</div>
                        <div className='text-xsm a2grey'>Roll No. {item.student?.rollNo}</div>
                      </div>
                    </div>
                  ))}

                </div>
                {
                  userType=="admin"
                  ?
                  <div className="r-c-c m-t-30">
                    <Button onClick={() => {
                      updateSelectedTestId(submittedTestsDetails.id)
                      setCModalType("PublishResultEval");
                      setIsCModalVisible(true);
                    }} className='blueBtnWrap radius-100 text-xs'> Publish Result </Button>
                  </div>
                  :
                  null
                }


              </div>
            </div>

          </div>
        </div>
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
      </div>
  );
}


const mapStateToProps = state => {
  const {submittedTestsDetails, submittedTestsUsers, absentStudents, isCModalVisible, cModalType, userType, filter, loaders}=state.testAdmin;
    return {submittedTestsDetails, submittedTestsUsers, absentStudents, isCModalVisible, cModalType, userType, filter, loaders}
};

const mapDispatchToProps = dispatch => ({
  updateSubmittedTestsStudentId: value=>dispatch(updateSubmittedTestsStudentId(value)),
  fetchTestSubmitDetails: (callback)=>dispatch(fetchTestSubmitDetails(callback)),
  setIsCModalVisible: (bool)=>dispatch(setIsCModalVisible(bool)),
  setCModalType: (type)=>dispatch(setCModalType(type)),
  updateSelectedTestId: (testId)=> dispatch(updateSelectedTestId(testId)),
  updateCurrentQuestionIndex: (index) => dispatch(updateCurrentQuestionIndex(index)),
  updateFilter: (key, val)=> dispatch(updateFilter(key, val))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedDetailScreen);
