import { Typography } from 'antd'
import React from 'react'

const LibraryCardDetails = ({ title, filesNO, images, lastWeek, index }) => {
  return (
    <>
      <div className='display-flex m-t-20'>
        <div className='r-c-c'>
          <img src={images} style={{ width: 50, height: 50 }} />
        </div>
        <div className='m-l-10' style={{ width: "21.5vw" }}>
          <div className=' dark-grey' style={{ height: 20, fontSize: 14 }}>
            {title}
          </div>
          <div className='text-md color-black bold-600 '>{filesNO}</div>
          {lastWeek ?
            <p><span style={{ color: lastWeek > 0 ? "#28DF99" : '#FF414D' }}>{lastWeek ? lastWeek : null} from last week</span></p>
            : null}
        </div>
        <div style={{ borderRight: index == 2 ? "" : "1px solid #E6E6E6", marginRight: 20 }}>
        </div>
      </div>
    </>
  )
}

export default LibraryCardDetails