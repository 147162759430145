import { fetchDownloadReport, setFeeDetails } from "actions/feeManagement";
import { Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Date_Picker from "./datePicker";
import { Notification } from "services";

function SelectRangeModal({
  isModalVisible,
  handleOk,
  handleCancel,
  setShowSelectRangeModal,
  fetchDownloadReport,
  feeMangementData,
  setFeeDetails,
  setShowReasonModal,
  kycStatus, resourceData,
  viewAllIs,
  fromCourseDetails,
}) {
  const [selectedValue, setSelectedValue] = useState(0);
  const [value, setValue] = React.useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const successCallBack = () => {
    setValue(null);
    setFeeDetails("startDate", null);
    setFeeDetails("endDate", null);
    setShowSelectRangeModal(false);
    Notification.success('success', 'Report downloaded successfully!')
  };

  useEffect(() => {
    setFeeDetails("startDate", null);
    setFeeDetails("endDate", null);
  }, []);

  const handleDownloadReport = () => {
    let params = {
      resourceType: viewAllIs,
      resourceId: resourceData?.id, filter: value,
      startDate: feeMangementData?.startDate ? feeMangementData?.startDate : null,
      endDate: feeMangementData?.endDate ? feeMangementData?.endDate : null,
    }
    setDownloadLoader(true)
    fetchDownloadReport(params, () => { }, () => { setDownloadLoader(false) })
  }

  return (
    <div>
      <Modal
        maskClosable={false}
        className="selectRangeModalP"
        title="Basic Modal"
        visible={isModalVisible}
        okText={"DOWNLOAD"}
        cancelText={"CANCEL"}
        okButtonProps={{
          loading: downloadLoader,
          disabled:
            value == "specificDateRange"
              ? !(feeMangementData?.endDate && feeMangementData?.startDate)
              : !value,
        }}
        onOk={() => {
          if (kycStatus !== 'Approved') {
            setShowReasonModal(true);
            setShowSelectRangeModal(false);
          } else if (fromCourseDetails && kycStatus == 'Approved') {
            console.log('loggin inside course')
            handleDownloadReport()
          } else {
            let object = {
              filter: value,
              startDate: feeMangementData?.startDate
                ? feeMangementData?.startDate
                : null,
              endDate: feeMangementData?.endDate
                ? feeMangementData?.endDate
                : null,
            };
            setDownloadLoader(true);
            fetchDownloadReport(
              object,
              () => setDownloadLoader(false),
              successCallBack
            );
          }
        }}
        onCancel={() => {
          setFeeDetails("startDate", null);
          setFeeDetails("endDate", null);
          setShowSelectRangeModal(false);
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Select range
        </h2>
        <div
          style={{ fontFamily: "roboto", marginTop: "40px", height: "480px" }}
        >
          <Radio.Group
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-start",
            }}
            onChange={onChange}
            value={value}
          >
            <Radio
              style={{ color: "#191919", padding: "10px" }}
              value={"lastweek"}
            >
              {" "}
              Last week
            </Radio>
            <Radio
              style={{ color: "#191919", padding: "10px" }}
              value={"lastmonth"}
            >
              {" "}
              Last month
            </Radio>
            <Radio
              style={{ color: "#191919", padding: "10px" }}
              value={"lastthreemonths"}
            >
              {" "}
              Last 3 months
            </Radio>
            <Radio
              style={{ color: "#191919", padding: "10px" }}
              value={"lastsixmonths"}
            >
              {" "}
              Last 6 months
            </Radio>
            <Radio
              style={{ color: "#191919", padding: "10px" }}
              value={"yeartodate"}
            >
              {" "}
              Year to date
            </Radio>
            <Radio
              style={{ color: "#191919", padding: "10px" }}
              value={"specificDateRange"}
            >
              {" "}
              Specific date range
            </Radio>
          </Radio.Group>
          {value == "specificDateRange" ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px",
                  marginBottom: "-17px",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  Start date
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    marginLeft: "250px",
                  }}
                >
                  End date
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px",
                }}
              >
                {/* {console.log("date",feeMangementData?.startDate)} */}
                <Date_Picker
                  value={feeMangementData?.startDate}
                  setDate={setFeeDetails}
                  keyName={"startDate"}
                />
                <div style={{ width: "100px" }}> </div>
                <Date_Picker
                  disableDate={feeMangementData?.startDate}
                  value={feeMangementData?.endDate}
                  setDate={setFeeDetails}
                  keyName={"endDate"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </div >
  );
}

const mapStateToProps = (state) => {
  const { feeMangementData, resourceData, viewAllIs } = state.feeManagement;
  return {
    feeMangementData, resourceData, viewAllIs
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDownloadReport: (object, callback, successCallBack) =>
    dispatch(fetchDownloadReport(object, callback, successCallBack)),
  setFeeDetails: (key, res) => dispatch(setFeeDetails(key, res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectRangeModal);

// export default SelectRangeModal;
