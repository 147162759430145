import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Color } from "services";

const Up = require("../../../Assets/test-new/ic-chevron-up.svg").default;
const Down = require("../../../Assets/test-new/ic-chevron-down.svg").default;

const circle = (item, mf_idx, handlePaletteClick) => {
	return <Col
	key={mf_idx}
		className="questionSelector r-c-c cursor-pointer m-t-5"
		onClick={() => handlePaletteClick(mf_idx)}
		style={{
			borderRadius: "50%",
			border: `1px solid ${Color.midGrey}`,
			background:
				item?.status === "Correct"
					? Color.seaGreen
					: item?.status === "Incorrect"
						? Color.wrong
						: item?.status === "PartialCorrect"
							? "linear-gradient(36deg, #28DF99 50%, #94EFCC 50%)"
							: item?.status === "Unanswered" ||
								item?.status === "Bookmarked" ||
								item?.status === "None"
								? "#AEAEAE"
								: item?.status === "Unseen"
									? "#FFFFFF"
									: "none",
			color:
				item?.status === "Unanswered" ||
					item?.status === "Unseen" ||
					item?.status === "Bookmarked" ||
					item?.status === "None"
					? Color.black
					: "white",
		}}
	>
		<span> {mf_idx + 1}</span>
	</Col>
}


function QuestionPaletteResult(props) {

	const { handlePaletteClick, palateData } = props;

	//creating a copy so that we dont modify redux state directly
	const testSubjects = JSON.parse(JSON.stringify(props.testSubjects))


	const [closed, setClosed] = useState({});
	let mf_idx = 0;	//modified index

	useEffect(() => {
		const closed_obj = {};
		testSubjects.map((item, index) => {
			closed_obj[item.subject_name] = false;
		});
		setClosed(closed_obj);
	}, []);


	const toggleViewHandler = (subject_name) => {
		setClosed((prev) => ({
			...prev,
			[subject_name]: !prev[subject_name],
		}));
	};


	if (testSubjects[0]?.sections)	//if test contains sections
	{
		return <div className="questionPalette">
			<div className='bold-600'
				style={{ color: '#AEAEAE', marginBottom: 10 }}>
				ALL QUESTIONS
			</div>

			{testSubjects?.map((sub_obj, index) =>
				<div key={index} style={{ margin: 5 }}>
					<div className="r-jsb"
						style={{ cursor: 'pointer' }}
						onClick={() => toggleViewHandler(sub_obj?.name)}
					>
						<div className='text-sm bold-600 m-b-10'>
							{sub_obj?.name}
						</div>
						<img src={closed[sub_obj?.name] ? Down : Up} />
					</div>


					{!closed[sub_obj?.name] &&
						sub_obj?.sections?.map((section_obj, index) => {
							section_obj.palateData = [];

							//! this calculation will be done everytime the component render
							// one way to prevent this is to write this calculation outside
							// useEffect and useMemo the result
							// or do all this calculation while dispatching in action function
							for (let i = 0; i < section_obj.questionCount; i++) {
								section_obj.palateData.push({
									palateData: palateData[mf_idx], mf_idx
								});
								mf_idx++;
							}

							return <div key={index} className='text-xmd bold-600 m-b-10 m-l-10'>
								{section_obj.sectionName}

								<span style={{ color: 'grey' }} className='bold-400'>
									&ensp; | &ensp;
									{`${mf_idx - section_obj.questionCount + 1} - ${mf_idx + 1}`}
								</span>

								<Row>
									{section_obj?.palateData?.map((item, index) =>
										circle(item.palateData, item.mf_idx, handlePaletteClick)
									)}
								</Row>
							</div>
						}
						)}
				</div>
			)}
		</div>
	}
	else	//if test does not contain sections
	{
		return <div className="questionPalette">
			<div className='bold-600'
				style={{ color: '#AEAEAE', marginBottom: 10 }}>
				ALL QUESTIONS
			</div>

			{testSubjects?.map((sub_obj, index) => {
				sub_obj.palateData = [];

				for (let i = 0; i < sub_obj.questionCount; i++) {
					sub_obj.palateData.push({
						palateData: palateData[mf_idx], mf_idx
					});
					mf_idx++;
				}

				return <div key={index} style={{ margin: 5 }}>
					<div className="r-jsb"
						style={{ cursor: 'pointer' }}
						onClick={() => toggleViewHandler(sub_obj?.name)}
					>
						<div className='text-sm bold-600 m-b-10'>
							{sub_obj?.name}
						</div>
						<img src={closed[sub_obj?.name] ? Down : Up} />
					</div>


					{!closed[sub_obj?.name] &&
						<div key={index} className='text-xmd bold-600 m-b-10 m-l-10'>
							<Row>
								{sub_obj?.palateData?.map((item, index) =>
									circle(item.palateData, item.mf_idx, handlePaletteClick)
								)}
							</Row>
						</div>}
				</div>
			}
			)}
		</div>
	}
}

export default QuestionPaletteResult;
