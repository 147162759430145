import React, { useEffect, useState } from 'react'
import { Popover, Input, Checkbox, Tooltip } from 'antd'
import { IMG_URL } from "../../env.json";
import { CaretDownOutlined } from '@ant-design/icons';



function DropDownCheckBox({ DATA, placeholder, checkedArray, onChange, checkAll }) {

	const isIcon = (typeof placeholder == 'object'); //if placeholder is antd Icon

	const CheckboxContent = () => {
		return DATA?.length ?
			<>
				{checkAll != undefined &&
					<div style={{
						width: '15vw',
					}}>
						<Checkbox
							className="p-10 full-width"
							style={{
								// backgroundColor: checkAll ? "#F6F4FE" : null,
							}}
							checked={checkAll}
							onChange={() => onChange({id: 'All'})}
						>
							<div
								className="m-l-10 text-overflow-ellipsis full-width"
							>
								All
							</div>
						</Checkbox>
					</div>
				}
				{DATA?.map((item, index) => (
					<div style={{
						minWidth: 200
					}}>
						<Checkbox
							className="p-10 full-width"
							style={{
								// backgroundColor: checkedArray?.includes(item.id) ? "#F6F4FE" : null,
							}}
							checked={checkedArray?.includes(item?.id)}
							onChange={() => onChange(item)}
						>
							<div
								className="m-l-10 text-overflow-ellipsis full-width"
								style={{maxWidth: 200}}
							>
								{item?.image && 
									<img src={`${IMG_URL}/${item?.image || "default.png"}`}
										style={{ height: 20, width: 20, borderRadius: "50%", marginRight: 10 }}
									/>
								}
								{item?.label}
							</div>
						</Checkbox>
					</div>
				))}
			</>

			:
			<div style={{
				width: '15vw',
			}}>
				<div
					className="m-l-10 text-overflow-ellipsis full-width"
				>
					NO DATA
				</div>
			</div>
	};

	return (
		<Popover
			placement="bottomRight"
			content={CheckboxContent()}
			trigger="click"
		>
			<div className="r-c-sa"
				style={{
					border: !isIcon && "1px solid #E6E6E6",
					background: 'white'
				}}
			>
				{!isIcon ?
					<div style={{paddingRight: 10}}>
						<Input style={{ width: "200px", height: "30px", fontWeight: 600 }}
							className='full-width'
							placeholder={placeholder}
							size="large"
							value={null}
							bordered={false}
							// suffix={}
							readOnly
						/>
						<CaretDownOutlined style={{ color: "#AEAEAE" }} />
					</div> :
					placeholder
				}
			</div>
		</Popover>
	)
}


export default DropDownCheckBox
