import React, { useEffect, useMemo, useState } from "react"; storeImportHoliday
import { ArrowLeftOutlined, DownloadOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, Menu, Typography } from "antd";
import CreateModal from "./Modals/createModal";
import HolidayModal from "./Modals/holidayModal";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { getHolidayOverview, storeImportHoliday, storeHolidayList, holidayDetail, storeholidayCheckBox, editHoliday, trackSelectedRadio, setBackToPrivilegedView, storeCheck, setHolidayList } from "actions/attendanceNew";
import moment from "moment";
import PageDataLoader from "components/pageDataLoader";
import './style.css'
import { parseInt } from "lodash";
import PageHeader from "components/pageHeader";
import { Heading } from "components/Typography";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { MdMoreVert } from "react-icons/md";
import EditHolidayModal from "./Modals/editHolidayModal";
import HolidayDeleteModal from "./Modals/holidayDeleteModal";
import { Button } from "components/Button";
const NoData = require("../../Assets/ill-no-data.svg").default



const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 30px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "auto",
  grid: "1fs 1fs",
};

const image = require("../../Assets/settingss/PlusCircle (1).svg").default;
const images = require("../../Assets/Attendance/loc.svg").default

function checkPush(month) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  let currentmonthNumber = moment().month();
  let monthNumber = months.indexOf(month);
  if (monthNumber < currentmonthNumber) {
    return false;
  }
  return true;

}

const HolidayCard = (props) => {
  console.log("AA-id", props?.id);

  return (
    <>
      <div
        className={props?.importHolidayListNew === props?.id ? " border-16 boxShadow118" : ''}
        style={{
          width: "250px",
          height: "63px",
          borderRadius: 6,
          backgroundColor: "#FFFFFF",
          borderColor: "#E6E6E6",
          margin: "5px",
          cursor: 'pointer'
        }}
      >
        <div className="m-l-20" style={{ display: "flex" }}>
          <div style={{ marginTop: "10px" }}>
            <img style={{ width: "40px" }} src={images}></img>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",

            }}
          >
            <div className="m-l-20">
              <div
                style={{ width: "150px" }}
              >
                <Typography.Text style={{ fontSize: "16px", color: "#191919", fontWeight: "bold", marginTop: "10px" }} ellipsis={{ tooltip: true }}>
                  {props.title}
                </Typography.Text>
              </div>

              <div
                style={{ width: "150px" }}
              >
                <Typography.Text style={{ fontWeight: "bold", color: "#AEAEAE", fontSize: "10px", width: "180px", cursor: "pointer" }} ellipsis={{ tooltip: true }}>
                  {props.address}
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Overview = (props) => {
  return (
    <>
      <div
        className="m-l-10 "
        style={{
          width: "32%",
          maxWidth: "450px",
          height: "80px",
          backgroundColor: "#FFFFFF ",
          border: "1px solid #E6E6E6",
          marginTop: "2px",
          borderRadius: "6px"
        }}
      >
        <div style={{ display: "flex", marginLeft: "20px" }}>
          <div
            style={{ fontSize: "28px", fontWeight: 500, color: "#191919" }}
          >
            {props.num}
          </div>
          <div
            style={{
              fontSize: "12px",
              color: "#191919",
              marginTop: "18px",
              marginLeft: 5,
            }}
          >
            {props.days}
          </div>
        </div>

        <div style={{ fontSize: "14px", color: "#636363", marginLeft: "20px", fontWeight: 400 }}>
          {props.remains}
        </div>
      </div>
    </>
  );
};

const DateCard = ({ convertedDate, convertedWeekday }) => {
  return (
    <div
      style={{
        width: "40px",
        height: "40px",
        borderRadius: 2,
        backgroundColor: "#F6F4FE",
        borderColor: "#F6F4FE",
        padding: 10,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", marginTop: "-5px" }}>
        <div style={{ fontSize: "13px", fontWeight: "bold" }}>{convertedDate}</div>
        <div style={{ color: "#636363", fontSize: "9px", fontWeight: 500 }}>{convertedWeekday}</div>
      </div>

    </div>
  )
}
let isMonth = moment().format('MM')

const HolidayListCard = ({ setAllHolidayLoc, setAllHolidayOverview, checkedValue, isImport, clearHolidayList, HolidayShowModal, showModal, setAllHolidayList, holidayCheckBox, storeholidayCheckBox, onChange, showText, checkedArray, editHolidayValue, setCheckedArray, NoDataCard }) => {
  let [selectedAll, setSelectedAll] = useState(false);
  const [count, setCount] = useState(0);
  const [allSelectedCount, setAllSelectedCount] = useState(null);
  const holidayList = [];
  setAllHolidayList?.forEach(item => {
    if (checkPush(item.month)) {
      item?.data?.forEach(holiday => {
        holidayList.push(holiday)
      })
    }
  })
  let allSelected = true, show = false;
  holidayList?.forEach(item => {
    if (!checkedArray.includes(moment(item.date).format('YYYY-MM-DD'))) {
      allSelected = false;

    } else {
      show = true;
    }
  })

  function onSelectAll(e) {
    setSelectedAll(e.target.checked);
    let temp = [];
    if (e.target.checked) {
      for (let i = 0; i < holidayList?.length; i++) {
        temp.push(moment(holidayList[i]?.date).format('YYYY-MM-DD'))
      }

    }
    storeholidayCheckBox([...temp])
    setCheckedArray([...temp])
  }
  const onClearSelected = () => {
    storeholidayCheckBox([])
    setCheckedArray([])
  }
  // const onClearSelected = () => {
  //   storeholidayCheckBox([])
  //   setCheckedArray([])
  // }

  // # hash method
  const monthTonumber = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: '08',
    September: "09",
    October: "10",
    November: "11",
    December: "12"
  }

  useEffect(() => {
    let temp = 0;
    for (let i = 0; i < setAllHolidayList.length; i++) {
      if (checkPush(setAllHolidayList[i].month)) {
        temp += setAllHolidayList[i].data.length;
      }
    }

    setAllSelectedCount(parseInt(temp));
  }, [JSON.stringify(setAllHolidayList)])

  return (
    <>
      {setAllHolidayList?.length != 0 ?
        <div
          className="m-l-10  shadow-box hover-shadow customCardShadow"
          style={{
            width: "97%",
            height: "100%",
            borderRadius: 2,
            backgroundColor: "#FFFFFF",
            borderColor: "#E6E6E6",
            padding: 10,
          }}
        >
          {editHolidayValue ? <div
            className="m-t-20"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="m-t-10 m-l-5"
              style={{ color: "#636363", fontSize: "14px", fontWeight: "200px" }}
            >
              Select the dates you want to set as holidays
            </div>
          </div> : null}
          {editHolidayValue ? <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="m-t-20 m-l-5 display-flex">
              <Checkbox
                checked={allSelected}
                onClick={onSelectAll} />
              <h4 style={{ fontSize: "12px", color: "#AEAEAE", marginTop: 3, marginLeft: 5 }}>SELECT ALL</h4>
            </div>
            {show ? <div style={{ fontSize: "11px", color: "#FF414D", fontWeight: "bold", marginTop: "20px", cursor: "pointer" }} onClick={onClearSelected}>
              CLEAR SELECTION
            </div> : null}
          </div> : null}
          <div >
            {Array.isArray(setAllHolidayList) && setAllHolidayList?.length != 0 &&
              setAllHolidayList?.map((item) => (
                <div>
                  <div
                    className="m-t-10 m-l-5"
                    style={{
                      fontSize: "15px",
                      color: "#191919",
                      fontWeight: "bold",
                      marginTop: "10px",
                      fontFamily: "Roboto"
                    }}
                  >
                    {
                      parseInt(monthTonumber[item.month]) >= isMonth ?
                        item.month : null
                    }
                  </div>
                  <div className="display-flex flex-wrap m-t-10">
                    {item.data?.map((item, i) => (
                      <HolidayCheckBoxCard
                        id={item.id}
                        date={item.date}
                        created={item.createdAt}
                        title={item?.title}
                        update={item.updatedAt}
                        holidayCheckBox={holidayCheckBox}
                        index={i}
                        storeholidayCheckBox={storeholidayCheckBox}
                        setCheckedArray={setCheckedArray}
                        checkedArray={checkedArray}
                        showText={showText}
                        onChange={onChange}
                        editHolidayValue={editHolidayValue}
                        checkedValue={checkedValue}
                      />
                    ))
                    }
                  </div>
                </div>
              ))}
          </div>
        </div>
        : <div className="m-t-150"><NoDataCard /></div>}
    </>
  );
};

function HolidayCheckBoxCard(props) {
  { console.log("AAAA_props", props?.id); }
  const [editHolidayModal, setEditHolidayModal] = useState(false)
  const [holidayDeleteModal, setHolidayDeleteModal] = useState(false)
  const menu = (
    <Menu style={{ width: "120px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
      <div className='r-ac'>
        <AiFillEdit style={{ width: "24px", height: "24px", color: "#636363", marginLeft: 10, marginRight: 5 }} />
        <Menu.Item key="1" onClick={() => setEditHolidayModal(true)} style={{ backgroundColor: 'white' }}>
          Edit
        </Menu.Item>
      </div>
      <div className='r-ac'>
        <AiFillDelete style={{ width: "24px", height: "24px", color: "#636363", marginLeft: 10, marginRight: 5 }} />
        <Menu.Item key='2' onClick={(e) => { setHolidayDeleteModal(true) }} style={{ backgroundColor: 'white' }}>
          Delete
        </Menu.Item>
      </div>
    </Menu>
  );
  const selected = props.checkedArray.includes(moment(props.date).format('YYYY-MM-DD'))
  return (
    <>
      {
        (moment(props.date).format('MM')) >= isMonth ?
          <div
            className="m-l-10  hover-shadow customCardShadow"
            style={{
              width: "48%",
              height: "70px",
              borderRadius: 6,
              marginLeft: 10,
              marginBottom: "5px",

            }}
          >

            <div className="m-l-20" style={{ display: "flex", marginTop: "20px" }}>
              <div style={{ display: "flex" }}>
                <div className="m-r-20">
                  {props.editHolidayValue ?
                    <Checkbox onChange={(e) => { props.onChange(e, props.date, props.index) }} checked={selected} ></Checkbox> : null
                  }

                </div>
                <div style={{ marginTop: "-5px" }}>
                  <DateCard
                    convertedDate={moment.utc(props.date).local().format('DD')}
                    convertedWeekday={moment.utc(props.date).local().format('ddd')}
                  />
                </div>
              </div>
              <div className="" style={{ width: '100%' }}>
                <div className="m-l-20 r-jsb" style={{ display: "flex", width: '90%' }}>
                  <Typography.Text style={{ fontWeight: "bold", fontSize: "13px", width: "150px", padding: 5, marginLeft: "5px", }} ellipsis={{ tooltip: true }}>
                    {props.title}
                  </Typography.Text>
                  <div style={{ marginLeft: "10px" }}>
                    <Dropdown
                      getPopupContainer={(trigger) => trigger.parentElement}
                      overlay={menu}
                      disabled={false}
                      placement="bottomRight"
                    >
                      <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                    </Dropdown>
                  </div>
                </div>

              </div>


            </div>
          </div> : null}
      {editHolidayModal && <EditHolidayModal editHolidayModal={editHolidayModal} setEditHolidayModal={setEditHolidayModal} props={props} />}
      {holidayDeleteModal && <HolidayDeleteModal holidayDeleteModal={holidayDeleteModal} setHolidayDeleteModal={setHolidayDeleteModal} id={props?.id} />}
    </>
  );

}

function Holiday({ values, uncheckedValue, checkedValue, storeCheck, clearHolidayList, setAllHolidayList, setAllHolidayLoc, importHolidayListNew, getHolidayOverview, setAllHolidayOverview, storeImportHoliday, holidayDetail, storeHolidayList, holidayCheckBox, storeholidayCheckBox, editHoliday, editHolidayValue, holidayPageLoader, createHolidayList, trackSelectedRadio, selectedImportRadio, setBackToPrivilegedView }) {
  useEffect(() => {
    storeholidayCheckBox([])
  }, [])
  useEffect(() => {
    const holidayList = [];
    setAllHolidayList?.forEach(item => {
      if (checkPush(item.month)) {
        item?.data?.forEach(holiday => {
          holidayList.push(holiday)
        })
      }
    })
    setCheckedArray(holidayList?.filter(holiday => holiday.selected)?.map(holiday => moment(holiday.date).format('YYYY-MM-DD')))
  }, [setAllHolidayList])



  const [fenceId, setFenceId] = useState('')
  const [checkedArray, setCheckedArray] = useState([]);
  const [isImport, setIsImport] = useState(false)
  useEffect(() => {
    if (checkedValue != undefined && checkedValue?.length != 0) {
      storeholidayCheckBox([...checkedValue])
    }
  }, [checkedValue?.length])

  const NoDataCard = () => {
    return (
      <>
        <div className='r-c-c-c'>
          <img src={NoData} />
          <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</div>
          <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no data to show yet. Please check back later.</div>
        </div>
      </>
    )
  }


  function onChange(e, date, index) {

    let temp = [...checkedArray];
    if (temp.includes(moment(date).format("YYYY-MM-DD"))
    ) {
      temp.splice(temp.indexOf(moment(date).format("YYYY-MM-DD")), 1)
    }
    else {
      temp.push(moment(date).format("YYYY-MM-DD"))
    }
    setCheckedArray(temp);
    storeholidayCheckBox([...temp])
  }

  const handleSaveList = () => {
    setShowText(!showText)
    storeHolidayList()
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [showText, setShowText] = useState(false);
  const [count, setCount] = useState(0);
  const HolidayShowModal = () => {
    setIsImportModalVisible(true);
  }
  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    storeImportHoliday()
    getHolidayOverview()

  }, [])

  const onClickEdit = function () {
    editHoliday(true);
    getHolidayOverview();
    const holidayList = []
    for (const month of setAllHolidayList) {
      for (const holiday of month.data) {
        holidayList.push((moment(holiday?.date).format('YYYY-MM-DD')))
      }
    }
    storeCheck([...holidayList])
    storeholidayCheckBox([...holidayList])
  }

  const history = useHistory();

  const handleBackPress = () => {
    history.push('/attendance-dashboard');
    setBackToPrivilegedView(true);
  }

  const easySwitch = [
    { label: 'Leaves', action: () => { history.push('/leave-graph') } },
    { label: 'Geo Fence', action: () => { history.push('/attendance-geofence') } },
    { label: 'Holidays', action: () => { history.push('/holiday-page') } },
    { label: 'Attendance Requirement', action: () => { history.push('/attendance-requirements') } }
  ]

  return (
    <>
      <div style={bodyStyle}>
        <div style={{}}>
          <PageHeader goBack={true} onBackPress={handleBackPress} title='Holidays' easySwitch={easySwitch} />
        </div>

        <div className="m-t-60" style={{ display: "flex", }}>
          <div>
            {setAllHolidayLoc?.map((item) => (
              <div className=" userCardStyle:hover" onClick={() => {
                setCheckedArray([])
                storeholidayCheckBox([])
                if (item.title === '_GLOBAL_') {
                  setIsImport(false)
                  editHoliday(false);
                  storeImportHoliday(item.id);
                  getHolidayOverview();
                } else {
                  setIsImport(true)
                  editHoliday(false);
                  storeImportHoliday(item.id);
                  getHolidayOverview();

                }
              }}>
                <HolidayCard
                  importHolidayListNew={importHolidayListNew}
                  id={item.id}
                  title={item.title}
                  address={item.address}
                  editHolidayValue={editHolidayValue}
                  storeCheck={storeCheck}
                  setFenceId={setFenceId}
                />
              </div>
            ))}
          </div>
          <div style={{ width: "1800px", marginLeft: 20 }}>
            <Heading className="m-l-10">
              Overview
            </Heading>
            <div
              className="m-t-10 m-r-10"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Overview
                num={setAllHolidayOverview?.total}
                days={'days'}
                remains={'Total holidays'}
              />

              <Overview
                num={setAllHolidayOverview?.previous}
                days={'days'}
                remains={'Total previous holidays'}
              />

              <Overview
                num={setAllHolidayOverview?.remaining}
                days={'days'}
                remains={'Remaining holidays'}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                // marginRight:"20px"
              }}
            >
              <Heading className="m-l-10">
                Holiday list
              </Heading>
              <div className="r-jsb" style={{ width: '45%' }}>
                <div>
                  {<Button type='transparent' style={{ color: '#FFA931', fontWeight: 600 }} icon={<DownloadOutlined style={{ color: '#FFA931' }} />} onClick={HolidayShowModal}>IMPORT</Button>}
                </div>
                <div className="bold-600">
                  <Button type='transparent' style={{ color: '#1089FF', fontWeight: 600 }} icon={<PlusCircleOutlined style={{ color: '#1089FF' }} />} onClick={showModal}>ADD A HOLIDAY</Button>
                </div>
                {!editHolidayValue ?
                  <div style={{ fontSize: "13px", fontFamily: "Roboto", fontWeight: "bold", color: "#594099", marginRight: "20px", cursor: "pointer", marginTop: 5 }} onClick={onClickEdit}>
                    <EditOutlined style={{ marginRight: "15px", fontFamily: "Roboto" }} />EDIT LIST
                  </div>
                  :
                  holidayCheckBox?.length > 0 ?
                    <div style={{ fontSize: "13px", fontFamily: "Roboto", fontWeight: "bold", color: "#594099", marginRight: "20px", cursor: "pointer" }} onClick={() => { holidayDetail(() => { handleSaveList() }); editHoliday(false) }}>
                      SAVE LIST
                    </div>
                    :
                    null
                }
              </div>
            </div>
            <div className="m-t-10">
              <HolidayListCard
                setAllHolidayLoc={setAllHolidayLoc}
                setAllHolidayOverview={setAllHolidayOverview}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                showModal={showModal}
                isImportModalVisible={isImportModalVisible}
                setIsImportModalVisible={setIsImportModalVisible}
                HolidayShowModal={HolidayShowModal}
                setAllHolidayList={setAllHolidayList}
                holidayCheckBox={holidayCheckBox}
                storeholidayCheckBox={storeholidayCheckBox}
                checkedArray={checkedArray}
                setCheckedArray={setCheckedArray}
                onChange={onChange}
                showText={showText}
                setShowText={setShowText}
                editHolidayValue={editHolidayValue}
                isImport={isImport}
                setIsImport={setIsImport}
                clearHolidayList={clearHolidayList}
                checkedValue={checkedValue}
                NoDataCard={NoDataCard}
              />
            </div>
          </div>
          {isModalVisible &&
            <CreateModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              showModal={showModal}
              clearHolidayList={clearHolidayList}
              createHolidayList={createHolidayList}
            />
          }

          <div>
            {isImportModalVisible &&
              <HolidayModal
                isImportModalVisible={isImportModalVisible}
                setIsImportModalVisible={setIsImportModalVisible}
                HolidayShowModal={HolidayShowModal}
                editHolidayValue={editHolidayValue}
                trackSelectedRadio={trackSelectedRadio}
                selectedImportRadio={selectedImportRadio}
              />
            }
          </div>
        </div>
        <PageDataLoader visible={holidayPageLoader} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { values, checkedValue, setAllHolidayList, setAllHolidayLoc, setAllHolidayOverview, holidayCheckBox, saveHolidayListDetailNew, editHolidayValue, holidayPageLoader, importHolidayListNew, createHolidayList, selectedImportRadio } = state.attendanceNew;
  return { values, checkedValue, setAllHolidayList, setAllHolidayLoc, setAllHolidayOverview, holidayCheckBox, saveHolidayListDetailNew, editHolidayValue, holidayPageLoader, importHolidayListNew, createHolidayList, selectedImportRadio };
};

const mapDispatchToProps = (dispatch) => ({
  getHolidayOverview: () => dispatch(getHolidayOverview()),
  storeholidayCheckBox: (val) => dispatch(storeholidayCheckBox(val)),
  storeImportHoliday: (val) => dispatch(storeImportHoliday(val)),
  storeHolidayList: (val) => dispatch(storeHolidayList(val)),
  holidayDetail: () => dispatch(holidayDetail()),
  editHoliday: (val) => dispatch(editHoliday(val)),
  trackSelectedRadio: (val) => dispatch(trackSelectedRadio(val)),
  setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
  storeCheck: (val) => dispatch(storeCheck(val)),
  uncheckedValue: (val) => dispatch(uncheckedValue(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(Holiday);