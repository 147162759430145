import React, { useState } from 'react';
import { newCardStyle } from "./courseInformationPage";
import "./styles.css";
import { Button } from 'components/Button';
import { EditOutlined } from '@ant-design/icons';

function LiveCoursePlanning({ isCoursePlanningEditClicked, setIsCoursePlanningEditClicked, postCoursePlanningData, getCoursePlanningData, coursePlanningData, setCoursePlanningData, setIsViewDetailsClicked, hasMarketingPrivilege }) {
    const [coursePlanningSaveLoader, setCoursePlanningSaveLoader] = useState(false);
    const coursePlanningArray = [
        {
            label: "Classes scheduled",
            id: "plannedClasses"
        },
        {
            label: "Total hours",
            id: "plannedHours"
        },
        {
            label: "Tests scheduled",
            id: "plannedTests"
        }
    ];
    const handlePlanningInputChange = (e, id) => {
        let { value } = e.target;
        if (id !== "plannedHours") {
            value = value.replace(/[^\d]/g, '');
        }
        if (id === "plannedHours") {
            const decimalIndex = value.indexOf('.');
            if (decimalIndex !== -1) {
                const integerPart = value.substring(0, decimalIndex);
                const decimalPart = value.substring(decimalIndex + 1, decimalIndex + 2);
                value = `${integerPart}.${decimalPart}`;
            }
        }
        setCoursePlanningData(prevState => ({
            ...prevState,
            [id]: value && id === "plannedHours" ? parseFloat(value) : value ? parseInt(value) : null
        }));
    };

    const handleKeyDown = (e, id) => {
        if (e.key === 'e') {
            e.preventDefault();
        }
        if (id == "plannedClasses" || id == "plannedTests") {
            if (e.key == ".") {
                e.preventDefault();

            }
        }
    };

    return (
        <div style={{ ...newCardStyle, marginTop: 20, height: isCoursePlanningEditClicked ? 137 : 76, transition: "height 0.2s ease" }} className='course-planning-card'>
            {isCoursePlanningEditClicked ?
                <>
                    <div className='r-c-sb'>
                        <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Course planning</div>
                        <Button
                            loading={coursePlanningSaveLoader}
                            type="link"
                            style={{ fontSize: 14 }}
                            onClick={() => {
                                setCoursePlanningSaveLoader(true)
                                postCoursePlanningData(
                                    coursePlanningData,
                                    () => setCoursePlanningSaveLoader(false),
                                    () => {
                                        setIsCoursePlanningEditClicked(false);
                                        getCoursePlanningData((response) => {
                                            setCoursePlanningData({
                                                plannedClasses: response?.plannedClasses,
                                                plannedHours: response?.plannedHours,
                                                plannedTests: response?.plannedTests
                                            });
                                        })
                                    })
                            }}
                        >SAVE</Button>
                    </div>
                    <div className='r-jsb m-t-5'>
                        {coursePlanningArray?.map((item) => (
                            <div style={{ width: "28%" }}>
                                <label style={{ color: "#8C8C8C", fontSize: 12, fontWeight: 600 }}>{item?.label}{" "} </label>
                                <input
                                    type="number"
                                    className="custom-input"
                                    value={coursePlanningData[item.id]}
                                    onChange={(e) => handlePlanningInputChange(e, item.id)}
                                    style={{ color: "#636363", fontSize: 14, backgroundColor: "#FFFFFF" }}
                                    onKeyDown={(e) => handleKeyDown(e, item?.id)}
                                />
                            </div>
                        ))}
                    </div>
                </> :
                <div className='r-c-sb'>
                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 18 }}>Course planning</div>
                    <div style={{ color: "#636363", fontWeight: "normal" }}>Classes scheduled : <span style={{ color: "#191919", fontWeight: "bold" }}>{coursePlanningData?.plannedClasses}</span></div>
                    <div style={{ color: "#636363", fontWeight: "normal" }}>Total hours : <span style={{ color: "#191919", fontWeight: "bold" }}>{coursePlanningData?.plannedHours}</span></div>
                    <div style={{ color: "#636363", fontWeight: "normal" }}>Tests scheduled : <span style={{ color: "#191919", fontWeight: "bold" }}>{coursePlanningData?.plannedTests}</span></div>
                    {hasMarketingPrivilege ? 
                    <></> :
                    <div className='cursor-pointer show-course-planning-edit' style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold" }} onClick={() => {
                        setIsCoursePlanningEditClicked(true);
                        setIsViewDetailsClicked(false);
                    }}><EditOutlined /> EDIT</div>
                    }
                    
                </div>
            }
        </div>
    )
}

export default LiveCoursePlanning