import React, { useState } from 'react'
import TopBar from './topBar'
import Footer from './footer'
import "./myAcc.css"
import Search from 'antd/lib/input/Search'
import dropDownIcon from "../../Assets/aulasMarket/ic-arrow-head-down.svg"
import dropUpIcon from "../../Assets/aulasMarket/ic-arrow-head-up.svg"
import { Dropdown, Menu, Rate, Button, Progress, Modal, Tooltip } from 'antd'
import { CoursePageApi, VideoResourcePageApi } from 'actions/aulasMarket'
import courseImage from '../../Assets/aulasMarket/teamgrp.png';
import checkIcon from "../../Assets/aulasMarket/ic-check-filled.svg";
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFill from "../../Assets/aulasMarket/saveFilledIcon.svg"
import MyCourses from './myCourses'
import popularCourses from './popularCourses'

function MyAccount() {
    
    
    return (
        <div>
            <MyCourses />
        </div>
    )
}

export default MyAccount
