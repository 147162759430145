import React from 'react';
import {connect} from 'react-redux';
import PageHeader from 'antd/lib/page-header';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Avatar from 'antd/lib/avatar';
import DatePicker from 'antd/lib/date-picker';
import Progress from 'antd/lib/progress';
import Empty from 'antd/lib/empty';
import 'antd/lib/page-header/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/date-picker/style/index';
import 'antd/lib/progress/style/index';
import 'antd/lib/empty/style/index';
import { Color } from '../../../services';
import '../styles.css';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getStudentDetail, setSelectedStudent, setDateRange } from '../../../actions/attendance-old'
import { BarGraphIcon, UserPresentIcon, UserAbsentIcon, BookOpenIcon } from '../../../icons';
import Styles from './styles';
import { Api } from '../../../services'


const { RangePicker } = DatePicker;

class StudentWiseView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      pickerOpen: false,
    }
  }

  handleDateSelect(date, history){
    this.props.setDateRange(date);

    this.props.getStudentDetail(this.props.user, date);
  }

  // pageShowCalendar = () => {
  //
  // }
  togglePicker = () => {
      this.setState({pickerOpen: !this.state.pickerOpen});
  }


  componentDidMount() {
    const date = [this.props.startDate, this.props.endDate]
    this.props.getStudentDetail(this.props.user, date);
  }

  render() {
    const {history, studentDetail, startDate, endDate } = this.props;

    const operations = (
      <div style={{position:'absolute', right: 0, top: 0}}>
        <RangePicker
        style={{borderRadius: 10}}
        onChange={(dateMoment, date) => this.handleDateSelect(date, history)}
        renderExtraFooter={() => 'extra footer'}
        defaultValue={[moment(startDate), moment(endDate)]}
        />
      </div>
      );

    return (
      <div style={{height:'100%'}}>
        <PageHeader
          style={{padding: 0, paddingBottom: 20}}
          className="site-page-header"
          title={'My Attendance'}
          extra={operations}
          />
        <Row style={{height:'100%'}}>
          <Col style={{height: '90%', overflowY: 'auto'}} xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}}>
            {/* singleClass.students && singleClass.students.length ?
              selectedStudentId !== -1 ?*/}
              <>
              <Row>
                <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}}>
                <div style={{ border: '1px solid lightgray', borderTopLeftRadius: 10, borderTopRightRadius: 10, marginRight:5}}>
                  <Row style={{padding: 15}}>
                    <Col xs={{span:4}} sm={{span:4}} md={{span:3}} lg={{span:2}}>
                      <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems:'flex-end', justifyContent:'flex-end'}}>
                        <Avatar src={studentDetail ? `${Api._s3_url}${studentDetail.image}` :null} size={95} style={{size: 'small'}}/>
                      </div>
                    </Col>
                    <Col xs={{span:21}} sm={{span:21}} md={{span:21}} lg={{span:22}}>
                      <Row>
                        <Col xs={{span:10}} sm={{span:10}} md={{span:10}} lg={{span:10}} style={{paddingTop: '4%'}}>
                          <Row style={{marginLeft:20, fontSize: 16}}>{studentDetail ? studentDetail.name : ''}</Row>
                          <Row style={{marginLeft:20, fontSize: 12, color: 'gray'}}>Roll No. {studentDetail ? studentDetail.rollNo : ''}</Row>
                        </Col>
                        <Col xs={{span:14}} sm={{span:14}} md={{span:14}} lg={{span:14}}>
                          <Row style={{position: 'relative', float: 'right'}}>
                            <div  style={Styles.attendanceStatCard}>
                              <BarGraphIcon style={{paddingTop:10, color:Color.primary, marginTop:5}}/>
                              <div style={{fontSize: 10}}>Average</div>
                              <div style={{fontSize: 14, color: Color.primary}}>{studentDetail ? studentDetail.average : ''}<span style={{fontSize: 12}}>%</span></div>
                            </div>
                            <div  style={Styles.attendanceStatCard}>
                              <UserPresentIcon style={{paddingTop:10, color:"#61BB45", marginTop:5}}/>
                              <div style={{fontSize: 10}}>Present</div>
                              <div style={{fontSize: 14, color: Color.primary}}>{studentDetail ? studentDetail.present : ''}</div>
                            </div>
                          {/*  <div  style={ Styles.attendanceStatCard}>
                              <UserMinusIcon style={{paddingTop:10, color:"#fff04d", marginTop:5}}/>
                              <div style={{fontSize: 10}}>Halfday</div>
                              <div style={{fontSize: 14, color: Color.primary}}>2</div>
                            </div> */}
                            <div  style={ Styles.attendanceStatCard}>
                              <UserAbsentIcon style={{paddingTop:10, color: Color.red, marginTop:5}}/>
                              <div style={{fontSize: 10}}>Absent</div>
                              <div style={{fontSize: 14, color: Color.primary}}>{studentDetail ? studentDetail.absent : ''}</div>
                            </div>
                            <div  style={ Styles.attendanceStatCard}>
                              <BookOpenIcon style={{paddingTop:10, color:Color.primary, marginTop:5}}/>
                              <div style={{fontSize: 10}}>Subjects</div>
                              <div style={{fontSize: 14, color: Color.primary}}>{studentDetail && studentDetail.subjects ? studentDetail.subjects.length : 0}</div>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <Row><Progress style={{marginLeft:10}} percent={studentDetail.average} showInfo={false} strokeColor="#61BB45" trailColor="#FF7D5C"/></Row>
                    </Col>
                  </Row>
                  </div>
                  <Row>
                    <div style={{ width: '100%',  border: '1px solid lightgray', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, marginRight:5}}>
                    <Row>
                    { studentDetail && studentDetail.subjects && studentDetail.subjects.length > 0 ?
                      studentDetail.subjects.map((subject, index) => (
                        <div key={index} style={{border: '1px solid lightgrey', height: 30, minWidth: 150, margin: 10, borderRadius:5, padding: 2}}>
                          <Row>
                            <Avatar style={{backgroundColor: Color.primary}} size='small' >{subject.name.charAt(0)}</Avatar>
                            <div style={{color: Color.primary, marginLeft: 10}}>{`${subject.name} - ${subject.average}`}</div>
                          </Row>
                        </div>
                      ))
                      : null }
                    </Row>
                    </div>
                  </Row>
                </Col>
              {/* <Col xs={{span:2}} sm={{span:2}} md={{span:2}} lg={{span:2}} style={{ display:'flex', justifyContent:'center'}}>
                  // <Col xs={{span:7}} sm={{span:7}} md={{span:7}} lg={{span:7}} style={{ height:240, overflowY: 'scroll', borderRadius: 10}}>
                  //     <Calendar
                  //       fullscreen={false}
                  //       open={this.state.pickerOpen}
                  //       style={Styles.calendar}
                  //       />
                  <CalendarOutlined
                    onClick={() => this.togglePicker()}
                    className='r-c-c'
                    style={{border:'1px solid #c5c5c5', borderRadius:50, width:40, height:40, color:'#c5c5c5', fontSize:20}}/>
                    // <DatePicker
                    //   open={this.state.pickerOpen}
                    //   dropdownClassName='studentsCalendarDropdown'
                    //   onChange={(date) => console.log(moment(date).format('YYYY-MM-DD'))}
                    //   style={{position:'absolute', borderRadius:50, width:40, height:40, zIndex:'-1001',top:0 }}  />

                </Col>*/}
              </Row>
              <Row>
                <div style={{ padding: 10, width: '100%', margin: 20}}>
                {  studentDetail.attendance &&  studentDetail.attendance.length && studentDetail.subjects.length>0 ?
                    studentDetail.attendance.map((singleDay, index) => (
                      <div key={index}>
                        <div style={Styles.dateBox}>
                          <div style={{fontSize: 12, color: Color.primary}}> {singleDay.month} </div>
                          <div style={{fontSize: 14, fontWeight: 'bold'}}> {singleDay.date} </div>
                        </div>
                        <Row>
                        { singleDay.schedules && singleDay.schedules.length>0 ?
                          singleDay.schedules.map((schedule, index) => (
                            <Col key={index} xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:8}} xxl={{span:6}}>
                              <div style={Styles.singleSubBox}>
                                <div style={{...Styles.marker, backgroundColor: schedule.status === "present" ? '#61BB45' : Color.red}}>
                                  {
                                    schedule.status === "present" ?
                                  <CheckOutlined style={{color: 'white'}} /> :
                                   <CloseOutlined  style={{color: 'white'}} />
                                 }
                                </div>
                                <Row style={{width:'100%'}}>
                                  <Col span={15} style={{fontSize: 11, textAlign: 'left', marginLeft: 5}}>
                                    <Row style={{color: 'black', marginBottom: 3}}> {schedule.subject} - {schedule.teacher} </Row>
                                    {/* <Row style={{color: Color.darkGrey}}> Electromagnetic Waves </Row> */}
                                  </Col>
                                  <Col span={8} style={{fontSize: 10, textAlign: 'right'}}>
                                    <Row style={{color: 'black', marginBottom: 3}}>{schedule.time}</Row>
                                    <Row style={{color: Color.purple}}>{schedule.duration}mins</Row>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          ))
                          : <div>No Schedules for the day</div>}

                        </Row>
                      </div>
                    ))
                  : <Empty description="No schedules to display. Try changing the date range"/> }

                </div>
              </Row>
              </>
              {/* : <Empty description="Please select a student"/>  : <Empty style={{marginTop: 30}} description="No data found. Try changing the date range" /> */}

          </Col>


        </Row>
      </div>
      )
  }
}

const mapStateToProps = state => {
  const { singleClass, studentDetail, startDate, endDate, selectedStudentId, selectedStudentLoader } = state.attendance
  const { user } = state.session;
  return { singleClass, studentDetail, startDate, endDate, selectedStudentId, selectedStudentLoader, user }
};

const mapDispatchToProps = dispatch => ({
  getStudentDetail: (data, date) => dispatch(getStudentDetail(data, date)),
  setSelectedStudent: (id) => dispatch(setSelectedStudent(id)),
  setDateRange: (date) => dispatch(setDateRange(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentWiseView);
