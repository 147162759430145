import { ArrowLeftOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { fetchClassByCurrData, setImportMeta } from "actions/testAdmin";
import { Col, Progress, Row } from "antd";
import {Button} from 'components/Button'
import PageDataLoader from "components/pageDataLoader";
import PageHeader from "components/pageHeader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Loader from "./localComponent/loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ImportClasses = ({ importMeta, fetchClassByCurrData, setImportMeta, loaders }) => {
  const [pageLoader, togglePageLoader] = useState(false)
  const history = useHistory();
  const location = useLocation()
  useEffect(() => {
    setImportMeta('sampleGroupBool', true);
    togglePageLoader(true)
    fetchClassByCurrData(() => togglePageLoader(false));
  }, []);
  const ClassCard = ({ standardData, curriculumId, setSelectedClassData,index1 }) => {
    return (
      <div className="adminTestCard customCardShadow m-t-10 cursor-pointer shadow-box hover-shadow m-r-20"
        style={{ height: 130, width: 250, minWidth: 120, padding: 0, borderRadius: 10, padding: 20 }}
        onClick={() => {
          setImportMeta('selectedStandardObj', { ...standardData, curriculumId });
          // console.log("lllll",importMeta?.classCardData?.[index1]?.name);
          // history.push({
          //   pathname: "sample-question-paper",
          //   state : {
          //     curriculumName : importMeta?.classCardData?.[index1]?.name
          //   }})
          if (importMeta.selectedTypeId===1){
            setImportMeta('selectedSampleQuestionPapers', []);
            setImportMeta('sampleGroupBool', true);
            setImportMeta('sampleIndividualBool', false);
            setImportMeta('sampleGroupAllSelectBool' , false);
            if(location?.state?.key){
              history.push({pathname:'/sample-question-paper', state:{key:"fromClassroom"}});
            }else if(location?.state?.testSeriesData) {
              history.push({pathname:'/sample-question-paper', state: location?.state });
            }
            else {
              history.push('sample-question-paper');
            }
          }else if(importMeta.selectedTypeId===2){
              history.push({pathname:'/test-import-subjects', state: location?.state });
          }
        }}>
        <Row style={{ height: "56%" }}>
          <Col span={24}>
            <div className="bold r-c-c-c" style={{ color: "#594099", fontFamily: "serif" }}>
              <div style={{ width: "100%" }} className='text-xlg text-center text-overflow p-r-5 p-l-5'>
                {standardData.name}
              </div>
              <div style={{ width: '100%', color: "#8C8C8C" }} className="text-center text-overflow p-r-5 p-l-5">
                {standardData.stream == "" ? "NA" : standardData.stream}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const actions = [
    <Button type="" onClick={() => { history.goBack() }} style={{}}>CANCEL</Button>
  ]
  return (
    <div style={{
      backgroundColor: "#FAFAFA", margin: -20, padding: 20
    }}>
      <div style={{ marginBottom: 70 }}>
        <PageHeader title={`Import ${importMeta?.selectedQBType}`} actions={actions} />
      </div>
      {importMeta?.classCardData?.map((curriculumData, index) => (
        <>
          <div className="text-xmd bold-600 color-black m-b-15" style={{ fontSize: 19 }}>
            {" "}
            {curriculumData.name}{" "}
          </div>
          <div className="full-width r-afs flex-wrap m-b-20">
            {curriculumData.standards?.map((standardData, index1) => (
              <ClassCard
                type="classcard"
                index1={index1}
                standardData={standardData}
                curriculumId={curriculumData.id}
              />
            ))}
          </div>
        </>
      ))}
      <PageDataLoader visible={pageLoader} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.testAdmin;
  return {  
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchClassByCurrData: (callback) => dispatch(fetchClassByCurrData(callback))
})


export default connect(mapStateToProps, mapDispatchToProps)(ImportClasses);
