import '../styles.css';
import React, { Component } from 'react';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import 'antd/lib/typography/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import {  MdClose } from "react-icons/md";
import { Color } from 'services';
import {connect} from 'react-redux';
import {editSubject, getClass} from 'actions/classRoom';


const { Title } = Typography;


class EditSubjectModal extends Component {
    constructor(){
      super();
      this.state = {
        subjectName: '',
        buttonLoader: false
      }
    }

    componentDidMount(){
        this.setState({subjectName: this.props.subject.name})
      // this.props.changeDetail({name: "", section: "", stream: ""})
    }

    handleSaveClick(){
        this.setState({buttonLoader: true});
        const params = {
            name: this.state.subjectName,
            subjectId: this.props.subject.id,
        }
        this.props.editSubject(params, () => this.setState({buttonLoader: false}), (sub) => this.onSuccess(sub));
    }

    onSuccess(sub){
      this.props.getClass(null, sub)
      this.props.onClose()
    }    

    render () {
      const { visible, onClose } = this.props;
      const {subjectName, buttonLoader} = this.state;

      return (
        <Modal
          visible={visible}
          className='modal-border-15'
          width={600}
          closable={false}
          onCancel={() => {}}
          footer={null}
          centered
        >
          <div>
            <div className='r-c-sb'>
              <Title style={{color: Color.primary, margin:0}} level={4}>Edit Subject</Title>
              <div className='cursor-pointer' onClick={() => onClose(false)}><MdClose className='text-md dark-grey'/></div>
            </div>
            <div className='m-b-20 m-t-30'>
              <div className='flex-1'>
                <div className='color-black text-xs m-b-5 bold-600'>Subject Name</div>
                <div style={{maxHeight: 200, overflowY: 'auto'}}>
                    <Input className='input-text-grey m-t-10' placeholder="What’s the name of the subject?" 
                    value={subjectName} onChange={(e) => this.setState({subjectName: e.target.value})}/>
                </div>
                </div>
            </div>
            <div className='r-c-c m-t-40'>
              <Button loading={buttonLoader} type='primary' className='btn-radius-15__height-38'  
                style={{width:'120px'}}
                disabled={this.state.subjectName.trim() === "" || this.state.subjectName === this.props.subject.name}
                onClick={() => this.handleSaveClick()}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      )
    }
}


const mapStateToProps = state => {
  const {
    showAddChapterModal, selectedSubject
  } = state.classRoom;
  return {
    showAddChapterModal, selectedSubject
  };
};

const mapDispatchToProps = dispatch => ({
  editSubject: (params, buttonCallback, callback) => dispatch(editSubject(params, buttonCallback, callback)),
  getClass: (callback, sub) => dispatch(getClass(callback, sub))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSubjectModal);