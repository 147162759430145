import React from 'react';
import { CalendarSm, SummaryCard } from '../../components';
import {connect} from 'react-redux';
import Typography from 'antd/lib/typography';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Tooltip from 'antd/lib/tooltip';
import Select from 'antd/lib/select';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Empty from 'antd/lib/empty';
import AutoComplete from 'antd/lib/auto-complete';
import 'antd/lib/typography/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import 'antd/lib/empty/style/index';
import 'antd/lib/auto-complete/style/index';
import { Color } from '../../services';
import moment from 'moment';
import {
  showModal, getSchedule, postSchedule, deleteSchedule,
  hideModal,searchUser,
  fetchClasslist, endCall, updateFilterParams, resetFilterParams
} from '../../actions/calendar';
import './styles.css';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import AddScheduleModal from './addSchedulModal'

const { Title } = Typography;
const { Option } = Select;

const { confirm } = Modal;

class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const {getSchedule} = this.props;
    const date =  moment().format('YYYY-MM-DD'); //singleSchedule.selectedDate ||
    this.props.updateFilterParams(date, 'date');

    getSchedule();
  }


  componentWillUnmount(){
    this.props.resetFilterParams();
  }


  handleClassSelection(std) {
    const {getSchedule, updateFilterParams} = this.props;
    updateFilterParams(std,'standardId');
    getSchedule();
    // const {singleSchedule} = this.props;
    // this.setState({selectedStandard: std});

    // this.props.handleInput(std[0],'selectedClass');
    // this.props.handleInput(`${std[1]}${std[2]}`,'selectedClassNameSec');
    // this.getScheduleData(std[0], singleSchedule.selectedDate, singleSchedule.selectedTeacherFilter);
  }

  handleTeacherFilter(teacher, obj) {
    const {getSchedule, updateFilterParams} = this.props;
    if(obj.id === -1) return;
    updateFilterParams(teacher, 'teacherName');
    updateFilterParams(obj.id, 'teacherId');
    getSchedule();
    // const {singleSchedule} = this.props;
    // this.props.handleInput(obj.id,'selectedTeacherFilter');
    // this.props.handleInput(teacher,'teacherName');
    // this.getScheduleData(singleSchedule.selectedClass, singleSchedule.selectedDate, obj.id);
  }

  handleTacherSearch(teacher, obj){
    const { updateFilterParams} = this.props;
    updateFilterParams(teacher, 'teacherName');
    updateFilterParams(obj.id, 'teacherId');
    if(teacher === ""){
      this.handleTeacherFilter(teacher, obj);
    }

  }

  handleDateChange(date) {
    const {getSchedule, updateFilterParams} = this.props;
    updateFilterParams(date.format('YYYY-MM-DD'), 'date');
    getSchedule();
    // const {singleSchedule} = this.props;
    // this.props.handleInput(date.format('YYYY-MM-DD'),'selectedDate');
    // this.getScheduleData(singleSchedule.selectedClass, date.format('YYYY-MM-DD'), singleSchedule.selectedTeacherFilter);
  }


  handleEditClick(item) {
    const {singleSchedule} = this.props;

    this.props.fetchClasslist(item.subject.id)  ;

    const data = {
      // selectedClass: singleSchedule.selectedClass,
      selectedClass: singleSchedule.selectedClass,
      selectedDate: singleSchedule.selectedDate,
      selectedDays: item.weekdays,
      selectedSubject: item.subject.name,
      selectedClasses: item.standards.map((item) => item.id),
      selectedTeacher: item.teacher.name,
      selectedTeacherId: item.teacher.id,
      selectedSubjectId: item.subject.id,
      mode: item.mode,
      // selectedClassNameSec: item.standards.map((item, index, array) => <> {item.std}-{item.section} {index == array.length - 1 ? '' : '|'} </>),
      startTime: moment(item.startTime, 'HH:mm:ss'),
      endTime: moment(item.endTime, 'HH:mm:ss'),
      scheduleId: item.id,
      // standardId: item.standard.id
    }
    this.props.showModal(data);
  }

  handleDeleteClick(item) {

    const {singleSchedule} = this.props;

    const otherParams = {
      classes: singleSchedule.selectedClasses,
      date: singleSchedule.selectedDate,
    };
    this.props.deleteSchedule(item, otherParams);
  }

  showConfirm(item, self) {
    return confirm({
      title: 'Do you want to delete this schedule?',
      icon: <ExclamationCircleOutlined />,
    content: 'Please click OK to confirm',
    onOk() {self.handleDeleteClick(item)},
    onCancel() {},
  })
}

showEndConfirm(item, self) {
  let message = 'Class has not started yet.';
  let roomName = '';

  if(item.conference.length){
    roomName = item.conference[0].roomname;
    message = 'Do you want to end this class?';
  }

  return confirm({
    title: message,
    icon: <ExclamationCircleOutlined />,
  content: roomName === ''? '' : 'Please click ond End Class to confirm',
  okText: roomName === ''? 'Back': 'End Class',
  onOk() {roomName !== ''? self.props.handleEndClick(roomName): console.log('no class');
  },
  onCancel() {},
})
}

handleScheduleClass() {
  const {singleSchedule} = this.props;
  const data = {
    ...singleSchedule,
    selectedClass: parseInt(singleSchedule.selectedClass),
    selectedClasses: singleSchedule.selectedClasses,
    selectedDate: singleSchedule.selectedDate,
    standardId: parseInt(singleSchedule.selectedClass)
  }
  this.props.showModal(data);
}


render() {
  const { standards, schedules, togglePostLoader, singleSchedule, user , teachers,
     subClassesLoader, fetchScheduleLoader, filterParams, classModal} = this.props;
    return (
      <div style={{height: 'calc(100% - 80px)'}}>
        <div className='r-ac m-b-20' style={{backgroundColor:'#F9F9F9', padding: 10, borderRadius: 10}}>
          <Title style={{color: Color.darkGrey, margin:0}} level={3}>Time Table</Title>
          <div style={{borderRadius:30, width: '25%'}}>
            <Select
              className='roundBorderSelect borderLessSelect bold-600 m-l-20'
              value={filterParams.standardId}
              style={{borderRadius:30, width: '90%', color:Color.primary, fontSize:'1.2em'}}
              onChange={(value) => this.handleClassSelection(value)}
              loading={subClassesLoader}
              disabled={subClassesLoader || fetchScheduleLoader}
              >
              <Option key={-1} value="-1">All Classes</Option>
              {standards && standards.map((item, index) => <Option key={index} value={item.id}>{item.std} - {item.section}</Option>)}
            </Select>
          </div>
          {user.role.length === 1 && user.role.includes("Teacher")  ? null : <AutoComplete
            options={teachers}
            style={{marginLeft: 10, border:'none', width:'15%',  color:Color.primary,
              fontSize:16, fontWeight:600, borderRadius:30}}
              onSelect={(value, obj) => this.handleTeacherFilter(value, obj)}
              onSearch={(query) => this.props.searchUser(query)}
              value={singleSchedule.teacherName}
              onChange={(value, obj) => this.handleTacherSearch(value,obj)}
              disabled={subClassesLoader || fetchScheduleLoader}
              >
              <Input.Search style={{ border:'none', borderRadius:30}} placeholder="Search by teacher..." loading={togglePostLoader} allowClear/>
          </AutoComplete>}
         {(user.role.includes("SuperAdmin") || user.role.includes("Admin") || user.role.includes("Planner")) && <Tooltip title="Schedule a Class" >
            <Button
              type="primary"
              disabled={fetchScheduleLoader}
              onClick={() => this.handleScheduleClass()}
              style={{marginLeft: '2%', borderRadius: 30}}
            >
                <PlusOutlined />Add Schedule
            </Button>
          </Tooltip>}
          </div>

          <Row style={{height: '100%'}}>
            <Col xs={{span:8}} sm={{span:16}} md={{span:16}} lg={{span:16}} xl={{span:18}} xxl={{span:18}}>
              { !fetchScheduleLoader ?
              schedules && !schedules.length ?
                <Empty
                  style={{marginTop: 100}}
                  description={
                    <span>
                      No schedule for selected date. <br />
                  </span>
                }>
                {user.role && (user.role.includes("SuperAdmin") || user.role.includes("Admin")) ? <Button type="primary" onClick={() => this.handleScheduleClass()}> <PlusOutlined />Add Schedule </Button> : null}
                </Empty> :
              <Row>
                { /* {`${item.standard.std}${item.standard.section}`} */}
                {(schedules && schedules.length) ? schedules.map((item, i) =>
                  <Col key={i} xs={{span:24}} sm={{span:12}} md={{span:12}} lg={{span:12}} xl={{span:8}} xxl={{span:6}}>
                    <SummaryCard
                      key={i}
                      live={item.mode === "conference" || item.mode === "broadcast"}
                      isEditable={user.role.includes("SuperAdmin") || user.role.includes("Admin") || user.role.includes("Planner")}
                      color={item.subject ? item.subject.color : "green"}
                      topic={item.subject ? item.subject.name : ""}
                      subject={item.subject ? item.subject.name : ''}
                      teacher={item.teacher ? item.teacher.name : ''}
                      days={item.weekdays.map((item, index, array) => <React.Fragment key={index}> {item.charAt(0).toUpperCase()}{item.slice(1,3)} {index === array.length - 1 ? '' : '|'} </React.Fragment>) }
                      standard= {item.standards.map((item, index, array) => <React.Fragment key={index}> {item.std}-{item.section} {index === array.length - 1 ? '' : '|'} </React.Fragment>)}
                      time={`${moment(item.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(item.endTime, 'HH:mm:ss').format('HH:mm')}`}
                      start={false}
                      onEditClick={() => this.handleEditClick(item)}
                      onDelteClick={() => this.showConfirm(item, this)}
                      onEndClick={() => this.showEndConfirm(item, this)}
                      labelName={item.visibility === "Resume" ? "On Going" : item.visibility }
                      />
                  </Col>
                ): null
                }
              </Row> : <div className="r-c-c-c" style={{height: "100%"}}><LoadingOutlined style={{color: Color.primary, opacity: 0.5, fontSize: "10em"}}/> </div>
              }
            </Col>
            <Col xs={{span:16}} sm={{span:8}} md={{span:8}} lg={{span:8}} xl={{span:6}} xxl={{span:6}} style={{backgroundColor: Color.lightprimary, borderRadius: 50,display: 'flex', justifyContent: 'center'}}>
              <div style={{marginTop: 20}}>
                <CalendarSm onSelect={(date) => this.handleDateChange(date)} date={moment(filterParams.date)}/>
              </div>
            </Col>
          </Row>

            {classModal && <AddScheduleModal />}
            </div>
          );
        }
      }

      const mapStateToProps = state => {
        const {
          classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses, teachers,
          subClasses, subClassesLoader, fetchScheduleLoader, filterParams
        } = state.calendar;
        const { searchLoader, subjectsFound } = state.classRoom;
        const { user } = state.session;

        return {
          classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses,
          user, teachers, subClasses, subClassesLoader, searchLoader, subjectsFound, fetchScheduleLoader, filterParams
        };
      };

      const mapDispatchToProps = dispatch => ({
        getSchedule: () => dispatch(getSchedule()),
        showModal: (data) => dispatch(showModal(data)),
        postSchedule: (params, otherParams, callback) => dispatch(postSchedule(params, otherParams, callback)),
        deleteSchedule: (item, otherParams) => dispatch(deleteSchedule(item, otherParams)),
        hideModal: (data) => dispatch(hideModal(data)),
        searchUser: (query) => dispatch(searchUser(query)),
        fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
        handleEndClick: (query) => dispatch(endCall(query)),
        updateFilterParams: (val, key) => dispatch(updateFilterParams(val, key)),
        resetFilterParams: () => dispatch(resetFilterParams())
      });

      export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
