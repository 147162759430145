import { Button, Input, Modal } from 'antd'
import React from 'react';
import "./leaveDetailsModal.css";
import moment from 'moment';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';

function UserLocationDetailsModal({ isDetailsModalVisible, setIsDetailsModalVisible, locationDetailsContainer }) {

    const [map, setMap] = React.useState(null)

    const handleCancel = () => {
        setIsDetailsModalVisible(false)
    }

    const containerStyle = {
        width: "100%",
        height: "40vh"
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANNuGsbyjTzqrtYc65aRapQ1fkfaVh0aI",
        libraries: ["places"]
    })

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    let markers = [
        {
            id: 1,
            latitude: locationDetailsContainer?.clockInGeo?.latitude,
            longitude: locationDetailsContainer?.clockInGeo?.longitude,
            shelter: 'Clock-in location'
        },
        {
            id: 2,
            latitude: locationDetailsContainer?.clockOutGeo?.latitude,
            longitude: locationDetailsContainer?.clockOutGeo?.longitude,
            shelter: 'Clock-out location'
        }
    ]

    return (
        <div>
            <Modal
                className='modal-round-corner1'
                centered
                footer={null}
                header={null}
                visible={isDetailsModalVisible}
                onCancel={handleCancel}
                onOk={handleCancel}
                closable={false}
            >
                <div
                    style={{
                        marginTop: "10px",
                        fontSize: "22px",
                        color: "#191919",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Attendance Summary
                </div>
                <div style={{fontSize:"14px",color:"#636363",marginTop:10,fontWeight:"bold"}}>CLOCK IN DETAILS</div>
                {/* {
                    locationDetailsContainer?.manual === 1 ?
                        <div style={{ font: " normal normal bold 15px/19px Roboto", color: "#28DF99", marginTop: '30px', border: "none", display: "flex", flexDirection: "row-reverse" }}>
                            Manual Punch
                        </div>
                        :
                                null

                } */}
                <div style={{ background: "#FAFAFA 0% 0% no-repeat padding-box", borderRadius: "6px", height: '140px', marginTop: "10px",padding:20 }}>
                    <div style={{ display: "flex",justifyContent:"space-between" }}>
                        <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363"}}>Date</div>
                        <div style={{ font: "normal normal bold 15px/18px Roboto", color: " #191919"}}>{moment(locationDetailsContainer?.date).format("DD/MM/YYYY")}</div>
                    </div>

                    <div style={{ display: "flex",justifyContent:"space-between", marginTop: "5px"}}>
                        <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363" }}>Clock-in</div>
                        <div style={{ font: "normal normal bold 15px/18px Roboto", color: " #191919"}}>{locationDetailsContainer?.clockIn === null ? "0:00" : locationDetailsContainer?.clockIn}</div>
                    </div>
                    <div style={{ display: "flex",justifyContent:"space-between", marginTop: "5px" }}>
                        <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363"}}>Clock-out</div>
                        <div style={{ font: "normal normal bold 15px/18px Roboto", color: " #191919"}}>{locationDetailsContainer?.clockOut == null ? "0:00" : locationDetailsContainer?.clockOut}</div>
                    </div>
                    <div style={{ display: "flex",justifyContent:"space-between", marginTop: "5px"}}>
                        <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363"}}>Working hours</div>{console.log("AA-locationDetailsContainer?.duration",locationDetailsContainer?.duration)}
                        <div style={{ font: "normal normal bold 15px/18px Roboto", color: " #191919"}}>{Math.floor(locationDetailsContainer?.duration / 60)}{" "}:{(locationDetailsContainer?.duration % 60)}</div>
                    </div>
                </div>
                <div style={{display:"flex",marginTop:10,marginBottom:10}}>
                    <div style={{width:200}}>
                        <div style={{fontSize:12,color:"#191919",}}>Clock in using</div>
                        <div style={{fontSize:14,color:"#191919",fontWeight:"bold"}}>{locationDetailsContainer?.manual === 1 ?"Manual punch" : "Geo fence" }</div>
                    </div>
                    <div style={{width:200}}>
                        <div style={{fontSize:12,color:"#191919",}}>Clock in from</div>
                        <div style={{fontSize:14,color:"#191919",fontWeight:"bold"}}>{locationDetailsContainer?.clockInGeo?.title!==null ? locationDetailsContainer?.clockInGeo?.title : "-"}</div>
                    </div>
                    <div>
                        <div style={{fontSize:12,color:"#191919",}}>Clock out from</div>
                        <div style={{fontSize:14,color:"#191919",fontWeight:"bold"}}>{locationDetailsContainer?.clockOutGeo?.title!==null ? locationDetailsContainer?.clockOutGeo?.title : "-"}</div>
                    </div>
                </div>
                {locationDetailsContainer?.manual === 0 && (locationDetailsContainer?.attendanceStatus === "Present" || locationDetailsContainer?.attendanceStatus === "HalfPresent" || locationDetailsContainer?.attendanceStatus === "HalfPresentLeave" || locationDetailsContainer?.attendanceStatus === "HalfLeave") ?
                    <>
                        {isLoaded ?
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                onLoad={onLoad}
                                center={center}
                                onUnmount={onUnmount}
                                defaultZoom={15}
                                zoom={13}
                            >
                                {markers.map(marker => {
                                    return (
                                        <Marker
                                            key={marker.id}
                                            position={{ lat: marker.latitude, lng: marker.longitude }}
                                        >
                                            <InfoWindow>
                                                <div>
                                                    {marker.shelter}
                                                </div>
                                            </InfoWindow>
                                        </Marker>
                                    )
                                })}
                            </GoogleMap>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                <div style={{display:"flex",justifyContent:"center"}}>
                <Button style={{
                        width: "120px",
                        height: "30px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #636363",
                        borderRadius: " 100px",
                        marginTop: "10px",
                    }}
                    onClick={() => {
                        setIsDetailsModalVisible(false)
                    }}>CLOSE</Button>
                    </div>
            </Modal>
        </div>
    )
}

export default UserLocationDetailsModal