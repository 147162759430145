
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { addCourses, setCourseBillingPlan, setCourseDuration, setCoursePrice, setCourseRef, setInstallments } from 'actions/course';
import { Checkbox, DatePicker, Input, Modal, Select } from 'antd'
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ceil, parseInt } from "lodash";
import { postAddLiveCourseInstallMents, postAddFeeStudyMaterial, setDiscountDetails } from 'actions/courseManagement';
import { MdEdit, MdKeyboardBackspace } from "react-icons/md";
import moment from 'moment';

const { Option } = Select;

function FeeTermPlanCard({
    isFeeDetailsModalVisible, setFeeDetailsModalVisible, coursePrice, courseDuration,
    courseRef, setCoursePrice, setCourseBillingPlan, courseBillingPlan, installments,
    setInstallments, postAddLiveCourseInstallMents, setCourseDuration, setCourseRef, togglePageLoader,
    getSpecificCourseData, specificCourseDataContainer, courseDiscountDetails, setDiscountDetails,
    courseTypeName, postAddFeeStudyMaterial, getSpecificSMData, getSpecificVideoCourseData, setAddFeeTermPlan, setFeeDetails, feeDetails, parentIndex, item, addFeeTermPlan, toggleFeeCard, isFilterClicked, hasMarketingPrivilege }) {

    const [instCust, setInstCust] = useState([]);
    const [isCustomizePlan, setIsCustomizePlan] = useState(false);
    const [forInstallmentReset, setForInstallmentReset] = useState(false);
    const [selectYear, setSelectYEar] = useState(false);

    const [allowDates, setAllowDates] = useState(false)
    console.log("item456", item);
    const returnDays = (val) => {
        if (val == "Day(s)") {
            return "1";
        } else if (val == "Monthly" || val == "month" || val == "Month(s)") {
            return "30";
        } else if (val == "Quarterly") {
            return "90";
        } else if (val == "Half Yearly") {
            return "180";
        } else if (val == "Annually" || val == "Year(s)") {
            return "360";
        } else if (val == "Yearly") {
            return "360";
        } else if (val == "One time") {
            return "One time";
        } else if (val == "Customize") {
            return "Customize";
        }
    };

    console.log("setAllowDates", item?.hasDueDate);
    useEffect(() => {
        setCourseRef(returnDays(specificCourseDataContainer?.course?.durationType));
        setCourseDuration(specificCourseDataContainer?.course?.duration);
        setAllowDates(item?.hasDueDate)
    }, []);



    useEffect(() => {
        if (selectYear) {
            clacInstallments();

        }

    }, [item?.installmentTotal, courseRef, item?.billingPlan, courseDuration]);
    const clacInstallments = () => {
        console.log("avcdf43");

        let inst = [];

        if (item?.installmentTotal == "") {

            inst = [];
        } else if (
            item?.billingPlan !== "One time" &&
            item?.billingPlan !== "Customize"
        ) {

            let daysToYear = 0;
            if (courseRef == "1" && courseDuration >= "360") {
                daysToYear = parseInt(parseInt(courseDuration) % 365);
            }
            let noOfInst = daysToYear
                ? (parseInt(courseDuration) * parseInt(courseRef)) /
                parseInt(returnDays(item?.billingPlan))
                : Math.floor(
                    (parseInt(courseDuration) / 365) * 360 * parseInt(courseRef)
                ) / parseInt(returnDays(item?.billingPlan));
            if (parseInt(courseDuration) % 360 == 0 && courseRef == "1") {
                noOfInst = noOfInst;
            }

            for (let i = 0; i < ceil(noOfInst); i++) {
                var numb = parseInt(item?.installmentTotal) / ceil(noOfInst);
                numb = numb.toFixed(2);
                inst[i] = {
                    amount: item?.installments[i]?.amount
                        && !forInstallmentReset
                        ? item?.installments[i]?.amount
                        : numb,
                    dueDate: item?.installments[i]?.dueDate || undefined
                };

            }

        } else if (item?.billingPlan == "One time") {

            inst[0] = {
                amount: forInstallmentReset
                    ? parseInt(item?.installmentTotal).toFixed(2)
                    : item?.installments[0]?.amount,
                dueDate: item?.installments[0]?.dueDate || undefined,
            };
        } else if (item?.billingPlan == "Customize") {

            inst = !isCustomizePlan ?
                item?.installments
                :
                [
                    {
                        amount: null,
                        dueDate:moment().format('YYYY-MM-DD') || undefined,
                    },
                ];
        }
        const updateFee = [...feeDetails];
        updateFee[parentIndex].installments = [...inst];
        setFeeDetails(updateFee);
    };
    const AddCustomiseInstallMent = () => {
        const updateFee = [...feeDetails];
        updateFee[parentIndex].installments[item?.installments?.length] = {
            amount: "",
            dueDate: moment().format('YYYY-MM-DD') || undefined
        };
        setFeeDetails(updateFee);
    };
    const isValidNumber = (e) => {
        let pattern1 = /[^\d.]+/;
        let pattern2 = /^[^.]+(\.{0}[^.]*|\.{1}[^.]{0,2})$/;
        return (
            e.target.value === "" ||
            (pattern2.test(e.target.value) && !pattern1.test(e.target.value))
        );
    };

    function removeElement(array, index) {
        let tmp = [];
        for (let i = 0; i < array.length; i++) {
            if (i == index) {
            } else {
                tmp.push(array[i]);
            }
        }
        return tmp;
    }

    let disableFeeAddBtn = false;
    installments?.length ?
        installments?.some((item) => (!item?.amount ? disableFeeAddBtn = true : disableFeeAddBtn = false)) :
        disableFeeAddBtn = true

    let totalDiscount = 0;
    courseDiscountDetails.forEach((item) => {
        totalDiscount += +item.amount;
    });

    

    const handleCheckboxChange = (e) => {
        // setDefaultPlan(e.target.checked);
        const updatedFeeDetails = [...feeDetails];
        updatedFeeDetails[parentIndex].defaultPlan = e.target.checked;
        setFeeDetails(updatedFeeDetails);
    };
    console.log("defaultPlan",feeDetails);
    return (
        <div >
            <div className='m-t-20'>
                {addFeeTermPlan && parentIndex == feeDetails?.length - 1 ? <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                        <MdKeyboardBackspace
                            className="text-mdl cursor-pointer"
                            label="Back"
                            onClick={() => {
                                setAddFeeTermPlan(false)
                                const updateFee = [...feeDetails]
                                updateFee.splice(parentIndex, 1)
                                setFeeDetails(updateFee)

                            }}
                        /><div style={{ fontSize: "15px", fontWeight: 400, marginLeft: "10px" }}>Add fee payment term</div></div>
                    <div style={{ fontSize: "13px", color: "#1089FF", fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                            console.log("item90", item);
                            postAddLiveCourseInstallMents(
                                item,
                                () => { },
                                () => {
                                    setAddFeeTermPlan(false), getSpecificCourseData(() => {
                                        toggleFeeCard(isFilterClicked)

                                    });
                                },
                            );
                        }}><CheckCircleOutlined /> SAVE</div>
                </div> : null}
                <div>
                    <Checkbox
                    className='cehckbox'
                    disabled={hasMarketingPrivilege}
                    checked={item?.defaultPlan}
                        onChange={handleCheckboxChange} /><span style={{ fontSize: 12, color: "#636363", fontWeight: 400, marginLeft: 10 }}>DEFAULT PLAN</span>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>

                    {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources") ?
                        <></>
                        :
                        <div >

                            <h
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                            >
                                Payment term
                            </h>
                            {console.log("item?.billingPlan", item)}
                            <Select
                                bordered={null}
                                disabled={hasMarketingPrivilege}
                                placeholder="Select plan"
                                defaultValue={null}
                                style={{ width: "100%", marginLeft: "-10px", marginTop: "-10px" }}
                                value={item?.billingPlan}
                                onChange={(e) => {
                                    const updateFee = [...feeDetails];
                                    updateFee[parentIndex].billingPlan = e;
                                    setSelectYEar(true)
                                    setFeeDetails(updateFee);
                                    setAllowDates(false)
                                    setForInstallmentReset(true);
                                    if (e == "Customize") {
                                        setIsCustomizePlan(true)
                                    }
                                }}
                            >
                                {60 <= parseInt(courseDuration) * parseInt(courseRef) ? (
                                    <Option value="Monthly">Monthly</Option>
                                ) : null}
                                {180 <= parseInt(courseDuration) * parseInt(courseRef) ? (
                                    <Option value="Quarterly">Quarterly</Option>
                                ) : null}
                                {(
                                    365 <= parseInt(courseDuration) && courseRef == "1"
                                        ? 365 <= parseInt(courseDuration) && courseRef == "1"
                                        : 360 <= parseInt(courseDuration) * parseInt(courseRef) &&
                                        courseRef != "1"
                                ) ? (
                                    <Option value="Half Yearly">Half Yearly</Option>
                                ) : null}
                                {(
                                    730 <= parseInt(courseDuration) && courseRef == "1"
                                        ? 730 <= parseInt(courseDuration) && courseRef == "1"
                                        : 720 <= parseInt(courseDuration) * parseInt(courseRef) &&
                                        courseRef != "1"
                                ) ? (
                                    <Option value="Annually">Annually</Option>
                                ) : null}

                                <Option value="One time">One time</Option>
                                <Option value="Customize">Customize</Option>
                            </Select>
                            <div className='marginTop' style={{ borderTop: "1px solid #8C8C8C", }}></div>

                        </div>
                    }
                    <div >
                        <div
                            style={{
                                fontSize: "12px",
                                color: "#636363",
                                fontWeight: "bold",
                                width: "100%"
                            }}
                        >
                            Fee payable (after registration fee)
                        </div>
                        {console.log("check123", item)}
                        <Input
                            bordered={null}
                            disabled={hasMarketingPrivilege}
                            prefix="₹"
                            placeholder="Enter fee amount"
                            value={item?.installmentTotal}
                            onChange={(e) => {
                                const updateFee = [...feeDetails];
                                updateFee[parentIndex].installmentTotal = e.target.value;
                                setFeeDetails(updateFee);
                            }}
                        />
                        <div style={{ borderTop: "1px solid #8C8C8C", }}></div>

                    </div>
                </div>
                {item?.billingPlan === "Customize" ?
                    (<><div style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginTop: "10px"
                    }}>Payment term name <span style={{ color: "#FF414D" }}>*</span></div>
                        <Input value={item?.planName} bordered={null} placeholder='Enter payment term name'
                            onChange={(e) => {
                                const updateFee = [...feeDetails];
                                updateFee[parentIndex].planName = e.target.value;
                                setFeeDetails(updateFee);
                            }} />
                        <div style={{ borderTop: "1px solid #8C8C8C", marginTop: "2px" }}></div></>)
                    : null}

                <div style={{ fontSize: "12px", color: "#8C8C8C", marginTop: "10px" }}>
                    {console.log("allowDates", allowDates)}
                    <Checkbox
                        checked={allowDates}
                        disabled={hasMarketingPrivilege}
                        onChange={(e) => {
                            setAllowDates(e.target.checked);
                            if (!e.target.checked) {
                                const updateFee = [...feeDetails];
                                updateFee[parentIndex].installments.forEach(installment => {
                                    installment.dueDate = undefined;
                                });
                                console.log("updateee1", updateFee);

                                setFeeDetails(updateFee);
                            } else {
                                const updateFee = [...feeDetails];
                                updateFee[parentIndex].installments.forEach(installment => {
                                    installment.dueDate = moment().format('YYYY-MM-DD');
                                });
                                console.log("updateee1", updateFee);

                                setFeeDetails(updateFee);
                            }


                        }}
                    />  <span style={{ color: "#636363", fontSize: '13px', fontWeight: 400, marginLeft: "10px" }}>Add payment due date</span></div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "space-between"
                    }}
                >
                    {item?.installmentTotal == 0 || item?.installmentTotal &&
                        item?.installments?.map((items, index) => (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                }}
                            >
                                <div style={{ display: "flex", }}>
                                    <div >
                                        <p
                                            style={{
                                                marginTop: "10px",
                                                color: "#636363",
                                                marginBottom: "7px",
                                                fontWeight: "bold",
                                                fontFamily: "Roboto",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Installment {index + 1}
                                        </p>
                                        <Input
                                            type='number'
                                            disabled={hasMarketingPrivilege}
                                            bordered={null}
                                            prefix="₹"
                                            style={{ marginTop: "5px", marginLeft: "-3px", }}
                                            placeholder="Enter Installment Amount"
                                            value={items?.amount}
                                            onChange={(e) => {
                                                const updateFee = [...feeDetails];
                                                updateFee[parentIndex].installments[index].amount = e.target.value;
                                                setFeeDetails(updateFee);
                                            }}

                                        />{" "}
                                        <div style={{ borderTop: "1px solid #8C8C8C", marginTop: "2px" }}></div>

                                    </div>
                                    <>
                                        {(item?.installments?.length >= 1 && item?.billingPlan === "Customize") ?
                                            <div style={{ marginTop: "40px", marginLeft: 20, cursor: "pointer" }}
                                                onClick={() => {
                                                    const updateFee = [...feeDetails];
                                                    updateFee[parentIndex].installments.splice(index, 1);
                                                    setFeeDetails(updateFee);
                                                }}
                                            ><MinusCircleOutlined style={{ color: "red", cursor: "pointer", fontSize: 18 }} />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </>
                                    {allowDates ?
                                        <div className='margin-l' style={{ marginTop: "18px", cursor: hasMarketingPrivilege ? "" :"pointer" }}>
                                            <div style={{
                                                color: "#636363",
                                                fontWeight: "bold",
                                                fontFamily: "Roboto",
                                                fontSize: "12px",
                                            }}>Payment due date</div>
                                            <div >
                                                {console.log("items?.dueDate", items)}
                                                <DatePicker
                                                    allowClear={false}
                                                    disabled={hasMarketingPrivilege}
                                                    bordered={null}
                                                    format={'DD-MM-YYYY'}
                                                    value={moment(items?.dueDate)}
                                                    style={{ width: "180px" }}

                                                    onChange={(date, dateString) => {
                                                        const updateFee = [...feeDetails];
                                                        updateFee[parentIndex].installments[index].dueDate = moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD');
                                                        console.log("updateFee", updateFee);
                                                        setFeeDetails(updateFee);
                                                    }}
                                                /></div>
                                            <div style={{ borderTop: "1px solid #8C8C8C", marginTop: "4px" }}></div>

                                        </div> : null}

                                </div>
                            </div>
                        ))}
                </div>
                <div className='r-c-c'>
                    <div style={{ display: "flex", justifyContent: "space-around", width: "70%" }}>
                        {item?.billingPlan == "Customize" ? (
                            <div
                                className="r-c-c m-b-20 m-t-20"
                                style={{
                                    cursor: item?.installments?.length ? item?.installments[item?.installments?.length - 1]?.amount ? "pointer" : "not-allowed" : "pointer",
                                    color: "#1089FF",
                                    fontWeight: "600",
                                    fontFamily: "roboto",
                                    opacity: item?.installments?.length ? item?.installments[item?.installments?.length - 1]?.amount ? 1 : 0.5 : 1
                                }}
                                onClick={() => {
                                    if (item?.installments.length == 0) {
                                        AddCustomiseInstallMent();
                                    } else if (item?.installments[item?.installments?.length - 1].amount) {
                                        AddCustomiseInstallMent();
                                    }
                                }}
                            >
                                + ADD INSTALLMENT
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>


        </div>
    )
}
const mapStateToProps = (state) => {
    const { courseDuration, courseRef, coursePrice, courseBillingPlan, installments } = state.course;
    const { specificCourseDataContainer, courseDiscountDetails } = state.courseManagement;
    return {
        courseDuration, courseRef, coursePrice, courseBillingPlan, installments, specificCourseDataContainer, courseDiscountDetails
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCourseDuration: (val) => dispatch(setCourseDuration(val)),
    setCourseRef: (val) => dispatch(setCourseRef(val)),
    setCoursePrice: (val) => dispatch(setCoursePrice(val)),
    setCourseBillingPlan: (val) => dispatch(setCourseBillingPlan(val)),
    setInstallments: (val) => dispatch(setInstallments(val)),
    setDiscountDetails: (val) => dispatch(setDiscountDetails(val)),
    postAddLiveCourseInstallMents: (feeDetails, callback, successCallBack) => dispatch(postAddLiveCourseInstallMents(feeDetails, callback, successCallBack)),
    postAddFeeStudyMaterial: (callback, successCallBack) => dispatch(postAddFeeStudyMaterial(callback, successCallBack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeTermPlanCard);