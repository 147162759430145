
import { Button, Modal, Typography } from 'antd'
import React,{useState} from 'react';
import { Api } from 'services';
import moment from 'moment';
/* import "./Modals/leaveDetailsModal.css"; */
/* const image=require("../../Assets/Icon awesome-user-alt.svg").default; */

const ButtonDiv = ({setIsLeaveModalVisible,storeSelectedLeaves,storeselectedAttendance}) =>{
    return(
        <div className='m-l-50' style={{display:"flex"}}>
            {/* <Button style={{width:"140px",
            height:"40px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius:" 100px",
            marginTop:"40px"}}>DELETE</Button> */}
            {/* <div style={{width:"100px"}}></div> */}
            <div style={{display:"flex",marginLeft:"200px"}}>
            <Button  style={{width:"140px",
            height:"40px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius:" 100px",
            marginTop:"40px",
            marginLeft:"15px"}} onClick={()=>{setIsLeaveModalVisible(false)}}>CLOSE</Button>
           {/*  <Button style={{width:"140px",
            height:"40px",
            background: "#28DF99 0% 0% no-repeat padding-box",
            borderRadius:" 100px",
            marginTop:"40px",
            color:"#FFFFFF",
            marginLeft:"25px"}} >CLOSE</Button> */}
            </div>
            </div>
    )
}

const RejectedDiv = ({setIsLeaveModalVisible,storeSelectedLeaves,storeselectedAttendance,attendanceOrLeaves}) => {
    return(
        <div style={{height:"300px"}}>
            
            <div style={{width:"130px",height:"40px",marginTop:"10px",marginLeft:"450px"}}>
                <div style={{font: "normal normal bold 11px/16px Roboto",color: "#AEAEAE"}}>APPROVED  BY</div>
                <div style={{display:"flex",marginTop:"5px"}}>
                {attendanceOrLeaves==='attendance'?
                     <div ><img style={{height:"30px",width:"30px",borderRadius:"50%"}} src={`${Api._s3_url}${storeselectedAttendance?.actionBy?.image}`}></img></div>
                    :
                    <div ><img style={{height:"30px",width:"30px",borderRadius:"50%"}} src={`${Api._s3_url}${storeSelectedLeaves?.actionBy?.image}`}></img></div>

                    }
                
                <div style={{marginLeft:"10px"}}>
                    {
                        attendanceOrLeaves==='attendance'?
                        <>
                        <Typography.Text className="text-overflow-ellipsis" ellipsis={{tooltip:true}} style={{width: 130, font: "normal normal bold 14px/16px Roboto", color: "#191919"}}>
                            {storeselectedAttendance?.actionBy?.name}
                        </Typography.Text>
                          <div style={{font: "normal normal normal 13px/16px Roboto",color:" #636363"}}>{storeselectedAttendance?.actionBy?.role}</div>
                        </>:
                        <>
                         <Typography.Text className="text-overflow-ellipsis" ellipsis={{tooltip:true}} style={{width: 130, font: "normal normal bold 14px/16px Roboto", color: "#191919"}}>
                          {storeSelectedLeaves?.actionBy?.name}
                        </Typography.Text>
                         <div style={{font: "normal normal normal 13px/16px Roboto",color:" #636363"}}>{storeSelectedLeaves?.actionBy?.role}</div>
                        </>
                    }
                </div>
                </div>
            </div>
            <Button style={{width:"140px",
            height:"40px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius:" 100px",
            marginTop:"40px",marginLeft:"250px"}} onClick={() => setIsLeaveModalVisible(false)} >CLOSE</Button>
        </div>
    )
}

const ApprovedDiv = ({setIsLeaveModalVisible, storeSelectedLeaves,storeselectedAttendance,attendanceOrLeaves}) => {
    return(
        <div>
            <div style={{width:"130px",height:"40px",marginTop:"10px",marginLeft:"450px"}   }>
                <div style={{font: "normal normal bold 11px/16px Roboto",color: "#AEAEAE"}}>REJECTED BY</div>
                <div style={{display:"flex",marginTop:"5px"}}>
                {attendanceOrLeaves==='attendance'?
                     <div ><img style={{height:"30px",width:"30px",borderRadius:"50%"}} src={`${Api._s3_url}${storeselectedAttendance?.actionBy?.image}`}></img></div>
                    :
                    <div ><img style={{height:"30px",width:"30px",borderRadius:"50%"}} src={`${Api._s3_url}${storeSelectedLeaves?.actionBy?.image}`}></img></div>

                    }
                
                <div style={{marginLeft:"10px"}}>
                    {
                        attendanceOrLeaves==='attendance'?
                        <>
                         <div style={{font: "normal normal bold 14px/16px Roboto",color: "#191919"}}>{storeselectedAttendance?.actionBy?.name}</div>
                          <div style={{font: "normal normal normal 13px/16px Roboto",color:" #636363",marginTop:"3px"}}>{storeselectedAttendance?.actionBy?.role}</div>
                        </>:
                        <>
                        {/* <Typograpgy.Text> */}
                          <div style={{font: "normal normal bold 14px/16px Roboto",color: "#191919"}}>{storeSelectedLeaves?.actionBy?.name}</div>
                        {/* </Typograpgy.Text> */}
                         <div style={{font: "normal normal normal 13px/16px Roboto",color:" #636363",marginTop:"3px"}}>{storeSelectedLeaves?.actionBy?.role}</div>
                        </>
                    }
                  
                </div>
                </div>
            </div>
            <Button style={{width:"140px",
            height:"40px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius:" 100px",
            marginTop:"40px",marginLeft:"250px"}} onClick={()=>{setIsLeaveModalVisible(false)}} >CLOSE</Button>
        </div>
    )
}

function Leavesmodel({isLeaveModalVisible, setIsLeaveModalVisible,storeSelectedLeaves,modelDataArray,storeselectedAttendance, attendanceOrLeaves}) {

    const handleOk = () => {
        setIsLeaveModalVisible(false);
      };
      const handleCancel = () => {
        setIsLeaveModalVisible(false);
      };
      console.log(storeselectedAttendance,"hahaha");
  return (
        <Modal className='modal-round-corner1'
        centered
        footer={null}  
        header={null} 
        open={isLeaveModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}>
            <div style={{height:"330px"}}>
            <div style={{
              marginTop: "15px",
              fontSize: "24px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}>{attendanceOrLeaves === 'attendance' ? "Attendance Details" : "Leaves Details"}</div>
            <div style={{background: "#FAFAFA",borderRadius: "6px",height:'160px', marginTop: "10px"}}>
               <div className='p-5'>
                {
                    modelDataArray?.map((item)=>(
                        <div  className='flex-row'>
                            <div className='m-l-20 m-t-10' style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",width:"100px"}}>{item[0]}</div>
                            <div style={{marginTop:"6px"}}>:</div>
                            <Typography.Text ellipsis={{tooltip:true}} className='m-l-20 m-t-10' style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",width:"450px",marginTop:"1px"}}>{item[1]}</Typography.Text>
                     </div>
                    ))
                }            
                </div> 
            </div>
           {attendanceOrLeaves==="attendance"?
             storeselectedAttendance?.status==="Approved"?<RejectedDiv attendanceOrLeaves={attendanceOrLeaves} storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible} storeSelectedLeaves={storeSelectedLeaves}/>:
             storeselectedAttendance?.status==="Rejected"? <ApprovedDiv attendanceOrLeaves={attendanceOrLeaves} storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible}  storeSelectedLeaves={storeSelectedLeaves}/>:
             storeselectedAttendance?.status==="Pending"? <ButtonDiv attendanceOrLeaves={attendanceOrLeaves} storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible}  storeSelectedLeaves={storeSelectedLeaves}/>:null
            :
            storeSelectedLeaves?.status==="Approved"? <RejectedDiv attendanceOrLeaves={attendanceOrLeaves} storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible} storeSelectedLeaves={storeSelectedLeaves}/>:
            storeSelectedLeaves?.status==="Rejected"? <ApprovedDiv attendanceOrLeaves={attendanceOrLeaves} storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible}  storeSelectedLeaves={storeSelectedLeaves}/>:
            storeSelectedLeaves?.status==="Pending"? <ButtonDiv attendanceOrLeaves={attendanceOrLeaves} storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible}  storeSelectedLeaves={storeSelectedLeaves}/>:null
           
            }
           {/*  {
                storeselectedAttendance?.status==="Approved"||storeSelectedLeaves?.status==="Approved"? <RejectedDiv storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible} storeSelectedLeaves={storeSelectedLeaves}/>:
                storeselectedAttendance?.status==="Rejected"||storeSelectedLeaves?.status==="Rejected"? <ApprovedDiv storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible}  storeSelectedLeaves={storeSelectedLeaves}/>:
                storeselectedAttendance?.status==="Pending"||storeSelectedLeaves?.status==="Pending"? <ButtonDiv storeselectedAttendance={storeselectedAttendance} setIsLeaveModalVisible={setIsLeaveModalVisible}  storeSelectedLeaves={storeSelectedLeaves}/>:null
            }  */}

            </div>           
        </Modal>
  )
}

export default Leavesmodel