export const OfferAvailableTypes = [
    { label: "All", value: 'All' },
    { label: "A course", value: "singleCourse" },
    { label: "Course type", value: "course" },
    { label: "Course category", value: "category" },
    { label: "Course subcategory", value: "subcategory" },
];
export const OfferFilterTypes = [
    { label: "All", value: 'All' },
    { label: "Published", value: "published" },
    { label: "Unpublished", value: "unpublished" },
    { label: "Private codes", value: "privateOffer" },
    { label: "Code in display", value: "banner" },
    { label: "Expired codes", value: "expired" },
];
export const AvailableOfferFilterTypes = [
    { label: "All", value: 'All' },
    { label: "Published", value: "published" },
    { label: "Private codes", value: "privateOffer" },
    { label: "Code in display", value: "banner" },
];
export const UnavailableOfferFilterTypes = [
    { label: "All", value: 'All' },
    { label: "Unpublished", value: "unpublished" },
    { label: "Expired codes", value: "expired" },
];
export const OfferSortTypes = [
    { label: 'Newest', value: '' },
    { label: 'Oldest', value: 'oldest' }
];

export const MainSectionTypes = {
    createEdit: 'createEdit',
    gridList: 'gridList',
    tableList: 'tableList',
}

export const OfferTypeGraphColor = {
    published: "#D8F6FF",
    unpublished: "#FDF3CE",
    privateOffer: "#DFDEF5",
    banner: "#D5F8EF",
    timesAvailed: "#ADCFD9",
    expired: "#FBE7E9",
}

export const OfferTagText = {
    published: "PUBLISHED",
    unpublished: "UNPUBLISHED",
    expired: "EXPIRED",
    banner: "CODE IN DISPLAY",
    privateOffer: "PRIVATE",
}

export const OfferTagTextColor = {
    published: "#0AB1E6",
    unpublished: "#DAAB00",
    privateOffer: "#594099",
    banner: "#2EA083",
    expired: "#FF414D",
}