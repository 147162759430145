import React from "react";
import { Api } from '../../services';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import ReactQuill from "react-quill";
import { removeImage } from './imageHandler';
import QuillToolbar, {  formats, modules, uploadedImageName } from "./toolbar";
import "react-quill/dist/quill.snow.css";
// import katex from 'katex';
// import 'katex/dist/katex.min.css';
// window.katex = katex;
// import "./styles.css";
import './styles.css'




class FormulaEditor extends React.Component{
  static quillRef = null;
  static uploadUrl = "";
  constructor(props){
    super(props);
    this.state = {
      clickedBtn : ''
    }
  }

  componentDidMount(){
    FormulaEditor.quillRef.getEditor().focus()
  }


  onChange = (value) => {
    // FormulaEditor.quillRef.getEditor().focus();
    const { imagesList, deletedImages, imgPath } = this.props;
    let editorData =  FormulaEditor.quillRef ? FormulaEditor.quillRef.getEditorContents() : '';
    // editorData = value;
    const editorImgs = Array.from( new DOMParser().parseFromString( editorData, 'text/html' )
    .querySelectorAll( 'img' ) )
    .map( img => img.getAttribute( 'src' ) );
    // console.log('imagesList.arr', imagesList.arr);
    // console.log('value', value);

    if(editorData == '<p><br></p>'){
      if(imagesList.arr.length){
        imagesList.arr.forEach((item, index) => {
          const deleteIndex  = editorImgs.indexOf(item);
          if(deleteIndex === -1){
            deletedImages.arr.push(item);
            imagesList.arr.splice(index,1);
          }
        })
      }
    }else{
      if(editorImgs.length){
        if(!editorImgs.includes(null)){
          if(editorImgs.length > imagesList.arr.length){
            imagesList.arr.length = 0;
            editorImgs.map((item, index) => {
              imagesList.arr.push(item);
            })
          }else if(editorImgs.length < imagesList.arr.length){
            imagesList.arr.forEach((item, index) => {
              const deleteIndex  = editorImgs.indexOf(item);
              if(deleteIndex === -1){
                deletedImages.arr.push(item);
                imagesList.arr.splice(index,1);
              }
            })
          }
        }
      }else{
        if(imagesList.arr.length){
          imagesList.arr.forEach((item, index) => {
            const deleteIndex  = editorImgs.indexOf(item);
            if(deleteIndex === -1){
              deletedImages.arr.push(item);
              imagesList.arr.splice(index,1);
            }
          })
        }
      }
    }
    // console.log('imagesList.arr', imagesList.arr);
    imagesList.callback(imagesList);
    deletedImages.callback(deletedImages);
  }

  onSave(){
    const editorData = FormulaEditor.quillRef.getEditorContents();
    this.props.onChange(editorData)
  }

  clearEditorData = () => {
    const { imagesList, deletedImages, imgPath, data } = this.props;
    const editorData = FormulaEditor.quillRef.getEditorContents();
    const editorImgs = Array.from( new DOMParser().parseFromString( editorData, 'text/html' )
    .querySelectorAll( 'img' ) )
    .map( img => img.getAttribute( 'src' ) );
    // console.log('imagesList.arr+_+', imagesList.arr);
    if(editorData === '' &&  data === ''){
    } else if (data === '' && editorData === '<p><br></p>') {
    } else if(data === '' && editorData !== '<p><br></p>'){
      if(editorImgs.length){
        editorImgs.forEach((item, index) => {
            deletedImages.arr.push(item);
        })
        imagesList.arr.length = 0;
        imagesList.callback(imagesList);
        deletedImages.callback(deletedImages);
      }
    }else if (data === '<p><br></p>' && editorData === '<p><br></p>' ) {
    }else if (data === '<p><br></p>' && editorData !== '<p><br></p>' ) {
      if(editorImgs.length){
        editorImgs.forEach((item, index) => {
            deletedImages.arr.push(item);
        })
        imagesList.arr.length = 0;
        imagesList.callback(imagesList);
        deletedImages.callback(deletedImages);
      }
    }else if (data !== '<p><br></p>' && editorData !== '<p><br></p>' ) {
      // editorImgs.forEach((item, index) => {
      //   const deleteIndex  = imagesList.arr.indexOf(item);
      //   if(deleteIndex === -1){
      //     deletedImages.arr.push(item);
      //     imagesList.arr.splice(index,1);
      //   }
      // })
      this.props.onChange(editorData)
      console.log('imagesList.arr', imagesList.arr);
      console.log('editorImgs', editorImgs);
    }
  }

  render(){
    const {  data, id, addOption, disableOptAdd, closeModal, deleteQuillImgsBool } = this.props;
    
    return (
      <div className="text-editor">
        <QuillToolbar/>
        <ReactQuill
          selection={{start:0, end:0}}
          ref={(el) => FormulaEditor.quillRef = el}
          theme="snow"
          value={data} // data || ""
          onChange={(value) => this.onChange(value)}
          placeholder={"Type here..."}
          modules={modules}
          formats={formats}
          style={{height: '60vh'}}
          className='admin-test-quill'
        />

        <div className='r-c-c m-t-20'>
          <Button
            loading={this.state.clickedBtn === 'cancel' ? deleteQuillImgsBool : false}
            className='blankGreyBtnWrap radius-100'
            onClick={() => {this.clearEditorData(); closeModal(); this.setState({clickedBtn:'cancel'})}}>
            CANCEL
          </Button>
          <Button
            loading={this.state.clickedBtn === 'save' ? deleteQuillImgsBool : false}
            className='purpleBtnWrap radius-100 m-l-20'
            onClick={() => {this.onSave(); closeModal(); this.setState({clickedBtn:'save'})}} >
            SAVE & CLOSE
          </Button>
        </div>
      </div>
    );
  }
};


export default FormulaEditor;
