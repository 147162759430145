import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import LibraryCardDetails from '../components/LibraryCards_details'
import lecturenotes from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg"
import videoImg from "../../../Assets/digitalLibrary/ic-dl-lecture-videos.svg"
import questBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg"
import { connect } from 'react-redux'
import TeachAndStudNotes from '../components/teacherAndStudentNotes'
import TeachAndStudVideo from '../components/teachAndStudVideo'
import { fetchTeacherHomeData } from 'actions/digitalLibrary'

const TeacherHome = ({ getTeachersHomeData }) => {

    return (
        <div>
            <div style={{ fontSize: '28px', fontWeight: "700", marginTop: '10px', marginBottom: '10px' }}>Library</div>
            {Object.keys(getTeachersHomeData)?.length !== 0 ?
                <div>
                    <Row >
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Notes"} filesNO={getTeachersHomeData?.count?.notesCount ? getTeachersHomeData?.count?.notesCount : 0} images={lecturenotes} lastWeek={getTeachersHomeData?.count?.lastWeekLectureNotesCount} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Videos"} filesNO={getTeachersHomeData?.count?.videoCount ? getTeachersHomeData?.count?.videoCount : 0} images={videoImg} lastWeek={getTeachersHomeData?.count?.lastWeekVideoCount} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Test papers"} filesNO={getTeachersHomeData?.count?.quesBankCount ? getTeachersHomeData?.count?.quesBankCount : 0} images={questBankImg} lastWeek={getTeachersHomeData?.count?.lastWeekQuestionBankCount} />
                            </div>

                        </Col>
                    </Row>
                    <br></br>
                    {getTeachersHomeData?.lectureNotes?.length != 0 ?
                        <div className="m-t-10" style={{ fontWeight: "bold", fontSize: "25px" }}>Recently added notes</div> : null}
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
                        {getTeachersHomeData?.lectureNotes?.map((item) =>
                            <TeachAndStudNotes chapterName={item?.chapterName} topic={item?.title} file={item?.file} id={item?.id} types={"lecture-notes"} std={item?.standard?.std} section={item?.standard?.section} img={lecturenotes} subject={item?.subject?.name} />
                        )}
                    </div>

                    {getTeachersHomeData?.video?.length != 0 ?
                        <div className="m-t-10" style={{ fontWeight: "bold", fontSize: "25px" }}>Recently added videos</div> : null}

                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
                        {getTeachersHomeData?.video?.map((item) =>
                            <TeachAndStudVideo url={item?.link} file={item?.fileName} chapterName={item?.chapterName} topic={item?.title} types={"video"} std={item?.standard?.std} section={item?.standard?.section} subject={item?.subject?.name} id={item?.id} item={item}/>
                        )}
                    </div>
                    {getTeachersHomeData?.questionBank?.length != 0 ?
                        <div className="m-t-10" style={{ fontWeight: "bold", fontSize: "25px" }}>Recently added test papers</div> : null}
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
                        {getTeachersHomeData?.questionBank?.map((item) =>
                            <TeachAndStudNotes chapterName={item?.chapterName} topic={item?.title} file={item?.file} id={item?.id} types={"question-bank"} std={item?.standard?.std} section={item?.standard?.section} img={questBankImg} subject={item?.subject?.name} />
                        )}
                    </div>
                </div>

                :
                (
                    <Row >
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Notes"} filesNO={0} images={lecturenotes} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Videos"} filesNO={0} images={videoImg} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Test papers"} filesNO={0} images={questBankImg} />
                            </div>

                        </Col>
                    </Row>
                )}
        </div>
    )

}

const mapStateToProps = (state) => {
    const {
        getTeachersHomeData,

    } = state.digitalLibrary;
    return {
        getTeachersHomeData

    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchTeacherHomeData: () => dispatch(fetchTeacherHomeData())

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(TeacherHome);
