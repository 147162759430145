import React, { useState, useEffect } from "react";
import "../style.css";
import { Modal, Button, Radio} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { importHolidayLoc, storeImportHoliday } from "actions/attendanceNew";

// const Radios = ({importHolidayListNew,item, trackSelectedRadio, id, value, setValue, onRadioChange }) =>{

//   if(importHolidayListNew===item?.id){
//     return "";
//   }else{
//     return (
      
//     )
//   }
// }

const HolidayModal = ({holidayCreateLoader, isImportModalVisible, setIsImportModalVisible,storeImportHoliday ,importHolidayLoc,setAllHolidayLoc,importHolidayListNew, editHolidayValue, trackSelectedRadio, selectedImportRadio}) => {

  const [value, setValue] = useState(setAllHolidayLoc[0] ? setAllHolidayLoc[0]?.id : 0);

 
  const history = useHistory();
  
  const handleCancel = () => {
    setIsImportModalVisible(false);
  };

  const handleImport = () => {
    setIsImportModalVisible(false);
  };

  const onRadioChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    trackSelectedRadio(setAllHolidayLoc[0] ? setAllHolidayLoc[0]?.id : 0)
    console.log('key', setAllHolidayLoc[0]?.id)
  }, [])
    
  const removeSelectedGeoFence = (curr) => {
    if(curr.id == importHolidayListNew){
      return false;
    }
    return true;
  }

  return (
    <>
      <div>
        <Modal
          centered
          className="modal-round-corner"
          visible={isImportModalVisible}
          onCancel={handleCancel}
          onOk={handleImport}
          footer={null}
        >
          <div
            style={{
              marginTop: "30px",
              fontSize: "21px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Import Holiday List
          </div>
          <div style={{marginTop:"25px",display:"flex",flexDirection:"column",height:"20vh",overflowY:"scroll"}}>
          
          {setAllHolidayLoc.filter(removeSelectedGeoFence)?.map((item)=>(
          <Radio.Group onChange={onRadioChange} value={value}>
            <Radio value={item.id} onClick={()=>{trackSelectedRadio(item?.id)}} style={{fontSize:"15px",fontWeight:500,marginLeft:"12px", marginTop: 5, marginBottom: 5}}>
              {item.title}  
            </Radio>
         </Radio.Group>
          ))}
          </div>
         
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #636363 ",
                  color: "#636363",
                }}
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
            <div>
              <Button
              disabled={selectedImportRadio === null ? true : false}
              loading={holidayCreateLoader}
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "40px",
                fontWeight: "500",
                backgroundColor: "#594099",
                color: "#FFFFFF",
                opacity: importHolidayListNew ? 1 : 0.5
              }}
              onClick={()=>{ 
                // setVisible(true)
                importHolidayLoc(()=>{handleImport()})
                }}
              >
                IMPORT
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  const {importHolidayListNew,setAllHolidayLoc,holidayCreateLoader} = state.attendanceNew;
  return {importHolidayListNew,setAllHolidayLoc,holidayCreateLoader};
};

const mapDispatchToProps = (dispatch) => ({
  storeImportHoliday:(value)=>dispatch(storeImportHoliday(value)),
  importHolidayLoc:(callBack)=>dispatch(importHolidayLoc(callBack)),
  holidayLoader:(val)=>dispatch(holidayLoader(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayModal);
