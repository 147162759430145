
import { LoadingOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import React from 'react'
import { Color } from 'services';

function AutoSubmitModal({visible}) {
    return (
        <Modal
        visible={visible}
        width="500px"
        height="500px"
        closable={false}
        footer={false}
        >
            <div style={{height: "100%"}} className="r-c-c-c">
                <LoadingOutlined style={{fontSize: "5em", color: Color.primary, opacity: 0.5}}/>
                <div className="m-t-20">Submitting Test</div>
            </div>

            
        </Modal>
    )
}

export default AutoSubmitModal;