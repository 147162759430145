import React, { useState } from "react";
import TeX from "@matejmazur/react-katex";
import ReactHtmlParser from "react-html-parser";
import { Input, Tooltip } from "antd";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import solutionImg from 'Assets/aulasMarket/FileText.svg'


export const HtmlComponent = ({ item, toggleImageModal, setCurrentImg }) => {
	return ReactHtmlParser(item, {
		transform: (node) => {
			if (
				node.type === "tag" &&
				node.name === "span" &&
				node.attribs["data-value"]
			) {
				return <TeX math={`${node.attribs["data-value"]}`} />;
			}
			if (node.type === "tag" && node.name === "img") {
				return (
					<img
						alt="question"
						style={{ maxHeight: "160px", maxWidth: "160px" }}
						src={node.attribs.src}
						className="m-t-10 m-r-10 cursor-pointer"
						onClick={(e) => {
							e.stopPropagation();
							toggleImageModal(true);
							setCurrentImg(node.attribs.src);
						}}
					/>
				);
			}
		},
	});
};

const QuestionStatement = ({ data, toggleImageModal, setCurrentImg }) => {
	return (
		<div className="m-l-15" style={{ border: '1px solid yellow' }}>
			<HtmlComponent
				item={data}
				toggleImageModal={() => { }}
				setCurrentImg={() => { }}
			/>
		</div>
	);
};

const OptionsFields = ({
	answerObj,
	updateAnswerObj,
	type,
	quesContent,
	toggleImageModal,
	setCurrentImg,
	parentType,
	subIndex
}) => {

	let subAnswerObj;
	if (parentType == "OrQuestions" && answerObj?.orQuestionIndex == subIndex)
		subAnswerObj = answerObj
	else if (parentType == "OrQuestions")
		subAnswerObj = null
	else if (parentType == "Comprehensive" && answerObj?.length)
		subAnswerObj = answerObj?.find((subAnswerObj) => subAnswerObj?.subQuestionIndex === subIndex)
	else
		subAnswerObj = answerObj

	let isSelected = (selectedAnswer) => {
		if (parentType == "Comprehensive") {
			if (type === "MCQ" || type === "T/F")
				return subAnswerObj?.answer === selectedAnswer;
			else if (type === "MSQ")
				return subAnswerObj?.answers?.includes(selectedAnswer)
		}
		if (type === "MCQ" || type === "T/F")
			return subAnswerObj?.answer === selectedAnswer;
		else if (type === "MSQ")
			return subAnswerObj?.answers?.includes(selectedAnswer)
		else return false
	};


	const handleOptionSelect = (index) => {
		if (parentType == "OrQuestions")
			updateAnswerObj({
				answer: index,
				orQuestionIndex: subIndex
			})
		else if (parentType == "Comprehensive") {
			let newAnswerObj = answerObj?.filter(el => el.subQuestionIndex != subIndex) ?? []
			newAnswerObj.push({
				answer: index,
				subQuestionIndex: subIndex
			})
			updateAnswerObj(newAnswerObj)
		}
		else updateAnswerObj({ answer: index })
	}

	const handleMSQClick = (index) => {

		let newAnswers = subAnswerObj?.answers ? [...subAnswerObj?.answers] : []
		if (newAnswers.includes(index)) newAnswers = newAnswers.filter((answer) => answer != index);
		else newAnswers.push(index)

		if (parentType == "OrQuestions")
			updateAnswerObj({
				answers: newAnswers,
				orQuestionIndex: subIndex
			})
		else if (parentType == "Comprehensive") {
			let newAnswerObj = answerObj?.filter(el => el.subQuestionIndex != subIndex) ?? []
			newAnswerObj.push({
				answers: newAnswers,
				subQuestionIndex: subIndex
			})
			updateAnswerObj(newAnswerObj)
		}
		else updateAnswerObj({ answers: newAnswers })
	}



	if (type == "Numeric")
		return (
			<>
				<div className="m-l-40">
					<span style={{ fontWeight: "bold" }}> Answer </span>
				</div>
				<Input
					style={{ width: "35%", marginLeft: 40, marginBottom: 20 }}
					value={subAnswerObj?.answer}
					onChange={(e) => { handleOptionSelect(e.target.value) }}
					pattern="/(^$)|(^[0-9\.\-\e\+\/]+$)/"
					className="numericInput"
					placeholder="Enter Answer"
					type="number"
				/>
			</>
		);
	else if (type == "MCQ" || type == "MSQ")
		return (
			<>
				{quesContent?.options?.map((item, index) => (
					<div
						className="options cursor-pointer"
						onClick={(type == "MCQ") ? () => handleOptionSelect(index)
							: () => handleMSQClick(index)}
						key={index}
						style={{
							borderRadius: 10,
							backgroundColor: isSelected(index) ? "#F6F4FE" : null,
						}}
					>
						<div className="optionId">{String.fromCharCode(65 + index)}</div>
						<div style={{ wordBreak: "break-word" }}>
							<HtmlComponent
								item={item?.value}
								toggleImageModal={toggleImageModal}
								setCurrentImg={setCurrentImg}
							/>
						</div>
					</div>
				))}
			</>
		);
	else if (type == "T/F")
		return (
			<>
				<div
					className="options cursor-pointer"
					onClick={() => handleOptionSelect(true)}
					key={"true"}
					style={{
						borderRadius: 10,
						backgroundColor: isSelected(true) ? "#F6F4FE" : null,
					}}
				>
					<div style={{ display: "flex" }}>
						<div className="optionId" style={{ marginTop: 5 }}>
							<div>A</div>
						</div>
						<div style={{ marginTop: 5 }}>TRUE</div>
					</div>
				</div>

				<div
					className="options cursor-pointer"
					onClick={() => handleOptionSelect(false)}
					key={"false"}
					style={{
						borderRadius: 10,
						backgroundColor: isSelected(false) ? "#F6F4FE" : null,
					}}
				>
					<div style={{ display: "flex" }}>
						<div className="optionId" style={{ marginTop: 5 }}>
							<div>B</div>
						</div>
						<div style={{ marginTop: 5 }}>FALSE</div>
					</div>
				</div>
			</>
		);
	else return <></>;
};



const customInputElement = (placeholder, color, comment) => {
	if (placeholder === true) placeholder = "True";
	else if (placeholder === false) placeholder = "False";
	else; //type is numeric

	return (
		<Input
			style={{
				backgroundColor:
					color === "Correct"
						? "#E9FCF5"
						: color === "Incorrect"
							? "#FFE4D9"
							: "",
				margin: 20,
				marginTop: 10,
				width: "95%",
			}}
			value={placeholder}
			suffix={
				<div className="answerType">
					<img
						style={{ width: "0.7rem", height: "0.7rem", fontSize: 12 }}
						src={
							color === "Correct"
								? require("Assets/ic-attempted.svg").default
								: require("Assets/CloseCircle.svg").default
						}
					></img>
					<span
						style={{
							color:
								color === "Correct"
									? "green"
									: color === "Incorrect"
										? "red"
										: "",
							fontSize: 12,
						}}
					>
						{" "}{comment}{" "}
					</span>
				</div>
			}
			disabled
		/>
	);
};



const optionElement = (
	optionType,
	option,
	index,
	toggleImageModal,
	setCurrentImg
) => {
	if (optionType == "correctanswer-but-notstudentanswer" ||
		optionType == "correctanswer-and-studentanswer") {
		return (
			<div className="optionCard greenOptionCard" key={index}>
				<div className="answerType">
					<span>
						<img
							style={{ width: "0.7rem", height: "0.7rem" }}
							src={require("Assets/ic-attempted.svg").default}
						></img>
						<span style={{ color: "green", fontSize: 12 }}>
							{(optionType == "correctanswer-but-notstudentanswer") ?
								"Correct answer " : "Your answer "}
						</span>
					</span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c greenRadioButton">
						{String.fromCharCode(65 + index)}
					</div>
					<div className="answerOptionKey">
						<HtmlComponent
							item={option?.value}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/>
					</div>
				</div>
			</div>
		);
	} else if (optionType == "studentanswer-but-notcorrectanswer") {
		return (
			<div
				className="optionCard redOptionCard"
				key={index}

			>
				<div className="answerType">
					<span>
						<img
							style={{ width: "0.7rem", height: "0.7rem" }}
							src={require("Assets/CloseCircle.svg").default}
						></img>
						<span style={{ color: "red", fontSize: 12 }}> Your answer </span>
					</span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c redRadioButton">
						{String.fromCharCode(65 + index)}{" "}
					</div>
					<div className="answerOptionKey">
						<HtmlComponent
							item={option?.value}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="optionCard" key={index}>
				<div className="answerType">
					<span></span>
				</div>
				<div className="optionContainer">
					<div className="optionKey r-c-c">
						{String.fromCharCode(65 + index)}{" "}
					</div>
					<div className="answerOptionKey">
						<HtmlComponent
							item={option?.value}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/>
					</div>
				</div>
			</div>
		);
	}
};


const AnswerBox = ({ currentQ, submitStatus, toggleImageModal, setCurrentImg }) => {
	if (currentQ?.type === "MCQ") {
		return (
			<div className="optionCards">
				{currentQ?.questionContent?.options?.map((option, index) => {
					let optionType;
					if (
						submitStatus === "Attempted" ||
						submitStatus === "AttemptedAndBookmarked"
					) {
						if (index == currentQ?.answerContent?.answer)
							optionType = (index == currentQ?.attempt?.answer)
								? "correctanswer-and-studentanswer"
								: "correctanswer-but-notstudentanswer"
						else if (index == currentQ?.attempt?.answer)
							optionType = "studentanswer-but-notcorrectanswer";
						else optionType = null
					} else if (index == currentQ?.answerContent?.answer)
						optionType = "correctanswer-but-notstudentanswer";
					else optionType = null


					return optionElement(
						optionType,
						option,
						index,
						toggleImageModal,
						setCurrentImg
					)
				})}
				<div ><img style={{ width: "20px", marginTop: "-3px" }} src={solutionImg} />{" "}{" "}<h style={{ color: "#594099", fontSize: "16px", }}>Solution</h></div>
				<div style={{ marginTop: "10px" }}>
					<HtmlComponent
						item={currentQ?.questionContent?.solution} />
				</div>
			</div>
		)
	} else if (currentQ?.type === "MSQ") {
		return (
			<div className="optionCards">
				{currentQ?.questionContent?.options?.map((option, index) => {
					let optionType;
					if (
						submitStatus === "Attempted" ||
						submitStatus === "AttemptedAndBookmarked"
					) {
						if (currentQ?.answerContent?.answers?.includes(index))
							optionType = (index == currentQ?.attempt?.answer)
								? "correctanswer-and-studentanswer"
								: "correctanswer-but-notstudentanswer"
						else if (currentQ?.attempt?.answers?.includes(index))
							optionType = "studentanswer-but-notcorrectanswer";
						else optionType = null
					} else if (currentQ?.answerContent?.answers?.includes(index))
						optionType = "correctanswer-but-notstudentanswer";
					else optionType = null


					return optionElement(
						optionType,
						option,
						index,
						toggleImageModal,
						setCurrentImg
					)
				})}
				<div ><img style={{ width: "20px", marginTop: "-3px" }} src={solutionImg} />{" "}{" "}<h style={{ color: "#594099", fontSize: "16px", }}>Solution</h></div>
				<div style={{ marginTop: "10px" }}>
					<HtmlComponent
						item={currentQ?.questionContent?.solution} />
				</div>
			</div>
		)
	} else if (currentQ?.type === "Numeric" || currentQ?.type === "T/F") {
		let answerInputs = [];
		console.log("dataaa", currentQ, answerInputs);



		if (
			currentQ.submitStatus === "Attempted" ||
			currentQ.submitStatus === "AttemptedAndBookmarked"
		) {
			if (currentQ?.answerContent?.answer === currentQ?.attempt?.answer) {
				answerInputs.push(
					customInputElement(
						currentQ?.answerContent?.answer,
						"Correct",
						"Your answer"
					)
				);
			} else {
				answerInputs.push(
					customInputElement(
						currentQ?.attempt?.answer,
						"Incorrect",
						"Your answer"
					)
				);
				answerInputs.push(
					customInputElement(
						currentQ?.answerContent?.answer,
						"Correct",
						"Correct answer"
					)
				)
			}
		} else {
			answerInputs.push(
				customInputElement(
					currentQ?.answerContent?.answer,
					"Correct",
					"Correct answer"
				)
			);
		}

		return (
			<>
				<div style={{ marginLeft: "20px", marginTop: "25px" }}>
					<span style={{ fontWeight: "bold" }}> Answer</span>
				</div>
				{answerInputs?.map((item) => item)}
				<div ><img style={{ width: "20px", marginTop: "-3px" }} src={solutionImg} />{" "}{" "}<h style={{ color: "#594099", fontSize: "16px", }}>Solution</h></div>

				<div style={{ marginTop: "10px" }}>
					<HtmlComponent
						item={currentQ?.questionContent?.solution} />
				</div>

			</>
		);

	} else {
		return null;
	}
};



export function QuestionTypes({
	currentIndex,
	toggleImageModal,
	setCurrentImg,
	currentQ,
	answerObj,
	updateAnswerObj,
	isResult
}) {

	const type = currentQ?.questionSnapshot?.type

	if (type == "OrQuestions") {
		return currentQ?.questionSnapshot?.questionContent?.orQuestions?.map((item, index) => {
			return (
				<div>
					<div style={{ fontWeight: 600, color: '#636363', margin: 20, marginBottom: -10 }}>{`Question ${currentIndex}(${String.fromCharCode('a'.charCodeAt(0) + index)})`}
					</div>

					<div style={{ width: "95%", margin: 20, wordBreak: "break-word" }}>
						<QuestionStatement
							data={item.questionContent.statement}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/>
					</div>

					{isResult ?
						<AnswerBox
							currentQ={{
								...item,
								answerContent: currentQ?.questionSnapshot?.answerContent?.[index],
								attempt: (currentQ?.questionSnapshot.attempt?.orQuestionIndex == index) ? currentQ?.questionSnapshot.attempt : null
							}}
							submitStatus={currentQ?.submitStatus}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/> :
						<OptionsFields
							answerObj={answerObj}
							updateAnswerObj={updateAnswerObj}
							quesContent={item.questionContent}
							type={item.type}
							parentType={"OrQuestions"}
							subIndex={index}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/>
					}

					{/* this condition is to add HORIZONTAL OR LINE */}
					{(index != currentQ?.questionSnapshot?.questionContent?.orQuestions.length - 1)
						&&
						<div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
							<div style={{ width: '40%', height: 1, borderBottom: '1px solid black', padding: '0 5px' }}></div>
							<span style={{ marginTop: -10, padding: '0px 10px' }}>OR</span>
							<div style={{ width: '40%', height: 1, borderBottom: '1px solid black' }}></div>
						</div>
					}
				</div>
			)
		})
	}

	if (type == "Comprehensive")
		return <div>
			<div className='m-l-40'>
				<QuestionStatement
					data={currentQ?.questionSnapshot?.questionContent?.paragraph}
					toggleImageModal={toggleImageModal}
					setCurrentImg={setCurrentImg}
				/>
			</div>

			{currentQ?.questionSnapshot?.questionContent?.subQuestions?.map((item, index) => {
				return (
					<div>
						<div style={{ fontWeight: 600, color: '#636363', margin: 20, marginBottom: -10 }}>{`Question ${currentIndex}(${index + 1})`}</div>

						<div style={{ width: "95%", margin: 20, wordBreak: "break-word" }}>
							<QuestionStatement
								data={item?.questionContent?.statement}
								toggleImageModal={toggleImageModal}
								setCurrentImg={setCurrentImg}
							/>
						</div>
						{isResult ?
							<AnswerBox
								currentQ={{
									...item,
									answerContent: currentQ?.questionSnapshot?.answerContent?.[index],
									attempt: currentQ?.attempt?.find(el => el.subQuestionIndex == index)
								}}
								submitStatus={currentQ?.submitStatus}
								toggleImageModal={toggleImageModal}
								setCurrentImg={setCurrentImg}
							/> :
							<OptionsFields
								answerObj={answerObj}
								updateAnswerObj={updateAnswerObj}
								quesContent={item?.questionContent}
								type={item.type}
								toggleImageModal={toggleImageModal}
								setCurrentImg={setCurrentImg}
								parentType={"Comprehensive"}
								subIndex={index}
							/>
						}
					</div>
				)
			})}

		</div>

	return <div>
		<div style={{ width: "95%", margin: 20, wordBreak: "break-word" }}>
			<QuestionStatement
				data={currentQ?.questionSnapshot?.questionContent?.statement}
				toggleImageModal={toggleImageModal}
				setCurrentImg={setCurrentImg}
			/>
		</div>
		{isResult ?	//todoo add solution
			<AnswerBox
				currentQ={{
					...currentQ?.questionSnapshot,
					attempt: currentQ?.attempt,
					submitStatus: currentQ?.submitStatus
				}}
				submitStatus={currentQ?.submitStatus}
				toggleImageModal={toggleImageModal}
				setCurrentImg={setCurrentImg}
			/> :
			<OptionsFields
				answerObj={answerObj}
				updateAnswerObj={updateAnswerObj}
				quesContent={currentQ?.questionSnapshot?.questionContent}
				type={type}
				toggleImageModal={toggleImageModal}
				setCurrentImg={setCurrentImg}
			/>
		}
	</div>
};




const AdminOptionFields = ({
	currentQ,
	updateCurrentQ,
	type,
	quesContent,
	answerContent,
	toggleImageModal,
	setCurrentImg,
	parentType,
	subIndex,
	setEditorValue,
	toggleTextEditor,
	setEditorCallback
}) => {

	if (type == "Numeric" || type == "CNum")
		return (
			<div className='p-20'>
				<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Answer</div>
				<Input
					placeholder="Type the answer here..."
					value={answerContent?.answer}
					onChange={(e) => {
						answerContent['answer'] = e.target.value
						updateCurrentQ({ ...currentQ })	//causing component to re-render
					}}
					type={'number'}
					style={{ borderRadius: 5 }}
				/>
			</div>
		);
	else if (type == "MCQ" || type == "MSQ" || type == "CMCQ" || type == "CMSQ")
		return (<div className='p-l-20 p-r-20'>
			<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Options</div>
			{quesContent?.options?.map((item, index) => (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div className='r-ac m-b-10' style={{ width: '93%' }} key={index}>
						<div
							style={{ background: item.correct ? '#28df99' : '', color: item.correct ? '#fff' : '', borderColor: item.correct ? '#28df99' : '' }}
							className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
							{String.fromCharCode(65 + index)}
						</div>

						<div
							style={{ background: item.correct ? '#E9FCF5' : '', width: "100%", overflowX: "hidden" }}
							className='cursor-pointer test-ques-window p-10 r-c-sb'
							onClick={() => {
								setEditorValue(item.value)
								setEditorCallback((prev) => (editorData) => {
									item.value = editorData
									toggleTextEditor(false)
								})
								toggleTextEditor(true)
							}}
						>
							<div className='resize-editor-img' style={{ overflowX: 'hidden', maxWidth: '850px' }} >
								{(!item.value || item.value == "<p><br></p>") ? <div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Option {index + 1}</div>
									:
									<HtmlComponent
										item={item.value}
										toggleImageModal={toggleImageModal}
										setCurrentImg={setCurrentImg}
									/>
								}
							</div>
							<div onClick={(e) => {
								e.stopPropagation()
								if (type == "MCQ" || type == "CMCQ") {
									quesContent?.options?.forEach(item => item.correct = false)
									item.correct = true
									answerContent['answer'] = index
								}
								else {
									item.correct = !item.correct
									if (answerContent.answers?.includes(index))
										answerContent.answers = answerContent.answers.filter(el => el != index)
									else answerContent.answers.push(index)
								}
								updateCurrentQ({ ...currentQ })
							}} key={index} className='r-ac m-l-5'>
								{item.correct
									?
									<RiCheckboxCircleLine className='text-sm seaGreen' />
									:
									<RiCheckboxBlankCircleLine className='text-sm a2grey' />
								}
								<div className='m-l-5 text-xs a2grey'>Correct answer</div>
							</div>
						</div>
					</div>


					<div
						onClick={() => {
							if (quesContent?.options?.length == 2) return
							quesContent.options = quesContent?.options?.filter((el, idx) => idx != index)
							if (type == "MCQ")
								quesContent?.options?.forEach((opt, idx) => {
									if (opt.correct)
										answerContent['answer'] = idx
								})
							if (type == "MSQ") {
								answerContent['answers'] = []
								quesContent?.options?.forEach((opt, idx) => {
									if (opt.correct)
										answerContent['answers'].push(idx)
								})
							}
							updateCurrentQ({ ...currentQ })
						}}
						style={{ marginTop: '1%' }}
						className='r-c-c radius-100 color-black test-circle-avatar m-r-5 m-l-10 cursor-pointer'>
						{'X'}
					</div>
				</div>
			))}

		</div>
		);
	else if (type == "T/F" || type == "CTF")
		return (
			<div className='p-l-20 p-r-20'>
				<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Options</div>
				<div className='r-ac m-b-10'>
					<div
						style={(answerContent?.answer === true) ? {
							background: '#28df99',
							color: '#fff',
							borderColor: '#28df99'
						} : {}}
						className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
						{'A'}
					</div>
					<div
						style={{ background: answerContent?.answer === true ? '#E9FCF5' : '', width: "100%", overflowX: "hidden" }}
						className='cursor-pointer test-ques-window p-10 r-c-sb'
					><div>
							True
						</div>
						<div onClick={(e) => {
							answerContent['answer'] = true
							updateCurrentQ({ ...currentQ })
						}} className='r-ac m-l-5'>
							{answerContent?.answer === true
								?
								<RiCheckboxCircleLine className='text-sm seaGreen' />
								:
								<RiCheckboxBlankCircleLine className='text-sm a2grey' />
							}
							<div className='m-l-5 text-xs a2grey'>Correct Answer</div>
						</div>
					</div>
				</div>
				<div className='r-ac m-b-10'>
					<div
						style={(answerContent?.answer === false) ? {
							background: '#28df99',
							color: '#fff',
							borderColor: '#28df99'
						} : {}}
						className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
						{'B'}
					</div>
					<div
						style={{ background: answerContent?.answer === false ? '#E9FCF5' : '', width: "100%", overflowX: "hidden" }}
						className='cursor-pointer test-ques-window p-10 r-c-sb'
					>
						<div>
							False
						</div>
						<div onClick={(e) => {
							answerContent['answer'] = false
							updateCurrentQ({ ...currentQ })
						}} className='r-ac m-l-5'>
							{answerContent?.answer === false
								?
								<RiCheckboxCircleLine className='text-sm seaGreen' />
								:
								<RiCheckboxBlankCircleLine className='text-sm a2grey' />
							}
							<div className='m-l-5 text-xs a2grey'>Correct Answer</div>
						</div>
					</div>
				</div>
			</div>
		);
	else return <></>;

}




export function AdminQuestionTypes({
	currentIndex,
	toggleImageModal,
	setCurrentImg,
	currentQ,
	currentQType,
	updateCurrentQ,
	setEditorValue,
	toggleTextEditor,
	setEditorCallback,
	selectedMS
}) {

	const type = currentQ?.type

	if (type == "OrQuestions") {
		return currentQ?.questionContent?.orQuestions?.map((item, index) => {
			return (<>
				<div className='p-20'>
					<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {currentIndex}({String.fromCharCode('a'.charCodeAt(0) + index)})</div>
					<div className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
						onClick={() => {
							setEditorValue(item.questionContent?.statement)
							setEditorCallback((prev) => (editorData) => {
								item.questionContent.statement = editorData
								toggleTextEditor(false)
							})
							toggleTextEditor(true)
						}}
					>
						{!item.questionContent?.statement ?
							<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Question</div>
							:
							<HtmlComponent
								item={item.questionContent?.statement}
								toggleImageModal={toggleImageModal}
								setCurrentImg={setCurrentImg}
							/>
						}
					</div>
				</div>


				<AdminOptionFields
					type={item?.type}
					toggleImageModal={toggleImageModal}
					setCurrentImg={setCurrentImg}
					quesContent={item?.questionContent}
					answerContent={currentQ?.answerContent?.[index]}
					setEditorValue={setEditorValue}
					toggleTextEditor={toggleTextEditor}
					setEditorCallback={setEditorCallback}
					currentQ={currentQ}
					updateCurrentQ={updateCurrentQ}
				/>

				<div className='p-20 m-b-50'>
					<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Solution</div>
					<div className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
						onClick={() => {
							setEditorValue(item.questionContent?.solution)
							setEditorCallback((prev) => (editorData) => {
								item.questionContent.solution = editorData
								toggleTextEditor(false)
							})
							toggleTextEditor(true)
						}}
					>
						{!item.questionContent?.solution ?
							<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Solution</div>
							:
							<HtmlComponent
								item={item.questionContent?.solution}
								toggleImageModal={toggleImageModal}
								setCurrentImg={setCurrentImg}
							/>
						}
					</div>
				</div>

				{/* this condition is to add HORIZONTAL OR LINE */}
				{(index != currentQ?.questionContent?.orQuestions.length - 1)
					&&
					<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
						<div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
						<span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
						<div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
					</div>
				}
			</>)
		})
	}

	if (type == "Comprehensive") {
		return <>
			<div className='p-20'>
				<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {currentIndex}</div>
				<div className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
					onClick={() => {
						setEditorValue(currentQ?.questionContent?.paragraph)
						setEditorCallback((prev) => (editorData) => {
							currentQ.questionContent.paragraph = editorData
							toggleTextEditor(false)
						})
						toggleTextEditor(true)
					}}
				>
					{!currentQ?.questionContent?.paragraph ?
						<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Paragraph</div>
						:
						<HtmlComponent
							item={currentQ?.questionContent?.paragraph}
							toggleImageModal={toggleImageModal}
							setCurrentImg={setCurrentImg}
						/>
					}
				</div>
			</div>

			{currentQ?.questionContent?.subQuestions?.map((item, index) => {
				return (<>
					<div className='p-20'>
						<div className='m-b-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {currentIndex} ({index + 1})</div>

							{/* Mark Section */}
							<div>
								<div className='r-ac m-b-10'>
									<div className='text-xmd bold-600 color-black m-r-5' style={{ color: '#636363' }}>Marks</div>
									<Tooltip placement="right" trigger="hover" title={
										<div>
											<div className='r-ac'>
												<div className='radius-100' style={{ width: 10, height: 10, background: '#6BDB9E' }}></div>
												<div className='text-xmd color-black m-l-10'>Positive Marks</div>
											</div>
											<div className='r-ac'>
												<div className='radius-100' style={{ width: 10, height: 10, background: '#ec5254' }}></div>
												<div className='text-xmd color-black m-l-10'>Negative Marks</div>
											</div>
										</div>
									} overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px", background: '#fff' }}>
										<ExclamationCircleOutlined style={{ color: "#1089FF" }} className='text-xs cursor-pointer' />
									</Tooltip>
								</div>
								<div className='r-ac'>
									<div className='r-c-c test-count-seq-box'>
										<AiOutlinePlus onClick={() => {
											if (!selectedMS) {
												item.positiveMarks += 1
												currentQ.positiveMarks += 1

												updateCurrentQ({ ...currentQ })
											}
										}} className='cursor-pointer' />
										<div className='text-xmd m-l-5 m-r-5'>{item?.positiveMarks}</div>
										<AiOutlineMinus onClick={() => {
											if (!selectedMS && item?.positiveMarks) {

												item.positiveMarks -= 1
												currentQ.positiveMarks -= 1

												updateCurrentQ({ ...currentQ })
											}
										}} className='cursor-pointer' />
									</div>
									<div className='r-c-c test-count-seq-box m-l-10' style={{ background: '#FFE4D9', borderColor: '#EC5254' }}>
										<AiOutlinePlus onClick={() => {
											if (!selectedMS) {
												item.negativeMarks += 1
												currentQ.negativeMarks += 1

												updateCurrentQ({ ...currentQ })
											}
										}} className='cursor-pointer' />
										<div className='text-xmd m-l-5 m-r-5'>{item?.negativeMarks}</div>
										<AiOutlineMinus onClick={() => {
											if (!selectedMS && item?.negativeMarks) {
												item.negativeMarks -= 1
												currentQ.negativeMarks -= 1

												updateCurrentQ({ ...currentQ })
											}
										}} className='cursor-pointer' />
									</div>
								</div>
							</div>

						</div>
						<div className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
							onClick={() => {
								setEditorValue(item.questionContent?.statement)
								setEditorCallback((prev) => (editorData) => {
									item.questionContent.statement = editorData
									toggleTextEditor(false)
								})
								toggleTextEditor(true)
							}}
						>
							{!item.questionContent?.statement ?
								<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Question</div>
								:
								<HtmlComponent
									item={item.questionContent?.statement}
									toggleImageModal={toggleImageModal}
									setCurrentImg={setCurrentImg}
								/>
							}
						</div>
					</div>


					<AdminOptionFields
						type={item.type}
						toggleImageModal={toggleImageModal}
						setCurrentImg={setCurrentImg}
						quesContent={item?.questionContent}
						answerContent={currentQ?.answerContent?.[index]}
						setEditorValue={setEditorValue}
						toggleTextEditor={toggleTextEditor}
						setEditorCallback={setEditorCallback}
						currentQ={currentQ}
						updateCurrentQ={updateCurrentQ}
					/>

					<div className='p-20 m-b-50'>
						<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Solution</div>
						<div className='cursor-pointer test-ques-window resize-editor-img p-10 full-width'
							onClick={() => {
								setEditorValue(item.questionContent?.solution)
								setEditorCallback((prev) => (editorData) => {
									item.questionContent.solution = editorData
									toggleTextEditor(false)
								})
								toggleTextEditor(true)
							}}
						>
							{!item.questionContent?.solution ?
								<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Solution</div>
								:
								<HtmlComponent
									item={item?.questionContent?.solution}
									toggleImageModal={toggleImageModal}
									setCurrentImg={setCurrentImg}
								/>
							}
						</div>
					</div>
				</>)
			})}

		</>
	}


	return <>
		<div className='p-20'>
			<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Question {currentIndex}</div>
			<div
				className={`cursor-pointer test-ques-window resize-editor-img p-10 full-width ${!currentQType ? 'not-allowed' : ''}`}
				style={{

					cursor: !currentQType ? 'not-allowed' : 'pointer'
				}}
				onClick={() => {
					if (currentQType) {
						setEditorValue(currentQ?.questionContent?.statement);
						setEditorCallback((prev) => (editorData) => {
							currentQ.questionContent.statement = editorData;
							toggleTextEditor(false);
						});
						toggleTextEditor(true);
					}
				}}
				title={!currentQType ? "Select question type" : ""}
			>
				{!currentQ?.questionContent?.statement ?
					<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Question</div>
					:
					<HtmlComponent
						item={currentQ?.questionContent?.statement}
						toggleImageModal={toggleImageModal}
						setCurrentImg={setCurrentImg}
					/>
				}
			</div>
		</div>


		<AdminOptionFields
			type={type}
			toggleImageModal={toggleImageModal}
			setCurrentImg={setCurrentImg}
			quesContent={currentQ?.questionContent}
			answerContent={currentQ?.answerContent}
			setEditorValue={setEditorValue}
			toggleTextEditor={toggleTextEditor}
			setEditorCallback={setEditorCallback}
			currentQ={currentQ}
			updateCurrentQ={updateCurrentQ}
		/>

		<div className='p-20 m-b-50'>
			<div className='text-xmd bold-600 color-black m-b-5' style={{ color: '#636363' }}>Solution</div>
			<div
				className={`test-ques-window resize-editor-img p-10 full-width ${!currentQType? 'not-allowed' : 'cursor-pointer'}`}
				style={{
					cursor: !currentQType? 'not-allowed' : 'pointer',
				}}
				onClick={() => {
					if (currentQType) {
						setEditorValue(currentQ?.questionContent?.solution);
						setEditorCallback((prev) => (editorData) => {
							currentQ.questionContent.solution = editorData;
							toggleTextEditor(false);
						});
						toggleTextEditor(true);
					}
				}}
				title={!currentQType ? "Select question type" : ""}
			>
				{!currentQ?.questionContent?.solution ?
					<div style={{ fontSize: '14px', color: '#AEAEAE' }}>Add Solution</div>
					:
					<HtmlComponent
						item={currentQ?.questionContent?.solution}
						toggleImageModal={toggleImageModal}
						setCurrentImg={() => { }}
					/>
				}
			</div>
		</div>
	</>

};