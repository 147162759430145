import { Popover } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import '../dashboard.css';
import PaymentReqDetailsModal from "pages/studentAulasWallet/modal/paymentReqDetailsModal";

export default function MyCalendar({ dashBoardOverviewData,storePaymentReqModalData,paymentReqModalData }) {
  const [dayNames, setDaynames] = useState([]);
  const [modifiedArray, setModifedArray] = useState([]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const dayObject = {
    0: "Mon",
    1: "Tue",
    2: "Wed",
    3: "Thu",
    4: "Fri",
    5: "Sat",
    6: "Sun",


  };

  console.log("AA-dayssss",dayNames);
  
  
  useEffect(() => {
    function init() {
      const days = ['Mon','Tue','Wed','Thu',"Fri",'Sat','Sun'];

      const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
      
      var result = [];
      // const newArr=[...dashBoardOverviewData?.Calendar] 
      const newArr = Array.isArray(dashBoardOverviewData?.Calendar) ? [...dashBoardOverviewData.Calendar] : [];
      for(let i = 0 ;i < firstDayOfMonth-1 ; i++){
        newArr.unshift({})
      }
      for (var i = 0; i < newArr?.length; i += 7) {
        result.push(newArr?.slice(i, i + 7));
      }
      let lastIndexLength = result[result.length - 1]?.length;
      for (let i = lastIndexLength; i < 7; i++) {
        result[result?.length - 1]?.push({});
      }
      setDaynames(days);
      setModifedArray(result);
    }
    init();
  }, [currentMonth, currentYear, dashBoardOverviewData?.Calendar]);

  const PopoverContent = ({ data ,storePaymentReqModalData,paymentReqModalData}) => {
  // const [isPaymentDetailsVisible, setPaymentDetailsVisible] = useState(false);
  // const [isEmailIdModalVisible, setIsEmailIdModalVisible] = useState(false);

    return (
      
      <div style={{width:"100%"}}>
        
        <div>
          {data?.items.map((item, index) => (
            <div key={index}>
              {item.ActivityType === 'Test' && (
                <>
  
                  <div style={{ fontSize: "11px", fontWeight: "bold" }}>{item.title}</div>
                  <div style={{ fontSize: "11px", fontWeight: "bold" }}>{item.subject?.name}</div>
                  <div style={{ fontSize: "11px", fontWeight: "bold", color: "#636363" }}>
                    {moment(item.startTime, 'h:mm a').format('h:mm a')}-{moment(item.endTime, 'h:mm a').format('h:mm a')}
                  </div>
                </>
              )}
              {item.ActivityType === 'Holiday' && (
                <>
                  <div style={{ fontSize: "11px", fontWeight: "bold" }}>{item.title}</div>
                  <div style={{ fontSize: "11px", fontWeight: "bold", color: "#636363" }}>
                    {moment(item.date).format('DD-MM-YYYY')}
                  </div>
                </>
              )}
              {item.ActivityType === 'Transactions' && (
                <>
                  <div style={{ display: "flex"}}>
                  <div style={{ fontSize: "11px", fontWeight: "bold" }}>₹ {item.amountToShow}</div>
                    {/* <div style={{ fontSize: "11px", fontWeight: "bold", color: "#636363" }}>{item.note} {console.log("AA-item.note",item.note)}</div> */}
                    <div style={{ marginLeft: "10px", color: "#1089FF", fontSize: "11px", fontWeight: "bold", cursor: "pointer" }} /* onClick={()=>{storePaymentReqModalData(item);setPaymentDetailsVisible(true)}} */>PAY NOW</div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        {console.log("AA-data1234",data?.items)}
        {/* <PaymentReqDetailsModal
                    isPaymentDetailsVisible={isPaymentDetailsVisible}
                    setPaymentDetailsVisible={setPaymentDetailsVisible}
                    setIsEmailIdModalVisible={setIsEmailIdModalVisible}
                    paymentReqModalData={paymentReqModalData}
                /> */}
      </div>
    );
  };

  return (
    <>
      <div className="calendar">
        {/* <div style={{ textAlign: "center" }}>
          {currentDate.toLocaleString("en-us", {
            month: "long",
            year: "numeric"
          })}
        </div> */}
        {/* Render the days of the week */}
        <div className="row">
          {dayNames.map((day, index) => (
            <div key={index} className="weekDays" style={{color:"#AEAEAE",}}>
              {day}
            </div>
          ))}
        </div>
        {modifiedArray.map((week, weekIndex) => (
          <div key={weekIndex} className="row">
            {console.log("AA-modfiy123",week)}
            {week.map((day, dayIndex) => (
              <div
                key={dayIndex}
                className="day"
                style={{
                  color: `${day?.color ? day.color : "#fff"}`,
                  cursor: `${day?.date ? "pointer" : "not-allowed"}`
                }}
              >
                <div> 
                {day?.date && day?.items?.length > 0 ? (
                    <>
                      <Popover content={<PopoverContent data={day} storePaymentReqModalData={storePaymentReqModalData} paymentReqModalData={paymentReqModalData}/>} trigger="hover">
                        {moment.utc(day.date).format('D')}
                      </Popover>
                    </>
                  ) : day?.date ? (
                    <span>{moment.utc(day.date).format('D')}</span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ))}
        
      </div>
    </>
  );
}
