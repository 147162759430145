import { AddSaveLater, applyPromo, fetchPromoCodes, payNowCart, setCouponInCart, setLoader } from 'actions/aulasMarket';
import { Button, Input, Rate } from 'antd';
import moment from 'moment';
import { generatePaymentRequest } from 'pages/profile/paymentGateway';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Api } from 'services';
import Footer from './footer';
import "./styles.css";
import TopBar from './topBar';


function Checkout({ singleBuyNowDetails, fetchPromoCodes, promoCodeDetials, user, cartDetails, applyPromo, payNowCart, setLoader, loginDetails, setCouponInCart, storePromoPrice }) {

    console.log(singleBuyNowDetails, cartDetails, promoCodeDetials, 'anurag')
    const token = loginDetails?.accessToken;
    const [coupon, setCoupon] = useState(false);
    const [promoCode, setPromoCode] = useState(promoCodeDetials)
    const [paynowLoader, togglePaynowLoader] = useState(false);
    const [offerCode, setOfferCode] = useState('');
    const [applyLoader, toggleApplyLoader] = useState(false);

    const [cartList, setCartList] = useState(
        {
            title: singleBuyNowDetails?.name ? singleBuyNowDetails?.name : singleBuyNowDetails?.courseName,
            description: singleBuyNowDetails?.brief,
            rating: singleBuyNowDetails?.ratingValue,
            totalRatings: singleBuyNowDetails?.ratingCount,
            costRs: singleBuyNowDetails?.amount,
            free: singleBuyNowDetails?.free || undefined,
            uid: singleBuyNowDetails?.id,
            new: singleBuyNowDetails?.new || undefined,
            image: singleBuyNowDetails?.image,
            discount: singleBuyNowDetails?.discount,
            itemType:singleBuyNowDetails?.resourceType
        },
    );

    console.log('singleBuyNowDetails',singleBuyNowDetails);

   
    useEffect(() => {
        fetchPromoCodes(() => { });
    }, []);

    const removeFromCart = (uid) => {
        const updatedCartList = cartList.filter(item => item.uid !== uid);
        setCartList(updatedCartList);
    };

    


    const handleSaveForLater = (id, itemType) => {
        AddSaveLater(token, id, itemType, (res) => {
            if (res) {
            }
        }, setLoader)
    }
    const CartList = ({ cartList }) => {
        return (
            <div className='cartContainer p-10' style={{}}>
                <div>
                    {console.log('iim',cartList)}
                    <img className='cart-img' style={{ objectFit: 'cover' }}  src={`${Api.dlS3Url}${cartList?.image}`}alt='cart image' />
                    <div className='cost-save r-c-c font-bold' style={{}}>₹ {cartList?.costRs}</div>
                </div>
                <div className='m-l-30' >
                    <div className='r-jsb' style={{ width: '99%' }}>
                        <div  className='' style={{ fontSize:'1.2em', fontWeight:'bold' }}>{cartList?.title}
                        <span style={{ color: "#FFA931", fontSize: 16, marginLeft: 10 }}>NEW</span>
                        </div>
                        
                    </div>
                    {/* <div className='cart-des' style={{}} >{cartList?.description}</div> */}
                    <div className='cart-des' style={{marginTop:10, marginBottom:10}} >{cartList?.description}</div>
                    <div className='cart-seller'>
                        <div className='sellerButton2 m-t-5'><h style={{ fontWeight: '600', fontSize: 15 }}>Bestseller</h></div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '100px', flexWrap: 'wrap' }}>
                         <div style={{ color: "#FFA931", marginRight: '5px', marginLeft: '-80px' }}>{cartList?.rating || 0}</div>
                             <Rate disabled defaultValue={cartList.ratingValue || 0} style={{ color: "#FFA931", marginLeft: '5px' }} />
                            <div style={{ marginLeft: '5px', color: '#191919' }}>({cartList?.totalRatings || 0} ratings)</div>
                        </div>
                        {/* <div className='r-c-c text-xmd m-l-10'><div style={{ color: "#FFA931" }}>{cartList?.rating || 0}</div><Rate disabled defaultValue={4} style={{ color: "#FFA931" }} /> <div>{cartList?.totalRatings}</div></div> */}
                    </div>
                 {console.log('cartList',cartList)}
                        {/* <div style={{fontWeight:'bold', marginTop:30}} onClick={() => handleSaveForLater(cartList?.uid, cartList?.itemType)}>SAVE FOR LATER</div> */}
                        {/* <div style={{ cursor: "pointer" }} onClick={() => removeFromCart(cartList?.uid)}>REMOVE</div> */}
    
                </div>
            </div>
        )
    }

    const handleRemoveClick = () => {
        setCoupon(false);
    };

    function numberWithCommas(x) {
        return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x?.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
    };

    const handleApplyClick = () => {

        let params = {
            code: offerCode,
            type: 'offer'
        };
        toggleApplyLoader(true);
        console.log('coupen12',coupon)

        
        applyPromo(params, () => toggleApplyLoader(false), () => {
            console.log('coupen12',coupon)
            setCoupon(true)
            console.log('coupen12',coupon)
            toggleApplyLoader(false)
        });
    };

    const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
    const handlePayNow = () => {
        let params = {
            orderAmount: coupon ? (storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount).toFixed(2) :(cartList?.costRs - cartList?.discount).toFixed(2) || null,
            orderCurrency: 'INR',
            orderId: generateOrderId.toString() || null,
            customerDetails: {
                customer_id: user?.id?.toString() || null,
                customer_email: user?.email || null,
                customer_phone: user?.phone?.toString() || null
            }
        };

        const callback = (response) => {
            togglePaynowLoader(false)
            generatePaymentRequest(response, null, user, null)
        }

        togglePaynowLoader(true)
        payNowCart(params, (response) => callback(response));
    };

    const totalAmount = storePromoPrice?.cart?.amount - storePromoPrice?.cart?.discount;
    const totalAmountBeforeDiscount = cartList?.costRs - cartList?.discount;

    return (
        <>
            <div className="top-bar">
                <TopBar />
            </div>
            <div style={{ backgroundColor: "#FAFAFA" }}>
                <div className='main-container-parent'>
                    {/* {promoCode?.length == 0 ? null :
                        <>
                            <div className='discount-banner' style={{}}>
                            </div>
                            <div className='r-c-sb discount-container' style={{}}>
                                {promoCode[0]?.discountType == 'percent' ?
                                    <div className='text-mdl font-bold' style={{ opacity: 1, color: "#594099" }}>Avail discount of {promoCode[0]?.discount}%</div>
                                    : <div className='text-mdl font-bold' style={{ opacity: 1, color: "#594099" }}>Avail discount of ₹{promoCode[0]?.discount}</div>
                                }
                                <div className='text-xmd' style={{ opacity: 1, color: "#594099", display: "flex", alignItems: "center" }}>
                                    <span className='m-r-10' style={{ display: "flex", alignItems: "center" }}>Use the promotional offer <Paragraph copyable style={{ fontWeight: 700, fontSize: 16, margin: "10px 5px", display: "flex", alignItems: "center", gap: 5, color: "#594099" }}>{promoCode[0]?.code}</Paragraph> to get an instant discount</span>
                                    <div className='r-c-c validity-tag'>Valid until {moment(promoCode[0]?.endDate).format('DD-MM-YYYY')}</div>
                                </div>
                            </div>
                        </>
                    } */}
                    <div style={{ marginTop: 50 }} className="main-container">
                        <div className='shopping-header font-bold' >Checkout</div>
                        <div className='shopping-parent' >
                            <div><CartList cartList={cartList} /></div>
                            <div className='priceContainer' style={{}}>
                                <div className='r-c-fs p-10 ' style={{ color: "#AEAEAE", borderBottom: "1px solid #E6E6E6" }}>PRICE DETAILS</div>
                                <div className='p-10' style={{ color: "#636363", borderBottom: "1px solid #E6E6E6" }}>
                                    <div className='r-c-sb'>Price <div>₹{coupon ? numberWithCommas(storePromoPrice && storePromoPrice?.cart?.amount.toFixed(2)) : numberWithCommas(cartList?.costRs)}</div></div>
                                    <div className='r-c-sb'>Discount <div style={{ color: "#28DF99" }}>₹{coupon ? numberWithCommas(storePromoPrice?.cart?.discount.toFixed(2)) : numberWithCommas(cartList?.discount)}</div></div>
                                </div>
                                <div className='p-10' style={{ color: "#636363", borderBottom: "1px solid #E6E6E6" }}>
                                    <div className='r-c-sb'>Total amount<div>₹{coupon ? numberWithCommas(totalAmount.toFixed(2)) : numberWithCommas(totalAmountBeforeDiscount.toFixed(2))}</div></div>
                                </div>
                                <div className='p-10' style={{ color: "#28DF99", borderBottom: "1px solid #E6E6E6" }}>
                                    <div className='r-c-c'>You will save ₹{numberWithCommas(cartList?.discount?.toFixed(2)) || 'NA'} on this purchase</div>
                                </div>
                                <div className='p-10 m-t-10'>
                                    {console.log('coupon1',coupon)}
                                    {coupon ?
                                        <div className='price-applied  ' style={{ alignItems: "flex-start" }}>
                                            <div className='r-c-c'><img style={{ marginTop: -20 }} src={require("../../Assets/aulasMarket/ic-check-filled.svg").default} />
                                                <div className='r-sfa m-l-10'>
                                                    <div>You saved ₹{numberWithCommas(storePromoPrice?.cart?.discount?.toFixed(2))} with this code</div>
                                                    <div style={{ color: "#AEAEAE" }}>Coupon Applied</div>
                                                </div>
                                            </div>
                                            <div style={{ color: '#FF414D', cursor: 'pointer' }} onClick={() => { setCoupon(false); }}>REMOVE</div>
                                        </div>
                                        :
                                        <div className=' r-c-c' style={{ display: 'flex' }}>
                                            <Input
                                                className='price-coupon-filed'
                                                type="text"
                                                placeholder='Enter coupon code'
                                                onChange={(e) => setOfferCode(e.target.value)}
                                                value={offerCode}
                                            ></Input>
                                            <Button
                                                loading={applyLoader}
                                                className='r-c-c text-xm bold-600'
                                                type="button"
                                                onClick={handleApplyClick}
                                                style={{
                                                    cursor: 'pointer',
                                                    border: '1px solid #594099',
                                                    borderTopRightRadius: '5px',
                                                    borderBottomRightRadius: '5px',
                                                    backgroundColor: 'white',
                                                    padding: '5px 10px',
                                                    color: "#594099",
                                                    height: "6vh",
                                                    opacity: 0.4
                                                }}
                                            >
                                                APPLY
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <div className='r-c-c m-t-20'>
                                    <Button loading={paynowLoader} onClick={() => handlePayNow()} className='btn-buy' >PAY NOW</Button>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
                <div className='m-t-100' style={{}}>
                    <Footer />
                </div>
            </div>
        </>
    )
};


const mapStateToProps = (state) => {
    const { singleBuyNowDetails, promoCodeDetials, cartDetails, loginDetails, coursePageData, storePromoPrice} = state.aulasMarket;
    const { user } = state.session
    return { singleBuyNowDetails, promoCodeDetials, user, cartDetails ,loginDetails, coursePageData, storePromoPrice};
};

const mapDispatchToProps = (dispatch) => ({
    fetchPromoCodes: (callback) => dispatch(fetchPromoCodes(callback)),
    applyPromo: (params, callback, succesCallback) => dispatch(applyPromo(params, callback, succesCallback)),
    payNowCart: (params, callback) => dispatch(payNowCart(params, callback)),
    setLoader: (val) => dispatch(setLoader(val)),
    setCouponInCart: res => dispatch(setCouponInCart(res)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)