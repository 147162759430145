import React from 'react';
import {connect} from 'react-redux';
import './styles.css';
import StudentWiseView from './views/studentWiseView';
import SingleTeachersView from './views/singleTeachersView';
import { getAllTeachers, setSingleClassView } from '../../actions/attendance-old'
import { getAllClasses } from './../../actions/classRoom';


class MyAttendance extends React.Component {


  tabCallback(){

  }

  mainTabCallback(e){
    if(e === "Teachers"){
      this.props.getAllTeachers();
    }else if (e === "Students") {
      this.props.getAllClasses();
    }

  }

  // handleBackToClasses(){
  //   this.props.setSingleClassView(false);
  // }

  render() {
    const { user } = this.props;
    return (
    <div style={{height: '100%'}}>
     {
       user.role.includes("Teacher") ?
        <SingleTeachersView />
        :
        user.role.includes("Student") ?
        <StudentWiseView />
        :
        null
     }
    </div>
  )
  }
}

const mapStateToProps = state => {
  const { singleTeacherView, singleClassView } = state.attendance;
  const { user } = state.session;
  return { singleTeacherView, singleClassView, user }
};

const mapDispatchToProps = dispatch => ({
  getAllTeachers: () => dispatch(getAllTeachers()),
  setSingleClassView: (status) => dispatch(setSingleClassView()),
  getAllClasses: (status) => dispatch(getAllClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAttendance);
