import React from "react";
import Carousel from "antd/lib/carousel";
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import "antd/lib/carousel/style/index";
import "antd/lib/upload/style/index";
import "antd/lib/message/style/index";
import "antd/lib/modal/style/index";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "antd/lib/button/style/index";
import {  Divider, Input } from 'antd';
import { MdNotifications, MdTimer } from "react-icons/md";
import { LoadingOutlined, CloseOutlined, SyncOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { BsFillPauseFill, BsPlayFill } from "react-icons/bs";
import { FiImage, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Api, Color, Utils } from "services";
import HyperModal from "react-hyper-modal";
import Lottie from "react-lottie";
import animationData from "Assets/lottie/doc.json";
import animationCheck from "Assets/lottie/check.json";
import moment from "moment";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import { submitModalFun, reviewModalFun, leaveModalFun, studentTestCollectQuesIndex,
  studentTestcollectMcq, studentTestcollectBookmark, postStudentTest, studentTestUpdateAns,
  studentTestcollectImage, deleteQuesImgModalFun, confromDeleteQuesImgModalFun, imageZoomModalFun,
  setImgsofReviewQuesiton, getStudentTestDurationTimer, getStudentTestDurationDecrement, getStudentTestDetail,
  getStudentTestCurrentTime} from "actions/mobileTest";
import { connect } from "react-redux";
import MathJax from "react-mathjax-preview";
import { DetailCard, TestTopBar, MobileButton } from "components";
import SubmitModal from './submitModal';
import ReviewModal from './reviewModal';
import LeaveModal from './leaveModal';
import ImageZoomModal from './imageZoomModal';
import TeX from '@matejmazur/react-katex';
import "./styles.css";
import "design/layout.css";
import "design/assignment.css";


class MobileTestReviewQuestions extends React.Component {
  constructor() {
    super();
    this.state = {
      QnOimg: '',
      callSubmitOnce: false,
    }
  }

  componentDidMount() {
    this.props.getStudentTestDurationTimer(Utils.secondsToHms(this.props.studentTestDurationInSec));
    Utils.startDuation(this.props.studentTestDurationInSec, (res) => {
      this.props.getStudentTestDurationDecrement(res);
      this.props.getStudentTestDurationTimer(Utils.secondsToHms(res));
    })
    document.addEventListener("message", this.handleEvent);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.studentTestDurationInSec === 0 && !this.state.callSubmitOnce){
      this.props.getStudentTestDurationDecrement(-1);
      this.hitActionButton(this.props.studentTestQuesIndex+1, 'submit', 'timeup');
    }
  }

 handleEvent = (reactNativeMessage) => {
   if(reactNativeMessage.data.rnMessage == 'no'){
     this.props.getStudentTestCurrentTime(this.props.studentTestLiveId, (response) => {
       Utils.stopDuration();
       if(this.props.studentTestDurationInSec > 0){
         this.props.getStudentTestDurationTimer(Utils.secondsToHms(this.props.studentTestDurationInSec));
         Utils.startDuation(this.props.studentTestDurationInSec, (res) => {
           this.props.getStudentTestDurationDecrement(res);
           this.props.getStudentTestDurationTimer(Utils.secondsToHms(res));
         })
       }else{
         this.props.getStudentTestDurationTimer(Utils.secondsToHms(0));
         this.props.getStudentTestDurationDecrement(0);
       }
     })
   }
}

 componentWillUnmount() {
   document.removeEventListener("message", this.handleEvent);
 }

  hitActionButton = (quesIndex, jump, timerOver) => {
    const {studentTestAnsObj, studentTestQuesArr, studentTestQuesIndex, testUploadedBase64Arr, testUploadedImgArrNew, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal} = this.props;
    this.props.studentTestUpdateAns();
    var params;
    if(studentTestQuesArr[studentTestQuesIndex].type === 'Image'){
        let imgObjArr = [];
        testUploadedBase64Arr.map((key, index) => {
          testUploadedImgArrNew.map((key1, index1) => {
            if(key1.localImg === key.localImg){
              if(key.apiImg === ''){
                imgObjArr.push(key)
              }
            }
          })
        })

        let duplicateObj = {};
        for (let i=0, len=imgObjArr.length; i < len; i++ ){
            duplicateObj[imgObjArr[i]['localImg']] = imgObjArr[i];
        }
        imgObjArr = new Array();
        for (let key in duplicateObj ){
          imgObjArr.push(duplicateObj[key].localImgObj);
        }

       params = {
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: timerOver === 'timeup' ? studentTestDurationInSecTotal - (studentTestDurationInSec) : studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        files: imgObjArr,
        deletedFiles: JSON.stringify(testUploadedImgdeleteArr),
        testStatus: jump === 'submit' ? 'Submitted' : 'Draft',
      }
    }else{
       params = {
        answer: studentTestAnsObj.answer,
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: timerOver === 'timeup' ? studentTestDurationInSecTotal - (studentTestDurationInSec) : studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        testStatus: jump === 'submit' ? 'Submitted' : 'Draft',
      }
    }
    console.log('params++++++++++', params);
    const type = studentTestQuesArr[studentTestQuesIndex].type === 'Image' ? 'image' : 'mcq';
    this.props.postStudentTest(params, type, (response) => {
        this.setState({callSubmitOnce:true})
        this.props.submitModalFun(true);
        Utils.stopDuration();
    });
  }

  htmlParser = (text) => {
    return(
      ReactHtmlParser(text, { transform: (node) => {
        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
          return <TeX math={`${node.attribs['data-value']}`} />
        }
        if (node.type === 'tag' && node.name === 'img') {
          return (
            <div>
              <img
                onClick={() => {
                    this.props.imageZoomModalFun(true, false);
                    this.setState({QnOimg:node.attribs.src});
                  }}
                alt=''
                style={{maxHeight: '40vh', maxWidth: '40vw', backgroundColor:'#f8f8f6'}}
                src={node.attribs.src} />
            </div>
          )
        }
      }})
    )
  }

  navToQueScreen = (history, index) => {
    Utils.stopDuration();
    history.push(history.location.pathname.replace('/review', ''));
    this.props.studentTestCollectQuesIndex(index);
  }


  render() {
    const {history,  studentTestQuesArr, user, studentTestQuesIndex, studentTestPostQuesLoader,
     reviewModalBool, studentTestDurationTimer, studentTestDetailLoader, studentTestCurrentTimeLoader,
     studentTestPostSubmitLoader, studentTestDurationInSec, testProgress} = this.props;
     console.log('history', history.location.pathname);

    return (
      <div className="greyBackTest" style={{ height: "100%" }}>
        <TestTopBar
          backPress={() => this.props.leaveModalFun(true)}
          title={'Review'}
          listMode={false}
          percent={testProgress/studentTestQuesArr.length*(100)}
          precentshow={true}
        />
        <div className="m-l-10 m-r-10 m-t-10 r-c-sb">
          <div>All Questions</div>
          <div className="r-ac">
            <MdTimer className="text-md seaGreen"/>
            <div className='higrey m-l-5'>{studentTestDurationTimer}</div>
          </div>
        </div>

        {studentTestQuesArr.length
            ?
              studentTestQuesArr.map((data, index) => (
                <div onClick={() => this.navToQueScreen(history, index)} className='test-borders m-10 p-10 m-b-20'>
                  <div>
                    <div className='r-c-sb'>
                      <div className='r-ac'>
                        <div className='lowgrey text-xmd'>Marks: </div>
                        <div className='seaGreen text-sm m-l-2'>
                          +{data.positiveMarks}
                          {(data.negativeMarks === null || data.negativeMarks === 0)
                          ?
                            null
                          :
                            <span>
                            <span className='m-l-5 m-r-5 lowgrey'>|</span>
                            <span style={{color:Color.red}}>-{(data.negativeMarks || '')}</span>
                            </span>
                          }
                        </div>
                      </div>
                      <div className='r-ac'>
                        <div className='orange-yellow text-xmd'>{index+1}</div>
                        <div className='lowgrey text-xmd'>/{studentTestQuesArr.length}</div>
                      </div>
                      <div className='r-ac'>
                        {data.submitStatus === 'Bookmarked' || data.submitStatus === 'AttemptedAndBookmarked'
                        ?
                          <FaBookmark className='orange-yellow text-sm'/>
                        :
                          <FaRegBookmark className='orange-yellow text-sm'/>
                        }
                        <div className='lowgrey m-l-2 text-xmd'>Bookmark</div>
                      </div>
                    </div>
                    <Divider style={{margin:'15px 0px'}}/>
                    <div className='text-md bold m-b-10 color-black'>
                      {this.htmlParser(data.statement)}
                    </div>
                    <div>
                      {(data.submitStatus === 'Unanswered' || data.submitStatus === 'NotVisited' || data.submitStatus === 'Bookmarked')
                        ?
                          <div className='r-ac p-10' style={{background:Color.lightblue, borderRadius:'0px 0px 9px 9px', margin:'-10px'}}>
                            <div className='text-sm color-black m-l-10'>NA</div>
                          </div>
                        :
                          (data.type === 'MCQ'
                            ?
                              data.options.map((key, index) => (
                                key.correct
                                ?
                                  <div className='r-ac p-10' style={{background:'#EAFCF5', borderRadius:'0px 0px 9px 9px', margin:'-10px'}}>
                                    <div className='test-small-avtar r-c-c color-black' style={{background: Color.seaGreen, color: '#fff', alignSelf:' flex-start'}}>
                                      {key.id === 0 ? 'A' : key.id === 1 ? 'B' : key.id === 2 ? 'C' : 'D'}
                                    </div>
                                    <div className='text-sm color-black m-l-10'>{this.htmlParser(key.value)}</div>
                                  </div>
                                :
                                null
                              ))
                            :
                              data.type === 'Subjective'
                                ?
                                  data.studentAnswer !== null
                                    ?
                                      <div className='r-ac p-10' style={{background:'#EAFCF5', borderRadius:'0px 0px 9px 9px', margin:'-10px'}}>
                                        <div className='text-sm color-black m-l-10'>{data.studentAnswer}</div>
                                      </div>
                                    :
                                      null
                                :
                                  data.studentFiles !== null
                                  ?
                                    <div className='r-ac p-10 display-flex flex-wrap' style={{background:'#EAFCF5', borderRadius:'0px 0px 9px 9px', margin:'-10px'}}>
                                      {data.studentFiles.map((key,index) => (
                                        <img
                                          alt=''
                                          onClick={() => {
                                            // this.setState({QnOimg: ''});
                                            // this.props.setImgsofReviewQuesiton(data.studentFiles);
                                            // this.props.imageZoomModalFun(true, index);
                                          }}
                                          src={key.apiImg !== '' ? `https://s3.ap-south-1.amazonaws.com/prod.files.classroom.digital-0949/${key.apiImg}` : key.localImg} style={{width:'90px', height:'115px', objectFit:'cover', backgroundColor:'#f8f8f6'}}
                                          className='radius-10 m-r-10 m-b-10'/>
                                      ))}
                                    </div>
                                  :
                                    null)
                      }
                    </div>
                  </div>
                </div>
              )) : null
        }
        <div className='r-c-c p-t-20 p-b-20 test-student-bottom-sticky'>
          <Button
            onClick={() => this.hitActionButton(studentTestQuesIndex+1, 'submit')}
            className='mobileTestGreenBtnWrap'
          >
            SUBMIT <ArrowRightOutlined />
          </Button>
        </div>

        <SubmitModal history={history}/>
        <LeaveModal history={history} />
        <HyperModal
          isOpen={this.props.deleteQuesImgModalBool}
          unmountOnClose
          requestClose={() => this.props.deleteQuesImgModalFun(false, false)}
          position={{alignItems: "flex-end", justifyContent: "center"}}
          renderCloseIcon={() => null}
        >
          <div className="full-width r-c-c p-t-10">
            <div className="topNotch inline-flex" />
          </div>
          <div className='p-10 text-center'>
            <div className="r-c-c m-t-10">
              <div className="r-ac">
                <MdTimer className="text-md seaGreen"/>
                <div className='higrey m-l-5'>45:12:43</div>
              </div>
            </div>
            <div className="text-sm m-t-20 color-black bold-700">Do you want to leave test?</div>
            <div className="higrey text-xmd m-b-20">
              The test will remain paused and you can resume the test later.
            </div>
            <Button onClick={() => this.props.confromDeleteQuesImgModalFun()} className='mobileTestGreenBtnWrap m-10' >LEAVE TEST</Button>
          </div>
        </HyperModal>
        <Modal
          visible={studentTestPostQuesLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">Loading Question</div> */}
          </div>
        </Modal>
        <ImageZoomModal QnOimg={this.state.QnOimg} />
        <Modal
          visible={studentTestPostSubmitLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">{studentTestDurationInSec === 0 ? 'Time Over. ' : ''} Saving test answers</div> */}
          </div>
        </Modal>
        <Modal
          visible={studentTestCurrentTimeLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">Updating Tests</div> */}
          </div>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {   studentTestQuesArr, studentTestQuesIndex,
    studentTestAnsObj, testUploadedBase64Arr, deleteQuesImgModalBool, studentTestPostQuesLoader,
    testUploadedImgdeleteArr, reviewModalBool, studentTestDurationInSec, studentTestDurationTimer,
    studentTestDurationInSecTotal, testUploadedImgArrNew, studentTestLiveId, studentTestDetailLoader,
    studentTestCurrentTimeLoader, studentTestPostSubmitLoader, testProgress
    } = state.mobileTest;
  const { user } = state.session;
  return {
    user,  studentTestQuesArr, studentTestQuesIndex,
    studentTestAnsObj, testUploadedBase64Arr, deleteQuesImgModalBool, studentTestPostQuesLoader,
    testUploadedImgdeleteArr, reviewModalBool, studentTestDurationInSec, studentTestDurationTimer,
    studentTestDurationInSecTotal, testUploadedImgArrNew, studentTestLiveId, studentTestDetailLoader,
    studentTestCurrentTimeLoader, studentTestPostSubmitLoader, testProgress
  };
};

const mapDispatchToProps = dispatch => ({
  submitModalFun: (bool) => dispatch(submitModalFun(bool)),
  reviewModalFun: (bool) => dispatch(reviewModalFun(bool)),
  leaveModalFun: (bool) => dispatch(leaveModalFun(bool)),
  deleteQuesImgModalFun: (bool, index) => dispatch(deleteQuesImgModalFun(bool, index)),
  studentTestCollectQuesIndex: (index) => dispatch(studentTestCollectQuesIndex(index)),
  studentTestcollectMcq: (id) => dispatch(studentTestcollectMcq(id)),
  studentTestcollectBookmark: () => dispatch(studentTestcollectBookmark()),
  postStudentTest: (params, type, callback) => dispatch(postStudentTest(params, type, callback)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  confromDeleteQuesImgModalFun: () => dispatch(confromDeleteQuesImgModalFun()),
  studentTestcollectImage: (imgObj, base64img) => dispatch(studentTestcollectImage(imgObj, base64img)),
  imageZoomModalFun: (bool, index) => dispatch(imageZoomModalFun(bool, index)),
  setImgsofReviewQuesiton: (arr) => dispatch(setImgsofReviewQuesiton(arr)),
  getStudentTestDurationTimer: (time) => dispatch(getStudentTestDurationTimer(time)),
  getStudentTestDurationDecrement: (time) => dispatch(getStudentTestDurationDecrement(time)),
  getStudentTestDetail: (id, tabname, callback) => dispatch(getStudentTestDetail(id, tabname, callback)),
  getStudentTestCurrentTime: (id, callback) => dispatch(getStudentTestCurrentTime(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTestReviewQuestions);
