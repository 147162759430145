import { Api, Notification } from "../services";
import { paymentDetails } from "./profileNew";


// POPULAR COURSES RENDERS
export const SET_POPULAR_COURSE_RENDER = "SET_POPULAR_COURSE_RENDER"
export const SET_COURSE_PAGE_DATA = "SET_COURSE_PAGE_DATA"
export const SET_TEST_SERIES_DATA = "SET_TEST_SERIES_DATA"
export const SET_HOMEPAGE_DATA = "SET_HOMEPAGE_DATA"
export const SET_ALL_COURSES_DATA = "SET_ALL_COURSES_DATA"
export const SET_EXPLORE_COURSE_DATA = "SET_EXPLORE_COURSE_DATA"
export const SET_LOADER = "SET_LOADER"
export const SET_ITEM_TYPE = "SET_ITEM_TYPE"
export const SET_LOGIN_DETAILS = "SET_LOGIN_DETAILS"
export const SET_PARENT_LINK = "SET_PARENT_LINK"
export const SET_CHILD_LINK = "SET_CHILD_LINK"
export const SET_MY_RESOURCES_DATA = "SET_MY_RESOURCES_DATA"
export const SET_ITEMID = "SET_ITEMID"
export const SET_RENDER_TEST = "SET_RENDER_TEST"
export const FETCH_PROMO_CODES = "FETCH_PROMO_CODES"
export const FETCH_CART_DETAILS = 'FETCH_CART_DETAILS'
export const STORE_PAYMENT_DETAILS_NEEDED = 'STORE_PAYMENT_DETAILS_NEEDED';
export const POST_PAYMENT_DETAILS = 'POST_PAYMENT_DETAILS';
export const SET_QUIZ_DATA = "SET_QUIZ_DATA"
export const STORE_SINGLE_BUYNOW = 'STORE_SINGLE_BUYNOW'
export const SET_CART_COUNT = "SET_CART_COUNT"
export const SET_COUPON = 'SET_COUPON'
export const SET_PROMO_PRICE = 'SET_PROMO_PRICE'
export const SET_RESOURCES_TYPE = 'SET_RESOURCES_TYPE'


export const storePromoPrice = res => ({
    type: SET_PROMO_PRICE,
    res
})

export const setCouponInCart = res => ({
    type: SET_COUPON,
    res
})

export const storeSingleBuyNow = res => ({
    type: STORE_SINGLE_BUYNOW,
    res
})

export const postPaymentDetails = res => ({
    type: POST_PAYMENT_DETAILS,
    res
})

export const storeDetailsNeededForPayment = res => ({
    type: STORE_PAYMENT_DETAILS_NEEDED,
    res
})

export const storeOrderDetails = res => ({
    type: FETCH_CART_DETAILS,
    res
})

export const storePromoCodesData = res => ({
    type: FETCH_PROMO_CODES,
    res
})

export const setExploreCourseData = (val) => ({
    type: SET_EXPLORE_COURSE_DATA,
    val,
})

export const setAllCourseData = (val) => ({
    type: SET_ALL_COURSES_DATA,
    val,
})

export const setHomePageData = (val) => ({
    type: SET_HOMEPAGE_DATA,
    val,
})

export const setPopularRender = (val) => ({
    type: SET_POPULAR_COURSE_RENDER,
    val,
});

export const setCoursePage = (item) => ({
    type: SET_COURSE_PAGE_DATA,
    item,
});



export const setTestSeriesData = (val) => ({
    type: SET_TEST_SERIES_DATA,
    val,
})

export const setLoader = (val) => ({
    type: SET_LOADER,
    val,
})

export const setItemType = (val) => ({
    type: SET_ITEM_TYPE,
    val,
})

export const setLoginDetails = (key, val) => ({
    type: SET_LOGIN_DETAILS,
    key,
    val,
})

export const setParentLink = (val) => ({
    type: SET_PARENT_LINK,
    val,
})

export const setChildLink = (val) => ({
    type: SET_CHILD_LINK,
    val,
})

export const setMyResources = (val) => ({
    type: SET_MY_RESOURCES_DATA,
    val,
})

export const setItemId = (val) => ({
    type: SET_ITEMID,
    val,
})

export const setRenderTest = (val) => ({
    type: SET_RENDER_TEST,
    val,
})
export const setQuizData = (val) => ({
    type: SET_QUIZ_DATA,
    val,
})

export const setCartCount = (val) => ({
    type: SET_CART_COUNT,
    val,
})

export const setResourceType = (val) => ({
    type: SET_RESOURCES_TYPE,
    val
})

export const LikeApi = (id, videoResourceId, unlike, callback) => {
    return (
        Api.patch("/course/like-question-answer")
            .params({ id, videoResourceId, unlike })
            .send((response) => {
                if (response) {
                    callback(response)
                }
                // Api.header(token);
            })
    )
}

export const QArequist = (param) => {
    return (
        Api.post("/course/update-question-answer")
            .params(param)
            .send((response) => {
                if (response) {
                    callback(response)
                }
                // Api.header(token);
            })
    )
}


export const AddRating = (id, type, ratingValue, ratingExplanation, callback) => {
    let param = {
        id: id,
        type: type,
        ratingValue: ratingValue,
        ratingExplanation: ratingExplanation,
    }
    return (
        Api.post("/course/add-rating")
            .params(param)
            .send((response) => {
                if (response) {
                    callback(response)
                }
                // Api.header(token);
            })
    )
}

export const QuizResults = (param, callback, loader) => {

    return (
        Api.get("/course/quiz-result")
            .params(param)
            .send((response) => {
                if (response) {
                    callback(response)
                }
                if (loader) {
                    loader(false)
                }
                // Api.header(token);
            })
    )
}

export const ViewSolutions = (param, callback, loader) => {
    return (
        Api.get("/course/quiz-solution")
            .params(param)
            .send((response) => {
                if (response) {
                    callback(response)
                }
                if (loader) {
                    loader(false)
                }
                // Api.header(token);
            })
    )
}

export const SubmitQuiz = (params, paramAttemptQuiz, callback, loader) => {

    console.log("xxxxxxxx", paramAttemptQuiz, params);
    return (
        Api.post("/course/submit-quiz")
            .params({ ...paramAttemptQuiz, questions: params })
            .send((response) => {
                if (response) {
                    callback(response)
                }
                if (loader) {
                    loader(false)
                }
                // Api.header(token);
            })
    )
}


export const AttemptQuiz = (param, callback, loader) => {
    if (loader)
        loader(true);
    return (
        Api.post("/course/attempt-quiz")
            .params(param)
            .send((response) => {
                if (response) {
                    callback(response)
                }
                if (loader) {
                    loader(false)
                }
                // Api.header(token);
            })
    )
}



export const Enquiry = (token, form, callback, loader) => {
    if (loader)
        loader(true)
    return Api.post(`/market/enquiry`)
        .params(form)
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            // console.log("resssssss", response)
            Api.header(token);
        })
}



export const TestSeriesApi = (token, id, callback, loader) => {
    if (loader)
        loader(true)
    return Api.get(`/testSeries`)
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            // console.log("resssssss", response)
            Api.header(token);
        })
}

export const MyStudyMaterial = (token, id, callback, loader) => {
    if (loader)
        loader(true)
    return Api.get("/course/my-material")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const MyResource = (token, itemId, callback, loader) => {
    if (loader)
        loader(true)
    console.log("navid", itemId)
    return Api.get("/course/my-resource")
        .params({ id: itemId })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            // console.log("resssssss", response)
            Api.header(token);
        })
}


export const MyLiveCourses = (token, id, callback, loader) => {
    if (loader)
        loader(true)
    return Api.get("/course/my-course")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            // console.log("resssssss", response)
            Api.header(token);
        })
}

export const MyResourse = (token, param, callback, loader) => {
    if (loader)
        loader(true)

    return Api.get("/course/my-resources").params(param)
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            // console.log("resssssss", response)
            Api.header(token);
        })
}

export const HomeApi = (callback, loader, token) => {

    if (loader)
        loader(true)
    return Api.get("/market/coursesInfo").send((response) => {
        if (response) {
            callback(response)
        }
        if (loader) {
            loader(false)
        }
        console.log("resssssss", response)
        Api.header(token);
    })
}

export const CoursePageApi = (token, id, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.get("/market/courseDetails")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const VideoResourcePageApi = (token, id, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.get("/market/vResourceDetails")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const StudyMaterialExplore = (token, id, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.get("/market/studyMaterialList")
        .params({})
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const StudyMaterialPage = (token, id, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.get("/market/studyMaterialDetails")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const CartList = (token, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.get("/market/cart")
        .params({})
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const RemoveCart = (token, id, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.delete("/market/cart/remove-item")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const AddToCart = (token, id, itemType, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.post("/market/cart/add-item")
        .params({ id, itemType })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const SavedLaterList = (token, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.get("/market/saved")
        .params({})
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const AddSaveLater = (token, id, itemType, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.post("/market/saved/add-item")
        .params({ id, itemType })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const RemoveSaveLater = (token, id, callback, loader) => {
    if (loader) {
        loader(true)
    }
    Api.delete("/market/saved/remove-item")
        .params({ id })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const getAllCoursesData = (token,fromHomePage, cId, callback, search) => {
    return (dispatch, getState) => {
        console.log(cId,'cId',fromHomePage);
        let params
        if(cId == ''){
             params = {
                token: token,
            }
        }else if(fromHomePage == 'pid'){
            console.log("hello");
            params = {
                pId:cId
            }
        }
        else{
            params = {
                token: token,
                cId: cId
            }
        }
        
        const url = `/market/resourcesInfo`;
        return Api.get(url).params({...params, search}).send((response) => {
            if (response) {
                callback();
                dispatch(setAllCourseData(response))
            };
        });
    };
};

export const getAllStudyMaterials = (filtersData,type,categoryId,callback) => {
    return (dispatch, getState) => {
        let params
        if(categoryId ==  ''){
            params = {
                feeType: filtersData == 'free' ? 'free' : null
            }
        }else if(type == 'pid'){
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                pId:categoryId
            }
        }
        else{
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                cId:categoryId
            }
        }
        
        const url = `/market/studyMaterialList`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};


export const AllCoursesData = (token, cId, callback, loader) => {
    if (loader)
        loader(true)
    Api.get("/market/resourcesInfo")
        .params({ cId })
        .send((response) => {
            if (response) {
                callback(response)
            }
            if (loader) {
                loader(false)
            }
            Api.header(token);
        })
}

export const getCourseList = (filter,type, categoryId, totalPage, callback) => {
    return (dispatch, getState) => {
        console.log("filter1", categoryId);
        let params
        if(categoryId == ''){
             params = {
                period: filter?.period,
                feeType: filter?.free,
                mode: filter?.mode,
                page: 1,
                limit: totalPage,
            }
        }else if(type == 'pid'){
            params = {
                period: filter?.period,
                feeType: filter?.free,
                mode: filter?.mode,
                pId: categoryId,
                page: 1,
                limit: totalPage,
            }
        }
        else{
            params = {
                period: filter?.period,
                feeType: filter?.free,
                mode: filter?.mode,
                cId: categoryId,
                page: 1,
                limit: totalPage,
            }
        }
        
        console.log("params", params);
        const url = `/market/courseList`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};

export const getVideoResourcesList = (filtersData,type, categoryId, totalPage, callback) => {
    return (dispatch, getState) => {
        let params
        if(categoryId == ''){
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                page: 1,
                limit: totalPage,
            }

        }else if(type == 'pid'){
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                pId: categoryId,
                page: 1,
                limit: totalPage,
            }
        }
        else{
            params = {
                feeType: filtersData == 'free' ? 'free' : null,
                cId: categoryId,
                page: 1,
                limit: totalPage,
            }
        }
    
        const url = `/market/videoResourceList`;
        return Api.get(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(setExploreCourseData(response))
            };
        });
    };
};
export const CourseListApi = (token, filter, callback, loader, page, totalPage) => {
    if (loader) {
        loader(true)
    }
    console.log("insideee", filter);
    let param = null;
    if (filter === "") {
        param = {

            periodType: null,
            feeType: null,
            mode: null,
            page: page,
            limit: totalPage,
        }
    } else {
        param = {
            periodType: null,
            feeType: null,
            mode: null,
            page: page,
            limit: totalPage,
        }
        if (filter === "ongoing" || filter === "upcoming") {
            param.periodType = filter;
        }
        else if (filter === "online" || filter === "offline" || filter === "hybrid") {
            param.mode = filter;
        }
        else if (filter === "free") {
            param.feeType = filter;
        }
        return (
            Api.get("")
                .params(param)
                .send((response) => {
                    if (response) {
                        callback(response)
                    }
                    if (loader) {
                        loader(false)
                    }
                    Api.header(token);
                })
        )
    }
}


export const ExploreCourseData = (token, filter, callback, loader, page, totalPage) => {
    if (loader) {
        loader(true)
    }
    console.log("insideee", filter);
    let param = null;
    if (filter === "") {
        param = {

            periodType: null,
            feeType: null,
            mode: null,
            page: page,
            limit: totalPage,
        }
    } else {
        param = {
            periodType: null,
            feeType: null,
            mode: null,
            page: page,
            limit: totalPage,
        }
        if (filter === "ongoing" || filter === "upcoming") {
            param.periodType = filter;
        }
        else if (filter === "online" || filter === "offline" || filter === "hybrid") {
            param.mode = filter;
        }
        else if (filter === "free") {
            param.feeType = filter;
        }
        return (
            Api.get("/market/videoResourceList")
                .params(param)
                .send((response) => {
                    if (response) {
                        callback(response)
                    }
                    if (loader) {
                        loader(false)
                    }
                    Api.header(token);
                })
        )
    }
};

export const fetchPromoCodes = (callBack = null) => {
    return (dispatch, getState) => {
        const url = `/market/offers`;
        return (
            Api.get(url).send((response) => {
                if (response !== undefined) {
                    dispatch(storePromoCodesData(response))
                }
                callBack();
            })
        );
    };
};

export const applyPromo = (params, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const url = `/market/apply-promo`;
        if(params){
            params.hideInvalid = true;
        }
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            console.log('response2', response);
            if (typeof response != "undefined" && response.show.type === "success") {
                dispatch(storePromoPrice(response))
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}


export const payNowCart = (params, callBack = null) => {
    return (dispatch, getState) => {
        const url = `/market/cart-order`;
        return (
            Api.get(url).params(params).send((response) => {
                if (response !== undefined) {
                    callBack(response.body);
                }
                dispatch(storeDetailsNeededForPayment(response))
            })
        );
    };
};

export const postCartPayment = (params, callback) => {
    return (dispatch, getState) => {
        const url = `/process-payment`;
        return Api.post(url).params(params).send((response) => {
            if (response) {
                callback();
                dispatch(postPaymentDetails(response))
            };
        });
    };
};

