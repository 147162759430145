import { Button, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import PhoneInput from "react-phone-number-input";
import {connect} from "react-redux";
import {saveSupportInfo, getOverallStatusFunction} from "actions/adminCare";
import { useHistory } from 'react-router-dom';
import PageDataLoader from 'components/pageDataLoader';


function CareInfoForm({
    saveSupportInfo, getOverallStatusFunction,access,
    role
}) {

    const [name, setName] = useState("");
    const [phoneNos, setPhone] = useState(['']);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);

    useEffect(() => {
        togglePageLoader(true);
        getOverallStatusFunction(() => togglePageLoader(false));
    }, [])

    const handlePhoneNoChange = (value, index) => {
        phoneNos[index] = value || "";
        setPhone([...phoneNos])
    }

    const addNo = () => {
        phoneNos.push('');
        setPhone([...phoneNos]);
    }
   

    const history = useHistory();

    const handleSaveClick = () => {
        setButtonLoader(true)
        saveSupportInfo({ supportPhones: phoneNos}, () => setButtonLoader(false), history)
    }

    return (
        <div style={{height: "100%"}}>
            <div className="r-c-c m-t-40">
                <img src={require("Assets/supportForm.svg").default} alt="" />
            </div>
            <div className="r-c-c text-md text-center bold-600 color-purple primary">
                Welcome to aulas Care
            </div>
            <div className="r-c-c  text-center dark-grey m-t-10">
                You are just one step away from providing help to your students and staff.
            </div>

            {/* {role.includes('SuperAdmin') ?  */}
            {access.includes('care-admin') ? 

            <div className="r-c-c m-t-20">
                <div className="careAdminSummaryCard" style={{width: 500, height: "fit-content", justifyContent: 'flex-start'}}>
                    <div className="text-center dark-grey p-l-30 p-r-30">
                        All we need is the name and phone number for your students and staff to reach for any inquires.
                    </div>
                    {/* <div className="p-l-30 m-t-20">
                        <div className="bold-600 text-xs">Name</div>
                        <Input className="m-t-10" value={name} onChange={e => setName(e.target.value)} placeholder="Enter name"/>
                    </div> */}
                    
                    <div className="p-l-30 m-t-20 supportPhone" style={{maxHeight: 250, overflowY: 'auto'}}>
                        {phoneNos.map((phone, index) => 
                        <div  key={index} className="m-t-10">
                            <div className="bold-600 text-xs">Number {index + 1 }</div>
                            <PhoneInput
                                style={{ width: "100%", border: "1px solid #d9d9d9", paddingLeft: 5, backgroundColor: "transparent" }}
                                defaultCountry="IN"
                                className="m-t-10"
                                placeholder="Enter phone number"
                                value={phone}
                                maxLength={(phone?.startsWith('+91') || phone?.startsWith('91')) && phone?.length == 13 ? 11 : ""}
                                // onCountryChange={(e) => console.log("code", e)}
                                onChange={e => handlePhoneNoChange(e, index)}
                            />
                        </div>
                        )}
                        <div className="r-c-fe">
                            <Button type="link" className="text-xs bold-600" 
                                onClick={() => addNo()}
                                disabled={phoneNos[phoneNos.length - 1] === ""}
                            >
                                + ADD MORE
                            </Button>
                        </div>
                        <div className="dark-grey text-xs m-t-5">
                            Number could be landline or mobile number.
                        </div>
                    </div>
                    <div className="r-c-c p-l-30 m-t-30">
                        <Button 
                            type="primary" shape="round" 
                            style={{width: 120}} 
                            onClick={() => handleSaveClick()} loading={buttonLoader}
                            disabled={phoneNos[phoneNos.length - 1] === ""}
                        >
                            SAVE
                        </Button>
                    </div>
                </div>
            </div> : 
             <div className="r-c-c  text-center dark-grey m-t-10">
                 All we need is the name and phone number from your SuperAdmin for your students and staff to reach for any inquires.
             </div>
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}


const mapStateToProps = (state) => {
    const {
     role,
     access
      
    } = state.session.user;
    return {
        role,
        access
      
    };
  };

const mapDispatchToProps = (dispatch) => ({
    saveSupportInfo: (params, callback, history) => dispatch(saveSupportInfo(params, callback, history)),
    getOverallStatusFunction: (callback) => dispatch(getOverallStatusFunction(callback)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CareInfoForm);
