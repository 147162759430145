import React, { useEffect, useState } from "react";
import { Modal, Select, DatePicker, TimePicker, Input } from "antd";
import { getStudentsDetailsById } from 'actions/userManagement';
import { connect } from 'react-redux';
import { assignClassCourseInCrm, getAutocompleteCourses, getAutocompleteSearchOffers, setCourseStudentRollNo } from "actions/courseManagement";
import { getAllClasses } from 'actions/classRoom'
import AnimatedCustomSelect from "components/AnimatedSelectComp";
import { fetchStudentRollNo, storeStudentRollNo } from "actions/userManagementV2";
import AnimatedTextField from "components/AnimatedTextField";
import { Button } from "components/Button";
import AnimatedDatePicker from "components/AnimatedDatePicker";

const { Option } = Select;

const AddCourseClassModal = ({ setIsAssignBatchModalVisible, isAssignBatchModalVisible, assignClassCourseInCrm, getStudentsDetailsById, userDetailsById, userRowDetails, selectedStudents, studentRollNo, assignserDetails, storeStudentRollNo, setCourseStudentRollNo, courseStudentRollNo,
    getAutocompleteCourses, classes, getAllClasses, getAutocompleteSearchOffers, autocompleteSearchData, autocompleteCoursesSearchData, fetchStudentRollNo, getEnquiryFormsData, getCoursesInCartData, getWhistledCoursesData, tab, togglePageLoader, setPage }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [seletedUserDetails, setSelectedUserDetils] = useState([]);
    const [selectedCourse, setSelectCourse] = useState(null);
    const [selectedBatch, setSelectBatch] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [itemType, setItemType] = useState('course');



    const [paramsObj, setParamsObj] = useState({
        targetStd: null,
        targetSec: null
    })

    const clearValues = () => {
        storeStudentRollNo(null)
        setSelectedOption(null)
        setSelectedDate(null)
        setItemType('')
        setCourseStudentRollNo("rollNo", null);


    }


    useEffect(() => {
        storeStudentRollNo(null)
        getAutocompleteCourses(() => { });
    }, []);

    useEffect(() => {
        getAllClasses();

    }, []);


    const successCallback = () => {
        togglePageLoader(true);
        setIsAssignBatchModalVisible(false);
        setParamsObj({
            targetStd: null,
            targetSec: null
        })
        setPage(1)
        if (tab == 1) {
            getEnquiryFormsData('Enquiry', 1, true, () => { togglePageLoader(false), clearValues() })
        } else if (tab == 2) {
            getEnquiryFormsData('Registration', 1, true, () => { togglePageLoader(false), clearValues() })
        } else if (tab == 3) {
            getCoursesInCartData(1, true, () => { togglePageLoader(false), clearValues() })
        } else {
            getWhistledCoursesData(1, true, () => { togglePageLoader(false), clearValues() })
        }
    }

    const handleOk = () => {

        let params = {
            courseId: selectedCourse,
            standardId: paramsObj?.targetSec,
            startDate: selectedDate,
            shiftCourse: false,
            itemType: itemType,
            rollNo: selectedStudents.length > 1 ? undefined : courseStudentRollNo?.rollNo ? courseStudentRollNo?.rollNo?.toString() : undefined,
            users: selectedStudents?.length > 0 ? selectedStudents : [assignserDetails]
        }
        assignClassCourseInCrm(params, () => { }, () => successCallback())

    };




    const handleDateChange = (date, dateString) => {
        setSelectedDate(dateString);
    };

    const handleCancel = () => {
        clearValues()
        setIsAssignBatchModalVisible(false);
    };

    const classKeysArray = Object.keys(classes)?.map((key) => ({ name: key, }));

    const sections = paramsObj?.targetStd && classes[paramsObj?.targetStd]?.map(cls => ({ id: cls.id, value: cls.section }));

    return (
        <>
            <Modal
                className="modal-round-corner"
                centered
                visible={isAssignBatchModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={false}
                width={520}
                title='Add batch/course'
            >



                <div className="'m-t-20">

                    <AnimatedCustomSelect
                        label={"Course name"}
                        required={true}
                        options={autocompleteCoursesSearchData}
                        source={"CRM"}
                        handleSelectChange={(value) => {
                            console.log('vvvaluevalue', value);
                            const selectedItem = autocompleteCoursesSearchData.find(item => item?.id === value);
                            setSelectedOption(selectedItem ? selectedItem?.id : null);
                            setItemType(selectedItem ? selectedItem.itemType : '')
                            setSelectCourse(selectedItem?.itemId)
                        }}
                        value={selectedOption}
                        isSelected={selectedOption ? true : false} />

                </div>
                {itemType == 'course' ?
                    <div className='m-t-40'>
                        <AnimatedDatePicker
                            inputReadOnly={true}
                            label={"Start date"}
                            isMandatory={true}
                            // inputId={"-999"}
                            value={selectedDate}
                            handleDateChange={handleDateChange}
                            isDisabled={!selectedOption}

                        />
                    </div> :
                    <></>}


                <div className='m-t-30'>
                    <AnimatedCustomSelect
                        label={"Batch"}
                        required={true}
                        options={classKeysArray}
                        handleSelectChange={(value) => {
                            if (!selectedDate) {
                                setParamsObj({ ...paramsObj, targetStd: value, targetSec: null });
                                setCourseStudentRollNo("rollNo", null)
                            } else {
                                setParamsObj({ ...paramsObj, targetStd: value, targetSec: null });
                                setCourseStudentRollNo("rollNo", null)
                            }

                        }}
                        value={paramsObj?.targetStd}
                        isSelected={paramsObj?.targetStd ? true : false}
                        // cursor = {!selectedDate}
                        disabled={itemType == 'course' ? !selectedDate : false}
                    />
                </div>


                <div className='m-t-30'>
                    <AnimatedCustomSelect
                        label={"Section"}
                        required={true}
                        options={sections}
                        handleSelectChange={(value) => {
                            setParamsObj({ ...paramsObj, targetSec: value });
                            fetchStudentRollNo(value, (res) => {
                                setCourseStudentRollNo("rollNo", res?.rollNo ? res?.rollNo : null)
                            })

                        }}
                        value={paramsObj?.targetSec}
                        isSelected={paramsObj?.targetSec ? true : false}
                        disabled={!paramsObj?.targetStd}

                    />
                </div>


                {selectedStudents?.length == 0 || selectedStudents?.length == 1 || !(assignserDetails?.userId == null && assignserDetails?.phone == null) ?
                    <div className='m-t-40'>
                        <AnimatedTextField
                            label={"Roll number"}
                            isMandatory={true}
                            inputId={"-998"}
                            // value={studentRollNo == null ? '' :  studentRollNo?.rollNo}
                            value={courseStudentRollNo?.rollNo}
                            handleInputChange={(e) => {
                                const value = e.target.value;
                                const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                                const truncatedValue = sanitizedValue.slice(0, 9);
                                setCourseStudentRollNo("rollNo", truncatedValue);
                            }}
                            isDisabled={!paramsObj?.targetSec || !paramsObj?.targetStd}
                            cursor={!paramsObj?.targetSec || !paramsObj?.targetStd}
                        />

                    </div>
                    : <></>}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                            onClick={handleCancel}
                        >
                            CANCEL
                        </Button>
                    </div>
                    <div>
                        <Button
                            disabled={itemType == 'course' ?
                                
                                selectedStudents?.length == 0 || selectedStudents?.length == 1 ? !selectedOption || !selectedDate || !paramsObj?.targetStd || !paramsObj?.targetSec || !courseStudentRollNo?.rollNo : !selectedOption || !selectedDate || !paramsObj?.targetStd || !paramsObj?.targetSec
                            :
                            selectedStudents?.length == 0 || selectedStudents?.length == 1 ? !selectedOption  || !paramsObj?.targetStd || !paramsObj?.targetSec || !courseStudentRollNo?.rollNo : !selectedOption || !paramsObj?.targetStd || !paramsObj?.targetSec
                        }
                            // style={{
                            //     opacity: selectedStudents?.length == 1 ? !selectedOption || !selectedDate  || !paramsObj?.targetStd || !paramsObj?.targetSec ||  !studentRollNo?.rollNo : !selectedOption || !selectedDate  || !paramsObj?.targetStd || !paramsObj?.targetSec ? 0.5 : 1
                            // }}
                            type="primary"
                            onClick={handleOk}
                        >
                            SAVE
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const { autocompleteCoursesSearchData, autocompleteSearchData, courseStudentRollNo } = state.courseManagement
    const { classes } = state.classRoom
    const { userDetailsById } = state.userManagement;
    const { userRowDetails, specificUserId, studentRollNo } = state.userManagementV2;
    return {
        userRowDetails, specificUserId, userDetailsById, autocompleteCoursesSearchData, classes, autocompleteSearchData, studentRollNo, courseStudentRollNo
    }
}

const mapDispatchToProps = (dispatch) => ({
    assignClassCourseInCrm: (params, callback, successCallback) => dispatch(assignClassCourseInCrm(params, callback, successCallback)),
    getStudentsDetailsById: (callback, id) => dispatch(getStudentsDetailsById(callback, id)),
    getAutocompleteCourses: (callback) => dispatch(getAutocompleteCourses(callback)),
    getAutocompleteSearchOffers: (callback) => dispatch(getAutocompleteSearchOffers(callback)),
    fetchStudentRollNo: (standardId, callback) => dispatch(fetchStudentRollNo(standardId, callback)),
    storeStudentRollNo: (rollNo) => dispatch(storeStudentRollNo(rollNo)),
    getAllClasses: () => dispatch(getAllClasses()),
    setCourseStudentRollNo: (key, val) => dispatch(setCourseStudentRollNo(key, val))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseClassModal);