import React from 'react'
import { Api } from 'services'
import { Typography } from 'antd'
import RatingStars from './ratingStars'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function MobileCourseCard({ item }) {
    const history = useHistory();
    function formatDuration(duration) {
        if (!duration) {
            return '';
        }
        const [hours, minutes, seconds] = duration?.split(':');
        const formattedDuration = new Date(0, 0, 0, hours, minutes, seconds);
        const formattedHours = formattedDuration.getHours();
        const formattedMinutes = formattedDuration.getMinutes();
        let result = '';
        if (formattedHours > 0) {
            result += `${formattedHours} ${formattedHours === 1 ? 'hour' : 'hours'}`;
        }
        if (formattedMinutes > 0) {
            if (result.length > 0) {
                result += ', ';
            }
            result += `${formattedMinutes} ${formattedMinutes === 1 ? 'minute' : 'minutes'}`;
        }
        return result || 'Less than a minute';
    }
    return (
        <div className='m-r-30'
            onClick={() => {
                history.push({
                    pathname: "/resource-details",
                    state: {
                        id: item?.id,
                        resourceType: item?.resourceType
                    }
                })
            }}>
            {item?.resourceType == "studyMaterial" ?
                <div style={{ width: "100%", cursor: "pointer" }}>
                    <div className='study-material-cards'
                    >
                        {item?.feeType == 'free' ?
                            <div className='study-materials-free' >
                                <div style={{ textAlign: "center", fontSize: "10px", color: '#FFFFFF', fontWeight: "700px", marginTop: 2 }}>FREE</div></div> : <div style={{ width: '60px', height: 20 }} ></div>}
                        <div style={{ display: "flex", marginLeft: "20px", position: "absolute", top: 20, left: 0 }}>
                            <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />
                            <div style={{ color: "#594099", fontSize: 16, marginLeft: 12, marginTop: "9px", }}>
                                {item?.m_count} {item?.m_count <= 1 ? "Material" : "Materials"}
                                {item?.q_count > 0 ? `, ${item?.q_count} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}
                            </div>
                        </div>
                        <div style={{ marginRight: "10px", marginTop: "-10px" }}>
                        {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <></>}
                        </div>
                    </div>
                </div>
                :
                <div style={{ width: "100%", height: "fit-content", position: "relative" }} >
                    <img src={`${Api.dlS3Url}${item?.image}`} style={{ borderRadius: 8, width: "200px", height: "120px" }} />
                    {item?.feeType == "free" ? <img src={require("../../Assets/userMarketPlace/freeTag.svg").default} style={{ position: "absolute", left: 0, top: -5, height: 45, width: 45 }} /> : <></>}
                    {item?.mode ? <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", position: "absolute", fontWeight: "bold", top: 15, right: 0 }}>{item?.mode?.charAt(0).toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
                    {item?.amount > 0 ? <div style={{ fontSize: 12, color: "#594099", backgroundColor: "#F6F4FE", padding: "3px 6px", position: "absolute", fontWeight: "bold", bottom: 10, right: 5, borderRadius: 4, border: " 1px solid #594099" }}>₹ {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount)).toFixed(2)}{" "}{item?.discountAmount > 0 ? <span style={{ color: "#FF414D", textDecoration: 'line-through' }}>₹ {parseFloat(item?.amount || 0).toFixed(2)}</span> : <></>}</div> : <></>}
                </div>
            }
            <Typography.Text className='m-t-5' ellipsis={{ tooltip: true }} style={{ color: "#191919", fontWeight: "bold", fontSize: 16, width: 200, }}>{item?.resourceType == "studyMaterial" ? item?.name : item?.courseName}</Typography.Text>
            {item?.resourceType === "videoResource" ? (
                <div className='' style={{ color: "#636363", fontSize: 12 }}>
                    {formatDuration(item?.duration)} video {item?.quizCount > 0 && <>| {item?.quizCount} quizzes</>} {item?.notesCount > 0 && <>| {item?.notesCount} notes</>}
                </div>
            ) : (
                <></>
            )}
            <div style={{ display: "flex" }}>
                {(item?.rating && item?.rating > 0) ? <RatingStars star={item?.rating} /> : <></>}
                <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : null}</div>
            </div>
        </div>
    )
}

export default MobileCourseCard