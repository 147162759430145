import {Modal} from 'antd';
import React from 'react';
import {connect} from 'react-redux';
import {toggleVideoPlayer} from 'actions/digitalLibrary_old';
// import YouTubePlayer from 'react-player/lib/players/YouTube';
import ReactPlayer from 'react-player';
import ReactPlayerHoc from 'components/ReactPlayerHoc';


function VideoPlayer({
    showVideoPlayer, toggleVideoPlayer,
    videoLink
}) {
    return (
        <Modal
            className='rounded-border'
            visible={showVideoPlayer}
            footer={false}
            width="fit-content"
            onCancel={() => toggleVideoPlayer(false, "")}
        >
            <ReactPlayerHoc
                style={{marginTop: 30}}
                height={500}
                keyboardControls={true}
                url={videoLink}
                controls={true}
            />
            
        </Modal>
    )
}



const mapStateToProps = state => {
    const {
        showVideoPlayer, videoLink
    } = state.digitalLibrary;    
    return {
        showVideoPlayer, videoLink
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    toggleVideoPlayer: bool => dispatch(toggleVideoPlayer(bool)),
    
  });


export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
