import React from 'react';
import {connect} from 'react-redux';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import 'antd/lib/layout/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import { MailOutlined } from '@ant-design/icons';
import './styles.css';
import { sendForgotLink } from '../../actions/login';

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  componentDidMount() {

  }

  handleForgotClick(history) {
    const{ email } = this.state;
    console.log('em', email);
    this.props.dispatch(sendForgotLink(email, history));
  }

  render() {
    const { history, resetLoader } = this.props;

    return (
      <Layout style={{height: '100%', padding:'60px'}}>
        <div className='r-c-c-c'>
          <div className='login-wrap'>
            <div className='text-md bold-600 color-black m-b-30'>Forgot Password</div>
            <div className='m-b-20'>
              <Input onChange={(em) => this.setState({email: em.target.value})}
               style={{borderRadius:'5px'}}
               prefix={<MailOutlined style={{color:'#787b8370'}} />}
               className='globalInput'
               size="large"
               placeholder="Registered Email"
               type="email"
               id="email"
              />
            </div>
            <div className='r-c-c m-t-50 m-b-10'>
              <Button
                className='login-btn'
                type="primary"
                onClick={() => this.handleForgotClick(history)}
                loading={resetLoader}
              >
              Send Reset Link
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const {
    resetLoader
  } = state.login;
  return {
    resetLoader
  };
};

export default connect(mapStateToProps)(ForgotPassword);
