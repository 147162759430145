import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchSubjectCardData, setImportMeta } from 'actions/practiseTest';
import { SubIcon } from 'components/subIcon';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NodataCard } from '.';
import Loader from './localComponents/loader';
import SearchBox from './localComponents/searchBox';



const PageHeader = ({ goBack, onSearch, searchVal, selectedQBtype, selectedClassname}) => {
    return (
      <div className="resultPageHeader full-width">
        <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
          <div className="text-md bold-700 m-l-20"> 
            <div>
              {selectedQBtype}
            </div>
            <div style={{color:"#AEAEAE", fontSize:12}}>
              {selectedClassname}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SubjectCard = ({data, onClick})=> {
    return (
       <div className='shadow-box p-20 m-l-20 m-b-20 p-t-20 r-c-c-c hover-shadow cursor-pointer' onClick={onClick(data)}
           style={{minWidth:200, borderRadius:10}}>
         <SubIcon icon={data.icon} name={data.subName} size={70}/>
         <div className='text-xmd bold-600 color-black m-b-5 m-t-10'>{data.subName}</div>
         <div className='text-xs color-black m-b-5'>{data.noOfChapter} Chapters</div>
       </div>
    )
}



const ImportPracSubwise = ({fetchSubjectCardData, importMeta, setImportMeta, loaders}) => {
    useEffect(()=>{
      fetchSubjectCardData(()=>{});
    }, []);
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }
    const onCardClick = (subData) => {
        return ()=>{
          setImportMeta('selectedSubjectObj', subData);
          history.push("/practise-test-import-sub-chapters")
        }
    }
    return (
        <div style={{backgroundColor:"#FAFAFA", width:"calc(100% + 40px)", overflow:"hidden", height:"calc(100% + 40px)", margin:-20, padding:20}}>
            <PageHeader selectedClassname={`${importMeta?.selectedStandardObj?.name} - ${importMeta?.selectedStandardObj?.stream===''?"NA":importMeta?.selectedStandardObj?.stream}`} selectedQBtype={'Subject-wise Question Papers'} goBack={goBack}/>
            {
              loaders?.importSubjectList?
              <Loader style={{fontSize:40}}/>
              :
              <div className="full-height" style={{overflowY:"scroll"}}>
                {
                  importMeta?.subjectCardData?.length>0
                  ?
                  <div className="r-afs flex-wrap m-t-20" >
                    {importMeta?.subjectCardData.map((item, index)=><SubjectCard data={item} onClick={onCardClick}/>)}
                  </div>
                  :<NodataCard/>
                }
              </div>
            }
       </div>
    )
}

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.practiseTest;
  return {  
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchSubjectCardData: (callback) => dispatch(fetchSubjectCardData(callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportPracSubwise);
