import { Api } from '../../services';
import React from 'react';
import {connect} from 'react-redux';
import PageHeader from 'antd/lib/page-header';
import Tabs from 'antd/lib/tabs';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Avatar from 'antd/lib/avatar';
import Select from 'antd/lib/select';
import Progress from 'antd/lib/progress';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import Input from 'antd/lib/input';
import Radio from 'antd/lib/radio';
import Empty from 'antd/lib/empty';
import 'antd/lib/page-header/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/progress/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/collapse/style/index';
import 'antd/lib/input/style/index';
import 'antd/lib/radio/style/index';
import 'antd/lib/empty/style/index';
import { BellOutlined,CheckOutlined, LoadingOutlined, CloseOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import { Color } from '../../services';
// import MathJax from 'react-mathjax-preview'
import { MdSend } from "react-icons/md";
import {NumericInput} from '../../components'
import ImageModal from './modals/imageModal';
import {Prompt} from 'react-router-dom'
import AssignmentUpdateModal from './modals/updateAssignment';
import moment from 'moment';
import { fetchStudentAnswers, setStudentAnswers, updateReviewItem, addTotalMarks, saveReview, setQuestionFeedBack, publishResults, handleAssignmentInput,
  sendReminder, getAssignmentById, updateSelectedTab, setCurrentStatement, removeCurrentQuestion, setCurrentImage, toggleQuestionModal } from '../../actions/assignment';
import QuestionModal from './modals/questionModal';
import QuestionList from './questionsList';
import InstructionModal from './modals/instructionModal'
import ViewQuestionModal from './modals/viewQuestionModal';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';


const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

class AssignmentSubmissionDetails extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedStudent: null,
      selectedStudentIndex: -1,
      marks: [],
      imageModal: false,
      imagePath: false,
      comments:[],
      commentView: false,
      editComment: false,
      comment: '',
      isEditing: false,
      ToggleInstructionModal: false
    }
  }

  async componentDidMount(){
    // window.addEventListener('popstate',() => );
    const {currentAssignment, assignmentPageTaskDetails, getAssignmentById} = this.props;
    const page = assignmentPageTaskDetails &&  assignmentPageTaskDetails.currentPage  ? assignmentPageTaskDetails.currentPage :  1;
    await getAssignmentById(currentAssignment.id, page, 'assignment-teacher');
  }

  submissionSummary = () => {
    const {graded, submitted, totalStudents, publishResults, currentAssignment, sendReminder, resultLoader, reminderLoader} = this.props;
    return (
    <Col style={{height: '100%'}} xs={{span: 24}} sm={{span:24}} md={{span:18}} lg={{span:16}} xl={{span:18}} xxl={{span:18}}>
      <div className='r-ac-jcc m-t-100'>
        <div style={{width: '90%', border: '1px solid #D9D9D9', borderRadius: 30, padding: 10, height: 150}}>
          <div className='r-jsb'>
            <div style={{color: Color.darkGrey, fontSize: 26, marginLeft:20}}>Submission</div>
            <div style={{marginRight:20}}>
              <Button style={{borderRadius: 30, fontSize: 10, borderColor: Color.red, color: Color.red}}
              loading={reminderLoader}
              onClick={() => sendReminder()}><BellOutlined /> Send Reminder</Button>
            </div>
          </div>
          <div style={{padding: 20, marginTop: 0}}>
            <div>{submitted}/{totalStudents}</div>
            <Progress percent={Math.round(submitted/totalStudents*100) || 0} />
          </div>
        </div>
      </div>
      <div className='r-ac-jcc m-t-200' >
        <div style={{width: '90%', border: '1px solid #D9D9D9', borderRadius: 30, padding: 10, height: 180}}>
          <div style={{color: Color.darkGrey, fontSize: 26, marginLeft:20}}>Evaluation</div>
          <div style={{padding: 20, marginTop: 0}}>
            <div>{graded}/{totalStudents}</div>
            <Progress percent={Math.round(graded/totalStudents*100) || 0} strokeColor={Color.green} />
            <div className='r-ac-jcc m-t-20'>
              <Button style={{borderRadius: 30, boxShadow: '1px 1px grey', color: Color.primary, fontWeight: 600}}
              loading={resultLoader}
              onClick={() => publishResults()} disabled={currentAssignment.declared || !currentAssignment.submitted}>
                {!currentAssignment.declared ? "Publish Results" : "Results Published"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )}

  studentAnswers = () => {
    const {answerLoader, studentResponse, orgId, currentAssignment, studentReview, saveButton, currentAssignmentTasks} = this.props;
    const {selectedStudent} = this.state;
    return (
    <Col style={{height: '100%'}} xs={{span: 24}} sm={{span:24}} md={{span:18}} lg={{span:16}} xl={{span:18}} xxl={{span:18}}>
      <div className='r-jsb' style={{ margin: '0px 20px', borderBottom: '1px solid #D9D9D9'}}>
        <div className="r-ac">
          <div> <ArrowLeftOutlined onClick={() => this.unselectStudent()} /></div>
          <div style={{marginLeft: 10}}>
            <div style={{fontSize: 22 }}>{this.state.selectedStudent.name}</div>
            <div className='display-flex' style={{fontSize: 12, paddingBottom: 5}}>
              {!answerLoader ? selectedStudent.submittedOn ?  `Submitted on ${moment(studentResponse.submittedOn).format('DD MMM')}` : 'Pending' : ''}
            </div>
          </div>
        </div>
        <div style={{fontSize: 20,  }} className='r-c-c-c'>
          {!answerLoader && currentAssignment.gradingType ==="Graded" ?
          <div>{selectedStudent.score !== null ? studentResponse.totalMarksObtained : '__ '}/{currentAssignment.totalPoints} </div>
          : null}
          <div style={{paddingBottom: 5}}>
              <Button type='primary'  size='small' style={{border: 0, fontSize: 12, borderRadius: 10, padding: '0px 20px'}}
              onClick={() => this.onSaveReview()}
              // disabled={!saveButton}
              >Save</Button>
          </div>
        </div>
      </div>
      <div>
      { answerLoader ?
        <div className='r-c-c-c' style={{height: '65vh'}}>
          <LoadingOutlined style={{color: '#5843BE', fontSize: 100}} />
        </div>
        :
         this.state.selectedStudent.submittedOn ?
        (
          <div style={{fontSize: 22, margin: '10px 20px', height: '70vh', overflowY: 'auto'}} >

            <Collapse
            expandIcon={() => null}
            style={{borderRadius: 10}}
            accordion
          >
          { studentResponse.questions && studentResponse.questions.map((item,index, self) => {
            return (
            <Panel header={
              <Row >
                <Col span={2}>
                  <Avatar size={32} style={{backgroundColor: Color.primary}}>
                    {index + 1}
                  </Avatar>
                </Col>
                <Col span={18} style={{display: 'flex',verticalAlign:'center'}} >
                  {/*<MathJax style={{marginLeft: 20}} math={`${item.question}`} />*/}
                  <div>
                  {ReactHtmlParser(item.question, { transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }
                    if (node.type === 'tag' && node.name === 'img') {
                      return <img style={{maxHeight: '40vh', maxWidth: '40vw'}} src={node.attribs.src} alt=""/>;
                    }
                  }})}
                  </div>
                </Col>
                <div className='r-c-c-c' style={{marginLeft: 10}}>
                  {item.answer || item.filename ?
                  <>
                    {studentReview[index].correct !== null ? studentReview[index].correct === true ? <CheckOutlined style={{fontSize: 16, color: 'green'}}/> :  <CloseOutlined style={{fontSize: 16, color: 'red'}}/>: null}
                    {currentAssignment.gradingType === "Graded" && studentReview[index].score !== null  ? <div style={{border: '1px solid #D9D9D9', width: '3vw', marginTop: 10, borderRadius: 10, padding: 5, textAlign: 'center'}}>{studentReview[index].score || 0}</div>  : null }
                  </>
                  :
                  <div style={{border: '1px solid #D9D9D9', width: '3vw', marginTop: 10, borderRadius: 10, padding: 5, textAlign: 'center'}}>NA</div>}
                </div>
              </Row>
              }
              key={index.toString()}>

              {item.answer || item.filename ? <div>
                <div>
                  {item.type === "Subjective" ?
                  <div className="r-jsb">
                  <div style={{width: '85%', border: '1px solid #D9D9D9', margin: 5, marginBottom: 20, padding: 5, borderRadius: 10, paddingLeft: 10}}>
                    {item.answer}
                  </div>
                  <div style={{width: '10%', border: '1px solid green', margin: 5, marginBottom: 20, padding: 5, borderRadius: 10, paddingLeft: 10}}>
                    {item.correctAnswer}
                  </div>
                  </div>:
                    item.type === "Image" ?
                      <Row>
                        <Col span={20}>
                          {item.filename.map(val => (
                              <img src={`${Api._s3_url}${val}`} 
                              style={{margin: 10, border: '1px solid grey', borderRadius: 3}} height={100} width={100}
                              alt=""
                              onClick={(e) => {e.stopPropagation(); this.setState({imageModal: true, imagePath: `${Api._s3_url}${val}`})}}/>
                          ))}
                        </Col>
                        <Col span={4}>
                          <div style={{width: 'fit-content', height: "fit-content", border: '1px solid green', margin: 5, marginBottom: 20, padding: 5, borderRadius: 10, paddingLeft: 10}}>
                            {item.correctAnswer}
                          </div>
                        </Col>
                      </Row>
                       :
                       <Row>
                      <Col span={20}>
                        <div className='r-ac' style={{border: '1px solid #D9D9D9', margin: 5, marginBottom: 20, padding: 5, borderRadius: 10}}>
                          <Avatar size={32} style={{ fontWeight: 600, marginRight: 5}}>{String.fromCharCode(65 + item.answer.id)}</Avatar>
                          <div style={{flex: 14, display:'flex', overflowY: 'hidden'}}>
                            <div>
                              { ReactHtmlParser(item.answer.value, { transform: (node) => {
                                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                    return <TeX math={`${node.attribs['data-value']}`} />;
                                  }
                                  if (node.type === 'tag' && node.name === 'img') {
                                    return <img style={{maxHeight: '40vh', maxWidth: '60vh'}} src={node.attribs.src} alt="" />;
                                  }
                                }}) }
                            </div>
                          </div>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div style={{flex: 4, width: 'fit-content', height: "fit-content", border: '1px solid green', margin: 5, marginBottom: 20, padding: 5, borderRadius: 10, paddingLeft: 10}}>
                            {String.fromCharCode(65 + item.correctAnswer.id)}
                          </div>
                        </Col>
                      </Row>
                  }
                </div>
                {item.type === "Image"  ? //|| item.type === "Subjective"
                <div>
                  <Radio.Group style={{marginBottom: 10, marginLeft: 5}} onChange={(e) => this.updateReview(e.target.value, 'correct', index)} value={studentReview[index].correct}>
                    <Radio value={false}>Incorrect</Radio>
                    <Radio value={true}>Correct</Radio>
                  </Radio.Group>
                  <div className='r-jsb'>
                    <Input placeholder="Type a comment..."
                    style={{borderRadius: 30, width: '50%'}}
                    disabled={studentReview[index].sent ? true : false}
                    value={studentReview[index].comment ? studentReview[index].comment : ''}
                    onChange={(e) => this.props.updateReviewItem(e.target.value, 'comment', index)}
                    suffix={!studentReview[index].sent ? <MdSend className='cursor-pointer'
                    onClick={() => this.updateReview(true, 'sent', index)}
                    style={{color: Color.primary, fontSize: 24, marginTop: 5}}
                    /> :
                    <CheckOutlined style={{fontSize: 24, color: 'green', marginTop: 5}}/>}
                    />
                    { currentAssignment.gradingType === "Graded" ?
                    <NumericInput
                    key={index.toString()}
                    value={studentReview[index].score}
                    onChange = {(value) => this.changeMarks(value, index, item.marks, studentReview[index].score)}
                    onAdd={(e) => this.onAddSub(e, index, 'add', item.marks)}
                    onSub={(e) => this.onAddSub(e, index, 'sub', item.marks)}
                    max={item.marks}
                    disabled={true}
                    />: null}
                  </div>
                </div> : null}
              </div> : <div>Question Unattempted</div>}
            </Panel>
          )})
          }

      </Collapse>
          </div>
        )
         :
        <Empty style={{marginTop: '20%'}} description="Student has not submitted his assignment yet" />

      }
      </div>
    </Col>
  )}


  onSaveReview(){
    const {studentReview, studentResponse, saveReview} = this.props;

    const params = {
      studentId: parseInt(studentResponse.studentId),
      assignmentId: parseInt(studentResponse.assignmentId),
      totalMarks: studentResponse.totalMarksObtained ? parseInt(studentResponse.totalMarksObtained) : 0,
      assignmentTaskMarks: studentReview,
      index: this.state.selectedStudentIndex
    }
    saveReview(params);
  }

  updateReview(value, key, index){
    const { studentResponse, studentReview } = this.props;
    const oldMarks = studentReview[index].score || 0;
    this.props.updateReviewItem(value, key, index)
    if(key === 'score'){
      // const totalMarksObtained = studentResponse.totalMarksObtained ? studentResponse.totalMarksObtained : 0;
      const newMarks = value ? value : 0;
      const totalMarks = studentResponse.totalMarksObtained - oldMarks + newMarks;
      this.props.addTotalMarks(totalMarks);
      this.setState({selectedStudent: {...this.state.selectedStudent, score: totalMarks}})
    }
  }

  onAddSub(e, index, action, max){
    e.stopPropagation();
    let marks = this.props.studentReview[index].score
    if(action === 'add'){
      marks = marks ? marks + 1 >= max ? max : parseInt(marks) + 1 : 1;
    }else{
      marks = marks ? marks - 1 <= 0 ? 0 : parseInt(marks) - 1 : 0;
    }
    this.updateReview(marks, 'score', index)
  }

  changeMarks(value, index, max,marks){
    if(value >= 0 && value <= max){
      this.updateReview(parseInt(value), 'score', index)
    }else{
      this.updateReview(marks, 'score', index)
    }
  }

  fetchStudentAns(data, index){
      this.setState({
        selectedStudent: data,
        selectedStudentIndex: index
      });
      if(!data.submittedOn) return
      this.props.fetchStudentAnswers(this.props.currentAssignment.id, data.id, 1);
  }

  unselectStudent(){
    this.setState({
      selectedStudent: null,
      selectedStudentIndex: -1
    });
    this.props.setStudentAnswers({});
  }

  componentWillUnmount(){
    this.props.updateSelectedTab("Question");
    this.props.setQuestionFeedBack({})
    window.removeEventListener("beforeunload", this.preventNav);
    this.props.setStudentAnswers({})
    this.props.handleAssignmentInput([],'standardIds');
    this.props.handleAssignmentInput(null,'id');
    this.props.handleAssignmentInput('','title');
    this.props.handleAssignmentInput('','selectedSubject');
    this.props.handleAssignmentInput(null,'subjectId');
    this.props.handleAssignmentInput('','selectedTeacher');
    this.props.handleAssignmentInput(null,'teacherId');
    this.props.handleAssignmentInput('Ungraded','gradingType');
    this.props.handleAssignmentInput(null,'deadlineDate');
    this.props.handleAssignmentInput(null,'resultDueDate');
    this.props.handleAssignmentInput('','instructions');
    this.closeQuestionModal()
  }

  closeQuestionModal(){
    this.props.setCurrentStatement('');
    this.props.removeCurrentQuestion()
    this.props.toggleQuestionModal(false)
    this.props.setCurrentImage(null, null)
  }



  updateComment(e, index){
      // this.state.comments[index] = e.target.value;
      const comments = this.state.comments; // this.state.comments.slice(0)
      comments[index] = e.target.value;
      this.setState({
        comments: [...comments]
      })
  }


  render(){
    const {studentList, currentAssignment, orgId,
    assignmentTaskLoader, history, updateSelectedTab, selectedTab} = this.props;
    const { ToggleQuestionModal, studentFilter, ToggleInstructionModal } = this.state;

    return (
      <div style={{height: '100%'}}>
      <PageHeader
        className="site-page-header"
        onBack={() => this.props.history.goBack()}
        title={`Assignment ${currentAssignment.sub ? `| ${currentAssignment.sub}`: ""}` }
        subTitle={currentAssignment.std && currentAssignment.std.map((role, index, array) => (<>{role} {index === array.length - 1 ? '' : '|'} </>))}
        />
        {assignmentTaskLoader ?
          <div className='r-c-c-c' style={{marginTop: '30%'}}>
            <LoadingOutlined style={{color: '#5843BE', fontSize: 100}} />
          </div> :
        <div style={{height: '95%'}}>
          <Tabs activeKey={selectedTab} onChange={(key) => updateSelectedTab(key)} >
            <TabPane tab={<div>Questions</div>} key="Question" style={{height: '100%'}}>
              <QuestionList history={history} openInstructions={() => this.setState({ToggleInstructionModal: true})}/>
            </TabPane>
            {currentAssignment.status !== 'Draft' ?
            <TabPane tab={<div>Submissions</div>} key="Submission" style={{height: '100%'}}>
              <Row style={{height: '100%'}}>
                <Col style={{height: '100%'}} xs={{span: 24}} sm={{span:24}} md={{span:6}} lg={{span:8}} xl={{span:6}} xxl={{span:6}}>
                  <div style={{height: '70vh', width: '100%', marginTop:-10, border: '1px solid #D9D9D9', overflowY: 'auto'}}>
                    <div className="flex-1 text-center" style={{width: '100%', padding: 5, fontSize: 20, backgroundColor: Color.lightGrey, border: '1px solid #D9D9D9'}}>
                      All Students
                    </div>
                    <div className="flex-1 text-center" style={{width: '100%', padding: 5, fontSize: 18, border: '1px solid #D9D9D9'}}>
                      Filter By
                       <Select
                          className='borderLessSelect bold-600'
                          defaultValue='all'
                          style={{width: '10vw'}}
                          value={this.state.studentFilter}
                          onChange={(val) => this.setState({studentFilter: val})}
                        >
                      <Option value='all'>All</Option>
                       <Option value='Pending'>Pending</Option>
                       <Option value='Submitted'>Submitted</Option>
                       <Option value='Graded'>Evaluated</Option>
                      </Select>
                    </div>
                    <div className="flex-1" style={{height: '100%', width: '100%', padding: 5, fontSize: 20}}>
                      {studentList.filter((item) =>
                        studentFilter && studentFilter !== 'all' ? item.status === studentFilter : item).map((item, i) =>  (
                        <>
                        <Row className='cursor-pointer' onClick={() => this.fetchStudentAns(item, i)} style={{padding: 5, display: 'flex', verticalAlign: 'center',
                        backgroundColor: item.id === (this.state.selectedStudent && this.state.selectedStudent.id) ? Color.midGrey: 'transparent'}}
                        >
                          <Col span={4}>
                            <div className='r-c-c-c'>
                              <Avatar size={36} src={`${Api._s3_url}${item.image}`} style={{zIndex:1}}/>
                              <div  style={{height: 10, width: 10, borderRadius:20, marginTop:-6, zIndex:2,
                                backgroundColor: item.status === 'Draft' || item.status === 'Pending' ? Color.red: item.status === "Graded" ? Color.green : Color.primary}} />
                            </div>
                          </Col>
                          <Col span={17}>
                            <div style={{marginLeft: 5, whiteSpace: 'nowrap',width:'95%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize:18,
                            color: item.id === (this.state.selectedStudent && this.state.selectedStudent.id) ? Color.primary: Color.darkGrey,
                            fontWeight: item.id === (this.state.selectedStudent && this.state.selectedStudent.id) ? 600: 'normal'}}>
                            {item.name}
                            </div>
                            <div style={{marginLeft: 5, fontSize:12}}>
                            {item.status === "Graded" ? "Evaluated" :item.submittedOn ? `Submitted on ${moment(item.submittedOn).format('DD MMM')}` : 'Pending'}
                            </div>
                          </Col>
                          <Col span={3}>
                          <div style={{marginLeft: 5, whiteSpace: 'nowrap',width:'95%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize:18}}>
                          {currentAssignment.gradingType === "Graded" && item.status === "Graded" ? item.score: null}
                          </div>
                          </Col>
                        </Row>
                        </>
                      ))}
                    </div>
                  </div>
                </Col>

                {!this.state.selectedStudent ?
                  this.submissionSummary() :
                  this.studentAnswers()
                }
              </Row>
            </TabPane> : null }
          </Tabs>
        </div>}
        <ImageModal visible={this.state.imageModal} onCancel={() => this.setState({imageModal: false})} path={this.state.imagePath} />
        <QuestionModal view={"assignment-teacher"} visible={ToggleQuestionModal} id={1} />
        <ViewQuestionModal />
        <Prompt
          when={this.state.isEditing}
          message={location =>
            `Are you sure you want to leave this page?`
          }
        />
        <AssignmentUpdateModal history={history} />
        <InstructionModal  modalVisible={ToggleInstructionModal} closeModal={() => this.setState({ToggleInstructionModal: false})}
        instructions={currentAssignment.instructions}/>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {studentList, graded, submitted, totalStudents, currentAssignment, answerLoader, studentResponse, studentReview, saveButton,
    assignmentTaskLoader, resultLoader, reminderLoader, currentAssignmentTasks, selectedTab} = state.assignment
  const {orgId} = state.session.user

  return { studentList, graded, submitted, totalStudents, currentAssignment, answerLoader, studentResponse, orgId, studentReview, saveButton,
    assignmentTaskLoader, resultLoader, reminderLoader, currentAssignmentTasks, selectedTab };
};

const mapDispatchToProps = dispatch => ({
  fetchStudentAnswers: (assignmentId, studentId, page) => dispatch(fetchStudentAnswers(assignmentId, studentId, page)),
  setStudentAnswers: (data) => dispatch(setStudentAnswers(data)),
  updateReviewItem: (value, key, index) => dispatch(updateReviewItem(value, key, index)),
  addTotalMarks: value => dispatch(addTotalMarks(value)),
  saveReview: params => dispatch(saveReview(params)),
  publishResults: () => dispatch(publishResults()),
  sendReminder: () => dispatch(sendReminder()),
  handleAssignmentInput: (val, key) => dispatch(handleAssignmentInput(val, key)),
  setQuestionFeedBack: (params) => dispatch(setQuestionFeedBack(params)),
  getAssignmentById: (id, page, view) => dispatch(getAssignmentById(id, page, view)),
  updateSelectedTab: key => dispatch(updateSelectedTab(key)),
  setCurrentStatement: (value) => dispatch(setCurrentStatement(value)),
  setCurrentImage: (value, image) => dispatch(setCurrentImage(value, image)),
  removeCurrentQuestion: () => dispatch(removeCurrentQuestion()),
  toggleQuestionModal: (state) => dispatch(toggleQuestionModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentSubmissionDetails);
