import { Api, Utils } from "services";
import { handleResendOtpTimer } from "./loginNew";
import moment from "moment";
export const SET_NUMBER_VERIFIED = "SET_NUMBER_VERIFIED";
export const SET_TAB_KEY = 'SET_TAB_KEY';
export const STORE_USER_KYC_STATUS = 'STORE_USER_KYC_STATUS';
export const STORE_WALLET_DASHBOARD_DATA = "STORE_WALLET_DASHBOARD_DATA";
export const STORE_USER_KYC_DATA = 'STORE_USER_KYC_DATA';
export const STORE_PAYMENT_REQ_MODAL_DATA = 'STORE_PAYMENT_REQ_MODAL_DATA';
export const STORE_PAYMENT_HISTORY = "STORE_PAYMENT_HISTORY";
export const STORE_TRANSACTION_MODAL_DATA = "STORE_TRANSACTION_MODAL_DATA";
export const STORE_SUMMARY_MONTH = "STORE_SUMMARY_MONTH";
export const STORE_PAYMENT_HISTORY_PARAMS = "STORE_PAYMENT_HISTORY_PARAMS";
export const STORE_PAYMENT_REQUESTS_PARAMS = "STORE_PAYMENT_REQUESTS_PARAMS";
export const SET_FROM_PAYMENT_HISTORY = "SET_FROM_PAYMENT_HISTORY";
export const STORE_WALLET_FEE_OVERVIEW_DATA = "STORE_WALLET_FEE_OVERVIEW_DATA";
export const SET_SINGLE_BAR_DATA = "SET_SINGLE_BAR_DATA";
export const SET_SUBMIT_CLICKED = "SET_SUBMIT_CLICKED"

export const GenerateKycOtp = (phoneNumber, callBack = null) =>
    (dispatch, getState) => {
        Utils.stopDuration();
        return Api.post('/fee/generateKycOtp')
            .params({ phone: phoneNumber })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    if (response?.show?.type === 'success') {
                        dispatch(handleResendOtpTimer(true, 30));
                        Utils.startDuation(30, (callback) => {
                            if (callback === 0) {
                                dispatch(handleResendOtpTimer(false, ''));
                            } else {
                                dispatch(handleResendOtpTimer(true, callback))
                            }
                        })
                    }
                }
            });
    };

export const VerifyKycOtp = (phoneNumber, otp, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        return Api.post('/fee/verifyKycOtp')
            .params({
                phone: phoneNumber,
                otp,
            })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        dispatch(setNumberVerified(true))
                        successCallBack();
                    }
                    Utils.stopDuration();
                }
            });
    };
export const UploadDocument = (file, successCallback) => {
    return (dispatch) => {
        const params = {
            file
        }
        return Api.post(`/fee/upload-kyc-doc`).upload(params, (percent, response) => {
            if (response !== undefined) {
                if (successCallback) {
                    console.log("userKycDetails1", response?.data?.key)
                    successCallback(response?.data?.key)
                }
            }
        });

    }
}
export const deleteKycDocument = (key) => {
    return dispatch => {
        const params = {
            key
        };
        return Api.delete("/fee/delete-kyc-doc")
            .params(params)
            .send((response, error) => {
                if (typeof response != "undefined") {
                    if (!response.length) {
                    } else {
                    }
                }
            });
    };
};
export const postUpdateUserKyc = (userKycDetails, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { submitClicked } = getState().aulasWallet
        return Api.post('/fee/update-user-kyc')
            .params({...userKycDetails, submit:submitClicked})
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };
export const getUserKycStatus = () => (dispatch, getState) => {
    return Api.get('/fee/user-kyc-status')
        .send((response, error) => {
            if (typeof response !== 'undefined') {
                dispatch(storeUserKycStatus(response));
            }
        })
};
export const getWalletDashBoardData = (callback = null) => {
    return (dispatch, getState) => {
        return Api.get(`/fee/wallet`)
            .params()
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined) {
                    dispatch(storeWalletDashbardData(response));
                }
            });
    };
};

export const getWalletFeeOverViewData = (skipGraph, callback) => {
    return (dispatch, getState) => {
        const {
            summaryTypeContainer,
            summaryYearContainer,
            summaryStartDateContainer,
            summaryEndDateContainer,
        } = getState().attendanceNew;
        const { summaryMonth, singleBarData, feeOverviewData } = getState().aulasWallet;
        const summaryGraph = feeOverviewData?.summaryGraph ? [...feeOverviewData?.summaryGraph] : [];
        const { user } = getState().session;
        const params = {
            period: summaryTypeContainer,
            year: summaryYearContainer,
            startDate: summaryStartDateContainer != null ? moment(summaryStartDateContainer, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: summaryEndDateContainer != null ? moment(summaryEndDateContainer, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            month: summaryMonth,
            userId: user?.id
        }
        return Api.get(`/fee/transaction-summary`)
            .params(skipGraph ? singleBarData : params)
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined) {
                    if (skipGraph) {
                        delete response?.summaryGraph
                        dispatch(storeWalletFeeOverviewData({ ...response, summaryGraph }));
                    } else {
                        dispatch(storeWalletFeeOverviewData(response));
                    }
                }
            });
    };
};
export const storeWalletFeeOverviewData = (val) => ({
    type: STORE_WALLET_FEE_OVERVIEW_DATA,
    val
})
export const getAllPaymentHistory = (callback = null) => {
    return (dispatch, getState) => {
        const { paymentHistoryObj, paymentRequestsObj, isFromPaymentHistory } = getState().aulasWallet
        const params = {
            status: "Paid",
            page: paymentHistoryObj?.page,
            arrayResponse: true,
            paymentStartDate: paymentHistoryObj?.paymentStartDate ? moment(paymentHistoryObj?.paymentStartDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            paymentEndDate: paymentHistoryObj?.paymentEndDate ? moment(paymentHistoryObj?.paymentEndDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            paymentMode: paymentHistoryObj?.paymentMode,
            paymentStatus: paymentHistoryObj?.paymentStatus,
            limit: paymentHistoryObj?.limit,
            monthYear: paymentHistoryObj?.monthYear
        };
        const params1 = {
            status: "Link Sent",
            page: paymentRequestsObj?.page,
            arrayResponse: true,
            issueDate: paymentRequestsObj?.issueDate ? moment(paymentRequestsObj?.issueDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            dueDate: paymentRequestsObj?.dueDate ? moment(paymentRequestsObj?.dueDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            overdueStatus: paymentRequestsObj?.overdueStatus,
            limit: paymentRequestsObj?.limit
        }
        return Api.get("/fee/filterwallet")
            .params(isFromPaymentHistory ? params : params1)
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined) {
                    dispatch(storePaymentHistory(response));
                }
            });
    };
};
export const getUserKycDetails = (callBack = null) => (dispatch, getState) => {
    return Api.get('/fee/get-user-kyc')
        .send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response !== 'undefined' && response?.show?.type !== 'error') {
                dispatch(storeUserKYcData(response));
            } else {
                console.log("response12")
            }
        })
};
export const updateEmailInfo = (email, callback = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const params = { newEmail: email }
        return Api.patch("/user/update-contact-info").params(params).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != "error") {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };
};

export const storePaymentHistory = (val) => ({
    type: STORE_PAYMENT_HISTORY,
    val
})

export const storeUserKYcData = (val) => ({
    type: STORE_USER_KYC_DATA,
    val
})

export const storeWalletDashbardData = (val) => ({
    type: STORE_WALLET_DASHBOARD_DATA,
    val
})

export const storeUserKycStatus = (val) => ({
    type: STORE_USER_KYC_STATUS,
    val
})
export const setNumberVerified = (bool) => ({
    type: SET_NUMBER_VERIFIED,
    bool
})

export const setTabKey = (val) => ({
    type: SET_TAB_KEY,
    val
})

export const storePaymentReqModalData = (val) => ({
    type: STORE_PAYMENT_REQ_MODAL_DATA,
    val
})
export const storeTransactionModalData = (val) => ({
    type: STORE_TRANSACTION_MODAL_DATA,
    val
})
export const storeSummaryMonth = (val) => ({
    type: STORE_SUMMARY_MONTH,
    val
})
export const storePaymentHistoryParams = (key, val) => ({
    type: STORE_PAYMENT_HISTORY_PARAMS,
    key,
    val
})
export const storePaymentRequestsParams = (key, val) => ({
    type: STORE_PAYMENT_REQUESTS_PARAMS,
    key,
    val
})
export const setFromPaymentHistory = (val) => ({
    type: SET_FROM_PAYMENT_HISTORY,
    val
})
export const setSingleBarData = (val) => ({
    type: SET_SINGLE_BAR_DATA,
    val
})
export const setSubmitClicked = (val) => ({
    type: SET_SUBMIT_CLICKED,
    val
})