import React, { useEffect, useState } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { newCourseCreationCardStyle } from './courseCreationPage'
import { Switch } from 'antd';
import { connect } from 'react-redux';
import { setCreateCourseObj } from 'actions/courseManagement';
import AnimatedTextField from 'components/AnimatedTextField';
import AnimatedCustomSelect from 'components/AnimatedSelectComp';
import { getExaminer } from 'actions/testAdmin';

function CourseSettingsComp({ isExpanded, setIsExpanded, setCreateCourseObj, courseCreateObj, getExaminer, getExaminerArr, courseTypeName, location, clearValues, expandedSection }) {
    const [searchText, setSearchText] = useState('');
    const [searchTextIndex, setSearchTextIndex] = useState('');
    const [handleRender, updateHandleRender] = useState(false);
    const [settingsInfoFilledCount, setSettingsInfoFilledCount] = useState(0)
    const [selectedTeachers, setSelectedTeachers] = useState(courseCreateObj?.vrTeacherIds || []);

  

    useEffect(() => {
        setSelectedTeachers(courseCreateObj?.vrTeacherIds || [])
    }, [courseCreateObj?.vrTeacherIds])

    useEffect(() => {
        getExaminer('');
        if (location?.state?.key?.isEdit == "isFromEdit") {
            setSelectedTeachers(courseCreateObj?.teacherIds)
            
        }else{
            setSelectedTeachers([])
            setCreateCourseObj('vrTeacherIds', []);
        }
    }, [])

    let timer = false;

    useEffect(() => {
        if (handleRender) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (searchTextIndex === 'examiner') {
                    getExaminer(searchText)
                    updateHandleRender(false);
                }
                else {
                    updateHandleRender(false);
                }
            }, 800);
        }
    }, [searchText, searchTextIndex, handleRender])

    const courseTypeObjects = {
        liveCourses: {
            premium: courseCreateObj?.premium,
            recordLive: courseCreateObj?.recordLive,
        },
        videoResources: {
            premium: courseCreateObj?.premium,
            enableQA: courseCreateObj?.enableQA,
        },
        onlineTestSeries: {
            premium: courseCreateObj?.premium,
        },
        studyMaterials: {
            premium: courseCreateObj?.premium,
        }
    };

    useEffect(() => {
        let count = 0;
        const currentCourseObj = courseTypeObjects[courseTypeName];
        for (const item in currentCourseObj) {
            if (currentCourseObj[item] == true) {
                count++;
            }
        }
        setSettingsInfoFilledCount(count)
    }, [courseTypeName, isExpanded, courseCreateObj]);

    useEffect(() => {
        return (() => {
            if (location?.state?.key?.isEdit != 'isFromEdit') {
                clearValues()
            }
        })
    }, [])

    const collapsedDataMap = {
        liveCourses: [
            { text: !courseCreateObj?.premium ? "Promo codes are not applicable for this course" : "Promo codes are applicable for this course" },
            { text: !courseCreateObj?.recordLive ? "This live course will not be recorded as a video course" : "This live course will be recorded as a video course" }
        ],
        videoResources: [
            { text: !courseCreateObj?.premium ? "Promo codes are not applicable for this course" : "Promo codes are applicable for this course" },
            { text: !courseCreateObj?.enableQA ? "Disabled Q&A section for this course" : "Enabled Q&A section for this course" }
        ],
        studyMaterials: [
            { text: !courseCreateObj?.premium ? "Promo codes are not applicable for this course" : "Promo codes are applicable for this course" }
        ],
        onlineTestSeries: [
            { text: !courseCreateObj?.premium ? "Promo codes are not applicable for this course" : "Promo codes are applicable for this course" }
        ]
    };


    const handleRemoveTeacher = (teacherId) => {
        const updatedTeachers = selectedTeachers.filter(teacher => teacher.id != teacherId);
        setSelectedTeachers(updatedTeachers);
        setCreateCourseObj('vrTeacherIds', updatedTeachers);
    };

    const handleSelectChange = (text, value) => {
        console.log('selectedTeachers1', value);

        if (value) {
            const newTeacher = { name: value.children, id: value.key };
            if (!selectedTeachers.some(teacher => teacher.id == newTeacher.id)) {
                const updatedTeachers = [...selectedTeachers, newTeacher];
                setSelectedTeachers(updatedTeachers);
                setCreateCourseObj('vrTeacherIds', updatedTeachers);
            }
        } else {
            getExaminer('');
        }
    };
    return (
        <div className='m-t-20' style={newCourseCreationCardStyle}>
            <div className='r-c-sb'>
                <div style={{ color: "#696969", fontSize: 18, fontWeight: 700 }}>Course settings</div>
                <div className='r-ac'>
                    {!isExpanded && settingsInfoFilledCount > 0 ? <div className='m-r-10' style={{ color: "#696969" }}>{settingsInfoFilledCount} {settingsInfoFilledCount == 1 ? "field" : "fields"}</div> : <></>}
                    {isExpanded ?
                        <FiChevronDown style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} /> :
                        <FiChevronRight style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} />
                    }
                </div>
            </div>
            {!expandedSection ?
                <>
                    {(location?.state?.key?.isEdit == "isFromEdit" && !isExpanded) || (location?.state?.key?.isEdit != "isFromEdit" && (courseCreateObj?.premium || courseCreateObj?.recordLive || courseCreateObj?.enableQA)) ?
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                            {collapsedDataMap[courseTypeName]?.map((item, index) => (
                                <div className='r-c-fs' style={{ alignItems: "baseline", width: "100%" }}>
                                    <span className='list-dot' style={{ height: 5, width: 5 }}></span>
                                    <div key={index} className='pre-list' style={{ flexBasis: "100%", color: "#191919" }}>{item?.text}</div>
                                </div>
                            ))}
                        </div>
                        : <></>}
                </> : <></>}
            {isExpanded ?
                <>
                    <div className='m-t-20'>
                        <div style={{ color: "#191919", fontSize: 16 }}>Promotional codes not applicable</div>
                        <div className='r-c-sb m-t-10' style={{ width: "100%" }}>
                            <div style={{ width: "90%", color: "#636363", fontSize: 13 }}> When promotional codes not applicable is turned on, the application of promotional codes to this particular course becomes invalid and making this course exempt from promotional code discounts.</div>
                            <div>
                                <Switch style={{ color: '#1089FF' }} size="small" checked={courseCreateObj?.premium} onChange={(checked) => { setCreateCourseObj("premium", checked); }} />
                            </div>
                        </div>
                    </div>
                    {courseTypeName == "liveCourses" ?
                        <>
                            <div className='m-t-20'>
                                <div style={{ color: "#191919", fontSize: 16 }}>Record this live course</div>
                                <div className='r-c-sb m-t-10' style={{ width: "100%" }}>
                                    <div style={{ width: "90%", color: "#636363", fontSize: 13 }}>You can record this live course conducted online for future use. The recording can then be transformed into a new video resource course. All recorded files will be stored in broadcast recordings. A video resource course will be created automatically in the video resources section. Don't worry; we won't publish it until you choose to do so.</div>
                                    <div>
                                        <Switch style={{ color: '#1089FF' }} size="small" checked={courseCreateObj?.recordLive} onChange={(checked) => { setCreateCourseObj("recordLive", checked); }} />
                                    </div>
                                </div>
                            </div>
                            {courseCreateObj?.recordLive ?
                                <>
                                    <AnimatedTextField
                                        label={"Enter video course name"}
                                        isMandatory={true}
                                        inputId={"-1000"}
                                        value={courseCreateObj?.recordVideoResourceName ? courseCreateObj?.recordVideoResourceName : ''}
                                        handleInputChange={(e) => { setCreateCourseObj("recordVideoResourceName", e.target.value) }}
                                        style={{ marginTop: 30, marginBottom: 20 }}
                                    />
                                    {/* <AnimatedCustomSelect
                                        label={"Select teacher"}
                                        required={true}
                                        options={getExaminerArr}
                                        value={courseCreateObj?.vrTeacherId?.name}
                                        isSelected={courseCreateObj?.vrTeacherId?.name ? true : false}
                                        handleSelectChange={(text, value) => { value ? setCreateCourseObj('vrTeacherId', { name: value.children, id: value.key }) : getExaminer('') }}
                                        handleSelectOnSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true) }}
                                        style={{ marginBottom: 0 }}

                                    /> */}

                                    <div style={{ width: "100%", marginTop: 20 }}>

                                        <AnimatedCustomSelect
                                            label={"Select teacher"}
                                            required={true}
                                            options={getExaminerArr}
                                            value={courseCreateObj?.vrTeacherIds?.length > 0 ? courseCreateObj?.vrTeacherIds[courseCreateObj?.vrTeacherIds?.length - 1].name : selectedTeachers?.length ? selectedTeachers[selectedTeachers?.length - 1]?.name : ''}
                                            isSelected={selectedTeachers?.length > 0}
                                            handleSelectChange={handleSelectChange}
                                            handleSelectOnSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true); }}
                                            style={{ marginBottom: 0 }}
                                        />



                                        <div style={{ marginTop: "10px" }}>
                                            {selectedTeachers?.map((teacher, index) => (

                                                <div key={teacher?.id} style={{ display: "inline-block", margin: "5px" }}>

                                                    <div style={{ padding: "2px 12px", borderRadius: "20px", border: "1px solid #D7D7D7", display: "flex", alignItems: "center", color: '#636363' }}>
                                                        <span>{teacher?.name}</span>
                                                        <span
                                                            style={{ marginLeft: "10px", cursor: "pointer", color: "#636363" }}
                                                            onClick={() => handleRemoveTeacher(teacher?.id)}
                                                        >
                                                            &#x2716;
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                                : null}
                        </>
                        :
                        <>
                            {courseTypeName == "videoResources" ?
                                <div className='r-c-sb m-t-20' style={{ width: "100%" }}>
                                    <div style={{ width: "90%", ccolor: "#191919", fontSize: 16 }}>Enable Q&A section for this course.</div>
                                    <div>
                                        <Switch style={{ color: '#1089FF' }} size="small" checked={courseCreateObj?.enableQA} onChange={(checked) => { setCreateCourseObj("enableQA", checked); }} />
                                    </div>
                                </div>
                                : <></>}
                        </>}
                </>
                : <></>}
        </div>
    )
}
const mapStateToProps = (state) => {
    const { courseCreateObj, courseTypeName } = state.courseManagement
    const { getExaminerArr, getExaminerBool } = state.testAdmin
    return {
        courseCreateObj, getExaminerArr, courseTypeName
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    getExaminer: (text) => dispatch(getExaminer(text)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CourseSettingsComp);