import React from 'react';
import {connect} from 'react-redux';
import AutoComplete from 'antd/lib/auto-complete';
import DatePicker from 'antd/lib/date-picker';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import TimePicker from 'antd/lib/time-picker';
import Tag from 'antd/lib/tag';
import 'antd/lib/auto-complete/style/index';
import 'antd/lib/date-picker/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/input/style/index';
import 'antd/lib/input-number/style/index';
import 'antd/lib/tag/style/index';
import 'antd/lib/time-picker/style/index';
import Color from '../../../services/Color'
import '../styles.css';
import moment from 'moment';
import {
  toggleTestModal, handleTestInput, createNewTest, fetchStandards, resetTestParams, searchChapter,
  updateSearchedChapters
} from '../../../actions/test_old';
import { searchUser } from '../../../actions/calendar';
import {searchSubject} from '../../../actions/classRoom';

const { Option } = Select;
const format = "HH:mm"


function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().add(-1, 'days').endOf('day');
}




class TestUpdateModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      chapterQuery: "",
      subjectQuery: ""

    }
  }

  disabledResultDate = (current) => {
    const {testParams} = this.props;
    if(testParams.testDate){
      return current < moment(testParams.testDate, "YYYY-MM-DD").add(-1, 'days').endOf('day');
    }else{
      return current < moment().add(-1, 'days').endOf('day');
    }
  }

  handleAddClassToAssignment(std) {
    this.props.handleTestInput(std, 'standardIds');
  }


  handleTeacherSelect(teacher, obj){
    if(!obj || obj.id === -1) {
      return console.log('return');
    };
    this.props.handleTestInput(obj.id,'teacherId');
    this.props.handleTestInput(obj.id,'selectedTeacher');
    this.props.handleTestInput(teacher,'teacherName');
  }

  handleChapterSelect(chapter, obj){
    if(!obj || obj.id === -1) {
      return console.log('return');
    };
    const {chapterIds, chapterNames} = this.props.testParams;
    if(!chapterNames.includes(chapter)){
      chapterNames.push(chapter);
      chapterIds.push(obj.id)
      // this.props.handleTestInput(chapterNames,'chapterNames');
      // this.props.handleTestInput(chapterIds,'chapterIds');
    }
    this.setState({chapterQuery: ""})
  }

  removeChapterFormArray(e, index){
    e.preventDefault();
    const {chapterIds, chapterNames} = this.props.testParams;
    chapterNames.splice(index,1);
    chapterIds.splice(index,1);
    this.props.handleTestInput([...chapterNames],'chapterNames');
    this.props.handleTestInput([...chapterIds],'chapterIds');
  }

  removeSubjectFormArray(e, index){
    e.preventDefault();
    const {subjectIds, subjectNames} = this.props.testParams;
    subjectIds.splice(index,1);
    subjectNames.splice(index,1);
    this.props.handleTestInput([...subjectNames],'subjectNames');
    this.props.handleTestInput([...subjectIds],'subjectIds');
    this.props.handleTestInput([], 'standardIds');
    this.props.handleTestInput([], 'chapterNames');
    this.props.handleTestInput([], 'chapterIds');
    this.props.handleTestInput([], 'standardIds');
    this.props.updateSearchedChapters([]);
  }

  handleDateChange = (date, type) => {
    const dateParam = date ? moment(date._d).format('YYYY-MM-DD') : null;
    if (type === 'submit') {
      this.props.handleTestInput(dateParam,'testDate');
      this.props.handleTestInput(null,'resultDate');
    }
    if (type === 'result') {
      this.props.handleTestInput(dateParam,'resultDate');
    }
  }

  handleSubjectSelect(subject, obj) {
    if(!obj || obj.id === -1) {
      return console.log('return');
    }
    if(!obj || obj.id === -1) {
      return console.log('return');
    };
    const {subjectIds, subjectNames} = this.props.testParams;
    if(!subjectNames.includes(subject)){
      subjectNames.push(subject);
      subjectIds.push(obj.id)
    }
    // this.props.handleTestInput([],'standardIds');
    // this.props.handleTestInput(subject,'selectedSubject');
    // this.props.handleTestInput(obj.id,'subjectId');
    this.props.fetchStandards(subjectIds);
    this.setState({subjectQuery: ""})
  }

  async handleSubjectSearch (query){
    const response = await this.props.searchSubject(query);
    this.props.handleTestInput(-1,'subjectId');
    return response;
  }

  // componentWillUnmount(){
  //   console.log("Unmounted");
  //   this.props.resetTestParams();
  // }

  closeModal(){
    this.props.toggleTestModal(false);
    this.props.resetTestParams()
    this.props.updateSearchedChapters([])
    // this.props.handleTestInput([],'standardIds');
    // this.props.handleTestInput(-1,'id');
    // this.props.handleTestInput("",'title');
    // this.props.handleTestInput("",'selectedSubject');
    // this.props.handleTestInput(-1,'subjectId');
    // this.props.handleTestInput("",'selectedTeacher');
    // this.props.handleTestInput(-1,'teacherId');
    // this.props.handleTestInput("Ungraded",'gradingType');
    // this.props.handleTestInput(null,'deadlineDate');
    // this.props.handleTestInput(null,'resultDueDate');
    // this.props.handleTestInput("",'instructions');
    // this.props.handleTestInput([],'chapterNames');
    // this.props.handleTestInput([],'chapterIds');
    // this.props.handleTestInput(null,'index');
    
  }

  gradeMenu = () => (
    <Menu className='assignmentddlmenu' >
      <Menu.Item key="0">
        <div style={{color: Color.black}} onClick={() => this.props.handleTestInput('Graded', 'gradingType')}>
          Graded
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div style={{color: Color.black}}  onClick={() => this.props.handleTestInput('Ungraded', 'gradingType')}>
          Ungraded
        </div>
      </Menu.Item>
    </Menu>
  );

  async saveClick(){
    const {user} = this.props;
    const params = this.props.testParams;
    // params.author = {name: this.props.testParams.selectedTeacher}
    if(!params.teacherId && (user.role.includes("Teacher") && !(user.role.includes("Admin") || user.role.includes("SuperAdmin")))){
      console.log("Enters here");
      params.selectedTeacher = user.id;
      params.teacherId = user.id;
      this.props.handleTestInput(user.name, 'teacherName');
    }
    params.sub = this.props.testParams.selectedSubject;
    let nextPage = null;
    if(!params.id){
      nextPage = user.access.includes('assignment-teacher') ? 'assignment-teacher' :
                      user.access.includes('assignment-logger') ? 'assignment-logger' : null
    }
    this.props.updateSearchedChapters([])

    await this.props.createNewTest(params, this.props.history, nextPage);
  }


  render(){
      const { testModal, user, testParams, testLoader, teachers, searchLoader, subjectsFound,
        togglePostLoader, searchedChaptersList, userSearchLoader, standardsList
       } = this.props;
      return (
        <Modal
          visible={testModal}
          onCancel={() => this.closeModal()}
          maskClosable={false}
          title={
            <div className='color-purple text-xmd'>
              Test Details
            </div>
          }
          footer={[
            <div className = 'r-c-c'>
              <Button
                loading={false}
                style={{backgroundColor: Color.midGrey, color: Color.black, borderRadius: 10}}
                onClick={() => this.closeModal()}
              >
                Discard
              </Button>
              <Button
                type='primary'
                loading={testLoader}
                style={{borderRadius: 10}}
                onClick={() => this.saveClick()}
                >
                {testParams.id ? 'Update Details':'Save & Next'}
              </Button>


            </div>,
          ]}
        >
        <div className='m-t-10 flex-1'>
          <div className='dark-grey m-b-5'>Title</div>
          <input
            value={testParams.title}
            onChange={(e) => this.props.handleTestInput(e.target.value, 'title')}
            type='text'
            style={{width: '100%', marginBottom: 20, color: Color.black}}
            className='flex-1 assignmentDropdown text-xmd border-0 p-l-10'
          />
        </div>
        <div className='r-jsb'>
          <div>
            <div className='dark-grey m-b-5'>Subject(s)</div>
            <div
              className='r-c-sb'
              style={{backgroundColor:'#F9F9F9', padding: 5, borderRadius:5, alignItems: 'flex-start'}}
              >
              <AutoComplete
                // onChange={(value) =>
                //   this.props.handleTestInput(value, 'selectedSubject')
                // }
                onChange={val => this.setState({subjectQuery: val})}
                value={this.state.subjectQuery}
                options={subjectsFound}
                style={{
                  border:'none',
                  color:Color.primary,
                  fontSize:16,
                  fontWeight:600,
                  backgroundColor: '#EEEEEE',
                }}
                disabled={testParams && testParams.cloneTestId}
                onSelect={(value, obj) => this.handleSubjectSelect(value, obj)}
                onSearch={(query) => this.handleSubjectSearch(query)}
                >
                <Input.Search
                  style={{ border:'none', borderRadius:5, height: 32}}
                  placeholder="Search subject..."
                  loading={searchLoader}
                  allowClear
                />
              </AutoComplete>
            </div>
            <div> {testParams.subjectNames && testParams.subjectNames.map((chapter, chapIndex) => 
            <Tag key={chapIndex.toString()} style={{borderRadius: 10, margin: 2, marginTop: 5}} onClose={(e) => this.removeSubjectFormArray(e,chapIndex)} 
            closable={testParams && !testParams.cloneTestId}>{chapter}</Tag> 
            )}    </div>
          </div>
          {!(user.role.includes('Teacher') && !( user.role.includes('SuperAdmin') || user.role.includes('Admin') || user.role.includes('DataLogger')))
          &&
          <div>
            <div className='dark-grey m-b-5'>Examiner</div>
            <div
              className='r-c-sb'
              style={{
                backgroundColor:'#F9F9F9',
                padding:5,
                borderRadius:5
              }}
            >
              <AutoComplete
                options={teachers}
                style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                onSelect={(value, obj) => this.handleTeacherSelect(value, obj)}
                onSearch={(query) => this.props.searchUser(query)}
                value={testParams ? testParams.teacherName : undefined}
                onChange={(value) => this.props.handleTestInput(value,'teacherName')}
                >
                <Input.Search 
                  style={{ border:'none', borderRadius:5, height: 32}}  
                  placeholder="Search name..."  
                  allowClear
                  loading={userSearchLoader} 
                />
              </AutoComplete>
            </div>
          </div>}
        </div>
        
          <div className='flex-1'>
            <div className='dark-grey m-t-5 m-b-5'>Class</div>
            <div
              className='r-c-sb flex-1'
              style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}
              >
              <Select
                className='borderLessSelect bold-600'
                // disabled={testParams && testParams.subjectId && testParams.subjectId != -1}
                value={testParams ? testParams.standardIds[0] : undefined}
                style={{ flex: 1}}
                onChange={(value) => this.handleAddClassToAssignment([value])}
                disabled={!testParams || !testParams.subjectIds || !testParams.subjectIds.length || !standardsList}
                loading={searchLoader}
                >

                {standardsList && standardsList.map((item) =>
                  <Option value={item.id}>
                    {item.std}-{item.section}
                  </Option>
                )}
              </Select>
            </div>
          </div> 
        <div className='r-jsb m-t-10'>
          <div className='flex-1'>
            <div className='dark-grey m-b-5'>Test Date</div>
            <DatePicker
              size='default'
              style={{
                display: 'block',
                marginRight: 10,
                fontSize: '1em',
                borderRadius: '10px',
                backgroundColor: '#EEEEEE',
                color: '#5448B7',
              }}
              onChange={(date) => this.handleDateChange(date, 'submit')}
              format="DD-MM-YYYY"
              value={testParams.testDate ? moment(testParams.testDate): null}
              disabledDate={disabledDate}
            />
          </div>
          <div className='flex-1'>
          <div className='dark-grey m-b-5'>Result Date</div>
            <DatePicker
              size='default'
              style={{
                display: 'block',
                fontSize: '1em',
                borderRadius: '10px',
                backgroundColor: '#EEEEEE',
                color: '#5448B7',
              }}
              value={testParams.resultDate ? moment(testParams.resultDate): null}
              onChange={(date) => this.handleDateChange(date, 'result')}
              format="DD-MM-YYYY"
              disabledDate={current => this.disabledResultDate(current)}
            />
          </div>
        </div>
        <div className='r-jsb m-t-10'>
          <div className='flex-1'>
            <div className='dark-grey m-b-5'>Start Time</div>
            <TimePicker
              size='default'
              style={{
                display: 'block',
                fontSize: '1em',
                marginRight: 10,
                borderRadius: '10px',
                backgroundColor: '#EEEEEE',
                color: '#5448B7',
              }}
             value={testParams.startTime ? moment(testParams.startTime, format) : null} 
             format={format} 
             onChange={(val) => this.props.handleTestInput(val ? moment(val).format('HH:mm:ss') : null, 'startTime')}
             />
             
          </div>
          <div className='flex-1'>
          <div className='dark-grey m-b-5'>End Time</div>
            <TimePicker 
            size='default'
            style={{
              display: 'block',
              fontSize: '1em',
              borderRadius: '10px',
              backgroundColor: '#EEEEEE',
              color: '#5448B7',
            }}
            value={testParams.endTime ? moment(testParams.endTime, format) : null}
            format={format} 
            onChange={(val) => this.props.handleTestInput(val ? moment(val).format('HH:mm:ss') : null, 'endTime')}
            />
          </div>
        </div>
        <div className="r-jsb m-t-10 display-flex">
          <div className="flex-2">
            <div className='dark-grey m-b-5'>Chapter(s)</div>
            <div
              className='r-c-sb m-r-5'
              style={{
                backgroundColor:'#F9F9F9',
                padding:5,
                borderRadius:5
              }}
            >
              <AutoComplete
                options={searchedChaptersList}
                style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                onSelect={(value, obj) => this.handleChapterSelect(value, obj)}
                onSearch={(query) => this.props.searchChapter(query)}
                value={this.state.chapterQuery}
                onChange={(value) => this.setState({chapterQuery: value})}
                disabled={!testParams || !testParams.subjectIds || !testParams.subjectIds.length || !testParams.standardIds || !testParams.standardIds.length }
                >
                <Input.Search 
                  style={{ border:'none', borderRadius:5, height: 32}} 
                  placeholder="Search chapter..."  loading={togglePostLoader} allowClear
                 />
              </AutoComplete>
            </div>
            <div> {testParams.chapterNames && testParams.chapterNames.map((chapter, chapIndex) => 
            <Tag key={chapIndex.toString()} style={{borderRadius: 10, margin: 2, marginTop: 5}} onClose={(e) => this.removeChapterFormArray(e,chapIndex)} closable>{chapter}</Tag> 
            )}    </div> 
          </div>
          <div className="flex-1">
            <div className='dark-grey m-b-5'>Index</div>
            <div
              style={{
                backgroundColor:'#F9F9F9',
                padding:5,
                borderRadius:5
              }}
            >
              <InputNumber 
              value={testParams.index}
              onChange={(val) => this.props.handleTestInput(val, 'index')}//
              min={1}
              type='number'
              style={{border: 'none', width: "100%"}}
              disabled={!testParams || !testParams.subjectIds || !testParams.subjectIds.length || !testParams.standardIds || !testParams.standardIds.length }
              />
            </div>
          </div>
        </div>
        <div className='m-t-10 flex-1'>
        <div className='dark-grey m-b-5'>Instructions</div>
          <textarea
            value={testParams.instructions}
            onChange={(e) => this.props.handleTestInput(e.target.value, 'instructions')}
            type='text'
            style={{width: '100%', color: Color.black}}
            className='flex-1 assignmentDropdown text-xmd border-0 p-l-10'
          />
        </div>
      </Modal>
      )
  }
}

const mapStateToProps = state => {
  const {testModal, testParams, testLoader, standardsList, searchedChaptersList} = state.test;
  const { teachers, subClasses, userSearchLoader } = state.calendar;
  const { searchLoader } = state.classRoom;
  const {user} = state.session;
  const { subjectsFound } = state.classRoom;
  return {
    testModal, teachers, subClasses, searchLoader, user, subjectsFound, testParams, testLoader, searchedChaptersList, userSearchLoader,
    standardsList
  }
};

const mapDispatchToProps = dispatch => ({
  searchUser: (query) => dispatch(searchUser(query)),
  toggleTestModal: (state) => dispatch(toggleTestModal(state)),
  handleTestInput: (val, key) => dispatch(handleTestInput(val, key)),
  fetchStandards: (subIds) => dispatch(fetchStandards(subIds)),
  searchSubject: (query) => dispatch(searchSubject(query)),
  createNewTest: (params, history, nextPage) => dispatch(createNewTest(params, history, nextPage)),
  // toggleTestModal: (state) => dispatch(toggleTestModal(state)),
  searchChapter: (query) => dispatch(searchChapter(query)),
  resetTestParams: () => dispatch(resetTestParams()),
  updateSearchedChapters: chapters => dispatch(updateSearchedChapters(chapters))
});

export default connect(mapStateToProps, mapDispatchToProps)(TestUpdateModal);
