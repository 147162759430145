import React, { useRef, useState, useEffect } from 'react';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { Radio, Select } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import Loader from 'pages/Test/localComponent/loader';
import { Utils } from 'services';


function RecordingModal ({visible, onCancel, onConfirm, title, content, confirmTitle="YES", rejectTitle="CANCEL", onClose=null, loading=false, fullmodalloading=false
, getVideoResourceSections, selectedSchedule,
sectionList, selectedSection, setSelectedSection, isRecording, recordCount, localRecordingCount}) {
    const [value, setValue] = useState(true);
    const [input, setInput] = useState('');
    const [topicName, setTopicName] = useState(null);
    const [filteredSelectionList, setFilteredSelectionList] = useState([]);
    const hasInputField = isRecording===0 && (value?localRecordingCount===0:recordCount===0) 
    const classRef = useRef(null);
    useEffect(()=>{
    console.log("801xx", selectedSchedule)
    setSelectedSection({})
    if (selectedSchedule.videoResourceIds?.length){
        getVideoResourceSections(selectedSchedule.videoResourceIds, ()=>{})
    }
    }, []);
    useEffect(()=>{
      setFilteredSelectionList(sectionList)
    }, [sectionList])
    const handleSearchTopic = (value) => {
      if (value && value.trim() != '') {
        setFilteredSelectionList(sectionList?.filter((item) => item.title.toLowerCase().includes(value.toLowerCase())))
        setTopicName(value)
      }
      else {
        setTopicName(null);
        setFilteredSelectionList(sectionList)
      }
    }
    const onChange = (e) => {
      setValue(e.target.value);
    };
    return (
      <Modal
        centered
        className='modal-round-corner'
        style={{padding:0}}
        visible={visible}
        onCancel={onClose?onClose:onCancel}
        onOk={onClose?onClose:onCancel}
        footer={null}
      >
        {
          fullmodalloading?
          <div style={{paddingTop:50, paddingBottom:50}}>
            <Loader style={{fontSize:50, color:"#5448b7"}}/>
          </div>
          :
            <>
              <div className='text-sm text-center bold-600 color-purple m-t-20'>
                  {title}
              </div>
              <div className='text-xs text-center a2grey m-b-20'>
                  {content}
              </div>
              <div style={{display:"flex", flexDirection:"column",alignItems:"center"}}>
                {
                  isRecording===0?
                  <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                    <div className='bold-600' style={{ color: '#636363', marginBottom: 5, fontSize:12 }}>Mode of recording <span style={{color:"red"}}>*</span></div>
                    <Radio.Group onChange={onChange} value={value} style={{ marginTop: "5px", width:300 }}>
                        <Radio value={true}>Offline</Radio>
                        <Radio value={false}>Online</Radio>
                    </Radio.Group>
                  </div>
                  :null
                }
                {
                  hasInputField?
                      <div className='r-c-c m-t-15'>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                            <div className='bold-600' style={{ color: '#636363', marginBottom: 5, fontSize:12 }}>Name <span style={{color:"red"}}>*</span></div>
                            <input value={input} onChange={(e)=>{
                              if (Utils.isValidString(e.target.value)||e.target.value?.trim()===''){
                                setInput(e.target.value)
                              }
                            }} onKeyDown={(e)=>{
                                  if(e.key === 'Enter' && !(input==undefined||input?.trim()=='')) {
                                    console.log("50", input)
                                    onConfirm(input);
                                  }
                            }} placeholder='Please enter a file name for the recording' style={{border:"none", borderBottomColor:"#5448b7", borderBottomWidth:1, borderBottomStyle:"solid", width:300}}></input>
                        </div>
                      </div>
                  :null
                }
              </div>
              {hasInputField && selectedSchedule.videoResourceIds?.length && !value?
                    <div className='r-c-c m-t-15'>
                    <div style={{display:"flex",flexDirection:"column", alignItems:"flex-start", width:300}}>
                            <div className='bold-600' className='bold-600' style={{ color: '#636363', marginBottom: 5, fontSize:12 }}>Section name  <span style={{color:"red"}}>*</span></div>
                            <Select
                                allowClear
                                disabled={false}
                                size="middle"
                                showSearch
                                bordered={false}
                                className="test-custom-select createTestModalValue"
                                placeholder={"Select section"}
                                loading={false}
                                style={{width:"100%"}}
                                value={selectedSection?.name}
                                showArrow={true}
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                notFoundContent={null}
                                onSearch={handleSearchTopic}
                                // onSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true) }}
                                onChange={(text, value) => { 
                                  console.log("109", text)
                                  if (text===-1){
                                    setSelectedSection({id: -1, name:topicName, title:topicName})
                                  }else if (text==undefined){
                                    setSelectedSection({})
                                  }else{
                                    setSelectedSection({id: value.key, name:value.value, title:value.value})
                                  }
                                 }}
                            >
                                {
                                    filteredSelectionList?.filter((item)=>item.id!=selectedSection?.id).map((item)=>{
                                        return <Option key={item?.id} className="createTestModalDropdown" value={item.title}>{item.title}</Option>
                                    })
                                }
                                {
                                  filteredSelectionList?.length===0 && topicName?
                                <Option key={'create'} className="createTestModalDropdown" value={-1}>{`+ Create section ${topicName}`}</Option>
                                :null
                                }
                            </Select>
                     </div>
                     </div>
                :null}
              <div className='r-c-c m-t-30'>
                <Button className='blankGreyBtnWrap radius-100' onClick={onCancel}>{rejectTitle}</Button>
                <Button className={"radius-100 m-l-20 purpleBtnWrap purpleButtonHoverEffect"} onClick={()=>{onConfirm(input, value)}} loading={loading} disabled={hasInputField && (input==undefined||input?.trim()==''||(!value && selectedSchedule.videoResourceIds?.length && (selectedSection==undefined || selectedSection.id==undefined)))}>{confirmTitle}</Button>
              </div>
            </>
        }
      </Modal>
  );
}



export default RecordingModal;
