import { setPaymentModalParamsObj } from 'actions/courseManagement';
import { Modal } from 'antd'
import AnimatedTextField from 'components/AnimatedTextField';
import { Button } from 'components/Button';
import React, { useState } from 'react'
import { connect } from 'react-redux';

function AddDiscountModal({ isAddDiscountModalVisible, setIsAddDiscountModalVisible, isDiscount, setPaymentModalParamsObj, setEditOfflinePaymentModalVisible, transactionData }) {
    const handleCancel = () => {
        setIsAddDiscountModalVisible(false);
        setPaymentModalParamsObj("discountDetails", []);
        setPaymentModalParamsObj("additionDetails", []);
        setPaymentModalParamsObj("amount", parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)));
        setPaymentModalParamsObj("paymentDate", transactionData?.paymentDate ? transactionData?.paymentDate : null);
        setPaymentModalParamsObj("paymentMethod", transactionData?.payment_method);
        setPaymentModalParamsObj("chequeNo", transactionData?.cheque_no);
        setPaymentModalParamsObj("EditedTotalPay", null);
        setPaymentModalParamsObj("editPaidAmount", null);
        // setAmount(parseFloat(parseFloat(transactionData?.amount - transactionData?.total_addition + transactionData?.total_discount).toFixed(2)));
        setPaymentModalParamsObj("totalPay", transactionData?.amount);
    }
    const [paramsObj, setParamsObj] = useState({
        name: null,
        details: null,
        amount: null
    });
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isAddDiscountModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>{isDiscount ? "Scholarship details" : "Addition details"}</center>
            <div className='m-t-30'>
                <AnimatedTextField
                    label={isDiscount ? "Scholarship name" : "Addition name"}
                    isMandatory={true}
                    inputId={"-996"}
                    value={paramsObj?.name}
                    handleInputChange={(e) => { setParamsObj({ ...paramsObj, name: e.target.value }) }}
                />
            </div>
            <div className='m-t-30'>
                <AnimatedTextField
                    label={isDiscount ? "Scholarship details" : "Addition details"}
                    isMandatory={false}
                    inputId={"-995"}
                    value={paramsObj?.details}
                    handleInputChange={(e) => { setParamsObj({ ...paramsObj, details: e.target.value }) }}
                />
            </div>
            <div className='m-t-30'>
                <AnimatedTextField
                    label={"Amount"}
                    isMandatory={true}
                    inputId={"-994"}
                    value={paramsObj?.amount}
                    handleInputChange={(e) => { setParamsObj({ ...paramsObj, amount: e.target.value }) }}
                />
            </div>
            <center>
                <div className='r-jsb m-t-30' style={{ width: "60%" }}>
                    <div style={{ color: "#636363", fontWeight: "bold", cursor: "pointer" }} onClick={handleCancel}>CANCEL</div>
                    <Button type="primary"
                        disabled={!paramsObj?.name || !paramsObj?.amount}
                        onClick={() => {
                            setIsAddDiscountModalVisible(false);
                            setEditOfflinePaymentModalVisible(true)
                            let temp = []
                            temp.push(paramsObj);
                            if (isDiscount) {
                                setPaymentModalParamsObj("discountDetails", temp);
                            } else {
                                setPaymentModalParamsObj("additionDetails", temp);
                            }
                        }}
                    >SAVE</Button>
                </div>
            </center>
        </Modal>
    )
}

export default AddDiscountModal