import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import "antd/lib/row/style/index";
import "antd/lib/col/style/index";
import "../design/layout.css";

export const DetailCard = ({
  dueDate,
  result,
  questions,
  faculty,
  time,
  attempted,
  unattempted,
  assignmentStatus
}) => {
  const duration = time.split(":");
  const hour = duration[0] ? `${parseInt(duration[0])} hr ` : "";
  const mins = duration[1] ? `${parseInt(duration[1])} min ` : "";
  const secs = duration[2] ? `${parseInt(duration[2])} sec` : "";
  const timStr = hour + mins + secs;
  return (
    <div className="assignmentCard NutinFont p-10 m-b-10 E-border">
      <span className="text-sm bold-800">
        Details <br />
      </span>
      <Row className="m-t-10 p-b-10">
        <Col span={8}>
          Due Date <br />
          Result <br />
          Questions <br />
          {(assignmentStatus == "Submitted" ||
            assignmentStatus == "Graded") && (
            <div>
              Attempted <br />
              Unattempted <br />
            </div>
          )}
          Faculty <br />
          Time
        </Col>
        <Col span={1}>
          : <br />
          : <br />
          : <br />
          {(assignmentStatus == "Submitted" ||
            assignmentStatus == "Graded") && (
            <div>
              : <br />
              : <br />
            </div>
          )}
          : <br />:
        </Col>
        <Col span={15} className="bold-600">
          {dueDate} <br />
          {result} <br />
          {questions} <br />
          {(assignmentStatus == "Submitted" ||
            assignmentStatus == "Graded") && (
            <div>
              {attempted} <br />
              {unattempted} <br />
            </div>
          )}
          {faculty} <br />
          {timStr}
          <br />
        </Col>
      </Row>
    </div>
  );
};
