import React, { useEffect } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { setEditDateOfBirth } from "actions/userManagement";
// import { setInstallments } from "actions/course";

function Date_Picker({
  setInstallments,
  installments,
  index,
  value,
  setInstCust,
  price,
}) {
  function onChange(date, dateString) {
    // console.log(dateString);
    if (
      moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date"
    ) {
      installments[index] = {
        installment: price,
        dueDate: moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
      setInstallments([...installments]);
      setInstCust([...installments]);
    } else {
      setEditDateOfBirth(null);
    }

    for (let i = index + 1; i < installments?.length; i++) {
      installments[i] = {
        installment: installments[i]?.installment,
        dueDate: null,
      };
    }
    setInstallments([...installments]);
  }
  function disabledDate(current) {
    // Can not select days before today and today
    // if (installments?.length) {
    //   return current > installments[index - 1] ? true : false;
    // console.log(
    //   "datepic",
    //   !(current && current > moment()),
    //   moment() < current,
    //   current < moment().add(10, "days")
    //   // current
    // );

    // }
    return (
      !(current && current > moment()) ||
      (index
        ? current <= moment(installments[index - 1]?.dueDate)?.add(1, "days")
        : false)
    );
  }
  // (moment() < current && current < moment().add(10, "days"))
  useEffect(() => {
    // console.log("dates in dp", installments[index]?.dueDate);
    // moment(installments[index]?.dueDate) != "Invalid date"
    // console.log(moment(installments[index]?.dueDate).format("DD-MM-YYYY"));
    // for (let i = index + 1; i < installments?.length; i++) {
    //   installments[index] = {
    //     installment: installments[index]?.installment,
    //     dueDate: null,
    //   };
    // }
    // setInstallments([...installments]);
  }, [installments[index]?.dueDate]);

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={50}>
      <DatePicker
        allowClear={false}
        className="datePicker"
        onChange={onChange}
        value={
          installments[index]?.dueDate != null
            ? moment(
              moment(installments[index]?.dueDate, "YYYY-MM-DD").format(
                "DD-MM-YYYY"
              ),
              "DD-MM-YYYY"
            )
            : ""
        }
        placeholder={["Select a date"]}
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
      />
    </Space>
  );
}

// export default Date_Picker1
const mapStateToProps = (state) => {
  const { } = state.course;
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setEditDateOfBirth: (date) => dispatch(setEditDateOfBirth(date)),
  // setInstallments: (val) => dispatch(setInstallments(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Date_Picker);
