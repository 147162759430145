import React, { useEffect, useState } from 'react';
import { Popover, Select } from 'antd';
import { connect } from 'react-redux';
import { setMonthOrWeek, fetchSummaryData } from '../../actions/schedule';
import image from '../../Assets/care/ic-calendar.svg';
import image1 from '../../Assets/care/ic-sort.svg';
import CalenderPopup from './calenderPop';

const { Option } = Select;


const ScheduleCalender = ({ isMonthOrWeek, setMonthOrWeek, togglePageLoader, fetchSummaryData }) => {
  const [monthOrWeek, setIsMonthOrWeek] = useState('week');
  const content = (
    <div className="popOverB" style={{ width: "217px" }}>
      <CalenderPopup togglePageLoader={togglePageLoader} />
    </div>
  );

  const handleChange = (value) => {
    setMonthOrWeek(value)
    setIsMonthOrWeek(value)
    fetchSummaryData(value)
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
      <div><img style={{ width: "50px", height: "15px" }} src={image1} alt="" /></div>
      <div id="testHomeDropdown" style={{display:'flex'}}>
      <Select
        className="inputSelecter"
        style={{ width: "100%", border: "none" }}
        onChange={handleChange}
        bordered={false}
        removeIcon={true}
        dropdownMatchSelectWidth={true}
        placeholder="Week"
        value={isMonthOrWeek}
        getPopupContainer={() =>document.getElementById("testHomeDropdown")}
      >
        <Option value="week">Week</Option>
        <Option value="month">Months</Option>
      </Select>
      {monthOrWeek === "week" ?
        <Popover placement="bottomLeft" dropdownClassName={"dropDownB"} content={content} trigger="click" getPopupContainer={() =>document.getElementById("testHomeDropdown")}>
          <img className="calenderIcon" style={{ width: "20px", height: "20px", marginRight: "2px",marginTop:'5px' }} src={image} alt="" />
        </Popover>
        : null}
        </div>
    </div>

  )
}
const mapStateToProps = state => {
  const {
    isMonthOrWeek,
    startDateSummary,
    endDateSummary,

  } = state.schedule;
  return {
    isMonthOrWeek,
    startDateSummary,
    endDateSummary,
  };
};

const mapDispatchToProps = dispatch => ({

  setMonthOrWeek: (isMonthOrWeek) => dispatch(setMonthOrWeek(isMonthOrWeek)),
  fetchSummaryData: (isMonthOrWeek) => dispatch(fetchSummaryData(isMonthOrWeek))


});


export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalender);