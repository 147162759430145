import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentIcon, updateSetType, currentTypes, fetchBroadcastSession, setStoreSubjectId, updateCurrentStd } from 'actions/digitalLibrary'
import { SquareSubIcon } from 'components/subIcon'
import { Image, Typography } from 'antd'

const BroadCastSubjectCard = ({
    title, filesNO, images, index, fetchClassLibraryData, classSubjectList, subjectsId,updateCurrentSub, updateCurrentIcon, updateSetType,
    user, currentTypes,item,fetchBroadcastSession,storeBroadcastRecordings,setStoreSubjectId,location,checkBoxValue,value,updateCurrentStd
}) => {
    console.log("AA-classSubjectList1",item);
    useEffect(() => {
        if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes('digital-library-dataoperator')) {
            // if(user.access.includes("Admin")||user.role.includes("SuperAdmin")||user.role.includes("Data Operator")){
            fetchClassLibraryData()
        }
    }, [])
    const history = useHistory();

    // const viewSubjectDetails = (data, images) => {
    //     updateCurrentSub(data)
    //     updateCurrentIcon(images)
    //     updateSetType("lecture-notes")
    //     currentTypes("lecture-notes")
    //     if (user.access.includes("digital-library-student")) {
    //         history.push('/digital-library/subject-details')
    //     }
    //     else {
    //         history.push('/digital-library/subject-details')
    //     }
    // }
    const viewRecordingsDetails=()=>{
        console.log("abcbd",user.access);
        if(location?.state?.fromBrodcastVR?.
            sections == undefined){
                checkBoxValue(false)

            }else{
                checkBoxValue(true)

            }
        setStoreSubjectId(subjectsId);
             if (user.access.includes("digital-library-student")) {
                updateCurrentStd(item?.standardId)
        }
        fetchBroadcastSession(()=>{},)
        history.push({
            pathname: "/digital-library/broadcast-recordings-details",
            state: {
                fromBrodcastVR:
                {
                    redirectingPageDL: true,
                    sections: location?.state?.fromBrodcastVR?.sections,
                }
            }
        })
        
    }
    return (
           <div style={{marginBottom:"10px"}}>
           <div className="SubjectCard cursor-pointer" key={index} onClick={()=>{viewRecordingsDetails()}}>
               <div style={{ padding: "20px", display: "flex", gap: 20 }}>
                   <SquareSubIcon icon={images ? images : ''} name={title ? title : ''} size={40} />
                   <div>
                       <Typography.Text ellipsis={{ tooltip: true }} style={{width:"130px"}} className="text-heading-library">{title}</Typography.Text>
                       <div className="s-nofiles">{filesNO} {" "}{filesNO == 1 || filesNO == 0 ? 'Session' : 'Sessions'}</div>
                   </div>
               </div>
           </div>
           {value == true ? (<></>):
           <div style={{ display: "flex", justifyContent: "space-evenly" }}>
               <div ><span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.notesCount ? item?.notesCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Notes</span> <span style={{ fontSize: "13px", color: "#AEAEAE" }}> | </span></div>
               <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.videoCount ? item?.videoCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Videos</span> <span style={{ fontSize: "13px", color: "#AEAEAE" }}> | </span></div>
               <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {item?.quesBankCount ? item?.quesBankCount : 0}  </span> <span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Question banks</span></div>
           </div>}
       </div>
    )
}

const mapStateToProps = (state) => {
    const {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        addedTeacher,storeBroadcastRecordings

    } = state.digitalLibrary;
    const { user } = state.session
    return {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        addedTeacher,
        user,storeBroadcastRecordings

    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchClassLibraryData: () => dispatch(fetchClassLibraryData()),
    updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
    updateCurrentIcon: (images) => dispatch(updateCurrentIcon(images)),
    updateSetType: (type) => dispatch(updateSetType(type)),
    currentTypes: (type) => dispatch(currentTypes(type)),
    fetchBroadcastSession:(callBack) =>dispatch(fetchBroadcastSession(callBack)),
    setStoreSubjectId:(res)=>dispatch(setStoreSubjectId(res)),
    updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),


});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(BroadCastSubjectCard);