import { ArrowLeftOutlined, CaretDownOutlined } from '@ant-design/icons';
import { getOrgsStandardList, postGenerateRegistrationOtp, postRegisterDetails, setShowRegisterForm } from 'actions/loginNew';
import { Col, Dropdown, Input, Menu } from 'antd';
import { Heading, SmallHeading, SubHeading } from 'components/Typography';
import { EditImage, ImageComponent } from 'pages/profile/subComponents';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { IMG_URL } from "../../env.json";
import { Button } from 'components/Button';

const defaultImg = require("../../Assets/Icon awesome-user-alt.svg").default

function RegisterForm({ getOrgsStandardList, postRegisterDetails, orgStandards, userRegister, setUserRegister, setShowRegisterForm, postGenerateRegistrationOtp, setShowVerifyOtpForm, isEditClicked }) {
    const [editLogoModal, setEditLogoModal] = useState(false);
    const [deleteConfirmation, setdeleteConfirmation] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    useEffect(() => {
        getOrgsStandardList();
    }, [])
    const instituteLogoRef = useRef(null);
    const handleLogoModalOk = () => {
        setEditLogoModal(false);
        instituteLogoRef?.current?.click();
    };
    const onDeleteConfirm = (callback, successCallback) => {
        callback()
        setUserRegister({ ...userRegister, image: defaultImg })
        successCallback()
    }
    let roleArr = ['Teacher', 'Student', 'Data Operator', 'Operation']
    const roleMenu = () => {
        return (
            <Menu selectedKeys={[userRegister?.role === "" ? "Select Role" : `${userRegister?.role}`]}>
                {roleArr.map((role, index) => (
                    <Menu.Item key={role} onClick={() => setUserRegister({ ...userRegister, role })}>
                        {role}
                    </Menu.Item>
                ))}
            </Menu>
        )
    }
    const courseMenu = () => {
        return (
            <Menu style={{ padding: 5, maxHeight: 105, overflow: "auto" }} selectedKeys={[userRegister?.preferredClass === "" ? "Select Course" : `${userRegister?.preferredClass}`]}>
                {orgStandards.map((item, index) => (
                    <Menu.Item key={item?.courseName} onClick={() => setUserRegister({ ...userRegister, preferredClass: item?.courseName, preferredCourseId: item?.id })}>
                        {item?.courseName}
                    </Menu.Item>
                ))}
            </Menu>
        )
    }
    const handleRegisterFormSubmit = () => {
        if (userRegister?.name === '') { }
        else {
            userRegister.role = 'Student';
            setSubmitLoader(true)
            // postRegisterDetails(
            //     userRegister,
            //     () => { setSubmitLoader(false) },
            //     () => { setShowRegisterForm(false) })
            postGenerateRegistrationOtp(
                userRegister?.email,
                () => { setSubmitLoader(false) },
                () => { setShowRegisterForm(false), setShowVerifyOtpForm(true) }
            )
        }
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            handleRegisterFormSubmit()
        }
    };
    const dropdownRef = useRef(null);
    return (
        <div className='login-registerForm-parent'>
            <ArrowLeftOutlined style={{ fontSize: 20 }} onClick={() => {
                setShowRegisterForm(false);
                if (isEditClicked) {
                }
                else {
                    setUserRegister({
                        role: "",
                        name: "",
                        email: "",
                        preferredClass: "",
                        preferredCourseId: "",
                        image: defaultImg
                    });
                }
            }} />
            <Col flex="auto" align="center">
                <Heading bold >Student registration form</Heading>
                <SubHeading color="#636363">Kindly fill the form below to register</SubHeading>
            </Col>
            <div style={{ marginTop: -45 }}>
                <ImageComponent
                    src={userRegister?.image}
                    onChange={() => { }}
                    handleEditModal={setEditLogoModal}
                    ref={instituteLogoRef}
                    setfileInputValue={(file) => { setUserRegister({ ...userRegister, image: file }) }}
                    localImg={userRegister?.image}
                    fromLoginRegisterForm={true}
                />
            </div>
            <div className='login-register-form-parent' >
                {/*we have temporarily disabled other roles registration*/}
                {/* <div style={{ marginTop: -35 }} className='signIn-emailForm-width'>
                    <SmallHeading bold color="#636363">Role <span style={{ color: "red" }}>{'*'}</span></SmallHeading>
                    <Dropdown overlay={roleMenu} trigger={["click"]} className='m-t-5'>
                        <div
                            ref={dropdownRef}
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            onClick={() => dropdownRef.current.focus()}>
                            <div className="cursor-pointer r-c-sb signIn-emailForm-width"
                                style={{ backgroundColor: '#FAFAFA', color: userRegister?.role !== '' ? '#191919' : '#AEAEAE', height: '40px', border: "1px solid #e6e6e6", width: "100%" }}>
                                <span bold className='p-l-20'>{userRegister?.role == '' ? 'Select Role' : userRegister?.role}</span>
                                <CaretDownOutlined style={{ fontSize: 15, marginRight: 20 }} />
                            </div>
                        </div>
                    </Dropdown>
                </div> */}
                <div className="m-t-20">
                    <SmallHeading bold color="#636363">Name <span style={{ color: "red" }}>{'*'}</span></SmallHeading>
                    <Input placeholder="Enter Name" className="m-t-5 p-l-15 aulas-login__input signIn-emailForm-width"
                        style={{ border: "1px solid #e6e6e6", }}
                        value={userRegister?.name}
                        onChange={(e) => {
                            let value = e.target.value
                            value = value.replace(/\s\s+/g, '');
                            setUserRegister({ ...userRegister, name: value })
                        }}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {/* <div className="m-t-20">
                    <SmallHeading bold color="#636363">Email ID</SmallHeading>
                    <Input
                        style={{ border: "1px solid #e6e6e6" }}
                        placeholder="Enter Email ID" className="m-t-5 p-l-15 aulas-login__input signIn-emailForm-width"
                        value={userRegister?.email} onChange={(em) => setUserRegister({ ...userRegister, email: em.target.value })}
                        onKeyDown={handleKeyDown}
                    />
                </div> */}
                {/* {
                    userRegister?.role == 'Student' ?
                        orgStandards?.length > 0 ?
                            <div className="m-t-20">
                                <SmallHeading bold color="#636363">Course</SmallHeading>
                                <Dropdown overlay={courseMenu} trigger={["click"]} className='m-t-5'>
                                    <div
                                        ref={dropdownRef}
                                        tabIndex={0}
                                        onKeyDown={handleKeyDown}
                                        onClick={() => dropdownRef.current.focus()}>
                                        <div
                                            className="cursor-pointer r-c-sb"
                                            style={{ backgroundColor: '#FAFAFA', color: userRegister?.preferredClass !== '' ? '#191919' : '#AEAEAE', height: '40px', width: "25vw", border: "1px solid #e6e6e6" }}>
                                            <span bold className='p-l-20'>{userRegister?.preferredClass == '' ? 'Select a course you are applying for?' : userRegister?.preferredClass}</span>
                                            <CaretDownOutlined style={{ fontSize: 15, marginRight: 20 }} />
                                        </div>
                                    </div>
                                </Dropdown>
                            </div> :
                            <></> :
                        ''
                } */}
                <div className='login-generate-btn-margin' style={{width:"55%"}}>
                    <Button
                        type="primary"
                        shape="round"
                        className="r-c-c bold-bold signIn-emailForm-width"
                        onClick={handleRegisterFormSubmit}
                        loading={submitLoader}
                        style={{opacity: userRegister?.name === '' ? 0.5 : 1, cursor: (userRegister?.name === '') ? "not-allowed" : "pointer", width:"100%" }}
                    >
                        GENERATE OTP
                    </Button>
                </div>
            </div>
            <EditImage
                imageUrl={userRegister?.image}
                onEditCancel={() => setEditLogoModal(false)}
                editModalVisible={editLogoModal}
                handleEditOk={handleLogoModalOk}
                deleteConfirm={() => {
                    setdeleteConfirmation(true), setEditLogoModal(false);
                }}
                deleteModalVisible={deleteConfirmation}
                onDeleteCancel={() => setdeleteConfirmation(false)}
                onDeleteConfirm={(callback, successCallback) => onDeleteConfirm(callback, successCallback)}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { orgStandards } = state.loginNew;
    return {
        orgStandards
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOrgsStandardList: () => dispatch(getOrgsStandardList()),
    postRegisterDetails: (userRegister, callback, successCallBack) => dispatch(postRegisterDetails(userRegister, callback, successCallBack)),
    setShowRegisterForm: (bool) => dispatch(setShowRegisterForm(bool)),
    postGenerateRegistrationOtp: (email, callback, successCallBack) => dispatch(postGenerateRegistrationOtp(email, callback, successCallBack))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

// export default RegisterForm