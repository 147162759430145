import axios from "axios"
import moment from "moment"
import { EventRegister } from 'react-native-event-listeners';
import { Api, BrigoshaMedia } from 'services';
import {
  updateRoomName,
  updateConfId,
  updateRoomUsers,
  getPreviousChat,
  updateClassJoin,
  setLiveClass,
  resetHandRaiseQ,
  clearPages,
  toggleChatLoader,
  updateChats,
  updateHandRaiseQ,
  updateClassMode,
  updateMyHandRaise,
  updateInHandRaiseQ,
} from 'actions/liveClass';
import initListeners from 'actions/listener';
import { updateConnectionState } from 'actions/brigoshaMedia';
import { copyChats, getRoomData, setLocalRecordingName, setBroadcastLoader, setRightView, setRightViewType, setRoomBoardData, setScheduledTeacher, updateUnreadCount, updateUserList } from "./broadcast"
import { fake_OrgData } from "pages/conference/fakedata";

export const SET_SUBJECT = "SET_SUBJECT"
export const SET_CLASS = "SET_CLASS"
export const SET_TEACHER = "SET_TEACHER"
export const SET_START_TIME = "SET_START_TIME"
export const SET_END_TIME = "SET_END_TIME"
export const SET_START_DATE = "SET_START_DATE"
export const SET_MODE = "SET_MODE"
export const SET_END_DATE = "SET_END_DATE"
export const SET_WEEKDAYS = "SET_WEEKDAYS"
export const GET_SUB_LIST = "GET_SUB_LIST"
export const GET_CLASS_LIST = "GET_CLASS_LIST"
export const GET_TEACHER_LIST = "GET_TEACHER_LIST"
export const GET_SCHEDULE_LIST = "GET_SCHEDULE_LIST"
export const GET_SUMMARY_DATA = "GET_SUMMARY_DATA"
export const GET_TIMETABLE_DATA = "GET_TIMETABLE_DATA"
export const GET_STD_LIST_FILTER = "GET_STD_LIST_FILTER"
export const SET_STD_FILTER = "SET_STD_FILTER"
export const GET_TODAY_ACTIVITIES_DATA = "GET_TODAY_ACTIVITIES_DATA"
export const GET_SUB_LIST_FILTER = "GET_SUB_LIST_FILTER"
export const SET_SCHEDULE_ID = "SET_SCHEDULE_ID"
export const SET_SCHEDULE_TIMETABLE = "SET_SCHEDULE_TIMETABLE"
export const SET_MONTH_OR_WEEK = "SET_MONTH_OR_WEEK"
export const SET_START_DATE_SCHEDULE = "SET_START_DATE_SCHEDULE"
export const SET_END_DATE_SCHEDULE = "SET_END_DATE_SCHEDULE"
export const SET_TAB_TYPE = "SET_TAB_TYPE"
export const SET_STD_FILTER_TIMETABLE = "SET_STD_FILTER_TIMETABLE"
export const UPDATE_ERROR = "UPDATE_ERROR"
export const SET_BROADCAST_SELECTED_CLASSROOM = "SET_BROADCAST_SELECTED_CLASSROOM"
export const SET_ROOM_IDS = "SET_ROOM_IDS"
export const GET_ONLINE_USER = "GET_ONLINE_USER"
export const SET_ACCESS = "SET_ACCESS"
export const SET_DEL_OR_CANCEL = "SET_DEL_OR_CANCEL"
export const UPDATE_BROADCAST_STUDENT_LIST = "UPDATE_BROADCAST_STUDENT_LIST"
export const SET_JOIN_LOADER = "SET_JOIN_LOADER"

export const SET_CURRENT_DATE = 'SET_CURRENT_DATE'
export const GET_MODES = 'GET_MODES'
export const SET_TEACHER_FILTER = 'SET_TEACHER_FILTER'
export const SET_CLASS_FILTER = 'SET_CLASS_FILTER'
export const SET_SUB_FILTER = 'SET_SUB_FILTER'
export const SET_PAGE_TOGGLE = 'SET_PAGE_TOGGLE'


// schedule attendance
export const SET_STUDENT_DATA = 'SET_STUDENT_DATA'
export const SET_SUBJECT_DATA = 'SET_SUBJECT_DATA'
export const SET_SELECTED_STUDENTS = 'SET_SELECTED_STUDENTS'
export const SET_SELECT_ALL = 'SET_SELECT_ALL'
export const SET_CHECKBOX_STATE = 'SET_CHECKBOX_STATE'
export const SET_SELECTED_ID = 'SET_SELECTED_ID'
export const SET_PRESENT_STUDENTS = 'SET_PRESENT_STUDENTS'
export const SET_CARD_DATA = 'SET_CARD_DATA'
export const SET_ALL_FALSE = 'SET_ALL_FALSE'
export const SET_INITIAL_SUMMARY = 'SET_INITIAL_SUMMARY'
export const SET_TEACHER_DATE = 'SET_TEACHER_DATE'
export const FETCH_SUBSALERT_DATA = 'FETCH_SUBSALERT_DATA'

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';

export const TOGGLE_LOADER = 'TOGGLE_LOADER';

export const UPDATE_MEET_DETAILS = 'UPDATE_MEET_DETAILS';

export const UPDATE_ZOOM_TOKEN = 'UPDATE_ZOOM_TOKEN';

export const UPDATE_START_LOADER_TEXT = 'UPDATE_START_LOADER_TEXT';

export const UPDATE_START_LOADER = 'UPDATE_START_LOADER';

export const UPDATE_ACTIVE_CLASS = 'UPDATE_ACTIVE_CLASS';

export const TOGGLE_SCHEDULE_REFRESH = 'TOGGLE_SCHEDULE_REFRESH';

export const TOGGLE_ZOOM_STATUS = 'TOGGLE'

export const SET_CONFERENCE_JOIN_LOADER = 'SET_CONFERENCE_JOIN_LOADER'

export const STUDENT_STANDARD_FILTER = 'STUDENT_STANDARD_FILTER'

export const SET_RECORD_COUNT = 'SET_RECORD_COUNT'
export const SET_LIVE_STREAM_URL = 'SET_LIVE_STREAM_URL'

export const toggleRefresh = value => ({
  type: TOGGLE_SCHEDULE_REFRESH,
  value,
});

export const updateActiveClass = value => ({
  type: UPDATE_ACTIVE_CLASS,
  value,
});

export const updateStartLoader = value => ({
  type: UPDATE_START_LOADER,
  value,
});

export const updateStartLoaderText = value => ({
  type: UPDATE_START_LOADER_TEXT,
  value,
});

export const updateZoomToken = token => ({
  type: UPDATE_ZOOM_TOKEN,
  token,
});

export const toggleLoader = res => ({
  type: TOGGLE_LOADER,
  res,
});

export const updateSchedule = (res, date) => ({
  type: UPDATE_SCHEDULE,
  res,
  date,
});

export const updateMeetDetails = res => ({
  type: UPDATE_MEET_DETAILS,
  res,
});

export const getSchedule =
  (date, standardId = null) =>
    dispatch => {
      const params = {
        date,
        standardId,
      };
      return Api.get('/schedule-new/filter-schedule')
        .params(params)
        .send(response => {
          dispatch(toggleLoader(false));
          dispatch(toggleRefresh(false));
          if (typeof response !== 'undefined') {
            let newResponse = {
              ongoing: response.ongoing.filter((item) => {
                if (item.mode === 'Zoom 40') {
                  return false
                }
                return true
              }),
              completed: response.completed.filter((item) => {
                if (item.mode === 'Zoom 40') {
                  return false
                }
                return true
              }),
              cancelled: response.cancelled.filter((item) => {
                if (item.mode === 'Zoom 40') {
                  return false
                }
                return true
              }),
              scheduled: response.scheduled.filter((item) => {
                if (item.mode === 'Zoom 40') {
                  return false
                }
                return true
              })
            }
            dispatch(
              updateSchedule(
                [
                  ...newResponse.completed,
                  ...newResponse.ongoing,
                  ...newResponse.scheduled,
                ],
                date,
              ),
            );
            // if (newResponse.ongoing.length) {
            //   dispatch(getOnlineCount(newResponse.ongoing));
            // }
          }
        });
    };


export const startClass =
  (scheduleId, isZoom = false, history) =>
    (dispatch, getState) => {
      const { user } = getState().session;
      const { roomName } = getState().liveClass;
      dispatch(updateStartLoaderText('Joining your classroom...'));
      dispatch(updateStartLoader(true));

      return new Promise((resolve, reject) => {
        return Api.patch(`/schedule-new/join-class?id=${scheduleId}`).send(
          response => {
            if (typeof response !== 'undefined') {
              resolve();
              if (roomName !== response.roomName) {
                dispatch(setLiveClass());
                dispatch(clearPages());
              }
              dispatch(updateConfId(response?.occurance?.id));
              dispatch(updateClassMode(response?.occurance?.mode));
              if (isZoom) {
                dispatch(updateClassJoin(true));
                dispatch(updateStartLoader(false));
                return;
              }
              dispatch(resetHandRaiseQ());
              // add teacher to room users array. for offline list
              response.occurance.teacher.isTeacher = true;
              response.students.unshift(response.occurance.teacher);
              dispatch(updateRoomUsers(response.students, user.id));
              dispatch(updateRoomName(response?.occurance?.roomName));
              dispatch(onClassJoin(user, response?.occurance?.roomName, history));
            } else {
              dispatch(setConferenceJoinLoader(false));
              reject();
              dispatch(updateStartLoader(false));
            }
          },
        );
      });
    };

export const onClassJoin = (user, roomName, history) => async (dispatch, getState) => {
  dispatch(connectMedia(user, roomName, history));
  dispatch(updateClassJoin(true));
  dispatch(getPreviousChat(roomName));
};

export const connectMedia = (user, roomName, history) => (dispatch, getState) => {
  const { activeClass } = getState().schedule;
  const { currentClassMode } = getState().liveClass;
  const isTeacher = user.isAdmin || user.isSuperAdmin || user.isPlanner || user.isAttendanceMarker ? activeClass.teacherId === user.id : activeClass.teacher.id === user.id;
  const platform = 'web';
  user.roomId = roomName;
  user.platform = platform;

  const media = new BrigoshaMedia();
  let newuser = {
    ...user,
    isTeacher: isTeacher,
    // org: fake_OrgData
  }
  media.init(
    user.id,
    roomName,
    user.name,
    newuser,
    isTeacher,
    platform,
    currentClassMode,
  );

  dispatch(initListeners(user, isTeacher, history));

  EventRegister.addEventListener('joinRcv', data => {
    const { currentClassMode } = getState().liveClass;
    if (currentClassMode == 'Offline') {
      dispatch(updateStartLoader(false));
      // RootNavigation.navigate('OfflineChat');
    }
    // inform brigosha media that you are in class
    media.updateClassJoinStatus(true);
    if (data.handRaiseQueue.length) {
      dispatch(updateHandRaiseQ(data.handRaiseQueue));
    }
    if (data.url) {
      media.setBaseUrl("https://livestream.aulas.in/api");
    }
    // if user is already in handRaiseQ, enable audio/video
    if (data.handRaiseStatus) {
      dispatch(updateMyHandRaise(true));
      dispatch(updateInHandRaiseQ(true));
    }
    dispatch(updateConnectionState(false));
  });

  EventRegister.addEventListener('settingupServerRcv', () => {
    dispatch(updateStartLoaderText('Setting up your shiny new classroom...'));
  });

  EventRegister.addEventListener('serverInitialized', () => {
    media.initSockets(true);
  });
};

export const sendZoomCode = code => (dispatch, getState) => {
  const params = {
    code,
  };
  dispatch(updateStartLoader(true));
  return new Promise(resolve => {
    Api.get('/schedule-new/fetch-token')
      .params(params)
      .send(response => {
        dispatch(updateStartLoader(false));
        resolve(response?.message);
      });
  });
};

export const getZoomUser = () => (dispatch, getState) => {
  dispatch(updateStartLoader(true));
  return new Promise((resolve, reject) => {
    Api.get('/schedule-new/fetch-zoom-user').send(response => {
      dispatch(updateStartLoader(false));
      if (typeof response !== 'undefined') {
        resolve(response);
      }
    });
  });
};


export const fetchSubList = (search, callback) => {
  return (dispatch) => {
    const url = `/schedule-new/subject-list?search=${search ? search : ''}`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            dispatch(getSubList(response))
          }
        })
    })
  }
}

export const fetchClassList = (search, subId, callback) => {
  return (dispatch) => {
    const url = `/schedule-new/standard-list?search=${search ? search : ''}&subjectId=${subId ? subId : ''}`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            dispatch(getClassList(response))
          }
        })
    })
  }
}

export const fetchTeacherList = (search, callback) => {
  return (dispatch) => {
    const url = `/schedule-new/teacher-list?search=${search ? search : ''}`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            dispatch(getTeacherList(response))
          }
        })
    })
  }
}

export const postCreateSchedule = (objectSchedule, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/`
    return new Promise((resolve, reject) => {
      Api.post(url, objectSchedule)
        .send((response, error) => {
          if (response != undefined) {
            if (response.show.type == 'error') {
              callback()
            }
            else {
              successCallback()
            }
          }
          else {
            callback()

          }
        })
    })
  }
}

export const fetchSchedule = (callback, date) => {
  return (dispatch, getState) => {
    // const { date } = getState().schedule;
    const url = `/schedule-new/`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            dispatch(getStdListFilter(response.standards))
            dispatch(getSubListFilter(response.subjects))
            dispatch(getSchedules(response.schedules))
            dispatch(getTodayactivitiesData(response.todaysActivities))
            dispatch(getSummaryData(response.occuranceCounts))
            dispatch(getModes(response.modes))
            // dispatch(fetchScheduleFilter(date,'','','',''))

          }
        })
    })
  }
}

export const fetchScheduleTeacher = (callback) => {
  return (dispatch) => {
    const url = `/schedule-new/`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            dispatch(getSchedules(response.schedules))
            dispatch(getStdListFilter(response.standards))
          }
        })
    })
  }
}

export const fetchScheduleStudent = (callback) => {
  return (dispatch) => {
    const url = `/schedule-new/`
    return new Promise((resolve, reject) => {
      Api.get(url)
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            dispatch(getSchedules(response.schedules))

          }
        })
    })
  }
}

export const fetchTimetableData = (stdIdFilter, callback, date) => {
  return (dispatch, getState) => {
    const url = `/schedule-new/timetable?classId=${stdIdFilter ? stdIdFilter : ''}`
    return new Promise((resolve, reject) => {
      Api.get(url).params({ date: date ? date : moment().format('YYYY-MM-DD') })
        .send((response, error) => {
          if (callback) {
            callback()
          }
          if (response != undefined) {
            //   console.log("response",response)
            dispatch(getTimetable(response))
          }
        })
    })
  }
}

export const fetchScheduleFilter = (date, topic, std, sub, callback) => {
  return (dispatch, getState) => {
    var dateCurrent = moment().format('YYYY-MM-DD')
    let params = {
      date: date ? date : dateCurrent,
      search: topic ? topic : '',
      subjectIds: sub.length != 0 ? sub : undefined,
      classIds: std.length != 0 ? std : undefined,
    }

    const url = `/schedule-new/filter-schedule`
    return new Promise((resolve, reject) => {
      Api.get(url).params(params)
        .send((response, error) => {
          if (response != undefined) {
            dispatch(getSchedules(response))
            if (callback) {
              callback()
            }
          }
        })
    })
  }
}

export const fetchScheduleFilterTeacherandStudent = (date, search, classIds, callback) => {
  return (dispatch, getState) => {
    var dateCurrent = moment().format('YYYY-MM-DD')
    let params = {
      date: date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : dateCurrent,
      search: search ? search : '',
      classIds: classIds ? classIds : []
    }

    const url = `/schedule-new/filter-schedule`
    return new Promise((resolve, reject) => {
      Api.get(url).params(params)
        .send((response, error) => {
          if (response != undefined) {
            dispatch(getSchedules(response))
            callback()
          }
        })
    })
  }
}

export const postEditOccurance = (objectOccurance, callback, successCallback) => {
  return (dispatch) => {
    const url = `/schedule-new/edit-occurance`
    return new Promise((resolve, reject) => {
      Api.patch(url, objectOccurance)
        .send((response, error) => {
          if (response != undefined) {
            console.log("res", response.type)
            if (response.show.type == 'error') {
              callback()
            }
            else {
              successCallback()
            }
          }
          else {
            callback()
          }
        })
    })
  }
}

export const deleteSchedule = (id) => {
  return (dispatch) => {
    const url = `/schedule-new/?scheduleId=${id}`
    return new Promise((resolve, reject) => {
      Api.delete(url)
        .send((response, error) => {
          if (response != undefined) {
            resolve()
            console.log(response)
          }else{
            reject()
          }
        })
    })
  }
}

export const cancelSchedule = (id) => {
  return (dispatch) => {
    const url = `/schedule-new/cancel?id=${id}`
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .send((response, error) => {
          if (response != undefined) {
            console.log(response)
            resolve()
          }
          else {
            reject()
            callback()
          }
        })
    })
  }
}

export const fetchSummaryData = (isMonthOrWeek, startDate, endDate) => {
  return (dispatch, getState) => {
    let params = {
      type: isMonthOrWeek,
      startDate: startDate ? startDate : '',
      endDate: endDate ? endDate : ''
    }
    const url = `/schedule-new/summary-data`
    return new Promise((resolve, reject) => {
      Api.get(url).params(params)
        .send((response, error) => {
          if (response != undefined) {
            dispatch(getSummaryData(response))
          }
        })
    })
  }
}

export const setRecordCount = (val) => ({
  type: SET_RECORD_COUNT,
  val
})


export const joinClass = (id, callBack = null) => {
  return (dispatch, getState) => {
    const url = `/schedule-new/join-class?id=${id}`;
    const { user } = getState().session;
    const {streamId, roomboardData} = getState().broadcast;
    dispatch(setBroadcastLoader(true));
    dispatch(setRecordCount(0))
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .send(async (response, error) => {
          if (callBack) {
            callBack();
          }
          if (response != undefined) {
            let isScheduledTeacher = response.occurance?.teacherId===user.id
            dispatch(setScheduledTeacher(isScheduledTeacher))
            dispatch(updateActiveClass(response));
            dispatch(setRecordCount(response.occurance?.recordCount))
            dispatch(setRightView(false));
            dispatch(setRightViewType(''));
            dispatch(updateBroadcastStudentList(response.students));
            dispatch(copyChats(response.chats.group, response.chats.private, user));
            dispatch(setLocalRecordingName('abc', 'abc'))
            dispatch(updateUnreadCount('clear'))
            if (response.chats){
              dispatch(updateUnreadCount('group', response.chats.groupChatUnseenCount))
              response.chats.privateCount = {}
              response.chats.privateUnseenCount.forEach((item)=>{
                response.chats.privateCount[item.userId] = item.count
              })
              dispatch(updateUnreadCount('privateFull', response.chats.privateCount))
            }
            if (!(streamId in roomboardData)){
              dispatch(setRoomBoardData(streamId, {
                roomdata: {
                "1": [],
                "2": [],
                "3": [],
              }}
              ))
            }
            dispatch(setJoinLoader(false));
            resolve();
          }
          else {
            reject();
          }
        })
    })
  }
}

export const getJoinUsers = () => {
  return (dispatch, getState) => {
    let { roomIds } = getState().schedule
    const url = `https://2damuur84g.execute-api.ap-south-1.amazonaws.com/prod/getjoinedusers`
    return new Promise((resolve, reject) => {
      axios.post(url,
        {
          operation: "list",
          data: {
            roomIds: roomIds
          }
        })
        .then((response) => {
          if (response != undefined) {
            dispatch(getOnlineUser(response.data))
          }
        })

    })
  }
}



export const setSubject = (subId) => ({
  type: SET_SUBJECT,
  subId
})

export const setClass = (classId) => ({
  type: SET_CLASS,
  classId
})

export const setTeacher = (teacherId) => ({
  type: SET_TEACHER,
  teacherId
})

export const setStartTime = (time) => ({
  type: SET_START_TIME,
  time
})

export const setEndTime = (time) => ({
  type: SET_END_TIME,
  time
})

export const setSartDate = (date) => ({
  type: SET_START_DATE,
  date
})

export const setEndDate = (enddate) => ({
  type: SET_END_DATE,
  enddate
})

export const setMode = (mode) => ({
  type: SET_MODE,
  mode
})

export const setWeekDays = (week) => ({
  type: SET_WEEKDAYS,
  week
})

export const getSubList = (subList) => ({
  type: GET_SUB_LIST,
  subList
})

export const getClassList = (classList) => ({
  type: GET_CLASS_LIST,
  classList

})

export const getTeacherList = (teacherList) => ({
  type: GET_TEACHER_LIST,
  teacherList
})

export const getSchedules = (schedule) => ({
  type: GET_SCHEDULE_LIST,
  schedule
})

export const getSummaryData = (summaryData) => ({
  type: GET_SUMMARY_DATA,
  summaryData
})

export const getTimetable = (timetableData) => ({
  type: GET_TIMETABLE_DATA,
  timetableData
})

export const getStdListFilter = (stdListFilter) => ({
  type: GET_STD_LIST_FILTER,
  stdListFilter
})

export const getTodayactivitiesData = (data) => ({
  type: GET_TODAY_ACTIVITIES_DATA,
  data
})

export const getSubListFilter = (sub) => ({
  type: GET_SUB_LIST_FILTER,
  sub
})

export const setScheduleIdDelete = (id) => ({
  type: SET_SCHEDULE_ID,
  id
})

export const setScheduleTimeTable = (item) => ({
  type: SET_SCHEDULE_TIMETABLE,
  item
})

export const setMonthOrWeek = (isMonthOrWeek) => ({
  type: SET_MONTH_OR_WEEK,
  isMonthOrWeek
})

export const setStartDateSchedule = (date) => ({
  type: SET_START_DATE_SCHEDULE,
  date
})

export const setEndDateSchedule = (date) => ({
  type: SET_END_DATE_SCHEDULE,
  date
})


export const setTabType = (tab) => ({
  type: SET_TAB_TYPE,
  tab
})

export const setStdFilterTimeTable = (std) => ({
  type: SET_STD_FILTER_TIMETABLE,
  std
})

export const updateError = (error) => ({
  type: UPDATE_ERROR,
  error
})

export const setBroadcastSelectedClassroom = (newClass) => ({
  type: SET_BROADCAST_SELECTED_CLASSROOM,
  newClass
})

export const setRoomIds = (roomIds) => ({
  type: SET_ROOM_IDS,
  roomIds
})

export const getOnlineUser = (users) => ({
  type: GET_ONLINE_USER,
  users
})

export const setAccess = (bool) => ({
  type: SET_ACCESS,
  bool
})

export const setDelOrCancel = (delOrCancel) => ({
  type: SET_DEL_OR_CANCEL,
  delOrCancel

})

export const updateBroadcastStudentList = (data) => ({
  type: UPDATE_BROADCAST_STUDENT_LIST,
  data
})

export const setCurrentDate = (currentDate) => ({
  type: SET_CURRENT_DATE,
  currentDate
})

export const getModes = (modes) => ({
  type: GET_MODES,
  modes
})

export const setTeacherfilter = (teacher) => ({
  type: SET_TEACHER_FILTER,
  teacher
})

export const setClassFilter = (classes) => ({
  type: SET_CLASS_FILTER,
  classes
})

export const setSubFilter = (sub) => ({
  type: SET_SUB_FILTER,
  sub
})

export const setJoinLoader = (bool) => ({
  type: SET_JOIN_LOADER,
  bool
})


// schedule attendance


export const fetchStudentListData = () => {

  return (dispatch, getState) => {
    let { selectedId } = getState().schedule
    let params = {
      occuranceId: selectedId,
    }
    const url = `/attendancev2/student-list`
    return new Promise((resolve, reject) => {
      Api.get(url).params(params)
        .send((response, error) => {
          if (response != undefined) {
            dispatch(setStudentData(response?.students))
            dispatch(setSubjectData(response?.occurance))
            dispatch(setInitialSummary({
              totalStudents: response?.totalStudents,
              presentCount: response?.presentCount,
              absentCount: response?.absentCount
            }))
            if (response?.totalStudents === response?.presentCount) {
              dispatch(setCheckBoxState(true))
            } else {
              dispatch(setCheckBoxState(false))
            }
            dispatch(setAllFalse())
            console.log('studentList', response)
          }
        })
    })
  }
}

export const setInitialSummary = (value) => ({
  type: SET_INITIAL_SUMMARY,
  value
})

export const setStudentData = (value) => ({
  type: SET_STUDENT_DATA,
  value
})

export const setSubjectData = (value) => ({
  type: SET_SUBJECT_DATA,
  value
})

export const setSelectedStudents = (id) => ({
  type: SET_SELECTED_STUDENTS,
  id,

})

export const setCheckBoxState = (bool) => ({
  type: SET_CHECKBOX_STATE,
  bool
})

export const setSelectAll = () => ({
  type: SET_SELECT_ALL,

})

export const setAllFalse = () => ({
  type: SET_ALL_FALSE,
})

export const setSelectedId = (id) => ({
  type: SET_SELECTED_ID,
  id
})

export const setPresentStudents = (id) => ({
  type: SET_PRESENT_STUDENTS,
  id
})

export const setCardData = (value) => ({
  type: SET_CARD_DATA,
  value
})

export const setTeacherDate = (key, value) => ({
  type: SET_TEACHER_DATE,
  key,
  value
})

export const postLogOfflineAttendance = (callBack = null) => {
  const url = `/attendancev2/log-offline-attendance`;
  return (dispatch, getState) => {
    const { selectedId, selectedStudents } = getState().schedule
    const output = [];
    Object.keys(selectedStudents).forEach(key => {
      if (selectedStudents[key]) {
        output.push(key);
      }
    })

    const params = {
      occuranceId: selectedId,
      studentIds: output
    }
    return new Promise((resolve, reject) => {
      Api.post(url).params(params)
        .send((response, error) => {
          if (callBack) {
            callBack()
          }
          resolve();
          if (response) {
          }
        });
    });
  };
}

export const pollAttendance = () => (
  dispatch,
  getState,
) => {
  const { user } = getState().session;
  const { broadcastSelectedClass } = getState().schedule;
  const params = {
    userId: user.id,
    orgUrl: user.org.url,
    occuranceId: broadcastSelectedClass.id
  }
  return Api.post('/attendancev2/log-duration')
    .params(params)
    .send((response, error) => {
      if (typeof response !== 'undefined') {

      }
    });
};

export const setPageToggle = (value) => ({
  type: SET_PAGE_TOGGLE,
  value
})

export const fetchSubscriptionAlert = (res) => ({
  type: FETCH_SUBSALERT_DATA,
  res
})

export const subscriptionAlert = () => (
  dispatch,
  getState,
) => {
  const { } = getState().schedule;
  const params = {}
  let intervalId = null
  return Api.get('/schedule-new/subscription/user-hours-data')
    .params(params)
    .send((response, error) => {
      if (response !== 'undefined') {
        // if( response?.status === "Fetching" || response?.status === "Invalid" ) {
        //     intervalId = setInterval(() => {
        //       console.log('calling')
        //       dispatch(subscriptionAlert())
        //     }, 1000); 
        //   } 

        // if(response?.status === "Error Fetching"){
        //     notification.error("Error in fetching broadcast user hours data")
        // }
        dispatch(fetchSubscriptionAlert(response))
        console.log('rest', response)
      }
    });
};

export const setConferenceJoinLoader = (bool) => ({
  type: SET_CONFERENCE_JOIN_LOADER,
  bool
});

export const studentStandardsFilter = res => ({
  type: STUDENT_STANDARD_FILTER,
  res
})

export const fetchStudentStandards = () => {
  return (dispatch, getState) => {
    const { user } = getState().session
    const url = `/user/studentStandards`;
    return (
      Api.get(url).params({ userId: user.id }).send((response) => {
        console.log(response, 'student filters response');
        dispatch(studentStandardsFilter(response));
      })
    )
  }
}

export const setLiveStreamUrl = (val) => ({
  type: SET_LIVE_STREAM_URL,
  val
})

