import React, { useEffect, useState } from 'react';
import "./styles.css"
import { Dropdown, Space, Input, Button, Menu, Modal, AutoComplete } from 'antd';
import { CaretDownOutlined, ShoppingCartOutlined, DownOutlined } from '@ant-design/icons';
import SearchError from './searchError';
import { Link } from "react-router-dom";
import { AllCoursesData, HomeApi, setAllCourseData, setChildLink, setHomePageData, setLoginDetails, setParentLink } from 'actions/aulasMarket';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import LogoutConfirm from 'layout_new/logoutConfirm';
import { logout } from 'actions/login';
import { clearPrivateChat } from 'actions/broadcast';
import SearchCompo from 'components/SearchCompo';
import SubMenu from 'antd/lib/menu/SubMenu';
import { setLoader } from 'actions/testAdmin';

const { Search } = Input;
function TopBar({ homePageData, coursePageData, cartCount, loginDetails, logout, setAllCourseData, setLoginDetails, setParentLink }) {
    const history = useHistory();

    const [search, setSearch] = useState("");
    const [confirmLogoutVisible, setConfirmLogoutVisible] = useState(false);
    const [token, setToken] = useState(loginDetails?.accessToken);

    const handleCategoryClick = (category) => {
        if (category.children) {
            setParentLink({ parentName: category.name, childName: null });
        } else {
            setParentLink({ parentName: category.name, childName: null });
        }
    };

    const handleParentClick = ({ parentName, childName, parentId, childId }) => {
        setParentLink({ parentName: parentName, childName: childName });
        AllCoursesData(token, childId, (res) => {
            setAllCourseData(res)
            if (res) {
                history.push('/popular-courses');
            }
        }, setLoader)
    }

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleLogout = (history) => {
        const reason = { type: 'Manual' }

        setLoginDetails("accessToken", null)
        setLoginDetails("userData", null)
        setToken(null);
        logout(history, reason);
        history.push("/");
        setConfirmLogoutVisible(false);
    };

    const MenuHome = (
        <div className='profileLayout'>
            <div className='r-c-c text-sm font-Bold p-10' style={{ color: "#191919" }}>
                {token ?
                    <span>Hello {loginDetails?.userData?.name}!</span>
                    :
                    <span>Hello Guest!</span>
                }
            </div>

            {token ? null :
                <div className='r-c-c' style={{}}>
                    <Button className='profileBtn' onClick={() => history.push('/login')} style={{ fontSize: 12, fontWeight: 600 }} >CONTINUE WITH MOBILE NUMBER</Button>
                </div>
            }

            {/* <Dropdown.Divider /> */}
            <div className='r-c-c-c m-t-0 p-10' >
                <div style={{ width: "100%" }}>

                    <a style={{ color: "#191919", fontSize: "1rem" }} href={token ? "/my-account" : "/login"} >My Account</a>
                </div>
                <div className='m-t-5' style={{ width: "100%", borderTop: "1px solid #E6E6E6" }}>
                    <div onClick={() => {
                        token ? history.push('/saved-later') : history.push('/login')
                    }}>
                        <a style={{ color: "#191919", fontSize: "1rem" }}>Saved For Later</a>
                    </div>
                </div>
                {token ?
                    <div className='m-t-5 font-bold' onClick={() => setConfirmLogoutVisible(true)} style={{ width: "100%", borderTop: "1px solid #E6E6E6" }}>
                        <a style={{ color: "#FF414D", fontSize: "1rem" }}>Logout</a>
                    </div>
                    : null}
            </div>

        </div>
    );

    const renderSubMenu = (category) => (
        <SubMenu key={category.id} title={category.name}>
            {category.children.map((childCategory) => (
                <Menu.Item key={childCategory.id} className='category-child-list' onClick={() => handleParentClick({ parentName: category?.name, childName: childCategory?.name, parentId: category?.id, childId: childCategory?.id })}>
                    {childCategory.name}
                </Menu.Item>
            ))}
        </SubMenu>
    );

    const menu = (
        <Menu className='category-list' style={{ overflowY: "scroll", height: "50vh" }} >
            {homePageData?.categories?.map((category) => (
                category.children ? (
                    renderSubMenu(category)
                ) : (
                    <Menu.Item key={category.id} onClick={() => handleCategoryClick(category)}>
                        {category.name}
                    </Menu.Item>
                )
            ))}
        </Menu>
    );

    const handleSearch = (val) => {
        console.log('searching', val);
    }
 
    console.log("cartCount",coursePageData?.cart?.items?.length)
    return (
        <div className='r-jsb p-h-70 top-bar' style={{ alignItems: "center", width: "100%" }}>
            <Link to="/home">
                <div className='topHeader-parent mobile-visible'>
                    <div className='topHeader' style={{}}> <img style={{position:"relative"}} src={require("../../Assets/aulasMarket/vectorLogo.png").default} /><div className='headerText'>{homePageData?.orgName}</div></div>
                    <div className='hamburger' ><img src={require("../../Assets/aulasMarket/hamburger.svg").default} /></div>
                </div>
            </Link>
            <div className='r-c-c mobile-hidden' style={{ width: "50%" }}>
                <div className='font-bold m-r-20' style={{ color: "#191919", fontSize: 18 }}>
                    {/* <Dropdown
                        menu={homePageData?.categories}
                        trigger={['click']}
                    >
                        <div>
                            <Space>
                                Categories
                                <a onClick={(e) => e.preventDefault()}>
                                    <CaretDownOutlined style={{ color: "#AEAEAE" }} />
                                </a>
                            </Space>
                        </div>
                    </Dropdown> */}


                    <Dropdown overlay={menu} trigger={['click']}>
                        <div >
                            <Space>
                                Categories
                                <a onClick={(e) => e.preventDefault()}>
                                    <CaretDownOutlined style={{ color: "#AEAEAE" }} />
                                </a>
                            </Space>
                        </div>
                    </Dropdown>
                </div>

                {/* <Search

                    allowClear={true}
                    placeholder="Search"
                    style={{ width: 500 }}
                    onSearch={onSearch}
                /> */}
                <div style={{ width: "70%" }}>
                    {/* <SearchCompo onSearch={(e) => handleSearch(e)} setSearch={setSearch} style={{ border: "1px solid #E6E6E6", width: "150%", height: "35px" }} /> */}
                    <AutoComplete style={{ width: "100%", padding: "0px" }} showSearch={true} placeholder="Search" />
                </div>
            </div>
            <div className='display-flex mobile-hidden' >
                <div className='m-r-20 m-t-5 r-c-sb' style={{ width: 55 }} >
                    <img style={{ width: 25 }} src={require("../../Assets/aulasMarket/profileIcon.svg").default} />
                    <div className='font-bold' style={{ color: "#191919", fontSize: 15 }}>
                        <Dropdown overlay={MenuHome} trigger={['click']}>
                            <span>
                                <Space>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <CaretDownOutlined style={{ color: "#AEAEAE" }} />
                                    </a>
                                </Space>
                            </span>
                        </Dropdown>
                    </div>
                </div>
                <div style={{ marginTop: 5 }}>
                    {/* <ShoppingCartOutlined style={{ color: "#594099", fontSize: 32 }} /> */}
                    <Link to={token ? "/shopping-cart" : "/login"}>
                        <div className='r-c-c cartCount' style={{ position: "absolute" }}>{coursePageData?.cart?.items?.length}</div>
                        <img style={{ width: 25 }} src={require("../../Assets/aulasMarket/cartIcon.svg").default} />
                    </Link>
                </div>
            </div>
            {/* {confirmLogoutVisible &&
                <LogoutConfirm
                    visible={confirmLogoutVisible}
                    onCancel={() => setConfirmLogoutVisible(false)}
                    onConfirm={() => handleLogout(history)}
                />
            } */}

        </div>
    )
}

const mapStateToProps = (state) => {
    const { homePageData, loginDetails, coursePageData, cartCount } = state.aulasMarket

    return {
        homePageData, loginDetails, coursePageData, cartCount
    }
};

const mapDispatchToProps = (dispatch) => ({
    logout: (history, reason) => dispatch(logout(history, reason)),
    setHomePageData: (item) => dispatch(setHomePageData(item)),
    HomeApi: (callback) => dispatch(HomeApi(callback)),
    setParentLink: (item) => dispatch(setParentLink(item)),
    setChildLink: (item) => dispatch(setChildLink(item)),
    setAllCourseData: (item) => dispatch(setAllCourseData(item)),
    setLoginDetails: (key, val) => dispatch(setLoginDetails(key, val)),
});




export default connect(mapStateToProps, mapDispatchToProps)(TopBar)