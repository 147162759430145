import { ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Col, Input, Row, Tabs, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import LibraryCardDetails from '../components/LibraryCards_details'
import lecturenotes from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg"
import videoImg from "../../../Assets/digitalLibrary/ic-dl-lecture-videos.svg"
import questBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg"
import LectureNotes from '../components/LectureNotes'
import Videocomponent from '../components/Videocomponent'
import EmptyCard from "./emptyCard";
import { useHistory } from 'react-router-dom'
import { currentTypes, fetchLibraryData, updatePageNumber, updateSelectedTopic, updateSetSort, updateSetType, updateTeacherLibraryInfo } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import UploadModal from "../modals/uploadModel";
import Uploadfiles from "../modals/uploadfiles";
import Videouploadmodel from "../modals/Videouploadmodel";
import Succesfulmodel from "../modals/Succesfulmodel";
import VideoTypeModal from '../modals/videoTypeModal'
import PageHeader from 'components/pageHeader'
import { Button } from 'components/Button'
import { InputSearch } from 'components/customInputs'
import NoDataCard from '../components/noDataCard'
const { TabPane } = Tabs

const ContentPage = ({ updateSelectedTopic, selectedTopic, updateSetType, fetchLibraryData, currentType, totalCountList,
    getTeacherLibraryList, arrStoreData, user, key, currentTypes, updateSetSort, libraryData, videoPremium, updateTeacherLibraryInfo,
    updatePageNumber, setTabType
}) => {

    console.log("AA-values",
    totalCountList,
  );
    const history = useHistory();
    const [tabKey, setTabKey] = useState(null);
    const [modalUpload, setModalUpload] = useState(false);
    const [uploadFileModal, toggleUploadFileModel] = useState(false);
    const [modal, setmodel] = useState(false);
    const [type, setType] = useState("");
    const [pageLoader, tooglePageLoader] = useState(false)
    const [search, setSearch] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [activeSort, setActiveSort] = useState([false, false,false])
    const [isVideoTypeModal, setIsVideoTypeModal] = useState(false)
    const [videoType, setVideoType] = useState('')
    const [isSearch,setIsSearch] = useState('');
    const handleHistory = () => {
        updateSetType('lecture-notes')
        updateSelectedTopic({})
        history.goBack()
    }


    const onSearchTitle = (value) => {
        setIsSearch(value)
        setSearch(value)
        tooglePageLoader(true)
        if (tabKey == 1) {
            updateSetType("lecture-notes");
            fetchLibraryData(value, () => tooglePageLoader(false));
        }
        else if (tabKey == 2) {
            updateSetType("video");
            fetchLibraryData(value, () => tooglePageLoader(false));
        }
        else if (tabKey == 3) {
            updateSetType("question-bank");
            fetchLibraryData(value, () => tooglePageLoader(false))
        }
    }

    const onChangeTitle = (e) => {
        setSearch(e)
    }

    useEffect(() => {
        tooglePageLoader(true)
        setSearch('')
        updateSetSort('')
        updateTeacherLibraryInfo([])
        updatePageNumber(3);
        if (currentType == "lecture-notes") {
            setTabKey("1");
            updateSetType(currentType)
        } else if (currentType == "video") {
            setTabKey("2");
            updateSetType(currentType)
        } else if (currentType == "question-bank") {
            setTabKey("3");
            updateSetType(currentType)
        }
        fetchLibraryData('', () => tooglePageLoader(false));
    }, [])

    useEffect(()=>{
        // tooglePageLoader(true)
        if (currentType == "lecture-notes") {
            setTabKey("1");
            updateSetType(currentType)
        } else if (currentType == "video") {
            setTabKey("2");
            updateSetType(currentType)
        } else if (currentType == "question-bank") {
            setTabKey("3");
            updateSetType(currentType)
        }
        // fetchLibraryData('', () => tooglePageLoader(false));
    },[currentType])

    const handleTabChange = (key) => {
        setSearch('')
        updateSetSort('')
        setActiveSort([false, false,false])
        setTabKey(key);
        updateTeacherLibraryInfo([])
        tooglePageLoader(true)
        if (key == 1) {
            setIsSearch('')
            currentTypes("lecture-notes")
            updateSetType("lecture-notes");
            fetchLibraryData('', () => tooglePageLoader(false));
        }
        else if (key == 2) {
            setIsSearch('')
            currentTypes("video")
            updateSetType("video");
            fetchLibraryData('', () => tooglePageLoader(false));
        }
        else if (key == 3) {
            setIsSearch('')
            currentTypes("question-bank")
            updateSetType("question-bank");
            fetchLibraryData('', () => tooglePageLoader(false))
        }

    };

    const handleSortBy = (sortBy, index) => {
        updateTeacherLibraryInfo([])
        if(index == 0){
            setActiveSort([true,false,false])
            activeSort[1] = false
            activeSort[2] = false
            updateSetSort(sortBy);
        }else if(index == 1){
            setActiveSort([false,true,false])
            activeSort[0] = false
            activeSort[2] = false
            updateSetSort(sortBy)
        }else if(index == 2){
            setActiveSort([false,false,true])
            activeSort[0] = false
            activeSort[1] = false
            updateSetSort(sortBy)
        }
        // if (index == 0) {
        //     activeSort[1] = false
        //     if (activeSort[0]) {
        //         updateSetSort('')
        //         activeSort[0] = !activeSort[0]
        //     }
        //     else {
        //         updateSetSort(sortBy)
        //         activeSort[0] = !activeSort[0]
        //     }

        // }
        // else if (index == 1) {
        //     activeSort[0] = false
        //     if (activeSort[1]) {
        //         updateSetSort('')
        //         activeSort[1] = !activeSort[1]

        //     }
        //     else {
        //         updateSetSort(sortBy)
        //         activeSort[1] = !activeSort[1]

        //     }
        // }
        // setActiveSort([...activeSort])
        tooglePageLoader(true)
        fetchLibraryData(search, () => tooglePageLoader(false))
    }

    const handleBackPress = () => {
        history.goBack();
    }

    const actions = [
        (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
        <Button type="primary" onClick={()=>{setModalUpload(true)}} style={{marginRight: 10}} icon={<PlusCircleOutlined/>}>UPLOAD</Button> : null,
        <InputSearch value={isSearch} allowClear onClear={(e)=>{onSearchTitle('')}} placeholder='Search by title...' style={{width: 200}} onInputChange={(e)=>{onSearchTitle(e)} }/>,
    ]
    console.log("AA-isSearch1",isSearch);

    return (
        <div>
            <div className="r-c-sb" style={{ height: "50px" }}>
                <div
                    style={{
                        width: "10%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {/* <PageHeader
                        title={
                            <Typography.Text
                                className="text-md"
                                style={{ fontSize: "8px !important", height: "40px", width: '300px' }}
                                ellipsis={{ tooltip: true }}
                            >
                                {selectedTopic?.topicName}
                            </Typography.Text>
                        }
                        onBack={() => handleHistory()}
                        backIcon={<ArrowLeftOutlined />} 
                    />*/}
                    <PageHeader title={selectedTopic?.topicName} onBackPress={()=>handleBackPress()} actions={actions}/>

                </div>
                {/* <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}> */}
                    {/* {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                        <Button
                            type='primary'
                            className="m-r-10"
                            style={{
                                borderRadius: "20px",
                                width: "120px",
                                backgroundColor: "#594099",
                                color: "white",
                            }}
                            onClick={() => setModalUpload(true)}
                        >
                            + Upload
                        </Button>
                        : null} */}
                    {(modalUpload && <UploadModal visible={modalUpload} setVisible={setModalUpload} toggleUploadFileModel={toggleUploadFileModel} setType={setType} setIsVideoTypeModal={setIsVideoTypeModal} setVideoType={setVideoType} />)}
                    {videoPremium ? (isVideoTypeModal && <VideoTypeModal visible={isVideoTypeModal} setVisible={setIsVideoTypeModal} setVideoType={setVideoType} toggleUploadFileModel={toggleUploadFileModel} />) : null}
                    {videoType == 'videoLink' ? (uploadFileModal && <Videouploadmodel type={type} classess={arrStoreData[0]} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} contentPage={true} setTabKey={setTabKey} />) : (uploadFileModal && <Uploadfiles classess={arrStoreData[0]} type={type} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} contentPage={true} setTabKey={setTabKey} />)}
                    {/* <div className="input-search-textk">
                        <Input.Search
                            placeholder="Search By Title"
                            bordered="false"
                            allowClear="true"
                            value={search}
                            onChange={onChangeTitle}
                            onSearch={onSearchTitle}
                        />
                    </div> */}
                {/* </div> */}
            </div>
            {/* <Row>
                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <div className="m-r-10 m-t-10">
                        <LibraryCardDetails title={"Lecture Notes"} filesNO={totalCountList && totalCountList[0]?.notesCount ? totalCountList[0]?.notesCount : 0} images={lecturenotes} lastWeek={libraryData?.lastWeekLectureNotesCount} />
                    </div>

                </Col>
                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <div className="m-r-10 m-t-10">
                        <LibraryCardDetails title={"Videos"} filesNO={totalCountList && totalCountList[0]?.videoCount ? totalCountList[0]?.videoCount : 0} images={videoImg} lastWeek={libraryData?.lastWeekVideoCount} />
                    </div>

                </Col>
                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                    <div className="m-r-10 m-t-10">
                        <LibraryCardDetails title={"Question Banks"} filesNO={totalCountList && totalCountList[0]?.quesBankCount ? totalCountList[0]?.quesBankCount : 0} images={questBankImg} lastWeek={libraryData?.lastWeekQuestionBankCount} />
                    </div>

                </Col>
            </Row> */}
            {totalCountList?.[0]?.notesCount == 0 && totalCountList?.[0]?.videoCount == 0 && totalCountList?.[0]?.quesBankCount == 0 ?
            <div className='m-t-100'><NoDataCard/></div>
            :
                <div>
                    <Tabs activeKey={tabKey} onTabClick={handleTabChange}>
                        {totalCountList?.[0]?.notesCount > 0 ?
                        <TabPane tab={"Notes"+" "+"("+totalCountList?.[0]?.notesCount+")"} key="1">
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px' }} className='text-heading-library'>Sort By:
                                    <Button type={activeSort[0] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('popularity', 0)}>Popularity</Button>
                                    <Button type={activeSort[1] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('alphabetic', 1)}>Alphabetical order</Button>
                                    <Button type={activeSort[2] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('newtoold', 2)}>Newest to oldest</Button>
                                </div>

                                {getTeacherLibraryList?.length != 0 ?
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                        {getTeacherLibraryList?.map((item, index) => (
                                            <LectureNotes
                                                title={item?.title}
                                                file={item?.file}
                                                id={item?.id}
                                                // filesSize={item.size}
                                                types={"lecture-notes"}
                                                img={lecturenotes}
                                                item={item}
                                            />
                                        ))}
                                    </div>

                                    : (
                                        <EmptyCard />
                                    )}
                            </div>
                        </TabPane>:<></>}
                        {totalCountList?.[0]?.videoCount > 0 ?
                        <TabPane tab={"Videos"+" "+"("+totalCountList?.[0]?.videoCount+")"} key="2">
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <div style={{ width: '600px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px' }} className='text-heading-library'>Sort By:
                                    <Button type={activeSort[0] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('popularity', 0)}>Popularity</Button>
                                    <Button type={activeSort[1] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('alphabetic', 1)}>Alphabetical order</Button>
                                    <Button type={activeSort[2] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('newtoold', 2)}>Newest to oldest</Button>
                                </div>

                                {getTeacherLibraryList?.length != 0 ?
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                        {getTeacherLibraryList?.map((item, index) => (
                                            <LectureNotes
                                                title={item?.title}
                                                file={item?.fileName}
                                                id={item?.id}
                                                // filesSize={item.size}
                                                types={"video"}
                                                // img={lecturenotes}
                                                item={item}
                                                link={item?.link}


                                            />
                                        ))}

                                    </div>

                                    : (
                                        <EmptyCard />
                                    )}
                            </div>
                        </TabPane>:<></>}
                        {totalCountList?.[0]?.quesBankCount > 0 ?
                        <TabPane tab={"Question Banks"+" "+"("+totalCountList?.[0]?.quesBankCount+")"} key="3">
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <div style={{ width: '600px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px' }} className='text-heading-library'>Sort By:
                                    <Button type={activeSort[0] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('popularity', 0)}>Popularity</Button>
                                    <Button type={activeSort[1] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('alphabetic', 1)}>Alphabetical order</Button>
                                    <Button type={activeSort[2] ? 'primary' : 'default'} style={{ width: '170px', margin: '8px' }} onClick={() => handleSortBy('newtoold', 2)}>Newest to oldest</Button>
                                </div>

                                {getTeacherLibraryList?.length != 0 ?
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                        {getTeacherLibraryList?.map((item, index) => (
                                            <LectureNotes
                                                title={item?.title}
                                                file={item?.file}
                                                id={item?.id}
                                                // filesSize={item.size}
                                                types={"question-bank"}
                                                img={questBankImg}
                                                item={item}
                                            />
                                        ))}
                                    </div>

                                    : (
                                        <EmptyCard />
                                    )}
                            </div>
                        </TabPane>:<></>}
                    </Tabs>
                </div>
}
            <PageDataLoader visible={pageLoader} />
            <Succesfulmodel togglePageLoader={tooglePageLoader} fetchApi={true} videoType={videoType}/>
        </div>
    )
}
const mapStateToProps = (state) => {
    const {
        selectedTopic,
        currentType,
        totalCountList,
        getTeacherLibraryList,
        arrStoreData, key,
        libraryData,
        videoPremium,
        setTabType
    } = state.digitalLibrary;
    const { user } = state.session
    return {
        user,
        selectedTopic,
        currentType,
        totalCountList,
        getTeacherLibraryList,
        arrStoreData,
        key,
        libraryData,
        videoPremium,
        setTabType
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSelectedTopic: (object) => dispatch(updateSelectedTopic(object)),
    updateSetType: (type) => dispatch(updateSetType(type)),
    fetchLibraryData: (search, callback) => dispatch(fetchLibraryData(search, callback)),
    currentTypes: (type) => dispatch(currentTypes(type)),
    updateSetSort: (sort) => dispatch(updateSetSort(sort)),
    updateTeacherLibraryInfo: (data) => dispatch(updateTeacherLibraryInfo(data)),
    updatePageNumber:(data)=>dispatch(updatePageNumber(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentPage);