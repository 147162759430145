import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import EditCourseModal from "./editCourseModal";
import NoCourseImage from "../../Assets/course/ill-courses.png";
import CourseEditModal from "./courseEditModal";
import { EditFilled, LoadingOutlined, PlusCircleOutlined, StopOutlined } from "@ant-design/icons";
import AddCourseModal from "./addCourseModal";
import CourseDeleteConfirmModal from "./courseDeleteConfirmModal";
import { Input, Select, Typography, Table, Progress, Row, Col } from "antd";
import moment from "moment";
import { setCoursePrice, setInstallments } from "actions/course";
import PageDataLoader from "components/pageDataLoader";
import { setFeeDetails } from "actions/feeManagement";
import CourseDetailsModal from "./courseDetailsModal";
import UnAssignConfirmModal from "./unAssignConfirmModal";
import PreferedCourseCard from "./preferedCourseCard";
import { Notification } from "services";
import FeeCourseCardNew from "./feeCourseCardNew";
import ScholarshipAndAdditionModal from "./scholarshipAndAdditionModal";
import { setCourseDetails, getStudentsRollNo, getCourseDetalis, updateScholarshipOrAddition, unassignStudent, setRollNumber, UpdateRollNumber, getStudentsDetailsById } from "actions/userManagement";
import AddAClass from "./modals/addAClass";
import { Button } from "components/Button";
import { Heading } from "components/Typography";
import { SubIcon } from "components/subIcon";
import EditClass from "./modals/editClass";
import { storeStudentRollNo, unAssignStudentfromStd } from "actions/userManagementV2";

const courseIcon = require('Assets/ic-course.svg').default;
const NoData = require("../../Assets/ill-no-data.svg").default;


const columns = () => {
        return [
                {
                        title: 'No',
                        dataIndex: 'paymentId',
                        key: 'paymentId',
                        render: (id, record, index) => index + 1
                },
                {
                        title: 'Request Details',
                        dataIndex: 'name',
                        key: 'name',
                        width: '20%',
                        render: (id, item, index) => (
                                <FeeCourseCardNew
                                        type='Note'
                                        item={item}
                                        // status="Pending"
                                        amount={item?.amountPayable}
                                        dueDate={item?.dueDate}
                                        issueDate={item?.date}
                                        status={item?.status}
                                />
                        )
                },
                {
                        title: 'Amount',
                        dataIndex: 'amountPayable',
                        key: 'amountPayable',
                        render: (value, record) => <div>₹ {record ? (record?.amountPayable).toFixed(2) : 0}</div>
                },
                {
                        title: 'Issue Date',
                        dataIndex: 'issueDate',
                        key: 'issueDate',
                        render: (value, record) => <div>{record?.status !== 'Not Requested' ? moment(record?.date, 'DD-MM-YYYY').format('DD-MM-YYYY') : '-'}</div>
                },
                {
                        title: 'Due Date',
                        dataIndex: 'dueDate',
                        key: 'dueDate',
                        render: (value, record) => (<div> {record.dueDate
                                ? moment(record.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                                : "-"}</div>)
                },
                {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (value, item) => {
                                return (
                                        <FeeCourseCardNew
                                                type='Status'
                                                item={item}
                                                // status="Pending"
                                                amount={item?.amountPayable}
                                                dueDate={item?.dueDate}
                                                issueDate={item?.date}
                                                status={item?.status}
                                        />
                                )
                        }
                },
                {
                        title: 'Action',
                        dataIndex: 'status',
                        key: "status",
                        render: (value, item) => {
                                return (
                                        <FeeCourseCardNew
                                                type='Action'
                                                item={item}
                                                amount={item?.amountPayable}
                                                dueDate={item?.dueDate}
                                                issueDate={item?.date}
                                                status={item?.status}
                                        />
                                )
                        }
                },
        ]
}

const NoDataCard = () => {
        return (
                <div className='r-c-c-c' style={{ position: 'absolute', left: '30%', top: '10%' }}>
                        <img src={NoData} />
                        <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</div>
                        <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no classes assigned. Please check back later.</div>
                </div>
        )
}

function CourseDetailsNew({
        feeMangementData,
        setCourseDetails,
        studentCourseDetails,
        userDetailsById,
        getStudentsRollNo,
        nextRollNo,
        setInstallments,
        setCoursePrice,
        userCourseUpdate,
        updateScholarshipOrAddition,
        feeDetails,
        setRollNumber,
        UpdateRollNumber,
        user,
        unAssignStudentfromStd,
        storeStudentRollNo,
        studentRollNo,
        getStudentsDetailsById
}) {
        const history = useHistory();



        function numberWithCommas(x) {
                return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
        }

        const [isModalVisible, setIsModalVisible] = useState(false);
        const [isAdditionModal, setIsAdditionModal] = useState(false);
        const [isCourseModalVisible, setIsCourseModalVisible] = useState(false);
        const [showCourseDetailsModal, setShowCourseDetailsModal] = useState(false);
        const [unAssignLdr, setUnAssignLdr] = useState(false);
        const [viewCourseDetails, setViewCourseDetails] = useState(feeDetails == true ? true : false);
        const [viewAddCourseModal, setViewAddCourseModal] = useState(false);
        const [viewEditCourseModal, setViewEditCourseModal] = useState(false);
        const [pageLoader, setPageLoader] = useState(false);
        const [modalType, setModalType] = useState(true);
        const [viewCourseDeleteConfirmModal, setViewCourseDeleteConfirmModal] = useState(false);
        const [filteredSections, setFilteredSections] = useState([]);
        const [unAssignModal, setUnassignModal] = useState(false);
        const [finalPayment, setFinalPayment] = useState(studentCourseDetails?.feeAmount);
        const [rollNoLoader, setRollNoLoader] = useState(false);

        const [addClassModal, setAddClassModal] = useState(false);
        const [updateVisible, setUpdateVisible] = useState(false);
        const [selectedClass, setSelectedClass] = useState(null);
        const [editClassModal, setEditClassModal] = useState(false);


        useEffect(() => {
                if (studentCourseDetails?.isSectionChanged?.courseUpdate?.coursePopUp === true) {
                        if (studentCourseDetails?.sectionCoursePop === true) {
                                setViewAddCourseModal(true);
                        }
                }
        }, [studentCourseDetails?.isSectionChanged]);

        useEffect(() => {
                setFeeDetails(feeDetails == true ? true : false);
        }, [feeDetails]);

        useEffect(() => {
                let temp = 0;
                for (let i = 0; i < studentCourseDetails?.scholarships?.length; i++) {
                        temp += parseFloat(studentCourseDetails?.scholarships[i]?.amountOrPercentage)
                }
                let removedTemp = 0;
                for (let i = 0; i < studentCourseDetails?.removedScholarships?.length; i++) {
                        removedTemp += parseFloat(
                                studentCourseDetails?.removedScholarships[i]?.amountOrPercentage
                        );
                }
                temp = temp ? parseFloat(temp) / 100 : 0;
                removedTemp = removedTemp ? parseFloat(removedTemp) / 100 : 0;
                let temp1 = studentCourseDetails?.additions?.[0]?.amountOrPercentage ? parseFloat(studentCourseDetails?.additions?.[0]?.amountOrPercentage) : 0;

                setFinalPayment(parseFloat(studentCourseDetails?.feeAmount) - studentCourseDetails?.feeAmount * temp + temp1);

                let tempArr = [];

                for (let i = 0; i < studentCourseDetails?.selectedCourse?.transactions?.length; i++) {
                        let sumPercentage = 0;
                        studentCourseDetails?.scholarships?.map((item) => (sumPercentage += parseFloat(item?.amountOrPercentage)));

                        let addAmount = studentCourseDetails?.additions?.[0]?.amountOrPercentage ? parseFloat(studentCourseDetails?.additions?.[0]?.amountOrPercentage) : 0;
                        let totalAmount = addAmount - (sumPercentage / 100) * studentCourseDetails?.feeAmount;


                        if (studentCourseDetails?.scholarshipsRemoved) {
                                totalAmount = totalAmount - (sumPercentage / 100) * studentCourseDetails?.feeAmount;
                        }

                        let forTransaction = totalAmount / studentCourseDetails?.selectedCourse?.transactions?.length;

                        tempArr[i] = {
                                installment: parseFloat(studentCourseDetails?.selectedCourse?.transactions[i]?.amount) + forTransaction,
                                dueDate: studentCourseDetails?.selectedCourse?.transactions[i]?.dueDate,
                        };
                }
                setInstallments(tempArr);
        }, [
                studentCourseDetails?.feeAmount,
                studentCourseDetails?.scholarships?.length,
                studentCourseDetails?.additions?.[0]?.amountOrPercentage,
                finalPayment,
        ]);

        const closeModal = () => {
                setIsModalVisible(false);
                setIsAdditionModal(false);
        };

        useEffect(() => {
                setCourseDetails("scholarships", []);
                setCourseDetails("additions", []);
                setCourseDetails(
                        "feeAmount",
                        studentCourseDetails?.selectedCourse?.totalFee
                );
                setCourseDetails(
                        "totalPayable",
                        studentCourseDetails?.selectedCourse?.totalPayable
                );
                setCourseDetails(
                        "feePlan",
                        studentCourseDetails?.selectedCourse?.durationType
                );
                setInstallments(
                        studentCourseDetails?.selectedCourse?.installmentDetails
                                ? JSON.parse(studentCourseDetails?.selectedCourse?.installmentDetails)
                                : []
                );

                setCourseDetails(
                        "scholarships",
                        studentCourseDetails?.selectedCourse?.scholarshipDetails
                                ? JSON.parse(studentCourseDetails?.selectedCourse?.scholarshipDetails)
                                : []
                );
                setCourseDetails(
                        "additions",
                        studentCourseDetails?.selectedCourse?.additonDetails
                                ? JSON.parse(studentCourseDetails?.selectedCourse?.additonDetails)
                                : []
                );
                setCoursePrice(studentCourseDetails?.selectedCourse?.totalFee);
        }, [studentCourseDetails?.selectedCourse]);


        useEffect(() => {
                let temp = userDetailsById?.student?.standards?.filter((item) => {
                        if (item?.std == studentCourseDetails?.class) {
                                return item;
                        }
                });

                if (temp != []) {
                        setFilteredSections(temp);
                }

        }, [studentCourseDetails?.class]);

        useEffect(() => {
                setCourseDetails("class", userDetailsById?.student?.standard?.std);
                setCourseDetails("section", userDetailsById?.student?.standard?.section);
                setCourseDetails("sectionId", userDetailsById?.student?.standard?.id);

                setCourseDetails(
                        "scholarships",
                        userDetailsById?.student?.feeDetails?.scholarshipDetails
                                ? JSON.parse(userDetailsById?.student?.feeDetails?.scholarshipDetails)
                                : []
                );
                setCourseDetails(
                        "additions",
                        userDetailsById?.student?.feeDetails?.additonDetails
                                ? JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)
                                : []
                );
                setCourseDetails(
                        "savedSholarshipLength",
                        studentCourseDetails?.scholarships?.length
                                ? studentCourseDetails?.scholarships?.length
                                : 0
                );
                setCourseDetails("removedScholarships", []);
        }, [userDetailsById?.student]);

        useEffect(() => {
                setCourseDetails("feeAmount", userDetailsById?.student?.feeDetails?.totalFee);
        }, [userDetailsById]);

        useEffect(() => {
                setCourseDetails("ApiC", !viewCourseDetails);
        }, [viewCourseDetails]);

        const handlerollNoEdit = (item) => {
                setSelectedClass(item);
                setEditClassModal(true);
        }

        console.log({ selectedClass })

        // const handleUnassign = (item) => {
        //         setSelectedClass(item);
        //         setTimeout(() => {
        //                 unAssignStudentfromStd({ userId: userDetailsById?.id, standardId: selectedClass?.standardId }, () => { });
        //         }, 1000);
        // }

        if (viewCourseDetails) {
                return (
                        <div
                                style={{
                                        width: "100%",
                                        height: "90vh",
                                        overflowY: "auto",
                                        padding: "10px",
                                }}
                                className='scroll-bar-universal'
                        >
                                {/* <div
                                        className=""
                                        style={{
                                                height: "fit-content",
                                                padding: "40px",
                                                overflowY: "auto",
                                                
                                        }}
                                >
                                        <div
                                                style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        marginBottom: "40px",
                                                }}
                                        >
                                                <div
                                                        style={{
                                                                fontSize: "18px",
                                                                fontFamily: "roboto",
                                                                fontWeight: "bold",
                                                        }}
                                                >
                                                        Class details
                                                </div>
                                                {userDetailsById?.student?.standardId != -1 ? (<Button loading={unAssignLdr} onClick={() => { setShowUnassign(true) }} style={{ borderRadius: "20px", border: "1px solid red", color: "red", fontSize: "10px", fontWeight: "700", height: "26px", paddingTop: "2px" }}>UNASSIGN</Button>) : null}
                                        </div>
                                        <div className="flex_row" style={{ width: "100%", justifyContent: "space-between" }}>
                                                <div style={{ width: "50%" }}>
                                                        <div style={{ width: "100%" }}>
                                                                <p className="dropDownLabelBasicStyle">Class</p>
                                                                <Select
                                                                        allowClear
                                                                        size="middle"
                                                                        placeholder="Select  Class"
                                                                        style={{ width: "97%", marginBottom: "20px" }}
                                                                        onChange={(e) => {
                                                                                setCourseDetails("class", e);
                                                                                setCourseDetails("section", null);
                                                                                setCourseDetails("sectionId", null);
                                                                        }}
                                                                        getPopupContainer={(triggerNode) => { return triggerNode.parentNode }}
                                                                        value={studentCourseDetails?.class ? studentCourseDetails?.class : null}
                                                                        disabled={!(user?.privilage.includes("SuperAdmin") || user?.privilage.includes("Admin"))}
                                                                >
                                                                        {userDetailsById?.student?.standards?.map((item, index) => (
                                                                                <Option value={item?.std}>{item?.std}</Option>
                                                                        ))}
                                                                </Select>
                                                        </div>
                                                        <div style={{ width: "97%" }}>
                                                                <p className="dropDownLabelBasicStyle">Roll number</p>

                                                                <div className="display-flex">
                                                                        <Input
                                                                                size="middle"
                                                                                style={{ width: "100%", color: "black" }}
                                                                                disabled={(studentCourseDetails?.class == undefined || studentCourseDetails?.class == null) || (studentCourseDetails?.section == undefined || studentCourseDetails?.section == null) ? true : false}
                                                                                value={studentCourseDetails?.class && studentCourseDetails?.section ? nextRollNo : null}
                                                                                onChange={(e) => {
                                                                                        if (/[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/.test(e?.target?.value)) {
                                                                                                Notification.error('Error', "Please enter roll no without special characters")
                                                                                        } else {
                                                                                                setRollNumber(e.target.value.trim())
                                                                                        }
                                                                                }}
                                                                                placeholder="Enter Roll number"
                                                                        >
                                                                        </Input>
                                                                        <Button disabled={(studentCourseDetails?.class == undefined || studentCourseDetails?.class == null) || (studentCourseDetails?.section == undefined || studentCourseDetails?.section == null) || !nextRollNo ? true : false} onClick={() => {
                                                                                if (nextRollNo == 0) {
                                                                                        Notification.error('Error', 'Roll number cannot be 0')
                                                                                } else {
                                                                                        UpdateRollNumber();
                                                                                }
                                                                        }} className='purpleBtnWrap radius-100 purpleButtonHoverEffect m-l-10'>Update</Button>
                                                                </div>
                                                                {rollNoLoader ? (
                                                                        <div
                                                                                style={{ marginTop: "-28px", marginLeft: "calc(100% - 160px)" }}>
                                                                                <LoadingOutlined style={{ fontSize: "20px" }} />
                                                                        </div>
                                                                ) : null}
                                                        </div>
                                                </div>
                                                <div style={{ width: "47%" }}>
                                                        <p className="dropDownLabelBasicStyle">Section</p>
                                                        <Select
                                                                placeholder="Select Section"
                                                                style={{ width: "100%" }}
                                                                size="middle"
                                                                onChange={(e) => {
                                                                        setCourseDetails("section", e);
                                                                        setCourseDetails("sectionId", e);
                                                                        setRollNoLoader(true);
                                                                        getStudentsRollNo(e, () => setRollNoLoader(false), true);
                                                                }}
                                                                value={studentCourseDetails?.section !== -1 ? studentCourseDetails?.section : null}
                                                                disabled={!(user?.privilage.includes("SuperAdmin") || user?.privilage.includes("Admin"))}
                                                                getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }}
                                                        >
                                                                {filteredSections?.[0]?.sectionNameArray?.map((item, index) => (
                                                                        <Option value={filteredSections?.[0]?.sectionIdArray[index]}>
                                                                                {item}
                                                                        </Option>
                                                                ))}
                                                        </Select>
                                                </div>
                                        </div>
                                </div> */}


                                <div className="scroll-bar-universal" style={{ height: '40vh' }}>
                                        <Row style={{ justifyContent: 'space-between', width: '100%' }}>
                                                <Heading>Classroom</Heading>
                                                <Button onClick={() => setAddClassModal(true)} style={{ padding: 0, fontSize: 14 }} type='link' icon={<PlusCircleOutlined />}>Add a class</Button>
                                        </Row>
                                        {userDetailsById?.student?.studentStandards?.length === 0 ? (
                                                <div><NoDataCard /></div>
                                        ) : (
                                                userDetailsById?.student?.studentStandards?.map((item, index) => {
                                                        return (
                                                                <div key={index} style={{ width: '99%', marginTop: 20, padding: '0 20px 0 20px' }}>
                                                                        <Row gutter={16} style={{ borderBottom: '1px solid #ccc', paddingBottom: '20px', justifyContent: 'space-between' }}>
                                                                                <Row style={{ width: '40%', marginLeft: 10 }}>
                                                                                        <Col>
                                                                                                <div>
                                                                                                        <SubIcon name={item?.std} size={60} alt="standard Icon" />
                                                                                                </div>
                                                                                        </Col>
                                                                                        <Col className='m-l-5'>
                                                                                                <div>
                                                                                                        <div className="simGrey bold-500">Class</div>
                                                                                                        <div style={{ width: '100%' }}>
                                                                                                                <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, fontWeight: 600 }}>{item?.std || 'NA'}</Typography.Text>
                                                                                                        </div>

                                                                                                </div>
                                                                                        </Col>
                                                                                </Row>
                                                                                <Col>
                                                                                        <div>
                                                                                                <div className="simGrey bold-500">Section</div>
                                                                                                <div style={{ fontSize: 16, fontWeight: 600 }}>{item?.section || 'NA'}</div>
                                                                                        </div>
                                                                                </Col>
                                                                                <Col>
                                                                                        <div>
                                                                                                <div className="simGrey bold-500">Roll no</div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                        <div style={{ fontSize: 16, fontWeight: 600 }}>{item?.rollNo || 'NA'}</div>
                                                                                                        <Button type='link' style={{ padding: 0, marginLeft: 5, fontSize: 14 }} icon={<EditFilled />} onClick={() => handlerollNoEdit(item)} />
                                                                                                </div>
                                                                                        </div>
                                                                                </Col>
                                                                                <Button type='delete' onClick={() => /* handleUnassign(item) */ { setUnassignModal(true); setSelectedClass(item) }} style={{ marginTop: 10 }}>UNASSIGN</Button>

                                                                        </Row>
                                                                </div>
                                                        );
                                                }))}
                                </div>








                                <div className="" style={{}}>
                                        <div
                                                style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        padding: "40px",
                                                        paddingTop: "30px",
                                                }}
                                        >
                                                <div style={{ fontWeight: "600", fontSize: "14px" }}>Course(s)</div>
                                                {userDetailsById?.student?.userAssignedCourses &&
                                                        userDetailsById?.student?.userAssignedCourses?.length != 0 ? (
                                                        <Button type="link" onClick={() => setViewAddCourseModal(true)} style={{ fontWeight: "650", fontSize: "12px", color: "#1089FF", cursor: "pointer" }}>
                                                                + ADD A COURSE
                                                        </Button>
                                                ) : null}
                                        </div>
                                        {
                                                <div className="flex_row_center" style={{ marginTop: "0px", flexWrap: "wrap", paddingBottom: "20px", }}>
                                                        {userDetailsById?.student?.preferredCourseCard?.preferredCourseCard == true ? (
                                                                <div>
                                                                        <PreferedCourseCard
                                                                                item={userDetailsById?.student?.preferredCourseCard}
                                                                                setViewAddCourseModal={setViewAddCourseModal}
                                                                                setCourseDetails={setCourseDetails}
                                                                        />
                                                                </div>
                                                        ) : null}
                                                        {userDetailsById?.student?.userAssignedCourses &&
                                                                userDetailsById?.student?.userAssignedCourses?.map((course, index) => (
                                                                        <>
                                                                                <div
                                                                                        onClick={() => {
                                                                                                setShowCourseDetailsModal(true);
                                                                                                setCourseDetails("selectedCourse", course);
                                                                                        }}
                                                                                        style={{ width: '100%' }}
                                                                                >
                                                                                        <div key={index} style={{ width: '100%' }}>
                                                                                                <div className="r-jsb m-t-20" style={{}}>
                                                                                                        <div className="display-flex" style={{ alignItems: "center" }}>
                                                                                                                <img src={courseIcon} width={64} height={64} alt="" />
                                                                                                                <div className='m-l-20'>
                                                                                                                        <div style={{ fontSize: 16, fontWeight: 500 }}>{course?.courseName}</div>
                                                                                                                        <div style={{ color: "#636363" }}>{course?.courseType == 'course' ? 'Live Course' : course?.courseType == 'studyMaterial' ? 'Study Material' : course?.courseType == 'videoResource' ? 'Video Course' : 'Online Test Series'}</div>
                                                                                                                        {!course?.startDate && !course?.endDate ? null :
                                                                                                                                <div style={{ color: "#636363" }}>{course?.startDate ? moment(course?.startDate).format("DD/MM/YYYY") : "NA"}</div>
                                                                                                                        }
                                                                                                                        {/* {course.inactive ? <div style={{ fontSize: 12, color: "#FF414D", fontWeight: 600 }}><StopOutlined /> Inactive</div> : null} */}
                                                                                                                </div>
                                                                                                        </div>
                                                                                                        {course?.progress >= 0 ?
                                                                                                                <div style={{ width: "15vw" }} >
                                                                                                                        <Progress percent={course?.progress} showInfo={false} />
                                                                                                                        <div className='r-jsb' style={{ color: "#636363", fontSize: 12 }}>
                                                                                                                                <div>Completed</div>
                                                                                                                                <div>{course?.progress}%</div>
                                                                                                                        </div>
                                                                                                                </div> : null}
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                                <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20 }} />
                                                                        </>
                                                                ))}
                                                        {userDetailsById?.student?.userAssignedCourses?.length == 0 ? (
                                                                <div
                                                                        className="flex_column_center"
                                                                        style={
                                                                                userDetailsById?.student?.userAssignedCourses?.length == 0
                                                                                        ? {
                                                                                                width: "100%",
                                                                                                marginLeft: "5%",
                                                                                                padding: "20px",
                                                                                                margin: "20px",
                                                                                        }
                                                                                        : {
                                                                                                width: "330px",
                                                                                                padding: "20px",
                                                                                                margin: "20px",
                                                                                                marginLeft: "20px",
                                                                                                height: "310px",
                                                                                        }
                                                                        }
                                                                >
                                                                        <div className="flex_row_center" style={{ justifyContent: "center" }}>
                                                                                <img width="80%" src={NoCourseImage} alt="" />
                                                                        </div>
                                                                        <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "25px", textAlign: "center" }}>
                                                                                {userDetailsById?.student?.userAssignedCourses?.length == 0
                                                                                        ? "No course assigned!"
                                                                                        : "Add more courses"}
                                                                        </div>
                                                                        {userDetailsById?.student?.userAssignedCourses?.length ==
                                                                                0 ? (
                                                                                <div style={{ color: "#636363", fontWeight: "500", fontSize: "12px", textAlign: "center" }}>
                                                                                        There are no course(s) assigned to this student yet.
                                                                                </div>
                                                                        ) : null
                                                                        }
                                                                        <div style={{ color: "#1089FF", fontWeight: "bold", marginTop: "20px" }}>
                                                                                <Button onClick={() => {
                                                                                        if (
                                                                                                userDetailsById?.student?.standardId == -1 ||
                                                                                                userDetailsById?.student?.standardId !==
                                                                                                studentCourseDetails?.sectionId
                                                                                        ) {
                                                                                                userCourseUpdate();
                                                                                        }
                                                                                        setViewAddCourseModal(true);
                                                                                }}
                                                                                        style={{
                                                                                                borderRadius: "20px",
                                                                                                backgroundColor: "#594099",
                                                                                                fontSize: "10px",
                                                                                                color: "white",
                                                                                                fontWeight: "bold",
                                                                                        }}
                                                                                >
                                                                                        ADD A COURSE
                                                                                </Button>
                                                                        </div>
                                                                </div>
                                                        ) : null}
                                                </div>
                                        }
                                        {viewAddCourseModal && (
                                                <AddCourseModal
                                                        setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                                                        isModalVisible={viewAddCourseModal}
                                                        setViewAddCourseModal={setViewAddCourseModal}
                                                />
                                        )}
                                        {showCourseDetailsModal && (
                                                <CourseDetailsModal
                                                        item={studentCourseDetails?.selectedCourse}
                                                        isModalVisible={showCourseDetailsModal}
                                                        setShowCourseDetailsModal={setShowCourseDetailsModal}
                                                />
                                        )}
                                        <UnAssignConfirmModal open={unAssignModal} onCancel={() => setUnassignModal(false)} selectedClass={selectedClass} userData={userDetailsById} unAssignStudentfromStd={unAssignStudentfromStd} getStudentsDetailsById={getStudentsDetailsById} />
                                        {addClassModal && <AddAClass open={addClassModal} onCancel={() => setAddClassModal(false)} updateVisible={updateVisible} selectedClass={selectedClass} />}
                                        {editClassModal && <EditClass open={editClassModal} onCancel={() => setEditClassModal(false)} selectedClass={selectedClass} UpdateRollNumber={UpdateRollNumber} setCourseDetails={setCourseDetails} storeStudentRollNo={storeStudentRollNo} studentRollNo={studentRollNo} getStudentsDetailsById={getStudentsDetailsById} userData={userDetailsById}/>}

                                </div>
                        </div>
                );
        } else {
                return (
                        <div style={{ minHeight: "100%", padding: "20px", overflowY: "auto" }}>
                                <div
                                        className="flex_row_center"
                                        style={{
                                                fontSize: "18px",
                                                fontWeight: "650",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                fontFamily: "roboto",
                                        }}
                                >
                                        <div className="flex_row_center">
                                                <div
                                                        onClick={() => {
                                                                setViewCourseDetails(true);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                >
                                                </div>
                                        </div>

                                </div>
                                {console.log("abc",
                                        feeMangementData?.dashBoardDetails?.kycStatus
                                )}

                                {feeMangementData?.dashBoardDetails?.kycStatus != 'Approved' && (
                                        < div style={{ backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6', padding: 10, cursor: 'text' }}>
                                                <span>Enable KYC to request payments <span style={{ color: '#1089ff', cursor: 'pointer' }} onClick={() => { history.push("/kyc-form") }}>Update KYC</span></span>
                                        </div>
                                )
                                }
                                <div>
                                        <div
                                                style={{
                                                        fontSize: "18px",
                                                        fontWeight: "650",
                                                        marginTop: "30px",
                                                        marginBottom: "15px",
                                                }}
                                        >
                                                Fee details
                                        </div>

                                        <div className="flex_row_center" style={{ justifyContent: "space-between", width: "100%" }}>
                                                <div style={{ width: "47%" }}>
                                                        <p className="dropDownLabelBasicStyle">Total fee amount (Inclusive of all Taxes)</p>
                                                        <p>₹ {numberWithCommas(userDetailsById?.student?.feeDetails?.totalFee || 0)}</p>
                                                </div>
                                                <div style={{ width: "47%" }}>
                                                        <p className="dropDownLabelBasicStyle">Fee payment plan</p>
                                                        <p>{userDetailsById?.student?.feeDetails?.feePlan || 'NA'}</p>
                                                        <div onClick={() => { setIsCourseModalVisible(true); setModalType(true) }} className="flex_row_center" style={{ color: "#1089FF", fontWeight: "600", marginTop: "-25px", cursor: "pointer", justifyContent: "flex-end", }}>EDIT PLAN</div>
                                                </div>
                                        </div>
                                        {userDetailsById?.student?.feeDetails?.tranactionDetails && userDetailsById?.student?.feeDetails?.tranactionDetails?.length != 0 ? (
                                                <div style={{ marginTop: "20px" }}>
                                                        <p className="dropDownLabelBasicStyle">Fee amount details</p>
                                                        <div
                                                                style={{ border: "1px solid #E6E6E6", padding: "20px", borderRadius: "10px", }}>
                                                                <div className="flex_row_center" style={{ justifyContent: "space-between", fontWeight: "600" }}>
                                                                        <p>Fee amount</p>
                                                                        <p>₹ {userDetailsById?.student?.feeDetails?.totalFee && numberWithCommas(userDetailsById?.student?.feeDetails?.totalFee)} </p>
                                                                </div>
                                                                <div>
                                                                        {userDetailsById?.student?.feeDetails?.scholarshipDetails && JSON.parse(userDetailsById?.student?.feeDetails?.scholarshipDetails)?.map((item, index) => (
                                                                                <div className="flex_row_center" style={{ justifyContent: "space-between", color: "#1089FF", fontWeight: "600" }}>
                                                                                        <p>
                                                                                                <Typography.Text style={{ width: "80px", color: "#1089FF" }} ellipsis={{ tooltip: true }}>{item?.name}</Typography.Text>
                                                                                                <span
                                                                                                        onClick={() => {
                                                                                                                let temp = studentCourseDetails?.scholarships;
                                                                                                                let removedScholarships = studentCourseDetails?.removedScholarships;
                                                                                                                removedScholarships.push(temp[index]);
                                                                                                                setCourseDetails("removedScholarships", removedScholarships);
                                                                                                                setCourseDetails("scholarshipsRemoved", true);
                                                                                                                temp.splice(index, 1);
                                                                                                                setCourseDetails("scholarships", temp);
                                                                                                                setCourseDetails("removeScholarshipIndex", index);
                                                                                                                setCourseDetails("method", "remove");
                                                                                                                setCourseDetails("type", "scholarship");
                                                                                                                setPageLoader(true);
                                                                                                                updateScholarshipOrAddition(() => setPageLoader(false));
                                                                                                        }}
                                                                                                        style={{
                                                                                                                color: "#FF414D",
                                                                                                                fontSize: "8px",
                                                                                                                border: "1px solid #FF414D",
                                                                                                                borderRadius: "10px",
                                                                                                                padding: "2px",
                                                                                                                marginLeft: "5px",
                                                                                                                cursor: "pointer",
                                                                                                        }}
                                                                                                >
                                                                                                        Remove
                                                                                                </span>
                                                                                        </p>
                                                                                        <p> -₹ {item?.amount && numberWithCommas(item?.amount)}</p>
                                                                                </div>
                                                                        ))}
                                                                </div>
                                                                {userDetailsById?.student?.feeDetails?.scholarshipDetails &&
                                                                        JSON.parse(
                                                                                userDetailsById?.student?.feeDetails?.scholarshipDetails
                                                                        )?.length == 0 ? (
                                                                        <div
                                                                                className="flex_row_center"
                                                                                style={{
                                                                                        justifyContent: "space-between",
                                                                                        color: "#1089FF",
                                                                                        fontWeight: "600",
                                                                                }}
                                                                        >
                                                                                <p>Scholarship</p>
                                                                                <p onClick={() => {
                                                                                        if (studentCourseDetails?.scholarships?.length == 0) {
                                                                                                setIsModalVisible(true);
                                                                                        }
                                                                                }}
                                                                                        style={{ cursor: studentCourseDetails?.scholarships?.length > 0 ? null : "pointer" }}>-₹ ADD</p>
                                                                        </div>
                                                                ) : null}
                                                                {userDetailsById?.student?.feeDetails?.additonDetails && JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.length != 0 ? (
                                                                        <div className="flex_row_center" style={{ justifyContent: "space-between", color: "#FFA931", fontWeight: "600" }}>
                                                                                <p>
                                                                                        <Typography.Text style={{ width: "80px", color: "#FFA931" }} ellipsis={{ tooltip: true }}>
                                                                                                {JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.[0]?.name}
                                                                                        </Typography.Text>
                                                                                        <span
                                                                                                onClick={() => {
                                                                                                        let temp = studentCourseDetails?.additions;
                                                                                                        let removedScholarships = studentCourseDetails?.removedScholarships;
                                                                                                        setCourseDetails("removedScholarships", removedScholarships);
                                                                                                        setCourseDetails("scholarshipsRemoved", true);
                                                                                                        temp.splice(0, 1);
                                                                                                        setCourseDetails("additions", temp);
                                                                                                        setCourseDetails("method", "remove");
                                                                                                        setCourseDetails("type", "scholarship");
                                                                                                        setPageLoader(true);
                                                                                                        updateScholarshipOrAddition(() => setPageLoader(false));
                                                                                                }}
                                                                                                style={{
                                                                                                        color: "#FF414D",
                                                                                                        fontSize: "8px",
                                                                                                        border: "1px solid #FF414D",
                                                                                                        borderRadius: "10px",
                                                                                                        padding: "2px",
                                                                                                        marginLeft: "5px",
                                                                                                        cursor: "pointer",
                                                                                                }}
                                                                                        >
                                                                                                Remove
                                                                                        </span>
                                                                                </p>
                                                                                <p>
                                                                                        +₹ {userDetailsById?.student?.feeDetails?.additonDetails !== undefined && JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.length != 0 ? numberWithCommas(JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)?.[0]?.amount) : "ADD"}
                                                                                </p>
                                                                        </div>
                                                                ) : (
                                                                        <div className="flex_row_center" style={{ justifyContent: "space-between", color: "#FFA931", fontWeight: "600" }}>
                                                                                <p>Addition</p>
                                                                                <p onClick={() => { setIsModalVisible(true); setIsAdditionModal(true) }} style={{ cursor: "pointer" }}> +₹ ADD </p>
                                                                        </div>
                                                                )}
                                                                <div className="flex_row_center" style={{ justifyContent: "space-between", borderTop: "1px solid #E6E6E6", borderBottom: "1px solid #E6E6E6", padding: "5px", fontWeight: "600" }}>
                                                                        <p style={{ marginBottom: "0" }}>Total payable</p>
                                                                        <p style={{ marginBottom: "0" }}> ₹ {userDetailsById?.student?.feeDetails?.totalPayable?.toFixed(2) && numberWithCommas(userDetailsById?.student?.feeDetails?.totalPayable?.toFixed(2))}</p>
                                                                </div>
                                                        </div>
                                                        <Table
                                                                className="m-t-10"
                                                                style={{ width: "100vw" }}
                                                                dataSource={userDetailsById?.student?.feeDetails?.tranactionDetails ? userDetailsById?.student?.feeDetails?.tranactionDetails : []}
                                                                columns={columns()}
                                                                rowSelection={null}
                                                                onRow={() => { }}
                                                                rowKey={e => e.transactionId}
                                                        />
                                                </div>
                                        ) : null}
                                </div>
                                {
                                        isModalVisible && (
                                                <ScholarshipAndAdditionModal
                                                        isModalVisible={isModalVisible}
                                                        isAdditionModal={isAdditionModal}
                                                        handleOk={closeModal}
                                                        handleCancel={closeModal}
                                                />
                                        )
                                }
                                {
                                        isCourseModalVisible && (
                                                <EditCourseModal
                                                        isModalVisible={isCourseModalVisible}
                                                        isEditModalVisible={true}
                                                        setIsModalVisible={setIsCourseModalVisible}
                                                        setViewCourseDetails={setViewCourseDetails}
                                                        setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                                                />
                                        )
                                }
                                {
                                        viewEditCourseModal && (
                                                <CourseEditModal
                                                        setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                                                        isModalVisible={viewEditCourseModal}
                                                        setViewEditCourseModal={setViewEditCourseModal}
                                                        setViewCourseDetails={setViewCourseDetails}
                                                />
                                        )
                                }
                                {
                                        viewCourseDeleteConfirmModal && (
                                                <CourseDeleteConfirmModal
                                                        modalType={modalType}
                                                        setViewEditCourseModal={setViewEditCourseModal}
                                                        isModalVisible={viewCourseDeleteConfirmModal}
                                                        setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                                                        setViewCourseDetails={setViewCourseDetails}
                                                        setViewAddCourseModal={setViewAddCourseModal}
                                                        setIsCourseModalVisible={setIsCourseModalVisible}
                                                />
                                        )
                                }


                                <PageDataLoader visible={pageLoader} />
                        </div >
                );
        }
}

const mapStateToProps = (state) => {
        const {
                tab,
                dashBoardRole,
                dashboardData,
                classList,
                studentCourseDetails,
                userDetailsById,
                nextRollNo,
                UpdateRollNo,
        } = state.userManagement;
        const { user } = state.session;
        const { installments } = state.course;
        const { feeMangementData, } = state.feeManagement;
        const { studentRollNo } = state.userManagementV2

        return {
                feeMangementData,
                tab,
                dashBoardRole,
                dashboardData,
                classList,
                studentCourseDetails,
                installments,
                userDetailsById,
                nextRollNo,
                UpdateRollNo,
                user,
                studentRollNo
        };
};

const mapDispatchToProps = (dispatch) => ({
        getStudentsRollNo: (id, callback, val) => dispatch(getStudentsRollNo(id, callback, val)),
        setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
        getCourseDetalis: (callback) => dispatch(getCourseDetalis(callback)),
        setInstallments: (val) => dispatch(setInstallments(val)),
        setCoursePrice: (val) => dispatch(setCoursePrice(val)),
        updateScholarshipOrAddition: (callback) => dispatch(updateScholarshipOrAddition(callback)),
        unassignStudent: (callback) => dispatch(unassignStudent(callback)),
        setFeeDetails: (res) => dispatch(setFeeDetails(res)),
        setRollNumber: (res) => dispatch(setRollNumber(res)),
        UpdateRollNumber: (standardId) => dispatch(UpdateRollNumber(standardId)),
        unAssignStudentfromStd: (params, callback) => dispatch(unAssignStudentfromStd(params, callback)),
        storeStudentRollNo: rollNo => dispatch(storeStudentRollNo(rollNo)),
        getStudentsDetailsById: (callback, id) => dispatch(getStudentsDetailsById(callback, id))

});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsNew);
