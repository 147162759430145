import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, PageHeader, Row } from 'antd';
import './careStyles.css';
import { connect } from 'react-redux';
import { getAdminFaqs, getStudentFaqs } from 'actions/adminCare';
import ReactHtmlParser from 'react-html-parser';
import { useEffect } from 'react';



function CareFaqs(props) {


    const middleIndex = Math.ceil(props.faqs?.faq?.length / 2);
    const firstHalf = props.faqs?.faq.splice(0, middleIndex);
    const secondHalf = props.faqs?.faq.splice(-middleIndex);

    useEffect(() => {

        props.getStudentFaqs();

    }, [])
    const history = useHistory();
    return (
        <div>

            <div className="faqAdminHeaderPart">

                <PageHeader
                    title={<div className="text-md"> </div>}
                    onBack={() => history.goBack()}
                    backIcon={<ArrowLeftOutlined />}
                />

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <h1>FAQs</h1>
                </div>
            </div>
            <Row style={{ marginTop: "10px" }}>
                <Col md={{ span: 24 }} lg={{ span: 12 }} >
                    {firstHalf?.map((item) =>
                        <div className="queAns" key={item.id} style={{ display: "flex", flexDirection: "column", width: "97%", height: "auto", marginLeft: "10px", marginRight: "10px" }}>
                            <h1 style={{ color: "#191919", fontSize: "18px", fontWeight: "bold", fontFamily: "roboto" }}>{item.title}</h1>
                            <p style={{ color: "#636363", fontSize: "14px", fontFamily: "roboto", textAlign: "justify" }}>{ReactHtmlParser(item.body)} </p>
                        </div>
                    )}
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    {secondHalf?.map((item) =>
                        <div className="queAns" key={item.id} style={{ display: "flex", flexDirection: "column", width: "97%", height: "auto", marginLeft: "10px", marginRight: "10px" }}>
                            <h1 style={{ color: "#191919", fontSize: "18px", fontWeight: "bold", fontFamily: "roboto" }}>{item.title}</h1>
                            <p style={{ color: "#636363", fontSize: "14px", fontFamily: "roboto", textAlign: "justify" }}>{ReactHtmlParser(item.body)} </p>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    )
}

// export default CareFaqs

const mapStateToProps = state => {
    const {
        faqs,

    } = state.adminCare;
    return {
        faqs,
    };
};

const mapDispatchToProps = dispatch => ({
    getAdminFaqs: () => dispatch(getAdminFaqs()),
    getStudentFaqs: () => dispatch(getStudentFaqs())

});


export default connect(mapStateToProps, mapDispatchToProps)(CareFaqs);
