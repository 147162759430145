import React from "react";
import Modal from "antd/lib/modal";
import "antd/lib/modal/style/index";
import { Divider, Button } from "antd";
import {
  SyncOutlined,
  BellOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { MdNotifications } from "react-icons/md";
import { TiFilter } from "react-icons/ti";
import { IoMdSearch } from "react-icons/io";
import { Api, Color, Utils } from "services";
import Carousel from "react-multi-carousel";
import { TestTopBar, TestSubjectsCard } from "components";
import { PieChart } from "react-minimal-pie-chart";
import {
  getStudentTestDurationTimer,
  getStudentTestDurationDecrement,
  getStudentTestDetail,
  getStudentTestCurrentTime,
} from "actions/mobileTest";
import TeX from "@matejmazur/react-katex";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { connect } from "react-redux";
// import chemistryIcon from "Assets/test-assignment/chemistryIcon.svg";
// import mathsIcon from "Assets/test-assignment/mathsIcon.svg";
// import physicsIcon from "Assets/test-assignment/physicsIcon.svg";
// import sliderIndicator from "Assets/test-assignment/sliderIndicator.svg";
// import sliderIndicatorLight from "Assets/test-assignment/sliderIndicatorLight.svg";
import "./styles.css";
import "../../design/layout.css";
import "../../design/assignment.css";
// import DonutChart from 'react-donut-chart';

class MobileTestStart extends React.Component {
  constructor() {
    super();
    this.myinterval = 0;
  }

  componentDidMount() {
    this.props.getStudentTestDurationTimer(
      Utils.secondsToHms(this.props.studentTestDurationInSec)
    );
    Utils.startDuation(this.props.studentTestDurationInSec, (res) => {
      this.props.getStudentTestDurationDecrement(res);
      this.props.getStudentTestDurationTimer(Utils.secondsToHms(res));
    });

    document.addEventListener("message", this.handleEvent);
  }

  handleEvent = (reactNativeMessage) => {
    if (reactNativeMessage.data.rnMessage == "no") {
      this.props.getStudentTestCurrentTime(
        this.props.studentTestLiveId,
        (response) => {
          Utils.stopDuration();
          if (this.props.studentTestDurationInSec > 0) {
            this.props.getStudentTestDurationTimer(
              Utils.secondsToHms(this.props.studentTestDurationInSec)
            );
            Utils.startDuation(this.props.studentTestDurationInSec, (res) => {
              this.props.getStudentTestDurationDecrement(res);
              this.props.getStudentTestDurationTimer(Utils.secondsToHms(res));
            });
          } else {
            this.props.getStudentTestDurationTimer(Utils.secondsToHms(0));
          }
        }
      );
    }
  };
  componentWillUnmount() {
    document.removeEventListener("message", this.handleEvent);
  }

  htmlParser = (text) => {
    return ReactHtmlParser(text, {
      transform: (node, index) => {
        if (
          node.type === "tag" &&
          node.name === "span" &&
          node.attribs["data-value"]
        ) {
          return <TeX math={`${node.attribs["data-value"]}`} />;
        }
      },
    });
  };

  render() {
    const {
      history,
      studentTestDetialData,
      studentSubjectHeader,
      studentTestDurationInSec,
      studentTestDurationTimer,
      studentTestDetailLoader,
      studentTestCurrentTimeLoader,
    } = this.props;

    return (
      <div>
        <TestTopBar
          backPress={() => {
            history.push("/web-test");
            Utils.stopDuration();
          }}
          title={studentSubjectHeader}
          listMode={false}
          precentshow={false}
        />
        <div
          className="radius-10 m-10 p-10"
          style={{ border: "1px solid #EDEDED" }}
        >
          <div className="text-xmd bold-600 simgrey">Title</div>
          <div className="text-sm bold-600 color-black">
            {studentTestDetialData.title}
          </div>
          <Divider style={{ margin: "15px 0px" }} />
          <div className="r-c-sb">
            <div>
              <div className="text-xmd bold-600 simgrey">Duration</div>
              {/*<div className='text-md bold-600 color-black'>
                {studentTestDetialData.duration.slice(0, studentTestDetialData.duration.length-3)} <span style={{fontSize:'14px'}}>min</span>
              </div>*/}
              <div className="text-md bold-600 color-black">
                {studentTestDurationInSec > 0
                  ? studentTestDurationTimer
                  : "00:00:00"}
              </div>
            </div>
            <div>
              <div className="text-xmd bold-600 simgrey">Questions</div>
              <div className="text-md bold-600 color-black">
                {studentTestDetialData.question.length}
              </div>
            </div>
            <div>
              <div className="text-xmd bold-600 simgrey">Marks</div>
              <div className="text-md bold-600 color-black">
                {studentTestDetialData.marks}
              </div>
            </div>
          </div>
          <Divider style={{ margin: "15px 0px" }} />
          <div className="text-xmd bold-600 simgrey">Description</div>
          <div className="text-sm bold-600 color-black">
            {this.htmlParser(
              studentTestDetialData.description.replace(/type:/g, ":")
            )}
          </div>
          <div className="text-xmd bold-600 simgrey m-t-20">Reference</div>
          <div className="text-sm bold-600 color-black">
            {studentTestDetialData.chapters !== null
              ? studentTestDetialData.chapters.replace(/,/g, ", ")
              : "NA"}
          </div>
          <div className="text-xmd bold-600 simgrey m-t-20">Prepared by</div>
          <div className="text-sm bold-600 color-black">
            {studentTestDetialData.teacher}
          </div>
          <div className="text-xmd bold-600 simgrey m-t-20">Instruction</div>
          <div className="text-sm bold-600 color-black">
            {studentTestDetialData.instruction}
          </div>
          <div className="r-c-c">
            <Button
              onClick={
                studentTestDurationInSec > 0
                  ? () => {
                      history.push(`${history.location.pathname}/start`);
                      Utils.stopDuration();
                    }
                  : () => {}
              }
              disabled={studentTestDurationInSec > 0 ? false : true}
              className="mobileTestGreenBtnWrap m-t-30"
            >
              {studentTestDurationInSec > 0
                ? studentTestDetialData.testProgress === "INPROGRESS"
                  ? "CONTINUE"
                  : "START NOW"
                : "MISSED"}{" "}
              <ArrowRightOutlined />
            </Button>
          </div>
        </div>
        <div className="p-b-20"></div>

        <Modal
          visible={studentTestCurrentTimeLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img
              src={require("Assets/dots-gif.gif").default}
              style={{ maxWidth: "100%" }}
              width={150}
              alt=""
            />
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} /> */}
            {/* <div className="text-xmd m-t-10">Updating Tests</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    studentTestDetialData,
    studentSubjectHeader,
    studentTestDurationInSec,
    studentTestDurationTimer,
    studentTestLiveId,
    studentTestDetailLoader,
    studentTestCurrentTimeLoader,
  } = state.mobileTest;
  return {
    studentTestDetialData,
    studentSubjectHeader,
    studentTestDurationInSec,
    studentTestDurationTimer,
    studentTestLiveId,
    studentTestDetailLoader,
    studentTestCurrentTimeLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentTestDurationTimer: (time) =>
    dispatch(getStudentTestDurationTimer(time)),
  getStudentTestDurationDecrement: (time) =>
    dispatch(getStudentTestDurationDecrement(time)),
  getStudentTestDetail: (id, tabname, callback) =>
    dispatch(getStudentTestDetail(id, tabname, callback)),
  getStudentTestCurrentTime: (id, callback) =>
    dispatch(getStudentTestCurrentTime(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTestStart);
