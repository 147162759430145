import { PlusCircleOutlined } from '@ant-design/icons';
import { getOffers } from 'actions/offers';
import { Button } from 'components/Button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { MainSectionTypes } from '../constants/offers';
import OfferDetailsModal from '../modals/offerDetailsModal';
import OffersConfirmationModal from '../modals/offersConfirmationModal';
import '../styles.css';
import OfferListHeader from './offerListHeader';
import OffersCard from './offersCard';

function OffersGridList({ getOffers, offerFilterParams, offerData, setMainSectionType, setSelectedOffer, selectedItems, setSelectedItems, setIsOfferModalVisible,
    setModalActionType, setPageLoading, pageLoading, setPreviousMainSectionType, previousMainSectionType, isModalVisible, actionType,
    selectedOffer, setOfferFilterParams, setFilterVisible, isFilterVisible, offersOverview, offersTypeIndex
}) {
    const [dataLoading, setDataLoading] = useState(false);
    const [copiedId, setCopiedId] = useState(-1);
    const [loadMore, setLoadMore] = useState(false);
    const [page, setPage] = useState(1);
    const [isOfferDetailsModalVisible, setIsOfferDetailsModalVisible] = useState(false);
    const selectAllHandler = (e) => {
        e.stopPropagation();
        const offerItems = offerData?.items || [];
        setSelectedItems((selectedItems) => {
            if (selectedItems?.length && selectedItems?.length === offerItems.length) {
                return [];
            } else {
                setSelectedOffer({});
                return offerItems.map((offer) => ({
                    id: offer.id,
                    published: offer.published,
                    type: offer.type,
                    privateOffer: offer.privateOffer,
                    expired: offer.expired,
                    banner: offer.banner,
                }));
            }
        })
    };
    useEffect(() => {
        if (!(Object.keys(offerFilterParams || {}).length == 0 && page == 1)) {
            if (!pageLoading && previousMainSectionType !== 'createEdit') {
                setDataLoading(true);
            }
        }
        setPage(1);
        setSelectedItems([]);
        if(previousMainSectionType == 'createEdit'){ // dataloading when search
            setPreviousMainSectionType('gridList');
        }
        getOffers({ ...offerFilterParams, page: 1, limit: 10 }, () => { setLoadMore(false); setDataLoading(false); });
    }, [offerFilterParams.search,
    offerFilterParams.sort,
    offerFilterParams.startDate,
    offerFilterParams.endDate,
    offerFilterParams.filterTypes,
    offerFilterParams.published,
    offerFilterParams.unpublished,
    offerFilterParams.expired,
    offerFilterParams.banner,
    offerFilterParams.privateOffer,
    offerFilterParams.available,
    ])
    useEffect(() => {
        if ((page !== 1 || !Object.keys(offerFilterParams || {}).length == 0)) {
            if (!pageLoading && offerData?.meta?.currentPage < offerData?.meta?.totalPages) {
                setLoadMore(true);
            }
            getOffers({ ...offerFilterParams, page, limit: 10 }, () => { setLoadMore(false); setDataLoading(false) });
        }
    }, [page])
    const observerData = useRef();
    const lastOfferRef = useCallback(node => {
        if (loadMore || !node) return;
        if (observerData.current) observerData.current.disconnect();
        //eslint-disable-next-line
        observerData.current = new IntersectionObserver(entries => {
            if (entries?.some((item) => item.isIntersecting) && offerData?.meta?.currentPage < offerData?.meta?.totalPages) {
                setPage(prevPage => prevPage + 1);
            }
        });
        observerData.current.observe(node);
    }, [loadMore, offerData?.items]);
    const totalCount = parseInt(offersOverview?.offerCounts?.total) || 0;
    const availableCount = parseInt(offersOverview?.offerCounts?.available) || 0;
    const unavailableCount = parseInt(offersOverview?.offerCounts?.unavailable) || 0;
    const hideHeader = totalCount === 0 || (offersTypeIndex == 1 && availableCount === 0) || (offersTypeIndex === 2 && unavailableCount === 0);
    return (
        <div className='offer-grid-list'>
            {!hideHeader ? <header>
                <OfferListHeader
                    offerFilterParams={offerFilterParams}
                    setOfferFilterParams={setOfferFilterParams}
                    setFilterVisible={setFilterVisible}
                    isFilterVisible={isFilterVisible}
                    selectAllHandler={selectAllHandler}
                    selectedItems={selectedItems}
                    offerData={offerData}
                    setSelectedItems={setSelectedItems}
                    setMainSectionType={setMainSectionType}
                    setPreviousMainSectionType={setPreviousMainSectionType}
                    type={'gridList'}
                ></OfferListHeader>
            </header> : null}
            {offerData?.items?.length ?
                <>
                    {(dataLoading) ?
                        <div className='r-c-c' style={{ width: "100%", height: "100%" }}>
                            <img src={require('Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                        </div>
                        :
                        <div className='scroll-bar-universal' style={{ height: '92%', width: '98%', marginLeft: '25px' }}>
                            <main className='grid-list-items' style={{ paddingBottom: 10 }}>
                                {
                                    offerData?.items?.map((offer, index) => {
                                        return (
                                            <OffersCard
                                                key={offer.id}
                                                offer={offer}
                                                isSelected={selectedItems.some((item) => item.id == offer.id)}
                                                setSelectedItems={setSelectedItems}
                                                setDataLoading={setDataLoading}
                                                cardRef={(index === offerData?.items?.length - 1) ? lastOfferRef : null}
                                                setMainSectionType={setMainSectionType}
                                                setPreviousMainSectionType={setPreviousMainSectionType}
                                                setSelectedOffer={setSelectedOffer}
                                                isCopied={offer.id == copiedId}
                                                setCopiedId={setCopiedId}
                                                setIsOfferModalVisible={setIsOfferModalVisible}
                                                setModalActionType={setModalActionType}
                                                index={index}
                                                offerData={offerData}
                                                selectedItems={selectedItems}
                                                setIsOfferDetailsModalVisible={setIsOfferDetailsModalVisible}
                                                setFilterVisible={setFilterVisible}
                                            ></OffersCard>
                                        )
                                    }) || []
                                }
                            </main>
                            {loadMore ? <div className='r-c-c' style={{ width: "100%", height: "10%" }}>
                                <img src={require('Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                            </div> : null}
                        </div>
                    }
                </>
                :
                (dataLoading ?
                    <div className='r-c-c' style={{ width: "100%", height: "100%" }}>
                        <img src={require('Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                    </div>
                    :
                    <div className={`r-c-c-c no-offers-card${hideHeader ? ' no-offers-card-full' : ''}`}>
                        <img src={require("Assets/offers/no-offers-img.png").default} style={{ width: 230 }} />
                        <div className='m-t-15' style={{ color: "#191919", fontSize: 16 }}>No offers available!</div>
                        <div className='m-t-5 m-b-30' style={{ color: "#636363" }}>There are no offers added yet.</div>
                        <Button type="primary" onClick={() => { setSelectedOffer({}), setPreviousMainSectionType('gridList'); setMainSectionType(MainSectionTypes.createEdit) }} icon={<PlusCircleOutlined />}> Add A Promotional Offer</Button>
                    </div>
                )
            }
            {isModalVisible &&
                <OffersConfirmationModal
                    isModalVisible={isModalVisible}
                    setModalVisible={setIsOfferModalVisible}
                    togglePageLoader={setPageLoading}
                    actionType={actionType}
                    selectedOfferId={selectedOffer.id}
                    selectedOfferIds={selectedItems.map((item) => item.id)}
                    isPublished={selectedItems?.length ? selectedItems.every((item) => item.published) : selectedOffer.published}
                    isBanner={selectedItems?.length ? selectedItems.every((item) => item.banner) : selectedOffer.banner}
                    offerFilterParams={offerFilterParams}
                    setSelectedItems={setSelectedItems}
                    setPage={setPage}
                    setMainSectionType={setMainSectionType}
                    previousMainSectionType={previousMainSectionType}
                />
            }
            {isOfferDetailsModalVisible &&
                <OfferDetailsModal
                    isOfferDetailsModalVisible={isOfferDetailsModalVisible}
                    setIsOfferDetailsModalVisible={setIsOfferDetailsModalVisible}
                    offer={selectedOffer}
                    setIsOfferModalVisible={setIsOfferModalVisible}
                    setModalActionType={setModalActionType}
                    setSelectedOffer={setSelectedOffer}
                    setMainSectionType={setMainSectionType}
                    setPreviousMainSectionType={setPreviousMainSectionType}
                    mainSectionType={MainSectionTypes.gridList}
                >
                </OfferDetailsModal>
            }
        </div >
    );
}
const mapStateToProps = (state) => {
    const { offerData, offersOverview, offersTypeIndex } = state.offers;
    return { offerData, offersOverview, offersTypeIndex };
}

const mapDispatchToProps = (dispatch) => ({
    getOffers: (params, callback) => dispatch(getOffers(params, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OffersGridList);