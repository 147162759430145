import { setSelectAll, setSelectedStudents, setCheckBoxState, postLogOfflineAttendance } from 'actions/schedule'
import { Button, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Api } from 'services'
const checkCircle = require("../../../../Assets/scheduleAttendance/CheckCircle.svg").default
const editIcon = require("../../../../Assets/scheduleAttendance/ic-edit.svg").default
const closeCircle = require("../../../../Assets/scheduleAttendance/CloseCircle.svg").default

const ScheduleStudentCard = ({ url, name, rollNo, id, setSelectedStudents, isSelected, disable }) => {

  const selectHandler = () => {
    setSelectedStudents(id)
  }

  return (
    <div className='schedule-studentCard' onClick={disable ? null : selectHandler} style={{ border: isSelected ? '1px solid #6BDB9E' : null, backgroundColor: isSelected ? '#E9FCF5' : null, cursor: 'pointer' }}>
      <div style={{ alignSelf: 'center', margin: '0px 20px' }}><img src={`${Api._s3_url}${url}`} className='schedule-studentCard-img'></img></div>
      <div className='schedule-studentCard-details'>
        <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{name}</div>
        <div style={{ fontSize: '14px', color: '#636363' }}>Roll no. {rollNo}</div>
      </div>
      {isSelected ? <div className='schedule-studentCard-svg'>
        <img src={checkCircle}></img>
      </div>
        :
        null}

    </div>
  )
}



const StudentCardsDisplay = ({ presentStudentsArray, absentStudentsArray, data, setSelectedStudents, selectedStudents, setSelectAll, setCheckBoxState, checkboxState, postLogOfflineAttendance, submitHandler, attendanceIndicator, presentStudents }) => {

  const [attendanceMarked, setAttendanceMarked] = useState(attendanceIndicator)

  data?.filter((item) => {
    if (selectedStudents?.[item?.studentId] === true) {
      return presentStudentsArray?.push(item)
    } else {
      return absentStudentsArray?.push(item)
    }
  })

  const selectAllHandler = () => {
    setCheckBoxState(!checkboxState)
    setSelectAll()
  }

  const editHandler = () => {
    setAttendanceMarked(false)
  }

  return (
    <div style={{ width: '100%', paddingTop: '12px' }}>
      {!attendanceMarked ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <div>Select those who are <img src={checkCircle} alt="Check Circle" /> <span style={{ color: '#28DF99' }}>PRESENT</span></div>
            <div>
              <Button style={{ backgroundColor: '#594099', borderRadius: '20px', color: '#fff', minWidth: '120px' }} onClick={submitHandler}>SUBMIT</Button>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>ALL STUDENTS({data?.length ? data.length : '0'})</div>
            {presentStudentsArray?.length >= 1 || absentStudentsArray?.length >= 1 ?
              <div>
                <Checkbox onClick={selectAllHandler} checked={checkboxState} />
                <span style={{ marginLeft: '10px' }}>{checkboxState ? 'UNSELECT ALL' : 'SELECT ALL'}</span>
              </div>
              : null
            }
          </div>
          <div style={{ height: '80vh', overflowY: 'scroll', marginTop: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px', flexWrap: 'wrap' }}>
              {data?.map((item) => (
                <ScheduleStudentCard
                  key={item?.studentId}
                  url={item?.image}
                  name={item?.name}
                  rollNo={item?.rollNo}
                  id={item?.studentId}
                  isSelected={selectedStudents[item?.studentId] !== undefined && selectedStudents[item?.studentId]}
                  setSelectedStudents={setSelectedStudents}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            {presentStudentsArray?.length >= 1 && (
              <div><img src={checkCircle} alt="Check Circle" /> <span style={{ color: '#28DF99' }}>PRESENT</span></div>
            )}
            <div>
              <div onClick={editHandler} style={{ border: '1px solid #1089FF', padding: '1px 20px', borderRadius: '20px' }}><img src={editIcon} alt="Edit Icon" /> <span style={{ color: '#1089FF', fontSize: '12px', fontWeight: 'bold' }}>EDIT</span></div>
            </div>
          </div>
          {presentStudentsArray?.length >= 1 && (
            <div style={{ height: '80vh', overflowY: 'scroll', marginTop: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px', flexWrap: 'wrap' }}>
                {presentStudentsArray?.map((item) => (
                  <ScheduleStudentCard
                    key={item?.studentId}
                    url={item?.image}
                    name={item?.name}
                    rollNo={item?.rollNo}
                    id={item?.studentId}
                    disable={true}
                  />
                ))}
              </div>
            </div>
          )}
          {absentStudentsArray?.length >= 1 && (
            <>
              <div style={{ marginBottom: '10px' }}>
                <div><img src={closeCircle} alt="Close Circle" /> <span style={{ color: '#FF414D' }}>ABSENT</span></div>
              </div>
              <div style={{ height: '80vh', overflowY: 'scroll', marginTop: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px', flexWrap: 'wrap' }}>
                  {absentStudentsArray?.map((item) => (
                    <ScheduleStudentCard
                      key={item?.studentId}
                      url={item?.image}
                      name={item?.name}
                      rollNo={item?.rollNo}
                      id={item?.studentId}
                      disable={true}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  const { selectedStudents, checkboxState } = state.schedule
  return { selectedStudents, checkboxState }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedStudents: (id) => dispatch(setSelectedStudents(id)),
  setSelectAll: (id, value) => dispatch(setSelectAll(id, value)),
  setCheckBoxState: (bool) => dispatch(setCheckBoxState(bool))
})
export default connect(mapStateToProps, mapDispatchToProps)(StudentCardsDisplay);