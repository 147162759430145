import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from 'antd'
import { Api } from 'services';
import '../userHomeStyles.css';
import RatingStars from '../ratingStars';
import { Button } from 'components/Button';
import { getCartCount, impressionEngagementIncrement, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, storeSingleBuyNow } from 'actions/studentMarketPlace';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import saveOutlined from "../../../Assets/aulasMarket/notSavedBookmark.svg"
import saveFilled from "../../../Assets/aulasMarket/savedBookmark.svg"
import studyMaterialImg from '../../../Assets/aulasMarket/studyMaterialImg.svg'
import testDefaultImg from '../../../Assets/aulasMarket/testImg.svg'
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';

import { motion } from 'framer-motion';
import FreeCourseConfirmationModal from './freeConfirmationModal';
import YtPlayer from 'components/ytPlayer';
import { setEnrolledItemId, setShowLoginModal } from 'actions/loginNew';




function NewCoursesCards({ item, postAddSaveItem, postAddCartItem, removeSaveItem, user, postCourseEnrollNow, getCartCount, impressionEngagementIncrement, setShowDownloadAppModal, setShowLoginModal , setEnrolledItemId, courseEnrolledId}) {
    const history = useHistory()
    const [shareUrl, setShareUrl] = useState(null);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false);
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)
    const [freeConfirmModal, setFreeConfirmModal] = useState(false)

 

    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.owned, item?.inSaved])

    const handleSaveForLater = (id, itemType) => {

        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        setIsSavedClicked(!isSavedClicked)
        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));

                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {

        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        if (user?.accessToken) {

            postAddCartItem(id, itemType, (res) => {
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    setAddToCartClicked(true)
                }
            });
        } else {
            history.push('/login');
        }
    };
    const handleEnroll = (id, itemType) => {
        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })

        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    setFreeConfirmModal(false)
                    // history.push('/courses-list')
                }
                setShowDownloadAppModal(true)
            });
        } else {
            history.push('/login');
        }
    };
    console.log("item-06", item);

    const handleCardClick = () => {
        let params = {
            id: item?.id,
            type: 'impression',
            courseType: item?.resourceType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        history.push({
            pathname: "/resource-details",
            state: {
                id: item?.id,
                resourceType: item?.resourceType
            }
        })

    }


    const [freeCourseName, setFreeCourseName] = useState('')
    const [freeCourseType, setFreeCourseType] = useState('')

    const handleFree = () => {
        handleEnroll(item?.id, item?.resourceType)

    }
   

    const [isHover, setIsHover] = useState(false)

   
    const handleMouseHover = () => {
        setIsHover(true)

    }
    const handleMouseUnHover = () => {
        setIsHover(false)
    }

    function getDurationText(duration) {
        if (typeof duration !== 'string' || !duration.includes(':')) {
            return false; // Return false for invalid input
        }
    
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return false; // Handle cases where parsing fails
        }
    
        if (hours < 1) {
            return false; // Return false if hours are less than 1
        }
    
        return hours === 1 ? `${hours} hour` : `${hours} hours`;
    }

    function isDurationGreaterThanOrEqualOneHour(duration) {
        if (!duration || typeof duration !== 'string') {
            return false; // Handle invalid or unexpected duration format
        }
    
        const [hours] = duration?.split(':').map(Number);
        return hours >= 1;
    }


    return (
        <div onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseUnHover} className='course-new-card geeks' onClick={() => { handleCardClick() }} style={{ cursor: "pointer" }}>
            <div>
                <div style={{ position: "relative" }}>
                    <div className='geeks' style={{ overflow: "hidden", borderRadius: "10px", width: "100%",height:170  }}>

                    {isHover && item?.preview ? (
                            <>
                                <div style={{ height: 170 }} >
                                    <YtPlayer keyboardControls={true}  videoUrl={`${Api.proDlS3Url}${item?.preview}`} hoverEffect={isHover} />
                                </div>

                            </>) : (
                            <>  <img style={{ height: 170, borderRadius: 10 }}
                            src={item?.image ? `${Api.dlS3Url}${item?.image}` : item?.resourceType == "testSeries" ? testDefaultImg : studyMaterialImg}
                        />
                            </>)}
                      
                    </div>
                    {item?.mode ? <div className={`${item?.mode == 'online' ? 'online-course-mode' : item?.mode == 'hybrid' ? 'hybrid-course-mode' : 'offline-course-mode'}`}>{item?.mode?.charAt(0)?.toUpperCase() + item?.mode?.slice(1)}</div> : <></>}
                </div>
                <div style={{ height:item?.resourceType == 'course' ? 83: '15vh', }}>
                    <div className='course-name-div' >
                        <Tooltip title={item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}>
                            <div style={{ maxHeight: '3.6em', width: '100%', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, fontWeight: 400, WebkitBoxOrient: "vertical", color: "#191919", fontSize: 16 }}>{item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}</div>
                        </Tooltip>
                    </div>
                    <div style={{ marginTop: 4 }}>
                        {item?.resourceType === "videoResource" ? (
                            <div className='' style={{ color: "#636363" }}>

                                {item?.duration == '00:00:00' ? '' : getDurationText(item?.duration)  } {<>
                                    {isDurationGreaterThanOrEqualOneHour(item?.duration) && item?.quizCount != 0 ? '| ' : '' } 
                                     { item?.quizCount!= 0 ? item?.quizCount == 1 ? `${item?.quizCount} quiz` : `${item?.quizCount} quizzes` : ''}</>} {<>
                                     {item?.quizCount != 0 && item?.notesCount!= 0 ? '| ' : ''}
                                     { item?.notesCount!= 0 ? item?.notesCount == 1 ? `${item?.notesCount} note` : `${item?.notesCount} notes` : ''} </>}
                            </div>
                        )
                            // : item?.resourceType == 'course' ?
                            //     (<>  
                            //         <div className='' style={{ color: "#636363", height: 22 }}>
                            //             {console.log("item?.plannedHours", item?.plannedHours)}
                            //             {<>{item?.plannedClasses} classes  |</>}  {<>{item?.plannedTests} tests </>} {<>| {formatDuration(item?.plannedHours)}</>}
                            //         </div>
                            //     </>) 
                            :
                            item?.resourceType == "studyMaterial" ? (
                                <>
                                    <div className='' style={{ color: "#636363", height: 22 }}>
                                        {<>{item?.m_count == 1 ? `${item?.m_count} material` : `${item?.m_count} materials`} |</>}  {<>{item?.q_count == 1 || item?.q_count == 0 ? `${item?.q_count} quiz` : `${item?.q_count} quizzes`}  </>}
                                    </div>
                                </>) :
                                item?.resourceType == "testSeries" ? (
                                    <>
                                        <div className='' style={{ color: "#636363", height: 22 }}>
                                            {<>{item?.tCount == 1 ? `${item?.tCount} test` : `${item?.tCount} tests`} </>}
                                        </div>
                                    </>) :
                                    (
                                        <div className='' style={{ color: "#636363", marginTop: item?.resourceType == 'course' ? 0 : 40 }}></div>
                                    )}
                    </div>
                    {item?.resourceType == "testSeries"
                        ?
                        <div style={{ display: "flex", marginTop: 5 }}>
                            {console.log("item?.rating", item)}
                            <div style={{ color: "#FFA931", fontSize: 14, margin: "0px 0px 0px 2px", fontWeight: 700, marginRight: 6 }}>{item?.ratingValue ? item?.ratingValue : 0}</div>
                            <RatingStars star={item?.ratingValue} />
                            <div style={{ color: "#AEAEAE", fontSize: 14, margin: "0px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : `(0)`}</div>
                        </div>
                        :
                        <div style={{ display: "flex", marginTop: 5 }}>
                            {console.log("item?.ratingValue", item)}
                            <div style={{ color: "#FFA931", fontSize: 14, margin: "0px 0px 0px 2px", fontWeight: 700, marginRight: 6 }}>{item?.rating ? item?.rating : 0}</div>
                            <RatingStars star={item?.rating} />
                            <div style={{ color: "#AEAEAE", fontSize: 14, margin: "0px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : `(0)`}</div>
                        </div>
                    }
                </div>
                <div className='m-t-5' style={{ display: "flex", width: "100%", justifyContent: "space-between" ,marginTop:item?.resourceType == 'course' ? 5 : -10}}>
                    <div>
                        {console.log("item?.amount23", item?.amount,)}
                        {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount || 0)) > 0 ? (
                            <div style={{ fontSize: 14, color: "#594099", padding: "3px 5px", fontWeight: 'bold', bottom: 10, right: 10 }}>
                                ₹{(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount || 0)).toFixed(2)}{" "}
                                {item?.discountAmount > 0 && (
                                    <span style={{ color: "#636363", fontWeight: 400, textDecoration: 'line-through', fontSize: 14 }}>
                                        ₹{parseFloat(item?.amount || 0).toFixed(2)}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div style={{marginTop:5, borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", bottom: 10, right: 10, backgroundColor: "#FF414D", width: "50px", height: 25 }}>
                                <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                            </div>
                        )}


                    </div>
                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", width: 80 }}>
                            {console.log("user?.role", user?.role)}
                            <img src={require("../../../Assets/aulasMarket/shareIcon-copy.svg").default} style={{ cursor: 'pointer', marginLeft: item?.owned == 1 || user?.role?.includes("Operation") || user?.role?.includes('SuperAdmin') || user?.role?.includes('Admin') ? 50 : 20, height: 27, width: 23 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                                }} />
                            {user?.role?.includes("Student") || user?.accessToken == undefined ?
                                <>
                                    {item?.owned == 1 ? <></> :
                                        <div style={{ marginTop: -3, cursor: 'pointer', }} onClick={(e) => {
                                            e.stopPropagation();
                                            if (user.accessToken) {
                                                handleSaveForLater(item?.id, item?.resourceType)
                                            } else {
                                                // history.push("/login")
                                                setShowLoginModal(true)
                                            }
                                        }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || (isSavedClicked && item?.isSaved == 0) ? saveFilled : saveOutlined} style={{ height: 33, width: 23 }} /></div>}
                                </> : <></>}
                        </div>
                    </div>

                </div>
                {item?.disableAdmission ?
                    <center>
                        <div className='r-c-c' style={{ fontSize: "15px", color: "#FF414D", width: "100%", height: "35px", borderRadius: "50px", marginTop: 10, fontWeight: 600 }}>ADDMISSION CLOSED</div>
                    </center> : <>
                        {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
                            <center className='m-t-10'>
                                {item?.owned == 1 || enroll[item?.id] ?
                                    <div className='marginTop' >
                                        <Button className='button' style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "100%", padding: "5px 10px", borderRadius: 8, }}>
                                            <div className='glare'></div>

                                            VIEW COURSE</Button>
                                    </div>
                                    :
                                    <>
                                        {item?.amount == 0 || item?.feeType == "free" ?
                                            <Button loading={enrollLoader} className='m-t-5' type="primary" style={{ position: 'relative', overflow: 'hidden', width: "100%", padding: "5px 10px", borderRadius: 8, marginTop: 10 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (user?.accessToken) {
                                                        if (enroll[item?.id]) { }
                                                        else if (item?.feeType == "free") {

                                                            setFreeConfirmModal(true)
                                                            setFreeCourseType(item?.resourceType)
                                                            setFreeCourseName(item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName)

                                                        }
                                                    } else {
                                                        // history.push("/login")
                                                        if(item?.feeType == "free"){
                                                            setEnrolledItemId({id:item?.id,type:item?.resourceType})
                                                        }           
                                                        setShowLoginModal(true)
                                                    }
                                                }}
                                            >
                                                <div className='glare'></div>

                                                {enroll[item?.id] ? "ENROLLED" : "ENROLL NOW"}</Button>
                                            :
                                            <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                                {item?.resourceType == "course" ? null :
                                                    <Button loading={addCartLoader} loaderArgColor="#594099" style={{ position: 'relative', overflow: 'hidden', border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", padding: "5px 10px", borderRadius: 8, }} onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (!user?.accessToken) {
                                                            // history.push("/login")
                                                            setShowLoginModal(true)
                                                        } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                            history.push("/cart")
                                                        } else {
                                                            setAddCartLoader(true)
                                                            handleAddToCart(item?.id, item?.resourceType)
                                                            getCartCount()
                                                        }
                                                    }}>
                                                        <div className='glare'></div>

                                                        {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                                }
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        position: 'relative',
                                                        width: item?.resourceType === "course" ? "100%" : "47%",
                                                        padding: "10px 10px",
                                                        borderRadius: 8,
                                                        marginTop: item?.resourceType === "course" ? 3 : 0,
                                                        overflow: "hidden"
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (user?.accessToken) {
                                                            if (item?.feeType === "free") {
                                                                handleEnroll(item?.id, item?.resourceType);
                                                            } else {
                                                                history.push({
                                                                    pathname: '/user-checkout',
                                                                    state: { itemId: item?.id, itemType: item?.resourceType }
                                                                });
                                                            }
                                                        } else {
                                                            // history.push("/login");
                                                            if(item?.feeType == "free"){
                                                                setEnrolledItemId({id:item?.id,type:item?.resourceType})
                                                            } 
                                                            setShowLoginModal(true)
                                                        }
                                                    }}
                                                >
                                                    <div className='glare'></div>
                                                    {enroll[item?.id] ? "ENROLLED" : item?.feeType === "free" || item?.resourceType === "course" ? "ENROLL NOW" : "BUY NOW"}
                                                </Button>

                                            </div>
                                        }
                                    </>
                                }
                            </center>
                        </> : <></>}
                    </>}
            </div>
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}

            {freeConfirmModal && <FreeCourseConfirmationModal
                freeConfirmModal={freeConfirmModal}
                setFreeConfirmModal={setFreeConfirmModal}
                freeCourseName={freeCourseName}
                freeCourseType={freeCourseType}
                handleFree={handleFree} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    const { courseEnrolledId } = state.loginNew
    const { user } = state.session
    return { userMarketPlaceHomeData, user, courseEnrolledId };
};

const mapDispatchToProps = (dispatch) => ({
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCartCount: () => dispatch(getCartCount()),
    impressionEngagementIncrement: (params, callback) => dispatch(impressionEngagementIncrement(params, callback)),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
    setEnrolledItemId: (val) => dispatch(setEnrolledItemId(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewCoursesCards);