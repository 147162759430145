import React from 'react'
import { Input} from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';

function SearchBox({loading, onChange, placeholder, style, className, value}) {
  return (
    <Input placeholder={placeholder}
    className={className}
    prefix={loading?<LoadingOutlined/>:<SearchOutlined/>}
    style={{...style, backgroundColor:"#FFF", borderRadius:5}}
    onChange={onChange}
    bordered={true}
    value={value}
    />
  )
}

export default SearchBox
