import React, {useState} from 'react'
import {Modal, Radio} from 'antd'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setImportMeta } from 'actions/testAdmin';
import { Notification } from 'services';

function ImportQuestionModal({visible, onCancel, setImportMeta}) {
    const [value, setValue] = useState(0);
    const history = useHistory();
    const onRadioChange = (e) => {
        console.log("radio checked", e.target.value);
        setValue(e.target.value);
      };

      const onImport = () => {
        if (value===0){
          Notification.error("Error", "Please choose a question paper type")
          return 
        }else if (value===2){
          setImportMeta('selectedQBType', 'Subject-wise Question Papers')
          setImportMeta('selectedTypeId', 2);
        }else if (value===1){
          setImportMeta('selectedQBType', 'Sample Question Papers')
          setImportMeta('selectedTypeId', 1);

        }
        history.push('test-import-classes');
      }
      

      return(
        <div>
        <div>
          <Modal
            centered={true}
            className="modal-round-corner"
            visible={visible}
            // onOk={handleOk}
            onCancel={onCancel}
            footer={null}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "25px",
                color:"#191919"
              }}
            >
              Import
            </div>
                <div style={{fontSize:14, color:"#191919", fontWeight:700}} className="m-b-15">Choose question paper type</div>
                <Radio.Group onChange={onRadioChange} value={value}>
                  <Radio value={1} style={{fontSize:12, color:"#191919"}}>Sample Question Papers</Radio>
                  <Radio value={2} style={{fontSize:12, color:"#191919"}}>Subject-wise Question Papers</Radio>
                </Radio.Group>

            <div className="m-t-100" style={{ display: "flex", justifyContent: "center" }}>
              <div className="purpleBtnWrap radius-100 m-r-15 cursor-pointer r-c-c" onClick={onCancel}
                style={{height:30, border:"1px solid #636363", backgroundColor:"white", color:"#636363"}} >
                  <div>CANCEL</div>
              </div>
              <div
                className="purpleBtnWrap radius-100 cursor-pointer purpleButtonHoverEffect r-c-c"
                onClick={onImport} style={{height:30}}>
                <div>IMPORT</div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      )
}

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.testAdmin;
  return {  
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportQuestionModal)