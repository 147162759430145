import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Popover, Progress, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import LeaveDetailsModal from './leaveDetailsModal';
import {
    getLeavesTabData,
    setCardId,
    setActionData,
    requestedActionData,
    setLeaveRequest,
    getAllLeave,
    postMarkLeave,
    setLeaveCardId,
    setLeaveIds,
    storeNumberOfDays,
} from 'actions/attendanceNew';
import PageDataLoader from 'components/pageDataLoader';
import { NodataCard } from 'pages/Test';
import ConfirmRejectModal from './Modals/confirmRejectModal';
import ConfirmApproveModal from './Modals/confirmApproveModal';
import { MdMoreVert } from 'react-icons/md';
import { AiFillEdit } from 'react-icons/ai';
import TeacherLeaveModal from './Modals/teacherEditModal';
import TeacherRequestModal from './Modals/teacherRequestModal';
import moment from 'moment';

const LeavesRingProgress = ({ totalData, leaveType, availedData }) => {
    return (
        <div>
            <Progress
                strokeColor={`${leaveType === "Casual" ? "#FEA5DD" :
                    leaveType === "Sick" ? "#FFA931" :
                        leaveType === "Marriage" ? "#99DED9" :
                            leaveType === "Privilege" ? "#9876E6" :
                                leaveType === "Paternity" ? "#28DF99" :
                                    leaveType === "Maternity" ? "#B2DFFF" : null}`}
                width={82}
                type="circle"
                percent={((totalData - availedData) / totalData) * 100}
                format={percent => {
                    return (
                        <div>
                            <div style={{ color: " #191919", font: " normal normal bold 13px/19px Roboto" }}>{totalData}</div>
                            <div style={{ color: " #636363", font: "normal normal normal 13px/19px Roboto" }}>Total</div>
                        </div>
                    )
                }}
            // format={percent =>`${percent} Total`}
            />
        </div>
    )
}

const LeaveSummaryCard = ({ leaveType, balanceData, availedData, totalData, togglePageLoader, leaveRequestObj, user, maxLeaves, index }) => {
    let uppercasetext = leaveType.toUpperCase();
    const [isTeacherLeave, setIsTeacherLeave] = useState(false);
    const [leaveCardIndex, SetLeaveCardIndex] = useState(null);

    const menu = (
        <Menu style={{ width: "120px", height: "35px" }}>
            <div className='r-ac' style={{ marginBottom: "-10px" }}>
                <AiFillEdit style={{ width: "24px", height: "20px", color: "#636363", marginLeft: 10, marginRight: 5 }} />
                <Menu.Item style={{ backgroundColor: 'white' }} onClick={() => { setIsTeacherLeave(true), SetLeaveCardIndex(index)}}>
                    Edit
                </Menu.Item>
            </div>

        </Menu>
    );
    return (
        <div
        className='border-16 boxShadow118'
        style={{
            width: "300px",
            height: "160px",
            background: " #FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #E6E6E6",
            marginRight: 25,
            marginTop: 20,
            borderLeft: "6px solid" + `${leaveType === "Casual" ? "#FEA5DD" :
                leaveType === "Sick" ? "#FFA931" :
                    leaveType === "Marriage" ? "#99DED9" :
                        leaveType === "Privilege" ? "#9876E6" :
                            leaveType === "Paternity" ? "#28DF99" :
                                leaveType === "Maternity" ? "#B2DFFF" : null}`,
        }}>

            <div style={{ display: "flex", justifyContent: 'space-between', width: '280px' }}>
                <div style={{
                    color: "#191919",
                    font: "normal normal bold 16px/26px Roboto",
                    fontSize: "14px",
                    marginTop: "10px", marginLeft: "20px"
                }}>{uppercasetext}{" "}{"LEAVE"}

                </div>
                {user?.privilage.includes("SuperAdmin") || user?.privilage.includes("LeaveManager") ? 
                <div style={{ marginTop: "12px", marginLeft: "50px" }}>
                    <Popover placement="bottomRight" content={menu}>
                        <MoreOutlined style={{ cursor: "pointer" }} />
                    </Popover>
                </div>
                :
                null
            }

            </div>


            <div style={{ display: "flex"}}>
                <div style={{ marginTop: "35px", marginLeft: "20px", width: 150 }}>
                    <div style={{ display: "flex"}}>
                        <div style={{ color: " #636363", font: "normal normal normal 13px/19px Roboto" }}>Balance</div>
                        <div style={{ color: " #191919", font: " normal normal bold 13px/19px Roboto", marginLeft: "40px" }}>{balanceData}</div>
                    </div>
                    <div style={{ display: "flex", marginTop: "5px" }}>
                        <div style={{ color: " #636363", font: "normal normal normal 13px/19px Roboto" }}>Availed</div>
                        <div style={{ color: " #191919", font: " normal normal bold 13px/19px Roboto", marginLeft: "44px" }}>{availedData}</div>
                    </div>
                </div>
                <div style={{ marginLeft: "15px", marginTop: "15px" }}>
                    <LeavesRingProgress
                        totalData={totalData}
                        availedData={availedData}
                        leaveType={leaveType} />
                </div>
            </div>
            {
                isTeacherLeave &&
                <TeacherLeaveModal
                    isTeacherLeave={isTeacherLeave}
                    setIsTeacherLeave={setIsTeacherLeave}
                    leaveType={leaveType}
                    balanceData={balanceData}
                    togglePageLoader={togglePageLoader}
                    maxLeaves={maxLeaves}
                    leaveCardIndex={leaveCardIndex}
                />
            }


        </div>
    )
}

 const StatusTag = ({ type }) => {

    return (
        <div style={{
            height: "14px",
            width: "47px",
            marginTop: "15px",
            border: `${ type === "Pending" ? "1px solid #FFA931" : type === "Approved" ? "1px solid #28DF99" : type === "Rejected" ? "1px solid #FF414D" : null}`,
            borderRadius: " 3px",
            marginRight: "10px",
            background: `${ type === "Pending" ? "#FFF4D5" : type === "Approved" ? "#E9FCF5" : type === "Rejected" ? "#FFE4D9" : null}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <div style={{
                font: "normal normal bold 8px/10px Roboto",
                color: `${ type === "Pending" ? "#FFA931" : type === "Approved" ? "#28DF99" : type === "Rejected" ? "#FF414D" : null}`
            }}>
                { type === "Pending" ? "PENDING" : type === "Approved" ? "APPROVED" : type === "Rejected" ? "REJECTED" : null}</div>
        </div>
    )
}

const RequestHistoryCard = ({
    requests,
    leaveDuration,
    leaveStartDate,
    leaveEndDate,
    leaveType,
    leaveStatus,
    leaveReason,
    leaveCardId,
    setCardId,
    setActionData,
    requestedActionData,
    actionByName,
    actionByRole,
    actionByImage,
    getLeavesTabData,
    tabDataContainer,
    togglePageLoader,
    requestType,
    user,
storeLeaveId,setLeaveIds,storeNumberOfDays
 }) => {
    console.log("leaveStatus",leaveStatus)
    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
    const handleCancel = () => {
        console.log("abcd")
        setIsLeaveModalVisible(false);
    };
    return (
        <div style={{
            width: "226px",
            height: "90px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #E6E6E6",
            borderRadius: " 4px",
            cursor: "pointer",
            marginLeft: "20px",
            marginTop: "20px",
            marginBottom: "20px",
        }} onClick={() => {
            setIsLeaveModalVisible(true);
            setLeaveIds([])
        }}>
            <div style={{ display: "flex", justifyContent: "space-between", }}>
                <div style={{ color: "#AEAEAE", font: " normal normal normal 12px/18px Roboto", marginLeft: "20px", marginTop: "15px" }}>
                    {leaveDuration}{" "}
                    {
                        leaveDuration > 1 ? "days" : "day"
                    }
                </div>
                {leaveStatus == "Partially approved" ?
                    <></>
                    :
                    <>
                        <StatusTag
                            type={leaveStatus} />
                    </>
                }
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ font: "normal normal bold 14px/18px Roboto", color: "#191919", marginLeft: "20px" }}>{moment(leaveStartDate).format('DD-MM-YYYY')}</div>
                <div style={{ font: "normal normal bold 14px/18px Roboto", color: "#191919", marginLeft: "3px" }}>
                    {moment(leaveEndDate).format('DD-MM-YYYY') !== null ? "-" : null}{" "}{moment(leaveEndDate).format('DD-MM-YYYY')}</div>
            </div>
            <div style={{ font: "normal normal normal 13px/18px Roboto", color: "#636363", marginLeft: "20px" }}>{leaveType}{" "}Leave</div>
            {isLeaveModalVisible && !isRejectModalVisible&& !isApproveModalVisible?
            <>
              <LeaveDetailsModal
                    isLeaveModalVisible={isLeaveModalVisible}
                    setIsLeaveModalVisible={setIsLeaveModalVisible}
                    handleCancel={handleCancel}
                    leaveType={leaveType}
                    leaveDuration={leaveDuration}
                    leaveStartDate={leaveStartDate}
                    leaveEndDate={leaveEndDate}
                    leaveReason={leaveReason}
                    leaveStatus={leaveStatus}
                    leaveCardId={leaveCardId}
                    setCardId={setCardId}
                    setActionData={setActionData}
                    requestedActionData={requestedActionData}
                    actionByImage={actionByImage}
                    actionByName={actionByName}
                    actionByRole={actionByRole}
                    getLeavesTabData={getLeavesTabData}
                    tabDataContainer={tabDataContainer}
                    isRejectModalVisible={isRejectModalVisible}
                    setIsRejectModalVisible={setIsRejectModalVisible}
                    isApproveModalVisible={isApproveModalVisible}
                    setIsApproveModalVisible={setIsApproveModalVisible}
                    togglePageLoader={togglePageLoader}
                    requestType={requestType}
                    user={user}
                    requests={requests}
                    storeLeaveId={storeLeaveId}
                    setLeaveIds={setLeaveIds}
                    storeNumberOfDays={storeNumberOfDays}

                />
            </>
            :
            null
              
            }
            {isRejectModalVisible &&
                <ConfirmRejectModal
                    isRejectModalVisible={isRejectModalVisible}
                    setIsRejectModalVisible={setIsRejectModalVisible}
                    functionProp={getLeavesTabData}
                    requestedActionData={requestedActionData}
                    id={leaveCardId}
                    setCardId={setCardId}
                    setActionData={setActionData}
                    togglePageLoader={togglePageLoader}
                    storeLeaveId={storeLeaveId}
                    setLeaveIds={setLeaveIds}
                />
            }
            {isApproveModalVisible &&
                <ConfirmApproveModal
                    isApproveModalVisible={isApproveModalVisible}
                    setIsApproveModalVisible={setIsApproveModalVisible}
                    functionProp={getLeavesTabData}
                    requestedActionData={requestedActionData}
                    id={leaveCardId}
                    setCardId={setCardId}
                    setActionData={setActionData}
                    togglePageLoader={togglePageLoader}
                    storeLeaveId={storeLeaveId}
                    setLeaveIds={setLeaveIds}
                />
            }

        </div>
    )
}



function LeavesDashBoard({
    getLeavesTabData,
    leavesTabDataContainer,
    setCardId,
    setActionData,
    requestedActionData,
    tabDataContainer,
    editRole,
    user, 
    getAllLeave, 
    setAllLeaves,
    leaveRequestObj,
    postMarkLeave,
    setLeaveRequest,
    storeLeaveId,
    setLeaveIds,storeNumberOfDays
 }) {

    const [pageLoader, togglePageLoader] = useState(false);
    const [teacherLeaveRequest, setTeacherLeaveRequest] = useState(false)
   

    const history = useHistory();

    useEffect(() => {
        if (editRole === "Teacher") {
            togglePageLoader(true)
            getLeavesTabData(() => {
                togglePageLoader(false)
            })
        } else {

        }
    }, [editRole])

    return (
        <div style={{
            width: "calc(100% + 40px)",
            height: "calc(100% + 40px)",
            margin: -20,
            padding: 20,
            overflow: "auto"
        }}>
            {/* <div style={{fontWeight: "700"}}>
        <ArrowLeftOutlined style={{marginTop:"10px",fontSize: "22px"}} onClick={()=>(history.goBack())}/>
        </div> */}
            <div style={{ display: "flex", width: "100%" }}>
                {/* <div style={{width:"25%"}}><StudentDetail/></div> */}
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ font: "normal normal bold 18px/24px Roboto", fontSize: "16px", color: "#191919" }}>Leave summary</div>
                    {leavesTabDataContainer?.leaveTab?.leaveSummary?.length > 0 ?
                        <div className="flex-wrap" style={{ display: "flex", width: "100%" }}>
                            {leavesTabDataContainer?.leaveTab?.leaveSummary?.map((items, index) => (
                                <LeaveSummaryCard
                                    leaveType={items?.leaveType}
                                    balanceData={items?.remaining}
                                    availedData={items?.availed}
                                    totalData={items?.total}
                                    maxLeaves={items?.maxLeaves}
                                    togglePageLoader={togglePageLoader}
                                    leaveRequestObj={leaveRequestObj}
                                    user={user}
                                    index={index}
                                />
                            ))}</div>
                        :
                        <div style={{
                            width: "95%",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #E6E6E6",
                            borderRadius: "4px",
                            marginTop: "10px"
                        }}>
                            <div style={{ marginTop: "35px", marginBottom: "25px" }} >
                                <NodataCard />
                            </div>
                        </div>
                    }
                    <div className='flex-row m-t-30' style={{ justifyContent: "space-between" }}>
                    <div style={{ font: "normal normal bold 18px/24px Roboto", fontSize: "16px", color: "#191919",}}>Leave request history</div>
                    {
                    user?.privilage.includes("SuperAdmin") || user?.privilage.includes("LeaveManager")
                        ?
                        <div 
                        onClick={() => {
                            getAllLeave(); 
                            setTeacherLeaveRequest(true); 
                            setLeaveRequest('noOfdays', ''); 
                            setLeaveRequest('endDate', '');
                            setLeaveRequest('startDate', '');
                            setLeaveRequest('leaveType', '');
                            setLeaveRequest('reason', '')
                        }} 
                            className='cursor-pointer' 
                            style={{ fontSize: "14px", color: "#1089FF", fontWeight: 700 }}>MARK LEAVE(S)</div>
                        :
                        <></>
                }
                </div>
                   
                    {
                    teacherLeaveRequest && <TeacherRequestModal
                                                teacherLeaveRequest={teacherLeaveRequest}
                                                setTeacherLeaveRequest={setTeacherLeaveRequest}
                                                user={user}
                                                setLeaveRequest={setLeaveRequest}
                                                leaveRequestObj={leaveRequestObj}
                                                getAllLeave={getAllLeave}
                                                setAllLeaves={setAllLeaves}
                                                postMarkLeave={postMarkLeave}
                                                togglePageLoader={togglePageLoader}
                                                getLeavesTabData={getLeavesTabData}
                                            />

                    }
                    <div style={{
                        width: "95%",
                        height: "fit-content",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #E6E6E6",
                        borderRadius: "4px",
                        marginTop: "10px"
                    }}>
                        {console.log("AA-agaya",leavesTabDataContainer?.leaveTab?.leaveHistory)}
                        {leavesTabDataContainer?.leaveTab?.leaveHistory?.length > 0
                            ?
                            <div className="flex-wrap " style={{ display: "flex", }} >
                                
                                {leavesTabDataContainer?.leaveTab?.leaveHistory?.map((items) => (
                                    <div>
                                        {console.log("AAitems",items?.id)}
                                        <RequestHistoryCard
                                            leaveDuration={items.duration}
                                            leaveStartDate={items.startDate}
                                            leaveEndDate={items.endDate}
                                            leaveType={items.leaveType}
                                            requestType={items?.requestType}
                                            leaveStatus={items?.status}
                                            leaveReason={items.reason}
                                            leaveCardId={items.id}
                                            setCardId={setCardId}
                                            setActionData={setActionData}
                                            requestedActionData={requestedActionData}
                                            actionByImage={items.actionBy?.image}
                                            actionByName={items.actionBy?.name}
                                            actionByRole={items.actionBy?.role}
                                            getLeavesTabData={getLeavesTabData}
                                            tabDataContainer={tabDataContainer}
                                            togglePageLoader={togglePageLoader}
                                            user={user}
                                            requests={items?.requests}
                                            storeLeaveId={storeLeaveId}
                                            setLeaveIds={setLeaveIds}
                                            storeNumberOfDays={storeNumberOfDays}
                                            
                                        />
                                    </div>
                                ))}
                            </div>
                            :
                            <div style={{ marginTop: "35px", marginBottom: "25px" }}>
                                <NodataCard />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { leavesTabDataContainer, tabDataContainer, leaveRequestObj, setAllLeaves,storeLeaveId } =
        state.attendanceNew;
    const { editRole } = state.userManagement;
    const { user } = state.session;
    return {
        leavesTabDataContainer, tabDataContainer, editRole, user, leaveRequestObj, setAllLeaves,storeLeaveId
    };
};

const mapDispatchToProps = (dispatch) => ({
    getLeavesTabData: (callback) => (dispatch(getLeavesTabData(callback))),
    setCardId: (id) => dispatch(setCardId(id)),
    setActionData: (val) => dispatch(setActionData(val)),
    requestedActionData: (callback, successCallBack) => dispatch(requestedActionData(callback, successCallBack)),
    setLeaveRequest: (key, val) => dispatch(setLeaveRequest(key, val)),
    getAllLeave: (callBack) => dispatch(getAllLeave(callBack)),
    postMarkLeave: (callBack, successCallBack) => dispatch(postMarkLeave(callBack, successCallBack)),
    setLeaveIds:(val) => dispatch(setLeaveIds(val)),
    storeNumberOfDays:(val)=>dispatch(storeNumberOfDays(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeavesDashBoard);

// export default LeavesDashBoard