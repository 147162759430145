import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import "antd/lib/modal/style/index";
import TimePicker from "antd/lib/time-picker";
import "antd/lib/time-picker/style/index";
import Select from "antd/lib/select";
import "antd/lib/select/style/index";
import Button from "antd/lib/button";
import "antd/lib/button/style/index";
import { Color, Notification } from "../../services";
import AutoComplete from "antd/lib/auto-complete";
import "antd/lib/auto-complete/style/index";
import Input from "antd/lib/input";
import "antd/lib/input/style/index";
import moment from "moment";
import {
  postSchedule,
  handleInput,
  hideModal,
  searchUser,
  fetchClasslist,
  clearSingleSchedule,
} from "../../actions/calendar";
import { searchSubject } from "../../actions/classRoom";

const { Option } = Select;
const format = "HH:mm";
const days = [
  {
    value: "monday",
    label: "Monday",
  },
  {
    value: "tuesday",
    label: "Tuesday",
  },
  {
    value: "wednesday",
    label: "Wednesday",
  },
  {
    value: "thursday",
    label: "Thursday",
  },
  {
    value: "friday",
    label: "Friday",
  },
  {
    value: "saturday",
    label: "Saturday",
  },
  {
    value: "sunday",
    label: "Sunday",
  },
];

class AddScheduleModal extends Component {
  componentDidMount() {
    const { modes, handleInput } = this.props;
    if (modes && modes.length === 1) {
      handleInput(modes[0], "mode");
    }
  }

  handleSubjectSearch(query) {
    this.props.searchSubject(query);
    this.props.handleInput(-1, "selectedSubjectId");
  }

  handleModalCancel() {
    const { hideModal } = this.props;
    hideModal();
  }

  handleAssignClass() {
    const { singleSchedule } = this.props;
    // const standardId = singleSchedule.standardId;
    if (
      !singleSchedule ||
      !singleSchedule.selectedDays ||
      singleSchedule.selectedDays === "" ||
      !singleSchedule.mode ||
      singleSchedule.mode === "" ||
      !singleSchedule.selectedTeacherId ||
      singleSchedule.selectedTeacherId === "" ||
      !singleSchedule.startTime ||
      singleSchedule.startTime === "" ||
      !singleSchedule.endTime ||
      singleSchedule.endTime === "" ||
      !singleSchedule.selectedSubjectId ||
      singleSchedule.selectedSubjectId === "" ||
      !singleSchedule.selectedClasses ||
      !singleSchedule.selectedClasses?.length
    ) {
      return Notification.error(
        "error",
        "All mandatory fields must be filled!"
      );
    }
    if (
      singleSchedule.mode === "zoom" &&
      moment(singleSchedule.endTime).diff(
        moment(singleSchedule.startTime),
        "minutes"
      ) > 40
    ) {
      return Notification.error(
        "error",
        "A zoom class cannot be scheduled for more than 40 mins!"
      );
    }
    const params = {
      // standardId: parseInt(standardId),
      standardIds: singleSchedule.selectedClasses,
      startTime: moment(singleSchedule.startTime).format("HH:mm:ss"),
      teacherId: singleSchedule.selectedTeacherId,
      subjectId: singleSchedule.selectedSubjectId,
      endTime: moment(singleSchedule.endTime).format("HH:mm:ss"),
      weekdays: singleSchedule.selectedDays,
      scheduleId: singleSchedule.scheduleId ? singleSchedule.scheduleId : "",
      mode: singleSchedule.mode,
    };
    const otherParams = {
      classes: singleSchedule.selectedClasses,
      date: singleSchedule.selectedDate,
      section: singleSchedule.selectedClassNameSec,
    };
    this.props.postSchedule(params, otherParams);
    // this.props.handleInput(null,'startTime');
    // this.props.handleInput(null,'endTime');
    // this.props.handleInput([],'selectedClasses');
  }

  handleSubjSelect(subjectIndex) {
    const { subjects } = this.props;
    this.props.handleInput([], "selectedClasses");
    this.props.handleInput(subjects[subjectIndex].name, "selectedSubject");
    this.props.handleInput(subjects[subjectIndex].id, "selectedSubjectId");
    this.props.fetchClasslist(subjects[subjectIndex].id);
    // this.props.handleInput(subjects[subjectIndex].teacher.name,'selectedTeacher');
    // this.props.handleInput(subjects[subjectIndex].teacher.id,'selectedTeacherId');
  }

  handleDaySelect(value) {
    this.props.handleInput(value, "selectedDays");
  }

  handleTeacherSelect(teacher, obj) {
    if (obj.id === -1) {
      return console.log("return");
    }
    this.props.handleInput(obj.id, "selectedTeacherId");
  }

  handleAddClassToSchedule(std) {
    this.props.handleInput(std, "selectedClasses");
    if (std.length > 1) this.props.handleInput("All Classes", "selectedClass");
    else this.props.handleInput(std[0], "selectedClass");
  }

  handleSubjectSelect(subject, obj) {
    const { handleInput, fetchClasslist } = this.props;
    if (obj.id === -1) {
      return console.log("return");
    }
    handleInput([], "selectedClasses");
    handleInput(subject, "selectedSubject");
    handleInput(obj.id, "selectedSubjectId");
    fetchClasslist(obj.id);
  }

  componentWillUnmount() {
    this.props.clearSingleSchedule();
  }

  render() {
    const {
      togglePostLoader,
      singleSchedule,
      teachers,
      subjectsFound,
      searchLoader,
      subClassesLoader,
      subClasses,
      classModal,
      modes,
      handleInput,
    } = this.props;

    //

    return (
      <div>
        <Modal
          width={650}
          visible={classModal}
          onCancel={() => this.handleModalCancel()}
          closable={true}
          maskClosable={false}
          footer={[
            <div className="r-c-c">
              <Button
                className="mod-assign-btn"
                onClick={() => this.handleAssignClass()}
                loading={togglePostLoader}
                disabled={
                  !singleSchedule.selectedDays ||
                  !singleSchedule.selectedDays.length ||
                  !singleSchedule.mode ||
                  singleSchedule.mode === "" ||
                  !singleSchedule.selectedTeacherId ||
                  singleSchedule.selectedTeacherId === -1 ||
                  !singleSchedule.startTime ||
                  singleSchedule.startTime === "" ||
                  !singleSchedule.endTime ||
                  singleSchedule.endTime === "" ||
                  !singleSchedule.selectedSubject ||
                  singleSchedule.selectedSubject == -1 ||
                  !singleSchedule.selectedClasses ||
                  !singleSchedule.selectedClasses.length
                }
                style={{
                  opacity:
                    !singleSchedule.selectedDays ||
                    !singleSchedule.selectedDays.length ||
                    !singleSchedule.mode ||
                    singleSchedule.mode === "" ||
                    !singleSchedule.selectedTeacherId ||
                    singleSchedule.selectedTeacherId === -1 ||
                    !singleSchedule.startTime ||
                    singleSchedule.startTime === "" ||
                    !singleSchedule.endTime ||
                    singleSchedule.endTime === "" ||
                    !singleSchedule.selectedSubject ||
                    singleSchedule.selectedSubject == -1 ||
                    !singleSchedule.selectedClasses ||
                    !singleSchedule.selectedClasses.length
                      ? 0.5
                      : 1,
                }}
              >
                Add to Schedule
              </Button>
              <Button
                className="mod-cancel-btn"
                loading={false}
                onClick={() => this.handleModalCancel()}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <div className="display-flex m-b-20 m-t-20">
            <div className="m-r-20 flex-3">
              <div className="dark-grey m-b-5">
                Day(s)<span style={{ color: Color.red }}>*</span>
              </div>
              <div
                className="r-c-sb m-b-20"
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <Select
                  mode="multiple"
                  className="borderLessSelect bold-600"
                  placeholder="Pick day(s)"
                  value={singleSchedule.selectedDays}
                  style={{ width: 350, color: Color.primary, fontSize: 16 }}
                  onChange={(value) => this.handleDaySelect(value)}
                >
                  {days.map((item, i) => (
                    <Option key={i} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex-2">
              <div className="dark-grey m-b-5">
                Mode<span style={{ color: Color.red }}>*</span>
              </div>
              <div
                className="r-c-sb m-b-20"
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <Select
                  className="borderLessSelect bold-600"
                  value={singleSchedule.mode}
                  placeholder="Select Mode"
                  style={{
                    width: "100%",
                    color: Color.primary,
                    fontSize: 16,
                    textTransform: "capitalize",
                  }}
                  onChange={(value) => handleInput(value, "mode")}
                  disabled={modes && modes.length === 1}
                >
                  {modes.map((item, i) => (
                    <Option
                      style={{ textTransform: "capitalize" }}
                      key={i}
                      value={item}
                    >
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="display-flex">
            <div className="m-r-20">
              <div className="dark-grey m-b-5">
                Teacher<span style={{ color: Color.red }}>*</span>
              </div>
              <div
                className="r-c-sb m-b-20"
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                {/* <Input
                    className='borderLessSelect bold-600'
                    value={singleSchedule.selectedTeacher}
                    style={{ width: 210, color:Color.primary, fontSize:16 }}
                    disabled
                    /> */}
                <AutoComplete
                  options={teachers}
                  style={{
                    border: "none",
                    width: "100%",
                    color: Color.primary,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  onSelect={(value, obj) =>
                    this.handleTeacherSelect(value, obj)
                  }
                  onSearch={(query) => this.props.searchUser(query)}
                  value={singleSchedule.selectedTeacher}
                  onChange={(value) =>
                    this.props.handleInput(value, "selectedTeacher")
                  }
                >
                  <Input.Search
                    style={{ border: "none", borderRadius: 5, height: 32 }}
                    placeholder="Search name..."
                    loading={togglePostLoader}
                    allowClear
                  />
                </AutoComplete>
              </div>
            </div>
            <div className="m-r-20">
              <div className="dark-grey m-b-5">
                Start Time<span style={{ color: Color.red }}>*</span>
              </div>
              <div
                className="r-c-sb m-b-20"
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <TimePicker
                  className="borderLessTimePicker"
                  value={singleSchedule.startTime}
                  format={format}
                  onChange={(value) =>
                    this.props.handleInput(value, "startTime")
                  }
                />
              </div>
            </div>
            <div>
              <div className="dark-grey m-b-5">
                End Time<span style={{ color: Color.red }}>*</span>
              </div>
              <div
                className="r-c-sb m-b-20"
                dis
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <TimePicker
                  className="borderLessTimePicker"
                  value={singleSchedule.endTime}
                  format={format}
                  onChange={(value) => this.props.handleInput(value, "endTime")}
                  showNow={false}
                />
              </div>
            </div>
          </div>
          <div className="display-flex m-b-20 m-t-20">
            <div className="m-r-20">
              <div className="dark-grey m-b-5">
                Subject<span style={{ color: Color.red }}>*</span>
              </div>
              <div
                className="r-c-sb m-b-20"
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                {/*<Select
                      className='borderLessSelect bold-600'
                      value={singleSchedule.selectedSubject}
                      style={{ width: 140, color:Color.primary, fontSize:16, height: 30 }}
                      onChange={(value) => this.handleSubjSelect(value)}>
                      {subjects && subjects.map((item, i) => <Option value={i}>{item.name}</Option>)}
                      </Select>*/}
                <AutoComplete
                  value={singleSchedule.selectedSubject}
                  onChange={(value) =>
                    this.props.handleInput(value, "selectedSubject")
                  }
                  options={subjectsFound}
                  style={{
                    border: "none",
                    width: "100%",
                    color: Color.primary,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  onSelect={(value, obj) =>
                    this.handleSubjectSelect(value, obj)
                  }
                  onSearch={(query) => this.handleSubjectSearch(query)}
                >
                  <Input.Search
                    style={{ border: "none", borderRadius: 5, height: 32 }}
                    placeholder="Search subject..."
                    loading={searchLoader}
                    allowClear
                  />
                </AutoComplete>
              </div>
            </div>
            {singleSchedule.selectedSubjectId &&
            singleSchedule.selectedSubjectId !== -1 ? (
              <div className="m-r-20">
                <div className="dark-grey m-b-5">
                  Class(es)<span style={{ color: Color.red }}>*</span>
                </div>
                <div
                  className="r-c-sb m-b-20"
                  style={{
                    backgroundColor: "#F9F9F9",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Select
                    mode="multiple"
                    className="borderLessSelect bold-600"
                    value={singleSchedule.selectedClasses}
                    style={{ width: 350, color: Color.primary, fontSize: 16 }}
                    onChange={(value) => this.handleAddClassToSchedule(value)}
                    loading={subClassesLoader}
                    disabled={subClassesLoader}
                  >
                    {subClasses.map((item) => (
                      <Option value={item.id}>
                        {item.std}-{item.section}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    classModal,
    schedules,
    standards,
    subjects,
    togglePostLoader,
    singleSchedule,
    selectedClass,
    selectedClasses,
    teachers,
    subClasses,
    subClassesLoader,
    fetchScheduleLoader,
    filterParams,
    modes,
  } = state.calendar;
  const { searchLoader, subjectsFound } = state.classRoom;
  const { user } = state.session;

  return {
    classModal,
    schedules,
    standards,
    subjects,
    togglePostLoader,
    singleSchedule,
    selectedClass,
    selectedClasses,
    user,
    teachers,
    subClasses,
    subClassesLoader,
    searchLoader,
    subjectsFound,
    fetchScheduleLoader,
    filterParams,
    modes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postSchedule: (params, otherParams, callback) =>
    dispatch(postSchedule(params, otherParams, callback)),
  handleInput: (std, sec) => dispatch(handleInput(std, sec)),
  hideModal: () => dispatch(hideModal()),
  searchUser: (query) => dispatch(searchUser(query)),
  fetchClasslist: (subId) => dispatch(fetchClasslist(subId)),
  searchSubject: (query) => dispatch(searchSubject(query)),
  clearSingleSchedule: () => dispatch(clearSingleSchedule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddScheduleModal);
