import React, { useState } from 'react';
import { Button, Modal, Radio } from 'antd';

const PublishModal = ({ chooseModal, setChooseModal, patchPublishOffers, getSpecificCourseData, selectedOfferId, setAddFeeTermPlan, isPublish, togglePageLoader, courseTypeName, getSpecificSMData, getSpecificVideoCourseData, getOnlineTestSeriesCourseDetails }) => {
    console.log("selectedOfferId", isPublish);
    const [loader, setLoader] = useState(false)
    const handleOK = () => {
        if (courseTypeName == 'videoResources') {
            setLoader(true)
            patchPublishOffers(
                selectedOfferId, !isPublish,
                () => { },
                () => {
                    setLoader(false);
                    togglePageLoader(true),
                    setChooseModal(false)
                    getSpecificVideoCourseData(
                        () => { togglePageLoader(false) })
                }
            )
        } else if (courseTypeName == 'studyMaterials') {
            setLoader(true)
            patchPublishOffers(
                selectedOfferId, !isPublish,
                () => { },
                () => {
                    setLoader(false)
                    togglePageLoader(true),
                    setChooseModal(false)
                    getSpecificSMData(
                        () => { togglePageLoader(false) })
                }
            )
        } else if (courseTypeName == 'onlineTestSeries') {
            setLoader(true)
            patchPublishOffers(
                selectedOfferId, !isPublish,
                () => { },
                () => {
                    setLoader(false);
                    togglePageLoader(true),
                    setChooseModal(false)
                    getOnlineTestSeriesCourseDetails(
                        () => { togglePageLoader(false)})
                }
            )
        }
        else {
            setLoader(true)
            patchPublishOffers(
                selectedOfferId, !isPublish,
                () => { },
                () => {
                    setLoader(false)
                    setChooseModal(false)
                    togglePageLoader(true),
                        getSpecificCourseData(
                            () => { setAddFeeTermPlan(false), togglePageLoader(false) })
                }
            )
        }

    };

    const handleCancel = () => {
        setChooseModal(false);
    };


    return (
        <>

            <Modal
                className="modal-round-corner"
                open={chooseModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={false}
                bodyStyle={{ borderRadius: "10px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Confirm</div>
                <div
                    style={{
                        fontSize: "16px",
                        color: "#636363",
                        fontWeight: "bold",
                        marginTop: "10px",
                        textAlign: 'center'
                    }}
                >
                    Are you sure you want to {isPublish == false ? 'publish' : 'unpublish'} ?


                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                border: "1px solid  #636363",
                                backgroundColor: "#FFFFFF",
                                color: "#594099",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    color: "#636363",
                                    fontWeight: "bold",
                                }}
                                onClick={handleCancel}
                            >
                                NO
                            </div>
                        </Button>
                    </div>
                    <div>
                        <Button
                            loading={loader}
                            style={{
                                borderRadius: "19px",
                                width: "120px",
                                height: "35px",
                                border: "1px solid #594099 ",
                                backgroundColor: "#594099",
                                fontSize: "16px",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                            }}
                            onClick={() => { handleOK() }}

                        >
                            YES
                        </Button>
                    </div>
                </div>
            </Modal>

        </>
    );
};
export default PublishModal;