import moment from "moment";
import { Api } from "../services";

export const UPDATE_DASHBOARD_DATA = "UPDATE_DASHBOARD_DATA";
export const UPDATE_STUDENT_TEST_FILTER = "UPDATE_STUDENT_TEST_FILTER";
export const SET_SELECTED_SUB = "SET_SELECTED_SUB";
export const UPDATE_STUDENT_TEST_COUNT = "UPDATE_STUDENT_TEST_COUNT";
export const SET_SELECTED_TEST = "SET_SELECTED_TEST";
export const UPDATE_TEST_RESULT = "UPDATE_TEST_RESULT";
export const SET_TEST_DURATION = "SET_TEST_DURATION";
export const SET_CURRENT_STUDENT_QUESTION = "SET_CURRENT_STUDENT_QUESTION";
export const UPDATE_PALATE_DATA = "UPDATE_PALATE_DATA";
export const UPDATE_COUNT_DATA = "UPDATE_COUNT_DATA";
export const SET_CURRENT_INDEX = "SET_CURRENT_INDEX";
export const UPDATE_Q_PARAM = "UPDATE_Q_PARAM";
export const UPDATE_TEST_SUBS_LIST = "UPDATE_TEST_SUBS_LIST";
export const SET_SELECTED_SUB_INDEX = "SET_SELECTED_SUB_INDEX";
export const UPDATE_SOLUTION_COUNT_DATA = "UPDATE_SOLUTION_COUNT_DATA";
export const UPDATE_ANSWER_IMAGES = "UPDATE_ANSWER_IMAGES";
export const REMOVE_ANSWER_IMAGE = "REMOVE_ANSWER_IMAGE";
export const CLEAR_IMG_LIST = "CLEAR_IMG_LIST";
export const UPDATE_CURRENT_DURATION = "UPDATE_CURRENT_DURATION";
export const UPDATE_SOLUTION_SUBS_LIST = "UPDATE_SOLUTION_SUBS_LIST";
export const TOGGLE_OUT_OF_TIME_MODAL = "TOGGLE_OUT_OF_TIME_MODAL";
export const UPDATE_QUESTIONS_CONTAINER = "UPDATE_QUESTIONS_CONTAINER";
export const UPDATE_TEST_TYPE = "UPDATE_TEST_TYPE";
export const UPDATE_STUDENT_TEST_DATA = "UPDATE_STUDENT_TEST_DATA";
export const CHECK_FOR_QUES_TYPE = "CHECK_FOR_QUES_TYPE";
export const STORE_SOLUTION_QUES_TYPE = "STORE_SOLUTION_QUES_TYPE";
export const SET_CURRENT_STUDENT_QUESTION_RESULT = "SET_CURRENT_STUDENT_QUESTION_RESULT"
export const STOP_TIMER = 'STOP_TIMER'
export const UPDATE_CURRENT_Q_ID = 'UPDATE_CURRENT_Q_ID'
export const UPDATE_PREVIOUS_Q_ID = 'UPDATE_PREVIOUS_Q_ID'
export const SET_STOP_TIME = 'SET_STOP_TIME'

export const selectQIndex = (item, pageLoaderCallback, submitCallback = null) => {
  return (dispatch, getState) => {
    const { currentQ, currentDuration } =
      getState().studentTest;
    const params = {
      answerObj: currentQ.answerObj ?? null,
      testQuestionId: currentQ.id,
      files: currentQ?.files,
      duration: currentDuration,
      submitStatus: currentQ?.status
        ? currentQ?.status
        : currentQ?.attempt?.submitStatus
        ? currentQ?.attempt?.submitStatus
        : "Unanswered",
    };

    if(params.submitStatus == "AttemptedAndBookmarked" ||
    params.submitStatus == "Attempted") params.answerObj = currentQ?.attempt?.answerObj

    return Api.post(`/testv2/submit/student-ans`)
      .params(params)
      .send((response, error) => {
        if (response !== undefined) {
          dispatch(updatePreviousQId(currentQ.id))
          dispatch(updateCurrentQId(item.qId))
          dispatch(setCurrentIndex(item.index));
          pageLoaderCallback(true);

          dispatch(fetchTestQuestions(pageLoaderCallback, submitCallback));
          dispatch(clearImageList());
          // Notification.success("success", "Answer Saved!")
        }
        else pageLoaderCallback(false)
      });
  };
};

export const updateCurrentDuration = (seconds) => ({
  type: UPDATE_CURRENT_DURATION,
  seconds,
});

export const setStopTime =(res)=>({
  type:SET_STOP_TIME,
  res
})

export const toggleOutOfTimeModal = (value) => ({
  type: TOGGLE_OUT_OF_TIME_MODAL,
  value,
});

export const finalSubmit = (buttonCallback, successCallback) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentIndex, currentDuration } =
      getState().studentTest;
    const params = {
      answerObj: currentQ.answerObj || null,
      testQuestionId: currentQ.id,
      files: currentQ?.files,
      duration: currentDuration,
      submitStatus: currentQ?.status
        ? currentQ?.status
        : currentQ?.attempt?.submitStatus
        ? currentQ?.attempt?.submitStatus
        : "Unanswered",
      testStatus: "Submitted",
    };

    if(params.submitStatus == "AttemptedAndBookmarked" ||
    params.submitStatus == "Attempted") params.answerObj = currentQ?.attempt?.answerObj

    return Api.post(`/testv2/submit/student-ans`)
      .params(params)
      .send((response, error) => {
        buttonCallback();
        if (response !== undefined) {
          successCallback();
        }
      });
  };
};

export const clearImageList = () => ({
  type: CLEAR_IMG_LIST,
});

export const removeAnswerImage = (index) => ({
  type: REMOVE_ANSWER_IMAGE,
  index,
});

export const updateImage = (base64Img, originalObject) => ({
  type: UPDATE_ANSWER_IMAGES,
  base64Img,
  originalObject,
});

export const setSelectedSubIndex = (index) => ({
  type: SET_SELECTED_SUB_INDEX,
  index,
});

export const handleSelectSubject = (index, pageLoaderCallback) => {
  return (dispatch, getState) => {
    const { palateData, currentQId } = getState().studentTest;
    dispatch(setSelectedSubIndex(index));
    let qLength = 1;
    for (let i = 0; i <= index - 1; i++) {
      qLength += parseInt(palateData[i].subjectQuestionCount);
    }
    dispatch(setCurrentIndex(qLength));
    pageLoaderCallback(true)
    dispatch(selectQIndex(palateData[index].sections[0].questions[0], pageLoaderCallback));
  };
};

export const handleSubjectSelection = (index, loaderCallback) => {
  return (dispatch, getState) => {
    const { testSubjects } = getState().studentTest;
    let qLength = 0;
    for (let i = 0; i <= index - 1; i++) {
      qLength += parseInt(testSubjects[i].questionCount);
    }
    dispatch(setCurrentIndex(qLength));
  };
};

export const navigateQuestion = (
  dir,
  buttonLoaderCallBack,
  pageLoaderCallback
) => {
  return (dispatch, getState) => {
    const { currentQ, palateData, currentIndex, currentDuration } =
      getState().studentTest;
    const params = {
      answerObj: currentQ.answerObj || null,
      testQuestionId: currentQ.id,
      files: currentQ?.files,
      duration: currentDuration,
      submitStatus: currentQ?.status
        ? currentQ?.status
        : currentQ?.attempt?.submitStatus
        ? currentQ?.attempt?.submitStatus
        : "Unanswered",
    };

    if(params.submitStatus == "AttemptedAndBookmarked" ||
    params.submitStatus == "Attempted") params.answerObj = currentQ?.attempt?.answerObj

    return Api.post(`/testv2/submit/student-ans`)
      .params(params)
      .send((response, error) => {
        buttonLoaderCallBack(false);
        if (response !== undefined) {
          const index = dir === "next" ? currentIndex + 1 : currentIndex - 1;

          if(index > palateData?.at(-1)?.endIndex) index = palateData?.at(-1)?.endIndex;
          if(index < 1) index = 1;
          
          dispatch(updatePreviousQId(currentQ.id))

          palateData.forEach(subject => {
            subject.sections.forEach((section) => {
              section.questions.forEach((question) => {
                if(question.index == index) 
                  dispatch(updateCurrentQId(question.qId))
              })
            })
          });

          dispatch(setCurrentIndex(index));
          pageLoaderCallback(true);
          dispatch(fetchTestQuestions(pageLoaderCallback));
          dispatch(clearImageList());
        }
      });
  };
};

export const removeAnswer = (
  status = null,
  history = null,
  buttonLoaderCallBack = null,
  pageLoaderCallback = null
) => {
  const url = `/testv2/submit/student-ans`;
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentDuration } = getState().studentTest;
    const params = {
      answerObj: currentQ.answerObj,
      testQuestionId: currentQ.id,
      duration: currentDuration,
      submitStatus: status,
      files: "[]",
      deletedFiles: currentQ?.attempt?.filename
        ? currentQ.attempt.filename
        : [],
    };
    // if(currentQ.type !== 'Image'){
    return Api.post(`/testv2/submit/student-ans`)
      .params(params)
      .send((response, error) => {
        buttonLoaderCallBack(false);
        pageLoaderCallback(false);
        dispatch(clearImageList());
        if (response !== undefined) {
          dispatch(clearImageList());
        }
      });
    // }else{

    // delete params.answer;
    // // Object.assign(params, {deletedFiles: currentQ.deletedFiles ? JSON.stringify(currentQ.deletedFiles) : "[]"})
    // return Api.post("/testv2/submit/student-ans").upload(
    //     params, (percent, response) => {
    //         // dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
    //         if (percent === -1) {
    //             buttonLoaderCallBack(false)
    //         }
    //         if(response !== undefined){
    //         Notification.success("success", "Answer Saved!")
    //         if(percent === 100){
    //             buttonLoaderCallBack(false)
    //             dispatch(clearImageList())
    //             // dispatch(updateCurrentQAndNext(finalStatus))
    //         }
    //         }
    //     }
    //     );
    // }
  };
};

export const saveNext = (
  status = null,
  history = null,
  finalSubmit = false,
  buttonLoaderCallBack = null,
  pageLoaderCallback = null
) => {
  return (dispatch, getState) => {
    const { currentQ, currentTest, currentIndex, currentDuration, palateData } =
      getState().studentTest;
    let finalStatus = "";
    if (
      status === "Bookmarked" &&
      (currentQ.studentAnswer || currentQ.files)
    ) {
      finalStatus = "AttemptedAndBookmarked";
    } else if (
      (status === "Attempted" || status === "AttemptedAndBookmarked") &&
      (
        !currentQ.answerObj ||
        currentQ.answerObj === "" // ||
        // (currentQ?.files?.length + currentQ?.attempt?.filename?.length - currentQ?.deletedFiles)  --> NaN
      ) && currentQ.type != "Subjective"
    ) {
      finalStatus = "Unanswered";
    } else {
      finalStatus = status;
    }
    const params = {
      answerObj: currentQ.answerObj,
      testQuestionId: currentQ.id,
      duration: currentDuration,
      submitStatus: finalStatus,
      files: currentQ?.files,
      testStatus: finalSubmit === true ? "Submitted" : "Draft",
    };
    
    if (currentQ.type !== "Subjective") {
      return Api.post(`/testv2/submit/student-ans`).params(params)
      .send((response, error) => {
          buttonLoaderCallBack(false);
          if (response !== undefined) {
            // Notification.success("success", "Answer Saved!")
            if (!finalSubmit) {
              const index =
                currentIndex < palateData?.at(-1)?.endIndex
                  ? currentIndex + 1
                  : currentIndex;
                  dispatch(updatePreviousQId(currentQ.id))

                  palateData.forEach(subject => {
                    subject.sections.forEach((section) => {
                      section.questions.forEach((question) => {
                        if(question.index == index) 
                          dispatch(updateCurrentQId(question.qId))
                      })
                    })
                  });
        
                  dispatch(setCurrentIndex(index));
                  pageLoaderCallback(true);
                  dispatch(fetchTestQuestions(pageLoaderCallback));
            } else {
              history.push("/student-test-summary");
            }
            dispatch(clearImageList());
          }
        }
      );
    } else {
      delete params.answerObj;
      Object.assign(params, {
        deletedImages: currentQ.deletedFiles ? currentQ.deletedFiles : [],
      });
      console.log("params ", params);
      return Api.post("/testv2/submit/student-ans").upload(
        params,
        (percent, response) => {
          // dispatch(testSutdentLoader(STUDENT_TEST_POST_QUES_LOADER, 'studentTestPostQuesLoader',  false));
          if (percent === -1) {
            buttonLoaderCallBack(false);
          }
          if (response !== undefined) {
            if (percent === 100) {
              buttonLoaderCallBack(false);
              // dispatch(updateCurrentQAndNext(finalStatus));
              if (!finalSubmit) {
                const index =
                  currentIndex < palateData?.at(-1)?.endIndex
                    ? currentIndex + 1
                    : currentIndex;

                dispatch(updatePreviousQId(currentQ.id))

                palateData.forEach(subject => {
                  subject.sections.forEach((section) => {
                    section.questions.forEach((question) => {
                      if(question.index == index) 
                        dispatch(updateCurrentQId(question.qId))
                    })
                  })
                });
      
                dispatch(setCurrentIndex(index));
                pageLoaderCallback(true);
                dispatch(fetchTestQuestions(pageLoaderCallback));
              } else {
                history.push("/student-test-summary");
              }
              dispatch(clearImageList());
            }
          }
        }
      );
    }
  };
};

export const updateQParam = (val, key) => ({
  type: UPDATE_Q_PARAM,
  qParam: { [key]: val },
});

export const setCurrentIndex = (index) => ({
  type: SET_CURRENT_INDEX,
  index,
});

const updateCountData = (value, key) => ({
  type: UPDATE_COUNT_DATA,
  countData: { [key]: value },
});

const updateSolutionCountData = (value, key) => ({
  type: UPDATE_SOLUTION_COUNT_DATA,
  solutionCountData: { [key]: value },
});

const updatePalateData = (data) => ({
  type: UPDATE_PALATE_DATA,
  data,
});

export const setCurrentStudentQ = (data) => ({
  type: SET_CURRENT_STUDENT_QUESTION,
  data,
});

export const setCurrentStudentQResult = (data) => ({
  type: SET_CURRENT_STUDENT_QUESTION_RESULT,
  data,
});

const updateTestSubList = (data) => ({
  type: UPDATE_TEST_SUBS_LIST,
  data,
});

const updateSolutionSubList = (data) => ({
  type: UPDATE_SOLUTION_SUBS_LIST,
  data,
});

export const questionType = (res) => ({
  type: CHECK_FOR_QUES_TYPE,
  res,
});


export const updateCurrentQId = (currentQId) => ({
  type: UPDATE_CURRENT_Q_ID,
  currentQId,
});

export const updatePreviousQId = (previousQId) => ({
  type: UPDATE_PREVIOUS_Q_ID,
  previousQId,
});

export const fetchTestDetails = (loaderCallback, successCallback) => {
  return (dispatch, getState) => {
    loaderCallback(true);
    const { selectedTest } = getState().studentTest;
    const params = {};

    return new Promise((resolve, reject) => {

      Api.get(
        `/test/test-by-id-student/${selectedTest.id}`
      )
        .params(params)
        .send((response, error) => {
          if (response !== undefined) {
            dispatch(updatePalateData(response.subjectSectionDetails));
            dispatch(updateTestSubList(response.test.subjects));

            dispatch(updateCurrentQId(response.subjectSectionDetails[0].sections[0].questions[0].qId));
            dispatch(setCurrentIndex(response.subjectSectionDetails[0].sections[0].questions[0].index));

            successCallback();
          }
          loaderCallback(false);
        });
    });
  };
}


export const fetchTestQuestions = (loaderCallback, submitCallback = null) => {

  return (dispatch, getState) => {
    loaderCallback(true);
    const { selectedTest, currentQId, previousQId, palateData } = getState().studentTest;
    const params = {};
    return new Promise((resolve, reject) => {
      Api.get(
        `/test/question-ongoing/${selectedTest.id}?currentQId=${
          currentQId
        }&previousQId=${
          previousQId
        }`
      )
        .params(params)
        .send((response, error) => {
          if (response !== undefined) {
            dispatch(questionType(response.question.type));
            dispatch(setCurrentStudentQ(response.question));
            dispatch(updateCountData(response.attempted, "attempted"));
            dispatch(
              updateCountData(
                response.attemptedAndBookmarked,
                "attemptedAndBookmarked"
              )
            );

            dispatch(updateCountData(response.bookmarked, "bookmarked"));
            dispatch(updateCountData(response.unanswered, "unanswered"));
            dispatch(updateCountData(response.unseen, "unseen"));

            const { currentQStatus, previousQStatus } = response;
            palateData.forEach(subject => {
              subject.sections.forEach((section) => {
                section.questions.forEach((question) => {
                  if(question.qId == currentQStatus.qId) question.status = currentQStatus.status
                  if(question.qId == previousQStatus.qId) question.status = previousQStatus.status
                })
              })
            });

            dispatch(updatePalateData(palateData));
            submitCallback()
          }
          loaderCallback(false);
        });
    });
  };
};

const getPalateData = (solutions) => {
  let palette = [];
  if (solutions != null) {
    solutions.map((item, index) => {
      palette.push({
        status:
          item.submitStatus === "Attempted" ||
          item.submitStatus === "AttemptedAndBookmarked"
            ? item.correct
            : item.submitStatus != null
            ? item.submitStatus
            : "Unseen",
      });
    });
  }
  return palette;
};

export const storeSolQuesType = (res) => ({
  type: STORE_SOLUTION_QUES_TYPE,
  res,
});

export const fetchTestSolutions = (
  index = 1,
  loaderCallback,
  buttonLoaderCallBack
) => {
  return (dispatch, getState) => {
    loaderCallback(true);
    buttonLoaderCallBack(true);
    const { selectedTest } = getState().studentTest;
    const params = {};

    return Api.get(
      `/testv2/student-solution/?testId=${selectedTest.id}&page=${index}`
    )
      .params(params)
      .send((response, error) => {
        if (response !== undefined) {
          dispatch(
            storeSolQuesType(response?.items?.map((item) => item?.type))
          );
          console.log("response", response);
          const resData = response.items[0];
          dispatch(setCurrentStudentQResult(resData));
          let PalateData = getPalateData(response.items);
          dispatch(updatePalateData(PalateData));
          console.log("updated palate data", PalateData);
          dispatch(
            updateSolutionCountData(response.unattemptedCount, "unattempted")
          );
          dispatch(updateSolutionCountData(response.correctCount, "correct"));
          dispatch(
            updateSolutionCountData(response.inCorrectCount, "incorrect")
          );
          dispatch(
            updateSolutionCountData(
              response.partialCorrectCount,
              "partialCorrect"
            )
          );
          dispatch(updateSolutionCountData(response.unSeenCount, "unseen"));
          dispatch(updateQuestionsContainer(response.items));
          dispatch(updateTestSubList(response.subjects));
          dispatch(setSelectedTest({...selectedTest, title: response?.test?.title}));
        }
        buttonLoaderCallBack(false);
        loaderCallback(false);
      });
  };
};
const updateQuestionsContainer = (questions) => ({
  type: UPDATE_QUESTIONS_CONTAINER,
  questions,
});
export const fetchTestSummary = (loaderCallback) => {
  return (dispatch, getState) => {
    loaderCallback(true);
    const { selectedTest } = getState().studentTest;
    const params = {};
    return Api.get(`/testv2/student-solution-summary/?testId=19`).send(
      (response, error) => {
        if (response !== undefined) {
          const resData = response.summary[0];
          dispatch(updatePalateData(response.matrix));
          dispatch(
            updateSolutionCountData(resData.totalUnattempted, "unanswered")
          );
          dispatch(updateSolutionCountData(resData.correctSum, "correct"));
          dispatch(updateSolutionCountData(resData.wrongSum, "incorrect"));
          dispatch(
            updateSolutionCountData(resData.partialCorrectSum, "partialCorrect")
          );
          dispatch(updateSolutionSubList(response.subjects));
        }
        loaderCallback(false);
      }
    );
  };
};

export const setDuration = (duration) => ({
  type: SET_TEST_DURATION,
  duration,
});

export const updateTestResult = (data) => ({
  type: UPDATE_TEST_RESULT,
  data,
});

export const setSelectedTest = (data) => ({
  type: SET_SELECTED_TEST,
  data,
});

export const fetchResult = (callback) => {
  return (dispatch, getState) => {
    const { selectedTest } = getState().studentTest;
    console.log("AA-selectedTest",selectedTest,selectedTest?.meta?.details);
    const params = {
      testId: selectedTest?.id,
    };
    return Api.get(`/testv2/get/student-result`)
      .params(params)
      .send((response, error) => {
        callback();
        if (response !== undefined) {
          dispatch(updateTestResult(response));
        }
      });
  };
};

const updateSubTestCount = (count) => ({
  type: UPDATE_STUDENT_TEST_COUNT,
  count,
});

export const setSelectedSub = (data) => ({
  type: SET_SELECTED_SUB,
  data,
});

export const fetchSubPageData = (callback) => {
  return (dispatch, getState) => {
    const { studentTestFilterParams, selectedSub } = getState().studentTest;
    const params = {
      status: studentTestFilterParams.testStatus,
      subjectId: selectedSub.subjectId,
    };
    return Api.get(`/testv2/get/subwise-tests-for-student`)
      .params(params)
      .send((response, error) => {
        callback();
        if (response !== undefined) {
          dispatch(updateDashboardData(response.data));
          dispatch(updateSubTestCount(response.total));
        }
      });
  };
};

export const updateStudentTestFilter = (val, key) => ({
  type: UPDATE_STUDENT_TEST_FILTER,
  filterParams: { [key]: val },
});

const updateDashboardData = (data) => ({
  type: UPDATE_DASHBOARD_DATA,
  data,
});

export const fetchDashBoardData = (
  callback = null,
  startDate = null,
  endDate = null
) => {
  return (dispatch, getState) => {
    const { studentTestFilterParams } = getState().studentTest;
    const params = {};
    if (studentTestFilterParams.rangeType == "custom") {
      params.startDate = startDate
        ? moment(startDate, "DD/MM/YYYY").format("YYYY/MM/DD")
        : null;
      params.endDate = endDate
        ? moment(endDate, "DD/MM/YYYY").format("YYYY/MM/DD")
        : null;
    } else {
      params.filter = studentTestFilterParams.rangeType;
    }
    return Api.get(`/testv2/get/all-tests-for-student`)
      .params(params)
      .send((response, error) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          dispatch(updateDashboardData(response));
        }
      });
  };
};

export const updateTestType = (testType) => ({
  type: UPDATE_TEST_TYPE,
  testType,
});

export const UpdateStudentTestData = (key, val) => ({
  type: UPDATE_STUDENT_TEST_DATA,
  key,
  val,
});

export const fetchChapterWiseTest = (standardId, callback = null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParam, studentTestData } = getState().studentTest;
    const params = {
      syllabus: studentTestData?.selectedTestCard?.testType,
      search: studentTestData?.search,
      standardId: standardId
    };

    return Api.get(`/testv2/student/syllabus-tests`)
      .params(params)
      .send((response, error) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          dispatch(UpdateStudentTestData("testData", response));
          console.log(response);
          // callback();
        }
      });
  };
};

export const getChapterWiseTest = (standardId, callback = null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParam, studentTestData } = getState().studentTest;
    const params = {
      subjectId: studentTestData?.selectedChapterTestCard?.subjectId,
      search: studentTestData?.search,
      standardId: standardId
    };
    return Api.get(`/testv2/student/chapter-test-chapters`)
      .params(params)
      .send((response, error) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          dispatch(UpdateStudentTestData("chapterWiseTestData", response));
          console.log(response);
        }
      });
  };
};

export const fetchStatusWiseTest = (standardId, callback = null) => {
  return (dispatch, getState) => {
    const { studentTestFilterParam, studentTestData, studentTestFilterParams } =
      getState().studentTest;
    const params = {
      status: studentTestFilterParams.testStatus,
      syllabus: studentTestData?.selectedTestCard?.testType,
      subjectId:
        studentTestData?.selectedTestCard?.testType != "Chapter Test"
          ? studentTestData?.selectedChapterTestCard?.subjectId
          : null,
      chapterId:
        studentTestData?.selectedTestCard?.testType == "Chapter Test"
          ? studentTestData?.selectedChapter?.chapterId
          : null,
      combined: studentTestData?.selectedChapterTestCard?.combinedTest
        ? studentTestData?.selectedChapterTestCard?.combinedTest
        : null,
      testType: studentTestData?.type,
      page: -1,
      // page:studentTestData?.page,
      search: studentTestData?.search,
      standardId: standardId
    };
    return Api.get(`/testv2/get/subwise-tests-for-student`)
      .params(params)
      .send((response, error) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          // dispatch(updateDashboardData(response));
          dispatch(UpdateStudentTestData("statusWiseTest", response));
          // dispatch(updateDashboardData(response));

          console.log(response);
        }
      });
  };
};

export const setStopTimer = (res) => ({
  type: STOP_TIMER,
  res
});