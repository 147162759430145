import React from 'react'
import {Button, Dropdown, Tooltip} from "antd"
import { MdMoreVert } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import CustomMenu from 'pages/practiseTest/localComponents/customMenu';
import { connect } from 'react-redux';
import { setCModalType, setIsCModalVisible, setSelectedClassroom,
    setSelectedClassroomId, updateSelectedTest, updateSelectedTestId, createTestModal,
    storeGenerateQuestionsObj, showGenerateQuesListView, updateListViewType, fetchTestSubmitDetails,
    updateSubmittedTestsId,
    universalLoader,
    updateSelectedCardType,
    changeTestStatusModal,
    getTestClassList,
    storeCreateTestModalData,
    getEachQuesData,
    updateFilter} from 'actions/practiseTest';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
const chapterTestImg = require("Assets/Practise Test/ic-chapter-test.svg").default
const fullTestImg = require("Assets/Practise Test/ic-full-test.svg").default
const partTestImg = require("Assets/Practise Test/ic-part-test.svg").default
const previousYearImg = require("Assets/Practise Test/ic-previous-test.svg").default
const noOfComment = require("Assets/Practise Test/Group 13000.svg").default

function TestCard({
    type, imgval, test, page, index,ispublished,sectionid, classdata, class_std_name, setSelectedClassroom, 
    setSelectedClassroomId, handleResultCardClick, isPublishButtonVisible, fromTodayActivities,
    updateSelectedTest, updateSelectedTestId, setCModalType, setIsCModalVisible, changeTestStatusModalBool,
    cModalType, createTestModal, storeGenerateQuestionsObj, showGenerateQuesListView,
    createTestModalBool, generateQuesListViewBool, updateListViewType, updateSubmittedTestsId,
    fetchTestSubmitDetails,sendForFeedback, userType, dash=false, user, universalLoader, editDetailsLoader, 
    cloneLoader, updateSelectedCardType, changeTestStatusModal, getTestClassList, storeCreateTestModalData,
    getEachQuesData, updateFilter, }) {
    const history = useHistory();

    const handleButtonClick = (id, type, test, e)=>{
        // setSelectedId(id);
        e.stopPropagation();
        updateSelectedTestId(id);
        if (type=="Publish"){
            updateSelectedTest(test);
            changeTestStatusModal("Published");

        }
        else if (type=="Review"){
            updateSelectedTest(test);
            changeTestStatusModal("SendReview");
        }
        else if (type=="Delete"){
            updateSelectedTest(test);
            changeTestStatusModal("Deleted");
        }
        else if (type=="Unpublish"){
            changeTestStatusModal("Draft");
        }
        else if (type=="MarkReady"){
            updateSelectedTest(test);
            changeTestStatusModal("MarkReady");
            // setCModalType("")
            // setIsCModalVisible(true)
        }
        else if (type=="Edit"){
            if (generateQuesListViewBool){
                return
            }
            universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', true);
            updateListViewType('edit');
            updateSelectedTest(test);
            showGenerateQuesListView(()=>{
                universalLoader('EDIT_DETAILS_LOADER', 'editDetailsLoader', false);
                createTestModal("test-edit", user, 'dropdown');
            }, "tabview");
        }
        else if (type=="Clone"){
            if (generateQuesListViewBool){
                return
            }
            universalLoader('CLONE_LOADER', 'cloneLoader', true);
            updateListViewType('clone');
            updateSelectedTest(test);
            showGenerateQuesListView((classIds, chapters)=>{
                console.log("chapter xpxs", chapters);
                const chapterList = [];
                for (let subject of chapters){
                    for (let chapter of subject.chapters){
                        chapterList.push(chapter.id);
                    }
                }
                getTestClassList(classIds, chapterList);
                universalLoader('CLONE_LOADER', 'cloneLoader', false);
                createTestModal("test-clone", user, 'dropdown');
                storeCreateTestModalData('tIndex', '');
            }, "tabview");
        }
    }
    const publishTest = (test, id) => {
        return (e)=>{
            e.stopPropagation();
            updateSelectedTestId(id);
            updateSelectedTest(test);
            changeTestStatusModal("Published");
        }
    }
    const onDraftClick = () => {
        updateSelectedCardType('Draft');
        updateListViewType('edit');
        updateSelectedTest(test);
        // togglePageLoader(true);
        showGenerateQuesListView(()=>{
            history.push("practiseTest-questionScreen")
            // togglePageLoader(false);
        },'tabview');
      }
    const handleReadyCardClick = (e) => {
        e.stopPropagation();
        updateListViewType('edit');
        updateSelectedCardType('Ready');
        updateSelectedTest(test);
        // togglePageLoader(true);
        showGenerateQuesListView(()=>{
            history.push("practiseTest-questionScreen")
            // togglePageLoader(false);
        },'tabview');
    }
    const onFeedbackClick = () => {
        updateListViewType('edit');
        updateSelectedTest(test);
        updateSelectedCardType('Feedback');
        // togglePageLoader(true);
        showGenerateQuesListView(()=>{
            history.push("practiseTest-questionScreen")
            // togglePageLoader(false);
        },'tabview');
    }
    const handleUpcomingClick = () => {
        updateListViewType('no-edit');
        updateSelectedTest(test);
        updateSelectedCardType('adminlive');
        // togglePageLoader(true);
        showGenerateQuesListView((response)=>{
            if (response && response?.subjects?.length>0 && response?.subjects[0]?.questions?.length>0){
                getEachQuesData(response?.subjects[0]?.questions[0]?.id);
            }
            history.push('practise-test-noneditable-questions');
            // togglePageLoader(false);
        },'tabviewlive');
    }
    if (type==='ready'){
        return (
            <>
            <div className='readyTo_Publish display-flex full-width m-b-10 m-r-10 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
                        width: 400,
                        backgroundColor: "#FFFFFF", 
                        border: "1px solid #E6E6E6",     
                        borderRadius: 10,
                        height:145,
                        // position: 'absolute'
            }} onClick={handleReadyCardClick}>
            <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
            <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                        test?.syllabus==='Part Test'?partTestImg:
                        test?.syllabus==='Full Test'?fullTestImg : test?.syllabus==='Previous Year Paper'?previousYearImg:null
                        }/>
            </Tooltip>                
                <div style={{display: "flex", flexDirection: "column",}}>
            <div className='bold-800 m-t-20 m-l-10'>{test.title}</div>
                <div className='text-xs m-l-10 m-t-5 text-overflow-ellipsis' style={{maxWidth: 250}}>{test.standard} | {test.subjects?.map((sub)=>(sub.name))?.join(' • ')}</div>
                    <div className='text-xs m-l-10'>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
                        <div className='r-jsb text-xs m-l-10 m-t-5'>
                        <div className='flex-column text-xs'>
                            <div style={{color: '#AEAEAE'}}> CREATED ON </div>
                        <div style={{fontWeight: "bold"}} className='bold-300r'>{test.createdAt!=undefined ?moment.utc(test.createdAt).local().format("DD/MM/YYYY • hh:mm A"):null}</div>
            </div>
            {
                userType==='admin'?
                <Button className='backBlue white radius-100 text-xmd' style={{width: 80, height: 27, position:"absolute", bottom:20, right:15}} onClick={publishTest(test, test.id)}>
                    <div>Publish</div>
                </Button>  :null
            }
 
            </div>
            </div>
            <Dropdown 
                // style={{position: 'relative'}}
                getPopupContainer={(trigger) => trigger.parentElement}
                overlay={<CustomMenu type={"ready"} 
                test={test} 
                handleButtonClick={handleButtonClick}
                changeTestStatusModalBool={changeTestStatusModalBool}
                                     createTestModalBool={createTestModalBool}
                                    generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                    editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                    />}
                                                placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                        <MdMoreVert 
                                            className='text-lg a2grey cursor-pointer' 
                                            style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}
                                        />
                                    </Dropdown>
            </div>
            </>
        )
    }else if(type==='feedback'){
        if (page==='home'){
            return(
                <>
                <div className='m-l-10 display-flex cursor-pointer flex-wrap full-width text-overflow-ellipsis' style={{
                                width: 400,
                                backgroundColor: "#FFFFFF", 
                                border: "1px solid #E6E6E6", 
                                borderRadius: 10,
                                height:120,
                                position:"relative",
                                marginBottom: 10
                    }} onClick={onFeedbackClick}>
                <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
            <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                        test?.syllabus==='Part Test'?partTestImg:
                        test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null
                        }/>
            </Tooltip>   
                <div className='m-t-10' style={{display: "flex", flexDirection: "column"}}>
                <div className='bold-800 m-t-20 m-l-10 display-flex'>{test.title}<span className="m-l-10 radius-5 bold-700 orange-yellow absolute r-c-c" style={{height: 20, width: 20, backgroundColor:"#FFF4D5", fontSize:"9px", marginLeft: 220, borderRadius: 10, textAlign: 'center'}}> {test?.reviewCount}
                </span></div>
                <div className='text-xs m-l-10 m-t-5 text-overflow-ellipsis'>{test.standard} | {test.subjects?.map((sub)=>(sub.name))?.join(' • ')} </div>
                    <div className='text-xs m-l-10 '>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
                    </div>
                    <Dropdown 
                    getPopupContainer={(trigger) => trigger.parentElement}
                    overlay={<CustomMenu type={"feedback"} 
                    test={test} 
                    handleButtonClick={handleButtonClick}
                    changeTestStatusModalBool={changeTestStatusModalBool}
                                         createTestModalBool={createTestModalBool}
                                        generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                        editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                        />}
                                                    placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                            <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                                        </Dropdown>
                    </div>
                </>
            )
        }else if (page==='tab'){
            return(
                <>
                <div className='readyTo_Publish m-l-10 display-flex full-width m-b-10 m-t-20 customCardShadow cursor-pointer shadow-box hover-shadow' style={{
                        width: 400,
                        backgroundColor: "#FFFFFF", 
                        border: "1px solid #E6E6E6", 
                        borderRadius: 10,
                        height:120,
                        position:"relative"
                    }} onClick={onFeedbackClick}>
                        <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
            <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                        test?.syllabus==='Part Test'?partTestImg:
                        test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null
                        }/>
            </Tooltip>   
                        <div className='m-t-20' style={{display: "flex", flexDirection: "column"}}>
                    <div className='bold-700  m-l-10 text-overflow-ellipsis'>{test.title} <span className="m-l-10 radius-5 bold-700 orange-yellow absolute r-c-c" style={{height: 20, width: 20, backgroundColor:"#FFF4D5", fontSize:"9px", marginLeft: 220, borderRadius: 10, textAlign: 'center'}}> {test?.reviewCount}
                </span></div>                  
                    <div className='text-xs m-l-10  m-t-5'>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
                    </div>
                    <Dropdown 
                        getPopupContainer={(trigger) => trigger.parentElement}
                        overlay={<CustomMenu type={"feedback"} 
                        test={test} 
                        handleButtonClick={handleButtonClick}
                        changeTestStatusModalBool={changeTestStatusModalBool}
                                             createTestModalBool={createTestModalBool}
                                            generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                            editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                            />}
                                                        placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                                <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                                            </Dropdown>
                    </div>
                        </>
            )
    }
    }else if(type==='review'){
        if (page==='home'){
            return(
                <div className='readyTo_Publish m-l-10 display-flex full-width m-b-10 customCardShadow cursor-pointer shadow-box hover-shadow relative' 
                style={{width: 400, backgroundColor: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: 10, height:120}} onClick={onFeedbackClick}>
                <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
                    <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                            test?.syllabus==='Part Test'?partTestImg:test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null}/>
                </Tooltip>
            <div className='m-t-20' style={{display: "flex", flexDirection: "column"}}>
        <div className='bold-700  m-l-10 text-overflow-ellipses'>{test.title}</div>
                <div className='text-xs m-l-10 m-t-5'>{test.standard} | {test.subjects?.map((sub)=>(sub.name))?.join(' • ')} </div>
                <div className='text-xs m-l-10 m-t-5'>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
        </div>
        <Dropdown 
              getPopupContainer={(trigger) => trigger.parentElement}
              overlay={<CustomMenu type={"feedback"}
              test={test} 
              handleButtonClick={handleButtonClick}
              changeTestStatusModalBool={changeTestStatusModalBool}
                                   createTestModalBool={createTestModalBool}
                                  generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                  editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                  />}
                                            placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                      <MdMoreVert 
                                        className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                                  </Dropdown>
        </div>
            )
        }
        else{
            return(
                <>
                <div className='readyTo_Publish m-l-10 display-flex full-width m-b-10 m-t-20 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
                        width: 400,
                        backgroundColor: "#FFFFFF", 
                        border: "1px solid #E6E6E6", 
                        borderRadius: 10,
                        height:120
                    }} onClick={onFeedbackClick}>
                        <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                        test?.syllabus==='Part Test'?partTestImg:
                        test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null}/>
                        <div className='m-t-20' style={{display: "flex", flexDirection: "column"}}>
                    <div className='bold-700  m-l-10 text-overflow-ellipses'>{test.title}</div>
                    
                            <div className='text-xs m-l-10 m-t-5'>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
                    </div>
                    <Dropdown 
                        getPopupContainer={(trigger) => trigger.parentElement}
                        overlay={<CustomMenu type={"feedback"} 
                        test={test} 
                        handleButtonClick={handleButtonClick}
                        changeTestStatusModalBool={changeTestStatusModalBool}
                                            createTestModalBool={createTestModalBool}
                                            generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                            editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                            />}
                                                        placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                                <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                                            </Dropdown>
                    </div>
                </>
            )
        }
    }
    else if(type==='draft'){
        return(
            <>
            <div onClick={onDraftClick} className='readyTo_Publish display-flex full-width m-b-10 m-r-10 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
                      width: 400,
                      backgroundColor: "#FFFFFF", 
                      border: "1px solid #E6E6E6", 
                      borderRadius: 10,
                      height:145
          }}>
              <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
            <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                        test?.syllabus==='Part Test'?partTestImg:
                        test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null
                        }/>
            </Tooltip>   
              <div style={{display: "flex", flexDirection: "column"}}>
          <div className='bold-800 m-t-20 m-l-10'>{test.title}</div>
              <div className='text-xs m-l-10 m-t-5 text-overflow-ellipses'>{test.standard} | {test.subjects?.map((sub)=>(sub.name))?.join(' • ')}</div>
                  <div className='text-xs m-l-10 '>{test.totalQuestions?test.totalQuestions:0} Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints?test.totalPoints:0} marks</div>
                      <div className='r-jsb text-xs m-l-10 m-t-5'>
                      <div className='flex-column text-xs'>
                          <div style={{color: '#AEAEAE'}}> CREATED ON </div>
                      <div style={{fontWeight: "bold"}} className='bold-300r'>{test.createdAt!=undefined ?moment.utc(test.createdAt).local().format("DD/MM/YYYY • hh:mm A"):null}</div>
                </div>
              </div>
            </div>
            <Dropdown 
                getPopupContainer={(trigger) => trigger.parentElement}
                overlay={
                    <CustomMenu type={"draft"} test={test} 
                                handleButtonClick={handleButtonClick} changeTestStatusModalBool={changeTestStatusModalBool}
                                createTestModalBool={createTestModalBool} generateQuesListViewBool={generateQuesListViewBool} 
                                userType={userType} editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                  />}
                placement="bottomRight" 
                arrow 
                onClick={(e)=>{e.stopPropagation();}}>
                     <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
            </Dropdown>
          </div>
          </>
          )
    }else if(type==='published'){
        if (page==='syllabus'){
            return(
                <>
                <div className='readyTo_Publish m-l-10 display-flex full-width m-b-10 m-t-20 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
                        width: 400,
                        backgroundColor: "#FFFFFF", 
                        border: "1px solid #E6E6E6", 
                        borderRadius: 10,
                        height:120
            }} onClick={handleUpcomingClick}>
               <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
                <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                            test?.syllabus==='Part Test'?partTestImg:
                            test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null
                            }/>
                </Tooltip>   
                <div className='m-t-5' style={{display: "flex", flexDirection: "column"}}>
                  <div className='bold-700  m-l-10 m-t-20 text-overflow-ellipses'>{test.title}</div>
                  <div className='text-xs m-l-10 m-t-5 text-overflow-ellipses'>{test.subjects?.map((sub)=>(sub.name))?.join(' • ')}</div>
                    <div className='text-xs m-l-10 text-overflow-ellipsis'>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
            </div>
            <Dropdown
                  getPopupContainer={(trigger) => trigger.parentElement}
                  overlay={<CustomMenu type={"published"} 
                  test={test} 
                  handleButtonClick={handleButtonClick}
                  changeTestStatusModalBool={changeTestStatusModalBool}
                                       createTestModalBool={createTestModalBool}
                                      generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                      editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                      />}
                                                  placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                          <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                                      </Dropdown>
            </div>
                </>
              )
        }else{
        return(
            <>
            <div className='readyTo_Publish m-l-10 display-flex full-width m-b-10 m-t-20 customCardShadow cursor-pointer shadow-box hover-shadow relative' style={{
                    width: 400,
                    backgroundColor: "#FFFFFF", 
                    border: "1px solid #E6E6E6", 
                    borderRadius: 10,
                    height:120
        }} onClick={handleUpcomingClick}>
           <Tooltip placement="topRight" trigger={"hover"} overlayInnerStyle={{borderRadius:5}} title={test.syllabus}>
            <img className='m-t-30 m-l-20' style={{height: 60, width: 60}} src={test?.syllabus==='Chapter Test'?chapterTestImg:
                        test?.syllabus==='Part Test'?partTestImg:
                        test?.syllabus==='Full Test'?fullTestImg: test?.syllabus==='Previous Year Paper'?previousYearImg:null
                        }/>
            </Tooltip>   
            <div className='m-t-20' style={{display: "flex", flexDirection: "column"}}>
              <div className='bold-700  m-l-10 m-t-20 text-overflow-ellipses'>{test.title}</div>
                <div className='text-xs m-l-10 text-overflow-ellipsis'>{test.totalQuestions}Qs {"•"} {test.durationInMinutes} mins {"•"} {test.totalPoints} marks</div>
        </div>
        <Dropdown 
            getPopupContainer={(trigger) => trigger.parentElement}
            overlay={<CustomMenu type={"published"} 
              test={test} 
              handleButtonClick={handleButtonClick}
              changeTestStatusModalBool={changeTestStatusModalBool}
                                   createTestModalBool={createTestModalBool}
                                  generateQuesListViewBool={generateQuesListViewBool} userType={userType}
                                  editDetailsLoader={editDetailsLoader} cloneLoader={cloneLoader}
                                  />}
                                              placement="bottomRight" arrow onClick={(e)=>{e.stopPropagation();}}>
                                      <MdMoreVert className='text-lg a2grey cursor-pointer' style={{position:"absolute", top: "7px", height:25, right:"5px", zIndex:5}}/>
                                  </Dropdown>
        </div>
            </>
          )
    }}
    else{
        return null
    }
}
const mapStateToProps = state => {
    const {universalLoaderBool, changeTestStatusModalBool,
        cModalType, createTestModalBool, generateQuesListViewBool, userType, editDetailsLoader, cloneLoader} = state.practiseTest;
    const {user} = state.session;
    return {universalLoaderBool, changeTestStatusModalBool,
        cModalType, createTestModalBool, generateQuesListViewBool, userType, user, editDetailsLoader, cloneLoader};
  };

const mapDispatchToProps = dispatch => ({
    setSelectedClassroom: (selectedClass) => dispatch(setSelectedClassroom(selectedClass)),
    setSelectedClassroomId: (selectedClassId) => dispatch(setSelectedClassroomId(selectedClassId)),
    updateSelectedTest: (test)=> dispatch(updateSelectedTest(test)),
    updateSelectedTestId: (testId)=> dispatch(updateSelectedTestId(testId)),
    setIsCModalVisible: (bool)=>dispatch(setIsCModalVisible(bool)),
    setCModalType: (type)=>dispatch(setCModalType(type)),
    createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
    storeGenerateQuestionsObj: (data)=> dispatch(storeGenerateQuestionsObj(data)),
    showGenerateQuesListView: (callback, type)=> dispatch(showGenerateQuesListView(callback, type)),
    updateListViewType: (type)=>dispatch(updateListViewType(type)),
    fetchTestSubmitDetails: (callback)=>dispatch(fetchTestSubmitDetails(callback)),
    updateSubmittedTestsId: value => dispatch(updateSubmittedTestsId(value)),
    universalLoader: (name, varname, bool)=>dispatch(universalLoader(name, varname, bool)),
    updateSelectedCardType: (type)=>dispatch(updateSelectedCardType(type)),
    changeTestStatusModal: (status)=>dispatch(changeTestStatusModal(status)),
    getTestClassList: (classIds, chapterIds) => dispatch(getTestClassList(classIds, chapterIds)),
    storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
    getEachQuesData: (id) => dispatch(getEachQuesData(id)),
    updateFilter: (key, val) => dispatch(updateFilter(key, val))
  });

export default connect(mapStateToProps, mapDispatchToProps)(TestCard);