import React, { useEffect, useState } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { newCourseCreationCardStyle } from './courseCreationPage'
import AnimatedCustomSelect from 'components/AnimatedSelectComp';
import { connect } from 'react-redux';
import { getCoursesCategories, getCoursesSubCategories, postCreateCategory, postCreateSubCategory, setCreateCourseObj } from 'actions/courseManagement';
import AnimatedTextField from 'components/AnimatedTextField';
import { Notification } from 'services';
import { getExaminer } from 'actions/testAdmin';
import { Typography } from 'antd';

export const LabelAndDataComp = ({ label, data }) => {
    return (
        <div style={{ width: "fit-content" }}>
            <div style={{ color: "#636363", fontSize: 12 }}>{label}</div>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: "100%" }}>{data}</Typography.Text>
        </div>
    )
}

function KeyInformationComp({ isExpanded, setIsExpanded, setCreateCourseObj, getCoursesCategories, getCoursesSubCategories, postCreateCategory, courseCategoryData, courseCreateObj, courseSubCategoriesData, postCreateSubCategory, location, courseTypeName, getExaminer, getExaminerArr, clearValues, expandedSection }) {
    let [CategoryListToShow, setCategoryListToShow] = useState([]);
    let [subCategoryListToShow, setSubCategoryListToShow] = useState([]);
    const courseDifficultyArray = [{ id: "easy", value: "Beginner" }, { id: "medium", value: "Intermediate" }, { id: "hard", value: "Expert" }]
    const coursePlanArray = [{ id: "1", value: "Day(s)" }, { id: "30", value: "Month(s)" }, { id: "360", value: "Year(s)" }]
    const [searchText, setSearchText] = useState('');
    const [searchTextIndex, setSearchTextIndex] = useState('');
    const [handleRender, updateHandleRender] = useState(false);
    const [keyInfoFilledCount, setKeyInfoFilledCount] = useState(0)
    const [selectedTeachers, setSelectedTeachers] = useState(courseCreateObj?.teacherIds || []);


    useEffect(() => {
        setSelectedTeachers(courseCreateObj?.teacherIds || [])
    }, [courseCreateObj?.teacherIds])
    
    useEffect(() => {
        if (location?.state?.key?.isEdit != "isFromEdit") {
            setCategoryListToShow([])
            setSubCategoryListToShow([])
            setSelectedTeachers([])
            setCreateCourseObj('teacherIds', []);
        }
    }, [])
    useEffect(() => {
        if (courseCategoryData?.length != 0) {
            setCategoryListToShow(courseCategoryData)
        }
        else {
            setCategoryListToShow([])
        }
    }, [courseCategoryData])
    useEffect(() => {
        if (courseSubCategoriesData?.length != 0) {
            setSubCategoryListToShow(courseSubCategoriesData)
        }
        else {
            setSubCategoryListToShow([])
        }
    }, [courseSubCategoriesData])

    const handleChangeCategory = (value, obj) => {
        if (obj?.key != -1) {
            setCreateCourseObj("categoryId", { name: value, id: obj?.key });
        }
        else if (obj?.key == -1) {
            setCreateCourseObj("categoryId", { name: null, id: null })
        }
        else {
            setCreateCourseObj("categoryId", { name: null, id: null })
        }
        getCoursesCategories();
        getCoursesSubCategories(obj?.key);
        setCreateCourseObj("subcategoryId", null);
    }

    const onSelectCategory = (value, obj) => {
        if (obj?.key != -1) {
            setCreateCourseObj("categoryId", { name: value, id: obj?.key });
        }
        else if (obj?.key == -1) {
            setCreateCourseObj("categoryId", { name: null, id: null })
            postCreateCategory(obj?.children?.[1], () => { getCoursesCategories() })
        }
        else {
            setCreateCourseObj("categoryId", { name: null, id: null })
        }
    }

    const handleSearchCategory = (value) => {
        if (value && value.trim() != '') {
            setCreateCourseObj("categoryId", { name: value, id: null })
            let category;
            category = courseCategoryData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
            setCategoryListToShow(category)
        }
        else {
            setCreateCourseObj("categoryId", { name: null, id: null })
            setCategoryListToShow(courseCategoryData)
        }
    }

    const handleChangeSubCategory = (value, obj) => {
        if (!courseCreateObj?.categoryId?.name) { }
        else {
            if (obj?.key != -1) {
                setCreateCourseObj("subcategoryId", { name: value, id: obj?.key });
            }
            else if (obj?.key == -1) {
                setCreateCourseObj("subcategoryId", { name: null, id: null })
            }
            else {
                setCreateCourseObj("subcategoryId", { name: null, id: null })
            }
        }
    }
    const onSelectSubCategory = (value, obj) => {
        if (!courseCreateObj?.categoryId?.name) { }
        else {
            if (obj?.key != -1) {
                setCreateCourseObj("subcategoryId", { name: value, id: obj?.key });
            }
            else if (obj?.key == -1) {
                setCreateCourseObj("subcategoryId", { name: null, id: null })
                postCreateSubCategory(obj?.children?.[1], () => { getCoursesSubCategories(courseCreateObj?.categoryId?.id) })
            }
            else {
                setCreateCourseObj("subcategoryId", { name: null, id: null })
            }
        }
    }

    const handleSearchSubCategory = (value) => {
        if (!courseCreateObj?.categoryId?.name) { }
        else {
            if (value && value.trim() != '') {
                setCreateCourseObj("subcategoryId", { name: value, id: null })
                let subCategory;
                subCategory = courseSubCategoriesData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
                setSubCategoryListToShow(subCategory)
            }
            else {
                setCreateCourseObj("subcategoryId", { name: null, id: null })
                setSubCategoryListToShow(courseSubCategoriesData)
            }
        }
    }

    useEffect(() => {
        if (location?.state?.key?.isEdit == "isFromEdit") {
            {console.log("courseCreateObj?.teacherIds1",courseCreateObj?.teacherIds)}

            setSelectedTeachers(courseCreateObj?.teacherIds)
        }
        
        getExaminer('');
    }, [])

    let timer = false;

    useEffect(() => {
        if (handleRender) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (searchTextIndex === 'examiner') {
                    getExaminer(searchText)
                    updateHandleRender(false);
                }
                else {
                    updateHandleRender(false);
                }
            }, 800);
        }
    }, [searchText, searchTextIndex, handleRender])

    const courseTypeObjects = {
        liveCourses: {
            categoryId: courseCreateObj?.categoryId,
            subcategoryId: courseCreateObj?.subcategoryId,
            difficulty: courseCreateObj?.difficulty,
            duration: courseCreateObj?.duration,
            durationType: courseCreateObj?.durationType,
            teacherIds:courseCreateObj?.teacherIds?.length > 0 ? courseCreateObj?.teacherIds : null
        },
        videoResources: {
            categoryId: courseCreateObj?.categoryId,
            subcategoryId: courseCreateObj?.subcategoryId,
            difficulty: courseCreateObj?.difficulty,
            teacherIds:courseCreateObj?.teacherIds?.length > 0 ? courseCreateObj?.teacherIds : null
        },
        onlineTestSeries: {
            categoryId: courseCreateObj?.categoryId,
            subcategoryId: courseCreateObj?.subcategoryId,
            difficulty: courseCreateObj?.difficulty,
        },
        studyMaterials: {
            categoryId: courseCreateObj?.categoryId,
            subcategoryId: courseCreateObj?.subcategoryId,
            teacherIds:courseCreateObj?.teacherIds?.length > 0 ? courseCreateObj?.teacherIds : null,
            difficulty: courseCreateObj?.difficulty,
        }
    };

  

    useEffect(() => {
        let count = 0;
        const currentCourseObj = courseTypeObjects[courseTypeName];
        for (const item in currentCourseObj) {
            if (currentCourseObj[item] !== null && currentCourseObj[item] !== undefined && currentCourseObj[item] !== '') {
                if ((item === 'categoryId' || item === 'subcategoryId') && !currentCourseObj[item]?.name) {
                    continue;
                }
                
                count++;
            }
        }
        setKeyInfoFilledCount(count)
    }, [courseTypeName, isExpanded, courseCreateObj]);

    useEffect(() => {
        return (() => {
            if (location?.state?.key?.isEdit != 'isFromEdit') {
                clearValues()
            }
        })
    }, [])

    const returnNames = (val) => {
        if (val == "30") {
            return "Month(s)";
        } else if (val == "360" || val == "365") {
            return "Year(s)";
        } else if (val == "1") {
            return "Day(s)";
        }
    };


    console.log('selectedTeachers111', selectedTeachers);


    const handleRemoveTeacher = (teacherId) => {
        const updatedTeachers = selectedTeachers.filter(teacher => teacher.id != teacherId);
        setSelectedTeachers(updatedTeachers);
        setCreateCourseObj('teacherIds', updatedTeachers);
    };

    const handleSelectChange = (text, value) => {
        console.log('selectedTeachers1', value);

        if (value) {
            const newTeacher = { name: value.children, id: value.key };
            if (!selectedTeachers.some(teacher => teacher.id == newTeacher.id)) {
                const updatedTeachers = [...selectedTeachers, newTeacher];
                setSelectedTeachers(updatedTeachers);
                setCreateCourseObj('teacherIds', updatedTeachers);
            }
        } else {
            getExaminer('');
        }
    };


    console.log('selectedTeachers12', courseCreateObj?.teacherIds);



    return (
        <div className='m-t-20' style={newCourseCreationCardStyle}>
            <div className='r-c-sb'>
                <div style={{ color: "#696969", fontSize: 18, fontWeight: 700 }}>Key information</div>
                <div className='r-ac'>
                    {!isExpanded && keyInfoFilledCount > 0 ? <div className='m-r-10' style={{ color: "#696969" }}>{keyInfoFilledCount} {keyInfoFilledCount == 1 ? "field" : "fields"}</div> : <></>}
                    {isExpanded ?
                        <FiChevronDown style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} /> :
                        <FiChevronRight style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} />
                    }
                </div>
            </div>
            {!expandedSection &&
                (location?.state?.key?.isEdit !== "isFromEdit" && courseCreateObj?.categoryId?.name && courseCreateObj?.subcategoryId?.name) || (location?.state?.key?.isEdit === "isFromEdit" && !isExpanded) ?
                <div className='r-c-sb m-t-10' style={{ width: "100%" }}>
                    <LabelAndDataComp label={"Category"} data={courseCreateObj?.categoryId?.name || "-"} />
                    <LabelAndDataComp label={"Sub category"} data={courseCreateObj?.subcategoryId?.name || "-"} />
                    {courseTypeName == "liveCourses" ?
                        <LabelAndDataComp label={"Duration"} data={`${courseCreateObj?.duration || "0"} ${courseCreateObj?.durationType ? returnNames(courseCreateObj?.durationType) : ""}`} /> :
                        courseTypeName == "videoResources" ?
                            <LabelAndDataComp label={"Teacher"} data={courseCreateObj?.teacherIds?.length > 0 ? `${courseCreateObj.teacherIds.length} Teacher(s) added` : 'No Teacher(s) added'} /> :
                            (courseTypeName == "onlineTestSeries" || courseTypeName == "studyMaterials") ?
                                <LabelAndDataComp label={"Course difficulty"} data={courseCreateObj?.difficulty == "easy" ? "Beginner" : courseCreateObj?.difficulty == "medium" ? "Intermediate" : courseCreateObj?.difficulty == "hard" ? "Expert" : "-"} /> : <></>}
                </div> : <></>}
            {isExpanded ?
                <>
                    <div className='r-c-sb m-t-20' style={{ width: "100%" }}>
                        <div className='m-r-20' style={{ width: "50%" }}>
                            <AnimatedCustomSelect
                                label={"Category"}
                                required={true}
                                options={CategoryListToShow?.length != 0 ? CategoryListToShow : courseCreateObj?.categoryId?.name ? courseCreateObj?.categoryId?.name : ""}
                                value={courseCreateObj?.categoryId?.name}
                                isSelected={courseCreateObj?.categoryId?.name ? true : false}
                                handleSelectChange={handleChangeCategory}
                                handleSelectOnSelect={onSelectCategory}
                                handleSelectOnSearch={handleSearchCategory}
                                showSearch={true}
                                allowClear={courseCreateObj?.categoryId?.name ? true : false} 
                                onKeyDown={(e) => {
                                    if (e.key === " ") {
                                        e.stopPropagation(); // Stop event propagation
                                    }
                                }}/>
                        </div>
                        <div style={{ width: "50%" }}>
                            <AnimatedCustomSelect
                                label={"Sub category"}
                                required={true}
                                options={subCategoryListToShow?.length != 0 && courseCreateObj?.categoryId?.name ? subCategoryListToShow : courseCreateObj?.subcategoryId?.name ? courseCreateObj?.subcategoryId?.name : ""}
                                value={courseCreateObj?.subcategoryId?.name}
                                isSelected={courseCreateObj?.subcategoryId?.name ? true : false}
                                handleSelectChange={handleChangeSubCategory}
                                handleSelectOnSelect={onSelectSubCategory}
                                handleSelectOnSearch={handleSearchSubCategory}
                                showSearch={true}
                                allowClear={courseCreateObj?.subcategoryId?.name ? true : false}
                                onKeyDown={(e) => {
                                    if (e.key === " ") {
                                        e.stopPropagation(); // Stop event propagation
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='r-c-sb' style={{ width: "100%" }}>
                        <div style={{ width: courseTypeName == "liveCourses" ? "33%" : courseTypeName == "videoResources" ? "100%" : "100%", marginRight: courseTypeName == "liveCourses"  ? 20 : 0 }}>
                            <AnimatedCustomSelect
                                label={"Course difficulty"}
                                required={true}
                                options={courseDifficultyArray}
                                value={courseCreateObj?.difficulty}
                                isSelected={courseCreateObj?.difficulty ? true : false}
                                handleSelectChange={(value) => { setCreateCourseObj("difficulty", value) }}
                                style={{ marginBottom: 0 }}
                                onKeyDown={(e) => {
                                    if (e.key === " ") {
                                        e.stopPropagation(); // Stop event propagation
                                    }
                                }}
                            />
                        </div>
                        {courseTypeName == "liveCourses" ?
                            <>
                                <div className='m-r-20' style={{ width: "33%" }}>
                                    <AnimatedTextField
                                        label={"Duration"}
                                        isMandatory={true}
                                        inputId={"-990"}
                                        value={courseCreateObj?.duration ? courseCreateObj?.duration : ''}
                                        handleInputChange={(e) => {
                                            if ((parseInt(e.target.value) > 60 && parseInt(courseCreateObj?.durationType) == 30 && courseCreateObj?.durationType != '' && e.target.value != '') || (parseInt(e.target.value) > 5 && parseInt(courseCreateObj?.durationType) == 360 && courseCreateObj?.durationType != '' && e.target.value != '') || (parseInt(e.target.value) > 1825 && parseInt(courseCreateObj?.durationType) == 1 && courseCreateObj?.durationType != '' && e.target.value != '')) {
                                                Notification.error("Error", 'Duration should not be greater than 5 years');
                                            }
                                            setCreateCourseObj("duration",
                                                e.target.value != " " && e.target.value != "0" && /^[\d]*$/.test(e.target.value)
                                                    ? e.target.value
                                                    : courseCreateObj?.duration
                                                        ? courseCreateObj?.duration
                                                        : null
                                            )
                                        }}
                                        style={{ marginTop: 10 }}
                                        onKeyDown={(e) => {
                                            if (e.key === " ") {
                                                e.stopPropagation(); // Stop event propagation
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ width: "33%" }}>
                                    <AnimatedCustomSelect
                                        label={""}
                                        required={false}
                                        options={coursePlanArray}
                                        value={courseCreateObj?.durationType}
                                        isSelected={courseCreateObj?.durationType ? true : false}
                                        handleSelectChange={(e) => {
                                            if ((parseInt(courseCreateObj?.duration) > 60 && courseCreateObj?.duration != '' && e == '30' && e != '') || (parseInt(courseCreateObj?.duration) > 5 && e == '360' && courseCreateObj?.duration != '' && e != '') || (parseInt(courseCreateObj?.duration) > 1825 && e == '1' && courseCreateObj?.duration != '' && e != '')) {
                                                Notification.error("Error", 'Duration should not be greater than 5 years');
                                            }
                                            setCreateCourseObj("durationType", e)
                                        }}
                                        style={{ marginBottom: 0 }} 
                                        onKeyDown={(e) => {
                                            if (e.key === " ") {
                                                e.stopPropagation(); // Stop event propagation
                                            }
                                        }}
                                        />
                                </div>
                            </> :
                            <>
                                {/* {courseTypeName == "videoResources" ?
                                    <div style={{ width: "50%" }}>
                                        <AnimatedCustomSelect
                                            label={"Select teacher"}
                                            required={true}
                                            options={getExaminerArr}
                                            value={courseCreateObj?.teacherId?.name}
                                            isSelected={courseCreateObj?.teacherId?.name ? true : false}
                                            handleSelectChange={(text, value) => { value ? setCreateCourseObj('teacherId', { name: value.value, id: value.key }) : getExaminer('') }}
                                            handleSelectOnSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true) }}
                                            style={{ marginBottom: 0 }} />
                                    </div>
                                    : <></>
                                } */}
                            </>}
                    </div>


                    {courseTypeName != "onlineTestSeries" ?
                        <div style={{ width: "100%", marginTop: 20 }}>
                            
                            <AnimatedCustomSelect
                                label={"Select teacher"}
                                required={true}
                                options={getExaminerArr}
                                value={courseCreateObj?.teacherIds?.length > 0 ? courseCreateObj?.teacherIds[courseCreateObj?.teacherIds?.length - 1].name : selectedTeachers?.length ? selectedTeachers[selectedTeachers?.length - 1]?.name : ''}
                                isSelected={selectedTeachers?.length > 0}
                                handleSelectChange={handleSelectChange}
                                handleSelectOnSearch={(text) => { setSearchText(text); setSearchTextIndex('examiner'); updateHandleRender(true); }}
                                style={{ marginBottom: 0 }}
                                onKeyDown={(e) => {
                                    if (e.key === " ") {
                                        e.stopPropagation(); // Stop event propagation
                                    }
                                }}
                            />



                            <div style={{ marginTop: "10px" }}>
                                {selectedTeachers?.map((teacher, index) => (

                                    <div key={teacher?.id} style={{ display: "inline-block", margin: "5px" }}>

                                        <div style={{ padding: "2px 12px", borderRadius: "20px", border: "1px solid #D7D7D7", display: "flex", alignItems: "center", color: '#636363' }}>
                                            <span>{teacher?.name}</span>
                                            <span
                                                style={{ marginLeft: "10px", cursor: "pointer", color: "#636363" }}
                                                onClick={() => handleRemoveTeacher(teacher?.id)}
                                            >
                                                &#x2716; 
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <></>}


                </>
                :
                <></>}
        </div>
    );
}

const mapStateToProps = (state) => {
    const { courseTypeName, courseCreateObj, courseCategoryData, courseSubCategoriesData } = state.courseManagement
    const { getExaminerArr, getExaminerBool } = state.testAdmin
    return {
        courseTypeName, courseCreateObj, courseCategoryData, courseSubCategoriesData, getExaminerArr
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    getCoursesCategories: () => dispatch(getCoursesCategories()),
    getCoursesSubCategories: (id) => dispatch(getCoursesSubCategories(id)),
    postCreateCategory: (name, callBack) => dispatch(postCreateCategory(name, callBack)),
    postCreateSubCategory: (name, callBack) => dispatch(postCreateSubCategory(name, callBack)),
    getExaminer: (text) => dispatch(getExaminer(text)),
});
export default connect(mapStateToProps, mapDispatchToProps)(KeyInformationComp);