import {
  TOGGLE_FETCH_CLASS_LOADER,
  UPDATE_CLASSES_LIST,
  TOGGLE_FETCH_SECTION_LOADER,
  UPDATE_CLASSES_DATA,
  UPDATE_STUDENTS_LIST,
  UPDATE_STUDENT_DATA,
  RESET_CLASSES_DATA,
  RESET_STUDENT_DATA,
  UPDATE_STUDENT_ATTENDANCE,
  TOGGLE_STUDENT_LOADER,
  TOGGLE_STUDENT_MORE_DATA_LOADER,
  UPDATE_CURRENT_STUDENT_PAGE,
  SET_SELECTED_DATE,
  PUSH_STUDENT_ATTENDANCE,
  CHANGE_SELECTED_ATTENDANCE_TAB,
  TOGGLE_FETCH_TEACHERS_LOADER,
  UPDATE_TEACHERS_LIST,
  UPDATE_SELECTED_TEACHER,
  UPDATE_SELECTED_SECTION,
  UPDATE_TEACHER_ATTENDANCE,
  TOGGLE_TEACHER_ATTENDANCE_LOADER,
  UPDATE_TEACHER_DETAILED_ATTENDANCE,
  TOGGLE_STUDENT_BY_CLASS_LOADER,
  UPDATE_CLASS_STUDENT_LIST,
} from "../actions/attendance";
import moment from "moment";
const initialState = {
  fetchClassesLoader: false,
  classes: [],
  singleClassLoader: false,
  classDetails: {},
  studentsData: [],
  classOverallSummary: [],
  standardSubjects: [],
  selectedStudent: {},
  studentLoader: false,
  studentAttendance: {},
  moreStudentDataLoader: false,
  currentStudentPage: 1,
  studentOverallSummary: {},
  studentSubjects: [],
  selectedDate: null,
  selectedAttendanceTab: "Standards",
  teachersList: [],
  fetchTeacherLoader: false,
  selectedTeacher: {},
  selectedSection: {},
  teacherAttendanceLoader: false,
  teacherAttendance: {},
  teacherTotalDuration: 0,
  teacherDetailedAttendance: {},
  studentListByClass: {},
  studentListByClassLoader: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STUDENT_BY_CLASS_LOADER:
      return {
        ...state,
        studentListByClassLoader: action.bool,
      };
    case UPDATE_CLASS_STUDENT_LIST:
      return {
        ...state,
        studentListByClass: action.data.studentsList,
      };
    case UPDATE_TEACHER_DETAILED_ATTENDANCE:
      return {
        ...state,
        teacherDetailedAttendance: action.data.teacherAttendance,
      };
    case UPDATE_TEACHER_ATTENDANCE:
      return {
        ...state,
        teacherAttendance: action.data.teacherAttendance,
        teacherTotalDuration: action.data.duration,
      };
    case TOGGLE_TEACHER_ATTENDANCE_LOADER:
      return {
        ...state,
        teacherAttendanceLoader: action.bool,
      };
    case UPDATE_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: action.data,
      };
    case UPDATE_SELECTED_TEACHER:
      return {
        ...state,
        selectedTeacher: action.data,
      };
    case TOGGLE_FETCH_TEACHERS_LOADER:
      return {
        ...state,
        fetchTeacherLoader: action.bool,
      };
    case UPDATE_TEACHERS_LIST:
      return {
        ...state,
        teachersList: action.data.teachers,
      };
    case CHANGE_SELECTED_ATTENDANCE_TAB:
      return {
        ...state,
        selectedAttendanceTab: action.key,
      };
    case PUSH_STUDENT_ATTENDANCE:
      return {
        ...state,
        studentAttendance: action.data
          ? { ...state.studentAttendance, ...action.data.attendance }
          : state.studentAttendance,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.date
          ? moment(action.date).format("YYYY-MM-DD")
          : null,
      };
    case UPDATE_CURRENT_STUDENT_PAGE:
      return {
        ...state,
        currentStudentPage: action.page,
      };
    case TOGGLE_STUDENT_MORE_DATA_LOADER:
      return {
        ...state,
        moreStudentDataLoader: action.bool,
      };
    case UPDATE_STUDENT_ATTENDANCE:
      return {
        ...state,
        studentAttendance: action.data ? action.data.attendance : {},
        studentOverallSummary: action.data ? action.data.overallSummary : {},
        studentSubjects: action.data ? action.data.subjects : [],
      };
    case TOGGLE_STUDENT_LOADER:
      return {
        ...state,
        studentLoader: action.bool,
      };
    case RESET_STUDENT_DATA:
      return {
        ...state,
        selectedStudent: {},
      };
    case UPDATE_STUDENT_DATA:
      return {
        ...state,
        selectedStudent: action.data,
      };
    case RESET_CLASSES_DATA:
      return {
        ...state,
        classDetails: {},
      };
    case TOGGLE_FETCH_CLASS_LOADER:
      return {
        ...state,
        fetchClassesLoader: action.bool,
      };
    case UPDATE_CLASSES_LIST:
      return {
        ...state,
        classes: action.data,
      };
    case TOGGLE_FETCH_SECTION_LOADER:
      return {
        ...state,
        singleClassLoader: action.bool,
      };
    case UPDATE_CLASSES_DATA:
      return {
        ...state,
        classDetails: action.data,
      };
    case UPDATE_STUDENTS_LIST:
      return {
        ...state,
        studentsData: action.data
          ? action.data.studentsData
          : initialState.studentsData,
        classOverallSummary: action.data
          ? action.data.overallData
          : initialState.classOverallSummary,
        standardSubjects: action.data
          ? action.data.standardSubjects
          : initialState.standardSubjects,
      };
    default:
      return state;
  }
};

export default reducer;
