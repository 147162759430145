import { getWebClockIn, postWebClockIn, setClockInEndDate, setClockInStartDate, setClockInSwitch } from 'actions/attendanceNew';
import { Button, Checkbox, DatePicker, Modal, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Notification from "services/Notification";


const SettingModal = ({ setIsSettingModalOpen, isSettingModalOpen, getWebClockIn, storeClockInData, storeUserId, postWebClockIn, setClockInStartDate, setClockInEndDate, setClockInSwitch, storeClockInStartDate,
  storeClockInEndDate,
  storeClockInSwitch }) => {
  const [type, setType] = useState(storeClockInData?.settings?.duration)
  const [isSwitch, setSwitch] = useState(false)
  const [loader, setLoader] = useState(false);
  const [isNo, setIsNo] = useState(false)

  useEffect(() => {
    setType("Permanent")
  }, []);

  useEffect(() => {
    setIsNo(false)
    setSwitch(storeClockInData?.enabled)
  }, [storeClockInData?.enabled, isNo])

  const handleOk = () => {
    setIsNo(true)
    setIsSettingModalOpen(false);
  };

  const handleCancel = () => {
    setIsSettingModalOpen(false);
    setClockInStartDate(null);
    setClockInEndDate(null);
    setType('');
    setIsNo(false);
  };

  const handleStartDateChange = (date) => {
    setClockInStartDate(date)
  };

  const handleEndDateChange = (date) => {
    setClockInEndDate(date)
  };

  const OnChangePermanent = (id) => {
    if (type == '') {
      id == 0 ? setType("Permanent") : setType("Temporary")
    } else if (type === "Permanent") {
      setType('Temporary')
    } else if (type !== "Permanent") {
      setType("Permanent")
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const disabledEndDate = (current) => {
    //disable end date based on start date
    return current && current <= moment(storeClockInStartDate, "DD/MM/YYYY").subtract(1, 'day').endOf('day');
  };

  return (
    <>
      <Modal
        className="modal-round-corner"
        open={isSettingModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ fontSize: "21px", fontWeight: "bold", textAlign: "center" }}>Setting</div>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div>
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Web clock in/clock out</div>
            <div style={{ fontSize: "14px", color: "#636363", fontWeight: "bold" }}>Enable web clock in/clock out and control it’s duration.</div>
          </div>

          <div style={{ marginLeft: "50px", marginTop: "20px" }}>
            <Switch checked={isSwitch} onChange={(e) => { setSwitch(e) }} />
          </div>
        </div>
        {isSwitch ?
          (
            <>
              <div style={{ marginTop: "20px", fontSize: "12px", color: "#636363", fontWeight: "bold" }}>Duration</div>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                <div style={{ display: "flex", marginLeft: "10px" }}>
                  <Checkbox onChange={() => { OnChangePermanent(0) }} onClick={() => { setType("Permanent") }} checked={type === "Permanent"} />
                  <div style={{ fontSize: "14px", marginLeft: "20px", color: "#191919", font: " normal normal normal 16px/21px Roboto" }}>Permanent</div>
                </div>
                <div style={{ fontSize: "14px", marginRight: "52px" }}><Checkbox onClick={() => { setType("Temporary") }} onChange={() => { OnChangePermanent(1) }} checked={type === "Temporary"}>Temporary</Checkbox></div>
              </div>
              {type == "Permanent" ? null :
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <div>
                      <div style={{ marginLeft: "30px", fontSize: "14px" }}>Start date</div>
                      <div style={{ marginLeft: "30px" }} ><DatePicker disabledDate={disabledDate} defaultValue={storeClockInData?.settings?.startDate != 'Invalid date' ? moment(storeClockInData?.settings?.startDate) : moment().format('YYYY-MM-DD')} onChange={(value) => { handleStartDateChange(value) }} /></div>
                    </div>
                    <div>
                      <div style={{ marginLeft: "50px", fontSize: "14px" }}>End date</div>
                      <div style={{ marginLeft: "50px" }} ><DatePicker
                        disabledDate={disabledEndDate} defaultValue={storeClockInData?.settings?.endDate != 'Invalid date' ? moment(storeClockInData?.settings?.endDate) : moment().format('YYYY-MM-DD')} onChange={(value) => { handleEndDateChange(value) }} /></div>
                    </div>
                  </div>
                </div>
              }
            </>
          )
          : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "30px",
          }}
        >
          <div>
            <Button
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid  #636363",
                backgroundColor: "#FFFFFF",
                color: "#594099",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
                onClick={handleOk}
              >
                NO
              </div>
            </Button>
          </div>
          <div>
            <Button
              loading={loader}
              disabled={moment(storeClockInStartDate).format('YYYY-MM-DD') > moment(storeClockInEndDate).format('YYYY-MM-DD')}
              style={{
                borderRadius: "19px",
                width: "120px",
                height: "35px",
                border: "1px solid #594099 ",
                backgroundColor: "#594099",
                fontSize: "16px",
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
              onClick={() => {
                setLoader(true)
                postWebClockIn(isSwitch, storeUserId, type, () => { setIsSettingModalOpen(false); setLoader(false) })
              }}
            >
              YES
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { storeClockInData, storeClockInStartDate, storeClockInEndDate, storeClockInSwitch } = state.attendanceNew;
  return { storeClockInData, storeClockInStartDate, storeClockInEndDate, storeClockInSwitch };
};

const mapDispatchToProps = (dispatch) => ({
  getWebClockIn: (storeUserId) => dispatch(getWebClockIn(storeUserId)),
  postWebClockIn: (isSwitch, storeUserId, type, callBack) => dispatch(postWebClockIn(isSwitch, storeUserId, type, callBack)),
  setClockInStartDate: (res) => dispatch(setClockInStartDate(res)),
  setClockInEndDate: (res) => dispatch(setClockInEndDate(res)),
  setClockInSwitch: (res) => dispatch(setClockInSwitch(res))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingModal);