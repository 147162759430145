import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { DatePicker, Select, Input, Tooltip, Col, Row, Typography } from "antd";
import NeetBarGraph from "./localComponents/classBarGraph";
import StudentGraph from "./localComponents/studentGraph";
import Progress from "antd/lib/progress";
import { useHistory } from "react-router";
import { fetchClassDashboard, setEndDate, setStartDate } from "actions/attendanceNew";
import { connect } from "react-redux";
import { Api } from 'services';
import PageDataLoader from "components/pageDataLoader";
import { setUserDetails } from "actions/userManagementV2";
import { setSpecificUserId } from "actions/userManagement";
import { SquareSubIcon, SubIcon } from 'components/subIcon'
import { setOverviewFilter } from "actions/feeManagement";
import { setOthersSelectedTab } from "actions/profileNew";
import moment from "moment";

const { RangePicker } = DatePicker;

export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}

const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 30px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "auto",
};

const PageHeader = ({ goBack, classDashboardData }) => {
  return (
    <div className="resultPageHeader full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined
          onClick={goBack}
          style={{ fontSize: 18 }}
          className="cursor-pointer"
        />
        <div className="text-md bold-700 m-l-20">
          {classDashboardData?.className}
        </div>
      </div>
    </div>
  );
};

const StudentCard = ({ classDashboardData }) => {
  return (
    <div
      className="border-16 boxShadow118"
      style={{
        width: "96%",
        height: "350px",
        // borderRadius: 2,
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
        border: "solid 1px #E6E6E6"
      }}
    >
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: "15px", color: "#191919", fontWeight: "bold", fontFamily: "roboto" }}>
          Today’s attendance
        </div>
        <div style={{ fontSize: "9px", color: "#636363" }}>
          {classDashboardData?.todaysAttendance?.date} {"•"}  {classDashboardData?.todaysAttendance?.day}
        </div>
      </div>
      <div style={{ marginTop: -70 }}>
        {classDashboardData?.todaysAttendance?.present || classDashboardData?.todaysAttendance?.absent ?
          <StudentGraph
            overall={classDashboardData?.todaysAttendance?.present}
            presentVal={classDashboardData?.todaysAttendance?.present}
            absentVal={classDashboardData?.todaysAttendance?.absent}
          />
          :
          <center style={{ marginTop: 110, width: '80%', marginLeft: 30 }}><NodataCard /></center>}
      </div>
    </div>
  );
};

const StudentBarCard = ({ classDashboardData, setStartDate, setEndDate, startDate, endDate, fetchClassDashboard }) => {
  const [graph, setGraph] = useState([])
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < classDashboardData?.todaysAttendanceByClass?.length; i++) {
      let obj = {}
      obj['subjectName'] = classDashboardData?.todaysAttendanceByClass[i].subjectName,
        obj['key'] = classDashboardData?.todaysAttendanceByClass[i].key
      obj['range'] = classDashboardData?.todaysAttendanceByClass[i].presentPercentage,
        obj['presentCount'] = classDashboardData?.todaysAttendanceByClass[i].presentCount,
        obj['icon'] = classDashboardData?.todaysAttendanceByClass[i].icon,
        obj['teacherName'] = classDashboardData?.todaysAttendanceByClass[i].teacherName,
        obj['name'] = 'Present count %'
      temp.push(obj);

      let obj1 = {}
      obj1['subjectName'] = classDashboardData?.todaysAttendanceByClass[i].subjectName,
        obj1['key'] = classDashboardData?.todaysAttendanceByClass[i].key
      obj1['range'] = classDashboardData?.todaysAttendanceByClass[i].absentPercentage,
        obj1['absentCount'] = classDashboardData?.todaysAttendanceByClass[i].absentCount,
        obj1['icon'] = classDashboardData?.todaysAttendanceByClass[i].icon
      obj1['teacherName'] = classDashboardData?.todaysAttendanceByClass[i].teacherName,
        obj1['name'] = 'Absent count %'
      temp.push(obj1);
    }
    setGraph([...temp])
  }, [classDashboardData?.todaysAttendanceByClass]);

  return (
    <div
      className="border-16 boxShadow118"
      style={{
        width: "100%",
        height: "350px",
        backgroundColor: "#FFFFFF",
        borderColor: "#E6E6E6",
        border: "solid 1px #E6E6E6",
        padding: 20
      }}
    >
      <div className="r-c-sb" style={{width:"100%"}}>
      <div
        style={{
          color: "#191919",
          fontWeight: "bold",
          fontSize: "15px",
          fontFamily: "roboto",
          width:"30%"
        }}
      >
        Attendance by class
      </div>
      <div style={{ display: "flex",width:"30%" }}>
        <div style={{ display: "flex"}}>
          <div style={{ display: "flex", marginLeft: 45 }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "#28DF99",
                marginRight: "10px",
                marginTop: "2px",
                marginLeft: "20px"
              }}
            ></div>
            <div style={{ fontSize: "9px", color: "#636363", fontFamily: "roboto", }}>Present</div>
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "#FF414D",
                marginRight: "10px",
                marginTop: "2px",
                marginLeft: "10px",
                marginLeft: "10px"
              }}
            ></div>
            <div style={{ fontSize: "9px", color: "#636363", fontFamily: "roboto" }}>Absent</div>
          </div>
        </div>
      </div>
      <div style={{ width:"40%"}}>
            <RangePicker
              // format={'DD-MM-YYYY'}
              getPopupContainer={(trigger) => trigger.parentElement}
              value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
              onChange={(value1, value) => {
                setStartDate(value[0])
                setEndDate(value[1])
                fetchClassDashboard();
              }}
            />
          </div>
          </div>
      <div
        style={{ width: "90%", height: "80%", marginTop: 50, marginLeft: 20 }}
      >
        {graph && graph.length === 0 ? <NodataCard /> : <NeetBarGraph data={graph?.length != 0 ? graph : []} />}
      </div>
    </div>
  );
};


const SubjectCard = ({ item }) => {

  return (
    <div
      className="border-16 boxShadow118"
      style={{
        minWidth: 290,
        alignItems: "center",
        border: "#E6E6E6 solid 1px",
        // borderRadius: "2px",
        padding: "10px",
        paddingTop: "10px",
        height: "150px",
        marginRight: 10,
        borderColor: "#E6E6E6",
        backgroundColor: "#FFFFFF",
        marginTop: "20px",
      }}
    >
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div>
          <SubIcon style={{ width: "60%" }} icon={item?.icon} name={item?.subjectName} size={60} />
        </div>
        <div style={{ marginLeft: "30px" }}>
          <div
            style={{ fontSize: "13px", color: "#191919", fontWeight: "bold", marginLeft: -20 }}
          >
            {item?.subjectName}
          </div>
          <div
            style={{ fontSize: "10px", color: "#636363", marginLeft: -20 }}
          >
            {item?.teacherName}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "15px" }}>
        <Progress
          percent={item?.averageAttendance}
          style={{ marginTop: -20 }}
          showInfo={false}
          strokeColor="#594099"
          trailColor="#F6F4FE"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", fontFamily: "roboto" }}>
        <div>Average attendance</div>
        <div>{item?.averageAttendance}%</div>
      </div>
    </div>
  );
};

const AllStudentCard = ({
  setSpecificUserId,
  studentId,
  image,
  sname,
  rollno,
  percent,
  setOverviewFilter,
  item,
  setOthersSelectedTab,
  setUserDetails
}) => {
  const history = useHistory();
  console.log({ item })

  // //append role: ['Student'] to the item... 
  // const newItem = { ...item };
  // newItem.role = ['Student'];

  // console.log({ newItem })

  return (
    <div
      className="border-16 boxShadow118"
      style={{
        width: "290px",
        alignItems: "center",
        border: "#E6E6E6 solid 1px",
        // borderRadius: "6px",
        padding: "10px",
        paddingTop: "10px",
        height: "100px",
        marginRight: 10,
        borderColor: "#E6E6E6",
        backgroundColor: "#FFFFFF",
        marginTop: 15,
        cursor: "pointer",
        marginLeft: "2px",
      }}
      onClick={() => {
        history.push({
          pathname: "/user-management/profile",
          state: {
            key: true
          }
        });
        setUserDetails(item?.user)
        setSpecificUserId(item?.user?.id);
        setOverviewFilter("userId", item?.user?.id);
        setOthersSelectedTab('Basic Profile')
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: 0
        }}
      >
        <div>
          <img style={{ width: 60, height: 60, borderRadius: '50%', marginTop: 10, marginLeft: 20, marginRight: 20 }} src={image} />
        </div>
        <div style={{ marginTop: 10 }}>


          <div>
            <Typography.Text style={{ fontSize: "13px", color: "#191919", fontWeight: "bold", marginTop: "10px", width: "100px" }} ellipsis={{ tooltip: true }}>
              {sname}
            </Typography.Text>
          </div>
          {/* <div
              style={{ fontSize: "13px", fontWeight: "bold", color: "#191919" }}
            >
              {sname}
            </div> */}
          <div style={{ fontSize: "11px", color: "#636363", fontFamily: "roboto" }}>
            Roll No: {rollno}
          </div>
          <div style={{ fontSize: "9px", color: "#636363" }}>
            {percent}%
          </div>
        </div>
      </div>
    </div>
  );
}

function ClassPage({ fetchClassDashboard, classDashboardData, studentPageLoader, setSpecificUserId, setOverviewFilter, setOthersSelectedTab, setUserDetails, setStartDate, setEndDate, startDate, endDate }) {

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
    setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
    fetchClassDashboard()
  }, [])

  return (
    <div style={bodyStyle}>
      <div>
        <PageHeader
          goBack={goBack}
          classDashboardData={classDashboardData}
        />
      </div>
      <Row style={{ marginTop: "10px" }}>
        <Col md={{ span: 24 }} xl={{ span: 8 }} sm={{ span: 24 }} lg={{ span: 24 }}>

          <StudentCard
            classDashboardData={classDashboardData} />
        </Col>
        <Col md={{ span: 24 }} xl={{ span: 16 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <StudentBarCard
            classDashboardData={classDashboardData} 
            setStartDate = {setStartDate} 
            setEndDate={setEndDate} 
            startDate={startDate}
            endDate={endDate}
            fetchClassDashboard={fetchClassDashboard}
          />
        </Col>
      </Row>

      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ fontSize: "15px", color: "#191919", fontWeight: "bold", fontFamily: "roboto" }}>
          Subjects
        </div>
        <div style={{ fontSize: "15px", color: "#AEAEAE", marginLeft: 5 }}>({classDashboardData?.subjects?.length})</div>
      </div>
      <div
        className="p-5 scroll-bar-universal "
        style={{ display: "flex", marginTop: "-10px", }}
      >
        {classDashboardData?.subjects?.map((item) => (
          <SubjectCard
            item={item}
          />
        ))}
        {classDashboardData?.subjects.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '28vw' }}><NodataCard /></div> : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ fontSize: "15px", color: "#191919", fontWeight: "bold", fontFamily: "roboto" }}>
          All students{" "}
        </div>
        {classDashboardData?.students?.length >= 4 ?
          <div
            onClick={() => {
              history.push("student-detail-page");
            }}
            style={{
              fontSize: "11px",
              color: "#1089FF",
              fontWeight: "bold",
              cursor: "pointer",
              fontFamily: "roboto",
              marginRight: "8px"
            }}
          >
            VIEW ALL
          </div>
          : null}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {classDashboardData?.students?.map((item, i) => (
          <>
            {i < 5 && (
              <AllStudentCard
                item={item}
                setSpecificUserId={setSpecificUserId}
                studentId={item.user.id}
                sname={item?.user?.name}
                rollno={item.rollNo}
                percent={item.averageAttendance}
                image={`${Api._s3_url}${item?.user?.image}`}
                setOverviewFilter={setOverviewFilter}
                setOthersSelectedTab={setOthersSelectedTab}
                setUserDetails={setUserDetails}
              // history={history}
              />
            )}
          </>


        ))}
      </div>
      <PageDataLoader visible={studentPageLoader} />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { classDashboardData, studentPageLoader, startDate, endDate } = state.attendanceNew;
  return { classDashboardData, studentPageLoader, startDate, endDate };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClassDashboard: () => dispatch(fetchClassDashboard()),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
  setOthersSelectedTab: tab => dispatch(setOthersSelectedTab(tab)),
  setUserDetails: res => dispatch(setUserDetails(res)),
  setStartDate: (val) => dispatch(setStartDate(val)),
  setEndDate: (val) => dispatch(setEndDate(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassPage);

