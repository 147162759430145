import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import { connect } from "react-redux";
import moment from "moment";

const DemoColumn = ({ dashboardData, growthSummary, isMonthOrWeek }) => {
  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    let array = [];
    let k = 0;
    // console.log(
    //   "growthsummary",
    //   growthSummary,
    //   growthSummary.length,
    //   isMonthOrWeek,
    //   array
    // );
    for (let i = 0; i < growthSummary?.length; i++) {
      if (isMonthOrWeek === "week") {
        let objadded = {};
        objadded["name"] = "New";
        objadded["月份"] = moment(growthSummary[i]?.date).format("DD/MM");
        objadded["月均降雨量"] = growthSummary[i]?.added;
        array.push(objadded);
        let objdelete = {};
        objdelete["name"] = "Deleted";
        objdelete["月份"] = moment(growthSummary[i]?.date).format("DD/MM");
        objdelete["月均降雨量"] = growthSummary[i]?.deleted;
        array.push(objdelete);
      }

      if (isMonthOrWeek === "month") {
        let objadded = {};
        objadded["name"] = "New";
        objadded["月份"] = growthSummary[i]?.month;
        objadded["月均降雨量"] = growthSummary[i]?.added;
        array.push(objadded);
        let objdelete = {};
        objdelete["name"] = "Deleted";
        objdelete["月份"] = growthSummary[i]?.month;
        objdelete["月均降雨量"] = growthSummary[i]?.deleted;
        array.push(objdelete);
      }
    }
    setGraphData([...array]);
    return () => {};
  }, [growthSummary]);
  // var data = [
  //   {
  //     name: "New",
  //     月份: "Jan.",
  //     月均降雨量: 18.9,
  //   },
  //   {
  //     name: "New",
  //     月份: "Feb.",
  //     月均降雨量: 28.8,
  //   },
  //   {
  //     name: "New",
  //     月份: "Mar.",
  //     月均降雨量: 39.3,
  //   },
  //   {
  //     name: "New",
  //     月份: "Apr.",
  //     月均降雨量: 81.4,
  //   },
  //   {
  //     name: "New",
  //     月份: "May",
  //     月均降雨量: 47,
  //   },
  //   {
  //     name: "New",
  //     月份: "Jun.",
  //     月均降雨量: 20.3,
  //   },
  //   {
  //     name: "New",
  //     月份: "Jul.",
  //     月均降雨量: 24,
  //   },
  //   {
  //     name: "New",
  //     月份: "Aug.",
  //     月均降雨量: 35.6,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Jan.",
  //     月均降雨量: 12.4,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Feb.",
  //     月均降雨量: 23.2,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Mar.",
  //     月均降雨量: 34.5,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Apr.",
  //     月均降雨量: 99.7,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "May",
  //     月均降雨量: 52.6,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Jun.",
  //     月均降雨量: 35.5,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Jul.",
  //     月均降雨量: 37.4,
  //   },
  //   {
  //     name: "Deleted",
  //     月份: "Aug.",
  //     月均降雨量: 42.4,
  //   },
  // ];
  //   var config = {
  //     data: data,
  //     isGroup: true,
  //     xField: '月份',
  //     yField: '月均降雨量',
  //     seriesField: 'name',
  //     label: {
  //       position: 'middle',
  //       layout: [
  //         { type: 'interval-adjust-position' },
  //         { type: 'interval-hide-overlap' },
  //         { type: 'adjust-color' },
  //       ],
  //     },
  //   };

  var config = {
    data: graphData,
    isGroup: true,
    xField: "月份",
    yField: "月均降雨量",
    seriesField: "name",
    columnStyle: {
      radius: [3, 3, 0, 0],
    },

    color: ["#9876E6", "#FEA5DD"],
    legend: false,
  };
  return <Column {...config} />;
};

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, isMonthOrWeek, growthSummary, dashboardData } =
    state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    isMonthOrWeek,
    growthSummary,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  // getStats: (m) => dispatch(getStats(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemoColumn);
