import { FullscreenOutlined } from '@ant-design/icons'
import { DatePicker, Dropdown, Menu } from 'antd'
import { Title } from 'components/Typography'
import GroupedBarPlot from 'components/groupedBarPlot'
import SwitchComponent from 'components/switchComponent'
import moment from 'moment'
import { Legend } from 'pages/courseManagement/pages/homePage'
import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const BarGraphLegendArray = [
    { color: "#594099", text: "Revenue (₹)" },
    // { color: "#BBB8E8", text: "Popularity" },
]

function CourseBarGraph({ heading, value, isGraphFilterClicked, setIsGraphFilterClicked, isMonth, setIsMonth, isYear, setIsYear, isChecked, setIsChecked, graphData, storeGraphData, setBarChartModal, setCurrentPie, setCourseType, courseType, courseDashboardData }) {
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 10 });

    let graphFilterArray = [
        { value: "liveCourse", label: "Live courses" },
        { value: "videoResource", label: "Video courses" },
        { value: "studyMaterial", label: "Study materials" },
        { value: "testSeries", label: "Test series" },
    ]

    graphFilterArray = graphFilterArray?.filter((filter) =>
        courseDashboardData?.overview?.some((item) => item?.overviewType === filter?.value)
    );

    const graphMenu = (
        <Menu className='different-bgc-dropdown' style={{ borderRadius: "8px", padding: "10px", width: "fit-content", boxShadow: "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1) !important", backgroundColor: "#FFFFFF" }}>
            {graphFilterArray?.map((item) => (
                <Menu.Item style={{ fontSize: 15, cursor: "pointer", color: courseType == item?.value ? "#191919" : "#636363", fontWeight: courseType == item?.value ? 600 : "normal", borderRadius: 4, marginTop: 5, padding: "5px 10px" }} onClick={() => { setCourseType(item?.value) }}>{item?.label}</Menu.Item>
            ))}
        </Menu>
    )
    return (
        <div style={{ height: "100%", }}>
            <div className='r-c-sb' style={{ width: "100%" }}>
                <div style={{ marginTop: -7 }}>
                    {value == "liveCourseGraph" ?
                        <Title>{heading}</Title> :
                        <Dropdown overlay={isGraphFilterClicked ? graphMenu : <></>} trigger={['click']} open={isGraphFilterClicked} >
                            <div className='display-flex' onClick={(e) => { e.stopPropagation() }}>
                                <Title>{heading}</Title>
                                {isGraphFilterClicked ?
                                    <FiChevronUp className='graph-chevronStyle' onClick={() => { setIsGraphFilterClicked(false) }} /> :
                                    <FiChevronDown className='graph-chevronStyle' onClick={() => { setIsGraphFilterClicked(true) }} />}
                            </div>
                        </Dropdown>
                    }
                </div>
                <div style={{ display: "flex", gap: 20 }}>
                    {!isChecked ?
                        <DatePicker
                            allowClear={false}
                            picker="month"
                            format={'MMM'}
                            value={moment(isMonth, 'MMM')}
                            className='graph-picker-style'
                            onChange={(date, dateString) => {
                                setIsMonth(moment(date).format('MMM'))
                            }}
                        /> :
                        <DatePicker
                            allowClear={false}
                            picker="year"
                            value={moment(isYear)}
                            className='graph-picker-style'
                            onChange={(date, dateString) => {
                                setIsYear(moment(date).format('YYYY'))
                            }}
                        />}
                    <SwitchComponent
                        setIsChecked={setIsChecked}
                        isChecked={isChecked} />
                    <FullscreenOutlined className='full-screen-expand-icon' onClick={() => { setBarChartModal(true), setCurrentPie(1) }} />
                </div>
            </div>
            {/* <div><Information>Popularity & revenue</Information></div> */}
            <div className='m-t-10'><GroupedBarPlot data={graphData?.length != 0 ? graphData : []} setVisibleRange={setVisibleRange} visibleRange={visibleRange} length={storeGraphData?.courseDetails?.length} source={"courseHome"} /></div>
            {BarGraphLegendArray?.map((item) => (
                <Legend color={item?.color} text={item?.text} />
            ))}
        </div>
    )
}

export default CourseBarGraph