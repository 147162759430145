
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { getTestSeriesDetails } from 'actions/studentMarketPlace'
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader'
import CourseDetailsDescriptionSection from '../courseDetailsDescriptionSection';
import IndividualTests from '../coursePageCompo/individualTests';
import CourseContent from '../coursePageCompo/courseContent';
import ExamPattern from '../coursePageCompo/examPattern';
import RelatedCourse from '../coursePageCompo/relatedCourse';
import RelatedStudyMaterial from '../coursePageCompo/relatedStudyMaterial';
import CourseTestContent from '../coursePageCompo/courseTestContent';






const OnlineTestSeries = ({ getTestSeriesDetails, singleCourseDetailsDataContainer }) => {
    const history = useHistory();
    const location = useLocation();
    const [pageLoader, togglePageLoader] = useState(false);

    console.log('here', location.state, singleCourseDetailsDataContainer);
    useEffect(() => {
        togglePageLoader(true);
        getTestSeriesDetails(location.state.fromTestSeriesPage?.id, () => { togglePageLoader(false); });
    }, [])
    return (
        <div className='parent' style={{ backgroundColor: '#E6E6E6' }}>
            <div className='parent-compo'>
                <div className='container-1'>
                    <PageHeader title={"Test Series"} style={{}} onBackPress={() => { history.push('/courses-list') }} />
                </div>
                <div className='container-3-videoResource'>


                    <CourseDetailsDescriptionSection singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.testSeriesDetails} fromTestSeriesPage={location.state.fromTestSeriesPage} testCount={singleCourseDetailsDataContainer?.testData?.length} />
                    <IndividualTests singleCourseDetailsDataContainer={singleCourseDetailsDataContainer} fromTestSeriesPage={location.state.fromTestSeriesPage} />
                    {singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries?.length > 0 ? (
                        <CourseTestContent singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries} resourceType={"testSeries"} fromTestSeriesPage={location.state.fromTestSeriesPage} />
                    ) : (
                        <></>
                    )}
                    <ExamPattern singleCourseDetailsDataContainer={singleCourseDetailsDataContainer} fromTestSeriesPage={location.state.fromTestSeriesPage} />
                    {/* <RelatedCourse
                        singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.relatedResources } fromTestSeriesPage={location.state.fromTestSeriesPage} 
                    /> */}

                    <RelatedStudyMaterial singleCourseDetailsDataContainer={singleCourseDetailsDataContainer?.relatedResources} fromTestSeriesPage={location.state.fromTestSeriesPage} />


                </div>

            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { singleCourseDetailsDataContainer } = state.studentMarketPlace;
    return {
        singleCourseDetailsDataContainer
    }
};

const mapDispatchToProps = (dispatch) => ({
    getTestSeriesDetails: (id, callback) => dispatch(getTestSeriesDetails(id, callback)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineTestSeries)