import {
    SET_MY_COURSE_HOME,
    SET_MY_COURSE_HOME_FOR_TEACHER,
    STORE_USER_MARKET_PLACE_HOME_DATA,
    STORE_OFFERS_CAROUSEL_DATA,
    FETCH_CART_DETAILS,
    FETCH_PROMO_CODES,
    POST_PAYMENT_DETAILS,
    SET_CART_COUNT,
    SET_COUPON,
    SET_MYQUIZ_DATA,
    SET_PDF_URL,
    SET_PROMO_PRICE,
    SET_QA_DATA,
    SET_QUIZ_FLOW_COUNT,
    SET_QUIZ_RESULTS,
    SET_QUIZ_SOLUTION,
    SET_RENDER_PAGE_LOADER,
    SET_SIDEBAR_STUDENT_HOME,
    SET_STUDY_MATERIAL,
    SET_VIDEO_COMPLETION_STATUS,
    SET_VIDEO_RESOURCES,
    STORE_PAYMENT_DETAILS_NEEDED,
    STORE_SINGLE_BUYNOW,
    STORE_USER_COURSE_DETAILS,
    STORE_USER_COURSE_REVIEWS,
    SET_ALL_COURSES_DATA,
    SET_EXPLORE_COURSE_DATA,
    SET_PARENT_LINK,
    SET_RESOURCES_TYPE,
    SET_QUIZ_PARAM_ITEM,
    STORE_MARKET_SETTINGS_DATA,
    SET_QUIZ_SAVED_DATA,
    SET_QUIZ_ATTEMPTED,
    STORE_CART_COUNT_DATA,
    STORE_STANDARDS_STUDENTS,
    SET_IS_LOGOUT_CLICKED,
    SET_IS_MY_ACCOUNT_CLICKED,
    SET_COLLAPSE_CHAPTER_LIST,
    SET_FROM_TEST_SERIES,
    SET_PROMO_CODE,
    SET_VALUE_FOR_CHECKOUT,
    SET_COUPON_LIST,
    SET_SELECTED_COUPON_CODE,
    STORE_COURSE_ID_AND_TYPE,
    SET_DOWNLOAD_BAR_VISIBLE,
    SET_IS_OTP_VERIFIED,
    SET_IS_EMAIL_OTP_VERIFIED,
    SET_SHOW_CONTACT_BAR,
    STORE_ENQUIRY_FORM_COURSE_LIST_DATA,
    STORE_SELECTED_TERM,
    STORE_TERM_LIST,
    STORE_ACTIVE_TERM_INDEX,
    STORE_PAY_TERM,
    SET_SELECTED_SUGGESTION,
    SET_OFFERS_BAR_VISIBLE,
    STORE_COURSE_DATA,
    SET_DEVICE_SPECIFIC_NAME
} from "actions/studentMarketPlace";

const initialState = {
    myCoursePage: [],
    myCoursePageForTeacher: [],
    userMarketPlaceHomeData: [],
    offersCarouselData: [],
    myVideoResources: [],
    courseQuizQuestions: [],
    quizResults: {},
    quizSolution: [],
    quizFlowCount: 0,
    pdfViewFile: {},
    questionAnswerData: [],
    studyMaterialData: [],
    sideBarStudentHome: false,
    videoCompletionStatus: {},
    promoCodeDetials: [],
    cartDetails: {},
    paymentOrderDetails: null,
    paymentStatus: null,
    singleBuyNowDetails: {},
    cartCount: null,
    couponInCart: false,
    storePromoPrice: null,
    renderPageLoading: false,
    singleCourseDetailsDataContainer: [],
    singleCourseDetailsReviews:[],
    allCoursesData: [],
    exploreCourseData: [],
    parentLink: { parentName: null, childName: null, parentId: null, childId: null },
    paramAttemptQuiz: { id: null, itemId: null, type: null },
    storeResourceType: '',
    quizParamItems: {
        id: null,
        itemId: null,
        type: null
    },
    marketSettingData: {},
    savedQuizData: [],
    quizAttempted: false,
    cartCountData: null,
    storeStudentStandard: [],
    isLogoutClicked: false,
    isMyAccountClicked: false,

    collapseChapterList: [],
    fromTestSeries: false,
    isPromocode: '',
    storeCheckOutValue: false,
    couponList: [],
    selectedCouponCode: null,
    isDownloadBarVisible: true,
    isOtpVerified: false,
    isEmailOtpVerified: false,
    showContactBar: false,
    enquiryFormCourseListData: [],
    offerAppliedData: null,
    termList: null,
    selectedTerm: null,
    courseIdAndTye: {},
    activeTermIndex: 0,
    storePayTerm: null,
    selectedSuggestion : null,
    storeCourseData:[],
    storeDeviceName:''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MY_COURSE_HOME: {
            return {
                ...state,
                myCoursePage: action.val,
            }
        }
        case SET_MY_COURSE_HOME_FOR_TEACHER: {
            return {
                ...state,
                myCoursePageForTeacher: action.val,
            }
        }
        case STORE_USER_MARKET_PLACE_HOME_DATA: {
            return {
                ...state,
                userMarketPlaceHomeData: action.val
            }
        }
        case STORE_OFFERS_CAROUSEL_DATA: {
            return {
                ...state,
                offersCarouselData: action.val
            }
        }
        case SET_VIDEO_RESOURCES: {
            return {
                ...state,
                myVideoResources: action.val,
            }
        }
        case SET_MYQUIZ_DATA: {
            return {
                ...state,
                courseQuizQuestions: action.val,
            }
        }
        case SET_QUIZ_RESULTS: {
            return {
                ...state,
                quizResults: action.val,
            }
        }
        case SET_QUIZ_SOLUTION: {
            return {
                ...state,
                quizSolution: action.val,
            }
        }
        case SET_QUIZ_FLOW_COUNT: {
            return {
                ...state,
                quizFlowCount: action.val,
            }
        }
        case SET_PDF_URL: {
            return {
                ...state,
                pdfViewFile: action.val,
            }
        }
        case SET_QA_DATA: {
            return {
                ...state,
                questionAnswerData: action.val,
            }
        }

        case SET_STUDY_MATERIAL: {
            return {
                ...state,
                studyMaterialData: action.val,
            }
        }
        case SET_SIDEBAR_STUDENT_HOME: {
            return {
                ...state,
                sideBarStudentHome: action.val,
            }
        }
        case SET_VIDEO_COMPLETION_STATUS: {
            return {
                ...state,
                videoCompletionStatus: action.val,
            }
        }
        case FETCH_PROMO_CODES: {
            return {
                ...state,
                promoCodeDetials: action.res
            }
        }
        case FETCH_CART_DETAILS: {
            return {
                ...state,
                cartDetails: action.res
            }
        }

        case STORE_PAYMENT_DETAILS_NEEDED: {
            return {
                ...state,
                paymentOrderDetails: action.res
            }
        }
        case SET_VALUE_FOR_CHECKOUT: {
            return {
                ...state,
                storeCheckOutValue: action.val
            }
        }

        case POST_PAYMENT_DETAILS: {
            return {
                ...state,
                paymentStatus: action.res
            }
        }

        case STORE_SINGLE_BUYNOW:
            return {
                ...state,
                singleBuyNowDetails: action.res
            }

        case SET_CART_COUNT: {
            return {
                ...state,
                cartCount: action.val
            }
        }

        case SET_COUPON:
            return {
                ...state,
                couponInCart: action.res
            }

        case SET_PROMO_PRICE:
            return {
                ...state,
                storePromoPrice: action.res
            }
        case SET_RENDER_PAGE_LOADER:
            return {
                ...state,
                renderPageLoading: action.val
            }
        case STORE_USER_COURSE_DETAILS:
            return {
                ...state,
                singleCourseDetailsDataContainer: action.val
            }
        case STORE_USER_COURSE_REVIEWS:
            return {
                ...state,
                singleCourseDetailsReviews: action.val
            }
        case SET_ALL_COURSES_DATA:
            return {
                ...state,
                allCoursesData: action.val
            }
        case SET_EXPLORE_COURSE_DATA:
            return {
                ...state,
                exploreCourseData: action.val
            }
        case SET_PARENT_LINK: {
            return {
                ...state,
                parentLink: action.val
            }
        }
        case SET_RESOURCES_TYPE:
            return {
                ...state,
                storeResourceType: action.val
            }
        case SET_QUIZ_PARAM_ITEM:
            return {
                ...state,
                paramAttemptQuiz: action.val,

            }
        case STORE_MARKET_SETTINGS_DATA:
            return {
                ...state,
                marketSettingData: action.val
            }
        case SET_QUIZ_SAVED_DATA:
            return {
                ...state,
                savedQuizData: action.val
            }
        case SET_QUIZ_ATTEMPTED:
            return {
                ...state,
                quizAttempted: action.val
            }
        case STORE_CART_COUNT_DATA:
            return {
                ...state,
                cartCountData: action.val
            }
        case STORE_STANDARDS_STUDENTS:
            return {
                ...state,
                storeStudentStandard: action.val
            }
        case SET_IS_LOGOUT_CLICKED:
            return {
                ...state,
                isLogoutClicked: action.val
            }
        case SET_IS_MY_ACCOUNT_CLICKED:
            return {
                ...state,
                isMyAccountClicked: action.val
            }
        // case SET_COLLAPSE_CHAPTER_LIST :
        //     return{
        //         ...state,
        //         collapseChapterList:action.val
        //     }
        case SET_PROMO_CODE:
            return {
                ...state,
                isPromocode: action.val
            }
        case SET_FROM_TEST_SERIES:
            return {
                ...state,
                fromTestSeries: action.val
            }
        case SET_COUPON_LIST:
            return {
                ...state,
                couponList: action.val
            }
        case SET_SELECTED_COUPON_CODE:
            return {
                ...state,
                selectedCouponCode: action.val
            }
        case STORE_COURSE_ID_AND_TYPE: {
            let newCourseIdTypeObj = { ...state.courseIdAndTye };
            newCourseIdTypeObj[action.key] = action.val;
            return {
                ...state,
                courseIdAndTye: newCourseIdTypeObj,
            }
        }
        case SET_DOWNLOAD_BAR_VISIBLE:
            return {
                ...state,
                isDownloadBarVisible: action.val
            }
        case SET_IS_OTP_VERIFIED:
            return {
                ...state,
                isOtpVerified: action.val
            }
        case SET_IS_EMAIL_OTP_VERIFIED:
            return {
                ...state,
                isEmailOtpVerified: action.val
            }
        case SET_SHOW_CONTACT_BAR:
            return {
                ...state,
                showContactBar: action.val
            }
        case STORE_ENQUIRY_FORM_COURSE_LIST_DATA:
            return {
                ...state,
                enquiryFormCourseListData: action.val
            }
        case STORE_TERM_LIST:
            return {
                ...state,
                termList: action.val
            }
        case STORE_SELECTED_TERM:
            return {
                ...state,
                selectedTerm: action.val
            }
        case STORE_ACTIVE_TERM_INDEX:
            return {
                ...state,
                activeTermIndex: action.val
            }
        case STORE_PAY_TERM:
            return {
                ...state,
                storePayTerm: action.val
            }
        case SET_SELECTED_SUGGESTION:
            return {
                ...state,
                selectedSuggestion : action.val
            }
        case SET_OFFERS_BAR_VISIBLE:
            return {
                ...state,
                isOffersBarVisible: action.val
            }
        case STORE_COURSE_DATA:
        //    let tempData=state.userMarketPlaceHomeData
        //    tempData.courses.meta=action.val.meta
        //    tempData.courses.items.push(...action.val.items)
            return{
                ...state,
                storeCourseData:action.val
            }
        case SET_DEVICE_SPECIFIC_NAME :
            return{
                ...state,
                storeDeviceName:action.val
            }
        default:
            return {
                ...state
            };
    }
}

export default reducer;