import { getAllClasses } from 'actions/classRoom';
import { Modal } from 'antd';
import { Button } from 'components/Button';
import React from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ClassCreatedModal({ showClassCreatedModal, setShowClassCreatedModal, newClassDetails, getAllClasses }) {
  const history = useHistory();
  const handleCancel = () => {
    setShowClassCreatedModal(false)
    getAllClasses();
  }
  const handleLaterClick = () => {
    setShowClassCreatedModal(false);
    getAllClasses();
  }
  const handleAddNowClick = () => {
    setShowClassCreatedModal(false);
    history.push("/classroom/classroom-details")
  }
  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={showClassCreatedModal}
        onCancel={handleCancel}
        onOk={handleCancel}
        closable={false}
        footer={null}>
        <div style={{ padding: 10 }}>
          <div className='r-c-c-c'>
            <div className='text-xlg r-c-c bold-600 font-pt-serif text-square-bg-grey' style={{ color: "#9876e6", fontSize: 24 }}>{newClassDetails?.section}</div>
            <div className=' m-t-10' style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>{newClassDetails?.std}</div>
            <div style={{ color: "#636363", fontSize: 14 }}>{newClassDetails?.courseName}</div>
            <div className='m-t-20' style={{ color: "#191919", fontSize: 20, fontWeight: "bold" }}>New classroom successfully created!</div>
            <center style={{ color: "#636363", fontSize: 15 }}>Would you like to add students and curriculum for the newly created class?</center>
            <div className='r-c-se m-t-30' style={{ width: "90%" }}>
              <Button
                style={{ width: '130px' }}
                onClick={handleLaterClick}
              >
                MAYBE LATER
              </Button>
              <Button
                type='primary'
                style={{ width: '130px' }}
                onClick={handleAddNowClick}
              >
                ADD NOW
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  const { newClassDetails } = state.classRoom;
  return {
    newClassDetails
  };
};

const mapDispatchToProps = dispatch => ({
  getAllClasses: () => dispatch(getAllClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassCreatedModal)

// export default ClassCreatedModal