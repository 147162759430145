import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import ChatScreen from './functionalGiftedWebChat'
import { PageHeader } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import PageDataLoader from 'components/pageDataLoader';
import { setNewChats } from 'actions/adminCare';

function StudentChat({ setNewChats }) {

    const [pageLoader, togglePageLoader] = useState(false)

    const history = useHistory();
    useEffect(() => {
        setNewChats([])
    })
    const goBack = () => {
        setNewChats([])
        history.goBack()
    }


    return (
        <div style={{ width: "100%", height: "100%" }}>
            <PageHeader
                title={<div className="text-md">Chat</div>}
                onBack={() => goBack()}
                backIcon={<ArrowLeftOutlined />}
            />
            <div style={{ width: "100%", height: "83%" }}>



                <ChatScreen studentChat={true} togglePageLoader={togglePageLoader} isAulasChat={true} />
                <PageDataLoader visible={pageLoader} />
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    setNewChats: (chats) => dispatch(setNewChats(chats))
});


export default connect(null, mapDispatchToProps)(StudentChat);