import React from "react";
import Progress from "antd/lib/progress";
import "antd/lib/progress/style/index";
import "../design/layout.css";
import { FiCheckCircle } from "react-icons/fi";
import { GoCalendar } from "react-icons/go";
import { MdStars } from "react-icons/md";
import moment from "moment";
const phIc = require("../Assets/sub/physics.svg").default;
const chIc = require("../Assets/sub/chem.svg").default;
const boIc = require("../Assets/sub/bio.svg").default;
const hiIc = require("../Assets/sub/Economics.svg").default;
const mtIc = require("../Assets/sub/mathema.svg").default;

export const AssignmentCard = ({
  subject,
  topic,
  dueDate,
  status,
  completion,
  onPress,
  newItem
}) => {
  const typeIcon =
    status === "OverDue" || "Today" ? (
      <GoCalendar />
    ) : status === "Attempted" || status === "Finished" ? (
      <FiCheckCircle />
    ) : (
      <MdStars />
    );

  const illus =
    subject === "Physics"
      ? phIc
      : subject === "Chemistry"
      ? chIc
      : subject === "History"
      ? hiIc
      : subject === "Biology"
      ? boIc
      : subject === "Mathematics"
      ? mtIc
      : hiIc;
  return (
    <div
      onClick={onPress}
      className="relative assignmentCard NutinFont p-10 m-b-10"
    >
      <div className="r-afs">
        <img src={illus} alt="" className="subillustration" />
        <div>
          <div className="text-md bold-600 color-black">{subject}</div>
          <div className="text-sm simgrey">{topic}</div>
        </div>
      </div>
      <Progress percent={completion.toFixed(0)} />
      <div className="statusSec statusIcon inline-flex">
        {status === "Today" ? (
          <div className="text-xs">
            <GoCalendar /> Due : {moment(dueDate).format("DD MMM YYYY")}
          </div>
        ) : (
          <div className="text-xs">
            {typeIcon}{" "}
            {status === "OverDue"
              ? "Over due"
              : status === "Finished"
              ? "Submitted"
              : status}
          </div>
        )}
      </div>
      {status === "Today" || newItem ? (
        <div className="text-xs newTag p-t-5 p-b-5 p-l-10 p-r-10 yellowBack white bold-500 absolute">
          <div className="tagShadow absolute" />
          NEW
        </div>
      ) : null}
    </div>
  );
};
