import React, { useEffect, useState } from 'react';
import { Input, Modal } from 'antd';
import { Button } from 'components/Button';
const CreateQuizModal = ({ setIsModalOpen, isModalOpen,addQuiz,setAddQuiz,sectionData,storeSectionIndex ,storeTopicIndex,setSectionData,editTrue,quizDataContainer,id}) => {

    const [tempQuiz, setTempQuiz] = useState(addQuiz);
    useEffect(()=>{
        console.log("quizDataContainer",quizDataContainer);
        if(editTrue){
        console.log("quizDataContainer1",quizDataContainer);

            setTempQuiz(quizDataContainer)
        }
    },[editTrue,quizDataContainer])


    const handleSave = () => {
        setAddQuiz(tempQuiz);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setTempQuiz(addQuiz); // Restore the old value
    };

    return (
        <>
            <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
                <div>
                    <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Add a quiz name</div>
                    <div style={{ marginTop: "20px", padding: "30px" }}>
                        <Input value={tempQuiz} onChange={(e)=>setTempQuiz(e.target.value)} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "10px" }}>
                        <Button onClick={handleCancel}>CLOSE</Button>
                        <Button type='primary' onClick={handleSave} style={{ marginLeft: "30px" }}>SAVE</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default CreateQuizModal;