import { Menu, Pagination, Radio, Row, Typography, Dropdown, Switch } from 'antd';
import { Api } from 'services';
import { connect } from 'react-redux';
import PageHeader from 'components/pageHeader';
import { SubHeading } from 'components/Typography';
import React, { useState, useEffect } from 'react';
import testSeriesIcon from '../../Assets/ic-course.svg'
import PageDataLoader from 'components/pageDataLoader';
import { fetchCourseVA, fetchStudyMaterialsVA, fetchTestSeriesVA, fetchVideoResourcesVA, setWhichViewAllClicked, storeAllStudentByCourseDetails, storeResourceData } from 'actions/feeManagement';
import { setSelectedCourse } from 'actions/userManagementV2';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined } from '@ant-design/icons';
import { InputSearch } from 'components/customInputs';
import { Button } from 'components/Button';
import { getCoursesCategoriesForFee, getCoursesSubCategories } from 'actions/courseManagement';

const defaultCourseImage = require('Assets/default_course-img.svg').default;

const Card = ({ imageSrc, title, testSeriesIconSrc, description, count, cardClick }) => (
    <div style={{ cursor: 'pointer', width: '23.5%' }} onClick={cardClick}>
        {imageSrc && <img style={{ borderRadius: 8, height: 160, width: 270, objectFit: 'cover', border: '1px solid #EDEDED' }} src={imageSrc} />}
        {title && <SubHeading bold style={{ width: 300 }}>{title}</SubHeading>}
        {/* {testSeriesIconSrc && (
            <div className='p-15' style={{ width: 270, height: 80, border: '1px solid #EDEDED', borderRadius: 8, backgroundColor: 'white' }}>
                <Row>
                    <img src={testSeriesIconSrc} style={{ width: 50 }} />
                    <div className='m-l-10 m-t-15'>{count}</div>
                </Row>
            </div>
        )} */}
        {description && <SubHeading bold style={{ width: 265 }}>{description}</SubHeading>}
    </div>
);

const AllStudentsCoursesVA = ({ history, viewAllIs, fetchCourseVA, fetchStudyMaterialsVA, fetchTestSeriesVA, fetchVideoResourcesVA, viewAllDATA, storeResourceData, setWhichViewAllClicked, setSelectedCourse, storeAllStudentByCourseDetails, allStudentsByCoursesReducer, getCoursesSubCategories, getCoursesCategoriesForFee, courseCategoryDataForFee, courseSubCategoriesData }) => {

    let paginationComponent;
    const [pageLoader, togglePageLoader] = useState(false);
    const bodyStyle = { backgroundColor: "#FAFAFA", width: " calc(100% + 30px)", height: "calc(100% + 40px)", margin: "-20px", padding: "20px", overflow: "auto" };
    const [statusArray, setStatusArray] = useState([
        { label: "All", value: null, },
        { label: "Published", value: true, },
        { label: "Unpublished", value: false, },
    ]);
    const modeArray = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Hybrid", value: "hybrid" }, { label: "Offline", value: "offline" }];

    const [isMultipleFilterClicked, setMultipleFilterClicked] = useState(false);
    const [isFilterCategoryClicked, setFilterCategoryClicked] = useState(false);
    const [isFilterSubCategoryClicked, setFilterSubCategoryClicked] = useState(false);
    const [modeLabel, setModeLabel] = useState('All');
    const [statusLabel, setStatusLabel] = useState('All');
    // const [selectedSubCategory, setSeletedSubCategory] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectMode, setSelectMode] = useState(allStudentsByCoursesReducer?.mode);
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [deleteToggle, setDeleteToggle] = useState(allStudentsByCoursesReducer?.deleted);

    const titles = {
        'courses': 'Live Courses',
        'videoResource': 'Video Resources',
        'testSeries': 'Online Test Series',
        'studyMaterial': 'Study Material',
    };

    const title = titles[viewAllIs] || '';

    let published = null;
    let modes = null

    if (allStudentsByCoursesReducer?.statusFilter?.length > 0) {
        if (allStudentsByCoursesReducer?.statusFilter?.includes(true) && allStudentsByCoursesReducer?.statusFilter?.includes(false)) {
            published = null;
        } else if (allStudentsByCoursesReducer?.statusFilter?.includes(true)) {
            published = true;
        } else if (allStudentsByCoursesReducer?.statusFilter?.includes(false)) {
            published = false;
        }
    }

    if (selectMode?.length > 0) {
        if (selectMode?.includes(null)) {
            modes = null
        } else {
            modes = selectMode
        }
    }
    let params = {
        search: allStudentsByCoursesReducer?.search ? allStudentsByCoursesReducer?.search : undefined,
        activeStatus: deleteToggle ? "inactive" : null,
        // mode: allStudentsByCoursesReducer?.mode ? allStudentsByCoursesReducer?.mode : undefined,
        modes: modes,
        // published: allStudentsByCoursesReducer?.statusFilter !== 'inactive' ? allStudentsByCoursesReducer?.statusFilter : undefined,
        published: published,
        subcategoryId: allStudentsByCoursesReducer?.subcategoryId ? allStudentsByCoursesReducer?.subcategoryId : undefined,
        limit: 16
    }

    useEffect(()=>{
        if(!isMultipleFilterClicked){
            setSelectMode(allStudentsByCoursesReducer?.mode)
            const latestStatus =  selectedStatus == 'Unpublished' ? [false] : selectedStatus == 'Published' ? [true] : [null]
            storeAllStudentByCourseDetails('statusFilter', latestStatus);
            setDeleteToggle(allStudentsByCoursesReducer?.deleted);
        }
    },[isMultipleFilterClicked])

    useEffect(() => {
        storeAllStudentByCourseDetails('mode', []);
        storeAllStudentByCourseDetails('statusFilter', []);
        storeAllStudentByCourseDetails('subcategoryId', null);
        storeAllStudentByCourseDetails('search', '');
        storeAllStudentByCourseDetails('deleted', false);

        togglePageLoader(true);
        switch (viewAllIs) {
            case 'courses':
                fetchCourseVA(params, () => togglePageLoader(false));
                break;

            case 'testSeries':
                fetchTestSeriesVA(params, () => togglePageLoader(false));
                break;

            case 'videoResource':
                fetchVideoResourcesVA(params, () => togglePageLoader(false));
                break;

            case 'studyMaterial':
                fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                break;

            default:
                break;
        }
        getCoursesCategoriesForFee(null, () => { })
    }, [])

    useEffect(() => {
        togglePageLoader(true);
        switch (viewAllIs) {
            case 'courses':
                fetchCourseVA(params, () => togglePageLoader(false));
                break;

            case 'testSeries':
                fetchTestSeriesVA(params, () => togglePageLoader(false));
                break;

            case 'videoResource':
                fetchVideoResourcesVA(params, () => togglePageLoader(false));
                break;

            case 'studyMaterial':
                fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                break;

            default:
                break;
        }
    }, [allStudentsByCoursesReducer?.search, allStudentsByCoursesReducer?.subcategoryId])

    const handlePageChange = (value) => {
        let params = {
            page: value,
            activeStatus: allStudentsByCoursesReducer?.deleted ?  'inactive' : undefined,
            published: allStudentsByCoursesReducer?.statusFilter !== 'inactive' ? allStudentsByCoursesReducer?.statusFilter : undefined,
            limit: 16
        }

        togglePageLoader(true);
        switch (viewAllIs) {
            case 'courses':
                fetchCourseVA(params, () => togglePageLoader(false));
                break;

            case 'testSeries':
                fetchTestSeriesVA(params, () => togglePageLoader(false));
                break;

            case 'videoResource':
                fetchVideoResourcesVA(params, () => togglePageLoader(false));
                break;

            case 'studyMaterial':
                fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                break;

            default:
                break;
        }
    }

    switch (viewAllIs) {
        case 'courses':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.liveCourses?.courses?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.liveCourses?.courses?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        case 'testSeries':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.onlineTestSeries?.testSeries?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.onlineTestSeries?.testSeries?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        case 'videoResource':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.videoResources?.videoResources?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.videoResources?.videoResources?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        case 'studyMaterial':
            paginationComponent = (
                <Pagination
                    size='small'
                    current={viewAllDATA?.studyMaterials?.studyMaterials?.meta?.currentPage}
                    defaultCurrent={1}
                    total={viewAllDATA?.studyMaterials?.studyMaterials?.meta?.totalPages * 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            );
            break;

        default:
            break;
    }

    useEffect(() => {
        let params = {
            disablePagination: false,
            limit: 16,
            published: null
        }

        togglePageLoader(true)

        switch (viewAllIs) {
            case 'courses':
                fetchCourseVA(params, () => togglePageLoader(false));
                break;

            case 'testSeries':
                fetchTestSeriesVA(params, () => togglePageLoader(false));
                break;

            case 'videoResource':
                fetchVideoResourcesVA(params, () => togglePageLoader(false));
                break;

            case 'studyMaterial':
                fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                break;

            default:
                togglePageLoader(false);
                break;
        }
    }, []);

    const FilterTitle = ({ titleName }) => {
        return (
            <div className='m-b-5' style={{ color: "#191919", fontWeight: 500 }}>{titleName}</div>
        )
    }

    const toggleMode = (mode, label) => {
        let updatedModes;
        if (Array.isArray(selectMode) && selectMode.includes(mode)) {
            updatedModes = selectMode.filter((i) => i !== mode);
        } else {
            updatedModes = [...(selectMode || []), mode];
        }
        // If all modes are selected, replace with an empty array to signify "All"
        if (updatedModes.length === modeArray.length - 1) {
            updatedModes = [];
        }

        if(mode == null){
            updatedModes = [];  
        }
        setSelectMode(updatedModes)
        // storeAllStudentByCourseDetails("mode", updatedModes);
    };


    const toggleStatus = (value, label) => {
        setStatusLabel(label);
        let updatedStatus;
        if (
            Array.isArray(allStudentsByCoursesReducer?.statusFilter) &&
            allStudentsByCoursesReducer?.statusFilter?.includes(value)
        ) {
            updatedStatus = allStudentsByCoursesReducer?.statusFilter?.filter((i) => i !== value);
        } else {
            updatedStatus = [...(allStudentsByCoursesReducer?.statusFilter || []), value];
        }
        // If all statuses are selected, replace with an empty array to signify "All"
        if (updatedStatus.length === statusArray.length - 1) {
            updatedStatus = [];
        }
        storeAllStudentByCourseDetails("statusFilter", updatedStatus);
    };


    const toggleDelete = (value) => {
        console.log("deletedState", value)
        setDeleteToggle(!deleteToggle)
    };
    const DeletedFilter = () => {

        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Deleted"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Switch checked={deleteToggle} onChange={toggleDelete} />
                </div>
            </div>
        )
    }
    const StatusFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course status"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {statusArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{
                                height: "fit-content", width: "fit-content",
                                backgroundColor: allStudentsByCoursesReducer?.statusFilter?.includes(item?.value) || (allStudentsByCoursesReducer?.statusFilter?.length === 0 && item?.value === null) ? "#1089FF" : "#FFFFFF",
                                border: allStudentsByCoursesReducer?.statusFilter?.includes(item?.value) || (allStudentsByCoursesReducer?.statusFilter?.length === 0 && item?.value === null) ? "1px solid #1089FF" : "1px solid #E6E6E6",
                                color: allStudentsByCoursesReducer?.statusFilter?.includes(item?.value) || (allStudentsByCoursesReducer?.statusFilter?.length === 0 && item?.value === null) ? "#FFFFFF" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12
                            }} onClick={() => toggleStatus(item?.value, item?.label)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const ModeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Mode of class"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {modeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{
                                height: "fit-content", width: "fit-content",
                                backgroundColor: selectMode.includes(item?.value) || (selectMode?.length === 0 && item?.value === null) ? "#1089FF" : "#FFFFFF",
                                border: selectMode.includes(item?.value) || (selectMode?.length === 0 && item?.value === null) ? "1px solid #1089FF" : "1px solid #E6E6E6", color: selectMode.includes(item?.value) || (selectMode?.length === 0 && item?.value === null) ? "#FFFFFF" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12
                            }} onClick={() => toggleMode(item?.value, item?.label)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const handleCategoryDropdownVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);
        setFilterSubCategoryClicked(false);
    };

    const handleSubCategoryDropdownVisibleChange = (visible) => {
        // setFilterSubCategoryClicked(visible);
    };

    // This function will be called when the dropdown visibility changes
    const handleVisibleChange = (visible) => {
        setFilterCategoryClicked(visible);
    };

    const handleVisible = (visible) => {
        setMultipleFilterClicked(visible);

    }

    const handleApply = () => {
        storeAllStudentByCourseDetails("mode", selectMode);
        setModeLabel(allStudentsByCoursesReducer?.mode);
        storeAllStudentByCourseDetails('deleted', deleteToggle);

        const currentStatusFilter = allStudentsByCoursesReducer?.statusFilter || [];
    const selectedStatusLabel =
        currentStatusFilter.length === 0
            ? "All"
            : statusArray.find((item) => currentStatusFilter.includes(item.value))?.label || "All";

    setSelectedStatus(selectedStatusLabel);


        togglePageLoader(true);
        switch (viewAllIs) {
            case 'courses':
                fetchCourseVA(params, () => togglePageLoader(false));
                break;

            case 'testSeries':
                fetchTestSeriesVA(params, () => togglePageLoader(false));
                break;

            case 'videoResource':
                fetchVideoResourcesVA(params, () => togglePageLoader(false));
                break;

            case 'studyMaterial':
                fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                break;

            default:
                togglePageLoader(false);
                break;
        }
        setMultipleFilterClicked(false)
    }

    const handleReset = () => {
        storeAllStudentByCourseDetails('mode', [])
        storeAllStudentByCourseDetails('statusFilter', []);
        storeAllStudentByCourseDetails('deleted', false);
        setModeLabel('All');
        setStatusLabel('All');
        setSelectedStatus("All");
        setMultipleFilterClicked(false)

        let resetParams = {
            mode: null,
            published: null,
        }

        togglePageLoader(true);
        switch (viewAllIs) {
            case 'courses':
                fetchCourseVA(resetParams, () => togglePageLoader(false));
                break;

            case 'testSeries':
                fetchTestSeriesVA(resetParams, () => togglePageLoader(false));
                break;

            case 'videoResource':
                fetchVideoResourcesVA(resetParams, () => togglePageLoader(false));
                break;

            case 'studyMaterial':
                fetchStudyMaterialsVA(resetParams, () => togglePageLoader(false));
                break;

            default:
                togglePageLoader(false);
                break;
        }
    }

    const multipleFilterMenu = (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#FFF', padding: 20, borderRadius: 8, marginLeft:25 }}>
            {viewAllIs=='courses' ? <ModeFilter /> : <></>}
            
            <StatusFilter />
            <DeletedFilter />
            <div style={{ borderBottom: '1px solid #AEAEAE', width: '100%' }}></div>
            <div className='r-jsb m-t-10'>
                <Button onClick={handleReset} type='link' style={{ fontSize: 14 }}>Reset</Button>
                <Button onClick={handleApply} type='primary' style={{ backgroundColor: '#1089FF', color: 'white', borderRadius: 4, width: '20%', border: 'none' }}>Apply</Button>
            </div>
        </div>
    )

    const filterSubCategoryMenu = (
        <Menu style={{ maxHeight: courseCategoryDataForFee?.length > 3 ? 150 : 'fit-content', overflowY: "scroll", padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
            <Radio.Group onChange={(e) => {
                console.log({ params })
                e.stopPropagation();
                setFilterCategoryClicked(true)
                const selectedSubcategory = e.target.value;
                storeAllStudentByCourseDetails("subcategoryId", selectedSubcategory?.id);
                togglePageLoader(true);
                switch (viewAllIs) {
                    case 'courses':
                        fetchCourseVA(params, () => togglePageLoader(false));
                        break;

                    case 'testSeries':
                        fetchTestSeriesVA(params, () => togglePageLoader(false));
                        break;

                    case 'videoResource':
                        fetchVideoResourcesVA(params, () => togglePageLoader(false));
                        break;

                    case 'studyMaterial':
                        fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                        break;

                    default:
                        togglePageLoader(false);
                        break;
                }
                setFilterCategoryClicked(false);
                setFilterSubCategoryClicked(false);
            }}
            >
                {courseSubCategoriesData?.map((subcategory) => (
                    <Radio key={subcategory?.name} value={subcategory} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{subcategory?.name}</Typography.Text>
                    </Radio>
                ))}
            </Radio.Group>
        </Menu>
    )

    const filterCategoryMenu = (
        <Dropdown
            visible={isFilterCategoryClicked}
            onVisibleChange={handleSubCategoryDropdownVisibleChange}
            overlay={isFilterSubCategoryClicked ? filterSubCategoryMenu : <></>} placement='right' open={isFilterSubCategoryClicked}>
            <Menu className='scroll-bar-universal' style={{ maxHeight: courseCategoryDataForFee?.length > 6 ? 300 : 'fit-content', padding: 10, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014" }}>
                <Radio.Group onChange={(e) => {
                    const selectedCat = e?.target?.value;
                    getCoursesSubCategories(selectedCat?.id, null); setFilterSubCategoryClicked(true); setSelectedCategory({ id: selectedCat?.id, name: selectedCat?.name });
                }}>
                    {courseCategoryDataForFee && courseCategoryDataForFee?.map((category) => (
                        <Radio key={category?.id} value={category} style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 160 }}>{category?.name}</Typography.Text>
                        </Radio>
                    ))}
                </Radio.Group>
            </Menu>
        </Dropdown>
    );

    const actions = [
        <div style={{ width: 650, display: 'flex', flexDirection: 'column' }}>
            <div className='display-flex'>
                <Dropdown overlay={isFilterCategoryClicked ? filterCategoryMenu : <></>} trigger={['click']} open={isFilterCategoryClicked} onVisibleChange={handleVisibleChange}>
                    <div

                        style={{
                            border: "1px solid #E6E6E6",
                            backgroundColor: "#FFFFFF",
                            width: 300,
                            padding: "5px 10px",
                            marginRight: 20,
                            color: "#AEAEAE",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 32,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setFilterCategoryClicked(!isFilterCategoryClicked);
                            setMultipleFilterClicked(false);
                            setFilterSubCategoryClicked(false);
                        }}
                    >
                        <div>Filter by category</div>
                        {!isFilterCategoryClicked && !isFilterSubCategoryClicked ? (
                            allStudentsByCoursesReducer?.subcategoryId ? (
                                <CloseOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilterSubCategoryClicked(false);
                                        storeAllStudentByCourseDetails("subcategoryId", null);
                                        togglePageLoader(true);
                                        switch (viewAllIs) {
                                            case 'courses':
                                                fetchCourseVA(params, () => togglePageLoader(false));
                                                break;

                                            case 'testSeries':
                                                fetchTestSeriesVA(params, () => togglePageLoader(false));
                                                break;

                                            case 'videoResource':
                                                fetchVideoResourcesVA(params, () => togglePageLoader(false));
                                                break;

                                            case 'studyMaterial':
                                                fetchStudyMaterialsVA(params, () => togglePageLoader(false));
                                                break;

                                            default:
                                                togglePageLoader(false);
                                                break;
                                        }
                                    }}
                                    style={{ marginLeft: 8 }}
                                />
                            ) : (
                                <CaretDownOutlined />
                            )
                        ) : (
                            isFilterCategoryClicked ? <CaretUpOutlined /> : null
                        )}
                    </div>
                </Dropdown>

                <Dropdown overlay={isMultipleFilterClicked ? multipleFilterMenu : <></>} trigger={['click']} open={isMultipleFilterClicked} onVisibleChange={handleVisible}>
                    <div
                        style={{
                            border: "1px solid #E6E6E6",
                            backgroundColor: "#FFFFFF",
                            width: "30%",
                            padding: "5px 10px",
                            color: "#AEAEAE",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 43,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setMultipleFilterClicked(!isMultipleFilterClicked);
                            setFilterCategoryClicked(false);
                            setFilterSubCategoryClicked(false);
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <img src={require("../../Assets/aulasMarket/filterIC.svg").default} style={{ height: 18, width: 18, marginRight: 10, marginTop: 3 }} />
                            <div style={{ color: "#191919" }}>Filter</div>
                        </div>
                        {!isMultipleFilterClicked ? (
                            <CaretDownOutlined onClick={() => { setMultipleFilterClicked(!isMultipleFilterClicked) }} />
                        ) : (
                            <CaretUpOutlined onClick={() => { setMultipleFilterClicked(!isMultipleFilterClicked) }} />
                        )}
                    </div>
                </Dropdown>

                <InputSearch allowClear value={allStudentsByCoursesReducer?.search} onEnter={(e) => storeAllStudentByCourseDetails('search', (e))} placeholder={'Search course'} style={{ marginLeft: "10px", borderRadius: 32, backgroundColor: 'white', padding: 5 }} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                {viewAllIs == 'courses' ?
                <div style={{ color: '#AEAEAE' }}>Mode: <span style={{ color: '#636363' }}>
                    {allStudentsByCoursesReducer?.mode?.length === 0
                    ? 'All'
                    : allStudentsByCoursesReducer?.mode?.includes(null)
                    ? ['All', ...allStudentsByCoursesReducer?.mode?.filter(mode => mode !== null)].join(', ')
                    : allStudentsByCoursesReducer?.mode?.join(', ')}
                     &nbsp; |  &nbsp; </span></div>  
                :
                <></>
                }
                 <div style={{ color: '#AEAEAE' }}>Status: <span style={{ color: '#636363' }}>{selectedStatus}</span></div>
            </div>
        </div>
    ];

    return (
        <div style={bodyStyle}>
            <PageHeader title={title} actions={actions} onBackPress={() => {
                     storeAllStudentByCourseDetails('mode', []);
                     storeAllStudentByCourseDetails('deleted', false);
                     history.goBack();
                }} />
            {pageLoader ? <></> :
                <div className='m-t-80'>
                    {viewAllIs === 'courses' &&
                        <div className='display-flex flex-wrap' style={{ gap: 15 }}>
                            {
                                viewAllDATA?.liveCourses?.courses?.items?.length > 0 ?
                                    viewAllDATA?.liveCourses?.courses?.items?.map((item) => {
                                        return <Card description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} cardClick={() => { storeResourceData(item); setSelectedCourse(item?.name); history.push('/fee-management/all-students'); }} />
                                    })
                                    :
                                    <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <div className='r-c-c-c m-t-80'>
                                            <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                            <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                        </div>
                                    </center>
                            }
                        </div>
                    }

                    {viewAllIs === 'videoResource' &&
                        <div className='display-flex flex-wrap' style={{ gap: 15 }}>
                            {viewAllDATA?.videoResources?.videoResources?.items?.length > 0 ?
                                viewAllDATA?.videoResources?.videoResources?.items?.map((item) => (
                                    <Card description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} cardClick={() => { setWhichViewAllClicked('videoResource'); storeResourceData(item); history.push('/fee-management/course-details'); }} />
                                ))
                                :
                                <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <div className='r-c-c-c m-t-80'>
                                        <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                        <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                    </div>
                                </center>
                            }
                        </div>
                    }

                    {viewAllIs === 'studyMaterial' &&
                        <div className='display-flex flex-wrap' style={{ gap: 15 }}>
                            {viewAllDATA?.studyMaterials?.studyMaterials?.items?.length > 0 ?
                                viewAllDATA?.studyMaterials?.studyMaterials?.items?.map((item) => (
                                    <Card description={item?.name} imageSrc={item?.image && item?.image !== 'null' ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} cardClick={() => { setWhichViewAllClicked('studyMaterial'); storeResourceData(item); history.push('/fee-management/course-details'); }} />
                                ))
                                :
                                <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <div className='r-c-c-c m-t-80'>
                                        <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                        <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                    </div>
                                </center>
                            }
                        </div>
                    }

                    {viewAllIs === 'testSeries' &&
                        <div className='display-flex flex-wrap' style={{ gap: 15 }}>
                            {viewAllDATA?.onlineTestSeries?.testSeries?.items?.length > 0 ?
                                viewAllDATA?.onlineTestSeries?.testSeries?.items?.map((item) => (
                                    <Card  description={item?.name} imageSrc={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage}cardClick={() => { setWhichViewAllClicked('testSeries'); storeResourceData(item); history.push('/fee-management/course-details'); }} />
                                )
                                )
                                :
                                <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <div className='r-c-c-c m-t-80'>
                                        <img style={{ width: 250 }} src={require("../../Assets/ill-no-data.svg").default} />
                                        <div className='r-c-c-c bold-600 text-xmd' style={{}}>No Data</div>
                                    </div>
                                </center>
                            }
                        </div>
                    }
                </div>
            }
            <div style={{ position: 'fixed', bottom: 40, right: 30 }}>
                {paginationComponent}
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { viewAllIs, viewAllDATA, allStudentsByCoursesReducer } = state.feeManagement;
    const { courseSubCategoriesData, courseCategoryDataForFee } = state.courseManagement;
    return { viewAllIs, viewAllDATA, allStudentsByCoursesReducer, courseCategoryDataForFee, courseSubCategoriesData };
};

const mapDispatchToProps = (dispatch) => ({
    fetchCourseVA: (params, callback) => dispatch(fetchCourseVA(params, callback)),
    fetchVideoResourcesVA: (params, callback) => dispatch(fetchVideoResourcesVA(params, callback)),
    fetchTestSeriesVA: (params, callback) => dispatch(fetchTestSeriesVA(params, callback)),
    fetchStudyMaterialsVA: (params, callback) => dispatch(fetchStudyMaterialsVA(params, callback)),
    storeResourceData: res => dispatch(storeResourceData(res)),
    setWhichViewAllClicked: val => dispatch(setWhichViewAllClicked(val)),
    setSelectedCourse: res => dispatch(setSelectedCourse(res)),
    storeAllStudentByCourseDetails: (key, val) => dispatch(storeAllStudentByCourseDetails(key, val)),
    getCoursesCategoriesForFee: callback => dispatch(getCoursesCategoriesForFee(callback)),
    getCoursesSubCategories: (id, callback) => dispatch(getCoursesSubCategories(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllStudentsCoursesVA)