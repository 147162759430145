import { Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Select } from "antd";
import { Checkbox } from "antd";
import { connect } from "react-redux";
import {
  clearAddUserFields,
  getStudentsclass,
  getStudentsRollNo,
  setClassId,
  setEmail,
  setName,
  setNextRollNo,
  setPhone,
  setPrivilage,
  setStatus,
  setUserName,
  storeAdduserFields,
} from "actions/userManagement";
import { CheckOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";

function AddStdTchrModal({
  role,
  isRoleModalVisible,
  dashBoardRole,
  showRoleModal,
  handleRoleOk,
  handleRoleCancel,
  setName,
  userName,
  setPhone,
  setPrivilage,
  setEmail,
  buttonLoader,
  setUserName,
  phone,
  email,
  privilages,
  getStudentsclass,
  classList,
  setClassId,
  getStudentsRollNo,
  nextRollNo,
  setNextRollNo,
  classId,
  user,
  storeAdduserFields,
  storeAddUserDetails,
  clearAddUserFields
}) {
  const [menuLoader, setMenuLoader] = useState(false);
  const [addDisabler, setAddDisabler] = useState(false);
  const [emailValidCheck, setEmailValidCheck] = useState(true);
  const [isAdminSelected, setIsAdminSelected] = useState(false);

  useEffect(() => {
    if (storeAddUserDetails?.privilage?.includes("Admin")) {
      setIsAdminSelected(true);
      if (storeAddUserDetails?.privilage?.indexOf("Planner") > -1) {
        storeAddUserDetails?.privilage?.splice(storeAddUserDetails?.privilage?.indexOf("Planner"), 1);
      }
      if (storeAddUserDetails?.privilage?.indexOf("InstituteSupport") > -1) {
        storeAddUserDetails?.privilage?.splice(storeAddUserDetails?.privilage?.indexOf("InstituteSupport"), 1);
      }
    } else {
      setIsAdminSelected(false);
    }
  }, [storeAddUserDetails?.privilage]);

  const handleSetEmail = (e) => {
    const email = e.target.value.trim();
    if (email === "") {
      setEmailValidCheck(true);
    }
    var val =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email?.match(val)) {
      setEmailValidCheck(true);
    } else {
      if (email) {
        setEmailValidCheck(false);
      }
    }
    setEmail(email == " " ? null : email);
  };
  const { Option } = Select;
  function onChange(checkedValues) {
    // console.log("checked = ", checkedValues);
    storeAdduserFields('privilage',checkedValues);
    setNextRollNo("1");
    setClassId("1");
  }

  function handleClassSelect(value) {
    // console.log(`selected ${value}`);
    setClassId(value);
    setNextRollNo(0);
    setPrivilage(["dummy"]);
    if (value !== "" && value !== undefined) {
      setAddDisabler(true);

      // getStudentsRollNo(() => setAddDisabler(false));
    }
  }

  // const handlegetClassName = () => {
  //   setMenuLoader(true)
  //   getStudentsclass(()=>setMenuLoader(false));

  // };
  // const handlegetRollNo=()=>{
  //   setNextRollNo(nextRollNo)
  // }
  // const handlegetRollNoSelect=()=>{
  // }

  useEffect(() => {
    if (dashBoardRole == "Student") {
      getStudentsclass(() => setMenuLoader(false));
    } else {
    }

    return () => {};
  }, []);

  useEffect(() => {
    clearAddUserFields('name', '')
    clearAddUserFields('email', '')
    clearAddUserFields('phoneNo', null)
    clearAddUserFields('course', '')
    clearAddUserFields('privilage', [])

    if(dashBoardRole == 'Student'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Teacher'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Data operator'){
      storeAdduserFields('role', dashBoardRole)
    }else if(dashBoardRole == 'Operation'){
      storeAdduserFields('role', dashBoardRole)
    }
  }, [])
  


  return (
    <div>
      {/* {dashBoardRole == "Student" ? : null} */}

      <Modal
        maskClosable={false}
        className="addStdTchrModal"
        title="Basic Modal"
        visible={isRoleModalVisible}
        onOk={handleRoleOk}
        okText={"ADD"}
        bodyStyle={{height: 'fit-content'}}
        onCancel={handleRoleCancel}
        confirmLoading={buttonLoader}
        centered
        okButtonProps={{
          disabled: !(
           (storeAddUserDetails?.name.trim() !== '' && storeAddUserDetails?.phoneNo !== '')
          ),
        }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            {dashBoardRole == "Student" ? (
              <div>
                <h2 style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Add a student
                </h2>
                <p style={{ color: "#636363" }}>
                  Kindly fill in the information to add a new student
                </p>
              </div>
            ) : (
              <div>
                <h2 style={{ fontWeight: "bold", marginBottom: "0" }}>
                  {dashBoardRole !== "Operation" ? `Add a ${dashBoardRole?.toLowerCase()}` : 
                    "Add an operations personnel"
                  }
                </h2>
                <p style={{ color: "#636363" }}>
                  Kindly fill in the information to add a new{" "}
                  {dashBoardRole?.toLowerCase()}
                </p>
              </div>
            )}
          </div>
          <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
            Name<span style={{ color: "red" }}> *</span>
          </p>
          <Input
            style={{
              marginBottom: "20px",
              border: "#E6E6E6 solid 1px",
              borderRadius: "5px",
            }}
            placeholder="Enter name"
            type="text"
              onChange={(e)=>{storeAdduserFields('name', e.target.value.trim())}}
          />
          <div className="supportPhone" style={{ marginBottom: "20px" }}>
            <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
              Phone Number<span style={{ color: "red" }}> *</span>{" "}
            </p>          

            <PhoneInput
              style={{
                width: "100%",
                border: "1px solid #d9d9d9",
                paddingLeft: 5,
                backgroundColor: "transparent",
              }}
              defaultCountry="IN"
              placeholder="Enter phone number"
              value={phone}
              height={"40px"}
              onCountryChange={(e) => console.log("code", e)}
              onChange={(e) => {storeAdduserFields('phoneNo', e)}}
              maxLength={(phone?.startsWith('+91') || phone?.startsWith('91')) && phone?.length == 13 ? 11 : ""}
            />
          </div>
          <p style={{ marginBottom: "5px", fontWeight: "bold" }}>Email ID</p>
          {!emailValidCheck ? (
            <p
              style={{
                color: "red",
                marginLeft: "15%",
                marginTop: "-27px",
                marginBottom: "5px",
              }}
            >
              Enter A Valid Email ID{" "}
            </p>
          ) : null}
          <Input
            onChange={(e) => {storeAdduserFields('email', e.target.value.trim())}}
            style={{
              border: "#E6E6E6 solid 1px",
              borderRadius: "5px",
              marginBottom: "20px",
            }}
            placeholder="Enter email ID"
            // type="email"
          />

          {dashBoardRole == "Student" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {classList?.response?.items?.length != 0 ? (
                <div>
                  <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
                    Course
                  </p>
                  <Select
                    placeholder="Select a Course"
                    style={{ width: 450 }}
                    onChange={(value)=>{storeAdduserFields('course', value)}}
                    loading={menuLoader}
                    // allowClear={classId ? true : false}
                    // value={classId}
                    // defaultValue={"Select a class"}
                  >
                    {classList?.response?.items?.length != 0 &&
                      classList?.response?.items?.map(
                        (item, index) => (
                          <Option key={index} value={item.id}>
                            {item?.courseName}
                          </Option>
                        )
                      )}
                  </Select>
                </div>
              ) : null}
              <div>
              </div>
            </div>
          ) : (
            <div>
              <p style={{ fontWeight: "bold" }}>Privileges</p>

              <Checkbox.Group
                style={{ width: "100%", fontFamily: "Roboto" }}
                onChange={onChange}
                value={storeAddUserDetails?.privilage}
              >
                <Row>
                  <Col style={{ marginBottom: "20px" }} span={8}>
                    <Checkbox
                      disabled={
                        user?.privilage?.includes("Admin") &&
                        !user?.privilage?.includes("SuperAdmin")
                          ? true
                          : false
                      }
                      value="Admin"
                    >
                      Admin
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                  {isAdminSelected?
                        <div style={{marginTop:3}}>
                        <div style={{height:17,width:17,border:"1px solid #E6E6E6",display:"flex",alignItems:"center",cursor:"not-allowed",backgroundColor:"#E6E6E6"}}>
                         <CheckOutlined style={{color:"white"}}/>
                         <div style={{padding:5,color:"#E6E6E6",fontSize:14,marginLeft:5}}>Planner</div>
                         
                        </div>
                        </div>:
                          <Checkbox disabled={isAdminSelected} value="Planner">
                          Planner
                        </Checkbox>

                        }
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      disabled={
                        user?.privilage?.includes("Admin") &&
                        !user?.privilage?.includes("SuperAdmin")
                          ? true
                          : false
                      }
                      value="FeeManager"
                    >
                      Fee manager
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    {isAdminSelected?
                        <div style={{marginTop:3}}>
                        <div style={{height:17,width:17,border:"1px solid #E6E6E6",display:"flex",alignItems:"center",cursor:"not-allowed",backgroundColor:"#E6E6E6"}}>
                         <CheckOutlined style={{color:"white"}}/>
                         <div style={{padding:5,color:"#E6E6E6",fontSize:14,marginLeft:5}}>Institute support</div>
                         
                        </div>
                        </div>:
                          <Checkbox
                          disabled={
                            // (user?.privilage?.includes("Admin") &&
                            // !user?.privilage?.includes("SuperAdmin")
                            //   ? true
                            //   : false) ||
                            isAdminSelected
                          }
                          value="InstituteSupport"
                        >
                          Institute support
                        </Checkbox>

                        }
                  </Col>
                  <Col
                    style={{
                      display: dashBoardRole == "Teacher" ? "none" : "view",
                    }}
                    span={8}
                  >
                    <Checkbox
                      // disabled={isAdminSelected}
                      value="AttendanceMarker"
                    >
                      Attendance marker
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                        <Checkbox
                          disabled={(user?.privilage?.includes("Admin") ? true : false)}
                          // ||
                          // isAdminSelected
                          value="LeaveManager"
                        >
                          Leave Manager 
                        </Checkbox>
                      </Col>
                </Row>
              </Checkbox.Group>
              <br />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

// export default AddStdTchrModal

const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    classId,
    userName,
    nextRollNo,
    classList,
    privilages,
    phone,
    email,
    storeAddUserDetails
  } = state.userManagement;
  const { user } = state.session;
  return {
    tab,
    dashBoardRole,
    userName,
    privilages,
    phone,
    email,
    classList,
    nextRollNo,
    classId,
    user,
    storeAddUserDetails
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setTab: (m) => dispatch(setTab(m))
  setStatus: (m) => dispatch(setStatus(m)),
  setName: (name) => dispatch(setName(name)),
  setPhone: (phone) => dispatch(setPhone(phone)),
  setEmail: (email) => dispatch(setEmail(email)),
  setPrivilage: (privilageData) => dispatch(setPrivilage(privilageData)),
  setUserName: (name) => dispatch(setUserName(name)),
  getStudentsclass: (callback) => dispatch(getStudentsclass(callback)),
  setClassId: (id) => dispatch(setClassId(id)),
  getStudentsRollNo: (callback) => dispatch(getStudentsRollNo(callback)),
  setNextRollNo: (rollNo) => dispatch(setNextRollNo(rollNo)),
  storeAdduserFields:(key, val) => dispatch(storeAdduserFields(key, val)),
  clearAddUserFields: (key,val) => dispatch(clearAddUserFields(key,val))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStdTchrModal);
