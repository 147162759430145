import { setNotificationVisible } from 'actions/layout';
import { Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
const infoImage = require("Assets/ill-information.svg").default;
const successImage = require("Assets/user/ill-success.svg").default;
const errorImage = require('Assets/ill-error.png').default;

const ResponseModal = ({ notificationTitle, notificationMessage, notificationVisible, setNotificationVisible, notificationType }) => {
    return (
        <Modal
            visible={notificationVisible}
            className="modal-round-corner"
            footer={false}
            style={{ width: 400 }}
            onCancel={() => setNotificationVisible(false, "", "", "")}
        >
            <div className='p-30'>
                <div className='r-c-c m-t-20'>
                    <img src={notificationType === 'success' ? successImage : notificationType === 'error' ? errorImage : infoImage} alt="" style={{ height: 180, width: 180 }} />
                </div>
                <div className='r-c-c m-t-20' style={{ fontSize: 18, fontWeight: 500 }}>{notificationTitle}</div>
                <div className='r-c-c m-t-20' style={{ height: "fit-content", overflowY: "auto" }}>
                    <center>
                        {notificationMessage?.split('\n').map(e => <div>{e}</div>)}
                    </center>
                </div>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    const { notificationTitle, notificationMessage, notificationVisible, notificationType } = state.layout;
    return {
        notificationTitle, notificationMessage, notificationVisible, notificationType
    }
}

const mapDispatchToProps = (dispatch) => ({
    setNotificationVisible: (visible, responseType, title, message) => dispatch(setNotificationVisible(visible, responseType, title, message))
})


export default connect(mapStateToProps, mapDispatchToProps)(ResponseModal);