import {
  SET_COURSE_NAME,
  SET_COURSE_PRICE,
  SET_COURSE_DURATION,
  SET_COURSE_REF,
  SET_COURSE_BILLING_PLAN,
  SET_INSTALLMENTS,
  SET_SEARCH_COURSE,
  SET_ALL_COURSES,
  SET_SPECIFIC_COURSE_DETAILS,
  STORE_PAGE_NUMBER,
  SET_IS_FROM_DETAILS_PAGE,
  STORE_SELECTED_COURSE_DATA,
  STORE_SELECTED_COURSE_ID
} from "../actions/course";

const initialState = {
  courseName: null,
  coursePrice: null,
  courseDuration: null,
  courseRef: null,
  courseBillingPlan: null,
  installments: [],
  search: null,
  courses: {},
  specificCourseDetails: null,
  pageNumber: null,
  selectedCourseId: null,
  isFromDetailsPage: false,
  selectedCourseDataContainer: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIFIC_COURSE_DETAILS:
      return {
        ...state,
        specificCourseDetails: action.payload,
      };
    case SET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case SET_SEARCH_COURSE:
      return {
        ...state,
        search: action.payload,
      };
    case SET_COURSE_NAME:
      return {
        ...state,
        courseName: action.payload,
      };
    case SET_INSTALLMENTS:
      return {
        ...state,
        installments: action.payload,
      };
    case SET_COURSE_PRICE:
      return {
        ...state,
        coursePrice: action.payload,
      };
    case SET_COURSE_DURATION:
      return {
        ...state,
        courseDuration: action.payload,
      };
    case SET_COURSE_REF:
      return {
        ...state,
        courseRef: action.payload,
      };
    case SET_COURSE_BILLING_PLAN:
      return {
        ...state,
        courseBillingPlan: action.payload,
      };
    case STORE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.val
      }
    case SET_IS_FROM_DETAILS_PAGE:
      return {
        ...state,
        isFromDetailsPage: action.val
      }
    case STORE_SELECTED_COURSE_DATA:
      return {
        ...state,
        selectedCourseDataContainer: action.val
      }
    case STORE_SELECTED_COURSE_ID:
      return {
        ...state,
        selectedCourseId: action.val
      }
    default:
      return state;
  }
};

export default reducer;