import React from "react";
import Slider from "antd/lib/slider";
import Divider from "antd/lib/divider";
import Empty from "antd/lib/empty";
import Modal from "antd/lib/modal";
import "antd/lib/slider/style/index";
import "antd/lib/divider/style/index";
import { Color } from "services";
import {
  findBySubject,
  getStudentAssignmentDetail,
  getStudentAssignment
} from "actions/assignment";
import { AssignmentTopBar, AssignmentCard } from "components";
import { SyncOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./styles.css";
import "../../design/layout.css";
import "../../design/assignment.css";

class FilterPage extends React.Component {
  state = {
    selectedSubject: false,
    dueDate: 0
  };

  async resetAll() {
    await this.setState({
      selectedSubject: false,
      dueDate: 0
    });
    const { getStudentAssignment } = this.props;
    getStudentAssignment(() => {});
  }

  async handleSliderChange(e) {
    await this.setState({ dueDate: e / 10 });
    const { dueDate, selectedSubject } = this.state;
    const { findBySubject } = this.props;
    findBySubject(selectedSubject, dueDate);
  }

  render() {
    const {
      history,
      assignmentLoader,
      findBySubject,
      subjectListStudent,
      studentAssignmentList,
      getStudentAssignmentDetail
    } = this.props;
    const { selectedSubject, dueDate } = this.state;
    const marks = {
      0: {
        style: {
          color: dueDate == 0 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 0 ? "1.4em" : "1em"
        },
        label: 0
      },
      10: {
        style: {
          color: dueDate == 1 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 1 ? "1.4em" : "1em"
        },
        label: 1
      },
      20: {
        style: {
          color: dueDate == 2 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 2 ? "1.4em" : "1em"
        },
        label: 2
      },
      30: {
        style: {
          color: dueDate == 3 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 3 ? "1.4em" : "1em"
        },
        label: 3
      },
      40: {
        style: {
          color: dueDate == 4 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 4 ? "1.4em" : "1em"
        },
        label: 4
      },
      50: {
        style: {
          color: dueDate == 5 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 5 ? "1.4em" : "1em"
        },
        label: 5
      },
      60: {
        style: {
          color: dueDate == 6 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 6 ? "1.4em" : "1em"
        },
        label: 6
      },
      70: {
        style: {
          color: dueDate == 7 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 7 ? "1.4em" : "1em"
        },
        label: 7
      },
      80: {
        style: {
          color: dueDate == 8 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 8 ? "1.4em" : "1em"
        },
        label: 8
      },
      90: {
        style: {
          color: dueDate == 9 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 9 ? "1.4em" : "1em"
        },
        label: 9
      },
      100: {
        style: {
          color: dueDate == 10 ? Color.dodgeBlue : Color.aeGrey,
          fontSize: dueDate == 10 ? "1.4em" : "1em"
        },
        label: 10
      }
    };
    return (
      <div className="NutinFont">
        <AssignmentTopBar
          backPress={() => history.push("/web-assignment")}
          title="Filters"
          listMode={false}
          lastItem={
            <div className="red text-xs" onClick={() => this.resetAll()}>
              Reset
            </div>
          }
        />
        <div className="p-t-10 p-l-10 p-r-10">
          <div className="color-black text-md m-10 bold-600">Subject</div>
          {subjectListStudent.map((data, index) => (
            <div
              onClick={() => {
                this.setState({ selectedSubject: data.id });
                findBySubject(data.id, dueDate);
              }}
              className="inline-flex optionsStyle m-l-5 m-r-5 m-t-10 p-t-5 p-b-5 p-l-10 p-r-10"
              style={{
                backgroundColor:
                  selectedSubject == data.id ? "#1089FF" : "white",
                color: selectedSubject == data.id ? "white" : "#191919",
                border:
                  selectedSubject == data.id
                    ? "1px solid white"
                    : "1px solid #AEAEAE"
              }}
            >
              {data.name}
            </div>
          ))}
          <Divider />
        </div>
        <div className="m-b-10 m-r-10 m-l-10">
          <div className="color-black text-md m-10 bold-600">Due Dates</div>
          <h5 className="lowgrey m-10">Number of days</h5>
          <Slider
            value={dueDate * 10}
            getTooltipPopupContainer
            tooltipVisible={false}
            onChange={e => this.handleSliderChange(e)}
            className="filterSlider"
            step={10}
            marks={marks}
          />
        </div>
        <div className="p-10">
          {studentAssignmentList.length ? (
            studentAssignmentList.map((data, index) => (
              <AssignmentCard
                subject={data.sub}
                topic={data.title}
                status={data.remainder}
                dueDate={data.deadlineDate}
                onPress={() =>
                  getStudentAssignmentDetail(data.id, data, history)
                }
                completion={data.attemptedQuestions / data.totalQuestions}
              />
            ))
          ) : (
            <Empty
              description={
                <div className="NutinFont bold-700 text-xmd">
                  No Assignments Found!
                </div>
              }
            />
          )}
        </div>
        <Modal
          visible={assignmentLoader}
          footer={null}
          centered
          closable={false}
          className="loadingModal"
        >
          <div className="r-c-c-c">
            <img src={require('Assets/dots-gif.gif').default} style={{maxWidth: "100%"}} width={150} alt=""/>
            {/* <SyncOutlined spin style={{ fontSize: 30, color: Color.primary }} />
            <div className="text-xmd m-t-10">Filtering assignment ...</div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    subjectListStudent,
    studentAssignmentList,
    assignmentLoader
  } = state.assignment;
  return {
    studentAssignmentList,
    subjectListStudent,
    assignmentLoader
  };
};

const mapDispatchToProps = dispatch => ({
  getStudentAssignment: (callback) => dispatch(getStudentAssignment(callback)),
  findBySubject: (id, date) => dispatch(findBySubject(id, date)),
  getStudentAssignmentDetail: (id, data, history) =>
    dispatch(getStudentAssignmentDetail(id, data, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPage);
