import React from 'react';
import "./courseRating.css";
import starIcon from "../../../Assets/aulasMarket/ic-star-fill.svg"
import profile from "../../../Assets/aulasMarket/courseImage.png"
import RatingStars from '../ratingStars';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from 'services';
function CourseRatings({ singleCourseDetailsDataContainer, location }) {
    const history = useHistory();
    const itemsToShow = 6;
    console.log("ratingggg", singleCourseDetailsDataContainer)

    const DateDisplay = ({ date }) => {
        const backendDate = new Date(date);
        const timeDifference = Date.now() - backendDate.getTime();
        const isFutureDate = timeDifference < 0;

        if (isFutureDate) {
            return <div>In the future</div>;
        }

        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        const hoursDifference = Math.floor(timeDifference / (1000 * 3600));
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        if (daysDifference > 0) {
            return <div>{daysDifference} {daysDifference === 1 ? 'day' : 'days'} ago</div>;
        } else if (hoursDifference > 0) {
            return <div>{hoursDifference} {hoursDifference === 1 ? 'hour' : 'hours'} ago</div>;
        } else if (minutesDifference > 0) {
            return <div>{minutesDifference} {minutesDifference === 1 ? 'minute' : 'minutes'} ago</div>;
        } else if (secondsDifference > 0) {
            return <div>{secondsDifference} {secondsDifference === 1 ? 'second' : 'seconds'} ago</div>;
        } else {
            return <div>Now</div>;
        }
    };

    return (
        <div className='course-rating-parent'>
            {singleCourseDetailsDataContainer?.ratingValue != null || singleCourseDetailsDataContainer?.testSeriesDetails?.averageRating !== null || singleCourseDetailsDataContainer?.studyMaterial?.ratingValue != null ?
                <div className="rating-header">
                    <img src={starIcon} /><span style={{ marginLeft: 5 }}>{singleCourseDetailsDataContainer?.ratingValue || singleCourseDetailsDataContainer?.testSeriesDetails?.averageRating || singleCourseDetailsDataContainer?.studyMaterial?.ratingValue} course rating <span className='rating-list-dot'></span>{singleCourseDetailsDataContainer?.ratingCount || singleCourseDetailsDataContainer?.testSeriesDetails?.totalReviews} ratings </span>
                </div>
                : null}
            {((location?.state?.resourceType == "course" || (location?.state?.courseReviews && location?.state?.type == 'course'))) ?
                (<>
                    <div className='reviewers-parent'>
                        {singleCourseDetailsDataContainer?.courseReviews?.slice(0, itemsToShow).map((item, index) => (
                            <div key={index} className='reviewer-item'>
                                <div className='review-child'>
                                    <div className='user-profile-parent'>
                                        <img className='user-profile' src={`${Api._s3_url}${item?.image}`} alt={"user"} />
                                    </div>
                                    <div className='m-l-20'>
                                        <div className='rating-name'>{item?.name}</div>
                                        <div className='r-c-fs'>
                                            <span><RatingStars star={item?.ratingValue} /></span>
                                            
                                            <span className='rating-durations'><DateDisplay date={item?.createdAt} /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='rating-user-review'>{item?.ratingExplanation}</div>
                            </div>
                        ))}
                    </div>
                </>) :
                (location?.state?.resourceType == "testSeries" || (location?.state?.courseReviews && location?.state?.type == 'testSeries')) ?
                    (<>
                        <div className='reviewers-parent'>
                            {singleCourseDetailsDataContainer?.latestReviews?.slice(0, itemsToShow).map((item, index) => (
                                <div key={index} className='reviewer-item'>
                                    <div className='review-child'>
                                        <div className='user-profile-parent'>
                                            <img className='user-profile' src={`${Api._s3_url}${item?.user?.image}`} alt={"user"} />
                                        </div>
                                        <div className='m-l-20'>
                                            <div className='rating-name'>{item?.user?.name}</div>
                                            <div className='r-c-fs'>
                                                <span><RatingStars star={item?.rating} /></span>

                                                <span className='rating-durations'><DateDisplay date={item?.updatedAt} /></span>
                                            </div>
                                            <div className='rating-user-review'>{item?.review}</div>
                                        </div>
                                    </div>
                                    
                                </div>
                            ))}
                        </div>
                    </>)
                    :
                    (<>
                        <div className='reviewers-parent'>
                            {singleCourseDetailsDataContainer?.reviews?.slice(0, itemsToShow).map((item, index) => (
                                <div key={index} className='reviewer-item'>
                                    <div className='review-child'>
                                        <div className='user-profile-parent'>
                                            <img className='user-profile' src={`${Api._s3_url}${item?.image}`} alt={"user"} />
                                        </div>
                                        <div className='m-l-20'>
                                            <div className='rating-name'>{item?.name}</div>
                                            <div className='r-c-fs'>
                                                <span><RatingStars star={item?.ratingValue} /></span>
                                                {console.log("item?.createdAt", item)}
                                                <span className='rating-durations'><DateDisplay date={item?.updatedAt} /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='rating-user-review'>{item?.ratingExplanation}</div>
                                </div>
                            ))}
                        </div>
                    </>)}

            {(location?.state?.resourceType !== "testSeries" && location?.state?.type != 'testSeries') ?
                (singleCourseDetailsDataContainer?.ratingCount > 6 ?
                    <div className='rel-show-more'>
                        <div onClick={() => history.push("/resource-details/reviews")}>Show more</div>
                    </div>
                    : null) :
                (singleCourseDetailsDataContainer?.testSeriesDetails?.totalReviews > 6 ?
                    <div className='rel-show-more'>
                        <div onClick={() => history.push("/resource-details/reviews")}>Show more</div>
                    </div>
                    : null)

            }

        </div>
    )
}

export default CourseRatings
