import React from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import { Api } from "services";
import {CloseOutlined } from '@ant-design/icons';
import { imageZoomModalFun } from "actions/mobileTest";
import "antd/lib/modal/style/index";

class ImageZoomModal extends React.Component{
  render(){
    const {imageZoomModalBool, QnOimg, testUploadedBase64Arr, user, imageZoomModalIndex} = this.props;

    return(
      <Modal
        className="imageModal NutinFont"
        centered
        visible={imageZoomModalBool}
        bodyStyle={{
          padding: 0,
          borderRadius: 15,
          textAlign: "center",
          backgroundColor: "transparent"
        }}
        closable={false}
        style={{ maxWidth: "calc(100vw - 16px)" }}
        footer={null}
      >
        <CloseOutlined
          onClick={() => this.props.imageZoomModalFun(false, false)}
          className="absolute top0 right0 text-sm"
          style={{color:'#fff'}}
        />
        {QnOimg === ''
          ?
            (testUploadedBase64Arr.length
              ?
                testUploadedBase64Arr.map((data,index) => (
                  index === imageZoomModalIndex
                  ?
                    <img
                      src={data.apiImg !== ''
                        ? `https://s3.ap-south-1.amazonaws.com/prod.files.classroom.digital-0949/${data.apiImg}`
                        : data.localImg} style={{width:'80vw',}}
                      alt="questionImage"
                      className='p-10'/>
                  :
                     null
                ))
              :
                null)
          :
            <img
              src={QnOimg} style={{width:'80vw',}}
              alt="questionImage"
              className='p-10'/>
        }
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { imageZoomModalBool, testUploadedBase64Arr, imageZoomModalIndex } = state.mobileTest;
  const { user } = state.session;
  return {
    imageZoomModalBool, testUploadedBase64Arr, user, imageZoomModalIndex
  };
};

const mapDispatchToProps = dispatch => ({
  imageZoomModalFun: (bool, index) => dispatch(imageZoomModalFun(bool, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageZoomModal);
