import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons'
import { Modal, Progress, Select } from 'antd'
import { Button, PageHeader, Typography, Table, Tooltip, Row, Col, Avatar, DatePicker, Input } from 'antd'

import SearchBox from 'pages/Test/localComponent/searchBox'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
const checkIcon = require("../../Assets/ic-check.svg").default
const closeIcon = require("../../Assets/ic-check.svg").default
import { attendanceByRole, getWebClockIn, storeUserDetails } from 'actions/attendanceNew'
import { setSpecificUserId, setUserDetails } from 'actions/userManagementV2';
import { Api } from 'services'
import moment from 'moment'
import PageDataLoader from 'components/pageDataLoader'
import "./style.css"
import { NodataCard } from 'pages/Test';
import UserLocationDetailsModal from "./Modals/userLocationDetailsModal"
import BackToTop from 'components/backToTop'
import { InputSearch } from 'components/customInputs'
import SettingModal from './Modals/settingModal'
import { setOthersSelectedTab } from 'actions/profileNew'
const { Search } = Input;

const Header = ({ usersNameContainer, attendanceByRole, setPageLoader }) => {
    const [month, setMonth] = useState(moment().format('YYYY-MM'))
    const [name, setName] = useState('')
    const history = useHistory();

    useEffect(() => {
        setPageLoader(true)
        attendanceByRole(() => { setPageLoader(false) }, month, name, month)
    }, [month])

    const disabledDate = current => {
        // Disable years before 2022 1st January
        if (current && current < moment("2022-01-01", "YYYY-MM-DD")) {
            return true;
        }
        // Disable months after current month in current year
        if (current && current > moment().endOf('month')) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ color: "#191919", fontWeight: 700, fontSize: "20px", display: "flex", flexDirection: 'row' }}>
                    <div className='p-5' onClick={history.goBack} style={{ cursor: "pointer" }}><ArrowLeftOutlined /></div>
                    <div style={{ fontSize: "24px", marginLeft: "15px" }}> {
                        usersNameContainer === "Teacher" ? "All teachers" :
                            usersNameContainer === "Operation" ? "All operations" :
                                usersNameContainer === "Data Operator" ? "All data operators" :
                                    usersNameContainer === "Student" ? "All Students" :
                                        null}</div>
                </div>
                <div className='display-flex' style={{ justifyContent: 'space-between', width: '45%' }}>
                    <DatePicker
                        allowClear={false}
                        picker={'month'}
                        disabledDate={disabledDate}
                        onChange={(date) => setMonth(moment(date).format('YYYY-MM'))}
                        style={{ height: 30 }}
                        format={"MMM-YY"}
                        value={moment(month)}
                    />

                    <InputSearch
                        className="p-5"
                        placeholder="Search by name"
                        onInputChange={(e) => {
                            setName(e)
                            setPageLoader(true);
                            attendanceByRole((e) => { setPageLoader(false) }, month, e)
                        }}
                        onClear={(e) => {
                            setName('');
                            setPageLoader(true);
                            attendanceByRole(() => { setPageLoader(false) }, month, e)
                        }}
                        style={{ width: 300 }}
                        allowClear
                    />
                </div>
            </div>
        </>
    )
}

// } 

const AllOperations = ({ attendanceTable, attendanceByRole, usersNameContainer, setSpecificUserId, storeUserDetails, locationDetailsContainer, getWebClockIn, setUserDetails, setOthersSelectedTab }) => {
    const history = useHistory();
    const [pageLoader, setPageLoader] = useState(false);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [month, setMonth] = useState(moment().format('YYYY-MM'))
    const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
    const [storeUserId, setStoreId] = useState('')
    let fromUserManagement = true


    useEffect(() => {
        setPageLoader(true)
        attendanceByRole(() => { setPageLoader(false) }, month, name, month)
    }, [])

    const handleClickUser = (user) => {
        const modifiedUserObj = {
            ...user,
            id: user.userId,
        }
        setSpecificUserId(user?.userId)
        setUserDetails(modifiedUserObj)
        setOthersSelectedTab('Basic Profile');
        history.push({
            pathname: '/user-management/profile',
            state: {
                key: fromUserManagement
            }
        })
    }

    const durationInHrs = Math.floor(attendanceTable?.attendance?.daywiseAttendance?.duration / 60)
    return (
        <>
            <div style={{ width: "100%" }}>
                <Header usersNameContainer={usersNameContainer} attendanceByRole={attendanceByRole} setPageLoader={setPageLoader} />
            </div>

            {attendanceTable?.attendance != 0 ?

                <div className='m-t-30' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        {/* <PageHeader
                        title={<div className="text-md" style={{ fontWeight: "bold", fontSize: "8px !important" }}>All roleType</div>}
                        onBack={() => handleBack()}
                    /> */}
                        <div style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <div>
                            </div>
                            <div>
                                {/* <Input.Search allowClear placeholder='Search by name' style={{ width: '190px' }} onSearch={handleSearch} onChange={handleChange}></Input.Search> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', border: '1px solid #E6E6E6' }}>
                        <div style={{ width: '100%', backgroundColor: '#F6F4FE' }}>
                            <Row className='cursor-pointer' style={{ width: '100%' }}>
                                <Col style={{ width: '5%' }} onClick={() => { null/*history.push("/user-management/students/details")*/ }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold", color: "#636363" }}>
                                            SL NO.
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', }}>
                                            {attendanceTable?.attendance?.map((item, index) => {

                                                return (
                                                    <div style={{ borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA', fontSize: "15px", fontWeight: 650 }}>
                                                        {index + 1}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                </Col>
                                <Col style={{ width: '25%' }}/*  onClick={()=>{history.push("/user-management/students/details")}} */>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: "20px", fontWeight: "bold", color: "#636363" }}>
                                            Name
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', backgroundColor: '#FFFFFF' }}>

                                            {
                                                attendanceTable?.attendance?.map((item, index) => (
                                                    <div className='cursor-pointer' style={{ borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
                                                        onClick={() => handleClickUser(item?.userDetails)}>
                                                        <Avatar src={`${Api._s3_url}${item?.userDetails?.image}`} style={{ marginLeft: "15px", marginRight: '10px' }} />
                                                        <div style={{ color: `${item?.userDetails?.presentToday ? "#28DF99" : "#FF414D"}`, fontSize: "18px", marginTop: "-24px", marginLeft: "-14px" }}>●</div>
                                                        <div style={{ display: "flex", justifyContent: 'space-between', width: '70%' }}>
                                                            <div className="text-overflow-ellipsis" style={{ fontSize: "15px", fontWeight: 650, color: "#191919", marginLeft: "5px" }}>{item?.userDetails?.name}</div>
                                                            <div onClick={(e) => {
                                                                getWebClockIn(item?.userDetails?.userId);
                                                                e.stopPropagation();
                                                                setStoreId(item?.userDetails?.userId)
                                                                setIsSettingModalOpen(true)
                                                            }}><SettingOutlined style={{ fontSize: "20px", }} /></div>
                                                        </div>
                                                    </div>

                                                ))

                                            }
                                        </div>

                                    </div>

                                </Col>

                                <Col style={{ width: '20%' }} /* onClick={()=>{history.push("/user-management/students/details")}} */>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold", color: "#636363" }}>
                                            Overall Percentage
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', }}>
                                            {attendanceTable?.attendance?.map((item, index) => (
                                                <div onClick={() => handleClickUser(item?.userDetails)} style={{ width: '100%', borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}>
                                                    <Progress strokeColor="#594099" type='line' percent={item?.overallAttendance ? item?.overallAttendance : 0} style={{ width: '180px', fontWeight: 650, color: "#636363" }}></Progress>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col style={{ width: '50%', overflowX: 'scroll' }}>
                                    {attendanceTable?.attendance?.map((item, index1) =>
                                        <div style={{ display: 'flex', flexDirection: 'column' }} /* onClick={()=>{setIsDetailsModalVisible(true)}} */>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                {item?.daywiseAttendance?.map((item, index) => (
                                                    <>
                                                        <div style={{ display: 'flex', flexDirection: 'column', }} onClick={
                                                            () => {
                                                                if (item?.attendanceStatus === 'Present' || item?.attendanceStatus === 'HalfPresent' || item?.attendanceStatus === 'HalfPresentLeave' || item?.attendanceStatus === 'HalfLeave' || item?.manual === 1) {
                                                                    setIsDetailsModalVisible(true);
                                                                    storeUserDetails(item);
                                                                } else {
                                                                    setIsDetailsModalVisible(false);
                                                                }
                                                            }}>
                                                            {index1 == 0 ?
                                                                <div className='text-heading' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '10px', width: '80px', height: '40px', fontWeight: "bold", color: "#636363" }}>
                                                                    <div>{moment(item?.date, 'YYYY-MM-DD').format('DD')}</div>
                                                                    <div>{item?.day} </div>
                                                                </div>
                                                                : null}
                                                            <div className={item.attendanceStatus === 'Absent' ? "close" :
                                                                item.attendanceStatus === 'Present' ? "check" :
                                                                    item.attendanceStatus === 'Leave' ? "leave" :
                                                                        item.attendanceStatus === 'Holiday' ? "" :
                                                                            item.attendanceStatus === 'Offday' ? "" :
                                                                                item.attendanceStatus === 'NA' ? "NA" :
                                                                                    "check"}
                                                                style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', backgroundColor: '#FFFFFF', width: '80px', height: '60px', borderLeft: '1px solid #E6E6E6' }}>

                                                                <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                    {/* <Tooltip placement='right'
                                                                title={
                                                                    <div style={{ width: '220px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <div style={{ fontSize: '11px' }}>CLOCK IN</div>
                                                                            <div className='text-heading' style={{ fontSize: '14px' }}>{item?.clockIn===null?"0:00":item?.clockIn}</div>
                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <div style={{ fontSize: '11px' }}>CLOCK OUT</div>
                                                                            <div className='text-heading' style={{ fontSize: '14px' }}>{item?.clockOut===null ?"0:00":item?.clockOut }</div>
                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <div style={{ fontSize: '11px' }}>WORKING HRS</div>
                                                                            <div className='text-heading' style={{ fontSize: '14px' }}>{item?.duration===null?"0:00":
                                                                            <div>{parseInt(item?.duration/60)}:{parseInt(item?.duration%60)}</div>}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                overlayInnerStyle={{ borderRadius: '8px', boxShadow: '4px 4px 18px #302A5F14', backgroundColor: '#343636 ' }}
                                                                // onVisibleChange={(visible) => setHover(visible)}
                                                            > */}
                                                                    {item?.attendanceStatus === "Absent" ?
                                                                        <CloseOutlined style={{ color: '#FF414D' }} /> :
                                                                        item?.attendanceStatus === "Present" ?
                                                                            <>
                                                                                {
                                                                                    item?.manual === 1 ? <div style={{ color: "#28DF99", fontSize: "15px" }}>M</div> : <CheckOutlined style={{ color: '#28DF99' }} />

                                                                                }
                                                                            </> :
                                                                            item?.attendanceStatus === "Leave" ?
                                                                                <div style={{ color: "#1089FF", fontSize: "15px" }}>L</div> :
                                                                                item?.attendanceStatus === "Holiday" ?
                                                                                    <div style={{ color: "#636363", fontSize: "15px" }}>H</div> :
                                                                                    item?.attendanceStatus === "Offday" ?
                                                                                        <div style={{ color: "#AEAEAE", fontSize: "15px" }}>WO</div> :
                                                                                        item?.attendanceStatus === "NA" ?
                                                                                            <div style={{ color: "#636363", fontSize: "15px" }}>NA</div> :
                                                                                            item?.attendanceStatus === 'HalfPresent' || item?.attendanceStatus === 'HalfPresentLeave' || item?.attendanceStatus === 'HalfLeave' ?
                                                                                                <div style={{ color: "#FFA931", fontSize: "15px" }}>HD</div> :
                                                                                                <></>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>

                                                ))}
                                            </div>

                                        </div>
                                    )}
                                    {isDetailsModalVisible &&
                                        <UserLocationDetailsModal
                                            isDetailsModalVisible={isDetailsModalVisible}
                                            setIsDetailsModalVisible={setIsDetailsModalVisible}
                                            locationDetailsContainer={locationDetailsContainer}
                                        />}

                                </Col>
                            </Row>

                        </div>

                    </div>
                </div>
                :
                <div style={{
                    width: "100%",
                    height: "100%",
                    background: "#FFFFFF",
                    border: "1px solid #E6E6E6",
                    borderRadius: "6px",
                    marginTop: "30px",
                    paddingBottom: "30px"
                }}>
                    <NodataCard />
                </div>
            }

            <PageDataLoader visible={pageLoader} />

            <BackToTop />

            {isSettingModalOpen &&
                <SettingModal
                    isSettingModalOpen={isSettingModalOpen}
                    setIsSettingModalOpen={setIsSettingModalOpen}
                    storeUserId={storeUserId}
                />}
        </>
    )
}
const mapStateToProps = (state) => {
    const { attendanceTable, usersNameContainer, locationDetailsContainer } = state.attendanceNew;
    return { attendanceTable, usersNameContainer, locationDetailsContainer };
};

const mapDispatchToProps = (dispatch) => ({
    attendanceByRole: (callback, month, search) => (dispatch(attendanceByRole(callback, month, search))),
    setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
    setUserDetails: (res) => dispatch(setUserDetails(res)),
    storeUserDetails: (val) => dispatch(storeUserDetails(val)),
    getWebClockIn: (storeUserId) => dispatch(getWebClockIn(storeUserId)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllOperations);
