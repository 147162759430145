import React, { useEffect, useState } from 'react'
import { Row, Col, Tooltip, Typography } from 'antd'
import lecturenotes from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg"
import videoImg from "../../../Assets/digitalLibrary/ic-dl-lecture-videos.svg"
import questBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg"
import { connect } from 'react-redux'
import SubjectCard from '../components/subjectCard'
import LibraryCardDetails from '../components/LibraryCards_details'
import { fetchClassLibraryData, updateCurrentSub, updateCurrentStd, updateCurrentIcon, updatepdf, fetchTeacherHomeDataSummary, setMonthOrWeek, updateClassSubjectInfo, updatePageNumber, updateTotalUnusedStorageSize } from 'actions/digitalLibrary'
import { useHistory } from 'react-router-dom'
import PageDataLoader from 'components/pageDataLoader'
import { ArrowLeftOutlined, PlusCircleOutlined, SettingFilled } from '@ant-design/icons'
import { TeacherDashBoard } from 'pages'
import UploadModal from '../modals/uploadModel'
import Uploadfiles from '../modals/uploadfiles'
import Videouploadmodel from '../modals/Videouploadmodel'
import Succesfulmodel from '../modals/Succesfulmodel'
import VideoTypeModal from '../modals/videoTypeModal'
import WarningModal from '../modals/warningModal'
import image1 from '../../../Assets/care/no-subjects.svg'
import { Heading } from 'components/Typography'
import PageHeader from 'components/pageHeader'
import { Button } from 'components/Button'
import { setSubscriptionSelectedTab } from 'actions/profileNew'
import BroadcastCard from '../components/broadcastCard'
// import BroadCastRecordingsModal from '../modals/broadCastRecordingsModal'
import RecordingsModal from 'pages/broadcast/localComponent/recordingsModal'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


const formatFileSize = (sizeInBytes) => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    const sizeInGB = sizeInMB / 1024;

    if (sizeInGB >= 1) {
        return parseFloat(sizeInGB.toFixed(2)) + ' GB';
    } else if (sizeInMB >= 1) {
        return parseFloat(sizeInMB.toFixed(2)) + ' MB';
    } else {
        const sizeInKB = sizeInBytes / 1024;
        return parseFloat(sizeInKB.toFixed(2)) + ' KB';
    }
};
const ClassDetailsEmpty = ({
    fetchClassLibraryData, classLibraryList, setMonthOrWeek, classSubjectList, selectedStd, updateCurrentStd, updateCurrentIcon, arrStoreData
    , updatepdf, updateCurrentSub, getTeachersHomeDataSummary, fetchTeacherHomeDataSummary, user, classInfo, videoPremium, updateClassSubjectInfo,
    updatePageNumber, sizeOfContents, totalStorageSize, updateTotalUnusedStorageSize, setSubscriptionsSelectedTab, selectedSection
}) => {

    const [pageLoader, togglePageLoader] = useState(false)
    const [modalUpload, setModalUpload] = useState(false)
    const [uploadFileModal, toggleUploadFileModel] = useState(false)
    const [modal, setmodel] = useState(false)
    const [isVideoTypeModal, setIsVideoTypeModal] = useState(false)
    const [videoType, setVideoType] = useState('')
    const [type, setType] = useState('')
    const [isRecordingsModalVisible, SetIsRecordingsModalVisible] = useState(false)
    const location = useLocation();

    useEffect(() => {
        updatePageNumber(2)
        updateClassSubjectInfo([])
        togglePageLoader(true)
        updateCurrentSub({})
        setMonthOrWeek('week')
        if (location?.state?.classroom) {
            let data = location?.state?.classroom
            updateCurrentStd({
                std_id: data?.std_id,
                courseName: data?.courseName,
                standard: data?.standard,
                std_section: data?.std_section
            })
        }
        if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
            fetchClassLibraryData(() => togglePageLoader(false))
        }
        else {
            fetchTeacherHomeDataSummary(selectedStd.std_id, () => togglePageLoader(false))
        }
        updatepdf(null)
    }, [])
    // useEffect(() => {
    //     let temp = totalStorageSize * 1024 * 1024 * 1024 - parseFloat(sizeOfContents?.sizeOfLn) - parseFloat(sizeOfContents?.sizeOfMp4Video) - parseFloat(sizeOfContents?.sizeOfQb);
    //     updateTotalUnusedStorageSize(temp)
    // }, [totalStorageSize, sizeOfContents?.sizeOfLn, sizeOfContents?.sizeOfMp4Video, sizeOfContents?.sizeOfQb])

    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false)
    const handleStorage = () => {
        if (user?.access?.includes('subscription-settings')) {
            history.push('/digital-library/buy-storage')
        }
        else {
            setModalVisible(true)
        }
    }

    const handleSetting = () => {
        history.push({
            pathname: '/subscriptions',
        })
        setSubscriptionsSelectedTab("Storage")
    }

    const handleBackPress = () => {
        history.goBack();
        // updateCurrentStd({})
        updateCurrentIcon('')
    }

    const actions = [
        (user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
            <Button type='primary' onClick={() => { setModalUpload(true) }} disabled={classSubjectList?.length == 0 ? true : false} icon={<PlusCircleOutlined />}>UPLOAD</Button> : null,
    ]

    const handleVAPress = () => {
        history.push({
            pathname: "/digital-library/broadcast-recordings",
            state: {
                fromBrodcastVR:
                {
                    redirectingPageDL: true,
                    sections: location?.state?.fromBrodcastVR?.sections,
                }
            }
        })
    }

    const usedData = sizeOfContents?.sizeOfTotal || 0
    const storageLeft = sizeOfContents?.spaceAvailable || 0;

    const totalUsedStorageSize = sizeOfContents?.sizeOfLn + sizeOfContents?.sizeOfMp4Video + sizeOfContents?.sizeOfQb;
    const totalUnusedStorageSize = totalStorageSize - totalUsedStorageSize;

    const convertToReadableSize = (sizeInBytes) => {
        if (sizeInBytes >= 1073741824) {
            return `${(sizeInBytes / 1073741824).toFixed(2)} GB`;
        } else if (sizeInBytes >= 1048576) {
            return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
        } else if (sizeInBytes >= 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else {
            return `${sizeInBytes} bytes`;
        }
    };

    const calculateWidthPercentage = (sizeInBytes, totalSizeInBytes) => {
        return ((sizeInBytes / totalSizeInBytes) * 100).toFixed(2);
    };

    const storageItems = [
        { label: 'Notes', size: sizeOfContents?.sizeOfLn, color: '#9876E6' },
        { label: 'Videos', size: sizeOfContents?.sizeOfMp4Video, color: '#579EFF' },
        { label: 'Question Banks', size: sizeOfContents?.sizeOfQb, color: '#FF83D0' },
        { label: 'No data', size: totalUnusedStorageSize, color: '#E6E6E6' },
    ];


    return (
        <div>

            <div>
                <div className='r-c-sb'>
                    <div style={{ width: "10%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <PageHeader title={`${selectedStd?.standard} : ${selectedStd?.std_section}`} actions={actions} onBackPress={() => { handleBackPress() }} />
                    </div>
                    {user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator") ?
                        <div>
                            {(modalUpload && <UploadModal visible={modalUpload} setVisible={setModalUpload} toggleUploadFileModel={toggleUploadFileModel} setType={setType} setIsVideoTypeModal={setIsVideoTypeModal} setVideoType={setVideoType} />)}

                            {videoPremium ? (isVideoTypeModal && <VideoTypeModal visible={isVideoTypeModal} setVisible={setIsVideoTypeModal} setVideoType={setVideoType} toggleUploadFileModel={toggleUploadFileModel} />) : null}

                            {videoType == 'videoLink' ? (uploadFileModal && <Videouploadmodel type={type} classess={arrStoreData[0]} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} contentPage={false} />) : (uploadFileModal && <Uploadfiles classess={arrStoreData[0]} type={type} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} contentPage={false} location={"subject"} />)}
                        </div>
                        : null}
                </div>

                {classLibraryList?.length != 0 ? classLibraryList?.map((item, index) => (
                    <div style={{ marginTop: 70 }}>
                        {!(user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) ?
                            <Row>

                                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                                    <div className="m-r-10 m-t-10">
                                        <LibraryCardDetails title={"Notes"} filesNO={item?.notesCount ? item?.notesCount : 0} lastWeek={classInfo?.lastWeekLectureNotesCount} images={lecturenotes} />
                                    </div>

                                </Col>
                                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                                    <div className="m-r-10 m-t-10">
                                        <LibraryCardDetails title={"Videos"} filesNO={item?.videoCount ? item?.videoCount : 0} lastWeek={classInfo?.lastWeekVideoCount} images={videoImg} />
                                    </div>

                                </Col>
                                <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                                    <div className="m-r-10 m-t-10">
                                        <LibraryCardDetails title={"Test papers"} filesNO={item?.quesBankCount ? item?.quesBankCount : 0} lastWeek={classInfo?.lastWeekQuestionBankCount} images={questBankImg} />
                                    </div>

                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md={{ span: 24 }} lg={{ span: 15 }}>
                                    <div style={{ width: '100%', padding: "20px", borderRadius: '12px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '10px', height: "175px" }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                            <div className='text-heading-library'>
                                                Overall stats
                                            </div>
                                            <div style={{ width: '98%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div className="display-flex-inlibrarycard">
                                                            <div className="image">
                                                                <img className="notes-image" src={lecturenotes} />
                                                            </div>
                                                            <div className="both">
                                                                <p className="nofiles">{item?.notesCount ? item?.notesCount : 0}</p>
                                                                <p style={{ width: "100px" }} className="titlefr">Notes</p>
                                                            </div>
                                                        </div>
                                                        {classInfo?.lastWeekLectureNotesCount ?
                                                            <p style={{ marginLeft: '50px' }}><span style={{ color: classInfo?.lastWeekLectureNotesCount > 0 ? "#28DF99" : '#FF414D' }}>{classInfo?.lastWeekLectureNotesCount ? classInfo?.lastWeekLectureNotesCount : null} from last week</span></p>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div style={{ borderLeft: "1px solid #E6E6E6" }}></div>
                                                <div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div className="display-flex-inlibrarycard">
                                                            <div className="image">
                                                                <img className="notes-image" src={videoImg} />
                                                            </div>
                                                            <div className="both">
                                                                <p className="nofiles">{item?.videoCount ? item?.videoCount : 0}</p>
                                                                <p style={{ width: "100px" }} className="titlefr">Videos</p>
                                                            </div>
                                                        </div>
                                                        {classInfo?.lastWeekVideoCount ?
                                                            <p style={{ marginLeft: '50px' }}><span style={{ color: classInfo?.lastWeekVideoCount > 0 ? "#28DF99" : '#FF414D' }}>{classInfo?.lastWeekVideoCount ? classInfo?.lastWeekVideoCount : null} from last week</span></p>
                                                            : null}
                                                    </div>

                                                </div>
                                                <div style={{ borderLeft: "1px solid #E6E6E6" }}></div>

                                                <div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div className="display-flex-inlibrarycard">
                                                            <div className="image">
                                                                <img className="notes-image" src={questBankImg} />
                                                            </div>
                                                            <div className="both">
                                                                <p className="nofiles">{item?.quesBankCount ? item?.quesBankCount : 0}</p>
                                                                <p style={{ width: "100px" }} className="titlefr">Test papers</p>
                                                            </div>
                                                        </div>
                                                        {classInfo?.lastWeekQuestionBankCount ?
                                                            <p style={{ marginLeft: '50px' }}><span style={{ color: classInfo?.lastWeekQuestionBankCount > 0 ? "#28DF99" : '#FF414D' }}>{classInfo?.lastWeekQuestionBankCount ? classInfo?.lastWeekQuestionBankCount : null} from last week</span></p>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col >
                                <Col md={{ span: 24 }} lg={{ span: 9 }} >
                                    <div style={{ width: '95%', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', boxShadow: "4px 4px 18px #302A5F14", borderRadius: '12px', height: "175px", marginLeft: '20px' }}>
                                        <div style={{ width: '100%', display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography.Text className='text-heading-library'>Overview</Typography.Text>
                                            {user?.access?.includes("digital-library-superadmin") ?
                                                <div className='cursor-pointer' style={{ display: 'flex', alignItems: 'center', color: '#1089FF' }} onClick={() => handleSetting(setSubscriptionsSelectedTab)}><SettingFilled style={{ marginRight: '5px' }} /> Manage</div>
                                                : null}
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            {storageItems.map((item,index) => (
                                                <Tooltip
                                                    key={item.label}
                                                    title={
                                                        <div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '5px' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                                <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>{item.label}</Typography.Text>
                                                                {index != 3 ? <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>{convertToReadableSize(item.size)}</Typography.Text>:<></>}
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            width: `${calculateWidthPercentage(item.size, totalStorageSize)}%`,
                                                            height: '40px',
                                                            backgroundColor: item.color,
                                                        }}
                                                    ></div>
                                                </Tooltip>
                                            ))}
                                        </div>
                                        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                            <Tooltip
                                                title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Lecture notes</Typography.Text>
                                                        <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>{parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024).toFixed(2))} MB</Typography.Text>
                                                    </div>
                                                </div>}
                                            >
                                                <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#9876E6', }}></div>
                                            </Tooltip>
                                            <Tooltip
                                                title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Videos</Typography.Text>
                                                        <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>{parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024).toFixed(2))} MB</Typography.Text>
                                                    </div>
                                                </div>}
                                            >
                                                <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#579EFF' }}></div>
                                            </Tooltip>
                                            <Tooltip
                                                title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Test papers</Typography.Text>
                                                        <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>{parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024).toFixed(2))} MB</Typography.Text>
                                                    </div>
                                                </div>}
                                            >
                                                <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#FF83D0' }}></div>
                                            </Tooltip>
                                            <Tooltip
                                                title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Storage Left</Typography.Text>
                                                        <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>{parseFloat((parseFloat(totalUnusedStorageSize) / 1024 / 1024).toFixed(2))} MB</Typography.Text>
                                                    </div>
                                                </div>}
                                            >
                                                <div style={{ width: `${(parseFloat((parseFloat(totalUnusedStorageSize) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#E6E6E6' }}></div>
                                            </Tooltip>
                                        </div> */}
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <div>
                                                <div style={{ display: "flex", gap: 10 }}>
                                                    <div style={{ backgroundColor: "#9876E6", height: "12px", width: "12px" }}></div>
                                                    <div style={{ marginTop: -2, fontSize: "11px", color: "#636363" }}>Notes ({convertToReadableSize(sizeOfContents?.sizeOfLn)})</div>
                                                </div>
                                                <div style={{ display: "flex", gap: 10 }}>
                                                    <div style={{ backgroundColor: "#579EFF", height: "12px", width: "12px" }}></div>
                                                    <div style={{ marginTop: -2, fontSize: "11px", color: "#636363" }}>Videos ({convertToReadableSize(sizeOfContents?.sizeOfMp4Video)})</div>
                                                </div>
                                                <div style={{ display: "flex", gap: 10 }}>
                                                    <div style={{ backgroundColor: "#FF83D0", height: "12px", width: "12px" }}></div>
                                                    <div style={{ marginTop: -2, fontSize: "11px", color: "#636363" }}>Test papers ({convertToReadableSize(sizeOfContents?.sizeOfQb)})</div>
                                                </div>

                                            </div>
                                            <div style={{ marginTop: '10px' }}>
                                                <Button type='primary' style={{ width: '170px', border: "1px solid #1089FF", backgroundColor: "#fff" }} onClick={() => handleStorage()}><h style={{ fontSize: "14px", color: "#1089FF", fontWeight: 600 }}>GET MORE STORAGE</h></Button>
                                            </div>
                                        </div>
                                        {(modalVisible && <WarningModal isModalVisible={modalVisible} setIsModalVisible={setModalVisible} />)}
                                    </div>
                                </Col>
                            </Row>
                        }
                    </div>
                )) :
                    <Row >
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Lecture notes"} filesNO={0} images={lecturenotes} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Videos"} filesNO={0} images={videoImg} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Question Banks"} filesNO={0} images={questBankImg} />
                            </div>

                        </Col>
                    </Row>



                }
            </div>
            {/* <div style={{ marginTop: "30px" }}>
                {user.access.includes("digital-library-teacher") || user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator") ?
                    <TeacherDashBoard togglePageLoader={togglePageLoader} pageLoader={pageLoader} standardId={location?.state?.classroom?.std_id}/>
                    :
                    null
                }
            </div> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Heading className='m-t-20 m-l-10'>Live class recordings{" "}{"("}{classInfo?.allBroadcastsCount}{")"}</Heading>
                {classInfo?.allBroadcasts != 0 ? <Heading onClick={() => handleVAPress()} style={{ color: "#1089FF", fontSize: "14px", fontWeight: "bold", marginTop: "30px", cursor: 'pointer' }}>VIEW ALL</Heading> : null}

            </div>
            {classInfo?.allBroadcasts != 0 ?
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {classInfo?.allBroadcasts?.map((item) => (
                        <div  >
                            <BroadcastCard
                                subjectName={item?.subject?.name}
                                teacherName={item?.teacher?.name}
                                date={item?.date}
                                startTime={item?.startTime}
                                endTime={item?.endTime}
                                image={item?.subject?.icon}
                                streamId={item?.roomName}
                                isRecordingsModalVisible={isRecordingsModalVisible}
                                SetIsRecordingsModalVisible={SetIsRecordingsModalVisible}
                                item={item}
                            />
                        </div>
                    ))}
                </div> :
                <div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ width: "10%", height: "10%", marginBottom: "20px" }} src={image1} alt="" />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try later.</p>
                        </div>
                    </div>
                    {user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') ? (<>
                        {classSubjectList?.length != 0 ? <div style={{ display: "flex", justifyContent: "center" }}><Button onClick={() => handleVAPress()} ><div>ADD</div></Button></div> : (<></>)}
                    </>) : null}

                </div>}
            <Heading className='m-t-20 m-l-10'>Subjects ({classSubjectList?.length})</Heading>
            {classSubjectList?.length != 0 ?
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {classSubjectList?.map((item, index) => (
                        <div style={{ margin: "7px" }}>
                            <SubjectCard title={item?.subjects_name} filesNO={item?.chapterCount} images={item?.icon} index={index} classSubjectList={classSubjectList} item={item} totalsize={item?.totalSize} />
                        </div>
                    ))}
                </div>
                :
                <div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ width: "10%", height: "10%", marginBottom: "20px" }} src={image1} alt="" />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center" }}>There are no data to show yet. Please try later.</p>
                        </div>
                    </div>
                </div>}
            <Succesfulmodel togglePageLoader={togglePageLoader} fetchApi={false} videoType={videoType} />
            {/* <BroadCastRecordingsModal
            isRecordingsModalVisible={isRecordingsModalVisible}
            SetIsRecordingsModalVisible={SetIsRecordingsModalVisible}
            /> */}
            <RecordingsModal
                isRecordingsModalVisible={isRecordingsModalVisible}
                setRecordingsModalVisibility={SetIsRecordingsModalVisible}

            />
            <PageDataLoader visible={pageLoader} />

        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        selectedStd,
        arrStoreData,
        getTeachersHomeDataSummary,
        adminDashBoardData,
        classInfo,
        videoPremium,
        sizeOfContents, totalStorageSize, totalUnusedStorageSize
    } = state.digitalLibrary;
    const { selectedSection } = state.classRoom
    const { user } = state.session;
    return {
        classLibraryList,
        classSubjectList,
        selectedSubject,
        selectedStd,
        arrStoreData,
        getTeachersHomeDataSummary,
        user,
        adminDashBoardData,
        classInfo,
        videoPremium,
        sizeOfContents, totalStorageSize, totalUnusedStorageSize, selectedSection
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchClassLibraryData: (callback) => dispatch(fetchClassLibraryData(callback)),
    updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),
    updateCurrentIcon: (icon) => dispatch(updateCurrentIcon(icon)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
    fetchTeacherHomeDataSummary: (id, callback) => dispatch(fetchTeacherHomeDataSummary(id, callback)),
    setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
    updateClassSubjectInfo: (data) => dispatch(updateClassSubjectInfo(data)),
    updatePageNumber: (data) => dispatch(updatePageNumber(data)),
    updateTotalUnusedStorageSize: (val) => dispatch(updateTotalUnusedStorageSize(val)),
    setSubscriptionsSelectedTab: tab => dispatch(setSubscriptionSelectedTab(tab)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ClassDetailsEmpty);
