import { Api } from '../services';
export const CHANGE_LIBRARY_TAB = "CHANGE_LIBRARY_TAB";
export const UPDATE_LIB_FILTER_PARAMS = "UPDATE_LIB_FILTER_PARAMS";
export const TOGGLE_PDF_FORM = "TOGGLE_PDF_FORM";
export const TOGGLE_VIDEO_FORM = "TOGGLE_VIDEO_FORM";
export const TOGGLE_VIDEO_PLAYER = "TOGGLE_VIDEO_PLAYER";
export const CLEAR_LIB_FILTER_PARAMS = "CLEAR_LIB_FILTER_PARAMS";
export const UPDATE_POST_CONTENT_PARAMS = "UPDATE_POST_CONTENT_PARAMS";
export const CLEAR_POST_CONTENT_PARAMS = "CLEAR_POST_CONTENT_PARAMS";
export const UPDATE_SEARCHED_CHAPTERS_DLIBRARY = "UPDATE_SEARCHED_CHAPTERS_DLIBRARY";
export const TOGGLE_UPDATE_CONTENT_LOADER = "TOGGLE_UPDATE_CONTENT_LOADER";
export const FETCH_CONTENT_LOADER = "FETCH_CONTENT_LOADER";
export const UPDATE_LIBRARY_CONTENT = "UPDATE_LIBRARY_CONTENT";
export const SET_CURRENT_CONTENT_PARAMS = "SET_CURRENT_CONTENT_PARAMS";
export const CLEAR_CHAPTERS_LIST = "CLEAR_CHAPTERS_LIST";
export const TOGGLE_ACCESS_FORM = "TOGGLE_ACCESS_FORM";
export const TOGGLE_FETCH_TEACHERS_ACCESS_LOADER = "TOGGLE_FETCH_TEACHERS_ACCESS_LOADER";
export const UPDATE_TEACHERS_ACCESS_LIST = "UPDATE_TEACHERS_ACCESS_LIST";
export const UPDATE_VIEW_MNGMT_FILTER_PARAMS = "UPDATE_VIEW_MNGMT_FILTER_PARAMS";
export const CLEAR_VIEW_MNGMT_FILTER_PARAMS = "CLEAR_VIEW_MNGMT_FILTER_PARAMS";
export const TOGGLE_TEACHER_SEARCH_LOADER = "TOGGLE_TEACHER_SEARCH_LOADER";
export const UPDATE_TEACHER_SEARCH_LIST = "UPDATE_TEACHER_SEARCH_LIST";
export const UPDATE_ACCESS_POST_PARAMS = "UPDATE_ACCESS_POST_PARAMS";
export const TOGGLE_POST_ACCESS_LOADER = "TOGGLE_POST_ACCESS_LOADER";
export const LOAD_CURRENT_TEACHER_ACCESS_DATA = "LOAD_CURRENT_TEACHER_ACCESS_DATA";
export const CLEAR_POST_ACCESS_PARAMS = "CLEAR_POST_ACCESS_PARAMS";
export const TOGGLE_DELETE_LOADER = "TOGGLE_DELETE_LOADER";




export const deleteItem = (id) => {
  return (dispatch, getState) => {
    const url = `/digital-library/teacher-management/${id}`;
    dispatch(fetchTeachersLoader(true));
    return new Promise((resolve, reject) => {
      Api.delete(url)
        .params({id})
        .send(async (response, error) => {
            if(response && response.show.type === "success"){
              dispatch(fetchTeacherList())
            }else{
              dispatch(fetchTeachersLoader(false));
            }
          }
        )
    })
  }
}

export const  clearPostAccessParams = () => ({
  type: CLEAR_POST_ACCESS_PARAMS
})

export const loadCurrentData = (index) => ({
  type: LOAD_CURRENT_TEACHER_ACCESS_DATA,
  index
})

const togglePostAccessLoader = bool => ({
  type: TOGGLE_POST_ACCESS_LOADER,
  bool
})

export const postAccessData = () => {
  return (dispatch, getState) => {
    const {postAccessParams} = getState().digitalLibrary;
    const params = {
      id: postAccessParams.id,
      teacherId: postAccessParams.teacherId,
      standardIds: postAccessParams.standardIds,
      subjectIds: postAccessParams.subjectIds,
    }
   
    const url = `/digital-library/teacher-management`;
    dispatch(togglePostAccessLoader(true));
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(params)
        .send(async (response, error) => {
            dispatch(togglePostAccessLoader(false));
            if(response && response.show.type === "success"){
              dispatch(toggleAccessForm(false));
              dispatch(fetchTeacherList())
            }
          }
        )
    })
  }
}

export const updateAccessPostParams = (val, key) => ({
  type: UPDATE_ACCESS_POST_PARAMS,
  postParams: {[key]: val}
})

export const toggleTeacherSearchLoader = teachers => ({
  type: TOGGLE_TEACHER_SEARCH_LOADER,
  teachers
});

export const updateTeacherList = teachers => ({
  type: UPDATE_TEACHER_SEARCH_LIST,
  teachers
});

export const searchUser = query => {
  return dispatch => {
    const params = {
      name: query,
      role: "Teacher"
    };
    dispatch(toggleTeacherSearchLoader(true));
    return Api.get("/user/autocomplete")
      .params(params)
      .send((response, error) => {
        dispatch(toggleTeacherSearchLoader(true));
        if (typeof response != "undefined") {
          if (!response.length) {
            dispatch(
              updateTeacherList([{ id: -1, value: "No teachers found", disabled: true }])
            );
          } else {
            dispatch(updateTeacherList(response));
          }
        }
      });
  };
};

export const clearViewManagementFilterParams = () => ({
  type: CLEAR_VIEW_MNGMT_FILTER_PARAMS,
})

export const updateViewManagementFilterParams = (val, key) => ({
  type: UPDATE_VIEW_MNGMT_FILTER_PARAMS,
  filterParams: {[key]: val}
})

const  updateTeachersList = data => ({
  type: UPDATE_TEACHERS_ACCESS_LIST,
  data
})

const fetchTeachersLoader = bool => ({
  type: TOGGLE_FETCH_TEACHERS_ACCESS_LOADER,
  bool
})

export const fetchTeacherList = () => {
  return (dispatch, getState) => {
    const {viewMngmtFilterParams} = getState().digitalLibrary;
   
    const url = `/digital-library/teacher-management`;
    dispatch(fetchTeachersLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(viewMngmtFilterParams)
        .send(async (response, error) => {
            dispatch(fetchTeachersLoader(false))
            dispatch(updateTeachersList(response))
          }
        )
    })
  }
}


export const toggleAccessForm = bool => ({
  type: TOGGLE_ACCESS_FORM,
  bool
})

export const clearChaptersList = () => ({
  type: CLEAR_CHAPTERS_LIST
})

export const updateContentStatus = (status, id = null) => {
  return (dispatch, getState) => {
    const {postContentParams} = getState().digitalLibrary;
    const params = {
      libraryId: id ? id : postContentParams.id,
      status
    }
    const url = `/digital-library/content`;
    dispatch(fetchContentLoader(true));
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .params(params)
        .send(async (response, error) => {
          if(response?.show?.type === "success"){
            dispatch(fetchContent());
            dispatch(togglePdfForm(false));
            dispatch(toggleVideoForm(false));
          }else{
            dispatch(fetchContentLoader(false));
          }
        }
        )
    })
  }
}

export const setCurrentContentParams = (index, contentType) => ({
  type: SET_CURRENT_CONTENT_PARAMS,
  index,
  contentType
})


const updateLibraryContent = data => ({
  type: UPDATE_LIBRARY_CONTENT,
  data
})

const fetchContentLoader = bool => ({
  type: FETCH_CONTENT_LOADER,
  bool
})


export const fetchContent = () => {
  return (dispatch, getState) => {
    const {libFilterParams} = getState().digitalLibrary;
    const url = `/digital-library/content`;
    dispatch(fetchContentLoader(true));
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(libFilterParams)
        .send(async (response, error) => {
          dispatch(fetchContentLoader(false));
          if(response){
            dispatch(updateLibraryContent(response))
          }
        }
        )
    })
  }

}

export const toggleUpdateContentLoader = bool => ({
    type: TOGGLE_UPDATE_CONTENT_LOADER,
    bool
})


export const updateContent = () => {
    return (dispatch, getState) => {
        const {postContentParams} = getState().digitalLibrary;
        const url = `/digital-library/content`;
        const params = {
          id: postContentParams.id,
          subjectId: postContentParams.subjectId,
          chapterId: postContentParams.chapterId,
          chapterName: postContentParams.chapterName,
          title: postContentParams.title,
          type: postContentParams.type,
          standardIds: JSON.stringify(postContentParams.standardIds),
          link: postContentParams.link,
          file: postContentParams.file,
        }
        console.log({params});
        dispatch(toggleUpdateContentLoader(true));
      return new Promise((resolve, reject) => {
        Api.post(url).upload(
          params, (percent, response) => {
            console.log({response});
            if (percent === -1) {
              dispatch(toggleUpdateContentLoader(false))
            }
            if(response !== undefined){
              if(percent === 100){
                dispatch(toggleUpdateContentLoader(false));
                dispatch(clearPostContentParams());
                dispatch(togglePdfForm(false));
                dispatch(toggleVideoForm(false));
                dispatch(fetchContent())
              }
            }
          })
      })
    }
}


export const searchChapter = query => {
    return (dispatch, getState) => {
      const { postContentParams } = getState().digitalLibrary;
      const params = {
        standardIds: postContentParams.standardIds,
        subjectIds: [postContentParams.subjectId],
        chapterName: query
      };
      const url = `/assignment/filter/autocomplete-chapters`;
      return new Promise((resolve, reject) => {
        Api.get(url)
          .params(params)
          .send(async (response, error) => {
            resolve();
            if (!response.length) {
              dispatch(
                updateSearchedChaptersDLibrary([
                  {
                    id: -1,
                    value: "No chapters found",
                    disabled: true
                  }
                ])
              );
            } else {
              dispatch(updateSearchedChaptersDLibrary(response));
            }
          });
      });
    };
  };


export const updateSearchedChaptersDLibrary = chapters => ({
    type: UPDATE_SEARCHED_CHAPTERS_DLIBRARY,
    chapters
  });
  


export const clearPostContentParams = () => ({
    type: CLEAR_POST_CONTENT_PARAMS,
})


export const updatePostContentParams = (val, key) => ({
    type: UPDATE_POST_CONTENT_PARAMS,
    postParams: {[key]: val}
})


export const toggleVideoPlayer = (bool, link) => ({
    type: TOGGLE_VIDEO_PLAYER,
    bool,
    link
})


export const toggleVideoForm = bool => ({
    type: TOGGLE_VIDEO_FORM,
    bool
})


export const togglePdfForm = bool => ({
    type: TOGGLE_PDF_FORM,
    bool
})


export const changeLibraryTab = key => ({
    type: CHANGE_LIBRARY_TAB,
    key
})


export const updateLibFilterParams = (val, key) => ({
    type: UPDATE_LIB_FILTER_PARAMS,
    filterParams: {[key]: val}
})

export const clearLibFilterParams = () => ({
    type: CLEAR_LIB_FILTER_PARAMS,
})


// export const autoSubmitTest = (history) => {
//     return (dispatch, getState) => {
//         const {currentQIndex, questionsList, currentTest} = getState().studentTest;
//         const params = {
//                 answer: questionsList[currentQIndex].studentAnswer,
//                 testQuestionId: questionsList[currentQIndex].id,
//                 duration: currentTest.studentDuration,
//                 submitStatus: questionsList[currentQIndex].submitStatus === "NotVisited" ? 
//                     "Unanswered" : questionsList[currentQIndex].submitStatus,
//                 files: questionsList[currentQIndex].files,
//                 testStatus : "Submitted"
//             }
//         return Api.post(`/test/add/test-student-ans`).params(params).send((response, error) => {
//             if(response !== undefined) {
//                  dispatch(clearCurrentTest()); 
//                  history.push('/student-test-summary');
//             }
//             });
//         }
// }