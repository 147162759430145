import { LoadingOutlined } from '@ant-design/icons'
import { getCRMCourseListData } from 'actions/courseManagement'
import { Modal, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'

function AllCoursesListModal({ isAllCoursesListModalVisible, setAllCoursesListModalVisible, crmCourseListData, storeSingleCourseId, setCourseTypeName, selectedUserId, tab, getCRMCourseListData }) {
    const [modalLoader, setModalLoader] = useState(false)
    const handleCancel = () => {
        setAllCoursesListModalVisible(false)
    }
    const history = useHistory()
    useEffect(() => {
        setModalLoader(true);
        getCRMCourseListData(selectedUserId, tab, () => { setModalLoader(false) })
    }, [])
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isAllCoursesListModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            width={600}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>All courses in {tab == 2 ? "cart" : "wishlist"}</div>
            <div className='scroll-bar-universal' style={{ height: "50vh" }}>
                {modalLoader ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", padding: 0, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                        <LoadingOutlined style={{ color: "#AEAEAE", fontSize: 30 }} />
                    </div>
                    :
                    <>
                        {crmCourseListData?.map((item) => (
                            <div style={{ border: "1px solid #E6E6E6", borderRadius: 4, padding: 10, display: "flex", margin: 15, alignItems: "center", cursor: "pointer" }}
                                onClick={() => {
                                    storeSingleCourseId(item?.id);
                                    if (item?.type == "course") {
                                        setCourseTypeName("liveCourses")
                                    } else if (item?.type == "videoResource") {
                                        setCourseTypeName("videoResources")
                                    } else if (item?.type == "testSeries") {
                                        setCourseTypeName("onlineTestSeries")
                                    } else if (item?.type == "studyMaterial") {
                                        setCourseTypeName("studyMaterials")
                                    }
                                    history.push(
                                        {
                                            pathname: "/course-details",
                                            state: {
                                                fromCRMPage: true,
                                                courseType: item?.type,
                                            }
                                        })
                                }}>
                                <div>
                                    {item?.type == "studyMaterial" || item?.type == "testSeries" ?
                                        <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E6E6E6", padding: 10, height: "fit-content", display: "flex", alignItems: "center", borderRadius: 4, width: 130 }}>
                                            <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 35, width: 35 }} />
                                            <div>
                                                {item?.type == "testSeries" ?
                                                    <div style={{ color: "#594099", fontSize: 12, marginLeft: 10 }}>
                                                        {item?.t_count || 0} {item?.t_count <= 1 ? "test" : "tests"}
                                                    </div>
                                                    :
                                                    <div style={{ color: "#594099", fontSize: 12, marginLeft: 10 }}>
                                                        {item?.m_count || 0} {item?.m_count <= 1 ? "Material" : "Materials"}
                                                    </div>
                                                }
                                                {item?.type == "studyMaterial" ?
                                                    <div style={{ color: "#594099", fontSize: 12, marginLeft: 10 }}>
                                                        {item?.q_count > 0 ? `${item?.q_count || 0} ${item?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div> :
                                        <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 130, height: 60, borderRadius: 8 }} />}
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontFamily: "roboto", fontWeight: "bold", fontSize: 14, width: 330 }}>
                                        {item?.courseName}
                                    </Typography.Text>
                                    <div style={{ fontSize: 12, color: "#636363" }}>{moment(item?.createdAt).format("DD/MM/YYYY, hh:mm A")}</div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { crmTableData, crmParamsObj, crmCourseListData, autocompleteSearchData } = state.courseManagement;
    const { startDateContainer, selectedRangeContainer, endDateContainer } = state.attendanceNew;
    return {
        crmTableData, crmParamsObj, crmCourseListData, startDateContainer, selectedRangeContainer, endDateContainer, autocompleteSearchData
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCRMCourseListData: (id, tab, callBack) => dispatch(getCRMCourseListData(id, tab, callBack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllCoursesListModal);
