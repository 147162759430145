import { Api } from '../services';

export const UPDATE_CURRICULUM = 'UPDATE_CURRICULUM';
export const UPDATE_CHECKED_TEACHERS = 'UPDATE_CHECKED_TEACHERS';


export const updateCurriculum = (data) => ({
	type: UPDATE_CURRICULUM,
	data
});


export const updateCheckedTeachers = (data) => ({
	type: UPDATE_CHECKED_TEACHERS,
	data
});

export const getCurriculum = (params, location, pageLoaderCallback = null, callback = null, selectedStandard) => {
	return (dispatch, getState) => {
		const url = `/curriculum`;

		const { checkedTeachers } = getState().curriculum
		const { selectedSection } = getState().classRoom;

		params = {
			...params,
			teacherIds: checkedTeachers,
			standardId: (location == '/classroom/curriculum') ? selectedSection?.id : null,
			studentStandardId: selectedStandard
		}

		Api.get(url).params(params).send((response, error) => {
			if (response) {
				dispatch(updateCurriculum(response))
				// if(callback) callback(response.teachers.map(el => el.id));
			}
			if (pageLoaderCallback) pageLoaderCallback(false)
		});
	}
};

export const updateProgress = (params, location, failureCallback) => {
	return (dispatch, getState) => {
		const url = `/curriculum/updateProgress`;
		Api.post(url).params(params).send((response, error) => {
			if (response && response.show.type === "success") {
				dispatch(getCurriculum({}, location))
			}
			else failureCallback();
		});
	}
};
