import React, { useState, useEffect } from 'react'
import PageHeader from 'components/pageHeader'
import { connect } from 'react-redux';
import { fetchcourseStandards, getUsers, setSelectedStandard, setSpecificUserId, setUserDetails } from 'actions/userManagementV2';
import PageDataLoader from 'components/pageDataLoader';
import './styles.css'
import { Table, Typography } from 'antd';
import { Heading } from 'components/Typography';
import { RightOutlined } from '@ant-design/icons';
import { InputSearch } from 'components/customInputs';
import { Button } from 'components/Button';
import UserDeleteModal from './modals/userDelete'
import PermanentDeleteModal from './modals/parmanentDeleteModal'
import { IMG_URL } from "../../env.json";
import { setOthersSelectedTab } from 'actions/profileNew';

const defaultUser = require('Assets/userManagementV2/ic-students.svg').default;

export const StandardCard = ({ standard, history, setSelectedStandard }) => {
    const handleStandardClick = () => {
        setSelectedStandard(standard);
        history.push('/user/student');
    };

    return (
        <div onClick={() => handleStandardClick()} className='CardShadow cursor-pointer' style={{ border: '1px solid #E6E6E6', borderRadius: 8, height: '22vh', width: '10vw' }}>
            <center className='flex-column m-t-10'>
                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#594099', fontSize: '2.5rem', fontFamily: 'PT serif', fontWeight: 600, width: '100%', padding: '0px 5px' }}>{standard?.section}</Typography.Text>
                <div className='simGrey bold-500'>{standard?.courseName || 'NA'}</div>
                <div style={{ width: '80%', border: '1px solid #E6E6E6', marginLeft: 15, marginTop: 5 }}></div>
                <div style={{ color: '#28DF99' }}>{standard?.studentCount}</div>
                <div className='bold-500'>{standard?.studentCount > 1 ? 'Students' : 'Student'}</div>
            </center>
        </div>
    )
};

const CourseStandards = ({ history, fetchcourseStandards, courseStandards, selectedCourse, setSelectedStandard, getUsers, usersList, user, setSpecificUserId, setUserDetails, setOthersSelectedTab }) => {

    let fromUserManagement = true
    const [pageLoader, togglePageLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [parmanentDeleteModal, setPermanentDeletModal] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [userDeleteModalBool, setUserDeleteModalOpen] = useState(false);

    useEffect(() => {
        togglePageLoader(true);
        fetchcourseStandards(null, () => togglePageLoader(false));
    }, []);

    let params = {
        search: search,
        userType: 'Student'
    }

    useEffect(() => {
        togglePageLoader(true)
        getUsers(params, () => togglePageLoader(false))
    }, [search]);

    const columns = (user) => {
        const renderName = (value, record) => (
            <div className='display-flex flex-start'>
                <img src={`${IMG_URL}/${record?.image ? record?.image : 'default.png'}`} alt="" style={{ width: '20px', marginRight: '10px' }} />
                <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "70%" }}>{value}</Typography.Text>
            </div>
        );

        const baseColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                render: renderName
            },
            {
                title: 'Class Details',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                render: (value, record) => <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%' }}><div style={{ width: 180 }}>{record?.classDetails ? record?.classDetails : 'NA'}</div></Typography.Text>
            },
            {
                title: 'Courses',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                render: (value, record) => <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%' }}><div style={{ width: 180 }}>{record?.courses ? record?.courses : 'NA'}</div></Typography.Text>
            },
            {
                title: 'Action',
                dataIndex: 'status',
                key: 'status',
                render: (value, record) => {
                    return <div className='' style={{ display: 'flex' }}>
                        {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) && (
                            <>
                                <Button onClick={(e) => openDeleteModal(e, record)} type='transparent' style={{ color: '#636363' }}>ARCHIVE</Button>
                                <Button onClick={(e) => openPermanentDeleteModal(e, record)} type='transparent' style={{ color: '#FF414D' }}>PERMANENT DELETE</Button>
                            </>
                        )}
                    </div>
                }
            }
        ];

        return baseColumns;
    };

    const onRow = (record, rowIndex) => {
        return {
            style: { cursor: 'pointer' },
            onClick: () => {
                history.push({
                    pathname: '/user-management/profile',
                    state: {
                        key: fromUserManagement
                    }
                })
                setSpecificUserId(record?.id);
                setUserDetails(record);
                setOthersSelectedTab('Basic Profile');
            }
        }
    }

    const setIdToDelete = (id) => {
        selectedRowKeys.push(id);
        setSelectedRowKeys([...selectedRowKeys]);
    };

    const openDeleteModal = (e, record) => {
        setUserDeleteModalOpen(true);
        e.stopPropagation();
        setIdToDelete(record?.id);
    }
    const openPermanentDeleteModal = (e, record) => {
        setPermanentDeletModal(true);
        e.stopPropagation();
        setIdToDelete(record?.id);
    }

    const onDeleteModalCancel = () => {
        setUserDeleteModalOpen(false)
    };
    const onPermanentDeleteModalCancel = () => {
        setPermanentDeletModal(false)
    };

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selections) => {
            setSelectedRowKeys([...selectedRowKeys]);
        },
    };

    console.log({ courseStandards })
    console.log({ usersList })

    const actions = [
        <InputSearch placeholder='Search by name/mobile' onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} val={search} allowClear />
    ]

    return (
        <div>
            {search != '' && usersList?.items?.length != 0 ?
                (
                    <>
                        <PageHeader title={'Search Results'} actions={actions} onBackPress={() => history.goBack()} />
                        {pageLoader ? null :
                            <div className='m-t-70'>
                                <Table
                                    style={{ width: "100vw" }}
                                    rowKey={record => record?.id}
                                    onRow={onRow}
                                    rowSelection={rowSelection}
                                    dataSource={usersList?.items || []}
                                    columns={columns(user)}
                                />
                            </div>
                        }
                    </>
                )
                :
                (
                    <>
                        <PageHeader title={selectedCourse} actions={actions} onBackPress={() => history.goBack()} />
                        {pageLoader ? null :
                            <div className='m-t-50 p-20'>
                                {
                                    <div>
                                        <Heading>Classes without course ({`${courseStandards?.std_wo_course}`})</Heading>
                                        <div onClick={() => history.push('/user-management/courses/unassigned')} className='cursor-pointer CardShadow' style={{ border: '1px solid #E6E6E6', height: 100, width: "22%", borderRadius: 8, marginTop: 10, backgroundColor: 'white', padding: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className='display-flex'>
                                                <img src={defaultUser} style={{ width: 50 }} />
                                                <div className='flex-column p-10 m-l-15'>
                                                    <div style={{ color: '#191919', fontWeight: 600, fontSize: '1.5vw' }}>{`${courseStandards?.std_wo_course_student}`}</div>
                                                    <div style={{ color: '#636363', fontWeight: 600, fontSize: '0.8vw' }}>{'students'}</div>
                                                    {/* <div className='bold-600 text-xs' style={{ color: '#28DF99' }}>+100 from last week</div> */}
                                                </div>
                                            </div>
                                            <div><RightOutlined /></div>
                                        </div>
                                    </div>
                                }
                                {courseStandards?.standardsArray && courseStandards?.standardsArray?.length !== 0 ? (
                                    courseStandards?.standardsArray?.map((item, idx) => (
                                        <div key={idx} className='m-t-40'>
                                            <h2 className="m-t-10 bold-600" style={{ fontSize: "14px" }}>
                                                {item.std}
                                            </h2>
                                            <div className="r-c-fs flex-wrap">
                                                {courseStandards?.standardsArray[idx]?.standards?.map((standard) => (
                                                    <div key={standard.id} className='' style={{ padding: 5 }}>
                                                        <StandardCard standard={standard} history={history} setSelectedStandard={setSelectedStandard} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                ) : 'NODATA'}
                            </div>
                        }
                    </>
                )
            }


            <PageDataLoader visible={pageLoader} />
            {userDeleteModalBool && <UserDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={userDeleteModalBool} onCancel={() => onDeleteModalCancel()} />}
            {parmanentDeleteModal && <PermanentDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={parmanentDeleteModal} onCancel={() => onPermanentDeleteModalCancel()} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.session;
    const { courseStandards, selectedCourse, usersList } = state.userManagementV2;
    return { courseStandards, selectedCourse, usersList, user }
};

const mapDispatchToProps = (dispatch) => ({
    fetchcourseStandards: (params, callback) => dispatch(fetchcourseStandards(params, callback)),
    setSelectedStandard: res => dispatch(setSelectedStandard(res)),
    getUsers: (params, callback) => dispatch(getUsers(params, callback)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setUserDetails: res => dispatch(setUserDetails(res)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseStandards);