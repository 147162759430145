import React from 'react';
import Styles from './style';
import Card from 'antd/lib/card';
import Avatar from 'antd/lib/avatar';
import 'antd/lib/avatar/style/index';
import 'antd/lib/typography/style/index';
import 'antd/lib/card/style/index';
import {connect} from 'react-redux';
import { Color } from '../../services';
import {deleteUsers} from '../../actions/user';
import moment from 'moment';



class AnnouncementCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      deleteCardBool : false,
      visible : false,
    }
  }


  showMenu = (e, cardDetail) => {
    e.stopPropagation();
    console.log('testing', cardDetail);
    this.setState({visible : true,})
  }
  deleteCard = (e) => {
    e.stopPropagation();
    this.setState({deleteCardBool: true})
  }
  deleteCardConfirm = (e, cardDetail) => {
    e.stopPropagation();
    this.setState({deleteCardBool: false})
    this.props.dispatch(deleteUsers(cardDetail,this.props.position))
    this.setState({visible : false,})
  }
  cancelCard = (e) => {
    e.stopPropagation();
    this.setState({deleteCardBool: false})
  }
  editCard = (e, cardDetail) => {
    e.stopPropagation();
    this.setState({visible : false});
    // this.props.dispatch(editUsers(cardDetail))
    this.props.onEditClick();
    // this.props.dispatch(studentModalVisible(cardDetail, true));
  }

  handleMenuItemClick() {
    // Do nothing
  }


  render(){
    const { color, onClick, cardDetail} = this.props;

    const lightColor = Color[`light${color}`];

    return(
      <Card style={{...Styles.announcemntCardContainer,backgroundColor: lightColor}} >
        <div className='r-jsb cursor-pointer' style={{margin:'-12px'}} onClick={onClick}>
          <div style={{width:'25px'}}></div>
          <div className='r-c-c-c m-t-10'>
            <Avatar size={60} style={{fontSize: 24}}> {cardDetail.target.charAt(0) || 'A'}</Avatar>
            <div className='text-sm color-black m-t-20' style={{textAlign: 'center'}}>{cardDetail.title}</div>
            <div className='color-black' style={{textAlign: 'center', fontSize: 12}}>{moment(cardDetail?.createdAt).format('DD MMM YYYY')}</div>
          </div>

        </div>
      </Card>
    )
  }
}


export default connect()(AnnouncementCard);
