import { Modal } from 'antd';
import React from 'react';
import {Button} from 'components/Button';
import { connect } from 'react-redux';

const FeeBreakupModal = ({visible, handleClose, studentFeeDetails}) => {
    return (
        <Modal
            visible={visible}
            className="modal-round-corner"
            footer={false}
            closable={false}
        >
            <div>
                <div className='m-t-30 r-c-c' style={{fontSize: "20px", fontWeight: 500}}>
                    Breakups
                </div>
                <div className='r-c-c'>
                    <div style={{fontSize: 16, width: "70%"}} >
                        <div className='r-jsb m-t-20'>
                            <div>Fee amount</div>
                            <div> ₹ {studentFeeDetails?.totalFee ?studentFeeDetails?.totalFee.toFixed(2) : 0 }</div>
                        </div>
                        {studentFeeDetails?.scholarshipDetails?.map((scholarship, index) => 
                            <div className='r-jsb m-t-20' key={index} style={{color: "#1089FF"}}>
                                <div>{scholarship.name}</div>
                                <div> -₹ {scholarship?.amount ? scholarship?.amount?.toFixed(2) : 0}</div>
                            </div>
                        )}
                        {studentFeeDetails?.additionDetails?.map((addition, index) => 
                            <div className='r-jsb m-t-20' style={{color: "#FFA931"}}>
                                <div>{addition.name}</div>
                                <div> +₹  {addition?.amount ? addition?.amount?.toFixed(2) : 0}</div>
                            </div>
                        )}
                        
                        <div style={{width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20}}/>
                        <div className='r-jsb m-t-20' style={{fontWeight: 500}}>
                            <div>Total payable</div>
                            <div> ₹ {studentFeeDetails?.totalPayable ?studentFeeDetails?.totalPayable.toFixed(2) : 0 }</div>
                        </div>
                        <div style={{width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20}}/>
                        <div className='m-t-40 m-b-30 r-c-c'>
                            <Button onClick={handleClose}>
                                CLOSE
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
            
        </Modal>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session;
    const { studentFeeDetails } = state.profileNew;
    return {
        user, studentFeeDetails
    }
}

const mapDispatchToProps = (dispatch) => ({
   
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeBreakupModal);

