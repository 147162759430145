import { CaretLeftOutlined, CaretRightOutlined, MoreOutlined, EditFilled, DeleteFilled, CheckCircleOutlined, ConsoleSqlOutlined } from '@ant-design/icons'
import { Col, DatePicker, Row, Menu, Popover, Tooltip, Dropdown, Select, Typography } from 'antd';
import TeachersWorkhrsGraph from 'pages/attendanceNew/teachersWorkHrsGraph'
import React, { useEffect, useState } from 'react'
import DoughnuttCard from './localcomponents/doughnuttCard'
import RequestAttendanceModel from './localcomponents/requestAttendanceModel'
import RequestLeavesModel from './localcomponents/requestLeavesModel'
import './styles.css'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { MdMoreVert, } from 'react-icons/md'
import { getAttendanceDashBoardData, getLocation, markAttendance, setLocation, setMarkAttendance, setLoader, setFilterValues, setLeaveRequest, leaveRequest, setEditLeave, selectedLeaves, selectEditLeaveCardId, editLeaveModal, storeSummaryStartDate, storeSummaryEndDate, getAllLeave, deleteLeaveReq, idSelected, storeMonthlySelectedMonth, roleFilter, getCaluclatedDates, storeNumberOfDays } from 'actions/attendanceNew'
import moment from 'moment'
import { loadChapters } from 'actions/classRoom_old'
import EditLeaves from 'pages/attendanceNew/localComponents/editLeaves'
import DeleteEditLeave from 'pages/attendanceNew/Modals/deleteEditLeave'
import WeeklyAttendanceGraph from 'pages/attendanceNew/localComponents/weeklyAttendanceGraph'
// import PageDataLoader from 'components/pageDataLoader'
import { Notification } from "../../services";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { setOthersSelectedTab } from 'actions/profileNew';
import LocationDetailModal from './locationModal';
import { setSpecificUserId } from 'actions/userManagement';
import { Heading, SubHeading } from 'components/Typography';
import { Button } from 'components/Button';

const E6E6E6timer = require('../../Assets/ic-timer.svg').default;
const warning = require("../../Assets/ic-warning.svg").default
const presentImage = require("../../Assets/ic-present.svg").default;
const absentImage = require("../../Assets/ic-absent.svg").default;
const overAllImage = require("../../Assets/ic-overall.svg").default;
const totalHrsImage = require("../../Assets/ic-working-hrs.svg").default;
const avgHrsImage = require("../../Assets/ic-avg-working-hrs.svg").default;
const totalDaysImage = require("../../Assets/ic-leaves.svg").default;
const halfDay = require('../../Assets/Attendance/Group 13469.svg').default;
const more = require("../../Assets/ic-more.svg").default
const checkCircle = require("../../Assets/ic-check-circle.svg").default
const icgeofence = require("../../Assets/ic-geo-fence.svg").default
const icclockout = require("../../Assets/ic-clock-out.svg").default
const Group_11708 = require("../../Assets/Group 11708.svg").default
const Iconmaterialtimer = require("../../Assets/Icon material-timer.svg").default
const icclockin = require("../../Assets/ic-clock-in.svg").default
const clockIcon = require("../../Assets/Group 15530.svg").default
const mapPointer = require("../../Assets/Group 15528.svg").default
const NoData = require("../../Assets/ill-no-data.svg").default

/* const checkCircle=require("../../Assets/ic-check-circle.svg").default */
const { RangePicker } = DatePicker



const NoDataCard = () => {
  return (
    <>
      <div className='r-c-c-c' style={{ marginLeft: "40px" }}>
        <img src={NoData} />
        <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</div>
        <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no data to show yet. Please check back later.</div>
      </div>
    </>
  )
}


const RequestHistoryCard = ({


  status,
  duration,
  endDate,
  startDate,
  leaveType,
  requestType,
  istrue,
  data,
  user,
  isEditModalVisible,
  setisEditModalVisible,
  selectedLeaves,
  AttendanceTab,
  editLeaveMeta,
  loaders,
  storeSelectedLeaves,
  idSelected,
  deleteLeaveReq,
  selectEditLeaveCardId,
  getAttendanceDashBoardData,
  togglePageLoader }) => {
  const [deleteEditLeaveModal, setDeleteEditLeaveModal] = useState(false)

  const menu = (
    <div onClick={(e) => { e.stopPropagation() }}>
      <Menu
        onClick={(e) => { }} style={{ width: "100%", backgroundColor: "white" }}>
        {/* {user?.privilage.includes("SuperAdmin")||user?.privilage.includes("LeaveManager") ?
        <Menu.Item onClick={(e) => {
          setisEditModalVisible(true);
          selectEditLeaveCardId(data.id)
          selectedLeaves(data)
          idSelected(data)
          }}
          style={{backgroundColor: 'white'}}>
            <EditFilled /> 
              Edit
            </Menu.Item>
          : 
        null
      } */}
        <Menu.Item style={{ backgroundColor: 'white' }}
          onClick={(e) => {
            selectEditLeaveCardId(data.id)
            setLoader('deleteEditLeave', false)
            setDeleteEditLeaveModal(true)
            selectedLeaves(data)
          }}>
          <DeleteFilled />
          Delete
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <div className=' shadow-box hover-shadow customCardShadow m-l-10 m-t-5' style={{
      width: "31%",
      minWidth: "180px",
      height: "fit-content",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #E6E6E6",
      borderRadius: " 4px",
      paddingBottom: 10
    }}>
      <div className='flex-row m-r-5' style={{ justifyContent: "space-between" }}>
        <div className='flex m-t-10'>
          <div style={{ color: "#AEAEAE", font: " normal normal normal 11px/18px Roboto", marginLeft: "15px" }}>{duration}days</div>
          <div style={{ font: "normal normal bold 12px/15px Roboto", color: "#191919", marginLeft: "15px", width: "150px" }}>{moment(startDate).format('DD-MM-YYYY')}{"-"}{moment(endDate).format('DD-MM-YYYY')}</div>
          <div style={{ font: "normal normal normal 13px/18px Roboto", color: "#636363", marginLeft: "15px" }}>{leaveType}{" "}{requestType}</div>
        </div>
        <div className='m-t-10 p-2' style={{ width: "fit-content", height: "fit-content", marginLeft: "-15px" }}>
          {istrue ? <StatusTag type={status} /> : null}
        </div>
        <div style={{ width: "fit-content", height: "20px" }} className='m-t-5'>
          <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            overlay={menu}
            disabled={false}
            placement="bottomRight"
          >
            <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
          </Dropdown>
        </div>
      </div>

      {deleteEditLeaveModal &&
        <DeleteEditLeave
          deleteLeaveReq={deleteLeaveReq}
          deleteEditLeaveModal={deleteEditLeaveModal}
          setDeleteEditLeaveModal={setDeleteEditLeaveModal}
          getAttendanceDashBoardData={getAttendanceDashBoardData}
          togglePageLoader={togglePageLoader}
          fromWhere='AttendanceHome'
        />}
    </div>
  )
};

const UpcomingLeaveRequests = ({ item }) => {
  return (
    <div style={{ padding: '20px 0 20px 0' }}>
      <Row style={{ justifyContent: 'space-between', width: '85%' }}>
        <div>{moment(item?.startDate).format('DD-MM-YYYY')}</div>
        <div className='m-l-30'>{item?.leaveType} Leave</div>
        <div style={{ width: 60 }}>{item?.duration} {item?.duration > 1 ? 'Days' : 'Day'} </div>
      </Row>
    </div>
  )
}

const StatusTag = ({ type }) => {
  return (
    <div style={{
      height: "fit-content",
      width: "fit-content",
      border: `${type === "Pending" ? "1px solid #FFA931" : type === "Approved" ? "1px solid #28DF99" : "1px solid #FF414D"}`,
      borderRadius: " 3px",
      background: `${type === "Pending" ? "#FFF4D5" : type === "Approved" ? "#E9FCF5" : "#FFE4D9"}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <div style={{ font: "normal normal bold 8px/10px Roboto", color: `${type === "Pending" ? "#FFA931" : type === "Approved" ? "#28DF99" : " #FF414D"}` }}>{type === "Pending" ? "Pending" : type === "Approved" ? "Approved" : "Rejected"}</div>
    </div>
  )
}

const AttendanceToday = ({ attendanceDataContainer, Location, setMarkAttendance, markAttendance, setLoader, loaders, disabledDate, user, isModalVisible, setIsModalVisible }) => {

  const [clockIn, setClockIn] = useState(true)
  const [timeH, setTimeH] = useState();
  const [timeM, setTimeM] = useState();
  const [selectedTime, setSelectedTime] = useState(moment());
  const [toggler, setToggler] = useState(true);
  const [ts, setTs] = useState();
  const [showTime, setShowTime] = useState(false);
  const [clockLdr, setClockLdr] = useState(false);

  useEffect(() => {
    const myTimer = () => {
      var diff = moment().diff(showTime ? selectedTime : moment(), 's');
      setTs(diff)
      let mins = diff / 60;

      setTimeM(mins % 60 ? mins % 60 : '00');
      setTimeH((mins / 60 ? mins / 60 : '00'))
      setToggler(!toggler)
      if (moment().format('HH:mm:ss') == '00:00:00') {
        setSelectedTime(moment());
        setShowTime(false)
      }
    }
    setTimeout(myTimer, 1000);
  }, [toggler])


  const clockinHandler = () => {
    setClockLdr(true)
    Location(() => {
      if (clockIn) {
        setSelectedTime(moment());
        setShowTime(true)
      }
      setClockIn(false);
    }, () => {
      setClockLdr(false)
    });
    setClockLdr(false)
  }

  useEffect(() => {
    setSelectedTime(moment(attendanceDataContainer?.attendanceToday?.startTime, "HH:mm:ss"))
    setShowTime(true)
    setClockIn(attendanceDataContainer?.attendanceToday?.startTime ? false : true);
  }, [attendanceDataContainer?.attendanceToday?.startTime])

  const ClockCard = ({ heading, time, location, address, activeLocation }) => {

    return (
      <div>
        <div className='m-t-20 m-l-10'>
          <div style={{ color: "AEAEAE", fontSize: 11, fontFamily: "roboto", fontWeight: "normal" }}>{heading}</div>
          <div style={{ display: "flex" }}>
            <img style={{ height: 13, width: 13, marginTop: 3 }} src={clockIcon}></img>
            <div style={{ fontSize: 13, color: "#191919", fontWeight: "bold", marginLeft: 6 }}>{time}</div>
          </div>
          <div style={{ display: "flex" }}>
            <img style={{ height: 13, width: 13, marginTop: 3 }} src={mapPointer}></img>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 13, color: "#636363", marginLeft: 6, width: "100%" }}>{location}</Typography.Text>
          </div>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "AEAEAE", fontSize: 11, fontFamily: "roboto", fontWeight: "normal", marginLeft: 20, width: "100%" }}>{address}</Typography.Text>
        </div>
      </div>
    )
  }

  return (
    <Row>
      <Col style={{ width: "50%" }}>
        <ClockCard
          heading={'CLOCK IN'}
          time={attendanceDataContainer?.attendanceTodayWeb?.startTime ? moment(attendanceDataContainer?.attendanceTodayWeb?.startTime, "HH:mm:ss").format("hh:mm A") : "--:--"}
          location={attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInTitle ? attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInTitle : "--:--"}
          address={attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInAddress ? attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInAddress : "--:--"}
          webClockIn={attendanceDataContainer?.webclockin}
        />
        <ClockCard
          heading={'CLOCK OUT'}
          time={attendanceDataContainer?.attendanceTodayWeb?.endTime ? moment(attendanceDataContainer?.attendanceTodayWeb?.endTime, "HH:mm:ss").format("hh:mm A") : "--:--"}
          location={attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockOutTitle ? attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockOutTitle : "--:--"}
          address={attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockOutAddress ? attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockOutAddress : "--:--"}
          webClockIn={attendanceDataContainer?.webclockin}
          activeLocation={attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences}

        />
      </Col>
      <Col style={{ width: "50%" }}>
        <div style={{ marginTop: "-20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ height: '180px', width: "180px" }} src={Group_11708} />
            <div><img style={{ marginTop: "-250px", height: "30px", width: "30px" }} src={Iconmaterialtimer} /></div>
            <div style={{ marginTop: "-120px", color: "white", fontSize: 18, fontWeight: 700, textAlign: "center" }}>
              {`${parseInt(timeH)}`.padStart(2, '0')}:{`${parseInt(timeM)}`.padStart(2, '0')}:{`${parseInt(ts % 60 && ts % 60 > -1 ? ts % 60 : '00')}`.padStart(2, '0')}</div>
            <div style={{ color: "white", fontSize: 15 }}>Working hours</div>
          </div>
          <div style={{ marginLeft: "-13px", marginTop: 70 }}>
            <Button className='m-t-50'
              loading={clockLdr}
              type='primary'
              style={{
                backgroundColor: "#28DF99",
                color: "#FFFFFF",
                border: 'none'
              }}
              onClick={clockinHandler}
            >
              {clockIn ? "CLOCK ME IN" : "CLOCK ME OUT"}
            </Button>
          </div>
        </div>
      </Col>
      {isModalVisible &&
        <RequestAttendanceModel user={user} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} setMarkAttendance={setMarkAttendance} markAttendance={markAttendance} setLoader={setLoader} loaders={loaders} disabledDate={disabledDate} />
      }
    </Row>
  )
}

export const attendanceOverviewData = (attendanceDataContainer) => {
  return [
    {
      image: presentImage,
      count: attendanceDataContainer?.attendanceOverview?.PresentCount,
      text: "Present day(s)"
    },
    {
      image: absentImage,
      count: attendanceDataContainer?.attendanceOverview?.AbsentCount,
      text: "Absent day(s)"
    },
    {
      image: halfDay,
      count: attendanceDataContainer?.attendanceOverview?.HalfDayCount,
      text: "Half day(s)"
    },
    {
      image: totalHrsImage,
      count: attendanceDataContainer?.attendanceOverview?.totalWorkingHoursTillDate,
      text: "Total working hours"
    },
    {
      image: avgHrsImage,
      count: attendanceDataContainer?.attendanceOverview?.averageWorkingHoursTillDate,
      text: "Avg. working hours"
    },
    {
      image: overAllImage,
      count: attendanceDataContainer?.attendanceOverview?.overallPercentage,
      text: "Overall percentage"
    },

  ]
}


const AttendanceOverview = ({ data, index, attendanceDataContainer }) => {
  return (
    <>
      <div className='m-l-10 m-t-10'
        style={{
          width: "31%", height: "75px", minWidth: "180px",
          borderRight: index === 0 || index === 1 || index === 3 || index === 4 ? "1px solid #E6E6E6" : "none"
        }}>
        <div className='flex-row m-l-10'>
          <div>
            <img style={{ height: 50 }} src={data?.image} />
          </div>
          <div>
            <div className='text-overflow-ellipsis  m-l-10' style={{ fontSize: "18px", color: "#191919", width: "15vh" }}>{data?.count}</div>
            <div className='text-overflow-ellipses  m-l-10' style={{ fontSize: "11px", color: "#636363" }}>{data?.text}</div>
          </div>
        </div>

      </div>

    </>
  )
}

const HolidayCalendar = ({ calendarData, leftClick, rightClick, date, user, history, setOthersSelectedTab, setSpecificUserId }) => {

  const CustomNavigator = () => {
    return (
      <>
        <div className='r-jsb' style={{ width: '95%', marginTop: "10px" }}>
          <div className='display-flex' style={{ height: 18, width: '40%', marginLeft: 10, alignItems: 'center', justifyContent: 'space-between' }}>
            <CaretLeftOutlined style={{ color: '#AEAEAE' }} onClick={leftClick} />
            <div style={{ color: '#636363', flexSize: 10 }}>{moment(date).format('MMMM, YYYY')}</div>
            <CaretRightOutlined style={{ color: '#AEAEAE' }} onClick={rightClick} />
          </div>
        </div>
      </>
    )
  }

  const tileClassName = ({ date, view }) => {
    if (view === 'month' && date.toDateString() === new Date().toDateString()) {
      return 'custom-today-class'; //removes today's hightlighter! 
    }
    return; // return null for other dates
  };

  const OnHolidayHover = ({ e }) => {
    let day = moment(e.date).format('DD-MM-YYYY');
    const isDayInCalendarData = calendarData?.some(item => item.date === day);
    if (isDayInCalendarData) {
      return (<Tooltip title={calendarData.filter(item => item.date === day).map(item => item.holidayName).join(' ')}>
        <div style={{ backgroundColor: "#ff414d", height: 30, width: 30, borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", color: "white", lineHeight: 0.2 }}>{moment(e.date).format('DD')}</div>
      </Tooltip>)
    } else {
      return <div>{moment(e.date).format('D')}</div>
    }
  }

  return (
    <>
      <div style={{ height: '45vh', width: '100%' }}>
        <Heading>Holidays</Heading>
        <CustomNavigator />
        <div style={{ marginTop: "20px" }}>
          <Calendar
            className="attendance-home"
            tileContent={(e) => <OnHolidayHover e={e} />}
            showNavigation={false}
            defaultView='month'
            calendarType={'ISO 8601'}
            activeStartDate={date}
            showNeighboringMonth={false}
            value={null}
            tileClassName={tileClassName}
          />
        </div>
      </div>
    </>
  )
}

const AttendanceHome = ({
  storeNoOfDay,
  getCaluclatedDates,
  roleFilter,
  getAttendanceDashBoardData,
  attendanceDataContainer,
  user,
  getLocation,
  setLocation,
  setMarkAttendance,
  markAttendance,
  setLoader,
  loaders,
  setFilterValues,
  filterValues,
  AttendanceTab,
  leaveRequestObj,
  setLeaveRequest,
  leaveRequest,
  getAllLeave,
  setAllLeaves,
  deleteLeaveReq,
  selectEditLeaveCardId,
  togglePageLoader,
  storeNumberOfDays,
  editLeaveMeta, storeSelectedLeaves, idSelected, idSelectedStore, storeSummaryEndDate, storeSummaryStartDate, summaryStartDate, summaryEndDate, storeMonthlySelectedMonth, storeMonthlySummaryMonth, setOthersSelectedTab, setSpecificUserId }) => {

  const data = Object.entries(attendanceDataContainer?.attendanceOverview ? attendanceDataContainer?.attendanceOverview : {})

  const currentDate = new Date();


  let [sum, setSum] = useState(0);
  const [isLeaveRequest, setIsLeaveRequest] = useState(false);
  const [isEditModalVisible, setisEditModalVisible] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [istrue, SetIstrue] = useState(false);
  const [leaveVisible, setLeaveVisible] = useState(false);
  const [date, setDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const [isLocationModal, setIsLocationModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [visibleItems, setVisibleItems] = useState(4);

  let holidayArr = [];
  const history = useHistory();
  const dateFormat = 'YYYY-MM-DD';
  let targetMonth = moment().format("MMMM");


  // HOLIDAYS CALENDAR CODE...
  // Example usage: update the holidays for the next month
  const handleCalendarLeftClick = () => {
    setDate(new Date(moment(date).subtract(1, 'months')));
  }

  const handleCalendarRightClick = () => {
    setDate(new Date(moment(date).add(1, 'months')));
  }

  const disableCalendarTillYearEnd = () => {
    if (current && current < moment("2022-01-01", "YYYY-MM-DD")) {
      return true;
    }
    // Disable months after current month in current year
    if (current && current > moment().endOf('year')) {
      return true;
    }
    return false;
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  const disabledYearAndMonth = current => {
    // Disable years before 2022 1st January
    if (current && current < moment("2022-01-01", "YYYY-MM-DD")) {
      return true;
    }
    // Disable months after current month in current year
    if (current && current > moment().endOf('month')) {
      return true;
    }
    return false;
  };

  // to access the location of the user!
  function Location(callback, successCallBack) {
    navigator.geolocation.getCurrentPosition(function (position) {
      getLocation(`${position.coords.latitude}`, `${position.coords.longitude}`);
      setLocation(() => { callback() }, () => { successCallBack() });
    }, function error(err) {
      Notification.error('error', 'Location access denied');
      return true;
    })
  }

  const handleOnChange = (value) => {
    storeMonthlySelectedMonth(value);
    togglePageLoader(true)
    getAttendanceDashBoardData(() => {
      togglePageLoader(false)
    })
  };

  const markLeavesHandler = () => {
    setLeaveVisible(true);
    storeNumberOfDays()
  }

  const handleLeftOutCaret = () => {
    setFilterValues('startDate', moment(filterValues?.startDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'))
    setFilterValues('endDate', moment(filterValues?.endDate, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'))
    togglePageLoader(true)
    getAttendanceDashBoardData(() => { togglePageLoader(false) })
  }

  const handleRightOutCaret = () => {
    setFilterValues('startDate', moment(filterValues?.startDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'))
    setFilterValues('endDate', moment(filterValues?.endDate, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'))
    togglePageLoader(true)
    getAttendanceDashBoardData(() => { togglePageLoader(false) })
  }

  const handleDates = (date, dateString) => {
    if (dateString[0] != '' && dateString[1] != '') {
      setFilterValues('startDate', dateString[0])
      setFilterValues('endDate', dateString[1])
    } else {
      setFilterValues('startDate', moment().startOf('week').format('YYYY-MM-DD'))
      setFilterValues('endDate', moment().endOf('week').format('YYYY-MM-DD'))
    }
  }

  useEffect(() => {
    setFilterValues('startDate', (moment().startOf('week').format("YYYY-MM-DD")));
    setFilterValues('endDate', (moment().endOf('week').format("YYYY-MM-DD")));

    storeMonthlySelectedMonth(moment().format("MMMM"));
    roleFilter();

    // pushing the holidays into an Arr!
    attendanceDataContainer?.holidays?.forEach((items) => {
      items?.data?.map((item) => {
        holidayArr.push({
          holidayName: item?.title,
          date: moment(item?.date).format('DD-MM-YYYY')
        })
      })
      setCalendarData(holidayArr)
    })

  }, []);

  useEffect(() => {
    togglePageLoader(true)
    getAttendanceDashBoardData(() => { togglePageLoader(false) })
  }, [filterValues?.startDate, filterValues?.endDate])

  useEffect(() => {
    let valOfDuration = attendanceDataContainer?.weeklyAttendance?.map((item) => item?.durationInMinutes)
    if (valOfDuration && valOfDuration?.length != 0 && Math?.max(...valOfDuration) > 0) {
      setSum(1)
    } else {
      setSum(0)
    }
  }, [attendanceDataContainer?.weeklyAttendance]);

  const monthlySummaryData = [
    {
      type: 'Present',
      value: attendanceDataContainer?.monthlyAttendance?.presentDays,
    },
    {
      type: 'Absent',
      value: attendanceDataContainer?.monthlyAttendance?.absentDays,
    },
    {
      type: 'Half Day',
      value: attendanceDataContainer?.monthlyAttendance?.halfDays,
    },
    {
      type: 'Holiday',
      value: attendanceDataContainer?.monthlyAttendance?.holidayCount,
    }
  ];

  const monthlySummaryPercentage = attendanceDataContainer?.monthlyAttendance?.averagePercentage;

  const GeofenceCard = ({ title, geoFenceType, address, geoEnd, geoStart }) => {
    const start = moment.utc(geoStart);
    const end = moment.utc(geoEnd);
    const diffInDays = end.diff(start, 'days');

    return (
      <div className='m-r-20'>
        <div className='boxShadow118' style={{ width: 300, height: 130, border: '1px solid #E6E6E6', borderRadius: 8, backgroundColor: '#FFFFFF', padding: 15 }}>
          <div style={{ color: '#191919', fontSize: 15, fontWeight: 600 }}>{title}</div>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: 250, color: 'white' }}>
            <div style={{ width: 220, fontFamily: 'roboto', color: '#636363', fontSize: "13px" }}>{address != '' ? address : 'NA'}</div>
          </Typography.Text>
          <div className='display-flex m-t-5'>
            <img src={E6E6E6timer} style={{ height: 20, fill: '#E6E6E6' }} />
            <div style={{ color: '#AEAEAE', marginLeft: 10, fontSize: "11px" }}>{geoFenceType == 'Permanent' ? 'All time' : `${moment.utc(geoStart).local().format('DD-MM-YYYY')} - ${moment.utc(geoEnd).local().format('DD-MM-YYYY')} • ${diffInDays + 1} ${diffInDays > 1 ? 'Days' : 'Day'}`}  </div>
          </div>
        </div>
      </div>
    )
  }

  const loadMore = () => {
    setVisibleItems(visibleItems + 5);
  };

  return (
    <>
      <Row style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 14 }} className='container boxShadow118  m-t-10 p-20' style={{ height: "44.5vh", minWidth: 400, overflow: 'hidden', }}>

          <div className='r-c-sb' style={{ width: '96%' }}>
            <div className='' style={{ minWidth: 300 }}>
              <Heading>Attendance Overview</Heading>
              {attendanceDataContainer?.since == "Invalid date" ?
                <></>
                :
                <div style={{ fontSize: "10px", color: "#636363" }}>From  {" "}{moment(attendanceDataContainer?.since).format('DD-MM-YYYY')} {" "}  {" "} To  {" "} {moment(attendanceDataContainer?.tillDate).format('DD-MM-YYYY')}</div>
              }
            </div>
          </div>

          <div className='flex-row m-l-10' style={{ display: "flex", flexWrap: "wrap", overflow: 'hidden', height: 250, justifyContent: "center" }}>
            {attendanceOverviewData(attendanceDataContainer)?.map((item, index) => (
              <AttendanceOverview data={item} index={index} attendanceDataContainer={attendanceDataContainer} />
            ))}
            <div className='r-c-c'>
              <Button type='transparent' onClick={() => { setOthersSelectedTab('Attendance'); history.push({ pathname: '/user-profile/others', state: { fromWhere: 'attendanceProfile' } }); }} className='cursor-pointer' style={{ color: "#1089FF" }}>VIEW PROFILE</Button>
            </div>
          </div>

        </Col>

        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 9 }} className='container  boxShadow118 m-t-10 p-20' style={{ height: "44.5vh", paddingBottom: 20, minWidth: 400, minHeight: 303 }}>
          <div className='m-t-10 m-l-10'>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Heading>Your attendance today</Heading>
              <div className="cursor-pointer" onClick={() => { setIsModalVisible(true) }} style={{ color: "#1089FF", marginRight: "20px", marginTop: "5px", opacity: attendanceDataContainer?.webclockin ? 1 : 0.5 }}>FORGOT TO CLOCK IN/OUT?</div>
            </div>
            <div style={{ fontSize: "10px", color: "#636363" }}>{moment().format('DD-MM-YYYY')}{" "}●{" "}{moment().format('dddd')}</div>
          </div>

          {attendanceDataContainer?.webclockin == true ?
            <AttendanceToday user={user} attendanceDataContainer={attendanceDataContainer} Location={Location} setMarkAttendance={setMarkAttendance} markAttendance={markAttendance} setLoader={setLoader} loaders={loaders} disabledDate={disabledDate} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} /> :
            (<>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ marginLeft: "25px" }}>
                    <div style={{ display: "flex" }}>
                      <img src={icclockin}></img>
                      <div style={{ marginLeft: "5px", marginTop: "10px" }}>
                        <div style={{ fontSize: "13px", fontWeight: "bold" }}>{attendanceDataContainer?.attendanceTodayWeb?.startTime == null ? '00:00:00' : attendanceDataContainer?.attendanceTodayWeb?.startTime}</div>
                        <div style={{ fontSize: 12 }}>Clock in</div>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <img src={icclockout}></img>
                      <div style={{ marginLeft: "5px", marginTop: "10px" }}>
                        <div style={{ fontSize: "13px", fontWeight: "bold" }}>{attendanceDataContainer?.attendanceTodayWeb?.endTime == null ? '00:00:00' : attendanceDataContainer?.attendanceTodayWeb?.endTime}</div>
                        <div style={{ fontSize: 12 }}>Clock out</div>
                      </div>
                    </div>

                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <div style={{ display: "flex", width: '60%' }}>
                      <img src={icgeofence}></img>
                      <div style={{ marginLeft: "10px", marginTop: 10, width: "90%" }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 13, fontWeight: "bold", color: "#636363", marginLeft: 6, width: 180 }}>{attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInTitle}</Typography.Text>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 13, color: "#636363", marginLeft: 6 }}>{attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInAddress == null ? "--" : attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInAddress}</Typography.Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "15px", width: '50%' }}>
                      <img src={icgeofence} style={{ width: 70 }}></img>
                      <div style={{ marginLeft: "10px", marginTop: 10, width: '100%' }}>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 13, fontWeight: "bold", color: "#636363", marginLeft: 6, width: 180 }}>{attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockOutTitle}</Typography.Text>
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 13, color: "#636363", marginLeft: 6 }}>{attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInAddress == null ? "--" : attendanceDataContainer?.attendanceTodayWeb?.geoFenceClockInAddress}</Typography.Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginLeft: "25px" }}>
                  <div style={{ display: "flex", marginTop: "10px", width: '60%' }}>
                    <img style={{ width: 70 }} src={icgeofence}></img>
                    <div style={{ marginLeft: "10px", width: '70%', marginTop: "10px" }}>
                      <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 13, fontWeight: "bold", color: "#636363", marginLeft: 6, width: "100%" }}>{attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences[0]?.geoFenceTitle}</Typography.Text>
                      <div>Active location(s)</div>
                    </div>
                  </div>
                  <div className='cursor-pointer' style={{ marginTop: "40px", color: "#1089FF", fontSize: "14px", fontWeight: "bold", marginLeft: "10px" }} onClick={() => { setIsLocationModal(true) }}>VIEW MORE</div>
                </div>
              </div></>)}
        </Col>
      </Row>
      <div style={{ marginTop: "20px" }}>
        <Heading>Assigned geo-fence location(s)</Heading>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences != '' ? attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences?.slice(0, visibleItems)?.map((Geofence) => {
            return (
              <div style={{}}>
                <GeofenceCard title={Geofence?.geoFenceTitle} subtitle={Geofence?.geoFenceTitle} totalDuration={null} geoFenceType={Geofence?.geoFenceType} address={Geofence?.geoFenceAddress} geoEnd={Geofence?.geoEnd} geoStart={Geofence?.geoStart} />
              </div>
            )
          })
            :
            (
              <div style={{ marginTop: "10px", marginLeft: "200px" }}>
                <NoDataCard />
              </div>
            )
          }
        </div>
        {attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences?.length > visibleItems &&
          <center><Button onClick={loadMore} type='link' style={{ fontSize: 14 }}>LOAD MORE</Button></center>
        }
      </div>

      <Row style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', width: '98%' }}>
        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 14 }} className='container boxShadow118  m-t-10 p-20' style={{ height: "45vh", paddingBottom: 20, minHeight: 280, minWidth: 400, width: 320 }}>

          <div className='r-c-sb'>
            <div>
              <Heading>Weekly Summary</Heading>
              <div className='flex-row' style={{ color: "#636363" }}>

                <div >
                  <CaretLeftOutlined onClick={() => { handleLeftOutCaret() }} />
                </div>

                {(user?.privilage.includes("SuperAdmin") || user?.privilage.includes("LeaveManager") || user?.role?.includes("Teacher") || user?.role?.includes("Data Operator")) || user?.role?.includes('Operation') ?
                  <div style={{ color: "#636363", fontSize: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{moment(filterValues?.startDate).format('DD-MM-YYYY')} - {moment(filterValues?.endDate).format('DD-MM-YYYY')}</div>
                  : null}

                {!(filterValues?.startDate === moment().startOf('week').format("YYYY-MM-DD")
                  &&
                  filterValues?.endDate === moment().endOf('week').format("YYYY-MM-DD"))
                  ?
                  <div>
                    <CaretRightOutlined onClick={() => { handleRightOutCaret() }} />
                  </div>
                  :
                  null}
              </div>
            </div>
            <div style={{ width: "260px", paddingRight: '20px', paddingTop: '10px' }}>
              <RangePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                value={[(moment(filterValues?.startDate)), (moment(filterValues?.endDate))]}
                onChange={handleDates}
                format={dateFormat}
              />
            </div>
          </div>
          {attendanceDataContainer?.weeklyAttendance && attendanceDataContainer?.weeklyAttendance?.length != 0 ?
            <div className='m-t-20' style={{ height: "30vh", paddingLeft: "30px" }}>
              {sum != 0 ?
                <WeeklyAttendanceGraph
                  data={attendanceDataContainer?.weeklyAttendance?.length != 0 ? attendanceDataContainer?.weeklyAttendance : []}
                  color={"#28DF99"} />
                :
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: -20 }}><NoDataCard /></div>
              }
            </div>
            :
            null}
        </Col>

        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 9 }} className='container boxShadow118  m-t-10 p-20' style={{ height: "45vh", paddingBottom: 20, minWidth: 300, minHeight: 290 }}>
          <div className='m-t-10 m-l-10'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Heading>Monthly Summary</Heading>
              <div style={{ marginRight: 8, zIndex: 1 }}>
                <DatePicker
                  allowClear={false}
                  picker={'month'}
                  value={moment(storeMonthlySummaryMonth, 'MMM-YY')}
                  disabledDate={disabledYearAndMonth}
                  onChange={handleOnChange}
                  format={"MMM-YY"}
                />
              </div>
            </div>
            <div style={{ fontSize: "10px", color: "#636363" }}>{attendanceDataContainer?.monthlyAttendance?.month}</div>
          </div>
          {attendanceDataContainer?.monthlyAttendance && Object.keys(attendanceDataContainer?.monthlyAttendance ? attendanceDataContainer?.monthlyAttendance : {})?.forEach(item => { item += item })}
          <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {attendanceDataContainer?.monthlyAttendance && Object.keys(attendanceDataContainer?.monthlyAttendance)?.length !== 0 ?
              <DoughnuttCard data={monthlySummaryData} percentage={monthlySummaryPercentage} /> :
              <NoDataCard />}</div>
        </Col>
      </Row>

      <Row style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', width: '98%' }}>

        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 9 }} className='container boxShadow118 m-t-10 p-20' style={{ height: "fit-content", minHeight: 300, width: 420 }}>
          <HolidayCalendar calendarData={calendarData} leftClick={() => handleCalendarLeftClick()} rightClick={() => handleCalendarRightClick()} date={date} user={user} history={history} setOthersSelectedTab={setOthersSelectedTab} setSpecificUserId={setSpecificUserId} />
          <center><Button type='link' onClick={() => { setSpecificUserId(user?.id); setOthersSelectedTab('Holidays'); history.push({ pathname: '/user-profile/others' }); }}> VIEW CALENDAR </Button></center>
        </Col>

        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 14 }} className='container boxShadow118 m-t-10 p-20' style={{ height: "54vh", paddingBottom: 20, minHeight: 290, minWidth: 270 }}>
          <Heading>Leave requests</Heading>
          <div className='flex-column scroll-bar-universal' style={{ height: 300, padding: '10px 0 10px 0' }}>
            {attendanceDataContainer?.upcomingLeaveOrRequest?.length != 0 ? attendanceDataContainer?.upcomingLeaveOrRequest?.map((item, index) => (
              <>
                <UpcomingLeaveRequests item={item} />
                {index !== attendanceDataContainer?.upcomingLeaveOrRequest?.length - 1 ? <div style={{ borderBottom: '1px solid #AEAEAE', width: '100%' }}></div> : null}
              </>
            ))
              :
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><NoDataCard /></div>
            }
            <EditLeaves
              loaders={loaders}
              setEditLeave={setEditLeave}
              AttendanceTab={AttendanceTab}
              editLeaveMeta={idSelectedStore}
              editLeaveModal={editLeaveModal}
              selectedLeaves={selectedLeaves(data)}
              isEditModalVisible={isEditModalVisible}
              storeSelectedLeaves={storeSelectedLeaves}
              setisEditModalVisible={setisEditModalVisible}
            />
          </div>
          <center><Button type='link' style={{ flexWeight: 600 }} onClick={() => { setOthersSelectedTab('Leaves'); history.push({ pathname: '/user-profile/others', state: { fromWhere: 'attendanceProfile' } }); }}>VIEW ALL</Button></center>
        </Col>
      </Row>

      {isLeaveRequest &&
        <RequestLeavesModel
          user={user}
          loaders={loaders}
          leaveRequest={leaveRequest}
          AttendanceTab={AttendanceTab}
          isLeaveRequest={isLeaveRequest}
          setLeaveRequest={setLeaveRequest}
          leaveRequestObj={leaveRequestObj}
          setIsLeaveRequest={setIsLeaveRequest}
          getAllLeave={getAllLeave}
          setAllLeaves={setAllLeaves}
          togglePageLoader={togglePageLoader}
          getAttendanceDashBoardData={getAttendanceDashBoardData}
          getCaluclatedDates={getCaluclatedDates}
          storeNoOfDay={storeNoOfDay}
          storeNumberOfDays={storeNumberOfDays}
        />
      }
      {<LocationDetailModal
        isLocationModal={isLocationModal}
        setIsLocationModal={setIsLocationModal}
        locationDetail={attendanceDataContainer?.attendanceTodayWeb?.AllocatedGeoFences}
      />}
    </>
  )
}

const mapStateToProps = (state) => {
  const { attendanceDataContainer, loaders, filterValues, AttendanceTab, leaveRequestObj, editLeaveMeta, storeSelectedLeaves, summaryEndDate, summaryStartDate, setAllLeaves, idSelectedStore, storeMonthlySummaryMonth, storeNoOfDay } = state.attendanceNew;
  const { user } = state.session;
  return { attendanceDataContainer, user, loaders, filterValues, leaveRequestObj, AttendanceTab, editLeaveMeta, storeSelectedLeaves, summaryEndDate, summaryStartDate, setAllLeaves, idSelectedStore, storeMonthlySummaryMonth, storeNoOfDay };
};
const mapDispatchToProps = (dispatch) => ({
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  selectedLeaves: (val) => (dispatch(selectedLeaves(val))),
  setEditLeave: (key, val) => dispatch(setEditLeave(key, val)),
  leaveRequest: (callBack, successCallBack, fromWhere) => dispatch(leaveRequest(callBack, successCallBack, fromWhere)),
  getLocation: (val1, val2) => (dispatch(getLocation(val1, val2))),
  markAttendance: (callBack) => dispatch(markAttendance(callBack)),
  setFilterValues: (key, val) => dispatch(setFilterValues(key, val)),
  editLeaveModal: (callBack) => dispatch(editLeaveModal(callBack)),
  setLeaveRequest: (key, val) => dispatch(setLeaveRequest(key, val)),
  selectEditLeaveCardId: (id) => dispatch(selectEditLeaveCardId(id)),
  setMarkAttendance: (key, val) => dispatch(setMarkAttendance(key, val)),
  getAttendanceDashBoardData: (callBack) => (dispatch(getAttendanceDashBoardData(callBack))),
  setLocation: (callback, successCallBack) => (dispatch(setLocation(callback, successCallBack))),
  storeSummaryStartDate: (val) => dispatch(storeSummaryStartDate(val)),
  storeSummaryEndDate: (val) => dispatch(storeSummaryEndDate(val)),
  getAllLeave: (callBack) => dispatch(getAllLeave(callBack)),
  deleteLeaveReq: (callBack, successCallBack) => dispatch(deleteLeaveReq(callBack, successCallBack)),
  idSelected: (val) => dispatch(idSelected(val)),
  storeMonthlySelectedMonth: (val) => dispatch(storeMonthlySelectedMonth(val)),
  roleFilter: (val) => dispatch(roleFilter(val)),
  getCaluclatedDates: () => dispatch(getCaluclatedDates()),
  storeNumberOfDays: (val) => dispatch(storeNumberOfDays(val)),
  setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),


});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceHome);

{/* <RequestHistoryCard
                    user={user}
                    data={item}
                    istrue={istrue}
                    loaders={loaders}
                    status={item.status}
                    endDate={item.endDate}
                    duration={item.duration}
                    leaveType={item.leaveType}
                    startDate={item.startDate}
                    requestType={item.requestType}
                    selectedLeaves={selectedLeaves}
                    isEditModalVisible={isEditModalVisible}
                    storeSelectedLeaves={storeSelectedLeaves}
                    setisEditModalVisible={setisEditModalVisible}
                    deleteLeaveReq={deleteLeaveReq}
                    selectEditLeaveCardId={selectEditLeaveCardId}
                    togglePageLoader={togglePageLoader}
                    getAttendanceDashBoardData={getAttendanceDashBoardData}
                    editLeaveMeta={attendanceDataContainer?.upcomingLeaveOrRequest[index]}
                    idSelected={idSelected}
                  /> */}