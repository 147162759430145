import React from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import { submitModalFun } from "actions/mobileTest";
import { ArrowRightOutlined} from '@ant-design/icons';
import { timerOverModalFun, studentTestUpdateAns, postStudentTest } from "actions/mobileTest";
import { Button } from 'antd';
import { MdTimer } from "react-icons/md";
import "antd/lib/modal/style/index";
import { Utils } from "services";

class TimerOverModal extends React.Component{

  hitActionButton = (quesIndex, jump, history) => {
    const {studentTestAnsObj, studentTestQuesArr, studentTestQuesIndex, testUploadedBase64Arr, testUploadedImgArrNew, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal} = this.props;
    this.props.studentTestUpdateAns();
    var params;
    if(studentTestQuesArr[studentTestQuesIndex].type === 'Image'){
        let imgObjArr = [];
        testUploadedBase64Arr.map((key, index) => {
          testUploadedImgArrNew.map((key1, index1) => {
            if(key1.localImg === key.localImg){
              if(key.apiImg === ''){
                imgObjArr.push(key)
              }
            }
          })
        })
        
        let duplicateObj = {};
        for (let i=0, len=imgObjArr.length; i < len; i++ ){
            duplicateObj[imgObjArr[i]['localImg']] = imgObjArr[i];
        }
        imgObjArr = new Array();
        for (let key in duplicateObj ){
          imgObjArr.push(duplicateObj[key].localImgObj);
        }
        
       params = {
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        files: imgObjArr,
        deletedFiles: JSON.stringify(testUploadedImgdeleteArr),
        testStatus: jump === 'submit' ? 'Submitted' : 'Draft',
      }
    }else{
       params = {
        answer: studentTestAnsObj.answer,
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        testStatus: jump === 'submit' ? 'Submitted' : 'Draft',
      }
    }
    console.log('params********', params);
    const type = studentTestQuesArr[studentTestQuesIndex].type === 'Image' ? 'image' : 'mcq';
    this.props.postStudentTest(params, type, (response) => {
        // history.push('/web-test');
        this.props.timerOverModalFun(true);
    });
  }

  render(){
    const {history, timerOverModalBool, studentTestQuesIndex, studentTestDurationInSec, studentTestResultPublishDate } = this.props;
    
    return(
      <Modal
        className="autoSaveModal NutinFont"
        centered
        visible={timerOverModalBool}
        bodyStyle={{
          padding: 0,
          borderRadius: 15,
          textAlign: "center",
          margin:'-10px'
        }}
        closable={false}
        width={1000}
        style={{ maxWidth: "calc(100vw - 16px)" }}
        footer={null}
      >
        <div className='p-10 text-center'>
          <div className="r-c-c m-t-10">
            <div className="r-ac">
              <MdTimer className="text-md seaGreen"/>
              <div className='higrey m-l-5 text-md bold-600'>00:00:00</div>
            </div>
          </div>
          <div className="text-sm m-t-20 m-b-10 color-black bold-700">You are out of time!</div>
          <div className="higrey text-xmd m-b-20">
            Sorry! Time is up but your answers have been submitted successfully. 
          </div>
          <Button onClick={() => history.push('/web-test')} className='mobileTestGreenBtnWrap m-b-30' >HOME <ArrowRightOutlined /></Button>
          <div onClick={() => {}} className='p-10' style={{background:'#EAFCF5', borderRadius:'0px 0px 15px 15px', margin:'-10px'}}>
            <div className="text-sm color-black bold-700">Your result will be published on</div>
            <div className="text-xmd higrey">{studentTestResultPublishDate}</div>
          </div>
        </div>
        
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { timerOverModalBool, studentTestQuesArr, studentTestAnsObj, studentTestQuesIndex,
    testUploadedBase64Arr, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal, 
    studentTestResultPublishDate, testUploadedImgArrNew  } = state.mobileTest;
  return {
    timerOverModalBool, studentTestQuesArr, studentTestAnsObj, studentTestQuesIndex,
      testUploadedBase64Arr, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal, 
      studentTestResultPublishDate, testUploadedImgArrNew
  };
};

const mapDispatchToProps = dispatch => ({
  timerOverModalFun: (bool) => dispatch(timerOverModalFun(bool)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  postStudentTest: (params, type, callback) => dispatch(postStudentTest(params, type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerOverModal);
