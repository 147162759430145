import React from 'react';
// import {Progress} from 'antd';
import '../design/layout.css';
import '../design/assignment.css';
import '../Assets/fonts/css/icon-assignment.css';
import { Progress } from 'antd';
import {FiList} from 'react-icons/fi'

const carouselIc = require('../Assets/carouselIc.svg').default;


export const TestTopBar = ({
  title, isStarted, listMode, changeMode, lastItem, backPress, percent, precentshow
}) => {
  return (
  <div className='p-10 sticky top0 whiteBack shadowTopBar' style={{zIndex:precentshow ? 0 : 100}}>
    <div
      className='r-c-sb color-black text-sm '>
      <i onClick={backPress} className='icon-ic-arrow-back text-sm color-black' />
      <div>
        {title}
      </div>
      {!isStarted ?
        <div>
          {lastItem}
        </div>
        : listMode ?
        <FiList onClick={() => changeMode()} /> :
        <img
          onClick={() => changeMode()}
          src={carouselIc}
          style={{width: '1.2em'}}
          alt=""
        />
      }
    </div>
    {precentshow
      ?
        <Progress
          className='student-test-progressbar'
          style={{position:'absolute', left:'0px', right:'0px'}}
          trailColor=''
          strokeColor='#28DF99'
          percent={percent}
          size="small"
          status="active"
          showInfo={false}/>
      :
      null
    }
  </div>
)}
