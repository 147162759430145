import { Color } from '../../../services';


const Styles = {
  attendanceStatCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center',
    width: 65,
    borderRadius:10,
    height: 80,
    marginLeft:10,
    border: '1px solid lightgray'
  },
  calendar: {
    backgroundColor: '#FFF',
    fontSize: 10,
    width:'90%'
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.lightpurple,
    padding:10,
    borderRadius: 5,
    width: 40,
    height: 50,
    marginTop:10
  },
  singleSubBox: {
    width: '95%',
    display: 'flex',
    backgroundColor: Color.lightGrey,
    marginTop: 10,
    padding: 10,
    borderRadius: 5
  },
  singleScheculeBox: {
    flex: 9,
    display: 'flex',
    backgroundColor: Color.lightGrey,

    padding: 5,
    paddingRight: 0,
    borderRadius: 5
  },
  marker: {
    marginLeft: -20,
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    height:20,
    width: 20,
    borderRadius:20
  },
  secBox: {
    backgroundColor: Color.lightpurple,
    padding:5,
    borderRadius: 5,
    fontSize: 16
  },
  activeSubject: {
    backgroundColor: Color.lightGrey
  },
  singleScheduleBox: {
    border:'1px solid lightgray ',
    padding:'15px 10px',
    borderRadius:10,
     margin: 10,
     marginTop: 0,
  }
}

export default Styles;
