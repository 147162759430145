import { Modal } from 'antd';
import React from 'react';
import "./styles.css";
import { Information, ModalHeading, SmallInfo } from 'components/Typography';
import { Color } from 'services';

export const renderCardContent = (label, value, width) => (
    <div className="light-purple-card-modal flex-column" style={{ width: width }}>
        <SmallInfo color={Color.lightBlack}>{label}</SmallInfo>
        <Information color={Color.black} bold className="m-t-5">{value}</Information>
    </div>
);

function RecentAddedCourseModal({ isCourseModalVisible, setIsCourseModalVisible, courseType, courseName, fee, installments, createdBy, createdAt }) {
    const handleCancel = () => {
        setIsCourseModalVisible(false);
    };

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isCourseModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <ModalHeading style={{ marginTop: -10 }}>{courseType} created</ModalHeading>
            <div className="m-t-10">
                {renderCardContent("Course name", courseName, "100%")}
            </div>
            <div className="r-jsb m-t-10" style={{ width: "100%" }}>
                {renderCardContent("Fee", fee, "100%")}
            </div>
            <div className="r-jsb m-t-10" style={{ width: "100%" }}>
                {createdBy ? renderCardContent("Created by", createdBy, "49%") : null}
                {renderCardContent("Created on", createdAt, createdBy ? "49%" : "100%")}
            </div>
        </Modal>
    );
}

export default RecentAddedCourseModal;