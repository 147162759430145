import Axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { Api } from "services";
import { setOnlineTestSeriesId } from "./marketTest";
import { updateSelectedSection } from "./classRoom";

export const SET_COURSE_TYPE_NAME = 'SET_COURSE_TYPE_NAME';
export const SET_CREATE_COURSE_OBJ = "SET_CREATE_COURSE_OBJ";
export const STORE_COURSE_CATEGORY_FOR_FEE = "STORE_COURSE_CATEGORY_FOR_FEE";
export const STORE_COURSE_CATEGORY = "STORE_COURSE_CATEGORY";
export const STORE_COURSE_SUB_CATEGORY = 'STORE_COURSE_SUB_CATEGORY';
export const STORE_SPECIFIC_COURSE_DATA = "STORE_SPECIFIC_COURSE_DATA";
export const STORE_SPECIFIC_COURSE_ID = "STORE_SPECIFIC_COURSE_ID";
export const SET_DISCOUNT_DETAILS = "SET_DISCOUNT_DETAILS";
export const STORE_COURSE_STATS = "STORE_COURSE_STATS";
export const STORE_TOTAL_COURSES_LIST = "STORE_TOTAL_COURSES_LIST";
export const SET_ALL_COURSES_PAGE_PARAMS_OBJ = "SET_ALL_COURSES_PAGE_PARAMS_OBJ";
export const STORE_ONLINE_TEST_SERIES = "STORE_ONLINE_TEST_SERIES"
export const STORE_SUBCATEGORY_DATA = "STORE_SUBCATEGORY_DATA"
export const STORE_CRM_TABLE_DATA = "STORE_CRM_TABLE_DATA";
export const SET_CRM_PAGE_PARAMS_OBJ = "SET_CRM_PAGE_PARAMS_OBJ";
export const STORE_SRM_COURSE_LIST_DATA = "STORE_SRM_COURSE_LIST_DATA";
export const STORE_TAGS_DATA = "STORE_TAGS_DATA";
export const STORE_AUTOCOMPLETE_SEARCH_DATA = "STORE_AUTOCOMPLETE_SEARCH_DATA";
export const STORE_AUTOCOMPLETE_COURSES_DATA = "STORE_AUTOCOMPLETE_COURSES_DATA"
export const STORE_COURSE_LANGUAGES_DATA = "STORE_COURSE_LANGUAGES_DATA"
export const STORE_SEARCH_RESULT_DATA = "STORE_SEARCH_RESULT_DATA";
export const SET_SEARCH_RESULT_PAGE_PARAMS_OBJ = "SET_SEARCH_RESULT_PAGE_PARAMS_OBJ";
export const STORE_QUIZ_DATA = "STORE_QUIZ_DATA";
export const FILE_KEY_UPDATE = 'FILE_KEY_UPDATE';
export const PDF_UPLOADED = "PDF_UPLOADED";
export const SET_QUIZ_DATA = 'SET_QUIZ_DATA';
export const STORE_THUMBNAILS_DATA = 'STORE_THUMBNAILS_DATA';
export const SET_COURSE_DASHBOARD_DATA = "SET_COURSE_DASHBOARD_DATA";
export const SET_COURSE_ACTIVITY_DATA = "SET_COURSE_ACTIVITY_DATA";
export const APPEND_COURSE_ACTIVITY_DATA = "APPEND_COURSE_ACTIVITY_DATA";
export const SET_STORE_COURSE_GRAPH_DATA = "SET_STORE_COURSE_GRAPH_DATA"
export const SET_STORE_PIE_CHART_DATA = 'SET_STORE_PIE_CHART_DATA';
export const SET_FROM_COURSE_DETAILS_PAGE = "SET_FROM_COURSE_DETAILS_PAGE";
export const STORE_ALL_STUDENTS_DATA = "STORE_ALL_STUDENTS_DATA";
export const SET_ALL_STUDENTS_PAGE_PARAMS = "SET_ALL_STUDENTS_PAGE_PARAMS";
export const SET_PAYMENT_MODAL_PARAMS_OBJ = "SET_PAYMENT_MODAL_PARAMS_OBJ";
export const SET_VIDEO_RESOURCES_QUESTION_ANSWER = "SET_VIDEO_RESOURCES_QUESTION_ANSWER";
export const SET_IS_FROM_ADMIN_PREVIEW = "SET_IS_FROM_ADMIN_PREVIEW";
export const APPEND_ALL_STUDENTS_DATA = "APPEND_ALL_STUDENTS_DATA";
export const APPEND_CRM_TABLE_DATA = "APPEND_CRM_TABLE_DATA";
export const SET_COURSE_STUDENT_ROLL_NO = "SET_COURSE_STUDENT_ROLL_NO";


export const SET_TAB = "SET_TAB"

export const setCourseTypeName = (val) => ({
    type: SET_COURSE_TYPE_NAME,
    val
})
export const updatepdf = (data) => {
    return (dispatch, getState) => {
        dispatch(updatepdfaction(data));
    };
};

const updatepdfaction = (data) => ({
    type: PDF_UPLOADED,
    data,
});
export const setCreateCourseObj = (key, val) => ({
    type: SET_CREATE_COURSE_OBJ,
    key,
    val
})

export const setCourseStudentRollNo = (key, val) => ({
    type: SET_COURSE_STUDENT_ROLL_NO,
    key,
    val
})

export const storeCourseCategories = (val) => ({
    type: STORE_COURSE_CATEGORY,
    val
})

export const storeCourseCategoriesForFee = (val) => ({
    type: STORE_COURSE_CATEGORY_FOR_FEE,
    val
})

export const storeCourseSubCategories = (val) => ({
    type: STORE_COURSE_SUB_CATEGORY,
    val
})

export const storeSpecificCourseData = (val) => ({
    type: STORE_SPECIFIC_COURSE_DATA,
    val
})

export const storeSingleCourseId = (val) => ({
    type: STORE_SPECIFIC_COURSE_ID,
    val
})

export const setDiscountDetails = (val) => ({
    type: SET_DISCOUNT_DETAILS,
    val
})

export const storeCourseStats = (val) => ({
    type: STORE_COURSE_STATS,
    val
})

export const storeTotalCoursesList = (val) => ({
    type: STORE_TOTAL_COURSES_LIST,
    val
})

export const setAllCoursesPageParamsObj = (key, val) => ({
    type: SET_ALL_COURSES_PAGE_PARAMS_OBJ,
    key,
    val
})

export const storeOnlineTestData = (val) => ({
    type: STORE_ONLINE_TEST_SERIES,
    val
})
export const storeSubcategoryData = (val) => ({
    type: STORE_SUBCATEGORY_DATA,
    val
})
export const storeCRMTableData = (val) => ({
    type: STORE_CRM_TABLE_DATA,
    val
})

export const setCRMPageParamsObj = (key, val) => ({
    type: SET_CRM_PAGE_PARAMS_OBJ,
    key,
    val
})

export const storeCrmCourseListData = (val) => ({
    type: STORE_SRM_COURSE_LIST_DATA,
    val
})

export const storeTagsData = (val) => ({
    type: STORE_TAGS_DATA,
    val
})

export const storeAutocompleteSearchData = (val) => ({
    type: STORE_AUTOCOMPLETE_SEARCH_DATA,
    val
})

export const storeAutocompleteCoursesData = (val) => ({
    type: STORE_AUTOCOMPLETE_COURSES_DATA,
    val
})

export const storeCourseLanguages = (val) => ({
    type: STORE_COURSE_LANGUAGES_DATA,
    val
})

export const storeSearchResultData = (val) => ({
    type: STORE_SEARCH_RESULT_DATA,
    val
})

export const setSearchResultPageParamsObj = (key, val) => ({
    type: SET_SEARCH_RESULT_PAGE_PARAMS_OBJ,
    key,
    val
})

export const storeQuizData = (val) => ({
    type: STORE_QUIZ_DATA,
    val
})

export const storeThumnailsData = (val) => ({
    type: STORE_THUMBNAILS_DATA,
    val
})

export const storeAllStudentsData = (val) => ({
    type: STORE_ALL_STUDENTS_DATA,
    val
})

export const setAllStudentsPageParam = (key, val) => ({
    type: SET_ALL_STUDENTS_PAGE_PARAMS,
    key,
    val
})

export const getCoursesCategories = (callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, courseTypeName } = getState().courseManagement;
        const params = {
            sort: searchPageParamsObj?.sort,
            feeType: searchPageParamsObj?.feeType,
            mode: searchPageParamsObj?.mode,
            durationFilter: searchPageParamsObj?.durationFilter,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            resourceType: courseTypeName == "liveCourses" ? "course" : courseTypeName == "videoResources" ? "videoResource" : courseTypeName == "studyMaterials" ? "studyMaterial" : courseTypeName == "onlineTestSeries" ? "testSeries" : null
        }
        return Api.get(`/course/categories`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeCourseCategories(response));
                if (successCallBack) {
                    successCallBack(response);
                }
            }
        })
    }
}

export const getCoursesCategoriesForFee = (callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, courseTypeName } = getState().courseManagement;
        const params = {
            withChildren : true,
            sort: searchPageParamsObj?.sort,
            feeType: searchPageParamsObj?.feeType,
            mode: searchPageParamsObj?.mode,
            durationFilter: searchPageParamsObj?.durationFilter,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            resourceType: courseTypeName == "liveCourses" ? "course" : courseTypeName == "videoResources" ? "videoResource" : courseTypeName == "studyMaterials" ? "studyMaterial" : courseTypeName == "onlineTestSeries" ? "testSeries" : null
        }
        return Api.get(`/course/categories`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeCourseCategoriesForFee(response));
                if (successCallBack) {
                    successCallBack(response);
                }
            }
        })
    }
}

export const getCoursesSubCategories = (id, callBack = null) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, courseTypeName } = getState().courseManagement;
        const params = {
            sort: searchPageParamsObj?.sort,
            feeType: searchPageParamsObj?.feeType,
            mode: searchPageParamsObj?.mode,
            durationFilter: searchPageParamsObj?.durationFilter,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            resourceType: courseTypeName == "liveCourses" ? "course" : courseTypeName == "videoResources" ? "videoResource" : courseTypeName == "studyMaterials" ? "studyMaterial" : courseTypeName == "onlineTestSeries" ? "testSeries" : null
        }
        return Api.get(`/course/subcategories`).params({ ...params, id }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeCourseSubCategories(response))
            }
        })
    }
}

export const getOnlineTestSeries = (callBack = null) => {
    return (dispatch, getState) => {
        const { allCoursesPageParamsObj, searchPageParamsObj } = getState().courseManagement;
      
        const params = {

            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            feeType: searchPageParamsObj?.feeType,
        }
        return Api.get(`/testSeries/test-series-list`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSubcategoryData(response))
            }
        })
    }
}
export const UploadCourseDocument = (file, isType, value, successCallback) => {
    return (dispatch) => {
        const params = {
            file,
            courseType: value,
            type: isType
        }
        return Api.post(`/course/courseFile`).upload(params, (percent, response) => {
            if (response !== undefined) {
                if (successCallback) {
                    successCallback(response?.data?.key)
                }
            }
        });

    }
}
export const deleteCourseDocument = (key, isType) => {
    return dispatch => {
        const params = {
            key,
            type: isType
        };
        return Api.delete("/course/courseFile")
            .params(params)
            .send((response, error) => {
                if (typeof response != "undefined") {
                    if (!response.length) {
                    } else {
                    }
                }
            });
    };
};


export const deleteCrmtable = (id, callback = null, successCallback = null) => {
    let params = {
        id: id
    }

    return (dispatch, getState) => {
        // const { } = getState().userManagementV2;
        return Api.delete(`/market/v2/removeEnquiryDetails`).params(params).send((response) => {
            if (response && response.show.type === "success") {
                successCallback();
            }
            callback();
        });
    };
};
const returnNames = (val) => {
    if (val == "30") {
        return "Month(s)";
    } else if (val == "360" || val == "365") {
        return "Year(s)";
    } else if (val == "1") {
        return "Day(s)";
    }
};
export const postLiveCourseCreate = (callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { courseCreateObj, specificCourseDataContainer } = getState().courseManagement;
        const filteredLearning = courseCreateObj?.learnings?.filter(learning => learning?.name !== "")
        const filteredPrerequisites = courseCreateObj?.prerequisites?.filter(prerequisite => prerequisite?.name !== "")
        const filteredFeatures = courseCreateObj?.features?.filter(feature => feature?.name !== "")
        let UpdatedDeletedLearnings = [...courseCreateObj?.deletedCourseLearnings];
        courseCreateObj?.learnings?.forEach(learning => {
            if (learning?.name === "" && learning?.id) {
                UpdatedDeletedLearnings.push(learning.id);
            }
        });
        let UpdatedDeletedPrerequisites = [...courseCreateObj?.deletedCoursePrerequisites];
        courseCreateObj?.prerequisites?.forEach(prerequisite => {
            if (prerequisite?.name === "" && prerequisite?.id) {
                UpdatedDeletedPrerequisites?.push(prerequisite.id);
            }
        });
        let UpdatedDeletedFeatures = [...courseCreateObj?.deletedCourseFeatures];
        courseCreateObj?.features?.forEach(feature => {
            if (feature?.name === "" && feature?.id) {
                UpdatedDeletedFeatures?.push(feature.id);
            }
        });
        const tagsIdsOnly = courseCreateObj?.tags.map(tag => tag.id);
        
        const params = {
            ...courseCreateObj,
            id: courseCreateObj?.id ? courseCreateObj?.id : null,
            durationType: returnNames(courseCreateObj?.durationType),
            categoryId: courseCreateObj?.categoryId?.id,
            subcategoryId: courseCreateObj?.subcategoryId?.id,
            vrTeacherId: parseInt(courseCreateObj?.vrTeacherId?.id),
            learnings: filteredLearning,
            prerequisites: filteredPrerequisites,
            features: filteredFeatures,
            preview: courseCreateObj?.previewType == "link" ? courseCreateObj?.previewLink : courseCreateObj?.preview,
            tags: tagsIdsOnly,
            recordVideoResourceName: courseCreateObj?.recordVideoResourceName,
            skipSubjects: true,
            discount: specificCourseDataContainer?.course?.discount || 0,
            discountType: specificCourseDataContainer?.course?.discountType || "amount",
            amount: specificCourseDataContainer?.course?.amount || 0,
            registrationFee: specificCourseDataContainer?.course?.registrationFee || 0,
            deletedCourseLearnings: UpdatedDeletedLearnings,
            deletedCoursePrerequisites: UpdatedDeletedPrerequisites,
            deletedCourseFeatures: UpdatedDeletedFeatures,
            langId:courseCreateObj?.langId || 1,
            totalSeats:courseCreateObj?.totalSeats ? courseCreateObj?.totalSeats : 0,
            teacherIds:courseCreateObj?.teacherIds?.map(item=>parseInt(item.id)),
            vrTeacherIds:courseCreateObj?.vrTeacherIds?.map(item=>parseInt(item.id))
        }

        delete courseCreateObj?.previewLink
        return Api.post('/course/updateCourse')
            .params(params)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    dispatch(storeSingleCourseId(response?.courseId))
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };

export const postVideoResourceCreate = (callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { courseCreateObj, specificCourseDataContainer } = getState().courseManagement;
        const filteredLearning = courseCreateObj?.learnings?.filter(learning => learning?.name !== "")
        const filteredPrerequisites = courseCreateObj?.prerequisites?.filter(prerequisite => prerequisite?.name !== "")
        const filteredDetails = courseCreateObj?.details?.filter(detail => detail?.name !== "")
        const filteredFeatures = courseCreateObj?.features?.filter(feature => feature?.name !== "")
        const tagsIdsOnly = courseCreateObj?.tags.map(tag => tag.id);
        delete courseCreateObj?.mode
        delete courseCreateObj?.duration
        delete courseCreateObj?.durationType
        if (!courseCreateObj?.id) {
            delete courseCreateObj?.id
        }
        let UpdatedDeletedLearnings = [...courseCreateObj?.deletedCourseLearnings];
        courseCreateObj?.learnings?.forEach(learning => {
            if (learning?.name === "" && learning?.id) {
                UpdatedDeletedLearnings.push(learning.id);
            }
        });
        let UpdatedDeletedPrerequisites = [...courseCreateObj?.deletedCoursePrerequisites];
        courseCreateObj?.prerequisites?.forEach(prerequisite => {
            if (prerequisite?.name === "" && prerequisite?.id) {
                UpdatedDeletedPrerequisites?.push(prerequisite.id);
            }
        });
        let UpdatedDeletedFeatures = [...courseCreateObj?.deletedCourseFeatures];
        courseCreateObj?.features?.forEach(feature => {
            if (feature?.name === "" && feature?.id) {
                UpdatedDeletedFeatures?.push(feature.id);
            }
        });
        let UpdatedDeletedDetails = [...courseCreateObj?.deletedCourseDetails];
        courseCreateObj?.details?.forEach(detail => {
            if (detail?.name === "" && detail?.id) {
                UpdatedDeletedDetails?.push(detail.id);
            }
        });
        delete courseCreateObj?.teacherId
        const newObj = {
            ...courseCreateObj,
            name: courseCreateObj?.courseName,
            description: courseCreateObj?.courseDescription,
            categoryId: courseCreateObj?.categoryId?.id,
            subcategoryId: courseCreateObj?.subcategoryId?.id,
            teacherId: courseCreateObj?.teacherId?.id,
            learnings: filteredLearning,
            prerequisites: filteredPrerequisites,
            details: filteredDetails,
            features: filteredFeatures,
            preview: courseCreateObj?.previewType == "link" ? courseCreateObj?.previewLink : courseCreateObj?.preview,
            tags: tagsIdsOnly,
            skipSections: true,
            discount: specificCourseDataContainer?.discount || 0,
            discountType: specificCourseDataContainer?.discountType || "amount",
            amount: specificCourseDataContainer?.amount || 0,
            registrationFee: specificCourseDataContainer?.registrationFee || 0,
            deletedLearnings: UpdatedDeletedLearnings,
            deletedPrerequisites: UpdatedDeletedPrerequisites,
            deletedCourseFeatures: UpdatedDeletedFeatures,
            deletedDetails: UpdatedDeletedDetails,
            langId:courseCreateObj?.langId || 1,
            teacherIds:courseCreateObj.teacherIds.map(item=>parseInt(item.id)),
           
        }
        return Api.post('/course/updateVideoResource').params(newObj).send((response) => {
            if (callback) {
                callback()
            }
            if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                dispatch(storeSingleCourseId(response?.vrId))
                if (successCallBack) {
                    successCallBack();
                }
            }
        })
    };


export const getSpecificVideoCourseData = (callback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        return Api.get(`/course/video-resource`).params({ id: newCourseId }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSpecificCourseData(response))
            }
        })
    }
}

export const postStudyMaterialsCreate = (callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { courseCreateObj, specificCourseDataContainer } = getState().courseManagement;
        const filteredLearning = courseCreateObj?.learnings?.filter(learning => learning?.name !== "")
        const filteredFeatures = courseCreateObj?.features?.filter(feature => feature?.name !== "")
        delete courseCreateObj?.mode
        delete courseCreateObj?.brief
        // delete courseCreateObj?.image
        delete courseCreateObj?.duration
        delete courseCreateObj?.durationType
        delete courseCreateObj?.teacherId
        delete courseCreateObj?.prerequisites
        delete courseCreateObj?.details
        delete courseCreateObj?.preview
        if (!courseCreateObj?.id) {
            delete courseCreateObj?.id
        }
        const tagsIdsOnly = courseCreateObj?.tags.map(tag => tag.id);
        let UpdatedDeletedLearnings = [...courseCreateObj?.deletedCourseLearnings];
        courseCreateObj?.learnings?.forEach(learning => {
            if (learning?.name === "" && learning?.id) {
                UpdatedDeletedLearnings.push(learning.id);
            }
        });
        let UpdatedDeletedFeatures = [...courseCreateObj?.deletedCourseFeatures];
        courseCreateObj?.features?.forEach(feature => {
            if (feature?.name === "" && feature?.id) {
                UpdatedDeletedFeatures?.push(feature.id);
            }
        });

    
        const newObj = {
            ...courseCreateObj,
            name: courseCreateObj?.courseName,
            description: courseCreateObj?.courseDescription,
            categoryId: courseCreateObj?.categoryId?.id,
            subcategoryId: courseCreateObj?.subcategoryId?.id,
            learnings: filteredLearning,
            features: filteredFeatures,
            tags: tagsIdsOnly,
            skipTopics: true,
            discount: specificCourseDataContainer?.discount || 0,
            discountType: specificCourseDataContainer?.discountType || "amount",
            amount: specificCourseDataContainer?.amount || 0,
            registrationFee: specificCourseDataContainer?.registrationFee || 0,
            deletedCourseLearnings: UpdatedDeletedLearnings,
            deletedCourseFeatures: UpdatedDeletedFeatures,
            langId:courseCreateObj?.langId || 1,
            image:courseCreateObj?.image,
            teacherIds:courseCreateObj.teacherIds.map(item=>parseInt(item.id))
        }
        return Api.post(`/course/update-study-material`).uploadMultiple(newObj, (percent, response) => {
            if (callBack) {
                callBack()
            }
            if (typeof response !== 'undefined' && response?.show?.type != 'error') {
                if (successCallBack) {
                    successCallBack();
                }
                dispatch(storeSingleCourseId(response?.data?.smId))
            }
        });
    };

export const getSpecificSMData = (callback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        return Api.get(`/course/study-material`).params({ id: newCourseId }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSpecificCourseData(response))
            }
        })
    }
}

export const getSpecificCourseData = (callback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        return Api.get(`/course`).params({ id: newCourseId }).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSpecificCourseData(response))
            }
        })
    }
}

export const autocompleteAssignStudents = (query, callback) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        const params = {
            name: query,
            courseId: newCourseId
        };
        dispatch(updateStudentsLoader(true))
        return Api.get('/user/userCourseAutocomplete').params(params).send((response, error) => {
            dispatch(updateStudentsLoader(false))
            if (typeof response != "undefined") {
                if (!response.length) {
                    callback();
                } else {
                    callback(response)
                }
            };
        });
    }
}

export const postAssignStudentsToCourse = (students, callback, successCallback) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        const params = {
            students,
            courseId: newCourseId
        }
        const url = `/user/student/assignCourseStudents`;
        return Api.post(url).params(params).send((response, error) => {
            callback()
            if (typeof response != "undefined" && response.show.type === "success") {
                successCallback()
            }
        });
    }
}
const returnPlanNames = (val) => {
    if (val == "30") {
        return "Monthly";
    } else if (val == "90") {
        return "Quarterly";
    } else if (val == "180") {
        return "Half Yearly";
    } else if (val == "360" || val == "365") {
        return "Annually";
    } else if (val == "One time") {
        return "One time";
    } else if (val == "Customize") {
        return "Customize";
    }
    else if (val == "none") {
        return null
    }
};


export const postCreateFeeRegistration = (totalFee, minRegisterFee, discountType, isDiscountValue, callBack = null, succesCallback = null,) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;

        const params = {
            id: newCourseId,
            amount: parseInt(totalFee),
            discountType: discountType,
            discount: isDiscountValue == null || isDiscountValue == '' ? 0 : parseInt(isDiscountValue),
            registrationFee: minRegisterFee == '' ? 0 : parseInt(minRegisterFee)
        }
        const url = `/course/update-fee`;
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}
const returnFeePlanNames = (val) => {

    if (val == "Monthly") {
        return "Monthly";
    } else if (val == "Quarterly") {
        return "Quarterly";
    } else if (val == "Half Yearly") {
        return "Half_Yearly";
    } else if (val == "Annually") {
        return "Annually";
    } else if (val == "One time") {
        return "One_Time";
    } else if (val == "Customize") {
        return "Customize";
    }
    else if (val == "none") {
        return null
    }
};
export const postAddLiveCourseInstallMents = (feeDetails, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { coursePrice, installments, courseBillingPlan } = getState().course;
        const { newCourseId, courseDiscountDetails } = getState().courseManagement;
        let totalDiscount = 0;
        courseDiscountDetails.forEach((item) => {
            totalDiscount += +item.amount;
        });

        // amount include registration fee
        const params = {
            id: feeDetails?.id ? feeDetails?.id : undefined,
            planName: returnFeePlanNames(feeDetails.billingPlan) == 'Customize' ? feeDetails?.planName : undefined,
            billingPlan: returnFeePlanNames(feeDetails.billingPlan),
            installments: feeDetails?.installments,
            defaultPlan: feeDetails?.defaultPlan,
            courseId: newCourseId,
            installmentTotal: parseInt(feeDetails?.installmentTotal)

        };
        return Api.post("/course/update-course-payment-plan")
            .params(params)
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined && response?.show?.type !== 'error') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };

export const postAddFeeInstallments = (registrationFee, discountType, discount, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { coursePrice, installments, courseBillingPlan } = getState().course;
        const { newCourseId, courseDiscountDetails } = getState().courseManagement;
        let totalDiscount = 0;
        courseDiscountDetails.forEach((item) => {
            totalDiscount += +item.amount;
        });
        // amount include registration fee
        const params = {
            id: newCourseId,
            amount:
                coursePrice && parseFloat(coursePrice) != NaN
                    ? parseFloat(coursePrice)
                    : 0,
            billingPlan: returnPlanNames(courseBillingPlan),
            installmentDetails: JSON.stringify(installments),
            offers: courseDiscountDetails,
            totalOfferDiscount: totalDiscount,
            registrationFee: parseInt(registrationFee),
            discountType: discountType,
            discount: discount,
        };
        return Api.post("/course/update-fee-details")
            .params(params)
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined && response?.show?.type !== 'error') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };
export const postAddFeeStudyMaterial = (discountType, discount, totalFee, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { coursePrice } = getState().course;
        const { newCourseId, courseDiscountDetails, courseTypeName } = getState().courseManagement;
        
        let totalDiscount = 0;
        courseDiscountDetails.forEach((item) => {
            totalDiscount += +item.amount;
        });
        const params = {
            id: newCourseId,
            amount: parseInt(totalFee),
            // offers: courseDiscountDetails,
            // totalOfferDiscount: totalDiscount,
            type: courseTypeName == 'studyMaterials' ? 'studyMaterial'
                : courseTypeName == 'onlineTestSeries' ? 'testSeries' : 'videoResource',
            discountType: discountType,
            discount: discount ? parseInt(discount) : 0,
        };
        return Api.post("/course/update-resource-fee")
            .params(params)
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined && response?.show?.type !== 'error') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };
export const getCourseStats = () => {
    return (dispatch, getState) => {
        return Api.get(`/course/stats`).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeCourseStats(response))
            }
        })
    }
}
export const postCreateCategory = (name, callBack = null, succesCallback = null, sendId, id) => {
    return (dispatch, getState) => {
        const params = {
            name,
            id: sendId ? id : null
        }
        const url = `/course/category`;
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const postCreateSubCategory = (name, callBack = null, succesCallback = null, sendId, id, isEditSubCategoryModal, categoryId) => {
    return (dispatch, getState) => {
        const { courseCreateObj } = getState().courseManagement;
        const params = {
            name,
            id: sendId ? id : null,
            parentId: isEditSubCategoryModal ? categoryId : parseInt(courseCreateObj?.categoryId?.id)
        }
        const url = `/course/subcategory`;
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const appendAllStudentsData = res => ({
    type: APPEND_ALL_STUDENTS_DATA,
    res
})


export const getCourseStudentsData = (callBack = null) => {
    return (dispatch, getState) => {
        const { allStudentsParamsObj } = getState().classRoom;
        const { newCourseId, courseTypeName } = getState().courseManagement;
        const params = {
            search: allStudentsParamsObj?.search,
            page: allStudentsParamsObj?.page,
            limit: allStudentsParamsObj?.limit,
            standardIds: allStudentsParamsObj?.standardIds,
            id: newCourseId,
            resourceType: courseTypeName == 'videoResources' ? 'videoResource' : courseTypeName == 'studyMaterials' ? 'studyMaterial' : courseTypeName == "onlineTestSeries" ? "testSeries" : 'course'
        }
        const url = `/course/v2/course-students`;
        return Api.get(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined") {
                if (courseTypeName == 'onlineTestSeries') {
                    dispatch(updateSelectedSection(response))
                } else {
                    dispatch(storeAllStudentsData(response));
                }
            }
        });
    }
};

export const getTotalCoursesList = (callBack = null) => {
    return (dispatch, getState) => {
        const { allCoursesPageParamsObj, searchPageParamsObj } = getState().courseManagement;
        const params = {
            search: allCoursesPageParamsObj?.search,
            page: allCoursesPageParamsObj?.page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            mode: searchPageParamsObj?.mode,
            durationFilter: searchPageParamsObj?.durationFilter,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        const url = `/course/courses`;
        return Api.get(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined") {
                dispatch(storeTotalCoursesList(response));
            }
        });
    }
};

export const getStudyMaterialsList = (callBack = null) => {
    return (dispatch, getState) => {
        const { allCoursesPageParamsObj, searchPageParamsObj } = getState().courseManagement;
        const params = {
            search: allCoursesPageParamsObj?.search,
            page: allCoursesPageParamsObj?.page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            feeType: searchPageParamsObj?.feeType,
        }
        const url = `/course/study-materials`;
        return Api.get(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined") {
                dispatch(storeTotalCoursesList(response));
            }
        });
    }
};

export const postMoveSubcategories = (categoryId, subcategoryIds, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const params = {
            categoryId,
            subcategoryIds
        }
        const url = `/course/move-subcategories`;
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const postMoveCourses = (subcategoryId, courseIds, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const params = {
            subcategoryId,
            courseIds
        }
        const url = `/course/move-courses`;
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const postMoveStudyMaterial = (subcategoryId, courseIds, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const params = {
            subcategoryId,
            resourceIds: courseIds,
            type: 'studyMaterial'
        }
        const url = `/course/move-resources`;
        return Api.patch(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const deleteCourses = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/courses').params({ courseIds: ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};
export const deleteStudyMaterials = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/study-materials').params({ materialIds: ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};
export const deleteSubCategories = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/subcategories').params({ subcategoryIds: ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};
export const deleteCategories = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/categories').params({ categoryIds: ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};
export const patchPublishCourse = (ids, publish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/courses-status').params({ courseIds: ids, publish }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};


export const patchPublishTest = (ids, publish, callBack = null, successCallBack = null) => {
    const status = (publish) ? "Published" : "Unpublished"

    return (dispatch, getState) => {
        return Api.patch(`/testSeries/testSeriesStatus`).params({ ids, status }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const getTotalsVideoResource = (callBack = null) => {
    return (dispatch, getState) => {
        const { allCoursesPageParamsObj, searchPageParamsObj } = getState().courseManagement;
        const params = {
            search: allCoursesPageParamsObj?.search,
            page: allCoursesPageParamsObj?.page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            feeType: searchPageParamsObj?.feeType,
        }
        const url = `/course/video-resources`;
        return Api.get(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined") {
                dispatch(storeTotalCoursesList(response));
            }
        });
    }
};

export const patchPublishVideoResource = (ids, isPublish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/resources-status').params({ resourceIds: ids, publish: isPublish, type: "videoResource" }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const deleteVideoResource = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/video-resources').params({ resourceIds: ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const deleteOnlineTestSeries = (ids, callBack = null, successCallBack = null) => {
 
    return (dispatch, getState) => {
        return Api.delete(`/testSeries`).params({ testSeriesIds: ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};
export const postMoveOnlineTestSeries = (subcategoryId, courseIds, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const params = {
            subcategoryId: subcategoryId,
            resourceIds: courseIds,
            type: 'testSeries'
        }
        const url = `/course/move-resources`;
        return Api.patch(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const postMoveCourseResources = (subcategoryId, courseIds, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const params = {
            subcategoryId: subcategoryId,
            resourceIds: courseIds,
            type: 'videoResource'
        }
        const url = `/course/move-resources`;
        return Api.patch(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}
export const patchPublishStudyMaterials = (ids, publish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/resources-status').params({ resourceIds: ids, publish, type: 'studyMaterial' }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const postAddQuiz = (quizArray, questionContent, valueTf, callBack = null, succesCallback = null, index) => {
    return (dispatch, getState) => {
        const { quizDataContainer } = getState().courseManagement;

        if (quizArray?.type == 'T/F') {
            questionContent['statement'] = quizArray.questionContent
            delete questionContent?.options
    
        } else {
            questionContent['options'] = quizArray.options
            questionContent['statement'] = quizArray.questionContent
        }

    
        const params = {

            answerContent: { answer: valueTf == 'True' ? true : false },
            ...(quizArray?.id ? { id: quizArray?.id } : null),
            questionContent: questionContent,
            positiveMarks: quizArray?.positiveMarks,
            type: quizArray?.type,
            ...(quizDataContainer?.id ? { quizId: quizDataContainer?.id } : null),
        }

        const url = `/course/update-quiz-question`;
        return Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                dispatch(storeQuizData(response?.quiz))

                if (succesCallback) {
                    succesCallback(response)
                }
            }
        });
    }
}

export const deleteQuestion = (storeId, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { quizDataContainer } = getState().courseManagement;

        return Api.delete('/course/quiz-question').params({ id: quizDataContainer?.questionId ? quizDataContainer?.questionId : storeId }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const postQuizName = (addQuizName, callBack, successCallBack) => {
    return (dispatch, getState) => {
        const { quizDataContainer } = getState().courseManagement;
        const params = {
            name: addQuizName,
            id: quizDataContainer?.id
        }
        return Api.post('/course/update-quiz').params(params).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                // dispatch(storeSingleCourseId(response?.quiz))

                if (successCallBack) {
                    successCallBack()
                }
            }
        });

    }
}

export const postCreateLiveCourseOffers = (new_promo_code, promo_code, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;


        return Api.post('/course/update-offer')
            .params(new_promo_code ? new_promo_code : promo_code)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };

export const postCreateOffers = (publish, selectedCourseType, categoryIds, subcategoryIds, selectedCourse, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { offersObj } = getState().courseManagement;
        const cloneOffersObj = {...offersObj};
        if (cloneOffersObj?.type === "singleCourse") {
            cloneOffersObj.type = selectedCourse?.map(course => course?.itemType).join(', ');
            const idKey = `${cloneOffersObj.type}Id`;
            const idValue = selectedCourse?.find(course => course?.itemType === cloneOffersObj.type)?.itemId;
            cloneOffersObj[idKey] = idValue;
            if(cloneOffersObj.type == 'course'){
                cloneOffersObj.type = 'liveCourse';
            }
        } else if(cloneOffersObj?.type === 'All'){
            cloneOffersObj.type = 'course';
            cloneOffersObj.applicableLive = cloneOffersObj.applicableVideo = cloneOffersObj.applicableStudy = cloneOffersObj.applicableTest = true;
        } else if (selectedCourseType?.length) {
            for (let i = 0; i < selectedCourseType?.length; i++) {
                cloneOffersObj.applicableLive = selectedCourseType?.includes("LIVE COURSES")
                cloneOffersObj.applicableVideo = selectedCourseType?.includes("VIDEO RESOURCES")
                cloneOffersObj.applicableTest = selectedCourseType?.includes("ONLINE TEST SERIES")
                cloneOffersObj.applicableStudy = selectedCourseType?.includes("STUDY MATERIALS")
            }
        }
        const newCategoryIds = categoryIds?.map(category => category?.id);
        const newSubcategoryIds = subcategoryIds?.map(category => category?.id);
        const params = {
            ...cloneOffersObj,
            categoryIds: newCategoryIds,
            subcategoryIds: newSubcategoryIds,
            startDate: cloneOffersObj?.startDate ? moment(cloneOffersObj?.startDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            endDate: cloneOffersObj?.endDate ? moment(cloneOffersObj?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            publish
        }
        return Api.post('/course/update-offer')
            .params(params)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };

export const deleteOffer = (id, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/delete-offer').params({ id }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const deleteOffers = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/delete-offers').params({ ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};


export const updateOfferBanner = (id, banner, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/update-offer-banner').params({ id, banner }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const deleteInstallment = (storeInstallmentData, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        return Api.delete('/course/course-payment-plan').params({ ids: [storeInstallmentData], courseId: newCourseId }).send((response, error) => {

            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const patchPublishOffers = (id, publish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/publish-offer').params({ id, publish }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
}

export const publishOffers = (ids, publish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/publish-offers').params({ ids, publish }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
}


export const getOnlineTestSeriesCourseDetails = (callBack = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        const params = {
            id: newCourseId
        }
        const url = `/testSeries`;
        return Api.get(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined") {
                dispatch(storeSpecificCourseData(response))
            }
        });
    }
};

export const deleteSubjectTestSeries = (subjectId, callback = null, successCallBack = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        return Api.delete(`/testSeries/deleteSubject`)
            .params({
                subjectId,
                testSeriesId: newCourseId
            })
            .send((response) => {
                if (callback) {
                    callback();
                }
                if (response !== undefined) {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };
}

export const postOnlineTestSeries = (callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { courseCreateObj, specificCourseDataContainer } = getState().courseManagement;
        courseCreateObj.name = courseCreateObj?.courseName
        courseCreateObj.description = courseCreateObj?.courseDescription
        courseCreateObj.subCategoryId = courseCreateObj?.subcategoryId?.id
        const tagsIdsOnly = courseCreateObj?.tags?.length > 0 ? courseCreateObj?.tags?.map(tag => tag.id) : []
        const filteredFeatures = courseCreateObj?.features?.filter(feature => feature?.name !== "");
        const filteredPrerequisites = courseCreateObj?.prerequisites?.filter(prerequisite => prerequisite?.name !== "")
        let UpdatedDeletedPrerequisites = [...courseCreateObj?.deletedCoursePrerequisites];
        courseCreateObj?.prerequisites?.forEach(prerequisite => {
            if (prerequisite?.name === "" && prerequisite?.id) {
                UpdatedDeletedPrerequisites?.push(prerequisite.id);
            }
        });
        let UpdatedDeletedFeatures = [...courseCreateObj?.deletedCourseFeatures];
        courseCreateObj?.features?.forEach(feature => {
            if (feature?.name === "" && feature?.id) {
                UpdatedDeletedFeatures?.push(feature.id);
            }
        });
        const params = {
            ...courseCreateObj,
            id: courseCreateObj?.id ? courseCreateObj?.id : null,
            teacherId: courseCreateObj?.teacherId?.id,
            features: filteredFeatures,
            prerequisites: filteredPrerequisites,
            tagIds: tagsIdsOnly,
            skipSubjects: true,
            discount: specificCourseDataContainer?.discount || 0,
            discountType: specificCourseDataContainer?.discountType || "amount",
            amount: specificCourseDataContainer?.amount || 0,
            registrationFee: specificCourseDataContainer?.registrationFee || 0,
            deletedCoursePrerequisites: UpdatedDeletedPrerequisites,
            deletedCourseFeatures: UpdatedDeletedFeatures,
            tags: [],
            image:courseCreateObj?.image,
            langId:courseCreateObj?.langId || 1
        }
        return Api.post('/testSeries')
            .params(params)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    dispatch(setOnlineTestSeriesId(response?.testSeriesId))
                    dispatch(storeSingleCourseId(response?.testSeriesId))
                    if (successCallBack) {
                        successCallBack(response?.testSeriesId);
                    }
                }
            });
    };



export const deleteIndividualTest = (callback, succesCallback) => {
    return (dispatch, getState) => {
        const { individualTestId } = getState().marketTest;

        Api.delete(`/testSeries/test/${individualTestId}`)
            .params()
            .send((response, error) => {
                if (response?.show?.type != "error") {
                    succesCallback()
                }
                callback()
            });
    }
}

export const appendCRMTableData = res => ({
    type: APPEND_CRM_TABLE_DATA,
    res
})


export const getEnquiryFormsData = (type, page, firstTimeLoading, callBack = null) => {
    return (dispatch, getState) => {
        const { crmParamsObj } = getState().courseManagement;
        const crmParamsObjCopy = { ...crmParamsObj };
        // delete crmParamsObjCopy.sort

                const params = {
            ...crmParamsObjCopy,
             startDate :moment(crmParamsObj?.startDate, "DD/MM/YYYY").isValid() 
            ? moment(crmParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") 
            : null,

            endDate: moment(crmParamsObj?.endDate, "DD/MM/YYYY").isValid() 
            ? moment(crmParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") 
            : null,

            type:type == 'Enquiry' ? 'enquiry' : 'registration',
            page:page
        }
        return Api.get(`/market/v2/registered-leads`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (firstTimeLoading) dispatch(storeCRMTableData(response))
                else dispatch(appendCRMTableData(response))
            }
        })
    }
}

export const getCoursesInCartData = (page, firstTimeLoading, callBack = null) => {
    return (dispatch, getState) => {
        const { crmParamsObj } = getState().courseManagement;
        const params = {
            ...crmParamsObj,
            startDate :moment(crmParamsObj?.startDate, "DD/MM/YYYY").isValid() 
            ? moment(crmParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") 
            : null,

            endDate: moment(crmParamsObj?.endDate, "DD/MM/YYYY").isValid() 
            ? moment(crmParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") 
            : null,
            page:page
        }
        return Api.get(`/market/cart/courses`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (firstTimeLoading) dispatch(storeCRMTableData(response))
                else dispatch(appendCRMTableData(response))
            }
        })
    }
}

export const getWhistledCoursesData = (page, firstTimeLoading, callBack = null) => {
    return (dispatch, getState) => {
        const { crmParamsObj } = getState().courseManagement;
        const params = {
            ...crmParamsObj,
            startDate :moment(crmParamsObj?.startDate, "DD/MM/YYYY").isValid() 
            ? moment(crmParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") 
            : null,

            endDate: moment(crmParamsObj?.endDate, "DD/MM/YYYY").isValid() 
            ? moment(crmParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") 
            : null,
            page:page,
            filterByCourseName:crmParamsObj.filterByCourseName
        }
        return Api.get(`/market/wish-list/courses`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (firstTimeLoading) dispatch(storeCRMTableData(response))
                else dispatch(appendCRMTableData(response))
            }
        })
    }
}

export const getCRMCourseListData = (id, tab) => {
    return (dispatch, getState) => {
        const { crmParamsObj } = getState().courseManagement;
        const params = {
            ...crmParamsObj,
            startDate: crmParamsObj?.startDate ? moment(crmParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: crmParamsObj?.endDate ? moment(crmParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        return Api.get(`/market/coursesList`).params(tab == 2 ? { ...params, id, cart: true } : { ...params, id }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeCrmCourseListData(response))
            }
        })
    }
}

export const postCrmDownloadReport = (tab, callback = null) => {
    return (dispatch, getState) => {
        const { selectedRangeContainer, startDateContainer, endDateContainer } = getState().attendanceNew;
        const mainParam = {
            filter: selectedRangeContainer,
            startDate: startDateContainer,
            endDate: endDateContainer,
        }
        return Api.get("/market/downloadReport")
            .params(tab == 1 ? { ...mainParam, leadsv2: true, type:'enquiry' } : tab == 2 ? { ...mainParam, leadsv2: true, type:'registration' } : tab == 3 ? { ...mainParam, cart: true } : {...mainParam, wishlist: true })
            .send((response) => {
                if (response !== undefined) {
                    if (callback) {
                        callback();
                    }
                    const fileName = 'excel-template.xlsx';
                    Axios.get(response.path, {
                        responseType: "blob",
                    }).then((res) => {
                        fileDownload(res.data, fileName);
                    });
                }
            });
    };
};

export const getTags = (searchedTag, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/autocomplete-tag`).params({ tag: searchedTag ? searchedTag : "" }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeTagsData(response))
                if (!response.length) {
                    callBack();
                } else {
                    callBack(response)
                }
            }
        })
    }
}

export const postCreateTags = (searchedTag, callBack = null) => {
    return (dispatch, getState) => {
        const url = `/course/create-tag`;
        return Api.post(url).params({ tag: searchedTag }).send((response, error) => {
            if (typeof response != "undefined" && response.show.type === "success") {
                if (callBack) {
                    callBack();
                }
            }
        });
    }
}

export const getAutocompleteSearch = (search, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/autocomplete-resources`).params({ search }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(storeAutocompleteSearchData(response))
                }
            }
        })
    }
}
export const getAutocompleteSearchOffers = (search, callBack = null,emptySearch=null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/autocomplete-resources`).params({ search, names: true, emptySearch: emptySearch || false }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(storeAutocompleteSearchData(response))
                }
            }
        })
    }
}

export const getAutocompleteCourses = (callBack = null) => {

    return (dispatch, getState) => {
        return Api.get(`/course/autocomplete-resources`).params({ fromCourse: true, emptySearch: true, names: true }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {

                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {

                    dispatch(storeAutocompleteCoursesData(response))
                }
            }
        })
    }
}

export const getCourseLanguages = (callBack = null) => {

    return (dispatch, getState) => {
        return Api.get(`/course/language`).params({ }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {

                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {

                    dispatch(storeCourseLanguages(response))
                }
            }
        })
    }
}


export const getSearchResultsData = (callBack = null) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj } = getState().courseManagement;
        const params = {
            ...searchPageParamsObj,
            limit: 15,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        return Api.get(`/course/resources`).params(params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSearchResultData(response))
            }
        })
    }
}

export const getCourseLoadMore = (page, callBack = null, published) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, searchResultData, courseTypeName, allCoursesPageParamsObj, totalCoursesDataContainer } = getState().courseManagement;
        const searchParams = {
            ...searchPageParamsObj,
            page,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        const params = {
            search: allCoursesPageParamsObj?.search,
            page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            mode: searchPageParamsObj?.mode,
            durationFilter: searchPageParamsObj?.durationFilter,
            published: published,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        return Api.get(`/course/filter-course`).params(courseTypeName == "searchResult" ? searchParams : params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (courseTypeName == "searchResult") {
                    const updatedItems = [
                        ...(searchResultData?.courses?.items || []),
                        ...(response?.courses?.items || [])
                    ];
                    const updatedSearchResultData = {
                        ...searchResultData,
                        courses: {
                            ...searchResultData.courses,
                            items: updatedItems,
                            meta: response?.courses?.meta
                        }
                    };
                    dispatch(storeSearchResultData(updatedSearchResultData));
                } else if (courseTypeName == "liveCourses") {
                    const updatedItems = [
                        ...(totalCoursesDataContainer?.published?.items || []),
                        ...(response?.courses?.items || [])
                    ];
                    const updatedPublishedData = {
                        ...totalCoursesDataContainer,
                        published: {
                            ...totalCoursesDataContainer?.published,
                            items: updatedItems,
                            meta: response?.courses?.meta
                        }
                    };
                    dispatch(storeTotalCoursesList(updatedPublishedData));
                } else if (!published) {
                    const updatedItems = [
                        ...(totalCoursesDataContainer?.unpublished?.items || []),
                        ...(response?.courses?.items || [])
                    ];
                    const updatedUnpublishedData = {
                        ...totalCoursesDataContainer,
                        unpublished: {
                            ...totalCoursesDataContainer?.unpublished,
                            items: updatedItems,
                            meta: response?.courses?.meta
                        }
                    };
                    dispatch(storeTotalCoursesList(updatedUnpublishedData));
                }
            }
        })
    }
}

export const getTestsLoadMore = (page, callBack = null, published) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, searchResultData, courseTypeName, allCoursesPageParamsObj, storeCategoryData } = getState().courseManagement;
        
        const searchParams = {
            ...searchPageParamsObj,
            page,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        const params = {
            search: allCoursesPageParamsObj?.search,
            page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            mode: searchPageParamsObj?.mode,
            durationFilter: searchPageParamsObj?.durationFilter,
            published: published,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        return Api.get(`/testSeries/filter-test-series`).params(courseTypeName == "searchResult" ? searchParams : params).send((response) => {
           
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (courseTypeName == "searchResult") {
             
                    const updatedItems = [
                        ...(searchResultData?.testSeries?.items || []),
                        ...(response?.testSeries?.items || [])
                    ];
                    const updatedSearchResultData = {
                        ...searchResultData,
                        courses: {
                            ...searchResultData.testSeries,
                            items: updatedItems,
                            meta: response?.testSeries?.meta
                        }
                    };
                    dispatch(storeSearchResultData(updatedSearchResultData));
                }
                else if (published) {
                          const updatedItems = [
                        ...(storeCategoryData?.published?.items || []),
                        ...(response?.testSeries?.items || [])
                    ];
                    const updatedPublishedData = {
                        ...storeCategoryData,
                        published: {
                            ...storeCategoryData?.published,
                            items: updatedItems,
                            meta: response?.testSeries?.meta
                        }
                    };
                    dispatch(storeSubcategoryData(updatedPublishedData));
                } else if (!published) {
                    const updatedItems = [
                        ...(storeCategoryData?.unpublished?.items || []),
                        ...(response?.testSeries?.items || [])
                    ];
                    const updatedUnpublishedData = {
                        ...storeCategoryData,
                        unpublished: {
                            ...storeCategoryData?.unpublished,
                            items: updatedItems,
                            meta: response?.testSeries?.meta
                        }
                    };

                    dispatch(storeSubcategoryData(updatedUnpublishedData));
                }
            }
        })
    }
}


export const getVideoResourceLoadMore = (page, callBack = null, published) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, searchResultData, courseTypeName, allCoursesPageParamsObj, totalCoursesDataContainer } = getState().courseManagement;
        const searchParams = {
            ...searchPageParamsObj,
            page,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        const params = {
            search: allCoursesPageParamsObj?.search,
            page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            type: searchPageParamsObj?.type,
            published: published,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        return Api.get(`/course/filter-video-resource`).params(courseTypeName == "searchResult" ? searchParams : params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (courseTypeName == "searchResult") {
                    const updatedItems = [
                        ...(searchResultData?.videoResources?.items || []),
                        ...(response?.videoResources?.items || [])
                    ];
                    const updatedSearchResultData = {
                        ...searchResultData,
                        courses: {
                            ...searchResultData.videoResources,
                            items: updatedItems,
                            meta: response?.videoResources?.meta
                        }
                    };
                    dispatch(storeSearchResultData(updatedSearchResultData));
                } else if (published) {
                    const updatedItems = [
                        ...(totalCoursesDataContainer?.published?.items || []),
                        ...(response?.videoResources?.items || [])
                    ];
                    const updatedPublishedData = {
                        ...totalCoursesDataContainer,
                        published: {
                            ...totalCoursesDataContainer?.published,
                            items: updatedItems,
                            meta: response?.videoResources?.meta
                        }
                    };
                    dispatch(storeTotalCoursesList(updatedPublishedData));
                } else if (!published) {
                    const updatedItems = [
                        ...(totalCoursesDataContainer?.unpublished?.items || []),
                        ...(response?.videoResources?.items || [])
                    ];
                    const updatedUnpublishedData = {
                        ...totalCoursesDataContainer,
                        unpublished: {
                            ...totalCoursesDataContainer?.unpublished,
                            items: updatedItems,
                            meta: response?.videoResources?.meta
                        }
                    };
                    dispatch(storeTotalCoursesList(updatedUnpublishedData));
                }
            }
        })
    }
}



export const getStudyMaterialLoadMore = (page, callBack = null, published) => {
    return (dispatch, getState) => {
        const { searchPageParamsObj, searchResultData, courseTypeName, allCoursesPageParamsObj, totalCoursesDataContainer } = getState().courseManagement;
        const searchParams = {
            ...searchPageParamsObj,
            page,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        const params = {
            search: allCoursesPageParamsObj?.search,
            page,
            limit: allCoursesPageParamsObj?.limit,
            subcategoryId: allCoursesPageParamsObj?.subcategoryId,
            sort: searchPageParamsObj?.sort,
            type: searchPageParamsObj?.type,
            published: published,
            startDate: searchPageParamsObj?.startDate ? moment(searchPageParamsObj?.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
            endDate: searchPageParamsObj?.endDate ? moment(searchPageParamsObj?.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        }
        return Api.get(`/course/filter-study-material`).params(courseTypeName == "searchResult" ? searchParams : params).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (courseTypeName == "searchResult") {
                    const updatedItems = [
                        ...(searchResultData?.studyMaterials?.items || []),
                        ...(response?.studyMaterials?.items || [])
                    ];
                    const updatedSearchResultData = {
                        ...searchResultData,
                        courses: {
                            ...searchResultData.studyMaterials,
                            items: updatedItems,
                            meta: response?.studyMaterials?.meta
                        }
                    };
                    dispatch(storeSearchResultData(updatedSearchResultData));
                } else if (published) {
                    const updatedItems = [
                        ...(totalCoursesDataContainer?.published?.items || []),
                        ...(response?.studyMaterials?.items || [])
                    ];
                    const updatedPublishedData = {
                        ...totalCoursesDataContainer,
                        published: {
                            ...totalCoursesDataContainer?.published,
                            items: updatedItems,
                            meta: response?.studyMaterials?.meta
                        }
                    };
                    dispatch(storeTotalCoursesList(updatedPublishedData));
                } else if (!published) {
                    const updatedItems = [
                        ...(totalCoursesDataContainer?.unpublished?.items || []),
                        ...(response?.studyMaterials?.items || [])
                    ];
                    const updatedUnpublishedData = {
                        ...totalCoursesDataContainer,
                        unpublished: {
                            ...totalCoursesDataContainer?.unpublished,
                            items: updatedItems,
                            meta: response?.studyMaterials?.meta
                        }
                    };
                    dispatch(storeTotalCoursesList(updatedUnpublishedData));
                }
            }
        })
    }
}

export const postCreateSMCourseContent = (studyMaterialsTopics, SMfilesArray, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        let newSMArray = []
        SMfilesArray?.forEach((item, index, arr) => {
            newSMArray?.push(item ? item[0] : [])
        })
        for (let i = 0; i < studyMaterialsTopics?.length; i++) {
            delete studyMaterialsTopics?.[i]?.file
        }
        const newTopicsArr = studyMaterialsTopics;
        const filteredTopics = newTopicsArr?.filter(topic => topic?.name !== "")?.map(topic => (topic?.fileUrl ? { ...topic, fileIndex: -1 } : topic));;
        const newObj = {
            topics: filteredTopics,
            files: newSMArray,
            studyMaterialId: newCourseId
        }
        return Api.post(`/course/update-study-material-content`).uploadMultiple(newObj, (percent, response) => {
            if (callBack) {
                callBack()
            }
            if (typeof response !== 'undefined' && response?.show?.type != 'error') {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };
export const postCreateVideoResourceContent = (sections, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {

        const { newCourseId } = getState().courseManagement;
        // const updatedSections = sections?.filter((item) => item?.name !== '')?.map((section) => {
        //     return {
        //         ...section,
        //         topics: section.topics.filter((topic) => topic.name !== null),
        //     };
        // });
        const filteredSections = sections?.filter((item) => item?.name !== '')

        const updatedSections = filteredSections?.map((section) => {
            return {
                ...section,
                topics: section?.topics?.filter((topic) => topic?.name || topic?.type),
            };
        });
        const newObj = {
            sections: updatedSections,
            videoResourceId: newCourseId
        }
        return Api.post(`/course/update-video-resource-content`).params(newObj)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack();
                        dispatch(storeSpecificCourseData(videoResourceId))

                    }

                }
            });
    };

export const deleteQuiz = (id) => {
    return dispatch => {
        return Api.delete("/course/delete-quiz")
            .params({ id })
            .send((response, error) => {
                if (typeof response != "undefined") {
                }
            });
    };
};

export const fileKeyUpdate = (data) => ({
    type: FILE_KEY_UPDATE,
    data,
});
export const MergeChunk =
    (fileId, callback = null) => (dispatch, getState) => {
        const params = {
            fileName: fileId,
        };
        return Api.post(`/course/MergeChunks`)
            .params(params)
            .send((response) => {

                // if (callback) {
                //   callback()
                // }
                if (response != undefined) {

                    // dispatch(uploadContent({ ...response, type: object.type }))
                    // callback(response)
                    if (response?.fileKey) {
                        if (callback) {
                            callback(response?.fileKey);
                        }
                        // dispatch(fileKeyUpdate(response.fileKey));

                    }
                }
            });
    };

export const postCreateTest = (publish, selectedCourseType, categoryIds, subcategoryIds, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        // const { offersObj } = getState().courseManagement;

        // const newCategoryIds = categoryIds?.map(category => category?.id);
        // const newSubcategoryIds = subcategoryIds?.map(category => category?.id);
        // const params = {
        //     ...offersObj,
        //     categoryIds: newCategoryIds,
        //     subcategoryIds: newSubcategoryIds,
        //     startDate: moment(offersObj?.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        //     endDate: moment(offersObj?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        //     publish
        // }
        return Api.post('/testSeries/createTest')
            .params(params)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };
export const uploadBlock2 = (params, callback) => {
    // const blockUploadUrl = `/admin-library/UploadChunk?fileName=${params.fileName}&orderId=${params.orderId}`;
    const blockUploadUrl = `/course/UploadChunk`;


    // const file = params?.file
    // file.type = 'application/pdf'

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            Api.post(blockUploadUrl).upload(params, (percent, response) => {
                if (percent === -1) {
       

                }
                if (response !== undefined) {

                    if (percent === 100) {
                        callback(response);
                        resolve();
                    }
                }
            });
        });
    };
};


export const setQuizData = (data) => ({
    type: SET_QUIZ_DATA,
    data,
});
export const getEditQuizData = (id, callBack = null) => {
    return (dispatch, getState) => {
        const { quizDataContainer } = getState().courseManagement;
        const params = {
            id: id
        }
        return Api.get(`/course/get-quiz`).params(params).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeQuizData(response))
            }
        })
    }
}

export const getCRMAutocompleteSearch = (search, tab, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/market/searchSuggestions`).params({ searchWord: search, type: tab == 3 ? 'cart' : 'wishlist' }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(storeAutocompleteSearchData(response))
                }
            }
        })
    }
}

export const getThumnailsData = (search, callBack = null) => {
    return (dispatch, getState) => {
        let params = {
            search: search,
            //     limit:40,
            disablePagination: true,
            //     page:2,

        }
        return Api.get(`/course/course-thumbnails`).params(params).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(storeThumnailsData(response))
                }
            }
        })
    }
}








export const setCourseActivityData = (val) => ({
    type: SET_COURSE_ACTIVITY_DATA,
    val
})

export const appendCourseActivityData = (res) => ({
    type: APPEND_COURSE_ACTIVITY_DATA,
    res
});

export const setCourseDashboardData = (val) => ({
    type: SET_COURSE_DASHBOARD_DATA,
    val
})

export const getCourseDashboardData = (callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/course-dashboard`).params({ isWeb: true }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setCourseDashboardData(response))
            }
        })
    }
}

export const getCourseActivityData = (params, firstTimeLoading, callBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/course-activity`).params(params).send((response, error) => {
            if (response) {
                if (firstTimeLoading) dispatch(setCourseActivityData(response))
                else dispatch(appendCourseActivityData(response))
            }
            if (!error) callBack()
        })
    }
}
export const setStoreCourseGraphData = (data) => ({
    type: SET_STORE_COURSE_GRAPH_DATA,
    data
});



export const getCourseGraphData = (courseType, isMonth, isYear, isChecked, callBack = null) => {
    return (dispatch, getState) => {
        const params = {
            courseType: courseType,
            period: !isChecked ? 'month' : 'year',
            year: isYear,
            month: moment(isMonth, 'MMM').format('MMMM')
        }
        return Api.get(`/feev2/course-revenue-graph`).params(params).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(setStoreCourseGraphData(response))
                }
            }
        })
    }
}

export const setStorePieChartData = (data) => ({
    type: SET_STORE_PIE_CHART_DATA,
    data
});

export const setFromCourseDetailsPage = (val) => ({
    type: SET_FROM_COURSE_DETAILS_PAGE,
    val
})
export const getCoursePieChartData = (callBack = null) => {
    return (dispatch, getState) => {
        const params = {
            graphType: 'course',
            pYear: moment().format('YYYY'),
        }
        return Api.get(`/course/course-graph`).params(params).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(setStorePieChartData(response))
                }
            }
        })
    }
}

export const getCoursePlanningData = (callback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        return Api.get(`/course/course-planning`).params({ id: newCourseId }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callback) {
                    callback(response)
                }
            }
        })
    }
}

export const postCoursePlanningData = (params, callback = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        Object.keys(params).forEach(key => {
            if (params[key] === null) {
                params[key] = 0;
            }
        });
        const newParams = {
            ...params,
            courseId: newCourseId
        }
        return Api.post(`/course/course-planning`).params(newParams).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (succesCallback) {
                    succesCallback()
                }
            }
        })
    }
}

export const postCreateCurriculum = (params, fromClassroom, callback = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        const { selectedSection } = getState().classRoom;
        const newParams = {
            subjects: params,
            courseId: newCourseId
        }
        const classroomParams = {
            subjects: params,
            standardId: selectedSection?.id
        }
        return Api.post(`/subjects/update-subjects`).params(fromClassroom ? classroomParams : newParams).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (succesCallback) {
                    succesCallback()
                }
            }
        })
    }
}

export const postToggleCourseAdmissions = (disableAdmission, callback = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        const newParams = {
            disableAdmission,
            courseIds: [newCourseId]
        }
        return Api.patch(`/course/update-admissions`).params(newParams).send((response) => {
            if (callback) {
                callback(response)
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (succesCallback) {
                    succesCallback()
                }
            }
        })
    }
}

export const postSendReminder = (params, callBack = null, succesCallback = null) =>
    (dispatch, getState) => {
        return Api.get("/feev2/send-fee-reminder")
            .params(params)
            .send((response) => {
                if (callBack) {
                    callBack()
                }
                if (response !== undefined) {
                    if (succesCallback) {
                        succesCallback()
                    }
                }
            });
    };

export const getStandardsFromStudentsIds = (ids, callback = null) =>
    (dispatch, getState) => {
        return Api.get("/user/users-standards").params({ userIds: ids }).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callback) {
                    callback(response)
                }
            }
        });
    };

export const postUnassignStudentsFromCourse = (ids, callback = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const { newCourseId } = getState().courseManagement;
        const newParams = {
            userIds: ids,
            courseId: newCourseId
        }
        return Api.patch(`/user/unassign-course`).params(newParams).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                if (succesCallback) {
                    succesCallback()
                }
            }
        })
    }
}

export const postNewTransferStudents = (params, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.post(`/standards/move-students`).params(params).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined" && response.show.type !== 'error') {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const postUnassignStudents = (params, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/standards/students').params(params).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const assignStudentToBatch = (params, callback = null, successCallback = null) => {
    return (dispatch, getState) => {
        const url = '/user/assignStudentStandard'
        return Api.post(url).params(params).send((response) => {
            if (callback) {
                callback()
            }
            if (response !== undefined) {
                if (successCallback) {
                    successCallback();
                }
            }
        });
    };
};

export const changeCrmStatus = (params, callback = null, successCallback = null) => {
    return (dispatch, getState) => {
        const url = '/market/v2/change-crm-status'
        return Api.post(url).params(params).send((response) => {
            if (callback) {
                callback()
            }
            if (typeof response != "undefined" && response.show.type !== 'error') {
                if (successCallback) {
                    successCallback();
                }
            }
        });
    };
};

export const assignClassCourseInCrm = (params, callback = null, successCallback = null) => {
    return (dispatch, getState) => {
        const url = '/user/v2/assignCourseClass'
        return Api.post(url).params(params).send((response) => {
            if (callback) {
                callback()
            }
            if (typeof response != "undefined" && response.show.type !== 'error') {
                if (successCallback) {
                    successCallback();
                }
            }
        });
    };
};


export const postCancelTransactionRequest = (id, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        const url = `/feev2/cancel-request/${id}`;
        return Api.patch(url).send((response) => {
            if (callback) {
                callback();
            }
            if (response !== undefined) {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };

export const revokeCancelledInstallment = (id, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        const url = `/feev2/revoke-cancel?id=${id}`;
        return Api.patch(url).send((response) => {
            if (callback) {
                callback();
            }
            if (response !== undefined) {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };

export const setPaymentModalParamsObj = (key, val) => ({
    type: SET_PAYMENT_MODAL_PARAMS_OBJ,
    key,
    val
})

export const postEditOfflinePayment = (params, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        return Api.post(`/feev2/edit-offline-payment`).params(params).send((response) => {
            if (callback) {
                callback();
            }
            if (response !== undefined) {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };

export const postMarkAsPaidOffline = (params, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        return Api.post(`/feev2/update-offline-transactions`).params(params).send((response) => {
            if (callback) {
                callback();
            }
            if (response !== undefined) {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };

export const postUnAssignStudent = (unAssignParam, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const url = `/course/unassign-course`;
        return Api.patch(url).params(unAssignParam).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const postExamPaterrn = (examPattern, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const url = `/testSeries/update-exam-pattern`;
        return Api.patch(url).params(examPattern).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const setVideoResourceQuestionAndAnswer = (val) => ({
    type: SET_VIDEO_RESOURCES_QUESTION_ANSWER,
    val,

})



export const getQuestionAndAnswer = (params, callBack = null) => {
    return (dispatch, getState) => {

        return Api.get(`/course/get-question-answer`).params(params).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (callBack) {
                    callBack()
                }
;
                if (response !== undefined && response?.show?.type != 'error') {
                    dispatch(setVideoResourceQuestionAndAnswer(response))
                }
            }
        })
    }
}

export const postQuestionAndAnswer = (params, callback = null, successCallBack = null) =>
    (dispatch, getState) => {
        return Api.post(`/course/update-question-answer`).params(params).send((response) => {
            if (callback) {
                callback();
            }
            if (response !== undefined) {
                if (successCallBack) {
                    successCallBack();
                }
            }
        });
    };

export const deleteQuestionAndAnswer = (id, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/delete-question-answer').params({ id: id }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
}

export const setIsFromAdminPreview = (val) => ({
    type: SET_IS_FROM_ADMIN_PREVIEW,
    val
})

export const postLikeUnlike = (params, callBack = null, succesCallback = null) => {
    return (dispatch, getState) => {
        const url = `/course/like-question-answer`;
        return Api.patch(url).params(params).send((response, error) => {
            if (callBack) {
                callBack();
            }
            if (typeof response != "undefined" && response.show.type === "success") {
                if (succesCallback) {
                    succesCallback()
                }
            }
        });
    }
}

export const setTab = (res) => ({
    type: SET_TAB,
    res
});