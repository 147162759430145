
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
    getListView
} from "actions/marketTest";
import { MdKeyboardBackspace } from "react-icons/md";
import { AiTwotoneEdit, AiOutlineCloseCircle } from "react-icons/ai";
import PageDataLoader from "components/pageDataLoader";
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";


const QuesAnsView = ({ questionData, indexMain }) => {

    const QuestionContainer = ({ questionData, indexMain, comprehensiveData, orQuestionsData, compIndex }) => {
        if (questionData?.type === 'Comprehensive') {
            return (
                <div>
                    <div className='r-jsb m-t-20'>
                        <div className='display-flex flex-1'>
                            <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}.</div>
                            <div className='text-xmd color-black resize-editor-img' style={{ maxWidth: '1050px', wordWrap: 'break-word' }}>
                                {ReactHtmlParser(questionData?.questionContent?.paragraph,
                                    {
                                        transform: (node) => {
                                            if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                return <TeX math={`${node.attribs['data-value']}`} />;
                                            }
                                            if (node.type === 'tag' && node.name === 'img') {
                                                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='r-jsb m-t-20'>
                        <div className='display-flex flex-1'>
                            <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}{compIndex !== null && compIndex !== undefined ? `(${String.fromCharCode('a'.charCodeAt() + compIndex)}).` : null}</div>
                            <div className='text-xmd color-black resize-editor-img' style={{ maxWidth: '1050px', wordWrap: 'break-word' }}>
                                {ReactHtmlParser(comprehensiveData?.questionContent?.statement,
                                    {
                                        transform: (node) => {
                                            if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                return <TeX math={`${node.attribs['data-value']}`} />;
                                            }
                                            if (node.type === 'tag' && node.name === 'img') {
                                                return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className='text-xmd a2grey m-l-20'>+ {comprehensiveData?.positiveMarks} | - {comprehensiveData?.negativeMarks}</div>
                    </div>
                </div>
            )
        } else if (questionData?.type === 'OrQuestions') {
            return (
                <div className='r-jsb m-t-20'>
                    <div className='display-flex flex-1'>
                        <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}.{orQuestionsData?.orQuestionIndexChar}</div>
                        <div className='text-xmd color-black resize-editor-img' style={{ maxWidth: '1050px', wordWrap: 'break-word' }}>
                            {ReactHtmlParser(orQuestionsData?.questionContent?.statement,
                                {
                                    transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                        }
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className='text-xmd a2grey m-l-20'>+ {questionData?.positiveMarks} | - {questionData?.negativeMarks}</div>
                </div>
            )

        } else {
            return (
                <div className='r-jsb m-t-20'>
                    <div className='display-flex flex-1'>
                        <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}.</div>
                        <div className='text-xmd color-black resize-editor-img' style={{ maxWidth: '1050px', wordWrap: 'break-word' }}>
                            {ReactHtmlParser(questionData?.questionContent?.statement,
                                {
                                    transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                        }
                                    }
                                })
                            }
                        </div>
                    </div>
                    {questionData.type === 'Subjective'
                        ?
                        <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks}</div>
                        :
                        <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div>
                    }
                </div>

            )
        }
    }

    const AnswerContainer = ({ answerData, questionData, identifier, answerIndex }) => {

        if (answerData?.type === 'MCQ' || answerData?.type === 'CMCQ' || answerData?.type === 'CMSQ' || answerData?.type === 'MSQ' || questionData?.type === 'MCQ' || questionData?.type === 'MSQ') {
            return (
                <div className='m-t-20' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    {((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.questionContent?.options : questionData?.questionContent?.options)?.map((opt, i) => (
                        <div keys={opt.id} className='test-ques-window r-ac m-b-10 p-10' style={{ background: opt.correct ? '#E9FCF5' : '', width: '49%' }}>
                            <div
                                style={{ background: opt.correct ? '#28df99' : '', color: opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : '' }}
                                className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                            </div>
                            <div
                                // style={{ background: opt.correct ? '#E9FCF5' : '' }}
                                className='r-c-sb flex-1'>
                                <div className='flex-1 resize-editor-img'>
                                    <div style={{ paddingTop: '10px', overflow: 'hidden', wordWrap: 'break-word', maxWidth: '70vh' }} >
                                        {ReactHtmlParser(opt.value,
                                            {
                                                transform: (node) => {
                                                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                                        return <TeX math={`${node.attribs['data-value']}`} />;
                                                    }
                                                    if (node.type === 'tag' && node.name === 'img') {
                                                        return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                                    }
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='r-ac m-l-5'>
                                    {opt.correct
                                        ?
                                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                                        :
                                        <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                    }
                                    <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )

        } else if (answerData?.type === 'Numeric' || answerData?.type === 'CNumeric' || questionData?.type === 'Numeric') {
            console.log('answerDataNUmeric', answerData)
            return (
                <div className='m-t-20'>
                    <div className='text-xmd bold-600 color-black'>Answer</div>
                    <div className='test-ques-window resize-editor-img p-10'>
                        {ReactHtmlParser(((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer))}
                    </div>
                </div>

            )

        }
        else if (answerData?.type === 'T/F' || answerData?.type === 'CTF' || questionData?.type === 'T/F') {

            return (
                <div className='m-t-20'>
                    <div className='text-xmd bold-600 color-black m-b-5'>Options</div>
                    <div style={{ display: 'flex' }}>
                        <div className='test-ques-window r-ac m-r-10 p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#E9FCF5' : '', }}>
                            <div
                                style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '' }}
                                className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                {'A'}
                            </div>
                            <div
                                style={{ width: "90%", overflowX: "hidden" }}
                                className='cursor-pointer p-10 r-c-sb'
                            ><div>
                                    True
                                </div>
                                <div className='r-ac m-l-5'>
                                    {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true
                                        ?
                                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                                        :
                                        <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                    }
                                    <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                                </div>
                            </div>
                        </div>
                        <div className='test-ques-window r-ac p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#E9FCF5' : '', }}>
                            <div
                                style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '' }}
                                className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                {'B'}
                            </div>
                            <div
                                style={{ width: "100%", overflowX: "hidden" }}
                                className='cursor-pointer p-10 r-c-sb'
                            >
                                <div>
                                    False
                                </div>
                                <div className='r-ac m-l-5'>
                                    {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false
                                        ?
                                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                                        :
                                        <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                    }
                                    <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )

        } else {
            return null
        }
    }

    const SolutionContainer = ({ solutionData }) => {
        return (
            <div className='m-t-20'>
                <div className='text-xmd bold-600 color-black'>Solution</div>
                <div className='test-ques-window resize-editor-img p-10' style={{ wordWrap: 'break-word' }}>
                    {ReactHtmlParser((solutionData?.questionContent?.solution),
                        {
                            transform: (node) => {
                                if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                    return <TeX math={`${node.attribs['data-value']}`} />;
                                }
                                if (node.type === 'tag' && node.name === 'img') {
                                    return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                                }
                            }
                        })
                    }
                </div>
            </div>
        )

    }

    if (questionData?.type === 'Comprehensive') {
        return (
            questionData?.questionContent?.subQuestions?.map((item, index) => (
                <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
                    <QuestionContainer questionData={questionData} indexMain={indexMain} compIndex={index} comprehensiveData={item} />
                    <AnswerContainer answerData={item} questionData={questionData} identifier={'Comp'} answerIndex={index} />
                    <SolutionContainer solutionData={item} identifier={'Comp'} />
                </div>
            ))
        )
    }
    else if (questionData?.type === 'OrQuestions') {
        return (
            questionData?.questionContent?.orQuestions?.map((item, index) => (
                <>
                    <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
                        <QuestionContainer questionData={questionData} indexMain={indexMain} orQuestionsData={item} />
                        <AnswerContainer answerData={item} questionData={questionData} identifier={'OrQues'} answerIndex={index} />
                        <SolutionContainer solutionData={item} identifier={'OrQues'} />
                    </div>
                    {index != questionData?.questionContent?.orQuestions?.length - 1 ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
                            <span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
                            <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
                        </div>
                        : null
                    }
                </>
            ))

        )
    } else {

        return (
            <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
                <QuestionContainer questionData={questionData} indexMain={indexMain} />
                <AnswerContainer questionData={questionData} />
                <SolutionContainer solutionData={questionData} />
            </div>
        )
    }


}



function ListView({
    listViewData,
    getListView
}) {

    const history = useHistory()
    const [pageLoader, togglePageLoader] = useState(false);

    useEffect(() => {
        togglePageLoader(true)
        getListView(() => togglePageLoader(false))
    }, []);

    let indexMain = 0

    return (<div style={{ height: '100%', margin: '-20px' }}>

        <div className='r-c-sb p-20' style={{ height: 60, background: '#F6F4FE' }}>
            <div className='r-ac'>
                <MdKeyboardBackspace onClick={() => { history.goBack() }} className='text-md color-black m-r-10 cursor-pointer' />

                <div className='text-sm bold-600 color-black'>{listViewData?.title}</div>

                <AiTwotoneEdit onClick={() => { history.push('/testSeries/createTest') }} className='text-md a2grey m-l-20 cursor-pointer' />
            </div>
        </div>



        <div className='overview-scroll' style={{ height: 'calc(100% - 20px)' }}>
            <div className='r-c-fe p-20'>
                <div className='r-ac cursor-pointer' onClick={() => history.goBack()} >
                    <AiOutlineCloseCircle className='text-sm blue m-r-5' />
                    <div className='text-xmd bold-600 blue'>EXIT LIST VIEW</div>
                </div>
            </div>
            <div className='p-l-20 p-r-20 p-b-20' style={{ backgroundColor: '#F6F4FE' }}>
                {listViewData?.individualTestSubjects?.map((item) => (
                    <div keys={item.subjId}>
                        <div className='text-sm bold-600 color-black'>{item.testSeriesSubject.subject.name}</div>
                        {item?.individualTestSection?.map((secData, secIndex) => (
                            <div>
                                {secData?.name != "_default_" &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div key={secIndex} style={{ fontSize: '14px' }} className='text-sm bold-600 color-black'>{secData?.name}</div>
                                    </div>
                                }
                                {secData?.individualTestQuestion?.map((quesData, quesIndex) => {
                                    indexMain++
                                    return <div className='m-b-20' keys={quesData.id}>
                                        <QuesAnsView questionData={quesData} indexMain={indexMain} />
                                    </div>
                                })}
                            </div>
                        ))}
                    </div>
                ))}

            </div>
        </div>

        <PageDataLoader visible={pageLoader} />
    </div>)
}

const mapStateToProps = (state) => {
    const { listViewData } = state.marketTest;
    return { listViewData };
};

const mapDispatchToProps = (dispatch) => ({
    getListView: (loaderCallback) => dispatch(getListView(loaderCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
