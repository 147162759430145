import React, { useState } from 'react';
import { Modal, Radio } from 'antd';
import { Button } from 'components/Button';
import confrimationImg  from '../../../Assets/aulasMarket/confirmationImg.svg'

const FreeCourseConfirmationModal = ({ freeConfirmModal, setFreeConfirmModal, freeCourseName, handleFree, freeCourseType }) => {

    const [loader, setLoader] = useState(false)
  

    const handleCancel = () => {
        console.log("376391");
        setFreeConfirmModal(false);
    };


    return (
        <div 
        onClick={(e) => {e.stopPropagation()}}
        >

            <Modal
                className="modal-round-corner"
                open={freeConfirmModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={false}
                bodyStyle={{ borderRadius: "10px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{textAlign:"center"}}>
                    <img src={confrimationImg}/>
                </div>
                <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Confirm</div>
                <div
                    style={{
                        marginTop: "10px",
                        textAlign: 'center'
                    }}
                >
                    Are you sure you want to enroll to
                    <span style={{
                        fontSize: "16px",
                        color: "#636363",
                        fontWeight: "bold",
                    }}>"{freeCourseName}"</span>
                    <span> {freeCourseType == 'studyMaterial' ? 'study material?' : freeCourseType == 'testSeries' ? 'test series?' : freeCourseType == 'videoResource' ? 'video course?': 'course?'}</span>

                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "30px",
                    }}
                >
                    <div>
                        <Button
                                onClick={(e)=>{e.stopPropagation(), handleCancel()}}
                            >
                                NO
                        </Button>
                    </div>
                    <div>
                        <Button
                            loading={loader}
                            type='primary'
                            onClick={(e) => {e.stopPropagation(), handleFree(),setLoader(true) }}

                        >
                            YES
                        </Button>
                    </div>
                </div>
            </Modal>

        </div>
    );
};
export default FreeCourseConfirmationModal;