import React, { useState } from "react";
import { Modal, Button, Radio, Input, Select, Search, Avatar, Tooltip } from "antd";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import image from "../../Assets/feeManagement/Mask Group 2.png";
import { Api } from "services";
import { cancelTransaction, fetchTransaction } from "actions/feeManagement";
import ConfirmModal from "./confirmationModal";
import moment from "moment";

// import Search from "antd/lib/transfer/search";

function PayRequestDetailsModal({
  isModalVisible,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,
  type,
  name,
  role,
  setIsModalVisible,
  setShowAddDetailsModal,
  setShowPayRequestModal,
  payRequestData,
  cancelTransaction,
  fetchTransaction,
  setShowConfirmationModal,
  recentActivitiesVAfromWhere
}) {

  console.log("naveessss", { payRequestData })

  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [value, setValue] = React.useState(1);
  const [cancelBtnLdr, setCancelBtnLdr] = useState(false);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const text = (
    <span>
      <div style={{ width: "200px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>
            UPI
            {/* /Rupay Card */}
          </p>
          <p>₹ 0</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Credit Card/Debit Card</p>
          <p>1.8 %</p>
        </div>
        {/* Credit Card/Debit Card 1.8 % */}
      </div>
    </span>
  );
  return (
    <div style={{ fontFamily: "roboto" }}>
      <Modal
        maskClosable={false}
        className="generatePayModalB"
        okText={"SEND REQUEST"}
        cancelText={"CANCEL"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={() => setShowPayRequestModal(false)}
        onCancel={() => setShowPayRequestModal(false)}
        footer={null}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Payment Request Details
        </h2>
        <div
          style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "600" }}
        >
          Requested to
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            paddingLeft: "30px",
          }}
        >
          <div
            className="r-ac"
            style={{ fontFamily: "roboto", marginLeft: "-20px" }}
          >
            <div>
              {/* <img width="60px" src={image} alt="" /> */}
              <Avatar
                style={{ width: "40px", height: "40px" }}
                src={recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? Api._s3_url + payRequestData?.userImage : Api._s3_url + payRequestData?.user?.image}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <div style={{ fontWeight: "550" }}>
                {recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? payRequestData?.username : payRequestData?.user?.name}
              </div>
              <div style={{ color: "#636363" }}>
                {/* recentActivitiesVAfromWhere === 'fromInsideLiveCourses' &&  */payRequestData?.resourceType || payRequestData?.resourceName ? payRequestData?.resourceName : payRequestData?.standardNames}
              </div>
            </div>
          </div>
          <div
            style={{
              border: `1px solid ${payRequestData?.status == "Overdue"
                ? "#FF414D"
                : payRequestData?.status == "Paid"
                  ? "#28DF99"
                  : payRequestData?.status == "Refunded"
                    ? "#1089FF"
                    : payRequestData?.status == "Cancelled"
                      ? "#1089FF"
                      : "#FFA931"
                }`,
              borderRadius: "7px",
              textAlign: "center",
              fontSize: "10px",
              padding: "5px",
              fontWeight: "700",
              width: "100px",
              color: `${payRequestData?.status == "Overdue"
                ? "#FF414D"
                : payRequestData?.status == "Paid"
                  ? "#28DF99"
                  : payRequestData?.status == "Refunded"
                    ? "#1089FF"
                    : payRequestData?.status == "Cancelled"
                      ? "#1089FF"
                      : "#FFA931"
                }`,
              backgroundColor: `${payRequestData?.status == "Overdue"
                ? "#FFECED"
                : payRequestData?.status == "Paid"
                  ? "#EAFCF5"
                  : payRequestData?.status == "Refunded"
                    ? "#E7F3FF"
                    : payRequestData?.status == "Cancelled"
                      ? "#E7F3FF"
                      : "#FFF6EA"
                }`,
            }}
          >
            {payRequestData?.status}
          </div>
        </div>
        <div
          style={{ overflowY: "auto", fontFamily: "roboto", height: "400px" }}
        >
          <div
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "600" }}
          >
            Details
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
              paddingLeft: "10px",
              marginBottom: "20px",
            }}
          >
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Amount
              </p>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#191919",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "15px",
                }}
              >
                ₹ {payRequestData?.amount}
              </p>
            </div>
            <div>
              {payRequestData?.date ? <>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                  }}
                >
                  Issue date
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#191919",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                  }}
                >
                  {/* {moment(payRequestData?.date).format('DD-MM-YYYY')} */}
                  {payRequestData?.date}
                </p>
              </> : null}
            </div>
            <div>
              {payRequestData?.paymentDate || payRequestData?.dueDate ? <>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                  }}
                >
                  {payRequestData?.status == 'Paid' ? "Paid date" : "Due date"}
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#191919",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                  }}
                >
                  {/* {payRequestData?.status == 'Paid' ? moment(payRequestData?.paymentDate).format('DD-MM-YYYY') : moment(payRequestData?.dueDate).format('DD-MM-YYYY')} */}
                  {payRequestData?.status == 'Paid' ? payRequestData?.paymentDate : payRequestData?.dueDate}
                </p>
              </> : null}
            </div>
          </div>
          <p
            style={{
              marginBottom: "0px",
              color: "#636363",
              marginBottom: "5px",
              fontWeight: "bold",
              fontFamily: "Roboto",
              fontSize: "12px",
              marginLeft: "10px",
            }}
          >
            Description
          </p>
          <div
            style={{
              marginLeft: "10px",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            {payRequestData?.note ? payRequestData?.note : payRequestData?.resourceLabel}
          </div>
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Amount details
            </p>
            <div
              style={{
                border: "1px solid #E6E6E6",
                borderRadius: "10px",
                padding: "10px",
                width: "98%",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "40px",
                  justifyContent: "space-between",
                }}
              >
                <div>Fee amount</div>
                <div>
                  ₹{" "}
                  {recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? payRequestData?.amount : parseFloat(payRequestData?.amount) - payRequestData?.total_addition + payRequestData?.total_discount}
                </div>
              </div>
              {payRequestData?.discount_details &&
                JSON.parse(payRequestData?.discount_details)?.length != 0 ? (
                JSON.parse(payRequestData?.discount_details)?.map((item) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "27px",
                      color: "#1089FF",
                    }}
                  >
                    <div>{item?.name}</div>
                    <div
                    // onClick={() => {
                    //   setShowAddDetailsModal(true);
                    //   setIsModalVisible(false);
                    // }}
                    // style={{ cursor: "pointer" }}
                    >
                      -₹ {item?.amount ? item?.amount : 0}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "27px",
                    color: "#1089FF",
                  }}
                >
                  <div>Discount</div>
                  <div>-₹ 0</div>
                </div>
              )}
              {payRequestData?.addition_details &&
                JSON.parse(payRequestData?.addition_details)?.length != 0 ? (
                JSON.parse(payRequestData?.addition_details)?.map((item) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "27px",
                      color: "#FFA931",
                    }}
                  >
                    <div>{item?.name}</div>
                    <div>+₹ {item?.amount ? item?.amount : 0}</div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "27px",
                    color: "#FFA931",
                  }}
                >
                  <div>Addition</div>
                  <div>+₹ 0</div>
                </div>
              )}
              {/* {payRequestData?.gstApplicable ? ( */}
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "40px",
                  color: "grey",
                }}
              >
                <div>GST</div>
                <div
                  onClick={() => {
                    // setIsAdditionModal(true);
                    // setShowAddDetailsModal(true);
                    // setIsModalVisible(false);
                  }}
                // style={{ cursor: "pointer" }}
                >
                  +₹{" "}
                  {payRequestData?.gst
                    ? parseFloat(payRequestData?.gst).toFixed(2)
                    : 0}
                </div>
              </div>
              {/* ) : null} */}
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #E6E6E6",
                  borderBottom: "1px solid #E6E6E6",
                  height: "40px",
                }}
              >
                <div>Total payable</div>
                <div>₹ {payRequestData?.amountPayable}</div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "10px", fontFamily: "roboto" }}>
            <div
              style={{ fontWeight: "700", marginTop: "10px", fontSize: "16px" }}
            >
              Processing fee
              <Tooltip placement="rightTop" title={text}>
                <ExclamationCircleOutlined
                  style={{ marginLeft: "10px", color: "#1089FF" }}
                />
              </Tooltip>
            </div>
            <div>
              Processing fee payable by {payRequestData?.transactionPaidBy}
            </div>
            {!(
              payRequestData?.status == "Paid" ||
              payRequestData?.status == "Cancelled"
            ) ? (
              <div>
                <div
                  style={{
                    fontWeight: "700",
                    marginTop: "10px",
                    fontSize: "16px",
                  }}
                >
                  Action
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontWeight: "550",
                        marginTop: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Cancel
                    </div>

                    <div>
                      {" "}
                      Cancel this payment request. You can't undo this!
                    </div>
                  </div>
                  <Button
                    loading={cancelBtnLdr}
                    // disabled={
                    //   payRequestData?.status == "Paid" ||
                    //   payRequestData?.status == "Cancelled"
                    // }
                    onClick={() => {
                      setShowPayRequestModal(false);
                      setShowConfirmationModal(true);

                      // setCancelBtnLdr(true);
                      // cancelTransaction(
                      //   () => {
                      //     setCancelBtnLdr(false);
                      //   },
                      //   () => {
                      //     setShowPayRequestModal(false);
                      //     fetchTransaction();
                      //   }
                      // );
                    }}
                    style={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "#636363",
                      border: "1px solid #636363",
                      fontWeight: "600",
                      height: "35px",
                      // marginLeft: "450px",
                      // marginTop: "-100px",
                      marginRight: "5px",
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-60px",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={() => {
              setShowPayRequestModal(false);
            }}
            style={{
              marginBottom: "-200px",
              borderRadius: "20px",
              width: "150px",
              color: "#636363",
              border: "1px solid #636363",
              fontWeight: "600",
              height: "40px",
            }}
          >
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } = state.userManagement;
  const { recentActivitiesVAfromWhere } = state.feeManagement
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    recentActivitiesVAfromWhere
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelTransaction: (callback, successCallback) =>
    dispatch(cancelTransaction(callback, successCallback)),
  fetchTransaction: () => dispatch(fetchTransaction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayRequestDetailsModal);

// recentActivitiesVAfromWhere == 'fromInsideLiveCourses'