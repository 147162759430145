import React from 'react';
import { Button, Divider, Modal } from 'antd';
import { useState } from 'react';
const PreviewTestModal = ({isModalOpen,setIsModalOpen}) => {
    console.log("AA-setIsModalOpen",isModalOpen);
  
 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal 
      className='modal-round-corner'
      footer={null}
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel}>
        <div style={{color:"#191919",fontSize:"21px",fontWeight:"bold",textAlign:"center"}}>Preview</div>
        <div style={{color:"#191919",fontSize:"17px",fontWeight:"bold"}}>JEE Advanced - 2022</div>
        <div style={{color:"#636363",fontSize:"13px"}}>JEE Advanced 2022 exam pattern marking scheme will comprise of zero, partial, and full mark scheme</div>
        <div style={{display:"flex"}}>
        <div style={{color:"#AEAEAE",fontSize:"11px"}}>Objective</div>
        <div style={{marginLeft:"5px",color:"#AEAEAE",fontSize:"11px"}}>{""}7 Conditions</div>
        </div>
        <div style={{border:"1px solid #E6E6E6",marginTop:"25px"}} >
            <div style={{display:"flex", justifyContent: 'space-between', padding: '10px 30px' }}>
                <div  style={{color:"#636363",fontSize:"13px",fontWeight:"bold"}}>Question type</div>
                <div className='r-jsb' style={{width: 150}}>
                    <div  style={{color:"#636363",fontSize:"13px",fontWeight:"bold"}} >+ve</div>
                    <div  style={{color:"#636363",fontSize:"13px",fontWeight:"bold"}}>-ve</div>
                </div>
            </div>
            <Divider style={{margin:'10px 20px'}}/>
            <div style={{display:"flex", justifyContent: 'space-between', padding: '10px 30px' }}>
                <div style={{color:"#0C0C0C",fontSize:"13px",fontWeight:"bold"}}>Single choice correct answer</div>
                <div className='r-jsb' style={{width: 150}}>
                    <div style={{color:"#28DF99",fontSize:"13px",fontWeight:"bold"}}>+4</div>
                    <div style={{color:"#FF414D",fontSize:"13px",fontWeight:"bold"}}>-2</div>
                </div>
            </div>
            <div style={{display:"flex", justifyContent: 'space-between', padding: '10px 30px' }}>
                <div  style={{color:"#0C0C0C",fontSize:"13px",fontWeight:"bold"}}>Multiple choice correct answer</div>
                <div className='r-jsb' style={{width: 150}}>
                    <div style={{color:"#28DF99",fontSize:"13px",fontWeight:"bold"}}>+4</div>
                    <div style={{color:"#FF414D",fontSize:"13px",fontWeight:"bold"}}>-2</div>
                </div>
            </div>
            <div style={{display:"flex", justifyContent: 'space-between', padding: '10px 30px' }}>
                <div  style={{color:"#0C0C0C",fontSize:"13px",fontWeight:"bold"}}>Numeric type questions</div>
                <div className='r-jsb' style={{width: 150}}>
                    <div style={{color:"#28DF99",fontSize:"13px",fontWeight:"bold"}}>+4</div>
                    <div style={{color:"#FF414D",fontSize:"13px",fontWeight:"bold"}}>-2</div>
                </div>
            </div>
            <div style={{display:"flex", justifyContent: 'space-between', padding: '10px 30px' }}>
                <div  style={{color:"#0C0C0C",fontSize:"13px",fontWeight:"bold"}}>True/False</div>
                <div className='r-jsb' style={{width: 150}}>
                    <div style={{color:"#28DF99",fontSize:"13px",fontWeight:"bold"}}>+4</div>
                    <div style={{color:"#FF414D",fontSize:"13px",fontWeight:"bold"}}>-2</div>
                </div>
            </div>
        </div>
        <center className='r-jsb m-t-20' style={{width: '60%', display: 'flex', justifyContent: 'space-between', marginLeft: 95}}>
                    <Button className='radius-100' style={{width: 120}} onClick={handleOk}>EDIT</Button>
                    <Button className='purpleBtnWrap radius-100 purpleButtonHoverEffect' onClick={handleCancel}>BACK</Button>
         </center>
        
        
      </Modal>
    </>
  );
};
export default PreviewTestModal;