import {Platform} from 'react-native';
import {Api} from 'services';

export const ORIENTATION_CHANGE = 'ORIENTATION_CHANGE';
export const DETECT_DEVICE_TYPE = 'DETECT_DEVICE_TYPE';
export const TOGGLE_LOCAL_STREAM = 'TOGGLE_LOCAL_STREAM';
export const UPDATE_LOCAL_STREAM = 'UPDATE_LOCAL_STREAM';
export const UPDATE_DEVICE_SIZE = 'UPDATE_DEVICE_SIZE';
export const UPDATE_NETWORK_STATE = 'UPDATE_NETWORK_STATE';
export const UPDATE_PUSH_TOKEN = 'UPDATE_PUSH_TOKEN';
export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR';
export const TOGGLE_VIDEO = 'TOGGLE_VIDEO';
export const TOGGLE_AUDIO = 'TOGGLE_AUDIO';
export const TOGGLE_SPEAKER = 'TOGGLE_SPEAKER';
export const UPDATE_RECONNECT_MODAL = 'UPDATE_RECONNECT_MODAL';
export const UPDATE_APP_STATE = 'UPDATE_APP_STATE';
export const UPDATE_HEADPHONE_STATUS = 'UPDATE_HEADPHONE_STATUS';
export const UPDATE_BLUETOOTH_HEADSET = 'UPDATE_BLUETOOTH_HEADSET';
export const UPDATE_AUDIOJACK_STATUS = 'UPDATE_AUDIOJACK_STATUS';
export const HIDE_INFO_MODAL = 'HIDE_INFO_MODAL';
export const SHOW_INFO_MODAL = 'SHOW_INFO_MODAL';

let snackTimer = false;

export const updateNotificationToken = token => (dispatch, getState) => {
  const url = '/user/device-info';
  const params = {
    pushId: token,
    pushOs: Platform.OS,
  };
  return Api.patch(url)
    .params(params)
    .send((response, error) => {
    });
};

export const updateAudioJackStatus = data => ({
  type: UPDATE_AUDIOJACK_STATUS,
  data,
});

export const updateBluetoothHeadset = value => ({
  type: UPDATE_BLUETOOTH_HEADSET,
  value,
});

export const updateReconnectModal = value => ({
  type: UPDATE_RECONNECT_MODAL,
  value,
});

export const updateAppState = value => ({
  type: UPDATE_APP_STATE,
  value,
});

export const toggleSpeaker = value => ({
  type: TOGGLE_SPEAKER,
  value,
});

export const toggleAudio = value => ({
  type: TOGGLE_AUDIO,
  value,
});

export const toggleVideo = value => ({
  type: TOGGLE_VIDEO,
  value,
});

export const toggleSnackbar = message => dispatch => {
  dispatch(showSnackbar(message));
  clearTimeout(snackTimer);
  snackTimer = setTimeout(() => {
    dispatch(hideSnackbar());
  }, 4000);
};

export const showSnackbar = message => ({
  type: SHOW_SNACK_BAR,
  message,
});

export const showInfoModal = data => ({
  type: SHOW_INFO_MODAL,
  data
})

export const hideInfoModal = () => ({
  type: HIDE_INFO_MODAL
})

export const hideSnackbar = () => ({
  type: HIDE_SNACK_BAR,
});

export const orientationChange = orientation => ({
  type: ORIENTATION_CHANGE,
  orientation,
});

export const detectDeviceTye = deviceType => ({
  type: DETECT_DEVICE_TYPE,
  deviceType,
});

export const toggleLocalStream = value => ({
  type: TOGGLE_LOCAL_STREAM,
  value,
});

export const updateLocalStream = stream => ({
  type: UPDATE_LOCAL_STREAM,
  stream,
});

export const updateDeviceSize = size => ({
  type: UPDATE_DEVICE_SIZE,
  width: size.width,
  height: size.height,
});

export const updateNetworkState = state => ({
  type: UPDATE_NETWORK_STATE,
  state,
});
