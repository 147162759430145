// src/DemoDualAxes.js
import React from 'react';
import { DualAxes } from '@ant-design/plots';
import loader from '../Assets/gifs/section-loader.gif'

function arePropsEqual(oldProps, newProps) {
    let x = JSON.stringify(oldProps) === JSON.stringify(newProps)
    return (
        x
    );
}
const DemoDualAxes = React.memo(({ data, isGraphLoader, fromFullScreen }) => {

    console.log("");
    const config = {
        data: [data, data],
        xField: 'year',
        yField: ['leads', 'conversions'],
        marginRatio: 0,

        legend: false,
        xAxis: {
            label: {
                style: {
                    fill: '#AEAEAE',
                },
            },
            line: {
                style: {
                    lineDash: [4, 4],
                    stroke: '#AEAEAE',
                },
            },
            // grid: {
            //     line: {
            //         style: {
            //             lineDash: [4, 4],
            //             stroke: '#AEAEAE',
            //         },
            //     },
            // },
        },
        yAxis: {
            label: {
                style: {
                    fill: '#AEAEAE',
                },
            },
            line: {
                style: {
                    lineDash: [4, 4],
                    stroke: '#AEAEAE',
                },
            },
            grid: {
                line: {
                    style: {
                        lineDash: [4, 4],
                        stroke: '#AEAEAE',
                    },
                },
            },
            // conversions: {
            //     ticks: 0
            // },

        },
        tooltip: {
            customContent: (val, item) => {
                return (
                    <div style={{ padding: 10, width: "130px", borderRadius: 20 }}>
                        <div>{item[0]?.data?.year}</div>
                        <div style={{ display: "flex", gap: 10, marginTop: "10px" }}>
                            <div style={{ height: "12px", width: "12px", backgroundColor: "#594099", borderRadius: 2 }}></div>
                            <div>Leads :<span style={{ marginLeft: 10 }}> {item[0]?.data?.leads} </span></div>
                        </div>
                        <div style={{ display: "flex", gap: 10, marginTop: "10px" }}>
                            <div style={{ height: "12px", width: "12px", backgroundColor: "#C3B1EC", borderRadius: 2 }}></div>
                            <div>Conversions:<span style={{ marginLeft: 10 }}>{item[0]?.data?.conversions}</span> </div>
                        </div>
                    </div>

                )

            }
        },
        geometryOptions: [
            { geometry: 'line', color: '#594099' }, // Yellow color for value axis
            { geometry: 'line', color: '#C3B1EC' }, // Blue color for count axis
        ],
    };

    return (
        <div style={{ height: fromFullScreen ? 270 : 170, width: '100%' }}>
            {isGraphLoader ?
                <div style={{display:"flex",justifyContent:"center",width:'100%',height:170,marginTop:40}}>
                    <img src={loader} width={120} height={70}/>
                </div> :

                <DualAxes {...config} />}
            

        </div>
    );
},
    arePropsEqual
);

export default DemoDualAxes;