import {
  TOGGLE_DETAIL_MODAL, TOGGLE_NOTIFY_MODAL, UPDATE_NOTIFICATION, UPDATE_REQUEST, APPROVE_LOADER,
   REJECT_LOADER, CHANGE_STATUS, SELECT_STATUS, SELECT_PAGE, SELECT_TYPE, UPDATE_COUNT,
   TOGGLE_SCHEDULE_MODAL, TOGGLE_NOTIFICATION_LOADER, SELECT_NOTIFICATION_TAB, SET_NOTIFICATIONS,UPDATE_FILTER,SET_LOADER,GET_NOTIFICATIONS,
   SET_CARD_ID,
   SET_ACTION_DATA,
   SET_FROM_NOTIFICATION,
   IS_TRUE
} from '../actions/notification';

const initialState = {
  detailModalVisible: false,
  notifyModalVisible: false,
  approveLoader: false,
  rejectLoader: false,
  detailModalData: {},
  notifyModalData: {},
  notificationData : [],
  requestData : [],
  selectedType : 'Notifications',
  selectedStatus : 'Pending',
  selectedPage: 1,
  itemsCount: 0,
  scheduleModalVisible: false,
  scheduleModalData: {},
  notificationLoader: false,
  notifyModalBody: "",
  selectedNotificationTab: "Notifications",
  notification: {},
  reloadToggler: false,
  filter:{dateFilter:'All',currentPage:1,search:'',isViewSelected:false, selectedNotification:{}},
  loaders:{generalLoader:false},
  cardId:0,
  actionDataContainer:"",
  fromNotification:false,
  setIsTrue:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_NOTIFICATION_TAB:
      return {
        ...state,
        selectedNotificationTab: action.key,
        reloadToggler: !state.reloadToggler
      };
    case CHANGE_STATUS:
      state.notificationData.items[action.index].status = "Read";
      return {
        ...state,
        notificationData: {...state.notificationData, items: [...state.notificationData.items]},
      };
    case TOGGLE_NOTIFICATION_LOADER:
      return {
        ...state,
        notificationLoader: action.bool
      };
    case TOGGLE_DETAIL_MODAL:
      return {
        ...state,
        detailModalVisible: action.value,
        detailModalData: action.data,
      };
    case TOGGLE_SCHEDULE_MODAL:
      return {
        ...state,
        scheduleModalVisible: action.value,
        scheduleModalData: action.data,
      };
    case TOGGLE_NOTIFY_MODAL:
      return {
        ...state,
        notifyModalVisible: action.value,
        notifyModalData: action.data,
        notifyModalBody: action.body
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notificationData: action.res ?  action.res.notifications : [],
        itemsCount:  action.res ? action.res.notifications.meta.totalItems : 0
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        requestData: action.res ? action.res.requests.items : [],
        itemsCount:  action.res ? action.res.requests.meta.totalItems : 0
      };
    case APPROVE_LOADER:
      return {
        ...state,
        approveLoader: action.bool,
      };
    case REJECT_LOADER:
      return {
        ...state,
        rejectLoader: action.bool,
      };
    case SELECT_STATUS:
      return {
        ...state,
        selectedStatus: action.status,
      };
    case SELECT_PAGE:
      return {
        ...state,
        selectedPage: action.page,
      };
    case SELECT_TYPE:
      return {
        ...state,
        selectedType: action.tab,
      };
    case UPDATE_COUNT:
      return {
        ...state,
        itemsCount: action.count,
      };
      case SET_NOTIFICATIONS:
        return {
          ...state,
          notification: action.res
        }
        case UPDATE_FILTER:
      let newfilter = {...state.filter}
      newfilter[action.key] = action.val;
      return {
        ...state,
        filter: newfilter,
        reloadToggler: !state.reloadToggler
      }
      case SET_LOADER:
        let newLoaders = {...state.loaders}
        newLoaders[action.key] = action.val;
        return {
          ...state,
          loaders: newLoaders
        }
        case SET_CARD_ID:
          return {
              ...state,
              cardId: action.id
          } 
          case SET_ACTION_DATA:
            return {
                ...state,
                actionDataContainer: action.val
            }
        case SET_FROM_NOTIFICATION:
          return{
            ...state,
            fromNotification : action.val
          }
        case IS_TRUE:
          return{
            ...state,
            setIsTrue:action.bool
          }  
      
    default:
      return state;
  }
  
};

export default reducer;
