import React from 'react'
import { Pie } from '@ant-design/charts';
import { useEffect, useRef } from 'react'

const DoughnuttCard = React.memo(({ data, percentage }) => {
  console.log({ data })
  {
    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      color: ['#28DF99', '#FF414D', '#FFA931', '#CDCDCD'],
      radius: 0.8,
      autoFit: true,
      legend: false,
      innerRadius: 0.68,
      label: {
        type: 'inner',
        offset: '-50%',
        content: "",
        style: {
          fill: '#fff',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 8
          },
          customHtml: function customHtml() {
            return (
              <div>
                <div style={{ fontSize: "20px", fontWeight: "700" }}>{percentage}%</div>
                <div style={{ fontSize: "10px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto", color: "grey" }}>Average</div>
              </div>
            )
          }
        },
      },
      annotations: [],
    };
    const tags = ["Present", "Absent", 'Half Day', 'Holiday']
    const tagcolor = ['#28DF99', '#FF414D', '#FFA931', '#CDCDCD']
    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: "100%", height: '35vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -50 }}>
          <Pie {...config} />
        </div>
        <div className="r-jsb absolute" style={{ bottom: 40, marginTop: -20, width: '90%' }}>
          {tags.map((tag, index) =>
            <div className="r-c-c" key={index} style={{}}>
              <div style={{ backgroundColor: tagcolor[index], width: "15px", height: "15px", borderRadius: "3px", marginRight: "5px" }}></div>
              <div style={{ fontSize: 12 }}>{data[index]?.value}</div>
              <div style={{ fontSize: 12, marginLeft: 5 }}>{tag}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
)

export default DoughnuttCard