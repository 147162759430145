import React, { useEffect, useState } from 'react';
import { Modal, Button, Tag, Row, Col, Typography } from 'antd';
import { ArrowLeftOutlined, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { FetchStudentAttStat } from 'actions/attendanceNew';
import { ClassMode } from '../localComponents/tableComponent';
import "../style.css"
import { connect } from 'react-redux';
import moment from 'moment';
import { Api } from 'services';
import { SubIcon } from 'components/subIcon';
import {getStudentsDetailsById } from 'actions/userManagement';
import {setSpecificUserId } from 'actions/userManagementV2';
import { useHistory } from 'react-router';
const image = require("../../../Assets/user/lab-conference.svg").default;
const image1 = require("../../../Assets/user/ic-present.svg").default;
const image2 = require("../../../Assets/user/Group 11858.svg").default;
const NoData = require("../../../Assets/ill-no-data.svg").default

const TeachersAttendanceDetailsModal = ({ isModalVisible, setIsModalVisible, attendanceDetailsModalData, attendanceDetailsContainer, FetchStudentAttStat, storeStudsStats, user, specificUserId, setSpecificUserId, getStudentsDetailsById }) => {

  const [currentView, setCurrentView] = useState(0)
  const [reload, setReload] = useState(true)
  const [location, setLocation] = useState(0)

  const history = useHistory();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    FetchStudentAttStat()
  }, [])

  const NoDataCard = () => {
    return (
      <>
        <div className='r-c-c-c' style={{ width: "230px" }}>
          <img src={NoData} />
          {/* <center style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</center> */}
          <center style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no students.</center>
        </div>
      </>
    )
  }


  return (
    <>
      <Modal
        centered
        className="modal-round-corner"
        visible={isModalVisible}
        onCancel={handleCancel}
        header={null}
        footer={null}
      >
        {currentView === 0 ?
          <>
            <div style={{ marginTop: "25px", color: "#191919", fontSize: "21px", fontWeight: "bold", textAlign: "center" }}>Attendance report</div>
            <div className="m-t-40" style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div><SubIcon size={50} icon={attendanceDetailsContainer?.icon} name={attendanceDetailsContainer?.subject} /></div>

                <div className="m-l-10">
                  <ClassMode mode={attendanceDetailsContainer?.mode} />
                  <div style={{ color: "#191919", fontSize: "15px", fontWeight: "bold" }}>{attendanceDetailsContainer?.subject}</div>
                  <div style={{ color: "#636363", fontSize: "11px" }}> {attendanceDetailsContainer?.standards.replace(/\s+/g, '-')}</div>
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ color: `${attendanceDetailsContainer.attendanceStatus === "Present" ? "#28DF99" : "#FF414D"}`, marginLeft: 10 }}>{attendanceDetailsContainer.attendanceStatus === "Present" ? <CheckCircleFilled /> : <CloseCircleFilled />}</div>
                <div className='m-l-5' style={{ color: `${attendanceDetailsContainer.attendanceStatus === "Present" ? "#28DF99" : "#FF414D"}` }}>{attendanceDetailsContainer?.attendanceStatus}</div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "25px" }}>
              <div><img src={image2} /></div>
              <div className='p-5' style={{ color: "#636363", fontSize: "13px", }}>
                {moment(attendanceDetailsContainer?.date).format("YYYY/MM/DD")}{" "}{"•"}{" "}{(moment(attendanceDetailsContainer?.startTime, 'hh:mm:ss').format('h:mm a'))}{" "}-{" "}{(moment(attendanceDetailsContainer?.endTime, 'hh:mm:ss').format('h:mm a'))}
              </div>
              <div style={{ display: "flex", marginLeft: "50px" }}>
                <div>
                  <SubIcon size={20} icon={attendanceDetailsContainer?.teacherImage} /></div>
                <div className='p-5' style={{ color: "#636363", fontSize: "13px" }}>{attendanceDetailsContainer?.teacherName}</div>
              </div>
            </div>
            <div className='m-l-15 m-t-20 bold-600 text-xs' style={{ color: '#191919' }}>DETAILS</div>
            <div
              style={{
                marginTop: "5px",
                marginLeft: "10px",
                borderRadius: "6px",
                alignItems: "center",
                Width: 170,
                height: 90,
                backgroundColor: "#FAFAFA",
              }}>
              <div style={{ display: "flex", justifyContent: "space-between", }}>
                <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px", marginTop: "10px" }}>Join time</div>
                <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", marginTop: "10px", fontWeight: "bold" }}>
                  {
                    attendanceDetailsContainer?.joinTime != null ?
                      moment(attendanceDetailsContainer?.joinTime, 'hh:mm').format('hh:mm a')
                      :
                      "--:--"
                  }
                  {/* {attendanceDetailsContainer?.joinTime===null?0:attendanceDetailsContainer?.joinTime} */}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px" }}>Leave time</div>
                <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", fontWeight: "bold" }}>
                  {
                    attendanceDetailsContainer?.leaveTime != null ?
                      moment(attendanceDetailsContainer?.leaveTime, 'hh:mm').format('hh:mm a')
                      :
                      "--:--"
                  }
                  {/* {attendanceDetailsContainer?.leaveTime===null?0:attendanceDetailsContainer?.leaveTime} */}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px" }}>Duration</div>
                <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", fontWeight: "bold" }}>
                  {attendanceDetailsContainer?.attendanceDuration === null ? '00:00' :
                    <div>{parseInt(attendanceDetailsContainer?.attendanceDuration / 60).toString().padStart(2, '0')}:{(attendanceDetailsContainer?.attendanceDuration % 60).toString().padStart(2, '0')} {"hrs"}</div>}</div>
              </div>
            </div>

                  {console.log('id', user?.id , specificUserId)}
            {(user?.privilage?.includes("SuperAdmin") || (user?.role?.includes("Teacher") && user?.id == specificUserId)) ?
              <>
                <div className='r-jsb' style={{ width: '100%' }}>
                  <div className='m-l-15 m-t-20 bold-600 text-xs' style={{ color: '#191919' }}>STUDENT'S DETAILS</div>
                  <div className='m-t-20 bold-600 blue text-xs cursor-pointer' onClick={() => { setCurrentView(1) }}>VIEW MORE</div>
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    marginLeft: "10px",
                    borderRadius: "6px",
                    alignItems: "center",
                    Width: 170,
                    height: 90,
                    backgroundColor: "#FAFAFA",
                  }}>
                  <div style={{ display: "flex", justifyContent: "space-between", }}>
                    <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px", marginTop: "10px" }}>Total Students</div>
                    <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", marginTop: "10px", fontWeight: "bold" }}>
                      {attendanceDetailsContainer?.studentDetails?.total ? attendanceDetailsContainer?.studentDetails?.total : '0'}
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px" }}>Present</div>
                    <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", fontWeight: "bold" }}>
                      {attendanceDetailsContainer?.studentDetails?.present ? attendanceDetailsContainer?.studentDetails?.present : '0'}
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ color: "#191919", fontSize: "13px", marginLeft: "20px" }}>Absent</div>
                    <div style={{ color: "#191919", fontSize: "13px", marginRight: "20px", fontWeight: "bold" }}>
                      {attendanceDetailsContainer?.studentDetails?.absent ? attendanceDetailsContainer?.studentDetails?.absent : '0'}
                    </div>
                  </div>
                </div>
              </>
              : null}


          </>
          : currentView === 1
            ?
            <>
              <div className='display-flex m-t-20' style={{ alignItems: 'center' }}>
                <div className='cursor-pointer' onClick={() => { setCurrentView(0) }}><ArrowLeftOutlined /></div>
                <div style={{ marginLeft: 15, color: "#191919", fontSize: "20px", fontWeight: "bold" }}>Student's Attendance Report</div>
              </div>

              <div style={{ width: '100%' }}>
                <Row>
                  <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Col>
                      <div style={{ display: "flex", marginTop: "20px" }}>
                        <div style={{ color: "#28DF99", marginLeft: 10 }}><CheckCircleFilled /></div>
                        <div className='m-l-5' style={{ color: "#28DF99" }}>PRESENT ({storeStudsStats?.present?.length})</div>
                      </div>

                      {storeStudsStats?.present?.length ?
                        storeStudsStats?.present?.map((item) => (
                          <div onClick={() => {  !user?.role.includes('Teacher') ? (setSpecificUserId(item?.user?.id),  history.push({pathname:'/user-management/profile', state: {  key: location + 1}}), setIsModalVisible(false), getStudentsDetailsById() ) : null }} style={{ backgroundColor: '#FAFAFA', height: 'fit-content', width: '200px', marginLeft: 10, borderRadius: 6, cursor: !user?.role.includes('Teacher') ? 'pointer' : null }}>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                height: 70
                              }}
                            >
                              {console.log('item1', item)}
                              <div>
                                <img style={{height: 60, width: 60, borderRadius: 50, margin: '5px 0 0 5px'}} src={`${Api._s3_url}${item.image}`} />
                              </div>
                              <div style={{ marginTop: "10px", marginLeft: 5 }}>
                                <Typography.Text ellipsis={{tooltip: true}} style={{ fontSize: "12px", fontWeight: "bold", color: "#191919", width: 120 }}>
                                  {item?.name}
                                </Typography.Text>

                                <Typography.Text ellipsis={{tooltip: true}} style={{ fontSize: "10px", color: "#636363", width: 120 }}>
                                  RollNo: {item?.rollNo}
                                </Typography.Text>

                                <div style={{ fontSize: "8px", color: "#636363" }}>
                                  {item?.duration != null || item?.duration != undefined ?
                                    `${item?.duration} min`
                                    : '0 min'}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        <div style={{ width: '100%', marginTop: 50 }}><NoDataCard /></div>
                      }

                    </Col>

                    <Col style={{ marginLeft: 20 }}>
                      <div style={{ display: "flex", marginTop: "20px" }}>
                        <div style={{ color: "#FF414D", marginLeft: 10 }}><CloseCircleFilled /></div>
                        <div className='m-l-5' style={{ color: "#FF414D" }}>ABSENT({storeStudsStats?.absent?.length})</div>
                      </div>

                      {storeStudsStats?.absent?.length ?
                        storeStudsStats?.absent?.map((item) => (
                          <div onClick={() => {  !user?.role.includes('Teacher') ? (setSpecificUserId(item?.user?.id),  history.push({pathname:'/user-management/profile', state: {  key: location + 1}}), setIsModalVisible(false), getStudentsDetailsById() ) : null }} style={{ backgroundColor: '#FAFAFA', height: 'fit-content', width: '200px', marginLeft: 10, borderRadius: 6, cursor: !user?.role.includes('Teacher') ? 'pointer' : null  }}>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                height: 70
                              }}
                            >
                              <div>
                              <img style={{height: 60, width: 60, borderRadius: 50, margin: '5px 0 0 5px'}} src={`${Api._s3_url}${item.image}`} />
                              </div>
                              <div style={{ marginTop: "10px", marginLeft: 5 }}>
                                <Typography.Text ellipsis={{tooltip: true}} style={{ fontSize: "12px", fontWeight: "bold", color: "#191919", width: 120 }}>
                                  {item?.name}
                                </Typography.Text>

                                <Typography.Text ellipsis={{tooltip: true}} style={{ fontSize: "10px", color: "#636363", width: 120 }}>
                                  RollNo: {item?.rollNo}
                                </Typography.Text>

                                <div style={{ fontSize: "8px", color: "#636363" }}>
                                  {item?.duration != null || item?.duration != undefined ?
                                    `${item?.duration} min`
                                    : '0 min'}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        <div style={{ width: '100%', marginTop: 50 }}><NoDataCard /></div>
                      }

                    </Col>
                  </div>
                </Row>
              </div>
            </>
            :
            null}

        <div style={{ display: 'flex', justifyContent: "center", marginTop: "25px" }}>
          <Button
            style={{
              borderRadius: "19px",
              width: "120px",
              height: "35px",
              fontWeight: "500",
              border: "1px solid #636363 ",
              backgroundColor: "#FFFFFF",
            }}
            onClick={handleCancel}

          >
            <div
              style={{
                fontSize: "16px",
                color: "#636363 ",
                fontWeight: "bold",
              }}
              onClick={handleCancel}
            >
              CLOSE
            </div>
          </Button>
        </div>
      </Modal>
    </>
  );

};


const mapStateToProps = (state) => {
  const { attendanceDetailsContainer, storeStudsStats } = state.attendanceNew;
  const { user } = state.session;
  const { specificUserId } = state.userManagement;

  return { attendanceDetailsContainer, storeStudsStats, user, specificUserId };
};


const mapDispatchToProps = (dispatch) => ({
  attendanceDetailsModalData: (val) => dispatch(attendanceDetailsModalData(val)),
  FetchStudentAttStat: () => dispatch(FetchStudentAttStat()),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  getStudentsDetailsById:( callback) => dispatch(getStudentsDetailsById(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeachersAttendanceDetailsModal);