import React, { useEffect, useState } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { newCourseCreationCardStyle } from './courseCreationPage'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AnimatedTextField from 'components/AnimatedTextField';
import { connect } from 'react-redux';
import { getTags, postCreateTags, setCreateCourseObj } from 'actions/courseManagement';
import AnimatedCustomSelect from 'components/AnimatedSelectComp';
import { Tag } from 'antd';
import { LabelAndDataComp } from './keyInformationComp';

function AdditionalInformationComp({
    isExpanded, setIsExpanded, courseLearningFields, setCourseLearningFields, deletedLearnings, setDeletedLearnings,
    setCreateCourseObj, coursePrerequisitesFields, setCoursePrerequisitesFields, deletedPrerequisites, setDeletedPrerequisites,
    postCreateTags, getTags, selectedTags, setSelectedTags, courseFeaturesFields, setCourseFeaturesFields, deletedfeatures, setDeletedFeatures,
    courseIncludesFields, setCourseIncludesFields, deletedDetails, setDeletedDetails, courseTypeName, courseCreateObj, location, clearValues, expandedSection }) {
    const [tagsList, setTagsList] = useState([]);
    const [searchTag, setSearchTag] = useState(null);
    const [additionalInfoFilledCount, setAdditionalInfoFilledCount] = useState(0)

    useEffect(() => {
        getTags(null, (tags) => { updateTagsList(tags) })
    }, [])

    const handleAddLearningField = () => {
        const lastField = courseLearningFields[courseLearningFields.length - 1];
        if (lastField.name !== '') {
            setCourseLearningFields([...courseLearningFields, { id: null, name: '' }]);
        }
    }
    const handleRemoveLearningField = (index) => {
        const updatedLearningFields = [...courseLearningFields];
        const deletedLearning = updatedLearningFields.splice(index, 1);
        deletedLearning.forEach(learning => {
            setDeletedLearnings([...deletedLearnings, learning.id]);
        });
        setCourseLearningFields(updatedLearningFields);
        setCreateCourseObj("learnings", updatedLearningFields);
    };

    const handleAddPrerequisitesField = () => {
        const lastField = coursePrerequisitesFields[coursePrerequisitesFields.length - 1];
        if (lastField.name !== '') {
            setCoursePrerequisitesFields([...coursePrerequisitesFields, { id: null, name: '' }]);
        }
    };
    const handleRemovePrerequisitesField = (index) => {
        const updatedPrerequisitesFields = [...coursePrerequisitesFields];
        const deletedPrerequisite = updatedPrerequisitesFields.splice(index, 1);
        deletedPrerequisite.forEach(prerequisite => {
            setDeletedPrerequisites([...deletedPrerequisites, prerequisite.id]);
        });
        setCoursePrerequisitesFields(updatedPrerequisitesFields);
        setCreateCourseObj("prerequisites", updatedPrerequisitesFields)
    };

    const handleAddFeatureField = () => {
        const lastField = courseFeaturesFields[courseFeaturesFields.length - 1];
        if (lastField.name !== '') {
            setCourseFeaturesFields([...courseFeaturesFields, { id: null, name: '' }]);
        }
    }
    const handleRemoveFeatureField = (index) => {
        const updatedFeaturesFields = [...courseFeaturesFields];
        const deletedFeature = updatedFeaturesFields.splice(index, 1);
        deletedFeature.forEach(feature => {
            setDeletedFeatures([...deletedfeatures, feature.id]);
        });
        setCourseFeaturesFields(updatedFeaturesFields);
        setCreateCourseObj("features", updatedFeaturesFields);
    };

    const handleAddIncludesField = () => {
        const lastField = courseIncludesFields[courseIncludesFields.length - 1];
        if (lastField.name !== '') {
            setCourseIncludesFields([...courseIncludesFields, { id: null, name: '' }]);
        }
    };
    const handleRemoveIncludesField = (index) => {
        const updatedIncludesFields = [...courseIncludesFields];
        const deletedDetail = updatedIncludesFields.splice(index, 1);
        deletedDetail.forEach(detail => {
            setDeletedDetails([...deletedDetails, detail.id]);
        });
        setCourseIncludesFields(updatedIncludesFields);
        setCreateCourseObj("details", updatedIncludesFields)
    };

    const updateTagsList = (tags) => {
        setTagsList(tags?.map(item => Object.assign(item, { key: item.id, label: item.tag, value: item.id })))
    }
    const removeSelectedTags = (currTag) => {
        for (let i = 0; i < selectedTags?.length; i++) {
            if (currTag.id == selectedTags[i].id) {
                return false
            }
        }
        return true
    }

    
    const handleTagsSelect = (value, obj) => {
        if (obj?.key == -1) {
            // If the tag does not exist, create it
            postCreateTags(obj?.children?.[1], () => {
                // After creating the tag, fetch the updated tags list
                getTags(null, (tags) => {
                    updateTagsList(tags);
                    // Find the newly created tag in the updated list and add it to selectedTags
                    const newTag = tags.find(tag => tag.label === obj?.children?.[1]);
                    if (newTag && !selectedTags.some(tag => tag.id === newTag.id)) {
                        setSelectedTags([...selectedTags, newTag]);
                        setCreateCourseObj("tags", [...selectedTags, newTag]);
                    }
                });
            });
        } else if (!selectedTags.some(tag => tag.id === obj.id)) {
            // Add the selected tag to the list if not already added
            setSelectedTags([...selectedTags, obj]);
            setCreateCourseObj("tags", [...selectedTags, obj]);
        }
        // Clear the searchTag to hide it from the input field
        setSearchTag('');
    };
    
    const handleTagsSearch = (query) => {
        if (query?.trim().length > 0 || query?.trim().length === 0) {
            setSearchTag(query);
            getTags(query, (tags) => updateTagsList(tags));
        }
    };
    const removeTag = (index) => {
        const updatedTags = [...selectedTags];
        updatedTags.splice(index, 1);
        setSelectedTags(updatedTags);
        setCreateCourseObj("tags", updatedTags);
        getTags("", (tags) => updateTagsList(tags));
    };

    const courseTypeObjects = {
        liveCourses: {
            features: courseCreateObj?.features,
            learnings: courseCreateObj?.learnings,
            prerequisites: courseCreateObj?.prerequisites,
            tags: courseCreateObj?.tags,
        },
        videoResources: {
            features: courseCreateObj?.features,
            learnings: courseCreateObj?.learnings,
            prerequisites: courseCreateObj?.prerequisites,
            details: courseCreateObj?.details,
            tags: courseCreateObj?.tags,
        },
        onlineTestSeries: {
            features: courseCreateObj?.features,
            prerequisites: courseCreateObj?.prerequisites,
            tags: courseCreateObj?.tags,
        },
        studyMaterials: {
            features: courseCreateObj?.features,
            learnings: courseCreateObj?.learnings,
            tags: courseCreateObj?.tags,
        }
    };

    useEffect(() => {
        let count = 0;
        const currentCourseObj = courseTypeObjects[courseTypeName];
        for (const item in currentCourseObj) {
            if (currentCourseObj[item]?.length && (currentCourseObj[item]?.[0]?.name || currentCourseObj[item]?.[0]?.value)) {
                count++;
            }
        }
        setAdditionalInfoFilledCount(count)
    }, [courseTypeName, isExpanded, courseCreateObj]);

    useEffect(() => {
        return (() => {
            if (location?.state?.key?.isEdit != 'isFromEdit') {
                clearValues()
            }
        })
    }, [])

    const isTagsPresent = courseCreateObj?.tags?.some((item) => item?.value);
    const learningsCount = courseCreateObj?.learnings?.filter(learning => learning?.name?.trim() !== '').length;
    const prerequisitesCount = courseCreateObj?.prerequisites?.filter(learning => learning?.name?.trim() !== '').length;
    const featuresCount = courseCreateObj?.features?.filter(learning => learning?.name?.trim() !== '').length;

    return (
        <div className='m-t-20' style={newCourseCreationCardStyle}>
            <div className='r-c-sb'>
                <div style={{ color: "#696969", fontSize: 18, fontWeight: 700 }}>Additional information (optional)</div>
                <div className='r-ac'>
                    {!isExpanded && additionalInfoFilledCount > 0 ? <div className='m-r-10' style={{ color: "#696969" }}>{additionalInfoFilledCount} {additionalInfoFilledCount == 1 ? "field" : "fields"}</div> : <></>}
                    {isExpanded ?
                        <FiChevronDown style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} /> :
                        <FiChevronRight style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)} />
                    }
                </div>
            </div>
            {!expandedSection ?
                <>
                    {location?.state?.key?.isEdit == "isFromEdit" && !isExpanded || (location?.state?.key?.isEdit != "isFromEdit" && courseCreateObj?.learnings?.length || courseCreateObj?.prerequisites?.length || courseCreateObj?.tags?.length) ?
                        <div className='r-c-sb m-t-10' style={{ width: "100%" }}>
                            {courseTypeName == "liveCourses" || courseTypeName == "videoResources" ?
                                <>
                                    <LabelAndDataComp label={"What will your students learn?"} data={learningsCount + " " + "point(s) added"} />
                                    <LabelAndDataComp label={"Prerequisites"} data={prerequisitesCount + " " + "point(s) added"} />
                                    {(!courseCreateObj?.tags?.length) ? <LabelAndDataComp label={"Salient features"} data={featuresCount + " " + "point(s) added"} /> : <></>}
                                    {isTagsPresent && courseCreateObj?.tags?.length ? <LabelAndDataComp label={"Tags"} data={courseCreateObj?.tags?.length + " " + "tag(s) added"} /> : <></>}
                                </> :
                                <>
                                    {courseTypeName == "onlineTestSeries" ?
                                        <>
                                            <LabelAndDataComp label={"Prerequisites"} data={prerequisitesCount + " " + "point(s) added"} />
                                            <LabelAndDataComp label={"Salient features"} data={featuresCount + " " + "point(s) added"} />
                                            <LabelAndDataComp label={"Tags"} data={courseCreateObj?.tags?.length + " " + "tag(s) added"} />
                                        </> : <>
                                            <LabelAndDataComp label={"What will your students learn?"} data={learningsCount + " " + "point(s) added"} />
                                            <LabelAndDataComp label={"Salient features"} data={featuresCount + " " + "point(s) added"} />
                                            <LabelAndDataComp label={"Tags"} data={courseCreateObj?.tags?.length + " " + "tag(s) added"} />
                                        </>}
                                </>}
                        </div>
                        : <></>}
                </> : <></>}
            {isExpanded ?
                <div>
                    {courseTypeName == "onlineTestSeries" ? <></> :
                        <>
                            <div className='m-t-20 m-b-5' style={{ color: "#8C8C8C" }}>What your students learn from this Course?</div>
                            {courseLearningFields?.map((field, index) => (
                                <div key={index} className='r-jsb' style={{ marginTop: index === 0 ? '' : 10, width: "100%" }}>
                                    <div className='r-ac'>
                                        <div className='m-r-5' style={{ color: "#636363" }}>{index + 1}.</div>
                                        <AnimatedTextField
                                            label={""}
                                            isMandatory={false}
                                            inputId={index}
                                            value={field?.name ? field?.name : ''}
                                            handleInputChange={(e) => {
                                                const updatedLearningFields = [...courseLearningFields];
                                                updatedLearningFields[index].name = e.target.value;
                                                setCourseLearningFields(updatedLearningFields);
                                                setCreateCourseObj("learnings", updatedLearningFields);
                                            }}
                                            style={{ marginTop: 0 }}
                                            width={"31vw"}
                                            onKeyDown={(e) => {
                                                if (e.key === " ") {
                                                    e.stopPropagation(); // Stop event propagation
                                                }
                                            }}
                                        />
                                    </div>
                                    {index === courseLearningFields?.length - 1 ? (
                                        <PlusCircleOutlined
                                            className='m-t-5'
                                            style={{ color: '#1089FF', cursor: courseLearningFields[courseLearningFields.length - 1]?.name == "" ? "not-allowed" : "pointer", fontSize: 18, marginLeft: 20 }}
                                            onClick={handleAddLearningField}
                                        />
                                    ) : (
                                        <MinusCircleOutlined
                                            className='m-t-5'
                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                            onClick={() => handleRemoveLearningField(index)}
                                        />
                                    )}
                                </div>
                            ))}
                        </>}
                    {courseTypeName == "studyMaterials" ? <></> :
                        <>
                            <div className='m-t-20 m-b-5' style={{ color: "#8C8C8C" }}>Prerequisites need for this Course</div>
                            {coursePrerequisitesFields?.map((field, index) => (
                                <div key={index} className='r-jsb' style={{ marginTop: index === 0 ? '' : 10, width: "100%" }}>
                                    <div className='r-ac'>
                                        <div className='m-r-5' style={{ color: "#636363" }}>{index + 1}.</div>
                                        <AnimatedTextField
                                            label={""}
                                            isMandatory={false}
                                            inputId={index}
                                            value={field?.name ? field?.name : ''}
                                            handleInputChange={(e) => {
                                                const updatedPrerequisitesFields = [...coursePrerequisitesFields];
                                                updatedPrerequisitesFields[index].name = e.target.value;
                                                setCoursePrerequisitesFields(updatedPrerequisitesFields);
                                                setCreateCourseObj("prerequisites", updatedPrerequisitesFields);
                                            }}
                                            style={{ marginTop: 0 }}
                                            width={"31vw"}
                                            onKeyDown={(e) => {
                                                if (e.key === " ") {
                                                    e.stopPropagation(); // Stop event propagation
                                                }
                                            }}
                                        />
                                    </div>
                                    {index === coursePrerequisitesFields?.length - 1 ? (
                                        <PlusCircleOutlined
                                            className='m-t-5'
                                            style={{ color: '#1089FF', cursor: coursePrerequisitesFields[coursePrerequisitesFields.length - 1]?.name == "" ? 'not-allowed' : 'pointer', fontSize: 18, marginLeft: 20 }}
                                            onClick={handleAddPrerequisitesField}
                                        />
                                    ) : (
                                        <MinusCircleOutlined
                                            className='m-t-5'
                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                            onClick={() => handleRemovePrerequisitesField(index)}
                                        />
                                    )}
                                </div>
                            ))}
                        </>}
                    <div className='m-t-20 m-b-5' style={{ color: "#8C8C8C" }}>Salient features</div>
                    {courseFeaturesFields?.map((field, index) => (
                        <div key={index} className='r-jsb' style={{ marginTop: index === 0 ? '' : 10, width: "100%" }}>
                            <div className='r-ac'>
                                <div className='m-r-5' style={{ color: "#636363" }}>{index + 1}.</div>
                                <AnimatedTextField
                                    label={""}
                                    isMandatory={false}
                                    inputId={index}
                                    value={field?.name ? field?.name : ''}
                                    handleInputChange={(e) => {
                                        const updatedFeaturesFields = [...courseFeaturesFields];
                                        updatedFeaturesFields[index].name = e.target.value;
                                        setCourseFeaturesFields(updatedFeaturesFields);
                                        setCreateCourseObj("features", updatedFeaturesFields);
                                    }}
                                    style={{ marginTop: 0 }}
                                    width={"31vw"}
                                    onKeyDown={(e) => {
                                        if (e.key === " ") {
                                            e.stopPropagation(); // Stop event propagation
                                        }
                                    }}
                                />
                            </div>
                            {index === courseFeaturesFields?.length - 1 ? (
                                <PlusCircleOutlined
                                    className='m-t-5'
                                    style={{ color: '#1089FF', cursor: courseFeaturesFields[courseFeaturesFields.length - 1]?.name == "" ? "not-allowed" : "pointer", fontSize: 18, marginLeft: 20 }}
                                    onClick={handleAddFeatureField}
                                />
                            ) : (
                                <MinusCircleOutlined
                                    className='m-t-5'
                                    style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                    onClick={() => handleRemoveFeatureField(index)}
                                />
                            )}
                        </div>
                    ))}
                    {courseTypeName == "videoResources" ?
                        <>
                            <div className='m-t-20 m-b-5' style={{ color: "#8C8C8C" }}>This course includes</div>
                            {courseIncludesFields?.map((field, index) => (
                                <div key={index} className='r-jsb' style={{ marginTop: index === 0 ? '' : 10, width: "100%" }}>
                                    <div className='r-ac'>
                                        <div className='m-r-5' style={{ color: "#636363" }}>{index + 1}.</div>
                                        <AnimatedTextField
                                            label={""}
                                            isMandatory={false}
                                            inputId={index}
                                            value={field?.name ? field?.name : ''}
                                            handleInputChange={(e) => {
                                                const updatedIncludesFields = [...courseIncludesFields];
                                                updatedIncludesFields[index].name = e.target.value;
                                                setCourseIncludesFields(updatedIncludesFields);
                                                setCreateCourseObj("details", updatedIncludesFields);
                                            }}
                                            style={{ marginTop: 0 }}
                                            width={"31vw"}
                                            onKeyDown={(e) => {
                                                if (e.key === " ") {
                                                    e.stopPropagation(); // Stop event propagation
                                                }
                                            }}
                                        />
                                    </div>
                                    {index === courseIncludesFields?.length - 1 ? (
                                        <PlusCircleOutlined
                                            className='m-t-5'
                                            style={{ color: '#1089FF', cursor: courseIncludesFields[courseIncludesFields.length - 1]?.name == "" ? "not-allowed" : "pointer", fontSize: 18, marginLeft: 20 }}
                                            onClick={handleAddIncludesField}
                                        />
                                    ) : (
                                        <MinusCircleOutlined
                                            className='m-t-5'
                                            style={{ color: '#FF414D', cursor: 'pointer', fontSize: 18, marginLeft: 20 }}
                                            onClick={() => handleRemoveIncludesField(index)}
                                        />
                                    )}
                                </div>
                            ))}
                        </>
                        : <></>}
                    <div className='m-t-20 m-b-10' style={{ color: "#8C8C8C" }}>Tags</div>
                    <div className='tag-select-item' style={{ color: "#636363", fontSize: 12 }}>Tags helps to make your course more discoverable to students and helps the students find your class. </div>
                    <AnimatedCustomSelect
                        label={""}
                        required={false}
                        options={tagsList?.length != 0 ? tagsList?.filter(removeSelectedTags) : searchTag && !tagsList?.some(tag => tag.label.toLowerCase().includes(searchTag.toLowerCase())) ? searchTag : ""}
                        value={searchTag || ''}
                        isSelected={searchTag ? true : false}
                        handleSelectChange={e => { setSearchTag(e) }}
                        handleSelectOnSelect={(value, obj) => handleTagsSelect(value, obj)}
                        handleSelectOnSearch={(query) => handleTagsSearch(query)}
                        showSearch={true}
                        allowClear={searchTag ? true : false}
                        placeholder={`${'\u00A0'.repeat(2)}Search tags Sample: Ethical Hacking`}
                        style={{ marginBottom: 0 }}
                        fromTagSelect = {true}
                        onKeyDown={(e) => {
                            if (e.key === " ") {
                                e.stopPropagation(); // Stop event propagation
                            }
                        }}
                    />

                  
                    <div className="flex-wrap m-t-10">
                        {selectedTags?.map((tag, index) =>
                            <Tag
                                key={tag.id}
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#191919',
                                    padding: "5px 10px",
                                    borderRadius: 20,
                                    border: "1px solid #636363",
                                    fontSize: 14,
                                    marginBottom: 10,
                                }}
                                closable
                                onClose={() => removeTag(index)}
                            >
                                {tag?.tag || tag?.value}
                            </Tag>
                        )}
                    </div>
                </div>
                : <></>}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { courseTypeName, courseCreateObj } = state.courseManagement
    return { courseTypeName, courseCreateObj };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    getTags: (searchedTag, callBack) => dispatch(getTags(searchedTag, callBack)),
    postCreateTags: (searchedTag, callBack) => dispatch(postCreateTags(searchedTag, callBack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInformationComp);