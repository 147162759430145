import { PauseCircleFilled, PlayCircleFilled, PlayCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import { FullscreenOutlined } from '@ant-design/icons';
import { getVideoResourcesData, postUpdateVideoProgress, setVideoCompletionStatus } from 'actions/studentMarketPlace';
import PageDataLoader from 'components/pageDataLoader';
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/youtube';
import { connect } from 'react-redux';
import Slider from 'antd/lib/slider';
import { GoUnmute, GoMute } from "react-icons/go";

let t1 = null;
let t2 = null;
let t3 = null;
let t4 = null;
let t5 = null;
let hovertimeout = null;
let interval = null;
let blockColor = 'black'

function YTPlayer({ videoUrl, videoData, postUpdateVideoProgress, videoDuration, getVideoResourcesData,
    setVideoCompletionStatus, itemIdVideoResource, topicId, title = "", postProgress = false, top = "calc(50% - 28px)",
    isLive = false, hoverEffect, keyboardControls=false }) {
    const [height, setHeight] = useState("100%")
    const [toggler, setToggler] = useState(false);
    const playerRef = useRef(null);
    const [volume, setVolume] = useState(0.8);
    const [hovering, setHovering] = useState(false);

    const KEYBOARD_CONTROLS_ENABLED =  keyboardControls;
    const [elapsed, setElapsed] = useState(0);
    const [isMuted, setMuted] = useState(false);
    const [isPlaying, setPlaying] = useState(false);
    const [isHalfScreenMode, setHalfScreenMode] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(true);
    const [playerState, setPlayerState] = useState('unloaded');
    const [duration, setDuration] = useState('00:00')
    const [totalDurationSeconds, setTotalDurationSeconds] = useState(0);
    const totalDurationRef = useRef(100);
    const elapsedSecondsRef = useRef(0);
    const [elapsedSeconds, setElapsedSeconds] = useState(0);
    const [previousState, setPreviousState] = useState(null);
    const [isInit5Sec, setInit5Sec] = useState(false);
    const [isEmbedAllowed, setEmbedAllowed] = useState(true);
    const [isFullScreen, setFullScreen] = useState(false);
    const containerRef = useRef(null);

    const [pageLoader, togglePageLoader] = useState(false);
    const [renderPagePlayer, setRenderPagePlayer] = useState(0);
    const [progressParam, setProgressParam] = useState({
        id: null,
        videoResourceId: itemIdVideoResource,
        progress: null,
    });
    const [videoProgress, setVideoProgress] = useState(0);

    const makeFullScreen = () => {
        let div = containerRef.current
        if (div.requestFullscreen)
            div.requestFullscreen();
        else if (div.webkitRequestFullscreen)
            div.webkitRequestFullscreen();
        else if (div.msRequestFullScreen)
            div.msRequestFullScreen();
    }

    const getDurationCall = async () => {
        const t = await playerRef?.current?.getDuration();
        const elapsed_ms = Math.floor(t * 1000);
        let seconds = Math.floor((elapsed_ms / 1000) % 60),
            minutes = Math.floor((elapsed_ms / (1000 * 60)) % 60),
            hours = Math.floor((elapsed_ms / (1000 * 60 * 60)) % 24);
        let isHourLong = false;
        if (hours > 0) {
            isHourLong = true;
        }
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        totalDurationRef.current = t
        setTotalDurationSeconds(t);
        setDuration(
            isHourLong ? hours + ":" + minutes + ":" + seconds : minutes + ":" + seconds
        );

    }

    useEffect(() => {
        if (!postProgress) {
            return
        }
        if (topicId) {
            if (progressParam?.id && progressParam?.progress) {
                postUpdateVideoProgress(progressParam, (res) => {
                    if (res) {
                        getVideoResourcesData(itemIdVideoResource, () => { togglePageLoader(false); })
                    }
                });
            }
        }
    }, [renderPagePlayer])

    useEffect(() => {
        setHeight(containerRef.current.clientHeight)
    })

    const handleProgress = (state) => {
        const played = state?.played;
        const progressPercentage = Math.round(played * 100);
        const finalProgress = played >= 0.98 ? 100 : progressPercentage;
        setProgressParam((prev) => ({
            ...prev,
            id: topicId,
            progress: finalProgress,
        }));
        setVideoProgress(played);


    };

    const handleVideoEnd = () => {
        onChangeState('ended')
        setRenderPagePlayer(renderPagePlayer + 1);
        // setVideoCompletionStatus((prevStatus) => ({
        //     ...prevStatus,
        //     [topicId]: true,
        // }));
        setVideoProgress(0);
    };

    const handleVideoPause = () => {
        onChangeState('paused')
        setRenderPagePlayer(renderPagePlayer + 1);
        const player = playerRef.current;
        if (player) {
            setVideoProgress(player.getCurrentTime() / player.getDuration());
        }
    };



    const onChangeState = (state) => {
        setPreviousState(playerState);
        setPlayerState(state);
        if (state === 'playing') {
            setShowPlayIcon(false);
        }
        if (state === 'unstarted') {
            setInit5Sec(true)
        }
        if (state === 'playing' && playerState === 'buffering') {
            setInit5Sec(true);
            clearTimeout(t5);
            t5 = setTimeout(() => {
                setInit5Sec(false);
            }, 4501);
        }
        if (previousState == 'unstarted' || previousState == 'unloaded' || previousState == undefined) {
            clearTimeout(t4);
            clearTimeout(t1);
            setHalfScreenMode(true);
            t1 = setTimeout(() => {
                setHalfScreenMode(false)
            }, 4500);
            if (previousState === 'unloaded') {
                getDurationCall();
            }
        }
        if (state === 'playing' && (playerState === 'paused' || (playerState === 'buffering' && previousState === 'paused'))) {
            clearTimeout(t4);
            clearTimeout(t3);
            t3 = setTimeout(() => {
                setHalfScreenMode(false);
            }, 4500);
        }
        if (state === 'paused') {
            clearTimeout(t3);
            clearTimeout(t1);
            clearTimeout(t2);
            setHalfScreenMode(true);
        }
        if (state === 'playing' || state === 'unstarted' || state === 'buffering') {
            // setPlaying(true);
        } else {
            setPlaying(false);
        }
    }


    useEffect(() => {
        //   document.querySelector('#customytplayer').addEventListener('mousemove', function(event) {
        //     // Mouseover (hover) event handling logic here
        //     clearTimeout(hovertimeout)
        //     if (!hovering){
        //         setHovering(true)
        //     }
        //     hovertimeout = setTimeout(()=>{
        //         setHovering(false)
        //     }, 3500);
        //   });

        //   document.querySelector('#customytplayer').addEventListener('mouseout', function(event) {
        //     // Mouseout (hover out) event handling logic here
        //     if (hovering){
        //         setHovering(false)
        //     }
        //   });
        //   document.querySelector('#customytplayer').addEventListener('mouseleave', function(event) {
        //     // Mouseout (hover out) event handling logic here
        //     if (hovering){
        //         setHovering(false)
        //     }
        //   });
        clearInterval(interval);
        interval = setInterval(async () => {
            const elapsed_sec = await playerRef.current.getCurrentTime(); // this is a promise. dont forget to await
            elapsedSecondsRef.current = elapsed_sec
            setElapsedSeconds(elapsed_sec);
            const t = await playerRef?.current?.getDuration();
            const full_ms = Math.floor(t * 1000);
            const fullhours = Math.floor((full_ms / (1000 * 60 * 60)) % 24);
            // calculations
            const elapsed_ms = Math.floor(elapsed_sec * 1000);
            let seconds = Math.floor((elapsed_ms / 1000) % 60),
                minutes = Math.floor((elapsed_ms / (1000 * 60)) % 60),
                hours = Math.floor((elapsed_ms / (1000 * 60 * 60)) % 24);
            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            setElapsed(
                fullhours > 0 ? hours + ":" + minutes + ":" + seconds : minutes + ":" + seconds
            );
        }, 1000);
        return () => {
            clearInterval(interval);
            clearTimeout(t1);
            clearTimeout(t2);
            clearTimeout(t3);
            clearTimeout(t4);
            clearTimeout(t5);
        }
    }, [])

    console.log("hoverEffect12", hoverEffect);
    useEffect(() => {
        if (hoverEffect) {
            setMuted(false)
            setPlaying(false);

            setTimeout(() => {
                setMuted(true)
                setPlaying(true);


            }, 1000)
        }
    }, [])
    const handleKeyDown = (event) => {
        if (!playerRef.current) return;
        switch (event.key) {
          case ' ': // Space to toggle play/pause
            event.preventDefault(); // Prevent spacebar from scrolling
            setPlaying((playing)=>!playing);
            break;
          case 'ArrowRight': // Right arrow to seek forward
            playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
            break;
          case 'ArrowLeft': // Left arrow to seek backward
            playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
            break;
          case 'ArrowUp': // Up arrow to increase volume
            setVolume(volume => Math.min(volume + 0.1, 1));
            break;
          case 'ArrowDown': // Down arrow to decrease volume
            setVolume(volume => Math.max(volume - 0.1, 0));
            break;
          default:
            break;
        }
      };
    const cleanUp = () => {
        if (KEYBOARD_CONTROLS_ENABLED){
            window.removeEventListener('keydown', handleKeyDown);
        }
    }
    useEffect(()=>{
        if (KEYBOARD_CONTROLS_ENABLED){
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            cleanUp();
        }
    }, []);

    return (
        <>

            <div id="customytplayer"
                style={
                    isFullScreen ?
                        {
                            position: "fixed", top: 0, left: 0, zIndex: 1000
                        }
                        :
                        { position: "relative", height: "100%", width: "100%" }
                } ref={containerRef}
                onClick={() => {
                    setToggler(!toggler)
                    if (playerState === 'playing') {
                        if (isHalfScreenMode === false) {
                            clearTimeout(t4);
                            clearTimeout(t2);
                            t2 = setTimeout(() => {
                                setHalfScreenMode(false);
                            }, 4500);
                            setHalfScreenMode(true);
                        } else {
                            setPlaying(false)
                        }
                    } else if (playerState === 'paused') {
                        setPlaying(true);
                    }
                }}
            >
                <div style={{ pointerEvents: "none", height: "100%" }}

                >
                    {console.log("abcdefgh259", isMuted, isPlaying)}

                    <ReactPlayer
                        ref={playerRef}
                        height={isFullScreen ? "100vh" : "100%"}
                        width={isFullScreen ? "100vw" : "100%"}
                        muted={isMuted}

                        // // Disable right click
                        onContextMenu={e => e.preventDefault()}
                        url={videoUrl}
                        controls={false}
                        playing={isPlaying}
                        volume={volume}
                        onBuffer={() => { onChangeState('buffering') }}
                        onProgress={handleProgress}
                        onEnded={handleVideoEnd}
                        onPause={handleVideoPause}
                        onPlay={() => { onChangeState('playing') }}
                        onReady={() => { onChangeState('unstarted') }}
                        onStart={() => { onChangeState('playing') }}
                        playIcon={<PlayCircleTwoTone style={{ fontSize: 50 }} />}
                        style={{ borderRadius: !isFullScreen ? 17 : "", overflow: !isFullScreen ? "hidden" : "" }}
                    />
                </div>
                {hoverEffect ? (<>
                    {
                        showPlayIcon || isHalfScreenMode || playerState === 'ended' || isInit5Sec
                            ?
                            <div
                                style={{
                                    // marginTop: 30,
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: blockColor,
                                    width: '100%',
                                    height: height < 250 ? '30%' : '17%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 0, // Set a lower z-index to place it behind the video player
                                    padding: 10,
                                    borderRadius: !isFullScreen ? "16px 16px 0px 0px" : ""
                                }}
                            >
                                <div style={{ color: "white", fontWeight: "bold" }}>{title}</div>
                            </div>
                            : null
                    }

                    <>
                        <div
                            style={{
                                width: '100%',
                                height: height < 350 ? '30%' : '15%',
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                zIndex: 0, // Set a lower z-index to place it behind the video player
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                padding: 20,
                                borderRadius: !isFullScreen ? "0px 0px 16px 16px" : "",
                            }}>
                            <div style={{ display: "flex", justifyContent: 'flex-end' }} onClick={(e) => {
                                e.stopPropagation()
                                setMuted(!isMuted);
                            }}>
                                <div style={{
                                    position: 'relative',
                                    height: 33,
                                    width: 34,
                                    border: "1px solid #AEAEAE",
                                    borderRadius: "50px",
                                    padding: 5,
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "#AEAEAE",
                                        borderRadius: "50px",
                                        opacity: 0.5,
                                        zIndex: -1
                                    }} />
                                    {
                                        isMuted ?
                                            <GoMute style={{ color: "white", fontSize: "20px" }} />
                                            :
                                            <GoUnmute style={{ color: "white", fontSize: "20px" }} />
                                    }
                                </div>

                            </div>

                        </div>
                    </>{

                        (isHalfScreenMode || playerState === 'ended' || (isInit5Sec)) && playerState != 'unstarted' ?
                            <div
                                style={{
                                    backgroundColor: blockColor,
                                    width: '100%',
                                    height: height < 350 ? '30%' : '15%',
                                    position: 'absolute',
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 0, // Set a lower z-index to place it behind the video player
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    padding: 20,
                                    borderRadius: !isFullScreen ? "0px 0px 16px 16px" : "",
                                }}>
                                <div style={{ display: "flex", justifyContent: 'flex-end' }} onClick={(e) => {
                                    e.stopPropagation()
                                    setMuted(!isMuted);
                                }}>
                                    {
                                        isMuted ?
                                            <GoMute style={{ color: "white", fontSize: "21px" }} />
                                            :
                                            <GoUnmute style={{ color: "white", fontSize: "21px" }} />
                                    }
                                </div>

                            </div>
                            : null

                    }

                    {
                        showPlayIcon || playerState === 'ended' || playerState === 'unstarted' ?
                            <div style={{
                                position: 'absolute',
                                width: 70,
                                height: 50,
                                left: '50%',
                                top: top,
                                transform: 'translateX(-50%)',
                                borderRadius: 10,
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: blockColor,
                                cursor: "pointer",
                            }} onClick={() => {
                                if (playerState === 'unstarted' || playerState === 'ended') {
                                    setPlaying(true);
                                    setTimeout(() => {
                                        playerRef.current.seekTo(0, 'fraction')
                                    }, 500)
                                }
                            }}>
                                <PlayCircleFilled style={{ color: "white", fontSize: "21px" }} />
                            </div>
                            : null
                    }
                    {
                        showPlayIcon || playerState === 'unstarted' || playerState === 'unloaded' ?
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                display: 'flex',
                                // alignItems: 'flex-start',
                                paddingLeft: 20,
                                height: height < 350 ? '30%' : "12%",
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexDirection: 'row',
                                width: "100%",
                                borderRadius: !isFullScreen ? "0px 0px 16px 16px" : "",
                                backgroundColor: blockColor,
                            }}>
                            </div>
                            : null
                    }
                </>) :
                    (<>
                        {
                            showPlayIcon || isHalfScreenMode || playerState === 'ended' || isInit5Sec
                                ?
                                <div
                                    style={{
                                        // marginTop: 30,
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: blockColor,
                                        width: '100%',
                                        height: height < 350 ? '30%' : '17%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 0, // Set a lower z-index to place it behind the video player
                                        padding: 10,
                                        borderRadius: !isFullScreen ? "16px 16px 0px 0px" : ""
                                    }}
                                >
                                    <div style={{ color: "white", fontWeight: "bold" }}>{title}</div>
                                </div>
                                : null
                        }

                        {
                            (isHalfScreenMode || playerState === 'ended' || (isInit5Sec)) && playerState != 'unstarted' ?
                                <div
                                    style={{
                                        backgroundColor: blockColor,
                                        width: '100%',
                                        height: height < 350 ? '30%' : '15%',
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 0, // Set a lower z-index to place it behind the video player
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        padding: 20,
                                        borderRadius: !isFullScreen ? "0px 0px 16px 16px" : "",
                                    }}>
                                    <div className='r-c-c cursor-pointer' onClick={() => {
                                        if (playerState === 'ended') {
                                            clearTimeout(t4);
                                            t4 = setTimeout(() => {
                                                setPlaying(true);
                                            }, 100);
                                        }
                                        setPlaying(!isPlaying)
                                    }}>
                                        {
                                            playerState === 'ended' ?
                                                <ReloadOutlined style={{ color: "white", fontSize: "21px" }} />
                                                :
                                                isPlaying ?
                                                    <PauseCircleFilled style={{ color: "white", fontSize: "21px" }} />
                                                    :
                                                    <PlayCircleFilled style={{ color: "white", fontSize: "21px" }} />
                                        }
                                    </div>
                                    <div className='m-l-10 r-c-c cursor-pointer' onClick={(e) => {

                                        e.stopPropagation()
                                        setMuted(!isMuted);


                                    }}>
                                        {
                                            isMuted ?
                                                <GoMute style={{ color: "white", fontSize: "21px" }} />
                                                :
                                                <GoUnmute style={{ color: "white", fontSize: "21px" }} />
                                        }
                                    </div>
                                    <div style={{ color: isLive ? "black" : "white", marginLeft: 10, fontSize: 12, fontWeight: "bold", whiteSpace: "nowrap" }}>{playerState === 'ended' ? duration : elapsed} / {duration}</div>
                                    <div style={{ width: "100%", paddingLeft: 10, paddingRight: 10, visibility: isLive ? 'hidden' : 'visible' }}>
                                        <Slider
                                            style={{ width: '100%' }}
                                            min={0}
                                            step={1}
                                            tooltip={{ formatter: null }}
                                            max={totalDurationSeconds}
                                            onChange={(value) => {
                                                if (isLive) {
                                                    return
                                                }
                                                playerRef.current.seekTo(value, 'seconds')
                                                setElapsedSeconds(value)
                                            }}
                                            value={playerState === 'ended' ? totalDurationSeconds : elapsedSeconds}
                                            onAfterChange={(value) => {
                                                // playerRef.current.seekTo(value, 'seconds')
                                            }}
                                        />
                                    </div>
                                    <div className='m-l-10 r-c-c cursor-pointer' onClick={(e) => {
                                        e.stopPropagation()
                                        setFullScreen(!isFullScreen)
                                        if (!isFullScreen) {
                                            makeFullScreen()
                                        } else {
                                            document.exitFullscreen();
                                        }
                                    }}>
                                        <FullscreenOutlined style={{ color: "white", fontSize: "21px" }} />
                                    </div>
                                </div>
                                : null
                        }

                        {
                            showPlayIcon || playerState === 'ended' || playerState === 'unstarted' ?
                                <div style={{
                                    position: 'absolute',
                                    width: 80,
                                    height: 60,
                                    left: '50%',
                                    top: top,
                                    transform: 'translateX(-50%)',
                                    borderRadius: 10,
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: blockColor,
                                    cursor: "pointer",
                                }} onClick={() => {
                                    if (playerState === 'unstarted' || playerState === 'ended') {
                                        setPlaying(true);
                                        setTimeout(() => {
                                            playerRef.current.seekTo(0, 'fraction')
                                        }, 500)
                                    }
                                }}>
                                    <PlayCircleFilled style={{ color: "white", fontSize: "21px" }} />
                                </div>
                                : null
                        }
                        {
                            showPlayIcon || playerState === 'unstarted' || playerState === 'unloaded' ?
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    display: 'flex',
                                    // alignItems: 'flex-start',
                                    paddingLeft: 20,
                                    height: height < 350 ? '30%' : "12%",
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    width: "100%",
                                    borderRadius: !isFullScreen ? "0px 0px 16px 16px" : "",
                                    backgroundColor: blockColor,
                                }}>
                                </div>
                                : null
                        }
                    </>)}

            </div>
            <PageDataLoader visible={pageLoader} />
        </>
    )
}

const mapStateToProps = (state) => {
    const { } = state?.studentMarketPlace
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    setVideoCompletionStatus: (val) => dispatch(setVideoCompletionStatus(val)),
    postUpdateVideoProgress: (param, callback) => dispatch(postUpdateVideoProgress(param, callback)),
    getVideoResourcesData: (id, callback) => dispatch(getVideoResourcesData(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(YTPlayer)