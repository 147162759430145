import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import './careStyles.css';
import UserProfileCards from './userProfileCards';
import CareNavbar from './careNavbar';
import { getCareUsers, setSpecificStudentUseId, setDate, setName, setBatch,setMonthOrWeek, setRole } from 'actions/adminCare';
import { connect } from 'react-redux';
import { IMG_URL } from '../../env.json';
import { useEffect } from 'react';
import { useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import image1 from '../../Assets/care/no-subjects.svg';
import moment from "moment";


function ConversationPage({ getCareUsers, setName, setDate, setMonthOrWeek, batch, role, setRole, setBatch, date, name, recentUserList, setSpecificStudentUseId, specificStudentId }) {
    const [pageLoader, togglePageLoader] = useState(false)
    useEffect(() => {
        togglePageLoader(true);
        getCareUsers(batch, role, date, name, () => togglePageLoader(false));
        return () => {

        };
    }, [batch, role])

    const history = useHistory();

    const handleViewChatlist = (userId) => {
        setSpecificStudentUseId(userId)
        history.push('/care/admin/conversations/user')
        setName('');
        setDate('');
        setRole(null);
        setBatch(null);
    }

    const goBack = () => {
        history.goBack()
        setName('');
        setDate(moment().format('YYYY-MM-DD'));
        setRole(null);
        setBatch(null);
        setMonthOrWeek('week')
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <PageHeader
                    title={<div className="text-md" style={{ fontFamily: "roboto", fontWeight: "bold" }}> Conversation</div>}
                    onBack={() => goBack()}
                    backIcon={<ArrowLeftOutlined />}
                />
                <CareNavbar togglePageLoader={(bool) => togglePageLoader(bool)} />
            </div>
            {recentUserList?.length >= 1 ?
                <div>
                    <div className="recentUserList" style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>

                        {recentUserList?.map((item) =>
                            < div key={item.id} onClick={() => handleViewChatlist(item?.chat_userId)}>
                                <UserProfileCards imgUrl={`${IMG_URL}/${item?.user_image}`} userName={item?.user_name} role={item?.user_role} message={item.message} createdAt={item.chat_createdAt} userId={item.id} type={item.chat_type} chat={false} unreadCount={item.unreadMessage}/>
                            </div>
                        )
                        }
                        <PageDataLoader visible={pageLoader} />
                    </div>

                </div>
                :
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "500px", borderRadius: "10px", padding: "20px" }}>
                        <img style={{ width: "30%", height: "30%", marginBottom: "20px" }} src={image1} alt="" />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No conversation available!</h1>
                            <p style={{ color: "#636363", fontFamily: "roboto", textAlign: "center", width: "250px" }}>There are no conversation available now,try again later. </p>
                        </div>
                    </div>
            </div>
        }
    </div>)
}

// export default ConversationPage


const mapStateToProps = state => {
    const {
        recentUserList,
        batch,
        role,
        date,
        name,
        specificStudentId,

    } = state.adminCare;
    return {
        recentUserList,
        batch,
        role,
        date,
        name,
        specificStudentId
    };
};

const mapDispatchToProps = dispatch => ({

    getCareUsers: (batch, role, date, name, callback) => dispatch(getCareUsers(batch, role, date, name, callback)),
    setSpecificStudentUseId: (userId) => dispatch(setSpecificStudentUseId(userId)),
    setName: (m) => dispatch(setName(m)),
    setDate: (m) => dispatch(setDate(m)),
    setRole: (m) => dispatch(setRole(m)),
    setBatch: (m) => dispatch(setBatch(m)),
    setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m))

});


export default connect(mapStateToProps, mapDispatchToProps)(ConversationPage);
