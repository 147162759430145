import moment from "moment";
import {
  TOGGLE_LOGIN_LOADER,
  IS_RESET_DISABLED,
  TOGGLE_RESET_LOADER,
  SET_EMAIL,
  UPDATE_NOTIFICATION_BADGELIST,
  CHANGE_STATUS_IN_TRAY_IN_TRAY,
  LOGOUT,
  SEND_OTP_LOADER_TOGGLE,
  OTP_PAGE_SHOW,
  HANDLE_RESEND_OTP_TIMER,
  VERIFY_OTP_LOADER_TOGGLE,
  SET_SUPER_PALLETE, //Pallete i.e. login or Register
  SET_PALLETE,
  UPDATE_ORG_DATA,
  UPDATE_ORGS_STANDARD_LIST,
  APPROVE_LOADER,
  REJECT_LOADER,
  SET_VERIFY_OTP_MODAL,
  UPDATE_REGISTER_DETAILS,
  CLEAR_REGISTER_DETAILS,
  VERIFIED_OTP_FOR_REGISTRATION,
} from "../actions/login";

const initialState = {
  loginLoader: false,
  sendOtpLoader: false,
  isResetDisabled: true,
  resetLoader: false,
  notifyBadgeList: [],
  notifyBadgeListCount: "",
  otpPageShow: false,
  superPalletKey: '1',
  superPalletTab: 'login',
  palleteKey: "1",
  palletTab: 'email',
  resendOtpTimer: false,
  verifyOtpLoader: false,
  timer: 30,
  email: "",
  offset: new Date().getTimezoneOffset(),
  today: moment(new Date()).format("YYYY-MM-DD"),
  weekday: new Date().getDay(),
  orgData: {
    logo: "",
    orgId: ""
  },
  orgStandards:[],
  rejectLoader: false,
  approveLoader: false,
  verfiyOtpModal: false,
  verifiedOtpforRegistration: false,
  userRegister: {
    name: '',
    role: '',
    phone: '',
    email: '',
    phoneWithCode: '',
    address: {city: '', state: '',pincode: ''},
    preferredClass: '',
    preferredCourseId:0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REGISTER_DETAILS:
      return {
        ...state,
        userRegister: {
          name: '',
          phone: '',
          email: '',
          phoneWithCode: '',
          address: {city: '', state: '',pincode: ''},
          role: '',
          preferredClass: '',
          preferredCourseId: 0,
        },
        verifiedOtpforRegistration : false,
        
      };
    case UPDATE_REGISTER_DETAILS:
      if (action.key == 'name') {
        state.userRegister.name = action.data;
      } else if (action.key == 'image') {
        state.userRegister.image = action.data;
      } else if (action.key == 'email') {
        state.userRegister.email = action.data;
      } else if (action.key == 'phone') {
        (state.userRegister.phone = action.data);
          // (state.userRegister.phoneWithCode = `+${action.data.phoneCode}${action.data}`);
      } else if (action.key == 'city') {
        state.userRegister.address.city = action.data;
      } else if (action.key == 'state') {
        state.userRegister.address.state = action.data;
      } else if (action.key == 'preferredClass') {
        state.userRegister.preferredClass = action.data;
      } else if (action.key == 'pincode') {
        state.userRegister.address.pincode = action.data;
      } else if (action.key == 'role') {
        state.userRegister.role = action.data;
      } else if (action.key == 'preferredCourseId') {
        state.userRegister.preferredCourseId = action.data;
      }
      return {
        ...state,
        userRegister: {...state.userRegister},
      }; 
    case VERIFIED_OTP_FOR_REGISTRATION:
      return {
        ...state,
        verifiedOtpforRegistration: action.bool,
      };
    case REJECT_LOADER:
      return {
        ...state,
        rejectLoader: action.bool,
      };
    case SET_VERIFY_OTP_MODAL:
      return {
        ...state,
        verfiyOtpModal: action.bool,
      };
    case APPROVE_LOADER:
      return {
        ...state,
        approveLoader: action.bool,
      };
    case UPDATE_ORG_DATA:
      return {
        ...state,
        orgData: { ...action.orgData }
      };
    case UPDATE_ORGS_STANDARD_LIST:
      return {
        ...state,
        orgStandards: [ ...action.orgStandards ]
      };
    case IS_RESET_DISABLED:
      return {
        ...state,
        isResetDisabled: action.value
      };
    case SET_SUPER_PALLETE:
      return {
        ...state,
        superPalletKey: action.num,
        superPalletTab: action.tab
      };
    case SET_PALLETE:
      return {
        ...state,
        palleteKey: action.num,
        palletTab: action.tab
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      };
    case TOGGLE_LOGIN_LOADER:
      return {
        ...state,
        loginLoader: action.value
      };
    case TOGGLE_RESET_LOADER:
      return {
        ...state,
        resetLoader: action.value
      };
    case SEND_OTP_LOADER_TOGGLE:
      return {
        ...state,
        sendOtpLoader: action.bool
      };
    case OTP_PAGE_SHOW:
      return {
        ...state,
        otpPageShow: action.bool
      };
    case HANDLE_RESEND_OTP_TIMER:
      return {
        ...state,
        resendOtpTimer: action.bool,
        timer: action.sec
      };
    case VERIFY_OTP_LOADER_TOGGLE:
      return {
        ...state,
        verifyOtpLoader: action.bool
      };
    case UPDATE_NOTIFICATION_BADGELIST:
      return {
        ...state,
        notifyBadgeList: action.res ? action.res.notifications : [],
        notifyBadgeListCount: action.res
          ? action.res.unreadNotificationCount
          : 0
      };
    case CHANGE_STATUS_IN_TRAY_IN_TRAY:
      if(action.index > 0) state.notifyBadgeList[action.index].status = action.status;
      const newBadgeList = state.notifyBadgeList;
      return {
        ...state,
        notifyBadgeList: [...newBadgeList],
        notifyBadgeListCount: state.notifyBadgeListCount > 0 ? state.notifyBadgeListCount - 1 : 0
      };
    case LOGOUT:
      return {
        superPalletKey:"1",
        superPalletTab:"login",
        palleteKey: "1",
        palletTab :'email',
        timer: 30,
        loginLoader: false,
        sendOtpLoader: false,
        isResetDisabled: true,
        resetLoader: false,
        resendOtpTimer: false,
        verifyOtpLoader: false,
        userRegister: {
          name: '',
          image: '',
          phone: '',
          email: '',
          phoneWithCode: '',
          address: {city: '', state: '',pincode: ''},
          role: '',
          preferredClass: '',
          preferredCourseId:0,
        },
      };
    default:
      return state;
  }
};

export default reducer;
