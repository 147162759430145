import React from 'react';
import { connect } from 'react-redux';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import ReactHtmlParser from 'react-html-parser';
import { AiOutlineCloseCircle, AiOutlineFileText } from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { LoadingOutlined, } from '@ant-design/icons';
import './styles.css';
import { Color } from 'services';
import { Col, Row, Typography, Select, Menu, Dropdown } from 'antd';
import TopNavigationColorHeader from './localComponent/topNavigationColorHeader';
import TestPublishModal from './localComponent/testPublishModal';
import {
  fetchSubmittedTestByStudentId,
} from 'actions/testAdmin';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import { Api } from 'services';
import Modal from 'antd/lib/modal';
import TeX from '@matejmazur/react-katex';


function AnswerPreviewModal({ isPreviewModalVisible,
  image, setIsPreviewModalVisible
}) {
  return (
    <Modal wrapClassName="imagePreview" centered={true} footer={null} visible={isPreviewModalVisible}>
      <div className="r-c-fe m-b-5 cursor-pointer" style={{ color: "black" }} onClick={() => {
        setIsPreviewModalVisible(false);
      }}>
        X
      </div>
      <div style={{ width: "fit-content", height: "fit-content" }}><img style={{ width: "100%", height: "100%" }} src={`${image}`} alt="" /></div>
    </Modal>
  );
}

const RowOptions = ({ question }) => {
  let answerTypes = [0, 0, 0, 0];
  let correctAnswer = question.correctAnswer;
  let studentAnswer = question.answer;
  const optionsObj = JSON.parse(question.options);
  if (question.options != undefined) {
    for (let i = 0; i < optionsObj.length; i++) {
      if ("" + optionsObj[i].id === studentAnswer) {
        if (studentAnswer === correctAnswer) {
          answerTypes[i] = 1
        }
        else {
          answerTypes[i] = 2
        }
      }
      else if ("" + optionsObj[i].id === correctAnswer) {
        if (studentAnswer !== correctAnswer) {
          answerTypes[i] = 3
        }
        else {
          answerTypes[i] = 0
        }
      }
      else {
        answerTypes[i] = 0
      }
    }
  }
  return (
    <div className='m-t-10' style={{ marginLeft: -20 }}>
      {JSON.parse(question.options)?.map((opt, index) => (
        <div keys={opt.id} className='r-ac m-b-10 m-l-20' >
          <div
            style={{
              background: answerTypes[index] === 1 ? '#28df99' : answerTypes[index] === 2 ? '#FF414D' : answerTypes[index] === 3 ? '#28df99' : '',
              color: answerTypes[index] !== 0 ? '#fff' : '',
              borderColor: ((answerTypes[index] === 1) || (answerTypes[index] === 3)) ? '#28df99' : answerTypes[index] === 2 ? '#FF414D' : ''
            }}
            className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
            {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'}
          </div>
          <div
            style={{ background: ((answerTypes[index] === 1) || (answerTypes[index] === 3)) ? '#E9FCF5' : answerTypes[index] === 2 ? '#FFE4D9' : '' }}
            className='test-ques-window p-10 r-c-sb flex-1'>
            <div className='flex-1 resize-editor-img'>
              {ReactHtmlParser(opt.value,
                {
                  transform: (node) => {
                    if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                      return <TeX math={`${node.attribs['data-value']}`} />;
                    }
                    if (node.type === 'tag' && node.name === 'img') {
                      return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                    }
                  }
                })
              }
            </div>
            <div className='r-ac m-l-5'>
              {((answerTypes[index] === 1) || (answerTypes[index] === 3))
                ?
                <RiCheckboxCircleLine className='text-sm seaGreen' />
                : answerTypes[index] === 2
                  ?
                  <RiCloseCircleLine className='text-sm red' />
                  :
                  null
              }
              {answerTypes[index] === 1
                ?
                <div className='m-l-5 text-xs a2grey'>Student's answer</div>
                : answerTypes[index] === 2
                  ?
                  <div className='m-l-5 text-xs a2grey'>Student's answer</div>
                  :
                  answerTypes[index] === 3
                    ?
                    <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                    :
                    null
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}


const RowNumeric = ({ question }) => {
  let answerType = question.answer === question.correctAnswer ? 1 : 0

  return (
    <div className='m-b-20' style={{ borderRadius: 4 }}>
      {
        answerType === 1 && question?.submitStatus !== 'Unanswered' && question?.submitStatus !== 'Bookmarked'
          ?
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className='r-c-sb cursor-pointer m-b-10' style={{ background: "rgb(233, 252, 245)", borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
              <div className='text-xmd m-l-10'> {question?.answer === '' || question?.answer === null ? 'Unanswered' : question.answer} </div>
              <div className="m-r-10 text-xs a2grey">
                <RiCheckboxCircleLine className='text-sm seaGreen' /> Student's Answer
              </div>
            </div>
          </div>
          :
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className='r-c-sb cursor-pointer m-b-10' style={{ background: "rgb(233, 252, 245)", borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
              <div className='text-xmd m-l-10'> {question.correctAnswer} </div>
              <div className="m-r-10 text-xs a2grey">
                <RiCheckboxCircleLine className='text-sm seaGreen' /> Correct Answer
              </div>
            </div>
            {
              question?.submitStatus !== 'Unanswered' && question?.submitStatus !== 'Bookmarked'
                ?
                <div className='r-c-sb cursor-pointer' style={{ background: '#FFE4D9', borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
                  <div className='text-xmd m-l-10'>{question.answer}</div>
                  <div className="m-r-10 text-xs a2grey">
                    <RiCloseCircleLine className='text-sm red' /> Student's Answer
                  </div>
                </div>
                :
                <div className='r-c-sb cursor-pointer' style={{ background: 'rgb(174 174 174)', borderColor: '#EC5254', borderRadius: 5, height: 50 }}>
                  <div className='text-xs m-l-10' style={{ color: "#fafafa" }}>Unanswered</div>
                  <div className="m-r-10 text-xs a2grey" style={{ color: "#fafafa" }}>
                    Student's Answer
                  </div>
                </div>
            }
          </div>
      }

    </div>
  )
}

function EvaluationQuestionsListView({ history,
  generateQuesListViewBool,
  generateQuesListViewData,
  submittedTestsByStudentId,
  getEvaluationPageBool,
  fetchSubmittedTestByStudentId, }) {

  const QuesAnsView = ({ questionData, indexMain }) => {

    const QuestionContainer = ({ questionData, indexMain, comprehensiveData, orQuestionsData, compIndex }) => {
      console.log('indexMain', indexMain)
      console.log('questionData', questionData)
      console.log('comprehensiveData', comprehensiveData)
      console.log('orQuestionsData', orQuestionsData)
      if (questionData?.type === 'Comprehensive') {
        return (
          <div>
            <div className='r-jsb m-t-20'>
              <div className='display-flex flex-1'>
                <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}.</div>
                <div className='text-xmd color-black resize-editor-img'>
                  {ReactHtmlParser(questionData?.questionContent?.paragraph,
                    {
                      transform: (node) => {
                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                          return <TeX math={`${node.attribs['data-value']}`} />;
                        }
                        if (node.type === 'tag' && node.name === 'img') {
                          return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                        }
                      }
                    })
                  }
                </div>
              </div>
            </div>
            <div className='r-jsb m-t-20'>
              <div className='display-flex flex-1'>
                <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}{compIndex !== null && compIndex !== undefined ? `(${String.fromCharCode('a'.charCodeAt() + compIndex)}).` : null}</div>
                <div className='text-xmd color-black resize-editor-img'>
                  {ReactHtmlParser(comprehensiveData?.questionContent?.statement,
                    {
                      transform: (node) => {
                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                          return <TeX math={`${node.attribs['data-value']}`} />;
                        }
                        if (node.type === 'tag' && node.name === 'img') {
                          return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                        }
                      }
                    })
                  }
                </div>
              </div>
              <div className='text-xmd a2grey m-l-20'>+ {comprehensiveData?.positiveMarks} | - {comprehensiveData?.negativeMarks}</div>
            </div>
          </div>
        )
      } else if (questionData?.type === 'OrQuestions') {
        return (
          <div className='r-jsb m-t-20'>
            <div className='display-flex flex-1'>
              <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}.{orQuestionsData?.orQuestionIndexChar}</div>
              <div className='text-xmd color-black resize-editor-img'>
                {ReactHtmlParser(orQuestionsData?.questionContent?.statement,
                  {
                    transform: (node) => {
                      if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                        return <TeX math={`${node.attribs['data-value']}`} />;
                      }
                      if (node.type === 'tag' && node.name === 'img') {
                        return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                      }
                    }
                  })
                }
              </div>
            </div>
            <div className='text-xmd a2grey m-l-20'>+ {questionData?.positiveMarks} | - {questionData?.negativeMarks}</div>
          </div>
        )

      } else {
        return (
          <div className='r-jsb m-t-20'>
            <div className='display-flex flex-1'>
              <div className='text-xmd bold-600 color-black m-r-5'>{indexMain}.</div>
              <div className='text-xmd color-black resize-editor-img'>
                {ReactHtmlParser(questionData?.questionContent?.statement,
                  {
                    transform: (node) => {
                      if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                        return <TeX math={`${node.attribs['data-value']}`} />;
                      }
                      if (node.type === 'tag' && node.name === 'img') {
                        return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                      }
                    }
                  })
                }
              </div>
            </div>
            {questionData.type === 'Subjective'
              ?
              <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks}</div>
              :
              <div className='text-xmd a2grey m-l-20'>+ {questionData.positiveMarks} | - {questionData.negativeMarks}</div>
            }
          </div>

        )
      }
    }

    const AnswerContainer = ({ answerData, questionData, identifier, answerIndex }) => {

      if (answerData?.type === 'MCQ' || answerData?.type === 'CMCQ' || answerData?.type === 'CMSQ' || answerData?.type === 'MSQ' || questionData?.type === 'MCQ' || questionData?.type === 'MSQ') {
        return (
          <div className='m-t-20' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.questionContent?.options : questionData?.questionContent?.options)?.map((opt, i) => (
              <div keys={opt.id} className='test-ques-window r-ac m-b-10 p-10' style={{ background: opt.correct ? '#E9FCF5' : '', width: '49%' }}>
                <div
                  style={{ background: opt.correct ? '#28df99' : '', color: opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : '' }}
                  className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                  {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                </div>
                <div
                  // style={{ background: opt.correct ? '#E9FCF5' : '' }}
                  className='r-c-sb flex-1'>
                  <div className='flex-1 resize-editor-img'>
                    <div>
                      {ReactHtmlParser(opt.value,
                        {
                          transform: (node) => {
                            if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                              return <TeX math={`${node.attribs['data-value']}`} />;
                            }
                            if (node.type === 'tag' && node.name === 'img') {
                              return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                            }
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className='r-ac m-l-5'>
                    {opt.correct
                      ?
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      :
                      <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                    }
                    <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )

      } else if (answerData?.type === 'Numeric' || answerData?.type === 'CNumeric' || questionData?.type === 'Numeric') {
        console.log('answerDataNUmeric', answerData)
        return (
          <div className='m-t-20'>
            <div className='text-xmd bold-600 color-black'>Answer</div>
            <div className='test-ques-window resize-editor-img p-10'>
              {ReactHtmlParser(((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer))}
            </div>
          </div>

        )

      }
      else if (answerData?.type === 'T/F' || answerData?.type === 'CTF' || questionData?.type === 'T/F') {

        return (
          <div className='m-t-20'>
            <div className='text-xmd bold-600 color-black m-b-5'>Options</div>
            <div style={{ display: 'flex' }}>
              <div className='test-ques-window r-ac m-r-10 p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#E9FCF5' : '', }}>
                <div
                  style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === true ? '#28df99' : '' }}
                  className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                  {'A'}
                </div>
                <div
                  style={{ width: "90%", overflowX: "hidden" }}
                  className='cursor-pointer p-10 r-c-sb'
                ><div>
                    True
                  </div>
                  <div className='r-ac m-l-5'>
                    {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === true
                      ?
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      :
                      <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                    }
                    <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                  </div>
                </div>
              </div>
              <div className='test-ques-window r-ac p-5' style={{ width: '85%', background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#E9FCF5' : '', }}>
                <div
                  style={{ background: ((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '', color: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#fff' : '', borderColor: ((identifier === 'Comp' || identifier === 'OrQues') ? answerData?.answerContent?.answer : questionData?.answerContent?.answer) === false ? '#28df99' : '' }}
                  className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                  {'B'}
                </div>
                <div
                  style={{ width: "100%", overflowX: "hidden" }}
                  className='cursor-pointer p-10 r-c-sb'
                >
                  <div>
                    False
                  </div>
                  <div className='r-ac m-l-5'>
                    {((identifier === 'Comp' || identifier === 'OrQues') ? questionData?.answerContent[answerIndex]?.answer : questionData?.answerContent?.answer) === false
                      ?
                      <RiCheckboxCircleLine className='text-sm seaGreen' />
                      :
                      <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                    }
                    <div className='m-l-5 text-xs a2grey'>Correct Answer</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )

      } else {
        return null
      }
    }

    const SolutionContainer = ({ solutionData, identifier, answerData }) => {

      console.log('solutionData', solutionData)

      return (
        <div className='m-t-20'>
          <div className='text-xmd bold-600 color-black'>Solution</div>
          <div className='test-ques-window resize-editor-img p-10'>
            {ReactHtmlParser((solutionData?.solution),
              {
                transform: (node) => {
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                  }
                }
              })
            }
          </div>
        </div>
      )

    }

    if (questionData?.type === 'Comprehensive') {
      return (
        questionData?.questionContent?.subQuestions?.map((item, index) => (
          <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
            <QuestionContainer questionData={questionData} indexMain={indexMain} compIndex={index} comprehensiveData={item} />
            <AnswerContainer answerData={item} questionData={questionData} identifier={'Comp'} answerIndex={index} />
            <SolutionContainer solutionData={item} identifier={'Comp'} />
          </div>
        ))
      )
    }
    else if (questionData?.type === 'OrQuestions') {
      return (
        questionData?.questionContent?.orQuestions?.map((item, index) => (
          <>
            <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
              <QuestionContainer questionData={questionData} indexMain={indexMain} orQuestionsData={item} />
              <AnswerContainer answerData={item} questionData={questionData} identifier={'OrQues'} answerIndex={index} />
              <SolutionContainer solutionData={item} identifier={'OrQues'} />
            </div>
            {index != questionData?.questionContent?.orQuestions?.length - 1 ?
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6', padding: '0 5px' }}></div>
                <span style={{ marginTop: -10, padding: '0px 10px', color: '#AEAEAE' }}>OR</span>
                <div style={{ width: '40%', height: 1, borderBottom: '1px solid #E6E6E6' }}></div>
              </div>
              : null
            }
          </>
        ))

      )
    } else {

      return (
        <div className='hoverBackWhite' style={{ padding: '20px 20px' }}>
          <QuestionContainer questionData={questionData} indexMain={indexMain} />
          <AnswerContainer questionData={questionData} />
          <SolutionContainer solutionData={questionData} />
        </div>
      )
    }


  }



  const [pageLoader, togglePageLoader] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  useEffect(() => {
    document.getElementById("root-1-conditional-height-for-formula").style.overflowY = "hidden";
    togglePageLoader(true);
    fetchSubmittedTestByStudentId((bool) => togglePageLoader(bool));
    return () => {
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY = "auto";
    }
  }, [])

  if (getEvaluationPageBool) {
    return (
      <PageDataLoader visible={pageLoader} />
    )
  }

  const isEmptyHtml = (content) => {
    let pattern = /^(?:\s|<br>|<p>|<\/p>)*$/;
    return pattern.test(content);
  }

  return (
    <div style={{ height: '100%', margin: '-20px', backgroundColor: "#FAFAFA", overflowY: "auto", overflowX: "hidden" }}>
      <TopNavigationColorHeader history={history} listView={true} type={'studentAnswer'} />
      {generateQuesListViewBool
        ?
        <div className='r-c-c' style={{ height: 'calc(100% - 20px)' }}><LoadingOutlined /></div>
        :
        // Object.keys(submittedTestsByStudentId.questions).length
        // ?
        <div className='overview-scroll' style={{ height: 'calc(100% - 20px)' }}>
          <div className='r-c-fe p-20'>
            <div className='r-ac cursor-pointer' onClick={() => history.goBack()} >
              <AiOutlineCloseCircle className='text-sm blue m-r-5' />
              <div className='text-xmd bold-600 blue'>EXIT LIST VIEW</div>
            </div>
          </div>
          <div className='p-l-20 p-r-20 p-b-20'>
            {submittedTestsByStudentId?.subjects?.map((subject, index) => (
              <div keys={subject?.subjectId}>
                <div className='text-sm bold-600 color-black'>{subject?.subjectName}</div>
                {(subject?.sections && subject?.sections.length > 0)
                  ?
                  subject?.sections?.map((secData, secIndex) => (
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div key={secIndex} style={{ fontSize: '14px' }} className='text-sm bold-600 color-black'>{secData?.sectionName}</div>
                      </div>
                      {secData?.questions?.map((quesData, quesIndex) => (
                        <div className='m-b-20' keys={quesData.id}>
                          <QuesAnsView questionData={quesData} indexMain={quesData?.index} />
                        </div>

                      ))}
                    </div>
                  ))
                  :
                  subject?.questions?.map((data, index) => (
                    <div className='m-b-20' keys={data.id}>
                      <QuesAnsView questionData={data} indexMain={data?.index} />
                    </div>
                  ))}
              </div>
              // <div keys={index}>
              //   <div className='text-sm bold-600 color-black m-b-20'>{subject.subjectName}</div>
              //   {subject?.questions?.length
              //     ?
              //     subject.questions?.map((question, index) => (
              //       <div className='m-b-20 hoverBackWhite' keys={index} style={{ borderRadius: 5, padding: 15 }}>
              //         <div className='r-jsb m-t-20'>
              //           <div className='display-flex flex-1'>
              //             <div className='text-xmd bold-600 color-black m-r-5'>{question.index}.</div>
              //             <div className='text-xmd color-black resize-editor-img'>
              //               {ReactHtmlParser(question.question,
              //                 {
              //                   transform: (node) => {
              //                     if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
              //                       return <TeX math={`${node.attribs['data-value']}`} />;
              //                     }
              //                     if (node.type === 'tag' && node.name === 'img') {
              //                       return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
              //                     }
              //                   }
              //                 })
              //               }
              //             </div>
              //           </div>
              //           <div className='text-xmd a2grey m-l-20'> {question.marksObtained ? question.marksObtained : 0}</div>
              //         </div>
              //         {question.type === 'MCQ'
              //           ?
              //           <RowOptions question={question} />
              //           : (question.type === 'Numeric')
              //             ?
              //             <RowNumeric question={question} />
              //             : (question.type == "Subjective")
              //               ?
              //               <>
              //                 <div className='text-xmd bold-600 color-black m-b-10'>Student's Answer</div>
              //                 <>
              //                   {question.filename ?
              //                     <div className='r-ac' style={{ overflowX: "auto" }}>
              //                       {question.filename?.split(',')?.map((item, index) => (
              //                         <img src={`${Api._s3_url}${item}`} alt={""} width="160"
              //                           height="160" text-align="left"
              //                           style={{ display: 'block' }} className='m-t-10 m-r-10 cursor-pointer'
              //                           onClick={() => {
              //                             setPreviewImg(`${Api._s3_url}${item}`);
              //                             setIsPreviewModalVisible(true);
              //                           }}
              //                         />
              //                       ))}
              //                       <AnswerPreviewModal isPreviewModalVisible={isPreviewModalVisible}
              //                         image={previewImg} setIsPreviewModalVisible={setIsPreviewModalVisible} />
              //                     </div>
              //                     : ""
              //                   }
              //                 </>
              //               </>
              //               :
              //               null
              //         }
              //         {
              //           question?.type === 'Subjective' ?

              //             <div className='m-t-20'>
              //               <div className="r-ac m-b-10">
              //                 <div className='text-xmd bold-600 color-purple'>Comments</div>
              //               </div>
              //               {(question.comment != undefined && question?.comment?.trim() !== '')
              //                 ?
              //                 <div className='test-ques-window resize-editor-img p-10'>
              //                   {ReactHtmlParser(question.comment,
              //                     {
              //                       transform: (node) => {
              //                         if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
              //                           return <TeX math={`${node.attribs['data-value']}`} />;
              //                         }
              //                         if (node.type === 'tag' && node.name === 'img') {
              //                           return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
              //                         }
              //                       }
              //                     })
              //                   }
              //                 </div>
              //                 : <div className="m-t-10">NA</div>}
              //             </div>
              //             : null}
              //         <div className='m-t-20'>
              //           <div className="r-ac m-b-10">
              //             <AiOutlineFileText className='color-purple text-sm m-r-5' />
              //             <div className='text-xmd bold-600 color-purple'>Solution</div>
              //           </div>
              //           {(question.solution != undefined && !isEmptyHtml(question.solution))
              //             ?
              //             <div className='test-ques-window resize-editor-img p-10'>
              //               {ReactHtmlParser(question.solution,
              //                 {
              //                   transform: (node) => {
              //                     if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
              //                       return <TeX math={`${node.attribs['data-value']}`} />;
              //                     }
              //                     if (node.type === 'tag' && node.name === 'img') {
              //                       return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
              //                     }
              //                   }
              //                 })
              //               }
              //             </div>
              //             : <div className="m-t-10">NA</div>}
              //         </div>
              //       </div>
              //     ))
              //     :
              //     null}
              // </div>
            ))}

          </div>
        </div>
      }
      <TestPublishModal history={history} />
    </div>
  );
}


const mapStateToProps = state => {
  const { generateQuesListViewBool,
    generateQuesListViewData,
    submittedTestsByStudentId,
    getEvaluationPageBool } = state.testAdmin;
  return {
    generateQuesListViewBool,
    generateQuesListViewData,
    submittedTestsByStudentId,
    getEvaluationPageBool
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSubmittedTestByStudentId: (callback) => dispatch(fetchSubmittedTestByStudentId(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationQuestionsListView);
