import { ArrowDownOutlined, ArrowUpOutlined, BellOutlined, CalendarFilled, CalendarOutlined, CaretDownOutlined, FilterFilled } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Popover, Radio, Space, Table, Typography } from 'antd';
// import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react'
import EmailIdModal from './modal/emailIdModal';
import PaymentReqDetailsModal from './modal/paymentReqDetailsModal';
import moment from 'moment';
import { connect } from 'react-redux';
import { storePaymentReqModalData } from 'actions/aulasWallet';
import "./styles.css"

const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;

function PaymentReqListView({ item, storePaymentReqModalData, paymentReqModalData, storePaymentRequestsParams, paymentRequestsObj, getAllPaymentHistory, togglePageLoader, setIsEmailIdModalVisible }) {
    const [isPaymentDetailsVisible, setPaymentDetailsVisible] = useState(false);
    const [isIssueDateSelected, setIssueDateSelected] = useState(false);
    const [isDueDateSelected, setDueDateSelected] = useState(false);
    const [isStatusSelected, setStatusSelected] = useState(false);

    useEffect(() => {
        storePaymentRequestsParams("issueDate", null);
        storePaymentRequestsParams("dueDate", null);
        storePaymentRequestsParams("page", 1);
        storePaymentRequestsParams("overdueStatus", null);
        storePaymentRequestsParams("limit", 10);
        togglePageLoader(true)
        getAllPaymentHistory(() => togglePageLoader(false))
    }, [])

    useEffect(() => {
        if (paymentRequestsObj?.issueDate) {
            setIssueDateSelected(true)
        } else {
            setIssueDateSelected(false)
        }
        if (paymentRequestsObj?.dueDate) {
            setDueDateSelected(true)
        } else {
            setDueDateSelected(false)
        }
        if (paymentRequestsObj?.overdueStatus) {
            setStatusSelected(true)
        } else {
            setStatusSelected(false)
        }
    }, [paymentRequestsObj?.issueDate, paymentRequestsObj?.dueDate, paymentRequestsObj?.overdueStatus])

    const content2 = () => {
        const [value, setValue] = useState(null);
        return (
            <div
                id='checkbox'
                className="flex-column organistationcardfilter"
                style={{
                    marginLeft: -16,
                    marginRight: -16,
                    marginTop: -12,
                    width: 160,
                    height: 120
                }}>
                <Radio.Group
                    className="flex-column"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                        storePaymentRequestsParams('overdueStatus', e.target.value);
                    }}
                >
                    <Radio value={"Overdue"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Overdue</Radio>
                    <Radio value={"Pending"} className=" p-10 full-width" style={{ color: "#191919", fontSize: 14 }}>Pending</Radio>
                </Radio.Group>
                <div style={{ borderBottom: "1px solid #E6E6E6" }}></div>
                <div className='r-jsb' style={{ padding: 10 }}>
                    <div
                        style={{ color: "#1089FF", fontSize: 14, fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                            setValue(null)
                            storePaymentRequestsParams('overdueStatus', null);
                            togglePageLoader(true);
                            getAllPaymentHistory(() => togglePageLoader(false));
                        }}
                    > RESET</div>
                    <Button
                        type="primary"
                        style={{ width: 50, borderRadius: 16, height: 25, display: "flex", alignItems: "center", fontWeight: "bold" }}
                        onClick={() => {
                            togglePageLoader(true);
                            getAllPaymentHistory(() => togglePageLoader(false));
                        }}
                    >OK</Button>
                </div>
            </div>
        )
    }
    const ReminderContent = ({ remainderCount, reminders, datediff }) => {
        return (
            <div style={{ height: "fit-content", width: 250, padding: 5 }}>
                <BellOutlined style={{ color: "#594099", fontSize: 17 }} /><span className='font m-l-5' style={{ color: "#191919", fontSize: 17 }}>Reminders ({remainderCount})</span>
                {reminders?.map((item) => (
                    <>
                        <div className='r-c-sb m-t-20'>
                            <div style={{ color: "#191919", fontSize: 14, fontWeight: "bold", fontFamily: "roboto" }}>{moment(item?.date).format("DD/MM/YYYY, hh:mm A")}</div>
                            <div style={{ color: "#AEAEAE", fontSize: 13, fontFamily: "roboto" }}>{item?.datediff}</div>
                        </div>
                        <div style={{ color: "#636363", fontSize: 13, fontFamily: "roboto" }}>{item?.reminderName} • {item?.reminderPrivilege}</div>
                    </>
                ))}
            </div>
        )
    }

    return (
        <div className='table-container' style={{ width: "100%", paddingBottom: "20px", marginTop: 20, /* height: "80vh", overflowY: "scroll" */ }}>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr
                        style={{
                            backgroundColor: "#F6F4FE",
                            padding: "20px",
                            height: "50px",
                            fontSize: 16
                        }}
                    >
                        <th style={{ color: "#636363", textAlign: "left", paddingLeft: 10, width: 100 }}>REQUEST DETAILS</th>
                        <th style={{ color: "#636363" }}>
                            AMOUNT
                        </th>
                        <th style={{ color: "#636363" }}>
                            ISSUE DATE
                            <Popover
                                placement="bottomRight"
                                content={
                                    <DatePicker
                                        format={"DD/MM/YYYY"}
                                        onChange={(date, dateString) => {
                                            storePaymentRequestsParams("issueDate", dateString);
                                            togglePageLoader(true)
                                            getAllPaymentHistory(() => togglePageLoader(false))
                                        }}
                                    />}
                                trigger="click"
                            >
                                {isIssueDateSelected ?
                                    <CalendarFilled
                                        style={{
                                            fontSize: "18px",
                                            color: "#1089FF",
                                            marginLeft: "20px",
                                        }} />
                                    :
                                    <CalendarOutlined
                                        style={{
                                            fontSize: "18px",
                                            color: "#AEAEAE",
                                            marginLeft: "20px",
                                        }}
                                    />
                                }
                            </Popover>
                        </th>
                        <th style={{ color: "#636363" }}>
                            DUE DATE
                            <Popover
                                placement="bottomRight"
                                content={
                                    <DatePicker
                                        format={"DD/MM/YYYY"}
                                        onChange={(date, dateString) => {
                                            storePaymentRequestsParams("dueDate", dateString);
                                            togglePageLoader(true)
                                            getAllPaymentHistory(() => togglePageLoader(false))
                                        }}
                                    />}
                                trigger="click"
                            >
                                {isDueDateSelected ?
                                    <CalendarFilled
                                        style={{
                                            fontSize: "18px",
                                            color: "#1089FF",
                                            marginLeft: "20px",
                                        }} />
                                    :
                                    <CalendarOutlined
                                        style={{
                                            fontSize: "18px",
                                            color: "#AEAEAE",
                                            marginLeft: "20px",
                                        }}
                                        onClick={() => setDueDateSelected(true)}
                                    />
                                }
                            </Popover>
                        </th>
                        <th style={{ color: "#636363" }}>STATUS
                            <Popover
                                placement="bottomRight"
                                content={content2}
                                trigger="click"
                            >
                                <FilterFilled
                                    style={{
                                        fontSize: "18px",
                                        color: isStatusSelected ? "#1089FF" : "#AEAEAE",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Popover></th>
                        <th style={{ color: "#636363" }}>ACTIONS</th>
                    </tr>
                </thead>
                {item?.map((items, index) => (
                    <tr
                        className={"cursor-pointer"}
                        style={
                            index % 2
                                ? {
                                    backgroundColor: "#FAFAFA",
                                    height: "70px",
                                }
                                : {
                                    backgroundColor: "#FFFFFF",
                                    height: "70px",
                                }
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            setPaymentDetailsVisible(true)
                            storePaymentReqModalData(items);
                        }}
                    >
                        <td>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", textAlign: "left", fontSize: 14, fontFamily: "roboto", fontWeight: "bold", width: 250, marginLeft: 10 }}>
                                {items?.note}
                            </Typography.Text>
                        </td>
                        <td>
                            <div style={{ color: "#191919", textAlign: "center", fontSize: 14, fontFamily: "roboto", fontWeight: "bold" }}>
                                ₹ {items?.amountToShow}
                            </div>
                        </td>
                        <td>
                            <div
                                style={{
                                    color: "#636363",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: 14,
                                    fontFamily: "roboto"
                                }}
                            >
                                {items?.date ? moment(items?.date, "DD-MM-YYYY, hh:mm A").format("DD/MM/YYYY, hh:mm A") : "-"}
                            </div>
                        </td>
                        <td>
                            <div
                                style={{
                                    color: "#636363",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                    justifyContent: "center",
                                    fontSize: 14,
                                    fontFamily: "roboto"
                                }}
                            >
                                {items?.dueDate ? moment(items?.dueDate, "DD-MM-YYYY, hh:mm A").format("DD/MM/YYYY, hh:mm A") : "-"}
                            </div>
                        </td>
                        <td>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "7px",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        padding: "5px",
                                        fontWeight: "700",
                                        color: items?.status === "Overdue" ? "red" : "#FFA931",
                                    }}
                                >
                                    {items?.status === "Overdue" ? "OVER DUE" : "PENDING"}
                                </div>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <Popover
                                        placement="rightTop"
                                        content={
                                            <ReminderContent
                                                remainderCount={items?.reminderCount}
                                                reminders={items?.reminders}
                                            />}
                                        trigger="click">
                                        {items?.reminderCount > 0 ?
                                            <><span className='m-l-10 m-r-5'><BellOutlined style={{ color: "#FFA931" }} /></span><span style={{ color: "#FFA931", fontWeight: "bold" }}>{items?.reminderCount}</span></>
                                            :
                                            <></>
                                        }
                                    </Popover>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='r-c-c'>
                                <div style={{ color: "#594099", fontSize: 14, fontWeight: "bold" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEmailIdModalVisible(true)
                                    }}>PAY NOW</div>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
            {
                item?.length == 0 ? (
                    <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
                        <div
                        >
                            <img src={illNoData} />
                        </div>
                        <div>
                            <h4>
                                <b>No data!</b>
                            </h4>
                        </div>
                        <div>
                            <h5>There are no data to show yet. Please check back later</h5>
                        </div>
                    </div>
                ) : null
            }
            {isPaymentDetailsVisible &&
                <PaymentReqDetailsModal
                    isPaymentDetailsVisible={isPaymentDetailsVisible}
                    setPaymentDetailsVisible={setPaymentDetailsVisible}
                    setIsEmailIdModalVisible={setIsEmailIdModalVisible}
                    paymentReqModalData={paymentReqModalData}
                />
            }
        </div >
    )
}
const mapStateToProps = state => {
    const { paymentReqModalData } = state.aulasWallet;
    return { paymentReqModalData };
};
const mapDispatchToProps = dispatch => ({
    storePaymentReqModalData: (val) => dispatch(storePaymentReqModalData(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReqListView);
// export default PaymentReqListView