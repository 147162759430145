import { ArrowLeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'
import { setParentLink } from 'actions/studentMarketPlace'
import { Input, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function MobileSearchPage({ setParentLink, userMarketPlaceHomeData }) {
    let [CategoryListToShow, setCategoryListToShow] = useState([]);
    const [searchedValue, setSearchedValue] = useState(null);
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([])
    const [categoryIndex, storeCategoryIndex] = useState(null)
    const history = useHistory();
    useEffect(() => {
        if (userMarketPlaceHomeData?.categories?.length != 0) {
            setCategoryListToShow(userMarketPlaceHomeData?.categories)
        }
        else {
            setCategoryListToShow([])
        }
    }, [userMarketPlaceHomeData?.categories])

    const handleSearchCategory = (value) => {
        setSearchedValue(value);
        if (value && value.trim() != '') {
            let category;
            category = userMarketPlaceHomeData?.categories?.filter((item) => item?.name?.toLowerCase()?.includes(value?.toLowerCase()))
            setCategoryListToShow(category)
        }
        else {
            setCategoryListToShow(userMarketPlaceHomeData?.categories)
        }
    }
    return (
        <div style={{ padding: 20 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowLeftOutlined style={{ fontSize: 20, fontWeight: "bold", color: "#191919" }} onClick={() => { history.goBack() }} />
                <Input style={{ border: "none", outline: "none", width: "90%", marginLeft: 20 }} placeholder='Search' onChange={(e) => { handleSearchCategory(e.target.value) }} value={searchedValue ? searchedValue : null} />
                <SearchOutlined style={{ color: "#AEAEAE", fontSize: 22, fontWeight: "bold", marginLeft: 20 }} />
            </div>
            <div className='r-c-c m-t-10 m-b-10' style={{ borderBottom: "1.5px solid #E6E6E6", width: "100%" }}></div>
            {CategoryListToShow?.length != 0 && !isCategoryClicked ?
                <>
                    <div style={{ color: "#191919", fontWeight: "bold" }}>Categories</div>
                    {CategoryListToShow?.map((item, index) => (
                        <div className='m-t-10' style={{ display: "flex", justifyContent: "space-between" }} onClick={() => {
                            setSearchedValue(null)
                            setIsCategoryClicked(true);
                            setSelectedCategory(item?.name)
                            storeCategoryIndex(index)
                        }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: "90%" }}>{item?.name}</Typography.Text>
                            <RightOutlined style={{ color: "#636363", fontSize: 12 }} />
                        </div>
                    ))}
                </>
                :
                CategoryListToShow[categoryIndex]?.children?.length != 0 && isCategoryClicked ?
                    <>
                        <div style={{ color: "#191919", fontWeight: "bold" }}>Sub - Categories</div>
                        {CategoryListToShow[categoryIndex]?.children?.map((item) => (
                            <div className='m-t-10' style={{ display: "flex", justifyContent: "space-between" }} onClick={() => {
                                setParentLink({ parentName: selectedCategory, childName: item?.name });
                                history.push({
                                    pathname: '/all-popular-courses',
                                    state: {
                                        categoryId: item?.id,
                                    }
                                })
                            }}>
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: "90%" }}>{item?.name}</Typography.Text>
                            </div>
                        ))}
                    </>
                    :
                    <div>
                        <div className='font-bold text-sm'>Sorry, we couldn't find any results for "{searchedValue}"</div>
                        <div className='m-t-10' style={{ color: "#191919", fontSize: 12 }}>
                            Suggestions:
                            <div className='m-t-10 '>
                                <li> Make sure that all words are spelled correctly.</li>
                                <li>Try different keywords.</li>
                                <li>Try more general keywords.</li>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    return {
        userMarketPlaceHomeData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setParentLink: (item) => dispatch(setParentLink(item)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchPage);
