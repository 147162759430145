import React, { useState } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import {
  deleteCourseWithId,
  getStudentsDetailsById,
} from "actions/userManagement";

function CourseDeleteConfirmModal({
  isModalVisible,
  setViewCourseDeleteConfirmModal,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,
  type,
  name,
  role,
  deleteCourseWithId,
  getStudentsDetailsById,
  setViewCourseDetails,
  setViewAddCourseModal,
  setIsCourseModalVisible,
  setViewEditCourseModal,
  modalType,
}) {
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  return (
    <div>
      <Modal
        maskClosable={false}
        className="deleteUserModal"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{ loading: deleteBtnLoader }}
        onOk={() => {
          setDeleteBtnLoader(true);
          deleteCourseWithId(
            () => {
              setDeleteBtnLoader(false);
              getStudentsDetailsById();
            },
            () => {
              setViewCourseDetails(true);
              setViewCourseDeleteConfirmModal(false);
            }
          );
        }}
        onCancel={() => {
          setViewCourseDeleteConfirmModal(false);
          if (modalType) {
            setIsCourseModalVisible(true);
          } else {
            setViewEditCourseModal(true);
          }
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Confirm
        </h2>
        <p
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#636363",
          }}
        >
          Are you sure you want to delete?
        </p>
      </Modal>
    </div>
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCourseWithId: (callback, successCallBack) =>
    dispatch(deleteCourseWithId(callback, successCallBack)),
  getStudentsDetailsById: () => dispatch(getStudentsDetailsById()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDeleteConfirmModal);
