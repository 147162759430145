import {
  ArrowLeftOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  PageHeader,
  Pagination,
  Popover,
  Select,
  Input,
  Avatar,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import image from "../../Assets/feeManagement/Mask Group 2.png";
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
import SelectRangeModal from "./selectRangeModal";
import OfflinePayReq from "./addOfflinePayReqModal";
import AddDetailsModal from "./addDetailsModal";
import PayRequestDetailsModal from "./payReqDetails";
import CalenderPopup from "./calenderPop";
import ConfirmModal from "./confirmationModal";
import {
  fetchTransaction,
  getFeeOverView,
  setAllActivitiesFilter,
  setFeeDetails,
} from "actions/feeManagement";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import { Api } from "services";
import { NodataCard } from "pages/Test";
import { InputSearch } from "components/customInputs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { fetchStudentsClassList } from "actions/userManagementV2";

const liveCoursesIcon = require('Assets/liveCoursesIcon.svg').default;
const videoResourceIcon = require('Assets/videoResourceIcon.svg').default;
const studyMaterialIcon = require('Assets/studyMaterialIcon.svg').default;
const testSeriesIcon = require('Assets/testSeriesIcon.svg').default;

function AllActivities({
  fetchTransaction,
  feeMangementData,
  setAllActivitiesFilter,
  allActivitiesFilter,
  setFeeDetails,
  getFeeOverView,
  fetchStudentsClassList,
  classList,
  recentActivitiesVAfromWhere
}) {
  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }
  const [showSelectRangeModal, setShowSelectRangeModal] = useState(false);
  const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
  const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
  const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
  const [showPayRequestModal, setShowPayRequestModal] = useState(false);
  const [pageLoader, tooglePageLoader] = useState(false);
  const [payRequestData, setPayRequestData] = useState({});
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [showCustomCal, setShowCustomCal] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  let [checkBoxFilter, setCheckBoxFilter] = useState([
    false,
    false,
    false,
    false,
    false,
    // false, 
  ]);
  const [values, setValues] = useState([])
  const [selectedClasses, setSelectedClasses] = useState([]);
  const { Search } = Input;

  const history = useHistory();
  const goBack = (history) => {
    history.goBack();
  };

  console.log({ allActivitiesFilter })

  const handleClassSelect = (selectedValues) => {
    console.log({ selectedValues })
    // when clicked on allow clear, not sending courseIds key to the API
    if (selectedValues === undefined) {
      setAllActivitiesFilter('courseIds', undefined);
      setSelectedClasses([]);
      return;
    }

    setSelectedClasses(selectedValues);
    setAllActivitiesFilter('courseIds', [selectedValues]);
  };

  useEffect(() => {
    setAllActivitiesFilter("status", []);
    setAllActivitiesFilter("mode", null);
    setAllActivitiesFilter("startDate", null);
    setAllActivitiesFilter("endDate", null);
    setAllActivitiesFilter("filter", null);
    setAllActivitiesFilter("page", 1);
    setAllActivitiesFilter("section", null);
    setAllActivitiesFilter("search", null);
    setAllActivitiesFilter('courseIds', []);
    setSelectedClasses([])

    tooglePageLoader(true);
    fetchTransaction(() => tooglePageLoader(false));
    fetchStudentsClassList(null, () => { tooglePageLoader(false) })
  }, []);


  useEffect(() => {
    tooglePageLoader(true);
    fetchTransaction(() => tooglePageLoader(false));
  }, [allActivitiesFilter?.courseIds, allActivitiesFilter?.startDate, allActivitiesFilter?.endDate])

  const handlePageination = (value) => {
    setPage(value);
    setAllActivitiesFilter("page", value);
    tooglePageLoader(true);

    fetchTransaction(() => tooglePageLoader(false));
  };
  const handleChange = (e, index, value) => {
    checkBoxFilter[index] = e.target.checked;
    setCheckBoxFilter([...checkBoxFilter]);

    if (e.target.checked) {
      const temp = [...values]
      temp.push(value)
      setValues(temp)
      setStatus(value);
      setAllActivitiesFilter("status", temp);
      tooglePageLoader(true);
      setAllActivitiesFilter("page", 1);

      fetchTransaction(() => tooglePageLoader(false));
    } else {
      setStatus(null);
      const temp = values.filter(el => el !== value);
      setValues(temp)

      setAllActivitiesFilter("status", temp);
      tooglePageLoader(true);
      setAllActivitiesFilter("page", 1);
      fetchTransaction(() => tooglePageLoader(false));
    }
  };

  const onSearchFilter = (value) => {
    setAllActivitiesFilter("search", value);
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);

    fetchTransaction(() => tooglePageLoader(false));
  };

  const handleFilterOption = (value) => {
    setAllActivitiesFilter("filter", value);
    setAllActivitiesFilter("startDate", null);
    setAllActivitiesFilter("endDate", null);
    setAllActivitiesFilter("page", 1);

    tooglePageLoader(true);
    fetchTransaction(() => tooglePageLoader(false));
  };

  const handleChangeStdFilter = (value) => {
    const mappedValue = value.map(item => item === "unassigned" ? -1 : item);
    setAllActivitiesFilter("section", mappedValue);
    tooglePageLoader(true);
    setAllActivitiesFilter("page", 1);
    fetchTransaction(() => tooglePageLoader(false));
  };

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.classroom) {
      setAllActivitiesFilter("section", [location?.state?.classroom]);
      tooglePageLoader(true);
      getFeeOverView(() => tooglePageLoader(false))
      fetchTransaction(() => tooglePageLoader(false));
    }
  }, []);

  const displayDateAndTime = (item) => {
    if (item?.status == 'Paid' || item?.status == 'Refunded') {
      if (item?.payment_type == 'online') {
        return moment.utc(item?.paymentDate).subtract(5.5, 'hours').local().format("DD/MM/YYYY • hh:mm A");
      } else {
        return moment.utc(item?.paymentDate).subtract(5.5, 'hours').local().format("DD/MM/YYYY");
      }
    } else {
      return moment.utc(item?.date).subtract(5.5, 'hours').local().format("DD/MM/YYYY • hh:mm A");
    }
  }

  const content = (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[0]}
          onChange={(e) => handleChange(e, 0, "Pending")}
        >
          Pending
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[1]}
          onChange={(e) => handleChange(e, 1, "Paid")}
        >
          Paid
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[2]}
          onChange={(e) => handleChange(e, 2, "Overdue")}
        >
          Overdue
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[3]}
          onChange={(e) => handleChange(e, 3, "Refunded")}
        >
          Refunded
        </Checkbox>
      </div>
      <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[4]}
          onChange={(e) => handleChange(e, 4, "Cancelled")}
        >
          Cancelled
        </Checkbox>
      </div>
      {/* <div className="dropmenuBp">
        <Checkbox
          checked={checkBoxFilter[5]}
          onChange={(e) => handleChange(e, 5, "Settled")}
        >
          Settled
        </Checkbox>
      </div> */}
    </div>
  );
  const content1 = (
    <div>
      {showCustomCal ? (
        <div
          style={{
            fontFamily: "roboto",
            width: "200px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
          }}
        >
          <div className="dropmenuBph" style={{ color: "#AEAEAE" }}>
            FILTER OPTIONS{" "}
            <span
              onClick={() => {
                handleFilterOption(null);

              }}
              style={{
                color: "#FF414D",
                fontWeight: "600",
                fontSize: "12px",
                marginLeft: "11px",
                cursor: "pointer",
              }}
            >
              CLEAR ALL
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => { handleFilterOption("today"); }}
            >
              Today
            </div>
            {allActivitiesFilter?.filter == "today" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => { handleFilterOption("yesterday"); }}
            >
              Yesterday
            </div>
            {allActivitiesFilter?.filter == "yesterday" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => { handleFilterOption("month"); }}
            >
              This month
            </div>
            {allActivitiesFilter?.filter == "month" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => { handleFilterOption("lastmonth"); }}
            >
              Past month
            </div>
            {allActivitiesFilter?.filter == "lastmonth" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => { handleFilterOption("threemonths"); }}
            >
              Past 3 months
            </div>
            {allActivitiesFilter?.filter == "threemonths" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="dropmenuBp"
              style={{ color: "#636363", width: "100%" }}
              onClick={() => {
                // handleFilterOption(" Customise range");
                setAllActivitiesFilter("filter", "Customise range");

                setShowCustomCal(false);
              }}
            >
              Customise range
            </div>
            {allActivitiesFilter?.filter == "Customise range" ? (
              <div>
                <CheckOutlined
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                    color: "#594099",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div>
          <CalenderPopup
            tooglePageLoader={tooglePageLoader}
            showCustomCal={setShowCustomCal}
            allActivitiesFilter={allActivitiesFilter}
          />
        </div>
      )}
    </div>
  );
  const content2 = (
    <div
      style={{
        fontFamily: "roboto",
        width: "200px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        // alignItems: "flex-start",
        // justifyContent: "space-around",
        overflowY: "auto",
      }}
    >
      <Checkbox.Group
        style={{ display: "flex", flexDirection: "column" }}
        onChange={(value) => handleChangeStdFilter(value)}
        defaultValue={location?.state?.classroom ? location?.state?.classroom.toString() : null}
      >
        {feeMangementData?.dashBoardDetails?.recentTransactions?.standards
          ?.length != 0
          ? feeMangementData?.dashBoardDetails?.recentTransactions?.standards?.map(
            (item) => (
              <Checkbox
                className="dropmenuBph"
                style={{ color: "black", marginLeft: "0" }}
                value={item?.id}
              >
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "130px" }}
                >
                  {item?.std}-{item?.section}
                </Typography.Text>
              </Checkbox>
            )
          )
          : null}
      </Checkbox.Group>
    </div>
  );
  return (
    <div
      style={{
        fontFamily: "roboto",
        marginTop: "-15px",
        height: "90vh",
        overflow: "hidden",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginLeft: "-20px" }}>
          <PageHeader
            title={
              <div
                className="text-md"
                style={{ fontWeight: "bold", height: "45px", marginTop: "10px" }}
              >
                All activities
              </div>
            }
            onBack={() => goBack(history)}
            backIcon={<ArrowLeftOutlined />}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          {recentActivitiesVAfromWhere !== 'fromInsideLiveCourses' ?
            <>
              <Select
                placeholder="Filter by: courses"
                allowClear={true}
                style={{ width: 220, marginRight: 10 }}
                value={selectedClasses}
                onChange={handleClassSelect}
              // size="medium"
              // mode="multiple"
              >
                {classList?.response?.items?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.courseName}
                  </Option>
                ))}
              </Select>

              <Popover
                placement="bottomRight"
                content={content2}
                trigger="click"
              >

                <div
                  className="classFilter"
                  style={{
                    width: "250px",
                    height: "30px",
                    borderRadius: "2px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      color: "#d9d9d9",
                      marginBottom: "0",
                      marginLeft: "5px",
                    }}
                  >
                    Filter by: Classes (
                    {
                      feeMangementData?.dashBoardDetails?.recentTransactions
                        ?.standards.length
                    }
                    )<span style={{ marginLeft: "70px" }}>▼</span>
                  </p>
                </div>
              </Popover>
            </>
            :
            <></>
          }

          <div style={{ marginLeft: "20px" }}>
            <InputSearch
              allowClear
              style={{ fontSize: "1em" }}
              onEnter={onSearchFilter}
              placeholder="Search student..."
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "76vh",
          overflowY: "scroll",
          paddingBottom: "30px",
        }}
      >
        <table style={{ width: "100%" }}>
          <thead>
            <tr
              style={{
                backgroundColor: "#F6F4FE",
                padding: "20px",
                height: "50px",
                // display: "flex",
                // flexDirection: "row",
                // alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <th
                style={{
                  color: "#636363",
                  // backgroundColor: "red",
                  width: "200px",
                }}
              >
                STUDENT’S DETAILS
              </th>
              <th style={{ color: "#636363" }}>DESCRIPTIONS</th>
              <th style={{ color: "#636363" }}>
                PAYMENT DATE/ISSUE DATE
                <Popover
                  placement="leftTop"
                  //   title={text}
                  content={content1}
                  trigger="hover"
                >
                  {/* <Button>RT</Button> */}
                  <CalendarOutlined
                    onClick={() => {
                      setShowCustomCal(true);
                    }}
                    style={{
                      fontSize: "18px",
                      color: "#1089FF",
                      marginLeft: "20px",
                    }}
                  />
                </Popover>
              </th>
              <th style={{ color: "#636363" }}>
                STATUS
                <Popover
                  placement="rightTop"
                  //   title={text}
                  content={content}
                  trigger="click"
                >
                  {/* <Button>RT</Button> */}
                  <CaretDownOutlined />
                </Popover>
              </th>
            </tr>
          </thead>
          {/* <tr> */}
          {feeMangementData?.allActiviies?.items?.length != 0
            ? feeMangementData?.allActiviies?.items?.map((item, index) => (
              <tr
                onClick={() => {
                  setShowPayRequestModal(true);
                  setPayRequestData(item);
                  setFeeDetails("selectedTransaction", item);
                }}
                style={
                  index % 2
                    ? {
                      backgroundColor: "#FAFAFA",
                      // padding: "100px",
                      height: "100px",
                      // marginLeft: "30px",
                      cursor: "pointer",
                    }
                    : {
                      // padding: "100px"
                      height: "100px",
                      cursor: "pointer",
                    }
                }
              >
                <td
                  style={{
                    // backgroundColor: "red",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                    marginRight: "-10px",
                    // width: "200px",
                  }}
                >
                  <div
                    className="r-ac"
                    style={{
                      fontFamily: "roboto",
                      marginLeft: "-20px",
                      // backgroundColor: "red",
                      width: "160px",
                    }}
                  >
                    <div>
                      <img
                        style={{ width: "40px", height: "40px", borderRadius: '50%' }}
                        src={recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? Api._s3_url + item?.userImage : Api._s3_url + item?.user?.image}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      {/* <div style={{ fontWeight: "550" }}>
                          {item?.user?.name}
                        </div> */}
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{
                          // color: "#636363",
                          width: "130px",
                          fontWeight: "550",
                        }}
                      >
                        {recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? item?.username : item?.user?.name}
                      </Typography.Text>
                      <div style={{ color: "#636363" }}>
                        <Typography.Text
                          ellipsis={{ tooltip: true }}
                          style={{
                            width: "130px",
                            fontWeight: "550",
                          }}
                        >
                          {recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? item?.resourceName : item?.resourceType != null ? item?.resourceLabel : item?.standardNames}
                          {/* {item?.resourceType != null ? item?.resourceLabel : item?.standardNames} */}

                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ height: "100px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "10px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontWeight: "550" }}>
                      ₹ {recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ? numberWithCommas(item?.amountPayable) : item?.amountPayable && numberWithCommas(item?.amountPayable)}
                    </div>

                    <div>
                      <img src={item?.resourceType && (item?.resourceLabel == 'Live Course' ? liveCoursesIcon : item?.resourceLabel == 'Video Course' ? videoResourceIcon : item?.resourceLabel == 'Study Materials' ? studyMaterialIcon : testSeriesIcon)} style={{ width: 20 }} />
                      <Typography.Text
                        ellipsis={{ tooltip: true }}
                        style={{ color: "#636363", width: "200px" }}
                      >
                        {item?.resourceType != null ? item?.resourceName : item?.note}
                      </Typography.Text>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      color: "#636363",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "10px",
                      // backgroundColor: "red",
                      justifyContent: "center",
                    }}
                  >
                    {recentActivitiesVAfromWhere == 'fromInsideLiveCourses' ?
                      item?.date && moment(item?.date).format('DD/MM/YYYY')
                      :
                      (item?.status === 'Paid' ? (
                        <div>
                          {item?.paymentDate && (
                            <>
                              <div>{moment(item?.paymentDate, 'DD-MM-YYYY').format('DD/MM/YYYY')}</div>
                              {item?.paymentDate && moment(item?.paymentDate, 'DD-MM-YYYY HH:mm').format('HH:mm') !== '00:00' && (
                                <div>{moment(item?.paymentDate, 'DD-MM-YYYY HH:mm').format('HH:mm')}</div>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        item?.date && (
                          <>
                            <div>{moment(item?.date?.split(',')[0], 'DD-MM-YYYY').format('DD/MM/YYYY')}</div>
                            <div>{item?.date?.split(',')[1]}</div>
                          </>
                        )
                      ))
                    }
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item?.status == "Overdue"
                          ? "#FF414D"
                          : item?.status == "Paid"
                            ? "#28DF99"
                            : item?.status == "Refunded"
                              ? "#1089FF"
                              : item?.status == "Cancelled"
                                ? "#1089FF"
                                : "#FFA931"
                          }`,
                        borderRadius: "7px",
                        textAlign: "center",
                        fontSize: "10px",
                        padding: "5px",
                        fontWeight: "700",
                        width: "100px",
                        color: `${item?.status == "Overdue"
                          ? "#FF414D"
                          : item?.status == "Paid"
                            ? "#28DF99"
                            : item?.status == "Refunded"
                              ? "#1089FF"
                              : item?.status == "Cancelled"
                                ? "#1089FF"
                                : "#FFA931"
                          }`,
                        backgroundColor: `${item?.status == "Overdue"
                          ? "#FFECED"
                          : item?.status == "Paid"
                            ? "#EAFCF5"
                            : item?.status == "Refunded"
                              ? "#E7F3FF"
                              : item?.status == "Cancelled"
                                ? "#E7F3FF"
                                : "#FFF6EA"
                          }`,
                      }}
                    >
                      {item?.status}
                    </div>

                    {/* <div
                      style={{
                        borderRadius: "7px",
                        textAlign: "center",
                        fontSize: "10px",
                        padding: "5px",
                        fontWeight: "700",
                        color: "#FFA931",
                        backgroundColor: "#FFF6EA",
                        width: "100px",
                        border: "1px solid #FFA931",
                      }}
                    >
                      PENDING
                    </div> */}
                  </div>
                </td>
              </tr>
            ))
            :
            <div className="r-c-c-c" style={{ width: "80%", height: "80%", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute" }}>
              <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
              >
                <img src={illNoData} />
              </div>
              <div>
                <h4>
                  <b>No data!</b>
                </h4>
              </div>
              <div>
                <h5>There are no data to show yet. Please check back later</h5>
              </div>
            </div>
          }
          {/* </tr> */}
        </table>
      </div>
      <div style={{ position: "absolute", right: "20px", bottom: "20px" }}>
        <Pagination
          size="small"
          current={feeMangementData?.allActiviies?.meta?.currentPage}
          defaultCurrent={1}
          total={feeMangementData?.allActiviies?.meta?.totalPages * 10}
          onChange={handlePageination}
          showSizeChanger={false}
        />
      </div>
      <div
        style={{
          position: "absolute",
          // left: "250px",
          bottom: "30px",
          color: "#636363",
        }}
      >
        Showing{" "}
        {feeMangementData?.allActiviies?.meta?.itemsPerPage *
          (feeMangementData?.allActiviies?.meta?.currentPage - 1) +
          1}{" "}
        -{" "}
        {feeMangementData?.allActiviies?.meta?.itemsPerPage *
          feeMangementData?.allActiviies?.meta?.currentPage <
          feeMangementData?.allActiviies?.meta?.totalItems
          ? feeMangementData?.allActiviies?.meta?.itemsPerPage *
          feeMangementData?.allActiviies?.meta?.currentPage
          : feeMangementData?.allActiviies?.meta?.totalItems}{" "}
        of {feeMangementData?.allActiviies?.meta?.totalItems}
      </div>
      <SelectRangeModal
        isModalVisible={showSelectRangeModal}
        setShowSelectRangeModal={setShowSelectRangeModal}
      />
      {showOfflinePayReqModal && (
        <OfflinePayReq
          isModalVisible={showOfflinePayReqModal}
          setIsModalVisible={setShowOfflinePayReqModal}
          setShowAddDetailsModal={setShowAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
        />
      )}
      {showAddDetailsModal && (
        <AddDetailsModal
          isModalVisible={showAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          isAddOrGenModal={isAddOrGenModal}
          handleCancel={() => {
            setShowAddDetailsModal(false);
            if (isAddOrGenModal == true) {
              setShowOfflinePayReqModal(true);
              setIsAddOrGenModal(false);
            } else {
              //   setShowGeneratePayReq(true);
              setIsAddOrGenModal(false);
            }
          }}
          setShowGeneratePayReq={() => { }}
        />
      )}
      {showPayRequestModal && (
        <PayRequestDetailsModal
          isModalVisible={showPayRequestModal}
          setShowPayRequestModal={setShowPayRequestModal}
          payRequestData={payRequestData}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      <PageDataLoader visible={pageLoader} />
      <ConfirmModal
        setShowPayRequestModal={setShowPayRequestModal}
        setShowConfirmationModal={setShowConfirmationModal}
        isModalVisible={showConfirmationModal}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  const { feeMangementData, allActivitiesFilter, recentActivitiesVAfromWhere } = state.feeManagement;
  const { classList } = state.userManagementV2;
  return {
    feeMangementData,
    allActivitiesFilter,
    classList,
    recentActivitiesVAfromWhere
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  setAllActivitiesFilter: (key, res) => dispatch(setAllActivitiesFilter(key, res)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  fetchStudentsClassList: (params, callback) => dispatch(fetchStudentsClassList(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllActivities);