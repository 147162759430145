import { Api } from '../../services';
import React from 'react';
import Styles from './style';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import Tag from 'antd/lib/tag';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tag/style/index';

import {connect} from 'react-redux';
import { Color } from '../../services';
import { CloseCircleOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import {deleteUsers } from '../../actions/user';
import moment from 'moment';
import { Tooltip } from 'antd';



class AdministratorCard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      deleteCardBool : false,
      visible : false,
    }
  }


  showMenu = (e, cardDetail) => {
    e.stopPropagation();
    console.log('testing', cardDetail);
    this.setState({visible : true,})
  }
  deleteCard = (e) => {
    e.stopPropagation();
    this.setState({deleteCardBool: true})
  }
  deleteCardConfirm = (e, cardDetail) => {
    e.stopPropagation();
    this.setState({deleteCardBool: false})
    this.props.dispatch(deleteUsers(cardDetail,this.props.position))
    this.setState({visible : false,})
  }
  cancelCard = (e) => {
    e.stopPropagation();
    this.setState({deleteCardBool: false})
  }
  editCard = (e, cardDetail) => {
    e.stopPropagation();
    this.setState({visible : false});
    // this.props.dispatch(editUsers(cardDetail))
    this.props.onEditClick();
    // this.props.dispatch(studentModalVisible(cardDetail, true));
  }

  handleMenuItemClick() {
    // Do nothing
  }

   menu = () => (
    <Menu onClick={this.handleMenuItemClick} style={{borderRadius: 10 }}>
     {this.props.allowEdit && <Menu.Item key="0">
          <div className='r-ac' onClick={(e) => this.editCard(e, this.props.cardDetail)}>
            <EditOutlined style={{color:Color.green}} className='m-r-10'/>
            <div>Edit</div>
          </div>
      </Menu.Item>}
      {this.props.allowDelete && <Menu.Item key="1">
          <div className='r-ac' onClick={(e) => this.props.onDeleteClick(e, this.props.cardDetail)}>
            <CloseCircleOutlined style={{color:Color.red}} className='m-r-10'/>
            <div>Remove</div>
          </div>
      </Menu.Item>}
    </Menu>
  );


  render(){
    const {name, phone, color, image, onClick, cardDetail, role, orgId, allowEdit, allowDelete} = this.props;
    const darkColor = Color[color];
    const lightColor = Color[`light${color}`];
    const roleColor = role.includes('SuperAdmin') ? "purple" : role.includes('Admin') ? "green" :  'transparent'
    const roleName = role.includes('SuperAdmin') ? "Super Admin" : role.includes('Admin') ? "Admin" :  null
    return(
      /*<div className='r-jsb cursor-pointer' style={{...Styles.adminCardContainerBackground,backgroundColor: borderColor}} onClick={onClick}>*/
        <div className='r-jsb cursor-pointer' style={{...Styles.adminCardContainer,backgroundColor: lightColor}} onClick={onClick}>
          <div style={{display: 'flex', alignItems: 'center', width: '80%'}}>
            <div style={{flex: 4}} span={4}>
              <Avatar src={`${Api._s3_url}${image}`} alt="" size={50} />
            </div>
            <div style={{flex: 14, verticalAlign: 'center', width:'100%', marginLeft: 10}} span={20}>
              <div style={{fontSize: 14, fontWeight: 'bold', whiteSpace: 'nowrap',width:'8vw', overflow: 'hidden', textOverflow: 'ellipsis'}}>{name}</div>   {/*<Badge dot={true} color={borderColor} offset={[10,10]} size={80}/>*/}
              <div style={{fontSize: 12, color: Color.darkGrey, whiteSpace: 'nowrap',width:'10vw', overflow: 'hidden', textOverflow: 'ellipsis'}}>{phone}</div>
              {roleName && <Tag color={roleColor} style={{fontSize: 8, borderRadius:5}}>{roleName}</Tag>}
              {role.includes("Student") && cardDetail.transaction && cardDetail.transaction.length ? 
                <Tooltip
                  placement="right"
                  title={
                  <div style={{width: "100%"}}>
                    <div className="m-b-20">Fee Payment Details</div>
                    <div className="r-jsb">
                      <div >
                        <div style={{fontSize: 11}}>Approved On</div> 
                        <div style={{fontSize: 12}}>
                        {moment(cardDetail.createdAt).format("DD-MM-YYYY")}
                        </div>
                      </div>
                      
                      <div style={{fontSize: 11}}>
                        <div style={{fontSize: 11}}>Amount</div> 
                        <div style={{fontSize: 12}}>
                          ₹ {cardDetail.transaction[0].amount}
                        </div>  
                      </div>
                    </div>
                  </div>
                  }
                  overlayStyle={{borderRadius: 60, padding: 20, width: 250}}
                >
                  <Tag color={cardDetail.transaction[0].status === "Link Sent" ? moment(cardDetail.transaction[0].dueDate) < moment() ? "red" : "yellow" : cardDetail.transaction[0].status === "Paid" ? "green" : "grey"}>
                    {cardDetail.transaction[0].status === "Link Sent" ? moment(cardDetail.transaction[0].dueDate) < moment() ? "Overdue" : "Pending" : cardDetail.transaction[0].status }
                  </Tag>
                </Tooltip>
              : null}
            </div>
          </div>
         {allowEdit || allowDelete ?  <Dropdown overlay={this.menu} trigger={['hover']} style={{}}>
            <div onClick={(e) => this.showMenu(e, cardDetail)} style={{height:'20px', marginBottom:10}}>
              <EllipsisOutlined  style={{fontSize:25, color:darkColor, cursor:'pointer'}}/>
            </div>
          </Dropdown> : null}
        </div>
    /*  </div>*/
    )
  }
}

const mapStateToProps = state => {
    const {orgId} = state.session.user
    return {
     orgId
    };
  };


export default connect(mapStateToProps)(AdministratorCard);
