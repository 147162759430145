import React from 'react'
import { DatePicker, Space } from 'antd';
import { connect } from 'react-redux';
import { setStartDateSchedule, setEndDateSchedule,fetchSummaryData} from 'actions/schedule';
 

const { RangePicker } = DatePicker;


const CalenderPopup = ( {setStartDateSchedule,setEndDateSchedule,fetchSummaryData,togglePageLoader}) =>{
   
    function onChange(dates, dateStrings) {
      console.log(dateStrings)
      setStartDateSchedule(dateStrings[0])
      setEndDateSchedule(dateStrings[1])
      fetchSummaryData('week',dateStrings[0],dateStrings[1])
    }
      
    const format = "YYYY-MM-DD"
    return(
        
<div className="rangePicker" >
        <Space  direction="vertical" mode={'month'}   size={12}>
       
        <RangePicker dropdownClassName={'rangePickerB'} placeholder={['Start date','End date']} format={format} onChange={onChange} renderExtraFooter={() => 'extra footer'} showTime />
        
      </Space>

    </div>
  )
}



const mapDispatchToProps = dispatch => ({
  
     setStartDateSchedule:(date)=> dispatch(setStartDateSchedule(date)),
     setEndDateSchedule:(date)=>dispatch(setEndDateSchedule(date)),
     fetchSummaryData:(isMonthOrWeek,startDate,endDate)=>dispatch(fetchSummaryData(isMonthOrWeek,startDate,endDate))

});


export default connect(null, mapDispatchToProps)(CalenderPopup);



