import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, DownOutlined, DragOutlined, EditOutlined, MoreOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined, ShareAltOutlined, StarFilled, UserOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import { InputSearch } from 'components/customInputs';
import PageHeader from 'components/pageHeader'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AutoComplete, Checkbox, DatePicker, Dropdown, Input, Menu, Popover, Progress, Radio, Space, Tabs, Tooltip, Typography } from 'antd';
import "./styles.css"
import { connect } from 'react-redux';
import NoCourseImage from "../../Assets/course/ill-courses.png";
import { getCoursesCategories, getCoursesSubCategories, getStudyMaterialsList, getTotalCoursesList, setAllCoursesPageParamsObj, storeSingleCourseId, getTotalsVideoResource, setCourseTypeName, getAutocompleteSearch, getSearchResultsData, setSearchResultPageParamsObj, storeAutocompleteSearchData, getCourseLoadMore, getVideoResourceLoadMore, getStudyMaterialLoadMore, getCourseStats, getOnlineTestSeries, getOnlineTestSeriesCourseDetails, getTestsLoadMore } from 'actions/courseManagement';
import PageDataLoader from 'components/pageDataLoader';
import CreateOrEditCategoryModal from './createOrEditCategoryModal';
import { Api } from 'services';
import moment from 'moment';
import MoveModal from './moveModal';
import DeleteModal from './deleteModal';
import ConfirmPublishOrUnpublishModal from './confirmPublishOrUnpublishModal';
import NoDataCard from 'pages/digitalLibrary/components/noDataCard';
import ShareCourseModal from './shareCourseModal';
import { IMG_URL } from "../../env.json";
import { MdMoreVert } from 'react-icons/md';
import ListViewCoursecards from './ListViewCoursecards';
const defaultCourseImage = require('Assets/default_course-img.svg').default;

export const bodyStyle = {
    backgroundColor: "#FAFAFA",
    margin: "-20px",
    padding: "20px",
    overflow: "auto",
    minHeight: "92vh"
}
const SearchAllCoursesPage = ({ courseTypeName, getCoursesCategories, courseCategoryData, getCoursesSubCategories, courseSubCategoriesData, getTotalCoursesList, setAllCoursesPageParamsObj, totalCoursesDataContainer, storeSingleCourseId, courseStatsData, courseCreateObj, getStudyMaterialsList, getTotalsVideoResource, setCourseTypeName, getAutocompleteSearch, autocompleteSearchData, getSearchResultsData, searchResultData, setSearchResultPageParamsObj, searchPageParamsObj, storeAutocompleteSearchData, getCourseLoadMore, getVideoResourceLoadMore, getStudyMaterialLoadMore, getCourseStats, getOnlineTestSeries, storeCategoryData, getTestsLoadMore, user }) => {
    const location = useLocation();
    const history = useHistory()


    const [selectedUnpublishCards, setSelectedUnpublishCards] = useState([]);
    const [selectedPublishCards, setSelectedPublishCards] = useState([]);
    const [selectedCategoryCards, setSelectedCategoryCards] = useState([]);
    const [selectedSubCategoryCards, setSelectedSubCategoryCards] = useState([]);
    const [isSubCategoryVisible, setSubCategoryVisible] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [isCoursesCardsVisible, setCoursesCardsVisible] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSeletedSubCategory] = useState({});
    const [isCreateOrEditCategoryVisible, setIsCreateOrEditCategoryVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSubCategory, setIsSubCategory] = useState(false);
    const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false);
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    const [showCourseTypeDropdown, setCourseTypeDropdown] = useState(false);
    const [showCategoriesDropdown, setCategoriesDropdown] = useState(false);
    const [showSubcategoriesDropdown, setSubcategoriesDropdown] = useState(false);
    const [isFilterCategoryClicked, setFilterCategoryClicked] = useState(false);
    const [isFilterSubCategoryClicked, setFilterSubCategoryClicked] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [isSearchedSuggestionClicked, setSearchedSuggestionClicked] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const [searchSelectedCourseType, setSearchSelectedCourseType] = useState(null);
    const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null);
    const [isSearchFilterClicked, setSearchFilterClicked] = useState(false);
    const [previousCourseType, setPreviousCourseType] = useState(null);
    const [loadMorePage, setLoadMorePage] = useState({
        courseLoadMore: 1,
        videoLoadMore: 1,
        studyLoadMore: 1,
        testsLoadMore: 1
    })
    const [isMultipleFilterClicked, setMultipleFilterClicked] = useState(false);
    const [isPublished, setIsPublished] = useState(true);
    const [hoveredCardId, setHoveredCardId] = useState(null);




    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');

    const categoryActions = hasMarketingPrivilege ? [] : [
        <Button type="cancel" style={{ marginRight: 10 }} onClick={() => { setSelectedCategoryCards([]); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]) }}>CANCEL</Button>,
        <Button type="course-delete" style={{ marginRight: 10 }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>
    ]
    const subCategoryActions = [
        <Button type="cancel" style={{ marginRight: 10 }} onClick={() => { setSelectedCategoryCards([]); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]) }}>CANCEL</Button>,
        <Button type="course-delete" style={{ marginRight: 10 }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>,
        <Button type="primary" onClick={() => { setIsMoveModalVisible(true) }}>MOVE</Button>,
    ]
    const courseActions = [
        <Button type="cancel" style={{ marginRight: 10 }} onClick={() => { setSelectedCategoryCards([]); setSelectedSubCategoryCards([]); setSelectedPublishCards([]); setSelectedUnpublishCards([]) }}>CANCEL</Button>,
        <Button type="course-delete" style={{ marginRight: 10 }} onClick={() => { setIsDeleteModalVisible(true) }}>DELETE</Button>,
        <Button type="primary" disabled={selectedPublishCards?.length > 0 && selectedUnpublishCards?.length > 0} onClick={() => { setIsConfirmPublishModalVisible(true) }}>{selectedPublishCards?.length != 0 ? "UNPUBLISH" : "PUBLISH"}</Button>
    ]


    const resetValues = () => {
        setSearchResultPageParamsObj("startDate", null);
        setSearchResultPageParamsObj("endDate", null);
        setSearchResultPageParamsObj("sort", null);
        setSearchResultPageParamsObj("mode", null);
        setSearchResultPageParamsObj("type", null);
        setSearchResultPageParamsObj("status", null);
        setSearchResultPageParamsObj("durationFilter", null);
        setSearchResultPageParamsObj("page", 1);
        setSearchResultPageParamsObj("feeType", null);
        setSelectedSubCategoryCards([])
    }
    useEffect(() => {
        setSearchResultPageParamsObj("subcategoryId", null);
        setSearchedSuggestionClicked(true);
        resetValues();
        togglePageLoader(true);
        getSearchResultsData(() => { togglePageLoader(false) })
    }, [])


    const publishedTests = searchResultData?.testSeries?.items
    const unpublishedTests = storeCategoryData?.testSeries?.items

    const toggleSort = (sort) => {
        setSearchResultPageParamsObj("sort", sort);
    };
    const toggleMode = (mode) => {
        setSearchResultPageParamsObj("mode", mode);
    };
    const toggleType = (type) => {
        setSearchResultPageParamsObj("type", type);
    };
    const toggleFeeType = (type) => {
        setSearchResultPageParamsObj("feeType", type);
    };
    const toggleStatus = (status) => {
        setSearchResultPageParamsObj("status", status);
    };
    const toggleDuration = (duration) => {
        setSearchResultPageParamsObj("durationFilter", duration);
    };

    const FilterTitle = ({ titleName }) => {
        return (
            <div className='m-b-5' style={{ color: "#636363", fontWeight: 500 }}>{titleName}</div>
        )
    }

    const sortArraySearch = [{ label: "Popularity", value: "popularity" }, { label: "Relevance", value: "relevance" }, { label: "Upload date", value: "uploadDate" }, { label: "Rating", value: "rating" }];
    const sortArrayCourse = [{ label: "Popularity", value: "popularity" }, { label: "Upload date", value: "uploadDate" }, { label: "Rating", value: "rating" }];
    const sortArrayCatSubcat = [{ label: "Popularity", value: "popularity" }, { label: "Rating", value: "rating" }];
    const modeArray = [{ label: "All", value: null }, { label: "Online", value: "online" }, { label: "Hybrid", value: "hybrid" }, { label: "Offline", value: "offline" }];
    const typeArray = [{ label: "All", value: null }, { label: "Live Courses", value: "course" }, { label: "Video Course", value: "videoResource" }, { label: "Study Materials", value: "studyMaterial" }, { label: "Online Test Series", value: "onlineTestSeries" }];
    const statusArray = [{ label: "All", value: null }, { label: "Unpublished", value: "unpublished" }, { label: "Published", value: "published" }];
    const durationArray = [{ label: "1 week", value: "oneWeek" }, { label: "7 to 30 days", value: "oneMonth" }, { label: "1 to 6 months", value: "sixMonths" }, { label: "6 to 12 month", value: "oneYear" }, { label: "1 to 2 year", value: "twoYears" }, { label: "More than 2 year", value: "greaterTwoYear" }];
    const studyTypeArray = [{ label: "All", value: null }, { label: "Free", value: "free" }, { label: "Paid", value: "paid" }]

    const SortFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Sort by"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {isSearchFilterClicked ?
                        <>
                            {sortArraySearch?.map((item, index) => (
                                <div className='m-r-20 m-b-10' key={index}>
                                    <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.sort === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.sort === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.sort === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleSort(item?.value)}>{item?.label}</div>
                                </div>
                            ))}
                        </>
                        :
                        isCoursesCardsVisible && !isSearchedSuggestionClicked ?
                            <>
                                {sortArrayCourse?.map((item, index) => (
                                    <div className='m-r-20 m-b-10' key={index}>
                                        <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.sort === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.sort === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.sort === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleSort(item?.value)}>{item?.label}</div>
                                    </div>
                                ))}
                            </>
                            :
                            <>
                                {sortArrayCatSubcat?.map((item, index) => (
                                    <div className='m-r-20 m-b-10' key={index}>
                                        <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.sort === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.sort === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.sort === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleSort(item?.value)}>{item?.label}</div>
                                    </div>
                                ))}
                            </>
                    }
                </div>
            </div>
        );
    }

    const ModeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Mode of class"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {modeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.mode === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.mode === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.mode === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleMode(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const TypeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course type"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {typeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.type === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.type === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.type === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleType(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const StatusFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course status"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {statusArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.status === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.status === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.status === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleStatus(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const DurationFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Course duration"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {durationArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.durationFilter === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.durationFilter === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.durationFilter === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleDuration(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    const StudyTypeFilter = () => {
        return (
            <div className='m-b-5'>
                <FilterTitle titleName={"Type"} />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {studyTypeArray?.map((item, index) => (
                        <div className='m-r-20 m-b-10'>
                            <div style={{ height: "fit-content", width: "fit-content", backgroundColor: searchPageParamsObj?.feeType === item?.value ? "#D6C8F5" : "#FFFFFF", border: searchPageParamsObj?.feeType === item?.value ? "1px solid #D6C8F5" : "1px solid #E6E6E6", color: searchPageParamsObj?.feeType === item?.value ? "#191919" : "#636363", borderRadius: 32, padding: "5px 10px 5px 10px", cursor: "pointer", fontSize: 12 }} onClick={() => toggleFeeType(item?.value)}>{item?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const searchFilterMenu = (
        <div style={{ height: "fit-content", padding: 20, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 450 }}>
            {/* <DateFilter /> */}
            <SortFilter />
            <ModeFilter />
            <TypeFilter />
            <StatusFilter />
            <DurationFilter />
            <div className='r-c-c-c' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            <div className='r-jsb m-t-10'>
                <div style={{ color: "#1089FF", fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                        resetValues();
                        setSearchFilterClicked(false);
                        togglePageLoader(true);
                        getSearchResultsData(() => { togglePageLoader(false) })
                    }}>Reset</div>
                <div className='r-c-c-c' style={{ color: "#FFFFFF", backgroundColor: "#1089FF", borderRadius: 4, padding: "5px 10px", fontWeight: 600, fontSize: 13, cursor: "pointer" }}
                    onClick={() => {
                        setSearchFilterClicked(false)
                        togglePageLoader(true);
                        getSearchResultsData(() => { togglePageLoader(false) })
                    }}
                >Apply</div>
            </div>
        </div>
    )

    const multipleFilterMenu = (
        <div style={{ height: "fit-content", padding: 20, backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 450, borderRadius: 17 }}>
            {/* {(courseTypeName == "liveCourses" || previousCourseType == "liveCourses") ? <DateFilter /> : <></>} */}
            <SortFilter />
            {(courseTypeName != "liveCourses" || previousCourseType != "liveCourses") ? <StudyTypeFilter /> : <></>}
            {(courseTypeName == "liveCourses" || previousCourseType == "liveCourses") ? <ModeFilter /> : <></>}
            {(courseTypeName == "liveCourses" || previousCourseType == "liveCourses") ? <DurationFilter /> : <></>}
            <div className='r-c-c-c' style={{ borderBottom: "1px solid #AEAEAE", width: "100%" }}></div>
            <div className='r-jsb m-t-10'>
                <div style={{ color: "#594099", fontWeight: "bold", cursor: "pointer", marginTop: 10 }} onClick={() => {
                    resetValues();
                    setMultipleFilterClicked(false);
                    togglePageLoader(true);
                    if (isSubCategoryVisible) {
                        getCoursesSubCategories(selectedCategory?.id, () => { togglePageLoader(false) })
                    } else if (isCoursesCardsVisible) {
                        if (courseTypeName == "liveCourses") {
                            getTotalCoursesList(() => togglePageLoader(false))
                        } else if (courseTypeName == "studyMaterials") {
                            getStudyMaterialsList(() => togglePageLoader(false))
                        } else if (courseTypeName == "videoResources") {
                            getTotalsVideoResource(() => togglePageLoader(false))
                        }
                        else if (courseTypeName == "onlineTestSeries") {
                            getOnlineTestSeries(() => togglePageLoader(false))
                        }
                    } else {
                        getCoursesCategories(() => togglePageLoader(false))
                    }
                }}>RESET</div>
                <div className='r-c-c-c' style={{ color: "#FFFFFF", backgroundColor: "#594099", borderRadius: 4, padding: "5px 10px", fontWeight: 600, fontSize: 13, cursor: "pointer", marginTop: 10 }}
                    onClick={() => {
                        setMultipleFilterClicked(false)
                        togglePageLoader(true);
                        if (isSubCategoryVisible) {
                            getCoursesSubCategories(selectedCategory?.id, () => { togglePageLoader(false) })
                        } else if (isCoursesCardsVisible) {
                            if (courseTypeName == "liveCourses") {
                                getTotalCoursesList(() => togglePageLoader(false))
                            } else if (courseTypeName == "studyMaterials") {
                                getStudyMaterialsList(() => togglePageLoader(false))
                            } else if (courseTypeName == "videoResources") {
                                getTotalsVideoResource(() => togglePageLoader(false))
                            }
                            else if (courseTypeName == "onlineTestSeries") {
                                getOnlineTestSeries(() => togglePageLoader(false))
                            }
                        } else {
                            getCoursesCategories(() => togglePageLoader(false))
                        }
                    }}
                >APPLY</div>
            </div>
        </div>
    )

    const RatingStars = ({ star }) => {
        const fullStars = Math.floor(star);
        const halfStar = star % 1 === 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarFilled key={i} className="fas fa-star" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
        }
        if (halfStar) {
            stars.push(<StarFilled key="half" className="fas fa-star-half-alt" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<StarFilled key={`empty-${i}`} className="fas fa-star" style={{ color: '#AEAEAE', marginLeft: 2, fontSize: 12 }} />);
        }
        return (
            <div style={{ display: "flex", }}>
                <div style={{ color: "#FFA931", fontWeight: "bold", marginRight: 5 }}>{star}</div>
                <div >{stars.map((starElement) => starElement)}</div>
            </div>
        );
    };
    const renderTitle = (title) => (
        <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
    );
    const handleAutocompleteSearch = (value) => {
        setAutocompleteSearchedValue(value);
        setSearchLoader(true);
        getAutocompleteSearch(value, () => { setSearchLoader(false) })
    }
    const searchOptions = [
        {
            label: autocompleteSearchData?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
            options: autocompleteSearchData?.suggestions?.length ? autocompleteSearchData?.suggestions?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => {
                        history.push({
                            pathname: "/search-results",
                            state: {
                                key: {
                                    fromCourse: isCoursesCardsVisible ? 'fromCourseCard' : isSubCategoryVisible ? 'fromSubCategory' : 'fromCategory',
                                    selectedCategory: selectedCategory,
                                    courseTypeName: courseTypeName

                                }
                            }
                        });
                        setSelectedSuggestion(item);
                        setSearchedSuggestionClicked(true);
                        setLoadMorePage({
                            courseLoadMore: 1,
                            videoLoadMore: 1,
                            studyLoadMore: 1,
                            testsLoadMore: 1
                        });
                        resetValues();
                        setSearchResultPageParamsObj("search", item);
                        togglePageLoader(true);
                        // getSearchResultsData(() => togglePageLoader(false));
                    }}>
                        <SearchOutlined /><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
        {
            label: autocompleteSearchData?.resources?.length != 0 ? renderTitle("Courses") : null,
            options: autocompleteSearchData?.resources?.length ? autocompleteSearchData?.resources?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => { setCourseTypeName(item?.type == "course" ? "liveCourses" : item?.type == "videoResource" ? "videoResources" : item?.type == "studyMaterial" ? "studyMaterials" : item?.type == "testSeries" ? "onlineTestSeries" : null); storeSingleCourseId(item?.id); history.push("/course-details") }}>
                        {<img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 70, height: 40 }} />}
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item?.name}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
    ];

    const actions = [
        <Dropdown overlay={isSearchFilterClicked ? searchFilterMenu : <></>} trigger={['click']} open={isSearchFilterClicked}>
            <div
            onClick={() => { setSearchFilterClicked(!isSearchFilterClicked) }} 
             style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", width: 150, padding: "5px 10px", marginRight: 20, color: "#AEAEAE", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                <div style={{ display: "flex", cursor: "pointer" }}>
                    <img src={require("../../Assets/aulasMarket/filterIC.svg").default} style={{ height: 18, width: 18, marginRight: 10 }} />
                    <div>Filter</div>
                </div>
                {!isSearchFilterClicked ? <CaretDownOutlined /> : <CaretUpOutlined />}
            </div>
         </Dropdown>

        // <div style={{ width: 400 }}>
        //     <AutoComplete
        //         // allowClear
        //         popupClassName="certain-category-search-dropdown"
        //         popupMatchSelectWidth={500}
        //         style={{ marginRight: 20, width: '100%' }}
        //         options={(autocompleteSearchData?.suggestions?.length > 0 || autocompleteSearchData?.resources?.length) ? searchOptions : []}
        //         onSearch={handleAutocompleteSearch}
        //         onClear={() => {
        //             setSelectedSuggestion(null);
        //             setAutocompleteSearchedValue(null);
        //             setSearchedSuggestionClicked(false);
        //             resetValues();
        //             history.push({
        //                 pathname: courseTypeName == "liveCourses" ? "/live-courses-data" : courseTypeName == "videoResources" ? "/video-resources-data" : courseTypeName == "onlineTestSeries" ? "/online-test-series-details" : courseTypeName == "studyMaterials" ? "/study-materials-data" : "/live-courses-data",
        //                 state: {
        //                     key: {
        //                         selectedCategory: location.state.key.selectedCategory
        //                     }
        //                 }
        //             })
        //         }}
        //         value={selectedSuggestion ? selectedSuggestion : autocompleteSearchedValue ? autocompleteSearchedValue : null}
        //         placeholder={
        //             <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 30 }}>
        //                 <span><SearchOutlined /> Search courses</span>
        //             </span>
        //         }
        //         loading={searchLoader}
        //         className='round-bordered-search-course-custom'
        //     />

        //     {/* Dropdown for filter icon */}
        //     <Dropdown
        //         overlay={isMultipleFilterClicked ? multipleFilterMenu : <></>}
        //         trigger={['click']}
        //         open={isMultipleFilterClicked}
        //     >
        //         <div
        //             style={{
        //                 position: 'absolute',
        //                 right: 10, // Adjust positioning as needed
        //                 top: '50%',
        //                 transform: 'translateY(-50%)',
        //                 cursor: 'pointer',
        //             }}
        //             onClick={(e) => {
        //                 e.stopPropagation();
        //                 setMultipleFilterClicked(!isMultipleFilterClicked);
        //             }}
        //         >
        //             <img
        //                 src={require("../../Assets/aulasMarket/filterIC.svg").default}
        //                 style={{
        //                     height: 15,
        //                     width: 15,
        //                     marginRight: 10,
        //                     marginTop: -3

        //                 }}
        //                 alt="Filter Icon"
        //             />
        //         </div>
        //     </Dropdown>
        // </div>,



    ]
    const CourseCardContent = (id, fromSearchCourse, fromSearchVideo, fromSearchStudy, fromSearchTest) => (
        <div style={{ borderRadius: 12 }}>
            {isGridView ? <></> :
                <>
                    <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            storeSingleCourseId(id);
                            if (isSearchedSuggestionClicked) {
                                setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : null);
                            }
                            history.push({
                                pathname: "/course-details",
                                state: {
                                    key: {
                                        fromCourseCard: {
                                            isCoursesCardsVisible: isCoursesCardsVisible,
                                            selectedCategory: selectedCategory,
                                            selectedSubCategory: selectedSubCategory,
                                        },
                                        isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                        searchedValue: selectedSuggestion,
                                        fromDashboard: location?.state?.key?.fromDashboardSearch,
                                        fromDashboardSearch: location?.state?.key?.fromDashboardSearch
                                    }
                                }
                            })
                        }}

                    >
                        <EditOutlined style={{ marginRight: 8 }} />Edit</div>
                    <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsDeleteModalVisible(true); setSelectedPublishCards([id]); setSelectedUnpublishCards([id]); if (isSearchedSuggestionClicked) { setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : fromSearchVideo ? "searchVideo" : fromSearchStudy ? "searchStudyMaterial" : fromSearchTest ? "searchTests" : null) } }}>
                        <DeleteOutlined style={{ marginRight: 8 }} />Delete </div>
                    {(fromSearchCourse || fromSearchVideo || fromSearchStudy || fromSearchTest) ? <></> : <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => { e.stopPropagation(); setIsMoveModalVisible(true); setSelectedPublishCards([id]); setSelectedUnpublishCards([id]) }}>
                        <DragOutlined style={{ marginRight: 8 }} />Move </div>}
                </>
            }
            <div style={{ display: 'flex', marginTop: 3 }} onClick={(e) => { e.stopPropagation(); !isPublished ? toggleUnpublishedCheckbox(id, true) : togglePublishedCheckbox(id, true); setIsConfirmPublishModalVisible(true); }}>
                <div><img src={isPublished ? require("../../Assets/courseManagementV4/unpublished.svg").default : require("../../Assets/courseManagementV4/publish.svg").default} style={{ width: "14px", height: "14px", color: "#636363", marginLeft: 2, marginTop: isGridView ? 9 : courseTypeName !== 'liveCourses' ? 10 : 6 }} onClick={(e) => { e.stopPropagation() }} /></div>
                <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 5, marginLeft: 8 }}>{isPublished ? 'Unpublish' : 'Publish'}</div>
            </div>

            {/* {courseTypeName == "liveCourses" ? <div style={{display:'flex', justifyContent:'space-between', marginTop:3}}>
                        <div><img src={require("../../Assets/courseManagementV4/markas.svg").default} style={{ width: "14px", height: "14px", color: "#636363", marginLeft:2 , marginTop: 10}} onClick={(e) => { e.stopPropagation() }} /></div>
                        <div style={{color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 5, marginLeft:8}}>Mark as course completed</div>
                    </div> :
                    <></> 
                    }     */}
        </div>
    );

    const LiveCourseCard = ({ item, index, reference, fromSearchCourse, fromSearchVideo, fromSearchStudy, fromSearchTest }) => {
        const getAdjustedHour = (duration) => {

            const [hours, minutes] = duration?.split(':').map(Number);

            if (minutes > 30) {
                return hours + 1;
            } else {
                return hours;
            }


        };
        const isSelectedCard = courseTypeName === 'onlineTestSeries'
            ? (isPublished
                ? selectedPublishCards?.includes(publishedTests[index]?.id)
                : selectedUnpublishCards?.includes(unpublishedTests[index]?.id)
            )
            : (
                (fromSearchCourse && reference === 'unpublished')
                    ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                    : (fromSearchCourse && reference === 'published')
                        ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                        : (fromSearchVideo && reference === 'unpublished')
                            ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                            : (fromSearchVideo && reference === 'published')
                                ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                : reference === 'unpublished'
                                    ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                    : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
            );
        return (
            // <div className={`${(fromSearchCourse || fromSearchVideo) ? 'search-live-course-card' : 'zoom live-course-card'}`}
            <div className='new-course-card' style={{
                backgroundColor:
                    courseTypeName === 'onlineTestSeries'
                        ? (
                            isSelectedCard ? "#EDE9F6" : "#ffffff"
                        )
                        : (
                            (fromSearchCourse && reference === "unpublished")
                                ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                : (fromSearchCourse && reference === "published")
                                    ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                    : (fromSearchVideo && reference === "unpublished")
                                        ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                        : (fromSearchVideo && reference === "published")
                                            ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                            : reference === "unpublished"
                                                ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                        )
                            ? "#EDE9F6"
                            : "#ffffff"




                , padding: 15, cursor: 'pointer', borderRadius: 10, width: '31.9%', marginBottom: '2.1%', boxShadow: '4px 4px 16px #0000000F'
            }}
                onClick={(e) => {
                    e.stopPropagation();
                    storeSingleCourseId(item?.id);
                    if (isSearchedSuggestionClicked) {
                        setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : null);
                    }
                    history.push({
                        pathname: "/course-details",
                        state: {
                            key: {
                                fromCourseCard: {
                                    isCoursesCardsVisible: isCoursesCardsVisible,
                                    selectedCategory: selectedCategory,
                                    selectedSubCategory: selectedSubCategory,
                                },
                                isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                searchedValue: searchPageParamsObj?.search,
                                fromDashboard: location?.state?.key?.fromDashboardSearch,
                                fromDashboardSearch: location?.state?.key?.fromDashboardSearch
                            }
                        }
                    })
                }}
            >
                <div style={{ position: "relative", }}>
                    <img src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{ width: "100%", height: 180, borderRadius: 8 }} loading='lazy' />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {hasMarketingPrivilege ? <></> :
                            <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>
                                <Checkbox
                                    checked={courseTypeName == 'onlineTestSeries' ?

                                        ((fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                            : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                                : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.testSeries?.items?.[index]?.id)
                                                    : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.testSeries?.items?.[index]?.id)
                                                        : reference == "unpublished"
                                                            ? (courseTypeName == "onlineTestSeries")
                                                                ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                                : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                            : (courseTypeName == "onlineTestSeries")
                                                                ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                                : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                        )

                                        :

                                        (fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                            : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                                : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                    : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                        : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                            : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}


                                    onChange={() => {
                                        if (fromSearchCourse || fromSearchVideo) {
                                            setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
                                        }
                                        if (fromSearchStudy) {
                                            setSearchSelectedCourseType("searchStudyMaterial")
                                        }
                                        if (fromSearchTest) {
                                            setSearchSelectedCourseType("searchTests")
                                        }

                                        reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id, false) : togglePublishedCheckbox(item?.id, false)
                                    }} />


                            </div>
                        }


                        {hasMarketingPrivilege ? <></> :
                            <div className={`${(isSelectedCard) ? 'three-dots-div' : 'hovered-text-visible'}`} style={{ cursor: isSelectedCard ? 'not-allowed' : 'pointer' }}>
                                <Popover content={(isSelectedCard) ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger="hover" placement='right' showArrow={false}>
                                    <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey", position: "absolute", top: 5, right: 5 }} onClick={(e) => { e.stopPropagation() }} />
                                </Popover>
                            </div>
                        }

                    </div>
                    {hasMarketingPrivilege ? <></> :
                        <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>
                            <Checkbox
                                checked={courseTypeName == 'onlineTestSeries' ?

                                    ((fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                        : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                            : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.testSeries?.items?.[index]?.id)
                                                : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.testSeries?.items?.[index]?.id)
                                                    : reference == "unpublished"
                                                        ? (courseTypeName == "onlineTestSeries")
                                                            ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                            : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : (courseTypeName == "onlineTestSeries")
                                                            ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                            : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                    )

                                    :

                                    (fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                        : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                            : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                    : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}


                                onChange={() => {
                                    if (fromSearchCourse || fromSearchVideo) {
                                        setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
                                    }
                                    if (fromSearchStudy) {
                                        setSearchSelectedCourseType("searchStudyMaterial")
                                    }
                                    if (fromSearchTest) {
                                        setSearchSelectedCourseType("searchTests")
                                    }

                                    reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id, false) : togglePublishedCheckbox(item?.id, false)
                                }} />
                        </div>
                    }

                    {(courseTypeName == "videoResources" || fromSearchVideo) ? <></> : <div style={{ borderTopLeftRadius: 3, borderBottomLeftRadius: 3, fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "5px 10px", position: "absolute", fontWeight: "bold", top: 28, right: 0 }}>{item?.mode == "online" ? "Online" : item?.mode == "offline" ? "Offline" : "Hybrid"}</div>}
                    <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ justifyContent: 'space-between', height: 30, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "0px 10px", alignItems: "center", position: "absolute", bottom: 0, borderRadius: "0px 0px 8px 8px" }}>
                        {item?.ratingValue ? <div><StarFilled style={{ color: "#FFA931", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.ratingValue || 0}</span></div> : <div></div>}
                        <div><UserOutlined style={{ color: "#FFFFFF", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.studentCount} {courseTypeName == 'liveCourses' ? 'enrolled' : 'purchased'}</span></div>
                    </div>
                </div>
                <div style={{ padding: "0px 0px 0px 0px", }}>
                    {(fromSearchCourse || fromSearchVideo) ?
                        <div className='r-jsb m-t-5'>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                            <div>{item?.published ?
                                <img src={require("../../Assets/course/check.svg").default} style={{ height: 16, width: 16 }} />
                                :
                                <img src={require("../../Assets/course/block.svg").default} style={{ height: 16, width: 16 }} />
                            }</div>
                        </div>
                        :
                        <Typography.Text
                            ellipsis={false}
                            style={{
                                fontSize: 16,
                                color: "#191919",
                                fontWeight: "400",
                                marginTop: 7,
                                width: '100%',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: '23px'
                            }}
                        >
                            {item?.name}
                        </Typography.Text>
                    }

                    {courseTypeName == 'liveCourses' ?
                        <div style={{ color: '#636363', marginTop: 2 }}>
                            <span>{parseInt(item?.duration) > 0 ? item?.duration : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.duration) > 0 ? item?.durationType?.toLowerCase() : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? '|' : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? item?.subjectCount : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? parseInt(item?.subjectCount) == 1 ? 'subject' : 'subjects' : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? '|' : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? item?.chapterCount : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? parseInt(item?.chapterCount) == 1 ? 'chapter' : 'chapters' : <></>}</span>
                        </div>
                        : courseTypeName == 'videoResources' ?

                            <div style={{ color: '#636363', marginTop: 2 }}>
                                <span>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? getAdjustedHour(item?.duration) : <></> : <></>}</span>
                                <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? 'hours' : <></> : <></>}</span>
                                <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{parseInt(item?.quizCount) > 0 && item?.duration ? getAdjustedHour(item?.duration) > 0 ? '|' : <></> : <></>}</span>
                                <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{parseInt(item?.quizCount) > 0 ? item?.quizCount : <></>}</span>
                                <span style={{ marginLeft: parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.quizCount) > 0 ? parseInt(item?.quizCount) == 1 ? 'quiz' : 'quizzes' : <></>}</span>
                                <span style={{ marginLeft: parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? '|' : <></>}</span>
                                <span style={{ marginLeft: parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.notesCount) > 0 ? item?.notesCount : <></>}</span>
                                <span style={{ marginLeft: 5 }}>{parseInt(item?.notesCount) > 0 ? parseInt(item?.notesCount) == 1 ? 'notes' : 'notes' : <></>}</span>
                            </div>
                            :
                            courseTypeName == 'onlineTestSeries' ?

                                <div style={{ color: '#636363', marginTop: 2 }}>
                                    <span>{parseInt(item?.tCount) > 0 ? item?.tCount : <></>}</span>
                                    <span style={{ marginLeft: 5 }}>{parseInt(item?.tCount) > 0 ? parseInt(item?.tCount) == 1 ? 'test' : 'tests' : <></>}</span>

                                </div>
                                :
                                <div style={{ color: '#636363', marginTop: 2 }}>
                                    <span>{parseInt(item?.m_count) > 0 ? item?.m_count : <></>}</span>
                                    <span style={{ marginLeft: 5 }}>{parseInt(item?.m_count) > 0 ? parseInt(item?.m_count) == 1 ? 'material' : 'materials' : <></>}</span>

                                </div>
                    }
                    <div style={{ display: "flex", justifyContent: 'space-between', marginTop: 2 }}>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <RatingStars star={item?.ratingValue || 0} />
                            <div style={{ color: "#AEAEAE", margin: "5px 0px 0px 10px", fontFamily: "roboto", fontWeight: 400, marginTop: -1 }}>({item?.ratingCount || 0})</div>
                        </div>
                        <div>
                            {parseInt(item?.studentCount) > 0 ?
                                <span ><img src={require("../../Assets/courseManagementV4/Team.svg").default} style={{ marginTop: -4, height: 15, width: 15 }} /></span> : <></>}
                            <span style={{ color: '#636363', marginLeft: 10, }}>{parseInt(item?.studentCount) > 0 ? item?.studentCount : <></>}</span> <span style={{ marginLeft: 5, color: '#636363', marginTop: -3 }}><span style={{ color: '#636363' }}>{parseInt(item?.studentCount) > 0 ? 'enrolled' : <></>}</span></span>
                        </div>


                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <div>
                            {/* Calculate final price after discount */}
                            {item?.discountType ? item?.discountType === "percent"
                                ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0
                                    ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8, }}>
                                        <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                    </div>
                                    : (
                                        <>

                                            <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                                ₹ {Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100}
                                            </span>

                                            {item?.discount > 0 && (
                                                <span
                                                    style={{ fontSize: 14, color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>
                                                    ₹ {item?.amount}
                                                </span>
                                            )}
                                        </>
                                    )
                                : item?.discountType === "amount"
                                    ? (item?.amount - item?.discount) === 0
                                        ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8 }}>
                                            <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                        </div>
                                        : <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                            ₹ {item?.amount - item?.discount}
                                        </span>
                                    : <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                        ₹ {item?.amount}
                                    </span>
                                :
                                (
                                    item?.amount - item?.discount === 0 ?
                                        <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8 }}>
                                            <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                        </div>
                                        :
                                        <div>
                                            : <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                                ₹ {item?.amount - item?.discount}
                                            </span>
                                        </div>)
                            }

                        </div>
                        <div style={{
                            display: 'flex',
                            marginTop: (item?.discountType === "percent" && Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0)
                                || (item?.discountType === "amount" && (item?.amount - item?.discount) === 0)
                                ? '8px'
                                : '0px'
                        }}>

                            <span>
                                <img
                                    src={require("../../Assets/courseManagementV4/share-2.svg").default}
                                    style={{ height: 20, width: 20, cursor: (isSelectedCard) ? 'not-allowed' : 'pointer' }}

                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isSelectedCard) {

                                        }
                                        else {
                                            setShareModalVisible(true);
                                            setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${courseTypeName}`);
                                        }
                                    }}
                                />
                            </span>
                            <span style={{ cursor: isSelectedCard ? 'not-allowed' : 'pointer' }}>
                                <Popover content={(isSelectedCard) ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger="hover" placement='right' showArrow={false}>
                                    <img
                                        src={require("../../Assets/courseManagementV4/new-more-icon.svg").default}
                                        style={{ width: 20, height: 20, color: "grey", marginLeft: 5 }}
                                        onClick={(e) => { e.stopPropagation(); }}
                                    />
                                </Popover>

                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const toggleUnpublishedCheckbox = (id, isListPublishClicked) => {
        if (isListPublishClicked) {
            setSelectedUnpublishCards([id]);
        }
        else {
            if (selectedUnpublishCards.includes(id)) {
                setSelectedUnpublishCards(selectedUnpublishCards?.filter(cardId => cardId !== id));
            } else {
                setSelectedUnpublishCards([...selectedUnpublishCards, id]);
            }
        }
    };

    const togglePublishedCheckbox = (id, isListPublishClicked) => {
        if (isListPublishClicked) {
            setSelectedPublishCards([id]);
        } else {
            if (selectedPublishCards.includes(id)) {
                setSelectedPublishCards(selectedPublishCards?.filter(cardId => cardId !== id));
            } else {
                setSelectedPublishCards([...selectedPublishCards, id]);
            }
        }
    };

    return (
        <div >
            <div >
                <PageHeader title={"Search results"}
                    onBackPress={() => {
                        history.push({
                            pathname: courseTypeName == "liveCourses" ? "/live-courses-data" : courseTypeName == "videoResources" ? "/video-resources-data" : courseTypeName == "onlineTestSeries" ? "/online-test-series-details" : courseTypeName == "studyMaterials" ? "/study-materials-data" : "/live-courses-data",
                            state: {

                                key: {
                                    selectedCategory: location.state.key.selectedCategory,
                                    fromSearchToCoursePage:location.state.key.isCoursesCardsVisible,
                                    selectedSubCategory:location.state.key.selectedSubCategory,
                                    courseTypeName:location.state.key.courseTypeName
                                    
                                }
                            }
                        })
                    }}
                    // onBackPress={() => {
                    //     resetValues();

                    //       if (isSearchedSuggestionClicked) {
                    //         setCourseTypeName(previousCourseType);
                    //         resetValues();
                    //         history.push(previousCourseType == "liveCourses" ? "/live-courses-data" : previousCourseType == "videoResources" ? "/video-resources-data" : previousCourseType == "onlineTestSeries" ? "/online-test-series-details" : previousCourseType == "studyMaterials" ? "/study-materials-data" : "/live-courses-data")
                    //         setAutocompleteSearchedValue(null);
                    //         setSelectedSuggestion(null);
                    //         setSearchedSuggestionClicked(false);
                    //         if (selectedCategory?.id && selectedSubCategory?.id) {
                    //             setCoursesCardsVisible(true);
                    //             togglePageLoader(true)
                    //             if (previousCourseType == "liveCourses") {
                    //                 getTotalCoursesList(() => togglePageLoader(false))
                    //             } else if (previousCourseType == "studyMaterials") {
                    //                 getStudyMaterialsList(() => togglePageLoader(false))
                    //             } else if (previousCourseType == "videoResources") {
                    //                 getTotalsVideoResource(() => togglePageLoader(false))
                    //             } else if (previousCourseType == "onlineTestSeries") {
                    //                 getOnlineTestSeries(() => togglePageLoader(false))
                    //             }
                    //         } else if (selectedCategory?.id) {
                    //             setSubCategoryVisible(true)
                    //         }
                    //     } else if (isCoursesCardsVisible) {
                    //         setCoursesCardsVisible(false);
                    //         setSubCategoryVisible(true)
                    //     } else if (isSubCategoryVisible) {
                    //         setSubCategoryVisible(false);
                    //         setCoursesCardsVisible(false)
                    //     } else {
                    //         history.push("/courses")
                    //     }
                    // }}
                    actions={selectedCategoryCards?.length > 0 ? categoryActions : selectedSubCategoryCards?.length > 0 ? subCategoryActions : (selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? courseActions : courseCategoryData?.length != 0 ? actions : []}
                />
            </div>
            <div style={{ marginTop: 50 }}>
                <div style={{ color: "#191919", fontSize: 20, fontWeight: 650 }}>{searchResultData?.totalCount ? searchResultData?.totalCount : "0"} results for “{searchPageParamsObj?.search || location?.state?.key?.searchedValue}”</div>

                {
                    (searchResultData?.courses?.items?.length == 0 &&
                        searchResultData?.videoResources?.items?.length == 0 &&
                        searchResultData?.studyMaterials?.items?.length == 0 &&
                        searchResultData?.testSeries?.items?.length == 0) ? (
                        <div style={{ marginTop: 100, height: "65vh" }}>
                            <NoDataCard />
                        </div>
                    ) : (
                        <>
                            {searchResultData?.courses?.items?.length > 0 && (
                                <div className={`${(searchSelectedCourseType === "searchStudyMaterial" || searchSelectedCourseType === "searchVideo" || searchSelectedCourseType === "searchTests") && (selectedUnpublishCards?.length !== 0 || selectedPublishCards?.length !== 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, marginTop: "20px", fontWeight: 650, marginBottom: "20px" }}>Live Courses</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                        {searchResultData?.courses?.items?.map((item, index) => (
                                            <LiveCourseCard key={index} item={item} index={index} reference={item?.published === 1 ? "published" : "unpublished"} fromSearchCourse={true} />
                                        ))}
                                        {(searchResultData?.courses?.meta?.currentPage !== searchResultData?.courses?.meta?.totalPages && searchResultData?.courses?.meta?.totalItems !== 0) && (
                                            <div className="r-c-c" style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, courseLoadMore: loadMorePage?.courseLoadMore + 1 });
                                                        togglePageLoader(true);
                                                        getCourseLoadMore(loadMorePage?.courseLoadMore + 1, () => { togglePageLoader(false); });
                                                    }}
                                                >LOAD MORE</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {searchResultData?.videoResources?.items?.length > 0 && (
                                <div className={`${(searchSelectedCourseType === "searchCourse" || searchSelectedCourseType === "searchStudyMaterial" || searchSelectedCourseType === "searchTests") && (selectedUnpublishCards?.length !== 0 || selectedPublishCards?.length !== 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, marginTop: "20px", fontWeight: 650, marginBottom: "20px" }}>Video Course</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                        {searchResultData?.videoResources?.items?.map((item, index) => (
                                            <LiveCourseCard key={index} item={item} index={index} reference={item?.published === 1 ? "published" : "unpublished"} fromSearchVideo={true} />
                                        ))}
                                        {(searchResultData?.videoResources?.meta?.currentPage !== searchResultData?.videoResources?.meta?.totalPages && searchResultData?.videoResources?.meta?.totalItems !== 0) && (
                                            <div className="r-c-c" style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, videoLoadMore: loadMorePage?.videoLoadMore + 1 });
                                                        togglePageLoader(true);
                                                        getVideoResourceLoadMore(loadMorePage?.videoLoadMore + 1, () => { togglePageLoader(false); });
                                                    }}
                                                >LOAD MORE</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {searchResultData?.studyMaterials?.items?.length > 0 && (
                                <div className={`${(searchSelectedCourseType === "searchVideo" || searchSelectedCourseType === "searchCourse" || searchSelectedCourseType === "searchTests") && (selectedUnpublishCards?.length !== 0 || selectedPublishCards?.length !== 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, margin: "20px 0px", fontWeight: 650, marginBottom: "20px" }}>Study Materials</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                        {searchResultData?.studyMaterials?.items?.map((item, index) => (
                                            <LiveCourseCard key={index} item={item} index={index} reference={item?.published === 1 ? "published" : "unpublished"} fromSearchStudy={true} />
                                        ))}
                                        {(searchResultData?.studyMaterials?.meta?.currentPage !== searchResultData?.studyMaterials?.meta?.totalPages && searchResultData?.studyMaterials?.meta?.totalItems !== 0) && (
                                            <div className="r-c-c" style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, studyLoadMore: loadMorePage?.studyLoadMore + 1 });
                                                        togglePageLoader(true);
                                                        getStudyMaterialLoadMore(loadMorePage?.studyLoadMore + 1, () => { togglePageLoader(false); });
                                                    }}
                                                >LOAD MORE</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {searchResultData?.testSeries?.items?.length > 0 && (
                                <div className={`${(searchSelectedCourseType === "searchVideo" || searchSelectedCourseType === "searchCourse" || searchSelectedCourseType === "searchStudyMaterial") && (selectedUnpublishCards?.length !== 0 || selectedPublishCards?.length !== 0) ? "disabled-div" : ""}`}>
                                    <div style={{ color: "#191919", fontSize: 18, margin: "20px 0px", fontWeight: 650, marginBottom: "20px" }}>Test Series</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: '2.1%' }}>
                                        {searchResultData?.testSeries?.items?.map((item, index) => (
                                            <LiveCourseCard key={index} item={item} index={index} reference={item?.published === 1 ? "published" : "unpublished"} fromSearchTest={true} />
                                        ))}
                                        {(searchResultData?.testSeries?.meta?.currentPage !== searchResultData?.testSeries?.meta?.totalPages && searchResultData?.testSeries?.meta?.totalItems !== 0) && (
                                            <div className="r-c-c" style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => {
                                                        setLoadMorePage({ ...loadMorePage, testsLoadMore: loadMorePage?.testsLoadMore + 1 });
                                                        togglePageLoader(true);
                                                        getTestsLoadMore(loadMorePage?.testsLoadMore + 1, () => { togglePageLoader(false); });
                                                    }}
                                                >LOAD MORE</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )
                }


            </div>
            {isCreateOrEditCategoryVisible &&
                <CreateOrEditCategoryModal
                    isCreateOrEditCategoryVisible={isCreateOrEditCategoryVisible}
                    setIsCreateOrEditCategoryVisible={setIsCreateOrEditCategoryVisible}
                    isEdit={isEdit}
                    isSubCategory={isSubCategory}
                    selectedCategory={selectedCategory}
                    selectedSubCategory={selectedSubCategory}
                    togglePageLoader={togglePageLoader}
                    getCoursesCategories={getCoursesCategories}
                    getCoursesSubCategories={getCoursesSubCategories}
                />}
            {isMoveModalVisible &&
                <MoveModal
                    isMoveModalVisible={isMoveModalVisible}
                    setIsMoveModalVisible={setIsMoveModalVisible}
                    selectedSubCategoryCards={selectedSubCategoryCards}
                    setSelectedSubCategoryCards={setSelectedSubCategoryCards}
                    courseSubCategoriesData={courseSubCategoriesData}
                    isSubCategoryVisible={isSubCategoryVisible}
                    courseCategoryData={courseCategoryData}
                    togglePageLoader={togglePageLoader}
                    getCoursesSubCategories={getCoursesSubCategories}
                    selectedCategory={selectedCategory}
                    selectedPublishCards={selectedPublishCards}
                    setSelectedPublishCards={setSelectedPublishCards}
                    selectedUnpublishCards={selectedUnpublishCards}
                    setSelectedUnpublishCards={setSelectedUnpublishCards}
                    isCoursesCardsVisible={isCoursesCardsVisible}
                    UnpublishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.testSeries?.items : totalCoursesDataContainer?.unpublished?.items}
                    publishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.testSeries?.items : totalCoursesDataContainer?.published?.items}
                    getTotalCoursesList={getTotalCoursesList}
                    getCoursesCategories={getCoursesCategories}
                    courseTypeName={courseTypeName}
                    getStudyMaterialsList={getStudyMaterialsList}
                    getTotalsVideoResource={getTotalsVideoResource}
                    getOnlineTestSeries={getOnlineTestSeries}
                />}
            {isDeleteModalVisible &&
                <DeleteModal
                    isDeleteModalVisible={isDeleteModalVisible}
                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                    selectedCategoryCards={selectedCategoryCards}
                    setSelectedCategoryCards={setSelectedCategoryCards}
                    courseCategoryData={courseCategoryData}
                    selectedSubCategoryCards={selectedSubCategoryCards}
                    setSelectedSubCategoryCards={setSelectedSubCategoryCards}
                    courseSubCategoriesData={courseSubCategoriesData}
                    isSubCategoryVisible={isSubCategoryVisible}
                    selectedPublishCards={selectedPublishCards}
                    setSelectedPublishCards={setSelectedPublishCards}
                    selectedUnpublishCards={selectedUnpublishCards}
                    setSelectedUnpublishCards={setSelectedUnpublishCards}
                    isCoursesCardsVisible={isCoursesCardsVisible}
                    UnpublishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.testSeries?.items : totalCoursesDataContainer?.unpublished?.items}
                    publishCourseData={(isSearchedSuggestionClicked && searchSelectedCourseType == "searchCourse") ? searchResultData?.courses?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchVideo") ? searchResultData?.videoResources?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchStudyMaterial") ? searchResultData?.studyMaterials?.items : (isSearchedSuggestionClicked && searchSelectedCourseType == "searchTests") ? searchResultData?.testSeries?.items : totalCoursesDataContainer?.published?.items}
                    togglePageLoader={togglePageLoader}
                    getTotalCoursesList={getTotalCoursesList}
                    getCoursesSubCategories={getCoursesSubCategories}
                    selectedCategory={selectedCategory}
                    getCoursesCategories={getCoursesCategories}
                    courseTypeName={courseTypeName}
                    getStudyMaterialsList={getStudyMaterialsList}
                    getTotalsVideoResource={getTotalsVideoResource}
                    isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                    searchSelectedCourseType={searchSelectedCourseType}
                    getSearchResultsData={getSearchResultsData}
                    getOnlineTestSeries={getOnlineTestSeries}

                />
            }
            {isConfirmPublishModalVisible &&
                <ConfirmPublishOrUnpublishModal
                    isConfirmPublishModalVisible={isConfirmPublishModalVisible}
                    setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}
                    selectedPublishCards={selectedPublishCards}
                    setSelectedPublishCards={setSelectedPublishCards}
                    selectedUnpublishCards={selectedUnpublishCards}
                    setSelectedUnpublishCards={setSelectedUnpublishCards}
                    togglePageLoader={togglePageLoader}
                    getTotalCoursesList={getTotalCoursesList}
                    courseTypeName={courseTypeName}
                    getStudyMaterialsList={getStudyMaterialsList}
                    getTotalsVideoResource={getTotalsVideoResource}
                    isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                    searchSelectedCourseType={searchSelectedCourseType}
                    getSearchResultsData={getSearchResultsData}
                    getOnlineTestSeries={getOnlineTestSeries}
                    setHoveredCardId={setHoveredCardId}
                />}
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}


const mapStateToProps = (state) => {
    const { courseTypeName, courseCategoryData, courseSubCategoriesData, totalCoursesDataContainer, courseStatsData, courseCreateObj, autocompleteSearchData, searchResultData, searchPageParamsObj, storeCategoryData } = state.courseManagement;
    const { user } = state.session;
    return {
        courseTypeName, courseCategoryData, courseSubCategoriesData, totalCoursesDataContainer, courseStatsData, courseCreateObj, autocompleteSearchData, searchResultData, searchPageParamsObj, storeCategoryData, user
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCoursesCategories: (callBack) => dispatch(getCoursesCategories(callBack)),
    getCoursesSubCategories: (id, callBack) => dispatch(getCoursesSubCategories(id, callBack)),
    getOnlineTestSeries: (callBack) => dispatch(getOnlineTestSeries(callBack)),
    getTotalCoursesList: (callBack) => dispatch(getTotalCoursesList(callBack)),
    setAllCoursesPageParamsObj: (key, val) => dispatch(setAllCoursesPageParamsObj(key, val)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    getStudyMaterialsList: (callBack) => dispatch(getStudyMaterialsList(callBack)),
    getTotalsVideoResource: (callBack) => dispatch(getTotalsVideoResource(callBack)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    getAutocompleteSearch: (search, callBack) => dispatch(getAutocompleteSearch(search, callBack)),
    getSearchResultsData: (callBack) => dispatch(getSearchResultsData(callBack)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    getCourseLoadMore: (page, callBack, published) => dispatch(getCourseLoadMore(page, callBack, published)),
    getVideoResourceLoadMore: (page, callBack, published) => dispatch(getVideoResourceLoadMore(page, callBack, published)),
    getTestsLoadMore: (page, callBack, published) => dispatch(getTestsLoadMore(page, callBack, published)),
    getStudyMaterialLoadMore: (page, callBack, published) => dispatch(getStudyMaterialLoadMore(page, callBack, published)),
    getCourseStats: () => dispatch(getCourseStats()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchAllCoursesPage);
