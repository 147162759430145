import React, { useEffect, useState } from 'react'
import TableComponent from './localComponents/tableComponent';
import { Button, Select, Tooltip, Typography } from "antd";
import { ArrowLeftOutlined, DownloadOutlined, CheckCircleFilled } from "@ant-design/icons";
import Progress from "antd/lib/progress";
import { useHistory } from 'react-router';
import LeaveDetailsModal from './leaveDetailsModal';
import TeachersWorkhrsGraph from './teachersWorkHrsGraph';
import ScrollBarGraph from './localComponents/scrollBarGraph';
import {
  getLeavesTabData,
  setCardId,
  setActionData,
  requestedActionData,
  storeSelectedMonth,
  usersDownloadReport,
  storeSelectedRange,
  storeStartDate,
  storeEndDate,
  storeAttendanceDetailsMonth,
  attendanceDetailsDownloadReport
} from 'actions/attendanceNew';
import "./style.css"
import { connect } from 'react-redux';
import moment from "moment";
import { Api } from '../../services';
import PageDataLoader from 'components/pageDataLoader';
import { NodataCard } from 'pages/Test';
import TeachersDownloadReportModal from './Modals/teachersDownloadReportModal';
import ConfirmRejectModal from './Modals/confirmRejectModal';
import ConfirmApproveModal from './Modals/confirmApproveModal';
import { SubIcon } from 'components/subIcon';
import AssignLocModal from './assignLocModal';


const AbsentImage = require("../../Assets/user/ic-absent.svg").default;
const OverAllImage = require("../../Assets/user/ic-overall.svg").default;
const PresentImage = require("../../Assets/user/ic-present.svg").default;
const WorkingImage = require("../../Assets/user/ic-working-hrs.svg").default;
const AvgWorkingImage = require("../../Assets/user/ic-avg-working-hrs.svg").default;
const halfDay = require('../../Assets/Attendance/Group 13469.svg').default;
const warning = require("../../Assets/ic-warning.svg").default

const images = require("../../Assets/user/ic-avg-working-hrs.svg").default;

const bodyStyle = {
  // background: "#FAFAFA",
  width: "calc(100% + 40px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflowY: "auto",
};

const PageHeader = ({ goBack }) => {
  return (
    <div className="resultPageHeader full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined
          onClick={goBack}
          style={{ fontSize: "22px", }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

const AttendanceCard = ({ data, leavesTabDataContainer }) => {
  return (
    <div style={{
      marginTop: "20px",
      marginLeft: "10px",
      borderRadius: "6px",
      alignItems: "center",
      marginRight: "10px",
      border: "1px solid #E6E6E6",
      width: 239,
      height: 100,
      backgroundColor: "#FFFFFF",
    }}>

      <div style={{ display: "flex", marginTop: "20px", marginLeft: "20px" }}>
        <div><img style={{ width: "60px", height: "60px" }}
          src={
            data[0] === "PresentCount" ? PresentImage :
            data[0] === "AbsentCount" ? AbsentImage :
            data[0] === "overallPercentage" ? OverAllImage :
            data[0] === "totalWorkingHoursTillDate" ? WorkingImage :
            data[0] === "averageWorkingHoursTillDate" ? AvgWorkingImage : 
            data[0] === "HalfDayCount" ? halfDay : null
            } />
        </div>
        <div style={{ marginLeft: "15px" }}>
          <div style={{ fontSize: "21px", color: "#191919", fontWeight: "bold", width: "100vh" }}>{data[1] === null ? "0" : data[1]}</div>
          <div style={{ fontSize: "13px", color: "#636363", }}>{data[0] === "PresentCount" ? "Present day(s)" :
            data[0] === "AbsentCount" ? "Absent day(s)" :
            data[0] === "overallPercentage" ? "Overall percentage" :
            data[0] === "totalWorkingHoursTillDate" ? "Total working hours" :
            data[0] === "averageWorkingHoursTillDate" ? "Average working hours" : 
            data[0] === "HalfDayCount" ? "Half day(s)" : null}
          </div>
          {data[0] === "averageWorkingHoursTillDate" &&
            leavesTabDataContainer?.attendanceTab?.warning ?
            <div style={{ fontSize: 6, width: '100px', marginTop: -3 }}>
              <Tooltip trigger={'hover'} placement='bottom' title={`Your attendance is ${leavesTabDataContainer?.attendanceTab?.shortFallPercentage}% lower than minimum requirements`} overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px" }} >
                <div className='' style={{ color: "#FF414D", fontSize: "10px", marginTop:2}}><img className='p-2' style={{ height: "15px", width: "15px", marginTop: -3 }} src={warning} />Low working hours</div>
              </Tooltip>
            </div>
            : null}
        </div>
      </div>
    </div>
  )
}

const OverViewCard = ({
  leavesTabDataContainer,
}) => {
  const overviewData = Object.entries(leavesTabDataContainer?.attendanceTab?.overview ? leavesTabDataContainer?.attendanceTab?.overview : {});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fromOverViewAssign,setFromOverViewAssign] = useState(false)
  return (
    <div style={{
      width: "100%",
      height: "fit-content",
      borderRadius: 6,
      backgroundColor: "#FFFFFF",
      border: "1px solid #E6E6E6",
      padding: 10,
      paddingBottom: "20px"
    }}>
      <div style={{ marginLeft: '10px' }}>
        <div style={{ fontSize: "18px", color: "#191919", fontWeight: "bold" }}>Overview</div>
        {leavesTabDataContainer?.attendanceTab?.since == "Invalid date" ?
          <></>
          :
          <div style={{ fontSize: "13px", color: "#636363" }}>From  {" "}{leavesTabDataContainer?.attendanceTab?.since} - To{" "}{leavesTabDataContainer?.attendanceTab?.tillDate}</div>
        }
        {isModalVisible && (
              <AssignLocModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              fromOverViewAssign={fromOverViewAssign}
              />
            )}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {overviewData?.map((items) => (
          <AttendanceCard
            data={items}
            leavesTabDataContainer={leavesTabDataContainer}
          />
        ))}
      </div>
    </div>
  )
}

const TimeSheetGraph = ({ leavesTabDataContainer, addDuration }) => {

  return (
    <div>
      <div className='flex-row m-t-20' style={{ justifyContent: "center" }}>
        <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>PRESENT DAYS</div>
          <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.presentDays}</div>
        </div>
        <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>ABSENT DAYS</div>
          <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.absentDays}</div>
        </div>
        <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>HALF DAYS</div>
          <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.halfDays}</div>
        </div>
        <div style={{ width: "20%", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>TOTAL WORKING HOURS</div>
          <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.totalWorkingHoursTillDate}</div>
        </div>
        <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>AVG. WORKING HOURS</div>
          <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>
            {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.averageWorkingHoursTillDate}
          </div>
        </div>
      </div>
      <div className='m-t-30 m-l-20' style={{ height: "280px", }}>
        {leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails ?
          <ScrollBarGraph
            leavesTabDataContainer={leavesTabDataContainer}
     /* color={"#28DF99"} */ />
          :
          <></>
        }
      </div>

    </div>
  )
}

const SubjectCard = ({ subjectIcon, subjectName, teacherName, avgAttendance }) => {
  return (
    <div style={{
      marginTop: "10px",
      borderRadius: "6px",
      borderColor: "#E6E6E6",
      alignItems: "center",
      border: "#E6E6E6 solid 1px",
      minWidth: 290,
      height: 140,
      backgroundColor: "#FFFFFF",
      marginRight: "15px"
      // borderLeft: "5px solid" ,
    }}>
      <div style={{ display: "flex", marginLeft: "20px", marginTop: "20px" }}>
        <div>
          <SubIcon size={40} icon={subjectIcon} name={subjectName} />
        </div>
        <div style={{ marginLeft: "20px", marginTop: "5px" }}>
          <div style={{ fontWeight: "bold", color: "#191919", fontSize: "15px" }}>{subjectName}</div>
          <div style={{ color: "#636363", fontSize: "12px" }}>{teacherName}</div>
        </div>
      </div>
      <div ><Progress
        percent={avgAttendance}
        style={{ width: "90%", marginLeft: "20px" }}
        showInfo={false}
        strokeColor="#594099"
        trailColor="#F6F4FE"
      /></div>
      <div style={{ display: "flex", justifyContent: "space-around" }}><div style={{ color: "#636363", fontSize: "12px" }}>Average attendance</div>
        <div style={{ color: "#636363", fontSize: "12px" }}>{avgAttendance}{"%"}</div></div>
    </div>
  )
}

export const StatusTag = ({ type }) => {
  return (
    <div style={{
      height: "14px",
      width: "47px",
      marginTop: "15px",
      border: `${type === "Pending" ? "1px solid #FFA931" : type === "Approved" ? "1px solid #28DF99" : type === "Rejected" ? "1px solid #FF414D" : null}`,
      borderRadius: " 3px",
      marginRight: "10px",
      background: `${type === "Pending" ? "#FFF4D5" : type === "Approved" ? "#E9FCF5" : type === "Rejected" ? "#FFE4D9" : null}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <div style={{
        font: "normal normal bold 8px/10px Roboto",
        color: `${type === "Pending" ? "#FFA931" : type === "Approved" ? "#28DF99" : type === "Rejected" ? "#FF414D" : null}`
      }}>
        {type === "Pending" ? "PENDING" : type === "Approved" ? "APPROVED" : type === "Rejected" ? "REJECTED" : null}</div>
    </div>
  )
}

export const RequestHistoryCard = ({
  startDate,
  leaveStatus,
  reason,
  tabDataContainer,
  actionByImage,
  actionByName,
  actionByRole,
  createdAtTime,
  clockInTime,
  clockOutTime,
  totalHoursDone,
  leaveCardId,
  setCardId,
  setActionData,
  requestedActionData,
  getLeavesTabData,
  togglePageLoader,
  requestType,
  user,
  getStudentOverview,
  fromStudentProfile,
  specificUserId
}) => {
  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  return (
    <div style={{
      width: "196px",
      height: "90px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #E6E6E6",
      borderRadius: " 4px",
      marginTop: 20,
      marginLeft: "20px",
      cursor: "pointer"
    }} onClick={() => {
      if (isRejectModalVisible && isApproveModalVisible) {
      } else {
        setIsLeaveModalVisible(true);
      }
    }}>
      <div style={{ marginLeft: "20px", }}>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <StatusTag
            type={leaveStatus} />
        </div>
        <div style={{ font: "normal normal bold 14px/18px Roboto", color: "#191919", }}>{startDate}</div>
        <Typography.Text style={{width:"90%"}} ellipsis={{tooltip:true}}>{reason}</Typography.Text>
      </div>
      {isLeaveModalVisible && !isRejectModalVisible&& !isApproveModalVisible ?
      <>
        <LeaveDetailsModal
          isLeaveModalVisible={isLeaveModalVisible}
          setIsLeaveModalVisible={setIsLeaveModalVisible}
          tabDataContainer={tabDataContainer}
          leaveStatus={leaveStatus}
          createdAtTime={createdAtTime}
          clockInTime={clockInTime}
          clockOutTime={clockOutTime}
          totalHoursDone={totalHoursDone}
          reason={reason}
          actionByImage={actionByImage}
          actionByName={actionByName}
          actionByRole={actionByRole}
          leaveCardId={leaveCardId}
          setCardId={setCardId}
          setActionData={setActionData}
          requestedActionData={requestedActionData}
          getLeavesTabData={getLeavesTabData}
          isRejectModalVisible={isRejectModalVisible}
          setIsRejectModalVisible={setIsRejectModalVisible}
          isApproveModalVisible={isApproveModalVisible}
          setIsApproveModalVisible={setIsApproveModalVisible}
          togglePageLoader={togglePageLoader}
          requestType={requestType}
          user={user}
          startDate={startDate}
          getStudentOverview={getStudentOverview}
          fromStudentProfile={fromStudentProfile}
          specificUserId={specificUserId}
        />
        </>:
        null
      }
      {isRejectModalVisible &&
        <ConfirmRejectModal
          isRejectModalVisible={isRejectModalVisible}
          setIsRejectModalVisible={setIsRejectModalVisible}
          functionProp={getLeavesTabData}
          requestedActionData={requestedActionData}
          id={leaveCardId}
          setCardId={setCardId}
          setActionData={setActionData}
          togglePageLoader={togglePageLoader}
          getStudentOverview={getStudentOverview}
          fromStudentProfile={fromStudentProfile}
        />
      }
      {isApproveModalVisible &&
        <ConfirmApproveModal
          isApproveModalVisible={isApproveModalVisible}
          setIsApproveModalVisible={setIsApproveModalVisible}
          functionProp={getLeavesTabData}
          requestedActionData={requestedActionData}
          id={leaveCardId}
          setCardId={setCardId}
          setActionData={setActionData}
          togglePageLoader={togglePageLoader}
          getStudentOverview={getStudentOverview}
          fromStudentProfile={fromStudentProfile}
        />
      }
    </div>
  )
}

const AttendanceTabDetails = ({ getLeavesTabData,
  leavesTabDataContainer,
  tabDataContainer,
  setCardId,
  setActionData,
  requestedActionData,
  storeSelectedMonth,
  editRole,
  usersDownloadReport,
  storeSelectedRange,
  storeStartDate,
  storeEndDate,
  startDateContainer,
  endDateContainer,
  selectedRangeContainer,
  storeAttendanceDetailsMonth,
  attendanceDetailsDownloadReport,
  user,
  specificUserId
}) => {
  const [pageLoader, togglePageLoader] = useState(false);
  const [isModelVisible, setisModelVisible] = useState(false);

  let [sum, setSum] = useState(0);
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }

  const defaultMonth = moment().format('MMMM');

  useEffect(() => {
    togglePageLoader(true)
    getLeavesTabData(() => {
      togglePageLoader(false)
    });
    storeSelectedMonth(defaultMonth);
    storeAttendanceDetailsMonth(defaultMonth)
  }, [])

  useEffect(() => {
    sum = 0;
    if (leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails?.length != 0) {
      leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails?.forEach(
        (item) => (
          (sum = sum + parseFloat(item?.durationInMinutes))
        )
      );
    }
    setSum(sum);
  }, [leavesTabDataContainer?.attendanceTab?.attendanceByTimesheet?.attendanceDetails]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    storeSelectedMonth(value);
    togglePageLoader(true)
    getLeavesTabData(() => {
      togglePageLoader(false)
    })
  };

  const attendanCeDetailsHandleChange = (value) => {
    console.log(`selected ${value}`);
    storeAttendanceDetailsMonth(value);
    togglePageLoader(true)
    getLeavesTabData(() => {
      togglePageLoader(false)
    })
  }

  return (
    <div style={bodyStyle}>
      {/* <PageHeader goBack={goBack}/> */}
      <div style={{ display: "flex"/* ,marginTop:"40px" */, width: "100%" }}>
        {/* <div style={{width:"25%"}}><StudentDetail/></div> */}
        <div style={{ width: "100%"/* ,marginLeft:"40px",overflowY:"scroll" */ }} >
          <div style={{ width: "100%", }}>
            <OverViewCard
              leavesTabDataContainer={leavesTabDataContainer} />
          </div>
          <div style={{ display: "flex", marginTop: "20px", width: "100%", justifyContent: "space-between" }}>
            <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Attendance by time sheet</div>
            <div style={{ display: "flex" }}>
              <div style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", marginTop: "5px", cursor: "pointer" }}
                onClick={() => {
                  setisModelVisible(true)
                }}>
                <DownloadOutlined style={{ color: "#1089FF" }} /> DOWNLOAD REPORT
                {isModelVisible &&
                  <TeachersDownloadReportModal
                    isModelVisible={isModelVisible}
                    setisModelVisible={setisModelVisible}
                    usersDownloadReport={usersDownloadReport}
                    storeSelectedRange={storeSelectedRange}
                    storeStartDate={storeStartDate}
                    storeEndDate={storeEndDate}
                    startDateContainer={startDateContainer}
                    endDateContainer={endDateContainer}
                    selectedRangeContainer={selectedRangeContainer}
                  />
                }
              </div>
              <div style={{ marginLeft: "20px" }}>
                <Select getPopupContainer={(trigger) => trigger.parentElement} defaultValue={defaultMonth} onChange={handleChange} style={{ width: "170px" }}>
                  {console.log("hiudheiurgh", defaultMonth,)}
                  <Option value="January">January</Option>
                  <Option value="February">February</Option>
                  <Option value="March">March</Option>
                  <Option value="April">April</Option>
                  <Option value="May">May</Option>
                  <Option value="June">June</Option>
                  <Option value="July">July</Option>
                  <Option value="August">August</Option>
                  <Option value="September">September</Option>
                  <Option value="October">October</Option>
                  <Option value="November">November</Option>
                  <Option value="December">December</Option>
                </Select>
              </div>
            </div>
          </div>
          <div style={{
            width: "100%",
            height: "400px",
            background: "#FFFFFF",
            border: "1px solid #E6E6E6",
            borderRadius: 6,
            marginTop: "20px",
     /*  minHeight:"600px",
      maxHeight:"400px" */}}>
            {/* {sum === 0 ? (
          <div style={{marginTop:"100px"}}>
            <NodataCard/>
          </div>
          )
           : */}
            {/* ( */}
            <TimeSheetGraph
              leavesTabDataContainer={leavesTabDataContainer} />
            {/* )
           } */}
          </div>
          {leavesTabDataContainer?.attendanceTab?.attendanceBySubject && leavesTabDataContainer?.attendanceTab?.attendanceDetailsByClass ?
            <div>
              <div style={{ display: "flex", marginTop: "20px", }}> <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Subjects</div>
                <div style={{ color: "#AEAEAE", fontSize: "17px", fontWeight: "bold", marginLeft: "5px" }}>
                  ({leavesTabDataContainer?.attendanceTab?.attendanceBySubject?.length})</div></div>
              {leavesTabDataContainer?.attendanceTab?.attendanceBySubject?.length > 0 ?
                <div className='p-5 scroll-bar-universal' style={{ display: "flex", width: "100%", }} >
                  {leavesTabDataContainer?.attendanceTab?.attendanceBySubject?.map((item) => (
                    <SubjectCard
                      subjectIcon={item.icon}
                      subjectName={item.subjectName}
                      teacherName={item.teacherName}
                      avgAttendance={item.averageAttendance} />))}
                </div>
                :
                <div style={{
                  width: "100%",
                  height: "fit-content",
                  background: "#FFFFFF",
                  border: "1px solid #E6E6E6",
                  borderRadius: 6,
                  marginTop: "20px"
                }}>
                  <div style={{ margin: "20px" }}>
                    <NodataCard />
                  </div>
                </div>
              }
              <div style={{ display: "flex", marginTop: "20px", width: "100%", justifyContent: "space-between" }}>
                <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Attendance details</div>
                <div style={{ display: "flex" }}>
                  <div style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", marginTop: "5px", cursor: "pointer" }}
                    onClick={() => { attendanceDetailsDownloadReport() }} >
                    <DownloadOutlined style={{ color: "#1089FF" }} /> DOWNLOAD REPORT

                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <Select getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }} defaultValue={defaultMonth} onChange={attendanCeDetailsHandleChange} style={{ width: "170px" }}>
                      <Option value="January">January</Option>
                      <Option value="February">February</Option>
                      <Option value="March">March</Option>
                      <Option value="April">April</Option>
                      <Option value="May">May</Option>
                      <Option value="June">June</Option>
                      <Option value="July">July</Option>
                      <Option value="August">August</Option>
                      <Option value="September">September</Option>
                      <Option value="October">October</Option>
                      <Option value="November">November</Option>
                      <Option value="December">December</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", }}>
                <TableComponent
                  leavesTabDataContainer={leavesTabDataContainer} /></div></div>
            :
            null
          }
          <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold", marginTop: "20px" }}>Attendance Request History</div>
          <div style={{
            width: "100%",
            height: "fit-content",
            background: "#FFFFFF",
            border: "1px solid #E6E6E6",
            borderRadius: "6px",
            marginTop: "20px",
            paddingBottom: "20px"
          }}>
            {leavesTabDataContainer?.attendanceTab?.attendanceHistory?.length > 0
              ?
              <div className="flex-wrap " style={{ display: "flex", }} >
                {leavesTabDataContainer?.attendanceTab?.attendanceHistory?.map((items) => (
                  <div>
                    <RequestHistoryCard
                      startDate={items.startDate}
                      leaveStatus={items.status}
                      reason={items.reason}
                      tabDataContainer={tabDataContainer}
                      leaveCardId={items.id}
                      createdAtTime={items.createdAt}
                      clockInTime={items.clockIn}
                      clockOutTime={items.clockOut}
                      totalHoursDone={items.totalHours}
                      actionByImage={items.actionBy?.image}
                      actionByName={items.actionBy?.name}
                      actionByRole={items.actionBy?.role}
                      setCardId={setCardId}
                      setActionData={setActionData}
                      requestedActionData={requestedActionData}
                      getLeavesTabData={getLeavesTabData}
                      togglePageLoader={togglePageLoader}
                      requestType={items.requestType}
                      user={user}
                      specificUserId={specificUserId}
                    />
                  </div>
                ))}
              </div>
              :
              <div style={{ marginTop: "40px" }}>
                <NodataCard />
              </div>
            }
          </div>
        </div>

      </div>
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { leavesTabDataContainer, tabDataContainer, selectedMonthContainer, startDateContainer, selectedRangeContainer, attendanceDetailsMonthContainer, endDateContainer } = state.attendanceNew;
  const { editRole, specificUserId } = state.userManagement;
  const { user } = state.session
  return {leavesTabDataContainer, tabDataContainer, selectedMonthContainer, editRole, startDateContainer, selectedRangeContainer, attendanceDetailsMonthContainer, endDateContainer, user, specificUserId};
};

const mapDispatchToProps = (dispatch) => ({
  getLeavesTabData: (callback) => (dispatch(getLeavesTabData(callback))),
  setCardId: (id) => dispatch(setCardId(id)),
  setActionData: (val) => dispatch(setActionData(val)),
  requestedActionData: (callback, successCallBack) => dispatch(requestedActionData(callback, successCallBack)),
  storeSelectedMonth: (val) => dispatch(storeSelectedMonth(val)),
  usersDownloadReport: (callback, successCallBack) => (dispatch(usersDownloadReport(callback, successCallBack))),
  storeSelectedRange: (val) => dispatch(storeSelectedRange(val)),
  storeStartDate: (val) => dispatch(storeStartDate(val)),
  storeEndDate: (val) => dispatch(storeEndDate(val)),
  storeAttendanceDetailsMonth: (val) => dispatch(storeAttendanceDetailsMonth(val)),
  attendanceDetailsDownloadReport: () => dispatch(attendanceDetailsDownloadReport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceTabDetails);
