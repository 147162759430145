import { postCreateCategory, postCreateSubCategory } from 'actions/courseManagement'
import { Input, Modal } from 'antd'
import AnimatedTextField from 'components/AnimatedTextField'
import { Button } from 'components/Button'
import React, { useState } from 'react'
import { connect } from 'react-redux'

function CreateOrEditCategoryModal({ isCreateOrEditCategoryVisible, setIsCreateOrEditCategoryVisible, isEdit, isSubCategory, selectedCategory, selectedSubCategory, postCreateCategory, postCreateSubCategory, togglePageLoader, getCoursesCategories, getCoursesSubCategories }) {
    const handleCancel = () => {
        setIsCreateOrEditCategoryVisible(false)
    }
    const [name, setName] = useState(null);
    const [btnLoader, setBtnLoader] = useState(false)
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isCreateOrEditCategoryVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{isEdit ? "Edit" : "Create"} {isSubCategory ? "subcategory" : "category"}</div>
            {isEdit ? <center style={{ color: "#636363", fontSize: 16, marginTop: 10, padding: "0px 20px" }}>Change {isSubCategory ? "subcategory" : "category"} name from <span style={{ fontWeight: "bold" }}>{isSubCategory ? selectedSubCategory?.name : selectedCategory?.name}</span> to</center> : <></>}
            <div style={{ padding: 20 }}>
                <AnimatedTextField
                    label={isSubCategory ? "Subcategory name" : "Category name"}
                    isMandatory={true}
                    inputId={"-800"}
                    value={name ? name : ''}
                    handleInputChange={(e) => { setName(e.target.value) }}
                    style={{marginTop:10}}
                />
            </div>
            <center>
                <div style={{ display: "flex", justifyContent: "space-around", width: "75%", marginTop: 30 }}>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                        disabled={!name}
                        type="primary"
                        loading={btnLoader}
                        onClick={() => {
                            setBtnLoader(true)
                            if (!isEdit && !isSubCategory) {
                                postCreateCategory(name, () => { setBtnLoader(false) }, () => { setIsCreateOrEditCategoryVisible(false); togglePageLoader(true); getCoursesCategories(() => togglePageLoader(false)) }, false, null)
                            } else if (isEdit && !isSubCategory) {
                                postCreateCategory(name, () => { setBtnLoader(false) }, () => { setIsCreateOrEditCategoryVisible(false); togglePageLoader(true); getCoursesCategories(() => togglePageLoader(false)) }, true, selectedCategory?.id)
                            } else if (!isEdit && isSubCategory) {
                                postCreateSubCategory(name, () => { setBtnLoader(false) }, () => { setIsCreateOrEditCategoryVisible(false); togglePageLoader(true); getCoursesSubCategories(selectedCategory?.id, () => togglePageLoader(false)) }, false, null, true, selectedCategory?.id)
                            } else if (isEdit && isSubCategory) {
                                postCreateSubCategory(name, () => { setBtnLoader(false) }, () => { setIsCreateOrEditCategoryVisible(false); togglePageLoader(true); getCoursesSubCategories(selectedCategory?.id, () => togglePageLoader(false)) }, true, selectedSubCategory?.id, true, selectedCategory?.id)
                            }
                        }}
                    >{isEdit ? "UPDATE" : "CREATE"}</Button>
                </div>
            </center>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    const { courseTypeName, courseCategoryData, courseSubCategoriesData } = state.courseManagement;
    return {
        courseTypeName, courseCategoryData, courseSubCategoriesData
    };
};

const mapDispatchToProps = (dispatch) => ({
    postCreateCategory: (name, callBack, succesCallback, sendId, id) => dispatch(postCreateCategory(name, callBack, succesCallback, sendId, id)),
    postCreateSubCategory: (name, callBack, succesCallback, sendId, id, isEditSubCategoryModal, categoryId) => dispatch(postCreateSubCategory(name, callBack, succesCallback, sendId, id, isEditSubCategoryModal, categoryId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditCategoryModal);