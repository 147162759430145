import { PlusCircleOutlined } from '@ant-design/icons';
import { getAllClasses, storeSectionId, storeSelectedClassDetails, updateSelectedSection } from 'actions/classRoom';
import { updateCurrentStd } from 'actions/digitalLibrary';
import { Button } from 'components/Button';
import { InputSearch } from 'components/customInputs';
import { NodataCard, SingleClass } from 'pages/classRoom';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import CreateClassModal from "../classRoom/modals/createClass"
import ClassCreatedModal from "../classRoom/modals/classCreated"
import EditClassModal from "../classRoom/modals/editClass"
import AddNewSectionModal from "../classRoom/modals/addNewSection"
import { storeSelectedCourseId } from 'actions/course';
import { setSelectedClassroom } from 'actions/testAdmin';
import { setFromCourseDetailsPage } from 'actions/courseManagement';
import "./styles.css"
import { storeResourceData, storeStandardData } from 'actions/feeManagement';

const NoData = require("../../Assets/ill-no-data.svg").default;


function BatchesComponent({ getAllClasses, classFetchLoader, classesOverview, classes, getAllCourses, storeSelectedClassDetails, storeSectionId, storeSelectedCourseId, updateSelectedSection, user, setSelectedClassroom, selectedStd, updateCurrentStd, newCourseId, setFromCourseDetailsPage, setIsBatchesExpandClicked, setIsFeeClosed, wasStudentCardExpanded, setIsStudentExpandClicked, wasDetailsCardExpanded, setIsViewDetailsClicked, hasMarketingPrivilege, specificCourseDataContainer,storeResourceData , storeStandardData}) {
    const classNames = Object.keys(classes);
    const classesLength = classNames.length;
    const getTotalSectionsCount = (dataArray) => {
        for (const item of dataArray) {
            if (item[0] === "totalSections") {
                return item[1];
            }
        }
        return 0;
    };
    const totalSectionsCount = getTotalSectionsCount(Object.entries(classesOverview));
    const [search, setSearch] = useState('');
    const [createClassModalVisible, setCreateClassModalVisible] = useState(false);
    const [showClassCreatedModal, setShowClassCreatedModal] = useState(false);
    const [showEditClassModal, setShowEditClassModal] = useState(false);
    const [isAddSectionVisible, setIsAddSectionVisible] = useState(false);
    let isNodata = true;
    const handleSearch = (e) => {
        setSearch(e);
    }
    const viewSectionDetails = (data) => {
        updateSelectedSection(data)
    }
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        if(specificCourseDataContainer?.course){
            storeResourceData(specificCourseDataContainer?.course)
        }
        const handleScroll = () => {
            if (scrollContainerRef.current.scrollTop === 0) {
                setIsBatchesExpandClicked(false);
                if (wasStudentCardExpanded) {
                    setIsStudentExpandClicked(true)
                }
                if (wasDetailsCardExpanded) {
                    setIsViewDetailsClicked(true);
                    if (wasStudentCardExpanded) {
                        setIsStudentExpandClicked(true)
                    } else {
                        setIsFeeClosed(false)
                    }
                } else {
                    setIsFeeClosed(false)
                }
            }
        };

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    return (
        <div>
            <div className='r-c-sb'>
                <div style={{ color: "#191919", fontSize: 18, fontWeight: "bold" }}>Batches <span style={{ fontWeight: 600, fontSize: 16 }}>({classesLength})</span></div>
                <div className='r-ac'>
                    {classesLength == 0 ? <></> :
                        <InputSearch
                            allowClear
                            placeholder="Search Batches"
                            onInputChange={(e) => { handleSearch(e) }}
                            onClear={() => { handleSearch("") }}
                            style={{ width: 250, marginRight: 20 }}
                        />
                    }
                    {hasMarketingPrivilege ?
                        <></>
                        :
                        <Button type="primary" className="m-l-20" onClick={() => { setCreateClassModalVisible(true) }} style={{}} icon={<PlusCircleOutlined />}>CREATE BATCH</Button>
                    }
                </div>
            </div>
            {classesLength > 0 ? <div className='m-t-10' style={{ color: "#191919", fontSize: 16, fontWeight: "bold" }}>All batches <span style={{ fontWeight: 600, fontSize: 14 }}>(Total sections - {totalSectionsCount})</span></div> : <></>}
            <div ref={scrollContainerRef} className={`scroll-bar-universal m-t-10 ${classFetchLoader ? "r-c-c-c" : ""} ${classesLength == 0 ? 'batches-card-no-data-height' : 'batches-card-data-height'}`} /* style={{ height: classesLength == 0 ? 430 : 392 }} */>
                {classFetchLoader ?
                    <div>
                        <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                    </div>
                    :
                    <>
                        {Object.entries(classes).map((item, index) => {
                            if (item[0]?.toLowerCase().includes(search?.toLowerCase())) {
                                isNodata = false;
                                return (
                                    <>
                                        <SingleClass
                                            item={item}
                                            setShowEditClassModal={setShowEditClassModal}
                                            storeSelectedClassDetails={storeSelectedClassDetails}
                                            setIsAddSectionVisible={setIsAddSectionVisible}
                                            storeSectionId={storeSectionId}
                                            storeSelectedCourseId={storeSelectedCourseId}
                                            viewSectionDetails={viewSectionDetails}
                                            user={user}
                                            setSelectedClassroom={setSelectedClassroom}
                                            updateCurrentStd={updateCurrentStd}
                                            selectedStd={selectedStd}
                                            fromCourseDetails={true}
                                            setFromCourseDetailsPage={setFromCourseDetailsPage}
                                            hasMarketingPrivilege={hasMarketingPrivilege}
                                            storeStandardData={storeStandardData}
                                            
                                        />
                                    </>
                                )
                            }
                            else return null;
                        })}
                        {isNodata ?
                            <div style={{ width: "100%" }}>
                                <div className='r-c-c m-t-50' style={{ flexDirection: "column" }}>
                                    <img src={NoData} alt='' style={{ width: 200 }} />
                                    <div className='m-t-10' style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No batches added</div>
                                    <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There are no batches added to this course yet</center>
                                </div>
                            </div>
                            : null
                        }
                    </>
                }
            </div>
            {createClassModalVisible &&
                <CreateClassModal
                    createClassModalVisible={createClassModalVisible}
                    setCreateClassModalVisible={setCreateClassModalVisible}
                    setShowClassCreatedModal={setShowClassCreatedModal}
                    fromCourseDetails={true}
                    getAllClasses={getAllClasses} />
            }
            {showClassCreatedModal &&
                <ClassCreatedModal
                    showClassCreatedModal={showClassCreatedModal}
                    setShowClassCreatedModal={setShowClassCreatedModal} />
            }
            {showEditClassModal &&
                <EditClassModal
                    showEditClassModal={showEditClassModal}
                    setShowEditClassModal={setShowEditClassModal}
                    fromCourseDetails={true} />
            }
            {isAddSectionVisible &&
                <AddNewSectionModal
                    isAddSectionVisible={isAddSectionVisible}
                    setIsAddSectionVisible={setIsAddSectionVisible}
                    setShowClassCreatedModal={setShowClassCreatedModal}
                    fromCourseDetails={true} />
            }
        </div>
    )
}

const mapStateToProps = state => {
    const { classFetchLoader, classesOverview, classes } = state.classRoom;
    const { newCourseId } = state.courseManagement;
    const { user } = state.session
    const { selectedStd } = state.digitalLibrary;
    return {
        classFetchLoader, classesOverview, classes, user, selectedStd, newCourseId
    };
};

const mapDispatchToProps = dispatch => ({
    getAllCourses: (callback, page, fromClassroom) => dispatch(getAllCourses(callback, page, fromClassroom)),
    storeSelectedClassDetails: (val) => dispatch(storeSelectedClassDetails(val)),
    storeSectionId: (val) => dispatch(storeSectionId(val)),
    storeSelectedCourseId: (val) => dispatch(storeSelectedCourseId(val)),
    updateSelectedSection: data => dispatch(updateSelectedSection(data)),
    setSelectedClassroom: (selectedClass) => dispatch(setSelectedClassroom(selectedClass)),
    updateCurrentStd: (object) => dispatch(updateCurrentStd(object)),
    getAllClasses: (id) => dispatch(getAllClasses(id)),
    setFromCourseDetailsPage: (val) => dispatch(setFromCourseDetailsPage(val)),
    storeResourceData: res => dispatch(storeResourceData(res)),
    storeStandardData: res => dispatch(storeStandardData(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchesComponent)