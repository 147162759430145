import React, { useEffect, useState } from 'react'
import './careStyles.css'
import { useHistory } from 'react-router';
import CareCards from './careCards';
import { getStudentFaqs, getAdminFaqs, setAulasCareChat, getChatUnreadCountForInst } from 'actions/adminCare';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import image0 from 'Assets/care/ic-support.svg'
import image1 from 'Assets/care/ic-chat.svg';
import image2 from 'Assets/care/ic-call-us.svg';
import image3 from 'Assets/care/ic-faq.svg';
import image4 from 'Assets/care/covid.png';
import user from 'pages/user/user';
import { LockOutlined } from '@ant-design/icons';
import PageDataLoader from 'components/pageDataLoader';
import { Heading } from 'components/Typography';



function StudentCare({ getStudentFaqs,unReadMsgCount, faqs, user,setAulasCareChat ,getChatUnreadCountForInst}) {
    const [loader ,setLoader]=useState(false);
    useEffect(() => {
        setLoader(true);
        getStudentFaqs();
        getChatUnreadCountForInst(()=>setLoader(false));

    }, [])

    const history = useHistory();
    const handleViewFaqsClick = () => {
        history.push('/care/faqs')
    }

    const handleViewAulasChatClick = () => {
        setAulasCareChat(false)
        history.push('/care/chat')
    }

    const handleViewAulasChatClickTeacher = () => {
        setAulasCareChat(true)
        history.push('/care/chat')
    }
    const handleViewCovidResourcesClick = () => {
        history.push('care/covid-resources')
    }
    const handleViewAulasSupportClick=()=>{
        history.push('care/admin')
    }

    return (
        <div style={{ height: "100%" }}>

            <div className="careHeader" style={{ display: "flex", height: "40%", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <h2 className="careHeading_h1" style={{ fontSize: "28px" }}>aulas Care</h2>

                </div>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginLeft: "5%", marginTop: "10px" }}>
                    <h2 className="careHeading_h2" style={{ marginBottom: "0px", marginTop: "0px" }}>Tell us how we can help</h2>
                    <p style={{ fontSize: "14px",  opacity: "100%", fontFamily: "roboto",fontWeight:"bold" }}>Our crew of superheroes are standing by for service and support!</p>

                </div>
            </div>
            <div >
            {user.access.includes('care-admin')?
                    <div>
                        <Heading className='m-t-20'>Institute’s support</Heading>
                        <Row>
                            <Col lg={{ span: 12 }} xl={{ span: 12 }} >
                                <div className="careHomeCards cursor-pointer display-flex" onClick={() => handleViewAulasSupportClick()} >
                                    <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image0} alt="" /></div>
                                    <CareCards count={true} unreadMsgCount={faqs?.unreadMessage} heading='Support' discription='Support your students and staff' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    : null}

                {!user.access.includes('care-admin') ?
                    <div>
                        <h1 className="careHeading_h2">Contact your institute</h1>
                        <Row>
                            <Col lg={{ span: 12 }} xl={{ span: 12 }} >
                                <div className="careHomeCards cursor-pointer display-flex" onClick={() => handleViewAulasChatClick()} style={{ maxWidth: 450}} >
                                    <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image1} alt="" /></div>
                                    <CareCards count={true} unreadMsgCount={faqs?.unreadMessage} heading='Chat with your institute' discription='Contact your institute for any support' />
                                </div>
                            </Col>
                            <Col lg={{ span: 12 }} xl={{ span: 12, offset: 0 }} >
                            {faqs?.contactNo ? 
                                <div className="careHomeCards cursor-pointer display-flex"  style={{ maxWidth: 450, marginLeft: 45}} >
                                    <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image2} alt="" /></div>
                                    <CareCards heading='Call your institute' discription={'Call for any inquiry at'} phoneNos={faqs?.contactNo} />
                                </div> : null}
                            </Col>
                        </Row>
                    </div>
                    : null}


                <Row  >
                    <Heading className='m-t-20'>Resources</Heading>
                    <Row style={{ width: "100%" }}>
                        <Col md={{ span: 24, offset: 0  }} lg={{ span: 12, offset: 0  }} xl={{ span: 12 }} >
                            <div className="careHomeCards cursor-pointer display-flex" style={{ maxWidth: 450}} onClick={() => handleViewFaqsClick()}>
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image3} alt="" /></div>
                                <CareCards heading='FAQs' discription='Find intelligent answers instantly' />
                            </div>
                        </Col>
                        <Col sm={24} md={{ span: 24, offset: 0  }} lg={{ span: 12, offset: 0  }} xl={{ span: 11, offset: 1 }} >
                            <div className="careHomeCards cursor-pointer display-flex"  style={{ maxWidth: 450}} onClick={() => handleViewCovidResourcesClick()} >
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image4} alt="" /></div>
                                <CareCards heading='Covid-19 resources' discription='Technical guidance publications' />
                            </div>
                        </Col>
                    </Row>
                </Row>
                {user?.role.includes('Student') ? (
                    <> </>
                ) : (
                    <div>
                    <Heading className='m-t-20'>Get in touch with aulas team</Heading>
                    <Row>
                        <Col md={{ span: 24, offset: 0  }} lg={{ span: 12, offset: 0  }} xl={{ span: 12 }} >
                            <div className="careHomeCards cursor-pointer display-flex" onClick={handleViewAulasChatClickTeacher} style={{ maxWidth: 450}} >
                                {/* <LockOutlined style={{position:"absolute",marginLeft:"380px",marginBottom:"60px",fontSize:"20px",color:"grey"}} /> */}
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image1} alt="" /></div>
                                <CareCards count={true} unreadMsgCount={unReadMsgCount} heading='Chat with aulas team' discription='Contact aulas team for any support' />
                            </div>
                        </Col>
                        <Col md={{ span: 24, offset: 0  }} lg={{ span: 12, offset: 0  }} xl={{ span: 11, offset: 1 }}>
                            <div className="careHomeCards display-flex"  style={{ maxWidth: 450, cursor:"default"}} >
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image2} alt="" /></div>
                                <CareCards heading='Call aulas team' discription={'Call for any inquiry at +91-7788998879'} />
                            </div>
                        </Col>
                    </Row>
                </div>
                )
               }

            </div>


<PageDataLoader visible={loader}/>


        </div>
    )
}

// export default  StudentCare 


const mapStateToProps = state => {
    const {
        faqs,
        unReadMsgCount

    } = state.adminCare;
    const {
        user,
        
    } = state.session;
    return {
        faqs,
        user,
        unReadMsgCount
    };
};

const mapDispatchToProps = dispatch => ({

    getStudentFaqs: () => dispatch(getStudentFaqs()),
    getAdminFaqs: () => dispatch(getAdminFaqs()),
    setAulasCareChat: (m)=>dispatch(setAulasCareChat(m)),
    getChatUnreadCountForInst:(callback)=>dispatch(getChatUnreadCountForInst(callback))

});


export default connect(mapStateToProps, mapDispatchToProps)(StudentCare);
