import { fetchGeoFenceLocationList, postGeoLocation, updateGeometryLocation, updateRadius, updateSearchLocation } from 'actions/attendanceNew'
import { Button, Input } from 'antd'
import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Notification } from 'services'
import { AutoComplete } from '../attendanceGeoFence'
import Geocode from "react-geocode";

const GeoFenceForm = forwardRef(({
    setVisibleGeoFenceForm, editLocationItem, postGeoLocation, fetchGeoFenceLocationList, updateSearchLocation,
    searchLocation, updateRadius, geometryLocation, updateGeometryLocation, setIsEdit, lat, long, isEdit, setLongi, setLatitude
}, ref) => {

    const [title, setTitle] = useState('')
    const [location, setLocation] = useState('')
    const [radiusValue, setRadiusValue] = useState(null)
    const [latitude, setLatitudeGF] = useState('')
    const [longitude, setLongitude] = useState('')
    const [id, setId] = useState(null)
    const [saveLoader, setSaveLoader] = useState(false)
    // const [isEdit, setIsEdit] = useState(false)
    const handleCancel = () => {
        setIsEdit(false)
        updateGeometryLocation({})
        setVisibleGeoFenceForm(false)
        updateSearchLocation('')
    }

    useEffect(() => {
        if (editLocationItem && Object.keys(editLocationItem)?.length != 0) {
            console.log('editLocationItem', editLocationItem)
            setTitle(editLocationItem?.title)
            setLocation(editLocationItem?.address)
            // updateSearchLocation(editLocationItem?.address)
            setRadiusValue(editLocationItem?.radius)
            setLatitudeGF(editLocationItem?.latitude)
            setLongitude(editLocationItem?.longitude)
            setId(editLocationItem?.id)
            updateGeometryLocation({})

        }

    }, [])

    useEffect(() => {
        if (searchLocation && !(editLocationItem && Object.keys(editLocationItem)?.length != 0)) {
            setLocation(searchLocation)
        }
    }, [searchLocation])

    // useEffect(()=>{
    //     console.log(geometryLocation?.geometry?.location.lng())
    //     if(geometryLocation && Object.keys(geometryLocation).length!=0){
    //         setLocation(geometryLocation.formatted_address)
    //         setLatitude(geometryLocation?.geometry?.location.lat())
    //         setLongitude(geometryLocation?.geometry?.location.lng())
    //     }
    // },[geometryLocation])

    const handleTitle = (e) => {
        // console.log(e.target.value)
        setTitle(e.target.value)
    }

    const handleLocation = (e) => {
        setLocation(e.target.value)
        // updateSearchLocation(e.target.value)
    }

    const handleSerachLocation = (value) => {
        // console.log('e',e)
        updateSearchLocation(value)
    }

    const handleRadius = (e) => {
        setRadiusValue(e.target.value)

    }

    const handleEnterRadius = (e) => {
        updateRadius(parseInt(e.target.value))
    }

    let searchRender = (e) => {
        setLocation(e)
        updateSearchLocation(e)
    }

    let buttonHandler = (e) => {
        extractData(e)
        updateSearchLocation(e)
        searchRender(e)
    }

    let allowClearHandler = () => {
        setLocation('')
    }

    let enterHandler = (e) => {
        if (e.key === 'Enter') {
            extractData(location)
            updateSearchLocation(location)
            console.log('Color has been suggested by Bikash Singha from Silchar')
        }

    }

    const extractData = (location) => {
        Geocode.setApiKey("AIzaSyCfVOeSxREHxv3LqvV6eLBlROKwV-64gKQ");
        Geocode.setLanguage("en");


        Geocode.fromAddress(location).then(
            (response) => {
                const { lat, lng } = response?.results[0]?.geometry?.location;
                setLatitude(lat)
                setLongi(lng)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const handleSave = () => {
        if (radiusValue >= 200) {
            var object = {
                id: id,
                // address: location +', '+geometryLocation?.formatted_address,
                address: location,
                title: title,
                // latitude: geometryLocation?.geometry?.location.lat()?geometryLocation?.geometry?.location.lat():latitude,
                latitude: latitude && latitude !== '' ? latitude : lat,
                longitude: longitude && longitude !== '' ? longitude : long,
                // longitude: geometryLocation?.geometry?.location.lng()?geometryLocation?.geometry?.location.lng():longitude,
                // longitude: long,
                radius: radiusValue
            }
            console.log('form', object)
            setSaveLoader(true)
            postGeoLocation(object, () => setSaveLoader(false), successCallback)
            setIsEdit(false)
        } else {
            Notification.error("Error", 'Radius should be more than or equal to 200');
        }

    }

    const successCallback = () => {
        setSaveLoader(false)
        fetchGeoFenceLocationList()
        setVisibleGeoFenceForm(false)
    }

    return (
        <div >
            <div ref={ref} style={{ marginTop: '10px' }}>
                <div className='r-c border-16 boxShadow118' style={{ width: '100%', height: '100%', border: '1px solid #E6E6E6', /* borderRadius: '6px', */ padding: '12px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '5px', padding: '8px' }}>
                        <div className='text-heading'>
                            Title
                        </div>
                        <Input className='attendance-input-area' value={title} onChange={handleTitle} placeholder={'Enter title'}></Input>
                    </div>
                    <div style={{ marginBottom: '5px', padding: '8px' }}>
                        <div className='text-heading'>
                            Location
                        </div>
                        <AutoComplete value={location} searchRender={searchRender} buttonHandler={buttonHandler} setSearchVal={setLocation} isEdit={isEdit} allowClearHandler={allowClearHandler} enterHandler={enterHandler} />
                        {/* <Input.Search className='attendance-input-area' value={location} allowClear onChange={handleLocation} onSearch={handleSerachLocation} /> */}
                    </div>
                    <div style={{ marginBottom: '5px', padding: '8px' }}>
                        <div className='text-heading'>
                            Radius
                        </div>
                        <Input className='attendance-input-area' value={parseInt(radiusValue)} onChange={handleRadius} placeholder={'Value more than or equal to 200'} type='number' onPressEnter={handleEnterRadius} addonAfter='Meter(s)'></Input>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                        <Button type='default' style={{ width: '100px', borderRadius: '16px', marginRight: '5px' }} onClick={() => handleCancel()}>Cancel</Button>
                        <Button disabled={location.trim() == ''}loading={saveLoader} type='primary' style={{ width: '100px', borderRadius: '16px', marginLeft: '5px' }} onClick={() => handleSave()}>Save</Button>
                    </div>
                </div>

            </div>

        </div>
    )
})

const mapStateToProps = state => {
    const {
        searchLocation,
        geometryLocation
    } = state.attendanceNew

    return {
        searchLocation,
        geometryLocation
    }
}

const mapDispatchToProps = (dispatch) => ({
    postGeoLocation: (object, callback, successCallback) => dispatch(postGeoLocation(object, callback, successCallback)),
    fetchGeoFenceLocationList: (callback) => dispatch(fetchGeoFenceLocationList(callback)),
    updateSearchLocation: (search) => dispatch(updateSearchLocation(search)),
    updateRadius: (radius) => dispatch(updateRadius(radius)),
    updateGeometryLocation: (object) => dispatch(updateGeometryLocation(object))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }

)(GeoFenceForm);

// export default GeoFenceForm