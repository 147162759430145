// import { Calendar } from 'antd';
import React from 'react'
import { DatePicker, Space } from 'antd';
import { setEndDate, setStartDate, barGraphPlotFunction } from 'actions/adminCare';
import { connect } from 'react-redux';
import { fetchPerfomanceGraph, setEndDateLib, setStartDateLib } from 'actions/digitalLibrary';
import moment from 'moment';
 

const { RangePicker } = DatePicker;


const CalenderPopup = ( {fetchPerfomanceGraph,setStartDate,setStartDateLib,setEndDateLib,setEndDate,barGraphPlotFunction,togglePageLoader,digitalLib,startDate,endDate}) =>{
   
    function onChange(dates, dateStrings) {
      if(digitalLib==true)
      {
        console.log("AA-dateString",dateStrings[0].split(' ')[0]);
        setStartDateLib(moment(dateStrings[0].split(' ')[0],'DD-MM-YYYY').format('YYYY-MM-DD'));
        setEndDateLib(moment(dateStrings[1].split(' ')[0],'DD-MM-YYYY').format('YYYY-MM-DD'));
        fetchPerfomanceGraph()
        
      }else{
        togglePageLoader(true)
        setStartDate(dateStrings[0].split(' ')[0]);
        setEndDate(dateStrings[1].split(' ')[0]);
        barGraphPlotFunction(() =>togglePageLoader(false))
      }
    }
      

    return(
        
<div className="rangePicker" style={{width:'300px'}} >
        <Space  direction="vertical" mode={'month'}   size={12}  style={{width:'300px'}}>
        <RangePicker getPopupContainer={(trigger) => trigger.parentElement} value={[startDate !='' ? moment(startDate)  :moment().startOf('week')  , endDate != '' ? moment(endDate)  :moment().startOf('week').add(6, 'days') ]} dropdownClassName={'rangePickerB'} placeholder={['Start Date','End Date']} onChange={onChange}  renderExtraFooter={() => 'extra footer'} showTime format={'DD-MM-YYYY'} allowClear={false}/>
        
      </Space>

    </div>
  )
}


const mapStateToProps = (state) => {
 
  const { user,
    startDate,
    endDate } = state.digitalLibrary;
  return {
    startDate,
    endDate
  };
};
const mapDispatchToProps = dispatch => ({
  
  setStartDate:(m)=>dispatch(setStartDate(m)),
  setEndDate:(m)=>dispatch(setEndDate(m)),
  barGraphPlotFunction:(callback)=>dispatch(barGraphPlotFunction(callback)),
  setEndDateLib:(endDate)=>dispatch(setEndDateLib(endDate)),
  setStartDateLib:(startDate)=>dispatch(setStartDateLib(startDate)),
  fetchPerfomanceGraph:(callback)=>dispatch(fetchPerfomanceGraph(callback))


});


export default connect(mapStateToProps, mapDispatchToProps)(CalenderPopup);



