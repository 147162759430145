import React, { memo } from 'react';
import { Dropdown } from 'antd';
import { Information, SmallInfo, Title, Title2 } from 'components/Typography'
import filterIcon from "../../../../Assets/courseManagementV4/new-filter-icon.png"
import "./styles.css"
import { Api, Color } from 'services'

const RecentlyAddedHeader = memo(({ isFilterClicked, menu, setIsFilterClicked, selectedOptions }) => {
    return (
        <div className='r-jsb'>
            <Title>Recently added</Title>
            <Dropdown
                overlay={isFilterClicked ? menu : <></>}
                trigger={['click']}
                open={isFilterClicked}
                style={{ cursor: 'pointer' }}
            >
                <div
                    className='course-dropdownStyle r-c-sb'
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsFilterClicked(!isFilterClicked);
                    }}
                >
                    <Information color={Color.black}>
                        {selectedOptions === "course" ? "Courses" : selectedOptions === "student" ? "Students" : "All"}
                    </Information>
                    <img className='filter-icon-size' src={filterIcon} alt="Filter Icon" />
                </div>
            </Dropdown>
        </div>
    );
});

export default RecentlyAddedHeader;
