import { CopyOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Menu, Typography } from 'antd';
import newPublishIcon from "Assets/courseManagementV4/new-publish-icon.svg";
import newUnpublishIcon from "Assets/courseManagementV4/new-unpublish-icon.svg";
import { Information } from 'components/Typography';
import React, { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { OfferTagText, OfferTagTextColor, OfferTypeGraphColor } from '../constants/offers';
import '../styles.css';
const offerIconPublished = require('Assets/offers/offer-icon-published.svg').default;
const offerIconUnpublished = require('Assets/offers/offer-icon-unpublished.svg').default;
const offerIconPrivate = require('Assets/offers/offer-icon-private.svg').default;
const offerIconExpired = require('Assets/offers/offer-icon-expired.svg').default;
const offerIconBanner = require('Assets/offers/offer-icon-banner.svg').default;

function OffersCard({ offer, setSelectedItems, isSelected = false, setDataLoading, setMainSectionType, setSelectedOffer, setCopiedId, isCopied, cardRef,
    setIsOfferModalVisible, setModalActionType, setPreviousMainSectionType, selectedItems, setIsOfferDetailsModalVisible, setFilterVisible }) {
    const [isCardMenuOpen, setIsCardMenuOpen] = useState(false);
    const handleChecked = (e) => {
        e.stopPropagation();
        if (!isSelected) {
            const newItem = {
                id: offer.id,
                published: offer.published,
                type: offer.type,
                privateOffer: offer.privateOffer,
                expired: offer.expired,
            };
            setSelectedItems((selectedItems) => [...selectedItems, newItem])
        } else if (isSelected) {
            setSelectedItems((selectedItems) => selectedItems.filter((item) => item.id !== offer.id));
        }
    };
    const handleCopyCode = (e) => {
        e.stopPropagation();
        // eslint-disable-next-line
        navigator.clipboard.writeText(offer.code || '');
        setCopiedId(offer.id);
    };
    function handleSetOffer() {
        setSelectedOffer({
            ...offer,
        })
    }
    const menu = () => (
        <Menu onMouseLeave={()=>setIsCardMenuOpen(false)} style={{ width: "fit-content", background: "#FFFFFF", boxShadow: "-4px 4px 16px #00000029", borderRadius: "8px", padding: "10px", marginTop: 15 }}>
            <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); setIsCardMenuOpen(false); handleSetOffer(); setPreviousMainSectionType('gridList'); setMainSectionType('createEdit'); }}>
                <EditOutlined style={{ marginRight: 8, fontSize: 16 }} />EDIT
            </div>
            {!offer.privateOffer && !offer.expired ? (
                <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); setIsCardMenuOpen(false); handleSetOffer(); setIsOfferModalVisible(true); setModalActionType('publish') }}>
                    <img style={{ marginRight: 8, width: 15 }} src={!offer.published ? newPublishIcon : newUnpublishIcon} alt="publish-icon" />
                    <span>{!offer.published ? 'PUBLISH' : 'UNPUBLISH'}</span>
                </div>
            ) : null}
            <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); setIsCardMenuOpen(false); handleSetOffer(); setIsOfferModalVisible(true); setModalActionType('delete') }}>
                <DeleteOutlined style={{ marginRight: 8, fontSize: 16 }} />DELETE
            </div>
            {!offer.privateOffer && !offer.expired ? (
                <div className='r-ac m-t-5 m-b-5 cursor-pointer three-dots-dropdown' style={{ color: "#636363", padding: "5px 10px" }} onClick={(e) => { e.stopPropagation(); setFilterVisible(false); setIsCardMenuOpen(false); handleSetOffer(); setIsOfferModalVisible(true); setModalActionType('banner') }}>
                    {offer.banner ? (
                        <MinusCircleOutlined style={{ marginRight: 8, fontSize: 16 }} />
                    ) : (
                        <PlusCircleOutlined style={{ marginRight: 8, fontSize: 16 }} />
                    )}
                    <span>{!offer.banner ? 'ADD TO DISPLAY' : 'REMOVE FROM DISPLAY'}</span>
                </div>
            ) : null}
        </Menu>
    )

    let offerIcon = offerIconPublished;
    if (offer.expired) {
        offerIcon = offerIconExpired;
    } else if (offer.privateOffer) {
        offerIcon = offerIconPrivate;
    } else if (!offer.published) {
        offerIcon = offerIconUnpublished;
    } else if (offer.banner) {
        offerIcon = offerIconBanner;
    }

    let offerTagText = OfferTagText.published;
    let offerTagTextColor = OfferTagTextColor.published;
    let offerTagBackground = OfferTypeGraphColor.published;
    if (offer.expired) {
        offerTagText = OfferTagText.expired;
        offerTagTextColor = OfferTagTextColor.expired;
        offerTagBackground = OfferTypeGraphColor.expired;
    } else if (offer.privateOffer) {
        offerTagText = OfferTagText.privateOffer;
        offerTagTextColor = OfferTagTextColor.privateOffer;
        offerTagBackground = OfferTypeGraphColor.privateOffer;
    } else if (!offer.published) {
        offerTagText = OfferTagText.unpublished;
        offerTagTextColor = OfferTagTextColor.unpublished;
        offerTagBackground = OfferTypeGraphColor.unpublished;
    } else if (offer.banner) {
        offerTagText = OfferTagText.banner;
        offerTagTextColor = OfferTagTextColor.banner;
        offerTagBackground = OfferTypeGraphColor.banner;
    }
    return (
        <section className={`offer-card`} onMouseLeave={()=>{setIsCardMenuOpen(false)}} onClick={() => { handleSetOffer(); setIsOfferDetailsModalVisible(true) }} ref={cardRef || null} style={{ backgroundColor: isSelected ? "#DACFF2" : "#FFFFFF", boxShadow: isSelected ? "" : "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(255, 255, 255, 1)" }}>
            <article className={`offer-card-main${isSelected ? ' selected--card-main' : ''}`}>
                <section className='offer-checkbox' style={{ ...(isSelected ? { display: 'block' } : null) }}>
                    <Checkbox onClick={handleChecked} checked={isSelected} />
                </section>
                <img src={offerIcon} className='offer-icon'></img>
                <section className='offer-basic-info'>
                    <div className='offer-card-title'>
                        <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true, color: "#636363" }} style={{ marginBottom: 0, lineHeight: 1.2 }}><Information>{offer.title}</Information></Typography.Paragraph>
                    </div>
                    <div className='r-c-fs' style={{ alignItems: 'baseline' }}>
                        <div className='r-c-fs' style={{ alignItems: 'baseline' }}>
                            <Typography.Text ellipsis={{ tooltip: true }} >
                                <Information style={{ fontWeight: 700, color: "#636363" }}>{offer.discountType === 'amount' ? `₹${offer.discount}` : `${offer.discount}%`}</Information>
                            </Typography.Text>
                            <Information>&nbsp;off</Information>
                        </div>
                        {offer.code
                            ? <>
                                <Information style={{ margin: "0 0.5rem" }}>|</Information>
                                <Typography.Paragraph style={{ marginBottom: '0', color: "#636363" }} ellipsis={{ rows: 1, tooltip: true }}><Information>Promo code:</Information><Information style={{ fontWeight: 700, color: "#636363", marginRight: 5 }}>{` ${offer.code}`}</Information></Typography.Paragraph>
                                {isCopied
                                    ? <Information style={{ fontSize: 13 }} className={`copy-code-copied`}> Copied!</Information>
                                    : <CopyOutlined className={`copy-code`} onClick={handleCopyCode} />
                                }
                            </>
                            : null
                        }
                    </div>
                </section>
                <div className='offer-card-action'>
                    {isCardMenuOpen && !selectedItems?.length ?
                        <Dropdown overlay={menu} placement="left" disabled={selectedItems?.length} open={isCardMenuOpen} destroyPopupOnHide={true} onOpenChange={()=>{if(!selectedItems?.length){setIsCardMenuOpen(true)}}}>
                            <MdMoreVert onClick={(e) => e.stopPropagation()} style={{ minWidth: "1.3rem", minHeight: "1rem", fontSize: 22, marginRight: -10, ...(selectedItems?.length ? { cursor: 'not-allowed' } : null) }}></MdMoreVert>
                        </Dropdown> : <MdMoreVert onMouseEnter={() => setIsCardMenuOpen(true)} onClick={(e) => e.stopPropagation()} style={{ minWidth: "1.3rem", minHeight: "1rem", fontSize: 22, marginRight: -10, ...(selectedItems?.length ? { cursor: 'not-allowed' } : null) }}></MdMoreVert>
                    }
                </div>
            </article>
            <section className='offer-type-tag' style={{ background: offerTagBackground }}>
                <Information color={offerTagTextColor}>{offerTagText}</Information>
            </section>
        </section >
    );
}

export default OffersCard;