import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import LeaveModal from './Modals/leaveModal'
import { useHistory } from "react-router";
import CreateLeaveModal from "./Modals/createLeaveModal";



const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 40px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,

};

const images = require("../../Assets/settingss/ill-leave-holidays.svg").default;
const image1 = require("../../Assets/settingss/PlusCircle.svg").default;



function LeavesCard({ setBackToPrivilegedView }) {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const history = useHistory()

  const PageHeader = () => {
    return (
      <div className="resultPageHeader full-width">
        <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined
            onClick={() => { history.goBack(); setBackToPrivilegedView(true) }}
            style={{ fontSize: 18 }}
            className="cursor-pointer"
          />
          <div className="text-md bold-700 m-l-20">
            <div>{"Leaves"}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={bodyStyle}>
      <PageHeader />
      <div
        style={{
          width: "95%",
          alignItems: "center",
          border: "#E6E6E6 solid 1px",
          borderRadius: "4px",
          padding: "10px",
          paddingTop: "10px",
          height: "83vh",
          marginLeft: "10px",
          borderColor: "#E6E6E6",
          backgroundColor: "#FFFFFF",
          marginTop: "20px",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8%",
          }}
        >
          <div style={{ marginLeft: "100px" }}>
            <img style={{ width: "68%", }} src={images} />
          </div>
          <div
            style={{
              fontSize: "21px",
              fontWeight: "bold",
              color: "#191919",
              fontFamily: "Roboto"

            }}
          >
            No leave(s) created yet!
          </div>
          <div style={{ fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>
            There are no leaves created yet
          </div>
          <div style={{ marginTop: "15px" }}
          >
            <Button
              style={{
                borderRadius: "19px",
                width: "150px",
                height: "38px",
                fontWeight: "500",
                border: "1px solid #594099 ",
                backgroundColor: "#FFFFFF",
              }}
              onClick={() => { setIsCreateModalVisible(true); console.log("clicked", isCreateModalVisible) }}
            >
              <div style={{ display: "flex", }}>
                <div style={{ marginBottom: "8px" }}>
                  <img src={image1} />
                </div>

                <div
                  style={{ fontSize: "13px", color: "#594099", fontWeight: "bold", marginLeft: "8px", marginTop: "2px" }}
                >
                  CREATE LEAVE
                </div>
              </div>
            </Button>

          </div>
          <div>

          </div>

        </div>
      </div>
      {console.log("modal", isCreateModalVisible)}
      {isCreateModalVisible &&
        <CreateLeaveModal
          isCreateModalVisible={isCreateModalVisible}
          setIsCreateModalVisible={setIsCreateModalVisible}
        />
      }
    </div>
  );
}


export default LeavesCard;
