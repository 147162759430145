import { searchFilter } from 'actions/testAdmin';
import { Button, Modal } from 'antd';
import React, { useState } from 'react'
import { connect } from 'react-redux';

const ConfirmDeleteModal = ({isDeleteModalVisible, setIsDeleteModalVisible, setIsEditModalVisisble, deleteMarkingScheme, togglePageLoader, getMarkingSchemeData, deleteChapter, totalQuesChapterData, DeleteChapterInTest,removeSelectedChapter, deletingChapterId, searchFilter }) => {
    const [deleteLoader , setDeleteLoader] = useState(false)

    const handleOk = () => {
       setIsDeleteModalVisible(false);
       if(!deleteChapter){
        setIsEditModalVisisble(true)
       }
   };

   const handleCancel = () => {
       setIsDeleteModalVisible(false);
   };
    
   return (
     <>
       <Modal
         className="modal-round-corner"
         centered
         visible={isDeleteModalVisible}
         onOk={handleOk}
         onCancel={handleOk}
         header={null}
         footer={null}
       >
         <div
           style={{
             display: "flex",
             flexDirection: "column",
             alignItems: "center",
             marginTop: "25px",
           }}
         >
           <div
             style={{ fontSize: "24px", color: "#191919", fontWeight: "bold" }}
           >
             Confirm
           </div>
           <div
             style={{
               fontSize: "16px",
               color: "#636363",
               fontWeight: "bold",
               marginTop: "10px",
               textAlign: 'center'
             }}
           >
             {deleteChapter ? ` This chapter contains ${totalQuesChapterData?.questionCountInChapter} question(s). Do you still want to delete this chapter?` : "Are you sure you want to delete?"}
           </div>
         </div>
         <div
           style={{
             display: "flex",
             justifyContent: "space-evenly",
             marginTop: "30px",
           }}
         >
           <div>
             <Button
               style={{
                 borderRadius: "19px",
                 width: "120px",
                 height: "35px",
                 border: "1px solid  #636363",
                 backgroundColor: "#FFFFFF",
                 color: "#594099",
               }}
             >
               <div
                 style={{
                   fontSize: "16px",
                   color: "#636363",
                   fontWeight: "bold",
                 }}
                 onClick={handleOk}
               >
                 NO
               </div>
             </Button>
           </div>
           <div>
             <Button
               loading={deleteLoader}
               style={{
                 borderRadius: "19px",
                 width: "120px",
                 height: "35px",
                 border: "1px solid #594099 ",
                 backgroundColor: "#594099",
                 fontSize: "16px",
                 color: "#FFFFFF",
                 fontWeight: "bold",
               }}
               onClick={() => {
                 setDeleteLoader(true);
                 if(deleteChapter){
                  DeleteChapterInTest(
                    ()=>{
                      setDeleteLoader(false);
                      setIsDeleteModalVisible(false);
                      removeSelectedChapter(deletingChapterId)
                  })
                 }else{
                  deleteMarkingScheme(
                    () => {
                      setDeleteLoader(false);
                      setIsDeleteModalVisible(false);
                    },
                    () => {
                      searchFilter('')
                      togglePageLoader(true);
                      getMarkingSchemeData("", () => togglePageLoader(false))
                    }
                  )
                 }
               }}
             >
               YES
             </Button>
           </div>
         </div>
       </Modal>
     </>
   );
 };

 const mapStateToProps = (state) => {
  const { } = state.testAdmin;
  return{}
}

const mapDispatchToProps = (dispatch) => ({
  searchFilter:(val)=>(dispatch(searchFilter(val))),
})

export default connect(mapStateToProps,mapDispatchToProps)(ConfirmDeleteModal)

// export default ConfirmDeleteModal