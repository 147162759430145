import { Button, Col, Menu, Popover, Row, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { SubIcon } from 'components/subIcon';
import feeIcon from '../../../Assets/dashBoard/ic-payment-request.svg'
import moment from 'moment';
import { RiWirelessChargingLine } from 'react-icons/ri';
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io';
import { MdCastConnected } from 'react-icons/md';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NodataCard } from 'pages/attendanceStudent/myAttendanceStudent';
import PaymentReqDetailsModal from 'pages/studentAulasWallet/modal/paymentReqDetailsModal';
import { connect } from 'react-redux';
import { setStreamId } from 'actions/broadcast';
import { joinClass, setBroadcastSelectedClassroom } from 'actions/schedule';
import { useHistory } from 'react-router';
import { Api, Notification } from 'services'
import { setSelectedTest } from 'actions/studentTest';
import clockIcon from '../../../Assets/dashBoard/ic-clock.svg'




const ScheduleOngoingCard = ({ activityStatus, amount, dueDate, status, endTime, subjectName, SubjectIcon, classMode, TestTitle, testEndTime, testStartTime, item, storePaymentReqModalData, TestsubjectNames, TestType, TestDuration, TestTotalQuestions, TestTotalPoints, setStreamId, roomName, setBroadcastSelectedClassroom, joinClass, setSelectedTest, testprogress, teacherDetail }) => {
    const history = useHistory();

    const [isPaymentDetailsVisible, setPaymentDetailsVisible] = useState(false);
    const [isEmailIdModalVisible, setIsEmailIdModalVisible] = useState(false);

    const [remainingTime, setRemainingTime] = useState('');
    const startTime = moment.utc().format('h:mm:ss a');
    const remainingDaysForDuaDate = moment(dueDate, 'DD-MM-YYYY').add(1, 'days').diff(moment(), 'days');
    // class start time 
    const [nowTime, setNowTime] = useState(moment());

    const [classStremainingHours, setClassStremainingHours] = useState(0);
    const [classStremainingMinutes, setClassStremainingMinutes] = useState(0);

    const calculateClassRemainingTime = () => {
        const nowTime = moment(); // Get the current time only when needed
        const ClassStremainingTime = moment.utc(endTime, 'HH:mm:ss').diff(moment.utc(nowTime.format('HH:mm:ss'), 'HH:mm:ss'));
        const classStremainingHours = Math.floor(ClassStremainingTime / (1000 * 60 * 60));
        const classStremainingMinutes = Math.floor((ClassStremainingTime % (1000 * 60 * 60)) / (1000 * 60));
        return { classStremainingHours, classStremainingMinutes };
    };

    const updateRemainingTime = () => {
        const { classStremainingHours, classStremainingMinutes } = calculateClassRemainingTime();
        setClassStremainingHours(classStremainingHours);
        setClassStremainingMinutes(classStremainingMinutes);
    };

    useEffect(() => {
        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const formatTime = (time) => moment.utc(time, 'HH:mm:ss').format('h:mm A');


    // test starts in logic
    const TestremainingTime = (moment.utc(testEndTime, 'h:mm:ss a') - moment.utc(testStartTime, 'h:mm:ss a'));
    const TestremainingHours = Math.floor(TestremainingTime / (1000 * 60 * 60));
    const TestremainingMinutes = Math.floor((TestremainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const [upcomingTestRemainingHours, setUpcomingRemainingHours] = useState(0);
    const [upcomingTestRemainingMinutes, setUpcomingRemainingMinutes] = useState(0);


    const handleJoin = async () => {
        if (classMode == "Broadcast") {
            let currentTime = new Date();
            if (endTime){
                // Parse end time
                const [hours, minutes, seconds] = endTime.split(':').map(Number);
                let endDateTime = new Date();
                endDateTime.setHours(hours, minutes, 60, 0);

                // Calculate the difference in milliseconds
                let differenceInMilliseconds = endDateTime - currentTime;
                let differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
                if (differenceInSeconds<0){
                    Notification.error("Error", "Class end time has passed")
                    return
                }
            }
            setStreamId(roomName != null ? roomName : '1234')
            setBroadcastSelectedClassroom(item)
            await joinClass(item.id)
            history.push('/broadcast')
        }
        else if (classMode == "Zoom 40") {
            await joinClass(item.id)
            Notification.error("Error", "Zoom is not supported on web presently. Please join the class from the mobile.")
        }
        else if ((classMode == "Conference") || (classMode == "Offline")) {
            Notification.error("Error", "This mode is not supported on web presently")
        }
    }
    useEffect(() => {
        // Calculate the remaining time until the test start
        const remainingTime = moment(testStartTime, 'h:mm ss a').diff(nowTime);
        if (remainingTime < 0) {
            setUpcomingRemainingHours(0);
            setUpcomingRemainingMinutes(0);
        } else {
            const remainingDuration = moment.duration(remainingTime);
            const TestStartInHr = remainingDuration.hours();
            const TestStartInMin = remainingDuration.minutes();
            setUpcomingRemainingHours(TestStartInHr);
            setUpcomingRemainingMinutes(TestStartInMin);
        }
    }, [testStartTime, nowTime]);

    // Function to update the remaining time
    useEffect(() => {
        const updateRemainingTime = () => {
            const remainingTime = moment.utc(testEndTime, 'h:mm:ss a').diff(moment.utc(testStartTime, 'h:mm:ss a'));
            const remainingDuration = moment.duration(remainingTime);
            const remainingHours = remainingDuration.hours();
            const remainingMinutes = remainingDuration.minutes();
            const formattedTime = `Remaining time: ${remainingHours} hrs ${remainingMinutes} min`;
            setRemainingTime(formattedTime);
        };
        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    const menu = (
        <div onClick={(e) => { e.stopPropagation() }}>
            {activityStatus == 'Test' ?
                (
                    <>
                        <div style={{ fontSize: "11px", fontWeight: "bold", }}>{TestsubjectNames}</div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ fontSize: "10px", fontWeight: "bold", color: "#AEAEAE" }}>{TestType}</div>
                            <div style={{ fontSize: "10px", fontWeight: "bold", color: "#AEAEAE" }}>{" "}•{" "}{TestremainingHours}{" "}hr {" "}{TestremainingMinutes}{" "}mins</div>
                            <div style={{ fontSize: "10px", fontWeight: "bold", color: "#AEAEAE" }}>{" "}•{""}{TestTotalPoints}Marks </div>
                            <div style={{ fontSize: "10px", fontWeight: "bold", color: "#AEAEAE" }}>{" "}•{""}{TestTotalQuestions}Qs</div>
                        </div>
                    </>
                )

                :
                activityStatus == 'Schedule' ?
                    <div style={{ width: "100%", }}>
                        {classMode == 'Offline' ?
                            (<>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <div>
                                        <SubIcon size={10} icon={teacherDetail?.image} name={teacherDetail?.name} />
                                    </div>
                                    <div style={{ marginLeft: "5px", fontSize: "11px", color: "#636363", marginTop: "3px" }}>{teacherDetail?.name}</div>
                                    <div style={{ marginLeft: "5px", fontSize: "11px", color: "#636363", marginTop: "3px" }}><img src={clockIcon} /><h style={{ fontSize: "11px", color: "#636363", marginLeft: "5px" }}>{moment.utc(item?.startTime, 'hh:mm:ss').format('hh:mm a')} - {moment(endTime, 'hh:mm:ss').format("hh:mm a")}</h></div>

                                </div>

                            </>) : (<>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <div>
                                        <SubIcon size={10} icon={teacherDetail?.image} name={teacherDetail?.name} />
                                    </div>
                                    <div style={{ marginLeft: "5px", fontSize: "11px", color: "#636363", marginTop: "3px" }}>{teacherDetail?.name}</div>
                                    <div style={{ marginLeft: "5px", fontSize: "11px", color: "#636363", marginTop: "3px" }}><img src={clockIcon} /><h style={{ fontSize: "11px", color: "#636363", marginLeft: "5px" }}>{moment.utc(item?.startTime, 'hh:mm:ss').format('hh:mm a')} - {moment(endTime, 'hh:mm:ss').format("hh:mm a")}</h></div>

                                </div></>)

                        }
                    </div> :
                    activityStatus == 'Transactions' ?
                        (
                            <>
                                <div style={{ fontSize: "11px", fontWeight: "bold", }}>₹{" "}{" "}{amount}{" "}{" "}</div>
                                <div style={{ fontSize: "10px", fontWeight: "bold", color: "#AEAEAE" }}>{dueDate}</div>

                            </>
                        ) : null}
        </div>
    );

    return (
        <div>
            <div style={{ padding: "2px", marginTop: "15px", }}>
                {activityStatus == 'Schedule' ?
                    (<>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    <SubIcon size={40} icon={SubjectIcon} name={subjectName} />
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                    <div>
                                        {status == 'Scheduled' ?
                                            (<>
                                                <div style={{ marginTop: "-10px" }}>
                                                    <Tag style={{
                                                        color: `${classMode === 'Conference' ? ' #594099' :
                                                            classMode === 'Zoom 40' ? ' #1089FF' : classMode === 'Broadcast' ? '#FFA931' : classMode === 'Offline' ? ' #28DF99' : null}`,
                                                        backgroundColor: `${classMode === 'Conference' ? ' #F6F4FE' :
                                                            classMode === 'Zoom 40' ? ' #E7F3FF' : classMode === 'Broadcast' ? '#FFF4D5' : classMode === 'Offline' ? ' #E9FCF5' : null}`,
                                                        borderColor: `${classMode === 'Conference' ? ' #F6F4FE' :
                                                            classMode === 'Zoom 40' ? ' #E7F3FF' : classMode === 'Broadcast' ? '#FFF4D5' : classMode === 'Offline' ? ' #E9FCF5' : null}`,
                                                        fontSize: "8px",
                                                        fontWeight: "bold",

                                                    }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div>{classMode === 'Conference' ? <RiWirelessChargingLine style={{ color: "#594099" }} /> :
                                                                classMode === 'Zoom 40' ? <IoMdVideocam /> : classMode === 'Broadcast' ? <MdCastConnected style={{ color: "#FFA931" }} /> : classMode === 'Offline' ? <IoMdChatbubbles style={{ color: "#28DF99" }} /> : null}
                                                            </div>
                                                            <div style={{ marginLeft: "3px", marginTop: "-1px" }}>{classMode === 'Conference' ? 'CONFERENCE' :
                                                                classMode === 'Zoom 40' ? 'ZOOM 40' : classMode === 'Broadcast' ? 'BROADCAST' : classMode === 'Offline' ? 'OFFLINE' : null}</div>
                                                        </div>
                                                    </Tag>
                                                </div>
                                            </>) :
                                            <div style={{ display: "flex", marginTop: "-10px" }}>
                                                <div style={{ fontSize: "19px", color: "red", fontWeight: "bold" }}>•</div>
                                                <div style={{ fontSize: "9px", color: "red", fontWeight: "bold", marginLeft: "5px", marginTop: "10px" }}>LIVE</div>
                                            </div>}
                                    </div>
                                    <div>
                                        <div style={{ fontSize: "12px", fontWeight: "bold", marginTop: "-5px", marginTop: "3px" }}>{subjectName}{" "}{" "}

                                        </div>
                                        <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px" }}>starting in  {classStremainingHours} hr {classStremainingMinutes} mins
                                            <Popover content={menu}>
                                                <InfoCircleOutlined style={{ color: "#1089FF", fontSize: '9px', fontWeight: "bold", marginLeft: '5px', marginTop: "2px" }} />
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Button
                                disabled={classMode == 'Offline'}
                                style={{
                                    borderRadius: "19px",
                                    width: "120px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: (classMode == 'Broadcast' || classMode == 'Conference' || classMode == 'Conference' || classMode == 'Zoom 40' || classMode != 'Offline') ? "#594099" : "#AEAEAE",
                                    color: "#FFFFFF",
                                    fontSize: "11px",
                                    opacity: (classMode == 'Offline') ? 1 : 0.5,
                                }}
                                onClick={() => {
                                    if (status != 'Scheduled') {
                                        handleJoin()
                                    }
                                }}
                            >
                                {classMode == 'Broadcast' || classMode == 'Conference' || classMode != 'Offline' || classMode == 'Conference' || classMode == 'Zoom 40' ? 'JOIN NOW' : 'OFFLINE CLASS'}</Button>
                        </div>
                    </>) : null}
            </div>
            <div style={{ marginTop: "10px" }}>
                {activityStatus == 'Transactions' ?
                    (<>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    <img width={40} src={feeIcon} />
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                    <div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <div style={{ marginTop: "5px" }}>
                                                <div style={{ fontSize: "12px", fontWeight: "bold", marginTop: "-5px" }}>₹{" "}{" "}{amount}{" "}{" "}</div>
                                                {remainingDaysForDuaDate < 0 ?
                                                    <div>
                                                        <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px", fontWeight: "bold" }}>Due on {" "}{" "}{dueDate}
                                                            <Popover content={menu}  >
                                                                <InfoCircleOutlined style={{ color: "#1089FF", fontSize: '9px', fontWeight: "bold", marginLeft: '5px', marginTop: "2px" }} />
                                                            </Popover></div>
                                                        <div style={{ color: "red", fontWeight: "bold", fontSize: "9px" }}>OVERDUE</div>
                                                    </div>
                                                    :
                                                    (<>
                                                        {dueDate != null ?
                                                            <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px", fontWeight: "bold" }}>
                                                                Due{" "}{" "}{remainingDaysForDuaDate == 0 ? `on ${dueDate}` : ` in${remainingDaysForDuaDate} day(s)`} {" "}
                                                            </div> : null}
                                                    </>)
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button
                                style={{
                                    borderRadius: "19px",
                                    width: "90px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",
                                    fontSize: "11px",
                                }}
                                onClick={() => { storePaymentReqModalData(item), setPaymentDetailsVisible(true) }}
                            >
                                PAY NOW</Button>
                        </div>
                    </>) : null}
            </div>
            {activityStatus == 'Test' ?
                (<>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-10px" }}>
                        <div style={{ display: "flex" }}>
                            <div>
                                <SubIcon size={40} icon={SubjectIcon} name={TestTitle} />
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                                <div>
                                    {status == "Ongoing" ?
                                        (<>
                                            <div style={{ display: "flex", marginTop: "-10px" }}>
                                                <div style={{ fontSize: "19px", color: "red", fontWeight: "bold" }}>•</div>
                                                <div style={{ fontSize: "9px", color: "red", fontWeight: "bold", marginLeft: "5px", marginTop: "10px" }}>LIVE</div>
                                            </div>
                                        </>)
                                        : null}
                                </div>
                                <div>
                                    <Typography.Text ellipsis={{ tooltip: true }}>
                                        <div style={{ width: "205px", fontFamily: 'roboto', fontSize: '12px', fontWeight: 'bold', textOverflow: 'ellipsis' }}>{TestTitle}{" "}{" "} </div>
                                    </Typography.Text>
                                    <div style={{ fontSize: "9px", color: "#AEAEAE", marginTop: "3px" }}>
                                        {status === 'Upcoming' ? (
                                            <>Starts in {upcomingTestRemainingHours}{""}{" "}hr {" "}{upcomingTestRemainingMinutes}{" "}{" "}mins</>
                                        ) : (
                                            <>Ending in {TestremainingHours}{""}{" "}hr {" "}{TestremainingMinutes}{" "}{" "}mins</>
                                        )}
                                        <Popover content={menu}  >
                                            <InfoCircleOutlined style={{ color: "#1089FF", fontSize: '9px', fontWeight: "bold", marginLeft: '5px', marginTop: "2px" }} />
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {console.log("item123",item)}
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "90px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#594099",
                                color: "#FFFFFF",
                                fontSize: "11px",
                                opacity: (status == 'Upcoming' || testprogress == 'SUBMITTED') ? 0.5 : 1,

                            }}
                            onClick={() => {

                                if (status != 'Upcoming') {
                                    if (testprogress != 'SUBMITTED') {
                                        setSelectedTest(item);
                                        history.push("/student-test/questions");
                                    }

                                }
                            }
                            }

                        >
                            START TEST</Button>
                    </div>
                </>
                ) : null}
            <PaymentReqDetailsModal
                isPaymentDetailsVisible={isPaymentDetailsVisible}
                setPaymentDetailsVisible={setPaymentDetailsVisible}
                setIsEmailIdModalVisible={setIsEmailIdModalVisible}

                paymentReqModalData={item}
            />
        </div>
    )
}

const ActivityBoardCard = ({ data, storePaymentReqModalData, setStreamId, setSelectedTest }) => {

    return (
        <div>
            {data?.Activities?.length != 0 ? (<>
                {data?.Activities?.map((item) => (
                    <>
                        <ScheduleOngoingCard
                            item={item}
                            activityStatus={item?.ActivityType}
                            amount={item?.amountToShow}
                            dueDate={item?.dueDate}
                            status={item?.status}
                            endTime={item?.endTime}
                            subjectName={item?.subject?.name}
                            SubjectIcon={item?.subject?.icon}
                            classMode={item?.mode}
                            testStartTime={item?.startTime}
                            testEndTime={item?.endTime}
                            TestTitle={item?.title}
                            TestsubjectNames={item?.subjectNames}
                            TestType={item?.testType}
                            TestDuration={item?.duration}
                            TestTotalQuestions={item?.totalQuestions}
                            TestTotalPoints={item?.totalPoints}
                            storePaymentReqModalData={storePaymentReqModalData}
                            setStreamId={setStreamId}
                            roomName={item?.roomName}
                            setBroadcastSelectedClassroom={setBroadcastSelectedClassroom}
                            joinClass={joinClass}
                            setSelectedTest={setSelectedTest}
                            testprogress={item?.testProgress}
                            teacherDetail={item?.teacher}


                        />
                        <div style={{ borderBottom: "1px solid #E6E6E6", marginLeft: "20px", marginRight: "20px", marginTop: "15px" }}></div>
                    </>
                ))}
            </>) : (<div style={{ marginTop: "40px" }}><NodataCard /></div>)}

        </div>
    )

}

const mapStateToProps = (state) => {
    const { dashBoardOverviewData, storeInfoBoardData } = state.dashBoard
    const { publishedTests, } = state.studentTest;
    return { dashBoardOverviewData, storeInfoBoardData, publishedTests };
};

const mapDispatchToProps = (dispatch) => ({
    setStreamId: (streamId) => dispatch(setStreamId(streamId)),
    setBroadcastSelectedClassroom: (newClass) => dispatch(setBroadcastSelectedClassroom(newClass)),
    joinClass: (id) => dispatch(joinClass(id)),
    setSelectedTest: (data) => dispatch(setSelectedTest(data)),




});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityBoardCard);




