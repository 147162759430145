import { setInvoiceFile } from "actions/feeManagement";
import { Button, Modal, Typography } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

function ViewTransactionDetailsModal({
  isModalVisible,
  item,
  setShowTansDtls,
  setInvoiceFile,
}) {
  const history = useHistory();
  
  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ width: "100%" }}
    >
      <Modal
        maskClosable={false}
        className="viewTransactionModalStyle"
        // title="Basic Modal"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={() => setShowTansDtls(false)}
      >
        <div style={{ fontFamily: "roboto", width: "100%" }}>
          <div
            style={{
              fontWeight: "750",
              fontSize: "20px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Transaction Details
          </div>
          <div
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "600" }}
          >
            Details
          </div>

          {item?.payment_type == "online" && item?.status == "Paid" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "500px",
              }}
            >
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Amount
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  ₹ {item?.amount}
                </div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Payment date
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {item?.paymentDate}
                </div>
              </div>
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Issue date
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {moment(item?.date, "DD-MM-YYYY").format("DD/MM/YYYY")}
                </div>
                {item?.status == "Paid" ? (
                  <div>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Invoice number
                    </p>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      {item.paymentId}
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Due date
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {moment(item?.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                </div>
                {item?.payment_type == "online" && item?.status == "Paid" ? (
                  <div>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Invoice
                    </p>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "#1089FF",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setInvoiceFile(item?.invoice);
                        history.push("/fee-management/invoice");
                      }}
                    >
                      VIEW INVOICE
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "500px",
              }}
            >
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Amount
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  ₹
                  {parseFloat(
                    parseFloat(item?.amount) +
                      item?.total_discount -
                      item?.total_addition
                  ).toFixed(2)  && numberWithCommas(parseFloat(
                    parseFloat(item?.amount) +
                      item?.total_discount -
                      item?.total_addition
                  ).toFixed(2))}
                  {/* {item?.amount} */}
                </div>
                <div>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Description
                  </p>
                  <div
                    style={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      style={{ width: "70px" }}
                    >
                      {item?.note ? item?.note : "NA"}
                    </Typography.Text>
                  </div>
                </div>
              </div>
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Payment date
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {item?.paymentDate ? moment(item?.paymentDate, 'DD-MM-YYYY').format('DD-MM-YYYY') : "00/00/0000"}
                  {/* {moment(item?.date, "DD-MM-YYYY").format("DD/MM/YYYY")} */}
                </div>
                {item?.payment_method == "Cash" ? null : (
                  <div>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {item?.payment_method == "Cheque"
                        ? "Cheque number"
                        : "Transaction ID"}
                    </p>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      {/* {item?.paymentId} */}
                      {item?.payment_method == "Cheque"
                        ? item?.cheque_no
                        : item?.cheque_no}
                    </div>
                  </div>
                )}
              </div>
              <div>
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#636363",
                    marginBottom: "7px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  Mode of payment
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {/* {moment(item?.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")} */}
                  {item?.payment_method}
                </div>
                {item?.payment_type == "online" && item?.status == "Paid" ? (
                  <div>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      Invoice
                    </p>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "#1089FF",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setInvoiceFile(item?.invoice);
                        history.push("/fee-management/invoice");
                      }}
                    >
                      VIEW INVOICE
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "500px",
            }}
          ></div>
          {item?.payment_type == "online" && item?.status == "Paid" ? (
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Description
              </p>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                {/* {item?.note ? item?.note : "NA"} */}
                <Typography.Text
                  ellipsis={{ tooltip: true }}
                  style={{ width: "70px" }}
                >
                  {item?.note ? item?.note : "NA"}
                </Typography.Text>
              </div>
            </div>
          ) : null}
          <p
            style={{
              marginBottom: "0px",
              color: "#636363",
              marginBottom: "7px",
              fontWeight: "bold",
              fontSize: "12px",
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            Amount details
          </p>
          <div
            style={{
              border: "1px solid #E6E6E6",
              padding: "15px",
              marginLeft: "10px",
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                fontWeight: "700",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Fee amount</div>
              <div>
                ₹{" "}
                {parseFloat(
                  parseFloat(item?.amount) +
                    item?.total_discount -
                    item?.total_addition
                ).toFixed(2)  && numberWithCommas(parseFloat(
                  parseFloat(item?.amount) +
                    item?.total_discount -
                    item?.total_addition
                ).toFixed(2))}
                {/* {item?.amount} */}
              </div>
            </div>
            <div
              style={{
                fontWeight: "700",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "8px",
                color: "#1089FF",
              }}
            >
              <div>Discount</div>
              <div>-₹ {item?.total_discount ? item?.total_discount : 0 && numberWithCommas(item?.total_discount ? item?.total_discount : 0)}</div>
            </div>
            <div
              style={{
                fontWeight: "700",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#FFA931",
                marginTop: "8px",
              }}
            >
              <div>Addition</div>
              <div>+₹ {item?.total_addition ? item?.total_addition : 0}</div>
            </div>
            {item?.gstApplicable ? (
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "40px",
                  color: "grey",
                }}
              >
                <div>GST</div>
                <div
                // onClick={() => {
                //   setIsAdditionModal(true);
                //   setShowAddDetailsModal(true);
                //   setIsModalVisible(false);
                // }}
                // style={{ cursor: "pointer" }}
                >
                  +₹ {item?.gst ? parseFloat(item?.gst).toFixed(2) : 0}
                </div>
              </div>
            ) : null}
            <div
              style={{
                fontWeight: "700",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: "1px solid #E6E6E6",
                borderBottom: "1px solid #E6E6E6",
                paddingTop: "3px",
                paddingBottom: "3px",
                marginTop: "8px",
              }}
            >
              <div>Total payable</div>
              <div>₹ {item?.amountPayable && numberWithCommas(item?.amountPayable)}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              setShowTansDtls(false);
              // console.log("clocked", isModalVisible);
            }}
            style={{
              width: "130px",
              color: "#636363",
              border: "1px solid #636363",
              fontWeight: "700",
            }}
          >
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  const {} = state.feeManagement;
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setInvoiceFile: (invoiceFile) => dispatch(setInvoiceFile(invoiceFile)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTransactionDetailsModal);
// export default ViewTransactionDetailsModal;
