import { Button, Col, DatePicker, Input, Modal, Row, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { getAttendanceProfileTabs, markAttendance, setLoader, setMarkAttendance, selectedAttendance, usersDownloadReport, storeSelectedRange, storeStartDate, storeEndDate, getAttendanceDashBoardData } from 'actions/attendanceNew'
import { ConsoleSqlOutlined } from '@ant-design/icons'

// const istrue=true
const RequestAttendanceModel = ({ isModalVisible, setIsModalVisible, setMarkAttendance, markAttendance, setLoader, loaders, user, disabledDate, markAttendanceObj, fromWhere, getAttendanceProfileTabs,getAttendanceDashBoardData }) => {

    const [diffT, setDiff] = useState(0)

    const handleOk = () => {
        setIsModalVisible(false);
        
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setMarkAttendance('startDate', '')

    };

    const handleRequest = () => {
        if(fromWhere == 'Profile'){
            markAttendance(() => { setIsModalVisible(false)}, () => {getAttendanceProfileTabs(),getAttendanceDashBoardData(() => {})})
        } else{
            markAttendance(() => { setIsModalVisible(false)}, () => {getAttendanceDashBoardData(() => {})})
        }
    
    }

    useEffect(() => {
        setLoader('modalClockLoader', false)
        setMarkAttendance('requestType', 'Attendance')
    }, [])
    
    const calcDiff = () => {
        console.log('total')
        let clockIn = moment(markAttendanceObj?.clockIn, 'HH:mm a');
        let clockOut = moment(markAttendanceObj?.clockOut, 'HH:mm a');
        let totalHours = clockOut.diff(clockIn, 'minutes')
        let a = totalHours;
        if (a===a) {
            console.log("total hours2", totalHours);
            totalHours = parseFloat(totalHours/60).toFixed(2);
            setMarkAttendance('totalhours',totalHours)
            setDiff(totalHours)
        }

    }

    useEffect(() => {
        calcDiff()
    }, [markAttendanceObj?.clockIn, markAttendanceObj?.clockOut])

    function disabledDate(current) {
        return current && (current < moment().add(-4, "days") || current > moment().add(-1, "days"))
    }

    const timerDisable = (dependency) => {
        for (let x of dependency){
          let bool = markAttendanceObj[x] === ''||markAttendanceObj[x]==='Invalid date'||markAttendanceObj[x]==undefined
          if (bool) return true
        }
        return false
      }

    const disabledStartTimeHours = () => {

        const hours = []
        if (moment().format('YYYY-MM-DD') === markAttendanceObj?.startDate) {
            for (var i = 25; i > moment().hour(); i--) {
                hours.push(i)
            }
        }
        return hours
    }

    const disabledStartTimeMinutes = (selectedHour) => {
        // console.log('first', selectedHour)
        var minutes = []
        if (selectedHour === moment().hour() && moment().format('YYYY-MM-DD') === markAttendanceObj?.startDate) {
            for (var i = 60; i >= moment().minute(); i--) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const disabledEndTimeHours = () => {

        var hours = []
        for (var i = 0; i < moment(markAttendanceObj?.clockIn, 'HH:mm:ss').hour(); i++) {
            hours.push(i)
        }

        if (moment().format('YYYY-MM-DD') === markAttendanceObj?.startDate) {
            for (var i = 25; i > moment().hour(); i--) {
                hours.push(i)
            }
        }
        return hours
    }

    const disabledEndTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment(markAttendanceObj?.startDate, 'HH:mm:ss').hour()){
            for (var i = 0; i <= moment(markAttendanceObj?.startDate, 'HH:mm:ss').minute(); i++) {
                minutes.push(i);
            }
        }

        if (selectedHour === moment().hour() && moment().format('YYYY-MM-DD') === markAttendanceObj?.startDate) {
            for (var i = 60; i > moment().minute(); i--) {
                minutes.push(i);
            }
        }
        return minutes;
    }


    useEffect(() => {
        setMarkAttendance('startDate', null)
        setMarkAttendance('clockIn', null)
        setMarkAttendance('clockOut', null)
        setMarkAttendance('reason', null)
        setMarkAttendance('totalhours', null)
        setDiff('')

    }, [])
   

    return (
        <>
            <Modal
                centered
                className="modal-round-corner"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                header={null}
                footer={null}>
                <Row>
                    <div className='font' style={{ color: "#191919", fontSize: "22px", marginLeft: "150px" }}>
                        {(user?.privilage?.includes("SuperAdmin")) ? "Mark Attendance" : "Request Attendance"}
                    </div>
                </Row>

                <Row>
                    <Col className='m-t-10 m-l-10'>
                        <div className='font' style={{ fontSize: "12px", color: "#636363" }}>Date</div>
                       { console.log("aaaa",markAttendanceObj?.startDate)}
                        <div><DatePicker
                        // value={moment(markAttendanceObj?.startDate,'YYYY-MM-DD').format('YYYY-MM-DD')}
                        // value={moment(markAttendanceObj?.startDate,'YYYY-MM-DD')}
                        // value={moment(markAttendanceObj?.startDate,'YYYY-MM-DD')}
                        // value={markAttendanceObj?.startDate}
                        value={!markAttendanceObj?.startDate  ?'' : moment(markAttendanceObj?.startDate) }


// 


                           
                            allowClear={false}
                            onKeyDown={(e)=>{e.preventDefault()}}
                            disabledDate={disabledDate}
                            style={{ width: "460px" }}
                            onChange={(date,dateString) => setMarkAttendance('startDate', dateString)}
                        /></div>
                    </Col>
                </Row>
                <Row>
                    <Col className='flex-row'>
                        <Col className='m-t-10 m-l-10' style={{ width: "50%" }}>
                            <div className='font' style={{ fontSize: "12px", color: "#636363" }}>Clock in</div>
                            <div>
                                {console.log("AAA",markAttendanceObj?.clockIn )}
                                <TimePicker
                                allowClear={false}
                                value={!markAttendanceObj?.clockIn  ?'' : moment(markAttendanceObj?.clockIn ,'h:mm') }
                                onKeyDown={(e)=>{e.preventDefault()}}
                                disabled={timerDisable(['startDate'])}
                                disabledHours={()=>disabledStartTimeHours()}
                                disabledMinutes={disabledStartTimeMinutes}
                                format={'HH:mm'}
                                style={{ width: "225px" }}
                                onChange={(e, time) => {
                                    console.log("AAA-time",time);
                                    setMarkAttendance('clockIn', moment(time, 'HH:mm').format('HH:mm'));
                                }}
                            />
                            </div>
                        </Col>
                        <Col className='m-t-10 m-l-10' style={{ width: "50%" }}>
                            <div className='font' style={{ fontSize: "12px", color: "#636363" }}>Clock out</div>
                            <div><TimePicker
                                allowClear={false}
                                value={!markAttendanceObj?.clockOut  ? '' : moment(markAttendanceObj?.clockOut,'h:mm') }
                                onKeyDown={(e)=>{e.preventDefault()}}
                                disabled={timerDisable(['startDate', 'clockIn'])}
                                disabledHours={()=>disabledEndTimeHours()}
                                disabledMinutes={disabledEndTimeMinutes}
                                format={'HH:mm'}
                                style={{ width: "225px" }}
                                onChange={(e, time) => {
                                    setMarkAttendance('clockOut', moment(time, 'HH:mm:ss').format('HH:mm:ss'));
                                }}
                            />
                            </div>
                        </Col>
                    </Col>
                </Row>

                <Row>
                    <div className='m-t-10 m-l-10'>
                        <div className='font' style={{ fontSize: "12px", color: "#636363" }}>Total hours</div>
                                {console.log("AA-diffT",diffT)}
                        <Input
                            readOnly={true}
                            placeholder='Total hours' 
                            style={{ width: "460px" }}
                            onChange={(e) => {
                                setMarkAttendance('totalhours', e.target.value);
                            }}
                            value={(!markAttendanceObj?.clockOut  ? ''  : (isNaN(diffT) ? 0 : diffT > 0 ? diffT : 0))}
                            // value={!markAttendanceObj?.clockOut  ? '' : moment(markAttendanceObj?.clockOut,'h:mm')}
                        >
                        </Input>
                    </div>
                </Row>

                <Row>
                    <div className='m-t-10 m-l-10'>
                        <div className='font' style={{ fontSize: "12px", color: "#636363" }}>Reason(s)</div>
                        <Input placeholder='Enter reason(s)' style={{ width: "460px", height: "70px" }} 
                        value={!markAttendanceObj?.reason ? '' :  markAttendanceObj?.reason}
                        onChange={(e) => setMarkAttendance('reason', e.target.value)}></Input>
                    </div>
                </Row>

                <Row style={{ marginLeft: "120px" }}>
                    <Col className='p-5'>
                        <Button className='m-t-20'
                            style={{
                                borderRadius: "19px",
                                width: "110px",
                                height: "35px",
                                fontWeight: "500",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #636363 ",
                                color: "#636363",
                            }} onClick={handleCancel}
                        >CANCEL
                        </Button>
                    </Col>
                    {console.log("AA-diffT",diffT)}
                    <Col className='p-5'>
                        <Button className='m-t-20'
                            style={{
                                borderRadius: "19px",
                                width: "110px",
                                height: "35px",
                                fontWeight: "500",
                                backgroundColor: "#594099",
                                border: "1px solid #636363 ",
                                color: "#FFFFFF",
                                opacity: markAttendanceObj?.reason === '' && markAttendanceObj?.totalHours === '' && markAttendanceObj?.startDate === '' ? 0.5 : 1,
                            }}
                            disabled={diffT != 0.00 && markAttendanceObj?.clockOut != 'Invalid date' &&markAttendanceObj?.clockIn != 'Invalid date' && markAttendanceObj?.reason?.length > 0 ? false : true}
                            loading={loaders?.modalClockLoader}
                            onClick={() => { handleRequest() }}
                        > {user?.privilage?.includes("SuperAdmin") ? 'Mark' : 'Request'}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const { AttendanceTab, storeselectedAttendance, loaders, startDateContainer, selectedRangeContainer, markAttendanceObj } = state.attendanceNew;
    const { user } = state.session;
    return { AttendanceTab, user, storeselectedAttendance, loaders, startDateContainer, selectedRangeContainer, markAttendanceObj };
};

const mapDispatchToProps = (dispatch) => ({
    getAttendanceProfileTabs: () => (dispatch(getAttendanceProfileTabs())),
    selectedAttendance: (val) => (dispatch(selectedAttendance(val))),
    setMarkAttendance: (key, val) => dispatch(setMarkAttendance(key, val)),
    markAttendance: (callBack,successCallBack) => dispatch(markAttendance(callBack,successCallBack)),
    setLoader: (key, val) => dispatch(setLoader(key, val)),
    usersDownloadReport: (callback, successCallBack) => (dispatch(usersDownloadReport(callback, successCallBack))),
    storeSelectedRange: (val) => dispatch(storeSelectedRange(val)),
    storeStartDate: (val) => dispatch(storeStartDate(val)),
    storeEndDate: (val) => dispatch(storeEndDate(val)),
    getAttendanceDashBoardData: callback => dispatch(getAttendanceDashBoardData(callback)),


});

export default connect(mapStateToProps, mapDispatchToProps)(RequestAttendanceModel)

