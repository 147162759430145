
import { Button, TreeSelect, Tooltip, Menu, Dropdown } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
    updateCurrentQId,
    getQuestion,
    updateCurrentQ,
    setCurrentIndex,
    saveQuestion,
    deleteQuestion
} from "actions/marketTest";
import { AdminPalette } from "./marketTestPalette";
import { MdKeyboardBackspace, MdFormatListBulleted, MdMoreVert } from "react-icons/md";
import { AiTwotoneEdit, AiOutlineDownload, AiFillDelete, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { ClearOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import PageDataLoader from "components/pageDataLoader";
import ViewImgModal from "pages/studentTest/modals/viewImgModal";
import { AdminQuestionTypes } from "./questionTypes";
import TextEditorModal from "./textEditorModal";
import { setImportMeta } from "actions/testAdmin";


const questionTypeList = [
    {
        title: 'Multiple Choice',
        value: '0-0',
        selectable: false,
        children: [
            {
                title: 'One Correct Answer',
                value: 'MCQ',
                selectable: true,
            }, {
                title: 'Multiple Correct Answer',
                value: 'MSQ',
                selectable: true,
            }
        ],
    },
    {
        title: 'Numeric',
        value: 'Numeric',
    },
    {
        title: 'Comprehensive',
        value: '0-2',
        selectable: false,
        children: [
            {
                title: 'Comp-One Correct Answer',
                value: 'CMCQ',
                selectable: true,
            }, {
                title: 'Comp-Multiple Correct Answer',
                value: 'CMSQ',
                selectable: true,
            }, {
                title: 'Comp-Numeric',
                value: 'CNum',
                selectable: true,
            }, {
                title: 'Comp-True/False',
                value: 'CTF',
                selectable: true,
            }
        ]
    }, {
        title: 'True/False',
        value: 'T/F',
        selectable: true,
    }
]


function AddQuestions({
    currentQ,
    palateData,
    currentQId,
    individualTest,
    updateCurrentQId,
    getQuestion,
    updateCurrentQ,
    currentIndex,
    setCurrentIndex,
    saveQuestion,
    deleteQuestion,
    individualTestId,
    setImportMeta
}) {

    const history = useHistory()

    const [currentImg, setCurrentImg] = useState("");
    const [showImgModal, toggleImageModal] = useState(false);
    const [textEditorVisible, toggleTextEditor] = useState(false);
    const [textEditorValue, setEditorValue] = useState();
    const [editorCallback, setEditorCallback] = useState(() => { });

    const [pageLoader, togglePageLoader] = useState(false);
    const [buttonLoader, toggleButtonLoader] = useState(false);
    const [saveLoader, toggleSaveLoader] = useState(false);

    const [isCompQ, setIsCompQ] = useState(false);
    const [sectionId, updateSelectedSecId] = useState(palateData[0]?.individualTestSection[0]?.id);
    const [selectedSubId, updateSelectedSubId] = useState(palateData[0]?.id);
    const [selectedMS, updateSelectedMS] = useState(palateData[0]?.individualTestSection[0]?.markingSchemeData);
    const [currentQType, updateQType] = useState(null);
    const [modifiedList, updateModifiedList] = useState([]);
console.log("type",currentQType);

    useEffect(() => {
        updateCurrentQ({
            negativeMarks: 0,
            positiveMarks: 0,
            questionContent: null,
            answerContent: null,
            type: null,
            sectionId: palateData[0]?.individualTestSection[0]?.id,
            individualTestId
        })
        updateCurrentQId(null)
        setCurrentIndex(null)
    }, []);

    useEffect(() => {
        setIsCompQ(false)

        let subType = currentQ?.type
        if (subType == "Comprehensive") {
            setIsCompQ(true)

            subType = currentQ.questionContent?.subQuestions?.[0]?.type
            if (subType == 'MCQ') subType = 'CMCQ'
            if (subType == 'MSQ') subType = 'CMSQ'
            if (subType == 'Numeric') subType = 'CNum'
            if (subType == 'T/F') subType = 'CTF'
        }
        if (subType == "OrQuestions") subType = currentQ.questionContent?.orQuestions[0]?.type

        updateQType(subType)
    }, [currentQ]);


    useEffect(() => {
        if (selectedMS) {
            const newModifiedList = []
            const types = selectedMS?.markingSchemeMarks?.map(el => el.typeOfQuestions) ?? []

            questionTypeList?.forEach(el => {
                if (types?.includes(el.value)) newModifiedList.push(el)
                else if (el?.children) {
                    const newChilds = []
                    el.children.forEach(child => {
                        if (types?.includes(child.value)) newChilds.push(child)
                    })

                    if (newChilds.length)
                        newModifiedList.push({
                            ...el,
                            children: newChilds
                        })
                }
            })

            updateModifiedList(newModifiedList)
        }
        else updateModifiedList(questionTypeList)
    }, [selectedMS]);


    const onQuestionSelect = (idx, qId, secId, subId, msData) => {
        updateCurrentQId(qId)

        const successCallBack = () => {
            updateSelectedSecId(secId)
            updateSelectedSubId(subId)
            updateSelectedMS(msData)
            setCurrentIndex(idx)
        }

        if (qId) {
            toggleButtonLoader(true)
            togglePageLoader(true)
            getQuestion(() => {
                toggleButtonLoader(false)
                togglePageLoader(false)
            }, successCallBack)
        } else {
            successCallBack()
            updateCurrentQ({
                negativeMarks: 0,
                positiveMarks: 0,
                questionContent: null,
                answerContent: null,
                type: null,
                sectionId,
                individualTestId
            })
        }
    }

    const handleTypeSelect = (type) => {
        console.log('type',type);
        updateQType(type)

        const subQues = {
            type,
            negativeMarks: 0,
            positiveMarks: 0,
            questionContent: {
                statement: null,
                solution: null,
            }
        }

        const options = []
        let noOfOptions = 0
        if (selectedMS) {
            const markingSchemeMark = selectedMS?.markingSchemeMarks?.find(el => el.typeOfQuestions == type)
            if (markingSchemeMark?.metadata) markingSchemeMark.metadata = JSON.parse(markingSchemeMark.metadata)

            noOfOptions = markingSchemeMark?.metadata?.noOfOptions

            if (markingSchemeMark?.negativeMarks) subQues.negativeMarks = parseFloat(markingSchemeMark?.negativeMarks)

            if (markingSchemeMark?.positiveMarks) subQues.positiveMarks = parseFloat(markingSchemeMark.positiveMarks)
            else subQues.positiveMarks = parseFloat(markingSchemeMark?.metadata?.schema[0]?.positiveMarks)
        }
        if (!noOfOptions) noOfOptions = 4

        for (let i = 0; i < noOfOptions; i++) {
            options.push({
                // id: i,
                value: null,
                "correct": false
            })
        }

        if (type == 'MCQ' || type == 'MSQ' || type == 'CMCQ' || type == 'CMSQ') {
            subQues.questionContent = {
                statement: null,
                solution: null,
                options
            }
        }

        if (type == 'Numeric' || type == 'T/F' || type == 'MCQ' || type == 'MSQ')
            updateCurrentQ({
                id: currentQId,
                ...subQues,
                answerContent: {
                    answer: (type != "MSQ") ? null : undefined,
                    answers: (type == "MSQ") ? [] : undefined
                },
                sectionId,
                individualTestId
            })

        if (type == "CNum" || type == 'CTF' || type == 'CMCQ' || type == 'CMSQ')
            updateCurrentQ({
                id: currentQId,
                type: "Comprehensive",
                negativeMarks: subQues.negativeMarks,
                positiveMarks: subQues.positiveMarks,
                questionContent: {
                    paragraph: null,
                    subQuestions: [{
                        ...subQues
                    }],
                },
                answerContent: [{
                    answer: (type != "CMSQ") ? null : undefined,
                    answers: (type == "CMSQ") ? [] : undefined
                }],
                sectionId,
                individualTestId
            })
    }


    const addQuestionHandler = () => {

        const type = currentQType
        const subQues = {
            type,
            negativeMarks: 0,
            positiveMarks: 0,
            questionContent: {
                statement: null,
                solution: null,
            }
        }

        const options = []
        let noOfOptions = 0
        if (selectedMS) {
            const markingSchemeMark = selectedMS?.markingSchemeMarks?.find(el => el.typeOfQuestions == type)
            noOfOptions = markingSchemeMark?.metadata?.noOfOptions

            subQues.negativeMarks = parseFloat(markingSchemeMark?.negativeMarks)
            if (markingSchemeMark?.positiveMarks) subQues.positiveMarks = parseFloat(markingSchemeMark.positiveMarks)
            else subQues.positiveMarks = parseFloat(markingSchemeMark?.metadata?.schema[0]?.positiveMarks)
        }
        if (!noOfOptions) noOfOptions = 4

        for (let i = 0; i < noOfOptions; i++) {
            options.push({
                // id: i,
                value: null,
                "correct": false
            })
        }

        if (type == 'MCQ' || type == 'MSQ' || type == 'CMCQ' || type == 'CMSQ') {
            subQues.questionContent = {
                statement: null,
                solution: null,
                options
            }
        }

        if (isCompQ) {
            currentQ.positiveMarks += subQues.positiveMarks
            currentQ.negativeMarks += subQues.negativeMarks

            currentQ.questionContent.subQuestions.push({
                ...subQues
            })
            currentQ.answerContent.push({
                answer: (type != "CMSQ") ? null : undefined,
                answers: (type == "CMSQ") ? [] : undefined
            })
            updateCurrentQ({ ...currentQ })
        }
        else {
            delete subQues.negativeMarks
            delete subQues.positiveMarks

            if (currentQ?.type != "OrQuestions") {
                currentQ = {
                    id: currentQId,
                    type: "OrQuestions",
                    negativeMarks: currentQ.negativeMarks,
                    positiveMarks: currentQ.positiveMarks,
                    questionContent: {
                        orQuestions: [{
                            type,
                            questionContent: {
                                statement: currentQ?.questionContent?.statement,
                                solution: currentQ?.questionContent?.solution,
                                options: currentQ?.questionContent?.options
                            }
                        }],
                    },
                    answerContent: [currentQ.answerContent],
                    sectionId,
                    individualTestId
                }
            }

            currentQ.questionContent.orQuestions.push({
                ...subQues
            })

            currentQ.answerContent.push({
                answer: (type != "MSQ") ? null : undefined,
                answers: (type == "MSQ") ? [] : undefined
            })

            updateCurrentQ({ ...currentQ })
        }
    }


    return (<div style={{ height: '100%', margin: '-20px' }}>
        <div className='r-c-sb p-20' style={{ height: 60, background: '#F6F4FE' }}>
            <div className='r-ac'>
                <MdKeyboardBackspace onClick={() => {
                    history.push({
                        pathname: '/testSeries/createTest',
                        state: {
                            key: {
                                isEdit: "isFromEdit"
                            }
                        }
                    })
                }} className='text-md color-black m-r-10 cursor-pointer' />

                <div className='text-sm bold-600 color-black'>{individualTest?.title}</div>

                <AiTwotoneEdit onClick={() => { history.push('/testSeries/createTest') }} className='text-md a2grey m-l-20 cursor-pointer' />
            </div>

            <Button
                loading={false}
                className="purpleBtnWrap radius-100 purpleButtonHoverEffect"
                style={{ width: 120 }}
                onClick={() => {
                    history.push({
                        pathname: '/course-details',
                        state: {
                            key: {
                                isEdit: "isFromEdit",
                                fromQuestionPage: true
                            }
                        }
                    })
                }}
            >SAVE
            </Button>
        </div>

        <div className='display-flex' style={{ height: 'calc(100% - 20px)' }}>
            <div className='position-relative' style={{ width: "80%", paddingBottom: 50 }}>
                <div style={{ height: 'calc(100% - 20px)', overflowX: "hidden", overflowY: "scroll" }}>

                    <div className='r-c-sb p-20'>
                        <div className='r-ac'>
                            <div className='r-c-c-c m-r-50'>
                                <div className='text-xmd bold-600'>{individualTest?.totalQuestions}</div>
                                <div className='text-xs'>Questions</div>
                            </div>
                            <div className='r-c-c-c m-r-50'>
                                <div className='text-xmd bold-600'>{individualTest?.duration} mins</div>
                                <div className='text-xs'>Duration</div>
                            </div>
                            <div className='r-c-c-c m-r-50'>
                                <div className='text-xmd bold-600'>{individualTest?.totalPoints}</div>
                                <div className='text-xs'>Marks</div>
                            </div>
                        </div>

                        <div className='r-ac'>
                            <div onClick={() => { history.push('/testSeries/listView') }} className='r-c-c-c m-r-20 cursor-pointer'>
                                <MdFormatListBulleted className='text-md color-black' />
                                <div className='text-xs text-center color-black'>List View</div>
                            </div>
                            <div className='r-c-c-c cursor-pointer'
                                onClick={() => {
                                    setImportMeta("selectedQBType", "subject-wise question papers");
                                    setImportMeta('selectedTypeId', 2);
                                    history.push({
                                        pathname: '/test-import-classes',
                                        state: {
                                            testSeriesData: true
                                        }
                                    })
                                }}>
                                <AiOutlineDownload className='text-md' />
                                <div className='text-xs text-center' >Import</div>
                            </div>
                        </div>
                    </div>


                    <div className='horizontal-divider'></div>


                    {/* div with grey background */}
                    <div className="full-height" style={{ backgroundColor: "#fafafa", height: 'fit-content' }}>
                        <div className='r-ac' style={{ maxWidth: "100%", overflowX: "auto", display: 'flex' }}>
                            {palateData?.map((item) => (
                                <div key={item?.id}
                                    onClick={() => { onQuestionSelect(null, null, item.individualTestSection[0].id, item.id, item.individualTestSection[0]?.markingSchemeData) }}
                                    style={{
                                        cursor: 'pointer',
                                        width: '100%',
                                        textAlign: 'center',
                                        backgroundColor: (item.id == selectedSubId) ? '#F6F4FE' : '#FFFFFF',
                                        color: (item.id == selectedSubId) ? '#191919' : '#AEAEAE',
                                        height: '30px',
                                        fontWeight: '500',
                                        padding: '5px'
                                    }}>
                                    {item?.testSeriesSubject?.subject?.name}
                                </div>
                            ))}
                        </div>

                        <div className='r-c-sb p-20'>
                            <div className='r-ac' style={{ maxWidth: "100%", overflowX: "auto" }}>
                                {palateData?.map((item) => {
                                    if (item.id == selectedSubId) {
                                        return item?.individualTestSection?.map((section) => {
                                            if (section?.name != "_default_")
                                                return <div style={{ width: 'auto' }}>
                                                    <Button onClick={() => { onQuestionSelect(null, null, section?.id, selectedSubId, section?.markingSchemeData) }}
                                                        className={(section?.id == sectionId) ? 'purpleBtnWrap m-r-20 text-xs' : 'blankGreyBtnWrap m-r-20 text-xs'}
                                                        style={{ cursor: 'pointer' }}>
                                                        {section?.name}
                                                    </Button>
                                                </div>
                                            else return null
                                        })
                                    }
                                })}
                            </div>
                            <Dropdown overlay={
                                <Menu>
                                    {currentQId &&
                                        <Menu.Item key="0" className="m-b-10">
                                            <div className='r-ac cursor-pointer p-l-20 p-r-20'
                                                onClick={() => {
                                                    toggleButtonLoader(true)
                                                    togglePageLoader(true)

                                                    const successCallBack = () => {
                                                        updateCurrentQId(null)
                                                        setCurrentIndex(null)
                                                        updateCurrentQ({
                                                            negativeMarks: 0,
                                                            positiveMarks: 0,
                                                        })
                                                    }

                                                    deleteQuestion(() => {
                                                        toggleButtonLoader(false)
                                                        togglePageLoader(false)
                                                    }, successCallBack)
                                                }}
                                            >
                                                <AiFillDelete className='text-md' />
                                                <div className='text-xs color-black m-l-10'>Delete</div>
                                            </div>
                                        </Menu.Item>}
                                    <Menu.Item key="1">
                                        <div className='r-ac cursor-pointer p-l-20 p-r-20' onClick={() => {
                                            updateCurrentQ({
                                                id: currentQId,
                                                positiveMarks: 0,
                                                negativeMarks: 0
                                            })
                                        }}>
                                            <ClearOutlined className='text-md' />
                                            <div className='text-xs color-black m-l-10'>Clear</div>
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            } placement="bottomRight" arrow>
                                <MdMoreVert className='text-lg a2grey cursor-pointer' />
                            </Dropdown>
                        </div>


                        <div className='p-l-20 p-r-20 r-c-sb'>
                            <div className='r-ac'>
                                <div className='m-r-30'>
                                    <div className='text-xmd bold-600 color-black m-b-10' style={{ color: '#636363' }}>Question type</div>
                                    {console.log('modifiedList', modifiedList)}
                                    <TreeSelect
                                        className='test-custom-select'
                                        disabled={false}
                                        style={{ width: 200 }}
                                        value={currentQType ?? 'Select Question Type'}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        treeData={modifiedList}
                                        placeholder={'Select Question Type'}
                                        treeDefaultExpandAll={true}
                                        onChange={((value) => { handleTypeSelect(value) })}
                                    />    
                                </div>
                            </div>
                            {!isCompQ &&
                                <div>
                                    <div className='r-ac m-b-10'>
                                        <div className='text-xmd bold-600 color-black m-r-5' style={{ color: '#636363' }}>Marks</div>
                                        <Tooltip placement="right" trigger="hover" title={
                                            <div>
                                                <div className='r-ac'>
                                                    <div className='radius-100' style={{ width: 10, height: 10, background: '#6BDB9E' }}></div>
                                                    <div className='text-xmd color-black m-l-10'>Positive Marks</div>
                                                </div>
                                                <div className='r-ac'>
                                                    <div className='radius-100' style={{ width: 10, height: 10, background: '#ec5254' }}></div>
                                                    <div className='text-xmd color-black m-l-10'>Negative Marks</div>
                                                </div>
                                            </div>
                                        } overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px", background: '#fff' }}>
                                            <ExclamationCircleOutlined style={{ color: "#1089FF" }} className='text-xs cursor-pointer' />
                                        </Tooltip>
                                    </div>
                                    <div className='r-ac'>
                                        <div className='r-c-c test-count-seq-box'>
                                            <AiOutlinePlus onClick={() => {
                                                if (!selectedMS) {
                                                    updateCurrentQ({
                                                        ...currentQ,
                                                        positiveMarks: currentQ?.positiveMarks + 1
                                                    })
                                                }
                                            }} className='cursor-pointer' />
                                            <div className='text-xmd m-l-5 m-r-5'>{currentQ?.positiveMarks}</div>
                                            <AiOutlineMinus onClick={() => {
                                                if (!selectedMS && currentQ?.positiveMarks) {
                                                    updateCurrentQ({
                                                        ...currentQ,
                                                        positiveMarks: currentQ?.positiveMarks - 1
                                                    })
                                                }
                                            }} className='cursor-pointer' />
                                        </div>
                                        <div className='r-c-c test-count-seq-box m-l-10' style={{ background: '#FFE4D9', borderColor: '#EC5254' }}>
                                            <AiOutlinePlus onClick={() => {
                                                if (!selectedMS) {
                                                    updateCurrentQ({
                                                        ...currentQ,
                                                        negativeMarks: currentQ?.negativeMarks + 1
                                                    })
                                                }
                                            }} className='cursor-pointer' />
                                            <div className='text-xmd m-l-5 m-r-5'>{currentQ?.negativeMarks}</div>
                                            <AiOutlineMinus onClick={() => {
                                                if (!selectedMS && currentQ?.negativeMarks) {
                                                    updateCurrentQ({
                                                        ...currentQ,
                                                        negativeMarks: currentQ?.negativeMarks - 1
                                                    })
                                                }
                                            }} className='cursor-pointer' />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>



                        <AdminQuestionTypes
                            currentIndex={currentIndex}
                            toggleImageModal={toggleImageModal}
                            setCurrentImg={setCurrentImg}
                            currentQ={currentQ}
                            updateCurrentQ={updateCurrentQ}
                            setEditorValue={setEditorValue}
                            setEditorCallback={setEditorCallback}
                            toggleTextEditor={toggleTextEditor}
                            selectedMS={selectedMS}
                            currentQType={currentQType}
                        />

                        {currentQType ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='text-xmd bold-600 blue text-center r-c-c'
                                    style={{ cursor: 'pointer', width: "33%", color: '#1089ff' }}
                                    onClick={addQuestionHandler}
                                >
                                    {isCompQ ? 'ADD QUESTION' : 'ADD OR QUESTION'}
                                </div>
                            </div>
                            : null
                        }

                    </div>
                </div>



                <div className='p-20 test-save-sticky-footer r-c-c'>
                    <div className='text-xmd bold-600 blue text-center r-c-c' style={{ width: "33%" }}>
                        <div className="cursor-pointer" onClick={() => {
                            toggleSaveLoader(true)
                            const successCallBack = () => {
                                updateCurrentQId(null)
                                setCurrentIndex(null)
                                updateCurrentQ({
                                    negativeMarks: 0,
                                    positiveMarks: 0,
                                })
                            }
                            saveQuestion(() => toggleSaveLoader(false), successCallBack)
                        }}>{saveLoader ? <LoadingOutlined className='m-r-10' style={{ color: '#1089ff' }} /> : null} SAVE & NEXT </div>
                    </div>
                </div>
            </div>

            <div className='vertical-divider'></div>
            <div className='p-10' style={{ width: "20%" }}>
                <AdminPalette
                    onQuestionSelect={onQuestionSelect}
                    selectedSecId={sectionId}
                    buttonLoader={buttonLoader}
                />
            </div>
        </div>


        <TextEditorModal
            visible={textEditorVisible}
            value={textEditorValue}
            onCancel={() => toggleTextEditor(false)}
            onSave={(editorData) => { editorCallback(editorData) }}
            currentQType={currentQType}
            fromTest={true}
        />

        <ViewImgModal
            visible={showImgModal}
            imgPath={currentImg}
            onCancel={() => toggleImageModal(false)}
        />
        <PageDataLoader visible={pageLoader} />
    </div>)
}

const mapStateToProps = (state) => {
    const { palateData, currentQ, currentIndex, currentQId, individualTest, individualTestId } = state.marketTest;
    return { palateData, currentQ, currentIndex, currentQId, individualTest, individualTestId };
};

const mapDispatchToProps = (dispatch) => ({
    getQuestion: (loaderCallback, successCallBack) => dispatch(getQuestion(loaderCallback, successCallBack)),
    updateCurrentQId: (qId) => dispatch(updateCurrentQId(qId)),
    updateCurrentQ: (data) => dispatch(updateCurrentQ(data)),
    setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
    saveQuestion: (loaderCallback, successCallBack) => dispatch(saveQuestion(loaderCallback, successCallBack)),
    deleteQuestion: (loaderCallback, successCallBack) => dispatch(deleteQuestion(loaderCallback, successCallBack)),
    setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestions);
