import { getTeachersGeofenceLocations, saveEditedGeoFence } from 'actions/attendanceNew'
import { Modal } from 'antd'
import { Button } from 'components/Button'
import { Information, SmallInfo, Title } from 'components/Typography'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { MainSectionTypes } from '../constants/offers'
import '../styles.css'

const renderCardContent = (label, value, width) => (
    <div className="offer-modal-light-purple-card" style={{ width: width }}>
        <div><SmallInfo color="#636363">{label}</SmallInfo></div>
        <div style={{ fontWeight: 400, width: "100%", textWrap: "wrap" }}><Information color='#191919'>{value}</Information></div>
    </div>
);

const getOfferApplicableString = (offer) => {
    let applicableString = '';
    if (offer?.type == 'course') {
        if (offer?.applicableLive && offer?.applicableVideo && offer?.applicableStudy && offer?.applicableTest) {
            applicableString += 'ALL';
        } else {
            if (offer?.applicableLive) {
                applicableString += 'LIVE COURSES'
            }
            if (offer?.applicableVideo) {
                applicableString += `${applicableString.length ? ', ' : ''}VIDEO COURSES`;
            }
            if (offer?.applicableStudy) {
                applicableString += `${applicableString.length ? ', ' : ''}STUDY MATERIALS`;
            }
            if (offer?.applicableTest) {
                applicableString += `${applicableString.length ? ', ' : ''}ONLINE TEST SERIES`
            }
        }
    } else if (offer?.type == 'category') {
        applicableString += offer?.categories?.reduce((acc, curr, index) => {
            if (index == 0) {
                return curr.name;
            } else {
                return acc + `, ${curr.name}`;
            }
        }, '');
    } else if (offer?.type == 'subcategory') {
        applicableString += offer?.subcategories?.reduce((acc, curr, index) => {
            if (index == 0) {
                return curr.name;
            } else {
                return acc + `, ${curr.name}`;
            }
        }, '');
    } else if (
        offer?.type == 'liveCourse'
        || offer?.type == 'videoResource'
        || offer?.type == 'studyMaterial'
        || offer?.type == 'testSeries'
    ) {
        applicableString += offer?.course?.[0]?.name || ''
    }
    return applicableString;
}
function OfferDetailsModal({ isOfferDetailsModalVisible, setIsOfferDetailsModalVisible, togglePageLoader, deleteOffer, offer,
    setIsOfferModalVisible, setModalActionType, setSelectedOffer, setMainSectionType, setPreviousMainSectionType, mainSectionType }) {
    const handleCancel = () => {
        setIsOfferDetailsModalVisible(false)
    }
    // <div>
    //     <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }} style={{ marginBottom: 0, fontWeight: 700, color: "#636363", fontSize: '0.875rem' }}>
    //         {getOfferApplicableString(offer) || ''}
    //     </Typography.Paragraph>
    // </div >
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isOfferDetailsModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <div className='r-c-fs-c' style={{ gap: "1rem" }}>
                <div className='r-c-c-c'>
                    <Title>Offer Details </Title>
                </div>
                <div className="offer-modal-details" style={{ width: "100%", flexWrap: true }}>
                    <div className='offer-modal-row'>
                        {renderCardContent("Title", offer?.title, "100%")}
                    </div>
                    <div className='offer-modal-row'>
                        {renderCardContent(offer?.discountType === 'percent' ? "Offer Percentange" : "Offer Amount", offer?.discountType === 'amount' ? `₹${offer?.discount}` : `${offer?.discount}%`, !offer?.code ? "100%" : "49%")}
                        {offer?.code ? renderCardContent("Promo code", offer?.code, "49%") : null}
                    </div>
                    {offer?.startDate || offer?.endDate ?
                        <div className='offer-modal-row'>
                            {offer?.startDate ? renderCardContent("Start Date", moment(offer?.startDate).format('DD/MM/YYYY'), !offer?.endDate ? "100%" : "49%") : null}
                            {offer?.endDate ? renderCardContent(offer?.privateOffer ? "Valid until" : "End Date", moment(offer?.endDate).format('DD/MM/YYYY'), !offer?.startDate ? "100%" : "49%") : null}
                        </div> : null
                    }{!offer?.privateOffer ?
                        <div className='offer-modal-row'>
                            {renderCardContent("Applicable for", getOfferApplicableString(offer), "100%")}
                        </div>
                        : null
                    }
                </div>
                <div className='r-c-c' style={{ gap: '1rem', width: '100%' }}>
                    <Button
                        type="transperent"
                        style={{ backgroundColor: '#FAFAFA', color: '#FF414D', borderColor: '#FF414D', padding: "1rem", width: '30%' }}
                        onClick={() => {
                            setIsOfferDetailsModalVisible(false);
                            setIsOfferModalVisible(true);
                            setModalActionType('delete');
                        }}
                    >DELETE</Button>
                    <Button
                        type="primary"
                        style={{ padding: "1rem", width: '30%' }}
                        onClick={() => {
                            setPreviousMainSectionType(mainSectionType);
                            setMainSectionType(MainSectionTypes.createEdit)
                        }}> EDIT</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getTeachersGeofenceLocations: (search, callBack) => (dispatch(getTeachersGeofenceLocations(search, callBack))),
    saveEditedGeoFence: (params, callBack, successCallBack) => dispatch(saveEditedGeoFence(params, callBack, successCallBack)),
});
export default connect(null, mapDispatchToProps)(OfferDetailsModal);