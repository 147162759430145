import { Modal } from 'antd'
import e from 'cors';
import "./styles.css";
import React, { useState } from 'react'

function ShareCourseModal({ isShareModalVisible, setShareModalVisible, shareUrl }) {
    console.log("abcdef", shareUrl);
    const [copied, setCopied] = useState(false);
    const handleCancel = (e) => {
        e.stopPropagation()
        setShareModalVisible(false)
    }
    const handleCopyClick = () => {
        const urlInput = document.getElementById('url-input');
        urlInput.select();
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 10000);
    };
    const openChatPage = (platform) => {
        let chatUrl = '';
        switch (platform) {
            case 'instagram':
                chatUrl = 'https://www.instagram.com/direct/inbox/';
                break;
            case 'facebook':
                chatUrl = 'https://www.facebook.com/';
                break;
            case 'linkedin':
                chatUrl = 'https://www.linkedin.com/messaging/';
                break;
            case 'whatsapp':
                chatUrl = 'https://web.whatsapp.com/';
                break;
            case 'twitter':
                chatUrl = "https://www.twitter.com";
                break;
            default:
                return;
        }
        window.open(chatUrl, '_blank');
    };
    return (
        <div onClick={(e)=>{e.stopPropagation()}}>
        <Modal
            centered
            className="modal-round-corner"
            visible={isShareModalVisible}
            onOk={(e) => { handleCancel(e) }}
            onCancel={(e) => { handleCancel(e) }}
            header={null}
            footer={null}
        >
            <center style={{ fontSize: 20, fontWeight: "bold", color: "#191919" }}>Share this course</center>
            <div className='m-t-20 r-sb share-modal-padding' style={{ display: "flex", justifyContent: "space-between" }}>
                <div className='cursor-pointer r-c-c-c' onClick={(e) => { e.stopPropagation(); openChatPage('instagram') }}>
                    <img alt="insta" src={require("../../Assets/aulasMarket/instagram.svg").default} loading="lazy" />
                    <div style={{ color: "#636363", fontSize: 12 }}>Instagram</div>
                </div>
                <div className='cursor-pointer r-c-c-c' onClick={(e) => { e.stopPropagation(); openChatPage('whatsapp') }}>
                    <img alt="whatsapp" src={require("../../Assets/aulasMarket/whatsappColored.svg").default} loading="lazy" />
                    <div style={{ color: "#636363", fontSize: 12 }}>WhatsApp</div>
                </div>
                <div className='cursor-pointer r-c-c-c' onClick={(e) => { e.stopPropagation(); openChatPage('facebook') }}>
                    <img alt="fb" src={require("../../Assets/aulasMarket/facebook.svg").default} loading="lazy" />
                    <div style={{ color: "#636363", fontSize: 12 }}>Facebook</div>
                </div>
                <div className='cursor-pointer r-c-c-c' onClick={(e) => { e.stopPropagation(); openChatPage('twitter') }}>
                    <img alt="linkedIn" src={require("../../Assets/aulasMarket/share-twitter-icon.svg").default} loading="lazy" />
                    <div style={{ color: "#636363", fontSize: 12 }}>Twitter</div>
                </div>
                <div className='cursor-pointer r-c-c-c' onClick={(e) => { e.stopPropagation(); openChatPage('linkedin') }}>
                    <img alt="linkedIn" src={require("../../Assets/aulasMarket/LinkedInColor.svg").default} loading="lazy" />
                    <div style={{ color: "#636363", fontSize: 12 }}>LinkedIn</div>
                </div>
            </div>
            <div className='m-t-20 m-b-10 r-c-c'>
                <input type="text" id="url-input" readOnly style={{ height: "fit-content", width: "90%", border: "1px solid #D8D8D8", marginRight: 20, fontSize: 13, color: "#747272", padding: 5, borderRadius: 4 }} value={shareUrl} />
                <div className='font-bold cursor-pointer' style={{ color: "#594099", fontSize: 12 }} onClick={(e) => { e.stopPropagation(); handleCopyClick() }}>{copied ? 'COPIED' : 'COPY'}</div>
            </div>
        </Modal>
        </div>
    )
}

export default ShareCourseModal