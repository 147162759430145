import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SettingFilled,
  SettingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Button, Col, PageHeader, Row, Input, Avatar, Typography } from "antd";
import React, { useEffect, useState } from "react";
import DashBoardGraph from "./dashBoardGraph";
import OverViewGraph from "./overViewGraph";
import style from "./style.css";
import image from "../../Assets/feeManagement/Mask Group 2.png";
import image1 from "Assets/user-management/ic-students.svg";
import empty from "Assets/feeManagement/ill-kyc-verification.svg";
import SettingsModal from "./settingsModal";
import GeneratePayRequest from "./generatePayRequest";
import AddDetailsModal from "./addDetailsModal";
import OfflinePayReq from "./addOfflinePayReqModal";
import { useHistory } from "react-router";
import StudentCard from "./studentCard";
import { connect } from "react-redux";
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;

import {
  getFeeOverView,
  setAllActivitiesFilter,
  setFeeDetails,
  setOverviewFilter,
  setSelectedStd,
} from "actions/feeManagement";
import { Api } from "services";
import PageDataLoader from "components/pageDataLoader";
import ShowReasonModal from "./showReasonModal";
import moment from "moment";
// import Search from "antd/lib/transfer/search";

function ClassFeeDashBoard({
  selectedStd,
  setSelectedStd,
  setOverviewFilter,
  getFeeOverView,
  feeMangementData,
  setFeeDetails,
  setAllActivitiesFilter,
  overviewFilter,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showGeneratePayReq, setShowGeneratePayReq] = useState(false);
  const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
  const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
  const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [pageLoader, tooglePageLoader] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(1);
  const [isAdditionModal, setIsAdditionModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);

  const { Search } = Input;
  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }

  const history = useHistory();
  const goBack = (history) => {
    history.goBack();
  };

  useEffect(() => {
    setFeeDetails("setStudentsList", []);
    setAllActivitiesFilter("userId", null);
    setOverviewFilter("userId", null);
    setAllActivitiesFilter("search", null);
    setOverviewFilter("search", null)
    setOverviewFilter("year", null);
    tooglePageLoader(true);
    getFeeOverView(() => tooglePageLoader(false));
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div
        className="r-ac"
        style={{ justifyContent: "space-between", marginBottom: "10px" }}
      >
        <div
          style={{
            marginLeft: "-10px",
          }}
        >
          <PageHeader
            title={<div className="text-md font_roboto bold-700" style={{ fontWeight: "bold", height: "45px", marginTop: "-5px", fontSize: "24px" }}>{selectedStd?.std} - {selectedStd?.section}</div>}
            onBack={() => {
              goBack(history);
              setSelectedStd({});
              setOverviewFilter("standardId", null);
            }}
            backIcon={<ArrowLeftOutlined />}
          />
        </div>
        {/* <div
              className="font_roboto bold-700"
              style={{ fontSize: "24px" }}
            ></div> */}
      </div>
      <Row style={{ marginTop: "-20px" }}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 16 }}
        >
          <div
            className=" div_style box-shadow"
            style={{
              height: "450px",
              padding: "20px",
              // border: "1px solid #E6E6E6",
              borderRadius: "15px",
              overflowY: "hidden",
              marginTop: "10px",
            }}
          >
            <div className="r-jc">
              <div style={{ width: "25%" }}>
                <div>
                  <div className="font_roboto bold-600">overview</div>
                  <div
                    className="font_roboto"
                    style={{ fontSize: "12px", color: "#636363" }}
                  >
                    {overviewFilter?.type === 'week' ?
                      (<> {moment(overviewFilter?.startDate).format("DD-MM-YYYY")} - {moment(overviewFilter?.endDate).format("DD-MM-YYYY")}</>)
                      : overviewFilter?.year}
                  </div>
                </div>
                <div style={{ marginTop: "60px" }}>
                  <div className="r-ac">
                    <div className="dot" style={{ marginRight: "3px" }}></div>
                    <div style={{ color: "#594099" }}>
                      <span className="bold-700">
                        {" "}
                        {
                          feeMangementData?.dashBoardDetails
                            ?.totalTransactionRequested?.count
                        }
                      </span>{" "}
                      {feeMangementData?.dashBoardDetails
                        ?.totalTransactionRequested?.count == 1
                        ? "payment"
                        : "payments"}{" "}
                      requested
                    </div>
                  </div>
                  <div className="bold-700" style={{ fontSize: "1rem" }}>
                    ₹{" "}
                    {
                      feeMangementData?.dashBoardDetails
                        ?.totalTransactionRequested?.amount &&
                      numberWithCommas(feeMangementData?.dashBoardDetails
                        ?.totalTransactionRequested?.amount)
                    }
                  </div>
                  <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                    {
                      feeMangementData?.dashBoardDetails
                        ?.totalTransactionRequested?.todayCount
                    }{" "}
                    {feeMangementData?.dashBoardDetails
                      ?.totalTransactionRequested?.todayCount == 1
                      ? "payment"
                      : "payments"}{" "}
                    requested today
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "80%",
                  marginRight: "-15px",
                  marginTop: "-10px",
                }}
              >
                <OverViewGraph />
              </div>
            </div>
            <div
              className="r-c-sb"
              style={{
                borderTop: "1px solid #E6E6E6",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#FFA931" }}
                  ></div>
                  <div
                    style={{
                      color: "#FFA931",
                      fontSize: "10px",
                      fontWeight: "550",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentPending?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    pending
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentPending
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  pending today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#28DF99" }}
                  ></div>
                  <div
                    style={{
                      color: "#28DF99",
                      fontSize: "10px",
                      fontWeight: "550",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails?.totalPaymentPaid
                          ?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentPaid
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    paid
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentPaid
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentPaid
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentPaid
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentPaid
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  paid today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#FF414D" }}
                  ></div>
                  <div
                    style={{
                      color: "#FF414D",
                      fontSize: "10px",
                      fontWeight: "550",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentOverDue?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    overdue
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  overdue today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#636363" }}
                  ></div>
                  <div
                    style={{
                      color: "#636363",
                      fontSize: "10px",
                      fontWeight: "550",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span
                      className="bold-700"
                      style={{
                        fontSize: "12.5px",
                      }}
                    >
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentCancelled?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    cancelled
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  cancelled today
                </div>
              </div>
              <div
                style={{
                  // borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#1089FF" }}
                  ></div>
                  <div
                    style={{
                      color: "#1089FF",
                      fontSize: "10px",
                      fontWeight: "550",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentRefunded?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    refunded
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  refunded today
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 8 }}
        >
          <div
            className="box-shadow"
            style={{
              // border: "1px solid #E6E6E6",
              height: "450px",
              borderRadius: "15px",
              marginLeft: "10px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <div
              className="r-ac"
              style={{
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "18px",
                  fontWeight: "550",
                }}
              >
                Recent activities{" "}
                <span style={{ color: "#AEAEAE" }}>
                  (
                  {feeMangementData?.dashBoardDetails?.recentTransactions
                    ?.count
                    ? feeMangementData?.dashBoardDetails?.recentTransactions
                      ?.count
                    : 0}
                  )
                </span>
              </div>
              <div
                onClick={() => {
                  history.push(
                    "/fee-management/class-details/all-activities"
                  );
                }}
                style={{
                  fontFamily: "roboto",
                  fontSize: "12px",
                  fontWeight: "550",
                  color: "#1089FF",
                  cursor: "pointer",
                }}
              >
                VIEW ALL
              </div>
            </div>
            {feeMangementData?.dashBoardDetails?.recentTransactions
              ?.allTransactionList?.length != 0
              ? feeMangementData?.dashBoardDetails?.recentTransactions?.allTransactionList?.map(
                (item, index) =>
                  index < 4 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "15px",
                      }}
                    >
                      <div
                        className="r-ac"
                        style={{ fontFamily: "roboto" }}
                      >
                        <div>
                          <Avatar
                            style={{ width: "35px", height: "35px" }}
                            src={Api._s3_url + item?.user?.image}
                          />
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                          <div
                            style={{ fontWeight: "550", fontSize: "13px" }}
                          >
                            {item?.user?.name}
                          </div>
                          <div
                            style={{
                              color: "#636363",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: "10px",
                            }}
                          >
                            ₹ {
                              item?.amountPayable &&
                              numberWithCommas(item?.amountPayable)
                            } |{" "}
                            {console.log("i", item?.amountPayable)}
                            <span>
                              <Typography.Text
                                ellipsis={{ tooltip: true }}
                                style={{
                                  // color: "#636363",
                                  width: "60px",
                                  fontWeight: "550",
                                }}
                              >
                                {item.note}
                              </Typography.Text>
                            </span>
                          </div>
                          <div
                            style={{ color: "#AEAEAE", fontSize: "10px" }}
                          >
                            {item?.date}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            border: `1px solid ${item?.status == "Overdue"
                              ? "#FF414D"
                              : item?.status == "Paid"
                                ? "#28DF99"
                                : item?.status == "Refunded"
                                  ? "#1089FF"
                                  : item?.status == "Cancelled"
                                    ? "#1089FF"
                                    : "#FFA931"
                              }`,
                            borderRadius: "7px",
                            textAlign: "center",
                            fontSize: "10px",
                            padding: "5px",
                            fontWeight: "500",
                            color: `${item?.status == "Overdue"
                              ? "#FF414D"
                              : item?.status == "Paid"
                                ? "#28DF99"
                                : item?.status == "Refunded"
                                  ? "#1089FF"
                                  : item?.status == "Cancelled"
                                    ? "#1089FF"
                                    : "#FFA931"
                              }`,
                            backgroundColor: `${item?.status == "Overdue"
                              ? "#FFECED"
                              : item?.status == "Paid"
                                ? "#EAFCF5"
                                : item?.status == "Refunded"
                                  ? "#E7F3FF"
                                  : item?.status == "Cancelled"
                                    ? "#E7F3FF"
                                    : "#FFF6EA"
                              }`,
                            width: "100px",
                          }}
                        >
                          {item?.status}
                        </div>
                      </div>
                    </div>
                  ) : null
              )
              : null}
            {feeMangementData?.dashBoardDetails?.recentTransactions
              ?.allTransactionList?.length == 0 ? (
              <div
                className="r-c-c-c"
                style={{ width: "100%", height: "60vh", marginTop: "-50px" }}
              >
                <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
                >
                  <img src={illNoData} />
                </div>
                <div>
                  <h4>
                    <b>No data!</b>
                  </h4>
                </div>
                <div>
                  <h5>
                    There are no data to show yet. Please check back later
                  </h5>
                </div>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <div
        className="r-ac"
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginTop: "50px",
          fontFamily: "roboto",
          fontSize: "18px",
          fontWeight: "550",
        }}
      >
        <div style={{ marginLeft: "10px" }}>
          All students (
          {feeMangementData?.dashBoardDetails?.allStudents?.length})
        </div>
        {!showBtns ? (
          <Search
            allowClear={true}
            placeholder="search"
            onSearch={(e) => {
              // setFeeDetails('searchStudentInClass',e)
              setOverviewFilter("search", e);

              tooglePageLoader(true);
              getFeeOverView(() => tooglePageLoader(false));
            }}
            style={{ width: 285 }}
          />
        ) : (
          <div style={{ fontFamily: "roboto" }}>
            <Button
              onClick={() => {
                setFeeDetails("setStudentsList", []);
              }}
              style={{
                color: "#FF414D",
                border: "1px solid #FF414D",
                borderRadius: "20px",
                fontWeight: "500",
              }}
            >
              CLEAR ALL
            </Button>
            <Button
              onClick={() => {
                setShowGeneratePayReq(true);
              }}
              style={{
                color: "white",
                backgroundColor: "#594099",
                borderRadius: "20px",
                fontWeight: "500",
                marginLeft: "20px",
              }}
            >
              GENERATE
            </Button>
          </div>
        )}
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        {feeMangementData?.dashBoardDetails?.allStudents?.length != 0
          ? feeMangementData?.dashBoardDetails?.allStudents?.map(
            (item, index) => (
              <div style={{ cursor: "pointer" }}>
                <StudentCard
                  name={item.name}
                  roleNo={item.rollNo}
                  image={item.image}
                  transactions={item.count}
                  setShowBtns={setShowBtns}
                  index={index}
                  length={feeMangementData?.dashBoardDetails?.allStudents}
                  userId={item.id}
                  item={item}
                />
              </div>
            )
          )
          : null}
      </div>
      {feeMangementData?.dashBoardDetails?.allStudents?.length == 0 ? (
        <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
          <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
          >
            <img src={illNoData} />
          </div>
          <div>
            <h4>
              <b>No data!</b>
            </h4>
          </div>
          <div>
            <h5>There are no data to show yet. Please check back later</h5>
          </div>
        </div>
      ) : null}

      <SettingsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <GeneratePayRequest
        isModalVisible={showGeneratePayReq}
        setIsModalVisible={setShowGeneratePayReq}
        setShowAddDetailsModal={setShowAddDetailsModal}
        showStudents={true}
        setSelectedBtn={setSelectedBtn}
        setIsAdditionModal={setIsAdditionModal}
        selectedBtn={selectedBtn}
        showReasonModal={showReasonModal}
        setShowReasonModal={setShowReasonModal}
      />
      {showReasonModal &&
        (<ShowReasonModal
          showReasonModal={showReasonModal}
          setShowReasonModal={setShowReasonModal}
          kycStatus={feeMangementData?.dashBoardDetails?.kycStatus}
          rejectKycMessage={feeMangementData?.dashBoardDetails?.rejectKycMessage ? feeMangementData?.dashBoardDetails?.rejectKycMessage : ''}
        />)}
      {showAddDetailsModal && (
        <AddDetailsModal
          isModalVisible={showAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          isAddOrGenModal={isAddOrGenModal}
          isAdditionModal={isAdditionModal}
          handleOk={() => {
            setShowAddDetailsModal(false);
            if (isAddOrGenModal == true) {
              setShowOfflinePayReqModal(true);
              setIsAddOrGenModal(false);
            } else {
              setShowGeneratePayReq(true);
              setIsAddOrGenModal(false);
            }
          }}
          handleCancel={() => {
            setShowAddDetailsModal(false);
            if (isAddOrGenModal == true) {
              setShowOfflinePayReqModal(true);
              setIsAddOrGenModal(false);
            } else {
              setShowGeneratePayReq(true);
              setIsAddOrGenModal(false);
            }
          }}
          setShowGeneratePayReq={setShowGeneratePayReq}
        />
      )}
      {showOfflinePayReqModal && (
        <OfflinePayReq
          isModalVisible={showOfflinePayReqModal}
          setIsModalVisible={setShowOfflinePayReqModal}
          setShowAddDetailsModal={setShowAddDetailsModal}
          setIsAdditionModal={setIsAdditionModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
        />
      )}
      <PageDataLoader visible={pageLoader} />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { selectedStd, feeMangementData, overviewFilter } = state.feeManagement;
  return {
    selectedStd,
    feeMangementData,
    overviewFilter
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedStd: (data) => dispatch(setSelectedStd(data)),
  setOverviewFilter: (key, res) => dispatch(setOverviewFilter(key, res)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setFeeDetails: (key, res) => dispatch(setFeeDetails(key, res)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassFeeDashBoard);