import { ArrowLeftOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import { connect } from 'react-redux';
import { fetchSampleQuestionPaperQuestions, importSampleQuestionPaper, setImportMeta, showGenerateQuesListView } from 'actions/practiseTest';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from 'react-icons/ri';
import { Notification } from 'services';
import Loader from './localComponents/loader';
import PageDataLoader from 'components/pageDataLoader';


const PageHeader = ({ goBack, onSearch, searchVal, selectedQBtype, selectedClassname, selectedSamplePaper, count, onImport, importing}) => {
  return (
      <div className="r-jsb full-width">
        <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
          <div className="text-md bold-700 m-l-20"> 
            <div>
              {selectedSamplePaper}
            </div>
            <div style={{color:"#AEAEAE", fontSize:12}}>
            {selectedQBtype} / {selectedClassname}
            </div>
          </div>
        </div>
        <div className="">
          <Button disabled={importing} onClick={onImport} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
            {importing? <LoadingOutlined/>: `IMPORT (${count?count:0})` } 
          </Button>
        </div>
      </div>
    );
  };



const QuestionSection = ({QuestionTree, importMeta, setImportMeta}) => {
    const QuestionSectionHeader = () => {
      return (
        <div>
          <div></div>
        </div>
      )
    }

    const QuestionCard = ({data, index}) => {
      return (
        <div style={{backgroundColor:"#FFFFFF"}} className="p-10 full-width customCardShadow shadow-box hover-shadow">
                          <div className='r-jsb'>
                            <div className='display-flex flex-1'>
                              <div className='text-xmd bold-600 color-black m-r-5'>{index+1}.</div>
                              <div className='text-xmd color-black resize-editor-img'>
                                    {ReactHtmlParser(data.statement,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                              </div>
                            </div>
                            <div className="text-xs" style={{color:"gray"}}>Difficulty: {data.difficulty} | Maximum marks: {data.positiveMarks??0} {' | '} Minimum Marks: {data.negativeMarks?`-${data.negativeMarks}`:0}</div>
                          </div>
                          {data.type === 'Objective'
                          ?
                            <div className='m-t-10' style={{marginLeft:-20}}>
                              {data.options?.map((opt, i) => (
                                <div keys={opt.id} className='r-ac m-b-10 m-l-20' >
                                  <div
                                    style={{background:opt.correct ? '#28df99' : '', color:opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : ''}}
                                    className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                    {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                                  </div>
                                  <div
                                    style={{background:opt.correct ? '#E9FCF5' : ''}}
                                    className='test-ques-window p-10 r-c-sb flex-1'>
                                    <div className='flex-1 resize-editor-img'>
                                    {ReactHtmlParser(opt.value,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                                    </div>
                                    <div className='r-ac m-l-5'>
                                      {opt.correct
                                      ?
                                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                                      :
                                        <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                      }
                                      <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          : data.type === 'Numeric'
                            ?
                              <div className='m-t-10'>
                                <div className='text-xmd bold-600 color-black'>Answer</div>
                                <div className='test-ques-window resize-editor-img p-10' style={{border:"none"}}>
                                  {ReactHtmlParser(data.answer)}
                                </div>
                              </div>
                            : null
                          }
                          {(data.solution !== '' && data.solution !== '<p><br></p>')
                          ?
                            <div className='m-t-20'>
                              <div className='text-xmd bold-600 m-b-10 display-flex' style={{color:"#594099"}}>
                                <div> <FileTextOutlined /> </div>
                                <div className="m-l-10">{'   '}Solution</div>
                                </div>
                              <div className='test-ques-window resize-editor-img p-10' style={{border:"none"}}>
                                    {ReactHtmlParser(data.solution,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                              </div>
                            </div>
                          :
                            null
                          }
        </div>
      )
    }
    const handleQuestionSelect = (selection)=>{
      if (selection?.length===(importMeta?.sampleQuestionTree.length)){
        setImportMeta('sampleIndividualAllSelectBool' , true);
      }else{
        setImportMeta('sampleIndividualAllSelectBool' , false);
      }
      setImportMeta('selectedSampleQuestions', selection);
    }
    const QuestionSelection= () => {
      return (
        <Checkbox.Group className="flex-column full-width" 
        onChange={handleQuestionSelect} value={importMeta?.selectedSampleQuestions}
        >
        { QuestionTree?.map(
              (item, index) => (
                <Checkbox className="black p-10 m-l-0 full-width"
                          value={item.questionId}>
                    <QuestionCard data={item} index={index} />
                </Checkbox>
              )
            )
          }
      </Checkbox.Group>
      )
    }
    return (
        <div style={{width:"100%"}} className="full-height m-l-20 ques-import">
          <QuestionSectionHeader/>
          <QuestionSelection/>
        </div>
    )
}


function PractiseSampleQuesPaper({setImportMeta, importMeta, fetchSampleQuestionPaperQuestions, importSampleQuestionPaper, loaders, showGenerateQuesListView}) {

    useEffect(()=>{
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY= "hidden";
      fetchSampleQuestionPaperQuestions((response)=>{
        const allSelection = response?.map((item)=>item.questionId);
        setImportMeta('sampleIndividualAllSelectBool' , false);
        setImportMeta('selectedSampleQuestions', allSelection);
      });
      return ()=>{
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY= "auto";
      }
    }, []);
    const history = useHistory();
    const goBack = () =>{
        history.goBack();
    }
    const onImport = () => {
      if (loaders?.sampleQuesLoader){
        return 
      }
      if (!importMeta || !importMeta.selectedSampleQuestions || importMeta.selectedSampleQuestions?.length===0){
        Notification.error("Error", "Please select questions for importing");
      }else{
        importSampleQuestionPaper((response)=>{
          if (response.show?.type==='success'){
            showGenerateQuesListView(()=>{
              history.push("practiseTest-questionScreen")
            },'import');
          }
        });
      }
    }
    const onSelectAll = (e) => {
      const allSelection = importMeta.sampleQuestionTree?.map((item)=>item.questionId);
      if (e.target.checked===true){
        setImportMeta('sampleIndividualAllSelectBool' , true);
        setImportMeta('selectedSampleQuestions', allSelection);
      }else{
        setImportMeta('sampleIndividualAllSelectBool' , false);
        setImportMeta('selectedSampleQuestions', []);
      }
    }
  return (
    <div style={{padding:15, margin:-20, height:"calc(100% + 40px)", width:"calc(100% + 30px)", overflow:"hidden", backgroundColor:"#FAFAFA"}}>
        <PageHeader selectedClassname={`${importMeta?.selectedStandardObj?.name} - ${importMeta?.selectedStandardObj?.stream===''?'NA':importMeta?.selectedStandardObj?.stream}`} 
                    selectedQBtype={'Sample Question Papers'} 
                    selectedSamplePaper={importMeta?.sampleQuestionPaperObj?.questionPaperName} 
                    goBack={goBack} 
                    count={importMeta?.selectedSampleQuestions?.length} 
                    onImport={onImport}
                    importing={loaders?.importingSampleQuesLoader}/>
          {
            loaders?.sampleQuesLoader
            ?
            <Loader style={{fontSize:40}}/>
            :
            <>
            <div className="display-flex m-t-20 p-20">
                <Checkbox onChange={onSelectAll} checked={importMeta?.sampleIndividualAllSelectBool}></Checkbox>
                <div className="m-l-15">Select all</div>
            </div>
            <div style={{padding:20, paddingTop:0}} className="full-width sample-ques-import" style={{height:"80vh", overflowY:"auto", overflowX:"hidden", paddingBottom:50}}>
                <QuestionSection QuestionTree={importMeta?.sampleQuestionTree} 
                                importMeta={importMeta} 
                                setImportMeta={setImportMeta}/>
            </div>
            </>
          }
          <PageDataLoader visible={loaders?.importingSampleQuesLoader}/>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.practiseTest;
  return {  
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) =>  ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchSampleQuestionPaperQuestions: (callback) => dispatch(fetchSampleQuestionPaperQuestions(callback)),
  importSampleQuestionPaper: (callback) => dispatch(importSampleQuestionPaper(callback)),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type))
})


export default connect(mapStateToProps, mapDispatchToProps)(PractiseSampleQuesPaper)
