import {
    CREATE_LEAVE_LIST,
    STORE_CREATE_LEAVE_MODAL_DATA,
    CLEAR_LEAVE_OBJ, EDIT_LEAVE_LIST,
    STORE_EDIT_LEAVE_MODAL_DATA,
    CLEAR_LEAVE_UPDATE, SET_LEAVE_LIST,
    DELETE_LEAVE_LIST, SET_DELETE_LOADER,
    SET_LEAVE_PAGE_LOADER,
    SET_CREATE_LOADER,
    LEAVE_REQUEST_DATA,
    SEARCH_FILTER,
    ROLE_FILTER,
    SET_ROLE_FILTER,
    TYPE_FILTER,
    SET_CARD_ID,
    SET_ACTION_DATA,
    SET_STATUS_DATA,
    TEACHERS_GEOFENCE_DATA,
    TEACHERS_GEOFENCE_LOC_DATA,
    STORE_ALLOCATED_TYPE,
    STORE_START_DATE,
    STORE_END_DATE,
    DELETE_CARD,
    SET_HOLIDAY_LIST,
    SET_HOLIDAY_LOCATION,
    SET_HOLIDAY_OVERVIEW,
    SET_CREATE_HOLIDAY,
    STORE_CREATE_HOLIDAY,
    STORE_HOLIDAY_CHECKBOX,
    STORE_SAME_MINIMUM_ATT,
    STORE_ATTENDANCE_DASH_BOARD_DATA,
    STORE_IMPORT_HOLIDAY,
    SET_APPROVAL_ID,
    IMPORT_HOLIDAY,
    SAVE_HOLIDAY_LIST,
    STORE_HOLIDAY_LIST,
    STORE_EDIT_HOLIDAY,
    HOLIDAY_PAGE_LOADER,
    HOLIDAY_CREATE_LOADER,
    ALL_ATTENDANCE_REQUIREMENT,
    STORE_ATTENDANCE_HOURS_MINUTES,
    STORE_ATTENDANCE_TEACHER,
    STORE_ATTENDANCE_DATA_OPERATOR,
    STORE_ATTENDANCE_OPERATION,
    STORE_ATTENDANCE_MIN_REQUIREMENT,
    STORE_ATTENDANCE_MAX_REQUIREMENT_TEACHER, STORE_ATTENDANCE_MAX_REQUIREMENT_DATA_OPERATOR,
    STORE_ATTENDANCE_MAX_OPERATION,
    STORE_ATTENDANCE_MAX_STUDENT,
    STORE_WORKING_DAYS,
    REQUIREMENT_PAGE_LOADER,
    STORE_ALL_ATTENDANCE_DETAILS,
    // STORE_ATTENDANCE_HOURS_MIN,
    ATTENDANCE_REQUIREMENT_SAVE,
    STUDENT_OVERVIEW,
    STORE_STUDENT_ID_DATA,
    SET_STUDENT_DASHBOARD_DATA,
    SET_CLASS_DASHBOARD_DATA,
    SET_ALLSTUDENTS_VA,
    UPDATE_FILTER,
    FETCH_CLASS_CARD_ID,
    STORE_USERS_DASH_BOARD_DATA,
    STORE_PREVILEGED_VIEW_DATA,
    STORE_USERS_NAME,
    STORE_LEAVES_TAB_DATA,
    STORE_REQUESTED_USER_ID,
    STORE_TAB_DATA,
    STORE_SELECTED_MONTH,
    STORE_SELECTED_RANGE,
    STUDENT_PAGE_LOADER,
    STUDENT_SUBJECTS,

    UPDATE_GEO_FENCE_LIST,
    UPDATE_SEARCH_LOCATION,
    UPDATE_RADIUS,
    UPDATE_GEOMETRY_LOCATION,
    UPDATE_USER_LIST,
    UPDATE_LOCATION_LIST,
    UPDATE_ATTENDANCE_DATA,
    LOCATION_CARD_DATA,
    STORE_ATTENDANCE_PROFILE_DATA,
    /* ATTENDANCE_PROFILE_TAB, */
    SELECTED_LEAVES,
    SELECTED_ATTENDANCE,
    SET_LOCATION,
    SET_LEAVE_REQUEST,
    SET_MARK_ATTENDANCE,
    SET_LOADER,
    ATTENDANCE_TABLE_DATA,
    TAB,
    SET_EDIT_LEAVE,
    EDIT_LEAVE_CARD_ID,
    SET_LOCATION_CARD_TITLE,
    SET_FILTER_VALUES,
    STORE_MONTH,
    STORE_STUDENT_SUBJECTS,
    START_DATE,
    END_DATE,
    STORE_USERS_SUMMARY_GRAPH_DATA,
    MONTH_OR_WEEK,
    STORE_SELECTED_SUBJECT,
    STORE_ATTENDANCE_DETAILS_MONTH,
    ATTENDANCE_DETAILS_MODAL_DATA,
    STORE_USER_SUMMARY_START_DATE,
    STORE_USER_SUMMARY_END_DATE,
    STORE_SUMMARY_TYPE,
    STORE_SUMMARY_YEAR,
    STORE_WORK_SUMMARY_TYPE,
    STORE_WORK_SUMMARY_YEAR,
    STORE_WORK_SUMMARY_START_DATE,
    STORE_WORK_SUMMARY_END_DATE,
    TRACK_SELECTED_RADIO,
    STORE_SUMMARY_END_DATE,
    STORE_SUMMARY_START_DATE,
    // STORE_USERS_SUMMARY_GRAPH_DATA,
    // MONTH_OR_WEEK,
    // STORE_SELECTED_SUBJECT,
    TEACHER_HALF_DAY_ATTENDANCE,
    DO_HALF_DAY_ATTENDANCE,
    OPERATION_HALF_DAY,
    ATTENDANCE_STUDENT_OVERVIEW,
    SELECTED_MONTH,
    STORE_SUBJECT_DETAIL_MODAL,
    STUDENT_SUMMARY_DETAIL,
    STUDENT_END_DATE,
    STUDENT_START_DATE,
    MONTHLY_SUMMARY,
    SUBJECT_SUMMARY,
    SET_BACK_TO_PRIVILEGED_VIEW,
    STORE_SELECTED_DROPDOWN_MONTH,
    CHECK_STORE,
    UNCHECKED_VALUE,
    SET_CLICKED_ON_USER_CARD,
    STORE_PROFILETAB_MONTH_SELECT,
    FETCH_MONTH_FOR_ATTDETAILS,
    SET_BUTTON_DIV_VISIBLE,
    ID_SELECTED,
    SET_REQUESTED_INDEX,
    REQUEST_VIEW_ALL_CLICKED,
    STORE_MONTHLY_SELECTED_MONTH,
    STORE_REQUEST_VIEW_ALL_PAGE,
    STORE_OCC_ID,
    STORE_STUD_STATS,
    SET_FROM_USERS_GEO_FENCE,
    STORE_EDITED_GEO_FENCE_DATA,
    STORE_GEO_FENCE_USERID,
    FETCH_STANDARDS,
    STORE_SELECTED_CLASS,
    SET_ATTENDNCE_OVERVIEW,
    STORE_STAFF_LEAVE_DATE,
    SET_LEAVE_OVERVIEW,
    SET_GRAPH_DATA,
    HALF_DAY_STUDENT,
    FULL_DAY_STUDENT,
    STORE_USER_DETAILS,
    STORE_NUMBER_OF_DAYS,
    SET_STORE_LEAVE_IDS,
    SET_SAME_HALF_ATT,
    SET_SAME_MAXIMUM_ATT,
    GET_WEB_CLOCK_IN_DATA,
    SET_CLOCK_IN_SWITCH,
    SET_CLOCK_IN_END_DATE,
    SET_CLOCK_IN_START_DATE,
    GET_REMAINDER_DATA,
    STUDENTS_LIST,
    LEAVE_OVERVIEW,
}
    from "../actions/attendanceNew"
import moment from "moment";
// import {
//     LEAVE_REQUEST_DATA,
//     SEARCH_FILTER, 
//     ROLE_FILTER, 
//     TYPE_FILTER,
//     SET_CARD_ID,
//     SET_ACTION_DATA, 
//     SET_STUDENT_DASHBOARD_DATA,
//     SET_CLASS_DASHBOARD_DATA,
//     SET_ALLSTUDENTS_VA,
//     UPDATE_FILTER,
//     FETCH_CLASS_CARD_ID,
// } from "../actions/attendanceNew"{
//     STORE_USERS_DASH_BOARD_DATA,
//     STORE_PREVILEGED_VIEW_DATA,
//     STORE_USERS_NAME,
//     STORE_LEAVES_TAB_DATA,
//     STORE_REQUESTED_USER_ID,
//     STORE_TAB_DATA,
//     STORE_SELECTED_MONTH,
//     STORE_SELECTED_RANGE
// }
//  from "../actions/attendanceNew"
// import {
//     LEAVE_REQUEST_DATA,SEARCH_FILTER, ROLE_FILTER, TYPE_FILTER,SET_CARD_ID,SET_ACTION_DATA} from "../actions/attendanceNew"


const initialState = {
    checkedValue: [],
    values: false,
    createLeaveObj: { leaveName: '', noOfDays: '', startDate: '', endDate: '', carryForward: false, halfDayApplicable: false, backDays: false, totalBackDays: '' },
    editLeaveObj: { leaveName: '', noOfDays: '', startDate: '', endDate: '', id: '', carryForward: false, halfDayApplicable: false, backDays: false, totalBackDays: '' },
    setAllLeaves: [],
    deleteCardId: null,
    deleteLoader: false,
    createLoader: false,
    holidayCreateLoader: false,
    attendanceSaveLoader: false,
    leaveRequestDataContainer: {},
    searchFilterContainer: "",
    roleFilterContainer: [],
    typeFilterContainer: [],
    cardId: 0,
    actionDataContainer: [],
    setAllHolidayLoc: [],
    importHolidayListNew: null,
    holidayImportList: { id: '' },
    holidayCheckBox: [],
    saveHolidayListDetailNew: { dates: '' },
    saveHolidayListDetail: { dates: '' },
    editHolidayValue: false,
    getattendanceRequirement: {
        id: '',
        workingDays: [],
        minAttendancePercentageStudent: '',
        minAvgWorkingHrForStaff: '',
        minWorkingHrForFullDayDO: '',
        minWorkingHrForFullDayOperation: '',
        minWorkingHrForFullDayTeacher: '',
        minWorkingHrForHalfDayDO: '',
        minWorkingHrForHalfDayOperation: '',
        minWorkingHrForHalfDayTeacher: '',
        minWorkingHrForFullDayStudent: '',
        minWorkingHrForHalfDayStudent: '',

        requestCountDO: '',
        requestCountOperation: '',
        requestCountTeacher: '',
        requestCountStudent: '',

        // totalWorkingHourStaff: null,
        // minAttendancePercentageTeacher: null,
        // minAttendancePercentageDO: null,
        // minAttendancePercentageOperation: null,
        // minAttendancePercentageStudent: null,
        // requestCountTeacher: null,
        // requestCountDO: null,
        // requestCountOperation: null,
        // updatedAt: '',
        // createdAt: ''
    },
    storeWorkingDaysDetails: [],
    staffAvgWorkingHrs: { hours: '', minutes: '' },
    attendanceTeacherHrsMinutes: { hour: '', minute: '' },
    storeAttendanceDataOperator: { doHour: '', doMinute: '' },
    storeAttendanceOperation: { opHour: '', opMinute: '' },
    teacherHalfDay: { tHour: '', tMinute: '' },
    storeFullDayStudent: { sHour: '', sMinute: '' },
    storeHalfDayStudent: { stHour: '', stMinute: '' },
    DoHalfDay: { dHour: '', dMinute: '' },
    operationHalfDay: { oHour: '', oMinute: '' },
    storeAttendanceMin: null,
    storeMaxTeacher: null,
    storeMaxDataOperater: null,
    storeMaxOperations: null,
    storeMaxStudentData: null,
    storeAttendanceMinute: null,
    storeClasscardId: { id: '' },
    studentDashboardData: {},
    classDashboardData: { todaysAttendance: {}, subjects: [], students: [], classname: '' },
    VAllStudentsData: { items: [], meta: {} },
    filter: {
        indexSearch: null,
        VAStudentsSearch: ''
    },
    studentOverviewDetail: { PresentCount: '', AbsentCount: '', overallPercentage: '' },
    storeStudentId: [],
    storeSelectingMonth: '',
    storeSubjectDetails: [],
    subjectDetailModal: [],
    // clearHolidayList: {title: '', date: '', id: ''}
    statusDataContainer: [],
    teachersGeofenceDataContainer: [],
    teachersGeofenceLocDataContainer: [],
    allocatedTypeContainer: "",
    startDateContainer: [],
    endDateContainer: [],
    selectedCard: [],
    setAllHolidayList: [],
    setAllHolidayOverview: {},
    createHolidayList: { title: '', date: '', id: '' },
    createHoliday: { title: '', date: '', id: '' },
    // subjectDetailOfStudent:[],

    usersDataContainer: [],
    // teachersDataContainer:{},
    // operationsDataContainer:{},
    previlegedViewDataContainer: {},
    usersNameContainer: "",
    filterRole: "",
    leavesTabDataContainer: [],
    requestedUserIdContainer: [],
    tabDataContainer: "",
    selectedMonthContainer: "",
    selectedRangeContainer: "",
    geoFenceLocationList: [],
    searchLocation: '',
    radius: null,
    geometryLocation: {},
    userList: [],
    locationList: [],
    attendanceData: {},
    locationCardId: null,
    attendanceDataContainer: {},
    AttendanceTab: [],
    attendanceLocation: { latitude: null, longitude: null },
    profileTab: [],
    storeSelectedLeaves: {},
    storeselectedAttendance: {},
    markAttendanceObj: { requestType: 'Attendance', startDate: null, clockIn: null, clockOut: null, reason: null, totalhours: null },
    leaveRequestObj: { requestType: 'Leave', leaveType: '', startDate: '', endDate: '', reason: '', noOfdays: '' },
    editLeaveMeta: { id: '', startDate: '', endDate: '', noOfdays: '', reason: '', leaveType: '', requestType: 'Leave' },
    editLeaveCardID: { id: '' },
    loaders: {
        modalClockLoader: false,
        modalLeaveReq: false,
        modalEditLeave: false,
        deleteEditLeave: false,
        weekLoader: false,
    },
    attendanceTable: {},
    tab: "1",
    locationCardTitle: null,
    filterValues: {},
    usersSummaryGraphDataContainer: [],
    selectedImportRadio: null,
    // usersSummaryGraphDataContainer:[]
    // usersSummaryGraphDataContainer:[]
    startDate: '',
    endDate: '',
    isWeekOrMonth: '',
    storeSubject: [],
    usersSummaryGraphDataContainer: [],
    attendanceDetailsMonthContainer: "",
    attendanceDetailsContainer: [],
    summaryStartDateContainer: "",
    summaryEndDateContainer: "",
    summaryTypeContainer: "",
    summaryYearContainer: "",
    workSummaryTypeContainer: "",
    workSummaryYearContainer: "",
    workSummaryStartDateCont: "",
    workSummaryEndDateCont: "",
    summaryEndDate: "",
    summaryStartDate: "",
    myAttendanceStudentDashBoard: {},
    studentSelectMonth: '',
    summaryOfStudent: {},
    studentattStartDate: '',
    studentattEndDate: '',
    studentMonthlySummary: '',
    studentSubjectSummary: '',
    storeBackToPrivilegedView: false,
    storeIsClickedOnUserCard: false,
    storeProfileTabMonthSelect: "",
    fetchMonthAttDetails: "",
    isButtonDivVisible: { c1: false, c2: false, c3: false, c4: false },
    idSelectedStore: {},
    storeRequestedIndex: "",
    viewAllClickedData: false,
    storeMonthlySummaryMonth: '',
    pageNumber: 1,
    OccurenceId: '',
    storeStudsStats: [],
    fromUsersGeoFence: false,
    editedGeoFenceData: [],
    userIdData: '',
    standardsData: [],
    selectedClassId: null,
    storeAttendanceLeaveOverview: [],
    storeLeaveDate: "",
    locationDetailsContainer: {},
    storeNoOfDay: '',
    storeLeaveId: [],
    sameMinimumAtt: false,
    sameHalfAtt: false,
    sameMaximumAtt: false,
    storeClockInData: '',
    storeClockInStartDate: moment().format('YYYY-MM-DD'),
    storeClockInEndDate: moment().format('YYYY-MM-DD'),
    storeClockInSwitch: false,
    storeRemainderData: '',

    /* NEW ATTENDANCE V3 */
    allStudentsList: {},
    storeApprovalId: 0,
    leavesOverviewOfStaff: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LEAVE_LIST:
            return {
                ...state,
                setAllLeaves: action.res,
            }

        case TAB:
            return {
                ...state,
                tab: action.val,
            }

        case EDIT_LEAVE_LIST:
            return {
                ...state,
                editLeave: action.res,
            }

        case STORE_EDIT_LEAVE_MODAL_DATA:
            let editLeaveObjNew = { ...state.editLeaveObj };
            if (action.key === 'leaveName') {
                editLeaveObjNew.leaveName = action.value;
            }
            else if (action.key === 'noOfDays') {
                editLeaveObjNew.noOfDays = action.value;
            }
            else if (action.key === 'startDate') {
                editLeaveObjNew.startDate = action.value;
            }
            else if (action.key === 'endDate') {
                editLeaveObjNew.endDate = action.value;
            }
            else if (action.key === 'id') {
                editLeaveObjNew.id = action.value;
            }
            else if (action.key === 'carryForward') {
                editLeaveObjNew.carryForward = action.value;
            }
            else if (action.key === 'halfDayApplicable') {
                editLeaveObjNew.halfDayApplicable = action.value;
            }
            else if (action.key === 'backDays') {
                editLeaveObjNew.backDays = action.value;
            }
            else if (action.key === 'totalBackDays') {
                editLeaveObjNew.totalBackDays = action.value;
            }
            return {
                ...state,
                editLeaveObj: { ...editLeaveObjNew }
            }

        case CLEAR_LEAVE_UPDATE:
            return {
                ...state,
                editLeaveObj: { leaveName: '', noOfDays: '', startDate: '', endDate: '', carryForward: false, halfDayApplicable: false }
            }

        case SET_DELETE_LOADER:
            return {
                ...state,
                deleteLoader: action.bool,
            }

        case REQUIREMENT_PAGE_LOADER:
            return {
                ...state,
                attRequirementPageLoader: action.bool
            }

        case SET_LEAVE_PAGE_LOADER:
            return {
                ...state,
                leavePageLoader: action.bool,
            }

        case HOLIDAY_PAGE_LOADER:
            return {
                ...state,
                holidayPageLoader: action.bool,
            }

        case STUDENT_PAGE_LOADER:
            return {
                ...state,
                studentPageLoader: action.bool,
            }

        case SET_CREATE_LOADER:
            return {
                ...state,
                createLoader: action.bool,
            }

        case HOLIDAY_CREATE_LOADER:
            return {
                ...state,
                holidayCreateLoader: action.bool,
            }

        case ATTENDANCE_REQUIREMENT_SAVE:
            return {
                ...state,
                attendanceSaveLoader: action.bool,
            }

        case CREATE_LEAVE_LIST:
            return {
                ...state,
                createleave: action.res,
            }

        case STORE_CREATE_LEAVE_MODAL_DATA:
            let createLeaveObjNew = { ...state.createLeaveObj };
            if (action.key === 'leaveName') {
                createLeaveObjNew.leaveName = action.val;
            }
            else if (action.key === 'noOfDays') {
                createLeaveObjNew.noOfDays = action.val;
            }
            else if (action.key === 'startDate') {
                createLeaveObjNew.startDate = action.val;
            }
            else if (action.key === 'endDate') {
                createLeaveObjNew.endDate = action.val;
            }
            else if (action.key === 'carryForward') {
                createLeaveObjNew.carryForward = action.val;
            }
            else if (action.key === 'halfDayApplicable') {
                createLeaveObjNew.halfDayApplicable = action.val;
            }
            else if (action.key === 'backDays') {
                createLeaveObjNew.backDays = action.val;
            }
            else if (action.key === 'totalBackDays') {
                createLeaveObjNew.totalBackDays = action.val;
            }

            return {
                ...state,
                createLeaveObj: { ...createLeaveObjNew }
            }

        case CLEAR_LEAVE_OBJ:
            return {
                ...state,
                createLeaveObj: { leaveName: '', noOfDays: '', startDate: '', endDate: '', carryForward: false, halfDayApplicable: false }
            }

        case DELETE_LEAVE_LIST:
            return {
                ...state,
                deleteCardId: action.value
            }

        case LEAVE_REQUEST_DATA:
            return {
                ...state,
                leaveRequestDataContainer: action.data
            }

        case SEARCH_FILTER:
            return {
                ...state,
                searchFilterContainer: action.val
            }

        case ROLE_FILTER:
            return {
                ...state,
                roleFilterContainer: action.val
            }
        case SET_ROLE_FILTER:
            return {
                ...state,
                filterRole: action.val
            }

        case TYPE_FILTER:
            return {
                ...state,
                typeFilterContainer: action.val
            }

        case SET_CARD_ID:
            return {
                ...state,
                cardId: action.id
            }

        case SET_ACTION_DATA:
            return {
                ...state,
                actionDataContainer: action.val
            }

        case SET_STATUS_DATA:
            return {
                ...state,
                statusDataContainer: action.val
            }

        case TEACHERS_GEOFENCE_DATA:
            return {
                ...state,
                teachersGeofenceDataContainer: action.val
            }

        case TEACHERS_GEOFENCE_LOC_DATA:
            return {
                ...state,
                teachersGeofenceLocDataContainer: action.val
            }

        case STORE_ALLOCATED_TYPE:
            return {
                ...state,
                allocatedTypeContainer: action.val
            }

        case STORE_START_DATE:
            return {
                ...state,
                startDateContainer: action.val
            }

        case STORE_END_DATE:
            return {
                ...state,
                endDateContainer: action.val
            }

        case DELETE_CARD:
            let deleteNew = { ...state.selectedCard }
            deleteNew[action.key] = action.val;
            return {
                ...state,
                selectedCard: deleteNew
            }

        case SET_HOLIDAY_LIST:
            return {
                ...state,
                setAllHolidayList: action.res
            }

        case SET_HOLIDAY_LOCATION:
            return {
                ...state,
                setAllHolidayLoc: action.res
            }

        case SET_HOLIDAY_OVERVIEW:
            return {
                ...state,
                setAllHolidayOverview: action.res
            }

        case SET_CREATE_HOLIDAY:
            return {
                ...state,
                createHoliday: action.res
            }

        case STORE_CREATE_HOLIDAY:
            let createHolidayListObj = { ...state.createHolidayList };
            if (action.key === 'title') {
                createHolidayListObj.title = action.val;
            }
            else if (action.key === 'date') {
                createHolidayListObj.date = action.val;
            }
            else if (action.key === 'id') {
                createHolidayListObj.id = action.value;
            }

            return {
                ...state,
                createHolidayList: { ...createHolidayListObj }
            }

        case STORE_HOLIDAY_CHECKBOX:
            return {
                ...state,
                holidayCheckBox: action.val
            }

        case STORE_ATTENDANCE_DASH_BOARD_DATA:
            return {
                ...state,
                attendanceDataContainer: action.val
            }

        case IMPORT_HOLIDAY:
            return {
                ...state,
                holidayImportList: action.res

            }

        case STORE_IMPORT_HOLIDAY:
            return {
                ...state,
                importHolidayListNew: action.value
            }
        case SET_APPROVAL_ID:
            return {
                ...state,
                storeApprovalId: action.val
            }

        case SAVE_HOLIDAY_LIST:
            return {
                ...state,
                saveHolidayListDetail: action.res
            }

        case STORE_HOLIDAY_LIST:
            return {
                ...state,
                saveHolidayListDetailNew: action.value
            }

        case STORE_EDIT_HOLIDAY:
            return {
                ...state,
                editHolidayValue: action.val
            }

        case ALL_ATTENDANCE_REQUIREMENT:
            return {
                ...state,
                getattendanceRequirement: action.res
            }

        // case STORE_ATTENDANCE_HOURS_MINUTES:
        // return{
        //     ...state,
        //     storeAttendanceDetail:action.value
        // }

        // case STORE_ATTENDANCE_TEACHER:
        // return{
        //     ...state,
        //     storeAttendanceRequirementVal:action.value
        // }

        case STORE_ATTENDANCE_DATA_OPERATOR:
            return {
                ...state,
                storeAttendanceDataOperator: action.value
            }

        case STORE_ATTENDANCE_OPERATION:
            return {
                ...state,
                storeAttendanceOperation: action.value
            }

        case STORE_ATTENDANCE_MIN_REQUIREMENT:
            return {
                ...state,
                storeAttendanceMin: action.value
            }

        case STORE_ATTENDANCE_MAX_REQUIREMENT_TEACHER:
            return {
                ...state,
                storeMaxTeacher: action.value
            }

        case STORE_ATTENDANCE_MAX_REQUIREMENT_DATA_OPERATOR:
            return {
                ...state,
                storeMaxDataOperater: action.value
            }

        case STORE_ATTENDANCE_MAX_OPERATION:
            return {
                ...state,
                storeMaxOperations: action.value
            }
        case STORE_ATTENDANCE_MAX_STUDENT:
            return {
                ...state,
                storeMaxStudentData: action.value
            }

        case STORE_USERS_DASH_BOARD_DATA:
            return {
                ...state,
                usersDataContainer: action.val
            }

        case STORE_PREVILEGED_VIEW_DATA:
            return {
                ...state,
                previlegedViewDataContainer: action.val
            }

        // case STORE_USERS_NAME:
        //     return {
        //         ...state,
        //         usersNameContainer: action.val
        //     }

        // case STORE_LEAVES_TAB_DATA:
        //     return {
        //         ...state,
        //         leavesTabDataContainer: action.val
        //     }

        case STORE_REQUESTED_USER_ID:
            return {
                ...state,
                requestedUserIdContainer: action.val
            }

        case STORE_TAB_DATA:
            return {
                ...state,
                tabDataContainer: action.val
            }

        // case STORE_SELECTED_MONTH:
        // return{
        //     ...state,
        //     selectedMonthContainer:action.val
        // }

        case STORE_SELECTED_RANGE:
            return {
                ...state,
                selectedRangeContainer: action.val
            }

        case STORE_WORKING_DAYS:
            return {
                ...state,
                storeWorkingDaysDetails: action.value
            }
        case HALF_DAY_STUDENT:
            return {
                ...state,
                storeHalfDayStudent: action.val
            }
        case FULL_DAY_STUDENT:
            return {
                ...state,
                storeFullDayStudent: action.val
            }


        // case STORE_ATTENDANCE_HOURS_MIN:
        //     return{
        //         ...state,
        //         storeAttendanceMinute:action.value
        //     }

        // case STUDENT_SUBJECTS:
        //     return{
        //         ...state,
        //         subjectDetailOfStudent:action.res
        //     }

        // case CLEAR_CREATE_HOLIDAY: 
        // return{
        //     ...state,
        //     createHolidayList:{id:'', title: '', date: ''}
        // }

        /*     case  ATTENDANCE_PROFILE_TAB:
        return{
        ...state,
        profileTab:action.val
        }  */

        case FETCH_CLASS_CARD_ID:
            return {
                ...state,
                storeClasscardId: action.id
            }

        case SET_STUDENT_DASHBOARD_DATA:
            return {
                ...state,
                studentDashboardData: action.data
            }

        case SET_CLASS_DASHBOARD_DATA:
            return {
                ...state,
                classDashboardData: action.data
            }

        case SET_ALLSTUDENTS_VA:
            return {
                ...state,
                VAllStudentsData: action.data
            }

        case UPDATE_FILTER:
            let newfilter = { ...state.filter }
            newfilter[action.key] = action.val;
            return {
                ...state,
                filter: newfilter
            }

        case STUDENT_OVERVIEW:
            return {
                ...state,
                studentOverviewDetail: action.res
            }

        case STORE_STUDENT_ID_DATA:
            return {
                ...state,
                storeStudentId: action.val
            }

        case UPDATE_GEO_FENCE_LIST:
            return {
                ...state,
                geoFenceLocationList: [...action.data]
            }

        case UPDATE_SEARCH_LOCATION:
            return {
                ...state,
                searchLocation: action.data
            }

        case UPDATE_RADIUS:
            return {
                ...state,
                radius: action.data
            }

        case UPDATE_GEOMETRY_LOCATION:
            return {
                ...state,
                geometryLocation: action.object
            }

        case UPDATE_USER_LIST:
            return {
                ...state,
                userList: [...action.data]
            }

        case UPDATE_LOCATION_LIST:
            return {
                ...state,
                locationList: [...action.data]
            }

        case UPDATE_ATTENDANCE_DATA:
            return {
                ...state,
                attendanceData: {
                    ...state.attendanceData,
                    [action.key]: action.value
                }
            }
        case LOCATION_CARD_DATA:
            return {
                ...state,
                locationCardId: action.value
            }

        case STORE_ATTENDANCE_PROFILE_DATA:
            return {
                ...state,
                AttendanceTab: action.val
            }

        case SET_LOCATION:
            return {
                ...state,
                attendanceLocation: {
                    latitude: action.val1,
                    longitude: action.val2
                }
            }

        case SELECTED_LEAVES:
            return {
                ...state,
                storeSelectedLeaves: action.val
            }

        case SELECTED_ATTENDANCE:
            return {
                ...state,
                storeselectedAttendance: action.val
            }

        case SET_MARK_ATTENDANCE:
            let markAttendanceObjNew = { ...state.markAttendanceObj };

            if (action.key === 'requestType') {
                markAttendanceObjNew.requestType = action.val;
            }
            else if (action.key === 'startDate') {
                markAttendanceObjNew.startDate = action.val;
            }
            else if (action.key === 'clockIn') {
                markAttendanceObjNew.clockIn = action.val;
            }
            else if (action.key === 'clockOut') {
                markAttendanceObjNew.clockOut = action.val;
            }
            else if (action.key === 'reason') {
                markAttendanceObjNew.reason = action.val;
            }
            else if (action.key === 'totalhours') {
                markAttendanceObjNew.totalhours = action.val;
            }
            return {
                ...state,
                markAttendanceObj: { ...markAttendanceObjNew }
            }


        case SET_LEAVE_REQUEST:
            let leaveRequestObjNew = { ...state.leaveRequestObj };
            if (action.key === 'requestType') {
                leaveRequestObjNew.requestType = action.val;
            }
            else if (action.key === 'leaveType') {
                leaveRequestObjNew.leaveType = action.val;
            }
            else if (action.key === 'startDate') {
                leaveRequestObjNew.startDate = action.val;
            }
            else if (action.key === 'endDate') {
                leaveRequestObjNew.endDate = action.val;
            }
            else if (action.key === 'reason') {
                leaveRequestObjNew.reason = action.val;
            }
            else if (action.key === 'noOfdays') {
                leaveRequestObjNew.noOfdays = action.val;
            }
            return {
                ...state,
                leaveRequestObj: { ...leaveRequestObjNew }
            }

        case SET_LOADER:
            let newloader = { ...state.loaders };
            newloader[action.key] = action.val;
            return {
                ...state,
                loaders: newloader
            }

        case ATTENDANCE_TABLE_DATA:
            return {
                ...state,
                attendanceTable: action.val
            }

        case STORE_MONTH:
            return {
                ...state,
                storeSelectingMonth: action.val
            }

        case STORE_STUDENT_SUBJECTS:
            return {
                ...state,
                storeSubjectDetails: action.val
            }

        case STORE_USERS_SUMMARY_GRAPH_DATA:
            return {
                ...state,
                usersSummaryGraphDataContainer: action.val
            }

        case SET_EDIT_LEAVE:
            let editLeaveMetaNew = { ...state.editLeaveMeta };
            if (action.key === 'id') {
                editLeaveMetaNew.id = action.val;
            }
            else if (action.key === 'leaveType') {
                editLeaveMetaNew.leaveType = action.val;
            }
            else if (action.key === 'startDate') {
                editLeaveMetaNew.startDate = action.val;
            }
            else if (action.key === 'endDate') {
                editLeaveMetaNew.endDate = action.val;
            }
            else if (action.key === 'noOfdays') {
                editLeaveMetaNew.noOfdays = action.val;
            }
            else if (action.key === 'reason') {
                editLeaveMetaNew.reason = action.val;
            }
            return {
                ...state,
                editLeaveMeta: { ...editLeaveMetaNew }
            }

        case EDIT_LEAVE_CARD_ID:
            return {
                ...state,
                editLeaveCardID: action.id
            }

        case SET_LOCATION_CARD_TITLE:
            return {
                ...state,
                locationCardTitle: action.value
            }

        // case SET_FILTER_VALUES:
        //     let newValues = {...state.filterValues};
        //     if (action.key==='something'){
        //         ////write some conditionhere
        //         }else{
        //         newValues[action.key] = action.val
        //         }
        // case STORE_CREATE_HOLIDAY:
        //     let createHolidayListObj = {...state.createHolidayList};
        //     if(action.key === 'title'){
        //         createHolidayListObj.title=action.val;
        //     }
        //     else if(action.key === 'date'){
        //         createHolidayListObj.date=action.val;
        //     }
        //     else if(action.key==='id')
        //     {
        //         createHolidayListObj.id=action.value;
        //     }

        //     return{
        //         ...state,
        //         createHolidayList:{...createHolidayListObj}
        //     }

        // case CLEAR_CREATE_HOLIDAY: 
        // return{
        //     ...state,
        //     createHolidayList:{id:'', title: '', date: ''}
        // }


        case STORE_HOLIDAY_CHECKBOX:
            return {
                ...state,
                holidayCheckBox: action.val
            }
        case STORE_ATTENDANCE_DASH_BOARD_DATA:
            return {
                ...state,
                attendanceDataContainer: action.val
            }
        case IMPORT_HOLIDAY:
            return {
                ...state,
                holidayImportList: action.res

            }
        // case STORE_IMPORT_HOLIDAY:
        //     return{
        //         ...state,
        //         importHolidayListNew:action.value
        //     }
        case SAVE_HOLIDAY_LIST:
            return {
                ...state,
                saveHolidayListDetail: action.res
            }
        case STORE_HOLIDAY_LIST:
            return {
                ...state,
                saveHolidayListDetailNew: action.value
            }
        case STORE_EDIT_HOLIDAY:
            return {
                ...state,
                editHolidayValue: action.val
            }
        case ALL_ATTENDANCE_REQUIREMENT:
            return {
                ...state,
                getattendanceRequirement: action.res
            }
        case STORE_ATTENDANCE_HOURS_MINUTES:
            return {
                ...state,
                staffAvgWorkingHrs: action.value
            }
        case STORE_ATTENDANCE_TEACHER:
            return {
                ...state,
                attendanceTeacherHrsMinutes: action.value
            }
        case STORE_ATTENDANCE_DATA_OPERATOR:
            return {
                ...state,
                storeAttendanceDataOperator: action.value
            }
        case STORE_ATTENDANCE_OPERATION:
            return {
                ...state,
                storeAttendanceOperation: action.value
            }
        case TEACHER_HALF_DAY_ATTENDANCE:
            return {
                ...state,
                teacherHalfDay: action.val
            }
        case DO_HALF_DAY_ATTENDANCE:
            return {
                ...state,
                DoHalfDay: action.val
            }
        case OPERATION_HALF_DAY:
            return {
                ...state,
                operationHalfDay: action.val
            }
        case STORE_ATTENDANCE_MIN_REQUIREMENT:
            return {
                ...state,
                storeAttendanceMin: action.value
            }
        case STORE_ATTENDANCE_MAX_REQUIREMENT_TEACHER:
            return {
                ...state,
                storeMaxTeacher: action.value
            }
        case STORE_ATTENDANCE_MAX_REQUIREMENT_DATA_OPERATOR:
            return {
                ...state,
                storeMaxDataOperater: action.value
            }
        case STORE_ATTENDANCE_MAX_OPERATION:
            return {
                ...state,
                storeMaxOperations: action.value
            }

        case STORE_USERS_DASH_BOARD_DATA:
            return {
                ...state,
                usersDataContainer: action.val
            }
        case STORE_PREVILEGED_VIEW_DATA:
            return {
                ...state,
                previlegedViewDataContainer: action.val
            }
        case STORE_USERS_NAME:
            return {
                ...state,
                usersNameContainer: action.val
            }

        case STORE_LEAVES_TAB_DATA:
            return {
                ...state,
                leavesTabDataContainer: action.val
            }
        case STORE_REQUESTED_USER_ID:
            return {
                ...state,
                requestedUserIdContainer: action.val
            }

        case STORE_TAB_DATA:
            return {
                ...state,
                tabDataContainer: action.val
            }
        case STORE_SELECTED_MONTH:
            return {
                ...state,
                selectedMonthContainer: action.val
            }
        case STORE_SELECTED_RANGE:
            return {
                ...state,
                selectedRangeContainer: action.val
            }
        case STORE_WORKING_DAYS:
            return {
                ...state,
                storeOffDaysDetails: action.value
            }
        // case STORE_ATTENDANCE_HOURS_MIN:
        //     return{
        //         ...state,
        //         storeAttendanceMinute:action.value
        //     }


        case FETCH_CLASS_CARD_ID:
            return {
                ...state,
                storeClasscardId: action.id
            }
        case SET_STUDENT_DASHBOARD_DATA:
            return {
                ...state,
                studentDashboardData: action.data
            }
        case SET_CLASS_DASHBOARD_DATA:
            return {
                ...state,
                classDashboardData: action.data
            }
        case SET_ALLSTUDENTS_VA:
            return {
                ...state,
                VAllStudentsData: action.data
            }
        // case UPDATE_FILTER:
        //     let newfilter = {...state.filter}
        //     newfilter[action.key] = action.val;
        //     return {
        //         ...state,
        //         filter: newfilter
        //     }
        case STUDENT_OVERVIEW:
            return {
                ...state,
                studentOverviewDetail: action.res
            }
        case STORE_STUDENT_ID_DATA:
            return {
                ...state,
                storeStudentId: action.val
            }
        case UPDATE_GEO_FENCE_LIST:
            return {
                ...state,
                geoFenceLocationList: [...action.data]
            }
        case UPDATE_SEARCH_LOCATION:
            return {
                ...state,
                searchLocation: action.data
            }
        case UPDATE_RADIUS:
            return {
                ...state,
                radius: action.data
            }
        case UPDATE_GEOMETRY_LOCATION:
            return {
                ...state,
                geometryLocation: action.object
            }
        case UPDATE_USER_LIST:
            return {
                ...state,
                userList: [...action.data]
            }
        case UPDATE_LOCATION_LIST:
            return {
                ...state,
                locationList: [...action.data]
            }
        case UPDATE_ATTENDANCE_DATA:
            // data[action.key] = action.value
            return {
                ...state,
                attendanceData: {
                    ...state.attendanceData,
                    [action.key]: action.value
                }
            }
        case LOCATION_CARD_DATA:
            return {
                ...state,
                locationCardId: action.value
            }
        case STORE_ATTENDANCE_PROFILE_DATA:
            return {
                ...state,
                AttendanceTab: action.val
            }
        case SET_LOCATION:
            return {
                ...state,
                attendanceLocation: {
                    latitude: action.val1,
                    longitude: action.val2
                }
            }
        case SELECTED_LEAVES:
            return {
                ...state,
                storeSelectedLeaves: action.val
            }
        case SELECTED_ATTENDANCE:
            return {
                ...state,
                storeselectedAttendance: action.val
            }
        // case SET_MARK_ATTENDANCE:

        //     let markAttendanceObjNew = {...state.markAttendanceObj};

        //     if(action.key === 'requestType'){
        //         markAttendanceObjNew.requestType=action.val;
        //     }
        //     else if(action.key === 'startDate'){
        //         markAttendanceObjNew.startDate=action.val;
        //     }
        //     else if(action.key==='clockIn'){
        //         markAttendanceObjNew.clockIn=action.val;
        //     }
        //     else if(action.key==='clockOut'){
        //         markAttendanceObjNew.clockOut=action.val;
        //     }
        //     else if(action.key==='reason'){
        //         markAttendanceObjNew.reason=action.val;
        //     }
        //     else if(action.key==='totalhours'){
        //         markAttendanceObjNew.totalhours=action.val;
        //     }
        //     return{
        //         ...state,
        //         markAttendanceObj:{...markAttendanceObjNew}
        //     }


        // case SET_LEAVE_REQUEST:
        //     let leaveRequestObjNew = {...state.leaveRequestObj};
        //     if(action.key === 'requestType'){
        //         leaveRequestObjNew.requestType=action.val;
        //     }
        //     else if(action.key === 'leaveType'){
        //         leaveRequestObjNew.leaveType=action.val;
        //     }
        //     else if(action.key === 'startDate'){
        //         leaveRequestObjNew.startDate=action.val;
        //     }
        //     else if(action.key==='endDate'){
        //         leaveRequestObjNew.endDate=action.val;
        //     }
        //     else if(action.key==='reason'){
        //         leaveRequestObjNew.reason=action.val;
        //     }
        //     else if(action.key==='noOfdays'){
        //         leaveRequestObjNew.noOfdays=action.val;
        //     }
        //     return{
        //         ...state,
        //         leaveRequestObj:{...leaveRequestObjNew}
        //     }
        // case SET_LOADER:
        //     let newloader = {...state.loaders};
        //     newloader[action.key] = action.val;
        //     return {
        //         ...state,
        //         loaders: newloader
        // }
        case ATTENDANCE_TABLE_DATA:
            return {
                ...state,
                attendanceTable: action.val
            }
        // case STUDENT_SUBJECTS:
        //     return{
        //         ...state,
        //         subjectDetailOfStudent:action.res
        //     }
        case STORE_MONTH:
            return {
                ...state,
                storeSelectingMonth: action.val
            }
        case STORE_STUDENT_SUBJECTS:
            return {
                ...state,
                storeSubjectDetails: action.val
            }
        case STORE_SUBJECT_DETAIL_MODAL:
            return {
                ...state,
                subjectDetailModal: action.val
            }


        case STORE_USERS_SUMMARY_GRAPH_DATA:
            return {
                ...state,
                usersSummaryGraphDataContainer: action.val
            }
        case STORE_ATTENDANCE_DETAILS_MONTH:
            return {
                ...state,
                attendanceDetailsMonthContainer: action.val
            }
        case ATTENDANCE_DETAILS_MODAL_DATA:
            return {
                ...state,
                attendanceDetailsContainer: action.val
            }

        case EDIT_LEAVE_CARD_ID:
            return {
                ...state,
                editLeaveCardID: action.id
            }
        case SET_LOCATION_CARD_TITLE:
            return {
                ...state,
                locationCardTitle: action.value
            }

        case SET_FILTER_VALUES:
            let newValues = { ...state.filterValues };
            if (action.key === 'something') {
                ////write some conditionhere
            } else {
                newValues[action.key] = action.val
            }
            return {
                ...state,
                filterValues: newValues
            }

        case START_DATE: {
            return {
                ...state,
                startDate: action.val
            }
        }
        case END_DATE: {
            return {
                ...state,
                endDate: action.val
            }
        }
        case MONTH_OR_WEEK: {
            return {
                ...state,
                isWeekOrMonth: action.val
            }
        }
        case STORE_SELECTED_SUBJECT: {
            return {
                ...state,
                storeSubject: action.val
            }
        }
        case STORE_SUMMARY_END_DATE: {
            return {
                ...state,
                summaryStartDate: action.val
            }
        }
        case STORE_SUMMARY_START_DATE: {
            return {
                ...state,
                summaryEndDate: action.val
            }
        }
        case ATTENDANCE_STUDENT_OVERVIEW: {
            return {
                ...state,
                myAttendanceStudentDashBoard: action.res
            }
        }
        case SELECTED_MONTH: {
            return {
                ...state,
                studentSelectMonth: action.value
            }
        }
        case STUDENT_SUMMARY_DETAIL: {
            return {
                ...state,
                summaryOfStudent: action.res
            }
        }
        case STUDENT_END_DATE: {
            return {
                ...state,
                studentattEndDate: action.val
            }
        }
        case STUDENT_START_DATE: {
            return {
                ...state,
                studentattStartDate: action.val
            }
        }
        case MONTHLY_SUMMARY: {
            return {
                ...state,
                studentMonthlySummary: action.val
            }
        }
        case SUBJECT_SUMMARY: {
            return {
                ...state,
                studentSubjectSummary: action.val
            }
        }


        case STORE_USER_SUMMARY_START_DATE:
            return {
                ...state,
                summaryStartDateContainer: action.val
            }
        case STORE_USER_SUMMARY_END_DATE:
            return {
                ...state,
                summaryEndDateContainer: action.val
            }

        case STORE_SUMMARY_TYPE:
            return {
                ...state,
                summaryTypeContainer: action.val
            }
        case STORE_SUMMARY_YEAR:
            return {
                ...state,
                summaryYearContainer: action.val
            }
        case STORE_WORK_SUMMARY_TYPE:
            return {
                ...state,
                workSummaryTypeContainer: action.val
            }
        case STORE_WORK_SUMMARY_YEAR:
            return {
                ...state,
                workSummaryYearContainer: action.val
            }
        case STORE_WORK_SUMMARY_START_DATE:
            return {
                ...state,
                workSummaryStartDateCont: action.val
            }
        case STORE_WORK_SUMMARY_END_DATE:
            return {
                ...state,
                workSummaryEndDateCont: action.val
            }
        case SET_BACK_TO_PRIVILEGED_VIEW:
            return {
                ...state,
                storeBackToPrivilegedView: action.val
            }
        case CHECK_STORE:
            return {
                ...state,
                checkedValue: action.val
            }
        case UNCHECKED_VALUE:
            return {
                ...state,
                values: action.val
            }
        case SET_CLICKED_ON_USER_CARD:
            return {
                ...state,
                storeIsClickedOnUserCard: action.val
            }
        case STORE_PROFILETAB_MONTH_SELECT:
            return {
                ...state,
                storeProfileTabMonthSelect: action.val
            }

        case FETCH_MONTH_FOR_ATTDETAILS:
            return {
                ...state,
                fetchMonthAttDetails: action.val
            }

        case TRACK_SELECTED_RADIO:
            return {
                ...state,
                selectedImportRadio: action.val
            }

        case SET_BUTTON_DIV_VISIBLE: {
            let filter = { ...state.isButtonDivVisible };
            filter[action.key] = action.val;
            return {
                ...state,
                isButtonDivVisible: filter,
            }
        }

        case ID_SELECTED:
            return {
                ...state,
                idSelectedStore: action.val
            }
        case SET_REQUESTED_INDEX:
            return {
                ...state,
                storeRequestedIndex: action.val
            }
        case REQUEST_VIEW_ALL_CLICKED:
            return {
                ...state,
                viewAllClickedData: action.val
            }

        case STORE_MONTHLY_SELECTED_MONTH:
            return {
                ...state,
                storeMonthlySummaryMonth: action.val
            }

        case STORE_REQUEST_VIEW_ALL_PAGE:
            return {
                ...state,
                pageNumber: action.val
            }

        case STORE_OCC_ID:
            return {
                ...state,
                OccurenceId: action.val
            }

        case STORE_STUD_STATS:
            return {
                ...state,
                storeStudsStats: action.res
            }
        case SET_FROM_USERS_GEO_FENCE:
            return {
                ...state,
                fromUsersGeoFence: action.bool
            }
        case STORE_EDITED_GEO_FENCE_DATA:
            return {
                ...state,
                editedGeoFenceData: action.val
            }
        case STORE_GEO_FENCE_USERID:
            return {
                ...state,
                userIdData: action.val
            }
        case FETCH_STANDARDS:
            return {
                ...state,
                standardsData: action.val
            }
        case STORE_SELECTED_CLASS:
            return {
                ...state,
                selectedClassId: action.val
            }

        case SET_ATTENDNCE_OVERVIEW:
            return {
                ...state,
                storeAttendanceLeaveOverview: action.res
            }
        case STORE_STAFF_LEAVE_DATE:
            return {
                ...state,
                storeLeaveDate: action.val
            }
        case SET_LEAVE_OVERVIEW:
            return {
                ...state,
                storeLeaveOverviewData: action.val
            }
        case SET_GRAPH_DATA:
            return {
                ...state,
                storeGraphDate: action.val
            }
        case STORE_USER_DETAILS:
            return {
                ...state,
                locationDetailsContainer: action.val
            }
        case STORE_NUMBER_OF_DAYS:
            return {
                ...state,
                storeNoOfDay: action.val
            }
        case SET_STORE_LEAVE_IDS:
            return {
                ...state,
                storeLeaveId: action.val
            }
        case STORE_SAME_MINIMUM_ATT:
            return {
                ...state,
                sameMinimumAtt: action.res
            }
        case SET_SAME_HALF_ATT:
            return {
                ...state,
                sameHalfAtt: action.res
            }
        case SET_SAME_MAXIMUM_ATT:
            return {
                ...state,
                sameMaximumAtt: action.res
            }
        case GET_WEB_CLOCK_IN_DATA:
            return {
                ...state,
                storeClockInData: action.res
            }
        case SET_CLOCK_IN_START_DATE:
            return {
                ...state,
                storeClockInStartDate: action.res
            }
        case SET_CLOCK_IN_END_DATE:
            return {
                ...state,
                storeClockInEndDate: action.res
            }
        case SET_CLOCK_IN_SWITCH:
            return {
                ...state,
                storeClockInSwitch: action.res
            }
        case GET_REMAINDER_DATA:
            return {
                ...state,
                storeRemainderData: action.res
            }

        /* NEW ATTENDANCE V3 */
        case STUDENTS_LIST:
            return {
                ...state,
                allStudentsList: action.res
            }

        case LEAVE_OVERVIEW:
            return {
                ...state,
                leavesOverviewOfStaff: action.res
            }
        default:
            return state;
    }
}
export default reducer;