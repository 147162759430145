import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import ReactHtmlParser from 'react-html-parser';
import { AiOutlineCloseCircle} from "react-icons/ai";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from "react-icons/ri";
import { LoadingOutlined, } from '@ant-design/icons';
import './styles.css';
import { Color } from 'services';
import { Col, Row , Typography, Select, Menu, Dropdown} from 'antd';
import TopNavigationColorHeader from './localComponents/topNavigationColorHeader';
import TestPublishModal from './localComponents/testPublishModal';
import TeX from '@matejmazur/react-katex';
import ImageViewer from 'components/ImageViewer';



function PractiseQuesGenerateListView ({history, generateQuesListViewBool, generateQuesListViewData}) {

  useEffect(()=>{
    document.getElementById("root-1-conditional-height-for-formula").style.overflowY= "hidden";
    return ()=>{
      document.getElementById("root-1-conditional-height-for-formula").style.overflowY= "auto";
    }
  }, []);

    return (
      <div style={{height: '100%', margin:'-20px'}}>
        <TopNavigationColorHeader history={history} listView={true} type="beforeTest"/>
        {generateQuesListViewBool
        ?
          <div className='r-c-c' style={{height:'calc(100% - 20px)'}}><LoadingOutlined /></div>
        :
          Object.keys(generateQuesListViewData)?.length
          ?
            <div className='overview-scroll' style={{height:'calc(100% - 20px)'}}>
              <div className='r-c-fe p-20'>
                <div className='r-ac cursor-pointer' onClick={() => history.goBack()} >
                  <AiOutlineCloseCircle className='text-sm blue m-r-5' />
                  <div className='text-xmd bold-600 blue'>EXIT LIST VIEW</div>
                </div>
              </div>
              <div className='p-l-20 p-r-20 p-b-20'>
                {generateQuesListViewData?.subjects?.map((item) => (
                  <div keys={item.subjId}>
                    <div className='text-sm bold-600 color-black'>{item.subName}</div>
                    {item.questions?.length
                    ?
                      item.questions?.map((data, index) => (
                        <div className='m-b-20' keys={data.id}>
                          <div className='r-jsb m-t-20'>
                            <div className='display-flex flex-1'>
                              <div className='text-xmd bold-600 color-black m-r-5'>{index+1}.</div>
                              <div className='text-xmd color-black resize-editor-img'>
                                    {ReactHtmlParser(data.statement,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                              </div>
                            </div>
                            {data.type === 'Subjective'
                            ?
                              <div className='text-xmd a2grey m-l-20'>+ {data.positiveMarks}</div>
                            :
                              <div className='text-xmd a2grey m-l-20'>+ {data.positiveMarks} | - {data.negativeMarks}</div>
                            }
                          </div>
                          {data.type === 'MCQ'
                          ?
                            <div className='m-t-10' style={{marginLeft:-20}}>
                              {data.options?.map((opt, i) => (
                                <div keys={opt.id} className='r-ac m-b-10 m-l-20' >
                                  <div
                                    style={{background:opt.correct ? '#28df99' : '', color:opt.correct ? '#fff' : '', borderColor: opt.correct ? '#28df99' : ''}}
                                    className='r-c-c radius-100 color-black test-circle-avatar m-r-10'>
                                    {i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D'}
                                  </div>
                                  <div
                                    style={{background:opt.correct ? '#E9FCF5' : ''}}
                                    className='test-ques-window p-10 r-c-sb flex-1'>
                                    <div className='flex-1 resize-editor-img'>
                                    {ReactHtmlParser(opt.value,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                                    </div>
                                    <div className='r-ac m-l-5'>
                                      {opt.correct
                                      ?
                                        <RiCheckboxCircleLine className='text-sm seaGreen' />
                                      :
                                        <RiCheckboxBlankCircleLine className='text-sm a2grey' />
                                      }
                                      <div className='m-l-5 text-xs a2grey'>Correct answer</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          : data.type === 'Numeric'
                            ?
                              <div className='m-t-10'>
                                <div className='text-xmd bold-600 color-black'>Answer</div>
                                <div className='test-ques-window resize-editor-img p-10'>
                                  {ReactHtmlParser(data.answer)}
                                </div>
                              </div>
                            : null
                          }
                          {(data.solution !== '' && data.solution !== '<p><br></p>')
                          ?
                            <div className='m-t-20'>
                              <div className='text-xmd bold-600 color-black'>Solution</div>
                              <div className='test-ques-window resize-editor-img p-10'>
                                    {ReactHtmlParser(data.solution,
                                        { transform: (node) => {
                                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                                            return <TeX math={`${node.attribs['data-value']}`} />;
                                        }
                                        if (node.type === 'tag' && node.name === 'img') {
                                            return <img style={{maxHeight: '40vh', maxWidth: '40vh'}} src={node.attribs.src} alt="" />;
                                        }
                                        }})
                                    }
                              </div>
                            </div>
                          :
                            null
                          }
                      </div>
                    ))
                  :
                    null}
                  </div>
                ))}

              </div>
            </div>
          :
            null
        }
        <TestPublishModal history={history}/>
      </div>
  );
}


const mapStateToProps = state => {
  const {generateQuesListViewBool, generateQuesListViewData} = state.practiseTest;
  return {generateQuesListViewBool, generateQuesListViewData};
};

const mapDispatchToProps = dispatch => ({
    // showTextEditor: (name) => dispatch(showTextEditor(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PractiseQuesGenerateListView);
