import React, { Component } from 'react';
import { Api } from '../../services';
import Pagination from 'antd/lib/pagination';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Avatar from 'antd/lib/avatar';
import Select from 'antd/lib/select';
import Tag from 'antd/lib/tag';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import Input from 'antd/lib/input';
import 'antd/lib/pagination/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/tag/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/tooltip/style/index';
import 'antd/lib/input/style/index';
import { EditOutlined, CloseCircleOutlined, UnorderedListOutlined, TableOutlined, ExclamationCircleOutlined, LoadingOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {  AdministratorCard} from '../../components';
import StudentModal from './modal/studentModal';
import UserModal from './modal/userModal';
import { Color } from '../../services';
import { getUsers, userModalVisible,  studentModalVisible, getStudents, deleteUsers, setFilterParams, resetFilterParams,
  updateAutoApprovalSetting } from '../../actions/user';
import { connect } from 'react-redux';
import { Badge } from 'antd';
import moment from 'moment';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const { Option } = Select;
const { confirm } = Modal;


class User extends Component {
  constructor(props) {
      super(props);
      this.state = {
          administratorModal: false,
          listView: false,
          editable: false,
          search : false,
      }
  }

  componentDidMount() {
    this.props.getUsers(1);
  }

  componentWillUnmount() {
    this.props.resetFilterParams();
  }


  handleRoleSelect(value){
      this.props.setFilterParams(value, "role");
      // this.props.getUsers(value, null, 1)
      this.props.getUsers(1);
  }

  handlePageChange(page){
    // filterParams.role != "Student" ?
    this.props.getUsers(page);
    //  :
    // this.props.getStudents(filterParams.stdId, page)
  }

  showUserModalDetails(data){
      const { filterParams } = this.props;
      this.setState({
        editable: true
      })
      Object.assign(data, {oldPhone: data.phone})
      if(filterParams.role === 'Student'){
        data.standardId = data.standard ? data.standard.id : -1;
        this.props.studentModalVisible(data, true);

      }else{
        this.props.userModalVisible(data, true);

      }
    }

    showUserNewModal(){
      const { filterParams } = this.props;
      this.setState({
        editable: false
      })
      if(filterParams.role === 'Student'){
        this.props.studentModalVisible({}, true)
      }else{
        this.props.userModalVisible({bloodGroup: null, gender: null}, true)
      }
    }

    // toggleSearch() {
    //   this.setState({search: !this.state.search});
    // }

  handleUserSearch(query) {
      this.props.setFilterParams(query, "search");
      this.props.getUsers(1);
  }

  selectClass(value){
    if(value === ""){
      this.props.setFilterParams(null, "stdId");
    }else{
      this.props.setFilterParams(value, "stdId");
    }
    this.props.getUsers(1);
    // this.props.getStudents(value, 1);
  }

  updateSearch(value){
    if(value === ""){
      this.props.setFilterParams(null, "search");
      this.props.getUsers(1);
    }else{
      this.props.setFilterParams(value, "search");
    }
    // this.props.getUsers(1);
    // this.props.getStudents(value, 1);
  }

  handleDeleteClick(item) {
    const { filterParams } = this.props;
    this.props.deleteUsers(item, filterParams.role);
    this.setState({visible : false})
  }

  showConfirm(e, item, self) {
    e.stopPropagation();
    return confirm({
      title: `Do you want to remove ${item.name} from the portal?`, /*${item.name}*/
      icon: <ExclamationCircleOutlined />,
      content: 'Please click OK to confirm.',
      onOk() {self.handleDeleteClick(item)},
      onCancel() {},
    })
  }

  studentAdd(value){
    this.props.setFilterParams(value, "stdId");
  }

  actionsList(childData){
    const {user} = this.props;
    let allowDelete = true, allowEdit =  true;
    if(childData.role.includes("SuperAdmin") && user.role.includes("SuperAdmin")){
      allowDelete = false;
      allowEdit = false;
    }else if(childData.role.includes("Admin") && user.role.includes("SuperAdmin")){
      allowDelete = true;
      allowEdit = true;
    }else if(childData.role.includes("SuperAdmin") && user.role.includes("Admin")){
      allowDelete = false;
      allowEdit = false;
    }else if(childData.role.includes("Admin") && user.role.includes("Admin")){
      allowDelete = false;
      allowEdit = false;
    }
    return {allowDelete, allowEdit}
  }

  handleAutoApprovalEnable(bool){
    this.props.updateAutoApprovalSetting(bool)
  }

  render() {
  const {usersData, searchLoader, positionCurrent, pageMeta, user, filterParams, history, pendingReqCount,
    autoApproval, autoApprovalLoader} = this.props;
  const standards = usersData.standards;

      return (
      <div style={{height:'100%'}}>
        <div style={{height:'100%'}} className='display-flex m-b-30'>
          <div style={{height:'100%', flex:1}}  className='m-r-20'>
            <div className='r-c-sb m-b-20'>
              <div className='r-c-sb' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:50}}>
                <Select
                  disabled={searchLoader}
                  onChange={(value) => this.handleRoleSelect(value)}
                  className='borderLessSelect roundBorderSelect bold-600'
                  style={{color:Color.primary, width:'150px', fontSize: "0.9em" }}
                  value={filterParams ? filterParams.role : null}
                >
                  {this.props.usersList.map((data) => (
                    data !== "Guardian" &&  data !== "Fee Manager" && data !== "Attendance Marker" && data !== "Institute Support" ? <Option style={{fontSize: "0.9em"}} value={data}>{data}</Option> : null
                  ))}
                </Select>
                {filterParams.role === "Student" &&
                <Select
                  disabled={searchLoader}
                  onChange={(value) => this.selectClass(value)}
                  className='borderLessSelect roundBorderSelect bold-600'
                  value={filterParams ? filterParams.stdId : null}
                  style={{color:Color.primary, width:'200px', marginLeft:10, fontSize: "0.9em" }}
                  allowClear
                  placeholder="Select Class"
                >
                  <Option value="-1" style={{fontSize: "0.9em"}}>Unassigned</Option>
                  {standards && standards.map((data) => (
                    <Option style={{fontSize: "0.9em"}} value={data.id}>{data.std} - {data.section}</Option>
                  ))
                }
                </Select>}
                <div className='m-r-20'>
                    <Input.Search
                    disabled={searchLoader}
                    style={{marginLeft:20, height:35, borderRadius: 60, maxWidth: "20vw", fontSize: "0.9em"}}
                    onChange={e => this.updateSearch(e.target.value)}
                    value={filterParams.search}
                    onSearch={(value) =>this.handleUserSearch(value)}
                    size="large" placeholder="Search user..." allowClear />
                </div>
                <Button type="primary"
                  disabled={searchLoader}
                  style={{borderRadius: 30, marginLeft:20, fontSize: "0.9em"}}
                  onClick={() => this.showUserNewModal()}>
                    + Add {filterParams.role}
                </Button>
              </div>
              <div className='r-ac'>
                <TableOutlined onClick={() => this.setState({listView : false})} className='cursor-pointer' style={{color:this.state.listView ? Color.midGrey : Color.primary, fontSize:"1.5em"}}/>
                <UnorderedListOutlined onClick={() => this.setState({listView : true})} className='m-l-20 m-r-20 cursor-pointer'  style={{color:this.state.listView ? Color.primary : Color.midGrey, fontSize:"1.5em"}}/>

              </div>
            </div>
            <Row style={{height: 'calc(100% - 80px)', width: '100%', overflowY: 'auto'}}>

              <div style={{backgroundColor: Color.lightGrey, marginBottom:10, width: '100%',padding:20, borderRadius: 30}}>
                {filterParams.role === 'Teacher' || filterParams.role === 'Planner' || filterParams.role === 'Data Operator' || filterParams.role === 'Student'
                || filterParams.role === 'Marketing' || filterParams.role === 'Operation'
                  ?
                  <div style={{height: "100%"}}>
                    {user.access.includes("user-request") ?
                      <Row className="r-jsb">
                      <div>
                        {autoApprovalLoader ? 
                          <LoadingOutlined style={{marginRight: 10}}/>
                        :
                        <Checkbox  style={{marginRight: 10}} checked={autoApproval} onClick={(e) => this.handleAutoApprovalEnable(e.target.checked)} />}
                       Allow student auto-approval</div>
                      <Badge count={pendingReqCount ? pendingReqCount : null}>
                          <Button type="link" onClick={() => history.push('/user/requests')}
                          >
                            <span className="bold-600">
                              <UsergroupAddOutlined /> User Registration Requests
                            </span>
                        </Button>
                      </Badge>
                      </Row>
                    : null}
                    {!searchLoader ?
                    this.state.listView
                      ?
                      <Row>
                        {usersData.users && usersData.users.map((childData, index) => {
                          const {allowDelete, allowEdit} = this.actionsList(childData);
                          return (
                              <Col key={index.toString()} xs={{span:24}}>
                                <Row onClick={() => this.showUserModalDetails(childData)} className='r-c-sb m-b-20 cursor-pointer' style={{backgroundColor:Color[`lightgreen`], padding:'5px 10px', borderRadius:10, boxShadow:'0px 4px 6px -2px #e2e2e2'}}>
                                  <Col span={1}>
                                    <Avatar src={`${Api._s3_url}${childData.image}`} alt=""  size={30}/>
                                  </Col>
                                  <Col span={6}>
                                    <div className='text-center bold-600' style={{width:200, overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{childData.name}
                                    {
                                    <Tag color={childData.role.includes('SuperAdmin') ? "purple" : childData.role.includes('Admin') ? "green" :  'transparent'} style={{fontSize: 8, borderRadius:5, marginLeft: 10}}>
                                    {childData.role.includes('SuperAdmin') ? "Super Admin" : childData.role.includes('Admin') ? "Admin" :  null}
                                    </Tag>}</div>
                                  </Col>

                                { /* <div className='m-r-20 text-center' style={{width:200, overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                  {childData.role && childData.role.map((item, index, array) => <> {item} {index == array.length - 1 ? '' : '|'}
                                    </>)}</div> */}
                                  <Col span={7}>
                                    <div className=' text-center' style={{width:200, overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                      <div>{childData.email}</div> 
                                      <div>{childData.phone}</div>
                                    </div>
                                  </Col>
                                  {/* <Col span={2}>
                                    <div className='text-center' style={{width:200, overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap'}}></div>
                                  </Col> */}
                                  <Col span={4}>
                                    {childData.role.includes("Student") && childData.transaction && childData.transaction.length ? 
                                        <Tooltip
                                          placement="right"
                                          title={
                                          <div style={{width: "100%"}}>
                                            <div className="m-b-20">Fee Payment Details</div>
                                            <div className="r-jsb">
                                              <div >
                                                <div style={{fontSize: 11}}>Approved On</div> 
                                                <div style={{fontSize: 12}}>
                                                {moment(childData.createdAt).format("DD-MM-YYYY")}
                                                </div>
                                              </div>
                                              
                                              <div style={{fontSize: 11}}>
                                                <div style={{fontSize: 11}}>Amount</div> 
                                                <div style={{fontSize: 12}}>
                                                  ₹ {childData.transaction[0].amount}
                                                </div>  
                                              </div>
                                            </div>
                                          </div>
                                          }
                                          overlayStyle={{borderRadius: 60, padding: 20, width: 250}}
                                        >
                                          <Tag color={childData.transaction[0].status === "Link Sent" ? moment(childData.transaction[0].dueDate) < moment() ? "red" : "yellow" : childData.transaction[0].status === "Paid" ? "green" : "grey"}>
                                            {childData.transaction[0].status === "Link Sent" ? moment(childData.transaction[0].dueDate) < moment() ? "Overdue" : "Pending" : childData.transaction[0].status }
                                          </Tag>
                                        </Tooltip>
                                      : null}
                                  </Col>
                                  <Col span={3}>
                                    <div>
                                      {allowEdit && <Tooltip title="Edit User">
                                        <EditOutlined className='cursor-pointer' style={{color:Color.primary, fontSize:25}} onClick={() => this.showUserModalDetails(childData)}/>
                                      </Tooltip>}
                                      {allowDelete && <Tooltip title="Delete User">
                                        <CloseCircleOutlined className='cursor-pointer m-l-20' style={{color:Color.red, fontSize:25}} onClick={(e) => this.showConfirm(e, childData, this)}/>
                                      </Tooltip>}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                        )}
                        )}
                      </Row>
                      :
                      <Row>
                        {usersData.users && usersData.users.map((childData, index) => {
                          const {allowDelete, allowEdit} = this.actionsList(childData);
                          return (
                            <Col key={index.toString()} xs={{span:24}} sm={{span:12}} md={{span:8}} lg={{span:6}} xl={{span:6}} xxl={{span:4}} >
                              <AdministratorCard onClick={() => this.showUserModalDetails(childData)} onEditClick={() => this.showUserModalDetails(childData)} allowDelete={allowDelete} allowEdit={allowEdit}
                              onDeleteClick={(e,item)=>this.showConfirm(e,item,this)} cardDetail={childData} image={childData.image} color={'green'} name={childData.name} phone={childData.phone}
                              role={childData.role} position={positionCurrent}  />
                            </Col>
                          )})}
                      </Row> :
                       <div className="r-c-c-c" style={{height: "100%"}}><LoadingOutlined style={{fontSize: "10em", color: Color.primary, opacity: 0.5}}/></div>
                    }
                  </div>
                  :
                  null
                }
                </div>
            </Row>
            <div className="display-flex" style={{justifyContent: 'flex-end'}}>
              <Pagination
                    onChange={(page) => this.handlePageChange(page)}
                    size="small"
                    current={filterParams ? parseInt(filterParams.page) : 1 }
                    pageSize={24}
                    total={pageMeta ? pageMeta.totalItems : 0}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    showSizeChanger={false}
                  />
            </div>

            </div>

          {/* <div>
            <div className='m-t-20' style={{backgroundColor:'#F9F9F9', borderRadius:25, padding:15, width:'260px', height:'100%'}}>
              <div className='r-c-sb'>
                <Select className='AdministratorColorSelect bold-600' defaultValue="Class XI" style={{ width: 150, fontSize:15 }} onChange={() => {}}>
                  <Option value="Class XI">Class XI</Option>
                  <Option value="Class XII">Class XII</Option>
                  <Option value="Class XILOL">Class XILOL</Option>
                </Select>
                <SearchOutlined style={{color:Color.primary, fontSize:'1.6em'}} />
              </div>
              <Divider style={{margin:'15px 0px'}}/>
              <div className='bold-600 m-b-10' style={{color:Color.primary}}>Administrator</div>
              <div className='r-c-sb m-b-20'>
                <div className='r-ac'>
                  <img src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="" style={{width:40, height:40, borderRadius:100}}/>
                  <div className='m-l-10'>
                    <div className='color-black'>Koi tho hai</div>
                  </div>
                </div>
                <EllipsisOutlined  style={{color:Color.darkGrey, fontSize:18}}/>
              </div>
              <CollapseGlobal
                headers={['Teachers', 'Students']}
                tab1={tab1Arr}
                tab2={[{'name':'Raj Malthora', 'title':'Vice Principal'}] }
              />
            </div>
          </div> */}
        </div>

        {this.props.userModalBool &&
        <UserModal editable={this.state.editable}  />}
        {this.props.studentModalBool &&
        <StudentModal studentAdd={(currentClass) => this.studentAdd(currentClass)} currentClass={filterParams.stdId} editable={this.state.editable}  />}

    </div> );
  }
}

const mapStateToProps = state => {
    const {
      usersList, usersData, positionCurrent, searchLoader, pageMeta, filterParams, studentModalBool, userModalBool,
      pendingReqCount, autoApproval, autoApprovalLoader
    } = state.user;
    const {orgId} = state.session.user
    const { standards } = state.calendar
    const {user} = state.session
    return {
      usersList, usersData,positionCurrent, searchLoader, standards, pageMeta, orgId, filterParams, studentModalBool, 
      userModalBool, user, pendingReqCount, autoApproval, autoApprovalLoader
    };
  };

  const mapDispatchToProps = dispatch => ({
    getUsers: (role, search, page) => dispatch(getUsers(role, search, page)),
    studentModalVisible: (data,val) => dispatch(studentModalVisible(data,val)),
    userModalVisible: (data,val) => dispatch(userModalVisible(data,val)),
    getStudents: (stdId, page) => dispatch(getStudents(stdId, page)),
    deleteUsers: (item, position) => dispatch(deleteUsers(item, position)),
    setFilterParams: (value, key) => dispatch(setFilterParams(value, key)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    updateAutoApprovalSetting: (bool) => dispatch(updateAutoApprovalSetting(bool))
    // searchUser: query => dispatch(searchUser(this.prop.positionCurrent,query)),

  });

export default connect(mapStateToProps,mapDispatchToProps)(User);
