import React from 'react'
import { Select, Tabs, Input, Button} from 'antd';
import './styles.css';
import { ArrowLeftOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import TestCard from './localComponent/testCards';
import { useHistory } from 'react-router-dom';
import CustomCalender from './localComponent/customCalender';
// import { Complete } from './modals/createTestModal';
import {updateSelectedTest, fetchTests, unsetCreateTestModalBool, toggleReloaderFlag, setOpenTab, fetchTestsByStage, updateFilter, updateListViewType, createTestModal, getTestChapterList, storeCreateTestModalData, setSubjectSectionAction } from 'actions/testAdmin';

import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import PageDataLoader from 'components/pageDataLoader';
import moment from 'moment';
import { NodataCard } from './index';
import ConfirmationModal from './modals/confirmationModal';
import CreateTestModal from './modals/createTestModal';
import TestPublishModal from './localComponent/testPublishModal';
import SearchBox from './localComponent/searchBox';
import Loader from './localComponent/loader';
import CustomDatePicker from './localComponent/customDatePicker';
import { InputSearch } from 'components/customInputs';
import CreateTestType from './modals/createTestType';

const TestTypeSelectors = ({selected, changeTestTypeFilter}) => {
    return (
        <div style={{marginLeft: -30}}>
            <Button onClick={changeTestTypeFilter(null)} className={`${selected==undefined?"purpleBtnWrap purpleButtonHoverEffect":"simgrey"} radius-100 m-l-30 m-r-15 text-xs bold`}>
                All
            </Button>
            <Button onClick={changeTestTypeFilter('Subjective')} className={`${selected==="Subjective"?"purpleBtnWrap purpleButtonHoverEffect":"simgrey"} radius-100 m-r-15 text-xs bold`}>
                SUBJECTIVE
            </Button>
            <Button onClick={changeTestTypeFilter('Objective')} className={`${selected==="Objective"?"purpleBtnWrap purpleButtonHoverEffect":"simgrey"} radius-100 text-xs bold`}>
                OBJECTIVE
            </Button>
        </div>
    )
}
const PageHeader = ({ goBack, onSearch, searchVal, syllabus, classname, subjectname, chaptername, chapterSelected, onDateChange, defaultDate, openTab, onCreateClick }) => {
    return (
      <div className="resultPageHeader full-width">
        <div className="r-c-fs m-r-30">
          <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
          <div className="text-md bold-700 m-l-20"> 
            <div>
              {chapterSelected?chaptername:subjectname}
            </div>
            <div style={{color:"#AEAEAE", fontSize:12}}>
              {classname} / {syllabus} {chapterSelected?"/ "+subjectname:""}
            </div>
          </div>
        </div>
        <div className="testfiltersgroup">
            <CustomDatePicker style={{borderRadius:5, width:180}} className="m-r-10 m-l-10"
                                              onDateChange={onDateChange}
                                              defaultDate={defaultDate}
                                              placeholder={openTab==='results'?'Filter by result date':'Filter by test date'}
                                            />
            <InputSearch loading={false} onInputChange={onSearch} allowClear onClear={()=>{onSearch('')}} className="m-r-10" placeholder="Search by test name" style={{width:230}}/>
            {/* <Button onClick={(e)=>{onCreateClick(e)}} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
                + CREATE TEST
            </Button> */}
        </div>
      </div>
    );
  };




let timer = false;
const {TabPane} = Tabs;
function SubjectPage({
    testsToPublish,
    resultsToPublish,
    publishedResults,
    submittedTests,
    ongoingTests,
    drafts,
    selectedSubject,
    selectedClassroom,
    upcomingTests,
    TestNameArr,
    updateSelectedTest,
    isCModalVisible,
    cModalType,
    reloadToggler,
    feedbackFromTeacher,
    sentForFeedback,
    userType,
    readyToPublish,
    toggleReloaderFlag,
    setOpenTab,
    openTab,
    fetchTestsByStage,
    filter,
    updateFilter,
    loaders,
    cancelledTests,
    createTestModalBool,
    user,
    updateListViewType,
    createTestModal,
    getTestChapterList,storeCreateTestModalData,setSubjectSectionAction,storeSubjectSection
}) {
    const [pageLoader, togglePageLoader] = useState(false);
    const [testModalOpen, setTestModalOpen] = useState(false);
   
    console.log("AA-storeSubjectSection",storeSubjectSection);
    const showModal = () => {
      setTestModalOpen(true);
    };
  
    const closeModal = () => {
      setTestModalOpen(false);
    }
    useEffect(() => {
        togglePageLoader(false);
        if (openTab==='results'){
            fetchTestsByStage('results-to-publish', ()=>{});
            fetchTestsByStage('results-published', ()=>{
                togglePageLoader(false);
            });
        }else if(openTab==='feedback'){
            fetchTestsByStage('feedback', ()=>{});
            fetchTestsByStage('review', ()=>{
                togglePageLoader(false);
            });
        }
        else{
            fetchTestsByStage(openTab, ()=>{
                togglePageLoader(false);
            });
        }

    }, [openTab, reloadToggler]);
    useEffect(() => {

            clearTimeout(timer);
            timer = setTimeout(() => {
                if (openTab==='results'){
                    fetchTestsByStage('results-to-publish', ()=>{});
                    fetchTestsByStage('results-published', ()=>{});
                }else if(openTab==='feedback'){
                    fetchTestsByStage('feedback', ()=>{});
                    fetchTestsByStage('review', ()=>{
                        togglePageLoader(false);
                    });
                }else{
                    fetchTestsByStage(openTab, ()=>{});
                }
            },1000);
        }
    , [filter?.tabSearch]);

    const handleResultCardClick = (test) => {
        updateSelectedTest(test)
        history.push('test-publish-result-screen')
    }
    const history = useHistory();
    const testsDueToPublish = []; 
    let currentdate = moment().format('DD,MMM,YYYY').toString();
    if (drafts&&drafts.length>0){
        drafts?.map((test) =>
        test.testDate===currentdate
        ?
        testsDueToPublish.push(test)
        :
        null)
    }
    const goBack = () => {
        // history.push("/test");
        history.goBack()
    }
    const onSearch = (e) => {
        updateFilter('tabSearch', e);
    }
    const onDateChange = (v) => {
        if (v==undefined){
        updateFilter('isTAdatefiltered', false);
        updateFilter('taDate', null);
        }else{
            updateFilter('isTAdatefiltered', true);
            updateFilter('taDate', v.format("YYYY-MM-DD"));
        }
        toggleReloaderFlag();
    }
    const changeTestTypeFilter = (key) => {
        return () => {
            updateFilter('testTypeSelector', key);
            toggleReloaderFlag();
        }
    }
    const onCreateClick = (value) => {
        showModal();
        updateListViewType('create');
        createTestModal('test-create', user, filter.selectedSyllabus==='Chapter Test'?'chapter':'subject');
        if (filter?.selectedSyllabus==='Chapter Test'||selectedSubject?.isCombined===false){
            getTestChapterList(null, null, true, null);
        }
      }
    return (
        <div className="testListPage full-height subjectPage" style={{backgroundColor:"#FAFAFA", margin:-20, padding:20, height:"100vh", overflowY:"hidden"}}>

            <PageHeader goBack={goBack} onSearch={onSearch} searchVal={filter?.tabSearch} 
                  classname={`${selectedClassroom?.classname} - ${selectedClassroom?.section}`} subjectname={selectedSubject?.subjectName} 
                  syllabus={filter?.selectedSyllabus} chaptername = {filter?.selectedChapter?.chapterName}
                  chapterSelected={filter?.selectedSyllabus==='Chapter Test'?true:false}
                  openTab={openTab} onDateChange={onDateChange} defaultDate={filter?.taDate} onCreateClick={(e)=>{onCreateClick(e)}}/>

            <Tabs className="testTab full-height subjectTestTab" tabBarGutter={70} 
                    defaultActiveKey={openTab}
                    onChange={(activeKey)=>{
                        changeTestTypeFilter()(null);
                        setOpenTab(activeKey)
                        toggleReloaderFlag();
                    }}>
                    <TabPane tab="DRAFT" key="draft" >
                        <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>
                        {
                            loaders?.tabPageLoader?
                            <Loader style={{fontSize:40, marginTop: 200}}/>
                            :
                            <>
                            {drafts && drafts.length
                            ?
                            <div>
                                <h1 className="userCard-heading m-t-20 m-b-20" style={{fontSize:"14px"}}>All draft(s)</h1>
                                <div style={{height:"80vh", overflowY:"scroll", paddingBottom:100}}>
                                <div className="display-flex flex-wrap full-width" style={{padding: "0 10px"}}>
                                        {drafts?.map((test,index) =>
                                            <TestCard type='draft' test={test} key = {index} index={index}  isPublishButtonVisible={false}/>)}
                                </div>
                            </div>
                            </div>
                            :
                            null
                            }
                            {(drafts?.length)>0
                            ?
                            null
                            :
                            <div style={{height: "80vh"}} className="r-c-c">
                                <NodataCard/>
                            </div>}
                            </>
                        }
                    </TabPane>
                    <TabPane tab="FEEDBACK" key="feedback">
                        <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>

                        {
                            loaders?.tabPageLoader?
                            <Loader style={{fontSize:40}}/>
                            :
                            <>
                            {((feedbackFromTeacher!=null)&&(sentForFeedback!=null)&&(feedbackFromTeacher?.length+sentForFeedback?.length)>0)
                            ?
                            <div className="full-height">
                            <div style={{height:"80vh", overflowY:"scroll"}}>
                                {((userType=="admin"||userType=="dataOperator")&&(feedbackFromTeacher?.length>0))
                                    ?
                                    <>
                                    <h1 className="userCard-heading m-t-10" style={{fontSize:"14px"}}>Feedback from teacher(s)</h1>
                                    <div className="display-flex flex-wrap full-width" style={{padding: "0 10px"}}>
                                    {feedbackFromTeacher?.map((test,index) => 
                                        <TestCard type='Feedback' test={test} key = {index} index={index} sendForFeedback={false} dash={false}/>
                                        )}
                                    </div>
                                    </>
                                    :
                                    null
                                }

                                {sentForFeedback?.length>0
                                    ?
                                    <>
                                    <h1 className="userCard-heading m-t-10" style={{fontSize:"14px"}}>Sent for feedback</h1>
                                    <div className="display-flex flex-wrap full-width" style={{padding: "0 10px"}}>
                                    {sentForFeedback?.map((test,index) => 
                                        <TestCard type='Feedback' test={test} key = {index} index={index} sendForFeedback={true} dash={false}/>
                                        )}
                                    </div>
                                    </>
                                
                                    :
                                    null
                                }
                            </div>
                            </div>
                            :
                            <div style={{height: "80vh"}} className="r-c-c">
                                <NodataCard/>
                            </div>
                            }
                            </>
                            }               
                    </TabPane>
                    <TabPane tab="READY TO BE PUBLISHED" key="ready" >
                        <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>

                          {
                            loaders?.tabPageLoader?
                            <Loader style={{fontSize:40}}/>
                            :
                            <>
                            { testsToPublish && testsToPublish.length ?
                            <div style={{height:"80vh", overflowY:"scroll"}}>
                                <div className="display-flex flex-wrap full-width" style={{padding: "0 20px ", paddingTop:20}}>
                                                        
                                    {testsToPublish?.map((test,index) =>
                                        <TestCard type='ready' test={test} key = {index} index={index}/>)}
                                </div>
                            </div>
                            :
                            <div style={{height: "80vh"}} className="r-c-c">
                                <NodataCard/>
                            </div>
                            }
                            </>
                            }
                    </TabPane>
                    {((userType=="admin")||(userType=="teacher"))?
                            <>
                                <TabPane tab="UPCOMING" key="upcoming" >
                                    <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>
                                        {
                                        loaders?.tabPageLoader?
                                        <Loader style={{fontSize:40}}/>
                                        :
                                        <>
                                        { upcomingTests && upcomingTests.length ?
                                        <div style={{height:"80vh", overflowY:"scroll"}}>
                                            <div className="display-flex flex-wrap full-width testYScroll" style={{paddingTop:20}}>
                                                                    
                                                {upcomingTests?.map((test,index) =>
                                                    <TestCard type='upcoming' test={test} key = {index} index={index}/>)}
                                            </div>
                                        </div>
                                        :
                                        <div style={{height: "80vh"}} className="r-c-c">
                                            <NodataCard/>
                                        </div>
                                        }
                                        </>
                                        }   
                                </TabPane>
                                <TabPane tab="ON-GOING" key="ongoing" >
                                    <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>
                                    {
                                    loaders?.tabPageLoader?
                                    <Loader style={{fontSize:40}}/>
                                    :
                                    <>
                                        {ongoingTests && ongoingTests.length > 0 ?
                                            <div style={{ height: "80vh", overflowY: "scroll" }}>
                                                <div className="display-flex flex-wrap full-width" style={{ padding: "0 20px" }}>
                                                    {ongoingTests?.map((test, index) =>
                                                        <TestCard type='adminlive' test={test} key={index} index={index} />)}
                                                </div>
                                            </div>
                                            :
                                            <div style={{ height: "80vh" }} className="r-c-c">
                                                <NodataCard />
                                            </div>
                                        }
                                    </>}
                                </TabPane>
                                <TabPane tab="SUBMITTED" key="submitted">  
                                    <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>
                                {
                                    loaders?.tabPageLoader?
                                    <Loader style={{fontSize:40}}/>
                                    :
                                    <>
                                    { submittedTests && submittedTests.length> 0 ? 
                                        <div style={{height:"80vh", overflowY:"scroll"}}>
                                            <div className="display-flex flex-wrap full-width" style={{padding: "0 20px"}}>

                                                {submittedTests?.map((test, index)=>

                                                            <TestCard type='submitted' test={test} key = {index} index={index}/>
                                                            )}
                                            </div>
                                        </div>
                                    :
                                    <div style={{ height: "80vh" }} className="r-c-c">
                                        <NodataCard />
                                    </div>
                                    }
                                    </>
                                }
                                </TabPane>
                                <TabPane tab="RESULT(S)" key="results">
                                    <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>
                                    
                                {
                                        loaders?.tabPageLoader?
                                        <Loader style={{fontSize:40}}/>
                                        :
                                    <>
                                        {
                                            resultsToPublish?.length === 0 && publishedResults?.length === 0
                                                ?
                                                <div style={{ height: "80vh" }} className="r-c-c">
                                                    <NodataCard />
                                                </div>
                                                :
                                                <div style={{ height: "80vh", overflowY: "scroll" }}>
                                                    <div className="display-flex flex-wrap full-width" style={{ padding: "0 20px" }}>
                                                        {resultsToPublish?.map((test, index) =>
                                                            <TestCard type='published_in_tab' test={test} key={index} index={index} ispublished={false}
                                                                handleResultCardClick={handleResultCardClick} />
                                                        )}
                                                        {publishedResults?.map((test, index) =>
                                                            <TestCard type='published_in_tab' test={test} key={index + 100} index={index} ispublished={true}
                                                                handleResultCardClick={handleResultCardClick} />
                                                        )}
                                                    </div>
                                                </div>
                                        }
                                    </>}
                                </TabPane>
                                <TabPane tab="CANCELLED" key="cancelled">
                                    <TestTypeSelectors selected={filter?.testTypeSelector} changeTestTypeFilter={changeTestTypeFilter}/>
                                    
                                {
                                        loaders?.tabPageLoader?
                                        <Loader style={{fontSize:40}}/>
                                        :
                                            <>
                                                {
                                                        cancelledTests?.length===0
                                                        ?
                                                        <div style={{ height: "80vh" }} className="r-c-c">
                                                            <NodataCard/>
                                                        </div>
                                                        :
                                                        <div style={{ height: "80vh", overflowY: "scroll" }}>
                                                            <div className="display-flex flex-wrap full-width" style={{ padding: "0 20px" }}>
                                                                {cancelledTests?.map((test, index) =>
                                                                    <TestCard type='cancelled' test={test} key={index} index={index} ispublished={false}
                                                                        handleResultCardClick={handleResultCardClick} />
                                                                )}
                                                            </div>
                                                        </div>
                                                }
                                            </>}
                                </TabPane>
                        </>
                        :
                        null
                    }

            </Tabs>
        {/* {createTestModalBool && <CreateTestModal history={history} />} */}
      {testModalOpen && <CreateTestType onCreateClick={onCreateClick} isModalOpen={testModalOpen} closeModal={closeModal} setSubjectSectionAction={setSubjectSectionAction}/>}
        <ConfirmationModal isCModalVisible={isCModalVisible}  cModalType={cModalType}/>
        <PageDataLoader visible={pageLoader}/>
        <TestPublishModal history={history}/>
        </div>
    )
}
const mapStateToProps = state =>{
    const {testsToPublish, resultsToPublish, publishedResults, 
            ongoingTests, drafts, selectedSubject, selectedClassroom,
            upcomingTests, TestNameArr, submittedTests, getResultsBool, 
            isCModalVisible, cModalType, reloadToggler, 
            feedbackFromTeacher, sentForFeedback, userType, readyToPublish,
            selectedClassroomId, openTab, filter, loaders, cancelledTests, createTestModalBool,storeSubjectSection}=state.testAdmin;
    const {user} = state.session;
    return {testsToPublish, resultsToPublish, 
            publishedResults, ongoingTests, 
            drafts, selectedSubject, 
            selectedClassroom, upcomingTests,
            TestNameArr, submittedTests, getResultsBool, 
            isCModalVisible, cModalType, reloadToggler, 
            feedbackFromTeacher, sentForFeedback, userType, readyToPublish,
            selectedClassroomId, openTab, filter, loaders, cancelledTests, 
            createTestModalBool, user,storeSubjectSection}
}

const mapDispatchToProps = dispatch=> ({
    fetchTests: (type, callback, startDate, endDate, searchText, selectedId, subjectId)=>dispatch(fetchTests(type, callback, startDate, endDate, searchText, selectedId, subjectId)),
    updateSelectedTest: (test)=> dispatch(updateSelectedTest(test)),
    unsetCreateTestModalBool: ()=>dispatch(unsetCreateTestModalBool()),
    toggleReloaderFlag: ()=>dispatch(toggleReloaderFlag()),
    setOpenTab: (tab) => dispatch(setOpenTab(tab)),
    fetchTestsByStage: (type, callback) => dispatch(fetchTestsByStage(type, callback)),
    updateFilter: (type, callback) => dispatch(updateFilter(type, callback)),
    updateListViewType: (type) => dispatch(updateListViewType(type)),
    createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
    getTestChapterList: (search, subjectId, iscreate, stdid) => dispatch(getTestChapterList(search, subjectId, iscreate, stdid)),
  storeCreateTestModalData: (key, value) => dispatch(storeCreateTestModalData(key, value)),
  setSubjectSectionAction:(val) => dispatch(setSubjectSectionAction(val)),


})
export default connect(mapStateToProps, mapDispatchToProps)(SubjectPage);
