import { ArrowLeftOutlined } from '@ant-design/icons';
import { MdAssignment } from "react-icons/md";
import { fetchSampleQuestionPaper, setImportMeta, importSampleQuestionPaper, showGenerateQuesListView } from 'actions/practiseTest';
import { Checkbox, Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Notification } from 'services';
import Loader from './localComponents/loader';
// import SearchBox from './localComponent/searchBox';
const icDlQuesBank = require("Assets/test/icdlquestionbank.svg").default;
const NoData = require("../../Assets/ill-no-data.svg").default


const PageHeader = ({ goBack, selectedQBtype, selectedClassname, onImport, count }) => {
  return (
    <div className="r-c-sb full-width">
      <div className="r-c-fs m-r-30">
        <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer" />
        <div className="text-md bold-700 m-l-20">
          <div>
            {selectedQBtype}
          </div>
          <div style={{ color: "#AEAEAE", fontSize: 12 }}>
            {selectedClassname}
          </div>
        </div>
      </div>
      <div className="">
        <Button onClick={onImport} className="purpleBtnWrap radius-100 purpleButtonHoverEffect p-r-10 p-l-10">
          IMPORT ({count})
        </Button>
      </div>
    </div>
  );
};

const PractiseSampleGroupImport = ({ setImportMeta, fetchSampleQuestionPaper, importMeta,
  importSampleQuestionPaper, loaders, showGenerateQuesListView }) => {
  useEffect(() => {
    setImportMeta('sampleGroupBool', true);
    setImportMeta('sampleIndividualBool', false);
    fetchSampleQuestionPaper();
  }, []);
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }
  const handleQuestionPaperSelect = (selection) => {
    if (selection?.length === importMeta.sampleQuestionPaperList?.length) {
      setImportMeta('sampleGroupAllSelectBool', true);
    } else {
      setImportMeta('sampleGroupAllSelectBool', false);
    }
    setImportMeta('selectedSampleQuestionPapers', selection);
  }
  const onImport = () => {
    if (loaders?.sampleGroupLoader) {
      return
    }
    if (!importMeta || !importMeta.selectedSampleQuestionPapers || importMeta.selectedSampleQuestionPapers?.length === 0) {
      Notification.error("Error", "Please select question paper(s) for importing");
    } else {
      importSampleQuestionPaper((response) => {
        if (response.show?.type === 'success') {
          showGenerateQuesListView(() => {
            history.push("practiseTest-questionScreen")
          }, 'import');
        }
      });
    }
  }
  const onSelectAll = (e) => {
    const allSelection = importMeta.sampleQuestionPaperList?.map((item) => item.questionPaperId);
    if (e.target.checked === true) {
      setImportMeta('sampleGroupAllSelectBool', true);
      setImportMeta('selectedSampleQuestionPapers', allSelection);
    } else {
      setImportMeta('sampleGroupAllSelectBool', false);
      setImportMeta('selectedSampleQuestionPapers', []);
    }
  }

  const NoDataCard = () => {
    return (
      <>
        <div className='r-c-c-c' /* style={{marginTop:"200px"}} */>
          <img src={NoData} />
          <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px" }}>No Data</div>
          <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "11px" }}>There are no data to show yet. Please check back later.</div>
        </div>
      </>
    )
  }

  return (
    <div style={{ background: "#FAFAFA", width: "calc(100%+50px)", height: "95vh", margin: -20, padding: 20, overflow: "hidden" }}>
      <PageHeader selectedClassname={`${importMeta?.selectedStandardObj?.name} - ${importMeta?.selectedStandardObj?.stream === '' ? "NA" : importMeta?.selectedStandardObj?.stream}`}
        selectedQBtype={'Sample Question Papers'}
        goBack={goBack}
        count={importMeta?.selectedSampleQuestionPapers?.length}
        onImport={onImport} />
      {
        loaders?.sampleGroupLoader ?
          <Loader style={{ fontSize: 40 }} />
          :
          <>
            {importMeta?.sampleQuestionPaperList?.length
              ?
              <div className="r-jsb m-t-20">
                <div className="display-flex">
                  <Checkbox onChange={onSelectAll} checked={importMeta?.sampleGroupAllSelectBool}></Checkbox>
                  <div className="m-l-15">Select all</div>
                </div>
                <div style={{ color: "#636363", fontWeight: "500" }}>
                  {importMeta?.selectedSampleQuestionPapers?.length} selected
                </div>
              </div>
              :
              <div className="r-jsb m-t-20">
                <div className="display-flex">
                  <Checkbox onChange={onSelectAll} checked={importMeta?.sampleGroupAllSelectBool} disabled={true}></Checkbox>
                  <div className="m-l-15">Select all</div>
                </div>
                <div style={{ color: "#636363", fontWeight: "500" }}>
                  {importMeta?.selectedSampleQuestionPapers?.length} selected
                </div>
              </div>}


            {importMeta?.sampleQuestionPaperList?.length
              ?
              <Checkbox.Group className="display-flex"
                onChange={handleQuestionPaperSelect} value={importMeta?.selectedSampleQuestionPapers}
              >
                <div style={{ overflow: "auto", height: "100vh" }}>
                <div className='flex-wrap r-afs cursor-pointer m-t-20'>
                  {importMeta?.sampleQuestionPaperList?.map((item) =>
                    <div style={{
                      width: 280,
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #E6E6E6",
                      borderRadius: 10,
                      height: 100,
                    }}
                      className="m-10 display-flex p-10 customCardShadow cursor-pointer shadow-box hover-shadow" onClick={() => {
                        setImportMeta('sampleQuestionPaperObj', item);
                        setImportMeta('selectedSampleQuestions', []);
                        setImportMeta('sampleGroupBool', false);
                        setImportMeta('sampleIndividualBool', true);
                        history.push("practise-test-sam-question-paper");
                      }}>
                      <div className="r-c-fs" style={{ width: "90%" }}>
                        <img src={icDlQuesBank} style={{ width: "50px", height: "50px" }}/>
                        <div style={{ display: "flex", flexDirection: "column", margin: "15 0 0 15" }} className="m-l-10">
                          <div style={{ font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight: "500" }}>
                            {item.questionPaperName}
                          </div>
                          <div style={{ font: "Roboto bold", textAlign: "left", fontSize: 12 }}>
                            {item.noOfQuestion} Qs
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "10%" }}>
                        <Checkbox className="black p-10 m-l-0 full-width"
                          value={item?.questionPaperId}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                        </Checkbox>
                      </div>
                    </div>
                  )}
                </div>
                </div>
              </Checkbox.Group>
              :
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '100px' }}>
                <NoDataCard />
              </div>
            }
          </>
      }
    </div>
  )



}

const mapStateToProps = (state) => {
  const {
    loaders,
    importMeta
  } = state.practiseTest;
  return {
    loaders,
    importMeta
  };
}

const mapDispatchToProps = (dispatch) => ({
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  fetchSampleQuestionPaper: (callback) => dispatch(fetchSampleQuestionPaper(callback)),
  importSampleQuestionPaper: (callback) => dispatch(importSampleQuestionPaper(callback)),
  showGenerateQuesListView: (callback, type) => dispatch(showGenerateQuesListView(callback, type))
})


export default connect(mapStateToProps, mapDispatchToProps)(PractiseSampleGroupImport)
