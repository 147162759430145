import { setTab } from "actions/userManagement";
import { GraphHeading } from "components/Typography";
import React from "react";
import { connect } from "react-redux";
import image1 from "../../Assets/care/no-subjects.svg";
import SingleBarGraph from "./singleBarGraph";

function GenderRatio({ dashBoardRole, gendrRatio, StudentDashBoard }) {
  return (
    <div
      style={{
        boxShadow: "1px 1px 8px #c7cece",
        // boxShadow: "2px 3px 10px #302a5f14",
        width: "100%",
        height: "100%",
        padding: "10px",
        borderRadius: "20px",
      }}
    >
      <GraphHeading>
        Gender
      </GraphHeading>
      {StudentDashBoard == true ? (
        <p style={{ color: "#636363" }}>Gender Distribution of Students</p>
      ) : (
        <p style={{ color: "#636363" }}>
          Gender Distribution of {dashBoardRole}s
        </p>
      )}
      <div style={{ height: "88%" }}>
        {gendrRatio?.length !== 0 ? (
          <SingleBarGraph />
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "30%", height: "30%", marginBottom: "20px" }}
              src={image1}
              alt=""
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontWeight: "500" }}>No data!</h1>
              <p
                style={{
                  color: "#636363",
                  width: "60%",
                  textAlign: "center",
                }}
              >
                There are no data to show yet. Please try later.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// export default GenderRatio

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, gendrRatio } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    gendrRatio,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTab: (m) => dispatch(setTab(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenderRatio);
