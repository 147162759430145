// import {GiftedChat} from 'react-native-gifted-chat';
import {
  UPDATE_CLASS_MODE,
  TOGGLE_CHAT_LOADER,
  UPDATE_ROOM_NAME,
  UPDATE_CHAT,
  UPDATE_CHATS,
  RESET_CHATS,
  UPDATE_ONLINE_USERS,
  UPDATE_ONLINE_OFFLINE_USER,
  UPDATE_ROOM_USERS,
  TOGGLE_IMAGE_LOADER,
  UPDATE_CANVAS_DATA,
  UPDATE_PATH_DATA,
  TOGGLE_TEACHER_CANVAS,
  TOGGLE_CHAT_BADGE,
  CLEAR_CANVAS,
  INIT_CANVAS,
  UNDO_CANVAS,
  UPDATE_CLASS_JOIN,
  NEW_USER_JOIN,
  UPDATE_HAND_RAISE,
  UPDATE_PATH_ARRAY,
  EMPTY_PATH_ARRAY,
  POPPED_PATH_ARRAY,
  TOGGLE_DOUBLE_PIP,
  UPDATE_MY_HAND_RAISE,
  SWITCH_STUDENT_CANVAS,
  ON_RESETCHAT_BADGE,
  UPDATE_HAND_RAISE_Q,
  UPDATE_STUDENT_HAND_RAISE_Q,
  REMOVE_HAND_RAISE_Q,
  UPDATE_IN_HAND_RAISE_Q,
  ACCEPT_HAND_RAISE_Q,
  UPDATE_STUDENT_PAGES,
  SET_LIVE_CLASS,
  RESET_HAND_RAISE_Q,
  SET_CANVAS_REF,
  TOGGLE_CALL_MODAL,
  CLEAR_PAGES,
  UPDATE_TEACHER_STATUS,
  RESET_PARTICIPANTS,
  PEN_STROKE_SET,
  ERASER_STROKE_SET,
  UPDATE_CONF_ID,
  UPDATE_SCREEN_RECORDING_STATUS,
  UPDATE_CANVAS_RECONNECT,
  ALLOW_CANVAS_SWITCH,
  UPDATE_ALL_PAGES,
  UPDATE_AV_MUTE,
  UPDATE_SHOW_LOAD_EARLIER,
  UPDATE_USER_ROLE
} from 'actions/liveClass';

const initialState = {
  disableEnd: false,
  recordingStartedFromLiveClass: false,
  isScreenRecording: false,
  chats: [],
  callModalBool: true,
  switchStudentCanvas: 0,
  chatLoader: false,
  penStroke: 7,
  eraserStroke: 7,
  roomName: null,
  confId: null,
  currentPage: 0,
  roomUsers: [],
  offlineUsers: [],
  onlineUsers: [],
  imageLoader: false,
  canvasData: false,
  pathData: false,
  showTeacherCanvas: false,
  showChatBadge: false,
  chatCount: 0,
  canvas: false,
  isClassJoined: false,
  remoteStreams: [],
  isHandRaiseActive: false,
  handRaisedUser: false,
  showDoublePip: false,
  isMyHandRaise: false,
  pages: [
    {
      pageno: 1,
      isShowing: true,
      path: [],
    },
    {
      pageno: 2,
      isShowing: true,
      path: [],
    },
    {
      pageno: 3,
      isShowing: true,
      path: [],
    },
  ],
  handRaiseQArray: [],
  isInHandRaiseQ: false,
  isTeacherPresent: false,
  canvasReconnect: false,
  isCanvasSwitchAllowed: true,
  currentClassMode: false,
  showLoadEarlier: false,
  isTeacher: false
};

const reducer = (state = initialState, action) => {
  const { pages } = state;
  switch (action.type) {
    case UPDATE_SHOW_LOAD_EARLIER: {
      return {
        ...state,
        showLoadEarlier: action.data,
      };
    }
    case UPDATE_CLASS_MODE: {
      return {
        ...state,
        currentClassMode: action.mode,
      };
    }
    case UPDATE_AV_MUTE: {
      const avUsers = {};
      action.data.forEach(item => {
        if (typeof avUsers[item.user.id] === 'undefined') {
          avUsers[item.user.id] = item;
        }
      });
      const newOnlineUsers = state.onlineUsers.map(item => {
        if (typeof avUsers[item.id] !== 'undefined') {
          item = { ...item, muteAudio: avUsers[item.id].muteaudio };
        }
        return item;
      });
      return {
        ...state,
        onlineUsers: newOnlineUsers,
      };
    }
    case ALLOW_CANVAS_SWITCH:
      return {
        ...state,
        isCanvasSwitchAllowed: action.value,
      };
    case UPDATE_CANVAS_RECONNECT:
      return {
        ...state,
        canvasReconnect: action.value,
      };
    case UPDATE_SCREEN_RECORDING_STATUS:
      return {
        ...state,
        isScreenRecording: action.value,
      };
    case UPDATE_CONF_ID:
      return {
        ...state,
        confId: action.id,
      };
    case RESET_HAND_RAISE_Q:
      return {
        ...state,
        handRaiseQArray: [],
      };
    case UPDATE_TEACHER_STATUS:
      return {
        ...state,
        isTeacherPresent: action.value,
      };
    case ACCEPT_HAND_RAISE_Q:
      state.handRaiseQArray.forEach((item, i) => {
        if (item.id == action.user.id) {
          item.active = true;
        }
      });
      return {
        ...state,
        handRaiseQArray: [...state.handRaiseQArray],
      };
    case SET_CANVAS_REF:
      return {
        ...state,
        canvasRef: action.ref,
      };
    case SET_LIVE_CLASS:
      return {
        ...initialState,
      };
    case UPDATE_IN_HAND_RAISE_Q:
      return {
        ...state,
        isInHandRaiseQ: action.value,
      };
    case UPDATE_STUDENT_HAND_RAISE_Q:
      return {
        ...state,
        handRaiseQArray: action.users,
      };
    case UPDATE_HAND_RAISE_Q:
      return {
        ...state,
        handRaiseQArray: action.users,
      };
    case REMOVE_HAND_RAISE_Q:
      return {
        ...state,
        handRaiseQArray: state.handRaiseQArray.filter(
          item => item.id !== action.user.id,
        ),
      };
    case UPDATE_MY_HAND_RAISE:
      return {
        ...state,
        isMyHandRaise: action.value,
      };
    case TOGGLE_DOUBLE_PIP:
      return {
        ...state,
        showDoublePip: action.value,
      };
    case POPPED_PATH_ARRAY:
      pages[state.switchStudentCanvas].path.pop();
      return {
        ...state,
        pages,
      };
    case EMPTY_PATH_ARRAY:
      state.pages[state.switchStudentCanvas] = {
        pageno: state.switchStudentCanvas + 1,
        isShowing: true,
        path: [],
      };
      let newpages = [...state.pages]
      return {
        ...state,
        pages: newpages
      };
    case UPDATE_STUDENT_PAGES: {
      return {
        ...state,
        canvasReconnect: action.isReconnect || false,
        switchStudentCanvas: action.currentPage,
      };
    }
    case SWITCH_STUDENT_CANVAS:
      return {
        ...state,
        switchStudentCanvas: action.data,
      };
    case UPDATE_HAND_RAISE:
      return {
        ...state,
        isHandRaiseActive: action.value,
      };
    case NEW_USER_JOIN:
      if (state.remoteStreams.indexOf(action.data) === -1) {
        // If new user has joined
        return {
          ...state,
          remoteStreams: [...state.remoteStreams, action.data],
        };
      }
      return {
        ...state,
      };
    case UPDATE_CLASS_JOIN:
      return {
        ...state,
        isClassJoined: action.value,
      };
    case INIT_CANVAS:
      return {
        ...state,
        canvas: action.canvas,
      };
    case UNDO_CANVAS: {
      state.canvas.undo();
      const paths = state.canvas.getPaths();
      if (paths) {
        pages[state.switchStudentCanvas].path = paths;
      }
      return {
        ...state,
      };
    }
    case CLEAR_CANVAS:
      state.canvas.clear();
      return {
        ...state,
      };
    case TOGGLE_CHAT_BADGE:
      return {
        ...state,
        showChatBadge: action.value,
        chatCount: state.chatCount + 1,
      };
    case UPDATE_ALL_PAGES: {
      return {
        ...state,
        pages: [...action.pages],
      };
    }
    case UPDATE_PATH_ARRAY: {
      const newPages = state.pages.map((item, i) => {
        if (i !== action.currentPage) {
          return item;
        }
        return {
          ...item,
          ...item.path.push(action.path),
        };
      });
      return {
        ...state,
        pages: [...newPages],
      };
    }
    case ON_RESETCHAT_BADGE:
      return {
        ...state,
        chatCount: 0,
      };
    case TOGGLE_TEACHER_CANVAS:
      return {
        ...state,
        showTeacherCanvas: action.value,
      };
    case TOGGLE_CALL_MODAL:
      return {
        ...state,
        callModalBool: action.value,
      };
    case UPDATE_CANVAS_DATA:
      return {
        ...state,
        canvasData: action.data,
      };
    case UPDATE_PATH_DATA:
      return {
        ...state,
        pathData: action.data,
      };
    case TOGGLE_IMAGE_LOADER:
      return {
        ...state,
        imageLoader: action.value,
      };
    case UPDATE_ONLINE_USERS: {
      const obj = {};
      const onlineUsers = [];
      let isTeacherOnline = false;
      action.users.forEach(item => {
        obj[item.id] = item;
        // add teacher to online list
        if (item.isTeacher) {
          onlineUsers.push(item);
          isTeacherOnline = true;
        } else if (item.privilage?.includes('SuperAdmin') || item.privilage?.includes('Admin')) {
          onlineUsers.push(item);
        }
      });
      state.roomUsers.forEach(item => {
        if (typeof obj[item.id] !== 'undefined' && !item.isTeacher) {
          onlineUsers.push(item);
        }
      });

      const offlineUsers = state.roomUsers.filter(
        item => typeof obj[item.id] === 'undefined',
      );

      return {
        ...state,
        onlineUsers,
        offlineUsers,
        isTeacherPresent: isTeacherOnline,
      };
    }
    case UPDATE_ONLINE_OFFLINE_USER: {
      const online = [];
      const offline = [];
      if (action.payload.event === 'join') {
        const user = state.offlineUsers.find(
          item => item.id === action.payload.userId,
        );
        if (typeof user !== 'undefined') {
          online.push(user);
        }
        const index = state.offlineUsers.indexOf(user);
        if (index > -1) {
          state.offlineUsers.splice(index, 1);
        }
      } else {
        const user = state.onlineUsers.find(
          item => item.id === action.payload.userId,
        );
        if (typeof user !== 'undefined') {
          offline.push(user);
        }
        const index = state.onlineUsers.indexOf(user);
        if (index > -1) {
          state.onlineUsers.splice(index, 1);
        }
      }
      return {
        ...state,
        onlineUsers: [...state.onlineUsers, ...online],
        offlineUsers: [...state.offlineUsers, ...offline],
      };
    }
    case UPDATE_CHAT:
      let newChats = state.chats ? [...state.chats] : []
      newChats.push(action.chat);
      return {
        ...state,
        chats: newChats
      }
    case PEN_STROKE_SET:
      return {
        ...state,
        penStroke: action.value,
      };
    case ERASER_STROKE_SET:
      return {
        ...state,
        eraserStroke: action.value,
      };
    case UPDATE_CHATS:
      return {
        ...state,
        chats: state.chats.concat(action.chats),
        currentPage: action.currentPage,
      };
    case RESET_CHATS:
      return {
        ...state,
        chats: action.chats,
        currentPage: action.currentPage,
      };
    case RESET_PARTICIPANTS:
      return {
        ...state,
        onlineUsers: [],
        offlineUsers: [],
      };
    case TOGGLE_CHAT_LOADER:
      return {
        ...state,
        chatLoader: action.value,
      };
    case UPDATE_ROOM_NAME:
      return {
        ...state,
        roomName: action.roomName,
      };
    case UPDATE_ROOM_USERS:
      return {
        ...state,
        roomUsers: action.users,
        offlineUsers: action.users,
      };
    case CLEAR_PAGES: {
      const emptyPages = [
        {
          pageno: 1,
          isShowing: true,
          path: [],
        },
        {
          pageno: 2,
          isShowing: true,
          path: [],
        },
        {
          pageno: 3,
          isShowing: true,
          path: [],
        },
      ];
      return {
        ...state,
        pages: emptyPages.slice(0),
        pathData: false,
        switchStudentCanvas: 0,
        showTeacherCanvas: false,
      };
    }
    case UPDATE_USER_ROLE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
