import React, {useEffect, useState} from 'react'
import { Row, Col, Menu, Dropdown, DatePicker, Tooltip } from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import SearchBox from "./localComponents/searchBox"
import CreateTestModal from 'pages/practiseTest/modals/createTestModal';
import { MdPublish } from "react-icons/md";
import TestCard from "./localComponents/practiseTestCards"
import CustomMenu from 'pages/practiseTest/localComponents/customMenu';
import { MdMoreVert } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { createTestModal, fetchPracticeTestDashboard, fetchStandards, setSelectedClassroom, updateListViewType, updateFilter, updateRole } from 'actions/practiseTest';
import { connect } from 'react-redux';
import PageDataLoader from "components/pageDataLoader"
import Loader from './localComponents/loader';
import ConfirmationModal from './modals/confirmationModal';
import TestPublishModal from './localComponents/testPublishModal';
import BackToTop from 'components/backToTop';
import PageHeader from 'components/pageHeader';
import {Button} from 'components/Button';
import { InputSearch } from 'components/customInputs';

//Imgs
const chapterTestImg = require("../../Assets/Practise Test/ic-chapter-test.svg").default
const fullTestImg = require("../../Assets/Practise Test/ic-full-test.svg").default
const partTestImg = require("../../Assets/Practise Test/ic-part-test.svg").default
const previousTestImg = require("../../Assets/Practise Test/ic-previous-test.svg").default
const chapterImg = require("../../Assets/Practise Test/Group 12505.svg").default
const fullImg = require("../../Assets/Practise Test/Group 12506.svg").default
const partImg = require("../../Assets/Practise Test/Group 12507.svg").default
const previousImg = require("../../Assets/Practise Test/Group 12725.svg").default

  const bodyStyle = {background: "#FAFAFA",
    padding:20,
    margin: -20
  }

    export function NodataCard({
        containerheight = "80%",
        imgwidth = "30%",
        imgheight = "30%",
      }) {
        return (
          <div className="r-c-c-c" style={{ height: containerheight }}>
            <img
              style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
              src={require("../../Assets/test/no-subjects.svg").default}
              alt=""
            />
            <div className="r-c-c-c">
              <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
              <p style={{ color: "#636363", fontFamily: "roboto" }}>
                There is no data to show yet. Please check back later.
              </p>
            </div>
          </div>
        );
      }
  

    const ChapterTestCard = ({count, lastweek}) => 
    {
    return(
        <div className='r-afs display-flex flex-wrap full-height m-t-20' style={{overflow:"auto"}}>
            <div style={{
                minWidth: 280,
                maxWidth: 280,
                backgroundColor: "#FFFFFF", 
                border: "1px solid #E6E6E6", 
                borderRadius: 10,
                height:100,
                marginLeft: 10
                }}
                className="r-c-fs p-10 customCardShadow shadow-box hover-shadow">
                <img src={chapterTestImg} style={{width:"50px", height:"50px"}}/> 
                <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-10">
                    <div style={{font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight:"500"}}>
                            {count}
                    </div>
                    <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12}}>
                        Chapter Test
                    </div>
            {lastweek==undefined || lastweek == 0 ? null : 
            lastweek < 0 ? 
            <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 , fontWeight:'700', color:"#FF414D"}}>
             {lastweek} from last week</div>
            :
            lastweek > 0 ? 
            <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 ,fontWeight:'700', color:"green"}}>
            + {lastweek} from last week
            </div>
            :
            null}
                </div>
            </div>
    </div>
    )
}

const FullTestCard = ({count, lastweek}) => 
{
    return(
        <div className='r-afs display-flex flex-wrap full-height m-t-20' style={{overflow:"auto"}}>
            <div style={{
                minWidth: 280,
                maxWidth: 280, 
                backgroundColor: "#FFFFFF", 
                border: "1px solid #E6E6E6", 
                borderRadius: 10,
                height:100,
                marginLeft: 10
                }}
                className="r-c-fs p-10 customCardShadow shadow-box hover-shadow">
              <Tooltip><img src={fullTestImg} style={{width:"50px", height:"50px"}}/></Tooltip>
                <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-10">
                    <div style={{font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight:"500"}}>
                    {count}
                    </div>
                    <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12}}>
                        Full Test
                    </div>
            {lastweek==undefined || lastweek == 0 ? null : 
              lastweek < 0 ? 
                <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 , fontWeight:'700', color:"#FF414D"}}>
                 {lastweek} from last week
                </div>
            :
              lastweek > 0 ? 
                <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 ,fontWeight:'700', color:"green"}}>
                + {lastweek} from last week
              </div>
            :
                null}
            </div>
            </div>
    </div>
    )
}


const PartTestCard = ({count, lastweek}) => 
{
    return(
        <div className='r-afs full-height m-t-20' style={{ overflow:"auto"}}>
            <div style={{
                minWidth: 280,
                maxWidth: 280,
                backgroundColor: "#FFFFFF", 
                border: "1px solid #E6E6E6", 
                borderRadius: 10,
                height:100,
                marginLeft: 10
                }}
                className="r-c-fs p-10 customCardShadow shadow-box hover-shadow">
                <img src={partTestImg} style={{width:"50px", height:"50px"}}/> 
                <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-10">
                    <div style={{font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight:"500"}}>
                    {count}

                    </div>
                    <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12}}>
                        Part Test
                    </div>
            {lastweek==undefined || lastweek == 0 ? null : 
              lastweek < 0 ? 
                <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 , fontWeight:'700', color:"#FF414D"}}>
                 {lastweek} from last week
                </div>
            :
              lastweek > 0 ? 
                <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 ,fontWeight:'700', color:"green"}}>
                + {lastweek} from last week
              </div>
            :
                null}
                </div>
            </div>
    </div>
    )
}

const PreviousTestCard = ({count, lastweek}) => 
{
    return(
        <div className='r-afs display-flex flex-wrap full-height m-t-20' style={{overflow:"auto"}}>
            <div style={{
                minWidth: 280,
                maxWidth: 280,
                backgroundColor: "#FFFFFF", 
                border: "1px solid #E6E6E6", 
                borderRadius: 10,
                height:100,
                marginLeft: 10
                }}
                className="r-c-fs p-10 customCardShadow shadow-box hover-shadow">
                <img src={previousTestImg} style={{width:"50px", height:"50px"}}/> 
                <div style={{display: "flex", flexDirection: "column", margin: "15 0 0 15"}} className="m-l-10">
                    <div style={{font: "Roboto", textAlign: "left", fontSize: 14, color: "#191919", fontWeight:"500"}}>
                    {count}
                    </div>
                    <div style={{font: "Roboto bold", textAlign: "left", fontSize: 12}}>
                        Previous Year Papers
                    </div>
                    {lastweek==undefined || lastweek == 0 ? null : 
              lastweek < 0 ? 
                <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 , fontWeight:'700', color:"#FF414D"}}>
                 {lastweek} from last week
                </div>
            :
              lastweek > 0 ? 
                <div style={{font: "Roboto bold", textAlign: "left", fontSize: 10 ,fontWeight:'700', color:"green"}}>
                + {lastweek} from last week
              </div>
            :
                null}
                </div>
            </div>
    </div>
    )
}

const SubjectCard = ({history, DATA,setSelectedClassroom, classname }) => {
    return(
    <div onClick={()=>{
      setSelectedClassroom({name:classname+' - '+DATA.section, id:DATA.id, classname, sectionid:DATA.section});
      history.push("practise-test-syllabus-page")}} style={{
        width: 240,
        backgroundColor: "#FFFFFF", 
        border: "1px solid #E6E6E6", 
        borderRadius: 10,
        height:160,
        marginRight: 10,
        marginBottom: 10
        }}
        className="r-c-c p-10 customCardShadow cursor-pointer shadow-box hover-shadow">
           <div className='full-width m' style={{marginTop: "-20px", display: "flex",flexDirection:"column", alignItems: "center"}}>
            <div style={{color: "#594099", fontSize: 30, fontFamily: "serif"}}>{DATA.section}</div>
            <div className='m-b-30' style={{marginTop: "-10px"}}>{DATA.stream}</div>
            <div className='r-c-sa full-width'>
                <div className='display-flex' style={{width: 20, height: 20}}>
                <Tooltip placement="topRight" trigger={'hover'} title={'Chapter Test'} overlayInnerStyle={{borderRadius:5}}>
                        <img style={{width:20,height:20,marginRight:5}}  src={chapterImg} alt="" />
                </Tooltip>                  
                <div className='m-l-5 text-xs'>{DATA.chapterTestCount}</div>
                </div>
                <div className='display-flex' style={{width: 20, height: 20}}>
                <Tooltip placement="topRight" trigger={'hover'} title={'Part Test'} overlayInnerStyle={{borderRadius:5}}>
                        <img style={{width:20,height:20,marginRight:5}}  src={fullImg} alt="" />
                </Tooltip>      
                <div className='m-l-5 text-xs'>{DATA.partTestCount}</div>
                </div>
                <div className='display-flex' style={{width: 20, height: 20}}>
                <Tooltip placement="topRight" trigger={'hover'} title={'Full Test'} overlayInnerStyle={{borderRadius:5}}>
                        <img style={{width:20,height:20,marginRight:5}}  src={partImg} alt="" />
                </Tooltip>      
                <div className='m-l-5 text-xs'>{DATA.fullTestCount}</div>
                </div>
                <div className='display-flex' style={{width: 20, height: 20}}>
                <Tooltip placement="topRight" trigger={'hover'} title={'Previous Year Paper'} overlayInnerStyle={{borderRadius:5}}>
                        <img style={{width:20,height:20,marginRight:5}}  src={previousImg} alt="" />
                </Tooltip> 
                <div className='m-l-5 text-xs'>{DATA.previousYearPaper}</div>
                </div>
            </div>
        </div>
        </div>
    )}

let timer = false;

function PractiseTest({fetchPracticeTestDashboard, homePageData,classTestInfo, fetchStandards,updateFilter, filter, 
  setSelectedClassroom,createTestModal, updateListViewType, user,createTestModalBool, loaders, updateRole, 
  isCModalVisible, cModalType,reloadToggler, userType }) {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const history = useHistory();
    console.log({homePageData})
 
    const lastWeekChapter = homePageData?.overview?.incrementFromLastWeek?.chapter;
    const lastWeekFull = homePageData?.overview?.incrementFromLastWeek?.full;
    const lastWeekPart = homePageData?.overview?.incrementFromLastWeek?.part;
    const lastWeekPrevious = homePageData?.overview?.incrementFromLastWeek?.previousYear;

    const showModal = () => {
      updateListViewType("create");
      createTestModal("test-create", user, "home");
    }
    const onSearch = (e) => {
      updateFilter('indexSearch', e.target.value);
  } 
  const handleVAclearUp = () => {
    updateFilter('isTabPage', false);
    updateFilter('isVA', true);
    updateFilter('VAsearch', null);
    updateFilter('VAclasses', []);
    updateFilter('VAsyllabus', []);
    updateFilter('isVAdatefiltered', false);
    updateFilter('vaDate', null);
  }

    useEffect(() => {
      updateRole();
      updateFilter('indexSearch', null);
      fetchStandards(()=>{})
      fetchPracticeTestDashboard(()=>{})
      return () => {
      }
    }, [reloadToggler])

    useEffect(()=>{
      clearTimeout(timer);
      timer = setTimeout(()=>{
        fetchStandards(()=>{});
      }, 1000);
    }, [filter?.indexSearch]);

    // const buttons = [
    //   {label: 'CREATE TEST', onClick: ()=>{showModal()}}
    // ]

    const actions = [
    <Button type="primary" onClick={()=>{showModal()}} style={{}} icon={<PlusCircleOutlined/>}>CREATE PRACTICE TEST</Button>
    ]

  return (

 <div style={bodyStyle}>
    <div style={{ marginBottom: 50}}>
      <PageHeader title='Practice Test' actions={actions}/>  
    </div>
    <div>
      <div className="bold-800 text-xmd m-t-50 ">Overview</div>
        <div style={{display: "flex", flexWrap: "wrap" }}>
          <ChapterTestCard count={homePageData?.overview?.chapterTestCount} lastweek={lastWeekChapter}/>
          <PartTestCard count={homePageData?.overview?.partTestCount} lastweek={lastWeekPart}/>
          <FullTestCard count={homePageData?.overview?.fullTestCount} lastweek={lastWeekFull}/>
          <PreviousTestCard count={homePageData?.overview?.previousYearPaperCount} lastweek={lastWeekPrevious}/>
        </div>
        {
          userType==='admin' && homePageData?.readyToPublish?.length?
          <>
            <div className="r-c-sb">
              <h1 className="userCard-heading m-t-40" style={{fontSize:"14px"}}>Test(s) ready to be published</h1>
              <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{padding: 0, width:"20%"}}
                  onClick={()=>{
                    handleVAclearUp();
                    updateFilter('VAtype', 'ready');
                    history.push('practise-test-view-all')}}>
                      VIEW ALL
              </Button>
            </div>
            <div className='display-flex flex-wrap'>
              {homePageData?.readyToPublish?.map((item) => ( 
                <TestCard type={'ready'} page={'index'} test={item} imgval={chapterTestImg}/>
                ))
              }
            </div>
          </>
          :null
        }
        {(userType == "dataOperator" || userType == "admin") && homePageData?.feedback?.length ? (
        <>
        <div className="r-c-sb">
                <h1 className="userCard-heading m-t-40" style={{ fontSize: "14px" }}>
                  Feedback from teacher(s)
                </h1>
                  <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{padding: 0, width:"20%"}}
                                      onClick={()=>{
                                        updateFilter('VAtype', 'feedback');
                                        handleVAclearUp();
                                        history.push('practise-test-view-all')}}>
                    VIEW ALL
                  </Button>
          </div>
          <div className='display-flex flex-wrap'>
              {homePageData?.feedback?.map((item)=> (
                  <TestCard type="feedback" page="home" test={item} />))
              }
          </div>
          </>):null}
          {userType == "teacher" || userType == "admin" ? (
        <>
          {homePageData?.review?.length > 0 ? (
            <>
              <div className="r-c-sb">
                <h1 className="userCard-heading m-t-40" style={{ fontSize: "14px" }}>
                  Test(s) to be reviewed
                </h1>
                  <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{padding: 0, width:"20%"}}
                                      onClick={()=>{
                                        updateFilter('VAtype', 'review');
                                        handleVAclearUp();
                                        history.push('practise-test-view-all')}}>
                    VIEW ALL
                  </Button>
              </div>
              <div className="r-c-fs flex-wrap">
                {homePageData?.review?.map((test, index) => (
                  <TestCard type="review" test={test} key={index} index={index} page={"home"}/>
                ))}
              </div>
            </>
          ) : null}
        </>
      ) : null}
      <div className="m-t-50"  style={{display: "flex", justifyContent: "space-between"}}>
          <div className="bold-800 text-xmd" style={{color: "#191919"}}>All Classes</div>  
          <InputSearch allowClear onClear={()=>{onSearch('')}} loading={false} onInputChange={onSearch} className="m-r-40" placeholder="Search by class name" style={{Width: 270}}/>
        </div>
      </div>
        <div>
      {loaders?.indexSearchLoader?
          <Loader style={{fontSize:24}}/>
          :
            classTestInfo && Object.keys(classTestInfo)?.length>0?
            Object.keys(classTestInfo)?.map((key) => (
              <>
                <div key={key}>
                  <h2
                    className="userCard-heading m-t-10"
                    style={{ fontSize: "14px" }}
                  >
                    {key}
                  </h2>
                </div>
                <div className="display-flex flex-wrap" >
                  {Array.isArray(classTestInfo[key])
                    ? classTestInfo[key]?.map((classcardData, index) => (
                      <SubjectCard 
                      DATA={classcardData}
                      history={history}
                      setSelectedClassroom={setSelectedClassroom}
                      classname={key}
                      />
                      ))
                    : null}
                </div>
              </>
            ))
            :<NodataCard/>
          }
           {createTestModalBool && <CreateTestModal history={history} createTestModalBool={createTestModalBool}/>}
           <PageDataLoader visible={loaders?.indexLoader}/>
           <TestPublishModal history={history}  />
           <ConfirmationModal
              isCModalVisible={isCModalVisible}
              cModalType={cModalType}
            />
            <BackToTop/>
     </div>
    </div>
  )
}


const mapStateToProps = state =>{
  const {homePageData, classTestInfo, filter, createTestModalBool, loaders, isCModalVisible, 
    cModalType, reloadToggler, userType}=state.practiseTest
  const {user} = state.session;
  return {homePageData, classTestInfo, filter, user, createTestModalBool, loaders, 
    isCModalVisible, cModalType, reloadToggler, userType}
}

const mapDispatchToProps = dispatch=> ({
  fetchPracticeTestDashboard: (callback)=> dispatch(fetchPracticeTestDashboard(callback)),
  fetchStandards: (callback)=> dispatch(fetchStandards(callback)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  setSelectedClassroom: (val) => dispatch(setSelectedClassroom(val)),
  updateListViewType: (data) => dispatch(updateListViewType(data)),
  createTestModal: (name, user, source) =>
  dispatch(createTestModal(name, user, source)),
  updateRole: () => dispatch(updateRole())
})

export default  connect(mapStateToProps, mapDispatchToProps)(PractiseTest); 

