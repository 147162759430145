import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Tooltip, Typography } from 'antd';
import "./styles.css";
import pencilOutlined from "../Assets/layout/newFilledIcons/dark-pencil.svg";
import QuickAccessModal from "./quickAccessModal";
import { setMenuSettingsData } from "actions/userManagementV2";
import { connect } from "react-redux";
import { useHistory, withRouter } from 'react-router';
import addUserEnabled from "../Assets/layout/newFilledIcons/add-user-enabled.svg";
import addUserDisabled from "../Assets/layout/newFilledIcons/add-user-disabled.svg";
import addFeeEnabled from "../Assets/layout/newFilledIcons/add-fee-enabled.svg";
import addFeeDisabled from "../Assets/layout/newFilledIcons/add-fee-disabled.svg";
import addScheduleEnabled from "../Assets/layout/newFilledIcons/add-schedule-enabled.svg";
import addScheduleDisabled from "../Assets/layout/newFilledIcons/add-schedulle-disabled.svg";
import addAnnounceEnabled from "../Assets/layout/newFilledIcons/add-announcement-enabled.svg";
import addAnnounceDisabled from "../Assets/layout/newFilledIcons/add-announcement-disabled.svg";
import markAttendanceEnabled from "../Assets/layout/newFilledIcons/mark-attendance-enabled.svg";
import markAttendanceDisabled from "../Assets/layout/newFilledIcons/mark-attendance-disabled.svg";
import applyLeaveEnabled from "../Assets/layout/newFilledIcons/apply-leave-enabled.svg";
import applyLeaveDisabled from "../Assets/layout/newFilledIcons/apply-leave-disabled.svg";
import createCourseEnabled from "../Assets/layout/newFilledIcons/cretae-course-enabled.svg";
import createCourseDisabled from "../Assets/layout/newFilledIcons/create-course-disabled.svg";
import addResourceEnabled from "../Assets/layout/newFilledIcons/add-resource-enabled.svg";
import addResourceDisabled from "../Assets/layout/newFilledIcons/add-resource-disabled.svg";
import addTestEnabled from "../Assets/layout/newFilledIcons/add-test-enabled.svg";
import addTestDisabled from "../Assets/layout/newFilledIcons/add-test-disabled.svg";
import menuCollapsed from "../Assets/layout/newFilledIcons/collapsed.svg";
import addUserWhite from "../Assets/layout/newFilledIcons/addUserWhite.svg";
import addFeeWhite from "../Assets/layout/newFilledIcons/addFeeWhite.svg";
import addScheduleWhite from "../Assets/layout/newFilledIcons/addScheduleWhite.svg";
import addAnnouncementWhite from "../Assets/layout/newFilledIcons/addAnnouncementWhite.svg";
import markAttendanceWhite from "../Assets/layout/newFilledIcons/markAttendanceWhite.svg";
import applyLeaveWhite from "../Assets/layout/newFilledIcons/applyLeaveWhite.svg";
import createCourseWhite from "../Assets/layout/newFilledIcons/createCourseWhite.svg";
import addResourceWhite from "../Assets/layout/newFilledIcons/addResourceWhite.svg";
import addTestwhite from "../Assets/layout/newFilledIcons/addTestwhite.svg";


const CollapseQuickAccessModal = ({ items, updateUserMenuSettings, userId, fetchUserMenuSettingsData, allItems, userMenusettingsData, menuSettingsData, setDropdownVisibleCollapse, setCourseTypeName, setQuickAccessItem, setEditClick, editClick, dropdownVisibleCollapse }) => {


    const iconMap = {
        "Add user": {
          enabled: addUserEnabled,
          disabled: addUserDisabled,
          white: addUserWhite,
        },
        "Add fee request": {
          enabled: addFeeEnabled,
          disabled: addFeeDisabled,
          white: addFeeWhite,
        },
        "Add schedule": {
          enabled: addScheduleEnabled,
          disabled: addScheduleDisabled,
          white: addScheduleWhite,
        },
        "Add announcement": {
          enabled: addAnnounceEnabled,
          disabled: addAnnounceDisabled,
          white: addAnnouncementWhite,
        },
        "Mark attendance": {
          enabled: markAttendanceEnabled,
          disabled: markAttendanceDisabled,
          white: markAttendanceWhite,
        },
        "Apply leave": {
          enabled: applyLeaveEnabled,
          disabled: applyLeaveDisabled,
          white: applyLeaveWhite,
        },
        "Create course": {
          enabled: createCourseEnabled,
          disabled: createCourseDisabled,
          white: createCourseWhite,
        },
        "Add resource": {
          enabled: addResourceEnabled,
          disabled: addResourceDisabled,
          white: addResourceWhite,
        },
        "Add test": {
          enabled: addTestEnabled,
          disabled: addTestDisabled,
          white: addTestwhite,
        },
      };


    const [selectedItems, setSelectedItems] = useState([]);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const history = useHistory();
    useEffect(() => {

        if (!dropdownVisible1) {
                    updateUserMenuSettings(userId, () => { }, () => { fetchUserMenuSettingsData(userId, () => { }, () => { setSelectedItems(userMenusettingsData?.menuSettings) }) })
            fetchUserMenuSettingsData(userId, () => { }, () => { setSelectedItems(userMenusettingsData?.menuSettings); })
            setSelectedItems(userMenusettingsData?.menuSettings);
        }

    }, [dropdownVisible1]);

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        // Close dropdown if click is outside the container
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdownVisible1(false);
        }
      };

      useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Cleanup event listener
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

  

    return (
        <div className="quick-access-menu" style={{ width: 'fit-content', marginLeft: 60, marginTop: -40 }}>

            {items.map((item, index) => (


                <div key={index}
                    style={{ margin: 3 }}
                    onClick={() => {
                        if (item?.label == 'Add user') {
                            setQuickAccessItem("addUser", true)
                            history.push({
                                pathname: "/user-management", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Add fee request') {
                            setQuickAccessItem("addFee", true)
                            history.push({
                                pathname: "/fee-management", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Add schedule') {
                            setQuickAccessItem("addSchedule", true)
                            history.push({
                                pathname: "/schedule", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Add announcement') {
                            setQuickAccessItem("addAnnounce", true)
                            history.push({
                                pathname: "/announcement", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Create course') {
                            setCourseTypeName('liveCourses')
                            history.push({
                                pathname: "/add-live-courses", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Add test') {
                            setQuickAccessItem("addTest", true)
                            history.push({
                                pathname: "/test", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Add resource') {
                            setQuickAccessItem("addResource", true)
                            history.push({
                                pathname: "/digital-library/division-card", state: {
                                    key: {
                                        fromSideMenu: true,
                                    }
                                }
                            })
                        } else if (item?.label == 'Mark attendance') {
                            setQuickAccessItem("markAttendance", true)
                            history.push({
                                pathname: "/user-profile/others",
                            })
                        } else if (item?.label == 'Apply leave') {
                            setQuickAccessItem("applyLeave", true)
                            history.push({
                                pathname: "/user-profile/others",
                            })
                        }

                    }}
                >
                    
                    <div
                        className="collapse-menu-item"
                        style={{ display: 'flex', padding: '10px', cursor: 'pointer', paddingTop: index == 0 ? 13 : 9 }}
                        onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
                        onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
                    >
                        <div>
                            <img
                                 src={
                                    hoveredIndex === index
                                        ? iconMap[item.label]?.white // Use white icon when hovered
                                        : iconMap[item.label]?.enabled // Use enabled icon when not hovered
                                }
                                style={{ height: 24, width: 24 }}
                                alt="menu-icon"
                            />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 20 }}>{item?.label}</div>
                    </div>
                </div>
            ))}
            <div className="collapse-menu-item" style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>


                <div className='custom-dropdown-position'>

                    <div style={{ display: 'flex' }}><img src={pencilOutlined} style={{ height: 20, width: 20 }} onClick={() => {
                        setDropdownVisible1(!dropdownVisible1)
                        setEditClick(true);
                        // setDropdownVisibleCollapse(false);
                    }} />
                        <div style={{ marginLeft: 15, alignItems: 'center', paddingBottom: 3 }}
                        onClick={() => {
                            setDropdownVisible1(!dropdownVisible1)
                            setEditClick(true);
                            // setDropdownVisibleCollapse(false);
                        }} >Edit list</div>
                    </div>

                    {dropdownVisible1 && (
                        <div
                        ref={dropdownRef}
                            style={{
                                position: "absolute",
                                top: -37, // Fixed position on the screen
                                left: 60,
                                width: "250px",
                             
                                
                            }}
                        >
                            <QuickAccessModal
                                selectedItems={selectedItems}
                                allItems={allItems}
                                setSelectedItems={setSelectedItems}
                                setEditClick={setEditClick}
                                editClick={editClick}
                            />
                        </div>
                    )}
                </div>
              
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    const { userMenusettingsData, menuSettingsData } = state.userManagementV2
    return {
        userMenusettingsData, menuSettingsData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMenuSettingsData: (res) => dispatch(setMenuSettingsData(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseQuickAccessModal)
