export const getCourseData = (storePieChartData) => {
    return [
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[0]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[0]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[0]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[0]?.subtitle,
            tooltip: 'courseDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[1]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[1]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[1]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[1]?.subtitle,
            tooltip: 'courseDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[2]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[2]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[2]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[2]?.subtitle,
            tooltip: 'courseDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.courseDetails[3]?.type,
            value: storePieChartData?.graph?.pieChart?.courseDetails[3]?.percentage,
            count: storePieChartData?.graph?.pieChart?.courseDetails[3]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[3]?.subtitle,
            tooltip: 'courseDec'
        },
    ];
};

export const getStudentData = (storePieChartData) => {
    return [
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[0]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[0]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[0]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[0]?.subtitle,
            tooltip: 'studentDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[1]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[1]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[1]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[1]?.subtitle,
            tooltip: 'studentDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[2]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[2]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[2]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[2]?.subtitle,
            tooltip: 'studentDec'
        },
        {
            type: storePieChartData?.graph?.pieChart?.studentDetails[3]?.type,
            value: storePieChartData?.graph?.pieChart?.studentDetails[3]?.percentage,
            count: storePieChartData?.graph?.pieChart?.studentDetails[3]?.count,
            subtitle: storePieChartData?.graph?.pieChart?.courseDetails[3]?.subtitle,
            tooltip: 'studentDec'
        },
    ];
};
