import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Avatar from 'antd/lib/avatar';
import Popover from 'antd/lib/popover';
import Tag from 'antd/lib/tag';
import PageHeader from 'antd/lib/page-header';
import Modal from 'antd/lib/modal';
import Pagination from 'antd/lib/pagination';
import Empty from 'antd/lib/empty';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/popover/style/index';
import 'antd/lib/tag/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';
import { FileTextOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined,
ExclamationCircleOutlined, CloseCircleOutlined, LoadingOutlined, EyeOutlined} from '@ant-design/icons';
import { GoPencil } from 'react-icons/go';
import { FiChevronDown } from "react-icons/fi";
import { FaRegCommentAlt } from "react-icons/fa";
import { MdPublish, MdDelete } from "react-icons/md";
import TestUpdateModal from './modals/updateTest';
import{ setTestParams, toggleTestModal, getTestById, unmarkForReview, updateTestStatus, fetchStandards, toggleQuestionModal,
  setCurrentTestQuestion, deleteTestQuestion, setCurrentTest, toggleViewQuestionModal, notifyTeacher } from '../../actions/test_old';
import './styles.css';
import { Color } from '../../services';
import QuestionModal from './modals/questionModal'
import InstructionModal from './modals/instructionModal'
import ReactHtmlParser from 'react-html-parser';
import TeX from '@matejmazur/react-katex';
import ViewQuestionModal from './modals/viewQuestionModal'
import { Col, Row } from 'antd';

const { confirm } = Modal;
const pageSize = 20;


class TestLoggerView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  async componentDidMount(){
    const {currentTestDetails, getTestById} = this.props;
    // const page = testQPageMeta &&  testQPageMeta.currentPage  ? testQPageMeta.currentPage :  1;
    await getTestById(currentTestDetails.id, 1);
    this.props.fetchStandards(this.props.currentTestDetails.subjectIds);
  }

  showDeleteTaskConfirm(id, marks, self) {
    return confirm({
      title: `Do you want to delete this question?`, //${text}
      icon: <CloseCircleOutlined style={{color: Color.red}}/>,
      content: 'Please click OK to confirm',
      onOk() {self.props.deleteTestQuestion(id, marks);},
      onCancel() {},
    })
  }

  handleEditQuestionModal = (data) => {
    const { setCurrentTestQuestion, toggleQuestionModal } =  this.props;
    setCurrentTestQuestion(data);
    toggleQuestionModal(true);
  }

  async openEditModal(){
    this.props.setTestParams();
    this.props.toggleTestModal(true);
    // const {currentTestDetails, handleAssignmentInput, fetchClasslist, toggleAssignmentModal} = this.props;
    // await fetchClasslist(currentTestDetails.subjectId);
    // handleAssignmentInput(currentTestDetails.standardIds.map(id => parseInt(id)),'standardIds');
    // handleAssignmentInput(currentTestDetails.id,'id');
    // handleAssignmentInput(currentTestDetails.title,'title');
    // handleAssignmentInput(currentTestDetails.sub,'selectedSubject');
    // handleAssignmentInput(currentTestDetails.subjectId,'subjectId');
    // handleAssignmentInput(currentTestDetails.author.name,'selectedTeacher');
    // handleAssignmentInput(currentTestDetails.teacherId,'teacherId');
    // handleAssignmentInput(currentTestDetails.gradingType,'gradingType');
    // handleAssignmentInput(currentTestDetails.deadlineDate,'deadlineDate');
    // handleAssignmentInput(currentTestDetails.resultDueDate,'resultDueDate');
    // handleAssignmentInput(currentTestDetails.instructions,'instructions');
    // handleAssignmentInput(currentTestDetails.chapterNames,'chapterNames');
    // handleAssignmentInput(currentTestDetails.chapterIds,'chapterIds');
    // handleAssignmentInput(currentTestDetails.index,'index');

    // toggleAssignmentModal(true)
  }


  // componentWillUnmount(){
    // this.props.setCurrentTest(false);
  // }


  showConfirm(action, self) {
    const icon = action === "Delete" ? <CloseCircleOutlined style={{color: Color.red}}/> : <ExclamationCircleOutlined />
    return confirm({
      title: `Do you want to ${action} this test?`, //${text}
      icon: icon,
      content: 'Please click OK to confirm',
      onOk() {self.handleConfirmClick(action)},
      onCancel() {},
    })
  }

  handleConfirmClick(action){
    action = action === "Delete" ? "Deleted" : action === "Publish" ? "Published" : null
    const params =  this.props.currentTestDetails;
    this.props.updateTestStatus(params, action, this.props.history)
  }

  handlePageChange(page) {
    const { getTestById, currentTestDetails } = this.props;
    getTestById(currentTestDetails.id, page)
  }

  handleViewQuestionModal = (data) => {
    const { toggleViewQuestionModal, setCurrentTestQuestion } =  this.props;
    setCurrentTestQuestion(data);
    toggleViewQuestionModal(true);
  }

  publishDDl = () => {
    const { access } = this.props.user;
    const { currentTestDetails } =  this.props;
    const statusList = access.includes('assignment-teacher') && currentTestDetails.status !== "Published"? [
      {
        text: 'Publish',
        icon: <MdPublish />
      },
      {
        text: 'Delete',
        icon: <MdDelete />
      }
    ] : [{
      text: 'Delete',
      icon: <MdDelete />
    }
    ]
    return (
      <Menu className='assignmentddlmenu'>
        {statusList.map((data, index) =>
          <Menu.Item key={index}>
            <div
              onClick={() =>
                this.showConfirm(data.text, this)
              }
              className='color-purple p-l-10 p-r-10'
            >
            <Menu.Divider/>
              {data.icon} {data.text}
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  taskChange = (data, history) => (
    <Menu className='assignmentddlmenu'>
      <Menu.Item key="0">
        <div onClick={() => this.handleViewQuestionModal(data)}>
          <EyeOutlined /> View
        </div>
      </Menu.Item>
      {this.props.currentTestDetails.status !== "Published" && <>
      <Menu.Item key="0">
        <div onClick={() => this.handleEditQuestionModal(data)}>
          <EditOutlined /> Edit
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div onClick={() => {
            this.showDeleteTaskConfirm(data.id, data.positiveMarks, this);
          }}>
          <DeleteOutlined /> Delete
        </div>
      </Menu.Item> 
      </>}
    </Menu>
  );

  testDataBox = () => {
    const { testQPageMeta, testQList, currentTestDetails, statusLoader, totalPoints, notifyTeacher, testTeacherReminderLoader } = this.props
    return (
      <div className='assignmentShadowBox m-b-10 r-jsb'>
        <div className='text-xmd bold firstCapital dark-grey'>
          Title: <br/>
          Subjects: <br/>
          Chapters: <br/>
          Standards: <br/>
          {/*Chapters: <br/>*/}
        </div>
        <div className='m-l-10 text-xmd color-purple flex-1'>
          <span className='r-c-fs'>
          {currentTestDetails.title}
          <Tag className='m-l-5' color={currentTestDetails.status === 'Draft' ? 'red' : currentTestDetails.status === 'Published' ? 'green' : 'transparent'}>
            {currentTestDetails.status}
          </Tag>
            <InfoCircleOutlined
              className='cursor-pointer'
              onClick={() => this.setState({ToggleInstructionModal: true})}
            />
          </span>
          <div>{currentTestDetails.subjectNames.map((sub, index, array) => (<>{sub} {index === array.length - 1 ? '' : ','} </>))} | <span style={{fontWeight: 600}}>{currentTestDetails.teacherName}</span></div>
          <div>{currentTestDetails.chapterNames.map((chap, index, array) => (<>{chap} {index === array.length - 1 ? '' : ','} </>))}</div>
          <div>{currentTestDetails.standardNames.split(",").map((std, index, array) => (<>{std} {index === array.length - 1 ? '' : ','} </>))}</div>
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
          <div className='color-purple r-c-fe' style={{marginTop: -10}}>
            Total Questions: {testQPageMeta && testQPageMeta.totalItems ? testQPageMeta.totalItems : 0 }
             <span className='med-grey text-md bold-600'>|</span>
            Total Marks: {totalPoints || 0 }
          </div>
          <div className='r-c-fe'>
           
              <div className='m-r-10'>
               <Button type="primary" className='radius-10 m-r-10' loading={testTeacherReminderLoader} onClick={() => notifyTeacher()} disabled={!testQList.length}>
                 Notify Teacher
               </Button>
              </div>
            {currentTestDetails.markedForReview ? <Button
              className='radius-10 m-r-10'
              loading={false}
              disabled={currentTestDetails.status === "Published"}
              onClick={() => this.props.unmarkForReview(currentTestDetails.id)}
            >
              Close Issues
            </Button> :  null}
            {testQList.length ?
              <div className='m-r-10'>
                {this.addQuestionBtn()}
              </div>
              : null
            }
            <Button
              className='radius-10 m-r-10'
              type="primary"
              loading={false}
              disabled={currentTestDetails.status === "Published"}
              onClick={() => this.openEditModal()}
            >
              <GoPencil style={{marginRight: 10}}/>Edit
            </Button>
            <Button
              type="primary"
              style={{borderRadius: 5}}
              loading={false}
              disabled={currentTestDetails.status === "Published"}
              // onClick={() => console.log('disc')}
            >
              { statusLoader ? <LoadingOutlined /> :
                <Dropdown
                  trigger={['click']}
                  overlay={this.publishDDl}>
                  <FiChevronDown style={{color: 'white'}} />
                </Dropdown>
              }
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderAssignmentTasks(data, index){
    const { testQPageMeta, currentTestDetails} = this.props;
    return (
      <div className='assignmentItem m-b-10 r-c-sb'
        style={{
          padding: 0,
          backgroundColor: '#FFF'
        }}
      >
        <div
          style={{
            borderRight: '1px solid #D9D9D9',
            padding: '10px 20px',
          }}
          className='text-sm m-r-50 text-center bold'
        >
          <Avatar style={{backgroundColor: 'transparent', color: Color.black, border: data.markedForReview ? '2px solid  red' : false}}>
          {(parseInt(testQPageMeta.totalItems) - (parseInt(testQPageMeta.currentPage) - 1) * pageSize - (index))} </Avatar>
        </div>
        <div style={{flex: 6}}>
          <Row className='text-sm bold r-c-sb'>
            {/*<CustomCKEditor width={100} disable={false} ckdata={data.statement} imagesList={[]} onChange={(val) => console.log(val)}/>*/}
            <Col span={22}>
              {ReactHtmlParser(data.statement, { transform: (node) => {
                if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                  return <TeX math={`${node.attribs['data-value']}`} />;
                }
                if (node.type === 'tag' && node.name === 'img') {
                  return <img style={{maxHeight: '40vh', maxWidth: '60vh'}} src={node.attribs.src} alt="question" />;
                }
              }})}
            </Col>
            {/*<Truncate
              lines={1}
              ellipsis={
                <span> ...
                  <Popover
                    placement="right"
                    content={
                      <CustomCKEditor disable={false} ckdata={data.statement} onChange={(val) => console.log(val)}/>
                    }
                    trigger="click">
                    <a style={{fontSize: 12}}> Read More</a>
                  </Popover>
                </span>
              }>
                <CustomCKEditor width={100} disable={false} ckdata={data.statement} onChange={(val) => console.log(val)}/>
            </Truncate>*/}
            {data.remark ?
            <Col span={1} offset={1}>
            <Popover
              placement="right"
              title="Comments"
              content={
                <div id="comment" style={{maxHeight: '15vh', overflowY: 'auto'}}>
                   {data.remark.map((item, i) =>
                   <div style={{backgroundColor: Color.midGrey, borderRadius: 5, margin: 3, padding: 4}} key={i.toString()}>
                     <div>{item.text}</div>
                     <div style={{fontSize: 8}}>{item.time}</div>
                   </div>)}
                </div>
              }
              trigger="click">
              <FaRegCommentAlt className="cursor-pointer"/>
            </Popover> </Col>:null}

          </Row>
        </div>
        <div style={{flex:1, textAlign: 'center'}}>
        {data.type === "MCQ" ?
          <div className='text-sm bold'>
          <Popover
            placement="right"
            style={{}}
            content={<div style={{maxHeight: '40vh', overflowY: 'auto'}}>
              {data && data.options && data.options !== 'string' ? data.options.map(item =>
                <div key={item.id ? item.id.toString() : ''}>
                  <div className='display-flex'>
                    <div>{String.fromCharCode(65 + item.id)}.</div>
                    <div style={{marginLeft: 5}}>
                      {ReactHtmlParser(item.value, { transform: (node) => {
                        if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                          return <TeX math={`${node.attribs['data-value']}`} />;
                        }
                        if (node.type === 'tag' && node.name === 'img') {
                          return <img style={{maxHeight: '40vh', maxWidth: '60vh'}} src={node.attribs.src} alt=""/>;
                        }
                      }})}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>}
            title="Options"
            trigger="click"
          >
            <FileTextOutlined className="cursor-pointer"/>
          </Popover>
          </div>
          :  'NA'}
        </div>
        <div style={{flex:1, textAlign: 'center'}}>
          <div className='text-sm bold'>
            {data.positiveMarks}
          </div>
        </div> 
        <div style={{flex:1, textAlign: 'center'}}>
          <Dropdown
            trigger={['click', 'hover']}
            overlay={() => this.taskChange(data, this.props.history)}
          >
            <EllipsisOutlined
              className='m-l-10 text-sm color-purple cursor-pointer'
          />
          </Dropdown>
        </div>

      </div>
    )
  }

  addQuestionBtn = () => (
    <Button className='radius-10' onClick={() => this.props.toggleQuestionModal(true)} type="primary" disabled={this.props.currentTestDetails.status === "Published"}>
      + Add Question
    </Button>
  )

  noQuestions = () => (
    <Empty
      description={
        <span style={{
            backgroundColor: '#EEEEEE',
            borderRadius: 10,
            padding: 10,
          }} className='bold dark-grey text-xmd'>
          No Questions Found!
        </span> }>
      {this.addQuestionBtn()}
    </Empty>
  )

  render() {
    const { history, testQList, testQLoader, testQPageMeta, currentTestDetails, testModal } =  this.props
    const { ToggleQuestionModal, ToggleInstructionModal } = this.state;

    return (
      <div style={{height: '100%'}}>
        <PageHeader
          className="site-page-header"
          onBack={() => this.props.history.goBack()}
          title='Test Details'
          />

        {this.testDataBox()}
        <div style={{
            marginTop: 15,
            padding: 10,
            height: '77%',
            overflowY: 'auto',
            backgroundColor: Color.lightGrey,
            borderRadius: 10
          }}
        >
          {testQLoader ?
            <div className='r-c-c-c' style={{marginTop: '30%'}}>
              <LoadingOutlined style={{color: '#5843BE', opacity: 0.5,  fontSize: 100}} />
            </div>
            :
            <div style={{height: '100%'}}>
              {
                testQList && testQList.length ?
                <div>
                  <div className='r-jsb bold text-center'>
                    <div className='m-l-10'>
                      S.No.
                    </div>
                    <div style={{flex: 6}}>
                      Statement
                    </div>
                    <div style={{flex: 1}}>
                      Options
                    </div>
                     <div style={{flex: 1}}>
                      Marks
                    </div> 
                    <div style={{flex: 1}}>
                      Actions
                    </div>
                  </div>
                  {testQList.map((item, index) =>
                    this.renderAssignmentTasks(item, index)
                  )}
                </div>
                :
                <div style={{height: '100%'}} className='r-c-c-c'>{this.noQuestions()}</div>
                }
            </div>
          }
        </div>
        <div style={{height: '10%', textAlign: 'right'}}>
          <Pagination
            size="small"
            current={testQPageMeta ? parseInt(testQPageMeta.currentPage) || 1 :  1}
            onChange={(page) => this.handlePageChange(page)}
            pageSize={20}
            total={testQPageMeta ? testQPageMeta.totalItems: 0}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            showSizeChanger={false}
          />
        </div>
        <QuestionModal visible={ToggleQuestionModal} id={1} />
        <ViewQuestionModal />
        <InstructionModal
        modalVisible={ToggleInstructionModal}
        closeModal={() => this.setState({ToggleInstructionModal: false})}
        instructions={currentTestDetails.instructions}
        />
       {testModal && <TestUpdateModal history={history} />}
      </div>
    )
  }

}

const mapStateToProps = state => {
  const {currentTestDetails, testQList, testQLoader, testQPageMeta, statusLoader, testModal, totalPoints, testTeacherReminderLoader} = state.test;
  const {
    classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses, teachers,
    subClasses, subClassesLoader
  } = state.calendar;
  const { searchLoader, subjectsFound } = state.classRoom;
  const { user } = state.session;

  return { classModal, schedules, standards, subjects, togglePostLoader,singleSchedule,selectedClass, selectedClasses,
    user, teachers, subClasses, subClassesLoader, searchLoader, subjectsFound,
    testQLoader, testQList, statusLoader, currentTestDetails, testQPageMeta, testModal, totalPoints, testTeacherReminderLoader
  };
};

const mapDispatchToProps = dispatch => ({
  setTestParams: () => dispatch(setTestParams()),
  toggleTestModal: (bool) => dispatch(toggleTestModal(bool)),
  getTestById: (testId, page, view) => dispatch(getTestById(testId, page, view)),
  updateTestStatus: (params, status, history) => dispatch(updateTestStatus(params, status, history)),
  unmarkForReview: (id) => dispatch(unmarkForReview(id)),
  fetchStandards: subjectIds => dispatch(fetchStandards(subjectIds)),
  toggleQuestionModal: (state) => dispatch(toggleQuestionModal(state)),
  setCurrentTestQuestion: (data) => dispatch(setCurrentTestQuestion(data)),
  setCurrentTest: () => dispatch(setCurrentTest()),
  deleteTestQuestion: (id, marks) => dispatch(deleteTestQuestion(id, marks)),
  toggleViewQuestionModal: (bool) => dispatch(toggleViewQuestionModal(bool)),
  notifyTeacher: () => dispatch(notifyTeacher())
});

export default connect(mapStateToProps, mapDispatchToProps)(TestLoggerView);
