import React from 'react'
import { Modal } from 'antd';
import logo from 'Assets/digitalLibrary/ill-information.svg'
import { connect } from 'react-redux'
import { removemodel } from 'actions/digitalLibrary';
import { useHistory } from 'react-router-dom';
import { setAulasCareChat } from 'actions/adminCare';


function WarningModal({
  isModalVisible, setIsModalVisible, setAulasCareChat
}) {
  const history = useHistory();

  const handleCancel = () => {
    setIsModalVisible(false)
  };

  const handleViewAulasChatClickTeacher = () => {
    setAulasCareChat(false)
    history.push('/care/chat')

  }

  return (
    <Modal className='rounded-border' visible={isModalVisible} footer={null} onCancel={handleCancel}  maskClosable={false}>
      <div className='successmain'>
        <img id='successmodelimg' src={logo}></img>
        <p id='successmodeltitle' style={{ margin: '0px', marginTop: '2%' }}>Contact your Admin</p>
        <p className='lecturnotesdescription' style={{ margin: '0px', color: '#636363' }}>You do not have the permission to buy more storage. Kindly get in touch with your Admin for assistance to continue uploading content.</p>
        <button className='modelbutton' onClick={() => handleViewAulasChatClickTeacher()}>Open Chat</button>
      </div>
    </Modal>
  )
}
const mapStateToProps = (state) => {
  const {


  } = state.digitalLibrary;
  return {


  };
};

const mapDispatchToProps = (dispatch) => ({
  setAulasCareChat: (chat) => dispatch(setAulasCareChat(chat))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(WarningModal);
