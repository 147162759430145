import React from "react";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingModal = ({ visible }) => {
  return (
    <Modal
      className="loadingModal"
      mask={false}
      visible={visible}
      footer={null}
      centered
      closable={false}
      transitionName=""
    >
      <div
        className="r-c-c"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: 60,
          height: 60,
          borderRadius: 30,
        }}
      >
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} />
      </div>
    </Modal>
  );
};

export default LoadingModal;
