import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import SearchByBatch from "./searchByProfesion";
import Date_Picker from "./datePicker";
import { connect } from "react-redux";
import "./careStyles.css";
import {
  setBatch,
  setRole,
  getCareUsers,
  setName,
  getConversationOpenedClosedUsersFunction,
  setSpecificStudentUseId,
} from "actions/adminCare";

function CareNavbar({
  setRole,
  getCareUsers,
  setName,
  chatNameSearch,
  batch,
  getConversationOpenedClosedUsersFunction,
  role,
  specificStudentId,
  date,
  name,
  togglePageLoader,
  chat,
  setSpecificStudentUseId,
}) {
  const { Option } = Select;
  const [batchs, setBatches] = useState("All");

  // useEffect(() => {
  //   setSpecificStudentUseId(null)
  // }, [])
  

  function handleChange(value) {
    setSpecificStudentUseId(null)
    setRole(value);
    togglePageLoader(true);
    getCareUsers(batch, role, date, name, () => togglePageLoader(false));
  }

  const handleSearch = (search) => {
    togglePageLoader(true);
    if (chatNameSearch == true) {
      getConversationOpenedClosedUsersFunction(
        1,
        batch,
        role,
        date,
        search,
        specificStudentId,
        () => togglePageLoader(false)
      );
    } else {
      getCareUsers(batch, role, date, search, () => togglePageLoader(false));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "90%",
      }}
    >
      <div>
        {role === "Student" ? (
          <SearchByBatch togglePageLoader={togglePageLoader} />
        ) : (
          <></>
        )}
      </div>
      <div style={{ margin: "1%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            border: "#E6E6E6 solid 1px",
            borderRadius: "5px",
          }}
        >
          {/* <div><img style={{ width: "100%", marginLeft: "2px", height: "15px" }} src={require('../../Assets/care/ic-sort.svg')} alt="" /></div> */}
          <Select
            className="inputSelecter"
            style={{ width: 150, border: "none" }}
            placeholder="All"
            onChange={handleChange}
            bordered={false}
            removeIcon={true}
            value={role}
            dropdownMatchSelectWidth={true}
          >
            <Option value={null}>
              <h3>All</h3>
            </Option>
            <Option value="Teacher">
              <h3>Teacher</h3>
            </Option>
            <Option value="Student">
              <h3>Student</h3>
            </Option>
            <Option value="Data operator">
              <h3>Data operator</h3>
            </Option>
            <Option value="Operation">
              <h3>Operation</h3>
            </Option>
            {/* <Option value="Planner">
              <h3>Planner</h3>
            </Option> */}
          </Select>
        </div>
      </div>
      {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "1%" }}>
      {chat!==true?
        <Date_Picker togglePageLoader={togglePageLoader} chatNameSearch={chatNameSearch} />
        :null}

      </div> */}
      {/* {chatNameSearch !=true
        ? */}
      <div className="searchByNameinpt" style={{ width: "40%" }}>
        <Input.Search
          size={"middle"}
          type="test"
          placeholder="Search by name"
          style={{ border: "0px", fontSize: "1em" }}
          onSearch={(e) => handleSearch(e)}
          onChange={(e) => setName(e.target.value)}
          value={name}
          allowClear
        />
      </div>
      {/* : null
      } */}
    </div>
  );
}

// export default CareNavbar
const mapStateToProps = (state) => {
  const { role, batch, date, name, specificStudentId } = state.adminCare;
  return {
    role,
    batch,
    date,
    name,
    specificStudentId,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setRole: (role) => dispatch(setRole(role)),
  setBatch: (batch) => dispatch(setBatch(batch)),
  getCareUsers: (batch, role, date, name, callback) =>
    dispatch(getCareUsers(batch, role, date, name, callback)),
  setName: (name) => dispatch(setName(name)),
  setSpecificStudentUseId: (userId) => dispatch(setSpecificStudentUseId(userId)),
  getConversationOpenedClosedUsersFunction: (
    page,
    batch,
    role,
    date,
    name,
    specificStudentId,
    callback
  ) =>
    dispatch(
      getConversationOpenedClosedUsersFunction(
        page,
        batch,
        role,
        date,
        name,
        specificStudentId,
        callback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CareNavbar);
