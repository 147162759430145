import React, { useState, useEffect } from 'react'
import { Table, Typography } from 'antd'
import PageHeader from 'components/pageHeader'
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { InputSearch } from 'components/customInputs';
import { fetchDeletedUsers, restoreDeletedUsers, updateUserToTranferData, getUsers } from 'actions/userManagementV2';
import { IMG_URL } from "../../env.json";
import { Button } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';
import ParmanentDeleteModal from './modals/parmanentDeleteModal';



const DeletedUsers = ({ fetchDeletedUsers, deletedUsersList, restoreDeletedUsers, updateUserToTranferData, getUsers }) => {


        const history = useHistory();

        const [search, setSearch] = useState('');
        const [selectedId, setSelectedId] = useState([]);
        const [selectedRows, setSelectedRows] = useState([]);
        const [pageLoader, togglePageLoader] = useState(false);
        const [restoreLoader, setRestoreLoader] = useState(false);
        const [selectedRowKeys, setSelectedRowKeys] = useState([]);
        const [filter, setFilter] = useState({ page: 1, limit: 25 });
        const [rowRestoreLoader, setRowRestoreLoader] = useState(false);
        const [parmanentDeleteModal, setPermanentDeletModal] = useState(false)


        let params = {
                limit: 25,
                search,
                page: search ? undefined : filter?.page
        }

        useEffect(() => {
                togglePageLoader(true);
                fetchDeletedUsers(params, () => togglePageLoader(false));
        }, [search, filter]);

        const renderName = (value, record) => (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 0 }}>
                        <img src={`${IMG_URL}/${record?.image ? record?.image : 'default.png'}`} alt="" style={{ width: '20px', marginRight: '10px' }} />
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontWeight: 600 }}>
                                <Typography.Text ellipsis={{ tooltip: true }}>{value}</Typography.Text>
                        </div>
                </div>
        );

        const setIdToDelete = (id) => {
                selectedRowKeys.push(id);
                setSelectedRowKeys([...selectedRowKeys]);
        };
        const openPermanentDeleteModal = (e, record) => {
                setPermanentDeletModal(true);
                e.stopPropagation();
                setIdToDelete(record?.id);
        }

        const columns = (rowRestoreLoader, handleRowRestore, selectedId) => [
                {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: renderName
                },
                {
                        title: 'Role',
                        dataIndex: 'role',
                        key: 'role',
                        render: (value, record) => <div style={{ display: 'flex', justifyContent: 'flex-start' }}>{record?.role ? record?.role : 'NA'}</div>
                },
                {
                        title: 'Mobile No',
                        dataIndex: 'mobile',
                        key: 'mobile',
                        render: (value, record) => <div style={{ display: 'flex', justifyContent: 'flex-start' }}>{record?.phone ? record?.phone : 'NA'}</div>
                },
                {
                        title: 'Actions',
                        key: 'actions',
                        render: (text, record) => {
                                return <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button onClick={() => handleRowRestore(record?.id)} loading={rowRestoreLoader && record?.id === selectedId} type='transparent' style={{ padding: 0, color: '#1089FF', border: 'none' }} disabled={selectedRowKeys.includes(record.id)}>RESTORE</Button>

                                        {record?.role == 'Teacher' ?
                                                <>
                                                        <Button onClick={() => { updateUserToTranferData(record); history.push('/user-management/transfer-data') }} loading={null} type='transparent' style={{ width: '20%', border: 'none', color: '#FFA931', marginLeft:10 }} disabled={selectedRowKeys.includes(record.id)}>MANAGE</Button>
                                                </>

                                                : null
                                        }
                                        <Button onClick={(e) => { openPermanentDeleteModal(e, record); }} loading={null} type='transparent' style={{ border: 'none', color: '#FF414D' }} disabled={selectedRowKeys.includes(record.id)}>DELETE</Button>

                                </div>
                        }
                },
        ];

        const rowSelection = {
                type: "checkbox",
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys, selections) => {
                        setSelectedRows([...selections?.map(item => item?.fileKey)]);
                        setSelectedRowKeys([...selectedRowKeys]);
                },
        };

        const onPermanentDeleteModalCancel = () => {
                setPermanentDeletModal(false)
        };

        const handleHeaderRestore = () => {

                let buttonParams = {
                        userIds: selectedRowKeys
                };

                const callback = () => {
                        togglePageLoader(true);
                        fetchDeletedUsers(params, () => togglePageLoader(false));
                        setRestoreLoader(false);
                        setSelectedRowKeys([]);
                }

                setRestoreLoader(true);
                restoreDeletedUsers(buttonParams, () => callback());
        }

        const handleRowRestore = (id) => {
                setSelectedId(id);

                let restoreParams = {
                        userIds: [id]
                };

                const callback = () => {
                        togglePageLoader(true);
                        fetchDeletedUsers(params, () => togglePageLoader(false));
                        setRowRestoreLoader(false);
                        setSelectedId([]);
                };

                setRowRestoreLoader(true);
                restoreDeletedUsers(restoreParams, () => callback());
        }

        const actions = [
                <>
                        <Button onClick={() => handleHeaderRestore()} loading={restoreLoader} disabled={selectedRowKeys?.length < 1 ? true : false} type='primary' style={{ backgroundColor: '#1089FF', color: 'white', border: 'none', marginRight: 10 }}>RESTORE</Button>
                        <Button disabled={selectedRowKeys?.length < 1 ? true : false} onClick={() => { setPermanentDeletModal(true) }} type='primary' style={{ backgroundColor: '#FF414D', color: 'white', border: 'none', marginRight: 10 }}>DELETE</Button>
                        <InputSearch style={{ width: '100%' }} allowClear placeholder='Search by name/mobile' onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} />
                </>
        ]

        return (
                <div>
                        <div><PageHeader title={'Suspended Users'} onBackPress={() => history.goBack()} actions={actions} /></div>
                        {pageLoader ? <></> :
                                <Table
                                        style={{ width: "100vw", marginTop: 60 }}
                                        rowSelection={rowSelection}
                                        columns={columns(rowRestoreLoader, handleRowRestore, selectedId)}
                                        dataSource={deletedUsersList?.items || []}
                                        rowKey={(record) => record.id}
                                        pagination={{
                                                total: deletedUsersList?.meta?.totalItems,
                                                pageSize: filter?.limit,
                                                current: filter?.page,
                                                size: 'small',
                                                showTotal: (total, range) => <div>Showing {range[0]} - {range[1]} of {total}</div>,
                                                onChange: (page) => setFilter({ ...filter, page }),
                                                showSizeChanger: false
                                        }}
                                />
                        }

                        <PageDataLoader visible={pageLoader} />
                        {parmanentDeleteModal && <ParmanentDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={fetchDeletedUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={parmanentDeleteModal} onCancel={(e) => onPermanentDeleteModalCancel()} />}
                </div>
        )
}

const mapStateToProps = (state) => {
        const { deletedUsersList } = state.userManagementV2;
        return { deletedUsersList }
};

const mapDispatchToProps = (dispatch) => ({
        fetchDeletedUsers: (params, callback) => dispatch(fetchDeletedUsers(params, callback)),
        restoreDeletedUsers: (params, callback) => dispatch(restoreDeletedUsers(params, callback)),
        updateUserToTranferData: data => dispatch(updateUserToTranferData(data)),
        getUsers: (params, callback) => dispatch(getUsers(params, callback)),


});

export default connect(mapStateToProps, mapDispatchToProps)(DeletedUsers);