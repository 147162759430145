import { Information, Title } from 'components/Typography';
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './styles.css'
import OverviewList from './overviewList';

function SingleOverviewCard({ count, subTitle, imageSources, studentCount, item, setCourseTypeName }) {
    const reorderedModeCountList = [
        item?.modeCountList?.find(item => item?.mode === 'online'),
        item?.modeCountList?.find(item => item?.mode === 'offline'),
        item?.modeCountList?.find(item => item?.mode === 'hybrid')
    ];
    const history = useHistory()
    return (
        <div className="overview-single-card" onClick={() => {
            setCourseTypeName("liveCourses");
            history.push("/live-courses-data")
        }}>
            <div className='r-jsb'>
                <div>
                    <Title>{count}</Title>
                    <div className='r-ac'>
                        <Information>
                            {subTitle.toLowerCase()}
                        </Information>
                        <Information className='m-l-5'>
                            (<span>{studentCount || 0}</span> {studentCount > 1 ? "students" : "student"})
                        </Information>
                    </div>
                </div>
                <div className="r-ac">
                    <img className='overview-single-card-img' src={imageSources} alt={"images"} />
                </div>
            </div>
            <div className='r-jsb' style={{ width: "fit-content", flexWrap: "wrap", width: "100%" }}>
                {reorderedModeCountList?.map((item, _index) => (
                    <OverviewList item={item} />
                ))}
            </div>
        </div>
    )
}

export default SingleOverviewCard