import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import SearchBox from 'pages/Test/localComponent/searchBox';
import { Button, Tabs } from 'antd';
import { connect } from 'react-redux';
import {
  fetchDashBoardData,
  updateStudentTestFilter,
  setSelectedSub,
  setSelectedTest,
  setDuration,
  updateTestType,
  UpdateStudentTestData,
  fetchChapterCardsData,
  updateChapterCardsData,
  fetchTestByStageData,
  setFilter,
  setCards,
  toggleReloader
} from "actions/studentPracticeTest";
import CombinedTestModal from './modals/combinedTestModal';
import { useHistory } from 'react-router';
import Loader from 'pages/Test/localComponent/loader';
import SubmittedSuccess from './modals/submittedSuccess';
const illnotest = require("../../Assets/studentPracticeTest/ill-no-test.svg").default
const paused = require("../../Assets/test/Paused.svg").default
const {TabPane} = Tabs;

const BackgroundCard = () => {
    return (
      <>
<div className='cursor-pointer r-afs flex-wrap full-height m-t-5 full-width full-height' style={{overflow:"auto"}}>
  <div style={{
      backgroundColor: "#FFFFFF", 
      border: "1px solid #E6E6E6", 
      borderRadius: 10,
      height:500
      }}className="m-l-10 r-c-c-c p-10 customCardShadow cursor-pointer shadow-box hover-shadow full-width full-height">
        <div>
            <div /* className='r-c-c-c' */>
                    <img src={illnotest}/>
                    <div className='m-t-30 m-l-20 flex-column' /* style={{display:'flex'}} */>
                      <div className='m-l-40' style={{color:"#191919",fontWeight:"800", fontStyle:"roboto", fontSize: "21px"}} >No test available</div>
                      <div className='m-t-10' style={{color:"#636363"}}>There are no test available at the moment</div>
                     </div>
            </div>
        </div>
     </div>
</div>
      </>
    )
}


const HeaderComp = ({filter}) =>{
    return(
        <div className='flex'>
        <div style={{fontStyle:"roboto", fontSize: "21px", color:"#191919"}}>{filter?.selectedSyllabus==='Chapter Test'?filter.selectedChapter.chapterName:filter.selectedSubject.subjectName}</div>
        <div style={{fontStyle:"roboto", fontSize: "11px", color:"#636363"}}>{filter?.selectedSyllabus}{filter?.selectedSyllabus==='Chapter Test'?`/ ${filter?.selectedSubject?.subjectName}`:null}</div>
</div>
    )
}

const AttemptedCards=()=>{
    return(
        <>
        <div className='cursor-pointer r-afs flex-wrap full-height m-t-5 full-width' style={{overflow:"auto"}}>
  <div style={{
      width: "100%",
      backgroundColor: "#FFFFFF", 
      border: "1px solid #E6E6E6", 
      borderRadius: 10,
      height:500
      }}className="m-l-10 r-c-c-c p-10 customCardShadow cursor-pointer shadow-box hover-shadow">
        <div>
            <div /* className='r-c-c-c' */>
                    <img src={illnotest}/>
                    <div className='m-t-30 m-l-20 flex-row' /* style={{display:'flex'}} */>
                      <div className='m-l-15' style={{color:"#191919",fontWeight:"800", fontStyle:"roboto", fontSize: "21px"}} >No attempted test(s)</div>
                      <div className='m-t-10' style={{color:"#636363"}}>You have not attempted any test(s) yet</div>
                     </div>
            </div>
        </div>
     </div>
</div>
        </>
        
    )
}

const TestCards = ({Status,onClick,data,paused,viewresults,reattempt}) => {
    const prevTest = require("../../Assets/test/ic-previous-test.svg").default;
    

    return (
      <div className='m-10 r-c-fs p-10 customCardShadow cursor-pointer shadow-box hover-shadow' style={{display:'flex', width:'400px', height:'110px'}} onClick={onClick(data)} >
        <div><img src={prevTest}/></div>
        <div className='m-l-10'>
          <div className='bold-600 text-overflow-ellipsis' style={{color:'#191919',fontSize:'14px',maxWidth:'90%'}}>{data.title}</div>
          <div className='m-t-2 bold text-overflow-ellipsis' style={{color:'#636363',fontSize:'13px',width:'300px',overflowWrap:'break-word',wordWrap:'break-word',wordBreak:'break-word'}}>{data?.subjects?.map(item=>item.name)?.join(" • ")} </div>
          <div className='m-t-2 bold' style={{color:'#636363',fontSize:'12px'}}>{data.totalQuestions}Qs • {data.durationInMinutes}mins • {data.totalPoints} marks</div>
          {(data.paused===true)
          ?
          <div><img src={paused}/></div>
          :
          (data.paused===false)
          ?
          <div><Button className="prac-rettempt-toggle" style={{border:'none',fontSize:'10px',boxShadow:'none',padding:'0px'}}onClick={reattempt(data)}>REATTEMPT</Button> | <Button className="prac-rettempt-toggle" style={{border:'none',fontSize:'10px',boxShadow:'none',padding:'0px'}} onClick={viewresults(data)}>VIEW RESULTS</Button></div>
          :
          null}
        </div>
      </div>
    )
  }
let timer = false;
function PracticeTabsPage ({setFilter, filter, fetchTestByStageData,cards, 
  setSelectedTest, loaders, toggleReloader, reloadToggler}) {
    useEffect(()=>{
      fetchTestByStageData(()=>{});

    }, [filter?.selectedTab, reloadToggler]);
    const onSearch = (e) =>{
      setFilter('tabSearch', e.target.value);
    }
    useEffect(()=>{
      clearTimeout(timer);
      timer = setTimeout(()=>{
        fetchTestByStageData(()=>{});
      }, 1000);
    }, [filter?.tabSearch]);

    const history = useHistory();

    const goBack=()=>{
        history.goBack();
    }

    const [isModalVisible,setIsModalVisible] = useState(false)
    const [showSubmitSuccess, toggleSubmitSuccess] = useState(false);
    

    const startTestHandle = (data)=>{
      
      return ()=>{
        setSelectedTest(data)
        setIsModalVisible(true)
      }
    }

    const startTestReattemptHandle = (data)=>{
      
      return ()=>{
        setSelectedTest(data)
        setIsModalVisible(true)
      }
    }

    const viewResultsHandle = (data)=>{
      return (e)=>{
        e.stopPropagation();
        setSelectedTest(data)
        history.push('/practice-result-screen')
      }
      
    }

    const handleCancel = ()=>{
      setIsModalVisible(false);
    }


  return (
    <>
    
    <div style={{display:"flex", justifyContent:"space-between"}} >
        <div className='m-l-10' style={{display:"flex"}} >
            <div  style={{ fontWeight: "800", fontSize: "20px",color:"#191919" }} className="m-b-20">
                <ArrowLeftOutlined className="cursor-pointer m-r-20 m-t-15" onClick={goBack}/> </div>  
                <HeaderComp filter={filter}/>
        </div>          
        <div className='m-t-10'>
                 <SearchBox loading={false} 
                                        onChange={onSearch}
                                        placeholder="Search by test name"
                                        style={{width:400, marginRight:25, height:40}}
                                        value={filter?.tabSearch}/>                       
             </div>
    </div>
    <div>
        
    <Tabs className="testTab full-height subjectTestTab m-l-10" defaultActiveKey={filter?.selectedTab}
                    style={{overflowY:'scroll'}}
                    onChange={(activeKey)=>{
                      setFilter('selectedTab', activeKey);
                  }}>
                <TabPane tab="AVAILABLE" key="Available">
                  <div style={{display:'flex',flexWrap:'wrap'}}>
                  {loaders?.tabPageLoader?<div style={{width:"100%", height:"70vh"}} className="r-c-c"> <Loader style={{fontSize:35}}/></div>:(cards?.Available&&cards.Available?.length)?cards?.Available?.map((item)=>{
                    return <TestCards data={item} onClick={startTestHandle} reattempt={()=>{}} viewresults={()=>{}}/>
                  }):BackgroundCard()}

                  </div>
                
                        {/* {displaycards(upcomingTests, "scheduled", loaders?.taPageLoader)} */}
                </TabPane>
                <TabPane tab="ATTEMPTED" key="Attempted">
                  <div style={{display:'flex',flexWrap:'wrap'}}>
                {loaders?.tabPageLoader?<div style={{width:"100%", height:"70vh"}} className="r-c-c"> <Loader style={{fontSize:35}}/></div>:(cards?.Attempted&&cards.Attempted?.length)?cards?.Attempted?.map((item)=>{
                    return <TestCards data={item} paused={paused} reattempt={startTestReattemptHandle} viewresults={viewResultsHandle} onClick={startTestHandle}/>
                  }):AttemptedCards()}

                  </div>
                    {/* {displaycards(ongoingTests, "adminlive", loaders?.taPageLoader)} */}
                </TabPane>

            </Tabs>

    </div>
    <div>
     {isModalVisible &&( 
          <CombinedTestModal 
              isModalVisible={isModalVisible} 
              handleCancel={handleCancel}
              toggleSubmitSuccess={toggleSubmitSuccess}
              />)}
     {
       showSubmitSuccess && (
        <SubmittedSuccess
            item="beforeResults"
            isModalVisible={showSubmitSuccess}
            history={history}
            close={() => {
              toggleSubmitSuccess(false);
              toggleReloader();
            }}
          />
        )
        }
             </div>        
    </>
  )
}

const mapStateToProps = (state) => {
    const {
      ChapterInSyllabusData,SubjectInSyllabus,filter,fetchTestByStageData,cards, loaders, reloadToggler
    } = state.studentPracticeTest;
    return {
      ChapterInSyllabusData,
      SubjectInSyllabus,filter,fetchTestByStageData,cards, loaders, reloadToggler
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    fetchChapterInSyllabusData: (callback) => dispatch(fetchChapterInSyllabusData(callback)),
    setFilter:(key,value)=>dispatch(setFilter(key,value)),
    fetchTestByStageData: (callback) => dispatch(fetchTestByStageData(callback)),
    setCards:(key,val)=> dispatch(setCards(key,val)),
    setSelectedTest: (data) => dispatch(setSelectedTest(data)),
    toggleReloader: () => dispatch(toggleReloader())
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PracticeTabsPage);
