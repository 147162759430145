import { Dropdown, Image, Typography, Menu } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import lectureNotes from 'Assets/digitalLibrary/lectureNotes.svg'
import videos from 'Assets/digitalLibrary/videos.svg'
import questionBank from 'Assets/digitalLibrary/question-bank.svg'
import { updateSelectedTopic, setKey, updateSetType, currentTypes } from 'actions/digitalLibrary'
import { connect } from 'react-redux'
import { MdMoreVert } from 'react-icons/md'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import CreateTopic from '../modals/createTopic'
import DeleteConfirm from '../modals/deleteConfirm'
import { useDrag, useDrop } from 'react-dnd'

const ItemType = 'TOPIC';

const TopicCard = ({ topicItem, updateSelectedTopic, postCreateTopic, user, setKey, updateSetType, currentTypes, index, isEditOrderClicked, moveTopic, tooglePageLoader, setTopicsData }) => {
    const history = useHistory()
    const [isCreateTopicModalVisible, setCreateTopicModalVisible] = useState(false)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    console.log("topicItem", topicItem);
    const handleClick = (e) => {
        if (!isEditOrderClicked) {
        e.stopPropagation();
        updateSelectedTopic(topicItem)
        updateSetType(parseInt(topicItem?.notesCount) > 0 ? 'lecture-notes' : parseInt(topicItem?.videoCount) > 0 ? "video" : "question-bank")
        currentTypes(parseInt(topicItem?.notesCount) > 0 ? 'lecture-notes' : parseInt(topicItem?.videoCount) > 0 ? "video" : "question-bank")
        setKey('1')
        history.push('/digital-library/content')
        }
    }

    const handleEdit = (e) => {
        e.domEvent.stopPropagation();
        updateSelectedTopic(topicItem)
        setCreateTopicModalVisible(true)
    }

    const handleDeleteTopic = (e) => {
        e.domEvent.stopPropagation();
        setIsDeleteModal(true)
    }

    const menu = (
        <Menu style={{ width: "100px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            <Menu.Item key="1" icon={< EditOutlined />} onClick={handleEdit}>
                Edit
            </Menu.Item>
            <Menu.Item key="2" icon={<DeleteOutlined />} onClick={handleDeleteTopic}>
                Delete
            </Menu.Item>
        </Menu>
    );

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { topicItem, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item) {
            if (!item || item.index === index) {
                return;
            }
            moveTopic(item.index, index);
            item.index = index;
        },
    });

    const totalSize = parseInt(topicItem?.totalSize) || 0;
    const totalSizeInMB = (totalSize / 1048576).toFixed(2);
    const totalSizeInGB = (totalSize / 1073741824).toFixed(2);
    const displaySize = totalSize === 0 
        ? '0 MB' 
        : (totalSizeInGB > 1 ? `${totalSizeInGB} GB` : `${totalSizeInMB} MB`);

    // const menu = (
    //     <Menu style={{ width: "100px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
    //         <div key="1" icon={< EditOutlined />} onClick={handleEdit}>
    //             Edit
    //         </div>
    //         <div key="2" icon={<DeleteOutlined />}>
    //             Delete
    //         </div>
    //     </Menu>

    // );
    return (
        <div style={{ marginRight: "15px",marginBottom:"10px"}}>
            <div ref={(node) => isEditOrderClicked ? drag(drop(node)) : null} style={{ opacity: isDragging ? 0.5 : 1, cursor: isEditOrderClicked ? 'move' : 'pointer', position:"relative" }} className='digital-library-topic-card cursor-pointer' onClick={(e) => handleClick(e)}>
                <div style={{ padding: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className='bold' style={{ fontSize: 13, color: '#636363' }}> Topic {index + 1}</div>
                        <div>
                            {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                                <div>
                                    <Dropdown overlay={menu} disabled={false} placement="bottomRight" onClick={(e) => e.stopPropagation()}>
                                        <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} onClick={(e) => e.stopPropagation(e)} />
                                    </Dropdown>

                                </div> : null}
                            {(isCreateTopicModalVisible && <CreateTopic isModalVisible={isCreateTopicModalVisible} setIsModalVisible={setCreateTopicModalVisible} edit={true} tooglePageLoader={tooglePageLoader} setTopicsData={setTopicsData}/>)}
                            {(isDeleteModal && <DeleteConfirm isModalVisible={isDeleteModal} setModalVisible={setIsDeleteModal} id={topicItem?.topicId} tooglePageLoader={tooglePageLoader} setTopicsData={setTopicsData}/>)}
                        </div>


                    </div>
                    <div>
                        <Typography.Paragraph className='text-heading-library'
                            style={{ width: '100%', marginBottom: 0, }}
                            ellipsis={{
                                rows: 1,
                                tooltip: true
                            }}
                        >
                            {topicItem?.topicName}
                        </Typography.Paragraph>
                    </div>
                </div>
                {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                    <div style={{ position: "absolute", display: "flex", justifyContent: 'flex-start', height: 30, width: '100%', background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 1) 110%)", padding: "0px 10px", alignItems: "center", borderRadius: "0px 0px 8px 8px", bottom: 0, right: 0 }}>
                        <div style={{ color: "#FFFFFF", fontWeight: "bold" }}> {displaySize} data</div>
                    </div> : <></>}
                {/* <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            {(user.access.includes('digital-library-admin') || user.access.includes('digital-library-superadmin') || user.access.includes('digital-library-dataoperator')) ?
                        <div>
                            <Dropdown overlay={menu} disabled={false} placement="bottomRight" onClick={(e) => e.stopPropagation()}>
                                <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} onClick={(e)=>e.stopPropagation(e)}/>
                            </Dropdown>

                        </div> : null}
            {(isCreateTopicModalVisible && <CreateTopic isModalVisible={isCreateTopicModalVisible} setIsModalVisible={setCreateTopicModalVisible} edit={true} />)}
            {(isDeleteModal && <DeleteConfirm isModalVisible={isDeleteModal} setModalVisible={setIsDeleteModal} id={topicItem?.topicId} />)}
                </div> */}
                {/* <Typography.Paragraph className='text-heading-library'
                        style={{ width: '100%', marginBottom: 0, fontSize:18 }}
                        ellipsis={{
                            rows: 3,
                            tooltip: true
                        }}
                    >
                        {topicItem?.topicName}
                    </Typography.Paragraph>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}>
                        <Image src={lectureNotes} preview={false}></Image>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '11px', marginLeft: '2px' }} ellipsis={{ rows: 1, tooltip: true }}>{topicItem?.notesCount}</Typography.Text>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}>
                        <Image src={videos} preview={false}></Image>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '11px', marginLeft: '2px' }} ellipsis={{ rows: 1, tooltip: true }}>{topicItem?.videoCount}</Typography.Text>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={questionBank} preview={false}></Image>
                        <Typography.Text className='text-heading-library' style={{ fontSize: '11px', marginLeft: '2px' }} ellipsis={{ rows: 1, tooltip: true }}>{topicItem?.quesBankCount}</Typography.Text>
                    </div>

                </div> */}

            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px",marginTop:"3px", width: "255px"}}>
                <div ><span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {topicItem?.notesCount ? topicItem?.notesCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Notes</span> <span style={{ fontSize: "13px", color: "#AEAEAE" ,marginLeft:"6px"}}>| </span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {topicItem?.videoCount ? topicItem?.videoCount : 0}  </span><span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Videos</span> <span style={{ fontSize: "13px", color: "#AEAEAE",marginLeft:"6px" }}>| </span></div>
                <div> <span style={{ fontSize: "13px", color: "#191919", fontWeight: 700 }}> {topicItem?.quesBankCount ? topicItem?.quesBankCount : 0}  </span> <span style={{ fontSize: "13px", color: "#636363", fontWeight: 400 }}> Test papers</span></div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const {

    } = state.digitalLibrary;
    const { user } = state.session
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateSelectedTopic: (object) => dispatch(updateSelectedTopic(object)),
    postCreateTopic: (object, callback, successCallback) => dispatch(postCreateTopic(object, callback, successCallback)),
    setKey: (key) => dispatch(setKey(key)),
    updateSetType: (data) => dispatch(updateSetType(data)),
    currentTypes: (type) => dispatch(currentTypes(type))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(TopicCard);
// export default TopicCard
