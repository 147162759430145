import {
    ArrowLeftOutlined,
    CalendarOutlined,
    CaretDownOutlined,
    CheckOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Checkbox,
    Pagination,
    Popover,
    Typography,
    Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import image from "../../Assets/feeManagement/Mask Group 2.png";
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;
//   import SelectRangeModal from "./selectRangeModal";
import OfflinePayReq from "pages/feeManagement/addOfflinePayReqModal";

import {
    fetchTransaction,
    setAllActivitiesFilter,
    setInvoiceFile,
    setFeeDetails,
    postSendInvoice
} from "actions/feeManagement";
import { connect } from "react-redux";
import PageDataLoader from "components/pageDataLoader";
import { Api } from "services";
  import CalenderPopup from "../feeManagement/calenderPop";
//   import ShowReasonModal from "./showReasonModal";
import { Button } from 'components/Button'
import PageHeader from 'components/pageHeader'
import AddDetailsModal from "pages/feeManagement/addDetailsModal";
import SelectRangeModal from "pages/feeManagement/selectRangeModal";
import ShowReasonModal from "pages/feeManagement/showReasonModal";
import NoDataCard from "pages/digitalLibrary/components/noDataCard";


function TransactionHistory({
    fetchTransaction,
    feeMangementData,
    setAllActivitiesFilter,
    allActivitiesFilter,
    setInvoiceFile,
    setFeeDetails,
    postSendInvoice, storeTransactionData,
    getTransactionData, userRowDetails
}) {
    console.log("AA", feeMangementData?.selectedStudentDescId);
    const [showSelectRangeModal, setShowSelectRangeModal] = useState(false);
    const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
    const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
    const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
    const [pageLoader, tooglePageLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [showCustomCal, setShowCustomCal] = useState(true);
    const [showCal, setShowmCal] = useState(false);
    const [isAdditionModal, setIsAdditionModal] = useState(false);
    const [showGeneratePayReq, setShowGeneratePayReq] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [isCheck1, setIsCheck1] = useState(false);
    const [showData ,setShowData] = useState(false)


    function numberWithCommas(x) {
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
    }

    const handlePageination = (value) => {
        setPage(value);
        setAllActivitiesFilter("page", value);
        tooglePageLoader(true);

        fetchTransaction(() => tooglePageLoader(false));
    };
    const history = useHistory();
    const goBack = (history) => {
        history.goBack();
        setAllActivitiesFilter("page", 1);
    };
    useEffect(() => {
        setAllActivitiesFilter("page", 1);
        setAllActivitiesFilter("status", ["paidAndRefunded"]);
        setAllActivitiesFilter("search", null);
        setAllActivitiesFilter("section", null);
        setAllActivitiesFilter("standardId", null);
        setAllActivitiesFilter("mode", null);
        setAllActivitiesFilter("startDate", null);
        setAllActivitiesFilter("endDate", null);
        setFeeDetails("generatePayDiscount", null);
        setAllActivitiesFilter("filter", null);
        setFeeDetails("generatePayAdditions", null);
        setInvoiceFile(null);
        tooglePageLoader(true);
        fetchTransaction(() => tooglePageLoader(false));
    }, []);

    const handleFilterOption = (value) => {
        setShowData(true)

        setAllActivitiesFilter('userId', userRowDetails?.id)

        setAllActivitiesFilter("filter", value);
        tooglePageLoader(true);
        setAllActivitiesFilter("page", 1);
        fetchTransaction(() => tooglePageLoader(false));
    };

    const handleClick = (invoiceFile) => {
        setInvoiceFile(invoiceFile);
        history.push("/fee-management/invoice");
    };
    // const modes = storeTransactionData?.paidTransactions?.map(transaction => transaction.mode) || [];
    console.log("AA-mode", isCheck, isCheck1);

    const content = (
        <div>
            <p>
                <Checkbox
                    onChange={(e) => {
                        setIsCheck(e.target.checked);
                        setIsCheck1(false)
                        setShowData(true)
                        if (e.target.checked) {
                            setAllActivitiesFilter("mode", "online");
                            setAllActivitiesFilter('userId', userRowDetails?.id)

                        } else {
                            setAllActivitiesFilter("mode", null);
                        }
                        tooglePageLoader(true);
                        setAllActivitiesFilter("page", 1);



                        fetchTransaction(() => tooglePageLoader(false));
                    }}
                    checked={isCheck}
                >
                    {/* {console.log("AA-abcd",storeTransactionData?.paidTransactions}))} */}
                    Online
                </Checkbox>
            </p>
            <p>
                <Checkbox
                    onChange={(e) => {
                        console.log("AA3-isCheck",isCheck,isCheck1);
                        setShowData(true)
                        setIsCheck1(e.target.checked);
                        setIsCheck(false)
                        // if (isCheck) {
                        //     console.log("AA-isCheck",isCheck,isCheck1);
                        //     setIsCheck1(false);
                        // }
                        if (e.target.checked) {
                            setAllActivitiesFilter("mode", "offline");
                            setAllActivitiesFilter('userId', userRowDetails?.id)

                        } else {
                            setAllActivitiesFilter("mode", null);
                        }
                        tooglePageLoader(true);
                        setAllActivitiesFilter("page", 1);

                        fetchTransaction(() => tooglePageLoader(false));
                    }}
                    checked={isCheck1}
                >
                    Offline
                </Checkbox>
            </p>
        </div>
    );

    const content1 = (
        <div>
            {showCustomCal ? (
                <div
                    style={{
                        fontFamily: "roboto",
                        width: "200px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-around",
                                            }}
                >
                    <div className="dropmenuBph" style={{ color: "#AEAEAE" }}>
                        FILTER OPTIONS{" "}
                        <span
                            onClick={() => {
                                
                                handleFilterOption(null);
                                setAllActivitiesFilter("startDate", null);
                                setAllActivitiesFilter("endDate", null);
                            }}
                            style={{
                                color: "#FF414D",
                                fontWeight: "600",
                                fontSize: "12px",
                                marginLeft: "11px",
                                cursor: "pointer",
                            }}
                        >
                            CLEAR ALL
                        </span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="dropmenuBp"
                            style={{ color: "#636363", width: "100%" }}
                            onClick={() => {handleFilterOption("today");setAllActivitiesFilter("startDate", null);
                                setAllActivitiesFilter("endDate", null);setShowmCal(false)}}
                        >
                            Today
                        </div>
                        {allActivitiesFilter?.filter == "today" ? (
                            <div>
                                <CheckOutlined
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "18px",
                                        color: "#594099",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="dropmenuBp"
                            style={{ color: "#636363", width: "100%" }}
                            onClick={() => {handleFilterOption("yesterday");setAllActivitiesFilter("startDate", null);
                                setAllActivitiesFilter("endDate", null);setShowmCal(false)}}
                        >
                            Yesterday
                        </div>
                        {allActivitiesFilter?.filter == "yesterday" ? (
                            <div>
                                <CheckOutlined
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "18px",
                                        color: "#594099",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="dropmenuBp"
                            style={{ color: "#636363", width: "100%" }}
                            onClick={() => {handleFilterOption("month");setAllActivitiesFilter("startDate", null);
                                setAllActivitiesFilter("endDate", null);setShowmCal(false)}}
                        >
                            This month
                        </div>
                        {allActivitiesFilter?.filter == "month" ? (
                            <div>
                                <CheckOutlined
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "18px",
                                        color: "#594099",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="dropmenuBp"
                            style={{ color: "#636363", width: "100%" }}
                            onClick={() => {handleFilterOption("lastmonth");setAllActivitiesFilter("startDate", null);
                                setAllActivitiesFilter("endDate", null);setShowmCal(false)}}
                        >
                            Past month
                        </div>
                        {allActivitiesFilter?.filter == "lastmonth" ? (
                            <div>
                                <CheckOutlined
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "18px",
                                        color: "#594099",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="dropmenuBp"
                            style={{ color: "#636363", width: "100%" }}
                            onClick={() => {handleFilterOption("threemonths");setAllActivitiesFilter("startDate", null);
                                setAllActivitiesFilter("endDate", null);setShowmCal(false)}}
                        >
                            Past 3 months
                        </div>
                        {allActivitiesFilter?.filter == "threemonths" ? (
                            <div>
                                <CheckOutlined
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "18px",
                                        color: "#594099",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="dropmenuBp"
                            style={{ color: "#636363", width: "100%" }}
                            onClick={() => {
                                // handleFilterOption(" Customise range");
                                setAllActivitiesFilter("filter", "Customise range");
                                setShowmCal(true)
                                setShowCustomCal(false);
                            }}
                        >
                            Customise range
                        </div>
                        {allActivitiesFilter?.filter == "Customise range" ? (
                            <div>
                                <CheckOutlined
                                    style={{
                                        fontWeight: "800",
                                        fontSize: "18px",
                                        color: "#594099",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div>
           <CalenderPopup
              tooglePageLoader={tooglePageLoader}
              showCustomCal={setShowCustomCal}
              allActivitiesFilter={allActivitiesFilter}
            />
                </div>
            )}
        </div>
    );

    const actions = [
        <Button type='secondary' onClick={() => { setShowSelectRangeModal(true) }} icon={<DownloadOutlined />} style={{ color: '#1089FF', border: '1px solid #1089FF', marginRight: 20 }}>DOWNLOAD REPORT</Button>,
        <Button type='primary' onClick={() => { setShowOfflinePayReqModal(true); setFeeDetails("paidDate", null); }} icon={<PlusCircleOutlined />}>ADD OFFLINE PAYMENT</Button>
    ]

    const handleBackPress = () => {
        history.goBack();
    }
    console.log(storeTransactionData?.paidTransactions?.length,'aaaaaa')


    return (
        <div style={{ fontFamily: "roboto", overflow: "hidden", height: "86vh" }}>
            <div className='scroll-bar-universal' style={{ width: "100%", height: "76vh", overflowX: "scroll", paddingBottom: "30px", marginTop: 60 }}>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr
                            style={{
                                backgroundColor: "#F6F4FE",
                                padding: "20px",
                                height: "50px",
                            }}
                        >
                            <th style={{ color: "#636363" }}>STUDENT’S DETAILS</th>
                            <th style={{ color: "#636363" }}>DESCRIPTIONS </th>
                            <th style={{ color: "#636363" }}>
                                PAYMENT DATE
                                <Popover
                                    placement="leftTop"
                                    //   title={text}
                                    content={content1}
                                    trigger="hover"
                                >
                                    {/* <Button>RT</Button> */}
                                    <CalendarOutlined
                                        onClick={() => {
                                            setShowCustomCal(true);
                                        }}
                                        style={{
                                            fontSize: "18px",
                                            color: "#1089FF",
                                            marginLeft: "20px",
                                        }}
                                    />
                                </Popover>
                            </th>
                            <th style={{ color: "#636363" }}>
                                TYPE OF PAYMENT
                                <Popover
                                    placement="rightTop"
                                    //   title={text}
                                    content={content}
                                    trigger="hover"
                                >
                                    {/* <Button>RT</Button> */}
                                    <CaretDownOutlined
                                        onClick={() => {
                                            setShowCustomCal(true);
                                        }}
                                    />
                                </Popover>
                            </th>
                            <th style={{ color: "#636363" }}>INVOICE NUMBER</th>
                            <th style={{ color: "#636363" }}>PAYMENT MODE</th>
                            <th></th>

                        </tr>

                    </thead>
                    {allActivitiesFilter?.mode == 'online' || allActivitiesFilter?.mode == 'offline' ||
                    allActivitiesFilter?.filter == "today" || allActivitiesFilter?.filter == "yesterday" || 
                    allActivitiesFilter?.filter == "month" || allActivitiesFilter?.filter == 'lastmonth' ||
                    allActivitiesFilter?.filter == "threemonths" || allActivitiesFilter?.filter == 'Customise range' ?
                        (
                            <>

                                {
                                    feeMangementData?.allActiviies?.items?.map((item, index) => (
                                            <tr
                                                
                                                style={
                                                    index % 2
                                                        ? {
                                                            backgroundColor: "#FAFAFA",
                                                            // padding: "100px",
                                                            height: "100px",
                                                            // marginLeft: "30px",
                                                        }
                                                        : {
                                                            // padding: "100px"
                                                            height: "100px",
                                                        }
                                                }
                                                onClick={() => {
                                                    // item?.payment_type == "online"
                                                    // ?
                                                    // handleClick(item?.invoice);
                                                    // : {};
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        // backgroundColor: "red",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: "100px",
                                                        marginRight: "-10px",

                                                        // width: "200px",
                                                    }}
                                                >
                                                    <div
                                                        className="r-ac"
                                                        style={{
                                                            fontFamily: "roboto",
                                                            marginLeft: "-20px",
                                                            width: "160px",
                                                        }}
                                                    >
                                                        <div  >
                                                            <img src={Api._s3_url + item?.user?.image} style={{ width: "35px", height: "35px", marginLeft: "10px" }} alt="" />

                                                        </div>
                                                        <div style={{ marginLeft: "10px" }}>
                                                            <div style={{ fontWeight: "550" }}>
                                                                <Typography.Text ellipsis={{ tooltip: true }}>
                                                                    <div style={{ width: "100px", fontFamily: 'roboto', fontWeight: "550", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.user?.name}</div>
                                                                </Typography.Text>
                                                            </div>
                                                            <div style={{ color: "#636363" }}>
                                                                {item?.user?.student?.standard?.std} -{" "}
                                                                {item?.user?.student?.standard?.section}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ height: "100px" }}>
                                                    <div
                                                        // className="c-ac"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            marginLeft: "10px",
                                                            // backgroundColor: "red",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <div style={{ fontWeight: "550" }}>
                                                            ₹ {
                                                                item?.amountPayable &&
                                                                numberWithCommas(item?.amountPayable)
                                                            }

                                                        </div>
                                                        {/* <div style={{ color: "#636363" }}> {item?.note}</div> */}
                                                        <Typography.Text style={{ color: "#636363", width: "200px", textAlign: "center" }} ellipsis={{ tooltip: true }}>{item?.note}</Typography.Text>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            color: "#636363",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            marginLeft: "10px",
                                                            // backgroundColor: "red",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <div>{item?.paymentDate}</div>
                                                        {/* <div>09:34 PM</div> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div
                                                            // className="r-ac"
                                                            style={{
                                                                borderRadius: "7px",
                                                                textAlign: "center",
                                                                fontSize: "10px",
                                                                padding: "5px",
                                                                fontWeight: "700",
                                                                color: "white",
                                                                backgroundColor: `${item?.payment_type == "online"
                                                                    ? "#28DF99"
                                                                    : "#636363"
                                                                    }`,
                                                                width: "100px",
                                                            }}
                                                        >
                                                            {item?.payment_type}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ color: "#191919", textAlign: "center" }}>
                                                        {item?.paymentId}
                                                    </div>
                                                </td>
                                                <td>

                                                    <div style={{ color: "#191919", textAlign: "center" }}>
                                                        {item?.payment_method}
                                                    </div>

                                                </td>
                                                <td>

                                                    {/* <Tooltip placement="topLeft" title={"Send Invoice"}>
                                                        <UploadOutlined style={{

                                                            color: "#1089FF", cursor: "pointer",
                                                            fontSize: "20px"

                                                        }}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                postSendInvoice(item?.id)
                                                            }}
                                                        />
                                                    </Tooltip> */}
                                                </td>
                                            </tr>
                                        ))
                                    /* </tr> */
                                }
                            </>
                        ) :
                        (<>
                            {
                                storeTransactionData?.paidTransactions?.length != 0
                                    ? storeTransactionData?.paidTransactions?.map((item, index) => (
                                        <tr
                                            
                                            style={
                                                index % 2
                                                    ? {
                                                        backgroundColor: "#FAFAFA",
                                                        // padding: "100px",
                                                        height: "100px",
                                                        // marginLeft: "30px",
                                                    }
                                                    : {
                                                        // padding: "100px"
                                                        height: "100px",
                                                    }
                                            }
                                            onClick={() => {
                                                // item?.payment_type == "online"
                                                // ?
                                                // handleClick(item?.invoice);
                                                // : {};
                                            }}
                                        >
                                            <td
                                                style={{
                                                    // backgroundColor: "red",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "100px",
                                                    marginRight: "-10px",

                                                    // width: "200px",
                                                }}
                                            >
                                                <div
                                                    className="r-ac"
                                                    style={{
                                                        fontFamily: "roboto",
                                                        marginLeft: "-20px",
                                                        width: "160px",
                                                    }}
                                                >
                                                    <div  >
                                                {console.log("AA-abcd",userRowDetails)}

                                                        <img src={Api._s3_url + userRowDetails?.image} style={{ width: "35px", height: "35px", marginLeft: "10px" }} alt="" />

                                                    </div>
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <div style={{ fontWeight: "550" }}>
                                                        <Typography.Text ellipsis={{ tooltip: true }}>
                                                            <Tooltip title={userRowDetails?.name}>
                                                                <div style={{ width: "100px", fontFamily: 'roboto', fontWeight: "550", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                {userRowDetails?.name}
                                                                </div>
                                                            </Tooltip>
                                                            </Typography.Text>
                                                        </div>
                                                        <div style={{ color: "#636363" }}>
                                                            {item?.user?.student?.standard?.std} -{" "}
                                                            {item?.user?.student?.standard?.section}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ height: "100px" }}>
                                                <div
                                                    // className="c-ac"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        marginLeft: "10px",
                                                        // backgroundColor: "red",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div style={{ fontWeight: "550" }}>
                                                        ₹ {
                                                            item?.amountPayable &&
                                                            numberWithCommas(item?.amountPayable)
                                                        }

                                                    </div>
                                                    {/* <div style={{ color: "#636363" }}> {item?.note}</div> */}
                                                    <Tooltip title={item?.note}>
                                                            <Typography.Text style={{ color: "#636363", width: "200px", textAlign: "center" }} ellipsis={{ tooltip: true }}>
                                                                {item?.note}
                                                            </Typography.Text>
                                                            </Tooltip>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        color: "#636363",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        marginLeft: "10px",
                                                        // backgroundColor: "red",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div>{item?.paymentDate}</div>
                                                    {/* <div>09:34 PM</div> */}
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        // className="r-ac"
                                                        style={{
                                                            borderRadius: "7px",
                                                            textAlign: "center",
                                                            fontSize: "10px",
                                                            padding: "5px",
                                                            fontWeight: "700",
                                                            color: "white",
                                                            backgroundColor: `${item?.payment_type == "online"
                                                                ? "#28DF99"
                                                                : "#636363"
                                                                }`,
                                                            width: "100px",
                                                        }}
                                                    >
                                                        {item?.payment_type}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ color: "#191919", textAlign: "center" }}>
                                                    {item?.paymentId}
                                                </div>
                                            </td>
                                            <td>

                                                <div style={{ color: "#191919", textAlign: "center" }}>
                                                    {item?.payment_method}
                                                </div>

                                            </td>
                                          
                                        </tr>
                                    ))
                                    : null  
   
                            }
                        </>)}

                </table>
                { showData == true ?
                (<>
                {feeMangementData?.allActiviies?.items?.length == 0   ? (
            <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
              <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
              >
                <img src={illNoData} />
              </div>
              <div>
                <h4>
                  <b>No data!</b>
                </h4>
              </div>
              <div>
                <h5>There are no data to show yet. Please check back later</h5>
              </div>
            </div>
          ) : null}
                
                </>):
                (<>
                { storeTransactionData?.paidTransactions?.length == 0   ? (
            <div className="r-c-c-c" style={{ width: "100%", height: "60vh" }}>
              <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
              >
                <img src={illNoData} />
              </div>
              <div>
                <h4>
                  <b>No data!</b>
                </h4>
              </div>
              <div>
                <h5>There are no data to show yet. Please check back later</h5>
              </div>
            </div>
          ) : null}
                </>)}
                   
          
        

            </div >
 
            
            {/* <PageDataLoader visible={pageLoader} /> */}

            {
                showSelectRangeModal && (
                    <SelectRangeModal
                        isModalVisible={showSelectRangeModal}
                        setShowSelectRangeModal={setShowSelectRangeModal}
                        showReasonModal={showReasonModal}
                        setShowReasonModal={setShowReasonModal}
                        kycStatus={feeMangementData?.dashBoardDetails?.kycStatus}
                    />
                )
            }
            {
                showReasonModal &&
                (<ShowReasonModal
                    showReasonModal={showReasonModal}
                    setShowReasonModal={setShowReasonModal}
                    kycStatus={feeMangementData?.dashBoardDetails?.kycStatus}
                    rejectKycMessage={feeMangementData?.dashBoardDetails?.rejectKycMessage ? feeMangementData?.dashBoardDetails?.rejectKycMessage : ''} />)
            }
            {
                showOfflinePayReqModal && (
                    <OfflinePayReq
                        isModalVisible={showOfflinePayReqModal}
                        setIsModalVisible={setShowOfflinePayReqModal}
                        setShowAddDetailsModal={setShowAddDetailsModal}
                        setIsAddOrGenModal={setIsAddOrGenModal}
                        setIsAdditionModal={setIsAdditionModal}
                        showReasonModal={showReasonModal}
                        setShowReasonModal={setShowReasonModal}
                    />
                )
            }
            {
                showAddDetailsModal && (
                    <AddDetailsModal
                        isModalVisible={showAddDetailsModal}
                        setIsAddOrGenModal={setIsAddOrGenModal}
                        isAddOrGenModal={isAddOrGenModal}
                        isAdditionModal={isAdditionModal}
                        handleOk={() => {
                            setShowAddDetailsModal(false);
                            if (isAddOrGenModal == true) {
                                setShowOfflinePayReqModal(true);
                                setIsAddOrGenModal(false);
                            } else {
                                setShowGeneratePayReq(true);
                                setIsAddOrGenModal(false);
                            }
                        }}
                        handleCancel={() => {
                            setShowAddDetailsModal(false);
                            if (isAddOrGenModal == true) {
                                setShowOfflinePayReqModal(true);
                                setIsAddOrGenModal(false);
                            } else {
                                setShowGeneratePayReq(true);
                                setIsAddOrGenModal(false);
                            }
                        }}
                        setShowGeneratePayReq={setShowGeneratePayReq}
                    />
                )
            }
        </div >
    );
}

const mapStateToProps = (state) => {
    const { feeMangementData, allActivitiesFilter } = state.feeManagement;
    const { userRowDetails } = state.userManagementV2;

    return {
        feeMangementData,
        allActivitiesFilter,
        userRowDetails
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
    setAllActivitiesFilter: (key, res) =>
        dispatch(setAllActivitiesFilter(key, res)),
    setInvoiceFile: (file) => dispatch(setInvoiceFile(file)),
    setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
    postSendInvoice: (id) => dispatch(postSendInvoice(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);