import React, { useEffect, useState } from 'react'
import { AutoComplete, Modal, Input, Typography, Button, DatePicker, TimePicker, Tag } from 'antd'
import '../styles.css'
import {
    fetchClassList, fetchSubList, fetchTeacherList, postEditOccurance, setClass, setEndTime, setMode, setSartDate, setEndDate, setStartTime, setSubject, setTeacher,
    fetchSchedule, setScheduleIdDelete, setDelOrCancel, fetchScheduleFilter
} from 'actions/schedule'
import { connect } from 'react-redux'
import moment from 'moment'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { MdCastConnected } from 'react-icons/md'
import Conference  from "Assets/schedule/confrence.svg"
import Broadcast  from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import Conferencewhite from "Assets/schedule/confrence-white.svg"
import BoradcastWhite from "Assets/schedule/Broadcast-white.svg"
import OfflineWhite from "Assets/schedule/ofline-white.svg"
import OtherColor from "Assets/schedule/other_color.svg"
import OtherWhite from "Assets/schedule/other_white.svg"
import { EventRegister } from 'react-native-event-listeners'
const { Text } = Typography

const EditOccurance = ({ isModalVisible, setModalVisible, editData, subList, fetchSubList, fetchClassList, classList, setSubject, setClass, subId, teacherList, fetchTeacherList,
    setTeacher, setStartTime, setSartDate, setEndDate, setEndTime, setMode, classId, teacherId, startTime, endTime,
    postEditOccurance, mode, fetchSchedule, togglePageLoader, setScheduleIdDelete, toogleDeleteModal, setDelOrCancel, fetchScheduleFilter, currentDate,
    getModes, teacherSearch, classFilter, subFilter
}) => {

    const [optionSub, setOptionsSub] = useState([])
    const [optionClass, setOptionsClass] = useState([])
    const [optionTeacher, setOptionsTeacher] = useState([])
    const [subName, setSubName] = useState('')
    const [className, setClassName] = useState('')
    const [teacherName, setTeacherName] = useState('')
    const [occuranceId, setOccuranceId] = useState(null)
    let [classNameArr, setClassNameArr] = useState([])
    const [buttonColor, setButtonColor] = useState([false, false, false, false, false])
    const [subjectLoader, setSubjectLoader] = useState(false)
    const [stdLoader, setStdLoader] = useState(false)
    const [teacherLoader, setTeacherLoader] = useState(false)
    const [saveLoader, setSaveLoader] = useState(false)

    const [dateOccur, setDateOccur] = useState()
    const format = 'hh:mm A'
    const formatDate = "DD/MM/YYYY"
    useEffect(() => {
        if (Object.keys(editData).length != 0) {
            setSubName(editData?.subject?.name)
            setSubject(editData?.subjectId)

            if (editData?.subjectId) {
                fetchClassList('', editData?.subjectId)
            }
            editData.standards.map((item) => (
                classId.push(item.id),
                classNameArr.push({ id: item.id, name: item.std + '-' + item.section })
            ))
            setClass(classId)
            setClassNameArr(classNameArr)
            setTeacherName(editData?.teacher?.name)
            setTeacher(editData?.teacherId)
            setStartTime(`${moment(editData?.startTime, "HH:mm:ss").format("HH:mm")}`)
            setEndTime(`${moment(editData?.endTime, "HH:mm:ss").format("HH:mm")}`)
            setMode(editData?.mode)
            if (editData?.mode == 'Zoom 40') {
                buttonColor[0] = true
            }
            else if (editData?.mode == 'Conference') {
                buttonColor[1] = true
            }
            else if (editData?.mode == 'Broadcast') {
                buttonColor[2] = true
            }
            else if (editData?.mode == 'Offline') {
                buttonColor[3] = true
            }else if(editData?.mode == 'Others'){
                buttonColor[4] = true
            }

            setOccuranceId(editData?.id)
            setScheduleIdDelete(editData?.id)
            setDateOccur(editData?.date)
        }
    }, [])
    const [validModes, setValidModes] = useState([])
    useEffect(() => {
        fetchSubList()
        fetchTeacherList('')
        if (getModes.length != 0) {
            getModes.map((item) => (
                validModes.push(item.mode)
            ))
            setValidModes([...validModes, 'Others'])
        }
    }, [])
    const searchResult = (query) =>
        query.map((item, index) => {
            return {
                key: index,
                value: item.id,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {item.name}
                        </span>
                    </div>
                ),
            };
        });

    const searchResultClass = (query) =>
        query.map((item, index) => {
            return {
                key: index,
                value: item.id,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            {item.std + '-' + item.section}
                        </span>
                    </div>
                ),
            };
        });
    useEffect(() => {
        setOptionsSub(subList ? [...searchResult(subList)] : [])
    }, [subList])

    useEffect(() => {
        setOptionsClass(classList ? [...searchResultClass(classList)] : [])
    }, [classList])

    useEffect(() => {
        setOptionsTeacher(teacherList ? [...searchResult(teacherList)] : [])
    }, [teacherList])

    const handleSearchSub = (value) => {
        if (!value) {
            setSubName('')
        }
        else {
            setSubName(value)
            setSubjectLoader(true)
        }
        fetchSubList(value, () => setSubjectLoader(false))
    }

    const handleChangeSub = (value, obj) => {
        setSubject(null)
        setClass([])
        setClassName('')
        setClassNameArr([])
        setTeacher(null)
        setTeacherName('')
        if (value) {
            setSubName(obj?.label?.props?.children?.props?.children)
        }
        else {
            setSubject(null)
            setSubName('')
            setClass([])
            setClassName('')
            setClassNameArr([])
            setTeacher(null)
            setTeacherName('')
        }
    }

    const onSelectSub = (value, obj) => {
        if (value) {
            setSubject(value)

            setSubName(obj?.label?.props?.children?.props?.children)
            setClass([])
            setClassNameArr([])
            fetchClassList('', value)

        }
        else {
            setSubject(null)
            setSubName('')
            setClass([])
            setClassNameArr([])
            setTeacher(null)
            setTeacherName('')
        }
    }

    const handleSearchClass = (value) => {
        if (value) {
            setClassName(value)
            setStdLoader(true)
            fetchClassList(value, subId, () => setStdLoader(false))
        }
        else {
            setClassName('')
            fetchClassList('', subId, () => setStdLoader(false))
        }
    }

    const handleChangeClass = (value, obj) => {
        if (!value) {
            // setClass([])
            setClassName('')
            // setClassNameArr([])
            // setTeacher(null)
            // setTeacherName('')
        }
    }

    const onSelectClass = (value, obj) => {
        if (value) {
            if (!classId.includes(value)) {
                classId.push(value)
                setClass(classId)
                setClassName(obj?.label?.props?.children?.props?.children)
                classNameArr.push({ id: value, name: obj?.label?.props?.children?.props?.children })
                setClassNameArr(classNameArr)
            }

            fetchTeacherList('')
        }
        else {
            setClass([])
            setClassName('')
            setClassNameArr([])
            setTeacher(null)
            setTeacherName('')
        }
    }

    const handleChangeTeacher = (value, obj) => {
        setTeacher(null)
        setTeacherName('')
        if (!value) {
            setTeacher(null)
            setTeacherName('')
        }
    }

    const handleSearchTeacher = (value) => {
        if (value) {
            setTeacherName(value)
            setTeacherLoader(true)

        }
        else {
            setTeacherName('')
        }
        fetchTeacherList(value, () => setTeacherLoader(false))
    }

    const onSelectTeacher = (value, obj) => {
        if (value) {
            setTeacher(value)
            setTeacherName(obj?.label?.props?.children?.props?.children)
        }
        else {
            setTeacher(null)
            setTeacherName('')
        }
    }

    const onChangeStartDate = (date, dateString) => {
        setSartDate(dateString)
    }

    const onChangeStartTime = (time, timestring) => {
        setEndTime('');
        if (timestring) {
            const timestring24 = moment(timestring, format).format('HH:mm');
            if (mode === "Zoom 40") {
                setEndTime(moment(timestring24, 'HH:mm').add(40, 'minutes').format('HH:mm'));
            }
            setStartTime(timestring24);
        } else {
            setStartTime('');
        }
    };

    const onChangeEndTime = (time, timestring) => {
        if (timestring) {
            const timestring24 = moment(timestring, format).format('HH:mm');
            setEndTime(timestring24);
        } else {
            setEndTime('');
        }
    };

    const disabledStartTimeHours = () => {

        var hours = []
        if (moment().format('YYYY-MM-DD') == moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD')) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }
        }
        return hours
    }

    const disabledStartTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour()) {
            for (var i = 0; i < moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const disabledEndTimeHours = () => {

        var hours = []
        if (moment().format('YYYY-MM-DD') == moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD')) {
            for (var i = 0; i < moment(startTime, "HH").format('HH'); i++) {
                hours.push(i)
            }
        }
        return hours
    }

    const disabledEndTimeMinutes = (selectedHour) => {
        var minutes = []
        if (selectedHour === moment().hour()) {
            for (var i = 0; i < moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const handleClickMode = (value) => {
        setMode(value)
        if (value == "Zoom 40") {
            buttonColor[0] = true
            buttonColor[1] = false
            buttonColor[2] = false
            buttonColor[3] = false
            buttonColor[4] = false
        }
        else if (value == "Conference") {
            buttonColor[0] = false
            buttonColor[1] = true
            buttonColor[2] = false
            buttonColor[3] = false
            buttonColor[4] = false
        }
        else if (value == "Broadcast") {
            buttonColor[0] = false
            buttonColor[1] = false
            buttonColor[2] = true
            buttonColor[3] = false
            buttonColor[4] = false
        }
        else if (value == "Offline") {
            buttonColor[0] = false
            buttonColor[1] = false
            buttonColor[2] = false
            buttonColor[3] = true
            buttonColor[4] = false
        }else if(value=="Others"){
            buttonColor[0] = false
            buttonColor[1] = false
            buttonColor[2] = false
            buttonColor[3] = false
            buttonColor[4] = true
        }
        setButtonColor(buttonColor)
    }

    const onClose = (value) => {
        console.log('val', value)
        classNameArr = classNameArr.filter((item) => {
            return item.id != value
        })
        classId = classId.filter((item) => {
            return item != value
        })
        setClass(classId)
        setClassName('')
        // setTeacher(null)
        // setTeacherName('')

    }
    const handleCancelOccurance = () => {
        setScheduleIdDelete(editData?.id)
        setSubject(null)
        setSubName('')
        setTeacher(null)
        setStartTime('')
        setEndTime('')
        setMode('')
        setClass([])
        setClassNameArr([])
        setOccuranceId(null)
        setDelOrCancel('cancelOccurance')
        setModalVisible(false)
        toogleDeleteModal(true)


    }

    const createSchedule = () => {
        if (occuranceId && subId && startTime && endTime && mode && classId.length != 0 && teacherId) {
            var objectSchedule = {
                id: occuranceId,
                teacherId: teacherId,
                subjectId: subId,
                classIds: classId,
                startTime: startTime,
                endTime: endTime,
                mode: mode,

            }

            setSaveLoader(true)

            postEditOccurance(objectSchedule, () => setSaveLoader(false), successCallback)

        }
        else {
            alert('please fill the all information')
        }
    }

    const successCallback = () => {
        EventRegister.emit('apicall:scheduleHome')
        setSaveLoader(false)
        setSubject(null)
        setSubName('')
        setTeacher(null)
        setStartTime('')
        setEndTime('')
        setMode('')
        setClass([])
        setClassNameArr([])
        setOccuranceId(null)
        setModalVisible(false)
        togglePageLoader(true)
        if (currentDate === moment().format('DD/MM/YYYY')) {
            fetchSchedule(() => togglePageLoader(false))
        }
        else {
            fetchScheduleFilter(moment(currentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), teacherSearch, classFilter, subFilter, () => togglePageLoader(false))
        }

    }


    const handleCancle = () => {
        setSubject(null)
        setSubName('')
        setTeacher(null)
        setStartTime('')
        setEndTime('')
        setMode('')
        setClass([])
        setClassNameArr([])
        setOccuranceId(null)
        setModalVisible(false)
    }

    const removeSelectedSubject = (currSubject) => {
        if (currSubject?.label.props.children.props?.children == subName) {
            return false;
        }
        return true
    }

    const removeSelectedClass = (currClass) => {
        for (let i = 0; i < classNameArr.length; i++) {
            if (currClass?.label?.props?.children?.props?.children === classNameArr[i]?.name) {
                return false
            }
        }
        return true
    }

    const removeSelectedTeacher = (currTeacher) => {
        if (currTeacher?.label?.props?.children?.props?.children == teacherName) {
            return false;
        }
        return true;
    }

    const onKeyDown = (e) => {
        e.preventDefault();
    }

    return (
        <Modal
            className="createForm"
            visible={isModalVisible}
            footer={null}
            centered={true}
            closable={true}
            bodyStyle={{ height: "450px" }}
            onCancel={() => handleCancle()}
        >
            <div className="createForm-scroll">
                <div className="createForm-input">
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '10px' }}>Subject</Text><br></br>
                        <AutoComplete
                            allowClear={true}
                            dropdownMatchSelectWidth={252}
                            className="autocompletetoupload"
                            options={optionSub ? optionSub?.filter(removeSelectedSubject) : []}
                            value={subName}
                            onChange={handleChangeSub}
                            onSelect={onSelectSub}
                            onSearch={handleSearchSub}
                            loading={subjectLoader}

                        >
                            <Input size="middle" className="input-component" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select subject' />
                        </AutoComplete>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Class</Text><br></br>
                        <AutoComplete
                            allowClear={true}
                            dropdownMatchSelectWidth={252}
                            className="autocompletetoupload"
                            options={optionClass ? optionClass?.filter(removeSelectedClass) : []}
                            value={className}
                            onChange={handleChangeClass}
                            onSelect={onSelectClass}
                            onSearch={handleSearchClass}
                            loading={stdLoader}
                            disabled={subId ? false : true}
                        >
                            <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select class' />
                        </AutoComplete>
                        {classNameArr.length != 0 ? classNameArr.map((item) => (
                            <Tag closable color="#594099" style={{ height: '30px', padding: 5, marginTop: 3 }} onClose={() => onClose(item.id)}>{item.name}</Tag>
                        )) : null}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Teacher</Text><br></br>
                        <AutoComplete
                            allowClear={true}
                            dropdownMatchSelectWidth={252}
                            className="autocompletetoupload"
                            value={teacherName}
                            options={optionTeacher ? optionTeacher?.filter(removeSelectedTeacher) : []}
                            onChange={handleChangeTeacher}
                            onSelect={onSelectTeacher}
                            onSearch={handleSearchTeacher}
                            loading={teacherLoader}
                        // disabled={classId.length != 0 ? false : true}
                        >
                            <Input size="middle" style={{ background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Select teacher' />
                        </AutoComplete>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Mode of class</Text><br></br>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop:10 }}>

                            <Button style={{width:190, backgroundColor: buttonColor[1] == true ? '#594099' : '#F6F4FE', display: validModes.includes('Conference') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', borderRadius: '16px' }} onClick={() => handleClickMode("Conference")}><img src={buttonColor[1] == false ? Conference : Conferencewhite}  style={{color: buttonColor[1] == true ? '#FAFAFA' : '#1089FF',  textAlign: 'center', height:"16px",width:"16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[1] == true ? '#FAFAFA' : '#594099', marginLeft: '5px' }}>Conference</Text></Button>
                            <Button style={{width:190, backgroundColor: buttonColor[2] == true ? '#FFA931' : '#FFF4D5', display: validModes.includes('Broadcast') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center', borderRadius: '16px' }} onClick={() => handleClickMode("Broadcast")}><img src={buttonColor[2]== false ? Broadcast : BoradcastWhite} style={{color: buttonColor[2] == true ? '#FAFAFA' : '#1089FF',  textAlign: 'center',height:"16px",width:"16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[2] == true ? '#FAFAFA' : '#FFA931', marginLeft: '5px' }}>Broadcast</Text></Button>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop:15 }}>
                                <Button style={{width:190, backgroundColor: buttonColor[3] == true ? "#28DF99" : '#E9FCF5', display: validModes.includes('Offline') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center',  borderRadius: '16px' }} onClick={() => handleClickMode("Offline")}><img src={buttonColor[3] == false ? Offline : OfflineWhite} style={{ color: buttonColor[3] == true ? '#FAFAFA' : '#1089FF', textAlign: 'center',height:"16px",width:"16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[3] == true ? '#FAFAFA' : '#28DF99', marginLeft: '5px' }}>Offline</Text></Button>
                                <Button style={{width:190, backgroundColor: buttonColor[4] == true ? "#E50087" : '#FFE5F4', display: validModes.includes('Others') ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center',  borderRadius: '16px' }} onClick={() => handleClickMode("Others")}><img src={buttonColor[4] == false ? OtherColor : OtherWhite} style={{ color: buttonColor[4] == true ? '#FAFAFA' : '#E50087', textAlign: 'center',height:"16px",width:"16px" }} /><Text style={{ fontSize: '10px', color: buttonColor[4] == true ? '#FAFAFA' : '#E50087', marginLeft: '5px' }}>Other</Text></Button>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ marginBottom: '5px' }}>Date</Text><br></br>
                        <DatePicker onKeyDown={(e) => onKeyDown(e)} value={moment(currentDate, 'DD/MM/YYYY')} style={{ width: "100%", background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} placeholder='Date' format={formatDate} onChange={onChangeStartDate} disabled={true} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <Text style={{ marginBottom: '5px' }}>Start time</Text><br></br>
                            <TimePicker onKeyDown={(e) => onKeyDown(e)} value={startTime ? moment(startTime, 'HH:mm') : null} format={format} style={{ width: '170px', background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} onChange={onChangeStartTime} showNow={false} disabledHours={() => disabledStartTimeHours()} disabledMinutes={disabledStartTimeMinutes} disabled={currentDate ? false : true} />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <Text style={{ marginBottom: '5px' }}>End time</Text><br></br>
                            <TimePicker onKeyDown={(e) => onKeyDown(e)} value={endTime ? moment(endTime, 'HH:mm') : null} format={format} style={{ width: '170px', background: '#FAFAFA 0% 0% no-repeat padding-box', border: 'none' }} disabled={startTime ? false : true} showNow={false} onChange={onChangeEndTime} disabledHours={() => disabledEndTimeHours()} disabledMinutes={disabledEndTimeMinutes} />
                        </div>
                    </div>


                </div>
                <div style={{ width: '70%', marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button className='BlankGreyBtnWrap radius-100' style={{ width: 150 }} onClick={handleCancelOccurance} disabled={occuranceId && subId && startTime && endTime && mode && classId.length != 0 && teacherId ? false : true}>Cancel Schedule</Button>
                    <Button loading={saveLoader} className='purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118' onClick={createSchedule} disabled={occuranceId && subId && startTime && endTime && mode && classId.length != 0 && teacherId ? false : true}>Save</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const {
        subList,
        subId,
        classList,
        teacherList,
        classId,
        teacherId,
        startTime,
        endTime,
        mode,
        currentDate,
        getModes,
        teacherSearch, classFilter, subFilter
    } = state.schedule;
    return {
        subList,
        subId,
        classList,
        teacherList,
        classId,
        teacherId,
        startTime,
        endTime,
        mode,
        currentDate,
        getModes,
        teacherSearch, classFilter, subFilter
    };
};

const mapDispatchToProps = (dispatch) => ({
    setSubject: (subId) => dispatch(setSubject(subId)),
    setClass: (classId) => dispatch(setClass(classId)),
    setTeacher: (teacherId) => dispatch(setTeacher(teacherId)),
    setStartTime: (time) => dispatch(setStartTime(time)),
    setEndTime: (time) => dispatch(setEndTime(time)),
    setSartDate: (date) => dispatch(setSartDate(date)),
    setEndDate: (enddate) => dispatch(setEndDate(enddate)),
    setMode: (mode) => dispatch(setMode(mode)),
    fetchSubList: (search, callback) => dispatch(fetchSubList(search, callback)),
    fetchClassList: (search, subId, callback) => dispatch(fetchClassList(search, subId, callback)),
    fetchTeacherList: (search, callback) => dispatch(fetchTeacherList(search, callback)),
    postEditOccurance: (object, callback, successCallback) => dispatch(postEditOccurance(object, callback, successCallback)),
    fetchSchedule: (callback) => dispatch(fetchSchedule(callback)),
    setScheduleIdDelete: (id) => dispatch(setScheduleIdDelete(id)),
    setDelOrCancel: (delOrCancel) => dispatch(setDelOrCancel(delOrCancel)),
    fetchScheduleFilter: (date, topic, std, sub, callback) => dispatch(fetchScheduleFilter(date, topic, std, sub, callback))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(EditOccurance);