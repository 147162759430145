import React, { useEffect, useState } from 'react'
import { QuestionTypes } from './questionTypes';

const OrQuestion = ({data, currQuesIndex, setAnswerObj, answerObj, toggleImageModal, setCurrentImg}) => {

  const [insideQuesType, setInsideQuesType] = useState();
  const [quesData, setQuesData] = useState(null);
  // const [answerObj, setAnswerObj] = useState({orQuestionIndex: null});

  useEffect(() => {
    data?.questionContent?.orQuestions?.map((item)=>{
      setInsideQuesType(item?.type);
    });
  }, []);

  return(
    <div>
      {data?.questionContent?.orQuestions?.map((item, index)=>{
        return(
          <div>
            <div style={{fontWeight: 600, color: '#636363', margin: 20, marginBottom: -10}}>{`Question ${currQuesIndex}(${String.fromCharCode('a'.charCodeAt(0) + index)})`}</div> 
            <QuestionTypes data={data} type={item?.type} quesContent={item?.questionContent} quesIndex={index} currQuesIndex={currQuesIndex} setAnswerObj={setAnswerObj} answerObj={answerObj} orQuestion={true} toggleImageModal={toggleImageModal} setCurrentImg={setCurrentImg}/>

            {/* this condition is to add HORIZONTAL OR LINE */}
            {index != data?.questionContent?.orQuestions.length - 1 ? 
              <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                <div style={{width: '40%', height: 1, borderBottom: '1px solid black', padding: '0 5px'}}></div>
                  <span style={{marginTop: -10, padding: '0px 10px'}}>OR</span>
                <div style={{width: '40%', height: 1, borderBottom: '1px solid black'}}></div>
              </div>
            : null
            } 
          </div>
        ) 
      })}
    </div>
  )
}

export default OrQuestion