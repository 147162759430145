import React, { useEffect } from 'react'
import { Col, PageHeader, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import image1 from '../../Assets/care/Mask Group 63.svg';
import image2 from 'Assets/care/Mask Group 49.png'
import image3 from 'Assets/care/Mask Group 50.png'
import image4 from 'Assets/care/Group 10231.png'
import image5 from 'Assets/care/Group 10233.png'
import image6 from 'Assets/care/Group 10235.png'
import image7 from 'Assets/care/Group 10237.png'
import image8 from 'Assets/care/Group 10239.png'
import image9 from 'Assets/care/Group 10376.svg'
import image10 from 'Assets/care/Group 10378.svg'
import image11 from 'Assets/care/Group 10380.svg'
import image12 from 'Assets/care/Group 10384.svg'
import image13 from 'Assets/care/Group 10387.svg'
import image14 from 'Assets/care/Group 10390.svg'
import image15 from 'Assets/care/Group 10496.png'
import image16 from 'Assets/care/Group 10497.png'
import image17 from 'Assets/care/Group 10498.png'
import image18 from 'Assets/care/Group 10499.png'
import image19 from 'Assets/care/Mask Group 42.png'
import image20 from 'Assets/care/Mask Group 43.png'
import image21 from 'Assets/care/Mask Group 44.png'
import image22 from 'Assets/care/Mask Group 45.png'
import image23 from 'Assets/care/Mask Group 46.png'
import image24 from 'Assets/care/Mask Group 47.png'
import image25 from 'Assets/care/Mask Group 48.png'
import { getCovid19Count } from 'actions/adminCare';


function changeNumberFormat(number, decimals, recursiveCall) {
    const decimalPoints = decimals || 2;
    const noOfLakhs = number / 100000;
    let displayStr;
    let isPlural;

    function roundOf(integer) {
        return +integer.toLocaleString(undefined, {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
        });
    }

    if (noOfLakhs >= 1 && noOfLakhs <= 99) {
        const lakhs = roundOf(noOfLakhs);
        isPlural = lakhs > 1 && !recursiveCall;
        displayStr = `${lakhs} Lakh${isPlural ? 's' : ''}`;
    } else if (noOfLakhs >= 100) {
        const crores = roundOf(noOfLakhs / 100);
        const crorePrefix = crores >= 100000 ? changeNumberFormat(crores, decimals, true) : crores;
        isPlural = crores > 1 && !recursiveCall;
        displayStr = `${crorePrefix} Crore${isPlural ? 's' : ''}`;
    } else {
        displayStr = roundOf(+number);
    }

    return displayStr;
}


const allVaccines = [
    {
        id: 1,
        image: image2,
        hText: 'Covishield',
        pText: 'Serum Institute of India',
        link: 'https://www.seruminstitute.com/product_covishield.php',
    },
    {
        id: 2,
        image: image3,
        hText: 'Covaxin',
        pText: 'Bharat Biotech',
        link: 'https://www.precisionvaccinations.com/vaccines/covaxin-covid-19-vaccine',
    },
    {
        id: 3,
        image: image4,
        hText: 'Sputnik V',
        pText: 'Gamaleya',
        // link: 'https://www.mpnrc.org/sputnik-vaccine-registration-efficacy-price-side-effects-dose-gap/',
        link:'https://sputnikvaccine.com/'
    },
    {
        id: 4,
        image: image5,
        hText: 'mRNA-1273',
        pText: 'Moderna',
        link: 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/Moderna.html',
    },
    {
        id: 5,
        image: image6,
        hText: 'ZyCov-D',
        pText: 'Zydus Cadila',
        link: 'https://indianexpress.com/article/explained/explained-zydus-cadilas-needle-free-system-to-administer-zycov-d-7467011/',
    },
    {
        id: 6,
        image: image7,
        hText: 'Ad26.COV2.S',
        pText: 'Johnson & Johnsons and Janssen ',
        link: 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/janssen.html',
    },
    {
        id: 7,
        image: image8,
        hText: 'AZD1222',
        pText: 'Oxford/AstraZeneka',
        link: 'https://www.who.int/news-room/feature-stories/detail/the-oxford-astrazeneca-covid-19-vaccine-what-you-need-to-know',
    },



]

const statistic = [
    {
        id: 1,
        pText: 'CoWIN Registrations',
        hText: '345436',
        rText: "+ 61.43 Lakhs Yesterday"
    },
    {
        id: 2,
        pText: 'Vaccinations Delivered',
        hText: "53.47 Crores",
        rText: "+ 61.43 Lakhs Yesterday"
    },
    {
        id: 3,
        pText: 'Fully Vaccinated',
        hText: "53.47 Crores",
        rText: "+ 61.43 Lakhs Yesterday"
    },
]

const faqsAboutCovid19Vaccination = [
    {
        image: image9,
        text: 'Vaccine registration'
    },
    {
        image: image10,
        text: 'About the vaccine'
    },
    {
        image: image11,
        text: 'Who will get the vaccine?'
    },
    {
        image: image12,
        text: 'How will be vaccinated?'
    },
    {
        image: image13,
        text: 'What to expect before vaccination?'
    },
    {
        image: image14,
        text: 'What to expect after vaccination?'
    },

]
const commonSideEffect = [
    {
        id: 1,
        image: image15,
        pText: 'Mild headaches'
    },
    {
        id: 1,
        image: image16,
        pText: 'Pain or swelling at the injection site'
    },
    {
        id: 1,
        image: image17,
        pText: 'Mild fever'
    },
    {
        id: 1,
        image: image18,
        pText: 'Irritability'
    },
]

const recognizedHealthOrganisation = [
    {
        id: 1,
        image: image19,
        hText: 'WHO India',
        link: 'https://www.who.int/india',
    },
    {
        id: 2,
        image: image20,
        hText: 'National Health Portal of India',
        link: 'https://www.india.gov.in/topics',
    },
    {
        id: 3,
        image: image21,
        hText: 'Public Health Foundation of India',
        link: 'https://phfi.org/',
    },
    {
        id: 4,
        image: image22,
        hText: 'Ministry of Health and Family Welfare ',
        link: 'https://main.mohfw.gov.in/Organisation/autonomous-bodies',
    },
    {
        id: 5,
        image: image23,
        hText: 'Indian Council of Medical Research',
        link: 'https://www.icmr.gov.in/',
    },
    {
        id: 6,
        image: image24,
        hText: 'MSF India',
        link: 'https://www.msfindia.in/',
    },
    {
        id: 7,
        image: image25,
        hText: 'UNICEF India',
        link: 'https://www.unicef.org/india/',
    },



]

function CovidResources({
    getCovid19Count,
    registrations,
    Y_registrations,
    delivered,
    Y_delivered,
    vaccinated,
    Y_vaccinated
}) {
    statistic[0]['hText'] = changeNumberFormat(registrations);
    statistic[0]['rText'] = changeNumberFormat(Y_registrations);
    statistic[1]['hText'] = changeNumberFormat(delivered);
    statistic[1]['rText'] = changeNumberFormat(Y_delivered);
    statistic[2]['hText'] = changeNumberFormat(vaccinated);
    statistic[2]['rText'] = changeNumberFormat(Y_vaccinated);


    useEffect(() => {
        getCovid19Count()

    }, [])
    const history = useHistory();


    return (
        <div style={{ width: "100%", height: "100%" }}>
            <PageHeader
                title={<div className="text-md" style={{ fontFamily: "roboto" }}>Covid 19 resources</div>}
                onBack={() => history.goBack()}
                backIcon={<ArrowLeftOutlined />}
            />
            <Row >
                <Col md={{ span: 24 }} lg={{ span: 8 }} >
                    <div className="vaccinationCard" >
                        <div >
                            <h3 style={{ fontFamily: "roboto", fontWeight: "700", marginBottom: "0px" }}>Find the nearest Vaccination Center</h3>
                            <p style={{ fontFamily: "roboto", color: "#636363" }}>Source:cowin.gov.in</p>
                        </div>
                        <p style={{ fontFamily: "roboto", color: "#636363" }}>The COVID-19 vaccines are safe for most people 18 years and older, including those with pre-existing conditions of any kind, including auto-immune disorders.</p>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", textAlign: "center" }}>
                            <button onClick={event => window.location.href = 'https://www.cowin.gov.in/'} style={{ backgroundColor: "#594099", border: "none", color: "#FFFFFF", fontFamily: "roboto", padding: "8px", width: "250px", cursor: "pointer", borderRadius: "25px" }}>FIND A VACCINATION CENTER</button>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 24 }} lg={{ span: 16 }}>
                    <div className="covidImageCard">
                        <div style={{ marginTop: "10px", display: "flex", padding: "10px 20px 0 20px", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <h3 style={{ fontFamily: "roboto", marginLeft: "10px", fontWeight: "700", marginBottom: "0px" }}>Prevent the spread of COVID-19</h3>
                                <p style={{ fontFamily: "roboto", marginLeft: "10px", color: "#636363", marginBottom: "0px" }}>Source:who.int</p>
                            </div>
                            <button onClick={event => window.location.href = 'https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public'} style={{ width: "150px", backgroundColor: "white", fontWeight: "650", padding: "5px", borderRadius: "25px", cursor: "pointer", border: "#594099 solid 1px", color: "#594099" }}>LEARN MORE</button>
                        </div>
                        <img width="100%" paddingBottom='0px' marginBottom='0px' style={{}} src={image1} />
                    </div>
                </Col>
            </Row>
            <h3 style={{ fontFamily: "roboto", marginTop: "20px", marginBottom: "0px", fontFamily: "roboto", marginLeft: "10px", fontWeight: "700", fontSize: "24px" }}>Know your vaccines</h3>
            <p style={{ color: "#AEAEAE", marginLeft: "10px", fontFamily: "roboto", fontSize: "12px" }}>Source: covid19.trackvaccines.org</p>
            <p style={{ color: "#636363", marginLeft: "10px", fontFamily: "roboto", fontSize: "14px" }}>7 Vaccines Approved for Use in India.</p>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", flexWrap: "wrap" }}>
                {allVaccines.map((item) =>
                    <div key={item.id} style={{ width: "190px", border: "#E6E6E6 solid 1px", display: "flex", height: "230px", flexDirection: "column", justifyContent: "space-between", marginLeft: "10px", padding: "20px", borderRadius: "15px", marginBottom: "10px", alignItems: "center" }}>

                        <img style={{ width: "70px", height: "70px" }} src={item.image} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                            <h3 style={{ fontFamily: "roboto", fontWeight: "700", marginBottom: "0px" }}> {item.hText}</h3>
                            <p style={{ fontFamily: "roboto", color: "#636363", marginBottom: "0px", textAlign: "center" }}>{item.pText}</p>
                        </div>
                        <button onClick={event => window.location.href = item.link} style={{ backgroundColor: "white", marginTop: "10px", padding: "7px", width: "150px", fontWeight: "650", borderRadius: "30px", border: "#594099 solid 1px", color: "#594099", cursor: "pointer" }}>LEARN MORE</button>

                    </div>
                )}
            </div>

            <h2 style={{ fontFamily: "roboto", marginTop: "20px", marginBottom: "0px", fontFamily: "roboto", marginLeft: "10px", fontWeight: "700", fontSize: "24px" }}>Join the largest vaccination drive</h2>
            <p style={{ color: "#AEAEAE", marginLeft: "10px", fontFamily: "roboto", fontSize: "12px" }}>Source:dashboard.cowin.gov.in</p>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", flexWrap: "wrap" }}>
                {statistic.map((item) =>
                    <div key={item.id} style={{ width: "360px", border: "#E6E6E6 solid 1px", display: "flex", height: "130px", flexDirection: "column", justifyContent: "space-between", marginLeft: "10px", padding: "20px", borderRadius: "30px", marginBottom: "10px", alignItems: "flex-start" }}>
                        <p style={{ color: "#636363" }}>{item.pText}</p>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline", width: "100%" }}>
                            <h3 style={{ fontFamily: "roboto", fontWeight: "700", marginBottom: "0px", fontSize: "24px" }}> {item.hText}</h3>
                            <p style={{ color: "#28DF99" }}>+{item.rText} Yesterday</p>
                        </div>

                    </div>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <button onClick={event => window.location.href = 'https://dashboard.cowin.gov.in/'} style={{ backgroundColor: "white", fontWeight: "650", padding: "5px", borderRadius: "30px", border: "#594099 solid 1px", color: "#594099", padding: "10px", width: "250px", cursor: "pointer" }}>VIEW DASHBOARD AT COWIN</button>
            </div>

            <h2 style={{ fontFamily: "roboto", marginTop: "20px", marginBottom: "0px", fontFamily: "roboto", marginLeft: "10px", fontWeight: "700", fontSize: "24px" }}>FAQs about Covid-19 Vaccination</h2>
            <p style={{ color: "#AEAEAE", marginLeft: "10px", fontFamily: "roboto", fontSize: "12px" }}>Source: mohfw.gov.in</p>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", flexWrap: "wrap" }}>
                {faqsAboutCovid19Vaccination.map((item) =>
                    <div key={item.id} style={{ width: "190px", border: "#E6E6E6 solid 1px", display: "flex", height: "230px", flexDirection: "column", justifyContent: "space-between", marginLeft: "10px", padding: "20px", borderRadius: "30px", marginBottom: "10px", alignItems: "center" }}>

                        <img style={{ width: "70px", height: "70px" }} src={item.image} />
                        <p style={{ fontFamily: "roboto", fontWeight: "700", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start", textAlign: "center" }}>{item.text}</p>

                    </div>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <button onClick={event => window.location.href = 'https://www.mohfw.gov.in/covid_vaccination/vaccination/faqs.html#vaccine-registration'} style={{ backgroundColor: "white", fontWeight: "650", padding: "5px", borderRadius: "30px", border: "#594099 solid 1px", color: "#594099", padding: "10px", width: "200px", cursor: "pointer" }}>READ NOW</button>
            </div>

            <h2 style={{ fontFamily: "roboto", marginTop: "20px", marginBottom: "0px", fontFamily: "roboto", marginLeft: "10px", fontWeight: "700", fontSize: "24px" }}>Common side effect (AEFI)</h2>
            <p style={{ color: "#AEAEAE", marginLeft: "10px", fontFamily: "roboto", fontSize: "12px" }}>Source: cowin.gov.in</p>
            <p style={{ color: "#AEAEAE", marginLeft: "10px", fontFamily: "roboto", fontSize: "12px" }}>Some common side-effects you might experience after the covid 19 vaccine.</p>
            <div style={{ display: "flex", marginBottom: "10px", flexDirection: "row", marginLeft: "10px", flexWrap: "wrap", width: "100%" }}>
                {commonSideEffect.map((item) =>
                    <div key={item.id} style={{ display: "flex", height: "250px", flexDirection: "column", justifyContent: "space-between", marginLeft: "10px", padding: "20px", borderRadius: "30px", marginBottom: "10px", alignItems: "center" }}>

                        <img style={{ width: "250px", height: "250px" }} src={item.image} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "roboto", color: "#636363", marginTop: "10px" }}>{item.pText}</p>
                        </div>

                    </div>
                )}
            </div>

            <div style={{ marginTop: "30px" }}>
                <ul>
                    <li><p style={{ fontFamily: "roboto", color: "#636363", marginBottom: "0px" }}>Side-effects should go away in a few days.</p></li>
                    <li><p style={{ fontFamily: "roboto", color: "#636363" }}>Get the second dose even if you have side-effects from the first dose, unless a vaccination provider or your doctor advices you otherwise.</p></li>
                </ul>
            </div>
            <h2 style={{ fontFamily: "roboto", marginTop: "20px", marginBottom: "20px", fontFamily: "roboto", marginLeft: "10px", fontWeight: "700", marginTop: "3px", fontSize: "24px" }}>Recognized health organisation</h2>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", flexWrap: "wrap" }}>
                {recognizedHealthOrganisation.map((item) =>
                    <div key={item.id} style={{ width: "190px", border: "#E6E6E6 solid 1px", display: "flex", height: "250px", flexDirection: "column", justifyContent: "space-between", marginLeft: "10px", padding: "20px", borderRadius: "30px", marginBottom: "10px", alignItems: "center" }}>

                        <img style={{ width: "70px", height: "70px" }} src={item.image} />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                            <h3 style={{ fontFamily: "roboto", fontWeight: "700", marginBottom: "0px", textAlign: "center" }}> {item.hText}</h3>
                        </div>
                        <button onClick={event => window.location.href = item.link} style={{ backgroundColor: "white", width: "100px", fontWeight: "650", padding: "5px", borderRadius: "30px", border: "#594099 solid 1px", color: "#594099", cursor: "pointer" }}>VISIT</button>

                    </div>
                )}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    const {
        registrations,
        Y_registrations,
        delivered,
        Y_delivered,
        vaccinated,
        Y_vaccinated



    } = state.adminCare;
    return {
        registrations,
        Y_registrations,
        delivered,
        Y_delivered,
        vaccinated,
        Y_vaccinated

    };
};

const mapDispatchToProps = dispatch => ({

    getCovid19Count: () => dispatch(getCovid19Count())


});


export default connect(mapStateToProps, mapDispatchToProps)(CovidResources);
