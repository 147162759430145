import axios from "axios";
import axiosRetry from "axios-retry";
import Notification from "./Notification";
import { withRouter } from "react-router-dom";
import { API_VERSION } from "env.json";
import { connect } from "react-redux";
import { logout } from "actions/login";
import { store } from "App";
import { setNotificationVisible } from "actions/layout";


let _TIMER = null;
const _REQUESTS = {};

class Api {
  constructor() {
    // this.orgHeader(window.location.hostname);
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });
  }

  _url = "/";
  _base_url = "https://test.aulas.in";
  // _base_url = "https://2f37-103-5-132-14.ngrok-free.app";
  _s3_url = "https://s3.ap-south-1.amazonaws.com/test.files.classroom.digital-brigosha-0949/";
  dlS3Url = "https://s3.ap-south-1.amazonaws.com/test-digital-library-brigosha-0949/";
  proDlS3Url = "https://s3.ap-south-1.amazonaws.com/prod-digital-library-0949/"
  hlsUrl = "https://hls-stream.aulas.in/"

  _method = "get";
  _params = {};
  _headers = {
    Authorization: "",
    version: API_VERSION,
    orgurl: "test.aulas.in",
    // 'ngrok-skip-browser-warning': "hey"
  };

  get(url, params = {}) {
    this._url = url;
    this._method = "get";
    this._middleUrl = "/api";
    this._params = params;
    return this;
  }

  post(url, params = {}) {
    this._url = url;
    this._method = "post";
    this._middleUrl = "/api";
    this._params = params;
    return this;
  }

  delete(url, params = {}) {
    this._url = url;
    this._method = "delete";
    this._middleUrl = "/api";
    this._params = params;
    return this;
  }
  patch(url, params = {}) {
    this._url = url;
    this._method = "patch";
    this._middleUrl = "/api";
    this._params = params;
    return this;
  }

  params(params) {
    this._params = params;
    return this;
  }

  header(value) {
    this._headers["Authorization"] = `Bearer ${value}`;
  }

  orgHeader(value) {
    this._headers["orgurl"] = `${value}`;
    this._base_url = `https://${this._headers["orgurl"]}`;
  }

  send(callback, timed=false) {
    _REQUESTS[this._url] = {
      _url: this._url,
      _base_url: this._base_url,
      _method: this._method,
      _params: this._params,
      _middleUrl: this._middleUrl,
      _headers: this._headers,
      callback,
    };

    const _self = this;
    if (timed){
      _self.processApiRequest();
    }else{
      clearTimeout(_TIMER);
      _TIMER = setTimeout(() => {
        _self.processApiRequest();
      }, 1000);
    }
  }

  processApiRequest() {
    const _keys = Object.keys(_REQUESTS);
    if (!_keys.length) {
      return;
    }

    const _self = _REQUESTS[_keys[0]];
    delete _REQUESTS[_keys[0]];
    _self.processApiRequest = this.processApiRequest;

    const request = {
      method: _self._method,
      headers: _self._headers,
      timeout: _self._timeout,
      url: `${_self._base_url}${_self._middleUrl}${_self._url}`,
    };

    const { authenticated, user } = store.getState()["session"];
    if (authenticated && _self._headers.Authorization == '') {
      _self._headers.Authorization = `Bearer ${user.accessToken}`
    }

    if (_self._method === "post" || _self._method === "patch") {
      request.data = _self._params;
    } else if (_self._method === "get" || _self._method === "delete") {
      request.params = _self._params;
    }
    console.log("APi req", request);
    axios(request)
      .then((response, error) => {
        console.log("APi res", response);
        if (typeof response !== "undefined" && response.data.show) {
          if (response.data.show.popup) {
            store.dispatch(setNotificationVisible(true, response.data.show.type,
              response.data.show.message.title,
              response.data.show.message.description));
          } else {
            Notification[response.data.show.type](
              response.data.show.type,
              response.data.show.message
            );
          }

        }
        _self.processApiRequest();
        _self.callback(response.data, error);
      })
      .catch((error, response) => {
        if (
          typeof error.response !== "undefined" &&
          error.response.status == 401
        ) {
          // TODO: Send to login page
        }
        if (typeof error.response !== "undefined" && error.response.data.show) {
          if (typeof error.response !== "undefined" && error.response.data.show.message) {
            if (error?.response?.data?.show?.popup) {
              store.dispatch(setNotificationVisible(true, error?.response?.data?.show?.type,
                error?.response.data.show.message.title,
                error?.response.data.show.message.description));
            } else {
              Notification.error("Error", error.response.data.show.message);
            }
          }
          if (error.response.data.show.logout && _self._url !== '/login/generateOtp') {
            logout(null, {
              type: error.response.data.show.message,
              endPoint: _self._url,
              method: _self._method
            })(store.dispatch, store.getState);
          }
        }
        _self.processApiRequest();
        _self.callback(response, error.message);
      });
  }

  uploadMultiple(params, callback) {
    console.log({ params })
    const headers = this._headers;
    headers["Content-Type"] = "multipart/form-data";
    // eslint-disable-next-line no-undef
    const form = new FormData();

    if (params.files) {
      params.files.forEach((file) => {
        form.append("files", file);
      });
    }

    if (params.images) {
      params.images.forEach((image) => {
        form.append('images', image)
      })
    }

    for (const param in params) {
      console.log(param, 'param')
      const element = params[param];
      if (param !== "files" && param !== "images" && !Array.isArray(element)) {
        form.append(param, params[param]);
      } else if (param !== "files" && param !== "images" && Array.isArray(element)) {
        // let index1 = 0;
        // for (const item of element) {
        //   if (item != null) {
        //     form.append(`${param}[${index1}]`, item);
        //   }
        //   index1++
        // }
        let index = 0;
        for (const item of element) {
          if (Object.keys(item)?.length > 0) {
            const itemKeys = Object.keys(item);
            console.log({ item })
            itemKeys.forEach((temp1, tIndex) => {
              if (item[temp1] != null) {
                const X = item[temp1];
                console.log(X, 'this is some x')
                if (Array.isArray(X)) {
                  let index2 = 0;
                  for (const ele of X) {
                    if (ele && (Object.keys(ele)?.length > 0)) {
                      const eleKeys = Object.keys(ele);
                      console.log(eleKeys, 'this is x')
                      eleKeys.forEach((temp2, tIndex) => {
                        if (ele[temp2] != null) {
                          form.append(`${param}[${index}][${temp1}][${index2}][${temp2}]`, ele[temp2]);
                        }
                      })
                    }
                    index2++
                  }
                } else {
                  form.append(`${param}[${index}][${temp1}]`, item[temp1]);
                }
              }
            })
          } else {
            form.append(`${param}[${index}]`, item);
          }
          index++;
        }
      }
    }

    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };

    axios
      .post(this._base_url + "/api" + this._url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response.data.show) {
          if (response.data.show.popup) {
            store.dispatch(setNotificationVisible(true, response.data.show.type,
              response.data.show.message.title,
              response.data.show.message.description));
          } else {
            Notification[response.data.show.type](
              response.data.show.type,
              response.data.show.message
            );
          }
        }
        if (
          typeof response !== "undefined" &&
          response.data.show &&
          response.data.show.type === "error"
        ) {
          callback(-1);
        }
        callback(100, response);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }

  upload(params, callback) {
    const headers = this._headers;
    headers["Content-Type"] = "multipart/form-data";
    // eslint-disable-next-line no-undef
    const form = new FormData();

    if (params.files) {
      params.files.forEach((file) => {
        form.append("files", file);
      });
    }
    for (const param in params) {
      if (param !== "files") {
        form.append(param, params[param]);
      }
    }

    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };

    axios
      .post(this._base_url + "/api" + this._url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response?.data?.show) {
          if (response.data.show.popup) {
            store.dispatch(setNotificationVisible(true, response.data.show.type,
              response.data.show.message.title,
              response.data.show.message.description));
          } else {
            Notification[response.data.show.type](
              response.data.show.type,
              response.data.show.message
            );
          }
        }
        if (
          typeof response !== "undefined" &&
          response?.data?.show &&
          response?.data?.show?.type === "error"
        ) {
          callback(-1);
        }
        callback(100, response);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }

  uploadAndPatch(params, callback) {
    const headers = this._headers;

    const form = new FormData();
    // for (var param in params) {
    //   form.append(param, params[param]);
    // }
    for (var param in params) {
      if (Array.isArray(params[param])) {
        form.append(param, JSON.stringify(params[param]));
      } else {
        form.append(param, params[param]);
      }
    }
    headers["Content-Type"] = `multipart/form-data;boundary=${form._boundary}`;

    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };

    axios
      .patch(this._base_url + "/api" + this._url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response.data.show) {
          if (response.data.show.popup) {
            store.dispatch(setNotificationVisible(true, response.data.show.type,
              response.data.show.message.title,
              response.data.show.message.description));
          } else {
            Notification[response.data.show.type](
              response.data.show.type,
              response.data.show.message
            );
          }
          if (
            typeof response !== "undefined" &&
            response.data.show &&
            response.data.show.type === "error"
          ) {
            callback(-1);
          }
        }
        callback(100, response);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }

  uploadAndPost(_url = null, params, callback) {
    const headers = this._headers;
    const form = new FormData();
    // for (var param in params) {
    //   form.append(param, params[param])
    // }
    for (var param in params) {
      if (Array.isArray(params[param])) {
        form.append(param, JSON.stringify(params[param]));
      } else {
        form.append(param, params[param]);
      }
    }
    headers["Content-Type"] = `multipart/form-data;boundary=${form._boundary}`;
    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };

    axios
      .post(this._base_url + "/api" + _url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response.data.show) {
          if (response.data.show.popup) {
            store.dispatch(setNotificationVisible(true, response.data.show.type,
              response.data.show.message.title,
              response.data.show.message.description));
          } else {
            Notification[response.data.show.type](
              response.data.show.type,
              response.data.show.message
            );
          }
          if (
            typeof response !== "undefined" &&
            response.data.show &&
            response.data.show.type === "error"
          ) {
            callback(-1);
          }
        }
        callback(100, response);
      })
      .catch((error) => {
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }
}

const ApiClass = new Api();

export default withRouter(ApiClass);