import { ArrowLeftOutlined, DeleteFilled, DownloadOutlined, MoreOutlined } from '@ant-design/icons'
import { Col, Menu, Modal, Popover, Progress, Row, Select, Tag, Tooltip, Typography, DatePicker } from 'antd'
import { Api } from 'services'
import { Option } from 'antd/lib/mentions'
import React, { useEffect, useState } from 'react'
import DownloadReport from './Modals/downloadReport'
import TeachersWorkhrsGraph from './teachersWorkHrsGraph'
import "../attendanceNew/style.css"
import RequestAttendanceModel from 'pages/attendance_user/localcomponents/requestAttendanceModel'
import ConfirmDeleteModal from './Modals/confirmDeleteModal'
import LeaveDetailsModal from './leaveDetailsModal'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { getAttendanceProfileTabs, markAttendance, setLoader, setMarkAttendance, selectedAttendance, usersDownloadReport, storeSelectedRange, storeStartDate, storeEndDate, storeSelectedDropDownMonth, getLeavesTabData, storeAttendanceDetailsMonth, fetchProfileTabMonthSelect, fetchMonthForAttDetails, storeSelectedMonth, attendanceDetailsDownloadReport, setLeaveRequest } from 'actions/attendanceNew'
import TableComponent from './localComponents/tableComponent'
import AttendanceGraph from './localComponents/attendanceGraph'
import Leavesmodel from './Modals/leavesModelDataOperator'
import moment from 'moment';
import PageDataLoader from 'components/pageDataLoader'
import { SubIcon } from 'components/subIcon'
import { setSpecificUserId } from 'actions/userManagement'

const AbsentImage = require("../../Assets/user/ic-absent.svg").default;
const OverAllImage = require("../../Assets/user/ic-overall.svg").default;
const PresentImage = require("../../Assets/user/ic-present.svg").default;
const WorkingImage = require("../../Assets/user/ic-working-hrs.svg").default;
const halfDay = require('../../Assets/Attendance/Group 13469.svg').default;
const AvgWorkingImage = require("../../Assets/user/ic-avg-working-hrs.svg").default;
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const warning = require("../../Assets/ic-warning.svg").default
const present = require("../../Assets/ic-present.svg").default
/* const present=require("../../Assets/ic-present.svg").default
const present=require("../../Assets/ic-present.svg").default */
const more = require("../../Assets/ic-more.svg").default
const checkCircle = require("../../Assets/ic-check-circle.svg").default
const icgeofence = require("../../Assets/ic-geo-fence.svg").default
const icclockout = require("../../Assets/ic-clock-out.svg").default
const Group_11708 = require("../../Assets/Group 11708.svg").default
const Iconmaterialtimer = require("../../Assets/Icon material-timer.svg").default
const icclockin = require("../../Assets/ic-clock-in.svg").default
const NoData = require("../../Assets/ill-no-data.svg").default

const RequestHistoryCard = ({ details, selectedAttendance, modelData, storeselectedAttendance, isModalVisible, setIsModalVisible, setIsLeaveModalVisible, isLeaveModalVisible }) => {

  const menu = (
    <Menu style={{ width: "100%" }}>

      <Menu.Item onClick={(e) => {
        setIsModalVisible(true)
      }}>
        <DeleteFilled />
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{
      width: "230px",
      height: "80px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #E6E6E6",
      borderRadius: " 4px",
      marginTop: 20,
      marginLeft: "20px",
      cursor: "pointer",
    }} 
        onClick={()=>{
        setIsLeaveModalVisible(true)
        selectedAttendance(details)
        }}
    >
      <div 
        style={{ display: "flex", justifyContent: "space-between"}}>
        <div style={{ color: "#AEAEAE", font: " normal normal normal 11px/18px Roboto", marginLeft: "20px", marginTop: "20px" }}>{details.duration}</div>

        <div className='flex-row'>
          <StatusTag type={details?.status} />
        </div>
      </div>

      <div style={{ font: "normal normal bold 14px/18px Roboto", color: "#191919", marginLeft: "20px" }}>
        {details?.startDate}
      </div>

      <div style={{ font: "normal normal normal 13px/18px Roboto", color: "#636363", marginLeft: "20px" }}>
        {details?.endDate}
      </div>

      <Typography.Text ellipsis={{tooltip: true}} style={{ font: "normal normal normal 13px/18px Roboto", color: "#636363", marginLeft: "20px", width: 180 }}>
        {details?.reason}
      </Typography.Text>

      <ConfirmDeleteModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      
    </div>
  )
}


const NoDataCard = () => {
  return (
    <div className='r-c-c-c'>
      <img src={NoData} />
      <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "18px" }}>No Data</div>
      <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "13px" }}>There are no data to show yet. Please check back later.</div>
    </div>
  )
}

const StatusTag = ({ type }) => {

  return (
    <div style={{
      marginRight: "10px",
      height: "14px",
      width: "45px",
      marginTop: "10px",
      border: `${type === "Pending" ? "1px solid #FFA931" : type === "Approved" ? "1px solid #28DF99" : "1px solid #FF414D"}`,
      borderRadius: " 3px",
      background: `${type === "Pending" ? "#FFF4D5" : type === "Approved" ? "#E9FCF5" : "#FFE4D9"}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    >
      <div style={{ font: "normal normal bold 8px/10px Roboto", color: `${type === "Pending" ? "#FFA931" : type === "Approved" ? "#28DF99" : " #FF414D"}` }}>{type === "Pending" ? "PENDING" : type === "Approved" ? "APPROVED" : "REJECTED"}</div>
    </div>
  )
}

const SubjectCard = ({ icon, subjectName, teacherName, averageAttendance }) => {
  return (
    <div style={{
      marginTop: "20px",
      marginLeft: "10px",
      borderRadius: "6px",
      borderColor: "#E6E6E6",
      alignItems: "center",
      border: "#E6E6E6 solid 1px",
      minWidth: 290,
      height: 140,
      backgroundColor: "#FFFFFF",
    }}>
      <div style={{ display: "flex", marginTop: "30px", marginLeft: "25px" }}>
        <div>
          <SubIcon size={40} icon={icon} name={subjectName} />
        </div>
        <div className='m-l-5'>
          <div style={{ fontWeight: "bold", color: "#191919", fontSize: "15px" }}>{subjectName}</div>
          <div style={{ color: "#636363", fontSize: "12px" }}>{teacherName}</div>
        </div>
      </div>
      <div>
        <Progress
          percent={averageAttendance}
          style={{ width: "90%", marginLeft: "20px" }}
          showInfo={false}
          strokeColor="#594099"
          trailColor="#F6F4FE"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}><div style={{ color: "#636363", fontSize: "12px" }}>Average attendance</div>
        <div style={{ color: "#636363", fontSize: "12px" }}>{averageAttendance}%</div></div>
    </div>
  )
}


const AttendanceView = ({ AttendanceTab, getAttendanceProfileTabs, user, setMarkAttendance, markAttendance, loaders, setLoader, storeselectedAttendance, selectedAttendance, usersDownloadReport, storeSelectedRange, storeStartDate, storeEndDate, startDateContainer, selectedRangeContainer, markAttendanceObj, storeSelectedDropDownMonth, leavesTabDataContainer, getLeavesTabData, storeAttendanceDetailsMonth, fetchProfileTabMonthSelect, fetchMonthForAttDetails, storeSelectedMonth, attendanceDetailsDownloadReport, setSpecificUserId, setLeaveRequest, selectedMonthContainer
}) => {

  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [isModelVisible, setisModelVisible] = useState(false);
  const [isModelVisibleReq, setisModelVisibleReq] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageLoader, togglePageLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);


  const defaultMonth = moment().format('YYYY-MM')

  const history = useHistory();

  useEffect(() => {
    storeSelectedMonth(defaultMonth)
    storeAttendanceDetailsMonth(defaultMonth)
    togglePageLoader(true)
    getAttendanceProfileTabs(() => {
      togglePageLoader(false)
    });

  }, [])

  const disabledDate = current => {
    // Disable years before 2022 1st January
    if (current && current < moment("2022-01-01", "YYYY-MM-DD")) {
      return true;
    }
    // Disable months after current month in current year
    if (current && current > moment().endOf('month')) {
      return true;
    }
    return false;
  };

  const graphdata = AttendanceTab?.attendanceTab?.attendanceByTimesheet?.attendanceDetails ? AttendanceTab?.attendanceTab?.attendanceByTimesheet?.attendanceDetails?.map((item) => ({
    'unit': item?.unit, 'avgDurationInMinutes': item?.durationInMinutes,
    'clockIn': item?.clockIn,
    'clockOut': item?.clockOut,
    'workingHours': item?.workingHours
  })) : {}

  const OverviewData = Object.entries(AttendanceTab?.attendanceTab?.overview ? AttendanceTab?.attendanceTab?.overview : {});

  const date = (moment(storeselectedAttendance?.startDate).format('DD-MM-YYYY'));

  const modelData = {
    "Date": date,
    'Clock in': storeselectedAttendance?.clockIn,
    'Clock out': storeselectedAttendance?.clockOut,
    'Total hours': storeselectedAttendance?.totalHours,
    'Reason': storeselectedAttendance?.reason
  };

  const attendanceDetailsHandleChange = (value) => {
    storeAttendanceDetailsMonth(value);
    togglePageLoader(true)
    getAttendanceProfileTabs(() => {
      togglePageLoader(false)
    })
  }

  const modelDataArray = Object.entries(modelData);

  return (
    <div className='m-l-20' style={{ width: "100%", overflowX: "hidden", height: "90vh", marginTop: 40 }}>
      {/* <div> */}
      <Col>
        <Row>
          <div className=' shadow-box hover-shadow ' style={{ width: "100%", height: "fit-content", paddingBottom: "10px", backgroundColor: "#FFFFFF", border: "1px solid #E6E6E6", minWidth: 500 }}>
            <div className='m-t-20 m-l-20' style={{ width: "25%" }}>
              <div style={{ fontWeight: "bold", fontSize: "14px", fontFamily: "Roboto", color: "#191919" }}>Overview</div>
              {AttendanceTab?.attendanceTab?.since=="Invalid date" ?
              <></>
              :
              <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#636363" }}>From {" "}{AttendanceTab?.attendanceTab?.since} to {AttendanceTab?.attendanceTab?.tillDate}</div>
            }
            </div>
            <div className='flex-row' style={{ flexWrap: "wrap" }}>
              {
                OverviewData?.map((item) => {
                  return (
                    <>
                      <div className='m-l-10 m-t-10 shadow-box hover-shadow customCardShadow' style={{ width: "250px", height: "75px", minWidth: 180 }}>
                        <div className='flex-row m-l-20' >
                          <div className='m-t-20'>
                            <img
                              style={{ height: "40px" }}
                              src={
                                item[0] === "PresentCount" ? PresentImage :
                                item[0] === "AbsentCount" ? AbsentImage :
                                item[0] === "overallPercentage" ? OverAllImage :
                                item[0] === "totalWorkingHoursTillDate" ? WorkingImage :
                                item[0] === "averageWorkingHoursTillDate" ? AvgWorkingImage :
                                item[0] === 'HalfDayCount' ? halfDay : null
                              }>
                            </img>
                          </div>
                          <div className='font m-l-10 m-t-10' style={{}} >
                            <div className='text-overflow-ellipsis' style={{ fontSize: 20, color: "#191919", width: "100vh", marginTop: -5 }}>{item[1]}</div>
                            <div className='text-overflow-ellipses' style={{ color: "#636363" }}>
                              {
                              item[0] === "PresentCount" ? "Present day(s)" :
                              item[0] === "AbsentCount" ? "Absent day(s)" :
                              item[0] === "overallPercentage" ? "Overall percentage" :
                              item[0] === "totalWorkingHoursTillDate" ? "Total working hours" :
                              item[0] === "averageWorkingHoursTillDate" ? "Average working hours" :
                              item[0] === 'HalfDayCount' ? "Half day(s)" : null
                              }</div>
                            {item[0] === "averageWorkingHoursTillDate" &&
                              AttendanceTab?.attendanceTab?.warning ? 
                              <div style={{ fontSize: 6, width: '100px', marginTop: -3 }}>
                                <Tooltip trigger={'hover'} placement='bottom' title={`Your attendance is ${AttendanceTab?.attendanceTab?.shortFallPercentage}% lower than minimum requirements`} overlayStyle={{ borderRadius: "15px" }} overlayInnerStyle={{ borderRadius: "15px", padding: "10px 20px" }} >
                                  <div className='' style={{ color: "#FF414D", fontSize: "10px" }}><img className='p-2' style={{ height: "15px", width: "15px", marginTop: -3 }} src={warning} />Low working hours</div>
                                </Tooltip>
                             </div>
                           : null }
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div>
        </Row>
        <Row /* className='m-t-40' */ style={{ height: "50px" }}>
          <div className='flex-row m-t-20 ' style={{ justifyContent: "space-between", width: "100%", height: "80px" }}>
            <div className='flex-row'>
              <div style={{ color: "#191919", fontSize: "16px", fontWeight: "bold" }}>Attendance by time sheet</div>
              {user?.privilage?.includes('SuperAdmin') ?
                <div className='m-l-5 m-t-5 cursor-pointer' style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }}
                  onClick={() => { setisModelVisibleReq(true); setLeaveRequest("noOfdays", '') }}>{"  "}|{"  "} MARK ATTENDANCE</div> :
                <div className='m-l-5 m-t-2 cursor-pointer' style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold" }}
                  onClick={() => { setisModelVisibleReq(true) }}>{"  "}|{"  "}<img src={checkCircle}></img> REQUEST ATTENDANCE</div>
              }
            </div>
            <div>
              <div className='flex-row cursor-pointer' style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold", marginTop: "5px" }}>
                <div>
                  <DownloadOutlined
                    onClick={() => { setisModelVisible(true) }}
                    style={{ color: "#1089FF" }}
                  />
                </div>
                <div onClick={() => { setisModelVisible(true) }}> REPORT</div>

                <DatePicker
                  style={{ width: "110px", marginTop: "-8px", marginLeft: 10 }}
                  picker={'month'}
                  disabledDate={disabledDate}
                  onChange={(date) => {
                    storeSelectedMonth(moment(date).format('YYYY-MM'));
                    togglePageLoader(true);
                    getAttendanceProfileTabs(() => {
                      togglePageLoader(false)
                    });
                  }}
                  format={"MMM-YY"}
                />

                {/* <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  defaultValue={defaultMonth}
                  className='m-l-20 '
                  onChange={(e, value) => {
                    storeSelectedMonth(value)
                    togglePageLoader(true)
                    getAttendanceProfileTabs(() => {
                      togglePageLoader(false)
                    });
                  }}
                  style={{ width: "110px", marginTop: "-8px" }}>
                  {
                    months?.map((items) => (
                      <Option value={items}>{items}</Option>
                    ))
                  }
                </Select> */}
              </div>
            </div>
          </div>
          {isModelVisibleReq &&
            <RequestAttendanceModel user={user} setIsModalVisible={setisModelVisibleReq} isModalVisible={isModelVisibleReq} setMarkAttendance={setMarkAttendance} markAttendance={markAttendance} setLoader={setLoader} loaders={loaders} />
          }
        </Row>
        {
          Object.keys(graphdata)?.length != 0 ?
            <Row style={{ marginTop: "20px" }}>

              <div className=' shadow-box hover-shadow customCardShadow' style={{ width: "100%", height: "330px", backgroundColor: "#FFFFFF", border: "1px solid #E6E6E6" }}>
                <div className='flex-row m-t-20' style={{ justifyContent: "space-around" }}>
                  <div style={{ width: "100px", display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>PRESENT DAYS</div>
                    <div style={{ fontWeight: "700", fontSize: "10px", fontFamily: "Roboto", color: "#636363" }}>{AttendanceTab?.attendanceTab?.attendanceByTimesheet?.presentDays}</div>
                  </div>
                  <div style={{ width: "100px", display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>ABSENT DAYS</div>
                    <div style={{ fontWeight: "700", fontSize: "10px", fontFamily: "Roboto", color: "#636363" }}>{AttendanceTab?.attendanceTab?.attendanceByTimesheet?.absentDays}</div>
                  </div>
                  <div style={{ width: "100px", display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>HALF DAYS</div>
                    <div style={{ fontWeight: "700", fontSize: "10px", fontFamily: "Roboto", color: "#636363" }}>{AttendanceTab?.attendanceTab?.attendanceByTimesheet?.halfDays}</div>
                  </div>
                  <div style={{ width: "150px", display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>TOTAL WORKING HOURS</div>
                    <div style={{ fontWeight: "700", fontSize: "10px", fontFamily: "Roboto", color: "#636363" }}>{AttendanceTab?.attendanceTab?.attendanceByTimesheet?.totalWorkingHoursTillDate}</div>
                  </div>
                  <div style={{ width: "150px", display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>AVG. WORKING HOURS</div>
                    <div style={{ fontWeight: "700", fontSize: "10px", fontFamily: "Roboto", color: "#636363" }}>{AttendanceTab?.attendanceTab?.attendanceByTimesheet?.averageWorkingHoursTillDate}</div>
                  </div>
                </div>
                <div className='m-t-20' style={{ height: "240px", paddingLeft: "15px", paddingRight: "15px" }}>
                  {/*  <AttendanceGraph leavesTabDataContainer={AttendanceTab?.attendanceTab?.attendanceByTimesheet?.attendanceDetails} index={0}/> */}

                  {Object.keys(graphdata)?.length != 0 && graphdata?.avgDurationInMinutes != 0 ?
                    <TeachersWorkhrsGraph
                      data={graphdata}
                      color={"#28DF99"}
                    /> : null
                    // <NoDataCard/>
                  }
                </div>
              </div>
            </Row> : <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><NoDataCard /></div>
        }
        {AttendanceTab?.attendanceTab?.attendanceBySubject ?
          <Row className='m-t-20'>
            <div style={{ color: "#191919", fontSize: "16px", fontWeight: "bold" }}>Subjects{" "}({AttendanceTab?.attendanceTab?.attendanceBySubject?.length})</div>
          </Row> : null
        }

        {AttendanceTab?.attendanceTab?.attendanceBySubject ?
          <div className='scroll-bar-universal' style={{ display: 'flex', width: '100%' }}>
            {AttendanceTab?.attendanceTab?.attendanceBySubject?.length != 0 ?
              AttendanceTab?.attendanceTab?.attendanceBySubject?.map((item) => (
                <SubjectCard
                  subjectName={item.subjectName}
                  icon={item.icon}
                  averageAttendance={item.averageAttendance}
                  teacherName={item.teacherName} />
              )) : <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><NoDataCard /></div>}
          </div> : null
        }
        {AttendanceTab?.attendanceTab?.attendanceDetailsByClass ?
          <Row style={{ height: "50px" }}>
            <div className='flex-row m-t-20 ' style={{ justifyContent: "space-between", width: "100%", height: "80px" }}>
              <div className='flex-row'>
                {user?.role.includes("Teacher") ?
                  <div style={{ color: "#191919", fontSize: "16px", fontWeight: "bold" }}>Attendance details</div> : <div style={{ color: "#191919", fontSize: "16px", fontWeight: "bold" }}>Attendance by schedule</div>}
                {/*   <div className='m-l-5 m-t-2 cursor-pointer' style={{color:"#1089FF",fontSize:"12px",fontWeight:"bold" }} onClick={()=>{setisModelVisibleReq(true)}}>{"  "}|{"  "} REQUEST ATTENDANCE</div> */}
              </div>
              <div>
                <div className='flex-row cursor-pointer' style={{ color: "#1089FF", fontSize: "12px", fontWeight: "bold", marginTop: "2px" }}  ><div><DownloadOutlined onClick={() => { setisModelVisible(true) }} style={{ color: "#1089FF" }} /></div><div onClick={() => { attendanceDetailsDownloadReport() }}> REPORT</div>
                  {/* <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={defaultMonth}
                    onChange={attendanceDetailsHandleChange}
                    className='m-l-20 '
                    style={{ width: "110px", marginTop: "-5px" }}>
                    {
                      months?.map((items) => (
                        <Option value={items}>{items}</Option>
                      ))
                    }
                  </Select> */}

                <DatePicker
                  style={{ width: "110px", marginTop: "-8px", marginLeft: 10 }}
                  picker={'month'}
                  disabledDate={disabledDate}
                  onChange={attendanceDetailsHandleChange}
                  format={"MMM-YY"}
                />
                </div>
              </div>
            </div>
            {isModelVisibleReq &&
              <RequestAttendanceModel user={user} setIsModalVisible={setisModelVisibleReq} isModalVisible={isModelVisibleReq} setMarkAttendance={setMarkAttendance} markAttendance={markAttendance} setLoader={setLoader} loaders={loaders} markAttendanceObj={markAttendanceObj} />
            }
          </Row> : null
        }

        {AttendanceTab?.attendanceTab?.attendanceDetailsByClass
          ?
          <div style={{ width: "100%" }}>
            <TableComponent
              leavesTabDataContainer={AttendanceTab} />
          </div>
          :
          null
        }

        <Row>
          <div className='flex-row m-t-50' style={{ justifyContent: "space-between", width: "100%", height: "50px" }}>
              <div style={{ color: "#191919", fontSize: "16px", fontWeight: "bold" }}>Attendance request history</div>
          </div>
        </Row>

        <Row>
          <div className='flex-row shadow-box hover-shadow customCardShadow '
            style={{ width: "100%", minHeight: "300px", height: "fit-content", backgroundColor: "#FFFFFF", border: "1px solid #E6E6E6", flexWrap: "wrap" }}>
            {AttendanceTab?.attendanceTab?.attendanceHistory?.length != 0 ?
              AttendanceTab?.attendanceTab?.attendanceHistory?.map((item) => (
                <RequestHistoryCard
                  setIsLeaveModalVisible={setIsLeaveModalVisible}
                  details={item}
                  selectedAttendance={selectedAttendance}
                  modelDataArray={modelDataArray}
                  storeselectedAttendance={storeselectedAttendance}
                  setIsModalVisible={setIsModalVisible}
                  isModalVisible={isModalVisible}
                />
              )) : <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><NoDataCard /></div>
            }
          </div>
        </Row>

        {isModelVisibleReq &&
          <RequestAttendanceModel user={user} isModelVisibleReq={isModelVisibleReq} setisModelVisibleReq={setisModelVisibleReq} setMarkAttendance={setMarkAttendance} markAttendance={markAttendance} setLoader={setLoader} loaders={loaders} markAttendanceObj={markAttendanceObj} fromWhere='Profile'/>
        }

        <DownloadReport
          isModelVisible={isModelVisible}
          setisModelVisible={setisModelVisible}
          usersDownloadReport={usersDownloadReport}
          storeSelectedRange={storeSelectedRange}
          storeStartDate={storeStartDate}
          storeEndDate={storeEndDate}
          startDateContainer={startDateContainer}
          selectedRangeContainer={selectedRangeContainer}
          setSpecificUserId={setSpecificUserId}
          user={user}
        />

      {isLeaveModalVisible && <Leavesmodel
        attendanceOrLeaves={'attendance'}
        storeselectedAttendance={storeselectedAttendance}
        modelDataArray={modelDataArray}
        isLeaveModalVisible={isLeaveModalVisible}
        setIsLeaveModalVisible={() => setIsLeaveModalVisible(false)}
      />}

      </Col>
      {/* <PageDataLoader visible={pageLoader}/> */}
      {/*  </div> */}
    </div>
    // </div>
  )
}

const mapStateToProps = (state) => {
  const { AttendanceTab, storeselectedAttendance, loaders, startDateContainer, selectedRangeContainer, markAttendanceObj, leavesTabDataContainer, storeProfileTabMonthSelect, selectedMonthContainer } = state.attendanceNew;
  const { user } = state.session;
  return { AttendanceTab, user, storeselectedAttendance, loaders, startDateContainer, selectedRangeContainer, markAttendanceObj, leavesTabDataContainer, storeProfileTabMonthSelect, selectedMonthContainer };
};

const mapDispatchToProps = (dispatch) => ({
  getAttendanceProfileTabs: (callBack) => (dispatch(getAttendanceProfileTabs(callBack))),
  selectedAttendance: (val) => (dispatch(selectedAttendance(val))),
  setMarkAttendance: (key, val) => dispatch(setMarkAttendance(key, val)),
  markAttendance: (callBack) => dispatch(markAttendance(callBack)),
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  usersDownloadReport: (callback, successCallBack) => (dispatch(usersDownloadReport(callback, successCallBack))),
  storeSelectedRange: (val) => dispatch(storeSelectedRange(val)),
  storeStartDate: (val) => dispatch(storeStartDate(val)),
  storeEndDate: (val) => dispatch(storeEndDate(val)),
  storeSelectedDropDownMonth: (key, val) => dispatch(storeSelectedDropDownMonth(key, val)),
  getLeavesTabData: (callBack) => dispatch(getLeavesTabData(callBack)),
  fetchProfileTabMonthSelect: (val) => dispatch(fetchProfileTabMonthSelect(val)),
  fetchMonthForAttDetails: (val) => dispatch(fetchMonthForAttDetails(val)),
  storeAttendanceDetailsMonth: (val) => dispatch(storeAttendanceDetailsMonth(val)),
  storeSelectedMonth: (val) => dispatch(storeSelectedMonth(val)),
  attendanceDetailsDownloadReport: () => dispatch(attendanceDetailsDownloadReport()),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  setLeaveRequest: (key,val) => dispatch(setLeaveRequest(key,val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceView);

/* 
/* 
< style={{height:"200px",marginTop:"45px"}}>
{/*   <TeachersWorkhrsGraph
  color={"#28DF99"}/> */