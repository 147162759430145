import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import { Dropdown, Menu, Rate, Progress, Modal, Tooltip, Col, Collapse, Divider, Row, AutoComplete } from 'antd'
import saveOutlined from "../../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../../Assets/aulasMarket/saveFilledIcon.svg"
import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import { Api } from 'services'
import noImage from "../../../Assets/aulasMarket/no-image.svg"
import { getCourseDashBoardData, postAddSaveItem, removeSaveItem, setMyCourseHome } from 'actions/studentMarketPlace'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const defaultCourseImage = require('Assets/default_course-img.svg').default;


function RelatedCoursesCompo({ course, postAddSaveItem, removeSaveItem, itemType }) {
    console.log("relssssssccccc", course, itemType)
    const durationInSeconds = course?.duration ? calculateDurationInSeconds(course.duration) : 0;
    const durationInHours = calculateHoursFromSeconds(durationInSeconds);
    function calculateDurationInSeconds(duration) {
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    }

    function calculateHoursFromSeconds(seconds) {
        return Math.floor(seconds / 3600);
    }
    const [pageLoader, togglePageLoader] = useState(false)
    const [saved, setSaved] = useState({});
    const [isSavedClicked, setIsSavedClicked] = useState(false)
    const history = useHistory();
    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [course?.id]: course?.inSaved == 1 ? true : course?.inSaved == 0 ? false : null,
        }));
    }, [])

    const handleSaveForLater = (id, itemType) => {
        setIsSavedClicked(!isSavedClicked)
        if (saved[id]) {
            removeSaveItem(id, itemType, (res) => {
                if (res) {
                    setSaved((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: false,
                    }));
                }
            });
        } else {
            postAddSaveItem(id, itemType, (res) => {
                if (res) {
                    setSaved((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        }
    };
    const handleCourseClick = (id, itemType) => {
        history.push({
            pathname: '/resource-details',
            state: {
                fromMyCourcesPage: {
                    id: id,
                    resourceType: itemType
                }

            }
        })
    };
    const handleVideoCourseClick = (id, itemType) => {
        history.push({
            pathname: "/video-resources",
            state: { id: id }
        });
    };
    const handleStudentCourseSide = (id, itemType) => {
        history.push({
            pathname: '/resource-details',
            state: {
                id: id,
                resourceType: itemType
            }
        })
    }
    const handleRelatedCourseClick = (itemType) => {
        if (itemType == "course" || itemType == "videoResource" || itemType == "studyMaterial" || itemType == "testSeries" && course?.owned == "0") {
            handleStudentCourseSide(course?.id, itemType);
        } else if (itemType == "course" || itemType == "videoResource" || itemType == "studyMaterial" || itemType == "testSeries" && !course?.owned == "0") {
            handleCourseClick(course?.id, itemType)
        } else if (itemType == "videoResource" && !course?.owned == "0") {
            handleVideoCourseClick(course?.id, itemType)
        } else if (itemType == "videoResource" && course?.owned == "0") {
            handleCourseClick(course?.id, itemType)
        }
    }


    return (
        <div className='relatedCourses' style={{}} >
            <div className='rel-card' >
                <div className='r-c-fs cursor-pointer' onClick={() => { handleRelatedCourseClick(itemType) }} style={{ padding: "10px 0px", width: "100%" }}>
                    <div className='rel-parent-img'>
                        {course?.resourceType == 'studyMaterial' ?
                            (<>
                                <div style={{ height: "100px", paddingTop: '10px' }}>
                                    <div style={{ color: "#594099", fontSize: 16, marginLeft: 2, marginTop: "9px" }}>
                                        <img src={require("../../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50, marginRight: "20px" }} />
                                        {course?.m_count} {course?.m_count <= 1 ? "Material" : "Materials"}
                                        {course?.q_count > 0 ? `, ${course?.q_count} ${course?.q_count <= 1 ? "quiz" : "quizzes"}` : ""}</div>
                                </div>
                            </>) :
                            <img className='cart-img-card' src={course?.image ? `${Api.dlS3Url}${course?.image}` : defaultCourseImage} />
                        }
                    </div>
                    <div className='' style={{ width: "50%" }}>
                        <div className='rel-name font-bold' style={{ fontSize: "14px" }}>{course?.name ? course?.name : course?.courseName}</div>
                        <div className='' style={{
                            color: "#636363",
                            fontSize: 12,
                            width: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            cursor: "pointer",
                        }}>
                            {console.log("itemType", itemType)}
                            {itemType === "course" || itemType === "studyMaterial" || itemType === "testSeries" ? (
                                // <Tooltip title={course?.brief}>{course?.brief}</Tooltip>
                                <></>
                            ) : (
                                <>
                                    {durationInHours} hours videos | {course?.quizCount} {"quizzes"} | {course?.notesCount} notes
                                </>
                            )}

                        </div>

                        <div className='r-c-c' style={{ width: "100%" }} >
                            {course?.bestseller ?
                                <div className='sellerButton2 m-r-10'>Bestseller</div>
                                : null}
                            <div className='rel-ratings' style={{}}>
                                {course?.ratingValue !== null && course?.ratingCount !== null && course?.ratingValue !== 0 && course?.ratingCount !== 0 ? (
                                    <>
                                        <div style={{ color: "#191919", fontSize: 12, margin: "-2px 8px 0px 0px" }}>
                                            {course?.ratingValue}
                                        </div>
                                        <Rate disabled defaultValue={course?.ratingValue} style={{ color: "#FFA931", fontSize: 14 }} />
                                        <div className='total-ratings' style={{ fontSize: 12, margin: "-12px 0px 0px" }}>
                                            {course?.ratingCount}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='rel-cost' style={{ fontSize: 16 }}>₹{course?.amount}</div>
                            <div className='rel-bookmark' style={{ width: 34, height: 34, }}>
                                {course?.owned == 1 ? <></> :
                                    <div onClick={() => handleSaveForLater(course?.id)} style={{ cursor: "pointer" }} >
                                        <Tooltip title="Save for Later">
                                            {/* <div onClick={(e) => { e.stopPropagation(); handleSaveForLater(course?.id, course?.resourceType) }}><img src={saved[course?.id] ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div> */}
                                            <div onClick={(e) => { e.stopPropagation(); handleSaveForLater(course?.id, course?.resourceType) }}><img src={saved[course?.id] || isSavedClicked ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} /></div>
                                        </Tooltip>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}

const mapStateToProps = (state) => {

};

const mapDispatchToProps = (dispatch) => ({
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedCoursesCompo)
