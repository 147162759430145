import React, { useEffect, useState } from 'react'
import { NodataCard } from '..';
import { Column } from "@ant-design/charts";
import { DatePicker, Dropdown, Menu } from 'antd';
import { BsFilterLeft } from 'react-icons/bs';
import { DownOutlined, CaretLeftFilled, CaretRightFilled, CaretDownFilled } from '@ant-design/icons';
import Loader from './loader';
import moment from 'moment';
import GraphLoading from "Assets/Graph.gif";
const {RangePicker} = DatePicker;


export let WeekMenu = ({ handleFilterSelect, setDropdownVisibility }) => (
    <Menu
      className="radius-5 legend-dropdown-body"
      onClick={() => {
        setDropdownVisibility(false);
      }}
    >
      <Menu.Item key="0" onClick={() => handleFilterSelect("week", "Week")}>
        <div className="text-xs color-black p-l-10">Week</div>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleFilterSelect("year", "Months")}>
        <div className="text-xs color-black p-l-10">Months</div>
      </Menu.Item>
      {/* <Menu.Item key="2" onClick={() => handleFilterSelect("month", "Year")}>
        <div className="text-xs color-black p-l-10">Year</div>
      </Menu.Item> */}
    </Menu>
  );

export const DateNavigator = ({yearFilter, starDate, endDate, selectedYear, onDateBackward, onDateForward}) => {
    return (
        <div style={{fontSize:12, color:"#AEAEAE"}}>
            <CaretLeftFilled style={{color:"#AEAEAE"}} onClick={onDateBackward}/> {yearFilter?selectedYear:`${moment(starDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`} <CaretRightFilled  style={{color:"#AEAEAE"}} onClick={onDateForward}/>
        </div>
    )
}

export const LegendRow = ({
    handleFilterSelect,
    selectedFilter,
    userType,
    isDropdownVisible,
    setDropdownVisibility,
    starDate,
    endDate,
    selectedYear,
    onDateBackward,
    onDateForward
  }) => {
    return (
      <div className='r-jsb'>
        <div className="r-c-c-fs m-b-5">
          <div className="text-xmd bold-600 color-black">Summary</div>
          <DateNavigator yearFilter={selectedFilter==='Months'?true:false} 
                         starDate={starDate} endDate={endDate} 
                         selectedYear={selectedYear}
                         onDateBackward={onDateBackward} onDateForward={onDateForward}/>
        </div>
        <div className="r-c-sb m-b-30">
          <div className="r-ac homepagelegend m-r-20">
            <div className="r-ac m-r-15">
              <div
                className="bar-char-legend-box radius-2"
                style={{ backgroundColor: "#9464FA" }}
              ></div>
              <div className="text-xs m-l-5">
                {userType == "dataOperator"
                  ? "Test created & draft"
                  : "Chapter test"}
              </div>
            </div>
            {
              userType!=='dataOperator'?
              <div className="r-ac m-r-15">
              <div
                className="bar-char-legend-box radius-2"
                style={{ backgroundColor: '#99DED9' }}
              ></div>
              <div className="text-xs m-l-5">
                   Part test
              </div>
            </div>
            :null
            }

            {userType != "dataOperator" ? (
              <div className="r-ac">
                <div
                  className="bar-char-legend-box radius-2"
                  style={{ backgroundColor: '#589DFF' }}
                ></div>
                <div className="text-xs m-l-5">Full test</div>
              </div>
            ) : null}
            {
              userType==='dataOperator'?
              <div className="r-ac m-r-15">
                <div
                  className="bar-char-legend-box radius-2"
                  style={{ backgroundColor: "#589EFF" }}
                ></div>
                <div className="text-xs m-l-5">
                    Test created & published
                </div>
              </div>
            :null
            }

          </div>
          <div className="r-ac" id="testHomeRangePicker64">
            <div
              className="shadow-box p-5 m-r-10"
              style={{ }}
              id="testHomeDropdown"
            >
              <Dropdown
                overlay={
                  <WeekMenu
                    handleFilterSelect={handleFilterSelect}
                    setDropdownVisibility={setDropdownVisibility}
                  />
                }
                trigger={["click"]}
                placement="bottomLeft"
                visible={isDropdownVisible}
                getPopupContainer={() =>
                  document.getElementById("testHomeDropdown")
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (isDropdownVisible) {
                    setDropdownVisibility(false);
                  } else {
                    setDropdownVisibility(true);
                  }
                }}
              >
                <div className="r-c-sb cursor-pointer">
                  <BsFilterLeft className="text-md a2grey" />
                  <div className="text-xs bold-700 m-l-10">{selectedFilter}</div>
                  <CaretDownFilled className="m-l-30" style={{color:"#AEAEAE"}}/>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  };

function BarGraph({ barchartData, userType, handleFilterSelect, selectedFilter, isBarChartEmpty,
  isDropdownVisible, setDropdownVisibility, loading, starDate, endDate, selectedYear,
  onDateBackward, onDateForward }) {

  let [sum, setSum] = useState(0);

  useEffect(() => {
    sum = 0;
    if (barchartData?.length != 0) {
      barchartData?.forEach(
        (item) => (
          (sum = sum + parseFloat(item?.totalCount))
        )
      );
    }
    setSum(sum);
  }, [barchartData]);
  const config = {
    data: barchartData,
    autoFit: true,
    columnWidthRatio: 0.3,
    isGroup: true,
    xField: "date",
    yField: "totalCount",
    seriesField: "name",
    columnStyle: {
      radius: [5, 5, 0, 0],
    },
    color: userType != "dataOperator" ? ['#9464FA', '#99DED9', '#589DFF'] : ['#589EFF', '#9464FA'],
    legend: false
  };
  return (
    <div className="shadow-box p-20 full-height">
      {loading ?
        <img src={GraphLoading} alt="" width="95%" height="95%" /> :
        <>
          <LegendRow
            handleFilterSelect={handleFilterSelect}
            selectedFilter={selectedFilter}
            userType={userType}
            isDropdownVisible={isDropdownVisible}
            setDropdownVisibility={setDropdownVisibility}
            starDate={starDate}
            endDate={endDate}
            selectedYear={selectedYear}
            onDateBackward={onDateBackward}
            onDateForward={onDateForward}
          />
          {sum == 0 ?
            <NodataCard /> :
            <div style={{ height: "87%" }}>
              <Column {...config} />
            </div>
          }
        </>}
    </div>
  )
}

export default BarGraph