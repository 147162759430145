import { YoutubeFilled, YoutubeOutlined } from '@ant-design/icons'
import { Modal, Typography, Radio } from 'antd'
import React, { useState } from 'react'
import logo1 from '../assets/UploadModal/illustration-videos.png'
import websiteLinkImg from 'Assets/digitalLibrary/Frame 1.svg'
import videoUploadImg from 'Assets/digitalLibrary/Frame 2.svg'
import { Button } from 'components/Button'
const { Text } = Typography



const VideoTypeModal = ({ visible, setVisible, setVideoType, toggleUploadFileModel }) => {
    const [checkedLink, setCheckedLink] = useState(false)
    const [checkedFile, setCheckedFile] = useState(false)
    const handleCancel = () => {
        setVisible(false)
    }
    const handleUpload = (e) => {
        console.log('e', e)
        if (e == 1) {
            setVideoType('videoFile')
            setCheckedLink(false)
            setCheckedFile(true)

        }
        else if (e == 2) {
            setVideoType('videoLink')
            setCheckedLink(true)
            setCheckedFile(false)
        }
    }

    const handleNext = () => {
        toggleUploadFileModel(true)
        setVisible(false)
    }
    return (
        <div>
            <Modal className='rounded-border' visible={visible} footer={null} centered={true} maskClosable={false} closable={false}
                onCancel={() => handleCancel()} width="820px" bodyStyle={{ width: "800px", height: "540px" }}>
                <div style={{ textAlign: "center", marginBottom: "70px" }}>
                    <Text style={{ color: "191919", font: "normal normal bold 20px/28px Roboto" }}>Upload content of library</Text><br></br>
                    <Text style={{ color: "#636363", font: "normal normal normal 12px/24px Roboto" }}>Please choose any one option</Text>
                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>

                        <div style={{ width: "300px", height: "260px", boxShadow: "4px 4px 18px #302A5F14", borderRadius: "4px", marginRight: "10px", border: " 1px solid #E6E6E6" }}>
                            <div className="cursor-pointer" style={{ width: "100%", height: "100%" }} onClick={() => handleUpload(1)}>
                                <Radio checked={checkedFile} style={{ marginLeft: "260px", marginTop: "8px" }}></Radio>
                                <div style={{ marginTop: "15px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img style={{ width: "200px", marginBottom: "15px" }} src={videoUploadImg} />
                                    <Text style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}>Upload from Local Device</Text>
                                    <Text style={{ color: '#636363', fontSize: '11px' }}>Upload any videos you want from your local device.</Text>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: "300px", height: "260px", boxShadow: "4px 4px 18px #302A5F14", borderRadius: "4px", marginRight: "10px", border: " 1px solid #E6E6E6" }} >
                            <div className="cursor-pointer" style={{ width: "100%", height: "100%" }} onClick={() => handleUpload(2)}>
                                <Radio checked={checkedLink} style={{ marginLeft: "260px", marginTop: "8px" }}></Radio>

                                <div style={{ marginTop: "15px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img style={{ width: "200px", marginBottom: "15px" }} src={websiteLinkImg} />
                                    {/* <YoutubeFilled  style={{fontSize:'120px', color:'#594099'}} /> */}

                                    <Text style={{ fontWeight: 'bold', color: 'black', fontSize: '15px' }}>YouTube links</Text>
                                    <Text style={{ color: '#636363', fontSize: '11px' }}>Embed any video you want from YouTube.</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "70px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <Button type="default" style={{ width: "100px", marginRight: "5px" }} onClick={() => handleCancel()}>Cancel</Button>
                    <Button type="primary" style={{ width: "100px", marginLeft: "5px" }} disabled={!(checkedLink || checkedFile)} onClick={() => handleNext()}>Next</Button>
                </div>
            </Modal>
        </div>
    )
}

export default VideoTypeModal
