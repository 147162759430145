import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, RightCircleFilled } from '@ant-design/icons';
import { PageHeader, Row, Col, Select, Progress } from 'antd';
// import './styles.css'
import ResultBySubject from '../studentTest/resultBySubject';
import { connect } from "react-redux";
import { updateTestType } from 'actions/studentPracticeTest';
import { fetchPerformance, fetchResult,fetchTestSolutions,setFilter, setSelectedTest} from 'actions/studentPracticeTest';
import { Color } from 'services';
import CombinedTestModal from './modals/combinedTestModal';


const singleSection = (sub, details, index) => {
    return (
    <div className="m-t-20">
        <div className="bold-600">{sub}</div>
        <div className="r-jsb">
            <div className="display-flex" style={{borderBottom: `1px solid ${Color.midGrey}`,  width: "100%"}}>
                <div style={{padding: 20}}>
                    <CheckCircleOutlined style={{color: Color.seaGreen}} /> {details.totalCorrect}
                </div>
                <div style={{padding: 20}}>
                    <CloseCircleOutlined style={{color: Color.red}} /> {details.totalIncorrect}
                </div>
                <div style={{padding: 20}}>
                    <MinusCircleOutlined style={{color: Color.darkGrey}} /> {details.totalUnattempted}
                </div>
            </div>
            <div className="text-md bold-600" style={{padding: 20}}>
                {details.marksObtained || 0}/{details.totalMarks}
            </div>
        </div>
    </div>
)}

function practiceResultScreen({updateTestType,filter,fetchResult,fetchPerformance,
    cards,fetchTestSolutions, selectedTest, setSelectedTest}) {
    useEffect(() => {
        fetchResult(()=>{
      }),
      fetchPerformance(()=>{

      }) 
    }, [])

    const [isModalVisible,setIsModalVisible] = useState(false);
    const [showSubmitSuccess, toggleSubmitSuccess] = useState(false);

    const history = useHistory();
    const handleViewSolutionsClick = () => {
        updateTestType("Attempted"),
        history.push('/practice-solution-screen')
    }

    const handleReattemptClick = () => {
        setSelectedTest({...selectedTest, paused:false})
        setIsModalVisible(true);
    }

    const handleCancel = ()=>{
        setIsModalVisible(false);
      }

    return (
        <div style={{ backgroundColor:'#FAFAFA', padding: 20, margin: -20/* , overflowY: 'auto' */ }}>
            <div style={{backgroundColor:'white', width: '100%', marginTop: -18}}>
              <PageHeader
                title={<div className="text-md" style={{fontSize:'22px'}}> Result</div>}
                onBack={() => history.push('student-practice-home')}
                backIcon={<ArrowLeftOutlined  style={{fontSize: "22px",fontFamily:"Roboto",fontWeight:'bold'}} />	}
                style={{paddingLeft:'-20px', paddingRight:'0px', width: '104%', marginLeft: -20}}
                />
            </div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div >
                    <h3 style={{fontFamily:"Roboto", fontSize:"22px",fontWeight:'bold',marginBottom:"0px"}}>{selectedTest.title}</h3>
                    <p style={{fontFamily:"Roboto", fontSize:"12px",fontWeight:'bold'}}>{selectedTest?.subjects?.map(item=>item.name)?.join(" • ")}</p>

                    </div>
                    <Button type="primary" shape="round" onClick={() => handleReattemptClick()} style={{backgroundColor:"#594099", width:"120px",height:"10%",color:"white",alignSelf:'center'}}>REATTEMPT</Button>
                </div>
            <div className="responsive_style" style={{display:"flex",flexWrap:"wrap"}}>
                <div className="circleStroke p-t-20 p-b-20 p-l-10 p-r-10" style={{display:"flex", flexDirection:"row",height:"70%",width:"500px",border:'1px solid #E4E4E4',backgroundColor:'white' ,borderRadius:"10px",alignItems:"center",justifyContent:"flex-start"}}>

                    <div>
                        <div><h3 style={{fontWeight:"700",marginLeft:"30px",marginTop:"-30px",marginBottom:"30px"}}>Score</h3></div>
                        <div style={{marginLeft:"20%"}}>
                            <Progress
                                        type="circle"
                                        percent={(((filter?.selectedResult?.total?.marksObtained)/(filter?.selectedResult?.total?.totalMarks))*100)}
                                        strokeWidth={10}
                                        width={200}
                                        strokeLinecap="square"
                                        strokeColor="#594099"
                                        trailColor="#E6E6E6"
                                        format={(percent) => (
                                            <>
                                                <div className="bold-600 m-b-10 " style={{fontSize: "0.6em"}}>{filter?.selectedResult?.total?.marksObtained}</div>
                                                <div className="bold-600 m-b-10 " style={{fontSize: "0.6em",margin:"0 auto",width:"20%",borderBottom:"black solid 1px"}}></div>
                                                <div className="bold-600 m-b-10 " style={{fontSize: "0.6em"}}>{filter?.selectedResult?.total?.totalMarks}</div>

                                            </>

                                            )}

                            />
                        </div>

                    </div>
                    <div style={{display:"flex",flexDirection:"row",marginTop:"6%",marginLeft:"15%"}}>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"center"}}>
                            <div><img style={{ marginBottom:"40%",width:"80%",height:"80%"}} src={require('Assets/images/ic-faq.svg').default} alt="" /></div>
                            <div><img style={{ marginBottom:"40%",width:"80%",height:"80%"}} src={require('Assets/images/ic-call-us.svg').default} alt="" /></div>
                            <div><img style={{ marginBottom:"40%",width:"80%",height:"80%"}} src={require('Assets/images/ic-chat.svg').default} alt="" /></div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"left",alignItems:"start",marginTop:"4%"}}>
                            <div className="noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedResult?.total?.totalCorrect}</h3><p className="results_p1">Correct</p></div>
                            <div className="noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedResult?.total?.totalIncorrect}</h3><p className="results_p1">Incorrect</p></div>
                            <div className="noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedResult?.total?.totalUnattempted}</h3><p className="results_p1">Unattempted</p></div>

                        </div>
                    </div>
                </div>
                {/* ////////leftpart */}

                <div className="rightPart p-t-20 p-r-20 p-b-20" style={{boxShadow:'none', border:'1px solid #E4E4E4',backgroundColor:'white'}}>
                    <h3 style={{marginTop:"3%",marginBottom:"7%",fontWeight:"700"}}>Performance analysis</h3>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{}}>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10684.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Rank</p></div>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10690.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Accuracy</p></div>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10691.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Speed</p></div>
                            <div  className="righticons"><img style={{ marginBottom:"10%",width:"25%",height:"8%"}} src={require('Assets/images/Group 10685.svg').default} alt="" /><p style={{fontSize:"15px", fontWeight:"600",marginLeft:"5%"}}>Percentile</p></div>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"left",alignItems:"start",marginTop:"-4%",paddingRight:"5%"}}>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedPerformance?.rank}</h3><p className="results_p"> </p></div>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedPerformance?.accuracy!=null?parseFloat(filter.selectedPerformance.accuracy).toFixed(2):0} </h3><p className="results_p"> %</p></div>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedPerformance?.speed} </h3><p className="results_p"> Mins/Qs</p></div>
                            <div className="l-noOfAns"><h3 style={{fontWeight:"750",fontSize:"20px",marginBottom:"0px"}}>{filter?.selectedPerformance?.percentile}{" "} </h3><p className="results_p"><sup>{" "}th</sup></p></div>
                    </div>
                    </div>
                </div>
            </div>

                {/* //////bottomleft content */}
                {
                filter?.selectedResult?.subjects && Object.entries(filter?.selectedResult?.subjects )?.length > 1 
                ?
                <div style={{padding: "20px 20px"}}>
                    <div className="graphBox" style={{ padding: 25, minHeight: 400}}>
                            <div className="bold-600">Sections</div>
                            {filter?.selectedResult?.subjects ? Object.entries(filter?.selectedResult?.subjects )?.map(([sub, details], index) => singleSection(sub, details, index)) : null}
                            <div className="r-c-c m-t-30">
                                <Button type="primary" shape="round" onClick={() =>handleViewSolutionsClick()}>
                                        VIEW SOLUTIONS
                                </Button>
                            </div>
                    </div>
                </div>
                :
                filter?.selectedResult?.subjects  &&Object.entries(filter?.selectedResult?.subjects )?.length == 1
                ?
                <div className="r-c-c m-t-30">
                    <Button type="primary" shape="round" onClick={() =>handleViewSolutionsClick()}>
                            VIEW SOLUTIONS
                    </Button>
                </div>
                :null
            }
            {isModalVisible &&( 
            <CombinedTestModal
              isModalVisible={isModalVisible} 
              handleCancel={handleCancel}
              toggleSubmitSuccess={toggleSubmitSuccess}
              />)}
        </div>


    )
}

const mapStateToProps = (state) => {
    const {filter,cards, selectedTest}=state.studentPracticeTest;

    return {
        filter,cards, selectedTest
    };
  };

  const mapDispatchToProps = dispatch => ({
    updateTestType: (newType)=>dispatch(updateTestType(newType)),
    fetchResult:(callback)=>dispatch(fetchResult(callback)),
    setFilter:(key,value)=>dispatch(setFilter(key,value)),
    fetchPerformance:(callback)=>dispatch(fetchPerformance(callback)),
    fetchTestSolutions:(callback)=>dispatch(fetchTestSolutions(callback)),
    setSelectedTest: (data) => dispatch(setSelectedTest(data))

  });
export default connect(mapStateToProps, mapDispatchToProps)(practiceResultScreen);