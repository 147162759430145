import {
  SET_RIGHT_VIEW,
  SET_RIGHT_VIEW_TYPE,
  TOGGLE_MUTE,
  SET_CAMERA_STATE,
  SET_SCREEN_STATE,
  ADD_MESSAGE_TO_GROUP,
  CLEAR_GROUP_CHAT,
  UPDATE_USERS_LIST,
  UPDATE_USER_COUNT,
  SET_USER_TYPE,
  SET_STREAM_ID,
  SET_CURRENT_PRIVATE_CHAT_RECIEVER,
  ADD_MESSAGE_TO_PRIVATE,
  CLEAR_PRIVATE_CHAT,
  COPY_CHATS,
  SET_BROADCAST_LOADER,
  SET_RECORDINGS_DATA,
  SET_CURRENT_RECORDING_PATH,
  SET_COMPLETED_SUBJECT_NAME,
  SET_SELECTED_RECORDINGS_INDEX,
  STORE_PATHS,
  SET_ROOMBOARD_DATA,
  SET_ROOMIMAGE_DATA,
  SET_SECTION_LIST,
  SET_SCHEDULED_TEACHER,
  SET_DOWNLOAD_META,
  UPDATE_UNREAD_COUNT,
  SET_WITHOUT_RECORDING_COUNT,
  PDF_UPLOADED,
  FILE_KEY_UPDATE,
  SET_HIDE_MSG_TRUE,
  SET_LOCAL_RECORDING_NAME
} from "actions/broadcast";
import user from "pages/user/user";
import { Api } from "services";


const initialState = {
rightView: false,
rightViewType: '',
micIsMuted: true,
isCamOn: false,
screenState: true,
groupMessages: [],
roomId: 4,
usersList: [],
userCount: 0,
userType: '',
streamId:'1234',
privateMessages: {},
currentPrivateChatReciever: {},
broadcastLoader: false,
userListRender: false,
recordingsData: [],
currentRecordingsPath: '',
completedSubjectName: '',
selectedRecordingsIndex: -1,
paths: {'abc': 1},
roomboardData: {
  '0': {
    roomdata: {
    "1": [],
    "2": [],
    "3": [],
  }
}
},
roomImageData: {
  "1": [],
  "2": [],
  "3": []
},
sectionList: [],
isScheduledTeacher: false,
downloadMeta: {},
unreadCount: {
  group: 0,
  private: {
  }
},
storeBroadcastWithoutCount:[],
Pdftoupload: null,
fileKey:'',
hideMsg:null,
localRecordingName: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
      case SET_USER_TYPE:
        let inferredType = '';
        if (action.user.role.includes("Student")){
          inferredType='student'
        }
        else if (!(action.user.role.includes("Student"))){
          inferredType='teacher'
        }
        return {
          ...state,
          userType: inferredType
        }
    case SET_RIGHT_VIEW:
      return{
          ...state,
          rightView: action.val
      }
    case SET_RIGHT_VIEW_TYPE:
      return{
        ...state,
        rightViewType: action.viewType
      }
    case TOGGLE_MUTE:
      return{
        ...state,
        micIsMuted: action.bool
      }
    case SET_CAMERA_STATE:
      return{
        ...state,
        isCamOn: action.bool
      }
    case SET_SCREEN_STATE:
      return{
        ...state,
        screenState: action.bool
      }
    case ADD_MESSAGE_TO_GROUP:
      let newGrpMessages = state.groupMessages?[...state.groupMessages]:[]
      newGrpMessages.push(action.newMessage);
      return{
        ...state,
        groupMessages: newGrpMessages
      }
    case CLEAR_GROUP_CHAT:
      return{
        ...state,
        groupMessages: []
      }
    case UPDATE_USERS_LIST:
      let broadcastlist = [...action.broadcastList];
      let usersList = [...action.usersList];
      let broadcastStudentIds = action.broadcastList.map(item=>item.id);
      let displaylist = []
      let usertracker = {}

      for (let i=0; i<broadcastlist.length; i++){
          displaylist.push({user:{...broadcastlist[i], isStudent:true}, statustoshow:"offline"})
          usertracker[broadcastlist[i].id] = 1
      }
      for (let i=0; i<usersList.length; i++){
        if (!(usersList[i].user?.id in usertracker)){
          let obj = {...usersList[i]};
          if (broadcastStudentIds.includes(obj.user.id)){
            obj.user.isStudent = true;
          }
          displaylist.push(obj);
        }else{
          displaylist.forEach((item)=>{
            if (item.user.id==usersList[i]?.user?.id){
              item.statustoshow = usersList[i].statustoshow
            }
          })
        }
      }

      return{
        ...state,
        usersList: displaylist,
        userCount: displaylist.length,
        userListRender: !state.userListRender
      }
    case UPDATE_USER_COUNT:
      return{
        ...state,
        userCount: action.newCount
      }
    case SET_STREAM_ID:
      return{
        ...state,
        streamId:action.streamId
      }  
    case SET_CURRENT_PRIVATE_CHAT_RECIEVER:
      if ((state.privateMessages) && !(action.newUser in state.privateMessages)){
        state.privateMessages[action.newUser] = [];
      }
      return {
        ...state,
        currentPrivateChatReciever: action.newUser
      }
    case ADD_MESSAGE_TO_PRIVATE:
      let reciever = action.reciever;
      if (!(reciever in state.privateMessages)){
        state.privateMessages[reciever] = [];
      }
      let newPrivateMessages = {...state.privateMessages}
      newPrivateMessages[reciever].push(action.newMessage);
      return{
        ...state,
        privateMessages: newPrivateMessages
      }
    case CLEAR_PRIVATE_CHAT:
      return{
        ...state,
        privateMessages: {}
      }
    case COPY_CHATS:
      let sessionuser = {...action.sessionuser}
      console.log("sesuder", sessionuser)
      let newGroupMessages = []
      let privateMessagesFromDB = {}
      for (let i =0; i<action.groupMessages.length; i++){
        let newMessage = {
          type: action.groupMessages[i].type,
          userId: action.groupMessages[i].userId,
          user: {...action.groupMessages[i].user, avatar: `${Api._s3_url}${action.groupMessages[i].user.image}`},
          createdAt: action.groupMessages[i].createdAt,
          id: action.groupMessages[i].messageId
        }
        console.log("newmes", newMessage);
        if (action.groupMessages[i].type=="Image"){
          newMessage.image = `${Api._s3_url}${action.groupMessages[i].message}`
        }
        else{
          newMessage.text = action.groupMessages[i].message
        }
        newGroupMessages.push(newMessage)
      }
      for (let i=0; i<action.privateMessages.length; i++){
        let receiver = sessionuser.id!=action.privateMessages[i].receiver.id?action.privateMessages[i].receiver.id:action.privateMessages[i].user.id;
        let newMessage = {
          type: action.privateMessages[i].type,
          userId: action.privateMessages[i].user.id,
          user: {...action.privateMessages[i].user, avatar: `${Api._s3_url}${action.privateMessages[i].user.image}`},
          createdAt: action.privateMessages[i].createdAt,
          id: action.privateMessages[i].messageId
        }
        if (action.privateMessages[i].type=="Image"){
          newMessage.image = `${Api._s3_url}${action.privateMessages[i].message}`
        }
        else{
          newMessage.text = action.privateMessages[i].message
        }
        if (!(receiver in privateMessagesFromDB)){
          privateMessagesFromDB[receiver] = [newMessage]
        }
        else{
          privateMessagesFromDB[receiver].push(newMessage)
        }
      }
      return {
        ...state,
        groupMessages: newGroupMessages,
        privateMessages: privateMessagesFromDB,
        broadcastLoader: false
      }
    case SET_BROADCAST_LOADER:
      return {
        ...state,
        broadcastLoader: action.bool
      }
    case SET_RECORDINGS_DATA:
      if (action.data.length){
        action.data[0].fileName = action.data[0].fileName?.split('_namespace_')?.join(' ')
        action.data = action.data.slice(0, 1)
        action.data[0].url = `https://dexey3fal68zk.cloudfront.net/${action.data[0].roomName}/hls/master.m3u8`
        action.data[0].downloadurl = `https://dvjwakd22kemz.cloudfront.net/${action.data[0].roomName}/transcoded.mp4`
      }
      return {
        ...state,
        recordingsData: action.data
      }
    case SET_CURRENT_RECORDING_PATH:
      return {
        ...state,
        currentRecordingsPath: `${action.filePath}`
      }
    case SET_COMPLETED_SUBJECT_NAME:
      return{
        ...state,
        completedSubjectName: action.name
      }  
    case SET_SELECTED_RECORDINGS_INDEX:
      return{
        ...state,
        selectedRecordingsIndex: action.index
      }
    case STORE_PATHS:
      return{
        ...state,
        paths: {...state.paths, [`${action.roomName}`]: action.paths}
      }
    case SET_ROOMBOARD_DATA:
      state.roomboardData[action.id] = action.data
      return{
        ...state,
        roomboardData: {...state.roomboardData}
      }
    case SET_ROOMIMAGE_DATA:
      return {
        ...state,
        roomImageData: action.data
      }
    case SET_SECTION_LIST:
      return {
        ...state,
        sectionList: action.val
      }
    case SET_SCHEDULED_TEACHER:
      return {
        ...state,
        isScheduledTeacher: action.val
      }
      case SET_WITHOUT_RECORDING_COUNT:
        return {
          ...state,
          storeBroadcastWithoutCount:action.res
        }
    case SET_DOWNLOAD_META:
      let newValues = state.downloadMeta? { ...state.downloadMeta }:{};
      if (action.key === 'clear') {
          ////write some conditionhere
          newValues = {}
      } else {
          newValues[action.key] = action.val
      }
      return {
          ...state,
          downloadMeta: newValues
      }
    case UPDATE_UNREAD_COUNT:
      let tc = {...state.unreadCount}
      if (action.key==='group'){
        tc['group'] = action.val
      }else if(action.key==='groupIncrement'){
        tc['group'] = tc['group']+1
      }else if(action.key==='privateIncrement'){
        let pc = {...tc['private']}
        console.log("558,", action.id, !(action.id in pc), pc)
        if (!(action.id in pc)){
          pc[action.id] = 0
        }
        pc[action.id] = pc[action.id] + 1
        tc['private'] = {...pc}
      }else if(action.key==='privateSpecific'){
        let pc = {...tc['private']}
        pc[action.id] = action.val
        tc['private'] = {...pc}
      }else if(action.key==='privateFull'){
        tc['private'] = action.val
      }else if(action.key==='clear'){
        tc = {...initialState.unreadCount}
      }
      console.log("561", action.key, action.val, tc)
      return {
        ...state,
        unreadCount: {...tc}
      }
      case PDF_UPLOADED:
          return {
              ...state,
              Pdftoupload: action.data

          }
      case FILE_KEY_UPDATE:
        return{
            ...state,
            fileKey:action.data
        }
      case SET_HIDE_MSG_TRUE:
        return{
          ...state,
          hideMsg:action.res
        }
      case SET_LOCAL_RECORDING_NAME:
        let curr = {...state.localRecordingName};
        curr[action.key] = action.val;
        return {
          ...state,
          localRecordingName: {...curr}
        }

    default:
      return state;
  }
};

export default reducer;
