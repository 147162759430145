import { Information, Title2 } from 'components/Typography';
import FormattedDate from 'components/formatCreatedAt';
import React from 'react'
import './styles.css'

function RecentlyAddedCard({ icon, title, item, isLast, isStudent, setSingleStudentData, setIsStudentModalVisible, setSingleCourseData, setIsCourseModalVisible }) {
    return (
        <div className='recently-added-card-course' style={{ marginBottom: !isLast ? 10 : 1 }} onClick={() => {
            if (isStudent) {
                setSingleStudentData(item); setIsStudentModalVisible(true)
            } else {
                setSingleCourseData(item); setIsCourseModalVisible(true)
            }
        }}>
            <div className='recently-added-card-course-content r-c-fs'>
                <img src={icon} className='overview-single-card-img' />
                <div className='m-l-10' style={{ flex: 1, overflow: 'hidden', marginLeft: 10 }}>
                    <div className='r-c-sb' style={{ width: "100%" }}>
                        <div className="recently-added-title"><Title2>{title}</Title2></div>
                        <div style={{ flexShrink: 0 }}><FormattedDate createdAt={item?.createdAt} /></div>
                    </div>
                    <Information className='m-t-4'>
                        {isStudent ? (
                            <span>{item?.userName} has {item.courseType === "liveCourse" ? 'enrolled to ' : 'purchased '}{item?.courseName}</span>
                        ) : (
                            item?.createdByName ? (
                                <span>{item?.courseName} was added by {item?.createdByName}</span>
                            ) : (
                                <span style={{ fontWeight: "normal" }}>{item?.courseName} has been added </span>
                            )
                        )}
                    </Information>
                </div>
            </div>
        </div>
    )
}

export default RecentlyAddedCard