import { ArrowLeftOutlined, CaretLeftOutlined, CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { Col, DatePicker, Row, Select, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Api } from '../../services';
import moment from 'moment';
import TeacherBarChart from './teachersBarChart';
import TeachersPieChart from './teachersPiechart';
import TeachersWorkhrsGraph from './teachersWorkHrsGraph';
import {
  getUsersDashBoardData,
  storeSummaryType,
  storeSummaryYear,
  storeUserSummaryEndDate,
  storeUserSummaryStartDate,
  storeWorkSummaryEndDate,
  storeWorkSummaryStartDate,
  storeWorkSummaryType,
  storeWorkSummaryYear,
  setBackToPrivilegedView,
  setClickedOnUserCard,
  setFromUsersGeofence
} from 'actions/attendanceNew';
import PageDataLoader from 'components/pageDataLoader';
import { NodataCard } from 'pages/Test';
// import { setSpecificStudentUseId } from 'actions/adminCare';
import { setSpecificUserId, setUserName } from 'actions/userManagement';
import UsersMonthBarChart from './usersMonthBarChart';
import UsersWorkSummMonthGraph from './usersWorkSummMonthGraph';
import PageHeader from 'components/pageHeader';
import { Button } from 'components/Button'
import { Heading } from 'components/Typography';
import { setOthersSelectedTab } from 'actions/profileNew';
import { setUserDetails } from 'actions/userManagementV2';

const geoImage = require("../../Assets/noun-location-point-4457421.svg").default;
const image = require("../../Assets/user/com-default-pic.svg").default;

const { RangePicker } = DatePicker;

const TeachersPieCard = ({ usersDataContainer, usersNameContainer }) => {
  return (
    <div
      className='border-16 boxShadow118'
      style={{
        width: "96%",
        height: "380px",
        background: " #FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E6E6E6",
        // borderRadius:"6px",
        marginTop: "20px"
      }}
    >
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: "15px", color: "#191919", fontWeight: "bold" }}>
          Today’s attendance
        </div>
        <div style={{ fontSize: "10px", color: "#636363", fontWeight: 600 }}>
          {moment().format('DD/MM/YYYY  •  dddd')}
        </div>
      </div>
      <div style={{ marginTop: -70 }}>
        <TeachersPieChart
          usersDataContainer={usersDataContainer}
          usersNameContainer={usersNameContainer}
        />
      </div>
    </div>
    // </div>
  );
};


const TeacherBarCard = ({
  getUsersDashBoardData,
  usersDataContainer,
  storeSummaryType,
  storeSummaryYear,
  storeUserSummaryStartDate,
  storeUserSummaryEndDate,
  summaryStartDateContainer,
  summaryEndDateContainer,
  summaryTypeContainer,
  summaryYearContainer,
  togglePageLoader
}) => {

  const handleChange = (value) => {
    storeSummaryType(value);
    storeUserSummaryStartDate(moment().startOf(value).format('YYYY-MM-DD'))
    storeUserSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"));
    togglePageLoader(true)
    getUsersDashBoardData(() => {
      togglePageLoader(false)
    })
  }
  const handleChangeYear = (value) => {
    console.log(`selected ${value.format("YYYY")}`);
    storeSummaryYear(value.format("YYYY"));
    togglePageLoader(true)
    getUsersDashBoardData(() => {
      togglePageLoader(false)
    })
  }

  const disable = (current) => {
    return current && current.format("YYYY") > moment().format("YYYY");
  };

  const disableFutureDate = (current) => {
    const yesterday = moment().subtract(1, 'days')
    return !(yesterday.isAfter(current));
  }

  return (
    <div
      className='border-16 boxShadow118'
      style={{
        width: "100%",
        height: "380px",
        background: " #FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E6E6E6",
        // borderRadius:"6px",
        marginTop: "20px",
      }}
    >
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }} span={24}>
        <Col span={5} style={{ margin: "20px" }}>
          <div
            style={{
              color: "#191919",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Summary
          </div>
          {summaryTypeContainer === "week" ?
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "9px", color: "#636363" }}>
                <CaretLeftOutlined
                  onClick={() => {
                    storeUserSummaryStartDate(moment(summaryStartDateContainer, 'YYYY-MM-DD').startOf(summaryTypeContainer).subtract(1, summaryTypeContainer).format('YYYY-MM-DD'))
                    storeUserSummaryEndDate(moment(summaryEndDateContainer, 'YYYY-MM-DD').endOf(summaryTypeContainer).subtract(1, summaryTypeContainer).format('YYYY-MM-DD'))
                    togglePageLoader(true)
                    getUsersDashBoardData(() => {
                      togglePageLoader(false)
                    })
                  }} />
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "9px",
                  color: "#636363",
                  width: "105px"
                }}
              >
                {(moment(summaryStartDateContainer).format('DD-MM-YYYY')) ? (moment(summaryStartDateContainer).format('DD-MM-YYYY')) : null}{" "}-{" "}{(moment(summaryEndDateContainer).format('DD-MM-YYYY')) ? (moment(summaryEndDateContainer).format('DD-MM-YYYY')) : null}
              </div>
              {!(summaryStartDateContainer === moment().startOf('week').format("YYYY-MM-DD") ||
                summaryEndDateContainer === moment().subtract(1, 'days').format("YYYY-MM-DD")) ?
                <div style={{ fontSize: "9px", color: "#636363" }}>
                  <CaretRightOutlined
                    onClick={() => {
                      storeUserSummaryStartDate(moment(summaryStartDateContainer, 'YYYY-MM-DD').startOf(summaryTypeContainer).add(1, summaryTypeContainer).format('YYYY-MM-DD'))
                      if (summaryEndDateContainer === (moment().startOf('week').subtract(1, "days").format("YYYY-MM-DD"))) {
                        storeUserSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"))
                      } else if (summaryStartDateContainer !== moment().startOf('week').format("YYYY-MM-DD")) {
                        storeUserSummaryEndDate(moment(summaryEndDateContainer, 'YYYY-MM-DD').endOf(summaryTypeContainer).add(1, summaryTypeContainer).format('YYYY-MM-DD'))
                      }
                      togglePageLoader(true)
                      getUsersDashBoardData(() => {
                        togglePageLoader(false)
                      })
                    }} />
                </div>
                : null
              }
            </div>
            :
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "9px", color: "#636363" }}>
                <CaretLeftOutlined
                  onClick={() => {
                    storeSummaryYear(moment(summaryYearContainer, "YYYY").subtract(1, "year").format("YYYY"));
                    togglePageLoader(true)
                    getUsersDashBoardData(() => {
                      togglePageLoader(false)
                    })
                  }} />
              </div>
              <div style={{
                marginLeft: "5px",
                fontSize: "10px",
                color: "#636363",
                width: "105px"
              }}>Jan - December, {summaryYearContainer}
              </div>
              {console.log("moment1", summaryYearContainer === moment().year())}
              {!(summaryYearContainer == moment().year()) ?
                <div style={{ fontSize: "9px", color: "#636363" }}>
                  <CaretRightOutlined
                    onClick={() => {
                      storeSummaryYear(moment(summaryYearContainer, "YYYY").add(1, "year").format("YYYY"));
                      togglePageLoader(true)
                      getUsersDashBoardData(() => {
                        togglePageLoader(false)
                      })
                    }} />
                </div>
                :
                null
              }
            </div>
          }
        </Col>
        <Col span={16}>
          <Row style={{ width: "100%", display: "flex" }}>
            <Col /* lg={{ span: 24 }} xl={{ span: 9 }} */ span={10}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "start",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "25px",
                  }}
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#28DF99",
                      borderRadius: "2px",
                      marginRight: "10px",
                      marginTop: "5px"
                    }}
                  ></div>
                  <span style={{ fontSize: "12px", color: "#636363" }}>Present</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "25px",
                  }}
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#FF414D",
                      borderRadius: "2px",
                      marginRight: "10px",
                      marginTop: "5px"
                    }}
                  ></div>
                  <span style={{ fontSize: "12px", color: "#636363" }}>Absent</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "25px",
                  }}
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#1089FF",
                      borderRadius: "2px",
                      marginRight: "10px",
                      marginTop: "5px"
                    }}
                  ></div>
                  <span style={{ fontSize: "12px", color: "#636363" }}>Leave</span>
                </div>
              </div>
            </Col>
            <Col /* lg={{ span: 24 }} xl={{ span: 9 }} */ span={11} style={{ marginTop: "20px" }}>
              {summaryTypeContainer === "week" ?
                <div style={{ width: "100%", maxWidth: "400px" }}>
                  <DatePicker.RangePicker
                    // format={'DD-MM-YYYY'}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    allowClear={false}
                    value={[summaryStartDateContainer ? moment(summaryStartDateContainer) : null,
                    summaryEndDateContainer ? moment(summaryEndDateContainer) : null]}
                    onChange={(arg, value) => {
                      if (value[0] !== "" && value[1] !== "") {
                        console.log("value", value)
                        storeUserSummaryStartDate(value[0]);
                        storeUserSummaryEndDate(value[1]);
                        togglePageLoader(true)
                        getUsersDashBoardData(() => {
                          togglePageLoader(false)
                        })
                      } else {
                        storeUserSummaryStartDate(moment().startOf('week').format("YYYY-MM-DD"));
                        storeUserSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"));
                      }
                    }}
                    disabledDate={disableFutureDate}
                  />
                </div>
                :
                <div style={{ marginLeft: "80px" }}>
                  <DatePicker
                    allowClear={false}
                    picker="year"
                    onChange={handleChangeYear}
                    value={moment(summaryYearContainer, "YYYY")}
                    disabledDate={disable} />
                </div>
              }
            </Col>
            <Col /* lg={{ span: 24 }} xl={{ span: 9 }} */ span={3} style={{ marginTop: "20px" }}>
              <Select style={{ width: "9x0px", marginLeft: "10px" }} defaultValue={"Week"} onChange={handleChange} getPopupContainer={(trigger) => trigger.parentElement}>
                <Option value="week" >Week</Option>
                <Option value="month">Months</Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      {usersDataContainer?.attendanceSummary?.length !== 0 ?
        <div style={{ width: "95%", height: "87%", padding: 20 }}>
          {summaryTypeContainer === "week" ?
            <TeacherBarChart
              data={usersDataContainer?.attendanceSummary} />
            :
            <UsersMonthBarChart
              data={usersDataContainer?.attendanceSummary} />
          }
        </div>
        : null
      }
    </div>
  );
};

const TeachersWorkHrsCard = ({
  storeWorkSummaryType,
  storeWorkSummaryStartDate,
  storeWorkSummaryEndDate,
  storeWorkSummaryYear,
  workSummaryStartDateCont,
  workSummaryEndDateCont,
  workSummaryTypeContainer,
  workSummaryYearContainer,
  togglePageLoader,
  getUsersDashBoardData,
  usersDataContainer,
  teacherGraph,
  sum
}) => {
  const handleChange = (value) => {
    console.log(`selectedddd ${value}`);
    storeWorkSummaryType(value);
    storeWorkSummaryStartDate(moment().startOf(value).format('YYYY-MM-DD'))
    storeWorkSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"))
    togglePageLoader(true)
    getUsersDashBoardData(() => {
      togglePageLoader(false)
    })
  }
  const handleChangeYear = (value) => {
    console.log(`selected ${value.format("YYYY")}`);
    storeWorkSummaryYear(value.format("YYYY"));
    togglePageLoader(true)
    getUsersDashBoardData(() => {
      togglePageLoader(false)
    })
  }

  const disable = (current) => {
    return current && current.format("YYYY") > moment().format("YYYY");
  };

  const disableFutureDate = (current) => {
    const yesterday = moment().subtract(1, 'days')
    return !(yesterday.isAfter(current));
  }

  const usersData = true
  return (
    <div
      className='border-16 boxShadow118'
      style={{
        width: "100%",
        height: "380px",
        background: " #FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E6E6E6",
        // borderRadius:"6px",
        marginTop: "20px"
      }}
    >
      <div
        style={{
          color: "#191919",
          fontWeight: "bold",
          fontSize: "15px",
          marginLeft: 20,
          marginTop: "20px"
        }}
      >
        Working hours summary
      </div>
      <div style={{ display: "flex" }}>
        {workSummaryTypeContainer === "week" ?
          <div style={{ display: "flex", marginLeft: "20px" }}>
            <div style={{ fontSize: "9px", color: "#636363" }}>
              <CaretLeftOutlined
                onClick={() => {
                  storeWorkSummaryStartDate(moment(workSummaryStartDateCont, 'YYYY-MM-DD').startOf(workSummaryTypeContainer).subtract(1, workSummaryTypeContainer).format('YYYY-MM-DD'))
                  storeWorkSummaryEndDate(moment(workSummaryEndDateCont, 'YYYY-MM-DD').endOf(workSummaryTypeContainer).subtract(1, workSummaryTypeContainer).format('YYYY-MM-DD'))
                  togglePageLoader(true)
                  getUsersDashBoardData(() => {
                    togglePageLoader(false)
                  })
                }} />
            </div>
            <div
              style={{
                fontSize: "9px",
                color: "#636363",
                width: "105px",
                marginLeft: "5px"
              }}
            >
              {(moment(workSummaryStartDateCont).format('DD-MM-YYYY')) ? (moment(workSummaryStartDateCont).format('DD-MM-YYYY')) : null}{" "}-{" "}{(moment(workSummaryEndDateCont).format('DD-MM-YYYY')) ? (moment(workSummaryEndDateCont).format('DD-MM-YYYY')) : null}
            </div>
            {!(workSummaryStartDateCont === moment().startOf('week').format("YYYY-MM-DD") ||
              workSummaryEndDateCont === moment().subtract(1, 'days').format("YYYY-MM-DD")) ?
              <div style={{ fontSize: "9px", color: "#636363" }}>
                <CaretRightOutlined
                  onClick={() => {
                    storeWorkSummaryStartDate(moment(workSummaryStartDateCont, 'YYYY-MM-DD').startOf(workSummaryTypeContainer).add(1, workSummaryTypeContainer).format('YYYY-MM-DD'))
                    if (workSummaryEndDateCont === (moment().startOf('week').subtract(1, "days").format("YYYY-MM-DD"))) {
                      storeWorkSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"))
                    } else if (workSummaryStartDateCont !== moment().startOf('week').format("YYYY-MM-DD")) {
                      storeWorkSummaryEndDate(moment(workSummaryEndDateCont, 'YYYY-MM-DD').endOf(workSummaryTypeContainer).add(1, workSummaryTypeContainer).format('YYYY-MM-DD'))
                    }
                    togglePageLoader(true)
                    getUsersDashBoardData(() => {
                      togglePageLoader(false)
                    })
                  }} />
              </div>
              : null}
          </div>
          :
          <div style={{ display: "flex", marginLeft: "20px" }}>
            <div style={{ fontSize: "9px", color: "#636363" }}>
              <CaretLeftOutlined
                onClick={() => {
                  storeWorkSummaryYear(moment(workSummaryYearContainer, "YYYY").subtract(1, "year").format("YYYY"))
                  togglePageLoader(true)
                  getUsersDashBoardData(() => {
                    togglePageLoader(false)
                  })
                }} />
            </div>
            <div style={{
              marginLeft: "5px",
              fontSize: "10px",
              color: "#636363",
              width: "105px"
            }}>Jan - December, {workSummaryYearContainer}
            </div>
            {!(workSummaryYearContainer == moment().year()) ?
              <div style={{ fontSize: "9px", color: "#636363" }}>
                <CaretRightOutlined
                  onClick={() => {
                    storeWorkSummaryYear(moment(workSummaryYearContainer, "YYYY").add(1, "year").format("YYYY"))
                    togglePageLoader(true)
                    getUsersDashBoardData(() => {
                      togglePageLoader(false)
                    })
                  }} />
              </div>
              :
              null
            }
          </div>
        }
        <div style={{ display: "flex", marginTop: -10 }}>
          {workSummaryTypeContainer === "week" ?
            <div style={{ width: "70%", marginLeft: "160px" }} id='calendar'>
              <DatePicker.RangePicker
                // format={'DD-MM-YYYY'}
                getPopupContainer={() => document.getElementById("calendar")}
                allowClear={false}
                value={[workSummaryStartDateCont ? moment(workSummaryStartDateCont) : null,
                workSummaryEndDateCont ? moment(workSummaryEndDateCont) : null]}
                onChange={(arg, value) => {
                  if (value[0] !== "" && value[1] !== "") {
                    storeWorkSummaryStartDate(value[0]);
                    storeWorkSummaryEndDate(value[1]);
                    togglePageLoader(true)
                    getUsersDashBoardData(() => {
                      togglePageLoader(false)
                    })
                  } else {
                    storeWorkSummaryStartDate(moment().startOf('week').format("YYYY-MM-DD"));
                    storeWorkSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"));
                  }
                }}
                disabledDate={disableFutureDate} />
            </div>
            :
            <div style={{ marginLeft: "300px" }}>
              <DatePicker
                allowClear={false}
                picker="year"
                onChange={handleChangeYear}
                value={moment(workSummaryYearContainer, "YYYY")}
                disabledDate={disable} />
            </div>}
          <div id="testHomeDropdown">
            <Select style={{ width: "90px", marginRight: "20px", marginLeft: "20px" }} defaultValue={"Week"} onChange={handleChange}
              getPopupContainer={() => document.getElementById("testHomeDropdown")}>
              <Option value="week">Week</Option>
              <Option value="month">Months</Option>
            </Select>
          </div>
        </div>
      </div>
      {usersDataContainer?.workingHoursSummary && usersDataContainer?.workingHoursSummary?.length != 0 ?
        <div
          style={{ width: "95%", height: "90%", padding: 20 }}
        >{workSummaryTypeContainer === "week" ?
          <TeachersWorkhrsGraph
            data={usersDataContainer?.workingHoursSummary}
            usersData={usersData}
            teacherGraph={teacherGraph}
            color="#9876E6" />
          :
          <>
            {
              sum != 0 ?
                <UsersWorkSummMonthGraph
                  data={usersDataContainer?.workingHoursSummary}
                />
                :
                <NodataCard />
            }
          </>

          }
        </div> :
        null}
    </div>
  );
};

const ReqCard = ({ userImage, profileName, statusTag, tag, time }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: "23px", height: "50px", justifyContent: "space-between", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ marginLeft: "20px", }}>
          <img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${userImage}`}></img>
        </div>
        <div style={{ marginLeft: "20px", }}>
          <div style={{ display: "flex", }}>
            <Typography.Text
              className="text-overflow-ellipsis"
              style={{ fontSize: "15px", fontWeight: "bold", width: "80px", cursor: "pointer" }}
              ellipsis={{ tooltip: true }}>{profileName}</Typography.Text >
            <div>
              <Tag color="#ffac47" style={{ fontWeight: "bold", border: "none", fontSize: "8px", borderRadius: "40px", marginLeft: "10px" }}>{statusTag}</Tag>
            </div>
          </div>
          <div>
            <Tag style={{
              color: `${tag === "Leave" ? " #1089FF" : "#594099"}`,
              backgroundColor: `${tag === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
              borderColor: `${tag === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
              fontSize: "10px",
              fontWeight: "bold",
            }}>{tag}</Tag>
          </div>
        </div>
      </div>
      <div className="text-overflow-ellipsis" style={{
        fontSize: "11px",
        color: "#636363",
        marginRight: "20px"
      }}>{moment.utc(time).local().format("DD/MM/YYYY • hh:mm A")}
      </div>
    </div>
  )
}

function RequestCard({
  usersDataContainer
}) {
  const history = useHistory();
  return (
    <div className='border-16 boxShadow118' style={{
      width: "95%",
      height: "380px",
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #E6E6E6",
      // borderRadius:"6px",
      marginTop: "20px",
      marginLeft: "20px"
    }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              color: "#191919",
              fontWeight: "bold",
              fontSize: "15px",
              padding: 10,
              marginLeft: 10,
              marginTop: "10px"
            }}
          >
            Request(s)
          </div>
          <div
            style={{
              fontWeight: "500",
              fontSize: "15px",
              color: "#AEAEAE",
              marginTop: "20px",
            }}
          >
            ({usersDataContainer?.count})
          </div>
        </div>
        <div style={{ color: "#1089FF", marginTop: "10px", cursor: "pointer", fontWeight: "bold", marginRight: 10, padding: 10 }}
          onClick={() => { history.push("/request-viewall") }}> VIEW ALL</div>
      </div>
      {usersDataContainer?.request?.length !== 0 ?
        <div className="flex-wrap " style={{ display: "flex" }}>
          {usersDataContainer?.request?.map((items) => (
            <ReqCard
              userImage={items.user.image}
              profileName={items.user.name}
              statusTag="NEW"
              tag={items.requestType}
              time={items.createdAt}
            />
          ))}
        </div>
        :
        <NodataCard />
      }
    </div>
  )
}


function TeachersCard({items, teacherImage, teacherName, teacherAttendancePercentage, status, userProfileId, setSpecificUserId, setClickedOnUserCard, setOthersSelectedTab ,setUserDetails }) {
  const history = useHistory()
  
  return (
    <div className="border-16 boxShadow118"
      style={{
        height: 90,
        backgroundColor: "#FFFFFF",
        border: "#E6E6E6 solid 1px",
        cursor: "pointer",
        width: 280,
        paddingRight: 10,
        margin: 10,
        padding: 15

      }}
      onClick={() => {

        history.push({
          pathname: "/user-management/profile",
          state: {
            key: true
          }
        });


        setUserDetails(items);
        setClickedOnUserCard(true)
        setSpecificUserId(userProfileId);
        setOthersSelectedTab('Basic Profile');
      }}
    >
      <div className='flex-row m-l-10 ' style={{ alignItems: "center", }}>
        <div className='flex-row'>
          <div><img style={{ width: "60px", height: "60px", borderRadius: "50%" }} src={`${Api._s3_url}${teacherImage}`}></img></div>
          <div style={{ color: `${status === "Present" ? "#28DF99" : "#FF414D"}`, fontSize: "25px", marginTop: "-15px", marginLeft: "-15px" }}>●</div>
        </div>
        <div className='m-l-15'>
          <Typography.Text className="text-overflow-ellipsis" style={{ fontSize: "15px", fontWeight: "bold", width: "150px" }} ellipsis={{ tooltip: true }}>{teacherName}</Typography.Text>
          <div style={{ fontSize: "13px" }}>{teacherAttendancePercentage}{"%"}</div>
        </div>
      </div>
    </div>

  )
}


function TeachersDashBoard({
  getUsersDashBoardData,
  usersDataContainer,
  usersNameContainer,
  setSpecificUserId,
  storeSummaryType,
  storeSummaryYear,
  storeUserSummaryStartDate,
  storeUserSummaryEndDate,
  storeWorkSummaryType,
  storeWorkSummaryYear,
  storeWorkSummaryStartDate,
  storeWorkSummaryEndDate,
  summaryStartDateContainer,
  summaryEndDateContainer,
  workSummaryStartDateCont,
  workSummaryEndDateCont,
  summaryTypeContainer,
  summaryYearContainer,
  workSummaryTypeContainer,
  workSummaryYearContainer,
  setBackToPrivilegedView,
  setClickedOnUserCard,
  setFromUsersGeofence,
  setOthersSelectedTab,
  setUserDetails
}) {

  const [pageLoader, togglePageLoader] = useState(false);
  let [sum, setSum] = useState(0)
  const teacherGraph = true;
  const history = useHistory();

  useEffect(() => {
    sum = 0;
    if (usersDataContainer?.workingHoursSummary?.length != 0) {
      usersDataContainer?.workingHoursSummary?.forEach(
        (item) => (
          (sum = sum + parseFloat(item?.duration))
        )
      )
    };
    setSum(sum)
  }, [usersDataContainer?.workingHoursSummary])


  useEffect(() => {
    togglePageLoader(true);
    storeSummaryType("week");
    storeWorkSummaryType("week");
    storeSummaryYear(moment().year());
    storeWorkSummaryYear(moment().year());
    storeUserSummaryStartDate(moment().startOf('week').format("YYYY-MM-DD"));
    storeUserSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"));
    storeWorkSummaryStartDate(moment().startOf('week').format("YYYY-MM-DD"));
    storeWorkSummaryEndDate(moment().subtract(1, 'days').format("YYYY-MM-DD"));
    getUsersDashBoardData(() => {
      togglePageLoader(false)
    });
  }, [])

  const onGeoFenceClick = () => {
    history.push("/attendance-geofence");
    setFromUsersGeofence(true);
  }

  const handleBackPress = () => {
    history.goBack();
    setBackToPrivilegedView(true);
  }

  const actions = [
    <Button type="primary" onClick={() => { onGeoFenceClick() }} style={{}} icon={<EnvironmentOutlined />}>GEO FENCE</Button>
  ]

  return (
    <div style={{
      background: "#FAFAFA",
      width: "calc(100% + 40px)",
      height: "calc(100% + 40px)",
      margin: -20,
      padding: 20,
      overflow: "auto",
    }}>
      <div style={{ marginTop: -10, height: 50 }}>
        <PageHeader title={`${usersNameContainer === "Teacher" ? "Teachers" : usersNameContainer === "Operation" ? "Operations" : usersNameContainer === "Data Operator" ? "Data Operators" : null}`} actions={actions} onBackPress={() => handleBackPress()} />
      </div>
      <Row>
        <Col md={{ span: 24 }} xl={{ span: 8 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <TeachersPieCard
            usersDataContainer={usersDataContainer}
            usersNameContainer={usersNameContainer} />
        </Col>
        <Col md={{ span: 24 }} xl={{ span: 16 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <TeacherBarCard
            storeSummaryType={storeSummaryType}
            storeSummaryYear={storeSummaryYear}
            storeUserSummaryStartDate={storeUserSummaryStartDate}
            storeUserSummaryEndDate={storeUserSummaryEndDate}
            summaryStartDateContainer={summaryStartDateContainer}
            summaryEndDateContainer={summaryEndDateContainer}
            summaryTypeContainer={summaryTypeContainer}
            summaryYearContainer={summaryYearContainer}
            getUsersDashBoardData={getUsersDashBoardData}
            usersDataContainer={usersDataContainer}
            togglePageLoader={togglePageLoader}
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 24 }} xl={{ span: 15 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <TeachersWorkHrsCard
            getUsersDashBoardData={getUsersDashBoardData}
            storeWorkSummaryType={storeWorkSummaryType}
            storeWorkSummaryStartDate={storeWorkSummaryStartDate}
            storeWorkSummaryEndDate={storeWorkSummaryEndDate}
            storeWorkSummaryYear={storeWorkSummaryYear}
            workSummaryStartDateCont={workSummaryStartDateCont}
            workSummaryEndDateCont={workSummaryEndDateCont}
            workSummaryTypeContainer={workSummaryTypeContainer}
            workSummaryYearContainer={workSummaryYearContainer}
            usersDataContainer={usersDataContainer}
            togglePageLoader={togglePageLoader}
            teacherGraph={teacherGraph}
            sum={sum}
            color={"#9876E6"} />
        </Col>
        <Col md={{ span: 24 }} xl={{ span: 9 }} sm={{ span: 24 }} lg={{ span: 24 }}>
          <RequestCard
            usersDataContainer={usersDataContainer} />
        </Col>
      </Row>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Heading className="m-t-20">
          {usersNameContainer === "Teacher" ? "All teachers" :
            usersNameContainer === "Operation" ? "All operations" :
              usersNameContainer === "Data Operator" ? "All data operators" : null}

        </Heading>
        <div onClick={() => { history.push("/allusers-view-all") }} style={{ color: "#1089FF", marginTop: "20px", cursor: "pointer", fontWeight: "bold" }}> VIEW ALL</div>
      </div>
      <div className="flex-wrap " style={{ display: "flex", marginLeft: "-10px" }}>
        {usersDataContainer?.users?.map((items) => (
          <TeachersCard
          items={items}
            teacherImage={items.image}
            teacherName={items.name}
            teacherAttendancePercentage={items.attendancePercentage}
            status={items.attendanceHistoryForToday}
            userProfileId={items.id}
            setSpecificUserId={setSpecificUserId}
            setClickedOnUserCard={setClickedOnUserCard}
            setOthersSelectedTab={setOthersSelectedTab}
            setUserDetails={setUserDetails}
          />
        ))}
      </div>
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    usersDataContainer,
    usersNameContainer,
    summaryTypeContainer,
    summaryYearContainer,
    summaryStartDateContainer,
    summaryEndDateContainer,
    workSummaryTypeContainer,
    workSummaryYearContainer,
    workSummaryStartDateCont,
    workSummaryEndDateCont } =
    state.attendanceNew;
  return {
    usersDataContainer,
    usersNameContainer,
    summaryTypeContainer,
    summaryYearContainer,
    summaryStartDateContainer,
    summaryEndDateContainer,
    workSummaryTypeContainer,
    workSummaryYearContainer,
    workSummaryStartDateCont,
    workSummaryEndDateCont
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsersDashBoardData: (callback) => (dispatch(getUsersDashBoardData(callback))),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  storeSummaryType: (val) => dispatch(storeSummaryType(val)),
  storeSummaryYear: (val) => dispatch(storeSummaryYear(val)),
  storeUserSummaryStartDate: (val) => dispatch(storeUserSummaryStartDate(val)),
  storeUserSummaryEndDate: (val) => dispatch(storeUserSummaryEndDate(val)),
  storeWorkSummaryType: (val) => dispatch(storeWorkSummaryType(val)),
  storeWorkSummaryYear: (val) => dispatch(storeWorkSummaryYear(val)),
  storeWorkSummaryStartDate: (val) => dispatch(storeWorkSummaryStartDate(val)),
  storeWorkSummaryEndDate: (val) => dispatch(storeWorkSummaryEndDate(val)),
  setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
  setClickedOnUserCard: (val) => dispatch(setClickedOnUserCard(val)),
  setFromUsersGeofence: (bool) => dispatch(setFromUsersGeofence(bool)),
  setOthersSelectedTab: tab => dispatch(setOthersSelectedTab(tab)),
  setUserDetails: res => dispatch(setUserDetails(res)),

});

export default connect(mapStateToProps, mapDispatchToProps)(TeachersDashBoard);