import React from 'react'
import './styles.css'
import totalBatches from "../../../../Assets/courseManagementV4/totalBatches.svg"
import totalSections from "../../../../Assets/courseManagementV4/totalSections.svg";
import classesScheduled from "../../../../Assets/courseManagementV4/classesScheduled.svg"
import testScheduled from "../../../../Assets/courseManagementV4/testScheduled.svg"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Information, Title, Title2 } from 'components/Typography';
import { Button } from 'components/Button';

function BatchesCard({ moreHeight, batchDetails, hasMarketingPrivilege }) {
    const history = useHistory()
    const batchesArray = [
        {
            image: totalBatches,
            count: parseInt(batchDetails?.standardCount) || 0,
            desc: "total batches"
        },
        {
            image: totalSections,
            count: parseInt(batchDetails?.sectionCount) || 0,
            desc: "total sections"
        },
        {
            image: classesScheduled,
            count: parseInt(batchDetails?.testCount) || 0,
            desc: "tests scheduled"
        },
        {
            image: testScheduled,
            count: parseInt(batchDetails?.scheduledCount) || 0,
            desc: "classes scheduled"
        },
    ]
    return (
        <div className='recently-added-card' style={{ width: "100%", alignItems: "center" }}>
            <div className='r-c-sb'>
                <Title>Batches</Title>
                {hasMarketingPrivilege ? <></> :
                    <Button type="link" onClick={() => { history.push({ pathname: "/classroom", state: { key: "fromCourseHome" } }) }}>VIEW ALL</Button>
                }
            </div>
            <div className='r-jsb' style={{ flexWrap: "wrap", width: "100%" }}>
                {batchesArray?.map((item, index) => (
                    <div className='r-ac m-t-10' style={{ width: "50%", marginBottom: (index == 0 || index == 1) && moreHeight ? 15 : (index == 0 || index == 1) ? 5 : 0 }}>
                        <img src={item?.image} className='batches-icon-size' />
                        <div className='m-l-10'>
                            <div><Title2>{item?.count}</Title2></div>
                            <div><Information>{item?.desc}</Information></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BatchesCard