import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, DragOutlined, EditOutlined, FilterFilled, RightOutlined, SearchOutlined, SettingOutlined, ShareAltOutlined, StarFilled, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { AutoComplete, Checkbox, Dropdown, Menu, Popover, Tag, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import filterIcon from "../../Assets/aulasMarket/filterIC.svg"
import { Button } from 'components/Button';
import "./styles.css"
import { Api } from 'services';
import { MdMoreVert } from 'react-icons/md';
import RatingStars from 'pages/studentMarketPlace/ratingStars';
import moment from 'moment';
import { connect } from 'react-redux';
import { getCoursesCategories, getCoursesSubCategories, getStudyMaterialsList, getTotalCoursesList, setAllCoursesPageParamsObj, storeSingleCourseId, getTotalsVideoResource, setCourseTypeName, getAutocompleteSearch, getSearchResultsData, setSearchResultPageParamsObj, storeAutocompleteSearchData, getCourseLoadMore, getVideoResourceLoadMore, getStudyMaterialLoadMore, getCourseStats, getOnlineTestSeries, getOnlineTestSeriesCourseDetails, getTestsLoadMore, storeSpecificCourseData } from 'actions/courseManagement';
import PageDataLoader from 'components/pageDataLoader';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import { getCategoryList, getResourceList } from 'actions/marketPlaceMenu';
import PageHeader from 'components/pageHeader';
import MoveModal from 'pages/adminMarketPlaceCourse/moveModal';
import { IMG_URL } from "../../env.json";
import DeleteModal from 'pages/adminMarketPlaceCourse/deleteModal';
import ConfirmPublishOrUnpublishModal from 'pages/adminMarketPlaceCourse/confirmPublishOrUnpublishModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setGsSelectedTab } from 'actions/profileNew';
import defaultImg from "../../Assets/default_course-img.svg"
import { getMarketSettingsData } from 'actions/studentMarketPlace';
import NoDataCard from 'pages/digitalLibrary/components/noDataCard';
import { showMenu } from 'actions/layout';
const defaultCourseImage = require('Assets/default_course-img.svg').default;



function MarketPlace({ courseTypeName, user, getMarketSettingsData, setGsSelectedTab, storeSpecificCourseData, specificCourseDataContainer, getCategoryList, getResourceList, getCoursesCategories, courseCategoryData, getCoursesSubCategories, courseSubCategoriesData, getTotalCoursesList, setAllCoursesPageParamsObj, totalCoursesDataContainer, storeSingleCourseId, courseStatsData, courseCreateObj, getStudyMaterialsList, getTotalsVideoResource, setCourseTypeName, getAutocompleteSearch, autocompleteSearchData, getSearchResultsData, searchResultData, setSearchResultPageParamsObj, searchPageParamsObj, storeAutocompleteSearchData, getCourseLoadMore, getVideoResourceLoadMore, getStudyMaterialLoadMore, getCourseStats, getOnlineTestSeries, storeCategoryData, getTestsLoadMore,showMenu }) {
    const [isFilterCategoryClicked, setFilterCategoryClicked] = useState(false);
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const history = useHistory();
    const location = useLocation();

    console.log("fromMarketPlaceMenu", user)
    const [filtersKey, setFiltersKey] = useState(null);

    const sortBy = [{ label: "Newest", value: "newest" }, { label: "Oldest", value: "oldest" }, { label: "Alphabetic", value: "name" }]
    const [selectedTypes, setSelectedTypes] = useState([
        { label: "All", value: null, selected: true },
        { label: "Live Course", value: "course", selected: false },
        { label: "Video Course", value: "videoResource", selected: false },
        { label: "Study Material", value: "studyMaterial", selected: false },
        { label: "Test Series", value: "testSeries", selected: false }
    ]);
    const [selectedStatus, setSelectedStatus] = useState([{ label: "All", value: null, selected: true }, { label: "Published", value: true, selected: false }, { label: "Unpublished", value: false, selected: false }]);


    const status = [{ label: "All", value: null }, { label: "Published", value: true }, { label: "Unpublished", value: false }, /* { label: "Deleted", value: "active" } */]
    const [filterParam, setFilterParam] = useState({
        "sort": "newest",
        "type": null,
        "activeStatus": null,
    })
    const [isSubCategoryVisible, setSubCategoryVisible] = useState(false);
    const [isCoursesCardsVisible, setCoursesCardsVisible] = useState(false);
    const [pageLoader, togglePageLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSeletedSubCategory] = useState({});
    const [isCreateOrEditCategoryVisible, setIsCreateOrEditCategoryVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSubCategory, setIsSubCategory] = useState(false);
    const [selectedUnpublishCards, setSelectedUnpublishCards] = useState([]);
    const [selectedPublishCards, setSelectedPublishCards] = useState([]);
    const [selectedCategoryCards, setSelectedCategoryCards] = useState([]);
    const [selectedSubCategoryCards, setSelectedSubCategoryCards] = useState([]);
    const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false);

    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [showCourseTypeDropdown, setCourseTypeDropdown] = useState(false);
    const [showCategoriesDropdown, setCategoriesDropdown] = useState(false);
    const [showSubcategoriesDropdown, setSubcategoriesDropdown] = useState(false);
    const [isFilterSubCategoryClicked, setFilterSubCategoryClicked] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [previousCourseType, setPreviousCourseType] = useState(null);
    const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null);
    const [renderPage, setRenderPage] = useState(false);

    const [categoryList, setCategoryList] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [resourceParams, setResourceParams] = useState({
        "page": 1,
        "limit": 12,
        "subcategoryId": null,
        "published": null,
        "activeStatus": null,
        "search": null,
        "type": null,
        "sort": "newest",
        "types": [],
    });
    const hasMarketingPrivilege = user?.privilage?.includes('Marketing') && !user?.privilage?.includes('Admin');

    console.log("filterParam", specificCourseDataContainer)

    useEffect(() => {
        togglePageLoader(true);
        getCategoryList((res) => { setCategoryList(res); togglePageLoader(false); });
        getResourceList(resourceParams, (res) => { setResourceList(res); togglePageLoader(false); });
        getMarketSettingsData(true, (res) => { console.log("settingsss", res); setFiltersKey(res) })
    }, [location?.state?.key?.fromMarketPlaceMenu, renderPage])

    useEffect(() => {
        togglePageLoader(true);
        getResourceList(resourceParams, (res) => { setResourceList(res); togglePageLoader(false) });
    }, [resourceParams])

    useEffect(()=>{
        showMenu(true);
        setAutocompleteSearchedValue(null);
    },[])

    console.log("resourceList", resourceParams);

    const handleChangeCategory = (cId, category, subCategory) => {
        console.log("checkidsss", cId, category, subCategory)
        setResourceParams({ ...resourceParams, subcategoryId: cId })
        setIsCategoryClicked(false)

    }
    const filterSubCategoryMenu = (category, index) => {
        return (
            category?.children && (
                <Menu className='scroll-bar-universal' style={{ height: "fit-content", backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 230, borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", margin: index == 0 ? "40px 0px 0px -2px" : "0px 0px 0px -2px" }}>
                    {category?.children?.map((subcategory) => (
                        <div className='dropmenuBp' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 45 }} onClick={() => { handleChangeCategory(subcategory?.id, category?.name, subcategory?.name); }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: 210, fontWeight: 550 }} >{subcategory?.name}</Typography.Text>
                        </div>
                    ))}
                </Menu>
            )
        );
    }

    const userCategoryMenu = (
        <Menu className='scroll-bar-universal' style={{ height: categoryList?.length > 5 ? 280 : "fit-content", backgroundColor: "#FFFFFF", boxShadow: " 4px 4px 16px #00000014", width: 230, borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop: -3 }}>
            {categoryList?.map((category, index) => {
                return (
                    <>
                        {category?.children?.length > 0 ?
                            <Dropdown overlay={filterSubCategoryMenu(category, index)} placement='right' trigger={['hover']}>
                                <div className='dropmenuBp' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 45 }}>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", width: 210, fontWeight: 550 }}>{category?.name}</Typography.Text>
                                    {category?.children?.length > 0 ?
                                        <RightOutlined style={{ color: "#AEAEAE" }} />
                                        : null}
                                </div>
                            </Dropdown>
                            :
                            null
                        }
                    </>
                )
            }
            )}
        </Menu>
    )
    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible);
    };
    const [isSearchActive, setSearchActive] = useState();


    const renderTitle = (title) => (
        <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
    );

    const searchOptions = [
        // {
        //     // label: autocompleteSearchData?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
        //     options: autocompleteSearchData?.suggestions?.length ? autocompleteSearchData?.suggestions?.map((item) => ({
        //         label: (
        //             <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => {
        //                 setCourseTypeName("searchResult");
        //                 history.push("/search-result");
        //                 setSelectedSuggestion(item);
        //                 setSearchedSuggestionClicked(true);
        //                 setLoadMorePage({
        //                     courseLoadMore: 1,
        //                     videoLoadMore: 1,
        //                     studyLoadMore: 1,
        //                     testsLoadMore: 1
        //                 });
        //                 resetValues();
        //                 setSearchResultPageParamsObj("search", item);
        //                 togglePageLoader(true);
        //                 getSearchResultsData(() => togglePageLoader(false));
        //             }}>
        //                 <SearchOutlined /><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item}</Typography.Text>
        //             </div>
        //         ),
        //     }))
        //         :
        //         []
        // },
        {
            label: autocompleteSearchData?.resources?.length != 0 ? renderTitle("Courses") : null,
            options: autocompleteSearchData?.resources?.length ? autocompleteSearchData?.resources?.map((item) => ({
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => { setCourseTypeName(item?.type == "course" ? "liveCourses" : item?.type == "videoResource" ? "videoResources" : item?.type == "studyMaterial" ? "studyMaterials" : item?.type == "onlineTestSeries" ? "onlineTestSeries" : null); storeSingleCourseId(item?.id); history.push({ pathname: "/course-details", state: { key: { fromMarketPlaceMenu: true } } }) }}>
                        { <img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 70, height: 40 }} />}
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item?.name}</Typography.Text>
                    </div>
                ),
            }))
                :
                []
        },
    ];
    const resetValues = () => {
        // setSearchResultPageParamsObj("startDate", null);
        // setSearchResultPageParamsObj("endDate", null);
        // setSearchResultPageParamsObj("sort", null);
        // setSearchResultPageParamsObj("mode", null);
        // setSearchResultPageParamsObj("type", null);
        // setSearchResultPageParamsObj("status", null);
        // setSearchResultPageParamsObj("durationFilter", null);
        // setSearchResultPageParamsObj("page", 1);
        // setSearchResultPageParamsObj("feeType", null);
    }
    // const handleAutocompleteSearch = (value) => {
    //     console.log("auotttt", value);
    //     setResourceParams({ ...resourceParams, search: value });
    // }
    const handleAutocompleteSearch = (value) => {
        setAutocompleteSearchedValue(value);
        setSearchLoader(true);
        getAutocompleteSearch(value, () => { setSearchLoader(false) })
    }
    const handleApplyFilter = () => {
        // Filter selected types and extract their values
        const selectedTypeValues = selectedTypes.filter(type => type.selected).map(type => type.value);
        const selectedStatusValues = selectedStatus.filter(status => status.selected).map(status => status.value);

        console.log("seleeedd", selectedTypeValues)
        setResourceParams({
            ...resourceParams,
            published: selectedStatusValues.includes(null) ? null : selectedStatusValues,
            sort: filterParam?.sort,
            type: filterParam?.type,
            activeStatus: filterParam?.activeStatus == "active" ? "inactive" : null,
            limit: 12,
            types: selectedTypeValues.includes(null) ? null : selectedTypeValues, // Send only the selected type values
        });

        setDropdownVisible(false);
    }


    const SortByFilter = () => {
        const handleClassToggle = (val) => {

            setFilterParam({
                ...filterParam,
                sort: val,
            })
        }
        return (
            <div className='r-c-c-fs' >
                <FilterTitleName filterName="Sort by" />
                <div className='r-c-fs' style={{}}>
                    {sortBy?.map((item) => (
                        <div className='sortByFilter' style={{ backgroundColor: filterParam?.sort === item?.value ? "#D6C8F5" : '#FFFFFF', color: filterParam?.sort === item?.value ? '#191919' : "#636363", border: filterParam?.sort === item?.value ? 'none' : '1px solid #E6E6E6', fontWeight: filterParam?.sort === item?.value ? "650" : "normal", width: "25%", height: "35px" }} onClick={() => { handleClassToggle(item?.value) }}>{item?.label}</div>
                    ))}
                </div>
            </div>
        )
    }
    const FilterTitleName = ({ filterName }) => {
        return (
            <div style={{ color: '', fontSize: 14, fontWeight: "bold", marginBottom: "5px" }}>
                {filterName}
            </div>
        )
    }

    // const TypeFilter = () => {
    //     const handleTypeToggle = (val) => {
    //         setFilterParam({
    //             ...filterParam,
    //             type: val
    //         })
    //     }
    //     const shouldShowType = (type) => {
    //         switch (type) {
    //             case "course":
    //                 return true;
    //             case "videoResource":
    //                 return filtersKey?.videoResourceSelected;
    //             case "studyMaterial":
    //                 return filtersKey?.studyMaterialSelected;
    //             case "testSeries":
    //                 return filtersKey?.onlineTestSelected;
    //             default:
    //                 return true; // Always show "All"
    //         }
    //     };
    //     return (
    //         <div className=''>
    //             <FilterTitleName filterName="Course type" />
    //             <div style={{ display: "flex", flexWrap: "wrap", }}>
    //                 {Types.map((item) => (
    //                     shouldShowType(item.value) && (
    //                         <div className='sortByFilter' key={item.value}
    //                             style={{ backgroundColor: filterParam?.type === item?.value ? "#D6C8F5" : '#FFFFFF', color: filterParam?.type === item?.value ? '#191919' : "#636363", border: filterParam?.type === item?.value ? 'none' : '1px solid #E6E6E6', fontWeight: filterParam?.type === item?.value ? "650" : "normal", width: item.value != null ? "25%" : "12%", }}
    //                             onClick={() => handleTypeToggle(item.value)}>
    //                             {item.label}
    //                         </div>
    //                     )
    //                 ))}
    //             </div>
    //         </div>
    //     )
    // }




    const TypeFilter = () => {

        const handleTypeToggle = (val) => {
            let updatedSelectedTypes = [...selectedTypes];

            if (val === null) {
                updatedSelectedTypes = updatedSelectedTypes.map((type, index) => ({
                    ...type,
                    selected: index === 0 ? true : false // Select "All", deselect others
                }));
            } else {
                updatedSelectedTypes = updatedSelectedTypes.map(type => {
                    if (type.value === val) {
                        return { ...type, selected: !type.selected };
                    }
                    return type;
                });

                // Check if any individual option is toggled, then deselect "All"
                updatedSelectedTypes[0].selected = false;
            }

            setSelectedTypes(updatedSelectedTypes);
        };



        const shouldShowType = (type) => {
            switch (type) {
                case "course":
                    return true;
                case "videoResource":
                    return filtersKey?.videoResourceSelected;
                case "studyMaterial":
                    return filtersKey?.studyMaterialSelected;
                case "testSeries":
                    return filtersKey?.onlineTestSelected;
                default:
                    return true; // Always show "All"
            }
        };

        return (
            <div className=''>
                <div className="FilterTitleName">Course type</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedTypes.map((item) => (
                        shouldShowType(item.value) && (
                            <div className='sortByFilter' key={item.value}
                                style={{
                                    backgroundColor: item.selected ? "#D6C8F5" : '#FFFFFF',
                                    color: item.selected ? '#191919' : "#636363",
                                    border: item.selected ? 'none' : '1px solid #E6E6E6',
                                    fontWeight: item.selected ? "650" : "normal",
                                    width: item.value != null ? "25%" : "12%",
                                }}
                                onClick={() => handleTypeToggle(item.value)}>
                                {item.label}
                            </div>
                        )
                    ))}
                </div>
            </div>
        )
    }

    // const StatusFilter = () => {
    //     const handleStatusToggle = (val, key) => {
    //         console.log("statusdd", val, key);
    //         setFilterParam({
    //             ...filterParam,
    //             activeStatus: val
    //         })

    //     }
    //     return (
    //         <div className=''>
    //             <FilterTitleName filterName="Status" />
    //             <div style={{ display: "flex", flexWrap: "wrap" }}>
    //                 {status?.map((item) => (
    //                     <div className='sortByFilter' style={{ backgroundColor: filterParam?.activeStatus === item?.value ? "#D6C8F5" : '#FFFFFF', color: filterParam?.type !== item?.activeStatus ? '#191919' : "#636363", border: filterParam?.activeStatus === item?.value ? 'none' : '1px solid #E6E6E6', fontWeight: filterParam?.activeStatus === item?.value ? "650" : "normal", width: item.value != null ? "25%" : "12%", }} onClick={() => { handleStatusToggle(item?.value, item?.label); }}>{item?.label}</div>
    //                 ))}
    //             </div>
    //         </div>
    //     )
    // }


    const StatusFilter = () => {

        const handleStatusToggle = (val, key) => {
            console.log("allssddsd", val)
            let updatedStatus = [...selectedStatus];
            if (val === null) {
                updatedStatus = updatedStatus.map((status, index) => ({
                    ...status,
                    selected: index === 0 ? !status.selected : false  // Select "All", deselect others
                }))
            } else {
                updatedStatus = updatedStatus.map(status => {
                    if (status.value === val) {
                        return { ...status, selected: !status.selected };
                    }
                    return status;
                });
                // Check if "All" should be selected or deselected based on other selections
                updatedStatus[0].selected = false;
            }
            setSelectedStatus(updatedStatus);
        };

        return (
            <div className=''>
                <div className="FilterTitleName">Status</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedStatus.map((item) => (
                        <div className='sortByFilter' key={item.value}
                            style={{
                                backgroundColor: item.selected ? "#D6C8F5" : '#FFFFFF',
                                color: item.selected ? '#191919' : "#636363",
                                border: item.selected ? 'none' : '1px solid #E6E6E6',
                                fontWeight: item.selected ? "650" : "normal",
                                width: item.value != null ? "25%" : "12%",
                            }}
                            onClick={() => handleStatusToggle(item.value, item?.key)}>
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const filterDropDownMenu = (
        <Menu visible={dropdownVisible} className='filterParent-1' style={{ width: "460px" }}>
            <SortByFilter />
            <TypeFilter />
            <StatusFilter />
            {/* <DateFilter /> */}
            <div style={{ borderBottom: '1px solid #E6E6E6', marginTop: "20px" }}></div>
            <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "10px" }}>
                <div style={{ color: "#594099", fontWeight: 600, fontSize: "16px", marginTop: "5px", fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                        setFilterParam({
                            sort: "newest",
                            type: null,
                            activeStatus: null,
                        });
                        setResourceParams({
                            page: 1,
                            limit: 12,
                            subcategoryId: null,
                            published: null,
                            activeStatus: null,
                            search: null,
                            type: null,
                            sort: "newest",
                            types: null,
                        });
                        setSelectedTypes([
                            { label: "All", value: null, selected: true },
                            { label: "Live Course", value: "course", selected: false },
                            { label: "Video Course", value: "videoResource", selected: false },
                            { label: "Study Material", value: "studyMaterial", selected: false },
                            { label: "Test Series", value: "testSeries", selected: false }
                        ]);
                        setSelectedStatus([
                            { label: "All", value: null, selected: true },
                            { label: "Published", value: true, selected: false },
                            { label: "Unpublished", value: false, selected: false }
                        ]);
                        setDropdownVisible(false);
                    }}
                >RESET</div>

                <Button style={{ color: "#fff", backgroundColor: '#594099', border: "none", borderRadius: "5px" }} onClick={handleApplyFilter}>APPLY</Button>
            </div>
        </Menu>
    );
    const actions = [
        <div className='' style={{ width: "100%" }}>
            <Dropdown overlay={userCategoryMenu} trigger={['hover']} open={isCategoryClicked}>
                <div className='r-c-sb p-15' style={{ color: "#191919", borderRadius: "23px", height: "35px", backgroundColor: "#FFFFFF", fontSize: 14, marginRight: 30, cursor: "pointer", border: "1px solid #E6E6E6", width: "200px" }} onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(!isCategoryClicked); setIsProfileClicked(false) }}>Categories
                    <div>{isCategoryClicked ? <CaretUpOutlined style={{ color: "#AEAEAE", marginLeft: 10 }} /> : <CaretDownOutlined style={{ color: "#AEAEAE", marginLeft: 10 }} />}</div>
                </div>
            </Dropdown>
        </div>,
        <div style={{ width: "100%", marginRight: isSearchActive ? "20px" : "" }}>
            {/* <AutoComplete className='round-bordered-search'
                onClear={() => {
                    handleAutocompleteSearch('');
                }}
                onChange={(val) => setSearchActive(val)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleAutocompleteSearch(e.target.value);
                    }
                }}
                allowClear style={{ marginRight: 20, width: 250 }} suffixIcon={resourceParams?.search ? <FilterFilled /> : ""} showSearch={true} placeholder={<span style={{ fontWeight: "normal" }}><SearchOutlined /> Search</span>} /> */}
            <AutoComplete
                allowClear
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={500}
                style={{ marginRight: 20, width: 250, }}
                options={(autocompleteSearchData?.suggestions?.length > 0 || autocompleteSearchData?.resources?.length) && autocompleteSearchedValue ? searchOptions : []}
                onSearch={handleAutocompleteSearch}
                onChange={(val) => setSearchActive(val)}
                onClear={() => {
                    // setSelectedSuggestion(null);
                    setAutocompleteSearchedValue(null);
                    // setSearchedSuggestionClicked(false);
                    resetValues();
                    setCourseTypeName(previousCourseType);
                    // history.push(previousCourseType == "liveCourses" ? "/live-courses-data" : previousCourseType == "videoResources" ? "/video-resources-data" : previousCourseType == "onlineTestSeries" ? "/online-test-series-details" : previousCourseType == "studyMaterials" ? "/study-materials-data" : "/live-courses-data")
                    // if (location?.state?.key?.fromDashboardSearch) {
                    //     history.push("/courses")
                    // } else if (selectedCategory?.id) {
                    //     setSubCategoryVisible(true)
                    // } else if (selectedSubCategory?.id) {
                    //     setCoursesCardsVisible(false)
                    // }
                }}
                value={autocompleteSearchedValue ? autocompleteSearchedValue : null}
                placeholder={<span><SearchOutlined /> Search</span>}
                loading={searchLoader}
                className='round-bordered-search'
            >
            </AutoComplete>
        </div>
        ,
        <div style={{ width: "100%", position: "relative", right: "50px" }}>
            {!isSearchActive ?
                <Dropdown
                    overlay={filterDropDownMenu}
                    visible={dropdownVisible}
                    onVisibleChange={handleDropdownVisibleChange}
                    trigger={['click']}
                    placement='bottomRight'
                    overlayStyle={{ width: "480px" }}
                >
                    <div className='' style={{ width: "100%", cursor: "pointer" }}>
                        <img src={filterIcon} style={{ width: 18, position: "relative", top: 5 }} />
                    </div>
                </Dropdown>
                : null}
        </div>,
        <div className='r-c-sb' style={{ height: "45px", alignItems: "flex-start", width: "100%", marginRight: "20px" }}>
            {/* <div className='r-c-c m-r-10' style={{ width: "60px", height: "33px", borderRadius: "20px", border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF" }}>
                <div className='r-c-c' style={{ fontSize: "14px", color: "#594099", width: "100%" }} onClick={() => { }} ><span> <UnorderedListOutlined style={{ marginRight: "5px", color: "#636363", fontSize: "14px" }} /></span></div>
            </div> */}
            {user?.privilage?.includes("SuperAdmin") ?
                <div style={{}}>
                    <Button type="secondary" style={{ fontSize: "14px", padding: "15px", color: "#594099" }} onClick={() => { history.push({ pathname: "/general-settings", state: { fromMarketPlaceMenu: true } }); setGsSelectedTab("Marketplace") }} ><span> <SettingOutlined style={{ marginRight: "5px", color: "#594099" }} /></span>CUSTOMIZE</Button>
                </div>
                : null}
        </div >,
        <div className='r-c-sb' style={{ height: "45px", alignItems: "flex-start", width: "100%", marginRight: "20px" }}>
            <div style={{}}>
                <Button type="secondary" style={{ fontSize: "14px", padding: "15px", color: "#594099" }} onClick={() => { window.open("/home", "_blank"); }} ><span> </span>PREVIEW</Button>
            </div>
        </div>



    ]

    const CourseCardContent = (id, resourceTypes, item) => (

        
        
        <div style={{ borderRadius: 12 }}>

            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer" }}
            //  onClick={(e) => {
            //     e.stopPropagation()
            //     storeSingleCourseId(id);
            //     setCourseTypeName(resourceTypes == "course" ? "liveCourses" : resourceTypes == "videoResource" ? "videoResources" : resourceTypes == "studyMaterial" ? "studyMaterials" : resourceTypes == "testSeries" ? "onlineTestSeries" : null);
            //     history?.push(resourceTypes == "course" ?
            //         {
            //             pathname: "/add-live-courses", state: {
            //                 key: {
            //                     isEdit: "isFromEdit",
            //                     fromCourseCard: {
            //                         isCoursesCardsVisible: isCoursesCardsVisible,
            //                         selectedCategory: selectedCategory,
            //                         selectedSubCategory: selectedSubCategory
            //                     },
            //                     fromMarketPlaceMenu: true
            //                 }
            //             }
            //         } :
            //         resourceTypes == "videoResource" ?
            //             {
            //                 pathname: "/add-video-resources", state: {
            //                     key: {
            //                         isEdit: "isFromEdit",
            //                         fromCourseCard: {
            //                             isCoursesCardsVisible: isCoursesCardsVisible,
            //                             selectedCategory: selectedCategory,
            //                             selectedSubCategory: selectedSubCategory
            //                         },
            //                         fromMarketPlaceMenu: true
            //                     }
            //                 }
            //             } :
            //             resourceTypes == "testSeries" ?
            //                 {
            //                     pathname: "/add-online-test-series", state: {
            //                         key: {
            //                             isEdit: "isFromEdit",
            //                             fromCourseCard: {
            //                                 isCoursesCardsVisible: isCoursesCardsVisible,
            //                                 selectedCategory: selectedCategory,
            //                                 selectedSubCategory: selectedSubCategory
            //                             },
            //                             fromMarketPlaceMenu: true
            //                         }
            //                     }
            //                 } :
            //                 resourceTypes == "studyMaterial" ?
            //                     {
            //                         pathname: "/add-study-materials", state: {
            //                             key: {
            //                                 isEdit: "isFromEdit",
            //                                 fromCourseCard: {
            //                                     isCoursesCardsVisible: isCoursesCardsVisible,
            //                                     selectedCategory: selectedCategory,
            //                                     selectedSubCategory: selectedSubCategory
            //                                 },
            //                                 fromMarketPlaceMenu: true
            //                             }
            //                         }
            //                     } : "/add-live-courses")

            // }}

            onClick={(e) => {
                e.stopPropagation();
                storeSingleCourseId(id);
                setCourseTypeName(resourceTypes == "course" ? "liveCourses" : resourceTypes == "videoResource" ? "videoResources" : resourceTypes == "studyMaterial" ? "studyMaterials" : resourceTypes == "testSeries" ? "onlineTestSeries" : null)
                history.push({
                    pathname: "/course-details",
                    state: {
                        key: {
                            fromCourseCard: {
                                                isCoursesCardsVisible: isCoursesCardsVisible,
                                                selectedCategory: selectedCategory,
                                                selectedSubCategory: selectedSubCategory
                                            },
                            fromMarketPlaceMenu: true
                        }
                    }
                })
            }}

            
            ><EditOutlined style={{ marginRight: 8 }} />Edit</div>
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => {
        

                e.stopPropagation();
                setCourseTypeName(resourceTypes == "course" ? "liveCourses" : resourceTypes == "videoResource" ? "videoResources" : resourceTypes == "studyMaterial" ? "studyMaterials" : resourceTypes == "testSeries" ? "onlineTestSeries" : null);
                setIsDeleteModalVisible(true);
                setSelectedPublishCards([id]);
                setSelectedUnpublishCards([id]);
                storeSpecificCourseData(item)
            }}>
                <DeleteOutlined style={{ marginRight: 8 }} />Delete </div>
            <div style={{ color: "#636363", fontSize: 16, fontWeight: 600, cursor: "pointer", marginTop: 10 }} onClick={(e) => {
                
                e.stopPropagation();
                setCourseTypeName(resourceTypes == "course" ? "liveCourses" : resourceTypes == "videoResource" ? "videoResources" : resourceTypes == "studyMaterial" ? "studyMaterials" : resourceTypes == "testSeries" ? "onlineTestSeries" : null);
                setIsMoveModalVisible(true);
                setSelectedPublishCards([id]); setSelectedUnpublishCards([id])
            }}><DragOutlined style={{ marginRight: 8 }} />Move </div>
        </div>
    );


    const LiveCourseCard = ({ item, index, reference, fromSearchCourse, fromSearchVideo, fromSearchStudy, fromSearchTest }) => {
        const getAdjustedHour = (duration) => {
    
            const [hours, minutes] = duration?.split(':').map(Number);

            if (minutes > 30) {
              return hours + 1;
            } else {
              return hours;
            }
            
            
          };
          const isSelectedCard = 
             (
              (fromSearchCourse && reference === 'unpublished')
                  ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                  : (fromSearchCourse && reference === 'published')
                      ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                      : (fromSearchVideo && reference === 'unpublished')
                          ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                          : (fromSearchVideo && reference === 'published')
                              ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                              : reference === 'unpublished'
                                  ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                  : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
          );
          
          console.log('isSelectedCardaa',isSelectedCard);
          
        return (
            // <div className={`${(fromSearchCourse || fromSearchVideo) ? 'search-live-course-card' : 'zoom live-course-card'}`}
            <div className='new-course-card' style={{ backgroundColor: 
                courseTypeName === 'onlineTestSeries'
                  ? (
                    isSelectedCard ? "#EDE9F6" : "#ffffff"
                    )
                  : (
                      (fromSearchCourse && reference === "unpublished") 
                        ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                        : (fromSearchCourse && reference === "published") 
                          ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                        : (fromSearchVideo && reference === "unpublished") 
                          ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                          : (fromSearchVideo && reference === "published") 
                            ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                            : reference === "unpublished" 
                              ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                              : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                    )
                  ? "#EDE9F6" 
                  : "#ffffff"
                ,padding:15, cursor:'pointer', borderRadius:10, width:'31.9%', boxShadow: '4px 4px 16px #0000000F',  marginBottom: '2.1%'}}
                onClick={(e) => {
                                        if (!item?.deleted) {
                                            e.stopPropagation();
                                            storeSingleCourseId(item?.id);
                                            // if (isSearchedSuggestionClicked) {
                                            setCourseTypeName(item?.resourceType === "course" ? "liveCourses" : item?.resourceType === "videoResource" ? "videoResources" : item?.resourceType === "studyMaterial" ? "studyMaterials" : item?.resourceType == "testSeries" ? "onlineTestSeries" : null);
                                            // }
                                            history.push({
                                                pathname: "/course-details",
                                                state: {
                                                    key: {
                                                        fromMarketPlaceMenu: true,
                                                    }
                                                }
                                            })
                                        }
                                    }}
            >
                <div style={{ position: "relative" ,}}>
                    <img src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{ width: "100%", height: 180, borderRadius: 8 }} loading='lazy' />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    </div>
                   

                    {(courseTypeName == "videoResources" || fromSearchVideo) ? <></> : <div style={{borderTopLeftRadius:3, borderBottomLeftRadius:3, fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "5px 10px", position: "absolute", fontWeight: "bold", top: 28, right: 0 }}>{item?.mode == "online" ? "Online" : item?.mode == "offline" ? "Offline" : "Hybrid"}</div>}
                    {/* {item?.amount ?
                        <div className='amount-text' style={{ fontSize: 10, color: "#594099", backgroundColor: "#F6F4FE", padding: "5px 10px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {parseFloat(item?.amount || 0) - parseFloat(item?.totalDiscount || 0)}</div>
                        :
                        <div className='amount-text' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: "#FF414D", padding: "5px 20px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #FF414D" }}>FREE</div>
                    } */}
                    <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ justifyContent: 'space-between', height: 30, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "0px 10px", alignItems: "center", position: "absolute", bottom: 0, borderRadius: "0px 0px 8px 8px" }}>
                        {item?.ratingValue ? <div><StarFilled style={{ color: "#FFA931", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.ratingValue || 0}</span></div> : <div></div>}
                        <div><UserOutlined style={{ color: "#FFFFFF", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.studentCount} {courseTypeName == 'liveCourses' ? 'enrolled' : 'purchased'}</span></div>
                    </div>
                </div>
                <div style={{ padding: "0px 0px 0px 0px",}}>
                    {/* {courseTypeName == 'videoResources' ?
                        <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ fontSize: 12, fontWeight: 600, color: "#636363", marginTop: 10 }}>{item?.sectionCount}sections •{item?.topicCount} •lecture {item?.duration}Total length • </div> :
                        <div className={`${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ fontSize: 12, fontWeight: 600, color: "#636363", marginTop: 10 }}>{item?.duration} {item?.durationType} • {item?.subjectCount} Subjects • {item?.chapterCount} Chapters</div>

                    } */}
                    <div className='r-jsb m-t-5'>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                            <div>{item?.published ?
                                <img src={require("../../Assets/course/check.svg").default} style={{ height: 16, width: 16 }} />
                                :
                                <img src={require("../../Assets/course/block.svg").default} style={{ height: 16, width: 16 }} />
                            }</div>
                        </div>

                    {console.log('item.resourceType',item.resourceType)
                    }

                    {item.resourceType == 'course' ?
                    <div style={{color:'#636363',marginTop:2}}>
                        <span>{parseInt(item?.duration) > 0 ? item?.duration : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.duration) > 0 ? item?.durationType?.toLowerCase(): <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.subjectCount) > 0 ? '|' : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.subjectCount) > 0 ? item?.subjectCount : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.subjectCount) > 0 ? parseInt(item?.subjectCount) == 1 ? 'subject' : 'subjects': <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.chapterCount) > 0 ? '|' : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.chapterCount) > 0 ? item?.chapterCount : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.chapterCount) > 0 ? parseInt(item?.chapterCount) == 1 ? 'chapter' : 'chapters': <></>}</span>
                    </div> 
                    : item.resourceType == 'videoResource' ?

                    <div style={{color:'#636363',marginTop:2}}>
                        <span>{ item?.duration ? getAdjustedHour(item?.duration) > 0 ? getAdjustedHour(item?.duration) : <></>  : <></> }</span>
                        <span style={{marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0}}>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? 'hours' :<></> : <></> }</span>
                        <span style={{marginLeft:item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0}}>{parseInt(item?.quizCount) > 0  && item?.duration ? getAdjustedHour(item?.duration) > 0 ? '|' : <></> : <></>}</span>
                        <span style={{marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0}}>{parseInt(item?.quizCount) > 0 ? item?.quizCount : <></>}</span>
                        <span style={{marginLeft:parseInt(item?.quizCount) > 0 ? 5 : 0}}>{parseInt(item?.quizCount) > 0 ? parseInt(item?.quizCount) == 1 ? 'quiz' : 'quizzes': <></>}</span>
                        <span style={{marginLeft:parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0}}>{parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? '|' : <></>}</span>
                        <span style={{marginLeft:parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0}}>{parseInt(item?.notesCount) > 0 ? item?.notesCount : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.notesCount) > 0 ? parseInt(item?.notesCount) == 1 ? 'notes' : 'notes': <></>}</span>
                    </div> 
                    :
                    item.resourceType == 'testSeries' ?

                    <div style={{color:'#636363',marginTop:2}}>
                        <span>{parseInt(item?.tCount) > 0 ? item?.tCount : <></>}</span>
                        <span style={{marginLeft:5}}>{parseInt(item?.tCount) > 0 ? parseInt(item?.tCount) == 1  ?  'test' : 'tests': <></>}</span>
                        
                    </div> 
                    :
                    <div style={{color:'#636363',marginTop:2}}>
                    <span>{parseInt(item?.m_count) > 0 ? item?.m_count : <></>}</span>
                    <span style={{marginLeft:5}}>{parseInt(item?.m_count) > 0 ? parseInt(item?.m_count)  == 1 ?  'material' : 'materials': <></>}</span>
                    
                     </div> 
                    }
                    {/* <div className='hovered-text-visible'>
                        {courseTypeName == 'videoResources' ?
                            (<div style={{ display: "flex" }}>
                                <img
                                    src={`${IMG_URL}/${item?.teacherImage || "default.png"}`}
                                    className="radius-100 batch-detial__student-img"
                                    style={{ height: 30, width: 30, borderRadius: '50%' }}
                                />
                                <div style={{ marginLeft: "5px", color: "#636363", fontSize: "12px", fontWeight: "bold", marginTop: "5px" }}>{item?.teacherName}</div>
                            </div>) : null}
                    </div> */}
                
                    <div style={{ display: "flex" , justifyContent:'space-between', marginTop:2}}>
                        <div style={{ display: "flex", alignItems:'center' }}>
                            <RatingStars star={item?.ratingValue || 0} />
                            <div style={{ color: "#AEAEAE", margin: "5px 0px 0px 10px", fontFamily: "roboto", fontWeight: 400 ,marginTop:-1}}>({item?.ratingCount || 0})</div>
                        </div>
                        <div>
                       {parseInt(item?.studentCount) > 0 ?
                        <span ><img src={require("../../Assets/courseManagementV4/Team.svg").default} style={{marginTop:-4, height:15, width:15}}/></span> : <></>}
                        <span style={{color:'#636363', marginLeft:10,}}>{parseInt(item?.studentCount) > 0 ? item?.studentCount : <></>}</span> <span style={{marginLeft:5, color:'#636363', marginTop:-3}}><span style={{color:'#636363'}}>{parseInt(item?.studentCount) > 0 ? 'enrolled' : <></>}</span></span>
                        </div>

                        
                    </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                            <div>
                            {/* Calculate final price after discount */}
                            {item?.discountType ? item?.discountType === "percent" 
                                    ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0 
                                        ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 8,  }}>
                                        <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                    </div>
                                        : (
                                            <>
                                      
                                            <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                                ₹ {Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100}
                                            </span>
                                           
                                            {item?.discount > 0 && (
                                                <span 
                                                style={{ fontSize: 14, color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>
                                                ₹ {item?.amount}
                                                </span>
                                            )}
                                            </>
                                        )
                                    : item?.discountType === "amount" 
                                        ? (item?.amount - item?.discount) === 0
                                            ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop:8 }}>
                                            <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                        </div>
                                            : <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                                ₹ {item?.amount - item?.discount}
                                            </span>
                                        : <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                            ₹ {item?.amount}
                                        </span>
                                :
                                (
                                    item?.amount - item?.discount === 0 ?
                                    <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop:8 }}>
                                    <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                        </div>
                                            :
                                        <div>
                                            : <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }}>
                                                        ₹ {item?.amount - item?.discount}
                                                    </span>
                                        </div>)
                                }

                            </div>
                            <div style={{ 
                                display: 'flex', 
                                marginTop: (item?.discountType === "percent" && Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0) 
                                            || (item?.discountType === "amount" && (item?.amount - item?.discount) === 0) 
                                            ? '8px' 
                                            : '0px' 
                            }}>
                        
                            <span>
                                <img 
                                src={require("../../Assets/courseManagementV4/share-2.svg").default} 
                                style={{ height: 20, width: 20, cursor: (isSelectedCard) ? 'not-allowed' : 'pointer'  }} 

                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isSelectedCard) {
                                        
                                    }
                                    else{
                                         setShareModalVisible(true);
                                        setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`);
                                    }
                                  }}

                        
                                />
                            </span>
          
                            <span >
                                {/* <Popover content={(isSelectedCard) ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger="hover" placement='right' showArrow={false}>
                                    <img 
                                    src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} 
                                    style={{ width: 20, height: 20, color: "grey", marginLeft: 5 }} 
                                    onClick={(e) => { e.stopPropagation(); }} 
                                    />
                                </Popover> */}

                                <Popover content={(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? null : CourseCardContent(item?.id, item?.resourceType, item)} trigger="hover" placement='right' showArrow={false}>
                                      <img 
                                    src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} 
                                    style={{ width: 20, height: 20, color: "grey", marginLeft: 5 }} 
                                    onClick={(e) => { e.stopPropagation(); }} 
                                    />
                                    </Popover>
                                
                            </span>
                            </div>
                        </div>
                        

                    {/* {courseTypeName == 'videoResources' ? (<></>) : (<>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>
                            <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold" }}>Completed</div>
                            <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold" }}>{Math.round(item?.progress === undefined ? null : item?.progress)} %</div>
                        </div>
                        <div>
                            <Progress
                                className='student-test-progressbar'
                                style={{ width: "100%", left: '0px', right: '50px' }}
                                trailColor=''
                                strokeColor={item?.progress == 100 ? "#28DF99" : '#594099'}
                                percent={item?.progress}
                                size={'default'}
                                status="active"
                                showInfo={false} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }}>{item?.startDate === null ? null : (moment(item?.startDate).format('DD-MM-YYYY'))}</div>
                            <div style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "bold" }}>{item?.endDate === null ? null : moment(item?.endDate).format('DD-MM-YYYY')} </div>
                        </div></>)} */}
                    {/* <div className={`m-t-5 m-b-5 ${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div> */}
                    {/* <div className={`r-jsb ${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`}>
                        {item?.amount ?
                            <div style={{ display: "flex" }}>
                                <div style={{ fontSize: 20, fontWeight: "bold", color: "#191919", }}>₹ {item?.discountType == "percent" ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 : item?.discountType == "amount" ? (item?.amount - item?.discount) : item?.amount}</div>
                                {item?.discount == 0 ? <></> : <div className='r-c-c' style={{ fontSize: 12, fontWeight: "bold", color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>₹ {item?.amount}</div>}
                            </div>
                            :
                            <div></div>
                        }
                        <div style={{ color: "#FFFFFF", backgroundColor: "#594099", padding: "3px 5px", fontWeight: "bold", borderRadius: 4, fontSize: 12, height: "fit-content", marginTop: 5 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${courseTypeName}`);
                            }}>
                            <ShareAltOutlined style={{ marginRight: 5 }} />Share
                        </div>
                    </div> */}
                </div>
            </div>
        )
    };

    // const LiveCourseCard = ({ item, index, reference, fromSearchCourse, fromSearchVideo }) => {

    //     console.log("checkinliveeee", item?.resourceType)

    //     const togglePublishedCheckbox = (id) => {
    //         if (selectedPublishCards.includes(id)) {
    //             setSelectedPublishCards(selectedPublishCards?.filter(cardId => cardId !== id));
    //         } else {
    //             setSelectedPublishCards([...selectedPublishCards, id]);
    //         }
    //     };
    //     return (
    //         <div className={`${(item?.resourceType === "course" || item?.resourceType === "videoResource") ? 'search-live-course-card-mp' : 'zoom live-course-card-mp'}`} style={{ width: "270px" }}>
    //             <div className='zoom live-course-card-mp checkHover-1'
    //                 onClick={(e) => {
    //                     if (!item?.deleted) {
    //                         e.stopPropagation();
    //                         storeSingleCourseId(item?.id);
    //                         // if (isSearchedSuggestionClicked) {
    //                         setCourseTypeName(item?.resourceType === "course" ? "liveCourses" : item?.resourceType === "videoResource" ? "videoResources" : null);
    //                         // }
    //                         history.push({
    //                             pathname: "/course-details",
    //                             state: {
    //                                 key: {
    //                                     fromMarketPlaceMenu: true,
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 }}
    //                 style={{ cursor: item?.deleted ? "default" : "pointer" }}
    //             >
    //                 <div style={{ position: "relative" }}>
    //                     <img src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultImg} style={{ width: "100%", height: 180, borderRadius: 8 }} loading='lazy' />
    //                     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                         <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>
    //                             {/* <Checkbox
    //                             // checked={(fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id) : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id) : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id) : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id) : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id) : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}
    //                             // onChange={() => {
    //                             //     if (fromSearchCourse || fromSearchVideo) {
    //                             //         setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
    //                             //     }
    //                             //     reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id) : togglePublishedCheckbox(item?.id)
    //                             // }} 
    //                             /> */}
    //                         </div>
    //                         {/* {console.log('selectedPublishCards1', (selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0), (selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0), courseTypeName)} */}
                          
    //                       {
    //                         hasMarketingPrivilege ?
    //                         <></> :
    //                         (!item?.deleted ? <>
    //                             <div className={`${(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? 'three-dots-div' : 'hovered-text-visible'}`}>
    //                                 <Popover content={(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? null : CourseCardContent(item?.id, item?.resourceType, item)} trigger="hover" placement='right' showArrow={false}>
    //                                     <MdMoreVert style={{ width: "24px", height: "22px", color: "grey", position: "absolute", top: 2, right: 0 }} onClick={(e) => { e.stopPropagation() }} />
    //                                 </Popover>
    //                             </div>
    //                         </> : <></>)
    //                       }
                            
    //                     </div>
    //                     <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>
    //                         {/* <Checkbox
    //                         // checked={(fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id) : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id) : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id) : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id) : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id) : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}
    //                         // onChange={() => {
    //                         //     if (fromSearchCourse || fromSearchVideo) {
    //                         //         setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
    //                         //     }
    //                         //     reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id) : togglePublishedCheckbox(item?.id)
    //                         // }} 
    //                         /> */}
    //                     </div>
    //                     {(item?.resourceType === "videoResource") ? <></> : <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "5px 10px", position: "absolute", fontWeight: "bold", top: 25, right: 0 }}>{item?.mode}</div>}
    //                     {item?.amount ?
    //                         <div className='amount-text' style={{ fontSize: 10, color: "#594099", backgroundColor: "#F6F4FE", padding: "5px 10px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #594099" }}>₹ {parseFloat(item?.amount || 0) - parseFloat(item?.totalDiscount || 0)}</div>
    //                         :
    //                         <div className='amount-text' style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: "#FF414D", padding: "5px 20px", position: "absolute", fontWeight: "bold", bottom: 10, right: 10, borderRadius: 4, border: " 1px solid #FF414D" }}>FREE</div>
    //                     }
    //                     <div className={`${/* (fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : */ 'hovered-text-visible'}`} style={{ justifyContent: 'space-between', height: 30, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "0px 10px", alignItems: "center", position: "absolute", bottom: 0, borderRadius: 8 }}>
    //                         {item?.ratingValue ? <div><StarFilled style={{ color: "#FFA931", marginRight: 5 }} /><span style={{ color: "#FFFFFF", fontWeight: "bold" }}>{item?.ratingValue || 0}</span></div> : <div></div>}
    //                         <div><UserOutlined style={{ color: "#FFFFFF", marginRight: 5 }} /><span style={{ color: "#FFFFFF", }}>{item?.studentCount} {item?.resourceType == 'course' ? 'enrolled' : 'purchased'}</span></div>
    //                     </div>
    //                 </div>
    //                 {item?.resourceType == 'videoResource' ?
    //                     <div className={`${'hovered-text-visible'}`} style={{ fontSize: 12, fontWeight: 600, color: "#636363", marginTop: 10 }}>{item?.sectionCount}sections •{item?.topicCount} •lecture {item?.duration}Total length • </div> :
    //                     <div className={`${'hovered-text-visible'}`} style={{ fontSize: 12, fontWeight: 600, color: "#636363", marginTop: 10 }}>{item?.duration} {item?.durationType} • {item?.subjectCount} Subjects • {item?.chapterCount} Chapters</div>

    //                 }
    //                 {(item?.resourceType == 'course' || item?.resourceType == 'videoResource') ?
    //                     <div className='r-jsb m-t-5'>
    //                         <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>

    //                     </div>
    //                     :
    //                     <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", marginTop: 5, width: 200 }}>{item?.name}</Typography.Text>
    //                 }
    //                 <div className='hovered-text-visible'>
    //                     {item?.resourceType == 'videoResource' ?
    //                         (
    //                             <div className='r-c-sb' style={{ width: "100%" }}>
    //                                 <div className='r-jsb' >
    //                                     <img
    //                                         src={`${IMG_URL}/${item?.teacherImage || "default.png"}`}
    //                                         className="radius-100 batch-detial__student-img"
    //                                         style={{ height: 30, width: 30, borderRadius: '50%' }}
    //                                     />
    //                                     <div style={{ marginLeft: "5px", color: "#636363", fontSize: "12px", fontWeight: "bold", marginTop: "5px" }}>{item?.teacherName}</div>

    //                                 </div>
    //                                 {item?.resourceType == "videoResource" && item?.published ?
    //                                     <Button type="link" onClick={(e) => {
    //                                         e.stopPropagation();
    //                                         setShareModalVisible(true);
    //                                         setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`);
    //                                     }} > <ShareAltOutlined style={{ marginRight: 5 }} /> Share</Button>
    //                                     : null}
    //                             </div>
    //                         ) : null}
    //                 </div>
    //                 <div className='r-c-sb ' style={{ width: "100%" }}>
    //                     <div className='' style={{ display: "flex", alignItems: "center" }}>
    //                         <div style={{ color: "#FFA931", fontWeight: "bold" }}>{item?.ratingValue}</div>
    //                         <RatingStars star={item?.ratingValue || 0} />
    //                         <div style={{ color: "#636363", margin: "0px 0px 0px 10px", fontFamily: "roboto", fontWeight: 600 }}>({item?.ratingCount || 0})</div>
    //                     </div>

    //                     {hasMarketingPrivilege
    //                         ?
    //                         <></>
    //                         :
    //                         !item?.deleted && (
    //                             <div className='hovered-text-visible-1'>
    //                                 <Tag color={item?.published ? "#28df99" : "#FFA931"} style={{ color: item?.published ? "" : "#191919" }}>
    //                                     {item?.published ? "Published" : "Unpublished"}
    //                                 </Tag>
    //                             </div>
    //                         )
    //                     }

    //                 </div>

    //                 {item?.resourceType == 'course' ? (<>
    //                 </>) : (<>
    //                     <div className='r-c-sb hovered-text-not-visible'>
    //                         <div className='' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 5 }}>
    //                             <div className='className="radius-100 batch-detial__student-img"' style={{ color: "#636363", fontSize: "12px", fontWeight: "bold", height: "25px", width: "25px", borderRadius: "50%", marginRight: 10 }}>
    //                                 <img style={{ height: "25px", width: "25px", }} src={`${Api._s3_url}${item?.teacherImage}`} />
    //                             </div>

    //                             <div style={{ color: "#636363", fontSize: "12px", fontWeight: "bold", color: "#636363" }}>{item?.teacherName}</div>
    //                         </div>
    //                         {(item?.resourceType == "course" || item?.resourceType == "videoResource") && item?.published ?
    //                             <Button type="link" onClick={(e) => {
    //                                 e.stopPropagation();
    //                                 setShareModalVisible(true);
    //                                 setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`);
    //                             }} > <ShareAltOutlined style={{ marginRight: 5 }} /> Share</Button>
    //                             : null}
    //                     </div>

    //                     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                     </div>
    //                 </>)}
    //                 <div className={`m-t-5 m-b-5 ${(fromSearchCourse || fromSearchVideo) ? 'hovered-text-not-visible' : 'hovered-text-visible'}`} style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div>

    //                 <div className='hovered-text-visible' style={{ flexDirection: "column" }} >
    //                     <div className='r-c-fe' style={{}}>
    //                         {item?.resourceType == "course" && !item?.deleted && item?.published ?
    //                             <Button type="link" onClick={(e) => {
    //                                 e.stopPropagation();
    //                                 setShareModalVisible(true);
    //                                 setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`);
    //                             }} > <ShareAltOutlined style={{ marginRight: 5 }} /> Share</Button>
    //                             : null}
    //                     </div>
    //                     <div className={`r-jsb`} style={{}}>
    //                         {item?.amount ?
    //                             <div style={{ display: "flex" }}>
    //                                 <div style={{ fontSize: 20, fontWeight: "bold", color: "#191919", }}>₹ {item?.discountType == "percent" ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 : item?.discountType == "amount" ? (item?.amount - item?.discount) : item?.amount}</div>
    //                                 {item?.discount == 0 ? <></> : <div className='r-c-c' style={{ fontSize: 12, fontWeight: "bold", color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>₹ {item?.amount}</div>}
    //                             </div>
    //                             :
    //                             <div></div>
    //                         }

    //                         {
    //                             user?.privilage?.includes('Marketing')
    //                                 ?
    //                                 <></>
    //                                 :
    //                                 (!item?.deleted ?
    //                                     <div style={{ color: "#FFFFFF", backgroundColor: "#594099", padding: "3px 5px", fontWeight: "bold", borderRadius: 23, fontSize: 10, height: "fit-content", marginTop: 5, cursor: "pointer" }}
    //                                         onClick={(e) => {
    //                                             e.stopPropagation(); setIsConfirmPublishModalVisible(true); storeSpecificCourseData(item)
    //                                             setCourseTypeName(item?.resourceType === "course" ? "liveCourses" : item?.resourceType == "videoResource" ? "videoResources" : null);
    //                                         }}
    //                                     >
    //                                         <div type="primary" style={{ padding: "3px" }} >{!item?.published ? "Publish" : "Unpublish"}</div>
    //                                     </div>
    //                                     : <></>)
    //                         }

    //                     </div>
    //                 </div>
    //             </div >
    //         </div>
    //     )
    // };


    const StudyMaterialCard = ({ item, index, reference, fromSearchStudy, fromSearchTest }) => {
        console.log("studeeee", item?.resourceType, fromSearchStudy, fromSearchTest)
        return (
            <div style={{ margin: "120px 20px 20px 0px", width: "275px" }}>
                <div className='study-material-card-1'
                    style={{ cursor: item?.deleted ? "default" : "pointer" }}
                    onClick={(e) => {
                        if (!item?.deleted) {
                            e.stopPropagation();
                            storeSingleCourseId(item?.id);
                            // if (isSearchedSuggestionClicked) {
                            setCourseTypeName(item?.resourceType === "studyMaterial" ? "studyMaterials" : item?.resourceType == "testSeries" ? "onlineTestSeries" : null);
                            // }
                            history.push({
                                pathname: "/course-details",
                                state: {
                                    key: {
                                        fromMarketPlaceMenu: true,
                                        // isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                        // searchedValue: selectedSuggestion
                                    }
                                }
                            })
                        }
                    }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div onClick={(e) => { e.stopPropagation() }}>
                            {/* <Checkbox style={{ paddingLeft: 5, paddingTop: 10 }}
                                checked={(fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                    : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                        : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.testSeries?.items?.[index]?.id)
                                            : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.testSeries?.items?.[index]?.id)
                                                : reference == "unpublished"
                                                    ? (courseTypeName == "onlineTestSeries")
                                                        ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                        : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                    : (courseTypeName == "onlineTestSeries")
                                                        ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                        : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                }
                                disabled={item?.tCount == 0}
                                onChange={() => {
                                    if (fromSearchStudy) {
                                        setSearchSelectedCourseType("searchStudyMaterial")
                                    }
                                    if (fromSearchTest) {
                                        setSearchSelectedCourseType("searchTests")
                                    }
                                    reference == "unpublished"
                                        ? toggleUnpublishedCheckbox(item?.id)
                                        : togglePublishedCheckbox(item?.id)
                                }} /> */}
                        </div>
                        <div style={{ display: "flex", paddingRight: '15px', paddingTop: '15px' }}>
                            {item?.amount ?
                                <div style={{ fontSize: 10, color: "#594099", backgroundColor: "#F6F4FE", padding: "5px 10px", fontWeight: "bold", borderRadius: 4, border: " 1px solid #594099" }}>₹ {parseFloat(item?.amount || 0) - parseFloat(item?.totalDiscount || 0)}</div>
                                :
                                <div style={{ fontSize: 10, color: "#FFFFFF", backgroundColor: "#FF414D", padding: "5px 20px", fontWeight: "bold", borderRadius: 4, border: " 1px solid #FF414D" }}>FREE</div>
                            }

                            {
                                hasMarketingPrivilege ? 
                                <></> 
                                :
                                (!item?.deleted ? <>
                                    <div className={`${(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? 'three-dots-div' : 'hovered-text-visible'}`}>
                                        <Popover content={(selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0) ? null : CourseCardContent(item?.id, item?.resourceType, item)} trigger="hover" placement='right' showArrow={false}>
                                            < MdMoreVert style={{ width: "24px", height: "22px", color: "grey", marginRight: -10, marginTop: -5 }} onClick={(e) => { e.stopPropagation() }} />
                                        </Popover>
                                    </div>
                                </> : <></>)
                            }
                            
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "-20px 0px 0px 10px" }}>
                        <img src={require("../../Assets/course/studyMaterialImg.svg").default} style={{ height: 50, width: 50 }} />

                        {(item?.resourceType == "testSeries") ?
                            <div style={{ color: "#636363", fontSize: 16, marginLeft: 20 }}>{item?.tCount} {item?.tCount > 1 ? "tests" : "test"}</div>
                            :
                            <div style={{ color: "#636363", fontSize: 16, marginLeft: 20 }}>{item?.m_count} {item?.m_count > 1 ? "materials" : "materials"}</div>
                        }
                    </div>
                </div>
                {
                    (item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries") ?
                        // <div style={{}}>
                        //     <div className='r-jsb m-t-5  ' onMouseEnter={() => setHovered(true)}
                        //         onMouseLeave={() => setHovered(false)}>
                        //         <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                        //         {hovered ?
                        //             <div className='' style={{ color: "#FFFFFF", backgroundColor: "#594099", padding: "3px", fontWeight: "bold", borderRadius: 23, fontSize: 10, height: "fit-content", marginTop: 5, cursor: "pointer" }}
                        //                 onClick={(e) => {
                        //                     e.stopPropagation(); setIsConfirmPublishModalVisible(true); storeSpecificCourseData(item);
                        //                     setCourseTypeName(item?.resourceType === "studyMaterial" ? "studyMaterials" : item?.resourceType == "testSeries" ? "onlineTestSeries" : null);

                        //                 }}
                        //             >
                        //                 <div style={{ padding: "3px 5px" }}>{!item?.published ? "Publish" : "Unpublish"}</div>
                        //             </div>
                        //             : <div className=''>
                        //                 <Tag color={item?.published ? "#28df99" : "#FFA931"} style={{ color: item?.published ? "" : "#191919" }}>
                        //                     {item?.published ? "Published" : "Unpublished"}
                        //                 </Tag>
                        //             </div>}
                        //     </div>

                        // </div>
                        <div className={`item-container m-t-10 ${hasMarketingPrivilege ? 'marketing' : 'hoverable'}`}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                            <div className="publish-controls">

                                {hasMarketingPrivilege ?
                                    <></>
                                    :
                                    <div
                                        className="publish-button"
                                        data-publish-text={!item?.published ? "Publish" : "Unpublish"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsConfirmPublishModalVisible(true);
                                            storeSpecificCourseData(item);
                                            setCourseTypeName(item?.resourceType === "studyMaterial" ? "studyMaterials" : item?.resourceType === "testSeries" ? "onlineTestSeries" : null);
                                        }}
                                    ></div>
                                }
                                <Tag className="publish-status" color={item?.published ? "#28df99" : "#FFA931"}>
                                    {item?.published ? "Published" : "Unpublished"}
                                </Tag>
                            </div>
                        </div>
                        :
                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 16, color: "#191919", fontWeight: "bold", marginTop: 5, width: 200 }}>{item?.name}</Typography.Text>
                }
                <div className='r-c-sb'>
                    <div style={{ display: "flex" }}>
                        <RatingStars star={item?.ratingValue || 0} />
                        <div style={{ color: "#191919", fontSize: 12, margin: "3px 0px 0px 10px", fontWeight: 600 }}>({item?.ratingCount || 0} ratings)</div>
                    </div>
                    <div>
                        {(item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries") && !item?.deleted && item?.published ?
                            <Button type="link" onClick={(e) => {
                                e.stopPropagation();
                                setShareModalVisible(true);
                                setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`);
                            }} > <ShareAltOutlined style={{ marginRight: 5 }} /> Share</Button>
                            : null}
                    </div>
                </div>
            </div >
        )
    }
    const [hasMoreQA, setHasMoreQA] = useState(true);
    const fetchMoreData = () => {
        console.log("inseedddfetchmore", resourceParams?.limit)
        setTimeout(() => {
            const newLimit = resourceParams.limit + 12; // Increase the limit by 10
            setResourceParams(prevParams => ({
                ...prevParams,
                limit: newLimit,
            }));
            // getResourceList({ ...resourceParams, limit: newLimit }, (res) => {
            //     setResourceList(prevList => ({
            //         ...prevList,
            //         items: [...prevList.items, ...res.items]
            //     }));
            //     togglePageLoader(false);
            // });
        }, 1500);
    };




    return (
        <>
            <PageHeader title={"Marketplace"} actions={actions} />
            <div onClick={(e) => { e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false) }} >

                <InfiniteScroll
                    dataLength={resourceList?.items?.length || 0}
                    next={() => fetchMoreData()}
                    hasMore={hasMoreQA}
                    height={770}
                >
                    <div style={{ display: "flex", flexWrap: "wrap", marginTop: "50px" , gap:'2.1%' }}>
                        {resourceList?.items?.length > 0 ? <>
                            {resourceList?.items?.map((item) => (
                                <React.Fragment key={item.id}>
                                    {item.resourceType === "course" && (
                                        <LiveCourseCard
                                            item={item}
                                            reference={item.published === 1 ? "published" : "unpublished"}
                                            fromSearchCourse={item.resourceType === "course"}
                                        />
                                    )}
                                    {item.resourceType === "videoResource" && (
                                        <LiveCourseCard
                                            item={item}
                                            reference={item.published === 1 ? "published" : "unpublished"}
                                            fromSearchVideo={item.resourceType === "videoResources"}
                                        />
                                    )}
                                    {item.resourceType === "studyMaterial" && (
                                        <LiveCourseCard
                                            item={item}
                                            reference={item.published === 1 ? "published" : "unpublished"}
                                            fromSearchStudy={item.resourceType === "studyMaterial"}
                                        />
                                    )}
                                    {item.resourceType === "testSeries" && (
                                        <LiveCourseCard
                                            item={item}
                                            reference={item.published === 1 ? "published" : "unpublished"}
                                            fromSearchTest={item.resourceType === "testSeries"}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </> : <div className='r-c-c m-t-100' style={{ marginLeft: "400px" }}>
                            <NoDataCard />
                        </div>}
                    </div>
                </InfiniteScroll>




                {isShareModalVisible &&
                    <ShareCourseModal
                        isShareModalVisible={isShareModalVisible}
                        setShareModalVisible={setShareModalVisible}
                        shareUrl={shareUrl} />}
                {isDeleteModalVisible &&
                    <DeleteModal
                        isDeleteModalVisible={isDeleteModalVisible}
                        setIsDeleteModalVisible={setIsDeleteModalVisible}
                        togglePageLoader={togglePageLoader}
                        fromCourseDetailsPage={true}
                        specificCourseDataContainer={specificCourseDataContainer}
                        courseTypeName={courseTypeName}
                        fromMarketPlaceMenu={true}
                        setSelectedPublishCards={setSelectedPublishCards}
                        setSelectedUnpublishCards={setSelectedUnpublishCards}
                    />
                }
                {isMoveModalVisible &&
                    <MoveModal
                        isMoveModalVisible={isMoveModalVisible}
                        setIsMoveModalVisible={setIsMoveModalVisible}
                        selectedSubCategoryCards={selectedSubCategoryCards}
                        setSelectedSubCategoryCards={setSelectedSubCategoryCards}
                        courseSubCategoriesData={courseSubCategoriesData}
                        isSubCategoryVisible={isSubCategoryVisible}
                        courseCategoryData={courseCategoryData}
                        togglePageLoader={togglePageLoader}
                        getCoursesSubCategories={getCoursesSubCategories}
                        selectedCategory={selectedCategory}
                        selectedPublishCards={selectedPublishCards}
                        setSelectedPublishCards={setSelectedPublishCards}
                        selectedUnpublishCards={selectedUnpublishCards}
                        setSelectedUnpublishCards={setSelectedUnpublishCards}
                        isCoursesCardsVisible={true}
                        getTotalCoursesList={getTotalCoursesList}
                        getCoursesCategories={getCoursesCategories}
                        courseTypeName={courseTypeName}
                        getStudyMaterialsList={getStudyMaterialsList}
                        getTotalsVideoResource={getTotalsVideoResource}
                        getOnlineTestSeries={getOnlineTestSeries}
                        fromMarketPlaceMenu={true}
                    />}

                {isConfirmPublishModalVisible &&
                    <ConfirmPublishOrUnpublishModal
                        isConfirmPublishModalVisible={isConfirmPublishModalVisible}
                        setIsConfirmPublishModalVisible={setIsConfirmPublishModalVisible}

                        selectedPublishCards={selectedPublishCards}
                        setSelectedPublishCards={setSelectedPublishCards}
                        selectedUnpublishCards={selectedUnpublishCards}
                        setSelectedUnpublishCards={setSelectedUnpublishCards}
                        togglePageLoader={togglePageLoader}
                        getTotalCoursesList={getTotalCoursesList}
                        courseTypeName={courseTypeName}
                        getStudyMaterialsList={getStudyMaterialsList}
                        getTotalsVideoResource={getTotalsVideoResource}
                        // isSearchedSuggestionClicked={isSearchedSuggestionClicked}
                        // searchSelectedCourseType={searchSelectedCourseType}
                        // getSearchResultsData={getSearchResultsData}
                        getOnlineTestSeries={getOnlineTestSeries}
                        specificCourseDataContainer={specificCourseDataContainer}
                        fromMarketPlaceMenu={true}
                        setRenderPage={setRenderPage}
                        renderPage={renderPage}
                    />}
                <PageDataLoader visible={pageLoader} />
            </div >
        </>
    )
}

const mapStateToProps = (state) => {
    const { courseTypeName, courseCategoryData, specificCourseDataContainer, courseSubCategoriesData, totalCoursesDataContainer, courseStatsData, courseCreateObj, autocompleteSearchData, searchResultData, searchPageParamsObj, storeCategoryData } = state.courseManagement;
    const { user } = state.session;
    return {
        courseTypeName, user, courseCategoryData, specificCourseDataContainer, courseSubCategoriesData, totalCoursesDataContainer, courseStatsData, courseCreateObj, autocompleteSearchData, searchResultData, searchPageParamsObj, storeCategoryData
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCoursesCategories: (callBack) => dispatch(getCoursesCategories(callBack)),
    getCoursesSubCategories: (id, callBack) => dispatch(getCoursesSubCategories(id, callBack)),
    getOnlineTestSeries: (callBack) => dispatch(getOnlineTestSeries(callBack)),
    getTotalCoursesList: (callBack) => dispatch(getTotalCoursesList(callBack)),
    setAllCoursesPageParamsObj: (key, val) => dispatch(setAllCoursesPageParamsObj(key, val)),
    storeSingleCourseId: (val) => dispatch(storeSingleCourseId(val)),
    getCategoryList: (val) => dispatch(getCategoryList(val)),
    getResourceList: (params, callback) => dispatch(getResourceList(params, callback)),
    getStudyMaterialsList: (callBack) => dispatch(getStudyMaterialsList(callBack)),
    getTotalsVideoResource: (callBack) => dispatch(getTotalsVideoResource(callBack)),
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    getAutocompleteSearch: (search, callBack) => dispatch(getAutocompleteSearch(search, callBack)),
    getSearchResultsData: (callBack) => dispatch(getSearchResultsData(callBack)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    storeAutocompleteSearchData: (val) => dispatch(storeAutocompleteSearchData(val)),
    getCourseLoadMore: (page, callBack, published) => dispatch(getCourseLoadMore(page, callBack, published)),
    getVideoResourceLoadMore: (page, callBack, published) => dispatch(getVideoResourceLoadMore(page, callBack, published)),
    getTestsLoadMore: (page, callBack, published) => dispatch(getTestsLoadMore(page, callBack, published)),
    getStudyMaterialLoadMore: (page, callBack, published) => dispatch(getStudyMaterialLoadMore(page, callBack, published)),
    getCourseStats: () => dispatch(getCourseStats()),
    storeSpecificCourseData: (val) => dispatch(storeSpecificCourseData(val)),
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    getMarketSettingsData: (all, callBack) => dispatch(getMarketSettingsData(all, callBack)),
  showMenu: (bool) => dispatch(showMenu(bool)),

});
export default connect(mapStateToProps, mapDispatchToProps)(MarketPlace);
