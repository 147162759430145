import { MinusCircleOutlined } from '@ant-design/icons';
import { addCourses, setCourseBillingPlan, setCourseDuration, setCoursePrice, setCourseRef, setInstallments } from 'actions/course';
import { Input, Modal, Select } from 'antd'
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ceil, parseInt } from "lodash";
import { postAddFeeInstallments, postAddFeeStudyMaterial, setCreateCourseObj, setDiscountDetails } from 'actions/courseManagement';
import CourseAddDiscountModal from 'pages/adminMarketPlaceCourse/courseAddDiscountModal';

const { Option } = Select;

export const inputHeadingStyle = {
  font: "12px",
  color: "#636363",
  fontWeight: 600,
  fontFamily: "roboto",
}
function FeeDetailsModal({
  isFeeDetailsModalVisible, setFeeDetailsModalVisible, coursePrice, courseDuration,
  courseRef, setCoursePrice, setCourseBillingPlan, courseBillingPlan, installments,
  setInstallments, postAddFeeInstallments, setCourseDuration, setCourseRef, togglePageLoader,
  getSpecificCourseData, specificCourseDataContainer, courseDiscountDetails, setDiscountDetails,
  courseTypeName, postAddFeeStudyMaterial, getSpecificSMData, getSpecificVideoCourseData, getOnlineTestSeriesCourseDetails, setCreateCourseObj, courseCreateObj }) {
  const [counter, setCounter] = useState(0);
  const [instCust, setInstCust] = useState([]);
  const [addButtonLdr, setAddButtonLdr] = useState(false);
  const [isCustomizePlan, setIsCustomizePlan] = useState(false);
  const [forInstallmentReset, setForInstallmentReset] = useState(false);
  const [isCourseDiscountModalVisible, setCourseDiscountModalVisible] = useState(false)
  { console.log("hello", specificCourseDataContainer?.course?.discount); }
  // courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" || courseTypeName == "videoResources"
  const [registrationFee, setRegistration] = useState(specificCourseDataContainer?.course?.registrationFee )
  const [discount, setDiscount] = useState(courseTypeName == "studyMaterials" || courseTypeName == "videoResources" ? specificCourseDataContainer?.discount : courseTypeName == "onlineTestSeries" ? specificCourseDataContainer?.testSeriesDetails?.discount : specificCourseDataContainer?.course?.discount)
  const [discountType, setDiscountType] = useState(courseTypeName == "studyMaterials" || courseTypeName == "videoResources" ? specificCourseDataContainer?.discountType : courseTypeName == "onlineTestSeries" ? specificCourseDataContainer?.testSeriesDetails?.discountType : specificCourseDataContainer?.course?.discountType)

  const [discountedPrice, setDiscountedPrice] = useState(
    discountType == 'amount' ?
      Math.round((specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount))
      :
      Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100);

  const handleCancel = () => {
    setFeeDetailsModalVisible(false);
    setCoursePrice(null);
    setCourseBillingPlan(null)
  }


  const calcDiscount = (e) => {
    if (discountType == "percent" || discountType == "percentage") {
      const inputValue = parseInt(e.target.value)
      const percentageValue = inputValue > 100 ? 100 : inputValue;
      console.log('percentageValue',percentageValue,inputValue);
      setDiscountedPrice(Math.round((coursePrice - (coursePrice * percentageValue) / 100) * 100) / 100)
    } else if (discountType == "amount") {
      setDiscountedPrice(coursePrice - e?.target?.value)
    }
    if (e.target.value == "") {
      setDiscount(parseInt(0))

    } else {
      setDiscount(parseInt(e.target.value))

    }
    console.log("e.target.value", e.target.value == "");
  }

  useEffect(() => {
    if (courseTypeName == "studyMaterials" || courseTypeName == "videoResources") {
      if (specificCourseDataContainer?.discountType == "percent") {
        setDiscountedPrice(Math.round((specificCourseDataContainer?.amount - (specificCourseDataContainer?.amount * specificCourseDataContainer?.discount) / 100) * 100) / 100)
      } else if (specificCourseDataContainer?.discountType == "amount") {
        setDiscountedPrice(specificCourseDataContainer?.amount - specificCourseDataContainer?.discount)
      }
    } else if (courseTypeName == "onlineTestSeries") {
      if (specificCourseDataContainer?.testSeriesDetails?.discountType == "percentage") {
        setDiscountedPrice(Math.round((specificCourseDataContainer?.testSeriesDetails?.amount - (specificCourseDataContainer?.testSeriesDetails?.amount * specificCourseDataContainer?.testSeriesDetails?.discount) / 100) * 100) / 100)
      } else if (specificCourseDataContainer?.testSeriesDetails?.discountType == "amount") {
        setDiscountedPrice(specificCourseDataContainer?.testSeriesDetails?.amount - specificCourseDataContainer?.testSeriesDetails?.discount)
      }
    }
    // else {
    //   if (specificCourseDataContainer?.course?.discountType == "percent") {
    //     setDiscountedPrice(Math.round((specificCourseDataContainer?.course?.amount - (specificCourseDataContainer?.course?.amount * specificCourseDataContainer?.course?.discount) / 100) * 100) / 100)
    //   } else if (specificCourseDataContainer?.course?.discountType == "amount") {
    //     setDiscountedPrice(specificCourseDataContainer?.course?.amount - specificCourseDataContainer?.course?.discount)
    //   }
    // }

  }, [])
  const returnDays = (val) => {
    if (val == "Day(s)") {
      return "1";
    } else if (val == "Monthly" || val == "month" || val == "Month(s)") {
      return "30";
    } else if (val == "Quarterly") {
      return "90";
    } else if (val == "Half Yearly") {
      return "180";
    } else if (val == "Annually" || val == "Year(s)") {
      return "360";
    } else if (val == "Yearly") {
      return "360";
    } else if (val == "One time") {
      return "One time";
    } else if (val == "Customize") {
      return "Customize";
    } else if (val == "none") {
      return null
    }
  };
  useEffect(() => {

    if (courseTypeName == "studyMaterials" || courseTypeName == "videoResources")
      setCoursePrice(specificCourseDataContainer?.amount == 0 ? null
        : specificCourseDataContainer?.discountType == "amount" ? (specificCourseDataContainer?.amount) :
          (Math.round((specificCourseDataContainer?.amount))))

    if (courseTypeName == "liveCourses")
      setCoursePrice(specificCourseDataContainer?.course?.amount == 0 ? null
        : specificCourseDataContainer?.course?.discountType == "amount" ? ((specificCourseDataContainer?.course?.amount)).toFixed(2) :
          ((Math.round((specificCourseDataContainer?.course?.amount)))))

    if (courseTypeName == "onlineTestSeries")
      setCoursePrice(specificCourseDataContainer?.testSeriesDetails?.amount == 0 ? null
        : specificCourseDataContainer?.testSeriesDetails?.discountType == "amount" ? (specificCourseDataContainer?.testSeriesDetails?.amount) :
          (Math.round((specificCourseDataContainer?.testSeriesDetails?.amount))))

    setCourseRef(returnDays(specificCourseDataContainer?.course?.durationType));
    setCourseDuration(specificCourseDataContainer?.course?.duration);
    setCourseBillingPlan(specificCourseDataContainer?.course?.amount ? returnDays(specificCourseDataContainer?.course?.billingPlan) : null);
    setInstallments(specificCourseDataContainer?.course?.installmentDetails ? JSON.parse(specificCourseDataContainer?.course?.installmentDetails) : []);

    if (courseTypeName == "studyMaterials" || courseTypeName == "videoResources")
      setDiscountDetails(specificCourseDataContainer?.offers ?? [])

    if (courseTypeName == "onlineTestSeries")
      setDiscountDetails(specificCourseDataContainer?.testSeriesDetails?.offers ?? [])

    if (courseTypeName == "liveCourses")
      setDiscountDetails(specificCourseDataContainer?.course?.offers ?? [])
  }, []);
  useEffect(() => {
    let temp = 0;
    for (let i = 0; i < installments?.length; i++) {
      if (
        installments &&
        installments[i]?.installment &&
        installments[i]?.installment !== undefined
      ) {
        temp++;
      }
    }
    if (installments?.length == "0") {
      setCounter(0);
    } else {
      setCounter(temp);
    }
  }, [installments]);

  useEffect(() => {
    clacInstallments();
  }, [coursePrice, courseRef, courseBillingPlan, courseDuration]);

  const clacInstallments = () => {
    let inst = [];
    if (coursePrice == "") {
      inst = [];
    } else if (
      courseBillingPlan !== "One time" &&
      courseBillingPlan !== "Customize"
    ) {
      let daysToYear = 0;
      if (courseRef == "1" && courseDuration >= "360") {
        daysToYear = parseInt(parseInt(courseDuration) % 365);
      }
      let noOfInst = daysToYear
        ? (parseInt(courseDuration) * parseInt(courseRef)) /
        parseInt(courseBillingPlan)
        : Math.floor(
          (parseInt(courseDuration) / 365) * 360 * parseInt(courseRef)
        ) / parseInt(courseBillingPlan);
      if (parseInt(courseDuration) % 360 == 0 && courseRef == "1") {
        noOfInst = noOfInst;
      }
      for (let i = 0; i < ceil(noOfInst); i++) {
        var numb = parseInt(discountedPrice - registrationFee) / ceil(noOfInst);
        numb = numb.toFixed(2);
        inst[i] = {
          installment:
            JSON.parse(specificCourseDataContainer?.course?.installmentDetails)[i]
              ?.installment && !forInstallmentReset
              ? JSON.parse(specificCourseDataContainer?.course?.installmentDetails)[i]
                ?.installment
              : numb,
        };
      }
    } else if (courseBillingPlan == "One time") {
      inst[0] = {
        installment: forInstallmentReset
          ? parseInt(discountedPrice - registrationFee).toFixed(2)
          : JSON.parse(specificCourseDataContainer?.course?.installmentDetails)[0]
            ?.installment,
      };
    } else if (courseBillingPlan == "Customize") {
      inst = !isCustomizePlan ?
        JSON.parse(specificCourseDataContainer?.course?.installmentDetails)
        :
        [
          {
            installment: null,
            dueDate: null,
          },
        ];
      setInstallments(inst);
      setInstCust([...inst]);
    }
    setInstallments([...inst]);
  };
  const AddCustomiseInstallMent = () => {
    instCust[instCust?.length] = {
      installment: "",
    };
    setInstCust(instCust);
    setInstallments([...instCust]);
  };
  const isValidNumber = (e) => {
    let pattern1 = /[^\d.]+/;
    let pattern2 = /^[^.]+(\.{0}[^.]*|\.{1}[^.]{0,2})$/;
    return (
      e.target.value === "" ||
      (pattern2.test(e.target.value) && !pattern1.test(e.target.value))
    );
  };

  function removeElement(array, index) {
    let tmp = [];
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
      } else {
        tmp.push(array[i]);
      }
    }
    return tmp;
  }

  let disableFeeAddBtn = false;
  installments?.length ?
    installments?.some((item) => (!item?.installment ? disableFeeAddBtn = true : disableFeeAddBtn = false)) :
    disableFeeAddBtn = true

  let totalDiscount = 0;
  courseDiscountDetails.forEach((item) => {
    console.log("item", item);
    totalDiscount += +item.amount;
  });

  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={isFeeDetailsModalVisible}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}
        width={600}>
        <div style={{ fontSize: "22px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>
          Fee Details
        </div>
        <div style={{ height: 400, overflowY: "scroll" }}>

          <div style={{ width: (courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" || courseTypeName == "videoResources") ? "100%" : "49%", padding: 10 }}>
            <h6
              style={{
                fontSize: "12px",
                color: "#636363",
                fontWeight: "bold",
              }}
            >
              Total fee amount
            </h6>
            <Input
              prefix="₹"
              type="number"
              style={{
                width: "100%",
                // marginBottom: "20px",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
              }}
              placeholder="Enter fee amount"
              value={coursePrice
                // courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" || courseTypeName == "videoResources" ? coursePrice 
                // :
                //  (parseFloat(coursePrice) + parseFloat(specificCourseDataContainer?.course?.registrationFee)).toFixed(2)
              }
              onChange={(e) => {
                if (e.target.value == "") {
                  setCourseBillingPlan(null);
                  setDiscount(null)
                  setDiscountedPrice(null)

                }
                // setCoursePrice(
                //   e.target.value != " " &&
                //     e.target.value != "0" &&
                //     /^[\d]*$/.test(e.target.value)
                //     ? e.target.value
                //     : coursePrice
                //       ? coursePrice
                //       : null
                // );
                setCoursePrice(e.target.value)
                setDiscountedPrice(
                  discountType == 'amount' ?
                    Math.round((e.target.value - discount) * 100) / 100
                    :
                    Math.round((e.target.value * (1 - (0.01 * discount))) * 100) / 100)


              }}
            />
          </div>
          <div style={{ display: "flex", gap: 20, padding: 10 }}>
            <div>
              <div className='m-b-5' style={inputHeadingStyle}>Discount</div>
              <Input.Group compact >
                <Select defaultValue="percentage" style={{ width: '50%' }} value={discountType} onChange={(value) => {
                  setDiscountType(value);
                  setDiscountedPrice(
                    value == 'amount' ?
                      Math.round((coursePrice - discount) * 100) / 100
                      :
                      Math.round((coursePrice * (1 - (0.01 * discount))) * 100) / 100)
                  setCourseBillingPlan(null);

                }} >
                  <Option value="percent">Percentage</Option>
                  <Option value="amount">Amount</Option>
                </Select>
                {console.log("discount", typeof discount)}
                <Input style={{ width: '50%', height: "32px" }}
                  placeholder={`discount ${discountType}`} type="number"
                  value={discount == null ? 0 : discount} onChange={(e) => {
                    calcDiscount(e),
                      setCourseBillingPlan(null);


                  }} />
              </Input.Group>

            </div>
            <div>
              <div >
                <div className='m-b-5' style={inputHeadingStyle}>Discounted fee</div>

                <Input
                  type="number"
                  placeholder="Enter amount"
                  prefix="₹"
                  readOnly
                  value={discountedPrice == null ? coursePrice : discountedPrice}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", width: "100%", padding: 10 }}>
            {courseTypeName == "studyMaterials" || courseTypeName == "videoResources" || courseTypeName == "onlineTestSeries" ? (<></>) :
              <div style={{ width: (courseTypeName == "studyMaterials" || courseTypeName == "onlineTestSeries" || courseTypeName == "videoResources") ? "100%" : "49%" }}>
                <h6
                  style={{
                    fontSize: "12px",
                    color: "#636363",
                    fontWeight: "bold",
                  }}
                >
                  Registration fee
                </h6>
                <Input
                  prefix="₹"
                  type='number'
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                    border: "#E6E6E6 solid 1px",
                    borderRadius: "5px",
                  }}
                  placeholder="Enter fee amount"
                  value={registrationFee}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setRegistration('')
                      setCourseBillingPlan(null);
                    } else {
                      setRegistration(e.target.value)
                    }
                    // setCoursePrice(
                    //   e.target.value != " " &&
                    //     e.target.value != "0" &&
                    //     /^[\d]*$/.test(e.target.value)
                    //     ? e.target.value
                    //     : coursePrice
                    //       ? coursePrice
                    //       : null
                    // );
                  }}
                />
              </div>}
            {(courseTypeName == "studyMaterials" || courseTypeName == "videoResources" || courseTypeName == "onlineTestSeries") ?
              <></>
              :
              <div style={{ width: "49%" }}>
                <h6
                  style={{
                    fontSize: "12px",
                    color: "#636363",
                    fontWeight: "bold",
                  }}
                >
                  Fee payment plan
                </h6>
                <Select
                  placeholder="Select plan"
                  defaultValue={'none'}
                  style={{ width: "100%" }}
                  value={courseBillingPlan == null ? 'none' : courseBillingPlan}
                  onChange={(e) => {
                    // setDiscount(0)

                    setCourseBillingPlan(e);
                    setForInstallmentReset(true);
                    if (e == "Customize") {
                      setIsCustomizePlan(true)
                    }
                  }}
                >
                  <Option value="none">None</Option>
                  {60 <= parseInt(courseDuration) * parseInt(courseRef) ? (
                    <Option value="30">Monthly</Option>
                  ) : null}
                  {180 <= parseInt(courseDuration) * parseInt(courseRef) ? (
                    <Option value="90">Quarterly</Option>
                  ) : null}
                  {(
                    365 <= parseInt(courseDuration) && courseRef == "1"
                      ? 365 <= parseInt(courseDuration) && courseRef == "1"
                      : 360 <= parseInt(courseDuration) * parseInt(courseRef) &&
                      courseRef != "1"
                  ) ? (
                    <Option value="180">Half Yearly</Option>
                  ) : null}
                  {(
                    730 <= parseInt(courseDuration) && courseRef == "1"
                      ? 730 <= parseInt(courseDuration) && courseRef == "1"
                      : 720 <= parseInt(courseDuration) * parseInt(courseRef) &&
                      courseRef != "1"
                  ) ? (
                    <Option value="360">Annually</Option>
                  ) : null}
                  <Option value="One time">One time</Option>
                  <Option value="Customize">Customize</Option>
                </Select>
              </div>
            }
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {coursePrice &&
              installments?.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "50%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "10px", width: "100%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#636363",
                          marginBottom: "7px",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                        }}
                      >
                        Installment {index + 1}
                      </p>
                      <Input
                        prefix="₹"
                        style={{
                          width: "105%",
                          marginBottom: "20px",
                          border: "#E6E6E6 solid 1px",
                          borderRadius: "5px",
                          color: "#000000",
                          marginRight: 10
                        }}
                        placeholder="Enter Installment Amount"
                        value={item?.installment}
                        onChange={(e) => {
                          let newInstallment = isValidNumber(e)
                            ? e.target.value
                            : installments[index]?.installment;
                          instCust[index] = {
                            installment: newInstallment,
                          };
                          installments[index]["installment"] = newInstallment;
                          setInstallments([...installments]);
                          setInstCust([...installments]);
                        }}
                      />{" "}
                    </div>
                    <>
                      {(installments?.length >= 1 && courseBillingPlan === "Customize") ?
                        <div style={{ marginTop: "30px", marginLeft: 20 }}
                          onClick={() => {
                            installments.splice(index, 1);
                            setInstallments([...installments])
                            setInstCust([...installments]);
                          }}
                        ><MinusCircleOutlined style={{ color: "red", cursor: "pointer", fontSize: 18 }} />
                        </div>
                        :
                        <></>
                      }
                    </>
                  </div>
                </div>
              ))}
          </div>
          <div className='r-c-c'>
            <div style={{ display: "flex", justifyContent: "space-around", width: "70%" }}>
              {courseBillingPlan == "Customize" ? (
                <div
                  className="r-c-c m-b-20"
                  style={{
                    cursor: installments?.length ? installments[installments?.length - 1]?.installment ? "pointer" : "not-allowed" : "pointer",
                    color: "#1089FF",
                    fontWeight: "600",
                    fontFamily: "roboto",
                    opacity: installments?.length ? installments[installments?.length - 1]?.installment ? 1 : 0.5 : 1
                  }}
                  onClick={() => {
                    if (installments.length == 0) {
                      AddCustomiseInstallMent();
                    } else if (installments[installments?.length - 1].installment) {
                      AddCustomiseInstallMent();
                    }
                  }}
                >
                  + ADD INSTALLMENT
                </div>
              ) : null}
              {/* {courseDiscountDetails?.length == 0 ?
                <div
                  style={{
                    cursor: coursePrice ? "pointer" : "not-allowed",
                    color: "#1089FF",
                    fontWeight: "600",
                    fontFamily: "roboto",
                  }}
                  onClick={() => { if (coursePrice) { setCourseDiscountModalVisible(true) } else { }}}
                >
                  + ADD DISCOUNT
                </div>
                :
                <></>
              } */}
            </div>
          </div>
          {/* {courseDiscountDetails?.length > 0 ?
            <>
              <div style={{ fontSize: 12, color: "#636363", fontWeight: 600, padding: 10 }}>Discount summary</div>
              <div style={{ border: " 1px solid #E6E6E6", borderRadius: 5, padding: 10, margin: "0px 10px" }}>
                <div className='font r-c-sb' style={{ color: "#191919", fontSize: 14 }}>
                  <div>Discounted fee amount</div>
                  <div>₹ {discountedPrice || "0"}</div>
                </div>
                {courseDiscountDetails?.length != 0 ?
                  <>
                    {courseDiscountDetails?.map((item, index) => (
                      <div className='font r-c-sb m-t-5' style={{ color: "#1089FF", fontSize: 14 }}>
                        <div>{item?.name}
                          <span
                            onClick={() => {
                              let temp = courseDiscountDetails?.length
                                ? courseDiscountDetails
                                : [];
                              let temp1 = removeElement(temp, index);
                              setDiscountDetails(temp1);
                            }}
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "10px",
                              marginLeft: "10px",
                              border: "1px solid red",
                              borderRadius: "10px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                          >
                            Remove
                          </span>
                        </div>
                        <div>- ₹ {item?.amount}</div>
                      </div>
                    ))}
                  </>
                  :
                  <></>
                }
                <div className='font r-c-sb m-t-5' style={{ color: "#1089FF", fontSize: 14 }}>
                  <div>Discount</div>
                  <div style={{ cursor: "pointer" }} onClick={() => { setCourseDiscountModalVisible(true) }}>- ₹ ADD</div>
                </div>
                <div className='m-t-10' style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div>
                <div className='font r-c-sb m-t-5' style={{ color: "#191919", fontSize: 14 }}>
                  <div>
                    <div>Total payable fee with discount</div>
                    <div>(Inc.Registration fee)</div>
                  </div>
                  <div>₹ {discountedPrice - totalDiscount}</div>
                </div>
                <div className='m-t-10' style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div>
              </div>
            </>
            :
            <></>
          } */}
        </div>
        <div className='r-c-c m-t-20'>
          <Button
            loading={addButtonLdr}
            disabled={(courseTypeName == "studyMaterials" || courseTypeName == "videoResources" || courseTypeName == 'onlineTestSeries') ? (!coursePrice) : (!coursePrice)}
            type="primary"
            onClick={() => {
              setAddButtonLdr(true);
              if ((courseTypeName == "studyMaterials" || courseTypeName == "videoResources" || courseTypeName == 'onlineTestSeries')) {


                postAddFeeStudyMaterial(
                  discountType,
                  discount,
                  () => setAddButtonLdr(false),
                  () => {
                    setFeeDetailsModalVisible(false);
                    togglePageLoader(true);
                    if (courseTypeName == 'studyMaterials') {
                      getSpecificSMData(() => togglePageLoader(false));
                    } else if (courseTypeName == 'onlineTestSeries') {
                      getOnlineTestSeriesCourseDetails(() => togglePageLoader(false))
                    }
                    else {
                      getSpecificVideoCourseData(() => togglePageLoader(false))
                    }
                  },
                );
              }
              else {
                postAddFeeInstallments(
                  registrationFee,
                  discountType,
                  discount,
                  () => setAddButtonLdr(false),
                  () => {
                    setFeeDetailsModalVisible(false);
                    togglePageLoader(true);
                    getSpecificCourseData(() => togglePageLoader(false));
                  },
                );
              }
            }} >ADD</Button>
        </div>
      </Modal>
      {isCourseDiscountModalVisible &&
        <CourseAddDiscountModal
          isCourseDiscountModalVisible={isCourseDiscountModalVisible}
          setCourseDiscountModalVisible={setCourseDiscountModalVisible}
        />
      }
    </div>
  )
}
const mapStateToProps = (state) => {
  const { courseDuration, courseRef, coursePrice, courseBillingPlan, installments } = state.course;
  const { specificCourseDataContainer, courseDiscountDetails, courseCreateObj } = state.courseManagement;
  return {
    courseDuration, courseRef, coursePrice, courseBillingPlan, installments, specificCourseDataContainer, courseDiscountDetails, courseCreateObj
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseDuration: (val) => dispatch(setCourseDuration(val)),
  setCourseRef: (val) => dispatch(setCourseRef(val)),
  setCoursePrice: (val) => dispatch(setCoursePrice(val)),
  setCourseBillingPlan: (val) => dispatch(setCourseBillingPlan(val)),
  setInstallments: (val) => dispatch(setInstallments(val)),
  setDiscountDetails: (val) => dispatch(setDiscountDetails(val)),
  postAddFeeInstallments: (registrationFee, discountType, discount, callback, successCallBack) => dispatch(postAddFeeInstallments(registrationFee, discountType, discount, callback, successCallBack)),
  postAddFeeStudyMaterial: (discountType, discount, callback, successCallBack) => dispatch(postAddFeeStudyMaterial(discountType, discount, callback, successCallBack)),
  setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FeeDetailsModal);

// export default FeeDetailsModal