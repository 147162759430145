import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import 'antd/lib/modal/style/index';
import '../broadcastStyles.css';
import { BroadcastRecordingEdit, deleteBroadcastRecording, publishBroadcastRecordings, setCurrentRecordingPath, setDownloadMeta, setSelectedRecordingsIndex } from 'actions/broadcast';
import { EditOutlined, DeleteOutlined, CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Input, Space, Tooltip, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { NodataCard } from './rightColumn.js';
import { IoMdDownload } from "react-icons/io";
import { TbReplace } from "react-icons/tb";
import { FaInfoCircle } from "react-icons/fa";
import { Utils } from 'services';
import { fetchSchedule, setCardData } from 'actions/schedule';
import DeleteModalOffer from 'pages/adminMarketPlaceCourse/offerDeleteModal';
import ReplaceVideos from 'pages/digitalLibrary/modals/replaceModal';
import { Button } from 'components/Button';
import { fetchClassLibraryData } from 'actions/digitalLibrary';
import { recorder } from '../browserRecorder';
import { EventRegister } from 'react-native-event-listeners';

export const RecordingsCard = ({ name, duration, fileSize, filePath, setCurrentRecordingPath, history,
  isFromModal, index, setSelectedRecordingsIndex, selectedIndex, status,
  setDownloading, downloading, setProgress, progress, allowDownload, isEdit, setIsEdit, enableEditField, setEnableEditField, setRecordingsModalVisibility,
  BroadcastRecordingEdit, roomName, deleteBroadcastRecording, setCheckName, type, downloadPath }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [replaceModal, setReplaceModal] = useState(false)
  console.log("roomName", name, isEdit);

  const params = {
    fileName: isEdit,
    roomName: roomName
  }
  console.log("status43", filePath);

  useEffect(() => {
    setIsEdit(name)
    setCheckName(name)
  }, [name])


  const onPlay = () => {
    if (!status) {
      return
    }
    setCurrentRecordingPath(filePath);
    if (isFromModal) {
      history.push('/recordings');
    }
    setSelectedRecordingsIndex(index);
  }
  const onDownload = async () => {
    if (!status) {
      return
    }
    try {
      setDownloading(true);
      setProgress(0);
      const response = await fetch(downloadPath);
      const total = Number(response.headers.get('content-length')) || response.body.length;
      const reader = response.body.getReader();
      let receivedLength = 0;
      let chunks = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setDownloading(false);
          break;
        }
        chunks.push(value);
        receivedLength += value.length;
        const progress = (receivedLength / total) * 100;
        if (progress === 0) {
          continue
        } else {
          setProgress(Math.floor(progress))
        }
      }
      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.mp4`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setDownloading(false);
      console.error('Error downloading video:', error);
    }
  }
  const displayDuration = (duration) => {
    return Math.floor(duration / 60000) === 0 ? `${Math.round(duration / 1000)} secs` : `${Math.floor(duration / 60000)} mins ${Math.round((duration % 60000) / 1000)} secs`
  }
  const displaySize = (size) => {
    const MB = (size / (1024 * 1024)).toFixed(2)
    return MB < 1.00 ? `${Math.round(size / 1024)} KB` : `${MB} MB`
  }
  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };
  if (type==='Upload'){
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
        <Button loading={false} type='primary' onClick={() => {
           setReplaceModal(true);
           setRecordingsModalVisibility(false) 
        }}><PlusCircleOutlined style={{marginRight:5}}/> Upload</Button> 
        <ReplaceVideos
          isUploadModal={replaceModal}
          setIsUploadModal={setReplaceModal}
          setRecordingsModalVisibility={setRecordingsModalVisibility}
          roomName={roomName}
          isupload={true}
          />
      </div>
    )
  }
  return (
    <div className={`m-t-10 m-b-10 full-width radius-10 hover-shadow shadow-box ${allowDownload ? 'recordings-grid-4' : 'recordings-grid-3'}`}
      style={{ width: "98%", height: 80, backgroundColor: selectedIndex === index ? 'rgb(246, 244, 255)' : null, marginLeft: "5px" }}>
      <div className="r-c-c">
        <img style={{ height: 50, width: 50 }} src={require('./assets/ic-dl-lecture-videos.svg').default} alt="" />
      </div>
      <div className="r-fs-c-c" style={{ overflow: "hidden" }}>
        {!enableEditField ?
          <div style={{ fontSize: 14, fontWeight: 600, }}>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 14, color: "#191919", fontWeight: 600, width: 190, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</Typography.Text>
          </div> :
          (<>
            <div style={{ marginLeft: "5px" }}><Input bordered={true} value={isEdit} style={{ width: "200px" }} onKeyDown={handleKeyDown} onChange={(e) => { setIsEdit(e.target.value)}} /></div>
            {/* <div style={{borderBottom:"2px solid #E6E6E6",width: "200px" }}></div> */}
          </>)

        }

        <div>{duration ? displayDuration(duration) : ''}</div>
        <div>{fileSize ? displaySize(fileSize) : ''}</div>
      </div>
      <div className='r-c-c' style={{ gap: 10, marginRight: '30px' }}>
        <div className={`r-c-c ${status ? 'recordingsPlayButton cursor-pointer' : 'cursor-not-allowed'}`} onClick={onPlay}>
          <Tooltip placement="top" overlay="Play">

            <img style={{ height: 30, width: 30, opacity: status ? 1 : 0.3 }} src={require('./assets/Group 10666.svg').default} alt="" />
          </Tooltip>

        </div>
        {
          allowDownload ?
            <>
              {
                !downloading ?
                  <div className={`r-c-c ${status ? 'recordingsPlayButton cursor-pointer' : 'cursor-not-allowed'}`} onClick={onDownload}>
                    <Tooltip placement="top" overlay="Download">
                      <IoMdDownload style={{ fontSize: 25, color: "#594099", opacity: status ? 1 : 0.3 }} />
                    </Tooltip>
                  </div>
                  :
                  <div className='r-c-c' style={{ fontSize: 10, color: "#594099", fontWeight: "900" }}><span>{progress} %</span></div>
              }
            </>
            : null

        }
        {allowDownload ? (<>{!enableEditField ?
          <div className={`${status ? 'cursor-pointer' : 'cursor-not-allowed'}`}  style={{ fontSize: "20px", color: "#1089FF" }} onClick={() => { 
            if (!status){
              return
            }
            setEnableEditField(true), setIsEdit(name) }}>
            <Tooltip placement="top" overlay="Edit">

              <EditOutlined style={{opacity:status?1:0.3}}/>
            </Tooltip>

          </div> :
          <div style={{ fontSize: "20px", color: "#1089FF", cursor: "pointer" }} onClick={() => {
            BroadcastRecordingEdit(params, () => { setEnableEditField(false) }, () => { 
              EventRegister.emit('apicall:scheduleHome');
              setRecordingsModalVisibility(false) })
          }}>
            <Tooltip placement="top" overlay="Save">

              <CheckCircleOutlined />
            </Tooltip>

          </div>}
          <div className={`${status ? 'cursor-pointer' : 'cursor-not-allowed'}`}  onClick={() => { 
            if (!status) {
              return
            }
            setReplaceModal(true);
            setRecordingsModalVisibility(false) }}>
            <Tooltip placement="top" overlay="Replace">

              <TbReplace style={{ fontSize: 20, color: "#594099", marginTop: "5px", opacity:status?1:0.3 }} />
            </Tooltip>
          </div>
          <div className={`${status ? 'cursor-pointer' : 'cursor-not-allowed'}`}   style={{ fontSize: "20px", color: "#FF414D", marginTop: "-3px" }} onClick={() => { 
            if (!status){
              return
            }
            setIsModalVisible(true); setRecordingsModalVisibility(false) }}>
            <Tooltip placement="top" overlay="Delete">

              <DeleteOutlined style={{opacity:status?1:0.3}}/>
            </Tooltip>

          </div></>) : null}
      </div>
      <DeleteModalOffer
        chooseModal={isModalVisible}
        setChooseModal={setIsModalVisible}
        setRecordingsModalVisibility={setRecordingsModalVisibility}
        modalTrue={'digital library'}
        deleteBroadcastRecording={deleteBroadcastRecording}
        roomName={roomName} />
      <ReplaceVideos
        isUploadModal={replaceModal}
        setIsUploadModal={setReplaceModal}
        setRecordingsModalVisibility={setRecordingsModalVisibility}
        roomName={roomName}
        deleteBroadcastRecording={deleteBroadcastRecording}
      />

    </div>
  )
}

function RecordingsModal({ isRecordingsModalVisible, setRecordingsModalVisibility, recordingsData, setCurrentRecordingPath,
  completedSubjectName, setSelectedRecordingsIndex, selectedRecordingsIndex, cardData, setDownloadMeta, BroadcastRecordingEdit,
  downloadMeta, user, setCardData, deleteBroadcastRecording, publishBroadcastRecordings ,fetchClassLibraryData,fetchSchedule}) {
  const streamId = (user && user.org && cardData) ? `${user.org.id}xxxx${cardData.roomName}` : false 
  const [isEdit, setIsEdit] = useState('')
  const [enableEditField, setEnableEditField] = useState(false)
  const [checkName, setCheckName] = useState(false)
  const [isPublish, setIsPublish] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [islocalrecordingavailable, setlocalrecordingavailable] = useState(false);

  console.log("isPublish",cardData);
  const checklocalrecordingavailability = async () => {
    if (streamId){
      console.log("checking local recordings available for", streamId)
      const b = await recorder.checkLocalRecordingsAvailable(streamId)
      setlocalrecordingavailable(b);
      console.log("b is", b) 
    }else{
      setlocalrecordingavailable(false);
    }
  }
  useEffect(() => {
    checklocalrecordingavailability();
    setIsPublish(cardData?.published)
  }, [cardData])


  const history = useHistory();


  const throttledSetDownloadMeta = useRef(Utils.throttleHigh((key, val) => {
    setDownloadMeta(key, val)
  }, 1000, { leading: true, trailing: true }));

  const progress = (downloadMeta && Object.keys(downloadMeta).length != 0 &&
    (cardData.id in downloadMeta)) ? downloadMeta[cardData.id].progress : 0

  const downloading = (downloadMeta && Object.keys(downloadMeta).length != 0 &&
    (cardData.id in downloadMeta)) ? downloadMeta[cardData.id].downloading : false

  const setProgress = (progress) => {
    let newObj = { ...downloadMeta[cardData.id] }
    if (progress != 100) {
      newObj.downloading = true
    }
    newObj.progress = progress
    throttledSetDownloadMeta.current(cardData.id, newObj)

  }

  const setDownloading = (bool) => {
    let newObj = { ...downloadMeta[cardData.id] }
    newObj.downloading = bool
    setDownloadMeta(cardData.id, newObj)
  }

  useEffect(() => {
    if (isRecordingsModalVisible) {
      if (!downloadMeta) {
        setDownloadMeta('clear')
      } else if (cardData.id in downloadMeta) {

      } else {
        setDownloadMeta(cardData.id, { downloading: false, progress: 0 })
      }
      if (recordingsData?.length && cardData) {
        cardData.recordingName = cardData.recordingName?.split('_namespace_')?.join(' ')
        setCardData({ ...cardData })
      }
    }
  }, [isRecordingsModalVisible])
  return (
    <Modal
      centered
      className='modal-round-corner'
      style={{ padding: 0 }}
      visible={isRecordingsModalVisible}
      onCancel={null}
      onOk={null}
      footer={null}
      closable={false}
    >
      <div style={{ maxHeight: 500, position: 'relative' }}>
        <div className="text-center cursor-pointer"
          style={{ fontSize: 17, position: 'absolute', right: 0, width: 20, height: 20 }}
          onClick={() => {
            setRecordingsModalVisibility(false);
            setEnableEditField(false)
            setIsEdit(checkName)
          }}> x </div>
        <div className="text-center bold-600 text-sm">{completedSubjectName}</div>
        <div className="lowgrey text-xmd m-b-20 m-t-20">Recording</div>
        <div style={{ overflowY: 'auto', maxHeight: 400, }}>
          {
            islocalrecordingavailable?
            <Alert message="Offline recording video is available for download!" type="info" action={
              <Space>
                <Button onClick={()=>{
                  if (islocalrecordingavailable){
                    recorder.externalDownloadRecording(streamId, cardData.recordingName)
                    setlocalrecordingavailable(false)
                  }
                }} type="link" size="small">
                  Download
                </Button>
              </Space>} 
              closable style={{ backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6' }} />
              :false
          }

          {console.log("recordingsData", cardData?.id)}
          {(cardData?.recordCount && recordingsData && recordingsData?.length > 0)
            ?
            <>
              {recordingsData.map((recording, index) => (
                <RecordingsCard
                  type="Card"
                  name={cardData.recordingName}
                  duration={recording.duration}
                  fileSize={recording.fileSize}
                  filePath={recording.url}
                  downloadPath={recording.downloadurl}
                  setCurrentRecordingPath={setCurrentRecordingPath}
                  history={history}
                  isFromModal={true}
                  index={index}
                  selectedIndex={-1}
                  setSelectedRecordingsIndex={setSelectedRecordingsIndex}
                  status={cardData.transcoded}
                  downloading={downloading}
                  setDownloading={setDownloading}
                  progress={progress}
                  setProgress={setProgress}
                  allowDownload={user.privilage?.includes("SuperAdmin") || user.privilage?.includes('Admin')}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  enableEditField={enableEditField}
                  setEnableEditField={setEnableEditField}
                  setRecordingsModalVisibility={setRecordingsModalVisibility}
                  BroadcastRecordingEdit={BroadcastRecordingEdit}
                  roomName={cardData?.roomName}
                  deleteBroadcastRecording={deleteBroadcastRecording}
                  setCheckName={setCheckName}
                />
              ))
              }
              {
                cardData.transcoded ?
                  null :
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <FaInfoCircle className="lowgrey" style={{ marginRight: 10 }} />
                    <div className="lowgrey text-xmd">
                      The recording video is being transcoded, kindly check later
                    </div>
                  </div>
              }
            </>
            :
            <NodataCard type="Recordings" />
          }
        </div>
        {(user.privilage?.includes("SuperAdmin") || user.privilage?.includes('Admin')) && cardData?.recordCount!=0 && recordingsData?.length >= 1 ?
          <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
            {!isPublish ?
              <Button loading={isLoader} type='primary' onClick={() => {
                setIsLoader(true)
                publishBroadcastRecordings(cardData?.id, true, () => { }, () => { setIsLoader(false),setRecordingsModalVisibility(false),fetchClassLibraryData(),fetchSchedule() })
              }}>Publish</Button> :
              <Button loading={isLoader} type='primary' onClick={() => {
                setIsLoader(true)

                publishBroadcastRecordings(cardData?.id, false, () => { }, () => { setIsLoader(false),setRecordingsModalVisibility(false),fetchClassLibraryData() ,fetchSchedule()})
              }}>Unpublish</Button>}

          </div> : null}
          {
            cardData?.recordCount===0 || recordingsData?.length===0?
            <RecordingsCard
            type="Upload"
            name={cardData.recordingName}
            duration={100}
            fileSize={100}
            filePath={100}
            setCurrentRecordingPath={setCurrentRecordingPath}
            history={history}
            isFromModal={true}
            index={0}
            selectedIndex={-1}
            setSelectedRecordingsIndex={setSelectedRecordingsIndex}
            status={cardData.transcoded}
            downloading={downloading}
            setDownloading={setDownloading}
            progress={progress}
            setProgress={setProgress}
            allowDownload={user.privilage?.includes("SuperAdmin") || user.privilage?.includes('Admin')}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            enableEditField={enableEditField}
            setEnableEditField={setEnableEditField}
            setRecordingsModalVisibility={setRecordingsModalVisibility}
            BroadcastRecordingEdit={BroadcastRecordingEdit}
            roomName={cardData?.roomName}
            deleteBroadcastRecording={deleteBroadcastRecording}
            setCheckName={setCheckName}
          />
            :null
          }
      </div>
    </Modal>
  );
}


const mapStateToProps = state => {
  const { user } = state.session;
  const { recordingsData, completedSubjectName, selectedRecordingsIndex, downloadMeta } = state.broadcast;
  const { cardData } = state.schedule;
  return { recordingsData, completedSubjectName, selectedRecordingsIndex, cardData, downloadMeta, user }
};

const mapDispatchToProps = dispatch => ({
  setCurrentRecordingPath: (filepath) => dispatch(setCurrentRecordingPath(filepath)),
  setSelectedRecordingsIndex: (index) => dispatch(setSelectedRecordingsIndex(index)),
  setDownloadMeta: (key, val) => dispatch(setDownloadMeta(key, val)),
  setCardData: (val) => dispatch(setCardData(val)),
  BroadcastRecordingEdit: (params, callback, successCallback) => dispatch(BroadcastRecordingEdit(params, callback, successCallback)),
  deleteBroadcastRecording: (roomName, callback, succesCallback) => dispatch(deleteBroadcastRecording(roomName, callback, succesCallback)),
  publishBroadcastRecordings: (id, isPublish, callback, successCallback) => dispatch(publishBroadcastRecordings(id, isPublish, callback, successCallback)),
  fetchClassLibraryData: () => dispatch(fetchClassLibraryData()),
  fetchSchedule: (callback) => dispatch(fetchSchedule(callback)),


});

export default connect(mapStateToProps, mapDispatchToProps)(RecordingsModal);
