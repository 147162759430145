import React, { useEffect, useState } from 'react';
import { Heading } from 'components/Typography';
import { Col, Row, Select, Tooltip } from 'antd';
import { Button } from 'components/Button';
import { CloudDownloadOutlined, SettingOutlined } from '@ant-design/icons';
import moment, { months } from 'moment';
import { AiOutlineWarning } from 'react-icons/ai';
import EditAttendanceModal from './editAttendanceModal';
import DateRangeModal from './dateRangeModal';
import { connect } from 'react-redux';
import { downloadAttendanceBySchedule, getAttendanceOverview } from 'actions/profileNew';
import PageDataLoader from 'components/pageDataLoader';
import AttendanceByTimeSheetGraph from './attendanceByTimeSheet';
import TableComponent from './attendanceBySchecduleTable';
import { storeEndDate, storeSelectedRange, storeStartDate, usersDownloadReport, getWebClockIn } from 'actions/attendanceNew';
import { setSpecificUserId } from 'actions/userManagement';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SettingModal from "pages/attendanceNew/Modals/settingModal";


const { Option } = Select;


const AbsentImage = require("../../Assets/user/ic-absent.svg").default;
const OverAllImage = require("../../Assets/user/ic-overall.svg").default;
const PresentImage = require("../../Assets/user/ic-present.svg").default;
const WorkingImage = require("../../Assets/user/ic-working-hrs.svg").default;
const AvgWorkingImage = require("../../Assets/user/ic-avg-working-hrs.svg").default;
const HalfDayImage = require('../../Assets/Attendance/Group 13469.svg').default;

const attendanceOverviewDetails = (attendanceOverview) => [
    [
        {
            label: "Present day(s)",
            value: attendanceOverview?.overview?.PresentCount,
            icon: PresentImage
        },
        {
            label: "Absent day(s)",
            value: attendanceOverview?.overview?.AbsentCount,
            icon: AbsentImage
        },
        {
            label: "Half day(s)",
            value: attendanceOverview?.overview?.HalfDayCount,
            icon: HalfDayImage
        },
    ],
    [
        {
            label: "Total working hours",
            value: attendanceOverview?.overview?.totalWorkingHoursTillDate,
            icon: WorkingImage,
            warningDetails: "Your attendance is 25% lower than minimum requirements."
        },
        {
            label: "Average working hours",
            value: attendanceOverview?.overview?.averageWorkingHoursTillDate,
            icon: AvgWorkingImage,
            warning: attendanceOverview?.warning ? "Low average working hours" : null,
            warningDetails: `Your avg working hours is short by ${attendanceOverview?.shortFallPercentage}%`
        },
        {
            label: "Overall percentage",
            value: attendanceOverview?.overview?.overallPercentage,
            icon: OverAllImage,
        },
    ]
]

const years = [2023]

const TimeSheetGraph = ({ attendanceOverview }) => {

    return (
        <div style={{ width: "100%", height: "400px", background: "#FFFFFF", borderRadius: 6, marginTop: "20px" }}>
            <div className='flex-row m-t-20' style={{ justifyContent: "center" }}>
                <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>PRESENT DAYS</div>
                    <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>{attendanceOverview?.attendanceByTimesheet?.presentDays}</div>
                </div>
                <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>ABSENT DAYS</div>
                    <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>{attendanceOverview?.attendanceByTimesheet?.absentDays}</div>
                </div>
                <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>HALF DAYS</div>
                    <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>{attendanceOverview?.attendanceByTimesheet?.halfDays}</div>
                </div>
                <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>TOTAL WORKING HOURS</div>
                    <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>{attendanceOverview?.attendanceByTimesheet?.totalWorkingHoursTillDate}</div>
                </div>
                <div style={{ width: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500", fontSize: "10px", fontFamily: "Roboto", color: "#AEAEAE" }}>AVG. WORKING HOURS</div>
                    <div style={{ fontWeight: "bold", fontSize: "13px", fontFamily: "Roboto", color: "#636363" }}>{attendanceOverview?.attendanceByTimesheet?.averageWorkingHoursTillDate}</div>
                </div>
            </div>
            <div className='m-t-40 m-l-20' style={{ height: "280px", }}>
                <AttendanceByTimeSheetGraph attendanceOverview={attendanceOverview} />
            </div>
        </div>
    )
}

const AttendanceSummary = ({ attendanceOverview, getAttendanceOverview, user, setPageLoader, storeSelectedRange, selectedRangeContainer, storeStartDate, storeEndDate, startDateContainer, usersDownloadReport, setSpecificUserId, downloadAttendanceBySchedule, endDateContainer, specificUserId, userRowDetails, getWebClockIn, fromWhere }) => {
    const [updateAttendanceModalVisible, setUpdateAttendanceModalVisible] = useState(false);
    const [rangeSelectionVisible, setRangeSelectionVisible] = useState(false);
    const [timeSheetMonth, setTimeSheetMonth] = useState(moment().format("MMMM"))
    const [timeSheetYear, setTimeSheetYear] = useState(moment().format('YYYY'));
    const [scheduleMonth, setScheduleMonth] = useState(moment().format("MMMM"))
    const [scheduleYear, setScheduleYear] = useState(moment().format("YYYY"))
    const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);



    const location = useLocation();
    // console.log(location, 'ff')
    useEffect(() => {
        setPageLoader(true)
        getAttendanceOverview({
            userId: location?.state?.key ? userRowDetails?.id : user.id,
            attendanceByTimesheetMonth: timeSheetMonth,
            attendanceByTimesheetYear: timeSheetYear,
            attendanceByScheduleMonth: scheduleMonth,
            attendanceByScheduleYear: scheduleYear
        }, () => setPageLoader(false))
    }, [timeSheetMonth, timeSheetYear, scheduleMonth, scheduleYear]);

    const attendanceByScheduleOnPress = () => {
        let params = {
            attendanceDetailsMonth: scheduleMonth,
            attendanceByScheduleYear: scheduleYear,
        }  
        setSpecificUserId(user?.id)
        downloadAttendanceBySchedule(params);
    };

    return (
        <div>
            {((user?.privilage?.length == 0) && (user?.role?.includes('Teacher') || user?.role?.includes('Data Operator') || user?.role?.includes('Operation')))
                ?
                <div className='r-jsb'>
                    <Heading>Attendance overview</Heading>
                </div>


                : <div className='r-jsb'>
                    <Heading>Attendance overview</Heading>

                    <div onClick={(e) => {
                        getWebClockIn(location?.state?.key ? specificUserId : user.id);
                        e.stopPropagation();
                        setIsSettingModalOpen(true)
                    }}
                    style={{cursor:'pointer'}}
                    ><SettingOutlined style={{ fontSize: "20px", }} /></div>
                </div>
            }

            <div style={{ color: "#636363", fontSize: 16, marginTop: -10 }}>{attendanceOverview?.since && attendanceOverview?.since == 'Invalid date' ? <></> : attendanceOverview?.since ? "from " + moment(attendanceOverview?.since, 'YYYY-MM-DD').format("DD/MM/YYYY") : ""}
                {attendanceOverview?.tillDate ? ` to ${moment(attendanceOverview?.tillDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}` : ''}</div>
            {attendanceOverviewDetails(attendanceOverview)?.map((set, index) =>
                <Row className='m-t-20' key={index}>
                    {set?.map((item, i) => (
                        <Col span={8} key={i}>
                            <div className='r-ac m-r-10' style={{}}>
                                <img src={item?.icon} alt="" className='m-r-5' />
                                <div>
                                    <div style={{ fontSize: 18, fontWeight: 500 }}>{item?.value}</div>
                                    <div style={{ color: "#636363" }}>{item?.label}</div>
                                </div>
                            </div>
                            {item?.warning ?
                                <Tooltip title={<div className='r-c-c'><AiOutlineWarning style={{ fontSize: 24 }} className="m-r-10" />{item?.warningDetails}</div>}>
                                    <div className='m-l-40 r-c-c' style={{ color: "#FF414D", fontWeight: 500, marginTop: -10 }}>
                                        <AiOutlineWarning />&nbsp;{item?.warning}
                                    </div>
                                </Tooltip>
                                : null}
                        </Col>
                    ))}
                    <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6", marginTop: 30 }} />
                </Row>
            )}
            <div className='r-jsb m-t-30'>
                <Heading>Attendance by time sheet</Heading>
                <div className='display-flex'>
                    {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager')) ?
                        <Button type="link" style={{ fontSize: 14 }} icon={<CloudDownloadOutlined />} onClick={() => setRangeSelectionVisible(true)}>
                            DOWNLOAD REPORT
                        </Button>
                        : null
                    }
                    <Select style={{ width: 80 }} disabled value={timeSheetYear} className="m-l-10" onSelect={val => { setTimeSheetYear(val) }}>
                        {years?.map((year, index) => <Option key={index} value={year}>{year}</Option>)}
                    </Select>
                    <Select style={{ width: 120 }} value={timeSheetMonth} className="m-l-10" onSelect={val => setTimeSheetMonth(val)}>
                        {months()?.map((month, index) => <Option key={index} value={month}>{month}</Option>)}
                    </Select>
                </div>
            </div>

            <div><TimeSheetGraph attendanceOverview={attendanceOverview || []} /></div>
            {
                ((user?.privilage?.includes('SuperAdmin') || user?.role?.includes('Teacher') || user?.role?.includes('Student')) && !userRowDetails) || (userRowDetails && (userRowDetails?.privilage?.includes('SuperAdmin') || userRowDetails?.role?.includes('Teacher') || userRowDetails?.role?.includes('Student'))) ?
                    <>
                        <div className='r-jsb m-t-30'>
                            <Heading>Attendance by schedule</Heading>
                            <div className='display-flex'>
                                {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager')) ?
                                    <Button type="link" style={{ fontSize: 14 }} icon={<CloudDownloadOutlined />} onClick={() => attendanceByScheduleOnPress()}>
                                        DOWNLOAD REPORT
                                    </Button>
                                    : null
                                }
                                <Select style={{ width: 80 }} disabled value={scheduleYear} className="m-l-10" onSelect={val => setScheduleYear(val)}>
                                    <Option>{timeSheetYear}</Option>
                                </Select>
                                <Select style={{ width: 120 }} value={scheduleMonth} className="m-l-10" onSelect={val => setScheduleMonth(val)}>
                                    {months()?.map((month, index) => <Option key={index} value={month}>{month}</Option>)}
                                </Select>
                            </div>
                        </div>
                       
                        <div><TableComponent tableData={(user?.role?.includes('Student')) ? attendanceOverview?.attendanceDetails : attendanceOverview?.attendanceDetailsByClass || []} getAttendanceOverview={getAttendanceOverview} /></div>
                    </>
                    : null}
            <EditAttendanceModal
                visible={updateAttendanceModalVisible}
                onClose={() => setUpdateAttendanceModalVisible(false)}
            />
            <DateRangeModal
                visible={rangeSelectionVisible}
                onClose={() => setRangeSelectionVisible(false)}
                storeSelectedRange={storeSelectedRange}
                selectedRangeContainer={selectedRangeContainer}
                storeStartDate={storeStartDate}
                storeEndDate={storeEndDate}
                startDateContainer={startDateContainer}
                usersDownloadReport={usersDownloadReport}
                setSpecificUserId={setSpecificUserId}
                user={user}
                endDateContainer={endDateContainer}
            />
            <SettingModal
                setIsSettingModalOpen={setIsSettingModalOpen}
                isSettingModalOpen={isSettingModalOpen}
                storeUserId={location?.state?.key ? specificUserId : user.id}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { user } = state.session;
    const { attendanceOverview } = state.profileNew;
    const { selectedRangeContainer, startDateContainer, endDateContainer } = state.attendanceNew
    const { specificUserId } = state.userManagementV2
    return {
        user, attendanceOverview, selectedRangeContainer, startDateContainer, endDateContainer, specificUserId
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAttendanceOverview: (params, callback) => dispatch(getAttendanceOverview(params, callback)),
    storeSelectedRange: (val) => dispatch(storeSelectedRange(val)),
    storeStartDate: (val) => dispatch(storeStartDate(val)),
    storeEndDate: (val) => dispatch(storeEndDate(val)),
    usersDownloadReport: (callback, successCallBack) => (dispatch(usersDownloadReport(callback, successCallBack))),
    setSpecificUserId: (res) => dispatch(setSpecificUserId(res)),
    getWebClockIn: (userId) => dispatch(getWebClockIn(userId)),
    downloadAttendanceBySchedule: (params) => dispatch(downloadAttendanceBySchedule(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceSummary);