import { Dropdown, Typography, Menu, Input, Button } from 'antd'
import React, { useState } from 'react'
import { MdMoreVert, } from 'react-icons/md'
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import GeoFenceForm from './geoFenceForm'
import location from 'Assets/Attendance/loc.svg'


const LocationCard = ({ locationItem, setIsEdit, isDashboard, setVisibleDelete, locationCardData, setLocationCardTitle, isEdit,setLongi,setLatitude}) => {
    const [visiblEdit, setVisibleEdit] = useState(false)
    const handleEdit = () => {
        setVisibleEdit(true)
        setIsEdit(true)
    }

    const handleDelete = (e) => {
        setVisibleDelete(true)
        locationCardData(locationItem?.id)
        setLocationCardTitle(locationItem?.title)
        console.log('location', locationItem)
    }

    const menu = (
        <Menu style={{ width: "120px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            <div className='r-ac'>
                <AiFillEdit style={{ width: "24px", height: "24px", color: "#636363", marginLeft: 10, marginRight: 5 }} />
                <Menu.Item key="1" onClick={() => handleEdit()} style={{ backgroundColor: 'white' }}>
                    Edit
                </Menu.Item>
            </div>
            <div className='r-ac'>
                <AiFillDelete style={{ width: "24px", height: "24px", color: "#636363", marginLeft: 10, marginRight: 5 }} />
                <Menu.Item key='2' onClick={(e) => handleDelete(e)} style={{ backgroundColor: 'white' }}>
                    Delete
                </Menu.Item>
            </div>
        </Menu>
    );
    return (
        <div >
            <div style={{ marginTop: '10px' }}>
                {!visiblEdit ?
                    <div  id="popover" style={{ width: '100%', height: '100%', border: '1px solid #E6E6E6', borderRadius: '6px', padding: '10px', display: 'flex', flexDirection: 'row' }}>
                        <img src={location}/>
                        
                        <div className='r-c' style={{ display: 'flex', flexDirection: 'column', width: '95%' }}>
                            <Typography.Text className='text-heading' style={{ fontSize: '14px', fontWeight: "bold" }}>{locationItem?.title}</Typography.Text>
                            <div style={{ fontSize: '12px', color: "#636363" }} ellipsis={{ rows: 2, tooltip: true, }}>{locationItem?.address}</div>
                            <Typography.Text className='text-content' style={{ fontSize: '10px', color: '#AEAEAE' }}>{locationItem?.radius} m</Typography.Text>
                        </div>
                        {
                            !isDashboard ?
                                <div style={{ width: '10%' }}>
                                    <Dropdown
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        overlay={menu}
                                        disabled={false}
                                        placement="bottomRight"
                                    >
                                        <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                                    </Dropdown>
                                </div> : <></>
                        }
                    </div>
                    :
                    // <div className='r-c' style={{ width: '100%', height: '100%', border: '1px solid #E6E6E6', borderRadius: '16px', padding: '12px', display: 'flex', flexDirection: 'column' }}>
                    //     <div style={{ marginBottom: '5px', padding: '8px' }}>
                    //         <div className='text-heading'>
                    //             Title
                    //         </div>
                    //         <Input className='attendance-input-area'></Input>
                    //     </div>
                    //     <div style={{ marginBottom: '5px', padding: '8px' }}>
                    //         <div className='text-heading'>
                    //             Title
                    //         </div>
                    //         <Input className='attendance-input-area'></Input>
                    //     </div>
                    //     <div style={{ marginBottom: '5px', padding: '8px' }}>
                    //         <div className='text-heading'>
                    //             Title
                    //         </div>
                    //         <Input className='attendance-input-area'></Input>
                    //     </div>
                    //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                    //         <Button type='primary' style={{ width: '100px', borderRadius: '16px' }}>Save</Button>
                    //     </div>
                    // </div>
                    <GeoFenceForm setVisibleGeoFenceForm={setVisibleEdit} editLocationItem={locationItem} setIsEdit={setIsEdit} isEdit={isEdit} setLatitude={setLatitude} setLongi={setLongi}/>

                }
            </div>
        </div>
    )

}

export default LocationCard