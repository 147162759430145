import { Col, DatePicker, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DemoColumn from "./antGraph";
import image1 from "../../Assets/care/no-subjects.svg";
import { getFeeOverView, setOverviewFilter } from "actions/feeManagement";
import PageDataLoader from "components/pageDataLoader";

const { Option } = Select;


function OverViewGraph({
  feeMangementData,
  setOverviewFilter,
  getFeeOverView,
  overviewFilter,
}) {
  let [graphData, setGraphData] = useState([]);
  let [sum, setSum] = useState(0);
  const [type, setType] = useState(null);
  const [pageLoaderFilter, tooglePageLoaderFilter] = useState(false);
  const [dateClose,setDateClose] =useState(false)

  // useEffect(() => {
  //   sum = 0
  //   if (feeMangementData?.dashBoardDetails?.graph?.length != 0) {
  //     feeMangementData?.dashBoardDetails?.graph?.map((item) => (
  //       sum = sum + parseInt(item?.totalSumRequested)
  //     ))
  //   }
  //   setSum(sum);
  // }, [feeMangementData?.dashBoardDetails?.graph]);

  useEffect(() => {
    setOverviewFilter("type", "week");
    setOverviewFilter(
      "startDate",
      moment().subtract(6,"days").format("YYYY-MM-DD")
    );
    setOverviewFilter(
      "endDate",
      moment().format("YYYY-MM-DD")
    );
    setType("week");
  }, []);

  useEffect(() => {
    graphData = [];
    sum = 0;
    if (feeMangementData?.dashBoardDetails?.graph?.length != 0) {
      feeMangementData?.dashBoardDetails?.graph?.map(
        (item) => (
         
          (sum = sum + parseFloat(item?.totalSumRequested)),
          graphData.push({
            type: "Payment Requested",
            short_date: item?.short_date,
            Payment_Requested: parseFloat(item?.totalSumRequested),
            totalCountequested:item?.totalCountequested
          })
        )

      );
    }

    setSum(sum);
    setGraphData([...graphData]);
  }, [feeMangementData?.dashBoardDetails?.graph]);

  const handleWeekOrMonth = (value) => {
    if (value) {
      setType(value);
      setOverviewFilter("type", value);
      if (value == "month") {
        setOverviewFilter("year", moment().year());
      }
    } else {
      setType("week");
      setOverviewFilter("type", "week");
    }
    tooglePageLoaderFilter(true);
    getFeeOverView(() => tooglePageLoaderFilter(false));
  };

  const disabledDate = (current) => {
    // console.log(current, dates);
    // if (!dates || dates.length === 0) {
    //   return false;
    // }
    // const tooLate = dates[0] && current.diff(dates[0], "days") > 6;
    // const tooEarly = dates[1] && dates[1].diff(current, "days") > 6;
    // return true;
    // if (
    //   (moment(current, "DD-MM-YYYY").format("YYYY-MM-DD") < startDt ||
    //     current > moment(startDt).add(6, "days")) &&
    //   startDt
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }
  };


  const handleCalenderChange = (date, dateString) => {
    console.log("AA-date",dateString[0],overviewFilter?.startDate);
    if (dateString[0]) {
      if(dateString[0]!== moment(overviewFilter?.startDate).format('DD-MM-YYYY') ){
        setOverviewFilter(
          "startDate",
          moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        setOverviewFilter("endDate",
        moment(dateString[0], "DD-MM-YYYY").add(6,'days').format("YYYY-MM-DD"));
      }
      else{
        setOverviewFilter(
          "endDate",
          moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        setOverviewFilter("startDate",
        moment(dateString[1], "DD-MM-YYYY").subtract(6,'days').format("YYYY-MM-DD"));

      }
        
      }

    setDateClose(false)
    tooglePageLoaderFilter(true);
    getFeeOverView(() => tooglePageLoaderFilter(false));
  };

  const handleChangeYear = (value, year) => {
    if (year) {
      setOverviewFilter("year", year);
    } else {
      setOverviewFilter("year", moment().year());
    }
    tooglePageLoaderFilter(true);
    getFeeOverView(() => tooglePageLoaderFilter(false));
  };
  const disable = (current) => {
    return current && current.format("YYYY") > moment().format("YYYY");
  };


  return (
    <div>
      <div
        className="careAdminSummaryCard"
        style={{
          boxShadow: "none",
          height: "300px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "-10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              width: "50%",
              marginRight: "10px",
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {type == "week" ? (
              
              <DatePicker.RangePicker
                open={dateClose}
                getPopupContainer={(trigger) => trigger.parentElement}
                picker='week'
                value={[
                  moment(
                    moment(overviewFilter?.startDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    ),
                    "DD-MM-YYYY"
                  ),
                  moment(
                    moment(overviewFilter?.endDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    ),
                    "DD-MM-YYYY"
                  ),
                ]}
                allowClear={false}
                style={{ width: "80%"}}
                format={"DD-MM-YYYY"}
                onCalendarChange={(value,dateString)=>{handleCalenderChange(value,dateString)}}
                onOpenChange={(open)=>{
                  setDateClose(open)
                  // if(open==true){
                  //   setOverviewFilter('endDate',moment(overviewFilter?.startDate).add(100,'days'))
                  // }
                
                }}
              />
              
            ) : (
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker="year"
                onChange={handleChangeYear}
                value={moment(overviewFilter?.year, "YYYY")}
                disabledDate={disable}
              />
            )}
          </div>
          <div
            id="testHomeDropdown"
            style={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <FeeCalender
            // togglePageLoader={togglePageLoader}
            /> */}
            <Select

              style={{ width: "100%" }}
              onChange={handleWeekOrMonth}
              value={overviewFilter?.type}
              getPopupContainer={() => document.getElementById("testHomeDropdown")}

            >
              <Option value={"week"}>Week</Option>
              <Option value={"month"}>Months</Option>
            </Select>
          </div>
        </div>
        <div style={{ width: "100%", height: "97%" }}>
          {sum == 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "30%", height: "30%", marginBottom: "20px" }}
                src={image1}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ fontWeight: "500" }}>No data!</h1>
                <p
                  style={{
                    color: "#636363",
                    width: "60%",
                    textAlign: "center",
                  }}
                >
                  There are no data to show yet. Please try some other date
                  range.
                </p>
              </div>
            </div>
          ) : (
            <DemoColumn
              graphData={graphData}
              xField={"short_date"}
              yField={"Payment_Requested"}
            />
          )}
        </div>
      </div>
      <PageDataLoader visible={pageLoaderFilter} />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { selectedStd, feeMangementData, overviewFilter } = state.feeManagement;
  return {
    selectedStd,
    feeMangementData,
    overviewFilter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setOverviewFilter: (key, res) => dispatch(setOverviewFilter(key, res)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverViewGraph);
