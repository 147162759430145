import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import App1 from './App1';
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./index.css";
import "./design/miscellaneous.css";
var cors = require('cors')


Sentry.init({
  dsn: "https://ffb5258a54564054a518820655afc5f4@o932470.ingest.sentry.io/5881205",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
