import React, { useEffect } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { setEditDateOfBirth } from "actions/userManagement";
import { setFeeDetails } from "actions/feeManagement";
// import { setInstallments } from "actions/course";

function Paid_date({
  setInstallments,
  installments,
  index,
  value,
  setPaidDate,
  setInstCust,
  price,
  setFeeDetails,
}) {
  function onChange(date, dateString) {
    // console.log("start date", dateString);
    if (
      moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date"
    ) {
      // console.log(
      //   "start date",
      //   moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD")
      // );
      setPaidDate(moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
      setFeeDetails(
        "paidDate",
        moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD")
      );
    } else {
    }

    // setInstallments([...installments]);
  }
  function disabledDate(current) {
    if (current > moment().endOf("day")) {
      return true;
    } else {
      return false;
    }
    // return !(current && current >= moment());
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={50}>
      <DatePicker
        getPopupContainer={(trigger) => trigger.parentElement}

        allowClear={false}
        className="datePicker"
        onChange={onChange}
        value={
          value !== null
            ? moment(
              moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
              "DD-MM-YYYY"
            )
            : ""
        }
        placeholder={["Select a date"]}
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
      />
    </Space>
  );
}

// export default Date_Picker1
const mapStateToProps = (state) => {
  const { } = state.course;
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setEditDateOfBirth: (date) => dispatch(setEditDateOfBirth(date)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  // setInstallments: (val) => dispatch(setInstallments(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Paid_date);
