import moment from "moment";
import { Api } from "services";

export const SET_CREATE_OFFERS_OBJ = "SET_CREATE_OFFERS_OBJ";
export const SET_CREATE_OFFERS_OBJ_KEY = "SET_CREATE_OFFERS_OBJ_KEY";
export const STORE_OFFERS_LIST = "STORE_OFFERS_LIST";
export const STORE_SINGLE_OFFER = "STORE_SINGLE_OFFER";
export const APPEND_OFFERS_LIST = "APPEND_OFFERS_LIST";
export const SET_OFFERS_OVERVIEW = "STORE_OFFERS_oVERVIEW";
export const SET_OFFERS_TYPE_INDEX = "SET_OFFERS_TYPE_INDEX";

export const setCreateOffersObjKey = (key, val) => ({
    type: SET_CREATE_OFFERS_OBJ_KEY,
    key,
    val
})

export const setCreateOffersObj = (val) => ({
    type: SET_CREATE_OFFERS_OBJ,
    val
})

export const storeOffersList = (val) => ({
    type: STORE_OFFERS_LIST,
    val
})

export const appendOffersList = (val) => ({
    type: APPEND_OFFERS_LIST,
    val
})

export const setOffersTypeIndex = (val) => ({
    type: SET_OFFERS_TYPE_INDEX,
    val,
})

export const setOffersOverview = (val) => ({
    type: SET_OFFERS_OVERVIEW,
    val
})

export const storeSingleOffer = (val) => ({
    type: STORE_SINGLE_OFFER,
    val
})

export const getOffers = (params, callBack = null) => {
    if (params?.filterTypes) {
        params.filterTypes = params.filterTypes.filter((val) => val !== 'All');
    }
    return (dispatch, getState) => {
        return Api.get(`/course/get-offers`).params(params).send((response) => {
            if (response !== undefined && response?.show?.type != 'error') {
                if (params.page > 1) {
                    dispatch(appendOffersList(response));
                } else {
                    dispatch(storeOffersList(response))
                }
            }
            if (callBack) {
                callBack()
            }
        })
    }
}

export const getSingleOffer = (id, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.get(`/course/get-offer`).params({ id: id }).send((response) => {
            if (callBack) {
                callBack()
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(storeSingleOffer(response));
                successCallBack(response);
            }
        })
    }
}
export const postCreateLiveCourseOffers = (new_promo_code, promo_code, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { newCourseId } = getState().adminMarketPlace;


        return Api.post('/course/update-offer')
            .params(new_promo_code ? new_promo_code : promo_code)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack();
                    }
                }
            });
    };

export const postCreateOffers = (publish, callBack = null, successCallBack = null) =>
    (dispatch, getState) => {
        const { offersObj } = getState().offers;
        const cloneOffersObj = { ...offersObj };
        if (cloneOffersObj?.type === "singleCourse") {
            if (cloneOffersObj.testSeriesId) {
                cloneOffersObj.type = 'testSeries';
            } else if (cloneOffersObj.videoResourceId) {
                cloneOffersObj.type = 'videoResource';
            } else if (cloneOffersObj.studyMaterialId) {
                cloneOffersObj.type = 'studyMaterial';
            } else {
                cloneOffersObj.type = 'liveCourse';
            }
        } else if (cloneOffersObj?.type === 'All') {
            cloneOffersObj.type = 'course';
            cloneOffersObj.applicableLive = cloneOffersObj.applicableVideo = cloneOffersObj.applicableStudy = cloneOffersObj.applicableTest = true;
        }
        const params = {
            ...cloneOffersObj,
            startDate: cloneOffersObj?.startDate ? moment(cloneOffersObj?.startDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            endDate: cloneOffersObj?.endDate ? moment(cloneOffersObj?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            publish
        }
        return Api.post('/course/update-offer')
            .params(params)
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && response?.show?.type == 'success') {
                    if (successCallBack) {
                        successCallBack(response.id);
                    }
                }
            });
    };

export const deleteOffer = (id, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/delete-offer').params({ id }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const deleteOffers = (ids, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.delete('/course/delete-offers').params({ ids }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};


export const updateOfferBanner = (id, banner, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/update-offer-banner').params({ id, banner }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
};

export const patchPublishOffers = (id, publish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/publish-offer').params({ id, publish }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
}

export const publishOffers = (ids, publish, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
        return Api.patch('/course/publish-offers').params({ ids, publish }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (typeof response != "undefined") {
                if (successCallBack) {
                    successCallBack()
                }
            }
        });
    }
}

export const getOffersOverview = (callBack = null) => {
    return (dispatch) => {
        return Api.get(`/course/offers-overview`).send((response, err) => {
            if (callBack) {
                callBack();
            }
            if (response !== undefined && response?.show?.type != 'error') {
                dispatch(setOffersOverview(response));
            }
        })
    }
}