import React from 'react';
import { Api } from '../../services';
import {connect} from 'react-redux';
import PageHeader from 'antd/lib/page-header';
import Tabs from 'antd/lib/tabs';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from 'antd/lib/typography';
import 'antd/lib/page-header/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/typography/style/index';
import { Chatting } from '../../components';
import { Color } from '../../services';
import './styles.css';
import '../../design/layout.css';
// import "./meeting.css";
import { getPreviousChat, toggleChatLoader, setPage } from '../../actions/classResources'
import ReactPlayer from 'react-player'
import ReactPlayerHoc from 'components/ReactPlayerHoc';

// let ws = false;

const { Title } = Typography;
const { TabPane } = Tabs;

class ClassResource extends React.Component {
  callback(key) {
  }
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleLoadEarlier() {
    const roomId = this.props.match.params.liveClassId;
    const { currentPage } = this.props;
    this.props.toggleChatLoader(true);
    this.props.getPreviousChat(roomId,parseInt(currentPage)+1, this.props.user.orgId);
  }

  componentDidMount() {
  }

  componentWillUnmount(){
    this.props.setPage(1);
  }
  render() {
    const {  classRecords, user, chatLoader, scheduleInfo } = this.props;
    console.log('render', scheduleInfo);

    return (
      <div style={{height: '100%'}}>
      <div className='r-ac'>
       <Title style={{color: Color.darkGrey, margin:0}} level={3}>Classroom Resources</Title>
     </div>
      <PageHeader
        className="site-page-header"
        onBack={() => this.props.history.goBack()}
        title={`${scheduleInfo.subject}`}
        subTitle={`${scheduleInfo.date}`}
        />
      <Row style={{height: '60%'}}>
        <Col xs={{span: 22}} sm={{span: 22}} md={{span: 22}} lg={{span: 18}} xl={{span: 16}} xxl={{span: 16}} style={{verticalAlign: 'middle', height: '100%'}} >
        <div style={{position:'relative', height:'100%', borderRadius:10}}>
          <ReactPlayerHoc 
              keyboardControls={true}
              height='100%' width='100%' controls playing={false} url={`${Api._base_url}/api/${user.orgId}/uploads/videos/${classRecords.resources.video}`} />
        </div>
        </Col>
        <Col xs={{span: 22}} sm={{span: 22}} md={{span: 22}} lg={{span: 6}} xl={{span: 8}} xxl={{span: 8}} style={{height: '100%'}}>
        <div style={{height:'100%', marginLeft: 20}}>
         <Tabs
           style={{height:'100%', borderRadius:5, border: `1px solid ${Color.midGrey}`, padding:5}}
           className='liveChatPage'
           size="large"
           defaultActiveKey="1"
           onChange={this.callback()}
         >
           <TabPane tab="Chat" key="1" style={{}}>
             <Chatting
               showFooter={this.state.showFooter}
               messages={classRecords.chats}
               userTyping={() => null}
               onSend={() => null}
               onImgUploaded={(url, imageFile) => this.handleImageUpload(url, imageFile)}
               updateShowFooter={() => null}
               user={{id: user.id, name: user.name, avatar: user.image}}
               onLoadEarlier={() => this.handleLoadEarlier()}
               loadIndicator={chatLoader}
               renderInput={false}
             />
           </TabPane>
         </Tabs>
         </div>
       </Col>
      </Row>
      <div className='display-flex'>
        {/*<Title style={{fontSize: 22, color: Color.primary, marginTop:50}}>Resource Library</Title>
        <div>
          <Empty style={{padding: 50}}/>
        </div> */}
      </div>
      </div>
    )
  }
};

const mapStateToProps = state => {
  const {
    classRecords, chatLoader, scheduleInfo, currentPage
  } = state.classResources;
  const {
    user
  } = state.session;
  return {
    classRecords, user, chatLoader, scheduleInfo,currentPage
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPreviousChat: (roomId, page, orgId) => dispatch(getPreviousChat(roomId, page, orgId)),
  toggleChatLoader: value => dispatch(toggleChatLoader(value)),
  setPage: value => dispatch(setPage(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassResource);
