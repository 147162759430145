import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import { connect } from "react-redux";

const SingleBarGraph = ({ gendrRatio }) => {
  for (let i = 0; i < gendrRatio?.length; i++) {
    let obj = {
      gender: gendrRatio[i]?.gender,
      percentage: parseFloat(gendrRatio[i]?.percentage),
      total: parseFloat(gendrRatio[i]?.total),
    };
    gendrRatio[i] = obj;
  }

  if (
    gendrRatio &&
    gendrRatio
      .filter((res) => res.gender == "Male")
      .map((ele) => ele.percentage)
  ) {
    let obj = {
      gender: "Male",
      percentage: 0,
      total: 0,
    };
    gendrRatio[gendrRatio?.length] = obj;
  }

  if (
    gendrRatio &&
    gendrRatio
      .filter((res) => res.gender == "Female")
      .map((ele) => ele.percentage)
  ) {
    let obj = {
      gender: "Female",
      percentage: 0,
      total: 0,
    };
    gendrRatio[gendrRatio?.length] = obj;
  }

  // if (
  //   gendrRatio &&
  //   gendrRatio
  //     .filter((res) => res.gender == "Unspecified")
  //     .map((ele) => ele.percentage)
  // ) {
  //   let obj = {
  //     gender: "Unspecified",
  //     percentage: 0,
  //     total: 0,
  //   };
  //   gendrRatio[gendrRatio?.length] = obj;
  // }

  if (
    gendrRatio &&
    gendrRatio
      .filter((res) => res.gender == "Others")
      .map((ele) => ele.percentage)
  ) {
    let obj = {
      gender: "Others",
      percentage: 0,
      total: 0,
    };
    gendrRatio[gendrRatio?.length] = obj;
  }

  var config = {
    data: gendrRatio ? gendrRatio : [],
    xField: "gender",
    yField: "percentage",

    columnStyle: {
      radius: [3, 3, 0, 0],
    },

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      // type: { alias: "类别" },
      Percentage: { alias: "Percentage(%)" },
      // total:{alias:"Total"}
    },
    color: "#99DED9",
    legend: false,
  };

  return <Column {...config} minColumnWidth={10} maxColumnWidth={35} />;
};

// export default SingleBarGraph;

const mapStateToProps = (state) => {
  const {
    tab,
    dashBoardRole,
    isMonthOrWeek,
    growthSummary,
    gendrRatio,
    dashboardData,
  } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    dashboardData,
    isMonthOrWeek,
    growthSummary,
    gendrRatio,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  // getStats: (m) => dispatch(getStats(m)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleBarGraph);
