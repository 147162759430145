import React from 'react'
import './careStyles.css'
import { useHistory } from 'react-router';
import CareCards from './careCards';
import { connect } from 'react-redux';
import { getAdminFaqs } from 'actions/adminCare';
import { useEffect } from 'react';
import { Col, Row } from 'antd';
import image1 from 'Assets/care/ic-chat.svg';
import image2 from 'Assets/care/ic-call-us.svg';
import image3 from 'Assets/care/ic-faq.svg';
import image4 from 'Assets/care/covid.png';
import { Heading } from 'components/Typography';

function Care(props) {

    useEffect(() => {
        props.getAdminFaqs();
    }, [])
    const history = useHistory();
    const handleViewFaqsClick = () => {
        history.push('/admin-care/faqs')
    }

    const handleViewAulasChatClick = () => {
        history.push('/admin-care/chat')
    }
    return (
        <div style={{ height: "100%" }}>

            <div className="careHeader" style={{ display: "flex", height: "40%", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <h2 className="careHeading_h1" style={{ fontSize: "28px" }}>aulas Care</h2>

                </div>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginLeft: "5%", marginTop: "10px" }}>
                    <h2 className="careHeading_h2" style={{ marginBottom: "0px", marginTop: "0px" }}>How may we help you ?</h2>
                    <p style={{ fontSize: "14px", color: "#636363", opacity: "100%", fontFamily: "roboto" }}>Our crew of superheroes are standing by for service and support!</p>

                </div>

            </div>
            <div  >
                <div >
                    <Heading className='m-t-20'>Get in touch with aulas team</Heading>
                    <Row>
                        <Col md={{ span: 24 }} lg={{ span: 12 }} >
                            <div className="careCard cursor-pointer" onClick={() => handleViewAulasChatClick()} >
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image1} alt="" /></div>
                                <CareCards heading='Chat' discription='Contact aulas Care for any support' />
                            </div>
                        </Col>
                        <Col md={{ span: 24 }} lg={{ span: 11, offset: 1 }}>
                            <div className="careCard"  >
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image2} alt="" /></div>
                                <CareCards heading='Call' discription={'Call for any inquiry at +91-7788998879'} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row  >
                    <Heading className='m-t-20'>Resources</Heading>
                    <Row style={{ width: "100%" }}>
                        <Col md={{ span: 24 }} lg={{ span: 12 }} onClick={() => handleViewFaqsClick()}>
                            <div className="careCard cursor-pointer " >
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image3} alt="" /></div>
                                <CareCards heading='FAQs' discription='Find intelligent answers instantly' />
                            </div>
                        </Col>
                        <Col md={{ span: 24 }} lg={{ span: 11, offset: 1 }} >
                            <div className="careCard " style={{ cursor: "not-allowed" }} >
                                <div><img style={{ width: "70px", height: "70px", marginRight: "40px" }} src={image4} alt="" /></div>
                                <CareCards heading='COVID-19 Resources' discription='Technical guidance publications' />
                            </div>
                        </Col>
                    </Row>
                </Row>

            </div>

        </div>
    )
}




const mapStateToProps = state => {
    const {
        faqs,

    } = state.adminCare;
    return {
        faqs,
    };
};

const mapDispatchToProps = dispatch => ({

    getAdminFaqs: () => dispatch(getAdminFaqs()),

});


export default connect(mapStateToProps, mapDispatchToProps)(Care);
