import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import SearchBox from 'pages/Test/localComponent/searchBox'
import React, { useEffect, useState } from 'react'
import "./styles.css"
import { Button, Select, Typography } from 'antd';
import { connect } from 'react-redux';
import { fetchNotifications, selectNotificationTab, updateFilter, patchNotifications, setCardId, setActionData, postUserStatus, setFromNotification, Istrue } from 'actions/notification';
import ReactHtmlParser from 'react-html-parser';
/* import { NodataCard  month={filter?.dateFilter} } from 'pages/Test'; */
import moment from 'moment';
import Loader from './localComponents/loader';
import ConfirmRejectModal from 'pages/attendanceNew/Modals/confirmRejectModal';
import ConfirmApproveModal from 'pages/attendanceNew/Modals/confirmApproveModal';
import { useHistory } from 'react-router';
import { setUserDetails } from 'actions/userManagement';
import fileDownload from "js-file-download";
import { Api } from 'services';
import Axios from 'axios';
import PageHeader from 'components/pageHeader';
import { Button as CustomButton } from 'components/Button';
import PageDataLoader from 'components/pageDataLoader';


const allnotification = require("../../Assets/notificationIcons/ic-other-notification-01.svg").default
const assignment = require("../../Assets/notificationIcons/ic-assignment.svg").default
const fee = require("../../Assets/notificationIcons/ic-fee.svg").default
const Announcements = require("../../Assets/notificationIcons/ic-announcement.svg").default
const test = require("../../Assets/notificationIcons/ic-test.svg").default
const NoData = require("../../Assets/notificationIcons/ill-no-data.svg").default
const digitallibrary = require("../../Assets/notificationIcons/ic-other-notification-02.svg").default
const practiceTest = require("../../Assets/notificationIcons/ic-practice-test.svg").default
const { Option } = Select;

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function MouseOver(event) {
  event.target.style.background = '#F6F4FE';
}
function MouseOut(event) {
  event.target.style.background = "";
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      marginTop: -3,
      color: color,
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    }}
  />
);


const NewNotification = () => {
  return (
    <>
      <text className='' style={{ color: "#FF414D ", marginTop: "-20px", marginLeft: "-8px", fontSize: "30px" }}>•</text>
    </>
  )
}

const PageLoader = () => {
  return (
    <>
      <div style={{
        width: "90%",
        minWidth: 500,
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        height: "100%",
        overflow: "scroll"
      }} className="m-l-50 customCardShadow flex flex-wrap cursor-pointer shadow-box hover-shadow">
        <Loader style={{ fontSize: "30px" }} />
      </div>

    </>
  )
}

const NotificationCard = ({ content, date, Title, data, status,
  message, updateFilter, selectedNotificationTab, setUserDetails, setActionData,
  setFromNotification, setCardId, patchNotifications, postUserStatus, fetchNotifications }) => {
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const fromNotification = true;
  const history = useHistory()
  const downloadFile = (file) => {

    let fileName = file[0]?.key
    Axios.get(Api._s3_url + file[0]?.data, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, fileName);
      updateFilter('selectedNotification', data)
      updateFilter('isViewSelected', true)
      patchNotifications(() => {
      })

    });
  }

  const src = selectedNotificationTab === 'Announcements' ? Announcements :
    selectedNotificationTab === 'Assignments' ? assignment :
      selectedNotificationTab === 'Test' ? test :
        selectedNotificationTab === 'Practice Test' ? practiceTest :
          selectedNotificationTab === 'Digital Library' ? digitallibrary :
            selectedNotificationTab === 'Fee Payments' ? fee :
              selectedNotificationTab === 'Others' || selectedNotificationTab === 'All' ? allnotification : null;
  return (
    <div className=' m-l-20'
      style={{
        width: "calc(100% - 40px)",
        minWidth: "300px", height: "100px", borderTop: "5px", border: "1px solid rgba(0, 0, 0, 0.05)"
      }}>
      <div className='r-jsb' style={{ width: "100%" }}>
        <div className='r-c-c' style={{ padding: "0px", width: "100%" }}>
          <div style={{ margin: "10px 20px", display: "flex", flexDirection: "row" }}><img src={src} />
            {
              status === "Unread" ? <div style={{ marginTop: "-20px" }}> <NewNotification /></div> : null
            }
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "75%", paddingTop: 13 }}>
            <Typography.Text className='text-overflow-ellipsis' style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "16px", maxWidth: "100%", width: "100%" }} ellipsis={{ tooltip: true }}>{Title}</Typography.Text>
            <Typography.Text className='text-overflow-ellipsis' style={{ color: "#191919", fontWeight: "600", fontStyle: "roboto", fontSize: "12px", width: "90%", height: 20 }} ellipsis={{ tooltip: true }}>
              {
                ReactHtmlParser(content != undefined && isJsonString(content) ? JSON.parse(content).mobile : content)
              }
            </Typography.Text>
            <div className='r-jsb' style={{ width: "90%" }}>
              <div className='' style={{ padding: "0px", width: "75%" }} >
                {
                  (data?.userStatus === "Pending" || data?.userStatus === "Rejected") ?
                    <CustomButton type="link" style={{ fontSize: 12 }}
                      onClick={() => {
                        setUserDetails(data);
                        updateFilter('selectedNotification', data)
                        updateFilter('isViewSelected', true)
                        patchNotifications(() => { })
                        history.push("/user-management/all-requests/approval")
                        setFromNotification(true);
                      }}>
                      VIEW REQUEST
                    </CustomButton> :
                    isJsonString(content) && JSON.parse(content)?.filenames !== undefined ?
                      <CustomButton type="link" style={{ fontSize: 12 }}
                        onClick={() => {
                          updateFilter('selectedNotification', data)
                          updateFilter('isViewSelected', true)
                          patchNotifications(() => { })
                          downloadFile(isJsonString(content) && JSON.parse(content)?.filenames)

                        }}
                      >DOWNLOAD</CustomButton> :

                      <CustomButton type="link" style={{ fontSize: 12 }}
                        onClick={() => {
                          updateFilter('selectedNotification', data)
                          updateFilter('isViewSelected', true)
                          patchNotifications(() => { })
                        }}
                      >VIEW</CustomButton>
                }
              </div>
            </div>
          </div>
          <div style={{ width: "25%", display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: 10 }}>
            <div style={{ color: "#191919", fontWeight: "500", fontStyle: "roboto", fontSize: "10px", padding: "10px 0" }}>{moment.utc(date).local().format("DD/MM/YYYY • hh:mm A")}</div>
            <div style={{ width: "100%", padding: "10px 0" }}>
              {data?.category == "RegistrationRequests" ?
                <div className='r-jsb' >
                  {
                    data?.userStatus === "Pending" ?
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                          <Button
                            style={{
                              width: "fit-content",
                              height: "25px",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              border: "1px solid #636363",
                              borderRadius: " 100px",
                              fontSize: "10px"
                            }} onClick={(e) => {
                              e.stopPropagation();
                              setIsRejectModalVisible(true);
                              setCardId(data?.refId);
                              setActionData("Rejected")
                            }}
                          >REJECT</Button>
                          <ConfirmRejectModal
                            isRejectModalVisible={isRejectModalVisible}
                            setIsRejectModalVisible={setIsRejectModalVisible}
                            postUserStatus={postUserStatus}
                            fromNotification={fromNotification}
                            Title={data?.name}
                          />
                          <Button
                            style={{
                              width: "fit-content",
                              height: "25px",
                              background: "#28DF99 0% 0% no-repeat padding-box",
                              borderRadius: " 100px",
                              color: "#FFFFFF",
                            }} onClick={(e) => {
                              e.stopPropagation();
                              setIsApproveModalVisible(true);
                              setCardId(data?.refId);
                              setActionData("Approved")
                            }}>
                            <div style={{ fontSize: "10px", marginLeft: "-6px" }}>ACCEPT</div></Button>
                          <ConfirmApproveModal
                            isApproveModalVisible={isApproveModalVisible}
                            setIsApproveModalVisible={setIsApproveModalVisible}
                            postUserStatus={postUserStatus}
                            fromNotification={fromNotification}
                            Title={data?.name}
                          />
                        </div>
                      </div>
                      :
                      data?.userStatus === "Rejected" ?
                        <div style={{ width: "100%" }}>
                          <div style={{ fontSize: '13px', color: '#FF414D', display: "flex", fontWeight: "500", justifyContent: "flex-end" }}>
                            <CloseCircleOutlined style={{ marginTop: 5 }} />
                            <div style={{ marginLeft: 5 }}>REJECTED</div>
                          </div>
                        </div>
                        :
                        data?.pendingUser === "Approved" ?
                          <div style={{ width: "100%" }}>
                            <div style={{ fontSize: '13px', color: '#28DF99', display: "flex", fontWeight: "500", justifyContent: "flex-end" }}>
                              <CheckCircleOutlined style={{ marginTop: 5 }} />
                              <div style={{ marginLeft: 5 }}>ACCEPTED</div>
                            </div>
                          </div>
                          :
                          <></>
                  }
                </div> :
                null
              }
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

const NotificationsList = ({ selectedNotificationTab, notification, updateFilter, filter,
  onBackward, onForward, AllnotificationCount, patchNotifications, setUserDetails, setFromNotification,
  setCardId, setActionData, postUserStatus, fetchNotifications }) => {
  const count = selectedNotificationTab === 'Announcements' ? AllnotificationCount?.announcement :
    selectedNotificationTab === 'Assignments' ? AllnotificationCount?.assignment :
      selectedNotificationTab === 'Test' ? AllnotificationCount?.test :
        selectedNotificationTab === 'Practice Test' ? AllnotificationCount?.practiceTest :
          selectedNotificationTab === 'Digital Library' ? AllnotificationCount?.digitallibrary :
            selectedNotificationTab === 'Fee Payments' ? AllnotificationCount?.feepayments :
              selectedNotificationTab === 'Others' ? AllnotificationCount?.others :
                selectedNotificationTab === 'All' ? AllnotificationCount?.all : 0
  return (
    <>
      <div style={{
        width: "90%",
        minWidth: 500,
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        height: "100%",
        alignItems: 'center'
      }}
        className="m-l-50 customCardShadow flex flex-wrap  shadow-box hover-shadow notificationscroll">
        <div style={{ display: "flex", alignItems: 'center' }}>
          <Selectbox updateFilter={updateFilter} filter={filter} />
          {
            count > 0 ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: "40px" }}>
              <div className='p-10' style={{ display: "flex", color: "#191919", fontWeight: "400", fontStyle: "roboto", fontSize: "13px" }}>
                <div >{notification?.notifications?.meta?.totalPages != 0 ?
                  filter?.currentPage : 0
                }</div>
                <div className='m-l-5'>/</div>
                <div className='m-l-5'>{notification?.notifications?.meta?.totalPages}</div>
              </div>
              <LeftOutlined className='m-l-10' style={{ cursor: parseInt(notification?.notifications?.meta?.currentPage) === 1 ? "not-allowed" : "pointer" }} onClick={onBackward} />
              <RightOutlined className='m-l-30' style={{ cursor: (notification?.notifications?.meta?.totalPages === parseInt(notification?.notifications?.meta?.currentPage) || notification?.notifications?.meta?.totalPages === 0) ? "not-allowed" : "pointer" }} onClick={onForward} />
            </div> : null
          }
        </div>
        <ColoredLine color="black" />
        <div style={{ overflow: 'auto', height: "80%", }}>
          {notification?.notifications?.items.length > 0 ?
          /* AllnotificationCount?.announcement>0? */notification?.notifications?.items?.map((item) => (
            <NotificationCard
              Title={item?.title}
              fetchNotifications={fetchNotifications}
              date={item?.createdAt}
              content={item?.message}
              data={item}
              status={item.status}
              updateFilter={updateFilter}
              selectedNotificationTab={selectedNotificationTab}
              setUserDetails={setUserDetails}
              setFromNotification={setFromNotification}
              setCardId={setCardId}
              setActionData={setActionData}
              patchNotifications={patchNotifications}
              postUserStatus={postUserStatus}
            />
          ))/* :<NodataCard  month={filter?.dateFilter}/> */ : <NodataCard month={filter?.dateFilter} />
          }
        </div>
      </div>
    </>
  )
}

const MenuItem = ({ content, type, onTabChange, notificationCount, unread, isSelected }) => {
  const src = content === 'Announcements' ? Announcements :
    content === 'Assignments' ? assignment :
      content === 'Test' ? test :
        content === 'Practice Test' ? practiceTest :
          content === 'Digital Library' ? digitallibrary :
            content === 'Fee Payments' ? fee :
              content === 'Others' || content === 'All Notifications' ? allnotification : null;
  const newMsg = content === 'Announcements' && unread?.announcement >= 1 ? "Announcements" :
    content === 'Assignments' && unread?.assignment >= 1 ? 'Assignments' :
      content === 'Test' && unread?.test >= 1 ? 'Test' :
        content === 'Practice Test' && unread?.practiceTest >= 1 ? 'Practice Test' :
          content === 'Digital Library' && unread?.digitallibrary >= 1 ? 'Digital Library' :
            content === 'Fee Payments' && unread?.feepayments >= 1 ? 'Fee Payments' :
              content === 'Others' && unread?.others >= 1 ? 'Others' :
                content === 'All Notifications' && unread?.all >= 1 ? 'All Notifications'
                  : null
  const count = content === 'Announcements' ? notificationCount?.announcement :
    content === 'Assignments' ? notificationCount?.assignment :
      content === 'Test' ? notificationCount?.test :
        content === 'Practice Test' ? notificationCount?.practiceTest :
          content === 'Digital Library' ? notificationCount?.digitallibrary :
            content === 'Fee Payments' ? notificationCount?.feepayments :
              content === 'Others' ? notificationCount?.others :
                content === 'All Notifications' ? notificationCount?.all : 0
  return (
    <div className='display-flex p-10'
      onClick={() => { onTabChange(type) }}
      style={{
        height: "fit-content", backgroundColor: `${isSelected ? "#F6F4FE" : ""}`,
        color: `${!isSelected ? "#AEAEAE" : "#594099"}`,
        width: "100%", border: "1px", borderRadius: "5px", alignItems: 'center'
      }} >
      <img src={src} height={40} alt="" />
      {newMsg === content ? <NewNotification /> : null}
      <div className="m-l-20" style={{ fontSize: "1vw", fontWeight: `${!isSelected ? 400 : 600}`, overflow: 'hidden' }}>{content}&nbsp; ({count})</div>
    </div>
  )
}


const Selectbox = ({ updateFilter, filter }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "80px" }}>
        <Select className=' m-l-20' value={filter?.dateFilter} style={{ width: 150 }} onSelect={(text, value) => {
          console.log(value.value, "hi")
          updateFilter('dateFilter', value.value)
        }}
          onMouseOver={MouseOver}
          onMouseOut={MouseOut}
        >
          <Option value={"all"}>All</Option>
          <Option value={"Today"}>Today</Option>
          <Option value={"Yesterday"}>Yesterday</Option>
          <Option value={"This week"}>This week</Option>
          <Option value={"This month"}>This month</Option>
        </Select>
      </div>
    </>
  )
}
const NodataCard = ({ month }) => {
  return (
    <>
      <div className='r-c-c-c' style={{ marginTop: "80px" }}>
        <img src={NoData} />
        <div style={{ color: "#191919", fontWeight: "800", fontStyle: "roboto", fontSize: "21px" }}>No notification.{/*  {month=="all"?"Today yet!":null}  */}</div>
        <div style={{ color: "#636363", fontWeight: "800", fontStyle: "roboto", fontSize: "13px" }}>We’ll notify you when something arrives.</div>
      </div>
    </>
  )
}

const DataDetailsCard = ({ updateFilter, filter }) => {
  const message = filter?.selectedNotification?.message;
let standardsname = '';

// if (message !== undefined && isJsonString(message)) {
//   const jsonData = JSON.parse(message);
  
//   if (jsonData && jsonData?.standardNames && jsonData?.standardNames?.length > 0) {
//     standardsname = jsonData?.standardNames[0];
//   }
// }
let standardNames = [];

if (message !== undefined && isJsonString(message)) {
  const jsonData = JSON.parse(message);

  if (jsonData && jsonData.standardNames && Array.isArray(jsonData.standardNames)) {
    standardNames = jsonData.standardNames.join(' , ')
  }
}
  return (
    <>
      <div style={{
        width: "90%",
        minWidth: 500,
        backgroundColor: "#FFFFFF",
        /*  border: "1px solid #E6E6E6", */
        borderRadius: 10,
        height: 520,
        overflow: "scroll"
      }} className="m-l-50 customCardShadow flex flex-wrap  shadow-box hover-shadow">
        <div className='m-t-20 m-l-20' style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ fontWeight: "700", display: "flex", flexDirection: "row" }} >
            <ArrowLeftOutlined style={{ marginTop: "10px", fontSize: "22px" }} onClick={() => {
              updateFilter('isViewSelected', false)
            }} />
            <div style={{ marginLeft: "15px", fontSize: "24px" }}>Back</div>
          </div>
        </div>


        <div className='r-c-c-c ' style={{ padding: "20px" }}>
          {console.log("AA-filter-notificatio", filter?.selectedNotification)}
          <img className='p-5' style={{ height: "80px", width: "80px" }} src={
            filter?.selectedNotification?.category === 'PaymentSuccess' ||
              filter?.selectedNotification?.category === 'PaymentLink' ? fee :
              filter?.selectedNotification?.category === 'Announcement' ? Announcements :
                filter?.selectedNotification?.category === 'TaskAssignment' ? assignment :
                  filter?.selectedNotification?.category === 'TaskTest' ? test :
                    filter?.selectedNotification?.category === 'DigitalLibrary' ? digitallibrary :
                      filter?.selectedNotification?.category === 'RegistrationRequests' ? allnotification :
                        filter?.selectedNotification?.category === 'PracticeTest' ? practiceTest :
                          filter?.selectedNotification?.category === 'Other' ? Announcements :


                            null
          } />
          <div className='m-t-20' style={{ color: "#191919", fontWeight: "500", fontStyle: "roboto", fontSize: "13px", height: "18px" }} >{moment.utc(filter?.selectedNotification?.createdAt).local().format("DD/MM/YYYY • hh:mm A")}</div>
          <div className='m-t-20' style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "21px" }}>{filter?.selectedNotification?.title}</div>
          <div className='m-t-20' style={{ color: "#636363", fontWeight: "800", fontStyle: "roboto", fontSize: "13px" }}>
            {ReactHtmlParser(filter?.selectedNotification?.message != undefined && isJsonString(filter?.selectedNotification?.message) ? JSON.parse(filter?.selectedNotification?.message).mobile : filter?.selectedNotification?.message,
              {
                transform: (node) => {
                  console.log("AA0lognode", node);
                  if (node.type === 'tag' && node.name === 'span' && node.attribs['data-value']) {
                    return <TeX math={`${node.attribs['data-value']}`} />;
                  }
                  if (node.type === 'tag' && node.name === 'img') {
                    return <img style={{ maxHeight: '40vh', maxWidth: '40vh' }} src={node.attribs.src} alt="" />;
                  }
                }
              })
            }</div>
          <div>
            {standardNames}
          </div>
        </div>
      </div>
    </>
  )
}


let timer = false;

function HomeNotification({ selectNotificationTab,
  selectedNotificationTab,
  fetchNotifications, notification, reloadToggler,
  updateFilter, filter, loaders, patchNotifications,
  setCardId, setActionData, postUserStatus, setUserDetails,
  setFromNotification, setIsTrue, Istrue }) {
    const [pageLoader ,setPageLoader] = useState(false)
  const onForward = () => {
    if (filter?.currentPage < notification?.notifications?.meta?.totalPages) {
      updateFilter("currentPage", filter?.currentPage + 1)
    }
  }

  const onBackward = () => {
    if (filter?.currentPage > 1) {
      updateFilter("currentPage", filter?.currentPage - 1)
    }
  }
  useEffect(() => {
    updateFilter('search', null);
    if (setIsTrue) {
      selectNotificationTab('Others')
      Istrue(false);
    } else {
      selectNotificationTab('All');
    }
    updateFilter('isViewSelected', false);

  }, [])
  useEffect(() => {
    setPageLoader(true)
    fetchNotifications(()=>{setPageLoader(false)});
  }, [reloadToggler, selectedNotificationTab])

  useEffect(() => {
    updateFilter("currentPage", 1)
  }, [filter?.dateFilter])


  const onTabChange = (tab) => {
    updateFilter('isViewSelected', false)
    updateFilter("currentPage", 1);
    selectNotificationTab(tab);
    updateFilter('search', null);


  }
  const onSearch = (e) => {
    updateFilter('search', e.target.value);
    updateFilter("currentPage", 1)
  }
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setPageLoader(true)
      fetchNotifications(()=>{setPageLoader(false)});

    }, 1000);
  }, [filter?.search]);
  return (
    <div style={{ height: "99%" }}>
      <div style={{ marginBottom: 50 }}>
        <PageHeader title={'Notifications'} searchRequired={'searchBox'} onSearchChange={onSearch} searchPlaceholder={'Search all notifications...'} searchVal={filter?.search}></PageHeader>
      </div>
      <div className='display-flex  flex-wrap m-t-30' style={{ justifyContent: 'space-between', height: "95%",marginTop:"70px" }}>
        <div style={{
          width: "28%",
          minWidth: 200,
          backgroundColor: "#FFFFFF",
          border: "1px solid #E6E6E6",
          borderRadius: 10,
          overflow: "scroll",
          height: "100%",
          padding: 30
        }}
          className="customCardShadow flex-wrap cursor-pointer flex-1 shadow-box hover-shadow scroll">
          <MenuItem
            notificationCount={notification?.notificationCount}
            onTabChange={onTabChange}
            type={"All"}
            unread={notification?.unreadCount}
            isSelected={selectedNotificationTab === 'All'}
            updateFilter={updateFilter}
            content="All Notifications"
          />
          {
            notification?.categories?.map((item) => (
              <MenuItem
                notificationCount={notification?.notificationCount}
                image={item?.img}
                content={item}
                type={item}
                key={item}
                unread={notification?.unreadCount}
                onTabChange={onTabChange}
                isSelected={selectedNotificationTab === item}
                updateFilter={updateFilter}
              />
            ))
          }
        </div>
        <div style={{ width: '75%', height: "100%" }} className="flex-3">
          {
            filter?.isViewSelected && !(isJsonString(filter?.selectedNotification?.message) && JSON.parse(filter?.selectedNotification?.message)?.filenames !== undefined)
              ?
              <DataDetailsCard updateFilter={updateFilter} filter={filter} />
              :
              <NotificationsList
                fetchNotifications={fetchNotifications}
                setFromNotification={setFromNotification}
                setUserDetails={setUserDetails}
                selectedNotificationTab={selectedNotificationTab}
                patchNotifications={patchNotifications}
                AllnotificationCount={notification?.notificationCount}
                notification={notification}
                updateFilter={updateFilter}
                filter={filter}
                onForward={onForward}
                onBackward={onBackward}
                setCardId={setCardId}
                setActionData={setActionData}
                postUserStatus={postUserStatus} />
          }
        </div>

      </div>
      <PageDataLoader visible={pageLoader}/>
    </div>
  )
}

const mapStateToProps = state => {
  const {
    notificationData, requestData, approveLoader, rejectLoader, selectedType, selectedStatus, selectedPage,
    itemsCount, notificationLoader, selectedNotificationTab, getNotification, notification, reloadToggler, filter, loaders, setIsTrue
  } = state.notification;
  const { orgId, role } = state.session.user
  return {
    notificationData, requestData, approveLoader, rejectLoader, selectedType, selectedStatus, selectedPage,
    itemsCount, orgId, notificationLoader, selectedNotificationTab, role, getNotification, notification, reloadToggler, filter, loaders, setIsTrue
  };
};


const mapDispatchToProps = dispatch => ({
  selectNotificationTab: (key) => dispatch(selectNotificationTab(key)),
  fetchNotifications: (callback) => dispatch(fetchNotifications(callback)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  patchNotifications: (callback) => dispatch(patchNotifications(callback)),
  setCardId: (id) => dispatch(setCardId(id)),
  setActionData: (val) => dispatch(setActionData(val)),
  postUserStatus: (callBack, successCallBack) => dispatch(postUserStatus(callBack, successCallBack)),
  setUserDetails: (res) => dispatch(setUserDetails(res)),
  setFromNotification: (val) => dispatch(setFromNotification(val)),
  Istrue: (bool) => dispatch(Istrue(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNotification); 