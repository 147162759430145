import React, { useEffect, useState } from 'react'
import TopBar from '../topBar'
import Footer from '../footer'
import { ArrowLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import "./courseContent.css"
import { Col, Collapse, Divider, Row } from 'antd'
import physics from "../../../Assets/aulasMarket/sbj-physics.svg"
import { connect } from 'react-redux'
import { Api } from 'services'
import { SquareSubIcon } from 'components/subIcon'
import MobilePageHeader from '../mobilePageHeader'
import { storeCourseIdAndType } from 'actions/studentMarketPlace'
import NewFooter from '../newFooter'

const PanelContent = ({ courseChapter }) => {
    const [showAll, setShowAll] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const itemsToShow = showAll ? courseChapter?.length : 10;

    return (
        <>
            <div style={{ color: "#636363" }}>
                <Row
                    style={{
                        fontWeight: "bold",
                        color: "#636363",
                        padding: 10,
                        borderBottom: "1px solid #ddd",
                    }}
                    className="r-ac"
                >
                    <Col offset={1} span={4}>
                        Chapter
                    </Col>
                    <Col span={3}>Name</Col>
                </Row>
            </div>

            {courseChapter?.slice(0, itemsToShow).map((item, index) => (
                <div key={index} style={{ color: "#636363" }}>
                    <Row
                        style={{
                            fontWeight: "normal",
                            color: "#636363",
                            padding: 10,
                            borderBottom: "1px solid #ddd",
                        }}
                        className="r-ac"
                    >
                        <Col offset={1} span={4}>
                            {index + 1}
                        </Col>
                        <Col span={7}>
                            {item?.name}
                        </Col>
                    </Row>
                </div>
            ))}
            {courseChapter?.length > 10 ?
                <div className="r-c-c font-bold" style={{ color: "#1089FF", cursor: "pointer", margin: 10 }}>
                    <div style={{ cursor: "pointer" }} onClick={toggleShowAll}>
                        {showAll ? "VIEW LESS" : "VIEW ALL"}
                    </div>
                </div>
                : null}
        </>
    );
};

const PanelHeader = ({ chaptersName , fromTestSeries}) => (
    <Row className="r-c-sb" style={{ padding: "10px" }}>
        <span className='r-c-c' style={{ fontSize: 16, fontWeight: "600", color: "#636363" }}>
            {chaptersName?.icon ?
                <img style={{ width: 45, height: 35, marginRight: 10 }} src={`${Api._s3_url}${chaptersName?.icon?.iconPath ? chaptersName?.icon?.iconPath:chaptersName?.icon}`} alt="Physics" />
                :
                <SquareSubIcon name={chaptersName?.name} size={24} />
            }
            {chaptersName?.name}
        </span>
        {fromTestSeries
            ?
            <span className="r-c-c" style={{ fontSize: 16, color: "#AEAEAE", fontWeight: "normal" }}>
                {chaptersName?.testSeriesChapter?.length} {chaptersName?.testSeriesChapter?.length === 1 ? 'Chapter' : 'Chapters'}
            </span>
            :
            <span className="r-c-c" style={{ fontSize: 16, color: "#AEAEAE", fontWeight: "normal" }}>
                {chaptersName?.courseChapter?.length} {chaptersName?.courseChapter?.length === 1 ? 'Chapter' : 'Chapters'}
            </span>
        }

    </Row>
);

function ChapterContent({ singleCourseDetailsDataContainer, fromTestSeries,storeCourseIdAndType, isDownloadBarVisible, showContactBar}) {
    const history = useHistory();
    const location = useLocation();
    const [activePanels, setActivePanels] = useState([location?.state?.key?.selectedSubject ? location?.state?.key?.selectedSubject : 0]);

    const handlePanelChange = (panelKey) => {
        setActivePanels((prevPanels) =>
            prevPanels.includes(panelKey)
                ? prevPanels.filter((key) => key !== panelKey)
                : [...prevPanels, panelKey]
        );
    };
    const ChapterList = ({ chapList, index }) => {
        return (
            <div>
                <div className='chapter-list-mobile-container'>
                    <div className='m-r-20' style={{ fontSize: "14px", color: "#8C8C8C" }}>{index + 1}</div>
                    <div style={{ fontSize: "14px", fontWeight: 600 }}>{chapList?.name}</div>
                </div>
            </div>
        )
    }
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const CalculateMarginTop = () => {
        if (isDownloadBarVisible && showContactBar) {
          return 160
        } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
          return 110
        } else if (!isDownloadBarVisible && !showContactBar) {
          return 60
        }
      }
    return (
        <div onClick={(e)=>{e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false)}} style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 0 }}>
            <div className='mobile-topbar-parent'>
                <TopBar isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked}/>
            </div>
            <div className='mobile-header-parent'>
                <MobilePageHeader title={location?.state?.type?.name} onBackPress={() => history.goBack()} subChapter={location?.state?.type?.courseChapter?.length > 0 ? location?.state?.type?.courseChapter?.length : ''} sider={true} />
            </div>
            {fromTestSeries ? <div className='course-content-parent' style={{ backgroundColor: "#FAFAFAFA", padding: "20px 120px" }}>
                <div className='chapter-content-header r-c-fs m-t-20'>
                    <div className='r-c-c'><ArrowLeftOutlined
                        // onClick={() => history.push({
                        //     pathname: "/resource-details",
                        //     state: {
                        //         id: singleCourseDetailsDataContainer?.testSeriesDetails?.id,
                        //         resourceType: 'testSeries'
                        //     }
                        // })
                        // }
                        onClick={() => history.goBack()}
                        style={{ marginRight: 10, fontSize: "14px" }} /> </div>
                    <div className='course-content-header'>Syllabus</div>
                </div>
                <div className='chapter-container-parent '>
                    {singleCourseDetailsDataContainer?.testSeriesDetails?.subjectTestSeries?.map((item, index) => (
                        <Collapse
                            key={index}
                            accordion={false}
                            expandIcon={({ isActive }) => (
                                <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: 15 }} />
                            )}
                            style={{
                                marginBottom: 20,
                                // borderRadius: 10,
                            }}
                            onChange={() => handlePanelChange(index)}
                            activeKey={activePanels}
                        >
                            <Collapse.Panel
                                header={<PanelHeader chaptersName={item?.subject}  fromTestSeries = {fromTestSeries}/>}
                                key={index}
                            >
                                {item?.subject?.testSeriesChapter?.length > 0 ?
                                    <PanelContent courseChapter={item?.subject?.testSeriesChapter} />
                                    : <></>}
                            </Collapse.Panel>
                        </Collapse>
                    ))}
                </div>
            </div>
                :
                <div className='course-content-parent' style={{ backgroundColor: "#FAFAFAFA", padding: "20px 120px" }}>
                    <div className='chapter-content-header r-c-fs m-t-20'>
                        <div className='r-c-c'><ArrowLeftOutlined onClick={() => 
                        {
                            storeCourseIdAndType("id",singleCourseDetailsDataContainer?.course?.id);
                            storeCourseIdAndType("type",singleCourseDetailsDataContainer?.course?.resourceType)
                            history.push({
                            pathname: `/resource-details?id=${singleCourseDetailsDataContainer?.course?.id}&type=${singleCourseDetailsDataContainer?.course?.resourceType}`,
                            state: {
                                id: singleCourseDetailsDataContainer?.course?.id,
                                resourceType: singleCourseDetailsDataContainer?.course?.resourceType
                            }
                        })}
                        } style={{ marginRight: 10, fontSize: "14px" }} /> </div>
                        <div className='course-content-header'>Course content</div>
                    </div>
                    <div className='chapter-container-parent'>
                        {singleCourseDetailsDataContainer?.course?.subjects?.map((item, index) => (
                            <Collapse
                                key={index}
                                accordion={false}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: 15 }} />
                                )}
                                style={{
                                    marginBottom: 20,
                                    // borderRadius: 10,
                                }}
                                onChange={() => handlePanelChange(index)}
                                activeKey={activePanels}
                            >
                                <Collapse.Panel
                                    header={<PanelHeader chaptersName={item}  fromTestSeries={fromTestSeries}/>}
                                    key={index}
                                >
                                    {item?.courseChapter?.length > 0 ?
                                        <PanelContent courseChapter={item?.courseChapter} />
                                        : <></>}
                                </Collapse.Panel>
                            </Collapse>
                        ))}
                    </div>
                </div>
            }


            {
                fromTestSeries ?
                    <div className='course-content-parent-mobile'>
                        <div className='chapter-container-parent-mobile'>
                            {location?.state?.type?.subject?.testSeriesChapter?.map((item, index) => (
                                <>
                                    <ChapterList chapList={item} index={index} />
                                </>
                            ))}
                        </div>
                    </div>
                    :
                    <div className='course-content-parent-mobile'>
                        <div className='chapter-container-parent-mobile'>
                            {location?.state?.type?.courseChapter?.map((item, index) => (
                                <>
                                    <ChapterList chapList={item} index={index} />
                                </>
                            ))}
                        </div>
                    </div>
            }


            <NewFooter/>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { singleCourseDetailsDataContainer, fromTestSeries, isDownloadBarVisible, showContactBar } = state.studentMarketPlace;
    return { singleCourseDetailsDataContainer, fromTestSeries, isDownloadBarVisible, showContactBar };
};

const mapDispatchToProps = (dispatch) => ({
    setFromTestSeries: (val) => dispatch(setFromTestSeries(val)),
  storeCourseIdAndType:(key,val) => dispatch(storeCourseIdAndType(key,val))
});
export default connect(mapStateToProps, mapDispatchToProps)(ChapterContent);
