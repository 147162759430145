import React, { useState } from 'react';
import { Modal, Button } from 'antd';
// import './modal.css';

const ExitModalPractice = ({visible, handleOk, handleCancel}) => {

  return (
    <div className="exitModal">
      {/* <Button type="primary" onClick={showModal}>
        EXIT
      </Button> */}
      <Modal className="exitModal" visible={visible} cancelText={"LEAVE"} okText={"RESUME"} onOk={handleOk} onCancel={handleCancel} footer={[
            <Button
            shape="round"
            type="primary"
            style={{ width: 120,backgroundColor:'white', color:'#636363',borderColor:'#636363' }}
            onClick={handleCancel}
    
          >
            <div className="bold-600" style={{ fontSize: 12,}}>
              RESUME
            </div>
          </Button>,
            <Button
            shape="round"
            type="primary"
            style={{ width: 120 }}
            onClick={handleOk}
          >
            <div className="bold-600" style={{ fontSize: 12 }}>
              EXIT
            </div>
          </Button>
          ]}>
         
         <h2 className="exit_h1">Do you want to exit the test?</h2>
         <p className="exit_p m-t-10">The test will remain paused and you can resume the test later.</p>

         
      </Modal>
    </div>
  )
}

export default ExitModalPractice