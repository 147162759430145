import { ConsoleSqlOutlined } from "@ant-design/icons";
import moment from "moment";
import availableSubscriptionPackages from "pages/profile/availableSubscriptionPackages";
import {
  SET_TAB,
  SET_STATUS,
  SET_ADD_ROLE,
  SET_ROLE_DASHBOARD,
  SET_MONTH_OR_WEEK,
  SET_USER_DASHBOARD,
  SET_USERBY_STATS,
  SET_ROLE,
  SET_USER_DETAILS,
  SET_SEARCH_NAME,
  SET_USERNAME,
  SET_PHONE,
  SET_EMAIL,
  SET_USER_CLASS,
  SET_ROLE_DASHBOARD_DATA,
  SET_UNASSIGNED_STUDENTS,
  SET_SORT_TYPE,
  SET_SECTION_DETAILS,
  SET_STUDENTSLIST_BY_STD,
  SET_USERSLIST_BY_ROLE,
  SET_STARTDATE,
  SET_ENDDATE,
  SET_GRAPH_DATA,
  SET_GENDER_RATIO,
  SET_ROLL_NUMBER,
  GET_CLASS_NAMES,
  SET_PRIVILAGE,
  SET_CLASS_ID,
  GET_ROLL_NUMBERS,
  SET_SPECIFIC_USER_ID,
  SET_USER_DETAILS_BY_ID,
  SET_AUTO_APPROVAL,
  //for edit form
  SET_EDIT_NAME,
  SET_EDIT_ROLE,
  SET_EDIT_PHONE,
  SET_EDIT_EMAIL,
  SET_EDIT_GENDER,
  SET_EDIT_BLOOD_GROUP,
  SET_EDIT_DATE_OF_BIRTH,
  SET_EDIT_EDIT_PRIVILAGES,
  //current address
  SET_EDIT_CURRENT_ADDRESS,
  SET_EDIT_CURRENT_PINCODE,
  SET_EDIT_CURRENT_STATE,
  SET_EDIT_CURRENT_DISTRICT,
  SET_EDIT_CURRENT_COUNTRY,
  //permanent addres
  SET_EDIT_PERMANENT_ADDRESS,
  SET_EDIT_PERMANENT_PINCODE,
  SET_EDIT_PERMANENT_STATE,
  SET_EDIT_PERMANENT_DISTRICT,
  SET_EDIT_PERMANENT_COUNTRY,
  //permanent address
  SET_EDIT_EMERGENCY_NAME,
  SET_EDIT_EMERGENCY_PHONE,
  SET_EDIT_EMERGENCY_RELATION,
  //for student course details
  SET_COURSE_DETAILS,
  STORE_UNASSIGNED_STUDENTS,
  STD_ID,
  STORE_SEARCHED_RESULTS,
  SET_PAGE,
  SET_LIMIT,
  FETCH_UNASSIGNED_COURSES,
  STORE_SELECTED_COURSE,
  STORE_SELECTED_DATE,
  STORE_CREATEUPLOAD_TYPE,
  STORE_ADDUSER_FIELDS,
  CLEAR_ADDUSER_FIELDS,
  STORE_EXCEL_DETAILS,
  STORE_EXCEL_FILEID,
  STORE_EXCEL_DATA,
  STORE_PENDING_USERS,
  STORE_STANDARDS
} from "../actions/userManagement";

const initialState = {
  tab: "Pending",
  userRequestStatus: null,
  roleToAdd: "",
  dashBoardRole: "",
  isMonthOrWeek: "week",
  startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  dashboardData: {},
  usersByStats: {},
  role: null,
  userDetails: {},
  search: null,
  userName: null,
  phone: null,
  email: null,
  class: null,
  dashBoardDataByRole: {},
  unAssignedStudents: {},
  sort: null,
  sectionDetails: {},
  studentsListByStd: {},
  usersListByRole: {},
  growthSummary: [],
  gendrRatio: [],
  privilages: [],
  classList: [],
  classId: null,
  nextRollNo: null,
  specificUserId: null,
  autoApproval: null,
  //for basic Info
  editName: null,
  editRole: null,
  editPhone: null,
  editEmail: null,
  editGender: null,
  editBloodGroup: null,
  editDateOfBirth: null,
  editPrivilage: [],
  //for current Address
  editCurrentAddress: null,
  editCurrentPinCode: null,
  editCurrentState: null,
  editCurrentDistrict: null,
  editCurrentCountry: null,
  //for premanent Address
  editPermanentAddress: null,
  editPermanentPinCode: null,
  editPermanentState: null,
  editPermanentDistrict: null,
  editPermanentCountry: null,
  //for emergency contact
  editEmergencyName: '',
  editEmergencyPhone: null,
  editEmergencyRelation: null,
  userDetailsById: {},
  //for student couse details
  studentCourseDetails: {
    course: null,
    class: null,
    section: null,
    rollNumber: null
  },
  unassignedStudsList: [],
  stdId: '',
  UpdateRollNo: '',
  storeSearchResponse: [],
  pageNumber: 1,
  pageLimit: 25,
  coursesContainer: [],
  selectedCourseName: null,
  selectedDate: null,
  ChooseUploadType: { type: '' },
  storeAddUserDetails: { role: '', name: '', phoneNo: null, email: '', course: '', privilage: [] },
  excelFile: null,
  excelFileId: null,
  excelData: null,
  pendingUsers: {},
  standards: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_DETAILS: {
      let newStudentCourseDetails = { ...state.studentCourseDetails }
      newStudentCourseDetails[action.key] = action.res
      return {
        ...state,
        studentCourseDetails: newStudentCourseDetails
      }
    };
    case SET_AUTO_APPROVAL:
      return {
        ...state,
        autoApproval: action.payload,
      };
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case SET_USER_DETAILS_BY_ID:
      return {
        ...state,
        userDetailsById: action.payload,
      };
    case SET_SPECIFIC_USER_ID:
      return {
        ...state,
        specificUserId: action.payload,
      };
    case SET_EDIT_EMERGENCY_NAME:
      return {
        ...state,
        editEmergencyName: action.payload,
      };
    case SET_EDIT_EMERGENCY_PHONE:
      return {
        ...state,
        editEmergencyPhone: action.payload,
      };
    case SET_EDIT_EMERGENCY_RELATION:
      return {
        ...state,
        editEmergencyRelation: action.payload,
      };
    //perminent use

    case SET_EDIT_PERMANENT_COUNTRY:
      return {
        ...state,
        editPermanentCountry: action.payload,
      };
    case SET_EDIT_PERMANENT_DISTRICT:
      return {
        ...state,
        editPermanentDistrict: action.payload,
      };
    case SET_EDIT_PERMANENT_STATE:
      return {
        ...state,
        editPermanentState: action.payload,
      };
    case SET_EDIT_PERMANENT_PINCODE:
      return {
        ...state,
        editPermanentPinCode: action.payload,
      };
    case SET_EDIT_PERMANENT_ADDRESS:
      return {
        ...state,
        editPermanentAddress: action.payload,
      };
    //current use
    case SET_EDIT_CURRENT_COUNTRY:
      return {
        ...state,
        editCurrentCountry: action.payload,
      };
    case SET_EDIT_CURRENT_DISTRICT:
      return {
        ...state,
        editCurrentDistrict: action.payload,
      };
    case SET_EDIT_CURRENT_STATE:
      return {
        ...state,
        editCurrentState: action.payload,
      };
    case SET_EDIT_CURRENT_PINCODE:
      return {
        ...state,
        editCurrentPinCode: action.payload,
      };
    case SET_EDIT_CURRENT_ADDRESS:
      return {
        ...state,
        editCurrentAddress: action.payload,
      };
    case SET_EDIT_EDIT_PRIVILAGES:
      return {
        ...state,
        editPrivilage: action.payload,
      };
    case SET_EDIT_DATE_OF_BIRTH:
      return {
        ...state,
        editDateOfBirth: action.payload,
      };
    case SET_EDIT_BLOOD_GROUP:
      return {
        ...state,
        editBloodGroup: action.payload,
      };
    case SET_EDIT_GENDER:
      return {
        ...state,
        editGender: action.payload,
      };
    case SET_EDIT_EMAIL:
      return {
        ...state,
        editEmail: action.payload,
      };
    case SET_EDIT_PHONE:
      return {
        ...state,
        editPhone: action.payload,
      };
    case SET_EDIT_ROLE:
      return {
        ...state,
        editRole: action.payload,
      };
    case SET_EDIT_NAME:
      return {
        ...state,
        editName: action.payload,
      };
    case GET_ROLL_NUMBERS:
      return {
        ...state,
        nextRollNo: action.payload,
      };
    case GET_CLASS_NAMES:
      return {
        ...state,
        classList: action.payload,
      };
    case SET_PRIVILAGE:
      return {
        ...state,
        privilages: action.payload,
      };
    case SET_GENDER_RATIO:
      return {
        ...state,
        gendrRatio: action.payload,
      };
    case SET_GRAPH_DATA:
      return {
        ...state,
        growthSummary: action.payload,
      };
    case SET_STATUS:
      return {
        ...state,
        userRequestStatus: action.payload,
      };
    case SET_STARTDATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_ENDDATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case SET_ADD_ROLE:
      return {
        ...state,
        roleToAdd: action.payload,
      };
    case SET_ROLE_DASHBOARD:
      return {
        ...state,
        dashBoardRole: action.payload,
      };
    case SET_MONTH_OR_WEEK:
      return {
        ...state,
        isMonthOrWeek: action.payload,
      };
    case SET_USER_DASHBOARD:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case SET_USERBY_STATS:
      return {
        ...state,
        usersByStats: action.payload,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_SEARCH_NAME:
      return {
        ...state,
        search: action.payload,
      };
    case SET_USERNAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_USER_CLASS:
      return {
        ...state,
        class: action.payload,
      };
    case SET_CLASS_ID:
      return {
        ...state,
        classId: action.payload,
      };
    case SET_ROLE_DASHBOARD_DATA:
      return {
        ...state,
        dashBoardDataByRole: action.payload,
      };
    case SET_UNASSIGNED_STUDENTS:
      return {
        ...state,
        unAssignedStudents: action.payload,
      };
    case SET_SORT_TYPE:
      return {
        ...state,
        sort: action.payload,
      };
    case SET_SECTION_DETAILS:
      return {
        ...state,
        sectionDetails: action.payload,
      };
    case SET_STUDENTSLIST_BY_STD:
      return {
        ...state,
        studentsListByStd: action.payload,
      };
    case STORE_UNASSIGNED_STUDENTS:
      return {
        ...state,
        unassignedStudsList: action.payload
      }
    case STD_ID:
      return {
        ...state,
        stdId: action.id
      }
    case SET_ROLL_NUMBER:
      return {
        ...state,
        nextRollNo: action.res
      }
    case STORE_SEARCHED_RESULTS:
      return {
        ...state,
        storeSearchResponse: action.val
      }
    case SET_PAGE:
      return {
        ...state,
        pageNumber: action.val
      }
    case SET_LIMIT:
      return {
        ...state,
        pageLimit: action.val
      }
    case FETCH_UNASSIGNED_COURSES:
      return {
        ...state,
        coursesContainer: action.val
      }
    case STORE_SELECTED_COURSE:
      return {
        ...state,
        selectedCourseName: action.val
      }
    case STORE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.val
      }

    case STORE_CREATEUPLOAD_TYPE:
      let ChooseUploadTypeNew = { ...state.ChooseUploadType };
      if (action.key === 'type') {
        ChooseUploadTypeNew.type = action.val;
      }
      return {
        ...state,
        ChooseUploadType: ChooseUploadTypeNew
      }

    case STORE_ADDUSER_FIELDS:
      let storeAddUserDetailsNew = { ...state.storeAddUserDetails }
      if (action.key === 'role') {
        storeAddUserDetailsNew.role = action.val
      } else if (action.key === 'name') {
        storeAddUserDetailsNew.name = action.val
      } else if (action.key === 'phoneNo') {
        storeAddUserDetailsNew.phoneNo = action.val
      } else if (action.key === 'email') {
        storeAddUserDetailsNew.email = action.val
      } else if (action.key === 'course') {
        storeAddUserDetailsNew.course = action.val
      } else if (action.key === 'privilage') {
        storeAddUserDetailsNew.privilage = action.val
      }
      return {
        ...state,
        storeAddUserDetails: storeAddUserDetailsNew
      }

    case CLEAR_ADDUSER_FIELDS:
      return {
        ...state,
        storeAddUserDetails: { role: '', name: '', phoneNo: null, email: '', course: '', privilage: [] }
      }

    case STORE_EXCEL_DETAILS:
      return {
        ...state,
        excelFile: action.res
      }

    case STORE_EXCEL_FILEID:
      return {
        ...state,
        excelFileId: action.id
      }

    case STORE_EXCEL_DATA:
      let newPendingUsers = [...state.pendingUsers];
      let excelIdsMap = {}
      action.res.data?.forEach((item, index) => {
        excelIdsMap[item.id] = index
    })
      console.log("excelidsmap", excelIdsMap)
      for (let i = 0; i < newPendingUsers.length; i++) {
        // console.log("newpending", newPendingUsers[i].id, excelIdsMap, newPendingUsers[i].id in excelIdsMap)
        if (newPendingUsers[i].id in excelIdsMap) {
          // console.log("lrm", excelIdsMap[newPendingUsers[i].id])
          newPendingUsers[i].status = action.res.data[excelIdsMap[newPendingUsers[i].id]].status
          newPendingUsers[i].failMessage = action.res.data[excelIdsMap[newPendingUsers[i].id]].failMessage
        }
      }

      return {
        ...state,
        excelData: { ...action.res, data: [...action.res.data] },
        pendingUsers: newPendingUsers
      }

    case STORE_PENDING_USERS:

      return {
        ...state,
        pendingUsers: action.res
      }

    case STORE_STANDARDS:
      return {
        ...state,
        standards: action.res
      }


    default:
      return state;
  }
};

export default reducer;