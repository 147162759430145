import { Divider, Modal } from 'antd'
import { Button } from 'components/Button'
import UserAvatar from 'components/userAvatar'
import moment from 'moment'
import React from 'react'
import { Api } from 'services'

function RemainderHistoryModal({ isRemainderHistoryModalVisible, setRemainderHistoryModalVisible, feeRemindersData }) {
    const handleCancel = () => {
        setRemainderHistoryModalVisible(false)
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isRemainderHistoryModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}>
            <center style={{ fontSize: "20px", color: "#191919", fontWeight: "bold" }}>Reminder History</center>
            <div className='m-t-20 scroll-bar-universal' style={{ height: 315 }}>
                {feeRemindersData?.map((item) => (
                    <>
                        <div className='r-c-sb'>
                            <div className='r-ac'>
                                <UserAvatar size={42} shape="circle" name={item?.reminderName} img={item?.reminderImage == "default.png" ? "" : `${Api._s3_url}${item?.reminderImage}`} />
                                <div className='m-l-10' style={{ color: "#191919", fontSize: 16 }}>{item?.reminderName}</div>
                            </div>
                            <div style={{ color: "#636363" }}>{moment(item?.date).format("DD/MM/YYYY, hh:mm A")}</div>
                        </div>
                        <Divider style={{ color: "1px solid #E6E6E6", margin: "10px 0px" }} />
                    </>
                ))}
            </div>
            <div className='r-c-c-c'><Button style={{ marginTop: 20 }} onClick={handleCancel}>CLOSE</Button></div>
        </Modal>
    )
}

export default RemainderHistoryModal