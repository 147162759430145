import React from 'react';
import { Api } from '../../services';
import {connect} from 'react-redux';
import Tabs from 'antd/lib/tabs';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Avatar from 'antd/lib/avatar';
import Select from 'antd/lib/select';
import Tag from 'antd/lib/tag';
import Button from 'antd/lib/button';
import Empty from 'antd/lib/empty';
import Pagination from 'antd/lib/pagination';
import 'antd/lib/tabs/style/index';
import 'antd/lib/row/style/index';
import 'antd/lib/col/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/tag/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/empty/style/index';
import 'antd/lib/pagination/style/index';
import { Color } from '../../services';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import {toggleDetailModal, toggleNotifyModal, toggleScheduleModal, getNotification, rejectRequest, approveRequest, selectStatus, 
  selectPage, markAsRead, selectNotificationTab} from '../../actions/notification';
import UserModal from './modals/userModal';
import ScheduleModal from './modals/scheduleModal';
import NotificationModal from './modals/notificationModal';
import './styles.css';
import ReactHtmlParser from 'react-html-parser';


const { Option } = Select;
const { TabPane } = Tabs;

function isHTML(str) {
  var a = document.createElement('div');
  a.innerHTML = str;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true; 
  }

  return false;
}

function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

class Notification extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      statusVal : 'Pending',
      notificationId: '',
      count: 0,
    }
  }

  componentDidMount() {
    this.props.dispatch(getNotification('Notifications', this.props.selectedPage, this.props.selectedPage));
    // this.props.dispatch(getNotification("Requests",this.props.selectedStatus, this.props.selectedPage));
  }

  componentWillUnmount(){
    this.props.dispatch(selectNotificationTab("Notifications"));
  }
  // getRequests(page){
  //
  //   this.props.dispatch(getNotification("Requests",this.props.selectedStatus, page));
  // }

  // getNotifications(page){
  //   this.props.dispatch(selectPage(page))
  //   this.props.dispatch(getNotification("Requests",this.props.selectedStatus, page));
  // }
  tabCallback(key) {
    const type = key;
    const status = key === 1 ? null : "Pending";
    this.props.dispatch(selectNotificationTab(key));
    this.props.dispatch(selectPage(1));
    this.props.dispatch(selectStatus("Pending"));
    this.props.dispatch(getNotification(type, status, 1));
  }
  changeStatus(value){
    this.props.dispatch(selectPage(1));
    this.props.dispatch(selectStatus(value));
    this.props.dispatch(getNotification("Requests",value,1));
  }
  showDetailModal(data){
    if(data.type === "User"){
      this.props.dispatch(toggleDetailModal(true, data));
    }
    else if(data.type === "Schedule"){
      this.props.dispatch(toggleScheduleModal(true, data));

    }
  }
  showNotifyModal(data, body, index){
    this.handleNotificationClick(index);
    this.props.dispatch(toggleNotifyModal(true, data, body))
  }
  pageRejectRequest(e, data){
    e.stopPropagation();
    this.props.dispatch(rejectRequest(data.id, this.props.selectedPage));
    this.setState({
      notificationId: data.id
    })
  }
  pageApproveRequest(e,data){
    e.stopPropagation();
    this.props.dispatch(approveRequest(data.id, this.props.selectedPage));
    this.setState({
      notificationId: data.id
    })
  }
  handlePageChange(page){
    this.props.dispatch(selectPage(page));
    this.props.dispatch(getNotification(this.props.selectedType,this.props.selectedStatus,page));
  }

  handleNotificationClick(index){
    const {notificationData} = this.props;
    if(notificationData && notificationData.items[index].status === "Unread"){
      const id = this.props.notificationData.items[index].id
      this.props.dispatch(markAsRead(id, index));
    }
  }

  render() {
    const { notificationData, requestData, approveLoader, rejectLoader, orgId, notificationLoader, selectedNotificationTab, role} = this.props;

    const operations = (
     <div style={{position: 'absolute', right: 0}}>
          <Pagination
          onChange={(page) => this.handlePageChange(page)}
          size="small" current={this.props.selectedPage}
          pageSize={24}
          showSizeChanger={false}
          total={this.props.itemsCount}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
      </div>

    );

    return (
      <div style={{height:'100%'}}>
        <Tabs className='notifyTab' activeKey={selectedNotificationTab} onChange={(e) => this.tabCallback(e)}  tabBarExtraContent={operations}>
          <TabPane tab="Notifications" key="Notifications">
            <div className='' style={{backgroundColor:'#f9f9f9', padding:20, borderRadius: 30, height:'calc(100% - 62px)', overflow:'scroll'}}>
              <div className='m-b-20'>
                {/* <div className='m-b-10 text-sm bold-600' style={{color:Color.darkGrey}}>Latest</div> */}
                <div className='display-flex' style={{flexWrap:'wrap'}}>
                  {!notificationLoader ? notificationData && notificationData.items && notificationData.items.length !== 0 ? notificationData.items.map((data, index) => {
                    const body = IsJsonString(data.message) && isHTML(JSON.parse(data.message).mobile) ? ReactHtmlParser(JSON.parse(data.message).mobile) : data.message;
                    return (
                    <Row className='cursor-pointer' onClick={() => this.showNotifyModal(data, body, index)}>
                      <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} style={{margin:8}}>
                      <div className='r-jsb-fc notifyCard' key={index} 
                      style={{background:data.status === 'Unread' ? '#f6f4ff' : '#fff', height: 100}}>
                        <div className='display-flex flex-1' style={{width: '100%', height: '100%'}}>
                          <div className='m-l-10 notifyCardWrap' style={{width: '100%', height: '100%'}}>
                            <div style={{fontWeight:'bold', color: Color.primary}}>{data.title}</div>
                            <div className='m-b-10' style={{color:Color.black, width: '80%',paddingLeft:'10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                              {body}
                            </div>
                            <div className='r-jsb'>
                              <div style={{ fontSize: 'small'}}><Tag>{moment(data.createdAt).format('DD-MM-YYYY hh:mm A')}</Tag></div>
                              {/* <div><a style={{fontSize: 'small'}} href="">
                              -{data.authorName} {data.authorImage && <Avatar src={`${Api._base_url}/api/${orgId}/uploads/users/${data.authorImage}`} size="small"/>} </a>
                              </div> */}
                            </div>

                          </div>
                        </div>
                      </div>
                      </Col>
                    </Row>
                  )}) : <div className='r-c-c' style={{width:'100%', height:"50vh"}}><Empty  description="No notifications"/></div> : 
                  <div className='r-c-c-c' style={{width:'100%',  height:"50vh"}}>
                    <LoadingOutlined style={{fontSize: '10em', color: Color.primary, opacity: 0.5}}/> 
                  </div> }
                </div>
              </div>
            </div>
          </TabPane>
          {/* {role && (role.includes("SuperAdmin") || role.includes("Admin")) &&<TabPane tab="Request" key="Requests">
            <div className='' style={{backgroundColor:'#f9f9f9', padding:20, borderRadius: 30, height:'calc(100% - 62px)', overflow:'scroll'}}>
              <div>
                <div className='r-c-sb m-b-20' style={{backgroundColor:'rgb(234, 234, 234)', padding:5, borderRadius:50, width:150}}>
                  <Select onChange={(value) => this.changeStatus(value)} value={this.props.selectedStatus} className='borderLessSelect roundBorderSelect bold-600' value={this.props.selectedStatus} style={{color:Color.primary, width:'140px', fontSize:16, borderRadius: 20 }} >
                    {['Pending', 'Approved','Rejected'].map((data, index) => (
                      <Option value={data}  key={index}>{data}</Option>
                    ))}
                  </Select>
                </div>
                {this.props.selectedStatus === 'Approved'
                ?
                <div className='display-flex' style={{flexWrap:'wrap'}}>
                  {!notificationLoader ? requestData.length !== 0 ? requestData.map((data, index) => (
                    <Row >
                    <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} style={{margin:8}}>
                    <div onClick={() => this.showDetailModal(data)} className='r-jsb-fc notifyCard cursor-pointer'  key={index} style={{borderColor:Color.green, height: 100}}>
                      <div className='notifyCardWrap'>
                        {data.type === "User" ?
                          <div className='m-b-10' style={{color:Color.black}}><span style={{fontWeight: 'bold', color: Color.primary}}>{data.name}</span>'s <span style={{fontWeight: 'bold'}}> {data.type}</span> request has been approved successfully</div>
                        :
                          <div className='m-b-10' style={{color:Color.black}}><span style={{fontWeight: 'bold'}}> {data.type}</span> request for subject <span style={{fontWeight: 'bold', color: Color.primary}}>{data.name}</span> has been approved successfully</div>
                      }
                      </div>
                      <div style={{display: 'flex'}}>
                            <div style={{textAlign: 'left', marginRight:10, fontSize: 'small', flex:1}}><Tag>{moment(data.updatedAt).format('DD-MM-YY hh:mm A')}</Tag></div>
                      </div>
                    </div>
                    </Col>
                    </Row>
                  )) : <div className='r-c-c' style={{width:'100%', height:'100%'}}><Empty /></div> :
                    <div className='r-c-c-c' style={{width:'100%',  height:"50vh"}}>
                      <LoadingOutlined style={{fontSize: '10em', color: Color.primary, opacity: 0.5}}/> 
                    </div> 
                  }
                </div>
                :
                (this.props.selectedStatus === 'Rejected')
                ?
                <div className='display-flex' style={{flexWrap:'wrap'}}>
                  {!notificationLoader ? requestData.length > 0 ? requestData.map((data, index) => (
                    <Row >
                    <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} style={{margin:8}}>
                    <div c onClick={() => this.showDetailModal(data)} className='r-jsb-fc notifyCard cursor-pointer' key={index} style={{borderColor:Color.red, height: 100}}>
                      <div className='notifyCardWrap'>
                      {data.type === "User" ?
                        <div className='m-b-10' style={{color:Color.black}}><span style={{fontWeight: 'bold', color: Color.primary}}>{data.name}</span>'s <span style={{fontWeight: 'bold'}}> {data.type}</span> request has been rejected</div>
                      :
                        <div className='m-b-10' style={{color:Color.black}}><span style={{fontWeight: 'bold'}}> {data.type}</span> request for subject <span style={{fontWeight: 'bold', color: Color.primary}}>{data.name}</span> has been rejected</div>
                      }
                      </div>
                      <div style={{display: 'flex'}}>
                            <div style={{textAlign: 'left', marginRight:10, fontSize: 'small', flex:1}}><Tag>{moment(data.updatedAt).format('DD-MM-YY hh:mm A')}</Tag></div>
                      </div>
                    </div>
                    </Col>
                  </Row>
                  )) : <div className='r-c-c' style={{width:'100%', height:"50vh"}}><Empty description="No requests"/></div>:
                    <div className='r-c-c-c' style={{width:'100%',  height:"50vh"}}>
                      <LoadingOutlined style={{fontSize: '10em', color: Color.primary, opacity: 0.5}}/> 
                    </div> 
                  }
                </div>

                :
                <div className='display-flex' style={{flexWrap:'wrap'}}>
                  {!notificationLoader ?  requestData && requestData.length !== 0 ? Object.values(requestData).map((data, index) => (
                    <Row >
                    <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} style={{margin:8}}>
                    <div onClick={() => this.showDetailModal(data)} className='r-jsb-fc notifyCard cursor-pointer'  key={index} style={{height: 100}}>
                      <div className='notifyCardWrap'>
                        <div className='m-b-10' style={{color:Color.black}}>There is a new <span style={{fontWeight: 'bold'}}>
                          {data.type}</span> approval request for <span style={{fontWeight: 'bold', color: Color.primary}}>{data ? data.name : ""}</span></div>
                        <div style={{display: 'flex'}}>
                            <div style={{textAlign: 'left', marginRight:10, fontSize: 'small', flex:1}}><Tag>{moment(data.updatedAt).format('DD-MM-YY hh:mm A')}</Tag></div>
                            <div style={{textAlign: 'right', marginRight:10, flex:1}}>
                              <Button 
                                loading={approveLoader && data.id === this.state.notificationId} 
                                onClick={(e) => this.pageApproveRequest(e,data)} 
                                style={{background:Color.green, color:'#fff', border:'none', marginRight:10}} 
                                size='small' shape="circle" 
                                icon={<CheckOutlined  style={{fontSize:13}}/>
                                } />
                              <Button 
                                loading={rejectLoader && data.id === this.state.notificationId} 
                                onClick={(e) => this.pageRejectRequest(e,data)} 
                                style={{background:Color.red, color:'#fff', border:'none'}} 
                                size='small' shape="circle" 
                                icon={<CloseOutlined style={{fontSize:13}} 
                                />} />
                            </div>
                        </div>
                        </div>
                    </div>
                    </Col>
                </Row>
                  )) : <div className='r-c-c' style={{width:'100%', height:"50vh"}}><Empty description="No requests"/></div> :
                    <div className='r-c-c-c' style={{width:'100%',  height:"50vh"}}>
                      <LoadingOutlined style={{fontSize: '10em', color: Color.primary, opacity: 0.5}}/> 
                    </div>
                }
                </div>

                }

              </div>
            </div>
          </TabPane>} */}
        </Tabs>
        <UserModal />
        <ScheduleModal />
        <NotificationModal />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
     notificationData, requestData, approveLoader, rejectLoader, selectedType, selectedStatus, selectedPage, 
     itemsCount, notificationLoader, selectedNotificationTab
  } = state.notification;
  const {orgId, role} = state.session.user
  return {
     notificationData, requestData, approveLoader, rejectLoader, selectedType, selectedStatus, selectedPage,
     itemsCount, orgId, notificationLoader, selectedNotificationTab, role
  };
};

export default connect(mapStateToProps)(Notification);
