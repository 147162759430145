import React, { useState } from 'react'
import "./courseContent.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Api } from 'services'
import { Tooltip } from 'antd'
import ChapterContent from './chapterContent'
import { SquareSubIcon } from 'components/subIcon'
import { setFromTestSeries } from 'actions/studentMarketPlace'
import { connect } from 'react-redux'


function CourseTestContent({ singleCourseDetailsDataContainer, fromMyCourcesPage , resourceType, setFromTestSeries, fromTestSeriesPage}) {
console.log('sub111',setFromTestSeries,resourceType,fromMyCourcesPage,singleCourseDetailsDataContainer);

    const history = useHistory();
    
    const ChapterList = ({ courseChapter, index }) => {
        return (
            <div className='chapter-list-parent'>
                <div className='chapter-list-child'>
                    <div className='chapter-counts-index'>
                        Chapter {index + 1}
                    </div>
                    <div className='chapter-names'>
                        <Tooltip title={courseChapter?.name} placement='left'>
                            {courseChapter?.name}
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div style={{ marginTop: "20px" }} className={fromMyCourcesPage || fromTestSeriesPage ? 'courses-content-container-my-course' : "courses-content-container"}>

            {resourceType == "testSeries" ?
            <div className='course-content-header'>Syllabus</div>
            :
            <div className='course-content-header'>Course content</div>
            }

            {resourceType == "testSeries" ?
             <div className='course-chapter-parent'>
                {singleCourseDetailsDataContainer?.map((item, index) => (
                    item?.subject?.testSeriesChapter?.length > 0 ? (
                        <div className='course-chapter-child' key={index} style={{ height: item?.subject?.testSeriesChapter?.length > 6 ? '50dvh' : 'fit-content' }}>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent'>
                                    {item?.icon ? (
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} alt={`Chapter Icon for ${item?.name}`} />
                                    ) : (
                                        <SquareSubIcon name={item?.subject?.name} size={24} />
                                    )}
                                </div>
                                <div className='course-chapter-title'>
                                    <span className='chapter-name'>{item?.subject?.name}</span>
                                    <span className='chapter-total-count'> {item?.subject?.testSeriesChapter?.length} {item?.subject?.testSeriesChapter?.length === 1 ? 'Chapter' : 'Chapters'}</span>
                                </div>
                            </div>
                            <div className='chapter-border-bottom'></div>

                            {item?.subject?.testSeriesChapter?.slice(0, 6)?.map((i, chapterIndex) => (
                                <ChapterList key={chapterIndex} courseChapter={i} index={chapterIndex} />
                            ))}

                            {console.log('fromMyCourcesPage',fromMyCourcesPage, fromTestSeriesPage)}
                            {item?.subject?.testSeriesChapter?.length > 6 ? (
                                <div className='view-all-content p-20' onClick={() => { fromTestSeriesPage?.resourceType === 'testSeries' ? history.push({pathname: "/course-chapters",state:{fromTest:true}}) : history.push("/course-content") ;  setFromTestSeries(true) }}>VIEW ALL</div>
                            ) : null}
                        </div>
                    ) : null
                ))}

            </div>
            : 
            <div className='course-chapter-parent'>
                {singleCourseDetailsDataContainer?.subjects?.map((item, index) => (
                    item?.courseChapter?.length > 0 ? (
                        <div className='course-chapter-child' key={index} style={{ height: item?.courseChapter?.length > 6 ? '50dvh' : 'fit-content' }}>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent'>
                                    {item?.icon ? (
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} alt={`Chapter Icon for ${item?.name}`} />
                                    ) : (
                                        <SquareSubIcon name={item?.name} size={24} />
                                    )}
                                </div>
                                <div className='course-chapter-title'>
                                    <span className='chapter-name'>{item?.name}</span>
                                    <span className='chapter-total-count'> {item?.courseChapter?.length} {item?.courseChapter?.length === 1 ? 'Chapter' : 'Chapters'}</span>
                                </div>
                            </div>
                            <div className='chapter-border-bottom'></div>

                            {item?.courseChapter?.slice(0, 6)?.map((i, chapterIndex) => (
                                <ChapterList key={chapterIndex} courseChapter={i} index={chapterIndex} />
                            ))}
                            {item?.courseChapter?.length > 6 ? (
                                <div className='view-all-content p-20' onClick={() => { fromMyCourcesPage?.resourceType === 'course' ? history.push("/course-chapters") : history.push("/course-content") ;  setFromTestSeries(false) }}>VIEW ALL</div>
                            ) : null}
                        </div>
                    ) : null
                ))}

            </div>}
            
            {resourceType == "testSeries" ? <div className='course-chapter-parent-mobile'>
                {singleCourseDetailsDataContainer?.map((item, index) => (
                    item?.subject?.testSeriesChapter?.length > 0 ? (
                        <div className='course-chapter-child-mobile'>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent' >
                                    {item?.icon ?
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} />
                                        :
                                        <SquareSubIcon name={item?.subject?.name} size={24} />
                                    }
                                </div>
                                <div className='course-chapter-title' onClick={() => history.push({ pathname: "/course-content", state: { type: item } })}>
                                    <span className='chapter-name-mobile'>{item?.subject?.name}</span>
                                    <span className='chapter-total-count-mobile'> {item?.subject?.testSeriesChapter?.length} {item?.subject?.testSeriesChapter?.length === 1 ? 'Chapter' : 'Chapters'}</span>
                                </div>
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
            :
            <div className='course-chapter-parent-mobile'>
                {singleCourseDetailsDataContainer?.subjects?.map((item, index) => (
                    item?.courseChapter?.length > 0 ? (
                        <div className='course-chapter-child-mobile'>
                            <div className='chapter-name-header'>
                                <div className='chapter-icon-parent' >
                                    {item?.icon ?
                                        <img className='chapter-icon' src={`${Api._s3_url}${item?.icon?.iconPath}`} />
                                        :
                                        <SquareSubIcon name={item?.name} size={24} />
                                    }
                                </div>
                                <div className='course-chapter-title' onClick={() => history.push({ pathname: "/course-content", state: { type: item } })}>
                                    <span className='chapter-name-mobile'>{item?.name}</span>
                                    <span className='chapter-total-count-mobile'> {item?.courseChapter?.length} {item?.courseChapter?.length === 1 ? 'Chapter' : 'Chapters'}</span>
                                </div>
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
            }
            
            
        </div>
    )
}
<ChapterContent />

const mapStateToProps = (state) => {

};

const mapDispatchToProps = (dispatch) => ({
    setFromTestSeries:(val) => dispatch(setFromTestSeries(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseTestContent)






