import { cloneSectionApi, getAllClasses, postAddClass, postAddClassWithImport } from 'actions/classRoom';
import { Checkbox, Col, Input, Modal, Row, Select } from 'antd';
import AnimatedTextField from 'components/AnimatedTextField';
import { Button } from 'components/Button';
import { ModalSubTitle } from 'components/Typography';
import React, { useState } from 'react'
import { connect } from 'react-redux';

const { Option } = Select;

function AddNewSectionModal({ isAddSectionVisible, setIsAddSectionVisible, setShowClassCreatedModal, selectedClassDetails, courses, postAddClass, postAddClassWithImport, cloneSectionApi, fromCourseDetails, newCourseId, getAllClasses }) {
  const [stdId, setStdId] = useState(null);
  const [sectionName, setSectionName] = useState(null);
  const [courseName, setCourseName] = useState(null);
  const [addSectionLoader, setAddSectionLoader] = useState(false);
  const [isImportChecked, setIsImportChecked] = useState(false)

  const handleCancel = () => {
    setIsAddSectionVisible(false)
  }
  const handleSectionSelect = (standardId) => {
    if (standardId !== stdId) {
      setStdId(standardId);
    } else {
      setStdId(null);
    }
  }
  const handleAddSection = () => {
    let courseId = courses?.response?.items.filter((item) => {
      return item.courseName == courseName;
    })[0]?.id
    setAddSectionLoader(true);
    if (stdId) {
      const params = {
        name: sectionName,
        stdId: stdId
      }
      cloneSectionApi(params, fromCourseDetails, () => setAddSectionLoader(false), () => setIsAddSectionVisible(false))
    } else {
      const params = {
        std: selectedClassDetails?.[0]?.std,
        section: sectionName,
        subjects: [],
        courseName: courseName ? courseName : null,
        courseId: fromCourseDetails ? newCourseId : courseId ? courseId : null,
      };
      postAddClass(
        params,
        () => setAddSectionLoader(false),
        (response) => {
          setIsAddSectionVisible(false);
          if (isImportChecked) {
            const linkArray = [{
              standardId: response?.id,
              courseId: response?.courseId
            }]
            postAddClassWithImport(linkArray)
          }
          if (fromCourseDetails) {
            getAllClasses(newCourseId)
          }
          else {
            setShowClassCreatedModal(true);
          }
        })
    }
  }

  return (
    <div>
      <Modal
        centered
        className="modal-round-corner"
        visible={isAddSectionVisible}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}
        closable={false}
        width={fromCourseDetails ? 550 : 700}>
        <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>{"Add a section"}</div>
        {selectedClassDetails?.filter(item => item.subCount > 0).length > 0 ?
          <>
            <div style={{ fontSize: "16px", color: "#636363", fontFamily: "roboto", marginTop: "10px", textAlign: 'center' }}>You can choose to clone the courses and it’s subjects from one of the sections below</div>
            <Row style={{ maxHeight: 250, overflowY: 'auto', padding: "15px 20px 0px 20px" }}>
              {selectedClassDetails?.filter(item => item.subCount > 0)?.map((standard, i) =>
                <Col span={12}>
                  <div className='m-b-15'>
                    <Checkbox onChange={() => handleSectionSelect(standard.id)} checked={standard.id === stdId}>
                      <div style={{ color: "#191919", fontSize: 16 }}>{standard.section} : {standard.std}</div>
                      <div style={{ color: "#636363" }}><span style={{ fontWeight: "bold" }}>{standard?.subCount}</span> subjects</div>
                    </Checkbox>
                  </div>
                </Col>
              )}
            </Row>
          </>
          :
          null
        }
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className='m-b-10' style={{marginTop:-5}}>
          <AnimatedTextField
            label={"Section"}
            isMandatory={true}
            inputId={"-999"}
            value={sectionName}
            handleInputChange={(e) => {
              if (e.target.value.length > 3) {
                return e.preventDefault();
              }
              setSectionName(e.target.value)
            }}
          />
          </div>
          {stdId || fromCourseDetails ? null :
            <>
            <div style={{display:'flex'}}><ModalSubTitle>Course</ModalSubTitle><span style={{ color: 'red' }}>&nbsp;*</span></div>    
              <Select
                placeholder="Select a course"
                style={{ width: "100%", marginBottom: 10 }}
                value={courseName}
                onChange={(value) => setCourseName(value.trim())}>
                {courses?.response?.items?.map((item) => (
                  <Option value={item?.courseName}>{item?.courseName}</Option>
                ))}
              </Select>
              <Checkbox style={{ color: "#191919", fontSize: 15 }} onChange={(e) => setIsImportChecked(e.target.checked)} disabled={!courseName}>Import predefined curriculum from selected course</Checkbox>
            </>
          }
          {fromCourseDetails && !stdId ? <Checkbox style={{ color: "#191919", fontSize: 15}} onChange={(e) => setIsImportChecked(e.target.checked)}>Import predefined curriculum from course</Checkbox> : null}
        </div>
        <div className='r-c-se m-t-10 m-b-10'>
          <Button onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={!sectionName || (!fromCourseDetails &&  !stdId && !courseName)}
            loading={addSectionLoader}
            type='primary'
            onClick={handleAddSection}>
            {stdId ? "CLONE" : "ADD"}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => {
  const { selectedClassDetails } = state.classRoom;
  const { courses } = state.course;
  const { newCourseId } = state.courseManagement;
  return {
    selectedClassDetails, courses, newCourseId
  };
};

const mapDispatchToProps = dispatch => ({
  postAddClass: (params, callBack, successCallBack) => dispatch(postAddClass(params, callBack, successCallBack)),
  cloneSectionApi: (params, fromCourseDetails, callBack, successCallBack) => dispatch(cloneSectionApi(params, fromCourseDetails, callBack, successCallBack)),
  postAddClassWithImport: (linkArray) => dispatch(postAddClassWithImport(linkArray)),
  getAllClasses: (id) => dispatch(getAllClasses(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewSectionModal)
// export default AddNewSectionModal