import React from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import Tabs from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import {  } from 'antd';
import 'antd/lib/button/style/index';
import 'antd/lib/menu/style/index';
import 'antd/lib/dropdown/style/index';
import 'antd/lib/avatar/style/index';
import 'antd/lib/tabs/style/index';
import 'antd/lib/page-header/style/index';
import 'antd/lib/modal/style/index';
import 'antd/lib/pagination/style/index';
import 'antd/lib/empty/style/index';

import { FaRegEdit, FaRegClone } from "react-icons/fa";
import { MdPublish, MdKeyboardBackspace } from "react-icons/md";
import '../styles.css';
import { Color } from 'services';
import { Col, Row , Typography} from 'antd';
import { actOnSelectedTest, publishResult, PublishTestResult, sendForReview, setIsCModalVisible, toggleReloaderFlag } from 'actions/practiseTest';
import { useHistory } from 'react-router-dom';




function ConfirmationModal ({isCModalVisible, setIsCModalVisible, 
                            cModalType, selectedTestId, actOnSelectedTest, sendForReview, publishResult, toggleReloaderFlag
                            , selectedTest, submittedTestsId}) {
    const history = useHistory();
    const publishResultCallback = (res) => {
        if (res.show?.type==='success'){
            if (window.location.pathname==='/test-submit-detail-screen'){
                history.goBack();
            }
        }
    }
    const handleYesClick = async() => {
        if (cModalType=="Review"){
                setIsCModalVisible(false);
            await sendForReview(selectedTestId,(bool)=>{});
                toggleReloaderFlag();
        }
        else if(cModalType=="Publish"){
                setIsCModalVisible(false);
            await publishResult(selectedTestId, (bool)=>{});
                toggleReloaderFlag();
        }
        else if (cModalType=="PublishResult"){
            setIsCModalVisible(false);
            await PublishTestResult(selectedTestId, publishResultCallback);
            toggleReloaderFlag();
        }
        else if (cModalType=="PublishResultEval"){
            setIsCModalVisible(false);
            await PublishTestResult(selectedTestId, publishResultCallback);
            toggleReloaderFlag();
        }
        else{
            setIsCModalVisible(false);
        await actOnSelectedTest(selectedTestId, cModalType, (bool)=>{});
            toggleReloaderFlag();
        }
    }
    return (
      <Modal
        centered
        className='modal-round-corner'
        style={{padding:0}}
        visible={isCModalVisible}
        onCancel={() => {setIsCModalVisible(false)}}
        onOk={()=>setIsCModalVisible(false)}
        footer={null}
      >

        <div className='text-sm text-center bold-600 color-purple m-t-20'>
            {cModalType=="Publish"
                ?
                "Publish Test"
            :cModalType=="Review"
                ?
                "Send for Review"
            :cModalType=="Delete"
                ?
                "Delete"
            :cModalType=="Cancel"
                ?
                "Cancel the Test"
            :cModalType=="Unpublish"
                ?
                "Unpublish"
            :cModalType=="PublishResultEval"
                ?
                "Publish Result"
            :null}
        </div>
        <div className='text-xs text-center a2grey m-b-20'>
            {cModalType=="Publish"
                ?
                "Are you sure you want to publish this test?"
            :cModalType=="PublishResult"
                ?
                "Are you sure you want to publish the result?"
            :cModalType=="Review"
                ?
                `Are you sure you want to send the test for review to ${selectedTest.teacherName}?`
            :cModalType=="Delete"
                ?
                "Are you sure you want to delete this test?"
            :cModalType=="Cancel"
                ?
                "Are you sure you want to cancel this test?"
            :cModalType=="Unpublish"
            ?
            "Are you sure you want to unpublish this test?"
            :cModalType=="PublishResultEval"
            ?
            "Are you sure you want to publish the result?"
            :null}
        </div>

        <div className='r-c-c m-t-50'>
          <Button className='blankGreyBtnWrap radius-100' onClick={() => {setIsCModalVisible(false)}}>CANCEL</Button>
          <Button className={'radius-100 m-l-20 ' +((cModalType=="Delete")||(cModalType=="Cancel")?"redBtnWrap":"purpleBtnWrap purpleButtonHoverEffect")} onClick={() => {handleYesClick()}}>YES</Button>
        </div>
      </Modal>
  );
}


const mapStateToProps = state => {
    const {selectedTestId, selectedTest, submittedTestsId} = state.practiseTest;
    return {selectedTestId, selectedTest, submittedTestsId};
};

const mapDispatchToProps = dispatch => ({
    actOnSelectedTest: (testid, type, callback)=> dispatch(actOnSelectedTest(testid, type, callback)),
    sendForReview: (selectedTestId,callback)=>dispatch(sendForReview(selectedTestId,callback)),
    setIsCModalVisible: (bool)=>dispatch(setIsCModalVisible(bool)),
    publishResult: (testid, callback)=> dispatch(publishResult(testid, callback)),
    toggleReloaderFlag: ()=>dispatch(toggleReloaderFlag()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
