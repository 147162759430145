import React from 'react';
import { Api, Color } from 'services';
import {Col, Row, Input, Button, Select, Tooltip, Upload, Radio} from 'antd';
import {CheckCircleOutlined, CloseOutlined, FileAddOutlined, FileImageOutlined, FilePdfOutlined, MessageOutlined} from '@ant-design/icons';


const {Dragger} = Upload;
const { Option } = Select;

const addrProofTypes = (excludeAadhaar) => {
if(excludeAadhaar){
    return [
        "Passport",
        "Voter ID"
    ]
}else{
    return [
        "Passport",
        "Aadhaar",
        "Voter ID"
    ]
}

}

const proprietorshipProofTypes = [
    "MSME/Udyam/Udyog Certificate",
    "GST Certificate",
    "Shop Establishment Act Certificate"
]

const adhaarInput = (kycDetails, handleUpdateDetails, validateData) => (
    <>
        <div className='text-xs color-black m-b-5 bold-600' >Aadhaar Number <span style={{color:Color.red}}>*</span></div>
        <Input 
            onChange={(e) => e.target.value.length <= 12 ? handleUpdateDetails(e.target.value.toUpperCase().trim(), 'adhaarNo') : e.preventDefault()}
            value={kycDetails.adhaarNo} placeholder="Aadhaar Number" className='radius-5 kyc-input-fields'
            style={{background:Color.lightGrey, border:'none'}}
            onBlur={e=> validateData(e.target.value, 'adhaar')}
            disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
        />
        {kycDetails.adhaarValid  === false ? <div className="text-xs red bold-600">Enter valid AADHAAR Number</div> : null}
        
        {/* <div className="text-xs color-grey m-t-5 dark-grey">OTP shall be sent to the mobile number linked to your Aadhaar. Enter the same to vetify.</div> */}
    </>
)

const aadhaarDocs = (kycDetails, handleUpdateDetails, handleUploadChange,
    beforeUpload, handleDocClick, handleEditCommentClick) => (
        <>
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton(`Aadhaar Front`, true, kycDetails, handleUploadChange, beforeUpload, 
                    `aadhaar_front`, handleUpdateDetails, handleDocClick, handleEditCommentClick)}
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 11, offset: 1 }}>
                {docButton(`Aadhaar Back`, true, kycDetails, handleUploadChange, beforeUpload, 
                        `aadhaar_back`, handleUpdateDetails, handleDocClick, handleEditCommentClick)}
            </Col>
        </>
)

export const docButton = (title, mandatory, kycDetails, handleUploadChange, beforeUpload, docType, handleUpdateDetails, handleDocClick, handleEditCommentClick) =>  (
    <>
    <div className="r-jsb">
        <div className='text-xs color-black m-b-5 bold-600' >{title}{mandatory && <span style={{color:Color.red}}>*</span>}</div>
        <div className="display-flex">
                
                {
                    kycDetails[docType + '_status'] === "rejected" ? 
                    <>
                        <Tooltip title="Reviewer's Comment">
                            <MessageOutlined className="m-r-10 cursor-pointer" 
                            onClick={e => {e.stopPropagation(); handleEditCommentClick(docType)}}/>
                        </Tooltip>
                        <div className="rejectedTag" >REJECTED</div>
                    </>
                    : kycDetails[docType + '_status'] === "approved" ?
                    <div className="approvedTag">APPROVED</div> :
                    null
                }
        </div>
        
        
    </div>
   { kycDetails[docType] && kycDetails[docType] !== "" ? 
   kycDetails[docType + '_type'] === "image" ? 
    <Button className="radius-5 kyc-doc-button" style={{width: "100%", 
    backgroundColor: kycDetails[docType + '_status'] === "rejected" ? Color.lightorange :
    kycDetails[docType + '_status'] === "approved" ? "#dbfff1" :
    "rgb(246, 247, 248)"
    }} onClick={() => handleDocClick(docType)}>
        <div className="r-jsb">
            <div className="r-c-c">
                <FileImageOutlined style={{color: "#1089ff", fontSize: '1.5em', marginRight:5}}/>
                <span className="m-r-5 text-xs bold-600">Click to View File</span> 
            </div>
            {kycDetails.kycStatus === "Submitted" ? 
                null
                :
                kycDetails.kycStatus === 'Approved' ?
                null
                :
            <div> 
                <Tooltip title="Remove Document">
                     <CloseOutlined disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"} onClick={(e) => {e.stopPropagation(); handleUpdateDetails(null, docType)}} /> 
                </Tooltip>
            </div>}
        </div>
    </Button> : 
    <a href={`${Api._s3_url}${kycDetails[docType]}`} target="blank">
        <Button className="radius-5 kyc-doc-button" style={{width: "100%", 
            backgroundColor: kycDetails[docType + '_status'] === "rejected" ? Color.lightorange :
            kycDetails[docType + '_status'] === "approved" ? "#dbfff1" :
            "rgb(246, 247, 248)"}}
        >
            <div className="r-jsb">
                <div className="r-c-c">
                    <FilePdfOutlined style={{color: "#1089ff", fontSize: '1.5em', marginRight:5}}/>
                    <span className="m-r-5 text-xs bold-600">Click to View File</span> 
                </div>
                {kycDetails.kycStatus === "Submitted" ? 
                null
                :
                kycDetails.kycStatus === 'Approved' ?
                null
                :
                <div> 
                    <Tooltip title="Remove Document">
                        <CloseOutlined disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"} onClick={(e) => {e.preventDefault(); handleUpdateDetails(null, docType)}} /> 
                    </Tooltip>
                    </div>}
            </div>
        </Button>
    </a>

    : 
    <div> 
        <Dragger className="radius-5 kyc-input-fields" 
            onChange={(e) => handleUploadChange(e, docType)}
            beforeUpload={(e) => beforeUpload(e)}
            showUploadList={false}
            disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
        >
            <div className="r-c-c">
                <FileAddOutlined style={{color: "#1089ff", fontSize: '1.5em', marginRight:5}}/>
                <span className="m-r-5 dark-grey text-xs">Drop File Here or</span>
                <span className="m-r-5 blue text-xs bold-600">Click to Upload</span> 
                {kycDetails.addressFront ? <CheckCircleOutlined style={{color: Color.green, fontSize: 18}}/> : null}
            </div>
        </Dragger>
    </div>}
    </>
)

const addressProofUpload = (kycDetails, handleUpdateDetails, handleUploadChange, beforeUpload, changeAddressProofType, 
    handleDocClick, handleEditCommentClick, updateAddressProofTypeIsAadhaar) => {
    const excludeAadhaar = ["Proprietorship", "Partnership", "Not Registered"].includes(kycDetails.orgType);
    return (
    <>
        <Row className="m-t-20">
            {excludeAadhaar ? 
                <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                    <div className='text-xs color-black m-b-5 bold-600' >Use same AADHAAR for address proof? <span style={{color:Color.red}}>*</span></div>
                    <div className="display-flex" style={{height: 50, alignItems: 'center'}}>
                        <Radio.Group
                         disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"} 
                        onChange={(e) => updateAddressProofTypeIsAadhaar(e)} value={kycDetails.addressProofIsAadhaar}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </div>
                </Col> : null
            }
           { excludeAadhaar && (kycDetails.addressProofIsAadhaar ) ? null : 
            <Col xs={{ span: 24 }} md={{ span: excludeAadhaar ? 11: 12, offset: excludeAadhaar ? 1 : 0 }}>
                <div className='text-xs color-black m-b-5 bold-600' >Authorized Signatory Address Proof <span style={{color:Color.red}}>*</span></div>
                <Select
                        value={kycDetails.proofOfAddrType}
                        rows={3} className='kyc-input-fields borderLessSelect'
                        style={{width: "100%", height: 50}}
                        onSelect={val =>  changeAddressProofType(val)}
                        placeholder="Select type of address proof"
                        disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                >
                    {addrProofTypes(excludeAadhaar).map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Col>}
        </Row>
        {excludeAadhaar && kycDetails.addressProofIsAadhaar ? null : 
        kycDetails.proofOfAddrType  ?
            <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton(`${kycDetails.proofOfAddrType} Front`, true, kycDetails, handleUploadChange, beforeUpload, 
                    `${kycDetails.proofOfAddrType !== "Voter ID" ? kycDetails.proofOfAddrType.toLowerCase() :
                    "voter"}_front`, handleUpdateDetails, handleDocClick, handleEditCommentClick)}
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                {docButton(`${kycDetails.proofOfAddrType} Back`, true, kycDetails, handleUploadChange, beforeUpload, 
                    `${kycDetails.proofOfAddrType !== "Voter ID" ? kycDetails.proofOfAddrType.toLowerCase() : "voter"}_back`, 
                    handleUpdateDetails, handleDocClick, handleEditCommentClick)}
            </Col>
        </Row>
            : null}
    </>
)}

export const documentDetailsPvt = (handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, beforeUpload, 
    handleUploadChange, changeAddressProofType, updateAddressProofTypeIsAadhaar) => (
    <div style={{width: "100%"}}>
        {addressProofUpload(kycDetails, handleUpdateDetails, handleUploadChange, beforeUpload, changeAddressProofType, 
            handleDocClick, handleEditCommentClick, updateAddressProofTypeIsAadhaar)}
        <Row className="m-t-50" >
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton("Business Registration Proof", true, kycDetails, handleUploadChange, beforeUpload, 
                'institute_registration_proof', handleUpdateDetails, handleDocClick, handleEditCommentClick)}
                <div className="text-xs m-t-10 dark-grey">Upload the scan of 
                    {kycDetails.orgType === "Private Limited" || kycDetails.orgType === "LLP" ?  
                        " Certificate of Incorporation" : " Registration Proof or Certificate" }
                </div>
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                {docButton("Business PAN Card", true, kycDetails, handleUploadChange, beforeUpload, 
                'pan_card', handleUpdateDetails, handleDocClick, handleEditCommentClick)}
                <div className="text-xs m-t-10 dark-grey">PAN Details must be of the mentioned business only.</div>
            </Col>
        </Row>
        {kycDetails.orgType === "Trust" || kycDetails.orgType === "Society" ?  
        <Row className="m-t-50" >
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton("Form 12A Allotment Letter", false, kycDetails, handleUploadChange, beforeUpload, 
                'form_12a', handleUpdateDetails, handleDocClick, handleEditCommentClick)}                
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                {docButton("Form 80G Allotment Letter", false, kycDetails, handleUploadChange, beforeUpload, 
                'form_80g', handleUpdateDetails, handleDocClick, handleEditCommentClick)}           
            </Col>
        </Row>: null}
    </div>
)

export const documentDetailsProp = (handleDocClick, kycDetails,handleEditCommentClick, handleUpdateDetails, beforeUpload, handleUploadChange, validateData, 
    changeAddressProofType, changeProprietoshipProofType, updateAddressProofTypeIsAadhaar) => (
    <div style={{width: "100%"}}>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {adhaarInput(kycDetails, handleUpdateDetails, validateData)}
            </Col>
            {/* <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >OTP </div>
                <Input onChange={(e) => {}}
                value={''} placeholder="Name of the contact person" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
            </Col> */}
        </Row>
        <Row className="m-t-20">
            {aadhaarDocs(kycDetails, handleUpdateDetails, handleUploadChange,
                                    beforeUpload, handleDocClick, handleEditCommentClick)}

        </Row>
        {/* <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 24, offset: 0 }}>
                <div> <Checkbox className="m-r-20"/> My aadhaar is not linked to my phone number  </div>    
            </Col>
        </Row> */}
        {addressProofUpload(kycDetails, handleUpdateDetails, handleUploadChange, beforeUpload, changeAddressProofType, 
            handleDocClick, handleEditCommentClick, updateAddressProofTypeIsAadhaar)}
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                <div className='text-xs color-black m-b-5 bold-600' >Business Registration Proof <span style={{color:Color.red}}>*</span></div>
                <Select
                      value={kycDetails.proprietorshipProof}
                      rows={3} className='kyc-input-fields borderLessSelect'
                      style={{width: "100%", height: 50}}
                      onSelect={val =>  changeProprietoshipProofType(val)}
                      placeholder="Select type of business proof"
                      disabled={kycDetails.kycStatus === "Submitted" || kycDetails.kycStatus === "Approved"}
                      // onClear={val => this.pageAddOrgDetail(null, 'orgType')}

                >
                    {proprietorshipProofTypes.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Col>
           {kycDetails.proprietorshipProof === "GST Certificate" ?
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >GSTIN <span style={{color:Color.red}}>*</span></div>
                <Input onChange={(e) => e.target.value.length <= 15 ?  handleUpdateDetails(e.target.value.toUpperCase().trim(), 'gstNo') : e.preventDefault()}
                value={kycDetails.gstNo} placeholder="Registered GST No." 
                onBlur={(e) => validateData(e.target.value, 'gst')}
                className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
                {kycDetails.gstValid  === false ? <div className="text-xs red bold-600">Enter valid GST Number</div> : null}
            </Col> : null}
        </Row>
        {kycDetails.proprietorshipProof ?
        <Row className="m-t-50" >
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton(`${kycDetails.proprietorshipProof}`, true, kycDetails, handleUploadChange, beforeUpload, 
                            `${kycDetails.proprietorshipProof === "MSME/Udyam/Udyog Certificate" ? 'msme' : 
                            kycDetails.proprietorshipProof === "GST Certificate" ? 'gst' :  'shop_establishment'
                            }`, handleUpdateDetails, handleDocClick, handleEditCommentClick)}
                <div className="text-xs m-t-10 dark-grey">Upload the scan of {kycDetails.proprietorshipProof}</div>
            </Col>
        </Row>: null}
        <Row className="m-t-50" >
            <Col xs={{ span: 24,}} md={{ span: 12, offset: 0 }} style={{width: '100%'}} >
                {docButton("Authorized Signatory PAN", true, kycDetails, handleUploadChange, beforeUpload, 
                'pan_card', handleUpdateDetails, handleDocClick, handleEditCommentClick)}
                <div className="text-xs m-t-10 dark-grey">Upload the scan of personal PAN card</div>
            </Col>
        </Row>
    </div>
)

export const documentDetailsPartner = (handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, 
    beforeUpload, handleUploadChange, validateData,
    changeAddressProofType, updateAddressProofTypeIsAadhaar) => (
    <div style={{width: "100%"}}>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {adhaarInput(kycDetails, handleUpdateDetails, validateData)}
            </Col>
            {/* <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >OTP </div>
                <Input onChange={(e) => {}}
                value={''} placeholder="Name of the contact person" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
            </Col> */}
        </Row>
        <Row className="m-t-20">
            {aadhaarDocs(kycDetails, handleUpdateDetails, handleUploadChange,
                                    beforeUpload, handleDocClick, handleEditCommentClick)}

        </Row>
        {/* <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 24, offset: 0 }}>
                <div> <Checkbox className="m-r-20"/> My aadhaar is not linked to my phone number  </div>    
            </Col>
        </Row> */}
         {addressProofUpload(kycDetails, handleUpdateDetails, handleUploadChange, beforeUpload, changeAddressProofType, 
            handleDocClick, handleEditCommentClick, updateAddressProofTypeIsAadhaar,)}
        <Row className="m-t-50" >
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {docButton("Business Registration Proof", true, kycDetails, handleUploadChange, beforeUpload, 
                'institute_registration_proof', handleUpdateDetails, handleDocClick, handleEditCommentClick)}
                <div className="text-xs m-t-10 dark-grey">Upload the scan of Partnership Deed</div>
            </Col>
            <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                {docButton("Authorized Signatory PAN", true, kycDetails, handleUploadChange, beforeUpload, 
                'pan_card', handleUpdateDetails, handleDocClick, handleEditCommentClick)}
                <div className="text-xs m-t-10 dark-grey">Upload the scan of personal PAN card</div>
            </Col>
        </Row>
    </div>
)

export const documentDetailsNtRegistered = (handleDocClick, kycDetails, handleEditCommentClick, handleUpdateDetails, 
    beforeUpload, handleUploadChange, validateData, changeAddressProofType, updateAddressProofTypeIsAadhaar) => (
    <div style={{width: "100%"}}>
        <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 12, offset: 0 }}>
                {adhaarInput(kycDetails, handleUpdateDetails, validateData)}
            </Col>
            {/* <Col xs={{ span: 24,}} md={{ span: 11, offset: 1 }} style={{width: '100%'}} >
                <div className='text-xs color-black m-b-5 bold-600' >OTP </div>
                <Input onChange={(e) => {}}
                value={''} placeholder="Name of the contact person" className='radius-5 kyc-input-fields' style={{background:Color.lightGrey, border:'none'}}/>
            </Col> */}
        </Row>
        <Row className="m-t-20">
            {aadhaarDocs(kycDetails, handleUpdateDetails, handleUploadChange,
                                    beforeUpload, handleDocClick, handleEditCommentClick)}

        </Row>
        {/* <Row className="m-t-20">
            <Col xs={{ span: 24 }} md={{ span: 24, offset: 0 }}>
                <div> <Checkbox className="m-r-20"/> My aadhaar is not linked to my phone number  </div>    
            </Col>
        </Row> */}
        {addressProofUpload(kycDetails, handleUpdateDetails, handleUploadChange, beforeUpload, changeAddressProofType, 
            handleDocClick, handleEditCommentClick, updateAddressProofTypeIsAadhaar)}
    </div>
)