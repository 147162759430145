import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './BottomSheet.css';
import FormComponent from './formComponent';
import { connect } from 'react-redux';
import { setShowBottomSheet } from 'actions/loginNew';

const BottomSheet = ({showBottomSheet, setShowBottomSheet}) => {
  const variants = {
    open: { y: 0 },
    closed: { y: "100%" },
  };

  return (
    <div>
      {showBottomSheet && <div className="overlay" onClick={() => setShowBottomSheet(false)} />}
      <motion.div
        className="bottom-sheet"
        animate={showBottomSheet ? "open" : "closed"}
        initial="closed"
        variants={variants}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <FormComponent isBottomSheet={true}/>
      </motion.div>
    </div>
  );
};

const mapStateToProps = (state) => {
    const { showBottomSheet } = state.loginNew;
    return {
        showBottomSheet
    }
}

const mapDispatchToProps = (dispatch) => ({
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomSheet);
