import { ArrowLeftOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { addSubjects, deleteCourse, deleteSubject, getSelectedCourseData } from 'actions/course';
import { Col, Divider, Row } from 'antd';
import { Button } from 'components/Button';
import { SquareSubIcon } from 'components/subIcon';
import { singleSubject } from 'pages/classRoom/batchDetails';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import CreateSubjectsModal from './createSubjectsModal';
import FeeDetailsModal from './feeDetailsModal';
import PageDataLoader from "components/pageDataLoader";
import moment from 'moment';
import AddCourseModal from './addCourseModal';
import CourseDeleteConfirmModal from './courseDeleteConfirmModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setFromClassroom } from 'actions/classRoom';

const NoData = require("../../Assets/ill-no-data.svg").default

const bodyStyle = {
    background: "#FAFAFA",
    margin: -20,
    padding: 20,
    overflowY: "scroll",
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
};

const cardStyle = {
    borderRadius: 16, boxShadow: "2px 3px 6px #302a5f14", border: "1px solid #302a5f14"
}

const SingleSubjectCard = ({ item, setCreateSubjectModalVisible, setEditSubjectVisible, setShowEditChapters }) => {
    return (
        <div className="box-sequare__subject" style={{ height: 300, width: 235 }}>
            <div className="r-ac">
                <SquareSubIcon name={item.name} size={24} />
                <div className="m-l-10">
                    <div className='text-xmd dark-black bold-600 text-overflow' style={{ width: 150, textTransform: 'uppercase' }}>{item?.name}</div>
                    <div style={{ color: "#636363", fontSize: 12 }}>
                        {item.courseChapter ? item.courseChapter?.length : 0} Chapters
                    </div>
                </div>
            </div>
            <Divider style={{ margin: "10px 0px" }} />
            <div className="box-sequare__subject-chapter-wrap">
                <div>
                    {item?.courseChapter && item?.courseChapter?.length
                        ? item.courseChapter
                            .filter((chap, i) => i < 6)
                            .map((chapter, chapIndex) => (
                                <div className="r-ac m-b-5">
                                    <div className=" m-r-15" style={{ color: "#636363", fontSize: 13 }}>
                                        Chapter {chapIndex + 1}
                                    </div>
                                    <div className="dark-black bold-600 text-overflow" style={{ fontSize: 13 }}>
                                        {chapter.name}
                                    </div>
                                </div>
                            ))
                        : null}
                </div>
                {item.courseChapter?.length > 4 ? (
                    <div
                        className=" bold-600 text-center blue cursor-pointer"
                        onClick={() => {
                            setEditSubjectVisible(true)
                            setCreateSubjectModalVisible(true);
                            setShowEditChapters(item)
                        }}
                        style={{ fontSize: 13 }}
                    >
                        VIEW ALL
                    </div>
                ) : (
                    <div
                        className="bold-600 text-center blue cursor-pointer"
                        style={{ fontSize: 13 }}
                        onClick={() => {
                            setEditSubjectVisible(true)
                            setCreateSubjectModalVisible(true)
                            setShowEditChapters(item)
                        }}
                    >
                        <PlusCircleOutlined /> ADD CHAPTERS
                    </div>
                )}
            </div>
        </div>
    )
}
const AddSubjectButton = ({ setCreateSubjectModalVisible, history, setEditSubjectVisible }) => (
    <div key={1} className="box-sequare__subject r-c-c-c" style={{ backgroundColor: "#F6F4FE", height: 300, width: 235 }}>
        <div
            style={{ fontWeight: 600, fontSize: 15, color: "#191919" }}
            className="display-flex "
        >
            Add Subjects
        </div>
        <div style={{ fontSize: 14, color: "#636363", textAlign: "center" }}>
            If you like you can browse from our resources or create manually
        </div>
        <Button onClick={() => { setEditSubjectVisible(false); setCreateSubjectModalVisible(true) }} style={{ marginBottom: 15, marginTop: 10 }}>CREATE</Button>
        <Button type="primary" onClick={() => history.push({ pathname: "/classroom/resources", state: { key: "fromCourse" } })}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
    </div>
);

function CourseDetails({ getSelectedCourseData, selectedCourseDataContainer, deleteCourse, addSubjects, deleteSubject, setFromClassroom, isFromClassRoomDetails }) {
    const history = useHistory();
    const [isFeeDetailsModalVisible, setFeeDetailsModalVisible] = useState(false);
    const [isCreateSubjectModalVisible, setCreateSubjectModalVisible] = useState(false);
    const [isEditSubjectVisible, setEditSubjectVisible] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
    const [showEditChapters, setShowEditChapters] = useState([]);
    const [isFromSubjects, setIsFromSubjects] = useState(false);
    const location = useLocation();

    const [pageLoader, togglePageLoader] = useState(false);
    useEffect(() => {
        togglePageLoader(true);
        getSelectedCourseData(() => togglePageLoader(false));
    }, []);

    useEffect(() => {
        setFromClassroom(false)
        if (location?.state?.key === "fromClassroom") {
            setFromClassroom(true)
        } else { }
    }, [])

    return (
        <div style={bodyStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "700", display: "flex", flexDirection: "row", marginTop: "5px" }}>
                    <ArrowLeftOutlined
                        style={{ marginTop: "10px", fontSize: "22px" }}
                        onClick={() => {
                            if (isFromClassRoomDetails) {
                                history.push("/classroom/classroom-details");
                                setFromClassroom(false)
                            } else {
                                history.push("/course");
                            }
                        }} />
                    <div style={{ marginLeft: "15px", fontSize: "22px" }}>{selectedCourseDataContainer?.courseName}</div>
                </div>
                <div className=' cursor-pointer m-t-5' style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold" }} onClick={() => setIsModalVisible(true)}><EditOutlined /> EDIT COURSE</div>
            </div>
            <div className='m-t-20' style={{ ...cardStyle, height: 200, width: "100%", padding: 20, fontFamily: "roboto" }}>
                <div
                    className='m-b-10'
                    style={{
                        color: "#636363",
                        fontSize: 16,
                        height: 130,
                        overflow: "auto",
                        wordWrap: "break-word",
                        overflowY: "scroll",
                    }}
                >
                    {selectedCourseDataContainer?.courseDescription?.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
                <div className='r-c-sb' style={{ color: "#AEAEAE", fontSize: 14 }}>
                    <div>{selectedCourseDataContainer?.duration} {selectedCourseDataContainer?.durationType} • {moment(selectedCourseDataContainer?.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - {moment(selectedCourseDataContainer?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>
                    <div>{Math.round(selectedCourseDataContainer?.progress === undefined ? null : selectedCourseDataContainer?.progress)}% Completed</div>
                </div>
            </div>
            <Row span={24} className='m-t-20'>
                <Col style={{ ...cardStyle, height: 480, width: "68%", padding: 20, fontFamily: "roboto", marginRight: 20, overflowY: "scroll" }}>
                    <div style={{ fontSize: 17, color: "#191919", fontWeight: "bold", fontFamily: "roboto" }}>Curriculum</div>
                    {selectedCourseDataContainer?.subjects?.length != 0 ?
                        <div className="r-ac flex-wrap">
                            {selectedCourseDataContainer?.subjects?.map((item, index) => (
                                <SingleSubjectCard
                                    item={item}
                                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                    setEditSubjectVisible={setEditSubjectVisible}
                                    setShowEditChapters={setShowEditChapters} />
                            )
                            )}
                            <AddSubjectButton
                                setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                                history={history}
                                setEditSubjectVisible={setEditSubjectVisible} />
                        </div>
                        :
                        <div className='r-c-c' style={{ margin: "30px 180px 0px 180px", flexDirection: "column" }}>
                            <img src={NoData} alt='' />
                            <div className='m-t-5' style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>Add subjects</div>
                            <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>If you like you can browse from our resources or create manually</center>
                            <div className='r-c-se m-t-20' style={{ width: "100%" }}>
                                <Button onClick={() => { setCreateSubjectModalVisible(true); setEditSubjectVisible(false) }}>CREATE</Button>
                                <Button type="primary" onClick={() => history.push({ pathname: "/classroom/resources", state: { key: "fromCourse" } })}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
                            </div>
                        </div>
                    }
                </Col>
                <Col style={{ ...cardStyle, height: 480, padding: 20, overflowY: "scroll", width: "30%" }}>
                    <div className='r-c-sb'>
                        <div style={{ fontSize: 17, color: "#191919", fontWeight: "bold", fontFamily: "roboto" }}>Fee details</div>
                        {selectedCourseDataContainer?.installmentDetails ?
                            JSON.parse(selectedCourseDataContainer?.installmentDetails)?.length === 0 ?
                                <></>
                                :
                                <div className=' cursor-pointer' style={{ color: "#1089FF", fontSize: 15, fontWeight: "bold" }} onClick={() => setFeeDetailsModalVisible(true)}><EditOutlined /> EDIT FEE DETAILS</div>
                            :
                            <></>
                        }
                    </div>
                    {
                        selectedCourseDataContainer?.installmentDetails ?
                            JSON.parse(selectedCourseDataContainer?.installmentDetails)?.length === 0 ?
                                <div className='r-c-c m-t-50' style={{ flexDirection: "column" }}>
                                    <img src={NoData} alt='' />
                                    <div className='m-t-5' style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "roboto", marginBottom: "2px" }}>No data available!</div>
                                    <center style={{ fontSize: "14px", color: "#636363", fontFamily: "roboto" }}>There is no data to show you right now</center>
                                    <Button type="primary" style={{ marginTop: 20 }} onClick={() => setFeeDetailsModalVisible(true)}><PlusCircleOutlined style={{ marginRight: 5 }} />ADD</Button>
                                </div>
                                :
                                <>
                                    <div className='m-t-20 display-flex'>
                                        <div style={{ width: 130 }}>
                                            <div style={{ fontSize: 13, color: "#636363", fontFamily: "roboto" }}>Total fee amount</div>
                                            <div style={{ fontSize: 15, color: "#191919", fontFamily: "roboto" }}>₹ {selectedCourseDataContainer?.amount}</div>
                                        </div>
                                        <div>
                                            <div style={{ fontSize: 13, color: "#636363", fontFamily: "roboto" }}>Fee payment plan</div>
                                            <div style={{ fontSize: 15, color: "#191919", fontFamily: "roboto" }}>{selectedCourseDataContainer?.billingPlan}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap", }}>
                                        {JSON.parse(selectedCourseDataContainer?.installmentDetails)?.map((item, index) => (
                                            <div className='m-t-20' style={{ width: 130 }}>
                                                <div style={{ fontSize: 13, color: "#636363", fontFamily: "roboto" }}>Installment {index + 1} </div>
                                                <div style={{ fontSize: 15, color: "#191919", fontFamily: "roboto" }}>₹ {item?.installment}</div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            :
                            <></>
                    }
                </Col>
            </Row>
            {isModalVisible && (
                <AddCourseModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    isEditModalVisible={true}
                    togglePageLoader={togglePageLoader}
                    setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                    setIsFromSubjects={setIsFromSubjects}
                />
            )}
            {isFeeDetailsModalVisible &&
                <FeeDetailsModal
                    isFeeDetailsModalVisible={isFeeDetailsModalVisible}
                    setFeeDetailsModalVisible={setFeeDetailsModalVisible}
                    togglePageLoader={togglePageLoader}
                    getSelectedCourseData={getSelectedCourseData}
                />
            }
            {isCreateSubjectModalVisible &&
                <CreateSubjectsModal
                    isCreateSubjectModalVisible={isCreateSubjectModalVisible}
                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                    addSubjects={addSubjects}
                    togglePageLoader={togglePageLoader}
                    getSelectedCourseData={getSelectedCourseData}
                    isEditSubjectVisible={isEditSubjectVisible}
                    showEditChapters={showEditChapters}
                    setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                    setIsFromSubjects={setIsFromSubjects}
                    fromClassroom={false}
                />
            }
            {isConfirmDeleteModalVisible &&
                <CourseDeleteConfirmModal
                    isConfirmDeleteModalVisible={isConfirmDeleteModalVisible}
                    setConfirmDeleteModalVisible={setConfirmDeleteModalVisible}
                    deleteCourse={deleteCourse}
                    togglePageLoader={togglePageLoader}
                    isFromSubjects={isFromSubjects}
                    showEditChapters={showEditChapters}
                    deleteSubject={deleteSubject}
                    getSelectedCourseData={getSelectedCourseData}
                    setIsModalVisible={setIsModalVisible}
                    setCreateSubjectModalVisible={setCreateSubjectModalVisible}
                    backToClassroom = {location?.state?.key === "fromClassroom" ? true : false} />
            }
            <PageDataLoader visible={pageLoader} />
        </div>
    )
}
const mapStateToProps = (state) => {
    const { selectedCourseDataContainer } = state.course;
    const { isFromClassRoomDetails } = state.classRoom;
    return {
        selectedCourseDataContainer, isFromClassRoomDetails
    };
};
const mapDispatchToProps = (dispatch) => ({
    getSelectedCourseData: (callback) => dispatch(getSelectedCourseData(callback)),
    deleteCourse: (callback, successCallBack) => dispatch(deleteCourse(callback, successCallBack)),
    addSubjects: (subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack) => dispatch(addSubjects(subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack)),
    deleteSubject: (subjectId, callback, successCallBack) => dispatch(deleteSubject(subjectId, callback, successCallBack)),
    setFromClassroom: (val) => dispatch(setFromClassroom(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);

// export default CourseDetails