import {
  fetchStatusWiseTest,
  UpdateStudentTestData,
} from "actions/studentTest";
import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";

function Buttons({ UpdateStudentTestData, studentTestData, fetchStatusWiseTest, selectedStandards }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "350px",
      }}
    >
      <Button
        onClick={() => {
          UpdateStudentTestData("type", null);
          fetchStatusWiseTest(selectedStandards, () => { })
        }}
        style={{
          borderRadius: "20px",
          fontWeight: "500",
          fontSize: "11px",
          width: "80px",
          backgroundColor: studentTestData?.type == null ? "#594099" : "white",
          color: studentTestData?.type == null ? "white" : "black",
          //   padding: "5px",
        }}
      >
        ALL
      </Button>
      <Button
        onClick={() => {
          UpdateStudentTestData("type", "Subjective");
          fetchStatusWiseTest(selectedStandards, () => { })
        }}
        style={{
          borderRadius: "20px",
          fontWeight: "500",
          fontSize: "11px",
          padding: "5px",
          width: "120px",
          backgroundColor:
            studentTestData?.type == "Subjective" ? "#594099" : "white",
          color: studentTestData?.type == "Subjective" ? "white" : "black",
        }}
      >
        SUBJECTIVE
      </Button>
      <Button
        onClick={() => {
          UpdateStudentTestData("type", "Objective");
          fetchStatusWiseTest(selectedStandards, () => { })
        }}
        style={{
          borderRadius: "20px",
          fontWeight: "500",
          fontSize: "11px",
          padding: "5px",
          width: "120px",
          backgroundColor:
            studentTestData?.type == "Objective" ? "#594099" : "white",
          color: studentTestData?.type == "Objective" ? "white" : "black",
        }}
      >
        OBJECTIVE
      </Button>
    </div>
  );
}

// export default Buttons;
const mapStateToProps = (state) => {
  const { studentTestData } = state.studentTest;
  return {
    studentTestData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  UpdateStudentTestData: (key, val) =>
    dispatch(UpdateStudentTestData(key, val)),
  fetchStatusWiseTest: (standardId, callback) => dispatch(fetchStatusWiseTest(standardId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
