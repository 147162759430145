import React, { useEffect, useState } from "react";
import "../style.css";
import { Modal, Button, Input, DatePicker, Space, Checkbox } from "antd";
import { connect } from "react-redux";
import { editLeave, storeEditLeaveModalData } from "actions/attendanceNew";
import moment from "moment";
import { RiSurroundSoundLine } from "react-icons/ri";

const EditModal = ({
  isEditModalVisible,
  setIsEditModalVisible,
  editLeaveObj,
  storeEditLeaveModalData,
  editLeave,
  createLoader,
  setAllLeaves }) => {
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [isCarryForward, setIsCarryForward] = useState(false)
  const [isHalfDayApplicable, setIsHalfDayApplicable] = useState(false)
  // const [backDays, SetBackDays] = useState(false)
  const [isTotalBackDays, setIsTotalBackDays] = useState(null)

  useEffect(() => {
    console.log("abcd", editLeaveObj)
    storeEditLeaveModalData(null)
    storeEditLeaveModalData('startDate', editLeaveObj?.startDate)
    storeEditLeaveModalData('endDate', editLeaveObj?.endDate)
    setIsCarryForward(editLeaveObj?.carryForward)
    setIsHalfDayApplicable(editLeaveObj?.halfDayApplicable)
    setIsTotalBackDays(editLeaveObj?.totalBackDays)
  }, [])

  console.log("abcd", editLeaveObj?.startDate, editLeaveObj?.endDate, editLeaveObj?.leaveName)

  // function disabledDate(current) {
  //   return current < moment().startOf('day');
  // }

  function disabledEndDate(current) {
    return current < moment(editLeaveObj.startDate);
  }

  const handleCancel = () => {
    setIsEditModalVisible(false);

  };

  const handleEdit = (e) => {
    setIsEditModalVisible(false);
    // editLeave()
  };

  return (
    <>
      <div>
        <Modal
          centered
          className="modal-round-corner"
          visible={isEditModalVisible}
          onCancel={handleCancel}
          onOk={handleEdit}
          footer={null}
        >
          <div
            style={{
              marginTop: "20px",
              fontSize: "22px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Leave Type
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Leave name
              </h6>
              <Input
                readOnly
                value={editLeaveObj?.leaveName}
                onChange={(e) => storeEditLeaveModalData('leaveName', e.target.value)}
                style={{
                  fontSize: "16px",
                  color: "#191919",
                }}
                placeholder="Enter leave name"
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Number of days
              </h6>
              <Input
              readOnly
                value={editLeaveObj?.noOfDays}
                onChange={(e) => { storeEditLeaveModalData('noOfDays', e.target.value); console.log("abcd", editLeaveObj?.startDate, editLeaveObj?.endDate) }}
                placeholder="Enter number of days"
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                Start Date
              </h6>
              <Space>
                {console.log("editLeaveObj", editLeaveObj)}

                <DatePicker
                disabled={true}
                  allowClear={false}
                  // disabledDate={disabledDate}
                  value={editLeaveObj.startDate === '' || editLeaveObj.startDate === '0000-00-00' ? '' : moment(editLeaveObj?.startDate)}
                  onChange={(e) => {
                    setLeaveStartDate(e.format('YYYY-MM-DD'))
                    storeEditLeaveModalData('startDate', e.format('YYYY-MM-DD'))
                  }}
                  style={{
                    fontSize: "16px",
                    color: "#AEAEAE",
                    width: "220px",
                  }}
                  placeholder="Select start date"
                />
              </Space>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h6
                style={{
                  fontSize: "14px",
                  color: "#636363",
                  fontWeight: "bold",
                }}
              >
                End Date
              </h6>
              <Space>
                <DatePicker
                disable={true}
                  disabledDate={disabledEndDate}
                  allowClear={false}
                  value={editLeaveObj.endDate === '' ? '' : moment(editLeaveObj.endDate)}
                  onChange={(e) => storeEditLeaveModalData('endDate', e.format('YYYY-MM-DD'))}
                  disabled={leaveStartDate === "" ? true : false}
                  style={{
                    fontSize: "16px",
                    color: "#AEAEAE",
                    width: "220px",
                  }}
                  placeholder="Select end date"
                />
              </Space>
            </div>
          </div>



          {console.log("XYZ", editLeaveObj?.carryForward, editLeaveObj?.halfDayApplicable,)}
          {console.log("XYZ", isCarryForward, isHalfDayApplicable,)}

          <div style={{
            fontSize: "14px",
            color: "#636363",
            fontWeight: "bold",
            marginTop: "20px"
          }}>Properties
          </div>
          <div style={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <Checkbox
              disabled={true}
                checked={isCarryForward}
                onChange={(e) => {
                  setIsCarryForward(e.target.checked);
                  storeEditLeaveModalData('carryForward', e.target.checked)
                }} />
              <div style={{ marginLeft: "15px", color: "#191919", font: "normal normal normal 14px/21px Roboto" }}>
                This leave type can be carried forward in the next academic year.</div>
            </div>
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Checkbox
               disabled={true}
                checked={isHalfDayApplicable}
                onChange={(e) => {
                  setIsHalfDayApplicable(e.target.checked);
                  storeEditLeaveModalData('halfDayApplicable', e.target.checked)
                }} />
              <div style={{ marginLeft: "15px", color: "#191919", font: "normal normal normal 14px/21px Roboto" }}>
                Half day leave can be applied for this leave type.</div>
            </div>
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Checkbox
               disabled={true}
               checked={isTotalBackDays ? true : false}
                onChange={(e) => {}} />
              <div style={{ marginLeft: "15px", color: "#191919", font: "normal normal normal 14px/21px Roboto" }}>
                Allow staffs to apply for back date(s).</div>
            </div>
            {isTotalBackDays ? 
            <div className="m-l-30">
              <div style={{color: '#636363', fontSize: 12, fontWeight: 600, marginTop: 5}}>Number of back date(s) is <span style={{fontWeight: 700}}> {isTotalBackDays} days(s)</span></div>
              {/* <Input disabled value={`${isTotalBackDays}`} style={{width: '50%'}} suffix={"day(s)"}/> */}
            </div>
            : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "50px",
            }}
          >
            <div>
              <Button
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #636363 ",
                  color: "#636363",
                }}
                onClick={handleCancel}
              >
                CLOSE
              </Button>
            </div>
            {/* <div>
              <Button
              disabled={true}
                loading={createLoader}
                disable={(editLeaveObj?.noOfDays === '') ? true : false}
                style={{
                  borderRadius: "19px",
                  width: "150px",
                  height: "40px",
                  fontWeight: "500",
                  backgroundColor: "#594099",
                  color: "#FFFFFF",
                  opacity: (editLeaveObj?.noOfDays === '') ? 0.5 : 1
                }}
                onClick={() => {
                  editLeave(
                    () => {
                      handleEdit()
                    }
                  )

                }}
              >
                SAVE
              </Button>
            </div> */}
          </div>
        </Modal>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { editLeaveObj, createLoader, setAllLeaves } = state.attendanceNew;
  return { editLeaveObj, createLoader, setAllLeaves };
};

const mapDispatchToProps = (dispatch) => ({
  editLeave: (callBack) => dispatch(editLeave(callBack)),
  storeEditLeaveModalData: (key, value) => dispatch(storeEditLeaveModalData(key, value)),
  setCreateLoader: (val) => dispatch(setCreateLoader(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);


