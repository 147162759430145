import React from 'react'

const OverViewCard = ({ rank, label, noOfStudent, color, icon }) => {

    return (
        <div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <div>
                    <div style={{ fontSize: "20px", fontWeight: "bold" }}>{rank != undefined ? rank : 0}</div>
                    <div style={{ fontSize: "12px", fontWeight: 500, color: "#636363" }}>{label}</div>
                    {noOfStudent != null ?
                        <div style={{ display: "flex" }}>
                            <div><img src={icon} /></div>
                            <div style={{ color: `${color}`, marginLeft: "10px", fontSize: "12px", fontWeight: 500, marginTop: "5px" }}>{noOfStudent}</div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}
export default OverViewCard