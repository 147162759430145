import React, { useEffect, useState } from "react";
import { DatePicker, Space } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { fetchTimetableData } from "actions/schedule";
import PageDataLoader from "components/pageDataLoader";

function Date_picker({
  id,
  value,
  setDate,
  fetchTimetableData
}) {
  const[pageLoader,setPageLoader]=useState(false);
  function onChange(date, dateString) {
    // console.log("start date", dateString);
    if (
      moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setDate(moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"));
      setPageLoader(true);
      fetchTimetableData(id,()=>{setPageLoader(false)},moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD"))
    } else {
    }

  }
  function disabledDate(current) {
    // Can not select days before today and today
    // if (installments?.length) {
    //   return current > installments[index - 1] ? true : false;
    // console.log(
    //   "datepic",
    //   !(current && current > moment()),
    //   moment() < current,
    //   current < moment().add(10, "days")
    //   // current
    // );
    // }
    // return !(current && current > moment());
  }

  return (
    <Space direction="vertical" style={{ width: "90%" }} size={50}>
      <DatePicker
        allowClear={false}
        className="datePicker"
        onChange={onChange}
        value={
          value !== null
            ? moment(
                moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                "DD-MM-YYYY"
              )
            : ""
        }
        placeholder={["Select a date"]}
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
      />
      <PageDataLoader visible={pageLoader}/>
    </Space>
  );
}

const mapStateToProps = (state) => {
  const {} = state.course;
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  // setInstallments: (val) => dispatch(setInstallments(val)),
  fetchTimetableData:(id,callback,date)=>dispatch(fetchTimetableData(id,callback,date))
});

export default connect(mapStateToProps, mapDispatchToProps)(Date_picker);
