import { CaretDownOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal, Typography } from "antd";
import React from "react";
import { MdEdit, MdKeyboardBackspace } from "react-icons/md";
import { Button } from "./Button";
import "./styles.css";
import { PgHeader } from "./Typography";
import { Color } from "services";

const EasySwitchDropdown = ({ easySwitch }) => (
	<Dropdown
		overlay={
			<Menu className="boxShadow118 p-5">
				{easySwitch.map((item, index) => (
					<Menu.Item key={index}>
						<div
							style={{
								color: "#636363",
								fontSize: "13px",
								textAlign: "left",
								fontWeight: 500,
							}}
							onClick={item.action}
						>
							{item.label}
						</div>
					</Menu.Item>
				))}
			</Menu>
		}
		style={{ width: "50%" }}
	>
		<CaretDownOutlined />
	</Dropdown>
);

const EditModal = ({ ModalBody, visible }) => {
	return (
		<div>
			<MdEdit style={{ fontSize: 20 }} />

			<Modal
				closable
				centered
				className="modal-round-corner"
				open={visible}
				footer={null}
			>
				{ModalBody}
			</Modal>
		</div>
	);
};

const bodyStyle = {
	paddingRight: 20,
	position: "fixed",
	top: 0,
	backgroundColor: "#FAFAFA",
	// borderBottom: '1px solid #E6E6E6',
	zIndex: 10,
	padding: 10,
};

const PageHeader = ({
	title,
	onBackPress,
	easySwitch,
	actions,
	subtitle,
	edit,
	ModalBody,
	ModalVisible,
	modalOnClick,
	givenStyle,
	editIcon,
	editOnClick,
	maxWidth,
	path
}) => {

	return (
		<div style={{ ...(path !== "courseHome" ? bodyStyle : {}), ...givenStyle }} className={path !== "courseHome" ? "headerStyle" : ""}>
		<div className="r-jsb">
				<div className="flex-1 display-flex">
					{onBackPress ? (
						<MdKeyboardBackspace
							style={{ marginLeft: onBackPress ? 30 : 20 }}
							className="text-mdl m-t-10 cursor-pointer"
							label="Back"
							onClick={() => onBackPress()}
						/>
					) : null}
					<div
						className=""
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "90%",
							marginLeft: path !== "courseHome" ? 22 : 0
						}}
					>
						<div style={{ display: "flex" }}>
							<div>
								<div className="display-flex">
								<Typography.Text ellipsis={{tooltip:true}} style={{  fontSize: "1.75rem",fontFamily: "Roboto",fontWeight: 700, color: Color.black, width: maxWidth || 'fit-content'  }}>
										{title}
									</Typography.Text>
									{easySwitch ? (
										<div style={{ margin: "15px 0 0 10px" }}>
											<EasySwitchDropdown easySwitch={easySwitch} />
										</div>
									) : null}
									{edit ? (
										<div
											style={{ marginLeft: 20, marginTop: 10, cursor: "pointer" }}
											onClick={() => {
												modalOnClick();
											}}
										>
											<EditModal ModalBody={ModalBody} visible={ModalVisible} />
										</div>
									) : null}
									{editIcon ? (
										<div style={{ marginLeft: 20, marginTop: 10, cursor: "pointer" }}>
											<Button onClick={() => { editOnClick() }} type='transparent' style={{ padding: 0, border: 'none' }} icon={<EditOutlined style={{ fontSize: "14px" }} />} ></Button>
										</div>
									) : null}
								</div>

								{subtitle ? (
									<Typography.Text
										ellipsis={{ tooltip: true }}
										style={{
											fontSize: "1rem",
											fontWeight: 600,
											marginLeft: onBackPress ? 10 : 30,
											color: "#636363",
											width: 400
										}}
									>
										{" "}
										{subtitle}{" "}
									</Typography.Text>
								) : null}
							</div>
						</div>
					</div>
				</div>
				<div className="flex-1 r-c-fe">
					<div className="display-flex">
						{actions?.map((action, index) => (
							<>{action}</>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageHeader;