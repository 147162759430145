import { Api } from '../services';


export const USER_MODAL = 'USER_MODAL';
export const STUDENT_MODAL = 'STUDENT_MODAL';
export const UPDATE_USERS = 'UPDATE_USERS';
export const TOGGLE_USERS_LOADER = 'TOGGLE_USERS_LOADER';
export const TERMINATE_USERS_MODAL = 'TERMINATE_USERS_MODAL';
export const UPDATE_USER_INPUT = 'UPDATE_USER_INPUT';
export const UPDATE_ALL_USER_INPUT = 'UPDATE_ALL_USER_INPUT';
export const TOGGLE_SEARCH_LOADER = 'TOGGLE_SEARCH_LOADER';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';
export const RESET_FILTER_PARAMS = 'RESET_FILTER_PARAMS';
export const UPDATE_PENDING_USER_LOADER = 'UPDATE_PENDING_USER_LOADER';
export const UPDATE_PENDING_USERS = 'UPDATE_PENDING_USERS';
export const TOGGLE_USER_DETAIL_MODAL = 'TOGGLE_USER_DETAIL_MODAL';
export const CHANGE_STATUS_LOADER = 'CHANGE_STATUS_LOADER';
export const TOGGLE_PAYMENT_MODAL = 'TOGGLE_PAYMENT_MODAL';
export const UPDATE_POST_LINK_PENDING_USER_PARAMS = 'UPDATE_POST_LINK_PENDING_USER_PARAMS';
export const CLEAR_POST_LINK_PENDING_USER_PARAMS = 'CLEAR_POST_LINK_PENDING_USER_PARAMS';
export const TOGGLE_MULTIPLE_PENDING_USER_LINK_MODAL = 'TOGGLE_MULTIPLE_PENDING_USER_LINK_MODAL';
export const UPDATE_PENDING_USER_FILTER_PARAMS = 'UPDATE_PENDING_USER_FILTER_PARAMS';
export const CLEAR_PENDING_USER_FILTER_PARAMS = 'CLEAR_PENDING_USER_FILTER_PARAMS';
export const TOGGLE_PERMANENT_ADDRESS_CHECKBOX = 'TOGGLE_PERMANENT_ADDRESS_CHECKBOX';
export const UPDATE_AUTO_APPROVAL_LOADER = 'UPDATE_AUTO_APPROVAL_LOADER';
export const UPDATE_AUTO_APPROVAL = 'UPDATE_AUTO_APPROVAL';


const updateAutoApprovalSettingLoader = bool => ({
  type: UPDATE_AUTO_APPROVAL_LOADER,
  bool
})

const updateAutoApproval = bool => ({
  type: UPDATE_AUTO_APPROVAL,
  bool
})

export const updateAutoApprovalSetting = bool => {
  return (dispatch) => {
    dispatch(updateAutoApprovalSettingLoader(true))
    const url = `/organisation/settings`
    return Api.patch(url).params({studentAutoApprove: bool}).send((response, error) => {
      if(response && response.show?.type === "success"){
        dispatch(updateAutoApproval(bool))
      }
      dispatch(updateAutoApprovalSettingLoader(false))
    });
  }
}

export const togglePermanentAddressCheckbox = bool => ({
  type: TOGGLE_PERMANENT_ADDRESS_CHECKBOX,
  bool
})

export const clearPendingUserFilterParams = () => ({
  type: CLEAR_PENDING_USER_FILTER_PARAMS,
})

export const updatePendingUserFilterParams = (val, key) => ({
  type: UPDATE_PENDING_USER_FILTER_PARAMS,
  filterParams: {[key]: val}
})
// const toggleMultipleLinkLoader = bool => ({
//   type: TOGGLE_MULTIPLE_LINK_LOADER,
//   bool
// })

export const sendLinkMultipleUser = () => {
  // return (dispatch, getState) => {
  //     const {postLinkParams, selectedIds} = getState().fees;
  //     const params = {
  //         userIds: selectedIds,
  //         ...postLinkParams
  //     }
  //     const url = `/fee`;
  //     dispatch(toggleMultipleLinkLoader(true));
  //     return new Promise((resolve, reject) => {
  //         Api.post(url)
  //         .params(params)
  //         .send(async (response, error) => {
  //             dispatch(toggleMultipleLinkLoader(false));
  //             dispatch(toggleMultipleUserLinkModal(false));
  //             dispatch(fetchStudentList());
  //         }
  //         )
  //     })
  // }
}

export const toggleMultipleUserLinkModal = bool => ({
  type: TOGGLE_MULTIPLE_PENDING_USER_LINK_MODAL,
  bool
})

export const clearPostLinkParams = () => ({
  type: CLEAR_POST_LINK_PENDING_USER_PARAMS,
})

export const updatePostLinkParams = (val, key) => ({
  type: UPDATE_POST_LINK_PENDING_USER_PARAMS,
  postLinkParams: {[key]: val}
})

export const togglePaymentModal = (value, data) => ({
  type: TOGGLE_PAYMENT_MODAL,
  value,
  data,
});

const updateChangeStatusLoader = bool => ({
  type: CHANGE_STATUS_LOADER,
  bool
})

export const updateUserStatus = (id, status) => {
  return (dispatch) => {
    dispatch(updateChangeStatusLoader(true))
    const url = `/user/status/${id}`
    return Api.patch(url).params({status}).send((response, error) => {
      if(response && response.show?.type === "success"){
        dispatch(toggleDetailModal(false, {}));
        dispatch(getNewRegistrations())
      }
      dispatch(updateChangeStatusLoader(false))
    });
  }
}

export const toggleDetailModal = (value, data) => ({
  type: TOGGLE_USER_DETAIL_MODAL,
  value,
  data,
});

const updatePendingUsers = (data) => ({
  type: UPDATE_PENDING_USERS,
  data
})

const updatePendingUserLoader = bool => ({
  type: UPDATE_PENDING_USER_LOADER,
  bool
})

export const getNewRegistrations = () => {
  return (dispatch, getState) => {
    const {filterPendingUserParams} = getState().user;
    dispatch(updatePendingUserLoader(true))
    const url = `/user/pending-list`
    return Api.get(url).params(filterPendingUserParams).send((response, error) => {
      if(typeof response != "undefined"){
          dispatch(updatePendingUsers(response))
      }
      dispatch(updatePendingUserLoader(false))
    });
  }
}

export const userModalVisible = (singleUser, userState) => ({
  type: USER_MODAL,
  singleUser,
  userState,
});
export const studentModalVisible = (singleStudent, studentState) => ({
  type: STUDENT_MODAL,
  singleStudent,
  studentState
});

export const updateUserInput = (value, key) => ({
  type: UPDATE_USER_INPUT,
  StudentSingle: {[key]: value}
});

export const updateAllUserInput = (value, key) => ({
    type: UPDATE_ALL_USER_INPUT,
    userSingle: {[key]: value}
  });

export const updateUserBasicInput = (value, key) => ({
    type: UPDATE_USER_INPUT,
    StudentSingle: {basicprofile : {[key]: value}}
  });

export const editUsers = (input,positionCurrent, standardId = -1) => {
  return (dispatch) => {
    const url = `/user`
    // return Api.patch(url).send((response, error) => {
    //   dispatch(getUsers(input.role));
    // });
    dispatch(toggleUsersLoader(true));
    if(input.data.phone === input.data.oldPhone){
      input.data.oldPhone = null;
    }
    const params = input.data;
    Object.assign(params, { image: input.file}); 
    return Api.uploadAndPost(url, params, (percent, response)=> { //post(url).params(params)
      if(typeof response != "undefined" && (response.data.show && response.data.show.type !== 'error')){
        dispatch(terminateUsersModal());
        standardId = standardId === -1 ? "-1" : standardId;
        dispatch(setFilterParams(standardId, "stdId"))
        dispatch(getUsers(1));
        if(percent === 100){
             dispatch(toggleUsersLoader(false));   
          }
      }
      if(percent === -1){
         dispatch(toggleUsersLoader(false));   
      }
    });
  }
  }


  export const fetchRollNo = (value) => {
    return (dispatch) => {
      const url = `/user/${value}/rollNo`
      return Api.get(url).send((response, error) => {
        if(typeof response != "undefined"){
            dispatch(updateUserInput(response, 'rollNo'))
        }
        // dispatch(getUsers(position));
      });
    }
  };

export const deleteUsers = (input,position) => {
  return (dispatch, getState) => {
    const page = getState().user.filterParams.page;
    const url = `/user/${input.id}`
    return Api.delete(url).send((response, error) => {
      dispatch(getUsers(page));
    });
  }
};

export const getUsers = (page = 1) => {  //role, search = null, page = 1
  return (dispatch, getState) => {
    // let url = `/user/?role=${role}&page=${page}`;
    const url = "/user";
    // url = search == null ? url: `${url}&search=${search}`;
    let params = getState().user.filterParams;
    if(!params){
      params = {
        role: "Teacher",
        page: 1,
        search: null,
        stdId: -1
      }
    }
    params.page = page;
    const {role} = params;

    dispatch(toggleSearchLoader(true));
    return Api.get(url).params(params).send((response, error) => {
      dispatch(toggleSearchLoader(false));
      if(typeof response !== "undefined"){
        const list = response.users;
        //setPage
       list.map(user => {
          let id = user.id;
          if(role === 'Student'){
              Object.assign(user, user.student);
              if(user.student){
                Object.assign(user, user.student.guardian);
                Object.assign(user, user.student.standard);
                user.standardId = user.student.standard ? user.student.standard.id : "-1"

              }else{
                Object.assign(user, {guardian: {}});
                Object.assign(user, {standard: {}});
                user.standardId = -1
              }

              // Object.assign(user.standardId, user.student.standard.id || -1);
            }
            Object.assign(user, user.basicprofile);
            user.id = id;
            return 1
        })
      Object.assign(response, { role });
        dispatch(updateUsers(response));
        dispatch(setFilterParams(response.pageMeta.currentPage, 'page'));
      }
    });
  }
};



export const updateUsers = (users) => ({
  type: UPDATE_USERS,
  users
});


export const saveUser = (newUser, positionCurrent) => {
  return (dispatch) => {
    dispatch(toggleUsersLoader(true));
    const params = newUser.data;
    Object.assign(params, { image: newUser.file});
    return Api.uploadAndPost('/user', params, (percent, response)=> { //post('/user').params(params).
      if(typeof response != "undefined" && (response.data.show && response.data.show.type !== 'error')){
        dispatch(getUsers(1))
        // positionCurrent != "Student" ?
        // dispatch(getUsers(positionCurrent)) :
        // dispatch(getStudents(params.standardId,1));
        dispatch(terminateUsersModal());
        if(percent === 100){
            console.log('response image---___', percent);
            dispatch(toggleUsersLoader(false));
          }
      }
      if(percent === -1){
        dispatch(toggleUsersLoader(false));
      }
    });
  }
};
// export const usersUploadImage = (id, params) => {
//   return (dispatch) => {
//     dispatch(toggleUsersLoader(true));
//     console.log('inside imageee', params);
//     return Api.post(`/user/image/${id}`).upload(params, (percent, response) => {
//       dispatch(toggleUsersLoader(false));
//       if(percent === 100){
//         console.log('response image---___', percent);
//       }
//     });
//   }
// }

export const toggleUsersLoader = value => ({
  type: TOGGLE_USERS_LOADER,
  value,
});
export const terminateUsersModal = () => ({
  type: TERMINATE_USERS_MODAL,
});

export const toggleSearchLoader = value => ({
  type: TOGGLE_SEARCH_LOADER,
  value
});

export const getStudents = (stdId=-1, page=1) => {

  return (dispatch) => {
    let url = `/user/?role=Student&stdId=${stdId}&page=${page}`;
    dispatch(toggleSearchLoader(true));

    return Api.get(url).send((response, error) => {
      dispatch(toggleSearchLoader(false));
      if(typeof response !== "undefined"){
        const list = response.users;
        list.map(user => {
          let id = user.id;
            Object.assign(user, user.student);
            Object.assign(user, user.student.guardian);
            Object.assign(user, user.student.standard);
            Object.assign(user, user.basicprofile);
            user.id = id;
            return 1;
        })
      Object.assign(response, { role: "Student" });
        dispatch(updateUsers(response));
      }
    });
  }
};


export const setFilterParams = (value, key) => ({
  type: SET_FILTER_PARAMS,
  filterParams: {[key]: value}
})

export const resetFilterParams = () => ({
  type: RESET_FILTER_PARAMS,
})
