import { Alert, Col, Row, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Status_Cards from './statusCards'
import AdminSummary from './graphPart'
import lecturenotes from "../../../Assets/digitalLibrary/ic-dl-lecture-notes.svg"
import videoImg from "../../../Assets/digitalLibrary/ic-dl-lecture-videos.svg"
import questBankImg from "../../../Assets/digitalLibrary/ic-dl-lecture-question-banks.svg"
import LibraryCardDetails from '../components/LibraryCards_details'
import StoragePieCard from '../components/storagePieCard'
import { ExclamationCircleOutlined, SettingFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Axios from 'axios'
import WarningModal from '../modals/warningModal'
import moment from 'moment'
import { updateTotalUnusedStorageSize } from 'actions/digitalLibrary'
import { Button } from 'components/Button'
import { setSubscriptionSelectedTab } from 'actions/profileNew'

const formatFileSize = (sizeInBytes) => {
    if (!sizeInBytes || isNaN(sizeInBytes)) {
        return '0 KB';
    }
    const sizeInMB = sizeInBytes / (1024 * 1024);
    const sizeInGB = sizeInMB / 1024;

    if (sizeInGB >= 1) {
        return parseFloat(sizeInGB.toFixed(2)) + ' GB';
    } else if (sizeInMB >= 1) {
        return parseFloat(sizeInMB.toFixed(2)) + ' MB';
    } else {
        const sizeInKB = sizeInBytes / 1024;
        return parseFloat(sizeInKB.toFixed(2)) + ' KB';
    }
};

function AdminDashBoardCard({ totalCountList, togglePageLoader, sizeOfContents, totalStorageSize, user, updateTotalUnusedStorageSize, totalUnusedStorageSize, currentSubscription, pageLoader, setSubscriptionsSelectedTab }) {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);

    const handleStorage = () => {
        if (user?.access?.includes('subscription-settings')) {
            history.push('/digital-library/buy-storage')
        }
        else {
            setModalVisible(true);
        }
    }

    const handleSetting = () => {
        history.push({
            pathname: '/subscriptions',

        })
        setSubscriptionsSelectedTab("Storage")
    }

    const usedData = sizeOfContents?.sizeOfTotal || 0
    const storageLeft = sizeOfContents?.spaceAvailable || 0;

    // useEffect(() => {
    //     let temp = totalStorageSize * 1024 * 1024 * 1024 - parseFloat(sizeOfContents?.sizeOfLn) - parseFloat(sizeOfContents?.sizeOfMp4Video) - parseFloat(sizeOfContents?.sizeOfQb);
    //     updateTotalUnusedStorageSize(temp)
    // }, [totalStorageSize, sizeOfContents?.sizeOfLn, sizeOfContents?.sizeOfMp4Video, sizeOfContents?.sizeOfQb])

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ marginTop: 30 }}>
                {!(user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) ?

                    <Row>

                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Notes"} filesNO={totalCountList?.toalNotesCount ? totalCountList?.toalNotesCount : 0} lastWeek={totalCountList?.lastWeekLectureNotesCount} images={lecturenotes} index={0} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Videos"} filesNO={totalCountList?.totalVideoCount ? totalCountList?.totalVideoCount : 0} lastWeek={totalCountList?.lastWeekVideoCount} images={videoImg} index={1} />
                            </div>

                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <div className="m-r-10 m-t-10">
                                <LibraryCardDetails title={"Test papers"} filesNO={totalCountList?.totalQuesBankCount ? totalCountList?.totalQuesBankCount : 0} lastWeek={totalCountList?.lastWeekQuestionBankCount} images={questBankImg} index={2} />
                            </div>

                        </Col>
                    </Row> :
                    <Row>
                        <Col md={{ span: 24 }} lg={{ span: 15 }}>
                            <div style={{ width: '100%', padding: "20px", borderRadius: '12px', boxShadow: '1px 5px 8px 2px #302A5F14', marginLeft: '10px', height: "175px" }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                    <div className='text-heading-library'>
                                        Overall stats
                                    </div>
                                    <div style={{ width: '98%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="display-flex-inlibrarycard">
                                                    <div className="image">
                                                        <img className="notes-image" src={lecturenotes} />
                                                    </div>
                                                    <div className="both">
                                                        <p className="nofiles">{totalCountList?.toalNotesCount ? totalCountList?.toalNotesCount : 0}</p>
                                                        <p style={{ width: "100px" }} className="titlefr">Notes</p>
                                                    </div>
                                                </div>
                                                {totalCountList?.lastWeekVideoCount ?
                                                    <p style={{ marginLeft: '50px' }}><span style={{ color: totalCountList?.lastWeekVideoCount > 0 ? "#28DF99" : '#FF414D' }}>{totalCountList?.lastWeekVideoCount ? totalCountList?.lastWeekVideoCount : null} from last week</span></p>
                                                    : null}
                                            </div>
                                        </div>
                                        <div style={{ borderLeft: "1px solid #E6E6E6" }}></div>
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="display-flex-inlibrarycard">
                                                    <div className="image">
                                                        <img className="notes-image" src={videoImg} />
                                                    </div>
                                                    <div className="both">
                                                        <p className="nofiles">{totalCountList?.totalVideoCount ? totalCountList?.totalVideoCount : 0}</p>
                                                        <p style={{ width: "100px" }} className="titlefr">Videos</p>
                                                    </div>
                                                </div>
                                                {totalCountList?.lastWeekVideoCount ?
                                                    <p style={{ marginLeft: '50px' }}><span style={{ color: totalCountList?.lastWeekVideoCount > 0 ? "#28DF99" : '#FF414D' }}>{totalCountList?.lastWeekVideoCount ? totalCountList?.lastWeekVideoCount : null} from last week</span></p>
                                                    : null}
                                            </div>

                                        </div>
                                        <div style={{ borderLeft: "1px solid #E6E6E6" }}></div>

                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div className="display-flex-inlibrarycard">
                                                    <div className="image">
                                                        <img className="notes-image" src={questBankImg} />
                                                    </div>
                                                    <div className="both">
                                                        <p className="nofiles">{totalCountList?.totalQuesBankCount ? totalCountList?.totalQuesBankCount : 0}</p>
                                                        <p style={{ width: "100px" }} className="titlefr">Test papers</p>
                                                    </div>
                                                </div>
                                                {totalCountList?.lastWeekQuestionBankCount ?
                                                    <p style={{ marginLeft: '50px' }}><span style={{ color: totalCountList?.lastWeekQuestionBankCount > 0 ? "#28DF99" : '#FF414D' }}>{totalCountList?.lastWeekQuestionBankCount ? totalCountList?.lastWeekQuestionBankCount : null} from last week</span></p>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col >
                        <Col md={{ span: 24 }} lg={{ span: 9 }} >
                            <div style={{ width: '95%', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', boxShadow: "4px 4px 18px #302A5F14", borderRadius: '12px', height: "175px", marginLeft: '20px' }}>
                                <div style={{ width: '100%', display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography.Text className='text-heading-library'>Overview</Typography.Text>
                                    {user?.access?.includes("digital-library-superadmin") ?
                                        <div className='cursor-pointer' style={{ display: 'flex', alignItems: 'center', color: '#1089FF' }} onClick={() => handleSetting(setSubscriptionsSelectedTab)}><SettingFilled style={{ marginRight: '5px' }} /> Manage</div>
                                        : null}
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Notes</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                            {formatFileSize(sizeOfContents?.sizeOfLn)}
                                {/* {
                            parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024).toFixed(2))} MB */}
                            </Typography.Text>
                        </div>
                    </div>}
                >
                    <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#9876E6', }}></div>
                </Tooltip>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Videos</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                            {formatFileSize(sizeOfContents?.sizeOfMp4Video)}

                                {/* {parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024).toFixed(2))} MB */}
                                </Typography.Text>
                        </div>
                    </div>}
                >
                    <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#579EFF' }}></div>
                </Tooltip>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Question Bank</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                            {formatFileSize(sizeOfContents?.sizeOfQb)}

                                {/* {parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024).toFixed(2))} MB */}
                                </Typography.Text>
                        </div>
                    </div>}
                >
                    <div style={{ width: `${(parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`, height: '40px', backgroundColor: '#FF83D0' }}></div>
                </Tooltip>
                <Tooltip
                    title={<div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>

                            <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Storage Left</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                                {/* {(parseFloat((parseFloat(totalSize * 1024 * 1024 * 1024 - (sizeOfContents?.sizeOfLn + sizeOfContents?.sizeOfQb + sizeOfContents?.sizeOfMp4Video))).toFixed(2) /1024/1024)).toFixed(2)} MB*/}
                                {formatFileSize(storageLeft)}
                                </Typography.Text> 
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        
                        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>Used data</Typography.Text>
                            <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>
                                {/* {parseFloat(parseFloat(sizeOfContents?.sizeOfTotal/1024/1024).toFixed(2))} MB */}
                                {formatFileSize(usedData)}
                                </Typography.Text>
                        </div>

                    </div>}
                >
                    <div style={{ width: 
                    `${(parseFloat((parseFloat(sizeOfContents?.spaceAvailable) / 1024 / 1024) / parseFloat(totalStorageSize * 1024)) * 100).toFixed(2)}%`,
                        // `${(parseFloat((parseFloat(totalSize * 1024 * 1024 * 1024 - (sizeOfContents?.sizeOfLn + sizeOfContents?.sizeOfQb + sizeOfContents?.sizeOfMp4Video))).toFixed(2) /1024/1024)).toFixed(2)}%`, 
                        height: '40px', backgroundColor: '#E6E6E6' }}></div>
                </Tooltip>
            </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            <div style={{ backgroundColor: "#9876E6", height: "12px", width: "12px" }}></div>
                                            <div style={{ marginTop: -2, fontSize: "11px", color: "#636363" }}>Notes ({formatFileSize(sizeOfContents?.sizeOfLn)})</div>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            <div style={{ backgroundColor: "#579EFF", height: "12px", width: "12px" }}></div>
                                            <div style={{ marginTop: -2, fontSize: "11px", color: "#636363" }}>Videos ({formatFileSize(sizeOfContents?.sizeOfMp4Video)})</div>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            <div style={{ backgroundColor: "#FF83D0", height: "12px", width: "12px" }}></div>
                                            <div style={{ marginTop: -2, fontSize: "11px", color: "#636363" }}>Test papers ({formatFileSize(sizeOfContents?.sizeOfQb)})</div>
                                        </div>

                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <Button type='primary' style={{ width: '170px', border: "1px solid #1089FF", backgroundColor: "#fff" }} onClick={() => handleStorage()}><h style={{ fontSize: "14px", color: "#1089FF", fontWeight: 600 }}>GET MORE STORAGE</h></Button>
                                    </div>
                                </div>
                                {(modalVisible && <WarningModal isModalVisible={modalVisible} setIsModalVisible={setModalVisible} />)}
                            </div>
                        </Col>
                    </Row>
                }
            </div>
            {/* <div style={{ width: '100%', marginBottom: '25px' }}>
                <div style={{ display: "flex", justifyContent: "space-evenly", padding: 5 }}>
                    <LibraryCardDetails title={"Lecture Notes"} filesNO={totalCountList?.toalNotesCount ? totalCountList?.toalNotesCount : 0} lastWeek={totalCountList?.lastWeekLectureNotesCount} images={lecturenotes} index={0} />
                    <LibraryCardDetails title={"Videos"} filesNO={totalCountList?.totalVideoCount ? totalCountList?.totalVideoCount : 0} lastWeek={totalCountList?.lastWeekVideoCount} images={videoImg} index={1} />
                    <LibraryCardDetails title={"Question Banks"} filesNO={totalCountList?.totalQuesBankCount ? totalCountList?.totalQuesBankCount : 0} lastWeek={totalCountList?.lastWeekQuestionBankCount} images={questBankImg} index={2} />
                    <div className='m-t-20 m-b-10' style={{ borderBottom: "1px solid #E6E6E6", width: "100%" }}></div>
                </div>
            </div> */}

            {/* <Row>
                <Col sm={{ span: 24 }} md={{ span: 14 }}>
                    <div>
                        <AdminSummary togglePageLoader={togglePageLoader} pageLoader={pageLoader} />
                    </div>
                </Col>
                <Col sm={{ span: 24 }} md={{ span: 10 }} style={{ width: "" }}>
                    <div className="adminSummaryCardB" >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className='text-heading-library' style={{ fontSize: '16px' }}>Storage Usage</div>
                            {user?.access?.includes("digital-library-superadmin") ?
                                <div className='cursor-pointer' style={{ display: 'flex', alignItems: 'center', color: '#1089FF' }} onClick={() => handleSetting(setSubscriptionsSelectedTab)}><SettingFilled style={{ marginRight: '5px' }} /> Manage</div>
                                : null}
                        </div>
                        <div>
                            {currentSubscription && Object.keys(currentSubscription)?.length != 0 && (moment(currentSubscription?.endDate, 'YYYY-MM-DD').diff(moment(), 'days') <= 30) ?
                                <div style={{ marginLeft: '8px', height: '10px' }}>
                                    {moment(currentSubscription?.endDate, 'YYYY-MM-DD').diff(moment(), 'days') < 0 ?
                                        <Alert message={<div style={{ color: '#636363' }}>Your subscription has ended on {moment(currentSubscription?.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')} </div>} showIcon icon={<ExclamationCircleOutlined style={{ color: '#FF414D' }} />} style={{ backgroundColor: 'white', border: 'none', padding: 0, }} />
                                        :
                                        <Alert message={<div style={{ color: '#636363' }}>Your subscription ends in {moment(currentSubscription?.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')} days</div>} showIcon icon={<ExclamationCircleOutlined style={{ color: '#FF414D' }} />} style={{ backgroundColor: 'white', border: 'none', padding: 0, }} />
                                    }
                                </div>
                                : null}
                        </div>
                        <div style={{ height: '240px' }}>
                            <StoragePieCard sizeOfContents={sizeOfContents} totalStorageSize={totalStorageSize} totalCountList={totalCountList} totalUnusedStorageSize={totalUnusedStorageSize} />
                        </div>
                        <div style={{ display: "flex", width: '100%', justifyContent: 'center', marginTop: '10px' }}>
                            <Button type='primary' style={{ width: '150px', borderRadius: '16px' }} onClick={() => handleStorage()}>Get More Storage</Button>
                        </div>
                        {(modalVisible && <WarningModal isModalVisible={modalVisible} setIsModalVisible={setModalVisible} />)}
                    </div>
                </Col>
            </Row> */}

        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        sizeOfContents,
        totalStorageSize,
        totalUnusedStorageSize,
        currentSubscription
    } = state.digitalLibrary;
    const { user } = state.session;
    return {
        sizeOfContents,
        totalStorageSize,
        user,
        totalUnusedStorageSize,
        currentSubscription
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateTotalUnusedStorageSize: (val) => dispatch(updateTotalUnusedStorageSize(val)),
    setSubscriptionsSelectedTab: tab => dispatch(setSubscriptionSelectedTab(tab))

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(AdminDashBoardCard);

