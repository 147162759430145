import { setFeeDetails } from "actions/feeManagement";
import { setCourseDetails } from "actions/userManagement";
import { Button, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function AddDetailsModal({
  isModalVisible,
  handleOk,
  handleCancel,
  isAdditionModal,
  setCourseDetails,
  studentCourseDetails,
  setShowGeneratePayReq,
  setIsAddOrGenModal,
  isAddOrGenModal,
  setFeeDetails,
  feeMangementData,
}) {
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  const [amountOrPercentage, setAmountOrPercentage] = useState(null);
  useEffect(() => {
    // if (isAdditionModal) {
    setName(null);
    setDesc(null);
    setAmountOrPercentage(null);
    // } else {
    //   setName(null);
    //   setDesc(feeMangementData?.generatePayDiscount?.[0]?.details);
    //   setAmountOrPercentage(feeMangementData?.generatePayDiscount?.[0]?.amount);
    // }
  }, []);

  return (
    <div>
      <Modal
        maskClosable={false}
        className="scholorshipAndAdditionModalStyles"
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          className="flex_column_center"
          style={{
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "roboto",
            justifyContent: "center",
            marginBottom: "35px",
          }}
        >
          {isAdditionModal ? "Addition" : "Discount"} details
        </div>
        <div style={{ width: "100%" }}>
          <p className="dropDownLabelBasicStyle">
            {isAdditionModal ? "Addition" : "Discount"} title{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder={`Enter ${isAdditionModal ? "addition" : "discount"} title`}
            defaultValue={null}
            style={{ width: "100%" }}
            value={name}
            onChange={(e) => {
              // setCourseDetails()
              if (e.target.value == " ") {
                setName(null);
              } else {
                setName(e.target.value);
              }
            }}
          ></Input>
        </div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <p className="dropDownLabelBasicStyle">
            {isAdditionModal ? "Addition" : "Discount"} details
          </p>
          <TextArea
            placeholder={`Enter ${isAdditionModal ? "addition" : "discount"} details`}
            defaultValue={null}
            style={{ width: "100%", height: "100px" }}
            // value={classId}
            value={desc}
            onChange={(e) => {
              if (e.target.value == " ") {
                setDesc(null);
              } else {
                setDesc(e.target.value);
              }
            }}
          ></TextArea>
        </div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <p className="dropDownLabelBasicStyle">
            {isAdditionModal ? "Amount" : "Amount"}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            type="number"
            placeholder="Enter amount"
            prefix="₹"
            defaultValue={null}
            style={{ width: "100%" }}
            // value={classId}
            value={amountOrPercentage}
            onChange={(e) => {
              setAmountOrPercentage(e.target.value);
            }}
          ></Input>
        </div>
        <div
          className="flex_row_center"
          style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <Button onClick={handleCancel}>CANCEL</Button>
          <Button
            disabled={
              name == null ||
              name == "" ||
              amountOrPercentage == null ||
              amountOrPercentage == ""
            }
            onClick={() => {
              handleOk();
              if (isAdditionModal) {
                let temp = feeMangementData?.generatePayAdditions
                  ? feeMangementData?.generatePayAdditions
                  : [];
                temp.push({
                  name: name,
                  amount: amountOrPercentage,
                  details: desc,
                });
                setFeeDetails("generatePayAdditions", temp);
              } else {
                let temp = feeMangementData?.generatePayDiscount
                  ? [...feeMangementData?.generatePayDiscount]
                  : [];
                temp.push({
                  name: name,
                  amount: amountOrPercentage,
                  details: desc,
                });
                // setCourseDetails("scholarships", temp);
                setFeeDetails("generatePayDiscount", temp);
              }
            }}
            style={{ backgroundColor: "#594099", color: "white" }}
          >
            SAVE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

// export default ScholarshipAndAdditionModal;
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, dashboardData, classList, studentCourseDetails } =
    state.userManagement;
  const { feeMangementData } = state.feeManagement;

  return {
    tab,
    dashBoardRole,
    dashboardData,
    classList,
    studentCourseDetails,
    feeMangementData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDetailsModal);
