import { Col, Row } from 'antd'
import React from 'react'
import "./styles.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux';



function Footer({ homePageData }) {
    const history = useHistory();
    console.log("fooooo", homePageData?.footerData);

    const footItem = homePageData?.footerData;

    return (
        <div className="footerContainer roboto" style={{}}>
            <div className='row1'>
                <div>
                    <div className='m-t-30 text-sm font-bold'>Vectors Academy</div>
                    <div className='m-t-20 text-xmd'>2nd & 3rd Floor, 67 B, Fatick Chandra Rd,<br /> Latasil, Uzan Bazar,<br /> Guwahati, Assam 781001</div>
                    <div className='m-t-30 text-sm font-bold cursor-pointer' onClick={() => history.push("/locations")}>View Other Locations</div>
                    <div className='m-t-80 text-sm font-bold'>Visit Us At  </div>
                    <div className="m-t-20 text-xmd cursor-pointer">
                        {footItem?.website && (
                            <a href="http://eks2.aulas.one" target="_blank" rel="noopener noreferrer" style={{color:"#FFFFFF"}}>
                                {footItem.website}
                            </a>
                        )}</div>
                    <div className="m-t-50 text-xmd m-b-15">Copyright @ Vectors Education LLP. All rights reserved.</div>
                </div>
                <div>
                    <div className='m-t-30 text-sm font-bold'>Contact Us</div>
                    <div className='m-t-20'>+91 {footItem?.mobileNumber1}</div>
                    <div className='m-t-10'>+91 {footItem?.mobileNumber2}</div>
                    <div className='m-t-20'>support@vectorseducation.com</div>
                    <div className='m-t-100 text-sm font-bold'>We're Social</div>
                    <div className='c-c-c m-t-30'>
                        <img className="m-r-20" src={require("../../Assets/aulasMarket/instagram-logo.svg").default} />
                        <img className="m-r-20" src={require("../../Assets/aulasMarket/whatsApp-Logo.svg").default} />
                        <img className="m-r-20" src={require("../../Assets/aulasMarket/facebook-logo.svg").default} />
                        <img className="m-r-20" src={require("../../Assets/aulasMarket/X-logo.svg").default} />
                        <img className="m-r-20" src={require("../../Assets/aulasMarket/icons8-linkedin.svg").default} />
                    </div>
                </div>
                <div>
                    <div className='m-t-30 text-sm font-bold'>Download The Vectors Academy App</div>
                    <div className='r-c-fs  m-t-20'>
                        <img src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} />
                    </div>
                    <div className='m-t-50'>or</div>
                    <div className="font-bold m-t-50" style={{ fontSize: "20px" }}>Login Using The aulas App</div>
                    <div className='r-c-c'>
                        <div className='r-c-c  m-t-20'>
                            <img src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} />
                        </div>
                        <div className='r-c-fs p-15 m-t-20 m-l-10'>
                            <img src={require("../../Assets/aulasMarket/appStoreRes.svg").default} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { coursePageData, homePageData, loginDetails, } = state.aulasMarket
    return { coursePageData, homePageData, loginDetails, }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
