import React, { useEffect, useState } from 'react';
import { Modal, AutoComplete, DatePicker, Input, Tooltip } from 'antd';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { BroadcastMergeChunk, fileKeyUpdate, postBroadcastVideoUpload, updatepdf, uploadBroadcastBlock ,setHideMsgTrue} from 'actions/broadcast';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import file_image_1 from '../../../Assets/images/file_image_1.svg'
import { Button } from 'components/Button';
import { EventRegister } from 'react-native-event-listeners';


const blockSize = 1048576 * 20;

const { Dragger } = Upload;


const ReplaceVideos = ({ isUploadModal, setIsUploadModal, setRecordingsModalVisibility, roomName, deleteBroadcastRecording=false, BroadcastMergeChunk, fileKeyUpdate, updatepdf, uploadBroadcastBlock, Pdftoupload, postBroadcastVideoUpload ,setHideMsgTrue, isupload=false}) => {
    const [duration, setDuration] = useState(null);
    const [fileSize, setFileSize] = useState(null);
    const [loader, setLoader] = useState(false)
    const [videoFile, setVideoFile] = useState(null)
    const [progress, setProgress] = useState(0);
    
console.log("fileSize",fileSize);
    let uploadingMessage;

    const handleOK = () => {
        // setIsUploadModal(false)

        setLoader(true)
        setHideMsgTrue(true)
        if (deleteBroadcastRecording){
            deleteBroadcastRecording(roomName, () => { }, () => {
                uploadFunction(uploadingMessage, () => { }, () => {});
            })
        }else{
            uploadFunction(uploadingMessage, () => { }, () => {});
        }

    };

    useEffect(() => {
        fileKeyUpdate('')
    }, [])
    const UploadData = {
        roomId: roomName,
        fileSize: fileSize,
        newFileName: videoFile,
        fileDuration: duration
    }
    console.log("UploadData", UploadData);

    const handleCancel = () => {
        setIsUploadModal(false)
        setVideoFile(null)
        setFileSize(null)
        setDuration(null)
        setRecordingsModalVisibility(true)

    };

    const handleReplaceBroadcastRecording = (info) => {
        const isMp4 = info.file.type === 'video/mp4';
        updatepdf(info.file.originFileObj);
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log("info.file.originFileObj", info.file.name);
            setVideoFile(info.file.name)
            if (!isMp4) {
                message.error('Only .mp4 files are allowed.');
                return;
            }

            console.log("chhchchc", info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            extractFileInfo(info.file.originFileObj);

        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const extractFileInfo = (file) => {
        // Extract duration and file size
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            setDuration(formatDuration(video.duration));
            setFileSize(file.size);
        };
        video.src = URL.createObjectURL(file);
    };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // merge chunk and upload
    let blockStart = null;
    let blockEnd = null;
    let currentBlockNumber = null;
    let fileId = null;
    let totalBlocksCount = null;
    const uploadFunction = async (uploadingMessage, callback, successCallback) => {

        totalBlocksCount = Pdftoupload?.size % blockSize === 0 ? Pdftoupload?.size / blockSize : Math.floor(Pdftoupload?.size / blockSize) + 1;
        fileId = uuidv4() + "." + Pdftoupload?.name?.split('.')?.pop();
        blockStart = 0;
        blockEnd = blockSize;
        console.log("pdffff1", totalBlocksCount, fileId);
        for (currentBlockNumber = 1; currentBlockNumber <= totalBlocksCount; currentBlockNumber += 1) {

            var block = Pdftoupload.slice(blockStart, blockEnd);
            var blockWithType = new Blob([block], { type: Pdftoupload?.type });

            await uploadBlock(uploadingMessage, blockWithType, callback, successCallback);
        }
    };

    const uploadBlock = async (uploadingMessage, currentBlock, callback, successCallback) => {

        try {
            // currentBlock.type='application/pdf'
            var params = {
                file: currentBlock,
                orderId: currentBlockNumber,
                fileName: fileId,
                roomName: roomName

            }
            console.log('pdfff', params);
            await uploadBroadcastBlock(params, async (response) => {
                if (response != undefined) {
                    if (response?.data?.progress?.message === "uploaded") {
                        blockStart = blockEnd;
                        blockEnd += blockSize;
                        if (currentBlockNumber === totalBlocksCount) {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setProgress(percent);
                            BroadcastMergeChunk(fileId, (key) => {
                                if (uploadingMessage) {
                                    uploadingMessage()
                                }
                                postBroadcastVideoUpload(UploadData, () => { setLoader(false)}, () => {
                                    setIsUploadModal(false)
                                    setVideoFile(null),setFileSize(null),setDuration(null) 
                                    EventRegister.emit('apicall:scheduleHome');
                                    // setHideMsgTrue(null)
                                })
                                //  const updateResourceFile = [...sections]
                                //  updateResourceFile[index].topics[topicIndex].fileUrl = key;
                                //  setSections(updateResourceFile)
                            })
                        } else {
                            var percent = Math.round((currentBlockNumber / totalBlocksCount) * 100);
                            setProgress(percent);
                        }
                    }
                }
            });
        } catch (error) {
            console.log("error in block upload", error);
        }
    }



    return (
        <>

            <Modal
                className="modal-round-corner"
                open={isUploadModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={false}
                bodyStyle={{ borderRadius: "10px" }}
                onCancel={() => handleCancel()}
            >
                <div>
                    <div className='lecturenotestitle' style={{ width: '100%', textAlign: 'center', padding: '0px' }}>
                        <p style={{ margin: '0px' }}> Upload content for Library</p>
                    </div>
                    {
                        isupload?
                        null:
                        <div>The current video will be replaced with a new one</div>
                    }
                    <div>
                        <Dragger
                        showUploadList={false}
                            action={"https://testprod.aulas.in/api/mock-api/mock-upload"}
                            onChange={(e) => { handleReplaceBroadcastRecording(e) }}
                            accept={".mp4"}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single  upload. Strictly prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>
                    </div>
                    {videoFile ?
                        (<>
                            <div style={{ marginTop: '10px', width: "100%" }}>
                                <div className='shadow-box hover-shadow customCardShadow' style={{ height: "60px", padding: "10px" }}>
                                    <div style={{ display: "flex", width: "100%" }}>
                                        <div >
                                            <img src={file_image_1} style={{ width: "40px", height: "40px" }} />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px" }}>
                                            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{videoFile}</div>
                                            <Tooltip placement="top" overlay="Remove file">
                                                <div style={{ borderColor: "#E6E6E6", color: "#AEAEAE", fontSize: "11px", fontWeight: "bold", cursor: "pointer", }} onClick={() => { setVideoFile(null),setFileSize(null),setDuration(null) }}>
                                                    <DeleteOutlined style={{ fontSize: "20px", color: "#FF414D" }} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>) : null}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: "30px",
                        }}
                    >
                        <div>
                            <Button
                                    onClick={handleCancel}
                                >
                                    CANCEL
                            </Button>
                        </div>
                        <div>
                            <Button
                            disabled={fileSize == null}
                                loading={loader}
                                style={{
                                    opacity:fileSize == null ? 0.5 : 1
                                }}
                                type='primary'
                                onClick={() => { handleOK() }}

                            >
                                UPLOAD
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
};

const mapStateToProps = (state) => {
    const {
        Pdftoupload

    } = state.broadcast;
    const { user } = state.session
    return {
        Pdftoupload

    };
};

const mapDispatchToProps = (dispatch) => ({
    uploadBroadcastBlock: (params, callback) => dispatch(uploadBroadcastBlock(params, callback)),
    BroadcastMergeChunk: (fileId, callback) => dispatch(BroadcastMergeChunk(fileId, callback)),
    fileKeyUpdate: (key) => dispatch(fileKeyUpdate(key)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    setHideMsgTrue:(res) => dispatch(setHideMsgTrue(res)),
    postBroadcastVideoUpload: (params, callback, successCallback) => dispatch(postBroadcastVideoUpload(params, callback, successCallback))


});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(ReplaceVideos);