import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Select, Input, Tooltip, Button } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchChapterWiseTest, UpdateStudentTestData } from "actions/studentTest";
import { Api } from "services";
import PageDataLoader from "components/pageDataLoader";
import { NodataCard } from "pages/Test";
import { SubIcon } from "components/subIcon";
import { fetchStudentStandards } from "actions/schedule";
const bookGreen = require("../../Assets/test/bookGreen.svg").default;
const bookRed = require("../../Assets/test/bookRed.svg").default;
const bookBlue = require("../../Assets/test/bookBlue.svg").default;
const bookPurple = require("../../Assets/test/bookPurple.svg").default;
const chapterTest = require("../../Assets/test/chapterTest.svg").default;
const fullTest = require("../../Assets/test/fullTest.svg").default;
const MathTest = require("../../Assets/test/sbj-mathematics.svg").default;
const GeoTest = require("../../Assets/test/sbj-geography.svg").default;
const ChemTest = require("../../Assets/test/sbj-chemistry.svg").default;
const MockTest = require("../../Assets/test/question-bank.svg").default;
const ICChapterTest = require("../../Assets/test/ic-chapter-test.svg").default;
let timer = false;

const SearchBox = ({ loading, UpdateStudentTestData, fetchChapterWiseTest, studentTestData }) => {
  return (
    <Input
      placeholder="Search by subject name"
      className="m-l-20"
      allowClear={true}
      prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
      style={{ backgroundColor: "#FFF", borderRadius: 5, width: 250 }}
      onChange={(e) => {
        console.log(e)
        UpdateStudentTestData("search",
          e.target.value
        );
      }}
      bordered={true}
      value={studentTestData?.search}
    />
  );
};

const DropdownCheckbox = ({ selectedStandards, setSelectedStandards, studentStandardFilters, fetchChapterWiseTest }) => {
  const handleStandardChange = (value) => {
    fetchChapterWiseTest(value, () => { })
    setSelectedStandards(value);
  };

  return (
    <div style={{ width: '100%', marginLeft: 20 }}>
      <Select
        style={{ width: '100%' }}
        placeholder="Select standards"
        onChange={handleStandardChange}
        value={selectedStandards}
      >
        {studentStandardFilters?.studentStandards?.map((standard) => (
          <Option key={standard.standardId} value={standard.standardId}>
            {`${standard.std} - ${standard?.section}`}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const PageHeader = ({ title, back, UpdateStudentTestData, fetchChapterWiseTest, studentTestData, selectedStandards, setSelectedStandards, studentStandardFilters }) => {
  return (
    <div className="resultPageHeader full-width" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ArrowLeftOutlined onClick={back} style={{ fontSize: 18 }} />
        <div className="text-md bold-700 m-l-20"> {title} </div>
      </div>
      <div className="testfiltersgroup" style={{ paddingRight: "10px", width: '40%', display: 'flex', justifyContent: 'space-between' }}>
        <SearchBox
          UpdateStudentTestData={UpdateStudentTestData}
          fetchChapterWiseTest={fetchChapterWiseTest}
          studentTestData={studentTestData}
          loading={false}
        />
        <DropdownCheckbox
          selectedStandards={selectedStandards}
          setSelectedStandards={setSelectedStandards}
          studentStandardFilters={studentStandardFilters}
          fetchChapterWiseTest={fetchChapterWiseTest}
        />
      </div>
    </div>
  );
};

const ChaptertTestMod = ({ fetchChapterWiseTest, studentTestData, UpdateStudentTestData, fetchStudentStandards, studentStandardFilters }) => {

  const initialSelectedStandard = studentStandardFilters?.studentStandards?.length > 0 ? studentStandardFilters?.studentStandards[0]?.standardId : null;

  const history = useHistory();
  const [pageLoader, setPageLoader] = useState(false);
  const [selectedStandards, setSelectedStandards] = useState(initialSelectedStandard);


  useEffect(() => {
    setPageLoader(true)
    fetchChapterWiseTest(null, () => { setPageLoader(false) });
    UpdateStudentTestData("search",
      null
    );

    fetchStudentStandards();
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setPageLoader(true)
      fetchChapterWiseTest(null, () => { setPageLoader(false) });
    }, 1000);
  }, [studentTestData?.search]);


  const navigateToChapter = (studentTestData) => {
    if (studentTestData?.selectedTestCard?.testType == "Chapter Test") {
      history.push("student-InsideChapTest");
    } else {
      history.push("student-test/subject");
    }
  };

  const IconAndVal = ({ val, iconUrl }) => {
    return (
      <div>
        <img style={{ width: "18px", height: "18px" }} src={iconUrl} alt="" />
        <span style={{ marginLeft: "5px" }}>{val}</span>
      </div>
    );
  };

  const SubCard = ({ item, iconUrl, Subject, test, chapter, studentTestData }) => {
    return (
      <>
        <div
          className="cursor-pointer shadow-box hover-shadow customCardShadow "
          style={{
            height: "180px",
            width: "180px",
            borderRadius: "10px",
            border: "1px solid #E6E6E6",
            margin: "10px 20px 20px 0px",
          }}
          onClick={() => {
            navigateToChapter(studentTestData);
            UpdateStudentTestData("selectedChapterTestCard", item);

          }}
        >
          <div className="p-t-10">
            <div className="r-c-c-c m-t-10">
              <SubIcon size={50} icon={iconUrl} name={item?.subjectName ? item?.subjectName : ''} alt="" />
            </div>
            <h1
              className="bold-700 text-overflow-ellipsis p-10"
              style={{
                color: "#191919",
                textAlign: "center",
                fontSize: "16px",
                paddingTop: "5px",
                marginBottom: "0px",
              }}
            >
              {Subject}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20px 10px 10px 10px",
            }}
          >
            <IconAndVal iconUrl={bookGreen} val={test} />
            <IconAndVal iconUrl={bookBlue} val={chapter} />
          </div>
        </div>
      </>
    );
  };


  return (
    <>
      <PageHeader
        title={studentTestData?.selectedTestCard?.testType}
        UpdateStudentTestData={UpdateStudentTestData}
        fetchChapterWiseTest={fetchChapterWiseTest}
        back={() => history.goBack()}
        studentTestData={studentTestData}
        setSelectedStandards={setSelectedStandards}
        selectedStandards={selectedStandards}
        studentStandardFilters={studentStandardFilters}
      />
      <div style={{ display: "flex", margin: "20px 0px", flexWrap: "wrap" }}>
        {studentTestData?.testData?.subjects?.map((item) => (
          <SubCard
            iconUrl={item?.icon}
            item={item}
            // Subject={item.subject}
            Subject={item?.subjectName}
            test={item?.objectiveCount}
            chapter={item.subjectiveCount}
            studentTestData={studentTestData}

          />
        ))}

        {studentTestData?.selectedTestCard?.testType === 'Chapter Test' && studentTestData?.testData?.subjects?.length === 0 ?
          <div style={{ width: "100vw", height: "80vh" }}>
            <NodataCard />
          </div>
          :
          null
        }

        {studentTestData?.selectedTestCard?.testType != "Chapter Test" ?
          <SubCard
            item={{ combinedTest: true }}
            iconUrl={studentTestData?.testData?.combinedIcon}
            // Subject={item.subject}
            Subject={'Combined Test(s)'}
            test={studentTestData?.testData?.combinedObjective}
            chapter={studentTestData?.testData?.combinedSubjective}
          />
          : null}

        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

// export default ChaptertTestMod;
const mapStateToProps = (state) => {
  const { studentTestData } = state.studentTest;
  const { user } = state.session;
  const { studentStandardFilters } = state.schedule;

  return {
    studentTestData, user, studentStandardFilters
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchChapterWiseTest: (standardId, callback) => dispatch(fetchChapterWiseTest(standardId, callback)),
  UpdateStudentTestData: (key, val) => dispatch(UpdateStudentTestData(key, val)),
  fetchStudentStandards: () => dispatch(fetchStudentStandards())

});

export default connect(mapStateToProps, mapDispatchToProps)(ChaptertTestMod);
