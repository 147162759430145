import { Button, Modal, Typography } from 'antd'
import moment from 'moment';
import React, { useState } from 'react';
import { Api } from 'services';


function DetailLeaveModal({ setIsLeaveModalVisible, isLeaveModalVisible, storeAllLeavesData, name, image, role,
    duration, reason, actionBy, startDate, endDate,status }) {
    
    return (
        <div>
            <Modal className='modal-round-corner1'
                centered
                footer={null}
                header={null}
                onCancel={(e) => {
                    e.stopPropagation();
                    setIsLeaveModalVisible(false)
                }}
                visible={isLeaveModalVisible}>
                <div style={{ height: "380px" }}>
                    <div style={{
                        marginTop: "25px",
                        fontSize: "24px",
                        color: "#191919",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        Leave Details
                    </div>
                    <div style={{ background: "#FAFAFA 0% 0% no-repeat padding-box", borderRadius: "6px", height: '160px', marginTop: "30px" }}>
                        <div style={{ display: "flex", marginLeft: "30px" }}>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "25px", width: 90 }}>Leave type</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "25px" }}>:</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: " #191919", marginLeft: "20px", marginTop: "25px" }}>
                                {status}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "30px" }}>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "8px", width: 90 }}>Day(s)</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "8px" }}>:</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: " #191919", marginLeft: "20px", marginTop: "8px" }}>
                                {duration} Days
                            </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "30px" }}>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "8px", width: 90 }}>Date(s)</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "8px" }}>:</div>
                            <div style={{ display: "flex" }}>
                                <div style={{ font: "normal normal normal 15px/18px Roboto", color: " #191919", marginLeft: "20px", marginTop: "8px" }}>{moment(startDate).format('DD-MM-YYYY')}{"  "}-{"  "}{moment(endDate).format('DD-MM-YYYY')}</div>
                                {/* <div style={{ font: "normal normal normal 15px/18px Roboto", color: " #191919", marginLeft: "2px", marginTop: "8px" }}>-{" "}</div> */}
                            </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "30px" }}>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "8px", width: 90 }}>Reason</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: "#636363", marginTop: "8px" }}>:</div>
                            <div style={{ font: "normal normal normal 15px/18px Roboto", color: " #191919", marginLeft: "20px", marginTop: "8px" }}>
                                {reason}
                            </div>
                        </div>

                    </div>
                    <div style={{ width: "140px", height: "60px", marginTop: "20px", marginLeft: "500px" }}>
                        <div style={{ font: "normal normal bold 11px/16px Roboto", color: "#AEAEAE" }}>APPROVED BY</div>
                        <div style={{ display: "flex", marginTop: "5px" }}>
                            {/* <div><img style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div> */}
                            <div><img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${actionBy?.image}`}></img></div>

                            <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                                <Typography.Text className="text-overflow-ellipsis" style={{ font: "normal normal bold 14px/16px Roboto", color: "#191919", width: "80px" }} ellipsis={{ tooltip: true }}>{actionBy?.name}</Typography.Text>
                                <div style={{ font: "normal normal normal 13px/16px Roboto", color: " #636363", marginTop: "3px" }}>{actionBy?.role[0]}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <Button style={{
                            width: "140px",
                            height: "40px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #636363",
                            borderRadius: " 100px",

                        }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsLeaveModalVisible(false)
                            }}>CLOSE</Button>
                    </div>
                </div>
            </Modal >
        </div >
    )
}

export default DetailLeaveModal