import React from 'react';
import Typography from 'antd/lib/typography';
import Button from 'antd/lib/button';
import Empty from 'antd/lib/empty';
import 'antd/lib/typography/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/empty/style/index';

const{Title} = Typography;

class FourOFourPage extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Empty
          image={require("../Assets/404.svg").default}
          imageStyle={{
            height: 500,
          }}
          description={
            <Title level={4} className="m-t-30">
              Looks like the page you are looking for does not exist
            </Title>
          }
        >
        <Button type="primary" onClick={() => history.goBack()}>Go Back</Button>
      </Empty>
      </div>
    );
  }
}

export default FourOFourPage;
