import {
  addCourses,
  deleteCourse,
  getAllCourses,
  setCourseBillingPlan,
  setCourseDuration,
  setCourseName,
  setCoursePrice,
  setCourseRef,
  setInstallments,
  setSpecificCourseDetails,
} from "actions/course";
import { Button, Input, Modal, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  assignCourseToStudent,
  deleteCourseWithId,
  getCourseDetalis,
  getStudentsDetailsById,
  setCourseDetails,
  updateTransactions,
} from "actions/userManagement";
import { DeleteFilled } from "@ant-design/icons";


function AddCourseModal({
  isModalVisible,
  handleOk,
  setIsModalVisible,
  coursePrice,
  setCoursePrice,
  setCourseBillingPlan,
  studentCourseDetails,
  setCourseDetails,
  userDetailsById,
  updateTransactions,
}) {
  const [addButtonLdr, setAddButtonLdr] = useState(false);
  const [deleteButtonLdr, setDeleteButtonLdr] = useState(false);
  const [counter, setCounter] = useState(0);
  const [instCust, setInstCust] = useState([]);
  const [forInstallmentReset, setForInstallmentReset] = useState(false);
  const [forDueDate, setForDueDate] = useState(false);
  const [installmentsArray, setInstallmentsArray] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [name, setName] = useState();
  const [isDueDate, setIsDueDate] = useState(null)
  const [btnDisable, setBtnDisable] = useState(true)

  const dateFormat = "DD-MM-YYYY"

  useEffect(() => {
    function checkDisabled() {
      if (counter != studentCourseDetails?.newInstallments?.length) {
        setBtnDisable(true)
      }
      for (let i = 0; i < studentCourseDetails?.newInstallments?.length; i++) {
        let item = studentCourseDetails?.newInstallments[i]
        console.log("aalet", item);
        if (item?.amount === null || item?.amount === '') {
          setBtnDisable(true)
          return
        }
      }
      setBtnDisable(false)
    }
    checkDisabled()
  }, [counter, JSON.stringify(studentCourseDetails)])

  useEffect(() => {
    setCourseDetails("newInstallments", []);
  }, []);

  useEffect(() => {
    if (
      userDetailsById?.student?.feeDetails?.installmentDetails &&
      JSON.parse(userDetailsById?.student?.feeDetails?.installmentDetails)
        ?.length != 0
    ) {
      let temp = JSON.parse(
        userDetailsById?.student?.feeDetails?.installmentDetails
      );
      setInstallmentsArray(temp);
      setCourseDetails("newInstallments", temp);
    }
    setCoursePrice(userDetailsById?.student?.feeDetails?.totalPayable);
  }, []);
  useEffect(() => {
    let ct = 0;
    for (let i = 0; i < studentCourseDetails?.newInstallments?.length; i++) {
      if (studentCourseDetails?.newInstallments?.[i]?.amount) {
        ct = ct + 1;
      }
    }
    setCounter(ct);
  }, [studentCourseDetails?.newInstallments]);

  return (
    <div>
      <Modal
        maskClosable={false}
        className="addCourseB"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={() => {
          handleOk();
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {true ? (
              <h1
                style={{
                  marginBottom: "0",
                  color: "#191919",
                  fontFamily: "roboto",
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                Details
              </h1>
            ) : (
              <>
                <h1
                  style={{
                    marginBottom: "0",
                    color: "#191919",
                    fontFamily: "roboto",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                >
                  Add a course
                </h1>
                <p style={{ color: "#636363" }}>
                  Please fill the details of the course below
                </p>
              </>
            )}
          </div>
          <div
            style={{
              overflowY: "auto",
              padding: "20px",
              height: "470px",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontFamily: "roboto",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              Fee Details
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  padding: "10px",
                }}
              >
                <div
                  className="flex_row_center"
                  style={{
                    width: "207%",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                  }}
                >
                </div>
                <div>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                    }}
                  >
                    Total amount payable
                  </p>
                  <Input
                    prefix="₹"
                    style={{
                      width: "98%",
                      marginBottom: "20px",
                      border: "#E6E6E6 solid 1px",
                      borderRadius: "5px",
                      color: "#000000",
                    }}
                    placeholder="Enter Total Fee Amount"
                    value={coursePrice}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setCourseBillingPlan(null);
                      }
                      if (
                        e.target.value != " " &&
                        e.target.value != "0" &&
                        /^[\d]*$/.test(e.target.value)
                      ) {
                        setForInstallmentReset(true);
                        if (forInstallmentReset) {
                        } else {
                          setForDueDate(false);
                        }
                      }
                      setCoursePrice(
                        e.target.value != " " &&
                          e.target.value != "0" &&
                          /^[\d]*$/.test(e.target.value)
                          ? e.target.value
                          : coursePrice
                            ? coursePrice
                            : null
                      );
                    }}
                    type="text"
                  />{" "}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "50%",
                  padding: "10px",
                }}
              >
                <div>
                </div>
                <div style={{ width: "100%", marginTop: "4px" }}>
                  <div
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginLeft: "-12px",
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>

            {studentCourseDetails?.newInstallments?.map((item, index) => (
              <div className="r-jsb" style={{ width: '100%', marginTop: 10 }}>
                <div>
                  <div>Installment {index + 1}</div>
                  <Input
                    readOnly={
                      item?.paymentStatus == "Paid" ||
                      item?.paymentStatus == "Refund"
                    }
                    prefix="₹"
                    value={
                      studentCourseDetails?.newInstallments?.[index]?.amount
                    }
                    onChange={(e) => {
                      setName(e.target.value)
                      if (Number(e.target.value) || e.target.value == "") {
                        let temp = studentCourseDetails?.newInstallments;
                        temp[index] = {
                          ...item,
                          amount: e.target.value,
                        };
                        let ct1 = 0;
                        for (let i = 0; i < temp?.length; i++) {
                          if (temp?.[i]?.amount) {
                            ct1 = ct1 + 1;
                          }
                        }
                        setCounter(ct1);
                        setCourseDetails("newInstallments", temp);
                      }
                    }}
                    style={{ width: "250px", color: "black" }}
                  />
                </div>
                <div>
                  <div>Due Date {index + 1}</div>
                  <DatePicker
                    style={{ width: "300px" }}
                    allowClear={false}
                    readOnly={
                      item?.paymentStatus == "Paid" ||
                      item?.paymentStatus == "Refund"
                    }
                    value={studentCourseDetails?.newInstallments?.[index]?.dueDate ? studentCourseDetails?.newInstallments?.[index]?.dueDate !== undefined ? studentCourseDetails?.newInstallments?.[index]?.dueDate?.split('-')[0].length == 4 ? moment(studentCourseDetails?.newInstallments?.[index]?.dueDate) : moment(studentCourseDetails?.newInstallments?.[index]?.dueDate?.split('-').reverse().join('-')) : null : null}
                    format={dateFormat}
                    onChange={(value) => {

                      console.log("AA", value)
                      setIsDueDate(value);
                      let temp = studentCourseDetails?.newInstallments;
                      temp[index] = {
                        ...item,
                        dueDate: moment(value).format('YYYY-MM-DD')

                      };
                      let ct1 = 0;
                      for (let i = 0; i < temp?.length; i++) {
                        if (temp?.[i]?.dueDate) {
                          ct1 = ct1 + 1;
                        }
                      }
                      setCounter(ct1);
                      setCourseDetails("newInstallments", temp);
                    }
                    }

                    // disabledDate={(current) => {
                    //   let customDate = moment().format("YYYY-MM-DD");
                    //   return current && current < moment(customDate, "YYYY-MM-DD");
                    // }}
                    disabledDate={() => false}
                  />
                  {console.log("isDueDate", isDueDate)}
                </div>

                <div style={{ marginTop: 36, }}>
                  {
                    (studentCourseDetails?.newInstallments?.length) - 1 == index ? (
                      <>
                        {
                          studentCourseDetails?.newInstallments?.length > 1 && (
                            <>
                              {
                                ['Paid', 'Rejected', 'Link Sent', 'Refunded', 'Transfered', 'Cancelled', 'Settlement intiated', 'Settled'].includes(studentCourseDetails?.newInstallments?.[index]?.paymentStatus) ?
                                  (<></>)
                                  :
                                  (
                                    <DeleteFilled
                                      onClick={() => {
                                        var temp = studentCourseDetails?.newInstallments;
                                        var toSave = studentCourseDetails?.newInstallments[index]?.transactionId;
                                        if (toSave) {
                                          let tmpDelete = deletedIds;
                                          tmpDelete.push(toSave);

                                          console.log("deletedIds", deletedIds);
                                          setDeletedIds(tmpDelete);
                                        }

                                        temp.splice(index, 1);
                                        setCourseDetails("newInstallments", temp);
                                        setCounter(counter - 1);
                                      }}
                                      style={{
                                        marginTop: "-5px",
                                        fontSize: "13px",
                                        color: "red",
                                        cursor: "pointer",
                                        marginLeft: "-11px"
                                      }}
                                    />
                                  )
                              }
                            </>
                          )
                        }
                      </>
                    ) : null
                  }
                </div>
              </div>
            ))}

            <div
              className="flex_row_center"
              style={{
                cursor: coursePrice ? "pointer" : "not-allowed",
                color: "#1089FF",
                fontWeight: "600",
                marginLeft: "470px",
                fontFamily: "roboto",
              }}
              onClick={() => {
                if (coursePrice) {
                  let temp = studentCourseDetails?.newInstallments;
                  if (deletedIds.length > 0) {
                    temp.push({
                      amount: null,
                    });
                    let tmpDelete = deletedIds;
                    tmpDelete.pop();
                    setDeletedIds(tmpDelete);
                  } else {
                    temp.push({
                      amount: null,
                      dueDate: null,
                    });
                  }
                  setCourseDetails("newInstallments", temp);
                  setIsDueDate()
                }
              }}
              disabled={true}
            >
              + ADD INSTALLMENT
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
                position: "absolute",
                bottom: "30px",
              }}
            >
              <Button
                style={{
                  borderRadius: "25px",
                  color: "white",
                  height: "40px",
                  fontWeight: "500",
                  fontSize: "13px",
                  marginTop: "10px",
                  color: "#AEAEAE",
                  width: "150px",
                }}
                onClick={() => { setIsModalVisible(false); }}
              >
                CANCEL
              </Button>
              <Button
                disabled={btnDisable}
                style={{
                  borderRadius: "25px",
                  backgroundColor: "#594099",
                  color: "white",
                  height: "40px",
                  fontWeight: "500",
                  fontSize: "13px",
                  marginTop: "10px",
                  width: "150px",
                  opacity: studentCourseDetails?.newInstallments <= 0 ? 0.5 : 1
                }}
                loading={addButtonLdr}
                onClick={() => {
                  setAddButtonLdr(true);
                  updateTransactions(
                    () => {
                      setAddButtonLdr(false);
                    },
                    () => {
                      setIsModalVisible(false);
                    }, deletedIds
                  );
                  setCourseDetails("feeAmount", coursePrice);
                }}
              >
                {true ? "SAVE" : "ADD"}
              </Button>
            </div>
          </div>
        </div>
      </Modal >
    </div >
  );
}

const mapStateToProps = (state) => {
  const {
    courseName,
    coursePrice,
    courseDuration,
    courseRef,
    courseBillingPlan,
    installments,
    specificCourseDetails,
  } = state.course;
  const { studentCourseDetails, userDetailsById } = state.userManagement;

  return {
    courseName,
    coursePrice,
    courseDuration,
    courseRef,
    courseBillingPlan,
    installments,
    specificCourseDetails,
    studentCourseDetails,
    userDetailsById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCourseRef: (val) => dispatch(setCourseRef(val)),
  setCourseName: (val) => dispatch(setCourseName(val)),
  setCoursePrice: (val) => dispatch(setCoursePrice(val)),
  setInstallments: (val) => dispatch(setInstallments(val)),
  getCourseDetalis: (val) => dispatch(getCourseDetalis(val)),
  setCourseDuration: (val) => dispatch(setCourseDuration(val)),
  getAllCourses: (callback) => dispatch(getAllCourses(callback)),
  getStudentsDetailsById: () => dispatch(getStudentsDetailsById()),
  setCourseBillingPlan: (val) => dispatch(setCourseBillingPlan(val)),
  setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
  setSpecificCourseDetails: (val) => dispatch(setSpecificCourseDetails(val)),
  deleteCourse: (callback, successCallBack) => dispatch(deleteCourse(callback, successCallBack)),
  addCourses: (callback, successCallBack, val) => dispatch(addCourses(callback, successCallBack, val)),
  updateTransactions: (callback, success, deletedIds) => dispatch(updateTransactions(callback, success, deletedIds)),
  deleteCourseWithId: (callback, successCallBack, val) => dispatch(deleteCourseWithId(callback, successCallBack, val)),
  assignCourseToStudent: (callback, successCallBack, edit, confirm) => dispatch(assignCourseToStudent(callback, successCallBack, edit, confirm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseModal);