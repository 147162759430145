import React from 'react';
import Result from 'antd/lib/result';
import 'antd/lib/result/style/index';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';


function KycSuccess() {
  const history = useHistory()


    return (
      <div style={{height: '100%'}} className="r-c-c-c">
         <div className="r-c-c">
                <img src={require('Assets/ill-pending.svg').default}  />
            </div>
            <div className="color-purple text-sm bold-600 r-c-c"
            >KYC Documents Under Review</div>
            <div className="text-center  r-c-c">
                The details and documents you had submitted are currently being verified. For any further assistance please drop us a mail at care@aulas.in
            </div>
            <div className="r-c-c m-t-20">
                <Button type="primary" shape="round" style={{width:120}} onClick={() => history.push('/kyc-form')}>View Form</Button>
            </div>

      </div>

    )

}


export default KycSuccess;
