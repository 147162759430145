import Axios from "axios";
import fileDownload from "js-file-download";
import { sessionService } from "redux-react-session";
import { Api } from "../services";
import { logout, updateOrgDetails } from "./login";
import { getStorageData, getStorageDetails } from "./digitalLibrary";

export const UPDATE_INSTITUTE_DETAILS = "UPDATE_INSTITUTE_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_SUPPORT_NUMBERS = "UPDATE_SUPPORT_NUMBERS";
export const SET_GS_TAB = "SET_GS_TAB";
export const SET_KYC_TAB = "SET_KYC_TAB";
export const SET_OTHERS_TAB = "SET_OTHERS_TAB";
export const STORE_KYC_DETAILS = "STORE_KYC_DETAILS";
export const SET_SUBSCRIPTIONS_TAB = "SET_SUBSCRIPTIONS_TAB";
export const UPDATE_STORAGE_SUBS_DETAILS = "UPDATE_STORAGE_SUBS_DETAILS";
export const UPDATE_BROADCAST_SUBS_DETAILS = "UPDATE_BROADCAST_SUBS_DETAILS";
export const UPDATE_SEARCHED_USERS_LIST = "UPDATE_SEARCHED_USERS_LIST";
export const UPDATE_ATTENDANCE_SETTINGS = "UPDATE_ATTENDANCE_SETTINGS";
export const UPDATE_AULAS_SUBS_DETAILS = "UPDATE_AULAS_SUBS_DETAILS";
export const UPDATE_STUDENT_CLASS_DETAILS = "UPDATE_STUDENT_CLASS_DETAILS";
export const UPDATE_STUDENT_FEE_DETAILS = "UPDATE_STUDENT_FEE_DETAILS";
export const UPDATE_USER_ATTENDANCE_OVERVIEW = "UPDATE_USER_ATTENDANCE_OVERVIEW";
export const UPDATE_USER_ATTENDANCE_REQUESTS = "UPDATE_USER_ATTENDANCE_REQUESTS";
export const UPDATE_HOLIDAYS = "UPDATE_HOLIDAYS";
export const UPDATE_LEAVES_DATA = "UPDATE_LEAVES_DATA";
export const STORE_CFRT = 'STORE_CFRT'
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS'
export const UPDATE_USER_ACTIVITY = 'UPDATE_USER_ACTIVITY'
export const APPEND_USER_ACTIVITY = 'APPEND_USER_ACTIVITY'
export const SET_TRANSACTION_DATA = 'SET_TRANSACTION_DATA'
export const STORE_MARKETPLACE_SETTING = 'STORE_MARKETPLACE_SETTING'

export const storeMarketPlaceSetting = res => ({
    type: STORE_MARKETPLACE_SETTING,
    res
})

// export const deleteRequest = (params, callback, successCallback, pagecallback) => {
//     return (dispatch, getState) => {

//         const url = ``;
//         return  Api.delete(url).params(params).send((response, error) => {
//             if (response && response.show.type === "success") {
//                 if (response && response.show.type === "success") {
//                     successCallback()
//                     pagecallback(true)
//                     if(params.requestType === "Leave"){
//                         dispatch(getLeavesData(params,() =>  pagecallback(false)))
//                     }else{
//                         dispatch(getAttendanceRequests(params,() =>  pagecallback(false)))
//                     }

//                 }
//             }
//             callback(response);
//         });
//   };
// }

export const calculateLeaveDays = (params, callback) => {
    return (dispatch, getState) => {

        const url = `/attendancev2/calculate-dates`;
        return Api.get(url).params(params).send((response, error) => {
            if (response) {
                callback(response);
            }
        });
    };
}

export const updatePermanentlLocation = (params, callback, successCallback, pagecallback) => {
    return (dispatch, getState) => {

        const url = `/attendancev2/profile/geo-fence/${params.userId}/edit-permanent-allocation`;
        return Api.post(url).params(params).send((response, error) => {
            if (response && response.show.type === "success") {
                successCallback();
                pagecallback(true)
                dispatch(getHolidays(params, () => pagecallback(false)))
            }
            callback();
        });
    };
}

export const requestLeaveOrAttendance = (params, callback, successCallback, pagecallback) => {
    return (dispatch, getState) => {
        const url = params.commonId ? `/attendancev2/edit-request` : `/attendancev2/request`;
        return Api.post(url).params(params).send((response, error) => {
            if (response && response.show.type === "success") {
                successCallback()
                pagecallback(true)
                if (params.requestType === "Leave") {
                    dispatch(getLeavesData(params, () => pagecallback(false)))
                } else {
                    dispatch(getAttendanceRequests(params, () => pagecallback(false)))
                }

            }
            callback();
        });
    };
}



export const leaveOrAttendanceRequestActions = (params, callback, pagecallback, successCallback) => {
    return (dispatch, getState) => {
        const url = `/attendancev2/request-action`;
        const { user } = getState().session;
        const { userRowDetails } = getState().userManagementV2;

        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                pagecallback(true)
                if (params?.requestType == 'Leave') {
                    dispatch(getLeavesData({ userId: userRowDetails?.id, page: 1, limit: 10 }, () => pagecallback(false)))
                } else if (window.location.pathname !== '/attendance/requests') {
                    dispatch(getAttendanceRequests({ userId: userRowDetails?.id, page: 1, limit: 10 }, () => pagecallback(false)))
                }
                successCallback();
            }
            callback();
        });
    };
}

const updateLeavesData = (data) => ({
    type: UPDATE_LEAVES_DATA,
    data
})

export const getLeavesData = (params, callback) => {
    return (dispatch, getState) => {
        const url = `/attendancev2/profile/${params.userId}/leave`;
        return Api.get(url).send((response, error) => {
            if (response) {
                dispatch(updateLeavesData(response))
            }
            callback();
        });
    };
}

export const saveHolidays = (params, callback, pagecallback) => {
    return (dispatch, getState) => {
        const url = `/attendancev2/profile/${params.userId}/holiday/save-list`;
        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                pagecallback(true)
                delete params.dates
                dispatch(getHolidays(params, pagecallback))
            }
            callback();
        });
    };
}

export const updateHolidays = (data) => ({
    type: UPDATE_HOLIDAYS,
    data
})

export const getHolidays = (params, callback) => {
    return (dispatch, getState) => {
        const url = `/attendancev2/profile/${params.userId}/holiday`;
        return Api.get(url).params(params).send((response, error) => {
            console.log('holi', response);
            if (response) {
                dispatch(updateHolidays(response))
            }
            callback();
        });
    };
}


const updateUserAttendanceRequests = (data) => ({
    type: UPDATE_USER_ATTENDANCE_REQUESTS,
    data
})

export const getAttendanceRequests = (params, callback) => {
    return (dispatch, getState) => {
        const url = `/attendancev2/profile/${params.userId}/attendance-requests`;
        return Api.get(url).params(params).send((response, error) => {
            if (response) {
                dispatch(updateUserAttendanceRequests(response))
            }
            callback();
        });
    };

}



const updateUserAttendanceOverview = (data) => ({
    type: UPDATE_USER_ATTENDANCE_OVERVIEW,
    data
})

export const getAttendanceOverview = (params, callback) => {
    return (dispatch, getState) => {
        const url = `/attendancev2/profile/${params?.userId}/attendance-overview`;
        return Api.get(url).params(params).send((response, error) => {
            if (response) {
                dispatch(updateUserAttendanceOverview(response))
            }
            if (callback) {
                callback();
            }
        });
    };
}


const updateStudentFeeDetails = (data) => ({
    type: UPDATE_STUDENT_FEE_DETAILS,
    data
})

export const getFeeDetails = (selectedUserId, callback, fromWhere) => {
    return (dispatch, getState) => {
        const userId = getState().session.user.id;
        const url = `/user/get-student-fee-details/${fromWhere == 'userManagementProfile' ? selectedUserId : userId}`;
        return Api.get(url).send((response, error) => {
            if (response) {
                console.log("feeDetails", response);
                dispatch(updateStudentFeeDetails(response))
            }
            callback();
        });
    };
}


export const setSubscriptionSelectedTab = (tab) => ({
    type: SET_SUBSCRIPTIONS_TAB,
    tab
})

export const setKycSelectedTab = (tab) => ({
    type: SET_KYC_TAB,
    tab
})

export const setOthersSelectedTab = (tab) => ({
    type: SET_OTHERS_TAB,
    tab
})

export const setGsSelectedTab = (tab) => ({
    type: SET_GS_TAB,
    tab
})

const updateInstituteDetails = (data) => ({
    type: UPDATE_INSTITUTE_DETAILS,
    data
})

const updateUserDetails = (data) => ({
    type: UPDATE_USER_DETAILS,
    data
})

const updateSupportNumber = (data) => ({
    type: UPDATE_SUPPORT_NUMBERS,
    data
})

const updateAttendanceSettings = data => ({
    type: UPDATE_ATTENDANCE_SETTINGS,
    data
})

const updateStudentClassDetails = data => ({
    type: UPDATE_STUDENT_CLASS_DETAILS,
    data
})

export const getUserProfileDetails = (callback) => {
    const url = `/user/account-details`;
    return (dispatch, getState) => {
        const userPrivilege = getState().session.user.privilage;
        const userToken = getState().session.user.accessToken;
        const userRole = getState().session.user.role;

        return Api.get(url).send((response, error) => {
            if (response) {
                if (userPrivilege?.length) {
                    dispatch(updateInstituteDetails(response.instituteInfo))
                    dispatch(updateSupportNumber(response.supportPhones))
                }
                if (userRole?.includes("Student")) {
                    dispatch(updateStudentClassDetails(response.studentCourseDetails))
                }
                dispatch(updateUserDetails(response.profile))
                dispatch(updateAttendanceSettings(response.attendanceReminderSettings))
                if (response?.user) {
                    dispatch(updateOrgDetails({
                        title: response?.user?.org?.name,
                        logo: response?.user?.org?.logo,
                        website: response?.user?.org?.website,
                        instituteId: response?.user?.org?.instituteId
                    }));
                    response.user.accessToken = userToken;
                    sessionService.saveSession({ token: userToken }).then(() => {
                        sessionService.saveUser(response.user)
                    })
                }
            }
            if (callback) {
                callback();
            }
        });
    };
}


export const saveProfileDetails = (params, stopGetAccountDetails = false, callback, buttonCallback) => {
    const url = `/user/v2/update-profile`;

    params.emergencyPhone = params.emergencyPhone ?? "";
    
    const currentAddress = typeof params.currentAddress == "string"
    ? JSON.parse(params.currentAddress)
    : params.currentAddress;

const isSameAddress = (currentAddress, permanentAddress) => {
    const keysToCompare = ["pincode", "state", "city", "country", "h_no"];

    return keysToCompare.every(
        (key) => currentAddress[key] === permanentAddress[key]
    );
};

if (isSameAddress(currentAddress, JSON.parse(params.permanentAddress))) {
    let permanentAddress =
        typeof params.permanentAddress === "string"
            ? JSON.parse(params.permanentAddress)
            : params.permanentAddress;
    permanentAddress.sameAsCa = true;
    params.permanentAddress = JSON.stringify(permanentAddress);
}
    return (dispatch, getState) => {
        return Api.uploadAndPost(url, params, (percent, response) => {
            if (response) {
                if (!stopGetAccountDetails) dispatch(getUserProfileDetails())
                callback();
            }
            console.log({ percent });
            if (percent === 100) {
                buttonCallback();
            }
        });
    };

}

export const saveInstituteDetails = (params, callback, buttonCallback) => {
    const url = `/user/v2/institute-info`;
    return (dispatch, getState) => {
        return Api.uploadAndPost(url, params, (percent, response) => {
            if (response) {
                dispatch(getUserProfileDetails())
                callback();
            }
            console.log({ percent });
            if (percent === 100) {
                buttonCallback();
            }
        });
    };

}


export const saveSupportDetails = (params, callback, buttonCallback) => {
    const url = `/user/support-phones`;
    return (dispatch, getState) => {
        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                dispatch(getUserProfileDetails(callback))
                callback();
            }
            buttonCallback();
        });
    };

}

const storeKycDetails = (data) => ({
    type: STORE_KYC_DETAILS,
    data
})

export const getKycDetails = (callback) => {
    const url = `/fee/kyc`;
    return (dispatch) => {
        return Api.get(url).send((response, error) => {
            if (response) {
                dispatch(storeKycDetails(response))
            }
            callback()
        }
        )
    }
}

export const deleteLogo = (callback, successCallback) => {
    const url = `/user/remove-org-logo`;
    return (dispatch) => {
        return Api.patch(url).send((response, error) => {
            if (response) {
                dispatch(getUserProfileDetails(callback))
                successCallback()
            }
            callback()
        }
        )
    }
}

export const deleteProfilePic = (callback, stopGetAccountDetails = false, successCallback) => {
    return (dispatch, getState) => {

        let userId = null;
        if (window.location.pathname === '/user-management/profile') {
            userId = getState().userManagementV2.userRowDetails?.id;
        } else {
            userId = getState().session?.user?.id;
        }

        const url = `/user/remove-image/${userId}`;
        return Api.patch(url).send((response, error) => {
            if (response) {
                if (!stopGetAccountDetails) dispatch(getUserProfileDetails(callback))
                successCallback()
            }
            callback()
        }
        )
    }
}


export const updateFeeSettings = (params, callback) => {
    const url = `/feev2/updateSettings`;
    return (dispatch, getState) => {
        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                dispatch(getUserProfileDetails())
            }
            callback();
        }
        )
    }
}

export const updateUserSettings = (params, callback) => {
    const url = `/user/addUserSettings`;
    return (dispatch, getState) => {
        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                dispatch(getUserProfileDetails(callback))
            }
        }
        )
    }
}

export const updateAttendanceReminderSettings = (params, callback) => {
    const url = `/attendancev2/attendance-reminder-settings`;
    return (dispatch, getState) => {
        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                dispatch(getUserProfileDetails(callback))
            }
        }
        )
    }
}

export const getStorageSubscriptionDetails = (callback, filter) => {
    const url = `/admin-library/billing-history`;
    return (dispatch, getState) => {
        return Api.get(url).params(filter).send((response, error) => {
            if (response) {
                dispatch(updateStorageSubscriptionDetails(response))
                dispatch(getStorageDetails());
            }
            callback()
        }
        )
    }
}

const updateStorageSubscriptionDetails = (data) => ({
    type: UPDATE_STORAGE_SUBS_DETAILS,
    data
})


export const searchUsers = (search, callback) => {
    const url = `/user/autocomplete`;
    return (dispatch, getState) => {
        const params = {
            name: search,
            roles: ["Teacher", "Data Operator", "Operation"]
        }
        return Api.get(url).params(params).send((response, error) => {
            if (response) {
                dispatch(updateUsersList(response))
            }
            callback()
        }
        )
    }
}

const updateUsersList = (data) => ({
    type: UPDATE_SEARCHED_USERS_LIST,
    data
})

export const transferSuperAdmin = (params, callback = null, successCallback = null, history) => {
    const url = `/user/transfer-ownership`;
    return (dispatch, getState) => {
        return Api.post(url).params(params).send((response, error) => {
            if (callback) {
                callback()
            }
            if (response) {
                if (successCallback) {
                    successCallback();
                }
                dispatch(logout(history, "Ownership changed"))
            }
            callback()
        }
        )
    }
}

export const getBroadcastSubscriptionDetails = (callback, filter) => {
    const url = `/schedule-new/subscription/billing-history`;
    return (dispatch, getState) => {
        return Api.get(url).params(filter).send((response, error) => {
            if (response) {
                dispatch(updateBroadcastSubscriptionDetails(response))
            }
            callback()
        }
        )
    }
}


const updateBroadcastSubscriptionDetails = (data) => ({
    type: UPDATE_BROADCAST_SUBS_DETAILS,
    data
})


export const getAulasSubscriptionDetails = (callback, filter) => {
    const url = `/organisation/billing-history`;
    return (dispatch, getState) => {
        return Api.get(url).params(filter).send((response, error) => {
            if (response) {
                dispatch(updateAulasSubscriptionDetails(response))
            }
            callback()
        }
        )
    }
}

export const downloadInvoices = (params, callback = null, successCallback = null) => {
    const url = `/invoice/download-all`;
    return (dispatch, getState) => {
        return Api.get(url).params(params).send((response) => {
            callback();
            if (response !== undefined) {
                Axios.get(response.url, {
                    responseType: "blob",
                }).then((res) => {
                    if (successCallback) {
                        successCallback(response?.key)
                    }
                    fileDownload(res?.data, response?.name);
                });
            }
        })
    }
}


export const deleteZipFile = (val, callBack = null) => {
    return (dispatch, getState) => {
        const url = `/invoice/delete-zip`;
        return (
            Api.delete(url).params({ key: val }).send((response) => {
                if (callBack) {
                    callBack();
                }
            })
        );
    };
};



const updateAulasSubscriptionDetails = (data) => ({
    type: UPDATE_AULAS_SUBS_DETAILS,
    data
});

export const paymentDetails = (res) => ({
    type: PAYMENT_DETAILS,
    res
})

export const editLeaveInProfile = (params, callback = null, successCallBack = null, pagecallback) => {

    return (dispatch, getState) => {
        const { specificUserId } = getState().userManagement;
        const { user } = getState().session;

        return Api.patch("/attendancev2/update-leaves-for-user").params(params).send((response) => {
            if (callback) callback();
            if (response && response.show.type === "success") {
                successCallBack();
                pagecallback(true)
                dispatch(getLeavesData({ userId: params?.userId }, pagecallback))
            }
        })

    }
}

export const deleteHistory = (params, callback, successCallback, fromWhere) => {
    return (dispatch, getState) => {
        console.log('delete param', params);
        const url = `/attendancev2/trash-request`;
        // const userId = getState().session.user.id;

        let userId = null;
        if (window.location.pathname === '/user-management/profile') {
            userId = getState().userManagementV2.userRowDetails?.id;
        } else {
            userId = getState().session?.user?.id;
        }

        return Api.delete(url).params(params).send((response, error) => {
            console.log("TP1", response);
            if (response && response.show.type === "success") {
                successCallback();
                {
                    fromWhere === 'LeaveSummary' ?
                        dispatch(getLeavesData({ userId: userId }, () => { })) :
                        dispatch(getAttendanceRequests({ userId: userId }, () => { }))
                }
            }
            console.log("TP1", callback);
            callback();
        });
    };
};

export const downloadAttendanceBySchedule = (params) => {
    return (dispatch, getState) => {

        let userId = null;
        if (window.location.pathname === '/user-management/profile') {
            userId = getState().userManagementV2.userRowDetails?.id;
        } else {
            userId = getState().session?.user?.id;
        }
        console.log({ userId })

        return Api.post(`/attendancev2/download-attendance-by-occurance/${userId}`).params(params).send((response) => {
            if (response !== undefined) {
                let fileName = response.file.split('/')[response.file.split('/').length - 1];
                Axios.get(response.file, {
                    responseType: "blob",
                }).then((res) => {
                    fileDownload(res.data, fileName);
                });
            }
        });
    };
};

export const getCfrt = (params, callback) => {
    const url = `/feev2/create-order`;
    return (dispatch, getState) => {
        return Api.post(url).params(params).send((response, error) => {
            if (response) {
                console.log({ response })
                // dispatch(storeCashfreeRT(response));
                callback(response?.body);
            }
        }
        )
    }
};

export const postPaymentDetails = (orderId, id, userId) => {
    const url = `/fee/getOrderDetails?orderId=${orderId}&id=${id}&userId=${userId}`;
    return (dispatch, getState) => {
        return Api.get(url).send((response, error) => {
            if (response) {
                dispatch(paymentDetails(response));
                console.log({ response })
            };
        }
        )
    };
};


export const getUserActivity = (params, firstTimeLoading, callback = null, loader = null) => {
    const { page, limit, userId } = params
    const url = `/notification/user-activity?page=${page}&limit=${limit}&disablePagination=${false}&userId=${userId}`;

    return (dispatch, getState) => {
        Api.get(url).send((response, error) => {
            if (response) {
                if (firstTimeLoading) dispatch(updateUserActivity(response))
                else dispatch(appendUserActivity(response))
            }
            if (!error) callback()   //so that if api fails we dont increase page number
            loader()
        })

    }
};

const setTransactionData = (res) => ({
    type: SET_TRANSACTION_DATA,
    res
})

export const getTransactionData = (startDate, endDate, week, isYear, callBack, successCallBack) => {

    const url = `/feev2`;
    console.log("AA-startDate", startDate, endDate, week, isYear);
    return (dispatch, getState) => {
        const { userRowDetails } = getState().userManagementV2;
        let params
        if (week == 'week') {
            params = {
                userId: userRowDetails.id,
                type: week,
                startDate: startDate,
                endDate: endDate,
            }
        } else {
            params = {
                userId: userRowDetails.id,
                type: week,
                year: isYear,

            }
        }

        Api.get(url).params(params).send((response, error) => {
            dispatch(setTransactionData(response))
            if (callBack) {
                callBack()
            }
            console.log('response', response);

            if (response) {
                if (successCallBack) {
                    successCallBack()
                }
            }
        })

    }
};

export const updateUserActivity = (data) => ({
    type: UPDATE_USER_ACTIVITY,
    data
})

export const appendUserActivity = (data) => ({
    type: APPEND_USER_ACTIVITY,
    data
});

export const getToggleButtonCheck = (params, callBack) => {
    return (dispatch, getState) => {

        const url = `/market/general-setting/defaultBanner`;
        Api.post(url).params(params).send((response, error) => {
            if (callBack) {
                callBack()
            }
            console.log('response', response);

            if (response) {
                callBack(response)
            }
        })
    };
}

export const updateMarketSettings = (params, callback, successCallback) => {
    return (dispatch, getState) => {
        console.log(params?.images, 'condition', params)

        let newImages = [];
        params?.images?.forEach((item, index, array) => {
            newImages.push(item ? item[0] : [])
        })
        const tempBanners = params?.bannerData;
        const mappedBanners = tempBanners?.map(item => (item?.fileUrl ? { ...item, imageIndex: -1 } : item));;
        const bannerParams = {
            bannerData: mappedBanners,
            images: newImages
        };


        let testimonialImages = [];
        params?.images?.forEach((item, index, array) => {
            testimonialImages.push(item ? item[0] : [])
        })
        const tempTestimonials = params?.testimonialData;
        for (let i = 0; i < tempTestimonials?.length; i++) {
            delete tempTestimonials[i].image;
        }

        const mappedTestimonials = tempTestimonials?.map(item => item?.imageUrl ? { ...item, imageIndex: -1 } : item)
        console.log({ mappedTestimonials })
        const testimonialsParams = {
            testimonialData: mappedTestimonials,
            images: testimonialImages
        }

        let outStandingImages = [];
        params?.images?.forEach((item, index, array) => {
            item?.map(item => outStandingImages.push(item ? item : []))
            // outStandingImages.push(item ? item : [])
        });

        const tempOutstandings = params?.outStandingResultData;
        for (let i = 0; i < tempOutstandings?.length; i++) {
            for (let j = 0; j < tempOutstandings[i]?.students?.length; j++) {
                delete tempOutstandings[i].students[j].file
                delete tempOutstandings[i].students[j].image
            }
        }
        console.log({ tempOutstandings })
        console.log({ outStandingImages })

        const outStandingParams = {
            outStandingResultData: tempOutstandings,
            images: outStandingImages
        }

        const paramsToSend = params?.bannerData && params?.images ? bannerParams : params?.testimonialData && params?.images ? testimonialsParams : outStandingParams

        console.log({ paramsToSend })
        const url = `/market/general-setting/save`;
        if ((params?.bannerData && params?.images) || (params.testimonialData && params.images || params.outStandingResultData && params.images)) {
            return Api.post(url).uploadMultiple(paramsToSend, (percent, response) => {
                if (typeof response !== 'undefined' && response?.show?.type != 'error') {
                    console.log({ response })
                    if (successCallback) {
                        successCallback();
                    }
                }
                callback();
            });
        } else {
            return Api.post(url).params(params).send((response, error) => {
                if (response && (response.show.type === "success" ||response.show.type == 'info')) {
                    successCallback();
                }
                callback();
            });
        };
    }
}

export const getMarketPlaceSettings = () => {
    return (dispatch, getState) => {

        const url = `/market/general-setting/get-market-place`;
        return Api.get(url).send((response, error) => {
            if (response) {
                dispatch(storeMarketPlaceSetting(response));
            }
        });
    };
};

export const getDropDownCourseList = (params, callback) => {
    return (dispatch, getState) => {

        const url = `/course/autocomplete-resources?names=true&emptySearch=true&published=${params}`;
        return Api.get(url).send((response, error) => {
            if (response) {
                callback(response);
            }
        });
    };
}