import React, { useState } from 'react';
import { Popover, Select } from 'antd';
import CalenderPopup from './calenderPopup';
import './careStyles.css'
import { connect } from 'react-redux';
import { barGraphPloat, setMonthOrWeek, barGraphPlotFunction } from 'actions/adminCare';
import image from '../../Assets/care/ic-calendar.svg';
import image1 from '../../Assets/care/ic-sort.svg';
import { CalendarOutlined } from '@ant-design/icons';

const { Option } = Select;


function CareCalender({ isMonthOrWeek, startDate, endDate, setMonthOrWeek, barGraphPlotFunction, togglePageLoader }) {
  const content = (
    <div className="popOverB" style={{ width: "217px" }}>
      <CalenderPopup togglePageLoader={togglePageLoader} />
    </div>
  );

  const handleChange = (value) => {
    setMonthOrWeek(value)
    togglePageLoader(true);
    barGraphPlotFunction(() => togglePageLoader(false))
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", border: "#E6E6E6 solid 1px" }}>
      <div><img style={{ width: "50px", height: "15px" }} src={image1} alt="" /></div>
      <Select
        className="inputSelecter"
        value={isMonthOrWeek}
        style={{ width: "80%", border: "none" }}
        onChange={handleChange}
        bordered={false}
        removeIcon={true}
        // disabled={true}
        dropdownMatchSelectWidth={true}
      >
        <Option value="week">Week</Option>
        <Option value="month">Months</Option>
        <Option value="year">Year</Option>
      </Select>
      {isMonthOrWeek == 'week' ?
        <Popover placement="bottomLeft" dropdownClassName={"dropDownB"} content={content} trigger="click">
         <CalendarOutlined className="antCalenderIcon" style={{ fontSize:18, marginRight: "2px" ,color:"#AEAEAE"}}/>
        </Popover>
        : null}
    </div>

  )
}
// export default CareCalender;

const mapStateToProps = state => {
  const {
    isMonthOrWeek,
    startDate,
    endDate,

  } = state.adminCare;
  return {
    isMonthOrWeek,
    startDate,
    endDate,
  };
};

const mapDispatchToProps = dispatch => ({

  setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  // barGraphPloat:(callback)=>dispatch(barGraphPloat(callback)),
  barGraphPlotFunction: (callback) => dispatch(barGraphPlotFunction(callback))


});


export default connect(mapStateToProps, mapDispatchToProps)(CareCalender);