import React, { useCallback, useEffect, useState } from 'react';

function ReCaptchaV3({ sitekey }) {
  const [isReCaptchaReady, setIsReCaptchaReady] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
    script.async = true;
    script.onload = () => {
      setIsReCaptchaReady(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [sitekey]);

  const executeReCaptcha = useCallback(async (action) => {
    if (isReCaptchaReady && window.grecaptcha) {
      try {
        const token = await window.grecaptcha.execute(sitekey, { action });
        return token;
      } catch (error) {
        console.error("Error executing ReCaptcha:", error);
      }
    }
  }, [isReCaptchaReady, sitekey]);

  return { executeReCaptcha, isReCaptchaReady };
}

export default ReCaptchaV3;