import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TopBar from "../topBar";
import NewFooter from "../newFooter";
import RatingStars from "../ratingStars";
import { getCourseReviews } from "actions/studentMarketPlace";
import { Api } from "services";
import "./courseRating.css";
import starIcon from "../../../Assets/aulasMarket/ic-star-fill.svg";

function AllCourseReviews({ getCourseReviews, singleCourseDetailsReviews, singleCourseDetailsDataContainer }) {
    const history = useHistory();
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [isProfileClicked, setIsProfileClicked] = useState(false);
    const [currentPage, setCurrentPage] = useState(singleCourseDetailsReviews?.meta?.currentPage);
 

    useEffect(() => {
        fetchReviews(currentPage);
    }, [currentPage]);

    const fetchReviews = (page) => {
        const itemType = singleCourseDetailsDataContainer?.course?.resourceType || singleCourseDetailsDataContainer?.videoResource?.resourceType || singleCourseDetailsDataContainer?.studyMaterial?.resourceType || singleCourseDetailsDataContainer?.testSeriesDetails?.resourceType
        const id = singleCourseDetailsDataContainer?.course?.id || singleCourseDetailsDataContainer?.videoResource?.id || singleCourseDetailsDataContainer?.studyMaterial?.id || singleCourseDetailsDataContainer?.testSeriesDetails?.id;

        if (itemType && id) {
            getCourseReviews(itemType, id, page, () => { });
        }
    };

  console.log('dddd', singleCourseDetailsDataContainer);
  

    const DateDisplay = ({ date }) => {
        const backendDate = new Date(date);
        const timeDifference = Date.now() - backendDate.getTime();
        const isFutureDate = timeDifference < 0;

        if (isFutureDate) {
            return <div>In the future</div>;
        }

        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        const hoursDifference = Math.floor(timeDifference / (1000 * 3600));
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        if (daysDifference > 0) {
            return <div>{daysDifference} {daysDifference === 1 ? "day" : "days"} ago</div>;
        } else if (hoursDifference > 0) {
            return <div>{hoursDifference} {hoursDifference === 1 ? "hour" : "hours"} ago</div>;
        } else if (minutesDifference > 0) {
            return <div>{minutesDifference} {minutesDifference === 1 ? "minute" : "minutes"} ago</div>;
        } else if (secondsDifference > 0) {
            return <div>{secondsDifference} {secondsDifference === 1 ? "second" : "seconds"} ago</div>;
        } else {
            return <div>Now</div>;
        }
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                setIsCategoryClicked(false);
                setIsProfileClicked(false);
            }}
        >
            <TopBar
                isCategoryClicked={isCategoryClicked}
                setIsCategoryClicked={setIsCategoryClicked}
                isProfileClicked={isProfileClicked}
                setIsProfileClicked={setIsProfileClicked}
            />
            <div
                className="course-reviews"
                style={{ backgroundColor: "#FAFAFA", minHeight: "calc(100vh - 50px)" }}
            >
                <div className="r-c-fs" style={{ marginTop: 100 }}>
                    <div>
                        <ArrowLeftOutlined onClick={() => history.push({
                            pathname: "/resource-details", 
                            state: {
                                courseReviews: true,
                                type:singleCourseDetailsDataContainer?.course?.resourceType || singleCourseDetailsDataContainer?.videoResource?.resourceType || singleCourseDetailsDataContainer?.studyMaterial?.resourceType || singleCourseDetailsDataContainer?.testSeriesDetails?.resourceType
                            },
                        })} style={{ marginRight: 10 }} />
                    </div>
                    <div className="rating-header">
                        <img src={starIcon} alt="star" />
                        <span style={{ marginLeft: 5 }}>
                            {singleCourseDetailsDataContainer?.course?.ratingValue || singleCourseDetailsDataContainer?.testSeriesDetails?.averageRating || singleCourseDetailsDataContainer?.videoResource?.ratingValue || singleCourseDetailsDataContainer?.studyMaterial?.ratingValue} course rating{" "}
                            <span className="rating-list-dot"></span>
                            {singleCourseDetailsReviews?.meta?.totalItems} ratings
                        </span>
                    </div>
                </div>
                <div className="all-reviewers-parent">
                    {singleCourseDetailsReviews?.items?.map((item, index) => (
                        <div key={index} className="course-reviewer-item">
                            <div className="review-child">
                                <div className="user-profile-parent">
                                    <img
                                        className="user-profile"
                                        src={`${Api._s3_url}${item?.image}`}
                                        alt="user"
                                    />
                                </div>
                                <div className="m-l-20">
                                    <div className="rating-name">{item?.name}</div>
                                    <div className="r-c-fs">
                                        <span>
                                            <RatingStars star={item?.ratingValue} />
                                        </span>
                                        <span className="rating-durations">
                                            <DateDisplay date={item?.createdAt} />
                                        </span>
                                    </div>
                                    <div className="rating-user-review">{item?.ratingExplanation}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Pagination Component */}
                <Pagination
                    current={currentPage}
                    total={singleCourseDetailsReviews?.meta?.totalItems || 0}
                    pageSize={singleCourseDetailsReviews?.meta?.itemsPerPage || 10}
                    onChange={(page) => setCurrentPage(page)}
                    style={{ textAlign: "center", marginTop: "20px" }}
                />
            </div>
            <NewFooter />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { singleCourseDetailsReviews, singleCourseDetailsDataContainer } = state.studentMarketPlace;
    const { user } = state.session;
    return { singleCourseDetailsReviews, user, singleCourseDetailsDataContainer };
};

const mapDispatchToProps = (dispatch) => ({
    getCourseReviews: (itemType, id, page, callback) => dispatch(getCourseReviews(itemType, id, page, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllCourseReviews);
