import React from 'react';
import {connect} from 'react-redux';
import Drawer from 'antd/lib/drawer';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import AutoComplete from 'antd/lib/auto-complete';
import Input from 'antd/lib/input';
import DatePicker from 'antd/lib/date-picker';
import 'antd/lib/drawer/style/index';
import 'antd/lib/button/style/index';
import 'antd/lib/select/style/index';
import 'antd/lib/auto-complete/style/index';
import 'antd/lib/input/style/index';
import 'antd/lib/date-picker/style/index';
import Color from '../../../services/Color'
import '../styles.css';
import moment from 'moment';
import { handleFilterParams } from '../../../actions/assignment';
import { searchUser } from '../../../actions/calendar';

const { Option } = Select;


class FilterModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedStd: '-1',
      sections: []
    }
  }



  onCancel(){
    this.props.onCancel();
    this.props.clearFilterParams();
  }



  standardSelect(value){
    if(value === -1){
      this.props.handleFilterParams(-1, 'selectedStd')
      this.props.handleFilterParams([], 'sections')
    }else{
      this.props.handleFilterParams(value, 'selectedStd')
      this.props.handleFilterParams(this.props.standards[value].section, 'sections')
    }
  }

  handleDateChange = (date, type) => {
    console.log(date);
    if (type === 'submit') {
      this.setState({
        newSubmitShowDate: moment(date._d).format('DD MMM'),
        newSubmitDate: moment(date._d).format('YYYY-MM-DD'),
      });
    }
    if (type === 'result') {
      this.setState({
        newResultDate: moment(date._d).format('YYYY-MM-DD'),
        newResultShowDate: moment(date._d).format('DD MMM'),
      });
    }
  }

  handleSubjectChange(value){
    this.props.handleFilterParams(value,'subjectName')
    if(!value || value === ""){
      this.props.handleFilterParams(null,'subject')
    }
  }

  handleTeacherChange(value){
    this.props.handleFilterParams(value,'teacherName')
    if(!value || value === ""){
      this.props.handleFilterParams(null,'teacher')
    }
  }

  render(){
      const { subjectsFound, searchLoader, filterModal, standards, filterParams,
      handleFilterParams, subjectSearch, teachers, onApply, user} = this.props;
      return (
        <Drawer
        title={<div className="r-c-sb"><span>Filters</span>
        <Button type='link' onClick={() => this.props.clearFilterParams()}>Clear All</Button></div>}
        placement="right"
        closable={false}
        footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button
              onClick={() => this.onCancel()}
               style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button onClick={() =>  onApply()} type="primary">
                Apply
              </Button>
            </div>
          }
        onClose={() => this.onCancel()}
        visible={filterModal}
        >
        <div className='dark-grey m-b-5'>Class</div>
        <div className='r-c-sb m-b-20' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}>
          <Select
            className='borderLessSelect'
            defaultValue={-1}
            value={filterParams.standard}
            style={{borderRadius:30, width: '100%'}}
            placeholder="Select Standard..."
            onChange={(value) =>  handleFilterParams(value, 'standard')}
            allowClear
            >
            <Option key={-1} value={-1} disabled>Select Standard</Option>
            {standards && standards.map((item, index) => (
              <Option key={item.id.toString()} value={item.id}>{item.std}-{item.section}</Option>
            ))}
          </Select>
        </div>
        {/*<div className='dark-grey m-b-5'>Section</div>
        <div className='r-c-sb m-b-20' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}>
          <Select
            className='borderLessSelect bold-600'
            defaultValue={-1}
            value={filterParams.selectedStd !== -1 ? filterParams.standard : -1}
            style={{borderRadius:30, width: '100%', color:Color.primary, fontSize:20}}
            onChange={(value) => handleFilterParams(value, 'standard')}
            >
            <Option key={-1} value={-1}>Select Section</Option>
            {filterParams.sections.map((item, index) => (
              <Option key={item.id.toString()} value={item.id}>{item.section}</Option>
            ))
          </Select>
        </div>*/}
        <div className='dark-grey m-b-5'>Subject</div>
        <div className='r-c-sb m-b-20' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}>
          <AutoComplete
            value={filterParams.subjectName}
            onChange={(value, obj) => this.handleSubjectChange(value)}
            options={subjectsFound}
            style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
            onSelect={(value, obj) => {
              handleFilterParams(obj.id,'subject')
            }}
            onSearch={(query) => subjectSearch(query)}
            >
            <Input.Search
              style={{ border:'none', borderRadius:5, height: 32}}
              placeholder="Search subject..."
              loading={searchLoader}
              allowClear
              onClear={() =>console.log("Cleareed")}

              />
          </AutoComplete>
        </div>
        {user?.access?.length === 1 && user?.access?.includes('assignment-teacher') ? null : 
          <>
            <div className='dark-grey m-b-5'>Teacher</div>
            <div className='r-c-sb m-b-20' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}>
              <AutoComplete
                options={teachers}
                style={{border:'none', width:'100%',  color:Color.primary, fontSize:16, fontWeight:600}}
                  onSelect={(value, obj) => {
                    handleFilterParams(obj.id,'teacher')

                  }}
                  onSearch={(query) => this.props.searchUser(query)}
                  value={filterParams.teacherName}

                  onChange={(value, obj) => this.handleTeacherChange(value) }
                  >
                  <Input.Search  placeholder="Search by teacher..." style={{ border:'none', borderRadius:5, height: 32}} allowClear/>
              </AutoComplete>
            </div> 
          </>
        }
        {filterParams.status !== "Draft" ?
        <>
        <div className='dark-grey m-b-5'>Publish Date</div>
        <div className='r-c-sb m-b-20' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}>
          <DatePicker
            size='small'
            style={{
              padding: '5px 5px !important',
              display: 'flex',
              flex: 1,
              fontSize: '1em',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              backgroundColor: '#EEEEEE !important',
              color: '#5448B7',
              border: false
            }}
            value={filterParams.publishDate ? moment(filterParams.publishDate) : null}
            onChange={(date) => handleFilterParams(date ? moment(date._d).format('YYYY-MM-DD') : null, 'publishDate')}
            format="DD-MM-YYYY"
          />
        </div>
        </>: null}
        <div className='dark-grey m-b-5'>Due Date</div>
        <div className='r-c-sb m-b-20' style={{backgroundColor:'#F9F9F9', padding:5, borderRadius:5}}>
          <DatePicker
            size='small'
            style={{
              padding: '5px 5px !important',
              display: 'flex',
              flex: 1,
              fontSize: '1em',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              backgroundColor: '#EEEEEE !important',
              color: '#5448B7',
              border: false
            }}
            onChange={(date) => handleFilterParams(date ? moment(date._d).format('YYYY-MM-DD') : null, 'dueDate') }
            value={filterParams.dueDate ? moment(filterParams.dueDate): null}
            format="DD-MM-YYYY"
          />
        </div>
        </Drawer>
      )
  }
}

const mapStateToProps = state => {
  const {standards, filterParams} = state.assignment
  const {
     teachers
  } = state.calendar;
  const {
    user
 } = state.session;
  const { subjectsFound } = state.classRoom;
  return {
    teachers, standards, filterParams, subjectsFound, user
  }
};

const mapDispatchToProps = dispatch => ({
  handleFilterParams: (val, key) => dispatch(handleFilterParams(val, key)),
  searchUser: (query) => dispatch(searchUser(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
