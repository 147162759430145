import { ArrowLeftOutlined } from '@ant-design/icons'
import { Checkbox, Pagination, Progress, Select } from 'antd'
import { Button, PageHeader, Typography, Table, Tooltip, Row, Col, Avatar, DatePicker, Input } from 'antd'
import { getLeaveOverViewData, roleFilter, searchFilter, storeRequestViewAllPage, typeFilter, setRoleFilter } from "actions/attendanceNew";


import SearchBox from 'pages/Test/localComponent/searchBox'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import DropDownCheckBox from './dropDownCheckBox'
import EditTeacherModal from './Modals/editTeacherModal'
import { connect } from 'react-redux';
import { Api } from 'services';
import PageDataLoader from 'components/pageDataLoader';
import { setLoader } from 'actions/testAdmin';
import RedirectModal from './Modals/redirectModal';
import { InputSearch } from 'components/customInputs';
import { NodataCard } from 'pages/classRoom';
const checkIcon = require("../../Assets/ic-check.svg").default
const closeIcon = require("../../Assets/ic-check.svg").default

const { Search } = Input;



const Header = ({ setIsCheckedValue, setIsEditTeacherModalVisible, roleFilter, filterRole, setRoleFilter, searchFilter, getLeaveOverViewData, storeRequestViewAllPage, storeLeaveOverviewData, setUserIds, setItem, isCheckedValue, setPageLoader,setIsModalOpen }) => {

    const planFilters = [
        { val: "All", id: 1, label: "All" },
        { val: "Teacher", id: 2, label: "Teacher" },
        { val: "Data Operator", id: 3, label: "Data Operator" },
        { val: "Operation", id: 4, label: "Operation" },
    ];
    const onSelection = () => {
        setPageLoader(true)
        storeRequestViewAllPage(1)
        setIsCheckedValue([])
        getLeaveOverViewData(() => { setPageLoader(false) })

    }
    useEffect(() => {
        setPageLoader(true)
        getLeaveOverViewData(() => { setPageLoader(false) })
        storeRequestViewAllPage(1)
    }, [])

    useEffect(() => {
        searchFilter()
    }, [])

    useEffect(() => {
        setRoleFilter()
    }, [])
    const history = useHistory();
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                    <div>
                        <InputSearch 
                            allowClear
                            placeholder="Search by name" 
                            onInputChange={(e) => {
                                searchFilter(e), setPageLoader(true)
                                setIsCheckedValue([])
                                storeRequestViewAllPage(1)
                                getLeaveOverViewData(() => { setPageLoader(false) })
                            }}
                            onClear={()=>{
                                searchFilter('');
                                setPageLoader(true);
                                getLeaveOverViewData(() => { setPageLoader(false)
                        })}}
                        />
                    </div>
                    <div style={{ width: "50%", marginLeft: "30px", }}>
                        <DropDownCheckBox style={{ height: "20px" }}
                            DATA={planFilters}
                            placeholder={`Role`}
                            type={`Role`}
                            className="full-width"
                            onSelection={onSelection}
                            onDropdownClose={() => { }}
                            defaultValue={null}
                            roleFilter={setRoleFilter}
                            role={filterRole}
                        // typeFilter={typeFilter}
                        />
                    </div>

                </div>
                {isCheckedValue?.length > 0 && (
                    <div>
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "150px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#594099",
                                color: "#FFFFFF",
                            }}
                            onClick={() => {
                                {storeLeaveOverviewData?.users?.allLeaves?.length == 0 ?
                                   (setIsModalOpen(true))
                                 :
                                setIsEditTeacherModalVisible(true)
                                setUserIds(isCheckedValue)
                                setItem(storeLeaveOverviewData?.users?.items[0]?.leaves)

                                 }
                                }
                            }
                        // createLeaves={createLeaves}
                        >
                            EDIT
                        </Button>
                    </div>
                )}


            </div>
        </>
    )
}

const AllTeachersViewAll = ({ setRoleFilter, typeFilter, getLeaveOverViewData, storeLeaveOverviewData, searchFilter, storeRequestViewAllPage, filterRole, roleFilter }) => {
    const [pageLoader, setPageLoader] = useState(false)

    const [isCheckedValue, setIsCheckedValue] = useState([])

    const [isEditTeacherModalVisible, setIsEditTeacherModalVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);

    const [leaveTypes, setLeaveTypes] = useState([]);
    console.log("AA-overview", storeLeaveOverviewData);


    useEffect(() => {

        setPageLoader(true)
        getLeaveOverViewData(() => { setPageLoader(false) })

    }, [])
    let temp = isCheckedValue
    const [reload, setReload] = useState(true);
    const handleChange = (id, e) => {
        if (!e) {
            temp = temp?.filter((e) => e != id);
        } else {
            temp?.push(id)
        }

        setIsCheckedValue(temp)
        setReload(!reload);
        console.log("AA-checel", temp);
    }
    const handleCheckAll = (e) => {
        console.log("AA-ee", e);
        temp = []
        if (e) {
            storeLeaveOverviewData?.users?.items.forEach(element => {

                temp.push(element?.id)
            });
        }
        setIsCheckedValue(temp)
        setReload(!reload);
    }

    useEffect(() => {

    }, [reload])
    const handlePageination = (value) => {
        storeRequestViewAllPage(value);

        setPageLoader(true)
        getLeaveOverViewData(() => { setPageLoader(false) })
        setIsCheckedValue([])


    };
    const [item, setItem] = useState(null);
    const [userIds, setUserIds] = useState([]);

    return (
        <>
            <div style={{ width: "100%" }}>
                <Header
                    searchFilter={searchFilter}
                    getLeaveOverViewData={getLeaveOverViewData}
                    storeRequestViewAllPage={storeRequestViewAllPage}
                    roleFilter={roleFilter}
                    storeLeaveOverviewData={storeLeaveOverviewData}
                    setItem={setItem}
                    setUserIds={setUserIds}
                    isCheckedValue={isCheckedValue}
                    setIsEditTeacherModalVisible={setIsEditTeacherModalVisible}
                    setPageLoader={setPageLoader}
                    typeFilter={typeFilter}
                    filterRole={filterRole}
                    setRoleFilter={setRoleFilter}
                    setIsCheckedValue={setIsCheckedValue}
                    setIsModalOpen={setIsModalOpen}

                />
            </div>
            {/* <div >Edit</div> */}
            {/* <div>
                <Button
                    style={{
                        borderRadius: "19px",
                        width: "150px",
                        height: "40px",
                        fontWeight: "500",
                        backgroundColor: "#594099",
                        color: "#FFFFFF",
                    }}
                    onClick={() => {
                        setIsEditTeacherModalVisible(true)
                        setUserIds(isCheckedValue)
                        setItem(storeLeaveOverviewData?.users?.items[0]?.leaves)

                    }
                    }
                // createLeaves={createLeaves}
                >
                    EDIT
                </Button>
            </div> */}


            <div className='m-t-50' style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    </div>
                </div>
                <div style={{ width: '100%', border: '1px solid #E6E6E6' }}>
                    {
                        storeLeaveOverviewData?.users?.items?.length != 0 ? 
                    
                    <div style={{ width: '100%', backgroundColor: '#F6F4FE' }}>
                        {console.log("AAA-storeLeaveOverviewData?.users?.items?.length",storeLeaveOverviewData?.users?.items?.length)}
                        <Row className='cursor-pointer' style={{ width: '100%' }}>
                            <Col style={{ width: '10%' }} onClick={() => { null }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: "flex" }}>
                                        <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold", color: "#636363", marginLeft: "8px" }}>
                                            <Checkbox
                                                checked={temp?.length > 0 && storeLeaveOverviewData?.users?.items?.length == temp.length}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                onChange={(e) => {
                                                    handleCheckAll(e.target.checked)
                                                }}
                                            /></div>
                                        <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold", color: "#636363", marginLeft: "8px" }}>
                                            SL NO.
                                        </div>


                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', }}>
                                        
                                        {storeLeaveOverviewData?.users?.items?.map((item, index) => {

                                            return (
                                                <div style={{ borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA', fontSize: "15px", fontWeight: 650 }}>

                                                    <Checkbox
                                                        style={{ marginRight: "10px" }}
                                                        checked={isCheckedValue?.includes(item.id)}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                        }
                                                        }
                                                        onChange={(e) => {
                                                            handleChange(item?.id, e.target.checked)
                                                        }}

                                                    />
                                                    {item?.slNo}
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </Col>
                            <Col style={{ width: '20%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: "20px", fontWeight: "bold", color: "#636363" }}>
                                        Name
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', backgroundColor: '#FFFFFF' }}
                                    >
                                        {storeLeaveOverviewData?.users?.allLeaves?.length == 0 ?
                                        
                                        (
                                        <>
                                        {storeLeaveOverviewData?.users?.items?.map((item, index) => (
                                            <div className='cursor-pointer' style={{ borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
                                                onClick={(e) => {
                                                    setIsModalOpen(true)

                                                    setItem(item?.leaves)
                                                    setUserIds([item.id])
                                                }}
                                            >
                                                <Avatar src={`${Api._s3_url}${item?.image}`} style={{ marginLeft: "15px", marginRight: '10px' }} />
                                                <div>
                                                    <div className="text-overflow-ellipsis" style={{ fontSize: "13px", fontWeight: 650, color: "#191919", }}>{item?.name}</div>
                                                    <div style={{ fontSize: "9px", color: "##636363", textAlign: "left" }}>{item?.role}</div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                        </>
                                        ):
                                        (
                                            <>
                                            {storeLeaveOverviewData?.users?.items?.map((item, index) => (
                                            <div className='cursor-pointer' style={{ borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
                                                onClick={(e) => {
                                                    setIsEditTeacherModalVisible(true)

                                                    setItem(item?.leaves)
                                                    setUserIds([item.id])
                                                }}
                                            >
                                                <Avatar src={`${Api._s3_url}${item?.image}`} style={{ marginLeft: "15px", marginRight: '10px' }} />
                                                <div>
                                                    <div className="text-overflow-ellipsis" style={{ fontSize: "13px", fontWeight: 650, color: "#191919", }}>{item?.name}</div>
                                                    <div style={{ fontSize: "9px", color: "##636363", textAlign: "left" }}>{item?.role}</div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                            </>
                                        )                                      
}
                                    </div>

                                </div>

                            </Col>

                            <Col style={{ width: '20%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold", color: "#636363" }}>
                                        Overall Percentage
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', }}>

                                        {
                                            storeLeaveOverviewData?.users?.allLeaves?.length == 0 ?
                                            (
                                                <>
                                                {storeLeaveOverviewData?.users?.items?.map((item, index) => (
                                            <div style={{ width: '100%', borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
                                                onClick={(e) => {
                                                    //  setIsEditTeacherModalVisible(true) 
                                                    setIsModalOpen(true)

                                                    setItem(item?.leaves)
                                                    setUserIds([item.id])
                                                }}
                                            >
                                                <Progress strokeColor="#594099" type='line' percent={item?.progress} style={{ width: '130px', fontWeight: 650, color: "#636363" }}></Progress>
                                            </div>
                                        ))}

                                                </>
                                            ) :
                                            (
                                                <>
                                                {storeLeaveOverviewData?.users?.items?.map((item, index) => (
                                            <div style={{ width: '100%', borderLeft: '1px  solid #E6E6E6', borderRight: '1px  solid #E6E6E6', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
                                                onClick={(e) => {
                                                    //  setIsEditTeacherModalVisible(true) 
                                                    // setIsEditTeacherModalVisible(true)
                                                    setIsEditTeacherModalVisible(true)

                                                    setItem(item?.leaves)
                                                    setUserIds([item.id])
                                                }}
                                            >
                                                <Progress strokeColor="#594099" type='line' percent={item?.progress} style={{ width: '130px', fontWeight: 650, color: "#636363" }}></Progress>
                                            </div>
                                        ))}
                                                </>
                                            )

                                        }
                                        
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ width: '50%', overflowX: 'scroll' }}>
                                <div style={{ display: "flex", flexDirection: "row" }} >
                                    {/* <div className='text-heading' style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold", color: "#636363" }}> */}
                                 
                                    {
                                        storeLeaveOverviewData?.users?.allLeaves?.map((item) => (
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'row', }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        // setIsEditTeacherModalVisible(true)
                                                        // setItem(item?.leaves)
                                                        // setUserIds([item.id])
                                                    }}>
                                                    <div className='text-heading' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '240px', height: '40px', fontWeight: "bold", color: "#636363" }}>
                                                        <div>{item}</div>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                                {storeLeaveOverviewData?.users?.items?.map((item, index) =>
                                    <div style={{ display: 'flex', flexDirection: 'column' }} onClick={(e) => {
                                        setIsEditTeacherModalVisible(true)
                                        setItem(item?.leaves)
                                        setUserIds([item.id])
                                    }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                            {item?.leaves.map((item, index) => {
                                                return (
                                                    <>
                                                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: "1px solid #E6E6E6", backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}>

                                                            <div className='text-heading' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '10px', width: '80px', height: '60px', fontWeight: "bold", color: "#636363", }}>

                                                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: "12px" }}>{item.total}</div>
                                                                <div style={{ color: "#636363", fontWeight: "bold", fontSize: "10px" }}>Total</div>

                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: "1px solid #E6E6E6", backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}>

                                                            <div className='text-heading' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '10px', width: '80px', height: '60px', fontWeight: "bold", color: "#636363", }}>

                                                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: "12px" }}>{item.balance}</div>
                                                                <div style={{ color: "#636363", fontWeight: "bold", fontSize: "10px" }}>Balance</div>

                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: "1px solid #E6E6E6", backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}>

                                                            <div className='text-heading' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '10px', width: '80px', height: '60px', fontWeight: "bold", color: "#636363", }}>

                                                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: "12px" }}>{item.availed}</div>
                                                                <div style={{ color: "#636363", fontWeight: "bold", fontSize: "10px" }}>Availed</div>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>

                                    </div>
                                )}
                            </Col>

                        </Row>
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <Pagination
                                current={storeLeaveOverviewData?.users?.meta?.currentPage}
                                defaultCurrent={1}
                                total={storeLeaveOverviewData?.users?.meta?.totalPages * 10}
                                onChange={handlePageination}
                                showSizeChanger={false}
                            />
                        </div>

                    </div>:
                    (
                    <>
                    <NodataCard/>
                    
                    </>)}
                </div>
                <div>
                    <EditTeacherModal
                        setIsEditTeacherModalVisible={setIsEditTeacherModalVisible}
                        isEditTeacherModalVisible={isEditTeacherModalVisible}
                        item={item}
                        userIds={userIds}
                        allData={storeLeaveOverviewData?.users?.items}
                        setPageLoader={setPageLoader}
                    />
                </div>
                <div>
                   <RedirectModal 
                   isModalOpen={isModalOpen}
                   setIsModalOpen={setIsModalOpen}
                   /> 
                </div>

            </div>
            <PageDataLoader visible={pageLoader} />

        </>
    )
}



const mapStateToProps = (state) => {
    const { storeLeaveOverviewData, filterRole, filter, searchFilterContainer, pageNumber } =
        state.attendanceNew;
    const { user } = state.session
    return {
        storeLeaveOverviewData, filterRole, filter, searchFilterContainer, pageNumber

    };
};

const mapDispatchToProps = (dispatch) => ({
    getLeaveOverViewData: (callback) => dispatch(getLeaveOverViewData(callback)),
    roleFilter: (val) => dispatch(roleFilter(val)),
    searchFilter: (val) => (dispatch(searchFilter(val))),
    storeRequestViewAllPage: (val) => dispatch(storeRequestViewAllPage(val)),
    typeFilter: (val) => dispatch(typeFilter(val)),
    setRoleFilter: (val) => dispatch(setRoleFilter(val))





});

export default connect(mapStateToProps, mapDispatchToProps)(AllTeachersViewAll)

