import PageHeader from 'components/pageHeader';
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { Col, Popover, Row, Table, Tabs } from 'antd';
import { deleteZipFile, downloadInvoices, getCfrt, getFeeDetails, getTransactionData, setGsSelectedTab, setOthersSelectedTab, setSubscriptionSelectedTab } from 'actions/profileNew';
import { IndividualDetails } from 'pages/profile/subComponents';
import { Button } from 'components/Button';
import { BellOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import AttendanceSummary from 'pages/profile/attendanceSummary';
import AttendanceRequests from 'pages/profile/attendanceRequests';
import LeaveSummary from 'pages/profile/leaveSummary';
import Holidays from 'pages/profile/holidays';
import Activities from 'pages/profile/activities';
import { connect } from 'react-redux';
import FeeBreakupModal from 'pages/profile/feeBreakupModal';
import TransactionDetailsModal from 'pages/profile/transactionDetailsModal';
import TabPane from 'antd/lib/tabs/TabPane';
import UserProfile from 'pages/profile/userProfile';
import moment from 'moment';
import { IMG_URL } from "../../env.json";
import { getStudentsDetailsById } from 'actions/userManagement';
import FeeDetailsNew from 'pages/userManagement/feeDetailsNew';
import CourseDetailsNew from 'pages/userManagement/courseDetailsNew';
import Transactions from './transactions';

const TabsComponent = ({ setBreakupModalVisible, setOthersSelectedTab, othersSelectedTab,specificUserProfile,
    studentCourseDetails, studentFeeDetails, rowSelection, onRow, handleDownload, downloadLoader,
    downloadVisible, user, setPageLoader, getCfrt, cfrToken, userRowDetails, userDetailsById, week, setWeek, startDate, setStartDate, endDate, setEndDate }) => {
    return (
        <Tabs onChange={key => setOthersSelectedTab(key)} activeKey={othersSelectedTab }>
            {Object.keys(tabs(setBreakupModalVisible, studentCourseDetails, studentFeeDetails, rowSelection, onRow,week,setWeek, startDate, setStartDate, endDate, setEndDate,specificUserProfile,
                handleDownload, downloadLoader, downloadVisible, user, setPageLoader, othersSelectedTab, getCfrt, cfrToken, userRowDetails)).map((tab, index) => (
                    <TabPane tab={tab} key={tab}>
                        {tabs(setBreakupModalVisible, studentCourseDetails, studentFeeDetails, rowSelection, onRow,week,setWeek, startDate, setStartDate, endDate, setEndDate,specificUserProfile,
                            handleDownload, downloadLoader, downloadVisible, user, setPageLoader, othersSelectedTab, getCfrt, cfrToken, userRowDetails, userDetailsById)[tab].body}
                    </TabPane>
                )
                )}
        </Tabs>
    )
}

const tabs = (setBreakupModalVisible, studentCourseDetails, studentFeeDetails, rowSelection, onRow,week,setWeek, startDate, setStartDate, endDate, setEndDate,specificUserProfile,
    handleDownload, downloadLoader, downloadVisible, user, setPageLoader, othersSelectedTab, getCfrt, cfrToken, userRowDetails, userDetailsById) => {
      
    const othersTabs = {}
    const userCourseUpdate = () => { }
    Object.assign(othersTabs, {
        "Basic Profile": {
            body: (
                <UserProfile setPageLoader={setPageLoader} fromWhere={'userManagementProfile'} userRowDetails={userRowDetails} />
            )
        }
    })
    if (((userRowDetails?.role?.includes('Student') || specificUserProfile?.role?.includes('Student')) && !(user?.privilage?.includes('LeaveManager'))) || (userRowDetails?.role?.includes('Student') && user?.privilage?.includes('Admin'))) {
        Object.assign(othersTabs, {
            "Classroom & course(s)": {
                body: (
                    <>
                        <CourseDetailsNew userCourseUpdate={userCourseUpdate} feeDetails={true} />
                    </>
                )
            },
        })
    }

    if ((userRowDetails?.role?.includes('Student') || specificUserProfile?.role?.includes('Student')) && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') || user?.privilage?.includes('FeeManager'))) {
        Object.assign(othersTabs, {
            "Transactions": {
                body: (
                    <>
                        <Transactions setPageLoader={setPageLoader} week={week} setWeek={setWeek} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
                    </>
                )
            },
        })
    }


    if ((userRowDetails?.role?.includes('Student') || specificUserProfile?.role?.includes('Student')) && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') || user?.privilage?.includes('FeeManager'))) {
        Object.assign(othersTabs, {
            "Fee details": {
                body: (
                    <>
                        <FeeDetailsNew userCourseUpdate={userCourseUpdate} />
                    </>
                )
            }
        })
    }

    Object.assign(othersTabs, {
        "Attendance": {
            body: (
                <AttendanceSummary setPageLoader={setPageLoader} userRowDetails={userRowDetails} fromWhere='userManagementProfile' />
            )
        },
        "Attendance Request(s)": {
            body: (
                <AttendanceRequests setPageLoader={setPageLoader} fromWhere={'userManagementProfile'} />
            )
        },
    })
    if (!(userRowDetails?.role?.includes("Student") || specificUserProfile?.role?.includes('Student'))) {
        Object.assign(othersTabs, {
            "Leaves": {
                body: (
                    <LeaveSummary setPageLoader={setPageLoader} fromWhere={'userManagementProfile'} />
                )
            },
        })
    }
    (user?.privilage?.includes('LeaveManager') || user?.privilage?.includes('SuperAdmin')) ?
        Object.assign(othersTabs, {
            "Holidays": {
                body: (
                    <Holidays setPageLoader={setPageLoader} />
                )
            }
        }) : null
    // if (userRowDetails?.role?.includes('Student')) {
    //     Object.assign(othersTabs, {
    //         "Activities": {
    //             body: (
    //                 <Activities setPageLoader={setPageLoader} />
    //             )
    //         }
    //     })
    // }

    return othersTabs
}

const UserBasicProfile = ({ setOthersSelectedTab, othersSelectedTab, user, studentCourseDetails, getFeeDetails,specificUserProfile,
    studentFeeDetails, downloadInvoices, deleteZipFile, setGsSelectedTab, setSubscriptionSelectedTab, getCfrt, cfrToken, userRowDetails, specificUserId, getStudentsDetailsById, userDetailsById, getTransactionData }) => {
    const history = useHistory();
    const [breakupModalVisible, setBreakupModalVisible] = useState(false);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [selectedInstallment, setSelectedInstallment] = useState({})
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [week, setWeek] = useState('week')
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [isYear, setIsYear] = useState(moment().format('YYYY'))

    const bodyStyle = {
        // background: "#FAFAFA",
        width: "calc(100% + 30px)",
        height: "calc(100% + 40px)",
        margin: "-10px -30px -30px 60px",
        padding: 20,
        overflow: "auto",
    };

    const onBackPress = () => {
        history.goBack();
    }

    useEffect(() => {
        switch (othersSelectedTab) {
            case "Fee details":
                setPageLoader(true)
                getStudentsDetailsById(null, userRowDetails?.id);
                getFeeDetails(userRowDetails?.id, () => setPageLoader(false), 'userManagementProfile');
                break;
            case 'Classroom & course(s)':
                getStudentsDetailsById(null, userRowDetails?.id);
                break;
            case 'Transactions':

                getStudentsDetailsById(null, userRowDetails?.id);
                setWeek('week')
                setStartDate(moment().format('YYYY-MM-DD'))
                setEndDate(moment().format('YYYY-MM-DD'))
                getTransactionData(startDate, endDate, week, isYear, () => { }, () => { })

                break;

        }
    }, [othersSelectedTab]);

    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys, selections, key) => {
            setSelectedPayments([...selections.map(item => item.paymentId)])
            setSelectedRowKeys([...selectedRowKeys])
        },
        getCheckboxProps: (record) =>
        ({
            disabled: record.status === "PENDING" || record.status === "OVERDUE" || record.status === "Not Requested", // Column configuration not to be checked    
        }),
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setDetailsModalVisible(true);
                setSelectedInstallment(record)
            }
        }
    }
    const handleDownload = () => {
        const params = {
            type: "Student Fee",
            invoiceKeys: selectedPayments
        }
        setDownloadLoader(true),
            downloadInvoices(params, () => { setDownloadLoader(false) }, (val) => { deleteZipFile(val) })
    }

    console.log('othersSelectedTab',othersSelectedTab);
    
    return (
        <div style={{ ...bodyStyle, marginLeft: 60 }}>
            {othersSelectedTab == 'Basic Profile' ? null :
                <PageHeader title={`${userRowDetails?.name}'s Profile`} onBackPress={onBackPress} />
            }
            <div className='r-c-c' style={{ width: '90%' }}>
                <div style={{ width: "100%" }} className="m-t-50">
                    <TabsComponent
                        setBreakupModalVisible={setBreakupModalVisible}
                        setOthersSelectedTab={setOthersSelectedTab}
                        othersSelectedTab={othersSelectedTab}
                        studentCourseDetails={studentCourseDetails}
                        studentFeeDetails={studentFeeDetails}
                        rowSelection={rowSelection}
                        onRow={onRow}
                        handleDownload={handleDownload}
                        downloadLoader={downloadLoader}
                        downloadVisible={selectedPayments.length > 1}
                        user={user}
                        setPageLoader={setPageLoader}
                        getCfrt={getCfrt}
                        cfrToken={cfrToken}
                        userRowDetails={userRowDetails}
                        userDetailsById={userDetailsById}
                        week={week}
                        setWeek={setWeek}
                        startDate={startDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        specificUserProfile={specificUserProfile}
                    />
                </div>
            </div>
            <FeeBreakupModal
                visible={breakupModalVisible}
                handleClose={() => setBreakupModalVisible(false)}
            />
            <TransactionDetailsModal
                handleClose={() => setDetailsModalVisible(false)}
                visible={detailsModalVisible}
                data={selectedInstallment}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.session;
    const { othersSelectedTab, studentCourseDetails, studentFeeDetails, cfrToken } = state.profileNew;
    const { userDetailsById } = state.userManagement;
    const { userRowDetails, specificUserId, specificUserProfile } = state.userManagementV2;
    return {
        user, othersSelectedTab, studentCourseDetails, studentFeeDetails, cfrToken, userRowDetails, specificUserId, userDetailsById, specificUserProfile
    }
}

const mapDispatchToProps = (dispatch) => ({
    setGsSelectedTab: (tab) => dispatch(setGsSelectedTab(tab)),
    getFeeDetails: (selectedUserId, callback, fromWhere) => dispatch(getFeeDetails(selectedUserId, callback, fromWhere)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    getCfrt: (params, callback) => dispatch(getCfrt(params, callback)),
    deleteZipFile: (params, callback) => dispatch(deleteZipFile(params, callback)),
    setSubscriptionSelectedTab: (tab) => dispatch(setSubscriptionSelectedTab(tab)),
    downloadInvoices: (params, callback, successCallback) => dispatch(downloadInvoices(params, callback, successCallback)),
    getStudentsDetailsById: (callback, id) => dispatch(getStudentsDetailsById(callback, id)),
    getTransactionData: (startDate, endDate, week, isYear, callBack, successCallBack) => dispatch(getTransactionData(startDate, endDate, week, isYear, callBack, successCallBack)),


})

export default connect(mapStateToProps, mapDispatchToProps)(UserBasicProfile);