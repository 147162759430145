import { ArrowLeftOutlined } from '@ant-design/icons';
import YTPlayer from 'components/ytPlayer';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'

function Liveplayer({liveStreamUrl}) {
  const history = useHistory();
  return (
    <div style={{height:"100%"}}>
        <div style={{ fontSize: "2em", fontWeight: 700, display: "flex", flexDirection: "row", marginBottom:20 }}>
            <ArrowLeftOutlined
                style={{ marginTop: "15px", fontSize: "20px" }}
                onClick={() => {
                    history.goBack();
                }} />
            <div style={{ marginLeft: "15px" }}>{"Live Stream"}</div>
        </div>
        <div style={{height:"90%"}}>
            <YTPlayer keyboardControls={true} videoUrl={liveStreamUrl} isLive={true}/>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    const {
        liveStreamUrl
    } = state.schedule;
    const { user } = state.session
    return {
        liveStreamUrl,
        user
    };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(Liveplayer);