import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";

function EditProfilePic({
  deletePicLoader,
  isModalVisible,
  handleOk,
  handleCancel,
  userDetailsById,
  handleProfileModalCancel,
  setImageUrl,
  userDetailsSaved,
  imageUrl,
  setdeleteConfirmation,
  userProfileDetails
}) {

 
  return (
    <div>
      <Modal
        className="editProfilePicModalB"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleProfileModalCancel}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Change/Remove display pic?
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "60px",
          }}
        >
          
          <Button
            onClick={setdeleteConfirmation}
            style={{ borderRadius: "20px", width: "150px" }}
            loading={deletePicLoader}
            disabled={
              userProfileDetails?.image && userProfileDetails.image !== "default.png" 
                ? false 
                : (!imageUrl || imageUrl === "" || imageUrl === "defaultLogo.png" || imageUrl === "default.png")
            }
          >
            <DeleteOutlined style={{ color: "#AEAEAE" }} />
            <span
              style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "600" }}
            >
              REMOVE
            </span>
          </Button>
          <Button
            onClick={handleOk}
            style={{
              borderRadius: "20px",
              backgroundColor: "#594099",
              width: "150px",
            }}
          >
            <SearchOutlined style={{ color: "white" }} />
            <span
              style={{ color: "white", fontSize: "12px", fontWeight: "600" }}
            >
              BROWSE PHOTO
            </span>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default EditProfilePic;
