import React from "react";
import { connect } from "react-redux";
import Modal from "antd/lib/modal";
import {CloseOutlined, ArrowRightOutlined} from '@ant-design/icons';
import { reviewModalFun, studentTestUpdateAns, postStudentTest, submitModalFun } from "actions/mobileTest";
import { Button } from 'antd';
import reviewIcon from "Assets/test-assignment/reviewIcon.svg";
import "antd/lib/modal/style/index";
import { Utils } from "services";

class ReviewModal extends React.Component{

  hitActionButton = (quesIndex, jump) => {
    const {studentTestAnsObj, studentTestQuesArr, studentTestQuesIndex, testUploadedBase64Arr, testUploadedImgArrNew, testUploadedImgdeleteArr, studentTestDurationInSec, studentTestDurationInSecTotal} = this.props;
    this.props.studentTestUpdateAns();
    var params;
    if(studentTestQuesArr[studentTestQuesIndex].type === 'Image'){
        let imgObjArr = [];
        testUploadedBase64Arr.map((key, index) => {
          testUploadedImgArrNew.map((key1, index1) => {
            if(key1.localImg === key.localImg){
              if(key.apiImg === ''){
                imgObjArr.push(key)
              }
            }
          })
        })

        let duplicateObj = {};
        for (let i=0, len=imgObjArr.length; i < len; i++ ){
            duplicateObj[imgObjArr[i]['localImg']] = imgObjArr[i];
        }
        imgObjArr = new Array();
        for (let key in duplicateObj ){
          imgObjArr.push(duplicateObj[key].localImgObj);
        }

       params = {
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        files: imgObjArr,
        deletedFiles: JSON.stringify(testUploadedImgdeleteArr),
        testStatus: jump === 'submit' ? 'Submitted' : 'Draft',
      }
    }else{
       params = {
        answer: studentTestAnsObj.answer,
        testQuestionId: studentTestQuesArr[studentTestQuesIndex].id,
        duration: studentTestDurationInSecTotal - studentTestDurationInSec || 0,
        submitStatus: studentTestAnsObj.submitStatus,
        testStatus: jump === 'submit' ? 'Submitted' : 'Draft',
      }
    }
    console.log('params leave++++++++++', params);
    this.props.reviewModalFun(false);
    const type = studentTestQuesArr[studentTestQuesIndex].type === 'Image' ? 'image' : 'mcq';
    this.props.postStudentTest(params, type, (response) => {
        Utils.stopDuration();
        this.props.submitModalFun(true);
    });
  }

  render(){
    const {history, data, reviewModalBool, studentTestQuesArr, studentTestQuesIndex} = this.props;
    return(
      <Modal
        className="autoSaveModal NutinFont"
        centered
        visible={reviewModalBool}
        bodyStyle={{
          padding: 0,
          borderRadius: 15,
          textAlign: "center",
        }}
        closable={false}
        width={1000}
        style={{ maxWidth: "calc(100vw - 16px)" }}
        footer={null}
      >
        <div className='p-10'>
          <div className='text-right higrey'>
            <CloseOutlined onClick={() => this.props.reviewModalFun(false)} />
          </div>
          <img src={reviewIcon} alt="" style={{width:'50px'}}/>
          <div className="text-sm color-black bold-700 m-t-10">Review Submission</div>
          <div className="higrey text-xmd m-b-10">
            Would you like to review before submission?
          </div>
        </div>
        <div className='p-20 radius-10' style={{background:'#EAFCF5'}}>
          <div className="text-sm color-black bold-700 m-b-10">Summary</div>
          <div className='r-c-sb'>
            <div className='color-black text-xmd bold-600'>All Questions</div>
            <div className='color-black text-xmd bold-700'>{studentTestQuesArr.length}</div>
          </div>
          <div className='m-10' style={{height:'1px', background:'#D4F9EB'}}></div>
          <div className='r-c-sb'>
            <div className='color-black text-xmd bold-600'>Attempted</div>
            <div className='color-black text-xmd bold-700'>{data.Attempted}</div>
          </div>
          <div className='m-10' style={{height:'1px', background:'#D4F9EB'}}></div>
          <div className='r-c-sb'>
            <div className='color-black text-xmd bold-600'>Attempted & Bookmarked</div>
            <div className='color-black text-xmd bold-700'>{data.AttemptedAndBookmarked}</div>
          </div>
          <div className='m-10' style={{height:'1px', background:'#D4F9EB'}}></div>
          <div className='r-c-sb'>
            <div className='color-black text-xmd bold-600'>Unattempted & Bookmarked</div>
            <div className='color-black text-xmd bold-700'>{data.Bookmarked}</div>
          </div>
          <div className='m-10' style={{height:'1px', background:'#D4F9EB'}}></div>
          <div className='r-c-sb'>
            <div className='color-black text-xmd bold-600'>Unattempted</div>
            <div className='color-black text-xmd bold-700'>{data.Skipped}</div>
          </div>
          <div className='m-10' style={{height:'1px', background:'#D4F9EB'}}></div>
          <div className='r-c-sb'>
            <div className='color-black text-xmd bold-600'> Unread</div>
            <div className='color-black text-xmd bold-700'>{data.Unattempted}</div>
          </div>
        </div>
        <div className='r-ac m-t-20'>
          <Button
            onClick={() => this.hitActionButton(studentTestQuesIndex+1, 'submit')}
            className='mobileTestGreyBtnWrap m-10'
          >
            SUBMIT
          </Button>
          <Button
            onClick={() => {
                            history.push(`${history.location.pathname}/review`);
                            Utils.stopDuration();
                            this.props.reviewModalFun(false);
                          }}
            className='mobileTestGreenBtnWrap m-10'
          >
            REVIEW <ArrowRightOutlined />
          </Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reviewModalBool, studentTestQuesArr, studentTestAnsObj, studentTestQuesIndex,
    testUploadedBase64Arr, testUploadedImgdeleteArr, studentTestDurationInSec,
  studentTestDurationInSecTotal, testUploadedImgArrNew } = state.mobileTest;
  return {
    reviewModalBool, studentTestQuesArr, studentTestAnsObj, studentTestQuesIndex,
    testUploadedBase64Arr, testUploadedImgdeleteArr, studentTestDurationInSec,
    studentTestDurationInSecTotal, testUploadedImgArrNew
  };
};

const mapDispatchToProps = dispatch => ({
  reviewModalFun: (bool) => dispatch(reviewModalFun(bool)),
  submitModalFun: (bool) => dispatch(submitModalFun(bool)),
  studentTestUpdateAns: () => dispatch(studentTestUpdateAns()),
  postStudentTest: (params, type, callback) => dispatch(postStudentTest(params, type, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);
