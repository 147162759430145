import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import { connect } from "react-redux";
import moment from "moment";

const DemoColumn = ({ growthSummary, isMonthOrWeek, graphData, xField, yField }) => {

  useEffect(() => {
    let array = [];

    for (let i = 0; i < growthSummary?.length; i++) {
      if (isMonthOrWeek === "week") {
        let objadded = {};
        objadded["name"] = "New";
        objadded["月份"] = moment(growthSummary[i]?.date).format("DD/MM");
        objadded["月均降雨量"] = growthSummary[i]?.added;
        array.push(objadded);
        let objdelete = {};
        objdelete["name"] = "Deleted";
        objdelete["月份"] = moment(growthSummary[i]?.date).format("DD/MM");
        objdelete["月均降雨量"] = growthSummary[i]?.deleted;
        array.push(objdelete);
      }

      if (isMonthOrWeek === "month") {
        let objadded = {};
        objadded["name"] = "New";
        objadded["月份"] = growthSummary[i]?.month;
        objadded["月均降雨量"] = growthSummary[i]?.added;
        array.push(objadded);
        let objdelete = {};
        objdelete["name"] = "Deleted";
        objdelete["月份"] = growthSummary[i]?.month;
        objdelete["月均降雨量"] = growthSummary[i]?.deleted;
        array.push(objdelete);
      }
    }
    return () => { };
  }, [growthSummary]);

  var config = {
    data: graphData,
    isGroup: true,
    xField: xField,
    yField: yField,
    seriesField: "type",
    tooltip: {
      customContent: (val, item) => {
        function numberWithCommas(x) {
          return (x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString());
        }
        return (
          <div>
            <div style={{ marginLeft: "-20px", marginRight: "-20px", alignItems: "center", height: "50px", width: "190px" }}>
              <div style={{ fontWeight: "bold", color: "#000000", fontSize: "14px", fontFamily: "Roboto", marginTop: "15px", marginLeft: "20px" }}>{item[0]?.data?.totalCountequested ? item[0]?.data?.totalCountequested : 0}{""}{" "}{" "}payment requested</div>
              <div style={{ fontWeight: "bold", color: "#000000", fontSize: "14px", fontFamily: "Roboto", marginTop: "15px", marginLeft: "20px" }}>₹  {numberWithCommas(item[0]?.data?.Payment_Requested ? item[0]?.data?.Payment_Requested : 0)}</div>
            </div>
          </div>
        )
      }
    },
    columnStyle: {
      radius: [3, 3, 0, 0],
    },
    meta: {
      Percentage: { alias: "Percentage(%)" },
    },

    color: ["#9876E6", "#FEA5DD"],
    legend: false,
  };
  return <Column {...config} />;
};

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, isMonthOrWeek, growthSummary, dashboardData } = state.userManagement;
  return { tab, dashBoardRole, dashboardData, isMonthOrWeek, growthSummary };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(DemoColumn);
