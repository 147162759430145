import Modal from "antd/lib/modal/Modal";
import StartDate_Picker from "./dueDatePicker";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import {
  getStudentsDetailsById,
  requestTransaction,
} from "actions/userManagement";
import { getFeeOverView } from "actions/feeManagement";
import moment from "moment";

function PaymentRequestModal({
  item,
  setShowRequestModal,
  isModalVisible,
  requestTransaction,
  getStudentsDetailsById,
  getFeeOverView,
  studentCourseDetails
}) {
  console.log("AA-item", item);
  const [dueDate, setDueDate] = useState(item?.dueDate);
  const [btnLoader, setBtnLoader] = useState(false);
  useEffect(() => {
    setDueDate(item?.dueDate?.split('-').reverse().join('-'))


  }, [item?.dueDate])
  return (
    <div>
      <Modal
        className="paymentRequestModalStyle"
        title="Basic Modal"
        visible={isModalVisible}
        closable={false}
      // onOk={handleOk}
      // onCancel={handleCancel}
      >
        <div style={{ fontFamily: "roboto" }}>
          <div
            style={{ textAlign: "center", fontWeight: "600", fontSize: "18px" }}
          >
            Request Payment
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Due date
              </p>
              <div style={{ width: "300px" }}>
                <StartDate_Picker
                  value={dueDate ? dueDate : null}
                  setCourseStartDate={setDueDate}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
              marginBottom: "-20px",
            }}
          >
            <Button
              onClick={() => {
                setShowRequestModal(false);
              }}
              style={{ color: "#636363", fontWeight: "550", fontSize: "12px" }}
            >
              CANCEL
            </Button>
            <Button
              loading={btnLoader}
              // disabled={dueDate ? false : true}s
              onClick={() => {
                setBtnLoader(true);
                requestTransaction(
                  () => {
                    setBtnLoader(false);
                  },
                  () => {
                    getStudentsDetailsById();
                    // getFeeOverView();
                    setShowRequestModal(false);
                  },
                  item?.id,
                  dueDate
                );
              }}
              style={{
                color: "white",
                backgroundColor: "#594099",
                fontWeight: "550",
                fontSize: "12px",
              }}
            >
              REQUEST
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// export default PaymentRequestModal;
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus, studentCourseDetails } =
    state.userManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    studentCourseDetails
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelTransaction: (callback, successCallback) =>
    dispatch(cancelTransaction(callback, successCallback)),
  fetchTransaction: () => dispatch(fetchTransaction()),
  requestTransaction: (callback, success, id, dueDate) =>
    dispatch(requestTransaction(callback, success, id, dueDate)),
  getStudentsDetailsById: (callback) =>
    dispatch(getStudentsDetailsById(callback)),
  getFeeOverView: () => dispatch(getFeeOverView()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentRequestModal);
