import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from 'antd'
import { Api } from 'services';
import '../userHomeStyles.css';
import RatingStars from '../ratingStars';
import { Button } from 'components/Button';
import { getCartCount, impressionEngagementIncrement, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem, storeSingleBuyNow } from 'actions/studentMarketPlace';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import saveOutlined from "../../../Assets/aulasMarket/notSavedBookmark.svg"
import saveFilled from "../../../Assets/aulasMarket/savedBookmark.svg"
import studyMaterialImg from '../../../Assets/aulasMarket/studyMaterialImg.svg'
import testDefaultImg from '../../../Assets/aulasMarket/testImg.svg'
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';

import { motion } from 'framer-motion';
import FreeCourseConfirmationModal from './freeConfirmationModal';
import YtPlayer from 'components/ytPlayer';
import { setEnrolledItemId, setShowLoginModal } from 'actions/loginNew';

function NewListViewCards({ item, postAddSaveItem, postAddCartItem, removeSaveItem, user, postCourseEnrollNow, getCartCount, impressionEngagementIncrement, setShowDownloadAppModal, setShowLoginModal,setEnrolledItemId }) {
    const history = useHistory()
    const [shareUrl, setShareUrl] = useState(null);
    const [saved, setSaved] = useState({});
    const [cart, setCart] = useState({});
    const [enroll, setEnroll] = useState({})
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [isSavedClicked, setIsSavedClicked] = useState(false);
    const [enrollLoader, setEnrollLoader] = useState(false);
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)
  

    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [item?.id]: item?.inSaved == 1 ? true : item?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [item?.id]: item?.owned == 1 ? true : item?.owned == 0 ? false : null,
        }));
    }, [item?.owned, item?.inSaved])

    const handleSaveForLater = (id, itemType) => {

        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        setIsSavedClicked(!isSavedClicked)
        if (user?.accessToken) {
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login');
        }
    };
    const handleAddToCart = (id, itemType) => {

        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        if (user?.accessToken) {

            postAddCartItem(id, itemType, (res) => {
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    setAddToCartClicked(true)
                }
            });
        } else {
            history.push('/login');
        }
    };
    const handleEnroll = (id, itemType) => {
        let params = {
            id: id,
            type: 'engagement',
            courseType: itemType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })

        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    setFreeConfirmModal(false)
                    // history.push('/courses-list')
                }
                setShowDownloadAppModal(true)
            });
        } else {
            history.push('/login');
        }
    };
    console.log("item-06", item);

    const handleCardClick = () => {
        let params = {
            id: item?.id,
            type: 'impression',
            courseType: item?.resourceType
        }
        impressionEngagementIncrement(params, () => { setEnrollLoader(false) })
        history.push({
            pathname: "/resource-details",
            state: {
                id: item?.id,
                resourceType: item?.resourceType
            }
        })

    }
    function formatDuration(duration) {
        console.log("item?.plannedHours1", duration);
        
        if (!duration) {
            return '0 hour'; // Return "0 hour" when no duration is provided
        }
    
        // Split the duration into hours, minutes, and seconds
        const [hours = '0', minutes = '0', seconds = '0'] = duration.split(':');
        
        // Convert minutes and seconds into minutes
        const totalMinutes = parseInt(minutes, 10);
        const totalSeconds = parseInt(seconds, 10);
        let totalHours = parseInt(hours, 10);
        let result = '';
    
        // Check the conditions for minutes and seconds
        if (totalMinutes === 30 && totalSeconds === 0) {
            // Exact 2:30:00 case
            totalHours += 0.5;
            result = `${totalHours} ${totalHours > 0 ? 'hours' : 'hour'}`;  // Use "hour" for 0, "hours" for >0
        } else if (totalMinutes === 30 && totalSeconds > 0) {
            // 2:30:01 and above case (e.g., 2:30:47)
            totalHours += 0.5;
            result = `${totalHours}+ ${totalHours > 0 ? 'hours' : 'hour'}`;  // Use "hours" for non-zero values
        } else if (totalMinutes >= 45) {
            // 2:45:00 and above
            totalHours += 0.5;
            result = `${totalHours}+ ${totalHours > 0 ? 'hours' : 'hour'}`;
        } else if (totalMinutes > 0 && totalMinutes < 30) {
            // 2:15:00 to 2:29:59
            totalHours += 1;
            result = `${totalHours}+ ${totalHours > 0 ? 'hours' : 'hour'}`;
        } else {
            // Handle exact hours like 2:00:00
            result = `${totalHours} ${totalHours > 0 ? 'hours' : 'hour'}`;  // Use "hours" if >0, "hour" if 0
        }
    
        return result;
    }
    const [freeCourseName,setFreeCourseName]=useState('')
    const [freeCourseType,setFreeCourseType]=useState('')
    const [freeConfirmModal,setFreeConfirmModal]= useState(false)


    const handleFree=()=>{
        handleEnroll(item?.id, item?.resourceType)
    }
    
    const [isHover,setIsHover]=useState(false)

    const handleMouseHover=()=>{
        setIsHover(true)
    }
    const handleMouseUnHover=()=>{
        setIsHover(false)
    }

    
    function getDurationText(duration) {
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        if (hours < 1) {
            return false;
        }
        return hours === 1 ? `${hours} hour` : `${hours} hours`;
    }

    function isDurationGreaterThanOrEqualOneHour(duration) {
        const [hours] = duration.split(':').map(Number); 
        return hours >= 1; 
    }
    
    
    
    return (
        <div onMouseEnter={handleMouseHover} 
        onMouseLeave={handleMouseUnHover} className='course-new-card' onClick={() => { handleCardClick() }} style={{cursor:"pointer"}}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", width: "100%", gap: 30 }}>
                    <div style={{ position: "relative" }}>
                        <div style={{ borderRadius: 10, overflow: "hidden" }}>
                        {isHover && item?.preview ? (
        <>
            <div style={{ height: "25dvh" }} >
                <YtPlayer keyboardControls={true}  videoUrl={`${Api.proDlS3Url}${item?.preview}`} hoverEffect={isHover} />
            </div>

        </>) : (
        <>
            <motion.img
                src={item?.image ? `${Api.dlS3Url}${item?.image}` : item?.resourceType == "testSeries" ? testDefaultImg : studyMaterialImg}
                loading="lazy"
                alt="blogs-image"
                style={{ width: "100%", height: 178 }}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                whileHover={{ scale: 1.08 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
            />
        </>)}
                        </div>
                        {item?.mode ? <div className={`${item?.mode == 'online' ? 'online-course-mode' : item?.mode == 'hybrid' ? 'hybrid-course-mode' : 'offline-course-mode'}`}>{item?.mode?.charAt(0)?.toUpperCase() + item?.mode?.slice(1)}</div> : <></>}

                    </div>
                    <div style={{ marginTop: -12 }}>
                        <div className='course-name-div' >
                            <Tooltip title={item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}>
                                <div style={{ width: '100%', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, fontWeight: 400, WebkitBoxOrient: "vertical", color: "#191919", fontSize: 16 }}>{item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName}</div>
                            </Tooltip>
                        </div>
                        <div className='m-t-5'>
                            {item?.resourceType === "videoResource" ? (
                                <div className='' style={{ color: "#636363" }}>
                                    {item?.duration == '00:00:00' ? '' : getDurationText(item?.duration)  } {<>
                                    {isDurationGreaterThanOrEqualOneHour(item?.duration) && item?.quizCount != 0 ? '| ' : '' } 
                                     { item?.quizCount!= 0 ? item?.quizCount == 1 ? `${item?.quizCount} quiz` : `${item?.quizCount} quizzes` : ''}</>} {<>
                                     {item?.quizCount != 0 && item?.notesCount!= 0 ? '| ' : ''}
                                     { item?.notesCount!= 0 ? item?.notesCount == 1 ? `${item?.notesCount} note` : `${item?.notesCount} notes` : ''} </>}
                                </div>
                            ) :  item?.resourceType == 'course' ? (<>
                                <div className='' style={{ color: "#636363",height:22 }}>
                                        {<>{ item?.plannedClasses != 0 ? item?.plannedClasses == 1 ? `${item?.plannedClasses} class` : `${item?.plannedClasses} classes` : ''}
                                        {item?.plannedClasses != 0 &&  item?.plannedTests != 0 ? ' | ' : ''}
                                          </>}  { <>
                                            { item?.plannedTests != 0 ? item?.plannedTests == 1 ? `${item?.plannedTests} test` : `${item?.plannedTests} tests` : ''}
                                             </>} { <>
                                                {item?.plannedHours != '0.00' &&  item?.plannedTests != 0 ? '| ' : ''}
                                                {item?.plannedHours != '0.00' ? formatDuration(item?.plannedHours) : ''}
                                                </>}
                                    </div>
                                </>):
                                item?.resourceType == "studyMaterial" ? (
                                    <>
                                     <div className='' style={{ color: "#636363",height:22 }}>
                                            {<>{ item?.m_count != 0 ? item?.m_count == 1 ? `${item?.m_count} material` : `${item?.m_count} materials` : ''}
                                            { item?.m_count != 0  && item?.q_count != 0 ? ' | ' : '' }
                                             </>}  {<>
                                                { item?.q_count != 0 ? item?.q_count == 1 ? `${item?.q_count} quiz` : `${item?.q_count} quizzes` : ''}   </>}
                                        </div>
                                    </>):
                                     item?.resourceType == "testSeries"? (
                                        <>
                                         <div className='' style={{ color: "#636363",height:22 }}>
                                                {<>{item?.tCount == 1 ? `${item?.tCount} test` : `${item?.tCount} tests`} </>}
                                            </div>
                                        </>): (
                                <div className='' style={{ color: "#636363", marginTop: 10 }}></div>
                            )}
                        </div>
                        {item?.resourceType == "testSeries"
                            ?
                            <div className='m-t-5' style={{ display: "flex" }}>
                                <div style={{ color: "#FFA931", fontSize: 14, margin: "1px 0px 0px 2px", fontWeight: 700, marginRight: 5 }}>{item?.ratingValue ? item?.ratingValue : 0}</div>
                                <RatingStars star={item?.ratingValue} />
                                <div style={{ color: "#AEAEAE", fontSize: 12, margin: "1px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : `(0)`}</div>
                            </div>
                            :
                            <div className='m-t-5' style={{ display: "flex" }}>
                                {console.log("item?.ratingValue", item)}
                                <div style={{ color: "#FFA931", fontSize: 14, margin: "1px 0px 0px 2px", fontWeight: 700, marginRight: 5 }}>{item?.rating ? item?.rating : 0}</div>
                                <RatingStars star={item?.rating} />
                                <div style={{ color: "#AEAEAE", fontSize: 12, margin: "1px 0px 0px 10px", fontWeight: 600 }}>{item?.ratingCount > 0 ? `(${item?.ratingCount})` : `(0)`}</div>
                            </div>
                        }
                        <div style={{ marginTop:item?.resourceType === "videoResource" || item?.resourceType == "testSeries" || item?.resourceType == "studyMaterial" ? 40: 55 }}>
                        {(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount || 0)) > 0 ? (
                            <div style={{ fontSize: 14, color: "#594099", padding: "3px 5px", fontWeight: 'bold', bottom: 10, right: 10 }}>
                                ₹{(parseFloat(item?.amount || 0) - parseFloat(item?.discountAmount || 0)).toFixed(2)}{" "}
                                {item?.discountAmount > 0 && (
                                    <span style={{ color: "#636363", fontWeight: 400, textDecoration: 'line-through', fontSize: 14 }}>
                                        ₹{parseFloat(item?.amount || 0).toFixed(2)}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", bottom: 10, right: 10, backgroundColor: "#FF414D", width: "50px", height: 25 }}>
                                <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                            </div>
                        )}
                        </div>
                    </div>

                </div>
                <div style={{ height: 10, width: '53%', }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 20 ,marginTop: item?.resourceType == "testSeries" || item?.resourceType == "studyMaterial"? 93 : 110}}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: 150, marginTop:item?.resourceType === "videoResource" || item?.resourceType == "testSeries" || item?.resourceType == "studyMaterial"? 12 : 14 }}>

                            <img src={require("../../../Assets/aulasMarket/shareIcon-copy.svg").default} style={{cursor:'pointer', marginLeft: item?.owned == 1  ? 80 : user?.role?.includes("Operation") || user?.role?.includes('SuperAdmin') || user?.role?.includes('Admin') ? 320 : 20, height: 27, width: 23 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${item?.resourceType}`)
                                }} />
                            {user?.role?.includes("Student") || user?.accessToken == undefined?
                                <>
                                    {item?.owned == 1 ? <></> :
                                        <div style={{ marginTop: -3,cursor:'pointer', }} onClick={(e) => {
                                            e.stopPropagation();
                                            if (user.accessToken) {
                                                handleSaveForLater(item?.id, item?.resourceType)
                                            } else {
                                                // history.push("/login")
                                                setShowLoginModal(true)
                                            }
                                        }}><img src={addCartClicked ? saveOutlined : saved[item?.id] || (isSavedClicked && item?.isSaved == 0) ? saveFilled : saveOutlined} style={{ height: 33, width: 23 }} /></div>}
                                </> : <></>}

                        </div>
                        <div style={{  width: "100%" }}>
                            {item?.disableAdmission ?
                                <center>
                                    <div className='r-c-c' style={{ fontSize: "15px", color: "#FF414D", width: "100%", height: "35px", borderRadius: "20px", marginTop: 5, fontWeight: 600 }}>Admission closed</div>
                                </center> : <>
                                    {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
                                        <center className='m-t-10'>
                                            {item?.owned == 1 || enroll[item?.id] ?
                                                <div className='marginTop' >
                                                    <Button style={{ position: 'relative', overflow: 'hidden', border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "100%", padding: "5px 10px", borderRadius: 5, }}>
                                                        <div className='glare'></div>

                                                        VIEW COURSE</Button>
                                                </div>
                                                :
                                                <>
                                                    {item?.amount == 0 || item?.feeType == "free" ?
                                                        <Button loading={enrollLoader} className='m-t-5' type="primary" style={{ position: 'relative', overflow: 'hidden', width: "100%", padding: "5px 10px", borderRadius: 5, marginTop: 10 }}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                if (user?.accessToken) {
                                                                    if (enroll[item?.id]) { } else if (item?.feeType == "free") {
                                                                        setFreeConfirmModal(true)
                                                                        setFreeCourseType(item?.resourceType)
                                                                        setFreeCourseName(item?.resourceType == "studyMaterial" || item?.resourceType == "testSeries" ? item?.name : item?.courseName)
                                                                       
                                                                    }
                                                                } else {
                                                                    // history.push("/login")
                                                                    if(item?.feeType == "free"){
                                                                        setEnrolledItemId({id:item?.id,type:item?.resourceType})
                                                                    }   
                                                                    setShowLoginModal(true)
                                                                }
                                                            }}
                                                        >
                                                            <div className='glare'></div>

                                                            {enroll[item?.id] ? "ENROLLED" : "ENROLL NOW"}</Button>
                                                        :
                                                        <div className='r-c-sb m-t-8' style={{ width: "100%" }}>
                                                            {item?.resourceType == "course" ? null :
                                                                <Button loading={addCartLoader} loaderArgColor="#594099" style={{ position: 'relative', overflow: 'hidden', border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", width: "47%", padding: "5px 10px", borderRadius: 5, }} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    if (!user?.accessToken) {
                                                                        // history.push("/login")
                                                                        setShowLoginModal(true)
                                                                    } else if ((item?.inCart == 1 || cart[item?.id]) && user?.accessToken) {
                                                                        history.push("/cart")
                                                                    } else {
                                                                        setAddCartLoader(true)
                                                                        handleAddToCart(item?.id, item?.resourceType)
                                                                        getCartCount()
                                                                    }
                                                                }}>
                                                                    <div className='glare'></div>

                                                                    {item?.inCart == 1 || cart[item?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                                            }
                                                            <Button
                                                                type="primary"
                                                                style={{
                                                                    position: 'relative',
                                                                    width: item?.resourceType === "course" ? "100%" : "47%",
                                                                    padding: "10px 10px",
                                                                    borderRadius: 5,
                                                                    marginTop: item?.resourceType === "course" ? 3 : 0,
                                                                    overflow: "hidden"
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (user?.accessToken) {
                                                                        if (item?.feeType === "free") {
                                                                            handleEnroll(item?.id, item?.resourceType);
                                                                        } else {
                                                                            history.push({
                                                                                pathname: '/user-checkout',
                                                                                state: { itemId: item?.id, itemType: item?.resourceType }
                                                                            });
                                                                        }
                                                                    } else {
                                                                        // history.push("/login");
                                                                        if(item?.feeType == "free"){
                                                                            setEnrolledItemId({id:item?.id,type:item?.resourceType})
                                                                        }   
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }}
                                                            >
                                                                <div className='glare'></div>
                                                                {enroll[item?.id] ? "ENROLLED" : item?.feeType === "free" || item?.resourceType === "course" ? "ENROLL NOW" : "BUY NOW"}
                                                            </Button>

                                                        </div>
                                                    }
                                                </>
                                            }
                                        </center>
                                    </> : <></>}
                                </>}
                        </div>
                    </div>
                </div>
            </div>
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl} />}
                      {freeConfirmModal && <FreeCourseConfirmationModal
            freeConfirmModal={freeConfirmModal}
            setFreeConfirmModal ={setFreeConfirmModal}
            freeCourseType={freeCourseType}
            freeCourseName={freeCourseName}
            handleFree={handleFree}/>}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    const { user } = state.session
    return { userMarketPlaceHomeData, user };
};

const mapDispatchToProps = (dispatch) => ({
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    storeSingleBuyNow: (res) => dispatch(storeSingleBuyNow(res)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCartCount: () => dispatch(getCartCount()),
    impressionEngagementIncrement: (params, callback) => dispatch(impressionEngagementIncrement(params, callback)),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
    setEnrolledItemId: (val) => dispatch(setEnrolledItemId(val)),

});
export default connect(mapStateToProps, mapDispatchToProps)(NewListViewCards);
