import React, { useEffect } from 'react'
import { AlipayCircleOutlined, ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import SearchBox from './localComponents/searchBox';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSubjectChapters, updateFilter, setOpenTab, updateListViewType, createTestModal } from 'actions/practiseTest';
import PageDataLoader from 'components/pageDataLoader';
import CreateTestModal from 'pages/practiseTest/modals/createTestModal';



function NodataCard({
  containerheight = "100%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, /* marginBottom: "20px" */ }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}

const bodyStyle = {backgroundColor:"#FAFAFA", padding:20, margin: -20, height: '105%', overflowY: 'scroll'}
    


    const PageHeader = ({ goBack, onSearch, searchVal,  filter, selectedClassroom, selectedSubject, onCreateClick }) => {
        return (
          <div className="resultPageHeader full-width">
            <div className="r-c-fs m-r-30">
              <ArrowLeftOutlined onClick={goBack} style={{ fontSize: 18 }} className="cursor-pointer"/>
              <div className="text-md bold-700 m-l-20"> 
                <div>
                  {selectedSubject?.subjectName}
                </div>
                <div style={{color:"#AEAEAE", fontSize:12}}>
                {selectedClassroom} / {filter?.selectedSyllabus}
                </div>
              </div>
            </div>
            <div className="testfiltersgroup m-r-50">
               <SearchBox loading={false} onChange={onSearch}  value={searchVal} className="m-r-10" placeholder="Search by chapter name" style={{width:230}}/>
               <Button onClick={onCreateClick} className="purpleBtnWrap radius-100 purpleButtonHoverEffect m-r-50">
               <PlusCircleOutlined />CREATE TEST
               </Button>
            </div>
          </div>
        );
      };

    const ChapterCard = ({chapterNo, chapterName, noOfTests, history, updateFilter, setOpenTab, DATA}) => {
        return(
            <>
            <div onClick={()=> 
            {
              updateFilter('selectedChapter', DATA);
              updateFilter('isVA', false);
              updateFilter('isTabPage', true);
              updateFilter('tabSearch', null);
              updateFilter('isTAdatefiltered', false);
              updateFilter('taDate', null);
              updateFilter('testTypeSelector', null);
              setOpenTab('draft');
              history.push("practise-test-tabs-page")}} className='m-t-30 m-l-20 customCardShadow cursor-pointer shadow-box hover-shadow ' style={{
                height:130,
                width: 300,
                borderRadius: 10,
                backgroundColor:"#FFFFFF",
                border: "1px solid #E6E6E6"}}>
                <div className='m-l-20 m-t-20'>
                    <div  style={{color: "#636363", }}>{chapterNo}</div>
                    <div className='m-t-10 bold-700 text-overflow-ellipsis'>{chapterName}</div>
                    <div className='m-t-20 m-l-20' style={{color: "#191919", fontSize: 12}} >{noOfTests}</div>
                </div>
            </div>
            </>
        )

    }

    let timer = false

const PracChapterPage = ({fetchSubjectChapters, chapterList, filter, updateFilter, setOpenTab, selectedSubject, selectedClassroom, 
  loaders, createTestModal, updateListViewType, createTestModalBool, user}) => {

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }

  useEffect(() => {
    fetchSubjectChapters()
  }, [])

  const onSearch = (e) => {
    updateFilter('chapterSearch', e.target.value);
  }
  useEffect(()=>{
    clearTimeout(timer);
    timer = setTimeout(()=>{
      fetchSubjectChapters(()=>{});
    }, 1000);
  }, [filter?.chapterSearch]);

  const onCreateClick = () => {
    updateListViewType('create');
    createTestModal('test-create', user, 'subject');
  }

  return (
    <div style={bodyStyle}>
        <PageHeader
                filter={filter}
                selectedSubject={selectedSubject}
                selectedClassroom={selectedClassroom.name}
                goBack={goBack}
                onSearch={onSearch}
                onCreateClick={onCreateClick}
        />
        <div className='display-flex flex-wrap'>
        {Array.isArray(chapterList)?
         chapterList?.map((item, index)=>(
        <ChapterCard
                DATA={item}
                chapterNo={`Chapter ${item.chapterNo}`}
                chapterName={item?.chapterName}
                noOfTests={item?.testCount}
                setOpenTab={setOpenTab}
                history={history}
                updateFilter={updateFilter}
        />)):null}
        </div>
        <PageDataLoader visible={loaders?.chapterPageLoader}/>
        {createTestModalBool && <CreateTestModal history={history} />}
        {chapterList && chapterList?.length === 0 ? <NodataCard/> : null}
    </div>
  )
}

const mapStateToProps = state =>{
  const {chapterList, filter, selectedSubject, selectedClassroom, loaders, createTestModalBool }=state.practiseTest
  const {user} = state.session;
  return {chapterList,filter, selectedSubject, selectedClassroom, loaders, createTestModalBool, user}
}

const mapDispatchToProps = dispatch=> ({
  fetchSubjectChapters:()=>dispatch(fetchSubjectChapters()),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  setOpenTab: (tab) => dispatch(setOpenTab(tab)),
  updateListViewType: (data)=>dispatch(updateListViewType(data)),
  createTestModal: (name, user, source) => dispatch(createTestModal(name, user, source)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PracChapterPage)