
import React, { useEffect, useState } from 'react';
import { Api } from 'services';
import { Upload, message, Progress, Typography } from 'antd';
import { LoadingOutlined, FilePdfOutlined, DeleteFilled, FileOutlined, FileAddOutlined } from '@ant-design/icons';
import { updatepdf, updateTotalUnusedStorageSize } from 'actions/digitalLibrary';
import { connect } from 'react-redux'
import { Button } from 'components/Button';

function Uploadpdf({ Pdftoupload, updatepdf, type, totalUnusedStorageSize, progressCheck, updateTotalUnusedStorageSize, sizeOfContents, totalStorageSize }) {
  const [loading, setloading] = useState(false)
  const [ispdfupdoaded, setispdfupdoaded] = useState(false)
  const [percent, setPercent] = useState(null)
//   useEffect(() => {

//     let temp=totalStorageSize*1024*1024*1024-parseFloat(sizeOfContents?.sizeOfLn)-parseFloat(sizeOfContents?.sizeOfMp4Video)-parseFloat(sizeOfContents?.sizeOfQb);
  
//     updateTotalUnusedStorageSize(temp)
 
// }, [totalStorageSize,sizeOfContents?.sizeOfLn,sizeOfContents?.sizeOfMp4Video,sizeOfContents?.sizeOfQb])

  function beforeUpload(file) {
    // console.log('file', file)
    let isFile
    let isLt2M
    // console.log('file selected',file)
    if (type != 'video') {
      isFile = (file.type === 'application/pdf');
      if (!isFile) {
        message.error('You can only upload pdf file!');
      }
      else if (isFile) {
        // isLt2M = (file.size / 1024 / 1024 < 70) & ();
        // console.log('isLt2m', !(file.size / 1024 / 1024 < 70))
        if (!(file.size / 1024 / 1024 < 70)) {
          isLt2M = false;
          message.error('File must smaller than 70MB!');
        }
        else if (totalUnusedStorageSize != null && !isNaN(totalUnusedStorageSize) && !(file.size / 1024 / 1024 < (totalUnusedStorageSize / 1024 / 1024))) {
          isLt2M = false;
          message.error(`Please upgrade your storage plan. You have only ${parseFloat(totalUnusedStorageSize / 1024 / 1024).toFixed(2)} MB left.`);
        }
        else {
          isLt2M = true;
        }
      }
    }
    else {
      //for mov file.type=='video/quicktime'
      //for mkv file.type==''
      //for flv file.type=='video/x-flv'

      isFile = (file.type === 'video/mp4' || file.type=='video/avi' || file.type=='video/x-flv' || file.type=='video/quicktime' || file.type === 'video/x-matroska' || file.type=="")
      if (!isFile) {
        message.error('You can only upload mp4/avi/flv/mov/mkv file!');
      }
      else if (file) {
        // isLt2M = file.size / 1024 / 1024 / 1024 < 1;
        if (!(file.size / 1024 / 1024 / 1024 <= 10)) {
          isLt2M = false;
          message.error('File must smaller than 10 GB!');
        }
        else if (totalUnusedStorageSize != null && !isNaN(totalUnusedStorageSize) && !(file.size / 1024 / 1024 < (totalUnusedStorageSize / 1024 / 1024))) {
          isLt2M = false;
          message.error(`Please upgrade your storage plan. You have only ${parseFloat(totalUnusedStorageSize / 1024 / 1024).toFixed(2)} MB left.`);
        }
        else {
          isLt2M = true;
        }
      }
    }
    // const isJpgOrPng = (file.type === 'application/pdf');
    // console.log('isJpgOrPng', isJpgOrPng)
    // if (!isJpgOrPng) {
    //   message.error('You can only upload pdf file!');
    // }
    // const isLt2M = file.size / 1024 / 1024 < 70;
    // if (!isLt2M) {
    //   message.error('File must smaller than 70MB!');
    // }
    if (isFile && isLt2M) {
      updatepdf(file)
    }
    return isFile && isLt2M;
  }

  const handleChange = (info) => {
    // if (info.file.status === 'uploading') {
    //   setPercent(info.file.percent)
    //   setloading(true)
    //   return;
    // }
    // setloading(false)
    // setispdfupdoaded(true)
    // if (info.file.status === 'done') {

    // }
  };

  const uploadButton = () => {
    if (!Pdftoupload) {
      return (
        <div 
        className='uploadfilesinside'
        style={{ width:type === 'video'? "120%" : "210%",height:"450px"}}
        >
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '12%' }}>
            <div style={{ marginBottom: '5px',marginTop:type === 'video'? "26%" : "35%" }}>
              {type != 'video' ? <FilePdfOutlined
                style={{ color: '#1089FF', fontSize: '300%' }}
              /> : <FileAddOutlined style={{ color: '#1089FF', fontSize: '300%' }} />}
            </div>
            <p style={{ margin: '0px' }}>Drag and drop files here</p>
            <p style={{ margin: '0px' }}>Files supported: {type == 'video' ? '' : 'PDF'}</p>
            {type==='video'?
            <p style={{margin:"0px"}}>MP4/AVI/FLV/MOV/MKV</p>
            :null
            }
          </div>
          <div style={{ marginTop: type === 'video'? '11%' : "15%", alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '10%', width: '100%' }}>
            <Button
              style={{ marginTop:"35%", background: '#E6E6E6 0% 0% no-repeat padding-box' }}
              type="default"
            >
              CHOOSE FILE
            </Button>
            <p style={{ fontSize: '12px', font: 'normal normal normal 12px/26px Roboto', color: '#AEAEAE', opacity: '1' }}>Maximum size: {type != 'video' ? '70 MB' : '10 GB'}</p>
            {type === 'video' &&
            <>
            <p style={{color:"#FF414D", fontSize:12}}>Note: Since we support multi resolution streaming,</p>
            <p style={{color:"#FF414D", fontSize:12}}> the file size may vary once uploaded.</p>
            </>}
          </div>
          <div style={{ marginTop: '80%' }}>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className='uploadfilesinside'  style={{ width:"330px",height:"450px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
          <div style={{ marginTop: '0',width:"100%", alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '10%' }}>
            <div style={{ marginBottom: '5px' }}>
              {type != 'video' ? <FilePdfOutlined
                style={{ color: 'red', fontSize: '300%' }}
              /> : <FileOutlined style={{ color: 'red', fontSize: '300%' }} />}
            </div>
            <p style={{ margin: '0px' }}>{Pdftoupload.name}</p>
            <Typography.Text style={{ margin: '0px',width:"150px", textAlign:"center" }} ellipsis={{tooltip:true}}>{Pdftoupload.name}</Typography.Text>
            <p style={{ margin: '0px' }}>{(Pdftoupload.size / 1024 / 1024).toFixed(2).toString() + ' MB'}</p>
          </div>
          <div style={{ marginTop: '20%', alignItems: 'center', display: progressCheck ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'center', height: '10%', width: '100%' }}>
            <div style={{ marginLeft:"-30px",width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', paddingRight: '5px' }}><DeleteFilled onClick={() => updatepdf(null)} style={{ color: '#AEAEAE' }} /> </div ><div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}><a style={{ paddingLeft: '5px', marginBottom: '0px', color: '#AEAEAE', font: 'normal normal normal 12px/26px Roboto', fontSize: '12px', opacity: '1' }} onClick={() => updatepdf(null)}>Remove</a></div>
          </div>
        </div>
      )
    }
  }

  return (
    <div 
        style={{  border: "1px dashed #1089ff",backgroundColor:"#f3f9ff",width:"100%",height:"100%",display:"flex"}}
        // style={{backgroundColor:'black'}}
    >
      {
        progressCheck ?
        <div style={{display:"flex",width:"100%",height:"100%",alignContent:"center",justifyContent:"center",paddingTop:"45%"}} className="avatar-uploader">Please wait...</div> :
        <div style={{width:"100%"}}>
        <Upload
          name="avatar"
          className="avatar-uploader"
          showUploadList={false}
//           Avi
// FLV
// Mov
// Mkv
          accept={type != 'video' ? 'image/*,.pdf' : 'video/*,.mp4,.avi,.flv,.mov,.mkv'}
          // action={`${Api._base_url}/api/mock-api/mock-upload`}
          beforeUpload={beforeUpload}
          style={{width:"100%"}}
        // onChange={(e) => handleChange(e)}
        >
          <div style={{width:"100%"}}>
          {
          // loading == true ?
            // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            //   {/* <LoadingOutlined /> */}
            //   <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            //     <Progress percent={parseInt(percent)} type='circle' strokeColor={{
            //       '0%': '#108ee9',
            //       '100%': '#87d068',
            //     }}
            //       strokeWidth={3}
            //     />
            //     <div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>Preparing to upload</div>

            //   </div>
            // </div>
            // : 
            !progressCheck ?
            <div style={{width:"100%"}}> {uploadButton()}</div> : ''}
            </div>
        </Upload>
        </div>
      }
    </div>
  );
}
const mapStateToProps = (state) => {
  const {
    Pdftoupload,
    totalUnusedStorageSize,
    sizeOfContents,
    totalStorageSize
  } = state.digitalLibrary;
  return {
    Pdftoupload,
    totalUnusedStorageSize,
    sizeOfContents,
    totalStorageSize

  };
};

const mapDispatchToProps = (dispatch) => ({
  updatepdf: (data) => dispatch(updatepdf(data)),
  updateTotalUnusedStorageSize:(val)=>dispatch(updateTotalUnusedStorageSize(val))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps

)(Uploadpdf);



