import { Avatar, Progress, Typography, Tooltip, Dropdown, Menu, Modal, Input } from 'antd'
import React, { useState } from 'react'
import { LinkOutlined, LoadingOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons'
import { MdCastConnected, MdMoreVert } from 'react-icons/md'
import { Api, Color, Notification } from 'services'
import moment from 'moment'
import { connect } from 'react-redux'
import { SquareSubIcon } from 'components/subIcon'
import { IoMdChatbubbles, IoMdVideocam } from 'react-icons/io'
import { RiWirelessChargingLine } from 'react-icons/ri'
import { postEmbedLink, setStreamId } from 'actions/broadcast'
import { fetchStudentListData, joinClass, setBroadcastSelectedClassroom, setCardData, setJoinLoader, setLiveStreamUrl, setSelectedId } from 'actions/schedule'
import { useHistory } from 'react-router-dom'
import { FaGraduationCap } from 'react-icons/fa'
import { handleJoinClass } from './handleJoinClass'
import Conference  from "Assets/schedule/confrence.svg"
import Broadcast  from "Assets/schedule/Broadcast.svg"
import Offline from "Assets/schedule/ofline.svg"
import OtherColor from "Assets/schedule/other_color.svg"
import YTPlayer from 'components/ytPlayer'
import { EventRegister } from 'react-native-event-listeners'
import UserAvatar from 'components/userAvatar'
import AnimatedTextField from 'components/AnimatedTextField'
import { Button } from 'components/Button'
const { Text } = Typography

const checkCirlce = require("../../Assets/scheduleAttendance/CheckCircle(1).svg").default
const menuMore = require("../../Assets/ic-more.svg").default
const avattar = require("../../Assets/Group 11416.svg").default
export const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
export const regExpLive = /^https:\/\/(www\.)?youtube\.com\/live\/[a-zA-Z0-9_-]+(\?.*)?$/;
export const regexMeet = /^https:\/\/meet\.google\.com\/([a-z]{3}-[a-z]{4}-[a-z]{3}(-[a-z])?|lookup\/[a-z0-9-]+)(\?authuser=\d+)?$/;
export const regexstudio = /^https:\/\/(www\.)?studio\.youtube\.com\/video\/[a-zA-Z0-9_-]+\/livestreaming(\?.*)?$/;
// const regexyt = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
export const EmbedModal = ({visible=false, onClose=()=>{}, onOk=()=>{}, embedding=false, adding=true}) => {
    const [link, setlink] = useState(null);
    const [matching, setMatch] = useState(false);
    const linkedchanged = (e) => {
        let text = e.target.value
        if (text.match(regExpLive)){
            let videoId = text?.split('?')?.[0]?.split('/')?.[4]
            text = `https://www.youtube.com/watch?v=${videoId}`
        }else if(text.match(regexstudio)){
            let videoId = text?.split('?')?.[0]?.split('/')?.[4]
            text = `https://www.youtube.com/watch?v=${videoId}`
        }
        setlink(text)
        if (text.match(regExp)){
            let match = text.match(regExp) 
            setMatch(match[2].length==11)
        }else if(text.match(regexMeet)){
            setMatch(false)
        }
        else{
            setMatch(false)
        }
        
    }
    return (
        <Modal
        centered
        className='modal-round-corner'
        style={{padding:0}}
        visible={visible}
        onCancel={onClose}
        onOk={onOk}
        closable={false}
        closeIcon={()=>(<></>)}
        footer={null}
      >
          <div className='r-c-c' style={{color:"black", fontSize:18, fontWeight:"bold"}}>{adding?'Add link':'Replace link'}</div>
          <div className='r-c-c' style={{color: "#636363", fontSize:12, marginBottom:20}}>Please add the livestream link below</div>
          <div style={{ width: '100%' }}>        
                    <AnimatedTextField
                        label="Livestream link"
                        value={link}
                        handleInputChange={linkedchanged}
                        isMandatory={true}
                        inputstyle={{fontSize:12, fontWeight:"normal", color:"black"}}
                        inputId="link"
                        isDisabled={false}
                    />
            <p style={{ fontSize: '10px', font: 'normal normal normal 10px/26px Roboto', opacity: '1', color: '#AEAEAE', marginTop: 0 }}>Example: http://www.websitename.com/watch?v=2Qsg3rQcfIE</p>
            <div className='r-c-c' style={{ marginBottom: '1px', fontSize: '12px', color: '#FF414D', font: 'normal normal bold 12px/16px Roboto' }}>
                { link === null || link === "" || link?.match(regExp) || link?.match(regexMeet) ? null : (<>Invalid youtube link</>)}
            </div>
          </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            link && matching?
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
            <YTPlayer keyboardControls={true}  top={"calc(50% - 27px)"} isLive={true}  videoUrl={link}/>
            </div>
              : null
          }
        </div>
        <div className='r-c-c' style={{marginTop:15}}>
            <Button style={{ width: "160px", marginRight: "45px", borderRadius: "8px"}} onClick={onClose}>CANCEL</Button>
            <Button type='primary' style={{ width: '160px', borderRadius: '8px' }} onClick={()=>{onOk(link)}} disabled={!(link && (link?.match(regExp)||link?.match(regexMeet))) || embedding} loading={embedding}>{'SAVE'} </Button>
        </div>
          </Modal>
    )
}
const OngoingClassCard = ({
    onGoingItem, setStreamId, joinClass, setBroadcastSelectedClassroom, setJoinLoader, isJoinLoader, user, fetchStudentListData, setSelectedId, 
    studentAttendanceData, presentStudents, setCardData, postEmbedLink, setLiveStreamUrl
}) => {
    const menu = (
        <Menu style={{ width: "230px", background: "#FFFFFF", boxShadow: "8px 8px 18px #302A5F14", borderRadius: "8px" }}>
            <Menu.Item key="1" onClick={()=>{handleJoin()}}>
                {onGoingItem.sUrl!=undefined?'Replace link':'Add link'} 
            </Menu.Item>
            {onGoingItem?.sUrl != undefined ?
            <Menu.Item key="2" onClick={()=>{playStream()}}>
                Play stream
            </Menu.Item>
            :null}
        </Menu>

    );
    const history = useHistory();
    const playStream = () => {
        if (onGoingItem.sUrl.match(regexMeet)){
            window.open(onGoingItem.sUrl, '_blank');
        }else{
            setLiveStreamUrl(onGoingItem.sUrl)
            history.push('/liveplayer')
        }
    }
    const [embedModalVisible, setEmbedModalVisible] = useState(false);
    const [embedding, setEmbedding] = useState(false);
    const onPlay = () => {}
    const handleContact = () => {
        history.push("/care/chat")
    }

    const [isJoinNowLoading, setIsJoinNowLoading] = useState(false)

    const handleJoin = async () => {
        setCardData(onGoingItem);
        setIsJoinNowLoading(true);
        if (onGoingItem?.mode == "Broadcast") {
            let currentTime = new Date();
            if (onGoingItem.endTime){
                // Parse end time
                const [hours, minutes, seconds] = onGoingItem.endTime.split(':').map(Number);
                let endDateTime = new Date();
                endDateTime.setHours(hours, minutes, 60, 0);

                // Calculate the difference in milliseconds
                let differenceInMilliseconds = endDateTime - currentTime;
                let differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
                if (differenceInSeconds<0){
                    Notification.error("Error", "Class end time has passed")
                    setIsJoinNowLoading(false)
                    return
                }
            }
            setStreamId(onGoingItem?.roomName != null ? onGoingItem.roomName : '1234')
            setBroadcastSelectedClassroom(onGoingItem)
            await joinClass(onGoingItem.id, () => {
                setIsJoinNowLoading(false)
            })
            history.push('/broadcast')
        }
        else if (onGoingItem?.mode == "Zoom 40") {
            await joinClass(onGoingItem.id, () => {
                setIsJoinNowLoading(false)
            })

            // history.push('/zoom')
            Notification.error("Error", "Zoom is not supported on web presently. Please join the class from the mobile.")
        } else if (onGoingItem?.mode == "Offline"||onGoingItem?.mode == "Conference") {
            setIsJoinNowLoading(false)
            Notification.error("Error", "This mode is not supported on web presently")
        }else if(onGoingItem?.mode == "Others") {
            setIsJoinNowLoading(false);
            setEmbedModalVisible(true);
        }
    }

    const markAttendanceHandler = () => {
        setSelectedId(onGoingItem?.id)
        setCardData(onGoingItem)
        fetchStudentListData()
        history.push('/schedule-attendance')

    }

    const onEmbed = async(link) => {
        setEmbedding(true);
        await postEmbedLink({id:onGoingItem.id, sUrl: link})
        setEmbedding(false);
        setEmbedModalVisible(false);
        EventRegister.emit('apicall:scheduleHome');
    }

    const currentTime = moment().format("HH:mm:ss")
    let duration = moment.duration(moment(onGoingItem?.endTime, "HH:mm:ss").diff(moment(onGoingItem?.startTime, "HH:mm:ss")))
    let spendDuration = moment.duration(moment(currentTime, "HH:mm:ss").diff(moment(onGoingItem?.startTime, "HH:mm:ss")))
    let hours = duration.asHours()
    let spendHours = spendDuration.asHours()
    return (
        <div className="ongoingclass-main" style={{position:"relative"}}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div className="ongoingclass-img-text">
                    <div>
                        <SquareSubIcon icon={onGoingItem?.subject?.icon ? onGoingItem?.subject?.icon : ''} name={onGoingItem?.subject?.name ? onGoingItem?.subject?.name : ''} size={40} />
                    </div>
                    <div style={{ width: '100%', marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                        {onGoingItem.mode == "Zoom 40" ?
                            <div style={{ width: '74px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E7F3FF', borderRadius: '8px', padding: '2px' }}>
                                <IoMdVideocam style={{ color: '#1089FF' }} />
                                <Text style={{ fontSize: '10px', color: '#1089FF', marginLeft: '5px' }}>Zoom 40</Text>
                            </div>
                            : onGoingItem.mode == "Broadcast" ?
                                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF4D5', borderRadius: '8px', padding: '3px 7px'}}>
                                    <img src={Broadcast}  style={{ color: '#FFA931', textAlign: 'center',height:"16px",width:"16px" }} />
                                    <Text style={{ fontSize: '10px', color: '#FFA931', marginLeft: '5px' }}>Broadcast</Text>
                                </div>
                                :
                                onGoingItem.mode == "Conference" ?
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F4FE', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={Conference} style={{ color: '#594099',height:"16px",width:"16px" }} />
                                        <Text style={{ color: '#594099', marginLeft: '5px', fontSize: '10px' }}>Conference</Text>
                                    </div>
                                    :
                                    onGoingItem.mode == 'Others' ?
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFE5F4', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={OtherColor} style={{ color: '#E50087',height:"16px",width:"16px" }} />
                                        <Text style={{ color: '#E50087', marginLeft: '5px', fontSize: '10px' }}>Other</Text>
                                    </div>
                                    :
                                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E9FCF5', borderRadius: '8px', padding: '3px 7px' }}>
                                        <img src={Offline} style={{ color: '#28DF99', textAlign: 'center',height:"16px",width:"16px" }} />
                                        <Text style={{ fontSize: '10px', color: '#28DF99', marginLeft: '5px' }}>Offline</Text>
                                    </div>
                        }
                        <div style={{ width: 240, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: 120 }}>
                                <Typography.Text ellipsis={{ tooltip: true }} style={{ fontWeight: 600, fontSize: 14, fontFamily: 'roboto', width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>{onGoingItem?.subject?.name}</Typography.Text>
                            </div>
                            <div style={{ flexShrink: 0 }}>
                                {((user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') || user?.privilage?.includes('Planner'))) ?
                                    onGoingItem?.mode == 'Others'?
                                        null
                                        // <div className='r-c-c'>
                                        //     <Tooltip placement="left" title="Embed Link">
                                        //         <div style={{cursor:"pointer"}} onClick={()=>{handleJoin()}}>
                                        //             <LinkOutlined style={{color:'#594099', fontSize:20}}/>
                                        //         </div>
                                        //     </Tooltip>
                                        //     {onGoingItem?.sUrl != undefined ?
                                        //     <div className={'r-c-c cursor-pointer'} onClick={onPlay}>
                                        //         <img style={{ height: 20, width: 20, opacity: 1, marginLeft:10 }} src={require('../broadcast/localComponent/assets/Group 10666.svg').default} alt="" />
                                        //     </div> 
                                        //     :
                                        //     null}
                                        // </div>
                                    :
                                    <Button type='primary' style={{ width: '100px', borderRadius: '8px' }} onClick={() => handleJoin()} disabled={isJoinLoader} loading={isJoinNowLoading}>{'Join Now' } </Button>
                                    : null}
                            </div>
                        </div>
                        <div style={{ width: '100%', display: "flex", flexDirection: "row", alignItems: "center", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                                            <FaGraduationCap style={{ color: 'purple', marginRight: '5px' }} />
                                                        {onGoingItem?.standards.length != 0 ? onGoingItem?.standards?.map((item, index, array) => (
                                <Tooltip title={onGoingItem?.standards.length != 0 ? onGoingItem?.standards?.map((item, index, array) => (`${item?.std + "-" + item?.section}${index === array.length - 1 ? '' : ' | '}`)) : null}>
                                    <Text style={{ fontSize: '80%', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '40%' }} key={index}>{item?.std}-{item?.section}{index === array.length - 1 ? '' : '|'}</Text>
</Tooltip>
                            )) : null}
                        </div>
                    </div>
                    {/* {((user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') || user?.privilage?.includes('Planner'))) ?
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100px', marginLeft: 50 }}>
                            {onGoingItem.mode === "Offline" ?
                                null :
                                <Button type='primary' style={{ width: '100px', borderRadius: '16px', marginLeft: '4px' }}
                                    onClick={() => handleJoin()}
                                    disabled={isJoinLoader}
                                >{isJoinNowLoading ? <LoadingOutlined /> : null} Join Now</Button>}
                        </div> : null} */}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div className='r-c-c'>
                    <UserAvatar size="small" shape="circle" name={onGoingItem?.teacher?.name} img={onGoingItem?.teacher?.image == "default.png" ? "" : `${Api._s3_url}${onGoingItem?.teacher?.image}`} />
                    <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{onGoingItem?.teacher?.name}</Text>
                </div>
                {((user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('AttendanceMarker')) && (onGoingItem?.mode === 'Offline')) ?
                    <div style={{ marginLeft: '15px', cursor: 'pointer' }} onClick={markAttendanceHandler}>
                        <Avatar shape='circle' size='small' src={avattar} />
                        <Text style={{ marginLeft: '5px', color: onGoingItem?.attendanceMarked ? '#636363' : '#1089FF', fontSize: '10px', fontFamily: 'roboto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold', cursor: 'pointer' }} disabled={onGoingItem?.attendanceMarked ? ((user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin')) || (user?.privilage?.includes('Planner')) ? false : true) : false}>{onGoingItem?.attendanceMarked ? `${onGoingItem?.attendanceData?.presentCount}/${onGoingItem?.attendanceData?.studentCount}` : 'MARK ATTENDANCE'}</Text>
                    </div>
                    :
                    null
                }
                {/* <div style={{ marginLeft: "90px" }}>
                    <Avatar
                        style={{ backgroundColor: '#594099' }}
                        size={14}
                        icon={<UserOutlined />}
                    />
                    <Text style={{ marginLeft: '5px', color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{onlineData[onGoingItem.roomName].length} Online</Text>
                </div> */}
            </div>
            <div>
                <Progress percent={parseInt(((spendHours) / hours) * 100)} showInfo={false} strokeColor="#594099" />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={{ color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(onGoingItem?.startTime, "HH:mm:ss").format('hh:mm A')}`}</Text>
                    <Text style={{ color: '#636363', fontSize: '10px', fontFamily: 'roboto' }}>{`${moment(onGoingItem?.endTime, "HH:mm:ss").format('hh:mm A')}`}</Text>
                </div>

            </div>
            {/* {((user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('AttendanceMarker')) && (onGoingItem?.mode === 'Offline')) ?
                <div style={{ margin: '5px auto', padding: '5px 0' }}>
                    {onGoingItem?.attendanceMarked
                        ?
                        <Button style={{ width: '280px', borderRadius: '50px', backgroundColor: '#E6E6E6', color: '#AEAEAE', fontSize: '14px', height: '35px' }} onClick={markAttendanceHandler} disabled><img src={checkCirlce} style={{ paddingRight: '6px' }}></img><span>ATTENDANCE MARKED</span></Button>
                        :
                        <Button style={{ width: '280px', borderRadius: '50px', backgroundColor: '#594099', color: '#FFFFFF', fontSize: '14px', height: '35px' }} onClick={markAttendanceHandler} >MARK ATTENDANCE</Button>
                    }

                </div>
                : null} */}
            {onGoingItem?.mode === 'Others' && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin') ) ?
                <div style={{position:"absolute", top:10, right:10}}>
                    <Dropdown overlay={menu} disabled={false} placement="bottomRight" >
                        <MdMoreVert style={{ width: "24px", height: "24px", color: "#AEAEAE" }} />
                    </Dropdown>
                </div>
                :
                null
            }
            {embedModalVisible ? <EmbedModal adding={onGoingItem.sUrl==undefined} embedding={embedding} visible={embedModalVisible} onClose={()=>{setEmbedModalVisible(false)}} onOk={onEmbed}/>:null}
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        scheduleId,
        access,
        delOrCancel,
        isJoinLoader,
        presentStudents,
        studentAttendanceData
    } = state.schedule;
    const { user } = state.session
    return {
        scheduleId,
        access,
        delOrCancel,
        user,
        isJoinLoader,
        presentStudents,
        studentAttendanceData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setStreamId: (streamId) => dispatch(setStreamId(streamId)),
    joinClass: (id, callBack) => dispatch(joinClass(id, callBack)),
    setBroadcastSelectedClassroom: (newClass) => dispatch(setBroadcastSelectedClassroom(newClass)),
    setJoinLoader: (bool) => dispatch(setJoinLoader(bool)),
    fetchStudentListData: (id) => dispatch(fetchStudentListData(id)),
    setSelectedId: (id) => dispatch(setSelectedId(id)),
    setCardData: (value) => dispatch(setCardData(value)),
    postEmbedLink: (params, c, sc) => dispatch(postEmbedLink(params, c, sc)),
    setLiveStreamUrl: (url) => dispatch(setLiveStreamUrl(url))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps

)(OngoingClassCard);
