const Image1 = require("Assets/loginNew/easyToUse.png").default;
const Image2 = require("Assets/loginNew/enhance.png").default;
const Image3 = require("Assets/loginNew/engage.png").default;
const Image4 = require("Assets/loginNew/easyOfAdoption.png").default;

const carouselData = [
    {
      image: Image1,
      title: "Easy-to-use",
      description: "A platform solution that can be readily intergrated on existing learning systems",
    },
    {
      image: Image2,
      title: "Enhance learning experiences",
      description: "An app that respects the integrity of a classroom and provides seamless student-teacher interaction",
    },
    {
      image: Image3,
      title: "Engage from anywhere, on any device",
      description: "Classrooms can now be access from no matter where you are, including low bandwidth areas",
    },
    {
      image: Image4,
      title: "Ease of adoption",
      description: "The changeover to aulas app, for any institute whether big or small, is just a five-finger exercise",
    },
  ];

export default carouselData