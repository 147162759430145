import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";

const StudentGraph = ({ presentVal, absentVal, overall }) => {
  const data = [
    {
      type: "Present",
      value: presentVal
    },
    {
      type: "Absent",
      value: absentVal,
    },

  ];
  const config = {
    appendPadding: 8,
    data,
    angleField: "value",
    colorField: "type",
    color: ["#28DF99", "#FF414D"],
    radius: 0.7,
    innerRadius: 0.7,
    label: {
      type: "inner",
      offset: "-50%",
      content: "",

      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],

    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "16px",
        },
        customHtml: function customHtml() {
          return (
            <div>
              {/* <div style={{display:"flex",justifyContent:"center"}}> */}
              <div style={{ fontSize: "24px", fontWeight: "700" }}>{overall}%</div>
              <div style={{ fontSize: "14px", color: "#191919", fontWeight: "300", marginLeft: "5px", marginTop: "8px", fontFamily: "roboto" }}>Overall attendance</div>
              {/* </div> */}

            </div>)
        }
        // customHtml: function customHtml() {
        //   return (
        //           <div>
        //               <div style={{fontSize:"18px", fontWeight:"700"}}></div>
        //               <div style={{fontSize:"10px", fontWeight:"100", color:"grey"}}>Average</div>
        //           </div>)
        // }

      },
    },
  };

  return <Pie {...config} />;
};

export default StudentGraph;
