import {
    SET_ALL_COURSES_DATA,
    SET_CHILD_LINK,
    SET_EXPLORE_COURSE_DATA,
    SET_HOMEPAGE_DATA,
    SET_ITEMID,
    SET_ITEM_TYPE,
    SET_LOADER,
    SET_LOGIN_DETAILS,
    SET_MY_RESOURCES_DATA,
    SET_PARENT_LINK,
    SET_POPULAR_COURSE_RENDER,
    SET_RENDER_TEST,
    SET_TEST_SERIES_DATA,
    SET_COURSE_PAGE_DATA,
    FETCH_PROMO_CODES,
    FETCH_CART_DETAILS,
    STORE_PAYMENT_DETAILS_NEEDED,
    POST_PAYMENT_DETAILS,
    SET_QUIZ_DATA,
    STORE_SINGLE_BUYNOW,
    SET_CART_COUNT,
    SET_COUPON,
    SET_PROMO_PRICE,
    SET_RESOURCES_TYPE
} from "actions/aulasMarket";

const initialState = {
    popularRenderPage: 0,
    renderTest: 0,
    coursePageData: [],
    testSeriesData: [],
    homePageData: [],
    allCoursesData: [],
    exploreCourseData: [],
    loader: false,
    itemType: "",
    loginDetails: {},
    parentLink: { parentName: null, childName: null, parentId: null, childId: null },
    myResourceData: [],
    itemId: null,
    promoCodeDetials: [],
    cartDetails: {},
    paymentOrderDetails: null,
    paymentStatus: null,
    quizData: [],
    singleBuyNowDetails: {},
    cartCount: null,
    couponInCart: false,
    storePromoPrice:'',
    storeResourceType:''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POPULAR_COURSE_RENDER:
            return {
                ...state,
                popularRenderPage: action.val
            };
        case SET_COURSE_PAGE_DATA:
            return {
                ...state,
                coursePageData: action.item
            };
        case SET_TEST_SERIES_DATA:
            return {
                ...state,
                testSeriesData: action.val
            };

        case SET_HOMEPAGE_DATA:
            return {
                ...state,
                homePageData: action.val
            }
        case SET_ALL_COURSES_DATA:
            return {
                ...state,
                allCoursesData: action.val
            }
        case SET_EXPLORE_COURSE_DATA:
            return {
                ...state,
                exploreCourseData: action.val
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.val
            }

        case SET_ITEM_TYPE:
            return {
                ...state,
                itemType: action.val
            }

        case SET_LOGIN_DETAILS: {
            let newParamsObj = { ...state.loginDetails }
            newParamsObj[action.key] = action.val;

            return {
                ...state,
                loginDetails: newParamsObj,
            }
        }

        case SET_PARENT_LINK: {
            return {
                ...state,
                parentLink: action.val
            }
        }

        case SET_MY_RESOURCES_DATA: {
            return {
                ...state,
                myResourceData: action.val
            }
        }

        case SET_ITEMID: {
            return {
                ...state,
                itemId: action.val,
            }
        }
        case SET_RENDER_TEST: {
            return {
                ...state,
                renderTest: action.val,
            }
        }

        case FETCH_PROMO_CODES: {
            return {
                ...state,
                promoCodeDetials: action.res
            }
        }

        case FETCH_CART_DETAILS: {
            return {
                ...state,
                cartDetails: action.res
            }
        }

        case STORE_PAYMENT_DETAILS_NEEDED: {
            return {
                ...state,
                paymentOrderDetails: action.res
            }
        }

        case POST_PAYMENT_DETAILS: {
            return {
                ...state,
                paymentStatus: action.res
            }
        }

        case SET_QUIZ_DATA: {
            return {
                ...state,
                quizData: action.val
            }
        };

        case STORE_SINGLE_BUYNOW:
            return {
                ...state,
                singleBuyNowDetails: action.res
            }

        case SET_CART_COUNT: {
            return {
                ...state,
                cartCount: action.val
            }
        }

        case SET_COUPON:
            return {
                ...state,
                couponInCart: action.res
            }

        case SET_PROMO_PRICE:
            return {
                ...state,
                storePromoPrice: action.res
            }
        case SET_RESOURCES_TYPE:
            return{
                ...state,
                storeResourceType:action.val
            }

        default:
            return state;
    }
};

export default reducer;