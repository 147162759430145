import React from "react";
import { connect } from "react-redux";
import Button from "antd/lib/button";
import { Divider, Typography } from "antd";
import "antd/lib/button/style/index";
import { ArrowLeftOutlined, EditFilled, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
	getClass,
	toggleAddChapterModal,
	toggleAddStudentModal,
	toggleCreateSubjectModal,
	setCurrentSubject,
} from "../../actions/classRoom";
import "./styles.css";
import AddChapterModal from "./modals/addChapter";
import AddSubjectModal from "./modals/addSubject";
import AddStudentModal from "./modals/addStudent";
import TransferClassModal from './modals/transferClass';
import EditCurriculumModal from "./modals/editCurriculum";
import PageDataLoader from "components/pageDataLoader";
import { Api, Color } from "services";
import Avatar from "antd/lib/avatar/avatar";
import { SquareSubIcon } from "components/subIcon";
import { savePageCoordinates } from "actions/layout";
import EditClassModal from "./modals/editClass";
import { toggleEditClassModal } from "../../actions/classRoom";
import PageHeader from "components/pageHeader";

const emptySubjects = (createSubjectModal, history) => (
	<div className="r-c-c-c full-height">
		<img
			src={require("../../Assets/classroom/noSubjects.svg").default}
			style={{ width: 300, height: 300 }}
		/>
		<div className="text-sm bold-600 color-purple">
			No subjects added for the class yet!
		</div>
		<div className="text-xmd">
			You may browse from our resources or create manually.
		</div>
		<div className="r-c-sb m-t-30" style={{ width: "300px" }}>
			<Button
				className="blankGreyBtnWrap radius-100 dark-grey"
				style={{ width: "130px" }}
				onClick={() => createSubjectModal(true)}
			>
				Create
			</Button>
			<Button
				loading={false}
				type="primary"
				className="purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118"
				style={{ width: "120px" }}
				onClick={() => history.push("/classroom/resources")}
			>
				Browse
			</Button>
		</div>
	</div>
);

const singleSubject = (item, index, openAddChapterModal, showEditChapters) => (
	<div key={index} className="box-sequare__subject">
		<div className="r-ac">
			<SquareSubIcon name={item.name} icon={item.icon} size={24} />
			<div className="m-l-10">
          <div className='text-xmd dark-black bold-600 text-overflow' style={{width: 150, textTransform: 'uppercase'}}>{item.name}</div>
          {/* <Typography.Text
            className='text-xmd dark-black bold-600'
						ellipsis={{ tooltip: false }}
            style={{width: 150}}
					>
						{item.name}
					</Typography.Text> */}
				<div className="text-xs dark-grey">
					{item.chapters ? item.chapters?.length : 0} Chapters
				</div>
			</div>
		</div>
		<Divider style={{ margin: "10px 0px" }} />
		<div className="box-sequare__subject-chapter-wrap">
			<div>
				{item.chapters && item.chapters?.length
					? item.chapters
						.filter((chap, i) => i < 5)
						.map((chapter, chapIndex) => (
							<div className="r-ac m-b-5">
								<div className="text-xs dark-grey m-r-10">
									Chapter {chapIndex + 1}
								</div>
								<div className="text-xs dark-black bold-600 text-overflow">
									{chapter.name}
								</div>
							</div>
						))
					: null}
			</div>
			{item.chapters?.length > 4 ? (
				<div
					className="text-xs bold-600 text-center blue cursor-pointer"
					onClick={() => showEditChapters(item)}
					style={{marginBottom: -10}}
				>
					View All
				</div>
			) : (
				<div
					className="text-xs bold-600 text-center blue cursor-pointer"
					onClick={() => openAddChapterModal(item)}
					style={{marginBottom: -10}}
				>
					<PlusCircleOutlined /> Add Chapters
				</div>
			)}
		</div>
	</div>
);

const addSubButton = (createSubjectModal, history) => (
	<div key={1} className="box-sequare__subject r-c-c-c">
		<div
			style={{ fontWeight: 600, fontSize: 14, color: Color.primary }}
			className="display-flex m-t-20"
		>
			Add Subjects
		</div>
		<div style={{ fontSize: 10, color: Color.darkGrey, textAlign: "center" }}>
			You can either browse and select from our resources or create your own
			subject!
		</div>
		<Button
			className="btn-radius-15__height-38 dark-grey m-t-30 m-b-10 cursor-pointer"
			style={{ width: "90%" }}
			onClick={() => createSubjectModal(true)}
		>
			Create
		</Button>
		<Button
			loading={false}
			type="primary"
			className="purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118 cursor-pointer"
			style={{ width: "90%" }}
			onClick={() => history.push("/classroom/resources")}
		>
			Browse
		</Button>
	</div>
);

const emptyStudents = (addStudent) => (
	<div className="r-c-c-c content-box__height-40">
		<img
			src={require("../../Assets/classroom/noStudents.svg").default}
			style={{ width: 200, height: 200 }}
		/>
		<div className="text-sm bold-600 color-purple">No Students Here!</div>
		<div className="text-xmd color-black">
			Start adding students to this class.
		</div>
		<div className="r-c-c m-t-30">
			<Button
				icon={<PlusCircleOutlined />}
				className="purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118 text-xmd"
				type="primary"
				onClick={() => addStudent(true)}
			>
				Add Students
			</Button>
		</div>
	</div>
);

const singleStudent = (item, index) => (
	<div className="r-ac m-b-20 m-l-10">
		{
			<Avatar
				src={`${Api._s3_url}${item.image}`}
				className="radius-100 batch-detial__student-img"
			/>
		}
		<div className="text-overflow flex-1 m-l-10">
			<div className="text-xs color-black bold-600 text-overflow">
				{item.name}
			</div>
			<Typography.Text
				className="text-xs dark-grey"
				ellipsis={{ tooltip: true }}
				style={{ width: 200 }}
			>
				Roll no. {item.rollNo}
			</Typography.Text>
		</div>
	</div>
);

class BatchDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showEditCurriculum: false,
		};
	}

	componentDidMount() {
		this.props.getClass();
	}

	openAddChapterModal = (data) => {
		this.props.setCurrentSubject(data);
		this.props.toggleAddChapterModal(true);
	};

	showEditChapters = (subject) => {
		this.props.setCurrentSubject(subject);
		this.props.history.push("/classroom/editchapters");
	};

	showEditCurriculum() {
		this.setState({ showEditCurriculum: true });
	}

	successCallback() {
		this.props.getClass(() =>
			this.setState({ students: this.props.selectedSectionDetails.students })
		);
	}

	componentWillUnmount() {
		if (!window.location.pathname.includes("/classroom")) {
			this.props.savePageCoordinates(0, 0);
		}
		
		this.props.toggleEditClassModal(false)
	}

	render() {
		const {
			history,
			selectedSectionDetails,
			selectedSectionLoader,
			showAddChapterModal,
			toggleAddStudentModal,
			showAddStudentModal,
			showCreateSubModal,
			toggleCreateSubjectModal,
			toggleEditClassModal,
			showEditModal,
			showTransferClassModal
		} = this.props;
		const { showEditCurriculum } = this.state;

		const pageHeaderTitle = (
			<div style={{ fontSize: "1em" }}>
				{selectedSectionDetails
					? `${selectedSectionDetails?.std} : ${selectedSectionDetails?.section}`
					: ""}
			</div>
		);
		const pageHeaderSubtitle =
			`${selectedSectionDetails?.courseName}` !== "null"
				? ` ${selectedSectionDetails?.courseName}`
				: "";

		return (
			<div style={{
				background: "#FAFAFA",
				width: "calc(100% + 40px)",
				height: "calc(100% + 40px)",
				margin: -20,
				padding: 20
			}}
				className="full-height display-flex"
			>
				{/* <div  style={{
					display:"flex",paddingRight: 20,
					position: "fixed",
					top: 0,
					backgroundColor: "#ffffff",
					borderBottom: '1px solid #E6E6E6',
					zIndex: 12,
					padding: 10,
					marginLeft:-20,
					width: "calc(100% + 40px)"}}>
		   				<div style={{marginLeft:15,display:"flex"}}>
							<ArrowLeftOutlined onClick = {()=>history.goBack()} style={{marginRight:10,marginTop:10,cursor:"pointer",fontSize: "1.5em",fontWeight: "bold"}}/>
							<div style={{border: '1px solid red'}}>
								<pre style={{fontSize: "2em",fontWeight: "bold",marginLeft:10}}>{selectedSectionDetails ? `${selectedSectionDetails?.std} : ${selectedSectionDetails?.section}`: ""}</pre>
								<pre style={{fontSize: "1rem",fontWeight: 600,marginTop:-30,color: "#636363",}}>{`${selectedSectionDetails?.courseName}` !== "null"? ` ${selectedSectionDetails?.courseName}`: ""}</pre>
							</div>
							<EditOutlined style={{marginLeft:20,marginTop:15,cursor:"pointer",fontSize: "1.5em",fontWeight: "bold"}} onClick={()=>toggleEditClassModal(true, selectedSectionDetails?.std)}/>
						</div>
				</div> */}
				<PageHeader
					title={pageHeaderTitle}
					subtitle={pageHeaderSubtitle}
					onBackPress={() => history.goBack()}
					edit={true}
					modalOnClick={() =>
						toggleEditClassModal(true, selectedSectionDetails?.std)
					}
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop:40,
						width: "100vw"
					}}
				>
						<div
							className="content-box__raduis-15 content-box__height-125 scroll-bar-universal"
							style = {{ width: '67%' }}
						>
							<div className="r-c-sb m-b-10">
								<div className="text-sm bold-600">Curriculum</div>
								{selectedSectionDetails?.subjects?.length ? 
								<Button
									type="link"
									icon={<EditFilled className="color-black" />}
									onClick={() => this.showEditChapters({})}
									style={{ fontWeight: 700, color: "#1089FF" }}
								>
									Edit Curriculum
								</Button>:null}
							</div>
							{!selectedSectionDetails?.subjects ||
								!selectedSectionDetails?.subjects?.length ? (
								emptySubjects(toggleCreateSubjectModal, history)
							) : (
								<div className="r-ac flex-wrap">
									{selectedSectionDetails.subjects.map((item, index) =>
										singleSubject(
											item,
											index,
											this.openAddChapterModal,
											this.showEditChapters
										)
									)}

									{addSubButton(toggleCreateSubjectModal, history)}
								</div>
							)}
						</div>

					<div
						style={{
							width: "31%",
							border: "1px solid #E6E6E6",
							borderRadius: 16,
							marginTop: 50,
							height: "90%",
							padding: 10,
							paddingBottom: 30
						}}
					>
						<div className="r-c-sb m-t-15">
							<div className="text-sm bold-600 m-r-20">
								{selectedSectionDetails?.students?.length > 1
									? "Students"
									: "Student"}{" "}
								<span className="dark-grey">
									(
									{selectedSectionDetails?.students
										? selectedSectionDetails?.students?.length
										: 0}
									)
								</span>
							</div>
							{selectedSectionDetails?.students?.length ? (
							<div>
								<Button
									type="transparent"
									style={{ color: "#1089FF", borderColor: "#1089FF" }}
									icon={<PlusCircleOutlined />}
									className=" radius-100 text-xmd"
									onClick={() => toggleAddStudentModal(true)}
								>
									Add Students
								</Button>
							</div>
							) : null}
						</div>
						{selectedSectionDetails?.students?.length ? (
							<div className="m-t-20 batch-detial__student-wrap scroll-bar-universal">
								{selectedSectionDetails?.students.map((item, index) =>
									singleStudent(item, index)
								)}
							</div>
						) : (
							emptyStudents(toggleAddStudentModal)
						)}

						{selectedSectionDetails?.students?.length ? (
							<div
								onClick={() => history.push("/classroom/allstudents")}
								className="text-xmd bold-600 text-center blue cursor-pointer"
								style={{ paddingBottom: -20 }}
							>
								VIEW ALL
							</div>)
						: null}
					</div>
				</div>
				{showEditCurriculum && (
					<EditCurriculumModal
						visible={showEditCurriculum}
						onClose={() => this.setState({ showEditCurriculum: false })}
					/>
				)}
				{showEditModal && (
					<EditClassModal
						showOnlySelectedSection={true}
						successCallback={() => this.successCallback()}
					/>
				)}
        		{showTransferClassModal && <TransferClassModal />}
				{showAddChapterModal && <AddChapterModal />}
				{showCreateSubModal && <AddSubjectModal />}
				{showAddStudentModal && (
					<AddStudentModal successCallback={() => this.successCallback()} />
				)}
				<PageDataLoader visible={selectedSectionLoader} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		selectedSectionDetails,
		selectedSectionLoader,
		showAddChapterModal,
		showAddStudentModal,
		showCreateSubModal,
		showEditModal,
		showTransferClassModal
	} = state.classRoom;
	return {
		selectedSectionDetails,
		selectedSectionLoader,
		showAddChapterModal,
		showAddStudentModal,
		showCreateSubModal,
		showEditModal,
		showTransferClassModal
	};
};

const mapDispatchToProps = (dispatch) => ({
	getClass: () => dispatch(getClass()),
	toggleAddChapterModal: (bool) => dispatch(toggleAddChapterModal(bool)),
	toggleAddStudentModal: (bool) => dispatch(toggleAddStudentModal(bool)),
	toggleCreateSubjectModal: (bool) => dispatch(toggleCreateSubjectModal(bool)),
	setCurrentSubject: (data) => dispatch(setCurrentSubject(data)),
	savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y)),
	toggleEditClassModal: (bool, key) =>
		dispatch(toggleEditClassModal(bool, key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchDetails);
