import React from 'react';
// import { Typography, Card, Avatar, Button } from 'antd';
import {IoIosHand, IoMdCheckmark} from "react-icons/io";

import './styles.css';
import '../../design/layout.css';

const HandBox = ({isAccepted, name, rollno}) => {
  return (
    <div className='chatContain'>
      <div className='chatView'>
        {isAccepted ?
          <div
            className='acceptedIcon r-c-c'
          >
            <IoMdCheckmark className='green text-sm' />
          </div>
          :
          <div className='handIcon r-c-c'>
            <IoIosHand className='color-purple text-sm' />
          </div>
        }
        <div className='p-l-10'>
          <div className='text-sm bold color-black'>{name}</div>
          <div className='text-xsm a2grey'>Roll No: {rollno}</div>
        </div>
      </div>
    </div>
  )
};

export default HandBox;
