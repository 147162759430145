import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react';
import "./userHomeStyles.css"

function DownloadAppTopBar({orgName, pSLink, setIsDownloadBarVisible, aSLink}) {
    return (
        <div className='download-app-topbar-padding' style={{ backgroundColor: "#00A566", height: "fit-content", width: "100%",justifyContent:'space-between', display: "flex",  alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className='contact-font' style={{ color: "#FAFAFA",fontWeight: 600, marginRight: 20 , marginBottom:4}}>Download our app</div>
                <img className='play-store-img-size' src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} onClick={() => window.open(pSLink, "_blank")} />
                <img className='play-store-img-size responsive-margin-left' src={require("../../Assets/aulasMarket/appStoreRes.svg").default} onClick={() => window.open(aSLink, "_blank")} />
            </div>
            <div className='download-cross'>
            <img src={require("../../Assets/aulasMarket/CrossCircleOutlined.svg").default} style={{width:20, cursor:"pointer"}} onClick={()=>{setIsDownloadBarVisible(false)}}/>
            </div>
            <div className='login-ussing-app-parent'>
                {/* <div className='contact-font' style={{ color: "#FAFAFA", fontWeight: 600, marginRight: 20 }}>Login Using The aulas App</div> */}
                {/* <img className='play-store-img-size m-r-10' src={require("../../Assets/aulasMarket/googlePlayRes.svg").default} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.classroom1.aulas", "_blank")} />
                <img className='play-store-img-size m-r-20' src={require("../../Assets/aulasMarket/appStoreRes.svg").default} onClick={() => window.open("https://apps.apple.com/in/app/aulas/id1564724183", "_blank")} /> */}
                <img src={require("../../Assets/aulasMarket/CrossCircleOutlined.svg").default} style={{width:20, cursor:"pointer"}} onClick={()=>{setIsDownloadBarVisible(false)}}/>
            </div>
        </div>
    )
}

export default DownloadAppTopBar