import React, { useState } from "react";
import { Popover, Select } from "antd";
import CalenderPopup from "./calenderPop";
// import './careStyles.css'
import { connect } from "react-redux";
import image from "Assets/care/ic-calendar.svg";
import image1 from "Assets/care/ic-sort.svg";
import {
  getStats,
  getUserDashBoardData,
  setEndDate,
  setMonthOrWeek,
  setStartDate,
} from "actions/userManagement";
import PageDataLoader from "components/pageDataLoader";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
// import { setStartDate } from "actions/adminCare";
// import { getDashBoardData, setMonthOrWeek } from 'actions/masterAdminCare';

const { Option } = Select;

function FeeCalender({
  isMonthOrWeek,
  getStats,
  startDate,
  endDate,
  getDashBoardData,
  setMonthOrWeek,
  barGraphPlotFunction,
  togglePageLoader,
  dashBoardRole,
  getUserDashBoardData,
  setEndDate,
  setStartDate,
}) {
  const [pageLoader, setPageLoader] = useState(false);
  const content = (
    <div className="popOverB" style={{ width: "230px" }}>
      <CalenderPopup togglePageLoader={togglePageLoader} />
    </div>
  );

  const handleChange = (value) => {
    // console.log(value)
    setMonthOrWeek(value);

    setEndDate(moment().format("YYYY-MM-DD"));
    setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    if (dashBoardRole) {
      setPageLoader(true);
      getUserDashBoardData(() => setPageLoader(false));
    } else {
      setPageLoader(true);
      getStats(() => setPageLoader(false));
    }
    //   togglePageLoader(true)
    //   getDashBoardData(()=>togglePageLoader(false))
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        border: "#E6E6E6 solid 1px",
      }}
    >
      <div>
        <img style={{ width: "50px", height: "15px" }} src={image1} alt="" />
      </div>
      <PageDataLoader visible={pageLoader} />
      <div id="testHomeDropdown">
        <Select
          className="inputSelecter"
          value={isMonthOrWeek}
          style={{ width: "80%", border: "none" }}
          onChange={handleChange}
          bordered={false}
          removeIcon={true}
          dropdownMatchSelectWidth={true}
          getPopupContainer={() => document.getElementById("testHomeDropdown")}

        >
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
        </Select>

      </div>

      {isMonthOrWeek == "week" ? (
        <Popover
          placement="bottomLeft"
          dropdownClassName={"dropDownB"}
          content={content}
          trigger="click"
        >
          <CalendarOutlined
            style={{ fontSize: "18px", marginRight: "2px", color: "#A0A0A0" }}
          />
        </Popover>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { tab, dashBoardRole, isMonthOrWeek } = state.userManagement;
  return {
    tab,
    dashBoardRole,
    isMonthOrWeek,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMonthOrWeek: (m) => dispatch(setMonthOrWeek(m)),
  getUserDashBoardData: (callback) => dispatch(getUserDashBoardData(callback)),
  getStats: (callback) => dispatch(getStats(callback)),
  setStartDate: (date) => dispatch(setStartDate(date)),
  setEndDate: (date) => dispatch(setEndDate(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeCalender);
