import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
// import "./modal.css";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import { submitAnswer, postUpdateAttempt,setCurrentIndex,fetchTestQuestions, updateQParam, setLoader, 
  finalSubmit, setSelectedTest,updateCurrentDuration, toggleOutOfTimeModal  } from "actions/studentPracticeTest";

let timer = false;
function CombinedTestModal({
  selectedTest,
  isModalVisible,
  handleCancel,
  setCurrentIndex,
  postUpdateAttempt,
  submitAnswer,
  fetchTestQuestions,
  updateQParam,
  setLoader,
  finalSubmit,
  toggleSubmitSuccess,
  setSelectedTest,
  updateCurrentDuration,
  toggleOutOfTimeModal
}) {
  const history = useHistory();
  const handleStartTestClick = () => {
    updateCurrentDuration(0);
    setCurrentIndex(1);
    toggleOutOfTimeModal(false);
    setLoader('testScreenLoader', true);
    if (selectedTest?.paused===true){
      if (timeLeft.isover!==1){
        setCurrentIndex(1);
        fetchTestQuestions((bool)=>{
          history.push("practice-testScreen");
          setLoader('testScreenLoader', false);
        })
      }
    }else{
      postUpdateAttempt(false, (response)=>{
        updateQParam(null, "currentAns");
        updateQParam(response.firstQuestionId, "id");
          submitAnswer("1", ()=>{}, (bool) =>
          {
            setSelectedTest({...selectedTest, attemptCreatedAt:""+moment.utc(moment()).format()})
            fetchTestQuestions((bool)=>{
              history.push("practice-testScreen");
              setLoader('testScreenLoader', false);
            });
          });
  
      });
    }
  };
  const getRemainingTime = () => {
        let isover = 0;
        let durationInMinutes = selectedTest.durationInMinutes;
        let createdTimeMoment = moment.utc(selectedTest.attemptCreatedAt).local();
        let createdTime  = createdTimeMoment.format("DD/MM/YYYY • hh:mm A");
        let endTimeMoment = createdTimeMoment.add(0, 'seconds').add(durationInMinutes, 'minutes')
        let endTime = endTimeMoment.format("DD/MM/YYYY • hh:mm A")
        let currentTimeMoment = moment();
        let currentTime = moment().format("DD/MM/YYYY • hh:mm A");
        let diff = moment.duration(endTimeMoment.diff(currentTimeMoment)).asSeconds();
        if (diff<0){
          isover = 1
        }
        let minutesLeft = parseInt(diff/60)
        console.log("65", minutesLeft, isover);
        return {minutesLeft, isover }
      }
  const handleOk = () => {
    handleCancel();
    toggleSubmitSuccess(true);
  };
  const [timeLeft, setTimeLeft] = useState({minutesLeft:getRemainingTime().minutesLeft, isover:getRemainingTime().isover});
  useEffect(()=>{
    clearTimeout(timer);
    if (selectedTest.paused===true && getRemainingTime().isover===1){
      finalSubmit(() => {}, handleOk)
    }
    timer = setTimeout(()=>{
      setTimeLeft(getRemainingTime())
    }, 60001);
  }, [timeLeft.minutesLeft]);
  return (
    <Modal
      className="CombinedTestModal modal-round-corner"
      title=""
      centered
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={750}
      height={600}
      footer={null}
      style={{borderRadius:10}}   
    >
      <div className="icon-cards">
        <div className="icon-card">
          <div className="icon-card-icon">
            <img src={require("Assets/ic-faq.svg").default}></img>
          </div>
          <div className="icon-card-text">
            <span className="icon-num-info">{selectedTest?.totalQuestions}</span>
            <span className="icon-text-info">Questions</span>
          </div>
        </div>
        <div className="icon-card">
          <div className="icon-card-icon">
            <img src={require("Assets/ic-call-us.svg").default}></img>
          </div>
          <div className="icon-card-text">
              <span className="icon-num-info">
                {
                  selectedTest.paused===true?
                  timeLeft.isover?0:timeLeft.minutesLeft
                  :Math.round(selectedTest.durationInMinutes)
                }
              </span>
            <span className="icon-text-info">Minutes</span>
          </div>
        </div>
        <div className="icon-card">
          <div className="icon-card-icon">
            <img src={require("Assets/ic-chat.svg").default}></img>
          </div>
          <div className="icon-card-text">
            <span className="icon-num-info">
              {selectedTest?.totalPoints}
            </span>
            <span className="icon-text-info">Marks</span>
          </div>
        </div>
      </div>
      <div className="container-answertype ct-xssm" style={{ padding: 10, backgroundColor:'#FAFAFA' }}>
        <p> Questions can be of two types: </p>
        <p>
          {" "}
          <b>1. Numeric: </b> The question will have a numeric answer. You can
          only enter a numeric value as the answer.
        </p>
        <p>
          {" "}
          <b>2. Objective: </b> The question will have multiple choices (maximum
          4) out of which you have to pick which one is correct.
        </p>
      </div>
      <div className="test-info-container">
        <div className="subject-container m-t-20">
          <span>Subject(s)</span>
          <ul>
            {selectedTest?.subjects?.map((item, index) => (
              <li key={index}>
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="introduction-container m-l-10">
        <span className="grey-font font-medium"> Instructions </span>
        <p className="font-medium">{selectedTest?.instructions}</p>
      </div>

      <div className="button-container">
        <Button
          shape="round"
          type="primary"
          style={{ width: 120 }}
          onClick={() => handleStartTestClick()}
        >
          <div className="bold-600" style={{ fontSize: 12 }}>
            {
              selectedTest.paused===true?timeLeft.isover?'SUBMIT TEST':'RESUME TEST':'START TEST'
            }
          </div>
        </Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { selectedTest} = state.studentPracticeTest;
  return {
    selectedTest,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentIndex: (index) => dispatch(setCurrentIndex(index)),
  postUpdateAttempt: (bool, callback) => dispatch(postUpdateAttempt(bool, callback)),
  submitAnswer: (dir, callback1, callback2) => dispatch(submitAnswer(dir, callback1, callback2)),
  fetchTestQuestions: (callback) => dispatch(fetchTestQuestions(callback)),
  updateQParam: (val, key) => dispatch(updateQParam(val, key)),
  setLoader: (key, val) => dispatch(setLoader(key, val)),
  finalSubmit: (buttonCallback, successCallback) => dispatch(finalSubmit(buttonCallback, successCallback)),
  setSelectedTest: (data) => dispatch(setSelectedTest(data)),
  updateCurrentDuration: (time) => dispatch(updateCurrentDuration(time)),
  toggleOutOfTimeModal: (bool) => dispatch(toggleOutOfTimeModal(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(CombinedTestModal);
