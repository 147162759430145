import { RadialBar, Pie } from '@ant-design/charts';
import React, { useEffect, useRef } from 'react'

const DoughnutCard = React.memo(({pieData})=>{
  const all0 = pieData?.chapterTestCount===0&&pieData?.partTestCount==0&&pieData?.fullTestCount===0
 
  {
      const data = [
        {
          type: 'Chapter test',
          value: pieData?.chapterTestCount?pieData.chapterTestCount:all0?0:null,
        },
        {
          type: 'Part test',
          value: pieData?.partTestCount?pieData.partTestCount:all0?0:null,
        },
        {
          type: 'Full test',
          value: pieData?.fullTestCount?pieData.fullTestCount:all0?0:null,
        }
      ];
      
      const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        color: ['#9464F9', '#99DED9', '#589DFF'],
        radius: 0.8,
        autoFit: true,
        legend: false,
        innerRadius: 0.68,
        label: {
          type: 'inner',
          offset: '-50%',
          content:"",
          style: {
            fill: '#fff',
            fontSize: 14,
            textAlign: 'center',
          },
        },
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: 8
            },
            customHtml: function customHtml() {
              return (
                      <div>
                          <div style={{fontSize:"20px", fontWeight:"700"}}>{pieData?.total??0}</div>
                          <div style={{marginTop: "5px", fontSize:"10px", fontWeight:"100", color:"grey"}}>Total test(s)</div>
                      </div>)
            }
          },
        },
        annotations: [
        ],
      };

    const tags = ["Chapter test", "Part test", "Full test"]
    const tagcolor = ['#9464F9', '#99DED9', '#589DFF']

    return (
        <>
        <div  
             style={{
                    width:"280px",
                    position:"absolute",
                    top:-80,
                     }}>
            <Pie {...config} />
        </div>
        <div className="r-jsb absolute" style={{bottom:10}}>
          {tags?.map((tag, index)=>
            <div className="r-c-c m-r-15" key={index}>
                <div style={{backgroundColor:tagcolor[index], 
                              width: "15px", 
                              height:"15px", 
                              borderRadius:"3px",
                              marginRight: "5px"}}>
                </div>
                <div style={{fontSize:12}}>
                  {tag}
                </div>
            </div>
          )
        }
        </div>
        </>
    )
}
}
)



export default DoughnutCard
