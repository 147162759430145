import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
// import '.../modal.css';
// import SubmittedSuccessModal from './submittedSuccessModal';
import {connect} from 'react-redux';
import { finalSubmit, selectQIndex, } from 'actions/studentPracticeTest';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, StarFilled, StopOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
 

const SubmitModalPractice = ({
  timeLeft,qCounts, finalSubmit,handleOk,visible,handleCancel, selectQIndex
}) => {
  const [buttonLoader, toggleButtonLoader] = useState(false);

  const handleSubmit = () => {
    toggleButtonLoader(true)
    selectQIndex(0, (bool) => {
      finalSubmit(() => toggleButtonLoader(false), handleOk);
      setLoader('testScreenLoader', false);
    }, true)

  }


  return (
      <Modal centered
        className="submitModal modal-round-corner" 
        visible={visible} 
        // onOk={handleOk}
        okButtonProps={{loading: buttonLoader}}
        onCancel={handleCancel}
        cancelText={"NOT YET"} okText={"SUBMIT"}
        width={650}
        footer={[
            <Button
            shape="round"
            type="primary"
            style={{ width: 120,backgroundColor:'white', color:'#636363',borderColor:'#636363' }}
            onClick={handleCancel}
    
          >
            <div className="bold-600" style={{ fontSize: 12,}}>
              NOT YET
            </div>
          </Button>,
            <Button
            shape="round"
            type="primary"
            style={{ width: 120 }}
            onClick={handleOk}
          >
            <div className="bold-600" style={{ fontSize: 12 }} onClick={handleSubmit}>
              SUBMIT
            </div>
          </Button>
          ]}
      >
        
        <h2 style={{fontWeight:"700",marginBottom:"0px", marginTop: 30,textAlign:'center'}}>Submit test</h2>
        <p style={{color:"#636363", opacity:"100%",textAlign:'center'}}>Are you sure you want to submit the test?</p>
        <div style={{width: "100%", padding: 20, marginTop: 30,  backgroundColor: "#FAFAFA", borderRadius: 5}}>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <ClockCircleOutlined style={{color: "#554394", }} />
            </Col>
            <Col span={18}>
              Time left
            </Col>
            <Col span={4} className="text-right" >
            {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <CheckCircleOutlined style={{color: "#554394", }}/>
            </Col>
            <Col span={18}>
              Attempted
            </Col>
            <Col span={4} className="text-right">
              {qCounts.attempted}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <CloseCircleOutlined style={{color: "#FF414D", }} />
            </Col>
            <Col span={18}>
              Unattempted
            </Col>
            <Col span={4} className="text-right">
              {qCounts.unanswered}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <StarFilled style={{color: "#FFA931", }}/>
            </Col>
            <Col span={18}>
              Attempted & Marked for review
            </Col>
            <Col span={4} className="text-right">
              {qCounts.attemptedAndBookmarked}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <StarFilled style={{color: "#FFA931", }}/>
            </Col>
            <Col span={18}>
              Unattempted & Marked for review
            </Col>
            <Col span={4} className="text-right">
              {qCounts.bookmarked}
            </Col>
          </Row>
          <Row style={{padding: '5px 0'}}>
            <Col span={2}>
              <StopOutlined style={{color: "#AEAEAE", }}/>
            </Col>
            <Col span={18}>
              Unseen
            </Col>
            <Col span={4} className="text-right">
              {qCounts.unseen}
            </Col>
          </Row>
        </div>
               
      </Modal>
  );
};


const mapStateToProps = state => {
  const {
     timeLeft, qCounts
  } = state.studentPracticeTest;
  return {
     timeLeft, qCounts
  };
};

const mapDispatchToProps = dispatch => ({
  finalSubmit: (buttonCallback, successCallback) => dispatch(finalSubmit(buttonCallback, successCallback)),
  selectQIndex:(index, callback, bool) => dispatch(selectQIndex(index, callback, bool)),
  
});


export default connect(mapStateToProps, mapDispatchToProps)(SubmitModalPractice);