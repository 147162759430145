import React, { useState, useEffect } from "react";
import { SubHeading, Heading, Title } from "components/Typography";
import {
  PageHeader,
  Row,
  Col,
  Select,
  Modal,
  Tabs,
  Button,
  Input,
  Pagination,
} from "antd";
import { useHistory } from "react-router";
import "./styles.css";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { testCard, handleStartClick } from "./index.js";
import ExitModal from "./modals/exitModal";
import OutoftimeModal from "./modals/outoftimeModal";
import ImageModal from "./modals/imageModal";
import PageDataLoader from "components/pageDataLoader";
import {
  updateStudentTestFilter,
  // fetchSubPageData,
  setDuration,
  setSelectedTest,
  updateTestType,
  fetchStatusWiseTest,
  UpdateStudentTestData,
} from "actions/studentTest";
import { connect } from "react-redux";
import StartTestModal from "./modals/startTestModal";
import { TestTimer } from "./timer";
import Buttons from "./buttons";
import Loader from "pages/Test/localComponent/loader";
import { NodataCard } from "pages/Test";
import { fetchStudentStandards } from "actions/schedule";
let timer = false;

const { TabPane } = Tabs;

const imagectnr = (type) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <img
      style={{ width: "20%" }}
      src={require("Assets/images/Group 10637.svg").default}
      alt=""
    />
    {type == "published" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>No test(s) available</h2>
        <h4>you have not attempted any test yet</h4>
      </div>
    ) : (
      <></>
    )}

    {type == "scheduled" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>No test(s) available</h2>
        <h4>There are no tests available</h4>
      </div>
    ) : (
      <>There are no tests available</>
    )}

    {type == "published_missed" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>No missed test</h2>
        <h4>You have not missed any test yet</h4>
      </div>
    ) : (
      <></>
    )}
  </div>
);

const SearchBox = ({ loading, studentTestData, UpdateStudentTestData }) => {
  return (
    <Input
      placeholder="Search by test name"
      className="m-l-20"
      prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
      style={{ backgroundColor: "#FFF", borderRadius: 5, width: 250 }}
      onChange={(e) => {
        UpdateStudentTestData("search", e.target.value);
      }}
      bordered={true}
      allowClear={true}
      value={studentTestData?.search}
    />
  );
};

const DropdownCheckbox = ({ selectedStandards, setSelectedStandards, studentStandardFilters, fetchStatusWiseTest }) => {
  const handleStandardChange = (value) => {
    fetchStatusWiseTest(value, () => { })
    setSelectedStandards(value);
  };

  return (
    <div style={{ width: '100%', marginLeft: 20 }}>
      <Select
        style={{ width: '100%' }}
        placeholder="Select standards"
        onChange={handleStandardChange}
        value={selectedStandards}
      >
        {studentStandardFilters?.studentStandards?.map((standard) => (
          <Option key={standard.standardId} value={standard.standardId}>
            {`${standard.std} - ${standard?.section}`}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const PageHeaderNew = ({
  title,
  subTestCount,
  back,
  studentTestData,
  UpdateStudentTestData,
  studentStandardFilters,
  selectedStandards, setSelectedStandards,
  fetchStatusWiseTest
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        className="text-md"
      >
        <ArrowLeftOutlined
          style={{ fontSize: "1em", marginRight: "20px" }}
          onClick={back}
        />{" "}
        <div>
          <div
            style={{
              fontFamily: "roboto",
              fontSize: "18px",
              fontWeight: "550",
            }}
          >
            {studentTestData?.selectedChapterTestCard?.combinedTest == true ? `Combined Test(s)` : title}{" "}
          </div>
          <div
            style={{
              fontFamily: "roboto",
              fontSize: "13px",
              fontWeight: "500",
              color: "#AEAEAE",
            }}
          >
            {studentTestData?.selectedTestCard?.testType}/
            {studentTestData?.selectedChapterTestCard?.combinedTest == true ? `Combined Test(s)` : studentTestData?.selectedChapterTestCard?.subjectName}{" "}
          </div>
        </div>
      </div>
      <div className="testfiltersgroup" style={{ paddingRight: "10px", width: '40%', display: 'flex', justifyContent: 'space-between' }}>
        <SearchBox
          UpdateStudentTestData={UpdateStudentTestData}
          studentTestData={studentTestData}
          loading={false}
        />
        <DropdownCheckbox
          selectedStandards={selectedStandards}
          setSelectedStandards={setSelectedStandards}
          studentStandardFilters={studentStandardFilters}
          fetchStatusWiseTest={fetchStatusWiseTest}
        />
      </div>
    </div>
  );
};

function TestBySubject({
  studentTestFilterParams,
  updateStudentTestFilter,
  scheduledTests,
  subTestCount,
  setDuration,
  timeLeft,
  setSelectedTest,
  updateTestType,
  fetchStatusWiseTest,
  studentTestData,
  UpdateStudentTestData,
  fetchStudentStandards,
  studentStandardFilters
}) {

  const history = useHistory();

  const initialSelectedStandard = studentStandardFilters?.studentStandards?.length > 0 ? studentStandardFilters?.studentStandards[0]?.standardId : null;

  const [pageLoader, togglePageLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStandards, setSelectedStandards] = useState(initialSelectedStandard);

  useEffect(() => {
    UpdateStudentTestData('search', '')
    UpdateStudentTestData("page", 1);
    togglePageLoader(true);
    fetchStatusWiseTest(selectedStandards, () => { togglePageLoader(false) });
    UpdateStudentTestData("type", null);

    return () => {
      updateStudentTestFilter("AVAILABLE", "testStatus");
      UpdateStudentTestData("type", null);
      TestTimer.clearTimer(setDuration);
    };
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      togglePageLoader(true); fetchStatusWiseTest(selectedStandards, () => { togglePageLoader(false) });
    }, 1000);
  }, [studentTestData?.search]);

  const handleTabChange = (key) => {
    updateStudentTestFilter(key, "testStatus");
    togglePageLoader(true);
    fetchStatusWiseTest(selectedStandards, () => { togglePageLoader(false) });
  };

  const handleStartClick = (test) => {
    setSelectedTest(test);
    setIsModalVisible(true);
  };

  const viewResult = (test) => {
    setSelectedTest(test);
    history.push("/student-test/result");
  };

  const viewSolution = (test) => {
    updateTestType("Missed");
    setSelectedTest(test);
    history.push("/student-test/solutions");
  };

  return (
    <div style={{ height: "100%", width: "100%" }} className="relative">
      <PageHeaderNew
        studentTestData={studentTestData}
        UpdateStudentTestData={UpdateStudentTestData}
        title={
          studentTestData?.selectedTestCard?.testType != "Chapter Test"
            ? studentTestData?.selectedChapterTestCard?.subjectName
            : studentTestData?.selectedChapter?.chapterName
        }
        subTestCount={subTestCount}
        back={() => history.goBack()}
        selectedStandards={selectedStandards}
        setSelectedStandards={setSelectedStandards}
        studentStandardFilters={studentStandardFilters}
        fetchStatusWiseTest={fetchStatusWiseTest}
      />

      <Tabs
        studentTestFilterParams={studentTestFilterParams.testStatus}
        onChange={(key) => handleTabChange(key)}
        className="testTab"
        activeKey={studentTestFilterParams.testStatus}
        style={{ height: "92%", overflowY: "scroll" }}
      >
        <TabPane tab="AVAILABLE" key="AVAILABLE">
          {pageLoader == false ?
            <div>
              <Buttons selectedStandards={selectedStandards} />
              {studentTestData?.statusWiseTest?.data?.ongoing &&
                Object.keys(studentTestData?.statusWiseTest?.data?.ongoing)
                  ?.length != 0 ?
                <div>
                  <div>
                    <Heading bold>Live Test</Heading>
                    <div style={{ width: 400, marginLeft: 20 }}>
                      {testCard(
                        "live",
                        studentTestData?.statusWiseTest?.data?.ongoing,
                        handleStartClick,
                        null,
                        null,
                        timeLeft
                      )}
                    </div>
                  </div>
                </div>
                :
                null
              }
              {studentTestData?.statusWiseTest?.data?.upcoming?.length ?
                <div>
                  <div className="r-jsb m-t-20">
                    <Heading bold>Upcoming Test(s)</Heading>
                  </div>
                  <div
                    style={{
                      padding: "20px 0px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {scheduledTests?.map((test, index) =>
                      testCard("scheduled", test, null, index)
                    )}
                  </div>
                </div>
                : null}
              {(studentTestData?.statusWiseTest?.data?.ongoing == undefined || Object.keys(studentTestData?.statusWiseTest?.data?.ongoing)?.length === 0) && studentTestData?.statusWiseTest?.data?.upcoming?.length === 0 ?
                imagectnr("scheduled")
                : null
              }
            </div>
            :
            <div style={{ height: "75vh" }}>
              <Loader style={{ fontSize: "40px" }} />
            </div>
          }
        </TabPane>
        <TabPane tab="SUBMITTED" key="ATTEMPTED">
          {pageLoader == false ?
            <div>
              <Buttons selectedStandards={selectedStandards} />
              {studentTestData?.statusWiseTest?.data?.attempted?.length > 0 ? (
                <div>
                  <div
                    style={{
                      padding: "20px 0px ",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {studentTestData?.statusWiseTest?.data?.attempted
                      ?.map((test, index) =>
                        <div style={{ width: "330px", height: "150px", margin: "10px" }}>
                          {testCard("published", test, null, index, viewResult)}
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                imagectnr("published")
              )}
            </div> :
            <div style={{ height: "75vh" }}>
              <Loader style={{ fontSize: "40px" }} />
            </div>
          }
        </TabPane>
        <TabPane tab="MISSED" key="MISSED">
          {pageLoader == false ?
            <div>
              <Buttons selectedStandards={selectedStandards} />
              {studentTestData?.statusWiseTest?.data?.missed?.length > 0 ? (
                <div>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  </div>
                  <div
                    style={{
                      padding: "20px 0px ",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {studentTestData?.statusWiseTest?.data?.missed?.map(
                      (test, index) =>
                        testCard(
                          "published_missed",
                          test,
                          null,
                          index,
                          null,
                          null,
                          viewSolution
                        )
                    )}
                  </div>
                </div>
              ) : (
                imagectnr("published_missed")
              )}
            </div> :
            <div style={{ height: "75vh" }}>
              <Loader style={{ fontSize: "40px" }} />
            </div>
          }
        </TabPane>
      </Tabs>
      <div style={{ marginLeft: "-300" }}>
      </div>
      <ExitModal />
      <OutoftimeModal />
      <ImageModal />
      <StartTestModal
        isModalVisible={isModalVisible}
        handleOk={() => setIsModalVisible(false)}
        handleCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    studentTestFilterParams,
    selectedSub,
    ongoingTest,
    scheduledTests,
    subTestCount,
    attemptedTests,
    missedTests,
    timeLeft,
    studentTestData,
  } = state.studentTest;
  const { studentStandardFilters } = state.schedule;

  return {
    studentTestFilterParams,
    selectedSub,
    ongoingTest,
    scheduledTests,
    subTestCount,
    attemptedTests,
    missedTests,
    timeLeft,
    studentTestData,
    studentStandardFilters
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedTest: (test) => dispatch(setSelectedTest(test)),
  updateStudentTestFilter: (val, key) =>
    dispatch(updateStudentTestFilter(val, key)),
  // fetchSubPageData: (callback) => dispatch(fetchSubPageData(callback)),
  setDuration: (duration) => dispatch(setDuration(duration)),
  updateTestType: (newType) => dispatch(updateTestType(newType)),
  fetchStatusWiseTest: (params, callback) => dispatch(fetchStatusWiseTest(params, callback)),
  UpdateStudentTestData: (key, val) => dispatch(UpdateStudentTestData(key, val)),
  fetchStudentStandards: () => dispatch(fetchStudentStandards())

});

export default connect(mapStateToProps, mapDispatchToProps)(TestBySubject);
