import React from 'react';
import moment from 'moment';
import { Button, Modal, Typography } from 'antd';
const timer = require('../../Assets/ic-timer.svg').default;

const LocationDetailModal = ({isLocationModal,setIsLocationModal,locationDetail}) => {

  const handleOk = () => {
    setIsLocationModal(false);
  };
  const handleCancel = () => {
    setIsLocationModal(false);
  };
  return (
    <>
      <Modal 
        className="modal-round-corner"
        open={isLocationModal} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{fontSize:"24px",fontWeight:"bold",textAlign:"center",marginTop:"20px"}}>Active Location(s)</div>
        <div style={{flexWrap:"wrap",flexDirection:"row",marginTop:"20px"}}>
          {locationDetail?.map((item)=>(
          <>
            <div style={{display:"flex",justifyContent:"space-between", padding: 10}}>
              <div style={{width: '55%'}}>
                <Typography.Text ellipsis={{tooltip:true}} style={{fontSize:14, color:"#191919", width:"100%", fontWeight: 700}}>{item?.geoFenceTitle}</Typography.Text>
                <div className='display-flex'>
                  {item?.geoFenceType !== 'Permanent' ? 
                  <div className='display-flex'>
                    <img src={timer} style={{width: 15, marginRight: 5}}/>
                  </div> : null}
                    <div style={{fontWeight:700, fontSize: 12, color: '#636363', }}>{item?.geoFenceType}</div>
                </div>
              </div>
              {item?.geoFenceType != 'Permanent' ? 
                <div style={{ fontSize:16, color: '#191919', fontSize: 14, fontWeight: 600 }}>{moment(item?.geoStart).format('DD-MM-YYYY')}{" "}-{" "}{moment(item?.geoEnd).format('DD-MM-YYYY')}</div>
              :null}
            </div>
          </>
          ))}
        </div>

        
      
      </Modal>
    </>
  );
};
export default LocationDetailModal;