import React, { useEffect, useRef, useState } from 'react'
import "./allCourses.css"
import { Button } from 'components/Button';
import { AutoComplete, Input } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import { connect } from 'react-redux'
import PageDataLoader from 'components/pageDataLoader'
import LikedOutlined from "../../../Assets/aulasMarket/likeOutlined.svg"
import LikedColored from "../../../Assets/aulasMarket/likeColored.svg"
import { Api } from 'services'
import noImage from "../../../Assets/aulasMarket/no-image.svg"
import { deleteQuestionText, getQuizResultsAttempted, getVideoResourceSearch, getVideoResourcesData, patchLikeVideoResources, postQaRequest, setMyCourseHome, setPdfViewUrl, setQuizFlowCount } from 'actions/studentMarketPlace'
import RelatedCoursesCompo from './relatedCoursesCompo'
import PageHeader from 'components/pageHeader';
import _debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EditOutlined } from '@ant-design/icons';
import { NodataCard } from 'pages/Test';

function QuestionAnswerSection({ myVideoResources, user, deleteQuestionText, questionAnswerData, getVideoResourceSearch, setQuizFlowCount, patchLikeVideoResources, quizFlowCount, getVideoResourcesData, postQaRequest }) {
    const history = useHistory();
    const { state } = useLocation();

    const [pageLoader, togglePageLoader] = useState(false);
    const inputRef = useRef(null);
    const [search, setSearch] = useState('');
    const itemIdVideoResource = state?.id;
    const [pageCount, setPageCount] = useState(1);
    const itemsToShow = questionAnswerData?.length;
    const [renderPage, setRenderPage] = useState(false);
    const [metaQAdata, setMetaQAdata] = useState([]);
    const [qAList, setQAList] = useState([]);
    const [searchQa, setSearchQa] = useState({
        id: itemIdVideoResource,
        search: '',
    });
    const [viewMoreQA, setViewMoreQA] = useState({
        id: itemIdVideoResource,
        page: pageCount,
        limit: 10,
    })
    const [param, setParam] = useState({
        type: "question",
        videoResourceId: myVideoResources?.videoResource?.id,
        question: "",

    })
    const [hasMoreQA, setHasMoreQA] = useState(true);

    useEffect(() => {
        { renderPage ? <></> : togglePageLoader(true) }
        togglePageLoader(true);
        getVideoResourceSearch(viewMoreQA, (res) => {
            togglePageLoader(false); setQAList(res?.items); if (searchQa.search) {
                setSearchQa({ search: '' });
            }
        })
    }, [renderPage])

    useEffect(() => {
        if (searchQa.search) {
            getVideoResourceSearch(searchQa, (res) => {
                togglePageLoader(false);
                setQAList(res?.items);
            });
        }
    }, [searchQa])

    const handlePost = () => {
        postQaRequest(param, () => {
            clearInput();
            setRenderPage(!renderPage);
            togglePageLoader(false);
        });
    }

    const clearInput = () => {
        setSearch('');
        inputRef.current.focus();
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    useEffect(() => {
        getVideoResourceSearch(viewMoreQA, (res) => {
            togglePageLoader(false);
            setMetaQAdata(res);
            // setQAList(res?.items);
            setQAList((prevData) => [...prevData, ...res?.items]);
        });
    }, [viewMoreQA]);

    const handleSearchQA = (val) => {
        setSearchQa({ search: val, id: itemIdVideoResource });
        getVideoResourceSearch(searchQa, (res) => {
            togglePageLoader(false);
            setQAList(res?.items);
        });
    };

    const handlePostInputText = (e) => {
        setSearch(e.target.value);
        setParam({
            type: "question",
            videoResourceId: myVideoResources?.videoResource?.id,
            question: e.target.value,
        })
    }

    const Review = ({ rList }) => {
        const [likeInfo, setLikeInfo] = useState({
            itemLiked: +rList?.liked,
            itemLikeCount: rList?.likeCount,
        });
        const [editText, setEditText] = useState(false);
        const [reText, setReText] = useState('');
        const [paramText, setParamText] = useState({
            type: "question",
            videoResourceId: myVideoResources?.videoResource?.id,
            id: rList?.id,
            question: '',
        })

        const handleLike = (id) => {
            let newLiked = !likeInfo?.itemLiked;
            patchLikeVideoResources(id, myVideoResources?.videoResource?.id, !newLiked, (res) => {
                if (res) {
                    setLikeInfo((prev) => ({
                        ...prev,
                        itemLiked: newLiked,
                        itemLikeCount: res?.likeCount,
                    }));
                }
            });
        };


        const DateDisplay = ({ date }) => {
            const backendDate = new Date(date);
            const timeDifference = Date.now() - backendDate.getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
            const hoursDifference = Math.floor(timeDifference / (1000 * 3600));
            const minutesDifference = Math.floor(timeDifference / (1000 * 60));
            if (daysDifference > 0) {
                return <div>{daysDifference} {daysDifference === 1 ? 'day' : 'days'} ago</div>;
            } else if (hoursDifference > 0) {
                return <div>{hoursDifference} {hoursDifference === 1 ? 'hour' : 'hours'} ago</div>;
            } else if (minutesDifference > 0) {
                return <div>{minutesDifference} {minutesDifference === 1 ? 'minute' : 'minutes'} ago</div>;
            } else {
                return <div>Now</div>;
            }
        };

        const handleEditQuestion = () => {
            setReText(rList?.question)
            setEditText(true);
        }
        const handleReText = (val) => {
            setReText(val.target.value);
            setParamText({
                type: "question",
                videoResourceId: myVideoResources?.videoResource?.id,
                id: rList?.id,
                question: val.target.value,
            })
        }

        const handleTextPost = () => {
            postQaRequest(paramText, () => {
                clearInput();
                setRenderPage(!renderPage);
            });
        }
        const handleDeleteQuestion = (id) => {
            deleteQuestionText(id, () => {
                setRenderPage(!renderPage);
            });
        }
        return (
            <>
                <div className='m-t-30' style={{ display: "flex", width: "100%", }}>
                    <div style={{
                        position: " relative",
                    }}>
                        <img style={{ borderRadius: "100%", width: "50px", height: "50px" }} src={`${Api._s3_url}${rList?.image}`} />
                    </div>
                    <div className='m-l-10' style={{ width: "100%" }}>
                        <span className='font-bold' style={{ marginBottom: "20px", fontSize: "14px" }} >
                            {editText ?
                                <div>
                                    <div className='m-t-0'><Input ref={inputRef} value={reText} onChange={(e) => handleReText(e)} style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "16px" }} /></div>
                                    <div className='r-c-fe font-bold m-t-10'>
                                        <div className='m-r-20 cursor-pointer' onClick={() => handleTextPost()} style={{ color: "#594099" }}>POST</div>
                                        <div onClick={() => { setEditText(false) }} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                                    </div>
                                </div>
                                : <>
                                    {rList?.question}
                                    {user?.id == rList?.userId ?
                                        <EditOutlined onClick={() => handleEditQuestion()} style={{ color: "#594099", cursor: "pointer", marginLeft: 10 }} />
                                        : <></>}
                                </>}
                        </span>
                        <div style={{ fontSize: "14px", paddingTop: "5px", fontSize: "13px" }}>{rList?.answer}</div>
                        <div className='font-bold' style={{ fontSize: "12px", display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            {rList?.answer != null ? <div key={rList?.id} className='r-c-fs'>

                                {likeInfo?.itemLikeCount}&nbsp;likes

                                <div style={{ display: 'flex', marginLeft: '10px' }} onClick={() => handleLike(rList?.id)}>
                                    <img
                                        src={likeInfo?.itemLiked ? LikedColored : LikedOutlined}
                                        style={{ width: '20px', cursor: 'pointer' }}
                                        alt={likeInfo?.itemLiked ? 'Liked' : 'Not Liked'}
                                    />
                                </div>
                            </div> : <>
                                {user?.id == rList?.userId ?
                                    <span onClick={() => handleDeleteQuestion(rList?.id)} style={{ color: "#FF414D", fontSize: 12, fontWeight: 'bold', width: "100%", cursor: "pointer" }}>DELETE QUESTION</span>
                                    : <></>}
                            </>}

                            <div className='r-c-fe' style={{ fontWeight: "lighter", width: "100%" }}>{rList?.username}
                                <div style={{
                                    width: "4px", height: "4px", borderRadius: "50%",
                                    display: "inline-block",
                                    margin: "0 10px",
                                    backgroundColor: "black",
                                    opacity: 0.4
                                }}>
                                </div>
                                <div><DateDisplay date={rList?.createdAt} /></div>
                            </div>
                        </div>

                    </div>

                </div>
            </>
        )
    }

    const fetchQAdata = () => {
        const updatedViewMoreQA = {
            id: itemIdVideoResource,
            page: pageCount + 1, // Update pageCount directly instead of relying on prev
            limit: 10,
        };
        setViewMoreQA(updatedViewMoreQA);
        if (pageCount <= metaQAdata?.meta?.totalPages) {
            setHasMoreQA(false);
            togglePageLoader(false);
        }

    };

    return (
        <div>
            <div className='parent'>
                <div className='parent-compo'>
                    <div className='container-1'>
                        <PageHeader title={myVideoResources?.videoResource?.name} onBackPress={() => { history.goBack(); }} />
                    </div>
                    <div className='container-3-videoResource'>
                        <>
                            <div className='r-c-sb'>
                                <div className='font-bold text-sm'>Q&A</div>
                                <div style={{ width: "300px" }}>
                                    <AutoComplete allowClear className='round-bordered-search' value={searchQa?.search} suffixIcon onSearch={(value) => handleSearchQA(value)} showSearch={true} placeholder="Search" style={{ width: "100%" }} />
                                </div>
                            </div>
                            {questionAnswerData?.length > 0 ?
                                (<>
                                    <div>
                                        <div className='m-t-20'><Input ref={inputRef} value={search} placeholder={"Couldn't find your question, post it now!"} onChange={(e) => handlePostInputText(e)} style={{ padding: "20px", border: "1px solid #E6E6E6", width: "100%", height: "60px", borderRadius: "16px" }} /></div>
                                        <div className='r-c-fe font-bold m-t-10'>
                                            {search ?
                                                <>
                                                    <div className='m-r-20 cursor-pointer' onClick={() => handlePost()} style={{ color: "#594099" }}>POST</div>
                                                    <div onClick={() => clearInput()} style={{ color: "#FF414D", cursor: "pointer" }}>CANCEL</div>
                                                </>
                                                : <></>}
                                        </div>
                                    </div>

                                    <div style={{}}>
                                        <InfiniteScroll
                                            dataLength={qAList?.length || 0}
                                            next={() => fetchQAdata()}
                                            hasMore={hasMoreQA}
                                            loader={<h4>Loading...</h4>}
                                            // endMessage={<p>You are all set!!!</p>}
                                            height={600}
                                            style={{ marginTop: "20px" }}
                                            className='scroll-bar-universal'

                                        >
                                            {qAList &&
                                                qAList?.map((item, index) => (
                                                    <Review key={item?.id} rList={item} />
                                                ))}
                                        </InfiniteScroll>
                                    </div>
                                </>)
                                :
                                <div style={{ marginTop: "50px" }}><NodataCard /></div>}
                        </>
                    </div>
                </div>
                {/* <PageDataLoader visible={pageLoader} /> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { myVideoResources, quizFlowCount, questionAnswerData } = state?.studentMarketPlace
    const { user } = state.session
    return {
        myVideoResources, quizFlowCount, questionAnswerData, user
    }
};

const mapDispatchToProps = (dispatch) => ({
    getVideoResourcesData: (id, callback) => dispatch(getVideoResourcesData(id, callback)),
    postQaRequest: (param, callback) => dispatch(postQaRequest(param, callback)),
    patchLikeVideoResources: (id, videoResourceId, unlike, callback) => dispatch(patchLikeVideoResources(id, videoResourceId, unlike, callback)),
    setQuizFlowCount: (val) => dispatch(setQuizFlowCount(val)),
    getVideoResourceSearch: (param, callback) => dispatch(getVideoResourceSearch(param, callback)),
    deleteQuestionText: (id, callback) => dispatch(deleteQuestionText(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswerSection)
