import React, { useEffect, useState } from "react";
import "./careStyles.css";
import CareCalender from "./careCalender";
import BarsComponents from "./barsComponents";
import { barGraphPlotFunction } from "actions/adminCare";
import { connect } from "react-redux";
import { Col, Row } from "antd";
import image1 from "../../Assets/care/no-subjects.svg";
import moment from "moment";

// function setScale(array) {
//   let max = 0;
//   array.forEach(function (item) {
//     if (max < parseInt(item)) {
//       max = item;
//     }
//   });

//   let var1 = parseInt(Math.ceil(max / 5));
//   for (let i = 0; i < 6; i++) {
//     arrayAxis[5 - i] = var1 * i;
//   }
// }


// const arrayAxis = []
// var count = 0;
function AdminSummary({ barGraphPlotFunction, totalProblemsWithCount, summaryData, startDate, endDate, isMonthOrWeek, togglePageLoader }) {

  // const [scaleY, setScaleY] = useState([])
  // useEffect(() => {
  //     var array = [];
  //     for (let i = 0; i < totalProblemsWithCount?.length; i++) {
  //       array[count++] = totalProblemsWithCount[i].openCount;
  //       array[count++] = totalProblemsWithCount[i].closeCount;
  //     }

  //     setScale(array)
  //     setScaleY([...arrayAxis]);


  // }, [totalProblemsWithCount])

  // if (summaryData?.details && arrayAxis[4] != 0 && totalProblemsWithCount?.length) {
  //     return (
  //         <div className="careAdminSummaryCard"  >
  //             <Row style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
  //                 <Col span={8}>
  //                     <div>
  //                         <h1 className="adminSummary_h1" style={{ marginBottom: "0px" }}>Summary</h1>

  //                         <p className="adminSummary_p" style={{ fontSize: "12px" }}>{totalProblemsWithCount[0].date ? totalProblemsWithCount[0].date + ' ' + totalProblemsWithCount[totalProblemsWithCount.length - 1].date + '. This Week' : totalProblemsWithCount[0].month + ' - ' + totalProblemsWithCount[totalProblemsWithCount.length - 1].month + '.'+moment().format("YYYY")}</p>

  //                     </div>
  //                 </Col>
  //                 <Col span={16} >
  //                     <Row style={{ width: "100%" }}>
  //                         <Col lg={{ span: 24 }} xl={{ span: 12 }}>
  //                             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start", marginBottom: 5 }}>
  //                                 <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
  //                                     <div style={{ width: "20px", height: "20px", backgroundColor: "#99DED9", marginRight: "10px" }}></div><span>Open</span>
  //                                 </div>
  //                                 <div style={{ display: "flex", flexDirection: "row", marginLeft: "10px", marginTop: "2%" }}>
  //                                     <div style={{ width: "20px", height: "20px", backgroundColor: "#B2DFFF", marginRight: "10px" }}></div><span style={{ marginRight: "10px" }}>Closed</span>
  //                                 </div>
  //                             </div>
  //                         </Col>
  //                         <Col lg={{ span: 24 }} xl={{ span: 12 }}>
  //                             <div style={{ width: "100%", maxWidth: "200px" }}>
  //                                 <CareCalender togglePageLoader={togglePageLoader} barGraphPlotFunction={barGraphPlotFunction} />
  //                             </div>
  //                         </Col>
  //                     </Row>

  //                 </Col>
  //             </Row>
  //             <div style={{ display: "flex" ,width:"100%",height:"300px", flexDirection: "row", justifyContent: "flex-start" ,paddingTop:"7px"}}>



  //                 <div style={{ display: "flex", flexDirection: "column", width: "5%", marginTop: "-10px", height: "230px", justifyContent: "space-between" }}>
  //                     {totalProblemsWithCount.length != 0 && scaleY.map((item) =>

  //                         <div className="daysOrMonths" key={item.id} >{item}</div>


  //                     )}

  //                 </div>

  //                 <div className="barGraphArea" style={{width:"500%",overflowX:"scroll",overflowY:"hidden",height:"250px"}} >
  //                     <div style={{ width: "100%", height: "210px", marginTop: "2px" }}>
  //                         <div style={{ display: "flex", flexDirection: "row", width: "97%", height: "210px", justifyContent: "space-between" }}>


  //                         { console.log("arrayAxis",arrayAxis)}
  //                         {console.log("totalProblemsWithCount",)}
  //                             {/* {arrayAxis[4] != 0 && totalProblemsWithCount.map((item) =>

  //                                 <div key={item.id} style={{ height: "100%", width: "100%",marginLeft:"10px" }}>


  //                                     <BarsComponents openproblemsNo={item.openCount} closeproblemsNo={item.closeCount} dateOrMonth={item.date ? item.date.substring(5, 7) + '/' + item.date.substring(8, 10) : item.month} maxHeight={Math.max(...arrayAxis)} />
  //                                 </div>

  //                             )} */}
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>

  //         </div>
  //     )
  // }
  //  console.log("data",data)
  // const data =  totalProblemsWithCount?.map((item)=>({
  //   "count": item.openCount,
  //   "counts": item.closeCount,
  //   "date":moment(item.date).format('MM/DD'),
  //    "month":item.month

  // }))
  //     const [ graph , setGraph ]=useState([])

  // console.log("totalProblemsWithCount",totalProblemsWithCount)
  //     useEffect(() => {
  //       let temp= [];
  //       for(let i=0; i < totalProblemsWithCount ?. length; i++ ){
  //           let obj={}
  //           if(obj['date']){
  //             obj['key']=obj['date']
  //           }
  //           else{
  //             obj['key'] = obj['short_date'];
  //           }

  //           obj['range']=totalProblemsWithCount[i].openCount,
  //           obj['name']='CLOSED CONVERSATIONS'
  //           temp.push(obj);

  //           let  obj1={}
  //           if(obj1['date']){
  //             obj1['key']=obj1['short_date']
  //           }
  //           else{
  //             obj1['key'] = obj1['month'];
  //             obj1['date']=totalProblemsWithCount[i].month
  //           }

  //           obj1['range']=totalProblemsWithCount[i].closeCount,
  //           obj1['name']='CLOSED CONVERSATIONS'
  //           temp.push(obj1);



  // let  obj2={}
  // obj2['date']=moment(summaryOfStudent?.weeklySummary[i].date).format('MM-DD')
  // // obj2['range']=summaryOfStudent?.weeklySummary[i].total,
  // obj2['name']='Total Classes'
  // temp.push(obj2);

  //   }
  //   setGraph([...temp])
  //   console.log("temp",temp)

  // }, [totalProblemsWithCount])

  const [graph, setGraph] = useState([])

  console.log("totalProblemsWithCount", totalProblemsWithCount)
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalProblemsWithCount?.length; i++) {
      let obj = {}
      obj['date'] = totalProblemsWithCount[i].short_date

      obj['range'] = (parseInt(totalProblemsWithCount[i].openCount)),
        obj['name'] = 'OPEN CONVERSATIONS'
      temp.push(obj);

      let obj1 = {}
      obj1['date'] = totalProblemsWithCount[i].short_date
      obj1['range'] = (parseInt(totalProblemsWithCount[i].closeCount)),
        obj1['name'] = 'CLOSED CONVERSATIONS'
      temp.push(obj1);

      let obj2 = {}
      obj2['date'] = totalProblemsWithCount[i].month
      obj2['range'] = (parseInt(totalProblemsWithCount[i].openCount)),
        obj2['name'] = 'OPEN CONVERSATIONS'
      temp.push(obj2);

      let obj3 = {}
      obj3['date'] = totalProblemsWithCount[i].month
      obj3['range'] = (parseInt(totalProblemsWithCount[i].closeCount)),
        obj3['name'] = 'CLOSED CONVERSATIONS'
      temp.push(obj3);

      let obj4 = {}
      obj4['date'] = totalProblemsWithCount[i].year
      obj4['range'] = (parseInt(totalProblemsWithCount[i].closeCount)),
      obj4['name'] = 'OPEN CONVERSATIONS'
      temp.push(obj4);

      let obj5 = {}
      obj5['date'] = totalProblemsWithCount[i].year
      obj5['range'] = (parseInt(totalProblemsWithCount[i].closeCount)),
      obj5['name'] = 'CLOSED CONVERSATIONS'
      temp.push(obj5);
    }
    temp = temp.filter(el => el.date !== undefined);
    setGraph([...temp])
    console.log("AA-temp", temp)

  }, [totalProblemsWithCount])




  return (
    <div className="careAdminSummaryCard">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <div>
            <h1 className="adminSummary_h1" style={{ marginBottom: "0px" }}>Summary</h1>
            <p className="adminSummary_p" style={{ fontSize: "12px" }}>
              {totalProblemsWithCount?.[0]?.date ? 
              moment(totalProblemsWithCount?.[0]?.date).format('DD-MM-YYYY') + ' ' + '-' + ' ' + moment(totalProblemsWithCount[totalProblemsWithCount?.length - 1]?.date).format('DD-MM-YYYY') :
              totalProblemsWithCount?.[0]?.month ?
              "Jan" + ' - ' + "Dec" + ' ' + ',' + ' ' + moment().format("YYYY") :
              totalProblemsWithCount?.[0]?.year ? 
              totalProblemsWithCount?.[0]?.year + " " + '-' + " " + totalProblemsWithCount[totalProblemsWithCount?.length - 1]?.year
              :
              null
            }
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "start",
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "#99DED9",
                borderRadius: "2px",
                marginRight: "10px",
                marginTop: "5px"
              }}
            ></div>
            <span style={{ fontSize: "12px", color: "#636363", marginRight: "20px" }}>Open</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "#B2DFFF",
                borderRadius: "2px",
                marginRight: "10px",
                marginTop: "5px"
              }}
            ></div>
            <span style={{ fontSize: "12px", color: "#636363" }}>Closed</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <div style={{ width: "200px" }}>
            <CareCalender
              togglePageLoader={togglePageLoader}
              barGraphPlotFunction={barGraphPlotFunction}
            />
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px"
          }}
        >
          {/* <img
            style={{ width: "30%", height: "30%", marginBottom: "20px" }}
            src={image1}
            alt=""
          /> */}
          <div style={{ width: "100%", height: "230px" }}>
            <BarsComponents data={graph?.length != 0 ? graph : []} />
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>
              No data!
            </h1>
            <p
              style={{
                color: "#636363",
                fontFamily: "roboto",
                textAlign: "center",
              }}
            >
              There are no data to show yet. Please try some other date range.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

// export default AdminSummary

const mapStateToProps = (state) => {
  const {
    recentUserList,
    summaryData,
    totalProblemsWithCount,
    startDate,
    endDate,
    isMonthOrWeek,
  } = state.adminCare;
  return {
    recentUserList,
    summaryData,
    totalProblemsWithCount,
    startDate,
    endDate,
    isMonthOrWeek,
  };
};

const mapDispatchToProps = (dispatch) => ({
  barGraphPlotFunction: (callback) => dispatch(barGraphPlotFunction(callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminSummary);
