import { Button, Checkbox, Modal, Typography } from 'antd'
import React,{useEffect, useState} from 'react';
import { Api } from '../../services';
import "./Modals/leaveDetailsModal.css";
import moment from 'moment';
import ConfirmRejectModal from './Modals/confirmRejectModal';
import ConfirmApproveModal from './Modals/confirmApproveModal';
import { connect } from 'react-redux';




const ButtonDiv = ({
    setIsLeaveModalVisible,
    requestedActionData,
    setActionData,
    setCardId,
    id,
    isRejectModalVisible,
    setIsRejectModalVisible,
    isApproveModalVisible,
    setIsApproveModalVisible,
    getLeavesTabData,
    togglePageLoader,
    user,
    storeLeaveId,
    setLeaveIds
}) =>{
    return(
        <div>
            {user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes("LeaveManager") ?
                <div style={{ display: "flex" }}>
                    <Button style={{
                        width: "140px",
                        height: "40px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #636363",
                        borderRadius: " 100px",
                        marginTop: "60px"
                    }}
                        onClick={(e) => {
                            e.stopPropagation()
                            console.log("clicked",)
                            setIsLeaveModalVisible(false)
                        }}>CLOSE</Button>
                    <div style={{ display: "flex", marginLeft: "200px" }}>
                        <Button
                            style={{
                                width: "140px",
                                height: "40px",
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                border: "1px solid #636363",
                                borderRadius: " 100px",
                                marginTop: "60px"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsRejectModalVisible(true);
                                setIsLeaveModalVisible(false);
                            }}
                        >REJECT</Button>
                        <ConfirmRejectModal
                            isRejectModalVisible={isRejectModalVisible}
                            setIsRejectModalVisible={setIsRejectModalVisible}
                            setCardId={setCardId}
                            id={id}
                            setActionData={setActionData}
                            requestedActionData={requestedActionData}
                            functionProp={getLeavesTabData}
                            togglePageLoader={togglePageLoader}
                            storeLeaveId={storeLeaveId}
                            setLeaveIds={setLeaveIds}
                        />
                        <Button
                            style={{
                                width: "140px",
                                height: "40px",
                                background: "#28DF99 0% 0% no-repeat padding-box",
                                borderRadius: " 100px",
                                marginTop: "60px",
                                color: "#FFFFFF",
                                marginLeft: "25px"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsApproveModalVisible(true);
                                setIsLeaveModalVisible(false);
                            }}
                        >APPROVE</Button>
                        <ConfirmApproveModal
                            isApproveModalVisible={isApproveModalVisible}
                            setIsApproveModalVisible={setIsApproveModalVisible}
                            setCardId={setCardId}
                            id={id}
                            setActionData={setActionData}
                            requestedActionData={requestedActionData}
                            functionProp={getLeavesTabData}
                            togglePageLoader={togglePageLoader}
                            storeLeaveId={storeLeaveId}
                            setLeaveIds={setLeaveIds}
                        />
                    </div>
                </div>
                :
                <div className='m-l-50' style={{ display: "flex" }}>
                    <div style={{ display: "flex", marginLeft: "200px" }}>
                        <Button style={{
                            width: "140px",
                            height: "40px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #636363",
                            borderRadius: " 100px",
                            marginTop: "60px"
                        }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsLeaveModalVisible(false)
                            }}>
                            CLOSE</Button>
                    </div>
                </div>
            }
        </div>
    )
}

const ApprovedDiv = ({
    actionByName,
    actionByImage,
    actionByRole,
    setIsLeaveModalVisible,
    id,
    setCardId,
    setActionData,
    requestedActionData,
    getLeavesTabData,
    isRejectModalVisible,
    setIsRejectModalVisible,
    togglePageLoader,
    requestType,
    user,
    leaveStartDate,
    storeLeaveId,
    setLeaveIds
}) => {
   
    return(
        <div style={{height:"300px"}}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                {console.log("ABCD",leaveStartDate,moment().add(1, 'days').format("YYYY-MM-DD"),(leaveStartDate>moment().add(1, 'days').format("YYYY-MM-DD")),moment(leaveStartDate).diff(moment(),"days"))}
                {requestType==="Leave" && 
                (user?.privilage.includes("SuperAdmin")||user?.privilage.includes("LeaveManager")) && 
                moment(leaveStartDate).diff(moment(),"days")>0 ?
                ( 
                <div>
                    <Button 
                    // loading={isApproveDivLoading}
                    style={{font:" normal normal bold 13px/19px Roboto",color: "#FF414D",marginTop:'30px',border:"none"}}
                    onClick={(e)=>{
                        e.stopPropagation();
                        setIsRejectModalVisible(true);
                        setIsLeaveModalVisible(false);
                    }}>Change to Rejected
                    </Button>
                </div>
                ):(<div style={{width:"300px"}}>{" "}</div>)
                
                }
                {isRejectModalVisible && 
                <ConfirmRejectModal
                isRejectModalVisible={isRejectModalVisible}
                setIsRejectModalVisible={setIsRejectModalVisible}
                setCardId={setCardId}
                id={id}
                setActionData={setActionData}
                requestedActionData={requestedActionData}
                functionProp={getLeavesTabData}
                togglePageLoader={togglePageLoader}
                storeLeaveId={storeLeaveId}
                setLeaveIds={setLeaveIds}

                />}
            <div style={{width:"140px",height:"60px",marginTop:"20px"}}>
                <div style={{font: "normal normal bold 11px/16px Roboto",color: "#AEAEAE"}}>APPROVED BYs</div>
                <div style={{display:"flex",marginTop:"5px"}}>
                <div><img style={{width:"50px",height:"50px",borderRadius:"50%"}} src={`${Api._s3_url}${actionByImage}`}></img></div>
                <div style={{marginLeft:"10px",marginTop:"10px"}}>
                    <Typography.Text className="text-overflow-ellipsis" style={{font: "normal normal bold 14px/16px Roboto",color: "#191919",width:"80px"}} ellipsis={{tooltip:true}}>{actionByName}</Typography.Text>
                    <div style={{font: "normal normal normal 13px/16px Roboto",color:" #636363",marginTop:"3px"}}>{actionByRole}</div>
                </div>
                </div>
            </div>
            </div>
            <Button style={{width:"140px",
            height:"40px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius:" 100px",
            marginTop:"20px",marginLeft:"250px"}} 
            onClick={(e)=>{
                e.stopPropagation()
            console.log("clicked",)
            setIsLeaveModalVisible(false)
            }}>CLOSE</Button>
        </div>
    )
}

const RejectedDiv = ({
    actionByName,
    actionByImage,
    actionByRole,
    setIsLeaveModalVisible,
    setActionData,
    setCardId,
    id,
    requestedActionData,
    getLeavesTabData,
    isApproveModalVisible,
    setIsApproveModalVisible,
    togglePageLoader,
    user,
    storeLeaveId,
    setLeaveIds
}) => {
    const [isRejectDivLoading,setIsRejectDivLoading]=useState(false);

    return(
        <div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
            {user?.privilage.includes("SuperAdmin")||user?.privilage.includes("LeaveManager") ?
            <div>
            <Button
            loading={isRejectDivLoading}
            style={{font:" normal normal bold 13px/19px Roboto",color: "#28DF99",marginTop:'30px',border:"none"}}
            onClick={(e)=>{
                e.stopPropagation();
                setIsApproveModalVisible(true);
                setIsLeaveModalVisible(false);
            }}
                >Change to Approve
                </Button>
                </div>
                :
                <div style={{width:"300px"}}>{" "}</div>
        }
                <ConfirmApproveModal
                isApproveModalVisible={isApproveModalVisible}
                setIsApproveModalVisible={setIsApproveModalVisible}
                setCardId={setCardId}
                id={id}
                setActionData={setActionData}
                requestedActionData={requestedActionData}
                functionProp={getLeavesTabData}
                togglePageLoader={togglePageLoader}
                storeLeaveId={storeLeaveId}
                setLeaveIds={setLeaveIds}
                />
            <div style={{width:"140px",height:"60px",marginTop:"20px"}}>
                <div style={{font: "normal normal bold 11px/16px Roboto",color: "#AEAEAE"}}>REJECTED BY</div>
                <div style={{display:"flex",marginTop:"5px"}}>
                <div><img style={{width:"50px",height:"50px",borderRadius:"50%"}} src={`${Api._s3_url}${actionByImage}`}></img></div>
                <div style={{marginLeft:"10px"}}>
                    <Typography.Text className="text-overflow-ellipsis" style={{font: "normal normal bold 14px/16px Roboto",color: "#191919",width:"80px"}} ellipsis={{tooltip:true}}>{actionByName}</Typography.Text>
                    <div style={{font: "normal normal normal 13px/16px Roboto",color:" #636363",marginTop:"3px"}}>{actionByRole}</div>
                </div>
                </div>
            </div>
            </div>
            <Button style={{width:"140px",
            height:"40px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius:" 100px",
            marginTop:"20px",marginLeft:"250px"}}
            onClick={(e)=>{
                e.stopPropagation()
            console.log("clicked",)
            setIsLeaveModalVisible(false)
            }}>CLOSE</Button>
        </div>
    )
}

function LeaveDetailsModal({
    storeNumberOfDays,
    requests,
    isLeaveModalVisible,
     setIsLeaveModalVisible,
     leaveType,
     leaveDuration,
     leaveStartDate,
     leaveEndDate,
     leaveReason,
     leaveStatus,
     handleCancel,
     leaveCardId,
     setCardId,
     setActionData,
     requestedActionData,
     actionByImage,
     actionByName,
     actionByRole,
     getLeavesTabData,
     tabDataContainer,
     createdAtTime,
     clockInTime,
     clockOutTime,
     totalHoursDone,
     reason,
     isRejectModalVisible,
     setIsRejectModalVisible,
     isApproveModalVisible,
     setIsApproveModalVisible,
     togglePageLoader,
     requestType,
     user,
     startDate,
     storeLeaveId,setLeaveIds, specificUserId
    }) {
         const [reload, setReload] = useState(true);
         const [storeLeaveId1,setStoreLeaveId1] = useState(storeLeaveId)
         const handleCheckAll=(e)=>{
            let temp=[]
            if(e){
                requests?.forEach(element =>{
                    temp?.push(element?.id)
                })
            }
            setStoreLeaveId1(temp)
            setReload(!reload);
        }
        let temp2 = storeLeaveId1
        const handleChange=(id,e)=>{
        
            if(temp2.includes(id) && e===false){
                temp2.splice(temp2?.indexOf(id),1);
                }else{
                temp2.push(id)
              }
              setStoreLeaveId1(temp2)
              setLeaveIds(storeLeaveId1)
        }
        
        

  return (
    <div>
        <Modal className='modal-round-corner1'
        centered
        footer={null}  
        header={null}
        onCancel={(e)=>{
            e.stopPropagation();
            setIsLeaveModalVisible(false)}} 
        visible={isLeaveModalVisible}>
            <div >
            <div style={{
              marginTop: "25px",
              fontSize: "24px",
              color: "#191919",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
            }}>{tabDataContainer==="Leaves"?"Leave Details":"Request Attendance Details"}
                </div>
                {tabDataContainer==="Leaves"?
            <div style={{background: "#FAFAFA 0% 0% no-repeat padding-box",borderRadius: "6px",height:'160px', marginTop: "30px"}}>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"25px",width:90}}>Leave type</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"25px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"25px"}}>
                   {leaveType}{" "}Leave</div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:90}}>Day(s)</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>
                {leaveDuration}{" "}
                   {
                       leaveDuration>1?"days":"day"
                   }</div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:90}}>Date(s)</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{display:"flex"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>{leaveStartDate}</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"2px",marginTop:"8px"}}>-{" "}{leaveEndDate}</div>
                </div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:90}}>Reason</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>
                    {leaveReason}</div>
            </div>
            </div>
            :
            <div style={{background: "#FAFAFA 0% 0% no-repeat padding-box",borderRadius: "6px",height:'160px', marginTop: "30px"}}>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"25px",width:100}}>Date</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"25px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"25px"}}>
                { moment.utc(startDate).local().format("DD/MM/YYYY")}</div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:100}}>Clock in</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>
                {clockInTime}</div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:100}}>Clock out</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>
                {clockOutTime}</div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:100}}>Total hours</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>
                {totalHoursDone}{" "}hours</div>
            </div>
            <div style={{display:"flex",marginLeft:"30px"}}>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px",width:100}}>Reason</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color: "#636363",marginTop:"8px"}}>:</div>
                <div style={{font: "normal normal normal 15px/18px Roboto",color:" #191919",marginLeft:"20px",marginTop:"8px"}}>
                {reason}</div>
            </div>
            </div>
            }
            {tabDataContainer==="Leaves" ? 
            <div>
            <div style={{marginLeft:"30px",marginTop:"45px"}}>
                <Checkbox 
                checked ={(requests?.length == 0 || requests?.length ===  storeLeaveId1?.length)}
                onChange={(e)=>{
                    handleCheckAll(e.target.checked)
                }} > <p style={{color:"#636363",font: "normal normal normal 15px/18px Roboto"}}>Select All</p></Checkbox>
            </div>
          <div >
            {requests?.map((item)=>(
                <div style={{background: "#FAFAFA 0% 0% no-repeat padding-box",borderRadius: "6px", marginTop: "30px",borderBottom:"1px solid #FAFAFA"}}>
                    <div style={{display:"flex",marginTop:"-25px",marginLeft:"30px"}}>
                        {console.log("AA-storeLeaveId",item)}
                    <Checkbox
                    checked={storeLeaveId1?.includes(item?.id)}
                    onChange={(e)=>{
                        handleChange(item?.id,e.target.checked)
                    }}
                    > <p style={{font: "normal normal normal 15px/18px Roboto",marginTop:"10px"}}>{item?.date} </p></Checkbox>
                    <div style={{font: "normal normal normal 15px/18px Roboto" ,marginTop:"10px",marginLeft:"180px",}}>Full day</div>
                    <div style={{
                                marginLeft:"150px",
                                height: "14px",
                                width: "47px",
                                marginTop: "15px",
                                border: `${item?.status === "Pending" ? "1px solid #FFA931" : item?.status === "Approved" ? "1px solid #28DF99" : item?.status === "Rejected" ? "1px solid #FF414D" : null}`,
                                borderRadius: " 3px",
                                marginRight: "10px",
                                background: `${item?.status === "Pending" ? "#FFF4D5" : item?.status === "Approved" ? "#E9FCF5" : item?.status === "Rejected" ? "#FFE4D9" : null}`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                                 }}>
                            <div style={{
                                font: "normal normal bold 8px/10px Roboto",
                                color: `${item?.status === "Pending" ? "#FFA931" : item?.status === "Approved" ? "#28DF99" : item?.status === "Rejected" ? "#FF414D" : null}`
                            }}>{item?.status === "Pending" ? "PENDING" : item?.status === "Approved" ? "APPROVED" : item?.status === "Rejected" ? "REJECTED" : null}</div>
                    </div>
                    </div>
                 </div>
                
            ))}
            </div>
            </div>:null}
            <div>
            {(leaveStatus==="Pending" || leaveStatus==="Partially approved" ) && user?.id !== specificUserId ?
            <ButtonDiv
            storeLeaveId={storeLeaveId}
            setLeaveIds={setLeaveIds}
            isLeaveModalVisible={isLeaveModalVisible}
            setIsLeaveModalVisible={setIsLeaveModalVisible}
            requestedActionData={requestedActionData}
            id={leaveCardId}
            setCardId={setCardId}
            setActionData={setActionData}
            getLeavesTabData={getLeavesTabData}
            isRejectModalVisible={isRejectModalVisible}
            setIsRejectModalVisible={setIsRejectModalVisible}
            isApproveModalVisible={isApproveModalVisible}
            setIsApproveModalVisible={setIsApproveModalVisible}
            togglePageLoader={togglePageLoader}
            user={user}
            />
            :
            leaveStatus==="Approved"?
            <ApprovedDiv
            storeLeaveId={storeLeaveId}
            setLeaveIds={setLeaveIds}
            actionByImage={actionByImage}
            actionByName={actionByName}
            actionByRole={actionByRole}
            setIsLeaveModalVisible={setIsLeaveModalVisible}
            id={leaveCardId}
            setCardId={setCardId}
            requestedActionData={requestedActionData}
            setActionData={setActionData}
            getLeavesTabData={getLeavesTabData}
            isRejectModalVisible={isRejectModalVisible}
            setIsRejectModalVisible={setIsRejectModalVisible}
            isApproveModalVisible={isApproveModalVisible}
            setIsApproveModalVisible={setIsApproveModalVisible}
            togglePageLoader={togglePageLoader}
            requestType={requestType}
            leaveStartDate={leaveStartDate}
            user={user}
            />
            :
            leaveStatus==="Rejected"?
            <RejectedDiv
            storeLeaveId={storeLeaveId}
            setLeaveIds={setLeaveIds}
            actionByImage={actionByImage}
            actionByName={actionByName}
            actionByRole={actionByRole}
            setIsLeaveModalVisible={setIsLeaveModalVisible}
            id={leaveCardId}
            setCardId={setCardId}
            setActionData={setActionData}
            requestedActionData={requestedActionData}
            getLeavesTabData={getLeavesTabData}
            isRejectModalVisible={isRejectModalVisible}
            setIsRejectModalVisible={setIsRejectModalVisible}
            isApproveModalVisible={isApproveModalVisible}
            setIsApproveModalVisible={setIsApproveModalVisible}
            togglePageLoader={togglePageLoader}
            user={user}
            />:null
                }           


            </div>
            
            </div>           
        </Modal>
    </div>
  )
}



export default LeaveDetailsModal;

