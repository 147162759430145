import {
    setCourseDetails,
    getStudentsRollNo,
    getCourseDetalis,
    updateScholarshipOrAddition,
    unassignStudent,
    setRollNumber,
    UpdateRollNumber,
  } from "actions/userManagement";
  
  import { Button, Input, Select, Typography, Alert, Table, Popover } from "antd";
  import React, { useEffect, useState } from "react";
  import { connect } from "react-redux";
  import EditCourseModal from "./editCourseModal";
  import InstallmentCard from "./installmentCard";
  import ScholarshipAndAdditionModal from "./scholarshipAndAdditionModal";
  import NoCourseImage from "../../Assets/course/ill-courses.png";
  import CourseEditModal from "./courseEditModal";
  
  import {
    ArrowLeftOutlined,
    BellOutlined,
    CheckCircleOutlined,
    DeleteFilled,
    EditFilled,
    EditOutlined,
    FilePdfOutlined,
    LoadingOutlined,
    MoreOutlined,
    ReloadOutlined,
    StopOutlined,
    SwapOutlined,
  } from "@ant-design/icons";
  import AddCourseModal from "./addCourseModal";
  import CourseDeleteConfirmModal from "./courseDeleteConfirmModal";
  import moment from "moment";
  import { setCoursePrice, setInstallments } from "actions/course";
  import PageDataLoader from "components/pageDataLoader";
  import CourseCard from "./courseCard";
  import { setFeeDetails } from "actions/feeManagement";
  import FeeCourseCard from "./feeCourseCard";
  import CourseDetailsModal from "./courseDetailsModal";
  import UnAssignConfirmModal from "./unAssignConfirmModal";
  import PreferedCourseCard from "./preferedCourseCard";
  import EditOfflinePayment from "./editOfflinePayment";
  import EidtScholerShipAddModal from "./eidtScholerShipAddModal";
  import { useHistory } from "react-router";
  import { Notification } from "services";
import FeeCourseCardNew from "./feeCourseCardNew";
  

  const columns = () => {
    return [
        {
            title: 'No',
            dataIndex: 'paymentId',
            key: 'paymentId',
            render: (id, record, index) => index + 1
        },
        {
            title: 'Request Details',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (id, item, index) => (
                <FeeCourseCardNew
                type='Note'
                item={item}
                // status="Pending"
                amount={item?.amountPayable}
                dueDate={item?.dueDate}
                issueDate={item?.date}
                status={item?.status}
              />
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amountPayable',
            key: 'amountPayable',
            render: (value, record) => <div>₹ {record ? (record?.amountPayable).toFixed(2) : 0}</div>
        },
        {
            title: 'Issue Date',
            dataIndex: 'issueDate',
            key: 'issueDate',
            render: (value, record) => <div>{record?.status !== 'Not Requested' ? moment(record?.date, 'DD-MM-YYYY').format('DD-MM-YYYY') : '-'}</div>
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (value, record) => (<div> {record.dueDate
                ? moment(record.dueDate, "DD-MM-YYYY").format("DD/MM/YYYY")
                : "-"}</div>)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value, item) => {
                return (
                    <FeeCourseCardNew
                    type='Status'
                    item={item}
                    // status="Pending"
                    amount={item?.amountPayable}
                    dueDate={item?.dueDate}
                    issueDate={item?.date}
                    status={item?.status}
                  />
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'status',
            key: "status",
            render: (value, item) => {
                return (
                    <FeeCourseCardNew
                    type='Action'
                    item={item}
                    // status="Pending"
                    amount={item?.amountPayable}
                    dueDate={item?.dueDate}
                    issueDate={item?.date}
                    status={item?.status}
                  />
                )
            }
        },
    ]
}
  
  function FeeDetailsNew({
    feeMangementData,
    setCourseDetails,
    studentCourseDetails,
    classList,
    installments,
    userDetailsById,
    getStudentsRollNo,
    nextRollNo,
    getCourseDetalis,
    setInstallments,
    setCoursePrice,
    userCourseUpdate,
    updateScholarshipOrAddition,
    feeDetails,
    unassignStudent,
    setRollNumber,
    UpdateRollNo,
    UpdateRollNumber,
    user
  }) {
    const history = useHistory();
  
    // const ButtonDisable = (dependency) => {
    //   for (let x of dependency){
    //     let bool = markAttendanceObj[x] === ''||markAttendanceObj[x]==='Invalid date'||markAttendanceObj[x]==undefined
    //     if (bool) return true
    //   }
    //   return false
    // }
  
  
    function numberWithCommas(x) {
      return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
    }
  
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAdditionModal, setIsAdditionModal] = useState(false);
    const [isCourseModalVisible, setIsCourseModalVisible] = useState(false);
    const [showCourseDetailsModal, setShowCourseDetailsModal] = useState(false);
    const [unAssignLdr, setUnAssignLdr] = useState(false);
    const [viewCourseDetails, setViewCourseDetails] = useState(
      feeDetails == true ? true : false
    );
    const [viewAddCourseModal, setViewAddCourseModal] = useState(false);
    const [viewEditCourseModal, setViewEditCourseModal] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [modalType, setModalType] = useState(true);
    const [viewCourseDeleteConfirmModal, setViewCourseDeleteConfirmModal] =
      useState(false);
    const [filteredSections, setFilteredSections] = useState([]);
    const [showUnassign, setShowUnassign] = useState(false);
    const [finalPayment, setFinalPayment] = useState(
      studentCourseDetails?.feeAmount
    );
    const [rollNoLoader, setRollNoLoader] = useState(false);
    useEffect(() => {
   
      if (
        studentCourseDetails?.isSectionChanged?.courseUpdate?.coursePopUp === true
      ) {

  
        if (studentCourseDetails?.sectionCoursePop === true) {
 
          setViewAddCourseModal(true);
        }
      }
    }, [studentCourseDetails?.isSectionChanged]);
  
    useEffect(() => {
      setFeeDetails(feeDetails == true ? true : false);
    }, [feeDetails]);
  
    useEffect(() => {
      let temp = 0;
      for (let i = 0; i < studentCourseDetails?.scholarships?.length; i++) {
        temp += parseFloat(
          studentCourseDetails?.scholarships[i]?.amountOrPercentage
        );
 
      }
      let removedTemp = 0;
      for (
        let i = 0;
        i < studentCourseDetails?.removedScholarships?.length;
        i++
      ) {
        removedTemp += parseFloat(
          studentCourseDetails?.removedScholarships[i]?.amountOrPercentage
        );
  
      }

      temp = temp ? parseFloat(temp) / 100 : 0;
      removedTemp = removedTemp ? parseFloat(removedTemp) / 100 : 0;
      let temp1 = studentCourseDetails?.additions?.[0]?.amountOrPercentage
        ? parseFloat(studentCourseDetails?.additions?.[0]?.amountOrPercentage)
        : 0;

      setFinalPayment(
        parseFloat(studentCourseDetails?.feeAmount) -
        studentCourseDetails?.feeAmount * temp +
        temp1
      );
  
      let tempArr = [];

      for (
        let i = 0;
        i < studentCourseDetails?.selectedCourse?.transactions?.length;
        i++
      ) {
        let sumPercentage = 0;
        studentCourseDetails?.scholarships?.map(
          (item) => (sumPercentage += parseFloat(item?.amountOrPercentage))
        );
        let addAmount = studentCourseDetails?.additions?.[0]?.amountOrPercentage
          ? parseFloat(studentCourseDetails?.additions?.[0]?.amountOrPercentage)
          : 0;
        let totalAmount =
          addAmount - (sumPercentage / 100) * studentCourseDetails?.feeAmount;
        // +
        // removedTemp * studentCourseDetails?.feeAmount;
  
        if (studentCourseDetails?.scholarshipsRemoved) {
          totalAmount =
            totalAmount - (sumPercentage / 100) * studentCourseDetails?.feeAmount;
        }
        let forTransaction =
          totalAmount /
          studentCourseDetails?.selectedCourse?.transactions?.length;
   
        tempArr[i] = {
          installment:
            parseFloat(
              studentCourseDetails?.selectedCourse?.transactions[i]?.amount
            ) + forTransaction,
  
          dueDate: studentCourseDetails?.selectedCourse?.transactions[i]?.dueDate,
        };
      }

      setInstallments(tempArr);
    }, [
      studentCourseDetails?.feeAmount,
      studentCourseDetails?.scholarships?.length,
      studentCourseDetails?.additions?.[0]?.amountOrPercentage,
      finalPayment,
    ]);

    const openModal = () => {
      setIsModalVisible(true);
    };
    const closeModal = () => {
      setIsModalVisible(false);
      setIsAdditionModal(false);
    };
  
    useEffect(() => {

   
    }, [
      studentCourseDetails?.feeAmount,
      studentCourseDetails?.scholarships?.length,
      studentCourseDetails?.additions?.length,
    ]);
  
    useEffect(() => {
      setCourseDetails("scholarships", []);

      setCourseDetails("additions", []);
      setCourseDetails(
        "feeAmount",
        studentCourseDetails?.selectedCourse?.totalFee
      );
      setCourseDetails(
        "totalPayable",
        studentCourseDetails?.selectedCourse?.totalPayable
      );
      setCourseDetails(
        "feePlan",
        studentCourseDetails?.selectedCourse?.durationType
      );
      setInstallments(
        studentCourseDetails?.selectedCourse?.installmentDetails
          ? JSON.parse(studentCourseDetails?.selectedCourse?.installmentDetails)
          : []
      );

      setCourseDetails(
        "scholarships",
        studentCourseDetails?.selectedCourse?.scholarshipDetails
          ? JSON.parse(studentCourseDetails?.selectedCourse?.scholarshipDetails)
          : []
      );
      setCourseDetails(
        "additions",
        studentCourseDetails?.selectedCourse?.additonDetails
          ? JSON.parse(studentCourseDetails?.selectedCourse?.additonDetails)
          : []
      );
      setCoursePrice(studentCourseDetails?.selectedCourse?.totalFee);
    }, [studentCourseDetails?.selectedCourse]);
  
  
    useEffect(() => {
      let temp = userDetailsById?.student?.standards?.filter((item) => {
        if (item?.std == studentCourseDetails?.class) {
          return item;
        }
      });

      if (temp != []) {
        setFilteredSections(temp);
      }

    }, [studentCourseDetails?.class]);
    useEffect(() => {
      setCourseDetails("class", userDetailsById?.student?.standard?.std);
      setCourseDetails("section", userDetailsById?.student?.standard?.section);
      setCourseDetails("sectionId", userDetailsById?.student?.standard?.id);
      setCourseDetails(
        "scholarships",
        userDetailsById?.student?.feeDetails?.scholarshipDetails
          ? JSON.parse(userDetailsById?.student?.feeDetails?.scholarshipDetails)
          : []
      );
      setCourseDetails(
        "additions",
        userDetailsById?.student?.feeDetails?.additonDetails
          ? JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)
          : []
      );
      setCourseDetails(
        "savedSholarshipLength",
        studentCourseDetails?.scholarships?.length
          ? studentCourseDetails?.scholarships?.length
          : 0
      );
      setCourseDetails("removedScholarships", []);
    }, [userDetailsById?.student]);
    // useEffect(() => {
    //   setCourseDetails("removedScholarships", []);
    // }, [studentCourseDetails?.selectedCourse]);
    useEffect(() => {

    }, [filteredSections]);
    useEffect(() => {
      setCourseDetails(
        "feeAmount",
        userDetailsById?.student?.feeDetails?.totalFee
      );
    }, [userDetailsById]);
  
    useEffect(() => {
      setCourseDetails("ApiC", !viewCourseDetails);
    }, [viewCourseDetails]);
    if (viewCourseDetails) {
      return (
        <div
          style={{
            width: "67%",
            height: "90vh",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          <div
            className="box-shadow"
            style={{
              // minHeight: "100%",
              height: "fit-content",
              padding: "40px",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontFamily: "roboto",
                  fontWeight: "bold",
                  // width: "100%",
                }}
              >
                Class details
              </div>
              {userDetailsById?.student?.standardId != -1 ? (
                <Button
                  loading={unAssignLdr}
                  onClick={() => {
                    // setUnAssignLdr(true);
                    // unassignStudent(() => {
                    //   setUnAssignLdr(false);
                    // });
                    setShowUnassign(true);
                  }}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid red",
                    color: "red",
                    fontSize: "10px",
                    fontWeight: "700",
                    height: "26px",
                    paddingTop: "2px",
                  }}
                >
                  UNASSIGN
                </Button>
              ) : null}
            </div>
            <div
              className="flex_row"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <div
                // className="flex_row_center"
                style={{ width: "50%" }}
              >
                <div style={{ width: "100%" }}>
       
                  <p className="dropDownLabelBasicStyle">Class</p>
                  <Select
                    allowClear
                    size="middle"
                    placeholder="Select  Class"
                    style={{ width: "97%", marginBottom: "20px" }}
                    onChange={(e) => {
                      setCourseDetails("class", e);
                      setCourseDetails("section", null);
                      setCourseDetails("sectionId", null);
                    }}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                  }}
  
  
                    value={
                      studentCourseDetails?.class
                        ? studentCourseDetails?.class
                        : null
                    }
                    disabled={!(user?.privilage.includes("SuperAdmin")||user?.privilage.includes("Admin"))}
                  // onChange={handleClassSelect}
                  >
        
  
                    {userDetailsById?.student?.standards?.map((item, index) => (
                      <Option value={item?.std}>{item?.std}</Option>
                    ))}
                  </Select>
                </div>
                <div style={{ width: "97%" }}>
                  <p className="dropDownLabelBasicStyle">Roll number</p>
                  
                  <div className="display-flex">
                  <Input
                    size="middle"
                    style={{ width: "100%", color: "black" }}
                    disabled={(studentCourseDetails?.class == undefined || studentCourseDetails?.class == null) || (studentCourseDetails?.section == undefined || studentCourseDetails?.section  == null) ? true : false}
                    value={studentCourseDetails?.class && studentCourseDetails?.section? nextRollNo : null}
                    onChange={(e)=>{
                      if(/[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/.test(e?.target?.value)){
                      Notification.error('Error', "Please enter roll no without special characters")
                    }else{
                      setRollNumber(e.target.value.trim())
                    }}}
                    placeholder="Enter Roll number"
                  >
                  </Input>
                    <Button disabled={(studentCourseDetails?.class == undefined || studentCourseDetails?.class == null) || (studentCourseDetails?.section == undefined || studentCourseDetails?.section  == null) || !nextRollNo ? true : false} onClick={()=>{
                      if(nextRollNo == 0){
                        Notification.error('Error', 'Roll number cannot be 0')
                      }else{
                        UpdateRollNumber();
                      }
                      }} className='purpleBtnWrap radius-100 purpleButtonHoverEffect m-l-10'>Update</Button>
                  </div>
                  {rollNoLoader ? (
                    <div
                      style={{
                        marginTop: "-28px",
                        marginLeft: "calc(100% - 160px)",
                      }}
                    >
                      <LoadingOutlined style={{ fontSize: "20px" }} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div style={{ width: "47%" }}>
                <p className="dropDownLabelBasicStyle">Section</p>
                <Select
                  placeholder="Select Section"
                  // defaultValue={null}
                  style={{ width: "100%" }}
                  size="middle"
                  onChange={(e) => {
                    setCourseDetails("section", e);
                    setCourseDetails("sectionId", e);
                    setRollNoLoader(true);
                    getStudentsRollNo(e, () => setRollNoLoader(false), true);
                  }}
                  /*                 value={studentCourseDetails?.section} */
                  value={
                    studentCourseDetails?.section !== -1
                      ? studentCourseDetails?.section
                      : null
                  }
                  disabled={!(user?.privilage.includes("SuperAdmin")||user?.privilage.includes("Admin"))}
                  getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
              }}
                // onChange={handleClassSelect}
                >
                  {/* {classList?.length &&
                classList.map(
                  (item, index) => (
                    // <div key={index} >
  
                    <Option key={index} value={item.id}>
                      {" "}
                      {item?.std}-{item?.section}:{item?.stream}
                    </Option>
                  )
                  // </div>
                )} */}
                 
                  {filteredSections?.[0]?.sectionNameArray?.map((item, index) => (
                    <Option value={filteredSections?.[0]?.sectionIdArray[index]}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="box-shadow">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "40px",
                paddingTop: "30px",
              }}
            >
              <div style={{ fontWeight: "600", fontSize: "14px" }}>Course(s)</div>
              {userDetailsById?.student?.userAssignedCourses &&
                userDetailsById?.student?.userAssignedCourses?.length != 0 ? (
                <div
                  onClick={() => {
                    // if (
                    //   userDetailsById?.student?.standardId == -1 ||
                    //   userDetailsById?.student?.standardId !==
                    //     studentCourseDetails?.sectionId
                    // ) {
                    //   userCourseUpdate();
                    // }
                    setViewAddCourseModal(true);
                  }}
                  style={{
                    fontWeight: "650",
                    fontSize: "12px",
                    color: "#1089FF",
                    cursor: "pointer",
                  }}
                >
                  + ADD A COURSE
                </div>
              ) : null}
            </div>
            {
              // (
              // studentCourseDetails?.class && studentCourseDetails?.section) ||
              // userDetailsById?.student?.userfee ? (
  
              <div
                className="flex_row_center"
                style={{
                  marginTop: "0px",
                  // marginLeft: "-20px",
                  flexWrap: "wrap",
                  paddingBottom: "20px",
                }}
              >
                {userDetailsById?.student?.preferredCourseCard
                  ?.preferredCourseCard == true ? (
                  <div>
                    <PreferedCourseCard
                      item={userDetailsById?.student?.preferredCourseCard}
                      setViewAddCourseModal={setViewAddCourseModal}
                      setCourseDetails={setCourseDetails}
                    />
                  </div>
                ) : null}
                {userDetailsById?.student?.userAssignedCourses &&
                  userDetailsById?.student?.userAssignedCourses?.map((item) => (
                    // {[true].map((item) => (
                    <div
                      onClick={() => {
                        setShowCourseDetailsModal(true);
                        setCourseDetails("selectedCourse", item);
                      }}
                    >
                      <CourseCard item={item} />
                    </div>
                  ))}
                {userDetailsById?.student?.userAssignedCourses?.length == 0 ? (
                  <div
                    className="flex_column_center"
                    style={
                      userDetailsById?.student?.userAssignedCourses?.length == 0
                        ? {
                          width: "100%",
                          marginLeft: "5%",
                          padding: "20px",
                          margin: "20px",
                        }
                        : {
                          width: "330px",
                          padding: "20px",
                          margin: "20px",
                          marginLeft: "20px",
                          height: "310px",
                        }
                    }
                  >
                    <div
                      className="flex_row_center"
                      style={{ justifyContent: "center" }}
                    >
                      <img width="80%" src={NoCourseImage} alt="" />
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginTop: "25px",
                        textAlign: "center",
                      }}
                    >
                      {userDetailsById?.student?.userAssignedCourses?.length == 0
                        ? "No course assigned!"
                        : "Add more courses"}
                    </div>
                    {userDetailsById?.student?.userAssignedCourses?.length ==
                      0 ? (
                      <div
                        style={{
                          color: "#636363",
                          fontWeight: "500",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        There are no course(s) assigned to this student yet.
                      </div>
                    ) : // <div style={{ height: "20px" }}></div>
                      null}
                    <div
                      style={{
                        color: "#1089FF",
                        fontWeight: "bold",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        // disabled={
                        //   userDetailsById?.student?.standardId == -1 &&
                        //   (studentCourseDetails?.section == null ||
                        //     studentCourseDetails?.section == undefined)
                        // }
                        onClick={() => {
                          if (
                            userDetailsById?.student?.standardId == -1 ||
                            userDetailsById?.student?.standardId !==
                            studentCourseDetails?.sectionId
                          ) {
                            userCourseUpdate();
                          }
                          setViewAddCourseModal(true);
                        }}
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#594099",
                          fontSize: "10px",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        ADD A COURSE
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
              // ) : null
            }
            {viewAddCourseModal && (
              <AddCourseModal
                setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                isModalVisible={viewAddCourseModal}
                setViewAddCourseModal={setViewAddCourseModal}
              />
            )}
            {showCourseDetailsModal && (
              <CourseDetailsModal
                item={studentCourseDetails?.selectedCourse}
                isModalVisible={showCourseDetailsModal}
                setShowCourseDetailsModal={setShowCourseDetailsModal}
              />
            )}
            <UnAssignConfirmModal
              isModalVisible={showUnassign}
              setShowUnassign={setShowUnassign}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            // width: "67%",
            minHeight: "100%",
            // height: "fit-content",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <div
            className="flex_row_center"
            style={{
              fontSize: "18px",
              fontWeight: "650",
              marginBottom: "20px",
              justifyContent: "space-between",
              fontFamily: "roboto",
            }}
          >
            <div className="flex_row_center">
              <div
                onClick={() => {
                  setViewCourseDetails(true);
                }}
                style={{ cursor: "pointer" }}
              >
              </div>
            </div>

          </div>
     
  
          {feeMangementData?.dashBoardDetails?.kycStatus != 'Approved' && (
            < div style={{ backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6', padding: 10, cursor: 'text' }}>
              <span>Enable KYC to request payments <span style={{ color: '#1089ff', cursor: 'pointer' }} onClick={() => { history.push("/kyc-form") }}>Update KYC</span></span>
            </div>
          )
          }
          <div>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "650",
                marginTop: "30px",
                marginBottom: "15px",
              }}
            >
              Fee details
            </div>
  
            <div
              className="flex_row_center"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ width: "47%" }}>
                <p className="dropDownLabelBasicStyle">Total fee amount (Inclusive of all Taxes)</p>
                <p>₹ {
  
                  numberWithCommas(userDetailsById?.student?.feeDetails?.totalFee || 0)
  
                }</p>
              </div>
              <div style={{ width: "47%" }}>
                <p className="dropDownLabelBasicStyle">Fee payment plan</p>
                <p>{userDetailsById?.student?.feeDetails?.feePlan || 'NA'}</p>
                <div
                  onClick={() => {
                    setIsCourseModalVisible(true);
                    setModalType(true);
                  }}
                  className="flex_row_center"
                  style={{
                    color: "#1089FF",
                    fontWeight: "600",
                    marginTop: "-25px",
                    // position: "absolute",
                    // zIndex: "1",
                    cursor: "pointer",
                    justifyContent: "flex-end",
                  }}
                >
                  EDIT PLAN
                </div>
              </div>
            </div>
            {userDetailsById?.student?.feeDetails?.tranactionDetails &&
              userDetailsById?.student?.feeDetails?.tranactionDetails?.length !=
              0 ? (
              <div style={{ marginTop: "20px" }}>
                <p className="dropDownLabelBasicStyle">Fee amount details</p>
                <div
                  style={{
                    border: "1px solid #E6E6E6",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="flex_row_center"
                    style={{ justifyContent: "space-between", fontWeight: "600" }}
                  >
                    <p>Fee amount</p>
                    <p>₹ {userDetailsById?.student?.feeDetails?.totalFee && numberWithCommas(userDetailsById?.student?.feeDetails?.totalFee)} </p>
                  </div>
                  <div>
                    {userDetailsById?.student?.feeDetails?.scholarshipDetails &&
                      JSON.parse(
                        userDetailsById?.student?.feeDetails?.scholarshipDetails
                      )?.map((item, index) => (
                        <div
                          className="flex_row_center"
                          style={{
                            justifyContent: "space-between",
                            color: "#1089FF",
                            fontWeight: "600",
                          }}
                        >
                          <p>
                            {/* {item?.name} */}
                            <Typography.Text
                              style={{ width: "80px", color: "#1089FF" }}
                              ellipsis={{ tooltip: true }}
                            >
                              {item?.name}
                            </Typography.Text>
                            {/* {studentCourseDetails?.addedNotSaved&&index>studentCourseDetails?.savedSholarshipLength-1? */}
                            <span
                              onClick={() => {
                              
  
                                let temp = studentCourseDetails?.scholarships;
                                let removedScholarships =
                                  studentCourseDetails?.removedScholarships;
                                removedScholarships.push(temp[index]);
                                setCourseDetails(
                                  "removedScholarships",
                                  removedScholarships
                                );
                                setCourseDetails("scholarshipsRemoved", true);
  
                                temp.splice(index, 1);
  
                                setCourseDetails("scholarships", temp);
                                setCourseDetails("removeScholarshipIndex", index);
                                setCourseDetails("method", "remove");
                                setCourseDetails("type", "scholarship");
  
                                setPageLoader(true);
                                updateScholarshipOrAddition(() =>
                                  setPageLoader(false)
                                );
                              }}
                              style={{
                                color: "#FF414D",
                                fontSize: "8px",
                                border: "1px solid #FF414D",
                                borderRadius: "10px",
                                padding: "2px",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            >
                              Remove
                            </span>
                            {/* :null} */}
                          </p>
                          <p>
                            -₹ {" "}
                            {/* {(item?.amountOrPercentage *
                            studentCourseDetails?.feeAmount) /
                            100} */}
                            {item?.amount && numberWithCommas(item?.amount)}
                          </p>
                        </div>
                      ))}
                  </div>
                  {userDetailsById?.student?.feeDetails?.scholarshipDetails &&
                    JSON.parse(
                      userDetailsById?.student?.feeDetails?.scholarshipDetails
                    )?.length == 0 ? (
                    <div
                      className="flex_row_center"
                      style={{
                        justifyContent: "space-between",
                        color: "#1089FF",
                        fontWeight: "600",
                      }}
                    >
                      <p>Scholarship</p>
                      <p
                        onClick={() => {
                          if (studentCourseDetails?.scholarships?.length == 0) {
                            setIsModalVisible(true);
                          }
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        -₹ ADD
                      </p>
                    </div>
                  ) : null}
                  {userDetailsById?.student?.feeDetails?.additonDetails &&
                    JSON.parse(userDetailsById?.student?.feeDetails?.additonDetails)
                      ?.length != 0 ? (
                    <div
                      className="flex_row_center"
                      style={{
                        justifyContent: "space-between",
                        color: "#FFA931",
                        fontWeight: "600",
                      }}
                    >
                      <p>
                        <Typography.Text
                          style={{ width: "80px", color: "#FFA931" }}
                          ellipsis={{ tooltip: true }}
                        >
                          {
                            JSON.parse(
                              userDetailsById?.student?.feeDetails?.additonDetails
                            )?.[0]?.name
                            // studentCourseDetails?.additions?.[0]?.name
                          }
                        </Typography.Text>
                        <span
                          onClick={() => {
                       
                            let temp = studentCourseDetails?.additions;
                            let removedScholarships =
                              studentCourseDetails?.removedScholarships;
                            // removedScholarships.push(temp[index]);
                            setCourseDetails(
                              "removedScholarships",
                              removedScholarships
                            );
                            setCourseDetails("scholarshipsRemoved", true);
  
                            temp.splice(0, 1);
  
                            setCourseDetails("additions", temp);
                            // setCourseDetails("removeScholarshipIndex", index);
                            setCourseDetails("method", "remove");
                            setCourseDetails("type", "scholarship");
  
                            setPageLoader(true);
                            updateScholarshipOrAddition(() =>
                              setPageLoader(false)
                            );
                          }}
                          style={{
                            color: "#FF414D",
                            fontSize: "8px",
                            border: "1px solid #FF414D",
                            borderRadius: "10px",
                            padding: "2px",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </span>
                      </p>
                      <p
                        onClick={() => {
                          // setIsModalVisible(true);
                          // setIsAdditionModal(true);
                        }}
                        style={{}}
                      >
                        +₹{" "}
                        {userDetailsById?.student?.feeDetails?.additonDetails !==
                          undefined &&
                          // JSON.parse(
                          //   userDetailsById?.student?.feeDetails?.additonDetails
                          // )?.[0]?.amount != 0 &&
                          JSON.parse(
                            userDetailsById?.student?.feeDetails?.additonDetails
                          )?.length != 0
                          ?
                          numberWithCommas(JSON.parse(
                            userDetailsById?.student?.feeDetails?.additonDetails
                          )?.[0]?.amount)
                          : "ADD"
                        }
                      </p>
                    </div>
                  ) : (
                    <div
                      className="flex_row_center"
                      style={{
                        justifyContent: "space-between",
                        color: "#FFA931",
                        fontWeight: "600",
                      }}
                    >
                      <p>Addition</p>
                      <p
                        onClick={() => {
                          setIsModalVisible(true);
                          setIsAdditionModal(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        +₹ ADD
                      </p>
                    </div>
                  )}
  
                  <div
                    className="flex_row_center"
                    style={{
                      justifyContent: "space-between",
                      borderTop: "1px solid #E6E6E6",
                      borderBottom: "1px solid #E6E6E6",
                      padding: "5px",
                      fontWeight: "600",
                    }}
                  >
                    <p style={{ marginBottom: "0" }}>Total payable</p>
                    <p style={{ marginBottom: "0" }}>
                      ₹{" "}
                      {
                        userDetailsById?.student?.feeDetails?.totalPayable?.toFixed(2) &&
                        numberWithCommas(userDetailsById?.student?.feeDetails?.totalPayable?.toFixed(2))
                      }
                    </p>
                  </div>
                </div>
                <Table
                        className="m-t-10"
                        style={{ width: "100vw" }}
                        dataSource={userDetailsById?.student?.feeDetails?.tranactionDetails ? userDetailsById?.student?.feeDetails?.tranactionDetails : []}
                        columns={columns()}
                        rowSelection={null}
                        onRow={()=>{}}
                        rowKey={e => e.transactionId}
                    />
              </div>
            ) : null}
          </div>
          {
            isModalVisible && (
              <ScholarshipAndAdditionModal
                isModalVisible={isModalVisible}
                isAdditionModal={isAdditionModal}
                handleOk={closeModal}
                handleCancel={closeModal}
              />
            )
          }
          {
            isCourseModalVisible && (
              <EditCourseModal
                isModalVisible={isCourseModalVisible}
                isEditModalVisible={true}
                setIsModalVisible={setIsCourseModalVisible}
                setViewCourseDetails={setViewCourseDetails}
                setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
              />
            )
          }
          {
            viewEditCourseModal && (
              <CourseEditModal
                setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                isModalVisible={viewEditCourseModal}
                setViewEditCourseModal={setViewEditCourseModal}
                setViewCourseDetails={setViewCourseDetails}
              />
            )
          }
          {
            viewCourseDeleteConfirmModal && (
              <CourseDeleteConfirmModal
                modalType={modalType}
                setViewEditCourseModal={setViewEditCourseModal}
                isModalVisible={viewCourseDeleteConfirmModal}
                setViewCourseDeleteConfirmModal={setViewCourseDeleteConfirmModal}
                setViewCourseDetails={setViewCourseDetails}
                setViewAddCourseModal={setViewAddCourseModal}
                setIsCourseModalVisible={setIsCourseModalVisible}
              />
            )
          }
          {/* <EditOfflinePayment /> */}
          {/* <EidtScholerShipAddModal /> */}
          <PageDataLoader visible={pageLoader} />
        </div >
      );
    }
  }
  
  const mapStateToProps = (state) => {
    const {
      tab,
      dashBoardRole,
      dashboardData,
      classList,
      studentCourseDetails,
      userDetailsById,
      nextRollNo,
      UpdateRollNo,
    } = state.userManagement;
    const { user } = state.session;
    const { installments } = state.course;
    const { feeMangementData, } = state.feeManagement;
  
    return {
      feeMangementData,
      tab,
      dashBoardRole,
      dashboardData,
      classList,
      studentCourseDetails,
      installments,
      userDetailsById,
      nextRollNo,
      UpdateRollNo,
      user
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    getStudentsRollNo: (id, callback, val) =>
      dispatch(getStudentsRollNo(id, callback, val)),
    setCourseDetails: (key, val) => dispatch(setCourseDetails(key, val)),
    getCourseDetalis: (callback) => dispatch(getCourseDetalis(callback)),
    setInstallments: (val) => dispatch(setInstallments(val)),
    setCoursePrice: (val) => dispatch(setCoursePrice(val)),
    updateScholarshipOrAddition: (callback) =>
      dispatch(updateScholarshipOrAddition(callback)),
    unassignStudent: (callback) => dispatch(unassignStudent(callback)),
    setFeeDetails: (res) => dispatch(setFeeDetails(res)),
    setRollNumber: (res) => dispatch(setRollNumber(res)),
    UpdateRollNumber:() => dispatch(UpdateRollNumber()),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(FeeDetailsNew);
  