import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router';
const RedirectModal = ({isModalOpen,setIsModalOpen}) => {
    const history =useHistory()
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
     
      <Modal 
                    className="modal-round-corner"
      
      visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} >
        <div style={{marginTop:"25px"}}>
        <div style={{textAlign:"center",fontSize:"18px",fontWeight:"bold"}}>No leave types have been created for your organization yet</div>
        <div style={{textAlign:"center",marginTop:"20px"}}>
                            <Button
                            onClick={()=>{
                                history.push("/leave-graph")
                            }}
                                
                                style={{
                                    borderRadius: "19px",
                                    width: "150px",
                                    height: "40px",
                                    fontWeight: "500",
                                    backgroundColor: "#594099",
                                    color: "#FFFFFF",
                                }}
                            >
                                CLICK NOW
                            </Button>
                        </div>
        </div>
      
      </Modal>
      
    </>
  );
};
export default RedirectModal;