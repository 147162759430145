import { Modal, Row } from 'antd';
import React from 'react';
import { IndividualDetails } from './subComponents';
import {Button} from 'components/Button';
import { connect } from 'react-redux';
import { IMG_URL } from "../../env.json";


const TransactionDetailsModal = ({handleClose, visible, studentFeeDetails,data}) => {
    console.log({data});
    const transactionInfo = [
        {label: "Amount", value: data?.amountPayable ? `₹ ${data?.amountPayable.toFixed(2)}` : 0},
        {label: "Issue Date", value: data?.status !== 'Not Requested' ? data?.issueDate : null},
        {label: "Due date", value:  data?.dueDate},
        {label: "Payment date", value: data?.paymentDate},
        {label: "Invoice number", value: data?.paymentId},
        {label: "Invoice", value: data.status === "PENDING" || data.status === "OVERDUE" || data.status === "Not Requested" ? "" : <a style={{fontSize: 12, fontWeight: 600}} href={IMG_URL+ '/' + data?.invoice} target="_blank">VIEW</a>},
        // {label: "Mode of payment", value: data?.paymentMethod},
        // {label: "Transaction ID", value: data?.transactionId},
        {label: "Description", value: data.name},

    ]
    return (
        <Modal
            open={visible}
            footer={false}
            className="modal-round-corner"
            closable={false}
        >
            <div>
                <div className='m-t-30 r-c-c' style={{fontSize: "20px", fontWeight: 500}}>
                    Transaction details
                </div>
                <div className='r-c-c'>
                    <Row style={{width: "90%"}} className="m-l-10">
                        {transactionInfo.map((transaction, index) => 
                            <IndividualDetails 
                                data={transaction}
                            />
                        )}
                    </Row>
                </div>
                <div className='r-c-c m-t-20' >
                    <div style={{size: 12, color: "#636363", width: "90%"}} className="m-l-10" >Amount details</div>
                </div>
                <div className='r-c-c'>
                    <div style={{fontSize: 16, width: "90%"}} className="m-l-10">
                        <div className='r-jsb m-t-20'>
                            <div>Fee amount</div>
                            <div> {data.feeAmount ? `₹ ${parseInt(data.feeAmount).toFixed(2)}` : 0}</div>
                        </div>
                        {data?.discountDetails?.map((scholarship, index) => 
                            <div className='r-jsb m-t-20' key={index} style={{color: "#1089FF"}}>
                                <div>{scholarship.name}</div>
                                <div> -₹ {scholarship?.amount ? parseInt(scholarship?.amount)?.toFixed(2) : 0}</div>
                            </div>
                        )}
                        {data?.additionDetails?.map((addition, index) => 
                            <div className='r-jsb m-t-20' style={{color: "#FFA931"}}>
                                <div>{addition.name}</div>
                                <div> +₹  {addition?.amount ? parseInt(addition?.amount)?.toFixed(2) : 0}</div>
                            </div>
                        )}
                        <div style={{width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20}}/>
                        <div className='r-jsb m-t-20' style={{fontWeight: 500}}>
                            <div>Total payable</div>
                            <div>{data.amountPayable ? `₹ ${parseInt(data.amountPayable).toFixed(2)}` : 0}</div>
                        </div>
                        <div style={{width: "100%", borderBottom: "1px solid #E6E6E6", marginBottom: 20, marginTop: 20}}/>
                        <div className='m-t-40 m-b-30 r-c-c'>
                            <Button onClick={handleClose}>
                                CLOSE
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
            
        </Modal>
    );
}


const mapStateToProps = (state) => { 
    const {user} = state.session;
    const { studentFeeDetails } = state.profileNew;
    return {
        user, studentFeeDetails
    }
}

const mapDispatchToProps = (dispatch) => ({
   
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsModal);

