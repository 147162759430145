import {
	LoadingOutlined,
	PlusCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { Collapse, Divider } from "antd";
import Button from "antd/lib/button";
import "antd/lib/button/style/index";
import React from "react";
import { connect } from "react-redux";
import ResourceAddToSectionModal from "./modals/resourceAddToSection";
import "./styles.css";
import {
	getCurriculums,
	updateSelectedCurriculum,
	getSubjectsByCurriculum,
} from "actions/classRoom";
import PageDataLoader from "components/pageDataLoader";
import { SubIcon } from "components/subIcon";
import { savePageCoordinates } from "actions/layout";
import PageHeader from "components/pageHeader";
import { withRouter } from 'react-router-dom';

const { Panel } = Collapse;

const singleCurriculum = (
	item,
	index,
	updateSelectedCurriculum,
	selectedMasterStd,
	selectedCurriculumId,
	toggleAddCurriculumModal,
	location
) => (
	<div key={index.toString()} style={{ marginTop: "50px" }}>
		{ }
		<div className="r-c-sb m-t-20">
			<div className="text-sm color-black bold-600 ">{item.name}</div>
			{selectedCurriculumId === item.id ? (
				<Button
					icon={<PlusCircleOutlined />}
					className="purpleBtnWrap radius-100 purpleButtonHoverEffect boxShadow118 text-xmd m-r-10"
					type="primary"
					onClick={() => toggleAddCurriculumModal(true)}
				>
					{location === 'fromCourse' ? "ADD TO COURSE" : "Add to section"}
				</Button>
			) : null}
		</div>
		<div className="r-ac flex-wrap">
			{item.standards.map((std, index) => (
				<div
					className="r-c-c-c box-sequare__resources cursor-pointer"
					key={index}
					style={{ margin: "10px" }}
					onClick={() => updateSelectedCurriculum(std.curriculum_id, std)}
				>
					<div className="">
						<div className="text-xlg text-center color-purple bold-600 font-pt-serif">
							{std.name}
						</div>
						<div className="text-xs text-center dark-grey">{std.stream}</div>
					</div>
					<Divider style={{ margin: "10px 0px" }} />
					<div className="r-ac text-center">
						<div className="text-xs dark-black bold-600 text-overflow">
							{std.subjectsCount}
						</div>
						<div className="text-xs dark-grey m-l-5">Subjects</div>
					</div>
				</div>
			))}
		</div>
	</div>
);

const headerOut = (sub, subIndex) => {
	return (
		<div className="r-ac" key={subIndex} style={{padding:10}}>
			<SubIcon icon={sub.icon} name={sub.name} size={40} />
			<div className="text-overflow flex-1 m-l-10">
				<div className="text-xs color-black bold-600 text-overflow">
					{sub.name}
				</div>
				<div className="text-xs dark-grey">{sub.chapters.length} Chapters</div>
			</div>
		</div>
	);
};

class BrowseResources extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoader: false,
			subjectsLoader: false,
			showAddCurriculumModal: false,
		};
	}

	componentDidMount() {
		this.setState({ pageLoader: true });
		this.props.getCurriculums(() => this.setState({ pageLoader: false }));
	}

	componentWillUnmount() {
		this.props.updateSelectedCurriculum({}, null);
		if (!window.location.pathname.includes("/classroom")) {
			this.props.savePageCoordinates(0, 0);
		}
	}

	handleSelectStandard = (curriculumId, data) => {
		this.setState({ subjectsLoader: true });
		this.props.updateSelectedCurriculum(data, curriculumId);
		this.props.getSubjectsByCurriculum(curriculumId, () =>
			this.setState({ subjectsLoader: false })
		);
	};

	toggleAddCurriculumModal = () => {
		this.setState({ showAddCurriculumModal: true });
	};

	render() {
		const {
			history,
			curriculums,
			selectedMasterStd,
			masterSubjects,
			selectedCurriculumId,
		} = this.props;
		const { pageLoader, subjectsLoader, showAddCurriculumModal } = this.state;

		return (
			<div className="full-height display-flex r-c-sb">
				<PageHeader title={"Resources"} onBackPress={() => {
					history.goBack()
					// if(this.props.isFromClassRoomDetails){
					// 	history.push({pathname:"/course-details", state:{key:"fromClassroom"}})
					// }else{
					// 	history.goBack()
					// }
				}}
				/>
				<div
					className="full-height flex-2"
					style={{ overflow: "auto", marginTop: 30, marginRight: 20 }}
				>
					{curriculums?.map((item, index) =>
						singleCurriculum(
							item,
							index,
							this.handleSelectStandard,
							selectedMasterStd,
							selectedCurriculumId,
							this.toggleAddCurriculumModal,
							this.props.location?.state?.key
						)
					)}
				</div>

				<div
					className="content-box__height-40 display-flex content-box__raduis-15"
					style={{ width: "380px", marginTop: 60 }}
				>
					<div className="full-height flex-1">
						<div className="text-sm bold-600">
							Subjects{" "}
							<span className="dark-grey bold-400">
								(
								{selectedMasterStd &&
									selectedMasterStd.subjectsCount !== undefined
									? selectedMasterStd.subjectsCount
									: 0}
								)
							</span>
						</div>
						{subjectsLoader ? (
							<div
								className="r-c-c-c"
								style={{ height: "100%", fontSize: "2.5em" }}
							>
								<LoadingOutlined />
							</div>
						) : selectedMasterStd?.id ? (
							<div
								className="m-t-15 content-box__height-40"
								style={{ overflow: "auto" }}
							>
								{masterSubjects.length ? (
									<Collapse
									className='collapse-for-vr-sm'

										ghost
										expandIconPosition={"right"}
									
									>
										{masterSubjects &&
											masterSubjects.map((sub, subIndex) => (
												<Panel header={headerOut(sub, subIndex)} key={subIndex} >
													<div className="p-l-10 p-r-10">
														{sub.chapters.map((chap, index) => (
															<div className="r-ac m-b-10" key={index}>
																<div
																	className="text-xmd dark-grey flex-1"
																	style={{ width: "90px" }}
																>
																	Chapter {index + 1}
																</div>
																<div className="text-xmd dark-black bold-600 flex-2">
																	{chap.name}
																</div>
															</div>
														))}
													</div>
												</Panel>
											))}
									</Collapse>
								) : (
									<div className="r-c-c-c content-box__height-20">
										<img
											src={
												require("../../Assets/classroom/noSubjects.svg").default
											}
											style={{ width: 200, height: 200 }}
										/>
										<div className="text-sm bold-600 color-purple">
											No subjects found in this curriculum.
										</div>
									</div>
								)}
							</div>
						) : (
							<div className="r-c-c-c content-box__height-20">
								<img
									src={require("../../Assets/classroom/noSubjects.svg").default}
									style={{ width: 200, height: 200 }}
								/>
								<div className="text-sm bold-600 color-purple">
									Please select any standard!
								</div>
							</div>
						)}
					</div>
				</div>
				{showAddCurriculumModal && (
					<ResourceAddToSectionModal
						visible={showAddCurriculumModal}
						onClose={() => this.setState({ showAddCurriculumModal: false })}
						history={history}
						location={this.props.location?.state?.key}
					/>
				)}
				<PageDataLoader visible={pageLoader} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		curriculums,
		selectedMasterStd,
		masterSubjects,
		selectedCurriculumId,
		showAddCurriculumModal,
		isFromClassRoomDetails
	} = state.classRoom;
	return {
		curriculums,
		selectedMasterStd,
		masterSubjects,
		selectedCurriculumId,
		showAddCurriculumModal,
		isFromClassRoomDetails
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCurriculums: (callback) => dispatch(getCurriculums(callback)),
	updateSelectedCurriculum: (data, curriculumId) =>
		dispatch(updateSelectedCurriculum(data, curriculumId)),
	getSubjectsByCurriculum: (curriculumId, callback) =>
		dispatch(getSubjectsByCurriculum(curriculumId, callback)),
	// toggleAddCurriculumModal: (bool) => dispatch(toggleAddCurriculumModal(bool))
	savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BrowseResources));
