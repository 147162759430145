import { Api } from '../services';
import { storeSelectedCourseId } from './course';
import { storeSelectedCourseData } from './course';
import { getCurriculum } from './curriculum';

export const UPDATE_CLASSES = 'UPDATE_CLASSES';
export const TOGGLE_CLASS_FETCH_LOADER = 'TOGGLE_CLASS_FETCH_LOADER';
export const NAV_TO_CLASS_DETAILS = 'NAV_TO_CLASS_DETAILS';
export const TOGGLE_SEARCH_LOADER = 'TOGGLE_SEARCH_LOADER';
export const UPDATE_SUBJECT_LIST = 'UPDATE_SUBJECT_LIST';
export const TOGGLE_NEW_CLASS_MODAL = 'TOGGLE_NEW_CLASS_MODAL';
export const TOGGLE_NEW_SEC_MODAL = 'TOGGLE_NEW_SEC_MODAL';
export const TOGGLE_EDIT_CLASS_MODAL = 'TOGGLE_EDIT_CLASS_MODAL';
export const TOGGLE_CLONE_LOADER = 'TOGGLE_CLONE_LOADER';
export const TOGGLE_CLASS_CREATED_MODAL = 'TOGGLE_CLASS_CREATED_MODAL';
export const TOGGLE_CLASS_TRANSFER_MODAL = 'TOGGLE_CLASS_TRANSFER_MODAL';
export const TOGGLE_CLASS_TRANSFER_LOADER = 'TOGGLE_CLASS_TRANSFER_LOADER';
export const TOGGLE_CLASS_CREATE_LOADER = 'TOGGLE_CLASS_CREATE_LOADER';
export const UPDATE_NEW_CLASS_DETAILS = 'UPDATE_NEW_CLASS_DETAILS';
export const TOGGLE_EDIT_CLASS_LOADER = 'TOGGLE_EDIT_CLASS_LOADER';
export const UPDATE_SELECTED_SECTION = 'UPDATE_SELECTED_SECTION';
export const UPDATE_SEC_DETAILS = 'UPDATE_SEC_DETAILS';
export const TOGGLE_SEC_LOADER = 'TOGGLE_SEC_LOADER';
export const TOGGLE_ADD_CHAPTER_MODAL = 'TOGGLE_ADD_CHAPTER_MODAL';
export const TOGGLE_ADD_STUDENT_MODAL = 'TOGGLE_ADD_STUDENT_MODAL';
export const TOGGLE_CREATE_SUB_MODAL = 'TOGGLE_CREATE_SUB_MODAL';
export const SET_CURRENT_SUB = 'SET_CURRENT_SUB';
export const UPDATE_CURRICULUMS = 'UPDATE_CURRICULUMS';
export const UPDATE_SELECTED_CURRICULUM = 'UPDATE_SELECTED_CURRICULUM';
export const UPDATE_MASTER_SUBJECTS = 'UPDATE_MASTER_SUBJECTS';
export const STORE_SELECTED_CLASS_DETAILS = 'STORE_SELECTED_CLASS_DETAILS';
export const STORE_SINGLE_SECTION_DETAILS = "STORE_SINGLE_SECTION_DETAILS";
export const STORE_SECTION_ID = "STORE_SECTION_ID";
export const SET_ALL_STUDENTS_PAGE_PARAMS = "SET_ALL_STUDENTS_PAGE_PARAMS"
export const SET_FROM_CLASSROOM = "SET_FROM_CLASSROOM";
export const UPDATE_STUDENTS_LOADER = "UPDATE_STUDENTS_LOADER";
export const STORE_All_STUDENTS = "STORE_All_STUDENTS"
// export const TOGGLE_ADD_CURRICULUM_MODAL = 'TOGGLE_ADD_CURRICULUM_MODAL';



// export const toggleAddCurriculumModal = bool => ({
//   type: TOGGLE_ADD_CURRICULUM_MODAL,
//   bool
// })

export const deleteSubject = (subId, callback, successCallBack = null) => {

  console.log('hererererer',1234);
  return (dispatch, getState) => {
    const {selectedSection} = getState().classRoom;
    const params = {
      standardId: selectedSection.id,
      subjectId: subId
    }

    return Api.delete('/subjects/delete-subjects').params(params).send((response, error) => {
      if(typeof response != "undefined" && response.show.type === "success") {
        if(successCallBack) {
          successCallBack(); 
        }
      }
      dispatch(getCurriculum(null, '/classroom/curriculum', null, null, ))
      callback()
    });
  }
}

export const editSubject = (params, buttonCallback, successCallback) => {
  return (dispatch, getState) => {
    const {selectedSectionDetails} = getState().classRoom;
    Object.assign(params, {standardId: selectedSectionDetails.id})
     
    return Api.post('/subjects/edit-subject').params(params).send((response, error) => {
      if(typeof response != "undefined" && response.show.type === "success") {
        // dispatch(getClass())
        successCallback(response.subject);
      }else{
        buttonCallback();
      }
    });
  }
}

export const deleteSubjects = (subjects, successCallback, buttonCallback) => {
  return (dispatch, getState) => {
    const {selectedSectionDetails} = getState().classRoom;
    const params = {
      standardId: selectedSectionDetails.id,
      subjectIds: subjects
    }
    return Api.delete('/subjects/delete-subjects').params(params).send((response, error) => {
      if(typeof response != "undefined" && response.show.type === "success") {
        dispatch(getClass())
        successCallback();
      }else{
        buttonCallback();
      }
    });
  }
}

export const saveCurriculum = (subjects, successCallback, buttonCallback, history) => {
  return (dispatch, getState) => {
    const {selectedMasterStd, selectedCurriculumId, selectedSection} = getState().classRoom;
    const params = {
      standardId: selectedSection.id,
      curriculumId: selectedCurriculumId,
      masterStandardId: selectedMasterStd.id,
      subjects,
    }
    return Api.post('/subjects/add-chapters').params(params).send((response, error) => {
      if(typeof response != "undefined") {
        successCallback()
        history.push('/classroom/classroomdetails')
      }else{
        buttonCallback();
      }
    });
  }
}

const updateMasterSubjects = (data) => ({
  type: UPDATE_MASTER_SUBJECTS,
  data
})

export const getSubjectsByCurriculum = (curriculumId, callback) => {
  return (dispatch, getState) => {
    const {selectedMasterStd} = getState().classRoom;
    const params = {
      curriculumId,
      standardId: selectedMasterStd.id
    }
    return Api.get('/master-subjects/subjects').params(params).send((response, error) => {
      if(typeof response != "undefined") {
        dispatch(updateMasterSubjects(response))
      }
      callback();
    });
  }
}

export const updateSelectedCurriculum = (data, curriculumId) => ({
  type: UPDATE_SELECTED_CURRICULUM,
  data,
  curriculumId
})

const updateCurriculums = data => ({
  type: UPDATE_CURRICULUMS,
  data
})

export const getCurriculums = (callback) => {
  return (dispatch, getState) => {
    return Api.get('/master-subjects/curriculums').send((response, error) => {
      if(typeof response != "undefined") {
        dispatch(updateCurriculums(response))
      }
      callback();
    });
  }
}

export const editChapters = (chapters, callback, successCallback) => {
  return (dispatch, getState) => {
    const { selectedSectionDetails, selectedSubject} = getState().classRoom;
    const params = {
      chapters,
      standardId: selectedSectionDetails.id,
      subjectId: selectedSubject.id
    }
    return Api.post('/subjects/edit-chapters').params(params).send((response, error) => {
      if(typeof response != "undefined") {
        dispatch(getClass(null, selectedSubject));
        successCallback();
      }
      callback();
    });
  }
}

export const deleteChapters = (chapterIds, callback, successCallback) => {
  return (dispatch, getState) => {
    const { selectedSubject} = getState().classRoom;
    return Api.delete('/subjects/delete-chapters').params({chapterIds}).send((response, error) => {
      if(typeof response != "undefined" && response.show.type === "success") {
        dispatch(getClass(null, selectedSubject));

        successCallback()
      }
      callback();
    });
  }
}




export const removeStudents = (params, callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {
    const {selectedSection} = getState().classRoom;
    const payload = {
      standardId: selectedSection.id,
      studentsList: params
    }
    return Api.patch('/standards/students').params(payload).send((response, error) => {
      if(callBack){
        callBack()
      }
      if(typeof response != "undefined") {
        if(successCallBack){
          successCallBack()
        }
      }
    });
  }
};

export const moveStudents = (params, callback, buttonCallback, refreshCallback) => {
  return (dispatch, getState) => {
    const {selectedSection} = getState().classRoom;
    Object.assign(params, {standardId: selectedSection.id})
    return Api.post(`/standards/move-students`).params(params).send((response, error) => { //
      if(typeof response != "undefined" && response.show.type !== 'error') {
        refreshCallback();
        callback();
      }else{
        buttonCallback();
      }
    });
  }
};
export const postTransferStudents = (params, callBack = null, successCallBack = null) => {
  return (dispatch, getState) => {
    const {selectedSection} = getState().classRoom;
    Object.assign(params, {standardId: selectedSection.id})
    return Api.post(`/standards/move-students`).params(params).send((response, error) => {
      if(callBack){
        callBack()
      }
      if(typeof response != "undefined" && response.show.type !== 'error') {
        if(successCallBack){
          successCallBack()
        }
      }
    });
  }
};

export const setCurrentSubject = data => ({
  type: SET_CURRENT_SUB,
  data
})

export const postChapters = (chapters, callback) => {
  return (dispatch, getState) => {
    const { selectedSection, selectedSubject } = getState().classRoom;
    const params = {
      standardId: selectedSection.id,
      subjectId: selectedSubject?.id,
      chapters
    }
    const url = `/subjects/create-chapters`;
    return Api.post(url).params(params).send((response, error) => {
      callback()
      if(typeof response != "undefined") {
        dispatch(toggleAddChapterModal(false));
        dispatch(getClass());
      }
    });
  }
}
export const addStudents = (standardId, students, callback, successCallback) => {
  return (dispatch, getState) => {
    const params = {
      standardId,
      students
    }
    const url = `/standards/assign-students`;
    return Api.post(url).params(params).send((response, error) => {
      callback()
      if(typeof response != "undefined" && response.show.type === "success") {
        successCallback()
      }
    });
  }
}

export const searchStudent = (query, callback, unAssignedBool=false) => {
  return (dispatch) => {
    const params = {
      name: query,
      role: 'Student',
      unAssignedBool:unAssignedBool
    };
    dispatch(updateStudentsLoader(true))
    return Api.get('/user/autocomplete').params(params).send((response, error) => {
      dispatch(updateStudentsLoader(false))
      if(typeof response != "undefined") {
        if(!response.length) {
          // callback([{id: -1, value: 'No students found', disabled: true}]);
          callback();
        } else {
          callback(response)
        }
      };
    });
  }
}

export const updateStudentsLoader = (val) => ({
  type: UPDATE_STUDENTS_LOADER,
  val
})

export const postSubjects = (subjects, callback) => {
  return (dispatch, getState) => {
    const { selectedSection } = getState().classRoom;
    const params = {
      standardId: selectedSection.id,
      subjects
    }
    const url = `/subjects/create-subjects`;
    return Api.post(url).params(params).send((response, error) => {
      callback()
      if(typeof response != "undefined") {
        dispatch(toggleCreateSubjectModal(false));
        dispatch(getClass())
      }
    });
  }
}

export const toggleCreateSubjectModal = bool => ({
  type: TOGGLE_CREATE_SUB_MODAL,
  bool
})

export const toggleAddStudentModal = bool => ({
  type: TOGGLE_ADD_STUDENT_MODAL,
  bool
})

export const toggleAddChapterModal = bool => ({
  type: TOGGLE_ADD_CHAPTER_MODAL,
  bool
})

const updateSecDetails = data => ({
  type: UPDATE_SEC_DETAILS,
  data
})

const toggleSecLoader = bool => ({
  type: TOGGLE_SEC_LOADER,
  bool
});

export const getClass = (callback = null, subject = {}) => {
  console.log("In get class", subject);
  return (dispatch, getState) => {
    const { selectedSection, selectedSubject } = getState().classRoom;
    console.log('idsss', selectedSection);
    const url = `/standards/${selectedSection.id}`;
    dispatch(toggleSecLoader(true));
    return Api.get(url).send((response, error) => {
      dispatch(updateSelectedSection(response))
      dispatch(toggleSecLoader(false));
      if(typeof response != "undefined") {
        dispatch(updateSecDetails(response));
        let sub;
        if(subject){
          sub = response.subjects.filter(item => item.id === subject.id);
        }else{
          sub = response.subjects.filter(item => item.id === selectedSubject.id);
        }
        dispatch(setCurrentSubject(sub ? sub[0] : subject));
        if(callback){
          callback()
        }
      }
    });
  }
};

export const updateMarkAsCompleted = (markAs, callBack = null) => {
  return (dispatch, getState) => {
    const { sectionId} = getState().classRoom;
    const params ={
      id:sectionId,
      markAs:markAs,
 
    }
    return Api.get('/standards/mark-batch-completed').params(params).send((response, error) => {
      if(typeof response != "undefined" && response.show.type === "success") {
        if(callBack){
          callBack();
        }
      }
   
    });
  }
}

export const getSubjStudentsData = (callBack = null) => {
  
  return (dispatch, getState) => {
    const { sectionId, allStudentsParamsObj, selectedSection, allStudentsData } = getState().classRoom;
    const params ={
      search:allStudentsParamsObj?.search,
      sort:allStudentsParamsObj?.sort,
      page:allStudentsParamsObj?.page,
      limit:allStudentsParamsObj?.limit
    }
   const url = `/standards/${sectionId}`;
    return Api.get(url).params(params).send((response, error) => {
      dispatch(storeSelectedCourseId(response?.courseId))
      if(typeof response != "undefined") {
        dispatch(storeAllStudents([...response?.students?.items]));
        dispatch(updateSelectedSection(response));
        if(callBack){
          callBack();
        }
        if(callBack){
          callBack(response?.courseId);
        }
      }
    });
  }
};
export const storeAllStudents = (val) => ({
  type:STORE_All_STUDENTS,
  val
})

export const setAllStudentsPageParam = (key,val) => ({
  type:SET_ALL_STUDENTS_PAGE_PARAMS,
  key,
  val
})

export const updateSelectedSection = data => ({
  type: UPDATE_SELECTED_SECTION,
  data
})

const toggleEditClassLoader = bool =>  ({
  type: TOGGLE_EDIT_CLASS_LOADER,
  bool
})

export const editClasses = (params,fromCourseDetails, callBack=null, successCallBack = null) => {
  return (dispatch, getState) => {
    const { newCourseId, isFromCourseDetailsReducer } = getState().courseManagement;
    return Api.patch('/standards/update-standards').params(params).send((response, error) => {
      if(callBack){
        callBack()
      }
      if(typeof response !== "undefined" && response?.show?.type !== "error") {
        if(successCallBack){
          successCallBack()
        }
        dispatch(getAllClasses(fromCourseDetails || isFromCourseDetailsReducer ? newCourseId : null));
        // dispatch(getClass())
      }
    });
  }
}

export const toggleTransferClassLoader = (bool) => ({
  type: TOGGLE_CLASS_TRANSFER_LOADER,
  bool,
})

export const transferStandard = (details, callBack= null, successCallBack = null) => {
  return (dispatch) => {
    return Api.post(`/standards/move`).params(details).send((response, error) => {
      if(callBack){
        callBack()
      }
      if(typeof response != "undefined" && response.show.type !== 'error') {
        if(successCallBack){
          successCallBack()
        }
      }
    });
  }
};



export const toggleTransferClassModal = (bool, data = {}) => ({
  type: TOGGLE_CLASS_TRANSFER_MODAL,
  bool,
  data
})

export const toggleClassCreatedModal = bool => ({
  type: TOGGLE_CLASS_CREATED_MODAL,
  bool
})

export const postClass = (payload) => {
  return (dispatch) => {
    dispatch(toggleCloneLoader(true));
    return Api.post('/standards/add').params(payload).send((response, error) => {
      dispatch(toggleCloneLoader(false));
      if(typeof response != "undefined" && typeof response.show == "undefined") {
        console.log(response);
        dispatch(toggleAddSectionModal(false))
        dispatch(toggleNewClassModal(false))
        dispatch(updateNewClassDetails(payload))
        dispatch(toggleClassCreatedModal(true))
        dispatch(updateSelectedSection(response))
        // dispatch(getAllClasses())
      }
    });
  }
};
export const postAddClass = (params, callBack = null, successCallBack) => {
  return (dispatch) => {
    return Api.post('/standards/add').params(params).send((response, error) => {
      if(callBack){
        callBack()
      }
      dispatch(updateNewClassDetails(response))
      if(typeof response != "undefined" && response?.show?.type != "error") {
        if(successCallBack){
          successCallBack()
        }
        dispatch(storeSectionId(response?.id))
        dispatch(storeSelectedCourseId(response?.courseId))
        if(successCallBack){
          successCallBack(response)
        }
      }
    });
  }
};
export const postAddClassWithImport = (linkArray, successCallBack = null) => {
  return (dispatch, getState) => {
    const params = {
      linkArray: linkArray ? linkArray : [],
      subjects: []
    }
    return Api.post('/subjects/linkCourseChapters').params(params).send((response, error) => {
      if(typeof response != "undefined" && response?.show?.type != "error") {
        if(successCallBack){
          successCallBack()
        }
      }
    });
  }
};

export const storeSelectedClassDetails = (val) => ({
  type: STORE_SELECTED_CLASS_DETAILS,
  val
})


export const editSingleClass = (payload) => {
  return (dispatch) => {
    dispatch(toggleCloneLoader(true));
    return Api.post('/standards/edit-single').params(payload).send((response, error) => {
      dispatch(toggleCloneLoader(false));
      if(typeof response != "undefined") {
        console.log(response);
        dispatch(toggleNewClassModal(false))
        dispatch(updateSelectedSection(response.standard))
        dispatch(getClass())
        // dispatch(getAllClasses())
      }
    });
  }
};

const updateNewClassDetails = data => ({
  type: UPDATE_NEW_CLASS_DETAILS,
  data
})

export const toggleCloneLoader = bool => ({
    type: TOGGLE_CLONE_LOADER,
    bool
  });

export const cloneSectionApi = (params,fromCourseDetails, callBack = null, successCallBack = null) => {
    return (dispatch, getState) => {
      const { newCourseId } = getState().courseManagement;
      return Api.post('/standards/clone').params(params).send((response, error) => {
        if(callBack){
          callBack()
        }
        if(typeof response !== "undefined") {
          if(successCallBack){
            successCallBack()
          }
          dispatch(getAllClasses(fromCourseDetails ? newCourseId : null));
        }
      });
    }
  };

export const toggleEditClassModal = (bool, key = null) => ({
    type: TOGGLE_EDIT_CLASS_MODAL,
    bool,
    key
})
export const toggleAddSectionModal = (bool, key = null) => ({
    type: TOGGLE_NEW_SEC_MODAL,
    bool,
    key
})

export const toggleNewClassModal = (bool) => ({
    type: TOGGLE_NEW_CLASS_MODAL,
    bool
})

export const toggleSearchLoader = value => ({
    type: TOGGLE_SEARCH_LOADER,
    value
  });

export const updateSubjectList = subjects => ({
    type: UPDATE_SUBJECT_LIST,
    subjects,
  });

export const searchSubject = (query) => {
    return (dispatch) => {
      const params = {
        name: query,
      };
      dispatch(toggleSearchLoader(true));
      return new Promise((resolve, reject) =>
        Api.get('/subjects/autocomplete').params(params).send(async (response, error)=>{
          if(typeof response != "undefined") {
            if(!response.length) {
              dispatch(updateSubjectList([{
                id: -1,
                value: 'No subjects found',
                disabled: true
              }]));
            } else {
              dispatch(updateSubjectList(response));
            }
          };
          dispatch(toggleSearchLoader(false));
          resolve(response, error);
        })
      )
    }
  };

export const navToClassDetails = (history) =>{
    history.push('/classroom/classroomdetails')
    return ({
      type: NAV_TO_CLASS_DETAILS,
    });
  }

export const getAllClasses = (id) => {
return (dispatch) => {
    dispatch(toggleClassFetchLoader(true));
    return Api.get('/standards').params({courseId : id ? id : null}).send((response, error) => {
    dispatch(toggleClassFetchLoader(false));
    if(typeof response != "undefined") {
        dispatch(updateClasses(response));
    }
    });
}
};

export const toggleClassFetchLoader = bool => ({
    type: TOGGLE_CLASS_FETCH_LOADER,
    bool
  })

export const updateClasses = classes => ({
    type: UPDATE_CLASSES,
    classes
  });

export const getSelectedSectionData = (callBack = null) => {
  return (dispatch, getState) => {
    const {sectionId} = getState().classRoom;
      return Api.get(`/standards/getStandardDetailsById/${sectionId}`).send((response, error) => {
        if(callBack){
          callBack()
        }
      if(typeof response != "undefined") {
          dispatch(storeSingleSectionDetails(response));
      }
      });
  }
  };
  export const getCourseData = () => {
    return (dispatch, getState) => {
      const { selectedCourseId } = getState().course;
        return Api.get(`/standards/course/${selectedCourseId}`).send((response, error) => {
        if(typeof response != "undefined") {
            dispatch(storeSelectedCourseData(response));
        }
        });
    }
    };
export const storeSectionId = (val) => ({
  type:STORE_SECTION_ID,
  val
})
export const storeSingleSectionDetails = (val) => ({
  type:STORE_SINGLE_SECTION_DETAILS,
  val
})
export const setFromClassroom = (val) => ({
  type:SET_FROM_CLASSROOM,
  val
})