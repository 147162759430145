import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";

function ImageChangeModal({
  deletePicLoader,
  isModalVisible,
  handleOk,
  onCancel,
  onRemove,
  onChange,
  disabled
}) {
  return (
    <div>
      <Modal
        className="imageChangeModalComponent"
        okText={"Yes"}
        cancelText={"No"}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
      >
        <div
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#191919",
            marginBottom: "0px",
            fontSize: "18px",
            fontWeight: "medium",
            marginTop:30
          }}
        >
          Change/Remove image?
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "60px",
          }}
        >
          <Button
            onClick={onRemove}
            style={{ borderRadius: "20px", width: "150px", border:"1px solid #636363" }}
            loading={deletePicLoader}
            disabled={disabled}
          >
            <DeleteOutlined style={{ color: "#AEAEAE" }} />
            <span
              style={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "600" }}
            >
              REMOVE
            </span>
          </Button>
          <Button
            onClick={onChange}
            style={{
              borderRadius: "20px",
              backgroundColor: "#594099",
              width: "150px",
            }}
          >
            <SearchOutlined style={{ color: "white" }} />
            <span
              style={{ color: "white", fontSize: "12px", fontWeight: "600" }}
            >
              BROWSE PHOTO
            </span>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ImageChangeModal;
