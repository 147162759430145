import { Checkbox, Input, Modal, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Button } from 'components/Button';
import PhoneInput from 'react-phone-number-input';
import AnimatedCustomSelect from 'components/AnimatedSelectComp';
import AnimatedTextField from 'components/AnimatedTextField';
import "../../pages/studentMarketPlace/userHomeStyles.css"

function EnquiryFormPreviewModal({ isEnquiryPreviewModal, setIsEnquiryPreviewModal, mobileOtpEnable, previewData, courseList, orgName, defaultForm, emailSwitch }) {
    console.log("previewdata", courseList)
    const [isChecked, setIsChecked] = useState(false);
    return (
        <div>
            <Modal
                centered
                className="modal-round-corner"
                open={isEnquiryPreviewModal}
                onOk={(e) => { setIsEnquiryPreviewModal(false) }}
                onCancel={(e) => { setIsEnquiryPreviewModal(false) }}
                header={null}
                footer={null}
            >
                <center style={{ fontSize: 18, color: "#191919", fontWeight: "bold", marginBottom: 20 }}>ADMISSIONS ENQUIRY FORM</center>
                <div className='scroll-bar-universal' style={{ height: previewData?.length > 2 ? 320 : "fit-content" }}>
                    <div className='name-input'>
                        <AnimatedTextField
                            label="Name"
                            // value={reachUsParams?.name}
                            // handleInputChange={handleNameInputChange}
                            isMandatory={true}
                            inputId="name"
                            isDisabled={true}
                        />
                    </div>

                    <div className='m-t-20' style={{ width: "100%" }}>
                        <div className='mobile-input' style={{ width: "100%" }}>
                            <div style={{ color: "#AEAEAE" }}>Mobile Number <span style={{ color: 'red' }}>*</span></div>
                            <PhoneInput
                                className='custom-phone-input-enquiry'
                                defaultCountry="IN"
                                addInternationalOption={true}
                                // countrySelectComponent={CustomCountrySelect}
                                placeholder='Enter mobile number'
                                disabled={true}
                            />
                        </div>
                    </div>
                    {mobileOtpEnable ? <>
                        <div className='r-jsb' style={{ width: "98%" }}>
                            <div className='otp-input m-t-20' >
                                <div style={{ color: "#AEAEAE", }}>Verify Mobile Number <span style={{ color: 'red' }}>*</span></div>
                                <div style={{ position: "relative", display: "flex", justifyContent: "flex-start" }}>
                                    <div className="r-c-c" style={{ fontWeight: 700 }}>
                                        <div className="otpInputNew">{/* ? "*" : null */}</div>
                                        <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                        <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                        <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                        <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                        <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                    </div>
                                    <input type="number" className='aulas-login__input-verify-otp'
                                        style={{ width: "100%", cursor: "not-allowed", opacity: 0.5 }}

                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <Button
                                disabled={true}
                                type="link"
                                shape="round"
                                className="r-c-c bold-bold"
                                style={{ color: "#1089FF", fontWeight: "bold", marginTop: 55, fontSize: 14, cursor: "not-allowed" }}
                            >
                                {"VERIFY OTP"}
                            </Button>
                        </div>
                    </> : null}
                    {!defaultForm ?
                        <>
                            <div className='r-jsb m-t-10'>
                                <div className='email-input' style={{ width: "100%" }}>
                                    <AnimatedTextField
                                        label="Email ID"
                                        isMandatory={emailSwitch}
                                        inputId="email"
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className='name-input m-t-30'>
                                <AnimatedTextField
                                    label={"Message"}
                                    inputId="message"
                                    isDisabled={true}
                                />
                            </div>
                        </>
                        : <>
                            {previewData?.map((item, index) => (
                                <>
                                    {item?.type == "email" ?
                                        <>
                                            {item?.type == "email" && !item?.verify ?
                                                <div className='r-jsb m-t-10'>
                                                    <div className='email-input' style={{ width: "100%" }}>
                                                        <AnimatedTextField
                                                            label={item?.type == "email" ? item?.questionContent?.statement : ""}
                                                            isMandatory={item?.verify || item?.required}
                                                            inputId="email"
                                                            isDisabled={true}

                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className='r-jsb m-t-10' style={{ width: "98%" }}>
                                                        <div className='email-input' style={{ width: item?.type == "email" && item?.verify ? "70%" : "100%" }}>
                                                            <AnimatedTextField
                                                                label={item?.type == "email" ? item?.questionContent?.statement : ""}
                                                                isMandatory={item?.verify}
                                                                inputId="email"
                                                                isDisabled={true}
                                                            />
                                                        </div>


                                                        <Button
                                                            disabled={true}
                                                            type="link"
                                                            shape="round"
                                                            className="r-c-c bold-bold"
                                                            style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, marginLeft: 10 }}

                                                        >
                                                            SEND OTP
                                                        </Button>

                                                    </div>
                                                    <div className='r-jsb' style={{ width: "98%" }}>
                                                        <div className='otp-input m-t-10' >
                                                            <div style={{ color: "#AEAEAE", }}>Verify Email ID <span style={{ color: 'red' }}>*</span></div>
                                                            <div style={{ position: "relative", display: "flex", justifyContent: "flex-start" }}>
                                                                <div className="r-c-c" style={{ fontWeight: 700 }}>
                                                                    <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                    <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                    <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                    <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                    <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                    <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                </div>
                                                                <input type="number" className='aulas-login__input-verify-otp'
                                                                    style={{ width: "100%", cursor: "not-allowed", opacity: 0.5 }}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Button
                                                            disabled={true}
                                                            type="link"
                                                            shape="round"
                                                            className="r-c-c bold-bold"
                                                            style={{ color: "#1089FF", fontWeight: "bold", marginTop: 30, fontSize: 14, cursor: "not-allowed" }}

                                                        >
                                                            VERIFY OTP
                                                        </Button>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        : <></>}
                                    {item?.type == "phone" ?
                                        <>
                                            <div className='m-t-20' style={{ width: "100%" }}>
                                                <div className='mobile-input' style={{ width: "100%" }}>
                                                    <div style={{ color: "#AEAEAE" }}>{item?.questionContent?.statement} <span style={{ color: 'red' }}>*</span></div>
                                                    <PhoneInput
                                                        className='custom-phone-input-enquiry'
                                                        defaultCountry="IN"
                                                        addInternationalOption={true}
                                                        // countrySelectComponent={CustomCountrySelect}
                                                        placeholder='Enter mobile number'
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            {item?.verify ? <>
                                                <div className='r-jsb' style={{ width: "98%" }}>
                                                    <div className='otp-input m-t-20' >
                                                        <div style={{ color: "#AEAEAE", }}>Verify Mobile Number <span style={{ color: 'red' }}>*</span></div>
                                                        <div style={{ position: "relative", display: "flex", justifyContent: "flex-start" }}>
                                                            <div className="r-c-c" style={{ fontWeight: 700 }}>
                                                                <div className="otpInputNew">{/* ? "*" : null */}</div>
                                                                <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                                <div className="otpInputNew">{ /* ? "*" : null */}</div>
                                                            </div>
                                                            <input type="number" className='aulas-login__input-verify-otp'
                                                                style={{ width: "100%", cursor: "not-allowed", opacity: 0.5 }}

                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Button
                                                        disabled={true}
                                                        type="link"
                                                        shape="round"
                                                        className="r-c-c bold-bold"
                                                        style={{ color: "#1089FF", fontWeight: "bold", marginTop: 55, fontSize: 14, cursor: "not-allowed" }}
                                                    >
                                                        {"VERIFY OTP"}
                                                    </Button>
                                                </div>
                                            </> : null}
                                        </>
                                        : <></>}
                                    {item?.type == "short" ?
                                        <div className='name-input m-t-30'>
                                            <AnimatedTextField
                                                label={item?.type == "short" ? item?.questionContent?.statement : ""}
                                                // value={item?.questionContent?.statement}
                                                isMandatory={item?.type == "short" && item?.required}
                                                inputId="short"
                                                isDisabled={true}
                                            />
                                        </div>
                                        : <></>}
                                    {item?.type == "paragraph" ?
                                        <div className='name-input m-t-30'>
                                            <AnimatedTextField
                                                label={item?.type == "paragraph" ? item?.questionContent?.statement : ""}
                                                // value={item?.questionContent?.statement}
                                                isDisabled={true}
                                                isMandatory={item?.type == "paragraph" && item?.required}
                                                inputId="paragraph"
                                            />
                                        </div>
                                        : <></>}
                                    {item?.type == "checkbox" ?
                                        <div className='m-t-20'>
                                            <div style={{ color: "#AEAEAE" }}>{item?.questionContent?.statement}{item?.required ? <span style={{ color: 'red' }}> *</span> : null}</div>
                                            {item?.questionContent?.options?.map((item) => (
                                                <div className='m-t-10'>
                                                    <Checkbox disabled={true} style={{ marginRight: 10, color: "#191919", fontWeight: 400 }}>{item?.value}</Checkbox>
                                                </div>
                                            ))}
                                        </div>
                                        : <></>}
                                    {item?.type == "MCQ" ?
                                        <div className='m-t-20'>
                                            <div style={{ color: "#AEAEAE" }}>{item?.questionContent?.statement}{item?.required ? <span style={{ color: 'red' }}> *</span> : null}</div>
                                            {item?.questionContent?.options?.map((item) => (
                                                <div className='m-t-10'>
                                                    <Radio disabled={true} style={{ marginRight: 10, color: "#191919", fontWeight: 400 }}>{item?.value}</Radio>
                                                </div>
                                            ))}
                                        </div>
                                        : <></>}
                                    {item?.type == "dropdown" ?
                                        <div className='m-t-20'>
                                            <AnimatedCustomSelect
                                                label={item?.questionContent?.statement}
                                                required={item?.required}
                                                options={item?.questionContent?.options?.[0]?.courseOptions?.length > 0 && item?.questionContent?.options?.[0]?.courseList ? item?.questionContent?.options?.[0]?.courseOptions?.filter(course => course?.isChecked) : item?.questionContent?.options}
                                                isSelected={item?.questionContent?.options?.[0]?.courseOptions?.length > 0 ? item?.questionContent?.options?.[0]?.courseOptions : item?.questionContent?.options}
                                            />
                                        </div>

                                        : <></>}

                                </>
                            ))}
                        </>
                    }
                    <Checkbox disabled={true} style={{ marginRight: 10, color: "#191919", fontWeight: 500, marginTop: 20 }} checked={isChecked} onChange={(e) => { setIsChecked(e.target.checked) }}>I hereby authorise {orgName} to contact me.</Checkbox>
                </div>
                <div className='r-c-c-c' style={{ height: 60, margin: "20px -25px -25px -25px", boxShadow: "0px -3px 10px #00000029", borderRadius: " 0px 0px 16px 16px" }}>
                    <Button type="primary" disabled={true}>SUBMIT </Button>
                </div>
            </Modal>
        </div >
    )
}

export default EnquiryFormPreviewModal
