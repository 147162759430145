import React, { useEffect, useState } from "react";
import { Tabs, Tag, Divider, Button, Row, Col, Typography } from "antd";
import MyAttendancePopulated from "./myAttendancePopulated";
import { Api } from '../../services';
import moment from 'moment';
import { useHistory } from "react-router";
import { connect } from "react-redux";
import './style.css'
import {
  getPrevilegedViewData,
  storeUsersName,
  setCardId,
  requestedActionData,
  setActionData,
  setTabs,
  setStatusData,
  storeImportHoliday,
  setBackToPrivilegedView,
  setClickedOnUserCard,
  setIsButtonDivVisible,
  requestViewAllClicked,
  roleFilter,
  setRoleFilter,
  getAttendanceDashBoardData
} from "actions/attendanceNew";
import { setSpecificUserId } from 'actions/userManagement';
import PageDataLoader from 'components/pageDataLoader';

import AttendanceHome from "pages/attendance_user/attendanceHome";
import { ExclamationCircleOutlined, LockOutlined } from "@ant-design/icons";
import ConfirmApproveModal from "./Modals/confirmApproveModal";
import ConfirmRejectModal from "./Modals/confirmRejectModal";
import StaffLeaveChartGraph from "./localComponents/staffLeaveChartGraph";
import NoDataCard from "pages/digitalLibrary/components/noDataCard";
import PageHeader from "components/pageHeader";
import { Heading } from "components/Typography";
// import StaffLeaveGraph from "./localComponents/staffLeaveSummaryGraph";

const bodyStyle = {
  background: "#FAFAFA",
  // width: "calc(100% + 30px)",
  // height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "hidden",
};


const image4 = require("../../Assets/user/ic-students.svg").default;
const image5 = require("../../Assets/user/ic-teachers.svg").default;
const image6 = require("../../Assets/user/ic-data-operator.svg").default;
const image7 = require("../../Assets/user/ic-operation.svg").default;
const images = require("../../Assets/settingss/ic-geo-fence.svg").default;
const image1 = require("../../Assets/settingss/ic-leaves.svg").default;
const image2 = require("../../Assets/settingss/ic-holidays.svg").default;
const image3 = require("../../Assets/settingss/ic-minimum-attendance.svg").default;

const { TabPane } = Tabs;
const data = [
  {
    key: "1",
    content: "Create a virtual geographic boundary around an area",
    image: images,
    cardname: "Geo Fence",
    view: "VIEW",
    color: "#E7F3FF",
  },
  {
    key: "2",
    content: "Customise leave policies for your organisation",
    image: image1,

    cardname: "Leaves",
    view: "VIEW",
    color: "#FFF4D5",
  },
  {
    key: "3",
    content: "Set/create holidays for your staffs and students",
    image: image2,
    cardname: "Holidays",
    view: "VIEW",
    color: "#F6F4FE",
  },
  {
    key: "4",
    content: "Set a standard attendance requirements/policies for your pupils",
    image: image3,
    cardname: "Attendance Requirements",
    view: "VIEW",
    color: "#E9FCF5",
  },
];

function UserCard({ userName, count, avgCount, storeUsersName }) {
  const history = useHistory();
  return (
    <div
      className="boxShadow118 border-16 pvUserCard"
      onClick={() => {
        if (userName === "Students") {
          storeUsersName("Student")
          history.push("/attendance-student");
        } else if (userName === "Data Operators") {
          history.push("/attendance-data-operator");
          storeUsersName("Data Operator")
        } else if (userName === "Operations") {
          history.push("/attendance-operation");
          storeUsersName("Operation")
        } else {
          history.push("/attendance-teacher");
          storeUsersName("Teacher")
        }
      }}
    >
      <div style={{ marginTop: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{ width: "25%", display: "flex" }} src={userName === "Students" ? image4 :
          userName === "Teachers" ? image5 :
            userName === "Data Operators" ? image6 :
              userName === "Operations" ? image7 : null} />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              fontSize: "1.2em",
              color: "#191919",
              fontFamily: "Roboto",
              fontWeight: "bold"
            }}
          >
            {userName}
          </div>
          <div
            style={{
              fontSize: "17px",
              color: "#AEAEAE",
              fontFamily: "Roboto",
              marginLeft: "5px"
            }}
          >
            ({count})
          </div>
        </div>
        <div
          style={{
            fontSize: "1em",
            color: "#636363",
            fontFamily: "Roboto",
            textAlign: "center"
          }}
        >
          {avgCount}{"%"}{" "}{"average"}

        </div>
      </div>
    </div>
  );
}

function SettingsCard(props) {
  const history = useHistory();
  return (
    <div
      className="pvSettingsCard"
      style={{
        backgroundColor: `${props.bgcolor}`
      }}
      onClick={() => {
        if (props.keys === '2') {
          history.push("/leave-graph");
        } else if (props.keys == 1) {
          props?.storeUsersName("All")
          history.push('/attendance-geofence');
        }
        else if (props.keys == 4) {
          history.push("attendance-requirements");
        }
        else if (props.keys == 3) {
          history.push("holiday-page");
        }
      }}
    >
      <img src={props.image} />
      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
        <Typography.Text ellipsis={{ tooltip: true }}
          style={{
            fontFamily: "Roboto",
            fontSize: "15px",
            color: "#191919",
            fontWeight: "bold",
            width: 200
          }}
        >
          {props.cardname}
        </Typography.Text>
        <div
          className="text-ellipsis--2"
          style={{
            fontFamily: "Roboto",
            fontSize: "13px",
            color: "#636363",
          }}
        >
          {props.cardetail}
        </div>
        <div
          style={{
            fontFamily: "Roboto",
            fontSize: "11px",
            color: "#1089FF",
            fontWeight: "bold",
          }}
        >
          {props.view}
        </div>
      </div>
    </div>
  );
}

const ButtonsDiv = ({
  requestedActionData,
  setCardId,
  id,
  requestedLeaveType,
  setActionData,
  userRemainingLeaves,
  setSpecificUserId,
  requestedUserId,
  previlegedViewDataContainer,
  index,
  isRejectModalVisible,
  setIsRejectModalVisible,
  isApproveModalVisible,
  setIsApproveModalVisible,
  getPrevilegedViewData,
  togglePageLoader,
  setIsButtonDivVisible,
  setClickedOnUserCard,
  tag }) => {
  const history = useHistory();
  return (
    <div>
      {previlegedViewDataContainer?.requests?.[index]?.requestType === "Leave" ?
        <div style={{ display: "flex", }}>

          <div style={{ marginLeft: "10px", marginTop: "6px" }}>
            <ExclamationCircleOutlined />
          </div>
          <div style={{ font: "normal normal bold 10px/16px Roboto", color: "#636363", marginTop: "10px", marginLeft: "5px" }}>
            {userRemainingLeaves} {requestedLeaveType} {" "}
            {userRemainingLeaves > 1 ? "leaves" : "leave"} remaining
          </div>
          <div style={{ font: " normal normal bold 10px/16px Roboto", color: "#1089FF", marginLeft: "50px", marginTop: "10px" }}
            onClick={() => {
              history.push({
                pathname: "/user/details",
                state:
                {
                  tab: (tag === "Leave" ? "leave" : "attendance")
                }
              });
              setClickedOnUserCard(true);
              setSpecificUserId(requestedUserId)
            }}>VIEW MORE</div>
        </div>
        :
        <div style={{ display: "flex", }}>

          <div style={{ marginLeft: "10px", marginTop: "6px" }}>
            {/* <ExclamationCircleOutlined /> */}{"  "}
          </div>
          <div style={{ font: "normal normal bold 10px/16px Roboto", color: "#636363", marginTop: "10px", marginLeft: "5px", width: "120px" }}>{" "}
          </div>
          <div style={{ font: " normal normal bold 10px/16px Roboto", color: "#1089FF", marginLeft: "50px", marginTop: "10px" }}
            onClick={() => {
              history.push({
                pathname: "/user/details",
                state:
                {
                  tab: (tag === "Leave" ? "leave" : "attendance")
                }
              });
              setClickedOnUserCard(true);
              setSpecificUserId(requestedUserId)
            }}>VIEW MORE</div>
        </div>
      }
      <div style={{ display: "flex"/* ,marginLeft:"60px" */, marginTop: "10px", justifyContent: "space-around" }}>
        <Button
          style={{
            width: "fit-content",
            height: "25px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #636363",
            borderRadius: " 100px",
            fontSize: "10px"
          }} onClick={(e) => {
            e.stopPropagation();
            setIsRejectModalVisible(true);
          }}
        >REJECT</Button>
        <ConfirmRejectModal
          isRejectModalVisible={isRejectModalVisible}
          setIsRejectModalVisible={setIsRejectModalVisible}
          setCardId={setCardId}
          id={id}
          setActionData={setActionData}
          requestedActionData={requestedActionData}
          functionProp={getPrevilegedViewData}
          togglePageLoader={togglePageLoader}
          setIsButtonDivVisible={setIsButtonDivVisible}
        />
        <Button
          style={{
            width: "fit-content",
            height: "25px",
            background: "#28DF99 0% 0% no-repeat padding-box",
            borderRadius: " 100px",
            color: "#FFFFFF",
          }} onClick={(e) => {
            e.stopPropagation();
            setIsApproveModalVisible(true)
          }}>
          <div style={{ fontSize: "10px", marginLeft: "-6px" }}>APPROVE</div></Button>
        <ConfirmApproveModal
          isApproveModalVisible={isApproveModalVisible}
          setIsApproveModalVisible={setIsApproveModalVisible}
          setCardId={setCardId}
          id={id}
          setActionData={setActionData}
          requestedActionData={requestedActionData}
          functionProp={getPrevilegedViewData}
          togglePageLoader={togglePageLoader}
          setIsButtonDivVisible={setIsButtonDivVisible}
        />
      </div>
    </div>
  )
}


const LeavereqCard = ({
  requestedimage,
  requestedName,
  requestedRole,
  requestedTime,
  requestedLeaveType,
  tag,
  userIsNew,
  cardIndex,
  setCardId,
  cardStatus,
  setActionData,
  requestedActionData,
  userRemainingLeaves,
  requestedUserId,
  setSpecificUserId,
  previlegedViewDataContainer,
  index,
  getPrevilegedViewData,
  setClickedOnUserCard,
  setIsButtonDivVisible,
  isButtonDivVisible }) => {
  // let [isButtonDivVisible,setIsButtonDivVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
      <div
        style={{
          marginRight: 10,
          borderRadius: "4px",
          width: "100%",
          backgroundColor: "#FFFFFF",
          cursor: "default",
          marginBottom: "20px",
          padding: "0px 10px 10px 0px",
          height: "85px",
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <div className="m-b-5" style={{ display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
            <Tag style={{
              color: `${tag === "Leave" ? " #1089FF" : "#594099"}`,
              backgroundColor: `${tag === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
              borderColor: `${tag === "Leave" ? "#E7F3FF" : "#F6F4FE"}`,
              fontSize: "10px",
              fontWeight: "bold",
            }}>{tag}</Tag>
          </div>
        </div>
        <div className="display-flex" style={{ marginLeft: "10px" }}>
          <div>
            <img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={`${Api._s3_url}${requestedimage}`} />
          </div>
          <div className="m-l-10" >

            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontSize: "13px",
                  color: "#191919",
                  fontWeight: 550,
                  fontFamily: "Roboto",
                }}
              >
                {requestedName}
              </div>
              <div style={{ display: userIsNew ? "view" : "none", marginTop: "-12px", marginLeft: "8px" }}>
                <Tag color="#FFA931" style={{ fontWeight: "bold", border: "none", fontSize: "8px", borderRadius: "100px", marginRight: "10px", marginTop: "10px" }}>New</Tag>
              </div>
            </div>

            <div style={{ display: "felx", }}>
              <div style={{ fontSize: "11px", color: "#636363", fontFamily: "Roboto", }}>
                {requestedRole}
              </div>
              <div
                style={{
                  fontSize: "9px",
                  color: "#AEAEAE",
                  fontFamily: "Roboto",
                }}
              >
                {moment().diff(moment(requestedTime), 'hour') + ' ' + 'hours ago'}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function AttendanceDashBoard({
  roleFilter,
  getPrevilegedViewData,
  previlegedViewDataContainer,
  storeUsersName,
  user,
  setCardId,
  setActionData,
  requestedActionData,
  setStatusData,
  setSpecificUserId,
  tab,
  setTabs,
  storeImportHoliday,
  setAllHolidayLoc,
  storeBackToPrivilegedView,
  setBackToPrivilegedView,
  setClickedOnUserCard,
  setIsButtonDivVisible,
  isButtonDivVisible,
  requestViewAllClicked,
  viewAllClickedData,
  attendanceDataContainer,
  setRoleFilter,
  getAttendanceDashBoardData
}) {
  const [pageLoader, togglePageLoader] = useState(false);
  const [sum, setSum] = useState(0)

  useEffect(() => {
    setSum(0)
    let temp = 0;

    if (previlegedViewDataContainer?.users?.length != 0) {
      previlegedViewDataContainer?.users?.forEach(
        (item) => (
          (temp = temp + parseInt(item?.total ? item?.total : 0))
        )
      )
    }
    setSum(temp)
  }, [previlegedViewDataContainer?.users])

  const history = useHistory()
  useEffect(() => {
    if (storeBackToPrivilegedView === true) {
      setTabs(2);
      setBackToPrivilegedView(false)
    } else {
      setTabs(1)
    }
    if (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager')) {
      togglePageLoader(true)
      getAttendanceDashBoardData(() => {
        togglePageLoader(false)
      })
    }
  }, [])

  useEffect(() => {
    if (storeBackToPrivilegedView === true) {
      togglePageLoader(true)
      getPrevilegedViewData(() => {
        togglePageLoader(false)
      })
    }
  }, [tab])

  function callback(key) {
    setTabs(key)
    togglePageLoader(true)
    if (key == "1") {
      getAttendanceDashBoardData(() => {
        togglePageLoader(false)
      })
    } else if (key == "2") {
      getPrevilegedViewData(() => {
        togglePageLoader(false)
      })
    }
  }

  useEffect(() => {
    roleFilter();
    setRoleFilter()
  }, [])

  // const buttons = [
  //   {label: 'VISIT ATTENDANCE', onClick: ()=>{}}
  // ]

  const actions = [
    attendanceDataContainer?.externalAttendance ? <Button type="primary" onClick={() => { window.open(attendanceDataContainer?.externalAttendance) }} style={{}} >VISIT ATTENDANCE</Button> : null
  ]

  return (
    <div style={bodyStyle}>

      <div style={{ marginTop: -10, height: 50, marginLeft: -20 }}>
        <PageHeader title='Attendance' actions={actions} />
      </div>
      <div
        style={{
          color: "#594099",
          fontWeight: 550,
          fontSize: "10px",
          marginTop: "10px",
        }}
      >
        {(user?.privilage?.includes("SuperAdmin") || user?.privilage?.includes('LeaveManager')) ?
          <Tabs activeKey={`${tab}`} onChange={callback}>
            <TabPane tab="MY ATTENDANCE" key="1" >
              <AttendanceHome
                togglePageLoader={togglePageLoader} />
            </TabPane>
            <TabPane tab="PRIVILEGED VIEW" key="2" >
              <Heading>
                Users
              </Heading>
              <div className="flex-wrap " style={{ display: "flex", justifyContent: 'space-between' }}>
                {previlegedViewDataContainer?.users?.map((items) => (
                  <UserCard
                    userName={items.name}
                    count={items.total}
                    avgCount={items.average}
                    storeUsersName={storeUsersName}
                  />
                ))}
              </div>

              <div style={{ marginTop: "20px", display: "flex", flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 11 }} className='scroll container border-16 boxShadow118 m-t-10'
                  style={{
                    width: "49%",
                    minWidth: 300,
                    height: "280px",
                    backgroundColor: "#FFFFFF",
                    borderColor: "#E6E6E6",
                  }}>

                  <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
                    <div style={{ display: "flex", }}>
                      <div
                        style={{ color: "#191919", fontSize: "15px", fontWeight: "bold", fontFamily: "Roboto", }}>
                        Request(s)
                      </div>
                      <div style={{ color: "#AEAEAE", fontSize: "15px", fontFamily: "Roboto", marginLeft: "5px" }}>({previlegedViewDataContainer?.count})</div>
                    </div>

                    <div style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                      history.push("/request-viewall")
                      storeUsersName('All');
                    }}>
                      VIEW ALL
                    </div>
                  </div>
                  {previlegedViewDataContainer?.requests?.length > 0 ?
                    <div className="flex-wrap " style={{ display: "flex", padding: "30px", marginTop: "-29px" }}>
                      {previlegedViewDataContainer?.requests?.map((items, index, i) => (
                        <>
                          {index < 4 && (
                            <LeavereqCard
                              requestedimage={items?.user?.image}
                              requestedName={items?.user?.name}
                              requestedRole={items?.user?.role}
                              requestedTime={items?.createdAt}
                              requestedLeaveType={items?.leaveType}
                              tag={items?.requestType}
                              userIsNew={items?.isNew}
                              cardIndex={items?.id}
                              userRemainingLeaves={items?.remaingLeaves}
                              setCardId={setCardId}
                              cardStatus={items?.status}
                              setActionData={setActionData}
                              requestedActionData={requestedActionData}
                              requestedUserId={items?.user?.id}
                              setSpecificUserId={setSpecificUserId}
                              previlegedViewDataContainer={previlegedViewDataContainer}
                              index={index}
                              getPrevilegedViewData={getPrevilegedViewData}
                              setClickedOnUserCard={setClickedOnUserCard}
                              setIsButtonDivVisible={setIsButtonDivVisible}
                              isButtonDivVisible={isButtonDivVisible}
                            />
                          )}
                        </>

                      ))}
                    </div>
                    :
                    <NoDataCard />
                  }
                </Col>

                <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 12 }} className='container boxShadow118 border-16 m-t-10' style={{
                  width: "50%",
                  minWidth: 520,
                  height: "280px",
                  backgroundColor: "#FFFFFF",
                  borderColor: "#E6E6E6",
                }} >
                  <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
                    <div
                      style={{ color: "#191919", fontSize: "15px", fontWeight: "bold", fontFamily: "Roboto", }}>
                      Staff Leave Summary
                    </div>
                    <div style={{ color: "#1089FF", fontSize: "13px", fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                      history.push("/leaves-overview");
                    }}>
                      VIEW MORE
                    </div>
                  </div>
                  <div style={{ width: "90%", marginTop: "-98px", marginLeft: "40px", display: 'flex', justifyContent: 'space-around' }}>
                    <StaffLeaveChartGraph leavesOverviewOfStaff={previlegedViewDataContainer} />
                    <div style={{ display: "flex", marginTop: "140px", marginLeft: "30px", flexDirection: 'column', width: 180, height: 80 }}>
                      <div className="r-jsb-fc" style={{ height: 80 }}>
                        <div className="display-flex" style={{ marginLeft: 8 }}>
                          <div style={{
                            backgroundColor: "#9876E6",
                            width: "10px",
                            height: "10px",
                            borderRadius: "3px",
                            marginRight: "5px"
                          }}>

                          </div>
                          <div style={{ fontSize: 12, marginTop: "-5px" }}>
                            Teachers
                          </div>
                        </div>
                        <div style={{ marginLeft: "8px", display: "flex" }}>
                          <div style={{
                            backgroundColor: "#99DED9",
                            width: "10px",
                            height: "10px",
                            borderRadius: "3px",
                            marginRight: "5px"
                          }}>

                          </div>
                          <div style={{ fontSize: 12, marginTop: "-5px" }}>
                            Data Operators
                          </div>

                        </div>
                        <div style={{ marginLeft: "8px", display: "flex" }}>
                          <div style={{
                            backgroundColor: "#589DFF",
                            width: "10px",
                            height: "10px",
                            borderRadius: "3px",
                            marginRight: "5px"
                          }}>

                          </div>
                          <div style={{ fontSize: 12, marginTop: "-5px" }}>
                            Operations
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </Col>
              </div>
              <Heading className="m-t-20">
                Settings
              </Heading>
              <div className="flex-wrap " style={{ display: "flex", justifyContent: 'space-between' }}>
                {data?.map((items) => (
                  <SettingsCard
                    cardname={items.cardname}
                    cardetail={items.content}
                    view={items.view}
                    bgcolor={items.color}
                    image={items.image}
                    keys={items.key}
                    storeImportHoliday={storeImportHoliday}
                    setAllHolidayLoc={setAllHolidayLoc}
                    storeUsersName={storeUsersName}
                  />
                ))}
              </div>
            </TabPane>
          </Tabs>
          :
          <AttendanceHome
            togglePageLoader={togglePageLoader} />
        }
      </div>
      <PageDataLoader visible={pageLoader} />
    </div >
  );
}

const mapStateToProps = (state) => {
  const { previlegedViewDataContainer, usersNameContainer, cardId, actionDataContainer, statusDataContainer, requestedUserIdContainer, tab, setAllHolidayLoc, storeBackToPrivilegedView, isButtonDivVisible, viewAllClickedData, attendanceDataContainer } =
    state.attendanceNew;
  const { user } = state.session
  return {
    previlegedViewDataContainer, usersNameContainer, user, cardId, actionDataContainer, statusDataContainer, requestedUserIdContainer, tab, setAllHolidayLoc, storeBackToPrivilegedView, isButtonDivVisible, viewAllClickedData, attendanceDataContainer
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPrevilegedViewData: (callback) => (dispatch(getPrevilegedViewData(callback))),
  storeUsersName: (val) => (dispatch(storeUsersName(val))),
  setTabs: (val) => (dispatch(setTabs(val))),
  setCardId: (id) => dispatch(setCardId(id)),
  setActionData: (val) => dispatch(setActionData(val)),
  requestedActionData: (callback, successCallBack) => dispatch(requestedActionData(callback, successCallBack)),
  setStatusData: (val) => dispatch(setStatusData(val)),
  setSpecificUserId: (val) => dispatch(setSpecificUserId(val)),
  storeImportHoliday: (value) => dispatch(storeImportHoliday(value)),
  setBackToPrivilegedView: (val) => dispatch(setBackToPrivilegedView(val)),
  setClickedOnUserCard: (val) => dispatch(setClickedOnUserCard(val)),
  setIsButtonDivVisible: (key, val) => dispatch(setIsButtonDivVisible(key, val)),
  requestViewAllClicked: (val) => dispatch(requestViewAllClicked(val)),
  roleFilter: (val) => dispatch(roleFilter(val)),
  setRoleFilter: (val) => dispatch(setRoleFilter(val)),
  getAttendanceDashBoardData: (callBack) => dispatch(getAttendanceDashBoardData(callBack))


});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceDashBoard)