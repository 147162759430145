import React, { useState } from 'react';
import { Modal } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import { connect } from 'react-redux';
import { deleteUsersFromTable } from 'actions/userManagementV2';


const UserDeleteModal = ({ open, onCancel, deleteUsersFromTable, selectedRowKeys, setSelectedRowKeys, params, togglePageLoader, getUsers }) => {

        const [deleteLoader, setDeleteLoader] = useState(false);

        console.log({ selectedRowKeys })

        const successCallback = () => {
                setSelectedRowKeys([]);
                onCancel();

                togglePageLoader(true);
                getUsers(params, () => togglePageLoader(false))
        }

        const handleDeleteClick = () => {

                let params = {
                        userIds: selectedRowKeys
                }

                setDeleteLoader(true);
                deleteUsersFromTable(params, () => setDeleteLoader(false), () => successCallback());
        }

        return (
                <div>
                        <Modal
                                centered
                                open={open}
                                footer={null}
                                className='modal-round-corner'
                                onCancel={onCancel}
                        >
                                <center className='p-20'>
                                        <Heading>Confirm Suspended User(s)</Heading>
                                        <div>Once you confirm, the user(s) will be log out from all devices.</div>
                                        <div style={{ width: '80%' }}>Please note that Suspended user(s) will go to Suspended Users folder in User Management where you will be able to Restore and/or Manage their data.</div>

                                        <div className='r-jsb m-t-40' style={{ width: '70%' }}>
                                                <Button loading={deleteLoader} onClick={() => handleDeleteClick()} type='transparent' style={{ color: '#FF414D' }}>YES, ARCHIVE USER(S)</Button>
                                                <Button onClick={() => onCancel()} type='primary'>CANCEL</Button>
                                        </div>
                                </center>
                        </Modal>
                </div>
        );
};

const mapStateToProps = (state) => {
        const { user } = state.session;
        const { } = state.userManagementV2;
        return {};
};

const mapDispatchToProps = (dispatch) => ({
        deleteUsersFromTable: (params, callback, successCallback) => dispatch(deleteUsersFromTable(params, callback, successCallback))
});



export default connect(mapStateToProps, mapDispatchToProps)(UserDeleteModal);