import React from 'react';
import courseOnlineIcon from "../../../../Assets/courseManagementV4/courseOnlineIcon.svg";
import courseOfflineIcon from "../../../../Assets/courseManagementV4/courseOfflineIcon.svg";
import courseHybridIcon from "../../../../Assets/courseManagementV4/courseHybridIcon.svg";
import { Information } from 'components/Typography';

function OverviewList({ item }) {
    const icon = {
        online: courseOnlineIcon,
        offline: courseOfflineIcon,
        hybrid: courseHybridIcon
    }

    const text = {
        online: "online",
        offline: "offline",
        hybrid: "hybrid"
    }

    return (
        <div className='r-ac' style={{ marginTop: 11 }}>
            <div><img src={icon[item?.mode]} style={{ height: 25, width: 25 }} /></div>
            <div className='m-l-10 flex-column'>
                <Information>{item?.studentCount} students</Information>
                <Information>{item?.count} {text[item?.mode]} class</Information>
            </div>
        </div>
    )
}

export default OverviewList