import { Pie } from '@ant-design/charts'
import React from 'react'
const NoData = require("../../../../Assets/ill-no-data.svg").default




const NoDataCard = () => {
  return (
    <>
      <div className='r-c-c-c' /* style={{marginTop:"200px"}} */>
        <img src={NoData} />
        <div style={{ color: "#191919", fontWeight: "700", fontStyle: "roboto", fontSize: "18px" }}>No Data</div>
        <div style={{ color: "#636363", fontWeight: "600", fontStyle: "roboto", fontSize: "13px" }}>There are no data to show yet. Please check back later.</div>
      </div>
    </>
  )
}

// const DataCard = (presentStudents,studentAttendanceData) =>{
//   {
//     const data = [
//       {
//         type: 'Present',
//         value: presentStudents?.length?presentStudents?.length:null,
//       },
//       {
//         type: 'Absent',
//         value: studentAttendanceData?.length?(studentAttendanceData?.length - presentStudents?.length):null,
//       }
//     ];

//     const config = {
//       appendPadding: 10,
//       data,
//       angleField: 'value',
//       colorField: 'type',
//       color: ['#28DF99', '#FF414D'],
//       radius: 0.8,
//       autoFit: true,
//       legend: false,
//       innerRadius: 0.68,
//       label: {
//         type: 'inner',
//         offset: '-50%',
//         content:"",
//         style: {
//           fill: '#fff',
//           fontSize: 14,
//           textAlign: 'center',
//         },
//       },
//       statistic: {
//         title: false,
//         content: {
//           style: {
//             whiteSpace: 'pre-wrap',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//             fontSize: 8
//           },
//           customHtml: function customHtml() {
//             return (
//                     <div>
//                         <div style={{fontSize:"20px", fontWeight:"700"}}>{studentAttendanceData?studentAttendanceData.length:0}</div>
//                         <div style={{marginTop: "5px", fontSize:"10px", fontWeight:"100", color:"grey"}}>Total Students</div>
//                     </div>)
//           }
//         },
//       },
//       annotations: [
//       ],
//     };

//   const tags = ["Present", "Absent"]
//   const tagcolor = ['#28DF99', '#FF414D']


// }

const AttendanceSummaryCard = ({ onGoingItem, presentStudents, studentAttendanceData, initialSummary }) => {

  const data = [
    {
      type: 'Present',
      value: initialSummary?.presentCount ? initialSummary?.presentCount : 0,
    },
    {
      type: 'Absent',
      value: initialSummary?.absentCount ? initialSummary?.absentCount : 0,
    }
  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#28DF99', '#FF414D'],
    radius: 0.8,
    autoFit: true,
    legend: false,
    innerRadius: 0.68,
    label: {
      type: 'inner',
      offset: '-50%',
      content: "",
      style: {
        fill: '#fff',
        fontSize: 14,
        textAlign: 'center',
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 8
        },
        customHtml: function customHtml() {
          return (
            <div>
              <div style={{ fontSize: "20px", fontWeight: "700" }}>{initialSummary?.totalStudents ? initialSummary?.totalStudents : 0}</div>
              <div style={{ marginTop: "5px", fontSize: "10px", fontWeight: "100", color: "grey" }}>Total Students</div>
            </div>)
        }
      },
    },
    annotations: [
    ],
  };

  const tags = ["Present", "Absent"]
  const tagcolor = ['#28DF99', '#FF414D']

  return (
    <div className='attendance-summaryCard'>
      <div className='attendance-summaryCard-header'>Attendance Summary</div>
      {onGoingItem?.attendanceMarked ?
        <div>
          <div style={{ width: '280px' }}>
            <Pie style={{ height: '250px' }} {...config} />
          </div>
          <div style={{
            display: "flex",
            justifyContent: 'center'
          }}>
            {tags?.map((tag, index) =>
              <div className="r-c-c m-r-15" key={index}>
                <div style={{
                  backgroundColor: tagcolor[index],
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                  marginRight: "5px"
                }}>

                </div>
                {tag == 'Present' ?
                  <div style={{ fontSize: 12, color: '#636363' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#191919' }}>{initialSummary?.presentCount}</span>  {tag}
                  </div>
                  :
                  <div style={{ fontSize: 12, color: '#636363' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#191919' }}>{initialSummary?.absentCount}</span>  {tag}
                  </div>
                }

              </div>
            )
            }
          </div>

        </div>
        :
        <NoDataCard />
      }
    </div>
  )
}

export default AttendanceSummaryCard