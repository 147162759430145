import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clearPrivateChat } from 'actions/broadcast';
import { logout, notificationBadgeList } from 'actions/login';
import { setScrollPosition, setWidthOfMenu, showMenu, toggleSider } from 'actions/layout';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { Api } from 'services';
import { locations } from './locations';
import LogoutConfirm from './logoutConfirm';
import { itemsGrp, menuItems } from './menuItems';
import { ProfileMenu } from './profileMenu';
import './styles.css';
import { Dropdown, Tooltip, Typography } from 'antd';
import UserAvatar from 'components/userAvatar';
import { setIsLogoutClicked } from 'actions/studentMarketPlace';
import { urlWithoutMenu } from 'services/urlWithoutMenu';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import chevronLeft from "../Assets/layout/newFilledIcons/chevron-double-left.svg";
import chevronRight from "../Assets/layout/newFilledIcons/chevron-double-right.svg";
import addUserEnabled from "../Assets/layout/newFilledIcons/add-user-enabled.svg";
import addUserDisabled from "../Assets/layout/newFilledIcons/add-user-disabled.svg";
import addFeeEnabled from "../Assets/layout/newFilledIcons/add-fee-enabled.svg";
import addFeeDisabled from "../Assets/layout/newFilledIcons/add-fee-disabled.svg";
import addScheduleEnabled from "../Assets/layout/newFilledIcons/add-schedule-enabled.svg";
import addScheduleDisabled from "../Assets/layout/newFilledIcons/add-schedulle-disabled.svg";
import addAnnounceEnabled from "../Assets/layout/newFilledIcons/add-announcement-enabled.svg";
import addAnnounceDisabled from "../Assets/layout/newFilledIcons/add-announcement-disabled.svg";
import markAttendanceEnabled from "../Assets/layout/newFilledIcons/mark-attendance-enabled.svg";
import markAttendanceDisabled from "../Assets/layout/newFilledIcons/mark-attendance-disabled.svg";
import applyLeaveEnabled from "../Assets/layout/newFilledIcons/apply-leave-enabled.svg";
import applyLeaveDisabled from "../Assets/layout/newFilledIcons/apply-leave-disabled.svg";
import createCourseEnabled from "../Assets/layout/newFilledIcons/cretae-course-enabled.svg";
import createCourseDisabled from "../Assets/layout/newFilledIcons/create-course-disabled.svg";
import addResourceEnabled from "../Assets/layout/newFilledIcons/add-resource-enabled.svg";
import addResourceDisabled from "../Assets/layout/newFilledIcons/add-resource-disabled.svg";
import addTestEnabled from "../Assets/layout/newFilledIcons/add-test-enabled.svg";
import addTestDisabled from "../Assets/layout/newFilledIcons/add-test-disabled.svg";
import pencilOutlined from "../Assets/layout/newFilledIcons/pencil.svg";
import menuCollapsed from "../Assets/layout/newFilledIcons/collapsed.svg";
import addUserWhite from "../Assets/layout/newFilledIcons/addUserWhite.svg";
import addFeeWhite from "../Assets/layout/newFilledIcons/addFeeWhite.svg";
import addScheduleWhite from "../Assets/layout/newFilledIcons/addScheduleWhite.svg";
import addAnnouncementWhite from "../Assets/layout/newFilledIcons/addAnnouncementWhite.svg";
import markAttendanceWhite from "../Assets/layout/newFilledIcons/markAttendanceWhite.svg";
import applyLeaveWhite from "../Assets/layout/newFilledIcons/applyLeaveWhite.svg";
import createCourseWhite from "../Assets/layout/newFilledIcons/createCourseWhite.svg";
import addResourceWhite from "../Assets/layout/newFilledIcons/addResourceWhite.svg";
import addTestwhite from "../Assets/layout/newFilledIcons/addTestwhite.svg";
import bellIcon from "../Assets/layout/newFilledIcons/bell.svg";




import QuickAccessModal from './quickAccessModal';
import { fetchNotificationsForMenu, fetchUserMenuSettingsData, setMenuSettingsData, setQuickAccessItem, updateUserMenuSettings } from 'actions/userManagementV2';
import MenuContext from 'antd/lib/menu/MenuContext';
import CollapseQuickAccessModal from './collapseQuickAccessModal';
import { fetchNotifications } from 'actions/notification';
import { setCourseTypeName } from 'actions/courseManagement';






const SideMenu = React.memo((props) => {

    const { user,
        notifyBadgeListCount,
        orgData,
        logout,
        clearPrivateChat,
        notificationBadgeList,
        collapsed,
        toggleSider,
        authenticated,
        setWidthOfMenu,
        setIsLogoutClicked,
        scrollPosition,
        setScrollPosition,
        showMenu,
        myCoursePage, myCoursePageForTeacher,
        fetchUserMenuSettingsData,
        userMenusettingsData,
        updateUserMenuSettings,
        menuSettingsData,
        fetchNotificationsForMenu,
        setQuickAccessItem,
        fetchNotifications,
        notification,
        setCourseTypeName,
        isLogoutClicked


    } = props;

    const setKeyBylocation = () => {
        locations.forEach((item, i) => {
            if (location.pathname === item.path) {
                setSelectedKey(item.key);
            }
        });
    };

    const setKeyByUrlPath = () => {
        urlWithoutMenu.forEach((item, i) => {
            if (location.pathname === item.urlPath) {
                showMenu(false)
            }
        });

    }

    let allItems

    const allOptions = [
        { id: 0, index: 0, label: "Add user", enabledIcon: addUserEnabled, disabledIcon: addUserDisabled, whiteIcon: addUserWhite },
        { id: 1, index: 1, label: "Add fee request", enabledIcon: addFeeEnabled, disabledIcon: addFeeDisabled, whiteIcon: addFeeWhite },
        { id: 2, index: 2, label: "Add schedule", enabledIcon: addScheduleEnabled, disabledIcon: addScheduleDisabled, whiteIcon: addScheduleWhite },
        { id: 3, index: 3, label: "Add announcement", enabledIcon: addAnnounceEnabled, disabledIcon: addAnnounceDisabled, whiteIcon: addAnnouncementWhite },
        { id: 4, index: 4, label: "Mark attendance", enabledIcon: markAttendanceEnabled, disabledIcon: markAttendanceDisabled, whiteIcon: markAttendanceWhite },
        { id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite },
        { id: 6, index: 6, label: "Create course", enabledIcon: createCourseEnabled, disabledIcon: createCourseDisabled, whiteIcon: createCourseWhite },
        { id: 7, index: 7, label: "Add resource", enabledIcon: addResourceEnabled, disabledIcon: addResourceDisabled, whiteIcon: addResourceWhite },
        { id: 8, index: 8, label: "Add test", enabledIcon: addTestEnabled, disabledIcon: addTestDisabled, whiteIcon: addTestwhite },
    ]

    const getAllItems = () => {
        // SuperAdmin and Admin get all items
        if (Object.keys(user).length !== 0 && (user.privilage.includes('SuperAdmin') || user.privilage.includes('Admin'))) {
            return allOptions;
        }

        // Define role-based items
        const roleBasedItems = {
            ['Teacher']: [{ id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
            ['Data Operator']: [
                { id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite },
                { id: 8, index: 8, label: "Add test", enabledIcon: addTestEnabled, disabledIcon: addTestDisabled, whiteIcon: addTestwhite },
                { id: 7, index: 7, label: "Add resource", enabledIcon: addResourceEnabled, disabledIcon: addResourceDisabled, whiteIcon: addResourceWhite }],
            ['Marketing']: [{ id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
            ['Operation']: [{ id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
            ['AttendanceMarker']: [{ id: 4, index: 4, label: "Mark attendance", enabledIcon: markAttendanceEnabled, disabledIcon: markAttendanceDisabled, whiteIcon: markAttendanceWhite },
            { id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
            ['FeeManager']: [{ id: 1, index: 1, label: "Add fee request", enabledIcon: addFeeEnabled, disabledIcon: addFeeDisabled, whiteIcon: addFeeWhite },
            { id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
            ['Planner']: [{ id: 2, index: 2, label: "Add schedule", enabledIcon: addScheduleEnabled, disabledIcon: addScheduleDisabled, whiteIcon: addScheduleWhite },
            { id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
            ['LeaveManager']: [{ id: 5, index: 5, label: "Apply leave", enabledIcon: applyLeaveEnabled, disabledIcon: applyLeaveDisabled, whiteIcon: applyLeaveWhite }],
        };

        let selectedItems = [];

        // Add items based on user role
        Object.keys(user).length !== 0 && user.role.forEach((role) => {
            if (roleBasedItems[role]) {
                selectedItems = [...selectedItems, ...roleBasedItems[role]];
            }
        });

        // Add items based on privileges
        Object.keys(user).length !== 0 && user.privilage.forEach((privilege) => {
            if (roleBasedItems[privilege]) {
                selectedItems = [...selectedItems, ...roleBasedItems[privilege]];
            }
        });

        const uniqueItems = selectedItems.filter(
            (item, index, self) => self.findIndex(i => i.id === item.id) === index
        );

        return uniqueItems;
    };

    allItems = getAllItems();
    // allItems = allOptions



    const [selectedItems, setSelectedItems] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownVisibleCollapse, setDropdownVisibleCollapse] = useState(false);
    const [editClick, setEditClick] = useState(false);

    useEffect(() => {
        const userId = user?.id
        if (!dropdownVisible) {
            if (!isLogoutClicked) {
                fetchUserMenuSettingsData(userId, () => { }, () => { setSelectedItems(userMenusettingsData?.menuSettings); })
                setSelectedItems(userMenusettingsData?.menuSettings);
                fetchNotifications(() => { });

            }

        }
        // fetchNotificationsForMenu(()=>{})

    }, [dropdownVisible]);




    const location = window.location;
    const divRef = useRef(null);
    const menuRef = useRef(null);


    useEffect(() => {
        setKeyBylocation();
        setKeyByUrlPath()
        // notificationBadgeList();
    }, [location.pathname]);

    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.clientWidth;
            setWidthOfMenu(divWidth);
        }
    }, [collapsed]);

    const [confirmLogoutVisible, setConfirmLogoutVisible] = useState(false);
    const [selectedKey, setSelectedKey] = useState(1);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [hovered, setHovered] = useState(false);
    const history = useHistory();

    useEffect(() => {
        return () => {
            if (menuRef.current) {
                setScrollPosition(menuRef.current.scrollTop);
            }
        }
    }, []);

    useEffect(() => {
        if (menuRef.current) {
            menuRef.current.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    useEffect(() => {
        menuSettingsData?.filter(item => item !== null);
    }, [menuSettingsData])

    const handleLogout = () => {
        clearPrivateChat();
        const reason = {
            type: 'Manual'
        }
        logout(history, reason);
        setIsLogoutClicked(true);
    };

    const handleDropdownVisibleChangeCollapse = (visible) => {
        setDropdownVisibleCollapse(visible);
    };



    const handleDropdownVisibleChange = (visible) => {
        setDropdownVisible(visible);
        
        if (!visible) {
            setEditClick(false)
            const userId = user?.id
            if (menuSettingsData.length < 1) {
                // alert("You must select at least 1 items.");
                setDropdownVisible(true); // Keep the dropdown open
                return;
            }

            updateUserMenuSettings(userId, () => { }, () => {
                if (user.accessToken) {
                    fetchUserMenuSettingsData(userId, () => { }, () => { setSelectedItems(userMenusettingsData?.menuSettings) })
                }
            })
        }
    };


    return (
        <div ref={divRef} style={{ position: "relative", height: "100%", width: collapsed ? "5%" : "20%", minWidth: collapsed ? "90px" : "200px", maxWidth: collapsed ? "110px" : "270px", background: "#FAFAFA", padding: "20px 0px", transition: "width 0.8s ease, min-width 0.8s ease, max-width 0.8s ease", }} className="r-c-c-c">


            {hovered && collapsed && (
                <div
                    className='mini-sidebar-toggle'
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleSider(!collapsed);
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <img src={chevronRight} alt="Toggle sidebar" />
                </div>
            )}

            <div className='menuWrapper flex-column' id="sideMenuId"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div className='display-flex m-l-20 m-t-20 flex-1 cursor-pointer' style={{ alignItems: "center" }} >
                    <Tooltip title={<span style={{ color: "#000" }}>{orgData?.title}</span>} placement="right" color='#FFF' onClick={() => history.push("/")} >
                        <div className=''>
                            <img style={{ borderRadius:'50%', height: "5vmin", width: "5vmin" }} src={`${Api._s3_url}${orgData?.logo || "defaultLogo.png"}`} alt="" />
                        </div>
                    </Tooltip>
                    {
                        collapsed ?
                            null
                            :
                            <div className='orgTitle' onClick={() => history.push("/")}>
                                {orgData?.title}
                            </div>
                    }
                    {hovered && !collapsed && (
                        <div
                            onClick={e => { e.isPropagationStopped(); toggleSider(!collapsed); setHovered(false) }}
                            className="sidebar-toggle"
                        >
                            <img src={chevronLeft} alt="Toggle sidebar" />
                        </div>
                    )}
                </div>
                {
                    !collapsed &&
                    <div style={{ marginTop: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Roboto, sans-serif', alignItems: 'center', width: '88%', marginLeft: '6%', marginBottom: 5 }}>
                            <div style={{ color: '#636363', fontWeight: 500, fontSize: 11 }}>QUICK ACCESS</div>

                            {allItems.length > 1 ?
                                <div className='custom-dropdown-position'>
                                    {/* <DndProvider backend={HTML5Backend}> */}
                                    <Dropdown
                                        overlay={<QuickAccessModal
                                            userMenusettingsData={userMenusettingsData}
                                            selectedItems={selectedItems}
                                            allItems={allItems}
                                            setSelectedItems={setSelectedItems}
                                            setEditClick={setEditClick}
                                            editClick={editClick}
                                        // onChange={handleItemsChange}
                                        />}
                                        visible={dropdownVisible}
                                        onVisibleChange={handleDropdownVisibleChange}
                                        trigger={['click']}
                                        placement='bottomRight'
                                        overlayStyle={{
                                            width: "290px",
                                            marginLeft: 80
                                        }}
                                    >

                                        <img src={pencilOutlined} style={{ height: 17, width: 17, marginBottom: 3, cursor: 'pointer' }} onClick={()=>{setEditClick(false)}} />
                                    </Dropdown>
                                    {/* </DndProvider> */}
                                </div>
                                :
                                <></>}



                            {/* <div ><img src={pencilOutlined} style={{ height: 17, width: 17, marginBottom: 3 }} onClick={() => setIsModalVisible(true)} /></div> */}
                        </div>
                        <div className="quick-access">
                            {userMenusettingsData?.menuSettings && userMenusettingsData?.menuSettings
                                .map((selected) =>
                                    allItems.find((item) => item?.label === selected?.label)
                                )
                                .filter(Boolean)
                                .map((item, index) => (
                                    <div key={index} className="quick-access-menu-item"
                                        onClick={() => {

                                            if (item?.label == 'Add user') {
                                                setQuickAccessItem("addUser", true)
                                                history.push({
                                                    pathname: "/user-management", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Add fee request') {
                                                setQuickAccessItem("addFee", true)
                                                history.push({
                                                    pathname: "/fee-management", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Add schedule') {
                                                setQuickAccessItem("addSchedule", true)
                                                history.push({
                                                    pathname: "/schedule", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Add announcement') {
                                                setQuickAccessItem("addAnnounce", true)
                                                history.push({
                                                    pathname: "/announcement", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Create course') {
                                                setCourseTypeName('liveCourses')
                                                history.push({
                                                    pathname: "/add-live-courses", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Add test') {
                                                setQuickAccessItem("addTest", true)

                                                history.push({
                                                    pathname: "/test", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Add resource') {
                                                setQuickAccessItem("addResource", true)
                                                history.push({
                                                    pathname: "/digital-library/division-card", state: {
                                                        key: {
                                                            fromSideMenu: true,
                                                        }
                                                    }
                                                })
                                            } else if (item?.label == 'Mark attendance') {
                                                setQuickAccessItem("markAttendance", true)
                                                history.push({
                                                    pathname: "/user-profile/others",
                                                })
                                            } else if (item?.label == 'Apply leave') {
                                                setQuickAccessItem("applyLeave", true)
                                                history.push({
                                                    pathname: "/user-profile/others",
                                                })
                                            }

                                        }}
                                    >
                                        <img title={item?.label} style={{ cursor: 'pointer' }} src={item?.enabledIcon} alt={`${item?.label} icon`} />
                                    </div>
                                ))}

                        </div>
                    </div>

                }
                {
                    collapsed &&
                    <div className="quick-access" style={{  marginLeft: '23.5%', marginTop: 10, width:'fit-content', padding: '8% 3% 10% 13%' }}>
                        <div className="quick-access-menu-item">
                            <Dropdown
                                overlay={<CollapseQuickAccessModal
                                    items={userMenusettingsData?.menuSettings}
                                    setDropdownVisible={setDropdownVisible}
                                    setDropdownVisibleCollapse={setDropdownVisibleCollapse}
                                    userId={user.id}
                                    allItems={allItems}
                                    updateUserMenuSettings={updateUserMenuSettings}
                                    fetchUserMenuSettingsData={fetchUserMenuSettingsData}
                                    userMenusettingsData={userMenusettingsData}
                                    setCourseTypeName={setCourseTypeName}
                                    setQuickAccessItem={setQuickAccessItem}
                                    setEditClick={setEditClick}
                                    editClick = {editClick}
                                // onChange={handleItemsChange}
                                />}
                                visible={dropdownVisibleCollapse}
                                onVisibleChange={handleDropdownVisibleChangeCollapse}
                                trigger={['click']}
                                // placement='bottomRight'
                                overlayStyle={{
                                    width: "270px",
                                    // left:100,
                                    top: 100
                                }}
                            >
                                <img style={{ cursor: 'pointer' }} src={menuCollapsed} />
                            </Dropdown>

                        </div>
                    </div>
                }
                <div ref={menuRef} className='m-t-10 flex-12 scroll-bar-universal' style={{ position: 'relative', }}>
                    {Object.keys(menuItems(props)).map((grp, index) => itemsGrp(grp, index, props, selectedKey, collapsed, notifyBadgeListCount, setHovered))}
                </div>
                <div style={{ margin: "0 20px 0 20px" }} />
                <div className='flex-1 display-flex m-b-10 cursor-pointer' style={{ alignItems: "flex-end", padding: "0 20px ", width: "100%" }} onMouseEnter={() => setShowProfileMenu(true)} onMouseLeave={() => setShowProfileMenu(false)} >
                    <div className='r-jsb' style={{ width: "100%", alignItems: "center" }} >
                        <div className='r-ac'>
                            <div style={{ marginLeft: collapsed ? 5 : 0, position: "relative" }}>
                                <UserAvatar size="5vmin" shape="circle" name={user.name} img={user.image == "default.png" ? "" : `${Api._s3_url}${user.image}`} />
                                {collapsed && <span className="notification-count">{notification?.notificationCount?.all > 9 ? '9+' : notification?.notificationCount?.all}</span>}
                            </div>
                            {collapsed ?
                                null
                                :
                                <div >
                                    <Typography.Text
                                        ellipsis={{ tooltip: true }}
                                        className='userName'
                                        style={{

                                            maxWidth: 120,
                                            width: 'fit-content',
                                        }}
                                    >
                                        {user.name}
                                    </Typography.Text>

                                </div>

                            }

                        </div>
                        <div onClick={e => { e.isPropagationStopped(); }} style={{ zIndex: 100 }} >
                            {collapsed ?
                                null
                                :

                                <div className="notification-bell"
                                    onClick={() => { history.push('/notifications-home') }}>
                                    <img
                                        className="bell-icon"
                                        style={{ cursor: "pointer", height: 22, width: 22 }}
                                        src={bellIcon}
                                        alt="Notification Bell"
                                    />
                                    {<span className="notification-count">{notification?.notificationCount?.all > 9 ? '9+' : notification?.notificationCount?.all}</span>}
                                </div>
                            }
                        </div>
                    </div>

                    <ProfileMenu user={user} visible={showProfileMenu} setConfirmLogoutVisible={setConfirmLogoutVisible} collapsed={collapsed} />
                </div>
                {/* {collapsed ?
                    <div className='r-c-c flex-1 cursor-pointer' style={{ zIndex: 100 }} onClick={e => { e.isPropagationStopped(); toggleSider(!collapsed); }}>
                        <div className="notification-bell"
                                onClick={()=>{history.push('/notifications-home')}}>
                                    <img
                                        className="bell-icon"
                                        style={{ cursor: "pointer", height: 22, width: 22 }}
                                        src={bellIcon}
                                        alt="Notification Bell"
                                    />
                                    { <span className="notification-count">{ 1}</span>}
                                </div>
                    </div>
                    : null
                } */}

            </div>
            <LogoutConfirm
                visible={confirmLogoutVisible}
                onCancel={() => setConfirmLogoutVisible(false)}
                onConfirm={() => handleLogout(history)}
            />
        </div>
    );
})

const mapStateToProps = (state) => {
    const { collapsed, scrollPosition } = state.layout;
    const { myCoursePage, myCoursePageForTeacher } = state.studentMarketPlace
    const {
        notifyBadgeList,
        notifyBadgeListCount,
        rejectLoader,
        approveLoader,
    } = state.login;
    const { userMenusettingsData, menuSettingsData, notificationMenu } = state.userManagementV2
    const { user, authenticated } = state.session;
    const { orgData } = state.loginNew;
    const { notification } = state.notification
    const { isLogoutClicked } = state.studentMarketPlace

    return {
        collapsed,
        user,
        notifyBadgeList,
        notifyBadgeListCount,
        rejectLoader,
        approveLoader,
        orgData,
        authenticated,
        scrollPosition,
        myCoursePage, myCoursePageForTeacher,
        userMenusettingsData, menuSettingsData, notificationMenu, notification, isLogoutClicked
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCourseTypeName: (val) => dispatch(setCourseTypeName(val)),
    logout: (history, reason) => dispatch(logout(history, reason)),
    notificationBadgeList: () => dispatch(notificationBadgeList()),
    clearPrivateChat: () => dispatch(clearPrivateChat()),
    toggleSider: (val) => dispatch(toggleSider(val)),
    setWidthOfMenu: (val) => dispatch(setWidthOfMenu(val)),
    setIsLogoutClicked: (val) => dispatch(setIsLogoutClicked(val)),
    setScrollPosition: (val) => dispatch(setScrollPosition(val)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    setMenuSettingsData: (res) => dispatch(setMenuSettingsData(res)),
    setQuickAccessItem: (res) => dispatch(setQuickAccessItem(res)),
    fetchUserMenuSettingsData: (userId, callback, successCallBack) => dispatch(fetchUserMenuSettingsData(userId, callback, successCallBack)),
    updateUserMenuSettings: (userId, callback, successCallBack) => dispatch(updateUserMenuSettings(userId, callback, successCallBack)),
    fetchNotificationsForMenu: (callback) => dispatch(fetchNotificationsForMenu(callback)),
    fetchNotifications: (callback) => dispatch(fetchNotifications(callback)),



});

const SiderMenu = withRouter(SideMenu);

export default connect(mapStateToProps, mapDispatchToProps)(SiderMenu);