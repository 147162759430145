import React from 'react'
import { Api } from 'services';
import { Checkbox, Popover, } from 'antd';
import { DeleteOutlined, DragOutlined, EditOutlined, ShareAltOutlined, StarFilled, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import "./styles.css"
const defaultCourseImage = require('Assets/default_course-img.svg').default;



const ListViewCoursecards = ({ key, index, item, hasMarketingPrivilege, fromSearchCourse, reference, selectedUnpublishCards, togglePublishedCheckbox, CourseCardContent, isCoursesCardsVisible,
    searchResultData, fromSearchVideo, selectedPublishCards, courseTypeName, totalCoursesDataContainer, setSearchSelectedCourseType, toggleUnpublishedCheckbox, storeSingleCourseId, isPublished,
    selectedCategory, selectedSubCategory, setIsDeleteModalVisible, setSelectedPublishCards, setSelectedUnpublishCards, setIsMoveModalVisible, setShareModalVisible, setShareUrl,
    setIsConfirmPublishModalVisible, fromSearchStudy, fromSearchTest, unpublishedTests, publishedTests, isSearchedSuggestionClicked, setCourseTypeName, selectedSuggestion, setHoveredCardId,
    hoveredCardId, isGridView}) => {
    console.log('qwertyu', item);

    const location = useLocation();

    const history = useHistory();
    const RatingStars = ({ star }) => {
        const fullStars = Math.floor(star);
        const halfStar = star % 1 === 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarFilled key={i} className="fas fa-star" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
        }
        if (halfStar) {
            stars.push(<StarFilled key="half" className="fas fa-star-half-alt" style={{ color: '#FFA931', marginLeft: 2, fontSize: 12 }} />);
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<StarFilled key={`empty-${i}`} className="fas fa-star" style={{ color: '#AEAEAE', marginLeft: 2, fontSize: 12 }} />);
        }
        return (
            <div style={{ display: "flex", }}>
                <div style={{ color: "#FFA931", fontWeight: "bold", marginRight: 5 }}>{star}</div>
                <div >{stars.map((starElement) => starElement)}</div>
            </div>
        );
    };

    const getAdjustedHour = (duration) => {

        const [hours, minutes] = duration?.split(':').map(Number);

        if (minutes > 30) {
            return hours + 1;
        } else {
            return hours;
        }


    };



    const isSelectedCard = courseTypeName === 'onlineTestSeries'
        ? (isPublished
            ? selectedPublishCards?.includes(publishedTests[index]?.id)
            : selectedUnpublishCards?.includes(unpublishedTests[index]?.id)
        )
        : (
            (fromSearchCourse && reference === 'unpublished')
                ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                : (fromSearchCourse && reference === 'published')
                    ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                    : (fromSearchVideo && reference === 'unpublished')
                        ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                        : (fromSearchVideo && reference === 'published')
                            ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                            : reference === 'unpublished'
                                ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
        );

    return (
        <div style={{
            width: '100%', backgroundColor: courseTypeName == 'onlineTestSeries' ?
                (isPublished ? selectedPublishCards?.includes(publishedTests[index]?.id) : selectedUnpublishCards?.includes(unpublishedTests[index]?.id)) ? '#EDE9F6' : '#ffffff'
                :
                ((fromSearchCourse && reference === "unpublished")
                    ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                        ? '#EDE9F6'
                        : '#ffffff'
                    : (fromSearchCourse && reference === "published")
                        ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                            ? '#EDE9F6'
                            : '#ffffff'
                        : (fromSearchVideo && reference === "unpublished")
                            ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                ? '#EDE9F6'
                                : '#ffffff'
                            : (fromSearchVideo && reference === "published")
                                ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                    ? '#EDE9F6'
                                    : '#ffffff'
                                : reference === "unpublished"
                                    ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                        ? '#EDE9F6'
                                        : '#ffffff'
                                    : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                        ? '#EDE9F6'
                                        : '#ffffff'),

            boxShadow: '4px 4px 16px #0000000F', borderRadius: 10, cursor: 'pointer', padding: 15, marginBottom: 20, display: 'flex', justifyContent: 'space-between'
        }}

            onClick={(e) => {
                e.stopPropagation();
                storeSingleCourseId(item?.id);
                if (isSearchedSuggestionClicked) {
                    setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : null);
                }
                history.push({
                    pathname: "/course-details",
                    state: {
                        key: {
                            fromCourseCard: {
                                isCoursesCardsVisible: isCoursesCardsVisible,
                                selectedCategory: selectedCategory,
                                selectedSubCategory: selectedSubCategory,
                            },
                            isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                            searchedValue: selectedSuggestion,
                            fromDashboard: location?.state?.key?.fromDashboardSearch,
                            fromDashboardSearch: location?.state?.key?.fromDashboardSearch,
                            isPublished:isPublished,
                            isGridView:isGridView
                        }
                    }
                })
            }}

        >

            <div style={{ position: "relative", width: '26%' }}>
                <img src={item?.image ? `${Api.dlS3Url}${item?.image}` : defaultCourseImage} style={{ width: "100%", height: 160, borderRadius: 8 }} loading='lazy' />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {hasMarketingPrivilege ? <></> :
                        <div style={{ position: "absolute", top: 7, left: 10 }} onClick={(e) => { e.stopPropagation() }}>
                            <Checkbox
                                checked={courseTypeName == 'onlineTestSeries' ?

                                    ((fromSearchStudy && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                        : (fromSearchStudy && reference == "published") ? selectedPublishCards?.includes(searchResultData?.studyMaterials?.items?.[index]?.id)
                                            : (fromSearchTest && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                : (fromSearchTest && reference == "published") ? selectedPublishCards?.includes(searchResultData?.onlineTestSeries?.items?.[index]?.id)
                                                    : reference == "unpublished"
                                                        ? (courseTypeName == "onlineTestSeries")
                                                            ? selectedUnpublishCards?.includes(unpublishedTests?.[index]?.id)
                                                            : selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : (courseTypeName == "onlineTestSeries")
                                                            ? selectedPublishCards?.includes(publishedTests?.[index]?.id)
                                                            : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)
                                    )

                                    :

                                    (fromSearchCourse && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                        : (fromSearchCourse && reference == "published") ? selectedPublishCards?.includes(searchResultData?.courses?.items?.[index]?.id)
                                            : (fromSearchVideo && reference == "unpublished") ? selectedUnpublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                : (fromSearchVideo && reference == "published") ? selectedPublishCards?.includes(searchResultData?.videoResources?.items?.[index]?.id)
                                                    : reference == "unpublished" ? selectedUnpublishCards?.includes(totalCoursesDataContainer?.unpublished?.items?.[index]?.id)
                                                        : selectedPublishCards?.includes(totalCoursesDataContainer?.published?.items?.[index]?.id)}
                                onChange={() => {
                                    if (fromSearchCourse || fromSearchVideo) {
                                        setSearchSelectedCourseType(fromSearchCourse ? "searchCourse" : "searchVideo")
                                    }
                                    reference == "unpublished" ? toggleUnpublishedCheckbox(item?.id, false) : togglePublishedCheckbox(item?.id, false)
                                }} />
                        </div>
                    }

                    {/* {console.log('selectedPublishCards1', (selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0), (selectedPublishCards?.length > 0 || selectedUnpublishCards?.length > 0), courseTypeName)} */}
                    {/* {hasMarketingPrivilege ? <></> :
                        <div className={`${isSelectedCard ? 'three-dots-div' : 'hovered-text-visible'}`} >
                            <Popover visible={hoveredCardId === item.id } content={isSelectedCard ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger={isSelectedCard ? '' : 'hover'} placement='right' showArrow={false} style={{cursor: isSelectedCard ? 'not-allowed' : 'pointer'}}>
                                <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "24px", height: "22px", color: "grey", position: "absolute", top: 5, right: 5 , cursor: isSelectedCard ? 'not-allowed' : 'pointer'}} onClick={(e) => { e.stopPropagation() }} />
                            </Popover>
                        </div>
                    } */}

                </div>


                {(courseTypeName == "videoResources" || fromSearchVideo) ? <></> : <div style={{ borderTopLeftRadius: 3, borderBottomLeftRadius: 3, fontSize: 10, color: "#FFFFFF", backgroundColor: item?.mode == "online" ? "#28DF99" : item?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "5px 10px", position: "absolute", fontWeight: "bold", top: 28, right: 0 }}>{item?.mode == "online" ? "Online" : item?.mode == "offline" ? "Offline" : "Hybrid"}</div>}


            </div>
            <div style={{ width: '72%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ color: '#191919', fontSize: 16, fontWeight: 500 }}>{item?.name}</div>
                    {courseTypeName == 'liveCourses' ? <div style={{cursor:isSelectedCard?'not-allowed':'pointer'}}>
                        <Popover visible={hoveredCardId === item.id } onVisibleChange={(visible) => setHoveredCardId(visible ? item.id : null)} content={isSelectedCard ? null : CourseCardContent(item?.id, fromSearchCourse, fromSearchVideo, false, false)} trigger={isSelectedCard ? '' : 'hover'} placement='right' showArrow={false}>
                            <img src={require("../../Assets/courseManagementV4/new-more-icon.svg").default} style={{ width: "28px", height: "23px", color: "grey", }} onClick={(e) => { e.stopPropagation() }} />
                        </Popover>
                    </div>
                        :
                        <></>}
                </div>
                {courseTypeName == 'liveCourses' ?
                    <div style={{ color: '#636363', marginTop: 8 }}>
                        <span>{parseInt(item?.duration) > 0 ? item?.duration : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.duration) > 0 ? item?.durationType?.toLowerCase() : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? '|' : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? item?.subjectCount : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.subjectCount) > 0 ? parseInt(item?.subjectCount) == 1 ? 'subject' : 'subjects' : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? '|' : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? item?.chapterCount : <></>}</span>
                        <span style={{ marginLeft: 5 }}>{parseInt(item?.chapterCount) > 0 ? parseInt(item?.chapterCount) == 1 ? 'chapter' : 'chapters' : <></>}</span>
                    </div>

                    :
                    courseTypeName == 'videoResources' ?
                        <div style={{ color: '#636363', marginTop: 8 }}>
                            <span>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? getAdjustedHour(item?.duration) : <></> : <></>}</span>
                            <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{item?.duration ? getAdjustedHour(item?.duration) > 0 ? 'hours' : <></> : <></>}</span>
                            <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{parseInt(item?.quizCount) > 0 && item?.duration ? getAdjustedHour(item?.duration) > 0 ? '|' : <></> : <></>}</span>
                            <span style={{ marginLeft: item?.duration ? getAdjustedHour(item?.duration) > 0 ? 5 : 0 : 0 }}>{parseInt(item?.quizCount) > 0 ? item?.quizCount : <></>}</span>
                            <span style={{ marginLeft: parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.quizCount) > 0 ? parseInt(item?.quizCount) == 1 ? 'quiz' : 'quizzes' : <></>}</span>
                            <span style={{ marginLeft: parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? '|' : <></>}</span>
                            <span style={{ marginLeft: parseInt(item?.notesCount) > 0 && parseInt(item?.quizCount) > 0 ? 5 : 0 }}>{parseInt(item?.notesCount) > 0 ? item?.notesCount : <></>}</span>
                            <span style={{ marginLeft: 5 }}>{parseInt(item?.notesCount) > 0 ? parseInt(item?.notesCount) == 1 ? 'notes' : 'notes' : <></>}</span>
                        </div>
                        :
                        courseTypeName == 'onlineTestSeries' ?

                            <div style={{ color: '#636363', marginTop: 2 }}>
                                <span>{parseInt(item?.tCount) > 0 ? item?.tCount : <></>}</span>
                                <span style={{ marginLeft: 5 }}>{parseInt(item?.tCount) > 0 ? parseInt(item?.tCount) == 1 ? 'test' : 'tests' : <></>}</span>

                            </div> :
                            <div style={{ color: '#636363', marginTop: 2 }}>
                                <span>{parseInt(item?.m_count) > 0 ? item?.m_count : <></>}</span>
                                <span style={{ marginLeft: 5 }}>{parseInt(item?.m_count) > 0 ? parseInt(item?.m_count) == 1 ? 'material' : 'materials' : <></>}</span>

                            </div>
                }

                <div style={{ display: "flex", justifyContent: 'space-between', marginTop: 8 }}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <RatingStars star={item?.ratingValue || 0} />
                        <div style={{ color: "#AEAEAE", margin: "5px 0px 0px 10px", fontFamily: "roboto", fontWeight: 400, marginTop: -1 }}>({item?.ratingCount || 0})</div>
                    </div>
                </div>

                <div style={{ marginTop: 10 }}>
                    {parseInt(item?.studentCount) > 0 ?
                        <span ><img src={require("../../Assets/courseManagementV4/Team.svg").default} style={{ marginTop: -4, height: 15, width: 15 }} /></span> : <></>}
                    <span style={{ color: '#636363', marginLeft: 10, }}>{parseInt(item?.studentCount) > 0 ? item?.studentCount : <></>}</span> <span style={{ marginLeft: 5, color: '#636363', marginTop: -3 }}><span style={{ color: '#636363' }}>{parseInt(item?.studentCount) > 0 ? 'enrolled' : <></>}</span></span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>

                        {item?.discountType ? item?.discountType === "percent"
                            ? Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100 === 0
                                ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 18 }}>
                                    <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                </div>
                                : (
                                    <div style={{ marginTop: 18 }}>

                                        <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                            ₹ {Math.round((item?.amount - (item?.amount * item?.discount) / 100) * 100) / 100}
                                        </span>

                                        {item?.discount > 0 && (
                                            <span
                                                style={{ fontSize: 14, color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>
                                                ₹ {item?.amount}
                                            </span>
                                        )}
                                    </div>
                                )
                            : item?.discountType === "amount"
                                ? (item?.amount - item?.discount) === 0
                                    ? <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 18 }}>
                                        <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                    </div>
                                    :
                                    <div style={{ fontSize: 18, fontWeight: "500", color: "#594099", marginTop: 18 }}>
                                        ₹ {item?.amount - item?.discount}
                                    </div>

                                :
                                <div>
                                    <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                        ₹ {item?.amount}
                                    </span>
                                </div>

                            :
                            (
                                item?.amount - item?.discount === 0 ?
                                    <div style={{ borderRadius: 4, marginLeft: 0, fontSize: 12, color: "#FF414D", padding: "3px 6px", fontWeight: "bold", backgroundColor: "#FF414D", width: "50px", height: 25, marginTop: 18 }}>
                                        <div style={{ fontSize: 12, color: '#fff', textAlign: "center" }}>Free</div>
                                    </div>
                                    :
                                    <div>
                                        : <span style={{ fontSize: 18, fontWeight: "500", color: "#594099" }}>
                                            ₹ {item?.amount - item?.discount}
                                        </span>
                                        {item?.discount > 0 && (
                                            <span
                                                style={{ fontSize: 14, color: "#636363", marginLeft: 10, textDecoration: 'line-through' }}>
                                                ₹ {item?.amount}
                                            </span>
                                        )}
                                    </div>)
                        }
                    </div>
                    <div style={{ display: 'flex', marginTop: 10, cursor: (isSelectedCard) ? 'not-allowed' : 'pointer' }}
                    // onClick={(e) => {
                    //     e.stopPropagation();
                    //     if (selectedPublishCards?.length > 0 && selectedUnpublishCards?.length > 0) {

                    //         !isPublished ? toggleUnpublishedCheckbox(item?.id) : togglePublishedCheckbox(item?.id);
                    //         setIsConfirmPublishModalVisible(true);
                    //     }
                    // }}

                    >

                        {courseTypeName != "liveCourses" ?

                            isPublished ? <div className={isSelectedCard ? 'custom-container-course-card-list-view-no-hover': 'custom-container-course-card-list-view'} onClick={(e) => { e.stopPropagation(); setIsConfirmPublishModalVisible(true); togglePublishedCheckbox(item?.id, true) }}>
                               
                                
                                <span> <img src={require("../../Assets/courseManagementV4/unpublished.svg").default} style={{ marginRight: 8, height: 13, width: 13 }} /> </span> <span style={{}} > Unpublish</span>
                            </div>
                                :
                                <div className={isSelectedCard ? 'custom-container-course-card-list-view-no-hover': 'custom-container-course-card-list-view'} style={{ marginTop: 2 }} onClick={(e) => { e.stopPropagation(); setIsConfirmPublishModalVisible(true); toggleUnpublishedCheckbox(item?.id, true); }}>
                                    <UploadOutlined style={{ marginRight: 8, fontSize: 14 }} /> <span > Publish</span>
                                </div>

                            :
                            <></>
                        }<div className={isSelectedCard ? 'custom-container-course-card-list-view-no-hover': 'custom-container-course-card-list-view'}
                            style={{ marginLeft: courseTypeName != 'liveCourses' ? 20 : 35 }}
                            onClick={(e) => {
                                console.log('isSelectedCard1111',isSelectedCard);
                                
                                e.stopPropagation();
                                if(!isSelectedCard){
                                    storeSingleCourseId(item?.id);
                                    if (isSearchedSuggestionClicked) {
                                        setCourseTypeName(fromSearchCourse ? "liveCourses" : fromSearchVideo ? "videoResources" : null);
                                    }
                                    history.push({
                                        pathname: "/course-details",
                                        state: {
                                            key: {
                                                fromCourseCard: {
                                                    isCoursesCardsVisible: isCoursesCardsVisible,
                                                    selectedCategory: selectedCategory,
                                                    selectedSubCategory: selectedSubCategory,
                                                },
                                                isSearchedSuggestionClicked: isSearchedSuggestionClicked,
                                                searchedValue: selectedSuggestion,
                                                fromDashboard: location?.state?.key?.fromDashboardSearch,
                                                fromDashboardSearch: location?.state?.key?.fromDashboardSearch,
                                                isPublished:isPublished,
                                                isGridView:isGridView
                                            }
                                        }
                                    })
                                }}
                                }
                               
                            ><EditOutlined style={{ marginRight: 8, fontSize: 14, marginLeft: courseTypeName != 'liveCourses' ? 0 : 0 }} />Edit</div>

                        <div className={isSelectedCard ? 'custom-container-course-card-list-view-no-hover': 'custom-container-course-card-list-view'}


                            onClick={(e) => {
                                e.stopPropagation();
                                if (isSelectedCard) {

                                } else {
                                     setIsDeleteModalVisible(true); setSelectedPublishCards([item?.id]); setSelectedUnpublishCards([item?.id]);
                                }
                            }}>
                            <DeleteOutlined style={{ marginRight: 8, fontSize: 14 }} />Delete</div>
                        <div className={isSelectedCard ? 'custom-container-course-card-list-view-no-hover': 'custom-container-course-card-list-view'}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (isSelectedCard) {

                                } else {
                                     setIsMoveModalVisible(true); isPublished ? setSelectedPublishCards([item?.id]) : setSelectedUnpublishCards([item?.id]);
                                }
                            }}
                        ><DragOutlined style={{ marginRight: 8, fontSize: 14 }} />Move </div>
                        <div className={isSelectedCard ? 'custom-container-course-card-list-view-no-hover': 'custom-container-course-card-list-view'}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (isSelectedCard) {

                                } else {
                                     setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${item?.id}&type=${courseTypeName}`);
                                }

                            }}
                        ><ShareAltOutlined style={{ marginRight: 8, fontSize: 14 }} />Share </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default ListViewCoursecards